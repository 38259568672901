import { useEffect, useState } from 'react';

import {
  Button,
  Grid,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Table,
  Typography,
  CircularProgress,
  IconButton,
  // IconButton,
} from '@material-ui/core';
import { Field, FieldArray, Form, Formik } from 'formik';
import { Checkbox } from 'formik-material-ui';
import { format, isSameDay } from 'date-fns';
import { useTranslation } from 'react-i18next';
// import { IconTrash } from '@tabler/icons';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
// import AccessTimeOutlinedIcon from '@material-ui/icons/AccessTimeOutlined';
import { ArrowBack } from '@material-ui/icons';

import SubCard from '../../../shared/components/cards/SubCard';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import {
  useAddFaultAssistantsMutation,
  useGetAssistantsListCreatedDateQuery,
} from '../slices/virtualClassroomTeacherApiSlice';
import { useAppSelector } from '../../../../hooks/useRedux';
// import DeleteAlert from '../../../shared/components/dialogs/DeleteAlert';
import DateTimePickers from './Components/DateTimePickers';
import { Course } from '../header/CourseDetailHeader';
import LoadingScreen from '../../../shared/screens/LoadingScreen';
import { useGetCourseStudentsQuery } from '../../courses/slices/coursesTeacherApiSlice';
import useFormatDate from '../../../shared/hooks/useFormatDate';
//import HeroData from '../dashboard/components/HeroData';

interface DataForm {
  students?: DataStudent[];
}

interface DataStudent {
  id: number;
  fullname: string;
  type_fault: string;
  prev_type_fault: string | null;
  fault_assistant_id: string;
  course_student_id: string;
  fault: boolean;
  prev_fault: boolean;
}

const Styles = makeStyles({
  boton: {
    '&.css-sghohy-MuiButtonBase-root-MuiButton-root ': {
      borderRadius: '50px',
    },
    '&.css-sghohy-MuiButtonBase-root-MuiButton-root.Mui-disabled': {
      fontWeight: 'medium',
      color: 'white',
    },
  },
});

const AssistantsAppList = () => {
  const [showAlerts, setShowAlerts] = useState(true);
  const [Fecha, setFecha] = useState<Date | null>(new Date());
  const { t } = useTranslation();

  const [course, setCourse] = useState<Course | null>(
    JSON.parse(localStorage.getItem('virtualClassroomTeacher')!) || null
  );

  const { formatDate } = useFormatDate();

  const {
    course: { courseId },
  } = useAppSelector((state) => state.virtualClassroomTeacherShared);

  const { data } = useGetCourseStudentsQuery({
    courseId,
    payload: {
      data: 'all',
      date: Fecha ? format(Fecha, 'yyyy-MM-dd') : format(new Date(), 'yyyy-MM-dd'),
    },
  });

  useEffect(() => {
    setCourse(JSON.parse(localStorage.getItem('virtualClassroomTeacher')!) || null);
  }, []);

  const { data: assistantsListCreated, isLoading: isLoadingAssistantsListCreated } =
    useGetAssistantsListCreatedDateQuery(
      {
        courseId: course?.courseId!,
      },
      {
        skip: !course?.courseId,
      }
    );

  const valuesStudent = data?.map((cs) => {
    const fault =
      cs.type_fault === 'Falta rectificada' || cs.type_fault === 'Atraso justificado'
        ? false
        : cs.type_fault || (!assistantsListCreated && Fecha && isSameDay(Fecha, new Date()))
        ? true
        : false;

    return {
      id: cs.id,
      fullname: `${cs.pers_full_lastname}`,
      type_fault: cs.type_fault ?? null,
      prev_type_fault: cs.type_fault ?? null,
      course_student_id: cs.course_student_id,
      fault_assistant_id: cs.fault_assistant_id,
      fault,
      prev_fault: fault,
    };
  });

  const initialValues: DataForm = {
    students: valuesStudent,
  };

  const styles = Styles();

  const [addFaultAssistants, { isError, error, isSuccess, isLoading }] =
    useAddFaultAssistantsMutation();

  // const [OpenAlert, setOpenAlert] = useState(false);
  const num = (index: number) => ++index;
  const history = useHistory();

  // const handleDeleteFault = (id: string) => {};

  return (
    <>
      {isLoadingAssistantsListCreated && <LoadingScreen />}
      {!isLoadingAssistantsListCreated && (
        <SubCard
          title={
            <>
              <IconButton onClick={() => history.goBack()}>
                <ArrowBack />
              </IconButton>
              {t('teacher_virtual_classroom.assistants_app_list.title_subcard')}
            </>
          }
          darkTitle
          sx={{
            borderTopLeftRadius: '0px',
            borderTopRightRadius: '0px',
          }}
        >
          <Grid item xs={12} md={6} mb={2} container>
            <Grid item>
              {isError && showAlerts && (
                <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
              )}

              {isSuccess && showAlerts && (
                <SuccessAlert
                  message={t('teacher_virtual_classroom.assistants_app_list.success_message')}
                  handleDismiss={() => setShowAlerts(false)}
                />
              )}
            </Grid>
          </Grid>
          {assistantsListCreated && (
            <Grid item xs={12} container>
              <Typography>
                <strong>{t('teacher_virtual_classroom.assistants_app_list.last_update')}</strong>:{' '}
                {formatDate(assistantsListCreated, {
                  format: 'PPPpp',
                })}
              </Typography>
            </Grid>
          )}

          {!data ? (
            <CircularProgress />
          ) : (
            <Formik
              initialValues={initialValues}
              onSubmit={async (values, { setFieldValue }) => {
                // console.log(values);
                // console.log(
                //   values
                //     .students!.filter(
                //       (st) =>
                //         (!assistantsListCreated && st.fault === true) ||
                //         (assistantsListCreated &&
                //           (st.prev_fault !== st.fault || st.prev_type_fault !== st.type_fault))
                //     )
                //     .map((f) => {
                //       return {
                //         course_student_id: Number(f.course_student_id),
                //         type_fault: f.type_fault ?? 'Falta injustificada',
                //       };
                //     })
                // );
                await addFaultAssistants({
                  faults: values
                    .students!.filter(
                      (st) =>
                        (!assistantsListCreated && st.fault === true) ||
                        (assistantsListCreated &&
                          (st.prev_fault !== st.fault || st.prev_type_fault !== st.type_fault))
                    )
                    .map((f) => {
                      return {
                        course_student_id: Number(f.course_student_id),
                        type_fault: f.type_fault ?? 'Falta injustificada',
                      };
                    }),
                  course_id: course?.courseId!,
                }).then((data) => {
                  if ((data as any).data.code === 200) {
                    setFieldValue(
                      'students',
                      values?.students?.map((st) => ({
                        ...st,
                        prev_type_fault: st.type_fault,
                        prev_fault: st.fault,
                      }))
                    );
                  }
                });

                setShowAlerts(true);
              }}
            >
              {({ values, handleSubmit, setFieldValue, getFieldProps }) => (
                <Form onSubmit={handleSubmit}>
                  <Grid display={'flex'} justifyContent={'center'} alignItems="center">
                    <DateTimePickers Fecha={Fecha} setFecha={setFecha} disabled />
                    <Button
                      type="submit"
                      variant="contained"
                      className={styles.boton}
                      disabled={isLoading}
                      endIcon={isLoading ? <CircularProgress size={12} /> : null}
                    >
                      {t('teacher_virtual_classroom.assistants_app_list.save')}
                    </Button>
                  </Grid>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">#</TableCell>
                          <TableCell align="left">
                            {t('teacher_virtual_classroom.assistants_app_list.names')}
                          </TableCell>
                          <TableCell style={{ alignItems: 'center' }} align="center">
                            <Grid container alignItems="center" justifyContent="center">
                              {t('teacher_virtual_classroom.assistants_app_list.attendance')}
                              <CheckBoxOutlinedIcon style={{ color: 'green', marginLeft: 1 }} />
                            </Grid>
                          </TableCell>
                          {/* <TableCell style={{ alignItems: 'center' }} align="center">
                            <Grid container alignItems="center" justifyContent="center">
                              {t('teacher_virtual_classroom.assistants_app_list.tardy')}
                              <AccessTimeOutlinedIcon style={{ color: 'orange', marginLeft: 1 }} />
                            </Grid>
                          </TableCell> */}
                          {/* <TableCell align="center"></TableCell> */}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {!values?.students?.length ? (
                          <TableRow>
                            <TableCell colSpan={5}>
                              <Typography
                                variant="body2"
                                color="textSecondary"
                                component="p"
                                textAlign="center"
                              >
                                {t('teacher_virtual_classroom.assistants_app_list.not_students')}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ) : (
                          <FieldArray
                            name="students"
                            render={(arrayHelpers) => (
                              <>
                                {values.students?.map((st, index) => (
                                  <TableRow className="table-row" key={st.id}>
                                    <TableCell align="center" width="5%">
                                      {num(index)}
                                    </TableCell>
                                    <TableCell align="left" width="40%">
                                      {st.fullname}
                                    </TableCell>
                                    <TableCell align="center" width="40%">
                                      <Field
                                        component={Checkbox}
                                        type="checkbox"
                                        // name={`students[${index}].fault`}
                                        // disabled={
                                        //   values.students &&
                                        //   values.students[index].type_fault === 'Atraso injustificado'
                                        //     ? true
                                        //     : false
                                        // }
                                        {...getFieldProps(`students[${index}].fault`)}
                                        onChange={(e: any) => {
                                          if (e.target.checked) {
                                            setFieldValue(`students[${index}].fault`, false);
                                            if (assistantsListCreated) {
                                              if (st.type_fault === 'Atraso injustificado') {
                                                setFieldValue(
                                                  `students[${index}].type_fault`,
                                                  'Atraso justificado'
                                                );
                                              }
                                              if (st.type_fault === 'Falta injustificada') {
                                                setFieldValue(
                                                  `students[${index}].type_fault`,
                                                  'Falta rectificada'
                                                );
                                              }
                                            }
                                          } else {
                                            setFieldValue(`students[${index}].fault`, true);
                                            setFieldValue(
                                              `students[${index}].type_fault`,
                                              'Falta injustificada'
                                            );
                                          }
                                          console.log(values);
                                        }}
                                        disabled={
                                          values.students &&
                                          values.students[index].type_fault ===
                                            'Atraso injustificado'
                                        }
                                        checked={
                                          !assistantsListCreated
                                            ? st.prev_fault !== st.fault && !st.fault
                                            : !st.fault
                                        }
                                        indeterminate={false}
                                      />
                                    </TableCell>
                                    {false && (
                                      <TableCell align="center" width="2%">
                                        <Field
                                          component={Checkbox}
                                          type="checkbox"
                                          name={`students[${index}].type_fault`}
                                          disabled={
                                            values.students && !values.students?.[index].fault
                                          }
                                          onChange={(e: any) => {
                                            if (e.target.checked) {
                                              setFieldValue(
                                                `students[${index}].type_fault`,
                                                'Atraso injustificado'
                                              );
                                            } else {
                                              if (st.prev_type_fault === 'Atraso injustificado') {
                                                setFieldValue(
                                                  `students[${index}].type_fault`,
                                                  'Atraso justificado'
                                                );
                                              } else {
                                                setFieldValue(
                                                  `students[${index}].type_fault`,
                                                  null
                                                );
                                              }
                                            }
                                          }}
                                          checked={st.type_fault === 'Atraso injustificado'}
                                          indeterminate={false}
                                        />
                                      </TableCell>
                                    )}
                                    {/* <TableCell align="center" width="3%">
                                  <IconButton
                                    onClick={
                                      () => handleDeleteFault(st.fault_assistant_id)
                                      //   {
                                      //   st.fault = false;
                                      //   setFieldValue(`students[${index}].fault`, false);
                                      //   setFieldValue(`students[${index}].type_fault`, true);
                                      //   //`students[${index}].type_fault`

                                      //   //setOpenAlert(true);
                                      // }
                                    }
                                  >
                                    <IconTrash />
                                  </IconButton>
                                  <DeleteAlert
                                    error=""
                                    isLoading={false}
                                    isSuccess={false}
                                    isError={false}
                                    title="Esta seguro de eliminar el estudiante ?"
                                    open={OpenAlert}
                                    handleDelete={() => {
                                      //arrayHelpers.remove(index);
                                      st.fault = false;
                                      ///History.goBack();
                                    }}
                                    onClose={() => setOpenAlert(false)}
                                  />
                                </TableCell> */}
                                  </TableRow>
                                ))}
                              </>
                            )}
                          />
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Form>
              )}
            </Formik>
          )}
        </SubCard>
      )}
    </>
  );
};

export default AssistantsAppList;
