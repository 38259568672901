import { useEffect } from 'react';

import { Grid } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import MainCard from '../../../shared/components/cards/MainCard';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import GoBackBtnTitle from '../../../shared/components/buttons/GoBackBtnTitle';
import SimpleFormOneColumn from '../../../shared/components/forms/skeletons/SimpleFormOneColumn';
import { setCollaboratorFile, setDataPersonColl } from '../slices/teachersSlice';
import { useLazyGetCollaboratorQuery } from '../../../hr/collaborators/slices/collaboratorsApiSlice';
import TeachersFile from '../components/TeachersFile';
import { useGetPersonInformationQuery } from '../../requisition-teacher/slices/requisitionsApiSlice';

const AssignCoursesScreen = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { teacherId } = useParams<{ teacherId: string }>();
  const collabFile = useAppSelector((state) => state.teacher.collabFile);

  const [getCollaborator, { isFetching }] = useLazyGetCollaboratorQuery();

  const { data: dataPerson, isLoading: isLoadingPerson } = useGetPersonInformationQuery(
    {
      id: collabFile?.user.person.pers_identification!,
    },
    { skip: !collabFile?.user?.person?.pers_identification }
  );

  useEffect(() => {
    dispatch(setDataPersonColl({ data: dataPerson, isLoading: isLoadingPerson }));
  }, [dataPerson, dispatch, isLoadingPerson]);

  useEffect(() => {
    if (!collabFile && !isFetching) {
      getCollaborator({
        collaboratorId: +teacherId,
      })
        .unwrap()
        .then((response) => {
          if (response) {
            dispatch(setCollaboratorFile(response as any)); // no se, no se porque any, a saber de donde sale el un collaborator y de donde sale otro, no se metan en mi vida que si funciona.
          }
        })
        .catch((error) => console.log(error));
    }
  }, [collabFile, dispatch, getCollaborator, isFetching, teacherId]);

  return (
    <Grid container component={MainCard} sx={{ padding: 2 }} contentSX={{ width: 1 }}>
      <GoBackBtnTitle title={`${t('Ficha del docente')}`} />

      <Grid item xs={12} sx={{ mt: 2 }}>
        {!!collabFile ? <TeachersFile /> : <SimpleFormOneColumn />}
      </Grid>
    </Grid>
  );
};

export default AssignCoursesScreen;
