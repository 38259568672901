import React from 'react';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  GridProps,
  Theme,
  Accordion as AccordionMui,
  AccordionSummary,
  AccordionDetails,
  AccordionProps,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// assets

// style constant
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography?.pxToRem(16)!,
    fontWeight: theme.typography?.fontWeightRegular!,
  },
}));

//-----------------------|| ACCORDION ||-----------------------//

export type AccordionItem = {
  id: string;
  title: React.ReactElement | string;
  content: React.ReactElement | string;
  disabled?: boolean;
  expanded?: boolean;
  defaultExpand?: boolean | undefined;
};

export interface accordionProps {
  data: AccordionItem[];
  defaultExpandedId?: string | boolean | null;
  expandIcon?: React.ReactElement;
  square?: boolean;
  toggle?: boolean;
  container?: Partial<GridProps>;
  containerChild?: Partial<GridProps>;
  accordionProps?: Partial<AccordionProps>;
}

const Accordion = ({
  data,
  defaultExpandedId = null,
  toggle,
  expandIcon,
  container,
  containerChild,
  accordionProps,
}: accordionProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container {...container}>
        {data &&
          data.map((item: AccordionItem) => (
            <Grid item {...containerChild}>
              <AccordionMui {...accordionProps}>
                <AccordionSummary expandIcon={expandIcon ? expandIcon : <ExpandMoreIcon />}>
                  {item.title}
                </AccordionSummary>
                <AccordionDetails>{item.content}</AccordionDetails>
              </AccordionMui>
            </Grid>
          ))}
      </Grid>
    </div>
  );
};

export default Accordion;
