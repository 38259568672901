import { useMemo } from 'react';

import { Distribution } from '../../interfaces/modelCalification.interfaces';
import { StyledTableCell, StyledTableRow } from './CalificationstTable';
import InputGrade from './InputGrade';

interface Props {
  columns: string[];
  row: any[];
  distributions: { [key: string]: Distribution };
}

const CustomRow = ({ columns, row, distributions }: Props) => {
  const rowsRender = useMemo(() => {
    return row.map((r, index) => {
      return (
        // TODO: esto no vuelve a renderizar, revisar porque (provisionalmente se agrega un random en la key para forzar el render)
        <StyledTableRow key={`${index.toString()}-${Math.random()}`}>
          {columns.map((k) => {
            //@ts-ignore
            return k === 'name' ? (
              <StyledTableCell key={`${k}`}>{r[`${k}`].fullname}</StyledTableCell>
            ) : k.split('-')[0] === 'res' ? (
              <StyledTableCell key={`${k}`}>
                {r[`${k}`] ? Number(r[`${k}`].value).toFixed(2) : null}
              </StyledTableCell>
            ) : k.split('-')[0] === 'nfin' ? (
              <StyledTableCell key={`${k}`}>
                {r[`${k}`] ? Number(r[`${k}`].value).toFixed(2) : null}
              </StyledTableCell>
            ) : (
              <StyledTableCell key={k}>
                <InputGrade
                  grade={r[`${k}`]}
                  distributions={distributions[`${k}`]}
                  student={r[`name`]}
                />
              </StyledTableCell>
            );
          })}
        </StyledTableRow>
      );
    });
  }, [columns, distributions, row]);

  return <>{rowsRender}</>;
};

export default CustomRow;
