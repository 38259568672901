import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  avatar: {
    width: '72px',
    height: '72px',
  },
  avatarPartner: {
    width: '72px',
    height: '72px',
    margin: '-50px auto 0',
  },
  avatarParnertContainer: {
    padding: '56px',
    background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[50],
    border: '1px solid',
    textAlign: 'center',
    maxHeight: '210px',
    borderColor: theme.palette.mode === 'dark' ? 'transparent' : theme.palette.grey[100],
    '&:hover': {
      border: '1px solid' + theme.palette.primary.main,
    },
  },
  separate: {
    marginBottom: '10px',
  },
  containerTab: {
    marginBottom: '24px',
    '& a': {
      minHeight: 'auto',
      minWidth: '10px',
      padding: '12px 8px',
      // marginRight: '18px',
      color: theme.palette.grey[600],
    },
    '& a.Mui-selected': {
      color: theme.palette.primary.main,
    },
    '& a > span': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '& a > span > svg': {
      marginBottom: '0px !important',
      marginRight: '10px',
    },
    '& a > span > span + svg': {
      margin: '0px 0px 0px auto !important',
      width: '14px',
      height: '14px',
    },
  },
}));
