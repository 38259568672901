import { useState, useMemo, useRef } from 'react';
import { Grid, MobileStepper, useTheme, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import GeneralDetailsForm from './GeneralDetailsForm';
import SpecificDetailsForm from './SpecificDetailsForm';
import AssignsForm from './AssignsForm';
import {
  useAddPublicationMutation,
  useSavePubDocumentInfoMutation,
  useUpdatePublicationMutation,
} from '../../../slices/publicationsApiSlice';
import SuccessAlert from '../../../../../shared/components/alerts/SuccessAlert';
import ErrorAlert from '../../../../../shared/components/alerts/ErrorAlert';
import { PublicationStepsPayload } from '../../../interfaces/publicationsPayload.interfaces';
import { useAppSelector } from '../../../../../../hooks/useRedux';
import { useUploadFilesMutation } from '../../../../../shared/slices/uploadFilesApi';

const stepsArray = [
  { id: 1, name: 'publication.publicationWizard.stepsArray.generalDetails' },
  { id: 2, name: 'publication.publicationWizard.stepsArray.specificsDetails' },
  { id: 3, name: 'publication.publicationWizard.stepsArray.assigns' },
];

interface Props {
  isEdit?: boolean;
  publicationId?: string;
}

const WizardPublication = ({ isEdit = false, publicationId = '' }: Props) => {
  const theme = useTheme();

  const { t } = useTranslation();

  const inputElement = useRef<HTMLDivElement>(null);

  const { publicationsList } = useAppSelector((state) => state.publications);

  const publicationInfo = useMemo(
    () => publicationsList?.find((e) => e.id === publicationId),
    [publicationsList, publicationId]
  );

  const [file, setFile] = useState<File>(null!);

  const [activeStep, setActiveStep] = useState(1);

  const [publicationData, setPublicationData] = useState({} as PublicationStepsPayload);

  const [showAlerts, setShowAlerts] = useState(true);

  const [uploadFile, { isLoading: isLoadingFile, isError: isErrorFile, error: errorFile }] =
    useUploadFilesMutation();
  const [
    saveDocInfo,
    { isLoading: isLoadingDocInfo, isError: isErrorDocInfo, error: errorDocInfo },
  ] = useSavePubDocumentInfoMutation();

  const [createPublication, { isLoading: isLoadingProject, isError, isSuccess, error }] =
    useAddPublicationMutation({});

  const [
    updatePublication,
    {
      isLoading: isLoadingEditProject,
      isError: isErrorEdit,
      isSuccess: isSuccessEdit,
      error: errorEdit,
    },
  ] = useUpdatePublicationMutation({});

  const requestFile = async () => {
    if (!file) return;

    const urlFile = await uploadFile({
      files: file!,
    }).unwrap();

    return urlFile;
  };

  const saveDocInfoHandler = async (urlFile?: string, publicationId?: string) => {
    if (!urlFile || !publicationId) return;

    const fileName = file.name.split('.').slice(0, -1).join('.');

    await saveDocInfo({
      document: {
        doc_name_file: fileName,
        doc_url: urlFile,
        scientific_publication_id: publicationId,
        status: 'active',
      },
    }).unwrap();
  };

  const succesRequestHandler = async (resposeId?: string) => {
    const urlFile = await requestFile();

    await saveDocInfoHandler(urlFile, resposeId);
  };

  const sendCreateProject = async ({
    assignments,
    generalDetails,
    specificDetails,
  }: PublicationStepsPayload) => {
    try {
      const response = await createPublication({
        publicationPayload: {
          ...assignments,
          ...generalDetails,
          ...specificDetails,
        },
      }).unwrap();

      await succesRequestHandler(response.id);
    } finally {
      setShowAlerts(true);
    }
  };

  const sendEditPublication = async ({
    assignments,
    generalDetails,
    specificDetails,
  }: PublicationStepsPayload) => {
    try {
      const response = await updatePublication({
        publicationId,
        publicationPayload: {
          ...assignments,
          ...generalDetails,
          ...specificDetails,
        },
      }).unwrap();

      await succesRequestHandler(response.id);
    } finally {
      setShowAlerts(true);
      inputElement?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      direction="column"
      ref={inputElement}
    >
      <Grid item xs={12}>
        {(isError || isErrorEdit || isErrorFile || isErrorDocInfo) && showAlerts && (
          <ErrorAlert
            // suggestion array spread of all errors with prefix description -> 'Al crear: ${errorMsgRequest}, ect.'
            message={
              (errorDocInfo as string) ||
              (errorFile as string) ||
              (errorEdit as string) ||
              (error as string)
            }
            handleDismiss={() => setShowAlerts(false)}
          />
        )}

        {(isSuccess || isSuccessEdit) && showAlerts && (
          <SuccessAlert
            message={
              isEdit
                ? t('publication.publication.edit.messages.success')
                : t('publication.publication.add.messages.success')
            }
            handleDismiss={() => setShowAlerts(false)}
          />
        )}
      </Grid>
      <Grid container justifyContent="center" alignItems="center" my={2}>
        <Typography variant="h4" color="primary">
          {t('publication.steps.step')} {activeStep} {t('publication.steps.of')} {stepsArray.length}
        </Typography>
        <Grid item xs={12}>
          <MobileStepper
            variant="progress"
            steps={stepsArray.length}
            position="static"
            activeStep={activeStep - 1}
            LinearProgressProps={{
              sx: {
                width: '100%',
                height: 10,
                borderRadius: 5,
                backgroundColor: `${theme.palette.grey[300]} !important`,
              },
            }}
            backButton
            nextButton
          />
        </Grid>
        <Grid container>
          <Typography variant="h4" color="inherit" my={2}>
            {t(`${stepsArray[activeStep - 1]?.name}`)}
          </Typography>
        </Grid>
      </Grid>
      <Grid container direction="column">
        <div hidden={activeStep !== 1}>
          <GeneralDetailsForm
            setData={setPublicationData}
            setNext={setActiveStep}
            isEdit={isEdit}
            dataEdit={publicationInfo}
          />
        </div>
        <div hidden={activeStep !== 2}>
          <SpecificDetailsForm
            setData={setPublicationData}
            setNext={setActiveStep}
            getData={publicationData}
            isEdit={isEdit}
            dataEdit={publicationInfo}
          />
        </div>
        <div hidden={activeStep !== 3}>
          <AssignsForm
            setData={setPublicationData}
            setNext={setActiveStep}
            getData={publicationData}
            sendSubmit={isEdit ? sendEditPublication : sendCreateProject}
            isLoading={
              isLoadingProject || isLoadingEditProject || isLoadingFile || isLoadingDocInfo
            }
            dataEdit={publicationInfo}
            isEdit={isEdit}
            document={[file, setFile]}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default WizardPublication;
