import { IconButton, Tooltip } from '@material-ui/core';
import { useAppDispatch } from '../../../../hooks/useRedux';
import SubCard from '../../../shared/components/cards/SubCard';
import SimpleTable from './SimpleTable';
import IconEditPersonal from '@material-ui/icons/ListAlt';
import { fireDialogAssignGrade, setSubjectAssignGrade } from '../slices/AssignGradeSlice';
import { SubjectCurriculumStatus } from '../../../students/subjects-register/interfaces/subjects-approveds';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { SerializedError } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';

interface Props {
  courses?: SubjectCurriculumStatus[];
  isFetching: boolean;
  error: FetchBaseQueryError | SerializedError | undefined;
}

const ListSubjects = ({ courses, isFetching, error }: Props) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const data =
    courses?.map(({ matter_id, matter_rename, status_matter, note }) => {
      return {
        matter_rename,
        status_matter,
        note,
        actions: matter_id,
      };
    }) || [];

  return (
    <SubCard title={t('teacher.assign_grades.list_subjects.title')} content={false} darkTitle>
      <SimpleTable
        data={data}
        headers={{
          matter_rename: t('teacher.assign_grades.list_subjects.subject'),
          status_matter: t('teacher.assign_grades.list_subjects.status'),
          note: t('teacher.assign_grades.list_subjects.grade'),
          actions: t('teacher.assign_grades.list_subjects.action'),
        }}
        keyExtractor={(item) => item.actions?.toString() || '1'}
        isFetching={isFetching}
        error={error}
        customHeadersCellsProperties={{
          matter_rename: {
            align: 'center',
          },
          status_matter: {
            align: 'center',
          },
        }}
        customDataCellsProperties={{
          status_matter: {
            align: 'center',
          },
        }}
        customRenderers={{
          actions: (row) => {
            if (row.note)
              return (
                <Tooltip title={t('teacher.assign_grades.list_subjects.edit')}>
                  <IconButton
                    aria-label="Editar"
                    color="secondary"
                    onClick={() => {
                      const subject = courses?.find((e) => e.matter_id === row.actions);
                      dispatch(setSubjectAssignGrade(subject));
                      dispatch(fireDialogAssignGrade(true));
                    }}
                  >
                    <IconEditPersonal sx={{ fontSize: '1.3rem' }} />
                  </IconButton>
                </Tooltip>
              );
          },
          note: (row) => <>{Number(row.note).toFixed(2) || '-'}</>,
        }}
      />
    </SubCard>
  );
};

export default ListSubjects;
