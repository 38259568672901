import { IconBrandTelegram } from '@tabler/icons';

import SearchPaginatedTable from '../../../../shared/components/tables/SearchPaginatedTable';
import EditButton from '../../../../shared/components/tables/buttons/EditButton';
import { updateUpdateBillTable } from '../../slices/contificoMigrationsSlice';
import { useTableSearch } from '../../../../../hooks/useTableSearch';
import { useAppSelector } from '../../../../../hooks/useRedux';
import { useControlModalStates } from '../../../../shared/hooks/useControlModalStates';
import MigrationsDialog from '../MigrationsDialog';
import { useGetContificoDocumentsMonthQuery } from '../../slices/contificoMigrationsApiSlice';
import { Grid } from '@material-ui/core';
import LocalizedDatePicker from '../../../../shared/components/controlled/LocalizedDatePicker';
import { useState } from 'react';

const UpdateBills = () => {
  const { open, handleCloseModal, handleOpenModalWith } = useControlModalStates();

  const { currentProfile } = useAppSelector((state) => state.access);

  const [date, setDate] = useState<Date>(new Date());

  const { page, perPage, search } = useAppSelector(
    (state) => state.contificoMigrations.updateBillsTable
  );

  const { data, isLoading, isFetching } = useGetContificoDocumentsMonthQuery({
    page,
    perPage: 12,
    profileId: currentProfile?.profileId!,
    year: date.getFullYear(),
    type_sort: 'asc',
  });

  const { setPage, setPerPage, setSearch } = useTableSearch({
    page,
    perPage,
    search,
    updateFunction: updateUpdateBillTable,
  });

  const handler = (id: number) => {
    handleOpenModalWith(id);
  };

  return (
    <>
      <SearchPaginatedTable
        data={
          data?.data?.map((item, index) => ({
            code: (data?.from || 0) + index,
            month: item.month,
            documents: item.number_of_documents,
            amount: item.amount,
            options: item,
          })) || []
        }
        error={undefined}
        headers={{
          code: 'N°',
          month: 'Mes',
          documents: 'Documentos',
          amount: 'Monto',
          options: 'Opciones',
        }}
        isFetching={isFetching}
        isLoading={isLoading}
        page={page}
        perPage={12}
        keyExtractor={(item) => item.month.toString()}
        search={search}
        searchPlacehoder="Buscar por mes"
        setPage={setPage}
        setPerPage={setPerPage}
        setSearch={setSearch}
        total={data?.total || 0}
        perPageBox={false}
        paginationBox={false}
        customRenderers={{
          documents: (item) => <>{item.documents || 0}</>,
          amount: (item) => <>${item.amount}</>,
          options: (item) => (
            <>
              <EditButton
                Icon={IconBrandTelegram}
                title="Migrar"
                handleEdit={() => handler(item.options.id_month)}
              />
            </>
          ),
        }}
        totalRowKeys={['amount']}
        customTotalRowLabels={{
          documents: 'Total: ',
          amount: 'Total: $',
        }}
        customDataCellsProperties={{
          code: {
            align: 'center',
          },
          month: {
            align: 'center',
          },
          documents: {
            align: 'center',
          },
          amount: {
            align: 'center',
          },
          options: {
            align: 'center',
          },
        }}
        additionalFilters={
          <Grid container>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <LocalizedDatePicker
                onChange={(date) => {
                  setDate(date as Date);
                }}
                label="Año"
                value={date}
                views={['year']}
              />
            </Grid>
          </Grid>
        }
      />
      <MigrationsDialog month={1} year={2023} open={open} handleCloseDialog={handleCloseModal} />
    </>
  );
};

export default UpdateBills;
