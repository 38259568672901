import { Dialog, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FC } from 'react';

const useStyles = makeStyles((theme) => ({
  paper: { maxWidth: '600px', width: '90%' },
}));

interface Props {
  onClose: () => void;
  open: boolean;
}

const CustomSimpleDialog: FC<Props> = ({ onClose, open, children }) => {
  const classes = useStyles();

  return (
    <Dialog open={open} classes={{ paper: classes.paper }} sx={{ p: 3 }} onClose={onClose}>
      <Grid container gap={4} sx={{ p: 2 }}>
        <Grid item xs={12} textAlign="center">
          {children}
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default CustomSimpleDialog;
