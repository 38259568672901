import { useEffect, useState } from 'react';
import { getProtocol } from '../../../../helpers/urlHelpers';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  makeStyles,
  TableCell,
  TableRow,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TextField,
  Tooltip,
  Typography,
  useTheme,
  Autocomplete,
} from '@material-ui/core';
import { IconEdit, IconSearch, IconFileDownload } from '@tabler/icons';
import CustomCollapsibleTable from '../../../shared/components/tables/CustomCollapsibleTable';
import { payChecksValues } from '../../deposits-transfers/constants/formConstants';
import useGuard from '../hooks/useGuard';
import SubCard from '../../../shared/components/cards/SubCard';
import {
  CatName,
  DepositsTransfersPayload,
} from '../../deposits-transfers/interfaces/depositsTransfers.interface';
import { useDepositsTransfersFilterQuery } from '../../deposits-transfers/slices/depositsTransfersApiSlice';
import { renderErrorOrEmptyRow } from '../../../shared/helpers/render';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import TransactionStatusChip from '../../../shared/components/badges/TransactionStatusChip';
import { useGetUseTypesQuery } from '../../maintenance-product/slices/productApiSlice';
import { useAppSelector } from '../../../../hooks/useRedux';
import {
  openChangeStatusModalWith,
  setPaymentStatus,
  updatePayCheckTable,
} from '../slices/payChecksSlice';
import { Child } from '../../maintenance-product/interfaces/usetypes.interface';
import { useTableSearch } from '../../../../hooks/useTableSearch';
import LocalizedDatePicker from '../../../shared/components/controlled/LocalizedDatePicker';
import useFormatDate from '../../../shared/hooks/useFormatDate';

const useStyles = makeStyles((theme) => ({
  searchBlock: {
    border: '3px solid',
    borderColor: theme.palette.primary.light,
    width: '100%',
  },
  cardHeader: {
    backgroundColor: theme.palette.primary.light,
    paddingBottom: 2,
    paddingTop: 2,
  },
  labelTypo: {
    fontWeight: 'bold',
    textAlign: 'right',
    width: '100%',
    marginRight: 5,
  },
}));
const PaychecksTable = function () {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();

  const { formatDate } = useFormatDate();

  const { currentProfile } = useAppSelector((state) => state.access);
  const { page, perPage, search, filterArrow } = useAppSelector(
    (state) => state.payCheck.payCheckTable
  );
  const apiUrl = localStorage.getItem('apiUrl');
  const dispatch = useDispatch();

  const [useStatus, setUseStatus] = useState<Child[]>([]);

  const { setPage, setPerPage, setSearch, setFilterArrow } = useTableSearch({
    page,
    perPage,
    search,
    filterArrow,
    updateFunction: updatePayCheckTable,
  });

  const [depositsTransfersPayload, setDepositsTransfersPayload] =
    useState<Partial<DepositsTransfersPayload> | null>(null);

  const { data: paymentStates /*, isLoading: isLoadingPS*/ } = useGetUseTypesQuery({
    profileId: currentProfile?.profileId!,
    acronym: 'STP',
  });

  const { hasAccess } = useGuard();

  const { data: status, isLoading: isLoadingS } = useGetUseTypesQuery({
    profileId: currentProfile?.profileId!,
    acronym: 'STC',
  });

  const handleEdit = (transactionId: number) => {
    dispatch(openChangeStatusModalWith(transactionId));
  };

  //   const datafetch = data?.data!.map((value) => ({
  //     id: value.id,
  //     per_name: value.per_name,
  //     detail: {
  //       per_max_matter_enrollment: value?.per_max_matter_enrollment,
  //       per_min_matter_enrollment: value?.per_min_matter_enrollment,
  //       per_num_fees: value?.per_num_fees,
  //       per_pay_enrollment: value?.per_pay_enrollment,
  //     },
  //     status_id: value.status_id,
  //     options: '',
  //   }));

  const { data, refetch, isLoading, isFetching, error } = useDepositsTransfersFilterQuery(
    {
      page,
      perPage,
      search,
      ...(filterArrow && {
        sort: filterArrow.name,
        type_sort: filterArrow.direction,
      }),
      depositsTransfersPayload: {
        ...depositsTransfersPayload,
      },
    },
    { refetchOnMountOrArgChange: 30, skip: depositsTransfersPayload === null }
  );

  const datafetch = data?.data!.map((value) => {
    // let pay_date = new Date(value.payment_date!);
    return {
      id: value.id,
      paycheck_name: value.payment_way?.cat_name!,
      emision_date: formatDate(value.start_date_collection),
      date: formatDate(value.payment_date),
      identification: value.debtor?.person?.pers_identification!,
      studCode: value?.stud_code || ' ',
      student: value.debtor?.person?.pers_full_name!,
      institution: value.financial_institution_target?.fin_int_name!,
      account_target: value.cuenta_destino,
      fees_amount: value.o_detaill_collect.fees_amount_sin_desc,
      discount: value.o_detaill_collect.sum_descu,
      finalPay: value.o_payment_amount,
      paycheck_type: value.check?.status?.cat_name!,
      status_id: value.pay?.status_payments?.cat_name!,
      options: value,
    };
  });

  const CollapsibleContentRow = (id: number) => {
    return (
      <Box margin={1}>
        <TableContainer>
          <SubCard content={false} sx={{ mb: 2 }}>
            <Table size="small" aria-label="purchases">
              <TableHead>
                <TableRow hover sx={{ backgroundColor: theme.palette.grey[200] }}>
                  <TableCell>{t('financial.pay_checks.collapsible_table.debt')}</TableCell>
                  <TableCell>
                    {t('financial.pay_checks.collapsible_table.payment_method')}
                  </TableCell>
                  <TableCell>
                    {defaultConfiguration?.currency_symbol}
                    {t('financial.pay_checks.collapsible_table.amount')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {datafetch &&
                  id !== -1 &&
                  datafetch[id]?.options?.details!.map((detail) => (
                    <TableRow>
                      <TableCell>{detail.fees_name_identifier}</TableCell>
                      <TableCell>{detail.cat_name}</TableCell>
                      <TableCell>{detail.o_amount}</TableCell>
                    </TableRow>
                  ))}
                {datafetch && id !== -1 && datafetch[id] === undefined && renderErrorOrEmptyRow(2)}
              </TableBody>
            </Table>
          </SubCard>
        </TableContainer>
      </Box>
    );
  };

  const { handleSubmit, values, getFieldProps, setFieldValue } = useFormik({
    initialValues: payChecksValues,
    //validationSchema: electronicDocumentSchema,
    onSubmit: async (value) => {
      const depositsTransfersP: Partial<DepositsTransfersPayload> = {
        search: values.search,
        start_date: values.start_date,
        end_date: values.end_date,
        start_date_collection: values.start_date_collection,
        end_date_collection: values.end_date_collection,
        payment_status_id: values.payment_status_id,
        keyword_payment_way: 'forma-pago-cheque',
        status_check: values.status_check,
        //payment_dep_transf: 'false',
      };
      if (JSON.stringify(depositsTransfersP) === JSON.stringify(depositsTransfersPayload)) {
        refetch();
      } else {
        setDepositsTransfersPayload(depositsTransfersP);
      }
      setPage(1);
    },
  });

  useEffect(() => {
    if (paymentStates) {
      const data = paymentStates?.children || [];
      dispatch(setPaymentStatus(data));
    }
  }, [paymentStates, dispatch]);

  useEffect(() => {
    if (status) {
      const data = status?.children || [];
      setUseStatus(data);
      //dispatch(setPaymentStatus(data));
    }
  }, [status, dispatch]);

  const defaultConfiguration = useAppSelector((state) => state.access.defaultConfiguration);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={3} alignContent="flex-start" mt={1}>
        <Grid item xs={12} container spacing={2}>
          <Card className={classes.searchBlock}>
            <CardHeader
              avatar={<IconSearch />}
              title={t('financial.pay_checks.filters.title')}
              className={classes.cardHeader}
            />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={6} container spacing={2}>
                  {/* container */}
                  <Grid item xs={12} container>
                    <Grid item xs={12} md={3} xl={4} container sx={{ alignContent: 'center' }}>
                      <Typography variant="h5" className={classes.labelTypo}>
                        {t('financial.pay_checks.filters.dates.label')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={9} xl={8} container>
                      <Grid item xs={12}>
                        <Grid container spacing={1}>
                          <Grid item xs={12} sm={6} md={6} lg={6}>
                            <Grid
                              container
                              spacing={1}
                              alignItems="flex-start"
                              alignContent="center"
                            >
                              <Grid item xs zeroMinWidth>
                                <LocalizedDatePicker
                                  value={values.start_date}
                                  onChange={(newValue) => {
                                    setFieldValue('start_date', newValue);
                                  }}
                                  label={t('financial.pay_checks.filters.dates.start_label')}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sm={6} md={6} lg={6}>
                            <Grid container spacing={1} alignItems="flex-start">
                              <Grid item xs zeroMinWidth>
                                <LocalizedDatePicker
                                  value={values.end_date}
                                  onChange={(newValue) => {
                                    setFieldValue('end_date', newValue);
                                  }}
                                  label={t('financial.pay_checks.filters.dates.end_label')}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* container */}
                  <Grid item xs={12} container>
                    <Grid item xs={12} md={3} xl={4} container sx={{ alignContent: 'center' }}>
                      <Typography variant="h5" className={classes.labelTypo}>
                        {t('financial.pay_checks.filters.emision_dates.label')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={9} xl={8} container>
                      <Grid item xs={12}>
                        <Grid container spacing={1}>
                          <Grid item xs={12} sm={6} md={6} lg={6}>
                            <Grid
                              container
                              spacing={1}
                              alignItems="flex-start"
                              alignContent="center"
                            >
                              <Grid item xs zeroMinWidth>
                                <LocalizedDatePicker
                                  value={values.start_date_collection}
                                  onChange={(newValue) => {
                                    setFieldValue('start_date_collection', newValue);
                                  }}
                                  label={t(
                                    'financial.pay_checks.filters.emision_dates.start_label'
                                  )}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sm={6} md={6} lg={6}>
                            <Grid container spacing={1} alignItems="flex-start">
                              <Grid item xs zeroMinWidth>
                                <LocalizedDatePicker
                                  value={values.end_date_collection}
                                  onChange={(newValue) => {
                                    setFieldValue('end_date_collection', newValue);
                                  }}
                                  label={t('financial.pay_checks.filters.emision_dates.end_label')}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={12} lg={6} container spacing={2}>
                  {/*container*/}
                  <Grid item xs={12} container>
                    <Grid item xs={12} md={3} xl={4} container sx={{ alignContent: 'center' }}>
                      <Typography variant="h5" className={classes.labelTypo}>
                        {t('financial.pay_checks.filters.name_label')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={9} xl={8} container>
                      <TextField
                        fullWidth
                        //error={Boolean(errors.to && touched.to)}
                        placeholder={t('financial.pay_checks.filters.name_placeholder')}
                        {...getFieldProps('search')}
                      />
                    </Grid>
                  </Grid>
                  {/* container */}
                  <Grid item xs={12} container>
                    <Grid item xs={12} md={3} xl={4} container sx={{ alignContent: 'center' }}>
                      <Typography variant="h5" className={classes.labelTypo}>
                        {t('financial.pay_checks.filters.status_label')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={9} xl={8} container>
                      <Autocomplete
                        options={useStatus}
                        loading={isLoadingS}
                        getOptionLabel={(option) => option.cat_name}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            //error={Boolean(errors.tipo_comprobante && touched.tipo_comprobante)}
                            placeholder={t('financial.pay_checks.filters.status_placeholder')}
                          />
                        )}
                        onChange={(_, value) => {
                          setFieldValue('status_check', value?.id!);
                        }}
                        fullWidth
                        clearIcon={null}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid item xs={12} textAlign="right" mt={2}>
                  <Button
                    type="submit"
                    variant="contained"
                    //disabled={isLoading}
                    //endIcon={isLoading && <CircularProgress size={20} />}
                    //fullWidth
                  >
                    <IconSearch />
                  </Button>
                  {/* <Grid item xs={12} md={6} xl={6} container sx={{ alignContent: 'center' }}>
                      
                    </Grid>
                    <Grid item xs={12} md={6} xl={6} container>
                      
                    </Grid> */}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} container spacing={2}>
          <CustomCollapsibleTable
            data={datafetch ? datafetch : []}
            headers={{
              id: t('financial.pay_checks.table.detail'),
              paycheck_name: t('financial.pay_checks.table.type'),
              emision_date: t('financial.pay_checks.table.emision_date'),
              date: t('financial.pay_checks.table.pay_date'),
              identification: t('financial.pay_checks.table.identification'),
              studCode: t('financial.pay_checks.table.stud_code'),
              student: t('financial.pay_checks.table.debtor'),
              institution: t('financial.pay_checks.table.destined_institution'),
              account_target: t('financial.pay_checks.table.destined_account'),
              fees_amount: `${defaultConfiguration?.currency_symbol}${t(
                'financial.pay_checks.table.amount'
              )}`,
              discount: `${defaultConfiguration?.currency_symbol}${t(
                'financial.pay_checks.table.discount'
              )}`,
              finalPay: `${defaultConfiguration?.currency_symbol}${t(
                'financial.pay_checks.table.total'
              )}`,
              paycheck_type: t('financial.pay_checks.table.type_status'),
              status_id: t('financial.pay_checks.table.status'),
              options: t('shared.search_paginated_table.table.options'),
            }}
            listFilterArrows={{
              account_target: 'cuenta_destino',
            }}
            filterArrow={filterArrow}
            setFilterArrow={setFilterArrow}
            keyExtractor={(item) => item.options.id.toString()}
            searchPlacehoder={t('financial.pay_checks.table.search_placeholder')}
            isLoading={isLoading}
            isFetching={isFetching}
            error={error}
            perPage={perPage}
            setPerPage={setPerPage}
            total={data?.total || 0}
            page={page}
            setPage={setPage}
            numHeader={14}
            search={search}
            setSearch={setSearch}
            //apply collapsible function, in specific row (optional)
            isCollapsible={true}
            overwriteRowC={0}
            CollapsibleItems={CollapsibleContentRow}
            showFilter={false}
            customRenderers={{
              status_id: (row) => (
                <TransactionStatusChip
                  name={row.options?.pay?.status_payments?.cat_name!}
                  keyword={row.options?.pay?.status_payments?.cat_keyword!}
                />
              ),
              options: (row) => (
                <>
                  {hasAccess('read') && row.options.url_file && (
                    <Tooltip title={t('financial.pay_checks.table.options.download_doc_tooltip')}>
                      <IconButton
                        onClick={() => {
                          window.open(
                            `${getProtocol()}://${apiUrl}/api/show?name=${row.options?.url_file!}`,
                            '_blank'
                          );
                        }}
                      >
                        <IconFileDownload />
                      </IconButton>
                    </Tooltip>
                  )}
                  {hasAccess('edit') &&
                    row.options.pay.status_payments.cat_name !== CatName.Aprobado &&
                    row.options.pay.status_payments.cat_name !== CatName.Rechazado && (
                      <Tooltip
                        title={t('financial.pay_checks.table.options.change_status_tooltip')}
                      >
                        <IconButton onClick={() => handleEdit(row.options.id)}>
                          <IconEdit />
                        </IconButton>
                      </Tooltip>
                    )}
                  {!hasAccess('edit') &&
                    !hasAccess('read') &&
                    t('shared.search_paginated_table.table.options.no_options')}
                </>
              ),
            }}
            customHeadersCellsProperties={{
              options: {
                align: 'center',
              },
              id: {
                align: 'center',
              },
              paycheck_name: {
                align: 'center',
              },
              date: {
                align: 'center',
              },
              identification: {
                align: 'center',
              },
              studCode: {
                align: 'center',
              },
              student: {
                align: 'center',
              },
              institution: {
                align: 'center',
              },
              account_target: {
                align: 'center',
              },
              fees_amount: {
                align: 'center',
              },
              discount: {
                align: 'center',
              },
              finalPay: {
                align: 'center',
              },
              paycheck_type: {
                align: 'center',
              },
              status_id: {
                align: 'center',
              },
            }}
            customDataCellsProperties={{
              id: {
                align: 'center',
              },
              options: {
                align: 'center',
              },
              paycheck_name: {
                align: 'center',
              },
              date: {
                align: 'center',
              },
              identification: {
                align: 'center',
              },
              studCode: {
                align: 'center',
              },
              student: {
                align: 'center',
              },
              institution: {
                align: 'center',
              },
              account_target: {
                align: 'center',
              },
              fees_amount: {
                align: 'center',
              },
              discount: {
                align: 'center',
              },
              finalPay: {
                align: 'center',
              },
              paycheck_type: {
                align: 'center',
              },
              status_id: {
                align: 'center',
              },
            }}
          />
          {/*
          <SearchPaginatedTable
            data={datafetch ? datafetch : []}
            headers={{
              id: 'Código',
              paycheck_name: 'Tipo',
              date: 'F. Pago',
              identification: 'Identificación',
              student: 'Deudor',
              institution: 'Institución destino',
              account_target: 'Cuenta destino',
              pay: 'Monto',
              status_id: 'Estado',
              options: 'Opciones',
            }}
            keyExtractor={(item) => item.id.toString()}
            searchPlacehoder="Buscar por nombre"
            isLoading={isLoading}
            isFetching={isFetching}
            error={error}
            perPage={perPage}
            setPerPage={setPerPage}
            total={data?.total || 0}
            page={page}
            setPage={setPage}
            numHeader={10}
            search={search}
            setSearch={setSearch}
            showFilter={false}
            customRenderers={{
              status_id: (row) => <Chip label={row.status_id} />,
              options: (row) => (
                <>
                  {hasAccess('read') && row.options.url_file && (
                    <Tooltip title="Descargar documento">
                      <IconButton
                        onClick={() => {
                          window.open(
                            `${getProtocol()}://${apiUrl}/api/show?name=${row.options?.url_file!}`,
                            '_blank'
                          );
                        }}
                      >
                        <IconFileDownload />
                      </IconButton>
                    </Tooltip>
                  )}
                  {hasAccess('edit') && (
                    <Tooltip title="Cambiar estado">
                      <IconButton onClick={() => handleEdit(row.options.id)}>
                        <IconEdit />
                      </IconButton>
                    </Tooltip>
                  )}
                  {!hasAccess('edit') && !hasAccess('read') && 'Ninguna'}
                </>
              ),
            }}
            customHeadersCellsProperties={{
              options: {
                align: 'center',
              },
              id: {
                align: 'center',
              },
            }}
            customDataCellsProperties={{
              id: {
                align: 'center',
              },
              options: {
                align: 'center',
              },
            }}
            //   ActionButtons={<>{hasAccess('create') && <AddButton size="large" onClick={goToAdd} />}</>}
          />
          */}
        </Grid>
      </Grid>
    </form>
  );
};

export default PaychecksTable;
