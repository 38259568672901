import { Badge, Box, Typography } from '@material-ui/core';

interface CurriculumTabProps {
  group: number;
  isActive: boolean;
  groupLength: number;
}

const CurriculumTab = ({ group, isActive, groupLength }: CurriculumTabProps) => {
  return (
    <Box
      sx={{
        backgroundColor: !isActive ? 'primary.light' : 'primary.main',
        display: 'flex',
        width: '100%',
        height: '60px',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        borderTopLeftRadius: 16,
        borderTopRightRadius: 16,
      }}
    >
      <Typography variant="h5" color={!isActive ? 'primary.main' : 'primary.contrastText'}>
        Semestre {group}
      </Typography>
      <Badge
        badgeContent={groupLength}
        color="secondary"
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          backgroundColor: 'secondary.dark',
          color: 'white',
        }}
      />
    </Box>
  );
};

export default CurriculumTab;
