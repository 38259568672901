import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISearchTable } from '../../../../../shared/interfaces/paginatedTable.interface';
import { IGroupState } from '../interfaces/groupState.interfaces';

const initialState: IGroupState = {
  groupsUserList: [],
  groupsUserTable: {
    page: 1,
    size: 5,
    search: '',
  },
  openAddGroupsUser: false,
};

export const groupsUsersSlice = createSlice({
  name: 'groupsUsers',
  initialState,
  reducers: {
    setGroupsUserTable: (state, action: PayloadAction<ISearchTable>) => {
      state.groupsUserTable = {
        page: action.payload.page,
        size: action.payload.perPage,
        search: action.payload.search,
      };
    },
    setGroupsUsersList: (state, action: PayloadAction<number[]>) => {
      state.groupsUserList = action.payload;
    },
    setOpenAddGroupsUser: (state, action: PayloadAction<boolean>) => {
      state.openAddGroupsUser = action.payload;
    },
  },
});

export const { setGroupsUserTable, setGroupsUsersList, setOpenAddGroupsUser } =
  groupsUsersSlice.actions;
