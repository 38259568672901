import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import DeleteAlert from '../../../shared/components/dialogs/DeleteAlert';
import { useDeletePosgraduatePlanningHourhandMutation } from '../slices/posgraduatePlanningHourhandsApiSlice';
import {
  setPosgraduatePlanningHourhandDeleteDialogOpen,
  setPosgraduatePlanningHourhandIdSelected,
} from '../slices/posgraduatePlanningHourhandsSlice';

const PostgraduatePlanningHourhandDeleteDialog = () => {
  const dispatch = useAppDispatch();

  const { currentProfile } = useAppSelector((state) => state.access);

  const { posgraduatePlanningHourhandIdSelected } = useAppSelector(
    (state) => state.posgraduatePlanningHourhands
  );

  const [deleteHourhand, { isLoading, isError, error, isSuccess }] =
    useDeletePosgraduatePlanningHourhandMutation();

  const handleDelete = () => {
    deleteHourhand({
      profileId: currentProfile?.profileId!,
      id: posgraduatePlanningHourhandIdSelected!,
    });
  };

  const handleClose = () => {
    dispatch(setPosgraduatePlanningHourhandDeleteDialogOpen(false));
    dispatch(setPosgraduatePlanningHourhandIdSelected(null));
  };

  return (
    <DeleteAlert
      open
      handleDelete={handleDelete}
      onClose={handleClose}
      isLoading={isLoading}
      isError={isError}
      error={error as string}
      isSuccess={isSuccess}
    />
  );
};

export default PostgraduatePlanningHourhandDeleteDialog;
