import { Grid, Typography } from '@material-ui/core';
import MainCard from '../../../shared/components/cards/MainCard';
import MailingTable from '../components/MailingTable';
const MarketingMailingScreen = () => {
    return (
        <Grid component={MainCard} sx={{ padding: 2 }}>
            <Grid sx={{ mb: 3 }}>
                <Typography variant="h2">Marketing</Typography>
                <Typography variant="h2" color="gray" fontWeight={400}>Mailing</Typography>
                <br />
                <MailingTable />

            </Grid>

        </Grid>
    )
}

export default MarketingMailingScreen;