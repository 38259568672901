import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISearchTable } from '../../../shared/interfaces/paginatedTable.interface';
import { ProjectStudent } from '../interfaces/projectsStudent.interfaces';

interface ProjectsSliceState {
  projectsList: ProjectStudent[];
  projectsTable: ISearchTable;
}

const initialState: ProjectsSliceState = {
  projectsList: [],

  projectsTable: {
    page: 1,
    perPage: 5,
    search: '',
  },
};

const projectsSlice = createSlice({
  name: 'Projects',
  initialState,
  reducers: {
    setProjectsList: (state, action: PayloadAction<ProjectStudent[]>) => {
      state.projectsList = action.payload;
    },

    updateProjectsTableStates: (state, action: PayloadAction<ISearchTable>) => {
      state.projectsTable = action.payload;
    },
  },
});

export const { setProjectsList, updateProjectsTableStates } = projectsSlice.actions;

export default projectsSlice.reducer;
