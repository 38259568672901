import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import { setChangePhoto } from '../../../reducers/accessReducer';
import PersonChangePhoto from '../components/PersonChangePhoto';

const PersonChangePhotoScreen = function () {

   const dispatch = useAppDispatch();

   const { changePhoto } = useAppSelector((state) => state.access);

   const handleClose = async () => {
      await dispatch(setChangePhoto(false));
   };

   return (
      <PersonChangePhoto
         open={changePhoto!}
         onClose={handleClose}
      />
   );
};

export default PersonChangePhotoScreen;
