import { ChangeEvent, useState } from 'react';
import {
  Dialog,
  InputLabel,
  TextField,
  FormHelperText,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
  CircularProgress,
  useMediaQuery,
  Button,
  InputAdornment,
  Autocomplete,
} from '@material-ui/core';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/useRedux';
import { setFaqsAdmin } from '../../../slices/faqSlice';
import { useStyles } from '../../../constants/styles';
import { useFormik } from 'formik';
import { useCreateFaqMutation } from '../../../slices/faqApiSlice';
import ErrorAlert from '../../../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../../../shared/components/alerts/SuccessAlert';
import { IconVideo, IconX, IconCloudUpload } from '@tabler/icons';
import MenuItems from '../../../../../../modules/shared/menu-items';
import { useTheme } from '@material-ui/core/styles';
import { FaqCreateInfoSchema } from '../../../constants/validation-schema';
import { useTranslation } from 'react-i18next';

export const FaqCreateDialog = () => {
  const { t } = useTranslation();

  const theme = useTheme();

  const styles = useStyles();

  const dispatch = useAppDispatch();

  const [showAlerts, setShowAlerts] = useState(false);

  const [target, setTarget] = useState<File>(null!);

  const { faqsAdmin } = useAppSelector((state) => state.faqs);

  const { currentProfile, currentModule } = useAppSelector((state) => state.access);

  const handleCloseDialog = () => {
    dispatch(setFaqsAdmin(false));
  };

  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const items = MenuItems.items
    .map((a) => {
      return a.children?.map((e) => ({ title: e.title?.toString()! }!))!;
    })
    .flat(1);

  const itemsTags = [...new Set(items.map((a) => a.title.toString()!))];

  const tags = itemsTags.map((e) => ({ title: e }));

  const [addFaq, { isError, error, isSuccess, isLoading }] = useCreateFaqMutation();

  const onChangeFile = function (event: ChangeEvent<HTMLInputElement>) {
    if (event.target.files && event.target.files[0]) {
      setTarget(event.target.files[0]);
    }
  };

  const transformUrl = (url: string): string => {
    return url.replace(/([0-9]+)/g, 'id');
  };

  const obtainNameUrl = (): string => {
    return window.location.pathname
      .replace(/-/g, ' ')
      .replace(/([0-9]+)/g, ' ')
      .split('/')
      .join(' ');
  };

  const { errors, setFieldValue, values, touched, handleSubmit } = useFormik({
    initialValues: {
      faq_answer: '',
      faq_keyword: window.location.pathname,
      faq_link_video: '',
      faq_question: '',
      faq_tags: [],
      faq_module: currentModule?.key,
    },
    validationSchema: FaqCreateInfoSchema,
    onSubmit: async () => {
      await addFaq({
        profileId: currentProfile?.profileId!,
        payload: {
          faq_answer: values.faq_answer,
          faq_keyword: transformUrl(values.faq_keyword),
          faq_link_video: values.faq_link_video,
          file: target,
          faq_tags: values.faq_tags,
          faq_question: values.faq_question,
          faq_module: currentModule?.key!,
        },
      });
      setShowAlerts(true);
    },
  });

  return (
    <form>
      <Dialog
        open={faqsAdmin}
        keepMounted
        fullScreen={fullScreen}
        onClose={() => handleCloseDialog()}
        className={styles.container}
        classes={{
          paper: styles.paper,
        }}
      >
        <DialogContent>
          <Grid container item xs={12} md={12} sm={12}>
            <Grid container item xs={12} justifyContent="center">
              <Grid item xs={12}>
                {isError && showAlerts && (
                  <ErrorAlert
                    message={error as string}
                    handleDismiss={() => setShowAlerts(false)}
                  />
                )}

                {isSuccess && showAlerts && (
                  <SuccessAlert
                    message={t('configuration.faqs.stepper.success')}
                    handleDismiss={() => setShowAlerts(false)}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid container item xs={12} justifyContent="center">
              <Grid container item xs={12} justifyContent="space-between" sx={{ mt: 5, mb: 5 }}>
                <Grid item>
                  <Typography variant="h3">FAQS - {obtainNameUrl()}</Typography>
                </Grid>
                <Grid item>
                  <IconX style={{ cursor: 'pointer', width: '45px' }} onClick={handleCloseDialog} />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <InputLabel>{t('configuration.faqs.stepper.step2.question')}</InputLabel>
                <TextField
                  fullWidth
                  error={Boolean(errors.faq_question && touched.faq_question)}
                  placeholder={t('configuration.faqs.stepper.step2.question.placeholder')}
                  value={values.faq_question}
                  onChange={(e) => {
                    setFieldValue('faq_question', e.target.value);
                  }}
                />
                {errors.faq_question && touched.faq_question && (
                  <FormHelperText error>{t(errors.faq_question)}</FormHelperText>
                )}
              </Grid>
              <Grid item xs={12} sx={{ mt: 2 }}>
                <InputLabel>{t('configuration.faqs.stepper.step2.description')}</InputLabel>
                <TextField
                  fullWidth
                  rows={10}
                  multiline
                  error={Boolean(errors.faq_answer && touched.faq_answer)}
                  placeholder={t('configuration.faqs.stepper.step2.description.placeholder')}
                  value={values.faq_answer}
                  onChange={(e) => {
                    setFieldValue('faq_answer', e.target.value);
                  }}
                />
                {errors.faq_answer && touched.faq_answer && (
                  <FormHelperText error>{t(errors.faq_answer)}</FormHelperText>
                )}
              </Grid>
              <Grid item xs={12} sx={{ mt: 2 }}>
                <InputLabel>{t('configuration.faqs.stepper.step2.video_link')}</InputLabel>
                <TextField
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <IconVideo fontSize="smal" />
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  error={Boolean(errors.faq_link_video && touched.faq_link_video)}
                  placeholder={t('configuration.faqs.stepper.step2.video_link.placeholder')}
                  value={values.faq_link_video}
                  onChange={(e) => {
                    setFieldValue('faq_link_video', e.target.value);
                  }}
                />
                {errors.faq_link_video && touched.faq_link_video && (
                  <FormHelperText error>{t(errors.faq_link_video)}</FormHelperText>
                )}
              </Grid>
              <Grid item xs={12} sx={{ mt: 2 }}>
                <InputLabel>{t('configuration.faqs.stepper.step2.tags')}</InputLabel>
                <Autocomplete
                  multiple
                  options={tags}
                  loadingText={t('configuration.faqs.stepper.step2.tags.loading')}
                  noOptionsText={t('configuration.faqs.stepper.step2.tags.no_data')}
                  getOptionLabel={(option) => option?.title!}
                  renderInput={(params) => (
                    <TextField
                      placeholder={t('configuration.faqs.stepper.step2.tags.placeholder')}
                      {...params}
                      error={Boolean(errors.faq_tags && touched.faq_tags)}
                    />
                  )}
                  value={tags?.filter((t) => values.faq_tags.some((dt) => dt === t.title))}
                  onChange={(_, values) => {
                    setFieldValue('faq_tags', [...values.map((v) => v.title)] || '');
                  }}
                  fullWidth
                  clearIcon={null}
                />
                {errors.faq_tags && touched.faq_tags && (
                  <FormHelperText error>{t(errors.faq_tags)}</FormHelperText>
                )}
              </Grid>
              <Grid item xs={12} sx={{ mt: 2 }}>
                <Button
                  endIcon={<IconCloudUpload fontSize="20px" />}
                  disabled={isLoading}
                  variant="outlined"
                  size="small"
                  color="primary"
                  onClick={() => document.getElementById('selectFile')?.click()}
                >
                  {target ? target.name : t('configuration.faqs.stepper.step3.select_document')}
                </Button>
                <input
                  type="file"
                  accept="application/pdf"
                  id="selectFile"
                  hidden
                  onChange={onChangeFile}
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="center" spacing={2} sx={{ mb: 7 }}>
            <Grid item>
              <Button
                disabled={isLoading}
                variant="outlined"
                size="large"
                color="primary"
                onClick={() => handleCloseDialog()}
              >
                {t('configuration.faqs.dialog.cancel')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                startIcon={isLoading && <CircularProgress size="1rem" />}
                disabled={isLoading}
                variant="contained"
                type="submit"
                color="primary"
                size="large"
                onClick={() => handleSubmit()}
              >
                {t('configuration.faqs.dialog.add')}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </form>
  );
};
