export const virtualClassrooms = [
  {
    id: 1,
    label: 'NBS',
    keyword: 'native',
  },
  {
    id: 2,
    label: 'Blackboard',
    keyword: 'blackboard',
  },
  {
    id: 3,
    label: 'Moodle',
    keyword: 'moodle',
  },
];

export const meetingsCredentials = [
  {
    id: 1,
    label: 'Zoom',
    keyword: 'Zoom',
  },
  // {
  //   id: 2,
  //   label: 'Meet',
  //   keyword: 'Meet',
  // },
];

// !Uso temporal hasta qeus e defina como se manejara las multiples aulas virtuales
export const tenantsVc: { [key: string]: { type: string; url: string } } = {
  ecotec: {
    type: 'Blackboard',
    url: 'https://ecotec.blackboard.com/',
  },
  argos: {
    type: 'Blackboard',
    url: 'https://argos.blackboard.com/',
  },
};
