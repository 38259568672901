import React from 'react';
import { Grid } from '@material-ui/core';

import DialogAlert from '../../../shared/components/dialogs/DialogAlert';
import InputLabel from '../../../shared/components/forms/InputLabel';

interface Props extends PropsChildren {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  error: any;
  event: () => void;
}

interface PropsChildren {
  periodName: string;
  subjectsName: string[];
  isError?: boolean;
}

const Children = ({ periodName, subjectsName, isError }: PropsChildren) => {
  return (
    <>
      {!isError && (
        <>
          <Grid item xs={12}>
            <InputLabel>Nuevo período:</InputLabel>
            {periodName}
          </Grid>
          <Grid item xs={12} mt={2}>
            <InputLabel>Cursos:</InputLabel>

            {subjectsName.map((e) => (
              <Grid item xs={12}>
                {e}
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </>
  );
};

const ConfirmAlertDialog = ({
  error,
  event,
  isError,
  isLoading,
  isSuccess,
  open,
  setOpen,
  periodName,
  subjectsName,
}: Props) => {
  const handleConfirm = () => {
    event();
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <DialogAlert
      open={open}
      onClose={handleClose}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      children={<Children isError={isError} periodName={periodName} subjectsName={subjectsName} />}
      handleAction={handleConfirm}
      customTitleSendButton={'Si, cambiar de período'}
      title={`Se cambiará el período`}
      error={error as string}
    />
  );
};

export default ConfirmAlertDialog;
