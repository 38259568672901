import {
  Dialog,
  DialogProps,
  InputLabel,
  Autocomplete,
  Grid,
  TextField,
  FormHelperText,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useFormik } from 'formik';
import { useAppSelector } from '../../../../../hooks/useRedux';
import TextFieldFetching from '../../TextFieldFetching';
import {
  useGetCatalogByAcronymQuery,
  useGetCatalogByKeywordQuery,
} from '../../../slices/catalogApi';
import MainCard from '../../cards/MainCard';
import { useCreatePersonDataMutation } from '../../../../shared/slices/searchPersonApi';
import ErrorAlert from '../../alerts/ErrorAlert';
import { useState } from 'react';
import SuccessAlert from '../../alerts/SuccessAlert';
import { PersonCreateSchema } from '../constants/PersonCreateSchema';

const useStyles = makeStyles((theme) => ({
  paper: { maxWidth: '600px', width: '90%' },
}));

interface Props extends DialogProps {
  onClose: () => void;
}

const PersonCreateModal = ({ onClose, ...props }: Props) => {
  const classes = useStyles();

  const { currentProfile } = useAppSelector((state) => state.access);

  const [showAlerts, setShowAlerts] = useState<boolean>(false);

  const [createPerson, { isLoading, error, isError, isSuccess }] = useCreatePersonDataMutation();

  //genders
  const {
    data: gendersAvailables = [],
    isLoading: isLoadingGA,
    isFetching: isFetchingGA,
  } = useGetCatalogByKeywordQuery({
    profileId: currentProfile?.profileId!,
    keyword: 'GE',
  });

  //marital status
  const { data: civilStatusAvailables = [], isLoading: isLoadingCSA } = useGetCatalogByAcronymQuery(
    {
      profileId: currentProfile?.profileId!,
      acronym: 'SM',
    }
  );

  //religions
  const { data: religionsAvailables = [], isLoading: isLoadingRA } = useGetCatalogByAcronymQuery({
    profileId: currentProfile?.profileId!,
    acronym: 'TR',
  });

  const {
    data: identificationsAvailables = [],
    isLoading: isLoadingIA,
    isFetching: isFetchingIA,
  } = useGetCatalogByAcronymQuery({
    profileId: currentProfile?.profileId!,
    acronym: 'TI',
  });

  //Ethnics
  const { data: ethnicsAvailables = [], isLoading: isLoadingEA } = useGetCatalogByAcronymQuery({
    profileId: currentProfile?.profileId!,
    acronym: 'ET',
  });

  const {
    errors,
    touched,
    handleSubmit,
    resetForm,
    setFieldValue,
    values: formikValues,
  } = useFormik({
    initialValues: {
      pers_firstname: '',
      pers_secondname: '',
      pers_first_lastname: '',
      pers_second_lastname: '',
      email: '',
      type_identification_id: '',
      pers_identification: '',
      pers_gender: '',
      status_marital_id: 0,
      ethnic_id: 0,
      type_religion_id: 0,
    },
    validationSchema: PersonCreateSchema,
    onSubmit: async (values) => {
      const errorCreatePerson = await createPerson({
        userProfileId: currentProfile?.profileId!,
        payload: {
          pers_firstname: values.pers_firstname,
          pers_secondname: values.pers_secondname,
          pers_first_lastname: values.pers_firstname,
          pers_second_lastname: values.pers_secondname,
          pers_email: values.email,
          type_identification_keyword: values.type_identification_id,
          pers_identification: values.pers_identification,
          pers_gender: values.pers_gender,
          status_marital_id: values.status_marital_id,
          ethnic_id: values.ethnic_id,
          type_religion_id: values.type_religion_id,
        },
      });

      if ('error' in errorCreatePerson === false) {
        resetForm();
      }

      setShowAlerts(true);
    },
  });

  return (
    <Dialog {...props} classes={{ paper: classes.paper }} sx={{ p: 3 }} onClose={onClose}>
      <Grid container gap={4} sx={{ p: 2 }}>
        <Grid item sm={12} md={12}>
          <form>
            <MainCard title="Datos personales">
              {isError && showAlerts && (
                <Grid container spacing={2} sx={{ mt: 2, mb: 2 }}>
                  <Grid item md={12} xs={12}>
                    <ErrorAlert
                      message={error as string}
                      handleDismiss={() => setShowAlerts(false)}
                    />
                  </Grid>
                </Grid>
              )}
              {isSuccess && showAlerts && (
                <Grid container spacing={2} sx={{ mt: 2, mb: 2 }}>
                  <Grid item md={12} xs={12}>
                    <SuccessAlert
                      message="Persona registrada con éxito"
                      handleDismiss={() => setShowAlerts(false)}
                    />
                  </Grid>
                </Grid>
              )}
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <InputLabel>Tipo Identificación: *</InputLabel>
                  {!isFetchingIA && (
                    <Autocomplete
                      options={identificationsAvailables}
                      loading={isLoadingIA}
                      loadingText="Cargando identificaciones..."
                      getOptionLabel={(option) => option.cat_name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={Boolean(
                            errors.type_identification_id && touched.type_identification_id
                          )}
                          placeholder="Seleccione una identificación disponible"
                        />
                      )}
                      value={
                        identificationsAvailables.find(
                          (dt) => dt.cat_keyword === formikValues.type_identification_id
                        ) || null
                      }
                      onChange={(_, value) => {
                        setFieldValue('type_identification_id', value?.cat_keyword || null);
                      }}
                      fullWidth
                      clearIcon={null}
                    />
                  )}
                  {isFetchingIA && <TextFieldFetching />}
                  {errors.type_identification_id && touched.type_identification_id && (
                    <FormHelperText error>{errors.type_identification_id}</FormHelperText>
                  )}
                </Grid>
                <Grid item md={6} xs={12}>
                  <InputLabel>Identificación *</InputLabel>
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Número de identificación"
                    error={Boolean(errors.pers_identification && touched.pers_identification)}
                    value={formikValues.pers_identification}
                    onChange={(e) => setFieldValue('pers_identification', e.target.value)}
                  />
                  {errors.pers_identification && touched.pers_identification && (
                    <FormHelperText error>{errors.pers_identification}</FormHelperText>
                  )}
                </Grid>

                <Grid item md={6} xs={12}>
                  <InputLabel>Nombre *</InputLabel>
                  <TextField
                    fullWidth
                    placeholder="Nombre"
                    variant="outlined"
                    error={Boolean(errors.pers_firstname && touched.pers_firstname)}
                    value={formikValues.pers_firstname}
                    onChange={(e) => setFieldValue('pers_firstname', e.target.value)}
                  />
                  {errors.pers_firstname && touched.pers_firstname && (
                    <FormHelperText error>{errors.pers_firstname}</FormHelperText>
                  )}
                </Grid>
                <Grid item md={6} xs={12}>
                  <InputLabel>Segundo Nombre *</InputLabel>
                  <TextField
                    fullWidth
                    placeholder="Segundo Nombre"
                    variant="outlined"
                    error={Boolean(errors.pers_secondname && touched.pers_secondname)}
                    value={formikValues.pers_secondname}
                    onChange={(e) => setFieldValue('pers_secondname', e.target.value)}
                  />
                  {errors.pers_secondname && touched.pers_secondname && (
                    <FormHelperText error>{errors.pers_secondname}</FormHelperText>
                  )}
                </Grid>
                <Grid item md={6} xs={12}>
                  <InputLabel>Apellido *</InputLabel>
                  <TextField
                    fullWidth
                    placeholder="Apellido"
                    variant="outlined"
                    error={Boolean(errors.pers_first_lastname && touched.pers_first_lastname)}
                    value={formikValues.pers_first_lastname}
                    onChange={(e) => setFieldValue('pers_first_lastname', e.target.value)}
                  />
                  {errors.pers_first_lastname && touched.pers_first_lastname && (
                    <FormHelperText error>{errors.pers_first_lastname}</FormHelperText>
                  )}
                </Grid>
                <Grid item md={6} xs={12}>
                  <InputLabel>Segundo Apellido *</InputLabel>
                  <TextField
                    id="secondLastName"
                    fullWidth
                    placeholder="Segundo Apellido"
                    variant="outlined"
                    error={Boolean(errors.pers_second_lastname && touched.pers_second_lastname)}
                    value={formikValues.pers_second_lastname}
                    onChange={(e) => setFieldValue('pers_second_lastname', e.target.value)}
                  />
                  {errors.pers_second_lastname && touched.pers_second_lastname && (
                    <FormHelperText error>{errors.pers_second_lastname}</FormHelperText>
                  )}
                </Grid>

                <Grid item md={6} xs={12}>
                  <InputLabel>Correo electrónico *</InputLabel>
                  <TextField
                    id="email"
                    fullWidth
                    placeholder="Correo electrónico"
                    variant="outlined"
                    error={Boolean(errors.email && touched.email)}
                    value={formikValues.email}
                    onChange={(e) => setFieldValue('email', e.target.value)}
                  />
                  {errors.email && touched.email && (
                    <FormHelperText error>{errors.email}</FormHelperText>
                  )}
                </Grid>

                <Grid item md={6} xs={12}>
                  <InputLabel>Etnia:*</InputLabel>
                  {!isLoadingEA && (
                    <Autocomplete
                      options={ethnicsAvailables}
                      loading={isLoadingEA}
                      loadingText="Cargando Estados civil..."
                      getOptionLabel={(option) => option.cat_name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={Boolean(errors.ethnic_id && touched.ethnic_id)}
                          placeholder="Seleccione un Estado civil disponible"
                        />
                      )}
                      value={
                        ethnicsAvailables.find((dt) => dt.id === formikValues.ethnic_id) || null
                      }
                      onChange={(_, value) => {
                        setFieldValue('ethnic_id', value?.id || '');
                      }}
                      fullWidth
                      clearIcon={null}
                    />
                  )}
                  {isLoadingEA && <TextFieldFetching />}
                  {errors.ethnic_id && touched.ethnic_id && (
                    <FormHelperText error>{errors.ethnic_id}</FormHelperText>
                  )}
                </Grid>

                <Grid item md={6} xs={12}>
                  <InputLabel>Religión:*</InputLabel>
                  {!isLoadingRA && (
                    <Autocomplete
                      options={religionsAvailables}
                      loading={isLoadingRA}
                      loadingText="Cargando Religiones..."
                      getOptionLabel={(option) => option.cat_name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={Boolean(errors.type_religion_id && touched.type_religion_id)}
                          placeholder="Seleccione una Religión disponible"
                        />
                      )}
                      value={
                        religionsAvailables.find((dt) => dt.id === formikValues.type_religion_id) ||
                        null
                      }
                      onChange={(_, value) => {
                        setFieldValue('type_religion_id', value?.id || '');
                      }}
                      fullWidth
                      clearIcon={null}
                    />
                  )}
                  {isLoadingRA && <TextFieldFetching />}
                  {errors.type_religion_id && touched.type_religion_id && (
                    <FormHelperText error>{errors.type_religion_id}</FormHelperText>
                  )}
                </Grid>

                <Grid item md={6} xs={12}>
                  <InputLabel>Estado civil:*</InputLabel>
                  {!isLoadingCSA && (
                    <Autocomplete
                      options={civilStatusAvailables}
                      loading={isLoadingCSA}
                      loadingText="Cargando Estados civil..."
                      getOptionLabel={(option) => option.cat_name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={Boolean(errors.status_marital_id && touched.status_marital_id)}
                          placeholder="Seleccione un Estado civil disponible"
                        />
                      )}
                      value={
                        civilStatusAvailables.find(
                          (dt) => dt.id === formikValues.status_marital_id
                        ) || null
                      }
                      onChange={(_, value) => {
                        setFieldValue('status_marital_id', value?.id || '');
                      }}
                      fullWidth
                      clearIcon={null}
                    />
                  )}
                  {isLoadingCSA && <TextFieldFetching />}
                  {errors.status_marital_id && touched.status_marital_id && (
                    <FormHelperText error>{errors.status_marital_id}</FormHelperText>
                  )}
                </Grid>

                <Grid item md={12} xs={12}>
                  <InputLabel>Género *</InputLabel>

                  {!isFetchingGA && (
                    <Autocomplete
                      options={gendersAvailables}
                      loading={isLoadingGA}
                      loadingText="Cargando géneros..."
                      getOptionLabel={(option) => option.cat_name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={Boolean(errors.pers_gender && touched.pers_gender)}
                          placeholder="Seleccione un género"
                        />
                      )}
                      value={
                        gendersAvailables.find((dt) => dt.cat_name === formikValues.pers_gender) ||
                        null
                      }
                      onChange={(_, value) => {
                        setFieldValue('pers_gender', value?.cat_name || null);
                      }}
                      fullWidth
                      clearIcon={null}
                    />
                  )}
                  {isFetchingGA && <TextFieldFetching />}
                  {errors.pers_gender && touched.pers_gender && (
                    <FormHelperText error>{errors.pers_gender}</FormHelperText>
                  )}
                </Grid>
                <Grid container item sx={{ justifyContent: 'right' }}>
                  <Grid item>
                    <Button
                      onClick={() => handleSubmit()}
                      disabled={isLoading}
                      endIcon={isLoading && <CircularProgress size={20} />}
                      variant="contained"
                      color="primary"
                    >
                      Guardar
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </MainCard>
          </form>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default PersonCreateModal;
