import { Switch, Redirect } from 'react-router-dom';
import EducationLevelsScreen from '../screens/EducationLevelsScreen';
import AddEducationLevelScreen from '../screens/AddEducationalLevelScreen';
import EditEducationLevelScreen from '../screens/EditEducationalLevelScreen';
import { useAppSelector } from '../../../../hooks/useRedux';

import { subPermissionKeys } from '../constants/permissions';

import GuardRoute from '../../../../router/guards/GuardRoute';
import AddCollectiveScreen from '../screens/AddCollectiveScreen';
import CollectiveTableScreen from '../screens/CollectiveTableScreen';
import EditCollectiveScreen from '../screens/EditCollectiveScreen';

const { groupKey: permissionKey } = subPermissionKeys;

const EducationLevelsRouter = () => {
  const { currentModule } = useAppSelector((state) => state.access);

  const hasPermission = permissionKey in currentModule!.permissions;

  if (!hasPermission) <Redirect to="/dashboard" />;

  return (
    <Switch>
      <GuardRoute
        exact
        path="/administrar-unidades-academicas"
        component={EducationLevelsScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.read}
      />
      <GuardRoute
        exact
        path="/administrar-unidades-academicas/agregar"
        component={AddEducationLevelScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.create}
      />
      <GuardRoute
        exact
        path="/administrar-unidades-academicas/:educationLevelId/editar"
        component={EditEducationLevelScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.edit}
      />

      <GuardRoute
        exact
        path="/administrar-unidades-academicas/agregar-colectivo/:educationLevelId"
        component={AddCollectiveScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.edit}
      />
      <GuardRoute
        exact
        path="/administrar-unidades-academicas/listar-colectivos/:educationLevelId"
        component={CollectiveTableScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.edit}
      />
      <GuardRoute
        exact
        path="/administrar-unidades-academicas/editar-colectivo/:educationLevelId"
        component={EditCollectiveScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.edit}
      />
    </Switch>
  );
};

export default EducationLevelsRouter;
