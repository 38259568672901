import { Button } from '@material-ui/core';
import { useRef } from 'react';
//import { render } from 'react-dom';

import EmailEditor from 'react-email-editor';

const TemplatesBuilder = () => {
  const emailEditorRef = useRef<any>(null);

  const exportHtml = () => {
    emailEditorRef?.current!.editor.exportHtml((data: any) => {
      const { /* design, */ html } = data;
      console.log('exportHtml', html);
    });
  };

  const onLoad = () => {
    // editor instance is created
    // you can load your template here;
    // const templateJson = {};
    // emailEditorRef.current.editor.loadDesign(templateJson);
  };

  const onReady = () => {
    // editor is ready
    console.log('onReady');
  };

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {/* <button onClick={exportHtml}>Export HTML</button> */}
        <Button variant="contained" size="large" onClick={exportHtml} sx={{ mt: 2, mb: 2 }}>
          Guardar
        </Button>
      </div>

      <EmailEditor
        options={{ locale: 'es' }}
        ref={emailEditorRef}
        onLoad={onLoad}
        onReady={onReady}
      />
    </div>
  );
};

export default TemplatesBuilder;
