import { Route, Switch } from 'react-router-dom';
import ScreenAssignGrade from '../screens/ScreenAssignGrade';

const AssignGradeRouter = () => {
  return (
    <Switch>
      <Route exact path="/asignar-notas/:studentId" component={ScreenAssignGrade} />
    </Switch>
  );
};

export default AssignGradeRouter;
