import { Switch } from 'react-router-dom';
import GuardRoute from '../../../../router/guards/GuardRoute';
import ProjectScreen from '../screens/ProjectScreen';
import TasksScreen from '../screens/TasksScreen';

const ProjectsStudentRouter = () => {
  return (
    <Switch>
      <GuardRoute exact path="/proyectos-estudiante" component={ProjectScreen} />
      <GuardRoute exact path="/proyectos-estudiante/:projectId/tareas" component={TasksScreen} />
    </Switch>
  );
};

export default ProjectsStudentRouter;
