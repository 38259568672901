import {
  Autocomplete,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  InputLabel,
  Skeleton,
  TextField,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useAppSelector } from '../../../../hooks/useRedux';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import { statusAvailables } from '../../../shared/constants/resourceStatus';
import { useGetAvailablesCampusQuery } from '../../campus/slices/campusApiSlice';
import { useFormStyles } from '../constants/styles';
import { ClassroomInfoSchema } from '../constants/validationSchemas';
import {
  useGetAvailableClassroomTypesQuery,
  useUpdateClassroomMutation,
} from '../slices/classroomsApiSlice';
import { useTranslation } from 'react-i18next';

const EditClassroomForm = () => {
  const { t } = useTranslation();

  const history = useHistory();
  const { classroomId } = useParams<{ classroomId: string }>();

  const { classroomsList } = useAppSelector((state) => state.classrooms);
  const { currentProfile } = useAppSelector((state) => state.access);

  const classes = useFormStyles();

  const [showAlerts, setShowAlerts] = useState(false);

  const [updateClassroom, { isLoading: isUpdating, isSuccess, isError, error }] =
    useUpdateClassroomMutation();

  const { data: classroomTypesAvailables = [], isLoading: isLoadingCT } =
    useGetAvailableClassroomTypesQuery({ profileId: currentProfile?.profileId! });

  const { data: campusAvailables = [], isLoading: isLoadingCAMP } = useGetAvailablesCampusQuery({
    profileId: currentProfile?.profileId!,
  });

  const classroomToEdit = classroomsList.find((p) => p.id === Number(classroomId));
  const { getFieldProps, errors, touched, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      block: classroomToEdit?.cl_block || '',
      name: classroomToEdit?.cl_name || '',
      description: classroomToEdit?.cl_description || '',
      capacity: classroomToEdit?.cl_cap_max || 1,
      statusId: classroomToEdit?.status_id || 1,
      campusId: classroomToEdit?.campus_id || 1,
      classroomTypeId: classroomToEdit?.classroom_type_id || 1,
    },
    validationSchema: ClassroomInfoSchema,
    onSubmit: async (values) => {
      await updateClassroom({
        profileId: currentProfile?.profileId!,
        classroomId: classroomToEdit?.id!,
        classroomPayload: {
          cl_block: values.block,
          cl_name: values.name,
          cl_cap_max: values.capacity,
          cl_acronym: null,
          cl_description: values.description,
          campus_id: values.campusId.toString(),
          classroom_type_id: values.classroomTypeId.toString(),
          status_id: values.statusId.toString(),
        },
      });

      setShowAlerts(true);
    },
  });
  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction="column" gap={2}>
        <Grid item>
          {isError && showAlerts && (
            <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
          )}

          {isSuccess && showAlerts && (
            <SuccessAlert
              message={t('academic.classrooms.edit.success')}
              handleDismiss={() => setShowAlerts(false)}
            />
          )}
        </Grid>

        <Grid item container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>
              {t('academic.classrooms.edit.campus')}
            </InputLabel>

            {isLoadingCAMP ? (
              <Skeleton variant="rectangular" sx={{ width: '100%', borderRadius: 3, height: 50 }} />
            ) : (
              <Autocomplete
                options={campusAvailables}
                loading={isLoadingCAMP}
                loadingText={t('academic.classrooms.edit.campus.loading')}
                noOptionsText={t('academic.classrooms.edit.campus.no_data')}
                getOptionLabel={(option) => option.cam_name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={t('academic.classrooms.edit.campus.placeholder')}
                    error={Boolean(errors.campusId && touched.campusId)}
                  />
                )}
                defaultValue={
                  campusAvailables.find(
                    (campus) => campus.id === Number(classroomToEdit?.campus_id)
                  ) || campusAvailables[0]
                }
                onChange={(_, value) => setFieldValue('campusId', value?.id || '')}
                fullWidth
                clearIcon={null}
              />
            )}
            {errors.campusId && touched.campusId && (
              <FormHelperText error>{t(errors.campusId)}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>
              {t('academic.classrooms.edit.block')}
            </InputLabel>
            <TextField
              fullWidth
              error={Boolean(errors.block && touched.block)}
              placeholder={t('academic.classrooms.edit.block.placeholder')}
              {...getFieldProps('block')}
            />
            {errors.block && touched.block && (
              <FormHelperText error>{t(errors.block)}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>
              {t('academic.classrooms.edit.classroom_type')}
            </InputLabel>

            {isLoadingCT ? (
              <Skeleton variant="rectangular" sx={{ width: '100%', borderRadius: 3, height: 50 }} />
            ) : (
              <Autocomplete
                options={classroomTypesAvailables}
                loading={isLoadingCT}
                loadingText={t('academic.classrooms.edit.classroom_type.loading')}
                noOptionsText={t('academic.classrooms.edit.classroom_type.no_data')}
                getOptionLabel={(option) => option.clt_name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={t('academic.classrooms.edit.classroom_type.placeholder')}
                    error={Boolean(errors.classroomTypeId && touched.classroomTypeId)}
                  />
                )}
                defaultValue={classroomTypesAvailables.find(
                  (p) => p.id === Number(classroomToEdit?.classroom_type_id)
                )}
                onChange={(_, value) => setFieldValue('classroomTypeId', value?.id || '')}
                fullWidth
                clearIcon={null}
              />
            )}

            {errors.classroomTypeId && touched.classroomTypeId && (
              <FormHelperText error>{t(errors.classroomTypeId)}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>
              {t('academic.classrooms.edit.name')}
            </InputLabel>
            <TextField
              fullWidth
              error={Boolean(errors.name && touched.name)}
              placeholder={t('academic.classrooms.edit.name.placeholder')}
              {...getFieldProps('name')}
            />
            {errors.name && touched.name && <FormHelperText error>{t(errors.name)}</FormHelperText>}
          </Grid>

          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>
              {t('academic.classrooms.edit.description')}
            </InputLabel>
            <TextField
              multiline
              rows={2}
              fullWidth
              placeholder={t('academic.classrooms.edit.description.placeholder')}
              {...getFieldProps('description')}
            />
          </Grid>

          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>
              {t('academic.classrooms.edit.capacity')}
            </InputLabel>
            <TextField
              fullWidth
              type="number"
              error={Boolean(errors.capacity && touched.capacity)}
              placeholder={t('academic.classrooms.edit.capacity.placeholder')}
              {...getFieldProps('capacity')}
            />
            {errors.capacity && touched.capacity && (
              <FormHelperText error>{t(errors.capacity)}</FormHelperText>
            )}
          </Grid>

          {/* <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>Acrónimo</InputLabel>
            <TextField
              fullWidth
              error={Boolean(errors.acronym && touched.acronym)}
              placeholder="Ingrese el acrónimo del aula"
              {...getFieldProps('acronym')}
            />
            {errors.acronym && touched.acronym && (
              <FormHelperText error>{errors.acronym}</FormHelperText>
            )}
          </Grid> */}

          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>
              {t('academic.classrooms.edit.status')}
            </InputLabel>
            <Autocomplete
              options={statusAvailables}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={t('academic.classrooms.edit.status.placeholder')}
                  error={Boolean(errors.statusId && touched.statusId)}
                />
              )}
              defaultValue={statusAvailables.find(
                (st) => Number(classroomToEdit?.status_id) === st.id
              )}
              onChange={(e, value) => setFieldValue('statusId', value?.id || '')}
              fullWidth
              clearIcon={null}
            />
            {errors.statusId && touched.statusId && (
              <FormHelperText error>{t(errors.statusId)}</FormHelperText>
            )}
          </Grid>
        </Grid>

        <Grid
          item
          container
          alignItems="center"
          justifyContent="flex-end"
          spacing={2}
          sx={{ mt: 1 }}
        >
          <Grid item>
            <Button
              variant="outlined"
              size="large"
              color="primary"
              onClick={() => history.goBack()}
            >
              {t('academic.classrooms.edit.back')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              disabled={isUpdating}
              endIcon={isUpdating && <CircularProgress size={20} />}
            >
              {t('academic.classrooms.edit.submit')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default EditClassroomForm;
