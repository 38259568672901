import { useEffect, useState } from 'react';

import { Grid, Stack, Tab, Tabs, Typography, useTheme } from '@material-ui/core';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { SerializedError } from '@reduxjs/toolkit';
import { IconArrowNarrowLeft } from '@tabler/icons';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';

import {
  Contact,
  Network,
} from '../../../../maintenance/education-levels/components/ContactDeanDialog/ContactDeanForm';
import { EducationLevelContacts } from '../../../../maintenance/education-levels/interfaces/educationLevels.interfaces';
import TableNoPaginated from '../../../../shared/components/tables/TableNoPaginated';
import CustomIconButton from '../../../../shared/components/tables/buttons/CustomIconButton';

interface DefaultProps {
  isLoading: boolean;
  isFetching: boolean;
  error?: FetchBaseQueryError | SerializedError | undefined;
  setTypeTable: (value: React.SetStateAction<'contact' | 'network' | null>) => void;
  height: number;
}

type Props<FormType = Contact | Network | null> = (FormType extends Contact
  ? {
      formType: FormType;
      data: EducationLevelContacts | undefined; //['education_levels_contact'];
    }
  : {
      formType: FormType;
      data: EducationLevelContacts | undefined; //['education_levels_network'];
    }) &
  DefaultProps;

const AcademicContactNetworkTable = ({
  formType,
  data,
  error,
  isLoading,
  isFetching,
  height,
  setTypeTable,
}: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [newFormType, setNewFormType] = useState(formType);

  // llave para re renderizar los tabs (al hacer flip se queda mal posicionado la linea del activo)
  const [tempTapKey, setTempTapKey] = useState<number>(0);
  useEffect(() => {
    setTimeout(() => {
      setTempTapKey(Math.random());
    }, 600); // 800 es el tiempo que tarda en hacer flip +-
  }, [newFormType]);

  //effect that set null newFormType when formType changes to null and with delay of 1 second

  useEffect(() => {
    if (formType === null) {
      setTimeout(() => {
        setNewFormType(formType);
      }, 800);
    } else {
      setNewFormType(formType);
    }
  }, [formType]);

  const datafetch =
    newFormType === 'contact'
      ? data?.education_levels_contact.map((dt, idx) => {
          return {
            name: { idx, value: dt.name },
            description: {
              value: dt.description,
              email: (dt as any).email,
              phone: (dt as any).phone,
            },
          };
        })
      : data?.education_levels_network.map((dt, idx) => {
          return {
            name: { idx, value: dt.name },
            description: { value: dt.description },
          };
        });

  return (
    <Grid container justifyContent="flex-end">
      <Grid item xs={12}>
        <CustomIconButton
          Icon={IconArrowNarrowLeft}
          onClick={() => setTypeTable(null)}
          title="Volver"
        />

        <Typography component="span" fontSize="1rem" fontWeight="bold">
          {t(`students.dashboard.academic_authority.${newFormType}.title`)}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Tabs
          key={`flipCardTabs-${tempTapKey}`}
          value={newFormType}
          onChange={(e, value) => {
            setNewFormType(value as 'contact' | 'network');
          }}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="secondary tabs example"
          variant="fullWidth"
          centered
        >
          <Tab
            value="contact"
            label={
              <Typography
                component="span"
                fontSize="0.9rem"
                fontWeight="bold"
                color={
                  newFormType === 'contact'
                    ? theme.palette.secondary.main
                    : theme.palette.common.white
                }
              >
                {t(`students.dashboard.academic_authority.contact.title`)}
              </Typography>
            }
          />
          <Tab
            value="network"
            label={
              <Typography
                component="span"
                fontSize="0.9rem"
                fontWeight="bold"
                color={
                  newFormType === 'network'
                    ? theme.palette.secondary.main
                    : theme.palette.common.white
                }
              >
                {t(`students.dashboard.academic_authority.network.title`)}
              </Typography>
            }
          />
        </Tabs>
      </Grid>

      {/* {false && (
        <Grid item>
          <FormControl>
            <RadioGroup
              value={newFormType}
              name="radio-buttons-group"
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 1,
              }}
              onChange={(e) => {
                setNewFormType(e.target.value as 'contact' | 'network');
              }}
            >
              <FormControlLabel
                control={<Radio size="small" />}
                value={'contact'}
                label={t(`students.dashboard.academic_authority.contact.title`)}
                // slotProps={{ input: { 'aria-describedby': 'contact-helper-text' } }}
                // labelPlacement="top"
              />
              <FormControlLabel
                control={<Radio size="small" />}
                value={'network'}
                label={t(`students.dashboard.academic_authority.network.title`)}
                // labelPlacement="top"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      )} */}

      <Grid
        item
        xs={12}
        sx={{ /* border: '1px solid black', */ height: `calc(${height}px - 130px)` }}
      >
        <PerfectScrollbar>
          <TableNoPaginated
            data={datafetch || []}
            headers={{
              name: (
                <Typography color={theme.palette.common.white} fontSize="1rem" fontWeight="bold">
                  Nombre
                </Typography>
              ) as any,
              description: (
                <Typography color={theme.palette.common.white} fontSize="1rem" fontWeight="bold">
                  Descripción
                </Typography>
              ) as any,
            }}
            error={error}
            isFetching={isFetching}
            isLoading={isLoading}
            keyExtractor={(item) => `contact-table-d-${item.name.idx.toString()}`}
            search={''}
            searchPlacehoder={'Buscar'}
            showFilter={false}
            customRenderers={{
              name: (item) => (
                <Typography color={theme.palette.common.white} component="span">
                  {item.name.value}
                </Typography>
              ),
              description: ({ description }) => (
                <Stack>
                  <Typography color={theme.palette.common.white}>{description.value}</Typography>
                  {newFormType === 'contact' && (
                    <>
                      <Typography color={theme.palette.common.white}>
                        <b>Correo: </b>
                        {(description as any)?.email}
                      </Typography>
                      <Typography color={theme.palette.common.white}>
                        <b>Teléfono: </b>
                        {(description as any)?.phone}
                      </Typography>
                    </>
                  )}
                </Stack>
              ),
            }}
            replaceHeader={true}
            // newHeader={null}
          />
        </PerfectScrollbar>
      </Grid>
    </Grid>
  );
};

export default AcademicContactNetworkTable;
