import { useState } from 'react';

import {
  Autocomplete,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  Switch,
  TextField,
  useMediaQuery,
  useTheme,
  ToggleButtonGroup,
  ToggleButton,
} from '@material-ui/core';
import DateTimePicker from '@material-ui/lab/DateTimePicker';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import { IMeetingPayloadForm } from '../interfaces/formik.interface';

// import { IconPlus } from '@tabler/icons';s

import { useFormik } from 'formik';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../../../hooks/useRedux';
import { useGetCourseStudentsQuery } from '../../../courses/slices/coursesTeacherApiSlice';
import { useUpdateMeetingMutation } from '../slices/teacherMeetingsApi';
import { ValidationSchema } from '../constants/validationSchemas';
import ErrorAlert from '../../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../../shared/components/alerts/SuccessAlert';
import InputLabel from '../../../../shared/components/forms/InputLabel';
import { approvalType, jbhTime } from '../constants';
import { useGetLocaleDateLang } from '../../../../../translation/hooks/useGetLocaleDateLang';
// import { formikInitialValue } from '../constants/formConstants';
import { borderRadius } from '../../../../shared/constants/uiConstants';
import { Meeting } from '../interfaces/meetings.interface';

interface Props {
  meetingData?: Meeting;
}

const MeetingsEditForm = ({ meetingData }: Props) => {
  console.log(meetingData);
  const history = useHistory();
  const { t } = useTranslation();

  const { lang } = useGetLocaleDateLang();

  const [showAlerts, setShowAlerts] = useState(true);

  const theme = useTheme();

  const downLg = useMediaQuery(theme.breakpoints.down('xl'));

  const {
    course: { courseId },
  } = useAppSelector((state) => state.virtualClassroomTeacherShared);

  const {
    userInfo: { id: userId },
  } = useAppSelector((state) => state.auth);

  const { data: studentData } = useGetCourseStudentsQuery(
    {
      courseId: courseId!,
      payload: { data: 'all' },
    },
    { skip: !courseId }
  );

  const [updateMeetingTrigger, { isError, error, isSuccess, isLoading }] =
    useUpdateMeetingMutation();

  const isEqualStudents = meetingData?.participants.length === studentData?.length;

  const formikInitialValue: IMeetingPayloadForm = {
    act_description: meetingData?.act_description || '',
    type: Number(meetingData?.activity_zoom.type) || 2,
    start_time: new Date(meetingData?.activity_zoom.start_time!) || null,
    duration: Number(meetingData?.activity_zoom.duration) || 0,
    join_before_host: Boolean(Number(meetingData?.activity_zoom.join_before_host)),
    approval_type:
      approvalType.find(
        (item) => item.value === Number(meetingData?.activity_zoom.approval_type)
      ) || null,
    auto_recording: (meetingData?.activity_zoom.auto_recording as any) || 'none',
    close_registration: Boolean(Number(meetingData?.activity_zoom.close_registration)),
    host_video: Boolean(Number(meetingData?.activity_zoom.host_video)),
    jbh_time:
      jbhTime.find((item) => item.value === Number(meetingData?.activity_zoom.jbh_time)) || null,
    mute_upon_entry: Boolean(Number(meetingData?.activity_zoom.mute_upon_entry)),
    participant_video: Boolean(Number(meetingData?.activity_zoom.participant_video)),
    weekly_days: meetingData?.activity_zoom.weekly_days || '3.5',
    weeks: 3.5,
    participants: !isEqualStudents
      ? meetingData?.participants.map((participant) => Number(participant.student_id)) || []
      : [],
  };

  const { getFieldProps, errors, touched, handleSubmit, setFieldValue, values } = useFormik({
    initialValues: {
      ...formikInitialValue,
      participants_check: isEqualStudents,
      add_participants: true,
    },
    validationSchema: ValidationSchema,
    onSubmit: async ({
      act_description,
      type,
      duration,
      join_before_host,
      close_registration,
      auto_recording,
      host_video,
      mute_upon_entry,
      participant_video,
      participants_check,
      add_participants,
      participants,
      ...formv
    }) => {
      const tempInitParticipants = [...participants].sort();
      const tempCurrentParticipants = [...participants].sort();

      let addParticipants: number[] = [];
      let removeParticipants: number[] = [];

      if (
        tempInitParticipants.length !== tempCurrentParticipants.length ||
        tempInitParticipants.some((item, index) => item !== tempCurrentParticipants[index])
      ) {
        addParticipants = tempCurrentParticipants.filter(
          (item) => !tempInitParticipants.includes(item)
        );

        removeParticipants = tempInitParticipants.filter(
          (item) => !tempCurrentParticipants.includes(item)
        );
      }

      await updateMeetingTrigger({
        course: courseId,
        activity: Number(meetingData?.activity_zoom.meeting_id)!,
        payload: {
          user_id: +userId,
          act_description,
          type,
          start_time: format(values.start_time!, 'yyyy-MM-dd HH:mm:ss'),
          duration,
          settings: {
            join_before_host,
            approval_type: formv.approval_type?.value!,
            auto_recording,
            close_registration,
            host_video,
            jbh_time: join_before_host ? formv.jbh_time?.value! : 0,
            mute_upon_entry,
            participant_video,
          },
          recurrence: {
            weekly_days: '3,5',
            weeks: 2,
          },

          addParticipants,
          removeParticipants,

          // participants: participants_check
          //   ? studentData?.map((stud) => stud.id) || []
          //   : add_participants
          //   ? participants
          //   : studentData
          //       ?.filter(
          //         (student) => !participants.some((participantId) => participantId === student.id)
          //       )
          //       .map((stud) => stud.id) || [],
        },
      });

      setShowAlerts(true);
      window.scrollTo(0, 0);
    },
  });

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {isError && showAlerts && (
            <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
          )}

          {isSuccess && showAlerts && (
            <SuccessAlert
              message={t('teacher_virtual_classroom.meetings.add.form.success')}
              handleDismiss={() => setShowAlerts(false)}
            />
          )}
        </Grid>

        <Grid item spacing={2} alignItems="center" xs={12} md={6}>
          <Grid item xs={12}>
            <InputLabel>{t('teacher_virtual_classroom.meetings.add.form.description')}</InputLabel>
            <TextField
              fullWidth
              {...getFieldProps('act_description')}
              error={Boolean(errors.act_description && touched.act_description)}
              placeholder={t('teacher_virtual_classroom.meetings.add.form.description_label')}
            />
            {errors.act_description && touched.act_description && (
              <FormHelperText error>{t(errors.act_description)}</FormHelperText>
            )}
          </Grid>

          {false && (
            <Grid item xs={12}>
              <FormControl>
                <FormGroup row>
                  <FormControlLabel
                    {...getFieldProps('type')}
                    onChange={(e: any) => {
                      setFieldValue('type', e.target.checked ? 8 : 2);
                    }}
                    control={<Switch checked={values.type === 8 ? true : false} />}
                    label={t('teacher_virtual_classroom.meetings.add.form.meet_type')}
                  />
                </FormGroup>
              </FormControl>
            </Grid>
          )}

          <Grid item xs={12} mt={2}>
            <InputLabel>{t('teacher_virtual_classroom.meetings.add.form.start_time')}</InputLabel>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={lang}>
              <DateTimePicker
                value={values.start_time}
                onChange={(newValue) => {
                  setFieldValue('start_time', newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    helperText=""
                    placeholder={t('teacher_virtual_classroom.meetings.add.form.start_time_label')}
                    error={Boolean(errors.start_time && touched.start_time)}
                    fullWidth
                  />
                )}
              />
              {errors.start_time && touched.start_time && (
                <FormHelperText error>{t(errors.start_time)}</FormHelperText>
              )}
            </LocalizationProvider>
          </Grid>

          <Grid item xs={12} mt={2}>
            <InputLabel>{t('teacher_virtual_classroom.meetings.add.form.duration')}</InputLabel>
            <TextField
              fullWidth
              {...getFieldProps('duration')}
              type="number"
              error={Boolean(errors.duration && touched.duration)}
              placeholder={t('teacher_virtual_classroom.meetings.add.form.duration_label')}
            />
            {errors.duration && touched.duration && (
              <FormHelperText error>{t(errors.duration)}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12} mt={2}>
            <InputLabel>
              {t('teacher_virtual_classroom.meetings.add.form.approval_type')}
            </InputLabel>
            <Autocomplete
              options={approvalType}
              value={values.approval_type}
              // getOptionSelected={(option, value) => option.keyword === value.keyword}
              getOptionLabel={(option) => t(option.label)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(errors.approval_type && touched.approval_type)}
                />
              )}
              onChange={(e, value) => setFieldValue('approval_type', value || null)}
              fullWidth
              clearIcon={null}
            />
            {errors.approval_type && touched.approval_type && (
              <FormHelperText error>{t(errors.approval_type)}</FormHelperText>
            )}
          </Grid>
        </Grid>

        <Grid item xs={12} md={6} mt={2}>
          <Grid
            container
            sx={{
              border: `1px solid ${theme.palette.primary.main}`,
              borderRadius: `${borderRadius}px`,
            }}
            padding={2}
          >
            <Grid item xs={12}>
              <FormControl>
                <FormGroup row>
                  <FormControlLabel
                    {...getFieldProps('join_before_host')}
                    onChange={(e: any) => {
                      setFieldValue('join_before_host', e.target.checked);
                    }}
                    control={<Switch checked={values.join_before_host} />}
                    label={t('teacher_virtual_classroom.meetings.add.form.join_before_host_label')}
                  />
                </FormGroup>
              </FormControl>
            </Grid>

            {values.join_before_host && (
              <Grid item xs={12} mb={2}>
                <InputLabel>{t('teacher_virtual_classroom.meetings.add.form.jbh_time')}</InputLabel>
                <Autocomplete
                  options={jbhTime}
                  value={values.jbh_time}
                  // getOptionSelected={(option, value) => option.keyword === value.keyword}
                  getOptionLabel={(option) => t(option.label)}
                  renderInput={(params) => (
                    <TextField {...params} error={Boolean(errors.jbh_time && touched.jbh_time)} />
                  )}
                  onChange={(e, value) => setFieldValue('jbh_time', value || null)}
                  fullWidth
                  clearIcon={null}
                />
                {errors.jbh_time && touched.jbh_time && (
                  <FormHelperText error>{t(errors.jbh_time)}</FormHelperText>
                )}
              </Grid>
            )}

            <Grid item xs={12}>
              <FormControl>
                <FormGroup row>
                  <FormControlLabel
                    {...getFieldProps('participants_check')}
                    onChange={(e: any) => {
                      setFieldValue('participants_check', e.target.checked);
                    }}
                    control={<Switch checked={values.participants_check} />}
                    // label={t('teacher_virtual_classroom.meetings.add.form.join_before_host_label')}
                    label={t('teacher_virtual_classroom.meetings.add.form.participants_all')}
                  />
                </FormGroup>
              </FormControl>
            </Grid>

            {!values.participants_check && (
              <Grid item xs={12} mb={2}>
                {/* <InputLabel>{t('teacher_virtual_classroom.meetings.add.form.jbh_time')}</InputLabel> */}
                <InputLabel>
                  {values.add_participants
                    ? t('teacher_virtual_classroom.meetings.add.form.can_participate')
                    : t('teacher_virtual_classroom.meetings.add.form.cant_participate')}
                </InputLabel>
                <Autocomplete
                  multiple
                  options={studentData || []}
                  value={
                    studentData?.filter((student) =>
                      values.participants.some((id) => id === student.id)
                    ) || []
                  }
                  // getOptionSelected={(option, value) => option.keyword === value.keyword}
                  // renderOption
                  getOptionLabel={(option) => option.pers_full_name}
                  renderInput={(params) => (
                    <Grid container>
                      <Grid item xs={downLg ? 12 : 2} mb={1}>
                        <ToggleButtonGroup
                          color="primary"
                          orientation={downLg ? 'horizontal' : 'vertical'}
                          value={values.add_participants}
                          exclusive
                          onChange={(_, event) => setFieldValue('add_participants', event)}
                        >
                          <ToggleButton color="primary" value={true}>
                            {t('teacher_virtual_classroom.meetings.add.form.include')}
                          </ToggleButton>
                          <ToggleButton value={false} color={'error' as any}>
                            {t('teacher_virtual_classroom.meetings.add.form.exclude')}
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </Grid>
                      <Grid item xs={downLg ? 12 : 10}>
                        <TextField
                          {...params}
                          error={Boolean(errors.participants && touched.participants)}
                        />
                      </Grid>
                    </Grid>
                  )}
                  onChange={(e, value) =>
                    setFieldValue('participants', value.map((student) => student.id) || null)
                  }
                  fullWidth
                  clearIcon={null}
                />
                {errors.participants && touched.participants && (
                  <FormHelperText error>{t(errors.participants)}</FormHelperText>
                )}
              </Grid>
            )}

            <Grid item xs={12}>
              <FormControl>
                <FormGroup row>
                  <FormControlLabel
                    {...getFieldProps('close_registration')}
                    onChange={(e: any) => {
                      setFieldValue('close_registration', e.target.checked);
                    }}
                    control={<Switch checked={values.close_registration} />}
                    label={t('teacher_virtual_classroom.meetings.add.form.close_registration')}
                  />
                </FormGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl>
                <FormGroup row>
                  <FormControlLabel
                    {...getFieldProps('host_video')}
                    onChange={(e: any) => {
                      setFieldValue('host_video', e.target.checked);
                    }}
                    control={<Switch checked={values.host_video} />}
                    label={t('teacher_virtual_classroom.meetings.add.form.host_video')}
                  />
                </FormGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl>
                <FormGroup row>
                  <FormControlLabel
                    {...getFieldProps('mute_upon_entry')}
                    onChange={(e: any) => {
                      setFieldValue('mute_upon_entry', e.target.checked);
                    }}
                    control={<Switch checked={values.mute_upon_entry} />}
                    label={t('teacher_virtual_classroom.meetings.add.form.mute_upon_entry')}
                  />
                </FormGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl>
                <FormGroup row>
                  <FormControlLabel
                    {...getFieldProps('participant_video')}
                    onChange={(e: any) => {
                      setFieldValue('participant_video', e.target.checked);
                    }}
                    control={<Switch checked={values.participant_video} />}
                    label={t('teacher_virtual_classroom.meetings.add.form.participant_video')}
                  />
                </FormGroup>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          container
          alignItems="center"
          justifyContent="flex-end"
          spacing={2}
          sx={{ mt: 1 }}
        >
          <Grid item>
            <Button
              variant="outlined"
              size="large"
              color="primary"
              onClick={() => history.goBack()}
            >
              {t('shared.go_back_button.label')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              disabled={isLoading}
              endIcon={isLoading && <CircularProgress size={20} />}
            >
              {t('shared.save_button.label')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default MeetingsEditForm;
