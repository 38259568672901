import { useEffect, useRef, useState } from 'react';

import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Collapse,
  Grid,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ChatBubbleTwoToneIcon from '@material-ui/icons/ChatBubbleTwoTone';
import noPhoto from '../../../../assets/images/pages/no_photo.jpg';
import { useTranslation } from 'react-i18next';
import { IconChevronDown, IconChevronUp } from '@tabler/icons';

import useGetBaseUrl from '../../../../hooks/useGetBaseUrl';
import useFormatDate from '../../hooks/useFormatDate';

const useStyles = makeStyles((theme) => ({
  containerCard: {
    background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[50],
    border: theme.palette.mode === 'dark' ? 'none' : '1px solid',
    borderColor: theme.palette.grey[100],
    textAlign: 'center',
    '&:hover': {
      borderColor: theme.palette.primary.main,
    },
    cursor: 'pointer',
  },
  separate: {
    marginTop: 40,
  },
}));

interface Props {
  id: number;
  title: string;
  description: string;
  url_image: string;
  start_date: string;
  end_date: string;
  url_external?: string;
}

const obtainDomainImage = function (url_image: string, baseUrl: string) {
  return `${baseUrl}/show?name=${url_image}`;
};

const collapsibleView = 40;
const InfoEventsNewsCard = function ({
  title,
  description,
  url_image,
  start_date,
  end_date,
  url_external,
}: Props) {
  const { t } = useTranslation();
  const { baseUrl } = useGetBaseUrl();

  const [isClamped, setIsClamped] = useState<boolean | number>(true);

  const textDescriptionRef = useRef<HTMLSpanElement | null>(null);

  const { formatDate } = useFormatDate();

  const [image, setImage] = useState(url_image ? obtainDomainImage(url_image, baseUrl) : noPhoto);

  const viewMoreToggleHandler = () => {
    setIsClamped((prev) => !prev);
  };

  const classes = useStyles();

  useEffect(() => {
    if (textDescriptionRef.current?.scrollHeight) {
      setIsClamped(Math.random());
    }
  }, []);

  return (
    <Card className={classes.containerCard}>
      <Grid>
        <CardMedia
          classes={{ img: 'img' }}
          component="img"
          src={image}
          onError={() => setImage(noPhoto)}
          title={title}
          sx={{
            // width: '250px',
            p: 1,
            height: '200px',
            objectFit: 'contain',
            margin: 'auto',
          }}
        />
      </Grid>

      <CardContent sx={{ p: 2, pb: '16px !important' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} alignItems="center">
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h4">{title}</Typography>
              </Grid>
              <Grid item>
                <Collapse in={!isClamped} collapsedSize={collapsibleView}>
                  <Typography
                    variant="body2"
                    ref={textDescriptionRef}
                    align="justify"
                    sx={{
                      overflow: 'hidden',
                      // wordWrap: 'break-word',
                      // textOverflow: 'ellipsis',
                      // display: '-webkit-box',
                      // WebkitLineClamp: defaultClamp,
                      // WebkitBoxOrient: 'vertical',
                      // height: '3em',
                      // height: 'auto',
                    }}
                  >
                    {description}
                  </Typography>
                </Collapse>
              </Grid>

              <Grid item xs={12}>
                {textDescriptionRef.current &&
                  (textDescriptionRef.current.scrollHeight > collapsibleView || !isClamped) && (
                    <Button
                      onClick={viewMoreToggleHandler}
                      endIcon={isClamped ? <IconChevronDown /> : <IconChevronUp />}
                    >
                      <Typography variant="h6">
                        {isClamped
                          ? t('shared.input.view_more.btn')
                          : t('shared.input.view_less.btn')}
                      </Typography>
                    </Button>
                  )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Typography variant="caption">{t('shared.events.start_date')}</Typography>
                <Typography variant="h6">{formatDate(start_date)}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="caption">{t('shared.events.end_date')}</Typography>
                <Typography variant="h6">{formatDate(end_date)}</Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} className={classes.separate}>
            <Button variant="outlined" color="primary" startIcon={<ChatBubbleTwoToneIcon />}>
              <Typography variant="h5">
                {' '}
                {t('shared.events.publish_date')} {formatDate(start_date)}
              </Typography>
            </Button>
          </Grid>
          {url_external && url_external !== '' && (
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => window.open(url_external, '_blank')}
              >
                {t('shared.events.view')}
              </Button>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default InfoEventsNewsCard;
