import * as Yup from 'yup';

export const forumSchema = Yup.object().shape({
  actDescription: Yup.string().required('El tema es requerido.'),
  dtaQuestion: Yup.string().required('La descripción es requerida.'),
  cactStartDate: Yup.string()
    .required('La fecha es requerida.')
    .typeError('La fecha debe ser una fecha válida.'),
  cactEndDate: Yup.string()
    .required('La fecha es requerida.')
    .typeError('La fecha debe ser una fecha válida.'),
  cactIsExtemporaneous: Yup.boolean().required('Este valor es requerido'),
  actIsScore: Yup.boolean().required('Este dato es requerido'),
  // studentId: Yup.array().min(1).required('requerido'),
  studentId: Yup.array()
    .min(0, 'Ingresa minimo un estudiante')
    .of(
      Yup.array()
        .min(0, 'Ingresa minimo un estudiante')
        .of(
          Yup.object().shape({
            id: Yup.number(),
            groupId: Yup.number(),
          })
        )
    ),
  distributionId: Yup.mixed()
    .nullable()
    .when('actIsScore', {
      is: true,
      then: Yup.string().nullable().required('La distribución es requerida en un foro evaluado'),
    }),
  supplyId: Yup.mixed().when('actIsScore', {
    is: true,
    then: Yup.string().nullable(),
  }),
  actScoreActivity: Yup.mixed()
    .nullable()
    .notRequired()
    .when('actIsScore', {
      is: true,
      then: Yup.number().nullable().required('La nota es requerida en un foro evaluado'),
    }),
  // act_score_activity: Yup.number().required(''),
});
