import * as Yup from 'yup';

export const EventNewSchema = Yup.object().shape({
  type: Yup.string().required('El tipo de evento es requerido.'),
  title: Yup.string().required('El título de evento es requerido.'),
  fileCount: Yup.number().min(1, 'Debe subir una imagen.'),
  description: Yup.string().required('Descripción de evento es requerido.'),
  start_date: Yup.date()
    .typeError('Ingrese una fecha válida.')
    .required('La fecha de inicio es requerida.'),
  end_date: Yup.date()
    .typeError('Ingrese una fecha válida.')
    .required('La fecha de fin es requerida.'),
  profiles: Yup.array().of(Yup.number()).min(1, 'Debe seleccionar al menos un perfil.'),
});
