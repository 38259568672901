import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  Autocomplete,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  TextField,
  // Switch,
  // FormControlLabel,
} from '@material-ui/core';

import { useFormik } from 'formik';

import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import InputLabel from '../../../shared/components/forms/InputLabel';

import { useAppSelector } from '../../../../hooks/useRedux';
import { useAddGroupAreaMutation } from '../slices/groupAreasApiSlice';
// import { useGetAvailablesSubjectsNivelationQuery } from '../../matters/slices/mattersApiSlice';

import { GroupAreaSchema } from '../constants/validation-schemas';
import { statusAvailables } from '../../../shared/constants/resourceStatus';

const AddPeriodForm = () => {
  const history = useHistory();

  const { currentProfile } = useAppSelector((state) => state.access);

  const [showAlerts, setShowAlerts] = useState(true);

  const [addGroupArea, { isLoading, isError, error, isSuccess }] = useAddGroupAreaMutation();

  // const { data: subjectsNivelationAvailables = [], isLoading: isLoadingSubjects } =
  //   useGetAvailablesSubjectsNivelationQuery({
  //     profileId: currentProfile?.profileId!,
  //   });

  const { getFieldProps, errors, touched, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      argName: '',
      argDescription: '',
      subjects: [],
      statusId: '1',
    },
    validationSchema: GroupAreaSchema,
    onSubmit: async (values) => {
      await addGroupArea({
        profileId: currentProfile?.profileId!,
        groupAreaPayload: {
          arg_name: values.argName.toUpperCase(),
          arg_description: values.argDescription,
          arg_keywords: values.argName.toLowerCase().replaceAll(' ', '-'),
          subjects: values.subjects,
          status_id: Number(values.statusId),
        },
      });

      setShowAlerts(true);
    },
  });

  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction="column" gap={2}>
        <Grid item>
          {isError && showAlerts && (
            <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
          )}

          {isSuccess && showAlerts && (
            <SuccessAlert
              message="El Grupo ha sido creado correctamente."
              handleDismiss={() => setShowAlerts(false)}
            />
          )}
        </Grid>

        <Grid item container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <InputLabel>Nombre</InputLabel>
            <TextField
              fullWidth
              error={Boolean(errors.argName && touched.argName)}
              placeholder="Ingrese el nombre del grupo"
              {...getFieldProps('argName')}
            />
            {errors.argName && touched.argName && (
              <FormHelperText error>{errors.argName}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12}>
            <InputLabel>Descripcion</InputLabel>
            <TextField
              fullWidth
              error={Boolean(errors.argDescription && touched.argDescription)}
              placeholder="Ingrese descripcion del grupo"
              {...getFieldProps('argDescription')}
            />
            {errors.argDescription && touched.argDescription && (
              <FormHelperText error>{errors.argDescription}</FormHelperText>
            )}
          </Grid>

          {/* <Grid item xs={12}>
            <InputLabel>Materias</InputLabel>
            <Autocomplete
              multiple
              options={subjectsNivelationAvailables}
              loading={isLoadingSubjects}
              loadingText="Cargando materias disponibles..."
              getOptionLabel={(option) => option.mat_name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(errors.subjects && touched.subjects)}
                  placeholder="Seleccione las materias disponibles"
                />
              )}
              onChange={(_, values) =>
                setFieldValue(
                  'subjects',
                  [...values.map((v) => ({ subject_id: v.id, status_id: v.status_id }))] || ''
                )
              }
              fullWidth
            />
            {errors.subjects && touched.subjects && (
              <FormHelperText error>{errors.subjects}</FormHelperText>
            )}
          </Grid> */}

          <Grid item xs={12}>
            <InputLabel>Estado</InputLabel>
            <Autocomplete
              options={statusAvailables}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField {...params} error={Boolean(errors.statusId && touched.statusId)} />
              )}
              defaultValue={statusAvailables[0]}
              onChange={(e, value) => setFieldValue('statusId', value?.id || '')}
              fullWidth
              clearIcon={null}
            />
            {errors.statusId && touched.statusId && (
              <FormHelperText error>{errors.statusId}</FormHelperText>
            )}
          </Grid>

          <Grid
            item
            container
            alignItems="center"
            justifyContent="flex-end"
            spacing={2}
            sx={{ mt: 1 }}
          >
            <Grid item>
              <Button
                variant="outlined"
                size="large"
                color="primary"
                onClick={() => history.goBack()}
              >
                Regresar
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                size="large"
                color="primary"
                disabled={isLoading}
                endIcon={isLoading && <CircularProgress size={20} />}
              >
                Agregar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default AddPeriodForm;
