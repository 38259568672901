import { IconButton, IconButtonProps, Tooltip, useTheme } from '@material-ui/core';
import { IconTrash, TablerIcon } from '@tabler/icons';

import { useTranslation } from 'react-i18next';

interface Props extends Partial<IconButtonProps> {
  disabled?: boolean;
  CustomIcon?: TablerIcon | null;
  handleDelete?: () => void;
}

const DeleteButton = ({ disabled, CustomIcon, handleDelete }: Props) => {
  const theme = useTheme();

  const { t } = useTranslation();

  return (
    <Tooltip title={t('shared.delete_button.tooltip')}>
      <IconButton
        disabled={disabled || false}
        {...(!disabled && { color: 'error' })}
        aria-label="Eliminar"
        onClick={handleDelete}
      >
        {CustomIcon ? (
          <CustomIcon {...(!disabled && { color: theme.palette.error.main })} size={20} />
        ) : (
          <IconTrash {...(!disabled && { color: theme.palette.error.main })} size={20} />
        )}
      </IconButton>
    </Tooltip>
  );
};

export default DeleteButton;
