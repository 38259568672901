import { format } from 'date-fns';
import { ElectronicDocumentPayload } from '../interfaces/electronicDocuments.interfaces';

export const electronicDocumentValues: ElectronicDocumentPayload = {
  fecha_emision_ini: new Date(),
  fecha_emision_fin: new Date(),
  fechaEmision: JSON.stringify([
    format(new Date(), 'yyyy-MM-dd'),
    format(new Date(), 'yyyy-MM-dd'),
  ]),
  type_document: '',
  no_document: '',
  search: '',
  fecha_pago_ini: null,
  fecha_pago_fin: null,
  fechaPagoDocumento: '',
  fecha_autorizacion_ini: null,
  fecha_autorizacion_fin: null,
  fechaAutorizacionSRI: '',
  status_payment: '',
  status_document: '',
};
