import {
  Button,
  CircularProgress,
  Dialog,
  DialogProps,
  Grid,
  Typography,
  useTheme,
  Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { IconCircleCheck, IconFileInfo, IconFileAlert } from '@tabler/icons';
import { useTranslation } from 'react-i18next';

import TimerAutoRedirect from '../TimerAutoRedirect';

const useStyles = makeStyles((theme) => ({
  paper: { maxWidth: '600px', width: '90%' },
}));

interface Props extends DialogProps {
  onClose: () => void;
  handleDelete: () => void;
  isLoading: boolean;

  /** Si se envia el isSuccess y el isError en true ambos, el icono sera el de error */
  isSuccess: boolean;
  isError: boolean;
  error: string;
  deleteTitle?: string;
  deleteSubTitle?: string;
  deleteSuccess?: string;
  /** si es true no se muestra el titulo */
  noTitle?: boolean;
  /** si es true no se muestra el subtitulo */
  noSubtitle?: boolean;
  /** Texto de reemplazo del boton de confirmar el delete */
  deleteBtnText?: string;
  /**Para determinar si el modal se cierra por boton y redirige a la pantalla principal */
  isAutoClose?: boolean;
  /**Para ejecutar una funcion al finalizar el timer */
  onCompleteAutoCloseFn?: () => void;
  /**Para determinar cada que intervalo pinta el linearProgress */
  customIntervalTime?: number;
  /**Para determinar cuanto se agrega en cada intervalo */
  customAddTime?: number;
  /**
   * Custom action al ser exitosa la peticion
   */
  customSuccessAction?: () => void;
  customSuccessBtnText?: string;
}

const DeleteAlert = ({
  onClose,
  handleDelete,
  onCompleteAutoCloseFn,
  isLoading,
  isSuccess,
  isError,
  error,
  noTitle,
  noSubtitle,
  deleteTitle,
  deleteSubTitle,
  deleteSuccess,
  deleteBtnText,
  isAutoClose,
  customSuccessAction,
  customSuccessBtnText,
  ...props
}: Props) => {
  const { t } = useTranslation();

  const classes = useStyles();
  const theme = useTheme();
  const title = deleteTitle !== undefined ? deleteTitle : t('alerts.delete.title');
  const subtitle = deleteSubTitle !== undefined ? deleteSubTitle : t('alerts.delete.subtitle');
  const success = deleteSuccess !== undefined ? deleteSuccess : t('alerts.delete.success');

  console.log('deleteTitle', deleteTitle);

  return (
    <Dialog {...props} classes={{ paper: classes.paper }} sx={{ p: 3 }} onClose={onClose}>
      <Grid container gap={4} sx={{ p: 2 }}>
        <Grid item container xs={12} alignItems="center" justifyContent="center">
          {!isError && !isSuccess && !isLoading && (
            <IconFileInfo
              size={60}
              color={theme.palette.error.main}
              style={{
                padding: 12,
                backgroundColor: theme.palette.orange.light,
                borderRadius: '50%',
              }}
            />
          )}
          {isLoading && <CircularProgress size={50} />}
          {isSuccess && !isError && (
            <IconCircleCheck
              size={60}
              color={theme.palette.success.main}
              style={{
                padding: 12,
                backgroundColor: theme.palette.success.light,
                borderRadius: '50%',
              }}
            />
          )}
          {isError && !isSuccess && (
            <IconFileAlert
              size={60}
              color={theme.palette.error.main}
              style={{
                padding: 12,
                backgroundColor: theme.palette.orange.light,
                borderRadius: '50%',
              }}
            />
          )}

          {isError && isSuccess && (
            <IconFileAlert
              size={60}
              color={theme.palette.error.main}
              style={{
                padding: 12,
                backgroundColor: theme.palette.orange.light,
                borderRadius: '50%',
              }}
            />
          )}
        </Grid>
        <Grid item xs={12} textAlign="center">
          {!noTitle && (
            <Typography variant="h2" sx={{ color: theme.palette.primary.main, mb: 1 }}>
              {!isError && !isSuccess && title}
              {isSuccess && success}
              {isError && error}
            </Typography>
          )}
          {!noSubtitle && (
            <Typography variant="body2">
              {!isError && !isSuccess && subtitle}
              {isError && error}
            </Typography>
          )}
        </Grid>
        <Grid item container xs={12} justifyContent="center" gap={1}>
          {!isSuccess && !isError && (
            <Button
              variant="text"
              size="large"
              color="primary"
              onClick={onClose}
              disabled={isLoading}
            >
              {t('alerts.no_cancel')}
            </Button>
          )}

          {(isSuccess || isError) && (
            <>
              {isAutoClose && isSuccess ? (
                <Box mt={1} sx={{ width: '60%' }}>
                  <Typography variant="body2" component="span">
                    {t('alerts.redirect')}
                  </Typography>
                  <TimerAutoRedirect completeFunction={onCompleteAutoCloseFn} />
                </Box>
              ) : (
                <>
                  {customSuccessAction ? (
                    <Button
                      variant="contained"
                      size="large"
                      color="primary"
                      onClick={customSuccessAction}
                    >
                      {customSuccessBtnText}
                    </Button>
                  ) : (
                    <Button variant="contained" size="large" color="primary" onClick={onClose}>
                      {t('alerts.continue')}
                    </Button>
                  )}
                </>
              )}
            </>
          )}

          {!isSuccess && !isError && (
            <Button
              variant="contained"
              size="large"
              onClick={handleDelete}
              color="primary"
              autoFocus
              disabled={isLoading}
            >
              {!!deleteBtnText ? deleteBtnText : t('alerts.delete.yes_delete')}
            </Button>
          )}
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default DeleteAlert;
