import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISearchTable } from '../../../shared/interfaces/paginatedTable.interface';
import { ParameterCompanyData } from '../interfaces/ParameterCompany';

interface ParameterCompanyState {
  syllabusTable: ISearchTable;
  isOpenModalAdd: boolean;
  isOpenModalEdit: boolean;
  isOpenModalDelete: boolean;
  pcIdSelected: number | null;
  parametersCompanyData: ParameterCompanyData[];
}

const initialState: ParameterCompanyState = {
  syllabusTable: {
    page: 1,
    perPage: 5,
    search: '',
  },
  isOpenModalAdd: false,
  isOpenModalEdit: false,
  isOpenModalDelete: false,
  pcIdSelected: null,
  parametersCompanyData: [],
};

const ParameterSlice = createSlice({
  name: 'parameterCompany',
  initialState,
  reducers: {
    updateParameterTable: (state, action: PayloadAction<ISearchTable>) => {
      state.syllabusTable = action.payload;
    },
    setIsOpenModalAdd: (state, action: PayloadAction<boolean>) => {
      state.isOpenModalAdd = action.payload;
    },
    setIsOpenModalEdit: (state, action: PayloadAction<boolean>) => {
      state.isOpenModalEdit = action.payload;
    },
    setPcIdSelected: (state, action: PayloadAction<number | null>) => {
      state.pcIdSelected = action.payload;
    },
    setParametersCompanyData: (state, action: PayloadAction<ParameterCompanyData[]>) => {
      state.parametersCompanyData = action.payload;
    },
    setIsOpenModalDelete: (state, action: PayloadAction<boolean>) => {
      state.isOpenModalDelete = action.payload;
    },
  },
});

export const {
  updateParameterTable,
  setIsOpenModalAdd,
  setIsOpenModalEdit,
  setPcIdSelected,
  setParametersCompanyData,
  setIsOpenModalDelete,
} = ParameterSlice.actions;

export default ParameterSlice.reducer;
