import { Grid, Typography } from '@material-ui/core';
//import { useAppSelector } from '../../../../hooks/useRedux';
import MainCard from '../../../../shared/components/cards/MainCard';
import Visits from '../../components/presenciales/Visits';

const ReportsScreen = () => {
  //const { authorToDelete } = useAppSelector((state) => state.authors);
  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">Totales por Género</Typography>
      </Grid>
      <Visits />
    </Grid>
  );
};

export default ReportsScreen;
