import { middlewareApi } from '../../../middleware/middlewareApi';
import { mainApi } from '../../../../api/mainApi';

import {
  InvestigationLineResponse,
  InvestigationLinePayload,
  InvestigationLine,
} from '../interfaces/investigationLine.interfaces';
import {
  GroupResearchPayload,
  LineGroupResearchAllAvailables,
} from '../interfaces/groupResearch.interfaces';
import { CollaboratorGroupResearchPayload } from '../interfaces/collaboratorGroupResearch.interfaces';
import { FinancialSourceAvailables } from '../interfaces/projectResearch.interfaces';
import { AccessState } from '../../../../types';
import { GroupResearchResponse, GroupResearch } from '../interfaces/groupResearch.interfaces';
import {
  ProjectResponse,
  ProjectResearchPayload,
  Project,
} from '../interfaces/projectResearch.interfaces';
import { CollaboratorProjectPayload } from '../interfaces/collaboratorProjectResearch.interfaces';
import { StudentProjectPayload } from '../interfaces/studentProjectResearch.interfaces';
import { ProjectsDocument } from '../interfaces/projectsDocuments.interfaces';
import { AssignStudentsProject } from '../interfaces/assignStudentsProject.interfaces';
import { AssignCollaboratorProject } from '../interfaces/assignCollaboratorProject.interfaces';
import { ApplicationResponse } from '../interfaces/applications.interfaces';
import { ApplicationPayload } from '../interfaces/applicationsPayload.interfaces';
import {
  ProjectPrincipalResponse,
  ProjectPrincipalPayload,
} from '../interfaces/projectPrincipal.interfaces';
import { IResponse } from '../../../shared/interfaces/response.interface';
import { ProjectTask, ProjectTaskPayload } from '../interfaces/tasks.interfaces';
import { AssingStudentTask } from '../components/projects/tasks/interfaces/studentsAssigns.interfaces';
import { CollaboratorByProjectID } from '../interfaces/CollaboratorByProjectId.interface';

export const InvestigationLinesTags = middlewareApi.enhanceEndpoints({
  addTagTypes: ['InvestigationLines', 'projectInfo', 'ProjectTasks', 'Collaborators'],
});

export const InvestigationLinesApi = InvestigationLinesTags.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * @GET investigation lines response
     */
    getInvestigationLines: builder.query<
      InvestigationLineResponse,
      {
        profileId?: number;
        page?: number;
        size?: number;
        sort?: string;
        type_sort: string;
        search: string;
      }
    >({
      queryFn: async ({ profileId, page, size, sort, type_sort, search }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = profileId || currentProfile?.profileId;

          const { data } = await mainApi.get<InvestigationLineResponse>(`/line-research`, {
            params: {
              user_profile_id,
              page,
              size,
              sort,
              type_sort,
              search,
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'InvestigationLines', id } as const)),
              { type: 'InvestigationLines', id: 'LIST' },
            ]
          : [{ type: 'InvestigationLines', id: 'LIST' }],
    }),

    getCollaboratorsByProjectId: builder.query<
      CollaboratorByProjectID,
      {
        project_id: string;
        page?: number;
        size?: number;
        sort?: string;
        type_sort: string;
        search: string;
      }
    >({
      queryFn: async ({ project_id, page, size, sort, type_sort, search }) => {
        try {
          const { data } = await mainApi.get<CollaboratorByProjectID>(
            `/projects-research/${project_id}/collaborators-education-level`,
            {
              params: {
                page,
                size,
                sort,
                type_sort,
                search,
              },
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) => [{ type: 'InvestigationLines', id: 'LIST' }],
    }),

    /**
     * @GET investigation all available lines response
     */
    getInvestigationLinesAllAvailables: builder.query<
      InvestigationLine[],
      {
        profileId?: number;
      }
    >({
      queryFn: async ({ profileId }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = profileId || currentProfile?.profileId;

          const { data } = await mainApi.get<InvestigationLine[]>(`/line-research`, {
            params: {
              data: 'all',
              user_profile_id,
            },
          });

          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) => [{ type: 'InvestigationLines', id: 'LIST' }],
    }),

    getInvestigationLinesByEducationLevel: builder.query<
      InvestigationLine[],
      {
        educationLevel: number | string;
        profileId?: number | string;
      }
    >({
      queryFn: async ({ profileId, educationLevel }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = profileId || currentProfile?.profileId;

          const { data } = await mainApi.get<InvestigationLine[]>(
            `line-research/${educationLevel}`,
            {
              params: {
                data: 'all',
                user_profile_id,
              },
            }
          );

          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) => [{ type: 'InvestigationLines', id: 'LIST' }],
    }),

    /**
     * @GET financial sources all available lines response
     */
    getFinancialSourcesAllAvailables: builder.query<
      FinancialSourceAvailables[],
      {
        profileId?: number;
      }
    >({
      queryFn: async ({ profileId }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = profileId || currentProfile?.profileId;

          const { data } = await mainApi.get<FinancialSourceAvailables[]>(`/financials-sources`, {
            params: {
              data: 'all',
              user_profile_id,
            },
          });

          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) => [{ type: 'InvestigationLines', id: 'FINANCIAL-SOURCE-LIST' }],
    }),

    /**
     * @DELETE remove line research
     */
    deleteInvestigationLine: builder.mutation<
      InvestigationLine,
      { profileId?: number; investigationLineId: string }
    >({
      queryFn: async ({ profileId, investigationLineId }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = profileId || currentProfile?.profileId;

          const { data } = await mainApi.delete(`line-research/${investigationLineId}`, {
            data: {
              user_profile_id,
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'InvestigationLines', id: 'LIST' }],
    }),

    /**
     * @DELETE remove research group
     */
    deleteResearchGroup: builder.mutation<
      GroupResearch,
      { profileId?: number; groupResearchId: string }
    >({
      queryFn: async ({ profileId, groupResearchId }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = profileId || currentProfile?.profileId;

          const { data } = await mainApi.delete(`group-research/${groupResearchId}`, {
            data: {
              user_profile_id,
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'InvestigationLines', id: 'RESEARCH-GROUP-LIST' }],
    }),

    /**
     * @DELETE remove project
     */
    deleteProject: builder.mutation<Project, { profileId?: number; projectId: string }>({
      queryFn: async ({ profileId, projectId }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = profileId || currentProfile?.profileId;

          const { data } = await mainApi.delete(`/projects-research/${projectId}`, {
            data: {
              user_profile_id,
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'InvestigationLines', id: 'PROJECT-LIST' }],
    }),

    /**
     * @DELETE remove project
     */
    deleteProjectPrincipal: builder.mutation<Project, { profileId?: number; projectId: string }>({
      queryFn: async ({ profileId, projectId }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = profileId || currentProfile?.profileId;

          const { data } = await mainApi.delete(`/project-research-principal/${projectId}`, {
            data: {
              user_profile_id,
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'InvestigationLines', id: 'PROJECT-LIST' }],
    }),

    /**
     * @DELETE remove student
     */
    deleteStudentByProject: builder.mutation<
      Project,
      { profileId?: number; studentProjectResearchId: string }
    >({
      queryFn: async ({ profileId, studentProjectResearchId }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = profileId || currentProfile?.profileId;

          const { data } = await mainApi.delete(
            `/students-project-research/${studentProjectResearchId}`,
            {
              data: {
                user_profile_id,
              },
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'InvestigationLines', id: 'PROJECT-LIST-STUDENTS' }],
    }),

    /**
     * @DELETE remove student assign task
     */
    deleteStudentByTask: builder.mutation<
      Project,
      { profileId?: number; studentProjectResearchId: string }
    >({
      queryFn: async ({ profileId, studentProjectResearchId }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = profileId || currentProfile?.profileId;

          const { data } = await mainApi.delete(
            `/students-project-research-details/${studentProjectResearchId}`,
            {
              data: {
                user_profile_id,
              },
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'ProjectTasks', id: 'LIST-STUDENTS-TASK' }],
    }),

    /**
     * @DELETE remove collaborator
     */
    deleteCollaboratorByProject: builder.mutation<
      Project,
      { profileId?: number; collaboratorProjectResearchId: string }
    >({
      queryFn: async ({ profileId, collaboratorProjectResearchId }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = profileId || currentProfile?.profileId;

          const { data } = await mainApi.delete(
            `/collaborators-project-research/${collaboratorProjectResearchId}`,
            {
              data: {
                user_profile_id,
              },
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [
        { type: 'InvestigationLines', id: 'PROJECT-LIST-COLLABORATORS' },
        { type: 'projectInfo', id: 'LIST' },
        { type: 'Collaborators', id: 'LIST' },
      ],
    }),

    /**
     * @DELETE remove collaborator group
     */
    deleteCollaboratorGroup: builder.mutation<
      CollaboratorGroupResearchPayload,
      { profileId?: number; collaboratorGroupId: string }
    >({
      queryFn: async ({ profileId, collaboratorGroupId }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = profileId || currentProfile?.profileId;

          const { data } = await mainApi.delete(
            `/collaborator-group-research/${collaboratorGroupId}`,
            {
              data: {
                user_profile_id,
              },
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'InvestigationLines', id: 'RESEARCH-GROUP-LIST' }],
    }),

    /**
     * @DELETE remove document project
     */
    deleteDocumentProject: builder.mutation<Project, { profileId?: number; documentId: string }>({
      queryFn: async ({ profileId, documentId }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = profileId || currentProfile?.profileId;

          const { data } = await mainApi.delete(`projects-research-documents/${documentId}`, {
            data: {
              user_profile_id,
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'InvestigationLines', id: 'RESEARCH-PROJECTS-DOCUMENTS-LIST' }],
    }),

    /**
     * @GET compliance parametres all available lines response
     */
    getComplianceParametersAllAvailables: builder.query<
      FinancialSourceAvailables[],
      {
        profileId?: number;
      }
    >({
      queryFn: async ({ profileId }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = profileId || currentProfile?.profileId;

          const { data } = await mainApi.get<FinancialSourceAvailables[]>(
            `/compliance-parameters`,
            {
              params: {
                data: 'all',
                user_profile_id,
              },
            }
          );

          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) => [{ type: 'InvestigationLines', id: 'COMPLIANCE-LIST' }],
    }),

    /**
     * @GET Community engagement
     */
    getCommunityEngagementApplications: builder.query<
      ApplicationResponse,
      {
        profileId?: number;
        page?: number;
        sort?: string;
        perPage?: number;
        type_sort?: string;
        search: string;
        projectId: string;
      }
    >({
      queryFn: async (
        { profileId, page, sort, type_sort, search, perPage, projectId },
        { getState }
      ) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = profileId || currentProfile?.profileId;

          const { data } = await mainApi.get<ApplicationResponse>(
            `/community-engagement-applications/${projectId}`,
            {
              params: {
                user_profile_id,
                page,
                size: perPage,
                sort,
                type_sort,
                search,
              },
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) => [{ type: 'InvestigationLines', id: 'APPLICATION-LIST' }],
    }),

    /**
     * @GET Research group response
     */
    getResearchGroup: builder.query<
      GroupResearchResponse,
      {
        profileId?: number;
        page?: number;
        size?: number;
        sort?: string;
        type_sort: string;
        search: string;
      }
    >({
      queryFn: async ({ profileId, page, size, sort, type_sort, search }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = profileId || currentProfile?.profileId;

          const { data } = await mainApi.get<GroupResearchResponse>(`/group-research`, {
            params: {
              user_profile_id,
              page,
              size,
              sort,
              type_sort,
              search,
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'InvestigationLines', id } as const)),
              { type: 'InvestigationLines', id: 'RESEARCH-GROUP-LIST' },
            ]
          : [{ type: 'InvestigationLines', id: 'RESEARCH-GROUP-LIST' }],
    }),

    /**
     * @GET group research all available
     */
    getGroupResearchAllAvailables: builder.query<
      LineGroupResearchAllAvailables[],
      {
        profileId?: number;
        education_level?: number;
      }
    >({
      queryFn: async ({ profileId, education_level }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = profileId || currentProfile?.profileId;

          const { data } = await mainApi.get<LineGroupResearchAllAvailables[]>(`/group-research`, {
            params: {
              data: 'all',
              user_profile_id,
              education_level,
            },
          });

          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) => [{ type: 'InvestigationLines', id: 'RESEARCH-GROUP-LIST' }],
    }),

    /**
     * @GET students by research group all available
     */
    getStudentsByResearchProjectAvailables: builder.query<
      AssignStudentsProject[],
      {
        profileId?: number;
        projectId: string;
      }
    >({
      queryFn: async ({ profileId, projectId }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = profileId || currentProfile?.profileId;

          const { data } = await mainApi.get<AssignStudentsProject[]>(
            `/projects-research/${projectId}/students`,
            {
              params: {
                data: 'all',
                user_profile_id,
              },
            }
          );

          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) => [{ type: 'InvestigationLines', id: 'PROJECT-LIST-STUDENTS' }],
    }),

    /**
     * @GET collaborators by research group all available
     */
    getCollaboratorsByResearchProjectAvailables: builder.query<
      AssignCollaboratorProject[],
      {
        profileId?: number;
        projectId: string;
      }
    >({
      queryFn: async ({ profileId, projectId }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = profileId || currentProfile?.profileId;

          const { data } = await mainApi.get<AssignCollaboratorProject[]>(
            `/projects-research/${projectId}/collaborators`,
            {
              params: {
                data: 'all',
                user_profile_id,
              },
            }
          );

          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) => [{ type: 'InvestigationLines', id: 'PROJECT-LIST-COLLABORATORS' }],
    }),

    /**
     * @GET investigation lines response
     */
    getProjectResearch: builder.query<
      ProjectResponse,
      {
        profileId?: number;
        page?: number;
        size?: number;
        sort?: string;
        type_sort: string;
        search: string;
        typeProject?: string;
        statusProject?: string;
        yearProject?: string;
        lineResearchId?: string;
        typeResearch?: string;
      }
    >({
      queryFn: async (
        {
          typeProject,
          statusProject,
          lineResearchId,
          typeResearch,
          yearProject,
          profileId,
          page,
          size,
          sort,
          type_sort,
          search,
        },
        { getState }
      ) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = profileId || currentProfile?.profileId;

          const { data } = await mainApi.get<ProjectResponse>(`/projects-research`, {
            params: {
              user_profile_id,
              page,
              size,
              sort,
              type_sort,
              search,
              ...(typeProject && { type_project: typeProject }),
              ...(statusProject && { status_project: statusProject }),
              ...(lineResearchId && { line_research_id: lineResearchId }),
              ...(typeResearch && { type_research: typeResearch }),
              ...(yearProject && { year_project: yearProject }),
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'InvestigationLines', id } as const)),
              { type: 'InvestigationLines', id: 'PROJECT-LIST' },
            ]
          : [{ type: 'InvestigationLines', id: 'PROJECT-LIST' }],
    }),

    /**
     * @GET project research principal
     */
    getProjectResearchPrincipal: builder.query<
      ProjectPrincipalResponse,
      {
        profileId?: number;
        page?: number;
        size?: number;
        sort?: string;
        type_sort: string;
        search: string;
        typeProject?: string;
        statusProject?: string;
        yearProject?: string;
        lineResearchId?: string;
        typeResearch?: string;
      }
    >({
      queryFn: async (
        {
          typeProject,
          statusProject,
          lineResearchId,
          typeResearch,
          yearProject,
          profileId,
          page,
          size,
          sort,
          type_sort,
          search,
        },
        { getState }
      ) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = profileId || currentProfile?.profileId;

          const { data } = await mainApi.get<ProjectPrincipalResponse>(
            `/project-research-principal`,
            {
              params: {
                user_profile_id,
                page,
                size,
                sort,
                type_sort,
                search,
                ...(typeProject && { type_project: typeProject }),
                ...(statusProject && { status_project: statusProject }),
                ...(lineResearchId && { line_research_id: lineResearchId }),
                ...(typeResearch && { type_research: typeResearch }),
                ...(yearProject && { year_project: yearProject }),
              },
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'InvestigationLines', id } as const)),
              { type: 'InvestigationLines', id: 'PROJECT-LIST' },
            ]
          : [{ type: 'InvestigationLines', id: 'PROJECT-LIST' }],
    }),

    /**
     * @GET project research principal child
     */
    getProjectResearchPrincipalChild: builder.query<
      ProjectResponse,
      {
        profileId?: number;
        page?: number;
        size?: number;
        sort?: string;
        type_sort: string;
        search: string;
        typeProject?: string;
        statusProject?: string;
        yearProject?: string;
        lineResearchId?: string;
        typeResearch?: string;
        projectId: string;
      }
    >({
      queryFn: async (
        {
          typeProject,
          statusProject,
          lineResearchId,
          typeResearch,
          yearProject,
          profileId,
          page,
          size,
          sort,
          type_sort,
          search,
          projectId,
        },
        { getState }
      ) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = profileId || currentProfile?.profileId;

          const { data } = await mainApi.get<ProjectResponse>(
            `project-research-principal/${projectId}/projects`,
            {
              params: {
                user_profile_id,
                page,
                size,
                sort,
                type_sort,
                search,
                ...(typeProject && { type_project: typeProject }),
                ...(statusProject && { status_project: statusProject }),
                ...(lineResearchId && { line_research_id: lineResearchId }),
                ...(typeResearch && { type_research: typeResearch }),
                ...(yearProject && { year_project: yearProject }),
              },
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'InvestigationLines', id } as const)),
              { type: 'InvestigationLines', id: 'PROJECT-LIST' },
            ]
          : [{ type: 'InvestigationLines', id: 'PROJECT-LIST' }],
    }),

    /**
     * @GET Project response
     */
    getProject: builder.query<Project, { projectId: string }>({
      queryFn: async ({ projectId }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = currentProfile?.profileId;

          const { data } = await mainApi.get<Project>(`/projects-research/${projectId}`, {
            params: {
              user_profile_id,
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) => [{ type: 'InvestigationLines', id: 'APPLICATION-LIST' }],
    }),

    /**
     * @GET Project response
     */
    getProjectPrincipal: builder.query<Project, { projectId: string }>({
      queryFn: async ({ projectId }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = currentProfile?.profileId;

          const { data } = await mainApi.get<Project>(`/project-research-principal/${projectId}`, {
            params: {
              user_profile_id,
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) => [{ type: 'InvestigationLines', id: 'APPLICATION-LIST' }],
    }),

    /**
     * @POST create investigation lines
     */
    addInvestigationLines: builder.mutation<
      InvestigationLinePayload,
      { investigationLinePayload: InvestigationLinePayload }
    >({
      queryFn: async ({ investigationLinePayload }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = currentProfile?.profileId;

          const { data } = await mainApi.post<InvestigationLinePayload>(`/line-research`, {
            user_profile_id,
            ...investigationLinePayload,
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'InvestigationLines', id: 'LIST' }],
    }),

    /**
     * @PUT update investigation lines
     */
    updateInvestigationLines: builder.mutation<
      InvestigationLinePayload,
      { investigationLinePayload: InvestigationLinePayload; researchLineId: string }
    >({
      queryFn: async ({ investigationLinePayload, researchLineId }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = currentProfile?.profileId;

          const { data } = await mainApi.put<InvestigationLinePayload>(
            `/line-research/${researchLineId}}`,
            {
              user_profile_id,
              ...investigationLinePayload,
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'InvestigationLines', id: 'LIST' }],
    }),

    /**
     * @PUT update group research
     */
    updateGroupResearch: builder.mutation<
      GroupResearchPayload,
      { groupResearchPayload: GroupResearchPayload; groupResearchId: string }
    >({
      queryFn: async ({ groupResearchPayload, groupResearchId }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = currentProfile?.profileId;

          const { data } = await mainApi.post<GroupResearchPayload>(
            `/group-research/${groupResearchId}`,
            {
              user_profile_id,
              ...groupResearchPayload,
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'InvestigationLines', id: 'RESEARCH-GROUP-LIST' }],
    }),

    /**
     * @PUT update projects research
     */
    updateProjectsResearch: builder.mutation<
      ProjectResearchPayload,
      { projectResearchPayload: ProjectResearchPayload; projectId: string }
    >({
      queryFn: async ({ projectResearchPayload, projectId }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = currentProfile?.profileId;

          const { data } = await mainApi.put<ProjectResearchPayload>(
            `/projects-research/${projectId}`,
            {
              user_profile_id,
              ...projectResearchPayload,
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [
        { type: 'InvestigationLines', id: 'PROJECT-LIST' },
        { type: 'InvestigationLines', id: 'RESEARCH-PROJECTS-DOCUMENTS-LIST' },
      ],
    }),

    /**
     * @PUT update projects principal research
     */
    updateProjectPrincipal: builder.mutation<
      ProjectPrincipalPayload,
      { projectPrincipalResearchPayload: ProjectPrincipalPayload; projectId: string }
    >({
      queryFn: async ({ projectPrincipalResearchPayload, projectId }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = currentProfile?.profileId;

          const { data } = await mainApi.put<ProjectPrincipalPayload>(
            `/project-research-principal/${projectId}`,
            {
              user_profile_id,
              ...projectPrincipalResearchPayload,
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [
        { type: 'InvestigationLines', id: 'PROJECT-LIST' },
        { type: 'InvestigationLines', id: 'RESEARCH-PROJECTS-DOCUMENTS-LIST' },
      ],
    }),

    /**
     * @POST create group research
     */
    addGroupResearch: builder.mutation<
      GroupResearchPayload,
      { groupResearchPayload: GroupResearchPayload }
    >({
      queryFn: async ({ groupResearchPayload }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = currentProfile?.profileId;

          const { data } = await mainApi.post<GroupResearchPayload>(`/group-research`, {
            user_profile_id,
            ...groupResearchPayload,
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'InvestigationLines', id: 'RESEARCH-GROUP-LIST' }],
    }),

    /**
     * @POST add teacher to group research
     */
    addCollaboratorGroupResearch: builder.mutation<
      CollaboratorGroupResearchPayload,
      { collaboratorGroupResearchPayload: CollaboratorGroupResearchPayload }
    >({
      queryFn: async ({ collaboratorGroupResearchPayload }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = currentProfile?.profileId;

          const { data } = await mainApi.post<CollaboratorGroupResearchPayload>(
            `/collaborator-group-research`,
            {
              user_profile_id,
              ...collaboratorGroupResearchPayload,
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'InvestigationLines', id: 'RESEARCH-GROUP-LIST' }],
    }),

    /**
     * @POST approve application
     */
    addApproveApplication: builder.mutation<
      ApplicationPayload,
      { ApplicationPayload: ApplicationPayload }
    >({
      queryFn: async ({ ApplicationPayload }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = currentProfile?.profileId;

          const { data } = await mainApi.post<ApplicationPayload>(
            `/approve-community-engagement-applications`,
            {
              user_profile_id,
              ...ApplicationPayload,
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'InvestigationLines', id: 'APPLICATION-LIST' }],
    }),

    //* PROJECT ENDPOINTS

    /**
     * @GET project research list
     */
    getProjectsResearchAvailables: builder.query<
      Project[],
      {
        profileId?: number;
      }
    >({
      queryFn: async ({ profileId }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = profileId || currentProfile?.profileId;

          const { data } = await mainApi.get<Project[]>(`/projects-research`, {
            params: {
              user_profile_id,
              data: 'all',
            },
          });

          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) => [{ type: 'InvestigationLines', id: 'LIST' }],
    }),

    /**
     * @GET project research documents list
     */
    getDocumentsByProjectsResearch: builder.query<
      ProjectsDocument[],
      {
        profileId?: number;
        projectId: string;
      }
    >({
      queryFn: async ({ profileId, projectId }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = profileId || currentProfile?.profileId;

          const { data } = await mainApi.get<ProjectsDocument[]>(
            `/projects-research/${projectId}/documents`,
            {
              params: {
                user_profile_id,
              },
            }
          );

          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) => [
        { type: 'InvestigationLines', id: 'RESEARCH-PROJECTS-DOCUMENTS-LIST' },
      ],
    }),

    /**
     * @POST add projects research
     */
    addprojectsResearch: builder.mutation<
      ProjectResearchPayload,
      { projectResearchPayload: ProjectResearchPayload }
    >({
      queryFn: async ({ projectResearchPayload }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = currentProfile?.profileId;

          const { data } = await mainApi.post<ProjectResearchPayload>(`/projects-research`, {
            user_profile_id,
            ...projectResearchPayload,
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'InvestigationLines', id: 'RESEARCH-GROUP-LIST' }],
    }),

    /**
     * @POST add teacher to project research
     */
    addCollaboratorProjectResearch: builder.mutation<
      CollaboratorProjectPayload,
      { collaboratorProjectPayload: CollaboratorProjectPayload }
    >({
      queryFn: async ({ collaboratorProjectPayload }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = currentProfile?.profileId;

          const { data } = await mainApi.post<CollaboratorProjectPayload>(
            `/collaborators-project-research`,
            {
              user_profile_id,
              ...collaboratorProjectPayload,
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [
        { type: 'InvestigationLines', id: 'PROJECT-LIST-COLLABORATORS' },
        { type: 'projectInfo', id: 'LIST' },
        { type: 'Collaborators', id: 'LIST' },
      ],
    }),

    /**
     * @POST add student to project research
     */
    addStudentProjectResearch: builder.mutation<
      StudentProjectPayload,
      { studentProjectPayload: StudentProjectPayload }
    >({
      queryFn: async ({ studentProjectPayload }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = currentProfile?.profileId;

          const { data } = await mainApi.post<StudentProjectPayload>(`/students-project-research`, {
            user_profile_id,
            ...studentProjectPayload,
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'InvestigationLines', id: 'PROJECT-LIST-STUDENTS' }],
    }),

    /**
     * @POST add student to task
     */
    addStudentProjectTask: builder.mutation<
      ProjectTaskPayload,
      { studentProjecTasktPayload: ProjectTaskPayload; projectId: string; taskId: number }
    >({
      queryFn: async ({ studentProjecTasktPayload, projectId, taskId }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = currentProfile?.profileId;

          const { data } = await mainApi.post<ProjectTaskPayload>(
            `/task-project-research/${taskId}/students-details`,
            {
              user_profile_id,
              project_research_id: projectId,
              ...studentProjecTasktPayload,
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'ProjectTasks', id: 'LIST' }],
    }),

    /**
     * @GET group research by array lines research
     */
    getGroupResearchByLinesResearch: builder.query<
      LineGroupResearchAllAvailables[],
      {
        profileId?: number;
        linesResearch: Array<string>;
      }
    >({
      queryFn: async ({ profileId, linesResearch }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = profileId || currentProfile?.profileId;

          const { data } = await mainApi.get<LineGroupResearchAllAvailables[]>(
            `/groups-line-research`,
            {
              params: {
                user_profile_id,
                lines_research: linesResearch,
              },
            }
          );

          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) => [{ type: 'InvestigationLines', id: 'LIST' }],
    }),

    /**
     * @GET tasks students assigns
     */
    geStudentsAssignTask: builder.query<
      AssingStudentTask[],
      {
        taskProjectResearch: string;
      }
    >({
      queryFn: async ({ taskProjectResearch }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = currentProfile?.profileId;

          const { data } = await mainApi.get<AssingStudentTask[]>(
            `/task-project-research/${taskProjectResearch}/students-details`,
            {
              params: {
                user_profile_id,
              },
            }
          );

          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) => [{ type: 'ProjectTasks', id: 'LIST-STUDENTS-TASK' }],
    }),

    /**
     * @GET group research all availables
     */
    getTasksByProjectStage: builder.query<
      IResponse<ProjectTask>,
      {
        projectId: string;
        profileId?: number;
        page: number;
        perPage: number;
        search: string;
        sort?: string;
        type_sort?: string;
      }
    >({
      queryFn: async (
        { profileId, page, perPage, search, sort, type_sort, projectId },
        { getState }
      ) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = profileId || currentProfile?.profileId;

          const { data } = await mainApi.get<IResponse<ProjectTask>>(
            `/projects-research/${projectId}/tasks`,
            {
              params: {
                user_profile_id,
                page,
                size: perPage,
                search,
                ...(sort && { sort }),
                ...(type_sort && { type_sort }),
              },
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) => [{ type: 'ProjectTasks', id: 'LIST' }],
    }),
    //
    addTaskToProjectResearch: builder.mutation<
      any,
      {
        title: string;
        observation: string;
        startDate: string;
        endDate: string;
        budget: number;
        numberHours: number;
        projectId: string;
        statusId: number;
      }
    >({
      queryFn: async ({
        title,
        observation,
        startDate,
        endDate,
        budget,
        numberHours,
        projectId,
        statusId,
      }) => {
        try {
          const { data } = await mainApi.post<any>(`/task-project-research`, {
            tas_title: title,
            tas_description: observation,
            tas_start_date: startDate,
            tas_end_date: endDate,
            tas_budget: budget,
            tas_number_hours: numberHours,
            project_research_id: projectId,
            status_id: statusId,
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'ProjectTasks', id: 'LIST' }],
    }),
    editTaskToProjectResearch: builder.mutation<
      any,
      {
        taskId: number;
        title: string;
        observation: string;
        startDate: string;
        endDate: string;
        budget: number;
        numberHours: number;
        projectId: string;
        statusId: number;
      }
    >({
      queryFn: async ({
        taskId,
        title,
        observation,
        startDate,
        endDate,
        budget,
        numberHours,
        projectId,
        statusId,
      }) => {
        try {
          const { data } = await mainApi.put<any>(`/task-project-research/${taskId}`, {
            tas_title: title,
            tas_description: observation,
            tas_start_date: startDate,
            tas_end_date: endDate,
            tas_budget: budget,
            tas_number_hours: numberHours,
            project_research_id: projectId,
            status_id: statusId,
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'ProjectTasks', id: 'LIST' }],
    }),
    deleteTaskToProjectResearch: builder.mutation<
      any,
      {
        taskId: number;
      }
    >({
      queryFn: async ({ taskId }) => {
        try {
          const { data } = await mainApi.delete<any>(`/task-project-research/${taskId}`);

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'ProjectTasks', id: 'LIST' }],
    }),
    //
    addActivityToTask: builder.mutation<
      any,
      {
        title: string;
        observation: string;
        taskId: number;
      }
    >({
      queryFn: async ({ title, observation, taskId }) => {
        try {
          const { data } = await mainApi.post<any>(`/activity-project-research`, {
            act_title: title,
            act_observation: observation,
            task_project_research_id: taskId,
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'ProjectTasks', id: 'LIST' }],
    }),
    editActivityToTask: builder.mutation<
      any,
      {
        title: string;
        observation: string;
        taskId: number;
        activityId: number;
      }
    >({
      queryFn: async ({ activityId, taskId, title, observation }) => {
        try {
          const { data } = await mainApi.put<any>(`/activity-project-research/${activityId}`, {
            act_title: title,
            act_observation: observation,
            task_project_research_id: taskId,
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'ProjectTasks', id: 'LIST' }],
    }),
    deleteActivityToTask: builder.mutation<
      any,
      {
        activityId: number;
      }
    >({
      queryFn: async ({ activityId }) => {
        try {
          const { data } = await mainApi.delete<any>(`/activity-project-research/${activityId}`);

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'ProjectTasks', id: 'LIST' }],
    }),

    //
  }),
});

export const {
  useGetInvestigationLinesQuery,
  useAddInvestigationLinesMutation,
  useAddCollaboratorGroupResearchMutation,
  useAddGroupResearchMutation,
  useAddprojectsResearchMutation,
  useGetInvestigationLinesAllAvailablesQuery,
  useGetGroupResearchAllAvailablesQuery,
  useGetGroupResearchByLinesResearchQuery,
  useGetFinancialSourcesAllAvailablesQuery,
  useGetComplianceParametersAllAvailablesQuery,
  useGetResearchGroupQuery,
  useGetProjectResearchQuery,
  useAddCollaboratorProjectResearchMutation,
  useAddStudentProjectResearchMutation,
  useGetProjectsResearchAvailablesQuery,
  useDeleteInvestigationLineMutation,
  useDeleteResearchGroupMutation,
  useUpdateGroupResearchMutation,
  useUpdateInvestigationLinesMutation,
  useDeleteProjectMutation,
  useUpdateProjectsResearchMutation,
  useGetDocumentsByProjectsResearchQuery,
  useDeleteDocumentProjectMutation,
  useDeleteCollaboratorGroupMutation,
  useGetStudentsByResearchProjectAvailablesQuery,
  useDeleteStudentByProjectMutation,
  useGetCollaboratorsByResearchProjectAvailablesQuery,
  useDeleteCollaboratorByProjectMutation,
  useGetCommunityEngagementApplicationsQuery,
  useGetProjectQuery,
  useAddApproveApplicationMutation,
  useGetProjectResearchPrincipalQuery,
  useGetProjectResearchPrincipalChildQuery,
  useGetProjectPrincipalQuery,
  useGetTasksByProjectStageQuery,
  useAddTaskToProjectResearchMutation,
  useEditTaskToProjectResearchMutation,
  useDeleteTaskToProjectResearchMutation,
  useUpdateProjectPrincipalMutation,
  useAddActivityToTaskMutation,
  useEditActivityToTaskMutation,
  useDeleteActivityToTaskMutation,
  useDeleteProjectPrincipalMutation,
  useAddStudentProjectTaskMutation,
  useGeStudentsAssignTaskQuery,
  useDeleteStudentByTaskMutation,
  useGetCollaboratorsByProjectIdQuery,
  useLazyGetInvestigationLinesByEducationLevelQuery,
} = InvestigationLinesApi;
