import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/useRedux';
import CustomSideDialog from '../../../../../shared/components/dialogs/CustomSideDialog';
import {
  setOpeningDatesIdSelected,
  setOpeningDatesSideDialogOpen,
} from '../slices/openingDateSlice';
import OpeningDateForm from './OpeningDateForm';

const OpeningDateSideDialog = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { openingDatesIdSelected } = useAppSelector((state) => state.openingDates);

  const handleClose = () => {
    dispatch(setOpeningDatesSideDialogOpen(false));
    dispatch(setOpeningDatesIdSelected(null));
  };

  return (
    <CustomSideDialog
      open
      handleCloseDialog={handleClose}
      title={
        openingDatesIdSelected
          ? t('maintenance.opening_dates.edit.title')
          : t('maintenance.opening_dates.add.title')
      }
    >
      <OpeningDateForm />
    </CustomSideDialog>
  );
};

export default OpeningDateSideDialog;
