import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import NotificationsTable from '../component/NotificationsTable';
import MainCard from '../../../shared/components/cards/MainCard';

const FaqCreateScreen = () => {
  const { t } = useTranslation();
  if (false) console.log(t);

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">{t('Notificaciones')}</Typography>
      </Grid>

      <NotificationsTable />
    </Grid>
  );
};

export default FaqCreateScreen;
