import * as Yup from 'yup';

export const CompanyInfoSchema = Yup.object().shape({
  name: Yup.string().required('El nombre es requerido.'),
  website: Yup.string()
  .required('El website es requerido.'),
  email: Yup.string()
  .required('El correo de la compañia es requerido.'),
  statusId: Yup.number(),
  ruc: Yup.string().max(13, 'El RUC debe tener máximo 13 dígitos.'),
  description: Yup.string(),
  assignedSite: Yup.string(),
  facebook: Yup.string(),
  instagram: Yup.string(),
  linkedin: Yup.string(),
  youtube: Yup.string(),
  infoMail: Yup.string(),
  matrix: Yup.string(),
  logo: Yup.string(),
  color: Yup.string(),
  payNotification: Yup.string(),
  businessName: Yup.string(),
  comercialNme: Yup.string(),
  legalNotification: Yup.string(),
  agentLegal: Yup.string(),
  personType: Yup.string(),
  direction: Yup.string(),
  phone: Yup.string(),  
});
