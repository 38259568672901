import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    '@global': {
      // body: {
      //   backgroundColor: 'black',
      // },
      '#launcher': {
        left: '0',
      },
      '#webWidget': {
        left: '0',
      },
    },
  })
);

const MoveZendeskStyles = () => {
  useStyles();

  return null;
};

export default MoveZendeskStyles;
