import { useMemo } from 'react';

import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { format, parseISO } from 'date-fns';

import { useAppSelector } from '../../../../../hooks/useRedux';
import MainCard from '../../../../shared/components/cards/MainCard';

import LaboralExperienceSimpleTable from '../../../requisition-teacher/components/simpleTables/LaboralExperienceSimpleTable';

const JobData = () => {
  const { t } = useTranslation();
  const { collabFile, dataPersonColl } = useAppSelector((state) => state.teacher);

  // const { user } = useAppSelector((state) => state.teacher.collabFile!);

  // //id
  // const { data: identificationsAvailables = [] } = useGetCatalogByAcronymQuery({
  //   acronym: 'TI',
  // });

  // //Ethnics
  // const { data: ethnicsAvailables = [] } = useGetCatalogByAcronymQuery({
  //   acronym: 'ET',
  // });

  // //marital status
  // const { data: civilStatusAvailables = [] } = useGetCatalogByAcronymQuery({
  //   acronym: 'SM',
  // });

  // //religions
  // const { data: religionsAvailables = [] } = useGetCatalogByAcronymQuery({
  //   acronym: 'TR',
  // });

  // // countries
  // const { data: countriesAvailables = [] } = useGetCatalogByAcronymQuery({
  //   acronym: 'PS',
  // });

  // // nacionalities
  // const { data: nacionalities = [] } = useGetCatalogByAcronymQuery({
  //   acronym: 'NA',
  // });

  // //provincia
  // const { data: provincies } = useGetAvailablesProvincesQuery({});

  // //disability
  // const { data: disability = [] } = useGetDisabilityAvailableQuery({});

  const laboralExperience = useMemo(
    () =>
      dataPersonColl.data?.person_job.map((dt) => ({
        ...dt,
        per_job_end_date: dt.per_job_end_date,
        per_job_current: Number(dt.per_job_current),
        per_job_iess_affiliated: Number(dt.per_job_iess_affiliated),
        document_url: dt.per_job_document_url,
        name_institution: dt.name_institution || '',
        subjects_taught: dt.subjects_taught || '',
        city_id: { id: +dt.city_id, name: '' },
        status_id: Number(dt.status_id),
        country_id: Number(dt.country_id),
      })) || [],
    [dataPersonColl.data?.person_job]
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <MainCard /* title={t('academic.teachers_list.file.personal_data.title')} */>
          <Grid container spacing={2}>
            <Grid item xs={12} /* md={6} */>
              <Typography variant="h4" component="span">
                {t('academic.teachers_list.file.working.principal_faculty')}
              </Typography>
              <Typography variant="body2" component="span">
                {collabFile?.education_level_principal?.edu_name}
              </Typography>
            </Grid>

            <Grid item xs={12} /* md={6} */>
              <Typography variant="h4" component="span">
                {t('academic.teachers_list.file.working.position')}
              </Typography>
              <Typography variant="body2" component="span">
                {collabFile?.position?.pos_name}
              </Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant="h4" component="span">
                {t('academic.teachers_list.file.working.start_date')}
              </Typography>
              <Typography variant="body2" component="span">
                {collabFile?.coll_entering_date
                  ? format(parseISO(collabFile.coll_entering_date), 'yyyy-MM-dd')
                  : ''}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h4" component="span">
                {t('academic.teachers_list.file.working.end_date')}
              </Typography>
              <Typography variant="body2" component="span">
                {collabFile?.coll_leaving_date
                  ? format(parseISO(collabFile.coll_leaving_date), 'yyyy-MM-dd')
                  : ''}
              </Typography>
            </Grid>
          </Grid>
        </MainCard>
      </Grid>

      <Grid item xs={12} md={10}>
        <MainCard title={t('academic.requisition.edit.wizard.step2.laboral_experience')}>
          <Grid item xs={12}>
            {dataPersonColl.isLoading ? (
              <>Loading...</>
            ) : (
              <LaboralExperienceSimpleTable
                data={laboralExperience}
                readOnly={true}
                // selectLaboralExperience={selectLaboralExperience}
              />
            )}
          </Grid>
        </MainCard>
      </Grid>
    </Grid>
  );
};

export default JobData;
