import { forwardRef } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {
  DialogTitle,
  Grid,
  IconButton,
  Slide,
  SlideProps,
  Typography,
  useTheme,
  useMediaQuery,
  DialogContent,
} from '@material-ui/core';
import { IconArrowNarrowLeft } from '@tabler/icons';

import { useGetClassroomQuery } from '../../classrooms/slices/classroomsApiSlice';
// import { useTranslation } from 'react-i18next';
import ExtendedSideDialog from '../../../shared/components/dialogs/ExtendedSideDialog';
import ClassroomLoansAssignForm from './ClassroomLoansForm';
import { useAppSelector } from '../../../../hooks/useRedux';

// style constant
const useStyles = makeStyles((theme) => ({
  userAddDialog: {
    '&>div:nth-child(3)': {
      justifyContent: 'flex-end',
      '&>div': {
        margin: '0px',
        borderRadius: '0px',
        maxWidth: '650px',
        maxHeight: '100%',
        '&>div.MuiDialogContent-root>div.scrollbar-container': {
          paddingLeft: '20px',
          overflow: 'hidden',
        },
      },
    },
  },
  paper: {
    minHeight: '100vh',
    width: '37%',
  },
  paperTable: {
    minHeight: '100vh',
    width: '55%!important',
  },
  movilPaper: {
    minHeight: '100vh',
    width: '100%',
  },
}));

// animation
const Transition = forwardRef(function Transition(props: SlideProps, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export interface Props {
  open: boolean;
  handleCloseDialog: (e: React.SyntheticEvent) => void;
  id: number;
  periodId: number;
  offerId: number;
}

const ClassroomLoans = ({ open, handleCloseDialog, id, periodId, offerId }: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const { currentProfile } = useAppSelector((state) => state.access);

  //   const { t } = useTranslation();

  const isSm = useMediaQuery(theme.breakpoints.down('md'));

  // console.log(id);

  const { data: dataClassroom } = useGetClassroomQuery({
    profileId: currentProfile?.profileId!,
    classroomId: id,
  });

  // console.log(dataClassroom);

  return (
    <ExtendedSideDialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCloseDialog}
      className={classes.userAddDialog}
      {...(!isSm && { classes: { paper: classes.paper } })}
      {...(!isSm && { classes: { paper: classes.paperTable } })}
      {...(isSm && { classes: { paper: classes.movilPaper } })}
    >
      <DialogTitle disableTypography>
        <Grid item container alignItems="center" gap={1}>
          <IconButton
            onClick={(e) => {
              handleCloseDialog(e);
            }}
          >
            <IconArrowNarrowLeft size={40} color={theme.palette.text.dark} />
          </IconButton>
          <Typography variant="h2">Préstamos de Aula</Typography>
        </Grid>
      </DialogTitle>
      <DialogContent>
        {open && dataClassroom && (
          <ClassroomLoansAssignForm
            // unitAcademi={}
            collId={id}
            periodId={periodId}
            handleCloseDialog={handleCloseDialog}
            offerId={offerId}
          />
        )}
      </DialogContent>
    </ExtendedSideDialog>
  );
};

export default ClassroomLoans;
