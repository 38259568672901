import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  Chip,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { MatterMesh } from '../../../maintenance/meshs/interfaces/curriculum.interfaces';
import MainCard from '../../../shared/components/cards/MainCard';
import { useState } from 'react';
import { SubjectCurriculumStatus } from '../../subjects-register/interfaces/subjects-approveds';
import { IconChevronDown } from '@tabler/icons';
import SubjectBadge from '../../../maintenance/meshs/components/builder/SubjectBadge';

interface CurriculumItemProps {
  data: MatterMesh;
  status: SubjectCurriculumStatus;
  statusPrerequisite?: SubjectCurriculumStatus[];
}

const CurriculumItem = ({ data, status, statusPrerequisite }: CurriculumItemProps) => {
  const [isHover, setIsHover] = useState(false);
  console.log('statusPrerequisite', statusPrerequisite);

  const getBgColorChip = (
    stKey: string | undefined = undefined,
    stMatter: string | undefined = undefined
  ) => {
    let color = '';

    let statusKeyword = stKey || status?.keyword_status_matter;
    let statusMatter = stMatter || status?.status_matter;

    console.log('statusKeyword', statusKeyword);

    switch (statusKeyword) {
      case 'APR':
        color = '#00AAF5';
        break;

      case 'CUR':
        if (statusMatter === 'Registrado') color = '#608b9fd9';
        else color = '#ffe57f';

        break;

      default:
        color = '#D84315';
        break;
    }

    return color;
  };

  const filterComponents = data.detail_matter_mesh.filter((lc) => +lc.dem_workload !== 0);

  return (
    <Card
      component={MainCard}
      sx={{
        padding: 0,
        border: `1px solid ${data.simbology?.sim_color}`,
        backgroundColor: isHover ? data.simbology?.sim_color : 'transparent',
        '& .MuiCardContent-root': {
          padding: 0,
          height: '100%',
        },
      }}
      // onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <Grid
        container
        flexDirection="row"
        sx={{
          height: '100%',
        }}
      >
        <Grid
          item
          xs={2}
          sx={{
            backgroundColor: data.simbology?.sim_color,
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 2,
          }}
        >
          <Typography
            sx={{
              writingMode: 'vertical-lr',
              transform: 'rotate(180deg)',
            }}
            color="white"
            variant="h4"
          >
            {data.simbology?.sim_description}
          </Typography>
        </Grid>
        <Grid item xs={10} p={2}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h4" color={isHover ? 'white' : 'primary'}>
                {data.matter?.cod_matter_migration} - {data.matter_rename || data.matter?.mat_name}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Chip
                label={status?.status_matter}
                sx={{
                  bgcolor: isHover ? 'white' : getBgColorChip(),
                  color: isHover ? 'primary.main' : 'white',
                }}
                size="small"
                // icon={
                //   <Box
                //     sx={{
                //       width: 14,
                //       height: 14,
                //       borderRadius: '50%',
                //       bgcolor: getBgColorChip(),
                //     }}
                //   />
                // }
              />
            </Grid>
            {/* <Grid item xs={12}>
              <Chip
                label={data.simbology?.sim_description}
                sx={{
                  bgcolor: isHover ? 'white' : data.simbology?.sim_color,
                  color: isHover ? 'primary.main' : 'white',
                }}
                size="small"
              />
            </Grid> */}
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                {filterComponents.map((lc) => (
                  <SubjectBadge
                    key={lc.id}
                    label={lc.component.com_acronym}
                    number={+lc.dem_workload}
                    tooltip={lc.component.com_name}
                  />
                ))}
                <SubjectBadge
                  label="Total"
                  number={filterComponents.reduce((acc, curr) => acc + +curr.dem_workload, 0)}
                  tooltip="Total de horas"
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Accordion
                sx={{
                  backgroundColor: 'transparent',
                }}
                defaultExpanded={data.matter_mesh_prerequisites?.length === 0}
              >
                {data.matter_mesh_prerequisites?.length > 0 && (
                  <AccordionSummary
                    sx={{
                      '& .MuiAccordionSummary-content': {
                        margin: 0,
                      },
                    }}
                    expandIcon={
                      data.matter_mesh_prerequisites?.length === 0 ? null : <IconChevronDown />
                    }
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Chip
                        label={data.matter_mesh_prerequisites?.length}
                        sx={{
                          backgroundColor:
                            data.matter_mesh_prerequisites.length === 0
                              ? 'gray.200'
                              : 'primary.main',
                          color: isHover ? 'primary.main' : 'white',
                          marginRight: 1,
                        }}
                        size="small"
                      />
                      <Typography variant="body1" color={isHover ? 'white' : 'primary'}>
                        Prerrequisito
                      </Typography>
                    </Box>
                  </AccordionSummary>
                )}
                <AccordionDetails>
                  <List>
                    {data.matter_mesh_prerequisites?.length === 0 && (
                      <Typography
                        variant="body2"
                        fontSize={12}
                        color={isHover ? 'white' : 'primary'}
                      >
                        No aplica prerrequisitos
                      </Typography>
                    )}
                    {data.matter_mesh_prerequisites?.map((prerequisite) => (
                      <ListItem key={prerequisite.id}>
                        <ListItemText>
                          <Typography
                            variant="body2"
                            fontSize={12}
                            color={isHover ? 'white' : 'primary'}
                          >
                            {prerequisite.matter?.cod_matter_migration} -{' '}
                            {prerequisite.matter_rename || prerequisite.matter?.mat_name}
                          </Typography>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              flexWrap: 'wrap',
                              gap: 1,
                            }}
                          >
                            <Chip
                              label={
                                statusPrerequisite?.find(
                                  (st) => +st.subject_curriculum_id === +prerequisite.id
                                )?.status_matter
                              }
                              sx={{
                                bgcolor: isHover
                                  ? 'white'
                                  : getBgColorChip(
                                      statusPrerequisite?.find(
                                        (st) => +st.subject_curriculum_id === +prerequisite.id
                                      )?.keyword_status_matter,
                                      statusPrerequisite?.find(
                                        (st) => +st.subject_curriculum_id === +prerequisite.id
                                      )?.status_matter
                                    ),
                                color: isHover ? 'primary.main' : 'white',
                              }}
                              size="small"
                            />
                            <Chip label={`Semestre ${prerequisite.group}`} size="small" />
                            {/* <Typography
                                variant="body2"
                                fontSize={12}
                                color={isHover ? 'white' : 'primary'}
                              >
                                (Semestre {prerequisite.group})
                              </Typography> */}
                          </Box>
                        </ListItemText>
                      </ListItem>
                    ))}
                  </List>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default CurriculumItem;
