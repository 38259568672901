import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Autocomplete from '@material-ui/core/Autocomplete';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import InputLabel from '../../../../../shared/components/forms/InputLabel';
import {
  PublicationStepsPayload,
  AuthorsScientificPublication,
} from '../../../interfaces/publicationsPayload.interfaces';
import { AssignmentsSchema } from '../../../constants/validationSchemas';
import { useGetInvestigationLinesAllAvailablesQuery } from '../../../../lines-of-investigation/slices/InvestigationLinesApiSlice';
import { LinesGroupResearch } from '../../../interfaces/publicationsPayload.interfaces';
import { Publication } from '../../../interfaces/publications.interfaces';
import TextFieldFetching from '../../../../../shared/components/TextFieldFetching';
import { ProjectDocuments } from '../../../../lines-of-investigation/interfaces/projectResearch.interfaces';
import UploadFileByAssignsForm from './UploadFileByAssignsForm';
import GoupsByAssingForm from './GoupsByAssingForm';
import AddStudentAndCollaboratorDialog from '../../AddStudentAndCollaboratorDialog';
import {
  setIsOpenAddPersonPublicationDialog,
  setKeywordAddPersonPublication,
} from '../../../slices/publicationsSlice';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/useRedux';

interface Props {
  setData: React.Dispatch<React.SetStateAction<PublicationStepsPayload>>;
  getData: PublicationStepsPayload;
  setNext: React.Dispatch<React.SetStateAction<number>>;
  sendSubmit: (dataPayload: PublicationStepsPayload) => void;
  isLoading: boolean;
  isEdit?: boolean;
  dataEdit?: Publication;
  document: [File, React.Dispatch<React.SetStateAction<File>>];
}

interface AuthorName extends AuthorsScientificPublication {
  name: string;
}

const ProjectPlanningForm = ({
  getData,
  setNext,
  sendSubmit,
  isLoading,
  isEdit = false,
  dataEdit,
  document: [file, setFile],
}: Props) => {
  const { t } = useTranslation();

  const { isOpenAddPersonPublicationDialog } = useAppSelector((state) => state.publications);

  const dispatch = useAppDispatch();

  const { data: investigationLinesAvailables, isLoading: isLoadingInvestigationLines } =
    useGetInvestigationLinesAllAvailablesQuery({});

  const handleReturn = () => {
    setNext((step) => step - 1);
  };

  const [listAddAuthor, setListAddAuthor] = useState<AuthorName[]>(
    isEdit
      ? dataEdit?.scientific_publication_author.map(
          ({ id, collaborator_id, student_id, is_coauthor, status, student, collaborator }) => ({
            id,
            collaborator_id: !!Number(collaborator_id) ? Number(collaborator_id) : null,
            student_id: !!Number(student_id) ? Number(student_id) : null,
            is_coauthor: Number(is_coauthor),
            status,
            name:
              student?.user.person.pers_full_name || collaborator?.user.person.pers_full_name || '',
          })
        ) || ([] as AuthorName[])
      : ([] as AuthorName[])
  );

  return (
    <>
      <Formik
        initialValues={{
          // linesGroupResearch: [] as LineGroupResearchAllAvailables[],
          linesGroupResearch: isEdit
            ? dataEdit?.line_group_research_publication.map(
                ({ group_research_id }) => group_research_id
              )
            : [] || [],
          // authorsScientificPublication: [],
          authorsScientificPublication: isEdit
            ? dataEdit?.scientific_publication_author.map(
                ({ id, collaborator_id, student_id, is_coauthor }) => ({
                  id,
                  collaborator_id: Number(collaborator_id) || 0,
                  student_id: Number(student_id) || 0,
                  is_coauthor: Number(is_coauthor) || 0,
                })
              ) || ([] as AuthorsScientificPublication[])
            : ([] as AuthorsScientificPublication[]),
          // investigationLines: [] as InvestigationLine[],
          investigationLines: isEdit
            ? dataEdit?.line_group_research_publication.map(
                ({ line_research_id }) => line_research_id
              )
            : [] || [],
          linesGroupResearchPrincipal: isEdit
            ? dataEdit?.line_group_research_publication.find(
                ({ is_principal }) => is_principal === '1'
              )?.group_research_id
            : '' || '',
          studentId: '',
          collaboratorId: '',

          projectDocuments: [] as ProjectDocuments[],
          typeFile: 'application/pdf',
        }}
        validationSchema={AssignmentsSchema}
        onSubmit={async ({
          authorsScientificPublication,
          linesGroupResearch,
          linesGroupResearchPrincipal,
        }) => {
          sendSubmit({
            ...getData,
            assignments: {
              authors_scientific_publication: listAddAuthor,
              // lines_group_research:  investigationLinesAvailables.filter(({id})=> id ===  ) linesGroupResearch?. map(
              //   ({ id, line_research_id }): LinesGroupResearch => ({
              //     group_research_id: id,
              //     is_principal: id.toString() === linesGroupResearchPrincipal ? 1 : 0,
              //     line_research_id,
              //   })
              // ),
              lines_group_research: [] as LinesGroupResearch[],
            },
            date_publication: '1',
          } as any);
        }}
      >
        {({ errors, touched, handleSubmit, setFieldValue, values }) => (
          <form onSubmit={handleSubmit}>
            <Grid container direction="column">
              <Grid item container xs={12} md={6} spacing={2}>
                <Grid
                  item
                  container
                  xs={12}
                  spacing={2}
                  // sx={{ border: `2px solid grey`, borderRadius: '10px' }}
                >
                  <Grid item xs={12}>
                    <InputLabel> {t('publication.AssignsForm.author.title')} </InputLabel>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Paper elevation={2}>
                      <Grid container justifyContent="space-between" alignItems="center">
                        <Grid
                          item
                          p={2}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Typography variant="body1" color="primary">
                            {t('publication.AssignsForm.addStudent.title')}:{' '}
                          </Typography>

                          <Typography
                            variant="body1"
                            color="primary"
                            sx={{
                              fontWeight: 'bold',
                              marginLeft: '5px',
                            }}
                          >
                            {listAddAuthor.filter(({ student_id }) => !!student_id).length}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <IconButton
                            aria-label="test"
                            onClick={() => {
                              dispatch(setIsOpenAddPersonPublicationDialog(true));
                              dispatch(setKeywordAddPersonPublication('student'));
                            }}
                          >
                            <AddCircleIcon color="primary" />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Paper elevation={2}>
                      <Grid container justifyContent="space-between" alignItems="center">
                        <Grid
                          item
                          p={2}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Typography variant="body1" color="primary">
                            {t('publication.AssignsForm.AddTeacher.title')}:{' '}
                          </Typography>
                          <Typography
                            variant="body1"
                            color="primary"
                            sx={{
                              fontWeight: 'bold',
                              marginLeft: '5px',
                            }}
                          >
                            {
                              listAddAuthor.filter(({ collaborator_id }) => !!collaborator_id)
                                .length
                            }
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          onClick={() => {
                            dispatch(setIsOpenAddPersonPublicationDialog(true));
                            dispatch(setKeywordAddPersonPublication('collaborator'));
                          }}
                        >
                          <IconButton aria-label="test">
                            <AddCircleIcon color="primary" />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={6}>
                  <InputLabel> {t('publication.AssignsForm.researchLines.title')} </InputLabel>
                  {isLoadingInvestigationLines ? (
                    <TextFieldFetching />
                  ) : (
                    <Autocomplete
                      multiple
                      options={investigationLinesAvailables || []}
                      loading={isLoadingInvestigationLines}
                      value={investigationLinesAvailables?.filter(({ id }) =>
                        values.investigationLines?.some((e) => e === id)
                      )}
                      loadingText={t('academic.subjects.add.edu_level.loading')}
                      noOptionsText={t('publication.AssignsForm.researchLines.noOptions')}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={Boolean(errors.investigationLines && touched.investigationLines)}
                          placeholder={t('publication.AssignsForm.researchLines.placeholder')}
                        />
                      )}
                      onChange={(_, value) => {
                        setFieldValue(
                          'investigationLines',
                          value.map(({ id }) => id)
                        );
                      }}
                      fullWidth
                      clearIcon={null}
                    />
                  )}
                  {errors.investigationLines && touched.investigationLines && (
                    <FormHelperText error>{errors.investigationLines}</FormHelperText>
                  )}
                </Grid>

                <GoupsByAssingForm isEdit={isEdit} />
                <UploadFileByAssignsForm isLoading={isLoading} setFile={setFile} />

                <Grid
                  item
                  container
                  alignItems="center"
                  justifyContent="center"
                  spacing={2}
                  sx={{ mt: 6 }}
                >
                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      variant="outlined"
                      size="large"
                      color="primary"
                      onClick={handleReturn}
                    >
                      {t('academic.subjects.add.back')}
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      type="submit"
                      variant="contained"
                      size="large"
                      color="primary"
                      disabled={isLoading}
                      endIcon={isLoading && <CircularProgress size={20} />}
                    >
                      {t('publication.AssignsForm.button.save')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {isOpenAddPersonPublicationDialog && (
              <AddStudentAndCollaboratorDialog
                listAddAuthor={listAddAuthor}
                setListAddAuthor={setListAddAuthor}
              />
            )}
          </form>
        )}
      </Formik>
    </>
  );
};

export default ProjectPlanningForm;
