import { mainApi } from '../../../../api/mainApi';
import { middlewareApi } from '../../../middleware/middlewareApi';
import { IResponse } from '../../../shared/interfaces/response.interface';
import { BorrowedBook } from '../interfaces/borrowed-books.interfaces';

export const userBorrowedBooksTags = middlewareApi.enhanceEndpoints({
  addTagTypes: ['BorrowedBooks'],
});

export const userBorrowedBooksApi = userBorrowedBooksTags.injectEndpoints({
  endpoints: (builder) => ({
    getBorrowedBooksByUser: builder.query<
      IResponse<BorrowedBook>,
      {
        search: string;
        perPage: number;
        page: number;
        userId: number;
        sort?: string;
        typeSort?: string;
      }
    >({
      queryFn: async ({ search, perPage, page, userId, sort, typeSort }) => {
        try {
          const { data } = await mainApi.get<IResponse<BorrowedBook>>(
            `/reservations/user/${userId}`,
            {
              params: {
                search,
                size: perPage,
                page,
                reservation_status: 'Pendiente',
                ...(sort && { sort }),
                ...(typeSort && { type_sort: typeSort }),
              },
            }
          );
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'BorrowedBooks', id } as const)),
              { type: 'BorrowedBooks', id: 'LIST' },
            ]
          : [{ type: 'BorrowedBooks', id: 'LIST' }],
    }),
    recommendBook: builder.mutation<
      any,
      {
        bookId: number;
        profileId: number;
      }
    >({
      queryFn: async ({ bookId, profileId }) => {
        try {
          const { data } = await mainApi.put<any>(`/books/${bookId}/recommendation`, {
            user_profile_id: profileId,
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),
  }),
});

export const { useGetBorrowedBooksByUserQuery, useRecommendBookMutation } = userBorrowedBooksApi;
