import { Redirect, Switch, Route } from 'react-router-dom';
import { useAppSelector } from '../../../../hooks/useRedux';
import GuardRoute from '../../../../router/guards/GuardRoute';
import StudentsGraduateScreen from '../screens/StudentsGraduatesScreen';
import { subPermissionKeys } from '../constants/permissions';

function StudentsGraduateRouter() {
  // Verify permission
  const { currentModule } = useAppSelector((state) => state.access);
  const hasPermission = subPermissionKeys.groupKey in currentModule?.permissions!;

  if (!hasPermission) return <Redirect to="/" />;

  return (
    <>
      <Switch>
        <GuardRoute
          path="/graduar-estudiantes"
          exact
          component={StudentsGraduateScreen}
          permissionKey={subPermissionKeys.groupKey}
          subPermissionKey={subPermissionKeys.read}
        />

        <Route path={`/graduar-estudiantes/*`}>
          <Redirect to="/graduar-estudiantes" />
        </Route>
      </Switch>
    </>
  );
}

export default StudentsGraduateRouter;
