import { Button } from '@material-ui/core';
import { ArrowBackIos } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

function ButtonBack() {
  const History = useHistory();
  const { t } = useTranslation();

  return (
    <Button onClick={() => History.goBack()} variant="text" startIcon={<ArrowBackIos />}>
      {t('students.virtual_classroom.task.modal.button.back')}
    </Button>
  );
}

export default ButtonBack;
