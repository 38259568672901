import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { IEvaluationForm } from '../../interfaces/evaluations.interface';
import { useAppSelector, useAppDispatch } from '../../../../../../../hooks/useRedux';
import {
  useAddEvaluationMutation,
  useUpdateEvaluationMutation,
} from '../../slices/evaluationsApiSlice';
import {
  resetEvaluationsEditFormQuestions,
  setEvaluationsFormConfirmDialogOpen,
  setEvaluationsFormStepActive,
} from '../../slices/evaluationsSlice';
import ConfirmAlert from '../../../../../../shared/components/dialogs/ConfirmAlert';

const ConfirmAddEvaluation = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { values } = useFormikContext<IEvaluationForm>();

  const onClose = () => {
    dispatch(setEvaluationsFormConfirmDialogOpen(false));
  };

  const {
    evaluationsIdSelected,
    evaluationsEditFormQuestionsAdded,
    evaluationsEditFormQuestionsDeleted,
  } = useAppSelector((state) => state.evaluations);

  const [addEvaluation, { isLoading, isSuccess, isError, error }] = useAddEvaluationMutation();
  const [
    updateEvaluation,
    { isLoading: isLoadingU, isSuccess: isSuccessU, isError: isErrorU, error: errorU },
  ] = useUpdateEvaluationMutation();

  const handleConfirm = () => {
    const formValues = {
      description: values.description,
      evaluationTypeId: values.evaluation_type_id,
      questions: values.scopes.reduce<string[]>(
        (acc, scope) => [...acc, ...(scope.questions?.map((question) => question.uuid!) || [])],
        []
      ),
      scopes: values.scopes.map((s) => ({
        categorie_id: s.uuid!,
        weight: s.weight,
      })),
      integralEvaluationId: values.integral_evaluation_id,
    };
    if (evaluationsIdSelected) {
      updateEvaluation({
        id: evaluationsIdSelected,
        ...formValues,
        addedQuestions: evaluationsEditFormQuestionsAdded.map((question) => question.uuid!),
        deletedQuestions: evaluationsEditFormQuestionsDeleted.map((question) => question.uuid!),
      });

      dispatch(resetEvaluationsEditFormQuestions());
    } else {
      addEvaluation({
        ...formValues,
      });
    }
  };

  useEffect(() => {
    if (isSuccess || isSuccessU) {
      dispatch(setEvaluationsFormStepActive(3));
    }
  }, [isSuccess, isSuccessU, dispatch]);

  return (
    <ConfirmAlert
      isError={isError || isErrorU}
      isSuccess={isSuccess || isSuccessU}
      error={(error as any) || (errorU as any)}
      isLoading={isLoading || isLoadingU}
      onClose={onClose}
      open
      handleDelete={handleConfirm}
      title={
        evaluationsIdSelected
          ? t('maintenance.evaluations.form.confirm.update.title')
          : t('maintenance.evaluations.form.confirm.add.title')
      }
      subtitle=""
      confirmBtnMessage={
        evaluationsIdSelected
          ? t('maintenance.evaluations.form.confirm.update.submit')
          : t('maintenance.evaluations.form.confirm.add.submit')
      }
      successMessage={
        evaluationsIdSelected
          ? t('maintenance.evaluations.form.confirm.update.success')
          : t('maintenance.evaluations.form.confirm.add.success')
      }
    />
  );
};

export default ConfirmAddEvaluation;
