import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Grid,
  InputLabel,
  Typography,
  Autocomplete,
  TextField,
  FormHelperText,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { useAddEventMutation } from '../slices/eventNewApiSlice';
import { useFormik } from 'formik';
import { useAppSelector } from '../../../../hooks/useRedux';
import { EventNewSchema } from '../constants/validation-eschemas';
import DateRangePicker, { DateRange } from '@material-ui/lab/DateRangePicker';
import esLocale from 'date-fns/locale/es';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import { useFormStyles } from '../constants/styles';
import { statusAvailables } from '../../../shared/constants/resourceStatus';
import { EVENTS_AVAILABLES } from '../constants/events_availables';
import { validateDates } from '../helpers';
import { useGetAvailablesProfilesQuery } from '../../profiles/slices/profilesApiSlice';
// import DragArea from '../../../students/virtual-classroom/components/DragArea';
import DragArea from '../../../shared/components/files/DragArea';
import { useUploadFilesMutation } from '../../../shared/slices/uploadFilesApi';
import { genUniqueCode, isEmptyObject, objMap } from '../../../shared/helpers';
import { useControlModalStates } from '../../../shared/hooks/useControlModalStates';
import ConfirmAlert from '../../../shared/components/dialogs/ConfirmAlert';
import EventsChunksDisplay from './EventsChunksDisplay';

const EventNewAdd = () => {
  const { currentProfile, defaultConfiguration } = useAppSelector((state) => state.access);

  const { open, handleCloseModal, handleOpenModalWith } = useControlModalStates();

  const [dates, setDates] = useState<DateRange<Date>>([null, null]);

  const [file, setFile] = useState<File | null>(null);

  const classes = useFormStyles();

  const history = useHistory();

  const onChangeDates = (newDatesValues: DateRange<Date>) => {
    const [start, end] = newDatesValues;
    // update formik values
    setFieldValue('start_date', start);
    setFieldValue('end_date', end);

    setDates(newDatesValues);
  };

  const [chunkCode, setChunkCode] = useState<string | null>(null);

  const [addEvent, { isLoading, isError, error, isSuccess, reset: resetAdd }] =
    useAddEventMutation();
  // const [showAlerts, setShowAlerts] = useState(true);

  const { isLoading: isLoadingProfiles, data: profilesAvailables } = useGetAvailablesProfilesQuery({
    profileId: currentProfile?.profileId!,
  });

  const [
    uploadFile,
    { isLoading: isLoadingF, isError: isErrorF, error: errorF, reset: resetFile },
  ] = useUploadFilesMutation();

  const {
    values,
    getFieldProps,
    errors,
    touched,
    handleSubmit,
    setFieldValue,
    validateForm,
    setTouched,
    setErrors,
  } = useFormik({
    initialValues: {
      title: '',
      description: '',
      start_date: '',
      end_date: '',
      type: '',
      status_id: 1,
      profiles: [],
      url_external: '',
      fileCount: 0,
    },
    validationSchema: EventNewSchema,
    validate: ({ start_date, end_date }) => {
      return validateDates(start_date, end_date);
    },
    onSubmit: async ({ fileCount, ...values }) => {
      uploadFile({
        profileId: currentProfile?.profileId!,
        files: file!,
      })
        .unwrap()
        .then(async (response) => {
          if (response) {
            const code = await genUniqueCode();

            addEvent({
              eventNewPayload: {
                title: values.title,
                description: values.description,
                start_date: values.start_date,
                end_date: values.end_date,
                type: values.type,
                status_id: values.status_id.toString(),
                profiles: values.profiles,
                url_image: response || 'null',
                url_external: values.url_external,
                cod_identifier: code,
              },
              profileId: currentProfile?.profileId!,
            });
            setChunkCode(code);

            // setShowAlerts(true);
            // window.scrollTo(0, 0);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  });

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={4} alignItems="flex-start">
          <Grid item xs={12} md={6} container spacing={2}>
            {/* <Grid item>
              {(isError || isErrorF) && showAlerts && (
                <ErrorAlert
                  message={(error || errorF) as string}
                  handleDismiss={() => setShowAlerts(false)}
                />
              )}

              {isSuccess && showAlerts && (
                <SuccessAlert
                  message="El evento ha sido creado correctamente."
                  handleDismiss={() => setShowAlerts(false)}
                />
              )}
            </Grid> */}

            <Grid item xs={12}>
              <Typography variant="h5" component="div" sx={{ mb: 3 }}>
                1. Complete datos del evento
              </Typography>
              <InputLabel>Tipo de evento</InputLabel>
              <Autocomplete
                options={EVENTS_AVAILABLES}
                loadingText="Cargando tipos de eventos..."
                getOptionSelected={(option, value) => option.value === value.value}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(errors.type && touched.type)}
                    placeholder="Seleccione un tipo de evento"
                  />
                )}
                onChange={(_, value) => {
                  setFieldValue('type', value?.value);
                }}
                fullWidth
                clearIcon={null}
              />
              {errors.type && touched.type && <FormHelperText error>{errors.type}</FormHelperText>}
            </Grid>

            <Grid item xs={12}>
              <InputLabel>Título</InputLabel>
              <TextField
                fullWidth
                error={Boolean(errors.title && touched.title)}
                placeholder="Ingrese el nombre del evento"
                {...getFieldProps('title')}
              />
              <FormHelperText>Nombre del evento</FormHelperText>
              {errors.title && touched.title && (
                <FormHelperText error>{errors.title}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12}>
              <InputLabel>Descripción</InputLabel>
              <TextField
                fullWidth
                error={Boolean(errors.title && touched.title)}
                placeholder="Descripción"
                multiline
                rows={4}
                {...getFieldProps('description')}
              />
              <FormHelperText>Descripción del evento</FormHelperText>
              {errors.description && touched.description && (
                <FormHelperText error>{errors.description}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12}>
              <InputLabel>Enlace</InputLabel>
              <TextField
                fullWidth
                placeholder="Enlace del evento"
                {...getFieldProps('url_external')}
              />
              <FormHelperText>(opcional, puede ser un enlace a un sitio web)</FormHelperText>
            </Grid>

            <Grid item xs={12} marginTop={2}>
              <Typography variant="h5" component="div" sx={{ mb: 3 }}>
                2. Establezca el inicio y fin del evento
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                <DateRangePicker
                  startText={null}
                  endText={null}
                  value={dates}
                  onChange={onChangeDates}
                  renderInput={(startProps, endProps) => (
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12} sm={3} lg={4} sx={{ pt: { xs: 2, sm: '0 !important' } }}>
                        <InputLabel
                          className={classes.inputLabel}
                          sx={{ textAlign: { xs: 'left', sm: 'right' } }}
                        >
                          Fecha de Inicio:
                        </InputLabel>
                      </Grid>
                      <Grid item xs={12} sm={9} lg={8}>
                        <TextField
                          fullWidth
                          {...startProps}
                          helperText=""
                          error={Boolean(errors.start_date && touched.start_date)}
                          {...getFieldProps('start_date')}
                        />
                        <FormHelperText>A partir de esta fecha comenzará el evento</FormHelperText>

                        {errors.start_date && touched.start_date && (
                          <FormHelperText error>{errors.start_date}</FormHelperText>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={3} lg={4} sx={{ pt: { xs: 2, sm: '0 !important' } }}>
                        <InputLabel
                          className={classes.inputLabel}
                          sx={{ textAlign: { xs: 'left', sm: 'right' } }}
                        >
                          Fecha de Fin:
                        </InputLabel>
                      </Grid>

                      <Grid item xs={12} sm={9} lg={8}>
                        <TextField
                          fullWidth
                          {...endProps}
                          helperText=""
                          error={Boolean(errors.end_date && touched.end_date)}
                          {...getFieldProps('end_date')}
                        />
                        <FormHelperText>En esta fecha el evento finalizará</FormHelperText>
                        {errors.end_date && touched.end_date && (
                          <FormHelperText error>{errors.end_date}</FormHelperText>
                        )}
                      </Grid>
                    </Grid>
                  )}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12}>
              <InputLabel>Asignar perfiles al evento</InputLabel>
              <Autocomplete
                multiple
                options={profilesAvailables! || []}
                loading={isLoadingProfiles}
                loadingText="Cargando perfiles disponibles..."
                getOptionLabel={(option) => option.pro_name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(errors.profiles && touched.profiles)}
                    placeholder="Seleccione los perfiles disponibles"
                  />
                )}
                onChange={(_, values) =>
                  setFieldValue('profiles', [...values.map((v) => v.id)] || '')
                }
                fullWidth
              />
              {errors.profiles && touched.profiles && (
                <FormHelperText error>{errors.profiles}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h5" component="div" sx={{ mb: 3 }}>
                3. Establezca el estado evento
              </Typography>
              <InputLabel>Estado de evento</InputLabel>
              <Autocomplete
                options={statusAvailables}
                getOptionLabel={(option) => option.name}
                onChange={(_, value) => setFieldValue('status_id', value?.id || '')}
                clearIcon={null}
                defaultValue={statusAvailables[0]}
                noOptionsText="No hay opciones disponibles"
                renderInput={(params) => (
                  <TextField {...params} error={Boolean(errors.status_id && touched.status_id)} />
                )}
                fullWidth
              />
              <FormHelperText>Selecciona un estado de actividad</FormHelperText>
              {errors.status_id && touched.status_id && (
                <FormHelperText error>{errors.status_id}</FormHelperText>
              )}
            </Grid>
            <Grid item xs={12}>
              <DragArea
                handleChange={(e) => {
                  setFieldValue('fileCount', e.target.files?.length || 0);
                  setFile(e.target.files![0]);
                }}
                label="Subir imagen"
                description={`Arrastre y suelte aquí la foto para el evento (Máximo ${defaultConfiguration?.file_size} MB)`}
                typesAccepted="image/*"
                isLoading={isLoadingF}
                error={errors.fileCount && touched.fileCount ? errors.fileCount : ''}
              />
            </Grid>

            <Grid
              item
              container
              alignItems="center"
              justifyContent="flex-end"
              spacing={2}
              sx={{ mt: 1 }}
            >
              <Grid item>
                <Button
                  variant="outlined"
                  size="large"
                  color="primary"
                  onClick={() => history.goBack()}
                >
                  Regresar
                </Button>
              </Grid>
              <Grid item>
                <Button
                  // type="submit"
                  variant="contained"
                  size="large"
                  color="primary"
                  onClick={() => {
                    validateForm(values).then((errors) => {
                      if (isEmptyObject(errors)) return handleOpenModalWith();

                      setTouched(
                        objMap(errors, (k, v) => !!v as any),
                        false
                      );
                      setErrors(errors);
                    });
                  }}
                  disabled={isLoading || isLoadingF}
                  endIcon={(isLoading || isLoadingF) && <CircularProgress size={20} />}
                >
                  Agregar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>

      <ConfirmAlert
        open={open}
        error={(error || errorF) as string}
        isError={isError || isErrorF}
        isSuccess={isSuccess}
        isLoading={isLoading || isLoadingF}
        loadingComponent={
          isLoadingF ? null : (
            <>
              <EventsChunksDisplay
                chunkCode={chunkCode}
                categoryChunks="events"
                isSuccess={isSuccess}
              />

              {/* <EventsChunksDisplay chunkCode={chunkCode} isSuccess={isSuccess}>
                {({ displayValue }) => {
                  return <>{displayValue}</>;
                }}
              </EventsChunksDisplay> */}
            </>
          )
        }
        handleDelete={handleSubmit}
        onClose={() => {
          handleCloseModal();
          setTimeout(() => {
            resetAdd();
            resetFile();
          }, 300);
          if (isSuccess) {
            history.push('/administrar-eventos');
          }
        }}
        title="¿Está seguro de enviar esta notificación?"
        subtitle="Al realizar el envío del comunicado no podrá ser eliminado, las notificaciones serán visibles desde el perfil asignado"
        successMessage="El evento ha sido creado correctamente."
      />
    </>
  );
};

export default EventNewAdd;
