import { useEffect, useCallback, useState } from 'react';
import { Link, useRouteMatch, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { useGetAreasQuery } from '../slices/areasApiSlice';
import useSharedGuard from '../../../../hooks/useSharedGuard';

import StatusChip from '../../../shared/components/badges/StatusChip';
import SearchPaginatedTable, {
  FilterArrow,
} from '../../../shared/components/tables/SearchPaginatedTable';
import { setAreasList, updateAreasTableStates } from '../slices/areasSlice';
import EditButton from '../../../shared/components/tables/buttons/EditButton';
import DeleteButton from '../../../shared/components/tables/buttons/DeleteButton';
import AddButton from '../../../shared/components/tables/buttons/AddButton';
import { useControlModalStates } from '../../../shared/hooks/useControlModalStates';
import DeleteAreaDialog from './DeleteAreaDialog';
import { subPermissionKeys } from '../constants/permissions';

const AreasTable = () => {
  const { t } = useTranslation();

  const { hasAccess } = useSharedGuard(subPermissionKeys);

  const dispatch = useAppDispatch();
  const history = useHistory();

  const { path } = useRouteMatch();

  const { page, perPage, search } = useAppSelector((state) => state.areas.areasTable);

  const [filterArrow, setFilterArrow] = useState<FilterArrow>({
    name: 'id',
    direction: 'asc',
  });

  const { dataId, open, handleCloseModal, handleOpenModalWith } = useControlModalStates();

  const setPage = useCallback(
    (page: number) => {
      dispatch(updateAreasTableStates({ page, perPage, search }));
    },
    [dispatch, perPage, search]
  );
  const setPerPage = useCallback(
    (perPage: number) => {
      dispatch(updateAreasTableStates({ page: 1, perPage, search }));
    },
    [dispatch, search]
  );
  const setSearch = useCallback(
    (search: string) => {
      dispatch(updateAreasTableStates({ page: 1, perPage, search }));
    },
    [dispatch, perPage]
  );

  const { isLoading, error, data, isFetching } = useGetAreasQuery({
    page,
    perPage,
    search,
    ...(filterArrow && {
      type_sort: filterArrow?.direction,
      sort: filterArrow?.name,
    }),
  });

  //cargar en un estado la vista actual de la tabla
  useEffect(() => {
    if (data) {
      dispatch(setAreasList(data.data));
    }
  }, [data, dispatch]);

  // const handleDelete = async (areaId: number) => {
  //   // dispatch(openDeleteModalWith(periodId));
  // };

  const datafetch = data?.data!.map((dt, i, arr) => ({
    id: i + ((page - 1) * perPage + 1),
    name: dt.ar_name,
    description: dt.ar_description,
    statusId: dt.status_id,
    options: dt,
  }));

  const goToAdd = () => {
    history.push(`${path}/agregar`);
  };

  return (
    <>
      <SearchPaginatedTable
        data={datafetch!}
        headers={{
          id: 'N°',
          name: t('academic.areas.name'),
          description: t('academic.areas.description'),
          statusId: t('academic.areas.status'),
          options: t('academic.areas.options'),
        }}
        keyExtractor={(item) => item.options.id.toString()}
        searchPlacehoder={t('academic.areas.search')}
        isLoading={isLoading}
        isFetching={isFetching}
        error={error}
        perPage={perPage!}
        setPerPage={setPerPage}
        total={data?.total || 0}
        page={page!}
        setPage={setPage}
        numHeader={4}
        search={search!}
        setSearch={setSearch}
        customRenderers={{
          statusId: (row) => <StatusChip statusId={Number(row.statusId)} />,

          options: (row) => (
            <>
              {hasAccess('edit') && (
                <Link to={`${path}/${row.options.id}/editar`}>
                  <EditButton />
                </Link>
              )}
              {hasAccess('delete') && (
                <DeleteButton handleDelete={() => handleOpenModalWith(row.options.id)} />
              )}
              {/* {row.principalId === null && hasAccess('edit') && (
              <ListCollective onClick={() => listAllCollectives(row.id)} />
            )} */}
              {!hasAccess('edit') && !hasAccess('delete') && t('academic.areas.none')}
            </>
          ),
        }}
        customDataCellsProperties={{
          id: { align: 'center' },
          name: {
            align: 'center',
          },
          description: {
            align: 'center',
          },
          statusId: {
            align: 'center',
          },

          options: {
            align: 'center',
          },
          // index: {
          //   align: 'center',
          // },
        }}
        customHeadersCellsProperties={{
          options: {
            align: 'center',
          },
          // index: {
          //   align: 'center',
          // },
        }}
        ActionButtons={<>{hasAccess('create') && <AddButton size="large" onClick={goToAdd} />}</>}
        listFilterArrows={{ name: 'ar_name', description: 'ar_description', statusId: 'status_id' }}
        filterArrow={filterArrow}
        setFilterArrow={setFilterArrow}
      />

      {open && (
        <DeleteAreaDialog areaId={dataId} openModal={open} handleCloseModal={handleCloseModal} />
      )}
    </>
  );
};

export default AreasTable;
