import { Grid, Typography } from '@material-ui/core';
import MainCard from '../../../shared/components/cards/MainCard';
import ApplicationsStatus from '../components/ApplicationsStatus';

const ApplicationsStatusScreen = () => {
  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid item sx={{ mb: 3 }} container justifyContent="space-between" alignItems="center">
        <Typography variant="h2">Listado</Typography>
      </Grid>
      <Grid item container>
        <ApplicationsStatus />
      </Grid>
    </Grid>
  );
};

export default ApplicationsStatusScreen;
