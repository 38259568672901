import { Grid, Typography } from '@material-ui/core';
import MainCard from '../../../shared/components/cards/MainCard';
import VacationsForm from '../forms/VacationsForm';

const VacationsScreen = () => {
  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid item sx={{ mb: 3 }} container justifyContent="space-between" alignItems="center">
        <Typography variant="h2">Solicitud de Vacaciones</Typography>
      </Grid>
      <Grid item container>
        <VacationsForm />
      </Grid>
    </Grid>
  );
};

export default VacationsScreen;
