import { Divider, Grid } from '@material-ui/core';
import { PropsFormEdit } from '..';
import JobsForm from './JobsForm';
import JobsTable from './JobsTable';

const Jobs = ({ data }: PropsFormEdit) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <JobsForm data={data!} />
      </Grid>
      <Grid item xs={12} mt={2}>
        <Divider />
      </Grid>
      <Grid item xs={12} mt={2}>
        <JobsTable data={data!} />
      </Grid>
    </Grid>
  );
};

export default Jobs;
