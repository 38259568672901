import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISearchTable } from '../../../../../shared/interfaces/paginatedTable.interface';
import { IQuestion } from '../../question/interfaces/questions.interface';
import { IEvaluation, EvaluationsState } from '../interfaces/evaluations.interface';

const initialState: EvaluationsState = {
  evaluationsList: [],
  evaluationsListView: 'table',
  evaluationsIdSelected: null,
  evaluationsIdToDelete: null,
  evaluationsDeleteModalOpen: false,

  evaluationsFormStepActive: 0,
  evaluationsFormLateralview: null,
  evaluationsFormScopeSelected: null,
  evaluationsFormQuestionsSelected: [],
  evaluationsFormConfirmDialogOpen: false,

  evaluationsEditFormQuestions: [],
  evaluationsEditFormQuestionsAdded: [],
  evaluationsEditFormQuestionsDeleted: [],

  evaluationsOpeningDateSelected: null,

  evaluationsGroupsList: [],
  evaluationsGroupsAssignedList: [],
  evaluationsGroupsPrevAssigned: [],
  evaluationsGroupsAdded: [],
  evaluationsGroupsRemoved: [],

  evaluationsGroupsAssignDialogOpen: false,

  evaluationsGroupsView: 'assigned',

  evaluationsSideDialogOpen: false,
  evaluationsTable: {
    page: 1,
    perPage: 5,
    search: '',
  },

  groupUsers: [],
  groupUsersModalOpen: false,

  evaTypeIdSelected: null,
  integralEvaluationIdSelected: null,
};

export const evaluationsSlice = createSlice({
  name: 'evaluations',
  initialState,
  reducers: {
    setEvaluationsList: (state, action: PayloadAction<IEvaluation[]>) => {
      state.evaluationsList = action.payload;
    },
    setEvaluationsListView: (state, action: PayloadAction<'cards' | 'table'>) => {
      state.evaluationsListView = action.payload;
    },
    setEvaluationsIdSelected: (state, action: PayloadAction<string | null>) => {
      state.evaluationsIdSelected = action.payload;
    },
    setEvaluationsIdToDelete: (state, action: PayloadAction<string | null>) => {
      state.evaluationsIdToDelete = action.payload;
    },
    setEvaluationsDeleteModalOpen: (state, action: PayloadAction<boolean>) => {
      state.evaluationsDeleteModalOpen = action.payload;
    },
    setEvaluationsFormStepActive: (state, action: PayloadAction<number>) => {
      state.evaluationsFormStepActive = action.payload;
    },
    setEvaluationsFormLateralview: (state, action: PayloadAction<string | null>) => {
      state.evaluationsFormLateralview = action.payload;
    },
    setEvaluationsOpeningDateSelected: (state, action: PayloadAction<string | null>) => {
      state.evaluationsOpeningDateSelected = action.payload;
    },

    setEvaluationsFormScopeSelected: (state, action: PayloadAction<string | null>) => {
      state.evaluationsFormScopeSelected = action.payload;
    },
    addEvaluationsFormQuestionsSelected: (state, action: PayloadAction<IQuestion>) => {
      state.evaluationsFormQuestionsSelected.push(action.payload);
    },
    removeEvaluationsFormQuestionsSelected: (state, action: PayloadAction<IQuestion>) => {
      state.evaluationsFormQuestionsSelected = state.evaluationsFormQuestionsSelected.filter(
        (question) => question.uuid !== action.payload.uuid
      );
    },
    setEvaluationsFormQuestionsSelected: (state, action: PayloadAction<IQuestion[]>) => {
      state.evaluationsFormQuestionsSelected = action.payload;
    },
    setEvaluationsFormConfirmDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.evaluationsFormConfirmDialogOpen = action.payload;
    },

    addEvaluationsEditFormQuestionsAdded: (state, action: PayloadAction<IQuestion>) => {
      state.evaluationsEditFormQuestionsAdded.push(action.payload);
    },
    removeEvaluationsEditFormQuestionsAdded: (state, action: PayloadAction<IQuestion>) => {
      state.evaluationsEditFormQuestionsAdded = state.evaluationsEditFormQuestionsAdded.filter(
        (question) => question.uuid !== action.payload.uuid
      );
    },
    setEvaluationsEditFormQuestionsAdded: (state, action: PayloadAction<IQuestion[]>) => {
      state.evaluationsEditFormQuestionsAdded = action.payload;
    },
    addEvaluationsEditFormQuestionsDeleted: (state, action: PayloadAction<IQuestion>) => {
      state.evaluationsEditFormQuestionsDeleted.push(action.payload);
    },
    removeEvaluationsEditFormQuestionsDeleted: (state, action: PayloadAction<IQuestion>) => {
      state.evaluationsEditFormQuestionsDeleted = state.evaluationsEditFormQuestionsDeleted.filter(
        (question) => question.uuid !== action.payload.uuid
      );
    },
    setEvaluationsEditFormQuestionsDeleted: (state, action: PayloadAction<IQuestion[]>) => {
      state.evaluationsEditFormQuestionsDeleted = action.payload;
    },

    setEvaluationsEditFormQuestions: (state, action: PayloadAction<IQuestion[]>) => {
      state.evaluationsEditFormQuestions = action.payload;
    },
    resetEvaluationsEditFormQuestions: (state) => {
      state.evaluationsEditFormQuestions = [];
    },

    setEvaluationsGroupsList: (state, action: PayloadAction<any[]>) => {
      state.evaluationsGroupsList = action.payload;
    },
    setEvaluationsGroupsAssignedList: (state, action: PayloadAction<any[]>) => {
      state.evaluationsGroupsAssignedList = action.payload;
    },
    setEvaluationsGroupsView: (state, action: PayloadAction<'assigned' | 'assign'>) => {
      state.evaluationsGroupsView = action.payload;
    },

    setEvaluationsGroupsPrevAssigned: (state, action: PayloadAction<any[] | null>) => {
      state.evaluationsGroupsPrevAssigned = action.payload;
    },
    addEvaluationsGroupsAdded: (state, action: PayloadAction<any>) => {
      state.evaluationsGroupsAdded.push(action.payload);
    },
    removeEvaluationsGroupsAdded: (state, action: PayloadAction<any>) => {
      state.evaluationsGroupsAdded = state.evaluationsGroupsAdded.filter(
        (group) => group.uuid !== action.payload.uuid
      );
    },
    setEvaluationsGroupsAdded: (state, action: PayloadAction<any[]>) => {
      state.evaluationsGroupsAdded = action.payload;
    },
    addEvaluationsGroupsRemoved: (state, action: PayloadAction<any>) => {
      state.evaluationsGroupsRemoved.push(action.payload);
    },
    removeEvaluationsGroupsRemoved: (state, action: PayloadAction<any>) => {
      state.evaluationsGroupsRemoved = state.evaluationsGroupsRemoved.filter(
        (group) => group.uuid !== action.payload.uuid
      );
    },
    setEvaluationsGroupsRemoved: (state, action: PayloadAction<any[]>) => {
      state.evaluationsGroupsRemoved = action.payload;
    },

    setEvaluationsGroupsAssignDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.evaluationsGroupsAssignDialogOpen = action.payload;
    },

    setEvaluationsTable: (state, action: PayloadAction<ISearchTable>) => {
      state.evaluationsTable = {
        ...state.evaluationsTable,
        ...action.payload,
      };
    },
    setEvaluationsSideDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.evaluationsSideDialogOpen = action.payload;
    },
    setGroupUsers: (state, action: PayloadAction<any[]>) => {
      state.groupUsers = action.payload;
    },
    setGroupUsersModalOpen: (state, action: PayloadAction<boolean>) => {
      state.groupUsersModalOpen = action.payload;
    },

    setEvaTypeIdSelected: (state, action: PayloadAction<string | null>) => {
      state.evaTypeIdSelected = action.payload;
    },
    setIntegralEvaluationIdSelected: (state, action: PayloadAction<string | null>) => {
      state.integralEvaluationIdSelected = action.payload;
    },
  },
});

export const {
  setEvaluationsList,
  setEvaluationsListView,
  setEvaluationsIdSelected,
  setEvaluationsIdToDelete,
  setEvaluationsDeleteModalOpen,
  setEvaluationsFormStepActive,
  setEvaluationsFormLateralview,
  setEvaluationsFormScopeSelected,
  addEvaluationsFormQuestionsSelected,
  removeEvaluationsFormQuestionsSelected,
  setEvaluationsFormQuestionsSelected,
  setEvaluationsFormConfirmDialogOpen,
  setEvaluationsGroupsList,
  setEvaluationsGroupsAssignedList,
  setEvaluationsGroupsView,
  setEvaluationsTable,
  setEvaluationsSideDialogOpen,
  setEvaluationsOpeningDateSelected,
  addEvaluationsEditFormQuestionsAdded,
  removeEvaluationsEditFormQuestionsAdded,
  setEvaluationsEditFormQuestionsAdded,
  addEvaluationsEditFormQuestionsDeleted,
  removeEvaluationsEditFormQuestionsDeleted,
  setEvaluationsEditFormQuestionsDeleted,
  setEvaluationsEditFormQuestions,
  resetEvaluationsEditFormQuestions,

  setEvaluationsGroupsPrevAssigned,
  addEvaluationsGroupsAdded,
  removeEvaluationsGroupsAdded,
  addEvaluationsGroupsRemoved,
  removeEvaluationsGroupsRemoved,
  setEvaluationsGroupsAdded,
  setEvaluationsGroupsRemoved,
  setEvaluationsGroupsAssignDialogOpen,

  setGroupUsers,
  setGroupUsersModalOpen,

  setEvaTypeIdSelected,
  setIntegralEvaluationIdSelected,
} = evaluationsSlice.actions;
