import { useEffect, useState } from 'react';

import { useHistory, useRouteMatch, Link } from 'react-router-dom';
import { IconUserPlus, IconUserOff } from '@tabler/icons';
import { IconButton, Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { useGetEmisionPointsQuery } from '../slices/financialConfigurationApiSlice';
import SearchPaginatedTable, {
  FilterArrow,
} from '../../../shared/components/tables/SearchPaginatedTable';
import StatusChip from '../../../shared/components/badges/StatusChip';
import EditButton from '../../../shared/components/tables/buttons/EditButton';
import DeleteButton from '../../../shared/components/tables/buttons/DeleteButton';
import AddButton from '../../../shared/components/tables/buttons/AddButton';
import {
  openDeleteModalUnlinkUserWith,
  openDeleteModalWith,
  setEmissionPointList,
  updateEmissionPointTable,
} from '../slices/financialConfigurationSlice';
import CashPointSideDialog from './CashPointSideDialog';
import { StablishmentData } from '../interfaces/financialConfiguration.interfaces';
import { useTableSearch } from '../../../../hooks/useTableSearch';

const FinancialConfigurationTable = () => {
  const { currentProfile } = useAppSelector((state) => state.access);
  const { t } = useTranslation();

  const { path } = useRouteMatch();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const { page, perPage, search } = useAppSelector(
    (state) => state.financialConfigurations.emissionPointTable
  );

  const [filterArrow, setFilterArrow] = useState<FilterArrow>(null!);

  const { setPage, setPerPage, setSearch } = useTableSearch({
    page,
    perPage,
    search,
    updateFunction: updateEmissionPointTable,
  });
  //side dialog state
  const [openSideDialog, setOpenSideDialog] = useState(false);
  const [stablishmentData, setStablishmentData] = useState<StablishmentData>();

  const handleOpenSideDialog = (data: StablishmentData) => {
    setStablishmentData(data);
    setOpenSideDialog(true);
  };
  const handleCloseSideDialog = () => {
    setOpenSideDialog(false);
  };

  const handleDelete = async (emissionPointId: number) => {
    dispatch(openDeleteModalWith(emissionPointId));
  };

  const handleUnlinkUser = async (userId: number) => {
    dispatch(openDeleteModalUnlinkUserWith(userId));
  };

  const goToAdd = () => {
    history.push(`${path}/agregar`);
  };

  const { isLoading, error, data, isFetching } = useGetEmisionPointsQuery(
    {
      profileId: currentProfile?.profileId!,
      page,
      perPage,
      search,
      ...(filterArrow && { sort: filterArrow.name, type_sort: filterArrow.direction }),
    },
    { refetchOnMountOrArgChange: 30 }
  );

  useEffect(() => {
    if (data) {
      dispatch(setEmissionPointList(data?.data));
    }
  }, [data, dispatch]);

  const datafetch = data?.data!.map((d, index) => ({
    id: index + (page - 1) * perPage + 1,
    // stablishmentCode: d.stablishment_point,
    // emissionCode: d.emission_point,
    // secuencial: d.secuencial,
    user: `
    ${d.person?.pers_firstname || ''} 
    ${d.person?.pers_secondname || ''} 
    ${d.person?.pers_first_lastname || ''} 
    ${d.person?.pers_second_lastname || ''}`,
    prefix: d.stablishment_point + '-' + d.emission_point + '-' + d.secuencial,
    type_document: d.type_document?.cat_name,
    direction: d.campus?.cam_name,
    statusId: d.status_id,
    options: d,
  }));

  return (
    <>
      <SearchPaginatedTable
        data={datafetch!}
        headers={{
          id: 'N°',
          // stablishmentCode: 'Cod. Establecimiento',
          // emissionCode: 'Cod. Punto emisión',
          // secuencial: 'Secuencial',
          user: t('financial.emission_point.table.user'),
          prefix: t('financial.emission_point.table.prefix'),
          type_document: t('financial.emission_point.table.doc_type'),
          direction: t('financial.emission_point.table.branch_office'),
          statusId: t('financial.emission_point.table.status'),
          options: t('shared.search_paginated_table.table.options'),
        }}
        listFilterArrows={{
          prefix: 'stablishment_point',
          statusId: 'status_id',
        }}
        filterArrow={filterArrow}
        setFilterArrow={setFilterArrow}
        keyExtractor={(item) => item.id.toString()}
        searchPlacehoder={t('financial.emission_point.table.search_placeholder')}
        isLoading={isLoading}
        isFetching={isFetching}
        error={error}
        perPage={perPage}
        setPerPage={setPerPage}
        total={data?.total || 0}
        page={page}
        setPage={setPage}
        numHeader={8}
        search={search}
        setSearch={setSearch}
        customDataCellsProperties={{
          id: { align: 'center', width: '10px' },
          // stablishmentCode: { align: 'center', width: '10px' },
          // emissionCode: { align: 'center', width: '25px' },
          // secuencial: { align: 'center', width: '10px' },
          prefix: { align: 'center' },
          type_document: { align: 'center' },
          direction: { align: 'center' },
          options: { align: 'center' },
        }}
        customHeadersCellsProperties={{
          id: { align: 'center', width: '10px' },
          // stablishmentCode: { align: 'center', width: '10px' },
          // emissionCode: { align: 'center', width: '25px' },
          // secuencial: { align: 'center', width: '10px' },
          prefix: { align: 'center' },
          type_document: { align: 'center' },
          direction: { align: 'center' },
          statusId: { align: 'center' },
          options: { align: 'center' },
        }}
        customRenderers={{
          statusId: (row) => <StatusChip statusId={Number(row.statusId)} />,
          options: (row) => (
            <>
              {/*{hasAccess('edit') && (*/}
              <Link to={`${path}/${row.options.id}/editar`}>
                <EditButton />
              </Link>
              {/*)}
            {hasAccess('delete') &&*/}{' '}
              <DeleteButton handleDelete={() => handleDelete(row.options.id)} />
              {/*}*/}
              <Tooltip
                title={
                  row.options.user_id
                    ? t('financial.emission_point.table.unlink_user_tooltip')
                    : t('financial.emission_point.table.link_user_tooltip')
                }
              >
                <IconButton
                  onClick={() => {
                    if (row.options.user_id) handleUnlinkUser(Number(row.options.user!.id));
                    else
                      handleOpenSideDialog({
                        stablishment_point: row.options.stablishment_point,
                        emission_point: row.options.emission_point,
                      });
                  }}
                >
                  {row.options.user_id ? <IconUserOff size={20} /> : <IconUserPlus size={20} />}
                </IconButton>
              </Tooltip>
              {/*
            {!hasAccess('edit') && !hasAccess('delete') && 'Ninguna'}
            */}
            </>
          ),
        }}
        ActionButtons={
          <>
            {/*{hasAccess('create') &&*/} <AddButton size="large" onClick={goToAdd} />
            {/*}*/}
          </>
        }
      />

      {openSideDialog && (
        <CashPointSideDialog
          open={openSideDialog}
          stablishmentData={stablishmentData}
          handleCloseDialog={handleCloseSideDialog}
        />
      )}
    </>
  );
};
export default FinancialConfigurationTable;
