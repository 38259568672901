import { Grid } from '@material-ui/core';

export interface MainCardFormProps {
  children: React.ReactNode | string | null;
  sx?: { [key: string]: string | number | undefined };
}

const MainCardForm = ({ children, sx }: MainCardFormProps) => {
  return (
    <Grid container sx={{ padding: 4, bgcolor: 'background.paper', borderRadius: 2, ...sx }}>
      {children}
    </Grid>
  );
};

export default MainCardForm;
