import React from 'react';

import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';

import NavItem from '../NavItem';
import NavCollapse from '../NavCollapse';

import { useAppSelector } from '../../../../../../../hooks/useRedux';

import {
  GenericCardProps,
  LanguageSelector,
} from '../../../../../../shared/interfaces/material-ui.interfaces';

export interface NavGroupProps {
  item: {
    id?: string;
    type?: string;
    icon?: GenericCardProps['iconPrimary'];
    children?: NavGroupProps['item'][];
    title?: string | LanguageSelector;
    caption?: React.ReactNode | string;
    color?: 'primary' | 'secondary' | 'default' | undefined;
    permissionKey?: string;
    menuId?: string;
    vcType?: string[];
  };
}

const NavGroup = ({ item }: NavGroupProps) => {
  const { currentModule, currentRol } = useAppSelector((state) => state.access);
  const { course: courseStudent } = useAppSelector((state) => state.virtualClassroomStudentShared);
  const { course: courseTeacher } = useAppSelector((state) => state.virtualClassroomTeacherShared);

  const permissions = currentModule?.permissions!;

  // menu list collapse & items
  const items = item.children?.map((menu) => {
    switch (menu.type) {
      case 'collapse':
        const somePermission = menu.children?.some((item) => item.permissionKey! in permissions);

        if (somePermission) {
          if (menu.vcType) {
            if (currentRol?.keyword === 'docente' && menu.vcType.includes(courseTeacher?.vcType!)) {
              return <NavCollapse key={menu.id} menu={menu} level={1} />;
            }

            if (
              currentRol?.keyword === 'estudiante' &&
              menu.vcType.includes(courseStudent?.vcType!)
            ) {
              return <NavCollapse key={menu.id} menu={menu} level={1} />;
            }

            return null;
          }
          return <NavCollapse key={menu.id} menu={menu} level={1} />;
        }

        return null;

      case 'item':
        if (menu.permissionKey === 'dashboard') {
          return <NavItem key={menu.id} item={menu} level={1} />;
        }
        if (menu.permissionKey === 'shared') {
          return <NavItem key={menu.id} item={menu} level={1} />;
        }

        if (permissions && menu!.permissionKey! in permissions) {
          if (menu.vcType) {
            if (currentRol?.keyword === 'docente' && menu.vcType.includes(courseTeacher?.vcType!)) {
              return <NavItem key={menu.id} item={menu} level={1} />;
            }

            if (
              currentRol?.keyword === 'estudiante' &&
              menu.vcType.includes(courseStudent?.vcType!)
            ) {
              return <NavItem key={menu.id} item={menu} level={1} />;
            }

            return null;
          }
          return <NavItem key={menu.id} item={menu} level={1} />;
        }

        return null;

      default:
        return (
          <Typography key={menu.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return (
    <React.Fragment>
      <List>{items}</List>
    </React.Fragment>
  );
};

export default NavGroup;
