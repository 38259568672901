import DeleteAlert from '../../../../shared/components/dialogs/DeleteAlert';

import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import { useDeleteInvestigationLineMutation } from '../../slices/InvestigationLinesApiSlice';
import {
  resetResearchLineIdToDelete,
  setDeleteModalOpen,
} from '../../slices/investigationLinesSlice';

const DeleteInvestigationLineDialog = () => {
  const dispatch = useAppDispatch();

  const { deleteModalOpen, researchLineIdToDelete } = useAppSelector(
    (state) => state.investigationLines
  );

  const [deleteInvestigationLine, { isLoading, isSuccess, isError, error }] =
    useDeleteInvestigationLineMutation();

  const handleDelete = () => {
    deleteInvestigationLine({
      investigationLineId: researchLineIdToDelete!,
    });
  };

  const handleClose = () => {
    dispatch(setDeleteModalOpen(false));
    dispatch(resetResearchLineIdToDelete());
  };

  return (
    <DeleteAlert
      open={deleteModalOpen}
      onClose={handleClose}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      handleDelete={handleDelete}
      error={error as string}
    />
  );
};

export default DeleteInvestigationLineDialog;
