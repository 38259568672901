import { AcroType } from './types';

export const appTypes = [
  {
    acro: 'CAMCAR' as AcroType['types'],
    value: 'Cambio de Carrera',
  },
  {
    acro: 'HOMEXT' as AcroType['types'],
    value: 'Homologación externa',
  },
  {
    acro: 'INGPRA' as AcroType['types'],
    value: 'Ingreso practicas pre profesionales',
  },
  {
    acro: 'APPP' as AcroType['types'],
    value: 'Aprobación de prácticas pre profesionales',
  },
  // {
  //   acro: 'REQDOC' as AcroType['types'],
  //   value: 'Requisición de personal docente',
  // },
  {
    acro: 'EXMATR' as AcroType['types'],
    value: 'Examen Atrasado',
  },
  {
    acro: 'EXMREC' as AcroType['types'],
    value: 'Examen de recuperación',
  },
  {
    acro: 'RETMAT' as AcroType['types'],
    value: 'Retiro de materia',
  },
  {
    acro: 'RECNOT' as AcroType['types'],
    value: 'Recalificación de nota',
  },
  {
    acro: 'REGTMC' as AcroType['types'],
    value: 'Registro de tercera matrícula',
  },
  {
    acro: 'OTROS' as AcroType['types'],
    value: 'Otros (casos especiales)',
  },
  {
    acro: 'INGVIN' as AcroType['types'],
    value: 'Ingreso a vinculación',
  },
  {
    acro: 'INGTIT' as AcroType['types'],
    value: 'Ingreso a titulación',
  },
  {
    acro: 'REGAPR' as AcroType['types'],
    value: 'Registro de anteproyecto',
  },
  {
    acro: 'CONPAG' as AcroType['types'],
    value: 'Convenio de pago',
  },
  {
    acro: 'JUSFAL' as AcroType['types'],
    value: 'Justificación de faltas',
  },
  {
    acro: 'PREBIB' as AcroType['types'],
    value: 'Prestamo de libros',
  },
  {
    acro: 'DOCHBH' as AcroType['types'],
    value: 'Solicitud Happy Birthday',
  },
  {
    acro: 'DOCANT' as AcroType['types'],
    value: 'Solicitud de anticipo',
  },
];

export const rrhhAppTypes = [
  {
    acro: 'DOCHBH' as AcroType['types'],
    value: 'Solicitud Happy Birthday',
  },
  {
    acro: 'DOCANT' as AcroType['types'],
    value: 'Solicitud de anticipo',
  },
  {
    acro: 'DOCPDA' as AcroType['types'],
    value: 'Solicitud de Personal Day',
  },
  {
    acro: 'DOCVAC' as AcroType['types'],
    value: 'Solicitud de Vacaciones',
  },
];

export const moduleApplicationTypes = {
  general: appTypes,
  rrhh: rrhhAppTypes,
};

export const appStates = [
  'Rechazado',
  'Enviado',
  'Revisado',
  'Aceptado',
  'Aprobado',
  'Registrado',
  'Finalizado',
  'Revisado por Decanato',
  'Revisado por Consejo Facultad',
  'Revisado por Secretaria',
];
