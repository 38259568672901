import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISearchTable } from '../../../shared/interfaces/paginatedTable.interface';
import { AcademicOffering } from '../interfaces/academicOfferings.interfaces';

interface AcademicOfferingState {
  academicOfferingsList: AcademicOffering[];
  deleteModalOpen: boolean;
  academicOfferingIdToDelete?: number;
  academicOfferingTable: ISearchTable;
}

const initialState: AcademicOfferingState = {
  academicOfferingsList: [],
  deleteModalOpen: false,
  academicOfferingTable: {
    page: 1,
    perPage: 5,
    search: '',
  },
};

const academicOfferingsSlice = createSlice({
  name: 'academicOfferings',
  initialState,
  reducers: {
    setAcademicOfferingsList: (state, action: PayloadAction<AcademicOffering[]>) => {
      state.academicOfferingsList = action.payload;
    },
    setDeleteModalOpen: (state, action: PayloadAction<boolean>) => {
      state.deleteModalOpen = action.payload;
    },
    openDeleteModalWith: (state, action: PayloadAction<number>) => {
      state.academicOfferingIdToDelete = action.payload;
      state.deleteModalOpen = true;
    },
    resetAcademicOfferingsIdToDelete: (state) => {
      state.academicOfferingIdToDelete = undefined;
    },
    updateAcademicOfferingTable: (state, action: PayloadAction<ISearchTable>) => {
      state.academicOfferingTable = action.payload;
    },
  },
});

export const {
  setAcademicOfferingsList,
  setDeleteModalOpen,
  openDeleteModalWith,
  resetAcademicOfferingsIdToDelete,
  updateAcademicOfferingTable,
} = academicOfferingsSlice.actions;

export default academicOfferingsSlice.reducer;
