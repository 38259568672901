import { FC, useEffect, useState } from 'react';
import useGetUrlDownload from '../../../../../hooks/useGetUrlDownload';
import { ExternalLink } from '../../interfaces/dashboard.interface';
import provitionalImg from '../../../../../assets/images/fondo-student-dashboard-placeholder.jpg';
import LoadingChildren from '../../../../shared/components/LoadingChildren';
import { Skeleton } from '@material-ui/core';

interface Props {
  data: ExternalLink;
}

const ViewExternalLink: FC<Props> = ({ data }) => {
  const { urlDownload } = useGetUrlDownload();

  const [loading, setLoading] = useState(true);
  const [img /* setImg */] = useState<string>(`${urlDownload}${data.ext_lin_image_url}`);

  // const handleErrorImg = () => {
  //   setImg(provitionalImg);
  // };

  useEffect(() => {
    setLoading(true);
    const image = document.createElement('img');
    image.src = img;

    image.onload = () => {
      setTimeout(() => {
        setLoading(false);
      }, 500); // Espera 500ms antes de mostrar la imagen
    };
    image.onerror = () => {
      setLoading(true);
    };
  }, [img]);

  return (
    <LoadingChildren
      isLoading={loading}
      loadingSkeleton={<Skeleton height={'25vh'} width={'100%'} />}
    >
      <a href={data.ext_lin_page_url} target="_blank" rel="noreferrer">
        <img
          src={`${urlDownload}${data.ext_lin_image_url}`}
          alt={data.ext_lin_title}
          // onError={handleErrorImg}
          /* loading="lazy" */ onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = provitionalImg;
          }}
          width="100%"
        />
      </a>
    </LoadingChildren>
  );
};

export default ViewExternalLink;
