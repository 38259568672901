import { useEffect, useState } from 'react';

import { Button, Divider, FormHelperText, Grid } from '@material-ui/core';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

import InputLabel from '../../../../shared/components/extended/Form/InputLabel';

import CustomAsyncPaginatedSelect from '../../../../shared/components/extended/Form/CustomAsyncPaginatedSelect';
import { useLazyGetCollaboratorsQuery } from '../../../../hr/collaborators/slices/collaboratorsApiSlice';
import { Collaborator } from '../../../../hr/collaborators/interfaces/collaborators.interfaces';
import ContactsForm from './ContactsForm';
import ContactsTable from './ContactsTable';
import {
  useGetAuthorityEducationLevelQuery,
  useGetContactByEducationLevelQuery,
  useUpdateEducationLevelAuthorityMutation,
} from '../../slices/educationLevelsApiSlice';
import {
  EducationLevelAuthority,
  EducationLevelContacts,
} from '../../interfaces/educationLevels.interfaces';
import { useControlModalStates } from '../../../../shared/hooks/useControlModalStates';
import DeleteContactDialog from './DeleteContactDialog';
import { CustomChilds } from '../../../../shared/interfaces/material-ui.interfaces';
import TextFieldFetching from '../../../../shared/components/TextFieldFetching';
import LoadingButton from '../../../../shared/components/buttons/LoadingButton';
import SuccessAlert from '../../../../shared/components/alerts/SuccessAlert';
import ErrorAlert from '../../../../shared/components/alerts/ErrorAlert';

export type Contact = 'contact';
export type Network = 'network';
interface Props {
  educationLevelId: number;
  handleCloseDialog: (e: React.SyntheticEvent) => void;
}

const ContactDeanForm = ({ educationLevelId, handleCloseDialog }: Props) => {
  const { t } = useTranslation();

  const [showAlerts, setShowAlerts] = useState(false); // const [showAlertsSuccess, setShowAlertsSuccess] = useState<

  const { open, multipleIds, handleOpenModalWithMultipleIds, handleCloseModal } =
    useControlModalStates<{
      index: number;
      id: number;
    }>();

  const [collaboratorValue, setCollaboratorValue] = useState<CustomChilds<
    Collaborator | EducationLevelAuthority,
    { value: number; label: string }
  > | null>(null);

  const { data: currentAuthority, isLoading: isLoadingCurrentAuthoity } =
    useGetAuthorityEducationLevelQuery({
      educationLevelId: educationLevelId,
    });

  // set curretn collaborator to collaboratorValue
  useEffect(() => {
    if (currentAuthority) {
      setCollaboratorValue({
        value: +currentAuthority.collaborator_id,
        label: currentAuthority.pers_full_name,
        ...currentAuthority,
      });
    }
  }, [currentAuthority]);

  const [
    saveAuthority,
    {
      isLoading: isLoadingSaveAuth,
      error: errorSaveAuth,
      isError: isErrorSaveAuth,
      isSuccess: isSuccessSaveAuth,
    },
  ] = useUpdateEducationLevelAuthorityMutation();

  const saveAuthorityHandler = async () => {
    if (!collaboratorValue) return;

    await saveAuthority({
      payload: {
        collaborator_id: collaboratorValue.value,
        education_level_id: educationLevelId,
      },
    });

    setShowAlerts(true);
  };

  const [payloadData, setPayloadData] = useState<EducationLevelContacts>({
    education_level_id: 0,
    education_levels_contact: [],
    education_levels_network: [],
  });

  const [typeForm, setTypeForm] = useState<'contact' | 'network' | null>('contact');
  const [formView, setFormView] = useState<
    | (
        | EducationLevelContacts['education_levels_contact'][0]
        | EducationLevelContacts['education_levels_network'][0]
      )
    | boolean
  >(false);

  const { data, isLoading, isFetching } = useGetContactByEducationLevelQuery({
    educationLevelId,
  });

  useEffect(() => {
    if (data) {
      setPayloadData(data);
    }
  }, [data]);

  const setEditForm = (
    value:
      | EducationLevelContacts['education_levels_contact'][0]
      | EducationLevelContacts['education_levels_network'][0]
  ) => {
    setFormView(value);
  };

  const [
    getCollaboratorTrigger,
    // { data: institutesAvailables, isLoading: isLoadingInstitutesAvailables },
  ] = useLazyGetCollaboratorsQuery();

  const getCollaborators = async (search: string, page: number, perPage: number) => {
    const response = await getCollaboratorTrigger({
      page,
      perPage,
      search,
      statusId: 1,
    }).unwrap();

    const hasMore = response.current_page < response.last_page;

    return {
      options: response.data.map((dt) => {
        return {
          value: dt.id,
          label: dt.user.person.pers_full_name || dt.user.person.pers_full_lastname,
          ...dt,
        };
      }),
      hasMore,
    };
  };

  const deleteContactHandler = (index: number, id: number) => {
    handleOpenModalWithMultipleIds({ index, id });
  };

  const { errors, touched, setFieldValue /* submitForm */ } = useFormik({
    initialValues: {
      collaboratorId: null,
    },
    // validationSchema: editTour ? UpdateRouteFormSchema : CreateRouteFormSchema,
    onSubmit: async (_values) => {
      try {
        // const newData: EducationLevelContactsCreate = {
        //   education_level_id: educationLevelId,
        //   education_levels_contact: [],
        //   education_levels_network: [],
        // };
        // const updateData: EducationLevelContacts = {
        //   education_level_id: educationLevelId,
        //   education_levels_contact: [],
        //   education_levels_network: [],
        // };
        // payloadData.education_levels_contact.forEach((item) => {
        //   if ('tempId' in item) newData.education_levels_contact.push(item);
        //   if ('id' in item /* && item.isEdited */) updateData.education_levels_contact.push(item);
        // });
        // payloadData.education_levels_network.forEach((item) => {
        //   if ('tempId' in item) newData.education_levels_network.push(item);
        //   if ('id' in item /* && item.isEdited */) updateData.education_levels_network.push(item);
        // });
        // const hasNewData =
        //   newData.education_levels_contact.length > 0 ||
        //   newData.education_levels_network.length > 0;
        // // const hasUpdateData =
        // //   updateData.education_levels_contact.length > 0 ||
        // //   updateData.education_levels_network.length > 0;
        // // if (hasUpdateData) {
        // console.log('updateData: ', updateData);
        // await updateContacts({
        //   payload: {
        //     ...updateData,
        //   },
        //   noInvalidate: hasNewData,
        // }).unwrap();
        // // }
        // if (hasNewData) {
        //   console.log('newData: ', newData);
        //   await createContacts({
        //     payload: {
        //       ...newData,
        //     },
        //   }).unwrap();
        // }
        // setShowAlerts(true);
      } catch (error) {
        console.log(error);
      }
    },
  });

  const toggleFormType = (type: typeof typeForm) => {
    if (typeForm === type) return setTypeForm(null);

    setFormView(false);
    setTypeForm(type);
  };

  const changePersonHandler = (
    newValue: CustomChilds<Collaborator, { value: number; label: string }> | null
  ) => {
    setCollaboratorValue(newValue);

    setFieldValue('collaboratorId', newValue?.id || '');
  };

  return (
    <Grid container spacing={2} alignItems="flex-start">
      <Grid item>
        {showAlerts && isSuccessSaveAuth && (
          <SuccessAlert
            message={`La autoridad ha sido guardada correctamente.`}
            handleDismiss={() => setShowAlerts(false)}
          />
        )}
        {showAlerts && isErrorSaveAuth && (
          <ErrorAlert
            message={errorSaveAuth as string}
            handleDismiss={() => setShowAlerts(false)}
          />
        )}
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs>
            <InputLabel>{t('Autoridad')}</InputLabel>
            {isLoadingCurrentAuthoity ? (
              <TextFieldFetching />
            ) : (
              <CustomAsyncPaginatedSelect
                key={'collaboratorId'}
                value={collaboratorValue}
                isError={false}
                placeholder={'Escoger decano'}
                setValue={(_value) => changePersonHandler(_value as any)}
                fetchFunc={getCollaborators}
              />
            )}
            {errors.collaboratorId && touched.collaboratorId && (
              <FormHelperText error>{t(errors.collaboratorId)}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={'auto'}>
            <InputLabel>&nbsp;</InputLabel>
            <LoadingButton
              variant="contained"
              color="primary"
              onClick={() => saveAuthorityHandler()}
              isLoading={isLoadingSaveAuth}
            >
              {t('Guardar autoridad')}
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={4} justifyContent="center">
          <Grid item>
            <Button
              variant={typeForm === 'contact' ? 'contained' : 'outlined'}
              onClick={() => toggleFormType('contact')}
              // disabled={typeForm === 'contact'}
            >
              Contactos
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant={typeForm === 'network' ? 'contained' : 'outlined'}
              onClick={() => toggleFormType('network')}
              // disabled={typeForm === 'network'}
            >
              Redes sociales
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        {typeForm === 'contact' && !!formView && (
          <ContactsForm
            formType="contact"
            dataList={payloadData}
            educationLevelId={educationLevelId}
            {...(typeof formView !== 'boolean' && 'phone' in formView && { edit: formView })}
            returnHandler={() => setFormView(false)}
            // changeContactHandler={changeContactHandler}
          />
        )}

        {typeForm === 'network' && !!formView && (
          <ContactsForm
            formType="network"
            dataList={payloadData}
            educationLevelId={educationLevelId}
            {...(typeof formView !== 'boolean' && { edit: formView })}
            returnHandler={() => setFormView(false)}
            // changeNetworkHandler={changeNetworkHandler}
          />
        )}
      </Grid>

      <Grid item xs={12}>
        {typeForm === 'contact' && !!!formView && (
          <ContactsTable
            formType="contact"
            data={payloadData?.education_levels_contact || []}
            isLoading={isLoading}
            isFetching={isFetching}
            addHandler={() => setFormView(true)}
            setEditForm={setEditForm}
            deleteHandler={deleteContactHandler}
          />
        )}

        {typeForm === 'network' && !!!formView && (
          <ContactsTable
            formType="network"
            data={payloadData?.education_levels_network || []}
            isLoading={isLoading}
            isFetching={isFetching}
            addHandler={() => setFormView(true)}
            setEditForm={setEditForm}
            deleteHandler={deleteContactHandler}
          />
        )}
      </Grid>

      <DeleteContactDialog
        openModal={open}
        dataList={payloadData}
        formType={typeForm}
        index={multipleIds?.index || 0}
        id={multipleIds?.id || 0}
        handleCloseModal={handleCloseModal}
        educationLevelId={educationLevelId}
      />

      {/* {!formView && (
        <Grid item xs={12}>
          <Grid container justifyContent={'flex-end'} spacing={1}>
            <Grid item>
              <Button
                variant="outlined"
                endIcon={false && <CircularProgress size={20} />}
                onClick={(e) => handleCloseDialog(e)}
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item>
              <LoadingButton
                type="submit"
                variant="contained"
                isLoading={isLoadingCreate || isLoadingUpdate || isFetching}
                onClick={submitForm}
                // disabled={isCreatingRoute || isUpdatingRoute}
                endIcon={false && <CircularProgress size={20} />}
              >
                Guardar
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      )} */}
    </Grid>
  );
};

export default ContactDeanForm;
