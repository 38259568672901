// import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useAppSelector } from '../../../../../hooks/useRedux';
import StatusChip from '../../../../shared/components/badges/StatusChip';
import AddButton from '../../../../shared/components/tables/buttons/AddButton';
import DeleteButton from '../../../../shared/components/tables/buttons/DeleteButton';
// import EditButton from '../../../shared/components/tables/buttons/EditButton';
import SearchPaginatedTable, {
  FilterArrow,
} from '../../../../shared/components/tables/SearchPaginatedTable';
import { useGetStagesPeriodQuery } from '../../slices/periodsApiSlice';
import { useDispatch } from 'react-redux';
import { openDeleteModalPeriodStage, setPeriodStages } from '../../slices/periodsSlice';
import { useTranslation } from 'react-i18next';
import useSharedGuard from '../../../../../hooks/useSharedGuard';
import { subPermissionKeys2 } from '../../constants/permissions';
import EditButton from '../../../../shared/components/tables/buttons/EditButton';

const PeriodStagesTable = function () {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { currentProfile } = useAppSelector((state) => state.access);

  const history = useHistory();

  const { periodId } = useParams<{ periodId: string }>();

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [perPage, setPerPage] = useState(5);

  const [filterArrow, setFilterArrow] = useState<FilterArrow>(null!);

  const { hasAccess: hasAccess2 } = useSharedGuard(subPermissionKeys2);

  const { data, isLoading, isFetching, error } = useGetStagesPeriodQuery(
    {
      periodId: Number(periodId),
      user_profile_id: currentProfile?.profileId!,
      page,
      perPage,
      search,
      ...(filterArrow && { sort: filterArrow.name, type_sort: filterArrow.direction }),
    },
    { refetchOnMountOrArgChange: 30 }
  );

  useEffect(() => {
    setPage(1);
  }, [perPage]);

  useEffect(() => {
    if (data) {
      dispatch(setPeriodStages(data?.data));
    }
  }, [dispatch, data]);

  const datafetch = data?.data!.map((value, index) => ({
    id: index + ((page - 1) * perPage + 1),
    stage_name: value.stage?.stg_name,
    start_date: value.start_date,
    end_date: value.end_date,
    status_id: value.status_id,
    options: {
      index: index,
      stage_id: value.id,
      id: value.stage_id,
      stg_acronym: value.stage?.stg_acronym,
    },
  }));

  const handleDelete = function (periodStage: number) {
    dispatch(openDeleteModalPeriodStage(periodStage));
  };

  const goToAdd = function () {
    history.push(`/administrar-periodos/${periodId}/asignar-etapas`);
  };

  return (
    <SearchPaginatedTable
      data={datafetch!}
      headers={{
        id: t('academic.periods_list.stages.code'),
        stage_name: t('academic.periods_list.stages.name'),
        start_date: t('academic.periods_list.stages.start_date'),
        end_date: t('academic.periods_list.stages.end_date'),
        status_id: t('academic.periods_list.stages.status'),
        options: t('academic.periods_list.stages.options'),
      }}
      keyExtractor={(item) => `${item.options.id}-${item.start_date}-${item.options.index}`}
      listFilterArrows={{
        start_date: 'start_date',
        end_date: 'end_date',
        status_id: 'status_id',
      }}
      filterArrow={filterArrow}
      setFilterArrow={setFilterArrow}
      searchPlacehoder={t('academic.periods_list.stages.search')}
      isLoading={isLoading}
      isFetching={isFetching}
      error={error}
      perPage={perPage}
      setPerPage={setPerPage}
      total={data?.total || 0}
      page={page}
      setPage={setPage}
      numHeader={7}
      search={search}
      setSearch={setSearch}
      customRenderers={{
        status_id: (row) => <StatusChip statusId={Number(row.status_id)} />,
        options: (row) => (
          <>
            {(row.options.stg_acronym === 'I' ||
              row.options.stg_acronym === 'R' ||
              row.options.stg_acronym === 'M') &&
              hasAccess2('edit') && (
                <Link
                  to={`/administrar-periodos/${periodId}/etapa/${Number(row.options.id)}/editar`}
                >
                  <EditButton />
                </Link>
              )}
            {hasAccess2('delete') && (
              <DeleteButton handleDelete={() => handleDelete(Number(row.options.stage_id))} />
            )}
            {!hasAccess2('edit') && !hasAccess2('delete') && 'Ninguna'}
          </>
        ),
      }}
      customHeadersCellsProperties={{
        id: {
          align: 'center',
        },
        options: {
          align: 'center',
        },
      }}
      customDataCellsProperties={{
        id: {
          align: 'center',
        },
        start_date: { align: 'center' },
        end_date: { align: 'center' },
        status_id: {
          align: 'center',
        },
        options: {
          align: 'center',
        },
      }}
      ActionButtons={
        <>{hasAccess2('create') && <AddButton size="large" onClick={() => goToAdd()} />}</>
      }
    />
  );
};

export default PeriodStagesTable;
