import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISearchTable } from '../../../shared/interfaces/paginatedTable.interface';
import { ClassroomEdLvl } from '../../classrooms-educational-levels/interfaces/classroomEdLvl.interface';
import {
  // Course,
  CourseByFilter,
  CoursesByFilterResponse,
  Hourhand,
} from '../interfaces/course.interface';

export interface CoursesFilter {
  offerId: number;
  offerDescription: string;
  campusId: number;
  educationLevelId: number;
  periodId: number;
  periodEndProcess: string;
  hourhandId?: number;
  modalityId?: number;
  parallelId?: number;
  search?: string;
}
interface CourseState {
  deleteModalOpen: boolean;
  courseIdToDelete?: number;

  actualClassroomSelected?: number;
  courseSideModalOpen: boolean;
  courseSideModalMode: string;
  classroom?: ClassroomEdLvl;
  courseIdToEdit?: number;
  coursesList: CourseByFilter[];
  hourhandsAvailables: Hourhand[];
  offerKey?: string;

  isClassroomOnline: boolean;
  coursesTable: ISearchTable;

  coursesFilter: CoursesFilter;
  coursesByFilterResponse?: CoursesByFilterResponse;

  alertMessage: {
    open: boolean;
    message?: any;
    variant?: 'success' | 'error' | 'warning';
  };
}

const initialState: CourseState = {
  deleteModalOpen: false,

  courseSideModalOpen: false,
  courseSideModalMode: 'view',
  coursesList: [],
  hourhandsAvailables: [],

  isClassroomOnline: false,

  coursesTable: {
    page: 1,
    perPage: 5,
    search: '',
  },
  coursesFilter: {
    offerId: 0,
    campusId: 0,
    periodId: 0,
    periodEndProcess: '',
    offerDescription: '',
    educationLevelId: 0,
  },
  alertMessage: {
    open: false,
    message: undefined,
    variant: 'success',
  },
};

const courseSlice = createSlice({
  name: 'course',
  initialState,
  reducers: {
    setDeleteModalOpen: (state, action: PayloadAction<boolean>) => {
      state.deleteModalOpen = action.payload;
    },
    openDeleteModalWith: (state, action: PayloadAction<number>) => {
      state.courseIdToDelete = action.payload;
      state.deleteModalOpen = true;
    },
    resetCourseIdToDelete: (state) => {
      state.courseIdToDelete = undefined;
    },

    setActualClassroomSelected: (state, action: PayloadAction<number>) => {
      state.actualClassroomSelected = action.payload;
    },
    setCourseSideModalOpen: (state, action: PayloadAction<boolean>) => {
      state.courseSideModalOpen = action.payload;
    },
    setCourseSideModalMode: (state, action: PayloadAction<string>) => {
      state.courseSideModalMode = action.payload;
    },
    setCourseList: (state, action: PayloadAction<CourseByFilter[]>) => {
      state.coursesList = action.payload;
    },
    setCoursesByFilterResponse: (state, action: PayloadAction<CoursesByFilterResponse>) => {
      state.coursesByFilterResponse = action.payload;
    },
    setCourseToEdit: (state, action: PayloadAction<number>) => {
      state.courseIdToEdit = action.payload;
    },
    setClassroom: (state, action: PayloadAction<ClassroomEdLvl>) => {
      state.classroom = action.payload;

      //mismo booleano en el componente CardClassroomsAsigned (en ese componente recien se instancia el classroom)
      if (
        action.payload.class_room.classroom_type.clt_name === 'AULA ONLINE' &&
        action.payload.class_room.classroom_type.clt_description === 'O'
      )
        state.isClassroomOnline = true;
      else state.isClassroomOnline = false;
    },
    setHourhandsAvailables: (state, action: PayloadAction<Hourhand[]>) => {
      state.hourhandsAvailables = action.payload;
    },
    setOfferKey: (state, action: PayloadAction<string | undefined>) => {
      state.offerKey = action.payload;
    },
    updateCoursesTable: (state, action: PayloadAction<ISearchTable>) => {
      state.coursesTable = action.payload;
    },
    updateCoursesFilter: (state, action: PayloadAction<CoursesFilter>) => {
      state.coursesFilter = action.payload;
    },
    updatecoursesTableTableStates: (state, action: PayloadAction<ISearchTable>) => {
      state.coursesTable = action.payload;
    },
    setAlertMessage: (
      state,
      action: PayloadAction<{
        open: boolean;
        message?: string;
        variant?: 'success' | 'error' | 'warning';
      }>
    ) => {
      state.alertMessage = action.payload;
    },
  },
});

export const {
  setDeleteModalOpen,
  openDeleteModalWith,
  resetCourseIdToDelete,

  setActualClassroomSelected,
  setCourseSideModalOpen,
  setCourseSideModalMode,
  setCourseList,
  setCoursesByFilterResponse,
  setCourseToEdit,
  setClassroom,
  setHourhandsAvailables,
  setOfferKey,
  updateCoursesTable,

  updateCoursesFilter,
  updatecoursesTableTableStates,
  setAlertMessage,
} = courseSlice.actions;

export default courseSlice.reducer;
