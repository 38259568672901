import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import MainCard from '../../../../shared/components/cards/MainCard';
import Schedule from '../components/Schedule';
import { useTranslation } from 'react-i18next';

const ScheduleScreen = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Grid
        component={MainCard}
        sx={{
          borderTopLeftRadius: '0px',
          borderTopRightRadius: '0px',
        }}
      >
        <Grid sx={{ mb: 3 }}>
          <Typography variant="h2">{t('studentVirtualClassroom.schedule.schedule')}</Typography>
        </Grid>
        <Grid sx={{ mb: 3 }}>
          <Schedule />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default ScheduleScreen;
