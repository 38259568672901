import { Grid, LinearProgress, Typography } from '@material-ui/core';
import { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../../../../../hooks/useRedux';
import OservationAnswer from '../../../../../../shared/user-evaluations/components/evaluation/OservationAnswer';
import QuestionsGroup from '../../../../../../shared/user-evaluations/components/evaluation/QuestionsGroup';
import { useGetAllScopesQuery } from '../../../scope/slices/scopeApiSlice';

const PreviewQuestions = () => {
  const { t } = useTranslation();

  const { id } = useParams<{ id: string }>();

  const { templateList } = useAppSelector((state) => state.templateEva);

  const infoEvaluationToDo = templateList.find((t) => t.id === Number(id));

  const [progress] = useState(0);

  const { data: scopes } = useGetAllScopesQuery({});

  const questionsReduced = useMemo(
    () =>
      infoEvaluationToDo?.eva_evaluations_questions?.reduce<any[][]>((acc, curr) => {
        if (acc.length === 0) {
          acc.push([curr.evaluation_question]);
        } else {
          const index = acc.findIndex(
            (item) => item[0]?.category_uuid === curr.evaluation_question?.category_uuid
          );
          if (index !== -1) {
            acc[index].push(curr.evaluation_question);
          } else {
            acc.push([curr.evaluation_question]);
          }
        }

        return acc;
      }, []),
    [infoEvaluationToDo?.eva_evaluations_questions]
  );

  return (
    <Grid container>
      <Grid item xs={12} mt={4}>
        <Typography variant="body1">{t('user_evaluations.evaluation.steps')}</Typography>
        <LinearProgress variant="determinate" value={progress} />
      </Grid>

      {scopes &&
        questionsReduced?.map((item, idx) => {
          const scopeName = scopes?.find((s) => s.uuid === item[0]?.category_uuid)?.name;
          return (
            <QuestionsGroup
              title={`${idx + 1}. ${scopeName || 'Sin titulo asignado'}`}
              questions={item}
              template={infoEvaluationToDo?.eva_evaluations_template.options!}
              idx={idx + 1}
            />
          );
        })}

      <OservationAnswer />
    </Grid>
  );
};

export default PreviewQuestions;
