import { FieldInputProps } from 'formik';
import { FormHelperText, Grid, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import InputLabel from '../../../../../shared/components/forms/InputLabel';
import { TypePublication } from '../../../constants/typePublication';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface Props {
  errors: any;
  touched: any;
  type: TypePublication;

  getFieldProps: (nameOrOptions: any) => FieldInputProps<any>;
}

// TODO: Aqui se encuentran varios campos que se duplican para cada tipo de publicacion -- refactorizar
const DetailsFormsSplitted = ({ errors, touched, type, getFieldProps }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      {type === 'PAPERS' && (
        <>
          <Grid item xs={12}>
            <InputLabel>{t('publication.GeneralDetailsForm.magazine.title')}</InputLabel>
            <TextField
              {...getFieldProps('magazine')}
              error={Boolean(errors.magazine && touched.magazine)}
              placeholder={t('publication.GeneralDetailsForm.magazine.placeholder')}
              fullWidth
            />
            {errors.magazine && touched.magazine && (
              <FormHelperText error>{t(errors.magazine)}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            <InputLabel>{t('publication.SpecificDetailsForm.magazinesJri.title')}</InputLabel>
            <TextField
              {...getFieldProps('magazinesJri')}
              error={Boolean(errors.magazinesJri && touched.magazinesJri)}
              placeholder={t('publication.SpecificDetailsForm.magazinesJri.placeholder')}
              fullWidth
            />
            {errors.magazinesJri && touched.magazinesJri && (
              <FormHelperText error>{t(errors.magazinesJri)}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            <InputLabel>{t('publication.SpecificDetailsForm.magazinesJrq.title')}</InputLabel>
            <TextField
              {...getFieldProps('magazinesJrq')}
              error={Boolean(errors.magazinesJrq && touched.magazinesJrq)}
              placeholder={t('publication.SpecificDetailsForm.magazinesJrq.placeholder')}
              fullWidth
            />
            {errors.magazinesJrq && touched.magazinesJrq && (
              <FormHelperText error>{t(errors.magazinesJrq)}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={12}>
            <InputLabel>{t('publication.SpecificDetailsForm.urlMagazine.title')}</InputLabel>
            <TextField
              {...getFieldProps('urlMagazine')}
              error={Boolean(errors.urlMagazine && touched.urlMagazine)}
              placeholder={t('publication.SpecificDetailsForm.urlMagazine.placeholder')}
              fullWidth
            />
            {errors.urlMagazine && touched.urlMagazine && (
              <FormHelperText error>{t(errors.urlMagazine)}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12}>
            <InputLabel>{t('publication.SpecificDetailsForm.numberMagazine.title')}</InputLabel>
            <TextField
              {...getFieldProps('numberMagazine')}
              error={Boolean(errors.numberMagazine && touched.numberMagazine)}
              placeholder={t('publication.SpecificDetailsForm.numberMagazine.placeholder')}
              fullWidth
            />
            {errors.numberMagazine && touched.numberMagazine && (
              <FormHelperText error>{t(errors.numberMagazine)}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            <InputLabel>{t('publication.SpecificDetailsForm.doi.title')}</InputLabel>
            <TextField
              {...getFieldProps('pubDOI')}
              error={Boolean(errors?.pubDOI && touched?.pubDOI)}
              placeholder={t('publication.SpecificDetailsForm.doi.placeholder')}
              fullWidth
            />
            {errors.pubDOI && touched.pubDOI && (
              <FormHelperText error>{t(errors.pubDOI)}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            <InputLabel>{t('publication.SpecificDetailsForm.issn.title')}</InputLabel>
            <TextField
              {...getFieldProps('pubISSN')}
              error={Boolean(errors.pubISSN && touched.pubISSN)}
              placeholder={t('publication.SpecificDetailsForm.issn.placeholder')}
              fullWidth
            />
            {errors.pubISSN && touched.pubISSN && (
              <FormHelperText error>{t(errors.pubISSN)}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            <InputLabel>{t('publication.SpecificDetailsForm.editorial.title')}</InputLabel>
            <TextField
              {...getFieldProps('pubEditorial')}
              error={Boolean(errors?.pubEditorial && touched?.pubEditorial)}
              placeholder={t('publication.SpecificDetailsForm.editorial.placeholder')}
              fullWidth
            />
            {errors.pubEditorial && touched.pubEditorial && (
              <FormHelperText error>{t(errors.pubEditorial)}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            <InputLabel>{t('publication.SpecificDetailsForm.numPage.title')}</InputLabel>
            <TextField
              {...getFieldProps('numberPage')}
              type="number"
              // error={Boolean(errors.numberPage && touched.numberPage)}
              placeholder={t('publication.SpecificDetailsForm.editorial.placeholder')}
              fullWidth
            />
            {errors.numberPage && touched.numberPage && (
              <FormHelperText error>{t(errors.numberPage)}</FormHelperText>
            )}
          </Grid>
        </>
      )}

      {type === 'MEMORIAS' && (
        <>
          <Grid item xs={12}>
            <InputLabel>{t('publication.SpecificDetailsForm.nameCongress.title')}</InputLabel>
            <TextField
              {...getFieldProps('nameCongress')}
              error={Boolean(errors.nameCongress && touched.nameCongress)}
              placeholder={t('publication.SpecificDetailsForm.nameCongress.placeholder')}
              fullWidth
            />

            {errors.nameCongress && touched.nameCongress && (
              <FormHelperText error>{t(errors.nameCongress)}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12}>
            <InputLabel>{t('publication.SpecificDetailsForm.organizer.title')}</InputLabel>
            <TextField
              {...getFieldProps('organizer')}
              error={Boolean(errors.organizer && touched.organizer)}
              placeholder={t('publication.SpecificDetailsForm.organizer.placeholder')}
              fullWidth
            />
            {errors.organizer && touched.organizer && (
              <FormHelperText error>{t(errors.organizer)}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={12}>
            <InputLabel>
              {t('publication.SpecificDetailsForm.organizingCommittee.title')}
            </InputLabel>
            <TextField
              {...getFieldProps('organizingCommittee')}
              error={Boolean(errors.organizingCommittee && touched.organizingCommittee)}
              placeholder={t('publication.SpecificDetailsForm.organizingCommittee.placeholder')}
              fullWidth
            />
            {errors.organizingCommittee && touched.organizingCommittee && (
              <FormHelperText error>{t(errors.organizingCommittee)}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12}>
            <InputLabel>{t('publication.AssignsForm.bookISBN.title')}</InputLabel>
            <TextField
              {...getFieldProps('bookISBN')}
              error={Boolean(errors.bookISBN && touched.bookISBN)}
              placeholder={t('publication.AssignsForm.bookISBN.placeholder')}
              fullWidth
            />
            {errors.bookISBN && touched.bookISBN && (
              <FormHelperText error>{t(errors.bookISBN)}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            <InputLabel>{t('publication.SpecificDetailsForm.editorial.title')}</InputLabel>
            <TextField
              {...getFieldProps('pubEditorial')}
              error={Boolean(errors?.pubEditorial && touched?.pubEditorial)}
              placeholder={t('publication.SpecificDetailsForm.editorial.placeholder')}
              fullWidth
            />
            {errors.pubEditorial && touched.pubEditorial && (
              <FormHelperText error>{t(errors.pubEditorial)}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            <InputLabel>{t('publication.SpecificDetailsForm.numPage.title')}</InputLabel>
            <TextField
              {...getFieldProps('numberPage')}
              type="number"
              // error={Boolean(errors.numberPage && touched.numberPage)}
              placeholder={t('publication.SpecificDetailsForm.editorial.placeholder')}
              fullWidth
            />
            {errors.numberPage && touched.numberPage && (
              <FormHelperText error>{t(errors.numberPage)}</FormHelperText>
            )}
          </Grid>
        </>
      )}

      {['CAPITULO LIBRO', 'LIBROS'].some((e) => e === type) && (
        <>
          <Grid item xs={12}>
            <InputLabel>{t('publication.AssignsForm.book.title')}</InputLabel>
            <TextField
              {...getFieldProps('book')}
              error={Boolean(errors.book && touched.book)}
              placeholder={t('publication.AssignsForm.book.placeholder')}
              fullWidth
            />
            {errors.book && touched.book && <FormHelperText error>{t(errors.book)}</FormHelperText>}
          </Grid>

          {/* <Grid item xs={12}>
                <InputLabel>{t('publication.AssignsForm.chapter.title')}</InputLabel>
                <TextField
                  {...getFieldProps('chapter')}
                  error={Boolean(errors.chapter && touched.chapter)}
                  placeholder={t('publication.AssignsForm.chapter.placeholder')}
                  fullWidth
                />
                {errors.chapter && touched.chapter && (
                  <FormHelperText error>{t(errors.chapter)}</FormHelperText>
                )}
              </Grid> */}

          <Grid item xs={12}>
            <InputLabel>{t('publication.AssignsForm.numberChapter.title')}</InputLabel>
            <TextField
              {...getFieldProps('numberChapter')}
              error={Boolean(errors.numberChapter && touched.numberChapter)}
              placeholder={t('publication.AssignsForm.numberChapter.placeholder')}
              fullWidth
            />
            {errors.numberChapter && touched.numberChapter && (
              <FormHelperText error>{t(errors.numberChapter)}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12}>
            <InputLabel>{t('publication.AssignsForm.editor.title')}</InputLabel>
            <TextField
              {...getFieldProps('editor')}
              error={Boolean(errors.editor && touched.editor)}
              placeholder={t('publication.AssignsForm.editor.placeholder')}
              fullWidth
            />
            {errors.editor && touched.editor && (
              <FormHelperText error>{t(errors.editor)}</FormHelperText>
            )}
          </Grid>

          {type === 'LIBROS' && (
            <Grid item xs={12}>
              <InputLabel>{t('publication.AssignsForm.bookEdition.title')}</InputLabel>
              <TextField
                {...getFieldProps('bookEdition')}
                error={Boolean(errors.bookEdition && touched.bookEdition)}
                placeholder={t('publication.AssignsForm.bookEdition.placeholder')}
                fullWidth
              />
              {errors.bookEdition && touched.bookEdition && (
                <FormHelperText error>{t(errors.bookEdition)}</FormHelperText>
              )}
            </Grid>
          )}

          <Grid item xs={12}>
            <InputLabel>{t('publication.AssignsForm.bookISBN.title')}</InputLabel>
            <TextField
              {...getFieldProps('bookISBN')}
              error={Boolean(errors.bookISBN && touched.bookISBN)}
              placeholder={t('publication.AssignsForm.bookISBN.placeholder')}
              fullWidth
            />
            {errors.bookISBN && touched.bookISBN && (
              <FormHelperText error>{t(errors.bookISBN)}</FormHelperText>
            )}
          </Grid>
        </>
      )}
    </>
  );
};

export default DetailsFormsSplitted;
