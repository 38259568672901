import { useTheme } from '@material-ui/core';
import { InsertDriveFileOutlined } from '@material-ui/icons';
import { CancelPresentationOutlined } from '@material-ui/icons';
import { AccessTimeOutlined } from '@material-ui/icons';
import {
  AtrasoInjustificado,
  AtrasoJustificado,
  FaltaInjustificada,
  FaltaRectificada,
} from '../constants';

interface Props {
  type: string;
}

const IconsItem = ({ type }: Props) => {
  const theme = useTheme();

  if (type === AtrasoInjustificado) {
    return (
      <InsertDriveFileOutlined
        sx={{
          color: '#F37F2B',
          fontSize: theme.typography.body1.fontSize,
        }}
      />
    );
  }
  if (type === AtrasoJustificado) {
    return (
      <AccessTimeOutlined
        sx={{
          color: '#1E5D23',
          fontSize: theme.typography.body1.fontSize,
        }}
      />
    );
  }
  if (type === FaltaInjustificada) {
    return (
      <CancelPresentationOutlined
        sx={{
          color: '#B51F23',
          fontSize: theme.typography.body1.fontSize,
        }}
      />
    );
  }
  if (type === FaltaRectificada) {
    return (
      <InsertDriveFileOutlined
        sx={{
          color: '#1E5D23',
          fontSize: theme.typography.body1.fontSize,
        }}
      />
    );
  }

  return (
    <InsertDriveFileOutlined
      sx={{
        color: '#2570B5',
        mr: 1,
      }}
    />
  );
};

export default IconsItem;
