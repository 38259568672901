import { Switch } from 'react-router-dom';
import GuardRoute from '../../../../router/guards/GuardRoute';
import RegistrerVisitsScreen from '../screens/RegistrerVisitsScreen';

const VisitsLibraryRouter = () => {
  return (
    <Switch>
      <GuardRoute exact path="/biblioteca-visitas" component={RegistrerVisitsScreen} />
    </Switch>
  );
};

export default VisitsLibraryRouter;
