// import { PermissionsKeys } from '../../../shared/interfaces/permissions.interface';

export const subPermissionKeys = {
  groupKey: 'student',

  read: 'student-listar-estudiante',
  store: 'student-crear-estudiante',
  update: 'student-update-estudiante',
  delete: 'student-delete-estudiante',
  show: 'student-show-estudiante',

  readOffers: 'offers-listar-ofertas',
  readInstituteTypes: 'institutetype-listar-tipos-de-institutos',
  readInstitutes: 'institutes-listar-institutos',
  onlyParentsEduLvls: 'education-levels-obtener-nivel-educativo',
  readTypeStudents: 'type_students-listar-tipos-estudiantes',
  listEntryTypes: 'entry_types-listar-tipos-ingreso',
  // permissions in certificates dialog
  getStudentRecords: 'students-obtener-student-records-por-id',
  studentPeriods: 'applications-certificates-studentPeriods',
  getPriceAndUrl: 'applications-certificates-eduCerPreview',
  getPeriods: 'periods-listar-periodos',
  getTypeCertificates: 'applications-certificates-types',
  //
}; // satisfies PermissionsKeys;
