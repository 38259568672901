import {
  Table,
  Grid,
  IconButton,
  InputAdornment,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  useTheme,
  TableHead,
  TableBody,
  Typography,
} from '@material-ui/core';
import { IconX } from '@tabler/icons';
import { DebounceInput } from 'react-debounce-input';
import { useRouteMatch } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useAppDispatch } from '../../../../hooks/useRedux';
import StatusChip from '../../../shared/components/badges/StatusChip';
import EditButton from '../../../shared/components/tables/buttons/EditButton';
import { HoursSummaries, HoursSummariesResponse } from '../interfaces/collaborator.interfaces';
import SearchIcon from '@material-ui/icons/Search';
import { renderErrorOrEmptyRow, renderSkeletonRows } from '../../../shared/helpers/render';
import DeleteButton from '../../../shared/components/tables/buttons/DeleteButton';
import { openDeleteModalWith } from '../slice/hoursTeacherSlice';
import IconTelework from '../../../shared/components/illustrations/IconTelework';
import useGuard from '../hooks/useGuard';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { SerializedError } from '@reduxjs/toolkit';
import CustomPaginationAndItems from '../../../shared/components/tables/CustomPaginationAndItems';

interface Props {
  setPage: (setPage: number) => void;
  setPerPage: (setPerPage: number) => void;
  setSearch: (setSearch: string) => void;
  periodId?: string;
  educationLevelId?: string;
  isLoading?: boolean;
  data?: HoursSummariesResponse;
  isFetching?: boolean;
  search?: string;
  perPage: number;
  page: number;
  error?: FetchBaseQueryError | SerializedError;
}

const TeachersTable = ({
  setPage,
  setPerPage,
  setSearch,
  error,
  page,
  perPage,
  periodId,
  educationLevelId,
  isLoading,
  data,
  isFetching,
  search,
}: Props) => {
  const dispatch = useAppDispatch();

  // const classes = useFormStyles();
  // const history = useHistory();
  // const { currentProfile } = useAppSelector((state) => state.access);

  const theme = useTheme();
  const { path } = useRouteMatch();
  const { hasAccess } = useGuard();

  const handleDelete = async (hoursTeacherId: number) => {
    dispatch(openDeleteModalWith(hoursTeacherId));
  };

  const RenderRow = (props: { h: HoursSummaries; index: number }) => {
    // const [openCollapse, setOpenCollapse] = useState(false);
    const { h, index } = props;

    return (
      <>
        <TableRow className="table-row" key={h.id.toString()}>
          {/* <TableCell width="3%" sx={{ pl: 3 }}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpenCollapse(!openCollapse)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell> */}
          <TableCell align="center" width="5%">
            {index + ((page - 1) * perPage + 1)}
          </TableCell>
          <TableCell width="10%">
            {h.collaborator?.user.person?.pers_first_lastname}{' '}
            {h.collaborator?.user.person?.pers_second_lastname}{' '}
            {h.collaborator?.user.person?.pers_firstname}{' '}
            {h.collaborator?.user.person?.pers_secondname}
          </TableCell>
          <TableCell align="center">{h?.hs_classes}</TableCell>
          <TableCell align="center">{h?.hs_classes_examns_preparation}</TableCell>
          <TableCell align="center">{h?.hs_tutoring}</TableCell>
          <TableCell align="center">{h?.hs_bonding}</TableCell>
          <TableCell align="center">{h?.hs_academic_management}</TableCell>
          <TableCell align="center">{h?.hs_research}</TableCell>
          <TableCell align="center">{h?.hs_total}</TableCell>
          <TableCell width="5%" align="center">
            <StatusChip statusId={Number(h.status_id)} />
          </TableCell>
          <TableCell width="5%" align="center">
            {hasAccess('edit') && (
              <Link to={`${path}/${h.id}/editar`}>
                <EditButton />
              </Link>
            )}
            {hasAccess('delete') && <DeleteButton handleDelete={() => handleDelete(h.id)} />}
            {!hasAccess('edit') && !hasAccess('delete') && 'Ninguna'}
          </TableCell>
        </TableRow>
        {/* <TableRow>
          <TableCell
            style={{ paddingBottom: 0, paddingTop: 0 }}
            colSpan={9}
            sx={{ backgroundColor: theme.palette.primary.light }}
          >
            <Collapse in={openCollapse} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <TableContainer>
                  <SubCard title="Horas asignadas" content={false}>
                    <Table size="small" aria-label="purchases">
                      <TableHead>
                        <TableRow>
                          <TableCell align="center" colSpan={4}>
                            Actividades de Docencia
                          </TableCell>
                          <TableCell align="center" colSpan={1}>
                            Actividades de Gestión Acádemica
                          </TableCell>
                          <TableCell align="center" colSpan={1}>
                            Actividades de Investigación
                          </TableCell>
                        </TableRow>
                        <TableRow sx={{ backgroundColor: theme.palette.grey[100] }}>
                          <TableCell align="center">Clases</TableCell>
                          <TableCell align="center">Preparación</TableCell>
                          <TableCell align="center">Tutorias</TableCell>
                          <TableCell align="center">Vinculación</TableCell>
                          <TableCell align="center">Gestión Acádemica</TableCell>
                          <TableCell align="center">Investigación</TableCell>
                          <TableCell align="center">Total</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow hover>
                          <TableCell align="center">{h?.hs_classes}</TableCell>
                          <TableCell align="center">{h?.hs_classes_examns_preparation}</TableCell>
                          <TableCell align="center">{h?.hs_tutoring}</TableCell>
                          <TableCell align="center">{h?.hs_bonding}</TableCell>
                          <TableCell align="center">{h?.hs_academic_management}</TableCell>
                          <TableCell align="center">{h?.hs_research}</TableCell>
                          <TableCell align="center">{h?.hs_total}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </SubCard>
                </TableContainer>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow> */}
      </>
    );
  };

  return (
    <Grid container>
      {periodId && educationLevelId ? (
        <>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            sx={{ mt: 3 }}
          >
            <Grid item xs={12} sm={6}>
              <DebounceInput
                autoFocus={true}
                minLength={2}
                debounceTimeout={300}
                onChange={({ target }) => setSearch(target.value)}
                value={search}
                element={(props) => (
                  <TextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon fontSize="small" />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            size="small"
                            onClick={() => setSearch('')}
                            {...(!search && { sx: { cursor: 'initial', opacity: 0 } })}
                          >
                            <IconX size={20} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    placeholder="Buscar docente"
                    variant="outlined"
                    size="small"
                    {...props}
                  />
                )}
              />
            </Grid>
          </Grid>

          <TableContainer sx={{ mt: 1 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={3}></TableCell>
                  <TableCell align="center" colSpan={4}>
                    Actividades de Docencia
                  </TableCell>
                  <TableCell align="center" colSpan={1}>
                    Actividades de Gestión Acádemica
                  </TableCell>
                  <TableCell align="center" colSpan={1}>
                    Actividades de Investigación
                  </TableCell>
                  <TableCell colSpan={3}></TableCell>
                </TableRow>
                <TableRow>
                  {/* <TableCell align="center"></TableCell> */}
                  <TableCell align="center">N°</TableCell>
                  <TableCell>Nombres</TableCell>
                  <TableCell align="center">Clases</TableCell>
                  <TableCell align="center">Preparación</TableCell>
                  <TableCell align="center">Tutorias</TableCell>
                  <TableCell align="center">Vinculación</TableCell>
                  <TableCell align="center">Gestión Acádemica</TableCell>
                  <TableCell align="center">Investigación</TableCell>
                  <TableCell align="center">Total</TableCell>
                  <TableCell align="center">Estado</TableCell>
                  <TableCell align="center">Opciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isFetching
                  ? renderSkeletonRows(perPage, 11)
                  : data?.data.map((row, index) => (
                      <RenderRow key={row.id} h={row} index={index} />
                    ))}

                {!isFetching && error && renderErrorOrEmptyRow(11, error as string)}
                {!isFetching && data?.data.length === 0 && renderErrorOrEmptyRow(11)}
              </TableBody>
            </Table>
          </TableContainer>

          <CustomPaginationAndItems
            error={error}
            isLoading={isLoading || false}
            total={data?.total || 0}
            perPage={perPage}
            page={page}
            setPage={setPage}
            setPerPage={setPerPage}
            perPageBox
            paginationBox
          />
        </>
      ) : (
        <Grid container justifyContent={'center'} alignContent={'center'} sx={{ height: '50vh' }}>
          <Grid container justifyContent={'center'} item xs={8} md={6}>
            <Grid container item xs={12} justifyContent={'center'}>
              <IconTelework size={300} />
            </Grid>
            <Typography variant={'body1'} textAlign={'center'} lineHeight={2} component={'div'}>
              <Typography
                variant={'h4'}
                sx={{ color: theme.palette.primary.main }}
                component={'p'}
                align={'center'}
                lineHeight={2}
              >
                Selecciona Unidad académica y Período
              </Typography>
              Con los siguientes filtros puede listar en una tabla <strong>los docentes</strong> y
              consultar sus <strong>horas asignadas</strong>.
            </Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default TeachersTable;
