import studentsList from './studentsList.json';
import menu from './menu.json';
import periodsList from './periodsList.json';
import classroomsDistribution from './classroomsDistribution.json';
import courses from './courses.json';
import homologation from './homologation.json';
import requisition from './requisition.json';
import classrooms from './classrooms.json';
import parallels from './parallels.json';
import subjects from './subjects.json';
import areas from './areas.json';
import assignCoursesPosgrade from './assignCoursesPosgrade.json';
import teacherList from './teachersList.json';
import posgraduate from './postgraduate.json';
import academicCouselors from './academicCouselors.json';
import buses from './buses.json';

const academicTranslations = {
  ...menu,
  ...studentsList,
  ...periodsList,
  ...classroomsDistribution,
  ...courses,
  ...homologation,
  ...requisition,
  ...classrooms,
  ...parallels,
  ...subjects,
  ...areas,
  ...assignCoursesPosgrade,
  ...teacherList,
  ...posgraduate,
  ...academicCouselors,
  ...buses,
};

export default academicTranslations;
