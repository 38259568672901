import * as React from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Form, Formik, FormikConfig, FormikValues } from 'formik';
import { makeStyles, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/useRedux';
import { setIsErrorStages, sethourhandsSelected } from '../../../slices/periodsSlice';

export interface FormikStepProps
  extends Pick<FormikConfig<FormikValues>, 'children' | 'validationSchema'> {
  label: string;
}

export interface Props<T> extends FormikConfig<T> {
  blockButtons?: boolean;
}

export const FormikStep = ({ children }: FormikStepProps) => {
  return <>{children}</>;
};
const useStyles = makeStyles((theme) => ({
  root: {
    // "& .MuiStepIcon-active": { color: "red" },
    // "& .MuiStepIcon-completed": { color: "green" },
    // "& .Mui-disabled .MuiStepIcon-root": {
    //    // color: 'black',
    //    backgroundColor: 'white',
    //    fontColor: 'black',
    //    border: '2px solid black',
    //    borderRadius: '50%'
    // }
  },
}));

export const PeriodFormCreateStepper = ({
  children,
  blockButtons,
  ...props
}: Props<FormikValues>) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { isErrorStages } = useAppSelector((state) => state.periods);

  const styles = useStyles();

  const childrenArray = React.Children.toArray(children) as React.ReactElement<FormikStepProps>[];

  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down('md'));

  const [step, setStep] = React.useState(0);

  const [completed, setCompleted] = React.useState(false);

  const currentChild = childrenArray[step];

  const isLastStep = () => step === childrenArray.length - 1;

  return (
    <Formik
      {...props}
      validationSchema={currentChild.props.validationSchema}
      onSubmit={async (values, helpers) => {
        if (isLastStep()) {
          try {
            await props.onSubmit(values, helpers);
            dispatch(sethourhandsSelected([]));
            setCompleted(true);
          } catch (error) {
            console.log(error);
          }
        } else {
          setStep((s) => s + 1);
        }
      }}
    >
      {({ isSubmitting }) => (
        <Grid item xs={12} container>
          <Form autoComplete="off" style={{ width: '100%' }}>
            <Stepper
              className={styles.root}
              sx={{ my: 3 }}
              activeStep={step}
              orientation={matches ? 'vertical' : 'horizontal'}
            >
              {childrenArray.map((child, index) => (
                <Step key={child.props.label} completed={step > index || completed}>
                  <StepLabel>{child.props.label}</StepLabel>
                </Step>
              ))}
            </Stepper>

            {currentChild}

            <Grid container spacing={2} justifyContent={step === 1 ? 'left' : 'center'}>
              {step > 0 ? (
                <Grid item>
                  <Button
                    disabled={isSubmitting || blockButtons}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setStep((s) => s - 1);
                      dispatch(setIsErrorStages(false));
                    }}
                  >
                    {t('academic.periods_list.add.prev')}
                  </Button>
                </Grid>
              ) : null}
              <Grid item>
                <Button
                  startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
                  disabled={isSubmitting || isErrorStages || blockButtons}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  {isSubmitting
                    ? t('academic.periods_list.add.adding')
                    : isLastStep()
                    ? t('academic.periods_list.add.finish')
                    : t('academic.periods_list.add.next')}
                </Button>
              </Grid>
            </Grid>
          </Form>
        </Grid>
      )}
    </Formik>
  );
};
