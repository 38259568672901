import {
  // Badge,
  Box,
  Button,
  ButtonGroup,
  Tooltip,
  Typography,
  Avatar,
  Grid,
} from '@material-ui/core';

import { useTheme } from '@material-ui/core';
import useExpandAccordion from '../../../../../students/virtual-classroom/hooks/useExpandAccordion';
import { IconFileUpload, IconFolderPlus, IconTrash } from '@tabler/icons';
import { AddLink } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

interface Props {
  children: JSX.Element | JSX.Element[] | undefined;
  title: string;
  handleVideoUrl: any;
  handleFile: any;
  handleFolder: any;
  HandleRemove: any;
  actions?: boolean;
  total: number;
  isChildren?: boolean;
}

const AccordeonRaiz = ({
  children,
  title,
  handleFile,
  handleFolder,
  handleVideoUrl,
  actions = true,
  HandleRemove,
  total,
  isChildren,
}: Props) => {
  const { expanded, handleClickToogle } = useExpandAccordion(false);
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          border: `1px solid ${theme.palette.secondary.main}`,
          borderRadius: '8px',
          cursor: 'pointer',
          paddingLeft: '1rem',
          py: 1,
          marginBottom: '0.5rem',
        }}
        onClick={handleClickToogle}
      >
        <Grid item container justifyContent="flex-start" alignItems="center">
          <Typography
            component="span"
            variant="body2"
            sx={{
              fontWeight: theme.typography.fontWeightBold,
              color: theme.palette.primary.dark,
              pt: '5px',
              pr: '5px',
            }}
          >
            {title}
          </Typography>
          <Avatar alt="cantidad" sx={{ width: 15, height: 15 }}>
            <Typography variant="body1" color="white" fontSize={10}>
              {total}
            </Typography>
          </Avatar>
          {/* <Badge color="primary" badgeContent={total}></Badge> */}
        </Grid>

        <Typography
          sx={{
            color: theme.palette.secondary[600],
            paddingX: '1rem',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {actions && (
            <>
              <ButtonGroup>
                <Tooltip title={t('teacherVirtualClassroom.materials.tooltips.crear.link')}>
                  <Button color="secondary" onClick={handleVideoUrl}>
                    <AddLink />
                  </Button>
                </Tooltip>
                <Tooltip title={t('teacherVirtualClassroom.materials.tooltips.crear.file')}>
                  <Button color="secondary" onClick={handleFile}>
                    <IconFileUpload />
                  </Button>
                </Tooltip>
                (
                <Tooltip title={t('teacherVirtualClassroom.materials.tooltips.crear.carpeta')}>
                  <Button color="secondary" onClick={handleFolder}>
                    <IconFolderPlus />
                  </Button>
                </Tooltip>
                )
                <Tooltip title={t('teacherVirtualClassroom.materials.tooltips.eliminar')}>
                  <Button color="secondary" onClick={HandleRemove}>
                    <IconTrash />
                  </Button>
                </Tooltip>
              </ButtonGroup>
            </>
          )}
        </Typography>
      </Box>
      {expanded && (
        <Box
          sx={{
            paddingLeft: '1rem',
          }}
        >
          {children}
        </Box>
      )}
    </Box>
  );
};

export default AccordeonRaiz;
