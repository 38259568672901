import { useTranslation } from 'react-i18next';
import DeleteAlert from '../../../../shared/components/dialogs/DeleteAlert';
// import DeleteAlert from '../../../../shared/components/dialogs/DeleteAlert';
import { useDeleteStudentRelativeMutation } from '../../slices/studentsApiSlice';
// import { resetRoleIdToDelete, setDeleteModalOpen } from '../slices/rolesSlice';

interface Props {
  openModal: boolean;
  dataId: number;
  studentId: string;
  handleCloseModal: () => void;
}

const DeleteRelativeDialog = ({ openModal, studentId, dataId, handleCloseModal }: Props) => {
  const { t } = useTranslation();
  const [deleteRelative, { isLoading, isSuccess, isError, error, reset }] =
    useDeleteStudentRelativeMutation();

  const handleDelete = () => {
    deleteRelative({ relativeId: dataId, studentId });
  };

  const handleClose = () => {
    handleCloseModal();
    setTimeout(() => reset(), 300);
  };

  return (
    <DeleteAlert
      open={openModal}
      onClose={handleClose}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      handleDelete={handleDelete}
      deleteTitle={t('¿Estás seguro de eliminar el familiar?')}
      error={error as string}
    />
  );
};

export default DeleteRelativeDialog;
