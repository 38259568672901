import { Switch, Redirect } from 'react-router-dom';

import { useAppSelector } from '../../../../hooks/useRedux';

import RolesScreen from '../screens/RolesScreen';
import EditRoleScreen from '../screens/EditRoleScreen';
import AddRoleScreen from '../screens/AddRoleScreen';
import GuardRoute from '../../../../router/guards/GuardRoute';

import { permissionKey, subPermissionKeys } from '../constants/permissions';

function RolesRouter() {
  // Verify permission
  const { currentModule } = useAppSelector((state) => state.access);
  const hasPermission = permissionKey in currentModule?.permissions!;

  if (!hasPermission) return <Redirect to="/" />;

  return (
    <Switch>
      <GuardRoute
        exact
        path="/administrar-roles"
        component={RolesScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.read}
      />

      <GuardRoute
        exact
        path={`/administrar-roles/agregar`}
        component={AddRoleScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.create}
      />

      <GuardRoute
        exact
        path={`/administrar-roles/:roleId/editar`}
        component={EditRoleScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.edit}
      />
    </Switch>
  );
}

export default RolesRouter;
