import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISearchTable } from '../../../shared/interfaces/paginatedTable.interface';
import { Institute } from '../interfaces/institutes.interfaces';

interface institutesState {
  instituteList: Institute[];
  deleteModalOpen: boolean;
  instituteIdToDelete?: number;
  institutesTable: ISearchTable;

  economicGroupIdSelected: number | null;
  typeInstituteIdSelected: number | null;
  statusIdSelected: number | null;
  valueMinSelected: string | null;
  valueMaxSelected: string | null;
}

const initialState: institutesState = {
  instituteList: [],
  deleteModalOpen: false,
  institutesTable: {
    page: 1,
    perPage: 5,
    search: '',
  },
  economicGroupIdSelected: null,
  typeInstituteIdSelected: null,
  statusIdSelected: null,
  valueMaxSelected: null,
  valueMinSelected: null,
};

const institutesSlice = createSlice({
  name: 'institutes',
  initialState,
  reducers: {
    setinstituteList: (state, action: PayloadAction<Institute[]>) => {
      state.instituteList = action.payload;
    },
    setDeleteModalOpen: (state, action: PayloadAction<boolean>) => {
      state.deleteModalOpen = action.payload;
    },
    openDeleteModalWith: (state, action: PayloadAction<number>) => {
      state.instituteIdToDelete = action.payload;
      state.deleteModalOpen = true;
    },
    resetinstituteIdToDelete: (state) => {
      state.instituteIdToDelete = undefined;
    },
    updateInstitutesTable: (state, action: PayloadAction<ISearchTable>) => {
      state.institutesTable = action.payload;
    },
    setEconomicGroupIdSelected: (state, action: PayloadAction<number | null>) => {
      state.economicGroupIdSelected = action.payload;
    },
    setTypeInstituteIdSelected: (state, action: PayloadAction<number | null>) => {
      state.typeInstituteIdSelected = action.payload;
    },
    setStatusIdSelected: (state, action: PayloadAction<number | null>) => {
      state.statusIdSelected = action.payload;
    },
    setValueMinSelected: (state, action: PayloadAction<string | null>) => {
      state.valueMinSelected = action.payload;
    },
    setValueMaxSelected: (state, action: PayloadAction<string | null>) => {
      state.valueMaxSelected = action.payload;
    },
  },
});

export const {
  setinstituteList,
  setDeleteModalOpen,
  openDeleteModalWith,
  resetinstituteIdToDelete,
  updateInstitutesTable,
  setEconomicGroupIdSelected,
  setTypeInstituteIdSelected,
  setStatusIdSelected,
  setValueMinSelected,
  setValueMaxSelected,
} = institutesSlice.actions;

export default institutesSlice.reducer;
