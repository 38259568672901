import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Forum } from '../../../shared/interfaces/forum.interfaces';
import { SubjectsByPeriod } from '../interfaces/subjectsByPeriods';

interface AcademicPortfolioState {
  deleteModalOpen: boolean;
  portfolioMatters: SubjectsByPeriod[];
  forumIdToManage?: Number;
  forums: Forum[];
}

const initialState: AcademicPortfolioState = {
  deleteModalOpen: false,
  portfolioMatters: [],
  forums: [],
};

const academicPortfolioSlice = createSlice({
  name: 'academicPortfolio',
  initialState,
  reducers: {
    setPortfolioAcademicMatters: (state, action: PayloadAction<SubjectsByPeriod[]>) => {
      state.portfolioMatters = action.payload;
    },
    setForums: (state, action: PayloadAction<Forum[]>) => {
      state.forums = action.payload;
    },
    setDeleteModalOpen: (state, action: PayloadAction<boolean>) => {
      state.deleteModalOpen = action.payload;
    },
    openDeleteModalWith: (state) => {
      state.deleteModalOpen = true;
    },
    resetForumId: (state) => {
      state.forumIdToManage = undefined;
    },
    setForumToManage: (state, action: PayloadAction<Number>) => {
      state.forumIdToManage = action.payload;
    },
  },
});

export const {
  setDeleteModalOpen,
  openDeleteModalWith,
  setPortfolioAcademicMatters,
  setForums,
  resetForumId,
  setForumToManage,
} = academicPortfolioSlice.actions;

export default academicPortfolioSlice.reducer;
