import { Grid, Typography } from '@material-ui/core';

import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../../hooks/useRedux';
import CloseCashPointDialog from '../components/CloseCashPointDialog';
import CloseBatchCashPointDialog from '../components/CloseBatchCashPointDialog';
import CashPointsTable from '../components/CashPointsTable';
import MainCard from '../../../shared/components/cards/MainCard';
// import BottomSticky from '../../../students/payments-history/components/builder/BottomSticky';
import OpenCashPointDialog from '../components/OpenCashPointDialog';
import ModalRefund from '../components/ModalRefund';

const CashPointsScreen = () => {
  const {
    cashPointToProcess,
    cashPointArrToProcess,
    // totalAmount,
    cashPointToOpen,
    cashPointToRefund,
  } = useAppSelector((state) => state.cashPoints);

  const { t } = useTranslation();

  return (
    <>
      <Grid component={MainCard} sx={{ padding: 2 }}>
        <Grid sx={{ mb: 3 }}>
          <Typography variant="h2">{t('financial.close_cash_point.title')}</Typography>
        </Grid>

        <CashPointsTable />
        {cashPointToProcess && <CloseCashPointDialog />}
        {!!cashPointArrToProcess.length && <CloseBatchCashPointDialog />}
        {!!cashPointToOpen && <OpenCashPointDialog />}
        {cashPointToRefund && <ModalRefund />}
      </Grid>

      <Grid item xs={12}>
        &nbsp;
      </Grid>

      {/* <BottomSticky
        totalAmount={totalAmount}
        alternativeText={t('financial.close_cash_point.report.sticky_btn')}
      /> */}
    </>
  );
};

export default CashPointsScreen;
