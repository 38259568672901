import { Grid, Typography } from '@material-ui/core';
import MainCard from '../../../shared/components/cards/MainCard';
import ActivitiesList from '../components/ActivitiesList';

const MyActivitiesScreen = () => {
  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">Mis actividades</Typography>
      </Grid>
      <ActivitiesList />
    </Grid>
  );
};

export default MyActivitiesScreen;
