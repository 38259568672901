import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import OmniChip from '../../../shared/components/badges/OmniChip';
import { useAppSelector } from '../../../../hooks/useRedux';
import { useTranslation } from 'react-i18next';
import { statusHistoryAcademic } from '../../../students/academic-counseling/constants';
import HistoryTable from '../../../students/academic-counseling/components/tables/HistoryTable';
import { DataSearchHistoryAcademic } from '../../../students/academic-counseling/interfaces/data-search.interfaces';
import { useGetStudentHistoryCalificationQuery } from '../../../students/academic-counseling/slices/academicCounselingApi';
import {
  Autocomplete,
  Button,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
} from '@material-ui/core';
import { useGetStudentRecordsByStudentQuery } from '../slices/studentsApiSlice';

const HistoryCalificationTable = () => {
  const { t } = useTranslation();
  const history = useHistory();

  // const dispatch = useAppDispatch();

  const { studentId } = useParams<{ studentId: string }>();

  const [page, setPage] = useState(1);

  const [perPage, setPerPage] = useState(5);

  const [currentRecordStudentId, setCurrentRecordStudentId] = useState<number | null>(null);

  const [switchAllCourses, setSwitchAllCourses] = useState<boolean>(false);

  const DataSearchBlank: DataSearchHistoryAcademic = {
    period: 0,
    status: 0,
    year: '',
  };

  const { currentProfile } = useAppSelector((state) => state.access);

  const { data: studentRecordsAvaliables = [], isLoading: isLoadingER } =
    useGetStudentRecordsByStudentQuery(
      { studentId },
      { refetchOnMountOrArgChange: 30, skip: !studentId }
    );

  const { data, isLoading, error, isFetching } = useGetStudentHistoryCalificationQuery(
    {
      profileId: currentProfile?.profileId!,
      educationLevel: Number(
        studentRecordsAvaliables?.find((record) => record.id === currentRecordStudentId)
          ?.education_level_id
      )!,
      student: Number(studentId!),
      courses: switchAllCourses ? 'all' : '',
    },
    {
      skip: !studentId || studentId === '' || !currentRecordStudentId,
    }
  );

  useEffect(() => {
    if (studentRecordsAvaliables.length > 0) {
      setCurrentRecordStudentId(studentRecordsAvaliables[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studentRecordsAvaliables]);

  const dataFetch = data?.map((value, index) => ({
    id: index + (page - 1) * perPage + 1,
    year: value.year,
    period: value.per_name,
    matter: `${value.cod_matter_migration} - ${value.mat_name}`,
    user: value.user_full_name || 'N/A',
    final_note: value.final_note,
    num_fouls: value.num_fouls,
    status_matter: {
      period_id: value.period_id,
      approval_status: value.approval_status,
      status_matter: value.status_matter,
    },
  }));

  const [dataSearch, setDataSearch] = useState<DataSearchHistoryAcademic>(DataSearchBlank);

  const sendDataOrFilter = function () {
    if (dataSearch.year !== '' || dataSearch.status !== 0 || dataSearch.period !== 0) {
      return dataFetch
        ?.filter(
          (value) =>
            (dataSearch.year ? value.year === dataSearch.year : true) &&
            (dataSearch.status
              ? value.status_matter.approval_status === dataSearch.status.toString()
              : true) &&
            (dataSearch.period
              ? value.status_matter.period_id === dataSearch.period.toString()
              : true)
        )
        ?.sort((a, b) => Number(b.year) - Number(a.year))!;
    }
    return dataFetch || [];
  };

  return (
    <>
      <Grid container spacing={2}>
        {currentRecordStudentId && (
          <Grid item container xs={12} justifyContent="flex-end">
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                history.push(`/asignar-notas/${studentId}?eduLvlId=${currentRecordStudentId}`)
              }
            >
              {t('academic.students_list.student.assign_grades')}
            </Button>
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <InputLabel>{t('teachers.assign_grades.header.academic_unit')}</InputLabel>
          {studentRecordsAvaliables.length === 1 ? (
            <Autocomplete
              id="combo-box-demo"
              options={studentRecordsAvaliables}
              defaultValue={studentRecordsAvaliables[0]}
              getOptionLabel={(option) =>
                `${option.education_level?.edu_name} (${option.mesh?.anio})`
              }
              //style={{ width: 300 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={t('teachers.assign_grades.header.academic_unit')}
                  variant="outlined"
                />
              )}
            />
          ) : (
            <>
              <Autocomplete
                options={studentRecordsAvaliables}
                loading={isLoadingER}
                loadingText={t('teachers.assign_grades.header.academic_unit_loading')}
                noOptionsText={t('teachers.assign_grades.header.academic_unit.no_data')}
                getOptionLabel={(option) =>
                  `${option.education_level?.edu_name} (${option.mesh?.anio})`
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={t('teachers.assign_grades.header.academic_unit.placeholder')}
                  />
                )}
                onChange={(_, value) => {
                  setCurrentRecordStudentId(value?.id || null);
                }}
                value={studentRecordsAvaliables.find(
                  (record) => record.id === currentRecordStudentId
                )}
              />
              <FormHelperText>
                {t('teachers.assign_grades.header.academic_unit.placeholder')}
              </FormHelperText>
            </>
          )}
        </Grid>

        <Grid item xs={12}>
          <HistoryTable
            data={sendDataOrFilter()}
            headers={{
              id: 'N°',
              year: t('students.academic_counseling.year'),
              period: t('students.academic_counseling.period'),
              matter: t('students.academic_counseling.subject'),
              user: t('students.academic_counseling.registered_by'),
              final_note: t('students.academic_counseling.grade'),
              num_fouls: t('students.academic_counseling.absences'),
              status_matter: t('students.academic_counseling.status'),
            }}
            keyExtractor={(item) => item.id.toString()}
            isLoading={isLoading}
            isFetching={isFetching}
            error={error}
            perPage={perPage}
            setPerPage={setPerPage}
            page={page}
            setPage={setPage}
            numHeader={7}
            dataSearch={dataSearch}
            setDataSearch={setDataSearch}
            switchChecked={switchAllCourses}
            setSwitchChecked={setSwitchAllCourses}
            customRenderers={{
              status_matter: (row) => (
                <OmniChip
                  variant={0}
                  id={
                    statusHistoryAcademic?.find(
                      (e) => e.id === Number(row.status_matter?.approval_status)
                    )?.id || 0
                  }
                  statusArray={statusHistoryAcademic}
                />
              ),
            }}
            customHeadersCellsProperties={{
              status_matter: {
                align: 'center',
              },
              num_fouls: {
                align: 'center',
              },
            }}
            customDataCellsProperties={{
              status_matter: {
                align: 'center',
              },
              num_fouls: {
                align: 'center',
              },
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default HistoryCalificationTable;
