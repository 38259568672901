/**
 *
 * @param currentStatus
 * @param options la intencion de esto es que se cree un objeto o array para usar el mismo tipado del input (pendiente)
 * @returns
 */
export const isAppChangeStatusAvailable = <T extends string>(
  currentStatus: T,
  options?: Partial<Record<T, null | undefined>>
): boolean => {
  return currentStatus !== 'Rechazado'; // || currentStatus === 'Finalizado';
};
