import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  TableBody,
  Table,
  TableContainer,
  TextField,
  Typography,
  TableCell,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core';
import { IconArrowNarrowLeft, IconTrash } from '@tabler/icons';
import { useAppDispatch } from '../../../../../hooks/useRedux';
import { stylesStudentAsignedSendMessage } from '../constants/styles';
import { setNotifyAll } from '../slices/studentsAsignedSlice';
import { useAppSelector } from '../../../../../hooks/useRedux';
import MainCard from '../../../../shared/components/cards/MainCard';
import LayersTwoToneIcon from '@material-ui/icons/LayersTwoTone';
import { useAddStudentAnnouncementMutation } from '../slices/studentsAsignedApiSlice';
import { useFormik } from 'formik';
import { StudentAdsInfoSchema } from '../constants/validation-schema';
import { useState } from 'react';
import SuccessAlert from '../../../../shared/components/alerts/SuccessAlert';
import ErrorAlert from '../../../../shared/components/alerts/ErrorAlert';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { StudentAsigned } from '../interfaces/students-asigned.interfaces';
import moment from 'moment';

import ExtendedSideDialog from '../../../../shared/components/dialogs/ExtendedSideDialog';
import { useTranslation } from 'react-i18next';

const StudentAsignedSendAnnouncementAll = function () {
  const { t } = useTranslation();

  const theme = useTheme();

  const styles = stylesStudentAsignedSendMessage();

  const dispatch = useAppDispatch();

  const [showAlerts, setShowAlerts] = useState<boolean>(false);

  const [open, setOpen] = useState<boolean>(true);

  const [removed, setRemoved] = useState<boolean>(false);

  const { currentProfile } = useAppSelector((state) => state.access);

  const { userInfo } = useAppSelector((state) => state.auth);

  const { studentsList } = useAppSelector((state) => state.studentsAsigned);

  const handleClose = () => {
    dispatch(setNotifyAll(false));
  };

  const [addStudentAds, { error, isError, isSuccess, isLoading }] =
    useAddStudentAnnouncementMutation();

  const { getFieldProps, errors, touched, handleSubmit, resetForm, values, setFieldValue } =
    useFormik({
      initialValues: {
        ans_message: '',
        students: studentsList || [],
      },
      validationSchema: StudentAdsInfoSchema,
      onSubmit: async (values) => {
        const studentsList = values.students.map((value) => Number(value?.user?.user_id));
        await addStudentAds({
          profileId: currentProfile?.profileId!,
          studentAdsPayload: {
            acronym: 'CONS',
            ans_message: values.ans_message,
            receiver: studentsList || [],
            user_profile_id: currentProfile?.profileId!,
            user_id: Number(userInfo?.id!),
          },
        });
        setShowAlerts(true);
      },
    });

  const handleResetListStudents = () => {
    setFieldValue('students', studentsList);
    setOpen(true);
  };

  const handleDeleteStudent = (studentId: number) => {
    const currentValues = values.students.filter(
      (value) => Number(value.user.user_id) !== studentId
    );

    setFieldValue('students', currentValues);

    setRemoved(true);
  };

  const RenderRow = (st: StudentAsigned) => (
    <TableRow hover className="table-row" key={`${st?.user?.user_id}-student`}>
      <TableCell width="50%" sx={{ cursor: 'pointer' }}>
        {st?.user?.person?.pers_first_name} {st?.user?.person?.pers_first_lastname}
        <FormHelperText>{st?.user?.email}</FormHelperText>
      </TableCell>
      <TableCell width="40%" sx={{ cursor: 'pointer' }}>
        {st.lastActivity === null ? (
          <FormHelperText>
            {t('teachers.academic_counseling.students_assigned.announce_all.no_contact')}
          </FormHelperText>
        ) : (
          <FormHelperText>
            {t('teachers.academic_counseling.students_assigned.announce_all.contacted')}{' '}
            {moment(st?.lastActivity).fromNow()}
          </FormHelperText>
        )}
      </TableCell>
      <TableCell width="10%" align="center">
        <Tooltip title={t('teachers.academic_counseling.students_assigned.announce_all.remove')}>
          <IconButton
            aria-label="Eliminar"
            onClick={() => handleDeleteStudent(Number(st?.user?.user_id))}
          >
            <IconTrash size={20} color={theme.palette.error.main} />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );

  const RenderRows = () => {
    return <>{values.students?.map(RenderRow)}</>;
  };

  return (
    <ExtendedSideDialog
      open={true}
      keepMounted
      onClose={() => handleClose()}
      className={styles.container}
      classes={{
        paper: styles.paper,
      }}
    >
      <form onSubmit={handleSubmit}>
        <Grid item xs={12} style={{ marginLeft: 10 }}>
          <Grid item container alignItems="center" gap={1} sx={{ cursor: 'pointer' }}>
            <IconButton onClick={() => handleClose()}>
              <IconArrowNarrowLeft size={30} color={theme.palette.text.dark} />
            </IconButton>
            <Typography variant="h3">
              {t('teachers.academic_counseling.students_assigned.announce_all.back')}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ marginLeft: 20, marginTop: 40, marginBottom: 40 }}>
          <Grid item xs zeroMinWidth>
            <Typography align="left" component="div" variant="h3">
              {t('teachers.academic_counseling.students_assigned.announce_all.send')}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ marginLeft: 20, marginRight: 20 }}>
          <Grid item xs={12}>
            {isError && showAlerts && (
              <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
            )}

            {isSuccess && showAlerts && (
              <SuccessAlert
                message={t('teachers.academic_counseling.students_assigned.announce_all.success')}
                handleDismiss={() => {
                  setShowAlerts(false);
                  resetForm();
                }}
              />
            )}
            {removed && (
              <Grid item sm={12} xs={12}>
                <SuccessAlert
                  message={t('teachers.academic_counseling.students_assigned.announce_all.removed')}
                  handleDismiss={() => setRemoved(false)}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          style={{ marginLeft: 20, marginTop: 40 }}
          justifyContent="space-between"
        >
          <Grid>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? (
                <>
                  <KeyboardArrowUpIcon />{' '}
                  <Typography>
                    {t('teachers.academic_counseling.students_assigned.announce_all.hide')}
                  </Typography>
                </>
              ) : (
                <>
                  <KeyboardArrowDownIcon />
                  <Typography>
                    {t('teachers.academic_counseling.students_assigned.announce_all.view')}
                  </Typography>
                </>
              )}
            </IconButton>
          </Grid>
          <Grid item xs={6}>
            <Button
              onClick={handleResetListStudents}
              disabled={values.students.length === studentsList.length}
              size="small"
              variant="outlined"
            >
              {t('teachers.academic_counseling.students_assigned.announce_all.reset_list')}
            </Button>
          </Grid>
        </Grid>
        {/* List students asigned */}
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box margin={1}>
            <Grid item xs={12} md={12} sx={{ maxHeight: 600, overflow: 'hidden', marginTop: 3 }}>
              <PerfectScrollbar component="div">
                <TableContainer>
                  <Table>
                    <TableBody>{values.students.length === 0 ? null : RenderRows()}</TableBody>
                  </Table>
                </TableContainer>
              </PerfectScrollbar>
            </Grid>
          </Box>
        </Collapse>
        <Grid item xs={12}>
          <MainCard>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <InputLabel>
                  {t('teachers.academic_counseling.students_assigned.announce_all.message')}
                </InputLabel>
                <TextField
                  id="outlined-textarea"
                  placeholder={t(
                    'teachers.academic_counseling.students_assigned.announce_all.what_announce'
                  )}
                  rows={4}
                  fullWidth
                  multiline
                  {...getFieldProps('ans_message')}
                />
                {errors.ans_message && touched.ans_message && (
                  <FormHelperText error>{errors.ans_message}</FormHelperText>
                )}
              </Grid>
              <Grid item xs={12}>
                <Grid container justifyContent="right" spacing={3}>
                  <Grid item>
                    <Button
                      type="submit"
                      size="large"
                      variant="contained"
                      color="primary"
                      startIcon={<LayersTwoToneIcon />}
                      disabled={isLoading || values.students.length === 0}
                      endIcon={isLoading && <CircularProgress size={20} />}
                    >
                      {t('teachers.academic_counseling.students_assigned.announce_all.submit')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </MainCard>
        </Grid>
      </form>
    </ExtendedSideDialog>
  );
};

export default StudentAsignedSendAnnouncementAll;
