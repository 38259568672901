import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../../../hooks/useRedux';
import MainCard from '../../../../../shared/components/cards/MainCard';
import DeleteFormDialog from '../components/DeleteFormDialog';
import ResultadosTable from '../components/ResultadosTable';
import ViewDocumentsDialog from '../components/ViewDocumentsDialog';

const ResultsScreen = () => {
  const { t } = useTranslation();

  const { isOpenDocumentDialog, isOpenDeleteDialogOpen } = useAppSelector(
    (state) => state.formsEva
  );

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">{t('Evaluación Integral')}</Typography>
      </Grid>
      <ResultadosTable />

      {isOpenDocumentDialog && <ViewDocumentsDialog />}
      {isOpenDeleteDialogOpen && <DeleteFormDialog />}
    </Grid>
  );
};

export default ResultsScreen;
