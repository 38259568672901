import Autocomplete from '@material-ui/core/Autocomplete';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ErrorAlert from '../../../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../../../shared/components/alerts/SuccessAlert';
import {
  useAddGroupResearchMutation,
  useGetInvestigationLinesAllAvailablesQuery,
} from '../../../slices/InvestigationLinesApiSlice';
import { GroupResearchSchema } from '../../../constants/validationSchemas.investigationLines';
import InputLabel from '../../../../../shared/components/forms/InputLabel';

const AddInvestigationGroupForm = () => {
  const { t } = useTranslation();

  const history = useHistory();

  const [showAlerts, setShowAlerts] = useState(true);

  const [addGroupResearch, { isLoading, isError, error, isSuccess }] =
    useAddGroupResearchMutation();

  const { data: investigationLinesAvailables, isLoading: isLoadingFalcultad } =
    useGetInvestigationLinesAllAvailablesQuery({});

  const { getFieldProps, errors, touched, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      name: '',
      lineResearchId: 0,
      status: 1,
    },
    validationSchema: GroupResearchSchema,
    onSubmit: async ({ name, lineResearchId, status }) => {
      await addGroupResearch({
        groupResearchPayload: {
          name,
          line_research_id: lineResearchId,
          status,
        },
      });
      window.scrollTo(0, 0);
      setShowAlerts(true);
    },
  });

  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction="column" gap={2}>
        <Grid item xs={12} md={6}>
          <Grid container direction="column" spacing={2}>
            {isError && showAlerts && (
              <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
            )}

            {isSuccess && showAlerts && (
              <SuccessAlert
                message={t('lines_research.AddInvestigationGroup.add.success')}
                handleDismiss={() => setShowAlerts(false)}
              />
            )}
            <Grid item xs={12} md={9}>
              <InputLabel>{t('academic.subjects.add.name')}</InputLabel>
              <TextField
                {...getFieldProps('name')}
                error={Boolean(errors.name && touched.name)}
                placeholder={t('academic.subjects.add.name.placeholder')}
                fullWidth
              />
              {errors.name && touched.name && (
                <FormHelperText error>{t(errors.name)}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12}>
              <InputLabel>{t('publication.AssignsForm.researchLines.title')}</InputLabel>
              <Autocomplete
                options={investigationLinesAvailables || []}
                loading={isLoadingFalcultad}
                loadingText={t('academic.subjects.add.edu_level.loading')}
                noOptionsText={'No hay lineas de investigacion disponibles'}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(errors.lineResearchId && touched.lineResearchId)}
                    placeholder={t('academic.subjects.add.edu_level.placeholder')}
                  />
                )}
                onChange={(_, value) => {
                  setFieldValue('lineResearchId', value?.id || '');
                }}
                fullWidth
                clearIcon={null}
              />
              {errors.lineResearchId && touched.lineResearchId && (
                <FormHelperText error>{t(errors.lineResearchId)}</FormHelperText>
              )}
            </Grid>
          </Grid>

          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            spacing={2}
            sx={{ mt: 6 }}
          >
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="outlined"
                size="large"
                color="primary"
                onClick={() => history.goBack()}
              >
                {t('academic.subjects.add.back')}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                type="submit"
                variant="contained"
                size="large"
                color="primary"
                disabled={isLoading}
                endIcon={isLoading && <CircularProgress size={20} />}
              >
                {t('academic.subjects.add.submit')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default AddInvestigationGroupForm;
