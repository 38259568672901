import {
  Autocomplete,
  Box,
  FormHelperText,
  InputLabel,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core';
import TextFieldFetching from '../../../../../../shared/components/TextFieldFetching';
import { useTranslation } from 'react-i18next';
import { useLazyGetInvestigationLinesByEducationLevelQuery } from '../../../../slices/InvestigationLinesApiSlice';
import { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { EducationLevel } from '../../../../../education-levels/interfaces/educationLevels.interfaces';

interface Props {
  facultadAvailables: EducationLevel[] | undefined;
  isLoadingFacultad: boolean;
}

const LinesInvestigation = ({ facultadAvailables, isLoadingFacultad }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { values, errors, touched, setFieldValue } = useFormikContext<{
    cityId: number;
    provinceId: number;
    educationLevels: {
      id: number | string | null;
      education_level_id: number;
      is_principal: boolean;
    }[];
    educationLevelsPrincipal: string | undefined;
    linesGroupResearch: string[];
    linesGroupResearchPrincipal: string | undefined;
    investigationLines: string[] | undefined;
    investigationLinesPrincipal: string | undefined;
    typeResearch: string;
  }>();

  const [educationLevelId, setEducationLevelId] = useState<number | null>(null);

  const [
    getInvestigationLinesByEducationalLevel,
    { data: investigationLinesAvailables, isLoading: isLoadingInvestigationLines },
  ] = useLazyGetInvestigationLinesByEducationLevelQuery();

  useEffect(() => {
    if (educationLevelId) {
      const executeGetInvestigationLines = async () => {
        await getInvestigationLinesByEducationalLevel({
          educationLevel: educationLevelId,
        });
      };
      executeGetInvestigationLines();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [educationLevelId]);

  return (
    <Box
      sx={{
        paddingTop: '16px',
        paddingLeft: '16px',
        width: '100%',
      }}
    >
      <Box
        alignItems="center"
        sx={{
          width: '100%',
          border: `1px solid ${theme.palette.primary.main}`,
          borderRadius: '8px',
          padding: '25px',
          marginTop: '15px',
        }}
      >
        <Typography variant="h5" color="inherit" mb={2.5}>
          {t('Línea de investigación principal')}
        </Typography>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          {/* unidad academica */}
          <Box
            sx={{
              width: '45%',
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
          >
            <InputLabel>{t('academic.subjects.add.academic_unit')}</InputLabel>
            {isLoadingFacultad ? (
              <TextFieldFetching />
            ) : (
              <Autocomplete
                options={facultadAvailables || []}
                loading={isLoadingFacultad}
                loadingText={t('academic.subjects.add.edu_level.loading')}
                noOptionsText={t('academic.subjects.add.edu_level.no_data')}
                getOptionLabel={(option) => option.edu_name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(errors.educationLevels && touched.educationLevels)}
                    placeholder={t('academic.subjects.add.edu_level.placeholder')}
                  />
                )}
                value={
                  facultadAvailables?.find(
                    ({ id }) => id === Number(values.educationLevelsPrincipal)
                  ) || null
                }
                onChange={(_, value) => {
                  const newArrayId = values.educationLevels?.filter(
                    (e) => e.education_level_id !== Number(values.educationLevelsPrincipal)
                  );

                  if (Array.isArray(newArrayId)) {
                    setEducationLevelId(value?.id || null);
                    setFieldValue('educationLevels', [
                      ...newArrayId,
                      {
                        id: null,
                        education_level_id: value?.id,
                        is_principal: true,
                      },
                    ]);
                    setFieldValue('educationLevelsPrincipal', value?.id);
                  }
                }}
                fullWidth
                clearIcon={null}
              />
            )}
            {errors.educationLevelsPrincipal && touched.educationLevelsPrincipal && (
              <FormHelperText error>{errors.educationLevelsPrincipal}</FormHelperText>
            )}
          </Box>
          {/* lineas de investigacion */}
          <Box
            sx={{
              width: '45%',
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
          >
            <InputLabel> {t('project.specificDetail.investigationLines.title')} </InputLabel>
            {isLoadingInvestigationLines ? (
              <TextFieldFetching />
            ) : (
              <Autocomplete
                options={investigationLinesAvailables || []}
                loading={isLoadingInvestigationLines}
                value={
                  investigationLinesAvailables?.find(
                    (item) => item.id === values.investigationLinesPrincipal
                  ) || null
                }
                loadingText={t('academic.subjects.add.edu_level.loading')}
                noOptionsText={t('project.specificDetail.investigationLines.no_option')}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(errors.investigationLines && touched.investigationLines)}
                    placeholder={t('project.specificDetail.investigationLines.placeholder')}
                  />
                )}
                onChange={(_, value) => {
                  const newArrayId = values.investigationLines?.filter(
                    (e) => e !== values.investigationLinesPrincipal
                  );
                  if (Array.isArray(newArrayId)) {
                    setFieldValue('investigationLines', [...newArrayId, value?.id]);
                    setFieldValue('investigationLinesPrincipal', value?.id);
                  }
                }}
                fullWidth
                clearIcon={null}
              />
            )}
            {errors.investigationLinesPrincipal && touched.investigationLinesPrincipal && (
              <FormHelperText error>{errors.investigationLinesPrincipal}</FormHelperText>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LinesInvestigation;
