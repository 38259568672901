import Box from '@material-ui/core/Box';
import { useParams } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import { useAppSelector } from '../../../../../hooks/useRedux';
import PeriodLane from './PeriodLane';
import LaneSkeleton from '../skeleton/LaneSkeleton';
import { useGetMeshQuery } from '../../slices/meshsApiSlice';
import { Chip, Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBlockStart: '20px',
    width: '100%',
    overflowX: 'auto',
    display: 'grid',
    gap: '16px',
    // gridTemplateColumns: 'repeat(8, minmax(332px, 30%))',
    gridTemplateRows: 'minmax(150px, 1fr)',
    // '&::-webkit-scrollbar': {
    //   height: '4px',
    // },
    // '&::-webkit-scrollbar-track': {
    //   borderRadius: '12px',
    //   backgroundColor: 'rgba(0, 0, 0, 0,1)',
    // },

    // '&::-webkit-scrollbar-thumb': {
    //   backgroundColor: theme.palette.grey[300],
    //   borderRadius: '100px',
    // },
  },
}));

const PeriodsLaneGrid = () => {
  const classes = useStyles();

  const { meshId: meshIdParam } = useParams<{ meshId: string }>();
  const meshId = Number(meshIdParam);

  const { currentProfile } = useAppSelector((state) => state.access);

  const { simbologyIdSelected } = useAppSelector((state) => state.meshs);

  const profileId = currentProfile!.profileId;

  const { data, isFetching } = useGetMeshQuery({ profileId, meshId });

  //const isOlderCurriculum = data?.matter_mesh.some((sc) => sc.group === null);
  const isOlderCurriculum = Boolean(Number(data?.curriculum_new));
  const areasName = data?.matter_mesh.map((sc) => sc.matter.area?.ar_name) || [];
  const areasNameArr = [...new Set(areasName)];

  // const simbologyarr = data?.matter_mesh.map((sm) => sm.simbology?.sim_description || []);

  return isFetching ? (
    <LaneSkeleton />
  ) : (
    <>
      <Grid container alignItems="center" spacing={1}>
        <Grid item>
          <Typography color="black">N° de períodos: </Typography>
        </Grid>
        <Grid item>
          <Chip
            variant="filled"
            color="primary"
            size="small"
            label={!isOlderCurriculum ? areasNameArr.length : data?.mes_number_period}
          />
        </Grid>
      </Grid>
      <Box
        className={classes.root}
        sx={{ gridTemplateColumns: `repeat(${data?.mes_number_period}, 350px)` }}
      >
        {!isOlderCurriculum
          ? areasNameArr.map((areaName, index) => {
              const subjects =
                data?.matter_mesh
                  .filter((m) => m.matter?.area?.ar_name === areaName)
                  ?.filter((sm) => {
                    if (simbologyIdSelected === null) return true;

                    return Number(sm.simbology_id) === simbologyIdSelected;
                  }) || [];

              return (
                <PeriodLane
                  isOlder
                  key={index}
                  periodTitle={areaName!}
                  subjects={subjects}
                  group={index + 1}
                />
              );
            })
          : Array.from(new Array(data?.mes_number_period)).map((n, index) => {
              const subjects =
                data?.matter_mesh
                  .filter((m) => m.group === index + 1)
                  .filter((sm) => {
                    if (simbologyIdSelected === null) return true;

                    return Number(sm.simbology_id) === simbologyIdSelected;
                  }) || [];

              return (
                <PeriodLane
                  key={index}
                  periodTitle={`Período ${index + 1}`}
                  subjects={subjects}
                  group={index + 1}
                />
              );
            })}
      </Box>
    </>
  );
};

export default PeriodsLaneGrid;
