import { useCallback, useEffect, useState } from 'react';
import { Box, CircularProgress, Grid, Tab, Tabs, Typography } from '@material-ui/core';
import { useStyles } from '../../../constants/styles';
import { Link as RouterLink } from 'react-router-dom';
import MainCard from '../../../../../shared/components/cards/MainCard';
import { useFormikContext } from 'formik';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/useRedux';
import GenerationQuote from '../tabs/GenerationQuote';
import RegisterMatterAdmin from './RegisterMatterAdmin';
import RegisterMatterStudentPortal from './RegisterMatterStudentPortal';
import RequestMatterGrade from './RequestMatterGrade';
import EntryTeachersCourse from './EntryTeachersCourse';
import RegisterMatter from './RegisterMatter';
import StartProgramMaestry from './StartProgramMaestry';
import StartClass from './StartClass';
import { PeriodForm, Stage } from '../../../interfaces/period-form.interfaces';
import { useGetStagesByOfferAcademicConfigQuery } from '../../../slices/periodsApiSlice';
import {
  STAGES,
  GENERACION_CUOTAS,
  INGRESO_DOCENTES_CURSO,
  INICIO_PROGRAMA_MAESTRIA,
  REGISTRO_ASIGNATURAS,
  REGISTRO_MATERIAS_ADMIN,
  INICIO_CLASES,
  REGISTRO_MATERIAS_PORTAL_ESTUDIANTIL,
  SOLICITUD_APERTURA_MATERIAS_GRADO,
} from '../../../constants/stages';
import { useTranslation } from 'react-i18next';
import { setIsErrorStages } from '../../../slices/periodsSlice';

export const StagesTabs = function () {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const classes = useStyles();

  const [tabValue, setTabValue] = useState(0);

  const { values, setFieldError, setFieldValue } = useFormikContext<PeriodForm>();

  const { currentProfile } = useAppSelector((state) => state.access);

  const { academicOfferConfig } = useAppSelector((state) => state.periods);

  const config = academicOfferConfig?.map((e) => e.stg_acronym);

  const arrayPeriodStagesDates = useCallback((quotas: number) => {
    const initialDate: Stage = {
      start_date: null,
      end_date: null,
      education_level_id: null,
      course_id: null,
      parallel_id: null,
      status_id: 1,
      stg_acronym: 'RC',
    };
    let initialDates: Stage[] = [];
    for (var i = 0; i < quotas; i++) {
      initialDates.push(initialDate);
    }

    return initialDates;
  }, []);

  const checkStageValid = (fieldName: string, iterator: number): boolean => {
    if (fieldName === 'generation_quote') {
      if (values.numFees > 0 && values.generation_quote?.length === 0) {
        setFieldError(
          'generation_quote',
          t('academic.periods_list.add.generation_quote.num_fees.required')
        );
        return false;
      }

      if (
        values.numFees > 0 &&
        values.generation_quote?.length > 0 &&
        values.generation_quote.some((e) => e.start_date === null)
      ) {
        setFieldError(
          'generation_quote',
          t('academic.periods_list.add.generation_quote.start_date.required')
        );
        return false;
      }

      if (
        values.numFees > 0 &&
        values.generation_quote?.length > 0 &&
        values.generation_quote.some((e) => e.end_date === null)
      ) {
        setFieldError(
          'generation_quote',
          t('academic.periods_list.add.generation_quote.end_date.required')
        );
        return false;
      }

      setFieldError('generation_quote', undefined);
      return true;
    }

    const field = values[fieldName as unknown as keyof PeriodForm] as Stage;
    const { start_date, end_date } = field;

    if (start_date === null) {
      setFieldError(
        `${STAGES[iterator].field}`,
        `${t(STAGES[iterator].descriptionId)},  ${t(
          'academic.periods_list.add.start_date.required'
        )}`
      );
      dispatch(setIsErrorStages(true));
      return false;
    }
    if (end_date === null) {
      setFieldError(
        `${STAGES[iterator].field}`,
        `${STAGES[iterator].description}, ${t('academic.periods_list.add.end_date.required')}`
      );
      dispatch(setIsErrorStages(true));
      return false;
    }
    setFieldError(`${STAGES[iterator].field}`, undefined);
    dispatch(setIsErrorStages(false));
    return true;
  };

  const checkAvailablesDates = () => {
    for (let i = 0; i < STAGES.length; i++) {
      if (config.includes(STAGES[i].use_case)) {
        checkStageValid(STAGES[i].field, i);
      }
    }
  };

  const handleChange = (event: React.SyntheticEvent | null, newValue: number) => {
    checkAvailablesDates();
    setTabValue(newValue);
  };

  useEffect(() => {
    checkAvailablesDates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  useEffect(() => {
    if (values.generation_quote?.length === 0) {
      setFieldValue('generation_quote', arrayPeriodStagesDates(values.numFees));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.numFees]);

  const { isLoading, isFetching } = useGetStagesByOfferAcademicConfigQuery(
    {
      offerId: values.academicOfferId,
      profileId: currentProfile?.profileId!,
    },
    { skip: values.academicOfferId === 0 }
  );

  const TabPanel = (props: { children: React.ReactElement; value: number; index: number }) => {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={0}>{children}</Box>}
      </div>
    );
  };

  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  const RenderTabs = () => {
    return (
      <Grid sx={{ marginTop: 2 }}>
        <Tabs
          value={tabValue}
          indicatorColor="primary"
          className={classes.accountTab}
          aria-label="vertical"
          orientation="vertical"
          onChange={handleChange}
          sx={{ borderRight: 2, borderColor: 'divider' }}
        >
          <Tab
            sx={{
              display: config?.includes(GENERACION_CUOTAS) ? 'block' : 'none',
              textTransform: 'none',
            }}
            component={RouterLink}
            to="#"
            label={t('academic.periods_list.add.stage_3.tab_1')}
            {...a11yProps(0)}
          />
          <Tab
            sx={{
              display: config?.includes(REGISTRO_MATERIAS_ADMIN) ? 'block' : 'none',
              textTransform: 'none',
            }}
            component={RouterLink}
            to="#"
            label={t('academic.periods_list.add.stage_3.tab_2')}
            {...a11yProps(1)}
          />
          <Tab
            sx={{
              display: config?.includes(REGISTRO_MATERIAS_PORTAL_ESTUDIANTIL) ? 'block' : 'none',
              textTransform: 'none',
            }}
            component={RouterLink}
            to="#"
            label={t('academic.periods_list.add.stage_3.tab_3')}
            {...a11yProps(2)}
          />
          <Tab
            sx={{
              display: config?.includes(INICIO_PROGRAMA_MAESTRIA) ? 'block' : 'none',
              textTransform: 'none',
            }}
            component={RouterLink}
            to="#"
            label={t('academic.periods_list.add.stage_3.tab_4')}
            {...a11yProps(3)}
          />
          <Tab
            sx={{
              display: config?.includes(SOLICITUD_APERTURA_MATERIAS_GRADO) ? 'block' : 'none',
              textTransform: 'none',
            }}
            component={RouterLink}
            to="#"
            label={t('academic.periods_list.add.stage_3.tab_5')}
            {...a11yProps(4)}
          />
          <Tab
            sx={{
              display: config?.includes(INGRESO_DOCENTES_CURSO) ? 'block' : 'none',
              textTransform: 'none',
            }}
            component={RouterLink}
            to="#"
            label={t('academic.periods_list.add.stage_3.tab_6')}
            {...a11yProps(5)}
          />
          <Tab
            sx={{
              display: config?.includes(REGISTRO_ASIGNATURAS) ? 'block' : 'none',
              textTransform: 'none',
            }}
            component={RouterLink}
            to="#"
            label={t('academic.periods_list.add.stage_3.tab_7')}
            {...a11yProps(6)}
          />
          <Tab
            sx={{
              display: config?.includes(INICIO_CLASES) ? 'block' : 'none',
              textTransform: 'none',
            }}
            component={RouterLink}
            to="#"
            label={t('academic.periods_list.add.stage_3.tab_8')}
            {...a11yProps(7)}
          />
        </Tabs>
      </Grid>
    );
  };

  // const RenderErrors = () => {
  //   return (
  //     <>
  //       <Grid item xs={12} sx={{ mt: 2, width: 1 }}>
  //         <InfoAlert message={Object.values(errors).map((e) => e.toString())!} />
  //       </Grid>
  //     </>
  //   );
  // };

  const Loader = () => {
    return (
      <Grid container item justifyContent="center" alignItems="center">
        <CircularProgress size={50} />
      </Grid>
    );
  };

  return (
    <Grid item direction="row">
      {isLoading || isFetching ? (
        <Loader />
      ) : (
        <Grid
          p={2}
          sx={{
            mt: 1,
            border: `3px solid #F2F3F4`,
            borderRadius: '10px',
          }}
          component={MainCard}
        >
          <Grid container direction="row" xs={12}>
            <Grid xs={3}>
              <Grid xs={9} spacing={2}>
                <Typography variant="h3" sx={{ textAlign: 'center' }}>
                  Etapas
                </Typography>
                <RenderTabs />
              </Grid>
            </Grid>
            {config?.length !== 0 && (
              <Grid xs={9}>
                <TabPanel value={tabValue} index={0}>
                  <GenerationQuote />
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                  <RegisterMatterAdmin />
                </TabPanel>
                <TabPanel value={tabValue} index={2}>
                  <RegisterMatterStudentPortal />
                </TabPanel>
                <TabPanel value={tabValue} index={3}>
                  <StartProgramMaestry />
                </TabPanel>
                <TabPanel value={tabValue} index={4}>
                  <RequestMatterGrade />
                </TabPanel>
                <TabPanel value={tabValue} index={5}>
                  <EntryTeachersCourse />
                </TabPanel>
                <TabPanel value={tabValue} index={6}>
                  <RegisterMatter />
                </TabPanel>
                <TabPanel value={tabValue} index={7}>
                  <StartClass />
                </TabPanel>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
