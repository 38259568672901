import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import BackButton from '../../../shared/components/buttons/BackButton';

import MainCard from '../../../shared/components/cards/MainCard';
import Binnacle from '../components/Binnacle';

const BinnacleStudentByProject = () => {
  const { t } = useTranslation();

  return (
    <Grid component={MainCard} sx={{ padding: 2, minHeight: '500px' }}>
      <Grid sx={{ mb: 3, display: 'flex', alignItems: 'center' }}>
        <BackButton />
        <Typography variant="h2">{t('Bitácora')}</Typography>
      </Grid>
      <Binnacle />
    </Grid>
  );
};

export default BinnacleStudentByProject;
