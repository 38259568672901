import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISearchTable } from '../../../shared/interfaces/paginatedTable.interface';

interface SyllabusState {
  syllabusTable: ISearchTable;
  isOpenChangeStatusModal: boolean;
  syllabusIdSelected: string | number | null;
  isOpenHistorialSyllabusModal: boolean;
}

const initialState: SyllabusState = {
  syllabusTable: {
    page: 1,
    perPage: 5,
    search: '',
  },
  isOpenChangeStatusModal: false,
  isOpenHistorialSyllabusModal: false,
  syllabusIdSelected: null,
};

const syllabusSlice = createSlice({
  name: 'syllabus',
  initialState,
  reducers: {
    updateSyllabusTable: (state, action: PayloadAction<ISearchTable>) => {
      state.syllabusTable = action.payload;
    },
    setIsOpenChangeStatusModal: (state, action: PayloadAction<boolean>) => {
      state.isOpenChangeStatusModal = action.payload;
    },
    setIsOpenHistorialSyllabusModal: (state, action: PayloadAction<boolean>) => {
      state.isOpenHistorialSyllabusModal = action.payload;
    },
    setSyllabusIdSelected: (state, action: PayloadAction<string | number | null>) => {
      state.syllabusIdSelected = action.payload;
    },
  },
});

export const {
  updateSyllabusTable,
  setIsOpenChangeStatusModal,
  setSyllabusIdSelected,
  setIsOpenHistorialSyllabusModal,
} = syllabusSlice.actions;

export default syllabusSlice.reducer;
