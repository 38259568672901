import { ButtonProps, Button, CircularProgress } from '@material-ui/core';

interface LoadingButtonInterface extends ButtonProps {
  isLoading: boolean;
  children?: React.ReactElement | string;
}

const LoadingButton = ({ isLoading, children, ...propsButton }: LoadingButtonInterface) => {
  return (
    <Button
      disabled={isLoading}
      variant="contained"
      color="primary"
      endIcon={isLoading && <CircularProgress size={20} />}
      {...propsButton}
    >
      {children || 'Guardar'}
    </Button>
  );
};

export default LoadingButton;
