import { Avatar, Box, Typography } from '@material-ui/core';
import moment from 'moment';
import { useGetRedlinksAvatarQueryQuery } from '../../../../shared/slices/redlinksApi';
import { AdvertisementsData } from '../interfaces/as.interfaces';

interface Props {
  advertisement: AdvertisementsData;
}

const AdvertisementsItem = ({ advertisement }: Props) => {
  const {
    type_announcement,
    ans_message,
    announcer: { person },
    ans_date_send,
  } = advertisement;
  const { pers_first_lastname, pers_firstname, pers_identification } = person;

  const { data } = useGetRedlinksAvatarQueryQuery({
    identification: pers_identification,
  });

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 2,
        borderBottom: '1px solid #e0e0e0',
        py: 1,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
          }}
        >
          <Avatar
            sx={{
              width: '25px',
              height: '25px',
            }}
            alt={`${pers_first_lastname} ${pers_firstname}`}
            src={data ? data?.result?.foto : ''}
          ></Avatar>
        </Box>

        <Typography
          variant="h5"
          sx={{
            ml: '10px',
            mr: '25px',
            fontWeight: 'bold',
            width: '125px',
          }}
        >
          {`${pers_first_lastname} ${pers_firstname}`}
        </Typography>
        <Typography
          sx={{
            mr: 1,
            textAlign: 'center',
          }}
        >
          {type_announcement.name}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="subtitle2"
            sx={{
              color: '#000',
              px: 3,
            }}
          >
            {ans_message}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <Typography
          variant="subtitle2"
          sx={{
            ml: 0.5,
            marginRight: '5px',
          }}
        >
          {moment(ans_date_send.toLocaleString()).format('DD MMM - hh:mm').replace('.', '')}
        </Typography>
      </Box>
    </Box>
  );
};

export default AdvertisementsItem;
