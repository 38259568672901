import { enUS, es } from 'date-fns/locale';
import { useAppSelector } from '../../hooks/useRedux';

const languages = {
  es: es,
  en: enUS,
};

export const formatDatesLangs = {
  //yyyy-MM-dd'T'HH:mm:ss
  normalizadDateTime: "yyyy-MM-dd'T'HH:mm:ss",
  //HH:mm:ss
  normalizadTimeSS: 'HH:mm:ss',
  //HH:mm
  normalizadTime: 'HH:mm',

  es: 'yyyy-MM-dd',
  esTime: "yyyy-MM-dd'T'HH:mm:ss",
};

export const useGetLocaleDateLang = () => {
  const { language } = useAppSelector((state) => state.customization);

  return {
    lang: languages[language],
    langKey: language,
    dateFormat: formatDatesLangs[language as keyof typeof formatDatesLangs],
    dateTimeFormat: formatDatesLangs[`${language}Time` as keyof typeof formatDatesLangs],
  };
};
