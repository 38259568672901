import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Collaborator } from '../../../hr/collaborators/interfaces/collaborators.interfaces';
import { ISearchTable } from '../../../shared/interfaces/paginatedTable.interface';

interface AcademicCounselorsState {
  academicCounselorsList: Collaborator[];
  academicCounselorsTable: ISearchTable;
}

const initialState: AcademicCounselorsState = {
  academicCounselorsList: [],
  academicCounselorsTable: {
    page: 1,
    perPage: 5,
    search: '',
  },
};

const academicCounselorsSlice = createSlice({
  name: 'academicCounselor',
  initialState,
  reducers: {
    setCounselorsList: (state, action: PayloadAction<Collaborator[]>) => {
      state.academicCounselorsList = action.payload;
    },
    updateCopunselorsTableStates: (state, action: PayloadAction<ISearchTable>) => {
      state.academicCounselorsTable = action.payload;
    },
  },
});

export const { setCounselorsList, updateCopunselorsTableStates } = academicCounselorsSlice.actions;

export default academicCounselorsSlice.reducer;
