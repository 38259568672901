import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import {
  Autocomplete,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
  InputAdornment,
} from '@material-ui/core';

import { useFormik } from 'formik';

import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';

import { useAppSelector } from '../../../../hooks/useRedux';
import { ValidationSchema } from '../constants/validationSchemas';

import { useUpdateLearningComponentsMutation } from '../slices/learningComponentsApiSlice';

import { statusAvailables } from '../../../shared/constants/resourceStatus';
import { useFormStyles } from '../constants/styles';
import { useGetAvailablesAreaQuery } from '../../matters/slices/mattersApiSlice';
import { componentProcess } from '../constants/mockOptions';
import { useGetAcademicOfferingsAvailablesQuery } from '../../periods/slices/periodsApiSlice';

const EditLearningComponentForm = () => {
  const history = useHistory();
  const { componentId } = useParams<{ componentId: string }>();

  const { learningComponentList } = useAppSelector((state) => state.learningComponents);
  const { currentProfile } = useAppSelector((state) => state.access);

  const classes = useFormStyles();

  const [showAlerts, setShowAlerts] = useState(false);

  const [updateLearningComponent, { isLoading: isUpdating, isSuccess, isError, error }] =
    useUpdateLearningComponentsMutation();

  const { data: areasAvailables = [], isLoading: isLoadingAreas } = useGetAvailablesAreaQuery({
    profileId: currentProfile?.profileId!,
  });

  const { data: offersAvailables = [], isLoading: isLoadingOffers } =
    useGetAcademicOfferingsAvailablesQuery({
      profileId: currentProfile?.profileId!,
    });

  const learningComponentToEdit = learningComponentList.find((p) => p.id === Number(componentId));

  const { getFieldProps, errors, touched, handleSubmit, setFieldValue, values } = useFormik({
    initialValues: {
      comAcronym: learningComponentToEdit?.com_acronym || '',
      comName: learningComponentToEdit?.com_name || '',
      areaId: learningComponentToEdit?.area_id ? Number(learningComponentToEdit?.area_id) : null,
      offerId: learningComponentToEdit?.offer_id || '',
      statusId: learningComponentToEdit?.status_id || '',
      process: learningComponentToEdit?.process || null,
    },
    validationSchema: ValidationSchema,
    onSubmit: async (values) => {
      await updateLearningComponent({
        learningComponentId: learningComponentToEdit?.id!,
        LearningComponentsPayload: {
          area_id: values.areaId,
          offer_id: values.offerId,
          com_name: values.comName,
          status_id: values.statusId.toString(),
          com_acronym: values.comAcronym,
          process: values.process || '',
        },
      });
      setShowAlerts(true);
    },
  });

  return (
    <form onSubmit={handleSubmit}>
      <Grid container gap={2}>
        <Grid item xs={12}>
          {isError && showAlerts && (
            <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
          )}

          {isSuccess && showAlerts && (
            <SuccessAlert
              message="El componente de aprendizaje ha sido actualizado correctamente."
              handleDismiss={() => setShowAlerts(false)}
            />
          )}
        </Grid>
        <Grid item container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}> Nombre </InputLabel>
            <TextField
              fullWidth
              defaultValue={values.comName}
              error={Boolean(errors.comName && touched.comName)}
              placeholder={`Ingrese un nombre`}
              {...getFieldProps('comName')}
            />
            {errors.comName && touched.comName && (
              <FormHelperText error>{errors.comName}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>Acrónimo</InputLabel>
            <TextField
              fullWidth
              defaultValue={values.comAcronym}
              error={Boolean(errors.comAcronym && touched.comAcronym)}
              placeholder={`Ingrese un acrónimo`}
              {...getFieldProps('comAcronym')}
            />
            {errors.comAcronym && touched.comAcronym && (
              <FormHelperText error>{errors.comAcronym}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>Área</InputLabel>
            {isLoadingAreas ? (
              <>
                <TextField
                  disabled
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <CircularProgress size={25} />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Cargando datos ..."
                />
              </>
            ) : (
              <>
                <Autocomplete
                  options={areasAvailables}
                  loading={isLoadingAreas}
                  loadingText="Cargando áreas disponibles..."
                  getOptionLabel={(option) => option.ar_name}
                  value={areasAvailables.find((e) => e.id === values.areaId) || null}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Seleccione un área"
                      error={Boolean(errors.areaId && touched.areaId)}
                    />
                  )}
                  onChange={(e, value) => setFieldValue('areaId', value?.id || null)}
                  fullWidth
                />
                {errors.areaId && touched.areaId && (
                  <FormHelperText error>{errors.areaId}</FormHelperText>
                )}
              </>
            )}
          </Grid>

          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>Oferta académica</InputLabel>
            {isLoadingOffers ? (
              <>
                <TextField
                  disabled
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <CircularProgress size={25} />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Cargando datos ..."
                />
              </>
            ) : (
              <>
                <Autocomplete
                  options={offersAvailables}
                  loading={isLoadingOffers}
                  loadingText="Cargando ofertas académicas disponibles..."
                  getOptionLabel={(option) => option.off_name}
                  value={offersAvailables.find((e) => e.id.toString() === values.offerId)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Seleccione una oferta académica"
                      error={Boolean(errors.offerId && touched.offerId)}
                    />
                  )}
                  onChange={(e, value) => setFieldValue('offerId', value?.id?.toString() || '')}
                  fullWidth
                  clearIcon={null}
                />
                {errors.offerId && touched.offerId && (
                  <FormHelperText error>{errors.offerId}</FormHelperText>
                )}
              </>
            )}
          </Grid>

          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>Proceso</InputLabel>
            <Autocomplete
              options={componentProcess}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Seleccione un proceso"
                  error={Boolean(errors.process && touched.process)}
                />
              )}
              defaultValue={componentProcess.find((dt) => dt.name === values.process)}
              onChange={(e, value) => setFieldValue('process', value?.name || '')}
              fullWidth
              clearIcon={null}
            />
            {errors.process && touched.process && (
              <FormHelperText error>{errors.process}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>Estado</InputLabel>
            <Autocomplete
              options={statusAvailables}
              getOptionLabel={(option) => option.name}
              defaultValue={statusAvailables.find((e) => e.id.toString() === values.statusId)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Seleccione un estado"
                  error={Boolean(errors.statusId && touched.statusId)}
                />
              )}
              onChange={(e, value) => setFieldValue('statusId', value?.id || '')}
              fullWidth
              clearIcon={null}
            />
            {errors.statusId && touched.statusId && (
              <FormHelperText error>{errors.statusId}</FormHelperText>
            )}
          </Grid>
        </Grid>
        <Grid
          item
          container
          alignItems="center"
          justifyContent="flex-end"
          spacing={2}
          sx={{ mt: 1 }}
        >
          <Grid item>
            <Button
              variant="outlined"
              size="large"
              color="primary"
              onClick={() => history.goBack()}
            >
              Regresar
            </Button>
          </Grid>
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              disabled={isUpdating}
              endIcon={isUpdating && <CircularProgress size={20} />}
            >
              Actualizar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default EditLearningComponentForm;
