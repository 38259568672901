import { FC, useState } from 'react';

import { useDispatch } from 'react-redux';
import {
  Autocomplete,
  Box,
  Button,
  FormHelperText,
  Grid,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { IconPlus, IconFileImport } from '@tabler/icons';

import TextFieldFetching from '../../../../shared/components/TextFieldFetching';
import { AddNewSubjectHomologation } from '../../constants/validationSchemas';
import { MatterMesh } from '../../../meshs/interfaces/curriculum.interfaces';
import InputLabel from '../../../../shared/components/forms/InputLabel';
import { Institute } from '../../../institutes/interfaces/institutes.interfaces';
import { setInstituteId } from '../../slices/homologationsSlice';
import ConfirmSubjectHomologation from './ConfirmSubjectHomologation';
import { useTranslation } from 'react-i18next';

interface Props {
  /** Institute Subject Status */
  isFetchingSBA: boolean;
  /** Institute Subject Data */
  subjectsAvailables: MatterMesh[];

  /** Institute Subject Status */
  isFetchingISBA: boolean;
  /** Institute Subject Data */
  instituteSubjectsAvailables: Institute | undefined;

  /** Open side dialog new subject */
  openDialog: (mode: 'person' | 'institute' | 'subjectInstitute') => void;
}

const HomologationPerSubject: FC<Props> = (props) => {
  const { t } = useTranslation();

  const {
    isFetchingSBA,
    subjectsAvailables,
    isFetchingISBA,
    instituteSubjectsAvailables,

    openDialog,
  } = props;
  const theme = useTheme();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  const handleCloseDialog = () => {
    setOpen(false);
  };
  const handleOpenDialog = () => {
    setOpen(true);
  };

  const { handleSubmit, setFieldValue, values, touched, errors } = useFormik({
    initialValues: {
      instSubject: undefined as MatterMesh | undefined,
      subject: undefined as MatterMesh | undefined,
    },
    validationSchema: AddNewSubjectHomologation,
    onSubmit: async (values) => {
      handleOpenDialog();
    },
  });

  const handleAddSubject = () => {
    dispatch(setInstituteId(instituteSubjectsAvailables?.id));
    openDialog('subjectInstitute');
  };

  // useEffect(() => {
  //   if (isFetchingISBA) resetForm({ values: { ...values, instSubject: undefined } });
  // }, [resetForm, values, isFetchingISBA]);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Box border={`1px solid ${theme.palette.grey[300]}`} borderRadius="8px" p={2}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h4" component="span">
                {t('academic.homologation.external.per_subject.title')}
              </Typography>
            </Grid>

            <Grid container item xs={8} justifyContent="space-between" alignItems="center">
              <Grid item xs={5}>
                <InputLabel>
                  {t('academic.homologation.external.per_subject.current_subject')}
                </InputLabel>
              </Grid>
              <Grid item xs={5}>
                <InputLabel>
                  {t('academic.homologation.external.per_subject.destinate_subject')}
                </InputLabel>
              </Grid>

              {/* autocompletes */}
              <Grid item xs={5}>
                {isFetchingSBA && <TextFieldFetching />}
                {!isFetchingSBA && (
                  <Autocomplete
                    options={subjectsAvailables}
                    getOptionLabel={(option) => option.matter_rename}
                    noOptionsText={t('academic.homologation.external.per_subject.subject.no_data')}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={Boolean(errors.subject && touched.subject)}
                        placeholder={t(
                          'academic.homologation.external.per_subject.subject.placeholder'
                        )}
                      />
                    )}
                    value={subjectsAvailables.find((dt) => dt.id === values.subject?.id) || null}
                    onChange={(_, value) => {
                      setFieldValue('subject', value || null);
                    }}
                    fullWidth
                    clearIcon={null}
                  />
                )}
                {errors.subject && touched.subject && (
                  <FormHelperText error>{t(errors.subject)}</FormHelperText>
                )}
              </Grid>

              <Grid item>
                <IconFileImport color={theme.palette.primary.main} />
              </Grid>

              <Grid item xs={5}>
                {isFetchingISBA && <TextFieldFetching />}
                {!isFetchingISBA && (
                  <Autocomplete
                    options={
                      instituteSubjectsAvailables ? instituteSubjectsAvailables!.subjects : []
                    }
                    getOptionLabel={(option) => option.name}
                    noOptionsText={t(
                      'academic.homologation.external.per_subject.inst_subject.no_data'
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={Boolean(errors.instSubject && touched.instSubject)}
                        placeholder={t(
                          'academic.homologation.external.per_subject.inst_subject.placeholder'
                        )}
                      />
                    )}
                    value={
                      instituteSubjectsAvailables
                        ? instituteSubjectsAvailables!.subjects.find(
                            (dt) => dt.id === values.instSubject?.id
                          ) || null
                        : null
                    }
                    onChange={(_, value) => {
                      setFieldValue('instSubject', value || null);
                    }}
                    fullWidth
                    clearIcon={null}
                  />
                )}
                {errors.instSubject && touched.instSubject && (
                  <FormHelperText error>{t(errors.instSubject)}</FormHelperText>
                )}
              </Grid>

              {/* new subject  */}
              {instituteSubjectsAvailables && (
                <>
                  <Grid item xs={5}></Grid>
                  <Grid item xs={5} sx={{ textAlign: 'right' }}>
                    <Button
                      size="small"
                      startIcon={<IconPlus size={10} />}
                      disabled={isFetchingISBA}
                      onClick={handleAddSubject}
                    >
                      {t('academic.homologation.external.per_subject.add_subject')}
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>

            <Grid container item xs={4} justifyContent="center" alignItems="center">
              <Grid item xs={12}></Grid>
              <Grid item>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  startIcon={<IconPlus size={10} />}
                  disabled={isFetchingISBA || isFetchingSBA}
                >
                  {t('academic.homologation.external.per_subject.add_homologation')}
                </Button>
              </Grid>
              {instituteSubjectsAvailables && <Grid item xs={12}></Grid>}
            </Grid>
          </Grid>
        </Box>
      </form>

      {/* homologation subject modal */}
      {open && (
        <ConfirmSubjectHomologation
          onClose={handleCloseDialog}
          open={open}
          data={{ instSubject: values.instSubject!, subject: values.subject! }}
        />
      )}
    </>
  );
};

export default HomologationPerSubject;
