export const permissionKey = 'debt';

export interface SubPermissionKeys {
  read: string;
  create: string;
  edit: string;
  delete: string;
}

export const subPermissionKeys: SubPermissionKeys = {
  read: 'debts-listar-deudas',
  create: 'debts-crear-deuda',
  edit: 'debts-actualizar-deuda',
  delete: 'debts-eliminar-deuda',
};
