import {
    Autocomplete,
    Button,
    CircularProgress,
    FormHelperText,
    Grid,
    InputLabel,
    TextField,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Radio,
    RadioGroup,
    FormLabel,
    FormControl,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../../hooks/useRedux';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import { statusAvailables } from '../../../shared/constants/resourceStatus';
import { /* formuleTypes, frecuencyType, systemType */ } from '../constants/valuesAutocomplete';
import { useFormStyles } from '../constants/styles';
import { ProductsValidationSchema } from '../constants/validationSchemas';
import { useAddProductMutation, useGetUseTypesQuery } from '../slices/productApiSlice';
import { useGetAllCategoriesQuery } from '../../maintenance-category/slices/categoryApiSlice';
import SkeletonForm from '../components/skeletons/SkeletonForm';

const AddForm = () => {
    const { t } = useTranslation();
    
    const history = useHistory();
    const { currentProfile } = useAppSelector((state) => state.access);
    const classes = useFormStyles();
    const [enablePorcentaje, setEnablePorcentaje] = useState(false);

    const [intgrConti, setIntgrConti] = useState(false);
    const [impuesto, setImpuesto] = useState(false);

    const handleChangeIntCont = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIntgrConti(event.target.checked);
    };

    const handleChangeImpuesto = (event: React.ChangeEvent<HTMLInputElement>) => {
        setImpuesto(event.target.checked);
    };

    //categories
    const { data: categories, isLoading: loadingCA } = useGetAllCategoriesQuery({
        profileId: currentProfile?.profileId!,
    })

    //use types
    const { data: useTypes, isLoading: loadingUT } = useGetUseTypesQuery({
        profileId: currentProfile?.profileId!,
        acronym: 'TUSO',
    })

    //Filter Tipos de Uso
    /* const useTypesData = useTypes?.children.filter(
        (cat) => {
            let keyword: string = cat.cat_acronym || '';
            if (keyword.includes("TU")) {
                return cat;
            }
        }
    ) */
    const useTypesData = useTypes?.children.filter(dt => dt.cat_acronym.includes("TU"))

    const [addProduct, { isLoading, isError, error, isSuccess }] = useAddProductMutation();
    const [showAlerts, setShowAlerts] = useState(true);

    const [systemTypeId, setSystemTypeId] = useState('1');

    const handleChangeSystemType = (event: any) => {
        setSystemTypeId(event.target.value);
    };

    const checkPorcentaje = (value: number) => {
        const errors: any = {};
        if (value < 1 || value > 100) {
            errors.porcentaje = t('shared.validation.percentage_limit')
        }
        return errors;
    }

    const { getFieldProps, errors, touched, handleSubmit, setFieldValue } = useFormik({
        initialValues: {
            categoryId: undefined,
            name: '',
            valor_inicial: 0,
            dia_vencimiento: 1,
            statusId: 1,
            useTypeId: undefined,
            porcentaje: 0,
            systemTypeId: 1,
            formuleTypeId: null,
            frecuencyTypeId: null,
        },
        validationSchema: ProductsValidationSchema,
        validate: ({ porcentaje }) => {
            return checkPorcentaje(porcentaje)
        },
        onSubmit: async (values) => {


            //console.log("validado");
            await addProduct({
              productPayload: {
                category_id: values.categoryId,
                pr_description: values.name,
                pr_use: values.useTypeId,
                status_id: values.statusId,
                amount: Number(values.valor_inicial) || 0,
                pr_end_date: null,
                pr_start_date: new Date().toISOString().split('T')[0],
                pr_type_formula: values.formuleTypeId,
                pr_type_frequency: values.frecuencyTypeId,
                stock: 0,
                user_profile_id: currentProfile?.profileId!,
                contifico: intgrConti,
                ice: impuesto,
                iva: impuesto,
                pr_expiration_day: Number(values.dia_vencimiento)
              },
            });
            setShowAlerts(true);
        },
    });

    const handleChangeTypeUse = (val: any) => {
        if (val === 396) {
            setEnablePorcentaje(true);
        } else {
            setEnablePorcentaje(false);
            setFieldValue('porcentaje', 1);
        }
        setFieldValue('useTypeId', val || '')
    };

    console.log(useTypesData, useTypes, loadingUT, handleChangeTypeUse)
    console.log(new Date().toISOString().split('T')[0], errors);
    return (
        <>
            {loadingCA || loadingUT ? <SkeletonForm /> :
                <form onSubmit={handleSubmit}>
                    <Grid container direction="column" gap={2}>
                        <Grid item>
                            {isError && showAlerts && (
                                <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
                            )}

                            {isSuccess && showAlerts && (
                                <SuccessAlert
                                    message={t('financial.maintenance_prod.add.success_alert')}
                                    handleDismiss={() => setShowAlerts(false)}
                                />
                            )}
                        </Grid>

                        <Grid item container spacing={2} alignItems="center">
                            <Grid item xs={12} md={6}>
                                <InputLabel className={classes.inputLabel}>{t('financial.maintenance_prod.add.category_label')}</InputLabel>
                                <Autocomplete
                                    options={categories || []}
                                    getOptionLabel={(option) => option.cat_name}
                                    renderInput={(params) => (
                                        <TextField placeholder={t('financial.maintenance_prod.add.category_placeholder')} {...params} error={Boolean(errors.categoryId && touched.categoryId)} />
                                    )}
                                    /* defaultValue={categories?.data!.at(0)} */
                                    onChange={(e, value) => setFieldValue('categoryId', value?.id || null)}
                                    fullWidth
                                    clearIcon={null}
                                    loading={loadingCA}
                                />
                                {errors.categoryId && touched.categoryId && (
                                    <FormHelperText error>{t(errors.categoryId)}</FormHelperText>
                                )}
                            </Grid>
                        </Grid>

                        <Grid item container spacing={2} alignItems="center">
                            <Grid item xs={12} md={6}>
                                <InputLabel className={classes.inputLabel}>{t('financial.maintenance_prod.add.name_label')}</InputLabel>
                                <TextField
                                    fullWidth
                                    error={Boolean(errors.name && touched.name)}
                                    placeholder={t('financial.maintenance_prod.add.name_placeholder')}
                                    {...getFieldProps('name')}
                                />
                                {errors.name && touched.name && <FormHelperText error>{t(errors.name)}</FormHelperText>}
                            </Grid>


                            <Grid item xs={12} container spacing={2}>
                                <Grid item xs={6}>
                                    <InputLabel>{t('financial.maintenance_prod.add.initial_value_label')}</InputLabel>
                                    <TextField
                                        fullWidth
                                        // inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                        error={Boolean(errors.valor_inicial && touched.valor_inicial)}
                                        placeholder={t('financial.maintenance_prod.add.initial_value_placeholder')}
                                        {...getFieldProps('valor_inicial')}
                                    />
                                    {errors.valor_inicial && touched.valor_inicial && (
                                        <FormHelperText error>{t(errors.valor_inicial)}</FormHelperText>
                                    )}
                                </Grid>
                            </Grid>

                            <Grid item xs={12} container spacing={2}>
                                <Grid item xs={6}>
                                    <InputLabel>{t('financial.maintenance_prod.add.expiration_days_label')}</InputLabel>
                                    <TextField
                                        fullWidth
                                        // inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                        type='number'
                                        error={Boolean(errors.dia_vencimiento && touched.dia_vencimiento)}
                                        placeholder={t('financial.maintenance_prod.add.expiration_days_placeholder')}
                                        {...getFieldProps('dia_vencimiento')}
                                    />
                                    {errors.dia_vencimiento && touched.dia_vencimiento && (
                                        <FormHelperText error>{t(errors.dia_vencimiento)}</FormHelperText>
                                    )}
                                </Grid>
                            </Grid>

                            <Grid item xs={6}>
                                <InputLabel className={classes.inputLabel}>{t('financial.maintenance_prod.add.use_type_label')}</InputLabel>
                                <Autocomplete
                                    options={useTypesData!}
                                    getOptionLabel={(option) => option.cat_name}
                                    renderInput={(params) => (
                                        <TextField placeholder={t('financial.maintenance_prod.add.use_type_placeholder')} {...params} error={Boolean(errors.useTypeId && touched.useTypeId)} />
                                    )}
                                    /* defaultValue={useTypesData!.at(0)} */
                                    /* onChange={(e, value) => setFieldValue('useTypeId', value?.id || '')} */
                                    onChange={(e, value) => handleChangeTypeUse(value?.id)}
                                    fullWidth
                                    clearIcon={null}
                                />
                                {errors.useTypeId && touched.useTypeId && (
                                    <FormHelperText error>{t(errors.useTypeId)}</FormHelperText>
                                )}
                            </Grid>

                            {enablePorcentaje ?
                                <>
                                    <Grid  item xs={12} container spacing={2}>
                                    <Grid item xs={6}>
                                        <InputLabel>{t('financial.maintenance_prod.add.percentage_label')}</InputLabel>
                                        <TextField
                                            fullWidth
                                            // inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                            error={Boolean(errors.porcentaje && touched.porcentaje)}
                                            placeholder={t('financial.maintenance_prod.add.percentage_placeholder')}
                                            {...getFieldProps('porcentaje')}
                                        />
                                        {errors.porcentaje && touched.porcentaje && (
                                            <FormHelperText error>{t(errors.porcentaje)}</FormHelperText>
                                        )}
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} container spacing={2}>
                                    <Grid item xs={6}>
                                        <InputLabel>{t('financial.maintenance_prod.add.amount_label')}</InputLabel>
                                        <TextField
                                            fullWidth
                                            // inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                            error={Boolean(errors.valor_inicial && touched.valor_inicial)}
                                            placeholder={t('financial.maintenance_prod.add.amount_placeholder')}
                                            {...getFieldProps('valor_inicial')}
                                        />
                                        {errors.valor_inicial && touched.valor_inicial && (
                                            <FormHelperText error>{t(errors.valor_inicial)}</FormHelperText>
                                        )}
                                    </Grid>
                                </Grid>

                                    <Grid item xs={12} container spacing={2}>
                                        <Grid item xs={6}>
                                            <FormControl component="fieldset">
                                                <FormLabel component="legend">{t('financial.maintenance_prod.add.system_type_label')}</FormLabel>
                                                <RadioGroup
                                                    aria-label="gender"
                                                    name="controlled-radio-buttons-group"
                                                    value={systemTypeId}
                                                    onChange={handleChangeSystemType}
                                                >
                                                    <FormControlLabel value="1" control={<Radio />} label={t('financial.maintenance_prod.add.system_type.system')} />
                                                    <FormControlLabel value="2" control={<Radio />} label={t('financial.maintenance_prod.add.system_type.client')} />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                    </Grid>

                                    {/* <Grid item xs={12}>
                                <InputLabel className={classes.inputLabel}>Tipo de Sistema</InputLabel>
                                <Autocomplete
                                    options={systemType}
                                    getOptionLabel={(option) => option.label}
                                    renderInput={(params) => (
                                        <TextField {...params} error={Boolean(errors.systemTypeId && touched.systemTypeId)} />
                                    )}
                                    defaultValue={systemType[0]}
                                    onChange={(e, value) => setFieldValue('systemTypeId', value?.id || '')}
                                    fullWidth
                                    clearIcon={null}
                                />
                                {errors.systemTypeId && touched.systemTypeId && (
                                    <FormHelperText error>{errors.systemTypeId}</FormHelperText>
                                )}
                            </Grid> */}
                                </>
                                : ''}

                            {/* <Grid item xs={12}>
                        <InputLabel className={classes.inputLabel}>Tipo de Formula</InputLabel>
                        <Autocomplete
                            hidden={true}
                            options={formuleTypes}
                            getOptionLabel={(option) => option.label}
                            renderInput={(params) => (
                                <TextField {...params} error={Boolean(errors.formuleTypeId && touched.formuleTypeId)} />
                            )}
                            defaultValue={formuleTypes[0]}
                            onChange={(e, value) => setFieldValue('formuleTypeId', value?.id || '')}
                            fullWidth
                            clearIcon={null}
                        />
                        {errors.formuleTypeId && touched.formuleTypeId && (
                            <FormHelperText error>{errors.formuleTypeId}</FormHelperText>
                        )}
                    </Grid> */}

                            {/* <Grid item xs={12}>
                        <InputLabel className={classes.inputLabel}>Tipo de Frecuencia</InputLabel>
                        <Autocomplete
                            hidden={true}
                            options={frecuencyType}
                            getOptionLabel={(option) => option.label}
                            renderInput={(params) => (
                                <TextField {...params} error={Boolean(errors.frecuencyTypeId && touched.frecuencyTypeId)} />
                            )}
                            defaultValue={frecuencyType[0]}
                            onChange={(e, value) => setFieldValue('frecuencyTypeId', value?.id || '')}
                            fullWidth
                            clearIcon={null}
                        />
                        {errors.frecuencyTypeId && touched.frecuencyTypeId && (
                            <FormHelperText error>{errors.frecuencyTypeId}</FormHelperText>
                        )}
                    </Grid> */}
                            <Grid item xs={12} container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <InputLabel className={classes.inputLabel}>{t('shared.input.status.label')}</InputLabel>
                                    <Autocomplete
                                        options={statusAvailables}
                                        getOptionLabel={(option) => option.name}
                                        renderInput={(params) => (
                                            <TextField {...params} error={Boolean(errors.statusId && touched.statusId)} />
                                        )}
                                        defaultValue={statusAvailables[0]}
                                        onChange={(e, value) => setFieldValue('statusId', value?.id || '')}
                                        fullWidth
                                        clearIcon={null}
                                    />
                                    {errors.statusId && touched.statusId && (
                                        <FormHelperText error>{t(errors.statusId)}</FormHelperText>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid item container xs={12} spacing={2}>
                                <Grid item xs={4}>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={intgrConti} onChange={handleChangeIntCont} />} label={t('financial.maintenance_prod.add.contifico_integration_label')} />
                                        <FormControlLabel control={<Checkbox checked={impuesto} onChange={handleChangeImpuesto} />} label={t('financial.maintenance_prod.add.apply_taxe_label')} />
                                    </FormGroup>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid
                            item
                            container
                            alignItems="center"
                            justifyContent="flex-end"
                            spacing={2}
                            sx={{ mt: 1 }}
                        >
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    size="large"
                                    color="primary"
                                    onClick={() => history.goBack()}
                                >
                                    {t('shared.go_back_button.label')}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    size="large"
                                    color="primary"
                                    disabled={isLoading}
                                    endIcon={isLoading && <CircularProgress size={20} />}
                                >
                                    {t('shared.add_button.label')}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            }
        </>
    )
}

export default AddForm;