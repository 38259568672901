import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISearchTable } from '../../../shared/interfaces/paginatedTable.interface';
import { IBook } from '../../books/interfaces/books.interfaces';
import { LibraryDashboardState } from '../interfaces/dashboard.interfaces';

const initialState: LibraryDashboardState = {
  isLoadingBooksSearched: false,
  showBooksSearch: false,
  booksSearched: [],

  booksFilters: {
    search: '',
    perPage: 10,
    page: 1,
  },

  bookIdToReserve: 0,
  reserveBookModalOpen: false,

  faqsExpandedId: null,
};

const libraryDashboardSlice = createSlice({
  initialState,
  name: 'libraryDashboard',
  reducers: {
    setIsLoadingBooksSearched: (state, action: PayloadAction<boolean>) => {
      state.isLoadingBooksSearched = action.payload;
    },
    setShowBooksSearch: (state, action: PayloadAction<boolean>) => {
      state.showBooksSearch = action.payload;
    },
    setBooksSearched: (state, action: PayloadAction<IBook[]>) => {
      state.booksSearched = action.payload;
    },
    updateBooksFilters: (state, action: PayloadAction<ISearchTable>) => {
      state.booksFilters = { ...state.booksFilters, ...action.payload };
    },
    setBookIdToReserve: (state, action: PayloadAction<number | null>) => {
      state.bookIdToReserve = action.payload;
    },
    setReserveBookModalOpen: (state, action: PayloadAction<boolean>) => {
      state.reserveBookModalOpen = action.payload;
    },
    setFaqsExpandedId: (state, action: PayloadAction<number | null>) => {
      state.faqsExpandedId = action.payload;
    },
  },
});

export const {
  setIsLoadingBooksSearched,
  setShowBooksSearch,
  setBooksSearched,
  updateBooksFilters,
  setBookIdToReserve,
  setReserveBookModalOpen,
  setFaqsExpandedId,
} = libraryDashboardSlice.actions;

export default libraryDashboardSlice.reducer;
