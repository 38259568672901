import { useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import { useAppSelector } from '../../../../hooks/useRedux';
import ConfirmAlert from '../../../shared/components/dialogs/ConfirmAlert';
import { useControlModalStates } from '../../../shared/hooks/useControlModalStates';

const ApprovedNivelationInfo = () => {
  const history = useHistory();

  const { currentRecordStudent } = useAppSelector((state) => state.access);

  const { open, handleCloseModal, handleOpenModalWith } = useControlModalStates();

  useEffect(() => {
    if (currentRecordStudent?.date_approval_nv && !currentRecordStudent.show_noti_nv)
      handleOpenModalWith();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRecordStudent?.date_approval_nv, currentRecordStudent?.show_noti_nv]);

  const handleRedirect = () => {
    history.push('/registro-asignaturas');
  };

  return (
    <ConfirmAlert
      open={open}
      isSuccess={false}
      isError={false}
      isLoading={false}
      onClose={handleCloseModal}
      handleDelete={handleRedirect}
      error={''}
      confirmBtnMessage="Regístrate aquí"
      title="Felicidades has aprobado tu curso de nivelación."
      subtitle="¿Estás listo para continuar?"
    />
  );
};

export default ApprovedNivelationInfo;
