//permiso pendiente de status para el filtro

export const subPermissionKeys = {
  groupKey: 'permisoPendiente',

  readSyllabus: 'syllabus-list-syllabus',

  readOffers: 'offers-obtener-oferta',
  readAllOffers: 'offers-listar-ofertas',
  readPeriodsByOffer: 'offers-listar-periodos-por-oferta',

  //permiso comentado desde el back con el mensaje:PERM-SEED-PermisionMarzoSeeder
  readMatterByEducationLevelId: 'permission:matters-get-by-education-level',

  readHistory: 'syllabus-get-history',

  changeStatusSyllabus: 'syllabus-change-status',

  updateSyllabus: 'syllabus-update',

  generatePdfSyllabus: 'permission:syllabus-generate-pdf',
}; // satisfies PermissionsKeys;
