import { Button, CircularProgress, Grid, Stack, TextField, Typography } from '@material-ui/core';

import { LocalizationProvider, DesktopDateTimePicker } from '@material-ui/lab';
import esLocale from 'date-fns/locale/es';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import { useState } from 'react';
import { useUpdateTaskDateMutation } from '../../../slices/virtualClassroomTeacherApiSlice';
import { makeStyles } from '@material-ui/styles';
import { format, parseISO } from 'date-fns';
import SuccessAlert from '../../../../../shared/components/alerts/SuccessAlert';
const colorazul = '#2570B5';

const Styles = makeStyles({
  calendar: {
    '&': {
      textAlign: 'center',
      margin: '5px ',
      color: colorazul,
      marginRight: '10px',
    },
    '& .MuiOutlinedInput-root': {
      borderColor: 'white',
      backgroundColor: 'transparent',
    },
    '& .MuiOutlinedInput-input': {
      color: 'black',
      backgroundColor: '#F2F3F4',
      borderRadius: '8px',
      fontWeight: 'bolder',

      textAlign: 'center',
    },
    '& .MuiOutlinedInput-root .css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent',
    },
    '& .MuiSvgIcon-root': {
      color: colorazul,
    },
    '& .MuiFormControl-root .MuiInputLabel-root': {
      fontSize: 0,
    },
    '& .css-1rhr49-MuiFormHelperText-root': {
      display: 'none',
    },
    '& .css-tptuxb-MuiOutlinedInput-notchedOutline': {
      border: 'none !important',
    },

    '& .MuiButtonBase-root': {
      backgroundColor: 'transparent',
      padding: '12px 0',
    },
    '& .css-nfrpf9-MuiInputBase-input-MuiOutlinedInput-input': {
      padding: '10px 0px',
    },
  },
  //    /* padding: 10px 0px
});

const EditDate: React.FC<{
  courseId: number;
  taskId: number;
  fechaInicio: Date;
  fechaFin: Date;
}> = ({ courseId, taskId, fechaInicio, fechaFin }) => {
  const [FechaInicio, setFechaInicio] = useState<Date>(parseISO(fechaInicio as unknown as string));
  const [FechaFin, setFechaFin] = useState<Date>(parseISO(fechaFin as unknown as string));

  const [UpdateDate, { data, isLoading, isSuccess }] = useUpdateTaskDateMutation();
  async function EditarDate() {
    await UpdateDate({
      courseId: courseId!,
      taskId: taskId!,
      DatePayload: {
        cact_end_date: format(FechaFin, 'yyyy-MM-dd HH:mm:ss')!,
        cact_start_date: format(FechaInicio, 'yyyy-MM-dd HH:mm:ss')!, //FechaInicio
      },
    });

    console.log(data, isLoading);
  }

  const styles = Styles();

  console.log(EditarDate, FechaInicio, FechaFin);

  return (
    <Grid container width={'100%'} justifyContent="center" alignItems="center" spacing={1}>
      {isSuccess && (
        <Grid item xs={12}>
          <SuccessAlert message="Fecha actualizada correctamente" />
        </Grid>
      )}
      <Grid item container alignItems="center" md={6} mb={2} gap={1}>
        <Grid item display={'flex'} alignItems="center">
          <Grid item md={5}>
            <Typography>Fecha Inicio</Typography>
          </Grid>
          <Grid
            item
            sx={{
              width: '230px',
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
              <Stack className={styles.calendar}>
                <DesktopDateTimePicker
                  renderInput={(props) => <TextField fullWidth {...props} helperText="" />}
                  onClose={EditarDate}
                  // label="Fecha de inicio"
                  value={FechaInicio}
                  onChange={(newValue) => {
                    setFechaInicio(newValue!);
                  }}
                />
              </Stack>
            </LocalizationProvider>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container alignItems="center" md={6} mb={2} gap={0.5}>
        <Grid item display={'flex'} alignItems="center">
          <Grid item md={4}>
            <Typography>Fecha Fin</Typography>
          </Grid>
          <Grid
            item
            sx={{
              width: '230px',
            }}
          >
            <Grid>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                <Stack className={styles.calendar}>
                  <DesktopDateTimePicker
                    renderInput={(props) => <TextField fullWidth {...props} helperText="" />}
                    // label="Fecha de inicio"
                    value={FechaFin}
                    onClose={EditarDate}
                    onChange={(newValue) => {
                      setFechaFin(newValue!);
                    }}
                  />
                </Stack>
              </LocalizationProvider>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container xs={12} justifyContent="flex-end">
        <Button
          onClick={EditarDate}
          variant="contained"
          disabled={isLoading}
          endIcon={isLoading ? <CircularProgress size={14} /> : null}
        >
          Guardar
        </Button>
      </Grid>
    </Grid>
  );
};

export default EditDate;
