import MainCard from '../../../shared/components/cards/MainCard';
import { Typography, Grid } from '@material-ui/core';
import SubjectEnables from '../components/Subject-Enables';
import { useTranslation } from 'react-i18next';

const SubjectsEnablesScreen = () => {
  const { t } = useTranslation();

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">{t('students.subjects.available.title')}</Typography>
        <Typography variant="body1">{t('students.subjects.available.description')}</Typography>

        <SubjectEnables />
      </Grid>
    </Grid>
  );
};

export default SubjectsEnablesScreen;
