import { Grid, Typography } from '@material-ui/core';
import { useAppSelector } from '../../../../hooks/useRedux';
import MainCard from '../../../shared/components/cards/MainCard';
import AcademicOfferingTable from '../components/AcademicOfferingTable';
import DeleteAcademicOfferingDialog from '../components/DeleteAcademicOfferingDialog';

const AcademicOfferingScreen = () => {
  const { academicOfferingIdToDelete } = useAppSelector((state) => state.academicOfferings);

  return (
    <div>
      <Grid component={MainCard} sx={{ padding: 2 }}>
        <Grid sx={{ mb: 3 }}>
          <Typography variant="h2">Niveles Educativos</Typography>
        </Grid>
        <AcademicOfferingTable />

        {academicOfferingIdToDelete && <DeleteAcademicOfferingDialog />}
      </Grid>
    </div>
  );
};

export default AcademicOfferingScreen;
