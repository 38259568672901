import { Box, IconButton, makeStyles, Typography, useTheme } from '@material-ui/core';
import { UploadFileOutlined, InsertDriveFileOutlined, CloseOutlined } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

const styles = makeStyles(() => ({
  fileUploadInput: {
    position: 'absolute',
    margin: 0,
    padding: 0,
    width: '100%',
    height: '100%',
    outline: 'none',
    opacity: 0,
    cursor: 'pointer',
  },
}));

interface Props {
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  description?: string;
  typesAccepted?: string;
  marginTop?: number;
  viewIconsDocuments?: boolean;
  filesView: {
    doc_name_file: string;
    [key: string]: any | null;
  }[];
  handleDeleteFile: (name: string) => void;
}

const DragAreaFileMultiple = ({
  handleChange,
  label,
  description,
  typesAccepted = '*',
  marginTop,
  viewIconsDocuments = true,
  filesView,
  handleDeleteFile,
}: Props) => {
  const classes = styles();
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        width: '100%',
        mt: marginTop || 5,
      }}
    >
      <Typography
        sx={{
          fontSize: '0.75rem',
          fontWeight: 'bold',
          textAlign: 'center',
          mb: 2,
        }}
      >
        {description || t('students.virtual_classroom.task.dragArea.description')}:
      </Typography>
      <Box
        sx={{
          position: 'relative',
          height: '120px',
          border: '4px dashed #d0d7de',
          ml: '10px',
          mr: '10px',
          borderRadius: '12px',
          mb: 1.5,
        }}
      >
        <input
          className={classes.fileUploadInput}
          type="file"
          multiple
          accept={typesAccepted}
          onChange={(event) => {
            handleChange(event);
          }}
        />
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <UploadFileOutlined />
          <Typography
            sx={{
              fontSize: '0.85rem',
              ml: '3px',
              fontWeight: 'bold',
            }}
          >
            {label || t('students.virtual_classroom.task.modal.title.file')}
          </Typography>
        </Box>
      </Box>
      {viewIconsDocuments && filesView && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            flexWrap: 'wrap',
            justifyContent: 'center',
            maxHeight: '150px',
            overflowY: 'auto',
          }}
        >
          {filesView.map((f) => (
            <Box
              sx={{
                mt: 3,
                mr: 1.5,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <IconButton
                sx={{
                  position: 'relative',
                  top: 0,
                  left: '40%',
                }}
                onClick={() => {
                  handleDeleteFile(f.doc_name_file);
                }}
              >
                <CloseOutlined
                  sx={{
                    color: theme.palette.error.main,
                    fontSize: '1.2rem',
                  }}
                />
              </IconButton>
              <InsertDriveFileOutlined
                sx={{
                  fontSize: '25px',
                  mb: 1,
                }}
              />

              <Typography
                sx={{
                  fontSize: '0.85rem',
                  ml: '3px',
                  fontWeight: 'bold',
                }}
              >
                {f.doc_name_file}
              </Typography>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default DragAreaFileMultiple;
