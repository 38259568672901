import DeleteAlert from '../../../shared/components/dialogs/DeleteAlert';
// import { useTranslation } from 'react-i18next';
import { useDeleteExternalLinkMutation } from '../slices/externalLinksApiSlice';

interface Props {
  open: boolean;
  externalId: number;
  handleCloseModal: () => void;
}

const DeleteExternalLinkDialog = ({ open, externalId, handleCloseModal }: Props) => {
  //   const { t } = useTranslation();
  const [deleteExternalLink, { isLoading, isSuccess, isError, error, reset }] =
    useDeleteExternalLinkMutation();

  const handleDelete = () => {
    deleteExternalLink({ externalId });
  };

  const handleClose = () => {
    handleCloseModal();

    setTimeout(() => {
      reset();
    }, 300);
  };

  return (
    <DeleteAlert
      open={open}
      onClose={handleClose}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      handleDelete={handleDelete}
      //   deleteTitle={t('¿Estás seguro de eliminar la ruta?')}
      error={error as string}
    />
  );
};

export default DeleteExternalLinkDialog;
