import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISearchTable } from '../../../shared/interfaces/paginatedTable.interface';

interface refundsState {
  deleteModalOpen: boolean;
  refundToDelete?: number;

  refundsTable: ISearchTable;
}

const initialState: refundsState = {
  deleteModalOpen: false,

  refundsTable: {
    page: 1,
    perPage: 5,
    search: '',
  },
};

const refundsSlice = createSlice({
  name: 'refunds',
  initialState,
  reducers: {
    setDeleteModalOpen: (state, action: PayloadAction<boolean>) => {
      state.deleteModalOpen = action.payload;
    },
    openDeleteModalWith: (state, action: PayloadAction<number>) => {
      state.refundToDelete = action.payload;
      state.deleteModalOpen = true;
    },
    resetRefundToDelete: (state) => {
      state.refundToDelete = undefined;
    },
    updateRefundsTable: (state, action: PayloadAction<ISearchTable>) => {
      state.refundsTable = action.payload;
    },
  },
});

export const { setDeleteModalOpen, openDeleteModalWith, resetRefundToDelete, updateRefundsTable } =
  refundsSlice.actions;

export default refundsSlice.reducer;
