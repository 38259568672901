import { Grid, Typography } from '@material-ui/core';
import { useAppSelector } from '../../../../hooks/useRedux';
import MainCard from '../../../shared/components/cards/MainCard';
import AcademicRequestsTable from '../components/AcademicRequestsTable';
import DeleteAcademicRequestDialog from '../components/DeleteAcademicRequestDialog';

import { useTranslation } from 'react-i18next';

const RequestsScreen = () => {
  const { t } = useTranslation();

  const { academicRequestIdToDelete } = useAppSelector((state) => state.academicRequests);
  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">{t('students.menu.applications.consult-requests')}</Typography>
      </Grid>
      <AcademicRequestsTable />
      {academicRequestIdToDelete && <DeleteAcademicRequestDialog />}
    </Grid>
  );
};

export default RequestsScreen;
