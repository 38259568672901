import { Button, Grid, TextField } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import InputLabel from '../../../shared/components/extended/Form/InputLabel';

const AddDocument = () => {
  const history = useHistory();

  return (
    <form>
      <Grid container direction="column" gap={2}>
        {/* <Grid item>
          {isError && showAlerts && (
            <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
          )}

          {isSuccess && showAlerts && (
            <SuccessAlert
              message="El modelo de calificación ha sido creado correctamente."
              handleDismiss={() => setShowAlerts(false)}
            />
          )}
        </Grid> */}

        <Grid item container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <InputLabel>Descripción</InputLabel>
            <TextField
              fullWidth
              multiline
              rows={2}
              //   error={Boolean(errors.name && touched.name)}
              placeholder="Ingrese la descripción del documento"
              //   {...getFieldProps('name')}
            />
            {/* {errors.name && touched.name && <FormHelperText error>{errors.name}</FormHelperText>} */}
          </Grid>

          <Grid item xs={12}>
            <InputLabel>Archivo</InputLabel>
            <TextField
              fullWidth
              type="file"
              //   error={Boolean(errors.acronym && touched.acronym)}
              //   placeholder="Ingrese el acrónimo del modelo de calificación"
              //   {...getFieldProps('acronym')}
            />
            {/* {errors.acronym && touched.acronym && (
              <FormHelperText error>{errors.acronym}</FormHelperText>
            )} */}
          </Grid>
        </Grid>

        <Grid
          item
          container
          alignItems="center"
          justifyContent="flex-end"
          spacing={2}
          sx={{ mt: 1 }}
        >
          <Grid item>
            <Button
              variant="outlined"
              size="large"
              color="primary"
              onClick={() => history.goBack()}
            >
              Regresar
            </Button>
          </Grid>
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              //   disabled={isLoading}
              //   endIcon={isLoading && <CircularProgress size={20} />}
            >
              Agregar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default AddDocument;
