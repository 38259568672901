import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISearchTable } from '../../../shared/interfaces/paginatedTable.interface';
import { Child } from '../../maintenance-product/interfaces/usetypes.interface';

interface PayChecksState {
  paymentStatus: Child[];
  changeStatusModalOpen: boolean;
  payCheckId?: number;
  payCheckTable: ISearchTable;
}
const initialState: PayChecksState = {
  paymentStatus: [],
  changeStatusModalOpen: false,
  payCheckTable: {
    page: 1,
    perPage: 5,
    search: '',
  },
};
const payChecksSlice = createSlice({
  name: 'payCheck',
  initialState,
  reducers: {
    resetDepositTransferId: (state) => {
      state.payCheckId = undefined;
    },
    setChangeStatusModalOpen: (state, action: PayloadAction<boolean>) => {
      state.changeStatusModalOpen = action.payload;
    },
    setPaymentStatus: (state, action: PayloadAction<Child[]>) => {
      state.paymentStatus = action.payload;
    },
    openChangeStatusModalWith: (state, action: PayloadAction<number>) => {
      state.payCheckId = action.payload;
      state.changeStatusModalOpen = true;
    },
    updatePayCheckTable: (state, action: PayloadAction<ISearchTable>) => {
      state.payCheckTable = action.payload;
    },
  },
});
export const {
  resetDepositTransferId,
  setChangeStatusModalOpen,
  setPaymentStatus,
  openChangeStatusModalWith,
  updatePayCheckTable,
} = payChecksSlice.actions;
export default payChecksSlice.reducer;
