import { useState, useEffect, useMemo } from 'react';

import {
  Autocomplete,
  TextField,
  Grid,
  Button,
  Stack,
  Typography,
  Menu,
  MenuItem,
  CircularProgress,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { useGetRoutesCampusQuery } from '../slices/busRouteApiSlice';
import ToggleButtonsBusRoute from './ToggleButtonsBusRoute';
import TextFieldFetching from '../../../shared/components/TextFieldFetching';
import { BusRoutesCampus } from '../interfaces/buses.interfaces';
import TimeLineRoute from './TimeLineRoute';
import { setBusRouteIdSelected, setBusRouteData, setBusCampusData } from '../slices/busRouteSlice';
import { useControlModalStates } from '../../../shared/hooks/useControlModalStates';
import BusScheduleDialog from './BusScheduleDialog';
import { drawerWidth } from '../../../shared/constants/uiConstants';
import { splitBusTours } from '../helpers';
import NoData from '../../../shared/components/NoData';
import { isEmptyObject } from '../../../shared/helpers';
import useGetUrlDownload from '../../../../hooks/useGetUrlDownload';
import LoadingChildren from '../../../shared/components/LoadingChildren';
import BusNewRouteDialog from './BusNewRouteDialog';
import useSharedGuard from '../../../../hooks/useSharedGuard';
import { subPermissionKeys } from '../constants/permissions';

const BusRoute = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const matchPath = useRouteMatch();

  const { urlDownload } = useGetUrlDownload();

  const { hasAccess, hasAtLeastOne } = useSharedGuard(subPermissionKeys);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openBtnMenu = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { busRouteIdSelected, busRouteData } = useAppSelector((state) => state.busRoute);
  const { opened } = useAppSelector((state) => state.customization);
  const { currentProfile } = useAppSelector((state) => state.access);

  const { open, handleCloseModal, handleOpenModalWith } = useControlModalStates();
  const {
    open: openCreateRoute,
    handleCloseModal: handleCloseCreateRoute,
    handleOpenModalWith: handleOpenCreateRoute,
  } = useControlModalStates();

  const [campus, setCampus] = useState<BusRoutesCampus | null>(null);

  const { data, isFetching } = useGetRoutesCampusQuery({});

  useEffect(() => {
    if (data && !campus) {
      setCampus(data[0]);
      dispatch(
        setBusRouteIdSelected(data?.[0]?.bus_route.length ? data?.[0].bus_route[0].id : null)
      );
      dispatch(setBusRouteData(data?.[0]?.bus_route?.length ? data[0].bus_route[0] : null));
    }
  }, [campus, data, dispatch]);

  const busCampus = useMemo(
    () => data?.find((busRoute) => busRoute.id === campus?.id),
    [campus?.id, data]
  );

  // let obj = input.reduce((res, curr) => {
  //   if (res[curr.index]) res[curr.index].push(curr);
  //   else Object.assign(res, { [curr.index]: [curr] });

  //   return res;
  // }, {});

  const handleSelectCampus = (value: BusRoutesCampus | null) => {
    setCampus(value || null);
    dispatch(setBusCampusData(value || null));
  };

  const goToEditRoutes = () => {
    history.push(`${matchPath.path}/editar-rutas`);
  };
  const goToEditSchedules = () => {
    history.push(`${matchPath.path}/horarios`);
  };
  const goToToursWithRouteId = () => {
    history.push(`${matchPath.path}/ruta/${busRouteIdSelected}/paradas`);
  };
  const goToCreateRoute = () => {
    handleOpenCreateRoute();
  };

  const busTours = useMemo(
    () => splitBusTours(busCampus?.bus_route?.find((item) => item.id === busRouteIdSelected)),

    [busCampus?.bus_route, busRouteIdSelected]
  );

  const clickDocPdfHandler = () => {
    window.open(`${urlDownload}${busRouteData?.bus_document}`, '_blank');
  };

  return (
    <Stack spacing={2}>
      {/* <Grid container spacing={2} alignItems="flex-start"> */}
      <Grid xs={12}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={12} sm={6} md={4}>
            {isFetching ? (
              <TextFieldFetching />
            ) : (
              <Autocomplete
                options={data || []}
                getOptionLabel={(option) => option.cam_name}
                renderInput={(params) => (
                  <TextField label={t('academic.buses.campus')} {...params} />
                )}
                value={campus}
                onChange={(_, value) => handleSelectCampus(value)}
                fullWidth
                clearIcon={null}
              />
            )}
          </Grid>

          {(currentProfile?.keyword === 'administrativo' ||
            currentProfile?.keyword === 'docente') &&
            hasAtLeastOne(['getScheduleList', 'getRoutes']) && (
              <>
                <Grid item xs>
                  <Grid container justifyContent="flex-end" spacing={1}>
                    <Button
                      id="descargar-excel-button"
                      aria-controls={openBtnMenu ? 'descargar-excel-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={openBtnMenu ? 'true' : undefined}
                      onClick={handleClick}
                      variant="contained"
                      endIcon={<ExpandMoreRoundedIcon />}
                    >
                      {t('academic.buses.manage.btn')}
                    </Button>
                    <Menu
                      id="descargar-excel-menu"
                      anchorEl={anchorEl}
                      open={openBtnMenu}
                      onClose={handleClose}
                      MenuListProps={{
                        'aria-labelledby': 'descargar-excel-button',
                      }}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                      // transformOrigin={{
                      //   vertical: 'top',
                      //   horizontal: 'left',
                      // }}
                    >
                      {hasAccess('getRoutes') && (
                        <MenuItem onClick={goToEditRoutes}>
                          {t('academic.buses.manage_routes')}
                        </MenuItem>
                      )}

                      {hasAccess('getScheduleList') && (
                        <MenuItem onClick={goToEditSchedules}>
                          {t('academic.buses.manage_schedules')}
                        </MenuItem>
                      )}
                    </Menu>
                  </Grid>
                </Grid>
              </>
            )}
        </Grid>
      </Grid>

      <LoadingChildren
        isLoading={isFetching}
        emptyFallback={
          <NoData
            title={t('academic.buses.no_routes.title')}
            subtitle={
              (
                <Stack justifyContent="center" spacing={1}>
                  <Typography variant="body1" align="center">
                    {t('academic.buses.no_routes_availables.subtitle')}
                  </Typography>
                  {(currentProfile?.keyword === 'administrativo' ||
                    currentProfile?.keyword === 'docente') &&
                    hasAccess('getRoutes') && (
                      <Button onClick={goToCreateRoute}>
                        {t('academic.buses.add_routes.btn')}
                      </Button>
                    )}
                </Stack>
              ) as unknown as string
            }
          />
        }
        isEmpty={!busCampus?.bus_route?.length}
        loadingSkeleton={<CircularProgress />}
      >
        <Grid item xs={'auto'}>
          <Grid container justifyContent="center" alignItems="center" mb={3}>
            <Grid item xs={12}>
              <ToggleButtonsBusRoute busRoutes={busCampus?.bus_route || []} />
            </Grid>
          </Grid>
        </Grid>

        <Stack
          direction="row"
          spacing={2}
          sx={{
            position: 'fixed',
            left: !opened ? '40px' : `calc(${drawerWidth}px + 20px)`,
            bottom: '20px',
            zIndex: 999,
          }}
          width={!opened ? `calc(100% - 60px)` : `calc(100% - (${drawerWidth}px + 30px))`}
          justifyContent="space-between"
        >
          <Stack direction="row" width="100%" justifyContent="center" spacing={2}>
            {busTours?.ida || busTours?.retorno ? (
              <>
                <Button variant="contained" size="large" onClick={() => clickDocPdfHandler()}>
                  {t('academic.buses.shared.view_doc_pdf.btn')}
                </Button>

                <Button variant="contained" size="large" onClick={() => handleOpenModalWith(0)}>
                  {t('academic.buses.view_schedules.btn')}
                </Button>
              </>
            ) : (
              <></>
            )}
          </Stack>
        </Stack>

        {!isEmptyObject(busTours) && busTours ? (
          <>
            <Stack direction="row">
              {busTours.ida && (
                <Grid container direction="row" justifyContent="flex-end" item xs={6} mb={3}>
                  <TimeLineRoute listLineRoute={busTours.ida as any} position={'left'} />
                </Grid>
              )}
              {busTours.retorno && (
                <Grid container direction="row" justifyContent="flex-end" item xs={6} mb={3}>
                  <TimeLineRoute listLineRoute={busTours.retorno as any} position={'right'} />
                </Grid>
              )}
            </Stack>
          </>
        ) : (
          <NoData
            title={t('academic.buses.no_tours_availables.title')}
            subtitle={
              (
                <Stack justifyContent="center" spacing={1}>
                  <Typography variant="body1" align="center">
                    {t('academic.buses.no_tours_availables.subtitle')}
                  </Typography>
                  {(currentProfile?.keyword === 'administrativo' ||
                    currentProfile?.keyword === 'docente') &&
                    hasAccess('getScheduleList') && (
                      <Button onClick={goToToursWithRouteId}>
                        {t('academic.buses.add_tours.btn')}
                      </Button>
                    )}
                </Stack>
              ) as unknown as string
            }
          />
        )}
      </LoadingChildren>

      {/* </Grid> */}

      <BusScheduleDialog open={open} busTours={busTours} handleCloseDialog={handleCloseModal} />

      <BusNewRouteDialog open={openCreateRoute} handleCloseDialog={handleCloseCreateRoute} />
    </Stack>
  );
};

export default BusRoute;
