import { Box, CircularProgress, Grid, Typography, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';
import { enUS } from 'date-fns/locale';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../../../hooks/useRedux';
import { getSubjectStatusColor } from '../../academic-portfolio/helpers/colors';
import useGoToVirtualClassroom from '../../academic-portfolio/hooks/useGoToVirtualClassroom';
import { Schedule } from '../interfaces/academic-hourhand-interfaces';

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    height: 105,
  },
  boxCard: {
    padding: '20px',
  },
  boxContainer: {
    width: '100%',
    height: 105,
    borderRadius: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0px 0px 12px 10px rgba(235,232,235,0.5)',
    '&:hover': {
      cursor: 'pointer',
      background: '#e2e2e2',
    },
  },
  text: {
    fontWeight: 600,
    color: theme.palette.primary.main,
    marginLeft: 3,
    fontSize: '0.8rem',
  },
  textBlack: {
    fontWeight: 600,
    color: theme.palette.dark.main,
    marginLeft: 3,
    fontSize: '0.8rem',
  },
  textGrey: {
    fontWeight: 500,
    color: theme.palette.grey[500],
    fontSize: '0.8rem',
  },
}));

export interface HourhandCardValues {
  day: string;
  start_hour: string;
  end_hour?: string;
}

const HourPlaceholder = ({ day, start_hour, end_hour }: HourhandCardValues) => {
  const theme = useTheme();

  const thisDay = format(new Date(), 'EEEE', { locale: enUS }).toLowerCase().slice(0, 2);
  const thisDayColor = `${theme.palette.primary.main}15`;

  const { courses } = useAppSelector((state) => state.academicHourhandStudents);
  const classes = useStyles();

  const [valueCourseId, setValueCourseId] = useState<number>(0);
  const [subjectRegister, setSubjectRegister] = useState<Schedule[] | null>(null);

  useEffect(() => {
    if (courses) {
      const hourhand = courses.map((cs) => cs);
      const sliceHourhand = hourhand?.slice(0, hourhand.length);
      const keysHourhand = Object.keys(sliceHourhand?.[0] || {});
      const keyDay = keysHourhand.find((kh) => kh.includes(`hour_${day}`));
      const keyHour = keysHourhand.find((kh) => kh.includes(`hour_start_time_${day}`));
      const filterSubject = courses?.filter((cs) => {
        const startHour = start_hour.split(':')[0];
        const auxStart = cs?.[keyHour! as unknown as keyof Schedule];
        const start = auxStart ? auxStart.toString().split(':')[0] : undefined;
        return (
          cs?.[keyDay! as unknown as keyof Schedule] === '1' && Number(start) === Number(startHour)
        );
      });
      if (filterSubject && filterSubject.length > 0) {
        setSubjectRegister(filterSubject);
      }
    }
  }, [courses, day, start_hour]);

  const { isFetching } = useGoToVirtualClassroom(valueCourseId, 'inicio');

  return (
    <Grid item xs={2} className={classes.gridContainer}>
      <Box
        className={classes.boxContainer}
        sx={{
          backgroundColor: thisDay === day ? `${thisDayColor} !important` : 'white',
        }}
        onClick={() => {
          if (subjectRegister) {
            setValueCourseId(Number(subjectRegister?.[0]?.course_id));
          }
        }}
      >
        {subjectRegister && !isFetching && (
          <Grid item container justifyContent="center">
            <Typography className={classes.text} textAlign="center">
              {subjectRegister?.[0]?.approval_status?.st_name && (
                <span
                  style={{
                    backgroundColor: getSubjectStatusColor(
                      subjectRegister?.[0]?.approval_status?.st_name || ''
                    ),
                    display: 'inline-flex',
                    width: '16px',
                    height: '16px',
                    borderRadius: '50%',
                    marginRight: '5px',
                  }}
                />
              )}{' '}
              {subjectRegister?.[0]?.mat_name?.split('/')?.[0]}
            </Typography>
            <Grid item container justifyContent="center">
              <Typography className={classes.textGrey}>Aula</Typography>
              <Typography className={classes.textBlack}>
                {subjectRegister?.[0]?.classroom}
              </Typography>
            </Grid>
            <Grid item container justifyContent="center">
              <Typography className={classes.textGrey}>Paralelo</Typography>
              <Typography className={classes.textBlack}>
                {subjectRegister?.[0]?.parallel}
              </Typography>
            </Grid>
          </Grid>
        )}
        {subjectRegister && isFetching && (
          <Box
            sx={{
              height: '100%',
              width: '100%',
            }}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress size={20} />
          </Box>
        )}
      </Box>
    </Grid>
  );
};

export default HourPlaceholder;
