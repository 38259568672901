import { Grid, Typography } from '@material-ui/core';
import { useAppSelector } from '../../../../hooks/useRedux';
import MainCard from '../../../shared/components/cards/MainCard';
import DeletePensumDialog from '../components/DeletePensumDialog';
import PensumTable from '../components/PensumTable';

const PensumsScreen = () => {
  const { pensumIdToDelete } = useAppSelector((state) => state.pensums);
  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">Pensums</Typography>
      </Grid>

      <PensumTable />

      {pensumIdToDelete && <DeletePensumDialog />}
    </Grid>
  );
};

export default PensumsScreen;
