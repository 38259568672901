import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PeriodType } from '../interfaces/period-types.interfaces';

interface PeriodsState {
  periodTypesList: PeriodType[];
  deleteModalOpen: boolean;
  periodTypeIdToDelete?: number;
}

const initialState: PeriodsState = {
  periodTypesList: [],
  deleteModalOpen: false,
};

const periodTypesSlice = createSlice({
  name: 'periodTypes',
  initialState,
  reducers: {
    setPeriodTypesList: (state, action: PayloadAction<PeriodType[]>) => {
      state.periodTypesList = action.payload;
    },
    setDeleteModalOpen: (state, action: PayloadAction<boolean>) => {
      state.deleteModalOpen = action.payload;
    },
    openDeleteModalWith: (state, action: PayloadAction<number>) => {
      state.periodTypeIdToDelete = action.payload;
      state.deleteModalOpen = true;
    },
    resetPeriodTypeToDelete: (state) => {
      state.periodTypeIdToDelete = undefined;
    },
  },
});

export const {
  setDeleteModalOpen,
  openDeleteModalWith,
  resetPeriodTypeToDelete,
  setPeriodTypesList,
} = periodTypesSlice.actions;

export default periodTypesSlice.reducer;
