import { useTranslation } from 'react-i18next';
import Chip from '../../../../../shared/components/extended/Chip';
import { statusAvailablesByDate } from '../contants/index';

interface Props {
  label?: string;
  statusId: number;
}

const StatusChipLaunching = ({ label, statusId }: Props) => {
  const { t } = useTranslation();

  const status = statusAvailablesByDate.find((status) => status.id === statusId);

  return (
    <Chip
      label={status?.label || t(`utils.status.${status?.id}`)}
      size="small"
      chipcolor={status?.color}
    />
  );
};

export default StatusChipLaunching;
