import * as Yup from 'yup';

export const ELSSchema = Yup.object().shape({
  groupNivelationId: Yup.number().required('El grupo de área es requerido.'),
  subjects: Yup.array().required('La materia es requerida.'),
  subjectHours: Yup.number().required('La hora es requerida.'),
  statusId: Yup.number().required('El estado es requerido'),
});

export const ELSSchemaEdit = Yup.object().shape({
  groupNivelationId: Yup.number().required('El grupo de área es requerido.'),
  subjectHours: Yup.number().required('La hora es requerida.'),
  statusId: Yup.number().required('El estado es requerido'),
});

//groupSubject
export const ELSGroupSchema = Yup.object().shape({
  subjects: Yup.array().required('La materia es requerida.'),
  subjectHours: Yup.number().required('La hora es requerida.'),
  statusId: Yup.number().required('El estado es requerido'),
});

export const ELSSGroupchemaEdit = Yup.object().shape({
  groupNivelationId: Yup.number().required('El grupo de área es requerido.'),
  statusId: Yup.number().required('El estado es requerido'),
});
