import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import useTheme from '@material-ui/core/styles/useTheme';

import { useLogoutMutation } from '../../../../auth/slices/authApiSlice';

const LogoutButton = () => {
  const theme = useTheme();
  const [logout] = useLogoutMutation();

  const logoutHandler = async () => {
    await logout({});
  };

  return (
    <Box sx={{ position: 'fixed', top: 15, right: 15 }}>
      <Button
        variant="contained"
        size="large"
        sx={{
          backgroundColor: theme.palette.orange.dark,
          '&:hover': { backgroundColor: theme.palette.orange.main },
        }}
        onClick={logoutHandler}
      >
        Salir
      </Button>
    </Box>
  );
};

export default LogoutButton;
