import { Switch, Redirect } from 'react-router-dom';

import { useAppSelector } from '../../../../hooks/useRedux';

//import { permissionKey, subPermissionKeys } from '../constants/permissions';
import GuardRoute from '../../../../router/guards/GuardRoute';

import BooksScreen from '../screens/BooksScreen';
import AddBookScreen from '../screens/AddBookScreen';
import EditBookScreen from '../screens/EditBookScreen';

const BooksRouter = () => {
  const { currentModule } = useAppSelector((state) => state.access);

  const hasPermission = 'borrows' in currentModule?.permissions!;

  if (!hasPermission) return <Redirect to="/dashboard" />;

  return (
    <Switch>
      <GuardRoute
        exact
        path="/administrar-libros"
        component={BooksScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.create}
      />
      <GuardRoute
        exact
        path="/administrar-libros/agregar"
        component={AddBookScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.create}
      />
      <GuardRoute
        exact
        path="/administrar-libros/editar"
        component={EditBookScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.create}
      />
    </Switch>
  );
};

export default BooksRouter;
