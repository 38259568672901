import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
  makeStyles,
} from '@material-ui/core';
import { ChatBubbleOutline, VisibilityOutlined } from '@material-ui/icons';
import { IconBulb } from '@tabler/icons';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { obtainDomainStorageImage } from '../../../../shared/helpers';
import { Forum } from '../interfaces/forums.interfaces';

interface Props {
  forum: Forum;
  goToForumView?: () => void;
  viewOptions?: boolean;
}

const useStyles = makeStyles((theme) => ({
  container: {
    border: '1px solid red',
  },
  card: {
    border: `2px solid ${theme.palette.grey[50]}`,
    minWidth: 200,
    textAlign: 'left',
    cursor: 'pointer',
    marginTop: 10,
    boxShadow: '1px 1px 5px rgba(0, 0, 0, 0.1)',
  },
  cardContent: {
    paddingTop: '0 !important',
  },
  cardHeader: {
    paddingBottom: '2 !important',
  },
}));
const useStylesContent = makeStyles((theme) => ({
  container: {
    border: '1px solid red',
  },
  card: {
    minWidth: 200,
    textAlign: 'left',
    marginTop: 10,
  },
  cardContent: {
    paddingTop: '0 !important',
    paddingBottom: '0 !important',
  },
  cardHeader: {
    paddingBottom: '2 !important',
  },
}));

const CardForum = ({ forum, goToForumView, viewOptions = true }: Props) => {
  const {
    id,
    act_description,
    course_activity_one: { cact_start_date },
    answers_activity_count,
    act_is_score,
    visible_note,
    detail_forum: { dta_question },
    author: {
      person: { pers_firstname, pers_first_lastname },
    },
  } = forum;
  const classes = useStyles();
  const classesContent = useStylesContent();
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Card
      key={id}
      className={viewOptions ? classes.card : classesContent.card}
      onClick={
        goToForumView
          ? () => {
              goToForumView();
            }
          : undefined
      }
    >
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Avatar
            alt="User 1"
            src={obtainDomainStorageImage(pers_firstname! + ' ' + pers_first_lastname!)}
          ></Avatar>
        }
        title={
          <Grid>
            <Grid>
              <Typography variant="subtitle1">
                {pers_firstname! + ' ' + pers_first_lastname!}
              </Typography>
            </Grid>
            <Grid>
              <Typography variant="subtitle2">
                {moment(cact_start_date.toLocaleString())
                  .format('DD MMM - hh:mm')
                  .replace('.', '') || '00:00'}
              </Typography>
            </Grid>
          </Grid>
        }
      />
      <CardContent className={classes.cardContent}>
        <Grid>
          <Grid mb={2}>
            <Typography variant="h4">{act_description}</Typography>
          </Grid>
          <Grid>
            <Typography variant="h5">{dta_question}</Typography>
          </Grid>
          {viewOptions && (
            <Grid textAlign={'right'}>
              <>
                <Tooltip title={t('studentVirtualClassroom.forum.principalComments')}>
                  <IconButton onClick={() => console.log('')}>
                    <VisibilityOutlined
                      sx={{
                        color: theme.palette.grey[700],
                        fontSize: '18px',
                      }}
                    />
                    <Typography
                      variant="subtitle1"
                      sx={{
                        ml: 0.5,
                      }}
                    >
                      {visible_note + ' '}
                    </Typography>
                  </IconButton>
                </Tooltip>
                <Tooltip title={t('studentVirtualClassroom.forum.principalComments')}>
                  <IconButton onClick={() => console.log('')}>
                    <ChatBubbleOutline
                      sx={{
                        color: theme.palette.grey[700],
                        fontSize: '18px',
                      }}
                    />
                    <Typography
                      variant="subtitle1"
                      sx={{
                        ml: 0.5,
                      }}
                    >
                      {answers_activity_count + ' '}
                    </Typography>
                  </IconButton>
                </Tooltip>
                <Tooltip title={t('studentVirtualClassroom.forum.isQualified')}>
                  <IconButton onClick={() => console.log('')}>
                    <IconBulb size={20} color={theme.palette.grey[700]} />
                    <Typography
                      variant="subtitle1"
                      sx={{
                        ml: 0.5,
                      }}
                    >
                      {act_is_score === '1' ? 'Si ' : 'No '}
                    </Typography>
                  </IconButton>
                </Tooltip>
              </>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CardForum;
