import ErrorAlert from '../../../../shared/components/alerts/ErrorAlert';

interface Props {
  message: string;
  handleDismiss: () => void;
  maxAttemptsCallback: () => void;
}

const ErrorAlertSmsHOC = ({ message, handleDismiss, maxAttemptsCallback }: Props) => {
  const splitMsg = Array.isArray(message) ? message[0].split('**') : '';

  if (splitMsg.length > 1) {
    return (
      <ErrorAlert
        message={splitMsg[0] as string}
        handleDismiss={handleDismiss}
        timeoutCallback={{ timer: 1000, callbackFn: maxAttemptsCallback }}
      />
    );
  }

  return <ErrorAlert message={message as string} handleDismiss={handleDismiss} />;
};

export default ErrorAlertSmsHOC;
