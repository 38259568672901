import { Switch } from 'react-router-dom';
import GuardRoute from '../../../../router/guards/GuardRoute';
import AddAgreementScreen from '../screens/AddAgreementScreen';
import AgreementsScreen from '../screens/AgreementsScreen';
import EditAgreementScreen from '../screens/EditAgreementScreen';
import { subPermissionKeys } from '../constants/permissions';

const AgreementsRouter = () => {
  return (
    <Switch>
      <GuardRoute
        exact
        path="/administrar-convenios"
        component={AgreementsScreen}
        permissionKey={subPermissionKeys.groupKey}
        subPermissionKey={subPermissionKeys.read}
      />

      <GuardRoute
        exact
        path="/administrar-convenios/agregar"
        component={AddAgreementScreen}
        permissionKey={subPermissionKeys.groupKey}
        subPermissionKey={subPermissionKeys.create}
      />

      <GuardRoute
        exact
        path="/administrar-convenios/:agreementId/editar"
        component={EditAgreementScreen}
        permissionKey={subPermissionKeys.groupKey}
        subPermissionKey={subPermissionKeys.edit}
      />
    </Switch>
  );
};

export default AgreementsRouter;
