/* eslint-disable react/no-unstable-nested-components */
import { useEffect, useMemo } from 'react';

import { Grid, IconButton, Tooltip, Divider } from '@material-ui/core';
import { IconReport } from '@tabler/icons';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useGetIntegralsReportsQuery } from '../slices/integralReportsApiSlice';
import {
  setIntegralsReportsList,
  setIsEditFilters,
  updateIntegralsReportsTable,
} from '../slices/integralReportsSlice';
import IntegralFilters from './IntegralFilters';
import SearchFilters from './SearchFilters';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/useRedux';
import { useTableSearch } from '../../../../../../hooks/useTableSearch';
import SearchPaginatedTable from '../../../../../shared/components/tables/SearchPaginatedTable';

const IntegralTable = () => {
  const navigate = useHistory();
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const {
    stageIdSelected,
    isEditFilters,
    integralsReportsTable: { page, perPage, search, filterArrow },
  } = useAppSelector((state) => state.integralReports);

  const { setPage, setPerPage, setSearch, setFilterArrow } = useTableSearch({
    page,
    perPage,
    search,
    filterArrow,
    updateFunction: updateIntegralsReportsTable,
  });

  const { isLoading, isFetching, error, data, refetch } = useGetIntegralsReportsQuery(
    {
      page,
      perPage,
      search,
      ...(filterArrow && { sort: filterArrow.name, typeSort: filterArrow.direction }),
    },
    { skip: !stageIdSelected }
  );

  useEffect(() => {
    if (data) dispatch(setIntegralsReportsList(data.data));
  }, [data, dispatch]);

  const datafetch = useMemo(
    () =>
      data?.data.map((dt, idx) => ({
        id: idx + 1,
        name: dt.person_name,
        eva: dt.integral_evaluation_name,
        options: { userId: dt.user_id, evaId: dt.integral_evaluation_id },
      })),
    [data?.data]
  );

  const handleSubmit = () => {
    if (stageIdSelected) refetch();

    dispatch(setIsEditFilters(false));
  };

  const handleViewWeighting = (evaId: string, userId: string) => {
    navigate.push(`${evaId}/ponderacion/${userId}`);
  };

  return (
    <>
      <Grid item xs={12} mt={2}>
        <IntegralFilters onSubmit={handleSubmit} />
      </Grid>
      {stageIdSelected && !isEditFilters && false && (
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12}>
            <SearchFilters />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
      )}
      {stageIdSelected && !isEditFilters && (
        <Grid item xs={12}>
          <SearchPaginatedTable
            showFilter={false}
            data={datafetch || []}
            headers={{
              id: 'N°',
              name: t('reports.integrals.filters.evaluated'),
              eva: t('reports.integrals.integral_evaluation'),
              options: t('reports.integrals.options'),
            }}
            listFilterArrows={{
              name: 'person_name',
              eva: 'integral_evaluation_name',
            }}
            filterArrow={filterArrow}
            setFilterArrow={setFilterArrow}
            error={error as any}
            isFetching={isFetching}
            isLoading={isLoading}
            keyExtractor={(item) => item.id.toString()}
            setPage={setPage}
            setPerPage={setPerPage}
            numHeader={4}
            page={page}
            perPage={perPage}
            search={search}
            total={data?.total || 0}
            searchPlacehoder={t('reports.integrals.table.search')}
            setSearch={setSearch}
            customDataCellsProperties={{
              id: {
                align: 'center',
              },
              name: {
                align: 'center',
              },
              eva: {
                align: 'center',
              },
              options: {
                align: 'center',
              },
            }}
            customRenderers={{
              options: (item) => (
                <Grid container alignItems="center" justifyContent="center">
                  <Tooltip
                    title={t('reports.integrals.table.view_report')}
                    onClick={() => handleViewWeighting(item.options.evaId, item.options.userId)}
                  >
                    <IconButton>
                      <IconReport />
                    </IconButton>
                  </Tooltip>
                  {/* <Tooltip title={t('reports.integrals.table.download_all')}>
                    <IconButton color="primary">
                      <IconCloudDownload />
                    </IconButton>
                  </Tooltip> */}
                </Grid>
              ),
            }}
          />
        </Grid>
      )}
    </>
  );
};

export default IntegralTable;
