import { useState } from 'react';

/**
 * Enviar interfaz como type param en la declaracion del hook para que herede
 * el multipleIds el tipado
 * @returns
 */
export const useControlModalStates = <T extends {} = any>() => {
  const [open, setOpen] = useState(false);
  const [dataId, setDataId] = useState(0);

  const [multipleIds, setMultipleIds] = useState<T | null>(null);

  const handleOpenModalWith = (dataId?: number) => {
    setOpen(true);
    if (dataId) setDataId(dataId);
  };

  const handleOpenModalWithMultipleIds = (dataIds: T) => {
    setOpen(true);

    setMultipleIds(dataIds);
  };

  const handleCloseModal = () => {
    setOpen(false);
    setDataId(0);
    setMultipleIds(null);
  };

  return {
    open,
    dataId,
    multipleIds,
    handleOpenModalWith,
    handleOpenModalWithMultipleIds,
    handleCloseModal,
  };
};
