interface OptionsTypePayments {
  id: number;
  name: string;
  value: string;
}

export const typePaymentsAvailables: OptionsTypePayments[] = [
  {
    id: 1,
    name: 'Ninguno',
    value: 'Ninguno',
  },
  {
    id: 2,
    name: 'Créditos',
    value: 'creditos',
  },
  {
    id: 3,
    name: 'Horas',
    value: 'horas',
  },
];
