import { IconFileDownload } from '@tabler/icons';
import download from 'downloadjs';
import CustomIconButton, { Props } from './CustomIconButton';
import Button, { ButtonProps } from '@material-ui/core/Button';
import useGetUrlDownload from '../../../../../hooks/useGetUrlDownload';

type ButtonType = 'button' | 'icon-button';

interface PropsButton {
  buttonLabel?: string;
  nameFile?: string;
  url: string;
  urlBase?: boolean;
  iconButtonProps?: Props;
  buttonProps?: ButtonProps;
  typeButton: ButtonType;
  onlyView?: boolean;
}

const DownloadButton = ({
  buttonLabel,
  nameFile,
  url,
  iconButtonProps,
  typeButton,
  buttonProps,
  urlBase = true,
  onlyView,
}: PropsButton) => {
  const urlSplit = url.split('/');
  const nameDefault = urlSplit[urlSplit.length - 1].split('.')[0];
  const labelDefault = 'Descargar documento';
  const { urlDownload } = useGetUrlDownload();
  const urlBaseDownload = urlBase ? urlDownload + '/' : '';

  const handleDownloadPDF = () => {
    try {
      if (onlyView) {
        return window.open(`${urlBaseDownload + url}`, '_blank');
      }

      download(urlBaseDownload + url, `${nameFile || nameDefault}.pdf`, 'application/pdf');
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {typeButton === 'icon-button' && (
        <CustomIconButton
          title={buttonLabel || labelDefault}
          Icon={iconButtonProps?.Icon || IconFileDownload}
          onClick={handleDownloadPDF}
          {...iconButtonProps}
        />
      )}
      {typeButton === 'button' && (
        <Button startIcon={<IconFileDownload />} onClick={handleDownloadPDF} {...buttonProps}>
          {buttonLabel || labelDefault}
        </Button>
      )}
    </>
  );
};

export default DownloadButton;
