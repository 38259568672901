import { Box, Button, Grid, Stack, SvgIcon, Typography, useTheme } from '@material-ui/core';
import { ReactComponent as PDFIcon } from '../../../../../assets/images/illustrations/pdf.svg';
import useGetUrlDownload from '../../../../../hooks/useGetUrlDownload';
import { Guide } from '../../interfaces/dashboard.interfaces';
import downloadjs from 'downloadjs';

const exampleGuides: Partial<Guide>[] = [
  {
    title: 'Guía de uso de la biblioteca',
    book_url: null!,
  },
  {
    title: 'Reglamento de la biblioteca',
    book_url: null!,
  },
];

const Documents = ({ guides }: { guides: Guide[] }) => {
  const theme = useTheme();

  const { urlDownload } = useGetUrlDownload();

  const handleDownload = (documentUrl: string) => {
    const url = `${urlDownload}/${documentUrl}`;
    try {
      downloadjs(url);
    } catch (error) {
      console.log(Error);
    }
  };

  return (
    <Stack spacing={2} sx={{ height: '100%', alignItems: 'space-betwween' }}>
      {(guides?.length > 0 ? guides : exampleGuides)?.slice(0, 2).map(({ title, book_url }) => (
        <Grid
          container
          sx={{
            border: `2px solid ${theme.palette.secondary.main}`,
            borderRadius: '24px',
            p: 2,
            height: '50%',
            position: 'relative',
            overflow: 'hidden',
          }}
          alignItems="center"
          justifyContent="center"
        >
          <Grid item>
            <SvgIcon
              component={PDFIcon}
              color="primary"
              sx={{
                width: '60px',
                height: '60px',
                '& > path': {
                  transform: 'scale(0.045)',
                },
              }}
            />
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h4" fontWeight={600} color="primary">
              {title}
            </Typography>
          </Grid>
          <Box
            sx={{
              position: 'absolute',
              right: '-1px',
              top: '-1px',
            }}
          >
            <Button
              sx={{
                borderRadius: '0 !important',
                borderTopRightRadius: '8px !important',
              }}
              variant="contained"
              color="info"
              onClick={() => handleDownload(book_url as string)}
              disabled={!book_url}
            >
              Descargar
            </Button>
          </Box>
        </Grid>
      ))}
    </Stack>
  );
};

export default Documents;
