import { FC, useEffect, useMemo, useState } from 'react';

import {
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Checkbox,
  Typography,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { StudentRecordSubjects, SubjectCurriculum } from '../../interfaces/homologations.interface';
import { renderErrorOrEmptyRow, renderSkeletonRows } from '../../../../shared/helpers/render';
import { IconChevronRight, IconChevronsDown } from '@tabler/icons';
import { useTranslation } from 'react-i18next';

interface Props {
  approvedSubjects: StudentRecordSubjects[];
  isFetchingApprovedSubjects: boolean;
  errorApprovedSubjects: string;
  selectedApprovedSubjs: number[];
  homologationSubjects: SubjectCurriculum[] | never[];
  selectedHomologationSubjs: number[];
  isFetchingHomologationSubjs: boolean;
  errorHomologationSubjs: string;

  handleApprovedSubjects: (param: number[]) => void;
  handleHomologationSubjects: (param: number[]) => void;
}
const SubjectsList: FC<Props> = (props) => {
  const { t } = useTranslation();

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('md'));
  const {
    approvedSubjects,
    isFetchingApprovedSubjects,
    errorApprovedSubjects,
    selectedApprovedSubjs,
    homologationSubjects,
    selectedHomologationSubjs,
    isFetchingHomologationSubjs,
    errorHomologationSubjs,
    handleApprovedSubjects,
    handleHomologationSubjects,
  } = props;

  const [matchAvailables, setMatchAvailables] = useState(0);

  const subjectsApprovedMap = useMemo(() => {
    let match: { [key: string]: string } = {};
    approvedSubjects.forEach((el, i) => {
      if (!(el.matter_id in match)) {
        match[el.matter_id] = el.matter_id;
      }
    });
    return match;
  }, [approvedSubjects]);

  const subjectsHomolgationMap = useMemo(() => {
    let match: { [key: string]: string } = {};
    homologationSubjects.forEach((el, i) => {
      if (!(el.matter_id in match)) {
        match[el.matter_id] = el.matter_id;
      }
    });
    return match;
  }, [homologationSubjects]);

  const handleClick = (
    // event: React.MouseEvent<HTMLTableHeaderCellElement, MouseEvent>,
    event: React.ChangeEvent<HTMLInputElement>,
    subjectId: number
  ) => {
    if (!(subjectId in subjectsHomolgationMap)) {
      return;
    }

    const selectedIndex = selectedApprovedSubjs.indexOf(subjectId);
    let newSelected: number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedApprovedSubjs, subjectId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedApprovedSubjs.slice(1));
    } else if (selectedIndex === selectedApprovedSubjs.length - 1) {
      newSelected = newSelected.concat(selectedApprovedSubjs.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedApprovedSubjs.slice(0, selectedIndex),
        selectedApprovedSubjs.slice(selectedIndex + 1)
      );
    }

    handleApprovedSubjects(newSelected);
    handleHomologationSubjects(newSelected);
  };

  //si se desea hacer un contador de materias dispinibles - seleccionadas, restarle el array de las seleccionadas
  useEffect(() => {
    let cont = 0;
    for (const key in subjectsApprovedMap) {
      if (key in subjectsHomolgationMap) {
        cont++;
      }
    }
    setMatchAvailables(cont - selectedApprovedSubjs.length);
  }, [subjectsApprovedMap, subjectsHomolgationMap, selectedApprovedSubjs.length]);

  const verifyAvailableSubject = (subjectId: string) => {
    if (subjectId in subjectsHomolgationMap) {
      return true;
    }
    return false;
  };

  const renderRow = (approvedSubjects: StudentRecordSubjects) => {
    const available = verifyAvailableSubject(approvedSubjects.matter_id);
    return (
      <TableRow hover className="table-row" key={approvedSubjects.matter_id.toString()}>
        <TableCell width="10%">
          <Checkbox
            // disabled={!available || false}
            checked={selectedApprovedSubjs.some((r) => r === Number(approvedSubjects.matter_id))}
            onChange={(e) => handleClick(e, Number(approvedSubjects.matter_id))}
            {...(!available && { sx: { color: `${theme.palette.error.main}!important` } })}
          />
        </TableCell>
        <TableCell
          width="60%"
          {...(!available && { sx: { color: `${theme.palette.error.main}` } })}
        >
          {approvedSubjects.matter_rename}
        </TableCell>
        <TableCell
          width="30%"
          align="center"
          {...(!available && { sx: { color: `${theme.palette.error.main}` } })}
        >
          {approvedSubjects.status_matter}
        </TableCell>
      </TableRow>
    );
  };

  const renderRowHomologationsSubjects = (homologationSubjects: SubjectCurriculum) => (
    <TableRow hover className="table-row" key={homologationSubjects.matter_id.toString()}>
      <TableCell width="10%">
        <Checkbox
          disabled
          checked={selectedHomologationSubjs.some(
            (r) => r === Number(homologationSubjects.matter_id)
          )}
        />
      </TableCell>
      <TableCell
        width="60%"
        onClick={() => {
          //   handleSelectRole(rol.id);
        }}
      >
        {homologationSubjects.matter_rename}
      </TableCell>
      {/* <TableCell
        width="30%"
        align="center"
        // sx={{ borderLeft: 1, borderColor: 'grey.200', paddingX: '10px' }}
      >
        {homologationSubjects.matter_rename}
      </TableCell> */}
    </TableRow>
  );

  const renderRows = () => {
    return <>{approvedSubjects?.map(renderRow)}</>;
  };

  const renderRowsHomologationsSubjects = () => {
    return <>{homologationSubjects?.map(renderRowHomologationsSubjects)}</>;
  };

  return (
    <>
      <Grid container item xs={12} justifyContent="flex-end" mb={1}>
        <Grid item>
          <Typography variant="body2" component="p">
            {t('academic.homologation.internal.subjects_list.num_subjects')}{' '}
            <b>{matchAvailables}</b>
          </Typography>
        </Grid>
      </Grid>

      <Grid item xs={12} md={5} sx={{ maxHeight: 600, overflow: 'hidden' }}>
        <PerfectScrollbar component="div">
          <TableContainer>
            <Table sx={{ border: '1px solid #eee', borderRadius: '12px' }}>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>{t('academic.homologation.internal.subjects_list.subject')}</TableCell>
                  <TableCell>{t('academic.homologation.internal.subjects_list.status')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isFetchingApprovedSubjects ? renderSkeletonRows(5, 3) : renderRows()}

                {(errorApprovedSubjects || approvedSubjects?.length === 0) &&
                  !isFetchingApprovedSubjects &&
                  renderErrorOrEmptyRow(5, errorApprovedSubjects as string)}
              </TableBody>
            </Table>
          </TableContainer>
        </PerfectScrollbar>
      </Grid>

      <Grid container item xs={12} md={2} justifyContent={'center'} alignItems={'center'}>
        {!isSmall && (
          <Grid item>
            <IconChevronRight />
            <br />
            <IconChevronRight />
          </Grid>
        )}
        {isSmall && (
          <Grid item m={2}>
            <IconChevronsDown />
          </Grid>
        )}
      </Grid>

      <Grid item xs={12} md={5} sx={{ maxHeight: 600, overflow: 'hidden' }}>
        <PerfectScrollbar component="div">
          <TableContainer>
            <Table sx={{ border: '1px solid #eee', borderRadius: '12px' }}>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>{t('academic.homologation.internal.subjects_list.subject')}</TableCell>
                  {/* <TableCell>Estado</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {isFetchingHomologationSubjs || isFetchingApprovedSubjects
                  ? renderSkeletonRows(5, 2)
                  : renderRowsHomologationsSubjects()}

                {(errorHomologationSubjs || homologationSubjects?.length === 0) &&
                  !isFetchingHomologationSubjs &&
                  renderErrorOrEmptyRow(5, errorHomologationSubjs as string)}
              </TableBody>
            </Table>
          </TableContainer>
        </PerfectScrollbar>
      </Grid>
    </>
  );
};

export default SubjectsList;
