import { Switch } from 'react-router-dom';

import GuardRoute from '../../../../router/guards/GuardRoute';
import StudentReservedBooks from '../screens/StudentReservedBooks';

const ReservedBooksRouter = () => {
  return (
    <Switch>
      <GuardRoute exact path="/biblioteca-reservas" component={StudentReservedBooks} />
    </Switch>
  );
};

export default ReservedBooksRouter;
