import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CircularProgress from '@material-ui/core/CircularProgress';

import Typography from '@material-ui/core/Typography';
import { useDeleteDocumentProjectMutation } from '../../../slices/InvestigationLinesApiSlice';
import { obtainDomainStorageImage } from '../../../../../shared/helpers';

interface Props {
  name: string;
  id: string;
  url: string;
}

const DocumentsCard = ({ name, id, url }: Props) => {
  const [deleteDocument, { isLoading: isLoadingDocumentDelete }] =
    useDeleteDocumentProjectMutation();

  const handleDelete = (id: string) => {
    deleteDocument({ documentId: id });
  };

  return (
    <Grid item xs={12} mt={2}>
      <Paper elevation={2} sx={{ width: '100%', padding: '5px' }}>
        <Grid container justifyContent="space-between" alignItems="center" p={1} spacing={1}>
          <Grid item>
            <Typography variant="subtitle1" color="initial">
              <Link href={obtainDomainStorageImage(url)} target="_blank" rel="noopener noreferrer">
                {name}
              </Link>
            </Typography>
          </Grid>
          <Grid item>
            <Tooltip title={'Quitar persona'}>
              <IconButton aria-label="" onClick={() => handleDelete(id)}>
                {isLoadingDocumentDelete ? (
                  <CircularProgress size={20} />
                ) : (
                  <HighlightOffIcon color="primary" />
                )}
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default DocumentsCard;
