import { Tab, Tabs } from '@material-ui/core';
import { useState } from 'react';

import ExtendDialog from '../../../../ExtendDialog';
import AssignStudentsForm from './AssignStudentsForm';
import StudenstAssigns from './StudenstAssigns';

interface TaskActivitiesDialogProps {
  projectId: string;
  taskId: number;
  open: boolean;
  handleClose: () => void;
}

const TaskStudentDialog = ({ handleClose, projectId, taskId }: TaskActivitiesDialogProps) => {
  const [tabSelected, setTabSelected] = useState('list');

  const [editDisabled, setEditDisabled] = useState(true);

  const changeTab = (event: React.ChangeEvent<{}>, newValue: string) => {
    if (tabSelected === 'edit') {
      setEditDisabled(true);
    }
    setTabSelected(newValue);
  };

  return (
    <ExtendDialog open handleCloseDialog={handleClose} title="Asignar estudiantes a tarea">
      <>
        <Tabs value={tabSelected} onChange={changeTab}>
          <Tab value="list" label="Listado" />
          <Tab value="add" label="Agregar" />
          {!editDisabled && <Tab value="edit" label="Editar" disabled={editDisabled} />}
        </Tabs>

        {tabSelected === 'list' && <StudenstAssigns projectId={projectId} />}
        {tabSelected === 'add' && <AssignStudentsForm projectId={projectId} taskId={taskId} />}
        {tabSelected === 'edit' && <></>}
      </>
    </ExtendDialog>
  );
};

export default TaskStudentDialog;
