import { useEffect } from 'react';

import { useParams } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import MainCardForm from '../../../shared/components/cards/MainCardForm';
import { useAppSelector, useAppDispatch } from '../../../../hooks/useRedux';
import SimpleFormOneColumn from '../../../shared/components/forms/skeletons/SimpleFormOneColumn';
import ExternalLinksForm from '../components/ExternalLinksForm';
import { useLazyGetExternalLinkQuery } from '../slices/externalLinksApiSlice';
import { setExternalLinksList } from '../slices/externalLinksSlice';
import LoadingChildren from '../../../shared/components/LoadingChildren';
import GoBackBtnTitle from '../../../shared/components/buttons/GoBackBtnTitle';

const EditExternalLink = () => {
  const { t } = useTranslation();
  const { externalLinkId } = useParams<{ externalLinkId: string }>();

  const dispatch = useAppDispatch();

  const { externalLinksList } = useAppSelector((state) => state.externalLinks);

  const [getExternalLink, { isFetching }] = useLazyGetExternalLinkQuery();

  useEffect(() => {
    if (!externalLinksList.length && !isFetching) {
      getExternalLink({
        id: Number(externalLinkId),
      })
        .unwrap()
        .then((response) => {
          if (response) dispatch(setExternalLinksList([response!]));
        })
        .catch((error) => console.log(error));
    }
  }, [dispatch, externalLinksList.length, externalLinkId, getExternalLink, isFetching]);

  const externalLink = externalLinksList.find((item) => item.id === Number(externalLinkId));

  return (
    <MainCardForm>
      <Grid item xs={12}>
        <Grid item container alignItems="center" gap={1} sx={{ cursor: 'pointer' }}>
          <GoBackBtnTitle title={t('financial.emission_point.edit.title')} />
        </Grid>
      </Grid>
      <Grid item xs={12} md={8} lg={6} sx={{ mt: 2 }}>
        <LoadingChildren
          isLoading={isFetching}
          isEmpty={!Boolean(externalLinksList.length)}
          loadingSkeleton={<SimpleFormOneColumn />}
        >
          <ExternalLinksForm editData={externalLink} />
        </LoadingChildren>
      </Grid>
    </MainCardForm>
  );
};
export default EditExternalLink;
