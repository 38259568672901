import { Grid, LinearProgress, Typography, Button } from '@material-ui/core';
import { useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import { EvaluationQuestion } from '../../interfaces/evaluationQuestions.interface';
import {
  setEvaluationIncompleteQuestionsOpen,
  setEvaluationUploadDialogOpen,
} from '../../slices/userEvaluationsSlice';
import QuestionsGroup from './QuestionsGroup';
import OservationAnswer from './OservationAnswer';

const QuestionGroupContainer = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { infoEvaluationToDo, evaluationQuestionsAnswered } = useAppSelector(
    (state) => state.userEvaluations
  );

  const [progress, setProgress] = useState(0);

  const questionsReduced = useMemo(
    () =>
      infoEvaluationToDo?.eva_release?.eva_evaluation?.eva_evaluations_questions?.reduce<
        EvaluationQuestion[][]
      >((acc, curr) => {
        if (acc.length === 0) {
          acc.push([curr.evaluation_question]);
        } else {
          const index = acc.findIndex(
            (item) => item[0]?.ambitos.uuid === curr.evaluation_question?.ambitos.uuid
          );
          if (index !== -1) {
            acc[index].push(curr.evaluation_question);
          } else {
            acc.push([curr.evaluation_question]);
          }
        }

        return acc;
      }, []),
    [infoEvaluationToDo?.eva_release?.eva_evaluation?.eva_evaluations_questions]
  );

  const sendEvaluation = () => {
    if (
      evaluationQuestionsAnswered?.length !==
      infoEvaluationToDo?.eva_release?.eva_evaluation?.eva_evaluations_questions?.length
    ) {
      dispatch(setEvaluationIncompleteQuestionsOpen(true));
    } else {
      dispatch(setEvaluationUploadDialogOpen(true));
    }
  };

  useEffect(() => {
    const val =
      ((evaluationQuestionsAnswered?.length || 0) * 100) /
      (infoEvaluationToDo?.eva_release?.eva_evaluation?.eva_evaluations_questions?.length || 1);

    setProgress(val);
  }, [
    evaluationQuestionsAnswered?.length,
    infoEvaluationToDo?.eva_release?.eva_evaluation?.eva_evaluations_questions?.length,
  ]);

  return (
    <Grid container>
      <Grid item xs={12} mt={4}>
        <Typography variant="body1">{t('user_evaluations.evaluation.steps')}</Typography>
        <LinearProgress variant="determinate" value={progress} />
      </Grid>

      {questionsReduced?.map((item, idx) => (
        <QuestionsGroup
          title={`${idx + 1}. ${item?.[0]?.ambitos?.name}` || 'Sin titulo asignado'}
          questions={item}
          template={
            infoEvaluationToDo?.eva_release?.eva_evaluation?.eva_evaluations_template.options!
          }
          idx={idx + 1}
        />
      ))}

      <OservationAnswer />

      <Grid item container xs={12} mt={2} justifyContent="center">
        <Button
          variant="contained"
          color="primary"
          // disabled={evaluationQuestionToAnswer !== (questionsReduced?.length || 1) - 1}
          onClick={sendEvaluation}
        >
          {t('user_evaluations.evaluation.finish')}
        </Button>
      </Grid>
    </Grid>
  );
};

export default QuestionGroupContainer;
