import { Grid, IconButton, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core';
import { IconArrowNarrowLeft } from '@tabler/icons';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { stylesDialogMatterDetail } from '../constants/styles';
import { resetMatterIdDetail, setOpenModalMatterDetail } from '../slices/AcademicCounselingSlice';
import SemesterMatterDetail from './SemesterMatterDetail';
import AreaMatterDetail from './AreaMatterDetail';
import ExtendedSideDialog from '../../../shared/components/dialogs/ExtendedSideDialog';
import { useTranslation } from 'react-i18next';

const AcademicCareerDetail = function () {
  const { t } = useTranslation();

  const theme = useTheme();

  const styles = stylesDialogMatterDetail();

  const dispatch = useAppDispatch();

  const { matterIdDetail, matterIdNew, matterName } = useAppSelector(
    (state) => state.academicCounseling
  );

  const handleClose = () => {
    dispatch(resetMatterIdDetail());
    dispatch(setOpenModalMatterDetail(false));
  };

  return (
    <ExtendedSideDialog
      open={true}
      keepMounted
      onClose={() => handleClose()}
      className={styles.container}
      classes={{
        paper: styles.paper,
      }}
    >
      <Grid item xs={12}>
        <Grid item container alignItems="center" gap={1} sx={{ cursor: 'pointer' }}>
          <IconButton onClick={() => handleClose()}>
            <IconArrowNarrowLeft size={30} color={theme.palette.text.dark} />
          </IconButton>
          <Typography variant="h2">{t('students.academic_counseling.back')}</Typography>
        </Grid>
      </Grid>

      <Grid style={{ marginLeft: 40, marginTop: 40 }}>
        <Grid style={{ marginTop: 10 }}>
          <Typography>
            {matterIdNew
              ? t('students.academic_counseling.semester.detail')
              : t('students.academic_counseling.area.detail')}
            .
          </Typography>
        </Grid>
        <Typography variant="h2">
          {matterIdNew
            ? `${t('students.academic_counseling.semester')} ${matterIdDetail}`
            : `${t('students.academic_counseling.area')}: ${matterName}`}
        </Typography>
      </Grid>

      <Grid style={{ marginLeft: 40, marginTop: 30, marginRight: 40 }}>
        {matterIdNew ? <SemesterMatterDetail /> : <AreaMatterDetail />}
      </Grid>
    </ExtendedSideDialog>
  );
};

export default AcademicCareerDetail;
