import * as Yup from 'yup';

export const MatterInfoSchema = Yup.object().shape({
  name: Yup.string().trim().required('academic.subjects.add.name.required'),
  offerId: Yup.number(),
  offerDescription: Yup.string(),
  acronym: Yup.string()
    .trim()
    .min(2, 'academic.subjects.add.acronym.min')
    .max(6, 'a')
    .required('academic.subjects.add.acronym.required'),
  description: Yup.string().trim(),
  traslate: Yup.string().trim(),
  codMatterMigration: Yup.string()
    .trim()
    .length(6, 'academic.subjects.add.current_code.length')
    .required('academic.subjects.add.current_code.required'),
  typeMatter: Yup.number()
    .required('academic.subjects.add.subject_type.required')
    .min(1, 'academic.subjects.add.subject_type.required'),
  educationLevel: Yup.number()
    .required('academic.subjects.add.edu_level.required')
    .min(1, 'academic.subjects.add.edu_level.required'),
  // area: Yup.number().required('academic.subjects.add.area.required'),
  area: Yup.number(),
  statusId: Yup.number().required('academic.subjects.add.status.required'),
  typePayment: Yup.string()
    .required('academic.subjects.add.payment_type.required')
    .when('offerDescription', {
      is: (offerDescription: string) => offerDescription === 'oferta_postgrado',
      then: Yup.string().required('academic.subjects.add.payment_type.required.postgrade'),
    }),
  jointAcademicId: Yup.number()
    .nullable()
    .when('offerDescription', {
      is: (offerDescription: string) => offerDescription !== 'oferta_postgrado',
      then: Yup.number()
        .nullable()
        .required('academic.subjects.add.joint_academic.required')
        .min(1, 'academic.subjects.add.joint_academic.required'),
    }),

  // typeModalityId: Yup.mixed().when('offerDescription', {
  //   is: 'oferta_postgrado',
  //   then: Yup.number().required('El número del certificado de discapacidad es requerido.'),
  // }),
});
