import * as Yup from 'yup';

export const TypePeriodValidationSchema = Yup.object().shape({
  name: Yup.string().required('El nombre es requerido.'),
  tp_acronym: Yup.string()
    .required('El acrónimo es requerido.')
    .max(10, 'El acrónimo no puede superar los 10 caraceteres.'),
  description: Yup.string(),
  minMatter: Yup.number()
    .integer('Solo se permiten números enteros.')
    .required('La cantidad mínima de materias es requerida.')
    .min(1, 'La cantidad mínima de materias es 1.'),
  maxMatter: Yup.number()
    .integer('Solo se permiten números enteros.')
    .min(Yup.ref('minMatter'), 'Max. Materias debe ser mayor o igual al Min. Materias.'),
  numFees: Yup.number()
    .min(0, 'La cantidad de cuotas debe ser un valor mayor a cero.')
    .required('La cantidad de cuotas es requerida.'),
  isPayEnrrollement: Yup.boolean().required('Confirmar el pago de matrícula es requerido.'),
  isRegisterVinculation: Yup.boolean(),
  validationFeesPerEnrollement: Yup.number(),
  feesPerEnrollement: Yup.number()
    .min(0, 'Debes ser un valor mayor o igual a cero')
    .max(
      Yup.ref('validationFeesPerEnrollement'),
      'Debe ser un valor menor al máximo número de materias.'
    ),
  statusId: Yup.number().required('El estado del permiso es requerido.'),
});
