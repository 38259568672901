import { useEffect } from 'react';

import { IconButton, Tooltip } from '@material-ui/core';
import { IconEye } from '@tabler/icons';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { useTableSearch } from '../../../../hooks/useTableSearch';
import DialogIframe from '../../../shared/components/dialogs/DialogIframe';
import SearchPaginatedTable from '../../../shared/components/tables/SearchPaginatedTable';
import { useControlModalStates } from '../../../shared/hooks/useControlModalStates';
import { useGetReportListQuery } from '../slices/moduleReportsApiSlice';
import { setReportList, updateModuleReportsTable } from '../slices/moduleReportsSlice';
import useSharedGuard from '../../../../hooks/useSharedGuard';
import { subPermissionKeys } from '../constants/permissions';

const ReportsTable = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const { page, perPage, search, filterArrow } = useAppSelector(
    (state) => state.moduleReports.reportsTable
  );
  const { currentModule } = useAppSelector((state) => state.access);

  const { hasAccess } = useSharedGuard(subPermissionKeys);

  const { open, handleCloseModal } = useControlModalStates();

  const { setPage, setPerPage, setSearch, setFilterArrow } = useTableSearch({
    page,
    perPage,
    search,
    filterArrow,
    updateFunction: updateModuleReportsTable,
  });

  const { data, error, isLoading, isFetching } = useGetReportListQuery({
    page,
    search,
    size: perPage,
    mod_name: currentModule?.key || '',
    ...(filterArrow && { sort: filterArrow.name, typeSort: filterArrow.direction }),
  });

  useEffect(() => {
    if (data) dispatch(setReportList(data.data));
  }, [data, dispatch]);

  const datafetch =
    data?.data?.map((item, idx) => ({
      idx: page === 1 ? idx + 1 : (page - 1) * perPage + idx + 1,
      rep_title: item.rep_title,
      rep_description: item.rep_description,
      options: item,
    })) || [];

  const goToView = (id: number) => {
    history.push(`/reportes/${id}`);
  };

  return (
    <>
      <SearchPaginatedTable
        data={datafetch}
        isLoading={isLoading}
        headers={{
          idx: t('shared.table.index'),
          rep_title: t('academic.module-reports.table.title'),
          rep_description: t('academic.module-reports.table.description'),
          options: t('shared.search_paginated_table.table.options'),
        }}
        listFilterArrows={{
          rep_title: 'rep_title',
        }}
        filterArrow={filterArrow}
        setFilterArrow={setFilterArrow}
        isFetching={isFetching}
        error={error}
        keyExtractor={(item) => `module-reports-${item.idx}-${item.options.id.toString()}`}
        searchPlacehoder={t('academic.module-reports.table.search')}
        total={data?.total || 0}
        perPage={perPage}
        setPerPage={setPerPage}
        page={page}
        setPage={setPage}
        search={search}
        setSearch={setSearch}
        // ActionButtons={
        //   // <Button variant="contained" endIcon={<IconPlus />} onClick={goToAdd}>
        //   //   Agregar
        //   // </Button>
        // }
        customRenderers={{
          options: (row) => (
            <>
              {!!row.options.rep_url && hasAccess('show') && (
                <Tooltip title={t('shared.table.visualize')}>
                  <IconButton color="secondary" onClick={() => goToView(row.options.id)}>
                    <IconEye />
                  </IconButton>
                </Tooltip>
              )}
            </>
          ),
        }}
        customDataCellsProperties={{
          idx: { align: 'center' },
          // rep_title: { align: 'center' },
          options: { align: 'center' },
        }}
      />

      {/* <Button variant="contained" onClick={opentest}>
        Testg
      </Button> */}

      {/* solo si se desea mostrar en un modal (y se realizan los cambos necesarios para que funcione) */}
      {/* no usado de momento  */}
      <DialogIframe
        open={open}
        onClose={handleCloseModal}
        title={'pruebas'}
        id={'un-id'}
        isLoadingPDF={false}
        error={''}
        avaliableDownload={false}
      />
    </>
  );
};

export default ReportsTable;
