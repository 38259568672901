import { Switch } from 'react-router-dom';
import VCStudentDashboardScreen from '../dashboard/screens/DashboardScreen';
import EvaluationDetailScreen from '../evaluations/screens/EvaluationDetailScreen';
import EvaluationListScreen from '../evaluations/screens/EvaluationListScreen';
import ForumScreen from '../forum/screens/ForumScreen';
import VCStudentHeaderScreen from '../header/screens/VCStudentHeader';
import MaterialScreen from '../materials/screens/MaterialScreen';
import NoteScreen from '../notes/screens/NoteScreen';
import PartnerScreen from '../partners/screens/PartnerScreen';
import ScheduleScreen from '../schedule/screens/ScheduleScreen';
import ForumDetailScreen from '../forum/screens/ForumDetailScreen';
import TaskScreen from '../tasks/screens/TaskScreen';

import GuardRoute from '../../../../router/guards/GuardRoute';
import { useAppSelector } from '../../../../hooks/useRedux';
import MixedSkeletons from '../../../shared/components/forms/skeletons/MixedSkeletons';
import AttendanceScreen from '../attendance/screens/AttendanceScreen';
import AdvertisementsScreen from '../advertisements/screens/AdvertisementsScreen';
import EvaluationFinished from '../evaluations/screens/EvaluationFinished';
import RedirectTest from '../evaluations/components/finished/RedirectTest';
import MeetingsScreen from '../meetings/screens/MeetingsScreen';
import QualificationsScreen from '../notes/screens/QualificationsScreen';
import QualificationsAulaVirtual from '../notes/screens/QualificationsAulaVirtual';

const VirtualClassroomStudentRouter = () => {
  const {
    course: { courseId },
  } = useAppSelector((state) => state.virtualClassroomStudentShared);
  const { evaluationAnswersCompleted } = useAppSelector(
    (state) => state.virtualClassroomStudent.evaluation
  );

  return (
    <>
      <VCStudentHeaderScreen />
      {courseId && courseId > 0 ? (
        <Switch>
          <GuardRoute
            exact
            path="/aula-virtual-estudiante/inicio"
            component={VCStudentDashboardScreen}
          />
          <GuardRoute
            exact
            path="/aula-virtual-estudiante/inicio"
            component={VCStudentDashboardScreen}
          />
          <GuardRoute exact path="/aula-virtual-estudiante/test" component={EvaluationListScreen} />
          <GuardRoute
            exact
            path="/aula-virtual-estudiante/test/:evaluationId/realizar"
            component={EvaluationDetailScreen}
          />
          <GuardRoute
            exact
            path="/aula-virtual-estudiante/test/:evaluationId/finalizado"
            component={evaluationAnswersCompleted ? EvaluationFinished : RedirectTest}
          />
          <GuardRoute exact path="/aula-virtual-estudiante/tareas" component={TaskScreen} />
          <GuardRoute exact path="/aula-virtual-estudiante/companero" component={PartnerScreen} />
          <GuardRoute exact path="/aula-virtual-estudiante/foros" component={ForumScreen} />
          <GuardRoute
            exact
            path="/aula-virtual-estudiante/foros/:foroId/view"
            component={ForumDetailScreen}
          />
          <GuardRoute exact path="/aula-virtual-estudiante/notas" component={NoteScreen} />
          <GuardRoute
            exact
            path="/aula-virtual-estudiante/calificaciones"
            component={QualificationsScreen}
          />
          <GuardRoute
            exact
            path="/aula-virtual-estudiante/calificaciones-aula-virtual"
            component={QualificationsAulaVirtual}
          />
          <GuardRoute exact path="/aula-virtual-estudiante/materiales" component={MaterialScreen} />
          <GuardRoute
            exact
            path="/aula-virtual-estudiante/asistencia"
            component={AttendanceScreen}
          />
          <GuardRoute exact path="/aula-virtual-estudiante/tareas" component={TaskScreen} />
          <GuardRoute
            exact
            path="/aula-virtual-estudiante/anuncios"
            component={AdvertisementsScreen}
          />
          <GuardRoute exact path="/aula-virtual-estudiante/agenda" component={ScheduleScreen} />
          <GuardRoute exact path="/aula-virtual-estudiante/compañeros" component={PartnerScreen} />

          <GuardRoute
            exact
            path={`/aula-virtual-estudiante/reuniones`}
            component={MeetingsScreen}
          />
        </Switch>
      ) : (
        <MixedSkeletons />
      )}
    </>
  );
};

export default VirtualClassroomStudentRouter;
