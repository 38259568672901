import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import DeleteAlert from '../../../../shared/components/dialogs/DeleteAlert';
import { useDeleteConfigurationStudentMutation } from '../../slices/clientApiSlice';
import { resetConfigurationStudentIdToDelete, setDeleteModalOpen } from '../../slices/clientSlice';

const DeleteDiscountDialog = () => {
  const dispatch = useAppDispatch();

  const { configurationStudentIdToDelete, deleteModalOpen } = useAppSelector((state) => state.client);

  const [deleteConfiguration, { isLoading, isSuccess, isError, error }] = useDeleteConfigurationStudentMutation();

  const handleDelete = () => {
    deleteConfiguration({ configurationStudentId: configurationStudentIdToDelete! });
  };

  const handleClose = () => {
    dispatch(setDeleteModalOpen(false));
    dispatch(resetConfigurationStudentIdToDelete());
  };
  return (
    <DeleteAlert
      open={deleteModalOpen}
      onClose={handleClose}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      handleDelete={handleDelete}
      error={error as string}
    />
  );
};

export default DeleteDiscountDialog;
