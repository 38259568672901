import { JSXElementConstructor, memo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Redirect, Route, RouteProps, useRouteMatch } from 'react-router-dom';
import { useAppSelector } from '../../hooks/useRedux';
import ErrorFallbackComponentScreen from '../../modules/shared/components/errors-boundary/ErrorFallbackComponentScreen';

interface Props extends RouteProps {
  component: JSXElementConstructor<RouteProps>;
  permissionKey?: string;
  subPermissionKey?: string | string[];
  /** ```Default: true```  false if no need of error component fallback */
  needErrorComponent?: boolean;
}

const GuardRoute = ({
  component: Component,
  subPermissionKey,
  // solo se le pasa para optimizar la busqueda del subpermission (si no, buscara el subpermission en todos los permisos)
  permissionKey,
  needErrorComponent = true,
  ...rest
}: Props) => {
  const { path } = useRouteMatch();

  const { currentModule } = useAppSelector((state) => state.access);

  const hasPermission = () => {
    try {
      /**
       * Valida si el permiso existe en el grupo del permission key
       */
      if (permissionKey && subPermissionKey) {
        const subPermissionKeys = currentModule?.permissions![permissionKey].map((p) => p.key);

        if (Array.isArray(subPermissionKey)) {
          return subPermissionKey.every((item) => subPermissionKeys?.includes(item));
        }

        return Boolean(subPermissionKeys?.includes(subPermissionKey));
      }

      /**
       * Valida si el permiso existe en todos los permissons
       */
      if (subPermissionKey) {
        if (Array.isArray(subPermissionKey)) {
          return subPermissionKey.every((item) =>
            currentModule?.permissionsArr?.some((sp) => sp.key === item)
          );
        }

        return currentModule?.permissionsArr?.some((sp) => sp.key === subPermissionKey);
      }

      /**
       * Si no se le pasan permissions, se le da acceso
       */
      return true;
    } catch (error) {
      console.log('===========Sin permisos===========');
      console.log(error);
      return false;
    }
  };

  return (
    <Route
      {...rest}
      render={(props) =>
        hasPermission() || true ? (
          needErrorComponent ? (
            <ErrorBoundary FallbackComponent={ErrorFallbackComponentScreen}>
              <Component {...props} />
            </ErrorBoundary>
          ) : (
            <Component {...props} />
          )
        ) : (
          <Redirect to={`${path}`} />
        )
      }
    />
  );
};

export default memo(GuardRoute);
