import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { IEvaluation } from '../interfaces/evaluations.interface';

const EvaluationInfoBox = ({ evaluation }: { evaluation: IEvaluation }) => {
  const { t } = useTranslation();

  return (
    <Grid
      container
      sx={{
        border: '1px solid',
        borderColor: 'primary.main',
        borderRadius: '12px',
        padding: 2,
        gap: 3,
      }}
    >
      <Grid item>
        <Typography variant="body1">
          {t('maintenance.evaluations.evaluation_type')}:{' '}
          <strong>{evaluation.eva_type?.description}</strong>
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1">
          {t('maintenance.evaluations.description')}: <strong>{evaluation.description}</strong>
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1">
          {t('maintenance.evaluations.created_at')}:{' '}
          <strong>{evaluation.created_at?.split('T')![0]}</strong>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default EvaluationInfoBox;
