import { Grid, Typography } from '@material-ui/core';
import MainCard from '../../../shared/components/cards/MainCard';
import EnterLeaveList from '../components/EnterLeaveList';

const EnterLeaveScreen = () => {
  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid item sx={{ mb: 3 }} container justifyContent="space-between" alignItems="center">
        <Typography variant="h2">Marcaciones</Typography>
      </Grid>
      <Grid item container>
        <EnterLeaveList />
      </Grid>
    </Grid>
  );
};

export default EnterLeaveScreen;
