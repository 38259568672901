import { TableHead } from '@material-ui/core';
import { useEffect, useState } from 'react';
import {
  StyledTableCell,
  StyledTableRow,
} from '../../../../teachers/virtual-classroom/califications/components/CalificationstTable';
import { getColHeader } from '../../../../teachers/virtual-classroom/califications/helpers/dataGridHelper';
import { useTranslation } from 'react-i18next';
import {
  Distribution,
  DistributionCalification,
} from '../../../../teachers/virtual-classroom/interfaces/modelCalification.interfaces';
interface Props {
  distribution?: DistributionCalification[];
  childs?: Distribution[];
}

const HeaderTable = ({ distribution }: Props) => {
  const [viewRows, setViewRows] = useState(true);

  const { col } = getColHeader(distribution);

  const { t } = useTranslation();

  const distributionList = distribution?.map((d) => (
    <StyledTableCell
      key={d.id}
      align="center"
      colSpan={
        d.distributions && d.distributions?.length > 1
          ? d.distributions?.length + 1
          : d.distributions?.length
      }
      rowSpan={d.distributions ? 1 : 2}
    >
      {t(`${d.name}`)}
    </StyledTableCell>
  ));

  const subRows = col?.map((d, index) => (
    <StyledTableCell align="center" key={index.toString()}>
      {d}
    </StyledTableCell>
  ));

  useEffect(() => {
    if (distributionList && distributionList?.length - 1 === subRows.length) setViewRows(false);
  }, [distributionList, subRows]);

  return (
    <TableHead>
      <StyledTableRow>
        <StyledTableCell align="center" rowSpan={2}>
          {t('studentVirtualClassroom.qualification.surnamesAndNames')}
        </StyledTableCell>
        {distributionList}
      </StyledTableRow>
      {viewRows && <StyledTableRow>{subRows}</StyledTableRow>}
    </TableHead>
  );
};

export default HeaderTable;
