import { Redirect, Switch } from 'react-router-dom';
import { useAppSelector } from '../../../../../../hooks/useRedux';
import GuardRoute from '../../../../../../router/guards/GuardRoute';
// eslint-disable-next-line import/no-cycle

import AddScopeScreen from '../screen/AddScopeScreen';
import EditScopeScreen from '../screen/EditScopeScreen';
import ScopesScreen from '../screen/ScopesScreen';

export const PeriodsRoutes = () => {
  const { currentModule } = useAppSelector((state) => state.access);

  const hasPermission = 'evaluation' in currentModule?.permissions!;

  if (!hasPermission) return <Redirect to="/dashboard" />;

  return (
    <Switch>
      <GuardRoute
        exact
        path="/ambitos"
        component={ScopesScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.list}
      />
      <GuardRoute
        exact
        path="/ambitos/agregar"
        component={AddScopeScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.create}
      />
      <GuardRoute
        exact
        path="/ambitos/:id/editar"
        component={EditScopeScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.edit}
      />
    </Switch>
  );
};
