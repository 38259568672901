import { Redirect, Switch } from 'react-router-dom';
import { useAppSelector } from '../../../../../../hooks/useRedux';
import GuardRoute from '../../../../../../router/guards/GuardRoute';
// eslint-disable-next-line import/no-cycle
import AddEvaluationTypeScreen from '../screen/AddEvaluationTypeScreen';
import EditEvaluationTypeScreen from '../screen/EditEvaluationTypeScreen';
import EvaluationTypeScreen from '../screen/EvaluationTypeScreen';

export const EvaluationTypeRoutes = () => {
  const { currentModule } = useAppSelector((state) => state.access);

  const hasPermission = 'evaluation' in currentModule?.permissions!;

  if (!hasPermission) return <Redirect to="/dashboard" />;

  return (
    <Switch>
      <GuardRoute
        exact
        path="/tipos-de-evaluacion"
        component={EvaluationTypeScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.list}
      />
      <GuardRoute
        exact
        path="/tipos-de-evaluacion/agregar"
        component={AddEvaluationTypeScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.create}
      />
      <GuardRoute
        exact
        path="/tipos-de-evaluacion/:id/editar"
        component={EditEvaluationTypeScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.edit}
      />
    </Switch>
  );
};
