import { Box, Typography, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { BasicSelect } from '../../components/Select';
import { months } from '../constants';

interface Props {
  handleMonth: React.Dispatch<React.SetStateAction<string>>;
  valueState: string;
}

const FilterMonth = ({ valueState, handleMonth }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Typography
        sx={{
          fontWeight: 'bold',
          color: theme.palette.primary[600],
          mr: 4,
        }}
      >
        {t('studentVirtualClassroom.attendance.filter.text')}
      </Typography>
      <BasicSelect
        items={months}
        handleChangeTask={handleMonth}
        label={t('studentVirtualClassroom.attendance.filter.select.placeholder')}
        valueState={valueState}
      />
    </Box>
  );
};

export default FilterMonth;
