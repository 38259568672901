import axios from 'axios';
import { getProtocol } from '../helpers/urlHelpers';
import { TenantsResponse } from '../modules/shared/interfaces/tenants.interfaces';

const protocol = getProtocol();

const baseUrlOauth = `${protocol}://${localStorage.getItem('apiUrl')}`;

// Se creo este mainAPi porque el endpoint del Login OAuth no contiene la ruta /api/

/**
 * Instancia de Axios para la API principal
 * @return {AxiosInstance}
 */
const mainApiOauth = axios.create();

mainApiOauth.interceptors.request.use(
  async function (config) {
    const apiUrl = localStorage.getItem('apiUrl');
    const logoPath = localStorage.getItem('logoPath');

    if (!apiUrl || !logoPath) {
      const { data: dataUrl } = await axios.get<TenantsResponse>(
        `${protocol}://${
          process.env.REACT_APP_LANDLORD_API_URL
        }/api/tenants?search=${window.location.origin.replace(':3000', '')}`
      );
      localStorage.setItem('apiUrl', dataUrl.domain);
      localStorage.setItem('logoPath', dataUrl.logo_path);
      localStorage.setItem('banners', JSON.stringify(dataUrl.banners));
      localStorage.setItem('defaultConfiguration', JSON.stringify(dataUrl.default_configuration));
      localStorage.setItem('msjBusinessHours', dataUrl.msj_business_hours || '');
    }

    config.baseURL = `${protocol}://${localStorage.getItem('apiUrl')}`;

    const token = localStorage.getItem('x-token');

    config.headers = {
      ...config.headers,
      ...(token && { Authorization: `Bearer ${token}` }),
    };

    return config;
  },
  function (error: any) {
    return Promise.reject(error);
  }
);

mainApiOauth.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger

    return response;
  },
  function (error: any) {
    // console.log(error);
    let errorMessage = '';

    if (error.response) {
      if (Array.isArray(error.response.data.detail)) {
        errorMessage = error.response.data.detail.join(' ');
      }

      errorMessage = error.response.data.detail;
    } else {
      errorMessage = error.detail;
    }

    return Promise.reject(errorMessage);
  }
);

export { mainApiOauth, baseUrlOauth };
