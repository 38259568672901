import {
  Table,
  Button,
  // Divider,
  Grid,
  TableContainer,
  Typography,
  TableBody,
  TableRow,
  TableCell,
  useTheme,
  Skeleton,
  Tooltip,
} from '@material-ui/core';
import { IconEdit } from '@tabler/icons';
import { FC } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useAppSelector } from '../../../../hooks/useRedux';
import SubCard from '../../../shared/components/cards/SubCard';
import { useGetCatalogByAcronymQuery } from '../../../shared/slices/catalogApi';
import { useGetTypePaymentSriQuery } from '../../../students/payments-history/slices/paymentsApiSlice';
import { ambienteAvailables } from '../../departments/constants/ambienteAvailables';

interface Props {
  isLoading: boolean;
}

const FinanceCard: FC<Props> = (props) => {
  const { company } = useAppSelector((state) => state.companies);
  const { path } = useRouteMatch();
  const history = useHistory();
  const theme = useTheme();
  const { currentProfile } = useAppSelector((state) => state.access);
  const profileId = currentProfile!.profileId;

  const { isLoading } = props;

  const { data: typePayment = [] } = useGetTypePaymentSriQuery(
    {
      data: 'all',
    },
    { skip: !company.co_pago_sri }
  );
  const { data: currency = [] } = useGetCatalogByAcronymQuery(
    {
      profileId,
      acronym: 'mon',
    },
    { skip: !company.co_moneda_id }
  );

  return (
    <Grid
      item
      xs={12}
      sx={{
        width: '100%',
      }}
    >
      <SubCard
        content={false}
        title="Información Financiera"
        secondary={
          company.co_ruc && (
            <Tooltip title="Editar esta sección" placement="top-end">
              <Button onClick={() => history.push(`${path}/editar-financiero`)}>
                <IconEdit stroke={1.5} size="1.3rem" />
              </Button>
            </Tooltip>
          )
        }
      >
        {isLoading ? (
          <>
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
          </>
        ) : (
          <Grid container p={2}>
            {company.co_description ? (
              <Grid item xs={12}>
                <Typography sx={{ color: theme.palette.text.secondary }}>
                  {company.co_description}
                </Typography>
              </Grid>
            ) : null}

            {/* <Grid item xs={12}>
              <Typography variant="subtitle1">Detalle</Typography>
            </Grid>
            <Divider sx={{ pt: 1 }} /> */}
            <Grid item xs={12}>
              <TableContainer>
                <Table
                  sx={{
                    '& td': {
                      borderBottom: 'none',
                    },
                  }}
                  size="small"
                >
                  <TableBody>
                    {company.co_business_name ? (
                      <TableRow>
                        <TableCell variant="head">Razón Social</TableCell>
                        <TableCell>:</TableCell>
                        <TableCell>
                          <Typography sx={{ color: theme.palette.text.secondary }}>
                            {company.co_business_name}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ) : null}
                    {company.co_comercial_name ? (
                      <TableRow>
                        <TableCell variant="head">Nombre Comercial</TableCell>
                        <TableCell>:</TableCell>
                        <TableCell>
                          <Typography sx={{ color: theme.palette.text.secondary }}>
                            {company.co_comercial_name}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ) : null}
                    {company.co_agent_legal ? (
                      <TableRow>
                        <TableCell variant="head">Representante Legal</TableCell>
                        <TableCell>:</TableCell>
                        <TableCell>
                          <Typography sx={{ color: theme.palette.text.secondary }}>
                            {company.co_agent_legal}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ) : null}
                    {company.co_legal_identification ? (
                      <TableRow>
                        <TableCell variant="head">ID Representante Legal</TableCell>
                        <TableCell>:</TableCell>
                        <TableCell>
                          <Typography sx={{ color: theme.palette.text.secondary }}>
                            {company.co_legal_identification}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ) : null}
                    {company.co_person_type ? (
                      <TableRow>
                        <TableCell variant="head">Tipo de persona</TableCell>
                        <TableCell>:</TableCell>
                        <TableCell>
                          <Typography sx={{ color: theme.palette.text.secondary }}>
                            {company.co_person_type}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ) : null}
                    {company.co_direction ? (
                      <TableRow>
                        <TableCell variant="head">Dirección</TableCell>
                        <TableCell>:</TableCell>
                        <TableCell>
                          <Typography sx={{ color: theme.palette.text.secondary }}>
                            {company.co_direction}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ) : null}
                    {company.co_info_mail ? (
                      <TableRow>
                        <TableCell variant="head">Email Informativo</TableCell>
                        <TableCell>:</TableCell>
                        <TableCell>
                          <Typography sx={{ color: theme.palette.text.secondary }}>
                            {company.co_info_mail}
                          </Typography>{' '}
                        </TableCell>
                      </TableRow>
                    ) : null}
                    {company.co_phone ? (
                      <TableRow>
                        <TableCell variant="head">Teléfono</TableCell>
                        <TableCell>:</TableCell>
                        <TableCell>
                          <Typography sx={{ color: theme.palette.text.secondary }}>
                            {company.co_phone}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ) : null}
                    {company.co_ambiente ? (
                      <TableRow>
                        <TableCell variant="head">Ambiente</TableCell>
                        <TableCell>:</TableCell>
                        <TableCell>
                          <Typography sx={{ color: theme.palette.text.secondary }}>
                            {
                              ambienteAvailables.find((a) => Number(company.co_ambiente) === a.id)
                                ?.name
                            }
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ) : null}
                    {company.co_contribuyenteEspecial ? (
                      <TableRow>
                        <TableCell variant="head">Contribuyente especial</TableCell>
                        <TableCell>:</TableCell>
                        <TableCell>
                          <Typography sx={{ color: theme.palette.text.secondary }}>
                            {company.co_contribuyenteEspecial}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ) : null}
                    {company.co_regimenMicroempresa ? (
                      <TableRow>
                        <TableCell variant="head">Régimen de microempresa</TableCell>
                        <TableCell>:</TableCell>
                        <TableCell>
                          <Typography sx={{ color: theme.palette.text.secondary }}>
                            {company.co_regimenMicroempresa}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ) : null}
                    {company.co_pago_sri ? (
                      <TableRow>
                        <TableCell variant="head">Tipo de pago SRI</TableCell>
                        <TableCell>:</TableCell>
                        <TableCell>
                          <Typography sx={{ color: theme.palette.text.secondary }}>
                            {
                              typePayment.find((tp) => tp.id === Number(company.co_pago_sri))
                                ?.payment_sri
                            }
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ) : null}
                    {company.co_moneda_id ? (
                      <TableRow>
                        <TableCell variant="head">Moneda</TableCell>
                        <TableCell>:</TableCell>
                        <TableCell>
                          <Typography sx={{ color: theme.palette.text.secondary }}>
                            {currency.find((c) => c.id === Number(company.co_moneda_id))?.cat_name}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ) : null}
                    {company.co_obligadoContabilidad ? (
                      <TableRow>
                        <TableCell variant="head">Obligado a llevar contabilidad</TableCell>
                        <TableCell>:</TableCell>
                        <TableCell>
                          <Typography sx={{ color: theme.palette.text.secondary }}>
                            {!!!company.co_obligadoContabilidad ? 'Si' : 'No'}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ) : null}
                  </TableBody>
                  {company.co_phone ||
                  company.co_person_type ||
                  company.co_info_mail ||
                  company.co_direction ||
                  company.co_legal_identification ||
                  company.co_agent_legal ||
                  company.co_comercial_name ||
                  company.co_business_name ||
                  company.co_ambiente ||
                  company.co_regimenMicroempresa ||
                  company.co_contribuyenteEspecial ||
                  company.co_obligadoContabilidad ? null : (
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <p>No hay datos para mostrar</p>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        )}
      </SubCard>
    </Grid>
  );
};

export default FinanceCard;
