import {
  Button,
  TextField,
  Grid,
  Typography,
  FormHelperText,
  CircularProgress,
  InputLabel,
  Pagination,
  Stack,
  Tooltip,
} from '@material-ui/core';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/useRedux';
import {
  setBackStep,
  ResetTask,

  //setTaskChooseGroup,
  setTaskQuestions,
  setTaskTypeQuestion,
  unsetIndexQuestion,
} from '../../../slices/virtualClassroomTeacherSlice';
import { useFormik } from 'formik';
import { useState } from 'react';
import { IconCheckbox } from '@tabler/icons';
import { useCreateTaskTeacherMutation } from '../../../slices/virtualClassroomTeacherApiSlice';
import SubCard from '../../../../../shared/components/cards/SubCard';
import ErrorAlert from '../../../../../shared/components/alerts/ErrorAlert';
import moment from 'moment';
import { useGetCatalogByAcronymChildrenQuery } from '../../../../../shared/slices/catalogApi';
import { TaskQuestionAnswerData } from '../../constants/validationSchemas';
import SuccessAlert from '../../../../../shared/components/alerts/SuccessAlert';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

const TaskChooseQuestions = () => {
  const dispatch = useAppDispatch();

  const History = useHistory();

  const { t } = useTranslation();

  const [showAlert, setShowAlert] = useState<boolean>(false);

  const { userInfo } = useAppSelector((state) => state.auth);

  const [, setNumQuestions] = useState(0);
  const [Page, setPage] = useState(1);

  const [pointInvalid, setPointInvalid] = useState(false);

  const [optionNotSelected, setOptionNotSelected] = useState(false);

  const [typeQuestionNoSelected, setTypeQuestionNoSelected] = useState(false);

  const { currentProfile } = useAppSelector((state) => state.access);

  const {
    course: { courseId },
  } = useAppSelector((state) => state.virtualClassroomTeacherShared);

  // Obtener data almacenada.
  const { taskToCreate } = useAppSelector((state) => state.virtualClassroomTeacher);

  console.log(taskToCreate);

  /**
   * Manejo de la lista de opciones dinámicas.
   * La propiedad correct se puede utilizar más luego para poder seleccionar la opción correcta.
   */
  const [optionsList, setOptionsList] = useState([{ option: 'Escribe opción...', correct: false }]);

  const { values, getFieldProps, errors, touched, resetForm } = useFormik({
    initialValues: {
      question: '',
      point: 0.25,
    },
    validationSchema: TaskQuestionAnswerData,
    onSubmit: () => {
      handleSubmitForm();
    },
  });

  const [createTask, { isLoading, isError, error, isSuccess }] = useCreateTaskTeacherMutation();

  /**
   * Obtener tipos de respuesta.
   */
  const { data: dataTypeQuestion } = useGetCatalogByAcronymChildrenQuery({
    acronym: 'tipo-respuesta',
    profileId: currentProfile?.profileId!,
  });

  const handleSubmitForm = async () => {
    await createTask({
      userId: Number(userInfo?.id!),
      courseId: courseId,
      payload: {
        act_description: taskToCreate?.act_description!,
        supply_id: taskToCreate?.supply_id! === 0 ? null : taskToCreate?.supply_id!,
        act_is_score: taskToCreate?.act_is_score!,
        type_activity_id: taskToCreate?.type_activity_id!,
        act_score_activity: taskToCreate?.act_score_activity!,
        act_num_tries: taskToCreate?.act_num_tries!,
        calification_models_distributions_id:
          taskToCreate?.calification_models_distributions_id! === 0
            ? null
            : taskToCreate?.calification_models_distributions_id!,
        status_id: taskToCreate?.status_id!,
        category_activity_id: taskToCreate?.category_activity_id!,
        course_activity: {
          cact_start_date: moment(new Date(taskToCreate?.course_activity?.cact_start_date!)).format(
            'YYYY-MM-DD HH:mm:ss'
          ),
          cact_end_date: moment(new Date(taskToCreate?.course_activity?.cact_end_date!)).format(
            'YYYY-MM-DD HH:mm:ss'
          ),
          cact_is_extemporaneous: taskToCreate?.course_activity?.cact_is_extemporaneous!,
        },
        course_student: taskToCreate?.course_student?.map((e) => ({
          grupo: taskToCreate.category_activity_id === 2089 ? e.grupo + 1 : e.grupo,
          student_id: e.student_id,
        }))!,
        detail: taskToCreate?.detail!,
        materials: [],
        visible_note: taskToCreate?.visible_note,
        visible: '1',
      },
    })
      .then((data) => console.log(data))
      .catch((err) => console.log(err));

    setShowAlert(true);

    if (!isError) {
      resetForm();
      dispatch(ResetTask());
      return History.goBack();
    }
  };

  /**
   * Seleccionar estudiantes y agruparlos.
   * Desactivar las pantallas de completado de información
   * y selección de preguntas.
   */
  const handleChooseGroup = () => {
    // dispatch(setTaskChooseGroup(true));
    dispatch(setBackStep(1));
  };

  /*const handleSelectCatalog = (): number => {
    const optionsSelectedPrevious = taskToCreate?.typeQuestion!;
    return dataTypeQuestion?.find((e) => e.cat_name === optionsSelectedPrevious)?.id!;
  };*/

  /**
   * Suma de los puntos de la actividades de todas las preguntas almacenadas.
   */
  const sumPointsActivity = (): number => {
    return taskToCreate?.detail?.reduce((acc, e) => acc + e?.dta_score_evaluated!, 0)!;
  };

  const checkIfPointIsAvailable = (): boolean => {
    const point = taskToCreate?.act_is_score === 0 ? 0 : values?.point!;
    const totalPoint = sumPointsActivity() + point!;
    if (totalPoint > taskToCreate?.act_score_activity!) {
      setPointInvalid(true);
      return false;
    }
    return true;
  };

  const checkIfTypeQuestionIsSelected = (): boolean => {
    if (taskToCreate?.typeQuestion === '' || taskToCreate?.typeQuestion === undefined) {
      setTypeQuestionNoSelected(true);
      return false;
    }
    return true;
  };

  const checkIfAnswerSelected = (): boolean => {
    if (taskToCreate?.typeQuestion === 'commentary' || taskToCreate?.typeQuestion === 'Imagen')
      return true;

    const isSelected = optionsList.reduce<number>(
      (acc, ele) => (ele.correct === true ? acc + 1 : acc),
      0
    );
    if (isSelected === 0) {
      setOptionNotSelected(true);
      return false;
    }

    return true;
  };

  const checkTypeQuestionId = (): number => {
    const typeQuestion = taskToCreate?.typeQuestion!;
    if (typeQuestion === 'commentary') {
      return dataTypeQuestion?.find((e) => e.cat_name === 'Comentario')?.id!;
    } else if (typeQuestion === 'Imagen') {
      return dataTypeQuestion?.find((e) => e.cat_name === 'Imagen')?.id!;
    }
    return dataTypeQuestion?.find((e) => e.cat_name === 'Comentario')?.id!;
  };

  const handleSaveQuestion = () => {
    const typeAnswerId = checkTypeQuestionId();

    const someAnswerSelected = checkIfAnswerSelected();
    if (!someAnswerSelected) return false;

    const typeQuestionSelected = checkIfTypeQuestionIsSelected();
    if (!typeQuestionSelected) return false;

    const sumAllpointValid = checkIfPointIsAvailable();
    if (!sumAllpointValid) return false;

    const typeQuestion = taskToCreate?.typeQuestion!;
    const data = {
      dta_question: values.question,
      dta_score_evaluated: taskToCreate?.act_is_score === 0 ? 0 : values.point,
      catalogo_id: typeAnswerId,
      is_manual: typeQuestion === 'commentary' ? 1 : 0,
    };

    //setValue((itemprev) => itemprev + 1);
    setNumQuestions((itemprev) => itemprev + 1);
    dispatch(setTaskQuestions(data));
    resetForm();
    setOptionsList([]);
  };

  const unsetQuestion = (index: number) => {
    if (index === 0) setPage(1);
    else setPage(index);

    dispatch(unsetIndexQuestion(index));
  };

  const handleSetTyQuestion = (typeQuestion: string) => {
    dispatch(setTaskTypeQuestion(typeQuestion));
  };

  return (
    <Grid margin={'15px 0'}>
      <Grid container justifyContent={'Center'}>
        <Typography variant="h2" pr={'15px'}>
          {' '}
          {t('teacherVirtualClassroom.tasks.questions.puntaje.total')}:{' '}
          {taskToCreate?.act_score_activity}
        </Typography>
        <Typography variant="h2">
          {' '}
          {t('teacherVirtualClassroom.tasks.questions.puntaje.restante')}:{' '}
          {taskToCreate?.act_score_activity! - sumPointsActivity()}
        </Typography>
      </Grid>
      <Grid
        container
        item
        xs={12}
        mt={'12px'}
        spacing={2}
        justifyContent="center"
        alignContent="center"
      >
        <Grid item xs={8} md={8}>
          <Grid component={SubCard}>
            {isSuccess && showAlert && (
              <Grid container justifyContent="space-between" item sx={{ mb: 3 }}>
                <Grid item xs={12}>
                  <SuccessAlert
                    message={t('teacherVirtualClassroom.tasks.questions.success')}
                    handleDismiss={() => setShowAlert(false)}
                  />
                </Grid>
              </Grid>
            )}

            {isError && showAlert && (
              <Grid container justifyContent="space-between" item sx={{ mb: 3 }}>
                <Grid item xs={12}>
                  <ErrorAlert message={error as string} handleDismiss={() => setShowAlert(false)} />
                </Grid>
              </Grid>
            )}

            {pointInvalid && (
              <Grid container justifyContent="space-between" item sx={{ mb: 3 }}>
                <Grid item xs={12}>
                  <ErrorAlert
                    message={`Total de puntos de actividad no puede ser mayor a ${taskToCreate?.act_score_activity} puntos`}
                    handleDismiss={() => setPointInvalid(false)}
                  />
                </Grid>
              </Grid>
            )}

            {typeQuestionNoSelected && (
              <Grid container justifyContent="space-between" item sx={{ mb: 3 }}>
                <Grid item xs={12}>
                  <ErrorAlert
                    message={`Tipo de pregunta no seleccionada.`}
                    handleDismiss={() => setTypeQuestionNoSelected(false)}
                  />
                </Grid>
              </Grid>
            )}

            {optionNotSelected && (
              <Grid container justifyContent="space-between" item sx={{ mb: 3 }}>
                <Grid item xs={12}>
                  <ErrorAlert
                    message={`Seleccione al menos una respuesta.`}
                    handleDismiss={() => setOptionNotSelected(false)}
                  />
                </Grid>
              </Grid>
            )}

            <>
              <Grid container justifyContent={'center'}>
                {taskToCreate?.act_is_score !== 0 && (
                  <Grid item>
                    <Typography variant="h5" component="div" sx={{ mb: 1 }}>
                      {t('teacherVirtualClassroom.tasks.questions.puntaje.question')}
                    </Typography>

                    <Tooltip
                      title={t('teacherVirtualClassroom.tasks.questions.puntaje.question_range')}
                    >
                      <TextField
                        id="button-action-search"
                        fullWidth
                        label="Puntaje"
                        InputProps={{
                          inputProps: {
                            min: 0.25,
                            step: 0.25,
                            max: taskToCreate?.act_score_activity! - sumPointsActivity(),
                          },
                        }}
                        type="number"
                        {...getFieldProps('point')}
                        variant="outlined"
                      />
                    </Tooltip>

                    {errors.point && touched.point && (
                      <FormHelperText error>{errors.point}</FormHelperText>
                    )}
                  </Grid>
                )}
              </Grid>
              <Grid container justifyContent="center" item>
                {/* Container left */}

                <Grid item xs={12}>
                  <Grid
                    style={{
                      borderTop: '4px solid #065CAB"',
                      borderRadius: '15px',
                      paddingBottom: '15px',
                    }}
                  >
                    <Grid item xs={12} sx={{ mt: 3 }}>
                      <InputLabel>
                        {t('teacherVirtualClassroom.tasks.questions.input.title')}
                      </InputLabel>
                      <TextField
                        multiline
                        rows={4}
                        style={{ height: '120px' }}
                        fullWidth
                        error={Boolean(errors.question && touched.question)}
                        placeholder="Ingrese pregunta..."
                        {...getFieldProps('question')}
                      />
                      {errors.question && touched.question && (
                        <FormHelperText error>{errors.question}</FormHelperText>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                {/* Container rigth */}
                <Grid item xs={12}>
                  <Grid container item justifyContent="center">
                    <Button
                      style={{ textTransform: 'unset', borderRadius: 0 }}
                      variant="contained"
                      onClick={handleSaveQuestion}
                      size="small"
                      color="info"
                    >
                      {t('teacherVirtualClassroom.tasks.questions.button.add')}
                    </Button>
                    {/*
               onClick={() => handleSetTyQuestion('single')}
               startIcon={<IconCircle />}
               style={{ textTransform: 'unset', borderRadius: 0 }}
                variant={taskToCreate?.typeQuestion === 'single' ? 'contained' : 'outlined'}
                size="small"
                color="primary"
              >
                Una respuesta
              </Button>
              <Button
              <Button
              onClick={() => handleSetTyQuestion('multiple')}
              startIcon={<IconCheckbox />}
              style={{ textTransform: 'unset', borderRadius: 0 }}
              color="primary"
              variant={taskToCreate?.typeQuestion === 'multiple' ? 'contained' : 'outlined'}
              >
              Varias respuesta
              </Button>
              */}
                    <Button
                      onClick={() => handleSetTyQuestion('commentary')}
                      startIcon={<IconCheckbox />}
                      style={{ textTransform: 'unset', borderRadius: 0 }}
                      color="primary"
                      variant={
                        taskToCreate?.typeQuestion === 'commentary' ? 'contained' : 'outlined'
                      }
                      size="small"
                    >
                      {t('teacherVirtualClassroom.tasks.questions.button.text')}
                    </Button>
                    <Button
                      startIcon={<IconCheckbox />}
                      onClick={() => handleSetTyQuestion('Imagen')}
                      style={{ textTransform: 'unset', borderRadius: 0 }}
                      color="primary"
                      size="small"
                      variant={taskToCreate?.typeQuestion === 'Imagen' ? 'contained' : 'outlined'}
                    >
                      {t('teacherVirtualClassroom.tasks.questions.button.upload')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </>
          </Grid>
        </Grid>

        {taskToCreate?.detail && taskToCreate?.detail?.length > 0 && (
          <Grid item container xs={12} justifyContent="center" spacing={1}>
            <Grid item xs={8}>
              <Typography variant="h5" component="div" sx={{ mb: 1 }}>
                Preview
              </Typography>
            </Grid>
            <Grid item xs={8}>
              {t('teacherVirtualClassroom.tasks.questions.puntaje.question')}
              <TextField
                value={taskToCreate?.detail[Page - 1].dta_score_evaluated}
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={8}>
              <InputLabel>{t('teacherVirtualClassroom.tasks.questions.input.title')}</InputLabel>
              <TextField
                multiline
                rows={3}
                value={taskToCreate?.detail[Page - 1].dta_question}
                fullWidth
                disabled
              />
            </Grid>
            <Grid item container justifyContent="center" xs={8}>
              <Stack textAlign={'center'} spacing={2}>
                <Pagination
                  count={taskToCreate?.detail?.length!}
                  page={Page}
                  onChange={(_, value) => setPage(value)}
                  color="primary"
                />
              </Stack>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12} sx={{ mt: 5 }}>
          <Grid container item justifyContent="center" spacing={1}>
            <Grid item>
              <Button
                onClick={() => handleChooseGroup()}
                style={{ textTransform: 'unset' }}
                variant="outlined"
                size="large"
                color="primary"
              >
                {t('teacherVirtualClassroom.tasks.questions.button.cancel')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={() => unsetQuestion(Page - 1)}
                disabled={!taskToCreate?.detail?.length || isLoading}
                style={{ textTransform: 'unset' }}
                variant="contained"
                size="large"
                color="error"
              >
                {t('teacherVirtualClassroom.tasks.questions.button.remove')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={() => handleSubmitForm()}
                disabled={sumPointsActivity() !== taskToCreate?.act_score_activity! || isLoading}
                endIcon={isLoading && <CircularProgress size={20} />}
                style={{ textTransform: 'unset' }}
                variant="contained"
                size="large"
                color="primary"
              >
                {t('teacherVirtualClassroom.tasks.questions.button.finish')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TaskChooseQuestions;
