import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISearchTable } from '../../../shared/interfaces/paginatedTable.interface';
import { AcademicRequests } from '../interfaces/academicRequests.interfaces';

interface IBondingProgram {
  name: string;
  value: string;
}
interface AcademicRequestsState {
  academicRequestsList: AcademicRequests[];
  deleteModalOpen: boolean;
  academicRequestIdToDelete?: number;

  /** Bonding */
  bondingProgram?: IBondingProgram;
  bondingProgramsTable: ISearchTable;
}

const initialState: AcademicRequestsState = {
  academicRequestsList: [],
  deleteModalOpen: false,

  /** Bonding */
  bondingProgramsTable: {
    page: 1,
    perPage: 10,
    search: '',
  },
};

const academicRequestsSlice = createSlice({
  name: 'academicRequests',
  initialState,
  reducers: {
    setAcademicRequestsList: (state, action: PayloadAction<AcademicRequests[]>) => {
      state.academicRequestsList = action.payload;
    },
    setDeleteModalOpen: (state, action: PayloadAction<boolean>) => {
      state.deleteModalOpen = action.payload;
    },
    openDeleteModalWith: (state, action: PayloadAction<number>) => {
      state.academicRequestIdToDelete = action.payload;
      state.deleteModalOpen = true;
    },
    resetAcademicRequestIdToDelete: (state) => {
      state.academicRequestIdToDelete = undefined;
    },

    /** Bonding */
    setBondingProgram: (state, action: PayloadAction<IBondingProgram>) => {
      state.bondingProgram = action.payload;
    },
    updateBondingProgramsTable: (state, action: PayloadAction<ISearchTable>) => {
      state.bondingProgramsTable = action.payload;
    },

    /** */
  },
});
export const {
  setAcademicRequestsList,
  setDeleteModalOpen,
  openDeleteModalWith,
  resetAcademicRequestIdToDelete,

  //bonding
  setBondingProgram,
  updateBondingProgramsTable,

  //
} = academicRequestsSlice.actions;
export default academicRequestsSlice.reducer;
