import { Grid, Typography } from '@material-ui/core';
// import { useAppSelector } from '../../../../hooks/useRedux';
import MainCard from '../../../shared/components/cards/MainCard';
import CalendarFormTemplate from '../components/CalendarFormTemplate';
import Breadcrumb, { Data } from '../components/Breadcrumbs';

const TemplateScreen = () => {
  const data: Data[] = [
    { name: 'Planeación académica posgrado', url: '/planeacion-academica-posgrado' },
    {
      name: 'Elección de inicio de programa',
      url: '/planeacion-academica-posgrado/fechas-carrera',
    },
  ];
  return (
    <div>
      <Grid component={MainCard} sx={{ padding: 2 }}>
        <Grid sx={{ mb: 3 }}>
          <Typography variant="h2" mb={2}>
            Plantilla
          </Typography>
          <Breadcrumb data={data} currentName="Plantilla" />
        </Grid>
        <CalendarFormTemplate />
      </Grid>
    </div>
  );
};

export default TemplateScreen;
