import { IconButton, Tooltip } from '@material-ui/core';
import { IconCamera } from '@tabler/icons';

const CameraAddEventNew = ({ handleEventNew }: { handleEventNew?: () => void }) => {
  return (
    <Tooltip title="Agregar foto">
      <IconButton aria-label="Agregar foto" color="default" onClick={handleEventNew}>
        <IconCamera />
      </IconButton>
    </Tooltip>
  );
};

export default CameraAddEventNew;
