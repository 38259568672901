import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import ConfirmAlert from '../../../shared/components/dialogs/ConfirmAlert';
import { useApproveSubjectCommunityEngagementMutation } from '../slices/followUpOfResearchProjectApiSlice';
import {
  setApproveBinnacleDialog,
  setStudentSelectedByFollowUpOfResearchProject,
} from '../slices/followUpOfResearchProjectSlice';

const ApproveBinnancleDialog = () => {
  const dispatch = useAppDispatch();

  const { studentSelected, approveBinnacleDialog } = useAppSelector(
    (state) => state.followUpOfResearch
  );

  const [approveSumaryCommunityEngagement, { isLoading, isSuccess, isError, error }] =
    useApproveSubjectCommunityEngagementMutation();

  const handleClose = () => {
    dispatch(setStudentSelectedByFollowUpOfResearchProject(null));
    dispatch(setApproveBinnacleDialog(false));
  };

  const handleApprove = () => {
    approveSumaryCommunityEngagement({
      projectId: studentSelected?.project_research_id!,
      studentId: studentSelected?.student_id!,
    });
  };

  return (
    <ConfirmAlert
      open={approveBinnacleDialog}
      onClose={handleClose}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      handleDelete={handleApprove}
      error={error as string}
      title="¿Está seguro de aprobar el proceso de Vinculación?"
      subtitle="Al aprobar el proceso de Vinculación, el estudiante ya no podrá realizar cambios en su bitácora."
      confirmBtnMessage="Sí, Aprobar"
      successMessage="El proceso de Vinculación ha sido aprobado."
    />
  );
};

export default ApproveBinnancleDialog;
