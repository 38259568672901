import { useCallback, useState } from 'react';

import { Box, Button, CircularProgress, Menu, MenuItem, Stack, useTheme } from '@material-ui/core';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import { IconFileReport, IconFileSpreadsheet } from '@tabler/icons';
import { downloadExcel } from 'react-export-table-to-excel';

import { Exports } from './SearchPaginatedTable';
import LoadingButton from '../buttons/LoadingButton';
import ExportFilesExcelBtn from './buttons/ExportFilesExcelBtn';
import { TableHeaders } from '../../interfaces/material-ui.interfaces';

interface Props<T extends {}> {
  tableRef: any;
  headers: TableHeaders<T>;
}

type IComponet<T extends {}> = Props<T> & Omit<Exports<T>, 'portalId'>;

const ExportFilesMenu = <T extends {}>({
  fileName: filename,
  tableRef,
  files,
  variant = 'menu',
  batch = false,
  allDataFetchHandler,
  headers,
}: IComponet<T>) => {
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [isLoading, setIsLoading] = useState(false);

  const fileName = filename || 'Reportes';
  const { options: _, ...newHeaders } = headers as any;

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDownloadExcel = useCallback(async () => {
    setIsLoading(true);
    const data = await allDataFetchHandler?.();

    downloadExcel({
      fileName: fileName,
      sheet: fileName,
      tablePayload: {
        header: Object.values(newHeaders),
        // accept two different data structures
        body: (data as any) || [],
      },
    });

    setIsLoading(false);
  }, [allDataFetchHandler, fileName, newHeaders]);

  if (variant === 'buttons') {
    return (
      <Stack direction="row" spacing={1}>
        {(!files || files.excel) && (
          <ExportFilesExcelBtn
            excelDownloadHandler={allDataFetchHandler ? handleDownloadExcel : undefined}
            isLoading={isLoading}
            tableRef={tableRef}
            fileName={fileName}
            variant="buttons"
          />
        )}
        {(!files || files.pdf) && (
          <LoadingButton
            variant="outlined"
            isLoading={isLoading}
            color="primary"
            startIcon={<IconFileSpreadsheet />}
            onClick={handleDownloadExcel}
          >
            Exportar a PDF
          </LoadingButton>
        )}
      </Stack>
    );
  }

  return (
    <>
      <Box
        component={Button}
        size="large"
        sx={{
          color: theme.palette.grey[900],
          backgroundColor: theme.palette.primary.light,
          ':hover': { bgcolor: theme.palette.primary.light },
          borderRadius: 4,
        }}
        endIcon={<ExpandMoreRoundedIcon />}
        onClick={handleClick}
      >
        <IconFileReport />
      </Box>

      <Menu
        id="menu-user-list-style1"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={() => handleClose()}
        variant="selectedMenu"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {(!files || files.excel) && (
          <ExportFilesExcelBtn
            excelDownloadHandler={allDataFetchHandler ? handleDownloadExcel : undefined}
            isLoading={isLoading}
            tableRef={tableRef}
            fileName={fileName}
            variant="menu"
          />
        )}

        {(!files || files.pdf) && (
          <MenuItem onClick={() => handleClose()} disabled>
            <IconFileSpreadsheet /> Pdf (próximamente) {isLoading && <CircularProgress size={20} />}
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default ExportFilesMenu;
