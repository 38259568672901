import { Box, Typography, useTheme } from '@material-ui/core';
import TableHeaderInfo from './TableHeaderInfo';
import { InsertDriveFileOutlined } from '@material-ui/icons';
import { CancelPresentationOutlined } from '@material-ui/icons';
import { AccessTimeOutlined } from '@material-ui/icons';

interface Props {
  onClick: () => void;
  data: {
    month: string;
    fj: string;
    fi: string;
    fr: string;
    ai: string;
    aj: string;
  };
  viewDetails: boolean;
}

const TableHeaderAttendance = ({ data, viewDetails, onClick }: Props) => {
  const theme = useTheme();
  const { ai, fi, fj, aj, fr, month } = data;
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        borderBottom: `${viewDetails ? `1px solid ${theme.palette.secondary.main}` : 'none'}`,
        p: 2,
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      <Typography
        color={theme.palette.primary.dark}
        variant="h3"
        sx={{
          mr: 5,
          mb: 1,
        }}
      >
        {month}
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <TableHeaderInfo
          Icon={
            <CancelPresentationOutlined
              sx={{
                color: '#B51F23',
                fontSize: theme.typography.body1.fontSize,
              }}
            />
          }
          info={fi}
        />
        <TableHeaderInfo
          Icon={
            <InsertDriveFileOutlined
              sx={{
                color: '#2570B5',
                fontSize: theme.typography.body1.fontSize,
              }}
            />
          }
          info={fj}
        />
        <TableHeaderInfo
          Icon={
            <InsertDriveFileOutlined
              sx={{
                color: '#1E5D23',
                fontSize: theme.typography.body1.fontSize,
              }}
            />
          }
          info={fr}
        />
        <TableHeaderInfo
          Icon={
            <InsertDriveFileOutlined
              sx={{
                color: '#F37F2B',
                fontSize: theme.typography.body1.fontSize,
              }}
            />
          }
          info={ai}
        />
        <TableHeaderInfo
          Icon={
            <AccessTimeOutlined
              sx={{
                color: '#1E5D23',
                fontSize: theme.typography.body1.fontSize,
              }}
            />
          }
          info={aj}
        />
      </Box>
    </Box>
  );
};

export default TableHeaderAttendance;
