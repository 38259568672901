import { FC } from 'react';
import { Skeleton, Typography, TypographyProps } from '@material-ui/core';
import { Variant } from '@material-ui/core/styles/createTypography';

interface Props extends Partial<TypographyProps> {
  isLoading?: boolean;
  variant?: Variant;
  component?: React.ElementType;
}

/** Un Typography con skeleton
 * ## Interface
 * ```
 *  interface Props {
 *    isLoading?: boolean;
 *    ...props?: TypographyProps;
 *    }
 * ```
 */
const TypographyFetching: FC<Props> = ({ children, isLoading, ...props }) => {
  return (
    <Typography variant="h4" component="span" {...props}>
      {isLoading ? (
        <Skeleton variant="text">
          <Typography variant="body2" component="span" {...props}>
            placeholder
          </Typography>
        </Skeleton>
      ) : (
        children
      )}
    </Typography>
  );
};

export default TypographyFetching;
