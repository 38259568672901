import DeleteAlert from '../../../shared/components/dialogs/DeleteAlert';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { useDeleteGroupAreaMutation } from '../slices/groupAreasApiSlice';
import { resetGroupAreaIdToDelete, setDeleteModalOpen } from '../slices/groupAreasSlice';

const DeleteGroupDialog = () => {
  const dispatch = useAppDispatch();

  const { deleteModalOpen, groupAreaIdToDelete } = useAppSelector((state) => state.groupAreas);
  const { currentProfile } = useAppSelector((state) => state.access);

  const [deleteGroupArea, { isLoading, isSuccess, isError, error }] = useDeleteGroupAreaMutation();

  const handleDelete = () => {
    deleteGroupArea({ profileId: currentProfile?.profileId!, groupAreaId: groupAreaIdToDelete! });
  };

  const handleClose = () => {
    dispatch(setDeleteModalOpen(false));
    dispatch(resetGroupAreaIdToDelete());
  };

  return (
    <DeleteAlert
      open={deleteModalOpen}
      onClose={handleClose}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      handleDelete={handleDelete}
      error={error as string}
    />
  );
};

export default DeleteGroupDialog;
