import { Grid } from '@material-ui/core';
import MainCard from '../../../shared/components/cards/MainCard';
import MatterDetailHeader from '../components/matter-detail/MatterDetailHeader';
import MatterDetailIndex from '../components/matter-detail/MatterDetailIndex';

const AcademicMatterDetailScreen = () => {
  return (
    <Grid component={MainCard}>
      <MatterDetailHeader />
      <MatterDetailIndex />
    </Grid>
  );
};

export default AcademicMatterDetailScreen;
