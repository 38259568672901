import { Grid, Typography, useTheme } from '@material-ui/core';

interface Props {
  icon?: JSX.Element;
  title?: JSX.Element | string;
  subtitle?: JSX.Element | string;
}

const IconComponent = ({ icon, title, subtitle }: Props) => {
  const theme = useTheme();
  return (
    <Grid container justifyContent={'center'} alignContent={'center'} sx={{ height: '50vh' }}>
      <Grid container justifyContent={'center'} item xs={8} md={6}>
        <Grid container item xs={12} justifyContent={'center'}>
          {icon}
        </Grid>
        <Typography variant={'body1'} textAlign={'center'} lineHeight={2} component={'div'}>
          <Typography
            variant={'h4'}
            sx={{ color: theme.palette.primary.main }}
            component={'p'}
            align={'center'}
            lineHeight={2}
          >
            {title}
          </Typography>
          {subtitle}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default IconComponent;
