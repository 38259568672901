export const newOption = {
  description: '',
  value: '',
};
export const newOptionEdit = {
  uuid: '',
  description: '',
  value: '',
};

export const newOptionIntervals = {
  name: '',
  keyword: '',
  scale_start: '',
  scale_end: '',
};
export const newOptionIntervalsEdit = {
  id: '',
  name: '',
  keyword: '',
  scale_start: '',
  scale_end: '',
};
