import { mainApi } from '../../../../../../api/mainApi';
import { middlewareApi } from '../../../../../middleware/middlewareApi';
import { IResponse } from '../../../../../shared/interfaces/response.interface';
import { RubricData } from '../../integral_evaluation/interfaces/rubrics.interface';
import { IOptions, IOptionsTemplate } from '../interfaces/optionsTemplate.interface';
import {
  RepresentativeScaleData,
  RepresentativeScalePayload,
} from '../interfaces/representativeScale.interface';
import { EscaleData } from '../interfaces/scale.interface';

export const optionsTemplateTags = middlewareApi.enhanceEndpoints({
  addTagTypes: ['OptionsTemplate', 'RepresentativeScale'],
});

export const optionsTemplateApi = optionsTemplateTags.injectEndpoints({
  endpoints: (builder) => ({
    getOptionsTemplates: builder.query<
      IResponse<IOptionsTemplate>,
      {
        page: number;
        perPage: number;
        search: string;
        sort?: string;
        typeSort?: string;
      }
    >({
      queryFn: async ({ page, perPage, search, sort, typeSort }, { dispatch }) => {
        try {
          const { data } = await mainApi.get<IResponse<IOptionsTemplate>>('/eva/templates', {
            params: {
              page,
              size: perPage,
              search,
              ...(sort && { sort }),
              ...(typeSort && { type_sort: typeSort }),
            },
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) =>
        result && result.data
          ? [
              ...result.data.map(({ uuid: id }) => ({ type: 'OptionsTemplate', id } as const)),
              { type: 'OptionsTemplate', id: 'LIST' },
            ]
          : [{ type: 'OptionsTemplate', id: 'LIST' }],
    }),
    getAllOptionsTemplates: builder.query<IOptionsTemplate[], {}>({
      queryFn: async (_, { dispatch }) => {
        try {
          const { data } = await mainApi.get<IOptionsTemplate[]>('/eva/templates', {
            params: {
              data: 'all',
            },
          });

          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ uuid: id }) => ({ type: 'OptionsTemplate', id } as const)),
              { type: 'OptionsTemplate', id: 'LIST' },
            ]
          : [{ type: 'OptionsTemplate', id: 'LIST' }],
    }),

    getAllScales: builder.query<EscaleData[], {}>({
      queryFn: async (_, { dispatch }) => {
        try {
          const { data } = await mainApi.get<EscaleData[]>('/eva/evaluation-scale', {
            params: {
              data: 'all',
            },
          });

          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    getOptionsTemplate: builder.query<
      IOptionsTemplate,
      {
        id: string;
      }
    >({
      queryFn: async ({ id }, { dispatch }) => {
        try {
          const { data } = await mainApi.get<IOptionsTemplate>(`/eva/templates/${id}`);
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),
    addOptionsTemplate: builder.mutation<
      IOptionsTemplate,
      {
        description: string;
        options: IOptions[];
      }
    >({
      queryFn: async ({ description, options }, { dispatch }) => {
        try {
          const { data } = await mainApi.post<IOptionsTemplate>('/eva/templates', {
            description,
            options,
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: () => [{ type: 'OptionsTemplate', id: 'LIST' }],
    }),
    updateOptionsTemplate: builder.mutation<
      IOptionsTemplate,
      {
        id: string;
        description: string;
        options: IOptions[];
      }
    >({
      queryFn: async ({ id, description, options }, { dispatch }) => {
        try {
          const { data } = await mainApi.put<IOptionsTemplate>(`/eva/templates/${id}`, {
            description,
            options,
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: () => [{ type: 'OptionsTemplate', id: 'LIST' }],
    }),
    deleteOptionsTemplate: builder.mutation<
      IOptionsTemplate,
      {
        id: string;
      }
    >({
      queryFn: async ({ id }, { dispatch }) => {
        try {
          const { data } = await mainApi.delete<IOptionsTemplate>(`/eva/templates/${id}`);
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: () => [{ type: 'OptionsTemplate', id: 'LIST' }],
    }),

    /*
      @escala representativa
    */
    getRepresentativeScale: builder.query<
      IResponse<RepresentativeScaleData>,
      {
        page: number;
        perPage: number;
        search: string;
        sort?: string;
        typeSort?: string;
      }
    >({
      queryFn: async ({ page, perPage, search, sort, typeSort }, { dispatch }) => {
        try {
          const { data } = await mainApi.get<IResponse<RepresentativeScaleData>>(
            '/eva/evaluation-scale',
            {
              params: {
                page,
                size: perPage,
                search,
                ...(sort && { sort }),
                ...(typeSort && { type_sort: typeSort }),
              },
            }
          );
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) =>
        result && result.data
          ? [
              ...result.data.map(({ id }) => ({ type: 'OptionsTemplate', id } as const)),
              { type: 'RepresentativeScale', id: 'LIST' },
            ]
          : [{ type: 'RepresentativeScale', id: 'LIST' }],
    }),

    getRepresentativeScaleAll: builder.query<RepresentativeScaleData[], {}>({
      queryFn: async (_, { dispatch }) => {
        try {
          const { data } = await mainApi.get<RepresentativeScaleData[]>('/eva/evaluation-scale', {
            params: {
              data: 'all',
            },
          });
          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    getRubricByID: builder.query<
      RubricData,
      {
        id: string | number;
      }
    >({
      queryFn: async ({ id }, { dispatch }) => {
        try {
          const { data } = await mainApi.get<RubricData>(`/eva/evaluations-rubrica/${id}`);
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    addRepresentativeScale: builder.mutation<any, RepresentativeScalePayload>({
      queryFn: async (payload, { dispatch }) => {
        try {
          const { data } = await mainApi.post<any>('/eva/evaluation-scale', {
            ...payload,
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: () => [{ type: 'RepresentativeScale', id: 'LIST' }],
    }),
    updateRepresentativeScale: builder.mutation<
      any,
      {
        id: string;
        payload: RepresentativeScalePayload;
      }
    >({
      queryFn: async ({ id, payload }, { dispatch }) => {
        try {
          const { data } = await mainApi.put<any>(`/eva/evaluation-scale/${id}`, {
            ...payload,
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: () => [{ type: 'RepresentativeScale', id: 'LIST' }],
    }),
    deleteRepresentativeScale: builder.mutation<
      any,
      {
        id: string;
      }
    >({
      queryFn: async ({ id }, { dispatch }) => {
        try {
          const { data } = await mainApi.delete<any>(`/eva/evaluation-scale/${id}`);
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: () => [{ type: 'RepresentativeScale', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetOptionsTemplatesQuery,
  useLazyGetOptionsTemplatesQuery,
  useGetAllOptionsTemplatesQuery,
  useGetOptionsTemplateQuery,
  useLazyGetOptionsTemplateQuery,
  useAddOptionsTemplateMutation,
  useUpdateOptionsTemplateMutation,
  useDeleteOptionsTemplateMutation,
  useGetAllScalesQuery,
  useGetRepresentativeScaleQuery,
  useGetRepresentativeScaleAllQuery,
  useLazyGetRubricByIDQuery,
  useGetRubricByIDQuery,
  useAddRepresentativeScaleMutation,
  useUpdateRepresentativeScaleMutation,
  useDeleteRepresentativeScaleMutation,
} = optionsTemplateApi;
