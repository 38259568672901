import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISearchTable } from '../../../shared/interfaces/paginatedTable.interface';
import { EconomicGroup } from '../interfaces/economicGroups.interfaces';

interface EconomicGroupsState {
  economicGroupsList: EconomicGroup[];
  deleteModalOpen: boolean;
  economicGroupIdToDelete?: number;
  EconomicGroupTable: ISearchTable;
}

const initialState: EconomicGroupsState = {
  economicGroupsList: [],
  deleteModalOpen: false,
  EconomicGroupTable: {
    page: 1,
    perPage: 5,
    search: '',
  },
};

const economicGroupsSlice = createSlice({
  name: 'economicGroups',
  initialState,
  reducers: {
    setEconomicGroupList: (state, action: PayloadAction<EconomicGroup[]>) => {
      state.economicGroupsList = action.payload;
    },
    setDeleteModalOpen: (state, action: PayloadAction<boolean>) => {
      state.deleteModalOpen = action.payload;
    },
    openDeleteModalWith: (state, action: PayloadAction<number>) => {
      state.economicGroupIdToDelete = action.payload;
      state.deleteModalOpen = true;
    },
    resetEconomicGroupIdToDelete: (state) => {
      state.economicGroupIdToDelete = undefined;
    },
    updateEconomicGroupeTableStates: (state, action: PayloadAction<ISearchTable>) => {
      state.EconomicGroupTable = action.payload;
    },
  },
});

export const {
  setEconomicGroupList,
  setDeleteModalOpen,
  openDeleteModalWith,
  resetEconomicGroupIdToDelete,
  updateEconomicGroupeTableStates,
} = economicGroupsSlice.actions;

export default economicGroupsSlice.reducer;
