import React, { useEffect, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';
import { useParams } from 'react-router-dom';

import MainCard from '../../../../shared/components/cards/MainCard';
import MeetingsEditForm from '../components/MeetingsEditForm';
import BackButton from '../../../../shared/components/buttons/BackButton';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import { useLazyGetMeetingByIdQuery } from '../slices/teacherMeetingsApi';
import { setMeetingsList } from '../slices/teacherMeetingsSlice';
import LoadingChildren from '../../../../shared/components/LoadingChildren';
import SimpleFormOneColumn from '../../../../shared/components/forms/skeletons/SimpleFormOneColumn';

const EditMeetingsScreen = function () {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { activityId } = useParams<{ activityId: string }>();

  const { meetingList } = useAppSelector((state) => state.meetingsTeacher);

  const meetingData = useMemo(
    () => meetingList.find((meeting) => meeting?.activity_zoom?.activity_id === activityId),
    [meetingList, activityId]
  );

  const {
    course: { courseId },
  } = useAppSelector((state) => state.virtualClassroomTeacherShared);

  const [getMeet, { isFetching }] = useLazyGetMeetingByIdQuery();

  useEffect(() => {
    if (!meetingList.length && !isFetching && activityId) {
      getMeet({
        activity_id: +activityId,
        courseId: +courseId,
      })
        .unwrap()
        .then((response) => {
          if (response) dispatch(setMeetingsList([response!]));
        })
        .catch((error) => console.log(error));
    }
  }, [dispatch, getMeet, isFetching, activityId, meetingList.length, courseId]);

  return (
    <React.Fragment>
      <Grid
        component={MainCard}
        sx={{
          borderTopLeftRadius: '0px',
          borderTopRightRadius: '0px',
        }}
      >
        <Grid sx={{ mb: 3 }} container spacing={1} alignItems="center">
          <BackButton />
          <Typography variant="h2">{t('teacher_virtual_classroom.meetings.edit.title')}</Typography>
        </Grid>

        <LoadingChildren
          isLoading={isFetching}
          isEmpty={!meetingData && !isFetching}
          loadingSkeleton={<SimpleFormOneColumn />}
        >
          <MeetingsEditForm meetingData={meetingData} />
        </LoadingChildren>
      </Grid>
    </React.Fragment>
  );
};

export default EditMeetingsScreen;
