import { Grid, IconButton, Tooltip, Typography } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import MainCard from '../../../shared/components/cards/MainCard';
import ProgramPeriodDates from '../components/ProgramPeriodDates';

const ApprovePlanificationScreen = () => {
  const history = useHistory();
  const handleBack = () => {
    history.push('/listado-planeacion-academica-posgrado');
  };

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid container alignItems="center" sx={{ mb: 3 }}>
        <Tooltip title="Regresar">
          <IconButton onClick={handleBack}>
            <ArrowBack />
          </IconButton>
        </Tooltip>
        <Typography variant="h2" mb={2}>
          Etapas del período
        </Typography>
      </Grid>
      <ProgramPeriodDates />
    </Grid>
  );
};

export default ApprovePlanificationScreen;
