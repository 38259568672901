import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../../../../hooks/useRedux';
import MainCard from '../../../../../../shared/components/cards/MainCard';
import LoadingData from '../../../../../../shared/components/cards/Skeleton/LoadingData';
import { setResetStep } from '../../../../slices/virtualClassroomTeacherSlice';
import { useGetEvaluationDetailQuery } from '../../../slices/evaluationApiSlice';
import PreCreatedQuestionOptions from './PreCreatedQuestionOptions';

function EditScreenEvaluations() {
  //setInfoEvaluation
  const dispatch = useAppDispatch();

  const {
    evaluation: { infoEvaluation },
  } = useAppSelector((state) => state.evaluationTeacher);
  const { courseId, evaluationId } = useParams<{ courseId: string; evaluationId: string }>();
  const { currentProfile } = useAppSelector((state) => state.access);
  const { data } = useGetEvaluationDetailQuery({
    courseId: Number(courseId),
    evaluationId: Number(evaluationId),
    profileId: currentProfile?.profileId!,
  });

  console.log('Detail EDITAD', infoEvaluation);

  useEffect(() => {
    return () => {
      dispatch(setResetStep());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!data) {
    return <LoadingData />;
  }

  return (
    <MainCard title="Editar Evaluación">
      <PreCreatedQuestionOptions />
    </MainCard>
  );
}

export default EditScreenEvaluations;
