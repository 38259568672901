import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CoursesCollaborator } from '../../../maintenance/courses/interfaces/coursesCollaborator.interface';
import { RegisterSyllabusData } from '../../../shared/interfaces/registerSyllabusData';
import { CourseStudents } from '../interfaces/courseStudents.interfaces';

interface CoursesTeacherState {
  coursesTeacherList?: CoursesCollaborator[];
  courseStudentsList?: CourseStudents[];
  deleteModalOpen: boolean;
  registerSyllabusData?: RegisterSyllabusData | null;
  periodId?: number | null;
}

const initialState: CoursesTeacherState = {
  deleteModalOpen: false,
  registerSyllabusData: null,
  periodId: null,
};

const coursesTeacherSlice = createSlice({
  name: 'courseTeacher',
  initialState,
  reducers: {
    setCoursesTeacherList: (state, action: PayloadAction<CoursesCollaborator[]>) => {
      state.coursesTeacherList = action.payload;
    },
    setCourseStudentsList: (state, action: PayloadAction<CourseStudents[]>) => {
      state.courseStudentsList = action.payload;
    },
    setDeleteModalOpen: (state, action: PayloadAction<boolean>) => {
      state.deleteModalOpen = action.payload;
    },
    setRegisterSyllabusData: (state, action: PayloadAction<RegisterSyllabusData | null>) => {
      state.registerSyllabusData = action.payload;
    },
    setPeriodForCourse: (state, action: PayloadAction<{ periodId: number }>) => {
      const { periodId } = action.payload;
      state.periodId = periodId;
    },
  },
});

export const {
  setCoursesTeacherList,
  setCourseStudentsList,
  setDeleteModalOpen,
  setRegisterSyllabusData,
  setPeriodForCourse,
} = coursesTeacherSlice.actions;

export default coursesTeacherSlice.reducer;
