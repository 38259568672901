import { useMemo, useState } from 'react';

import {
  TableBody,
  TableContainer,
  Table,
  CircularProgress,
  Grid,
  TableCell,
  TableRow,
} from '@material-ui/core';
// import { CalificationsStudentResponse } from '../../interfaces/notes.interfaces';
import HeaderTable from './HeaderTable';
import CustomRow from './CustomRow';
import {
  getCol,
  getRows,
  useColumns,
} from '../../../../teachers/virtual-classroom/califications/helpers/dataGridHelper';
import { withStyles } from '@material-ui/core/styles';
import { useAppSelector } from '../../../../../hooks/useRedux';
import SubCard from '../../../../shared/components/cards/SubCard';
import {
  useGetCourseStudentCalificationsQuery,
  useGetDistributionCalificationQuery,
} from '../../../../teachers/virtual-classroom/slices/virtualClassroomTeacherApiSlice';
import { StudentCalifications } from '../../../../teachers/virtual-classroom/interfaces/studentCalification.interfaces';
import { getStudentVirtualClassroomGrades } from '../helpers';
import { isEmptyObject } from '../../../../shared/helpers';
import NoGradesIcon from './NoGradesIcon';
import ModalNotas from './modal/ModalNotas';

export interface DataStudent {
  id: number;
  name: string;
  grade?: StudentCalifications[];
}

export const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white + ' !important',
    border: `1px solid ${theme.palette.common.white}`,
  },
  body: {
    fontSize: 14,
    // border: `1px solid ${theme.palette.common.black}`,
  },
}))(TableCell);

export const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const NotesTable = () => {
  const {
    course: { courseId },
  } = useAppSelector((state) => state.virtualClassroomStudentShared);

  const { currentRecordStudent, currentStudent } = useAppSelector((state) => state.access);
  const { userInfo } = useAppSelector((state) => state.auth);

  const [showModal, setShowModal] = useState<{
    open: boolean;
    id: string | null;
    name: string | null;
  }>({
    open: false,
    id: null,
    name: null,
  });

  const { data: distribution, isLoading: isLoadingDistributions } =
    useGetDistributionCalificationQuery(
      {
        courseId,
      },
      { refetchOnMountOrArgChange: 30, skip: !courseId }
    );

  const distributionFiltered = useMemo(() => {
    if (distribution?.length) return [...distribution.filter((dt) => dt !== null)];
    else return [];
  }, [distribution]);

  const { data: grades, isLoading: isLoadingGrades } = useGetCourseStudentCalificationsQuery(
    {
      courseId: courseId || 0,
      studentRecordId: currentRecordStudent?.id!,
    },
    { refetchOnMountOrArgChange: 30, skip: !courseId }
  );

  const { columns, distributions } = useColumns(distributionFiltered);

  if (isEmptyObject(distributions) && !isLoadingGrades && !isLoadingDistributions) {
    return <NoGradesIcon />;
  }

  const listGrades = getStudentVirtualClassroomGrades(
    userInfo.fullname,
    [currentStudent! as any],
    grades as StudentCalifications[]
  );

  console.log(listGrades);

  const rows = getRows(columns, listGrades);

  const r = getCol(rows, columns);
  console.log(r);

  return (
    <>
      <SubCard>
        {!isLoadingDistributions && !isLoadingGrades ? (
          <TableContainer>
            <Table>
              <HeaderTable distribution={distributionFiltered} />
              <TableBody>
                <CustomRow
                  columns={columns}
                  row={r}
                  distributions={distributions}
                  setShowModal={setShowModal}
                />
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Grid container item justifyContent="center" alignItems="center">
            <CircularProgress />
          </Grid>
        )}
      </SubCard>

      {showModal.open && (
        <ModalNotas
          showModal={showModal}
          handleClose={() => {
            setShowModal({
              open: false,
              id: null,
              name: null,
            });
          }}
        />
      )}
    </>
  );
};

export default NotesTable;
