import { Grid, Typography } from '@material-ui/core';

import MainCard from '../../../shared/components/cards/MainCard';
import BusRoutes from '../components/BusRoutes';

import { useTranslation } from 'react-i18next';

const BusRouteScreen = () => {
  const { t } = useTranslation();
  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">{t('Ruta de buses')}</Typography>
      </Grid>

      <Grid item xs={12}>
        <BusRoutes />
      </Grid>
    </Grid>
  );
};

export default BusRouteScreen;
