import { Grid } from '@material-ui/core';
import { useAppSelector } from '../../../../../hooks/useRedux';
import MainCard from '../../../../shared/components/cards/MainCard';
import TaskChooseFilesModal from '../components/create/TaskChooseFilesModal';
import TaskCreate from '../components/create/TaskCreate';

const TaskCreateScreen = () => {
  const { taskChooseFiles } = useAppSelector((state) => state.virtualClassroomTeacher);

  return (
    <>
      <Grid
        component={MainCard}
        sx={{
          borderTopLeftRadius: '0px',
          borderTopRightRadius: '0px',
        }}
      >
        <TaskCreate />
        {taskChooseFiles && <TaskChooseFilesModal open={taskChooseFiles} />}
      </Grid>
    </>
  );
};

export default TaskCreateScreen;
