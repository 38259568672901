import { FC, useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import {
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Checkbox,
  Tooltip,
  IconButton,
  CircularProgress,
  useTheme,
  Button,
  Typography,
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { IconChevronsRight, IconNotes } from '@tabler/icons';
import { useHistory } from 'react-router-dom';

import {
  useGetPreconfigSubjectsQuery,
  useUpdateExtHomAppMutation,
} from '../../slices/homologationsApiSlice';
import { useAppSelector } from '../../../../../hooks/useRedux';
import { PreconfigSubjects, ValueElement } from '../../interfaces/homologations.interface';
import { Score, SubjectsHomologate } from '../../interfaces/payload.interface';
import { renderErrorOrEmptyRow, renderSkeletonRows } from '../../../../shared/helpers/render';
import {
  setPreconfigSubjectToEdit,
  setExternalPreconfigSubjectScore,
} from '../../slices/homologationsSlice';
import ErrorAlert from '../../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../../shared/components/alerts/SuccessAlert';
import { useTranslation } from 'react-i18next';

interface Props {
  curriculumId: number;
  instituteId: number;
  agreementId?: number;
  data: ValueElement[];

  /** Homologation Id */
  hId: number;

  //si el mensaje se quiere mostrar al inicio de la pagina y no en el encabezado de la tabla
  onShowAlert?: (state: boolean) => void;
  handleUpdateAppStatus?: (state: { status: 'error' | 'success'; message: string }) => void;

  openDialog: (mode: 'person' | 'institute' | 'subjectInstitute' | 'editSubjectPreconfig') => void;
}

const HomologationListEdit: FC<Props> = (props) => {
  const { t } = useTranslation();

  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const { currentProfile } = useAppSelector((state) => state.access);
  const { externalPreconfigSubjectScore } = useAppSelector((state) => state.homologations);

  const [selected, setSelected] = useState<number[]>([]);
  const [showAlerts, setShowAlerts] = useState(false);

  const [dipatchScore, setDispatchScore] = useState(false);

  const { curriculumId, instituteId, hId, openDialog, data: homologatedData, agreementId } = props;
  //
  // useEffect(() => {
  //   if (Boolean(externalPreconfigSubjectScore)) {
  //     window.addEventListener('beforeunload', alertUser);
  //     return () => {
  //       window.removeEventListener('beforeunload', alertUser);
  //     };
  //   }
  // }, [externalPreconfigSubjectScore]);
  // const alertUser = (e: any) => {
  //   e.preventDefault();
  //   return '';
  // };

  //Materias preconfiguradas
  const {
    data: preconfig,
    // isLoading: isLoadingPC,
    isFetching: isFetchingPC,
    error: errorPreconfig,
  } = useGetPreconfigSubjectsQuery({
    profileId: currentProfile?.profileId!,
    curriculumId,
    instituteId,
  });

  const memoPreconfig = useMemo(() => {
    if (Boolean(preconfig)) {
      return preconfig;
    }
  }, [preconfig]);

  const memoScore = useMemo(() => {
    if (Boolean(externalPreconfigSubjectScore)) {
      return externalPreconfigSubjectScore;
    }
  }, [externalPreconfigSubjectScore]);

  const newTable = useMemo(() => {
    if (Boolean(memoPreconfig)) {
      const selected: number[] = [];

      const filterPreconfig = memoPreconfig!.filter((pref) => {
        let filterDataHomologated = homologatedData.find(
          (dhom) =>
            Number(pref.inst_subject_id) === dhom.inst_subject_id &&
            Number(pref.subject_id) === dhom.subject_id
        );

        if (Boolean(filterDataHomologated)) {
          selected.push(pref.id);

          return true;
        } else {
          return false;
        }
      });

      //mover el dispatch de las notas a un useeffect distinto
      // if (!Boolean(dipatchScore)) dispatch(setExternalPreconfigSubjectScore(scoreMapped));
      // setDispatchScore(true);
      setSelected(selected);
      return filterPreconfig;
    }
  }, [memoPreconfig, homologatedData]);

  useEffect(() => {
    if (Boolean(memoPreconfig)) {
      const scoreMapped: Score = memoScore ? { ...memoScore } : {};

      memoPreconfig!.forEach((pref) => {
        const preconfigId = pref.id as unknown;
        let filterDataHomologated = homologatedData.find(
          (dhom) =>
            Number(pref.inst_subject_id) === dhom.inst_subject_id &&
            Number(pref.subject_id) === dhom.subject_id
        );

        if (Boolean(filterDataHomologated)) {
          scoreMapped[preconfigId as keyof Score] = {
            id: preconfigId as number,
            score: filterDataHomologated!.final_note ? filterDataHomologated!.final_note! : 0,
            comment: filterDataHomologated!.observation,
          };
        }
      });
      if (!Boolean(dipatchScore)) dispatch(setExternalPreconfigSubjectScore(scoreMapped));
      setDispatchScore(true);
    }
  }, [homologatedData, memoScore, memoPreconfig, dipatchScore, dispatch]);

  const handleEditClick = (data: { id: number; instSubject: string; subject: string }) => {
    dispatch(setPreconfigSubjectToEdit(data));
    openDialog('editSubjectPreconfig');
  };

  const [updateExtHomApp, { isLoading, isError, error, isSuccess }] = useUpdateExtHomAppMutation();

  const handleUpdate = () => {
    const subjectsToHomolgate: SubjectsHomologate[] = [];

    const selectedMapped = selected.reduce((key, value) => ({ ...key, [value]: value }), {});

    newTable!.forEach((dt) => {
      if (dt.id in selectedMapped) {
        if (!Boolean(externalPreconfigSubjectScore)) {
          subjectsToHomolgate.push({
            inst_subject_id: Number(dt.inst_subject_id),
            subject_id: Number(dt.subject_id),
            final_note: 0,
            observation: '',
          });
        } else {
          const preId = dt.id as unknown;
          const score = externalPreconfigSubjectScore![preId as keyof Score]
            ? externalPreconfigSubjectScore![preId as keyof Score]!.score
            : 0;
          const comment = externalPreconfigSubjectScore![preId as keyof Score]
            ? externalPreconfigSubjectScore![preId as keyof Score]!.comment
            : '';
          //
          subjectsToHomolgate.push({
            inst_subject_id: Number(dt.inst_subject_id),
            subject_id: Number(dt.subject_id),
            final_note: score,
            observation: comment,
          });
        }
      }
    });

    updateExtHomApp({
      profileId: currentProfile?.profileId!,
      extHomId: hId,
      // curriculumId: curriculumId,
      subjectHomologationPayload: {
        curriculum_id: curriculumId,
        institute_id: instituteId,
        convenio_id: agreementId,
        subjects_homologate: subjectsToHomolgate,
      },
    });
  };

  useEffect(() => {
    if (isError || isSuccess) {
      setShowAlerts(true);
    }
  }, [isError, isSuccess]);

  const renderRow = (preconfigSubjects: PreconfigSubjects) => {
    const preconfigId = preconfigSubjects.id as unknown;
    return (
      <TableRow hover className="table-row" key={preconfigSubjects.id.toString()}>
        <TableCell>
          <Checkbox
            disabled
            checked={selected.some((r) => r === Number(preconfigSubjects.id))}
            // {...(!available && { sx: { color: `${theme.palette.error.main}!important` } })}
          />
        </TableCell>
        <TableCell
        // width="60%"
        // {...(!available && { sx: { color: `${theme.palette.error.main}` } })}
        >
          {preconfigSubjects.subject.mat_name}
        </TableCell>
        <TableCell
          // width="30%"
          align="center"
          // {...(!available && { sx: { color: `${theme.palette.error.main}` } })}
        >
          {preconfigSubjects.status_id}
        </TableCell>

        <TableCell>
          <IconChevronsRight color={theme.palette.primary.main} />
        </TableCell>
        <TableCell>{preconfigSubjects.institution_subject.name}</TableCell>
        <TableCell align="center">
          {externalPreconfigSubjectScore
            ? externalPreconfigSubjectScore[preconfigId as keyof Score]
              ? externalPreconfigSubjectScore[preconfigId as keyof Score]!.score
              : '0'
            : '0'}
        </TableCell>
        <TableCell>
          <Tooltip title={t('academic.homologation.external.list_edit.edit_grade')}>
            <IconButton
              aria-label={t('academic.homologation.external.list_edit.edit_grade')}
              color="primary"
              onClick={() => {
                handleEditClick({
                  id: preconfigSubjects.id,
                  instSubject: preconfigSubjects.institution_subject.name,
                  subject: preconfigSubjects.subject.mat_name,
                });
              }}
            >
              <IconNotes size={20} />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
    );
  };

  const renderRows = () => {
    return <>{newTable?.map(renderRow)}</>;
  };

  return (
    <>
      <Grid item mb={2}>
        <Typography variant="h4" color={theme.palette.primary.main}>
          {t('academic.homologation.external.list_edit.subjects_homologated')}
        </Typography>
      </Grid>

      <Grid item>
        {isError && showAlerts && (
          <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
        )}

        {isSuccess && showAlerts && (
          <SuccessAlert
            message={t('academic.homologation.external.list_edit.success')}
            handleDismiss={() => setShowAlerts(false)}
          />
        )}
      </Grid>

      <Grid item xs={12} sx={{ maxHeight: 600, overflow: 'hidden' }}>
        <PerfectScrollbar component="div">
          <TableContainer
            sx={{
              border: '1px solid #eee',
              borderRadius: '12px!important',
              maxHeight: '500px',
            }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>
                    {t('academic.homologation.external.list_edit.current_subject')}
                  </TableCell>
                  <TableCell>
                    {t('academic.homologation.external.list_edit.current_status')}
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell>
                    {t('academic.homologation.external.list_edit.destinate_subject')}
                  </TableCell>
                  <TableCell>
                    {t('academic.homologation.external.list_edit.calification')}
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isFetchingPC || !Boolean(newTable) ? renderSkeletonRows(5, 7) : renderRows()}
                {errorPreconfig && renderErrorOrEmptyRow(5, errorPreconfig as string)}
                {preconfig?.length === 0 && renderErrorOrEmptyRow(5)}
              </TableBody>
            </Table>
          </TableContainer>
        </PerfectScrollbar>
      </Grid>

      <Grid container justifyContent={'flex-end'} mt={2} spacing={2}>
        <Grid item>
          <Button variant="outlined" size="large" color="primary" onClick={() => history.goBack()}>
            {t('academic.homologation.external.list_edit.cancel')}
          </Button>
        </Grid>
        <Grid item>
          {/* <Button
            variant="contained"
            size="large"
            style={{ background: theme.palette.error.main, borderColor: '#EDE7F6' }}
            onClick={() => {
              // touched.comment = true;
              // validateField('comment').then(() => {
              //   if (touched.comment && errors.comment) return;
              //   handleDelete({ code: currentApplication!.app_code, msg: values.comment });
              // });
            }}
          >
            Rechazar
          </Button> */}
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            size="large"
            color="primary"
            disabled={isLoading || isFetchingPC}
            endIcon={isLoading && <CircularProgress size={20} />}
            onClick={handleUpdate}
          >
            {t('academic.homologation.external.list_edit.save')}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default HomologationListEdit;

// const HomologationList = () => {
//   return <></>;
// };

// export default HomologationList;
