import * as yup from 'yup';

export const evaluationTypeFormSchema = yup.object().shape({
  name: yup.string().required('maintenance.evaluation_type.form.name.required'),
  description: yup.string(),
  status: yup.string().required('maintenance.evaluation_type.form.name.status'),
  acronym: yup
    .string()
    .required('maintenance.evaluation_type.form.acronym.required')
    .min(3, 'maintenance.evaluation_type.form.acronym.min')
    .max(5, 'maintenance.evaluation_type.form.acronym.max')
    .matches(/^[A-Z]+$/, 'maintenance.evaluation_type.form.acronym.uppercase'),

  eva_group_type_id: yup.string().required('Tipo de grupo requerido'),
});
