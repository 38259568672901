import { Switch } from 'react-router-dom';
import GuardRoute from '../../../../router/guards/GuardRoute';
import BlackBoardFilesScreen from '../screens/BlackBoardFilesScreen';
import { subPermissionsKeys } from '../constants/permissions';
// import { useAppSelector } from "../../../../hooks/useRedux";

const BlackboardRouter = () => {
  //   const { currentModule } = useAppSelector((state) => state.access);

  //   const hasPermission = permissionKey in currentModule?.permissions!;

  //   if (!hasPermission) return <Redirect to="/" />;

  return (
    <Switch>
      <GuardRoute
        exact
        path="/blackboard/archivos"
        component={BlackBoardFilesScreen}
        permissionKey={subPermissionsKeys.groupKey}
        subPermissionKey={subPermissionsKeys.blackboardMigrationList}
      />
    </Switch>
  );
};

export default BlackboardRouter;
