import { Grid, Typography } from '@material-ui/core';
import NoData from '../../../../shared/components/NoData';
import SkeletonCard from '../../../../students/academic-portfolio/components/skeleton/SkeletonCard';
import { CourseStudents } from '../../../courses/interfaces/courseStudents.interfaces';
import CardStudent from './CardStudent';
import noDataImage from '../../../../../assets/images/no-classmates.png';
interface Props {
  isLoading: boolean;
  data?: CourseStudents[];
}

const CourseStudentsGrid = ({ isLoading, data }: Props) => {
  return (
    <Grid container mt={2} spacing={3}>
      {isLoading ? (
        [...new Array(6)].map((_, index) => <SkeletonCard key={index.toString()} />)
      ) : data?.length ? (
        <>
          <Grid item xs={12}>
            <Typography textAlign="right" color="black">
              Total de alumnos: <b>{data.length}</b>
            </Typography>
          </Grid>
          {data?.map((cs) => (
            <CardStudent student={cs} key={cs.id} />
          ))}
        </>
      ) : (
        <>
          <Grid item xs={12} textAlign="center">
            <NoData
              image={noDataImage}
              title="No hay alumnos registrados"
              subtitle="Aún no hay alumnos registrados en este curso"
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default CourseStudentsGrid;
