// import { useState } from 'react';
import { Grid, Button } from '@material-ui/core';
import { IconArrowNarrowLeft, IconBooks, IconUsers, IconUserPlus } from '@tabler/icons';
import { useHistory, useParams } from 'react-router-dom';
import { useTheme, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import MainCard from '../../../../../shared/components/cards/MainCard';
import AddInvestigationLineForm from './InvestigationLineForm';
import AddInvestigationGroupForm from './AddInvestigationGroupForm';
import AddCollaboratorGroupResearchForm from './CollaboratorGroupResearchForm';

const invLine = {
  label: 'lines_research.AddInvestigationLinesStepper.invLine.label',
  Icon: IconBooks,
  url: 'linea-de-investigacion',
};

const invGroup = {
  label: 'lines_research.AddInvestigationLinesStepper.invGroup.label',
  Icon: IconUsers,
  url: 'grupo-de-investigacion',
};

const teacherAssign = {
  label: 'lines_research.AddInvestigationLinesStepper.invTeacherAssign.label',
  Icon: IconUserPlus,
  url: 'asignar-Docentes',
};

const steps = [invLine, invGroup, teacherAssign];

const AddInvestigationLinesStepperScreen = () => {
  const history = useHistory();

  const { t } = useTranslation();

  // const { path } = useRouteMatch();

  const { caseKey } = useParams<{ caseKey: string }>();

  const theme = useTheme();

  // const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

  // const activeStep = steps.findIndex((step) => step.url === caseKey) || 0;

  // const [completed] = useState<{
  //   [k: number]: boolean;
  // }>({});

  // const handleStep = (step: number) => () => {
  //   // history.push(path.replace(':caseKey', steps[step].url || invLine.url));
  //   // setActiveStep(step);
  //   history.replace(path.replace(':caseKey', steps[step].url || invLine.url));
  // };

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Button variant="text" onClick={() => history.goBack()} startIcon={<IconArrowNarrowLeft />}>
          {t('lines_research.AddInvestigationLinesStepper.title')}
        </Button>
      </Grid>
      {/* <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={10}>
          <Stepper
            nonLinear
            activeStep={activeStep}
            orientation={matchUpMd ? 'horizontal' : 'vertical'}
          >
            {steps.map(({ label, Icon }, index) => (
              <Step key={label} completed={completed[index]}>
                <StepButton
                  color="inherit"
                  onClick={handleStep(index)}
                  icon={
                    <Icon
                      style={
                        index === activeStep
                          ? {
                              backgroundColor: theme.palette.primary.light,
                              borderRadius: '100%',
                              padding: '5px',
                              color: theme.palette.primary.main,
                            }
                          : {}
                      }
                    />
                  }
                >
                  {t(label)}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </Grid>
      </Grid> */}
      <Grid container justifyContent="center" alignItems="center" mt={4}>
        <Grid item container m={2} justifyContent="center" alignItems="center">
          {steps.map(({ url, Icon }) => {
            if (url === caseKey) return <Icon size={40} color={theme.palette.primary.main} />;
            else return '';
          })}
        </Grid>

        <Grid item container justifyContent="center" alignItems="center">
          <Typography fontWeight={2} variant="h3" color="initial">
            {steps.map(({ url, label }) => {
              if (url === caseKey) return t(label);
              else return '';
            })}
          </Typography>
        </Grid>

        <Grid item xs={8} mt={4}>
          {invLine.url === caseKey && <AddInvestigationLineForm />}
          {invGroup.url === caseKey && <AddInvestigationGroupForm />}
          {teacherAssign.url === caseKey && <AddCollaboratorGroupResearchForm />}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AddInvestigationLinesStepperScreen;
