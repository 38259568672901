import { useEffect, useState } from 'react';
import { useHistory, Link, useRouteMatch } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { useGetCampusQuery } from '../slices/campusApiSlice';

import StatusChip from '../../../shared/components/badges/StatusChip';
import EditButton from '../../../shared/components/tables/buttons/EditButton';
import DeleteButton from '../../../shared/components/tables/buttons/DeleteButton';
import AddButton from '../../../shared/components/tables/buttons/AddButton';

import { openDeleteModalWith, setCampusList } from '../slices/campusSlice';
import useSharedGuard from '../../../../hooks/useSharedGuard';
import { subPermissionKeys } from '../constants/permissions';
import SearchPaginatedTable, {
  FilterArrow,
} from '../../../shared/components/tables/SearchPaginatedTable';

const CampusTable = () => {
  const { currentProfile } = useAppSelector((state) => state.access);

  const { hasAccess } = useSharedGuard(subPermissionKeys);

  const dispatch = useAppDispatch();

  const { path } = useRouteMatch();
  const history = useHistory();

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const [search, setSearch] = useState('');

  const [filterArrow, setFilterArrow] = useState<FilterArrow>({
    name: 'id',
    direction: 'desc',
  });

  useEffect(() => {
    setPage(1);
  }, [perPage, search]);

  const { isLoading, error, data, isFetching } = useGetCampusQuery(
    {
      profileId: currentProfile?.profileId!,
      page,
      perPage,
      search,
      ...(filterArrow && {
        type_sort: filterArrow?.direction,
        sort: filterArrow?.name,
      }),
    },
    { refetchOnMountOrArgChange: 30 }
  );

  useEffect(() => {
    if (data) {
      dispatch(setCampusList(data.data));
    }
  }, [data, dispatch]);

  const handleDelete = async (campusId: number) => {
    dispatch(openDeleteModalWith(campusId));
  };

  const goToAdd = () => {
    history.push(`${path}/agregar`);
  };

  const datafetch = data?.data!.map((d, index) => ({
    id: index + ((page - 1) * perPage + 1),
    name: d.cam_name,
    description: d.cam_description,
    direction: d.cam_direction,
    initial: d.cam_initials,
    statusId: d.status_id,
    options: d,
  }));

  return (
    <SearchPaginatedTable
      data={datafetch!}
      headers={{
        id: 'N°',
        name: 'Nombre',
        description: 'Descripción',
        direction: 'Dirección',
        initial: 'Iniciales',
        statusId: 'Estado',
        options: 'Opciones',
      }}
      keyExtractor={(item) => item.id.toString()}
      listFilterArrows={{
        name: 'cam_name',
        description: 'cam_description',
        direction: 'cam_direction',
        initial: 'cam_initials',
        statusId: 'status_id',
      }}
      filterArrow={filterArrow}
      setFilterArrow={setFilterArrow}
      searchPlacehoder="Buscar campus"
      isLoading={isLoading}
      isFetching={isFetching}
      error={error}
      perPage={perPage}
      setPerPage={setPerPage}
      total={data?.total || 0}
      page={page}
      setPage={setPage}
      numHeader={7}
      search={search}
      setSearch={setSearch}
      customRenderers={{
        statusId: (row) => <StatusChip statusId={Number(row.statusId)} />,
        options: (row) => (
          <>
            {hasAccess('edit') && (
              <Link to={`${path}/${row.options.id}/editar`}>
                <EditButton />
              </Link>
            )}
            {hasAccess('delete') && (
              <DeleteButton handleDelete={() => handleDelete(row.options.id)} />
            )}
            {!hasAccess('edit') && !hasAccess('delete') && 'Ninguna'}
          </>
        ),
      }}
      customDataCellsProperties={{
        id: {
          align: 'center',
        },
        name: {
          align: 'center',
        },
        description: {
          align: 'center',
        },
        direction: {
          align: 'center',
        },
        initial: {
          align: 'center',
        },
        statusId: {
          align: 'center',
        },
        options: {
          align: 'center',
        },
      }}
      ActionButtons={<>{hasAccess('create') && <AddButton size="large" onClick={goToAdd} />}</>}
    />
  );
};

export default CampusTable;
