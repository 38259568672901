import { mainApi } from '../../../../api/mainApi';
import { AccessState } from '../../../../types';
import { middlewareApi } from '../../../middleware/middlewareApi';
import { ClientsResponse } from '../interfaces/clients.interface';
import { ClientsDiscountPayload } from '../interfaces/clientsDiscountPayload.interface';

export const clientsTags = middlewareApi.enhanceEndpoints({
  addTagTypes: ['ClientsDiscount'],
});

export const clientsDiscountApi = clientsTags.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * @GET all clients
     */
    getClients: builder.query<
      ClientsResponse,
      {
        profileId: number;
        page: number;
        size: number;
        sort: string;
        type_sort: string;
        search: string;
      }
    >({
      queryFn: async ({ profileId, page, size, sort, type_sort, search }) => {
        try {
          const { data } = await mainApi.get<ClientsResponse>('/persons', {
            params: {
              user_profile_id: profileId,
              page,
              size,
              sort,
              type_sort,
              search,
            },
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: [
        {
          type: 'ClientsDiscount',
          id: 'LIST',
        },
      ],
    }),

    /**
     * @POST asigna descuento a estudiantes
     */
    assignDiscountList: builder.mutation<ClientsDiscountPayload, ClientsDiscountPayload>({
      queryFn: async (PayloadDiscount, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = currentProfile?.profileId;

          const { data } = await mainApi.post<ClientsDiscountPayload>(
            '/group-configuration-students/massive',
            {
              user_profile_id,
              ...PayloadDiscount,
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'ClientsDiscount', id: 'LIST' }],
    }),

    //
  }),
});

export const { useGetClientsQuery, useAssignDiscountListMutation } = clientsDiscountApi;
