import { useAppSelector } from '../../../../hooks/useRedux';
import MattersTypeTable from '../components/MattersTypeTable';
import DeleteMatterTypeDialog from '../components/DeleteMatterTypeDialog';
import MainCard from '../../../shared/components/cards/MainCard';
import { Grid, Typography } from '@material-ui/core';

const MattersTypesScreen = () => {
  const { matterTypeIdToDelete } = useAppSelector((state) => state.mattersTypes);

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">Tipos de Materia</Typography>
      </Grid>

      <MattersTypeTable />

      {matterTypeIdToDelete && <DeleteMatterTypeDialog />}
    </Grid>
  );
};

export default MattersTypesScreen;
