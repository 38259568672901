export const subPermissionKeys = {
  groupKey: 'offers',

  readCalendar: 'planning-calendar-listar-calendarios',
  postgraduateTemporaryPlanningSave: 'postgraduate-temporary-planning-save',
  destroyCompletely: 'postgraduate-temporary-planning-destroy',
  readHolidays: 'holidays-listar-feriados',
  updateHolidays: 'holidays-actualizar-feriado',
  destroyHolidays: 'holidays-destroy-feriado',

  readPosgraduatePlanningListTemp: 'postgraduate-planning-temp-list',
  showCampus: 'campus-listar-sedes',

  renamePeriod: 'postgraduate-planning-additional-information-rename-period',
  getPlaningPostgraduate: 'postgraduates-obtener-planificacion',
  planingsProgramsChangeStatus: 'postgraduates-actualizar-planificacion-estado',
};
