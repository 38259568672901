import { Grid, Typography } from '@material-ui/core';
import MainCard from '../../../../shared/components/cards/MainCard';
import DatabaseByEducationLevel from '../../components/databases/DatabaseByEducationLevel';

const DatabaseByEducationLevelScreen = () => {
  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">Base por facultad</Typography>
      </Grid>
      <DatabaseByEducationLevel />
    </Grid>
  );
};

export default DatabaseByEducationLevelScreen;
