import { FormHelperText, Grid, Typography } from '@material-ui/core';
import { useAppSelector } from '../../../../../hooks/useRedux';
import MainCard from '../../../../shared/components/cards/MainCard';
import { useUseGetActivitiesbyCourseTeacherQuery } from '../../slices/virtualClassroomTeacherApiSlice';
import CalendarComponent from '../components/CalendarComponent';
import HeroData from '../components/HeroData';
import TaskRecentIndividual from '../components/TaskRecentIndividual';
import TimeLineActivities from '../components/TimeLineActivities';
//import { IconPlus } from '@tabler/icons';
//import { useHistory, useRouteMatch } from 'react-router-dom';
//import DeleteMaterialsDialog from '../../materials/DeleteMaterialsDialog';
//import { useAppSelector } from '../../../../../hooks/useRedux';
import imgNoContent from '../../../../../assets/images_optimized/av-student-no-content_optimized.png';
import { useTranslation } from 'react-i18next';
import LoadingData from '../../../../shared/components/cards/Skeleton/LoadingData';

const DashboardScreen = () => {
  /*const { path } = useRouteMatch();
  const history = useHistory();
 const goToAddContent = () => {
    history.push(`${path}/crear`);
  };*/
  /* const { materialIdToDelete } = useAppSelector((state) => state.virtualClassroomTeacher);*/
  const { t } = useTranslation();

  const {
    userInfo: { collaboratorInfo },
  } = useAppSelector((state) => state.auth);

  const {
    course: { courseId },
  } = useAppSelector((state) => state.virtualClassroomTeacherShared);
  const { data, isLoading, error, isFetching } = useUseGetActivitiesbyCourseTeacherQuery(
    {
      courseId: courseId!,
      collaboratorID: collaboratorInfo?.id!,
      page: 1,
      size: 100,
    },
    { skip: courseId === 0 || courseId === undefined }
  );

  if (isFetching === false) {
    if (data !== undefined && data?.data?.length < 1) {
      return <HeroData img={imgNoContent} />;
    }
  }
  if (isLoading) {
    return <LoadingData />;
  }
  return (
    <>
      <Grid style={{ marginTop: 0, borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
        <Grid container>
          <Grid
            component={MainCard}
            item
            sx={{ boxShadow: 2, borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
            style={{ backgroundColor: '' }}
            xs={7}
          >
            <Grid item alignItems="center" sx={{ mb: 3 }}>
              <Typography variant="h2">{t('teacherVirtualClassroom.dashboard.title')}</Typography>
            </Grid>
            <Grid container item justifyContent="center" md={12}>
              <CalendarComponent />
            </Grid>
            <Grid item alignItems="center" sx={{ mb: 3 }}>
              <Typography variant="h2">
                {t('teacherVirtualClassroom.dashboard.task_recent')}
              </Typography>
            </Grid>
            <TaskRecentIndividual />
          </Grid>
          <Grid
            height={'100vh'}
            overflow="scroll"
            sx={{ bgcolor: 'lightgray[200]-', p: '24px' }}
            item
            xs={5}
          >
            <Grid item alignItems="center" sx={{ mb: 3 }}>
              <Typography variant="h2">
                {' '}
                {t('teacherVirtualClassroom.dashboard.history_activities')}
              </Typography>
            </Grid>
            <TimeLineActivities />
          </Grid>

          {/*materialIdToDelete && <DeleteMaterialsDialog />*/}
          {error && <FormHelperText>{error}</FormHelperText>}
        </Grid>
      </Grid>
    </>
  );
};
export default DashboardScreen;
