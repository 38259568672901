import DeleteAlert from '../../../shared/components/dialogs/DeleteAlert';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
//import { useDeleteCampusMutation } from '../slices/campusApiSlice';
import { resetBookToDelete, setDeleteModalOpen } from '../slices/booksSlice';
import { useDeleteBookMutation } from '../slices/booskApiSlice';

const DeleteBookDialog = () => {
  const dispatch = useAppDispatch();

  const { deleteModalOpen, bookToDelete } = useAppSelector((state) => state.books);
  const [deleteBook, { isLoading, isSuccess, isError, error }] = useDeleteBookMutation();

  const handleDelete = () => {
    deleteBook({
      id: bookToDelete!,
    });
  };

  const handleClose = () => {
    dispatch(setDeleteModalOpen(false));
    dispatch(resetBookToDelete());
  };

  return (
    <DeleteAlert
      open={deleteModalOpen}
      onClose={handleClose}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      handleDelete={handleDelete}
      error={String(error)}
      deleteTitle="¿Estás seguro de eliminar el libro?"
    />
  );
};

export default DeleteBookDialog;
