import { Switch, Redirect } from 'react-router-dom';

import { useAppSelector } from '../../../../hooks/useRedux';

import GuardRoute from '../../../../router/guards/GuardRoute';

import { permissionKey } from '../constants/permissions';
// import EventNewScreen from '../screens/EventNewScreen';

import ExternalLinksScreen from '../screens/ExternalLinksScreen';
import AddExternalLink from '../screens/AddExternalLink';
import EditExternalLink from '../screens/EditExternalLink';

const ExternalLinkConfigRouter = () => {
  const { currentModule } = useAppSelector((state) => state.access);

  const hasPermission = permissionKey in currentModule?.permissions!;

  if (!hasPermission && false) return <Redirect to="/dashboard" />;

  return (
    <Switch>
      {/* Stages */}
      <GuardRoute
        exact
        path="/administrar-enlaces-externos"
        component={ExternalLinksScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.read}
      />

      <GuardRoute
        exact
        path="/administrar-enlaces-externos/crear"
        component={AddExternalLink}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.read}
      />

      <GuardRoute
        exact
        path="/administrar-enlaces-externos/:externalLinkId/editar"
        component={EditExternalLink}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.read}
      />
    </Switch>
  );
};

export default ExternalLinkConfigRouter;
