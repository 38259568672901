import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISearchTable } from '../../../shared/interfaces/paginatedTable.interface';

export interface UserReservedBooksState {
  reservedBooksTable: ISearchTable;

  deleteReserveBookDialogOpen: boolean;
  reserveBookIdSelected: string | null;
}

const initialState: UserReservedBooksState = {
  reservedBooksTable: {
    search: '',
    perPage: 5,
    page: 1,
    filterArrow: {
      name: 'id',
      direction: 'desc',
    },
  },

  deleteReserveBookDialogOpen: false,
  reserveBookIdSelected: null,
};

export const userReservedBooksSlice = createSlice({
  initialState,
  name: 'userReservedBooks',
  reducers: {
    updateReservedBooksTable: (state, action: PayloadAction<ISearchTable>) => {
      state.reservedBooksTable = { ...state.reservedBooksTable, ...action.payload };
    },

    openDeleteReserveBookDialog: (state, action: PayloadAction<string>) => {
      state.deleteReserveBookDialogOpen = true;
      state.reserveBookIdSelected = action.payload;
    },

    closeDeleteReserveBookDialog: (state) => {
      state.deleteReserveBookDialogOpen = false;
      state.reserveBookIdSelected = null;
    },
  },
});

export const {
  updateReservedBooksTable,
  openDeleteReserveBookDialog,
  closeDeleteReserveBookDialog,
} = userReservedBooksSlice.actions;

export default userReservedBooksSlice.reducer;
