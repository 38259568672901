import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISearchTable } from '../../../shared/interfaces/paginatedTable.interface';
import { ELS } from '../interfaces/ELS.interfaces';

interface EducationLevelSubjectsState {
  educationLevelSubjectList: ELS[];
  educationLevelSubjectGroupList: ELS[];
  deleteModalOpen: boolean;
  educationLevelSubjectIdToDelete?: number;
  educationLevelSubjectTable: ISearchTable;
  subjectsGroupNivelationTable: ISearchTable;
}

const initialState: EducationLevelSubjectsState = {
  educationLevelSubjectList: [],
  educationLevelSubjectGroupList: [],
  deleteModalOpen: false,
  educationLevelSubjectTable: {
    page: 1,
    perPage: 5,
    search: '',
  },
  subjectsGroupNivelationTable: {
    page: 1,
    perPage: 5,
    search: '',
  },
};

const educationLevelSubjectsSlice = createSlice({
  name: 'educationLevelSubjects',
  initialState,
  reducers: {
    setEducationLevelSubjectList: (state, action: PayloadAction<ELS[]>) => {
      state.educationLevelSubjectList = action.payload;
    },
    setEducationLevelSubjectGroupList: (state, action: PayloadAction<ELS[]>) => {
      state.educationLevelSubjectGroupList = action.payload.concat(
        state.educationLevelSubjectGroupList
      );
    },
    setDeleteModalOpen: (state, action: PayloadAction<boolean>) => {
      state.deleteModalOpen = action.payload;
    },
    openDeleteModalWith: (state, action: PayloadAction<number>) => {
      state.educationLevelSubjectIdToDelete = action.payload;
      state.deleteModalOpen = true;
    },
    resetEducationLevelSubjectIdToDelete: (state) => {
      state.educationLevelSubjectIdToDelete = undefined;
    },
    updateEducationLevelSubjectTableStates: (state, action: PayloadAction<ISearchTable>) => {
      state.educationLevelSubjectTable = action.payload;
    },
    updateSubjectsGroupNivelationTableStates: (state, action: PayloadAction<ISearchTable>) => {
      state.subjectsGroupNivelationTable = action.payload;
    },
  },
});

export const {
  setEducationLevelSubjectList,
  setEducationLevelSubjectGroupList,
  setDeleteModalOpen,
  openDeleteModalWith,
  resetEducationLevelSubjectIdToDelete,
  updateEducationLevelSubjectTableStates,
  updateSubjectsGroupNivelationTableStates,
} = educationLevelSubjectsSlice.actions;

export default educationLevelSubjectsSlice.reducer;
