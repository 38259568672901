import DeleteAlert from '../../../../shared/components/dialogs/DeleteAlert';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import { useDeleteResearchGroupMutation } from '../../slices/InvestigationLinesApiSlice';
import {
  setDeleteModalOpenResearchGroup,
  resetGroupResearchIdToDelete,
} from '../../slices/investigationLinesSlice';

const DeleteGroupResearchDialog = () => {
  const dispatch = useAppDispatch();

  const { deleteModalOpenGroupResearch, groupResearchToDelete } = useAppSelector(
    (state) => state.investigationLines
  );

  const [deleteGroupResearch, { isLoading, isSuccess, isError, error }] =
    useDeleteResearchGroupMutation();

  const handleDelete = () => {
    deleteGroupResearch({
      groupResearchId: groupResearchToDelete!,
    });
  };

  const handleClose = () => {
    dispatch(setDeleteModalOpenResearchGroup(false));
    dispatch(resetGroupResearchIdToDelete());
  };

  return (
    <DeleteAlert
      open={deleteModalOpenGroupResearch}
      onClose={handleClose}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      handleDelete={handleDelete}
      error={error as string}
    />
  );
};

export default DeleteGroupResearchDialog;
