import { Box, Grid, Skeleton } from '@material-ui/core';
import Carousel from 'react-material-ui-carousel';

import { useGetExternalLinksQuery } from '../slices/dashboardApiSlice';
import ViewExternalLink from './builder/ViewExternalLink';
import { useMemo } from 'react';

const ExternalLinks = () => {
  // const { t } = useTranslation();

  const { data, isLoading } = useGetExternalLinksQuery({});

  const databasesGroups = useMemo(
    () =>
      data?.reduce<any[][]>((acc, curr) => {
        if (acc.length > 0) {
          if (acc[acc.length - 1].length < 2) {
            acc[acc.length - 1].push(curr);
          } else {
            acc.push([curr]);
          }
        } else {
          acc.push([curr]);
        }
        return acc;
      }, []),
    [data]
  );

  return (
    <Box
      sx={{
        // border: `1px solid ${theme.palette.primary[200]}`,
        padding: 2,
        height: '100%',
        borderRadius: '12px',
      }}
    >
      {/* <Typography component="h3" variant="h3">
        {t('students.dashboard.external_links.title')}
      </Typography> */}

      {isLoading ? (
        <Skeleton variant="rectangular" width="100%" height="200px" />
      ) : (
        <Grid container pt={2} pb={1}>
          <Grid item xs={12}>
            {!data ? (
              <></>
            ) : (
              <Carousel
                animation="slide"
                // navButtonsAlwaysInvisible={true}
                // autoPlay={false}
                // indicators={false}
              >
                {databasesGroups?.map((item, idx) => (
                  <Grid
                    container
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                    key={`carousel-external-${idx}`}
                  >
                    {item.map((_item, _idx) => (
                      <Grid item xs={6} key={`carouse-external-item-${_idx}`}>
                        <ViewExternalLink data={_item} />
                      </Grid>
                    ))}
                  </Grid>
                ))}
              </Carousel>
            )}
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default ExternalLinks;
