import {
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Slide,
  SlideProps,
  Tabs,
  Tab,
} from '@material-ui/core';
import { IconArrowNarrowLeft } from '@tabler/icons';
import { forwardRef, useState } from 'react';
import { useAppSelector } from '../../../../hooks/useRedux';
import ExtendedSideDialog from '../../../shared/components/dialogs/ExtendedSideDialog';
import { useGetPermissionModulesQuery } from '../slices/permissionsApiSlice';
import ModuleForm from './modules/ModuleForm';
import ModulesList from './modules/ModulesList';

interface Props {
  open: boolean;
  handleClose: () => void;
}

//styles
const useStyles = makeStyles((theme) => ({
  userAddDialog: {
    '&>div:nth-child(3)': {
      justifyContent: 'flex-end',
      '&>div': {
        margin: '0px',
        borderRadius: '0px',
        maxWidth: '500px',
        maxHeight: '100%',
        '&>div.MuiDialogContent-root>div.scrollbar-container': {
          paddingLeft: '20px',
          overflow: 'hidden',
        },
      },
    },
  },
  paper: {
    minHeight: '100vh',
    width: '37%',
  },
  paperTable: {
    minHeight: '100vh',
    width: '50%!important',
  },
  movilPaper: {
    minHeight: '100vh',
    width: '100%',
  },
}));

// animation
const Transition = forwardRef(function Transition(props: SlideProps, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const ModulesDialog = ({ open, handleClose }: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  // const dispatch = useDispatch();

  const isSm = useMediaQuery(theme.breakpoints.down('md'));

  const [tabSelected, setTabSelected] = useState('list');

  const { currentProfile } = useAppSelector((state) => state.access);
  const { permissionIdSelected, permissionsList } = useAppSelector((state) => state.permissions);

  const permission = permissionsList.find((permission) => permission.id === permissionIdSelected);

  const { data, isLoading } = useGetPermissionModulesQuery({
    profileId: currentProfile?.profileId!,
    permissionId: permissionIdSelected!,
  });

  return (
    <ExtendedSideDialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      className={classes.userAddDialog}
      {...(!isSm && { classes: { paper: classes.paper } })}
      {...(!isSm && { classes: { paper: classes.paperTable } })}
      {...(isSm && { classes: { paper: classes.movilPaper } })}
    >
      <DialogTitle disableTypography>
        <Grid item container alignItems="center" spacing={1}>
          <Grid item xs={2}>
            <IconButton onClick={handleClose}>
              <IconArrowNarrowLeft size={30} color={theme.palette.text.dark} />
            </IconButton>
          </Grid>
          <Grid item xs={10}>
            <Typography variant="h3">{permission?.alias} - Módulos</Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Tabs
          value={tabSelected}
          onChange={(_, newValue) => {
            setTabSelected(newValue);
          }}
        >
          <Tab value="list" label="Listado" />
          <Tab value="add" label="Agregar" />
        </Tabs>

        {tabSelected === 'list' && <ModulesList modules={data || []} isLoading={isLoading} />}
        {tabSelected === 'add' && <ModuleForm />}
      </DialogContent>
    </ExtendedSideDialog>
  );
};

export default ModulesDialog;
