import { mainApi } from '../../../../api/mainApi';
import { AccessState } from '../../../../types';
import { middlewareApi } from '../../../middleware/middlewareApi';
import { Company } from '../interfaces/companies.interfaces';
import {
  ResponseServicesCompany,
  ServicesCompanyData,
  ServicesCompanyPayload,
} from '../interfaces/servicesCompany.interfaces';
import { setCompany } from './companiesSlice';

export const companiesTags = middlewareApi.enhanceEndpoints({
  addTagTypes: ['Companies', 'ServicesCompanies'],
});
export const companiesApi = companiesTags.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * @GET all Companiess
     */
    // getCompanies: builder.query<
    //   CompaniesResponse,
    //   { profileId: number; page: number; perPage: number; search: string }
    // >({
    //   queryFn: async ({ profileId, page, search, perPage }, { dispatch }) => {
    //     try {
    //       const { data } = await mainApi.get<CompaniesResponse>('/companies', {
    //         params: {
    //           user_profile_id: profileId,
    //           page,
    //           size: perPage,
    //           type_sort: 'desc',
    //           search,
    //         },
    //       });

    //       dispatch(setCompaniesList(data.data));

    //       return { data };
    //     } catch (error: any) {
    //       return { error };
    //     }
    //   },
    //   providesTags: (result) =>
    //     result
    //       ? [
    //           ...result.data.map(({ id }) => ({ type: 'Companies', id } as const)),
    //           { type: 'Companies', id: 'LIST' },
    //         ]
    //       : [{ type: 'Companies', id: 'LIST' }],
    // }),

    /**
     * @GET Companies
     */
    getCompany: builder.query<Company, { profileId?: number }>({
      queryFn: async ({ profileId }, { dispatch, getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = profileId || currentProfile?.profileId;

          const { data } = await mainApi.get<Company>('/companies', {
            params: {
              user_profile_id,
            },
          });

          dispatch(setCompany(data));

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result, error, { profileId }) => [{ type: 'Companies', profileId }],
    }),

    /**
     * @PUT update Companies
     */
    updateCompany: builder.mutation<
      Company,
      { profileId: number; companyId: number; companyPayload: Partial<Company> }
    >({
      queryFn: async ({ profileId, companyId, companyPayload }, { dispatch }) => {
        try {
          const { data } = await mainApi.put<Company>(`/companies/${companyId}`, {
            user_profile_id: profileId,
            ...companyPayload,
          });
          dispatch(setCompany(data));
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'Companies', id: 'LIST' }],
    }),

    getServicesCompany: builder.query<
      ResponseServicesCompany,
      {
        profileId?: number;
        page: number;
        perPage: number;
        type_sort?: string;
        search?: string;
      }
    >({
      queryFn: async ({ profileId, page, search, perPage, type_sort }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = profileId || currentProfile?.profileId;

          const { data } = await mainApi.get<ResponseServicesCompany>(`/company-service`, {
            params: {
              user_profile_id,
              page,
              size: perPage,
              type_sort,
              search,
            },
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) => [{ type: 'ServicesCompanies', id: 'LIST' }],
    }),

    getServiceCompanyById: builder.query<
      ServicesCompanyData,
      { profileId?: number; serviceCompany: number | string }
    >({
      queryFn: async ({ profileId, serviceCompany }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = profileId || currentProfile?.profileId;

          const { data } = await mainApi.get<ServicesCompanyData>(
            `/company-service/${serviceCompany}`,
            {
              params: {
                user_profile_id,
              },
            }
          );
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    addServicesCompany: builder.mutation<
      any,
      { profileId?: number; payload: ServicesCompanyPayload }
    >({
      queryFn: async ({ profileId, payload }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = profileId || currentProfile?.profileId;

          const { data } = await mainApi.post<any>(`/company-service`, {
            user_profile_id,
            ...payload,
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'ServicesCompanies', id: 'LIST' }],
    }),

    updateServicesCompany: builder.mutation<
      ServicesCompanyPayload,
      { profileId?: number; serviceCompanyId: number | string; payload: ServicesCompanyPayload }
    >({
      queryFn: async ({ profileId, serviceCompanyId, payload }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };
          const user_profile_id = profileId || currentProfile?.profileId;

          const { data } = await mainApi.put<ServicesCompanyPayload>(
            `/company-service/${serviceCompanyId}`,
            {
              user_profile_id,
              ...payload,
            }
          );
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'ServicesCompanies', id: 'LIST' }],
    }),

    deleteServicesCompany: builder.mutation<
      any,
      { profileId?: number; companyId: number | string }
    >({
      queryFn: async ({ profileId, companyId }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = profileId || currentProfile?.profileId;
          const { data } = await mainApi.put<any>(`/company-service/${companyId}`, {
            user_profile_id,
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'ServicesCompanies', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetCompanyQuery,
  useUpdateCompanyMutation,
  useGetServicesCompanyQuery,
  useAddServicesCompanyMutation,
  useUpdateServicesCompanyMutation,
  useDeleteServicesCompanyMutation,
  useLazyGetServiceCompanyByIdQuery,
} = companiesApi;
