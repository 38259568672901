import { useEffect, useState } from 'react';
// import { useHistory, useRouteMatch } from 'react-router-dom';
// import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import StatusChip from '../../../shared/components/badges/StatusChip';
// import AddButton from '../../../shared/components/tables/buttons/AddButton';
// import DeleteButton from '../../../shared/components/tables/buttons/DeleteButton';
// import EditButton from '../../../shared/components/tables/buttons/EditButton';
// import useGuard from '../hooks/useGuard';
import { useGetCalificationModelsQuery } from '../slices/calificationModelsApiSlice';
import {
  // openDeleteModalWith,
  setCalificationModelList,
  updateCalificationModelTableStates,
} from '../slices/calificationModelsSlice';
import SearchPaginatedTable, {
  FilterArrow,
} from '../../../shared/components/tables/SearchPaginatedTable';
import { useTableSearch } from '../../../../hooks/useTableSearch';

const CalificationModelTable = () => {
  const { currentProfile } = useAppSelector((state) => state.access);

  const dispatch = useAppDispatch();

  // const { path } = useRouteMatch();
  // const history = useHistory();

  const { page, perPage, search } = useAppSelector(
    (state) => state.calificationModel.calificationModelTable
  );

  const [filterArrow, setFilterArrow] = useState<FilterArrow>({
    name: 'id',
    direction: 'desc',
  });

  const { setPage, setPerPage, setSearch } = useTableSearch({
    page,
    perPage,
    search,
    updateFunction: updateCalificationModelTableStates,
  });

  const { isLoading, error, data, isFetching } = useGetCalificationModelsQuery(
    {
      profileId: currentProfile?.profileId!,
      page,
      perPage,
      search,
      ...(filterArrow && { sort: filterArrow?.name, type_sort: filterArrow?.direction }),
    },
    { refetchOnMountOrArgChange: 30 }
  );

  useEffect(() => {
    if (data) {
      dispatch(setCalificationModelList(data.data));
    }
  }, [data, dispatch]);

  // const { hasAccess } = useGuard();

  // const handleDelete = async (calificationModelId: number) => {
  //   dispatch(openDeleteModalWith(calificationModelId));
  // };

  // const goToAdd = () => {
  //   history.push(`${path}/agregar`);
  // };

  const datafetch = data?.data!.map((d, index) => ({
    id: index + (page - 1) * perPage + 1,
    name: d.cal_mod_name,
    acronym: d.cal_mod_acronym,
    statusId: d.status_id,
    // options: d,
  }));

  return (
    <SearchPaginatedTable
      data={datafetch!}
      headers={{
        id: 'N°',
        name: 'Nombre',
        acronym: 'Acrónimo',
        statusId: 'Estado',
        // options: 'Opciones',
      }}
      keyExtractor={(item) => item.id.toString()}
      listFilterArrows={{
        name: 'cal_mod_name',
        acronym: 'cal_mod_acronym',
        statusId: 'status_id',
      }}
      filterArrow={filterArrow}
      setFilterArrow={setFilterArrow}
      searchPlacehoder="Buscar modelo de calificación"
      isLoading={isLoading}
      isFetching={isFetching}
      error={error}
      perPage={perPage}
      setPerPage={setPerPage}
      total={data?.total || 0}
      page={page}
      setPage={setPage}
      numHeader={6}
      search={search}
      setSearch={setSearch}
      customRenderers={{
        statusId: (row) => <StatusChip statusId={Number(row.statusId)} />,
        // options: (row) => (
        //   <>
        //     {hasAccess('edit') && (
        //       <Link to={`${path}/${row.options.id}/editar`}>
        //         <EditButton />
        //       </Link>
        //     )}
        //     {hasAccess('delete') && (
        //       <DeleteButton handleDelete={() => handleDelete(row.options.id)} />
        //     )}
        //     {!hasAccess('edit') && !hasAccess('delete') && 'Ninguna'}
        //   </>
        // ),
      }}
      // ActionButtons={<>{hasAccess('create') && <AddButton size="large" onClick={goToAdd} />}</>}
      customDataCellsProperties={{
        id: { align: 'center' },
        acronym: { align: 'center' },
        statusId: { align: 'center' },
        // options: { align: 'center' },
      }}
    />
  );
};

export default CalificationModelTable;
