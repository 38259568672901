import { FC } from 'react';
import { PermissionsKeys } from '../../interfaces/permissions.interface';
import PermissionObjectCheck from './PermissionObjectCheck';
import PermissionListCheck from './PermissionListCheck';

interface Props {
  permissions?: string | string[] | PermissionsKeys | PermissionsKeys[];
  mode?: 'all' | 'one';

  notPermission?: () => JSX.Element;

  testForceValidation?: boolean;
}

const PermissionsCheck: FC<Props> = ({
  permissions,
  children,
  mode,
  notPermission,
  testForceValidation,
}) => {
  if (!permissions || (Array.isArray(permissions) && permissions.length === 0))
    return <>{children}</>;

  if (
    typeof permissions === 'string' ||
    (Array.isArray(permissions) &&
      typeof permissions[0] === 'string' &&
      typeof permissions[0] !== 'object')
  )
    return (
      <PermissionListCheck
        permissions={permissions as string | string[]}
        mode={mode}
        notPermission={notPermission}
        testForceValidation={testForceValidation}
      >
        <>{children}</>
      </PermissionListCheck>
    );

  return (
    <PermissionObjectCheck
      permissions={permissions as PermissionsKeys | PermissionsKeys[]}
      mode={mode}
      notPermission={notPermission}
      testForceValidation={testForceValidation}
    >
      <>{children}</>
    </PermissionObjectCheck>
  );
};

export default PermissionsCheck;
