import header from './header.json';
import menu from './menu.json';
import tests from './tests.json';
import announcements from './announcements.json';
import attendance from './attendance.json';
import forum from './forum.json';
import meetings from './meetings.json';

import materials from "./materials.json"
import dashboard from "./dashboard.json"
import tasks from "./tasks.json"
import califications from "./califications.json"
const virtualClassroom = {
  ...header,
  ...menu,
  ...tests,
  ...announcements,
  ...attendance,
  ...forum,
  ...meetings,
  ...materials,
  ...dashboard,
  ...tasks,
  ...califications


};

export default virtualClassroom;
