import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import App from './App';

import store from './store';

import './assets/fonts';

import './assets/scss/style.scss';

/**
 * App entry point
 * renders principal App component and wrap into redux provider
 */
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);
