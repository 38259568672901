import { mainApi } from '../../../../api/mainApi';
import { middlewareApi } from '../../../middleware/middlewareApi';
import { Shelf, ShelfsResponse } from '../interfaces/shlefs.interface';

const shelfsTags = middlewareApi.enhanceEndpoints({
  addTagTypes: ['Shelfs'],
});

const shelfsApi = shelfsTags.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * @GET estantes
     */
    getShelfs: builder.query<
      ShelfsResponse,
      {
        available_storage?: number;
        page?: number;
        size?: number;
        search?: string;
        sort?: string;
        type_sort?: string;
        data_?: string;
      }
    >({
      queryFn: async ({ available_storage, page, size, search, sort, type_sort, data_ }) => {
        try {
          const { data } = await mainApi.get<ShelfsResponse>('/books-locations', {
            params: {
              available_storage,
              page,
              size,
              search,
              sort,
              type_sort,
              data: data_,
            },
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: ['Shelfs'],
    }),
    /**
     * @GET all shefls
     */
    getAllShelfs: builder.query<Shelf[], void>({
      queryFn: async () => {
        try {
          const { data } = await mainApi.get<Shelf[]>('/books-locations', {
            params: {
              data: 'all',
            },
          });
          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: ['Shelfs'],
    }),
    /**
     * @POST add shelf
     */
    addShelf: builder.mutation<
      any,
      {
        payload: {
          description: string;
          location_type: string;
          acronym: string;
          code: string;
          capacity: number;
          parent_id?: number | null;
          campus_id?: number;
          status_id: number;
        };
      }
    >({
      queryFn: async ({ payload }) => {
        try {
          const { data } = await mainApi.post<any>('/books-locations', { ...payload });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: ['Shelfs'],
    }),
    /**
     * @PUT edit shelft
     */
    editShelft: builder.mutation<
      any,
      {
        id: number;
        payload: {
          description: string;
          location_type: string;
          acronym: string;
          code: string;
          capacity: number;
          parent_id?: number | null;
          status_id: number;
        };
      }
    >({
      queryFn: async ({ id, payload }) => {
        try {
          const { data } = await mainApi.put(`/books-locations/${id}`, {
            ...payload,
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: ['Shelfs'],
    }),
    /**
     * @DELETE remove shelft
     */
    deleteShelft: builder.mutation<
      any,
      {
        id: number;
      }
    >({
      queryFn: async ({ id }) => {
        try {
          const { data } = await mainApi.delete<any>(`/books-locations/${id}`);
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: ['Shelfs'],
    }),
  }),
});

export const {
  useGetShelfsQuery,
  useGetAllShelfsQuery,
  useAddShelfMutation,
  useEditShelftMutation,
  useDeleteShelftMutation,
} = shelfsApi;
