import { useState, useEffect } from 'react';

import {
  Autocomplete,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useHistory, useParams } from 'react-router-dom';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import DatePicker from '@material-ui/lab/DatePicker';
import esLocale from 'date-fns/locale/es';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import { format, parseISO } from 'date-fns';

import { useAppSelector } from '../../../../hooks/useRedux';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import { statusAvailables } from '../../../shared/constants/resourceStatus';
import { useFormStyles } from '../constants/styles';
import { AgreementInfoSchema } from '../constants/validationSchemas';
import { useUpdateAgreementMutation } from '../slices/agreementsApiSlices';
import { useLazyGetInstitutesQuery } from '../../institutes/slices/institutesApiSlice';
import CustomAsyncPaginatedSelect from '../../../shared/components/extended/Form/CustomAsyncPaginatedSelect';

const agreementType = [
  { id: 1, value: 'N', label: 'Nivelación' },
  { id: 2, value: 'H', label: 'Homologación' },
];

const EditAgreementForm = () => {
  const history = useHistory();
  const { agreementId } = useParams<{ agreementId: string }>();

  const { agreementsList } = useAppSelector((state) => state.agreements);
  const { currentProfile } = useAppSelector((state) => state.access);

  const classes = useFormStyles();

  const [showAlerts, setShowAlerts] = useState(false);

  const [updateAgreement, { isLoading: isUpdating, isSuccess, isError, error }] =
    useUpdateAgreementMutation();

  // const { data: institutesAvailables = [], isLoading: isLoadingInstitutesAvailables } =
  //   useGetAvailableInstitutesQuery({
  //     profileId: currentProfile?.profileId!,
  //   });
  const [getInstitutesTrigger] = useLazyGetInstitutesQuery();

  //TODO: crear hook en el componente del select
  const getInstitutes = async (search: string, page: number, perPage: number) => {
    const response = await getInstitutesTrigger({
      page,
      perPage,
      search,
      profileId: currentProfile?.profileId!,
    }).unwrap();

    const hasMore = response.current_page < response.last_page;

    return {
      options: response.data.map((dt) => {
        return {
          value: dt.id,
          label: dt.inst_name,
          ...dt,
        };
      }),
      hasMore,
    };
  };

  const agreementToEdit = agreementsList.find((p) => p.id === Number(agreementId));

  const [instituteValue, setInstituteValue] = useState<any | null>(
    {
      id: agreementToEdit?.institute?.id,
      value: agreementToEdit?.institute?.id,
      label: agreementToEdit?.institute?.inst_name || '',
    } || null
  );

  const { getFieldProps, errors, touched, handleSubmit, setFieldValue, values } = useFormik({
    initialValues: {
      name: agreementToEdit?.agr_name || '',
      agr_num: agreementToEdit?.agr_num_matter_homologate || 1,
      agr_start_date: parseISO(agreementToEdit?.agr_start_date!) || null,
      agr_end_date: parseISO(agreementToEdit?.agr_end_date!) || null,
      instituteId: agreementToEdit?.institute_id || -1,
      statusId: agreementToEdit?.status_id || 1,
      agreementType: agreementToEdit?.type_agreement || '',
    },
    validationSchema: AgreementInfoSchema,
    onSubmit: async (values) => {
      await updateAgreement({
        profileId: currentProfile?.profileId!,
        agreementId: agreementToEdit?.id!,
        agreementPayload: {
          agr_name: values.name,
          // agr_num_matter_homologate: values.agr_num.toString(),
          ...(values.agreementType === 'H' && {
            agr_num_matter_homologate: values.agr_num.toString(),
          }),
          agr_start_date: format(values.agr_start_date!, 'yyyy-MM-dd'),
          agr_end_date: format(values.agr_end_date!, 'yyyy-MM-dd'),
          institute_id: values.instituteId.toString(),
          type_agreement: values.agreementType,
          status_id: values.statusId.toString(),
        },
      });

      setShowAlerts(true);
    },
  });

  useEffect(() => {
    if (Number(instituteValue.id) !== Number(agreementToEdit?.institute_id)) {
      setFieldValue('instituteId', instituteValue.id);
    }
  }, [agreementToEdit?.institute_id, instituteValue, setFieldValue]);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid container direction="column" gap={2}>
          <Grid item>
            {isError && showAlerts && (
              <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
            )}

            {isSuccess && showAlerts && (
              <SuccessAlert
                message="El convenio ha sido actualizado correctamente."
                handleDismiss={() => setShowAlerts(false)}
              />
            )}
          </Grid>

          <Grid item container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <InputLabel className={classes.inputLabel}>Tipo de Convenio</InputLabel>
              <Autocomplete
                options={agreementType}
                getOptionLabel={(option) => option.label}
                loadingText="Cargando Tipos de Convenio..."
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(errors.agreementType && touched.agreementType)}
                    placeholder="Seleccione el Tipo de Convenio"
                  />
                )}
                value={agreementType.find((dt) => dt.value === values.agreementType) || null}
                onChange={(_, value) => setFieldValue('agreementType', value?.value || '')}
                fullWidth
                clearIcon={null}
              />
              {errors.agreementType && touched.agreementType && (
                <FormHelperText error>{errors.agreementType}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12}>
              <InputLabel className={classes.inputLabel}>Nombre</InputLabel>
              <TextField
                fullWidth
                error={Boolean(errors.name && touched.name)}
                placeholder="Ingrese el nombre del convenio"
                {...getFieldProps('name')}
              />
              {errors.name && touched.name && <FormHelperText error>{errors.name}</FormHelperText>}
            </Grid>

            {values.agreementType === 'H' && (
              <Grid item xs={12}>
                <InputLabel className={classes.inputLabel}>Número de materias</InputLabel>
                <TextField
                  type="number"
                  fullWidth
                  error={Boolean(errors.agr_num && touched.agr_num)}
                  placeholder="Ingrese el número de materias"
                  {...getFieldProps('agr_num')}
                />
                {errors.agr_num && touched.agr_num && (
                  <FormHelperText error>{errors.agr_num}</FormHelperText>
                )}
              </Grid>
            )}

            {/* Fecha de inicio */}
            <Grid item xs={12}>
              <InputLabel className={classes.inputLabel}>Fecha de inicio del convenio</InputLabel>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                <DatePicker
                  value={values.agr_start_date}
                  onChange={(newValue) => {
                    setFieldValue('agr_start_date', newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(errors.agr_start_date && touched.agr_start_date)}
                      helperText=""
                      placeholder="Fecha de inicio del convenio"
                      fullWidth
                    />
                  )}
                />
              </LocalizationProvider>
              {errors.agr_start_date && touched.agr_start_date && (
                <FormHelperText error>{errors.agr_start_date}</FormHelperText>
              )}
            </Grid>

            {/* Fecha de fin */}
            <Grid item xs={12}>
              <InputLabel className={classes.inputLabel}>Fecha de fin del convenio</InputLabel>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                <DatePicker
                  value={values.agr_end_date}
                  onChange={(newValue) => {
                    setFieldValue('agr_end_date', newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(errors.agr_end_date && touched.agr_end_date)}
                      helperText=""
                      placeholder="Fecha de fin del convenio"
                      fullWidth
                    />
                  )}
                />
              </LocalizationProvider>
              {errors.agr_end_date && touched.agr_end_date && (
                <FormHelperText error>{errors.agr_end_date}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12}>
              <InputLabel className={classes.inputLabel}>Instituto</InputLabel>
              {/* <Autocomplete
                loadingText="Cargando Datos"
                noOptionsText="No hay opciones"
                options={institutesAvailables}
                loading={isLoadingInstitutesAvailables}
                getOptionLabel={(option) => option.inst_name}
                defaultValue={institutesAvailables.find(
                  (i) => i.id === Number(agreementToEdit?.institute_id)
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(errors.instituteId && touched.instituteId)}
                    placeholder="Ingrese el instituto"
                  />
                )}
                onChange={(e, value) => setFieldValue('instituteId', value?.id || '')}
                fullWidth
                clearIcon={null}
              /> */}
              <CustomAsyncPaginatedSelect
                key={'instituteId'}
                value={instituteValue}
                isError={'instituteId' in errors && touched['instituteId'] ? true : false}
                placeholder={'Escoger instituto'}
                setValue={setInstituteValue}
                fetchFunc={getInstitutes}
              />
              {errors.instituteId && touched.instituteId && (
                <FormHelperText error>{errors.instituteId}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12}>
              <InputLabel className={classes.inputLabel}>Estado</InputLabel>
              <Autocomplete
                options={statusAvailables}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField {...params} error={Boolean(errors.statusId && touched.statusId)} />
                )}
                defaultValue={statusAvailables.find(
                  (s) => s.id === Number(agreementToEdit?.status_id)
                )}
                onChange={(e, value) => setFieldValue('statusId', value?.id || '')}
                fullWidth
                clearIcon={null}
              />
              {errors.statusId && touched.statusId && (
                <FormHelperText error>{errors.statusId}</FormHelperText>
              )}
            </Grid>
          </Grid>

          <Grid
            item
            container
            alignItems="center"
            justifyContent="flex-end"
            spacing={2}
            sx={{ mt: 1 }}
          >
            <Grid item>
              <Button
                variant="outlined"
                size="large"
                color="primary"
                onClick={() => history.goBack()}
              >
                Regresar
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                size="large"
                color="primary"
                disabled={isUpdating}
                endIcon={isUpdating && <CircularProgress size={20} />}
              >
                Actualizar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default EditAgreementForm;
