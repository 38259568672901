import { Grid, Typography } from '@material-ui/core';
import { useAppSelector } from '../../../../hooks/useRedux';
import MainCard from '../../../shared/components/cards/MainCard';
import ChangeStatusSyllabusDialog from '../components/ChangeStatusSyllabusDialog';
import HistorialSyllabusDialog from '../components/HistorialSyllabusDialog';
import SyllabusListTable from '../components/SyllabusListTable';

const SyllabusScreen = () => {
  const { isOpenChangeStatusModal, isOpenHistorialSyllabusModal } = useAppSelector(
    (state) => state.syllabus
  );

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">Syllabus</Typography>
      </Grid>
      <SyllabusListTable />
      {isOpenChangeStatusModal && <ChangeStatusSyllabusDialog />}
      {isOpenHistorialSyllabusModal && <HistorialSyllabusDialog />}
    </Grid>
  );
};

export default SyllabusScreen;
