import {
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppSelector } from '../../../../hooks/useRedux';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import { useFormStyles } from '../constants/styles';
import { CompanyInfoSchema } from '../constants/validarionSchemas';
import { useUpdateCompanyMutation } from '../slices/companiesApiSlice';

const EditCompaniesForm = () => {
  const { currentProfile } = useAppSelector((state) => state.access);
  const { company } = useAppSelector((state) => state.companies);
  const classes = useFormStyles();
  const [showAlerts, setShowAlerts] = useState(false);

  const history = useHistory();

  const [updateCompany, { isLoading: isUpdating, isSuccess, isError, error }] =
    useUpdateCompanyMutation();

  const { getFieldProps, errors, touched, handleSubmit } = useFormik({
    initialValues: {
      name: company?.co_name || '',
      ruc: company?.co_ruc || '',
      website: company?.co_website || '',
      email: company?.co_email || '',
      whatsApp: company?.co_whatsapp || '',
      phone: company?.co_phone || '',
      pbx: company?.co_pbx || '',
      statusId: 1,
      co_mision: company?.co_mision || '',
      co_vision: company?.co_vision || '',
      co_director_message: company?.co_director_message || '',
      co_url_file_director_photo: company?.co_url_file_director_photo || '',
    },
    validationSchema: CompanyInfoSchema,
    onSubmit: async (values) => {
      await updateCompany({
        profileId: currentProfile?.profileId!,
        companyId: company?.id!,
        companyPayload: {
          co_name: values.name,
          co_ruc: values.ruc,
          co_website: values.website,
          co_email: values.email,
          status_id: values.statusId.toString(),
          co_mision: values.co_mision,
          co_vision: values.co_vision,
          co_whatsapp: values.whatsApp,
          co_phone: values.phone,
          co_pbx: values.pbx,
          co_director_message: values?.co_director_message || '',
          co_url_file_director_photo: values?.co_url_file_director_photo || '',
        },
      });

      setShowAlerts(true);
    },
  });

  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction="column" gap={2}>
        <Grid item>
          {isError && showAlerts && (
            <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
          )}

          {isSuccess && showAlerts && (
            <SuccessAlert
              message="Los datos principales de la compañía se actualizaron."
              handleDismiss={() => setShowAlerts(false)}
            />
          )}
        </Grid>

        <Grid item container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>Nombre</InputLabel>
            <TextField
              fullWidth
              error={Boolean(errors.name && touched.name)}
              placeholder="Ingrese el nombre de la compañía"
              {...getFieldProps('name')}
            />
            {errors.name && touched.name && <FormHelperText error>{errors.name}</FormHelperText>}
          </Grid>

          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>Ruc</InputLabel>
            <TextField
              fullWidth
              error={Boolean(errors.ruc && touched.ruc)}
              placeholder="Ingrese el ruc de la compañía"
              {...getFieldProps('ruc')}
            />
            {errors.ruc && touched.ruc && <FormHelperText error>{errors.ruc}</FormHelperText>}
          </Grid>

          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>Website</InputLabel>
            <TextField
              fullWidth
              error={Boolean(errors.website && touched.website)}
              placeholder="Ingrese el website de la compañía"
              {...getFieldProps('website')}
            />
            {errors.website && touched.website && (
              <FormHelperText error>{errors.website}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>Email</InputLabel>
            <TextField
              fullWidth
              error={Boolean(errors.email && touched.email)}
              placeholder="Ingrese el email de la compañía"
              {...getFieldProps('email')}
            />
            {errors.email && touched.email && <FormHelperText error>{errors.email}</FormHelperText>}
          </Grid>
          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>WhatsApp</InputLabel>
            <TextField
              fullWidth
              error={Boolean(errors.whatsApp && touched.whatsApp)}
              placeholder="Ingrese el WhatsApp de la compañía"
              {...getFieldProps('whatsApp')}
            />
            {errors.whatsApp && touched.whatsApp && (
              <FormHelperText error>{errors.whatsApp}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>Celular</InputLabel>
            <TextField
              fullWidth
              error={Boolean(errors.phone && touched.phone)}
              placeholder="Ingrese el celular de la compañía"
              {...getFieldProps('phone')}
            />
            {errors.phone && touched.phone && <FormHelperText error>{errors.phone}</FormHelperText>}
          </Grid>
          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>Pbx</InputLabel>
            <TextField
              fullWidth
              error={Boolean(errors.pbx && touched.pbx)}
              placeholder="Ingrese el pbx de la compañía"
              {...getFieldProps('pbx')}
            />
            {errors.pbx && touched.pbx && <FormHelperText error>{errors.pbx}</FormHelperText>}
          </Grid>

          <Grid
            item
            container
            alignItems="center"
            justifyContent="flex-end"
            spacing={2}
            sx={{ mt: 1 }}
          >
            <Grid item>
              <Button
                variant="outlined"
                size="large"
                color="primary"
                onClick={() => history.goBack()}
              >
                Regresar
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                size="large"
                color="primary"
                disabled={isUpdating}
                endIcon={isUpdating && <CircularProgress size={20} />}
              >
                Actualizar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default EditCompaniesForm;
