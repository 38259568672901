import {
  Box,
  Card,
  Chip,
  Grid,
  Typography,
  Theme,
  useTheme,
  Stack,
  Button,
} from '@material-ui/core';
import { IconCheck, IconX } from '@tabler/icons';
import { makeStyles } from '@material-ui/core/styles';
import { FC, MouseEvent, useState } from 'react';
import { ClassRoom } from '../interfaces/classroomEdLvl.interface';
import { useTranslation } from 'react-i18next';
import { MeetingRoom } from '@material-ui/icons';
import ClassroomLoans from './ClassroomLoans';

//grey.100 = f5f5f5
//grey.200 = eee
//grey.300 = e0e0e0
//grey.400 = bdbdbd
//grey.500 = 9e9e9e
// style constant
const useStyles = makeStyles((theme: Theme) => ({
  cardClassroom: {
    // width: '250px',
    // height: '115px',
    width: '300px',
    height: 'auto',
    border: '1px solid',
    padding: '20px 16px',
    userSelect: 'none',
    '& div.MuiCardContent-root:last-child': {
      paddingBottom: '0',
    },
  },
  chipStatus: {
    marginTop: '5px',
    fontSize: '0.75rem',
    padding: '2px 5px',
    height: '20px',
    '&>span.MuiChip-label': { paddingRight: 0 },
    '&>div.MuiAvatar-root': {
      borderRadius: '100%',
      height: '0.7rem',
      width: '0.7rem',
      // backgroundColor: theme.palette.success.dark, //'#00C853',
      marginLeft: 0,
    },
  },
  disabled: {
    backgroundColor: '#F2F3F4',
    // boxShadow: '0px 0px 20px #EAF0F7',
    color: '#CCD0D3',
  },
  available: {
    border: '1px solid #F2F3F4',
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 0px 20px #EAF0F7',
    cursor: 'pointer',
    '&:hover': {
      border: `1px solid ${theme.palette.primary.main}`,
      '& $circleStatus': {
        border: `1px ${theme.palette.primary.main} solid!important`,
        // '&>svg.icon': { color: theme.palette.primary.light },
      },
    },
  },
  selected: {
    boxShadow: `0px 0px 20px #EAF0F7`,
    border: `1px solid ${theme.palette.primary.main}!important`,
    cursor: 'pointer',
    // color: 'white',
    '&:hover': {},
  },
  asigned: {
    boxShadow: '0px 0px 20px #EAF0F7',
    border: '1px solid #F2F3F4',
    cursor: 'pointer',
    color: 'white',
    '&:hover': {
      border: '1px solid #D84315',
      '& $circleStatus': {
        backgroundColor: theme.palette.error.main,
        border: `1px ${theme.palette.error.main} solid!important`,
        '&>svg.icon': { color: theme.palette.primary.light },
      },
    },
  },

  circleStatus: {
    height: '16px',
    width: '16px',
    borderRadius: '100%',
    color: theme.palette.primary.light,
    border: `2px ${theme.palette.grey[300]} solid`,
  },
  circleStatusSelected: {
    border: `1px ${theme.palette.primary.main} solid`,
    backgroundColor: theme.palette.primary.main,
    '&>svg.icon': { color: `${theme.palette.primary.light}` },
  },
}));

type stylesType = ReturnType<typeof useStyles>;

interface Props {
  //   status: 'disabled' | 'selected' | 'asigned' | 'available';
  data: ClassRoom;
  selected: boolean;
  periodId: number;
  offerId: number;
  onClick: (
    status: 'disponible' | 'no disponible' | 'asignada',
    id: number,
    idAsigned: string | undefined,
    selected: boolean
  ) => void;
}

const CardView: FC<Props> = (props) => {
  const { t } = useTranslation();

  const { selected, data: classroom, onClick: handleSubmit, periodId, offerId } = props;
  const classes = useStyles();
  const theme = useTheme();

  const [open, setOpen] = useState(false);
  // const [idClass, setIdClass] = useState<number>();

  // const [classroomId,setClassroomId] = useState<number>();

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleOpenClientSideDialog = (e: MouseEvent) => {
    // setClassroomId(classroom?.id);
    // selection.map((ids)=>{
    //   const id = Number(ids);
    //   return id;
    // })
    e.stopPropagation();
    setOpen(true);
  };

  const [cardStatus] = useState<'disponible' | 'no disponible' | 'asignada'>(
    classroom.EstadoAula.toLowerCase() as 'disponible' | 'no disponible' | 'asignada'
  );

  return (
    <Grid
      container
      item
      xs={12}
      sm={6}
      md={4}
      lg={3}
      justifyContent={'center'}
      //   key={`card-${classroom.id}`}
    >
      <Card
        // id={ranId.toString()}
        className={`${classes.cardClassroom} ${
          classes[
            selected
              ? 'selected'
              : cardStatus === 'disponible'
              ? 'available'
              : cardStatus === 'asignada'
              ? 'asigned'
              : ('disabled' as keyof stylesType)
          ]
        }`}
        sx={{
          margin: '5px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        onClick={() => {
          handleSubmit(cardStatus, classroom.id, classroom.cl_edu_lev_id!, selected);
          //   if (cardStatus === 'available') setCardStatus('selected');
          //   if (cardStatus === 'selected') setCardStatus('available');
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Stack direction="row" alignItems="center" justifyContent={'space-between'}>
              <Typography
                variant="h4"
                align="center"
                sx={{ color: `${theme.palette.primary.main}` }}
              >
                {classroom.cl_name}
              </Typography>
              <Grid
                item
                container
                justifyContent={'center'}
                alignContent={'center'}
                className={`${classes.circleStatus} ${
                  selected ? classes.circleStatusSelected : ''
                }`}
              >
                {selected && <IconCheck size={12} />}
                {cardStatus === 'asignada' && <IconX size={12} />}
              </Grid>
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2">{classroom.clt_name}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">
              {t('academic.classrooms_distribution.capacity')}: {classroom.cl_cap_max}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', gap: 5 }}>
            <Chip
              className={classes.chipStatus}
              icon={
                <Box
                  sx={{
                    height: '10px',
                    width: '10px',
                    borderRadius: '100%',
                    backgroundColor:
                      cardStatus === 'disponible'
                        ? theme.palette.success.main
                        : cardStatus === 'asignada'
                        ? theme.palette.primary.main
                        : theme.palette.error.main,
                    marginLeft: '0!important',
                  }}
                ></Box>
              }
              label={t('utils.classroom_status.' + cardStatus)}
              //   label={available ? (asigned ? 'asignada' : 'disponible') : 'No disponible'}
            />

            <Button onClick={(e) => handleOpenClientSideDialog(e)}>
              <MeetingRoom />
            </Button>

            <ClassroomLoans
              open={open}
              periodId={periodId}
              handleCloseDialog={handleCloseDialog}
              id={classroom?.id!}
              offerId={offerId}
            />
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};

export default CardView;
