import {
  Grid,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Table,
  Typography,
  Checkbox,
  IconButton,
} from '@material-ui/core';

import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
//import AccessTimeOutlinedIcon from '@material-ui/icons/AccessTimeOutlined';
import { ArrowBack } from '@material-ui/icons';
import { isSameDay } from 'date-fns';

import SubCard from '../../../shared/components/cards/SubCard';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import {
  useAddFaultAssistantsMutation,
  useGetAssistantsListCreatedDateQuery,
} from '../slices/virtualClassroomTeacherApiSlice';
import { useAppSelector } from '../../../../hooks/useRedux';
import DateTimePickers from './Components/DateTimePickers';
import { useGetCourseStudentsQuery } from '../../courses/slices/coursesTeacherApiSlice';
import LoadingScreen from '../../../shared/screens/LoadingScreen';
import { CourseStudents } from '../../courses/interfaces/courseStudents.interfaces';
import useFormatDate from '../../../shared/hooks/useFormatDate';
//import HeroData from '../dashboard/components/HeroData';

// interface DataForm {
//   students?: DataStudent[];
// }

interface DataStudent {
  id: number;
  fullname: string;
  type_fault: string;
  prev_type_fault: string | null;
  fault_assistant_id: string;
  course_student_id: string;
  fault: boolean;
}

// const Styles = makeStyles({
//   boton: {
//     '&.css-sghohy-MuiButtonBase-root-MuiButton-root ': {
//       borderRadius: '50px',
//     },
//     '&.css-sghohy-MuiButtonBase-root-MuiButton-root.Mui-disabled': {
//       fontWeight: 'medium',
//       color: 'white',
//     },
//   },
// });

const AssistantsAppListView = () => {
  const [showAlerts, setShowAlerts] = useState(true);
  const { t } = useTranslation();

  const { formatDate } = useFormatDate();

  const {
    course: { courseId },
  } = useAppSelector((state) => state.virtualClassroomTeacherShared);

  const [courseStudentsList, setCourseStudentsList] = useState<CourseStudents[]>([]);
  const [Fecha, setFecha] = useState<Date | null>(new Date());

  const { data = [] } = useGetCourseStudentsQuery({
    courseId,
    payload: {
      data: 'all',
      date: Fecha ? format(Fecha, 'yyyy-MM-dd') : format(new Date(), 'yyyy-MM-dd'),
    },
  });

  useEffect(() => {
    if (data) {
      setCourseStudentsList(data);
    }
  }, [data]);

  const { data: assistantsListCreated, isLoading: isLoadingAssistantsListCreated } =
    useGetAssistantsListCreatedDateQuery(
      {
        courseId: courseId!,
      },
      {
        skip: !courseId,
      }
    );

  const valuesStudent: DataStudent[] = courseStudentsList?.map((cs) => {
    const fault =
      cs.type_fault === 'Falta rectificada' || cs.type_fault === 'Atraso justificado'
        ? false
        : cs.type_fault || (!assistantsListCreated && Fecha && isSameDay(Fecha, new Date()))
        ? true
        : false;

    return {
      id: cs.id,
      fullname: `${cs.pers_full_lastname}`,
      type_fault: cs.type_fault ?? null,
      prev_type_fault: cs.type_fault ?? null,
      course_student_id: cs.course_student_id,
      fault_assistant_id: cs.fault_assistant_id,
      fault,
    };
  });

  console.log(valuesStudent);
  // const initialValues: DataForm = {
  //   students: valuesStudent,
  // };

  const [, { isError, error, isSuccess }] = useAddFaultAssistantsMutation();

  const num = (index: number) => ++index;
  const history = useHistory();

  return (
    <>
      {isLoadingAssistantsListCreated && <LoadingScreen />}
      {!isLoadingAssistantsListCreated && (
        <SubCard
          title={
            <>
              <IconButton onClick={() => history.goBack()}>
                <ArrowBack />
              </IconButton>
              {t('teacher_virtual_classroom.assistants_app_list.title_subcard_list')}
            </>
          }
          darkTitle
          sx={{
            borderTopLeftRadius: '0px',
            borderTopRightRadius: '0px',
          }}
        >
          <Grid item xs={12} md={6} mb={2} container>
            <Grid item>
              {isError && showAlerts && (
                <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
              )}

              {isSuccess && showAlerts && (
                <SuccessAlert
                  message={t('teacher_virtual_classroom.assistants_app_list.success_message')}
                  handleDismiss={() => setShowAlerts(false)}
                />
              )}
            </Grid>
          </Grid>
          {assistantsListCreated && (
            <Grid item xs={12} container>
              <Typography>
                <strong>{t('teacher_virtual_classroom.assistants_app_list.last_update')}</strong>:{' '}
                {formatDate(assistantsListCreated, {
                  format: 'PPPpp',
                })}
              </Typography>
            </Grid>
          )}
          <Grid display={'flex'} justifyContent={'center'} alignItems="center">
            <DateTimePickers Fecha={Fecha} setFecha={setFecha} />
          </Grid>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">#</TableCell>
                  <TableCell align="left">
                    {t('teacher_virtual_classroom.assistants_app_list.names')}
                  </TableCell>
                  <TableCell style={{ alignItems: 'center' }} align="center">
                    <Grid container alignItems="center" justifyContent="center">
                      {t('teacher_virtual_classroom.assistants_app_list.attendance')}
                      <CheckBoxOutlinedIcon style={{ color: 'green', marginLeft: 1 }} />
                    </Grid>
                  </TableCell>
                  {/* <TableCell style={{ alignItems: 'center' }} align="center">
                    <Grid container alignItems="center" justifyContent="center">
                      {t('teacher_virtual_classroom.assistants_app_list.tardy')}
                      <AccessTimeOutlinedIcon style={{ color: 'orange', marginLeft: 1 }} />
                    </Grid>
                  </TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {!courseStudentsList.length ? (
                  <TableRow>
                    <TableCell colSpan={5}>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                        textAlign="center"
                      >
                        {t('teacher_virtual_classroom.assistants_app_list.not_students')}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  <>
                    {valuesStudent?.map((st, index) => (
                      <TableRow className="table-row" key={st.id}>
                        <TableCell align="center" width="5%">
                          {num(index)}
                        </TableCell>
                        <TableCell align="left" width="40%">
                          {st.fullname}
                        </TableCell>
                        <TableCell align="center" width="40%">
                          <Checkbox disabled={true} checked={!st.fault} indeterminate={false} />
                        </TableCell>
                        {false && (
                          <TableCell align="center" width="2%">
                            <Checkbox
                              disabled={true}
                              checked={st.type_fault === 'Atraso injustificado'}
                              indeterminate={false}
                            />
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </SubCard>
      )}
    </>
  );
};

export default AssistantsAppListView;
