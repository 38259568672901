import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import DeleteAlert from '../../../../shared/components/dialogs/DeleteAlert';
import { useDeleteForumMutation } from '../../slices/virtualClassroomTeacherApiSlice';
import {
  setForumDeleteDialogOpen,
  setForumIdToDelete,
} from '../../slices/virtualClassroomTeacherSlice';

const ForumDeleteDialog = () => {
  const dispatch = useAppDispatch();

  const { forumIdToDelete, forumDeleteDialogOpen } = useAppSelector(
    (state) => state.virtualClassroomTeacher
  );
  const { course } = useAppSelector((state) => state.virtualClassroomTeacherShared);

  const { currentProfile } = useAppSelector((state) => state.access);

  const [deleteForum, { isLoading, isSuccess, isError, error }] = useDeleteForumMutation();

  const handleDelete = () => {
    deleteForum({
      forumId: forumIdToDelete!,
      profileId: currentProfile?.profileId!,
      courseId: course.courseId!,
    });
  };

  const handleClose = () => {
    dispatch(setForumDeleteDialogOpen(false));
    dispatch(setForumIdToDelete(null));
  };
  return (
    <DeleteAlert
      open={forumDeleteDialogOpen}
      onClose={handleClose}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      handleDelete={handleDelete}
      error={error as string}
    />
  );
};

export default ForumDeleteDialog;
