import { Languages } from '../../../../../../types';
import { LanguageSelector } from '../../../../interfaces/material-ui.interfaces';

export const languages: LanguageSwitcherItems[] = [
  {
    label: {
      en: 'Spanish',
      es: 'Español',
    },
    value: 'es',
    icon: 'flag_ec.png',
  },
  {
    label: {
      en: 'English',
      es: 'Inglés',
    },
    value: 'en',
    icon: 'flag_usa.png',
  },
];

export interface LanguageSwitcherItems {
  label: LanguageSelector;
  value: Languages;
  icon: string;
}
