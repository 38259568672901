import { Grid, Typography } from '@material-ui/core';
import { useAppSelector } from '../../../../hooks/useRedux';
import MainCard from '../../../shared/components/cards/MainCard';
import ParallelsTable from '../components/ParallelsTable';
import DeleteParallelDialog from '../components/DeletePerallelDialog';
import { useTranslation } from 'react-i18next';

const ParallelsScreen = () => {
  const { t } = useTranslation();

  const { parallelIdToDelete } = useAppSelector((state) => state.parallels);
  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">{t('academic.parallels.title')}</Typography>
      </Grid>

      <ParallelsTable />

      {parallelIdToDelete && <DeleteParallelDialog />}
    </Grid>
  );
};

export default ParallelsScreen;
