import { Button, ButtonGroup, Dialog, Grid, Paper, Tooltip, Typography } from '@material-ui/core';
import {
  IconBook,
  IconDownload,
  IconListDetails,
  IconTrash,
  IconEye,
  IconLink,
} from '@tabler/icons';
import moment from 'moment';
import { useState } from 'react';
import downloadjs from 'downloadjs';
// import { useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import ViewFileModal from './ViewFileModal';
import useGetUrlDownload from '../../../../../hooks/useGetUrlDownload';
import { useAppDispatch } from '../../../../../hooks/useRedux';
import { openDeleteModalMaterials } from '../../slices/virtualClassroomTeacherSlice';
//import { getProtocol } from '../../../../../helpers/urlHelpers';

const ItemFileAccordeon: React.FC<{
  tool_ms_description: string;
  upload_date: Date;
  id: number;
  link: string;
  course: string;
  urlFile: string;
}> = ({ tool_ms_description, upload_date, urlFile, id, course, link }) => {
  const dispatch = useAppDispatch();

  // const history = useHistory();

  const [modalDetailOpen, setModalDetailOpen] = useState(false);

  const [modalViewDocOpen, setModalViewDocOpen] = useState(false);

  const { t } = useTranslation();

  const { urlDownload } = useGetUrlDownload();
  // const apiUrl = localStorage.getItem('apiUrl');

  const handleDownload = (urlFile: string) => {
    const url = `${urlDownload}/${urlFile}`;
    // console.log(url.replace(' ', '%20'));
    try {
      downloadjs(url.replace(' ', '%20'));
    } catch (error) {
      console.log(error);
    }
  };
  const handleClickDelete = (id: number) => {
    dispatch(openDeleteModalMaterials(id));
  };

  const handleLink = (link: string) => {
    window.open(link, '__blank');
  };

  return (
    <Paper
      elevation={1}
      style={{
        padding: '5px 0',
        margin: '8px 0',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        // marginLeft: '30px',
      }}
    >
      <ViewFileModal
        Open={modalViewDocOpen}
        onClose={() => setModalViewDocOpen(false)}
        url={urlFile}
      />

      {/* {modalDeleteOpen.modalDelete && (
        <DeleteAlert
          deleteTitle="Desea eliminar esta carpeta?"
          open={modalDeleteOpen.modalDelete}
          handleDelete={() => DeleteFolder(modalDeleteOpen.id)}
          isError={isError}
          isLoading={isLoading}
          isSuccess={isSuccess}
          onClose={() => setModalDeleteOpen({ ...modalDeleteOpen, modalDelete: false })}
          error=""
        />
      )} */}
      <Dialog open={modalDetailOpen}>
        <Grid justifyContent={'center'} style={{ margin: '20px 40px ', padding: '0 20px' }}>
          <Typography textAlign={'center'} variant="h3" color="primary">
            {t('teacherVirtualClassroom.materials.modal.file.input.detail')}
          </Typography>

          <Grid justifyContent={'center'} spacing={1} mt="8px" container>
            <Grid justifyContent={'center'} item xs={2}>
              {!link ? (
                <IconBook
                  size={'40px'}
                  style={{
                    borderRadius: '50%',

                    padding: '5',
                    color: 'white',
                    backgroundColor: 'lightgray',
                  }}
                />
              ) : (
                <IconLink
                  size={'40px'}
                  style={{
                    borderRadius: '50%',

                    padding: '5',
                    color: 'white',
                    backgroundColor: 'lightgray',
                  }}
                />
              )}
            </Grid>
            <Grid justifyContent={'center'} item xs={8}>
              <Typography color="black" variant={'h4'}>
                {t('teacherVirtualClassroom.materials.imports.material')} {tool_ms_description}{' '}
              </Typography>
              <Grid item>
                <Typography variant="h5" color="black">
                  {t('teacherVirtualClassroom.materials.imports.subido')}
                </Typography>
                <Typography variant="body2"> {moment(upload_date).format('L')}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="h5" color="black">
                  {t('teacherVirtualClassroom.materials.imports.carpeta')}
                </Typography>
                <Typography variant="body2"> {course!}</Typography>
              </Grid>
            </Grid>
            <Grid spacing={2} mt={'30px'}>
              <Button
                onClick={() => setModalDetailOpen(false)}
                style={{ marginRight: '7px' }}
                variant="outlined"
              >
                {t('teacherVirtualClassroom.materials.modal.button.regresar')}
              </Button>
              {!link ? (
                <Button onClick={() => handleDownload(urlFile)} variant="contained">
                  {t('teacherVirtualClassroom.materials.imports.download')}
                </Button>
              ) : (
                <Button onClick={() => handleLink(link)} variant="contained">
                  {t('teacherVirtualClassroom.materials.create.goToLink')}
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Dialog>

      <Grid alignItems={'center'} paddingLeft={'30px'} container justifyContent={'space-around'}>
        <Grid display="flex" alignItems={'center'}>
          {!link ? <IconBook color="cyan" /> : <IconLink color="cyan" />}
          <Typography style={{ alignItems: 'center', alignContent: 'center' }} color={'black'}>
            {tool_ms_description}
          </Typography>
        </Grid>
        <Grid alignItems={'center'}>
          <Typography color={'black'}>
            {t('teacherVirtualClassroom.materials.imports.subido')}:
            {moment(upload_date).format('L')}
          </Typography>
        </Grid>

        <Grid>
          <ButtonGroup>
            <Tooltip title={t('teacherVirtualClassroom.materials.modal.file.input.detail')}>
              <Button onClick={() => setModalDetailOpen(true)} color="secondary">
                <IconListDetails />
              </Button>
            </Tooltip>
            {!link && (
              <Tooltip title={t('teacherVirtualClassroom.materials.imports.tooltips.ver')}>
                <Button onClick={() => setModalViewDocOpen(true)} color="secondary">
                  <IconEye />
                </Button>
              </Tooltip>
            )}

            {!link ? (
              <Tooltip title={t('teacherVirtualClassroom.materials.imports.download')}>
                <Button onClick={() => handleDownload(urlFile)} color="secondary">
                  <IconDownload />
                </Button>
              </Tooltip>
            ) : (
              <Tooltip title={t('teacherVirtualClassroom.materials.create.goToLink')}>
                <Button onClick={() => handleLink(link)} color="secondary">
                  <IconLink />
                </Button>
              </Tooltip>
            )}
            <Tooltip title={t('teacherVirtualClassroom.materials.create.delete')}>
              <Button color="secondary" onClick={() => handleClickDelete(id)}>
                <IconTrash />
              </Button>
            </Tooltip>
          </ButtonGroup>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ItemFileAccordeon;
