import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import DeleteAlert from '../../../../shared/components/dialogs/DeleteAlert';
import {
  useDeletePostgraduateProgramMutation,
  useGetPosgraduatePlanificationByTransactionIdQuery,
} from '../../slices/acPlanPostgraduateApiSlice';
import {
  setPostgraduateDeleteProgramDialogOpen,
  setPostgraduateDeleteProgramDialogIdSelected,
  setShowEventsDialog,
} from '../../slices/acPlanPostgraduateSlice';

const DeleteProgramDialog = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { postgraduateDeleteProgramDialogIdSelected, initialPrograms, showEventsDialog } =
    useAppSelector((state) => state.academicPlanningPosgraduate);

  const { data: programInfo, isLoading: isLoadingInfo } =
    useGetPosgraduatePlanificationByTransactionIdQuery(
      {
        id: initialPrograms.find(
          (program) => program.id === postgraduateDeleteProgramDialogIdSelected
        )?.idTransactionPlanning!,
      },
      {
        skip: !initialPrograms.find(
          (program) => program.id === postgraduateDeleteProgramDialogIdSelected
        ),
      }
    );

  const [deleteProgram, { isLoading, isError, isSuccess, error }] =
    useDeletePostgraduateProgramMutation();

  const handleSubmit = async () => {
    await deleteProgram({
      id: postgraduateDeleteProgramDialogIdSelected!,
    });
    dispatch(
      setShowEventsDialog({
        ...showEventsDialog,
        events: showEventsDialog.events.filter(
          (event) => event.id !== postgraduateDeleteProgramDialogIdSelected
        ),
      })
    );
  };

  const handleClose = () => {
    dispatch(setPostgraduateDeleteProgramDialogOpen(false));
    dispatch(setPostgraduateDeleteProgramDialogIdSelected(null));
  };

  const message =
    (programInfo?.postgraduate_academic_planing?.length || 0) > 0
      ? t('posgraduate.delete.title.planned')
      : t('posgraduate.delete.title');

  return (
    <DeleteAlert
      error={error as string}
      isError={isError}
      isLoading={isLoading || isLoadingInfo}
      isSuccess={isSuccess}
      handleDelete={handleSubmit}
      onClose={handleClose}
      open={true}
      deleteTitle={message}
      deleteSuccess={t('posgraduate.delete.success')}
    />
  );
};

export default DeleteProgramDialog;
