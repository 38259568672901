import { Grid, Typography } from '@material-ui/core';
import MainCard from '../../../../shared/components/cards/MainCard';

import CourseHistoryAnnouncements from '../components/CourseHistoryAnnouncements';
import { useTranslation } from 'react-i18next';

const AnnouncementsScreen = () => {
  const { t } = useTranslation();

  return (
    <>
      <Grid
        component={MainCard}
        style={{ padding: 2 }}
        sx={{
          borderTopLeftRadius: '0px',
          borderTopRightRadius: '0px',
        }}
      >
        <Grid sx={{ mb: 3 }}>
          <Typography variant="h2">{t('teacher_virtual_classroom.announcements.title')}</Typography>
        </Grid>
        <CourseHistoryAnnouncements />
      </Grid>
    </>
  );
};

export default AnnouncementsScreen;
