import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import ConfirmAlert from '../../../shared/components/dialogs/ConfirmAlert';
import { useEditParameterCompanyMutation } from '../slices/parameterCompanyApiSlice';
import { setIsOpenModalEdit, setPcIdSelected } from '../slices/parameterCompanySlice';

const ConfirmChangeValueDialog = () => {
  const dispatch = useAppDispatch();
  const { parametersCompanyData, pcIdSelected } = useAppSelector((state) => state.parameterCompany);

  const parameter = parametersCompanyData.find((p) => p.id === pcIdSelected);

  const [editPM, { isLoading, isSuccess, isError, error }] = useEditParameterCompanyMutation();

  const handleEdit = async () => {
    if (parameter) {
      await editPM({
        parameterId: pcIdSelected!,
        body: {
          cp_keyword: parameter.cp_keyword,
          value: Number(!Boolean(+parameter.value)).toString(),
          descripcion: parameter.descripcion,
          cp_module: parameter.cp_module,
          cp_name: parameter.cp_name,
        },
      });
    }
  };

  const handleClose = () => {
    dispatch(setPcIdSelected(null));
    dispatch(setIsOpenModalEdit(false));
  };

  return (
    <ConfirmAlert
      error={error as string}
      title="¿Desea cambiar el estado del valor?"
      subtitle={`Valor actual: ${parameter?.value ? 'Activado' : 'Desactivado'}. Proceder a ${
        parameter?.value ? 'Desactivar' : 'Activar'
      }`}
      handleDelete={handleEdit}
      isError={isError}
      isLoading={isLoading}
      isSuccess={isSuccess}
      confirmBtnMessage="Confirmar"
      successMessage="Valor cambiado con éxito"
      open
      onClose={handleClose}
    />
  );
};

export default ConfirmChangeValueDialog;
