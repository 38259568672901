import { Grid, Skeleton, Typography } from '@material-ui/core';
import { Module } from '../../interfaces/permissions1.interfaces';
import ModuleItem from './ModuleItem';

interface Props {
  modules: Module[];
  isLoading: boolean;
}

const ModulesList = ({ modules, isLoading }: Props) => {
  return (
    <Grid container mt={2} spacing={2}>
      {isLoading && <Skeleton variant="rectangular" width="100%" height={50} />}
      {!isLoading && modules?.length === 0 && (
        <Typography variant="h4" color="textSecondary">
          No hay módulos para este permiso
        </Typography>
      )}
      {!isLoading && modules?.length !== 0 && (
        <>
          {[...modules]
            ?.sort((a, b) => (a.principal ? -1 : 1) - (b.principal ? -1 : 1))
            .map((module) => (
              <Grid item xs={12} key={module.permission_id}>
                <ModuleItem module={module} />
              </Grid>
            ))}
        </>
      )}
    </Grid>
  );
};

export default ModulesList;
