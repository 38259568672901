import { Grid, Typography } from '@material-ui/core';
import MainCard from '../../../shared/components/cards/MainCard';

import DashboardSocialtray from '../components/Dashboard';

const DashboardSocialtrayScreen = () => {
    return (
        <Grid component={MainCard} sx={{ padding: 2 }}>
            <Grid sx={{ mb: 3 }}>
                <Typography variant="h2">CRM Dashboard</Typography>
                <Typography variant="h2" color="gray" fontWeight={400}>Resumen de Leads/Clientes</Typography>

                <DashboardSocialtray />

            </Grid>


        </Grid>
    );
}

export default DashboardSocialtrayScreen;