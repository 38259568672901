import { useState, useRef } from 'react';

import {
  Grid,
  Autocomplete,
  // FormHelperText,
  TextField,
  Button,
  CircularProgress,
  Typography,
  // Theme,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import { useFormik } from 'formik';

import InputLabel from '../../../shared/components/forms/InputLabel';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../hooks/useRedux';
import {
  useClassroomLoansMutation,
  useDeleteClassroomLoansMutation,
  useGetClassroomQuery,
  useGetClassroomsLoansQuery,
} from '../../classrooms/slices/classroomsApiSlice';

import { useGetHourhandsByPeriodQuery } from '../../periods/slices/periodsApiSlice';
import { ClassroomLoansSchema } from '../constants/validationSchemas';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
// import { LendingHourhands } from '../../classrooms/interfaces/classrooms.interface';
import DeleteButton from '../../../shared/components/tables/buttons/DeleteButton';

interface Props {
  collId: number;
  periodId: number;
  // unitAcademi: string;
  offerId: number;
  handleCloseDialog: (e: React.SyntheticEvent) => void;
}

const ClassroomLoansAssignForm = ({ collId, handleCloseDialog, periodId, offerId }: Props) => {
  const { t } = useTranslation();

  const num = (index: number) => ++index;

  const alertRef = useRef<HTMLDivElement>(null);

  const [showAlerts, setshowAlerts] = useState(true);

  const { currentProfile } = useAppSelector((state) => state.access);

  const { data: dataClassroom, isLoading: classLoading } = useGetClassroomQuery({
    profileId: currentProfile?.profileId!,
    classroomId: collId,
  });

  const { data: dataHourhands, isLoading: hourhandsLoading } = useGetHourhandsByPeriodQuery({
    profileId: currentProfile?.profileId!,
    periodId: periodId,
  });

  const { data: dataClassLoans, isLoading: loadingClassLoans } = useGetClassroomsLoansQuery({
    profileId: currentProfile?.profileId!,
    classroomId: dataClassroom?.id!,
    periodId: periodId,
    offerId: offerId,
    // classroom_education_level[0].education_level_id
    educationLevelId: dataClassroom?.classroom_education_level[0]?.education_level_id!,
    campusId: dataClassroom?.campus?.id!,
  });

  const [deleteHourhand, { isSuccess: isSucessHour, error: errorHour, isError: isErrorHour }] =
    useDeleteClassroomLoansMutation();

  const handleDelete = (id: number) => {
    console.log(id);
    deleteHourhand({
      profileId: currentProfile?.profileId!,
      lendingHourhand: id,
    });
  };

  // const dataHours = useMemo(
  //   () =>
  //     Array.isArray(dataClassLoans) &&
  //     dataClassLoans.reduce<LendingHourhands[]>((acc, curr) => {
  //       curr.lending_hourhands?.map((data) => {
  //         acc.push(data);
  //         return acc;
  //       });
  //       return acc;
  //     }, [] as LendingHourhands[]),
  //   [dataClassLoans]
  // );

  // console.log(dataHours);

  const [classroomLoans, { isLoading: classLoansLoading, isError, error, isSuccess }] =
    useClassroomLoansMutation();

  const { getFieldProps, handleSubmit, setFieldValue, errors } = useFormik({
    initialValues: {
      name: dataClassroom?.cl_name!,
      campus_id: dataClassroom?.campus?.id!,
      education_level_id: dataClassroom?.classroom_education_level[0].education_level_id,
      type: dataClassroom?.classroom_type.clt_name!,
      array_hourhand_id: [],
      classroom_id: dataClassroom?.id!,
      period_id: periodId,
    },
    validationSchema: ClassroomLoansSchema,
    onSubmit: async (values) => {
      await classroomLoans({
        profileId: currentProfile?.profileId!,
        classroomPayload: {
          classroom_id: values.classroom_id,
          period_id: values.period_id,
          offer_id: offerId,
          education_level_id: values.education_level_id,
          campus_id: values.campus_id,
          array_hourhand_id: values.array_hourhand_id,
        },
      });
      setshowAlerts(true);
    },
  });

  return classLoading && hourhandsLoading && loadingClassLoans ? (
    <Grid item xs={12} sx={{ mt: 5 }} container justifyContent="center" alignItems="center">
      <CircularProgress size={30} />
    </Grid>
  ) : (
    <Grid item xs={12}>
      <form onSubmit={handleSubmit}>
        <Grid container direction="column" gap={2}>
          <Grid ref={alertRef} item xs={12}>
            {isError && showAlerts && errors && errorHour && isErrorHour && (
              <ErrorAlert message={error as string} handleDismiss={() => setshowAlerts(false)} />
            )}

            {isSuccess && showAlerts && isSucessHour && (
              <SuccessAlert
                message={'Prestamo de aula realizado.'}
                handleDismiss={() => setshowAlerts(false)}
              />
            )}
          </Grid>
          {/* <pre>
          {JSON.stringify({errors},null,2)}
        </pre> */}
          {/* Name academic */}
          <Grid item xs={12}>
            <InputLabel>Unidad académica</InputLabel>
            <TextField
              disabled
              fullWidth
              defaultValue={dataClassroom?.cl_name!}
              placeholder="Nombre del curso"
              {...getFieldProps('name')}
            />
          </Grid>
          {/* Name classroom */}
          <Grid item xs={12}>
            <InputLabel>Aula</InputLabel>
            <TextField
              disabled
              fullWidth
              defaultValue={dataClassroom?.cl_name!}
              placeholder="Nombre del curso"
              {...getFieldProps('name')}
            />
          </Grid>
          {/* Description */}
          <Grid item xs={12}>
            <InputLabel>Tipo</InputLabel>
            <TextField
              disabled
              fullWidth
              defaultValue={dataClassroom?.classroom_type.clt_name!!}
              placeholder="tipo"
              {...getFieldProps('type')}
            />
          </Grid>
          {/* date of Classroomloans */}
          <Grid item xs={12}>
            <InputLabel>Fecha de préstamos por periodo</InputLabel>
            <Autocomplete
              multiple
              options={Array.isArray(dataHourhands) ? dataHourhands : []}
              getOptionLabel={(option) => option?.hour_description}
              onChange={(event, newValue) => {
                setFieldValue(
                  'array_hourhand_id',
                  newValue.map((v) => v.id)
                );
                console.log(event);
                console.log(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={t('Seleccione la fecha de prestamo')}
                  fullWidth
                />
              )}
            />
          </Grid>

          {/* date of Classroomloans */}

          {/* {dataClassLoans?.length! >= 1 && (
            <Grid item xs={12}>
              <Typography>Horarios de prestamos</Typography>
              {dataClassLoans?.map((data) => {
                return data?.lending_hourhands.map((hours) => {
                  return (
                    <Grid>
                      <ListItem>
                        <Chip
                          className={classes.classLoans}
                          deleteIcon={<DeleteButton />}
                          label={hours?.hourhand.hour_description}
                          onDelete={handleDelete}
                          onClick={() => {
                            console.log(hours);
                          }}
                        />
                      </ListItem>
                    </Grid>
                  );
                });
              })}
            </Grid>
          )} */}

          <Grid item xs={12}>
            <InputLabel>Fecha de préstamos</InputLabel>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>Horario</TableCell>
                    <TableCell>Opciones</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!dataClassLoans?.length ? (
                    <TableRow>
                      <TableCell colSpan={5}>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          component="p"
                          textAlign="center"
                        >
                          {t('Esta aula no tiene préstamos vinculados')}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ) : (
                    <>
                      {dataClassLoans?.map((dataH) => {
                        return dataH?.lending_hourhands.map((hours, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell align="left" width="20%">
                                {num(index)}
                              </TableCell>
                              <TableCell align="left" width="50%">
                                {hours?.hourhand.hour_description}
                              </TableCell>
                              <TableCell align="center" width="50%">
                                <DeleteButton
                                  handleDelete={() => {
                                    handleDelete(hours?.id);
                                  }}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        });
                      })}
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>

        <Grid
          item
          container
          alignItems="center"
          justifyContent="flex-end"
          spacing={2}
          sx={{ mt: 1 }}
        >
          <Grid item>
            <Button variant="outlined" size="large" color="primary" onClick={handleCloseDialog}>
              {t('shared.cancel_button.label')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              disabled={classLoading || classLoansLoading}
              endIcon={classLoading && <CircularProgress size={20} />}
            >
              {t('shared.save_button.label')}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

export default ClassroomLoansAssignForm;
