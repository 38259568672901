import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import MainCard from '../../../../../shared/components/cards/MainCard';
import TemplateTable from '../components/TemplateTable';

const TemplateScreen = () => {
  const { t } = useTranslation();

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">{t('Formatos de rúbricas')}</Typography>
        <Typography variant="body1" color="textSecondary">
          {t(
            'El formato es el contenido de la evaluación y se compone de las secciones, preguntas y rúbricas.'
          )}
        </Typography>
      </Grid>
      <TemplateTable />
    </Grid>
  );
};

export default TemplateScreen;
