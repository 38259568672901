import { Button, Stack, Typography } from '@material-ui/core';

import { IconDownload } from '@tabler/icons';
import useGetUrlDownload from '../../../../../hooks/useGetUrlDownload';
import { useTranslation } from 'react-i18next';

interface Props {
  title: string;
  urlFile: string;
}

const AccordionTitleBusesSchedules = ({ title, urlFile }: Props) => {
  const { t } = useTranslation();
  const { urlDownload } = useGetUrlDownload();

  const clickHandler = (e: any) => {
    e.stopPropagation();

    window.open(`${urlDownload}${urlFile}`, '_blank');
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      spacing={2}
      pr={2}
      width={'100%'}
    >
      <Typography variant="h3" component="div" /* sx={{ mb: 3 }} */>
        {t(title)}
      </Typography>
      <Button variant="contained" onClick={clickHandler} endIcon={<IconDownload size={20} />}>
        {t('academic.buses.shared.view_doc_pdf.btn')}
      </Button>
    </Stack>
  );
};

export default AccordionTitleBusesSchedules;
