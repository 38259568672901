import { mainApi } from '../../../../api/mainApi';
import { AccessState } from '../../../../types';
import { middlewareApi } from '../../../middleware/middlewareApi';
import { CourseStudent } from '../interface/assignGrade.interface';

export const assignGradeTags = middlewareApi.enhanceEndpoints({
  addTagTypes: [],
});

export const assignGradeApi = assignGradeTags.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * @PUT  actualiza la nota y motivo de aprobacion del estudiante en studentCourse
     */
    updateCourseStudentNote
    : builder.mutation<
      CourseStudent,
      { payloadCourse: Partial<CourseStudent>; courseStudentId?: string }
    >({
      queryFn: async ({ payloadCourse, courseStudentId }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };
          const user_profile_id = currentProfile?.profileId;
          const { data } = await mainApi.put<CourseStudent>(
            `/course-students/${courseStudentId}/update-final-note`,
            {
              user_profile_id,
              ...payloadCourse,
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'StatusSubjects', id: 'LIST' }],
    }),
  }),
});

export const { useUpdateCourseStudentNoteMutation } = assignGradeApi;
