import { useEffect, useState } from 'react';
import {
  Autocomplete,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { IEvaluationType } from '../interfaces/evaluationType.interface';
import { evaluationTypeFormSchema } from '../validation/evaluationType.validation';
import {
  useAddEvaluationTypeMutation,
  useGetGroupsTypesAllQuery,
  useUpdateEvaluationTypeMutation,
} from '../slices/evaluationTypeApiSlice';
import { useAppSelector } from '../../../../../../hooks/useRedux';
import SuccessAlert from '../../../../../shared/components/alerts/SuccessAlert';
import ErrorAlert from '../../../../../shared/components/alerts/ErrorAlert';

const EvaluationTypeForm = () => {
  const { t } = useTranslation();
  const [showAlerts, setShowAlerts] = useState(false);

  const [addEvaluationType, { isLoading, isError, error, isSuccess }] =
    useAddEvaluationTypeMutation();

  const { data: groupsTypes } = useGetGroupsTypesAllQuery({});

  const [
    updateEvaluationType,
    { isLoading: isLoadingU, isError: isErrorU, error: errorU, isSuccess: isSuccessU },
  ] = useUpdateEvaluationTypeMutation();

  const { evaluationTypeList, evaluationTypeIdSelected } = useAppSelector(
    (state) => state.evaluationType
  );

  const evaluationTypeSelected = evaluationTypeList.find(
    (option) => option.uuid === evaluationTypeIdSelected
  );

  const statusOptions = [
    { value: 'A', label: t('maintenance.scopes.status.active') },
    { value: 'I', label: t('maintenance.scopes.status.inactive') },
  ];

  const { errors, getFieldProps, handleSubmit, touched, resetForm, setFieldValue } =
    useFormik<IEvaluationType>({
      initialValues: {
        name: evaluationTypeSelected?.name || '',
        status: evaluationTypeSelected?.status || 'A',
        description: evaluationTypeSelected?.description || '',
        acronym: evaluationTypeSelected?.acronym || '',
        eva_group_type_id: evaluationTypeSelected?.eva_group_type_id || '',
      },
      validationSchema: evaluationTypeFormSchema,
      onSubmit: async (currentValues) => {
        if (evaluationTypeIdSelected) {
          await updateEvaluationType({
            id: evaluationTypeIdSelected,
            name: currentValues.name,
            status: currentValues.status,
            description: currentValues.description,
            acronym: currentValues.acronym,
            eva_group_type_id: currentValues.eva_group_type_id,
          });
        } else {
          await addEvaluationType({
            name: currentValues.name,
            status: currentValues.status,
            description: currentValues.description,
            acronym: currentValues.acronym,
            eva_group_type_id: currentValues.eva_group_type_id,
          });
        }
        setShowAlerts(true);
      },
    });

  useEffect(() => {
    if (isSuccess) {
      resetForm();
    }
  }, [isSuccess, resetForm]);

  return (
    <Grid container spacing={2}>
      {(isSuccess || isSuccessU) && showAlerts && (
        <Grid item xs={12}>
          <SuccessAlert
            message={
              isSuccess
                ? t('maintenance.evaluation_type.add.success')
                : t('maintenance.evaluation_type.edit.success')
            }
            handleDismiss={() => setShowAlerts(false)}
          />
        </Grid>
      )}
      {(isError || isErrorU) && showAlerts && (
        <Grid item xs={12}>
          <ErrorAlert
            message={(error as string) || (errorU as string)}
            handleDismiss={() => setShowAlerts(false)}
          />
        </Grid>
      )}

      <Grid item xs={12}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputLabel>{t('maintenance.evaluation_type.form.name')}</InputLabel>
              <TextField
                placeholder={t('maintenance.evaluation_type.form.name.placeholder')}
                error={Boolean(errors.name && touched.name)}
                {...getFieldProps('name')}
                fullWidth
              />
              {errors.name && touched.name && (
                <FormHelperText error>{t(errors.name)}</FormHelperText>
              )}
            </Grid>
            <Grid item xs={12}>
              <InputLabel>{t('maintenance.evaluation_type.form.description')}</InputLabel>
              <TextField
                placeholder={t('maintenance.evaluation_type.form.description.placeholder')}
                error={Boolean(errors.description && touched.description)}
                {...getFieldProps('description')}
                fullWidth
              />
              {errors.description && touched.description && (
                <FormHelperText error>{t(errors.description)}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12}>
              <InputLabel>{t('maintenance.evaluation_type.form.acronym')}</InputLabel>
              <TextField
                placeholder={t('maintenance.evaluation_type.form.acronym.placeholder')}
                error={Boolean(errors.acronym && touched.acronym)}
                {...getFieldProps('acronym')}
                fullWidth
              />
              {errors.acronym && touched.acronym && (
                <FormHelperText error>{t(errors.acronym)}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12}>
              <InputLabel>{t('Tipo de grupo')}</InputLabel>
              <Autocomplete
                options={Array.isArray(groupsTypes) ? groupsTypes : []}
                getOptionLabel={(option) => option.name}
                renderOption={(props, option) => (
                  <>
                    {option.keyword !== 'EGU' ? (
                      <li {...props}>
                        <Grid item xs={12}>
                          {option.name}
                        </Grid>
                      </li>
                    ) : (
                      <li {...props}>
                        <Grid item xs={12}>
                          {`${option.name} - Opción no disponible`}
                        </Grid>
                      </li>
                    )}
                  </>
                )}
                onChange={(event, value) => {
                  if (value?.keyword !== 'EGU') {
                    setFieldValue('eva_group_type_id', value?.id);
                  } else {
                    setFieldValue('eva_group_type_id', '');
                  }
                }}
                value={
                  groupsTypes?.find(
                    (option) =>
                      Number(option.id) === Number(getFieldProps('eva_group_type_id').value)
                  ) || null
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={t('Seleccione un tipo de grupo')}
                    fullWidth
                    error={Boolean(errors.eva_group_type_id && touched.eva_group_type_id)}
                  />
                )}
              />
              {errors.eva_group_type_id && touched.eva_group_type_id && (
                <FormHelperText error>{t(errors.eva_group_type_id)}</FormHelperText>
              )}
            </Grid>
            <Grid item xs={12}>
              <InputLabel>{t('maintenance.scopes.form.status')}</InputLabel>
              <Autocomplete
                options={statusOptions}
                onChange={(event, value) => {
                  setFieldValue('status', value?.value);
                }}
                value={statusOptions.find(
                  (option) => option.value === getFieldProps('status').value
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={t('maintenance.scopes.form.status.placeholder')}
                    fullWidth
                  />
                )}
              />
              {errors.status && touched.status && (
                <FormHelperText error>{t(errors.status)}</FormHelperText>
              )}
            </Grid>

            <Grid item container xs={12} justifyContent="flex-end" spacing={2}>
              <Grid item>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  disabled={isLoading || isLoadingU}
                  endIcon={
                    isLoading || (isLoadingU && <CircularProgress size={12} color="primary" />)
                  }
                >
                  {!evaluationTypeIdSelected
                    ? t('maintenance.evaluation_type.add.submit')
                    : t('maintenance.evaluation_type.edit.submit')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default EvaluationTypeForm;
