import { ChangeEvent, useState, useEffect } from 'react';

import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  makeStyles,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { useGetUseTypesQuery } from '../../../maintenance/maintenance-product/slices/productApiSlice';
import { Child } from '../../../maintenance/maintenance-product/interfaces/usetypes.interface';
import { IconFileCheck, IconMailForward, IconSearch } from '@tabler/icons';
import downloadjs from 'downloadjs';

import {
  useElectronicDocumentsFilterQuery,
  useLazyGetMassiveSriZipQuery,
} from '../slices/electronicDocumentsApiSlice';
import { useFormik } from 'formik';
import { electronicDocumentValues } from '../constants/formConstants';
import SearchPaginatedTable from '../../../shared/components/tables/SearchPaginatedTable';
import {
  openResendModalWith,
  openUpdateModalWith,
  updateElectronicDocumentsTable,
} from '../slices/electronicDocumentsSlice';
import { ElectronicDocumentPayload } from '../interfaces/electronicDocuments.interfaces';
import { DownloadRIDEDocument } from './DownloadRIDEDocument';
import { DownloadXMLDocument } from './DownloadXMLDocument';
import { useTableSearch } from '../../../../hooks/useTableSearch';
import LocalizedDatePicker from '../../../shared/components/controlled/LocalizedDatePicker';
import LoadingButton from '../../../shared/components/buttons/LoadingButton';
import { format } from 'date-fns';
const useStyles = makeStyles((theme) => ({
  searchBlock: {
    border: '3px solid',
    borderColor: theme.palette.primary.light,
    width: '100%',
  },
  cardHeader: {
    backgroundColor: theme.palette.primary.light,
    paddingBottom: 2,
    paddingTop: 2,
  },
  labelTypo: {
    fontWeight: 'bold',
    textAlign: 'right',
    width: '100%',
    marginRight: 5,
  },
}));
const ElectronicDocumentTable = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { currentProfile } = useAppSelector((state) => state.access);
  const { page, perPage, search, filterArrow } = useAppSelector(
    (state) => state.electronicDocuments.electronicDocumentsTable
  );

  const { setPage, setPerPage, setSearch, setFilterArrow } = useTableSearch({
    page,
    perPage,
    search,
    filterArrow,
    updateFunction: updateElectronicDocumentsTable,
  });

  const [filterShowed, setFilterShowed] = useState(false);
  const [useTypesData, setUseTypesData] = useState<Child[]>([]);
  const [useElectronicDocumentsState, setUseElectronicDocumentsState] = useState<Child[]>([]);
  const [noDocument, setNoDocument] = useState('');

  const [electronicDocumentPayload, setElectronicDocumentPayload] =
    useState<Partial<ElectronicDocumentPayload> | null>(null);
  //const [showResult, setShowResult] = useState(false);

  const handleUpdate = async (electronicDocumentIdUpdate: number) => {
    dispatch(openUpdateModalWith(electronicDocumentIdUpdate));
  };

  const handleResend = async (electronicDocumentIdUpdate: number) => {
    dispatch(openResendModalWith(electronicDocumentIdUpdate));
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFilterShowed(e.target.checked);
  };

  //get all type documents
  const { data: useTypes, isLoading: isLoadingT } = useGetUseTypesQuery({
    profileId: currentProfile?.profileId!,
    acronym: 'tce',
  });

  //get all electronic documents states
  const { data: electronicDocumentsStates, isLoading: isLoadingEDS } = useGetUseTypesQuery({
    profileId: currentProfile?.profileId!,
    acronym: 'SDE',
  });

  // get zip file massive documents
  const [getZipFile, { isLoading: isLoadingZipFile }] = useLazyGetMassiveSriZipQuery();

  const {
    isLoading,
    isFetching,
    refetch: electronicDocReload,
    data,
    error,
  } = useElectronicDocumentsFilterQuery(
    {
      page,
      perPage,
      search,
      electronicDocumentPayload: {
        ...electronicDocumentPayload,
      },
      ...(filterArrow && {
        sort: filterArrow.name,
        type_sort: filterArrow.direction,
      }),
    },
    { refetchOnMountOrArgChange: 30, skip: electronicDocumentPayload === null }
  );

  const { handleSubmit, values, getFieldProps, setFieldValue } = useFormik({
    initialValues: electronicDocumentValues,
    //validationSchema: electronicDocumentSchema,
    onSubmit: async (value) => {
      const electronicDoc: Partial<ElectronicDocumentPayload> = {
        search: values.search,
        no_document: noDocument,
        status_document: values.status_document,
        status_payment: values.status_payment,
        fechaEmision: values.fechaEmision,
        fechaPagoDocumento: values.fechaPagoDocumento,
        fechaAutorizacionSRI: values.fechaAutorizacionSRI,
        type_document: values.type_document,
      };
      if (JSON.stringify(electronicDoc) === JSON.stringify(electronicDocumentPayload)) {
        electronicDocReload();
      } else {
        setElectronicDocumentPayload(electronicDoc);
      }
    },
  });

  const handleDownloadZipFile = async () => {
    try {
      const datatest = await getZipFile({
        start_date: format(values.fecha_emision_ini!, 'yyyy-MM-dd'),
        end_date: format(values.fecha_emision_fin!, 'yyyy-MM-dd'),
        type_document: values.type_document,
      }).unwrap();

      downloadjs(
        datatest,
        `documentos-${format(values.fecha_emision_ini!, 'yyyyMMddHHmm')}${format(
          values.fecha_emision_fin!,
          'yyyyMMddHHmm'
        )}.zip`,
        'application/zip'
      );
    } catch (error) {
      console.log(error);
    }
  };

  const datafetch = data?.data!.map((d, index) => ({
    id: index + (page - 1) * perPage + 1,
    code: d.id + ',' + d.establecimiento + '-' + d.ptoEmision + '-' + d.secuencial,
    person: d.person?.pers_firstname + ' ' + d.person?.pers_first_lastname,
    tipo: d.tipoDocument,
    totalDoc: Number(d.importeTotal).toFixed(2),
    DocState: d.sri_estado_documento,
    EmissionDate: d.fechaEmision,
    options: d,
  }));

  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | undefined) => {
    let inputNoDocument = e?.target?.value!;
    inputNoDocument = inputNoDocument?.replace(/\D/g, '');
    if (inputNoDocument?.length > 3) {
      //setNoDocument(inputNoDocument);
      inputNoDocument = inputNoDocument?.replace(/.{3}/, '$&-');
    }
    if (inputNoDocument?.length > 7) {
      //setNoDocument(inputNoDocument);
      inputNoDocument = inputNoDocument?.replace(/.{7}/, '$&-');
    }
    if (inputNoDocument?.length < 18) setNoDocument(inputNoDocument);
  };

  const handleDate = (field: string, fecha_ini: Date | null, fecha_fin: Date | null) => {
    let fecha_ini_string = '';
    let fecha_fin_string = '';
    if (fecha_ini)
      fecha_ini_string =
        fecha_ini.getFullYear() + '-' + (fecha_ini.getMonth() + 1) + '-' + fecha_ini.getDate();
    if (fecha_fin)
      fecha_fin_string =
        fecha_fin.getFullYear() + '-' + (fecha_fin.getMonth() + 1) + '-' + fecha_fin.getDate();
    setFieldValue(field, '["' + fecha_ini_string + '","' + fecha_fin_string + '"]');
  };

  useEffect(() => {
    if (useTypes) {
      const data =
        useTypes?.children?.filter(
          (dt) => dt.cat_keyword === 'fact' || dt.cat_keyword === 'nota-cred'
        ) || [];
      setUseTypesData(data);
    }
  }, [useTypes]);

  useEffect(() => {
    if (electronicDocumentsStates) {
      const data = electronicDocumentsStates?.children || [];
      setUseElectronicDocumentsState(data);
    }
  }, [electronicDocumentsStates]);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={3} alignContent="flex-start" mt={1}>
        <Grid item xs={12} container spacing={2}>
          <Card className={classes.searchBlock}>
            <CardHeader
              avatar={<IconSearch />}
              title={t('financial.electronic_docs.filters.title')}
              className={classes.cardHeader}
            />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={6} container spacing={2}>
                  {/* container */}
                  <Grid item xs={12} container>
                    <Grid item xs={12} md={3} xl={4} container sx={{ alignContent: 'center' }}>
                      <Typography variant="h5" className={classes.labelTypo}>
                        {t('financial.electronic_docs.filters.dates.emission_label')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={9} xl={8} container>
                      <Grid item xs={12}>
                        <Grid container spacing={1}>
                          <Grid item xs={12} sm={6} md={6} lg={6}>
                            <Grid
                              container
                              spacing={1}
                              alignItems="flex-start"
                              alignContent="center"
                            >
                              <Grid item xs zeroMinWidth>
                                <LocalizedDatePicker
                                  value={values.fecha_emision_ini}
                                  onChange={(newValue) => {
                                    setFieldValue('fecha_emision_ini', newValue);
                                    handleDate(
                                      'fechaEmision',
                                      newValue as Date,
                                      values.fecha_emision_fin
                                    );
                                  }}
                                  label={t('financial.electronic_docs.filters.dates.start_label')}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sm={6} md={6} lg={6}>
                            <Grid container spacing={1} alignItems="flex-start">
                              <Grid item xs zeroMinWidth>
                                <LocalizedDatePicker
                                  value={values.fecha_emision_fin}
                                  onChange={(newValue) => {
                                    setFieldValue('fecha_emision_fin', newValue);
                                    handleDate(
                                      'fechaEmision',
                                      values.fecha_emision_ini,
                                      newValue as Date
                                    );
                                  }}
                                  label={t('financial.electronic_docs.filters.dates.end_label')}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {filterShowed && (
                    <>
                      {/*container*/}
                      <Grid item xs={12} container>
                        <Grid item xs={12} md={3} xl={4} container sx={{ alignContent: 'center' }}>
                          <Typography variant="h5" className={classes.labelTypo}>
                            {t('financial.electronic_docs.filters.doc_number_label')}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={9} xl={8} container>
                          <TextField
                            fullWidth
                            //error={Boolean(errors.to && touched.to)}
                            placeholder={t(
                              'financial.electronic_docs.filters.doc_number_placeholder'
                            )}
                            value={noDocument}
                            onChange={(
                              e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | undefined
                            ) => {
                              onChange(e);
                            }}
                            //{...getFieldProps('to')}
                          />
                        </Grid>
                      </Grid>
                      {/*container*/}
                      <Grid item xs={12} container>
                        <Grid item xs={12} md={3} xl={4} container sx={{ alignContent: 'center' }}>
                          <Typography variant="h5" className={classes.labelTypo}>
                            {t('financial.electronic_docs.filters.person_label')}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={9} xl={8} container>
                          <TextField
                            fullWidth
                            //error={Boolean(errors.to && touched.to)}
                            placeholder={t('financial.electronic_docs.filters.person_placeholder')}
                            {...getFieldProps('search')}
                          />
                        </Grid>
                      </Grid>
                      {/*container*/}
                      <Grid item xs={12} container>
                        <Grid item xs={12} md={3} xl={4} container sx={{ alignContent: 'center' }}>
                          <Typography variant="h5" className={classes.labelTypo}>
                            {t('financial.electronic_docs.filters.dates.payment_label')}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={9} xl={8} container>
                          <Grid item xs={12}>
                            <Grid container spacing={1}>
                              <Grid item xs={12} sm={6} md={6} lg={6}>
                                <Grid container spacing={1} alignItems="flex-start">
                                  <Grid item xs zeroMinWidth>
                                    <LocalizedDatePicker
                                      value={values.fecha_pago_ini}
                                      onChange={(newValue) => {
                                        setFieldValue('fecha_pago_ini', newValue);
                                        handleDate(
                                          'fechaPagoDocumento',
                                          newValue as Date,
                                          values.fecha_pago_ini
                                        );
                                      }}
                                      label={t(
                                        'financial.electronic_docs.filters.dates.start_label'
                                      )}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={12} sm={6} md={6} lg={6}>
                                <Grid container spacing={1} alignItems="flex-start">
                                  <Grid item xs zeroMinWidth>
                                    <LocalizedDatePicker
                                      value={values.fecha_pago_fin}
                                      onChange={(newValue) => {
                                        setFieldValue('fecha_pago_fin', newValue);
                                        handleDate(
                                          'fechaPagoDocumento',
                                          values.fecha_pago_ini,
                                          newValue as Date
                                        );
                                      }}
                                      label={t('financial.electronic_docs.filters.dates.end_label')}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Grid>
                <Grid item xs={12} md={12} lg={6} container spacing={2}>
                  {/* container */}
                  <Grid item xs={12} container>
                    <Grid item xs={12} md={3} xl={4} container sx={{ alignContent: 'center' }}>
                      <Typography variant="h5" className={classes.labelTypo}>
                        {t('financial.electronic_docs.filters.voucher_label')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={9} xl={8} container>
                      <Autocomplete
                        options={useTypesData}
                        loading={isLoadingT}
                        getOptionLabel={(option) => option.cat_name}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            //error={Boolean(errors.tipo_comprobante && touched.tipo_comprobante)}
                            placeholder={t('financial.electronic_docs.filters.voucher_placeholder')}
                          />
                        )}
                        onChange={(_, value) => {
                          setFieldValue('type_document', value?.id!);
                        }}
                        fullWidth
                        clearIcon={null}
                      />
                    </Grid>
                    {/* {errors.tipo_comprobante && touched.tipo_comprobante && (
                        <FormHelperText error>{errors.tipo_comprobante}</FormHelperText>
                      )} */}
                  </Grid>
                  {filterShowed && (
                    <>
                      {/* container */}
                      <Grid item xs={12} container>
                        <Grid item xs={12} md={3} xl={4} container sx={{ alignContent: 'center' }}>
                          <Typography variant="h5" className={classes.labelTypo}>
                            {t('financial.electronic_docs.filters.dates.authorization_label')}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={9} xl={8} container>
                          <Grid item xs={12}>
                            <Grid container spacing={1}>
                              <Grid item xs={12} sm={6} md={6} lg={6}>
                                <Grid container spacing={1} alignItems="flex-start">
                                  <Grid item xs zeroMinWidth>
                                    <LocalizedDatePicker
                                      value={values.fecha_autorizacion_ini}
                                      onChange={(newValue) => {
                                        setFieldValue('fecha_autorizacion_ini', newValue);
                                        handleDate(
                                          'fechaAutorizacionSRI',
                                          newValue as Date,
                                          values.fecha_autorizacion_fin
                                        );
                                      }}
                                      label={t(
                                        'financial.electronic_docs.filters.dates.start_label'
                                      )}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={12} sm={6} md={6} lg={6}>
                                <Grid container spacing={1} alignItems="flex-start">
                                  <Grid item xs zeroMinWidth>
                                    <LocalizedDatePicker
                                      value={values.fecha_autorizacion_fin}
                                      onChange={(newValue) => {
                                        setFieldValue('fecha_autorizacion_fin', newValue);
                                        handleDate(
                                          'fechaAutorizacionSRI',
                                          values.fecha_autorizacion_ini,
                                          newValue as Date
                                        );
                                      }}
                                      label={t('financial.electronic_docs.filters.dates.end_label')}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      {/* container */}
                      <Grid item xs={12} container>
                        <Grid item xs={12} md={3} xl={4} container sx={{ alignContent: 'center' }}>
                          <Typography variant="h5" className={classes.labelTypo}>
                            {t('financial.electronic_docs.filters.payment_status_label')}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={9} xl={8} container>
                          <Autocomplete
                            options={[
                              {
                                id: 'Pagado',
                                label: t('financial.electronic_docs.filters.payment_status.paid'),
                              },
                              {
                                id: 'Por Pagar',
                                label: t(
                                  'financial.electronic_docs.filters.payment_status.payable'
                                ),
                              },
                            ]}
                            //loading={isLoadingP}
                            getOptionLabel={(option) => option.label}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                //error={Boolean(errors.period_id && touched.period_id)}
                                placeholder={t(
                                  'financial.electronic_docs.filters.payment_status_placeholder'
                                )}
                              />
                            )}
                            onChange={(_, value) => {
                              setFieldValue('status_payment', value?.id!);
                            }}
                            fullWidth
                            clearIcon={null}
                          />
                        </Grid>
                      </Grid>
                      {/* container */}
                      <Grid item xs={12} container>
                        <Grid item xs={12} md={3} xl={4} container sx={{ alignContent: 'center' }}>
                          <Typography variant="h5" className={classes.labelTypo}>
                            {t('financial.electronic_docs.filters.doc_status_label')}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={9} xl={8} container>
                          <Autocomplete
                            options={useElectronicDocumentsState}
                            loading={isLoadingEDS}
                            getOptionLabel={(option) => option.cat_name}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                //error={Boolean(errors.period_id && touched.period_id)}
                                placeholder={t(
                                  'financial.electronic_docs.filters.doc_status_placeholder'
                                )}
                              />
                            )}
                            onChange={(_, value) => {
                              setFieldValue('status_document', value?.id!);
                            }}
                            fullWidth
                            clearIcon={null}
                          />
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid item xs={12} textAlign="right">
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={handleChange}
                        // defaultChecked={initialValues.hourSunday}
                        id="customSearch"
                      />
                    }
                    label={t('financial.electronic_docs.filters.advance_search_label')}
                    labelPlacement="end"
                  />

                  <LoadingButton
                    isLoading={isLoadingZipFile}
                    disabled={
                      isLoadingZipFile || !values.fecha_emision_ini || !values.fecha_emision_fin
                    }
                    onClick={handleDownloadZipFile}
                    sx={{ mr: 1 }}
                  >
                    {t('financial.electronic_docs.filters.massive_zip_download')}
                  </LoadingButton>

                  <Button
                    type="submit"
                    variant="contained"
                    //disabled={isLoading}
                    //endIcon={isLoading && <CircularProgress size={20} />}
                    //fullWidth
                  >
                    <IconSearch />
                  </Button>
                  {/* <Grid item xs={12} md={6} xl={6} container sx={{ alignContent: 'center' }}>
                      
                    </Grid>
                    <Grid item xs={12} md={6} xl={6} container>
                      
                    </Grid> */}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} container spacing={2}>
          <SearchPaginatedTable
            data={datafetch ? datafetch : []}
            headers={{
              id: 'N°',
              code: t('financial.electronic_docs.table.code'),
              person: t('financial.electronic_docs.table.person'),
              tipo: t('financial.electronic_docs.table.type'),
              totalDoc: t('financial.electronic_docs.table.total'),
              DocState: t('financial.electronic_docs.table.doc_status'),
              EmissionDate: t('financial.electronic_docs.table.emission_date'),
              options: t('shared.search_paginated_table.table.options'),
            }}
            listFilterArrows={{
              code: 'id',
              tipo: 'tipoEmision',
              DocState: 'sri_estado_documento',
              EmissionDate: 'fechaEmision',
            }}
            filterArrow={filterArrow}
            setFilterArrow={setFilterArrow}
            keyExtractor={(item) => item.id.toString()}
            searchPlacehoder={t('financial.electronic_docs.table.search_placeholder')}
            isLoading={isLoading}
            isFetching={isFetching}
            error={error}
            perPage={perPage}
            setPerPage={setPerPage}
            total={data?.total || 0}
            page={page}
            setPage={setPage}
            numHeader={7}
            search={search}
            setSearch={setSearch}
            showFilter={false}
            customRenderers={{
              code: (row) => row.code.split(',')[1],
              options: (row) => (
                <>
                  <Tooltip title={t('financial.electronic_docs.table.options.resend_tooltip')}>
                    <IconButton
                      onClick={() => {
                        handleResend(Number(row.code.split(',')[0]));
                      }}
                    >
                      <IconMailForward />
                    </IconButton>
                  </Tooltip>
                  {row.options.signed === '1' && (
                    <>
                      <DownloadRIDEDocument docId={Number(row.options.id)} />
                      <DownloadXMLDocument
                        docId={Number(row.options.id)}
                        docName={
                          row.options.establecimiento +
                          '-' +
                          row.options.ptoEmision +
                          '-' +
                          row.options.secuencial
                        }
                      />
                    </>
                  )}
                  {row.DocState !== 'AUTORIZADO' && row.DocState !== 'ANULADO' && (
                    <Tooltip
                      title={t('financial.electronic_docs.table.options.change_status_tooltip')}
                    >
                      <IconButton
                        onClick={() => {
                          handleUpdate(Number(row.code.split(',')[0]));
                        }}
                      >
                        <IconFileCheck />
                      </IconButton>
                    </Tooltip>
                  )}
                </>
              ),
            }}
            customHeadersCellsProperties={{
              id: {
                align: 'center',
              },
              code: {
                align: 'center',
              },
              person: {
                align: 'center',
              },
              DocState: {
                align: 'center',
              },
              EmissionDate: {
                align: 'center',
              },
              options: {
                align: 'center',
              },
            }}
            customDataCellsProperties={{
              id: {
                align: 'center',
              },
              code: {
                align: 'center',
              },
              tipo: {
                align: 'center',
              },
              totalDoc: {
                align: 'center',
              },
              person: {
                align: 'center',
              },
              DocState: {
                align: 'center',
              },
              EmissionDate: {
                align: 'center',
              },
              options: {
                align: 'center',
              },
            }}
          />
        </Grid>
      </Grid>
    </form>
  );
};
export default ElectronicDocumentTable;
