import { ButtonProps, IconButton, Tooltip } from '@material-ui/core';
import { IconClipboardList } from '@tabler/icons';

interface Props extends ButtonProps { }

const ListCollective = ({ onClick, ...props }: Props) => {
    return (
        <Tooltip title="Listar colectivos">
            <IconButton aria-label="Listar colectivos" color="default" onClick={onClick}>
                <IconClipboardList />
            </IconButton>
        </Tooltip>
    );
};

export default ListCollective;
