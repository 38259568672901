import * as Yup from 'yup';

export const PermissionInfoSchema = Yup.object().shape({
  name: Yup.string().required('configuration.permissions.form.name.required'),
  description: Yup.string(),
  alias: Yup.string().required('configuration.permissions.form.alias.required'),
  parentName: Yup.string().required('configuration.permissions.form.parent.required'),
  statusId: Yup.number().required('configuration.permissions.form.status.required'),
});

export const ModulePermissionSchema = Yup.object().shape({
  moduleId: Yup.number().required('El módulo es requerido').typeError('El módulo es requerido'),
});
