import * as yup from 'yup';

export const createCohortSchema = yup.object().shape({
  year: yup.string().required('El año es requerido'),
  cohortNumber: yup
    .number()
    .required('El número de cohorte es requerido')
    .min(1, 'El número de cohorte debe ser mayor a 0'),
  cantParallelCohort: yup
    .number()
    .required('La cantidad de paralelos es requerida')
    .min(1, 'La cantidad de paralelos debe ser mayor a 0'),
});
