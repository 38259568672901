import { Button, Grid } from '@material-ui/core';
import AddButton from '../../../../../shared/components/tables/buttons/AddButton';
import { useState } from 'react';
import ListStudentsAssign from '../lateral-dialogs/ListStudentsAssign';
import { ArrowBack } from '@material-ui/icons';
import AddCollaboratorProjectForm from './AddCollaboratorProjectForm';

interface Props {
  projectId: string;
}

const TeacherListAndForm = ({ projectId }: Props) => {
  const [viewForm, setViewForm] = useState(false);

  const goToAdd = () => {
    setViewForm(true);
  };

  return (
    <Grid
      sx={{
        width: '100%',
      }}
    >
      <Grid
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: `${viewForm ? 'flex-start' : 'flex-end'}`,
          alignItems: 'center',
          my: 2,
        }}
      >
        {viewForm ? (
          <Button
            variant="outlined"
            size="large"
            color="primary"
            onClick={() => setViewForm(false)}
          >
            <ArrowBack />
          </Button>
        ) : (
          <AddButton onClick={goToAdd} />
        )}
      </Grid>
      <Grid>
        {viewForm ? (
          <AddCollaboratorProjectForm projectId={projectId} />
        ) : (
          <ListStudentsAssign projectId={projectId} type="T" />
        )}
      </Grid>
    </Grid>
  );
};

export default TeacherListAndForm;
