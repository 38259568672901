import { mainApi } from '../../../../api/mainApi';
import { AccessState } from '../../../../types';
import { middlewareApi } from '../../../middleware/middlewareApi';
import {
  DebtorStudentData,
  PayBalance,
  PayMethodsStudent,
} from '../interfaces/debtsPayment.interface';
import { DebtPaymentPayload } from '../interfaces/payloads.interface';

export const paymentsTags = middlewareApi.enhanceEndpoints({
  addTagTypes: [
    'PaymentsDebtsHistory', //
    'PaymentsHistory', // historial de pago del estudiante
    // 'FinancialInformation', //
    'ClientPayMethods', // metodos de pago del estudiante
    'StudentsDebts', //deudas del estudiante
    'cashPoints', //cajas
  ],
});
// Define a service using a base URL and expected endpoints
export const paymentsApi = paymentsTags.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * @POST send checkout datafast
     */
    processPaymentCashierPoint: builder.mutation<
      any,
      { profileId: number; studentId?: number; datafastPayload: Partial<DebtPaymentPayload> }
    >({
      queryFn: async ({ profileId, studentId, datafastPayload }) => {
        try {
          const { data } = await mainApi.post<any>('/cash-collection', {
            user_profile_id: profileId,
            // student: studentId,
            ...datafastPayload,
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [
        { type: 'PaymentsHistory', id: 'LIST' },
        { type: 'PaymentsDebtsHistory', id: 'LIST' },
        { type: 'ClientPayMethods', id: 'LIST' },
        { type: 'StudentsDebts', id: 'LIST' }, //actualiza las deudas del estudiante
      ],
    }),

    /**
     * @GET student payment methods data
     * @Permission cash-collection-forma-pago
     */
    getPayMethodsByStudent: builder.query<
      Array<PayBalance | PayMethodsStudent>,
      { profileId: number; studentId: number }
    >({
      queryFn: async ({ profileId, studentId }) => {
        try {
          const { data } = await mainApi.get<Array<PayBalance | PayMethodsStudent>>(
            `/cash-collection/student/${studentId}/payment-ways/FP`,
            {
              params: {
                user_profile_id: profileId,
              },
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result, error, { profileId }) => [{ type: 'ClientPayMethods', id: 'LIST' }],
    }),

    /**
     * @GET debtor student data
     */
    getDebtorStudent: builder.query<
      DebtorStudentData,
      { profileId: number; debtorStudentId: number }
    >({
      queryFn: async ({ profileId, debtorStudentId }) => {
        try {
          const { data } = await mainApi.get<DebtorStudentData>(
            `/debtors/debtor-student/${debtorStudentId}`,
            {
              params: {
                user_profile_id: profileId,
              },
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) => [{ type: 'ClientPayMethods', id: 'LIST' }],
    }),

    changeDebtorByPersonId: builder.mutation<
      any,
      { profileId?: number; debtor: number; personId: number }
    >({
      queryFn: async ({ profileId, debtor, personId }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = profileId || currentProfile?.profileId;

          const { data } = await mainApi.put<any>(`/debtors/${debtor}`, {
            person_id: personId,
            user_profile_id,
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [
        { type: 'PaymentsHistory', id: 'LIST' },
        { type: 'PaymentsDebtsHistory', id: 'LIST' },
        { type: 'ClientPayMethods', id: 'LIST' },
        { type: 'StudentsDebts', id: 'LIST' }, //actualiza las deudas del estudiante
      ],
    }),

    //
  }),
});

export const {
  useProcessPaymentCashierPointMutation,
  useGetPayMethodsByStudentQuery,
  useGetDebtorStudentQuery,
  useChangeDebtorByPersonIdMutation,
} = paymentsApi;
