import { mainApi } from '../../../../api/mainApi';
import { AccessState } from '../../../../types';
import { middlewareApi } from '../../../middleware/middlewareApi';
import {
  CalificationModel,
  CalificationModelAvilable,
  CalificationModelsResponse,
} from '../interfaces/calificationModels.interface';
import { setCalificationModelList } from './calificationModelsSlice';

export const calificationModelsTags = middlewareApi.enhanceEndpoints({
  addTagTypes: ['CalificationModels'],
});

export const calificationModelsApi = calificationModelsTags.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * @GET all calificationModels
     */
    getCalificationModels: builder.query<
      CalificationModelsResponse,
      {
        profileId: number;
        page: number;
        perPage: number;
        search: string;
        sort?: string;
        type_sort?: string;
      }
    >({
      queryFn: async ({ profileId, page, search, perPage, sort, type_sort }, { dispatch }) => {
        try {
          const { data } = await mainApi.get<CalificationModelsResponse>('/calification-models', {
            params: {
              user_profile_id: profileId,
              page,
              size: perPage,
              type_sort: type_sort || 'desc',
              ...(sort && { sort }),
              search,
            },
          });

          dispatch(setCalificationModelList(data.data));

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'CalificationModels', id } as const)),
              { type: 'CalificationModels', id: 'LIST' },
            ]
          : [{ type: 'CalificationModels', id: 'LIST' }],
    }),

    /**
     * @GET calificationModel by id
     */
    getCalificationModel: builder.query<
      CalificationModel,
      { profileId?: number; calificationModelId: number }
    >({
      queryFn: async ({ profileId, calificationModelId }, { dispatch, getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };
          const user_profile_id = profileId || currentProfile?.profileId;

          const { data } = await mainApi.get<CalificationModel>(
            `/calification-model/${calificationModelId}`,
            {
              params: {
                user_profile_id,
              },
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result, error, { profileId }) => [{ type: 'CalificationModels', profileId }],
    }),

    /**
     * @POST create calificationModel
     */
    addCalificationModel: builder.mutation<
      CalificationModel,
      { profileId: number; calificationModelPayload: Partial<CalificationModel> }
    >({
      queryFn: async ({ profileId, calificationModelPayload }) => {
        try {
          const { data } = await mainApi.post<CalificationModel>('/calification-models', {
            user_profile_id: profileId,
            ...calificationModelPayload,
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'CalificationModels', id: 'LIST' }],
    }),

    /**
     * @PUT update calificationModel
     */
    updateCalificationModel: builder.mutation<
      CalificationModel,
      {
        profileId: number;
        calificationModelId: number;
        calificationModelPayload: Partial<CalificationModel>;
      }
    >({
      queryFn: async ({ profileId, calificationModelId, calificationModelPayload }) => {
        try {
          const { data } = await mainApi.put<CalificationModel>(
            `/calification-models/${calificationModelId}`,
            {
              user_profile_id: profileId,
              ...calificationModelPayload,
            }
          );
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'CalificationModels', id: 'LIST' }],
    }),

    /**
     * @DELETE remove calificationModel
     */
    deleteCalificationModel: builder.mutation<
      CalificationModel,
      { profileId: number; calificationModelId: number }
    >({
      queryFn: async ({ profileId, calificationModelId }) => {
        try {
          const { data } = await mainApi.delete(`/calification-models/${calificationModelId}`, {
            data: {
              user_profile_id: profileId,
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'CalificationModels', id: 'LIST' }],
    }),

    /**
     * @GET all calification model to combo box
     */
    getCalificationModelsAvailables: builder.query<
      CalificationModelAvilable[],
      { profileId: number }
    >({
      queryFn: async ({ profileId }) => {
        try {
          const { data } = await mainApi.get<CalificationModelAvilable[]>('/calification-models', {
            params: {
              user_profile_id: profileId,
              data: 'all',
            },
          });

          if (!Array.isArray(data)) {
            return { error: 'No hay datos' };
          }

          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
    }),
  }),
});

export const {
  useGetCalificationModelsQuery,
  useAddCalificationModelMutation,
  useDeleteCalificationModelMutation,
  useGetCalificationModelQuery,
  useUpdateCalificationModelMutation,
  useGetCalificationModelsAvailablesQuery,
  useLazyGetCalificationModelQuery,
} = calificationModelsApi;
