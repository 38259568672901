import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../../../hooks/useRedux';
import SubCard from '../../../../shared/components/cards/SubCard';
import { renderErrorOrEmptyRow, renderSkeletonRows } from '../../../../shared/helpers/render';
import { useGetGroupConfigurationByStudentDisabledQuery } from '../../slices/clientApiSlice';

const DisabledDiscount = () => {
  const { clientList } = useAppSelector((state) => state.client);

  const {t} = useTranslation();

  const { idPerson } = useParams<{ idPerson: string }>();

  const student = clientList.find((c) => c.id === Number(idPerson));

  const { data, error, isFetching } = useGetGroupConfigurationByStudentDisabledQuery({
    studentId: Number(student?.user?.student?.id!),
  });
  const defaultConfiguration = useAppSelector((state) => state.access.defaultConfiguration);

  const renderRow = data?.map((r) => {
    let parent = r.group_configuration.group_configuration_product.find((g) => !g.gcp_parent_id);
    const getValue = () => {
      if (Number(parent?.gcp_amount) > 0) {
        return `${defaultConfiguration?.currency_symbol} ${Number(parent?.gcp_amount)}`;
      } else {
        return `${Number(parent?.gcp_percent)} %`;
      }
    };
    return (
      <TableRow key={r.id}>
        <TableCell width="25%" align="center">
          {r.group_configuration.gc_description}
        </TableCell>
        <TableCell width="25%" align="center">
          {getValue()}
        </TableCell>
        <TableCell width="25%" align="center">
          {r.group_configuration.gc_end_date || '-'}
        </TableCell>
        <TableCell width="25%" align="center">
          {r.group_configuration?.gc_end_date || '-'}
        </TableCell>
      </TableRow>
    );
  });

  return (
    <Grid container>
      <Grid item xs={12}>
        <TableContainer component={SubCard} content={false}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">{t('financial.client_list.table.type')}</TableCell>
                <TableCell align="center">{t('financial.client_list.table.discount')}</TableCell>
                <TableCell align="center">{t('financial.client_list.table.start_validity')}</TableCell>
                <TableCell align="center">{t('financial.client_list.table.end_validity')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isFetching ? renderSkeletonRows(3, 4) : renderRow}
              {error && renderErrorOrEmptyRow(4, error as string)}
              {!isFetching && data?.length === 0 && renderErrorOrEmptyRow(4)}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default DisabledDiscount;
