import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISearchTable } from '../../../../../shared/interfaces/paginatedTable.interface';
import { FormsData, FormState } from '../interfaces/forms.interfaces';
import { IForcmUser } from '../interfaces/formUser.interface';

const initialState: FormState = {
  formTable: {
    page: 1,
    perPage: 5,
    search: '',
  },
  start_date: new Date().toISOString(),
  end_date: new Date().toISOString(),
  formUserSelected: null,
  openDialogAcceptResult: false,
  formSelected: null,
  isOpenDocumentDialog: false,
  formIdSelected: null,
  isOpenDeleteDialogOpen: false,
};

export const formsSlice = createSlice({
  name: 'formsEva',
  initialState,
  reducers: {
    setFormTable: (state, action: PayloadAction<ISearchTable>) => {
      state.formTable = {
        ...state.formTable,
        ...action.payload,
      };
    },
    setStart_date: (state, action: PayloadAction<string>) => {
      state.start_date = action.payload;
    },
    setEnd_date: (state, action: PayloadAction<string>) => {
      state.end_date = action.payload;
    },
    setFormUserSelected: (state, action: PayloadAction<IForcmUser>) => {
      state.formUserSelected = action.payload;
    },
    setOpenDialogAcceptResult: (state, action: PayloadAction<boolean>) => {
      state.openDialogAcceptResult = action.payload;
    },
    setFormSelected: (state, action: PayloadAction<FormsData>) => {
      state.formSelected = action.payload;
    },
    setIsOpenDocumentDialog: (state, action: PayloadAction<boolean>) => {
      state.isOpenDocumentDialog = action.payload;
    },
    setFormIdSelected: (state, action: PayloadAction<number | string | null>) => {
      state.formIdSelected = action.payload;
    },
    setIsOpenDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.isOpenDeleteDialogOpen = action.payload;
    },
  },
});

export const {
  setFormTable,
  setStart_date,
  setEnd_date,
  setFormUserSelected,
  setOpenDialogAcceptResult,
  setFormSelected,
  setIsOpenDocumentDialog,
  setFormIdSelected,
  setIsOpenDialogOpen,
} = formsSlice.actions;
