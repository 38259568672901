import { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import { Autocomplete, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import TextFieldFetching from '../../../../shared/components/TextFieldFetching';

import InputLabel from '../../../../shared/components/forms/InputLabel';
import { SearchPersonParam } from '../../../../shared/interfaces/searchPerson.interface';
import { useGetDebtorsStudentQuery } from '../../../../students/subjects-register/slices/subjectsRegisterApiSlice';
import { useAppDispatch } from '../../../../../hooks/useRedux';
import { setDebtor } from '../../slices/generateDebtsSlice';

interface Props {
  currentClient: SearchPersonParam;
}

const ClientLabels = ({ currentClient }: Props) => {
  const dispatch = useAppDispatch();

  const { data: AvailablesDebtors, isLoading: isLoadingDebtors } = useGetDebtorsStudentQuery({
    studentId: currentClient.user.student?.id!,
  });

  const { t } = useTranslation();

  const [deudor, setDeudor] = useState<string>('');

  const handleSetDeudor = (deudors: string) => {
    setDeudor(deudors);
  };

  useEffect(() => {
    setDeudor(AvailablesDebtors?.[0]!?.id.toString());
  }, [AvailablesDebtors]);

  useEffect(() => {
    if (deudor) {
      dispatch(setDebtor(deudor));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deudor]);

  useEffect(() => {
    handleSetDeudor(AvailablesDebtors?.[0]?.id.toString() || '');
  }, [AvailablesDebtors, setDeudor]);

  return (
    <>
      {/* <Grid container spacing={1} justifyContent={'space-around'}> */}
      <Grid
        container
        alignContent={'center'}
        sx={{ justifyContent: { lg: 'space-around' } }}
        p={2}
        spacing={2}
      >
        <Grid item xs={12} sm={6} md={6} lg={'auto'}>
          <InputLabel sx={{ m: '0px!important' }}>
            {t('financial.generate_debts.client_component.code_label')}
            <Typography variant={'h5'} component={'span'}>
              {currentClient?.user.student?.stud_code || ''}
            </Typography>
          </InputLabel>
        </Grid>

        {/* <Grid item> */}
        {/* <InputLabel>Código</InputLabel>
            <TextField
              value={currentClient?.user.student?.stud_code || ''}
              placeholder="Código del estudiante"
              InputProps={{ readOnly: true }}
            /> */}
        {/* </Grid> */}

        <Grid item xs={12} sm={6} md={6} lg={'auto'}>
          <InputLabel sx={{ m: '0px!important' }}>
            {t('financial.generate_debts.client_component.dni_label')}
            <Typography variant={'h5'} component={'span'}>
              {currentClient?.pers_identification || ''}
            </Typography>
          </InputLabel>
        </Grid>

        {/* <Grid item>
            <InputLabel>CI / RUC</InputLabel>
            <TextField
              value={currentClient?.pers_identification || ''}
              placeholder="Número de identificación"
              InputProps={{ readOnly: true }}
            />
          </Grid> */}

        <Grid item xs={12} sm={6} md={6} lg={'auto'}>
          <InputLabel sx={{ m: '0px!important' }}>
            {t('financial.generate_debts.client_component.name_label')}
            <Tooltip
              title={`${currentClient?.pers_firstname ? currentClient?.pers_firstname + ' ' : ''}${
                currentClient?.pers_secondname ? currentClient?.pers_secondname + ' ' : ''
              }${
                currentClient?.pers_first_lastname ? currentClient?.pers_first_lastname + ' ' : ''
              }${
                currentClient?.pers_second_lastname ? currentClient?.pers_second_lastname + ' ' : ''
              }`}
            >
              <Typography variant={'h5'} component={'span'} sx={{ pointerEvents: 'auto' }}>
                {`${currentClient?.pers_firstname ? currentClient?.pers_firstname + ' ' : ''}${
                  currentClient?.pers_secondname ? currentClient?.pers_secondname + ' ' : ''
                }${
                  currentClient?.pers_first_lastname ? currentClient?.pers_first_lastname + ' ' : ''
                }${
                  currentClient?.pers_second_lastname
                    ? currentClient?.pers_second_lastname + ' '
                    : ''
                }`}
              </Typography>
            </Tooltip>
          </InputLabel>
        </Grid>
        {currentClient?.user.student && (
          <Grid item container xs={12} md={12} justifyContent={'left'} mt={2}>
            <InputLabel sx={{ m: '0px!important' }}>
              {t('financial.generate_debts.client_component.debtor_label')}
            </InputLabel>

            {isLoadingDebtors ? (
              <TextFieldFetching variant="standard" />
            ) : (
              <>
                <Autocomplete
                  loadingText={t('financial.generate_debts.client_component.debtor.loading_text')}
                  noOptionsText={t(
                    'financial.generate_debts.client_component.debtor.no_options_text'
                  )}
                  options={AvailablesDebtors || []}
                  closeText={t('financial.generate_debts.client_component.debtor.close_text')}
                  openText={t('financial.generate_debts.client_component.debtor.open_text')}
                  loading={isLoadingDebtors}
                  getOptionLabel={(option) => option?.person?.pers_full_name}
                  disabled={AvailablesDebtors?.length! <= 1}
                  defaultValue={AvailablesDebtors?.length === 1 ? AvailablesDebtors[0]! : undefined}
                  value={
                    AvailablesDebtors?.find((_deudor) => _deudor?.id === Number(deudor || '0')) ||
                    null
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={t(
                        'financial.generate_debts.client_component.debtor.placeholder'
                      )}
                      variant="standard"
                    />
                  )}
                  onChange={(e, value) => handleSetDeudor(value?.id.toString() || '')}
                  fullWidth
                />
              </>
            )}
          </Grid>
        )}

        {/* <Grid item>
            <InputLabel>Nombres</InputLabel>
            <TextField
              value={`${currentClient?.pers_firstname ? currentClient?.pers_firstname + ' ' : ''}${
                currentClient?.pers_secondname ? currentClient?.pers_secondname + ' ' : ''
              }${
                currentClient?.pers_first_lastname ? currentClient?.pers_first_lastname + ' ' : ''
              }${
                currentClient?.pers_second_lastname ? currentClient?.pers_second_lastname + ' ' : ''
              }`}
              placeholder="Nombre del estudiante"
              InputProps={{ readOnly: true }}
            />
          </Grid> */}
      </Grid>
    </>
  );
};

export default ClientLabels;
