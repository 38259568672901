import {
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  InputLabel,
  // makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { ChangeEvent, useState } from 'react';
import { useAppSelector } from '../../../../hooks/useRedux';
import { ApprovePracticesPayload } from '../interfaces/approvePractices.interfaces';
import { useAddApprovePracticeMutation } from '../slices/academicRequestsApiSlice';
import { approvePracticesSchema } from '../constants/validationSchemas';
import { approvePracticesValues } from '../constants/formConstants';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
// import { SUPPORT_TYPES_RM } from '../../../shared/constants/extensionRequestType';
import useGuard from '../hooks/useGuard';

import { useTranslation } from 'react-i18next';
import DragArea from '../../../shared/components/files/DragArea';
import { useUploadFilesMutation } from '../../../shared/slices/uploadFilesApi';

interface Target {
  report: File;
  cert: File;
}

interface Props {
  isCurriculumNew: boolean;
}

const ApprovePracticeForm = ({ isCurriculumNew }: Props) => {
  const { t } = useTranslation();

  const { hasAccess } = useGuard();

  const { currentProfile, currentRecordStudent } = useAppSelector((state) => state.access);

  const { userInfo } = useAppSelector((state) => state.auth);

  const [showAlerts, setShowAlerts] = useState(true);

  const [, setFile] = useState('');

  const [target, setTarget] = useState<Target>(null!);

  const onChangeFile = (event: ChangeEvent<HTMLInputElement>, type: keyof Target) => {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e: any) => {
        setFile(e.target.result);
      };
      reader.readAsDataURL(event.target.files[0]);
      setTarget((data) => ({ ...data, [type]: event?.target?.files?.[0] || ({} as File) }));
    }
  };
  const onRemoveFile = function () {
    setFile('');
    setTarget(null!);
  };

  const [addApprovePractice, { isLoading: isLoadingAP, isError, error, isSuccess }] =
    useAddApprovePracticeMutation();

  const [uploadFile, { isLoading: isLoadingFile, isError: isErrorFile, error: errorFile }] =
    useUploadFilesMutation();

  const { handleSubmit, errors, touched, getFieldProps } = useFormik<ApprovePracticesPayload>({
    initialValues: approvePracticesValues,
    validationSchema: approvePracticesSchema,
    // validate: (values) => {
    //   const fileNotAllowed = `${t('students.applications.missed_exam.validation1')}`;
    //   const fileNotFound = `${t('students.applications.missed_exam.validation2')}`;
    //   const errors: any = {};
    //   if (target) {
    //     let extension = target?.type;
    //     if (SUPPORT_TYPES_RM.indexOf(extension) < 0) {
    //       errors.file_url = fileNotAllowed;
    //     }
    //   } else {
    //     errors.file_url = fileNotFound;
    //   }
    //   return errors;
    // },
    onSubmit: async ({ app_description, file_url_cert, file_url_report }) => {
      if (!isCurriculumNew) {
        await Promise.all([
          uploadFile({ files: target.cert }),
          uploadFile({ files: target.report }),
        ])
          .then((url) =>
            addApprovePractice({
              profileId: currentProfile?.profileId!,
              userId: String(userInfo?.id!),
              approvePracticesPayload: {
                app_description,
                student_record_id: currentRecordStudent?.id || 0,
                file_url_cert: (url[0] as any)?.data,
                file_url_report: (url[1] as any)?.data,
              },
            })
          )
          .catch((error) => console.log(error));
      } else {
        await uploadFile({ files: target.cert })
          .unwrap()
          .then((url) =>
            addApprovePractice({
              profileId: currentProfile?.profileId!,
              userId: String(userInfo?.id!),
              approvePracticesPayload: {
                app_description,
                student_record_id: currentRecordStudent?.id || 0,
                file_url_cert: url,
              },
            })
          )
          .catch((error) => console.log(error));
      }

      setShowAlerts(true);
    },
  });

  return (
    <form onSubmit={handleSubmit}>
      <Grid item sx={{ my: 3 }}>
        <Typography variant="h3">
          Cargar documentos de prácticas preprofesionales de la empresa
        </Typography>
      </Grid>
      <Grid container item direction="column" gap={4} xs={12}>
        <Grid item>
          {isError && showAlerts && (
            <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
          )}
          {isErrorFile && showAlerts && (
            <ErrorAlert message={errorFile as string} handleDismiss={() => setShowAlerts(false)} />
          )}
          {isSuccess && showAlerts && (
            <SuccessAlert
              message={t('students.applications.success_message')}
              handleDismiss={() => setShowAlerts(false)}
            />
          )}
        </Grid>

        <Grid item xs={6}>
          <InputLabel>{t('Descripción')}</InputLabel>
          <TextField
            {...getFieldProps('app_description')}
            error={Boolean(errors.app_description && touched.app_description)}
            multiline
            fullWidth
            placeholder={t('Ingrese una descripción')}
          />
          {errors.app_description && touched.app_description && (
            <FormHelperText error>{errors.app_description}</FormHelperText>
          )}
        </Grid>
        <Grid item xs={6} md={6}>
          <InputLabel>{t('Cargar certificado')}</InputLabel>

          <DragArea
            handleChange={(event) => onChangeFile(event, 'cert')}
            label={t('students.applications.upload_file')}
            onRemoveFile={onRemoveFile}
            // isLoading={isLoadingFile}
            typesAccepted={'application/pdf'}
          />

          {errors.file_url_cert && touched.file_url_cert && (
            <FormHelperText error>{errors.file_url_cert}</FormHelperText>
          )}
        </Grid>

        {!isCurriculumNew && (
          <Grid item xs={6} md={6}>
            <InputLabel>{t('Cargar Reporte')}</InputLabel>
            <DragArea
              handleChange={(event) => onChangeFile(event, 'report')}
              label={t('students.applications.upload_file')}
              onRemoveFile={onRemoveFile}
              // isLoading={isLoadingFile}
              typesAccepted={'application/pdf'}
            />

            {errors.file_url_report && touched.file_url_report && (
              <FormHelperText error>{errors.file_url_report}</FormHelperText>
            )}
          </Grid>
        )}

        <Grid
          item
          container
          alignItems="center"
          justifyContent="flex-end"
          spacing={2}
          sx={{ mt: 1 }}
        >
          {hasAccess('save_late_exam') && (
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                size="large"
                color="primary"
                disabled={isLoadingAP || isLoadingFile}
                endIcon={(isLoadingAP || isLoadingFile) && <CircularProgress size={20} />}
              >
                {t('students.applications.save')}
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </form>
  );
};
export default ApprovePracticeForm;
