import { useEffect } from 'react';

import { Grid } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import MainCard from '../../../shared/components/cards/MainCard';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import GoBackBtnTitle from '../../../shared/components/buttons/GoBackBtnTitle';
import SimpleFormOneColumn from '../../../shared/components/forms/skeletons/SimpleFormOneColumn';
import { setCollaboratorsList } from '../slices/teachersSlice';
import { useLazyGetCollaboratorQuery } from '../../../hr/collaborators/slices/collaboratorsApiSlice';
import AsignCoursesTeacher from '../components/AsignCoursesTeacher';
import { ITeacher } from '../interfaces/teachers.interface';

const AssignCoursesScreen = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { teacherId } = useParams<{ teacherId: string }>();
  const collabList = useAppSelector((state) => state.teacher.collabList);

  const [getCollaborator, { isFetching }] = useLazyGetCollaboratorQuery();

  useEffect(() => {
    if (!collabList.length && !isFetching) {
      getCollaborator({
        collaboratorId: +teacherId,
      })
        .unwrap()
        .then((response) => {
          if (response) {
            dispatch(setCollaboratorsList([response as unknown as ITeacher]));
          }
        })
        .catch((error) => console.log(error));
    }
  }, [collabList.length, dispatch, getCollaborator, isFetching, teacherId]);

  return (
    <Grid container component={MainCard} sx={{ padding: 2 }} contentSX={{ width: 1 }}>
      <GoBackBtnTitle title={`${t('academic.teachers_list.asign_courses.title')}`} />

      <Grid item xs={12} sx={{ mt: 2, width: 1 }}>
        {!!collabList.length ? <AsignCoursesTeacher /> : <SimpleFormOneColumn />}
      </Grid>
    </Grid>
  );
};

export default AssignCoursesScreen;
