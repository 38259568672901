export const permissionKey = 'profiles';

export interface UsersSubPermissionKeys {
  read: string;
}

export const usersSubPermissionKeys: UsersSubPermissionKeys = {
  read: 'users-listar-usuarios',
};

export interface UserProfilesSubPermissionKeys {
  readProfilesByUser: string;
  readOne: string;
  create: string;
  update: string;
  delete: string;
  deleteOne: string;
}

export const userProfilesSubPermissionKeys: UserProfilesSubPermissionKeys = {
  readProfilesByUser: 'users-listar-perfiles-usuario',
  readOne: 'users-mostrar-perfil-especifico-por-usuario',
  create: 'users-guardar-perfil-por-usuario',
  update: 'users-actualizar-perfil-por-usuario',
  delete: 'users-borrar-perfiles-por-usuario',
  deleteOne: 'users-borrar-perfil-especifico-por-usuario',
};

export interface UsersProfileRolesSubPermissionKeys {
  read: string;
  readProfilesByUser: string;
  readOne: string;
  update: string;
  syncro: string;
}

export const usersProfileRolesSubPermissionKeys: UsersProfileRolesSubPermissionKeys = {
  read: 'users-listar-roles-por-usuario-y-perfil',
  readProfilesByUser: 'users-listar-roles-por-usuario',
  readOne: 'users-mostrar-perfil-especifico-por-usuario',
  update: 'roles-actualizar-rol',
  syncro: 'users-sincronizar-roles-por-usuario-y-perfil',
};
