import {
  Button,
  Grid,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { useHistory, useParams } from 'react-router-dom';
import { useAppSelector } from '../../../../../hooks/useRedux';
import MainCard from '../../../../shared/components/cards/MainCard';
import LoadingData from '../../../../shared/components/cards/Skeleton/LoadingData';
import { useGetCatalogByAcronymChildrenQuery } from '../../../../shared/slices/catalogApi';
import EditorReadText from '../../../../students/virtual-classroom/forum/components/EditorReadText';
import { OptionAnswerDetail } from '../../evaluations/interfaces/evaluation-qualify.interfaces';
import { useGetActityForStudentQuery } from '../../slices/virtualClassroomTeacherApiSlice';

function DetailActivityCalificate() {
  const {
    course: { courseId },
  } = useAppSelector((state) => state.virtualClassroomTeacherShared);
  const { activityId, studentId } = useParams<{ studentId: string; activityId: string }>();
  const History = useHistory();

  const { t } = useTranslation();

  const { currentProfile } = useAppSelector((state) => state.access);

  const { data, isLoading } = useGetActityForStudentQuery({
    courseId: courseId,
    activityId: Number(activityId),
    studentId: Number(studentId),
  });

  console.log(activityId, studentId, courseId);

  const { data: dataTypeQuestion } = useGetCatalogByAcronymChildrenQuery({
    acronym: 'tipo-respuesta',
    profileId: currentProfile?.profileId!,
  });

  const Multiple = ({ op_ans_description, isAnswer }: OptionAnswerDetail, index: number) => {
    return (
      <Grid container item xs={12} key={`${index}-${op_ans_description}`}>
        <Grid item xs={10} sx={{ mt: 2 }}>
          <TextField
            variant="standard"
            fullWidth
            disabled
            value={`${index + 1}. ${op_ans_description}`}
          />
        </Grid>
        <Grid item sx={{ mt: 1 }}>
          <Checkbox disabled checked={Number(isAnswer) === 1 ? true : false} />
        </Grid>
      </Grid>
    );
  };

  const Single = ({ op_ans_description, isAnswer }: OptionAnswerDetail, index: number) => {
    return (
      <Grid container key={`${index}-${op_ans_description}`}>
        <Grid item xs={10} sx={{ mt: 2 }}>
          <TextField
            variant="standard"
            fullWidth
            disabled
            value={`${index + 1}. ${op_ans_description}`}
          />
        </Grid>
        <Grid item sx={{ mt: 1 }}>
          <FormControlLabel
            label=""
            disabled
            control={<Radio />}
            checked={Number(isAnswer) === 1 ? true : false}
          />
        </Grid>
      </Grid>
    );
  };

  const commentary = (comment: string) => {
    return (
      <Grid container>
        <Grid item xs={10} sx={{ mt: 2 }}>
          <TextField fullWidth rows={5} defaultValue={comment} multiline disabled />
        </Grid>
      </Grid>
    );
  };
  //    <EditorReadText text={commnet} />
  const renderOptions = (typeAnswerId: number, options: OptionAnswerDetail[], comment: string) => {
    const typeQuestion = dataTypeQuestion?.find((e) => e.id === typeAnswerId);
    let textEditor: any;
    if (typeQuestion?.cat_name! === 'Opcion Multiple') {
      return options?.map((e, index) => Multiple(e, index));
    } else if (typeQuestion?.cat_name! === 'Opcion Seleccion') {
      return options.map((e, index) => Single(e, index));
    } else if (!comment?.includes('{') || !comment?.includes('[')) {
      return commentary(comment);
    } else {
      textEditor = JSON.parse(comment);
      return <EditorReadText text={textEditor} />;
    }
  };

  if (isLoading) {
    return <LoadingData />;
  }

  return (
    <MainCard
      sx={{
        borderTopLeftRadius: '0px',
        borderTopRightRadius: '0px',
      }}
    >
      <Grid>
        <Typography variant="h2" color="black">
          {`   ${data?.act_description}  |   Nota  : ${Number(data?.act_score_activity)}`}
        </Typography>
        <Grid display="flex" justifyContent="flex-end" alignItems="center">
          <Button
            onClick={() =>
              History.replace(
                `/administrar-aula-virtual/notas/view/activities/${Number(activityId)}`
              )
            }
            variant="outlined"
          >
            {t('teacher_virtual_classroom.califications.calificate.button.finishCheck')}
          </Button>
        </Grid>
        {data?.details_activity?.map((itemTask) => {
          return (
            <Grid>
              <Typography color="black" variant="h3">
                {t('teacher_virtual_classroom.califications.calificate.questions')} :{' '}
                {itemTask.dta_question}
              </Typography>
              <Typography variant="h4" color="black">
                {t('teacher_virtual_classroom.califications.calificate.questions.score')} :{' '}
                {Number(itemTask.dta_score_evaluated)}
              </Typography>

              {/*itemTask.answered_activities.length < 1 && (
                <Grid>
                  <Typography color="error" variant="body1">
                    No ha sido Contestada
                  </Typography>
                </Grid>
              )*/}

              {renderOptions(
                Number(itemTask.type_answer_id),
                itemTask.option_answers,
                itemTask.answered_activities[0]?.csact_comments
              )}
              {/*
                <Grid display="flex" gap={2}>
                  {itemTask.type_answer_id === '625' &&
                    itemTask.answered_activities.map((itemAnswer) => {
                      return (
                        <TextField
                          fullWidth
                          maxRows={5}
                          multiline
                          disabled
                          defaultValue={itemAnswer.csact_comments}
                        />
                      );
                    })}
                </Grid>*/}
            </Grid>
          );
        })}
      </Grid>
    </MainCard>
  );
}

export default DetailActivityCalificate;
