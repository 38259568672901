import React, { useState } from 'react';

// material-ui
import {
    Box,
    Grid,
    useTheme,
    Menu,
    MenuItem,
    TextField,
    Button,
    InputAdornment,
    IconButton,
    Card,
    Avatar,
    CardContent,
    Typography,
} from '@material-ui/core';

import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';

import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { SerializedError } from '@reduxjs/toolkit';
import CustomPagination from '../../../../shared/components/tables/CustomPagination';
import { DebounceInput } from 'react-debounce-input';
import { GridSearchIcon } from '@material-ui/data-grid';
import { useStyles } from '../../helpers/styles';
import { IconX } from '@tabler/icons';
import { obtainDomainStorageImage } from '../../../../shared/helpers/'
import AvatarNoLoaded from '../../../../../assets/images/users/noloadedavatar.png'
import LoadingData from '../../../../shared/components/cards/Skeleton/LoadingData';

interface PartnertProps {
    partner_id: number;
    partner_name: string;
    partner_email: string;
    partner_image: string;
}

interface Props {
    data: PartnertProps[];
    keyExtractor: (item: PartnertProps) => string;
    isLoading: boolean;
    search: string;
    setSearch: React.Dispatch<React.SetStateAction<string>>;
    isFetching: boolean;
    error: FetchBaseQueryError | SerializedError | undefined;
    perPage: number;
    setPerPage: React.Dispatch<React.SetStateAction<number>>;
    page: number;
    setPage: React.Dispatch<React.SetStateAction<number>>;
}

const PartnerCards = ({
    data,
    keyExtractor,
    isLoading,
    isFetching,
    search,
    setSearch,
    error,
    perPage,
    setPerPage,
    page,
    setPage,
}: Props) => {
    const theme = useTheme();

    const classes = useStyles();

    const obtainInitialSlice = function (_page: number, _perPage: number): number {
        if (_page > 1) {
            return _perPage * _page - _perPage;
        }
        return 0;
    };

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (newPerPage: number) => {
        setAnchorEl(null);
        setPerPage(newPerPage);
    };

    const RenderSearch = function () {
        return (
            <Grid container justifyContent="right" alignItems="center" spacing={2}>
                <Grid item xs={12} sm={4}>
                    <DebounceInput
                        autoFocus={Boolean(search)}
                        minLength={2}
                        debounceTimeout={400}
                        onChange={({ target }) => setSearch(target.value)}
                        value={search}
                        element={(props) => (
                            <TextField
                                style={{
                                    width: '100%',
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <GridSearchIcon fontSize="small" />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                size="small"
                                                onClick={() => setSearch('')}
                                                {...(!search && { sx: { cursor: 'initial', opacity: 0 } })}
                                            >
                                                <IconX size={20} />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                placeholder="Buscar"
                                variant="outlined"
                                size="small"
                                {...props}
                            />
                        )}
                    />
                </Grid>
            </Grid>
        )
    }

    const RenderCardParnert = function ({ partner_id, partner_name, partner_image, partner_email }: PartnertProps) {
        return <Grid key={`${keyExtractor({ partner_id, partner_name, partner_image, partner_email })}-${partner_id}-partner`} item xs={12} sm={12} lg={4} xl={3}>
            <Card className={classes.avatarParnertContainer}>
                <CardContent sx={{ p: 2, pb: '16px !important' }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Avatar className={classes.avatarPartner} alt={partner_name} src={partner_image ? partner_image : AvatarNoLoaded} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} alignItems="center">
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography variant="h5">{partner_name}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body2">{partner_email}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    }

    const RenderCardsParnerts = function () {
        const dataSlice = data && data!.slice(obtainInitialSlice(page, perPage), perPage * page);
        return <>
            {
                isFetching
                    ? <LoadingData/>
                    : (
                        <Grid container direction="row" sx={{ marginTop: 3 }} spacing={3}>
                            {data && dataSlice.map((value) => (
                                <RenderCardParnert key={value.partner_id} partner_id={value.partner_id} partner_name={value.partner_name} partner_email={value.partner_email} partner_image={obtainDomainStorageImage(value.partner_image)} />
                            ))}
                        </Grid>
                    )
            }</>
    }

    return (
        <>
            <RenderSearch />
            <RenderCardsParnerts />
            {!isLoading && !error && (
                <Grid container justifyContent="space-between" alignItems="flex-end" sx={{ mt: 4 }}>
                    <Grid item>
                        <CustomPagination
                            shape="rounded"
                            color="primary"
                            count={Math.round(data?.length! / perPage)}
                            page={page}
                            onChange={(_, newPage) => {
                                setPage(newPage);
                            }}
                        />
                    </Grid>
                    <Grid>
                        <Box
                            component={Button}
                            size="large"
                            sx={{
                                color: theme.palette.grey[900],
                                backgroundColor: theme.palette.primary.light,
                                ':hover': { bgcolor: theme.palette.primary.light },
                                px: 2,
                                borderRadius: 4,
                            }}
                            endIcon={<ExpandMoreRoundedIcon />}
                            onClick={handleClick}
                        >
                            {perPage} Registros
                        </Box>

                        <Menu
                            id="menu-user-list-style1"
                            anchorEl={anchorEl}
                            keepMounted
                            open={open}
                            onClose={() => handleClose(perPage)}
                            variant="selectedMenu"
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            <MenuItem onClick={() => handleClose(5)}> 5 Registros</MenuItem>
                            <MenuItem onClick={() => handleClose(10)}> 10 Registros</MenuItem>
                            <MenuItem onClick={() => handleClose(15)}> 15 Registros</MenuItem>
                        </Menu>
                    </Grid>
                </Grid>
            )}
        </>
    );
};

export default PartnerCards;
