import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from '@material-ui/core';
import SubCard from '../../../shared/components/cards/SubCard';

const RenderHourHandsCollapsible = (_: number, row: any) => {
  const theme = useTheme();

  const { options: p } = row;

  return (
    <Box margin={1}>
      <TableContainer>
        <SubCard content={false} sx={{ mb: 2 }}>
          <Table size="small" aria-label="purchases">
            <TableHead>
              <TableRow hover sx={{ backgroundColor: theme.palette.grey[200] }}>
                <TableCell>Domingo</TableCell>
                <TableCell>Lunes</TableCell>
                <TableCell>Martes</TableCell>
                <TableCell>Miércoles</TableCell>
                <TableCell>Jueves</TableCell>
                <TableCell>Viernes</TableCell>
                <TableCell>Sábado</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  {p?.hour_sunday === '1' ? (
                    <>
                      Desde: {p?.hour_start_time_sunday} <br />
                      Hasta: {p?.hour_end_time_sunday}
                    </>
                  ) : (
                    '-'
                  )}
                </TableCell>
                <TableCell>
                  {p?.hour_monday === '1' ? (
                    <>
                      Desde: {p?.hour_start_time_monday} <br />
                      Hasta: {p?.hour_end_time_monday}
                    </>
                  ) : (
                    '-'
                  )}
                </TableCell>
                <TableCell>
                  {p?.hour_tuesday === '1' ? (
                    <>
                      Desde: {p?.hour_start_time_tuesday} <br />
                      Hasta: {p?.hour_end_time_tuesday}
                    </>
                  ) : (
                    '-'
                  )}
                </TableCell>
                <TableCell>
                  {p?.hour_wednesday === '1' ? (
                    <>
                      Desde: {p?.hour_start_time_wednesday} <br />
                      Hasta: {p?.hour_end_time_wednesday}
                    </>
                  ) : (
                    '-'
                  )}
                </TableCell>
                <TableCell>
                  {p?.hour_thursday === '1' ? (
                    <>
                      Desde: {p?.hour_start_time_thursday} <br />
                      Hasta: {p?.hour_end_time_thursday}
                    </>
                  ) : (
                    '-'
                  )}
                </TableCell>
                <TableCell>
                  {p?.hour_friday === '1' ? (
                    <>
                      Desde: {p?.hour_start_time_friday} <br />
                      Hasta: {p?.hour_end_time_friday}
                    </>
                  ) : (
                    '-'
                  )}
                </TableCell>

                <TableCell>
                  {p?.hour_saturday === '1' ? (
                    <>
                      Desde: {p?.hour_start_time_saturday} <br />
                      Hasta: {p?.hour_end_time_saturday}
                    </>
                  ) : (
                    '-'
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </SubCard>
      </TableContainer>
    </Box>
  );
};

export default RenderHourHandsCollapsible;
