import {
  SentimentSatisfiedAlt,
  SentimentDissatisfied,
  SentimentVeryDissatisfied,
  TagFacesOutlined,
} from '@material-ui/icons';

import { makeStyles } from '@material-ui/core';

interface Props {
  setColor: () => any;
  type: 'bad' | 'good' | 'regular' | 'procesing';
}

const styles = makeStyles({
  face: {
    fontWeight: 'bold',
    fontSize: '50px',
    position: 'absolute',
    top: '2%',
    left: '105%',
  },
});

const FaceIcon = ({ setColor, type }: Props) => {
  const classes = styles();

  if (type === 'bad') {
    return (
      <SentimentVeryDissatisfied
        className={classes.face}
        sx={{
          color: setColor(),
        }}
      />
    );
  }
  if (type === 'good') {
    return (
      <TagFacesOutlined
        className={classes.face}
        sx={{
          color: setColor(),
        }}
      />
    );
  }
  if (type === 'regular') {
    return (
      <SentimentDissatisfied
        className={classes.face}
        sx={{
          color: setColor(),
        }}
      />
    );
  }

  return (
    <SentimentSatisfiedAlt
      className={classes.face}
      sx={{
        color: setColor(),
      }}
    />
  );
};

export default FaceIcon;
