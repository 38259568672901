// material-ui
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
  useTheme,
} from '@material-ui/core';
// import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
// import { SerializedError } from '@reduxjs/toolkit';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { IconArrowNarrowLeft } from '@tabler/icons';

import { useTranslation } from 'react-i18next';
import ExtendedSideDialog from '../../../../../shared/components/dialogs/ExtendedSideDialog';
import {
  EmergencyContact,
  EmergencyContactNews,
} from '../../../interfaces/studentRecordAcademic.interfaces';
import EditEmergencyContactForm from './EditEmergencyContactForm';
// import EditEmergencyContactForm from './EditEmergencyContactForm';

export interface Props {
  open: boolean;
  studentId: string;
  data: {
    newContact?: EmergencyContactNews;
    existingContact?: EmergencyContact;
  } | null;
  cases?: 'newContact' | 'existentingContact';
  handleCloseDialog: (e: React.SyntheticEvent) => void;
  setNewContacts?: React.Dispatch<React.SetStateAction<EmergencyContactNews[]>>;
}

const EditEmergencyContactDialog = ({
  open,
  studentId,
  data,
  cases,
  handleCloseDialog,
  setNewContacts,
}: Props) => {
  const theme = useTheme();

  const { t } = useTranslation();

  return (
    <ExtendedSideDialog open={open} keepMounted onClose={handleCloseDialog} isStyled={true}>
      <DialogTitle disableTypography>
        <Grid item container alignItems="center" gap={1}>
          <IconButton
            onClick={(e) => {
              handleCloseDialog(e);
            }}
          >
            <IconArrowNarrowLeft size={30} color={theme.palette.text.dark} />
          </IconButton>
          <Typography variant="h3">{t('Editar contacto')}</Typography>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <PerfectScrollbar component={'div'}>
          {open && (
            <Grid container spacing={1} mt={1}>
              <EditEmergencyContactForm
                data={data as any}
                studentId={studentId}
                cases={cases as any}
                handleCloseDialog={handleCloseDialog}
                setNewContacts={setNewContacts}
              />
            </Grid>
          )}
        </PerfectScrollbar>
      </DialogContent>
      <DialogActions>
        {/* <AnimateButton>
          <Button variant="contained" color="primary">
            Create
          </Button>
        </AnimateButton>
        <Button variant="text" onClick={handleCloseDialog} color="primary">
          Close
        </Button> */}
      </DialogActions>
    </ExtendedSideDialog>
  );
};

export default EditEmergencyContactDialog;
