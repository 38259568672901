import {
  Autocomplete,
  Button,
  CircularProgress,
  Grid,
  InputLabel,
  TextField,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { useFormik } from 'formik';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import { useAppSelector } from '../../../../hooks/useRedux';
import { useTranslation } from 'react-i18next';
import {
  useAddClassficationMutation,
  useGetClassificationsQuery,
  useEditClassficationMutation,
} from '../slices/classificationsApiSlice';
import { useEffect } from 'react';

const AddClasification = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { classificationToEdit } = useAppSelector((state) => state.classifications);
  const { currentProfile } = useAppSelector((state) => state.access);
  const schema = yup.object().shape({
    subject: yup.string().required('Materia es un campo requerido'),
    name: yup.string().required('classifications.form.name.validation'),
    code: yup.string().required('Código es un campo requerido'),
  });

  const { data: classifications, isLoading: isLoadingClassifications } = useGetClassificationsQuery(
    {}
  );

  // const [
  //   addClassification,
  //   {
  //     isSuccess: isSuccessAdd,
  //     isLoading: isLoadingAdd,
  //     isError: isErrorAdd,
  //     error: errorAdd,
  //     reset,
  //   },
  // ] = useAddAutorMutation();

  const [
    addClassification,
    {
      isSuccess: isSuccessAdd,
      isLoading: isLoadingAdd,
      isError: isErrorAdd,
      error: errorAdd,
      reset,
    },
  ] = useAddClassficationMutation();

  // const [
  //   editClassification,
  //   {
  //     isSuccess: isSuccessEdit,
  //     isLoading: isLoadingEdit,
  //     isError: isErrorEdit,
  //     error: errorEdit,
  //     reset: resetEdit,
  //   },
  // ] = useEditAuthorMutation();

  const [
    editClassification,
    {
      isSuccess: isSuccessEdit,
      isLoading: isLoadingEdit,
      isError: isErrorEdit,
      error: errorEdit,
      reset: resetEdit,
    },
  ] = useEditClassficationMutation();

  const formik = useFormik({
    initialValues: {
      subject: classificationToEdit?.parent_id || '',
      name: classificationToEdit?.name || '',
      code: classificationToEdit?.code || '',
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      if (classificationToEdit) {
        editClassification({
          id: classificationToEdit?.id!,
          payload: {
            code: values.code,
            name: values.name,
            parent_id: values.subject,
            user_profile_id: currentProfile?.profileId!,
          },
        });
        return;
      }

      addClassification({
        payload: {
          code: values.code,
          name: values.name,
          parent_id: values.subject,
          user_profile_id: currentProfile?.profileId!,
        },
      });
    },
  });

  const handleSubmit = () => {
    formik.handleSubmit();
  };

  useEffect(() => {
    if (isSuccessAdd) {
      formik.resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessAdd]);

  return (
    <Grid container direction="column" gap={2}>
      <Grid item xs={12} md={4}>
        <InputLabel>Materia</InputLabel>
        {isLoadingClassifications ? (
          <TextField placeholder="Cargando..." fullWidth />
        ) : (
          <Autocomplete
            options={
              classifications?.data
                .filter((cl) => !Boolean(cl.parent_id))
                .map((cl) => ({
                  label: `${cl.code} -${cl.name}`,
                  value: cl.id,
                })) || []
            }
            getOptionLabel={(opt) => opt.label}
            getOptionSelected={(opt) => Boolean(opt.value === formik.values.subject)}
            value={
              classifications?.data
                .filter((cl) => !Boolean(cl.parent_id))
                .map((cl) => ({
                  label: `${cl.code} -${cl.name}`,
                  value: cl.id,
                }))
                .find((cl) => cl.value === formik.values.subject) || null
            }
            renderInput={(props) => (
              <TextField
                {...props}
                placeholder="Seleccione Materia"
                error={Boolean(formik.errors.subject && formik.touched.subject)}
                helperText={Boolean(formik.errors.subject) && formik.errors.subject}
                {...formik.getFieldProps('subject')}
              />
            )}
            onChange={(_, value) => {
              if (value) {
                formik.setFieldValue('subject', value.value);
              }
            }}
            clearIcon={null}
          />
        )}
      </Grid>
      <Grid item xs={12} md={4}>
        <InputLabel>{t('classifications.form.name')}</InputLabel>
        <TextField
          fullWidth
          error={Boolean(formik.errors.name && formik.touched.name)}
          helperText={
            Boolean(formik.errors.name) && formik.touched.name ? t(String(formik.errors.name)) : ''
          }
          placeholder={t('classifications.form.placeholder.name')}
          // onChange={({ target }) => formik.setFieldValue('name', target.value)}
          // value={formik.values.name}
          {...formik.getFieldProps('name')}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <InputLabel>Código</InputLabel>
        <TextField
          fullWidth
          error={Boolean(formik.errors.code && formik.touched.code)}
          helperText={
            Boolean(formik.errors.code) && formik.touched.code ? t(String(formik.errors.code)) : ''
          }
          placeholder="Ingrese Código"
          // onChange={({ target }) => formik.setFieldValue('code', target.value)}
          // value={formik.values.code}
          {...formik.getFieldProps('code')}
        />
      </Grid>

      {/* <Grid item xs={12} md={4}>
        <InputLabel>{t('classifications.form.status')}</InputLabel>
        <Autocomplete
          options={statusAvailables.map((st) => ({
            value: st.id,
            label: st.name,
          }))}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={t('classifications.form.placeholder.status')}
              error={Boolean(formik.errors.status && formik.touched.status)}
              helperText={
                Boolean(formik.errors.status) && formik.touched.status
                  ? t(String(formik.errors.status))
                  : ''
              }
            />
          )}
          value={statusAvailables
            .map((st) => ({
              value: st.id,
              label: st.name,
            }))
            .find((st) => st.value === Number(formik.values.status))}
          onChange={(e, value) => {
            formik.setFieldValue('status', value?.value);
          }}
          fullWidth
          clearIcon={null}
        />
      </Grid> */}

      <Grid item xs={12} md={4}>
        {isErrorAdd && <ErrorAlert message={errorAdd as string} handleDismiss={reset} />}

        {isSuccessAdd && (
          <SuccessAlert message={t('classifications.add.success.message')} handleDismiss={reset} />
        )}
        {isErrorEdit && <ErrorAlert message={errorEdit as string} handleDismiss={resetEdit} />}

        {isSuccessEdit && (
          <SuccessAlert
            message={t('classifications.edit.success.message')}
            handleDismiss={resetEdit}
          />
        )}
      </Grid>

      <Grid item xs={12}>
        {/* <InputLabel>Nombre</InputLabel>
                    <TextField
                        fullWidth
                        error={Boolean(errors.name && touched.name)}
                        placeholder="Ingrese el nombre de la sede"
                        {...getFieldProps('name')}
                    />
                    {errors.name && touched.name && <FormHelperText error>{errors.name}</FormHelperText>} */}
      </Grid>
      <Grid item xs={12}>
        {/* <InputLabel>Descripción</InputLabel>
                    <TextField
                        multiline
                        rows={2}
                        fullWidth
                        error={Boolean(errors.description && touched.description)}
                        placeholder="Ingrese una descripción para la sede"
                        {...getFieldProps('description')}
                    />
                    {errors.description && touched.description && (
                        <FormHelperText error>{errors.description}</FormHelperText>
                    )} */}
      </Grid>
      <Grid item xs={12} container spacing={2}>
        {/* <Grid item xs={8}>
                        <InputLabel>Dirección</InputLabel>
                        <TextField
                            fullWidth
                            error={Boolean(errors.direction && touched.direction)}
                            placeholder="Ingrese la ubicación de la sede"
                            {...getFieldProps('direction')}
                        />
                        {errors.direction && touched.direction && (
                            <FormHelperText error>{errors.direction}</FormHelperText>
                        )}
                    </Grid>
                    <Grid item xs={4}>
                        <InputLabel>Iniciales</InputLabel>
                        <TextField
                            fullWidth
                            error={Boolean(errors.initials && touched.initials)}
                            placeholder="Ingrese las iniciales de la sede"
                            {...getFieldProps('initials')}
                        />
                        {errors.initials && touched.initials && (
                            <FormHelperText error>{errors.initials}</FormHelperText>
                        )}
                    </Grid> */}
      </Grid>

      {/* <Grid item xs={12}>
                    <InputLabel>Estado</InputLabel>
                    <Autocomplete
                        options={statusAvailables}
                        getOptionLabel={option => option.name}
                        renderInput={params => (
                            <TextField {...params} error={Boolean(errors.statusId && touched.statusId)} />
                        )}
                        defaultValue={statusAvailables[0]}
                        onChange={(e, value) => setFieldValue('statusId', value?.id || '')}
                        fullWidth
                        clearIcon={null}
                    />
                    {errors.statusId && touched.statusId && (
                        <FormHelperText error>{errors.statusId}</FormHelperText>
                    )}
                </Grid> */}

      <Grid
        item
        container
        alignItems="center"
        justifyContent="center"
        spacing={2}
        sx={{ mt: 1 }}
        xs={12}
        md={4}
      >
        <Grid item>
          <Button variant="outlined" size="large" color="primary" onClick={() => history.goBack()}>
            {t('shared.go_back_button.label')}
          </Button>
        </Grid>
        <Grid item>
          <Button
            type="submit"
            variant="contained"
            size="large"
            color="primary"
            onClick={handleSubmit}
            disabled={isLoadingAdd || isLoadingEdit}
            endIcon={isLoadingAdd || (isLoadingEdit && <CircularProgress size={20} />)}
          >
            {classificationToEdit ? t('shared.edit_button.tooltip') : t('shared.add_button.label')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AddClasification;
