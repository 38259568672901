import {
  Button,
  CircularProgress,
  Dialog,
  DialogProps,
  Grid,
  Typography,
  useTheme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { IconCircleCheck, IconFileInfo, IconFileAlert } from '@tabler/icons';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import useStudentInfo from '../../hooks/useStudentInfo';
import { useGenerateDebtsMutation } from '../../slices/subjectsRegisterApiSlice';
import { useTranslation } from 'react-i18next';
import { setShowRegisterDebtsModal } from '../../slices/subjectsRegisterSlice';
import { useEffect } from 'react';

const useStyles = makeStyles((theme) => ({
  paper: { maxWidth: '600px', width: '90%' },
}));

interface Props extends DialogProps {
  onClose: () => void;
  debtorId: string;
}
const DialogConfirmRegister = ({ onClose, debtorId, ...props }: Props) => {
  const { t } = useTranslation();

  const classes = useStyles();
  const theme = useTheme();
  const studentInfo = useStudentInfo();

  const dispatch = useAppDispatch();

  const { currentPeriodId, studentInfoAcademic } = useAppSelector(
    (state) => state.subjectsRegister
  );
  const { userInfo } = useAppSelector((state) => state.auth);
  const { currentProfile } = useAppSelector((state) => state.access);
  // const { userInfo } = useAppSelector((state) => state.auth);

  const title = t('students.subjects.registration.register.modal.title');
  const subtitle = t('students.subjects.registration.register.modal.subtitle');
  const success = t('students.subjects.registration.register.modal.success');

  const [generateDebts, { isLoading, isSuccess, isError, error }] = useGenerateDebtsMutation();

  const handleRegister = () => {
    generateDebts({
      period_id: currentPeriodId!,
      user_profile_id: currentProfile?.profileId!,
      student_record_id: studentInfo?.studentRecordId!,
      person_id: studentInfoAcademic?.personId! || +userInfo.personId!,
      ...(debtorId && { id_debtor: Number(debtorId) }),
    });
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch(setShowRegisterDebtsModal(false));
    }
  }, [dispatch, isSuccess]);

  console.log(studentInfo?.personId!, studentInfoAcademic?.personId!);

  return (
    <Dialog {...props} classes={{ paper: classes.paper }} sx={{ p: 3 }} onClose={onClose}>
      <Grid container gap={4} sx={{ p: 2 }}>
        <Grid item container xs={12} alignItems="center" justifyContent="center">
          {!isError && !isSuccess && !isLoading && (
            <IconFileInfo
              size={60}
              color={theme.palette.error.main}
              style={{
                padding: 12,
                backgroundColor: theme.palette.orange.light,
                borderRadius: '50%',
              }}
            />
          )}
          {isLoading && <CircularProgress size={50} />}
          {isSuccess && (
            <IconCircleCheck
              size={60}
              color={theme.palette.success.main}
              style={{
                padding: 12,
                backgroundColor: theme.palette.success.light,
                borderRadius: '50%',
              }}
            />
          )}
          {isError && (
            <IconFileAlert
              size={60}
              color={theme.palette.error.main}
              style={{
                padding: 12,
                backgroundColor: theme.palette.orange.light,
                borderRadius: '50%',
              }}
            />
          )}
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Typography variant="h2" sx={{ color: theme.palette.primary.main, mb: 1 }}>
            {!isError && !isSuccess && title}
            {isSuccess && success}
            {isError && error}
          </Typography>
          <Typography variant="body2">
            {!isError && !isSuccess && subtitle}
            {isError && error}
          </Typography>
        </Grid>
        <Grid item container xs={12} justifyContent="center" gap={1}>
          {!isSuccess && !isError && (
            <Button
              variant="text"
              size="large"
              color="primary"
              onClick={onClose}
              disabled={isLoading}
            >
              {t('students.subjects.registration.register.modal.cancel')}
            </Button>
          )}

          {(isSuccess || isError) && (
            <Button variant="contained" size="large" color="primary" onClick={onClose}>
              {t('students.subjects.registration.register.modal.close')}
            </Button>
          )}

          {!isSuccess && !isError && (
            <Button
              variant="contained"
              size="large"
              onClick={handleRegister}
              color="primary"
              autoFocus
              disabled={isLoading}
            >
              {t('students.subjects.registration.register.modal.register')}
            </Button>
          )}
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default DialogConfirmRegister;
