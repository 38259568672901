import { forwardRef } from 'react';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import {
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Slide,
  SlideProps,
  Typography,
  useTheme,
  useMediaQuery,
  Divider,
} from '@material-ui/core';
import { IconArrowNarrowLeft } from '@tabler/icons';
import ExtendedSideDialog from '../../../shared/components/dialogs/ExtendedSideDialog';
import { setDepositTransferId, setObservationDialogOpen } from '../slices/depositsTransfersSlice';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import TransactionStatusChip from '../../../shared/components/badges/TransactionStatusChip';

//styles
const useStyles = makeStyles((theme) => ({
  userAddDialog: {
    '&>div:nth-child(3)': {
      justifyContent: 'flex-end',
      '&>div': {
        margin: '0px',
        borderRadius: '0px',
        maxWidth: '650px',
        maxHeight: '100%',
        '&>div.MuiDialogContent-root>div.scrollbar-container': {
          paddingLeft: '20px',
          overflow: 'hidden',
        },
      },
    },
  },
  paper: {
    minHeight: '100vh',
    width: '37%',
  },
  paperTable: {
    minHeight: '100vh',
    width: '50%!important',
    maxWidth: '400px!important',
  },
  movilPaper: {
    minHeight: '100vh',
    width: '100%',
  },
}));

// animation
const Transition = forwardRef(function Transition(props: SlideProps, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

interface Props {
  open: boolean;
}

const ObservationDialog = ({ open }: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { depositTransferList, depositTransferId } = useAppSelector(
    (state) => state.depositsTransfers
  );

  const depositTransfer = depositTransferList.find(
    (depositTransfer) => depositTransfer.id === depositTransferId
  );

  const isSm = useMediaQuery(theme.breakpoints.down('md'));

  const handleCloseDialog = () => {
    dispatch(setObservationDialogOpen(false));
    dispatch(setDepositTransferId(null!));
  };

  return (
    <ExtendedSideDialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCloseDialog}
      className={classes.userAddDialog}
      {...(!isSm && { classes: { paper: classes.paper } })}
      {...(!isSm && { classes: { paper: classes.paperTable } })}
      {...(isSm && { classes: { paper: classes.movilPaper } })}
    >
      <DialogTitle disableTypography>
        <Grid item container alignItems="center" gap={1}>
          <IconButton onClick={handleCloseDialog}>
            <IconArrowNarrowLeft size={30} color={theme.palette.text.dark} />
          </IconButton>
          <Typography variant="h3">Observación</Typography>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          {/* <pre>{JSON.stringify(depositTransfer, null, 2)}</pre> */}
          <Grid item xs={12}>
            <Typography variant="subtitle1" color="textPrimary">
              <strong>Nombre: </strong>
              {depositTransfer?.student_full_name}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1" color="textPrimary">
              <strong>Identificación: </strong>
              {depositTransfer?.student_identification}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1" color="textPrimary">
              <strong>Método de pago: </strong>
              {depositTransfer?.payment_way?.cat_name}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1" color="textPrimary">
              <strong>Institución: </strong>
              {depositTransfer?.financial_institution_target?.fin_int_name}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1" color="textPrimary">
              <strong>N° cuenta: </strong>
              {depositTransfer?.cuenta_destino}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1" color="textPrimary">
              <strong>Estado: </strong>
              <TransactionStatusChip
                name={depositTransfer?.pay?.status_payments?.cat_name!}
                keyword={depositTransfer?.pay?.status_payments?.cat_keyword!}
              />
            </Typography>
          </Grid>
          <Grid item xs={12} mt={2}>
            <Divider />
          </Grid>
          <Grid item xs={12} mt={2}>
            <Typography variant="subtitle1" color="textPrimary">
              <strong>Motivo: </strong>
            </Typography>
            <Typography variant="subtitle1" color="textPrimary">
              {depositTransfer?.observation ? depositTransfer?.observation : 'Sin observación'}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
    </ExtendedSideDialog>
  );
};

export default ObservationDialog;
