import { useState, useEffect } from 'react';
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  OutlinedInput,
  CircularProgress,
  Alert,
} from '@material-ui/core';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import AnimateButton from '../../../shared/components/extended/AnimateButton';
import ToggleVisibility from '../../../shared/components/controlled/ToggleVisibility';
import InputLabel from '../../../shared/components/forms/InputLabel';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';

import { useAppSelector } from '../../../../hooks/useRedux';

import { useChangePasswordMutation } from '../slices/configurationsApiSlice';

const ResetPasswordSchema = Yup.object().shape({
  currentPassword: Yup.string().required('La contraseña actual es requerida.'),
  password: Yup.string()
    .required('La contraseña nueva es requerida.')
    .min(6, 'La contraseña debe tener mínimo  6 caracteres')
    .max(15, 'La contraseña debe tener máximo 15 caracteres')
    .matches(/^(?=.*[#?!@$%^&*-.])/, 'La contraseña debe tener mínimo 1 un caracter especial')
    .matches(/^(?=.*[A-Z])/, 'La contraseña debe tener mínimo 1 un caracter en mayúscula')
    .matches(/^(?=.*[0-9])/, 'La contraseña debe tener mínimo 1 un número')
    .matches(/^(?=.*[a-z])/, 'La contraseña debe tener mínimo 1 caracter en minúscula'),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Las contraseñas deben coincidir.')
    .required('La confirmación  de la contraseña es requerida.'),
});

interface setPass {
  setPass: (data: string) => void;
}

const ChangePasswordForm = (props: setPass) => {
  const { setPass } = props;
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordR, setShowPasswordR] = useState(false);
  const [showAlerts, setShowAlerts] = useState(true);

  const {
    userInfo: { remember_token },
  } = useAppSelector((state) => state.auth);
  const { currentProfile } = useAppSelector((state) => state.access);

  const handleCurrentShowPassword = () => setShowCurrentPassword(!showCurrentPassword);
  const handleShowPassword = () => setShowPassword(!showPassword);
  const handleShowPasswordR = () => setShowPasswordR(!showPasswordR);

  const [changePassword, { isLoading, isError, isSuccess, error }] = useChangePasswordMutation();

  const { errors, touched, getFieldProps, handleSubmit, values, setFieldValue } = useFormik({
    initialValues: {
      currentPassword: '',
      password: '',
      passwordConfirmation: '',
    },
    validationSchema: ResetPasswordSchema,
    onSubmit: (values) => {
      changePassword({
        profileId: currentProfile?.profileId!,
        changePasswordPayload: {
          current_password: values.currentPassword.trim(),
          password: values.password.trim(),
          password_confirmation: values.passwordConfirmation.trim(),
        },
      });
    },
  });

  useEffect(() => {
    setPass(values.password);
  }, [values, setPass]);

  return (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <Grid container spacing={2} style={{ marginBlockEnd: 16 }}>
        <Grid item xs={12}>
          {isError && showAlerts && (
            <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
          )}

          {isSuccess && showAlerts && (
            <SuccessAlert
              message="Se ha modificado la contraseña correctamente."
              handleDismiss={() => setShowAlerts(false)}
            />
          )}

          {!isSuccess && !isError && !remember_token && (
            <Alert severity="info">
              Para prevenir riesgos de seguridad, es necesario cambiar la contraseña por primera vez
            </Alert>
          )}
        </Grid>
        <Grid item xs={12}>
          <InputLabel htmlFor="currentPassword">Actual Contraseña</InputLabel>
          <OutlinedInput
            id="currentPassword"
            type={showCurrentPassword ? 'text' : 'password'}
            error={Boolean(errors.currentPassword && touched.currentPassword)}
            placeholder="Ingrese contraseña actual"
            fullWidth
            endAdornment={
              <ToggleVisibility
                showPassword={showCurrentPassword}
                handleShowPassword={handleCurrentShowPassword}
              />
            }
            onPaste={(e) =>
              setFieldValue('currentPassword', (e.target as HTMLInputElement)?.value?.trim())
            }
            {...getFieldProps('currentPassword')}
            value={values.currentPassword.trim()}
          />
          {errors.currentPassword && touched.currentPassword && (
            <FormHelperText error id="passwordCurrentError">
              {errors.currentPassword}
            </FormHelperText>
          )}
        </Grid>
        <Grid item xs={12}>
          <InputLabel htmlFor="password">Nueva Contraseña</InputLabel>
          <OutlinedInput
            id="password"
            type={showPassword ? 'text' : 'password'}
            error={Boolean(errors.password && touched.password)}
            placeholder="Ingrese una nueva contraseña"
            fullWidth
            endAdornment={
              <ToggleVisibility
                showPassword={showPassword}
                handleShowPassword={handleShowPassword}
              />
            }
            {...getFieldProps('password')}
            onPaste={(e) =>
              setFieldValue('password', (e.target as HTMLInputElement)?.value?.trim())
            }
            value={values.password.trim()}
          />
          {errors.password && touched.password && (
            <FormHelperText error id="passwordError">
              {errors.password}
            </FormHelperText>
          )}
        </Grid>

        <Grid item xs={12}>
          <InputLabel htmlFor="passwordConfirmation">Repite la nueva contraseña</InputLabel>
          <OutlinedInput
            id="passwordConfirmation"
            type={showPasswordR ? 'text' : 'password'}
            placeholder="Confirmar nueva contraseña"
            error={Boolean(errors.passwordConfirmation && touched.passwordConfirmation)}
            fullWidth
            endAdornment={
              <ToggleVisibility
                showPassword={showPasswordR}
                handleShowPassword={handleShowPasswordR}
              />
            }
            {...getFieldProps('passwordConfirmation')}
            value={values.passwordConfirmation.trim()}
            onPaste={(e) =>
              setFieldValue('passwordConfirmation', (e.target as HTMLInputElement)?.value?.trim())
            }
          />
          {errors.passwordConfirmation && touched.passwordConfirmation && (
            <FormHelperText error id="passwordConfirmationError">
              {errors.passwordConfirmation}
            </FormHelperText>
          )}
        </Grid>
      </Grid>

      <Box mt={3}>
        <AnimateButton>
          <Button
            color="primary"
            size="large"
            type="submit"
            variant="contained"
            disabled={isLoading}
            endIcon={isLoading && <CircularProgress size={20} />}
            fullWidth
          >
            Enviar
          </Button>
        </AnimateButton>
      </Box>
    </form>
  );
};

export default ChangePasswordForm;
