import { useAppSelector } from '../../../hooks/useRedux';
import { useGetCollaboratorBirthdaysQuery } from '../slices/birthdayApi';

interface Props {
  month: number;
}
const useBirthdayOptions = ({ month }: Props) => {
  const { currentProfile } = useAppSelector((state) => state.access);
  const profileId = currentProfile!.profileId;
  const {
    data: collaboratorBirthdays = [],
    isLoading: isLoadingCB,
    isFetching: isFetchingCB,
  } = useGetCollaboratorBirthdaysQuery({ profileId, month });
  return { collaboratorBirthdays, isLoadingCB, isFetchingCB };
};
export default useBirthdayOptions;
