import { Alert, Box } from '@material-ui/core';
import { CabActivityStudent, Person } from '../../interfaces/tasks.interface';
import TaskItemHeaderDate from './TaskItemHeaderDate';
import TaskItemHeaderGroups from './TaskItemHeaderGroups';
import TaskItemHeaderTitle from './TaskItemHeaderTitle';
import TaskItemHeaderToggle from './TaskItemHeaderToggle';

interface Props {
  handleClickToggle: () => void;
  expanded: boolean;
  infoHeader: {
    title: string;
    score: string;
    startDate: Date;
    endDate: Date;
    groups: Person[][];
    numTries: string;
    numTriesRealized: string | number;
    calification: CabActivityStudent | null;
    isVisibleNote: boolean;
    groupId: string;
  };
}

const TaskItemHeader = ({ handleClickToggle, expanded, infoHeader }: Props) => {
  return (
    <Box
      sx={{
        p: 1,
      }}
    >
      <TaskItemHeaderTitle
        title={infoHeader.title}
        scoreActivity={infoHeader.score}
        calification={infoHeader.calification}
        isVisibleNote={infoHeader.isVisibleNote}
      />
      <Box sx={{ ml: '37px' }}>
        <TaskItemHeaderDate
          startDate={infoHeader.startDate}
          endDate={infoHeader.endDate}
          numTries={infoHeader.numTries}
          numTriesRealized={infoHeader.numTriesRealized}
          calification={infoHeader.calification}
          scoreActivity={infoHeader.score}
        />

        {infoHeader.numTriesRealized === Number(infoHeader.numTries) - 1 && (
          <Alert severity="warning" sx={{ mt: 1 }}>
            Se considera como un nuevo intento responder cualquier pregunta más de una vez
          </Alert>
        )}

        {infoHeader.groups.length > 0 &&
          infoHeader.groups.map((group) => (
            <TaskItemHeaderGroups
              group={group}
              groupId={infoHeader.groupId}
              key={`${new Date().toLocaleString()} - ${Math.random() * 10000}`}
            />
          ))}

        <TaskItemHeaderToggle expanded={expanded} handleClickToggle={handleClickToggle} />
      </Box>
    </Box>
  );
};

export default TaskItemHeader;
