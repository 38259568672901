import OptionsTemplateTable from '../components/OptionsTemplateTable';
import RepresentativeScaleTable from '../components/RepresentativeScaleTable';

export const escalasOptions = [
  {
    label: 'Calificativa',
    value: '1',
    Component: OptionsTemplateTable,
  },
  {
    label: 'Representativa',
    value: '2',
    Component: RepresentativeScaleTable,
  },
];
