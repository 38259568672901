import { Grid, Typography } from '@material-ui/core';
import MainCard from '../../../../shared/components/cards/MainCard';
import SearchByDatabase from '../../components/databases/SearchByDatabase';

const SearchByDatabaseScreen = () => {
  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">Búsqueda por bases</Typography>
      </Grid>
      <SearchByDatabase />
    </Grid>
  );
};

export default SearchByDatabaseScreen;
