export const permissionKey = 'collaborator-rrhh';

export interface SubPermissionKeys {
  read: string;
  list: string;
  create: string;
  edit: string;
  delete: string;
}

export const subPermissionKeys: SubPermissionKeys = {
  read: 'collaborators-obtener-colaborador',
  list: 'collaborators-listar-colaborador',
  create: 'collaborators-crear-colaborador',
  edit: 'collaborators-actualizar-colaborador',
  delete: 'collaborators-eliminar-colaborador',
};
