import { useState, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Tooltip, IconButton } from '@material-ui/core';
import { IconUsers, IconFiles, IconChecklist, IconSubtask } from '@tabler/icons';

import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import StatusChip from '../../../../shared/components/badges/StatusChip';
import EditButton from '../../../../shared/components/tables/buttons/EditButton';
import DeleteButton from '../../../../shared/components/tables/buttons/DeleteButton';
import AddButton from '../../../../shared/components/tables/buttons/AddButton';
import {
  updateProjectTableStates,
  openDeleteModalWithProject,
  setProjectList,
  setProjectIdSelected,
} from '../../slices/investigationLinesSlice';
import SearchPaginatedTable from '../../../../shared/components/tables/SearchPaginatedTable';
import { useTableSearch } from '../../../../../hooks/useTableSearch';
import { ProjectResponse } from '../../interfaces/projectResearch.interfaces';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { SerializedError } from '@reduxjs/toolkit';
import { statusAvailables } from '../../../../shared/constants/resourceStatus';
import DeleteProjectDialog from './DeleteProjectDialog';
import ExtendDialog from '../ExtendDialog';
import WizardProject from './forms/wizard-project';
import OmniChipAcronym from '../../../../shared/components/badges/OmniChipAcronym';
import StatusProject from '../../constants/lists/statusProject';
import ListAssignPersonDelete from './TabAssignPerson';
import ListDocument from './lateral-dialogs/ListDocument';
import { FilterData } from './index';
import { useParams } from 'react-router-dom';

interface Props {
  data: ProjectResponse;
  isLoading: boolean;
  isFetching: boolean;
  error: FetchBaseQueryError | SerializedError | undefined;
  setSort: React.Dispatch<React.SetStateAction<FilterData>>;
  sort: FilterData;
  isStage?: boolean;
}

const ProjectTable = ({ data, isLoading, isFetching, error, setSort, sort, isStage }: Props) => {
  const { t } = useTranslation();

  const { projectId } = useParams<{ projectId: string }>();

  const dispatch = useAppDispatch();

  const { path } = useRouteMatch();

  const history = useHistory();

  const [openDialog, setOpenDialog] = useState(false);

  const [openDialogAssignPerson, setOpenDialogAssignPerson] = useState(false);

  const [openDialogDocument, setOpenDialogDocument] = useState(false);

  const [projectToEdit, setProjectToEdit] = useState('');

  const { page, perPage, search, filterArrow } = useAppSelector(
    (state) => state.investigationLines.projectTable
  );

  const { deleteModalOpenProject } = useAppSelector((state) => state.investigationLines);

  const { setPage, setPerPage, setSearch, setFilterArrow } = useTableSearch({
    page,
    perPage,
    search,
    updateFunction: updateProjectTableStates,
  });

  const handleDelete = async (projectId: string) => {
    dispatch(openDeleteModalWithProject(projectId));
  };

  const handleOpenDialog = (projectId: string) => {
    setOpenDialog(true);
    setProjectToEdit(projectId);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleOpenDialogAssignPerson = (projectId: string) => {
    setOpenDialogAssignPerson(true);
    setProjectToEdit(projectId);
  };

  const handleOpenDialogDocument = (projectId: string) => {
    setOpenDialogDocument(true);
    setProjectToEdit(projectId);
  };

  const handleCloseDialogAssignPerson = () => {
    setOpenDialogAssignPerson(false);
  };

  const handleCloseDialogDocument = () => {
    setOpenDialogDocument(false);
  };

  const goToAdd = () => {
    history.push(`/${path.split('/')[1]}/proyectos/crear/crear-etapa/proyecto/${projectId}`);
  };

  const goToApplications = (projectId: string) => {
    history.push(`/${path.split('/')[1]}/proyecto/solicitudes/${projectId}`);
  };
  const goToTasks = (projectId: string) => {
    history.push(`/${path.split('/')[1]}/proyecto/tareas/${projectId}`);
  };

  const datafetch = data?.data!.map((d, index) => ({
    id: { index: index + 1 + (page - 1) * perPage, id: d.id },
    name: d.name,
    description: d.activity_description,
    programName: d.program_name,
    statusAcronym: d.status,
    statusProject: d.status_project,
    options: '',
  }));

  useEffect(() => {
    if (data) {
      dispatch(setProjectList(data.data));
    }
  }, [data, dispatch]);

  return (
    <>
      <SearchPaginatedTable
        data={datafetch!}
        headers={{
          id: t('N°'),
          name: t('academic.subjects.name'),
          description: 'Descripción',
          programName: 'Nombre del programa',
          statusAcronym: t('academic.subjects.status'),
          statusProject: 'Estado del proyecto',
          options: t('academic.subjects.options'),
        }}
        keyExtractor={(item) => item.id.id.toString()}
        searchPlacehoder={t('lines_research.index.investigationProjectFilter.search.project')}
        isLoading={isLoading}
        isFetching={isFetching}
        error={error}
        perPage={perPage}
        setPerPage={setPerPage}
        total={data?.total || 0}
        page={page}
        setPage={setPage}
        numHeader={7}
        search={search}
        listFilterArrows={{
          name: 'name',
          statusProject: 'status_project',
          programName: 'program_name',
          statusAcronym: 'status',
        }}
        filterArrow={filterArrow}
        setFilterArrow={setFilterArrow}
        setSearch={setSearch}
        customRenderers={{
          id: (row) => row.id.index,

          statusAcronym: (row) => (
            <StatusChip
              statusId={
                statusAvailables.find(
                  (status) => status.acronym?.toLowerCase() === row.statusAcronym
                )?.id || 0
              }
            />
          ),

          statusProject: (row) => (
            <OmniChipAcronym
              acronym={row.statusProject}
              statusArray={StatusProject.map(({ keyword, label, color }) => ({
                name: label,
                acronym: keyword,
                color,
              }))}
            />
          ),

          options: (row) => (
            <>
              <EditButton
                disabled={row.statusProject === 'FINALIZADO'}
                handleEdit={() => handleOpenDialog(row.id.id)}
              />
              {row.statusProject === 'FINALIZADO' && 'Finalizado'}
              <Tooltip title={t('Personas asignadas')}>
                <IconButton
                  aria-label="Editar"
                  color="primary"
                  onClick={() => {
                    dispatch(setProjectIdSelected(row.id.id));
                    handleOpenDialogAssignPerson(row.id.id);
                  }}
                >
                  <IconUsers />
                </IconButton>
              </Tooltip>

              <Tooltip title={t('Documentos')}>
                <IconButton
                  aria-label="Editar"
                  color="primary"
                  onClick={() => handleOpenDialogDocument(row.id.id)}
                >
                  <IconFiles />
                </IconButton>
              </Tooltip>

              {isStage && (
                <Tooltip title={t('Solicitudes')}>
                  <IconButton
                    aria-label="Editar"
                    color="primary"
                    onClick={() => goToApplications(row.id.id)}
                  >
                    <IconChecklist />
                  </IconButton>
                </Tooltip>
              )}
              {isStage && (
                <Tooltip title={t('Tareas')}>
                  <IconButton
                    aria-label="Editar"
                    color="primary"
                    onClick={() => goToTasks(row.id.id)}
                  >
                    <IconSubtask />
                  </IconButton>
                </Tooltip>
              )}

              <DeleteButton handleDelete={() => handleDelete(row.id.id)} />
            </>
          ),
        }}
        ActionButtons={
          <>
            <AddButton size="large" onClick={goToAdd} btnText={'Agregar'} />
          </>
        }
        customDataCellsProperties={{
          id: {
            align: 'center',
          },
          name: {
            align: 'center',
          },
          description: {
            align: 'center',
          },
          programName: {
            align: 'center',
          },
          statusAcronym: {
            align: 'center',
          },
          statusProject: {
            align: 'center',
          },
          options: {
            align: 'center',
          },
        }}
      />
      {deleteModalOpenProject && <DeleteProjectDialog />}
      <ExtendDialog open={openDialog} handleCloseDialog={handleCloseDialog} title="Editar proyecto">
        <>{openDialog && <WizardProject isEdit={true} projectId={projectToEdit} />}</>
      </ExtendDialog>

      <ExtendDialog
        open={openDialogAssignPerson}
        handleCloseDialog={handleCloseDialogAssignPerson}
        title="Personas asignadas"
      >
        <>{openDialogAssignPerson && <ListAssignPersonDelete projectId={projectToEdit} />}</>
      </ExtendDialog>

      <ExtendDialog open={openDialog} handleCloseDialog={handleCloseDialog} title="Editar proyecto">
        <>{openDialog && <WizardProject isEdit={true} projectId={projectToEdit} />}</>
      </ExtendDialog>

      <ExtendDialog
        open={openDialogDocument}
        handleCloseDialog={handleCloseDialogDocument}
        title="Documentos"
      >
        <>{openDialogDocument && <ListDocument projectId={projectToEdit} />}</>
      </ExtendDialog>
    </>
  );
};

export default ProjectTable;
