import { mainApi } from '../../../../api/mainApi';
import { AccessState } from '../../../../types';
import { middlewareApi } from '../../../middleware/middlewareApi';
import { Catalog } from '../../../shared/interfaces/catalogs.interfaces';
import {
  DepositsTransfersPayload,
  DepositsTransfersResponse,
  DepositsTransfersStatusPayload,
} from '../interfaces/depositsTransfers.interface';

export const depositsTransfersTags = middlewareApi.enhanceEndpoints({
  addTagTypes: ['depositsTransfers'],
});
export const depositsTransfersApi = depositsTransfersTags.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * @GET deposit and transger by filters
     * @Permission collection-web-list-deposito-or-transferencias-method-paid
     */
    depositsTransfersFilter: builder.query<
      DepositsTransfersResponse,
      {
        page: number;
        perPage: number;
        search: string;
        depositsTransfersPayload: Partial<DepositsTransfersPayload>;
        sort?: string;
        type_sort?: string;
      }
    >({
      queryFn: async (
        { page, perPage, search, depositsTransfersPayload, sort, type_sort },
        { dispatch, getState }
      ) => {
        try {
          const {
            access: {
              currentProfile: { profileId },
            },
          } = getState() as { access: { currentProfile: { profileId: number } } };

          const { data } = await mainApi.get<DepositsTransfersResponse>(
            '/collection/payment-method',
            {
              params: {
                user_profile_id: profileId,
                search,
                page,
                size: perPage,
                sort: sort || 'id',
                type_sort: type_sort || 'desc',
                ...depositsTransfersPayload,
              },
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'depositsTransfers', id } as const)),
              { type: 'depositsTransfers', id: 'LIST' },
            ]
          : [{ type: 'depositsTransfers', id: 'LIST' }],
    }),

    /**
     * @PUT update transaction state
     * @Permission collection-web-list-deposito-or-transferencias-change-status
     */
    updateDepositTransferState: builder.mutation<
      any,
      {
        transactionId: number;
        depositsTransfersStatusPayload: Partial<DepositsTransfersStatusPayload>;
      }
    >({
      queryFn: async ({ transactionId, depositsTransfersStatusPayload }, { getState }) => {
        try {
          const {
            access: {
              currentProfile: { profileId },
            },
          } = getState() as { access: { currentProfile: { profileId: number } } };

          const { data } = await mainApi.put<any>(
            `/collection/${transactionId}/transaction-status-change`,
            {
              user_profile_id: profileId,
              ...depositsTransfersStatusPayload,
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'depositsTransfers', id: 'LIST' }],
    }),

    /**
     * @PUT update transaction data
     * @Permission cash-collection-web-guardar-deposito-or-tranferencia-change-data
     */
    updateDepositTransfer: builder.mutation<
      any,
      {
        paymentMethodKeyword: string;
        transactionId: number;
        targetAccountNumber: string;
        targetFinancialInstituteId: number;
        paymentDate: string;
        voucherNumber: string;
        financialInstituteOriginId: number;
        nameHolderOriginAccount: string;
        profileId: number;
      }
    >({
      queryFn: async (
        {
          profileId,
          paymentMethodKeyword,
          transactionId,
          targetAccountNumber,
          targetFinancialInstituteId,
          paymentDate,
          voucherNumber,
          financialInstituteOriginId,
          nameHolderOriginAccount,
        },
        { getState }
      ) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = profileId || currentProfile?.profileId;

          const { data } = await mainApi.put<any>(`/collection-change-data`, {
            user_profile_id,
            keyword_payment_way: paymentMethodKeyword,
            cash_collection_id: transactionId,
            voucher_number: voucherNumber,
            financial_institution_target_id: targetFinancialInstituteId,
            cuenta_destino: targetAccountNumber,
            payment_date: paymentDate,
            financial_institution_origin_id: financialInstituteOriginId,
            name_holder_origin_account: nameHolderOriginAccount,
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'depositsTransfers', id: 'LIST' }],
    }),

    getPaymentMethods: builder.query<Catalog[], { profileId: number }>({
      queryFn: async ({ profileId }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = profileId || currentProfile?.profileId;

          const { data } = await mainApi.get<Catalog>(`/catalogs-others/fpad/children-acronym`, {
            params: {
              user_profile_id,
            },
          });

          return { data: data?.children || [] };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    /**
     * @PUT change payment method
     * @Permission cash-collection-web-guardar-deposito-or-tranferencia-previous-months
     */
    changePaymentMethod: builder.mutation<
      any,
      {
        transactionId: number;
        paymentMethodKeyword: string;
        profileId: number;
      }
    >({
      queryFn: async ({ transactionId, paymentMethodKeyword, profileId }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = profileId || currentProfile?.profileId;

          const { data } = await mainApi.put<any>(`/collection-payment-method/previous-months`, {
            user_profile_id,
            cash_collection_id: transactionId,
            cat_keyword: paymentMethodKeyword,
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'depositsTransfers', id: 'LIST' }],
    }),
  }),
});
export const {
  useDepositsTransfersFilterQuery,
  useUpdateDepositTransferStateMutation,
  useUpdateDepositTransferMutation,
  useGetPaymentMethodsQuery,
  useChangePaymentMethodMutation,
} = depositsTransfersApi;
