import { Autocomplete, Grid, TextField } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { useTableSearch } from '../../../../hooks/useTableSearch';
import { useGetPrincipalEducationLevelsQuery } from '../../../maintenance/education-levels/slices/educationLevelsApiSlice';
import { useGetAvailiablesPeriodsQuery } from '../../../maintenance/periods/slices/periodsApiSlice';
import TextFieldFetching from '../../../shared/components/TextFieldFetching';
import InputLabel from '../../../shared/components/forms/InputLabel';
import AddButton from '../../../shared/components/tables/buttons/AddButton';
import useGuard from '../hooks/useGuard';
import { useGetHoursTeachersQuery } from '../slice/hoursTeacherApiSlice';
import {
  setEducationLevelIdSelected,
  setPeriodIdSelected,
  updateTeachersTable,
} from '../slice/hoursTeacherSlice';
import TeachersGridView from './TeachersGridView';
import TeachersTable from './TeachersTable';

interface Props {
  viewMode: string;
}

const TeachersViews = ({ viewMode }: Props) => {
  const dispatch = useAppDispatch();

  const history = useHistory();
  const { path } = useRouteMatch();
  const { currentProfile } = useAppSelector((state) => state.access);

  const { periodIdSelected, educationLevelIdSelected } = useAppSelector(
    (state) => state.hoursTeachers
  );
  const { page, perPage, search } = useAppSelector((state) => state.hoursTeachers.teachersTable);
  const { setPage, setPerPage, setSearch } = useTableSearch({
    page,
    perPage,
    search,
    updateFunction: updateTeachersTable,
  });

  const [skip, setSkip] = useState(true);

  const { hasAccess } = useGuard();

  const { isLoading, error, data, isFetching } = useGetHoursTeachersQuery(
    {
      profileId: currentProfile?.profileId!,
      page,
      perPage,
      search,
      ...(periodIdSelected && { period_id: periodIdSelected }),
      ...(educationLevelIdSelected && { education_level_id: educationLevelIdSelected }),
    },
    { refetchOnMountOrArgChange: 30, skip }
  );

  const {
    data: periodsAvailables = [],
    isLoading: isLoadingPeriods,
    isFetching: isFetchingPeriods,
  } = useGetAvailiablesPeriodsQuery({
    profileId: currentProfile?.profileId!,
    current: true,
  });

  const {
    data: educationLevelsAvailables = [],
    isLoading: isLoadingEL,
    isFetching: isFetchingEL,
  } = useGetPrincipalEducationLevelsQuery({
    profileId: currentProfile?.profileId!,
  });

  useEffect(() => {
    if (periodIdSelected && educationLevelIdSelected) {
      setSkip(false);
    } else {
      setSkip(true);
    }
  }, [periodIdSelected, educationLevelIdSelected]);

  return (
    <Grid container>
      <Grid container item spacing={2} alignItems="center">
        <Grid item xs={12} md={3}>
          <InputLabel>Unidad académica</InputLabel>
          {isFetchingEL ? (
            <TextFieldFetching />
          ) : (
            <Autocomplete
              options={educationLevelsAvailables}
              loading={isLoadingEL}
              loadingText="Cargando niveles educativos..."
              getOptionLabel={(option) => option.edu_name}
              renderInput={(params) => (
                <TextField {...params} placeholder="Seleccione un nivel educativo" />
              )}
              onChange={(_, value) => {
                dispatch(setEducationLevelIdSelected(value?.id || null));
              }}
              fullWidth
            />
          )}
        </Grid>

        <Grid item xs={12} md={3}>
          <InputLabel>Períodos</InputLabel>
          {isFetchingPeriods ? (
            <TextFieldFetching />
          ) : (
            <Autocomplete
              options={periodsAvailables}
              loading={isLoadingPeriods}
              loadingText="Cargando períodos..."
              getOptionLabel={(option) => option.per_name}
              renderInput={(params) => (
                <TextField {...params} placeholder="Seleccione el período" />
              )}
              value={periodsAvailables.find((p) => p.id === Number(periodIdSelected)) || null}
              onChange={(_, value) => {
                dispatch(setPeriodIdSelected(value?.id || null));
              }}
              fullWidth
            />
          )}
        </Grid>

        {hasAccess('create') && (
          <Grid item xs={12} sm={6} sx={{ textAlign: 'right' }}>
            <AddButton size="large" onClick={() => history.push(`${path}/agregar`)} />
          </Grid>
        )}
      </Grid>

      {viewMode === 'grid' ? (
        <TeachersGridView
          setPage={setPage}
          setPerPage={setPerPage}
          setSearch={setSearch}
          periodId={periodIdSelected?.toString()}
          isLoading={isLoading}
          data={data}
          isFetching={isFetching}
          search={search}
          error={error}
          perPage={perPage}
          page={page}
          educationLevelId={educationLevelIdSelected?.toString()}
        />
      ) : (
        <TeachersTable
          setPage={setPage}
          setPerPage={setPerPage}
          setSearch={setSearch}
          periodId={periodIdSelected?.toString()}
          isLoading={isLoading}
          data={data}
          isFetching={isFetching}
          search={search}
          error={error}
          perPage={perPage}
          page={page}
          educationLevelId={educationLevelIdSelected?.toString()}
        />
      )}
    </Grid>
  );
};

export default TeachersViews;
