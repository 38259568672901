export function disableReactDevTools() {
  if (typeof (window as any).__REACT_DEVTOOLS_GLOBAL_HOOK__ === 'object') {
    // Ensure the React Developer Tools global hook exists
    if (!isObject((window as any).__REACT_DEVTOOLS_GLOBAL_HOOK__)) {
      return;
    }

    // Replace all global hook properties with a no-op function or a null value
    for (const prop in (window as any).__REACT_DEVTOOLS_GLOBAL_HOOK__) {
      if (prop === 'renderers') {
        // prevents console error when dev tools try to iterate of renderers
        (window as any).__REACT_DEVTOOLS_GLOBAL_HOOK__[prop] = new Map();
        continue;
      }
      (window as any).__REACT_DEVTOOLS_GLOBAL_HOOK__[prop] = isFunction(
        (window as any).__REACT_DEVTOOLS_GLOBAL_HOOK__[prop]
      )
        ? Function.prototype
        : null;
    }
  }
}

function isFunction(obj: any) {
  return typeof obj == 'function';
}

function isObject(obj: any) {
  let type = typeof obj;
  return type === 'function' || (type === 'object' && !!obj);
}
