import { Chip, Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { IEvaluationScopes } from '../../interfaces/evaluations.interface';
import EvaluationPreviewScopeQuestion from './EvaluationPreviewScopeQuestion';

interface EvaluationPreviewScopeProps {
  scope: IEvaluationScopes;
}

const EvaluationPreviewScope = ({ scope }: EvaluationPreviewScopeProps) => {
  const theme = useTheme();

  const isSmall = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Grid
      container
      sx={{
        backgroundColor: `${theme.palette.secondary.main}60`,
        borderRadius: '12px',
        padding: 2,
      }}
      alignItems="center"
      justifyContent="space-between"
      mt={2}
    >
      <Grid item xs={12} md={9} mb={isSmall ? 1 : 0}>
        <Typography variant="h4">{scope.description}</Typography>
      </Grid>
      <Grid item>
        <Chip
          label={`${scope.questions?.length} ${
            scope.questions?.length === 1 ? 'pregunta' : 'preguntas'
          }`}
          size="small"
          sx={{
            fontSize: '14px',
            fontWeight: '700',
            background: theme.palette.background.paper,
            color: theme.palette.primary.main,
          }}
        />
      </Grid>
      <Grid item container xs={12} mt={2} gap={2}>
        {scope.questions.map((question) => (
          <EvaluationPreviewScopeQuestion key={question.uuid} question={question} />
        ))}
      </Grid>
    </Grid>
  );
};

export default EvaluationPreviewScope;
