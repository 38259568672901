import { Box, Button, Skeleton, Typography, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import EditorText from './EditorText';
// import { PostDataType /*, Profile, Reply*/ } from '../../../../shared/interfaces/post.interfaces';
import PostsForum from './PostsForum';
import CommentsForum from './ComentsForum';
import { useAddCommentsMutation } from '../../slices/virtualClassroomApiSlice';
import { useAppSelector } from '../../../../../hooks/useRedux';
import { useState } from 'react';
import { SubdirectoryArrowRightOutlined } from '@material-ui/icons';
import { Forum } from '../interfaces/forums.interfaces';

interface Props {
  forumCurrent: Forum;
  refetch: () => void;
}

const ForoSelected = ({ forumCurrent, refetch }: Props) => {
  const { id, answers_activity: comments } = forumCurrent;
  const theme = useTheme();
  const { t } = useTranslation();

  const [viewEditor, setViewEditor] = useState<boolean>(true);
  const [viewButtonReply, setViewButtonReply] = useState<boolean>(false);

  const { userInfo } = useAppSelector((state) => state.auth);
  const { currentStudent } = useAppSelector((state) => state.access);

  const [createComment /*{ isSuccess: isSuccessComment, isError: isErrorComment, error }*/] =
    useAddCommentsMutation();

  const commentAdd = async (data: { comment: string; files: File | undefined }) => {
    await createComment({
      forumId: Number(id),
      CommentPayload: {
        csact_comments: data.comment,
        files: data.files,
        user_id: Number(userInfo?.id!),
        student_id: Number(currentStudent?.id!),
        type_document: 12,
        parent_id: null,
      },
    });
    refetch();
    // setShowAlerts(true);
  };

  return (
    <Box
      sx={{
        px: 4,
        mt: 6,
      }}
    >
      <Box
        sx={{
          border: `1px solid ${theme.palette.secondary.main}`,
          borderRadius: '8px',
        }}
      >
        {forumCurrent ? (
          <PostsForum forum={forumCurrent} />
        ) : (
          <Skeleton
            variant="rectangular"
            sx={{ width: '100%', borderRadius: 3, height: '40vh', marginTop: 2 }}
          />
        )}
        {viewButtonReply && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              px: 3,
              py: 1,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Button
                startIcon={<SubdirectoryArrowRightOutlined />}
                onClick={() => {
                  setViewEditor(true);
                  setViewButtonReply(false);
                }}
              >
                {t('studentVirtualClassroom.forum.editor.reply')}
              </Button>
            </Box>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          mt: 4,
        }}
      >
        <Typography
          variant="h3"
          sx={{
            mb: 3,
          }}
        >
          {t('studentVirtualClassroom.forum.comments')}
        </Typography>
        {viewEditor && (
          <EditorText
            commentAdd={commentAdd}
            setViewEditor={() => {
              setViewEditor(false);
              setViewButtonReply(true);
            }}
          />
        )}
      </Box>
      <Box
        sx={{
          mt: 3,
        }}
      >
        {comments &&
          comments.map((comment) => (
            <CommentsForum
              key={comment.id}
              comment={comment}
              forumId={id.toString()}
              refetch={refetch}
            />
          ))}
      </Box>
    </Box>
  );
};

export default ForoSelected;
