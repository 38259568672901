import { Autocomplete, Grid, TextField } from '@material-ui/core';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/useRedux';
import { useTableSearch } from '../../../../../../hooks/useTableSearch';
import StatusChip from '../../../../../shared/components/badges/StatusChip';
import AddButton from '../../../../../shared/components/tables/buttons/AddButton';
import DeleteButton from '../../../../../shared/components/tables/buttons/DeleteButton';
import EditButton from '../../../../../shared/components/tables/buttons/EditButton';
import SearchPaginatedTable from '../../../../../shared/components/tables/SearchPaginatedTable';
import { renderSkeletonTable } from '../../../../../shared/helpers/render';
import { ISearchTable } from '../../../../../shared/interfaces/paginatedTable.interface';
import { useGetEvaluationTypesQuery } from '../slices/evaluationTypeApiSlice';
import {
  setEvaluationTypeDeleteModalOpen,
  setEvaluationTypeIdSelected,
  setEvaluationTypeIdToDelete,
  setEvaluationTypeList,
  setEvaluationTypeSideDialogOpen,
  setEvaluationTypeTable,
  setStatusEvaluationType,
} from '../slices/evaluationTypeSlice';

const EvaluationTypeTable = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const {
    evaluationTypeList,
    evaluationTypeTable: { page, perPage, search },
    status,
  } = useAppSelector((state) => state.evaluationType);

  const { setPage, setPerPage, setSearch } = useTableSearch({
    page,
    perPage,
    search,
    updateFunction: setEvaluationTypeTable as ActionCreatorWithPayload<ISearchTable, string>,
  });

  const { data, isLoading, isFetching, error } = useGetEvaluationTypesQuery({
    page,
    perPage,
    search,
    ...(status && { status }),
  });

  useEffect(() => {
    if (data?.data) {
      dispatch(setEvaluationTypeList(data?.data));
    }
  }, [data, dispatch]);

  const datafetch = evaluationTypeList.map((item, idx) => ({
    id: (page - 1) * perPage + (idx + 1),
    name: item.name || 'Sin nombre asignado',
    description: item.description,
    acronym: item.acronym,
    status: item.status,
    options: { id: item.uuid },
  }));

  const statusOptions = [
    { value: 'A', label: t('maintenance.scopes.status.active') },
    { value: 'I', label: t('maintenance.scopes.status.inactive') },
  ];

  const goToAdd = () => {
    dispatch(setEvaluationTypeSideDialogOpen(true));
  };

  const handleEdit = (id: string) => {
    dispatch(setEvaluationTypeIdSelected(id));
    dispatch(setEvaluationTypeSideDialogOpen(true));
  };

  const handleDelete = (id: string) => {
    dispatch(setEvaluationTypeIdToDelete(id));
    dispatch(setEvaluationTypeDeleteModalOpen(true));
  };

  return isLoading ? (
    renderSkeletonTable(10, 5)
  ) : (
    <SearchPaginatedTable
      data={datafetch!}
      headers={{
        id: t('N°'),
        name: t('maintenance.evaluation_type.name'),
        description: t('maintenance.evaluation_type.description'),
        acronym: t('maintenance.evaluation_type.acronym'),
        status: t('maintenance.evaluation_type.status'),
        options: t('maintenance.evaluation_type.options'),
      }}
      keyExtractor={(item) => item.id!.toString()}
      searchPlacehoder={t('maintenance.evaluation_type.search')}
      isLoading={isLoading}
      isFetching={isFetching}
      error={error as any}
      perPage={perPage}
      setPerPage={setPerPage}
      total={data?.total || 0}
      page={page}
      setPage={setPage}
      numHeader={5}
      search={search}
      setSearch={setSearch}
      customDataCellsProperties={{
        id: {
          align: 'center',
        },
        name: {
          align: 'center',
        },
        description: {
          align: 'center',
        },
        acronym: {
          align: 'center',
        },
        status: {
          align: 'center',
        },
        options: {
          align: 'center',
        },
      }}
      customRenderers={{
        // eslint-disable-next-line react/no-unstable-nested-components
        status: (row) => (
          <>
            <StatusChip statusId={row.status === 'A' ? 1 : 2} />
          </>
        ),
        options: (row) => (
          <>
            <EditButton handleEdit={() => handleEdit(row.options.id!)} />
            <DeleteButton handleDelete={() => handleDelete(row.options.id!)} />
          </>
        ),
      }}
      ActionButtons={<AddButton size="large" onClick={goToAdd} />}
      additionalFilters={
        <>
          <Grid container spacing={1}>
            <Grid item xs={12} md={4}>
              <Autocomplete
                options={statusOptions}
                value={statusOptions.find((item) => item.value === status)}
                onChange={(_, value) => {
                  dispatch(setStatusEvaluationType(value?.value!));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('maintenance.scopes.form.status')}
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </Grid>
          </Grid>
        </>
      }
    />
  );
};

export default EvaluationTypeTable;
