const projectPermissions = {
  persons: {
    groupKey: 'persons',

    findPerson: 'persons-obtener-person-by-params',
    updatePerson: 'persons-actualizar-person',
    createPerson: 'persons-crear-person',
  },

  student: {
    groupKey: 'student',

    accountStatusPdf: 'student-descarga-estado-cuenta-estudiante-pdf',
    getDebstByStudents: 'debts-student-listar-deudas-estudiante',
  },

  catalog: {
    groupKey: 'catalog',

    getChildrenCatalogByAcro: 'catalogs-obtener-catalog',
    getpaymentArray: 'catalogs-keyword-select',
    getChildrenByAcronymOther: 'catalogs-children-acronym',
  },

  applications: {
    groupKey: 'applications',

    uploadFile: 'applications-others-upload-files',
  },

  fees: {
    groupKey: 'fees',

    setDateExtension: 'fees-setNameIdentifier',
  },

  financialInformation: {
    groupKey: 'financial-information',

    getIdDebtor: 'financial-information-obtener-id-debtor',
  },

  financialInstitutions: {
    groupKey: 'financial-institutions',

    read: 'financial-institutions-accounts-listar',
    getFinancialInstituteChildren:
      'financial-institutions-obtener-intituciones-financieras-hijas-del-keyword-solicitado',
  },

  debtors: {
    groupKey: 'debtors',

    update: 'debtors-actualizar-deudor',
    getDebtorsByStudent: 'debtors-obtener-deudores-por-estudiante',
    getDebtorPerPerson: 'debtors-obtener-deudor-persona',
  },

  groupConfiguration: {
    groupKey: 'group-configuration',

    read: 'group-configurations-obtener-todos',
    store: 'group-configuration-students-crear',
  },

  groupConfigurationStudents: {
    groupKey: 'group-configuration-students',

    groupConfigurationByStudent:
      'group-configuration-students-obtener-configuracion-por-estudiante',
    delete: 'group-configuration-students-destroy',
    disabledGroup: 'group-configuration-students-desactivar-por-estudiante',
    setMassiveDiscounts: 'group-configuration-students-crear-massive',
  },

  cashPoint: {
    groupKey: 'cash-point',

    getUsersCash: 'cash-point-list-user-listar-usuarios',
  },

  cashCollection: {
    groupKey: 'cash-collection',

    collectionPaymentMethod: 'collection-web-list-deposito-or-transferencias-method-paid',
    collectionStatus: 'collection-web-list-deposito-or-transferencias-change-status',

    updateInfoTransferOrDeposit: 'cash-collection-web-guardar-deposito-or-tranferencia-change-data',
    methodPreviousMonths: 'cash-collection-web-guardar-deposito-or-tranferencia-previous-months',
  },
};

export default Object.freeze(projectPermissions);
