import { useState } from 'react';

const usePaginationAndSearch = (pageInitial?: number, perPageInitial?: number) => {
  const [page, setPage] = useState<number>(pageInitial || 1);
  const [alert, setAlert] = useState(false);
  const [perPage, setPerPage] = useState<number>(perPageInitial || 10);
  const [search, setSearch] = useState<string>('');

  return {
    page,
    setPage,
    alert,
    setAlert,
    perPage,
    setPerPage,
    search,
    setSearch,
  };
};

export default usePaginationAndSearch;
