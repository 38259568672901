import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/useRedux';
import CustomSideDialog from '../../../../../shared/components/dialogs/CustomSideDialog';
import { setScopesIdSelected, setScopesSideDialogOpen } from '../slices/scopeSlice';
import ScopeForm from './ScopeForm';

const ScopeSideDialog = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { scopesIdSelected } = useAppSelector((state) => state.scopes);

  const handleClose = () => {
    dispatch(setScopesSideDialogOpen(false));
    dispatch(setScopesIdSelected(null));
  };

  return (
    <CustomSideDialog
      open
      handleCloseDialog={handleClose}
      title={
        scopesIdSelected ? t('maintenance.scopes.edit.title') : t('maintenance.scopes.add.title')
      }
    >
      <ScopeForm />
    </CustomSideDialog>
  );
};

export default ScopeSideDialog;
