import { Grid, Typography } from '@material-ui/core';
import { useAppSelector } from '../../../../hooks/useRedux';
import MainCard from '../../../shared/components/cards/MainCard';
import DeleteInstituteDialog from '../components/DeleteInstituteDialog';
import InstitutesTable from '../components/InstitutesTable';

const InstituteScreen = () => {
  // Verify permission
  const { instituteIdToDelete } = useAppSelector((state) => state.institutes);

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">Institutos</Typography>
      </Grid>

      <InstitutesTable />

      {instituteIdToDelete && <DeleteInstituteDialog />}
    </Grid>
  );
};

export default InstituteScreen;
