import { useMemo } from 'react';
import { useAppSelector } from '../../../../hooks/useRedux';
import useGetDayTypes from './useGetDayTypes';

const useGetHolidays = () => {
  const { holidaysList } = useAppSelector((state) => state.academicPlanningPosgraduate);

  const { holiday } = useGetDayTypes();

  const holidays = useMemo(
    () => [
      ...(holidaysList?.map((thisHoliday) => ({
        start: new Date(
          Number(thisHoliday?.year),
          Number(thisHoliday?.month) - 1,
          Number(thisHoliday?.day)
        ),
        end: new Date(
          Number(thisHoliday?.year),
          Number(thisHoliday?.month) - 1,
          Number(thisHoliday?.day)
        ),
        title: thisHoliday?.description,
        color: holiday?.pdt_colour || '#ff0000',
        id: thisHoliday?.id,
        typeId: thisHoliday?.postgraduate_day_type?.pdt_keywork,
        droppedOnAllDaySlot: false,
      })) || []),
    ],
    [holidaysList, holiday]
  );

  return holidays;
};

export default useGetHolidays;
