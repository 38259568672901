import { useTranslation } from 'react-i18next';
import DeleteAlert from '../../../../shared/components/dialogs/DeleteAlert';
// import DeleteAlert from '../../../../shared/components/dialogs/DeleteAlert';
import { useDeleteStudentDocumentMutation } from '../../slices/studentsApiSlice';
// import { resetRoleIdToDelete, setDeleteModalOpen } from '../slices/rolesSlice';

interface Props {
  openModal: boolean;
  dataId: number;
  handleCloseModal: () => void;
}

const DeleteDocDialog = ({ openModal, dataId, handleCloseModal }: Props) => {
  const { t } = useTranslation();
  const [deleteDoc, { isLoading, isSuccess, isError, error }] = useDeleteStudentDocumentMutation();

  const handleDelete = () => {
    deleteDoc({ studentDocumentId: dataId });
  };

  const handleClose = () => {
    handleCloseModal();
  };
  //

  return (
    <DeleteAlert
      open={openModal}
      onClose={handleClose}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      handleDelete={handleDelete}
      deleteTitle={t('academic.areas.delete.title')}
      error={error as string}
    />
  );
};

export default DeleteDocDialog;
