import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  Skeleton,
  TextField,
} from '@material-ui/core';

import { useFormik } from 'formik';

import ErrorAlert from '../../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../../shared/components/alerts/SuccessAlert';
import InputLabel from '../../../../shared/components/forms/InputLabel';
import { useAddAnnouncementMutation } from '../slices/announcementTeacherApiSlice';
import { useGetCourseStudentsQuery } from '../../../courses/slices/coursesTeacherApiSlice';
import { createAnnouncement } from '../constants/validation-schemas';
import { useAppSelector } from '../../../../../hooks/useRedux';
import { useTranslation } from 'react-i18next';

const SendAnnouncentForm = () => {
  const history = useHistory();

  const [showAlerts, setShowAlerts] = useState(true);

  const { t } = useTranslation();

  const {
    course: { courseId },
  } = useAppSelector((state) => state.virtualClassroomTeacherShared);

  const [sendAnnouncement, { isLoading, isError, error, isSuccess }] = useAddAnnouncementMutation();

  const { data, isLoading: isLoadingCourse } = useGetCourseStudentsQuery(
    {
      courseId: courseId!,
      payload: { data: 'all' },
    },
    { skip: !courseId }
  );

  const { getFieldProps, errors, touched, handleSubmit } = useFormik({
    initialValues: {
      ansMessage: '',
    },
    validationSchema: createAnnouncement,
    onSubmit: async (values) => {
      await sendAnnouncement({
        announcementdPayload: {
          ans_message: values.ansMessage,
          receiver: data?.map((student) => Number(student.user_id)),
        },
        courseId: courseId!,
      }).unwrap;

      setShowAlerts(true);
    },
  });

  useEffect(() => {
    if (data?.length === 0) {
      setShowAlerts(true);
    }
  }, [data]);

  return (
    <>
      {isLoadingCourse ? (
        <Skeleton>
          <Grid container spacing={2} sx={{ height: '100px' }}></Grid>
        </Skeleton>
      ) : (
        <form onSubmit={handleSubmit}>
          <Grid container direction="column" gap={2}>
            <Grid item>
              {data?.length === 0 && (
                <ErrorAlert message="No hay estudiantes inscritos en este curso" />
              )}
              {isError && showAlerts && (
                <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
              )}

              {isSuccess && showAlerts && (
                <SuccessAlert
                  message={t('teacher_virtual_classroom.send_announcements_form.success_message')}
                  handleDismiss={() => setShowAlerts(false)}
                />
              )}
            </Grid>

            <Grid item container spacing={2} alignItems="center">
              <Grid item xs={12}>
                <InputLabel>
                  {t('teacher_virtual_classroom.send_announcements_form.announcement_label')}
                </InputLabel>
                <TextField
                  fullWidth
                  multiline
                  minRows={2}
                  error={Boolean(errors.ansMessage && touched.ansMessage)}
                  placeholder={t(
                    'teacher_virtual_classroom.send_announcements_form.announcement_placeholder'
                  )}
                  {...getFieldProps('ansMessage')}
                />
                {errors.ansMessage && touched.ansMessage && (
                  <FormHelperText error>{errors.ansMessage}</FormHelperText>
                )}
              </Grid>
            </Grid>

            <Grid
              item
              container
              alignItems="center"
              justifyContent="flex-end"
              spacing={2}
              sx={{ mt: 1 }}
            >
              <Grid item>
                <Button
                  variant="outlined"
                  size="large"
                  color="primary"
                  onClick={() => history.goBack()}
                >
                  {t('teacher_virtual_classroom.send_announcements_form.announcement_return')}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  color="primary"
                  disabled={isLoading || data?.length === 0}
                  endIcon={isLoading && <CircularProgress size={20} />}
                >
                  {t('teacher_virtual_classroom.send_announcements_form.announcement_send')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}
    </>
  );
};

export default SendAnnouncentForm;
