import { useState } from 'react';

import { useHistory, useLocation } from 'react-router-dom';
import { Grid, IconButton, Typography, useTheme } from '@material-ui/core';
import { IconArrowNarrowLeft } from '@tabler/icons';

// import MainCardForm from '../../../shared/components/cards/MainCardForm';
// import Checkout from '../components/Checkout';
import CheckoutWizard from '../components/CheckoutWizard';
import ContactFoot from '../components/builder/ContactFoot';

const CheckoutScreen = () => {
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();

  const [activeStep, setActiveStep] = useState(1);
  const [errorIndex, setErrorIndex] = useState<number | null>(null);

  const handleSetError = (i: number | null) => {
    setErrorIndex(i);
  };

  const handleActiveStep = (step: number) => {
    setActiveStep(step);
  };

  const handleNext = () => {
    setActiveStep(activeStep + 1);
    setErrorIndex(null);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <Grid container>
      <Grid item xs={12} xl={'auto'}>
        <Grid item xs={12} sx={{ padding: 4, bgcolor: 'background.paper', borderRadius: 2 }}>
          <Grid item xs={12}>
            <Grid item container alignItems="center" gap={1} sx={{ cursor: 'pointer' }}>
              <IconButton onClick={() => history.push(`/${location.pathname.split('/')[1]}`)}>
                <IconArrowNarrowLeft size={30} color={theme.palette.text.dark} />
              </IconButton>
              <Typography variant="h2">Pagar</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ mt: 2 }}>
            <CheckoutWizard
              activeStep={activeStep}
              errorIndex={errorIndex}
              setActiveStep={handleActiveStep}
              handleNext={handleNext}
              handleBack={handleBack}
              setErrorIndex={handleSetError}
            />

            <ContactFoot />
            {/* {false && <Checkout />} */}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CheckoutScreen;
