import Skeleton from '@material-ui/core/Skeleton';
import { Grid } from '@material-ui/core';

const LoadingData = () => {
  return (

    <Grid container direction="row" spacing={3} style={{marginTop:30 , marginLeft: 20}} gap={3}>
      <Skeleton variant="rectangular" key="skeleton1" width={300} height={300} sx={{ borderRadius: 2 }} />
      <Skeleton variant="rectangular" key="skeleton2"width={300} height={300} sx={{ borderRadius: 2 }} />
      <Skeleton variant="rectangular" key="skeleton3" width={300} height={300} sx={{ borderRadius: 2 }} />
      <Skeleton variant="rectangular" key="skeleton4"width={300} height={300} sx={{ borderRadius: 2 }} />
      <Skeleton variant="rectangular" key="skeleton5"width={300} height={300} sx={{ borderRadius: 2 }} />
    </Grid>
  );
};

export default LoadingData;