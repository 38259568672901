import Autocomplete from '@material-ui/core/Autocomplete';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LocalizationProvider } from '@material-ui/lab';
import DateRangePicker, { DateRange } from '@material-ui/lab/DateRangePicker';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';

import ErrorAlert from '../../../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../../../shared/components/alerts/SuccessAlert';
import { statusAvailables } from '../../../../../shared/constants/resourceStatus';
import {
  useAddStudentProjectResearchMutation,
  useGetProjectsResearchAvailablesQuery,
} from '../../../slices/InvestigationLinesApiSlice';
import InputLabel from '../../../../../shared/components/forms/InputLabel';
import { studentProjectResearch } from '../../../constants/validationSchemas.projects';
import CustomAsyncPaginatedSelect from '../../../../../shared/components/extended/Form/CustomAsyncPaginatedSelect';
import { useLazyGetStudentsQuery } from '../../../../students/slices/studentsApiSlice';
import TextFieldFetching from '../../../../../shared/components/TextFieldFetching';

interface Props {
  projectId?: string;
}

const AddStudentProjectForm = ({ projectId }: Props) => {
  const { t } = useTranslation();

  const history = useHistory();

  const [showAlerts, setShowAlerts] = useState(true);

  const [addStudentProject, { isLoading, isError, error, isSuccess }] =
    useAddStudentProjectResearchMutation();

  const { data: projectsResearchAvailables, isLoading: isLoadingProjects } =
    useGetProjectsResearchAvailablesQuery({});

  const [getStudentsTrigger] = useLazyGetStudentsQuery();

  //TODO: crear hook en el componente del select
  const getStudents = async (search: string, page: number, perPage: number) => {
    const response = await getStudentsTrigger({
      page,
      perPage,
      search,
    }).unwrap();

    const hasMore = response.current_page < response.last_page;

    return {
      options: response.data.map((dt) => {
        return {
          value: dt.id,
          label: (dt as any).pers_full_lastname || (dt as any).pers_full_name,
          ...dt,
        };
      }),
      hasMore,
    };
  };

  const { getFieldProps, errors, touched, handleSubmit, setFieldValue, values } = useFormik({
    initialValues: {
      projectResearchId: projectId || '',
      studentId: '',
      status: statusAvailables[0]?.acronym || '',
      startDate: new Date(),
      endDate: new Date(),
      numberHours: 0,
    },
    validationSchema: studentProjectResearch,
    onSubmit: async ({ projectResearchId, studentId, status, startDate, endDate, numberHours }) => {
      await addStudentProject({
        studentProjectPayload: {
          student_id: (studentId as any).id || '0',
          number_hours: numberHours,
          project_research_id: projectResearchId,
          start_date: startDate,
          end_date: endDate,
          status,
        },
      });
      window.scrollTo(0, 0);
      setShowAlerts(true);
    },
  });

  const onChangeDates = (newDatesValues: DateRange<Date>) => {
    const [start, end] = newDatesValues;
    // update formik values
    setFieldValue('startDate', start);
    setFieldValue('endDate', end);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction="column" gap={2}>
        <Grid item xs={12} md={6}>
          <Grid container direction="column" spacing={2}>
            {isError && showAlerts && (
              <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
            )}

            {isSuccess && showAlerts && (
              <SuccessAlert
                message={t('Estudiante agregado con éxito')}
                handleDismiss={() => setShowAlerts(false)}
              />
            )}

            <Grid item xs={12}>
              <InputLabel>{t('Proyectos de investigación')}</InputLabel>
              {isLoadingProjects ? (
                <TextFieldFetching />
              ) : (
                <Autocomplete
                  options={projectsResearchAvailables || []}
                  loading={isLoadingProjects}
                  loadingText={t('academic.subjects.add.edu_level.loading')}
                  noOptionsText={t('academic.subjects.add.edu_level.no_data')}
                  disabled={!!projectId}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(errors.projectResearchId && touched.projectResearchId)}
                      placeholder={t('academic.subjects.add.edu_level.placeholder')}
                    />
                  )}
                  onChange={(_, value) => {
                    setFieldValue('projectResearchId', value?.id || '');
                  }}
                  value={projectsResearchAvailables?.find(
                    ({ id }) => id === values.projectResearchId
                  )}
                  fullWidth
                  clearIcon={null}
                />
              )}
              {errors.projectResearchId && touched.projectResearchId && (
                <FormHelperText error>{t(errors.projectResearchId)}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12} md={9}>
              <InputLabel>{t('Número de Horas')}</InputLabel>
              <TextField
                {...getFieldProps('numberHours')}
                error={Boolean(errors.numberHours && touched.numberHours)}
                placeholder={t('academic.subjects.add.numberHours.placeholder')}
                fullWidth
              />
              {errors.numberHours && touched.numberHours && (
                <FormHelperText error>{t(errors.numberHours)}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12}>
              <InputLabel>Estudiante</InputLabel>
              <CustomAsyncPaginatedSelect
                key={'studentId'}
                value={values.studentId}
                isError={'studentId' in errors && touched['studentId'] ? true : false}
                placeholder={'Escoger estudiante'}
                setValue={(value) => setFieldValue('studentId', value)}
                fetchFunc={getStudents as any}
              />
              {errors.studentId && touched.studentId && (
                <FormHelperText error>{t(errors.studentId)}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateRangePicker
                  startText={null}
                  endText={null}
                  value={[values.startDate, values.endDate]}
                  onChange={onChangeDates}
                  renderInput={(startProps, endProps) => (
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12} md={6}>
                        <InputLabel>Fecha de Inicio:</InputLabel>
                        <TextField
                          fullWidth
                          {...startProps}
                          helperText=""
                          error={Boolean(errors.startDate && touched.startDate)}
                          {...getFieldProps('startDate')}
                        />
                        {errors.startDate && touched.startDate && (
                          <FormHelperText error>{errors.startDate}</FormHelperText>
                        )}
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <InputLabel>Fecha de Fin planeación:</InputLabel>
                        <TextField
                          fullWidth
                          {...endProps}
                          helperText=""
                          error={Boolean(errors.endDate && touched.endDate)}
                          {...getFieldProps('endDate')}
                        />

                        {errors.endDate && touched.endDate && (
                          <FormHelperText error>{errors.endDate}</FormHelperText>
                        )}
                      </Grid>
                    </Grid>
                  )}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>

          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            spacing={2}
            sx={{ mt: 6 }}
          >
            <Grid item xs={6}>
              {!projectId && (
                <Button
                  fullWidth
                  variant="outlined"
                  size="large"
                  color="primary"
                  onClick={() => history.goBack()}
                >
                  {t('academic.subjects.add.back')}
                </Button>
              )}
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                type="submit"
                variant="contained"
                size="large"
                color="primary"
                disabled={isLoading}
                endIcon={isLoading && <CircularProgress size={20} />}
              >
                {t('academic.subjects.add.submit')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default AddStudentProjectForm;
