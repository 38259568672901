import { useAppDispatch, useAppSelector } from '../../../../../../hooks/useRedux';
import DeleteAlert from '../../../../../shared/components/dialogs/DeleteAlert';
import { useDeleteRepresentativeScaleMutation } from '../slices/optionsTemplateApiSlice';
import {
  setOptionsTemplateIdToDelete,
  setRepresentativeDeleteModalOpen,
} from '../slices/optionsTemplateSlice';

const RepresentativeScaleDeleteDialog = () => {
  const dispatch = useAppDispatch();
  const { optionsTemplateIdToDelete, representativeDeleteModalOpen } = useAppSelector(
    (state) => state.optionsTemplate
  );

  const [deleteOptionsTemplate, { isLoading, isSuccess, error, isError }] =
    useDeleteRepresentativeScaleMutation();

  const handleDelete = () => {
    deleteOptionsTemplate({
      id: optionsTemplateIdToDelete!,
    });
  };
  const handleClose = () => {
    dispatch(setOptionsTemplateIdToDelete(null));
    dispatch(setRepresentativeDeleteModalOpen(false));
  };

  return (
    <DeleteAlert
      open={representativeDeleteModalOpen}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      error={error as any}
      handleDelete={handleDelete}
      onClose={handleClose}
    />
  );
};

export default RepresentativeScaleDeleteDialog;
