import React, { useState, ChangeEvent, useEffect } from 'react';

import {
  Button,
  DialogActions,
  DialogContent,
  Slide,
  SlideProps,
  Stack,
  Typography,
  Grid,
  TextField,
  FormHelperText,
  CircularProgress,
  Autocomplete,
  InputAdornment,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { IconX } from '@tabler/icons';
// Date
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import esLocale from 'date-fns/locale/es';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import DatePicker from '@material-ui/lab/DatePicker';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

import InputLabel from '../../../../shared/components/forms/InputLabel';
import { useAppDispatch } from '../../../../../hooks/useRedux';
import {
  setListAcademicFormations,
  updateListAcademicFormations,
} from '../../slices/requisitionsSlice';
import ErrorAlert from '../../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../../shared/components/alerts/SuccessAlert';
import { baseUrl } from '../../../../../api/mainApi';
// data available
import { useGetAvailableInstitutesQuery } from '../../../institutes/slices/institutesApiSlice';
import {
  useGetAcademicFormationStatusesQuery,
  useGetAvailablesTypeEducationsQuery,
} from '../../slices/requisitionsApiSlice';
import {
  useLazyGetCatalogByAcronymChildrenQuery,
  useGetCatalogByAcronymQuery,
} from '../../../../shared/slices/catalogApi';
import typeEducationInstitute from '../../constants/typeEducationInstitute';
import TextFieldFetching from '../../../../shared/components/TextFieldFetching';
// upload Files
import { useSendFilesRequisitionMutation } from '../../slices/requisitionsApiSlice';
// import PerfectScrollbar from 'react-perfect-scrollbar';
// redux
import { useAppSelector } from '../../../../../hooks/useRedux';
import AnimateButton from '../../../../shared/components/extended/AnimateButton';
import ExtendedSideDialog from '../../../../shared/components/dialogs/ExtendedSideDialog';
import { Catalog } from '../../../../shared/interfaces/catalogs.interfaces';
import { useGetLocaleDateLang } from '../../../../../translation/hooks/useGetLocaleDateLang';
import { DateRangePicker } from '@material-ui/lab';
import { studyStatusList } from '../../constants/statusRequisition';
import { format, parseISO } from 'date-fns';
import { AcademicFormation } from '../../interfaces/academicFormation.interface';

const validationSchema = yup.object({
  certificateTitle: yup
    .string()
    .required('academic.requisition.edit.wizard.step2.add_academic_form.dialog.title.required'),
  graduationDate: yup
    .string()
    .required('academic.requisition.edit.wizard.step2.add_academic_form.dialog.grad_date.required'),
  educationCalification: yup.number(),
  // .required('academic.requisition.edit.wizard.step2.add_academic_form.dialog.grade.required'),
  educationObservation: yup.string(),
  document: yup.string(),
  instituteId: yup
    .object({
      id: yup.number(),
      name: yup.string(),
    })
    .test(({ id }) => {
      if (!id || id <= 0) {
        return new yup.ValidationError(
          'academic.requisition.edit.wizard.step2.add_academic_form.dialog.institute.required',
          null,
          'instituteId'
        );
      }
      return true;
    })
    .required('academic.requisition.edit.wizard.step2.add_academic_form.dialog.institute.required'),
  cityId: yup
    .object({
      id: yup.number(),
      name: yup.string(),
    })
    .test(({ id }) => {
      if (!id || id <= 0) {
        return new yup.ValidationError(
          'academic.requisition.edit.wizard.step2.add_academic_form.dialog.institute.required',
          null,
          'cityId'
        );
      }
      return true;
    })
    .required('academic.requisition.edit.wizard.step2.add_academic_form.dialog.city.required'),
  typeEducationId: yup
    .object({
      id: yup.number(),
      name: yup.string(),
    })
    .test(({ id }) => {
      if (!id || id <= 0) {
        return new yup.ValidationError(
          'academic.requisition.edit.wizard.step2.add_academic_form.dialog.edu_type.required',
          null,
          'typeEducationId'
        );
      }
      return true;
    })
    .required('academic.requisition.edit.wizard.step2.add_academic_form.dialog.edu_type.required'),
  statusId: yup
    .number()
    .required('academic.requisition.edit.wizard.step2.add_academic_form.dialog.status.required'),
  iesCode: yup
    .string()
    .required('academic.requisition.edit.wizard.step2.add_academic_form.dialog.ies_code.required'),
  senescytRegisterNumber: yup
    .string()
    .required(
      'academic.requisition.edit.wizard.step2.add_academic_form.dialog.senescyt_code.required'
    ),
  unescoCode: yup
    .string()
    .required(
      'academic.requisition.edit.wizard.step2.add_academic_form.dialog.unesco_code.required'
    ),
  startDate: yup
    .date()
    .required(
      'academic.requisition.edit.wizard.step2.add_academic_form.dialog.study_date.start.required'
    )
    .typeError(
      'academic.requisition.edit.wizard.step2.add_academic_form.dialog.study_date.start.required'
    ),
  endDate: yup
    .date()
    .required(
      'academic.requisition.edit.wizard.step2.add_academic_form.dialog.study_date.end.required'
    )
    .typeError(
      'academic.requisition.edit.wizard.step2.add_academic_form.dialog.study_date.end.required'
    ),
  educationStatus: yup
    .string()
    .required(
      'academic.requisition.edit.wizard.step2.add_academic_form.dialog.study_status.required'
    ),

  countryId: yup
    .number()
    .required('academic.requisition.edit.wizard.step2.add_academic_form.dialog.country.required')
    .min(1, 'academic.requisition.edit.wizard.step2.add_academic_form.dialog.country.required'),
});

const useStyles = makeStyles((theme) => ({
  userAddDialog: {
    '&>div:nth-child(3)': {
      justifyContent: 'flex-end',
      '&>div': {
        margin: '0px',
        borderRadius: '0px',
        maxWidth: '450px',
        maxHeight: '100%',
      },
    },
  },
  paper: {
    minHeight: '100vh',
    width: '100%',
  },
  learningComponents: {
    fontSize: '16px',
    fontWeight: 600,
    color: theme.palette.primary.main,
    marginBottom: '8px',
  },
  selectSubject: {
    fontSize: '16px',
    fontWeight: 600,
    color: theme.palette.primary[800],
    marginBottom: '8px',
  },
  selectSubjectType: {
    fontSize: '14px',
    fontWeight: 600,
    color: theme.palette.secondary.main,
    marginBottom: '8px',
  },
}));

const Transition = React.forwardRef(function Transition(props: SlideProps, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

interface Props {
  open: boolean;
  handleCloseDialog: () => void;
  academicFormation?: AcademicFormation;
}

const AcademicFormationDialog = ({ open, handleCloseDialog, academicFormation }: Props) => {
  const { t } = useTranslation();

  const { lang } = useGetLocaleDateLang();

  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [showAlerts, setShowAlerts] = useState(true);

  const { currentProfile } = useAppSelector((state) => state.access);

  const [availablesCities, setAvailablesCities] = useState<Catalog[]>([]);

  const profileId = currentProfile?.profileId || -1;

  const { data: availablesTypeEducations = [], isLoading: isLoadingTypeEducations } =
    useGetAvailablesTypeEducationsQuery({
      profileId,
    });

  const { data: countries = [], isLoading: isLoadingCountries } = useGetCatalogByAcronymQuery({
    profileId,
    acronym: 'PS',
  });

  const [getProvinces, { isLoading: isLoadingCities }] = useLazyGetCatalogByAcronymChildrenQuery();

  const [
    sendFile,
    { isLoading: isLoadingFile, isError: isErrorFile, isSuccess: isSuccessFile, error },
  ] = useSendFilesRequisitionMutation();

  const { data: availableStatuses, isLoading: isLoadingStatuses } =
    useGetAcademicFormationStatusesQuery({});

  const { errors, touched, handleSubmit, getFieldProps, setFieldValue, values, resetForm } =
    useFormik({
      initialValues: {
        certificateTitle: academicFormation?.certificate_title || '',
        graduationDate: academicFormation?.graduation_date
          ? parseISO(academicFormation?.graduation_date as string)
          : new Date(),
        educationCalification: academicFormation?.education_calification || undefined,
        educationObservation: academicFormation?.education_observation || '',
        document: '',
        instituteId: academicFormation?.institute_id || { name: '', id: -1, typeInstituteId: -1 },
        cityId: academicFormation?.city_id || { name: '', id: -1 },
        typeEducationId: academicFormation?.type_education_id || { name: '', id: -1 },
        statusId: 1,
        typeEducationInstitute: academicFormation?.institute_id?.typeInstituteId || 0,
        countryId:
          academicFormation?.country_id && !isNaN(academicFormation?.country_id)
            ? academicFormation?.country_id
            : 0,
        iesCode: academicFormation?.ies_code || '',
        senescytRegisterNumber: academicFormation?.senescyt_register_number || '',
        unescoCode: academicFormation?.unesco_code || '',
        startDate: academicFormation?.start_date
          ? parseISO(academicFormation?.start_date as string)
          : new Date(),
        endDate: academicFormation?.end_date
          ? parseISO(academicFormation?.end_date as string)
          : new Date(),
        educationStatus: academicFormation?.education_status || '',
      },
      validationSchema: validationSchema,
      onSubmit: async (values) => {
        var sendFileW;

        if (target) {
          sendFileW = await sendFile({
            filePayload: {
              files: target!,
              type_document: 6,
            },
            profileId,
          }).unwrap();
        }

        const dataArray = sendFileW?.split('/');

        const item = {
          id: academicFormation?.id || Date.now(),
          certificate_title: values.certificateTitle,
          graduation_date: values.graduationDate.toISOString().split('T')[0],
          education_calification: values.educationCalification,
          education_observation: values.educationObservation,
          document_url: dataArray
            ? `${baseUrl}/show?name=${dataArray[0]}/${dataArray[1]}/${dataArray[2]}`
            : '',
          institute_id: values.instituteId,
          city_id: values.cityId,
          type_education_id: values.typeEducationId,
          status_id: values.statusId,
          country_id: values.countryId,
          ies_code: values.iesCode,
          senescyt_register_number: values.senescytRegisterNumber,
          unesco_code: values.unescoCode,
          start_date: format(values.startDate, 'yyyy-MM-dd'),
          end_date: format(values.endDate, 'yyyy-MM-dd'),
          education_status: values.educationStatus,
        };
        //              format
        //Web\/IMAGEN_FORO\/1644855161_620a7f79d70a3.png

        if (academicFormation?.id) {
          dispatch(updateListAcademicFormations(item));
        } else {
          dispatch(setListAcademicFormations([item]));
        }
        resetForm();
        setTarget(null!);
        handleCloseDialog();
      },
    });

  const { data: availablesInstitutes, isLoading: isLoadingInstitutes } =
    useGetAvailableInstitutesQuery(
      {
        profileId,
        typeInstituteId: values?.typeEducationInstitute || 0,
      },
      { skip: !values.typeEducationInstitute }
    );

  const [, setFile] = useState('');

  const [target, setTarget] = useState<File>(null!);

  const onChangePicture = function (event: ChangeEvent<HTMLInputElement>) {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e: any) => {
        setFile(e.target.result);
      };
      reader.readAsDataURL(event.target.files[0]);
      setTarget(event.target.files[0]);
    }
  };

  const cleanFiles = () => {
    setFile('');
    setTarget(null!);
    setFieldValue('document', '');
  };

  useEffect(() => {
    if (values.countryId && values.countryId !== 0) {
      getProvinces({
        profileId,
        acronym: countries?.find((country) => country.id === values.countryId)?.cat_keyword!,
      })
        .unwrap()
        .then((provinces) => {
          const cities = provinces?.reduce((acc: Catalog[], province) => {
            return [...acc, ...province.children];
          }, []);
          setAvailablesCities(cities);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.countryId]);

  useEffect(() => {
    if (availableStatuses) {
      setFieldValue('statusId', availableStatuses[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableStatuses]);

  return (
    <ExtendedSideDialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => {
        handleCloseDialog();
      }}
      className={classes.userAddDialog}
      classes={{
        paper: classes.paper,
      }}
    >
      {/* <PerfectScrollbar> */}
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Typography variant="h2">
            {t('academic.requisition.edit.wizard.step2.academic_form')}
          </Typography>

          <Stack spacing={2} sx={{ mt: 4 }}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <InputLabel>
                  {t('academic.requisition.edit.wizard.step2.add_academic_form.dialog.title')}
                </InputLabel>
                <TextField
                  fullWidth
                  placeholder={t(
                    'academic.requisition.edit.wizard.step2.add_academic_form.dialog.title.placeholder'
                  )}
                  {...getFieldProps('certificateTitle')}
                  error={Boolean(errors.certificateTitle && touched.certificateTitle)}
                />
                {errors.certificateTitle && touched.certificateTitle && (
                  <FormHelperText error>{t(errors.certificateTitle)}</FormHelperText>
                )}
              </Grid>

              <Grid item xs={12}>
                <InputLabel>Tipo de institución</InputLabel>

                <Autocomplete
                  options={typeEducationInstitute}
                  getOptionLabel={(option) => option.label}
                  value={
                    typeEducationInstitute.find(({ id }) => id === values.typeEducationInstitute) ||
                    null
                  }
                  // defaultValue={statusAvailables[0]}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(
                        errors.typeEducationInstitute && touched.typeEducationInstitute
                      )}
                    />
                  )}
                  onChange={(e, value) => setFieldValue('typeEducationInstitute', value?.id)}
                  fullWidth
                  clearIcon={null}
                />

                {errors && touched.typeEducationInstitute && (
                  <FormHelperText error>
                    {t(errors.typeEducationInstitute as string)}
                  </FormHelperText>
                )}
              </Grid>

              <Grid item xs={12}>
                <InputLabel>
                  {t('academic.requisition.edit.wizard.step2.add_academic_form.dialog.institute')}
                </InputLabel>
                {isLoadingInstitutes ? (
                  <>
                    <TextFieldFetching
                      placeholder={t(
                        'academic.requisition.edit.wizard.step2.add_academic_form.dialog.institute.loading'
                      )}
                    />
                  </>
                ) : (
                  <>
                    <Autocomplete
                      options={availablesInstitutes || []}
                      loading={isLoadingInstitutes}
                      loadingText={t(
                        'academic.requisition.edit.wizard.step2.add_academic_form.dialog.institute.loading'
                      )}
                      noOptionsText={t(
                        'academic.requisition.edit.wizard.step2.add_academic_form.dialog.institute.no_data'
                      )}
                      getOptionLabel={(option) => option.inst_name}
                      value={
                        availablesInstitutes?.find((e) => e.id === values.instituteId.id) || null
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={t(
                            'academic.requisition.edit.wizard.step2.add_academic_form.dialog.institute.placeholder'
                          )}
                          error={Boolean(errors.instituteId && touched.instituteId)}
                        />
                      )}
                      onChange={(e, value) =>
                        setFieldValue('instituteId', {
                          id: value?.id,
                          name: value?.inst_name,
                          typeInstituteId: Number(value?.type_institute_id),
                        })
                      }
                      fullWidth
                      clearIcon={null}
                    />
                    {errors.instituteId?.id && touched.instituteId?.id && (
                      <FormHelperText error>{t(errors.instituteId.id)}</FormHelperText>
                    )}
                    {errors.instituteId && touched.instituteId && (
                      <FormHelperText error>{t(errors.cityId as string)}</FormHelperText>
                    )}
                    {errors.instituteId?.name && touched.instituteId?.name && (
                      <FormHelperText error>{t(errors.instituteId?.name!)}</FormHelperText>
                    )}
                  </>
                )}
              </Grid>

              <Grid item xs={12}>
                <InputLabel>
                  {t('academic.requisition.edit.wizard.step2.add_academic_form.dialog.edu_type')}
                </InputLabel>
                {isLoadingTypeEducations ? (
                  <>
                    <TextField
                      disabled
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <CircularProgress size={25} />
                          </InputAdornment>
                        ),
                      }}
                      placeholder={t(
                        'academic.requisition.edit.wizard.step2.add_academic_form.dialog.edu_type.loading'
                      )}
                    />
                  </>
                ) : (
                  <>
                    <Autocomplete
                      options={availablesTypeEducations}
                      loading={isLoadingTypeEducations}
                      loadingText={t(
                        'academic.requisition.edit.wizard.step2.add_academic_form.dialog.edu_type.loading'
                      )}
                      noOptionsText={t(
                        'academic.requisition.edit.wizard.step2.add_academic_form.dialog.edu_type.no_data'
                      )}
                      getOptionLabel={(option) => option.typ_edu_name}
                      value={
                        availablesTypeEducations.find((e) => e.id === values.typeEducationId.id) ||
                        null
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={Boolean(errors.typeEducationId && touched.typeEducationId)}
                        />
                      )}
                      onChange={(e, value) =>
                        setFieldValue('typeEducationId', {
                          id: value?.id,
                          name: value?.typ_edu_name,
                        })
                      }
                      fullWidth
                      clearIcon={null}
                    />

                    {errors && touched.typeEducationId && (
                      <FormHelperText error>{t(errors.typeEducationId as string)}</FormHelperText>
                    )}
                  </>
                )}
              </Grid>

              <Grid item xs={12}>
                <InputLabel>
                  {t('academic.requisition.edit.wizard.step2.add_academic_form.dialog.ies_code')}
                </InputLabel>
                <TextField
                  fullWidth
                  {...getFieldProps('iesCode')}
                  error={Boolean(errors.iesCode && touched.iesCode)}
                />
                {errors.iesCode && touched.iesCode && (
                  <FormHelperText error>{t(errors.iesCode)}</FormHelperText>
                )}
              </Grid>

              <Grid item xs={12}>
                <InputLabel>
                  {t(
                    'academic.requisition.edit.wizard.step2.add_academic_form.dialog.senescyt_code'
                  )}
                </InputLabel>
                <TextField
                  fullWidth
                  {...getFieldProps('senescytRegisterNumber')}
                  error={Boolean(errors.senescytRegisterNumber && touched.senescytRegisterNumber)}
                />
                {errors.senescytRegisterNumber && touched.senescytRegisterNumber && (
                  <FormHelperText error>{t(errors.senescytRegisterNumber)}</FormHelperText>
                )}
              </Grid>

              <Grid item xs={12}>
                <InputLabel>
                  {t('academic.requisition.edit.wizard.step2.add_academic_form.dialog.unesco_code')}
                </InputLabel>
                <TextField
                  fullWidth
                  {...getFieldProps('unescoCode')}
                  error={Boolean(errors.unescoCode && touched.unescoCode)}
                />
                {errors.unescoCode && touched.unescoCode && (
                  <FormHelperText error>{t(errors.unescoCode)}</FormHelperText>
                )}
              </Grid>

              <Grid item xs={12}>
                {/* <InputLabel>
                  {t('academic.requisition.edit.wizard.step2.add_academic_form.dialog.study_date')} 
                </InputLabel> */}
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={lang}>
                  <DateRangePicker
                    startText={null}
                    endText={null}
                    value={[values.startDate, values.endDate]}
                    onChange={(newValue) => {
                      setFieldValue('startDate', newValue?.[0]);
                      setFieldValue('endDate', newValue?.[1]);
                    }}
                    renderInput={(startProps, endProps) => (
                      <Grid container spacing={3}>
                        <Grid item xs={6}>
                          <InputLabel>
                            {t(
                              'academic.requisition.edit.wizard.step2.add_academic_form.dialog.study_date.start'
                            )}
                          </InputLabel>
                          <TextField
                            {...startProps}
                            fullWidth
                            helperText={null}
                            error={Boolean(errors.startDate && touched.startDate)}
                          />
                          {errors.startDate && touched.startDate && (
                            <FormHelperText error>{t(errors.startDate as string)}</FormHelperText>
                          )}
                        </Grid>
                        <Grid item xs={6}>
                          <InputLabel>
                            {t(
                              'academic.requisition.edit.wizard.step2.add_academic_form.dialog.study_date.end'
                            )}
                          </InputLabel>
                          <TextField
                            {...endProps}
                            fullWidth
                            helperText={null}
                            error={Boolean(errors.endDate && touched.endDate)}
                          />
                          {errors.endDate && touched.endDate && (
                            <FormHelperText error>{t(errors.endDate as string)}</FormHelperText>
                          )}
                        </Grid>
                      </Grid>
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12}>
                <InputLabel>
                  {t(
                    'academic.requisition.edit.wizard.step2.add_academic_form.dialog.study_status'
                  )}
                </InputLabel>
                <Autocomplete
                  options={studyStatusList}
                  value={studyStatusList.find((e) => e.value === values.educationStatus) || null}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={t(
                        'academic.requisition.edit.wizard.step2.add_academic_form.dialog.study_status.placeholder'
                      )}
                      error={Boolean(errors.educationStatus && touched.educationStatus)}
                    />
                  )}
                  onChange={(e, value) => setFieldValue('educationStatus', value?.value)}
                  fullWidth
                  clearIcon={null}
                />
              </Grid>

              {values.educationStatus === 'Finalizado' && (
                <Grid item xs={12}>
                  <InputLabel>
                    {t('academic.requisition.edit.wizard.step2.add_academic_form.dialog.grad_date')}
                  </InputLabel>
                  <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                    <DatePicker
                      value={values.graduationDate}
                      onChange={(newValue) => {
                        setFieldValue('graduationDate', newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          helperText=""
                          placeholder={t(
                            'academic.requisition.edit.wizard.step2.add_academic_form.dialog.grad_date.placeholder'
                          )}
                          error={Boolean(errors.graduationDate && touched.graduationDate)}
                          fullWidth
                        />
                      )}
                    />
                  </LocalizationProvider>
                  {errors.graduationDate && touched.graduationDate && (
                    <FormHelperText error>{t(errors.graduationDate as string)}</FormHelperText>
                  )}
                </Grid>
              )}

              <Grid item xs={12}>
                <InputLabel>
                  {t('academic.requisition.edit.wizard.step2.add_academic_form.dialog.country')}
                </InputLabel>
                {isLoadingCountries ? (
                  <TextField
                    disabled
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <CircularProgress size={25} />
                        </InputAdornment>
                      ),
                    }}
                    placeholder={t(
                      'academic.requisition.edit.wizard.step2.add_academic_form.dialog.country.loading'
                    )}
                  />
                ) : (
                  <Autocomplete
                    options={countries}
                    loading={isLoadingCountries}
                    loadingText={t(
                      'academic.requisition.edit.wizard.step2.add_academic_form.dialog.country.loading'
                    )}
                    noOptionsText={t(
                      'academic.requisition.edit.wizard.step2.add_academic_form.dialog.country.no_data'
                    )}
                    getOptionLabel={(option) => option.cat_name}
                    value={countries.find((e) => e.id === values.countryId) || null}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={t(
                          'academic.requisition.edit.wizard.step2.add_academic_form.dialog.country.placeholder'
                        )}
                        error={Boolean(errors.countryId && touched.countryId)}
                      />
                    )}
                    onChange={(e, value) => setFieldValue('countryId', value?.id)}
                    fullWidth
                    clearIcon={null}
                  />
                )}
              </Grid>

              <Grid item xs={12}>
                <InputLabel>
                  {t('academic.requisition.edit.wizard.step2.add_academic_form.dialog.city')}
                </InputLabel>
                {isLoadingCities ? (
                  <>
                    <TextField
                      disabled
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <CircularProgress size={25} />
                          </InputAdornment>
                        ),
                      }}
                      placeholder={t(
                        'academic.requisition.edit.wizard.step2.add_academic_form.dialog.city.loading'
                      )}
                    />
                  </>
                ) : (
                  <>
                    <Autocomplete
                      options={availablesCities}
                      loading={isLoadingCities}
                      loadingText={t(
                        'academic.requisition.edit.wizard.step2.add_academic_form.dialog.city.loading'
                      )}
                      noOptionsText={t(
                        'academic.requisition.edit.wizard.step2.add_academic_form.dialog.city.no_data'
                      )}
                      getOptionLabel={(option) => option.cat_name}
                      value={availablesCities.find((e) => e.id === values.cityId.id) || null}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={t(
                            'academic.requisition.edit.wizard.step2.add_academic_form.dialog.city.placeholder'
                          )}
                          error={Boolean(errors.cityId && touched.cityId)}
                        />
                      )}
                      onChange={(e, value) =>
                        setFieldValue('cityId', {
                          id: value?.id,
                          name: value?.cat_name,
                        })
                      }
                      fullWidth
                      clearIcon={null}
                    />

                    {errors && touched.cityId && (
                      <FormHelperText error>{t(errors.cityId as string)}</FormHelperText>
                    )}
                  </>
                )}
              </Grid>

              <Grid item xs={12}>
                <InputLabel>
                  {t('academic.requisition.edit.wizard.step2.add_academic_form.dialog.grade')}
                </InputLabel>
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ inputProps: { min: 0, max: 100 } }}
                  type="number"
                  placeholder={t(
                    'academic.requisition.edit.wizard.step2.add_academic_form.dialog.grade'
                  )}
                  {...getFieldProps('educationCalification')}
                  error={Boolean(errors.educationCalification && touched.educationCalification)}
                />
                {errors.educationCalification && touched.educationCalification && (
                  <FormHelperText error>{t(errors.educationCalification)}</FormHelperText>
                )}
              </Grid>

              <Grid item xs={12}>
                <InputLabel>
                  {t('academic.requisition.edit.wizard.step2.add_academic_form.dialog.observation')}
                </InputLabel>
                <TextField
                  fullWidth
                  placeholder={t(
                    'academic.requisition.edit.wizard.step2.add_academic_form.dialog.observation.placeholder'
                  )}
                  {...getFieldProps('educationObservation')}
                  error={Boolean(errors.educationObservation && touched.educationObservation)}
                />
                {errors.educationObservation && touched.educationObservation && (
                  <FormHelperText error>{errors.educationObservation}</FormHelperText>
                )}
              </Grid>

              <Grid item xs={12}>
                <InputLabel>
                  {t('academic.requisition.edit.wizard.step2.add_academic_form.dialog.document')}
                </InputLabel>
                {isErrorFile && showAlerts && (
                  <ErrorAlert
                    message={error as string}
                    handleDismiss={() => setShowAlerts(false)}
                  />
                )}
                {isSuccessFile && showAlerts && (
                  <SuccessAlert
                    message={t(
                      'academic.requisition.edit.wizard.step2.add_academic_form.dialog.document.success'
                    )}
                    handleDismiss={() => setShowAlerts(false)}
                  />
                )}

                <TextField
                  sx={{ overflow: 'hidden' }}
                  fullWidth
                  value={values.document}
                  placeholder={t(
                    'academic.requisition.edit.wizard.step2.add_academic_form.dialog.document.placeholder'
                  )}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <label htmlFor="contained-button-file">
                          <input
                            style={{ display: 'none' }}
                            accept="image/*"
                            id="contained-button-file"
                            type="file"
                            onChange={(event) => {
                              onChangePicture(event);
                              setFieldValue('document', event.target.files![0]?.name);
                            }}
                          />
                          <Button
                            variant="contained"
                            component="span"
                            disabled={isLoadingFile}
                            endIcon={isLoadingFile && <CircularProgress size={20} />}
                            sx={{
                              height: '50px',
                              borderRadius: '12px 00px 0px 12px',
                              marginLeft: -1.8,
                            }}
                          >
                            {t(
                              'academic.requisition.edit.wizard.step2.add_academic_form.dialog.document.upload'
                            )}
                          </Button>
                        </label>
                      </InputAdornment>
                    ),

                    endAdornment: target && (
                      <InputAdornment position="end" onClick={() => cleanFiles()}>
                        <Tooltip
                          title={t(
                            'academic.requisition.edit.wizard.step2.add_academic_form.dialog.document.quit'
                          )}
                        >
                          <IconButton>
                            <IconX size={16} />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                  error={Boolean(errors.document && touched.document)}
                />
                {errors.document && touched.document && (
                  <FormHelperText error>{t(errors.document)}</FormHelperText>
                )}
              </Grid>

              {false && (
                <Grid item xs={12}>
                  <InputLabel>
                    {t('academic.requisition.edit.wizard.step2.add_academic_form.dialog.status')}
                  </InputLabel>

                  {isLoadingStatuses ? (
                    <TextFieldFetching />
                  ) : (
                    <Autocomplete
                      options={availableStatuses || []}
                      getOptionLabel={(option) => option.st_name}
                      value={
                        availableStatuses?.find((status) => status.id === values.statusId) || null
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={Boolean(errors.statusId && touched.statusId)}
                        />
                      )}
                      onChange={(e, value) => setFieldValue('statusId', value?.id)}
                      fullWidth
                      clearIcon={null}
                    />
                  )}

                  {errors && touched.statusId && (
                    <FormHelperText error>{t(errors.statusId as string)}</FormHelperText>
                  )}
                </Grid>
              )}

              {/* end */}
            </Grid>
          </Stack>
        </DialogContent>
        {/* </PerfectScrollbar> */}
        <DialogActions>
          <Stack direction="row" spacing={2}>
            <Button
              variant="text"
              onClick={() => {
                // dispatch(resetCurrentGroup());
                // dispatch(resetSubjectCurriculumSelected());
                handleCloseDialog();
              }}
              color="primary"
            >
              {t('academic.requisition.edit.wizard.step2.add_academic_form.dialog.cancel')}
            </Button>

            <AnimateButton>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isLoadingFile}
                endIcon={isLoadingFile && <CircularProgress size={20} />}
              >
                {academicFormation
                  ? t('academic.requisition.edit.wizard.step2.add_academic_form.dialog.edit')
                  : t('academic.requisition.edit.wizard.step2.add_academic_form.dialog.add')}
              </Button>
            </AnimateButton>
          </Stack>
        </DialogActions>
      </form>
    </ExtendedSideDialog>
  );
};

export default AcademicFormationDialog;
