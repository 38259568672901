import { Grid } from '@material-ui/core';
import { useGetLibraryInfoQuery } from '../../slices/libraryDashboardApiSlice';
import LibraryInfoV2 from '../LibraryInfoV2';
// import BooksInfo from './BooksInfo';
import Documents from './Documents';
import Schedules from './Schedules';

import imageInfoLibrary from '../../../../../assets/images/banner-biblioteca.jpg';

const LibraryInfo = () => {
  const { data: libraryInfo, isLoading } = useGetLibraryInfoQuery({});

  return (
    <>
      {!isLoading && libraryInfo && (
        <Grid container px={2} py={4}>
          <Grid item xs={12}>
            <LibraryInfoV2 booksInfo={libraryInfo.books_info} />
          </Grid>
          {libraryInfo?.schedule && (
            <Grid item xs={12} md={4} p={2}>
              <Schedules schedule={libraryInfo?.schedule} />
            </Grid>
          )}
          {/* <Grid item xs={12} md={4} p={2}>
            <BooksInfo booksInfo={libraryInfo?.books_info} />
          </Grid> */}

          <Grid item container xs={12} md={4} p={2} alignItems="center">
            <img
              src={imageInfoLibrary}
              alt=""
              style={{
                width: '100%',
                height: 'auto',
                borderRadius: 16,
              }}
            />
          </Grid>

          <Grid item xs={12} md={4} p={2}>
            <Documents guides={libraryInfo?.guides} />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default LibraryInfo;
