import { JSXElementConstructor } from 'react';
import { useEffect, useState } from 'react';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';

// redux hooks
import { useAppSelector } from '../../hooks/useRedux';

interface Props extends RouteProps {
  component: JSXElementConstructor<RouteProps>;
}

const PublicRoute = ({ component: Component, ...rest }: Props) => {
  const { isAuth } = useAppSelector((state) => state.auth);
  const { profiles, currentRol } = useAppSelector((state) => state.access);

  const { pathname } = useLocation();

  const [redirectRoute, setRedirectRoute] = useState('');

  // const isInAuthRoutes = pathname === '/login' || pathname === '/register';
  const isInAuthRoutes =
    pathname === '/login' ||
    pathname === '/register' ||
    pathname === '/recuperar-clave' ||
    pathname === '/saml' ||
    pathname === '/saml/googleSSO' ||
    pathname === '/login/googleSSO' ||
    pathname === '/auth/google' ||
    pathname.split('/')[1] === 'restablecer-clave';

  useEffect(() => {
    if (profiles) {
      setRedirectRoute(
        Object.keys(profiles).length > 1
          ? '/perfiles'
          : currentRol && currentRol?.modules && Object.keys(currentRol?.modules).length > 1
          ? '/modulos'
          : '/dashboard'
      );
    }
  }, [profiles, isAuth, currentRol]);
  return (
    <Route
      {...rest}
      render={(props) =>
        !isAuth ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: isInAuthRoutes ? redirectRoute : pathname }} />
        )
      }
    />
  );
};

export default PublicRoute;
