import { useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { IconButton, Grid, useTheme, Typography } from '@material-ui/core';
import { IconArrowNarrowLeft } from '@tabler/icons';

import { useTranslation } from 'react-i18next';

import MainCardForm from '../../../shared/components/cards/MainCardForm';
import ReportTable from '../components/ReportTable';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { useLazyGetCashPointQuery } from '../slices/cashPointsApiSlice';
import { setCashPointList } from '../slices/cashPointsSlice';
import BottomSticky from '../../../students/payments-history/components/builder/BottomSticky';

const ReportScreen = () => {
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { cashPointId } = useParams<{ cashPointId: string }>();

  const { t } = useTranslation();

  const { cashPointList, totalDailyAmount, extraTitle, extraSubtitle } = useAppSelector(
    (state) => state.cashPoints
  );

  const [getCashPoint, { isFetching }] = useLazyGetCashPointQuery();

  useEffect(() => {
    if (!cashPointList.length && !isFetching) {
      getCashPoint({
        cashPointId: +cashPointId,
      })
        .unwrap()
        .then((response) => {
          if (response) dispatch(setCashPointList([response!]));
        })
        .catch((error) => console.log(error));
    }
  }, [isFetching, cashPointId, cashPointList.length, getCashPoint, dispatch]);

  return (
    <>
      <MainCardForm>
        <Grid item xs={12}>
          <Grid item container alignItems="center" sx={{ cursor: 'pointer' }}>
            <Grid item>
              <IconButton
                onClick={() =>
                  history.push(
                    `/${location.pathname.split('/')[1]}/${location.pathname.split('/')[2]}`
                  )
                }
              >
                <IconArrowNarrowLeft size={30} color={theme.palette.text.dark} />
              </IconButton>
            </Grid>
            <Grid item xs={10}>
              <Typography variant="h2">{`${t('financial.close_cash_point.report.title')}${
                extraTitle || ''
              }`}</Typography>
              <Typography variant="subtitle1" color="textSecondary">
                {extraSubtitle}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container alignItems="center" gap={1}></Grid>
        </Grid>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <ReportTable />
        </Grid>
      </MainCardForm>

      <Grid item xs={12}>
        &nbsp;
      </Grid>

      <BottomSticky
        totalAmount={totalDailyAmount}
        alternativeText={t('financial.close_cash_point.report.sticky_btn')}
      />
    </>
  );
};

export default ReportScreen;
