import {
  IconUsers,
  IconFolder,
  IconNotebook,
  IconNotes,
  IconAd,
  IconDashboard,
  IconLicense,
  IconArrowBack,
  IconMessages,
  IconHome,
  IconCheckupList,
  IconBoxMultiple,
  IconClipboardList,
  IconNews,
  IconBrandZoom,
} from '@tabler/icons';

const icons = {
  IconHome,
  IconCheckupList,
  IconBoxMultiple,
  IconClipboardList,
  IconNews,
  IconUsers,
  IconFolder,
  IconNotebook,
  IconNotes,
  IconDashboard,
  IconAd,
  IconLicense,
  IconArrowBack,
  IconMessages,
  IconBrandZoom,
};

export const virtualClassroomStudents = {
  id: 'virtual-classroom-students',
  type: 'group',
  title: 'Aula Virtual Estudiantes',
  children: [
    {
      id: 'aula-virtual-estudiante/inicio',
      title: 'Inicio',
      type: 'item',
      menuId: 'studentVirtualClassroom.menu.index',
      url: '/aula-virtual-estudiante/inicio',
      permissionKey: 'student',
      vcType: ['native', 'moodle', 'blackboard'],
      icon: icons['IconHome'],
    },
    {
      id: 'aula-virtual-estudiante/asistencia',
      title: 'Asistencia',
      type: 'item',
      menuId: 'studentVirtualClassroom.menu.attendance',
      url: '/aula-virtual-estudiante/asistencia',
      permissionKey: 'student',
      vcType: ['native', 'moodle', 'blackboard'],
      icon: icons['IconCheckupList'],
    },
    {
      id: 'aula-virtual-estudiante/materiales',
      title: 'Materiales',
      type: 'item',
      menuId: 'studentVirtualClassroom.menu.materials',
      url: '/aula-virtual-estudiante/materiales',
      permissionKey: 'student',
      vcType: ['native', 'moodle', 'blackboard'],
      icon: icons['IconBoxMultiple'],
    },
    {
      id: 'actividades',
      title: 'Actividades',
      menuId: 'studentVirtualClassroom.menu.activities',
      type: 'collapse',
      permissionKey: 'student',
      icon: icons['IconClipboardList'],
      vcType: ['native'],
      children: [
        {
          id: 'aula-virtual-estudiante/tareas',
          title: 'Tareas',
          menuId: 'studentVirtualClassroom.menu.tasks',
          type: 'item',
          url: '/aula-virtual-estudiante/tareas',
          permissionKey: 'student',
          vcType: ['native'],
        },
        {
          id: 'aula-virtual-estudiante/test',
          title: 'Evaluaciones',
          menuId: 'studentVirtualClassroom.menu.tests',
          type: 'item',
          url: '/aula-virtual-estudiante/test',
          permissionKey: 'student',
          vcType: ['native'],
        },
        {
          id: 'aula-virtual-estudiante/foros',
          title: 'Foro',
          menuId: 'studentVirtualClassroom.menu.forum',
          type: 'item',
          url: '/aula-virtual-estudiante/foros',
          permissionKey: 'student',
          vcType: ['native'],
        },
      ],
    },
    {
      id: 'aula-virtual-estudiante/notas',
      title: 'Calificaciones',
      type: 'item',
      menuId: 'studentVirtualClassroom.menu.qualifications',
      url: '/aula-virtual-estudiante/notas',
      permissionKey: 'student',
      vcType: ['native', 'moodle', 'blackboard'],

      icon: icons['IconNotes'],
    },
    /*{
      id: 'aula-virtual-estudiante/materiales',
      title: 'Materiales',
      type: 'item',
      url: '/aula-virtual-estudiante/materiales',
      permissionKey: 'student',
      icon: icons['IconAd'],
    },*/

    // {
    //   id: 'aula-virtual-estudiante/foros',
    //   title: 'Debate',
    //   type: 'item',
    //   menuId: 'studentVirtualClassroom.menu.forum',
    //   url: '/aula-virtual-estudiante/foros',
    //   permissionKey: 'student',
    //   icon: icons['IconMessages'],
    // // },
    // {
    //   id: 'aula-virtual-estudiante/test',
    //   title: 'Test',
    //   type: 'item',
    //   menuId: 'studentVirtualClassroom.menu.tests',
    //   url: '/aula-virtual-estudiante/test',
    //   permissionKey: 'student',
    //   icon: icons['IconLicense'],
    // },
    // },
    {
      id: 'aula-virtual-estudiante/anuncios',
      title: 'Anuncios',
      menuId: 'studentVirtualClassroom.menu.calendar',
      type: 'item',
      url: '/aula-virtual-estudiante/anuncios',
      permissionKey: 'student',
      vcType: ['native', 'moodle', 'blackboard'],
      icon: icons['IconNews'],
    },
    {
      id: 'aula-virtual-estudiante/compañeros',
      title: 'Compañeros',
      type: 'item',
      menuId: 'studentVirtualClassroom.menu.classmates',
      url: '/aula-virtual-estudiante/compañeros',
      permissionKey: 'student',
      vcType: ['native', 'moodle', 'blackboard'],
      icon: icons['IconUsers'],
    },
    {
      id: 'aula-virtual-estudiante/reuniones',
      title: 'Reuniones virtuales',
      menuId: 'studentVirtualClassroom.menu.meetings',
      type: 'item',
      url: '/aula-virtual-estudiante/reuniones',
      permissionKey: 'student',
      vcType: ['native'],
      icon: icons['IconBrandZoom'],
    },
    {
      id: 'vc-student-close',
      title: 'Salir de este Curso',
      menuId: 'studentVirtualClassroom.menu.exit',
      type: 'item',
      url: '/',
      permissionKey: 'student',
      vcType: ['native', 'moodle', 'blackboard'],
      icon: icons['IconArrowBack'],
    },
  ],
};
