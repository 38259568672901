// import { Chip } from '@material-ui/core';
import { Chip, IconButton, Tooltip, Typography } from '@material-ui/core';
import { IconDownload, IconThumbUp } from '@tabler/icons';
import { differenceInCalendarDays, parseISO } from 'date-fns';
import downloadjs from 'downloadjs';
import { useState } from 'react';
import useGetUrlDownload from '../../../../hooks/useGetUrlDownload';
import {
  // useAppDispatch,
  useAppSelector,
} from '../../../../hooks/useRedux';
import { useTableSearch } from '../../../../hooks/useTableSearch';
// import DeleteButton from '../../../shared/components/tables/buttons/DeleteButton';
import SearchPaginatedTable from '../../../shared/components/tables/SearchPaginatedTable';
import { useGetLendingsQuery } from '../../lendings/slices/lendingApiSlice';

import {
  // openDeleteBorrowedBookDialog,
  updateBorrowedBooksTable,
} from '../slices/userBorrowedbooksSlice';
import RecommendBookDialog from './RecommendBookDialog';

const UserBorrowedBooksTable = () => {
  // const dispatch = useAppDispatch();

  // const { userInfo } = useAppSelector((state) => state.auth);
  const { currentProfile } = useAppSelector((state) => state.access);

  const [bookId, setBookId] = useState<number | null>(null);
  const [recommendDialogOpen, setRecommendDialogOpen] = useState(false);

  const { page, perPage, search, filterArrow } = useAppSelector(
    (state) => state.userBorrowedBooks.borrowedBooksTable
  );

  const { setSearch, setPage, setPerPage, setFilterArrow } = useTableSearch({
    search,
    filterArrow,
    page,
    perPage,
    updateFunction: updateBorrowedBooksTable,
  });

  const { data, isLoading, isFetching, error } = useGetLendingsQuery({
    profile_user_id: currentProfile?.profileId!,
    page,
    size: perPage,
    search,
    ...(filterArrow && {
      sort: filterArrow?.name,
      typeSort: filterArrow?.direction,
    }),
  });

  const datafetch =
    data?.data?.map((item, index) => ({
      id: index + (page - 1) * perPage + 1,
      name: item.model_data?.title,
      type: `${item.model_data?.type_book} ${item.model_data?.origin}`,
      borrowedDate: item.borrow_date,
      returnDate: item.estimated_return_date,
      days: differenceInCalendarDays(parseISO(item.estimated_return_date), new Date()),
      // availables: item.model_data?.available_copies_number,
      status: item.status?.st_name,
      options: item,
    })) || [];

  // const handleDelete = (id: string) => {
  //   dispatch(openDeleteBorrowedBookDialog(id));
  // };

  const { urlDownload } = useGetUrlDownload();

  const handleDownload = (documentUrl: string) => {
    let url = documentUrl;

    if (!documentUrl.includes('http')) {
      url = `${urlDownload}/${documentUrl}`;
    }

    try {
      downloadjs(url);
    } catch (error) {
      console.log(Error);
    }
  };

  return (
    <>
      <SearchPaginatedTable
        data={datafetch}
        isLoading={isLoading}
        isFetching={isFetching}
        error={error}
        page={page}
        perPage={perPage}
        search={search}
        setSearch={setSearch}
        setPage={setPage}
        setPerPage={setPerPage}
        setFilterArrow={setFilterArrow}
        filterArrow={filterArrow}
        total={data?.total || 0}
        keyExtractor={(item) => item.id.toString()}
        numHeader={5}
        searchPlacehoder="Buscar"
        headers={{
          id: 'N°',
          name: 'Título',
          type: 'Tipo',
          borrowedDate: 'Fecha de préstamo',
          returnDate: 'Fecha de devolución',
          // availables: 'Copias disponibles',
          days: 'Días de préstamo',
          status: 'Estado',
          options: 'Opciones',
        }}
        listFilterArrows={{
          borrowedDate: 'reservation_date',
          returnDate: 'estimated_return_date',
          // status: 'reservation_status',
        }}
        customDataCellsProperties={{
          id: { align: 'center' },
          // name: { align: 'center' },
          type: { align: 'center' },
          borrowedDate: { align: 'center' },
          days: { align: 'center' },
          returnDate: { align: 'center' },
          // availables: { align: 'center' },
          status: { align: 'center' },
          options: { align: 'center' },
        }}
        customHeadersCellsProperties={{
          id: { align: 'center' },
          // name: { align: 'center' },
          type: { align: 'center' },
          borrowedDate: { align: 'center' },
          days: { align: 'center' },

          options: { align: 'center' },
        }}
        customRenderers={{
          // status: (value) => (
          //   <Chip
          //     label={value.status}
          //     // color={value.status === 'Pendiente' ? 'warning' : 'success'}
          //     sx={{
          //       backgroundColor: value.status === 'Pendiente' ? 'warning.main' : 'success.main',
          //       color: value.status === 'Pendiente' ? 'black' : 'white',
          //     }}
          //   />
          // ),
          name: (value) => (
            <>
              <Typography variant="body2">{value.name}</Typography>
              <Typography variant="subtitle2">
                {value.options.model_data?.autores?.reduce(
                  (prev, curr) => `${prev !== '' ? `${prev}, ` : ''}${curr.name}`,
                  ''
                )}
              </Typography>
            </>
          ),

          days: (value) => (
            <>
              <Tooltip title={value.days > 0 ? 'Días restantes' : 'Días de atraso'}>
                <Chip
                  label={Math.abs(value.days)}
                  sx={{
                    backgroundColor: value.days > 0 ? 'success.main' : 'error.main',
                    color: 'white',
                  }}
                />
              </Tooltip>
            </>
          ),

          options: (value) => (
            <>
              {value?.options?.model_data?.origin === 'Digital' && (
                <Tooltip title="Descargar">
                  <IconButton
                    onClick={() => handleDownload(value?.options?.model_data?.book_url)}
                    color="primary"
                    disabled={!value?.options?.model_data?.book_url}
                  >
                    <IconDownload />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip title="Recomendar">
                <IconButton
                  onClick={() => {
                    setRecommendDialogOpen(true);
                    setBookId(value.options.model_data?.id || null);
                  }}
                  color="primary"
                >
                  <IconThumbUp />
                </IconButton>
              </Tooltip>
              {/* <DeleteButton handleDelete={() => handleDelete(value.options.id.toString())} /> */}
            </>
          ),
        }}
      />
      {bookId && recommendDialogOpen && (
        <RecommendBookDialog bookId={bookId} handleClose={() => setRecommendDialogOpen(false)} />
      )}
    </>
  );
};

export default UserBorrowedBooksTable;
