import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISearchTable } from '../../../shared/interfaces/paginatedTable.interface';
import { ConfigurationProduct } from '../interfaces/configuration-product.interfaces';

interface ConfigProductsState {
  configProductList: ConfigurationProduct[];
  configProductSelected?: number;
  configProductDeleteModal: boolean;
  configProductsTable: ISearchTable;
}

const initialState: ConfigProductsState = {
  configProductList: [],
  configProductDeleteModal: false,
  configProductsTable: {
    page: 1,
    perPage: 5,
    search: '',
  },
};

const configProductSlice = createSlice({
  name: 'configProducts',
  initialState,
  reducers: {
    setConfigProductList: (state, action: PayloadAction<ConfigurationProduct[]>) => {
      state.configProductList = action.payload;
    },
    setConfigProductDeleteModal: (state, action: PayloadAction<boolean>) => {
      state.configProductDeleteModal = action.payload;
    },
    setConfigProductSelected: (state, action: PayloadAction<number>) => {
      state.configProductSelected = action.payload;
    },
    resetConfigProductSelected: (state) => {
      state.configProductSelected = undefined;
    },
    updateConfigProductsTableStates: (state, action: PayloadAction<ISearchTable>) => {
      state.configProductsTable = action.payload;
    },
  },
});

export const {
  setConfigProductList,
  setConfigProductDeleteModal,
  setConfigProductSelected,
  resetConfigProductSelected,
  updateConfigProductsTableStates,
} = configProductSlice.actions;

export default configProductSlice.reducer;
