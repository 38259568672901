import { Grid, Typography } from '@material-ui/core';
import MainCard from '../../../shared/components/cards/MainCard';
import SedesTable from '../components/SedesTable';

const AdministrationSedesScreen = () => {
   return (
      <Grid component={MainCard} sx={{ padding: 2 }}>
         <Grid sx={{ mb: 3 }}>
            <Typography variant="h2">Administración</Typography>
            <Typography variant="h2" color="gray" fontWeight={400}>Sedes</Typography>
            <br />
            <SedesTable />

         </Grid>

      </Grid>
   )
}

export default AdministrationSedesScreen;