import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../../../../hooks/useRedux';
import CustomSideDialog from '../../../../../../shared/components/dialogs/CustomSideDialog';
import { setOpenAddGroupCourse } from '../../slices/listGroupsSlice';
import AddGroupsCourseForm from './AddGroupsCourseForm';

const AddGroupsCoursesSideDialog = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  // const { openAddGroupsUser } = useAppSelector((state) => state.groupsUser);

  const handleClose = () => {
    dispatch(setOpenAddGroupCourse(false));
  };

  return (
    <CustomSideDialog
      open
      handleCloseDialog={handleClose}
      title={t('Agregar Grupo de cursos por periodo')}
    >
      <AddGroupsCourseForm />
    </CustomSideDialog>
  );
};

export default AddGroupsCoursesSideDialog;
