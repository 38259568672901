import { ChangeEvent, FC, useState } from 'react';

import {
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  useGetUsersAvailableCashiersQuery,
  useAddUserCashierMutation,
} from '../../slices/financialConfigurationApiSlice';
import { useAppSelector } from '../../../../../hooks/useRedux';
import { renderErrorOrEmptyRow, renderSkeletonRows } from '../../../../shared/helpers/render';
import {
  AvailableCashierUser,
  StablishmentData,
} from '../../interfaces/financialConfiguration.interfaces';
import ErrorAlert from '../../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../../shared/components/alerts/SuccessAlert';

const useStyles = makeStyles((theme) => ({
  scrollBar: {
    // backgroundColor: 'black',
    '>div.scrollbar-container': {
      backgroundColor: 'black',
      paddingLeft: '0px',
    },
  },

  selectedUser: {
    backgroundColor: '#eee',
  },
}));

// const dt = [
//   {
//     email: 'sistemas@redlinks.com.ec',
//     id: 1,
//     person_id: '1',
//     status_id: '1',
//     us_username: 'sistemas',
//   },
//   {
//     email: 'sistemas2@redlinks.com.ec',
//     id: 2,
//     person_id: '2',
//     status_id: '2',
//     us_username: 'sistemas2',
//   },
// ];

interface Props {
  stablishmentData?: StablishmentData;
  handleCancel: (e: React.SyntheticEvent) => void;
}

const CheckoutUsersTable: FC<Props> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { stablishmentData, handleCancel } = props;

  const { currentProfile } = useAppSelector((state) => state.access);

  const { data, isFetching, error } = useGetUsersAvailableCashiersQuery({
    profileId: currentProfile?.profileId!,
  });

  const [addCashier, { isLoading, isError, isSuccess, error: errorAssign }] =
    useAddUserCashierMutation();

  const [userSelected, setUserSelected] = useState(0);

  const [showAlerts, setShowAlerts] = useState(true);

  const handleSelectUser = (userId: number) => {
    if (userId === userSelected) {
      setUserSelected(0);
      return false;
    }
    setUserSelected(userId);
    return true;
  };
  const handleSelectUserRadio = (event: ChangeEvent<HTMLInputElement>) => {
    setUserSelected(Number((event.target as HTMLInputElement).value));
  };

  const handleAssign = async () => {
    if (userSelected) {
      await addCashier({
        profileId: currentProfile?.profileId!,
        addUserCashierPayload: { cashier_id: userSelected, ...stablishmentData },
      });

      setShowAlerts(true);
      window.scrollTo(0, 0);
    }
  };

  const renderRow = (user: AvailableCashierUser) => {
    return (
      <TableRow
        hover
        className={`table-row ${userSelected === user.id ? '' : ''}`}
        key={`cajero-${user.id.toString()}`}
      >
        <TableCell
          width="5%"
          // sx={{ cursor: 'pointer' }}
          // onClick={() => {
          //   handleSelectUser(user.id);
          // }}
        >
          <RadioGroup
            aria-label={`cajero-${user.us_username}`}
            name="controlled-radio-buttons-group"
            value={userSelected}
            onChange={handleSelectUserRadio}
          >
            <FormControlLabel value={user.id} control={<Radio />} label=" " />
          </RadioGroup>
        </TableCell>
        <TableCell
          width="70%"
          sx={{ cursor: 'pointer' }}
          onClick={() => {
            handleSelectUser(user.id);
          }}
        >
          {user.us_username || ''} - {user.person?.pers_firstname || ''}{' '}
          {user.person?.pers_first_lastname || ''}
          {/* <FormHelperText>{rol.description}</FormHelperText> */}
        </TableCell>
      </TableRow>
    );
  };

  const renderRows = () => {
    return <>{data?.map(renderRow)}</>;
  };

  return (
    <>
      <Grid container className={classes.scrollBar} spacing={2} justifyContent={'flex-end'}>
        <Grid item xs={12}>
          {isError && showAlerts && (
            <ErrorAlert
              message={errorAssign as string}
              handleDismiss={() => setShowAlerts(false)}
            />
          )}

          {isSuccess && showAlerts && (
            <SuccessAlert
              message={t('financial.emission_point.link_user_dialog.success_alert')}
              handleDismiss={() => setShowAlerts(false)}
            />
          )}
        </Grid>

        <Grid item xs={12} sx={{ maxHeight: '75vh', overflow: 'hidden', pl: '0px' }}>
          <PerfectScrollbar component="div" style={{ width: '200' }}>
            <TableContainer>
              <Table>
                <TableBody>
                  {isFetching ? renderSkeletonRows(5, 1) : renderRows()}

                  {error && renderErrorOrEmptyRow(5, error as string)}
                  {data?.length === 0 &&
                    renderErrorOrEmptyRow(
                      5,
                      t('financial.emission_point.link_user_dialog.no_data')
                    )}
                </TableBody>
              </Table>
            </TableContainer>
          </PerfectScrollbar>
        </Grid>

        <Grid item>
          <Button variant="outlined" size="large" onClick={handleCancel}>
            {t('shared.cancel_button.label')}
          </Button>
        </Grid>

        <Grid item>
          <Button
            variant="contained"
            size="large"
            disabled={!userSelected || isLoading || isSuccess}
            endIcon={isLoading && <CircularProgress size={20} />}
            onClick={handleAssign}
          >
            {t('shared.assign_button.label')}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default CheckoutUsersTable;
