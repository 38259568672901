import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { obtainDomainStorageImage } from '../../../../shared/helpers';
import { Profile } from '../../../../shared/interfaces/post.interfaces';
import { AnswersActivity } from '../interfaces/forums.interfaces';

interface Props {
  id: string;
  data: {
    id: string;
    content: string;
    details: string;
    images: {
      img: string;
    }[];
    comments: AnswersActivity[] | undefined;
  };
  profile: Profile;
}

const useStylesContent = makeStyles((theme) => ({
  container: {
    border: '1px solid red',
  },
  card: {
    minWidth: 200,
    textAlign: 'left',
    marginTop: 0,
  },
  cardContent: {
    paddingTop: '0 !important',
  },
  cardHeader: {
    paddingBottom: '2 !important',
  },
}));

const CardInfoPosts = ({ id, data, profile }: Props) => {
  const classesContent = useStylesContent();
  return (
    <Card key={id} className={classesContent.card}>
      <CardHeader
        className={classesContent.cardHeader}
        avatar={<Avatar alt="User 1" src={obtainDomainStorageImage(profile.name)}></Avatar>}
        title={
          <Grid>
            <Grid>
              <Typography variant="subtitle1">{profile.name}</Typography>
            </Grid>
            <Grid>
              <Typography variant="subtitle2">{profile.time}</Typography>
            </Grid>
          </Grid>
        }
      />
      <CardContent className={classesContent.cardContent}>
        <Grid>
          <Grid mb={2}>
            <Typography variant="h4">{data.content}</Typography>
          </Grid>
          <Grid>
            <Typography variant="h5">{data.details}</Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CardInfoPosts;
