import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import MainCard from '../../../../../shared/components/cards/MainCard';
import IntegralTable from '../components/IntegralTable';

const IntegralsReportScreen = () => {
  const { t } = useTranslation();

  return (
    <Grid
      container
      component={MainCard}
      contentSX={{
        width: '100%',
      }}
    >
      <Grid item xs={12}>
        <Typography variant="h2">{t('reports.integrals.title')}</Typography>
      </Grid>

      <Grid item xs={12} mt={2}>
        <IntegralTable />
      </Grid>
    </Grid>
  );
};

export default IntegralsReportScreen;
