import { FC, useMemo, useRef, useState } from 'react';

import Badge from '@material-ui/core/Badge';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import Divider from '@material-ui/core/Divider';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import FormHelperText from '@material-ui/core/FormHelperText';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { PickersDayProps } from '@material-ui/lab/PickersDay';
import { parseISO, isSameDay, format } from 'date-fns';
import Transitions from '../../../../shared/components/extended/Transitions';
import Noloadedavatar from './../../../../../../src/assets/images/users/noloadedavatar.png';
import { ButtonBase, CircularProgress, Stack, Typography } from '@material-ui/core';

import { ActivitiesRecents } from '../../interfaces/TaskRecents.interface';
import useGoToVirtualClassroom from '../../../../students/academic-portfolio/hooks/useGoToVirtualClassroom';
import { activityTypes } from '../../../../students/dashboard/constants';
import { IconBook2, IconBrandZoom, IconClipboardText, IconMessage } from '@tabler/icons';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useAppSelector } from '../../../../../hooks/useRedux';
import { useGoActivityMoodleMutation } from '../../../../shared/slices/moodle';

// style constant
const useStyles = makeStyles((theme) => ({
  avatar: {
    ...theme.typography.commonAvatar,
    ...theme.typography.largeAvatar,
    // margin: '8px',
    height: 'auto',
    width: 'auto',
    // backgroundColor: 'transparent',
    backgroundColor: theme.palette.grey[200],
    padding: '5px',
  },

  box: {
    marginLeft: '16px',
    marginRight: '24px',
    [theme.breakpoints.down('sm')]: {
      marginRight: '16px',
    },
  },

  eventsCounter: {
    // backgroundColor: 'black',
    '& >span': {
      //   backgroundColor: 'black',
    },
  },

  dayPicker: {
    // backgroundColor: 'black',

    '&:hover': {
      '& $eventsCounter': {
        // top: '-10px',
        // transitionDuration: 2,
        '& >span': {
          //   transform: 'translate(0px,30px)',
        },
      },
    },
  },
}));
const IconMap = {
  IconBook2: IconBook2,
  IconMessage: IconMessage,
  IconClipboardText: IconClipboardText,
  IconBrandZoom: IconBrandZoom,
};

interface CourseDataRoute {
  /**
   * course id
   */
  id: number;
  /**
   * course path
   */
  path: string;
  /**
   * course activity id
   */
  activityId: number;
}

interface Props {
  day: number;
  // hasEvents: boolean;
  data: ActivitiesRecents[];
  dayDate: Date;
  openDay: number;
  setOpenDay: React.Dispatch<React.SetStateAction<number>>;

  PickerDayComponent: <TDate>(
    props: PickersDayProps<TDate> & React.RefAttributes<HTMLButtonElement>
  ) => JSX.Element;
  dayComponentProps: PickersDayProps<Date>;
}

const CaleandarPopperTeacher: FC<Props> = ({
  //   children,
  dayDate,
  // hasEvents,
  data,
  day,
  openDay,
  setOpenDay,

  PickerDayComponent,
  dayComponentProps,
}) => {
  const theme = useTheme();
  const classes = useStyles();

  const { userInfo } = useAppSelector((state) => state.auth);

  /**
   * anchorRef is used on different componets and specifying one type leads to other components throwing an error
   * */
  const popperRef = useRef<any>(null);

  const [ActivityID, setActivityID] = useState<number>();

  const [courseDataRoute, setCourseDataRoute] = useState<CourseDataRoute | undefined>();

  const activities = useMemo(
    () =>
      data.filter(
        ({ cact_start_date, cact_end_date }) =>
          !dayComponentProps.outsideCurrentMonth &&
          (isSameDay(parseISO(String(cact_start_date)), dayDate) ||
            (cact_end_date ? isSameDay(parseISO(String(cact_end_date)), dayDate) : false))
      ),
    [data, dayComponentProps.outsideCurrentMonth, dayDate]
  );

  const handleToggle = () => {
    setOpenDay((prevState) => (prevState === -1 ? day : -1));
  };

  const handleClose = () => {
    setOpenDay(-1);
  };

  const [getMoodleActivity, { isLoading: isLoadingMoodle }] = useGoActivityMoodleMutation();

  const { isFetching: isFetchingGoToAct } = useGoToVirtualClassroom(
    courseDataRoute?.id!,
    `${courseDataRoute?.path}`,
    false,
    {
      isTeacher: true,
      activityId: ActivityID!,
    }
  );

  const RenderActivity = (activity: typeof activities[0], index: number) => {
    const {
      act_description,
      cact_start_date,
      cact_end_date,
      // course_activity: [{ course_id, activity_id }],
      course_activity_one: { course_id, activity_id },
      type_activity: { cat_acronym },

      //moodle
      instance,
      modulename,
    } = activity;

    const startDate = format(parseISO(String(cact_start_date)), '	dd MMM - HH:mm');
    const endDate = cact_end_date
      ? format(parseISO(String(cact_end_date)), '	dd MMM - HH:mm')
      : format(parseISO(String(cact_start_date)), '	dd MMM');

    const setActivityDataRoute = async () => {
      setActivityID(Number(activity_id));

      if (instance && modulename) {
        try {
          const moodleActivity = await getMoodleActivity({
            instance,
            modulename,
            userId: userInfo?.id!,
          }).unwrap();

          window.open(moodleActivity);
        } finally {
          // catch (error) {
          // console.log('F');
          // }
          return;
        }
      }

      setCourseDataRoute({
        id: +course_id,
        path: `${activityTypes[cat_acronym as keyof typeof activityTypes].path}`,
        activityId: +activity_id,
      });
    };

    const ActivityIcon =
      IconMap[
        activityTypes[cat_acronym as keyof typeof activityTypes]?.icon as keyof typeof IconMap
      ];

    return (
      <>
        <ButtonBase
          onClick={setActivityDataRoute}
          disabled={isFetchingGoToAct || isLoadingMoodle}
          sx={{ width: '100%' }}
        >
          <Stack direction="row" alignItems="center" width="100%" p={1} spacing={1}>
            <Box>
              {!isFetchingGoToAct && !isLoadingMoodle && Number(activity_id) !== ActivityID ? (
                <Avatar
                  variant="rounded"
                  className={`${classes.avatar}`}
                  // sx={{ mb: isNormal ? 0 : 1 }}
                >
                  {activityTypes[cat_acronym as keyof typeof activityTypes] ? (
                    <Grid>
                      <ActivityIcon size={18} />
                      <Typography fontSize={'8px'}>
                        {activityTypes[cat_acronym as keyof typeof activityTypes].name}
                      </Typography>
                    </Grid>
                  ) : (
                    <img
                      style={{
                        height: '30px',
                        borderRadius: '50%',
                        // backgroundColor: `${theme.palette.grey[300]}`,
                      }}
                      src={Noloadedavatar}
                      alt={'Tipo de actividad'}
                    />
                  )}
                </Avatar>
              ) : (
                <CircularProgress />
              )}
            </Box>

            <Box>
              <Divider orientation="vertical" flexItem />
            </Box>

            <Box width="100%">
              <Stack justifyContent="center">
                <Box>{act_description}</Box>
                <FormHelperText>{`${startDate} - ${endDate}`}</FormHelperText>
              </Stack>
            </Box>
          </Stack>
        </ButtonBase>
        {index !== activities.length - 1 && (
          <Box width="100%">
            <Divider />
          </Box>
        )}
      </>
    );
  };

  const renderActivities = () => activities.map(RenderActivity);

  return (
    <Box className={classes.dayPicker}>
      <Badge
        // key={key}
        overlap="circular"
        badgeContent={
          <>
            {!!activities.length ? (
              <>
                <Box component="span" className={classes.box + ' marcado'}>
                  <Box color="inherit">
                    <Badge
                      //   overlap="circular"
                      badgeContent={activities.length}
                      color="secondary"
                      className={classes.eventsCounter}
                    ></Badge>
                  </Box>
                </Box>
                <Popper
                  // placement={matchesXs ? 'bottom' : 'bottom-end'}
                  placement={'bottom-end'}
                  open={openDay === day}
                  anchorEl={popperRef.current}
                  role={undefined}
                  disablePortal={false}
                  transition
                  style={{ zIndex: 999999 }}

                  // popperOptions={{
                  //   modifiers: [
                  //     {
                  //       name: 'offset',
                  //       options: {
                  //         offset: [matchesXs ? 5 : 0, 20],
                  //       },
                  //     },
                  //   ],
                  // }}
                >
                  {({ TransitionProps }) => (
                    <Transitions in={openDay + 1} {...TransitionProps}>
                      <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                          <Box
                            sx={{
                              boxShadow: theme.customShadows.primary,
                              borderRadius: '12px',
                              // p: 1,
                            }}
                          >
                            <Box sx={{ width: '250px', py: 1 }}>
                              <PerfectScrollbar component={'div'}>
                                <Grid
                                  container
                                  sx={{ height: '100%' }}
                                  justifyContent="flex-start"
                                  alignItems="center"
                                  // spacing={1}
                                >
                                  {renderActivities()}
                                </Grid>
                              </PerfectScrollbar>
                            </Box>
                          </Box>
                        </ClickAwayListener>
                      </Paper>
                    </Transitions>
                  )}
                </Popper>
              </>
            ) : undefined}
          </>
        }
      >
        <PickerDayComponent
          {...dayComponentProps}
          ref={popperRef}
          onClick={() => handleToggle()}
          // aria-controls={open ? 'menu-list-grow' : undefined}
          // aria-haspopup="true"
        />
      </Badge>
    </Box>
  );
};

export default CaleandarPopperTeacher;
