import DeleteAlert from '../../../../../shared/components/dialogs/DeleteAlert';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/useRedux';
import { useDeleteFormByIdMutation } from '../slices/formsApiSlice';
import { setFormIdSelected, setIsOpenDialogOpen } from '../slices/formSlice';

const DeleteFormDialog = () => {
  const dispatch = useAppDispatch();
  const { formIdSelected, isOpenDeleteDialogOpen } = useAppSelector((state) => state.formsEva);

  const [deleteForm, { isLoading, isSuccess, error, isError }] = useDeleteFormByIdMutation();

  const handleDelete = () => {
    deleteForm({
      id: formIdSelected!,
    });
  };

  const handleClose = () => {
    dispatch(setFormIdSelected(null));
    dispatch(setIsOpenDialogOpen(false));
  };

  return (
    <DeleteAlert
      open={isOpenDeleteDialogOpen}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      error={error as any}
      handleDelete={handleDelete}
      onClose={handleClose}
    />
  );
};

export default DeleteFormDialog;
