import { useState } from 'react';

import { useFormikContext } from 'formik';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  InputLabel,
  TextField,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { subPermissionKeys } from '../../../../periods-types/constants/permissions';
import useSharedGuard from '../../../../../../hooks/useSharedGuard';
import { useAppSelector } from '../../../../../../hooks/useRedux';
import { useGetAvailablePeriodTypesAdditionalQuery } from '../../../../periods-types/slices/periodTypesApiSlice';
import InputSecondaryLabelAdd from '../../../../../shared/components/forms/InputSecondaryLabelAdd';
import Accordion from '../../../../../shared/components/extended/Accordion';
import { PeriodForm } from '../../../interfaces/period-form.interfaces';
import InfoAlert from '../../../../../shared/components/alerts/InfoAlert';

const PeriodFormConfig = () => {
  const { t } = useTranslation();

  const [showMaxMinMatters, setShowMinMaxMatters] = useState(true);

  const { currentProfile } = useAppSelector((state) => state.access);

  const { setFieldValue, values, errors, touched } = useFormikContext<PeriodForm>();

  const { hasAccess: hasAccessTypePeriod } = useSharedGuard(subPermissionKeys);

  const { data: periodTypesAvailables = [], isLoading: isLoadingTP } =
    useGetAvailablePeriodTypesAdditionalQuery({ profileId: currentProfile?.profileId! });

  const isAvailableMinMaxMatter = function (typePeriodName: string) {
    const leveling = 'Nivelación';
    if (typePeriodName !== leveling) {
      setShowMinMaxMatters(true);
    } else {
      setShowMinMaxMatters(false);
    }
  };

  const data = [
    {
      id: 'form-info-evaluation',
      title: (
        <Typography variant="h3" component="div" sx={{ mb: 3 }}>
          {t('academic.periods_list.add.stage_2.subject_max')}
        </Typography>
      ),
      defaultExpand: true,
      content: (
        <>
          {showMaxMinMatters && (
            <Grid item xs={12} container spacing={2}>
              <Grid item xs={6}>
                <InputLabel> {t('academic.periods_list.add.stage_2.subject_max.min')}</InputLabel>
                <TextField
                  disabled
                  type="number"
                  fullWidth
                  placeholder={t('academic.periods_list.add.stage_2.subject_max.min.placeholder')}
                  value={values.minMatter}
                  onChange={(e) => {
                    setFieldValue('minMatter', e.target.value);
                  }}
                  error={Boolean(errors.minMatter && touched.minMatter)}
                />
                {errors.minMatter && touched.minMatter && (
                  <FormHelperText error>{t(errors.minMatter)}</FormHelperText>
                )}
              </Grid>

              <Grid item xs={6}>
                <InputLabel>
                  <InputLabel> {t('academic.periods_list.add.stage_2.subject_max.max')}</InputLabel>
                </InputLabel>
                <TextField
                  disabled
                  type="number"
                  fullWidth
                  placeholder={t('academic.periods_list.add.stage_2.subject_max.max.placeholder')}
                  value={values.maxMatter}
                  onChange={(e) => {
                    setFieldValue('maxMatter', Number(e.target.value));
                  }}
                  error={Boolean(errors.maxMatter && touched.maxMatter)}
                />
                {errors.maxMatter && touched.maxMatter && (
                  <FormHelperText error>{t(errors.maxMatter)}</FormHelperText>
                )}
              </Grid>
              <Grid item xs={12}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.isPayEnrollment}
                        onChange={(e) => {
                          values.isPayEnrollment = e?.target?.checked;
                          setFieldValue('isPayEnrollment', e?.target?.checked);
                        }}
                        disabled={Number(values.feesEnrollmentUnique) === 1}
                      />
                    }
                    label={t('academic.periods_list.add.stage_2.subject_max.enrollment')}
                  />
                </FormGroup>
                {errors.isPayEnrollment && touched.isPayEnrollment && (
                  <FormHelperText error>{t(errors.isPayEnrollment)}</FormHelperText>
                )}
              </Grid>
            </Grid>
          )}
        </>
      ),
    },
  ];

  return (
    <Grid container spacing={4} alignItems="flex-start">
      <Grid item xs={12} md={6} container spacing={2} sx={{ mb: 2 }}>
        {Object.values(errors).length !== 0 && (
          <Grid item xs={12} sx={{ mb: 1 }}>
            <InfoAlert message={Object.values(errors).map((a) => t(a.toString()))!}></InfoAlert>
          </Grid>
        )}

        <Grid item xs={12}>
          <Typography variant="h3" component="div" sx={{ mb: 3 }}>
            {t('academic.periods_list.add.stage_2.title')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <InputSecondaryLabelAdd
            label={t('academic.periods_list.add.stage_2.period_type')}
            title={t('academic.periods_list.add.stage_2.period_type.add')}
            link={'/administrar-tipos-periodos/agregar'}
            hasAccess={hasAccessTypePeriod('create')}
          />
          <Autocomplete
            options={periodTypesAvailables}
            loading={isLoadingTP}
            loadingText={t('academic.periods_list.add.stage_2.period_type.loading')}
            getOptionLabel={(option) => option.tp_name}
            renderInput={(params) => (
              <TextField
                {...params}
                error={Boolean(errors.periodTypeId && touched.periodTypeId)}
                placeholder={t('academic.periods_list.add.stage_2.period_type.placeholder')}
              />
            )}
            value={periodTypesAvailables.find((e) => e.id === values.periodTypeId) || null}
            onChange={(_, value) => {
              setFieldValue('periodTypeId', value?.id!);

              setFieldValue('minMatter', value?.tp_min_matter_enrollment! || 1);
              setFieldValue('maxMatter', value?.tp_max_matter_enrollment! || 1);

              setFieldValue('isPayEnrollment', value?.tp_pay_enrollment === '1' ? true : false);
              setFieldValue(
                'numFees',
                (Number(value?.tp_num_fees) || 0) + (value?.additional_fees?.length || 0)
              );

              setFieldValue('tp_num_fees', Number(value?.tp_num_fees) || 0);
              setFieldValue('tp_pay_enrollment', value?.tp_pay_enrollment || '0');

              setFieldValue('generation_quote', []);

              setFieldValue('feesPerEnrollement', value?.tp_fees_enrollment || 0);

              setFieldValue('additional_fees', value?.additional_fees || []);

              setFieldValue('feesEnrollmentUnique', value?.tp_fees_enrollment_unique || 0);

              isAvailableMinMaxMatter(value?.tp_name!);
            }}
            fullWidth
            clearIcon={null}
          />
          {errors.periodTypeId && touched.periodTypeId && (
            <FormHelperText error>{t(errors.periodTypeId)}</FormHelperText>
          )}
        </Grid>
        <Grid item xs={12}>
          <InputLabel>{t('academic.periods_list.add.stage_2.period_name')}</InputLabel>
          <TextField
            fullWidth
            error={Boolean(errors.name && touched.name)}
            placeholder={t('academic.periods_list.add.stage_2.period_name.placeholder')}
            value={values.name}
            onChange={(e) => {
              setFieldValue('name', e.target.value);
            }}
          />
          {errors.name && touched.name && <FormHelperText error>{t(errors.name)}</FormHelperText>}
        </Grid>
        <Grid
          item
          justifyContent={'center'}
          xs={12}
          p={2}
          sx={{
            ml: 2,
            mt: 3,
            mb: 2,
            border: `3px solid #F2F3F4`,
            borderRadius: '10px',
          }}
        >
          <Accordion data={data} />
        </Grid>

        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={values.can_access_av_debt_expire}
                onChange={(e) => {
                  values.can_access_av_debt_expire = e?.target?.checked;
                  setFieldValue('can_access_av_debt_expire', e?.target?.checked);
                }}
                // disabled={Number(values.feesEnrollmentUnique) === 1}
              />
            }
            label={t('academic.periods_list.add.stage_2.virtual_classroom.available')}
          />
        </Grid>

        <Grid item xs={12} container spacing={2}>
          <Grid item xs={12}>
            <InputLabel>{t('academic.periods_list.add.stage_2.num_faults')}</InputLabel>
            <TextField
              type="number"
              fullWidth
              InputProps={{ inputProps: { min: 0 } }}
              placeholder={t('academic.periods_list.add.stage_2.num_faults.placeholder')}
              value={values.numFouls}
              onChange={(e) => {
                setFieldValue('numFouls', Number(e.target.value));
              }}
              error={Boolean(errors.numFouls && touched.numFouls)}
            />
            {errors.numFouls && touched.numFouls && (
              <FormHelperText error>{t(errors.numFouls)}</FormHelperText>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PeriodFormConfig;
