import { Grid } from '@material-ui/core';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useAppDispatch } from '../../../../hooks/useRedux';
import StatusChip from '../../../shared/components/badges/StatusChip';
import AddButton from '../../../shared/components/tables/buttons/AddButton';
import DeleteButton from '../../../shared/components/tables/buttons/DeleteButton';
import EditButton from '../../../shared/components/tables/buttons/EditButton';
import SearchPaginatedTable, {
  FilterArrow,
} from '../../../shared/components/tables/SearchPaginatedTable';
import useGuard from '../hooks/useGuard';
import { useGetTagsQuery } from '../slices/tagsApiSlice';
import { openDeleteModalWith, setTagToEdit } from '../slices/tagsSlice';

const TagsTable = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(5);
  const [search, setSearch] = useState('');
  const [filterArrow, setFilterArrow] = useState<FilterArrow>(null!);

  const { hasAccess } = useGuard();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const {
    data: tags,
    isLoading,
    isFetching,
    isError,
    error,
  } = useGetTagsQuery(
    {
      page,
      size,
      search,
      ...(filterArrow && { sort: filterArrow.name, type_sort: filterArrow.direction }),
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const handleGoAdd = () => {
    dispatch(setTagToEdit(undefined));
    history.push('/administrar-etiquetas/agregar');
  };

  return (
    <div>
      <Grid container>
        <Grid item xs={12} md={12}>
          <SearchPaginatedTable
            headers={{
              id: 'Nº',
              name: t('shared.table.name'),
              type: 'Tipo',
              status: t('shared.table.status'),
              options: t('shared.search_paginated_table.table.options'),
            }}
            data={
              tags?.data.map((dt, index) => ({
                id: Number(tags.from) + index,
                name: dt.name,
                type: dt.type_tag,
                status: dt.status_id,
                options: 's',
              })) || []
            }
            isFetching={isFetching}
            isLoading={isLoading}
            page={page}
            perPage={size}
            setPage={setPage}
            setPerPage={setSize}
            error={isError ? error : undefined}
            keyExtractor={(row) => String(row.id)}
            numHeader={5}
            search={search}
            setSearch={setSearch}
            searchPlacehoder={t('tags.search')}
            listFilterArrows={{
              name: 'name',
              type: 'type_tag',
              status: 'status_id',
            }}
            filterArrow={filterArrow}
            setFilterArrow={setFilterArrow}
            total={tags?.total!}
            customRenderers={{
              status: (row) => <StatusChip statusId={Number(row.status)} />,
              options: (row) => (
                <>
                  {hasAccess('edit') && (
                    <EditButton
                      handleEdit={() => {
                        //dispatch(setTagToEdit(tags?.data.find((tg) => tg.id === Number(row.id))));
                        dispatch(
                          setTagToEdit(
                            tags?.data.find((tg, index) => index === row.id - Number(tags?.from))
                          )
                        );
                        history.push('/administrar-etiquetas/editar');
                      }}
                    />
                  )}
                  {hasAccess('delete') && (
                    <DeleteButton
                      handleDelete={() => {
                        dispatch(
                          openDeleteModalWith(
                            tags?.data.find((tg, index) => index === row.id - Number(tags?.from))
                              ?.id!
                          )
                        );
                      }}
                    />
                  )}
                </>
              ),
            }}
            ActionButtons={
              <>{hasAccess('create') && <AddButton size="large" onClick={handleGoAdd} />}</>
            }
            customDataCellsProperties={{
              id: { align: 'center' },
              type: { align: 'center' },
              status: { align: 'center' },
              options: { align: 'center' },
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default TagsTable;
