import SearchPaginatedTable, { PropsSearchTable } from './SearchPaginatedTable';

interface Props<T extends {}>
  extends Omit<
    PropsSearchTable<T>,
    | 'page'
    | 'setPage'
    | 'perPage'
    | 'setPerPage'
    | 'total'
    | 'paginationBox'
    | 'perPageBox'
    | 'numHeader'
    | 'search'
    | 'setSearch'
    | 'searchPlacehoder'
  > {
  search?: string;
  searchPlacehoder?: string;
  /** ```undefined``` solo si no se usara paginada la tabla */
  setSearch?: React.Dispatch<React.SetStateAction<string>> | ((search: string) => void) | undefined;
}

/**
 * Component SearchPaginatedTable without pagination
 * @param param0
 * @returns
 */
const TableNoPaginated = <T extends {}>({
  search,
  setSearch,
  searchPlacehoder,
  showFilter = false,
  ...props
}: Props<T>) => {
  return (
    <SearchPaginatedTable
      setPage={() => {}}
      setPerPage={() => {}}
      page={1}
      perPage={10}
      total={10}
      search={search || ''}
      setSearch={setSearch}
      searchPlacehoder={searchPlacehoder || ''}
      paginationBox={false}
      perPageBox={false}
      showFilter={showFilter}
      {...props}
    />
  );
};

export default TableNoPaginated;
