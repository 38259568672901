import { Grid, IconButton, InputAdornment, TextField } from '@material-ui/core';
import { useState } from 'react';
import { DebounceInput } from 'react-debounce-input';
import { useAppSelector } from '../../../../../hooks/useRedux';
import SearchIcon from '@material-ui/icons/Search';
import { IconX } from '@tabler/icons';
import {
  renderSkeletonTable,
  renderErrorOrEmptyRow,
  renderSkeletonRows,
} from '../../../../shared/helpers/render';
import { useGetStudentPaymentsByStateQuery } from '../../slices/paymentsApiSlice';

import {
  TableCell,
  TableRow,
  TableContainer,
  Table,
  TableHead,
  TableBody,
} from '@material-ui/core';
import { PaymentsDebts } from '../../interfaces/paymentsHistory.interface';
import CustomPaginationAndItems from '../../../../shared/components/tables/CustomPaginationAndItems';

const PendingTransc = () => {
  const { currentProfile, currentStudent, currentRecordStudent } = useAppSelector(
    (state) => state.access
  );

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [search, setSearch] = useState('');

  const {
    data,
    isFetching,
    isLoading,
    error,
    // refetch,
  } = useGetStudentPaymentsByStateQuery({
    profileId: currentProfile?.profileId!,
    studentId: currentStudent?.id!,
    page,
    perPage,
    search,
    payment_status: 'pending-payments',
    ...(currentRecordStudent && { student_record: currentRecordStudent?.id }),
  });

  const RenderRow = (props: { p: PaymentsDebts }) => {
    const { p } = props;
    return (
      <>
        <TableRow className="table-row" key={p.fees_identifier}>
          <TableCell sx={{ pl: 3 }} width="20%">
            {p.fees_name_identifier}
          </TableCell>
          <TableCell align="center">
            {p.per_current_year && p.per_due_year ? `${p.per_current_year}-${p.per_due_year}` : ''}
          </TableCell>
          <TableCell align="center">{p.fees_start_date}</TableCell>
          <TableCell align="center">{p.fees_expiration_date}</TableCell>
          <TableCell align="center" width="20%">
            {p.balance_pending_approval}
          </TableCell>
          <TableCell align="center">
            {p.apply_early_payment
              ? p.discount_early_payment_is_percent
                ? p.discount_value_early_payment //p.discount_early_payment
                : p.discount_early_payment //p.discount_percent_early_payment
              : '0.00'}
          </TableCell>
          <TableCell align="center">{p.fees_payment}</TableCell>
          <TableCell align="center">
            {p.fees_net_amount}
            {/* {p.apply_early_payment
              ? p.net_value_early_payment
              : p.fees_balance === '.00'
              ? '0.00'
              : p.fees_balance} */}
          </TableCell>
        </TableRow>
      </>
    );
  };

  const defaultConfiguration = useAppSelector((state) => state.access.defaultConfiguration);

  return (
    <>
      {isLoading ? (
        renderSkeletonTable(4, 8)
      ) : (
        /*Fragment is used until CustomCollapsibleTable is ready*/
        <>
          <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
            <Grid item xs={12} sm={6}>
              <DebounceInput
                autoFocus={Boolean(search)}
                minLength={2}
                debounceTimeout={300}
                onChange={({ target }) => setSearch(target.value)}
                value={search}
                element={(props) => (
                  <TextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon fontSize="small" />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            size="small"
                            onClick={() => setSearch('')}
                            {...(!search && { sx: { cursor: 'initial', opacity: 0 } })}
                          >
                            <IconX size={20} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    placeholder="Buscar transacción"
                    variant="outlined"
                    size="small"
                    {...props}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ textAlign: 'right' }}>
              {/* {addPermission && <AddButton size="large" onClick={goToAdd} />} */}
            </Grid>
          </Grid>

          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ pl: 3 }} align="center">
                    Deuda
                  </TableCell>
                  <TableCell>Período lectivo</TableCell>
                  <TableCell align="center">Fecha de inicio de cobro</TableCell>
                  <TableCell align="center">Fecha de vencimiento</TableCell>
                  <TableCell align="center">
                    {defaultConfiguration?.currency_symbol} Valor Por Aprobar
                  </TableCell>
                  <TableCell align="center">
                    {defaultConfiguration?.currency_symbol} Descuento
                  </TableCell>
                  <TableCell align="center">
                    {defaultConfiguration?.currency_symbol} Abono
                  </TableCell>
                  <TableCell align="center">
                    {defaultConfiguration?.currency_symbol} Valor final
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isFetching
                  ? renderSkeletonRows(perPage, 6)
                  : data?.data?.map((row) => <RenderRow key={row.fees_identifier} p={row} />)}

                {error && renderErrorOrEmptyRow(7, error as string)}
                {data?.data.length === 0 && renderErrorOrEmptyRow(7)}
              </TableBody>
            </Table>
          </TableContainer>

          <CustomPaginationAndItems
            error={error}
            isLoading={isLoading}
            total={data?.total || 0}
            perPage={perPage}
            page={page}
            setPage={setPage}
            setPerPage={setPerPage}
            perPageBox
            paginationBox
          />
        </>
      )}
    </>
  );
};

export default PendingTransc;
