import { IconButton, Tooltip } from '@material-ui/core';
import { IconCheckbox } from '@tabler/icons';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppSelector } from '../../../../hooks/useRedux';
import { useTableSearch } from '../../../../hooks/useTableSearch';
import ConfirmAlert from '../../../shared/components/dialogs/ConfirmAlert';
import DeleteAlert from '../../../shared/components/dialogs/DeleteAlert';
import DeleteButton from '../../../shared/components/tables/buttons/DeleteButton';
import SearchPaginatedTable from '../../../shared/components/tables/SearchPaginatedTable';
import {
  useCancelRerservationMutation,
  useGetReservationsQuery,
} from '../slices/reservationsApiSlice';
import { updateLibraryReservationsTable } from '../slices/reservationsSlice';
import Chip from './Chip';

const ListReservations = () => {
  const history = useHistory();

  const { page, perPage, search, filterArrow } = useAppSelector(
    (state) => state.libraryReservations.libraryReservationsTable
  );

  const { setPage, setPerPage, setSearch, setFilterArrow } = useTableSearch({
    page,
    perPage,
    search,
    filterArrow,
    updateFunction: updateLibraryReservationsTable,
  });

  const [reservationId, setReservationid] = useState<string | undefined>();
  const [reservationIdToAccept, setReservationidToAccept] = useState<string | undefined>();

  const [
    cancelReservation,
    {
      isLoading: isLoadingCancel,
      isSuccess: isSuccessCancel,
      isError: isErrorCancel,
      error: errorCancel,
      reset,
    },
  ] = useCancelRerservationMutation();

  const [
    acceptReservation,
    {
      isLoading: isLoadingAccept,
      isSuccess: isSuccessAccept,
      isError: isErrorAccept,
      error: errorAccept,
      reset: resetAccept,
    },
  ] = useCancelRerservationMutation();

  const {
    data: reservations,
    isLoading: isLoadingReservations,
    isFetching: isFetchingReservations,
    //isError: isErrorReservations,
    error: errorReservations,
    refetch: refetchReservations,
  } = useGetReservationsQuery({
    page,
    size: perPage,
    search,
    ...(filterArrow && { sort: filterArrow.name, type_sort: filterArrow.direction }),
  });

  const datafetch =
    reservations?.data.map((rs, index) => ({
      num: Number(reservations.from) + index,
      code: rs.model_data?.institution_barcode || '',
      user: rs.person_full_name,
      type_user: rs.user_profile,
      title: rs.model_data?.title,
      author: rs.model_data?.autores?.map((at) => at.name).join(','),
      reservation_date: rs.reservation_date,
      status: rs.reservation_status,
      options: '',
    })) || [];

  return (
    <>
      <SearchPaginatedTable
        data={datafetch}
        headers={{
          num: 'Nº',
          code: 'Código de Libro',
          user: 'Usuario',
          type_user: 'Tipo de Usuario',
          title: 'Título',
          author: 'Autor(es)',
          reservation_date: 'Fecha de Reserva',
          status: 'Estado',
          options: 'Opciones',
        }}
        error={errorReservations}
        isFetching={isFetchingReservations}
        isLoading={isLoadingReservations}
        keyExtractor={(row) => String(row.num)}
        numHeader={9}
        page={page}
        perPage={perPage}
        search={search}
        searchPlacehoder="Buscar reserva"
        listFilterArrows={{
          user: 'person_full_name',
          type_user: 'user_profile',
          status: 'reservation_status',
        }}
        filterArrow={filterArrow}
        setFilterArrow={setFilterArrow}
        setPage={setPage}
        setPerPage={setPerPage}
        setSearch={setSearch}
        total={reservations?.total!}
        customDataCellsProperties={{
          num: { align: 'center' },
          type_user: { align: 'center' },
          title: { align: 'center' },
          author: { align: 'center' },
          reservation_date: { align: 'center' },
          status: { align: 'center' },
          options: { align: 'center' },
        }}
        customRenderers={{
          status: (row) => <Chip label={row.status} />,
          options: (row) => (
            <>
              {row.status === 'Pendiente' ? (
                <>
                  <Tooltip title="Aceptar Reserva">
                    <IconButton
                      onClick={() => {
                        setReservationidToAccept(
                          reservations?.data[Number(row.num) - Number(reservations.from)].id ||
                            undefined
                        );
                      }}
                    >
                      <IconCheckbox color="#4ECCA3" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Anular Reserva">
                    <DeleteButton
                      handleDelete={() => {
                        setReservationid(
                          reservations?.data[Number(row.num) - Number(reservations.from)].id ||
                            undefined
                        );
                      }}
                    />
                  </Tooltip>
                </>
              ) : null}
            </>
          ),
        }}
      />
      {Boolean(reservationId) && (
        <DeleteAlert
          error={String(errorCancel)}
          handleDelete={() => {
            cancelReservation({
              id: reservationId!,
              status: 'Eliminado',
              type: 'Interno',
            });
          }}
          isError={isErrorCancel}
          isLoading={isLoadingCancel}
          isSuccess={isSuccessCancel}
          onClose={() => {
            reset();
            setReservationid(undefined);
            refetchReservations();
          }}
          open={Boolean(reservationId)}
          deleteTitle="¿Estás seguro de anular esta reserva?"
          deleteBtnText="Sí, Anular"
          //deleteSuccess="Se anuló la reserva correctamente"
        />
      )}
      {Boolean(reservationIdToAccept) && (
        <ConfirmAlert
          error={String(errorAccept)}
          handleDelete={() => {
            acceptReservation({
              id: reservationIdToAccept!,
              status: 'Aceptado',
              type: 'Interno',
            });
          }}
          isError={isErrorAccept}
          isLoading={isLoadingAccept}
          isSuccess={isSuccessAccept}
          onClose={() => {
            resetAccept();
            setReservationidToAccept(undefined);
            refetchReservations();
          }}
          open={Boolean(reservationIdToAccept)}
          title="¿Está seguro de aceptar esta reserva?"
          subtitle="La reserva cambiara su estado a aceptada."
          confirmBtnMessage="Sí, aceptar reserva"
          customSuccessAction={() => history.push('/administrar-prestamos')}
          customSuccessBtnText="Ir a préstamos"
          successMessage="Se ha aceptado correctamente la reserva"
        />
      )}
    </>
  );
};

export default ListReservations;
