import React, { Ref, useState } from 'react';

// material-ui
import { useTheme } from '@material-ui/core/styles';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  CardProps,
  CardHeaderProps,
  CardContentProps,
  Collapse,
  IconButton,
} from '@material-ui/core';
import { IconChevronDown, IconChevronUp } from '@tabler/icons';

// project imports
import { KeyedObject } from '../../interfaces/material-ui.interfaces';

// constant
const headerSX = {
  '& .MuiCardHeader-action': { mr: 0 },
};

//-----------------------|| CUSTOM MAIN CARD ||-----------------------//

export interface CollapsibleCardProps extends KeyedObject {
  iconToOpen?: JSX.Element;
  iconToClose?: JSX.Element;
  border?: boolean;
  boxShadow?: boolean;
  children: React.ReactNode | string;
  style?: React.CSSProperties;
  content?: boolean;
  className?: string;
  contentClass?: string;
  contentSX?: CardContentProps['sx'];
  darkTitle?: boolean;
  sx?: CardProps['sx'];
  secondary?: CardHeaderProps['action'];
  shadow?: string;
  elevation?: number;
  title?: React.ReactNode | string;
  /**
   * false: click on title container not toggle accordion
   *  true: yes
   *  */

  containerClick?: boolean;
}

const CollapsibleCard = React.forwardRef(
  (
    {
      iconToOpen,
      iconToClose,
      border = false,
      boxShadow,
      children,
      content = true,
      contentClass = '',
      contentSX = {},
      darkTitle,
      secondary,
      shadow,
      sx = {},
      title,
      containerClick = true,
      ...others
    }: CollapsibleCardProps,
    ref: Ref<HTMLDivElement>
  ) => {
    const theme = useTheme();
    const collapseBtnSize = 20;

    const [open, setOpen] = useState(true);

    const toogleOpen = () => {
      setOpen(!open);
    };
    const toogleOpenHeader = () => {
      if (containerClick) setOpen(!open);
    };

    return (
      <Card
        ref={ref}
        {...others}
        sx={{
          border: border ? '1px solid' : 'none',
          borderColor:
            theme.palette.mode === 'dark'
              ? theme.palette.background.default
              : theme.palette.grey[100],
          ':hover': {
            boxShadow: boxShadow
              ? shadow
                ? shadow
                : theme.palette.mode === 'dark'
                ? '0 2px 14px 0 rgb(33 150 243 / 10%)'
                : '0 2px 14px 0 rgb(32 40 45 / 8%)'
              : 'inherit',
          },
          padding: 0,
          ...sx,
        }}
      >
        {/* card header and action */}
        {!darkTitle && title && (
          <CardHeader
            sx={headerSX}
            title={title}
            onClick={toogleOpenHeader}
            action={
              secondary ? (
                <>
                  {secondary}
                  {!open ? (
                    <IconButton onClick={toogleOpen}>
                      {iconToOpen ? iconToOpen : <IconChevronDown size={collapseBtnSize} />}
                    </IconButton>
                  ) : (
                    <IconButton onClick={toogleOpen}>
                      {iconToClose ? iconToClose : <IconChevronUp size={collapseBtnSize} />}
                    </IconButton>
                  )}
                </>
              ) : !open ? (
                <IconButton onClick={toogleOpen}>
                  {iconToOpen ? iconToOpen : <IconChevronDown size={collapseBtnSize} />}
                </IconButton>
              ) : (
                <IconButton onClick={toogleOpen}>
                  {iconToClose ? iconToClose : <IconChevronUp size={collapseBtnSize} />}
                </IconButton>
              )
            }
          />
        )}

        {darkTitle && title && (
          <CardHeader
            sx={headerSX}
            title={<Typography variant="h3">{title}</Typography>}
            onClick={toogleOpenHeader}
            action={
              secondary ? (
                <>
                  {secondary}
                  {!open ? (
                    <IconButton onClick={toogleOpen}>
                      {iconToOpen ? iconToOpen : <IconChevronDown size={collapseBtnSize} />}
                    </IconButton>
                  ) : (
                    <IconButton onClick={toogleOpen}>
                      {iconToClose ? iconToClose : <IconChevronUp size={collapseBtnSize} />}
                    </IconButton>
                  )}
                </>
              ) : !open ? (
                <IconButton onClick={toogleOpen}>
                  {iconToOpen ? iconToOpen : <IconChevronDown size={collapseBtnSize} />}
                </IconButton>
              ) : (
                <IconButton onClick={toogleOpen}>
                  {iconToClose ? iconToClose : <IconChevronUp size={collapseBtnSize} />}
                </IconButton>
              )
            }
          />
        )}

        {!darkTitle && !title && (
          <CardHeader
            sx={headerSX}
            title={''}
            onClick={toogleOpenHeader}
            action={
              secondary ? (
                <>
                  {secondary}
                  {!open ? (
                    <IconButton onClick={toogleOpen}>
                      {iconToOpen ? iconToOpen : <IconChevronDown size={collapseBtnSize} />}
                    </IconButton>
                  ) : (
                    <IconButton onClick={toogleOpen}>
                      {iconToClose ? iconToClose : <IconChevronUp size={collapseBtnSize} />}
                    </IconButton>
                  )}
                </>
              ) : !open ? (
                <IconButton onClick={toogleOpen}>
                  {iconToOpen ? iconToOpen : <IconChevronDown size={collapseBtnSize} />}
                </IconButton>
              ) : (
                <IconButton onClick={toogleOpen}>
                  {iconToClose ? iconToClose : <IconChevronUp size={collapseBtnSize} />}
                </IconButton>
              )
            }
          />
        )}

        {/* content & header divider */}
        {title && <Divider />}

        {/* card content */}
        {content && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <CardContent sx={contentSX} className={contentClass}>
              {children}
            </CardContent>
          </Collapse>
        )}
        {!content && children}
      </Card>
    );
  }
);

export default CollapsibleCard;
