import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISearchTable } from '../../../../shared/interfaces/paginatedTable.interface';
import {
  StudentAnnouncements,
  UserAnnouncements,
} from '../interfaces/student-announcement.interfaces';
import { StudentCourseDetail } from '../interfaces/student-course.interfaces';
import { StudentAsigned } from '../interfaces/students-asigned.interfaces';

interface StudentAsignedState {
  studentsList: StudentAsigned[];
  studentCourseDetail: StudentCourseDetail[];
  userHistoryAnnouncements: UserAnnouncements[];
  studentHistoryAnnouncements: StudentAnnouncements[];
  studentSelected?: number;
  notifyAll: boolean;
  courseSelected?: number;
  openModalUserAnnouncements: boolean;
  openModalStudentHistoryAnnouncements: boolean;
  openModalStudentAds: boolean;
  openModalStudentDetailCourse: boolean;
  studentsAssignedTable: ISearchTable;
}

const initialState: StudentAsignedState = {
  studentsList: [],
  studentHistoryAnnouncements: [],
  userHistoryAnnouncements: [],
  openModalStudentHistoryAnnouncements: false,
  studentCourseDetail: [],
  openModalUserAnnouncements: false,
  openModalStudentDetailCourse: false,
  notifyAll: false,
  openModalStudentAds: false,
  studentsAssignedTable: {
    page: 1,
    perPage: 5,
    search: '',
  },
};

const studentAsignedSlice = createSlice({
  name: 'studentsAsigned',
  initialState,
  reducers: {
    setStudentList: (state, action: PayloadAction<StudentAsigned[]>) => {
      state.studentsList = action.payload;
    },
    setStudentCourseDetail: (state, action: PayloadAction<StudentCourseDetail[]>) => {
      state.studentCourseDetail = action.payload;
    },
    setCourseSelected: (state, action: PayloadAction<number>) => {
      state.courseSelected = action.payload;
    },
    resetCourseSelected: (state) => {
      state.courseSelected = undefined;
    },
    setOpenModalStudentAds: (state, action: PayloadAction<boolean>) => {
      state.openModalStudentAds = action.payload;
    },
    setStudentSelected: (state, action: PayloadAction<number>) => {
      state.studentSelected = action.payload;
    },
    setNotifyAll: (state, action: PayloadAction<boolean>) => {
      state.notifyAll = action.payload;
    },
    resetStudentSelected: (state) => {
      state.studentSelected = undefined;
    },
    setOpenModalStudentDetailCourse: (state, action: PayloadAction<boolean>) => {
      state.openModalStudentDetailCourse = action.payload;
    },
    setUserHistoryAnnouncements: (state, action: PayloadAction<UserAnnouncements[]>) => {
      state.userHistoryAnnouncements = state.userHistoryAnnouncements.concat(action.payload);
    },
    setStudentHistoryAnouncements: (state, action: PayloadAction<StudentAnnouncements[]>) => {
      state.studentHistoryAnnouncements = state.studentHistoryAnnouncements.concat(action.payload);
    },
    resetStudentHistoryAnouncements: (state) => {
      state.studentHistoryAnnouncements = [];
    },
    setOpenModalUserAnnouncements: (state, action: PayloadAction<boolean>) => {
      state.openModalUserAnnouncements = action.payload;
    },
    setOpenModalStudentHistoryAnnouncements: (state, action: PayloadAction<boolean>) => {
      state.openModalStudentHistoryAnnouncements = action.payload;
    },
    updateStudentAsignedTable: (state, action: PayloadAction<ISearchTable>) => {
      state.studentsAssignedTable = action.payload;
    },
  },
});

export const {
  setCourseSelected,
  resetCourseSelected,
  resetStudentSelected,
  setOpenModalStudentAds,
  setOpenModalStudentDetailCourse,
  setStudentSelected,
  setOpenModalUserAnnouncements,
  setNotifyAll,
  setStudentHistoryAnouncements,
  resetStudentHistoryAnouncements,
  setOpenModalStudentHistoryAnnouncements,
  setUserHistoryAnnouncements,
  setStudentCourseDetail,
  setStudentList,
  updateStudentAsignedTable,
} = studentAsignedSlice.actions;

export default studentAsignedSlice.reducer;
