import * as yup from 'yup';

export const launchingFormSchema = yup.object().shape({
  evaluators: yup
    .array()
    .of(
      yup
        .number()
        .typeError('Debe seleccionar un evaluador para cada evaluado')
        .integer()
        .positive()
        .required('Debe seleccionar un evaluador para cada evaluado')
    )
    .min(1, 'Debe seleccionar los evaluadores')
    .required('Evaludores requeridos'),
});
