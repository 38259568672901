export const permissionKey = 'calificationModel';

export interface SubPermissionKeys {
  read: string;
  create: string;
  edit: string;
  delete: string;
}

export const subPermissionKeys: SubPermissionKeys = {
  read: 'calification-models-listar-modelo-calificacion',
  create: 'calification-models-crear-modelo-calificacion',
  edit: 'calification-models-actualizar-modelo-calificacion',
  delete: 'calification-models-borrar-modelo-calificacion',
};
