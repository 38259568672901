import { Button, FormHelperText, Grid, InputLabel, TextField, Typography } from '@material-ui/core';
import { ArrowBack, ArrowForward } from '@material-ui/icons';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { RegisterSyllabusData, SyllabusBibliogra } from '../../../interfaces/registerSyllabusData';
import DeleteButton from '../../tables/buttons/DeleteButton';

interface Props {
  setNext: React.Dispatch<React.SetStateAction<number>>;
  isBack?: boolean;
  aditionalFunctionInNext?: () => void;
  finalStep?: boolean;
}

const ComplementaryBibliography = ({
  setNext,
  finalStep,
  isBack,
  aditionalFunctionInNext,
}: Props) => {
  const { t } = useTranslation();

  const { values, setFieldValue, getFieldProps, errors, touched } =
    useFormikContext<RegisterSyllabusData>();

  const handleDeleteOption = (index: number) => {
    setFieldValue(
      'syllabus_bibliogra_comple',
      values.syllabus_bibliogra_comple.filter((_, i) => i !== index)
    );
  };

  const handleBack = () => {
    setNext((step) => {
      if (step === 1) {
        return step;
      }
      return step - 1;
    });
  };

  const handleNext = () => {
    setNext((step) => {
      if (finalStep) {
        return step;
      }
      return step + 1;
    });
    aditionalFunctionInNext && aditionalFunctionInNext();
  };

  return (
    <Grid container direction="column">
      <Grid item container xs={12} md={6} spacing={2}>
        <Grid item xs={12}>
          {values.syllabus_bibliogra_comple?.length > 0 ? (
            <>
              {values.syllabus_bibliogra_comple.map((option, index) => (
                <Grid
                  item
                  container
                  xs={12}
                  spacing={1}
                  key={`bibliografy-comple-${index + 1}`}
                  sx={{
                    my: 1,
                  }}
                >
                  <Grid item xs={4}>
                    <InputLabel>{t('Texto')}</InputLabel>
                    <TextField
                      {...getFieldProps(`syllabus_bibliogra_comple[${index}].book_name`)}
                      fullWidth
                      error={Boolean(
                        (errors.syllabus_bibliogra_comple?.[index] as SyllabusBibliogra)
                          ?.book_name && touched.syllabus_bibliogra_comple?.[index]?.book_name
                      )}
                    />
                    {errors.syllabus_bibliogra_comple?.[index] &&
                      touched.syllabus_bibliogra_comple?.[index]?.book_name && (
                        <FormHelperText error>
                          {t(
                            (errors.syllabus_bibliogra_comple?.[index] as SyllabusBibliogra)
                              .book_name
                          )}
                        </FormHelperText>
                      )}
                  </Grid>
                  <Grid item xs={3}>
                    <InputLabel>{t('Autor')}</InputLabel>
                    <TextField
                      error={Boolean(
                        (errors.syllabus_bibliogra_comple?.[index] as SyllabusBibliogra)
                          ?.book_autor && touched.syllabus_bibliogra_comple?.[index]?.book_autor
                      )}
                      {...getFieldProps(`syllabus_bibliogra_comple[${index}].book_autor`)}
                      fullWidth
                    />
                    {errors.syllabus_bibliogra_comple?.[index] &&
                      touched.syllabus_bibliogra_comple?.[index]?.book_autor && (
                        <FormHelperText error>
                          {t(
                            (errors.syllabus_bibliogra_comple?.[index] as SyllabusBibliogra)
                              .book_autor
                          )}
                        </FormHelperText>
                      )}
                  </Grid>
                  <Grid item xs={3}>
                    <InputLabel>{t('Editorial')}</InputLabel>
                    <TextField
                      error={Boolean(
                        (errors.syllabus_bibliogra_comple?.[index] as SyllabusBibliogra)
                          ?.book_editor && touched.syllabus_bibliogra_comple?.[index]?.book_editor
                      )}
                      {...getFieldProps(`syllabus_bibliogra_comple[${index}].book_editor`)}
                      fullWidth
                    />
                    {errors.syllabus_bibliogra_comple?.[index] &&
                      touched.syllabus_bibliogra_comple?.[index]?.book_editor && (
                        <FormHelperText error>
                          {t(
                            (errors.syllabus_bibliogra_comple?.[index] as SyllabusBibliogra)
                              .book_editor
                          )}
                        </FormHelperText>
                      )}
                  </Grid>
                  <Grid item container xs={2} justifyContent="center" alignItems="center">
                    <DeleteButton handleDelete={() => handleDeleteOption(index)} />
                  </Grid>
                </Grid>
              ))}
            </>
          ) : (
            <Grid item xs={12}>
              <Typography variant="h5" textAlign="center">
                {t('No ha agregado ninguna bibliografía complementaria')}
              </Typography>
            </Grid>
          )}
        </Grid>
        {errors.syllabus_bibliogra_comple && !Array.isArray(errors.syllabus_bibliogra_comple) && (
          <FormHelperText sx={{ mt: 6 }} error>
            {errors.syllabus_bibliogra_comple}
          </FormHelperText>
        )}
        <Grid
          item
          xs={12}
          container
          alignItems="center"
          justifyContent="center"
          spacing={2}
          sx={{ mt: 6 }}
        >
          {isBack && (
            <Grid item>
              <Button
                fullWidth
                variant="outlined"
                size="large"
                color="primary"
                onClick={handleBack}
              >
                <ArrowBack />
              </Button>
            </Grid>
          )}
          <Grid item>
            <Button
              fullWidth
              variant="outlined"
              size="large"
              color="primary"
              onClick={handleNext}
              // disabled={isLoading}
              // endIcon={isLoading && <CircularProgress size={20} />}
            >
              <ArrowForward />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ComplementaryBibliography;
