import { Box, Button, Grid, Typography } from '@material-ui/core';
import React from 'react';
import MainCard from '../../../../shared/components/cards/MainCard';
import { useTranslation } from 'react-i18next';
import NotesTable from '../components/NotesTable';
import { ArrowBackIos } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';

const QualificationsScreen = () => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <React.Fragment>
      <Grid
        component={MainCard}
        style={{ minHeight: '400px' }}
        sx={{
          borderTopLeftRadius: '0px',
          borderTopRightRadius: '0px',
        }}
      >
        <Box
          sx={{
            mb: 3,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Typography variant="h2">
            {t('studentVirtualClassroom.qualification.qualification')}
          </Typography>
          <Button
            onClick={() => history.push('/aula-virtual-estudiante/notas')}
            variant="contained"
            startIcon={<ArrowBackIos />}
          >
            {t('students.virtual_classroom.task.modal.button.back')}
          </Button>
        </Box>

        <NotesTable />
      </Grid>
    </React.Fragment>
  );
};

export default QualificationsScreen;
