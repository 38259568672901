import { useEffect } from 'react';

// import { useHistory } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';
// import { IconArrowNarrowLeft } from '@tabler/icons';

import { useTranslation } from 'react-i18next';

// import MainCardForm from '../../../shared/components/cards/MainCardForm';
// import Checkout from '../components/Checkout';

import { useAppDispatch } from '../../../../hooks/useRedux';
import { setSidebarOpened } from '../../../shared/reducers/customizationReducer';
import GenerateCreditNote from '../components/GenerateCreditNote';

const GenerateCreditNoteScreen = () => {
  //   const theme = useTheme();
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  //close menu
  useEffect(() => {
    if (false) dispatch(setSidebarOpened(false));
  }, [dispatch]);

  //
  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid item xs={12} sx={{ padding: 4, bgcolor: 'background.paper', borderRadius: 2 }}>
          <Grid item xs={12}>
            <Grid item container alignItems="center" gap={1} sx={{ cursor: 'pointer' }}>
              {/* <IconButton onClick={() => history.push(`/${location.pathname.split('/')[1]}`)}>
                <IconArrowNarrowLeft size={30} color={theme.palette.text.dark} />
              </IconButton> */}
              <Typography variant="h2">{t('financial.generate_creditnote.title')}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ mt: 2 }}>
            <GenerateCreditNote />
            {/* {false && <Checkout />} */}
          </Grid>
        </Grid>
      </Grid>

      {/* <Grid item xs={12} md={'auto'} lg={4} sx={{ pt: { xs: 1, lg: 0 } }}>
        <Grid item xs={12} sx={{ padding: 1, bgcolor: 'background.paper', borderRadius: 2 }}>
          <TempBillingData />
        </Grid>
      </Grid> */}
    </Grid>
  );
};

export default GenerateCreditNoteScreen;
