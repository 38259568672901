import { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Autocomplete from '@material-ui/core/Autocomplete';
import TextField from '@material-ui/core/TextField';
// import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/core/Alert';

import { useTranslation } from 'react-i18next';

import SelectInvoiceSideDialog from '../SelectInvoiceSideDialog';
import { Reason } from '../../interfaces/payload.interface';
import { reasons, reasonAnulationTotal, reasonAnulationPartial } from '../../constants';
import { useAppSelector, useAppDispatch } from '../../../../../hooks/useRedux';
import { useGenerateCreditNoteMutation } from '../../slices/generateCreditNoteApiSlice';
import {
  resetClient,
  resetInvoice,
  setMotivo,
  setSelectedFees,
  setTotalFees,
} from '../../slices/generateCreditNoteSlice';
import ErrorAlert from '../../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../../shared/components/alerts/SuccessAlert';
import InvoiceDetailsTabs from './InvoiceDetailsTabs';

// interface Props {}

const CreditNote = () => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const defaultConfiguration = useAppSelector((state) => state.access.defaultConfiguration);

  const { currentClient, selectedInvoice, selectedFees, totalFees, motivo, totalRevertedFees } =
    useAppSelector((state) => state.generateCreditNote);

  const [showAlerts, setShowAlerts] = useState(false);

  const [open, setOpen] = useState(false);

  const [autocompleteKeys] = useState<{
    motivoKey: number;
    valorModificacionKey: number;
  }>({
    motivoKey: Math.random(),
    valorModificacionKey: Math.random(),
  });

  // const [motivo, setMotivo] = useState<string | undefined>(undefined);

  const handleOpenDialog = () => {
    setOpen(true);
  };
  const handleCloseDialog = (e: React.SyntheticEvent) => {
    setOpen(false);
  };

  const [generateCreditNote, { isLoading, isSuccess, isError, error }] =
    useGenerateCreditNoteMutation();

  //submit alert with portal
  const AlertPortal = () => {
    const container = document.getElementById('creditNote-alert-portal');

    return container ? (
      ReactDOM.createPortal(
        <>
          {isError && showAlerts && (
            <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
          )}
          {isSuccess && showAlerts && (
            <SuccessAlert
              message={t('financial.generate_creditnote.success_alert')}
              handleDismiss={() => setShowAlerts(false)}
            />
          )}
        </>,
        container!
      )
    ) : (
      <></>
    );
  };

  const handleGenerateCreditNote = async () => {
    try {
      await generateCreditNote({
        generateCreditNotePayload: {
          id_fact: selectedInvoice?.id,
          motivo: motivo,
          valorModificacion: totalFees,
          detalles: selectedFees,
        },
      })
        .unwrap()
        .then(() => {
          dispatch(resetInvoice());
          dispatch(resetClient());
        });
    } catch (error) {
      console.log(error);
    }

    setShowAlerts(true);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    //cambiar el value de valor si el valor del slice cambia o el motivo si el valor del slice es igual que el total
    if (selectedFees.length) {
      if (totalFees === +(selectedInvoice?.importeTotal || 0) - totalRevertedFees) {
        dispatch(setMotivo(reasonAnulationTotal));
      } else {
        dispatch(setMotivo(reasonAnulationPartial));
      }
    }
  }, [dispatch, selectedFees.length, selectedInvoice?.importeTotal, totalFees, totalRevertedFees]);

  //actualiza el initlaState del formik cuando este cambie
  // useEffect(() => {
  //   setValues({ ...initialStates });
  //   setAutocompleteKeys({
  //     motivoKey: Math.random(),
  //     valorModificacionKey: Math.random(),
  //   });
  // }, [initialStates, setValues, currentClient, selectedInvoice]);

  const handleChangeAutocomplete = (
    values: {
      id: number;
      label: Reason;
    } | null
  ) => {
    dispatch(setMotivo(values?.label || undefined));
    // return;
    if (values?.label === reasonAnulationTotal) {
      dispatch(setTotalFees(Number(selectedInvoice?.importeTotal) || 0));
      dispatch(setSelectedFees(selectedInvoice?.details.map((fee) => fee.id) || []));
    }
  };

  return (
    <>
      <AlertPortal />

      <Grid container spacing={1}>
        <Grid item xs={12} sx={{ textAlign: 'right' }}>
          <Button onClick={handleOpenDialog} variant="outlined" disabled={!currentClient}>
            {t('financial.generate_creditnote.search_client_btn')}
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Grid container justifyContent={'right'} alignItems={'center'} spacing={1}>
            <Grid item xs={12} sm={4}>
              <Typography variant="h4">{t('financial.generate_creditnote.subtitle')}</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Autocomplete
                key={autocompleteKeys.motivoKey}
                disabled={!selectedInvoice}
                options={reasons || []}
                getOptionLabel={(option) => option.label}
                clearIcon={false}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    // error={Boolean(errors.campusIds && touched.campusIds)}
                    placeholder={t('financial.generate_creditnote.invoice_placeholder')}
                    label={t('financial.generate_creditnote.invoice_label')}
                    // error={Boolean(errors.motivo && touched.motivo)}
                  />
                )}
                value={reasons?.find((rs) => rs.label === motivo) || null}
                onChange={(_, values) => {
                  handleChangeAutocomplete(values);
                }}
              />
              {/* {errors.motivo && touched.motivo && (
                <FormHelperText error>{errors.motivo}</FormHelperText>
              )} */}
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                // key={autocompleteKeys.valorModificacionKey}
                disabled
                // disabled={
                //   !motivo || motivo === reasonAnulationTotal || !selectedInvoice
                // }
                // {...(motivo === reasonAnulationTotal && {
                //   value: selectedInvoice?.importeTotal,
                // })}
                {...(motivo && totalFees && { label: 'Total N/C' })}
                fullWidth
                InputProps={{
                  startAdornment: defaultConfiguration?.currency_symbol,
                }}
                value={totalFees}
                placeholder={`${defaultConfiguration?.currency_symbol}0.00`}
                // error={Boolean(errors.valorModificacion && touched.valorModificacion)}
              />
              {/* {errors.valorModificacion && touched.valorModificacion && (
                <FormHelperText error>{errors.valorModificacion}</FormHelperText>
              )} */}
            </Grid>
          </Grid>
        </Grid>

        {!selectedInvoice && currentClient ? (
          <Grid item xs={12}>
            <Grid container justifyContent="center" p={2}>
              <Grid item xs={8}>
                <Alert severity="info">
                  {t('financial.generate_creditnote.select_invoice_alert')}
                </Alert>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <InvoiceDetailsTabs />
          </Grid>
        )}

        <Grid item xs={12} sx={{ textAlign: 'right' }}>
          <Button
            variant="contained"
            size="large"
            onClick={handleGenerateCreditNote}
            disabled={!selectedInvoice || isLoading}
            endIcon={isLoading && <CircularProgress size={20} />}
          >
            {t('financial.generate_creditnote.generate_btn')}
          </Button>
        </Grid>
      </Grid>

      <SelectInvoiceSideDialog open={open} handleCloseDialog={handleCloseDialog} />
    </>
  );
};

export default CreditNote;
