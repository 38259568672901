import { ErrorValidation, NamesTabs } from '../components/formTabs';

const errorValidation = (
  errors: {},
  formName: NamesTabs,
  firstShow: boolean,
  errorValidation?: React.Dispatch<React.SetStateAction<ErrorValidation[]>>
) => {
  if (!!errorValidation && firstShow) {
    if (!!Object.keys(errors)?.length) {
      errorValidation((data) => [
        ...data.filter((e) => !(e.formName === formName)),
        ...[{ formName, error: true }],
      ]);
    } else {
      errorValidation((data) => [
        ...data.filter((e) => !(e.formName === formName)),
        ...[{ formName, error: false }],
      ]);
    }
  }
};

export default errorValidation;
