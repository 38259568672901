import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import BackButton from '../../../../../shared/components/buttons/BackButton';
import MainCard from '../../../../../shared/components/cards/MainCard';
import RepresentativeScaleForm from '../components/RepresentativeScaleForm';

const AddRepresentativeScale = () => {
  const { t } = useTranslation();

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid container alignItems="center" sx={{ mb: 3 }}>
        <BackButton />
        <Typography variant="h2">{t('Agregar Escala Representativa')}</Typography>
      </Grid>
      <RepresentativeScaleForm />
    </Grid>
  );
};

export default AddRepresentativeScale;
