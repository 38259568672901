import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
// import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import { IconNotes } from '@tabler/icons';

import MainCard from '../../../shared/components/cards/MainCard';
import { useAppSelector } from '../../../../hooks/useRedux';

// style constant
const useStyles = makeStyles((theme) => ({
  content: {
    // padding: '10px',
    border: `${theme.palette.secondary.main} solid 1px`,
    borderRadius: '12px !important',
  },
}));

const CurriculumCard = () => {
  // const theme = useTheme();
  const classes = useStyles();

  const { currentRecordStudent } = useAppSelector((state) => state.access);

  return (
    <React.Fragment>
      <MainCard contentClass={classes.content}>
        <Grid container direction="row" justifyContent="flex-start" alignItems="center">
          <Grid item>
            <Tooltip title={currentRecordStudent?.mesh.link_curriculum || ''}>
              <Button
                onClick={() =>
                  window.open(currentRecordStudent?.mesh.link_curriculum || '', '_blank')
                }
                disabled={!currentRecordStudent?.mesh?.link_curriculum}
                startIcon={<IconNotes />}
              >
                {currentRecordStudent?.mesh.link_curriculum
                  ? 'Ver malla curricular'
                  : 'No existe malla'}
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
      </MainCard>
    </React.Fragment>
  );
};

export default CurriculumCard;
