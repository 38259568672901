import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import DeleteAlert from '../../../shared/components/dialogs/DeleteAlert';
import { useDeletePosgraduatePlanningCohortMutation } from '../slices/posgraduatePlanningCohortApiSlice';
import {
  setPosgraduatePlanningCohortDeleteDialogOpen,
  setPosgraduatePlanningCohortIdSelected,
} from '../slices/posgraduatePlanningCohortSlice';

const PostgraduatePlanningCohortDeleteDialog = () => {
  const dispatch = useAppDispatch();

  const { currentProfile } = useAppSelector((state) => state.access);

  const { posgraduatePlanningCohortIdSelected } = useAppSelector(
    (state) => state.posgraduatePlanningCohorts
  );

  const [deleteCohort, { isLoading, isError, error, isSuccess }] =
    useDeletePosgraduatePlanningCohortMutation();

  const handleDelete = () => {
    deleteCohort({
      profileId: currentProfile?.profileId!,
      id: posgraduatePlanningCohortIdSelected!,
    });
  };

  const handleClose = () => {
    dispatch(setPosgraduatePlanningCohortDeleteDialogOpen(false));
    dispatch(setPosgraduatePlanningCohortIdSelected(null));
  };

  return (
    <DeleteAlert
      open
      handleDelete={handleDelete}
      onClose={handleClose}
      isLoading={isLoading}
      isError={isError}
      error={error as string}
      isSuccess={isSuccess}
    />
  );
};

export default PostgraduatePlanningCohortDeleteDialog;
