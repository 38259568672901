import { useFormikContext } from 'formik';
import { AssignQualiCoursesFormValues } from '../AssignQualiCoursesForm';
import { useTranslation } from 'react-i18next';
import { Checkbox, Grid, IconButton, Tooltip, Typography } from '@material-ui/core';
import { IconEye, IconChevronRight } from '@tabler/icons';

import { useGetCoursesStagesQuery } from '../../../periods/slices/periodsApiSlice';
import TableNoPaginated from '../../../../shared/components/tables/TableNoPaginated';
import { parseISO } from 'date-fns';
import { CoursesStage } from '../../../periods/interfaces/calification-models.interfaces';
import { useEffect } from 'react';

interface Props {
  singleCourseSel: number;
  setSingleCourseSel: React.Dispatch<React.SetStateAction<number>>;
}

const CoursesTable = ({ singleCourseSel, setSingleCourseSel }: Props) => {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<AssignQualiCoursesFormValues>();

  const { data, error, isLoading, isFetching } = useGetCoursesStagesQuery(
    {
      periodId: values.period_id,
      qualificationModelId: values.calificationModelId,
      courseId: values.course_id,
      collaboratorId: values.collaborator_id,
    },
    { skip: !values.period_id || !values.calificationModelId }
  );

  const isCheckedOrIndeterminate = (keyCase: 'indeterminate' | 'checked' = 'checked') => {
    if (!data?.length || !values.courses.length) return false;

    const mappedData = data?.map((n) => +n.id) || [];
    const currentPageSelected = values.courses.filter((sel) =>
      mappedData.some((mp) => mp === sel.course_id)
    );

    const isChecked = currentPageSelected.length === mappedData.length;

    if (keyCase === 'indeterminate') {
      if (isChecked) return false;
      return currentPageSelected.some((sel) => mappedData.some((mp) => mp === sel.course_id));
    }

    return isChecked;
  };

  //check all
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const addSelection =
        data
          ?.filter((dt) => !values.courses.some((sel) => sel.course_id === dt.id))
          .map((n) => ({ course_id: +n.id, ...n })) || [];

      setFieldValue('courses', [...values.courses, ...addSelection]);
      return;
    }

    // si ya esta el select all y solo se quiere quitar la seleccion de la pagina actual
    const newSelection = values.courses.filter(
      (sel) => !data?.some((mp) => mp.id === sel.course_id)
    );
    setFieldValue('courses', newSelection);
  };

  const datafetch = data?.map((item) => ({
    id: item.id,
    course: { name: item.course_description, teacher: item.pers_full_name },
    options: item,
    // credits: item.credits,
    // hours: item.hours,
  }));

  const toogleSelectHandler = (course: CoursesStage, singleSel = false) => {
    // if (singleSel) return setFieldValue(`courses`, [{ course_id: course.id, ...course }]);

    if (values.courses.some((dt) => dt.course_id === course.id)) {
      // cuando se selecciona desde los checks y se vuelve a seleccionar desde el ojito
      // se borre la seleccion de todos los checks menos la del ojito
      if (values.courses.length > 1 && singleSel)
        return setFieldValue(`courses`, [{ course_id: course.id, ...course }]);

      setFieldValue(
        `courses`,
        values.courses.filter((dt) => dt.course_id !== course.id)
      );
      return;
    }

    if (singleSel) return setFieldValue(`courses`, [{ course_id: course.id, ...course }]);
    setFieldValue(`courses`, [...values.courses, { course_id: course.id, ...course }]);
  };

  const setModelDatesHandler = (id: number) => {
    const model = data?.find((dt) => dt.id === id);
    if (!model) return;

    setFieldValue('calificationModelDates', {
      calification_model_id: model.calification_model_id,
      distributions: [
        ...values.calificationModelDates.distributions.map((dist) => ({
          ...dist,
          supplies: dist.supplies.map((supply) => {
            const startDate = model.course_stage.find(
              (stg) => stg.supplie_id === supply.ref2_supply_id
            )?.start_date;
            const endDate = model.course_stage.find(
              (stg) => stg.supplie_id === supply.ref2_supply_id
            )?.end_date;

            return {
              ...supply,
              startDate: startDate ? parseISO(startDate) : null,
              endDate: endDate ? parseISO(endDate) : null,
            };
          }),
        })),
      ],
    });
  };

  useEffect(() => {
    if (values && values?.courses.length === 1) {
      setModelDatesHandler(values?.courses[0].id!);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.courses]);

  return (
    <Grid container spacing={2}>
      {/* <Grid item xs={12}>
        <Typography variant="h5" sx={{ my: 3 }}>
          {t('Cursos')}
        </Typography>
      </Grid> */}

      <Grid item xs={12}>
        <TableNoPaginated
          data={datafetch || []}
          headers={{
            id: (
              <Checkbox
                disabled={isLoading || !!singleCourseSel}
                indeterminate={isCheckedOrIndeterminate('indeterminate')}
                checked={isCheckedOrIndeterminate()}
                onChange={handleSelectAllClick}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            ) as any,
            course: t('Curso'),
            options: t('Opciones'),
          }}
          isLoading={isLoading}
          isFetching={isFetching}
          error={error}
          search=""
          keyExtractor={(item) => item.id.toString()}
          searchPlacehoder=""
          customRenderers={{
            id: (item) => (
              <Checkbox
                disabled={!!singleCourseSel}
                checked={values?.courses.some((dt) => dt.course_id === item.id)}
                onChange={(e) => {
                  toogleSelectHandler(item.options);
                  setModelDatesHandler(item.id);
                  setSingleCourseSel(0);
                }}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            ),

            course: (item) => (
              <div>
                <Typography variant="body1">{item.course.name}</Typography>
                <Typography variant="body2" color="textSecondary">
                  {item.course.teacher}
                </Typography>
              </div>
            ),
            options: (item) => (
              <>
                <Tooltip title={t('Ver insumos')}>
                  <IconButton
                    aria-label={t('Ver insumos')}
                    color="default"
                    onClick={() => {
                      const isOpen =
                        values.courses.length === 1 && values.courses[0].course_id === item.id;

                      toogleSelectHandler(item.options, true);

                      setSingleCourseSel(!isOpen ? item.id : 0);

                      if (!isOpen) {
                        setModelDatesHandler(item.id);
                      }
                    }}
                  >
                    {values.courses.length === 1 && values.courses[0].course_id === item.id ? (
                      <IconChevronRight size={20} />
                    ) : (
                      <IconEye size={20} />
                    )}
                  </IconButton>
                </Tooltip>
              </>
            ),
          }}
          customDataCellsProperties={{
            id: { align: 'center' },
            options: { align: 'center' },
            // course: { align: 'left' },
          }}
          showFilter={false}
        />
      </Grid>
    </Grid>
  );
};

export default CoursesTable;
