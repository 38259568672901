import Chip2 from '../../../shared/components/extended/Chip';

interface props {
  label: string;
}

const Chip = ({ label }: props) => {
  const handleColor = () => {
    let chipLabel = '';
    switch (label) {
      case 'Pendiente':
        chipLabel = 'info';
        break;
      case 'Aceptado':
        chipLabel = 'success';
        break;
      case 'Eliminado':
        chipLabel = 'error';
        break;
      default:
        break;
    }
    return chipLabel;
  };

  return <Chip2 label={label} chipcolor={handleColor()} />;
};

export default Chip;
