import { makeStyles } from '@material-ui/core/styles';

export const useFormStyles = makeStyles((theme) => ({
  inputLabel: {
    marginBlockEnd: 6,
    fontWeight: 500,
    color: theme.palette.text.primary,
  },
}));

export const containerStyles = makeStyles((theme) => ({
  inputLabel: {
    marginBlockEnd: 6,
    fontWeight: 500,
    color: theme.palette.text.primary,
  },
}));

export const useStyles = makeStyles((theme) => ({
  separate: {
    marginBottom: '20px',
  },
  accountTab: {
    marginBottom: '24px',
    '& a': {
      minHeight: 'auto',
      minWidth: '10px',
      padding: '12px 8px',
      marginRight: '18px',
      color: theme.palette.grey[600],
    },
    '& a.Mui-selected': {
      color: theme.palette.primary.main,
    },
    '& a > span': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '& a > span > svg': {
      marginBottom: '0px !important',
      marginRight: '10px',
    },
    '& a > span > span + svg': {
      margin: '0px 0px 0px auto !important',
      width: '14px',
      height: '14px',
    },
  },
}));
