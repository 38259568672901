import { useEffect, useState } from 'react';
import { useHistory, Link, useRouteMatch } from 'react-router-dom';
import KeyIcon from '@material-ui/icons/Lock';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { useGetCollaboratorsQuery } from '../slices/collaboratorsApiSlice';
import EditButton from '../../../shared/components/tables/buttons/EditButton';
import DeleteButton from '../../../shared/components/tables/buttons/DeleteButton';
import AddButton from '../../../shared/components/tables/buttons/AddButton';

import { openDeleteModalWith } from '../slices/collaboratorsSlice';
import useGuard from '../hooks/useGuard';

import StatusChip from '../../../shared/components/badges/StatusChip';
import SearchPaginatedTable from '../../../shared/components/tables/SearchPaginatedTable';
import { IconButton, Tooltip, Typography } from '@material-ui/core';
// import IconEditPersonal from '@material-ui/icons/ListAlt';
import { useControlModalStates } from '../../../shared/hooks/useControlModalStates';
import AssignCredentialsDialog from './AssignCredentialsDialog';

const UsersTable = () => {
  const { currentProfile } = useAppSelector((state) => state.access);

  const dispatch = useAppDispatch();

  const { path } = useRouteMatch();
  const history = useHistory();

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const [search, setSearch] = useState('');

  useEffect(() => {
    setPage(1);
  }, [perPage, search]);

  const { hasAccess } = useGuard();

  const { dataId, handleCloseModal, handleOpenModalWith, open } = useControlModalStates();

  const { isLoading, error, data, isFetching } = useGetCollaboratorsQuery(
    {
      profileId: currentProfile?.profileId!,
      page,
      perPage,
      search,
    },
    { refetchOnMountOrArgChange: 30 }
  );

  const handleDelete = async (periodId: number) => {
    dispatch(openDeleteModalWith(periodId));
  };

  const datafetch = data?.data!.map((d, index) => ({
    id: index + ((page - 1) * perPage + 1),
    name: d.user?.person?.pers_full_lastname,
    // mail: d.coll_email,
    // gender: d.user.person?.pers_gender,
    phone_home: d.user?.person?.pers_phone_home,
    identification: d.user?.person?.pers_identification,
    address: d.user?.person?.pers_direction,
    // birthday: d.user.person.pers_date_birth,
    statusId: d.status_id,
    options: {
      id: d.id,
      idPerson: d.user?.person?.id,
      email: d.coll_email,
      gender: d.user?.person?.pers_gender,
      user: d.user,
    },
  }));

  const goToAdd = () => {
    history.push(`${path}/agregar`);
  };

  return (
    <>
      <SearchPaginatedTable
        data={datafetch!}
        headers={{
          id: 'N°',
          name: 'Nombre',
          // mail: 'Email',
          // gender: 'Género',
          phone_home: 'Teléfonio convencional',
          identification: 'Cédula',
          address: 'Dirección',
          // birthday: 'Cumpleaños',
          statusId: 'Estado',
          options: 'Opciones',
        }}
        keyExtractor={(item) => item.id.toString()}
        searchPlacehoder="Buscar colaborador"
        isLoading={isLoading}
        isFetching={isFetching}
        error={error}
        perPage={perPage}
        setPerPage={setPerPage}
        total={data?.total || 0}
        page={page}
        setPage={setPage}
        numHeader={7}
        search={search}
        setSearch={setSearch}
        customRenderers={{
          // id: (row) => row.id.id,

          statusId: (row) => <StatusChip statusId={Number(row.statusId)} />,
          name: (row) => (
            <>
              {row.name}
              <Typography variant="subtitle2" color="grey">
                {row.options.email}
              </Typography>
              <Typography variant="subtitle2" color="grey">
                {row.options.gender}
              </Typography>
            </>
          ),

          options: (row) => (
            <>
              {hasAccess('edit') && (
                <Link to={`${path}/${row.options.id}/editar-colaborador`}>
                  <EditButton title="Editar información del colaborador" />
                </Link>
              )}
              {/* {hasAccess('edit') && (
                <Link to={`${path}/${row.options.idPerson}/editar-personal`}>
                  <Tooltip title="Editar información personal">
                    <IconButton aria-label="Editar" color="secondary">
                      <IconEditPersonal sx={{ fontSize: '1.3rem' }} />
                    </IconButton>
                  </Tooltip>
                </Link>
              )} */}
              {hasAccess('edit') && (
                <Tooltip title="Asignar credenciales de meets">
                  <IconButton color="inherit" onClick={() => handleOpenModalWith(row.options.id)}>
                    <KeyIcon sx={{ fontSize: '1.3rem' }} />
                  </IconButton>
                </Tooltip>
              )}
              {hasAccess('delete') && (
                <DeleteButton handleDelete={() => handleDelete(row.options.id)} />
              )}
              {!hasAccess('edit') && !hasAccess('delete') && 'Ninguna'}
            </>
          ),
        }}
        ActionButtons={<>{hasAccess('create') && <AddButton size="large" onClick={goToAdd} />}</>}
      />

      <AssignCredentialsDialog open={open} handleCloseDialog={handleCloseModal} collId={dataId} />
    </>
  );
};

export default UsersTable;
