import { Grid, GridProps, IconButton, Typography, useTheme } from '@material-ui/core';
import { IconArrowNarrowLeft } from '@tabler/icons';
import { useHistory } from 'react-router-dom';

interface Props {
  title: string;
  itemContainerProps?: Partial<GridProps>;
}

const GoBackBtnTitle = ({ title, itemContainerProps }: Props) => {
  const theme = useTheme();
  const history = useHistory();

  return (
    <Grid item xs={12} {...itemContainerProps}>
      <Grid item container alignItems="center" gap={1}>
        <IconButton onClick={() => history.goBack()}>
          <IconArrowNarrowLeft size={30} color={theme.palette.text.dark} />
        </IconButton>
        <Typography variant="h2">{title}</Typography>
      </Grid>
    </Grid>
  );
};

export default GoBackBtnTitle;
