import { useHistory, useParams } from 'react-router-dom';
import { Grid, IconButton, Typography, useTheme } from '@material-ui/core';
import { IconArrowNarrowLeft } from '@tabler/icons';

import MainCardForm from '../../../shared/components/cards/MainCardForm';
import HomologateSubjectInternalTable from '../components/homologate-subjects/HomologateSubjectInternalTable';
import DeleteHomologateSubjectInternalDialog from '../components/homologate-subjects/DeleteHomologateSubjectIntDialog';
import { useAppSelector } from '../../../../hooks/useRedux';
import { useLazyGetCurriculumQuery } from '../slices/meshsApiSlice';
import { useEffect } from 'react';

const HomolagateScreen = () => {
  const theme = useTheme();
  const { subjectInternalHomologateSelected, curriculumList } = useAppSelector(
    (state) => state.meshs
  );

  const { meshId } = useParams<{ meshId: string }>();

  const [getCurriculum] = useLazyGetCurriculumQuery();

  const history = useHistory();

  const curriculum = curriculumList.find((curriculum) => curriculum.id === Number(meshId));

  useEffect(() => {
    if (!curriculum) {
      getCurriculum({
        meshId: Number(meshId),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curriculum]);

  return (
    <MainCardForm>
      <Grid item xs={12}>
        <Grid item container alignItems="center" gap={1} sx={{ cursor: 'pointer' }}>
          <IconButton onClick={() => history.goBack()}>
            <IconArrowNarrowLeft size={30} color={theme.palette.text.dark} />
          </IconButton>
          <Typography variant="h2">Materias Internas Homologadas </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} mt={1}>
        <Typography variant="h3" align="center">
          {curriculum && `${curriculum.education_level?.edu_name} (${curriculum.anio})`}
        </Typography>
      </Grid>
      <Grid item container sx={{ mt: 2 }}>
        <HomologateSubjectInternalTable />
      </Grid>
      {subjectInternalHomologateSelected && <DeleteHomologateSubjectInternalDialog />}
    </MainCardForm>
  );
};

export default HomolagateScreen;
