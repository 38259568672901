import AddButton from '../../../../shared/components/tables/buttons/AddButton';
import DeleteButton from '../../../../shared/components/tables/buttons/DeleteButton';
import EditButton from '../../../../shared/components/tables/buttons/EditButton';
import SearchPaginatedTable from '../../../../shared/components/tables/SearchPaginatedTable';
import { EducationLevelContacts } from '../../interfaces/educationLevels.interfaces';
import { Contact, Network } from './ContactDeanForm';

interface DefaultProps {
  isLoading: boolean;
  isFetching: boolean;
  addHandler: () => void;
  deleteHandler: (index: number, id: number) => void;
  setEditForm: (
    value:
      | EducationLevelContacts['education_levels_contact'][0]
      | EducationLevelContacts['education_levels_network'][0]
  ) => void;
}

type Props<FormType = Contact | Network> = (FormType extends Contact
  ? {
      formType: FormType;
      data: EducationLevelContacts['education_levels_contact'];
    }
  : {
      formType: FormType;
      data: EducationLevelContacts['education_levels_network'];
    }) &
  DefaultProps;

const ContactsTable = ({
  formType,
  data,
  isLoading,
  isFetching,
  addHandler,
  setEditForm,
  deleteHandler,
}: Props) => {
  const datafetch =
    formType === 'contact'
      ? data.map((dt, idx) => {
          return {
            idx,
            name: dt.name,
            description: dt.description,
            email: (dt as any).email,
            phone: (dt as any).phone,
            options: dt,
          };
        })
      : data.map((dt, idx) => {
          return {
            idx,
            name: dt.name,
            description: dt.description,
            options: dt,
          };
        });

  return (
    <SearchPaginatedTable
      data={datafetch}
      headers={{
        idx: '#',
        name: 'Nombre',
        description: 'Descripción',
        ...(formType === 'contact' && { email: 'Correo', phone: 'Teléfono' }),
        options: 'Opciones',
      }}
      error={undefined}
      isFetching={isFetching}
      isLoading={isLoading}
      keyExtractor={(item) => `contact-table-d-${item.idx.toString()}`}
      page={1}
      perPage={10}
      total={data.length}
      search={''}
      searchPlacehoder={'Buscar'}
      setPage={() => {}}
      setPerPage={() => {}}
      setSearch={() => {}}
      paginationBox={false}
      perPageBox={false}
      showFilter={false}
      ActionButtons={
        <AddButton disabled={isFetching || isLoading} variant="outlined" onClick={addHandler} />
      }
      customDataCellsProperties={{
        options: {
          align: 'center',
        },
      }}
      customRenderers={{
        idx: (item) => item.idx + 1,

        options: (item) => (
          <>
            <EditButton handleEdit={() => setEditForm(item.options)} />
            <DeleteButton handleDelete={() => deleteHandler(item.idx, item.options.id)} />,
          </>
        ),
      }}
    />
  );
};

export default ContactsTable;
