import { useAppDispatch } from '../../../../hooks/useRedux';
import DeleteAlert from '../../../shared/components/dialogs/DeleteAlert';
import { useCancelRerservationMutation } from '../../reservations/slices/reservationsApiSlice';
import { closeDeleteReserveBookDialog } from '../slices/userReservedbooksSlice';

interface Props {
  openModal: boolean;
  reservationId: string;
}

const DeleteReserveBookDialog = ({ openModal, reservationId }: Props) => {
  const dispatch = useAppDispatch();

  const [cancelReservation, { isLoading, isSuccess, isError, error }] =
    useCancelRerservationMutation();

  const handleDelete = () => {
    cancelReservation({
      id: reservationId!,
      status: 'Eliminado',
      type: 'Interno',
    });
  };

  const handleClose = () => {
    dispatch(closeDeleteReserveBookDialog());
  };
  //

  return (
    <DeleteAlert
      open={openModal}
      onClose={handleClose}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      handleDelete={handleDelete}
      deleteTitle="¿Está seguro que desea cancelar la reserva?"
      error={error as string}
    />
  );
};

export default DeleteReserveBookDialog;
