
import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import DeleteAlert from '../../../../shared/components/dialogs/DeleteAlert';
import { useDeleteCommentMutation } from '../../slices/virtualClassroomTeacherApiSlice';
import { resetCommentIdToDelete, setDeleteModalOpen } from '../../slices/virtualClassroomTeacherSlice';

const DeleteCommentDialog = () => {
  const dispatch = useAppDispatch();

  const { deleteModalOpen, commentIdToDelete } = useAppSelector((state) => state.virtualClassroomTeacher);

  const [deleteForum, { isLoading, isSuccess, isError, error }] = useDeleteCommentMutation();

  const handleDelete = () => {
    deleteForum({
      commentId: commentIdToDelete!,
    });
  };

  const handleClose = () => {
    dispatch(setDeleteModalOpen(false));
    dispatch(resetCommentIdToDelete());
  };

  return (
    <DeleteAlert
      deleteTitle="Estás seguro de eliminar este comentario"
      deleteSubTitle="Eliminaremos el comentario de forma permanente, ¿Estás seguro de realizar esta acción?"
      open={deleteModalOpen}
      onClose={handleClose}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      handleDelete={handleDelete}
      error={error as string}
    />
  );
};

export default DeleteCommentDialog;
