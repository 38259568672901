import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import MainCard from '../../../shared/components/cards/MainCard';
import ProjectTable from '../components/ProjectTable';

const ProjectScreen = () => {
  const { t } = useTranslation();

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">{t('Proyectos')}</Typography>
      </Grid>
      <ProjectTable />
    </Grid>
  );
};

export default ProjectScreen;
