import { Grid, makeStyles, Paper, Typography } from '@material-ui/core';

import { PropsFormEdit } from './';
import useFormatDate from '../../../../shared/hooks/useFormatDate';

const useStyles = makeStyles((theme) => ({
  cardDisability: {
    background: `linear-gradient(90deg, ${theme.palette.secondary.light} 0%, transparent 7%)`,
    width: '100%',
  },
}));

const EducationCard = ({ data }: PropsFormEdit) => {
  const classes = useStyles();

  const { formatDate } = useFormatDate();

  return (
    <Grid item container spacing={2} xs={12} mt={2}>
      {data?.person_education?.map(
        ({
          city_name,
          edu_name,
          inst_name,
          typ_edu_name,
          end_date,
          start_date,
          pers_edu_calification,
        }) => (
          <Grid item container xs={12} md={6}>
            <Paper elevation={3} className={classes.cardDisability}>
              <Grid item xs={12} p={2} pl={4}>
                <Typography variant="body1" gutterBottom>
                  Ciudad: {city_name}
                </Typography>

                <Typography variant="body1" gutterBottom>
                  Carrera: {edu_name}
                </Typography>

                <Typography variant="body1" gutterBottom>
                  Tipo de instituto: {typ_edu_name}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Instituto: {inst_name}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Calification: {pers_edu_calification}
                </Typography>

                <Typography variant="body1" gutterBottom>
                  Fecha de estudio: {formatDate(start_date)} hasta {formatDate(end_date)}
                </Typography>
              </Grid>
            </Paper>
          </Grid>
        )
      )}
    </Grid>
  );
};

export default EducationCard;
