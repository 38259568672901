import { FC, useState } from 'react';

import {
  Autocomplete,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  TextField,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

import InputLabel from '../../../../shared/components/extended/Form/InputLabel';

import { BusRoute } from '../../interfaces/buses.interfaces';
import { useUploadFilesMutation } from '../../../../shared/slices/uploadFilesApi';
import DragArea from '../../../../shared/components/files/DragArea';
import TextFieldFetching from '../../../../shared/components/TextFieldFetching';
import { useGetAvailablesCampusQuery } from '../../../campus/slices/campusApiSlice';
import {
  useCreateBusRouteMutation,
  useUpdateBusRouteMutation,
} from '../../slices/busRouteApiSlice';
import { CreateRouteFormSchema, UpdateRouteFormSchema } from '../../constants/validationSchemas';
import { statusAvailables } from '../../../../shared/constants/resourceStatus';
import ErrorAlert from '../../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../../shared/components/alerts/SuccessAlert';

interface Props {
  editRoute?: BusRoute;

  onCancel: (e: React.SyntheticEvent) => void;
}

const NewRouteForm: FC<Props> = ({ editRoute, onCancel }) => {
  const { t } = useTranslation();

  const [file, setFile] = useState<File | null>(null);

  const [showAlerts, setShowAlerts] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState<any>(null);

  const { data: dataCampus, isFetching: isFetchingCampus } = useGetAvailablesCampusQuery({});

  const [createRoute, { isLoading: isCreatingRoute }] = useCreateBusRouteMutation();
  const [updateRoute, { isLoading: isUpdatingRoute }] = useUpdateBusRouteMutation();

  const [uploadFile, { isLoading: isUploading }] = useUploadFilesMutation();

  const { getFieldProps, errors, touched, handleSubmit, setFieldValue, values } = useFormik({
    initialValues: {
      id: editRoute?.id || null,
      name: editRoute?.bus_name || '',
      description: editRoute?.bus_description || '',
      document: editRoute?.bus_document || '',
      campusId: editRoute?.campus_id || null,
      status_id: editRoute?.status_id || null,
    },
    validationSchema: editRoute ? UpdateRouteFormSchema : CreateRouteFormSchema,
    onSubmit: async ({ campusId, description, document, id, name, status_id }) => {
      try {
        let urlPhoto = document;

        if (file) {
          urlPhoto = await uploadFile({
            files: file,
          }).unwrap();
        }

        if (!editRoute) {
          createRoute({
            dataPayload: {
              bus_description: description,
              bus_document: urlPhoto,
              bus_name: name,
              campus_id: campusId!,
            },
          });
        } else {
          updateRoute({
            routeId: id!,
            dataPayload: {
              bus_description: description,
              bus_document: urlPhoto,
              bus_name: name,
              campus_id: campusId!,
              status_id: status_id!,
            },
          });
        }

        setIsError(false);
      } catch (error) {
        setError(error);
        setIsError(true);
      }

      setShowAlerts(true);
      //   window.scrollTo(0, 0);
    },
  });

  console.log(errors);

  return (
    <form onSubmit={handleSubmit}>
      {/* <Button onClick={() => setValues({ ...initialValues });}>asd</Button> */}

      <Grid container spacing={1} alignItems="flex-start">
        <Grid item xs={12}>
          {isError && showAlerts && (
            <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
          )}

          {!isError && showAlerts && (
            <SuccessAlert
              message={
                !!editRoute
                  ? t('academic.buses.route.dialog.form.success_update')
                  : t('academic.buses.route.dialog.form.success_create')
              }
              handleDismiss={() => setShowAlerts(false)}
            />
          )}
        </Grid>

        <Grid item xs={12}>
          <InputLabel>{t('academic.buses.campus')}</InputLabel>
          {isFetchingCampus ? (
            <TextFieldFetching />
          ) : (
            <Autocomplete
              options={dataCampus || []}
              getOptionLabel={(option) => option.cam_name}
              onChange={(_, value) => setFieldValue('campusId', value?.id.toString() || null)}
              fullWidth
              value={dataCampus?.find((dt) => dt.id === Number(values.campusId)) || null}
              renderInput={(params) => (
                <TextField {...params} error={Boolean(errors.campusId && touched.campusId)} />
              )}
            />
          )}
          {errors.campusId && touched.campusId && (
            <FormHelperText error>{t(errors.campusId)}</FormHelperText>
          )}
        </Grid>

        <Grid item xs={12}>
          <InputLabel>{t('academic.buses.route.dialog.form.name.label')}</InputLabel>
          <TextField
            // type="number"
            // disabled={paymethod.keyword === 'forma-pago-saldo-favor'}
            fullWidth
            // InputProps={{
            //   defaultValue: '',
            //   inputComponent: CurrencyInputMasked as any,
            //   startAdornment: defaultConfiguration?.currency_symbol,
            // }}
            // placeholder={`${defaultConfiguration?.currency_symbol}0.00`}
            {...getFieldProps('name')}
            error={Boolean(errors.name && touched.name)}
          />
          {errors.name && touched.name && <FormHelperText error>{t(errors.name)}</FormHelperText>}
        </Grid>

        <Grid item xs={12}>
          <InputLabel>{t('academic.buses.route.dialog.form.description.label')}</InputLabel>
          <TextField
            fullWidth
            multiline
            rows={2}
            error={Boolean(errors.description && touched.description)}
            placeholder={t('academic.buses.route.dialog.form.description.placeholder')}
            {...getFieldProps('description')}
          />
          {/* <FormHelperText>Nombre del periodo</FormHelperText> */}
          {errors.description && touched.description && (
            <FormHelperText error>{t(errors.description)}</FormHelperText>
          )}
        </Grid>

        {!!editRoute && (
          <Grid item xs={12}>
            <InputLabel>{t('academic.areas.add.status')}</InputLabel>
            <Autocomplete
              options={statusAvailables}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField {...params} error={Boolean(errors.status_id && touched.status_id)} />
              )}
              value={
                statusAvailables.find((status) => status.id === Number(values.status_id)) ||
                statusAvailables[0]
              }
              onChange={(e, value) => setFieldValue('status_id', value?.id || '')}
              fullWidth
              clearIcon={null}
            />
            {errors.status_id && touched.status_id && (
              <FormHelperText error>{t(errors.status_id)}</FormHelperText>
            )}
          </Grid>
        )}

        <Grid xs={12}>
          <DragArea
            handleChange={(e) => {
              setFieldValue('document', 'd');
              setFile(e.target.files![0]);
            }}
            label={t('academic.buses.route.dialog.form.drag_file.label')}
            // description="La foto del director debe ser una imagen de no más de 2MB"
            typesAccepted=".pdf"
          />
        </Grid>
        <Grid item xs={12}>
          {errors.document && touched.document && (
            <FormHelperText error>{t(errors.document)}</FormHelperText>
          )}
        </Grid>

        <Grid item xs={12}>
          <Grid container justifyContent={'flex-end'} spacing={1}>
            <Grid item>
              <Button variant="outlined" onClick={onCancel}>
                {t('shared.cancel_button.label')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                disabled={isCreatingRoute || isUpdatingRoute || isUploading}
                endIcon={
                  (isCreatingRoute || isUpdatingRoute || isUploading) && (
                    <CircularProgress size={20} />
                  )
                }
              >
                {editRoute ? t('shared.save_button.label') : t('shared.add_button.label')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default NewRouteForm;
