import { Typography, Skeleton } from '@material-ui/core';

import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  SkeletonHeader: {
    height: '100px',
  },
  SkeletonCell: {
    height: '50px',
  },
});

function SkeletonLista() {
  const classes = useStyles();

  return (
    <>
      <Typography variant="h2">
        <Skeleton className={classes.SkeletonHeader} />
      </Typography>
      {[...Array(5)].map((_, i) => (
        <Skeleton key={i} className={classes.SkeletonCell} />
      ))}
    </>
  );
}

export default SkeletonLista;
