import { useHistory, Link, useRouteMatch } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { useGetMattersQuery, useLazyGetAvaliblesMattersQuery } from '../slices/mattersApiSlice';

import StatusChip from '../../../shared/components/badges/StatusChip';
import EditButton from '../../../shared/components/tables/buttons/EditButton';
import DeleteButton from '../../../shared/components/tables/buttons/DeleteButton';
import AddButton from '../../../shared/components/tables/buttons/AddButton';

import {
  openDeleteModalWith,
  setEducationLevelIdSelected,
  setMatterTypeIdSelected,
  updateMattersTable,
} from '../slices/mattersSlice';

import useSharedGuard from '../../../../hooks/useSharedGuard';
import { subPermissionKeys } from '../constants/permissions';
import SearchPaginatedTable, {
  FilterArrow,
} from '../../../shared/components/tables/SearchPaginatedTable';
import { useTableSearch } from '../../../../hooks/useTableSearch';
import { Autocomplete, Grid, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useGetAvailablesMatterTypeQuery } from '../../matters-types/slices/mattersTypesApiSlice';
import TextFieldFetching from '../../../shared/components/TextFieldFetching';
import { useGetPrincipalEducationLevelsQuery } from '../../education-levels/slices/educationLevelsApiSlice';
import { statusAvailables } from '../../../shared/constants/resourceStatus';

const MattersTable = () => {
  const { t } = useTranslation();
  const { hasAccess } = useSharedGuard(subPermissionKeys);
  const { currentProfile } = useAppSelector((state) => state.access);

  const dispatch = useAppDispatch();

  const { path } = useRouteMatch();
  const history = useHistory();

  const { matterTypeIdSelected, educationLevelIdSelected } = useAppSelector(
    (state) => state.matters
  );
  const { page, perPage, search } = useAppSelector((state) => state.matters.mattersTable);

  const [filterArrow, setFilterArrow] = useState<FilterArrow>({
    name: 'mat_name',
    direction: 'desc',
    // name: 'id',
    // direction: 'desc',
  });

  const { setPage, setPerPage, setSearch } = useTableSearch({
    page,
    perPage,
    search,
    updateFunction: updateMattersTable,
    otherFilters: {
      matterTypeIdSelected,
      educationLevelIdSelected,
    },
  });

  const { isLoading, error, data, isFetching } = useGetMattersQuery(
    {
      profileId: currentProfile?.profileId!,
      page,
      perPage,
      search,

      typeSort: filterArrow?.direction || 'asc',
      sort: filterArrow?.name || 'mat_name',
      ...(matterTypeIdSelected && { matterTypeId: matterTypeIdSelected }),
      ...(educationLevelIdSelected && { educationLevelId: educationLevelIdSelected }),
    },
    { refetchOnMountOrArgChange: 60 }
  );

  const handleDelete = async (matterId: number) => {
    dispatch(openDeleteModalWith(matterId));
  };

  const goToAdd = () => {
    history.push(`${path}/agregar`);
  };

  const { data: matterTypes, isLoading: isLoadingMatterTypes } = useGetAvailablesMatterTypeQuery({
    profileId: currentProfile?.profileId!,
  });

  const { data: educationLevels, isLoading: isLoadingEducationLevels } =
    useGetPrincipalEducationLevelsQuery({
      profileId: currentProfile?.profileId!,
    });

  const [getAllSubjects] = useLazyGetAvaliblesMattersQuery();

  const datafetch = data?.data!.map((d, idx) => ({
    id: idx + ((page - 1) * perPage + 1),
    name: d?.mat_name,
    // translate: d?.mat_translate,
    // description: d?.mat_description,
    // acronym: d?.mat_acronym,
    codMigration: d?.cod_matter_migration,
    // codOldMigration: d?.cod_old_migration,
    typeMatterName: d?.type_matter?.tm_name,
    educationLevelName: d?.education_level?.edu_name,
    areaName: d?.area?.ar_name,
    matterPaymentType: d?.mat_payment_type,
    statusId: d?.status_id,
    options: {
      matterId: d?.id,
    },
  }));

  const getAllDatafetch = async () => {
    try {
      const classroomsData = await getAllSubjects(
        {
          search,

          typeSort: filterArrow?.direction || 'asc',
          sort: filterArrow?.name || 'mat_name',
          ...(matterTypeIdSelected && { matterTypeId: matterTypeIdSelected }),
          ...(educationLevelIdSelected && {
            educationLevelId: educationLevelIdSelected.toString(),
          }),
        },
        true
      ).unwrap();

      return classroomsData.map((d, index) => ({
        id: index + ((page - 1) * perPage + 1),
        name: d?.mat_name,
        codMigration: d?.cod_matter_migration,
        typeMatterName: d?.type_matter?.tm_name,
        educationLevelName: d?.education_level?.edu_name,
        areaName: d?.area?.ar_name,
        matterPaymentType: d?.mat_payment_type,

        statusId:
          statusAvailables.find((status) => status.id === Number(d.status_id))?.name || d.status_id,
      }));
    } catch (error) {
      return [];
    }
  };

  return (
    <SearchPaginatedTable
      data={datafetch!}
      headers={{
        id: 'N°',
        name: t('academic.subjects.name'),
        // translate: t('academic.subjects.translation'),
        // description: t('academic.subjects.description'),
        // acronym: t('academic.subjects.acronym'),
        codMigration: t('academic.subjects.current_code'),
        // codOldMigration: t('academic.subjects.prev_code'),
        typeMatterName: t('academic.subjects.subject_type'),
        educationLevelName: t('academic.subjects.academic_unit'),
        areaName: t('academic.subjects.area'),
        matterPaymentType: t('academic.subjects.payment_type'),
        statusId: t('academic.subjects.status'),
        options: t('academic.subjects.options'),
      }}
      keyExtractor={(item) => item.id.toString()}
      listFilterArrows={{
        name: 'mat_name',
        codMigration: 'cod_matter_migration',
        // typeMatterName: 'type_matter.tm_name',
        // educationLevelName: 'education_level.edu_name',
        // areaName: 'area.ar_name',
        matterPaymentType: 'mat_payment_type',
        statusId: 'status_id',
      }}
      exportProps={{
        fileName: t('academic.subjects.export_file_name'),
        files: { excel: true },
        allDataFetchHandler: getAllDatafetch,
      }}
      filterArrow={filterArrow}
      setFilterArrow={setFilterArrow}
      searchPlacehoder={t('academic.subjects.search')}
      isLoading={isLoading}
      isFetching={isFetching}
      error={error}
      perPage={perPage}
      setPerPage={setPerPage}
      total={data?.total || 0}
      page={page}
      setPage={setPage}
      numHeader={9}
      search={search}
      setSearch={setSearch}
      customRenderers={{
        matterPaymentType: (row) =>
          row.matterPaymentType
            ? `${row.matterPaymentType.charAt(0).toUpperCase() + row.matterPaymentType.slice(1)}`
            : null,
        statusId: (row) => <StatusChip statusId={Number(row.statusId)} />,
        options: (row) => (
          <>
            {hasAccess('edit') && (
              <Link to={`${path}/${row.options.matterId}/editar`}>
                <EditButton />
              </Link>
            )}
            {hasAccess('delete') && (
              <DeleteButton handleDelete={() => handleDelete(row.options.matterId)} />
            )}
            {!hasAccess('edit') && !hasAccess('delete') && t('academic.subjects.none')}
          </>
        ),
      }}
      ActionButtons={<>{hasAccess('create') && <AddButton size="large" onClick={goToAdd} />}</>}
      additionalFilters={
        <Grid container spacing={2}>
          {hasAccess('getMatterTypes') && (
            <Grid item xs={12} md={3}>
              {isLoadingMatterTypes && <TextFieldFetching />}
              {!isLoadingMatterTypes && (
                <Autocomplete
                  options={[...(matterTypes || [])]?.sort((a, b) =>
                    a.tm_name.toLowerCase() > b.tm_name.toLowerCase() ? 1 : -1
                  )}
                  getOptionLabel={(option) => option.tm_name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Tipo de materia"
                      placeholder="Seleccione el tipo de materia"
                      variant="outlined"
                    />
                  )}
                  value={matterTypes?.find((mt) => mt.id === matterTypeIdSelected) || null}
                  onChange={(_, value) => {
                    dispatch(setMatterTypeIdSelected(value?.id || null));
                  }}
                />
              )}
            </Grid>
          )}
          {hasAccess('getEducationLevels') && (
            <Grid item xs={12} md={3}>
              {isLoadingEducationLevels && <TextFieldFetching />}
              {!isLoadingEducationLevels && (
                <Autocomplete
                  options={[...(educationLevels || [])]?.sort((a, b) =>
                    a.edu_name.toLowerCase() > b.edu_name.toLowerCase() ? 1 : -1
                  )}
                  getOptionLabel={(option) => option.edu_name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Unidad académica"
                      placeholder="Seleccione la unidad académica"
                      variant="outlined"
                    />
                  )}
                  value={educationLevels?.find((mt) => mt.id === educationLevelIdSelected) || null}
                  onChange={(_, value) => {
                    dispatch(setEducationLevelIdSelected(value?.id || null));
                  }}
                />
              )}
            </Grid>
          )}
        </Grid>
      }
    />
  );
};

export default MattersTable;
