import { useState, useMemo, useEffect } from 'react';

import {
  Autocomplete,
  Button,
  Divider,
  FormHelperText,
  Grid,
  IconButton,
  Skeleton,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
  Link,
} from '@material-ui/core';
import { IconPlus, IconCirclePlus } from '@tabler/icons';
import { useParams } from 'react-router-dom';
import { isAfter, isBefore, parseISO } from 'date-fns';

import { useAppSelector } from '../../../../../hooks/useRedux';
import { UpdateApplicationValidationSchema } from '../../constants/validationSchemas';
import { useFormik } from 'formik';
import InputLabel from '../../../../shared/components/forms/InputLabel';
import TextFieldFetching from '../../../../shared/components/TextFieldFetching';
// import { useGetAvailablesOffersQuery } from '../../../education-levels/slices/educationLevelsApiSlice';
import { EducationLevel } from '../../../education-levels/interfaces/educationLevels.interfaces';
import HomologationSideDialog from '../HomologationSideDialog';
import {
  useGetInstituteQuery,
  useLazyGetInstitutesQuery,
} from '../../../institutes/slices/institutesApiSlice';
import { Institute } from '../../../institutes/interfaces/institutes.interfaces';
import { useGetAgreementsAvailablesQuery } from '../../../agreements/slices/agreementsApiSlices';
import { AgreementAvailable } from '../../../agreements/interfaces/agreements.interfaces';
// import { useGetAvailablesCareersByOfferQuery } from '../../../academic-offerings/slices/academicOfferingsApiSlice';
import HomologationList from './HomologationList';
import { useGetExternalHomologationSubjectsQuery } from '../../../meshs/slices/meshsApiSlice';
import HomologationPerSubject from './HomologationPerSubject';
import ErrorAlert from '../../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../../shared/components/alerts/SuccessAlert';
import { Mesh } from '../../interfaces/homologations.interface';
import { useGetExtAppByIdQuery } from '../../slices/homologationsApiSlice';
import { useGetEducationLevelQuery } from '../../../education-levels/slices/educationLevelsApiSlice';
import HomologationListEdit from './HomologationListEdit';
import {
  useGetAvailablesCareersByOfferQuery,
  useGetAvailablesOffersQuery,
} from '../../../academic-offerings/slices/academicOfferingsApiSlice';
import { AcademicOffering } from '../../../academic-offerings/interfaces/academicOfferings.interfaces';
import CustomAsyncPaginatedSelect from '../../../../shared/components/extended/Form/CustomAsyncPaginatedSelect';
import { baseUrl } from '../../../../../api/mainApi';
import { useTranslation } from 'react-i18next';

const spacing = 2;

//Quitar proceso de aprobar solicitudes
const EditExternalHomologationForm = () => {
  const { t } = useTranslation();

  // const dispatch = useDispatch();
  const theme = useTheme();
  const { currentProfile } = useAppSelector((state) => state.access);

  const isSmall = useMediaQuery(theme.breakpoints.down('lg'));
  const isSm = useMediaQuery(theme.breakpoints.only('sm'));

  const [showAlerts, setShowAlerts] = useState(true);

  const [editCareer, setEditCareer] = useState(false);

  const [hasAgreedment, setHasAgreedment] = useState(false);

  const [changePreconfig, setChangePreconfig] = useState(false);

  const handlePreconfigChange = () => {
    setChangePreconfig(false);
  };

  const [updateAppStatus, setUpdateAppStatus] = useState<{
    status: 'error' | 'success';
    message: string;
  }>();

  const handleShowAlert = (state: boolean) => {
    setShowAlerts(state);
  };

  const handleUpdateAppStatus = (state: { status: 'error' | 'success'; message: string }) => {
    setUpdateAppStatus(state);
  };

  // show a right sidebar
  const [sideDialogMode, setSideDialogMode] = useState<
    'person' | 'institute' | 'subjectInstitute' | 'editSubjectPreconfig'
  >('person');
  const [openSideDialog, setOpenSideDialog] = useState(false);

  const handleClickOpenDialogMode = (
    mode: 'person' | 'institute' | 'subjectInstitute' | 'editSubjectPreconfig'
  ) => {
    setOpenSideDialog(true);
    setSideDialogMode(mode);
  };
  const handleCloseDialog = () => {
    setOpenSideDialog(false);
  };

  const { hId } = useParams<{ hId: string }>();

  const [instituteValue, setInstituteValue] = useState<any | null>(null);

  const [
    getInstitutesTrigger,
    // { data: institutesAvailables, isLoading: isLoadingInstitutesAvailables },
  ] = useLazyGetInstitutesQuery();

  const getInstitutes = async (search: string, page: number, perPage: number) => {
    const response = await getInstitutesTrigger({
      page,
      perPage,
      search,
      profileId: currentProfile?.profileId!,
    }).unwrap();

    const hasMore = response.current_page < response.last_page;

    return {
      options: response.data.map((dt) => {
        return {
          value: dt.id,
          label: dt.inst_name,
          ...dt,
        };
      }),
      hasMore,
    };
  };

  // const {
  //   data,
  //   isLoading,
  //   isFetching,
  //   error: getDataError,
  // } = useGetApplicationHomologationQuery(
  //   {
  //     profileId: currentProfile?.profileId!,
  //     ApplicationHomId: Number(hId),
  //   },
  //   { refetchOnMountOrArgChange: 30 }
  // );

  // const primaryData = currentApplication!.applicationDetails[0].value as StudentRecord;

  // const [updateApplication, { isLoading: isUpdating, isSuccess, isError, error }] =
  //   useUpdateApplicationMutation();

  // for (const el of currentApplication!.applicationDetails) {
  //   const aux = getApplicationDetailValue(el.value, 'collaborator.coll_advice', 'coll_advice');
  //   // const aux = getApplicationDetailValue(el.value, 'collaborator_id');
  //   if (Boolean(aux)) break;
  // }

  const { handleSubmit, setFieldValue, setValues, values, touched, errors } = useFormik({
    initialValues: {
      comment: '',
      offer: undefined as AcademicOffering | undefined,
      institute: undefined as Institute | undefined,
      agreement: undefined as AgreementAvailable | undefined,
      career: undefined as EducationLevel | undefined,
    },
    validationSchema: UpdateApplicationValidationSchema,
    onSubmit: async (values) => {
      // await updateApplication({
      //   profileId: currentProfile?.profileId!,
      //   applicationPayload: {
      //     app_code: currentApplication!.app_code,
      //     comment: values.comment,
      //     role_id: currentRol!.rolId,
      //   },
      // });
      // setShowAlerts(true);
    },
  });

  //set paginated select value on formik
  useEffect(() => {
    if (instituteValue) {
      setFieldValue('institute', instituteValue);
    }
  }, [instituteValue, setFieldValue]);

  // solicitud por id
  const { data: applicationById, isFetching: isFetchingOA } = useGetExtAppByIdQuery({
    profileId: currentProfile?.profileId!,
    ApplicationHomId: Number(hId),
  });

  //carrera de la malla
  const { data: career, isFetching: isFetchingCAA } = useGetEducationLevelQuery(
    {
      profileId: currentProfile?.profileId!,
      educationLevelId: applicationById ? Number(applicationById!.curriculum.level_edu_id) : 0,
    },
    { skip: !Boolean(applicationById) }
  );

  //update formik initial values
  useEffect(() => {
    if (Boolean(applicationById) && Boolean(career) && !isFetchingCAA) {
      setValues({
        ...values,
        offer: career?.offer as any, // interfaces similares duplicadas
        career: career,
        institute: applicationById?.institute as any, // interfaces similares duplicadas
      });

      setInstituteValue({
        id: applicationById?.institute.id,
        value: applicationById?.institute.id,
        label: applicationById?.institute.inst_name,
        ...applicationById?.institute,
      });

      // setFieldValue('offer', career?.offer);
      // setFieldValue('career', career);
      // setFieldValue('institute', applicationById?.institute);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationById, career, isFetchingCAA, setValues]);
  //

  //unidades academicas
  const { data: offersAvailables = [], isFetching: isFetchingOFFA } = useGetAvailablesOffersQuery({
    profileId: currentProfile?.profileId!,
  });

  //instituciones
  // const {
  //   data: institutesAvailables = [],
  //   isLoading: isLoadingIA,
  //   isFetching: isFetchingIA,
  // } = useGetAvailableInstitutesQuery({
  //   profileId: currentProfile?.profileId!,
  // });

  //convenios
  const {
    data: agreementsAvailables = [],
    isLoading: isLoadingAA,
    isFetching: isFetchingAA,
  } = useGetAgreementsAvailablesQuery({
    profileId: currentProfile?.profileId!,
  });

  const memoAgreements = useMemo(() => {
    if (Boolean(agreementsAvailables.length)) {
      return agreementsAvailables;
    }
  }, [agreementsAvailables]);

  const customAgreements = useMemo(() => {
    let newArr: AgreementAvailable[] = [];

    if (Boolean(values.institute) && Boolean(memoAgreements)) {
      newArr = memoAgreements!.filter((agreement) => {
        const today = new Date();
        return (
          values.institute!.id === agreement.institute_id &&
          isAfter(today, parseISO(agreement.agr_start_date as unknown as string)) &&
          isBefore(today, parseISO(agreement.agr_end_date as unknown as string))
        );
      });
    }

    if (Boolean(newArr.length)) {
      if (values.agreement === undefined) setFieldValue('agreement', newArr[0]);
    } else {
      setFieldValue('agreement', undefined);
    }

    return newArr;
  }, [values.institute, memoAgreements, setFieldValue, values.agreement]);
  //

  //carreras disponibles
  const { data: careersAvailables = [], isFetching: isFetchingCA } =
    useGetAvailablesCareersByOfferQuery(
      {
        profileId: currentProfile?.profileId!,
        academicOfferingId: values.offer ? values.offer.id : -1,
      },
      { skip: !values.offer?.id }
    );

  //materias disponibles del programa a aplicar
  const { data: subjectsAvailables = [], isFetching: isFetchingSBA } =
    useGetExternalHomologationSubjectsQuery(
      {
        profileId: currentProfile?.profileId!,
        educationLevel: values.career ? values.career.id : -1,
      },
      { skip: !values.career?.id }
    );

  //materias del instituto seleccionado
  const { data: instituteSubjectsAvailables, isFetching: isFetchingISBA } = useGetInstituteQuery(
    {
      profileId: currentProfile?.profileId!,
      instituteId: values.institute ? values.institute.id : -1,
    },
    { skip: !values.institute?.id }
  );

  const memoMeshActive = useMemo(() => {
    let activeMesh: Mesh | undefined;

    if (Boolean(values.career?.meshs?.length)) {
      activeMesh = values.career!.meshs!.find((dt) => Number(dt.status_id) === 7);
    }

    return activeMesh;
  }, [values.career]);

  // const handleDelete = function (internalHomologation: { code: string; msg: string }) {
  //   dispatch(openInternalDeleteModalWith(internalHomologation));
  // };

  useEffect(() => {
    if (values.agreement && Boolean(Number(values.institute?.has_agreement)))
      setHasAgreedment(true);
    else setHasAgreedment(false);
  }, [values.agreement, values.institute]);

  return (
    <>
      <Grid container spacing={4} alignItems="flex-start">
        <Grid item xs={12}>
          {updateAppStatus?.status === 'error' && showAlerts && (
            <ErrorAlert
              message={updateAppStatus.message as string}
              handleDismiss={() => setShowAlerts(false)}
            />
          )}

          {updateAppStatus?.status === 'success' && showAlerts && (
            <SuccessAlert
              message={t('academic.homologation.external.add_form.success')}
              handleDismiss={() => setShowAlerts(false)}
            />
          )}
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid container item xs={12} mb={1} alignItems={'center'} justifyContent={'flex-end'}>
              <Grid item xs={'auto'} sx={{ pl: spacing }}>
                <Typography variant={'body2'} component={'span'}>
                  {t('academic.homologation.external.add_form.app')} N°
                </Typography>
              </Grid>
              {isFetchingOA ? (
                <Grid item xs={3}>
                  <Skeleton
                    variant="text"
                    component={'span'}
                    sx={{ width: '100%', height: 30, ml: 1 }}
                  />
                </Grid>
              ) : (
                <Grid item xs={'auto'} sx={{ pl: spacing }}>
                  <Typography variant={'h4'} component={'span'}>
                    {applicationById?.app_code}
                  </Typography>
                </Grid>
              )}
            </Grid>

            <Grid container item xs={12} md={6} alignItems={'center'}>
              <Typography variant={'body2'} component={'span'}>
                {t('academic.homologation.external.add_form.student_code')}
              </Typography>
              {isFetchingOA ? (
                <Grid item xs={6}>
                  <Skeleton
                    variant="text"
                    component={'span'}
                    sx={{ width: '100%', height: 30, ml: 1 }}
                  />
                </Grid>
              ) : (
                <Grid item xs={'auto'} lg={6} sx={{ pl: spacing }}>
                  <Typography variant={'h4'} component={'span'}>
                    {applicationById?.user.student.stud_code}
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Grid container item xs={12} md={6} alignItems={'center'}>
              <Typography variant={'body2'} component={'span'}>
                {t('academic.homologation.external.add_form.dni')}
              </Typography>
              {isFetchingOA ? (
                <Grid item xs={6}>
                  <Skeleton
                    variant="text"
                    component={'span'}
                    sx={{ width: '100%', height: 30, ml: 1 }}
                  />
                </Grid>
              ) : (
                <Grid item xs={'auto'} lg={6} sx={{ pl: spacing }}>
                  <Typography variant={'h4'} component={'span'}>
                    {applicationById?.person.pers_identification}
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Grid container item xs={12} md={6} alignItems={'center'}>
              <Typography variant={'body2'} component={'span'}>
                {t('academic.homologation.external.add_form.student_name')}
              </Typography>
              {isFetchingOA ? (
                <Grid item xs={6}>
                  <Skeleton
                    variant="text"
                    component={'span'}
                    sx={{ width: '100%', height: 30, ml: 1 }}
                  />
                </Grid>
              ) : (
                <Grid item xs={'auto'} lg={6} sx={{ pl: spacing }}>
                  <Typography variant={'h4'} component={'span'}>
                    {`${applicationById?.person.pers_firstname} ${applicationById?.person.pers_secondname} ${applicationById?.person.pers_first_lastname} ${applicationById?.person.pers_second_lastname}`}
                  </Typography>
                </Grid>
              )}
            </Grid>

            {applicationById?.file_url && (
              <Grid container item xs={12} alignItems={'center'} mb={1}>
                <Grid item xs={'auto'}>
                  <Typography variant={'body2'} component={'span'}>
                    {t('academic.homologation.external.add_form.academic_record')}:
                  </Typography>
                </Grid>

                <Grid item xs={'auto'} lg={6} sx={{ pl: spacing }}>
                  <Typography variant={'h5'} component={'span'}>
                    <Link
                      href={`${baseUrl}/show?name=${applicationById?.file_url}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t('academic.homologation.external.add_form.see')}
                    </Link>
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <InputLabel>{t('academic.homologation.external.add_form.edu_level')}</InputLabel>
                {(isFetchingCAA || isFetchingOA || isFetchingOFFA) && <TextFieldFetching />}
                {!isFetchingCAA && !isFetchingOA && !isFetchingOFFA && (
                  <Autocomplete
                    options={offersAvailables}
                    disabled={Boolean(applicationById?.subjects_homologate.length)}
                    loadingText={t('academic.homologation.external.add_form.edu_level.loading')}
                    noOptionsText={t('academic.homologation.external.add_form.edu_level.no_data')}
                    getOptionLabel={(option) => option.off_name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={Boolean(errors.offer && touched.offer)}
                        placeholder={t(
                          'academic.homologation.external.add_form.edu_level.placeholder'
                        )}
                      />
                    )}
                    defaultValue={values.offer}
                    value={offersAvailables.find((dt) => dt.id === values.offer?.id) || null}
                    onChange={(_, value) => {
                      setFieldValue('offer', value || null);
                      setChangePreconfig(true);
                    }}
                    fullWidth
                    clearIcon={null}
                  />
                )}
                {errors.offer && touched.offer && (
                  <FormHelperText error>{errors.offer}</FormHelperText>
                )}
              </Grid>

              <Grid item xs={12} md={6}>
                <InputLabel>{t('academic.homologation.external.add_form.agreement')}</InputLabel>
                {isFetchingAA && <TextFieldFetching />}
                {!isFetchingAA && (
                  <Autocomplete
                    options={
                      // Boolean(customAgreements.length) ? customAgreements : agreementsAvailables
                      Boolean(values.institute) ? customAgreements : agreementsAvailables
                      // customAgreements
                    }
                    loading={isLoadingAA}
                    disabled={Boolean(applicationById?.subjects_homologate.length)}
                    loadingText={t('academic.homologation.external.add_form.agreement.loading')}
                    noOptionsText={t('academic.homologation.external.add_form.agreement.no_data')}
                    getOptionLabel={(option) => option.agr_name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={Boolean(errors.agreement && touched.agreement)}
                        placeholder={t(
                          'academic.homologation.external.add_form.agreement.placeholder'
                        )}
                      />
                    )}
                    value={
                      agreementsAvailables.find((dt) => dt.id === values.agreement?.id) || null
                    }
                    onChange={(_, value) => {
                      setFieldValue('agreement', value || null);
                      // setFieldValue(
                      //   'institute',
                      //   value?.institute) || null
                      // );
                      setInstituteValue({
                        id: applicationById?.institute.id,
                        value: applicationById?.institute.id,
                        label: applicationById?.institute.inst_name,
                      });
                      setChangePreconfig(true);
                    }}
                    fullWidth
                    clearIcon={null}
                  />
                )}
                {errors.agreement && touched.agreement && (
                  <FormHelperText error>{errors.agreement}</FormHelperText>
                )}
              </Grid>

              <Grid item xs={12} md={6}>
                <InputLabel>{t('academic.homologation.external.add_form.program')}</InputLabel>
                {(isFetchingCAA || isFetchingOA || isFetchingCA) && <TextFieldFetching />}
                {!isFetchingCAA &&
                  !isFetchingOA &&
                  !isFetchingCA &&
                  editCareer && ( //notuse
                    <TextField
                      fullWidth
                      sx={{ cursor: 'pointer' }}
                      placeholder={t('academic.homologation.external.add_form.program.placeholder')}
                      value={values.career?.edu_name}
                      onClick={() => setEditCareer(true)}
                    />
                  )}
                {!isFetchingCAA && !isFetchingOA && !isFetchingCA && !editCareer && (
                  <Autocomplete
                    options={careersAvailables}
                    disabled={Boolean(applicationById?.subjects_homologate.length)}
                    noOptionsText={
                      values.offer
                        ? t('academic.homologation.external.add_form.program.no_data')
                        : t('academic.homologation.external.add_form.program.placeholder')
                    }
                    loadingText={t('academic.homologation.external.add_form.program.loading')}
                    getOptionLabel={(option) => option.edu_name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={Boolean(errors.career && touched.career)}
                        placeholder={t(
                          'academic.homologation.external.add_form.program.placeholder'
                        )}
                      />
                    )}
                    defaultValue={values.career}
                    value={careersAvailables.find((dt) => dt.id === values.career?.id) || null}
                    onChange={(_, value) => {
                      setFieldValue('career', value || null);
                      setChangePreconfig(true);
                    }}
                    fullWidth
                    clearIcon={null}
                  />
                )}
                {errors.career && touched.career && (
                  <FormHelperText error>{errors.career}</FormHelperText>
                )}
              </Grid>

              <Grid item xs={12} md={6} sx={{ position: 'relative' }}>
                <InputLabel>{t('academic.homologation.external.add_form.institute')}</InputLabel>
                {(!isSmall || isSm) && !Boolean(applicationById?.subjects_homologate.length) && (
                  <Button
                    size="small"
                    sx={{
                      position: 'absolute',
                      right: '0',
                      top: '1rem',
                    }}
                    startIcon={<IconPlus size={10} />}
                    onClick={() => handleClickOpenDialogMode('institute')}
                  >
                    {t('academic.homologation.external.add_form.institute.add')}
                  </Button>
                )}

                {isSmall && !isSm && !Boolean(applicationById?.subjects_homologate.length) && (
                  <Tooltip
                    aria-label={t('academic.homologation.external.add_form.institute.new')}
                    title={t('academic.homologation.external.add_form.institute.new')}
                  >
                    <IconButton
                      aria-label={t('academic.homologation.external.add_form.institute.new')}
                      color="primary"
                      sx={{
                        padding: '0',
                        position: 'absolute',
                        right: '10px',
                        top: '1rem',
                      }}
                      onClick={() => handleClickOpenDialogMode('institute')}
                    >
                      <IconCirclePlus />
                    </IconButton>
                  </Tooltip>
                )}

                {/* {(isFetchingCAA || isFetchingOA || isFetchingIA) && <TextFieldFetching />} */}
                {(isFetchingCAA || isFetchingOA) && <TextFieldFetching />}
                {/* {!isFetchingIA && !isFetchingCAA && !isFetchingOA && ( */}
                {!isFetchingCAA && !isFetchingOA && (
                  // <Autocomplete
                  //   options={institutesAvailables}
                  //   loading={isLoadingIA}
                  //   loadingText="Cargando institutos..."
                  //   disabled={Boolean(applicationById?.subjects_homologate.length)}
                  //   getOptionLabel={(option) => option.inst_name}
                  //   renderInput={(params) => (
                  //     <TextField
                  //       {...params}
                  //       error={Boolean(errors.institute && touched.institute)}
                  //       placeholder="Seleccione un Instituto"
                  //     />
                  //   )}
                  //   value={
                  //     institutesAvailables.find((dt) => dt.id === values.institute?.id) || null
                  //   }
                  //   onChange={(_, value) => {
                  //     setFieldValue('institute', value || null);
                  //     setChangePreconfig(true);
                  //   }}
                  //   fullWidth
                  //   clearIcon={null}
                  // />
                  <CustomAsyncPaginatedSelect
                    key={'institute'}
                    value={instituteValue}
                    isError={'institute' in errors && touched['institute'] ? true : false}
                    placeholder={t('academic.homologation.external.add_form.institute.placeholder')}
                    setValue={setInstituteValue}
                    fetchFunc={getInstitutes}
                  />
                )}
                {errors.institute && touched.institute && (
                  <FormHelperText error>{errors.institute}</FormHelperText>
                )}
                {hasAgreedment && !Boolean(applicationById?.subjects_homologate.length) && (
                  <Typography variant="body2" mt={1}>
                    {t('academic.homologation.external.add_form.max_subjects')}{' '}
                    <strong>{values.agreement?.agr_num_matter_homologate}</strong>
                  </Typography>
                )}

                {!Boolean(Number(values.institute?.can_change)) && (
                  <Typography variant="body2" mt={1} color={theme.palette.error.main}>
                    {t('academic.homologation.external.add_form.institute.not_institute')}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </form>
        </Grid>

        {!Boolean(applicationById?.subjects_homologate.length) &&
          Boolean(Number(values.institute?.can_change)) && (
            <Grid item xs={12}>
              <HomologationPerSubject
                subjectsAvailables={subjectsAvailables}
                instituteSubjectsAvailables={instituteSubjectsAvailables}
                isFetchingSBA={isFetchingSBA}
                isFetchingISBA={isFetchingISBA}
                openDialog={handleClickOpenDialogMode}
              />
            </Grid>
          )}

        <Grid container item xs={12}>
          {memoMeshActive &&
            values.institute &&
            !Boolean(applicationById?.subjects_homologate.length) &&
            Boolean(Number(values.institute?.can_change)) && (
              <>
                <Grid item xs={12}>
                  <HomologationList
                    curriculumId={memoMeshActive.id}
                    instituteId={values.institute!.id}
                    agreementId={values.agreement?.id}
                    hId={Number(hId)}
                    hasAgreedment={hasAgreedment}
                    change={changePreconfig}
                    maxSubjects={Number(values.agreement?.agr_num_matter_homologate)}
                    onShowAlert={handleShowAlert}
                    setChange={handlePreconfigChange}
                    handleUpdateAppStatus={handleUpdateAppStatus}
                    openDialog={handleClickOpenDialogMode}
                  />
                </Grid>
              </>
            )}
          {memoMeshActive &&
            values.institute &&
            Boolean(applicationById?.subjects_homologate.length) &&
            Boolean(Number(values.institute?.can_change)) && (
              <>
                {/* <Grid item mb={2}>
                <Typography variant="h4" color={theme.palette.primary.main}>
                  Materias homologadas
                </Typography>
              </Grid> */}
                <Grid item xs={12}>
                  <HomologationListEdit
                    curriculumId={memoMeshActive.id}
                    instituteId={values.institute!.id}
                    agreementId={values.agreement?.id}
                    data={applicationById!.subjects_homologate}
                    hId={Number(hId)}
                    onShowAlert={handleShowAlert}
                    handleUpdateAppStatus={handleUpdateAppStatus}
                    openDialog={handleClickOpenDialogMode}
                  />
                </Grid>
              </>
            )}
        </Grid>

        {/* // */}
      </Grid>

      {openSideDialog && (
        <HomologationSideDialog
          open={openSideDialog}
          handleCloseDialog={handleCloseDialog}
          mode={sideDialogMode}
        />
      )}
    </>
  );
};

export default EditExternalHomologationForm;
