import React, { FC, useEffect } from 'react';

import Table from '@material-ui/core/Table';

import { renderSkeletonRows } from '../../../helpers/render';
import ComponentFormTable from './ComponentFormTable';
import ComponentSkeleton from './ComponentSkeleton';
import SimpleFormOneColumn from './SimpleFormOneColumn';
import Transitions from '../../extended/Transitions';

const skeletons = {
    0: <ComponentSkeleton />,
    1: <SimpleFormOneColumn />,
    2: <ComponentFormTable />,
    3: <Table sx={{ width: '100%' }}>{renderSkeletonRows(5, 1)}</Table>,
};

interface IProps {
    /** ```default: 'fade'``` */
    type?: 'fade' | 'slide' | 'zoom';
    /** should be required when type  is 'slide' */
    position?: 'top' | 'bottom' | 'left' | 'right';
    /** should be required when type  is 'zoom' and 'grow' */
    direction?: 'up' | 'down' | 'left' | 'right';
    /** ```default: true``` */
    animate?: boolean;
}

const MixedSkeletons: FC<IProps> = ({ type = "fade", position, direction, animate = true }) => {
    const [skeletonIndex, setSkeletonIndex] = React.useState(0);


    /** when every time the skeleton compoennt will be change */
    useEffect(() => {
        const timer = setInterval(() => {
            setSkeletonIndex((prev) => (prev >= Object.keys(skeletons).length - 1 ? 0 : prev + 1));
        }, 1800);

        return () => clearInterval(timer);
    }, []);

    // animation

    return <Transitions type={type} in={animate} position={position} direction={direction}>{skeletons[skeletonIndex as keyof typeof skeletons]}</Transitions>;
};

export default MixedSkeletons;
