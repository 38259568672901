import { Box, Button, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import MainCard from '../../../../shared/components/cards/MainCard';
import ForumContent from '../components/ForumContent';
import { ArrowBackIos } from '@material-ui/icons';

const ForumDetailScreen = () => {
  const { foroId } = useParams<{ foroId: string }>();
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Grid component={MainCard} style={{ marginTop: 10 }}>
      <Grid item xs={12}>
        <Box
          sx={{
            mb: 3,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Typography variant="h2">{t('studentVirtualClassroom.forum.forum')}</Typography>
          <Button
            onClick={() => history.push('/aula-virtual-estudiante/foros')}
            variant="contained"
            startIcon={<ArrowBackIos />}
          >
            {t('students.virtual_classroom.task.modal.button.back')}
          </Button>
        </Box>
      </Grid>
      {/*<ForumCollapseList />*/}
      <ForumContent goToForumView={() => {}} forumId={Number(foroId!)} />
    </Grid>
  );
};
export default ForumDetailScreen;
