import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { IconEye } from '@tabler/icons';
import { useHistory } from 'react-router-dom';
import { Button, Stack } from '@material-ui/core';

import TableNoPaginated from '../../../shared/components/tables/TableNoPaginated';
import CustomIconButton from '../../../shared/components/tables/buttons/CustomIconButton';
import { useGetExternalLinksQuery } from '../../../students/dashboard/slices/dashboardApiSlice';
import { useControlModalStates } from '../../../shared/hooks/useControlModalStates';
import DialogImg from '../../../shared/components/dialogs/DialogImg';
import useGetUrlDownload from '../../../../hooks/useGetUrlDownload';
import AddButton from '../../../shared/components/tables/buttons/AddButton';
import EditButton from '../../../shared/components/tables/buttons/EditButton';
import DeleteButton from '../../../shared/components/tables/buttons/DeleteButton';
import CustomDialog from '../../../shared/components/dialogs/CustomDialog';
import ExternalLinks from '../../../students/dashboard/components/ExternalLinks';
import { setExternalLinksList } from '../slices/externalLinksSlice';
import DeleteExternalLinkDialog from './DeleteExternalLinkDialog';

const ExternalLinksTable = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const [prevImg, setPrevImg] = useState<{ src: string; url: string } | null>(null);

  const { urlDownload } = useGetUrlDownload();

  const {
    open: openCarrousel,
    handleCloseModal: handleCloseCarrousel,
    handleOpenModalWith: handleOpenCarrousel,
  } = useControlModalStates<{ img: string }>();
  const {
    open: openDelete,
    handleCloseModal: closeDeleteModal,
    handleOpenModalWith: openDeleteModal,
    dataId: deleteExternalId,
  } = useControlModalStates();
  const { open, handleCloseModal, handleOpenModalWith } = useControlModalStates<{ img: string }>();

  const { data, isLoading, isFetching, error } = useGetExternalLinksQuery({});

  useEffect(() => {
    if (data) {
      setExternalLinksList(data);
    }
  }, [data]);

  const datafetch = data?.map((item) => ({
    name: item.ext_lin_title,
    description: item.ext_lin_description,
    options: item,
  }));

  const handlePreviewImage = (img: string, url: string) => {
    setPrevImg({ src: `${urlDownload}${img}`, url });
    handleOpenModalWith();
  };

  const goToCreate = () => {
    history.push('/administrar-enlaces-externos/crear');
  };

  const goToEdit = (id: number) => {
    history.push(`/administrar-enlaces-externos/${id}/editar`);
  };

  const handlePreview = () => {
    window.open(prevImg?.url, '_blank');
  };

  return (
    <>
      <TableNoPaginated
        data={datafetch || []}
        headers={{
          name: t('academic.external_links.table.name'),
          description: t('academic.external_links.table.description'),
          options: t('shared.search_paginated_table.table.options'),
        }}
        error={error}
        isLoading={isLoading}
        isFetching={isFetching}
        keyExtractor={(item) => `external-links-table-${item.options.id}`}
        ActionButtons={
          <Stack direction="row" spacing={2} width="100%" justifyContent="flex-end">
            <Button
              variant="outlined"
              size="large"
              onClick={() => handleOpenCarrousel()}
              startIcon={<IconEye size={15} />}
            >
              {t('academic.external_links.table.preview_btn')}
            </Button>
            <AddButton onClick={goToCreate} />
          </Stack>
        }
        customRenderers={{
          options: (item) => (
            <>
              <EditButton handleEdit={() => goToEdit(item.options.id)} />

              <CustomIconButton
                title={t('academic.external_links.table.preview_icon')}
                Icon={IconEye}
                onClick={() =>
                  handlePreviewImage(item.options.ext_lin_image_url, item.options.ext_lin_page_url)
                }
              />

              <DeleteButton handleDelete={() => openDeleteModal(item.options.id)} />
            </>
          ),
        }}
        customDataCellsProperties={{
          options: {
            align: 'center',
          },
        }}
      />

      <DialogImg
        keepMounted
        open={open}
        onClose={handleCloseModal}
        img={prevImg?.src || ''}
        onClick={handlePreview}
      />

      <CustomDialog
        // keepMounted
        onClose={handleCloseCarrousel}
        actionHandler={handleCloseCarrousel}
        isLoading
        isSuccess
        isError
        // noTittle={true}
        open={openCarrousel}
        onlyChildren
        size="lg"
      >
        <ExternalLinks />
      </CustomDialog>

      <DeleteExternalLinkDialog
        open={openDelete}
        handleCloseModal={closeDeleteModal}
        externalId={deleteExternalId}
      />
    </>
  );
};

export default ExternalLinksTable;
