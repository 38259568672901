import * as React from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Form, Formik, FormikConfig, FormikValues } from 'formik';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useGuard from '../../hooks/useGuard';

import { useTranslation } from 'react-i18next';

export interface FormikStepProps
  extends Pick<FormikConfig<FormikValues>, 'children' | 'validationSchema'> {
  label: string;
}

export const FormikStep = ({ children }: FormikStepProps) => {
  return <>{children}</>;
};

export const ProfessionalPracticesStepper = ({
  children,
  ...props
}: FormikConfig<FormikValues>) => {
  const { t } = useTranslation();

  const { hasAccess } = useGuard();
  const childrenArray = React.Children.toArray(children) as React.ReactElement<FormikStepProps>[];

  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down('md'));

  const [step, setStep] = React.useState(0);
  const [completed, setCompleted] = React.useState(false);

  const currentChild = childrenArray[step];

  const isLastStep = () => step === childrenArray.length - 1;

  return (
    <Formik
      {...props}
      validationSchema={currentChild.props.validationSchema}
      onSubmit={async (values, helpers) => {
        if (isLastStep()) {
          await props.onSubmit(values, helpers);
          setCompleted(true);
        } else {
          setStep((s) => s + 1);
        }
      }}
    >
      {({ isSubmitting }) => (
        <Grid item xs={12} container>
          <Form autoComplete="off" style={{ width: '100%' }}>
            <Stepper
              sx={{ my: 3 }}
              activeStep={step}
              orientation={matches ? 'vertical' : 'horizontal'}
            >
              {childrenArray.map((child, index) => (
                <Step key={child.props.label} completed={step > index || completed}>
                  <StepLabel>{child.props.label}</StepLabel>
                </Step>
              ))}
            </Stepper>

            {currentChild}

            {hasAccess('save_practice') && (
              <Grid container spacing={2} justifyContent="flex-end">
                {step > 0 ? (
                  <Grid item>
                    <Button
                      disabled={isSubmitting}
                      variant="contained"
                      color="primary"
                      onClick={() => setStep((s) => s - 1)}
                    >
                      {t('shared.go_back_button.label')}
                    </Button>
                  </Grid>
                ) : null}
                <Grid item>
                  <Button
                    startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
                    disabled={isSubmitting}
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    {isSubmitting ? `${t('students.applications.sending')}` : isLastStep() ? `${t('shared.finished_button.label')}` : `${t('shared.next_button.label')}`}
                  </Button>
                </Grid>
              </Grid>
            )}
          </Form>
        </Grid>
      )}
    </Formik>
  );
};
