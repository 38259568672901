import { Grid, Typography } from '@material-ui/core';
import MainCard from '../../../shared/components/cards/MainCard';
import Schedule from '../components/ScheduleTable';

const AcademicHourhandScreen = () => {
  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">Horario</Typography>
        <Typography variant="body1">
          Encontrarás todas las <strong>materias</strong> en las que te has registrado ordenados por
          hora.
        </Typography>

        <Schedule />
      </Grid>
    </Grid>
  );
};

export default AcademicHourhandScreen;
