import { FormHelperText, Grid, TextField } from '@material-ui/core';
import { useFormik } from 'formik';

import InputLabel from '../../../../shared/components/forms/InputLabel';
import { AddPersonValidationSchema } from '../../constants/validationSchemas';
import { useTranslation } from 'react-i18next';

const AddPersonForm = () => {
  const { t } = useTranslation();

  const { getFieldProps, errors, touched, handleSubmit } = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema: AddPersonValidationSchema,
    onSubmit: async (values) => {
      //   setShowAlerts(true);
    },
  });

  return (
    <>
      <Grid item xs={12}>
        <form onSubmit={handleSubmit}>
          <Grid item xs={12}>
            <InputLabel>{t('academic.homologation.external.side.add_person.name')}</InputLabel>
            <TextField
              type="number"
              fullWidth
              placeholder={t('academic.homologation.external.side.add_person.name.placeholder')}
              {...getFieldProps('name')}
              error={Boolean(errors.name && touched.name)}
            />
            {errors.name && touched.name && <FormHelperText error>{errors.name}</FormHelperText>}
          </Grid>
        </form>
      </Grid>
    </>
  );
};

export default AddPersonForm;
