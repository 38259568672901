import { useState, useEffect, Dispatch, SetStateAction } from 'react';

// material-ui
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  TextField,
  useTheme,
  InputAdornment,
  IconButton,
  Collapse,
  Alert,
  Tooltip,
  Checkbox,
  Typography,
  Badge,
  FormControlLabel,
  // Tooltip,
} from '@material-ui/core';
import type {
  TableProps,
  TableContainerProps,
  TableRowProps,
  TableBodyProps,
  TableHeadProps,
  TableCellProps,
} from '@material-ui/core';

import { renderErrorOrEmptyRow, renderSkeletonRows } from '../../../shared/helpers/render';

// helpers
import { objectKeys } from '../../helpers';

// types
import {
  CustomCellProperties,
  CustomRenderers,
  TableHeaders,
} from '../../interfaces/material-ui.interfaces';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { SerializedError } from '@reduxjs/toolkit';
import { DebounceInput } from 'react-debounce-input';
import {
  IconX,
  IconArrowNarrowDown,
  IconArrowNarrowUp,
  IconMinus,
  IconPlus,
  IconSearch,
} from '@tabler/icons';
import CustomPaginationAndItems from './CustomPaginationAndItems';
import useFormatDate from '../../hooks/useFormatDate';
import { useTranslation } from 'react-i18next';

interface OnCLickI {
  // funcion trigger
  onClickFunc: (arg0: string | number) => void;
  // isSelection y selected se usaran por si se quiere sombrear la fila seleccionada
  isSelection?: boolean;
  selected?: string | number;
  /** key que identificara cual sera el parametro para la funcion y cual sera la key del match */
  funcKey: string;
}

export interface FilterArrow {
  name: string;
  direction: string;
}

interface ListFilterArrow {}

interface MuiTableProps {
  table?: TableProps;
  tableContainer?: TableContainerProps;

  tableHead?: TableHeadProps;
  tableHeadRows?: TableRowProps;
  tableHeadCells?: TableCellProps;

  tableRows?: TableRowProps;
  tableCells?: TableCellProps;
  tableBody?: TableBodyProps;
}

interface Props<T extends {}> {
  headers: TableHeaders<T>;
  data: T[];
  keyExtractor: (item: T) => string;
  customRenderers?: CustomRenderers<T>;
  searchPlacehoder: string;
  customDataCellsProperties?: CustomCellProperties<T>;
  customHeadersCellsProperties?: CustomCellProperties<T>;
  isLoading: boolean;
  isFetching: boolean;
  error: FetchBaseQueryError | SerializedError | undefined | string;
  // error?: string | undefined;
  total: number;
  perPage: number;
  /** ```undefined``` solo si no se usara paginada la tabla */
  setPerPage:
    | React.Dispatch<React.SetStateAction<number>>
    | ((perPage: number) => void)
    | undefined;
  page: number;
  numHeader: number;
  /** ```undefined``` solo si no se usara paginada la tabla */
  setPage: React.Dispatch<React.SetStateAction<number>> | ((page: number) => void) | undefined;
  search: string;
  /** ```undefined``` solo si no se usara paginada la tabla */
  setSearch: React.Dispatch<React.SetStateAction<string>> | ((search: string) => void) | undefined;
  ActionButtons?: JSX.Element;
  actBtnFullWith?: boolean;
  replaceHeader?: boolean;
  selectedCount?: number;
  newHeader?: JSX.Element;
  showFilter?: boolean;
  listFilterArrows?: ListFilterArrow;
  setFilterArrow?: React.Dispatch<React.SetStateAction<FilterArrow>> | (() => void);
  /** Objeto con funcion y parametro a ejecutar al dar click en una fila
   * ### Interface
   * ```jsx
   * interface OnCLickI {
   * //funcion trigger
   * onClickFunc: (arg0: string | number) => void;
   *  // isSelection y selected se usaran por si se quiere sombrear la fila seleccionada
   * isSelection?: boolean;
   * selected?: string | number;
   * // key que identificara cual sera el parametro para la funcion y cual sera la key del match
   * funcKey: string;
   * }
   * ```
   */
  onClickObj?: OnCLickI;
  /** enviar false si no se quiere visualizar el numero de registros por pagina */
  perPageBox?: Boolean;
  /** enviar false si no se quiere visualizar la paginación */
  paginationBox?: Boolean;
  /** ### Props de los componentes de la tabla en base a material ui
   * ```jsx
   * interface MuiTableProps = {
   * //Todas las interfaces son extensiones del type respectivo de su componente de material ui
   *    table?: TableProps;
   *    tableContainer?: TableContainerProps;
   *
   *    tableHead?: TableHeadProps;
   *    tableHeadRows?: TableRowProps;
   *    tableHeadCells?: TableCellProps; //celdas del row head
   *
   *    tableRows?: TableRowProps;
   *    tableBody?: TableBodyProps;
   *    tableCells?: TableCellProps;
   * }
   * ```
   */

  /**
   * Nueva fila para filtros opcionales
   */
  additionalFilters?: JSX.Element;

  filtersPos?: 'top' | 'bottom';

  tableProps?: MuiTableProps;
  isCollapsible?: boolean;
  collapsibleItems?: JSX.Element[];
  expandedFuncAdditional?: (row: any) => void;
  filterArrow?: FilterArrow;
  CollapsibleItems?: JSX.Element[];
  checkboxSelection?: boolean;
  rowsSelectedActions?: { title: string; action: () => void; disable?: boolean }[];
  onChangeSelections?: (rows: T[]) => void;
  customTableSize?: [number, number, number];
  hideToolbars?: boolean;
  center?: boolean;
}

interface renderRowsProps<T extends {}> {
  row: T;
  isCollapsible: boolean;
  tableProps?: MuiTableProps;
  keyExtractor: any;
  onClickObj: any;
  customRenderers: any;
  customDataCellsProperties?: CustomCellProperties<T> | any;
  CollapsibleItems?: JSX.Element[];
  expandedFuncAdditional: (row: any) => void;
  checkboxSelection?: boolean;
  isRowSelected?: boolean;
  rowsSelected?: any[];
  setRowsSelected?: Dispatch<SetStateAction<any[] | any>>;
  numCols: number;
  center?: boolean;
}

const RenderRow = <T extends {}>({
  row,
  isCollapsible,
  tableProps,
  keyExtractor,
  onClickObj,
  customRenderers,
  customDataCellsProperties,
  CollapsibleItems,
  expandedFuncAdditional = (row: any) => {},
  checkboxSelection,
  isRowSelected,
  rowsSelected,
  setRowsSelected,
  numCols,
  center,
}: renderRowsProps<T>) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  const theme = useTheme();

  const { formatTableDate } = useFormatDate();

  const { t } = useTranslation();

  // Checkeds Rows
  const [rowSelected, setRowSelected] = useState(false);
  const handleChecked = () => {
    setRowSelected(!rowSelected);
    if (!rowSelected) {
      // Add row selected
      const rowsTemp = [...rowsSelected!];
      rowsTemp.push(row);
      setRowsSelected!(rowsTemp!);
    } else {
      // Remove row selected
      const rowsTemp = [...rowsSelected!];
      const rowTemp: any = row;
      const rowIndex = rowsTemp.findIndex((r) => r.id === rowTemp.id); // eslint-disable-line no-use-before-define
      rowsTemp.splice(rowIndex, 1);
      setRowsSelected!(rowsTemp!);
    }
  };

  useEffect(() => {
    setRowSelected(isRowSelected || false);
  }, [isRowSelected]);

  if (isCollapsible && !checkboxSelection) {
    return (
      <>
        <TableRow
          {...(tableProps && { ...tableProps.tableRows })}
          className="table-row"
          key={keyExtractor(row)}
          {...(onClickObj && {
            hover: true,
            onClick: () =>
              onClickObj.onClickFunc(row[onClickObj.funcKey as keyof T] as unknown as string),
            sx: {
              cursor: 'pointer',
              ...(onClickObj.isSelection && {
                backgroundColor:
                  (onClickObj.selected as unknown) ===
                  (row[onClickObj.funcKey as keyof T] as unknown)
                    ? theme.palette.primary.light
                    : '',
              }),
            }, //color provisional
          })}
        >
          <TableCell {...((tableProps && { ...tableProps.tableCells }) as any)}>
            <IconButton
              size="small"
              onClick={() => {
                expandedFuncAdditional(row);
                setExpanded(!expanded);
              }}
              sx={{
                color: theme.palette.primary.main,
              }}
            >
              {expanded ? <IconMinus /> : <IconPlus />}
            </IconButton>
          </TableCell>
          {objectKeys(row).map((cell) => {
            const customRenderer = customRenderers?.[cell];

            if (customRenderer) {
              return (
                <TableCell
                  {...((tableProps && { ...tableProps.tableCells }) as any)}
                  key={`${keyExtractor(row)}-${cell.toString()}`}
                  {...(customDataCellsProperties?.[cell] as any)}
                >
                  {customRenderer(row)}
                </TableCell>
              );
            }

            return (
              <TableCell
                {...((tableProps && { ...tableProps.tableCells }) as any)}
                key={`${keyExtractor(row)}-${cell.toString()}`}
                {...(customDataCellsProperties?.[cell] as any)}
              >
                {/* eslint-disable-next-line react/jsx-no-useless-fragment */}

                {formatTableDate(row[cell])}
              </TableCell>
            );
          })}
        </TableRow>
        <TableRow
        //sx={{ border: 1, padding: 0 }}
        >
          <TableCell colSpan={objectKeys(row).length + 1} sx={{ padding: 0, borderBottom: 0 }}>
            <Collapse in={expanded}>
              <div style={{ padding: 20, backgroundColor: 'whitesmoke' }}>
                {CollapsibleItems &&
                  CollapsibleItems.length > 0 &&
                  CollapsibleItems.find((item) => item.key === keyExtractor(row))}
              </div>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  }
  return (
    <>
      <TableRow
        {...(tableProps && { ...tableProps.tableRows })}
        className="table-row"
        key={keyExtractor(row)}
        {...(onClickObj && {
          hover: true,
          onClick: () =>
            onClickObj.onClickFunc(row[onClickObj.funcKey as keyof T] as unknown as string),
          sx: {
            cursor: 'pointer',
            ...(onClickObj.isSelection && {
              backgroundColor:
                (onClickObj.selected as unknown) === (row[onClickObj.funcKey as keyof T] as unknown)
                  ? '#eee'
                  : '',
            }),
          }, //color provisional
        })}
      >
        {checkboxSelection && (
          <TableCell {...((tableProps && { ...tableProps.tableCells }) as any)}>
            <Checkbox
              checked={rowSelected}
              onChange={handleChecked}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </TableCell>
        )}
        {isCollapsible && (
          <TableCell {...((tableProps && { ...tableProps.tableCells }) as any)}>
            <Tooltip title={`${expanded ? t('Menos información') : t('Más información')}`}>
              <IconButton size="small" color="info" onClick={() => setExpanded(!expanded)}>
                {expanded ? <IconMinus /> : <IconPlus />}
              </IconButton>
            </Tooltip>
          </TableCell>
        )}
        {objectKeys(row).map((cell) => {
          const customRenderer = customRenderers?.[cell];

          if (customRenderer) {
            return (
              <TableCell
                {...((tableProps && { ...tableProps.tableCells }) as any)}
                key={`${keyExtractor(row)}-${cell.toString()}`}
                {...customDataCellsProperties?.[cell]}
              >
                {customRenderer(row)}
              </TableCell>
            );
          }

          return (
            <TableCell
              {...((tableProps && { ...tableProps.tableCells }) as any)}
              key={`${keyExtractor(row)}-${cell.toString()}`}
              {...customDataCellsProperties?.[cell]}
            >
              {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
              <>
                {/* {isPrimitive(row[cell]) ? row[cell] : ''} */}

                {formatTableDate(row[cell])}
              </>
            </TableCell>
          );
        })}
      </TableRow>
      {isCollapsible && (
        <TableRow>
          <TableCell colSpan={numCols} sx={{ padding: 0, borderBottom: 0 }}>
            <Collapse in={expanded}>
              <div style={{ padding: 20, backgroundColor: 'whitesmoke' }}>
                {CollapsibleItems &&
                  CollapsibleItems.length > 0 &&
                  CollapsibleItems.find((item) => item.key === keyExtractor(row))}
              </div>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

const SearchPaginatedTableAndCollapsibleItems = <T extends {}>({
  data,
  headers,
  customRenderers,
  keyExtractor,
  searchPlacehoder,
  customDataCellsProperties,
  customHeadersCellsProperties,
  numHeader,
  isLoading,
  isFetching,
  error,
  perPage,
  setPerPage = () => {},
  total,
  page,
  setPage = () => {},
  search,
  setSearch = () => {},
  ActionButtons,
  actBtnFullWith,
  replaceHeader,
  // selectedCount,
  newHeader,
  showFilter = true,
  listFilterArrows = {},
  setFilterArrow: setFilterArrowDispatch,
  onClickObj,
  perPageBox = true,
  paginationBox = true,
  collapsibleItems: CollapsibleItems,
  expandedFuncAdditional = () => {},
  filterArrow: customFilterArrow,
  tableProps,
  isCollapsible,
  checkboxSelection,
  rowsSelectedActions,
  onChangeSelections,
  customTableSize,
  hideToolbars,
  center,
  additionalFilters,
  filtersPos = 'bottom',
}: Props<T>) => {
  const theme = useTheme();

  const { t } = useTranslation();

  // temp search
  const [searchFirst, setSearchFirst] = useState(search);

  useEffect(() => {
    if (page === 1) {
      //set search only if page is 1 -> page change to one on search input
      setSearch(searchFirst);
    }
  }, [page, searchFirst, setSearch]);

  // Checkbox Actions
  const [checkedAllRows, setCheckedAllRows] = useState(false);
  const [rowsSelected, setRowsSelected] = useState<T[]>([]);

  const handleCheckedAllRows = async () => {
    await setCheckedAllRows(!checkedAllRows);
    if (!checkedAllRows) {
      const rows = data.map((row, index) => row);
      setRowsSelected(rows);
    } else {
      setRowsSelected([]);
    }
  };

  useEffect(() => {
    if (onChangeSelections) {
      onChangeSelections(rowsSelected);
    }
  }, [rowsSelected, onChangeSelections]);

  const caclNumColumns = () => {
    let rows = 0;
    rows = Object.keys(headers).length;
    if (isCollapsible) {
      rows += rows + 1;
    }
    if (checkboxSelection) {
      rows += rows + 1;
    }
    return rows;
  };

  const renderRows = () => {
    try {
      /* eslint-disable */
      return (
        <>
          {data!.map((row, index) => (
            <RenderRow
              customDataCellsProperties={customDataCellsProperties}
              customRenderers={customRenderers}
              isCollapsible={isCollapsible || false}
              keyExtractor={keyExtractor}
              onClickObj={onClickObj}
              row={row}
              tableProps={tableProps}
              key={index}
              CollapsibleItems={CollapsibleItems}
              expandedFuncAdditional={expandedFuncAdditional}
              checkboxSelection={checkboxSelection}
              isRowSelected={checkedAllRows ? true : false}
              rowsSelected={rowsSelected}
              setRowsSelected={setRowsSelected as any}
              numCols={caclNumColumns()}
              center={center}
            />
          ))}
        </>
      );
    } catch (errorRenderRow) {
      return (
        <>
          <td />
          <td />
        </>
      );
    }
  };

  const [filterArrow, setFilterArrow] = useState<FilterArrow>(customFilterArrow || null!);

  const handleFilterArrowDown = (name: string, direction: string, nameHeader: string) => {
    setFilterArrow({ name: nameHeader, direction });
    if (setFilterArrowDispatch) setFilterArrowDispatch({ name, direction });
  };

  const handleFilterArrowUp = (name: string, direction: string, nameHeader: string) => {
    setFilterArrow({ name: nameHeader, direction });
    if (setFilterArrowDispatch) setFilterArrowDispatch({ name, direction });
  };

  /* eslint-disable */

  if (error) {
    return <Alert severity="error">{String(error)}</Alert>;
  }
  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
        {additionalFilters && filtersPos === 'top' && (
          <Grid item xs={12}>
            {additionalFilters}
          </Grid>
        )}
        <Grid item xs={12} sm={7} lg={6} sx={{ width: '100% !important' }}>
          {showFilter && (
            <DebounceInput
              autoFocus={Boolean(search)}
              minLength={2}
              debounceTimeout={300}
              onChange={({ target }) => {
                setPage(1);
                setSearchFirst(target.value);
                // setSearch(target.value);
              }}
              value={search}
              //element={TextField}
              /* eslint-disable */
              element={(props) => (
                <TextField
                  style={{
                    width: '100%',
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconSearch fontSize="small" />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          onClick={() => {
                            setSearchFirst('');
                            setSearch('');
                          }}
                          {...(!search && { sx: { cursor: 'initial', opacity: 0 } })}
                        >
                          <IconX size={20} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  placeholder={searchPlacehoder}
                  variant="outlined"
                  size="small"
                  {...props}
                />
              )}
            />
          )}
        </Grid>
        <Grid
          item
          xs={12}
          sm={!actBtnFullWith && 5}
          lg={6}
          sx={{ textAlign: 'right', width: '100% !important' }}
        >
          {ActionButtons}
        </Grid>
        {additionalFilters && filtersPos === 'bottom' && (
          <Grid item xs={12}>
            {additionalFilters}
          </Grid>
        )}
      </Grid>
      <TableContainer
        {...(tableProps && { ...tableProps.tableContainer })}
        {...(replaceHeader && !tableProps?.tableContainer && { sx: { mt: 2 } })}
      >
        <Table {...(tableProps && { ...tableProps.table })}>
          <TableHead {...(tableProps && { ...tableProps.tableHead })}>
            <TableRow>
              {checkboxSelection && (
                <TableCell colSpan={caclNumColumns()}>
                  <Grid
                    container
                    alignItems="center"
                    sx={{ border: 1, borderColor: 'primary.main', p: 2, borderRadius: '.6rem' }}
                  >
                    <Grid item>
                      <Typography>
                        {t('table.rows.selected')}: &nbsp;&nbsp;&nbsp;
                        <Badge
                          badgeContent={rowsSelected.length ? rowsSelected.length : '0'}
                          color="primary"
                        />
                        &nbsp;&nbsp;&nbsp;
                      </Typography>
                    </Grid>
                    {/* <Grid item>
                      <RowsSelectedActions menuItems={rowsSelectedActions} />
                    </Grid> */}
                  </Grid>
                </TableCell>
              )}
            </TableRow>

            <TableRow {...(tableProps && { ...tableProps.tableHeadRows })}>
              {checkboxSelection && (
                <TableCell {...((tableProps && { ...tableProps.tableHeadCells }) as any)}>
                  <FormControlLabel
                    sx={{
                      '& .MuiTypography-body1': {
                        fontSize: '0.8rem',
                      },
                      mx: 0,
                    }}
                    control={
                      <Checkbox
                        checked={checkedAllRows}
                        onChange={handleCheckedAllRows}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    }
                    label="Todos"
                  />
                </TableCell>
              )}

              {isCollapsible ? (
                <TableCell {...((tableProps && { ...tableProps.tableHeadCells }) as any)} />
              ) : null}
              <>
                {replaceHeader && { newHeader }}
                {!replaceHeader &&
                  Object.keys(headers).map((key) => (
                    <TableCell
                      key={key}
                      {...((tableProps && { ...tableProps.tableHeadCells }) as any)}
                      {...customHeadersCellsProperties?.[key as keyof T]}
                    >
                      <Grid container justifyContent="space-around" alignItems="center">
                        <Grid item>{headers[key as keyof T]}</Grid>
                        {Object.keys(listFilterArrows).some((e) => e === key) && (
                          <Grid item>
                            {/* <Grid item xs={6}> */}
                            <IconArrowNarrowDown
                              style={{
                                color:
                                  filterArrow?.direction === 'asc' &&
                                  filterArrow?.name ===
                                    listFilterArrows[key as keyof ListFilterArrow]
                                    ? theme.palette.primary.main
                                    : 'inherit',
                                cursor: 'pointer',
                              }}
                              stroke={
                                filterArrow?.direction === 'asc' &&
                                filterArrow?.name === listFilterArrows[key as keyof ListFilterArrow]
                                  ? 2
                                  : 1
                              }
                              size={18}
                              onClick={() =>
                                handleFilterArrowUp(
                                  listFilterArrows[key as keyof ListFilterArrow],
                                  'asc',
                                  listFilterArrows[key as keyof ListFilterArrow]
                                )
                              }
                            />
                            {/* </Grid> */}
                            {/* <Grid item xs={6}> */}
                            <IconArrowNarrowUp
                              style={{
                                color:
                                  filterArrow?.direction === 'desc' &&
                                  filterArrow?.name ===
                                    listFilterArrows[key as keyof ListFilterArrow]
                                    ? theme.palette.primary.main
                                    : 'inherit',
                                cursor: 'pointer',
                              }}
                              stroke={
                                filterArrow?.direction === 'desc' &&
                                filterArrow?.name === listFilterArrows[key as keyof ListFilterArrow]
                                  ? 2
                                  : 1
                              }
                              size={18}
                              onClick={() =>
                                handleFilterArrowDown(
                                  listFilterArrows[key as keyof ListFilterArrow],
                                  'desc',
                                  listFilterArrows[key as keyof ListFilterArrow]
                                )
                              }
                            />
                            {/* </Grid> */}
                          </Grid>
                        )}
                      </Grid>
                    </TableCell>
                  ))}
              </>
            </TableRow>
          </TableHead>
          <TableBody {...(tableProps && { ...tableProps.tableBody })}>
            {isFetching ? renderSkeletonRows(perPage, numHeader) : renderRows()}
            {/* {error && <tr><td> <Alert severity='error'> {String(error)}</Alert></td></tr>} */}

            {/* {error && renderErrorOrEmptyRow(numHeader, error as string)} */}
            {data?.length === 0 && !isFetching && renderErrorOrEmptyRow(numHeader)}
          </TableBody>
        </Table>
      </TableContainer>

      <CustomPaginationAndItems
        error={error as any}
        isLoading={isLoading}
        total={total}
        perPage={perPage}
        page={page}
        setPage={setPage}
        setPerPage={setPerPage}
        perPageBox={perPageBox}
        paginationBox={paginationBox}
      />
    </>
  );
};

export default SearchPaginatedTableAndCollapsibleItems;
