import { Redirect, Switch } from 'react-router-dom';
import { useAppSelector } from '../../../../../../hooks/useRedux';
import GuardRoute from '../../../../../../router/guards/GuardRoute';
// eslint-disable-next-line import/no-cycle
import AddOptionsTemplateScreen from '../screen/AddOptionsTemplateScreen';
import AddRepresentativeScale from '../screen/AddRepresentativeScale';
import EditOptionsTemplateScreen from '../screen/EditOptionsTemplateScreen';
import EditRepresentativeScale from '../screen/EditRepresentativeScale';
import OptionsTemplateScreen from '../screen/OptionsTemplateScreen';

export const OptionsTemplateRoutes = () => {
  const { currentModule } = useAppSelector((state) => state.access);

  const hasPermission = 'evaluation' in currentModule?.permissions!;

  if (!hasPermission) return <Redirect to="/dashboard" />;

  return (
    <Switch>
      <GuardRoute
        exact
        path="/escalas"
        component={OptionsTemplateScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.list}
      />
      <GuardRoute
        exact
        path="/escalas/escalas-calificativa/agregar"
        component={AddOptionsTemplateScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.create}
      />
      <GuardRoute
        exact
        path="/escalas/escalas-calificativa/:id/editar"
        component={EditOptionsTemplateScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.edit}
      />
      <GuardRoute
        exact
        path="/escalas/escalas-representativa/agregar"
        component={AddRepresentativeScale}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.create}
      />
      <GuardRoute
        exact
        path="/escalas/escalas-representativa/:id/editar"
        component={EditRepresentativeScale}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.edit}
      />
    </Switch>
  );
};
