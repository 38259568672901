import {
  Autocomplete,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useAppSelector } from '../../../../hooks/useRedux';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import { statusAvailables } from '../../../shared/constants/resourceStatus';
import { useFormStyles } from '../../periods/constants/styles';
import { PensumInfoSchema } from '../constants/validationSchemas';
import { useUpdatePensumMutation } from '../slices/pensumsApiSlice';

const EditPensumForm = () => {
  const history = useHistory();
  const { pensumId } = useParams<{ pensumId: string }>();

  const { pensumsList } = useAppSelector((state) => state.pensums);
  const { currentProfile } = useAppSelector((state) => state.access);

  const classes = useFormStyles();

  const [showAlerts, setShowAlerts] = useState(false);

  const [updatePensum, { isLoading: isUpdating, isSuccess, isError, error }] =
    useUpdatePensumMutation();

  const pensumToEdit = pensumsList.find((p) => p.id === Number(pensumId));

  const { getFieldProps, errors, touched, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      name: pensumToEdit?.pen_name || '',
      description: pensumToEdit?.pen_description || '',
      acronym: pensumToEdit?.pen_acronym || '',
      anio: pensumToEdit?.anio || new Date().getFullYear(),
      statusId: pensumToEdit?.status_id || 1,
    },
    validationSchema: PensumInfoSchema,
    onSubmit: async (values) => {
      await updatePensum({
        profileId: currentProfile?.profileId!,
        pensumId: pensumToEdit?.id!,
        pensumPayload: {
          pen_name: values.name,
          pen_description: values.description,
          pen_acronym: values.acronym,
          anio: values.anio.toString(),
          status_id: values.statusId.toString(),
        },
      });

      setShowAlerts(true);
    },
  });
  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction="column" gap={2}>
        <Grid item>
          {isError && showAlerts && (
            <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
          )}

          {isSuccess && showAlerts && (
            <SuccessAlert
              message="El pensum ha sido actualizado correctamente."
              handleDismiss={() => setShowAlerts(false)}
            />
          )}
        </Grid>

        <Grid item container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>Nombre</InputLabel>
            <TextField
              fullWidth
              error={Boolean(errors.name && touched.name)}
              placeholder="Ingrese el nombre del pensum"
              {...getFieldProps('name')}
            />
            {errors.name && touched.name && <FormHelperText error>{errors.name}</FormHelperText>}
          </Grid>
          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>Descripción</InputLabel>
            <TextField
              multiline
              rows={2}
              fullWidth
              error={Boolean(errors.description && touched.description)}
              placeholder="Ingrese una descripción para el pensum"
              {...getFieldProps('description')}
            />
            {errors.description && touched.description && (
              <FormHelperText error>{errors.description}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} container spacing={2}>
            <Grid item xs={6}>
              <InputLabel className={classes.inputLabel}>Acrónimo</InputLabel>
              <TextField
                type="text"
                fullWidth
                placeholder="Ingrese un acrónimo"
                {...getFieldProps('acronym')}
                error={Boolean(errors.acronym && touched.acronym)}
              />
              {errors.acronym && touched.acronym && (
                <FormHelperText error>{errors.acronym}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={6}>
              <InputLabel className={classes.inputLabel}>Año</InputLabel>
              <TextField
                type="number"
                fullWidth
                placeholder="Ingrese el año"
                {...getFieldProps('anio')}
                error={Boolean(errors.anio && touched.anio)}
              />
              {errors.anio && touched.anio && <FormHelperText error>{errors.anio}</FormHelperText>}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>Estado</InputLabel>
            <Autocomplete
              options={statusAvailables}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField {...params} error={Boolean(errors.statusId && touched.statusId)} />
              )}
              defaultValue={statusAvailables[0]}
              onChange={(e, value) => setFieldValue('statusId', value?.id || '')}
              fullWidth
              clearIcon={null}
            />
            {errors.statusId && touched.statusId && (
              <FormHelperText error>{errors.statusId}</FormHelperText>
            )}
          </Grid>
        </Grid>

        <Grid
          item
          container
          alignItems="center"
          justifyContent="flex-end"
          spacing={2}
          sx={{ mt: 1 }}
        >
          <Grid item>
            <Button
              variant="outlined"
              size="large"
              color="primary"
              onClick={() => history.goBack()}
            >
              Regresar
            </Button>
          </Grid>
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              disabled={isUpdating}
              endIcon={isUpdating && <CircularProgress size={20} />}
            >
              Actualizar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default EditPensumForm;
