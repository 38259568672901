import { useAppDispatch, useAppSelector } from '../../../../../../hooks/useRedux';
import DeleteAlert from '../../../../../shared/components/dialogs/DeleteAlert';
import { useDeleteOptionsTemplateMutation } from '../slices/optionsTemplateApiSlice';
import {
  setOptionsTemplatesDeleteModalOpen,
  setOptionsTemplateIdToDelete,
} from '../slices/optionsTemplateSlice';

const OptionsTemplateDeleteDialog = () => {
  const dispatch = useAppDispatch();
  const { optionsTemplateIdToDelete, optionsTemplatesDeleteModalOpen } = useAppSelector(
    (state) => state.optionsTemplate
  );

  const [deleteOptionsTemplate, { isLoading, isSuccess, error, isError }] =
    useDeleteOptionsTemplateMutation();

  const handleDelete = () => {
    deleteOptionsTemplate({
      id: optionsTemplateIdToDelete!,
    });
  };
  const handleClose = () => {
    dispatch(setOptionsTemplateIdToDelete(null));
    dispatch(setOptionsTemplatesDeleteModalOpen(false));
  };

  return (
    <DeleteAlert
      open={optionsTemplatesDeleteModalOpen}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      error={error as any}
      handleDelete={handleDelete}
      onClose={handleClose}
    />
  );
};

export default OptionsTemplateDeleteDialog;
