import { Switch } from 'react-router-dom';
import GuardRoute from '../../../../router/guards/GuardRoute';

// import { useAppSelector } from '../../../../hooks/useRedux';

// import ChangePasswordScreen from '../screens/ChangePasswordScreen';
import ChangePasswordScreen from '../screens/ChangePasswordScreen';

const ConfigurationsRouter = () => {
  // const { currentRol } = useAppSelector((state) => state.access);

  // const hasPermission = 'periods' in currentRol?.permissions!;

  // if (!hasPermission) return <Redirect to="/dashboard" />;

  return (
    <Switch>
      <GuardRoute
        exact
        path="/configuraciones/cambiar-clave"
        component={ChangePasswordScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.read}
      />
    </Switch>
  );
};

export default ConfigurationsRouter;
