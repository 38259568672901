import {
  Avatar,
  AvatarGroup,
  Button,
  CardActions,
  CardContent,
  Chip,
  Dialog,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
} from '@material-ui/core';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@material-ui/core';
import { IconArrowNarrowLeft } from '@tabler/icons';
import { useAppDispatch } from '../../../../../hooks/useRedux';
import { stylesStudentAsignedSendMessage } from '../constants/styles';
import { useGetUserAnnouncementsPreviewQuery } from '../slices/studentsAsignedApiSlice';
import { setOpenModalUserAnnouncements } from '../slices/studentsAsignedSlice';
import { renderSkeletonTable } from '../../../../shared/helpers/render';
import { ReceiverElement } from '../interfaces/student-announcement.interfaces';
// import { obtainDomainStorageImage } from "../../../../shared/helpers";
import { useAppSelector } from '../../../../../hooks/useRedux';
import MainCard from '../../../../shared/components/cards/MainCard';
import { obtainDomainStorageImage } from '../../../../shared/helpers';
import RenderAvatars from './tooltip/avatars';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  textPrimary: {
    color: theme.palette.primary.main,
  },
  container: {
    position: 'relative',
    '&>*': {
      position: 'relative',
      zIndex: '5',
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      top: '0',
      left: '43px',
      width: '2px',
      height: '100%',
      background: '#ebebeb',
      zIndex: '1',
    },
  },
  avatar: {
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.primary[300],
    fontSize: '1rem',
  },
  tooltip: {
    backgroundColor: theme.palette.grey[300],
    zIndex: 5,
    border: '1px solid',
  },
}));

interface UserAnnouncement {
  id: number;
  receiver: ReceiverElement[];
  ans_message: string;
  and_date_send: string;
}

const UserAnnouncements = function () {
  const { t } = useTranslation();
  const history = useHistory();

  const classes = useStyles();

  const theme = useTheme();

  const styles = stylesStudentAsignedSendMessage();

  const dispatch = useAppDispatch();

  const { currentProfile } = useAppSelector((state) => state.access);

  const { data, isLoading, isFetching } = useGetUserAnnouncementsPreviewQuery({
    user_profile_id: currentProfile?.profileId!,
  });

  const dataFetch =
    data?.map((value) => ({
      id: value.id,
      receiver: value?.receivers,
      ans_message: value.ans_message,
      and_date_send: value.ans_date_send,
    })) || [];

  const handleClose = () => {
    dispatch(setOpenModalUserAnnouncements(false));
  };

  const renderRow = (ans: UserAnnouncement) => {
    return (
      <Grid style={{ cursor: 'pointer' }} item xs={12} key={`${ans.id}-${ans.and_date_send}`}>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <AvatarGroup max={2} classes={{ avatar: classes.avatar }}>
              {ans.receiver.map((value) => (
                <Tooltip
                  classes={{ tooltip: classes.tooltip }}
                  title={RenderAvatars(ans)}
                  key={`${value.announcement_id}-${value.id}-${value.rec_date_read}`}
                >
                  <Avatar
                    src={obtainDomainStorageImage(value?.receiver?.student?.stud_photo_path!)}
                  ></Avatar>
                </Tooltip>
              ))}
            </AvatarGroup>
          </Grid>
          <Grid item xs zeroMinWidth>
            <Grid container>
              <Grid item xs={12}>
                {ans?.receiver.map((value) => (
                  <Typography align="left" variant="body2">
                    {value?.receiver?.person?.pers_firstname}{' '}
                    {value?.receiver?.person?.pers_first_lastname}{' '}
                    {value?.receiver?.person?.pers_second_lastname}
                  </Typography>
                ))}
              </Grid>
              <Grid item xs={12}>
                <Typography align="left" variant="caption">
                  {moment(ans?.and_date_send).fromNow()}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    display: 'block',
                  }}
                  align="left"
                  variant="body2"
                >
                  {ans.ans_message}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs zeroMinWidth style={{ marginTop: 10 }}>
            {moment(ans?.and_date_send).isSame(moment(), 'day') && (
              <Grid container justifyContent="flex-end">
                <Chip style={{ cursor: 'pointer' }} label="Reciente" color="primary" size="small" />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const handleHistory = () => {
    history.push(`/administrar-estudiantes-asignados/historial-anuncios-enviados`);
  };

  const renderRows = () => {
    return (
      <Grid container alignItems="center" spacing={3} className={classes.container}>
        {dataFetch.length ? (
          dataFetch?.slice(0, 7)?.map(renderRow)
        ) : (
          <Grid xs={12} item container justify-content="center">
            No existen anuncios disponibles
          </Grid>
        )}
      </Grid>
    );
  };

  return (
    <Dialog
      open={true}
      keepMounted
      onClose={() => handleClose()}
      className={styles.container}
      classes={{
        paper: styles.paper,
      }}
    >
      <Grid item xs={12} style={{ marginLeft: 10, marginBottom: 10 }}>
        <Grid item container alignItems="center" gap={1} sx={{ cursor: 'pointer' }}>
          <IconButton onClick={() => handleClose()}>
            <IconArrowNarrowLeft size={30} color={theme.palette.text.dark} />
          </IconButton>
          <Typography variant="h3">
            {t('teachers.academic_counseling.students_assigned.preview.back')}
          </Typography>
        </Grid>
      </Grid>
      <MainCard
        title={t('teachers.academic_counseling.students_assigned.preview.sent')}
        boxShadow={true}
      >
        <CardContent>
          {isLoading || isFetching ? renderSkeletonTable(6, 6) : renderRows()}
        </CardContent>
      </MainCard>
      <Divider />
      <CardActions sx={{ justifyContent: 'flex-end' }}>
        <Button
          style={{ textTransform: 'unset' }}
          onClick={handleHistory}
          variant="text"
          size="small"
          color="primary"
        >
          {t('teachers.academic_counseling.students_assigned.preview.view_all')}
        </Button>
      </CardActions>
    </Dialog>
  );
};

export default UserAnnouncements;
