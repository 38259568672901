import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Notification } from '../../../shared/interfaces/notifications.interface';
import { ISearchTable } from '../../../shared/interfaces/paginatedTable.interface';

interface NotificationsState {
  notificationsList: Notification[];
  notificationTable: ISearchTable;
}

const initialState: NotificationsState = {
  notificationsList: [],
  notificationTable: {
    page: 1,
    perPage: 10,
    search: '',
  },
};

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setNotificationsList: (state, action: PayloadAction<Notification[]>) => {
      state.notificationsList = action.payload;
    },
    updateNotificationTable: (state, action: PayloadAction<ISearchTable>) => {
      state.notificationTable = action.payload;
    },
  },
});

export const { setNotificationsList, updateNotificationTable } = notificationsSlice.actions;

export default notificationsSlice.reducer;
