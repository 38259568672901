import {
  Autocomplete,
  Button,
  Chip,
  CircularProgress,
  FormHelperText,
  Grid,
  InputLabel,
  Skeleton,
  TextField,
  Typography,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useAppSelector } from '../../../../hooks/useRedux';
import { useGetAvailiablesPeriodsQuery } from '../../../maintenance/periods/slices/periodsApiSlice';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import { statusAvailables } from '../../../shared/constants/resourceStatus';
import { useFormStyles } from '../constants/styles';
import { useUpdateHourSummaryMutation } from '../slice/hoursTeacherApiSlice';
import { Period } from '../../../maintenance/periods/interfaces/periods.interfaces';
import { editHoursSummariesSchema } from '../constants/validationSchemas';
import { useGetPrincipalEducationLevelsQuery } from '../../../maintenance/education-levels/slices/educationLevelsApiSlice';
import { PrincipalEducationLevel } from '../../../maintenance/education-levels/interfaces/educationLevels.interfaces';

interface DataForm {
  periods?: Period;
  educationLevels?: PrincipalEducationLevel;
  collaborator_id: number;
  hclasses: number;
  htutoring: number;
  hbonding: number;
  hAcademicManagement: number;
  hPreparation: number;
  hResearch: number;
  requisition_id: number;
  statusId: number;
  journeyDescription: string;
  totalHours: number;
}
const EditHoursSummariesForm = () => {
  const history = useHistory();
  const classes = useFormStyles();
  const { currentProfile } = useAppSelector((state) => state.access);
  const { hourSummaryId } = useParams<{ hourSummaryId: string }>();

  const { teachersList } = useAppSelector((state) => state.hoursTeachers);

  const teacherToEdit = teachersList.find((hs) => hs.id === Number(hourSummaryId));

  const [showAlerts, setShowAlerts] = useState(true);

  const [updateHourSummary, { isLoading, isError, error, isSuccess }] =
    useUpdateHourSummaryMutation();

  const { data: periodsAvailables = [], isLoading: isLoadingPeriods } =
    useGetAvailiablesPeriodsQuery({
      profileId: currentProfile?.profileId!,
    });

  const { data: eduactionLevelsAvailables = [], isLoading: isLoadingEL } =
    useGetPrincipalEducationLevelsQuery({
      profileId: currentProfile?.profileId!,
    });

  const validate = (values: DataForm) => {
    let errors: any = {};
    if (values.journeyDescription === 'TC' && values?.totalHours !== 40) {
      errors.totalHours = 'El tiempo completo debe ser de 40H';
    } else if (values.journeyDescription === 'MT' && values?.totalHours !== 20) {
      errors.totalHours = 'El tiempo completo debe ser de 20H';
    }
    return errors;
  };

  const { getFieldProps, errors, touched, handleSubmit, setFieldValue, values } =
    useFormik<DataForm>({
      initialValues: {
        periods: teacherToEdit?.period as Period | undefined,
        educationLevels: teacherToEdit?.education_level as PrincipalEducationLevel | undefined,
        collaborator_id: Number(teacherToEdit?.collaborator_id) || -1,
        hclasses: Number(teacherToEdit?.hs_classes) || 0,
        htutoring: Number(teacherToEdit?.hs_tutoring) || 0,
        hbonding: Number(teacherToEdit?.hs_bonding) || 0,
        hAcademicManagement: Number(teacherToEdit?.hs_academic_management) || 0,
        hPreparation: Number(teacherToEdit?.hs_classes_examns_preparation) || 0,
        hResearch: Number(teacherToEdit?.hs_research) || 0,
        requisition_id: 0,
        statusId: Number(teacherToEdit?.status_id) || 1,
        journeyDescription: teacherToEdit?.collaborator?.coll_journey_description || '',
        totalHours:
          Number(teacherToEdit?.hs_classes || 0) +
          Number(teacherToEdit?.hs_academic_management || 0) +
          Number(teacherToEdit?.hs_classes_examns_preparation || 0) +
          Number(teacherToEdit?.hs_research || 0) +
          Number(teacherToEdit?.hs_bonding || 0) +
          Number(teacherToEdit?.hs_tutoring || 0),
      },
      validationSchema: editHoursSummariesSchema,
      validate: validate,
      onSubmit: async (values) => {
        await updateHourSummary({
          profileId: currentProfile?.profileId!,
          hourSummaryId: hourSummaryId,
          hourSummaryPayload: {
            collaborator_id: values.collaborator_id,
            hs_classes: values.hclasses,
            hs_classes_examns_preparation: values.hPreparation,
            hs_bonding: values.hbonding,
            hs_tutoring: values.htutoring,
            hs_research: values.hResearch,
            hs_academic_management: values.hAcademicManagement,
            period_id: values.periods?.id,
            education_level_id: values.educationLevels?.id,
            requisition_id: 1,
            status_id: values.statusId,
          },
        });
        setShowAlerts(true);
      },
    });

  useEffect(() => {
    setFieldValue(
      'totalHours',
      Number(values.hclasses) +
        Number(values.hAcademicManagement) +
        Number(values.hPreparation) +
        Number(values.hResearch) +
        Number(values.hbonding) +
        Number(values.htutoring)
    );
  }, [values, setFieldValue]);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction="column" gap={2}>
        <Grid item>
          {isError && showAlerts && (
            <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
          )}

          {isSuccess && showAlerts && (
            <SuccessAlert
              message="Las horas han sido actualizadas correctamente."
              handleDismiss={() => setShowAlerts(false)}
            />
          )}
        </Grid>

        <Grid item container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <Typography
              variant={'h2'}
            >{`${teacherToEdit?.collaborator.user.person.pers_first_lastname} ${teacherToEdit?.collaborator.user.person.pers_second_lastname} ${teacherToEdit?.collaborator.user.person.pers_firstname} ${teacherToEdit?.collaborator.user.person.pers_secondname}`}</Typography>
          </Grid>
          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>Unidad académica</InputLabel>
            {isLoadingEL ? (
              <Skeleton variant="rectangular" sx={{ width: '100%', borderRadius: 3, height: 50 }} />
            ) : (
              <Autocomplete
                options={eduactionLevelsAvailables}
                loading={isLoadingEL}
                loadingText="Cargando tipos de indentificación..."
                getOptionLabel={(option) => option.edu_name}
                defaultValue={eduactionLevelsAvailables.find(
                  (el) => el.id === Number(teacherToEdit?.education_level_id)
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Seleccione un tipo de identificación"
                    error={Boolean(errors.educationLevels && touched.educationLevels)}
                  />
                )}
                onChange={(_, value) => setFieldValue('educationLevels', value)}
                fullWidth
                clearIcon={null}
              />
            )}
            {errors.educationLevels && touched.educationLevels && (
              <FormHelperText error>{errors.educationLevels}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>Período</InputLabel>
            {isLoadingPeriods ? (
              <Skeleton variant="rectangular" sx={{ width: '100%', borderRadius: 3, height: 50 }} />
            ) : (
              <Autocomplete
                options={periodsAvailables}
                loading={isLoadingPeriods}
                loadingText="Cargando los períodos..."
                getOptionLabel={(option) => option.per_name}
                defaultValue={periodsAvailables.find(
                  (p) => p.id === Number(teacherToEdit?.period_id)
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Seleccione el período"
                    error={Boolean(errors.periods && touched.periods)}
                  />
                )}
                onChange={(_, value) => setFieldValue('periods', value)}
                disabled
                fullWidth
                clearIcon={null}
              />
            )}
            {errors.periods && touched.periods && (
              <FormHelperText error>{errors.periods}</FormHelperText>
            )}
          </Grid>

          <Grid item md={6} xs={12}>
            <InputLabel className={classes.inputLabel}>Horas clases</InputLabel>
            <TextField
              type="number"
              fullWidth
              error={Boolean(errors.hclasses && touched.hclasses)}
              placeholder="Ingrese horas clases"
              {...getFieldProps('hclasses')}
            />
            {errors.hclasses && touched.hclasses && (
              <FormHelperText error>{errors.hclasses}</FormHelperText>
            )}
          </Grid>

          <Grid item md={6} xs={12}>
            <InputLabel className={classes.inputLabel}>
              Horas preparación clases y examenes
            </InputLabel>
            <TextField
              type="number"
              fullWidth
              error={Boolean(errors.hPreparation && touched.hPreparation)}
              placeholder="Ingrese horas clases"
              {...getFieldProps('hPreparation')}
            />
            {errors.hPreparation && touched.hPreparation && (
              <FormHelperText error>{errors.hPreparation}</FormHelperText>
            )}
          </Grid>

          <Grid item md={6} xs={12}>
            <InputLabel className={classes.inputLabel}>Horas tutorias</InputLabel>
            <TextField
              fullWidth
              type="number"
              error={Boolean(errors.htutoring && touched.htutoring)}
              placeholder="Ingrese tutorias"
              {...getFieldProps('htutoring')}
            />
            {errors.htutoring && touched.htutoring && (
              <FormHelperText error>{errors.htutoring}</FormHelperText>
            )}
          </Grid>

          <Grid item md={6} xs={12}>
            <InputLabel className={classes.inputLabel}>Horas vinculación</InputLabel>
            <TextField
              fullWidth
              type="number"
              error={Boolean(errors.hbonding && touched.hbonding)}
              placeholder="Ingrese la capacidad del aula"
              {...getFieldProps('hbonding')}
            />
            {errors.hbonding && touched.hbonding && (
              <FormHelperText error>{errors.hbonding}</FormHelperText>
            )}
          </Grid>

          <Grid item md={6} xs={12}>
            <InputLabel className={classes.inputLabel}>Horas gestión académica</InputLabel>
            <TextField
              fullWidth
              type="number"
              error={Boolean(errors.hAcademicManagement && touched.hAcademicManagement)}
              placeholder="Ingrese horas académicas"
              {...getFieldProps('hAcademicManagement')}
            />
            {errors.hAcademicManagement && touched.hAcademicManagement && (
              <FormHelperText error>{errors.hAcademicManagement}</FormHelperText>
            )}
          </Grid>

          <Grid item md={6} xs={12}>
            <InputLabel className={classes.inputLabel}>Horas investigación</InputLabel>
            <TextField
              fullWidth
              type="number"
              error={Boolean(errors.hResearch && touched.hResearch)}
              placeholder="Ingrese horas investigación"
              {...getFieldProps('hResearch')}
            />
            {errors.hResearch && touched.hResearch && (
              <FormHelperText error>{errors.hResearch}</FormHelperText>
            )}
          </Grid>

          <Grid item container>
            <Typography className={classes.inputLabel}>
              Total de horas:{' '}
              <Chip
                label={
                  Number(values.hclasses) +
                  Number(values.hAcademicManagement) +
                  Number(values.hPreparation) +
                  Number(values.hResearch) +
                  Number(values.hbonding) +
                  Number(values.htutoring)
                }
              />
            </Typography>
            {/* {errors.totalHours && touched.totalHours && (
              <FormHelperText error>{errors.totalHours}</FormHelperText>
            )} */}
          </Grid>

          <Grid item container>
            {errors.totalHours && touched.totalHours && (
              <FormHelperText error>{errors.totalHours}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>Estado</InputLabel>
            <Autocomplete
              options={statusAvailables}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField {...params} error={Boolean(errors.statusId && touched.statusId)} />
              )}
              value={statusAvailables.find((s) => s.id === Number(values.statusId))}
              onChange={(e, value) => setFieldValue('statusId', value?.id || '')}
              fullWidth
              clearIcon={null}
            />
            {errors.statusId && touched.statusId && (
              <FormHelperText error>{errors.statusId}</FormHelperText>
            )}
          </Grid>
        </Grid>

        <Grid
          item
          container
          alignItems="center"
          justifyContent="flex-end"
          spacing={2}
          sx={{ mt: 1 }}
        >
          <Grid item>
            <Button
              variant="outlined"
              size="large"
              color="primary"
              onClick={() => history.goBack()}
            >
              Regresar
            </Button>
          </Grid>
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              disabled={isLoading}
              endIcon={isLoading && <CircularProgress size={20} />}
            >
              Actualizar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default EditHoursSummariesForm;
