import { FC, useEffect } from 'react';
//   //useHistory,
//   useLocation } from 'react-router-dom';

import {
  Grid,
  Typography,
  Button,
  useTheme,
  CircularProgress,
  TextField,
  FormHelperText,
  Stack,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useHistory, useLocation } from 'react-router-dom';

import { DetailsProps } from '../../constants/types';
import InputLabel from '../../../../shared/components/forms/InputLabel';
import { openRejectAppModalWith, setEditDataApp } from '../../slices/GeneralApplicationsSlice';
import { ChangeStatusAppPracticeNew } from '../../constants/validation-schemas';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import {
  BossDetail,
  CompanieDetail,
  Practice as PracticeInt,
  ValuePracticeDetail,
} from '../../interfaces/generalApplications.interfaces';
import { useUpdatePracticeApplicationMutation } from '../../slices/GeneralApplicationsApiSlice';
// import { firstLetterUppercase } from '../../../../shared/helpers';
import useFormatDate from '../../../../shared/hooks/useFormatDate';
import ProfessionalPracticesForm from '../../../../students/academic-requests/components/ProfessionalPracticesForm';
import { isAppChangeStatusAvailable } from '../../helpers';
import { IconFile } from '@tabler/icons';
import useGetUrlDownload from '../../../../../hooks/useGetUrlDownload';
import downloadjs from 'downloadjs';
import { subPermissionKeys } from '../../constants/permissions';
import useSharedGuard from '../../../../../hooks/useSharedGuard';

const Practice: FC<DetailsProps> = (props) => {
  const {
    details: detailsProp,
    spacing,
    appCode,
    appId,
    // appDescription,
    currentStatus,
    nextStatus,
    updateAlertHandler,
    updateStatusHandler,
  } = props;
  const details = detailsProp as PracticeInt;
  //CompanieDetail | BossDetail | ValuePracticeDetail
  const companieDetail = details[0].value[0] as CompanieDetail;
  const bossDetail = details[0].value[1] as BossDetail;
  const positionDetail = details[0].value[2] as ValuePracticeDetail;

  const fileUrl = details[1]?.value;

  const isNotAvailable = !isAppChangeStatusAvailable(currentStatus);

  const theme = useTheme();

  const history = useHistory();
  const location = useLocation();

  const { formatDate } = useFormatDate();

  //const history = useHistory();
  //const location = useLocation();
  const dispatch = useAppDispatch();

  const { hasAccess } = useSharedGuard(subPermissionKeys);

  const { currentProfile } = useAppSelector((state) => state.access);
  const { editDataApp } = useAppSelector((state) => state.applications);

  const [
    updateApplication,
    {
      isLoading: isUpdating,
      error: updateError,
      isError: isErrorUpdate,
      isSuccess: isSuccessUpdate,
    },
  ] = useUpdatePracticeApplicationMutation();

  const { errors, touched, validateField, values, getFieldProps, handleSubmit } = useFormik({
    initialValues: {
      comment: '',
    },
    validationSchema: ChangeStatusAppPracticeNew,
    onSubmit: async (values) => {
      await updateApplication({
        profileId: currentProfile?.profileId!,
        ApplicationId: appId,
        applicationPayload: {
          app_code: appCode,
          comment: values.comment,
        },
      });
      updateAlertHandler(true);
    },
  });

  useEffect(() => {
    if (isErrorUpdate) updateStatusHandler({ state: 'error', msg: updateError as string });
    if (isSuccessUpdate)
      updateStatusHandler({ state: 'success', msg: 'La solicitud se ha aprobado' });
  }, [isErrorUpdate, updateStatusHandler, updateError, isSuccessUpdate]);

  const handleDelete = (data: { appId: number; code: string; msg: string }) => {
    dispatch(openRejectAppModalWith(data));
  };

  const { urlDownload } = useGetUrlDownload();

  const handleDownload = () => {
    if (fileUrl) {
      const url = `${urlDownload}/${fileUrl}`;
      try {
        downloadjs(url);
      } catch (error) {
        console.log(Error);
      }
    }
  };

  // useEffect(() => {
  //   if (practiceSubjects && practiceSubjects.course_student) {
  //     setTotalHours(
  //       practiceSubjects.course_student
  //         .map((st) => Number(st.final_note))
  //         .reduce((partialSum, a) => partialSum + a, 0)
  //     );
  //   }
  // }, [practiceSubjects]);

  return (
    <Grid container justifyContent="center" alignItems="center">
      <>
        {!isNotAvailable && hasAccess('changeApplicationStatus') && (
          <Grid
            item
            xs={12}
            sx={{
              maxWidth: '1000px',
            }}
          >
            <Button onClick={() => dispatch(setEditDataApp(!editDataApp))}>
              {editDataApp ? 'Cancelar' : 'Editar'}
            </Button>
          </Grid>
        )}
        {editDataApp && (
          <Grid
            sx={{
              maxWidth: '1000px',
            }}
          >
            <ProfessionalPracticesForm isEdit />
          </Grid>
        )}
        {!editDataApp && (
          <form onSubmit={handleSubmit}>
            {/* <Grid item mb={1}>
              <InputLabel>{firstLetterUppercase(appDescription)}</InputLabel>
            </Grid> */}
            <Grid
              container
              border="1px solid #eee"
              borderRadius="12px"
              p={4}
              mb={1}
              sx={{ maxWidth: '1000px' }}
            >
              {/* Datos de la compania */}
              <Grid item xs={12}>
                <Typography variant="h4" color="primary" component="div" sx={{ mb: 2 }}>
                  1. Datos de la compañia
                </Typography>
              </Grid>

              <Grid container item alignItems={'center'} mb={1}>
                <Grid item xs={'auto'}>
                  <Typography variant={'body2'} component={'span'}>
                    Ruc:
                  </Typography>
                </Grid>

                <Grid item sx={{ pl: spacing }}>
                  <Typography variant={'h5'} component={'span'}>
                    {companieDetail.companie_ruc}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container item alignItems={'center'} mb={1}>
                <Grid item xs={'auto'}>
                  <Typography variant={'body2'} component={'span'}>
                    Empresa:
                  </Typography>
                </Grid>

                <Grid item sx={{ pl: spacing }}>
                  <Typography variant={'h5'} component={'span'}>
                    {companieDetail.companie_name}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container item xs={12} alignItems={'center'} mb={1}>
                <Grid item xs={'auto'}>
                  <Typography variant={'body2'} component={'span'}>
                    Actividad:
                  </Typography>
                </Grid>

                <Grid item xs={'auto'} lg={6} sx={{ pl: spacing }}>
                  <Typography variant={'h5'} component={'span'}>
                    {companieDetail.companie_activity}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container item xs={12} sm={6} alignItems={'center'} mb={1}>
                <Grid item xs={'auto'}>
                  <Typography variant={'body2'} component={'span'}>
                    Razón Social:
                  </Typography>
                </Grid>

                <Grid item xs={'auto'} lg={6} sx={{ pl: spacing }}>
                  <Typography variant={'h5'} component={'span'}>
                    {companieDetail.companie_reason}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container item alignItems={'center'} mb={1}>
                <Grid item xs={'auto'}>
                  <Typography variant={'body2'} component={'span'}>
                    Dirección:
                  </Typography>
                </Grid>

                <Grid item xs={'auto'} lg={6} sx={{ pl: spacing }}>
                  <Typography variant={'h5'} component={'span'}>
                    {companieDetail.companie_address}
                  </Typography>
                </Grid>
              </Grid>

              {/* datos del jefe */}
              <Grid item xs={12}>
                <Typography variant="h4" color="primary" component="div" sx={{ my: 2 }}>
                  2. Datos del jefe
                </Typography>
              </Grid>
              <Grid container item alignItems={'center'} mb={1}>
                <Grid item xs={'auto'}>
                  <Typography variant={'body2'} component={'span'}>
                    Nombre:
                  </Typography>
                </Grid>

                <Grid item xs={'auto'} lg={6} sx={{ pl: spacing }}>
                  <Typography variant={'h5'} component={'span'}>
                    {bossDetail.boss_name}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container item alignItems={'center'} mb={1}>
                <Grid item xs={'auto'}>
                  <Typography variant={'body2'} component={'span'}>
                    Correo:
                  </Typography>
                </Grid>

                <Grid item xs={'auto'} lg={6} sx={{ pl: spacing }}>
                  <Typography variant={'h5'} component={'span'}>
                    {bossDetail.boss_email}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container item alignItems={'center'} mb={1}>
                <Grid item xs={'auto'}>
                  <Typography variant={'body2'} component={'span'}>
                    Cargo:
                  </Typography>
                </Grid>

                <Grid item xs={'auto'} lg={6} sx={{ pl: spacing }}>
                  <Typography variant={'h5'} component={'span'}>
                    {bossDetail.boss_position}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container item alignItems={'center'} mb={1}>
                <Grid item xs={'auto'}>
                  <Typography variant={'body2'} component={'span'}>
                    Teléfono:
                  </Typography>
                </Grid>

                <Grid item xs={'auto'} lg={6} sx={{ pl: spacing }}>
                  <Typography variant={'h5'} component={'span'}>
                    {bossDetail.boss_tlf}
                  </Typography>
                </Grid>
              </Grid>

              {/* datos de la practica */}
              <Grid item xs={12}>
                <Typography variant="h4" color="primary" component="div" sx={{ my: 2 }}>
                  3. Datos del puesto
                </Typography>
              </Grid>

              <Grid container item alignItems={'center'} mb={1}>
                <Grid item xs={'auto'}>
                  <Typography variant={'body2'} component={'span'}>
                    Cargo:
                  </Typography>
                </Grid>

                <Grid item xs={'auto'} lg={6} sx={{ pl: spacing }}>
                  <Typography variant={'h5'} component={'span'}>
                    {positionDetail.practice_position}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container item alignItems={'center'} mb={1}>
                <Grid item xs={'auto'}>
                  <Typography variant={'body2'} component={'span'}>
                    Horario:
                  </Typography>
                </Grid>

                <Grid item xs={'auto'} lg={6} sx={{ pl: spacing }}>
                  <Typography variant={'h5'} component={'span'}>
                    {positionDetail.practice_hourhand} 1
                  </Typography>
                </Grid>
              </Grid>

              <Grid container item alignItems={'center'} mb={1}>
                <Grid item xs={'auto'}>
                  <Typography variant={'body2'} component={'span'}>
                    Fecha inicio:
                  </Typography>
                </Grid>

                <Grid item xs={'auto'} lg={6} sx={{ pl: spacing }}>
                  <Typography variant={'h5'} component={'span'}>
                    {formatDate(positionDetail.practice_start_date)}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container item alignItems={'center'} mb={1}>
                <Grid item xs={'auto'}>
                  <Typography variant={'body2'} component={'span'}>
                    Fecha fin:
                  </Typography>
                </Grid>

                <Grid item xs={'auto'} lg={6} sx={{ pl: spacing }}>
                  <Typography variant={'h5'} component={'span'}>
                    {formatDate(positionDetail.practice_end_date)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={6}>
              <Stack alignItems="center">
                <IconFile size={120} />
                <Button variant="outlined" onClick={handleDownload}>
                  Descargar archivo
                </Button>
              </Stack>
            </Grid>

            {hasAccess('changeApplicationStatus') && (
              <>
                ≈
                <Grid item xs={12} md={12} lg={12}>
                  <InputLabel>Comentario</InputLabel>
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    error={Boolean(errors.comment && touched.comment)}
                    placeholder="Ingrese el nombre del período"
                    {...getFieldProps('comment')}
                    disabled={isNotAvailable}
                  />
                  {errors.comment && touched.comment && (
                    <FormHelperText error>{errors.comment}</FormHelperText>
                  )}
                </Grid>
                <Grid item container justifyContent={'flex-end'} mt={2} spacing={2}>
                  <Grid item>
                    <Button
                      variant="outlined"
                      size="large"
                      onClick={() => history.push(`/${location.pathname.split('/')[1]}`)}
                    >
                      Cancelar
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      size="large"
                      {...(!isNotAvailable && {
                        sx: { background: theme.palette.error.main, borderColor: '#EDE7F6' },
                      })}
                      onClick={() => {
                        touched.comment = true;
                        validateField('comment').then(() => {
                          if ((touched.comment && errors.comment) || !values.comment) return;
                          handleDelete({ appId, code: appCode, msg: values.comment });
                        });
                      }}
                      disabled={
                        isUpdating || isNotAvailable || isSuccessUpdate || nextStatus === null
                      }
                    >
                      Rechazar
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      type="submit"
                      variant="contained"
                      size="large"
                      color="primary"
                      disabled={
                        isUpdating || isNotAvailable || isSuccessUpdate || nextStatus === null
                      }
                      endIcon={isUpdating && <CircularProgress size={20} />}
                    >
                      Aprobar
                    </Button>
                  </Grid>
                </Grid>
              </>
            )}
          </form>
        )}
      </>

      {/* {((currentRol?.keyword === 'decano' &&
          currentApplication!.current_status.toLowerCase() === 'enviado') ||
          (currentRol?.keyword === 'coordinador-facultad' &&
            (currentApplication!.current_status.toLowerCase() === 'revisado por decanato' ||
              currentApplication!.current_status.toLowerCase() ===
                'revisado por consejo facultad'))) &&
          !isSuccess && ( */}
      {/* )} */}
    </Grid>
  );
};

export default Practice;
