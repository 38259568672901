import { mainApi } from '../../../../../../api/mainApi';
import { middlewareApi } from '../../../../../middleware/middlewareApi';
import { Courses } from '../interfaces/groupsListCourses.interfaces';
import { PeriodsData } from '../interfaces/groupsPeriodsByCourses.interfaces';
import { ListGroups } from '../interfaces/listGroup.interfaces';

export const listGroupsTags = middlewareApi.enhanceEndpoints({
  addTagTypes: ['ListGroups'],
});

export const listGroupsApi = listGroupsTags.injectEndpoints({
  endpoints: (builder) => ({
    getListGroupsBygroupType: builder.query<
      ListGroups,
      {
        start_date: string;
        end_date: string;
        sort: string;
        status: string;
        eva_groups_type_keyword: string;
        type_sort: string;
        search: string;
        size: number;
        page: number;
      }
    >({
      queryFn: async ({
        start_date,
        end_date,
        eva_groups_type_keyword,
        page,
        search,
        size,
        sort,
        status,
        type_sort,
      }) => {
        try {
          const { data } = await mainApi.get<ListGroups>('/eva/groups', {
            params: {
              start_date,
              end_date,
              eva_groups_type_keyword,
              page,
              search,
              size,
              sort,
              status,
              type_sort,
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: [{ type: 'ListGroups', id: 'LIST' }],
    }),

    deleteGroup: builder.mutation<{ message: string }, { id: string }>({
      queryFn: async ({ id }) => {
        try {
          const { data } = await mainApi.delete<{ message: string }>(`/eva/groups/${id}`);

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'ListGroups', id: 'LIST' }],
    }),

    addCourseGroup: builder.mutation<
      { message: string },
      {
        name: string;
        eva_type_uuid: string;
        keyword: string;
        period_id: number;
        course: number[];
      }
    >({
      queryFn: async ({ name, eva_type_uuid, keyword, period_id, course }) => {
        try {
          const { data } = await mainApi.post<{ message: string }>('/eva/groups', {
            name,
            eva_type_uuid,
            keyword,
            period_id,
            course,
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'ListGroups', id: 'LIST' }],
    }),

    addGroupsUsers: builder.mutation<
      { message: string },
      {
        name: string;
        eva_type_uuid: string;
        keyword: string;
        user: number[];
      }
    >({
      queryFn: async ({ name, eva_type_uuid, keyword, user }) => {
        try {
          const { data } = await mainApi.post<{ message: string }>('/eva/groups', {
            name,
            eva_type_uuid,
            keyword,
            user,
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'ListGroups', id: 'LIST' }],
    }),
    getListAllPeriods: builder.query<PeriodsData[], {}>({
      queryFn: async () => {
        try {
          const { data } = await mainApi.get<PeriodsData[]>(
            '/periods?user_profile_id=1&page=1&size=5&type_sort=desc&status_id=1&search=&data=all'
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    getListCourses: builder.query<
      Courses[],
      {
        periodId: string | number;
      }
    >({
      queryFn: async ({ periodId }) => {
        try {
          const { data } = await mainApi.get<Courses[]>(`/eva/periods-by-courses/${periodId}`);

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),
  }),
});

export const {
  useLazyGetListGroupsBygroupTypeQuery,
  useDeleteGroupMutation,
  useAddCourseGroupMutation,
  useAddGroupsUsersMutation,
  useGetListAllPeriodsQuery,
  useLazyGetListCoursesQuery,
} = listGroupsApi;
