import { Redirect, Switch, Route } from 'react-router-dom';
import { useAppSelector } from '../../../../hooks/useRedux';
import StudentsScreen from '../screens/StudentsScreen';
import EditStudentScreen from '../screens/EditStudentScreen';
import AddStudentScreen from '../screens/AddStudentScreen';
import GuardRoute from '../../../../router/guards/GuardRoute';
import StudentAcademicHistoryScreen from '../screens/StudentAcademicHistoryScreen';
import { subPermissionKeys } from '../constants/permissions';

function StudentsRouter() {
  // Verify permission
  const { currentModule } = useAppSelector((state) => state.access);
  const hasPermission = 'student' in currentModule?.permissions!;

  if (!hasPermission) return <Redirect to="/" />;

  return (
    <>
      <Switch>
        <GuardRoute
          path="/administrar-estudiantes"
          exact
          component={StudentsScreen}
          permissionKey={subPermissionKeys.groupKey}
          subPermissionKey={[subPermissionKeys.read]}
        />
        <GuardRoute
          exact
          path={`/administrar-estudiantes/agregar`}
          component={AddStudentScreen}
          permissionKey={subPermissionKeys.groupKey}
          subPermissionKey={[subPermissionKeys.store]}
        />
        <GuardRoute
          exact
          path={`/administrar-estudiantes/:studentId/editar`}
          component={EditStudentScreen}
          permissionKey={subPermissionKeys.groupKey}
          subPermissionKey={[subPermissionKeys.update]}
        />
        <GuardRoute
          exact
          path={`/administrar-estudiantes/:studentId/historial-academico`}
          component={StudentAcademicHistoryScreen}
          permissionKey={subPermissionKeys.groupKey}
          subPermissionKey={[subPermissionKeys.show]}
        />
        <Route path={`/administrar-estudiantes/*`}>
          <Redirect to="/administrar-estudiantes" />
        </Route>
      </Switch>
    </>
  );
}

export default StudentsRouter;
