import { FormHelperText, Grid, InputLabel, TextField } from '@material-ui/core';
import { useFormikContext } from 'formik';

import { useTranslation } from 'react-i18next';
interface BossDataForm {
  bossName: string;
  bossPosition: string;
  bossEmail: string;
  bossTlf: string;
}
const BossStep = () => {
  const { t } = useTranslation();

  const { getFieldProps, errors, touched } = useFormikContext<BossDataForm>();
  return (
    <Grid container spacing={4} alignContent="flex-start">
      <Grid item xs={12} md={6} container spacing={2}>
        <Grid item xs={12} container spacing={2}>
          <Grid item xs={12}>
            <InputLabel>{t('students.applications.professional_practices.boss.name')}</InputLabel>
            <TextField
              fullWidth
              placeholder={t('students.applications.professional_practices.boss.name')}
              error={Boolean(errors.bossName && touched.bossName)}
              {...getFieldProps('bossName')}
            />
            {errors.bossName && touched.bossName && (
              <FormHelperText error>{errors.bossName}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12}>
            <InputLabel>{t('students.applications.professional_practices.boss.email')}</InputLabel>
            <TextField
              fullWidth
              placeholder={t('students.applications.professional_practices.boss.email')}
              error={Boolean(errors.bossEmail && touched.bossEmail)}
              {...getFieldProps('bossEmail')}
            />
            {errors.bossEmail && touched.bossEmail && (
              <FormHelperText error>{errors.bossEmail}</FormHelperText>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6} container spacing={2}>
        <Grid item xs={12} container spacing={2}>
          <Grid item xs={12}>
            <InputLabel>{t('students.applications.professional_practices.boss.role')}</InputLabel>
            <TextField
              fullWidth
              placeholder={t('students.applications.professional_practices.boss.role')}
              error={Boolean(errors.bossPosition && touched.bossPosition)}
              {...getFieldProps('bossPosition')}
            />
            {errors.bossPosition && touched.bossPosition && (
              <FormHelperText error>{errors.bossPosition}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={12}>
            <InputLabel>{t('students.applications.professional_practices.boss.phone')}</InputLabel>
            <TextField
              fullWidth
              placeholder={t('students.applications.professional_practices.boss.phone')}
              error={Boolean(errors.bossTlf && touched.bossTlf)}
              {...getFieldProps('bossTlf')}
            />
            {errors.bossTlf && touched.bossTlf && (
              <FormHelperText error>{errors.bossTlf}</FormHelperText>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default BossStep;
