import { FC, useEffect, useMemo } from 'react';

import { Button, FormHelperText, Grid, TextField } from '@material-ui/core';
import { useFormik } from 'formik';

import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../../../../hooks/useRedux';
import InputLabel from '../../../../../shared/components/forms/InputLabel';

import { FormProps } from '../../../interfaces/components.interface';
import { setPayCollection } from '../../../slices/debtsPaymentSlice';
import { epsilonRound, updateItemFromIndexArr } from '../../../../../shared/helpers';
import { CashMethodSchema } from '../../../constants/validationForms-schemas';
import CurrencyInputMasked from '../../shared/CurrencyInputMasked';

const CashMethod: FC<FormProps> = (props) => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  //setIndexToEdit(indexToEdit);
  const { paymethod, indexPayToEdit, positiveBalance, setIsPay, onCancel, handleSetEditPayment } =
    props;

  const { payCollection, totalDebtAmount, totalPayAmount } = useAppSelector(
    (state) => state.debtsPayment
  );

  const defaultConfiguration = useAppSelector((state) => state.access.defaultConfiguration);

  useEffect(() => {
    if (paymethod.keyword === 'forma-pago-efectivo') {
      const isExist = payCollection
        .map((paid) => paid.payment_way_keyword)
        .indexOf('forma-pago-efectivo');

      if (isExist >= 0 && handleSetEditPayment) {
        handleSetEditPayment(isExist);
      }
    }

    return () => {
      if (handleSetEditPayment) handleSetEditPayment(undefined);
    };
  }, [handleSetEditPayment, payCollection, paymethod]);

  const initialValues = useMemo(() => {
    return {
      observation: indexPayToEdit?.toString() ? payCollection[indexPayToEdit]?.observation : '',
      ...(paymethod.keyword === 'forma-pago-efectivo' && {
        payAmount: indexPayToEdit?.toString()
          ? payCollection[indexPayToEdit]?.payment_amount
          : epsilonRound(
              totalDebtAmount - totalPayAmount > 0 ? totalDebtAmount - totalPayAmount : 0
            ) || null,
      }),
      ...(paymethod.keyword === 'forma-pago-saldo-favor' && {
        payAmount: positiveBalance ? positiveBalance : totalDebtAmount || null,
      }),
    };
  }, [
    indexPayToEdit,
    payCollection,
    paymethod.keyword,
    positiveBalance,
    totalDebtAmount,
    totalPayAmount,
  ]);

  //usar un solo formik e importar los initial state
  const { getFieldProps, errors, touched, handleSubmit, setValues } = useFormik({
    initialValues: initialValues,
    validationSchema: CashMethodSchema,
    onSubmit: async (values) => {
      try {
        if (!indexPayToEdit?.toString()) {
          const newPayCollection = [
            {
              payment_amount: +values.payAmount!,
              observation: values.observation,
              payment_way_id: paymethod.id,
              payment_way: paymethod.id,
              payment_way_name: paymethod.name,
              payment_way_keyword: paymethod.keyword,
              issues_invoice: 1,
            },
            ...payCollection,
          ];

          dispatch(setPayCollection(newPayCollection));
        } else {
          const updatedItem = {
            payment_amount: +values.payAmount!,
            observation: values.observation,
            payment_way_id: paymethod.id,
            payment_way: paymethod.id,
            payment_way_name: paymethod.name,
            payment_way_keyword: paymethod.keyword,
            issues_invoice: 1,
          };

          const newPayCollection = updateItemFromIndexArr(
            payCollection,
            indexPayToEdit,
            updatedItem
          );

          dispatch(setPayCollection(newPayCollection));
        }

        setIsPay();
      } catch (error) {
        console.log(error);
      }

      //   setShowAlerts(true);
      //   window.scrollTo(0, 0);
    },
  });

  useEffect(() => {
    if (indexPayToEdit?.toString()) {
      setValues({ ...initialValues });
    }
  }, [indexPayToEdit, initialValues, setValues]);

  //reset values on mount (cause if change effective and change
  //to possitive balance, the effective amount still remain)
  useEffect(() => {
    setValues({ ...initialValues });
  }, [initialValues, setValues]);

  return (
    <form onSubmit={handleSubmit}>
      {/* <Button onClick={() => setValues({ ...initialValues });}>asd</Button> */}
      <Grid container spacing={1} alignItems="flex-start">
        {/* <Grid item>
            {isError && showAlerts && (
              <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
            )}

            {isSuccess && showAlerts && (
              <SuccessAlert
                message="El periodo ha sido creado correctamente."
                handleDismiss={() => setShowAlerts(false)}
              />
            )}
          </Grid> */}

        <Grid item xs={12}>
          <InputLabel>{t('financial.debts_payment.pay_dialog.form.amount_label')}</InputLabel>
          <TextField
            // type="number"
            disabled={paymethod.keyword === 'forma-pago-saldo-favor'}
            fullWidth
            InputProps={{
              defaultValue: '',
              inputComponent: CurrencyInputMasked as any,
              startAdornment: defaultConfiguration?.currency_symbol,
            }}
            placeholder={`${defaultConfiguration?.currency_symbol}0.00`}
            {...getFieldProps('payAmount')}
            error={Boolean(errors.payAmount && touched.payAmount)}
          />
          {errors.payAmount && touched.payAmount && (
            <FormHelperText error>{t(errors.payAmount)}</FormHelperText>
          )}
        </Grid>

        <Grid item xs={12}>
          <InputLabel>{t('financial.debts_payment.pay_dialog.form.observation_label')}</InputLabel>
          <TextField
            fullWidth
            multiline
            rows={2}
            error={Boolean(errors.observation && touched.observation)}
            placeholder={t('financial.debts_payment.pay_dialog.form.observation_placeholder')}
            {...getFieldProps('observation')}
          />
          {/* <FormHelperText>Nombre del periodo</FormHelperText> */}
          {errors.observation && touched.observation && (
            <FormHelperText error>{t(errors.observation)}</FormHelperText>
          )}
        </Grid>

        <Grid item xs={12}>
          <Grid container justifyContent={'flex-end'} spacing={1}>
            <Grid item>
              <Button variant="outlined" onClick={onCancel}>
                {t('financial.debts_payment.pay_dialog.form.cancel_btn')}
              </Button>
            </Grid>
            <Grid item>
              <Button type="submit" variant="contained">
                {indexPayToEdit?.toString()
                  ? t('financial.debts_payment.pay_dialog.form.update_btn')
                  : t('financial.debts_payment.pay_dialog.form.save_btn')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default CashMethod;
