export const subPermissionKeys = {
  groupKey: 'course',

  getCollaboratorAdviser: 'list-collaborators-adviser',
  showCollaborator: 'collaborators-obtener-colaborador',
  getStudentsOfCollaborator: 'collaborators-listar-estudiantes-de-colaborado',
  showStudent: 'student-show-estudiante',

  showOffers: 'offers-listar-ofertas',
  getOnlyParentsWithMeshVigents: 'education-levels-obtener-nivel-educativos-con-mallas',
}; // satisfies PermissionsKeys;
