import { Button, CircularProgress, Grid } from '@material-ui/core';
import { format } from 'date-fns';
import { Formik, FormikValues } from 'formik';
import { useParams, useHistory } from 'react-router-dom';
import { useAppSelector } from '../../../../../hooks/useRedux';
import { STAGES } from '../../constants/stages';
import { PeriodForm, Stage, StagePayload } from '../../interfaces/period-form.interfaces';
import { useApprovePlanningMutation } from '../../slices/acPlanPostgraduateApiSlice';
import { StagesTabs } from './tabs';
import * as Yup from 'yup';
import { useState } from 'react';
import SuccessAlert from '../../../../shared/components/alerts/SuccessAlert';
import ErrorAlert from '../../../../shared/components/alerts/ErrorAlert';
import moment from 'moment';
import { PeriodStage } from '../../interfaces/postgraduatePlanification.interface';

const ProgramPeriodDates = () => {
  const history = useHistory();

  const { postGraduatePlanificationList } = useAppSelector(
    (state) => state.academicPlanningPosgraduate
  );
  const [showAlerts, setShowAlerts] = useState(false);

  const [approvePlanning, { isLoading: isApproving, isSuccess, isError, error }] =
    useApprovePlanningMutation();

  const { academicOfferConfig } = useAppSelector((state) => state.periods);

  const config = academicOfferConfig?.map((e) => e.stg_acronym);

  const { idTransaction } = useParams<{ idTransaction: string }>();
  const numFees =
    postGraduatePlanificationList.find((e) => e.id_transaction_planning === idTransaction)
      ?.number_fees_period_stages || 0;

  const startDate = postGraduatePlanificationList.find(
    (e) => e.id_transaction_planning === idTransaction
  )?.start_process;

  const endDate = postGraduatePlanificationList.find(
    (e) => e.id_transaction_planning === idTransaction
  )?.end_process;

  const feeCollectionDay = Number(
    postGraduatePlanificationList.find((e) => e.id_transaction_planning === idTransaction)
      ?.postgraduate_planning_status?.fee_collection_day_max || 1
  );

  const periodStages = postGraduatePlanificationList.find(
    (e) => e.id_transaction_planning === idTransaction
  )?.period_stages;

  const isDisabled =
    postGraduatePlanificationList.find((e) => e.id_transaction_planning === idTransaction)
      ?.postgraduate_planning_status?.status_planning === 'ejecutada';

  const periodStagesToValue = periodStages?.reduce(
    (
      acc: {
        [key: string]: PeriodStage[];
      },
      stage
    ) => {
      const { stg_acronym } = stage.stage;

      if (acc[STAGES.find((e) => e.use_case === stg_acronym)!.field]) {
        acc[STAGES.find((e) => e.use_case === stg_acronym)!.field].push(stage);
      } else {
        acc[STAGES.find((e) => e.use_case === stg_acronym)!.field] = [stage];
      }
      return acc;
    },
    {}
  );

  const firstDate = postGraduatePlanificationList.find(
    (e) => e.id_transaction_planning === idTransaction
  )?.start_process;

  const validationSchema = () => {
    const validationObject: { [key: string]: Yup.AnySchema } = {
      numFees: Yup.number()
        .nullable()
        .required('Número de cuotas requerido')
        .min(1, 'Número de cuotas debe ser mínimo uno')
        .when('feesEnrollmentUnique', {
          is: (feesEnrollmentUnique: string) => feesEnrollmentUnique === '1',
          then: Yup.number().min(2, 'Número de cuotas debe ser mínimo 2'),
        }),
    };

    // validation schema keys based on the name prop of the radio buttons in the form
    config.forEach((conf) => {
      validationObject[STAGES.find((e) => e.use_case === conf)!.field] = Yup.object().shape({
        start_date: Yup.date()
          .typeError('Ingrese una fecha de inicio válida.')
          .required('La fecha de inicio es requerida.'),
        end_date: Yup.date()
          .typeError('Ingrese una fecha de fin válida.')
          .required('La fecha de fin es requerida.')
          .min(Yup.ref('start_date'), 'La fecha fin debe ser mayor a la fecha de inicio'),
      });
    });
    validationObject['generation_quote'] = Yup.array().of(
      Yup.object().shape({
        start_date: Yup.date()
          .typeError('Ingrese una fecha de inicio válida.')
          .required('La fecha de inicio es requerida.'),
        end_date: Yup.date()
          .typeError('Ingrese una fecha de fin válida.')
          .required('La fecha de fin es requerida.')
          .min(Yup.ref('start_date'), 'La fecha fin debe ser mayor a la fecha de inicio'),
      })
    );

    return Yup.object(validationObject);
  };

  const obtainDateStage = (stage: any): StagePayload => {
    const {
      course_id,
      education_level_id,
      end_date,
      parallel_id,
      start_date,
      status_id,
      stg_acronym,
    } = stage;
    return {
      course_id,
      education_level_id,
      parallel_id,
      start_date: format(start_date!, 'yyyy-MM-dd'),
      end_date: format(end_date!, 'yyyy-MM-dd'),
      status_id,
      stg_acronym,
    };
  };

  const obtainStages = (values: FormikValues): StagePayload[] => {
    const stages: StagePayload[] = [];

    for (let i = 0; i < STAGES.length; i++) {
      if (config.includes(STAGES[i].use_case)) {
        const field = values[STAGES[i].field as unknown as keyof PeriodForm] as Stage;
        if (Array.isArray(field)) {
          for (let e = 0; e < field?.length; e++) {
            stages.push(obtainDateStage(field[e]));
          }
        } else {
          stages.push(obtainDateStage(field));
        }
      }
    }
    return stages;
  };

  return (
    <Formik
      initialValues={{
        disabled: isDisabled || false,
        numFees,
        feeCollectionDay,
        generation_quote:
          periodStagesToValue?.['generation_quote']?.map((stage) => ({
            start_date: moment(stage.start_date, 'YYYY-MM-DD').toDate(),
            end_date: moment(stage.end_date, 'YYYY-MM-DD').toDate(),
            stg_acronym: stage.stage?.stg_acronym,
            status_id: 1,
            education_level_id: null,
            parallel_id: null,
            course_id: null,
          })) ||
          Array.from({ length: Number(numFees) }, (_, idx) => ({
            ...(idx === 0
              ? {
                  start_date: moment(firstDate, 'YYYY-MM-DD').subtract(5, 'days').toDate(),
                  end_date: moment(firstDate, 'YYYY-MM-DD').toDate(),
                }
              : {
                  start_date: moment(
                    new Date(
                      moment(firstDate, 'YYYY-MM-DD').year(),
                      moment(firstDate, 'YYYY-MM-DD').month(),
                      feeCollectionDay
                    )
                  )
                    .add(
                      idx -
                        1 +
                        (moment(firstDate, 'YYYY-MM-DD').date() >= feeCollectionDay ? 1 : 0), // si la fecha de inicio es mayor al día de cobro, se suma un mes
                      'months'
                    )
                    .subtract(5, 'days')
                    .toDate(),
                  end_date: moment(
                    new Date(
                      moment(firstDate, 'YYYY-MM-DD').year(),
                      moment(firstDate, 'YYYY-MM-DD').month(),
                      feeCollectionDay
                    )
                  )
                    .add(
                      idx -
                        1 +
                        (moment(firstDate, 'YYYY-MM-DD').date() >= feeCollectionDay ? 1 : 0), // si la fecha de inicio es mayor al día de cobro, se suma un mes
                      'months'
                    )
                    .toDate(),
                }),
            stg_acronym: 'RC',
            status_id: 1,
            education_level_id: null,
            parallel_id: null,
            course_id: null,
          })),

        entry_teacher: {
          start_date: periodStagesToValue?.['entry_teacher']?.[0]?.start_date
            ? moment(periodStagesToValue?.['entry_teacher']?.[0]?.start_date, 'YYYY-MM-DD').toDate()
            : moment(startDate, 'YYYY-MM-DD').toDate(),

          end_date: periodStagesToValue?.['entry_teacher']?.[0]?.end_date
            ? moment(periodStagesToValue?.['entry_teacher']?.[0]?.end_date, 'YYYY-MM-DD').toDate()
            : moment(endDate, 'YYYY-MM-DD').toDate(),
          stg_acronym: periodStagesToValue?.['entry_teacher']?.[0]?.stage?.stg_acronym || 'D',
          status_id: 1,
          education_level_id: null,
          parallel_id: null,
          course_id: null,
        },

        register_matter: {
          start_date: periodStagesToValue?.['register_matter']?.[0]?.start_date
            ? moment(
                periodStagesToValue?.['register_matter']?.[0]?.start_date,
                'YYYY-MM-DD'
              ).toDate()
            : moment(startDate, 'YYYY-MM-DD').toDate(),
          end_date: periodStagesToValue?.['register_matter']?.[0]?.end_date
            ? moment(periodStagesToValue?.['register_matter']?.[0]?.end_date, 'YYYY-MM-DD').toDate()
            : moment(endDate, 'YYYY-MM-DD').toDate(),
          status_id: 1,
          stg_acronym: periodStagesToValue?.['register_matter']?.[0]?.stage?.stg_acronym || 'E',
          education_level_id: null,
          parallel_id: null,
          course_id: null,
        },

        register_matter_grade: {
          start_date: periodStagesToValue?.['register_matter_grade']?.[0]?.start_date
            ? moment(
                periodStagesToValue?.['register_matter_grade']?.[0]?.start_date,
                'YYYY-MM-DD'
              ).toDate()
            : null,
          end_date: periodStagesToValue?.['register_matter_grade']?.[0]?.end_date
            ? moment(
                periodStagesToValue?.['register_matter_grade']?.[0]?.end_date,
                'YYYY-MM-DD'
              ).toDate()
            : null,
          stg_acronym:
            periodStagesToValue?.['register_matter_grade']?.[0]?.stage?.stg_acronym || '',
          status_id: 1,
          education_level_id: null,
          parallel_id: null,
          course_id: null,
        },

        register_matter_admin: {
          start_date: periodStagesToValue?.['register_matter_admin']?.[0]?.start_date
            ? moment(
                periodStagesToValue?.['register_matter_admin']?.[0]?.start_date,
                'YYYY-MM-DD'
              ).toDate()
            : null,
          end_date: periodStagesToValue?.['register_matter_admin']?.[0]?.end_date
            ? moment(
                periodStagesToValue?.['register_matter_admin']?.[0]?.end_date,
                'YYYY-MM-DD'
              ).toDate()
            : null,
          stg_acronym:
            periodStagesToValue?.['register_matter_admin']?.[0]?.stage?.stg_acronym || '',
          status_id: 1,
          education_level_id: null,
          parallel_id: null,
          course_id: null,
        },

        register_matter_student_portal: {
          start_date: periodStagesToValue?.['register_matter_student_portal']?.[0]?.start_date
            ? moment(
                periodStagesToValue?.['register_matter_student_portal']?.[0]?.start_date,
                'YYYY-MM-DD'
              ).toDate()
            : moment(startDate, 'YYYY-MM-DD').toDate(),
          stg_acronym:
            periodStagesToValue?.['register_matter_student_portal']?.[0]?.stage?.stg_acronym || 'R',
          end_date: periodStagesToValue?.['register_matter_student_portal']?.[0]?.end_date
            ? moment(
                periodStagesToValue?.['register_matter_student_portal']?.[0]?.end_date,
                'YYYY-MM-DD'
              ).toDate()
            : moment(endDate, 'YYYY-MM-DD').toDate(),
          status_id: 1,
          education_level_id: null,
          parallel_id: null,
          course_id: null,
        },

        request_matter_grade: {
          start_date: periodStagesToValue?.['request_matter_grade']?.[0]?.start_date
            ? moment(
                periodStagesToValue?.['request_matter_grade']?.[0]?.start_date,
                'YYYY-MM-DD'
              ).toDate()
            : null,
          end_date: periodStagesToValue?.['request_matter_grade']?.[0]?.end_date
            ? moment(
                periodStagesToValue?.['request_matter_grade']?.[0]?.end_date,
                'YYYY-MM-DD'
              ).toDate()
            : null,
          stg_acronym: periodStagesToValue?.['request_matter_grade']?.[0]?.stage?.stg_acronym || '',
          status_id: 1,
          education_level_id: null,
          parallel_id: null,
          course_id: null,
        },

        start_program_maestry: {
          start_date: periodStagesToValue?.['start_program_maestry']?.[0]?.start_date
            ? moment(
                periodStagesToValue?.['start_program_maestry']?.[0]?.start_date,
                'YYYY-MM-DD'
              ).toDate()
            : null,
          end_date: periodStagesToValue?.['start_program_maestry']?.[0]?.end_date
            ? moment(
                periodStagesToValue?.['start_program_maestry']?.[0]?.end_date,
                'YYYY-MM-DD'
              ).toDate()
            : null,
          stg_acronym:
            periodStagesToValue?.['start_program_maestry']?.[0]?.stage?.stg_acronym || '',
          status_id: 1,
          education_level_id: null,
          parallel_id: null,
          course_id: null,
        },

        start_class: {
          start_date: periodStagesToValue?.['start_class']?.[0]?.start_date
            ? moment(periodStagesToValue?.['start_class']?.[0]?.start_date, 'YYYY-MM-DD').toDate()
            : null,
          end_date: periodStagesToValue?.['start_class']?.[0]?.end_date
            ? moment(periodStagesToValue?.['start_class']?.[0]?.end_date, 'YYYY-MM-DD').toDate()
            : null,
          stg_acronym: periodStagesToValue?.['start_class']?.[0]?.stage?.stg_acronym || '',
          status_id: 1,
          education_level_id: null,
          parallel_id: null,
          course_id: null,
        },
      }}
      onSubmit={async (values) => {
        const stages = obtainStages(values);

        const stagesGrouped = stages.reduce<{
          [key: string]: StagePayload[];
        }>((acc, curr) => {
          if (!acc[curr.stg_acronym!]) {
            acc[curr.stg_acronym!] = [curr];
          } else {
            acc[curr.stg_acronym!].push(curr);
          }
          return acc;
        }, {});
        const stagesGroupedArray = Object.values(stagesGrouped);

        await approvePlanning({
          id: idTransaction,
          periodStageDates: stagesGroupedArray,
        });

        setShowAlerts(true);
      }}
      validationSchema={validationSchema}
    >
      {({ handleSubmit, errors }) => (
        <form onSubmit={handleSubmit}>
          {showAlerts && isSuccess && (
            <Grid item xs={12}>
              <SuccessAlert
                message="El plan de estudios ha sido aprobado correctamente."
                handleDismiss={() => {
                  setShowAlerts(false);
                }}
              />
            </Grid>
          )}
          {showAlerts && isError && (
            <Grid item xs={12}>
              <ErrorAlert
                message={error as string}
                onClose={() => {
                  setShowAlerts(false);
                }}
              />
            </Grid>
          )}

          <StagesTabs />

          <Grid container justifyContent="flex-end" mt={2}>
            {!isDisabled && (
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isApproving || isSuccess}
                endIcon={isApproving ? <CircularProgress size={14} /> : null}
              >
                Aprobar planificación
              </Button>
            )}
            {(isSuccess || isDisabled) && (
              <Button
                variant="outlined"
                color="primary"
                sx={{ ml: 1 }}
                onClick={() => {
                  history.push('/listado-planeacion-academica-posgrado');
                }}
              >
                Regresar
              </Button>
            )}
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default ProgramPeriodDates;
