import {
    //useHistory,
    //Link,
    //useRouteMatch
} from 'react-router-dom';
import {
    Autocomplete,
    //Checkbox,
    Button,
    Grid,
    //Typography,
    TextField,
    /* TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Table,
    CircularProgress,
    FormHelperText,
    Collapse,
    Box,
    useTheme, */
    // FormControlLabel,
} from '@material-ui/core';
//import { useState } from 'react';
import InputLabel from '../../../shared/components/forms/InputLabel';
//import EditButton from '../../../shared/components/tables/buttons/EditButton';
//import DeleteButton from '../../../shared/components/tables/buttons/DeleteButton';
//import AddButton from '../../../shared/components/tables/buttons/AddButton';

//import { openDeleteModalWith } from '../slices/booksSlice';
//import useGuard from '../hooks/useGuard';

//import StatusChip from '../../../shared/components/badges/StatusChip';
import SearchPaginatedTable from '../../../shared/components/tables/SearchPaginatedTable';
//import { useAppDispatch } from '../../../../hooks/useRedux';

const MailingTable = () => {

    //const { hasAccess } = useGuard();
    //const { path } = useRouteMatch();
    //const history = useHistory();

    //const handleDelete = async (periodId: number) => {
    //dispatch(openDeleteModalWith(periodId));
    //};

    /* const goToAdd = () => {
        history.push(`${path}/agregar`);
    }; */
    const data = [
        {
            id: '001',
            name: 'Test Test',
            mail: 'test2022@mail.com.ec',
            //options: ''
        },
    ]

    return (
        <>
            <Grid item xs={12}>
                <Grid container direction="column" gap={2}>
                    <Grid item container spacing={2} alignItems="center">
                        <Grid item xs={12} md={3}>
                            <InputLabel>Fecha</InputLabel>
                            <Autocomplete
                                options={[]}
                                placeholder="Seleccione Titulo..."
                                //getOptionLabel={option => option.name}
                                renderInput={params => (
                                    <TextField {...params}
                                        //error={Boolean(errors.statusId && touched.statusId)} 
                                        placeholder="Seleccione Titulo"
                                    />
                                )}
                                //defaultValue={}
                                onChange={(e, value) => { }
                                    //setFieldValue('statusId', value?.id || '')
                                }
                                fullWidth
                                clearIcon={null}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <InputLabel>Sede</InputLabel>
                            <Autocomplete
                                options={[]}
                                placeholder="Seleccione Sede..."
                                //getOptionLabel={option => option.name}
                                renderInput={params => (
                                    <TextField {...params}
                                        //error={Boolean(errors.statusId && touched.statusId)} 
                                        placeholder="Seleccione Sede"
                                    />
                                )}
                                //defaultValue={}
                                onChange={(e, value) => { }
                                    //setFieldValue('statusId', value?.id || '')
                                }
                                fullWidth
                                clearIcon={null}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <InputLabel>Campaña</InputLabel>
                            <Autocomplete
                                options={[]}
                                placeholder="Seleccione Campaña..."
                                //getOptionLabel={option => option.name}
                                renderInput={params => (
                                    <TextField {...params}
                                        //error={Boolean(errors.statusId && touched.statusId)} 
                                        placeholder="Seleccione Campaña"
                                    />
                                )}
                                //defaultValue={}
                                onChange={(e, value) => { }
                                    //setFieldValue('statusId', value?.id || '')
                                }
                                fullWidth
                                clearIcon={null}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <InputLabel>Servicios</InputLabel>
                            <Autocomplete
                                options={[]}
                                placeholder="Seleccione Servicios..."
                                //getOptionLabel={option => option.name}
                                renderInput={params => (
                                    <TextField {...params}
                                        //error={Boolean(errors.statusId && touched.statusId)} 
                                        placeholder="Seleccione Servicios"
                                    />
                                )}
                                //defaultValue={}
                                onChange={(e, value) => { }
                                    //setFieldValue('statusId', value?.id || '')
                                }
                                fullWidth
                                clearIcon={null}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <InputLabel>Fuente de Contacto</InputLabel>
                            <Autocomplete
                                options={[]}
                                placeholder="Seleccione Fuente de Contacto..."
                                //getOptionLabel={option => option.name}
                                renderInput={params => (
                                    <TextField {...params}
                                        //error={Boolean(errors.statusId && touched.statusId)} 
                                        placeholder="Seleccione Fuente de Contacto"
                                    />
                                )}
                                //defaultValue={}
                                onChange={(e, value) => { }
                                    //setFieldValue('statusId', value?.id || '')
                                }
                                fullWidth
                                clearIcon={null}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <InputLabel>Plantilla</InputLabel>
                            <Autocomplete
                                options={[]}
                                placeholder="Seleccione Plantilla..."
                                //getOptionLabel={option => option.name}
                                renderInput={params => (
                                    <TextField {...params}
                                        //error={Boolean(errors.statusId && touched.statusId)} 
                                        placeholder="Seleccione Plantilla"
                                    />
                                )}
                                //defaultValue={}
                                onChange={(e, value) => { }
                                    //setFieldValue('statusId', value?.id || '')
                                }
                                fullWidth
                                clearIcon={null}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <InputLabel>Modo Envio</InputLabel>
                            <Autocomplete
                                options={[]}
                                placeholder="Seleccione Modo Envio..."
                                //getOptionLabel={option => option.name}
                                renderInput={params => (
                                    <TextField {...params}
                                        //error={Boolean(errors.statusId && touched.statusId)} 
                                        placeholder="Seleccione Modo Envio"
                                    />
                                )}
                                //defaultValue={}
                                onChange={(e, value) => { }
                                    //setFieldValue('statusId', value?.id || '')
                                }
                                fullWidth
                                clearIcon={null}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <InputLabel>Estado Comercial</InputLabel>
                            <Autocomplete
                                options={[]}
                                placeholder="Seleccione Estado Comercial..."
                                //getOptionLabel={option => option.name}
                                renderInput={params => (
                                    <TextField {...params}
                                        //error={Boolean(errors.statusId && touched.statusId)} 
                                        placeholder="Seleccione Estado Comercial"
                                    />
                                )}
                                //defaultValue={}
                                onChange={(e, value) => { }
                                    //setFieldValue('statusId', value?.id || '')
                                }
                                fullWidth
                                clearIcon={null}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputLabel>Asunto</InputLabel>
                            <TextField fullWidth placeholder='Asunto...'></TextField>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                item
                container
                alignItems="center"
                justifyContent="flex-end"
                spacing={2}
                sx={{ mt: 1 }}
            >
                <Grid item>
                    <Button
                        variant="contained"
                        size="large"
                        color="primary"
                        onClick={() => {
                            /* if (filtersSearch) {
                                refetchClassroomData();
                            } else {
                                setDisplayFilters(false);
                            }
                            setFiltersSearch(true); */
                        }}
                    //disabled={!searchBtn}
                    >
                        Visualizar Lista
                    </Button>
                </Grid>
            </Grid>


            <Grid
                container
                spacing={2}
                sx={{ mt: 4 }}
            >
                <Grid
                    item
                    md={12}
                >
                    <SearchPaginatedTable
                        data={data.flatMap(e => [e, e, e])}
                        headers={{
                            id: 'ID',
                            name: 'Nombre',
                            mail: 'Correo',
                            //options: 'Opciones'
                        }}
                        keyExtractor={(item) => item.id.toString()}
                        searchPlacehoder="Buscar Mail"
                        isLoading={false}
                        isFetching={false}
                        error={undefined}
                        perPage={1}
                        setPerPage={() => { }}
                        total={0}
                        page={1}
                        setPage={() => { }}
                        numHeader={7}
                        search={""}
                        setSearch={() => { }}
                        customRenderers={{
                            // id: (row) => row.id.id,

                            //statusId: (row) => <StatusChip statusId={row.statusId} />,

                            /* options: (row) => (
                                <>
                                    {hasAccess('edit') && (
                                        <Link to={`${path}/editar`}>
                                            <EditButton title="Editar" />
                                        </Link>
                                    )}
                                    {hasAccess('delete') && <DeleteButton handleDelete={() => handleDelete(1)} />}
                                    {!hasAccess('edit') && !hasAccess('delete') && 'Ninguna'}
                                </>
                            ), */
                        }}
                    //ActionButtons={<>{hasAccess('create') && <AddButton size="large" onClick={goToAdd} />}</>}
                    />
                </Grid>
            </Grid>

            <Grid
                item
                container
                alignItems="center"
                justifyContent="flex-end"
                spacing={1}
                sx={{ mt: 1 }}
            >
                <Grid item >
                    <Button
                        variant="outlined"
                        size="large"
                        color="primary"
                        onClick={() => {
                            /* if (filtersSearch) {
                                refetchClassroomData();
                            } else {
                                setDisplayFilters(false);
                            }
                            setFiltersSearch(true); */
                        }}
                    //disabled={!searchBtn}
                    >
                        Ver Plantilla
                    </Button>
                </Grid>
                <Grid item >
                    <Button
                        variant="contained"
                        size="large"
                        color="primary"
                        onClick={() => {
                            /* if (filtersSearch) {
                                refetchClassroomData();
                            } else {
                                setDisplayFilters(false);
                            }
                            setFiltersSearch(true); */
                        }}
                    //disabled={!searchBtn}
                    >
                        Generar Proceso
                    </Button>
                </Grid>
            </Grid>
        </>
    )
}

export default MailingTable;