import React from 'react';

import DialogAlert from '../../../../../../shared/components/dialogs/DialogAlert';

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  error: any;
  event: () => void;
}

const ConfirmAlertDialog = ({
  error,
  event,
  isError,
  isLoading,
  isSuccess,
  open,
  setOpen,
}: Props) => {
  const handleConfirm = () => {
    event();
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <DialogAlert
      open={open}
      onClose={handleClose}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      handleAction={handleConfirm}
      customTitleSendButton={'Si, aprobar'}
      title={`Se aprobarán estas solicitudes`}
      error={error as string}
    />
  );
};

export default ConfirmAlertDialog;
