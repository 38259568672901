import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Button,
  DialogActions,
  DialogContent,
  Slide,
  SlideProps,
  Stack,
  Typography,
  Grid,
  TextField,
  FormHelperText,
  CircularProgress,
  Autocomplete,
  Divider,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '../../../../shared/components/forms/InputLabel';
import ErrorAlert from '../../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../../shared/components/alerts/SuccessAlert';
import { InternalHomologationSubjectSchema } from '../../constants/validationSchemas';
import { HomologateInternalSubjects } from '../../interfaces/homologateInternalSubjects.interfaces';

// data available
import { useGetMeshQuery } from '../../slices/meshsApiSlice';
import { useUpdateInternalHomologationSubjectMutation } from '../../../homologations/slices/homologationsApiSlice';
import { useGetAvailablesCareersQuery } from '../../../education-levels/slices/educationLevelsApiSlice';
import {
  useGetInternalHomologationSubjectsbyMeshQuery,
  useGetInfoInternalHomologateSubjectQuery,
} from '../../slices/meshsApiSlice';

// redux
import { useAppSelector } from '../../../../../hooks/useRedux';

import AnimateButton from '../../../../shared/components/extended/AnimateButton';

import { useFormik } from 'formik';
import TextFieldFetching from '../../../../shared/components/TextFieldFetching';
import ExtendedSideDialog from '../../../../shared/components/dialogs/ExtendedSideDialog';

// import * as yup from 'yup';

// const validationSchema = yup.object({
//   certificateTitle: yup.string().required('titulo es requirida'),
//   graduationDate: yup.string().required('Fecha de graduación es requerida'),
//   educationCalification: yup.number().required('calificación es requirida'),
//   educationObservation: yup.string(),
//   document: yup.mixed(),
//   instituteId: yup
//     .object({
//       id: yup.number(),
//       name: yup.string(),
//     })
//     .required('instituto es requerido'),
//   cityId: yup
//     .object({
//       id: yup.number(),
//       name: yup.string(),
//     })
//     .required('ciudad es requerido'),
//   typeEducationId: yup
//     .object({
//       id: yup.number(),
//       name: yup.string(),
//     })
//     .required('educación es requerido'),
//   statusId: yup.number().required('el estado es requirido'),
// });

const useStyles = makeStyles((theme) => ({
  userAddDialog: {
    '&>div:nth-child(3)': {
      justifyContent: 'flex-end',
      '&>div': {
        margin: '0px',
        borderRadius: '0px',
        maxWidth: '450px',
        maxHeight: '100%',
      },
    },
  },
  paper: {
    minHeight: '100vh',
    width: '100%',
  },
  learningComponents: {
    fontSize: '16px',
    fontWeight: 600,
    color: theme.palette.primary.main,
    marginBottom: '8px',
  },
  selectSubject: {
    fontSize: '16px',
    fontWeight: 600,
    color: theme.palette.primary[800],
    marginBottom: '8px',
  },
  selectSubjectType: {
    fontSize: '14px',
    fontWeight: 600,
    color: theme.palette.secondary.main,
    marginBottom: '8px',
  },
}));

const Transition = React.forwardRef(function Transition(props: SlideProps, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

interface Props {
  open: boolean;
  dataSubject: HomologateInternalSubjects;
  handleCloseDialog: () => void;
}

const HomologationInternalSubjectDialog = ({ open, handleCloseDialog, dataSubject }: Props) => {
  const classes = useStyles();
  const [showAlerts, setShowAlerts] = useState(true);
  const { meshId } = useParams<{ meshId: string }>();

  const [autocompletekeys, setAutocompletekeys] = useState<{ meshId: number }>({
    meshId: Math.random(),
  });

  const { currentProfile } = useAppSelector((state) => state.access);

  const profileId = currentProfile?.profileId || -1;

  const { data: infoIntHomologateSubject, isLoading: isLoadingIntHomologateSubject } =
    useGetInfoInternalHomologateSubjectQuery(
      {
        profileId,
        subjectHomologateId: dataSubject.id,
      },
      { skip: !open }
    );

  const { data: availablesSubjectsByMesh, isLoading: isLoadingSubjectsByMesh } = useGetMeshQuery(
    {
      profileId,
      meshId: Number(meshId),
    },
    { skip: !open }
  );

  const { data: availablesCareers, isLoading: isLoadingCareers } = useGetAvailablesCareersQuery(
    {
      profileId,
    },
    { skip: !open }
  );

  const [
    updateInternalHomologationSubject,
    { isLoading: isLoadingAddIHS, isError, isSuccess, error },
  ] = useUpdateInternalHomologationSubjectMutation();

  const { errors, touched, handleSubmit, getFieldProps, setFieldValue, values } = useFormik({
    initialValues: {
      subjectCurriculumCurrentId: dataSubject.subjectOrigin,
      carrerId: '',
      meshId: dataSubject.curriculumDestinyId,
      subjectCurriculumNewId: dataSubject.curriculumCurrentId,
      relation: dataSubject.relation,
      comments: '',
    },
    validationSchema: InternalHomologationSubjectSchema,
    onSubmit: (values) => {
      updateInternalHomologationSubject({
        internalHomologationId: Number(dataSubject.id),
        profileId,
        InternalHomologationSubjectPayload: {
          subject_curriculum_current_id: Number(values.subjectCurriculumCurrentId),
          subject_curriculum_new_id: Number(values.subjectCurriculumNewId),
          comments: values.comments,
          relation: Number(values.relation),
        },
      });
      // resetForm();
    },
  });

  const {
    data: availablesSubjectsNew,
    isLoading: isLoadingSubjectsNew,
    isFetching: isFetchingSubjectsNew,
  } = useGetInternalHomologationSubjectsbyMeshQuery(
    {
      profileId,
      educationLevel: Number(values.carrerId),
      curriculumId: Number(values.meshId),
    },
    { skip: !open || !!!Number(values.carrerId) || !!!Number(values.meshId) }
  );

  // useEffect(() => {
  //   setFieldValue('meshId', '');
  // }, [values.carrerId, setFieldValue]);

  useEffect(() => {
    setFieldValue('subjectCurriculumNewId', infoIntHomologateSubject?.subject_curriculum_new_id);
    setFieldValue('comments', infoIntHomologateSubject?.comments);
    setFieldValue('meshId', infoIntHomologateSubject?.subject_curriculum_new.mesh_id);
    setFieldValue(
      'subjectCurriculumCurrentId',
      infoIntHomologateSubject?.subject_curriculum_current_id
    );
  }, [setFieldValue, infoIntHomologateSubject]);

  useEffect(() => {
    setFieldValue(
      'carrerId',
      availablesCareers?.find((e) => e.id === Number(dataSubject.educationLevelDestinyId))?.id
    );
  }, [availablesCareers, dataSubject, setFieldValue]);

  return (
    <ExtendedSideDialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => {
        handleCloseDialog();
      }}
      className={classes.userAddDialog}
      classes={{
        paper: classes.paper,
      }}
    >
      {/* <PerfectScrollbar> */}
      {!isLoadingIntHomologateSubject ? (
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <Typography variant="h2">Editar materia homologada</Typography>

            {isError && showAlerts && (
              <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
            )}
            {isSuccess && showAlerts && (
              <SuccessAlert
                message="La homologación ha sido actualizada correctamente."
                handleDismiss={() => setShowAlerts(false)}
              />
            )}
            <Stack spacing={2} sx={{ mt: 4 }}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <InputLabel>Materia malla</InputLabel>
                  {isLoadingSubjectsByMesh ? (
                    <>
                      <TextFieldFetching />
                    </>
                  ) : (
                    <>
                      <Autocomplete
                        options={availablesSubjectsByMesh?.matter_mesh || []}
                        loading={isLoadingSubjectsByMesh}
                        loadingText="Cargando materias disponibles..."
                        getOptionLabel={(option) => `${option.matter.mat_name} | ${option.id}`}
                        getOptionDisabled={(option) => !option.can_internal_homologations}
                        defaultValue={
                          availablesSubjectsByMesh?.matter_mesh?.find(
                            (e) =>
                              e.matter_id ===
                              infoIntHomologateSubject?.subject_curriculum_current.matter_id
                          ) || null
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={Boolean(
                              errors.subjectCurriculumCurrentId &&
                                touched.subjectCurriculumCurrentId
                            )}
                          />
                        )}
                        onChange={(e, value) =>
                          setFieldValue('subjectCurriculumCurrentId', value?.matter.id)
                        }
                        fullWidth
                        clearIcon={null}
                      />

                      {errors.subjectCurriculumCurrentId && touched.subjectCurriculumCurrentId && (
                        <FormHelperText error>{errors.subjectCurriculumCurrentId}</FormHelperText>
                      )}
                    </>
                  )}
                </Grid>

                <Grid xs={12} marginY={2}>
                  <Divider />
                </Grid>

                <Grid item xs={12}>
                  <InputLabel>Carrera</InputLabel>
                  {isLoadingCareers ? (
                    <>
                      <TextFieldFetching />
                    </>
                  ) : (
                    <>
                      <Autocomplete
                        options={availablesCareers || []}
                        loading={isLoadingCareers}
                        loadingText="Cargando carreras disponibles..."
                        getOptionLabel={(option) => option.edu_name}
                        // getOptionDisabled={(option) => option.meshs?.length <= 0}
                        defaultValue={availablesCareers?.find(
                          (e) => e.id === Number(dataSubject.educationLevelDestinyId)
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={Boolean(errors.carrerId && touched.carrerId)}
                          />
                        )}
                        onChange={(e, value) => {
                          setAutocompletekeys((prevState) => {
                            return { ...prevState, meshId: Math.random() };
                          });
                          setFieldValue('carrerId', value?.id);
                          setFieldValue('meshId', '');
                          setFieldValue('subjectCurriculumNewId', '');
                        }}
                        fullWidth
                        clearIcon={null}
                      />
                      {errors.carrerId && touched.carrerId && (
                        <FormHelperText error>{errors.carrerId}</FormHelperText>
                      )}
                    </>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <InputLabel>Malla</InputLabel>
                  {isLoadingCareers ? (
                    <>
                      <TextFieldFetching />
                    </>
                  ) : (
                    <>
                      <Autocomplete
                        options={
                          availablesCareers?.find((e) => e.id === Number(values.carrerId))?.meshs ||
                          []
                        }
                        loading={isLoadingCareers}
                        loadingText="Cargando mallas disponibles..."
                        getOptionLabel={(option) => option.mes_name}
                        key={autocompletekeys.meshId}
                        value={
                          availablesCareers
                            ?.find((e) => e.id === Number(dataSubject.educationLevelDestinyId))
                            ?.meshs?.find(
                              (malla) => malla.id === Number(dataSubject.curriculumDestinyId)
                            ) || null
                        }
                        // getOptionDisabled={(option) => option.meshs?.length <= 0}
                        renderInput={(params) => (
                          <TextField {...params} error={Boolean(errors.meshId && touched.meshId)} />
                        )}
                        onChange={(e, value) => setFieldValue('meshId', value?.id)}
                        fullWidth
                        clearIcon={null}
                      />
                      {errors.meshId && touched.meshId && (
                        <FormHelperText error>{errors.meshId}</FormHelperText>
                      )}
                    </>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <InputLabel>Materia</InputLabel>
                  {isLoadingSubjectsNew || isFetchingSubjectsNew ? (
                    <>
                      <TextFieldFetching />
                    </>
                  ) : (
                    <>
                      <Autocomplete
                        options={availablesSubjectsNew || []}
                        loading={isLoadingSubjectsNew || isFetchingSubjectsNew}
                        loadingText="Cargando mallas disponibles..."
                        getOptionLabel={(option) => option.matter_rename}
                        defaultValue={availablesSubjectsNew?.find(
                          (e) =>
                            e.matter_id ===
                            infoIntHomologateSubject?.subject_curriculum_new.matter_id
                        )}
                        // getOptionDisabled={(option) => option.meshs?.length <= 0}
                        noOptionsText="No hya opciones"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={Boolean(
                              errors.subjectCurriculumNewId && touched.subjectCurriculumNewId
                            )}
                          />
                        )}
                        onChange={(e, value) => setFieldValue('subjectCurriculumNewId', value?.id)}
                        fullWidth
                        clearIcon={null}
                      />
                      {errors.subjectCurriculumNewId && touched.subjectCurriculumNewId && (
                        <FormHelperText error>{errors.subjectCurriculumNewId}</FormHelperText>
                      )}
                    </>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <InputLabel>Porcentaje de Relación</InputLabel>
                  <TextField
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ inputProps: { min: 1, max: 100 } }}
                    type="number"
                    placeholder="Ingrese calificación"
                    {...getFieldProps('relation')}
                    error={Boolean(errors.relation && touched.relation)}
                  />
                  {errors.relation && touched.relation && (
                    <FormHelperText error>{errors.relation}</FormHelperText>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <InputLabel>Comentario</InputLabel>
                  <TextField
                    fullWidth
                    placeholder="Ingrese un comentario"
                    defaultValue={infoIntHomologateSubject?.comments}
                    {...getFieldProps('comments')}
                    error={Boolean(errors.comments && touched.comments)}
                  />
                  {errors.comments && touched.comments && (
                    <FormHelperText error>{errors.comments}</FormHelperText>
                  )}
                </Grid>

                {/* end */}
              </Grid>
            </Stack>
          </DialogContent>
          {/* </PerfectScrollbar> */}
          <DialogActions>
            <Stack direction="row" spacing={2}>
              <Button
                variant="text"
                onClick={() => {
                  // dispatch(resetCurrentGroup());
                  // dispatch(resetSubjectCurriculumSelected());
                  handleCloseDialog();
                }}
                color="primary"
              >
                Cancelar
              </Button>

              <AnimateButton>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isLoadingAddIHS}
                  endIcon={isLoadingAddIHS && <CircularProgress size={20} />}
                >
                  Actualizar
                </Button>
              </AnimateButton>
            </Stack>
          </DialogActions>
        </form>
      ) : (
        <Grid container justifyContent="center" alignItems="center" height={'100vh'}>
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      )}
    </ExtendedSideDialog>
  );
};

export default HomologationInternalSubjectDialog;
