import { useEffect, useState } from 'react';
import { GoogleMap, withScriptjs, withGoogleMap, GoogleMapProps, Marker } from 'react-google-maps';

interface MapProps extends GoogleMapProps {
  latitude: string;
  longitude: string;
  handleChangePosition?: (lat: number, lng: number) => void;
}

const Map = ({ latitude, longitude, handleChangePosition }: MapProps) => {
  const [pointer, setPointer] = useState({
    lat: parseFloat(latitude),
    lng: parseFloat(longitude),
  });

  useEffect(() => {
    setPointer({
      lat: parseFloat(latitude),
      lng: parseFloat(longitude),
    });
  }, [latitude, longitude]);

  return (
    <GoogleMap
      defaultZoom={15}
      defaultCenter={{ lat: pointer.lat, lng: pointer.lng }}
      center={{
        lat: pointer.lat,
        lng: pointer.lng,
      }}
    >
      <Marker
        position={{ lat: pointer.lat, lng: pointer.lng }}
        draggable={Boolean(handleChangePosition)}
        onDragEnd={(e) => {
          if (handleChangePosition) {
            let lat = e.latLng?.lat();
            let lng = e.latLng?.lng();

            handleChangePosition(lat, lng);
          }
        }}
      ></Marker>
    </GoogleMap>
  );
};

export default withScriptjs(withGoogleMap(Map));
