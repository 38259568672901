import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../hooks/useRedux';
import MainCard from '../../../shared/components/cards/MainCard';
import ClassificationsTable from '../components/ClassificationsTable';
import DeleteClassificationDialog from '../components/DeleteClassificationDialog';

const ClasificationsScreen = () => {
  const { t } = useTranslation();
  const { classificationToDelete } = useAppSelector((state) => state.classifications);
  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">{t('classifications.title')}</Typography>
      </Grid>

      <ClassificationsTable />

      {classificationToDelete && <DeleteClassificationDialog />}
    </Grid>
  );
};

export default ClasificationsScreen;
