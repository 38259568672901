import { DataForm } from './AddHoursSummariesForm';
import { TextField } from 'formik-material-ui';
import { useEffect } from 'react';
import { Field } from 'formik';

interface Props {
  values: DataForm;
  index: number;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}

const CalculatedField = ({ values, index, setFieldValue }: Props) => {

  useEffect(() => {
    let val = 0;
    if (values)
      val =
        values.hourSummary[index].hs_academic_management +
        values.hourSummary[index].hs_bonding +
        values.hourSummary[index].hs_classes +
        values.hourSummary[index].hs_classes_examns_preparation +
        values.hourSummary[index].hs_research +
        values.hourSummary[index].hs_tutoring;
    setFieldValue(`hourSummary[${index}].total`, val);
  }, [values, index, setFieldValue]);

  return (
    <Field
      component={TextField}
      type="number"
      name={`hourSummary[${index}].total`}
      value={values.hourSummary[index].total}
      disabled={true}
    />
  );
};

export default CalculatedField;
