import {
  Autocomplete,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useAppSelector } from '../../../../hooks/useRedux';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import { statusAvailables } from '../../../shared/constants/resourceStatus';
import { useFormStyles } from '../constants/styles';
import { CalificationModelInfoSchema } from '../constants/validationSchemas';
import { useUpdateCalificationModelMutation } from '../slices/calificationModelsApiSlice';

const EditCalificationModelForm = () => {
  const history = useHistory();
  const { calificationModelId } = useParams<{ calificationModelId: string }>();

  const { calificationModelsList } = useAppSelector((state) => state.calificationModel);
  const { currentProfile } = useAppSelector((state) => state.access);

  const classes = useFormStyles();

  const [showAlerts, setShowAlerts] = useState(false);

  const [updateCalificationModel, { isLoading: isUpdating, isSuccess, isError, error }] =
    useUpdateCalificationModelMutation();

  const calificationModelToEdit = calificationModelsList.find(
    (p) => p.id === Number(calificationModelId)
  );
  const { getFieldProps, errors, touched, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      name: calificationModelToEdit?.cal_mod_name || '',
      acronym: calificationModelToEdit?.cal_mod_acronym || '',
      statusId: calificationModelToEdit?.status_id || 1,
    },
    validationSchema: CalificationModelInfoSchema,
    onSubmit: async (values) => {
     // values.equivalence = checked.checkedEquivalence ? 1 : 0;
      await updateCalificationModel({
        profileId: currentProfile?.profileId!,
        calificationModelId: calificationModelToEdit?.id!,
        calificationModelPayload: {
          cal_mod_name: values.name,
          cal_mod_acronym: values.acronym,
          status_id: values.statusId.toString(),
        },
      });

      setShowAlerts(true);
    },
  });

  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction="column" gap={2}>
        <Grid item>
          {isError && showAlerts && (
            <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
          )}

          {isSuccess && showAlerts && (
            <SuccessAlert
              message="El modelo de calificación ha sido actualizado correctamente."
              handleDismiss={() => setShowAlerts(false)}
            />
          )}
        </Grid>

        <Grid item container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>Nombre</InputLabel>
            <TextField
              fullWidth
              error={Boolean(errors.name && touched.name)}
              placeholder="Ingrese el nombre del modelo de calificación"
              {...getFieldProps('name')}
            />
            {errors.name && touched.name && <FormHelperText error>{errors.name}</FormHelperText>}
          </Grid>
          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>Acrónimo</InputLabel>
            <TextField
              fullWidth
              error={Boolean(errors.acronym && touched.acronym)}
              placeholder="Ingrese el acrónimo del modelo de calificación"
              {...getFieldProps('acronym')}
            />
            {errors.acronym && touched.acronym && (
              <FormHelperText error>{errors.acronym}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>Estado</InputLabel>
            <Autocomplete
              options={statusAvailables}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField {...params} error={Boolean(errors.statusId && touched.statusId)} />
              )}
              defaultValue={statusAvailables[0]}
              onChange={(e, value) => setFieldValue('statusId', value?.id || '')}
              fullWidth
              clearIcon={null}
            />
            {errors.statusId && touched.statusId && (
              <FormHelperText error>{errors.statusId}</FormHelperText>
            )}
          </Grid>
        </Grid>

        <Grid
          item
          container
          alignItems="center"
          justifyContent="flex-end"
          spacing={2}
          sx={{ mt: 1 }}
        >
          <Grid item>
            <Button
              variant="outlined"
              size="large"
              color="primary"
              onClick={() => history.goBack()}
            >
              Regresar
            </Button>
          </Grid>
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              disabled={isUpdating}
              endIcon={isUpdating && <CircularProgress size={20} />}
            >
              Actualizar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default EditCalificationModelForm;
