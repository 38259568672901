import {
  Autocomplete,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  Typography,
  TextField,
  IconButton,
  Alert,
  CircularProgress,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useAppSelector } from '../../../../../hooks/useRedux';
import * as yup from 'yup';
import FormAddIdiom from '../../../books/components/mini-forms/FormAddIdiom';
import FormAddEditorial from '../../../books/components/mini-forms/FormAddEditorial';
import FormAddCity from '../../../books/components/mini-forms/FormAddCity';
import { FileUpload } from '@material-ui/icons';
import { statusAvailables } from '../../../../shared/constants/resourceStatus';
import { DatePicker, LocalizationProvider } from '@material-ui/lab';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import esLocale from 'date-fns/locale/es';
import { useGetSubjectsClassificationsQuery } from '../../../books/slices/booskApiSlice';
import { useGetInvestigationLinesAllAvailablesQuery } from '../../../../maintenance/lines-of-investigation/slices/InvestigationLinesApiSlice';
import { IconPlus } from '@tabler/icons';
//import { typesDocuments } from '../../../books/constants/typesDocuments';
import { useGetAllTagsByTypeQuery } from '../../../tags/slices/tagsApiSlice';
import { useGetAllShelfsQuery } from '../../../shelfs/slices/shelfsApiSlice';
import { useGetAllCollaboratorsQuery } from '../../../../hr/collaborators/slices/collaboratorsApiSlice';
import { useAddThesisMutation, useEditThesisMutation } from '../../slices/thesisApiSlice';
import { uploadFilesApi } from '../../../../shared/slices/uploadFilesApi';
import { format } from 'date-fns';
import ErrorAlert from '../../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../../shared/components/alerts/SuccessAlert';
import { isBefore } from 'date-fns';
import { useHistory } from 'react-router-dom';
interface props {
  isActive: boolean;
  handleBack: () => void;
}

const Step2 = ({ isActive, handleBack }: props) => {
  const { currentProfile } = useAppSelector((state) => state.access);
  const { thesisToEdit } = useAppSelector((state) => state.thesis);
  const [updatAutocomplete] = useState(false);
  const [showFormCity, setShowFormCity] = useState(false);
  const [showFormEditorial, setShowFormEditorial] = useState(false);
  const [showFormIdiom, setShowFormIdiom] = useState(false);
  const { studentId } = useAppSelector((state) => state.thesis);

  const history = useHistory();

  const {
    data: cities,
    isLoading: isLoadingCities,
    isFetching: isFetchingCities,
    refetch: refetchCities,
  } = useGetAllTagsByTypeQuery({
    type_tag: 'Ciudad',
  });

  const {
    data: editorials,
    isLoading: isLoadingEditorials,
    isFetching: isFetchingEditorials,
    refetch: refetchEditorials,
  } = useGetAllTagsByTypeQuery({
    type_tag: 'Editorial',
  });

  const {
    data: idioms,
    isLoading: isLoadingIdioms,
    isFetching: isFetchingIdioms,
    refetch: refetchIdioms,
  } = useGetAllTagsByTypeQuery({
    type_tag: 'Idioma',
  });

  // const origins = [
  //   { value: 'Fisico', label: 'Físico' },
  //   { value: 'Digital', label: 'Digital' },
  //   { value: 'Fisico y Digital', label: 'Físico y Digital' },
  // ];

  const { data: classifications, isLoading: isLoadingClassifications } =
    useGetSubjectsClassificationsQuery({
      user_profile_id: currentProfile?.profileId!,
    });

  const { data: shelfs, isLoading: isLoadingShelfs } = useGetAllShelfsQuery();

  const { data: collaborators, isLoading: isLoadingCollaborators } = useGetAllCollaboratorsQuery({
    user_profile_id: 1,
  });

  const classificationsOptions =
    classifications
      ?.filter((cl) => !Boolean(cl.parent_id))
      .map((cl) => ({
        label: `${cl.code} - ${cl.name}`,
        value: String(cl.id),
        code: cl.code,
      })) || [];

  const { data: lines, isLoading: isLoadingLines } = useGetInvestigationLinesAllAvailablesQuery({
    profileId: currentProfile?.profileId!,
  });

  const [uploadFile, { isLoading: isLoadingUpload }] = uploadFilesApi.useUploadFilesMutation();

  const [
    addThesis,
    {
      isLoading: isLoadingAdd,
      isError: isErrorAdd,
      error: errorAdd,
      isSuccess: isSuccessAdd,
      reset,
    },
  ] = useAddThesisMutation();

  const [
    editThesis,
    {
      isLoading: isLoadingEdit,
      isError: isErrorEdit,
      error: errorEdit,
      isSuccess: isSuccessEdit,
      reset: resetEdit,
    },
  ] = useEditThesisMutation();

  const schema = yup.object().shape({
    isEdit: yup.boolean(),
    title: yup.string().required('Título es requerido'),
    subtitle: yup.string(),
    city: yup.string().required('Ciudad es requerida'),
    editorial: yup.string(),
    // .required('Editorial es requerida'),
    idiom: yup.string().required('Idioma es requerida'),
    codebar: yup.string(),
    publish_date: yup.string().required('Fecha de publicación es requerida'),
    status: yup.string().required('Estado es requerido'),
    file: yup.array().min(1, 'Archivo es requerido').required(),
    classification: yup.string().required('Materia es requerida'),
    classification_child: yup.string().required('Clasificación es requerida'),
    line: yup.string().required('Linea de Investigación es requerida'),
    description: yup.string(),
    location: yup.string().required('Ubicación es requerida'),
    collaborator: yup.string().required('Director es requerido'),
    symbol: yup.string(),
  });

  const { handleSubmit, setFieldValue, values, errors, touched } = useFormik({
    initialValues: {
      isEdit: false,
      title: thesisToEdit?.title || '',
      subtitle: thesisToEdit?.subtitle || '',
      city: thesisToEdit?.tags.find((tg) => tg.type_tag === 'Ciudad')?.id || '',
      editorial: thesisToEdit?.tags.find((tg) => tg.type_tag === 'Editorial')?.id || '',
      idiom: thesisToEdit?.tags.find((tg) => tg.type_tag === 'Idioma')?.id || '',
      isbn: thesisToEdit?.isbn || '',
      version: thesisToEdit?.version || '',
      codebar: thesisToEdit?.institution_barcode || '',
      publish_date: thesisToEdit?.publish_date || '',
      status: thesisToEdit?.status_id || '',
      file: new Array<File>(),
      classification: thesisToEdit?.clasification?.parent_id || '',
      classification_child: thesisToEdit?.clasification?.id || '',
      line: thesisToEdit?.line_research_id || '',
      serie: thesisToEdit?.serie || '',
      content_notes: thesisToEdit?.content_notes || '',
      description: thesisToEdit?.description || '',
      location: thesisToEdit?.locations[0].id || '',
      collaborator: thesisToEdit?.collaborator[0].id || '',
      symbol: thesisToEdit?.symbol || '',
      subject_code: '',
      classification_code: '',
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      //await dispatch(setBodyForm(values));
      //alert(JSON.stringify(values));

      const _file = new File([], 'undefined');
      let book_file: string | null;

      const res = await uploadFile({
        files: values.file ? values.file[0] : _file,
      });
      if (res && 'error' in res) {
        return;
      }
      book_file = res?.data;

      if (thesisToEdit) {
        await editThesis({
          id: thesisToEdit.id,
          payload: {
            title: values.title,
            subtitle: values.subtitle,
            isbn: values.isbn,
            version: values.version,
            initial_copies_number: 1,
            origin: 'Digital',
            book_cover: null,
            book_url: book_file,
            bib_clasification_id: values.classification_child,
            line_research_id: values.line,
            collaborator_id: Number(values.collaborator),
            description: values.description,
            institution_barcode: values.codebar,
            locations: [Number(values.location)],
            procedencia: '',
            publish_date: format(new Date(values.publish_date), 'yyyy-MM-dd'),
            reason: 'Compra',
            status_id: Number(values.status),
            student_record_id: studentId!,
            symbol: values.symbol,
            tags: [Number(values.city), Number(values.editorial), Number(values.idiom)]?.filter(
              (tag) => tag !== 0
            ),
            type_book: 'Tesis',
          },
        });
        return;
      }

      await addThesis({
        payload: {
          title: values.title,
          subtitle: values.subtitle,
          isbn: values.isbn,
          version: values.version,
          initial_copies_number: 1,
          origin: 'Digital',
          book_cover: null,
          book_url: book_file,
          bib_clasification_id: values.classification_child,
          line_research_id: values.line,
          collaborator_id: Number(values.collaborator),
          description: values.description,
          institution_barcode: values.codebar,
          locations: [Number(values.location)],
          procedencia: '',
          publish_date: format(new Date(values.publish_date), 'yyyy-MM-dd'),
          reason: 'Compra',
          status_id: Number(values.status),
          student_record_id: studentId!,
          symbol: values.symbol,
          tags: [Number(values.city), Number(values.editorial), Number(values.idiom)]?.filter(
            (tag) => tag !== 0
          ),
          type_book: 'Tesis',
        },
      });
    },
  });

  // useEffect(() => {
  //   setUpdateAutocomplete(true);
  //   setTimeout(() => {
  //     setUpdateAutocomplete(false);
  //   }, 50);
  //   //eslint-disable-next-line
  // }, [values.classification_child]);

  const handleFormSubmit = () => {
    handleSubmit();
  };

  const citiesOptions =
    cities && cities.length > 0
      ? cities.map((ct) => ({
          label: ct.name,
          value: ct.id,
        }))
      : [];

  const editorialsOptions =
    editorials && editorials.length > 0
      ? editorials.map((ct) => ({
          label: ct.name,
          value: ct.id,
        }))
      : [];

  const idiomsOptions =
    idioms && idioms.length > 0
      ? idioms.map((ct) => ({
          label: ct.name,
          value: ct.id,
        }))
      : [];

  if (isActive === false) {
    return <div />;
  }

  const changeBarcode = (subjectCode: string, classificationCode: string) => {
    const codebar = `${subjectCode || ''}${classificationCode || ''}`;
    setFieldValue('codebar', codebar);
  };

  return (
    <>
      <Grid item xs={12} md={6}>
        <InputLabel>Título</InputLabel>
        <TextField
          fullWidth
          placeholder="Ingrese Título"
          value={values.title}
          error={Boolean(errors.title && touched.title)}
          helperText={Boolean(errors.title && touched.title) ? errors.title : ''}
          onChange={({ target }) => setFieldValue('title', target.value.toUpperCase())}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <InputLabel>Subtítulo</InputLabel>
        <TextField
          fullWidth
          placeholder="Ingrese Subtítulo"
          value={values.subtitle}
          error={Boolean(errors.subtitle && touched.subtitle)}
          helperText={Boolean(errors.subtitle && touched.subtitle) ? errors.subtitle : ''}
          onChange={({ target }) => setFieldValue('subtitle', target.value.toUpperCase())}
        />
      </Grid>

      <Grid item container xs={12} md={6}>
        <Grid item xs={6} md={6} lg={6}>
          <InputLabel>Ciudad</InputLabel>
        </Grid>
        <Grid item xs={6} md={6} lg={6} sx={{ textAlign: 'right' }}>
          <Button
            size="small"
            endIcon={<IconPlus size={14} />}
            onClick={() => {
              setShowFormCity(true);
            }}
          >
            Agregar
          </Button>
        </Grid>
        {isLoadingCities || isFetchingCities ? (
          <TextField placeholder="Cargando..." fullWidth />
        ) : (
          <Autocomplete
            options={citiesOptions}
            getOptionLabel={(option) => option.label}
            getOptionSelected={(option) => Number(values.city) === option.value}
            value={citiesOptions.find((ct) => ct.value === Number(values.city))}
            fullWidth
            clearIcon={null}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Seleccione Ciudad..."
                error={Boolean(errors.city && touched.city)}
                helperText={Boolean(errors.city && touched.city) ? errors.city : ''}
              />
            )}
            onChange={(_, value) => {
              if (value) {
                setFieldValue('city', value.value);
              }
            }}
          />
        )}
      </Grid>

      {false && (
        <Grid item container xs={12} md={6}>
          <Grid item xs={6} md={6} lg={6}>
            <InputLabel>Editorial</InputLabel>
          </Grid>
          <Grid item xs={6} md={6} lg={6} sx={{ textAlign: 'right' }}>
            <Button
              size="small"
              endIcon={<IconPlus size={14} />}
              onClick={() => {
                setShowFormEditorial(true);
              }}
            >
              Agregar
            </Button>
          </Grid>
          {isLoadingEditorials || isFetchingEditorials ? (
            <TextField placeholder="Cargando..." fullWidth />
          ) : (
            <Autocomplete
              options={editorialsOptions}
              getOptionLabel={(option) => option.label}
              getOptionSelected={(option) => Number(values.editorial) === option.value}
              value={editorialsOptions.find((ed) => ed.value === Number(values.editorial))}
              fullWidth
              clearIcon={null}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Seleccione Editorial..."
                  error={Boolean(errors.editorial && touched.editorial)}
                  helperText={
                    Boolean(errors.editorial && touched.editorial) ? errors.editorial : ''
                  }
                />
              )}
              onChange={(_, value) => {
                if (value) {
                  setFieldValue('editorial', value.value);
                }
              }}
            />
          )}
        </Grid>
      )}

      <Grid item container xs={12} md={6}>
        <Grid item xs={6} md={6} lg={6}>
          <InputLabel>Idioma</InputLabel>
        </Grid>
        <Grid item xs={6} md={6} lg={6} sx={{ textAlign: 'right' }}>
          <Button
            size="small"
            endIcon={<IconPlus size={14} />}
            onClick={() => {
              setShowFormIdiom(true);
            }}
          >
            Agregar
          </Button>
        </Grid>
        {isLoadingIdioms || isFetchingIdioms ? (
          <TextField placeholder="Cargando..." fullWidth />
        ) : (
          <Autocomplete
            options={idiomsOptions}
            getOptionLabel={(option) => option.label}
            getOptionSelected={(option) => Number(values.idiom) === option.value}
            value={idiomsOptions.find((id) => id.value === Number(values.idiom))}
            fullWidth
            clearIcon={null}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Seleccione Idioma..."
                error={Boolean(errors.idiom && touched.idiom)}
                helperText={Boolean(errors.idiom && touched.idiom) ? errors.idiom : ''}
              />
            )}
            onChange={(_, value) => {
              if (value) {
                setFieldValue('idiom', value.value);
              }
            }}
          />
        )}
      </Grid>

      {/* <Grid item xs={12} md={6}>
        <InputLabel>Tipo de Documento</InputLabel>
        <Autocomplete
          options={typesDocuments}
          getOptionLabel={(option) => option.label}
          getOptionSelected={(option) => values.type_document === option.value}
          value={typesDocuments.find((doc) => doc.value === values.type_document)}
          fullWidth
          clearIcon={null}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Seleccione Tipo..."
              error={Boolean(errors.type_document && touched.type_document)}
              helperText={
                Boolean(errors.type_document && touched.type_document)
                  ? errors.type_document
                  : ''
              }
            />
          )}
          onChange={(_, value) => {
            if (value) {
              setFieldValue('type_document', value.value);
            }
          }}
        />
      </Grid> */}

      {/* <Grid item xs={12} md={6}>
        <InputLabel>Origen</InputLabel>
        <Autocomplete
          options={origins}
          getOptionLabel={(opt) => opt.label}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Seleccione Origen..."
              error={Boolean(errors.origin && touched.origin)}
              helperText={
                Boolean(errors.origin && touched.origin) ? errors.origin : ''
              }
            />
          )}
          getOptionSelected={(opt) => values.origin === opt.value}
          value={origins.find((or) => or.value === values.origin)}
          onChange={(e, value) => {
            if (value) {
              setFieldValue('origin', value.value);
            }
          }}
          fullWidth
          clearIcon={null}
        />
      </Grid> */}

      <Grid item xs={12} md={6}>
        <InputLabel>Fecha de Publicación</InputLabel>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
          <DatePicker
            value={values.publish_date}
            onChange={(newValue) => {
              setFieldValue('publish_date', newValue);
            }}
            shouldDisableDate={(date) => isBefore(new Date(), new Date(date))}
            renderInput={(params) => (
              <TextField
                {...params}
                error={Boolean(errors.publish_date && touched.publish_date)}
                helperText={
                  Boolean(errors.publish_date && touched.publish_date) ? errors.publish_date : ''
                }
                placeholder="Ingrese fecha de publicación"
                fullWidth
              />
            )}
          />
        </LocalizationProvider>
      </Grid>

      {/* <Grid item xs={12} md={6}>
        <InputLabel>Número de Paginas</InputLabel>
        <TextField
          fullWidth
          placeholder="Ingrese Número de paginas"
          value={values.num_pag}
          error={Boolean(errors.num_pag && touched.num_pag)}
          helperText={
            Boolean(errors.num_pag && touched.num_pag) ? errors.num_pag : ''
          }
          onChange={({ target }) => setFieldValue('num_pag', target.value)}
        />
      </Grid> */}

      <Grid item xs={12} md={6}>
        <InputLabel>Descripción</InputLabel>
        <TextField
          fullWidth
          placeholder="Ingrese Descripción"
          value={values.description}
          error={Boolean(errors.description && touched.description)}
          helperText={Boolean(errors.description && touched.description) ? errors.description : ''}
          onChange={({ target }) => setFieldValue('description', target.value)}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <InputLabel>Simbolo</InputLabel>
        <TextField
          fullWidth
          placeholder="Ingrese Simbolo"
          value={values.symbol}
          error={Boolean(errors.symbol && touched.symbol)}
          helperText={Boolean(errors.symbol && touched.symbol) ? errors.symbol : ''}
          onChange={({ target }) => setFieldValue('symbol', target.value)}
        />
      </Grid>

      <Grid item container xs={12} md={6}>
        <InputLabel>Materia</InputLabel>

        {isLoadingClassifications ? (
          <TextField placeholder="Cargando..." fullWidth />
        ) : (
          <Autocomplete
            options={classificationsOptions}
            getOptionLabel={(option) => option.label}
            value={classificationsOptions.find((el) => el.value === values.classification)}
            getOptionSelected={(option) => values.classification === option.value}
            //defaultValue={[]}
            fullWidth
            clearIcon={null}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Seleccione Materia..."
                error={Boolean(errors.classification && touched.classification)}
                helperText={
                  Boolean(errors.classification && touched.classification)
                    ? errors.classification
                    : ''
                }
              />
            )}
            onChange={async (_, value) => {
              if (value) {
                await setFieldValue('classification', value.value);
                await setFieldValue('subject_code', value.code);
              }
              await setFieldValue('classification_child', '');
              await setFieldValue('classification_code', '');
              changeBarcode(value?.code || '', values.classification_code);
            }}
          />
        )}
      </Grid>

      <Grid item container xs={12} md={6}>
        <InputLabel>Clasificación</InputLabel>

        {isLoadingClassifications || updatAutocomplete ? (
          <TextField placeholder="Cargando..." fullWidth />
        ) : (
          <Autocomplete
            options={
              values.classification !== ''
                ? classifications
                    ?.find((cl) => cl.id === values.classification)
                    ?.child?.map((ch) => ({
                      value: ch.id,
                      label: ch.name,
                      code: ch.code,
                    })) || []
                : []
            }
            getOptionLabel={(option) => `${option.code}-${option.label}`}
            getOptionSelected={(option) => values.classification_child === option.value}
            value={
              values.classification !== ''
                ? classifications
                    ?.find((cl) => cl.id === values.classification)
                    ?.child?.map((ch) => ({
                      value: ch.id,
                      label: ch.name,
                      code: ch.code,
                    }))
                    .find((ch) => ch.value === values.classification_child)
                : undefined
            }
            fullWidth
            clearIcon={null}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Seleccione Clasificación..."
                error={Boolean(errors.classification_child && touched.classification_child)}
                helperText={
                  Boolean(errors.classification_child && touched.classification_child)
                    ? errors.classification_child
                    : ''
                }
              />
            )}
            onChange={async (_, value) => {
              if (value) {
                await setFieldValue('classification_child', value.value);
                await setFieldValue('classification_code', value.code);
              }

              changeBarcode(values.subject_code, value?.code || '');
            }}
          />
        )}
      </Grid>

      <Grid item container xs={12} md={6}>
        <InputLabel>Lineas de Investigación</InputLabel>

        {isLoadingLines ? (
          <TextField placeholder="Cargando..." fullWidth />
        ) : (
          <Autocomplete
            options={
              lines?.map((ln) => ({
                value: ln.id,
                label: ln.name,
              })) || []
            }
            getOptionLabel={(option) => option.label}
            getOptionSelected={(option) => values.line === String(option.value)}
            value={
              lines
                ?.map((ln) => ({
                  value: ln.id,
                  label: ln.name,
                }))
                .find((ln) => String(ln.value) === values.line) || undefined
            }
            fullWidth
            clearIcon={null}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Seleccione Linea de Investigación..."
                error={Boolean(errors.line && touched.line)}
                helperText={Boolean(errors.line && touched.line) ? errors.line : ''}
              />
            )}
            onChange={(_, value) => {
              if (value) {
                setFieldValue('line', value.value);
              }
            }}
          />
        )}
      </Grid>

      <Grid item xs={12} md={6}>
        <InputLabel>Código de Barra de Institución</InputLabel>
        <TextField
          fullWidth
          placeholder="Ingrese Código de Barra"
          value={values.codebar}
          error={Boolean(errors.codebar && touched.codebar)}
          helperText={Boolean(errors.codebar && touched.codebar) ? errors.codebar : ''}
          disabled
          onChange={({ target }) => setFieldValue('codebar', target.value)}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <InputLabel>Ubicación</InputLabel>
        {isLoadingShelfs ? (
          <TextField placeholder="Cargando..." fullWidth />
        ) : (
          <Autocomplete
            options={
              shelfs?.map((sh) => ({
                label: sh.description,
                value: sh.id,
              })) || []
            }
            getOptionLabel={(option) => option.label}
            getOptionSelected={(opt) => Number(values.location) === opt.value}
            renderInput={(params) => (
              <TextField
                {...params}
                //error={Boolean(errors.statusId && touched.statusId)}
                placeholder="Seleccione Ubicación..."
                error={Boolean(errors.location && touched.location)}
                helperText={Boolean(errors.location && touched.location) ? errors.location : ''}
              />
            )}
            // defaultValue={shelfs
            //   ?.filter((sh) => values.location.split(',').includes(sh.location_type))
            //   .map((sh) => ({ value: sh.id, label: sh.description }))}
            value={shelfs
              ?.map((sh) => ({
                label: sh.description,
                value: sh.id,
              }))
              .find((sh) => sh.value === Number(values.location))}
            onChange={(e, value) => {
              // setFieldValue('location', value.map((vl) => String(vl.value)).join(','));
              if (value) {
                setFieldValue('location', value.value);
              }
            }}
            fullWidth
            clearIcon={null}
          />
        )}
      </Grid>

      <Grid item xs={12} md={6}>
        <InputLabel>Director/Tutor</InputLabel>
        {isLoadingCollaborators ? (
          <TextField placeholder="Cargando..." fullWidth />
        ) : (
          <Autocomplete
            options={
              collaborators?.data.map((sh) => ({
                label: sh.user.person.pers_full_name || '',
                value: sh.id,
              })) || []
              // collaborators?.data.map((sh) => ({
              //   label: sh.user.person.pers_full_name || '',
              //   value: sh.id,
              // })) || []
            }
            getOptionLabel={(option) => option.label}
            getOptionSelected={(opt) => Number(values.location) === opt.value}
            renderInput={(params) => (
              <TextField
                {...params}
                //error={Boolean(errors.statusId && touched.statusId)}
                placeholder="Seleccione Tutor..."
                error={Boolean(errors.collaborator && touched.collaborator)}
                helperText={
                  Boolean(errors.collaborator && touched.collaborator) ? errors.collaborator : ''
                }
              />
            )}
            // defaultValue={shelfs
            //   ?.filter((sh) => values.location.split(',').includes(sh.location_type))
            //   .map((sh) => ({ value: sh.id, label: sh.description }))}
            value={collaborators?.data
              .map((sh) => ({
                label: sh.user.person.pers_full_name || '',
                value: sh.id,
              }))
              .find((t) => t.value === Number(values.collaborator))}
            onChange={(e, value) => {
              // setFieldValue('location', value.map((vl) => String(vl.value)).join(','));
              if (value) {
                setFieldValue('collaborator', value.value);
              }
            }}
            fullWidth
            clearIcon={null}
          />
        )}
      </Grid>

      <Grid item xs={12} md={6} sx={{ alignSelf: 'baseline' }}>
        <InputLabel>Estado</InputLabel>
        <Autocomplete
          options={statusAvailables.map((st) => ({ value: st.id, label: st.name }))}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Seleccione Estado..."
              error={Boolean(errors.status && touched.status)}
              helperText={Boolean(errors.status && touched.status) ? errors.status : ''}
            />
          )}
          value={statusAvailables
            .map((st) => ({ value: st.id, label: st.name }))
            .find((st) => st.value === Number(values.status))}
          onChange={(e, value) => {
            setFieldValue('status', value?.value);
          }}
          fullWidth
          clearIcon={null}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <InputLabel>Archivo</InputLabel>
        <IconButton
          color="primary"
          aria-label="upload picture"
          component="label"
          size="medium"
          sx={{ borderRadius: '10px', border: 1, borderColor: 'cyan', width: '100%' }}
        >
          <input
            hidden
            // accept="application/pdf,application/msword"
            accept="application/pdf"
            type="file"
            onChange={(e) => {
              setFieldValue('file', [e!.target!.files![0]]);
            }}
          />
          <>
            {values.file.length > 0 ? (
              <Typography>&nbsp;{values!.file![0].name}</Typography>
            ) : (
              <>
                <FileUpload />
                <Typography>&nbsp;Subir Archivo...</Typography>
              </>
            )}
          </>
        </IconButton>
        {Boolean(errors.file && touched.file) && (
          <Typography variant="caption" color="red">
            &nbsp;&nbsp; {errors.file}
          </Typography>
        )}
        <Alert severity="info" sx={{ mt: 1 }}>
          <ul>
            <li>El tamaño máximo permitido es 5 MB.</li>
            <li>Solo se permiten archivos PDF.</li>
          </ul>
        </Alert>
      </Grid>

      <Grid item xs={12} md={12}>
        {isErrorAdd && <ErrorAlert message={String(errorAdd)} handleDismiss={reset} />}
        {isErrorEdit && <ErrorAlert message={String(errorEdit)} handleDismiss={resetEdit} />}
        {isSuccessAdd && (
          <SuccessAlert message="Tesis creada correctamente" handleDismiss={reset} />
        )}
        {isSuccessEdit && (
          <SuccessAlert message="Tesis editada correctamente" handleDismiss={resetEdit} />
        )}
      </Grid>

      <Grid item container xs={12} md={12} justifyContent="flex-end">
        <Button variant="outlined" disabled={isSuccessAdd} onClick={handleBack} size="large">
          Regresar
        </Button>{' '}
        &nbsp;&nbsp;
        <Button
          variant="contained"
          disabled={
            isLoadingUpload || isLoadingAdd || isSuccessAdd || isLoadingEdit || isSuccessEdit
          }
          onClick={handleFormSubmit}
          size="large"
          endIcon={
            (isLoadingUpload || isLoadingEdit || isLoadingAdd) && <CircularProgress size={20} />
          }
        >
          {thesisToEdit ? 'Editar' : 'Agregar'}
        </Button>
        &nbsp;&nbsp;
        {isSuccessAdd && (
          <Button variant="contained" onClick={() => history.goBack()} size="large">
            Volver a lista de tesis
          </Button>
        )}
      </Grid>
      {/* 
      /**
       * DIALOGS
       * FORMS AUTHOR, CITY, IDIOM
       **
      */}

      <Dialog
        onClose={() => {
          setShowFormCity(false);
        }}
        open={showFormCity}
      >
        <DialogTitle>
          <Typography variant="h3" sx={{ textAlign: 'center' }}>
            Agregar Ciudad
          </Typography>
        </DialogTitle>
        <DialogContent>
          <FormAddCity onUpdate={refetchCities} handleClose={() => setShowFormCity(false)} />
        </DialogContent>
      </Dialog>

      <Dialog
        onClose={() => {
          setShowFormEditorial(false);
        }}
        open={showFormEditorial}
      >
        <DialogTitle>
          <Typography variant="h3" sx={{ textAlign: 'center' }}>
            Agregar Editorial
          </Typography>
        </DialogTitle>
        <DialogContent>
          <FormAddEditorial
            onUpdate={refetchEditorials}
            handleClose={() => setShowFormEditorial(false)}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        onClose={() => {
          setShowFormIdiom(false);
        }}
        open={showFormIdiom}
      >
        <DialogTitle>
          <Typography variant="h3" sx={{ textAlign: 'center' }}>
            Agregar Idioma
          </Typography>
        </DialogTitle>
        <DialogContent>
          <FormAddIdiom onUpdate={refetchIdioms} handleClose={() => setShowFormIdiom(false)} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Step2;
