import { ChangeEvent, useRef, useState, FC, useEffect } from 'react';

import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/core/Alert';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/core/Skeleton';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import useTheme from '@material-ui/core/styles/useTheme';

import CloseIcon from '@material-ui/icons/Close';

import { useUploadFilesMutation } from '../../../../shared/slices/uploadFilesApi';
import CustomDialog from '../../../../shared/components/dialogs/CustomDialog';
import { IconFile } from '@tabler/icons';
import SuccessAlert from '../../../../shared/components/alerts/SuccessAlert';
import ErrorAlert from '../../../../shared/components/alerts/ErrorAlert';
import { PictureAsPdf } from '@material-ui/icons';
import { useUpdateRecordStudentFileMutation } from '../../slices/academicRequestsApiSlice';

interface Props {
  applicationId: number;
  dialogProps: DialogProps;
}

interface DialogProps {
  open: boolean;
  onClose: () => void;
}

const UploadRecordStudent: FC<Props> = ({ applicationId, dialogProps }) => {
  const theme = useTheme();

  // input file ref
  const fileRef = useRef<HTMLInputElement>(null);

  //file data
  const [voucherFile, setVoucherFile] = useState<File>();
  //file url
  const [imgSrc, setImgSrc] = useState<string>();
  //file size alert
  const [typeAlert, setTypeAlert] = useState<boolean>();
  const [alertFile, setAlertFile] = useState<string | undefined>();
  const [showAlerts, setShowAlerts] = useState<boolean>();

  const [uploadFile, { isLoading, isError, error, isSuccess }] = useUploadFilesMutation();
  const [
    updateFileApp,
    { isLoading: isLoadingUp, isError: isErrorUp, error: errorUp, isSuccess: isSuccessUp },
  ] = useUpdateRecordStudentFileMutation();

  // simulate input file click
  const onClickUpload = () => {
    if (!fileRef) return;
    fileRef!.current!.click();
  };

  const clearFilesHandler = () => {
    setVoucherFile(undefined);
    setImgSrc(undefined);
  };

  // trigger onChange del input file al cargar archivos
  const onFilechange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      setVoucherFile(undefined);
      return;
    }

    const files = e.target.files;

    if (files[0].size > 314600000) {
      // console.log(files[0].size);
      setVoucherFile(undefined);
      return;
    }

    if (!checkExtension(files[0].name)) {
      setTypeAlert(true);
      setVoucherFile(undefined);
      return;
    }

    setTypeAlert(false);
    setVoucherFile(files[0]);
  };

  //Return extension file
  const getExtension = (filename: string) => {
    var parts = filename.split('.');
    return parts[parts.length - 1];
  };

  //Check the extension
  const checkExtension = (filename: string) => {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case 'pdf':
        return true;
    }
    return false;
  };

  const uploadHandler = async () => {
    try {
      //   return console.log(voucherFile);

      const filePath = await uploadFile({
        files: voucherFile!,
      });
      // .then(() => {
      //   console.log('exitoso en filePath');
      // })
      // .catch((error) => {
      //   console.log('error en filePath');
      //   throw new Error(error);
      // });

      if ((filePath as any).error?.length) throw new Error('file');

      const response = await updateFileApp({
        applicationId: applicationId,
        filePath: (filePath as any).data as unknown as string,
      });
      // .then(() => {
      //   console.log('exitoso en response');
      // })
      // .catch((error) => {
      //   console.log('error en updateFile');
      //   throw new Error(error);
      // });

      if ((response as any).error?.length) throw new Error('path');

      setShowAlerts(true);
      setAlertFile(undefined);
    } catch (error) {
      console.log(error);
      setShowAlerts(false);
      setAlertFile(error as string);
    }
  };

  //side effect for set img preview
  useEffect(() => {
    // create the preview
    if (!voucherFile) {
      setImgSrc(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(voucherFile);
    setImgSrc(objectUrl);
    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [voucherFile]);

  return (
    <CustomDialog
      {...dialogProps}
      isSuccess={isSuccessUp && isSuccess}
      isLoading={isLoadingUp || isLoading}
      isError={isErrorUp || isError}
      confirmBtnMessage="Guardar"
      disabledConfirm={!voucherFile}
      actionHandler={uploadHandler}
    >
      <>
        {typeAlert && (
          <Grid item xs={12} container justifyContent="center">
            <Grid item xs={12} md={6}>
              <Alert
                severity="error"
                action={
                  <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={() => {
                      setTypeAlert(false);
                    }}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                }
              >
                <Typography component="p" align="center">
                  El tipo de archivo no es correcto
                </Typography>
              </Alert>
            </Grid>
          </Grid>
        )}

        {alertFile && (
          <Grid item xs={12} container justifyContent="center">
            <Grid item xs={12} md={6}>
              <ErrorAlert
                message={(alertFile === 'file' ? error : errorUp) as string}
                handleDismiss={() => setAlertFile(undefined)}
              />
            </Grid>
          </Grid>
        )}

        {!imgSrc && (
          <Grid item xs={12} container justifyContent="center">
            <Grid item xs={12} md={6}>
              <Box
                border={`1px dashed ${theme.palette.primary.main}`}
                borderRadius="12px"
                sx={{
                  cursor: 'pointer',
                  userSelect: 'none',
                  ':hover': {
                    backgroundColor: '#eee',
                  },
                }}
                p={4}
                justifyContent="center"
                onClick={onClickUpload}
              >
                <Typography align="center" color="primary">
                  <IconFile />
                </Typography>
                <Typography align="center" color="primary">
                  CARGAR COMPROBANTE
                </Typography>
              </Box>
            </Grid>
          </Grid>
        )}

        {isSuccessUp && isSuccess && showAlerts && (
          <Grid item xs={12} container justifyContent="center">
            <Grid item xs={12} md={6}>
              <SuccessAlert
                message="Récord académico guardado correctamente."
                handleDismiss={() => setShowAlerts(false)}
              />
            </Grid>
          </Grid>
        )}

        <Grid item xs={12} container justifyContent="center">
          <Grid item xs={12} md={6}>
            <Grid container spacing={1}>
              {imgSrc && (
                <>
                  <Grid container item xs={12} justifyContent="flex-end">
                    <Button
                      variant="outlined"
                      onClick={onClickUpload}
                      disabled={isLoadingUp || isLoading}
                      endIcon={(isLoading || isLoadingUp) && <CircularProgress size={20} />}
                    >
                      Cargar Nuevo archivo
                    </Button>
                  </Grid>

                  {!isLoading || !isLoadingUp ? (
                    <Grid item xs={12} md={12}>
                      {voucherFile && voucherFile.type === 'application/pdf' ? (
                        <Alert
                          variant="outlined"
                          severity="info"
                          icon={false}
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                clearFilesHandler();
                              }}
                            >
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          }
                          sx={{ flexDirection: 'row', alignContent: 'center' }}
                        >
                          <PictureAsPdf fontSize="small" sx={{ mr: 1 }} />
                          <span>{voucherFile.name}</span>
                        </Alert>
                      ) : (
                        <img src={imgSrc} alt="comprobante" width="100%" />
                      )}
                    </Grid>
                  ) : (
                    <Skeleton
                      variant="rectangular"
                      sx={{ borderRadius: '12px' }}
                      height="150px"
                      width="100%"
                    />
                  )}
                </>
              )}
              <Grid item hidden>
                <input
                  type="file"
                  ref={fileRef}
                  //   inputRef={fileRef}
                  onChange={onFilechange}
                  accept=".pdf"
                  //   inputProps={{ accept: 'image/jpg, image/png' }}
                />
              </Grid>{' '}
            </Grid>
          </Grid>
        </Grid>
      </>
    </CustomDialog>
  );
};

export default UploadRecordStudent;
