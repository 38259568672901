import { Grid, IconButton, TextField, Typography } from '@material-ui/core';
import { IconDownload, IconFileZip } from '@tabler/icons';
import { useParams } from 'react-router-dom';
import downloadjs from 'downloadjs';

import { useAppSelector } from '../../../../../../hooks/useRedux';
import MainCard from '../../../../../shared/components/cards/MainCard';
import LoadingData from '../../../../../shared/components/cards/Skeleton/LoadingData';
import CalificateForm from '../../../califications/components/CalificateForm';
import { useGetActityForStudentQuery } from '../../../slices/virtualClassroomTeacherApiSlice';
import ButtonBack from '../../../Components/ButtonBack';
import useGetUrlDownload from '../../../../../../hooks/useGetUrlDownload';
import { useGetActivityByAcronymQuery } from '../../../../../shared/slices/catalogApi';

function ScreenQualifyTask() {
  const {
    course: { courseId },
  } = useAppSelector((state) => state.virtualClassroomTeacherShared);
  const { activityId, studentId } = useParams<{ studentId: string; activityId: string }>();

  const { data: commentCatalog, isFetching: isFetchingCatalog } = useGetActivityByAcronymQuery({
    acronym: 'TPR3',
  });

  const { data, isLoading } = useGetActityForStudentQuery({
    courseId: courseId,
    activityId: Number(activityId),
    studentId: Number(studentId),
  });
  const { urlDownload } = useGetUrlDownload();

  const handleDownload = (urlFile: string) => {
    const url = `${urlDownload}/${urlFile}`;
    try {
      downloadjs(url);
    } catch (error) {
      console.log('errr', error);
    }
  };

  if (isLoading) {
    return <LoadingData />;
  }

  return (
    <MainCard
      title={`   ${data?.act_description}  |   Nota es: ${Number(data?.act_score_activity)}`}
    >
      <Grid>
        <Grid display="flex" justifyContent="flex-end" alignItems="center">
          <ButtonBack />
        </Grid>
        {data?.details_activity?.map((itemTask) => {
          return (
            <Grid>
              <Typography color="black" variant="h3">
                Pregunta : {itemTask.dta_question}
              </Typography>
              <Typography style={{ margin: '10px 0' }} variant="h4" color="black">
                Puntaje de la pregunta : {Number(itemTask.dta_score_evaluated)}
              </Typography>

              {itemTask.answered_activities.length < 1 && (
                <Grid>
                  <Typography color="error" variant="body1">
                    No ha sido Contestada
                  </Typography>
                  <CalificateForm
                    score={itemTask.dta_score_evaluated}
                    idDetail={Number(itemTask.id)}
                    idStudent={studentId}
                    TaskId={activityId}
                  />
                </Grid>
              )}

              {!isFetchingCatalog &&
                itemTask.answered_activities.map((itemAnswer) => {
                  return (
                    <>
                      <Typography
                        style={{ margin: '10px 0' }}
                        color="error"
                        variant="body1"
                      ></Typography>
                      {itemAnswer.csact_name_file !== null && itemAnswer.csact_url_file !== null ? (
                        <IconButton
                          size="small"
                          onClick={() => handleDownload(itemAnswer.csact_url_file)}
                        >
                          <IconFileZip />
                          {itemAnswer.csact_name_file}
                          <IconDownload />
                        </IconButton>
                      ) : (
                        'No hay archivos cargados'
                      )}
                      {itemTask.type_answer_id === commentCatalog?.id.toString() &&
                        itemAnswer.csact_comments && (
                          <>
                            <TextField
                              fullWidth
                              rows={5}
                              multiline
                              disabled
                              defaultValue={itemAnswer.csact_comments}
                            />
                          </>
                        )}
                      <Grid>
                        <CalificateForm
                          score={itemTask.dta_score_evaluated}
                          idDetail={Number(itemTask.id)}
                          idStudent={studentId}
                          TaskId={activityId}
                          Comment={itemAnswer.csact_observations || ''}
                        />
                      </Grid>
                    </>
                  );
                })}
            </Grid>
          );
        })}
      </Grid>
    </MainCard>
  );
}

export default ScreenQualifyTask;
