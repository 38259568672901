import { useState, useEffect, useMemo } from 'react';
import { PostDataType, Reply } from '../../../../shared/interfaces/post.interfaces';
// import { PostDataType } from '../../../../../shared/interfaces/post.interfaces';
import Posts from '../../../../shared/components/cards/Post';
import { Grid, IconButton, Tooltip, CircularProgress } from '@material-ui/core';
import ErrorAlert from '../../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../../shared/components/alerts/SuccessAlert';
import { baseUrl } from '../../../../../api/mainApi';
import { useAppSelector } from '../../../../../hooks/useRedux';
import {
  useAddCommentMutation,
  useGetCommentsQuery,
  useUpdateCommentMutation,
} from '../../slices/virtualClassroomTeacherApiSlice';
import { IconRefresh, IconX } from '@tabler/icons';
import { format } from 'date-fns';
// import { es } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';
// import { useParams } from 'react-router-dom';
import { useGetLocaleDateLang } from '../../../../../translation/hooks/useGetLocaleDateLang';

interface Props {
  goToForumView: (state: boolean) => void;
  forumId: number;
}

const ForumView = (props: Props) => {
  const { goToForumView, forumId } = props;
  const { t } = useTranslation();
  const { lang } = useGetLocaleDateLang();

  const [showAlerts, setShowAlerts] = useState(true);

  const { currentProfile } = useAppSelector((state) => state.access);

  const { userInfo } = useAppSelector((state) => state.auth);

  const {
    course: { courseId },
  } = useAppSelector((state) => state.virtualClassroomTeacherShared);

  const { data, refetch, isFetching, isLoading } = useGetCommentsQuery(
    {
      profileId: currentProfile?.profileId!,
      courseId: courseId!,
      forumId,
    },
    { skip: !courseId }
  );
  const [createComment, { isSuccess: isSuccessComment, isError: isErrorComment, error }] =
    useAddCommentMutation();

  const [
    editComment,
    { isSuccess: isSuccessEditComment, isError: isErrorEditComment, error: errorEdit },
  ] = useUpdateCommentMutation();

  const dataPost: PostDataType = useMemo(
    () => ({
      id: data?.id.toString() || 'N/A',
      data: {
        id: data?.id.toString() || 'N/A',
        content: data?.act_description || 'N/A',
        details: data?.detail_forum.dta_question || 'N/A',
        images: [
          {
            img: '',
          },
        ],
        likes: { like: false, value: 0 },
        comments: data?.answers_activity!.map((item) => ({
          id: item.id.toString(),
          profile: {
            id: item.user_id.toString(),
            avatar: 'N/A',
            name: `${item?.user.person.pers_firstname} ${item?.user.person.pers_first_lastname}`,
            time: format(new Date(item.created_at! || null), 'd MMMM Y kk:mm', {
              locale: lang,
            }),
          },
          data: {
            images: [
              {
                img: `${baseUrl}/show?name=${item?.csact_url_file}`,
              },
            ],
            name: `${item?.user.person.pers_firstname} ${item?.user.person.pers_first_lastname}`,
            comment: item.csact_comments,
            replies: item?.children!.map((itemChild) => ({
              id: itemChild.id.toString(),
              profile: {
                id: itemChild.user_id.toString(),
                avatar: 'N/A',
                name: `${itemChild?.user.person.pers_firstname} ${itemChild?.user.person.pers_first_lastname}`,
                time: format(new Date(itemChild.created_at! || null), 'd MMMM Y kk:mm', {
                  locale: lang,
                }),
              },
              data: {
                name: `${itemChild?.user.person.pers_firstname} ${itemChild?.user.person.pers_first_lastname}`,
                comment: itemChild.csact_comments,
                images: [
                  {
                    img: `${baseUrl}/show?name=${itemChild?.csact_url_file}`,
                  },
                ],
              },
            })),
          },
        })),
      },
      profile: {
        id: data?.author.id.toString() || 'N/A',
        avatar: 'user-1.png',
        name:
          `${data?.author.person.pers_firstname} ${data?.author.person.pers_first_lastname}` ||
          'N/A',
        time:
          format(new Date(data?.course_activity_one?.cact_start_date! || null), 'd MMMM Y kk:mm', {
            locale: lang,
          }) || 'N/A',
      },
    }),
    [data, lang]
  );

  // console.log('dataPost', dataPost);

  const [posts, setPosts] = useState<PostDataType>(dataPost);

  const getPost = async () => {
    console.log('getPost');
  };

  const editPost = async (id: string, commentId: string) => {
    console.log(id, commentId);
  };

  const commentAdd = async (id: string, comment: Reply) => {
    // setComments(comments.concat(comment));
    setPosts(dataPost);
    await createComment({
      forumId,
      CommentPayload: {
        csact_comments: comment.data.comment,
        files: comment.data.imagesFile,
        user_id: Number(userInfo?.id!),
        type_document: 12,
        parent_id: null,
      },
    });
    setShowAlerts(true);
  };

  const commentEdit = async (postId: string, commentId: string, reply: Reply) => {
    //console.log(postId, commentId, reply);
    await editComment({
      commentId: Number(commentId!),
      CommentPayload: {
        csact_comments: reply.data.comment,
        files: reply.data.imagesFile,
        type_document: 12,
      },
    });
    setShowAlerts(true);
  };

  const replyAdd = async (postId: string, commentId: string, reply: Reply) => {
    await createComment({
      forumId,
      CommentPayload: {
        csact_comments: reply.data.comment,
        files: reply.data.imagesFile,
        user_id: Number(userInfo?.id!),
        type_document: 12,
        parent_id: commentId,
      },
    });
    setShowAlerts(true);
  };

  const handlePostLikes = async (postId: string) => {
    console.log(postId);
  };

  const handleCommentLikes = async (postId: string, commentId: string) => {
    console.log(postId, commentId);
  };

  const handleReplayLikes = async (postId: string, commentId: string, replayId: string) => {
    console.log(postId, commentId, replayId);
  };

  const handleRefetch = () => {
    refetch();
  };

  useEffect(() => {
    setPosts(dataPost);
  }, [dataPost]);

  return (
    <>
      <Grid container justifyContent="flex-end" alignItems="center">
        <Tooltip title={t('teacher_virtual_classroom.ForoScreen.refresh')} onClick={handleRefetch}>
          <IconButton>
            {isFetching && !isLoading ? <CircularProgress size={18} /> : <IconRefresh size={18} />}
          </IconButton>
        </Tooltip>
        <Tooltip title={t('teacher_virtual_classroom.ForoScreen.title')}>
          <IconButton onClick={() => goToForumView(false)}>
            <IconX size={18} />
          </IconButton>
        </Tooltip>
      </Grid>
      {/* {!isFetching && ( */}
      <Grid item>
        {isErrorComment && showAlerts && (
          <ErrorAlert
            message={error as string}
            handleDismiss={() => {
              setShowAlerts(false);
              window.scrollTo(0, 0);
            }}
          />
        )}

        {isSuccessComment && showAlerts && (
          <SuccessAlert
            message={t('teacher_virtual_classroom.ForoScreen.success_comment')}
            handleDismiss={() => setShowAlerts(false)}
          />
        )}
      </Grid>
      {/*Edit comments*/}
      <Grid item>
        {isErrorEditComment && showAlerts && (
          <ErrorAlert
            message={errorEdit as string}
            handleDismiss={() => {
              setShowAlerts(false);
              window.scrollTo(0, 0);
            }}
          />
        )}

        {isSuccessEditComment && showAlerts && (
          <SuccessAlert
            message={t('teacher_virtual_classroom.ForoScreen.success_update_comment')}
            handleDismiss={() => setShowAlerts(false)}
          />
        )}
      </Grid>

      <Grid key={posts.id} item xs={12}>
        <Posts
          key={posts.id}
          post={posts}
          editPost={editPost}
          renderPost={getPost}
          setPosts={setPosts}
          commentAdd={commentAdd}
          commentEdit={commentEdit}
          replyAdd={replyAdd}
          handlePostLikes={handlePostLikes}
          handleCommentLikes={handleCommentLikes}
          handleReplayLikes={handleReplayLikes}
          isLoading={isLoading}
        />
      </Grid>
    </>
  );
};

export default ForumView;
