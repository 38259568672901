import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { CourseAvailable } from '../interfaces/coursesAvailables.interfaces';
import { setCarSubjects } from '../slices/subjectsRegisterSlice';

export const useAddCarOnline = () => {
  const [error, setError] = useState<string[] | string>();
  const [isError, setIsError] = useState<boolean>();
  const [isSuccess, setIsSuccess] = useState<boolean>();

  const { carSubjects } = useAppSelector((state) => state.subjectsRegister);
  const dispatch = useAppDispatch();

  const addCourseCar = (course: CourseAvailable) => {
    try {
      if (carSubjects.find((cs) => cs.id === course.id)) throw new Error('Este curso ya se encuentra en el carrito.');
      if (carSubjects.length >= 2) throw new Error('Carrito lleno. Máximo 2 cursos.');

      dispatch(setCarSubjects(course));
      setIsSuccess(true);
    } catch (error: any) {
      setIsError(true);
      setError(error.message);
    }
  };

  return {
    addCourseCar,
    error,
    isError,
    isSuccess,
  };
};
