import React from 'react';
import { Button, CircularProgress, Divider, Menu, MenuItem } from '@material-ui/core';
import { IconFileSpreadsheet } from '@tabler/icons';
import { DownloadTableExcel } from 'react-export-table-to-excel';

import { Exports } from '../SearchPaginatedTable';

interface Props {
  tableRef: any;
  isLoading?: boolean;
  excelDownloadHandler?: () => Promise<void>;
}

type IComponet<T extends {}> = Props &
  Omit<Exports<T>, 'portalId' | 'files' | 'allDataFetchHandler' | 'batch' | 'customHeaders'>;

const ExportFilesExcelBtn = <T extends {}>({
  variant,
  isLoading,
  fileName = '',
  tableRef,
  excelDownloadHandler,
}: IComponet<T>) => {
  console.log(excelDownloadHandler);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = async () => {
    if (isLoading) return;
    setAnchorEl(null);
  };

  const handleCloseWithLoading = async () => {
    if (isLoading) return;
    await excelDownloadHandler?.();
    handleClose();
  };

  if (variant === 'buttons') {
    return (
      <>
        <Button
          id="descargar-excel-button"
          aria-controls={open ? 'descargar-excel-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          startIcon={<IconFileSpreadsheet />}
          variant="contained"
          endIcon={isLoading && <CircularProgress size={20} />}
        >
          Exportar Excel
        </Button>
        <Menu
          id="descargar-excel-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'descargar-excel-button',
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          // transformOrigin={{
          //   vertical: 'top',
          //   horizontal: 'left',
          // }}
        >
          {!!excelDownloadHandler && (
            <MenuItem onClick={handleCloseWithLoading} disabled={isLoading}>
              {`Listado   `}
              {isLoading ? <CircularProgress size={20} /> : ''}
            </MenuItem>
          )}

          <DownloadTableExcel filename={fileName} sheet={fileName} currentTableRef={tableRef}>
            <MenuItem onClick={handleClose}>Paginado</MenuItem>
          </DownloadTableExcel>
        </Menu>
      </>
    );
  }

  return (
    <>
      {!!excelDownloadHandler && (
        <MenuItem onClick={handleCloseWithLoading} disabled={isLoading}>
          <IconFileSpreadsheet />
          {`Listado   `}
          {isLoading ? <CircularProgress size={20} /> : ''}
        </MenuItem>
      )}

      <DownloadTableExcel filename={fileName} sheet={fileName} currentTableRef={tableRef}>
        <MenuItem onClick={handleClose}>
          <IconFileSpreadsheet />
          Paginado
        </MenuItem>
      </DownloadTableExcel>

      <Divider />
    </>
  );
};

export default ExportFilesExcelBtn;
