import { Alert, IconButton, AlertProps, Snackbar } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useState } from 'react';
import TimerAutoRedirect from '../TimerAutoRedirect';

interface Props extends AlertProps {
  type?: 'fixed' | 'snackbar';
  message: string | string[];
  handleDismiss?: () => void;
  timeoutCallback?: {
    timer?: number;
    callbackFn: (...args: any) => void;
  };
}

const ErrorAlert = ({
  type = 'snackbar',
  message,
  timeoutCallback,
  handleDismiss = () => {},
  ...props
}: Props) => {
  const [open, setOpen] = useState(true);

  return (
    <>
      {type === 'snackbar' ? (
        <Snackbar
          open={open}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          autoHideDuration={5000}
          sx={{
            left: '60% !important',
          }}
          onClose={() => {
            setOpen(false);
            handleDismiss && handleDismiss();
          }}
        >
          <Alert
            icon={false}
            severity="error"
            sx={{
              '& .MuiAlert-action': {
                alignSelf: 'self-start',
              },
            }}
            action={
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => {
                  setOpen(false);
                  handleDismiss && handleDismiss();
                }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            }
            {...props}
          >
            {Array.isArray(message) ? message.map((m) => <li key={m}>{m}</li>) : message}
            {timeoutCallback && (
              <TimerAutoRedirect
                completeFunction={timeoutCallback.callbackFn}
                // customTimeInterval={timeoutCallback.timer}
                customAddTime={5}
              />
            )}
          </Alert>
        </Snackbar>
      ) : (
        <Alert
          icon={false}
          severity="error"
          action={
            handleDismiss ? (
              <IconButton size="small" aria-label="close" color="inherit" onClick={handleDismiss}>
                <CloseIcon fontSize="small" />
              </IconButton>
            ) : null
          }
          {...props}
        >
          {Array.isArray(message)
            ? message.map((m) => <li key={m}>{m}</li>)
            : typeof message === 'string'
            ? message
            : JSON.stringify(message)}
          {timeoutCallback && (
            <TimerAutoRedirect
              completeFunction={timeoutCallback.callbackFn}
              // customTimeInterval={timeoutCallback.timer}
              customAddTime={5}
            />
          )}
        </Alert>
      )}
    </>
  );
};

export default ErrorAlert;
