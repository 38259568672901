import { useEffect, useMemo, useState } from 'react';

import {
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  TextField,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { IconX } from '@tabler/icons';

import CustomAsyncPaginatedSelect from '../../../../shared/components/extended/Form/CustomAsyncPaginatedSelect';
import InputLabel from '../../../../shared/components/forms/InputLabel';
import DeleteButton from '../../../../shared/components/tables/buttons/DeleteButton';
import SearchPaginatedTable from '../../../../shared/components/tables/SearchPaginatedTable';
import TextFieldFetching from '../../../../shared/components/TextFieldFetching';
import { removeItemFromIndexArr } from '../../../../shared/helpers';
import { useControlModalStates } from '../../../../shared/hooks/useControlModalStates';
import { CustomChilds } from '../../../../shared/interfaces/material-ui.interfaces';
import { SearchPersonParam } from '../../../../shared/interfaces/searchPerson.interface';
import { useGetCatalogByAcronymQuery } from '../../../../shared/slices/catalogApi';
import { useLazyGetPersonsByParamQuery } from '../../../../shared/slices/searchPersonApi';
import { PropsFormEdit } from './';
import { AssociatedPersonData } from '../../constants/validationSchemas';
import DeleteRelativeDialog from './DeleteRelativeDialog';
import NewPersonRelativeModal from './NewPersonRelativeModal';
import AddButton from '../../../../shared/components/tables/buttons/AddButton';
import { useAddStudentRelativePersonMutation } from '../../slices/studentsApiSlice';
import {
  NewRelativesPersonTable,
  NewRelativesTable,
  PersonRelative,
} from '../../interfaces/studentRecordAcademic.interfaces';
import ErrorAlert from '../../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../../shared/components/alerts/SuccessAlert';
import LoadingButton from '../../../../shared/components/buttons/LoadingButton';
import EditRelativeDialog from './EditRelativeDialog';
import EditButton from '../../../../shared/components/tables/buttons/EditButton';
import { personfilters } from '../../../../shared/constants/globals';
import { useGetAvailablesTypeEducationsQuery } from '../../../requisition-teacher/slices/requisitionsApiSlice';

const RelativesForm = ({
  data,
  isFetchingTable,
}: CustomChilds<PropsFormEdit, { isFetchingTable: boolean }>) => {
  const { t } = useTranslation();

  const [filter, setFilter] = useState('ci');
  const [personSelect, setPersonSelect] = useState<CustomChilds<
    SearchPersonParam,
    { id: number; label: string }
  > | null>(null);

  const [showAlerts, setShowAlerts] = useState(false);

  const [newRelatives, setNewRelatives] = useState<Partial<NewRelativesTable>[]>([]);
  const [newPersonRelatives, setNewPersonRelatives] = useState<NewRelativesPersonTable[]>([]);

  const { open, dataId, handleOpenModalWith, handleCloseModal } = useControlModalStates();
  const {
    open: openEditRelative,
    multipleIds: editRelativeData,
    handleOpenModalWithMultipleIds: editRelativeDialog,
    handleCloseModal: handleCloseModalEditRelative,
  } = useControlModalStates<{
    newRelative?: Partial<NewRelativesTable>;
    existingPerson?: PersonRelative;
  }>();
  const {
    open: openNewPerson,
    multipleIds: editNewPersonRelative,
    handleOpenModalWith: handleOpenModalWithNewPerson,
    handleOpenModalWithMultipleIds: handleOpenEditModalNewPerson,
    handleCloseModal: handleCloseModalNewPerson,
  } = useControlModalStates<{ newPersonRelative?: NewRelativesPersonTable }>();

  const [getPersonsTrigger] = useLazyGetPersonsByParamQuery();

  const [saveRelatives, { isError, isSuccess, isLoading, error: errorUpdate }] =
    useAddStudentRelativePersonMutation();

  // parentesco
  const { data: kinship, isLoading: isLoadingParentesco } = useGetCatalogByAcronymQuery({
    acronym: 'P',
  });

  const { data: citiesAvailables = [], isLoading: isLoadingCA } = useGetCatalogByAcronymQuery({
    acronym: 'C',
  });

  const { data: availablesTypeEducations = [], isLoading: isLoadingTypeEducations } =
    useGetAvailablesTypeEducationsQuery({});

  //TODO: crear hook en el componente del select
  const getPersons = async (search: string, page: number, perPage: number) => {
    if (!filter || !search)
      return {
        options: [],
        hasMore: false,
      };

    const response = await getPersonsTrigger({
      page,
      perPage,
      [filter]: search,
      // statusId: 1,
    }).unwrap();

    const hasMore = response.current_page < response.last_page;

    return {
      options: response.data.map((dt) => {
        return {
          value: dt.id,
          label: `${dt.pers_full_name} - ${dt.pers_identification}`,
          ...dt,
        };
      }),
      hasMore,
    };
  };

  const { errors, touched, values, getFieldProps, handleSubmit, setFieldValue, resetForm } =
    useFormik<Partial<NewRelativesTable>>({
      initialValues: {
        id: '',
        person_id_relative: '',
        rel_description: '',
        status_id: '1',
        type_kinship_id: '',
        pers_full_name_relative: '',
        pers_identification_relative: '',
        emergency_contacts: false,
        city_id: 0,
        pers_phone_home: '',
        type_education_id: 0,
        pers_cell: '',
      },
      validationSchema: AssociatedPersonData,
      onSubmit: async (values) => {
        // const { faculty, ...value } = values;

        setNewRelatives((prev) => [
          { ...values, id: `t${new Date().getTime()}${values.id}` },
          ...prev,
        ]);

        resetForm();
        setPersonSelect(null);
      },
    });

  const changePersonHandler = (
    newValue: CustomChilds<SearchPersonParam, { id: number; label: string }> | null
  ) => {
    setPersonSelect(newValue);

    setFieldValue('person_id_relative', newValue?.id || '');
    setFieldValue('pers_full_name_relative', newValue?.pers_full_name || '');
    setFieldValue('pers_identification_relative', newValue?.pers_identification || '');
  };

  const saveContacsHandler = async () => {
    try {
      await saveRelatives({
        studentId: data!.user.student.id.toString(),
        studentPersonId: data!.user.person_id,
        payload: [
          ...(!newRelatives.length
            ? []
            : newRelatives.map((item) => ({
                person_id_relative: item.person_id_relative!,
                person_existent: false,
                person_id: Number(data!.user.person_id),
                person_id_existent: Number(item.person_id_relative!),
                type_kinship_id: Number(item.type_kinship_id!),
                rel_description: item.rel_description || '',
                emergency_contacts: item.emergency_contacts || false,
                city_id: item?.city_id || 0,
                type_education_id: item?.type_education_id || 0,
                pers_phone_home: item.pers_phone_home!,
                pers_cell: item.pers_cell!,
              }))),

          ...(!newPersonRelatives.length
            ? []
            : newPersonRelatives.map((item) => ({
                createPerson: {
                  type_identification_id: item.type_identification_id!,
                  pers_identification: item.pers_identification!,
                  pers_firstname: item.pers_firstname!,
                  pers_secondname: item.pers_secondname!,
                  pers_first_lastname: item.pers_first_lastname!,
                  pers_second_lastname: item.pers_second_lastname!,
                  pers_email: item.pers_email!,
                  ethnic_id: item.ethnic_id!,
                  type_religion_id: item.type_religion_id!,
                  status_marital_id: item.status_marital_id!,
                  pers_gender: item.pers_gender!,
                  pers_phone_home: item.pers_phone_home!,
                  pers_cell: item.pers_cell!,
                  pers_direction: item.pers_direction!,
                },
                person_id_relative: '0',
                person_existent: true,
                person_id: Number(data!.user.person_id),
                person_id_existent: Number(data!.user.person_id),
                type_kinship_id: Number(item.type_kinship_id!),
                rel_description: item.rel_description || '',
                city_id: item.city_id!,
                per_job_organization: item.per_job_organization!,
                per_job_position: item.per_job_position!,
                emergency_contacts: item.emergency_contacts || false,
                type_education_id: item.type_education_id!,
              }))),
        ],
      }).unwrap();

      setNewRelatives([]);
      setNewPersonRelatives([]);
      setShowAlerts(true);
    } catch (error) {
      setShowAlerts(true);
    }
  };

  const datafetch = useMemo(() => {
    return [...newRelatives, ...newPersonRelatives, ...(data?.person_relatives || [])].map(
      (item, i) => ({
        idx: i + 1,
        person_identification_relative:
          (item as Partial<NewRelativesTable> | PersonRelative)?.pers_identification_relative ||
          (item as NewRelativesPersonTable)?.pers_identification ||
          '',
        person_id_relative:
          (item as Partial<NewRelativesTable> | PersonRelative)?.pers_full_name_relative ||
          (item as NewRelativesPersonTable)?.pers_firstname ||
          item.person_id_relative,
        rel_description: item.rel_description,
        type_kinship_id:
          kinship?.find((dt) => Number(dt.id) === Number(item.type_kinship_id))?.cat_name || '...',
        // status_id: item.status_id,
        isEmergecyContact: (item as Partial<NewRelativesTable> | PersonRelative)
          ?.emergency_contacts_id,
        options: item,
      })
    );

    // return toFetch;
  }, [newRelatives, newPersonRelatives, data?.person_relatives, kinship]);

  const removeRelativeHandler = (index: number, id: string | number) => {
    if (typeof id === 'string')
      return setNewRelatives((prev) => removeItemFromIndexArr(prev, index));

    return handleOpenModalWith(id);
    //
  };

  useEffect(() => {
    if (personSelect?.pers_cell) {
      setFieldValue('pers_cell', personSelect?.pers_cell);
    }
    if (personSelect?.pers_phone_home) {
      setFieldValue('pers_phone_home', personSelect?.pers_phone_home);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personSelect?.pers_cell, personSelect?.pers_phone_home]);

  return (
    <>
      <form onSubmit={handleSubmit}>
        {isError && showAlerts && (
          <ErrorAlert message={errorUpdate as string} handleDismiss={() => setShowAlerts(false)} />
        )}

        {isSuccess && showAlerts && (
          <SuccessAlert
            message={t('Contactos del familiar actualizado exitosamente')}
            handleDismiss={() => setShowAlerts(false)}
          />
        )}

        <Grid container spacing={2}>
          <Grid item xs={12} md={6} container alignItems="end">
            <InputLabel>{t('Tipo de búsqueda')}</InputLabel>
            <Autocomplete
              options={personfilters}
              getOptionLabel={(option) => option.value}
              renderInput={(params) => (
                <TextField {...params} placeholder={t('Seleccione el tipo de filtro')} />
              )}
              value={personfilters.find((item) => item.name === filter) || null}
              onChange={(_, value) => {
                setFilter((prev) => value?.name || '');
                setPersonSelect(null);
              }}
              fullWidth
              clearIcon={null}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Grid
              container
              item
              xs={12}
              justifyContent="space-between"
              alignItems="flex-end"
              mb={1}
            >
              <InputLabel>{t('Persona')}</InputLabel>
              <AddButton variant="outlined" onClick={() => handleOpenModalWithNewPerson()} />
            </Grid>
            <CustomAsyncPaginatedSelect
              key={`async-select-${filter}`}
              value={personSelect}
              isError={false}
              placeholder={`Buscar persona por ${
                personfilters.find((dt) => dt.name === filter)?.value
              }`}
              // noOptionsMessage={''}
              setValue={(newValue) =>
                changePersonHandler(
                  newValue as CustomChilds<SearchPersonParam, { id: number; label: string }> | null
                )
              }
              fetchFunc={getPersons}
            />
            {errors.person_id_relative && touched.person_id_relative && (
              <FormHelperText error>{t(errors.person_id_relative)}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            <InputLabel>{t('Parentesco')}</InputLabel>
            {isLoadingParentesco ? (
              <TextFieldFetching />
            ) : (
              <Autocomplete
                options={kinship || []}
                loadingText="Cargando parentescos..."
                getOptionLabel={(option) => (option ? option.cat_name! : '')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(errors.type_kinship_id && touched.type_kinship_id)}
                    placeholder="Cargando parentescos"
                  />
                )}
                value={kinship?.find((dt) => dt.id === Number(values.type_kinship_id)) || null}
                onChange={(_, value) => {
                  setFieldValue('type_kinship_id', value?.id || '');
                }}
                fullWidth
                clearIcon={null}
              />
            )}
            {errors.type_kinship_id && touched.type_kinship_id && (
              <FormHelperText error>{errors.type_kinship_id}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            <InputLabel>Nombres</InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Nombres del familiar"
              disabled
              value={personSelect?.pers_full_name || ''}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <InputLabel required>{t('Ciudad')}</InputLabel>
            {isLoadingCA ? (
              <TextFieldFetching />
            ) : (
              <Autocomplete
                options={citiesAvailables || []}
                loading={isLoadingCA}
                loadingText={t('Cargando ciudades...')}
                noOptionsText={t('No hay ciudades disponibles')}
                getOptionLabel={(option) => option.cat_name}
                value={citiesAvailables.find(({ id }) => values.city_id + '' === id + '')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(errors.city_id && touched.city_id)}
                    placeholder={t('Seleccione una ciudad')}
                  />
                )}
                onChange={(_, value) => {
                  setFieldValue('city_id', value?.id || 0);
                }}
                fullWidth
                clearIcon={null}
              />
            )}
            {errors.city_id && touched.city_id && (
              <FormHelperText error>{t(errors.city_id)}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            <InputLabel>{t('Nivel educativo')}</InputLabel>
            <Autocomplete
              options={availablesTypeEducations}
              loading={isLoadingTypeEducations}
              loadingText={t('Cargando tipo de instituciones')}
              noOptionsText={t('No hay tipo de instituciones disponibles')}
              getOptionLabel={(option) => option.typ_edu_name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(errors.type_education_id && touched.type_education_id)}
                  placeholder={t('Seleccione un tipo de institución disponible')}
                />
              )}
              value={
                availablesTypeEducations?.find(({ id }) => id === values.type_education_id) || null
              }
              onChange={(_, value) => {
                setFieldValue('type_education_id', value?.id || 0);
              }}
              fullWidth
              clearIcon={null}
            />
            {errors.type_education_id && touched.type_education_id && (
              <FormHelperText error>{t(errors.type_education_id)}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={6} alignItems="center">
            <FormControlLabel
              sx={{
                display: 'flex',
                alignItems: 'center',
                height: '100%',
              }}
              control={
                <Checkbox
                  checked={values.emergency_contacts}
                  onChange={(e) => {
                    setFieldValue('emergency_contacts', e.target.checked);
                  }}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label="¿Es contacto de emergencia?"
            />
          </Grid>

          {values.emergency_contacts && (
            <>
              {personSelect?.pers_phone_home ? (
                <Grid item xs={12} md={6}>
                  <InputLabel>{t('Telefono')}</InputLabel>
                  <TextField
                    error={Boolean(errors.pers_cell && touched.pers_cell)}
                    placeholder={t('Ingrese el telefono')}
                    fullWidth
                    disabled={Boolean(personSelect?.pers_phone_home)}
                    value={personSelect?.pers_phone_home || ''}
                  />
                  {errors.pers_phone_home && touched.pers_phone_home && (
                    <FormHelperText error>{errors.pers_phone_home}</FormHelperText>
                  )}
                </Grid>
              ) : (
                <Grid item xs={12} md={6}>
                  <InputLabel>{t('Telefono')}</InputLabel>
                  <TextField
                    {...getFieldProps('pers_phone_home')}
                    error={Boolean(errors.pers_phone_home && touched.pers_phone_home)}
                    placeholder={t('Ingrese el telefono')}
                    fullWidth
                  />
                  {errors.pers_phone_home && touched.pers_phone_home && (
                    <FormHelperText error>{errors.pers_phone_home}</FormHelperText>
                  )}
                </Grid>
              )}

              {personSelect?.pers_cell ? (
                <Grid item xs={12} md={6}>
                  <InputLabel>{t('Celular')}</InputLabel>
                  <TextField
                    error={Boolean(errors.pers_cell && touched.pers_cell)}
                    placeholder={t('Ingrese el celular')}
                    disabled={Boolean(personSelect?.pers_cell)}
                    value={personSelect?.pers_cell || ''}
                    fullWidth
                  />

                  {errors.pers_cell && touched.pers_cell && (
                    <FormHelperText error>{errors.pers_cell}</FormHelperText>
                  )}
                </Grid>
              ) : (
                <Grid item xs={12} md={6}>
                  <InputLabel>{t('Celular')}</InputLabel>
                  <TextField
                    {...getFieldProps('pers_cell')}
                    error={Boolean(errors.pers_cell && touched.pers_cell)}
                    placeholder={t('Ingrese el celular')}
                    fullWidth
                  />

                  {errors.pers_cell && touched.pers_cell && (
                    <FormHelperText error>{errors.pers_cell}</FormHelperText>
                  )}
                </Grid>
              )}
            </>
          )}

          <Grid item xs={12}>
            <InputLabel>{t('Descripción de relación')}</InputLabel>
            <TextField
              fullWidth
              multiline
              rows={2}
              variant="outlined"
              placeholder="Descripción de relación"
              error={Boolean(errors.rel_description && touched.rel_description)}
              {...getFieldProps('rel_description')}
              // value={
              //   dataPersons?.data?.find((e) => e.id === formikValues.person_id_relative)
              //     ?.pers_full_name
              // }
            />
            {errors.rel_description && touched.rel_description && (
              <FormHelperText error>{errors.rel_description}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12}>
            <Grid container alignItems="center" justifyContent="flex-end" mt={1} spacing={2}>
              <Button type="submit" variant="outlined" size="large" color="primary">
                Agregar
              </Button>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <SearchPaginatedTable
              data={datafetch || []}
              error={undefined}
              headers={{
                idx: '#',
                person_identification_relative: 'Identificación',
                person_id_relative: 'Nombre',
                rel_description: 'Descripción de relación',
                type_kinship_id: 'Parentesco',
                isEmergecyContact: '¿Es contacto de emergencia?',
                // status_id: 'Estado',
                options: 'Opciones',
              }}
              isFetching={isFetchingTable}
              isLoading={false}
              page={1}
              perPage={100}
              search={''}
              setPage={() => {}}
              setPerPage={() => {}}
              setSearch={() => {}}
              keyExtractor={(item) => `relatives-file-student-table-${item.idx}`}
              searchPlacehoder={''}
              total={0}
              showFilter={false}
              paginationBox={false}
              perPageBox={false}
              customHeadersCellsProperties={{
                isEmergecyContact: {
                  align: 'center',
                },
                type_kinship_id: {
                  align: 'center',
                },
              }}
              customDataCellsProperties={{
                isEmergecyContact: {
                  align: 'center',
                },
                type_kinship_id: {
                  align: 'center',
                },
                person_identification_relative: {
                  align: 'center',
                },
                idx: {
                  align: 'center',
                },
              }}
              customRenderers={{
                isEmergecyContact: (row) => (
                  <>
                    {Boolean(row.isEmergecyContact) || row.options.emergency_contacts ? (
                      <Checkbox
                        checked={Boolean(row.isEmergecyContact || row.options.emergency_contacts)}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    ) : (
                      t('-')
                    )}
                  </>
                ),

                options: (row) => (
                  <>
                    <EditButton
                      handleEdit={() => {
                        if (typeof row.options.id === 'number')
                          return editRelativeDialog({
                            existingPerson: row.options as PersonRelative,
                          });

                        if ((row.options as NewRelativesPersonTable).type_identification_id)
                          return handleOpenEditModalNewPerson({
                            newPersonRelative: row.options as NewRelativesPersonTable,
                          });

                        return editRelativeDialog({
                          newRelative: row.options as Partial<NewRelativesTable>,
                        });
                      }}
                    />

                    <DeleteButton
                      handleDelete={() => removeRelativeHandler(row.idx - 1, row.options.id!)}
                      CustomIcon={
                        typeof row.options.id === 'string' && row.options.id[0] === 't'
                          ? IconX
                          : null
                      }
                    />
                  </>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Grid container alignItems="center" justifyContent="flex-end" mt={1} spacing={2}>
              <LoadingButton
                onClick={saveContacsHandler}
                isLoading={isLoading}
                disabled={isLoading || (!newRelatives.length && !newPersonRelatives.length)}
                variant="contained"
                size="large"
                color="primary"
              >
                Guardar
              </LoadingButton>
            </Grid>
          </Grid>

          {/* / */}
        </Grid>
      </form>

      <DeleteRelativeDialog
        dataId={dataId || 0}
        studentId={data?.user.student.id.toString() || '0'}
        handleCloseModal={handleCloseModal}
        openModal={open}
      />

      {!!data && (
        <EditRelativeDialog
          open={openEditRelative}
          studentId={data.user.student.id}
          data={editRelativeData}
          cases={
            (editRelativeData?.existingPerson && 'existingRelative') ||
            (editRelativeData?.newRelative && 'newRelative')
          }
          handleCloseDialog={handleCloseModalEditRelative}
          setNewRelatives={setNewRelatives}
        />
      )}

      {!!data && (
        <NewPersonRelativeModal
          open={openNewPerson}
          editData={editNewPersonRelative?.newPersonRelative}
          studentPersonId={data.user.person_id}
          onClose={handleCloseModalNewPerson}
          onSave={setNewPersonRelatives}
        />
      )}
    </>
  );
};

export default RelativesForm;
