import { useState } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { Box, Grid } from '@material-ui/core';
import { useUpdateFaqMutation } from '../../slices/faqApiSlice';
import ErrorAlert from '../../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../../shared/components/alerts/SuccessAlert';
import { useAppSelector } from '../../../../../hooks/useRedux';
import { FaqCreateInfoSchema, FaqStepperModule } from '../../constants/validation-schema';
import { FormikStep, FaqStepper } from '../stepper';
import { FaqEditSelectModule } from './FaqEditSelectModule';
import { FaqEditInfo } from './FaqEditInfo';
import { FaqEditFiles } from './FaqEditFiles';
import { useTranslation } from 'react-i18next';

export const FaqEditStepperIndex = () => {
  const { t } = useTranslation();

  const [showAlerts, setShowAlerts] = useState(false);

  const { faqList } = useAppSelector((state) => state.faqs);

  const { faqId } = useParams<{ faqId: string }>();

  const [updateFaq, { isError, error, isSuccess }] = useUpdateFaqMutation();

  const { currentProfile  } = useAppSelector((state) => state.access);

  const faqToEdit = faqList?.find((e) => e.id === Number(faqId));

  if (!faqToEdit) {
    return <Redirect to="/administrar-preguntas-frecuentes" />;
  }

  return (
    <>
      <Grid container sx={{ mt: 2, mb: 2 }}>
        <Grid item xs={12}>
          {isError && showAlerts && (
            <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
          )}
          {isSuccess && showAlerts && (
            <SuccessAlert
              message={t('configuration.faqs.edit.success')}
              handleDismiss={() => setShowAlerts(false)}
            />
          )}
        </Grid>
      </Grid>
      <FaqStepper
        initialValues={{
          faq_question: faqToEdit?.faq_question || '',
          faq_answer: faqToEdit?.faq_answer || '',
          faq_keyword: faqToEdit?.faq_keyword || '',
          faq_link_video: faqToEdit?.faq_link_video || '',
          type_document: 0,
          faq_tags: faqToEdit?.faq_tags.split(',') || [],
          file: null,
          faq_module:faqToEdit?.faq_module || '',
        }}
        onSubmit={async (values) => {
          await updateFaq({
            profileId: currentProfile?.profileId!,
            faqId: Number(faqId),
            payload: {
              faq_answer: values.faq_answer,
              faq_keyword: values.faq_keyword,
              faq_link_video: values.faq_link_video,
              type_document: 14,
              faq_tags: values.faq_tags,
              faq_question: values.faq_question,
              file: values.file,
              faq_module: values.faq_module,
            },
          });
          setShowAlerts(true);
        }}
      >
        <FormikStep
          label={t('configuration.faqs.stepper.step1')}
          validationSchema={FaqStepperModule}
        >
          <Box paddingBottom={3} paddingTop={3}>
            <FaqEditSelectModule />
          </Box>
        </FormikStep>
        <FormikStep
          label={t('configuration.faqs.stepper.step2')}
          validationSchema={FaqCreateInfoSchema}
        >
          <Box paddingBottom={3} paddingTop={3}>
            <FaqEditInfo />
          </Box>
        </FormikStep>
        <FormikStep label={t('configuration.faqs.stepper.step3')}>
          <Box paddingBottom={3} paddingTop={3}>
            <FaqEditFiles />
          </Box>
        </FormikStep>
      </FaqStepper>
    </>
  );
};
