import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import DialogAlert from '../../../shared/components/dialogs/DialogAlert';
import { useUpdateElectronicDocumentStateMutation } from '../slices/electronicDocumentsApiSlice';
import {
  setUpdateModalOpen,
  resetElectronicDocumentIdUpdate,
} from '../slices/electronicDocumentsSlice';

const UpdateStateElectronicDocumentDialog = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { electronicDocumentIdUpdate, updateModalOpen } = useAppSelector(
    (state) => state.electronicDocuments
  );

  const [updateDocumentStatus, { isLoading, isSuccess, isError, error }] =
    useUpdateElectronicDocumentStateMutation();
  const handleAction = () => {
    updateDocumentStatus({
      sriDocumentId: electronicDocumentIdUpdate!,
    });
  };
  const handleClose = () => {
    dispatch(setUpdateModalOpen(false));
    dispatch(resetElectronicDocumentIdUpdate());
  };
  return (
    <DialogAlert
      open={updateModalOpen}
      onClose={handleClose}
      handleAction={handleAction}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      error={error as string}
      title={t('financial.electronic_docs.update_status_dialog.title')}
      bodyText={t('financial.electronic_docs.update_status_dialog.body')}
    />
  );
};

export default UpdateStateElectronicDocumentDialog;
