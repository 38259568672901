import { forwardRef, useCallback } from 'react';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
// import { useAppSelector } from '../../../../../hooks/useRedux';

const defaultMaskOptions = {
  prefix: '',
  suffix: '',
  includeThousandsSeparator: false,
  thousandsSeparatorSymbol: ',',
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 2,
  integerLimit: 9,
  allowNegative: false,
  allowLeadingZeroes: false,
};
//

interface Props {
  inputmode?: string;
  maskOptions?: MaskedOptions;
}
interface MaskedOptions {
  prefix: string;
  suffix: string;
  includeThousandsSeparator: boolean;
  thousandsSeparatorSymbol: string;
  allowDecimal: boolean;
  decimalSymbol: string;
  decimalLimit: string;
  requireDecimal: boolean;
  allowNegative: boolean;
  allowLeadingZeroes: boolean;
  integerLimit: number;
}

const CurrencyInputMasked = forwardRef(function TextMaskCustom(
  { maskOptions, ...inputProps }: Props,
  ref
) {
  const setRef = useCallback(
    (maskedInputRef) => {
      const value = maskedInputRef ? maskedInputRef.inputElement : null;

      if (typeof ref === 'function') {
        ref(value);
      } else if (ref) {
        ref.current = value;
      }
    },
    [ref]
  );

  // const defaultConfiguration = useAppSelector((state) => state.access.defaultConfiguration);

  const currencyMask = createNumberMask({
    ...defaultMaskOptions,
    // prefix: defaultConfiguration?.currency_symbol,
    // ...maskOptions,
  });

  return <MaskedInput mask={currencyMask} {...inputProps} ref={setRef} />;
});

// const CurrencyInputMasked = ({ maskOptions, ...inputProps }: Props) => {
//   const currencyMask = createNumberMask({
//     ...defaultMaskOptions,
//     ...maskOptions,
//   });

//   return <MaskedInput mask={currencyMask} {...inputProps} />;
// };

// CurrencyInputMasked.defaultProps = {
//   inputMode: 'numeric',
//   maskOptions: {},
// };

export default CurrencyInputMasked;
