import { Redirect, Switch } from 'react-router-dom';
import { useAppSelector } from '../../../../hooks/useRedux';
import GuardRoute from '../../../../router/guards/GuardRoute';
import { permissionKey, subPermissionKeys } from '../constants/permissions';
// import AddProfileScreen from '../screens/AddProfileScreen';
// import EditProfileScreen from '../screens/EditProfileScreen';
import ProfilesScreen from '../screens/ProfilesScreen';

const ProfilesRouter = () => {
  const { currentModule } = useAppSelector((state) => state.access);

  const hasPermission = 'profiles' in currentModule?.permissions!;

  if (!hasPermission) return <Redirect to="/" />;

  return (
    <Switch>
      <GuardRoute
        exact
        path="/administrar-perfiles"
        component={ProfilesScreen}
        subPermissionKey={subPermissionKeys.read}
        permissionKey={permissionKey}
      />
      {/* <GuardRoute
        exact
        path="/administrar-perfiles/agregar"
        component={AddProfileScreen}
        subPermissionKey={subPermissionKeys.read}
        permissionKey={permissionKey}
      />
      <GuardRoute
        exact
        path="/administrar-perfiles/:profileId/editar"
        component={EditProfileScreen}
        subPermissionKey={subPermissionKeys.read}
        permissionKey={permissionKey}
      /> */}
    </Switch>
  );
};

export default ProfilesRouter;
