import { Switch, Redirect } from 'react-router-dom';
import { useAppSelector } from '../../../../hooks/useRedux';
import AcademicRequestsScreen from '../screens/AcademicRequestsScreen';
import { permissionKey, subPermissionKeys } from '../constants/permissions';
import GuardRoute from '../../../../router/guards/GuardRoute';
import MissedExamScreen from '../screens/MissedExamScreen';
import ChangeCareerScreen from '../screens/ChangeCareerScreen';
import RecoveryExamScreen from '../screens/RecoveryExamScreen';
import SubjectWithDrawalScreen from '../screens/SubjectWithdrawalScreen';
import RequalificationExamScreen from '../screens/RequalificationScreen';
import ThirdTimeRegistrationScreen from '../screens/ThirdTimeRegistrationScreen';
import OtherAcademicRequestsScreen from '../screens/OtherAcademicRequestsScreen';
import ProfessionalPracticesScreen from '../screens/ProfessionalPracticesScreen';
import BondingScreen from '../screens/BondingScreen';
import TitulationScreen from '../screens/TitulationScreen';
import InfoRequestScreen from '../screens/InfoRequestScreen';
import PaymentAgreementScreen from '../screens/PaymentAgreementScreen';
const AcademicRequestsRouter = () => {
  const { currentModule } = useAppSelector((state) => state.access);

  const hasPermission = permissionKey in currentModule?.permissions!;
  if (!hasPermission) return <Redirect to="/dashboard" />;

  return (
    <Switch>
      {/* Soliciudes academicas */}
      <GuardRoute
        exact
        path="/solicitudes/examen-atrasado"
        component={MissedExamScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.create}
      />
      <GuardRoute
        exact
        path="/solicitudes/examen-recuperacion"
        component={RecoveryExamScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.create}
      />
      <GuardRoute
        exact
        path="/solicitudes/retiro-materia"
        component={SubjectWithDrawalScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.create}
      />
      <GuardRoute
        exact
        path="/solicitudes/recalificacion"
        component={RequalificationExamScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.create}
      />
      <GuardRoute
        exact
        path="/solicitudes/cambio-facultad-carrera"
        component={ChangeCareerScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.create}
      />
      <GuardRoute
        exact
        path="/solicitudes/registro-tercera-matricula"
        component={ThirdTimeRegistrationScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.create}
      />
      <GuardRoute
        exact
        path="/solicitudes/otros-casos"
        component={OtherAcademicRequestsScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.create}
      />
      {/* Practicas Preprofesionales */}
      <GuardRoute
        exact
        path="/solicitudes/ingreso-practica-preprofesional"
        component={ProfessionalPracticesScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.create}
      />
      {/*Vinculacion*/}
      <GuardRoute
        exact
        path="/solicitudes/ingreso-vinculacion"
        component={BondingScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.create}
      />
      {/* Titulacion */}
      <GuardRoute
        exact
        path="/solicitudes/registro-anteproyecto"
        component={TitulationScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.create}
      />
      {/* Convenio de pago */}
      <GuardRoute
        exact
        path="/solicitudes/convenio-pago"
        component={PaymentAgreementScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.create}
      />
      {/* Consulta e informacion de estados de la solicitud */}
      <GuardRoute
        exact
        path="/solicitudes/consultar-solicitudes"
        component={AcademicRequestsScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.read}
      />
      <GuardRoute
        exact
        path="/solicitudes/consultar-solicitudes/:applicationCode/info"
        component={InfoRequestScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.get}
      />
    </Switch>
  );
};

export default AcademicRequestsRouter;
