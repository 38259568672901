import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import useTheme from '@material-ui/core/styles/useTheme';

import CustomIcon from '../../../../shared/components/illustrations/CustomIcon';
import noGrades from '../../../../../assets/images/illustrations/no-grades.svg';

const NoGradesIcon = () => {
  const theme = useTheme();

  return (
    <Box>
      <Grid container justifyContent={'center'} alignContent={'center'} sx={{ height: '50vh' }}>
        <Grid container justifyContent={'center'} item xs={8} md={6}>
          <Grid container item xs={12} justifyContent={'center'}>
            <CustomIcon icon={noGrades} size={300} />
          </Grid>
          <Typography variant={'body1'} textAlign={'center'} lineHeight={2} component={'div'}>
            <Typography
              variant={'h4'}
              sx={{ color: theme.palette.primary.main }}
              component={'p'}
              align={'center'}
              lineHeight={2}
            >
              No hay calificaciones disponibles
            </Typography>
            Mientras esperas, puedes evaluar tu día ¿Qué aprendiste el día de hoy?
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default NoGradesIcon;
