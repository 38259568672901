import { mainApi } from '../../../../api/mainApi';
import { AccessState } from '../../../../types';
import { middlewareApi } from '../../../middleware/middlewareApi';
import { GraduatedStudentsResponse, StudentsGraduateResponse } from '../interfaces/interface';

export const studentsGraduateTags = middlewareApi.enhanceEndpoints({
  addTagTypes: ['StudentsGraduate', 'StudentsGraduated'],
});

export const studentsGraduateApi = studentsGraduateTags.injectEndpoints({
  endpoints: (builder) => ({
    getStudentsGraduate: builder.query<
      StudentsGraduateResponse,
      {
        page: number;
        perPage: number;
        search: string;
        educationLevelId?: number;
        careerId?: number;
        sort?: string;
        type_sort?: string;
        isPaid?: boolean;
      }
    >({
      queryFn: async (
        { page, search, perPage, educationLevelId, sort, type_sort, careerId, isPaid },
        { dispatch, getState }
      ) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const { data } = await mainApi.get<StudentsGraduateResponse>(
            '/student/graduate/student-record',
            {
              params: {
                user_profile_id: currentProfile?.profileId,
                page,
                size: perPage,
                search,
                ...(educationLevelId && { education_level: educationLevelId }),
                ...(careerId && { education_level_career: careerId }),
                ...(sort && { sort }),
                ...(type_sort && { type_sort }),
                ...(isPaid && { is_paid: isPaid }),
              },
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: [{ type: 'StudentsGraduate', id: 'LIST' }],
    }),

    getGraduatedStudents: builder.query<
      GraduatedStudentsResponse,
      {
        page: number;
        perPage: number;
        search: string;
        educationLevelId?: number;
        careerId?: number;
        graduationDate?: string;
        sort?: string;
        type_sort?: string;
      }
    >({
      queryFn: async (
        { page, search, perPage, educationLevelId, graduationDate, sort, type_sort, careerId },
        { dispatch, getState }
      ) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const { data } = await mainApi.get<GraduatedStudentsResponse>(
            '/student/graduate/students',
            {
              params: {
                user_profile_id: currentProfile?.profileId,
                page,
                size: perPage,
                search,
                ...(educationLevelId && { education_level: educationLevelId }),
                ...(careerId && { education_level_career: careerId }),
                ...(graduationDate && { graduation_date: graduationDate }),
                ...(sort && { sort }),
                ...(type_sort && { type_sort }),
              },
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: [{ type: 'StudentsGraduated', id: 'LIST' }],
    }),

    graduateStudent: builder.mutation<
      StudentsGraduateResponse,
      {
        studentId: number;
        certificateTitle: string;
        graduationDate: string;
        educationCalification: number;
        educationObservation: string;
        educationTypeId: number;
        documentUrl: string;
        cityId: number;
      }
    >({
      queryFn: async (
        {
          studentId,
          certificateTitle,
          graduationDate,
          educationCalification,
          educationObservation,
          educationTypeId,
          documentUrl,
          cityId,
        },
        { dispatch, getState }
      ) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const { data } = await mainApi.post<StudentsGraduateResponse>(
            `student/graduate/student-record/${studentId}`,
            {
              user_profile_id: currentProfile?.profileId,
              certificate_title: certificateTitle,
              graduation_date: graduationDate,
              education_calification: educationCalification,
              education_observation: educationObservation,
              type_education: educationTypeId,
              document_url: documentUrl,
              city_id: cityId,
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'StudentsGraduate', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetStudentsGraduateQuery,
  useGraduateStudentMutation,
  useGetGraduatedStudentsQuery,
} = studentsGraduateApi;
