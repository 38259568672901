import { FC, useEffect, Fragment, useState, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import {
  Grid,
  Collapse,
  Typography,
  Skeleton,
  TextField,
  Button,
  Divider,
  FormHelperText,
  useTheme,
  CircularProgress,
  Stack,
  IconButton,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';

import { DetailsProps } from '../../constants/types';
import InputLabel from '../../../../shared/components/forms/InputLabel';
import { openRejectAppModalWith } from '../../slices/GeneralApplicationsSlice';
import { ChangeStatusApp } from '../../constants/validation-schemas';
import { useAppSelector } from '../../../../../hooks/useRedux';
import { InternalHomologation as InternalHomologationInt } from '../../interfaces/generalApplications.interfaces';
import { useGetMeshQuery } from '../../../meshs/slices/meshsApiSlice';
import { useUpdateInternalHomApplicationMutation } from '../../slices/GeneralApplicationsApiSlice';
import { firstLetterUppercase } from '../../../../shared/helpers';
import { IconChevronUp, IconChevronDown } from '@tabler/icons';
import { useGetEducationLevelMattersQuery } from '../../../homologations/slices/homologationsApiSlice';
import { renderSkeletonTable } from '../../../../shared/helpers/render';
import { isAppChangeStatusAvailable } from '../../helpers';
import { subPermissionKeys } from '../../constants/permissions';
import useSharedGuard from '../../../../../hooks/useSharedGuard';

const InternalHomologation: FC<DetailsProps> = (props) => {
  const {
    details: detailsProp,
    spacing,
    appCode,
    appId,
    appDescription,
    currentStatus,
    nextStatus,
    updateAlertHandler,
    updateStatusHandler,
  } = props;
  const details = detailsProp as InternalHomologationInt;

  const isNotAvailable = !isAppChangeStatusAvailable(currentStatus);

  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const { hasAccess } = useSharedGuard(subPermissionKeys);

  //Lista de materias homologadas
  const [openSubjects, setOpenSubjects] = useState(true);

  const { currentProfile } = useAppSelector((state) => state.access);

  const {
    data: destinedCurriculum,
    // isLoading: isLoadingDM,
    isFetching: isFetchingDM,
  } = useGetMeshQuery({
    profileId: currentProfile?.profileId!,
    meshId: details.curriculum.id || -1,
  });

  const { data: subjectHomologationAvailables, isFetching: isFetchingSA } =
    useGetEducationLevelMattersQuery({
      edLvlId: details.curriculum.level_edu_id,
      curriculum_id: details.curriculum.id,
    });

  const [
    updateApplication,
    {
      isLoading: isUpdating,
      error: updateError,
      isError: isErrorUpdate,
      isSuccess: isSuccessUpdate,
    },
  ] = useUpdateInternalHomApplicationMutation();

  const { handleSubmit, getFieldProps, errors, touched, validateField, values } = useFormik({
    initialValues: {
      comment: '',
    },
    validationSchema: ChangeStatusApp,
    onSubmit: async (values) => {
      await updateApplication({
        profileId: currentProfile?.profileId!,
        ApplicationId: appId,
        applicationPayload: {
          app_code: appCode,
          comment: values.comment,
        },
        ...(currentStatus === 'Revisado por Decanato' && {
          subjects: homologatedSubjects.map((dt) => Number(dt.subject_id)),
        }),
      });
      updateAlertHandler(true);
    },
  });

  useEffect(() => {
    if (isErrorUpdate) updateStatusHandler({ state: 'error', msg: updateError as string });
    if (isSuccessUpdate)
      updateStatusHandler({ state: 'success', msg: 'La solicitud se ha aprobado' });
  }, [isErrorUpdate, updateStatusHandler, updateError, isSuccessUpdate]);

  const handleDelete = (data: { appId: number; code: string; msg: string }) => {
    dispatch(openRejectAppModalWith(data));
  };

  const toogleOpenSubjects = () => {
    setOpenSubjects(!openSubjects);
  };

  const homologatedSubjects = useMemo(() => {
    return details[1].value.filter((dt) =>
      subjectHomologationAvailables?.some((mtm) => Number(mtm.matter_id) === Number(dt.subject_id))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subjectHomologationAvailables]);

  return (
    <Grid container item xs={12}>
      <form onSubmit={handleSubmit}>
        <Grid item mb={1}>
          <InputLabel>{firstLetterUppercase(appDescription)}</InputLabel>
        </Grid>
        <Grid container border="1px solid #eee" borderRadius="12px" p={2} mb={1}>
          <Grid container item xs={12} sm={6} alignItems={'center'} mb={1}>
            <Grid item xs={'auto'}>
              <Typography variant={'body2'} component={'span'}>
                Programa actual:
              </Typography>
            </Grid>

            {isFetchingDM ? (
              <Grid item xs={6}>
                <Skeleton
                  variant="text"
                  component={'span'}
                  sx={{ width: '100%', height: 30, ml: 1 }}
                />
              </Grid>
            ) : (
              <Grid item xs={'auto'} lg={6} sx={{ pl: spacing }}>
                <Typography variant={'h5'} component={'span'}>
                  {details[0].value.education_level.edu_name}
                </Typography>
              </Grid>
            )}
          </Grid>

          <Grid container item xs={12} sm={6} alignItems={'center'} mb={1}>
            <Grid item xs={'auto'}>
              <Typography variant={'body2'} component={'span'}>
                Programa de destino:
              </Typography>
            </Grid>
            {isFetchingDM ? (
              <Grid item xs={6}>
                <Skeleton
                  variant="text"
                  component={'span'}
                  sx={{ width: '100%', height: 30, ml: 1 }}
                />
              </Grid>
            ) : (
              <Grid item xs={'auto'} lg={6} sx={{ pl: spacing }}>
                <Typography variant={'h5'} component={'span'}>
                  {destinedCurriculum?.education_level.edu_name}
                </Typography>
              </Grid>
            )}
          </Grid>

          <Grid container p={1} mt={1} spacing={1} alignItems="center">
            <Grid container justifyContent="space-between">
              <Typography variant="h4" mb={1}>
                Materias a Homologar
              </Typography>

              <IconButton onClick={toogleOpenSubjects}>
                {openSubjects ? <IconChevronUp size={15} /> : <IconChevronDown size={15} />}
              </IconButton>
            </Grid>

            <Grid container>
              <Collapse in={openSubjects} timeout="auto" unmountOnExit sx={{ width: '100%' }}>
                {!!homologatedSubjects.length ? (
                  <>
                    {homologatedSubjects?.map((approvedSub, i) => (
                      <Fragment key={`aprsb-${i}`}>
                        <Stack
                          py={1}
                          direction="row"
                          divider={<Divider orientation="vertical" flexItem />}
                          spacing={2}
                          alignItems="center"
                          // justifyContent="space-between"
                        >
                          <Grid container item xs={11}>
                            {approvedSub.subject_curriculum.matter_rename}
                          </Grid>
                          <Grid container item xs={1}>
                            {approvedSub.final_note}
                          </Grid>
                        </Stack>
                      </Fragment>
                    ))}
                  </>
                ) : isFetchingSA ? (
                  renderSkeletonTable(1, 1)
                ) : (
                  <Typography component="p" align="center">
                    Sin materias a homologar
                  </Typography>
                )}
              </Collapse>
            </Grid>
          </Grid>

          {/* {((currentRol?.keyword === 'decano' &&
          currentApplication!.current_status.toLowerCase() === 'enviado') ||
          (currentRol?.keyword === 'coordinador-facultad' &&
            (currentApplication!.current_status.toLowerCase() === 'revisado por decanato' ||
              currentApplication!.current_status.toLowerCase() ===
                'revisado por consejo facultad'))) &&
          !isSuccess && ( */}
        </Grid>
        {/* )} */}

        {hasAccess('changeApplicationStatus') && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputLabel>Comentario</InputLabel>
              <TextField
                fullWidth
                multiline
                rows={4}
                error={Boolean(errors.comment && touched.comment)}
                placeholder="Ingrese el nombre del período"
                {...getFieldProps('comment')}
                disabled={isNotAvailable}
              />
              {errors.comment && touched.comment && (
                <FormHelperText error>{errors.comment}</FormHelperText>
              )}
            </Grid>

            {/* {((currentRol?.keyword === 'decano' &&
            currentApplication!.current_status.toLowerCase() === 'enviado') ||
            (currentRol?.keyword === 'coordinador-facultad' &&
              (currentApplication!.current_status.toLowerCase() === 'revisado por decanato' ||
                currentApplication!.current_status.toLowerCase() ===
                  'revisado por consejo facultad'))) &&
            !isSuccess && ( */}
            <Grid container justifyContent={'flex-end'} mt={2} spacing={2}>
              <Grid item>
                <Button
                  variant="outlined"
                  size="large"
                  onClick={() => history.push(`/${location.pathname.split('/')[1]}`)}
                >
                  Cancelar
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  size="large"
                  {...(!isNotAvailable && {
                    sx: { background: theme.palette.error.main, borderColor: '#EDE7F6' },
                  })}
                  onClick={() => {
                    touched.comment = true;
                    validateField('comment').then(() => {
                      if ((touched.comment && errors.comment) || !values.comment) return;
                      handleDelete({ appId, code: appCode, msg: values.comment });
                    });
                  }}
                  disabled={isUpdating || isNotAvailable || isSuccessUpdate || nextStatus === null}
                >
                  Rechazar
                </Button>
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  color="primary"
                  disabled={isUpdating || isNotAvailable || isSuccessUpdate || nextStatus === null}
                  endIcon={isUpdating && <CircularProgress size={20} />}
                >
                  Aprobar
                </Button>
              </Grid>
            </Grid>
            {/* )} */}
          </Grid>
        )}
      </form>
    </Grid>
  );
};

export default InternalHomologation;
