import * as yup from 'yup';

const phoneRegExp = /^\d{10}$/;
const telephoneRegExp = /\d/;

export const PersonCreateSchema = yup.object().shape({
  type_identification_id: yup
    .number()
    .min(1, 'Tipo de identificación requerido.')
    .typeError('Tipo de identificación requerido.')
    .required('Tipo de identificación requerido.'),
  pers_identification: yup.string().required('Número de identificación requerido.'),
  pers_firstname: yup.string().required('Primer nombre requerido.'),
  pers_secondname: yup.string().required('Segundo nombre requerido.'),
  pers_first_lastname: yup.string().required('Primer apellido requerido.'),
  pers_second_lastname: yup.string().required('Segundo apellido requerido.'),
  pers_email: yup
    .string()
    .email('Ingrese un correo electrónico válido.')
    .required('Correo electrónico requerido.'),
  ethnic_id: yup
    .number()
    .min(1, 'Etnia requerida.')
    .typeError('Etnia requerida.')
    .required('Etnia requerida.'),
  type_religion_id: yup.number().min(1, 'Religión requerida.').typeError('Religión requerida.'),
  pers_gender: yup.string().required('Género requerido.'),
  rel_description: yup.string().required('Descripción requerida.'),
  city_id: yup
    .number()
    .min(1, 'Ciudad requerida.')
    .typeError('Tipo de identificación requerido.')
    .required('Ciudad requerida.'),
  pers_phone_home: yup.string().matches(telephoneRegExp, 'Ingrese un número de teléfono válido.'),
  pers_cell: yup.string().matches(phoneRegExp, 'Ingrese un número de celular válido.'),
  type_education_id: yup
    .number()
    .min(1, 'Nivel de educación requerido.')
    .typeError('Nivel de educación requerido.')
    .required('Nivel de educación requerido.'),
  type_kinship_id: yup.number().min(1, ''),
});
