import generateDebts from './generateDebts.json';
import menu from './menu.json';
import closeCashPoints from './closeCashPoints.json';
import debtsPayment from './debtsPayment.json';
import generateCreditNote from './generateCreditNote.json';
import maintenanceCategory from './maintenanceCategory.json';
import maintenanceProduct from './maintenanceProduct.json';
import clientList from './clientList.json';
import depositTransfers from './depositTransfers.json';
import electronicDocuments from './electronicDocuments.json';
import receipts from './receipts.json';
import cheque from './cheque.json';
import financialPeriods from './financialPeriods.json';
import economicGroups from './economicGroups.json';
import configurationProductPrice from './configurationProductPrice.json';
import emissionPoint from './emissionPoint.json';

const financialTranslations = {
  ...generateDebts,
  ...menu,
  ...closeCashPoints,
  ...debtsPayment,
  ...generateCreditNote,
  ...maintenanceCategory,
  ...maintenanceProduct,
  ...clientList,
  ...depositTransfers,
  ...electronicDocuments,
  ...receipts,
  ...cheque,
  ...financialPeriods,
  ...economicGroups,
  ...configurationProductPrice,
  ...emissionPoint,
};

export default financialTranslations;
