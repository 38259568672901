import { Box, Button, Grid, Stack, Typography } from '@material-ui/core';
import { CompanyService } from '../../companies/interfaces/companies.interfaces';
import AnimateButton from '../../../shared/components/extended/AnimateButton';

const ServiceCard = ({ service, url, description }: Omit<CompanyService, 'id' | 'company_id'>) => {
  const handleContact = () => {
    window.location = `mailto:${url}` as any;
  };

  return (
    <Grid item /*  xs={6} md={4} lg={3} xl={2} */>
      <Box
        sx={{
          border: '1px solid ',
          borderColor: 'primary.main',
          borderRadius: '12px',
          width: '250px',
          height: '250px',
          cursor: 'pointer',

          ':hover': {
            '& button': {
              backgroundColor: 'primary.main',
              color: 'background.paper',
            },
          },
        }}
        p={3}
        alignItems="center"
        display="flex"
        justifyContent="center"
        onClick={handleContact}
      >
        <Stack justifyContent="center" p={1} spacing={4}>
          <Grid item>
            <Typography variant="h3" align="center" mb={1}>
              {service}
            </Typography>
            <Typography
              variant="body1"
              align="center"
              fontWeight={500}
              fontSize={12}
              sx={{ color: 'secondary.main' }}
            >
              {url}
            </Typography>
          </Grid>

          <Grid item textAlign="center">
            <AnimateButton>
              <Button
                variant="outlined"
                size="small"
                sx={{
                  //   borderRadius: `12px`,
                  py: 0,
                  px: 4,
                  //   backgroundColor: `primary.light`,
                  //   color: `primary.main`,
                  fontWeight: 800,
                  //   fontSize: '1.2rem',
                }}
                // onClick={handleContact}
              >
                Contactar
              </Button>
            </AnimateButton>
          </Grid>
        </Stack>
      </Box>
    </Grid>
  );
};

export default ServiceCard;
