import { Switch, Redirect } from 'react-router-dom';
import { useAppSelector } from '../../../../hooks/useRedux';
import GuardRoute from '../../../../router/guards/GuardRoute';
import { subPermissionKeys } from '../constants/permissions';
import PlanificationListScreen from '../screens/PlanificationListScreen';
import ApprovePlanificationScreen from '../screens/ApprovePlanificationScreen';

const PosgradatePlanificationListRouter = () => {
  const { currentModule } = useAppSelector((state) => state.access);

  const hasPermission = subPermissionKeys.groupKey in currentModule!.permissions;

  if (!hasPermission) return <Redirect to="/dashboard" />;

  return (
    <Switch>
      <GuardRoute
        exact
        path="/listado-planeacion-academica-posgrado"
        component={PlanificationListScreen}
        subPermissionKey={subPermissionKeys.readPosgraduatePlanningListTemp}
        permissionKey={subPermissionKeys.groupKey}
      />
      <GuardRoute
        exact
        path="/listado-planeacion-academica-posgrado/:idTransaction/fechas"
        component={ApprovePlanificationScreen}
        subPermissionKey={subPermissionKeys.readCalendar}
        permissionKey={subPermissionKeys.groupKey}
      />
    </Switch>
  );
};

export default PosgradatePlanificationListRouter;
