import { Switch } from 'react-router-dom';
import GuardRoute from '../../../../router/guards/GuardRoute';
import { HelpCenterFaqScreen } from '../screens/HelpCenterFaqScreen';
import { HelpCenterScreen } from '../screens/HelpCenterScreen';

const HelpCenterRouter = () => {

   return (
      <Switch>
         <GuardRoute
            exact
            path="/centro-asistencia"
            component={HelpCenterScreen}
         />
         <GuardRoute
            exact
            path="/centro-asistencia/detalle/:faqId"
            component={HelpCenterFaqScreen}
         />
      </Switch>
   );
};

export default HelpCenterRouter;
