import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  errorContent: {
    maxWidth: '350px',
    margin: '0 auto',
    textAlign: 'center',
  },
  constructionBlock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

interface Props {
  type: 'events' | 'notices';
}

const InfoEventsNewsEmpty = function ({ type }: Props) {
  const { t } = useTranslation();

  const classes = useStyles();
  return (
    <Card className={classes.constructionBlock}>
      <CardContent>
        <Grid container justifyContent="center" spacing={3}>
          <Grid item xs={12}>
            <div className={classes.errorContent}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography variant="h3" component="div">
                    {type === 'events' ? t('shared.events.no_events') : t('shared.events.no_news')}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2">{t('shared.events.no_data')}</Typography>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default InfoEventsNewsEmpty;
