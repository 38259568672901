import DeleteAlert from '../../../../shared/components/dialogs/DeleteAlert';

import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import { useUpdateApplicationMutation } from '../../slices/homologationsApiSlice';
import { resetInternalAppIdToDelete, setDeleteModalOpen } from '../../slices/homologationsSlice';
// import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const RejectAppDialog = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { deleteModalOpen, internalAppToReject } = useAppSelector((state) => state.homologations);

  const { currentProfile } = useAppSelector((state) => state.access);

  const [rejectApplication, { isLoading, isSuccess, isError, error }] =
    useUpdateApplicationMutation();

  const handleDelete = () => {
    rejectApplication({
      profileId: currentProfile?.profileId!,
      applicationPayload: {
        app_code: internalAppToReject!.code,
        comment: internalAppToReject!.msg,
        refused: 1,
      },
    });
  };

  const handleClose = () => {
    dispatch(setDeleteModalOpen(false));
    dispatch(resetInternalAppIdToDelete());
    // return <Redirect to="/administrar-homologaciones" />;
  };

  return (
    <DeleteAlert
      open={deleteModalOpen}
      onClose={handleClose}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      handleDelete={handleDelete}
      error={error as string}
      deleteTitle={t('academic.homologation.internal.reject_dialog.title')}
      deleteSubTitle={t('academic.homologation.internal.reject_dialog.subtitle')}
      deleteSuccess={t('academic.homologation.internal.reject_dialog.success')}
      deleteBtnText={t('academic.homologation.internal.reject_dialog.reject_button')}
    />
  );
};

export default RejectAppDialog;
