import {
  Autocomplete,
  Grid,
  IconButton,
  TextField,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import { School } from '@material-ui/icons';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { useTableSearch } from '../../../../hooks/useTableSearch';
import SearchPaginatedTable from '../../../shared/components/tables/SearchPaginatedTable';
import { useGetCareerByEduLevelQuery } from '../../courses/slices/courseApiSlice';
import { useGetPrincipalEducationLevelsQuery } from '../../education-levels/slices/educationLevelsApiSlice';
import useVerificationColl from '../hooks/useVerificationColl';

import { useGetStudentsGraduateQuery } from '../slices/studentsGraduateApiSlice';
import {
  setCareerIdSelected,
  setEducationLevelIdSelected,
  setStudentGraduateSideDialogOpen,
  setStudentIdSelected,
  setStudentsList,
  updateStudentsGraduateTable,
} from '../slices/studentsGraduateSlice';
import useSharedGuard from '../../../../hooks/useSharedGuard';
import { subPermissionKeys } from '../constants/permissions';

const StudentsGraduateTable = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { hasAccess } = useSharedGuard(subPermissionKeys);

  const { blockFilter } = useVerificationColl();

  const { currentProfile } = useAppSelector((state) => state.access);

  const {
    studentsGraduateTable: { page, perPage, search, filterArrow },
    educationLevelIdSelected,
    careerIdSelected,
  } = useAppSelector((state) => state.studentsGraduate);

  const [isPaid, setIsPaid] = useState(false);

  const { setPage, setPerPage, setSearch, setFilterArrow } = useTableSearch({
    page,
    perPage,
    search,
    filterArrow,
    updateFunction: updateStudentsGraduateTable,
    otherFilters: {
      educationLevelIdSelected,
      careerIdSelected,
      isPaid,
    },
  });

  const { data, error, isLoading, isFetching } = useGetStudentsGraduateQuery({
    page: page,
    perPage: perPage,
    search: search,
    isPaid,
    ...(educationLevelIdSelected && { educationLevelId: educationLevelIdSelected }),
    ...(careerIdSelected && { careerId: careerIdSelected }),
    ...(filterArrow && { sort: filterArrow.name, type_sort: filterArrow.direction }),
  });

  useEffect(() => {
    if (data) dispatch(setStudentsList(data.data));
  }, [data, dispatch]);

  const { data: eduLevels = [], isLoading: isLoadingE } = useGetPrincipalEducationLevelsQuery(
    {
      profileId: currentProfile?.profileId!,
    },
    {
      skip: !currentProfile?.profileId,
    }
  );

  const { data: careers, isLoading: isLoadingC } = useGetCareerByEduLevelQuery(
    {
      eduLevelId: educationLevelIdSelected!,
      profileId: currentProfile?.profileId!,
    },
    {
      skip: !educationLevelIdSelected || !currentProfile?.profileId,
    }
  );

  const datafetch =
    data?.data?.map((item, index) => ({
      id: index + ((page - 1) * perPage + 1),
      code: item.student?.stud_code,
      name: item.student?.user?.person?.pers_full_lastname_fullname,
      dni: item.student?.user?.person?.pers_identification,
      edu_level_father: item?.education_level?.father?.edu_name,
      edu_level: item?.education_level?.edu_name,
      options: item,
    })) || [];

  const handleGraduate = (id: number) => {
    dispatch(setStudentIdSelected(id));
    dispatch(setStudentGraduateSideDialogOpen(true));
  };

  return (
    <Grid item xs={12} mt={2}>
      <SearchPaginatedTable
        data={datafetch}
        error={error}
        isLoading={isLoading}
        isFetching={isFetching}
        search={search}
        searchPlacehoder={t('academic.students_graduate.search')}
        perPage={perPage}
        page={page}
        listFilterArrows={{
          // name: 'pers_firstname',
          name: 'student.user.person.pers_full_name',
          // dni: 'student.user.person.pers_identification',
          // edu_level: 'education_level.father.edu_name',
        }}
        filterArrow={filterArrow}
        setFilterArrow={setFilterArrow}
        setPage={setPage}
        setPerPage={setPerPage}
        setSearch={setSearch}
        keyExtractor={(item) => item.id.toString()}
        headers={{
          id: 'N°',
          code: 'Código',
          name: t('academic.students_graduate.name'),
          dni: t('academic.students_graduate.dni'),
          edu_level_father: t('academic.students_graduate.edu_level'),
          edu_level: t('academic.students_graduate.career'),
          options: t('academic.students_graduate.options'),
        }}
        customDataCellsProperties={{
          id: {
            align: 'center',
          },
          name: {
            align: 'center',
          },
          dni: {
            align: 'center',
          },
          edu_level: {
            align: 'center',
          },
          options: {
            align: 'center',
          },
        }}
        customRenderers={{
          options: (item) => (
            <>
              {hasAccess('graduateStudent') && (
                <IconButton onClick={() => handleGraduate(item.options.id)}>
                  <School />
                </IconButton>
              )}

              {!hasAccess('graduateStudent') && <>{t('shared.table.no_options')}</>}
            </>
          ),
        }}
        numHeader={5}
        total={data?.total || 0}
        additionalFilters={
          <Grid container spacing={1}>
            {hasAccess('getOnlyPrincipalEduLvls') && (
              <Grid item container xs={12} md={3}>
                {isLoadingE ? (
                  <TextField
                    disabled
                    placeholder={t('academic.students_graduate.edu_level.loading')}
                    fullWidth
                  />
                ) : (
                  <Autocomplete
                    options={eduLevels}
                    getOptionLabel={(option) => option.edu_name}
                    loading={isLoadingE}
                    disabled={blockFilter}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t('academic.students_graduate.edu_level')}
                        placeholder={t('academic.students_graduate.edu_level.placeholder')}
                        variant="outlined"
                        fullWidth
                      />
                    )}
                    onChange={(event, value) => {
                      dispatch(setEducationLevelIdSelected(value?.id!));
                    }}
                    value={eduLevels.find((item) => item.id === educationLevelIdSelected)}
                    fullWidth
                  />
                )}
              </Grid>
            )}
            {hasAccess(['getOnlyPrincipalEduLvls', 'getOnlyCarrers']) && (
              <Grid item container xs={12} md={3}>
                {!educationLevelIdSelected && (
                  <TextField
                    disabled
                    placeholder={t('academic.students_graduate.career.no_edu_level')}
                    fullWidth
                  />
                )}
                {educationLevelIdSelected && isLoadingC && (
                  <TextField
                    disabled
                    placeholder={t('academic.students_graduate.career.loading')}
                    fullWidth
                  />
                )}
                {educationLevelIdSelected && !isLoadingC && (
                  <Autocomplete
                    options={careers?.children || []}
                    getOptionLabel={(option) => option.edu_name}
                    loading={isLoadingC}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t('academic.students_graduate.career')}
                        placeholder={t('academic.students_graduate.career.placeholder')}
                        variant="outlined"
                        fullWidth
                      />
                    )}
                    onChange={(event, value) => {
                      dispatch(setCareerIdSelected(value?.id!));
                    }}
                    value={careers?.children?.find((item) => item.id === careerIdSelected)}
                    fullWidth
                  />
                )}
              </Grid>
            )}

            <Grid item container xs={12} md={3}>
              <FormControlLabel
                control={
                  <Switch
                    onChange={(e) => {
                      setIsPaid(e.target.checked);
                    }}
                  />
                }
                label="Sin deudas"
                value={isPaid}
              />
            </Grid>
          </Grid>
        }
      />
    </Grid>
  );
};

export default StudentsGraduateTable;
