import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/useRedux';
import { useTableSearch } from '../../../../../../hooks/useTableSearch';
import AddButton from '../../../../../shared/components/tables/buttons/AddButton';
import DeleteButton from '../../../../../shared/components/tables/buttons/DeleteButton';
import EditButton from '../../../../../shared/components/tables/buttons/EditButton';
import SearchPaginatedTable from '../../../../../shared/components/tables/SearchPaginatedTable';
import { useGetRepresentativeScaleQuery } from '../slices/optionsTemplateApiSlice';
import {
  setOptionsTemplateIdToDelete,
  setOptionsTemplateTable,
  setRepresensativeScaleIdSelected,
  setRepresentativeDeleteModalOpen,
  setRepresentativeScaleList,
} from '../slices/optionsTemplateSlice';

const RepresentativeScaleTable = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const {
    optionsTemplateTable: { page, perPage, search, filterArrow },
  } = useAppSelector((state) => state.optionsTemplate);

  const { setPage, setPerPage, setSearch, setFilterArrow } = useTableSearch({
    page,
    perPage,
    search,
    filterArrow,
    updateFunction: setOptionsTemplateTable,
  });

  const {
    data: representativeScale,
    isLoading,
    isFetching,
    error,
  } = useGetRepresentativeScaleQuery({
    page,
    perPage,
    search,
    sort: 'id',
    typeSort: 'DESC',
    ...(filterArrow && { sort: filterArrow.name, typeSort: filterArrow.direction }),
  });

  const datafetch = representativeScale?.data.map((item, idx) => ({
    id: (page - 1) * perPage + (idx + 1),
    name: item.name,
    description: item.description || 'Sin descripción',
    keywords: item.keyword,
    options: item,
  }));

  const goToAdd = () => {
    history.push('/escalas/escalas-representativa/agregar');
  };

  const handleEdit = (id: string | number) => {
    dispatch(setRepresensativeScaleIdSelected(id.toString()));
    history.push(`/escalas/escalas-representativa/${id}/editar`);
  };

  const handleDelete = (id: string | number) => {
    dispatch(setOptionsTemplateIdToDelete(id.toString()));
    dispatch(setRepresentativeDeleteModalOpen(true));
  };

  useEffect(() => {
    if (representativeScale) {
      dispatch(setRepresentativeScaleList(representativeScale.data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [representativeScale]);

  return (
    <SearchPaginatedTable
      data={datafetch!}
      headers={{
        id: 'N°',
        name: t('Nombre'),
        description: t('maintenance.options_template.description'),
        keywords: t('Palabra clave (keywords)'),
        options: t('maintenance.options_template.options'),
      }}
      listFilterArrows={{
        description: 'description',
      }}
      filterArrow={filterArrow}
      setFilterArrow={setFilterArrow}
      keyExtractor={(item) => item.id!.toString()}
      searchPlacehoder={t('maintenance.options_template.search')}
      isLoading={isLoading}
      isFetching={isFetching}
      error={error as any}
      perPage={perPage}
      setPerPage={setPerPage}
      total={representativeScale?.total || 0}
      page={page}
      setPage={setPage}
      numHeader={5}
      search={search}
      setSearch={setSearch}
      customDataCellsProperties={{
        id: {
          align: 'center',
        },
        description: {
          align: 'center',
        },
        options: {
          align: 'center',
        },
      }}
      customRenderers={{
        // eslint-disable-next-line react/no-unstable-nested-components
        options: (row) => (
          <>
            <EditButton handleEdit={() => handleEdit(row.options.id!)} />
            <DeleteButton handleDelete={() => handleDelete(row.options.id!)} />
          </>
        ),
      }}
      ActionButtons={<AddButton onClick={goToAdd} />}
    />
  );
};

export default RepresentativeScaleTable;
