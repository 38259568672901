import { TextField, Grid, Button } from '@material-ui/core';
import { useState } from 'react';
// import { useHistory } from 'react-router-dom';
import ErrorAlert from '../../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../../shared/components/alerts/SuccessAlert';
import { useQualifyTaskMutation } from '../../slices/virtualClassroomTeacherApiSlice';

const CalificateForm: React.FC<{
  score: string;
  idDetail: number;
  idStudent: string;
  TaskId: string;
  Comment?: string;
}> = ({ score, idDetail, Comment = '', idStudent, TaskId }) => {
  const [ComentaryTask, setComentaryTask] = useState(Comment);
  const [scoreEvaluate, setScoreEvaluate] = useState(Number(score));
  const [ShowAlert, setShowAlert] = useState(false);

  const [QualifyTask, { isSuccess, isError, error }] = useQualifyTaskMutation();

  // const History = useHistory();

  async function CalificarTask() {
    await QualifyTask({
      detailId: idDetail,
      studentId: Number(idStudent),
      taskId: Number(TaskId),
      qualifyPayload: {
        csact_observations: ComentaryTask,
        csact_score_evaluate: String(scoreEvaluate),
      },
    });
    setShowAlert(true);
    // if (!isError) {
    //   History.goBack();
    // }

    // console.log(scoreEvaluate);
  }

  console.log(idDetail, idStudent, TaskId);
  return (
    <Grid>
      {isSuccess && ShowAlert && (
        <SuccessAlert
          handleDismiss={() => setShowAlert(false)}
          message="Calificado Correctamente"
        />
      )}
      {isError && ShowAlert && (
        <ErrorAlert handleDismiss={() => setShowAlert(false)} message={error as string} />
      )}
      <Grid display={'flex'} alignItems="center" gap={2} margin={'15px 0'}>
        <TextField
          placeholder={`Comentario ... `}
          style={{ width: '300px' }}
          type="text"
          value={ComentaryTask}
          disabled={Comment !== '' && Comment !== null}
          onChange={(e) => setComentaryTask(e.target.value)}
          rows={3}
          multiline
          variant="outlined"
        />

        <TextField
          style={{ width: '200px' }}
          InputProps={{
            inputProps: {
              min: 0.5,
              max: Number(score),
              step: 0.5,
            },
          }}
          type="number"
          value={scoreEvaluate}
          disabled={Comment !== '' && Comment !== null}
          onChange={(e) => setScoreEvaluate(Number(e.target.value))}
          placeholder={` ${Number(score)}`}
          variant="outlined"
        />

        <Button
          onClick={CalificarTask}
          disabled={Comment !== '' && Comment !== null}
          style={{ textAlign: 'center', margin: '20px 10px' }}
          variant="contained"
        >
          Calificar
        </Button>
      </Grid>
    </Grid>
  );
};

export default CalificateForm;
