import { Link, useRouteMatch, useHistory } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';

import {
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  Grid,
  FormHelperText,
  Skeleton,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
  TextField,
  InputAdornment,
} from '@material-ui/core';

import { IconEye, IconChevronRight, IconSearch } from '@tabler/icons';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { useGetAvaliblesRolesQuery, useGetRoleQuery } from '../slices/rolesApiSlice';

import EditButton from '../../../shared/components/tables/buttons/EditButton';
import DeleteButton from '../../../shared/components/tables/buttons/DeleteButton';
import AddButton from '../../../shared/components/tables/buttons/AddButton';

import { renderErrorOrEmptyRow, renderSkeletonRows } from '../../../shared/helpers/render';

import { openDeleteModalWith } from '../slices/rolesSlice';
import { checkboxTreeData, Role } from '../interfaces/roles.interfaces';
import { useGetAvailablesGroupedPermissionsQuery } from '../../permissions/slices/permissionsApiSlice';
import AddPermissionsForm from './AddPermissionsForm';
import { useMemo, useState } from 'react';
import { Permission } from '../../permissions/interfaces/permissions.interfaces';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';

import useGuard from '../hooks/useGuard';

import CustomIcon from '../../../shared/components/illustrations/CustomIcon';
import imagenSeleccionMenu from '../../../../assets/images/imagen-selecciona-una-opcion.png';
import { useTranslation } from 'react-i18next';

const MeshsTable = () => {
  const { t } = useTranslation();
  //permissions
  const { currentModule } = useAppSelector((state) => state.access);

  const hasPermissionPermissions = 'permissions' in currentModule?.permissions!;

  const history = useHistory();

  const { currentProfile } = useAppSelector((state) => state.access);

  const dispatch = useAppDispatch();

  const { path } = useRouteMatch();

  const { hasAccess } = useGuard();

  const theme = useTheme();

  const [selectedRoleId, setSelectedRoleId] = useState<number>();
  const [status, setStatus] = useState<string>();
  const [message, setMessage] = useState<string>('');
  const [showAlerts, setShowAlerts] = useState(true);
  const [isSelectId, setIsSelectId] = useState<number>(null!);
  const [searchRole, setSearchRole] = useState<string>('');

  const handleStatus = (value: string) => {
    setShowAlerts(true);
    setStatus(value);
  };

  const handleMessage = (value: string) => {
    setMessage(value);
  };

  //permissions
  const {
    data: permissionsAvailables = [],
    isLoading: isLoadingPA,
    // isFetching: isFetchingPA,
  } = useGetAvailablesGroupedPermissionsQuery({
    profileId: currentProfile?.profileId!,
  });

  //seletcted Role
  const {
    data: selectedRole,
    isFetching: isFetchingSRA,
    refetch,
  } = useGetRoleQuery(
    {
      profileId: currentProfile?.profileId!,
      roleId: selectedRoleId!,
    },
    { skip: Boolean(!selectedRoleId) }
  );

  const selectedPermissions = selectedRole?.permissions.map((item) => item.name);

  let permissionsGrouped: checkboxTreeData[] = [];
  // let permissionsData: checkboxTreeData[] = [];

  const handleSelectRole = (rolId: number) => {
    if (hasAccess('getOne') && hasPermissionPermissions) {
      setStatus('');
      setSelectedRoleId(rolId);
    } else {
      console.log('sin permisos');
    }
  };

  //thash module groups not repeated
  const thashedPermissions: { [key: string]: string } = useMemo(() => {
    let auxThashedPermissions: { [keyAux: string]: string } = {};
    if (Boolean(permissionsAvailables)) {
      for (const key in permissionsAvailables) {
        if (key === 'debug') continue;
        const auxObj: Permission[] = permissionsAvailables[key] as any;
        if (Boolean(auxObj[0].module_group_translated)) {
          if (!(auxObj[0].module_group_translated! in auxThashedPermissions)) {
            const aux = auxObj[0].module_group_translated!;
            auxThashedPermissions[aux] = aux;
          }
        }
      }
    }
    return { ...auxThashedPermissions };
  }, [permissionsAvailables]);

  console.log('thashedPermissions', thashedPermissions);

  // for (const key in permissionsAvailables) {
  //   if (key === 'debug') continue;
  //   const permissions: Permission[] = permissionsAvailables[key] as any;

  //   permissionsData.push({
  //     value: key,
  //     label: key,
  //     parent_name: permissions[0].module_group_translated,
  //     // title: 'asd',
  //     leaf: false,
  //     children: [
  //       ...permissions.map((item) => {
  //         return {
  //           value: item.name,
  //           label: item.alias,
  //           title: item.description, // tooltip descriptivo provisional
  //           parent_name: item.parent_name,
  //           leaf: true,
  //         };
  //       }),
  //     ],
  //   });
  // }

  for (const moduleKey in thashedPermissions) {
    let childrenTree: checkboxTreeData[] = [];
    for (const key in permissionsAvailables) {
      if (key === 'debug') continue;
      const permissions: Permission[] = permissionsAvailables[key] as any;

      if (permissions[0].module_group_translated !== moduleKey) continue;

      if (permissions[0].icon_path) {
        childrenTree.push(
          ...(permissions.map((item) => {
            return {
              value: item.name,
              label: item.alias.charAt(0).toUpperCase() + item.alias.slice(1),
              title: item.description, // tooltip descriptivo provisional
              parent_name: item.parent_name,
              leaf: true,
            };
          }) as any)
        );

        continue;
      }

      childrenTree.push({
        value: key,
        label: key.charAt(0).toUpperCase() + key.slice(1),
        parent_name: permissions[0].module_group_translated,
        leaf: false,
        title: permissions[0].module_group_translated,
        children: [
          ...permissions.map((item) => {
            return {
              value: item.name,
              label: item.alias.charAt(0).toUpperCase() + item.alias.slice(1),
              title: item.description, // tooltip descriptivo provisional
              parent_name: item.parent_name,
              leaf: true,
            };
          }),
        ],
      });
    }
    //
    permissionsGrouped.push({
      value: moduleKey,
      label: moduleKey.charAt(0).toUpperCase() + moduleKey.slice(1),
      parent_name: 'permissionsData',
      leaf: false,
      children: [...childrenTree],
    });
  }

  const { error, data, isFetching } = useGetAvaliblesRolesQuery(
    {
      profileId: currentProfile?.profileId!,
      // search,
    },
    { refetchOnMountOrArgChange: 30 }
  );

  const handleDelete = async (roleId: number) => {
    dispatch(openDeleteModalWith(roleId));
  };

  const renderRow = (rol: Role) => (
    <TableRow
      hover
      className="table-row"
      key={rol.id.toString()}
      sx={{ backgroundColor: isSelectId === rol.id ? '#E7E7E7' : 'transparent' }}
    >
      <TableCell
        width="70%"
        sx={{
          cursor: 'pointer',
        }}
        onClick={() => {
          handleSelectRole(rol.id);
          setIsSelectId(rol.id);
        }}
      >
        {rol.name}
        <FormHelperText>{rol.description}</FormHelperText>
      </TableCell>
      <TableCell
        width="30%"
        align="center"
        // sx={{ borderLeft: 1, borderColor: 'grey.200', paddingX: '10px' }}
      >
        {hasAccess('edit') && (
          <Link to={`${path}/${rol.id}/editar`}>
            <EditButton />
          </Link>
        )}
        {hasAccess('delete') && (
          <DeleteButton
            handleDelete={() => {
              handleDelete(rol.id);
            }}
          />
        )}
        {hasAccess('delete') && (
          <Tooltip title={t('configuration.roles.view_permissions')}>
            <IconButton
              aria-label={t('configuration.roles.view_permissions')}
              color="default"
              onClick={() => {
                handleSelectRole(rol.id);
                setIsSelectId(rol.id);
              }}
            >
              {isSelectId === rol.id ? <IconChevronRight size={20} /> : <IconEye size={20} />}
            </IconButton>
          </Tooltip>
        )}
      </TableCell>
    </TableRow>
  );

  const rolesFiltered = useMemo(() => {
    if (searchRole) {
      return data?.filter((item) => {
        return item.name.toLowerCase().includes(searchRole.toLowerCase());
      });
    }
    return data;
  }, [data, searchRole]);

  const renderRows = () => {
    return <>{rolesFiltered?.map(renderRow)}</>;
  };

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
        <Grid item xs={12} sm={6}></Grid>
        <Grid item xs={12} sm={6} sx={{ textAlign: 'right' }}>
          {hasAccess('create') && (
            <AddButton size="large" onClick={() => history.push(`${path}/agregar`)} />
          )}
        </Grid>

        <Grid container mt={'1rem'}>
          <Grid item xs={12} md={5} pr={1}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                placeholder={t('configuration.roles.search')}
                value={searchRole}
                onChange={(e) => setSearchRole(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconSearch size={14} />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              mt={1}
              sx={{
                maxHeight: 600,
                height: 600,
                overflow: 'hidden',
                border: '#DEDEDE 1px solid',
                padding: '1rem',
              }}
            >
              <PerfectScrollbar component="div">
                <TableContainer>
                  <Table>
                    <TableBody>
                      {isFetching ? renderSkeletonRows(5, 3) : renderRows()}

                      {error && renderErrorOrEmptyRow(5, error as string)}
                      {rolesFiltered?.length === 0 && renderErrorOrEmptyRow(5)}
                    </TableBody>
                  </Table>
                </TableContainer>
              </PerfectScrollbar>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            md={7}
            sx={{ padding: '1rem', border: '#DEDEDE 1px solid', backgroundColor: '#F8F8F8' }}
          >
            {status && status === 'success' && showAlerts && (
              <Grid>
                <SuccessAlert message={message!} handleDismiss={() => setShowAlerts(false)} />
              </Grid>
            )}
            {status && status === 'error' && showAlerts && (
              <Grid>
                <ErrorAlert message={message} handleDismiss={() => setShowAlerts(false)} />
              </Grid>
            )}
            {!isLoadingPA && !isFetchingSRA && Boolean(selectedRoleId) && (
              <>
                <AddPermissionsForm
                  key={selectedRole?.id}
                  role={selectedRole!}
                  data={permissionsGrouped}
                  permissions={selectedPermissions || []}
                  refetch={refetch!}
                  handleMessage={handleMessage}
                  handleStatus={handleStatus}
                />
              </>
            )}
            {!isLoadingPA && isFetchingSRA && Boolean(selectedRoleId) && (
              <Grid container item xs={12} justifyContent={'center'}>
                <Skeleton
                  variant={'rectangular'}
                  width={'100%'}
                  height={'500px'}
                  sx={{ mt: '1px', px: '20px' }}
                />
              </Grid>
            )}
            {!isLoadingPA && !isFetchingSRA && !Boolean(selectedRoleId) && (
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{ height: '100%' }}
              >
                <Grid container justifyContent={'center'} item xs={8} md={6}>
                  <Grid container item xs={12} justifyContent={'center'}>
                    <CustomIcon icon={imagenSeleccionMenu} customWidth={350} />
                  </Grid>
                  <Typography
                    variant={'body1'}
                    textAlign={'center'}
                    lineHeight={2}
                    component={'div'}
                  >
                    <Typography
                      variant={'h4'}
                      sx={{ color: theme.palette.primary.main }}
                      component={'p'}
                      align={'center'}
                      lineHeight={2}
                    >
                      {t('configuration.roles.select')}
                    </Typography>
                    {/* Selecciona una opción del menú lateral */}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default MeshsTable;
