import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISearchTable } from '../../../shared/interfaces/paginatedTable.interface';
import { Department, DepartmentinLine } from '../interfaces/departments.interface';

interface DepartmentsState {
  departmentList: Department[];
  departmentListinLine: DepartmentinLine[];
  deleteModalOpen: boolean;
  departmentIdToDelete?: number;
  departmentTable: ISearchTable;
}

const initialState: DepartmentsState = {
  departmentList: [],
  departmentListinLine: [],
  deleteModalOpen: false,
  departmentTable: {
    page: 1,
    perPage: 5,
    search: '',
  },
};

const departmentsSlice = createSlice({
  name: 'departments',
  initialState,
  reducers: {
    setDepartmentList: (state, action: PayloadAction<Department[]>) => {
      state.departmentList = action.payload;
    },
    setDepartmentListinLine: (state, action: PayloadAction<DepartmentinLine[]>) => {
      state.departmentListinLine = action.payload;
    },
    setDeleteModalOpen: (state, action: PayloadAction<boolean>) => {
      state.deleteModalOpen = action.payload;
    },
    openDeleteModalWith: (state, action: PayloadAction<number>) => {
      state.departmentIdToDelete = action.payload;
      state.deleteModalOpen = true;
    },
    resetDepartmentIdToDelete: (state) => {
      state.departmentIdToDelete = undefined;
    },
    updateDepartmentTable: (state, action: PayloadAction<ISearchTable>) => {
      state.departmentTable = action.payload;
    },
  },
});

export const {
  setDepartmentList,
  setDepartmentListinLine,
  setDeleteModalOpen,
  openDeleteModalWith,
  resetDepartmentIdToDelete,
  updateDepartmentTable,
} = departmentsSlice.actions;

export default departmentsSlice.reducer;
