import { mainApi } from '../../../../api/mainApi';
import { AccessState } from '../../../../types';
import { middlewareApi } from '../../../middleware/middlewareApi';
import {
  ActivitiesResponse,
  Activity,
} from '../../../students/dashboard/interfaces/dashboard.interface';

export const dashboardTags = middlewareApi.enhanceEndpoints({
  addTagTypes: ['StatusSubjects'],
});
// Define a service using a base URL and expected endpoints
export const dashboardApi = dashboardTags.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * @GET teachers activities paged
     */
    getTeacherActivities: builder.query<
      ActivitiesResponse,
      {
        collaboratorId: number;
        profileId?: number;
        isCalendar?: boolean;
        month?: number;
        year?: number;
        page: number;
        perPage: number;
      }
    >({
      queryFn: async (
        { profileId, page, perPage, collaboratorId, isCalendar, month, year },
        { getState }
      ) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = profileId || currentProfile?.profileId;

          const { data } = await mainApi.get<ActivitiesResponse>(
            `collaborators/${collaboratorId}/news/activities`,
            {
              params: {
                user_profile_id,
                ...(isCalendar && { isCalendar: isCalendar }),
                ...(month && { month }),
                ...(year && { year }),
                page,
                size: perPage,
              },
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      // TODO: add tags for activities (invalidate tags when activities change needed)
      // providesTags: (result) =>
      //   result
      //     ? [
      //         ...result.data.map(
      //           ({ activity_id }) => ({ type: 'Activities', activity_id } as const)
      //         ),
      //         { type: 'Activities', id: 'LIST' },
      //       ]
      //     : [{ type: 'Activities', id: 'LIST' }],
    }),

    /**
     * @GET teacher activities not paged
     */
    getTeacherActivitiesNotPaged: builder.query<
      Activity[],
      {
        collaboratorId: number;
        profileId?: number;
        isCalendar?: boolean;
        month?: number;
        year?: number;
      }
    >({
      queryFn: async ({ profileId, collaboratorId, isCalendar, month, year }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = profileId || currentProfile?.profileId;

          const { data } = await mainApi.get<Activity[]>(
            `collaborators/${collaboratorId}/news/activities`,
            {
              params: {
                user_profile_id,
                ...(isCalendar && { isCalendar: isCalendar }),
                ...(month && { month }),
                ...(year && { year }),
                data: 'all',
              },
            }
          );

          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
      // TODO: add tags for activities (invalidate tags when activities change needed)
      // providesTags: (result) =>
      //   result
      //     ? [
      //         ...result.data.map(
      //           ({ activity_id }) => ({ type: 'Activities', activity_id } as const)
      //         ),
      //         { type: 'Activities', id: 'LIST' },
      //       ]
      //     : [{ type: 'Activities', id: 'LIST' }],
    }),
  }),
});

export const { useGetTeacherActivitiesNotPagedQuery, useGetTeacherActivitiesQuery } = dashboardApi;
