import { forwardRef } from 'react';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Slide,
  SlideProps,
  Typography,
  useTheme,
} from '@material-ui/core';

// third-party
// import clsx from 'clsx';

// import AnimateButton from '../../../../shared/components/extended/AnimateButton';
import { useAppSelector } from '../../../../hooks/useRedux';
import { IconArrowNarrowLeft } from '@tabler/icons';
// import CoursesTable from './CoursesTable';
import ExtendedSideDialog from '../../../shared/components/dialogs/ExtendedSideDialog';

import PosgraduatePlanningCalendarsForm from './PosgraduatePlanningCalendarsForm';

//styles
const useStyles = makeStyles((theme) => ({
  userAddDialog: {
    '&>div:nth-child(3)': {
      justifyContent: 'flex-end',
      '&>div': {
        margin: '0px',
        borderRadius: '0px',
        maxWidth: '420px',
        maxHeight: '100%',
        height: '100%',
        '&>div.MuiDialogContent-root>div.scrollbar-container': {
          paddingLeft: '20px',
          overflow: 'hidden',
        },
      },
    },
  },
  paper: {
    minHeight: '100vh',
    width: '37%',
  },
  paperTable: {
    minHeight: '100vh',
    width: '50%!important',
  },
  movilPaper: {
    minHeight: '100vh',
    width: '100%',
  },
}));

// animation
const Transition = forwardRef(function Transition(props: SlideProps, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export interface Props {
  open: boolean;
  handleCloseDialog: (e: React.SyntheticEvent) => void;
}

const PosgraduatePlanningCalendarDialog = ({ open, handleCloseDialog }: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  // const dispatch = useDispatch();

  const { posgraduatePlanningCalendarIdSelected } = useAppSelector(
    (state) => state.posgraduatePlanningCalendars
  );

  return (
    <ExtendedSideDialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCloseDialog}
      className={classes.userAddDialog}
    >
      <DialogTitle disableTypography>
        <Grid item container alignItems="center" gap={1}>
          <IconButton onClick={handleCloseDialog}>
            <IconArrowNarrowLeft size={30} color={theme.palette.text.dark} />
          </IconButton>
          <Typography variant="h3">
            {!posgraduatePlanningCalendarIdSelected ? 'Agregar calendario' : 'Editar calendario'}
          </Typography>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <PosgraduatePlanningCalendarsForm />
      </DialogContent>
      <DialogActions>
        {/* <AnimateButton>
          <Button variant="contained" color="primary">
            Create
          </Button>
        </AnimateButton>
        <Button variant="text" onClick={handleCloseDialog} color="primary">
          Close
        </Button> */}
      </DialogActions>
      <DialogActions></DialogActions>
    </ExtendedSideDialog>
  );
};

export default PosgraduatePlanningCalendarDialog;
