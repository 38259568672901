import { FC, useCallback, useEffect, useState } from 'react';

import { Button, CircularProgress, Grid, Stack, Typography } from '@material-ui/core';

import {
  useAddPaymentDatafastMutation,
  useGetStudentBillingDataQuery,
} from '../../slices/paymentsApiSlice';
import { useAppSelector } from '../../../../../hooks/useRedux';

interface Props {
  totalAmount?: number;
  handleBack: () => void;
}

const DatafastPayment: FC<Props> = (props) => {
  const { totalAmount, handleBack } = props;
  const { currentProfile, currentStudent, currentRecordStudent } = useAppSelector(
    (state) => state.access
  );
  const { selectedDebts, debtorId } = useAppSelector((state) => state.payments);

  const [datafastDate, setDatafastDate] = useState<Date>();

  //billing Data
  const { data: billingData } = useGetStudentBillingDataQuery({
    profileId: currentProfile?.profileId!,
    debtorId,
  });

  const [datafastRequest, { isLoading, isError, error, isSuccess, data }] =
    useAddPaymentDatafastMutation();

  console.log(error);
  console.log(totalAmount);
  // User has switched back to the tab
  const onFocus = () => {
    //on re focus calculate times

    console.log(datafastDate);
    console.log(new Date());
    const currentDate = new Date().getTime();

    if (datafastDate) {
      const diffTime = (currentDate - datafastDate?.getTime()) / 1000 / 60;
      console.log(diffTime);
      if (diffTime >= 30) {
        datafastHandler();
      }
    }
    //
  };

  useEffect(() => {
    window.addEventListener('focus', onFocus);
    // window.addEventListener('blur', onBlur);
    // Specify how to clean up after this effect:
    return () => {
      window.removeEventListener('focus', onFocus);
      // window.removeEventListener('blur', onBlur);
    };
  });

  // const datafastHandler = () => {
  //   const productDetails = selectedDebts.map((pr) => {
  //     return {
  //       name: 'nombre del producto',
  //       description: 'detalle del producto',
  //       price: +pr.de_net_value - +pr.de_net_value + 1,
  //       quantity: +pr.de_product_quantity,
  //     };
  //   });

  //   const transac = {
  //     amount: totalAmount - totalAmount + 1,
  //     productDetail: [...productDetails],
  //   };

  //   datafastRequest({
  //     profileId: currentProfile?.profileId!,
  //     studentId: currentStudent?.id!,
  //     datafastPayload: { ...transac },
  //   });

  //   //set time'
  // };

  const datafastHandler = useCallback(() => {
    const productDetails = selectedDebts.map((pr) => {
      return {
        student_record_id: currentRecordStudent?.id!,
        fees_identifier: pr.fees_identifier!,
        fees_start_date: new Date(pr.fees_start_date!),
        fees_expiration_date: new Date(pr.fees_expiration_date!),
        fees_name_identifier: pr.fees_name_identifier!,
        period_id: pr.period_id!,
        per_name: pr.per_name!,
        per_reference: pr.per_reference!,
        per_current_year: pr.per_current_year!,
        per_due_year: pr.per_due_year!,
        debtor_id: pr.debtor_id!,
        debtor_student_id: pr.debtor_student_id!,
        student_id: pr.student_id!,
        fees_balance: pr.fees_balance!,
        o_fees_balance: pr.fees_balance!,
        apply_early_payment: pr.apply_early_payment === true,
        o_discount_early_payment: pr.o_discount_early_payment!,
        o_discount_early_payment_is_percent: pr.o_discount_early_payment_is_percent === true,
        o_discount_value_early_payment: pr.o_discount_value_early_payment!,
        o_net_value_early_payment: pr.o_net_value_early_payment!,
        o_discount_percent_early_payment: pr.o_discount_percent_early_payment!,
        o_fees_net_amount: pr.o_fees_net_amount,
        fees_payment: pr.fees_payment,
        //fees_identifier: +pr.fees_identifier,
        //fees_balance: (+pr.fees_balance - +pr.fees_balance + 2).toString(),
        // name: 'nombre del producto',
        // description: 'detalle del producto',
        // price: +pr.fees_balance - +pr.fees_balance + 1,
        // quantity: +pr.de_product_quantity,
      };
    });

    const transac = {
      collections_keyword: 'forma-pago-tarjeta-credito-debito',
      student: currentStudent?.id!,
      fees: [...productDetails],
      temporal: {
        tipoIdentificacion: billingData?.tipoIdentificacion!,
        identificacion: billingData?.identificacion!,
        razonSocial: billingData?.razonSocial!,
        direccion: billingData?.direccion!,
        telefono: billingData?.telefono!,
        email: billingData?.email!,
      },
    };

    datafastRequest({
      profileId: currentProfile?.profileId!,
      studentId: currentStudent?.id!,
      datafastPayload: { ...transac },
    });

    //timer
    setDatafastDate(new Date());
  }, [
    selectedDebts,
    currentStudent?.id,
    billingData?.tipoIdentificacion,
    billingData?.identificacion,
    billingData?.razonSocial,
    billingData?.direccion,
    billingData?.telefono,
    billingData?.email,
    datafastRequest,
    currentProfile?.profileId,
    currentRecordStudent?.id,
  ]);

  useEffect(() => {
    if (isSuccess) {
      var iframe = document.createElement('iframe');
      document.getElementById('pruebaTest')!.appendChild(iframe);
      iframe.setAttribute('style', 'height:100%;width:100%;border-width:0px');
      iframe.contentWindow!.document.open();
      iframe.contentWindow!.document.write(data);
      iframe.contentWindow!.document.close();

      let timer = setTimeout(() => datafastHandler(), 30 * 60 * 1000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [isSuccess, data, datafastHandler]);

  useEffect(() => {
    datafastHandler();
  }, [datafastHandler]);

  return (
    <>
      <Grid container justifyContent="center" minHeight="50vh">
        <Grid item xs={12}>
          {isLoading && !data && (
            <Grid container item xs={12} height="100%" justifyContent="center" alignItems="center">
              <CircularProgress />
            </Grid>
          )}

          {isError && (
            <Grid container item xs={12} height="100%" justifyContent="center" alignItems="center">
              <Typography color="red">Ha ocurrido un problema. {error}</Typography>
            </Grid>
          )}

          {!isLoading && data && <form id="pruebaTest" style={{ height: '100%' }}></form>}
        </Grid>
      </Grid>
      <Grid item container xs={12} justifyContent="center">
        <Stack direction="row" justifyContent="center">
          <Button variant="contained" sx={{ my: 3, ml: 1 }} onClick={handleBack}>
            Regresar
          </Button>
        </Stack>
      </Grid>
    </>
  );
};

export default DatafastPayment;
