import { useEffect } from 'react';
import { useHistory, Link, useRouteMatch } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { useGetPositionsQuery } from '../slices/PositionsApiSlice';

import { Typography } from '@material-ui/core';

// import MainCard from '../../../shared/components/cards/MainCard';
import StatusChip from '../../../shared/components/badges/StatusChip';
import EditButton from '../../../shared/components/tables/buttons/EditButton';
import DeleteButton from '../../../shared/components/tables/buttons/DeleteButton';
import AddButton from '../../../shared/components/tables/buttons/AddButton';

import { openDeleteModalWith, setPositionList, setPositionsTable } from '../slices/PositionsSlice';

import SearchPaginatedTable from '../../../shared/components/tables/SearchPaginatedTable';

import useGuard from '../hooks/useGuard';
import { useTableSearch } from '../../../../hooks/useTableSearch';

const PeriodsTable = () => {
  const { currentProfile } = useAppSelector((state) => state.access);

  const { hasAccess } = useGuard();

  const dispatch = useAppDispatch();

  const { path } = useRouteMatch();
  const history = useHistory();

  const { page, perPage, search, filterArrow } = useAppSelector(
    (state) => state.positions.positionsTable
  );

  const { setPage, setPerPage, setSearch, setFilterArrow } = useTableSearch({
    page,
    perPage,
    search,
    filterArrow,
    updateFunction: setPositionsTable,
  });

  const { isLoading, error, data, isFetching } = useGetPositionsQuery(
    {
      profileId: currentProfile?.profileId!,
      page,
      perPage,
      search,
      ...(filterArrow && { sort: filterArrow.name, type_sort: filterArrow.direction }),
    },
    { refetchOnMountOrArgChange: 30 }
  );

  const handleDelete = async (periodId: number) => {
    dispatch(openDeleteModalWith(periodId));
  };

  const goToAdd = () => {
    history.push(`${path}/agregar`);
  };

  const datafetch = data?.data!.map((d, index) => ({
    id: index + ((page - 1) * perPage + 1),
    name: d.pos_name + ',' + d.pos_keyword,
    description: d.pos_description,
    roleName: d?.role?.name,
    departmentName: d.department?.dep_name,
    statusId: d.status_id,
    options: d,
  }));

  useEffect(() => {
    if (data?.data) {
      dispatch(setPositionList(data.data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <SearchPaginatedTable
      data={datafetch!}
      headers={{
        id: 'N°',
        name: 'Nombre',
        description: 'Descripción',
        roleName: 'Rol',
        departmentName: 'Departamento',
        statusId: 'Estado',
        options: 'Opciones',
      }}
      listFilterArrows={{
        name: 'pos_name',
        description: 'pos_description',
        statusId: 'status_id',
      }}
      filterArrow={filterArrow}
      setFilterArrow={setFilterArrow}
      keyExtractor={(item) => item.id.toString()}
      searchPlacehoder="Buscar cargo"
      isLoading={isLoading}
      isFetching={isFetching}
      error={error}
      perPage={perPage}
      setPerPage={setPerPage}
      total={data?.total || 0}
      page={page}
      setPage={setPage}
      numHeader={7}
      search={search}
      setSearch={setSearch}
      customRenderers={{
        name: (row) => (
          <>
            {row.name.split(',')[0]}
            <br />
            <Typography variant="caption">{row.name.split(',')[1]}</Typography>
          </>
        ),
        statusId: (row) => <StatusChip statusId={Number(row.statusId)} />,
        options: (row) => (
          <>
            {hasAccess('edit') && (
              <Link to={`${path}/${row.options.id}/editar`}>
                <EditButton />
              </Link>
            )}
            {hasAccess('delete') && (
              <DeleteButton handleDelete={() => handleDelete(row.options.id)} />
            )}
            {!hasAccess('edit') && !hasAccess('delete') && 'Ninguna'}
          </>
        ),
      }}
      ActionButtons={<>{hasAccess('create') && <AddButton size="large" onClick={goToAdd} />}</>}
      customDataCellsProperties={{
        id: { align: 'center' },
        roleName: { align: 'center' },
        departmentName: { align: 'center' },
        statusId: { align: 'center' },
        options: { align: 'center' },
      }}
    />
  );
};

export default PeriodsTable;
