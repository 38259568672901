import { mainApi } from '../../../../../../api/mainApi';
import { middlewareApi } from '../../../../../middleware/middlewareApi';
import { IResponse } from '../../../../../shared/interfaces/response.interface';

const heteroevaluationReportsTags = middlewareApi.enhanceEndpoints({
  addTagTypes: ['HeteroevaluationReports'],
});

export const heteroevaluationReportsApi = heteroevaluationReportsTags.injectEndpoints({
  endpoints: (builder) => ({
    getHeteroevaluationsReports: builder.query<IResponse<any>, {}>({
      queryFn: async () => {
        try {
          const { data } = await mainApi.get<IResponse<any>>('/eva/heteroevaluations/reports');

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),
  }),
});

export const { useGetHeteroevaluationsReportsQuery, useLazyGetHeteroevaluationsReportsQuery } =
  heteroevaluationReportsApi;
