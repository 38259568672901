import { useEffect } from 'react';
import { useHistory, Link, useRouteMatch } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { useGetLearningComponentsListQuery } from '../slices/learningComponentsApiSlice';

import StatusChip from '../../../shared/components/badges/StatusChip';
import EditButton from '../../../shared/components/tables/buttons/EditButton';
import DeleteButton from '../../../shared/components/tables/buttons/DeleteButton';
import AddButton from '../../../shared/components/tables/buttons/AddButton';

import { Autocomplete, Grid, TextField, Typography } from '@material-ui/core';

import {
  openDeleteModalWith,
  setLearningComponentList,
  setOfferIdSelected,
  updateLearningComponentsTable,
} from '../slices/learningComponentsSlice';

import useSharedGuard from '../../../../hooks/useSharedGuard';
import { subPermissionKeys } from '../constants/permissions';
import SearchPaginatedTable from '../../../shared/components/tables/SearchPaginatedTable';
import { useGetAcademicOfferingsAvailablesQuery } from '../../periods/slices/periodsApiSlice';
import TextFieldFetching from '../../../shared/components/TextFieldFetching';
import { useTableSearch } from '../../../../hooks/useTableSearch';

const LearningComponentsTable = () => {
  const dispatch = useAppDispatch();

  const { path } = useRouteMatch();
  const history = useHistory();

  const { currentProfile } = useAppSelector((state) => state.access);
  const { offerIdSelected } = useAppSelector((state) => state.learningComponents);
  const { page, perPage, search, filterArrow } = useAppSelector(
    (state) => state.learningComponents.learningComponentsTable
  );

  const { setPage, setPerPage, setSearch, setFilterArrow } = useTableSearch({
    page,
    perPage,
    search,
    filterArrow,
    updateFunction: updateLearningComponentsTable,
    otherFilters: {
      offerIdSelected,
    },
  });

  const { data: offers, isLoading: isLoadingO } = useGetAcademicOfferingsAvailablesQuery({
    profileId: currentProfile?.profileId!,
  });

  const { isLoading, error, data, isFetching } = useGetLearningComponentsListQuery(
    {
      page,
      perPage,
      search,
      ...(filterArrow && { sort: filterArrow?.name, type: filterArrow?.name }),
      ...(offerIdSelected && { offerId: offerIdSelected }),
    },
    { refetchOnMountOrArgChange: 30 }
  );

  useEffect(() => {
    if (data) {
      dispatch(setLearningComponentList(data?.data!));
    }
  }, [data, dispatch]);

  const handleDelete = async (periodId: number) => {
    dispatch(openDeleteModalWith(periodId));
  };

  const { hasAccess } = useSharedGuard(subPermissionKeys);

  const goToAdd = () => {
    history.push(`${path}/agregar`);
  };

  const datafetch = data?.data!.map((d, index) => ({
    id: { id: d.id, com_acronym: d.com_acronym, idx: index + ((page - 1) * perPage + 1) },
    name: d.com_name,
    offer: d.offer.off_name,
    // process: d.process,
    statusId: d.status_id,
    options: '',
  }));

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={3}>
        {isLoadingO ? (
          <TextFieldFetching />
        ) : (
          <Autocomplete
            options={offers || []}
            getOptionLabel={(option) => option.off_name}
            renderInput={(params) => (
              <TextField {...params} placeholder="Seleccione una oferta" label="Oferta" />
            )}
            value={offers?.find((o) => o.id === offerIdSelected) || null}
            onChange={(e, value) => {
              dispatch(setOfferIdSelected(value?.id || null));
            }}
          />
        )}
      </Grid>

      <Grid item xs={12}>
        <SearchPaginatedTable
          data={datafetch!}
          headers={{
            id: 'N°',
            name: 'Nombre',
            offer: 'Oferta',
            // process: 'Proceso',
            statusId: 'Estado',
            options: 'Opciones',
          }}
          keyExtractor={(item) => item.id.id.toString()}
          searchPlacehoder="Buscar componentes de aprendizaje"
          listFilterArrows={{
            name: 'com_name',
            // process: 'process',
            statusId: 'status_id',
            offer: 'offer_id',
          }}
          filterArrow={filterArrow}
          setFilterArrow={setFilterArrow}
          isLoading={isLoading}
          isFetching={isFetching}
          error={error}
          perPage={perPage}
          setPerPage={setPerPage}
          total={data?.total || 0}
          page={page}
          setPage={setPage}
          numHeader={5}
          search={search}
          setSearch={setSearch}
          customRenderers={{
            statusId: (row) => <StatusChip statusId={Number(row.statusId)} />,
            id: (row) => <>{row.id.idx}</>,
            name: (row) => (
              <>
                {row.name}
                <Typography variant="subtitle2">{row.id.com_acronym}</Typography>
              </>
            ),
            options: (row) => (
              <>
                {hasAccess('edit') && (
                  <Link to={`${path}/${row.id.id}/editar`}>
                    <EditButton />
                  </Link>
                )}
                {hasAccess('delete') && (
                  <DeleteButton handleDelete={() => handleDelete(row.id.id)} />
                )}
                {!hasAccess('edit') && !hasAccess('delete') && 'Ninguna'}
              </>
            ),
          }}
          customHeadersCellsProperties={{
            id: { align: 'center' },
            options: {
              align: 'center',
            },
          }}
          customDataCellsProperties={{
            id: { align: 'center' },
            statusId: { align: 'center' },
            offer: { align: 'center' },
            options: {
              align: 'center',
            },
          }}
          ActionButtons={<>{hasAccess('create') && <AddButton size="large" onClick={goToAdd} />}</>}
        />
      </Grid>
    </Grid>
  );
};

export default LearningComponentsTable;
