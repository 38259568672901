import { Grid, Typography } from '@material-ui/core';
import MainCard from '../../../shared/components/cards/MainCard';
import ChangeCareerForm from '../components/ChangeCareerForm';

import { useTranslation } from 'react-i18next';

const ChangeCareerScreen = () => {
  const { t } = useTranslation();

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">
          {t('students.menu.applications.academic.change-faculty-carrer')}
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ mt: 2 }}>
        <ChangeCareerForm />
      </Grid>
    </Grid>
  );
};
export default ChangeCareerScreen;
