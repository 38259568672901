export const subPermissionKeys = {
  groupKey: 'hourhand',

  read: 'hourhands-listar-horarios',
  create: 'hourhands-crear-horario',
  edit: 'hourhands-actualizar-horario',
  delete: 'hourhands-borrar-horario',

  //
}; // satisfies PermissionsKeys;
