import { Grid, useTheme, Typography, TextField } from '@material-ui/core';
import { useAppDispatch } from '../../../../../hooks/useRedux';
import { setObservationAnswer } from '../../slices/userEvaluationsSlice';

const OservationAnswer = () => {
  const theme = useTheme();

  const dispatch = useAppDispatch();

  return (
    <Grid
      item
      container
      xs={12}
      mt={2}
      sx={{
        padding: 2,
        borderRadius: 2,
        backgroundColor: theme.palette.secondary.light,
      }}
    >
      <Grid item xs={12}>
        <Typography variant="h4">Observaciones</Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid
          item
          container
          xs={12}
          mt={2}
          sx={{
            padding: 2,
            borderRadius: 2,
            backgroundColor: theme.palette.background.paper,
          }}
        >
          <TextField
            fullWidth
            multiline
            rows={4}
            placeholder="Escribe aquí tus observaciones"
            variant="outlined"
            onChange={(e) => dispatch(setObservationAnswer(e.target.value))}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OservationAnswer;
