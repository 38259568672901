import { Grid, Typography } from '@material-ui/core';
import MainCard from '../../../shared/components/cards/MainCard';
import RolesList from '../components/RolesList';

const RolesScreent = () => {
  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid item sx={{ mb: 3 }} container justifyContent="space-between" alignItems="center">
        <Typography variant="h2">Roles de Pago</Typography>
      </Grid>
      <Grid item container>
        <RolesList />
      </Grid>
    </Grid>
  );
};

export default RolesScreent;
