import { Redirect, Switch } from 'react-router-dom';
import { useAppSelector } from '../../../../../hooks/useRedux';
import GuardRoute from '../../../../../router/guards/GuardRoute';
import IntegralWeightingScreen from '../integral/screens/IntegralPonderation';
import IntegralsReportScreen from '../integral/screens/IntegralsReportScreen';
// eslint-disable-next-line import/no-cycle
// import { heteroevaluationRoutes } from '../heteroevaluation/router';
// import { integralReportsRoutes } from '../integral/router';

// path: '/reportes',
// isMenuLink: true,
// title: 'Reportes',
// type: 'collapse',
// children: [...heteroevaluationRoutes, ...integralReportsRoutes],

export const ReportsRoutes = () => {
  const { currentModule } = useAppSelector((state) => state.access);

  const hasPermission = 'evaluation' in currentModule?.permissions!;

  if (!hasPermission) return <Redirect to="/dashboard" />;

  return (
    <Switch>
      <GuardRoute
        exact
        path="/evaluacion-integrales"
        component={IntegralsReportScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.list}
      />
      <GuardRoute
        exact
        path="/evaluacion-integrales/:evaId/ponderacion/:userId"
        component={IntegralWeightingScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.create}
      />
    </Switch>
  );
};
