import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../hooks/useRedux';
import MainCard from '../../../shared/components/cards/MainCard';
import DeleteReserveBookDialog from '../components/DeleteReservationDialog';
import UserReservedBooksTable from '../components/UserReservedBooksTable';

const StudentReservedBooks = () => {
  const { t } = useTranslation();

  const { reserveBookIdSelected, deleteReserveBookDialogOpen } = useAppSelector(
    (state) => state.userReservedBooks
  );

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">{t('Mis libros reservados')}</Typography>
      </Grid>
      <UserReservedBooksTable />
      <DeleteReserveBookDialog
        openModal={deleteReserveBookDialogOpen}
        reservationId={reserveBookIdSelected!}
      />
    </Grid>
  );
};

export default StudentReservedBooks;
