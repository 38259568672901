import DeleteAlert from '../../../shared/components/dialogs/DeleteAlert';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { useDeleteClassroomMutation } from '../slices/classroomsApiSlice';
import { resetClassroomIdToDelete, setDeleteModalOpen } from '../slices/classroomsSlices';

const DeleteClassroomDialog = () => {
  const dispatch = useAppDispatch();

  const { deleteModalOpen, classroomIdToDelete } = useAppSelector((state) => state.classrooms);
  const { currentProfile } = useAppSelector((state) => state.access);

  const [deletePeriod, { isLoading, isSuccess, isError, error }] = useDeleteClassroomMutation();

  const handleDelete = () => {
    deletePeriod({ profileId: currentProfile?.profileId!, classroomId: classroomIdToDelete! });
  };

  const handleClose = () => {
    dispatch(setDeleteModalOpen(false));
    dispatch(resetClassroomIdToDelete());
  };
  return (
    <DeleteAlert
      open={deleteModalOpen}
      onClose={handleClose}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      handleDelete={handleDelete}
      error={error as string}
    />
  );
};

export default DeleteClassroomDialog;
