import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

import SelectClient from './SelectClient';
// import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';

import CreditNote from './CreditNote';
import OpenCashPoint from '../../../debts-payment/components/DebtsPaymentsWizard/components/OpenCashPoint';

const GenerateCreditNote = () => {
  // const dispatch = useAppDispatch();

  //
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} id="creditNote-alert-portal"></Grid>

        <Grid item xs={12}>
          <OpenCashPoint />
        </Grid>

        <Grid item xs={12}>
          <SelectClient />
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <CreditNote />
        </Grid>
      </Grid>
    </>
  );
};

export default GenerateCreditNote;
