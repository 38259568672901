import { useHistory, useLocation } from 'react-router-dom';
import { IconButton, Grid, useTheme, Typography } from '@material-ui/core';
import { IconArrowNarrowLeft } from '@tabler/icons';
// import { Redirect } from 'react-router-dom';

import InternalHomologationForm from '../../components/internal/InternalHomologationForm';

import MainCardForm from '../../../../shared/components/cards/MainCardForm';
import { useAppSelector } from '../../../../../hooks/useRedux';
import ConfirmAppDialog from '../../components/internal/ConfirmAppDialog';
import RejectAppDialog from '../../components/internal/RejectAppDialog';
import { useTranslation } from 'react-i18next';

const EditPeriodScreen = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();

  const { internalAppToConfirm, internalAppToReject } = useAppSelector(
    (state) => state.homologations
  );

  // if (!Boolean(internalHomologationsList.length))
  //   return <Redirect to="/administrar-homologaciones" />;

  return (
    <MainCardForm>
      <Grid item xs={12}>
        <Grid item container alignItems="center" gap={1} sx={{ cursor: 'pointer' }}>
          <IconButton onClick={() => history.push(`/${location.pathname.split('/')[1]}`)}>
            <IconArrowNarrowLeft size={30} color={theme.palette.text.dark} />
          </IconButton>
          <Typography variant="h2">{t('academic.homologation.internal.form')}</Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} md={10} sx={{ mt: 2 }}>
        <InternalHomologationForm />

        {internalAppToConfirm && <ConfirmAppDialog />}
        {internalAppToReject && <RejectAppDialog />}
      </Grid>
    </MainCardForm>
  );
};

export default EditPeriodScreen;
