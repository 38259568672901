import {
  Autocomplete,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  InputLabel,
  // makeStyles,
  TextField,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { ChangeEvent, useEffect, useState } from 'react';
import { useAppSelector } from '../../../../hooks/useRedux';
import { MissedExamPayload } from '../interfaces/missedExam.interfaces';
import {
  useAddMissedExamRequestMutation,
  useGetMissedExamByStudentRecordQuery,
} from '../slices/academicRequestsApiSlice';
import { missedExamSchema } from '../constants/validationSchemas';
import { missedExamValues } from '../constants/formConstants';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import { SUPPORT_TYPES_RM } from '../../../shared/constants/extensionRequestType';
import useGuard from '../hooks/useGuard';
import TextFieldFetching from '../../../shared/components/TextFieldFetching';

import { useTranslation } from 'react-i18next';
import PeriodStudentSelect from './Builder/PeriodStudentSelect';
import DragArea from '../../../shared/components/files/DragArea';

// const useStyles = makeStyles((theme) => ({
//   uploadFile: {
//     borderColor: theme.palette.secondary.light,
//     borderWidth: '2px',
//     borderStyle: 'solid',
//     borderRadius: 20,
//     textAlign: 'center',
//     width: '100%',
//     minHeight: '20vh',
//     position: 'relative',
//   },
//   fileIcon: {
//     mt: 3,
//   },
//   closeButton: {
//     fposition: 'absolute',
//     marginTop: 2,
//     marginRight: 2,
//     right: 0,
//   },
// }));

const MissedExamForm = () => {
  const { t } = useTranslation();

  const { hasAccess } = useGuard();
  const { currentProfile, currentRecordStudent } = useAppSelector((state) => state.access);
  const { userInfo } = useAppSelector((state) => state.auth);

  const [showAlerts, setShowAlerts] = useState(true);
  const [, setFile] = useState('');
  const [target, setTarget] = useState<File>(null!);
  const onChangeFile = function (event: ChangeEvent<HTMLInputElement>) {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e: any) => {
        setFile(e.target.result);
      };
      reader.readAsDataURL(event.target.files[0]);
      setTarget(event.target.files[0]);
    }
  };
  const onRemoveFile = function () {
    setFile('');
    setTarget(null!);
  };

  const [studentPeriodId, setStudentPeriodId] = useState(0);

  const {
    data: missedExams = [],
    isLoading: isLoadingME,
    isError: isErrorME,
    error: errorME,
  } = useGetMissedExamByStudentRecordQuery(
    {
      profileId: currentProfile?.profileId!,
      studentRecordId: currentRecordStudent?.id!,
      periodId: studentPeriodId,
    },
    { skip: !studentPeriodId, refetchOnMountOrArgChange: 30 }
  );

  const [addMissedExamRequest, { isLoading: isLoadingMER, isError, error, isSuccess }] =
    useAddMissedExamRequestMutation();

  const { handleSubmit, errors, touched, setFieldValue, getFieldProps, values } =
    useFormik<MissedExamPayload>({
      initialValues: missedExamValues,
      validationSchema: missedExamSchema,
      validate: (values) => {
        const fileNotAllowed = `${t('students.applications.missed_exam.validation1')}`;
        const fileNotFound = `${t('students.applications.missed_exam.validation2')}`;
        const errors: any = {};
        if (target) {
          let extension = target?.type;
          if (SUPPORT_TYPES_RM.indexOf(extension) < 0) {
            errors.files = fileNotAllowed;
          }
        } else {
          errors.files = fileNotFound;
        }
        return errors;
      },
      onSubmit: async (values) => {
        await addMissedExamRequest({
          profileId: currentProfile?.profileId!,
          userId: String(userInfo?.id!),
          missedExamPayload: {
            files: target,
            app_description: values.app_description,
            course_student_id: values.course_student_id,
            partial_id: values.partial_id,
            motivo: values.motivo,
          },
        });

        setShowAlerts(true);
      },
    });

  console.log(values);

  useEffect(() => {
    if (isErrorME && errorME) {
      setShowAlerts(true);
    }
  }, [isErrorME, errorME]);

  useEffect(() => {
    if (missedExams && missedExams.length === 1) {
      setFieldValue('course_student_id', missedExams[0].course_student_id);
      setFieldValue('partial_id', missedExams[0].partial_id);
    }
  }, [missedExams, setFieldValue]);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction="column" gap={2}>
        <Grid item>
          {isError && showAlerts && (
            <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
          )}
          {isErrorME && showAlerts && (
            <ErrorAlert message={errorME as string} handleDismiss={() => setShowAlerts(false)} />
          )}
          {isSuccess && showAlerts && (
            <SuccessAlert
              message={t('students.applications.success_message')}
              handleDismiss={() => setShowAlerts(false)}
            />
          )}
        </Grid>

        <Grid item xs={12}>
          <PeriodStudentSelect
            changeHandler={setStudentPeriodId}
            sideFunc={() => {
              setFieldValue('course_student_id', 0);
              setFieldValue('partial_id', null);
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <InputLabel>
            {t('students.applications.missed_exam.subject')} -{' '}
            {t('students.applications.missed_exam.supply')}
          </InputLabel>
          {!isLoadingME ? (
            <Autocomplete
              options={Array.isArray(missedExams) && !isErrorME ? missedExams : []}
              loading={isLoadingME}
              loadingText="Cargando materias y parciales..."
              getOptionLabel={(option) => option.partial_name}
              noOptionsText={t('students.applications.no_period_selected')}
              groupBy={(option) => option.matter_rename}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(
                    errors.course_student_id &&
                      touched.course_student_id &&
                      errors.partial_id &&
                      touched.partial_id
                  )}
                  placeholder={t('students.applications.missed_exam.subject_supply')}
                />
              )}
              onChange={(_, value) => {
                setFieldValue('course_student_id', value?.course_student_id);
                setFieldValue('partial_id', value?.partial_id);
              }}
              fullWidth
              clearIcon={null}
              value={
                missedExams.length === 1 && !isErrorME
                  ? missedExams[0]
                  : missedExams.find((dt) => Number(dt.partial_id) === Number(values.partial_id)) ||
                    null
              }
            />
          ) : (
            <TextFieldFetching />
          )}
          {errors.course_student_id && touched.course_student_id && (
            <FormHelperText error>{errors.course_student_id}</FormHelperText>
          )}
          {/*errors.partial_id && touched.partial_id && (
            <FormHelperText error>{errors.partial_id}</FormHelperText>
          )*/}
        </Grid>
        {/*<Grid item xs={12}>
          <InputLabel>Parcial</InputLabel>
          <Autocomplete
            options={PARCIALES}
            //loading={isLoadingRS}
            loadingText="Cargando parcial..."
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <TextField
                {...params}
                //error={Boolean(errors.supplie_id && touched.supplie_id)}
                placeholder="Seleccione un parcial"
              />
            )}
            //onChange={(_, value) => setFieldValue('supplie_id', value?.id)}
            fullWidth
            clearIcon={null}
          />
            </Grid>*/}
        <Grid item xs={12}>
          <InputLabel>{t('students.applications.missed_exam.reason')}</InputLabel>
          <TextField
            {...getFieldProps('motivo')}
            error={Boolean(errors.motivo && touched.motivo)}
            multiline
            fullWidth
            placeholder={t('students.applications.missed_exam.reason_placeholder')}
          />
          {errors.motivo && touched.motivo && (
            <FormHelperText error>{errors.motivo}</FormHelperText>
          )}
        </Grid>
        <Grid item xs={12}>
          {/* <InputLabel>{t('students.applications.upload_file')}</InputLabel> */}
          <DragArea
            handleChange={onChangeFile}
            label={t('students.applications.upload_file')}
            onRemoveFile={onRemoveFile}
            // file={file}
            typesAccepted={'application/pdf'}
          />
          {/* <Grid item xs={12} md={12} container>
            <label className={classes.uploadFile}>
              {file && <IconCircleX className={classes.closeButton} onClick={onRemoveFile} />}
              <br />
              <input name="files[]" type="file" hidden onChange={onChangeFile} />

              <IconFile size={32} />
              <p>{!file ? `${t('students.applications.upload_file')}` : target.name}</p>
            </label>
          </Grid> */}
          <Grid item xs={12}>
            {errors.files && touched.files && <FormHelperText error>{errors.files}</FormHelperText>}
          </Grid>
        </Grid>
        <Grid
          item
          container
          alignItems="center"
          justifyContent="flex-end"
          spacing={2}
          sx={{ mt: 1 }}
        >
          {hasAccess('save_late_exam') && (
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                size="large"
                color="primary"
                disabled={isLoadingMER}
                endIcon={isLoadingMER && <CircularProgress size={20} />}
              >
                {t('students.applications.save')}
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </form>
  );
};
export default MissedExamForm;
