import { useEffect, useState } from 'react';

import {
  Alert,
  Autocomplete,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  Skeleton,
  TextField,
  TextFieldProps,
  Typography,
  useTheme,
} from '@material-ui/core';
import { DateRange, DateRangePicker, LocalizationProvider } from '@material-ui/lab';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import { Field, FieldArray, FormikProvider, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import InputLabel from '../../../shared/components/forms/InputLabel';
import TextFieldFetching from '../../../shared/components/TextFieldFetching';
import { navigateTopSmooth } from '../../../shared/helpers';
import {
  CalificationModel,
  CalificationModelCourses,
  CoursesQualificationAssign,
} from '../interfaces/calification-models.interfaces';
import { Stage } from '../interfaces/periods.interfaces';
import {
  useGetAvailableStagesQuery,
  useGetPeriodCalificationModelCoursesQuery,
  useGetPeriodCalificationModelsQuery,
  useGetPeriodQuery,
  usePeriodStageQualificationsMutation,
} from '../slices/periodsApiSlice';
import { CalificationModelDates, Distribution, Supply } from './AssignStagesForm';
import SimpleTable from './SimpleTable';
import { useGetLocaleDateLang } from '../../../../translation/hooks/useGetLocaleDateLang';
import LoadingChildren from '../../../shared/components/LoadingChildren';
import PeriodCoursesDialog from './PeriodCoursesDialog';
import { useControlModalStates } from '../../../shared/hooks/useControlModalStates';
import LoadingButton from '../../../shared/components/buttons/LoadingButton';
import { format } from 'date-fns';

const qualificationAcronym = 'B';

interface Props {
  modelAcronym?: string | typeof qualificationAcronym;
  isEdit?: boolean;
}

const AssignStagesQualificationForm = ({ isEdit, modelAcronym = qualificationAcronym }: Props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const theme = useTheme();
  const { periodId } = useParams<{ periodId: string }>();

  const { lang } = useGetLocaleDateLang();

  const [showAlerts, setShowAlerts] = useState(false);

  // Mensajes de error fechas incorrectas
  // const [dateInvalid, setDateInvalid] = useState<{ index: number; message: string; show: boolean }>(
  //   { show: false, index: 0, message: '' }
  // );

  const { dataId, open, handleCloseModal, handleOpenModalWith } = useControlModalStates();
  const [selectedCourses, setSelectedCourses] = useState<CoursesQualificationAssign[]>([]);

  const [addPeriodQualification, { isLoading, isError, isSuccess, error }] =
    usePeriodStageQualificationsMutation();

  const { data: modelsInfo, isLoading: isLoadingModelsInfo } = useGetPeriodCalificationModelsQuery({
    periodId: Number(periodId),
  });
  const { data: modelsCoursesInfo } = useGetPeriodCalificationModelCoursesQuery({
    periodId: Number(periodId),
  });

  const {
    data: stagesAvailables,
    isLoading: isLoadingStagesAvailables,
    isFetching: isFetchingStagesAvailables,
  } = useGetAvailableStagesQuery({});

  const { data: periodInfo, isLoading: isLoadingPeriodInfo } = useGetPeriodQuery({
    periodId: Number(periodId),
  });

  const formik = useFormik({
    initialValues: {
      stageInfo: null as Stage | null,
      modelInfo: null as CalificationModel | null,
      modelCourses: [] as CalificationModelCourses[],

      period_id: Number(periodId),
      stg_acronym: modelAcronym,
      all_courses: isEdit ? false : true,
      current_period_stage: isEdit ? false : true,
      qualification_model_id: 0,
      courses: [],

      // periodStages: arrayPeriodStagesDates(Number(periodInfo?.per_num_fees)),
      calificationModelId: 0,
      calificationModelDates: {} as CalificationModelDates,
    },
    // validationSchema: StagePeriodAssignmentSchema,
    // validate: () => {
    //   return checkDatesAvailables();
    // },
    onSubmit: async (_values) => {
      console.log(_values);

      if (isEdit) {
      } else {
        await addPeriodQualification({
          periodStageQuaPayload: {
            period_id: Number(periodId),
            stg_acronym: modelAcronym,
            all_courses: _values.all_courses,
            courses: _values.all_courses ? [] : selectedCourses,
            current_period_stage: true,
            qualification_model_id: _values.calificationModelId,
            dates: values.calificationModelDates?.distributions?.reduce<any[]>(
              (acc, curr) => [
                ...acc,
                ...curr.supplies?.map((s) => ({
                  supply_id: s.ref2_supply_id,
                  start_date: format(s.startDate!, 'yyyy-MM-dd'),
                  end_date: format(s.endDate!, 'yyyy-MM-dd'),
                })),
              ],
              []
            ) as unknown as any[],
          },
        });
      }

      setShowAlerts(true);
      navigateTopSmooth();
    },
  });

  const { values, errors, touched, handleSubmit, setFieldValue, getFieldProps } = formik;

  // ? Set stageInfo (acronym B qualifications  )
  useEffect(() => {
    if (stagesAvailables) {
      const stageInfo = stagesAvailables.find((stage) => stage.stg_acronym === modelAcronym);
      setFieldValue('stageInfo', stageInfo);
    }
  }, [stagesAvailables, setFieldValue, modelAcronym]);

  console.log(values);
  console.log(dataId);

  // const allCoursesHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const { checked } = event.target;
  //   setFieldValue('all_courses', checked);
  // };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid container direction="column" gap={2}>
          <Grid item>
            {isError && showAlerts && (
              <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
            )}

            {isSuccess && showAlerts && (
              <SuccessAlert
                message={t('Datos guardados exitosamente')}
                handleDismiss={() => setShowAlerts(false)}
              />
            )}
          </Grid>

          <Grid container spacing={5} alignItems="flex-start">
            <Grid item container spacing={1} xs={12} md={12}>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Typography variant="h4">
                  {t('academic.periods_list.stages.add.period')}:
                </Typography>
                <Typography variant="h3">
                  {!isLoadingPeriodInfo ? periodInfo?.per_name : <Skeleton />}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <Typography variant="h4">{t('academic.periods_list.stages.add.start')}:</Typography>
                <Typography variant="h3">
                  {!isLoadingPeriodInfo ? periodInfo?.start_process : <Skeleton />}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <Typography variant="h4">{t('academic.periods_list.stages.add.end')}:</Typography>
                <Typography variant="h3">
                  {!isLoadingPeriodInfo ? periodInfo?.end_process : <Skeleton />}
                </Typography>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>

          <Grid container spacing={2} alignItems="flex-start">
            <Grid item container spacing={1} xs={12} md={12}>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <InputLabel>{t('academic.periods_list.stages.add.stages')}:</InputLabel>
                {isFetchingStagesAvailables ? (
                  <TextFieldFetching />
                ) : (
                  <Autocomplete
                    options={stagesAvailables || []}
                    loading={isLoadingStagesAvailables}
                    disabled={!!modelAcronym}
                    loadingText={t('academic.periods_list.stages.add.loading')}
                    getOptionLabel={(option) => option.stg_name}
                    value={values.stageInfo}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={Boolean(errors.stageInfo && touched.stageInfo)}
                      />
                    )}
                    // onChange={(_, value) => {
                    //   setFieldValue('stageInfo', value?.stg_acronym || '');
                    // }}
                    fullWidth
                    clearIcon={null}
                    noOptionsText={t('academic.periods_list.stages.add.data')}
                  />
                )}
                {/* {errors.stageInfo && touched.stageInfo && (
                  <FormHelperText error>{t(errors.stageInfo)}</FormHelperText>
                )} */}
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <InputLabel>{t('academic.periods_list.stages.add.calification_model')}:</InputLabel>
                <Autocomplete
                  options={modelsInfo || []}
                  loading={isLoadingModelsInfo /* isLoadingModelsCoursesInfo */}
                  loadingText={t('academic.periods_list.stages.add.calification_model.loading')}
                  getOptionLabel={(option) => option.cal_mod_name || 'N/A'}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(errors.calificationModelId && touched.calificationModelId)}
                    />
                  )}
                  onChange={(_, value) => {
                    setFieldValue('calificationModelId', value?.id || '');

                    setFieldValue('modelInfo', value || null);

                    // const modelCourses =
                    //   modelsCoursesInfo?.find(
                    //     (item) => Number(item.qualification_model_id) === Number(value?.id)
                    //   ) || null;

                    // setFieldValue('modelCourses', modelCourses);

                    if (value?.id) {
                      setFieldValue('calificationModelDates', {
                        calification_model_id: value?.id,
                        distributions: value?.distribution?.map((e) => ({
                          ref1_supply_id: e.ref1_supply_id,
                          supplies: e.ref2?.map((s) => ({
                            ref2_supply_id: s.ref2_supply_id,
                          })),
                        })),
                      });
                    }
                  }}
                  fullWidth
                  clearIcon={null}
                  noOptionsText={t('academic.periods_list.stages.add.calification_model.no_data')}
                />
                <FormHelperText>
                  {t('academic.periods_list.stages.add.calification_model.helper')}
                </FormHelperText>
                {errors.calificationModelId && touched.calificationModelId && (
                  <FormHelperText error>{t(errors.calificationModelId)}</FormHelperText>
                )}
              </Grid>

              <Grid item container xs={12} spacing={2} alignItems="center">
                <Grid item>
                  <FormControlLabel
                    label={
                      isEdit
                        ? t('algo de si se reescribe no rEcuerdo')
                        : t('Aplica a todos los cursos')
                    }
                    control={
                      <Checkbox
                        checked={isEdit ? values.current_period_stage : values.all_courses}
                        {...getFieldProps(isEdit ? 'current_period_stage' : 'all_courses')}
                      />
                    }
                  />
                </Grid>

                {!values.all_courses && !isEdit && (
                  <Grid item>
                    <Button
                      variant="contained"
                      onClick={() => handleOpenModalWith(Number(periodId))}
                    >
                      Asignar cursos
                    </Button>
                  </Grid>
                )}

                {!!modelsCoursesInfo && (
                  <Grid item xs={12}>
                    <Alert severity="info">
                      <Grid container spacing={2}>
                        <Grid item>
                          <Typography>
                            {t('Cursos del periodo: ')}
                            <b>{modelsCoursesInfo.total_courses}</b>
                          </Typography>
                        </Grid>

                        {!values.all_courses && (
                          <Grid item>
                            <Typography>
                              {t('Cursos seleccionados: ')}
                              <b>{selectedCourses.length}</b>
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </Alert>
                  </Grid>
                )}
              </Grid>

              <Grid item container xs={12} spacing={5} alignItems="flex-start">
                <Grid item container md={8} mt={2}>
                  <LoadingChildren
                    isLoading={isLoadingPeriodInfo}
                    loadingSkeleton={<Skeleton sx={{ width: '100%', height: '20vh' }} />}
                    isEmpty={!values.calificationModelId}
                    // errorFallback={<>componente de no hay nada compa</>}
                  >
                    <>
                      <Grid item xs={12}>
                        <Typography variant="h5" sx={{ my: 3 }}>
                          {t('academic.periods_list.stages.add.stages_dates')}
                        </Typography>
                      </Grid>

                      {values.modelInfo?.distribution?.map((dist, distrIndex) => (
                        <>
                          <Grid
                            item
                            xs={12}
                            sx={{
                              border: `1px solid ${theme.palette.primary.main}`,
                              borderRadius: '10px',
                              mb: 2,
                            }}
                            p={2}
                          >
                            <Typography variant="h4">{dist?.distribution}</Typography>
                            <SimpleTable
                              title=""
                              data={
                                dist?.ref2?.map((ref2, index: number) => ({
                                  ids: {
                                    id: ref2?.id,
                                    index: distrIndex + 1,
                                    ref2_supply_id: ref2?.ref2_supply_id,
                                  },
                                  supplyName: ref2?.ref2,
                                  dates: '',
                                })) || []
                              }
                              headers={{
                                ids: <>#</>,
                                periodStageName: t('academic.periods_list.stages.add.supply'),
                                dates: t('academic.periods_list.stages.add.date'),
                              }}
                              keyExtractor={(e) => e.ids.index.toString()}
                              customRenderers={{
                                ids: ({ ids }) => <>{ids.index} </>,

                                dates: (date) => (
                                  <FormikProvider value={formik}>
                                    <FieldArray
                                      name="periodStages"
                                      render={(arrayHelpers) => (
                                        <LocalizationProvider
                                          dateAdapter={AdapterDateFns}
                                          locale={lang}
                                        >
                                          <Field
                                            name={`periodStages[${date.ids}]`}
                                            component={DateRangePicker}
                                            startText={null}
                                            endText={null}
                                            value={[
                                              values?.calificationModelDates?.distributions
                                                ?.find(
                                                  (e) => e?.ref1_supply_id === dist?.ref1_supply_id
                                                )
                                                ?.supplies?.find(
                                                  (e) =>
                                                    e?.ref2_supply_id === date?.ids?.ref2_supply_id
                                                )?.startDate || null,
                                              values?.calificationModelDates?.distributions
                                                ?.find(
                                                  (e) => e?.ref1_supply_id === dist?.ref1_supply_id
                                                )
                                                ?.supplies?.find(
                                                  (e) =>
                                                    e?.ref2_supply_id === date?.ids?.ref2_supply_id
                                                )?.endDate || null,
                                            ]}
                                            onChange={(e: DateRange<Date>) => {
                                              setFieldValue('calificationModelDates', {
                                                ...values.calificationModelDates,
                                                distributions:
                                                  values?.calificationModelDates?.distributions?.find(
                                                    (_distribution) =>
                                                      _distribution?.ref1_supply_id ===
                                                      dist?.ref1_supply_id
                                                  )
                                                    ? values.calificationModelDates.distributions.map(
                                                        (_distribution) => {
                                                          if (
                                                            _distribution?.ref1_supply_id ===
                                                            dist?.ref1_supply_id
                                                          ) {
                                                            return {
                                                              ..._distribution,
                                                              supplies:
                                                                _distribution.supplies.find?.(
                                                                  (supply) =>
                                                                    supply?.ref2_supply_id ===
                                                                    date?.ids?.ref2_supply_id
                                                                )
                                                                  ? _distribution.supplies.map(
                                                                      (supply) => {
                                                                        if (
                                                                          supply?.ref2_supply_id ===
                                                                          date?.ids?.ref2_supply_id
                                                                        ) {
                                                                          return {
                                                                            ...supply,
                                                                            startDate: e[0],
                                                                            endDate: e[1],
                                                                          };
                                                                        }
                                                                        return supply;
                                                                      }
                                                                    )
                                                                  : _distribution.supplies.concat({
                                                                      ref2_supply_id:
                                                                        date?.ids?.ref2_supply_id,
                                                                      startDate: e[0],
                                                                      endDate: e[1],
                                                                    }),
                                                            };
                                                          }
                                                          return _distribution;
                                                        }
                                                      )
                                                    : values.calificationModelDates.distributions.concat(
                                                        {
                                                          ref1_supply_id: dist?.ref1_supply_id,
                                                          supplies: [
                                                            {
                                                              ref2_supply_id:
                                                                date?.ids?.ref2_supply_id,
                                                              startDate: e[0],
                                                              endDate: e[1],
                                                            },
                                                          ],
                                                        }
                                                      ),
                                              });
                                            }}
                                            renderInput={(
                                              startProps: TextFieldProps,
                                              endProps: TextFieldProps
                                            ) => (
                                              <Grid
                                                container
                                                spacing={2}
                                                justifyContent="center"
                                                alignItems="center"
                                              >
                                                <Grid item xs={12} md={6} lg={4}>
                                                  <InputLabel>
                                                    {t(
                                                      'academic.periods_list.stages.add.stages_dates.start'
                                                    )}
                                                    :
                                                  </InputLabel>
                                                  <TextField
                                                    fullWidth
                                                    {...startProps}
                                                    helperText=""
                                                    {...getFieldProps(
                                                      `periodStages[${date.ids}].startDate`
                                                    )}
                                                  />
                                                  <FormHelperText>
                                                    {t(
                                                      'academic.periods_list.stages.add.stages_dates.start.helper'
                                                    )}
                                                  </FormHelperText>
                                                  <FormHelperText error>
                                                    {t(
                                                      (
                                                        (
                                                          errors?.calificationModelDates
                                                            ?.distributions?.[
                                                            distrIndex
                                                          ] as unknown as Distribution
                                                        )?.supplies?.[
                                                          date.ids.index - 1
                                                        ] as unknown as Supply
                                                      )?.startDate as unknown as string
                                                    )}
                                                  </FormHelperText>
                                                  {/* <ErrorMessage
                                                    name={`periodStages[${date.ids}].startDate`}
                                                  /> */}
                                                </Grid>

                                                <Grid item xs={12} md={6} lg={4}>
                                                  <InputLabel>
                                                    {t(
                                                      'academic.periods_list.stages.add.stages_dates.end'
                                                    )}
                                                    :
                                                  </InputLabel>
                                                  <TextField
                                                    fullWidth
                                                    {...endProps}
                                                    helperText=""
                                                    {...getFieldProps(
                                                      `periodStages[${date.ids}].endDate`
                                                    )}
                                                  />
                                                  <FormHelperText>
                                                    {t(
                                                      'academic.periods_list.stages.add.stages_dates.end.helper'
                                                    )}
                                                  </FormHelperText>
                                                  <FormHelperText error>
                                                    {t(
                                                      (
                                                        (
                                                          errors?.calificationModelDates
                                                            ?.distributions?.[
                                                            distrIndex
                                                          ] as unknown as Distribution
                                                        )?.supplies?.[
                                                          date.ids.index - 1
                                                        ] as unknown as Supply
                                                      )?.endDate as unknown as string
                                                    )}
                                                  </FormHelperText>
                                                  {/* <ErrorMessage
                                                    name={`periodStages[${date.ids}].endDate`}
                                                  /> */}
                                                </Grid>
                                              </Grid>
                                            )}
                                          />
                                        </LocalizationProvider>
                                      )}
                                    />
                                  </FormikProvider>
                                ),
                              }}
                            />
                          </Grid>
                        </>
                      ))}
                    </>
                  </LoadingChildren>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container alignItems="center" justifyContent="right" spacing={2} sx={{ mt: 2 }}>
            <Grid item>
              <Button
                variant="outlined"
                size="large"
                color="primary"
                onClick={() => history.goBack()}
              >
                {t('academic.periods_list.stages.add.back')}
              </Button>
            </Grid>
            <Grid item>
              <LoadingButton
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                isLoading={isLoading}
                // onClick={() => setConfirmModalOpen(true)}
              >
                {t('academic.periods_list.stages.add.stage.submit')}
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </form>

      <PeriodCoursesDialog
        open={open}
        periodId={dataId}
        controlCourses={{ selectedCourses, setSelectedCourses }}
        handleCloseDialog={handleCloseModal}
      />
    </>
  );
};

export default AssignStagesQualificationForm;
