import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import BackButton from '../../../../../shared/components/buttons/BackButton';
import MainCard from '../../../../../shared/components/cards/MainCard';
import ScopeForm from '../components/ScopeForm';

const EditScopeScreen = () => {
  const { t } = useTranslation();

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid container alignItems="center" sx={{ mb: 3 }}>
        <BackButton />
        <Typography variant="h2">{t('maintenance.scopes.edit.title')}</Typography>
      </Grid>
      <ScopeForm />
    </Grid>
  );
};

export default EditScopeScreen;
