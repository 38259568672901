import { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CalendarPicker from '@material-ui/lab/CalendarPicker';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import PickersDay from '@material-ui/lab/PickersDay';
import esLocale from 'date-fns/locale/es';
import { getTime, getMonth, getYear, setMonth } from 'date-fns';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';

import { useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import CalendarPopper from '../../../dashboard/components/builder/CalendarPopper';
import { useGetStudentActivitiesNotPagedQuery } from '../../../dashboard/slices/dashboardApiSlice';
import { useAppSelector } from '../../../../../hooks/useRedux';

// style constant
const useStyles = makeStyles((theme) => ({
  root: {
    '&.css-3ok8xw': {
      width: '80%',
      color: 'black',
    },

    '& div': {
      justifyContent: 'space-between',
      color: 'black',
    },
    '& div > * ': {
      color: 'black',
    },
    '& .MuiPickersDay-root': {
      color: 'black',
    },
    '& .MuiPickersDay-root.Mui-selected': {
      color: 'white !important',
    },
  },
}));

const StudentCalendar = () => {
  const classes = useStyles();

  const history = useHistory();

  const { t } = useTranslation();

  const [date, setDate] = useState<Date | null>(new Date());

  const changeMonthHandler = (month: Date) => {
    if (date) return setDate(setMonth(date, getMonth(month)));

    return setDate(new Date());
  };

  // const changeYearHandler = (year: Date) => {
  //   if (date) return setDate(setYear(date, getYear(date)));

  //   return setDate(new Date());
  // };
  // console.log(changeYearHandler);

  // const [highlightedDays] = useState([1, 2, 15]);

  const { currentStudent: { id: studentId } = { id: undefined } } = useAppSelector(
    (state) => state.access
  );

  const { data, isFetching } = useGetStudentActivitiesNotPagedQuery(
    {
      studentId: studentId!,
      isCalendar: true,
      month: getMonth(date!) + 1,
      year: getYear(date!),
    },
    { skip: !studentId || !date }
  );

  console.log(data);

  const [openDay, setOpenDay] = useState(-1);

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CalendarPicker
              date={date}
              onChange={setDate}
              allowSameDateSelection
              className={`${classes.root}`}
              loading={isFetching}
              onMonthChange={changeMonthHandler}
              // onYearChange={changeYearHandler}
              renderDay={(day, _value, DayComponentProps) => {
                const dayNumber = getTime(day);

                return (
                  <CalendarPopper
                    key={day.toString()}
                    data={data || []}
                    day={dayNumber}
                    dayDate={day}
                    openDay={openDay}
                    setOpenDay={setOpenDay}
                    PickerDayComponent={PickersDay}
                    dayComponentProps={DayComponentProps}
                    // className={classes.popper}
                  />
                );
              }}
            />
          </Grid>
        </Grid>
      </LocalizationProvider>

      {false && (
        <Grid item>
          <Button
            variant="contained"
            color="info"
            sx={{
              border: 'none',
              borderRadius: `12px`,
              px: 4,
              // backgroundColor: `${theme.palette.primary.light}`,
              // color: `${theme.palette.primary.main}`,
              fontWeight: 800,
              fontSize: '1.2rem',
            }}
            onClick={() => history.push('/eventos-visualizar')}
          >
            {t('students.dashboard.events_calendar.view_more_events_btn')}
          </Button>
        </Grid>
      )}
    </>
  );
};

export default StudentCalendar;
