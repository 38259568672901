import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import BackButton from '../components/buttons/BackButton';
import MainCard from '../components/cards/MainCard';
import RegisterSilabusStepper from '../components/register_syllabus/RegisterSilabusStepper';

const RegisterSilabus = () => {
  const { t } = useTranslation();

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid alignItems="center" sx={{ mb: 3, display: 'flex' }}>
        <BackButton />
        <Typography variant="h2">{t('Registro del Syllabus')}</Typography>
      </Grid>

      <RegisterSilabusStepper />
    </Grid>
  );
};

export default RegisterSilabus;
