export interface AcademicPortfolioMatterDetailNote {
  id: number;
  file_detail: string;
  type: string;
  archive: string;
}

export interface AcademicPortfolioMatterDetailMaterial {
  id: number;
  type: string;
  tar: number;
  lec: number;
  tra: number;
  exp: number;
  prom: number;
  exa: number;
  rec: number;
  final_note: number;
  observations: string;
}

interface StatusMatter {
  id: number;
  name: string;
  color: 'orange' | 'warning' | 'success';
}

export const matterStatus: StatusMatter[] = [
  {id: 1, name: 'Aprobado', color: 'success'},
  {id: 2, name: 'Reprobado', color: 'orange'},
]

export const academicPortfolioMatterDetailNotes: AcademicPortfolioMatterDetailNote[] = [
  { id: 1, file_detail: 'Lorem ipsum', type: 'Lorem ipsum', archive: 'Lorem ipsum' },
  { id: 2, file_detail: 'Lorem ipsum', type: 'Lorem ipsum', archive: 'Lorem ipsum' },
  { id: 3, file_detail: 'Lorem ipsum', type: 'Lorem ipsum', archive: 'Lorem ipsum' },
  { id: 4, file_detail: 'Lorem ipsum', type: 'Lorem ipsum', archive: 'Lorem ipsum' },
  { id: 5, file_detail: 'Lorem ipsum', type: 'Lorem ipsum', archive: 'Lorem ipsum' },
  { id: 6, file_detail: 'Lorem ipsum', type: 'Lorem ipsum', archive: 'Lorem ipsum' },
  { id: 7, file_detail: 'Lorem ipsum', type: 'Lorem ipsum', archive: 'Lorem ipsum' },
  { id: 8, file_detail: 'Lorem ipsum', type: 'Lorem ipsum', archive: 'Lorem ipsum' },
  { id: 9, file_detail: 'Lorem ipsum', type: 'Lorem ipsum', archive: 'Lorem ipsum' },
  { id: 10, file_detail: 'Lorem ipsum', type: 'Lorem ipsum', archive: 'Lorem ipsum' },
  { id: 11, file_detail: 'Lorem ipsum', type: 'Lorem ipsum', archive: 'Lorem ipsum' },
  { id: 12, file_detail: 'Lorem ipsum', type: 'Lorem ipsum', archive: 'Lorem ipsum' },
  { id: 13, file_detail: 'Lorem ipsum', type: 'Lorem ipsum', archive: 'Lorem ipsum' },
  { id: 14, file_detail: 'Lorem ipsum', type: 'Lorem ipsum', archive: 'Lorem ipsum' },
  { id: 15, file_detail: 'Lorem ipsum', type: 'Lorem ipsum', archive: 'Lorem ipsum' },
  { id: 16, file_detail: 'Lorem ipsum', type: 'Lorem ipsum', archive: 'Lorem ipsum' },
];

export const academicPortfolioMaterials: AcademicPortfolioMatterDetailMaterial[] = [
    {id: 1, type: '95.00',tar: 95, lec: 10, tra: 100, exp: 99, exa: 10, rec: 12, final_note: 9, observations: 'Aprobado', prom: 10},
    {id: 2, type: '95.00',tar: 95, lec: 10, tra: 100, exp: 99, exa: 10, rec: 12, final_note: 9, observations: 'Aprobado', prom: 10},
    {id: 3, type: '95.00',tar: 95, lec: 10, tra: 100, exp: 99, exa: 10, rec: 12, final_note: 9, observations: 'Aprobado', prom: 10}
]



export interface AcademicPortfolioPartners {
  id:                    number;
  course_id:             string;
  student_record_id:     string;
  final_note:            string;
  observation:           null;
  num_fouls:             string;
  subject_id:            null;
  period_id:             string;
  approval_status:       string;
  approval_reason_id:    string;
  status_id:             string;
  curriculum_id:         string;
  subject_curriculum_id: string;
  student_record:        StudentRecord;
}

export interface StudentRecord {
  id:                 number;
  stud_code_record:   string;
  student_id:         string;
  education_level_id: string;
  mesh_id:            string;
  type_student_id:    string;
  collaborator_id:    string;
  economic_group_id:  string;
  status_id:          string;
  student:            Student;
}

export interface Student {
  id:               number;
  stud_code:        string;
  stud_photo:       string;
  stud_photo_path:  string;
  stud_observation: string;
  user_id:          string;
  type_entry_id:    string;
  jornada_id:       string;
  status_id:        string;
  campus_id:        string;
  modalidad_id:     string;
  image_student:    null;
  user:             User;
}

export interface User {
  id:          number;
  us_username: string;
  email:       string;
  person_id:   string;
  status_id:   string;
  person:      Person;
}

export interface Person {
  id:                             number;
  pers_identification:            string;
  pers_firstname:                 string;
  pers_secondname:                null;
  pers_first_lastname:            string;
  pers_second_lastname:           string;
  pers_gender:                    string;
  pers_email:                     string;
  pers_date_birth:                string;
  pers_direction:                 string;
  pers_phone_home:                string;
  pers_cell:                      string;
  pers_num_child:                 string;
  pers_profession:                string;
  pers_num_bedrooms:              string;
  pers_study_reason:              string;
  pers_num_taxpayers_household:   string;
  pers_has_vehicle:               string;
  pers_has_disability:            string;
  pers_disability_identification: null;
  pers_disability_percent:        null;
  pers_is_provider:               null;
  nationality_id:                 string;
  type_religion_id:               string;
  status_marital_id:              string;
  city_id:                        string;
  current_city_id:                string;
  sector_id:                      string;
  type_identification_id:         string;
  ethnic_id:                      string;
  vivienda_id:                    string;
}
