import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../hooks/useRedux';
import MainCard from '../../../shared/components/cards/MainCard';
import { FaqDeleteDialog } from '../component/delete/FaqDeleteDialog';
import { FaqList } from '../component/FaqList';

const FaqListScreen = () => {
  const { t } = useTranslation();

  const { faqDelete } = useAppSelector((state) => state.faqs);

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">{t('configuration.faqs.title')}</Typography>
      </Grid>
      <FaqList />
      {faqDelete && <FaqDeleteDialog />}
    </Grid>
  );
};

export default FaqListScreen;
