import {useHistory} from 'react-router-dom'
import { Grid, IconButton, Typography, useTheme } from "@material-ui/core"
import { IconArrowNarrowLeft } from "@tabler/icons"
import { useAppSelector } from "../../../../hooks/useRedux"
import MainCard from "../../../shared/components/cards/MainCard"
import AddCollective from "../components/AddCollective"

const AddCollectiveScreen = function () {
    const history = useHistory();

    const theme = useTheme();

    const { currentEducationLevelId, educationLevelList } = useAppSelector((state) => state.educationLevels);

    const educationLevelToAdd = educationLevelList.find((value) => value.id === currentEducationLevelId);

    return <Grid component={MainCard} sx={{ padding: 2 }}>
        <Grid container flexDirection="row" alignItems="center" sx={{ mb: 3 }}>
            <IconButton onClick={() => history.goBack()}>
                <IconArrowNarrowLeft size={30} color={theme.palette.text.dark} />
            </IconButton>
            <Typography variant="h2">Agregar colectivo - {educationLevelToAdd && educationLevelToAdd?.edu_name!}</Typography>
        </Grid>
        <AddCollective />
    </Grid>
}

export default AddCollectiveScreen