import {
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Table,
  useTheme,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { EvaRubricaDetail } from '../../integral_evaluation/interfaces/rubrics.interface';

interface Props {
  data: {
    id?: number;
    name: string;
    description: string;
    user_id: string;
    eva_rubrica_detail: EvaRubricaDetail[];
  };
}

const EvaluationsEvaTypes = ({ data }: Props) => {
  const { eva_rubrica_detail } = data;
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <TableContainer component={Paper} sx={{ border: 1 }}>
      <Table
        sx={{
          minWidth: '650px',
        }}
        aria-label="simple table"
      >
        <TableHead sx={{ backgroundColor: theme.palette.primary[300] }}>
          <TableRow>
            <TableCell align="center">{t('Nombre')}</TableCell>
            <TableCell align="center">{t('Descripción')}</TableCell>
            <TableCell align="center">{t('Acronimo')}</TableCell>
            <TableCell align="center">{t('Estado')}</TableCell>
            <TableCell align="center">{t('Peso')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {eva_rubrica_detail?.map((evaType) => (
            <TableRow key={evaType.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell align="center">{evaType?.eva_types?.name}</TableCell>
              <TableCell align="center">{evaType?.eva_types?.description}</TableCell>
              <TableCell align="center">{evaType?.eva_types?.acronym}</TableCell>
              <TableCell align="center">{evaType?.eva_types?.status}</TableCell>
              <TableCell align="center">{Number(evaType.eva_peso).toFixed(2)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default EvaluationsEvaTypes;
