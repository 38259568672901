import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Button,
  DialogActions,
  DialogContent,
  Slide,
  SlideProps,
  Stack,
  Typography,
  Grid,
  TextField,
  FormHelperText,
  CircularProgress,
  Autocomplete,
  InputAdornment,
  Divider,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '../../../../shared/components/forms/InputLabel';
import ErrorAlert from '../../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../../shared/components/alerts/SuccessAlert';
import { InternalHomologationSubjectSchema } from '../../constants/validationSchemas';

// data available
import { useGetMeshQuery } from '../../slices/meshsApiSlice';
import { useAddInternalHomologationSubjectMutation } from '../../../homologations/slices/homologationsApiSlice';
import { useGetAvailablesCareersQuery } from '../../../education-levels/slices/educationLevelsApiSlice';
import { useGetInternalHomologationSubjectsbyMeshQuery } from '../../slices/meshsApiSlice';

// redux
import { useAppSelector } from '../../../../../hooks/useRedux';

import AnimateButton from '../../../../shared/components/extended/AnimateButton';

import { useFormik } from 'formik';
import ExtendedSideDialog from '../../../../shared/components/dialogs/ExtendedSideDialog';
import InfoAlert from '../../../../shared/components/alerts/InfoAlert';

// import * as yup from 'yup';

// const validationSchema = yup.object({
//   certificateTitle: yup.string().required('titulo es requirida'),
//   graduationDate: yup.string().required('Fecha de graduación es requerida'),
//   educationCalification: yup.number().required('calificación es requirida'),
//   educationObservation: yup.string(),
//   document: yup.mixed(),
//   instituteId: yup
//     .object({
//       id: yup.number(),
//       name: yup.string(),
//     })
//     .required('instituto es requerido'),
//   cityId: yup
//     .object({
//       id: yup.number(),
//       name: yup.string(),
//     })
//     .required('ciudad es requerido'),
//   typeEducationId: yup
//     .object({
//       id: yup.number(),
//       name: yup.string(),
//     })
//     .required('educación es requerido'),
//   statusId: yup.number().required('el estado es requirido'),
// });

const useStyles = makeStyles((theme) => ({
  userAddDialog: {
    '&>div:nth-child(3)': {
      justifyContent: 'flex-end',
      '&>div': {
        margin: '0px',
        borderRadius: '0px',
        maxWidth: '450px',
        maxHeight: '100%',
      },
    },
  },
  paper: {
    minHeight: '100vh',
    width: '100%',
  },
  learningComponents: {
    fontSize: '16px',
    fontWeight: 600,
    color: theme.palette.primary.main,
    marginBottom: '8px',
  },
  selectSubject: {
    fontSize: '16px',
    fontWeight: 600,
    color: theme.palette.primary[800],
    marginBottom: '8px',
  },
  selectSubjectType: {
    fontSize: '14px',
    fontWeight: 600,
    color: theme.palette.secondary.main,
    marginBottom: '8px',
  },
}));

const Transition = React.forwardRef(function Transition(props: SlideProps, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

interface Props {
  open: boolean;
  handleCloseDialog: () => void;
}

const HomologationInternalSubjectDialog = ({ open, handleCloseDialog }: Props) => {
  const classes = useStyles();
  const [showAlerts, setShowAlerts] = useState(true);
  const { meshId } = useParams<{ meshId: string }>();

  const { currentProfile } = useAppSelector((state) => state.access);

  const profileId = currentProfile?.profileId || -1;

  const { data: availablesSubjectsByMesh, isLoading: isLoadingSubjectsByMesh } = useGetMeshQuery(
    {
      profileId,
      meshId: Number(meshId),
    },
    { skip: !open }
  );

  const { data: availablesCareers, isLoading: isLoadingCareers } = useGetAvailablesCareersQuery(
    {
      profileId,
    },
    { skip: !open }
  );

  const [
    addInternalHomologationSubject,
    { isLoading: isLoadingAddIHS, isError, isSuccess, error },
  ] = useAddInternalHomologationSubjectMutation();

  const { errors, touched, handleSubmit, getFieldProps, setFieldValue, values } = useFormik({
    initialValues: {
      subjectCurriculumCurrentId: '',
      carrerId: '',
      meshId: '',
      subjectCurriculumNewId: '',
      relation: '',
      comments: '',
    },
    validationSchema: InternalHomologationSubjectSchema,
    onSubmit: (values) => {
      addInternalHomologationSubject({
        profileId,
        InternalHomologationSubjectPayload: {
          subject_curriculum_current_id: Number(values.subjectCurriculumCurrentId),
          subject_curriculum_new_id: Number(values.subjectCurriculumNewId),
          comments: values.comments,
          relation: Number(values.relation),
        },
      });
      // resetForm();
    },
  });

  const {
    data: availablesSubjectsNew,
    isLoading: isLoadingSubjectsNew,
    isFetching: isFetchingSubjectsNew,
  } = useGetInternalHomologationSubjectsbyMeshQuery(
    {
      profileId,
      educationLevel: Number(values.carrerId),
      curriculumId: Number(values.meshId),
    },
    { skip: !open || !!!Number(values.carrerId) || !!!Number(values.meshId) }
  );

  return (
    <ExtendedSideDialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => {
        handleCloseDialog();
      }}
      className={classes.userAddDialog}
      classes={{
        paper: classes.paper,
      }}
    >
      {/* <PerfectScrollbar> */}
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Typography variant="h2">Agregar materia homologada</Typography>

          {isError && showAlerts && (
            <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
          )}
          {isSuccess && showAlerts && (
            <SuccessAlert
              message="La homologación ha sido creada correctamente."
              handleDismiss={() => setShowAlerts(false)}
            />
          )}
          <Stack spacing={2} sx={{ mt: 4 }}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <InfoAlert
                  message={[
                    'Las materias que se muestran en esta sección deben tener activa en la malla la opción (Puede homologar internamente).',
                  ]}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel>Materia malla</InputLabel>
                {isLoadingSubjectsByMesh ? (
                  <>
                    <TextField
                      disabled
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <CircularProgress size={25} />
                          </InputAdornment>
                        ),
                      }}
                      placeholder="Cargando datos ..."
                    />
                  </>
                ) : (
                  <>
                    <Autocomplete
                      options={
                        availablesSubjectsByMesh?.matter_mesh?.filter(
                          (matter) => matter.can_internal_homologations
                        ) || []
                      }
                      loading={isLoadingSubjectsByMesh}
                      loadingText="Cargando materias disponibles..."
                      getOptionLabel={(option) => option.matter.mat_name}
                      getOptionDisabled={(option) => !option.can_internal_homologations}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={Boolean(
                            errors.subjectCurriculumCurrentId && touched.subjectCurriculumCurrentId
                          )}
                        />
                      )}
                      onChange={(e, value) =>
                        setFieldValue('subjectCurriculumCurrentId', value?.id)
                      }
                      fullWidth
                      clearIcon={null}
                    />

                    {errors.subjectCurriculumCurrentId && touched.subjectCurriculumCurrentId && (
                      <FormHelperText error>{errors.subjectCurriculumCurrentId}</FormHelperText>
                    )}
                  </>
                )}
              </Grid>

              <Grid xs={12} marginY={2}>
                <Divider />
              </Grid>

              <Grid item xs={12}>
                <InputLabel>Carrera</InputLabel>
                {isLoadingCareers ? (
                  <>
                    <TextField
                      disabled
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <CircularProgress size={25} />
                          </InputAdornment>
                        ),
                      }}
                      placeholder="Cargando datos ..."
                    />
                  </>
                ) : (
                  <>
                    <Autocomplete
                      options={availablesCareers || []}
                      loading={isLoadingCareers}
                      loadingText="Cargando carreras disponibles..."
                      getOptionLabel={(option) => option.edu_name}
                      // getOptionDisabled={(option) => option.meshs?.length <= 0}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={Boolean(errors.carrerId && touched.carrerId)}
                        />
                      )}
                      onChange={(e, value) => setFieldValue('carrerId', value?.id)}
                      fullWidth
                      clearIcon={null}
                    />
                    {errors.carrerId && touched.carrerId && (
                      <FormHelperText error>{errors.carrerId}</FormHelperText>
                    )}
                  </>
                )}
              </Grid>

              <Grid item xs={12}>
                <InputLabel>Malla</InputLabel>
                {isLoadingCareers ? (
                  <>
                    <TextField
                      disabled
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <CircularProgress size={25} />
                          </InputAdornment>
                        ),
                      }}
                      placeholder="Cargando datos ..."
                    />
                  </>
                ) : (
                  <>
                    <Autocomplete
                      options={
                        availablesCareers?.find((e) => e.id === Number(values.carrerId))?.meshs ||
                        []
                      }
                      loading={isLoadingCareers}
                      loadingText="Cargando mallas disponibles..."
                      getOptionLabel={(option) => option.mes_name}
                      // getOptionDisabled={(option) => option.meshs?.length <= 0}
                      renderInput={(params) => (
                        <TextField {...params} error={Boolean(errors.meshId && touched.meshId)} />
                      )}
                      onChange={(e, value) => setFieldValue('meshId', value?.id)}
                      fullWidth
                      clearIcon={null}
                    />
                    {errors.meshId && touched.meshId && (
                      <FormHelperText error>{errors.meshId}</FormHelperText>
                    )}
                  </>
                )}
              </Grid>

              <Grid item xs={12}>
                <InputLabel>Materia</InputLabel>
                {isLoadingSubjectsNew || isFetchingSubjectsNew ? (
                  <>
                    <TextField
                      disabled
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <CircularProgress size={25} />
                          </InputAdornment>
                        ),
                      }}
                      placeholder="Cargando datos ..."
                    />
                  </>
                ) : (
                  <>
                    <Autocomplete
                      options={availablesSubjectsNew || []}
                      loading={isLoadingSubjectsNew || isFetchingSubjectsNew}
                      loadingText="Cargando mallas disponibles..."
                      getOptionLabel={(option) => option.matter_rename}
                      // getOptionDisabled={(option) => option.meshs?.length <= 0}
                      noOptionsText="No hya opciones"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={Boolean(
                            errors.subjectCurriculumNewId && touched.subjectCurriculumNewId
                          )}
                        />
                      )}
                      onChange={(e, value) => setFieldValue('subjectCurriculumNewId', value?.id)}
                      fullWidth
                      clearIcon={null}
                    />
                    {errors.subjectCurriculumNewId && touched.subjectCurriculumNewId && (
                      <FormHelperText error>{errors.subjectCurriculumNewId}</FormHelperText>
                    )}
                  </>
                )}
              </Grid>

              <Grid item xs={12}>
                <InputLabel>Porcentaje de Relación</InputLabel>
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ inputProps: { min: 1, max: 100 } }}
                  type="number"
                  placeholder="Ingrese calificación"
                  {...getFieldProps('relation')}
                  error={Boolean(errors.relation && touched.relation)}
                />
                {errors.relation && touched.relation && (
                  <FormHelperText error>{errors.relation}</FormHelperText>
                )}
              </Grid>

              <Grid item xs={12}>
                <InputLabel>Comentario</InputLabel>
                <TextField
                  fullWidth
                  placeholder="Ingrese un comentario"
                  {...getFieldProps('comments')}
                  error={Boolean(errors.comments && touched.comments)}
                />
                {errors.comments && touched.comments && (
                  <FormHelperText error>{errors.comments}</FormHelperText>
                )}
              </Grid>

              {/* end */}
            </Grid>
          </Stack>
        </DialogContent>
        {/* </PerfectScrollbar> */}
        <DialogActions>
          <Stack direction="row" spacing={2}>
            <Button
              variant="text"
              onClick={() => {
                // dispatch(resetCurrentGroup());
                // dispatch(resetSubjectCurriculumSelected());
                handleCloseDialog();
              }}
              color="primary"
            >
              Cancelar
            </Button>

            <AnimateButton>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isLoadingAddIHS}
                endIcon={isLoadingAddIHS && <CircularProgress size={20} />}
              >
                Agregar
              </Button>
            </AnimateButton>
          </Stack>
        </DialogActions>
      </form>
    </ExtendedSideDialog>
  );
};

export default HomologationInternalSubjectDialog;
