import DeleteAlert from '../../../shared/components/dialogs/DeleteAlert';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { useDeleteEducationLevelSubjectMutation } from '../../education-level-subject/slices/ELSApiSlice';
import { resetEducationLevelCareerIdToDelete, setDeleteModalOpen } from '../slices/ELCSlice';

const DeleteELSDialog = () => {
  const dispatch = useAppDispatch();

  const { deleteModalOpen, educationLevelSubjectIdToDelete } = useAppSelector(
    (state) => state.educationLevelSubject
  );
  const { currentProfile } = useAppSelector((state) => state.access);

  const [deleteELS, { isLoading, isSuccess, isError, error }] =
    useDeleteEducationLevelSubjectMutation();

  const handleDelete = () => {
    deleteELS({
      profileId: currentProfile?.profileId!,
      educationLevelSubjectId: educationLevelSubjectIdToDelete!,
    });
  };

  const handleClose = () => {
    dispatch(setDeleteModalOpen(false));
    dispatch(resetEducationLevelCareerIdToDelete());
  };

  return (
    <DeleteAlert
      open={deleteModalOpen}
      onClose={handleClose}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      handleDelete={handleDelete}
      error={error as string}
    />
  );
};

export default DeleteELSDialog;
