export const permissionKey = 'parallels';

export interface SubPermissionKeys {
  read: string;
  create: string;
  edit: string;
  delete: string;
}

export const subPermissionKeys: SubPermissionKeys = {
  read: 'parallels-listar-paralelos',
  create: 'parallels-crear-paralelo',
  edit: 'parallels-actualizar-paralelo',
  delete: 'parallels-borrar-paralelo',
};
