import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { format } from 'date-fns';
import { ISearchTable } from '../../../shared/interfaces/paginatedTable.interface';
import { CashPoint } from '../interfaces/cashPoint.interfaces';

interface EmissionPoint {
  emission_point: string;
  stablishment_point: string;
  user_id: string;
}
interface CashPointsState {
  cashPointList: CashPoint[];

  isOpenStartCashPointModal: boolean;
  isOpenCloseCashPointModal: boolean;
  isOpenCloseBatchCashPointModal: boolean;

  isOpenRefundModal: boolean;

  cashPointToProcess?: number;
  cashPointArrToProcess: number[];
  cashPointToOpen?: EmissionPoint;
  cashPointToRefund?: number;

  isSuccessRefund?: boolean;

  // currentFinancialPeriodId?: number;

  //table states cash point list
  cpTablePage: number;
  cpTablePerPage: number;
  cpTableSearch: string;

  cashPointsTable: ISearchTable;

  //==cashpoint state -> close|open
  cpTableCpState: '1' | '0';
  cpTableDateFilter?: string | undefined;

  totalDailyAmount: number;
  totalAmount: number;

  extraTitle?: string;
  extraSubtitle?: string;
}

const initialState: CashPointsState = {
  cashPointList: [],
  isOpenStartCashPointModal: false,
  isOpenCloseCashPointModal: false,
  isOpenCloseBatchCashPointModal: false,

  isOpenRefundModal: false,

  cashPointArrToProcess: [],

  cpTablePage: 1,
  cpTablePerPage: 5,
  cpTableSearch: '',
  cpTableCpState: '1',

  cashPointsTable: {
    page: 1,
    perPage: 5,
    search: '',
  },

  totalDailyAmount: 0,
  totalAmount: 0,

  cpTableDateFilter: format(new Date(), 'yyyy-MM-dd'),
};

const cashPointsSlice = createSlice({
  name: 'cashPoints',
  initialState,
  reducers: {
    setCashPointList: (state, action: PayloadAction<CashPoint[]>) => {
      state.cashPointList = action.payload;
    },

    //states methods
    setCurrentCashPointPage: (state, action: PayloadAction<number>) => {
      state.cpTablePage = action.payload;
    },
    resetCurrentCashPointPage: (state) => {
      state.cpTablePage = 1;
    },
    setCurrentCashPointPerPage: (state, action: PayloadAction<number>) => {
      state.cpTablePerPage = action.payload;

      cashPointsSlice.caseReducers.resetCurrentCashPointPage(state);
    },
    setCurrentCashPointCpState: (state, action: PayloadAction<'1' | '0'>) => {
      state.cpTableCpState = action.payload;

      cashPointsSlice.caseReducers.resetCurrentCashPointPage(state);
    },
    setCurrentCashPointSearch: (state, action: PayloadAction<string>) => {
      state.cpTableSearch = action.payload;

      cashPointsSlice.caseReducers.resetCurrentCashPointPage(state);
    },
    setCurrentCashPointDateFilter: (state, action: PayloadAction<string | undefined>) => {
      state.cpTableDateFilter = action.payload;

      cashPointsSlice.caseReducers.resetCurrentCashPointPage(state);
    },

    setStartCashPointModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isOpenStartCashPointModal = action.payload;
    },
    openStartModalWith: (state, action: PayloadAction<EmissionPoint | undefined>) => {
      state.cashPointToOpen = action.payload;
      state.isOpenStartCashPointModal = true;
    },

    setCloseCashPointModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isOpenCloseCashPointModal = action.payload;
    },
    openCloseModalWith: (state, action: PayloadAction<number>) => {
      state.cashPointToProcess = action.payload;
      state.isOpenCloseCashPointModal = true;
    },

    setCloseBatchCashPointModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isOpenCloseBatchCashPointModal = action.payload;
    },
    openCloseBatchModalWith: (state, action: PayloadAction<number[]>) => {
      state.cashPointArrToProcess = action.payload;
      state.isOpenCloseBatchCashPointModal = true;
    },

    resetCashPointToProcess: (state) => {
      state.cashPointToProcess = undefined;
    },
    resetCashPointArrToProcess: (state) => {
      state.cashPointArrToProcess = [];
    },
    resetOpenCashPoint: (state) => {
      state.cashPointToOpen = undefined;
    },
    updateCashPointsTable: (state, action: PayloadAction<ISearchTable>) => {
      state.cashPointsTable = action.payload;
    },

    setTotalDailyAmount: (state, action: PayloadAction<number>) => {
      state.totalDailyAmount = action.payload;
    },
    setTotalAmount: (state, action: PayloadAction<number>) => {
      state.totalAmount = action.payload;
    },
    setCahsPointToRefund: (state, action: PayloadAction<number>) => {
      state.cashPointToRefund = action.payload;
    },
    setOpenRefundModal: (state, action: PayloadAction<boolean>) => {
      state.isOpenRefundModal = action.payload;
    },
    setIsSuccessRefund: (state, action: PayloadAction<boolean | undefined>) => {
      state.isSuccessRefund = action.payload;
    },

    setExtraTitle: (state, action: PayloadAction<string | undefined>) => {
      state.extraTitle = action.payload;
    },
    setExtraSubtitle: (state, action: PayloadAction<string | undefined>) => {
      state.extraSubtitle = action.payload;
    },
  },
});

export const {
  setCashPointList,

  //table states
  setCurrentCashPointPage,
  setCurrentCashPointPerPage,
  setCurrentCashPointCpState,
  setCurrentCashPointSearch,
  setCurrentCashPointDateFilter,

  setStartCashPointModalOpen,
  openStartModalWith,
  setCloseCashPointModalOpen,
  openCloseModalWith,
  setCloseBatchCashPointModalOpen,
  openCloseBatchModalWith,
  resetCashPointToProcess,
  resetOpenCashPoint,
  resetCashPointArrToProcess,
  updateCashPointsTable,

  //totales recaudados
  setTotalDailyAmount,
  setTotalAmount,
  setCahsPointToRefund,
  setOpenRefundModal,
  setIsSuccessRefund,

  setExtraTitle,
  setExtraSubtitle,
} = cashPointsSlice.actions;

export default cashPointsSlice.reducer;
