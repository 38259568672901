import * as Yup from 'yup';

export const TemporalBillingDataSchema = Yup.object().shape({
  identificationType: Yup.number()
    .min(1, 'financial.debts_payment.temp_billing_dialog.form.identification_type_required')
    .required('financial.debts_payment.temp_billing_dialog.form.identification_type_required'),
  identification: Yup.string().required(
    'financial.debts_payment.temp_billing_dialog.form.identification_required'
  ),
  socialReason: Yup.string().required(
    'financial.debts_payment.temp_billing_dialog.form.name_required'
  ),
  email: Yup.string()
    .email()
    .required('financial.debts_payment.temp_billing_dialog.form.email_required'),
});
