import { Switch } from 'react-router-dom';

// import { useAppSelector } from '../../../../hooks/useRedux';

import InstitutionInfoScreen from '../screens/InstitutionInfoScreen';
import AddDocumentScreen from '../screens/AddDocumentScreen';

import GuardRoute from '../../../../router/guards/GuardRoute';

// import { permissionKey, subPermissionKeys } from '../constants/permissions';

const InstitutionInfoRouter = () => {
  // const { currentModule } = useAppSelector((state) => state.access);

  // const hasPermission = 'debt' in currentModule?.permissions!;

  // if (!hasPermission) return <Redirect to="/dashboard" />;

  return (
    <Switch>
      <GuardRoute exact path="/informacion-institucional" component={InstitutionInfoScreen} />
      <GuardRoute exact path="/informacion-institucional/agregar" component={AddDocumentScreen} />

      {/* guardRoute */}
      {/* <GuardRoute
        exact
        path="/administrar-cheques"
        component={PaychecksScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.read}
      /> */}
    </Switch>
  );
};

export default InstitutionInfoRouter;
