import { FormHelperText, Grid, InputLabel, TextField } from '@material-ui/core';
import { useFormik } from 'formik';
import { useState } from 'react';
import ErrorAlert from '../../../../../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../../../../../shared/components/alerts/SuccessAlert';
import LoadingButton from '../../../../../../../shared/components/buttons/LoadingButton';
import { ActivityProjectResearch } from '../../../../../interfaces/tasks.interfaces';
import {
  useAddActivityToTaskMutation,
  useEditActivityToTaskMutation,
} from '../../../../../slices/InvestigationLinesApiSlice';

interface ActivityFormProps {
  activityToEdit?: ActivityProjectResearch;
  taskId: number;
}

const ActivityForm = ({ activityToEdit, taskId }: ActivityFormProps) => {
  const [showAlert, setShowAlert] = useState(false);

  const [
    addTask,
    { isLoading: isAddingTask, isError: isErrorAdd, isSuccess: isSuccessAdd, error: errorAdd },
  ] = useAddActivityToTaskMutation();
  const [
    editTask,
    {
      isLoading: isUpdatingTask,
      isError: isErrorUpdate,
      isSuccess: isSuccessUpdate,
      error: errorUpdate,
    },
  ] = useEditActivityToTaskMutation();

  const { getFieldProps, handleSubmit, errors, touched } = useFormik({
    initialValues: {
      title: activityToEdit?.act_title || '',
      observation: activityToEdit?.act_observation || '',
      taskId: taskId,
    },
    // validationSchema: taskValidationSchema,
    onSubmit: async (_values) => {
      if (activityToEdit) {
        await editTask({
          title: _values.title,
          observation: _values.observation,
          taskId: taskId,
          activityId: activityToEdit?.id,
        });
      } else {
        await addTask({
          title: _values.title,
          observation: _values.observation,
          taskId,
        });
      }

      setShowAlert(true);
    },
  });

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        {isErrorAdd && showAlert && (
          <Grid item xs={12}>
            <ErrorAlert message={errorAdd as string} handleDismiss={() => setShowAlert(false)} />
          </Grid>
        )}

        {isSuccessAdd && showAlert && (
          <Grid item xs={12}>
            <SuccessAlert
              message="Tarea agregada correctamente"
              handleDismiss={() => setShowAlert(false)}
            />
          </Grid>
        )}

        {isErrorUpdate && showAlert && (
          <Grid item xs={12}>
            <ErrorAlert message={errorUpdate as string} handleDismiss={() => setShowAlert(false)} />
          </Grid>
        )}

        {isSuccessUpdate && showAlert && (
          <Grid item xs={12}>
            <SuccessAlert
              message="Tarea actualizada correctamente"
              handleDismiss={() => setShowAlert(false)}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <InputLabel htmlFor="title">Título</InputLabel>
          <TextField
            {...getFieldProps('title')}
            placeholder="Ingrese el título"
            error={Boolean(errors.title && touched.title)}
            fullWidth
          />
          {errors.title && touched.title && <FormHelperText error>{errors.title}</FormHelperText>}
        </Grid>
        <Grid item xs={12}>
          <InputLabel htmlFor="observation">Observación</InputLabel>
          <TextField
            {...getFieldProps('observation')}
            error={Boolean(errors.observation && touched.observation)}
            placeholder="Ingrese la observación"
            fullWidth
          />
          {errors.observation && touched.observation && (
            <FormHelperText error>{errors.observation}</FormHelperText>
          )}
        </Grid>

        <Grid item xs={12}>
          <LoadingButton
            type="submit"
            variant="contained"
            color="primary"
            isLoading={isAddingTask || isUpdatingTask}
            title="Guardar"
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default ActivityForm;
