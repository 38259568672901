import { Typography, ToggleButton, Grid, ToggleButtonGroup } from '@material-ui/core';
import DirectionsBusIcon from '@material-ui/icons/DirectionsBus';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { setBusRouteData, setBusRouteIdSelected } from '../slices/busRouteSlice';
import { BusRoute } from '../interfaces/buses.interfaces';

interface Props {
  busRoutes: BusRoute[] | never[];
}

const ToggleButtonsBusRoute = ({ busRoutes }: Props) => {
  // const theme = useTheme();
  const dispatch = useAppDispatch();

  const { busRouteData } = useAppSelector((state) => state.busRoute);

  const handleChange = (event: React.MouseEvent<HTMLElement>, value: BusRoute | null) => {
    if (!value) return;
    dispatch(setBusRouteIdSelected(value.id));
    console.log(value);
    dispatch(setBusRouteData(value));
  };

  return (
    <ToggleButtonGroup
      value={busRouteData}
      exclusive
      onChange={handleChange}
      aria-label="text alignment"
      sx={{
        display: 'grid',
        // gridTemplateColumns: 'repeat(auto-fill, 108px)',

        gridTemplateColumns: 'repeat(auto-fit, minmax(108px, max-content))',
        justifyContent: 'center',

        // gridGap: '1rem',
        '&>.MuiToggleButton-root': {
          border: '1px solid rgba(0, 0, 0, 0.12)!important',
        },

        // flexWrap: 'wrap',
      }}

      // fullWidth
    >
      {/* <Grid container justifyContent="center"> */}
      {busRoutes.map((item, index) => (
        <ToggleButton
          key={index}
          value={item}
          aria-label="left aligned"
          color="primary"
          sx={{
            width: '108px',
          }}
        >
          {/* <Grid item xs={4} sm={3} md={2} lg={1}> */}
          <Grid item mx={3}>
            <DirectionsBusIcon sx={{ fontSize: '25px' }} />
            <Typography variant="h4" color="inherit">
              {item.bus_name}
            </Typography>
          </Grid>
          {/* </Grid> */}
        </ToggleButton>
      ))}
      {/* </Grid> */}
    </ToggleButtonGroup>
  );
};

export default ToggleButtonsBusRoute;
