import { useState, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import StatusChip from '../../../shared/components/badges/StatusChip';
import EditButton from '../../../shared/components/tables/buttons/EditButton';
import DeleteButton from '../../../shared/components/tables/buttons/DeleteButton';
import AddButton from '../../../shared/components/tables/buttons/AddButton';
import {
  updatePublicationsStates,
  openDeleteModalWith,
  setPublicationsList,
} from '../slices/publicationsSlice';
import SearchPaginatedTable, {
  FilterArrow,
} from '../../../shared/components/tables/SearchPaginatedTable';
import { useTableSearch } from '../../../../hooks/useTableSearch';
import { PublicationsResponse } from '../interfaces/publications.interfaces';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { SerializedError } from '@reduxjs/toolkit';
import { statusAvailables } from '../../../shared/constants/resourceStatus';
import Typography from '@material-ui/core/Typography';
import ExtendDialog from './ExtendDialog';
import WizardPublications from './forms/wizard-publications';
import DeletePublicationDialog from './DeletePublicationDialog';
import { FilterData } from './index';
import OmniChipAcronym from '../../../shared/components/badges/OmniChipAcronym';
import publicationStatusAvailables from '../constants/publicationStatus';

interface Props {
  data: PublicationsResponse;
  isLoading: boolean;
  isFetching: boolean;
  error: FetchBaseQueryError | SerializedError | undefined;
  setSort: React.Dispatch<React.SetStateAction<FilterData>>;
  sort: FilterData;
}

const PublicationsTable = ({ data, isLoading, isFetching, error, setSort, sort }: Props) => {
  const { t } = useTranslation();
  // const { hasAccess } = useGuard();
  // const { currentProfile } = useAppSelector((state) => state.access);

  const dispatch = useAppDispatch();

  const { path } = useRouteMatch();
  const history = useHistory();

  const { deleteModalOpen } = useAppSelector((state) => state.publications);

  const { page, perPage, search } = useAppSelector((state) => state.publications.publicationsTable);

  const { setPage, setPerPage, setSearch } = useTableSearch({
    page,
    perPage,
    search,
    updateFunction: updatePublicationsStates,
  });

  const [openDialog, setOpenDialog] = useState(false);

  const [publicationToEdit, setPublicationToEdit] = useState('');

  const [filterArrow, setFilterArrow] = useState<FilterArrow>(sort.publication);

  const handleDelete = async (subjectId: string) => {
    dispatch(openDeleteModalWith(subjectId));
  };

  const handleOpenDialog = (publicationId: string) => {
    setOpenDialog(true);
    setPublicationToEdit(publicationId);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const goToAdd = () => {
    history.push(`${path}/crear/publicacion`);
  };

  const datafetch = data?.data!.map((d, index) => ({
    id: { index: index + 1 + (page - 1) * perPage, id: d.id },
    title: d?.title,
    educationLevel: d?.education_level?.edu_name,
    author: d?.scientific_publication_author,
    typePublication: d?.type_publication,
    publicationStatus: d?.publication_status,
    year: d?.year_publication,
    statusAcronym: d?.status,
    options: '',
  }));

  useEffect(() => {
    if (data) {
      dispatch(setPublicationsList(data.data));
    }
  }, [data, dispatch]);

  useEffect(() => {
    setSort((state) => ({ ...state, publication: filterArrow }));
    //eslint-disable-next-line
  }, [filterArrow]);

  return (
    <>
      <SearchPaginatedTable
        data={datafetch!}
        headers={{
          id: 'N°',
          title: t('Título'),
          educationLevel: 'Carrera',
          author: 'Autor',
          typePublication: 'Tipo de publicación',
          publicationStatus: 'Estado de la publicación',
          year: 'Año',
          statusAcronym: t('academic.subjects.status'),
          options: t('academic.subjects.options'),
        }}
        keyExtractor={(item) => item.id.id.toString()}
        searchPlacehoder={t('lines_research.index.investigationProjectFilter.search.publication')}
        listFilterArrows={{
          title: 'title',
          typePublication: 'type_publication',
          year: 'year_publication',
          statusAcronym: 'status',
        }}
        filterArrow={filterArrow}
        setFilterArrow={setFilterArrow}
        isLoading={isLoading}
        isFetching={isFetching}
        error={error}
        perPage={perPage}
        setPerPage={setPerPage}
        total={data?.total || 0}
        page={page}
        setPage={setPage}
        numHeader={8}
        search={search}
        showFilter
        setSearch={setSearch}
        customRenderers={{
          id: (row) => row.id.index,
          statusAcronym: (row) => (
            <StatusChip
              statusId={
                statusAvailables.find(
                  (status) => status.acronym?.toLowerCase() === row.statusAcronym
                )?.id || 0
              }
            />
          ),
          author: (row) =>
            row.author.map((auth) => (
              <Box>
                <Typography variant="subtitle2" color="inherit">
                  {auth.student?.user.person.pers_full_name ||
                    auth.collaborator?.user.person.pers_full_name}
                </Typography>
              </Box>
            )),
          options: (row) => (
            <>
              {/* {hasAccess('edit') && ( */}
              {/* <Link to={`${path}/${row.id}/editar/publicacion`}>
                <EditButton />
              </Link> */}

              <EditButton handleEdit={() => handleOpenDialog(row.id.id)} />
              {/* )} */}
              {/* {hasAccess('delete') && */}
              <DeleteButton handleDelete={() => handleDelete(row.id.id)} />
              {/* } */}
              {/* {!hasAccess('edit') && !hasAccess('delete') && t('academic.subjects.none')} */}
            </>
          ),

          publicationStatus: ({ publicationStatus }) =>
            publicationStatus && (
              <OmniChipAcronym
                acronym={publicationStatus}
                statusArray={publicationStatusAvailables.map(({ keyword, label, color }) => ({
                  name: label,
                  acronym: keyword,
                  color,
                }))}
              />
            ),
        }}
        ActionButtons={
          <>
            {/* {hasAccess('create') &&  */}

            <AddButton size="large" onClick={goToAdd} />
            {/* } */}
          </>
        }
        customDataCellsProperties={{
          id: {
            align: 'center',
          },
          title: {
            align: 'center',
          },
          educationLevel: {
            align: 'center',
          },
          author: {
            align: 'center',
          },
          typePublication: {
            align: 'center',
          },
          year: {
            align: 'center',
          },
          statusAcronym: {
            align: 'center',
          },
          options: {
            align: 'center',
          },
        }}
      />

      {deleteModalOpen && <DeletePublicationDialog />}

      <ExtendDialog
        open={openDialog}
        handleCloseDialog={handleCloseDialog}
        title="Editar Publicación"
      >
        {openDialog ? (
          <WizardPublications isEdit={true} publicationId={publicationToEdit} />
        ) : (
          <></>
        )}
      </ExtendDialog>
    </>
  );
};

export default PublicationsTable;
