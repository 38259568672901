import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { IconButton, Grid, useTheme, Typography } from '@material-ui/core';
import { IconArrowNarrowLeft } from '@tabler/icons';

import EditDepartmentForm from '../components/EditDepartmentForm';

import MainCardForm from '../../../shared/components/cards/MainCardForm';
import SimpleFormOneColumn from '../../../shared/components/forms/skeletons/SimpleFormOneColumn';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { useLazyGetDepartmentQuery } from '../slices/departmentsApiSlice';
import { setDepartmentListinLine } from '../slices/departmentsSlice';

const EditDepartmentScreen = () => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const { departmentId } = useParams<{ departmentId: string }>();

  const { departmentListinLine } = useAppSelector((state) => state.departments);

  const [getDepartment, { isFetching }] = useLazyGetDepartmentQuery();

  useEffect(() => {
    if (!departmentListinLine.length && !isFetching) {
      getDepartment({
        departmentId: +departmentId,
      })
        .unwrap()
        .then((response) => {
          if (response) dispatch(setDepartmentListinLine([response!]));
        })
        .catch((error) => console.log(error));
    }
  }, [getDepartment, isFetching, departmentId, departmentListinLine.length, dispatch]);

  return (
    <MainCardForm>
      <Grid item xs={12}>
        <Grid item container alignItems="center" gap={1} sx={{ cursor: 'pointer' }}>
          <IconButton onClick={() => history.goBack()}>
            <IconArrowNarrowLeft size={30} color={theme.palette.text.dark} />
          </IconButton>
          <Typography variant="h2">Editar</Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} md={8} lg={6} sx={{ mt: 2 }}>
        {Boolean(departmentListinLine.length) ? <EditDepartmentForm /> : <SimpleFormOneColumn />}
      </Grid>
    </MainCardForm>
  );
};

export default EditDepartmentScreen;
