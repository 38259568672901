import { Grid } from '@material-ui/core';
import { useState } from 'react';
import OmniChip from '../../../../shared/components/badges/OmniChip';
import MainCard from '../../../../shared/components/cards/MainCard';
import { renderSkeletonTable } from '../../../../shared/helpers/render';
import MatterDetailTable from '../../components/tables/MatterDetailTable';
import { matterStatus } from '../../interfaces/AcademicPortfolio.interfaces';
import { useGetAcademicPorfolioMatterDetailMaterialsQuery } from '../../slices/AcademicPortfolioApiSlice';

const MatterDetailMaterial = function () {
  const { data, isLoading, isFetching, error } = useGetAcademicPorfolioMatterDetailMaterialsQuery();

  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(5);
  const [search, setSearch] = useState<string>('');

  const dataFetch =
    data &&
    data?.map((value) => ({
      id: value.id,
      type: value.type,
      tar: value.tar,
      lec: value.lec,
      tra: value.tra,
      exp: value.exp,
      prom: value.prom,
      exa: value.exa,
      rec: value.rec,
      final_note: value.final_note,
      observations: value.final_note,
    }));

  return (
    <Grid component={MainCard}>
      {isLoading ? (
        renderSkeletonTable(4, 6)
      ) : (
        <MatterDetailTable
          data={dataFetch!}
          headers={{
            id: 'N°',
            type: 'Tipo',
            tar: 'TAR',
            lec: 'LEC',
            tra: 'TRA',
            exp: 'EXP',
            prom: 'PROM',
            exa: 'EXA',
            rec: 'REC',
            final_note: 'N. Final',
            observations: 'Observaciones',
          }}
          page={page}
          setPage={setPage}
          perPage={perPage}
          error={error}
          numHeader={11}
          isFetching={isFetching}
          isLoading={isLoading}
          keyExtractor={(value) => value.id.toString()}
          setPerPage={setPerPage}
          searchPlacehoder="Buscar"
          searchField={true}
          search={search}
          setSearch={setSearch}
          customRenderers={{
            observations: (row) => <OmniChip variant={0} id={2} statusArray={matterStatus} />,
          }}
        />
      )}
    </Grid>
  );
};

export default MatterDetailMaterial;
