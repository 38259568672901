import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';

import { useAppDispatch, useAppSelector } from '../../../../../../../hooks/useRedux';
import SearchPaginatedTable from '../../../../../../shared/components/tables/SearchPaginatedTable';
import {
  setApplicationsList,
  updateApplicationTableStates,
} from '../../../../slices/investigationLinesSlice';
import {
  useGetCommunityEngagementApplicationsQuery,
  useAddApproveApplicationMutation,
} from '../../../../slices/InvestigationLinesApiSlice';
import { useTableSearch } from '../../../../../../../hooks/useTableSearch';
import ConfirmAlertDialog from './ConfirmAlertDialog';
import { Project } from '../../../../interfaces/projectResearch.interfaces';
import { StudentApprove } from '../../../../interfaces/applicationsPayload.interfaces';
import OmniChipAcronym from '../../../../../../shared/components/badges/OmniChipAcronym';
import statusApplication from '../../../../constants/lists/statusApplication';

interface Props {
  data: Project;
}

const ApplicationProjectsTable = ({ data: dataProject }: Props) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { projectId } = useParams<{ projectId: string }>();

  const [selected, setSelected] = useState<StudentApprove[]>([]);

  const [isOpen, setIsOpen] = useState(false);

  const { page, perPage, search, filterArrow } = useAppSelector(
    (state) => state.investigationLines.applicationsTable
  );

  const { setPage, setPerPage, setSearch, setFilterArrow } = useTableSearch({
    page,
    perPage,
    search,
    filterArrow,
    updateFunction: updateApplicationTableStates,
  });

  const { isLoading, error, data, isFetching } = useGetCommunityEngagementApplicationsQuery({
    page,
    perPage,
    search,
    projectId,
    ...(filterArrow && {
      type_sort: filterArrow?.direction,
      sort: filterArrow?.name,
    }),
  });

  const [
    approveStudents,
    {
      isLoading: isLoadingApprove,
      isSuccess: isSuccessApprove,
      isError: isErrorApprove,
      error: errorApprove,
    },
  ] = useAddApproveApplicationMutation();

  const controlSelectionArrString = (
    selected: StudentApprove[],
    itemId: StudentApprove
  ): StudentApprove[] => {
    const selectedIndex = selected.map((e) => e.app_code).indexOf(itemId.app_code);
    let newSelected: StudentApprove[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, itemId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    return newSelected;
  };

  const handleClick = (event: React.ChangeEvent<HTMLInputElement>, appId: StudentApprove) => {
    const newSelected = controlSelectionArrString(selected, appId);

    setSelected(newSelected);
  };
  //check all
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected =
        data?.data?.map((dt) => ({
          app_code: dt.app_code,
          user: dt.user,
          student_record_id: dt.student_record_id,
          student_id: dt.student_id,
        })) || [];
      setSelected(newSelected);
      return;
    }

    setSelected([]);
  };

  //cargar en un estado la vista actual de la tabla
  useEffect(() => {
    if (data) {
      dispatch(setApplicationsList(data.data));
    }
  }, [data, dispatch]);

  const approveStudentsFunction = async () => {
    await approveStudents({
      ApplicationPayload: {
        end_date: dataProject.end_date_real,
        start_date: dataProject.start_date,
        project_research_id: dataProject.id,
        comment: 'Estudiantes aprobados',
        total_hours: dataProject.total_hours,
        students: selected,
      },
    });
  };

  const datafetch = data?.data!.map((dt, i, arr) => ({
    select: dt,
    id: i + ((page - 1) * perPage + 1),
    // name: dt.project_name,
    user: dt.user,
    numberSubjectsApproved: dt.number_subjects_approved,
    career: dt.career,
    description: dt.app_description,
    statusId: dt.current_status,
    // options: dt,
  }));

  return (
    <>
      <SearchPaginatedTable
        data={datafetch!}
        headers={{
          select: (
            <Checkbox
              color="primary"
              disabled={!data?.data?.length}
              indeterminate={
                data?.data &&
                !!selected.length &&
                data?.data?.filter(({ app_code }) => selected.some((e) => e.app_code === app_code))
                  .length !== data?.data?.length
              }
              checked={
                // data?.data &&
                !!selected.length &&
                data?.data?.filter(({ app_code }) => selected.some((e) => e.app_code === app_code))
                  .length === data?.data?.length
              }
              onChange={handleSelectAllClick}
              inputProps={{
                'aria-label': 'select all cash points',
              }}
            />
          ) as any,
          id: 'N°',
          // name: t('academic.areas.name'),
          user: 'Usuario',
          career: 'Carrera',
          numberSubjectsApproved: 'Cantidad de materias aprobadas ',
          description: t('academic.areas.description'),
          statusId: t('academic.areas.status'),
          // options: t('academic.areas.options'),
        }}
        keyExtractor={(item) => item.select.app_code}
        searchPlacehoder={t('academic.areas.search')}
        isLoading={isLoading}
        isFetching={isFetching}
        error={error}
        perPage={perPage!}
        setPerPage={setPerPage}
        total={data?.total || 0}
        page={page!}
        setPage={setPage}
        numHeader={4}
        search={search!}
        setSearch={setSearch}
        customRenderers={{
          select: (row) => (
            <>
              <Checkbox
                checked={selected.some((r) => r.app_code === row.select.app_code)}
                onChange={(e) =>
                  handleClick(e, {
                    app_code: row.select.app_code,
                    student_id: row.select.student_id,
                    student_record_id: row.select.student_record_id,
                    user: row.select.user,
                  })
                }
                // disabled={Number(row.) === 1}
              />
            </>
          ),

          statusId: (row) => (
            <OmniChipAcronym
              acronym={row.statusId}
              statusArray={statusApplication.map(({ keyword, label, color }) => ({
                name: label,
                acronym: keyword,
                color,
              }))}
            />
          ),
        }}
        customDataCellsProperties={{
          id: { align: 'center' },
          select: { align: 'center' },
          user: {
            align: 'center',
          },
          description: {
            align: 'center',
          },
          statusId: {
            align: 'center',
          },

          // options: {
          //   align: 'center',
          // },
          // index: {
          //   align: 'center',
          // },
        }}
        customHeadersCellsProperties={
          {
            // options: {
            //   align: 'center',
            // },
            // index: {
            //   align: 'center',
            // },
          }
        }
        ActionButtons={
          <>
            <Button
              disabled={!selected.length}
              variant="contained"
              color={'error' as any}
              sx={{ margin: 1 }}
            >
              Rechazar
            </Button>
            <Button
              disabled={!selected.length}
              variant="contained"
              onClick={() => setIsOpen(true)}
              sx={{ margin: 1 }}
            >
              Aprobar
            </Button>
          </>
        }
        listFilterArrows={{
          user: 'user',
          description: 'app_description',
          numberSubjectsApproved: 'number_subjects_approved',
          // statusId: 'current_status',
        }}
        filterArrow={filterArrow}
        setFilterArrow={setFilterArrow}
      />
      <ConfirmAlertDialog
        open={isOpen}
        setOpen={setIsOpen}
        isLoading={isLoadingApprove}
        isSuccess={isSuccessApprove}
        event={() => approveStudentsFunction()}
        isError={isErrorApprove}
        error={errorApprove}
      />
    </>
  );
};

export default ApplicationProjectsTable;
