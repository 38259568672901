import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Typography, Box, Tooltip, IconButton } from '@material-ui/core';
import { IconLayoutGrid, IconList, IconArrowNarrowLeft } from '@tabler/icons';
import { useAppSelector } from '../../../../hooks/useRedux';
import MainCard from '../../../shared/components/cards/MainCard';

import DeleteELSDialog from '../components/DeleteELSDialog';
import ELSTable from '../components/ELSTable';
import ELSGridView from '../components/ELSGridView';
// import AddSubjects from './AddSubjects';

const ELSScreen = () => {
  const { educationLevelSubjectIdToDelete } = useAppSelector(
    (state) => state.educationLevelSubject
  );
  const [viewMode, setViewMode] = useState<'table' | 'grid'>('grid');

  const history = useHistory();

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid item container alignItems="center" justifyContent="space-between" gap={1}>
        <Grid item>
          <Grid item container alignItems="center" spacing={4}>
            <IconButton onClick={() => history.goBack()} sx={{ display: 'block' }}>
              <IconArrowNarrowLeft size={30} />
            </IconButton>
            <Typography variant="h2">Listado de materias de nivelación y su grupo</Typography>
          </Grid>
        </Grid>
        <Box>
          <Tooltip title="Vista de tabla">
            <IconButton onClick={() => setViewMode('table')}>
              <IconList size={20} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Vista de tarjetas">
            <IconButton onClick={() => setViewMode('grid')}>
              <IconLayoutGrid size={20} />
            </IconButton>
          </Tooltip>
        </Box>
      </Grid>
      {viewMode === 'table' && <ELSTable />}
      {viewMode === 'grid' && <ELSGridView />}

      {educationLevelSubjectIdToDelete && <DeleteELSDialog />}
    </Grid>
  );
};

export default ELSScreen;
