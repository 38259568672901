import { Grid, Typography, Skeleton } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../hooks/useRedux';
// import { useAppSelector } from '../../../../hooks/useRedux';
import MainCard from '../../../shared/components/cards/MainCard';
import CalendarFormInitial from '../components/CalendarFormInitial';
import { useGetDaysTypesAvailablesQuery } from '../slices/acPlanPostgraduateApiSlice';
import CalendarSelection from './CalendarSelection';

const AcPlanPostgraduateScreen = () => {
  const { t } = useTranslation();

  const { calendarIdSelected } = useAppSelector((state) => state.academicPlanningPosgraduate);

  const {
    isLoading: isLoadingDaysTypes,
    // error: errorDaysTypes,
  } = useGetDaysTypesAvailablesQuery({});

  return (
    <div>
      <Grid component={MainCard} sx={{ padding: 2 }}>
        <Grid sx={{ mb: 3 }}>
          <Typography variant="h2">{t('posgraduate.title')}</Typography>
        </Grid>
        {isLoadingDaysTypes ? (
          <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
            <Grid item xs={8} md={4}>
              <Skeleton variant="rectangular" width="100%" height="10vh" />
            </Grid>

            <Grid item xs={8} md={9} mt={2}>
              <Skeleton variant="rectangular" width="100%" height="40vh" />
            </Grid>
          </Grid>
        ) : (
          <>
            <CalendarSelection />
            {calendarIdSelected && <CalendarFormInitial />}
          </>
        )}
      </Grid>
    </div>
  );
};

export default AcPlanPostgraduateScreen;
