import { Redirect, useHistory } from 'react-router-dom';
import { Grid, IconButton, Typography, useTheme } from '@material-ui/core';
import { IconArrowNarrowLeft } from '@tabler/icons';

import MainCard from '../../../shared/components/cards/MainCard';
import EditRoleForm from '../components/EditRoleForm';
import { useAppSelector } from '../../../../hooks/useRedux';
import { useTranslation } from 'react-i18next';

const EditRoleScreen = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const history = useHistory();

  const { rolesList } = useAppSelector((state) => state.roles);

  if (!rolesList.length) return <Redirect to={`/administrar-roles`} />;

  return (
    <Grid container component={MainCard} sx={{ padding: 2 }} contentSX={{ width: 1 }}>
      <Grid item xs={12}>
        <Grid item container alignItems="center" gap={1} sx={{ cursor: 'pointer' }}>
          <IconButton onClick={() => history.goBack()}>
            <IconArrowNarrowLeft size={30} color={theme.palette.text.dark} />
          </IconButton>
          <Typography variant="h2">{t('configuration.roles.edit.title')}</Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} md={8} lg={6} sx={{ mt: 2, width: 1 }}>
        <EditRoleForm />
      </Grid>
    </Grid>
  );
};

export default EditRoleScreen;
