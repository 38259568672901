import { IconButton, Tooltip } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

interface BackButtonProps {
  to?: string | number;
  title?: string;
  adicionalFunction?: () => void;
}

const BackButton = ({ to, title, adicionalFunction }: BackButtonProps) => {
  const { t } = useTranslation();

  const history = useHistory();

  const handleBack = () => {
    if (to) {
      adicionalFunction && adicionalFunction();
      history.push('/' + to);
      return;
    }
    adicionalFunction && adicionalFunction();
    history.goBack();
  };

  return (
    <Tooltip title={title || t('shared.go_back_button.tooltip')}>
      <IconButton
        onClick={handleBack}
        sx={{
          marginRight: '1em',
          color: 'black',
        }}
      >
        <ArrowBack />
      </IconButton>
    </Tooltip>
  );
};

export default BackButton;
