import { mainApi } from '../../../../api/mainApi';
import { middlewareApi } from '../../../middleware/middlewareApi';
import { AuthorsResponse, Author } from '../../authors/interfaces/authors.interface';

const tagsApi = middlewareApi.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * @GET tags
     */
    getTags: builder.query<
      AuthorsResponse,
      {
        page?: number;
        size?: number;
        search?: string;
        sort?: string;
        type_sort?: string;
      }
    >({
      queryFn: async ({ page, size, search, sort, type_sort }) => {
        try {
          const { data } = await mainApi.get<AuthorsResponse>('/books-tags?tag=1', {
            params: {
              page,
              size,
              search,
              sort,
              type_sort,
            },
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),
    /**
     * @GET all tags by type
     */
    getAllTagsByType: builder.query<
      Author[],
      {
        type_tag: string;
      }
    >({
      queryFn: async ({ type_tag }) => {
        try {
          const { data } = await mainApi.get<Author[]>('/books-tags?data=all', {
            params: {
              type_tag,
            },
          });
          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
    }),
  }),
});

export const { useGetTagsQuery, useGetAllTagsByTypeQuery } = tagsApi;
