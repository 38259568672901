import { useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import { useAppSelector } from '../../../../../../hooks/useRedux';
import {
  PeriodFormConfigData,
  PeriodOfferAcademicData,
  StageGenerationQuote,
} from '../../../constants/validation-schemas';
import { useAddPeriodMutation } from '../../../slices/periodsApiSlice';
import { PeriodFormCreateStepper, FormikStep } from './index';
import PeriodFormConfig from './PeriodFormConfig';
import PeriodOfferAcademic from './PeriodFormOfferAcademic';
import ErrorAlert from '../../../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../../../shared/components/alerts/SuccessAlert';
import { navigateTop } from '../../../../../teachers/virtual-classroom/evaluations/helpers';
import PeriodFormConfirm from './PeriodFormConfirm';
import { StagesTabs } from '../tabs/index';
// import { PeriodForm } from '../../../interfaces/period-form.interfaces';
import { StagePayload } from '../../../interfaces/periods.interfaces';
import {
  // INGRESO_DOCENTES_CURSO,
  // REGISTRO_MATERIAS_ADMIN,
  // GENERACION_CUOTAS,
  // INICIO_CLASES,
  // INICIO_PROGRAMA_MAESTRIA,
  // REGISTRO_ASIGNATURAS,
  // REGISTRO_MATERIAS_PORTAL_ESTUDIANTIL,
  // SOLICITUD_APERTURA_MATERIAS_GRADO,
  STAGES,
} from '../../../constants/stages';
import { format } from 'date-fns';
import { FormikValues } from 'formik';
import { PeriodForm, Stage } from '../../../interfaces/period-form.interfaces';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

export const PeriodStepper = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const [showAlerts, setShowAlerts] = useState(false);

  const [addPeriod, { isError, error, isSuccess }] = useAddPeriodMutation();

  const { currentProfile } = useAppSelector((state) => state.access);

  const { academicOfferConfig } = useAppSelector((state) => state.periods);

  const config = academicOfferConfig?.map((e) => e.stg_acronym);

  const [blockButtons, setBlockButtons] = useState(false);

  const obtainDateStage = (stage: any): StagePayload => {
    const {
      course_id,
      education_level_id,
      end_date,
      parallel_id,
      start_date,
      status_id,
      stg_acronym,
    } = stage;
    return {
      course_id,
      education_level_id,
      parallel_id,
      start_date: format(start_date!, 'yyyy-MM-dd'),
      end_date: format(end_date!, 'yyyy-MM-dd'),
      status_id,
      stg_acronym,
    };
  };

  const obtainStages = (values: FormikValues): StagePayload[] => {
    const stages: StagePayload[] = [];

    for (let i = 0; i < STAGES.length; i++) {
      if (config.includes(STAGES[i].use_case)) {
        const field = values[STAGES[i].field as unknown as keyof PeriodForm] as Stage;
        if (Array.isArray(field)) {
          for (let e = 0; e < field?.length; e++) {
            stages.push(obtainDateStage(field[e]));
          }
        } else {
          stages.push(obtainDateStage(field));
        }
      }
    }
    return stages;
  };

  const initialValues = {
    // Seleccionar oferta académica
    academicOfferId: 0,
    campusIds: [],
    hourhandsIds: [],
    // Configuración del periodo
    name: '',
    minMatter: 1,
    maxMatter: 1,
    periodTypeId: 0,
    numFees: 0,
    isPayEnrollment: false,
    feesPerEnrollement: 0,
    feesEnrollmentUnique: false,
    tp_num_fees: 0,
    tp_pay_enrollment: '0',
    additional_fees: [],
    numFouls: 0,
    status: true,
    can_access_av_debt_expire: false,
    // Etapas

    generation_quote: [],

    entry_teacher: {
      start_date: null,
      end_date: null,
      stg_acronym: '',
      status_id: 1,
      education_level_id: null,
      parallel_id: null,
      course_id: null,
    },

    register_matter: {
      start_date: null,
      end_date: null,
      status_id: 1,
      stg_acronym: '',
      education_level_id: null,
      parallel_id: null,
      course_id: null,
    },

    register_matter_grade: {
      start_date: null,
      end_date: null,
      stg_acronym: '',
      status_id: 1,
      education_level_id: null,
      parallel_id: null,
      course_id: null,
    },

    register_matter_admin: {
      start_date: null,
      end_date: null,
      stg_acronym: '',
      status_id: 1,
      education_level_id: null,
      parallel_id: null,
      course_id: null,
    },

    register_matter_student_portal: {
      start_date: null,
      stg_acronym: '',
      end_date: null,
      status_id: 1,
      education_level_id: null,
      parallel_id: null,
      course_id: null,
    },

    request_matter_grade: {
      start_date: null,
      end_date: null,
      stg_acronym: '',
      status_id: 1,
      education_level_id: null,
      parallel_id: null,
      course_id: null,
    },

    start_program_maestry: {
      start_date: null,
      end_date: null,
      stg_acronym: '',
      status_id: 1,
      education_level_id: null,
      parallel_id: null,
      course_id: null,
    },

    start_class: {
      start_date: null,
      end_date: null,
      stg_acronym: '',
      status_id: 1,
      education_level_id: null,
      parallel_id: null,
      course_id: null,
    },
  };

  return (
    <>
      <Grid container sx={{ mt: 2, mb: 2 }}>
        <Grid item xs={12}>
          {isError && showAlerts && (
            <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
          )}

          {isSuccess && showAlerts && (
            <SuccessAlert
              message={t('academic.periods_list.add.success')}
              handleDismiss={() => setShowAlerts(false)}
              timeoutCallback={{
                timer: 3,
                callbackFn: () => {
                  console.log('closing...');
                  history.push('/administrar-periodos');
                },
              }}
            />
          )}
        </Grid>
      </Grid>
      <PeriodFormCreateStepper
        initialValues={initialValues}
        validateOnChange={false}
        onSubmit={async (values) => {
          setBlockButtons(true);
          const stages = obtainStages(values);

          await addPeriod({
            profileId: currentProfile?.profileId!,
            periodPayload: {
              per_name: values.name,
              type_period_id: values.periodTypeId,
              per_min_matter_enrollment: values.minMatter,
              per_max_matter_enrollment: values.maxMatter,
              per_fees_enrollment: values.feesPerEnrollement,
              per_num_fees: values.numFees,
              per_pay_enrollment: values.isPayEnrollment ? (1 as any) : (0 as any),
              campus: values.campusIds,
              can_access_av_debt_expire: values.can_access_av_debt_expire,
              hourhands: values.hourhandsIds,
              offers: [values.academicOfferId],
              status_id: 1,
              num_fouls: values.numFouls,
              stages: stages,
            },
          });
          setShowAlerts(true);
          navigateTop();
        }}
        blockButtons={blockButtons}
      >
        <FormikStep
          label={t('academic.periods_list.add.stage_1')}
          validationSchema={PeriodOfferAcademicData}
        >
          <Box paddingBottom={3} paddingTop={3}>
            <PeriodOfferAcademic />
          </Box>
        </FormikStep>
        <FormikStep
          label={t('academic.periods_list.add.stage_2')}
          validationSchema={PeriodFormConfigData}
        >
          <Box paddingBottom={3} paddingTop={3}>
            <PeriodFormConfig />
          </Box>
        </FormikStep>
        <FormikStep
          label={t('academic.periods_list.add.stage_3')}
          validationSchema={StageGenerationQuote}
        >
          <Box paddingBottom={3} paddingTop={3}>
            <StagesTabs />
          </Box>
        </FormikStep>
        <FormikStep label={t('academic.periods_list.add.stage_4')}>
          <Box paddingBottom={3} paddingTop={3}>
            <PeriodFormConfirm />
          </Box>
        </FormikStep>
      </PeriodFormCreateStepper>
    </>
  );
};
