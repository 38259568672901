import { Grid } from '@material-ui/core';
import { useAppSelector } from '../../../../../../../hooks/useRedux';
import EvaluationInfoBox from '../EvaluationInfoBox';
import LoadingScreen from '../../../../../../shared/screens/LoadingScreen';
import EvaluationPreviewScope from './EvaluationPreviewScope';

const EvaluationPreview = () => {
  const { evaluationsIdSelected, evaluationsList } = useAppSelector((state) => state.evaluations);

  const evaluation = evaluationsList.find((_ev) => _ev.uuid === evaluationsIdSelected);

  const scopes = Object.values(
    evaluation?.questions?.reduce<{
      [key: string]: {
        uuid: string;
        name: string;
        status: 'A' | 'I';
        description: string;
        questions: any[];
        weight: number;
      };
    }>(
      (acc, question) => ({
        ...acc,
        [question.ambitos.uuid]: {
          uuid: question.ambitos.uuid,
          name: question.ambitos?.name!,
          status: question.ambitos?.status! as 'A' | 'I',
          description: question.ambitos?.description!,
          questions: [...(acc[question.ambitos.uuid]?.questions || []), question],
          weight:
            evaluation?.categories?.find((cat) => cat.uuid === question.ambitos.uuid)?.weight || 0,
        },
      }),
      {}
    ) || []
  );

  return evaluation ? (
    <Grid container>
      <Grid item xs={12} mt={2}>
        <EvaluationInfoBox evaluation={evaluation} />
      </Grid>

      <Grid item xs={12}>
        {scopes.map((scope) => (
          <EvaluationPreviewScope scope={scope} />
        ))}
      </Grid>
    </Grid>
  ) : (
    <LoadingScreen />
  );
};

export default EvaluationPreview;
