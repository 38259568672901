import { Grid, Typography } from '@material-ui/core';
import MainCard from '../../../shared/components/cards/MainCard';
import ActaApplicationForm from '../forms/ActaApplicationForm';

const ActaApplicationScreen = () => {
  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">Acta de Consejo</Typography>
      </Grid>

      <ActaApplicationForm />
    </Grid>
  );
};

export default ActaApplicationScreen;
