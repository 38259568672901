export const DaysWeeks = [
  {
    name: 'Domingo',
    id: '1',
    day: 'Sunday',
  },
  {
    name: 'Lunes',
    id: '2',
    day: 'Monday',
  },
  {
    name: 'Martes',
    id: '3',
    day: 'Tuesday',
  },
  {
    name: 'Miércoles',
    id: '4',
    day: 'Wednesday',
  },
  {
    name: 'Jueves',
    id: '5',
    day: 'Thursday',
  },
  {
    name: 'Viernes',
    id: '6',
    day: 'Friday',
  },
  {
    name: 'Sábado',
    id: '7',
    day: 'Saturday',
  },
];
