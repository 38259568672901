import { Stack, Typography } from '@material-ui/core';
import imge from '../../../assets/images/illustrations/warninglite-nbs.png';

interface props {
  message?: string;
}

const NoCollaboratorInfo = ({ message }: props) => {
  return (
    <Stack sx={{ alignItems: 'center', flex: 1 }}>
      <img src={imge} style={{ maxWidth: 520 }} alt="no_collaborator_info" />
      <br />
      <Typography variant="h5" sx={{ maxWidth: 400, textAlign: 'center' }}>
        {message ||
          'Esta opción no se encuentra disponible por el momento. El usuario no cuenta con información del colaborador porfavor comuníquese con el administrador'}
      </Typography>
    </Stack>
  );
};

export default NoCollaboratorInfo;
