import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import OptionsTemplateForm from '../components/OptionsTemplateForm';
import {
  setOptionsTemplateIdSelected,
  setOptionsTemplateList,
} from '../slices/optionsTemplateSlice';
import { useLazyGetOptionsTemplateQuery } from '../slices/optionsTemplateApiSlice';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/useRedux';
import { Grid, Typography } from '@material-ui/core';
import BackButton from '../../../../../shared/components/buttons/BackButton';
import SimpleFormOneColumn from '../../../../../shared/components/forms/skeletons/SimpleFormOneColumn';
import MainCard from '../../../../../shared/components/cards/MainCard';

const EditOptionsTemplateScreen = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  const { optionsTemplateIdSelected, optionsTemplateList } = useAppSelector(
    (state) => state.optionsTemplate
  );

  const [getOptionsTemplate, { isLoading }] = useLazyGetOptionsTemplateQuery();

  useEffect(() => {
    if (!optionsTemplateIdSelected && id) {
      dispatch(setOptionsTemplateIdSelected(id!));
    }

    if (!optionsTemplateList?.length && id) {
      getOptionsTemplate({ id }).then((optionsTemplate) => {
        dispatch(setOptionsTemplateList([optionsTemplate.data!]));
      });
    }

    return () => {
      dispatch(setOptionsTemplateIdSelected(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid container alignItems="center" sx={{ mb: 3 }}>
        <BackButton adicionalFunction={() => dispatch(setOptionsTemplateIdSelected(null))} />
        <Typography variant="h2">{t('maintenance.options_template.edit.title')}</Typography>
      </Grid>
      {optionsTemplateIdSelected && id && optionsTemplateList?.length && !isLoading ? (
        <OptionsTemplateForm />
      ) : (
        <SimpleFormOneColumn />
      )}
    </Grid>
  );
};

export default EditOptionsTemplateScreen;
