import { Switch, Redirect } from 'react-router-dom';

import { useAppSelector } from '../../../../hooks/useRedux';

import GenerateCreditNoteScreen from '../screens/GenerateCreditNoteScreen';

import GuardRoute from '../../../../router/guards/GuardRoute';

// import { permissionKey, subPermissionKeys } from '../constants/permissions';

const GenerateCreditNoteRouter = () => {
  const { currentModule } = useAppSelector((state) => state.access);

  const hasPermission = 'economicGroup' in currentModule?.permissions!;

  if (!hasPermission) return <Redirect to="/dashboard" />;

  return (
    <Switch>
      <GuardRoute
        exact
        path="/administrar-caja/generar-nota-credito"
        component={GenerateCreditNoteScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.read}
      />
    </Switch>
  );
};

export default GenerateCreditNoteRouter;
