import { useEffect } from 'react';
import {
  Autocomplete,
  Grid,
  TextField,
  FormHelperText,
  Skeleton,
  CircularProgress,
} from '@material-ui/core';
import InputLabel from '../../../../shared/components/forms/InputLabel';
import DatePicker from '@material-ui/lab/DatePicker';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import esLocale from 'date-fns/locale/es';

import { useFormikContext } from 'formik';
import { useGetCatalogByAcronymQuery } from '../../../../shared/slices/catalogApi';
import { useGetCatalogByKeywordQuery } from '../../../../shared/slices/catalogApi';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import { Catalog } from '../../interfaces/person.interface';
import { useLazyGetCollaboratorByCiQuery } from '../../slices/collaboratorsApiSlice';
import { useLazyGetPersonInformationQuery } from '../../../../maintenance/requisition-teacher/slices/requisitionsApiSlice';
import { parseISO } from 'date-fns';
import { setCollaboratorData, setPersonData } from '../../slices/collaboratorsSlice';

interface PersonalDataForm {
  // Datos personales
  identificationType: Catalog;
  identification: string;
  firstName: string;
  secondName: string;
  lastName: string;
  secondLastName: string;
  gender: string;
  birthDate: Date;
  homeAddress: string;
  homePhone: string;
  cellPhone: string;
  personalEmail: string;
  nacionality: Catalog;
  city: Catalog;
  currentCity: Catalog;
  sector: Catalog;

  // TODO: random data
  isProvider: boolean;
}

const PersonaDataStep = () => {
  const dispatch = useAppDispatch();

  const { currentProfile } = useAppSelector((state) => state.access);

  const profileId = currentProfile!.profileId;

  // const [birthDate, setBirthDate] = useState<Date | null>(null);

  const { getFieldProps, setFieldValue, errors, touched, setTouched, values } =
    useFormikContext<PersonalDataForm>();

  const { data: identificationTypes = [], isLoading: isLoadingIT } = useGetCatalogByAcronymQuery({
    profileId,
    acronym: 'TI',
  });

  const { data: genders = [], isLoading: isLoadingG } = useGetCatalogByKeywordQuery({
    profileId,
    keyword: 'GE',
  });

  const { data: cities = [], isLoading: isLoadingCities } = useGetCatalogByAcronymQuery({
    profileId,
    acronym: 'C',
  });

  const { data: nacionalities = [], isLoading: isLoadingNacionalities } =
    useGetCatalogByAcronymQuery({
      profileId,
      acronym: 'NA',
    });

  const { data: sectores = [], isLoading: isLoadingSectores } = useGetCatalogByAcronymQuery({
    profileId,
    acronym: 'S',
  });

  const [getCollaborator, { data: collaboratorData }] = useLazyGetCollaboratorByCiQuery();

  const [getPersonTrigger, { data: personData, isLoading: isLoadingPersonData }] =
    useLazyGetPersonInformationQuery();

  useEffect(() => {
    setTouched({});
  }, [setTouched]);

  useEffect(() => {
    if (
      values.identificationType?.cat_keyword === 'cedula' &&
      values.identification.length === 10
    ) {
      //  getPersonTrigger({ profileId, id: values.id });

      getPersonTrigger({ profileId, id: values.identification });
      getCollaborator({ profileId, ci: values.identification });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.identificationType, values.identification]);

  useEffect(() => {
    if (!personData) {
      dispatch(setPersonData(null!));
      return;
    }

    if (personData && !values?.firstName) {
      setFieldValue('firstName', personData.pers_firstname);
      setFieldValue('secondName', personData.pers_secondname || '');
      setFieldValue('lastName', personData.pers_first_lastname);
      setFieldValue('secondLastName', personData.pers_second_lastname || '');
      setFieldValue('personalEmail', personData.email);
      setFieldValue('homePhone', personData.pers_phone_home);
      setFieldValue('cellPhone', personData.pers_cell);
      setFieldValue('homeAddress', personData.pers_direction);
      setFieldValue(
        'birthDate',
        personData.pers_date_birth ? parseISO(`${personData.pers_date_birth}`) : null
      );
      setFieldValue('nacionality', personData.nationality);
      setFieldValue('city', personData.city);
      setFieldValue('currentCity', personData.current_city);
      setFieldValue('sector', personData.sector);
      setFieldValue('gender', personData.pers_gender);
    }

    dispatch(setPersonData(personData));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personData]);

  useEffect(() => {
    if (collaboratorData) {
      dispatch(setCollaboratorData(collaboratorData));
    } else {
      dispatch(setCollaboratorData(null!));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collaboratorData]);

  console.log(errors, touched, values);

  return (
    <Grid container spacing={4} alignItems="flex-start">
      <Grid item xs={12} md={6} container spacing={2}>
        <Grid item xs={12}>
          <InputLabel>Tipo de Identificación</InputLabel>
          {isLoadingIT ? (
            <Skeleton variant="rectangular" sx={{ width: '100%', borderRadius: 3, height: 50 }} />
          ) : (
            <Autocomplete
              options={identificationTypes}
              loading={isLoadingIT}
              loadingText="Cargando tipos de indentificación..."
              noOptionsText="No hay opciones"
              getOptionLabel={(option) => option.cat_name}
              value={values.identificationType || null}
              onChange={(_, value) => {
                setFieldValue('identificationType', value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Seleccione un tipo de identificación"
                  error={Boolean(errors.identificationType && touched.identificationType)}
                />
              )}
              fullWidth
              clearIcon={null}
            />
          )}
          {errors.identificationType && touched.identificationType && (
            <FormHelperText error>{errors.identificationType}</FormHelperText>
          )}
        </Grid>
        <Grid item xs={12}>
          <InputLabel>Identificación</InputLabel>
          <TextField
            fullWidth
            placeholder="Ingrese su número de identificación"
            error={Boolean(errors.identification && touched.identification)}
            {...getFieldProps('identification')}
            InputProps={{
              endAdornment: (
                <>{isLoadingPersonData && <CircularProgress color="inherit" size={14} />}</>
              ),
            }}
          />
          {errors.identification && touched.identification && (
            <FormHelperText error>{errors.identification}</FormHelperText>
          )}
        </Grid>
        <Grid item xs={12} container spacing={2}>
          <Grid item xs={6}>
            <InputLabel>Primer nombre</InputLabel>
            <TextField
              fullWidth
              placeholder="Primer nombre"
              {...getFieldProps('firstName')}
              error={Boolean(errors.firstName && touched.firstName)}
            />
            {errors.firstName && touched.firstName && (
              <FormHelperText error>{errors.firstName}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={6}>
            <InputLabel>Segundo nombre</InputLabel>
            <TextField
              fullWidth
              placeholder="Segundo nombre"
              {...getFieldProps('secondName')}
              error={Boolean(errors.secondName && touched.secondName)}
            />
            {errors.secondName && touched.secondName && (
              <FormHelperText error>{errors.secondName}</FormHelperText>
            )}
          </Grid>
        </Grid>

        <Grid item xs={12} container spacing={2}>
          <Grid item xs={6}>
            <InputLabel>Apellido paterno</InputLabel>
            <TextField
              fullWidth
              placeholder="Apellido paterno"
              {...getFieldProps('lastName')}
              error={Boolean(errors.lastName && touched.lastName)}
            />
            {errors.lastName && touched.lastName && (
              <FormHelperText error>{errors.lastName}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={6}>
            <InputLabel>Apellido materno</InputLabel>
            <TextField
              fullWidth
              placeholder="Apellido materno"
              {...getFieldProps('secondLastName')}
              error={Boolean(errors.secondLastName && touched.secondLastName)}
            />
            {errors.secondLastName && touched.secondLastName && (
              <FormHelperText error>{errors.secondLastName}</FormHelperText>
            )}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <InputLabel>Email personal</InputLabel>
          <TextField
            fullWidth
            placeholder="Ej: alberto@gmail.com"
            {...getFieldProps('personalEmail')}
            error={Boolean(errors.personalEmail && touched.personalEmail)}
          />
          {errors.personalEmail && touched.personalEmail && (
            <FormHelperText error>{errors.personalEmail}</FormHelperText>
          )}
        </Grid>

        <Grid item xs={12}>
          <InputLabel>Teléfono convencional</InputLabel>
          <TextField
            fullWidth
            placeholder="Télefono convencional"
            {...getFieldProps('homePhone')}
            error={Boolean(errors.homePhone && touched.homePhone)}
            inputProps={{
              maxLength: 12,
            }}
          />
          {errors.homePhone && touched.homePhone && (
            <FormHelperText error>{errors.homePhone}</FormHelperText>
          )}
        </Grid>

        <Grid item xs={12}>
          <InputLabel>Celular</InputLabel>
          <TextField
            fullWidth
            placeholder="Celular"
            {...getFieldProps('cellPhone')}
            error={Boolean(errors.cellPhone && touched.cellPhone)}
            inputProps={{
              maxLength: 12,
            }}
          />
          {errors.cellPhone && touched.cellPhone && (
            <FormHelperText error>{errors.cellPhone}</FormHelperText>
          )}
        </Grid>

        <Grid item xs={12}>
          <InputLabel>Género</InputLabel>
          {isLoadingG && !genders ? (
            <Skeleton variant="rectangular" sx={{ width: '100%', borderRadius: 3, height: 50 }} />
          ) : (
            <Autocomplete
              options={genders}
              loading={isLoadingG}
              loadingText="Cargando géneros..."
              noOptionsText="No hay opciones"
              getOptionLabel={(option) => option.cat_name}
              value={
                genders.find(
                  (g) => g.cat_name === values.gender
                  /* ||(!isNaN(Number(values.gender)) && g.id === Number(values.gender)) */
                ) || null
              }
              onChange={(_, value) => {
                setFieldValue('gender', value?.cat_name || null);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Seleccione un género"
                  error={Boolean(errors.gender && touched.gender)}
                />
              )}
              fullWidth
              clearIcon={null}
            />
          )}
          {errors.gender && touched.gender && (
            <FormHelperText error>{errors.gender}</FormHelperText>
          )}
        </Grid>

        <Grid item xs={12}>
          <InputLabel>Fecha de nacimiento</InputLabel>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
            <DatePicker
              value={values.birthDate}
              onChange={(newValue) => {
                setFieldValue('birthDate', newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  helperText=""
                  placeholder="Fecha de nacimiento"
                  error={Boolean(errors.birthDate && touched.birthDate)}
                  fullWidth
                  inputProps={{
                    ...params.inputProps,
                    placeholder: 'd/m/a',
                  }}
                />
              )}
            />
          </LocalizationProvider>
          {errors.birthDate && touched.birthDate && (
            <FormHelperText error>{errors.birthDate}</FormHelperText>
          )}
        </Grid>
      </Grid>

      <Grid item xs={12} md={6} container spacing={2}>
        <Grid item xs={12}>
          <InputLabel>Nacionalidad</InputLabel>
          {isLoadingNacionalities ? (
            <Skeleton variant="rectangular" sx={{ width: '100%', borderRadius: 3, height: 50 }} />
          ) : (
            <Autocomplete
              options={nacionalities}
              loading={isLoadingNacionalities}
              loadingText="Cargando nacionalidades..."
              noOptionsText="No hay opciones"
              getOptionLabel={(option) => option.cat_name}
              value={values.nacionality || null}
              onChange={(_, value) => {
                setFieldValue('nacionality', value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(errors.nacionality && touched.nacionality)}
                  placeholder="Seleccionar una nacionalidad"
                />
              )}
              fullWidth
              clearIcon={null}
            />
          )}
          {errors.nacionality && touched.nacionality && (
            <FormHelperText error>{errors.nacionality}</FormHelperText>
          )}
        </Grid>

        <Grid item xs={12}>
          <InputLabel>Ciudad natal</InputLabel>
          {isLoadingCities ? (
            <Skeleton variant="rectangular" sx={{ width: '100%', borderRadius: 3, height: 50 }} />
          ) : (
            <Autocomplete
              options={cities}
              loading={isLoadingCities}
              loadingText="Cargando ciudades..."
              noOptionsText="No hay opciones"
              getOptionLabel={(option) => option.cat_name}
              value={values.city || null}
              onChange={(_, value) => {
                setFieldValue('city', value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(errors.city && touched.city)}
                  placeholder="Seleccionar una ciudad"
                />
              )}
              fullWidth
              clearIcon={null}
            />
          )}
          {errors.city && touched.city && <FormHelperText error>{errors.city}</FormHelperText>}
        </Grid>

        <Grid item xs={12}>
          <InputLabel>Ciudad de residencia</InputLabel>
          {isLoadingCities ? (
            <Skeleton variant="rectangular" sx={{ width: '100%', borderRadius: 3, height: 50 }} />
          ) : (
            <Autocomplete
              options={cities}
              loading={isLoadingCities}
              loadingText="Cargando ciudades..."
              getOptionLabel={(option) => option.cat_name}
              value={values.currentCity || null}
              onChange={(_, value) => {
                setFieldValue('currentCity', value?.cat_keyword);
                setFieldValue('currentCity', value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(errors.currentCity && touched.currentCity)}
                  placeholder="Seleccionar una ciudad"
                />
              )}
              fullWidth
              clearIcon={null}
            />
          )}
          {errors.currentCity && touched.currentCity && (
            <FormHelperText error>{errors.currentCity}</FormHelperText>
          )}
        </Grid>

        <Grid item xs={12}>
          <InputLabel>Dirección</InputLabel>
          <TextField
            fullWidth
            placeholder="Dirección domiciliaria"
            error={Boolean(errors.homeAddress && touched.homeAddress)}
            {...getFieldProps('homeAddress')}
          />

          {errors.homeAddress && touched.homeAddress && (
            <FormHelperText error>{errors.homeAddress}</FormHelperText>
          )}
        </Grid>

        <Grid item xs={12}>
          <InputLabel>Sector</InputLabel>
          {isLoadingSectores ? (
            <Skeleton variant="rectangular" sx={{ width: '100%', borderRadius: 3, height: 50 }} />
          ) : (
            <Autocomplete
              options={sectores}
              loading={isLoadingSectores}
              loadingText="Cargando sectores..."
              getOptionLabel={(option) => option.cat_name}
              value={values.sector || null}
              onChange={(_, value) => {
                setFieldValue('sector', value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(errors.sector && touched.sector)}
                  placeholder="Seleccione el sector al que pertenece su dirección domiciliaria."
                />
              )}
              fullWidth
              clearIcon={null}
            />
          )}
          {errors.sector && touched.sector && (
            <FormHelperText error>{errors.sector}</FormHelperText>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PersonaDataStep;
