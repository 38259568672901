export const permissionKey = 'economicGroup';

export interface SubPermissionKeys {
  read: string;
  create: string;
  edit: string;
  delete: string;
}

export const subPermissionKeys: SubPermissionKeys = {
  read: 'economic_group-listar-grupo-economico',
  create: 'economic_group-crear-grupo-economico',
  edit: 'economic_group-actualizar-grupo-economico',
  delete: 'economic_group-eliminar-grupo-economico',
};
