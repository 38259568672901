import DeleteAlert from '../../../shared/components/dialogs/DeleteAlert';
import { useTranslation } from 'react-i18next';
import { useDeleteBusRouteMutation } from '../slices/busRouteApiSlice';
// import { resetRoleIdToDelete, setDeleteModalOpen } from '../slices/rolesSlice';

interface Props {
  openModal: boolean;
  routeId: number;
  handleCloseModal: () => void;
}

const DeleteBusRouteDialog = ({ openModal, routeId, handleCloseModal }: Props) => {
  const { t } = useTranslation();
  const [deleteRoute, { isLoading, isSuccess, isError, error }] = useDeleteBusRouteMutation();

  const handleDelete = () => {
    deleteRoute({ routeId });
  };

  const handleClose = () => {
    handleCloseModal();
  };

  return (
    <DeleteAlert
      open={openModal}
      onClose={handleClose}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      handleDelete={handleDelete}
      deleteTitle={t('¿Estás seguro de eliminar la ruta?')}
      error={error as string}
    />
  );
};

export default DeleteBusRouteDialog;
