import { Button, Grid, Typography } from '@material-ui/core';
import { useFormik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../../../hooks/useRedux';
import InputLabel from '../../../../shared/components/forms/InputLabel';
import {
  useAssingRolesToStatusMutation,
  useGetRolesToAssingQuery,
} from '../../slices/requestsStatusApiSlice';
import RolesCheckGroup from './RolesCheckGroup';
import * as yup from 'yup';
import ErrorAlert from '../../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../../shared/components/alerts/SuccessAlert';

const rolesStatusSchema = yup.object().shape({
  roles: yup.array().min(1).required(),
});

const EditRolesStatusForm = () => {
  const { currentProfile } = useAppSelector((state) => state.access);
  const { id } = useParams<{ id: string }>();

  const { statusNameToAddRol } = useAppSelector((state) => state.requestsStatus);

  const { data: roles, isSuccess: isSuccessRoles } = useGetRolesToAssingQuery(
    {
      user_profile_id: currentProfile?.profileId || '',
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const [showAlerts, setShowAlerts] = useState(true);

  const [rolesId, setRolesId] = useState<number[]>([]);

  const [
    assingRoles,
    {
      isLoading: isLoadingAssign,
      isError: isErrorAssign,
      error: errorAssign,
      isSuccess: isSuccessAssign,
    },
  ] = useAssingRolesToStatusMutation();

  const formik = useFormik({
    initialValues: {
      roles: [],
    },
    validationSchema: rolesStatusSchema,
    onSubmit: (values) => {
      //alert(JSON.stringify(values))
      assingRoles({
        user_profile_id: currentProfile?.profileId || '',
        roles: values.roles,
        type_application_status_id: id,
      });
    },
  });

  const formikRef = useRef(formik).current;

  useEffect(() => {
    formikRef.setFieldValue('roles', rolesId);
  }, [rolesId, formikRef]);

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <Grid container direction="column" spacing={4}>
          <Grid item>
            {isErrorAssign && showAlerts && (
              <ErrorAlert
                message={errorAssign as string}
                handleDismiss={() => setShowAlerts(false)}
              />
            )}
            {isSuccessAssign && showAlerts && (
              <SuccessAlert
                message="Se asignaron los roles correctamente"
                handleDismiss={() => setShowAlerts(false)}
              />
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <InputLabel>Estado</InputLabel>
            <Typography variant="h3" color="Highlight">
              {statusNameToAddRol}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <InputLabel>Roles</InputLabel>
            {isSuccessRoles && roles && <RolesCheckGroup rolesTo={roles} setRoles={setRolesId} />}
            {formik.errors.roles && formik.touched.roles && (
              <Typography variant="subtitle2" color="red">
                Debe seleccionar al menos un rol
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <Button variant="contained" type="submit" disabled={isLoadingAssign}>
              Editar
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default EditRolesStatusForm;
