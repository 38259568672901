import { useEffect } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { useGetUsersQuery } from '../slices/userConfigurationsApiSlice';
import { IconSettings, IconLockOff } from '@tabler/icons';
// import EditButton from '../../../shared/components/tables/buttons/EditButton';
// import DeleteButton from '../../../shared/components/tables/buttons/DeleteButton';
// import AddButton from '../../../shared/components/tables/buttons/AddButton';

// import useGuard from '../hooks/useGuard';

import StatusChip from '../../../shared/components/badges/StatusChip';
import SearchPaginatedTable from '../../../shared/components/tables/SearchPaginatedTable';
import { IconButton, Tooltip } from '@material-ui/core';
import { setUsersList, updateUserListTable } from '../slices/userConfigurationsSlice';
import { useTableSearch } from '../../../../hooks/useTableSearch';
// import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useControlModalStates } from '../../../shared/hooks/useControlModalStates';
import ClearClientTokenDialog from './ClearClientTokenDialog';

const UsersTable = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { path } = useRouteMatch();

  const { dataId, handleOpenModalWith, handleCloseModal, open } = useControlModalStates();

  const { page, perPage, search, filterArrow } = useAppSelector(
    (state) => state.usersConfiguration.userListTable
  );
  const { setPage, setPerPage, setSearch, setFilterArrow } = useTableSearch({
    page,
    perPage,
    search,
    filterArrow,
    updateFunction: updateUserListTable,
  });

  const { isLoading, error, data, isFetching } = useGetUsersQuery({
    page,
    perPage,
    search,
    ...(filterArrow && { sort: filterArrow.name, type_sort: filterArrow.direction }),
  });

  //cargar en un estado la vista actual de la tabla
  useEffect(() => {
    if (data) {
      dispatch(setUsersList(data.data));
    }
  }, [data, dispatch]);

  // const { hasAccessUsers } = useGuard();

  // const handleDelete = async (periodId: number) => {
  //   // dispatch(openDeleteModalWith(periodId));
  // };

  const datafetch = data?.data!.map((dt, index) => ({
    id: index + (page - 1) * perPage + 1,
    // name:
    //   d.user.person.pers_firstname +
    //   ' ' +
    //   d.user.person.pers_secondname +
    //   ' ' +
    //   d.user.person.pers_first_lastname +
    //   ' ' +
    //   d.user.person.pers_second_lastname,
    // mail: d.coll_email,
    // gender: d.user.person.pers_gender,
    user: dt.person?.pers_full_name || t('configuration.users.no_data'),
    ci: dt.person?.pers_identification || t('configuration.users.no_data'),
    // birthday: d.user.person.pers_date_birth,
    statusId: dt.status_id,
    options: dt,
  }));

  // const goToAdd = () => {
  //   history.push(`${path}/agregar`);
  // };

  const handleOpenRemoveToken = (userId: number) => {
    handleOpenModalWith(userId);
  };

  return (
    <>
      <SearchPaginatedTable
        data={datafetch!}
        headers={{
          id: 'N°',
          // name: 'Nombre',
          // mail: 'Email',
          // gender: 'Género',
          user: t('configuration.users.user'),
          ci: t('configuration.users.dni'),
          statusId: t('configuration.users.status'),
          options: t('configuration.users.options'),
        }}
        // listFilterArrows={{
        //   user: 'person.pers_full_name',
        //   ci: 'person.pers_identification',
        // }}
        filterArrow={filterArrow}
        setFilterArrow={setFilterArrow}
        keyExtractor={(item) => item.options.id.toString()}
        searchPlacehoder={t('configuration.users.search')}
        isLoading={isLoading}
        isFetching={isFetching}
        error={error}
        perPage={perPage}
        setPerPage={setPerPage}
        total={data?.total || 0}
        page={page}
        setPage={setPage}
        numHeader={4}
        search={search}
        setSearch={setSearch}
        customRenderers={{
          // id: (row) => row.id.id,

          statusId: (row) => <StatusChip statusId={Number(row.statusId)} />,
          // name: (row) => (
          //   <>
          //     {row.name}
          //     <Typography variant="subtitle2" color="grey">
          //       {row.options.email}
          //     </Typography>
          //     <Typography variant="subtitle2" color="grey">
          //       {row.options.gender}
          //     </Typography>
          //   </>
          // ),

          options: (row) => (
            <>
              {/* {hasAccess('edit') && ( */}
              <Link to={`${path}/usuario/${row.options.id}/perfiles`}>
                <Tooltip title={t('configuration.users.view_profiles')}>
                  <IconButton aria-label="Editar" color="secondary">
                    <IconSettings size={20} />
                  </IconButton>
                </Tooltip>
                {/* <EditButton title="Editar información colaborador" /> */}
              </Link>

              {row.options.token_client && (
                <Tooltip title={t('configuration.users.clear_token_client')}>
                  <IconButton onClick={() => handleOpenRemoveToken(row.options.id)} color="default">
                    <IconLockOff size={20} />
                  </IconButton>
                </Tooltip>
              )}
              {/* )} */}
              {/* {!hasAccess('edit') && !hasAccess('delete') && 'Ninguna'} */}
            </>
          ),
        }}
        customDataCellsProperties={{
          id: { align: 'center' },
          options: {
            align: 'center',
          },
          ci: {
            align: 'center',
          },
          statusId: {
            align: 'center',
          },
        }}
        customHeadersCellsProperties={{
          id: { align: 'center' },
          options: {
            align: 'center',
          },
          user: {
            align: 'left',
          },
        }}
        // ActionButtons={<>{hasAccess('create') && <AddButton size="large" onClick={goToAdd} />}</>}
      />

      {open && (
        <ClearClientTokenDialog
          handleCloseModal={handleCloseModal}
          userId={dataId}
          openModal={open}
        />
      )}
    </>
  );
};

export default UsersTable;
