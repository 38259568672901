import * as Yup from 'yup';

export const AgreementInfoSchema = Yup.object().shape({
  name: Yup.string().required('El nombre es requerido.'),
  agr_num: Yup.number().moreThan(0, 'Debe ser mayor a 0.').typeError('Se esperaba un número.'),
  agr_start_date: Yup.date()
    .required('La fecha de creación del convenio es requerida.')
    .typeError('Seleccione una fecha.'),
  agr_end_date: Yup.date()
    .required('La fecha de fin del convenio es requerida.')
    .typeError('Seleccione una fecha.'),
  instituteId: Yup.number()
    .min(1, 'El instituto es requerido.')
    .required('El instituto es requerido.'),
  statusId: Yup.number().required('El estado del permiso es requerido.'),
});
