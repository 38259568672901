import { Button, CircularProgress, Grid, Typography } from '@material-ui/core';
import { useState } from 'react';
import { useAppSelector } from '../../../../hooks/useRedux';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import NoCollaboratorInfo from '../../messages/NoCollaboratorInfo';
import { useLazyGetMealTicketQuery } from '../../slices/rrhhApiSlice';
import imge from '../../../../assets/images_optimized/meal-ticket.png';
import noimge from '../../../../assets/images_optimized/no-meal.png';

const MealTicket = () => {
  const { userInfo } = useAppSelector((state) => state.auth);
  const { currentProfile } = useAppSelector((state) => state.access);
  const [showAlert, setShowAlert] = useState(false);

  const [meal, { data, isSuccess, isError, error, isLoading, isFetching, isUninitialized }] =
    useLazyGetMealTicketQuery();

  if (!userInfo.collaboratorInfo) {
    return <NoCollaboratorInfo />;
  }

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} textAlign="center">
        {isError && showAlert && (
          <img
            alt="img"
            src={noimge}
            style={{
              width: 400,
            }}
          />
        )}
      </Grid>
      <Grid item xs={12} textAlign="center">
        {isSuccess && (
          <>
            {data && data.flag && data.flag === 'success' && (
              <>
                <Typography variant="h2">Ticket Nº {data?.ticket || '--'} </Typography>
              </>
            )}
          </>
        )}
      </Grid>
      <Grid item xs={12} textAlign="center">
        <div style={{ minWidth: 500, margin: '0 auto' }}>
          {isSuccess && showAlert && (
            <SuccessAlert
              handleDismiss={() => {
                setShowAlert(false);
              }}
              message={
                data && data.flag && data.flag === 'success'
                  ? data?.msg || 'El ticket se generó correctamente'
                  : 'El ticket se generó correctamente'
              }
            />
          )}
          {isError && showAlert && (
            <ErrorAlert
              handleDismiss={() => {
                setShowAlert(false);
              }}
              message={String(error)}
            />
          )}
        </div>
      </Grid>
      <Grid item xs={12} textAlign="center">
        {isUninitialized && (
          <img
            alt="img"
            src={imge}
            style={{
              width: 320,
            }}
          />
        )}
      </Grid>
      <Grid item xs={12} textAlign="center">
        <Button
          size="large"
          variant="contained"
          onClick={async () => {
            await setShowAlert(false);
            await meal({
              apellidos: `${userInfo.lastname} ${userInfo.secondlastname}`,
              nombres: `${userInfo.name} ${userInfo.secondname}`,
              collaborator_id: userInfo.collaboratorInfo?.id!,
              user_profile_id: currentProfile?.profileId!,
            });
            await setShowAlert(true);
          }}
          disabled={isLoading || isFetching || isSuccess}
          endIcon={(isLoading || isFetching) && <CircularProgress size={20} />}
        >
          Generar Ticket
        </Button>
      </Grid>
    </Grid>
  );
};

export default MealTicket;
