import { format } from 'date-fns';
import ConfirmAlert from '../../../shared/components/dialogs/ConfirmAlert';
import { useReturnLendingMutation } from '../../lendings/slices/lendingApiSlice';

interface RefundDialogProps {
  lendings: any;
  lendingCode: string;
  handleClose: () => void;
}

const RefundDialog = ({ lendings, lendingCode, handleClose }: RefundDialogProps) => {
  const [
    returnLending,
    {
      isLoading: isLoadingReturn,
      isError: isErrorReturn,
      error: errorReturn,
      isSuccess: isSuccessReturn,
    },
  ] = useReturnLendingMutation();

  const handleReturn = () => {
    const id = lendings?.data.find((ld: any) => ld.borrow_code === lendingCode)?.id;

    try {
      if (id) {
        returnLending({
          borrows: [id],
          return_date: format(new Date(), 'yyy-MM-dd'),
        });
      }
    } catch (error) {}
  };
  return (
    <ConfirmAlert
      open
      error={errorReturn as string}
      isError={isErrorReturn}
      isLoading={isLoadingReturn}
      isSuccess={isSuccessReturn}
      handleDelete={handleReturn}
      title="¿Está seguro que desea devolver el préstamo?"
      subtitle="Se devolvera el préstamo seleccionado"
      confirmBtnMessage="Sí, devolver"
      customSuccessBtnText="Aceptar"
      successMessage="Se realizó la devolución correctamente"
      onClose={handleClose}
    />
  );
};

export default RefundDialog;
