import { useEffect, useState } from 'react';

import {
  Dialog,
  DialogProps,
  Grid,
  Button,
  Typography,
  useTheme,
  Autocomplete,
  TextField,
  FormHelperText,
  CircularProgress,
  DialogTitle,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { IconDownload, IconFileAlert, IconSearch } from '@tabler/icons';
import downloadjs from 'downloadjs';
import { useTranslation } from 'react-i18next';

import InputLabel from '../../../shared/components/forms/InputLabel';
import {
  useGetStudentRecordsByStudentQuery,
  useLazyGetStudentCertificateQuery,
} from '../slices/studentsApiSlice';
import TextFieldFetching from '../../../shared/components/TextFieldFetching';
import { StudentRecords } from '../interfaces/studentRecords.interface';
import CustomAsyncPaginatedSelect from '../../../shared/components/extended/Form/CustomAsyncPaginatedSelect';
import { useLazyGetPeriodsQuery } from '../../periods/slices/periodsApiSlice';
import {
  useGetCertificateTypesQuery,
  useGetPeriodByStudentRecordQuery,
} from '../../../students/certificates/slices/certificateApiSlice';
import { Certificate } from '../../../students/certificates/interfaces/certificate.interfaces';
import { useAppSelector } from '../../../../hooks/useRedux';
import { useFormik } from 'formik';
import { consulateName, degreeName } from '../../../shared/constants/certificateType';
import { certificateDownloadSchema } from '../constants/validationSchemas';
import { certificateLanguages } from '../constants/languages';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';

const useStyles = makeStyles((theme) => ({
  paper: { maxWidth: '600px', width: '90%', minHeight: '30%', padding: 0 },
  iframe: {
    display: 'flex',
    height: '100%',
    width: '100%',
    // maxWidth: '600px',
    // maxHeight: '600px',
  },
}));

interface Props extends DialogProps {
  onClose: () => void;
  title: string;

  userId: number | null;
  studentId: string | null;
}

const CertificateDialog = ({ onClose, title, userId, studentId, ...props }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();

  const { studentsList } = useAppSelector((state) => state.students);

  const [pdfState, setPdfState] = useState(false);

  const [downloadPdfError, setDownloadPdfError] = useState(null);

  const { currentProfile } = useAppSelector((state) => state.access);

  const [certificateType, setCertificateType] = useState<Certificate | null>(null);
  const [studentRecord, setStudentRecord] = useState<StudentRecords | null>(null);
  const [period, setPeriod] = useState<any | null>(null);

  const [showDownloadBtn, setShowDownloadBtn] = useState(false);

  const [getCertificate, { data: pdfReq, isLoading, isFetching, isSuccess, error }] =
    useLazyGetStudentCertificateQuery();

  const student = studentsList?.find((student) => student.id === Number(studentId));

  console.log(student);

  const { values, getFieldProps, setFieldValue, handleSubmit, errors, touched } = useFormik({
    initialValues: {
      certificateTypeAcronym: '',
      studentRecordId: 0,
      lang: 'es',
      periodId: 0,
      to: '',
      consul: '',
      practices: {
        isCERPPP: false,
        title: '',
        name: '',
        position: '',
        enterprise: '',
        city: '',
      },
    },
    validationSchema: certificateDownloadSchema,
    onSubmit: async (_values) => {
      await getCertificate({
        user_profile_id: currentProfile?.profileId || 0,
        user_id: userId || 0,
        lang: _values.lang,
        app_acronym: certificateType?.typ_app_acronym || '',
        student_record_id: studentRecord?.id || 0,
        ...((_values?.certificateTypeAcronym === 'CEREDU' ||
          _values?.certificateTypeAcronym === 'CERREN') && {
          period_id: period?.id || 0,
        }),
        ...(_values?.certificateTypeAcronym === 'CERPPP' && {
          practices: _values.practices,
        }),
        ...(values?.certificateTypeAcronym === 'CERCON' && {
          consul: _values.consul,
        }),
        ...(values?.certificateTypeAcronym === 'CEREDU' && {
          to: values.to,
        }),
      });

      setShowDownloadBtn(true);
    },
  });

  useEffect(() => {
    if (showDownloadBtn) {
      setShowDownloadBtn(false);
      setPdfState(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  useEffect(() => {
    setFieldValue('to', '');
    setFieldValue('periodId', 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.certificateTypeAcronym]);

  const { data: studentRecords, isLoading: isLoadingStudRecord } =
    useGetStudentRecordsByStudentQuery(
      {
        studentId: studentId || '0',
      },
      { skip: !studentId }
    );

  const { data: studentPeriods, isLoading: isLoadingSP } = useGetPeriodByStudentRecordQuery(
    {
      profileId: currentProfile?.profileId!,
      studentRecordId: values.studentRecordId || 0,
    },
    {
      skip: !values.studentRecordId || !currentProfile?.profileId,
    }
  );

  const [getPeriodsTrigger] = useLazyGetPeriodsQuery();

  const getPeriods = async (search: string, page: number, perPage: number) => {
    const response = await getPeriodsTrigger({
      page,
      perPage,
      search,
    }).unwrap();

    const hasMore = response.current_page < response.last_page;

    return {
      options: response.data.map((dt) => {
        return {
          value: dt.id,
          label: dt.per_name,
          ...dt,
        };
      }),
      hasMore,
    };
  };

  const { data: certificateTypes, isLoading: isLoadingC } = useGetCertificateTypesQuery(
    {
      profileId: currentProfile?.profileId!,
    },
    {
      skip: !currentProfile?.profileId,
    }
  );

  const printAsPDF = async () => {
    try {
      if (pdfReq) {
        setPdfState(true);

        const response = await fetch(pdfReq.pdf_url);

        if (!response.ok) {
          const error = await response.json();
          setPdfState(false);
          return setDownloadPdfError(error.detail);
        }

        const pdfData = await response.blob();

        const req = downloadjs(pdfData) as XMLHttpRequest;

        setDownloadPdfError(null);
        req.addEventListener('load', () => setPdfState(false));
      }
    } catch (error) {}

    setPdfState(false);
  };

  // const theme = useTheme();
  return (
    <Dialog
      {...props}
      classes={{ paper: classes.paper }}
      sx={{ backgroundColor: 'transparent' }}
      onClose={onClose}
    >
      <DialogTitle>
        <Typography
          variant="h2"
          textAlign="center"
          sx={{ color: theme.palette.primary.main, mb: 1 }}
        >
          {t('academic.students_list.student.certificate.title')}{' '}
        </Typography>
        {Boolean(student?.pers_full_name) && (
          <Typography
            variant="h3"
            textAlign="center"
            sx={{ color: theme.palette.primary.main, mb: 1 }}
          >
            {' '}
            {student?.pers_full_name}
          </Typography>
        )}
      </DialogTitle>
      <form onSubmit={handleSubmit}>
        <Grid container gap={4} p={3}>
          <Grid item container xs={12} spacing={2}>
            {downloadPdfError !== null && (
              <Grid item xs={12}>
                <ErrorAlert message={downloadPdfError as unknown as string} />
              </Grid>
            )}

            <Grid item xs={6}>
              <InputLabel>{t('academic.students_list.student.certificate.type')}</InputLabel>
              {isLoadingC ? (
                <TextFieldFetching />
              ) : (
                <Autocomplete
                  options={certificateTypes || []}
                  getOptionLabel={(option) => option.typ_app_name || ''}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(
                        errors.certificateTypeAcronym && touched.certificateTypeAcronym
                      )}
                      placeholder={t('academic.students_list.student.certificate.type.placeholder')}
                    />
                  )}
                  value={
                    certificateTypes?.find(
                      (ct) => ct.typ_app_acronym === values.certificateTypeAcronym
                    ) || null
                  }
                  onChange={(_, value) => {
                    setCertificateType(value || null);
                    setFieldValue('certificateTypeAcronym', value?.typ_app_acronym || null);
                    setFieldValue('practices.isCERPPP', value?.typ_app_acronym === 'CERPPP');
                  }}
                  fullWidth
                  clearIcon={null}
                />
              )}
              {errors.certificateTypeAcronym && touched.certificateTypeAcronym && (
                <FormHelperText error>{t(errors.certificateTypeAcronym as string)}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={6}>
              <InputLabel>{t('academic.students_list.student.certificate.language')}</InputLabel>
              <Autocomplete
                options={certificateLanguages}
                getOptionLabel={(option) => option.label || ''}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(errors.lang && touched.lang)}
                    placeholder={t(
                      'academic.students_list.student.certificate.language.placeholder'
                    )}
                  />
                )}
                value={certificateLanguages.find((cl) => cl.id === values.lang) || null}
                onChange={(_, value) => {
                  setFieldValue('lang', value?.id || null);
                }}
                fullWidth
                clearIcon={null}
              />
              {errors.lang && touched.lang && (
                <FormHelperText error>{t(errors.lang as string)}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={6}>
              <InputLabel>
                {t('academic.students_list.student.certificate.student_record')}
              </InputLabel>
              {isLoadingStudRecord ? (
                <TextFieldFetching />
              ) : (
                <Autocomplete
                  options={studentRecords || []}
                  getOptionLabel={(option) => option.education_level?.edu_name || ''}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(errors.studentRecordId && touched.studentRecordId)}
                      placeholder={t(
                        'academic.students_list.student.certificate.student_record_placeholder'
                      )}
                    />
                  )}
                  value={studentRecords?.find((sr) => sr.id === values.studentRecordId) || null}
                  onChange={(_, value) => {
                    setStudentRecord(value || null);
                    setFieldValue('studentRecordId', value?.id);
                  }}
                  fullWidth
                  clearIcon={null}
                />
              )}
              {errors.studentRecordId && touched.studentRecordId && (
                <FormHelperText error>{t(errors.studentRecordId as string)}</FormHelperText>
              )}
            </Grid>

            {certificateType?.typ_app_acronym === 'CERPPP' && (
              <>
                <Grid item xs={6}>
                  <InputLabel>
                    {t('academic.students_list.student.certificate.practices.name')}
                  </InputLabel>
                  <TextField
                    {...getFieldProps('practices.name')}
                    error={Boolean(errors.practices?.name && touched.practices?.name)}
                    placeholder={t(
                      'academic.students_list.student.certificate.practices.name.placeholder'
                    )}
                    fullWidth
                  />
                  {errors.practices?.name && touched.practices?.name && (
                    <FormHelperText error>{t(errors.practices?.name as string)}</FormHelperText>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <InputLabel>
                    {t('academic.students_list.student.certificate.practices.title')}
                  </InputLabel>
                  <Autocomplete
                    options={degreeName || []}
                    getOptionLabel={(option) => option.label || ''}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        {...getFieldProps('practices.title')}
                        error={Boolean(errors.practices?.title && touched.practices?.title)}
                        placeholder={t(
                          'academic.students_list.student.certificate.practices.title.placeholder'
                        )}
                        fullWidth
                      />
                    )}
                    value={degreeName?.find((dn) => dn.id === values.practices?.title) || null}
                    onChange={(_, value) => {
                      setFieldValue('practices.title', value?.id);
                    }}
                    fullWidth
                    clearIcon={null}
                  />
                  {errors.practices?.title && touched.practices?.title && (
                    <FormHelperText error>{t(errors.practices?.title as string)}</FormHelperText>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <InputLabel>
                    {t('academic.students_list.student.certificate.practices.position')}
                  </InputLabel>
                  <TextField
                    {...getFieldProps('practices.position')}
                    error={Boolean(errors.practices?.position && touched.practices?.position)}
                    placeholder={t(
                      'academic.students_list.student.certificate.practices.position.placeholder'
                    )}
                    fullWidth
                  />
                  {errors.practices?.position && touched.practices?.position && (
                    <FormHelperText error>{t(errors.practices?.position as string)}</FormHelperText>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <InputLabel>
                    {t('academic.students_list.student.certificate.practices.company')}
                  </InputLabel>
                  <TextField
                    {...getFieldProps('practices.enterprise')}
                    error={Boolean(errors.practices?.enterprise && touched.practices?.enterprise)}
                    placeholder={t(
                      'academic.students_list.student.certificate.practices.company.placeholder'
                    )}
                    fullWidth
                  />
                  {errors.practices?.enterprise && touched.practices?.enterprise && (
                    <FormHelperText error>
                      {t(errors.practices?.enterprise as string)}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <InputLabel>
                    {t('academic.students_list.student.certificate.practices.city')}
                  </InputLabel>
                  <TextField
                    {...getFieldProps('practices.city')}
                    error={Boolean(errors.practices?.city && touched.practices?.city)}
                    placeholder={t(
                      'academic.students_list.student.certificate.practices.city.placeholder'
                    )}
                    fullWidth
                  />
                  {errors.practices?.city && touched.practices?.city && (
                    <FormHelperText error>{t(errors.practices?.city as string)}</FormHelperText>
                  )}
                </Grid>
              </>
            )}

            {certificateType?.typ_app_acronym === 'CEREDU' && (
              <>
                <Grid item xs={6}>
                  <InputLabel>{t('academic.students_list.student.certificate.period')}</InputLabel>
                  {isLoadingSP ? (
                    <TextFieldFetching />
                  ) : (
                    <Autocomplete
                      options={studentPeriods || []}
                      getOptionLabel={(option) => option.per_name || ''}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={Boolean(errors.periodId && touched.periodId)}
                          placeholder={t(
                            'academic.students_list.student.certificate.period_placeholder'
                          )}
                        />
                      )}
                      value={period || null}
                      onChange={(_, value) => {
                        setPeriod(value || null);
                        setFieldValue('periodId', value?.id);
                      }}
                      fullWidth
                      clearIcon={null}
                    />
                  )}
                  {errors.periodId && touched.periodId && (
                    <FormHelperText error>{t(errors.periodId as string)}</FormHelperText>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <InputLabel>{t('academic.students_list.student.certificate.to')}</InputLabel>
                  <TextField
                    {...getFieldProps('to')}
                    error={Boolean(errors.to && touched.to)}
                    placeholder={t('academic.students_list.student.certificate.to.placeholder')}
                    fullWidth
                  />
                  {errors.to && touched.to && (
                    <FormHelperText error>{t(errors.to as string)}</FormHelperText>
                  )}
                </Grid>
              </>
            )}

            {certificateType?.typ_app_acronym === 'CERCON' && (
              <Grid item xs={6}>
                <InputLabel sx={{ mb: 2 }}>
                  {t('academic.students_list.student.certificate.to')}
                </InputLabel>
                <Autocomplete
                  options={consulateName}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(errors.consul && touched.consul)}
                      placeholder={t(
                        'academic.students_list.student.certificate.consul.placeholder'
                      )}
                    />
                  )}
                  onChange={(_, value) => {
                    setFieldValue('consul', value?.id || '');
                  }}
                  fullWidth
                  clearIcon={null}
                />
                {errors.consul && touched.consul && (
                  <FormHelperText error>{t(errors.consul)}</FormHelperText>
                )}
              </Grid>
            )}

            {certificateType?.typ_app_acronym === 'CERREN' && (
              <Grid item xs={6}>
                <InputLabel>{t('academic.students_list.student.certificate.period')}</InputLabel>
                {isLoadingStudRecord ? (
                  <TextFieldFetching />
                ) : (
                  <CustomAsyncPaginatedSelect
                    key={'PeriodsIdAsync'}
                    value={period}
                    placeholder={t('academic.students_list.student.certificate.period_placeholder')}
                    setValue={(_period) => {
                      setPeriod(_period);
                      setFieldValue('periodId', (_period as any)?.id);
                    }}
                    fetchFunc={getPeriods}
                    isError={Boolean(errors.periodId && touched.periodId)}
                  />
                )}
                {errors.periodId && touched.periodId && (
                  <FormHelperText error>{t(errors.periodId)}</FormHelperText>
                )}
              </Grid>
            )}
          </Grid>

          <Grid item container xs={12} alignItems="center" justifyContent="center">
            {!isLoading && error && (
              <>
                <Grid item container xs={12} alignItems="center" justifyContent="center">
                  <IconFileAlert
                    size={60}
                    color={theme.palette.error.main}
                    style={{
                      padding: 12,
                      backgroundColor: theme.palette.orange.light,
                      borderRadius: '50%',
                    }}
                  />
                </Grid>
                <Grid item xs={12} textAlign="center" mb={2}>
                  <Typography variant="body2">
                    {t('academic.students_list.student.certificate.pdf_error')}
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
          <Grid
            item
            container
            xs={12}
            alignItems="center"
            justifyContent="center"
            paddingBottom={2}
          >
            <Button
              type="submit"
              variant="contained"
              disabled={isLoading || isFetching}
              endIcon={isLoading ? <CircularProgress size={14} /> : <IconSearch />}
            >
              Buscar
            </Button>
            {!isLoading && isSuccess && showDownloadBtn && (
              <Button
                variant="contained"
                color="secondary"
                onClick={printAsPDF}
                endIcon={pdfState ? <CircularProgress size={14} /> : <IconDownload />}
                disabled={pdfState}
                sx={{ ml: 2 }}
              >
                Descargar
              </Button>
            )}

            {/* <Button
              variant="outlined"
              onClick={printAsPDF}
              disabled={!pdfReq || isLoading || isFetching || pdfState}
              size="large"
              endIcon={
                isLoading || isFetching || pdfState ? (
                  <CircularProgress size={20} />
                ) : (
                  <IconDownload />
                )
              }
            >
              {t('academic.students_list.student.certificate.pdf_btn')}
            </Button> */}
          </Grid>
        </Grid>
      </form>
    </Dialog>
  );
};

export default CertificateDialog;
