import React, { useState } from 'react';
import {
  Button,
  CardActions,
  Radio,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  setChooseAnswerEdit,
  setEvaluationQuestionCurrent,
  setQuestions,
  setChoose,
  updateQuestion,
  setQuestionAdded,
} from '../../../slices/evaluationSlice';
import { IconEditCircle, IconTrash } from '@tabler/icons';
import { useFormik } from 'formik';
import { useAppDispatch, useAppSelector } from '../../../../../../../hooks/useRedux';
import ErrorAlert from '../../../../../../shared/components/alerts/ErrorAlert';
import MainCard from '../../../../../../shared/components/cards/MainCard';
import { EvaluationQuestionSave, Options } from '../../../interfaces/evaluations.interfaces';
import { useTranslation } from 'react-i18next';

const EvaluationChooseAnswer = () => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  /**
   * Permite mantener el estado de selección del radio
   */
  const [radioSelected, setRadioSelected] = useState<number | null>(null);
  /**
   * Estado de puntaje cuando el puntaje seleccionado supera el total de evalaución.
   */
  const [pointInvalid, setPointInvalid] = useState<boolean>(false);
  /**
   * Estado de pregunta inválida.
   */
  const [questionInvalid, setQuestionInvalid] = useState<boolean>(false);

  const {
    evaluationQuestionCurrent: evaluation,
    typeQuestion,
    infoEvaluation,
    questions,
  } = useAppSelector((state) => state.evaluationTeacher.evaluation);
  /**
   * Actualizar estado de una evaluación.
   */
  const handleEditQuestion = async () => {
    const data: EvaluationQuestionSave = {
      id: values?.id!,
      point: values.point!,
      options: values?.options!,
      question: values?.question!,
      catalogId: values.catalogId,
      typeQuestion: typeQuestion,
    };

    await dispatch(setEvaluationQuestionCurrent(data));
    await dispatch(setChooseAnswerEdit(true));
  };
  /**
   * Suma de los puntos de la evalaución de todas las preguntas almacenadas.
   */
  const sumPointsEvaluation = (): number => {
    return questions.reduce((acc, value) => acc + value.point, 0);
  };

  const evaluationQuestionSave = async () => {
    const questionSaved = questions.find((e) => e.id === values.id);

    const data: EvaluationQuestionSave = {
      id: values?.id!,
      point: values.point!,
      options: values?.options!,
      question: values?.question!,
      catalogId: values?.catalogId!,
      typeQuestion: typeQuestion,
    };
    if (questionSaved) {
      dispatch(updateQuestion(data));
    } else {
      await dispatch(setQuestions(data));
    }
  };
  /**
   * Función que permite calcular si ha sido seleccionado un
   * puntaje de la pregunta.
   */
  const checkQuestionPointValid = (): boolean => {
    if (values?.point! === 0) {
      setQuestionInvalid(true);
      return false;
    }
    return true;
  };
  /**
   * Función que permite verificar si una pregunta contiene un respuesta
   * asignada. Solo si el tipo de pregunta es comentario no se verifica
   * si hay al menos un opción.
   */
  const checkQuestionSelected = (): boolean => {
    if (typeQuestion === 'comentary') return true;
    /**
     * Al menos una opción correcta.
     */
    const listSelected = [...values.options];
    const listFiltered = listSelected.filter((e) => e.correct === true);
    if (listFiltered.length === 0) {
      setQuestionInvalid(true);
      return false;
    }
    return true;
  };

  /**
   * Chequear todos lo puntos de la evaluación antes de guardar pregunta.
   * Si supera el total del máxmimo arroja puntaje inválido.
   */
  const checkAllSumPoints = (): boolean => {
    const totalPoint = sumPointsEvaluation() + values?.point!;
    if (totalPoint > infoEvaluation?.actScoreActivity!) {
      setPointInvalid(true);
      return false;
    }
    return true;
  };

  const handleSubmitForm = async () => {
    const questionAnswerSelected = checkQuestionSelected();
    if (!questionAnswerSelected) return false;

    const pointValid = checkQuestionPointValid();
    if (!pointValid) return false;

    const sumAllpointValid = checkAllSumPoints();
    if (!sumAllpointValid) return false;

    await evaluationQuestionSave();
    await dispatch(setChoose(false));
    await dispatch(setQuestionAdded(true));
    window.scrollTo(0, document.body.scrollHeight);
  };

  const { values, setFieldValue, handleSubmit, getFieldProps } = useFormik({
    initialValues: {
      id: evaluation?.id!,
      question: evaluation?.question!,
      options: evaluation?.options!,
      point: evaluation?.point,
      catalogId: evaluation?.catalogId!,
    },
    onSubmit: () => {
      handleSubmitForm();
    },
  });

  /**
   * Remover una pregunta mientras se edita.
   * Por defecto monta el componente de agregar preguntas y opciones.
   */
  const handleRemoveQuestion = async () => {
    await dispatch(setChoose(false));
  };
  /**
   * Seleccionar varias opciones en un checkbox.
   */
  const handleSelectedCheckbox = (index: number, checked: boolean) => {
    const list = [...values?.options!];
    list[index] = { ...list[index], correct: checked };
    setFieldValue('options', list);
  };
  /**
   * Seleccionar una sola opción.
   * Se actualiza el estado de todas las opciones a falso y luego
   * en verdadero la opción válida.
   */
  const handleSelectedRadio = (index: number) => {
    let list = [...values?.options!];
    const newList = list.map((e) => ({ ...e, correct: false }));
    newList[index] = { ...newList[index], correct: true };
    setFieldValue('options', newList);
    setRadioSelected(index);
  };

  const renderOptionsMultiple = ({ option, correct }: Options, index: number) => {
    return (
      <React.Fragment key={`${index}-${option}`}>
        <Grid item xs={6} sx={{ mt: 2 }}>
          <TextField
            variant="standard"
            style={{
              fontWeight: 'bold',
              color: 'darkblue',
            }}
            value={`${index + 1}.-  ${option}`}
            fullWidth
            disabled
            placeholder={`${index + 1}.-  ${option}`}
          />
        </Grid>
        <Grid item>
          <Checkbox
            defaultChecked={correct && correct}
            onChange={(e) => handleSelectedCheckbox(index, e?.target?.checked)}
          />
        </Grid>
      </React.Fragment>
    );
  };

  const renderOptionsSingle = ({ option }: Options, index: number) => {
    return (
      <React.Fragment key={`${index}-${option}`}>
        <Grid item xs={8} sx={{ mt: 2 }}>
          <TextField
            variant="standard"
            style={{
              fontWeight: 'bold',
            }}
            fullWidth
            disabled
            value={`${index + 1}.-  ${option}`}
            placeholder={`${index + 1}. ${option}`}
          />
        </Grid>
        <Grid item>
          <FormControlLabel
            label=""
            checked={index === radioSelected}
            onChange={(e) => handleSelectedRadio(index)}
            control={<Radio />}
          />
        </Grid>
      </React.Fragment>
    );
  };

  const ActionsButton = () => {
    return (
      <React.Fragment>
        <Grid item>
          <Button
            onClick={handleEditQuestion}
            style={{ textTransform: 'unset' }}
            variant="outlined"
            size="small"
            color="primary"
          >
            {t('teacherVirtualClassroom.tests.chooseAnswerEditQuestion')}
          </Button>
        </Grid>
        <Button style={{ textTransform: 'unset' }} variant="text" size="small" color="primary">
          {`${values?.point!} ${
            values?.point! > 1
              ? t('teacherVirtualClassroom.tests.chooseAnswerScore') + 's'
              : t('teacherVirtualClassroom.tests.chooseAnswerScore')
          }`}
        </Button>
        <Button>
          <IconTrash onClick={handleRemoveQuestion} style={{ cursor: 'pointer' }} />
        </Button>
        <Grid item>
          <Button
            onClick={() => handleSubmit()}
            type="button"
            startIcon={<IconEditCircle fontSize="0.875rem" />}
            style={{ textTransform: 'unset' }}
            variant="contained"
            size="small"
            color="primary"
          >
            {t('teacherVirtualClassroom.tests.chooseAnswerSaveQuestion')}
          </Button>
        </Grid>
      </React.Fragment>
    );
  };

  return (
    <Grid container spacing={4} alignItems="flex-start">
      <Grid item xs={12} md={10} lg={6} margin=" 0 auto" container spacing={1}>
        <Grid item xs={12}>
          <MainCard xs={12}>
            <Grid container spacing={4}>
              <Grid
                item
                xs={12}
                md={12}
                container
                spacing={1}
                justifyContent="space-between"
                alignItems="flex-end"
              >
                <Grid container sx={{ mb: 1 }} justifyContent="space-between">
                  {pointInvalid && (
                    <Grid item xs={12} sx={{ mb: 2 }}>
                      <ErrorAlert
                        message={t('teacherVirtualClassroom.tests.chooseAnswerPointInvalid')}
                        handleDismiss={() => setPointInvalid(false)}
                      />
                    </Grid>
                  )}

                  {questionInvalid && (
                    <Grid item xs={12} sx={{ mb: 2 }}>
                      <ErrorAlert
                        message={t('teacherVirtualClassroom.tests.chooseAnswerQuestionInvalid')}
                        handleDismiss={() => setQuestionInvalid(false)}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                container
                spacing={1}
                justifyContent="space-between"
                alignItems="flex-end"
              >
                <Grid container sx={{ mb: 1 }} justifyContent="space-between">
                  <Grid item xs={8}>
                    <Typography variant="h5">Question : {evaluation?.question!}</Typography>
                  </Grid>

                  <Grid item xs={2}>
                    <TextField
                      id="button-action-search"
                      fullWidth
                      label={t('teacherVirtualClassroom.tests.chooseAnswerScored')}
                      InputProps={{
                        inputProps: {
                          min: 0.5,
                          max: infoEvaluation?.actScoreActivity! - sumPointsEvaluation(),
                          step: 0.5,
                        },
                      }}
                      type="number"
                      {...getFieldProps('point')}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
                {typeQuestion === 'multiple' &&
                  evaluation?.options.map((e, index) => renderOptionsMultiple(e, index))}

                {typeQuestion === 'single' &&
                  evaluation?.options.map((e, index) => renderOptionsSingle(e, index))}
              </Grid>
            </Grid>
          </MainCard>
          <Divider />
          <CardActions sx={{ justifyContent: 'space-between' }}>
            <ActionsButton />
          </CardActions>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EvaluationChooseAnswer;
