import { mainApi } from '../../../api/mainApi';
import { middlewareApi } from '../../middleware/middlewareApi';
import { Permission } from '../interfaces/permission.interfaces';
import { AccessState } from '../../../types';

export const permissionsTags = middlewareApi.enhanceEndpoints({
  addTagTypes: ['PermissionsAccess'],
});

export const permissionsAccessApi = permissionsTags.injectEndpoints({
  //tagTypes: ['','',''],
  endpoints: (builder) => ({
    /**
     * @GET  permissions by module
     */
    getPermissionsAuth: builder.query<Permission[], { profileId?: number; keyword: string }>({
      queryFn: async ({ profileId, keyword }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = profileId || currentProfile?.profileId;

          const { data } = await mainApi.get<Permission[]>(`/modules/${keyword}/permissions`, {
            params: {
              user_profile_id,
            },
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),
  }),
});
export const { useGetPermissionsAuthQuery } = permissionsAccessApi;
