import { FC, useState, useEffect, useMemo } from 'react';

import {
  Button,
  Grid,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  useTheme,
  Box,
  Checkbox,
  Alert,
  Typography,
  Stack,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import { useTranslation } from 'react-i18next';

import InputLabel from '../../../../shared/components/forms/InputLabel';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import { renderErrorOrEmptyRow, renderSkeletonRows } from '../../../../shared/helpers/render';
import { PaymentsDebts } from '../../../../students/payments-history/interfaces/paymentsHistory.interface';
import { setSelectedDebts, setSelectedDebtsId } from '../../slices/debtsPaymentSlice';
// import Chip from '../../../../shared/components/extended/Chip';
import OpenCashPoint from './components/OpenCashPoint';
import { showWithDecimals } from '../../../../shared/helpers/index';
import SelectStudentRecord from './SelectStudentRecord';
import IsPositiveBalanceSwitch from './components/IsPositiveBalanceSwitch';

interface Props {
  handleOpenClientSideDialog: () => void;
  handleValidStep: (param: boolean) => void;
}

const SelectClient: FC<Props> = (props) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const defaultConfiguration = useAppSelector((state) => state.access.defaultConfiguration);

  const { handleOpenClientSideDialog, handleValidStep } = props;

  const {
    currentClient,
    debtsList,
    selectedDebtsId: selected,
    totalDebtAmount,
    isPositiveBalanceTransac,
  } = useAppSelector((state) => state.debtsPayment);

  const [outRangeState, setOutRangeState] = useState<number[]>();
  const [isOutRange, setIsOutRange] = useState<boolean>();

  //valid next step only if selected
  useEffect(() => {
    if (selected.length) handleValidStep(true);
    else handleValidStep(!isPositiveBalanceTransac ? false : true);
  }, [handleValidStep, isPositiveBalanceTransac, selected.length]);

  //select all checkbox
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelectedId = debtsList?.data!.map((n) => +n.fees_identifier) || [];
      dispatch(setSelectedDebtsId(newSelectedId));
      //   setSelected(newSelectedId);
      return;
    }

    dispatch(setSelectedDebtsId([]));
    // setSelected([]);
  };

  const handleClick = (
    // event: React.MouseEvent<HTMLTableHeaderCellElement, MouseEvent>,
    event: React.ChangeEvent<HTMLInputElement>,
    feeId: number
  ) => {
    const selectedIndex = selected.indexOf(feeId);
    let newSelected: number[] = [];

    let isUnSelected = false;

    const currentData = debtsList!.data?.find((el) => +el.fees_identifier === feeId);

    if (selectedIndex === -1) {
      const outRange = debtsList!.data!.filter(
        (dt) => Date.parse(dt.fees_expiration_date) < Date.parse(currentData?.fees_expiration_date!)
      );

      //comportamiento de bloqueo
      // hacer un filter de la data en base a los seleccionados y comparar el lenght
      if (outRange.length) {
        const dataSelected = outRange.filter((dt) =>
          selected.some((sel) => sel === +dt.fees_identifier)
        );

        if (dataSelected.length !== outRange.length) {
          setOutRangeState(
            outRange
              .filter((dt) =>
                debtsList!.data!.some((selDt) => selDt.fees_identifier === dt.fees_identifier)
              )
              .map((fil) => +fil.fees_identifier)
          );
          setIsOutRange(true);
          return;
        } else {
          setOutRangeState([]);
          setIsOutRange(false);
        }
      } else {
        setOutRangeState([]);
        setIsOutRange(false);
      }

      //comportamiento de autoselect
      outRange.forEach((element) => {
        const isStored = selected.some((dt) => dt === +element.fees_identifier);
        if (!isStored) newSelected = newSelected.concat(+element.fees_identifier);
      });

      newSelected = newSelected.concat(selected, feeId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
      isUnSelected = true;
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
      isUnSelected = true;
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
      isUnSelected = true;
    }

    //

    if (isUnSelected) {
      const stored = debtsList!.data!.filter((dt) =>
        selected.some((sel) => +dt.fees_identifier === sel && +dt.fees_identifier !== feeId)
      );
      stored.forEach((element) => {
        const isOutDate =
          Date.parse(element.fees_expiration_date) > Date.parse(currentData?.fees_expiration_date!);
        if (isOutDate) {
          setIsOutRange(true);
          const outRangeIndex = newSelected.indexOf(+element.fees_identifier);
          newSelected.splice(outRangeIndex, 1);
        } else {
          setIsOutRange(false);
        }
      });
    }

    dispatch(setSelectedDebtsId(newSelected));
  };

  //set complete data debts payment
  useEffect(() => {
    if (debtsList?.data?.length) {
      const filterDebts =
        debtsList?.data.filter((debt) =>
          // filtrar por los seleccionados y los que no estan en el array de selected
          selected.some((select) => +debt.fees_identifier === select)
        ) || [];

      dispatch(setSelectedDebts(filterDebts));
      //   if (filterDebts.length) dispatch(setDebtorId(Number(filterDebts[0].debtor_id)));
    }
  }, [debtsList?.data, dispatch, selected]);

  const renderRow = (debt: PaymentsDebts) => (
    <TableRow
      hover
      className="table-row"
      key={debt.fees_identifier.toString()}
      //   sx={{ cursor: 'pointer', backgroundColor: selected === debt.id ? '#eee' : '' }}
      onClick={() => {
        // setSelected(debt.id);
      }}
    >
      <TableCell>
        <Stack spacing={1}>
          <Grid item>
            <Typography>{debt.fees_name_identifier}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" fontSize="0.8rem">
              <b>{t('financial.debts_payment.wizard.select_client.table.period_school')}: </b>
              {debt.per_current_year && debt.per_due_year
                ? `${debt.per_current_year}-${debt.per_due_year}`
                : ''}
            </Typography>
          </Grid>
        </Stack>
      </TableCell>
      {/* <TableCell align="center">
        {debt.per_current_year && debt.per_due_year
          ? `${debt.per_current_year}-${debt.per_due_year}`
          : ''}
      </TableCell> */}
      <TableCell align="center">{debt.fees_start_date}</TableCell>
      <TableCell align="center">{debt.fees_expiration_date}</TableCell>
      {/* <TableCell>
        {debt.apply_early_payment === null ? (
          <Chip size="small" label="N/A" chipcolor="primary" />
        ) : debt.apply_early_payment ? (
          <Chip size="small" label="Si" chipcolor="success" />
        ) : (
          <Chip size="small" label="No" chipcolor="error" />
        )}
      </TableCell> */}
      {/* <TableCell>{debt.o_discount_percent_early_payment}</TableCell> */}
      <TableCell align="center">{debt.fees_net_amount}</TableCell>
      <TableCell align="center">
        {debt.apply_early_payment
          ? debt.discount_early_payment_is_percent
            ? debt.discount_value_early_payment //debt.discount_early_payment
            : debt.discount_early_payment //debt.discount_percent_early_payment
          : '0.00'}
      </TableCell>
      {/* <TableCell>{debt.fees_balance}</TableCell> */}
      <TableCell align="center">
        {defaultConfiguration?.currency_symbol}
        {debt.fees_payment}
      </TableCell>
      <TableCell align="center">
        {/* {debt.apply_early_payment ? debt.net_value_early_payment : debt.fees_balance} */}
        {defaultConfiguration?.currency_symbol}
        {debt.apply_early_payment ? debt.net_value_early_payment : debt.fees_balance}
      </TableCell>
      <TableCell align="center">
        <Checkbox
          //   disabled={page > 1}
          {...(outRangeState?.some((dt) => dt === +debt.fees_identifier) && {
            sx: { color: 'red' },
          })}
          checked={selected.some((r) => r === Number(debt.fees_identifier))}
          onChange={(e) => handleClick(e, Number(debt.fees_identifier))}
        />
      </TableCell>
      {/* <TableCell
      // width="30%"
      // align="center"
      // sx={{ borderLeft: 1, borderColor: 'grey.200', paddingX: '10px' }}
      ></TableCell> */}
    </TableRow>
  );

  const renderRows = () => {
    return <>{debtsList?.data?.map(renderRow)}</>;
  };

  // totals debList data momoized
  const totals = useMemo(() => {
    console.log('test re redenderq');
    let totalValue = 0,
      totalDiscount = 0,
      totalPayment = 0,
      totalToPay = 0;

    if (debtsList?.data?.length) {
      totalValue = debtsList?.data?.reduce((acc, debt) => {
        return acc + Number(debt.fees_net_amount);
      }, 0);
      totalDiscount = debtsList?.data?.reduce((acc, debt) => {
        return (
          acc +
          Number(
            debt.apply_early_payment
              ? debt.discount_early_payment_is_percent
                ? debt.discount_value_early_payment //debt.discount_early_payment
                : debt.discount_early_payment //debt.discount_percent_early_payment
              : '0.00'
          )
        );
      }, 0);
      totalPayment = debtsList?.data?.reduce((acc, debt) => {
        return acc + Number(debt.fees_payment);
      }, 0);

      totalToPay = debtsList?.data?.reduce((acc, debt) => {
        return (
          acc + Number(debt.apply_early_payment ? debt.net_value_early_payment : debt.fees_balance)
        );
      }, 0);
    }

    return { totalValue, totalDiscount, totalPayment, totalToPay };
  }, [debtsList?.data]);

  console.log(totals);

  return (
    <>
      <Grid container>
        <Grid item xs={12} mb={1}>
          <OpenCashPoint />
        </Grid>

        <Box
          sx={{ width: '100%' }}
          border={`1px solid ${theme.palette.primary.main}`}
          borderRadius={'12px'}
          p={2}
          mb={2}
        >
          <Grid item container xs={12} justifyContent={'flex-end'}>
            <Grid container alignItems={'center'} spacing={1} mb={1}>
              <Grid item xs={12} md={6}>
                {!!currentClient?.user.student?.student_records &&
                currentClient?.user.student?.student_records.length > 1 ? (
                  <SelectStudentRecord />
                ) : (
                  !!currentClient?.user.student?.student_records.length && (
                    <TextField
                      fullWidth
                      label={t('financial.debts_payment.wizard.select_client.program_select_label')}
                      disabled
                      variant="standard"
                      value={
                        currentClient?.user.student?.student_records[0].education_level.edu_name
                      }
                    />
                  )
                )}
              </Grid>

              <Grid item xs={12} md={6}>
                <Grid container justifyContent="flex-end" spacing={1}>
                  <Grid item>{currentClient && <IsPositiveBalanceSwitch />}</Grid>
                  <Grid item>
                    <Button
                      startIcon={<SearchIcon fontSize="small" />}
                      variant="outlined"
                      onClick={handleOpenClientSideDialog}
                    >
                      {t('financial.debts_payment.wizard.select_client.search_btn')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={1} justifyContent={'space-around'}>
            <Grid item>
              <InputLabel>
                {t('financial.debts_payment.wizard.select_client.code_label')}
              </InputLabel>
              <TextField
                value={currentClient?.user.student?.stud_code || ''}
                placeholder={t('financial.debts_payment.wizard.select_client.code_placeholder')}
                InputProps={{ readOnly: true }}
              />
            </Grid>

            <Grid item>
              <InputLabel>
                {t('financial.debts_payment.wizard.select_client.identification_label')}
              </InputLabel>
              <TextField
                value={currentClient?.pers_identification || ''}
                placeholder={t(
                  'financial.debts_payment.wizard.select_client.identification_placeholder'
                )}
                InputProps={{ readOnly: true }}
              />
            </Grid>

            <Grid item md={5}>
              <InputLabel>
                {t('financial.debts_payment.wizard.select_client.names_label')}
              </InputLabel>
              <TextField
                value={`${
                  currentClient?.pers_firstname ? currentClient?.pers_firstname + ' ' : ''
                }${currentClient?.pers_secondname ? currentClient?.pers_secondname + ' ' : ''}${
                  currentClient?.pers_first_lastname ? currentClient?.pers_first_lastname + ' ' : ''
                }${
                  currentClient?.pers_second_lastname
                    ? currentClient?.pers_second_lastname + ' '
                    : ''
                }`}
                fullWidth
                placeholder={t('financial.debts_payment.wizard.select_client.names_placeholder')}
                InputProps={{ readOnly: true }}
              />
            </Grid>
          </Grid>
        </Box>

        {isPositiveBalanceTransac ? (
          <Grid item xs={12} sx={{ p: 3 }}>
            <Grid container justifyContent="center">
              <Grid item xs={12} md={8}>
                <Alert severity="info" sx={{ p: 3 }}>
                  {t(
                    'financial.debts_payment.wizard.select_client.is_positive_balance_transaction_alert'
                  )}
                </Alert>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Grid container my={1}>
              <Grid item xs={12}>
                {isOutRange && (
                  <Alert severity="warning">
                    {t('financial.debts_payment.wizard.invalid_debt_selection_alert')}
                  </Alert>
                )}
              </Grid>
            </Grid>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow sx={{ backgroundColor: theme.palette.grey[200] }}>
                    <TableCell>
                      {t('financial.debts_payment.wizard.select_client.table.debt_description')}
                    </TableCell>
                    {/* <TableCell align="center">
                      {t('financial.debts_payment.wizard.select_client.table.period_school')}
                    </TableCell> */}
                    <TableCell align="center">
                      {t('financial.debts_payment.wizard.select_client.table.start_date_fee')}
                    </TableCell>
                    <TableCell align="center">
                      {t('financial.debts_payment.wizard.select_client.table.endline_fee')}
                    </TableCell>
                    {/* <TableCell>Desc. Prontopago</TableCell> */}
                    <TableCell align="center">
                      ({defaultConfiguration?.currency_symbol})
                      {t('financial.debts_payment.wizard.select_client.table.fee_value')}
                    </TableCell>
                    <TableCell align="center">
                      ({defaultConfiguration?.currency_symbol})
                      {t(
                        'financial.debts_payment.wizard.select_client.table.early_payment_discount'
                      )}
                    </TableCell>
                    {/* <TableCell>Valor de la deuda</TableCell> */}
                    <TableCell align="center">
                      ({defaultConfiguration?.currency_symbol})
                      {t('financial.debts_payment.wizard.select_client.table.paid_payment')}
                    </TableCell>
                    <TableCell align="center">
                      ({defaultConfiguration?.currency_symbol})
                      {t('financial.debts_payment.wizard.select_client.table.fee_balance')}
                    </TableCell>
                    <TableCell align="center">
                      <Checkbox
                        color="primary"
                        //   disabled={page > 1}
                        disabled={!debtsList?.data}
                        indeterminate={
                          selected.length > 0 && selected.length < (debtsList?.data?.length || 0)
                        }
                        checked={
                          (debtsList?.data?.length || 0) > 0 &&
                          selected.length === (debtsList?.data?.length || 0)
                        }
                        onChange={handleSelectAllClick}
                        inputProps={{
                          'aria-label': 'select all desserts',
                        }}
                      />
                    </TableCell>
                    {/* <TableCell>Identificación</TableCell>
                  <TableCell>Nombre</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {debtsList?.isFetching ? renderSkeletonRows(5, 8) : renderRows()}
                  {!debtsList?.isFetching && !!debtsList?.data?.length && (
                    <>
                      <TableRow>
                        <TableCell colSpan={3} align="right">
                          {/* <b>
                            {t(
                              'financial.debts_payment.wizard.select_client.table.total_selected_label'
                            )}
                          </b> */}
                        </TableCell>
                        <TableCell align="center">
                          <b>
                            {defaultConfiguration?.currency_symbol}
                            {showWithDecimals(totals.totalValue)}
                          </b>
                        </TableCell>
                        <TableCell align="center">
                          <b>
                            {defaultConfiguration?.currency_symbol}
                            {showWithDecimals(totals.totalDiscount)}
                          </b>
                        </TableCell>
                        <TableCell align="center">
                          <b>
                            {defaultConfiguration?.currency_symbol}
                            {showWithDecimals(totals.totalPayment)}
                          </b>
                        </TableCell>
                        <TableCell align="center">
                          <b>
                            {defaultConfiguration?.currency_symbol}
                            {showWithDecimals(totals.totalToPay)}
                          </b>
                        </TableCell>
                        <TableCell>&nbsp;</TableCell>
                      </TableRow>

                      <TableRow
                        sx={{
                          backgroundColor: theme.palette.primary.main,
                        }}
                      >
                        <TableCell colSpan={7} align="right">
                          <Typography color={theme.palette.common.white || 'white'}>
                            <b>
                              {t(
                                'financial.debts_payment.wizard.select_client.table.total_selected_label'
                              )}
                            </b>
                          </Typography>
                        </TableCell>

                        <TableCell align="center">
                          <Typography color={theme.palette.common.white || 'white'}>
                            <b>
                              {defaultConfiguration?.currency_symbol}
                              {showWithDecimals(totalDebtAmount)}
                            </b>
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </>
                  )}

                  {debtsList?.error && renderErrorOrEmptyRow(5, debtsList?.error as string)}
                  {(debtsList?.data?.length === 0 || !debtsList?.data) &&
                    !debtsList?.isFetching &&
                    renderErrorOrEmptyRow(8)}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default SelectClient;
