import { Switch } from 'react-router-dom';
import GuardRoute from '../../../../router/guards/GuardRoute';
import DashboardScreen from '../screens/DashboardScreen';
const AcademicRequestsRouter = () => {
  return (
    <Switch>
      {/* Soliciudes academicas */}
      <GuardRoute exact path="/inicio-estudiante" component={DashboardScreen} />
    </Switch>
  );
};

export default AcademicRequestsRouter;
