import { mainApi } from '../../../../api/mainApi';
import { AccessState } from '../../../../types';
import { middlewareApi } from '../../../middleware/middlewareApi';
import { CustomChilds } from '../../../shared/interfaces/material-ui.interfaces';
import { Permission, PermissionResponse } from '../interfaces/permissions.interfaces';
import { Module } from '../interfaces/permissions1.interfaces';
import { setPermissionList } from './permissionsSlice';

export const permissionsTags = middlewareApi.enhanceEndpoints({
  addTagTypes: ['Roles', 'RolesAvailables', 'RolesTree', 'Role', 'PermissionModules'],
});

// Define a service using a base URL and expected endpoints
export const permissionsApi = permissionsTags.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * @GET all permissions
     */
    getPermissions: builder.query<
      PermissionResponse,
      { profileId: number; page: number; perPage: number; search: string }
    >({
      queryFn: async ({ profileId, page, search, perPage }, { dispatch }) => {
        try {
          const { data } = await mainApi.get<PermissionResponse>('/permissions', {
            params: {
              user_profile_id: profileId,
              page,
              size: perPage,
              type_sort: 'desc',
              search,
            },
          });

          dispatch(setPermissionList(data.data));

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'Permissions', id } as const)),
              { type: 'Permissions', id: 'LIST' },
            ]
          : [{ type: 'Permissions', id: 'LIST' }],
    }),

    /**
     * @GET all permissions Avalibles
     */
    getAvailablesPermissions: builder.query<Permission[], { profileId: Number }>({
      queryFn: async ({ profileId }) => {
        try {
          const { data } = await mainApi.get<Permission[]>('/permissions', {
            params: {
              user_profile_id: profileId,
              data: 'all',
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: [{ type: 'PermissionsAvailables', id: 'COMBO_PERMISSION_LIST' }],
    }),

    /**
     * @GET all grouped permissions Avalibles
     */
    getAvailablesGroupedPermissions: builder.query<Permission[], { profileId: Number }>({
      queryFn: async ({ profileId }) => {
        try {
          const { data } = await mainApi.get<Permission[]>('/permissions-grouped-translate', {
            params: {
              user_profile_id: profileId,
              data: 'all',
            },
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: [{ type: 'PermissionsAvailables', id: 'COMBO_PERMISSION_LIST' }],
    }),

    /**
     * @GET permission by id
     */
    getPermission: builder.query<Permission, { profileId: number; permissionId: number }>({
      queryFn: async ({ profileId, permissionId }, { dispatch }) => {
        try {
          const { data } = await mainApi.get<Permission>('/permissions', {
            params: {
              user_profile_id: profileId,
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result, error, { profileId }) => [{ type: 'Permissions', profileId }],
    }),

    /**
     * @POST create permission
     */
    addPermission: builder.mutation<
      Permission,
      { profileId: number; permissionPayload: Partial<Permission> }
    >({
      queryFn: async ({ profileId, permissionPayload }) => {
        try {
          const { data } = await mainApi.post<Permission>('/permissions', {
            user_profile_id: profileId,
            ...permissionPayload,
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'Permissions', id: 'LIST' }],
    }),

    /**
     * @PUT update permission
     */
    updatePermission: builder.mutation<
      Permission,
      { profileId: number; permissionId: number; permissionPayload: Partial<Permission> }
    >({
      queryFn: async ({ profileId, permissionId, permissionPayload }) => {
        try {
          const { data } = await mainApi.put<Permission>(`/permissions/${permissionId}`, {
            user_profile_id: profileId,
            ...permissionPayload,
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'Permissions', id: 'LIST' }],
    }),

    /**
     * @DELETE remove permission
     */
    deletePermission: builder.mutation<Permission, { profileId: number; permissionId: number }>({
      queryFn: async ({ profileId, permissionId }) => {
        try {
          const { data } = await mainApi.delete(`permissions/${permissionId}`, {
            data: {
              user_profile_id: profileId,
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'Permissions', id: 'LIST' }],
    }),

    getPermissionModules: builder.query<Module[], { profileId: number; permissionId: number }>({
      queryFn: async ({ profileId, permissionId }) => {
        try {
          const { data } = await mainApi.get<Module[]>(`/permissions/${permissionId}/modules`, {
            params: {
              user_profile_id: profileId,
              data: 'all',
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result, error, { permissionId }) => [
        { type: 'PermissionModules', id: permissionId },
      ],
      // providesTags: (result) =>
      //   result
      //     ? [
      //         ...result.data.map(({ id }) => ({ type: 'Permissions', id } as const)),
      //         { type: 'Permissions', id: 'LIST' },
      //       ]
      //     : [{ type: 'Permissions', id: 'LIST' }],
    }),

    addPermissionAdditionalModule: builder.mutation<
      Permission,
      { profileId: number; permissionId: number; moduleId: number }
    >({
      queryFn: async ({ profileId, permissionId, moduleId }) => {
        try {
          const { data } = await mainApi.post<Permission>(
            `/permissions/${permissionId}/additional/modules/${moduleId}`,
            {
              user_profile_id: profileId,
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: (_, __, { permissionId }) => [
        { type: 'PermissionModules', id: permissionId },
      ],
    }),

    getModules: builder.query<
      any[],
      {
        profileId: number;
        aditional?: boolean;
      }
    >({
      queryFn: async ({ profileId, aditional }) => {
        try {
          const { data } = await mainApi.get<any[]>('/system/modules', {
            params: {
              data: 'all',
              user_profile_id: profileId,
              ...(aditional && { aditional: 'yes' }),
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    getModuleByKeyword: builder.query<
      CustomChilds<any, { id: number }>,
      {
        profileId?: number;
        moduleKeyword: string;
      }
    >({
      queryFn: async ({ profileId, moduleKeyword }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = profileId || currentProfile?.profileId;

          const { data } = await mainApi.get<CustomChilds<any, { id: number }>>(
            `/system/modules/${moduleKeyword}`,
            {
              params: {
                user_profile_id,
              },
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    //
  }),
});

export const {
  useGetPermissionsQuery,
  useAddPermissionMutation,
  useDeletePermissionMutation,
  useGetPermissionQuery,
  useUpdatePermissionMutation,
  useGetAvailablesPermissionsQuery,
  useGetAvailablesGroupedPermissionsQuery,
  useGetPermissionModulesQuery,
  useAddPermissionAdditionalModuleMutation,
  useGetModulesQuery,
  useGetModuleByKeywordQuery,
  useLazyGetModuleByKeywordQuery,
} = permissionsApi;
