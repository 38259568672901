import { useState, useEffect } from 'react';

import {
  Grid,
  IconButton,
  Tooltip,
  TextField,
  Autocomplete,
  Button,
  Typography,
} from '@material-ui/core';
import { IconEye } from '@tabler/icons';
import { useRouteMatch } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { DateRange } from '@material-ui/lab/DateRangePicker';

import { useGetGeneralApplicationsQuery } from '../slices/GeneralApplicationsApiSlice';
import SearchPaginatedTable from '../../../shared/components/tables/SearchPaginatedTable';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import Chip from '../../../shared/components/extended/Chip';
import { DownloadPdf } from '../../homologations/components/ExternalHomologationsTable';
import { appTypes } from '../constants/mockOptions';
import { useTableSearch } from '../../../../hooks/useTableSearch';
import {
  setAppStateIdSelected,
  setAppTypeIdSelected,
  setDateRangeSelected,
  setEducationLevelIdSelected,
  setGeneralApplicationList,
  updateGeneralApplicationTable,
} from '../slices/GeneralApplicationsSlice';
import { useGetEducationLevelsParentsAndChildrenQuery } from '../../education-levels/slices/educationLevelsApiSlice';
import { useGetApplicationStatusByAcronymQuery } from '../../requests-status/slices/requestsStatusApiSlice';
import TextFieldFetching from '../../../shared/components/TextFieldFetching';
import LocalizedDateRangePicker from '../../../shared/components/controlled/LocalizedDateRangePicker';
import useSharedGuard from '../../../../hooks/useSharedGuard';
import { subPermissionKeys } from '../constants/permissions';

const GeneralApplicationsTable = function () {
  const dispatch = useAppDispatch();

  const { path } = useRouteMatch();

  const { currentProfile } = useAppSelector((state) => state.access);
  const { userInfo } = useAppSelector((state) => state.auth);

  const { educationLevelIdSelected, dateRangeSelected, appTypeIdSelected, appStateIdSelected } =
    useAppSelector((state) => state.applications);

  const { page, perPage, search, filterArrow } = useAppSelector(
    (state) => state.applications.generalApplicationTable
  );
  const { setPage, setPerPage, setSearch, resetTable, setFilterArrow } = useTableSearch({
    page,
    perPage,
    search,
    filterArrow,
    updateFunction: updateGeneralApplicationTable,
  });

  // const filterByEducationLevel = Boolean(Number(currentRol?.showFilteredRequests));
  const educationLevelCollaborator = userInfo?.collaboratorInfo?.education_level_principal_id;

  const {
    data: educationLevels,
    isLoading: isLoadingELA,
    isFetching: isFetchingELA,
    error: errorELA,
  } = useGetEducationLevelsParentsAndChildrenQuery({ filter: true });

  const [filter, setFilter] = useState<boolean>(true);

  const {
    data: appStatuses,
    isLoading: isLoadingAppStatuses,
    isFetching: isFetchingStatuses,
  } = useGetApplicationStatusByAcronymQuery(
    {
      user_profile_id: currentProfile?.profileId!,
      acronym: appTypeIdSelected || '',
    },
    {
      skip: !appTypeIdSelected || !currentProfile?.profileId,
    }
  );

  const { hasAccess } = useSharedGuard(subPermissionKeys);
  const { data, isLoading, isFetching, error, refetch } = useGetGeneralApplicationsQuery(
    {
      profileId: currentProfile?.profileId!,
      page,
      perPage,
      search,
      ...(filter && appTypeIdSelected && { acronym: appTypeIdSelected }),
      ...(dateRangeSelected[0] && { startDate: dateRangeSelected[0] }),
      ...(dateRangeSelected[1] && { endDate: dateRangeSelected[1] }),
      ...(filter && appStateIdSelected && { status: appStateIdSelected }),
      ...(filter && appStateIdSelected && { fa: appStateIdSelected }),
      ...(filter && educationLevelIdSelected && { facultyId: educationLevelIdSelected }),
      ...(filterArrow && { sort: filterArrow.name, type_sort: filterArrow.direction }),
      ...(educationLevelCollaborator && { facultyId: Number(educationLevelCollaborator) }),
    }
    // { refetchOnMountOrArgChange: 120 }
  );

  useEffect(() => {
    // filterByEducationLevel &&
    if (educationLevelCollaborator) {
      dispatch(
        setEducationLevelIdSelected(
          educationLevels?.find((el) => el.id === Number(educationLevelCollaborator))?.id || null
        )
      );
    }
  }, [dispatch, educationLevelCollaborator, educationLevels]);

  useEffect(() => {
    if (data) dispatch(setGeneralApplicationList(data.data));
  }, [data, dispatch]);

  const datafetch = data?.data!.map((value, index) => ({
    id: index + ((page - 1) * perPage + 1),
    appCode: value.app_code,
    studCode: value?.stud_code || '',
    student: { name: value.user, id: value.pers_identification },
    // persIdentification: value?.pers_identification || '',
    typeApplication: value.type_application,
    // detail: {
    //   per_max_matter_enrollment: value?.per_max_matter_enrollment,
    //   per_min_matter_enrollment: value?.per_min_matter_enrollment,
    //   per_num_fees: value?.per_num_fees,
    //   per_pay_enrollment: value?.per_pay_enrollment,
    // },
    startDate: value.app_register_date,
    status_id: value.current_status,
    options: value,
  }));

  // const handleDelete = function (periodId: number) {
  //   dispatch(openDeleteModalWith(periodId));
  // };

  //filtros
  const handleFilter = () => {
    if (filter) refetch();
    else setFilter(true);
  };
  const handleClearFilter = () => {
    dispatch(setDateRangeSelected([new Date(), new Date()]));
    dispatch(setAppTypeIdSelected(null));
    dispatch(setAppStateIdSelected(null));
    if (!educationLevelCollaborator) {
      dispatch(setEducationLevelIdSelected(null));
    }
    setFilter(false);
  };
  const onChangeDates = (newDatesValues: DateRange<Date>) => {
    dispatch(setDateRangeSelected(newDatesValues));

    resetTable();
  };

  // const goToAdd = () => {
  //   history.push('/administrar-periodos/agregar');
  // };

  return (
    <SearchPaginatedTable
      actBtnFullWith
      data={datafetch!}
      headers={{
        id: 'N°',
        appCode: 'Cód. Sol.',
        studCode: 'Cód. Est.',
        student: 'Estudiante',
        // persIdentification: 'Identificación',
        typeApplication: 'Tipo',
        // detail: 'Detalle',
        startDate: 'Fecha de Registro',
        status_id: 'Estado',
        options: 'Opciones',
      }}
      listFilterArrows={{
        student: 'p.pers_full_name',
        startDate: 'app_register_date',
        // status_id: 'current_status',
      }}
      filterArrow={filterArrow}
      setFilterArrow={setFilterArrow}
      keyExtractor={(item) => item.options.app_code + item.options.id.toString()}
      searchPlacehoder="Buscar estudiante"
      isLoading={isLoading}
      isFetching={isFetching}
      error={error}
      perPage={perPage}
      setPerPage={setPerPage}
      total={data?.total || 0}
      page={page}
      setPage={setPage}
      numHeader={9}
      search={search}
      setSearch={setSearch}
      customRenderers={{
        student: ({ student }) => (
          <Grid>
            <Typography variant="h5" component="p">
              {student.name}
            </Typography>
            <Typography variant="body2" component="p">
              {student.id}
            </Typography>
          </Grid>
        ),
        status_id: (row) => (
          <Chip
            label={row.options.current_status}
            size="small"
            chipcolor={
              row.options.current_status === 'Enviado'
                ? 'info'
                : row.options.current_status === 'Rechazado'
                ? 'error'
                : 'success'
            }
          />
        ),

        options: (row) => (
          <>
            {hasAccess('show') && (
              <Link to={`${path}/${row.options.id}/revision`}>
                <Tooltip title="Revisar solicitud">
                  <IconButton aria-label="Revisar solicitud" color="default">
                    <IconEye size={20} />
                  </IconButton>
                </Tooltip>
              </Link>
            )}
            {hasAccess('generatePDF') &&
              row.options.app_code.split('-')[0] === 'HOMEXT' &&
              row.options.matters && (
                <DownloadPdf appId={row.options.id} profile={currentProfile?.profileId!} />
              )}
            {/* {hasAccess('edit') && (
                  <Link to={`${path}/${row.options.id}/editar`}>
                    <EditButton />
                  </Link>
                )}
                {hasAccess('delete') && (
                  <DeleteButton handleDelete={() => handleDelete(row.options.id)} />
                )} */}
            {!hasAccess('read') && 'Ninguna'}
          </>
        ),
      }}
      customHeadersCellsProperties={{
        appCode: {
          align: 'center',
        },
        studCode: {
          align: 'center',
        },
        // persIdentification: {
        //   align: 'center',
        // },
        typeApplication: {
          align: 'center',
        },
        student: {
          align: 'center',
        },
        // detail: 'Detalle',
        startDate: {
          align: 'center',
        },
        status_id: {
          align: 'center',
        },
        options: {
          align: 'center',
        },
      }}
      customDataCellsProperties={{
        id: { align: 'center' },
        appCode: {
          align: 'center',
        },
        typeApplication: {
          align: 'center',
        },
        // student: {
        //   align: 'center',
        // },
        // detail: 'Detalle',
        startDate: {
          align: 'center',
        },
        status_id: {
          align: 'center',
        },
        options: {
          align: 'center',
        },
      }}
      ActionButtons={
        <>
          <Grid container spacing={1} justifyContent="flex-end">
            {/* filtro facultades */}

            {hasAccess('getEduLvls') && (
              <Grid item xs={12} sm={6} md={3}>
                {isFetchingELA || isLoadingELA ? (
                  <TextFieldFetching />
                ) : (
                  <Autocomplete
                    options={educationLevels || []}
                    loading={isFetchingELA || isLoadingELA}
                    onError={() => errorELA}
                    loadingText="Cargando unidades académicas..."
                    getOptionLabel={(option) => option.edu_name}
                    renderInput={(params) => <TextField {...params} label="Unidades académicas" />}
                    // disabled={true}
                    disabled={Boolean(educationLevelCollaborator)}
                    onChange={(_, value) => {
                      dispatch(setEducationLevelIdSelected(value?.id || null));
                    }}
                    value={
                      educationLevels?.find((el) => el.id === Number(educationLevelIdSelected)) ||
                      null
                    }
                    fullWidth
                  />
                )}
              </Grid>
            )}

            <Grid item xs={12} md={4}>
              <LocalizedDateRangePicker
                value={dateRangeSelected}
                onChange={onChangeDates}
                startText="Fecha Inicio"
                endText="Fecha Fin"
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Autocomplete
                options={appTypes}
                loadingText="Cargando solicitudes..."
                getOptionLabel={(option) => option.value}
                renderInput={(params) => <TextField {...params} label="Tipo de solicitud" />}
                value={appTypes.find((dt) => dt.acro === appTypeIdSelected) || null}
                onChange={(_, value) => {
                  dispatch(setAppTypeIdSelected(value?.acro || null));
                  dispatch(setAppStateIdSelected(null));
                  resetTable();
                }}
                fullWidth
              />
            </Grid>

            {hasAccess('getAllStatusRoles') && (
              <Grid item xs={12} sm={6} md={2}>
                {isLoadingAppStatuses || isFetchingStatuses ? (
                  <TextFieldFetching />
                ) : (
                  <Autocomplete
                    options={appStatuses || []}
                    loadingText="Cargando estados..."
                    loading={isLoadingAppStatuses || isFetchingStatuses}
                    getOptionLabel={(option) => option.status_name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={appTypeIdSelected ? 'Estado' : 'Seleccione un tipo de solicitud'}
                        // placeholder={
                        //   appType ? 'Seleccione un estado' : 'Seleccione un tipo de solicitud'
                        // }
                      />
                    )}
                    onChange={(_, value) => {
                      dispatch(setAppStateIdSelected(value?.status_name || null));
                      resetTable();
                    }}
                    value={appStatuses?.find((dt) => dt.status_name === appStateIdSelected) || null}
                    disabled={isLoadingAppStatuses || isFetchingStatuses || !appTypeIdSelected}
                    fullWidth
                  />
                )}
              </Grid>
            )}

            <Grid container justifyContent="flex-end" spacing={1} mt={1}>
              <Grid item xs={'auto'}>
                <Button variant={'outlined'} onClick={handleClearFilter}>
                  Limpiar
                </Button>
              </Grid>
              <Grid item xs={'auto'}>
                <Button variant={'outlined'} onClick={handleFilter}>
                  Buscar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </>
      }
    />
  );
};

export default GeneralApplicationsTable;
