import { Reason } from '../interfaces/payload.interface';

export const reasons = [
  {
    id: 1,
    label: 'Anulación Total' as Reason,
  },
  {
    id: 2,
    label: 'Anulación Parcial' as Reason,
  },
];

export const reasonAnulationTotal = 'Anulación Total' as Reason;
export const reasonAnulationPartial = 'Anulación Parcial' as Reason;
