import { Switch, Redirect } from 'react-router-dom';

import { useAppSelector } from '../../../../hooks/useRedux';

import GuardRoute from '../../../../router/guards/GuardRoute';

import { subPermissionKeys } from '../constants/permissions';
import EventNewAddScreen from '../screens/EventNewAddScreen';
import EventNewEditScreen from '../screens/EventNewEditScreen';
import EventNewScreen from '../screens/EventNewScreen';
import EventAdminListScreen from '../screens/EventListScreen';
import NewsAdminListScreen from '../screens/NewListScreen';

const EventNewRouter = () => {
  const { currentModule } = useAppSelector((state) => state.access);

  const hasPermission = 'periods' in currentModule?.permissions!;

  if (!hasPermission) return <Redirect to="/dashboard" />;

  return (
    <Switch>
      {/* Stages */}
      <GuardRoute
        exact
        path="/administrar-eventos"
        component={EventNewScreen}
        permissionKey={subPermissionKeys.groupKey}
        subPermissionKey={subPermissionKeys.readEvents}
      />
      <GuardRoute
        exact
        path="/administrar-eventos/agregar"
        component={EventNewAddScreen}
        permissionKey={subPermissionKeys.groupKey}
        subPermissionKey={subPermissionKeys.storeEvent}
      />
      <GuardRoute
        exact
        path="/administrar-eventos/:eventNewId/editar"
        component={EventNewEditScreen}
        permissionKey={subPermissionKeys.groupKey}
        subPermissionKey={subPermissionKeys.updateEvent}
      />
      <GuardRoute
        exact
        path="/administrar-eventos/visualizar"
        component={EventAdminListScreen}
        permissionKey={subPermissionKeys.groupKey}
        subPermissionKey={subPermissionKeys.showEventsProfile}
      />
      <GuardRoute
        exact
        path="/administrar-eventos/noticias/visualizar"
        component={NewsAdminListScreen}
        permissionKey={subPermissionKeys.groupKey}
        subPermissionKey={subPermissionKeys.showEventsProfile}
      />
    </Switch>
  );
};

export default EventNewRouter;
