import { Box, Grid, Tooltip, Typography, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { IconClock } from '@tabler/icons';

import { CourseByCurriculum } from '../interfaces/CourseByCurriculum.interfaces';
import { PassData } from '../interfaces/passData.interfaces';

const useStyles = makeStyles((theme) => ({
  root: {
    // border: '1px solid red',
    padding: '20px 20px 20px 30px',
    boxShadow: '0px 0px 20px #BFDAF9',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: '20px',
    width: '100%',
    position: 'relative',

    '&::after': {
      content: `""`,
      position: 'absolute',
      top: 0,
      left: 0,
      borderTopLeftRadius: '10px',
      borderBottomLeftRadius: '10px',
      width: '10px',
      height: '100%',
    },
  },
  cardRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  cardCollapse: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  cardTitle: {
    color: theme.palette.primary.main,
    fontSize: '14px',
    fontWeight: 600,
    overflow: 'hidden',
    wordWrap: 'break-word',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    height: '3em',
  },
  cardSubTitle: {
    color: theme.palette.grey[500],
    fontSize: '14px',
    fontWeight: 400,
  },

  subjectType: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.main,
    fontSize: '12px',
    fontWeight: 400,
  },
  subjectTypeText: {
    color: theme.palette.grey[600],
    fontSize: '12px',
    fontWeight: 400,
  },
  preRequisitesText: {
    color: '#31355C',
    fontSize: '12px',
    fontWeight: 600,
  },
  cardBadges: {
    display: 'flex',
    gap: '4px',
    alignItems: 'center',
    flexWrap: 'wrap',
    // justifyContent: 'flex-start',
  },
  subjectPreRequisites: {
    display: 'flex',
    gap: '4px',
    flexWrap: 'wrap',
    marginTop: '0.5rem',
  },
  hourhandContainer: {
    backgroundColor: theme.palette.grey[200],
    padding: '10px',
    borderRadius: '8px',
  },
  gradeContainer: {
    backgroundColor: theme.palette.grey[200],
    padding: '10px',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
}));

interface Props {
  course: CourseByCurriculum;
  // courseIdDispatch: React.Dispatch<React.SetStateAction<PassData[]>>;
  handlecourseSubject: (data: PassData) => void;
  coursesSubjectId: PassData[];
}

const SubjectCardNew = ({ course, handlecourseSubject, coursesSubjectId }: Props) => {
  // const dispatch = useAppDispatch();
  // const history = useHistory();

  const theme = useTheme();

  const classes = useStyles();

  return (
    <Grid item xs={12} sm={6} md={4} xl={3}>
      <Box
        className={classes.root}
        onClick={(e) =>
          !course.was_aprroved &&
          handlecourseSubject({ subjectId: Number(course.matter_id), courseId: course.id })
        }
        sx={{
          '&::after': {
            bgcolor: course.was_aprroved ? theme.palette.success.main : theme.palette.primary.main,
            // bgcolor: getSubjectStatusColor(subject.approval_status.st_name),
          },
          ...(coursesSubjectId?.some(({ courseId }) => courseId === course.id) && {
            border: `${theme.palette.primary.main} 3px solid`,
            borderRadius: '10px',
          }),
          ...(!course.was_aprroved && { cursor: 'pointer' }),
          opacity: 1,
          ...(course.was_aprroved && { backgroundColor: theme.palette.grey[100] }),
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12} className={classes.cardRow}>
            <Tooltip title={course.matter.mat_name}>
              <Typography variant="subtitle1" className={classes.cardTitle}>
                {course.matter.mat_name}
              </Typography>
            </Tooltip>
          </Grid>

          <Grid container item className={classes.cardRow}>
            <Typography variant="subtitle2" className={classes.cardSubTitle}>
              {course.collaborator.user.person.pers_full_lastname}
            </Typography>
          </Grid>
          <Grid container item className={classes.cardRow}>
            <Typography variant="subtitle2" className={classes.cardSubTitle}>
              {course.classroom.cl_name}
            </Typography>
          </Grid>
          <Grid item className={classes.cardRow}>
            <Box className={classes.hourhandContainer}>
              <Typography variant="subtitle2" className={classes.subjectType}>
                <IconClock size={24} /> Desde: {course.start_date} Hasta: {course.end_date}
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Box className={classes.cardRow}>
          <Grid item>
            {/* <Typography variant="subtitle2">Desde: {period.start_process}</Typography> */}
            <Grid container item className={classes.cardRow}>
              <Typography
                variant="h6"
                color={
                  course.was_aprroved ? theme.palette.success.main : theme.palette.primary.main
                }
              >
                {course.was_aprroved ? 'Aprobada' : 'Por cursar'}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Grid>
  );
};

export default SubjectCardNew;
