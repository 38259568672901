import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import MainCard from '../../../../../shared/components/cards/MainCard';
import LaunchingTable from '../components/LaunchingTable';

const LaunchingScreen = () => {
  const { t } = useTranslation();

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">{t('maintenance.launching.title')}</Typography>
      </Grid>
      <LaunchingTable />
    </Grid>
  );
};

export default LaunchingScreen;
