import classifications from './classifications.json';
import authors from './authors.json';
import shelfs from './shelfs.json';
import tags from './tags.json';
import books from './books.json';

const libraryTranslations = {
  ...classifications,
  ...authors,
  ...shelfs,
  ...tags,
  ...books,
};

export default libraryTranslations;
