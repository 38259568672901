import { Switch } from 'react-router-dom';
import GuardRoute from '../../../../router/guards/GuardRoute';
import CompanyParametersScreen from '../screen/CompanyParametersScreen';

const CompanyParametersRouter = () => {
  return (
    <Switch>
      <GuardRoute
        exact
        path="/parametros-companias"
        component={CompanyParametersScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.read}
      />
    </Switch>
  );
};

export default CompanyParametersRouter;
