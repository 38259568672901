import { Route, Switch } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallbackComponentScreen from '../../shared/components/errors-boundary/ErrorFallbackComponentScreen';
import { EvaluationsRoutes } from '../modules/maintenance/evaluations/router';
import { EvaluationTypeRoutes } from '../modules/maintenance/evaluation_type/router';
import { IntegralEvaluationRoutes } from '../modules/maintenance/integral_evaluation/router';
import { OpeningDatesRoutes } from '../modules/maintenance/opening_dates/router';
import { OptionsTemplateRoutes } from '../modules/maintenance/options_template/router';
import { QuestionRoutes } from '../modules/maintenance/question/router';
import { PeriodsRoutes } from '../modules/maintenance/scope/router';
import { ReportsRoutes } from '../modules/reports/router';
import { GroupsRoutes } from '../modules/maintenance/groups/router';
import UserEvaluationsRouter from '../../shared/user-evaluations/router';
import { LaunchingRoutes } from '../modules/maintenance/launching/router';
import { TemplateRoutes } from '../modules/maintenance/templates/router';
import { FormRoutes } from '../modules/maintenance/form/router';

const EvaluationRouter = () => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallbackComponentScreen}>
      <Switch>
        <Route path="/evaluaciones" component={EvaluationsRoutes} />
        <Route path="/tipos-de-evaluacion" component={EvaluationTypeRoutes} />
        <Route path="/rubricas" component={IntegralEvaluationRoutes} />
        <Route path="/fechas-aperturas" component={OpeningDatesRoutes} />
        <Route path="/escalas" component={OptionsTemplateRoutes} />
        <Route path="/preguntas" component={QuestionRoutes} />
        <Route path="/ambitos" component={PeriodsRoutes} />
        <Route path="/evaluacion-integrales" component={ReportsRoutes} />
        <Route path="/grupos" component={GroupsRoutes} />
        <Route path="/evaluaciones-usuario" component={UserEvaluationsRouter} />
        <Route path="/lanzamiento" component={LaunchingRoutes} />
        <Route path="/plantilla" component={TemplateRoutes} />
        <Route path="/formularios" component={FormRoutes} />
      </Switch>
    </ErrorBoundary>
  );
};

export default EvaluationRouter;
