import { Box, Button, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useAppSelector } from '../../../../../hooks/useRedux';
import ViewVisivleNote from './finished/ViewVisivleNote';

const FinishedDetail = () => {
  const { evalutionFinishedData, evaluationQuestionAnswers: questions } = useAppSelector(
    (state) => state.virtualClassroomStudent.evaluation
  );
  const {
    visible_note,
    act_description,
    act_score_activity,
    details_activity,
    cab_course_student_activity,
  } = evalutionFinishedData;

  const totalQuestion = questions.length;
  const { t } = useTranslation();

  const getQuestionsCompleted = () => {
    let questionsCompleted: number = 0;
    details_activity.forEach((calification) => {
      if (calification.answered_activities.length > 0) {
        questionsCompleted++;
      }
    });
    return questionsCompleted;
  };

  const navigate = useHistory();

  const questionsCompleted = getQuestionsCompleted();

  return (
    <Box
      sx={{
        minHeight: '600px',
        mt: 3,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          mb: 3,
        }}
      >
        <Typography
          sx={{
            mr: 1,
            fontSize: '17px',
          }}
        >
          {act_description}
        </Typography>
        <Typography
          sx={{
            fontWeight: 'bold',
            fontSize: '17px',
          }}
        >{`(${act_score_activity.replace('.00', '')}) ${t(
          'studentVirtualClassroom.test.points'
        )}`}</Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
        }}
      >
        <Typography
          sx={{
            mr: 1,
            fontSize: '17px',
          }}
        >
          {t('studentVirtualClassroom.test.card.description.questions.completed')}
        </Typography>
        <Typography
          sx={{
            fontWeight: 'bold',
            fontSize: '17px',
          }}
        >{`${questionsCompleted} / ${totalQuestion}`}</Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          mt: 8,
          mb: 3,
        }}
      >
        {visible_note === '1' ? (
          <ViewVisivleNote
            score={act_score_activity}
            califications={details_activity}
            questions={questions}
            actualScore={cab_course_student_activity?.act_score}
          />
        ) : (
          <ViewVisivleNote
            score={act_score_activity}
            califications={details_activity}
            questions={questions}
            hideNote={true}
            actualScore={cab_course_student_activity?.act_score}
          />
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          mt: 10,
        }}
      >
        <Button onClick={() => navigate.push('/aula-virtual-estudiante/test')} variant="contained">
          {t('students.virtual_classroom.task.modal.button.back')}
        </Button>
      </Box>
    </Box>
  );
};

export default FinishedDetail;
