import { mainApi } from '../../../../../../api/mainApi';
import { middlewareApi } from '../../../../../middleware/middlewareApi';
import { IResponse } from '../../../../../shared/interfaces/response.interface';
import { IOpeningDate } from '../interfaces/openingDates.interface';

export const openingDatesTags = middlewareApi.enhanceEndpoints({
  addTagTypes: ['OpeningDates'],
});

export const openingDatesApi = openingDatesTags.injectEndpoints({
  endpoints: (builder) => ({
    getOpeningDates: builder.query<
      IResponse<IOpeningDate>,
      {
        page: number;
        perPage: number;
        search: string;
      }
    >({
      queryFn: async ({ page, perPage, search }, { dispatch }) => {
        try {
          const { data } = await mainApi.get<IResponse<IOpeningDate>>('/eva/terms', {
            params: {
              page,
              size: perPage,
              search,
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) =>
        result && result.data
          ? [
              ...result.data.map(({ uuid: id }) => ({ type: 'OpeningDates', id } as const)),
              { type: 'OpeningDates', id: 'LIST' },
            ]
          : [{ type: 'OpeningDates', id: 'LIST' }],
    }),

    getAllOpeningDates: builder.query<IOpeningDate[], {}>({
      // eslint-disable-next-line no-empty-pattern
      queryFn: async ({}, { dispatch }) => {
        try {
          const { data } = await mainApi.get<IOpeningDate[]>('/eva/terms', {
            params: {
              data: 'all',
            },
          });

          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ uuid: id }) => ({ type: 'OpeningDates', id } as const)),
              { type: 'OpeningDates', id: 'LIST' },
            ]
          : [{ type: 'OpeningDates', id: 'LIST' }],
    }),

    getAllOpeningDatesByEvaluation: builder.query<
      IOpeningDate[],
      {
        evaluationId?: string;
      }
    >({
      queryFn: async ({ evaluationId }, { dispatch }) => {
        try {
          const { data } = await mainApi.get<IOpeningDate[]>('/eva/terms', {
            params: {
              data: 'all',
              evaluation_uuid: evaluationId,
            },
          });

          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) => [{ type: 'OpeningDates', id: 'LIST' }],
    }),

    getOpeningDate: builder.query<
      IOpeningDate,
      {
        id: string;
      }
    >({
      queryFn: async ({ id }, { dispatch }) => {
        try {
          const { data } = await mainApi.get<IOpeningDate>(`/eva/terms/${id}`);
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),
    addOpeningDate: builder.mutation<
      IOpeningDate,
      {
        name: string;
        startDate: string;
        endDate: string;
        evaluationId: string;
      }
    >({
      queryFn: async ({ name, startDate, endDate, evaluationId }, { dispatch }) => {
        try {
          const { data } = await mainApi.post<IOpeningDate>('/eva/terms', {
            name,
            start_date: startDate,
            end_date: endDate,
            evaluation_uuid: evaluationId,
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'OpeningDates', id: 'LIST' }],
    }),

    updateOpeningDate: builder.mutation<
      IOpeningDate,
      {
        id: string;
        description: string;
        name: string;
      }
    >({
      queryFn: async ({ id, description, name }, { dispatch }) => {
        try {
          const { data } = await mainApi.put<IOpeningDate>(`/eva/terms/${id}`, {
            description,
            name,
          });
          return { data };
        } catch (error: any) {
          return {
            error,
          };
        }
      },
      invalidatesTags: [{ type: 'OpeningDates', id: 'LIST' }],
    }),
    deleteOpeningDate: builder.mutation<
      IOpeningDate,
      {
        id: string;
      }
    >({
      queryFn: async ({ id }, { dispatch }) => {
        try {
          const { data } = await mainApi.delete<IOpeningDate>(`/eva/terms/${id}`);
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'OpeningDates', id: 'LIST' }],
    }),

    assignGroupsToOpeningDate: builder.mutation<
      IOpeningDate,
      {
        id: string;
        addedGroups: string[];
        removedGroups: string[];
      }
    >({
      queryFn: async ({ id, addedGroups, removedGroups }, { dispatch }) => {
        try {
          const { data } = await mainApi.post<IOpeningDate>(`/eva/terms/${id}/assign-group`, {
            attach_groups: addedGroups,
            detach_groups: removedGroups,
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [
        { type: 'OpeningDates', id: 'LIST' },
        { type: 'Groups', id: 'LIST' },
      ],
    }),
  }),
});

export const {
  useGetOpeningDatesQuery,
  useLazyGetOpeningDatesQuery,
  useGetAllOpeningDatesByEvaluationQuery,
  useGetAllOpeningDatesQuery,
  useLazyGetAllOpeningDatesQuery,
  useGetOpeningDateQuery,
  useLazyGetOpeningDateQuery,
  useAddOpeningDateMutation,
  useUpdateOpeningDateMutation,
  useDeleteOpeningDateMutation,
  useAssignGroupsToOpeningDateMutation,
} = openingDatesApi;
