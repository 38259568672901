import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useAppSelector } from '../../../../../hooks/useRedux';
import MainCard from '../../../../shared/components/cards/MainCard';
import StudentAsignedCourseDetail from '../components/StudentAsignedCourseDetail';
import StudentHistoryAnnouncementsPreview from '../components/StudentHistoryAnnouncementsPreview';
import StudentsAsignedList from '../components/StudentsAsignedList';
import UserHistoryAnnouncementsPreview from '../components/UserHistoryAnnouncementsPreview';
import StudentAsignedSendAnnouncementAll from '../components/StudentAsignedSendAnnouncementAll';
import StudentAsignedSendAnnouncement from '../components/StudentAsignedSendAnnouncement';
import { useTranslation } from 'react-i18next';

const StudentAsignedListScreen = () => {
  const { t } = useTranslation();

  const {
    studentSelected,
    notifyAll,
    openModalStudentHistoryAnnouncements,
    openModalUserAnnouncements,
    openModalStudentAds,
    openModalStudentDetailCourse,
  } = useAppSelector((state) => state.studentsAsigned);

  return (
    <React.Fragment>
      <Grid component={MainCard} sx={{ padding: 1 }}>
        <Grid sx={{ mb: 3 }}>
          <Typography variant="h2">
            {t('teachers.academic_counseling.students_assigned.title')}
          </Typography>
        </Grid>
      </Grid>
      <Grid component={MainCard} style={{ marginTop: 10 }}>
        <StudentsAsignedList />
        {studentSelected && openModalStudentDetailCourse && <StudentAsignedCourseDetail />}
        {studentSelected && openModalStudentAds && <StudentAsignedSendAnnouncement />}
        {notifyAll && <StudentAsignedSendAnnouncementAll />}
        {openModalUserAnnouncements && <UserHistoryAnnouncementsPreview />}
        {openModalStudentHistoryAnnouncements && <StudentHistoryAnnouncementsPreview />}
      </Grid>
    </React.Fragment>
  );
};

export default StudentAsignedListScreen;
