export const permissionKey = 'hourSummary';

export interface SubPermissionKeys {
  read: string;
  create: string;
  edit: string;
  delete: string;
}

export const subPermissionKeys: SubPermissionKeys = {
  read: 'hours-summaries-listar-resumen-horas-colaborador',
  create: 'hours-summaries-crear-resumen-horas-colaborador',
  edit: 'hours-summaries-actualizar-resumen-horas-colaborador',
  delete: 'hours-summaries-borrar-resumen-horas-colaborador',
};
