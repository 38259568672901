import * as Yup from 'yup';

export const GeneralDetailsSchema = Yup.object().shape({
  yearPublication: Yup.date().required('El año de publicación es requerido.'),
  title: Yup.string().required('El título es requerido.'),
  code: Yup.string().required('El código es requerido.'),
  details: Yup.string().required('Los detalles son requeridos.'),
  eventDate: Yup.date().required('La fecha del evento es requerida.'),
  typePublication: Yup.string().required('El tipo de publicación es requerido.'),
  educationLevelId: Yup.number()
    .min(1, 'Debe ser un número mayor.')
    .required('El nivel educativo es requerido.'),
  countryId: Yup.number().min(1, 'Debe ser un número mayor').required('El país es requerido'),
  cityId: Yup.string().required('La ciudad es requerido.'),
  status: Yup.string().required('El estado es requerido.'),
});

export const SpecificDetailsMagazineSchema = Yup.object().shape({
  urlMagazine: Yup.string().required('La url de la revista es requerida.'),
  numberMagazine: Yup.string().required('La cantidad de revistas es requerida.'),
  magazine: Yup.string().required('La revista es requerida.'),
  magazinesJri: Yup.string().required('La revista SJRi es requerida.'),
  magazinesJrq: Yup.string().required('La revista SJR-Q es requerida.'),

  pubDOI: Yup.string().required('El DOI es requerido.'),
  pubISSN: Yup.string().required('El ISSN es requerido.'),
  pubEditorial: Yup.string().required('La editorial es requerida.'),
  numberPage: Yup.number().required('La cantidad de páginas es requerida'),
});

export const SpecificDetailsBookSchema = Yup.object().shape({
  book: Yup.string().required('El libro es requerido.'),
  // chapter: Yup.string().required('El capítulo es requerido.'),
  bookISBN: Yup.string().required('El código ISBN es requerido.'),
  bookEdition: Yup.string().nullable(),
  numberChapter: Yup.string().required('La cantidad de páginas es requerida'),
  editor: Yup.string().required('La editorial es requerida.'),
});

export const SpecificDetailsPonenciaSchema = Yup.object().shape({
  nameCongress: Yup.string().required('La línea de investigación es requerida.'),
  organizer: Yup.string().required('El organizador es requerido.'),
  organizingCommittee: Yup.string().required('El comité organizador es requerido.'),
});

export const AssignmentsSchema = Yup.object().shape({
  linesGroupResearch: Yup.array().required('El grupo de investigación es requerido.'),
  authorsScientificPublication: Yup.array().required('El autor de la publicación es requerido.'),
  investigationLines: Yup.array().required('La línea de investigación es requerida.'),
  linesGroupResearchPrincipal: Yup.string().required('El grupo principal es requerido.'),
  projectDocuments: Yup.array().required('El documento es necesario'),
});
