import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';

import { CollaboratorsStepper, FormikStep } from '../CollaboratorsStepper';
import CollaboratorTypeStep from './CollaboratorTypeStep';
import DisabilitiesStep from './DisabilitiesStep';
import PersonaDataStep from './PersonalDataStep';

import {
  CivilStatusSchema,
  CollaboratorTypeSchema,
  ComplementaryDataSchema,
  ContractDetailsSchema,
  DisabilitiesSchema,
  PersonalDataSchema,
} from '../../constants/validationSchemas';
import ContractDetailsStep from './ContractDetailsStep';
import { useAddCollaboratorMutation } from '../../slices/collaboratorsApiSlice';
import { useAppSelector } from '../../../../../hooks/useRedux';

import CivilStatusStep from './CivilStatusStep';
import ComplementaryDataStep from './ComplementaryData';
import ErrorAlert from '../../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../../shared/components/alerts/SuccessAlert';
import { useState } from 'react';
import { DisabilityType } from '../../interfaces/disabilityType.interfaces';
import { EducationLevel } from '../../../../maintenance/education-levels/interfaces/educationLevels.interfaces';
import { Campus } from '../../../../maintenance/campus/interfaces/campus.interfaces';
import { Catalog } from '../../../../shared/interfaces/catalogs.interfaces';

interface AddCollaboratorWizardProps {
  collaboratorType?: string;
  collaboratorIdentification?: string;
  collaboratorIdentificationType?: Catalog;
}

const AddCollaboratorWizard = ({
  collaboratorIdentification,
  collaboratorIdentificationType,
  collaboratorType,
}: AddCollaboratorWizardProps) => {
  const history = useHistory();

  const { currentProfile } = useAppSelector((state) => state.access);

  const { personData } = useAppSelector((state) => state.collaborators);

  const [showAlerts, setShowAlerts] = useState(false);

  const profileId = currentProfile!.profileId;

  const [addColaborator, { isError, error, isSuccess }] = useAddCollaboratorMutation();

  return (
    <>
      <Grid container>
        <Grid item>
          {isError && showAlerts && (
            <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
          )}

          {isSuccess && showAlerts && (
            <SuccessAlert
              message={
                personData?.identification
                  ? 'El colaborador ha sido editado correctamente.'
                  : 'El colaborador ha sido creado correctamente.'
              }
              handleDismiss={() => setShowAlerts(false)}
              timeoutCallback={{
                timer: 3,
                callbackFn: () => {
                  history.push('/administrar-colaboradores');
                },
              }}
            />
          )}
        </Grid>
      </Grid>
      <CollaboratorsStepper
        initialValues={{
          collaboratorType: collaboratorType || '',

          // Datos personales
          identificationType: collaboratorIdentificationType || null,
          identification: collaboratorIdentification || '',
          firstName: '',
          secondName: '',
          lastName: '',
          secondLastName: '',
          gender: null,
          birthDate: new Date(),
          homeAddress: '',
          homePhone: '',
          cellPhone: '',
          personalEmail: '',
          city: null,
          sector: null,
          currentCity: null,
          nacionality: null,
          //si es casado
          civilStatus: null,
          relativeIdentificationType: null,
          relativeIdentification: '',
          relativeFirstName: '',
          relativeSecondName: '',
          relativeLastName: '',
          relativeSecondLastName: '',
          relativeRelationDescription: '',
          typeKinshigKeyword: '',
          relativeGender: '',
          relativeTypeReligion: null,
          relativeEthnic: null,
          //datos complementarios
          proffesion: '',
          studyReason: '',
          hasVehicle: false,
          taxPayersHousehold: 0,
          numChild: 0,
          vivienda: null,
          numBedrooms: 1,
          religion: null,
          etnic: null,
          //accesibilidad
          hasDisability: false,
          numDisabilityCertificate: '',
          disabilityPercentage: '',
          disabilities: null,
          //contrato
          enterDate: null,
          leftDate: null,
          hasNombramiento: false,
          startNombramientoDate: null,
          endNombramientoDate: null,
          journeyType: null,
          dependencyRelation: false,
          journeyHours: 0,
          collaboratorEmail: '',
          department: null,
          position: null,
          educationLevels: null,
          principalEducationLevel: null,
          campus: null,
          status: null,
        }}
        validate={({ startNombramientoDate, endNombramientoDate, enterDate, leftDate }) => {
          const errors: any = {};
          if (leftDate && enterDate > leftDate) {
            const enterDateInvalid = 'Fecha de salida no debe ser superior a fecha de entrada.';
            errors.leftDate = enterDateInvalid;
          }
          if (startNombramientoDate > endNombramientoDate) {
            const endNombDateInvalid =
              'Fecha de fin del nombramiento no debe ser superior a fecha de inicio.';
            errors.endNombramientoDate = endNombDateInvalid;
          }
          return errors;
        }}
        onSubmit={async (values) => {
          await addColaborator({
            profileId,
            colaboratorPayload: {
              // Datos personales
              coll_type: values.collaboratorType,
              type_identification_keyword: values.identificationType?.cat_keyword,
              pers_identification: values.identification,
              pers_firstname: values.firstName,
              pers_secondname: values.secondName,
              pers_first_lastname: values.lastName,
              pers_second_lastname: values.secondLastName,
              email: values.personalEmail,
              status_marital_keyword: values.civilStatus?.cat_keyword,
              pers_nationality_keyword: values.nacionality?.cat_keyword,
              city_keyword: values.city?.cat_keyword,
              current_city_keyword: values.currentCity.cat_keyword,
              pers_direction: values.homeAddress,
              sector_keyword: values.sector?.cat_keyword,
              pers_gender: values.gender,
              pers_cell: values.cellPhone,
              pers_phone_home: values.homePhone,
              pers_date_birth: format(values.birthDate!, 'yyyy-MM-dd'),
              // Si es casado
              type_identification_keyword_relatives_person:
                values.relativeIdentificationType?.cat_keyword,
              pers_identification_relatives_person: values.relativeIdentification,
              pers_firstname_relatives_person: values.relativeFirstName,
              pers_secondname_relatives_person: values.relativeSecondName,
              pers_first_lastname_relatives_person: values.relativeLastName,
              pers_second_lastname_relatives_person: values.relativeSecondLastName,
              pers_relatives_person_desc: values.relativeRelationDescription,
              typeKinship_keyword: values.typeKinshigKeyword,
              pers_gender_relative: values.relativeGender?.cat_name,
              type_religion_relative_keyword: values.relativeTypeReligion?.cat_keyword,
              ethnic_relative_keyword: values.relativeEthnic?.cat_keyword,
              // Complementary
              pers_profession: values.proffesion,
              pers_study_reason: values.studyReason,
              pers_has_vehicle: values.hasVehicle,
              pers_num_taxpayers_household: values.taxPayersHousehold,
              pers_num_child: values.numChild,
              vivienda_keyword: values.vivienda?.cat_keyword,
              pers_num_bedrooms: values.numBedrooms,
              type_religion_keyword: values.religion?.cat_keyword,
              ethnic_keyword: values.etnic?.cat_keyword,
              // Accesibilidad
              pers_has_disability: values.hasDisability,
              pers_disability_identification: values.numDisabilityCertificate,
              pers_disability_percent: values.disabilityPercentage,
              pers_disabilities: values.disabilities?.map((d: DisabilityType) => d.id),
              // Colaborador
              coll_entering_date: values.enterDate ? format(values.enterDate!, 'yyyy-MM-dd') : '',
              coll_leaving_date: values.leftDate ? format(values.leftDate!, 'yyyy-MM-dd') : '',
              coll_has_nomination: values.hasNombramiento,
              coll_nomination_entering_date: values.startNombramientoDate
                ? format(values.startNombramientoDate, 'yyyy-MM-dd')
                : '',
              coll_nomination_leaving_date: values.endNombramientoDate
                ? format(values.endNombramientoDate, 'yyyy-MM-dd')
                : '',
              coll_journey_description: values.journeyType?.id,
              coll_dependency: values.dependencyRelation,
              coll_journey_hours: values.journeyHours,
              coll_email: values.collaboratorEmail,
              position_company_id: values.position?.id,
              education_levels: values.educationLevels?.map((d: EducationLevel) => d.id),
              education_level_principal_id: values.principalEducationLevel?.id,
              campus: values.campus.map((d: Campus) => d.id),
              status_id: values.status.id,

              // random data
              pers_is_provider: values.isProvider,
            },
          });

          setShowAlerts(true);
        }}
      >
        <FormikStep label="Tipo de Colaborador" validationSchema={CollaboratorTypeSchema}>
          <Box paddingBottom={3} paddingTop={3}>
            <CollaboratorTypeStep />
          </Box>
        </FormikStep>
        <FormikStep label="Información Personal" validationSchema={PersonalDataSchema}>
          <Box paddingBottom={3} paddingTop={3}>
            <PersonaDataStep />
          </Box>
        </FormikStep>
        <FormikStep label="Estado Civil" validationSchema={CivilStatusSchema}>
          <Box paddingBottom={3} paddingTop={3}>
            <CivilStatusStep />
          </Box>
        </FormikStep>
        <FormikStep label="Datos complementarios" validationSchema={ComplementaryDataSchema}>
          <Box paddingBottom={3} paddingTop={3}>
            <ComplementaryDataStep />
          </Box>
        </FormikStep>
        <FormikStep label="Accesibilidad" validationSchema={DisabilitiesSchema}>
          <Box paddingBottom={2} paddingTop={2}>
            <DisabilitiesStep />
          </Box>
        </FormikStep>
        <FormikStep label="Contrato" validationSchema={ContractDetailsSchema}>
          <Box paddingBottom={2} paddingTop={2}>
            <ContractDetailsStep />
          </Box>
        </FormikStep>
      </CollaboratorsStepper>
    </>
  );
};

export default AddCollaboratorWizard;
