import { useTranslation } from 'react-i18next';
import DialogAlert from '../../../shared/components/dialogs/DialogAlert';
import { useReceiptCancellationMutation } from '../slices/receiptApiSlice';

interface Props {
  open: boolean;
  receiptId: number;
  idFact: number;
  handleClose: () => void;
}
const CancelReceiptDialog = ({ open, receiptId, idFact, handleClose }: Props) => {
  const { t } = useTranslation();

  const [
    debtsCancellationRequest,
    { isLoading: isLoadingDC, isSuccess: isSuccessDC, isError: isErrorDC, error: errorDC },
  ] = useReceiptCancellationMutation();

  //trigger cancelation debts
  const handleCancellation = () => {
    debtsCancellationRequest({
      idFact,
      receiptId,
    });
  };

  return (
    <>
      <DialogAlert
        open={open} //{openDebtsCancellation}
        isCloseClickOutComponent={false}
        onClose={handleClose}
        handleAction={handleCancellation}
        isLoading={isLoadingDC}
        isSuccess={isSuccessDC}
        isError={isErrorDC}
        error={errorDC as string}
        title={t('financial.receipts.reverse_dialog.cancel_receipt.title')}
        bodyText={t('financial.receipts.reverse_dialog.cancel_receipt.body')}
        updateSuccess={t('financial.receipts.reverse_dialog.cancel_receipt.success')}
        customTitleSendButton={t('shared.accept_button.label')}
        customTitleCancelButton={t('shared.cancel_button.label')}
      />
    </>
  );
};

export default CancelReceiptDialog;
