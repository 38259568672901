import {
  IconNotebook,
  IconCash,
  IconFileExport,
  IconCertificate,
  IconCalendarEvent,
  IconArchive,
  IconListCheck,
  IconClipboardCheck,
  IconReceipt2,
  IconCreditCard,
  IconNotification,
  IconNews,
  IconSchool,
  IconGift,
  IconUsers,
  IconCalendarTime,
  IconHome,
  IconFiles,
  IconFileReport,
  IconBus,
} from '@tabler/icons';
import { shared } from './shared';

// Icon Group
const icons = {
  IconNotebook,
  IconCash,
  IconFileExport,
  IconCertificate,
  IconNotification,
  IconCalendarEvent,
  IconArchive,
  IconListCheck,
  IconClipboardCheck,
  IconReceipt2,
  IconCreditCard,
  IconNews,
  IconUsers,
  IconSchool,
  IconGift,
  IconCalendarTime,
  IconHome,
  IconFiles,
  IconFileReport,
  IconBus,
};

// TODO: asignar los permissions key correspondientes

export const teachers = {
  id: 'teachers', /// se debe cambiar a teacher
  type: 'group',
  title: 'Docentes',
  children: [
    {
      id: 'inicio-docente',
      title: 'inicio',
      menuId: 'teachers.menu.dashboard',
      type: 'item',
      url: '/inicio-docente',
      permissionKey: 'collaborator',
      icon: icons['IconHome'],
    },
    {
      id: 'administrar-cursos-profesor',
      title: 'Cursos',
      menuId: 'teachers.menu.courses',
      type: 'item',
      url: '/administrar-cursos-profesor',
      permissionKey: 'collaborator',
      icon: icons['IconNotebook'],
    },
    {
      id: 'solicitudes',
      title: 'test',
      type: 'collapse',
      permissionKey: 'teachers',
      icon: icons['IconFileExport'],
      children: [
        {
          id: 'events',
          title: 'test',
          type: 'item',
          url: '/eventos',
          permissionKey: 'teachers',
          icon: icons['IconNotification'],
          children: [
            {
              id: 'eventos',
              title: 'Eventos',
              type: 'item',
              url: '/eventos',
              permissionKey: 'teachers',
            },
            {
              id: 'noticias',
              title: 'Noticias',
              type: 'item',
              url: '/noticias',
              permissionKey: 'teachers',
            },
          ],
        },
      ],
    },
    {
      id: 'administrar-horarios-profesor',
      title: 'Horarios',
      menuId: 'teachers.menu.schedules',
      type: 'item',
      url: '/administrar-horarios-profesor',
      permissionKey: 'collaborator',
      icon: icons['IconCalendarEvent'],
    },
    {
      id: 'administrar-estudiantes-asignados',
      title: 'Consejería Académica',
      menuId: 'teachers.menu.academic_counseling',
      type: 'item',
      url: '/administrar-estudiantes-asignados',
      permissionKey: 'collaborator',
      icon: icons['IconUsers'],
    },
    {
      id: 'cumpleanios',
      title: 'Cumpleaños',
      menuId: 'teachers.menu.birthday',
      type: 'item',
      url: '/cumpleanios',
      permissionKey: 'collaborator',
      icon: icons['IconGift'],
    },
    {
      id: 'eventos-visualizar',
      title: 'Eventos',
      menuId: 'teachers.menu.events',
      type: 'item',
      url: '/eventos-visualizar',
      permissionKey: 'collaborator',
      icon: icons['IconNotification'],
    },
    {
      id: 'noticias-visualizar',
      title: 'Noticias',
      menuId: 'teachers.menu.news',
      type: 'item',
      url: '/noticias-visualizar',
      permissionKey: 'collaborator',
      icon: icons['IconCalendarTime'],
    },

    {
      id: 'talento-humano',
      title: 'Talento Humano',
      type: 'collapse',
      permissionKey: 'collaborator',
      icon: icons['IconFileReport'],
      children: [
        {
          id: 'entradas-salidas',
          title: 'Marcaciones',
          type: 'item',
          url: '/entradas-salidas',
          permissionKey: 'collaborator',
        },
        {
          id: 'roles-pagos',
          title: 'Roles de Pago',
          type: 'item',
          url: '/roles-pagos',
          permissionKey: 'collaborator',
        },
        {
          id: 'ticket-comida',
          title: 'Ticket de comida',
          type: 'item',
          url: '/ticket-comida',
          permissionKey: 'collaborator',
        },
        // {
        //   id: 'anticipo',
        //   title: 'Solicitud de Anticipo',
        //   type: 'item',
        //   url: '/solicitud/anticipo',
        //   permissionKey: 'collaborator',
        // },
        // {
        //   id: 'solictudvacaciones',
        //   title: 'Solicitud de Vacaciones',
        //   type: 'item',
        //   url: '/solicitud/vacaciones',
        //   permissionKey: 'collaborator',
        // },
        // {
        //   id: 'solictuddiapersonal',
        //   title: 'Solicitud de Día Personal',
        //   type: 'item',
        //   url: '/solicitud/dia-personal',
        //   permissionKey: 'collaborator',
        // },
        // {
        //   id: 'solictudhappybirthdayhour',
        //   title: 'Solicitud de Happy Birthday Hour',
        //   type: 'item',
        //   url: '/solicitud/happy-birthday-hour',
        //   permissionKey: 'collaborator',
        // },
        // {
        //   id: 'solictud-estados',
        //   title: 'Estados de Solicitudes',
        //   type: 'item',
        //   url: '/solicitud/estados',
        //   permissionKey: 'collaborator',
        // },
      ],
    },
    {
      id: 'solicitudes',
      title: 'Solicitudes',
      type: 'collapse',
      permissionKey: 'collaborator',
      icon: icons['IconFiles'],
      children: [
        // {
        //   id: 'entradas-salidas',
        //   title: 'Marcaciones',
        //   type: 'item',
        //   url: '/entradas-salidas',
        //   permissionKey: 'collaborator',
        // },
        // {
        //   id: 'roles-pagos',
        //   title: 'Roles de Pago',
        //   type: 'item',
        //   url: '/roles-pagos',
        //   permissionKey: 'collaborator',
        // },
        // {
        //   id: 'ticket-comida',
        //   title: 'Ticket de Comida',
        //   type: 'item',
        //   url: '/ticket-comida',
        //   permissionKey: 'collaborator',
        // },
        {
          id: 'anticipo',
          title: 'Anticipo',
          type: 'item',
          url: '/solicitud/anticipo',
          permissionKey: 'collaborator',
        },
        {
          id: 'solictudvacaciones',
          title: 'Vacaciones',
          type: 'item',
          url: '/solicitud/vacaciones',
          permissionKey: 'collaborator',
        },
        {
          id: 'solictuddiapersonal',
          title: 'Día Personal',
          type: 'item',
          url: '/solicitud/dia-personal',
          permissionKey: 'collaborator',
        },
        {
          id: 'solictudhappybirthdayhour',
          title: 'Happy Birthday Hour',
          type: 'item',
          url: '/solicitud/happy-birthday-hour',
          permissionKey: 'collaborator',
        },
        {
          id: 'solictud-estados',
          title: 'Listado',
          type: 'item',
          url: '/solicitud/estados',
          permissionKey: 'collaborator',
        },
      ],
    },
    {
      id: 'bus-route',
      title: 'Ruta de buses',
      menuId: 'academic.menu.bus_route',
      type: 'item',
      url: '/bus-route',
      permissionKey: 'collaborator',
      icon: icons['IconBus'],
    },
    {
      id: 'biblioteca',
      title: 'Biblioteca',
      menuId: 'students.menu.library',
      type: 'collapse',
      // url: 'https://biblio.ecotec.edu.ec/apps',
      url: '/biblioteca/inicio',
      permissionKey: 'collaborator',
      icon: icons['IconNotebook'],
      children: [
        {
          id: 'biblioteca/inicio',
          title: 'Libros',
          menuId: 'students.menu.library.books',
          type: 'item',
          url: '/biblioteca/inicio',
          permissionKey: 'collaborator',
        },
        {
          id: 'biblioteca-reservas',
          title: 'Reservas',
          menuId: 'students.menu.library.reservations',
          type: 'item',
          url: '/biblioteca-reservas',
          permissionKey: 'collaborator',
        },
        {
          id: 'biblioteca-prestamos',
          title: 'Préstamos',
          menuId: 'students.menu.library.loans',
          type: 'item',
          url: '/biblioteca-prestamos',
          permissionKey: 'collaborator',
        },
      ],
    },
    // shared menu
    ...shared,
  ],
};
