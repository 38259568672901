import { useEffect, useState } from 'react';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import StatusChip from '../../../shared/components/badges/StatusChip';
import AddButton from '../../../shared/components/tables/buttons/AddButton';
import DeleteButton from '../../../shared/components/tables/buttons/DeleteButton';
import EditButton from '../../../shared/components/tables/buttons/EditButton';
import {
  useGetParallelsQuery,
  useLazyGetAvailablesParallelsQuery,
} from '../slices/parallelsApiSlice';
import { openDeleteModalWith } from '../slices/parallesSlice';
import SearchPaginatedTable, {
  FilterArrow,
} from '../../../shared/components/tables/SearchPaginatedTable';
import useSharedGuard from '../../../../hooks/useSharedGuard';
import { subPermissionKeys } from '../constants/permissions';
import { useTranslation } from 'react-i18next';
import { statusAvailables } from '../../../shared/constants/resourceStatus';

const ParallelsTable = () => {
  const { t } = useTranslation();
  const { currentProfile } = useAppSelector((state) => state.access);

  const dispatch = useAppDispatch();

  const { hasAccess } = useSharedGuard(subPermissionKeys);

  const { path } = useRouteMatch();
  const history = useHistory();

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const [search, setSearch] = useState('');

  const [filterArrow, setFilterArrow] = useState<FilterArrow>(null!);

  useEffect(() => {
    setPage(1);
  }, [perPage, search]);

  const { isLoading, error, data, isFetching } = useGetParallelsQuery(
    {
      profileId: currentProfile?.profileId!,
      page,
      perPage,
      search,
      ...(filterArrow && {
        type_sort: filterArrow?.direction,
        sort: filterArrow?.name,
      }),
    },
    { refetchOnMountOrArgChange: 30 }
  );

  const [getAllParallels] = useLazyGetAvailablesParallelsQuery();

  const getAllDatafetch = async () => {
    try {
      const classroomsData = await getAllParallels(
        {
          search,
          ...(filterArrow && {
            type_sort: filterArrow?.direction,
            sort: filterArrow?.name,
          }),
        },
        true
      ).unwrap();

      return classroomsData.map((d, index) => ({
        id: index + ((page - 1) * perPage + 1),
        name: d.par_name,
        description: d.par_description,
        acronym: d.par_acronym,
        statusId:
          statusAvailables.find((status) => status.id === Number(d.status_id))?.name || d.status_id,
      }));
    } catch (error) {
      return [];
    }
  };

  const handleDelete = async (paralleId: number) => {
    dispatch(openDeleteModalWith(paralleId));
  };

  const goToAdd = () => {
    history.push(`${path}/agregar`);
  };

  const datafetch = data?.data!.map((d, index) => ({
    id: index + ((page - 1) * perPage + 1),
    name: d.par_name,
    description: d.par_description,
    acronym: d.par_acronym,
    statusId: d.status_id,
    options: d,
  }));

  return (
    <SearchPaginatedTable
      data={datafetch!}
      headers={{
        id: 'N°',
        name: t('academic.parallels.name'),
        description: t('academic.parallels.description'),
        acronym: t('academic.parallels.acronym'),
        statusId: t('academic.parallels.status'),
        options: t('academic.parallels.options'),
      }}
      keyExtractor={(item) => item.id.toString()}
      listFilterArrows={{
        name: 'par_name',
        description: 'par_description',
        acronym: 'par_acronym',
        statusId: 'status_id',
      }}
      exportProps={{
        fileName: 'Paralelos',
        files: { excel: true },
        allDataFetchHandler: getAllDatafetch,
      }}
      filterArrow={filterArrow}
      setFilterArrow={setFilterArrow}
      searchPlacehoder={t('academic.parallels.search')}
      isLoading={isLoading}
      isFetching={isFetching}
      error={error}
      perPage={perPage}
      setPerPage={setPerPage}
      total={data?.total || 0}
      page={page}
      setPage={setPage}
      numHeader={6}
      search={search}
      setSearch={setSearch}
      customRenderers={{
        statusId: (row) => <StatusChip statusId={Number(row.statusId)} />,
        options: (row) => (
          <>
            {hasAccess('edit') && (
              <Link to={`${path}/${row.options.id}/editar`}>
                <EditButton />
              </Link>
            )}
            {hasAccess('delete') && (
              <DeleteButton handleDelete={() => handleDelete(row.options.id)} />
            )}
            {!hasAccess('edit') && !hasAccess('delete') && t('academic.parallels.none')}
          </>
        ),
      }}
      ActionButtons={<>{hasAccess('create') && <AddButton size="large" onClick={goToAdd} />}</>}
      customDataCellsProperties={{
        id: { align: 'center' },
        name: { align: 'center' },
        description: { align: 'center' },
        acronym: { align: 'center' },
        statusId: { align: 'center' },
        options: { align: 'center' },
      }}
    />
  );
};

export default ParallelsTable;
