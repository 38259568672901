import * as yup from 'yup';

export const groupsCourseSchema = yup.object().shape({
  name: yup.string().required('maintenance.course.form.name.required'),
  keyword: yup.string().required('maintenance.course.form.keyword.required'),
  eva_type_uuid: yup.string().required('maintenance.course.form.eva_type_uuid.required'),
  period_id: yup.string().required('maintenance.course.form.period_id.required'),
  course: yup
    .array()
    .min(1, 'maintenance.course.from.course.min')
    .required('maintenance.course.form.course.required'),
});
