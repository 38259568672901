import {
  Grid,
  Typography,
  Autocomplete,
  TextField,
  Divider,
  ButtonGroup,
  Button,
  IconButton,
  Tooltip,
  Alert as AlertComponent,
  // CircularProgress,
} from '@material-ui/core';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../../hooks/useRedux';
import ErrorAlert from '../../../../shared/components/alerts/ErrorAlert';
import MainCard from '../../../../shared/components/cards/MainCard';
import ButtonBack from '../../Components/ButtonBack';
import {
  useGetDistributionsByCourseQuery,
  useGetSupplysToDistributionsQuery,
  usePostConfigurationSuppliesMutation,
} from '../../slices/virtualClassroomTeacherApiSlice';
import { IconHelp } from '@tabler/icons';
import InfoDialog from '../components/ModalSettings/InfoDialog';

function ConfigCalifications() {
  const { t } = useTranslation();

  const { currentProfile } = useAppSelector((state) => state.access);

  const [DistributionId, setDistributionId] = useState<string>();

  console.log(DistributionId);

  const {
    course: { courseId },
  } = useAppSelector((state) => state.virtualClassroomTeacherShared);
  const { data, isLoading } = useGetDistributionsByCourseQuery({
    courseId: String(courseId!)!,
    profileId: currentProfile?.profileId!,
  });

  const { data: SupplyOfDistributions } = useGetSupplysToDistributionsQuery({
    courseId: String(courseId!)!,
    profileId: currentProfile?.profileId!,
    distributionsId: DistributionId!,
  });

  const [Alert, setAlert] = useState(false);

  const [PostConfigurateSupply, { isError: ErrorPostSupply, error: msgErrorsSupply }] =
    usePostConfigurationSuppliesMutation();

  const TiposDeCalificaciones = ['Mejor nota', 'Promedio', 'Suma', 'Mediana'];

  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const handleSetOpenDialog = () => {
    setOpenDialog(!openDialog);
  };

  console.log(SupplyOfDistributions);
  const ItemInsumo = (nameInsumo: string, type_calculation: string, idSupply: string) => {
    const ConfigurateSupply = async (typeCalculation: string) => {
      await PostConfigurateSupply({
        courseId: String(courseId!)!,
        payloadConfiguration: {
          calification_models_distributions_id: DistributionId!,
          supply_id: idSupply,
          type_calculation: typeCalculation,
        },
      });
      setAlert(true);
    };
    return (
      <Grid container p={'5px 0'} alignItems={'center'} xs={7} justifyContent="center">
        <Grid item m={2}>
          <Typography variant="h4">
            <Tooltip title="¿Qué significa esto?">
              <IconButton aria-label="info" onClick={handleSetOpenDialog}>
                <IconHelp />
              </IconButton>
            </Tooltip>
            {nameInsumo}
          </Typography>
        </Grid>

        <Grid item m={2}>
          <ButtonGroup>
            {TiposDeCalificaciones.map((item) => {
              return (
                <Button
                  // endIcon={LoadingSupply && <CircularProgress />}
                  onClick={() => ConfigurateSupply(item)}
                  variant={type_calculation === item ? 'contained' : 'outlined'}
                >
                  {item}
                </Button>
              );
            })}
          </ButtonGroup>
        </Grid>
      </Grid>
    );
  };
  const SupplysOfDistributions = () => {
    return (
      <>
        {!DistributionId ? (
          <Grid item mt={3}>
            <Typography variant="h4">Seleccione una Distribución</Typography>
          </Grid>
        ) : (
          SupplyOfDistributions?.map((item) => {
            return ItemInsumo(
              item.ref2,
              item.config ? item.config.type_calculation! : '',
              item.ref2_supply_id
            );
          })
        )}
      </>
    );
  };

  return (
    <Grid
      component={MainCard}
      minHeight="70vh"
      sx={{
        borderTopLeftRadius: '0px',
        borderTopRightRadius: '0px',
      }}
    >
      {ErrorPostSupply && Alert && (
        <ErrorAlert onClose={() => setAlert(false)} message={msgErrorsSupply as string} />
      )}
      <Grid container display={'flex'} justifyContent="space-between" alignItems={'center'}>
        <Typography mb={5} color={'primary'} textAlign="center" variant="h3">
          {t('teacher_virtual_classroom.settings.title.supply')}
        </Typography>
        <ButtonBack />
      </Grid>

      <Divider />

      <Grid container justifyContent={'center'} p={'12px 0'} spacing={1}>
        <Grid xs={12} item>
          <AlertComponent severity="info">
            <Typography variant="body2">
              <strong>{t('teacher_virtual_classroom.settings.info.alert.title')}</strong>
              {t('teacher_virtual_classroom.settings.info.alert.label')}
            </Typography>
          </AlertComponent>
        </Grid>

        <Grid xs={5} item gap={2} alignItems="center" display="flex">
          <Typography variant="h4" color="primary">
            {t('teacher_virtual_classroom.settings.title.distributions')}
          </Typography>

          <Autocomplete
            loading={isLoading}
            fullWidth
            options={data || []}
            onChange={(_, item) => (item ? setDistributionId(item.ref1_supply_id) : null)}
            getOptionLabel={(item) => item.distribution}
            renderInput={(children) => (
              <TextField
                {...children}
                placeholder={t('teacher_virtual_classroom.settings.title.distributions')}
              />
            )}
          />
        </Grid>
      </Grid>
      <Divider />
      <Grid container mt={1}>
        <Grid
          margin="auto"
          container
          height={'100%'}
          justifyContent={'center'}
          xs={10}
          padding="5px"
        >
          {SupplysOfDistributions()}
        </Grid>
      </Grid>
      <InfoDialog open={openDialog} handleCloseDialog={handleSetOpenDialog} />
    </Grid>
  );
}

export default ConfigCalifications;
