import { IMeetingPayloadForm } from '../interfaces/formik.interface';
import { Meeting } from '../interfaces/meetings.interface';

export const formikInitialValue = (values?: Meeting): IMeetingPayloadForm => {
  return {
    act_description: '',
    type: 2,
    start_time: null,
    duration: 0,
    join_before_host: true,
    approval_type: null,
    auto_recording: 'none',
    close_registration: true,
    host_video: true,
    jbh_time: null,
    mute_upon_entry: true,
    participant_video: true,
    weekly_days: '3,5',
    weeks: 2,
    participants: [],
  };
};
