import { Grid } from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import GoBackBtnTitle from '../../../shared/components/buttons/GoBackBtnTitle';

import MainCard from '../../../shared/components/cards/MainCard';
import EditForm from '../components/EditForm';

const MaintenanceProductEdit = () => {
  const { t } = useTranslation();

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <GoBackBtnTitle title={t('financial.maintenance_prod.edit.title')} />
      </Grid>

      <EditForm />
    </Grid>
  );
};

export default MaintenanceProductEdit;
