import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Collaborator } from '../../../hr/hours-to-teachers/interfaces/collaborator.interfaces';
import { ISearchTable } from '../../../shared/interfaces/paginatedTable.interface';
import { ITeacher } from '../interfaces/teachers.interface';
import { PersonSearch } from '../../requisition-teacher/interfaces/person.interfaces';

interface UsersState {
  collabList: ITeacher[];
  teachersTable: ISearchTable;
  teacherCourseTable: ISearchTable;

  collabFile?: Collaborator;
  dataPersonColl: { isLoading: boolean; data: PersonSearch | undefined };

  educationLevelIdSelected: number | null;
  jointAcademicIdSelected: number | null;
}

const initialState: UsersState = {
  collabList: [],
  teachersTable: {
    page: 1,
    perPage: 5,
    search: '',
  },

  teacherCourseTable: {
    page: 1,
    perPage: 5,
    search: '',
  },

  dataPersonColl: {
    data: undefined,
    isLoading: false,
  },

  educationLevelIdSelected: null,
  jointAcademicIdSelected: null,
};

const teachersSlice = createSlice({
  name: 'teachers',
  initialState,
  reducers: {
    setCollaboratorsList: (state, action: PayloadAction<ITeacher[]>) => {
      state.collabList = action.payload;
    },

    setCollaboratorFile: (state, action: PayloadAction<Collaborator>) => {
      state.collabFile = action.payload;
    },
    setDataPersonColl: (
      state,
      action: PayloadAction<{ isLoading: boolean; data: PersonSearch | undefined }>
    ) => {
      console.log(action.payload);
      state.dataPersonColl = action.payload;
    },

    updateTeachersTableStates: (state, action: PayloadAction<ISearchTable>) => {
      state.teachersTable = action.payload;
    },
    updateTeachersCoursesTableStates: (state, action: PayloadAction<ISearchTable>) => {
      state.teacherCourseTable = action.payload;
    },

    setEducationLevelIdSelected: (state, action: PayloadAction<number | null>) => {
      state.educationLevelIdSelected = action.payload;
    },
    setJointAcademicIdSelected: (state, action: PayloadAction<number | null>) => {
      state.jointAcademicIdSelected = action.payload;
    },
  },
});

export const {
  setCollaboratorsList,
  updateTeachersTableStates,
  setCollaboratorFile,
  setDataPersonColl,
  updateTeachersCoursesTableStates,
  setEducationLevelIdSelected,
  setJointAcademicIdSelected,
} = teachersSlice.actions;

export default teachersSlice.reducer;
