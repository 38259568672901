import React from 'react';

// project imports
// import Customization from './../Customization';

//-----------------------|| MINIMAL LAYOUT ||-----------------------//

const MinimalLayout = (props: {
  children: React.ReactElement | React.ReactElement[];
}) => {
  return (
    <React.Fragment>
      {props.children}
      {/* <Customization /> */}
    </React.Fragment>
  );
};

export default MinimalLayout;
