import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISearchTable } from '../../../shared/interfaces/paginatedTable.interface';

export interface UserBorrowedBooksState {
  borrowedBooksTable: ISearchTable;

  deleteBorrowedBookDialogOpen: boolean;
  borrowedBookIdSelected: string | null;
}

const initialState: UserBorrowedBooksState = {
  borrowedBooksTable: {
    search: '',
    perPage: 5,
    page: 1,
    filterArrow: {
      name: 'id',
      direction: 'desc',
    },
  },

  deleteBorrowedBookDialogOpen: false,
  borrowedBookIdSelected: null,
};

export const userBorrowedBooksSlice = createSlice({
  initialState,
  name: 'userBorrowedBooks',
  reducers: {
    updateBorrowedBooksTable: (state, action: PayloadAction<ISearchTable>) => {
      state.borrowedBooksTable = { ...state.borrowedBooksTable, ...action.payload };
    },

    openDeleteBorrowedBookDialog: (state, action: PayloadAction<string>) => {
      state.deleteBorrowedBookDialogOpen = true;
      state.borrowedBookIdSelected = action.payload;
    },

    closeDeleteBorrowedBookDialog: (state) => {
      state.deleteBorrowedBookDialogOpen = false;
      state.borrowedBookIdSelected = null;
    },
  },
});

export const {
  updateBorrowedBooksTable,
  openDeleteBorrowedBookDialog,
  closeDeleteBorrowedBookDialog,
} = userBorrowedBooksSlice.actions;

export default userBorrowedBooksSlice.reducer;
