import { hasSubPermission } from '../../../../helpers/prmissionsHelpers';

import { useAppSelector } from '../../../../hooks/useRedux';

import { permissionKey, subPermissionKeys, SubPermissionKeys } from '../constants/permissions';

const useGuard = () => {
  const { currentModule } = useAppSelector((state) => state.access);

  const { permissions } = currentModule!;
  const hasAccess = (spKey: keyof SubPermissionKeys) =>
    hasSubPermission(permissions!, permissionKey, subPermissionKeys[spKey]);

  return {
    hasAccess,
  };
};

export default useGuard;
