import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { defaultSharedTableStates } from '../../../shared/constants/tableConstants';
import { ISearchTable } from '../../../shared/interfaces/paginatedTable.interface';
import { Report } from '../interfaces/reports.interface';

interface initialStateProps {
  reportList: Report[];
  reportsTable: ISearchTable;
}

const initialState: initialStateProps = {
  reportList: [],
  reportsTable: {
    ...defaultSharedTableStates,
  },
};

const moduleReportsSlice = createSlice({
  name: 'requestsStatus',
  initialState,
  reducers: {
    setReportList: (state, action: PayloadAction<Report[]>) => {
      state.reportList = action.payload;
    },
    updateModuleReportsTable: (state, action: PayloadAction<ISearchTable>) => {
      state.reportsTable = action.payload;
    },
  },
});

export const { setReportList, updateModuleReportsTable } = moduleReportsSlice.actions;

export default moduleReportsSlice.reducer;
