import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import MainCard from '../../../../shared/components/cards/MainCard';
import TaskDetail from '../components/TaskDetail';

const TaskScreen = () => {
  const { t } = useTranslation();

  return (
    <Grid
      component={MainCard}
      sx={{
        borderTopLeftRadius: '0px',
        borderTopRightRadius: '0px',
      }}
    >
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">{t('studentVirtualClassroom.task.task')}</Typography>
      </Grid>
      <TaskDetail />
    </Grid>
  );
};
export default TaskScreen;
