import { UsersThree, Stack, Files, Bank, User } from 'phosphor-react';
import {
  IconCashBanknote,
  IconBrowser,
  IconReportMoney,
  IconUsers,
  IconCoin,
  IconSettings,
} from '@tabler/icons';
import { shared } from '../shared';

// Icon Group
const icons = {
  UsersThree,
  Stack,
  Files,
  IconCashBanknote,
  Bank,
  User,
  IconBrowser,
  IconReportMoney,
  IconUsers,
  IconCoin,
  IconSettings,
};

export const financial = {
  id: 'financial',
  type: 'group',
  title: 'Financiero',
  children: [
    {
      id: 'administrar-caja',
      title: 'Caja',
      menuId: 'financial.cash',
      type: 'collapse',
      url: '/administrar-caja',
      permissionKey: 'economicGroup',
      icon: icons['IconCoin'],
      children: [
        {
          id: 'administrar-caja/cobrar-deudas',
          title: 'Cobrar Deudas',
          menuId: 'financial.cash.pay_debts',
          type: 'item',
          url: '/administrar-caja/cobrar-deudas',
          permissionKey: 'cashPoint',
        },
        {
          id: 'administrar-caja/generar-deudas',
          title: 'Generar Deudas',
          menuId: 'financial.cash.generate_debts',
          type: 'item',
          url: '/administrar-caja/generar-deudas',
          permissionKey: 'cashPoint',
        },
        {
          id: 'administrar-caja/generar-nota-credito',
          title: 'Generar N/Crédito',
          menuId: 'financial.cash.credit_note',
          type: 'item',
          url: '/administrar-caja/generar-nota-credito',
          permissionKey: 'cashPoint',
        },
        {
          id: 'administrar-caja/listar-cajas',
          title: 'Listar Cajas',
          menuId: 'financial.cash.list_cash',
          type: 'item',
          url: '/administrar-caja/listar-cajas',
          permissionKey: 'cashPointSup',
        },
      ],
    },
    {
      id: 'administrar-clientes',
      title: 'Clientes',
      menuId: 'financial.clients',
      type: 'collapse',
      url: '/administrar-clientes',
      icon: icons['IconUsers'],
      children: [
        {
          id: 'administrar-clientes',
          title: 'Listado',
          menuId: 'financial.clients.list',
          type: 'item',
          url: '/administrar-clientes',
          permissionKey: 'economicGroup',
        },
        {
          id: 'administrar-clientes-descuento',
          title: 'Descuento',
          menuId: 'Descuento',
          type: 'item',
          url: '/administrar-clientes-descuento',
          permissionKey: 'economicGroup',
        },
        {
          id: 'administrar-depositos-transferencias',
          title: 'Depósitos y Transferencias',
          menuId: 'financial.clients.deposit_transfers',
          type: 'item',
          url: '/administrar-depositos-transferencias',
          // icon: icons['Bank'],
          permissionKey: 'economicGroup',
        },
        {
          id: 'administrar-cheques',
          title: 'Cheques',
          menuId: 'financial.clients.cheque',
          type: 'item',
          url: '/administrar-cheques',
          // icon: icons['IconCashBanknote'],
          permissionKey: 'economicGroup',
        },
        {
          id: 'administrar-documentos-electronicos',
          title: 'Documentos Electrónicos',
          menuId: 'financial.clients.documents',
          type: 'item',
          url: '/administrar-documentos-electronicos',
          // icon: icons['IconBrowser'],
          permissionKey: 'economicGroup',
        },
        {
          id: 'administrar-recibos',
          title: 'Recibos',
          menuId: 'financial.clients.receipts',
          type: 'item',
          url: '/administrar-recibos',
          // icon: icons['IconBrowser'],
          permissionKey: 'economicGroup',
        },
      ],
    },
    {
      id: 'administrar-contifico',
      title: 'Contífico',
      menuId: 'financial.contifico',
      type: 'collapse',
      url: '/administrar-mantenimiento-producto',
      icon: icons['IconCashBanknote'],
      children: [
        {
          id: 'administrar-mantenimiento-categoria',
          title: 'Categorías',
          menuId: 'financial.contifico.categories',
          type: 'item',
          url: '/administrar-mantenimiento-categoria',
          // icon: icons['Stack'],
          permissionKey: 'economicGroup',
        },
        {
          id: 'administrar-mantenimiento-producto',
          title: 'Productos',
          menuId: 'financial.contifico.products',
          type: 'item',
          url: '/administrar-mantenimiento-producto',
          // icon: icons['Files'],
          permissionKey: 'economicGroup',
        },
        {
          id: 'administrar-migraciones',
          title: 'Migraciones',
          menuId: 'financial.contifico.migrations',
          type: 'item',
          url: '/migraciones-contifico',
          // icon: icons['Files'],
          permissionKey: 'economicGroup',
        },
      ],
    },
    {
      id: 'administrar-configuracion',
      title: 'Configuración',
      menuId: 'financial.configuration',
      type: 'collapse',
      url: '/administrar-periodos-financieros',
      icon: icons['IconSettings'],
      children: [
        {
          id: 'administrar-periodos-financieros',
          title: 'Periodos Financieros',
          menuId: 'financial.configuration.financial_periods',
          type: 'item',
          url: '/administrar-periodos-financieros',
          // icon: icons['Stack'],
          permissionKey: 'financial period',
        },
        {
          id: 'administrar-grupos-economicos',
          title: 'Grupo Económico',
          menuId: 'financial.configuration.economic_group',
          type: 'item',
          url: '/administrar-grupos-economicos',
          // icon: icons['UsersThree'],
          permissionKey: 'economicGroup',
        },
        {
          id: 'administrar-puntos-cobro/punto-emision',
          title: 'Puntos de Emisión',
          menuId: 'financial.configuration.emission_points',
          type: 'item',
          url: '/administrar-puntos-cobro/punto-emision',
          permissionKey: 'cashPoint',
        },
        {
          id: 'administrar-configuracion-producto',
          title: 'Precio Producto',
          menuId: 'financial.configuration.product_price',
          type: 'item',
          url: '/administrar-configuracion-productos',
          // icon: icons['Files'],
          permissionKey: 'economicGroup',
        },
      ],
    },

    // {
    //   id: 'administrar-puntos-cobro',
    //   title: 'Puntos de cobro',
    //   type: 'collapse',
    //   url: '/administrar-puntos-cobro',
    //   permissionKey: 'economicGroup',
    //   icon: icons['IconReportMoney'],
    //   children: [
    //     /*{
    //       id: 'establecimiento',
    //       title: 'Establecimiento',
    //       type: 'item',
    //       url: '/administrar-puntos-cobro/establecimiento',
    //       permissionKey: 'economicGroup',
    //     },*/

    //   ],
    // },

    // shared menu
    ...shared,
  ],
};
