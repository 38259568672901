import { useState } from 'react'
import {
    Table,
    TableBody,
    TableContainer,
    Grid,
    TableHead,
    IconButton,
    useTheme,
    TableCell,
    TableRow,
    Typography,
    FormHelperText,
    Checkbox,
    Tooltip,
    CircularProgress
} from "@material-ui/core"
import PerfectScrollbar from 'react-perfect-scrollbar';
import { IconArrowNarrowLeft, IconTrash, IconTrashOff } from "@tabler/icons";
import { resetCurrentColective, setOpenDialogCollCollective } from '../../slices/educationLevelsSlice'
import { dialogStyles } from '../../helpers/styles'
import { useAppDispatch, useAppSelector } from "../../../../../hooks/useRedux";
import SuccessAlert from '../../../../shared/components/alerts/SuccessAlert';
import { useUpdateEducationLevelCollectiveMutation } from '../../slices/educationLevelsApiSlice';
import ErrorAlert from '../../../../shared/components/alerts/ErrorAlert';
import ExtendedSideDialog from '../../../../shared/components/dialogs/ExtendedSideDialog';

interface Props {
    id: number;
    name: string;
    email: string;
    principal: boolean;
}

const DialogCollCollectives = function () {

    const theme = useTheme();

    const styles = dialogStyles();

    const dispatch = useAppDispatch();

    const [removeId, setRemoveId] = useState<number>(0);

    const [showAlert, setShowAlert] = useState<boolean>(false);

    const [updateCollective, { isLoading, isSuccess, isError, error }] = useUpdateEducationLevelCollectiveMutation()

    const { educationLevelCollectives,
        currentColective,
        openCollaboratorsCollective } = useAppSelector((state) => state.educationLevels);

    const collective = educationLevelCollectives.find((value) => value.id === currentColective);

    const handleClose = () => {
        dispatch(resetCurrentColective());

        dispatch(setOpenDialogCollCollective(false));
    };

    const handleDelete = async (collId: number) => {
        setRemoveId(collId);

        const collaborators = collective?.collaborators.filter((e) => e.id !== collId);
        const collaboratorsId = collaborators?.map((a) => a.id)

        await updateCollective({
            collective: currentColective!,
            payload: {
                collaborators_join_acd_pric: Number(collective?.collaborator_id!),
                status_id: 1,
                collaborators_list: collaboratorsId! || [],
                collaborators_delete: [collId],
                name: collective?.name!,
            }
        })

        setShowAlert(true);
    }

    const collPrincipal = collective && collective?.collaborator_id;

    const collaborators = collective && collective?.collaborators.map((value) => ({
        id: value.id,
        name: `${value.user.person.pers_firstname} 
        ${value.user.person.pers_first_lastname}`,
        email: value.coll_email,
        principal: value.id === Number(collPrincipal)
    }))

    const renderRowsCollaborators = () => {
        return <>{collaborators?.map(CollRenderRow)}</>;
    };

    const CollRenderRow = ({ id, name, email, principal }: Props) => {
        return (
            <TableRow hover className="table-row" key={id} selected={principal}>
                <TableCell
                    width="70%"
                    sx={{ cursor: 'pointer' }}
                >
                    {name}
                    <FormHelperText>{email}</FormHelperText>
                </TableCell>
                <TableCell width="30%"
                    align="center">
                    <Tooltip title="Colaborador">
                        <Checkbox checked={principal} disabled={!principal} />
                    </Tooltip>
                </TableCell>
                <TableCell
                    width="50%"
                    align="center"
                >
                    {isLoading && id === removeId ? (<CircularProgress size={30} />) : (
                        <Tooltip title={principal ? 'No se permite remover.' : "Remover del colectivo."}>
                            <span>
                                {isSuccess && id === removeId ? (
                                    <IconButton disabled aria-label="Eliminar colaborador del colectivo.">
                                        <IconTrashOff size={20} color={theme.palette.error.light} />
                                    </IconButton>
                                ) : (
                                    <IconButton onClick={(() => handleDelete(id))} disabled={principal} aria-label="Eliminar colaborador del colectivo.">
                                        <IconTrash size={20} color={theme.palette.error.main} />
                                    </IconButton>
                                )}
                            </span>
                        </Tooltip>
                    )}

                </TableCell>
            </TableRow >
        )
    }

    return (
        <ExtendedSideDialog
            keepMounted
            open={openCollaboratorsCollective!}
            onClose={() => handleClose()}
            className={styles.container}
            classes={{
                paper: styles.paper,
            }}
        >
            <Grid item xs={12}>
                <Grid item container alignItems="center" gap={1} sx={{ cursor: 'pointer' }}>
                    <IconButton onClick={() => handleClose()}>
                        <IconArrowNarrowLeft size={30} color={theme.palette.text.dark} />
                    </IconButton>
                    <Typography variant="h2">Regresar</Typography>
                </Grid>
            </Grid>

            {isError && showAlert && (
                <Grid item xs={12} sx={{ margin: 4 }}>
                    <ErrorAlert message={error as string} handleDismiss={() => setShowAlert(false)} />
                </Grid>
            )}

            {showAlert && isSuccess && (
                <Grid item xs={12} sx={{ margin: 4 }}>
                    <SuccessAlert
                        message="Colaborador removido del colectivo."
                        handleDismiss={() => setShowAlert(false)}
                    />
                </Grid>
            )}

            {/* Container table collaborators */}
            <Grid item xs={12} md={12} sx={{ maxHeight: 600, overflow: 'hidden', marginTop: 3, margin: 4 }}>
                <PerfectScrollbar component="div">
                    <Typography variant="h3">{collective?.name}</Typography>
                    <TableContainer>
                        <Table style={{ marginTop: 10 }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell key={'headColaborator'}>Colaborador</TableCell>
                                    <TableCell align="center" key={'headPrincipal'}>Principal</TableCell>
                                    <TableCell align="center" key={'headOptions'}>Opciones</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {collective && renderRowsCollaborators()}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </PerfectScrollbar>
            </Grid>

        </ExtendedSideDialog>
    )
}


export default DialogCollCollectives