import React, { FC, useState } from 'react';

import { useFormik } from 'formik';
import { Autocomplete, Button, FormHelperText, Grid, TextField } from '@material-ui/core';

import { useTranslation } from 'react-i18next';

import InputLabel from '../../../../shared/components/forms/InputLabel';
import { useAppSelector, useAppDispatch } from '../../../../../hooks/useRedux';
import { useGetCatalogByAcronymQuery } from '../../../../shared/slices/catalogApi';
// // import { BillingData } from '../../interfaces/paymentsHistory.interface';
// // import { useUpdateStudentBillingDataMutation } from '../../slices/paymentsApiSlice';
// import { useGetCatalogByAcronymQuery } from '../../../../shared/slices/catalogApi';
import TextFieldFetching from '../../../../shared/components/TextFieldFetching';
import { setTemporalBillingData } from '../../slices/debtsPaymentSlice';
import { TemporalBillingDataSchema } from '../../constants/validation-schemas';
import SuccessAlert from '../../../../shared/components/alerts/SuccessAlert';
// import ErrorAlert from '../../../../shared/components/alerts/ErrorAlert';
// import SuccessAlert from '../../../../shared/components/alerts/SuccessAlert';
// import { FinancialInformationSchema } from '../constants/validation-schemas';

interface Props {
  // BillingData: BillingData | undefined;
  handleCloseDialog: (e: React.SyntheticEvent) => void;
}

const EditTempBillingInfo: FC<Props> = ({ handleCloseDialog }: Props) => {
  // const {
  //   userInfo: { fullname },
  // } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const { tempBillingData, defaultTempBillingData } = useAppSelector((state) => state.debtsPayment);

  const { currentProfile } = useAppSelector((state) => state.access);

  const [showAlerts, setShowAlerts] = useState(false);

  // //identifications types
  const {
    data: identificationsAvailables = [],
    isLoading: isLoadingIA,
    isFetching: isFetchingIA,
  } = useGetCatalogByAcronymQuery({
    profileId: currentProfile?.profileId!,
    acronym: 'TI',
  });

  // const [updateFinancialInformation, { isLoading, isError, error, isSuccess }] =
  //   useUpdateStudentBillingDataMutation();

  const { errors, touched, values, handleSubmit, getFieldProps, setFieldValue, resetForm } =
    useFormik({
      initialValues: {
        identificationType:
          tempBillingData?.tipoIdentificacion || defaultTempBillingData?.tipoIdentificacion || '',
        identification:
          tempBillingData?.identificacion || defaultTempBillingData?.identificacion || '',
        telf: tempBillingData?.telefono || defaultTempBillingData?.telefono || '',
        email: tempBillingData?.email || defaultTempBillingData?.email || '',
        address: tempBillingData?.direccion || defaultTempBillingData?.direccion || '',
        socialReason: tempBillingData?.razonSocial || defaultTempBillingData?.razonSocial || '',
      },
      validationSchema: TemporalBillingDataSchema,
      // validate: ({ start_process, end_process, startDate, endDate }) => {
      //   return validateDates(start_process, end_process, startDate, endDate);
      // },
      onSubmit: async (values) => {
        try {
          dispatch(
            setTemporalBillingData({
              tipoIdentificacion: values.identificationType,
              identificacion: values.identification!,
              telefono: values.telf,
              email: values.email,
              direccion: values.address,
              razonSocial: values.socialReason,
            })
          );

          setShowAlerts(true);
          window.scrollTo(0, 0);
        } catch (error) {
          console.log(error);
        }
      },
    });

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2} sx={{ pl: { lg: 3 } }}>
        <Grid item xs={12} lg={8}>
          {showAlerts && (
            <SuccessAlert
              message={t('financial.debts_payment.temp_billing_dialog.form.success_alert')}
              handleDismiss={() => setShowAlerts(false)}
            />
          )}
        </Grid>

        {/* <Grid item xs={12} lg={8}>
          <InputLabel>Nombre y Apellido / Razón Social</InputLabel>
          <TextField fullWidth value={fullname} disabled />
        </Grid> */}

        <Grid item xs={12} lg={8}>
          <InputLabel>
            {t('financial.debts_payment.temp_billing_dialog.form.identification_type_input_label')}
          </InputLabel>
          {isFetchingIA ? (
            <TextFieldFetching />
          ) : (
            <Autocomplete
              options={identificationsAvailables}
              loading={isLoadingIA}
              loadingText={t(
                'financial.debts_payment.temp_billing_dialog.form.identification_type_placeholder'
              )}
              getOptionLabel={(option) => option.cat_name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(errors.identificationType && touched.identificationType)}
                  placeholder={t(
                    'financial.debts_payment.temp_billing_dialog.form.identification_type_placeholder'
                  )}
                />
              )}
              value={
                identificationsAvailables.find(
                  (identification) => identification.id === Number(values.identificationType)
                ) || null
                // identificationsAvailables.find(
                //   (identification) => identification.cat_keyword === values.identificationType
                // ) || null
              }
              onChange={(_, value) => {
                // setFieldValue('identificationType', value?.cat_keyword);
                setFieldValue('identificationType', value?.id);
              }}
              fullWidth
              clearIcon={null}
            />
          )}
          {errors.identificationType && touched.identificationType && (
            <FormHelperText error>{t(errors.identificationType)}</FormHelperText>
          )}
        </Grid>

        <Grid item xs={12} lg={8}>
          <InputLabel>
            {t('financial.debts_payment.temp_billing.identification_input_label')}
          </InputLabel>
          <TextField
            fullWidth
            {...getFieldProps('identification')}
            error={Boolean(errors.identification && touched.identification)}
            placeholder={t(
              'financial.debts_payment.temp_billing_dialog.form.identification_placeholder'
            )}
          />
          {errors.identification && touched.identification && (
            <FormHelperText error>{t(errors.identification)}</FormHelperText>
          )}
        </Grid>

        <Grid item xs={12} lg={8}>
          <InputLabel>{t('financial.debts_payment.temp_billing.name_input_label')}</InputLabel>
          <TextField
            fullWidth
            {...getFieldProps('socialReason')}
            error={Boolean(errors.socialReason && touched.socialReason)}
            placeholder={t('financial.debts_payment.temp_billing_dialog.form.name_placeholder')}
          />
          {errors.socialReason && touched.socialReason && (
            <FormHelperText error>{t(errors.socialReason)}</FormHelperText>
          )}
        </Grid>

        <Grid item xs={12} lg={8}>
          <InputLabel>{t('financial.debts_payment.temp_billing.phone_input_label')}</InputLabel>
          <TextField
            fullWidth
            {...getFieldProps('telf')}
            error={Boolean(errors.telf && touched.telf)}
            placeholder={t('financial.debts_payment.temp_billing_dialog.form.phone_placeholder')}
          />
          {errors.telf && touched.telf && <FormHelperText error>{t(errors.telf)}</FormHelperText>}
        </Grid>

        <Grid item xs={12} lg={8}>
          <InputLabel>{t('financial.debts_payment.temp_billing.mail_input_label')}</InputLabel>
          <TextField
            fullWidth
            {...getFieldProps('email')}
            error={Boolean(errors.email && touched.email)}
            placeholder={t('financial.debts_payment.temp_billing_dialog.form.mail_placeholder')}
          />
          {errors.email && touched.email && (
            <FormHelperText error>{t(errors.email)}</FormHelperText>
          )}
        </Grid>

        <Grid item xs={12} lg={8}>
          <InputLabel>{t('financial.debts_payment.temp_billing.address_input_label')}</InputLabel>
          <TextField
            fullWidth
            multiline
            rows={2}
            {...getFieldProps('address')}
            error={Boolean(errors.address && touched.address)}
            placeholder={t('financial.debts_payment.temp_billing_dialog.form.address_placeholder')}
          />
          {errors.address && touched.address && (
            <FormHelperText error>{t(errors.address)}</FormHelperText>
          )}
        </Grid>

        <Grid container item xs={12} lg={8} spacing={2}>
          <Grid item>
            <Button
              variant="outlined"
              size="large"
              color="primary"
              onClick={(e) => {
                resetForm();
                handleCloseDialog(e);
              }}
            >
              {t('financial.debts_payment.temp_billing_dialog.form.cancel_btn')}
            </Button>
          </Grid>
          <Grid item>
            {/* {hasAccess('create') && ( */}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              // disabled={isLoading}
              // endIcon={isLoading && <CircularProgress size={20} />}
            >
              {t('financial.debts_payment.temp_billing_dialog.form.update_btn')}
            </Button>
            {/* )} */}
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default EditTempBillingInfo;
