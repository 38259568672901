import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISearchTable } from '../../../../shared/interfaces/paginatedTable.interface';
import { x } from '../interfaces/task-list.interfaces';

interface VirtualClassroomTeacherTasksState {
  tasksList: x[];
  taskIdSelected: number | null;

  taskEditQuestionsAdded: any[];
  taskEditQuestionsRemoved: any[];
  taskEditQuestionsEdited: any[];

  activeStep: number;

  tasksTable: ISearchTable;
}

const initialState: VirtualClassroomTeacherTasksState = {
  tasksList: [],
  taskIdSelected: null,

  taskEditQuestionsAdded: [],
  taskEditQuestionsRemoved: [],
  taskEditQuestionsEdited: [],
  activeStep: 0,

  tasksTable: {
    page: 1,
    perPage: 5,
    search: '',
  },
};

const tasksTeacher = createSlice({
  name: 'books',
  initialState,
  reducers: {
    setTasksList: (state, action: PayloadAction<x[]>) => {
      state.tasksList = action.payload;
    },
    setTaskIdSelected: (state, action: PayloadAction<number | null>) => {
      state.taskIdSelected = action.payload;
    },
    setTaskEditQuestionsAdded: (state, action: PayloadAction<any[]>) => {
      state.taskEditQuestionsAdded = action.payload;
    },
    setTaskEditQuestionsRemoved: (state, action: PayloadAction<any[]>) => {
      state.taskEditQuestionsRemoved = action.payload;
    },
    setTaskEditQuestionsEdited: (state, action: PayloadAction<any[]>) => {
      state.taskEditQuestionsEdited = action.payload;
    },
    setActiveStep: (state, action: PayloadAction<number>) => {
      state.activeStep = action.payload;
    },
    setNextActiveStep: (state) => {
      state.activeStep++;
    },
    setPrevActiveStep: (state) => {
      state.activeStep--;
    },
    resetActiveStep: (state) => {
      state.activeStep = 0;
    },
    updateTasksTable: (state, action: PayloadAction<ISearchTable>) => {
      state.tasksTable = action.payload;
    },
  },
});

export const {
  setTasksList,
  setTaskIdSelected,
  setTaskEditQuestionsAdded,
  setTaskEditQuestionsRemoved,
  setTaskEditQuestionsEdited,
  setActiveStep,
  setNextActiveStep,
  setPrevActiveStep,
  resetActiveStep,
  updateTasksTable,
} = tasksTeacher.actions;

export default tasksTeacher.reducer;
