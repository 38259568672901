import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Campus } from '../interfaces/campus.interfaces';

interface CampusState {
  campusList: Campus[];
  deleteModalOpen: boolean;
  campusIdToDelete?: number;
}

const initialState: CampusState = {
  campusList: [],
  deleteModalOpen: false,
};

const campusSlice = createSlice({
  name: 'campus',
  initialState,
  reducers: {
    setCampusList: (state, action: PayloadAction<Campus[]>) => {
      state.campusList = action.payload;
    },
    setDeleteModalOpen: (state, action: PayloadAction<boolean>) => {
      state.deleteModalOpen = action.payload;
    },
    openDeleteModalWith: (state, action: PayloadAction<number>) => {
      state.campusIdToDelete = action.payload;
      state.deleteModalOpen = true;
    },
    resetCampusIdToDelete: (state) => {
      state.campusIdToDelete = undefined;
    },
  },
});

export const { setCampusList, setDeleteModalOpen, openDeleteModalWith, resetCampusIdToDelete } =
  campusSlice.actions;

export default campusSlice.reducer;
