import { CircularProgress, Grid, Typography } from '@material-ui/core';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/useRedux';
import MainCard from '../../../../../shared/components/cards/MainCard';
import BackButton from '../../../../../shared/components/buttons/BackButton';
// import EvaluationGroups from '../components/Groups';
import EvaluationOpeningDates from '../components/OpeningDates';
import { useLazyGetEvaluationQuery } from '../slices/evaluationsApiSlice';
import {
  setEvaluationsIdSelected,
  setEvaluationsList,
  setEvaluationsOpeningDateSelected,
} from '../slices/evaluationsSlice';

const EvaluationGroupsScreen = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();

  const { evaluationsIdSelected, evaluationsList } = useAppSelector((state) => state.evaluations);

  const [getEvaluation, { isLoading }] = useLazyGetEvaluationQuery();

  useEffect(() => {
    if (!evaluationsIdSelected && id) {
      dispatch(setEvaluationsIdSelected(id!));
    }

    if (!evaluationsList?.length && id) {
      getEvaluation({ id }).then((evaluation) => {
        dispatch(setEvaluationsList([evaluation.data!]));
      });
    }

    return () => {
      dispatch(setEvaluationsIdSelected(null));
      dispatch(setEvaluationsOpeningDateSelected(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid container alignItems="center" sx={{ mb: 3 }}>
        <BackButton />
        <Typography variant="h2">{t('maintenance.evaluations.groups.title')}</Typography>
      </Grid>

      {!isLoading && evaluationsIdSelected && evaluationsList?.length ? (
        <>
          <EvaluationOpeningDates />
          {/* {evaluationsOpeningDateSelected && <EvaluationGroups />} */}
        </>
      ) : (
        <CircularProgress />
      )}
    </Grid>
  );
};

export default EvaluationGroupsScreen;
