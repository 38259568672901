import {
  Autocomplete,
  CircularProgress,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from '@material-ui/core';
import { format } from 'date-fns';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../../../hooks/useRedux';
import DeleteButton from '../../../../../../shared/components/tables/buttons/DeleteButton';
import LinkButton from '../../../../../../shared/components/tables/buttons/LinkButton';
import { locales } from '../../../../../../shared/constants/locales';
import OpeningDateDeleteDialog from '../../../opening_dates/components/OpeningDateDeleteDialog';
import OpeningDateSideDialog from '../../../opening_dates/components/OpeningDateSideDialog';
import { useGetAllOpeningDatesByEvaluationQuery } from '../../../opening_dates/slices/openingDateApiSlice';
import {
  setOpeningDatesDeleteModalOpen,
  setOpeningDatesIdToDelete,
  setOpeningDatesSideDialogOpen,
} from '../../../opening_dates/slices/openingDateSlice';

import { setEvaluationsOpeningDateSelected } from '../../slices/evaluationsSlice';
import EvaluationInfoBox from '../EvaluationInfoBox';

import noDataImage from '../../../../../../../assets/images/evaluation_success.png';

const EvaluationOpeningDates = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { evaluationsList, evaluationsIdSelected, evaluationsOpeningDateSelected } = useAppSelector(
    (state) => state.evaluations
  );
  const { openingDatesSideDialogOpen, openingDatesDeleteModalOpen } = useAppSelector(
    (state) => state.openingDates
  );

  const evaluation = evaluationsList.find((_ev) => _ev.uuid === evaluationsIdSelected);

  const { data: openingDates = [], isLoading: isLoadingOD } =
    useGetAllOpeningDatesByEvaluationQuery(
      {
        evaluationId: evaluation!.uuid!,
      },
      {
        skip: !evaluation?.uuid,
      }
    );

  const handleAddNewOpeningDate = () => {
    // TODO: Add new opening date
    dispatch(setOpeningDatesSideDialogOpen(true));
  };

  const handleDeleteOpeningDate = () => {
    dispatch(setOpeningDatesIdToDelete(evaluationsOpeningDateSelected));
    dispatch(setOpeningDatesDeleteModalOpen(true));
  };

  const handleSelectOpeningDate = (id: string | null) => {
    dispatch(setEvaluationsOpeningDateSelected(id));
  };

  return (
    <Grid container mt={2} justifyContent="center">
      {evaluation ? (
        <>
          <EvaluationInfoBox evaluation={evaluation} />

          <Grid item container xs={8} mt={2} alignItems="center" justifyContent="space-between">
            <Grid item>
              <InputLabel>{t('maintenance.evaluations.opening_dates')}</InputLabel>
            </Grid>
            <Grid item>
              {evaluationsOpeningDateSelected && (
                <DeleteButton handleDelete={handleDeleteOpeningDate} />
              )}
              <LinkButton onClick={handleAddNewOpeningDate} />
            </Grid>
          </Grid>
          <Grid item container xs={8}>
            <Autocomplete
              options={openingDates}
              getOptionLabel={(option) =>
                `${format(new Date(option.start_date), 'PPP', {
                  locale: locales[i18next.language],
                })} - ${format(new Date(option.end_date), 'PPP', {
                  locale: locales[i18next.language],
                })}`
              }
              noOptionsText={t('maintenance.evaluations.opening_dates.no_data')}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={t('maintenance.evaluations.opening_dates.placeholder')}
                />
              )}
              value={openingDates.find((dt) => dt.uuid === evaluationsOpeningDateSelected) || null}
              loading={isLoadingOD}
              loadingText={t('maintenance.groups.opening_dates')}
              fullWidth
              onChange={(event, value) => {
                if (value) {
                  handleSelectOpeningDate(value.uuid!);
                } else {
                  handleSelectOpeningDate(null);
                }
              }}
            />
          </Grid>

          {!evaluationsOpeningDateSelected && (
            <Grid item container xs={8} mt={2} alignItems="center" justifyContent="center">
              <Grid item>
                <img src={noDataImage} alt="" />
              </Grid>
              <Grid item>
                <Typography variant="h2" color="primary">
                  {t('maintenance.evaluations.opening_dates.no_selected')}
                </Typography>
              </Grid>
            </Grid>
          )}

          {openingDatesSideDialogOpen && <OpeningDateSideDialog />}
          {openingDatesDeleteModalOpen && <OpeningDateDeleteDialog />}
        </>
      ) : (
        <Grid container justifyContent="center">
          <CircularProgress />
        </Grid>
      )}
    </Grid>
  );
};

export default EvaluationOpeningDates;
