import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import MainCard from '../../../../shared/components/cards/MainCard';
import AttendanceDetail from '../components/AttendanceDetail';

const AttendanceScreen = () => {
  const { t } = useTranslation();
  return (
    <>
      <Grid
        component={MainCard}
        sx={{
          borderTopLeftRadius: '0px',
          borderTopRightRadius: '0px',
        }}
      >
        <Grid sx={{ mb: 3 }}>
          <Typography variant="h2">{t('studentVirtualClassroom.attendance.title')}</Typography>
        </Grid>
        <Grid
          sx={{
            minHeight: '350px',
          }}
        >
          <AttendanceDetail />
        </Grid>
      </Grid>
    </>
  );
};

export default AttendanceScreen;
