import { useHistory, Redirect } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux';
import { Module } from '../../../types';
import { checkModuleQuantity } from '../../auth/helpers';
import LogoutButton from '../components/buttons/LogoutButton';
import LogoERP from '../components/LogoERP';
import ModuleCard from '../components/cards/ModuleCard';

// import modulesImg from '../../../assets/images/modules/academico.png';

const ModulesScreen = () => {
  // const ROL_DEFAULT = 'ESTUDIANTE'
  const theme = useTheme();

  const { t } = useTranslation();

  const history = useHistory();

  const dispatch = useAppDispatch();

  const { currentRol } = useAppSelector((state) => state.access);

  const currentModule = localStorage.getItem('currentModule');

  const handleSetModule = async (module: { [key: string]: Module }) => {
    const [defaultModule] = Object.keys(module);
    const externalUrl = module[defaultModule].url;

    if (externalUrl) {
      window.open(externalUrl, '__blank');
    } else {
      /**
       * Valida que se envie un solo modulo para asignarlo al
       * currentModule en el store.
       */
      if (!Object.keys(module).find((key) => key === currentModule)) {
        checkModuleQuantity(dispatch, module);
      }

      history.push('/dashboard');
    }
  };

  //If student contain one record student, by default redirect dashboard.
  // if (currentRol?.name === ROL_DEFAULT && studentRecordReference) {
  //   const { id, name, offerId, educationLevelId } = studentRecordReference![0];
  //   localStorage.setItem(
  //     'currentStudentRecord',
  //     JSON.stringify({ id, name, offerId, educationLevelId })
  //   );
  // return <Redirect to="/dashboard"/>
  // debugger
  // }

  /**
   * Si solo existe un modulo
   */
  if (currentRol && currentRol?.modules && Object.keys(currentRol?.modules).length === 1) {
    // handleSetModule(currentRol!.modules);
    checkModuleQuantity(dispatch, currentRol.modules);
    return <Redirect to="/dashboard" />;
  }

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          // height: '10vh',
          py: 3,
          // px: 2,
          backgroundColor: theme.palette.primary[800],
        }}
      >
        <Grid item xs={10}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item>
              <LogoERP formal />
            </Grid>
            <Grid item>
              <LogoutButton />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container sx={{ minHeight: '90vh' }} justifyContent="center" alignItems="center">
        <Grid item xs={10}>
          <Grid item xs={12} sx={{ my: 3 }}>
            <Typography variant="h2" color="primary" textAlign="center">
              {t('shared.modules.title')}
            </Typography>
          </Grid>

          <Grid item xs={12} container spacing={3} sx={{ pb: 2 }}>
            {Object.keys(currentRol!.modules)
              // validacion para que solo se muestre el modulo academico para el rol estudiante
              ?.filter((key) => {
                const roleKeyword = currentRol?.keyword?.toLowerCase();
                if (roleKeyword !== 'estudiante') return true;

                return key === 'academic';
              })
              // fin de la validacion
              .map((key: string) => {
                const module: { [key: string]: Module } = { [`${key}`]: currentRol!.modules[key] };
                let imgTest: string;
                try {
                  // imgTest = require(`../../../assets/images/modules/${key}.png`).default;
                  imgTest = require(`../../../assets/images/modules/${key}-icon.png`).default;
                } catch {
                  imgTest =
                    require('../../../assets/images_optimized/cards/card-2_optimized.png').default;
                }

                return (
                  <Grid key={key} item xs={12} md={6} lg={4} padding={1}>
                    <ModuleCard
                      image={imgTest}
                      moduleKeyword={key}
                      onClick={() => handleSetModule(module)}
                      moduleName={module[`${key}`].name}
                    />
                  </Grid>
                );
              })}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ModulesScreen;
