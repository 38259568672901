import {
  IconUser,
  IconEditCircle,
  IconCalendarEvent,
  IconBriefcase,
  IconBook,
  IconNotes,
  IconCheckupList,
  IconFolders,
  IconHome,
  IconFlag,
  IconUsers,
  IconMessages,
  IconArrowBack,
  IconBoxMultiple,
  IconClipboardList,
  IconStar,
  IconNews,
  IconBrandZoom,
} from '@tabler/icons';

const icons = {
  IconUsers,
  IconEditCircle,
  IconCalendarEvent,
  IconBriefcase,
  IconBook,
  IconCheckupList,
  IconNotes,
  IconHome,
  IconFolders,
  IconUser,
  IconFlag,
  IconMessages,
  IconArrowBack,
  IconBoxMultiple,
  IconClipboardList,
  IconStar,
  IconNews,
  IconBrandZoom,
};

export const virtualClassroomTeacher = {
  id: 'virtual-classroom-teachers',
  type: 'group',
  title: 'Aula Virtual Docente',
  children: [
    {
      id: 'administrar-aula-virtual/contenido',
      title: 'Inicio',
      menuId: 'teacherVirtualClassroom.menu.home',
      type: 'item',
      url: '/administrar-aula-virtual/contenido',
      permissionKey: 'collaborator',
      vcType: ['native', 'moodle', 'blackboard'],
      icon: icons['IconHome'],
    },
    {
      id: 'administrar-aula-virtual/asistencia',
      title: 'Asistencia',
      menuId: 'teacherVirtualClassroom.menu.attendance',
      type: 'item',
      url: '/administrar-aula-virtual/asistencia',
      permissionKey: 'collaborator',
      vcType: ['native', 'moodle', 'blackboard'],
      icon: icons['IconCheckupList'],
    },
    {
      id: 'administrar-aula-virtual/materiales',
      title: 'Materiales',
      menuId: 'teacherVirtualClassroom.menu.materials',
      type: 'item',
      url: '/administrar-aula-virtual/materiales',
      permissionKey: 'collaborator',
      vcType: ['native', 'moodle', 'blackboard'],
      icon: icons['IconBoxMultiple'],
    },
    {
      id: 'actividades',
      title: 'Actividades',
      menuId: 'teacherVirtualClassroom.menu.activities',
      type: 'collapse',
      permissionKey: 'collaborator',
      icon: icons['IconClipboardList'],
      vcType: ['native'],
      children: [
        {
          id: 'administrar-aula-virtual/actividades',
          title: 'Tareas',
          menuId: 'teacherVirtualClassroom.menu.task',
          type: 'item',
          url: '/administrar-aula-virtual/actividades',
          permissionKey: 'collaborator',
          vcType: ['native'],
        },
        {
          id: 'administrar-aula-virtual/test',
          title: 'Evaluaciones',
          menuId: 'teacherVirtualClassroom.menu.test',
          type: 'item',
          url: '/administrar-aula-virtual/test',
          vcType: ['native'],
          permissionKey: 'collaborator',
        },
        {
          id: 'administrar-aula-virtual/sala-debate',
          title: 'Foro',
          menuId: 'teacherVirtualClassroom.menu.forum',
          type: 'item',
          url: '/administrar-aula-virtual/sala-debate',
          vcType: ['native'],
          permissionKey: 'collaborator',
        },
      ],
    },
    {
      id: 'administrar-aula-virtual/notas',
      title: 'Calificaciones',
      menuId: 'teacherVirtualClassroom.menu.qualifications',
      type: 'item',
      url: '/administrar-aula-virtual/notas/calificar',
      permissionKey: 'collaborator',
      vcType: ['native', 'moodle', 'blackboard'],
      icon: icons['IconStar'],
    },
    {
      id: 'administrar-aula-virtual/anuncios',
      title: 'Anuncios',
      menuId: 'teacherVirtualClassroom.menu.calendar',
      type: 'item',
      url: '/administrar-aula-virtual/anuncios',
      permissionKey: 'collaborator',
      vcType: ['native', 'moodle', 'blackboard'],
      icon: icons['IconNews'],
    },
    {
      id: 'administrar-aula-virtual/estudiantes',
      title: 'Estudiantes',
      menuId: 'teacherVirtualClassroom.menu.students',
      type: 'item',
      url: '/administrar-aula-virtual/estudiantes',
      permissionKey: 'collaborator',
      vcType: ['native', 'moodle', 'blackboard'],
      icon: icons['IconUsers'],
    },
    {
      id: 'administrar-aula-virtual/reuniones',
      title: 'Reuniones virtuales',
      menuId: 'teacherVirtualClassroom.menu.meetings',
      type: 'item',
      url: '/administrar-aula-virtual/reuniones',
      permissionKey: 'collaborator',
      vcType: ['native'],
      icon: icons['IconBrandZoom'],
    },

    {
      id: 'vc-salir',
      title: 'Salir de aula virtual',
      menuId: 'teacherVirtualClassroom.menu.exit',
      type: 'item',
      url: '/inicio-docente',
      permissionKey: 'collaborator',
      vcType: ['native', 'moodle', 'blackboard'],
      icon: icons['IconArrowBack'],
    },
  ],
};
