import { Button, FormHelperText, Grid } from '@material-ui/core';
import { ArrowBack, ArrowForward } from '@material-ui/icons';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import useGetUrlDownload from '../../../../../hooks/useGetUrlDownload';
import { RegisterSyllabusData } from '../../../interfaces/registerSyllabusData';
import { useUploadFilesMutation } from '../../../slices/uploadFilesApi';
import DragArea from '../../files/DragArea';

const LessonPlan = () => {
  const [file, setFile] = useState<File | null>(null);

  const { path } = useRouteMatch();

  const { id, courseId, period } = useParams<{ id?: string; courseId?: string; period?: string }>();

  const { urlDownload } = useGetUrlDownload();

  const history = useHistory();

  const { setFieldValue, values, errors } = useFormikContext<RegisterSyllabusData>();

  const [uploadFile, { isLoading: isUploading }] = useUploadFilesMutation();

  const handleBack = () => {
    history.push(
      path
        .replace(':caseKey', 'contenido-general')
        .replace(':id', id ? id : '')
        .replace(':courseId', courseId ? courseId : '')
        .replace(':period', period ? period : '')
    );
  };

  const handleSubmit = () => {
    history.push(
      path
        .replace(':caseKey', 'bibliografia')
        .replace(':id', id ? id : '')
        .replace(':courseId', courseId ? courseId : '')
        .replace(':period', period ? period : '')
    );
  };

  useEffect(() => {
    if (file) {
      const execute = async () => {
        try {
          const response = await uploadFile({
            files: file,
          }).unwrap();
          setFieldValue('syllabus_entries.lesson_plan', response);
          setFieldValue('temp.fileLessonPlan', file);
        } catch (error) {
          console.log(error);
        }
      };
      execute();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  return (
    <Grid container direction="column" gap={2}>
      <Grid item xs={12} md={6}>
        <Grid container direction="column" spacing={2}>
          <Grid item xs={12}>
            <DragArea
              handleChange={(e) => setFile(e.target.files![0])}
              label={'Plan de clase'}
              typesAccepted="application/pdf"
              documentExist={
                values.syllabus_entries.lesson_plan
                  ? {
                      name: 'Plan_de_clases.pdf',
                      url: `${urlDownload}/${values.syllabus_entries.lesson_plan.toString()}`,
                    }
                  : null
              }
              url={
                values.syllabus_entries.lesson_plan
                  ? `${urlDownload}/${values.syllabus_entries.lesson_plan.toString()}`
                  : ''
              }
            />
          </Grid>
        </Grid>

        {errors.syllabus_entries?.lesson_plan && (
          <FormHelperText sx={{ mt: 4 }} error>
            {errors.syllabus_entries?.lesson_plan}
          </FormHelperText>
        )}

        <Grid
          item
          container
          xs={8}
          lg={5}
          mx="auto"
          alignItems="center"
          justifyContent="center"
          spacing={2}
          sx={{ mt: 6 }}
        >
          <Grid item>
            <Button
              fullWidth
              variant="outlined"
              size="large"
              color="primary"
              onClick={handleBack}
              disabled={isUploading}
            >
              <ArrowBack />
            </Button>
          </Grid>
          <Grid item>
            <Button
              fullWidth
              variant="outlined"
              size="large"
              color="primary"
              disabled={isUploading}
              onClick={handleSubmit}
              // disabled={isLoading}
              // endIcon={isLoading && <CircularProgress size={20} />}
            >
              <ArrowForward />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LessonPlan;
