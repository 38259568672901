import { Grid, Typography } from '@material-ui/core';
import MainCard from '../../../shared/components/cards/MainCard';
import MealTicket from '../components/MealTicket';

const MealTicketScreen = () => {
  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid item sx={{ mb: 3 }} container justifyContent="space-between" alignItems="center">
        <Typography variant="h2">Ticket de comida</Typography>
      </Grid>
      <Grid item container>
        <MealTicket />
      </Grid>
    </Grid>
  );
};

export default MealTicketScreen;
