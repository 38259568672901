import { IconButton, TextField, TextFieldProps } from '@material-ui/core';
import { DatePicker, DatePickerProps, LocalizationProvider } from '@material-ui/lab';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import { IconX } from '@tabler/icons';
import { JSXElementConstructor, ReactElement } from 'react';
import { useGetLocaleDateLang } from '../../../../translation/hooks/useGetLocaleDateLang';

export interface LocalizedDatePickerProps extends Omit<DatePickerProps, 'renderInput'> {
  error?: boolean;
  placeholder?: string;
  helperText?: string;
  renderInput?: (props: TextFieldProps) => ReactElement<any, string | JSXElementConstructor<any>>;
  clearIcon?: boolean | (() => ReactElement);
}

/**
 * @param props - props pertenecientes a MUI DatePicker
 * @param props.renderInput - input personalizado (si no se pasa se usa el input por defecto)
 * @param props.placeholder - placeholder del input (si no se pasa se usa el placeholder por defecto)
 * @param props.helperText - helperText del input (si no se pasa se usa el helperText por defecto)
 * @param props.error - error del input
 * @param props.value - valor del input
 * @param props.onChange - onChange del input
 * @param props.clearIcon - icono para limpiar el input (default: false). recibe un bool (por defecti IconX) o function => element
 * @returns
 * DatePicker con localización de idioma y formato de fecha
 * @example
 * Componente simple de ejemplo:
 * <LocalizedDatePicker
 *    value={myValue}
 *    onChange={handleChange}
 * />
 * Componente con input personalizado:
 * <LocalizedDatePicker
 *    value={myValue}
 *    onChange={handleChange}
 *    renderInput={(params) => (
 *      <TextField
 *        {...params}
 *        error={params.error || error}
 *        placeholder={placeholder || 'dd/mm/a'}
 *        helperText={helperText || null}
 *        fullWidth
 *        inputProps={{
 *          ...params.inputProps,
 *          placeholder: placeholder || 'dd/mm/a',
 *        }}
 *      />
 *    )}
 * />
 * @see https://material-ui.com/es/components/date-picker/
 * @see https://material-ui.com/es/components/text-fields/
 * @see https://material-ui.com/es/components/pickers/
 * @see https://material-ui.com/es/guides/localization/
 * @see https://material-ui.com/es/guides/localization/#date-fns
 */
const LocalizedDatePicker = ({ clearIcon = false, ...props }: LocalizedDatePickerProps) => {
  const { lang } = useGetLocaleDateLang();

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={lang}>
      <DatePicker
        {...props}
        {...(props.renderInput
          ? { renderInput: props.renderInput }
          : {
              renderInput: (params) => (
                <TextField
                  {...params}
                  error={params.error || props.error}
                  placeholder={props.placeholder || 'dd/mm/a'}
                  helperText={props.helperText || null}
                  fullWidth
                  inputProps={{
                    ...params.inputProps,
                    placeholder: props.placeholder || 'dd/mm/a',
                  }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {!!clearIcon && props.value && (
                          <IconButton size="small" onClick={() => props.onChange(null)}>
                            {typeof clearIcon === 'boolean' ? <IconX /> : clearIcon()}
                          </IconButton>
                        )}
                        {params.InputProps?.endAdornment}
                      </>
                    ),
                  }}
                />
              ),
            })}
      />
    </LocalizationProvider>
  );
};

export default LocalizedDatePicker;
