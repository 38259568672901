import { Grid, IconButton, Typography } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useAppSelector } from '../../../../hooks/useRedux';
import MainCard from '../../../shared/components/cards/MainCard';
import PosgraduatePlanningHourhandsForm from '../components/PosgraduatePlanningHourhandsForm';
import { useLazyGetPosgraduatePlanningHourhandQuery } from '../slices/posgraduatePlanningHourhandsApiSlice';
import { setPosgraduatePlanningHourhandsList } from '../slices/posgraduatePlanningHourhandsSlice';

const EditPosgraduatePlanningHourhandsScreen = () => {
  const history = useHistory();

  const { currentProfile } = useAppSelector((state) => state.access);
  const { id } = useParams<{ id: string }>();

  const { posgraduatePlanningHourhandsList, posgraduatePlanningHourhandIdSelected } =
    useAppSelector((state) => state.posgraduatePlanningHourhands);

  const [getPosgraduatePlanningHourhand, { isLoading }] =
    useLazyGetPosgraduatePlanningHourhandQuery();

  useEffect(() => {
    if (posgraduatePlanningHourhandsList.length === 0) {
      getPosgraduatePlanningHourhand({
        profileId: currentProfile?.profileId!,
        id: posgraduatePlanningHourhandIdSelected || id,
      }).then((res) => {
        setPosgraduatePlanningHourhandsList([res!]);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid component={MainCard} sx={{ p: 2 }}>
      <Grid container alignItems="center" sx={{ mb: 3 }}>
        <IconButton
          onClick={() => {
            history.goBack();
          }}
        >
          <ArrowBack />
        </IconButton>
        <Typography variant="h2">Editar horarios de posgrado</Typography>
      </Grid>
      {!isLoading && posgraduatePlanningHourhandsList.length > 0 && (
        <PosgraduatePlanningHourhandsForm />
      )}
    </Grid>
  );
};

export default EditPosgraduatePlanningHourhandsScreen;
