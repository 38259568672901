import { useState } from 'react';

export const useModalUserConfig = () => {
  const [open, setOpen] = useState(false);

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  return { open, handleOpenModal, handleCloseModal };
};
