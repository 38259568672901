import { Switch } from 'react-router-dom';
import GuardRoute from '../../../../router/guards/GuardRoute';
import DashboardScreen from '../screens/DashboardScreen';

const DashboardRouter = () => {
  return (
    <Switch>
      {/* Inicio de docente */}
      <GuardRoute exact path="/inicio-docente" component={DashboardScreen} />
    </Switch>
  );
};

export default DashboardRouter;
