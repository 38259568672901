import { ButtonProps, IconButton, Tooltip } from '@material-ui/core';
import { IconUsers } from '@tabler/icons';

interface Props extends ButtonProps { }

const ListCollaboratorsCollective = ({ onClick, ...props }: Props) => {
    return (
        <Tooltip title="Listar colaboradres">
            <IconButton size="small" aria-label="Listar colectivos" color="default" onClick={onClick}>
                <IconUsers />
            </IconButton>
        </Tooltip>
    );
};

export default ListCollaboratorsCollective;
