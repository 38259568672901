import { mainApi } from '../../../../api/mainApi';
import { AccessState } from '../../../../types';
import { middlewareApi } from '../../../middleware/middlewareApi';
import { Parallel, ParallelsResponse } from '../interfaces/parallels.interface';
import { setParallelList } from './parallesSlice';

export const parallelsTags = middlewareApi.enhanceEndpoints({
  addTagTypes: ['Parallels', 'ParallelsAvailables'],
});
// Define a service using a base URL and expected endpoints
export const parallelsApi = parallelsTags.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * @GET all parallels
     */
    getParallels: builder.query<
      ParallelsResponse,
      {
        profileId: number;
        page: number;
        perPage: number;
        search: string;
        sort?: string;
        type_sort?: string;
      }
    >({
      queryFn: async ({ profileId, page, search, perPage, type_sort, sort }, { dispatch }) => {
        try {
          const { data } = await mainApi.get<ParallelsResponse>('/parallels', {
            params: {
              user_profile_id: profileId,
              page,
              size: perPage,
              type_sort: type_sort || 'desc',
              ...(sort && { sort }),
              search,
            },
          });

          dispatch(setParallelList(data.data));

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'Parallels', id } as const)),
              { type: 'Parallels', id: 'LIST' },
            ]
          : [{ type: 'Parallels', id: 'LIST' }],
    }),

    /**
     * @GET available parallels
     */
    getAvailablesParallels: builder.query<
      Parallel[],
      { profileId?: number; sort?: string; type_sort?: string; search?: string }
    >({
      queryFn: async ({ profileId, sort, type_sort, search }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = profileId || currentProfile?.profileId;

          const { data } = await mainApi.get<Parallel[]>('/parallels', {
            params: {
              user_profile_id,
              search,
              type_sort: type_sort || 'desc',
              ...(sort && { sort }),
              data: 'all',
            },
          });

          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result, error, { profileId }) => [
        { type: 'ParallelsAvailables', id: 'PARALLELS_CMBO_LIST' },
      ],
    }),

    /**
     * @GET parallel by id
     */
    getParallel: builder.query<Parallel, { profileId: number; parallelId: number }>({
      queryFn: async ({ profileId, parallelId }, { dispatch }) => {
        try {
          const { data } = await mainApi.get<Parallel>('/parallels', {
            params: {
              user_profile_id: profileId,
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result, error, { profileId }) => [{ type: 'Parallels', profileId }],
    }),

    /**
     * @POST create parallel
     */
    addParallel: builder.mutation<
      Parallel,
      { profileId: number; parallelPayload: Partial<Parallel> }
    >({
      queryFn: async ({ profileId, parallelPayload }) => {
        try {
          const { data } = await mainApi.post<Parallel>('/parallels', {
            user_profile_id: profileId,
            ...parallelPayload,
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [
        { type: 'Parallels', id: 'LIST' },
        { type: 'ParallelsAvailables', id: 'PARALLELS_CMBO_LIST' },
      ],
    }),

    /**
     * @PUT update parallel
     */
    updateParallel: builder.mutation<
      Parallel,
      { profileId: number; parallelId: number; parallelPayload: Partial<Parallel> }
    >({
      queryFn: async ({ profileId, parallelId, parallelPayload }) => {
        try {
          const { data } = await mainApi.put<Parallel>(`/parallels/${parallelId}`, {
            user_profile_id: profileId,
            ...parallelPayload,
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [
        { type: 'Parallels', id: 'LIST' },
        { type: 'ParallelsAvailables', id: 'PARALLELS_CMBO_LIST' },
      ],
    }),

    /**
     * @DELETE remove parallel
     */
    deleteParallel: builder.mutation<Parallel, { profileId: number; parallelId: number }>({
      queryFn: async ({ profileId, parallelId }) => {
        try {
          const { data } = await mainApi.delete(`parallels/${parallelId}`, {
            data: {
              user_profile_id: profileId,
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [
        { type: 'Parallels', id: 'LIST' },
        { type: 'ParallelsAvailables', id: 'PARALLELS_CMBO_LIST' },
      ],
    }),
  }),
});

export const {
  useGetParallelsQuery,
  useAddParallelMutation,
  useDeleteParallelMutation,
  useGetParallelQuery,
  useUpdateParallelMutation,
  useGetAvailablesParallelsQuery,
  useLazyGetAvailablesParallelsQuery,
  useLazyGetParallelsQuery,
} = parallelsApi;
