import { useEffect, useState, useMemo } from 'react';

import { Link, useRouteMatch } from 'react-router-dom';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Skeleton,
  TextField,
  Tooltip,
  useTheme,
} from '@material-ui/core';
import { format } from 'date-fns'; //
import esLocale from 'date-fns/locale/es';
import { IconClipboardList, IconEye } from '@tabler/icons';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import DateRangePicker, { DateRange } from '@material-ui/lab/DateRangePicker';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';

import SearchPaginatedTable, {
  FilterArrow,
} from '../../../shared/components/tables/SearchPaginatedTable';
import { useAppSelector } from '../../../../hooks/useRedux';
import { useGetApplicationsHomologationsQuery } from '../slices/homologationsApiSlice';
import Chip from '../../../shared/components/extended/Chip';
import InputLabel from '../../../shared/components/forms/InputLabel';
// import { StudentRecord } from '../interfaces/homologations.interface';
import CustomPopper from '../../../shared/components/popper/CustomPopper';
import { InternalHomologation } from '../interfaces/homologations.interface';
import { useTranslation } from 'react-i18next';
import useSharedGuard from '../../../../hooks/useSharedGuard';
import { subPermissionKeys, subPermissionKeys2 } from '../constants/permissions';

const InternalHomologationsTable = () => {
  const { t } = useTranslation();

  const theme = useTheme();
  const { path } = useRouteMatch();

  const { currentProfile, currentModule } = useAppSelector((state) => state.access);

  const hasPermissionIntApp = 'internalHomologation' in currentModule?.permissions!;

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [perPage, setPerPage] = useState(5);
  const [value, setValue] = useState<DateRange<Date>>([null, null]);
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();
  const [filterStatus, setFilterStatus] = useState<string | undefined | null>(null);
  const [status, setStatus] = useState<string>('');

  const [filterArrow, setFilterArrow] = useState<FilterArrow>(null!);

  const { hasAccess } = useSharedGuard(subPermissionKeys);
  const { hasAccess: hasAccess2 } = useSharedGuard(subPermissionKeys2);

  const { data, isLoading, isFetching, error } = useGetApplicationsHomologationsQuery(
    {
      profileId: currentProfile?.profileId!,
      acronym: 'CAMCAR',
      page,
      perPage,
      search,
      ...(filterArrow && { sort: filterArrow.name, type_sort: filterArrow.direction }),
      ...(startDate && endDate && { startDate: startDate }),
      ...(endDate && startDate && { endDate: endDate }),
      ...(status && { status }),
    },
    { refetchOnMountOrArgChange: 30 }
  );

  const dataMemorized = useMemo(() => {
    return data?.data as InternalHomologation[];
  }, [data?.data]);

  const statusMemorized = useMemo(() => {
    if (Array.isArray(dataMemorized)) {
      let statusDict: { [key: string]: number } = {};
      for (let obj of dataMemorized) {
        if (obj.current_status in statusDict) {
          ++statusDict[obj.current_status];
        } else {
          statusDict[obj.current_status] = 1;
        }
      }
      return statusDict;
    } else {
      return {};
    }
  }, [dataMemorized]);

  useEffect(() => {
    setPage(1);
  }, [perPage, search]);

  useEffect(() => {
    let timer = setTimeout(() => {
      // value[0] && !isNaN(value[0].getTime()) && setStartDate(format(value[0], 'yyyy-MM-dd'));
      // value[1] && !isNaN(value[1].getTime()) && setEndDate(format(value[1], 'yyyy-MM-dd'));
    }, 700);

    return () => {
      clearTimeout(timer);
    };
  }, [value]);

  const handleFilter = () => {
    value[0] && !isNaN(value[0].getTime()) && setStartDate(format(value[0], 'yyyy-MM-dd'));
    value[1] && !isNaN(value[1].getTime()) && setEndDate(format(value[1], 'yyyy-MM-dd'));
    if (filterStatus) setStatus(filterStatus);
  };
  const handleClearFilter = () => {
    setValue([null, null]);
    setStartDate('');
    setEndDate('');

    setFilterStatus(null);
    setStatus('');
  };

  //controlar acceso a propiedades en caso de no haber solicitudes
  const datafetch = data?.data!.map((value, index) => {
    // const studentRecord = Boolean(value.applicationDetails.length)
    //   ? (value.applicationDetails[0].value as StudentRecord)
    //   : undefined;
    return {
      id: index + ((page - 1) * perPage + 1),
      // stud_code: Boolean(studentRecord) ? studentRecord!.student.stud_code : '00000000000',
      // student: Boolean(studentRecord)
      //   ? `${studentRecord!.student.user.person.pers_firstname} ${
      //       studentRecord!.student.user.person.pers_first_lastname
      //     }`
      //   : 'Nombre del Estudiante',
      app_code: value.app_code,
      student: value.user,
      // campus: value.campus.cam_name,
      // detail: {
      //   per_max_matter_enrollment: value?.per_max_matter_enrollment,
      //   per_min_matter_enrollment: value?.per_min_matter_enrollment,
      //   per_num_fees: value?.per_num_fees,
      //   per_pay_enrollment: value?.per_pay_enrollment,
      // },
      status: value.current_status,
      options: value,
    };
  });

  const onChangeDates = (newDatesValues: DateRange<Date>) => {
    // const [start, end] = newDatesValues;
    setValue(newDatesValues);
  };

  //filter

  const [anchorElClassStatus, setAnchorElClassStatus] = useState<null | HTMLElement>(null);

  const openClassStatus = Boolean(anchorElClassStatus);

  const handleClickFilterClassStatus = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElClassStatus(event.currentTarget);
  };

  const handleCloseFilterClassStatus = (filterStatus: string | undefined | null) => {
    setAnchorElClassStatus(null);
    setFilterStatus(filterStatus);
  };

  return (
    <SearchPaginatedTable
      data={datafetch!}
      headers={{
        id: 'N°',
        // stud_code: 'Código Estudiante',
        app_code: t('academic.homologation.internal.code'),
        student: t('academic.homologation.internal.student'),
        // detail: 'Detalle',
        status: t('academic.homologation.internal.status'),
        options: t('academic.homologation.internal.options'),
      }}
      keyExtractor={(item) => item.options.id.toString()}
      listFilterArrows={{
        app_code: 'app_code',
        student: 'p.pers_full_name',
        status: 'status.st_name',
      }}
      filterArrow={filterArrow}
      setFilterArrow={setFilterArrow}
      searchPlacehoder={t('academic.homologation.internal.search')}
      isLoading={isLoading}
      isFetching={isFetching}
      error={error}
      perPage={perPage}
      setPerPage={setPerPage}
      total={data?.total || 0}
      page={page}
      setPage={setPage}
      numHeader={5}
      search={search}
      setSearch={setSearch}
      customRenderers={{
        status: (row) => <Chip label={row.status} size="small" chipcolor={'success'} />,
        // detail: ({ detail }) => (
        //   <Grid container item direction="column" gap={1}>
        //     <Typography>Máximo materias: {detail.per_max_matter_enrollment}</Typography>
        //     <Typography>Mínimo materias: {detail.per_min_matter_enrollment}</Typography>
        //     <Typography>Cuota/matrícula : {detail.per_pay_enrollment}</Typography>
        //     <Typography>Número cuotas : {detail.per_num_fees}</Typography>
        //   </Grid>
        // ),
        //
        options: (row) => (
          <>
            {
              // currentRol?.keyword === 'coordinador-facultad' &&
              row.status.toLowerCase() === 'aprobado' &&
                hasPermissionIntApp &&
                hasAccess2('readMatters') && (
                  <Link to={`${path}/homologacion-i/${row.options.id}`}>
                    <Tooltip title={t('academic.homologation.internal.homologate')}>
                      <IconButton
                        aria-label={t('academic.homologation.internal.homologate')}
                        color="default"
                      >
                        <IconClipboardList size={20} />
                      </IconButton>
                    </Tooltip>
                  </Link>
                )
            }
            {hasAccess('read') && ( // hasAccess('editIntApp') && (
              <Link to={`${path}/${row.options.id}/editar-hi`}>
                <Tooltip title={t('academic.homologation.internal.view_app')}>
                  <IconButton
                    aria-label={t('academic.homologation.internal.view_app')}
                    color="secondary"
                  >
                    <IconEye size={20} />
                  </IconButton>
                </Tooltip>
              </Link>
              // <Link to={`${path}/${row.id}/editar-hi`}>
              //   <EditButton title="Ver Solicitud" />
              // </Link>
            )}
            {/* {hasAccess('editIntApp') && (
                  <DeleteButton handleDelete={() => handleDelete(row.id)} />
                )} */}
            {!hasAccess('read') &&
              !hasPermissionIntApp &&
              !hasAccess2('readMatters') &&
              t('academic.homologation.internal.none')}
          </>
        ),
      }}
      customHeadersCellsProperties={{
        // id: { hidden: true },
        options: {
          align: 'center',
        },
        // stud_code: {
        //   align: 'center',
        // },
      }}
      customDataCellsProperties={{
        // id: { hidden: true },
        // stud_code: {
        //   align: 'center',
        // },
        id: { align: 'center' },
        status: { align: 'center' },
        options: {
          align: 'center',
        },
      }}
      // ActionButtons={<>{hasAccess('create') && <AddButton size="large" onClick={goToAdd} />}</>}
      ActionButtons={
        <>
          <CustomPopper onClickAwayNotClose>
            <Grid
              container
              p={1}
              pt={2}
              sx={{ maxWidth: { xs: '65vw', sm: '50vw', md: '40vw', lg: '25vw' } }}
            >
              <Grid item xs={12}>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                  <DateRangePicker
                    calendars={1}
                    startText={null}
                    endText={null}
                    value={value}
                    onChange={onChangeDates}
                    PopperProps={{ style: { ...{ left: '-400px' } } }}
                    renderInput={(startProps, endProps) => (
                      <Grid container spacing={1} alignItems="center">
                        <Grid item xs={12}>
                          <Grid item xs={12} sx={{ pt: { xs: 2, sm: '0 !important' } }}>
                            <InputLabel
                              // className={classes.inputLabel}
                              sx={{ textAlign: { xs: 'left', sm: 'right' } }}
                            >
                              {t('academic.homologation.internal.start_date')}
                            </InputLabel>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              {...startProps}
                              helperText=""
                              value={value[0]}
                              // error={Boolean(errors.startDate && touched.startDate)}
                              // {...getFieldProps('startDate')}
                            />

                            {/* {errors.startDate && touched.startDate && (
                          <FormHelperText error>{errors.startDate}</FormHelperText>
                        )} */}
                          </Grid>
                        </Grid>

                        <Grid item xs={12}>
                          <Grid item xs={12} sx={{ pt: { xs: 2, sm: '0 !important' } }}>
                            <InputLabel
                              // className={classes.inputLabel}
                              sx={{ textAlign: { xs: 'left', sm: 'right' } }}
                            >
                              {t('academic.homologation.internal.end_date')}
                            </InputLabel>
                          </Grid>

                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              {...endProps}
                              helperText=""
                              value={value[1]}
                              // error={Boolean(errors.endDate && touched.endDate)}
                              // {...getFieldProps('endDate')}
                            />
                            {/* <FormHelperText>En esta fecha la etapa finalizará</FormHelperText>
                        {errors.endDate && touched.endDate && (
                          <FormHelperText error>{errors.endDate}</FormHelperText>
                        )} */}
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid container justifyContent="flex-end" mt={1}>
                <Grid item xs={12}>
                  <InputLabel
                    // className={classes.inputLabel}
                    sx={{ textAlign: { xs: 'left', sm: 'right' } }}
                  >
                    {t('academic.homologation.internal.app_status')}
                  </InputLabel>
                </Grid>
                {(isFetching || !Boolean(Object.entries(statusMemorized).length)) && (
                  <Skeleton variant="rectangular" />
                )}
                {!isFetching && Boolean(typeof statusMemorized === 'object') && (
                  <Grid item xs="auto">
                    <Box
                      component={Button}
                      size="large"
                      sx={{
                        color: theme.palette.grey[900],
                        backgroundColor: theme.palette.primary.light,
                        ':hover': { bgcolor: theme.palette.primary.light },
                        px: 2,
                        borderRadius: 4,
                        mx: 1,
                      }}
                      endIcon={<ExpandMoreRoundedIcon />}
                      onClick={handleClickFilterClassStatus}
                    >
                      {filterStatus && Boolean(dataMemorized.length)
                        ? dataMemorized.find((dt) => dt.current_status === filterStatus)!
                            .current_status
                        : 'General'}
                    </Box>
                    <Menu
                      anchorEl={anchorElClassStatus}
                      keepMounted
                      open={openClassStatus}
                      onClose={() => handleCloseFilterClassStatus(filterStatus?.toString())}
                      variant="selectedMenu"
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                    >
                      <MenuItem onClick={() => handleCloseFilterClassStatus(null)}>
                        General
                      </MenuItem>
                      {Object.keys(statusMemorized).map((key, index) => {
                        return (
                          <MenuItem
                            key={`search-by-type-${key}`}
                            onClick={() => handleCloseFilterClassStatus(`${key}`)}
                          >
                            {key}
                          </MenuItem>
                        );
                      })}
                      {/* {classroomTypes.map((classroomType) => {
                        return (
                          <MenuItem
                            key={`search-by-type-${classroomType.id}`}
                            onClick={() => handleCloseFilterClassStatus(`${classroomType.id}`)}
                          >
                            {classroomType.clt_name}
                          </MenuItem>
                        );
                      })} */}
                    </Menu>
                  </Grid>
                )}
              </Grid>

              <Grid container mt={1} justifyContent={'flex-end'} spacing={1}>
                <Grid item xs={'auto'}>
                  <Button variant={'outlined'} onClick={handleClearFilter}>
                    {t('academic.homologation.internal.reset_filter')}
                  </Button>
                </Grid>
                <Grid item xs={'auto'}>
                  <Button variant={'outlined'} onClick={handleFilter}>
                    {t('academic.homologation.internal.filter')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </CustomPopper>

          {/* <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                  <DateRangePicker
                    startText={null}
                    endText={null}
                    value={value}
                    onChange={onChangeDates}
                    renderInput={(startProps, endProps) => (
                      <Grid container spacing={1} alignItems="center">
                        <Grid item xs={12}>
                          <Grid item xs={12} sx={{ pt: { xs: 2, sm: '0 !important' } }}>
                            <InputLabel
                              // className={classes.inputLabel}
                              sx={{ textAlign: { xs: 'left', sm: 'right' } }}
                            >
                              Fecha de Inicio
                            </InputLabel>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              {...startProps}
                              helperText=""
                              value={value[0]}
                              // error={Boolean(errors.startDate && touched.startDate)}
                              // {...getFieldProps('startDate')}
                            />
                          </Grid>
                        </Grid>

                        <Grid item xs={12}>
                          <Grid item xs={12} sx={{ pt: { xs: 2, sm: '0 !important' } }}>
                            <InputLabel
                              // className={classes.inputLabel}
                              sx={{ textAlign: { xs: 'left', sm: 'right' } }}
                            >
                              Fecha de Fin
                            </InputLabel>
                          </Grid>

                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              {...endProps}
                              helperText=""
                              value={value[1]}
                              // error={Boolean(errors.endDate && touched.endDate)}
                              // {...getFieldProps('endDate')}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  />
                </LocalizationProvider> */}
        </>
      }
    />
  );
};

export default InternalHomologationsTable;
