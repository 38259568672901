import React, { useEffect, useState } from 'react';

// material-ui
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Avatar, CircularProgress, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

// project imports
import MainCard from '../../../shared/components/cards/MainCard';
import SkeletonEarningCard from '../../../shared/components/cards/Skeleton/EarningCard';
// assets
import Noloadedavatar from './../../../../../src/assets/images/users/noloadedavatar.png';
import { useAppSelector } from '../../../../hooks/useRedux';

import { obtainDomainStorageImage } from '../../../shared/helpers';

// style constant
const useStyles = makeStyles((theme) => ({
  card: {
    color: '#fff',
    overflow: 'hidden',
    position: 'relative',
  },
  content: {
    padding: '0px !important',
    marginLeft: '10px !important',
  },
  avatar: {
    ...theme.typography.commonAvatar,
    ...theme.typography.largeAvatar,
    margin: '8px',
    height: 'auto',
    width: 'auto',
    backgroundColor: 'transparent',
  },
  avatarRight: {
    ...theme.typography.commonAvatar,
    ...theme.typography.mediumAvatar,
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.secondary[200],
    zIndex: 1,
  },
  cardHeading: {
    fontSize: '1.1rem',
    fontWeight: 500,
    marginRight: '8px',
    marginTop: '14px',
    marginBottom: '6px',
    color: theme.palette.primary.main,
  },
  cardHeadingLite: {
    fontSize: '1.1rem',
    fontWeight: 300,
    marginRight: '8px',
    marginTop: '5px',
    marginBottom: '5px',
    color: theme.palette.primary.main,
  },
  subHeading: {
    fontSize: '0.9rem',
    fontWeight: 300,
    color: theme.palette.secondary.dark,
  },
  avatarCircle: {
    cursor: 'pointer',
    ...theme.typography.smallAvatar,
    backgroundColor: theme.palette.secondary[200],
    color: theme.palette.secondary.dark,
  },
  circleIcon: {
    transform: 'rotate3d(1, 1, 1, 45deg)',
  },
  menuItem: {
    marginRight: '14px',
    fontSize: '1.25rem',
  },
}));

//===========================|| DASHBOARD DEFAULT - EARNING CARD ||===========================//

export interface EarningCardProps {
  isLoading: boolean;
}

const ProfileTeacherCard = ({ isLoading }: EarningCardProps) => {
  const { t } = useTranslation();

  const classes = useStyles();
  const theme = useTheme();

  const [photo, setPhoto] = useState(Noloadedavatar);

  // const [anchorEl, setAnchorEl] = React.useState<
  //   Element | ((element: Element) => Element) | null | undefined
  // >(null);
  // Web/IMAGEN_FORO/1643211913_61f16c896203c.png

  const { userInfo } = useAppSelector((state) => state.auth);
  const { currentProfile } = useAppSelector((state) => state.access);
  const { language } = useAppSelector((state) => state.customization);

  // const { data: dataRedlinks, isLoading: isLoadingRDL } = useGetRedlinksAvatarQueryQuery({
  //   identification: userInfo?.identification,
  // });

  // useEffect(() => {
  //   if (currentProfile?.imgProfile) setPhoto(`${baseUrl}/show?name=${currentProfile?.imgProfile}`);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [currentProfile?.imgProfile]);

  const handleResetPhoto = () => {
    setPhoto(Noloadedavatar);
  };

  const capitalize = (text: string) => {
    var finalText = '';
    const textArray = text.split(' ');
    textArray.forEach((eText: string) => {
      const lower = eText.toLowerCase();
      const initial = eText.charAt(0).toUpperCase();
      finalText += ' ' + initial + lower.slice(1);
    });
    return finalText;
  };
  console.log(capitalize);

  useEffect(() => {
    if (currentProfile?.imgProfile) {
      setPhoto(obtainDomainStorageImage(currentProfile?.imgProfile || ''));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProfile?.imgProfile]);

  return (
    <React.Fragment>
      {isLoading ? (
        <SkeletonEarningCard />
      ) : (
        <MainCard
          border={false}
          className={classes.card}
          contentClass={classes.content}
          sx={{
            backgroundColor: { sm: 'none', md: theme.palette.primary.main },
            borderRadius: '1rem',
          }}
        >
          <Grid container direction="column">
            <Grid
              container
              alignItems="center"
              sx={{
                backgroundColor: 'white',
                borderTopLeftRadius: '100px',
                borderBottomLeftRadius: '100px',
                paddingY: '32px',
              }}
            >
              <Grid item xs={12} sm={6} md={3} lg={2} xl={1}>
                {
                  /* isLoadingRDL */ !photo ? (
                    <Avatar>
                      <CircularProgress />
                    </Avatar>
                  ) : (
                    <Avatar
                      sx={{
                        width: '110px',
                        height: '110px',
                        backgroundColor: '#ccc',
                        margin: '0 auto',
                      }}
                    >
                      <img
                        src={photo}
                        onError={(e) => handleResetPhoto()}
                        width="100%"
                        height="100%"
                        alt={userInfo?.fullname ? userInfo.fullname : 'Docente'}
                      />
                    </Avatar>
                  )
                }
              </Grid>

              <Grid item>
                <Typography className={classes.cardHeading}>
                  {language === 'es'
                    ? `Bienvenid${userInfo?.gender === 'Femenino' ? 'a' : 'o'} `
                    : t('teachers.dashboard.profile.welcome')}{' '}
                  {userInfo?.fullname}
                </Typography>
                <Typography className={classes.cardHeadingLite}>{userInfo?.email}</Typography>
                {/* <Typography className={classes.cardHeadingLite}>
                    código: {userInfo?.studentInfo?.studentRecordId}
                  </Typography> */}

                <Typography className={classes.subHeading}>
                  {t('teachers.dashboard.profile.teacher')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </MainCard>
      )}
    </React.Fragment>
  );
};

export default ProfileTeacherCard;
