import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';

import DeleteAlert from '../../../../shared/components/dialogs/DeleteAlert';
import { useDeleteForumMutation } from '../../slices/virtualClassroomTeacherApiSlice';
import { resetForumIdToDelete, setDeleteModalOpen } from '../../slices/virtualClassroomTeacherSlice';

const DeleteForumDialog = () => {
  const dispatch = useAppDispatch();

  const { deleteModalOpen, forumIdToDelete } = useAppSelector(
    (state) => state.virtualClassroomTeacher
  );

  const { course: {courseId}} = useAppSelector((state)=>state.virtualClassroomTeacherShared);

  const { currentProfile } = useAppSelector((state) => state.access);

  const [deleteForum, { isLoading, isSuccess, isError, error }] = useDeleteForumMutation();

  const handleDelete = () => {
    deleteForum({
      profileId: currentProfile?.profileId!,
      forumId: forumIdToDelete!,
      courseId: courseId!,
    });
  };

  const handleClose = () => {
    dispatch(setDeleteModalOpen(false));
    dispatch(resetForumIdToDelete());
  };

  return (
    <DeleteAlert
      deleteTitle="Estás seguro de eliminar este foro"
      deleteSubTitle="Eliminaremos el foro de forma permanente, ¿Estás seguro de realizar esta acción?"
      open={deleteModalOpen}
      onClose={handleClose}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      handleDelete={handleDelete}
      error={error as string}
    />
  );
};

export default DeleteForumDialog;
