import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/useRedux';
import CustomSideDialog from '../../../../../shared/components/dialogs/CustomSideDialog';
import {
  setOptionsTemplateIdSelected,
  setOptionsTemplateSideDialogOpen,
} from '../slices/optionsTemplateSlice';
import OptionsTemplateForm from './OptionsTemplateForm';

const OptionsTemplateSideDialog = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { optionsTemplateIdSelected } = useAppSelector((state) => state.optionsTemplate);

  const handleClose = () => {
    dispatch(setOptionsTemplateSideDialogOpen(false));
    dispatch(setOptionsTemplateIdSelected(null));
  };

  return (
    <CustomSideDialog
      open
      handleCloseDialog={handleClose}
      title={
        optionsTemplateIdSelected
          ? t('maintenance.options_template.edit.title')
          : t('maintenance.options_template.add.title')
      }
    >
      <OptionsTemplateForm />
    </CustomSideDialog>
  );
};

export default OptionsTemplateSideDialog;
