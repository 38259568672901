import { hasSubPermission } from '../helpers/prmissionsHelpers';
import { sharedPermissions } from '../modules/shared/constants/sharedPermissions';
import { PermissionsKeys } from '../modules/shared/interfaces/permissions.interface';
import { useAppSelector } from './useRedux';

type HasAccesI<T, K> = (
  spKey?: (T extends T[] ? undefined : K | K[]) | undefined,
  testForceBool?: boolean
) => boolean;
type HasAtLeastOneI<K> = (spKey?: K[] | undefined, testForceBool?: boolean) => boolean;
type HasSharedPermissionsI = (
  spKey?: keyof typeof sharedPermissions | (keyof typeof sharedPermissions)[] | string | string[],
  type?: 'all' | 'one',
  testForceBool?: boolean
) => boolean | undefined;

interface ReturnedInterface<T, K> {
  hasAccess: HasAccesI<T, K>;
  hasAtLeastOne: HasAtLeastOneI<K>;
  hasSharedPermissions: HasSharedPermissionsI;
}
interface ReturnedInterfaceUndefined {
  hasSharedPermissions: HasSharedPermissionsI;
}

// ? Por que no usar un solo fdichero compartido de permisos, para no tener que duplicar permisos en cada mantenimiento (para los casos
// ?  que las pantallas usan permisos de otros mantenimientos

const useSharedGuard: {
  (subPermissionKeys?: undefined): ReturnedInterfaceUndefined; // Sobrecarga sin parámetros
  <T extends PermissionsKeys, K extends keyof Omit<T, 'groupKey'>>(
    subPermissionKeys: T | T[]
  ): ReturnedInterface<T, K>; // Sobrecarga con parámetro
} = <T extends PermissionsKeys, K extends keyof Omit<T, 'groupKey'>>(
  subPermissionKeys: any
): any => {
  const { currentModule } = useAppSelector((state) => state.access);

  const { permissions } = currentModule!;

  /**
   * !Si esta en true, se ignoran los permisos y se retorna true (o false si se pasa el parametro donde se invoque la validacion)
   */
  const test = true;

  const validateArray = (spKey: K[] | undefined, subPermissionKeys: T, type: 'all' | 'one') => {
    const { groupKey, ...rest } = subPermissionKeys;

    const allValues = Object.values(rest);

    if (type === 'all') {
      return (!spKey ? allValues : spKey).every((itemKey) =>
        hasSubPermission(permissions!, groupKey, subPermissionKeys[itemKey])
      );
    }

    return (!spKey ? allValues : spKey).some((itemKey) =>
      hasSubPermission(permissions!, groupKey, subPermissionKeys[itemKey])
    );
  };

  const hasAccess: HasAccesI<T, K> = (
    spKey?: T extends T[] ? undefined : K | K[],
    testForceBool: boolean = true
  ) => {
    if (test) return testForceBool;

    if (Array.isArray(subPermissionKeys)) {
      return subPermissionKeys.every((permissions) => validateArray(undefined, permissions, 'all'));
    }

    if (Array.isArray(spKey) || spKey === undefined) {
      return validateArray(spKey, subPermissionKeys, 'all');
    }

    return hasSubPermission(
      permissions!,
      subPermissionKeys.groupKey,
      subPermissionKeys[spKey as K]
    );
  };

  const hasAtLeastOne: HasAtLeastOneI<K> = (spKey?: K[], testForceBool: boolean = true) => {
    if (test) return testForceBool;

    if (Array.isArray(subPermissionKeys)) {
      return subPermissionKeys.some((permissions) => validateArray(undefined, permissions, 'one'));
    }

    if (!spKey) {
      return validateArray(undefined, subPermissionKeys, 'one');
    }

    return validateArray(spKey, subPermissionKeys, 'one');
  };

  const hasSharedPermissions: HasSharedPermissionsI = (
    spKey?: keyof typeof sharedPermissions | (keyof typeof sharedPermissions)[] | string | string[],
    type?: 'all' | 'one',
    testForceBool: boolean = true
  ) => {
    if (test) return testForceBool;

    if (Array.isArray(spKey)) {
      if (type === 'all')
        return spKey.every((item) => currentModule?.permissionsArr?.some((sp) => sp.key === item));

      return spKey.some((item) => currentModule?.permissionsArr?.some((sp) => sp.key === item));
    }

    return currentModule?.permissionsArr?.some((sp) => sp.key === spKey);
  };

  if (subPermissionKeys === undefined) return { hasSharedPermissions };

  return {
    hasAccess,
    hasAtLeastOne,
    hasSharedPermissions,
  };
};

export default useSharedGuard;
