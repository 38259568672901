import React, { useEffect, useState } from 'react';

// material-ui
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Box, Drawer, useMediaQuery, Grid, Typography, Divider } from '@material-ui/core';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';
import { BrowserView, MobileView } from 'react-device-detect';

// project imports
import MenuList from './MenuList';
import LogoSection from '../LogoSection';
import { drawerWidth } from '../../../constants/uiConstants';
import { FaqsClient } from '../../../../maintenance/faqs/component/buttons/FaqsClient';
import { useAppSelector } from '../../../../../hooks/useRedux';
import SocialNetworks from '../../../components/social-networks/SocialNetworks';

// style constant
const useStyles = makeStyles((theme) => ({
  drawer: {
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
    background: theme.palette.background.paper,
    color: theme.palette.text.primary,
    borderRight: 'none',
    [theme.breakpoints.up('md')]: {
      top: '88px',
    },
  },
  ScrollHeight: {
    height: 'calc(100vh - 88px)',
    paddingLeft: '16px',
    paddingRight: '16px',
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 56px)',
    },
  },
  ScrollHeightVirtualClassroom: {
    height: 'calc(100vh - 200px)',
    paddingLeft: '16px',
    paddingRight: '16px',
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 56px)',
    },
  },
  boxContainer: {
    display: 'flex',
    padding: '16px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

//-----------------------|| SIDEBAR DRAWER ||-----------------------//

export interface SidebarProps {
  drawerOpen?: boolean;
  drawerToggle?: () => void;
  window?: Window;
}

const Sidebar = ({ drawerOpen, drawerToggle, window }: SidebarProps) => {
  const classes = useStyles();

  const { companySocialNetwork } = useAppSelector((state) => state.access);

  const [isViewSocialNetworks, setIsViewSocialNetworks] = useState(false);

  const theme = useTheme();

  const { currentVirtualClassroom } = useAppSelector((state) => state.access);
  const { opened } = useAppSelector((state) => state.customization);
  const { course } = useAppSelector((state) => state.virtualClassroomStudentShared);
  const { course: courseTeachear } = useAppSelector((state) => state.virtualClassroomTeacherShared);

  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

  const drawer = (
    <React.Fragment>
      <Box sx={{ display: { xs: 'block', md: 'none' } }}>
        <div className={classes.boxContainer}>
          <LogoSection />
        </div>
      </Box>
      <BrowserView>
        {currentVirtualClassroom && (course.courseName || courseTeachear.courseName) && (
          <Grid container justifyContent="center" alignItems="center" marginTop={5}>
            <Grid
              item
              xs={9}
              sx={{ borderBottom: `2px solid ${theme.palette.secondary.dark}` }}
              paddingBottom={2}
              marginBottom={2}
            >
              <Typography
                variant="h5"
                color={theme.palette.primary.main}
                align="center"
                sx={{
                  // height: '60px',
                  overflow: 'hidden',
                  wordWrap: 'break-word',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical',
                  // height: '2.5em',
                }}
              >
                {course.courseName || courseTeachear.courseName}
              </Typography>
            </Grid>
          </Grid>
        )}
        {isViewSocialNetworks ? (
          <Box
            sx={{
              height: 'calc(100vh - 88px)',
            }}
          >
            <Box
              sx={{
                height: '90%',
                display: 'flex',
              }}
            >
              <PerfectScrollbar component="div" style={{ flex: 1 }}>
                <MenuList />
              </PerfectScrollbar>
            </Box>
            <Box
              sx={{
                height: '10%',
              }}
            >
              <Divider />

              <SocialNetworks />
            </Box>
          </Box>
        ) : (
          <PerfectScrollbar
            component="div"
            className={
              course.courseName || courseTeachear.courseName
                ? classes.ScrollHeightVirtualClassroom
                : classes.ScrollHeight
            }
          >
            <MenuList />
          </PerfectScrollbar>
        )}
      </BrowserView>
      <MobileView>
        <Box sx={{ px: 2 }}>
          <MenuList />
        </Box>
      </MobileView>
      {opened && <FaqsClient />}
    </React.Fragment>
  );

  const container = window !== undefined ? () => window.document.body : undefined;

  useEffect(() => {
    if (
      !companySocialNetwork?.co_facebook &&
      !companySocialNetwork?.co_instagram &&
      !companySocialNetwork?.co_linkedin &&
      !companySocialNetwork?.co_tiktok &&
      !companySocialNetwork?.co_youtube
    ) {
      setIsViewSocialNetworks(false);
    } else {
      setIsViewSocialNetworks(true);
    }
  }, [companySocialNetwork]);

  return (
    <nav className={classes.drawer} aria-label="mailbox folders">
      <Drawer
        container={container}
        variant={matchUpMd ? 'persistent' : 'temporary'}
        anchor="left"
        open={drawerOpen}
        onClose={drawerToggle}
        classes={{
          paper: classes.drawerPaper,
        }}
        ModalProps={{ keepMounted: true }}
        color="inherit"
      >
        {drawer}
      </Drawer>
    </nav>
  );
};

export default Sidebar;
