import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Course {
  courseParalel: string;
  courseName: string;
  courseHourhandDescription: string;
  courseId: number;
  MatterId: number;
  vcType?: string;
}

interface state {
  course: Course;
  isFinishedCourse: boolean;
}

const initialCourse = {
  courseHourhandDescription: '',
  courseId: 0,
  courseName: '',
  courseParalel: '',
  MatterId: 0,
};

const initialState: state = {
  course: initialCourse,
  isFinishedCourse: false,
};

const virtualClassroomTeacherShared = createSlice({
  name: 'virtualClassroomTeacherSharedSlice',
  initialState,
  reducers: {
    setCurrentCourse: (state, action: PayloadAction<Course>) => {
      state.course = action.payload;
    },
    resetCurrentCourse: (state) => {
      state.course = initialCourse;
    },
    setIsFinishedCourse: (state, action: PayloadAction<boolean>) => {
      state.isFinishedCourse = action.payload;
    },
  },
});

export const { setCurrentCourse, resetCurrentCourse, setIsFinishedCourse } =
  virtualClassroomTeacherShared.actions;

export default virtualClassroomTeacherShared.reducer;
