import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../../../hooks/useRedux';
import MainCard from '../../../../shared/components/cards/MainCard';
import TypographyFetching from '../../../../shared/components/TypographyFetching';
import { useGetCatalogByAcronymQuery } from '../../../../shared/slices/catalogApi';
// import { useGetAvailablesProvincesQuery } from '../../../institutes/slices/institutesApiSlice';

const ContactData = () => {
  const { t } = useTranslation();
  // const { collabFile } = useAppSelector((state) => state.teacher);

  const { user } = useAppSelector((state) => state.teacher.collabFile!);

  // countries
  const { data: countriesAvailables = [], isFetching: isFetchingCA } = useGetCatalogByAcronymQuery({
    acronym: 'PS',
  });

  // nacionalities
  const { data: nacionalities = [], isFetching: isFetchingNA } = useGetCatalogByAcronymQuery({
    acronym: 'NA',
  });

  // //provincia
  // const { data: provincies } = useGetAvailablesProvincesQuery({});

  //sectors
  const { data: sectorsAvailables, isFetching: isFetchingSA } = useGetCatalogByAcronymQuery({
    acronym: 'S',
  });

  //households
  const { data: householdsAvailables = [], isFetching: isFetchingHA } = useGetCatalogByAcronymQuery(
    {
      acronym: 'VV',
    }
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <MainCard title={t('academic.teachers_list.file.contact.title')}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography variant="h4" component="span">
                {t('academic.teachers_list.file.contact.country')}
              </Typography>
              <TypographyFetching isLoading={isFetchingCA} variant="body2" component="span">
                {countriesAvailables.find((dt) => dt.id === Number(user.person.country_id))
                  ?.cat_name || null}
              </TypographyFetching>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant="h4" component="span">
                {t('academic.teachers_list.file.contact.nationality')}
              </Typography>
              <TypographyFetching isLoading={isFetchingNA} variant="body2" component="span">
                {nacionalities.find((dt) => dt.id === Number(user.person.nationality_id))
                  ?.cat_name || null}
              </TypographyFetching>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant="h4" component="span">
                {t('academic.teachers_list.file.contact.sector')}
              </Typography>
              <TypographyFetching isLoading={isFetchingSA} variant="body2" component="span">
                {sectorsAvailables?.find((dt) => dt.cat_keyword === user.person.sector_id)
                  ?.cat_name || null}
              </TypographyFetching>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h4" component="span">
                {t('academic.teachers_list.file.contact.address')}
              </Typography>
              <Typography variant="body2" component="span">
                {user.person.pers_direction}
              </Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant="h4" component="span">
                {t('academic.teachers_list.file.contact.phone')}
              </Typography>
              <Typography variant="body2" component="span">
                {user.person.pers_phone_home}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h4" component="span">
                {t('academic.teachers_list.file.contact.cellphone')}
              </Typography>
              <Typography variant="body2" component="span">
                {user.person.pers_cell}
              </Typography>
            </Grid>
          </Grid>
        </MainCard>
      </Grid>

      <Grid item xs={12} md={6}>
        <MainCard title={t('academic.teachers_list.file.household.title')}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography variant="h4" component="span">
                {t('academic.teachers_list.file.household.housing')}
              </Typography>
              <TypographyFetching isLoading={isFetchingHA} variant="body2" component="span">
                {householdsAvailables?.find((dt) => dt.cat_keyword === user.person.vivienda_id)
                  ?.cat_name || null}
              </TypographyFetching>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant="h4" component="span">
                {t('academic.teachers_list.file.household.bedroom_num')}
              </Typography>
              <Typography variant="body2" component="span">
                {user.person.pers_num_bedrooms}
              </Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant="h4" component="span">
                {t('academic.teachers_list.file.household.home_payers')}
              </Typography>
              <Typography variant="body2" component="span">
                {user.person.pers_num_taxpayers_household}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h4" component="span">
                {t('academic.teachers_list.file.household.num_childs')}
              </Typography>
              <Typography variant="body2" component="span">
                {user.person.pers_num_child}
              </Typography>
            </Grid>
          </Grid>
        </MainCard>
      </Grid>
    </Grid>
  );
};

export default ContactData;
