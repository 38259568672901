import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
// import { useAppSelector } from '../../../../hooks/useRedux';
import MainCard from '../../../shared/components/cards/MainCard';

// import DeletePeriodDialog from '../components/DeletePeriodDialog';
import InternalHomologationsTable from '../components/InternalHomologationsTable';
import ExternalHomologationsTable from '../components/ExternalHomologationsTable';
import { useAppSelector } from '../../../../hooks/useRedux';

import { setViewMode } from '../slices/homologationsSlice';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

const HomologationsScreen = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  // const { periodIdToDelete } = useAppSelector((state) => state.periods);
  const { viewMode } = useAppSelector((state) => state.homologations);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setViewMode((event.target as HTMLInputElement).value as 'internal' | 'external'));
  };

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">
          {viewMode === 'internal'
            ? t('academic.homologation.internal.title')
            : t('academic.homologation.external.title')}
        </Typography>
      </Grid>

      <Grid sx={{ textAlign: 'right' }}>
        <FormControl component="fieldset">
          {/* <FormLabel component="legend">Homlogación</FormLabel> */}
          <RadioGroup
            row
            aria-label="homologaciones"
            name="controlled-radio-buttons-group"
            value={viewMode}
            onChange={handleChange}
          >
            <FormControlLabel
              value="internal"
              control={<Radio />}
              label={t('academic.homologation.internal')}
            />
            <FormControlLabel
              value="external"
              control={<Radio />}
              label={t('academic.homologation.external')}
            />
          </RadioGroup>
        </FormControl>
      </Grid>

      {viewMode === 'internal' && <InternalHomologationsTable />}
      {viewMode === 'external' && <ExternalHomologationsTable />}

      {/* {periodIdToDelete && <DeletePeriodDialog />} */}
    </Grid>
  );
};

export default HomologationsScreen;
