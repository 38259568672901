import { Switch, Redirect } from 'react-router-dom';

import { useAppSelector } from '../../../../hooks/useRedux';

import UsersScreen from '../screens/UsersScreen';
import UserProfilesScreen from '../screens/UserProfilesScreen';
import UserProfileRolesScreen from '../screens/UserProfileRolesScreen';

// import EditRoleScreen from '../screens/EditRoleScreen';
// import AddRoleScreen from '../screens/AddRoleScreen';
import GuardRoute from '../../../../router/guards/GuardRoute';

import { permissionKey } from '../constants/permissions';

function RolesRouter() {
  // Verify permission
  const { currentModule } = useAppSelector((state) => state.access);
  const hasPermission = permissionKey in currentModule?.permissions!;

  if (!hasPermission) return <Redirect to="/" />;

  return (
    <Switch>
      <GuardRoute
        exact
        path="/administrar-usuarios"
        component={UsersScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={usersSubPermissionKeys.read}
      />
      <GuardRoute
        exact
        path="/administrar-usuarios/usuario/:userId/perfiles"
        component={UserProfilesScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={usersSubPermissionKeys.read}
      />
      <GuardRoute
        exact
        path="/administrar-usuarios/usuario/:userId/perfiles/:profileId/roles"
        component={UserProfileRolesScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={usersSubPermissionKeys.read}
      />

      {/* <GuardRoute
        exact
        path={`/administrar-roles/agregar`}
        component={AddRoleScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.create}
      />

      <GuardRoute
        exact
        path={`/administrar-roles/:roleId/editar`}
        component={EditRoleScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.edit}
      /> */}
    </Switch>
  );
}

export default RolesRouter;
