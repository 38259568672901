import { Box, Grid, IconButton, Tooltip, Typography } from '@material-ui/core';
import { IconLayoutGrid, IconList } from '@tabler/icons';
import { useState } from 'react';
import { useAppSelector } from '../../../../hooks/useRedux';
import MainCard from '../../../shared/components/cards/MainCard';
import DeleteHoursSummariesDialog from '../components/DeleteHoursSummariesDialog';
import TeachersViews from '../components/TeachersViews';

const TeachersScreen = () => {
  const [viewMode, setViewMode] = useState<'table' | 'grid'>('table');
  const { teacherIdToDelete } = useAppSelector((state) => state.hoursTeachers);

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid item sx={{ mb: 3 }} container justifyContent="space-between" alignItems="center">
        <Typography variant="h2">Asignar horas a docencia</Typography>

        {/* Switch view */}
        <Box>
          <Tooltip title="Vista de tabla">
            <IconButton onClick={() => setViewMode('table')}>
              <IconList size={20} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Vista de tarjetas">
            <IconButton onClick={() => setViewMode('grid')}>
              <IconLayoutGrid size={20} />
            </IconButton>
          </Tooltip>
        </Box>
      </Grid>

      <TeachersViews viewMode={viewMode} />

      {teacherIdToDelete && <DeleteHoursSummariesDialog />}
    </Grid>
  );
};

export default TeachersScreen;
