export const getAppCode = (appCode: string) => {
  if (!appCode) return undefined;

  return appCode.split('-')[0];
};

export const convertTime12to24 = (time12h: string) => {
  const [time, modifier] = time12h.split(' ');

  let [hours, minutes] = time.split(':');

  if (hours === '12' || hours === 'NaN') {
    hours = '00';
  }

  if (minutes === 'NaN') {
    minutes = '00';
  }

  if (modifier === 'pm' || modifier === 'PM') {
    hours = `${parseInt(hours, 10) + 12}`;
  }

  return `${hours}:${minutes}`;
};
