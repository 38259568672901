import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISearchTable } from '../../../shared/interfaces/paginatedTable.interface';
import { User, UserProfileRole, UserProfile } from '../interfaces/usersConfiguration.interfaces';

interface UsersConfigurationState {
  usersList: User[];
  userProfileList: UserProfile[];
  userProfileRolesList: UserProfileRole[];
  userListTable: ISearchTable;
}

const initialState: UsersConfigurationState = {
  usersList: [],
  userProfileList: [],
  userProfileRolesList: [],
  userListTable: {
    page: 1,
    perPage: 5,
    search: '',
  },
};

const usersConfigurationSlice = createSlice({
  name: 'usersConfiguration',
  initialState,
  reducers: {
    setUsersList: (state, action: PayloadAction<User[]>) => {
      state.usersList = action.payload;
    },
    setUserProfilesList: (state, action: PayloadAction<UserProfile[]>) => {
      state.userProfileList = action.payload;
    },
    setUserProfileRolesList: (state, action: PayloadAction<UserProfileRole[]>) => {
      state.userProfileRolesList = action.payload;
    },
    updateUserListTable: (state, action: PayloadAction<ISearchTable>) => {
      state.userListTable = action.payload;
    },
  },
});

export const { setUsersList, setUserProfilesList, setUserProfileRolesList, updateUserListTable } =
  usersConfigurationSlice.actions;

export default usersConfigurationSlice.reducer;
