import React, { useState, useEffect } from 'react';

import { IconChevronDown, IconChevronUp } from '@tabler/icons';
import { useParams } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import Autocomplete from '@material-ui/core/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import useTheme from '@material-ui/core/styles/useTheme';

import { useTranslation } from 'react-i18next';

import { useGetCashPointReportQuery } from '../slices/cashPointsApiSlice';

import { renderErrorOrEmptyRow, renderSkeletonRows } from '../../../shared/helpers/render';

// import SearchPaginatedTable from '../../../shared/components/tables/SearchPaginatedTable';
import Chip from '../../../shared/components/extended/Chip';
import { TableCell, Typography, makeStyles } from '@material-ui/core';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { CashPoint, FinancialInstitution, PaymentData } from '../interfaces/cashPoint.interfaces';
import { UnknownObject } from '../../../../types';
import ReportDetails from './ReportDetails';
import SubCard from '../../../shared/components/cards/SubCard';
import { setExtraSubtitle, setExtraTitle, setTotalDailyAmount } from '../slices/cashPointsSlice';

const useStyles = makeStyles((theme) => ({
  labelTypo: {
    fontWeight: 'bold',
    textAlign: 'left',
    width: '100%',
    marginRight: 5,
  },
  labelTypo2: {
    textAlign: 'left',
    width: '100%',
    marginRight: 5,
  },
}));

const ReportTable = () => {
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { cashPointId } = useParams<{ cashPointId: string }>();

  const { t } = useTranslation();

  const defaultConfiguration = useAppSelector((state) => state.access.defaultConfiguration);
  /**
   * A CashPoint information retrieved from CashPointList
   */
  const { cashPointList } = useAppSelector((state) => state.cashPoints);
  const [cashPoint, setCashPoint] = useState<CashPoint | null>(null);

  const [destinedInstitution, setDestinedInstitution] = useState<FinancialInstitution | null>(null);

  const [openedList, setOpenedList] = useState<UnknownObject | undefined>(undefined);

  const { isLoading, error, data, isFetching } = useGetCashPointReportQuery(
    {
      cashPointId: +cashPointId,
      ...(destinedInstitution && { financialInst: destinedInstitution?.fin_int_keyword }),
    },
    { skip: !cashPointId }
  );

  useEffect(() => {
    if (cashPoint) {
      const cajero =
        cashPoint?.user?.person?.pers_full_name ||
        cashPoint?.user?.us_username ||
        t('financial.close_cash_point.report.table.labels.disengaged_cashier');

      const emissionPoint = `${t(
        'financial.close_cash_point.report.table.labels.emission_point'
      )}: ${cashPoint ? cashPoint?.emission_point! + '-' + cashPoint?.stablishment_point! : ''}`;

      dispatch(setExtraTitle(`: ${cajero}`));
      dispatch(setExtraSubtitle(emissionPoint));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cashPoint]);

  useEffect(() => {
    if (data) dispatch(setTotalDailyAmount(data.total_amount));
  }, [data, dispatch]);
  //

  // const datafetch = data?.payment_way.map((payWay, i) => ({
  //   numeration: { payWay, i: i++ },
  //   type: payWay.cat_description,
  //   destinedInstitution: '-',
  //   finalValue: '-',
  // }));

  useEffect(() => {
    if (cashPointId && cashPointList) {
      const currentCashPoint = cashPointList.find((cp) => `${cp.id}` === cashPointId);
      if (currentCashPoint) setCashPoint(currentCashPoint!);
    }
  }, [cashPointId, cashPointList]);

  const toogleOpenDetails = (id: number) => {
    if (openedList) {
      if (id in openedList) {
        const { [id as unknown as keyof UnknownObject]: remove, ...newList } = openedList;
        setOpenedList(newList);
      } else {
        setOpenedList((prevState) => {
          return { ...prevState, [id]: 1 };
        });
      }
    } else {
      setOpenedList({ [id]: 1 });
    }
  };

  const renderRow = (payData: PaymentData) => (
    <React.Fragment key={payData?.payment_way?.id}>
      <TableRow className="table-row">
        <TableCell align="center">
          <IconButton onClick={() => toogleOpenDetails(payData?.payment_way?.id)}>
            {openedList && payData?.payment_way?.id in openedList ? (
              <IconChevronUp size={20} />
            ) : (
              <IconChevronDown size={20} />
            )}
          </IconButton>
        </TableCell>
        <TableCell align="center">{payData?.payment_way?.cat_name}</TableCell>
        {/* <TableCell align="center">
          {payData?.payment_way?.cat_keyword !== 'forma-pago-saldo-favor' &&
          payData?.payment_way?.cat_keyword !== 'forma-pago-efectivo'
            ? destinedInstitution?.fin_int_name || '-'
            : '-'}
        </TableCell> */}
        <TableCell align="center">
          {defaultConfiguration?.currency_symbol}
          {payData?.total_collected /*.toFixed(2)*/ || '0.00'}
        </TableCell>

        {/* <TableCell width="5%">
        <StatusChip statusId={Number(p.status_id)} />
      </TableCell> */}
      </TableRow>
      {openedList && payData?.payment_way?.id in openedList && (
        <TableRow className="table-row">
          <TableCell align="center" colSpan={4}>
            <Collapse
              in={openedList && payData?.payment_way?.id in openedList}
              timeout="auto"
              unmountOnExit
            >
              <Box margin={1}>
                <Grid
                  container
                  p={2}
                  sx={{
                    backgroundColor: theme.palette.primary.light,
                    borderRadius: theme.shape.borderRadius,
                  }}
                >
                  <Grid item xs={12}>
                    <SubCard
                      content={false}
                      sx={{ mb: 2, p: 1, backgroundColor: theme.palette.background.paper }}
                    >
                      <ReportDetails
                        cashPointId={+cashPointId}
                        payment_way_keyword={payData?.payment_way?.cat_keyword}
                        financial_institutions_keyword={destinedInstitution?.fin_int_keyword}
                      />
                    </SubCard>
                  </Grid>
                </Grid>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );

  const renderRows = () => {
    return <>{data?.payment_data.map(renderRow)}</>;
  };

  return (
    <>
      <Grid container alignContent="flex-start">
        {/* <Grid item xs={12} container spacing={2} mb={2}>
          <Grid item mr={2}>
            <Typography variant="h5" className={classes.labelTypo}>
              {t('financial.close_cash_point.report.table.labels.emission_point')}
            </Typography>
          </Grid>
          <Grid item mr={2}>
            <Typography variant="h5" className={classes.labelTypo2}>
              {cashPoint ? cashPoint?.emission_point! + '-' + cashPoint?.stablishment_point! : ''}
            </Typography>
          </Grid>
          <Grid item mr={2}>
            <Typography variant="h5" className={classes.labelTypo}>
              {t('financial.close_cash_point.report.table.labels.state')}
            </Typography>
          </Grid>
          <Grid item mr={2}>
            {cashPoint && Number(cashPoint.is_closed) ? (
              <Chip
                chipcolor="error"
                label={t('financial.close_cash_point.report.table.labels.state.close_badge')}
              />
            ) : (
              <Chip
                chipcolor="success"
                label={t('financial.close_cash_point.report.table.labels.state.open_badge')}
              />
            )}
          </Grid>
        </Grid> */}
        <Grid item xs={12} container spacing={2} mb={1}>
          <Grid item xs={4}>
            <Typography variant="h5" className={classes.labelTypo}>
              {t('financial.close_cash_point.report.table.labels.open_date')}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h5" className={classes.labelTypo}>
              {t('financial.close_cash_point.report.table.labels.close_date')}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            {/* <Typography variant="h5" className={classes.labelTypo}>
              {t('financial.close_cash_point.report.table.labels.cashier')}
            </Typography> */}
            <Typography variant="h5" className={classes.labelTypo}>
              {t('financial.close_cash_point.report.table.labels.state')}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} container spacing={2} mb={2}>
          <Grid item xs={4}>
            <Typography variant="h5" className={classes.labelTypo2}>
              {cashPoint?.opening_date || ''}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h5" className={classes.labelTypo2}>
              {cashPoint?.deadline || '-'}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            {/* <Typography variant="h5" className={classes.labelTypo2}>
              {cashPoint?.user?.person?.pers_full_name ||
                cashPoint?.user?.us_username ||
                t('financial.close_cash_point.report.table.labels.disengaged_cashier')}
            </Typography> */}
            {cashPoint && Number(cashPoint.is_closed) ? (
              <Chip
                chipcolor="error"
                label={t('financial.close_cash_point.report.table.labels.state.close_badge')}
              />
            ) : (
              <Chip
                chipcolor="success"
                label={t('financial.close_cash_point.report.table.labels.state.open_badge')}
              />
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid container justifyContent={'flex-end'} p={1}>
        {false && (
          <Grid item xs={6}>
            <Autocomplete
              options={data?.financial_institutions || []}
              getOptionLabel={(option) => option.fin_int_name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('financial.close_cash_point.report.table.filter.institution_label')}
                />
              )}
              value={destinedInstitution}
              onChange={(e, value) => setDestinedInstitution(value || null)}
              getOptionSelected={(option, value) => option.id === value.id}
              fullWidth
              // clearIcon={null}
            />
          </Grid>
        )}
      </Grid>

      <Grid container justifyContent="center">
        <Grid item sm={12} overflow={'hidden'}>
          <>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">
                      {t('financial.close_cash_point.report.table.detail')}
                    </TableCell>
                    <TableCell align="center">
                      {t('financial.close_cash_point.report.table.type')}
                    </TableCell>
                    {/* <TableCell align="center">
                    {t('financial.close_cash_point.report.table.destined_institution')}
                  </TableCell> */}
                    <TableCell align="center">
                      ({defaultConfiguration?.currency_symbol})
                      {t('financial.close_cash_point.report.table.final_value')}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isLoading || isFetching ? renderSkeletonRows(2, 4) : renderRows()}

                  {error && renderErrorOrEmptyRow(4, error as string)}
                  {data?.payment_data?.length === 0 && renderErrorOrEmptyRow(4)}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        </Grid>
      </Grid>

      {/* <SearchPaginatedTable
        data={datafetch!}
        headers={{
          numeration: '#',
          type: 'Tipo',
          destinedInstitution: 'Institución destino',
          finalValue: '(${defaultConfiguration?.currency_symbol})
 Valor Final',
          // payment_way: 'Método de pago',
        }}
        keyExtractor={(item) => item.numeration.report.id.toString()}
        searchPlacehoder="Buscar unidades académicas"
        isLoading={isLoading}
        isFetching={isFetching}
        error={error}
        perPage={perPage}
        setPerPage={setPerPage}
        total={data?.total || 0}
        page={page}
        setPage={setPage}
        numHeader={7}
        search={search}
        setSearch={setSearch}
        showFilter={false}
        customRenderers={{
          numeration: (row) => <>{row.numeration.i}</>,
          // payment_status: (row) => (
          //   <>
          //     {row.payment_status.cat_keyword === 'estado-cobro-pagado' && (
          //       <Chip chipcolor="success" label="Pagado" />
          //     )}
          //     {row.payment_status.cat_keyword === 'estado-cobro-por-cobrar' && (
          //       <Chip chipcolor="warning" label="Por Cobrar" />
          //     )}
          //   </>
          // ),
          payment_way: (row) => <>{row.payment_way.cat_name}</>,
          // options: (row) => (
          //   <>
          //     {hasAccess('delete') && +row.status === 0 && (
          //       <Tooltip title="Cerrar Caja">
          //         <IconButton onClick={() => closeCashPoint(row.options.id)}>
          //           <IconLock size={20} color={theme.palette.primary.main} />
          //         </IconButton>
          //       </Tooltip>
          //     )}
          //     {hasAccess('delete') && +row.status === 1 && (
          //       <Tooltip title="Reporte Caja">
          //         <Link to={`${path}/${row.options.id}/reporte`}>
          //           <IconClipboardList size={20} color={theme.palette.secondary.main} />
          //         </Link>
          //       </Tooltip>
          //     )}
          //      {!hasAccess('edit') && !hasAccess('delete') && 'Ninguna'}   
          //   </>
          // ),
        }}
        customHeadersCellsProperties={{
          o_payment_amount: {
            align: 'center',
          },
        }}
        customDataCellsProperties={{
          o_payment_amount: {
            align: 'center',
          },
        }}
        ActionButtons={
          <Grid container alignItems={'center'} spacing={1}>
            <Grid item xs={4}></Grid>
            <Grid item xs={4}></Grid>
            <Grid item xs={4}></Grid>
          </Grid>
        }
        replaceHeader={true}
        newHeader={
          <>
            <TableCell>#</TableCell>
            <TableCell>Código de transacción</TableCell>
            <TableCell>Cobro</TableCell>
            <TableCell>Estado</TableCell>
            <TableCell>Método de pago</TableCell>
          </>
        }
      /> */}
    </>
  );
};

export default ReportTable;
