import { useHistory } from 'react-router-dom';
import { IconButton, Grid, useTheme, Typography } from '@material-ui/core';
import { IconArrowNarrowLeft } from '@tabler/icons';

import EditPeriodForm from '../components/edit/EditPeriodForm';

import MainCardForm from '../../../shared/components/cards/MainCardForm';
import { useTranslation } from 'react-i18next';

const EditPeriodScreen = () => {
  const { t } = useTranslation();

  const theme = useTheme();
  const history = useHistory();

  return (
    <MainCardForm>
      <Grid item xs={12}>
        <Grid item container alignItems="center" gap={1} sx={{ cursor: 'pointer' }}>
          <IconButton onClick={() => history.goBack()}>
            <IconArrowNarrowLeft size={30} color={theme.palette.text.dark} />
          </IconButton>
          <Typography variant="h2">{t('academic.periods_list.edit.title')}</Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} md={8} lg={6} sx={{ mt: 2 }}>
        <EditPeriodForm />
      </Grid>
    </MainCardForm>
  );
};

export default EditPeriodScreen;
