import {
  Checkbox,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  Paper,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';

import MainCard from '../../../../../shared/components/cards/MainCard';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { renderSkeletonRows } from '../../../../../shared/helpers/render';
import { useAppSelector } from '../../../../../../hooks/useRedux';
import { useGetTaskDetailQuery } from '../../../slices/virtualClassroomTeacherApiSlice';
import { useParams } from 'react-router-dom';
import { useGetCatalogByAcronymChildrenQuery } from '../../../../../shared/slices/catalogApi';
import { OptionAnswer } from '../../../interfaces/taskDetail.interfaces';
import { useTranslation } from 'react-i18next';
import LoadingScreen from '../../../../../shared/screens/LoadingScreen';

function DetailActivity() {
  const { activityId } = useParams<{ activityId: string }>();
  const {
    course: { courseId },
  } = useAppSelector((state) => state.virtualClassroomTeacherShared);

  const { currentProfile } = useAppSelector((state) => state.access);
  const { data: dataTypeQuestion } = useGetCatalogByAcronymChildrenQuery({
    acronym: 'tipo-respuesta',
    profileId: currentProfile?.profileId!,
  });

  const { data, isLoading } = useGetTaskDetailQuery({
    cursoId: courseId!,
    taskId: Number(activityId!),
  });

  const { t } = useTranslation();

  if (isLoading) {
    return <LoadingScreen />;
  }

  console.log(data);

  const RenderStudentsTable = () => {
    return (
      <>
        {data && data?.course_activity_one?.student_activities?.length > 0 ? (
          <>
            {data?.course_activity_one?.student_activities?.map((student) => {
              return (
                <TableRow
                  hover
                  className="table-row"
                  key={`${student?.student_id?.toString()}-${student?.student?.user?.person
                    ?.pers_first_lastname!}`}
                >
                  <TableCell width="70%" sx={{ color: 'black', cursor: 'pointer' }}>
                    {student?.student?.user?.person?.pers_first_lastname!}
                    <FormHelperText>{student?.student?.user?.email!}</FormHelperText>
                  </TableCell>
                </TableRow>
              );
            })}
          </>
        ) : (
          <>
            <TableRow>
              <TableCell colSpan={3}>
                <Typography variant="body2" color="textSecondary" component="p" textAlign="center">
                  No hay estudiantes en este curso.
                </Typography>
              </TableCell>
            </TableRow>
          </>
        )}
      </>
    );
  };

  const StudentsActivity = () => {
    return (
      <Grid item xs={12} sm={12} container spacing={2} justifyContent="center">
        <Grid item xs={12} md={12} sx={{ maxHeight: 600, overflow: 'hidden', marginTop: 3 }}>
          <PerfectScrollbar component="div">
            <TableContainer>
              <Table style={{ marginTop: 10 }}>
                <TableBody>
                  {isLoading ? renderSkeletonRows(3, 3) : RenderStudentsTable()}
                </TableBody>
              </Table>
            </TableContainer>
          </PerfectScrollbar>
        </Grid>
      </Grid>
    );
  };
  const ItemPregunta = (nameItem: string, valueItem: string) => {
    return (
      <Grid display="flex" mb="12px" p={'0 15px '} justifyContent={'space-between'}>
        <Typography variant="h4" mr={'10px'}>
          {nameItem} :
        </Typography>
        <Typography variant="body1">{valueItem} </Typography>
      </Grid>
    );
  };

  const Multiple = ({ op_ans_description, op_ans_is_answer }: OptionAnswer, index: number) => {
    return (
      <Grid container item xs={12} key={`${index}-${op_ans_description}`} justifyContent={'center'}>
        <Grid item xs={10} sx={{ mt: 2 }}>
          <TextField
            variant="standard"
            fullWidth
            disabled
            value={`${index + 1}. ${op_ans_description}`}
          />
        </Grid>
        <Grid item sx={{ mt: 1 }}>
          <Checkbox disabled checked={Number(op_ans_is_answer) === 1 ? true : false} />
        </Grid>
      </Grid>
    );
  };

  const Single = ({ op_ans_description, op_ans_is_answer }: OptionAnswer, index: number) => {
    return (
      <Grid container key={`${index}-${op_ans_description}`} justifyContent={'center'}>
        <Grid item xs={10} sx={{ mt: 2 }}>
          <TextField
            variant="standard"
            fullWidth
            disabled
            value={`${index + 1}. ${op_ans_description}`}
          />
        </Grid>
        <Grid item sx={{ mt: 1 }}>
          <FormControlLabel
            label=""
            disabled
            control={<Radio />}
            checked={Number(op_ans_is_answer) === 1 ? true : false}
          />
        </Grid>
      </Grid>
    );
  };

  const commentary = (comment: string) => {
    return (
      <Grid container justifyContent={'center'}>
        <Grid item xs={10} sx={{ mt: 2 }}>
          <TextField fullWidth rows={2} defaultValue={comment} multiline disabled />
        </Grid>
      </Grid>
    );
  };
  //    <EditorReadText text={commnet} />
  const renderOptions = (typeAnswerId: number, options: OptionAnswer[], comment: string) => {
    const typeQuestion = dataTypeQuestion?.find((e) => e.id === typeAnswerId);

    if (typeQuestion?.cat_name! === 'Opcion Multiple') {
      return options?.map((e, index) => Multiple(e, index));
    } else if (typeQuestion?.cat_name! === 'Opcion Seleccion') {
      return options.map((e, index) => Single(e, index));
    } else {
      return commentary(comment);
    }
  };

  const renderQuestions = () => {
    return (
      <>
        {data?.details_activity &&
          data?.details_activity?.map((itemTask, index) => (
            <>
              <Typography mt="15px" color="black">
                {index + 1}. {itemTask.dta_question}
              </Typography>
              {renderOptions(
                Number(itemTask.type_answer_id),
                itemTask.option_answers,
                itemTask?.option_answers[0]?.op_ans_comment!
              )}
            </>
          ))}
      </>
    );
  };

  return (
    <Grid component={MainCard}>
      <Grid container>
        <Grid item xs={6} style={{ height: '70vh' }}>
          <PerfectScrollbar component="div">
            <Grid padding={'0 12px'}>
              <Typography sx={{ mb: '10px' }} textAlign={'center'} variant="h2">
                {t('teacherVirtualClassroom.tasks.detailActivity.information')}
              </Typography>
              <Paper elevation={2} sx={{ p: '20px 0', m: '10px 5px' }}>
                <Grid>{ItemPregunta('Nombre de la Actividad', data?.act_description!)}</Grid>
                <Grid>
                  {ItemPregunta(
                    'La actividad es Calificable',
                    data?.act_is_score! === '1' ? 'Si' : 'No'
                  )}
                </Grid>
                <Grid>{ItemPregunta('Puntaje de la Actividad', data?.act_score_activity!)}</Grid>
                <Grid>
                  {ItemPregunta('Nota visible', data?.act_score_activity! === '1' ? 'Si' : 'No')}
                </Grid>
                <Grid>{ItemPregunta('Número de Preguntas', data?.act_num_max_questions!)}</Grid>
                <Grid>{ItemPregunta('Número de intentos', data?.act_num_tries!)}</Grid>
              </Paper>
              <Typography mt={'30px'} textAlign={'center'} variant="h2">
                {t('teacherVirtualClassroom.tasks.detailActivity.students')}
                {StudentsActivity()}
              </Typography>
            </Grid>
          </PerfectScrollbar>
          <Divider orientation="vertical" />
        </Grid>

        <Grid item xs={6} style={{ height: '70vh' }}>
          <PerfectScrollbar component="div">
            <Typography textAlign={'center'} variant="h2">
              {t('teacherVirtualClassroom.tasks.detailActivity.questions')}
              <Grid component={MainCard}>{renderQuestions()}</Grid>
            </Typography>
          </PerfectScrollbar>
        </Grid>
      </Grid>
    </Grid>
  );
}
export default DetailActivity;
