import { Modal, Box, Typography, Button, CircularProgress, Grid } from '@material-ui/core';
import { GridCloseIcon } from '@material-ui/data-grid';
import { useState } from 'react';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80vw',
  height: '80vh',
  bgcolor: 'background.paper',
  borderRadius: '8px',
  p: 4,
};
interface Props {
  open: boolean;
  handleClose: () => void;
  urlFile: string;
  srcFile?: string;
  title?: string;
}

export const ModalDocuments = ({ open, handleClose, urlFile, title, srcFile }: Props) => {
  const [isLoader, setIsLoader] = useState(true);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        py: 0,
      }}
    >
      <Box sx={style}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            mb: 2,
          }}
        >
          <Typography variant="h3">{title}</Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
            onClick={handleClose}
          >
            <Button variant="contained">
              <GridCloseIcon />
            </Button>
          </Box>
        </Box>
        {isLoader && (
          <Grid container justifyContent="center" alignItems="center">
            <CircularProgress color="primary" size={50} />
          </Grid>
        )}
        <iframe
          src={urlFile}
          style={{
            width: '100%',
            height: '90%',
            border: 'none',
            borderWidth: 0,
          }}
          title={title}
          onLoad={(item) => {
            setIsLoader(false);
          }}
        />
      </Box>
    </Modal>
  );
};
