import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../hooks/useRedux';
import MainCard from '../../../shared/components/cards/MainCard';
import DeleteEconomicGroupsDialog from '../components/DeleteEconomicGroupsDialog';
import EconomicGroupsTable from '../components/EconomicGroupsTable';

const EconomicGroupsScreens = () => {
  const { t } = useTranslation();
  const { economicGroupIdToDelete } = useAppSelector((state) => state.economicGroups);

  return (
    <div>
      <Grid component={MainCard} sx={{ padding: 2 }}>
        <Grid sx={{ mb: 3 }}>
          <Typography variant="h2">{t('financial.economic_group.title')}</Typography>
        </Grid>
        <EconomicGroupsTable />

        {economicGroupIdToDelete && <DeleteEconomicGroupsDialog />}
      </Grid>
    </div>
  );
};

export default EconomicGroupsScreens;
