import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../../../../hooks/useRedux';
import SubCard from '../../../../../shared/components/cards/SubCard';

const CreditNoteInformation = () => {
  const { selectedInvoice } = useAppSelector((state) => state.generateCreditNote);

  const { t } = useTranslation();

  return (
    <Grid container>
      <Grid item xs={12}>
        <SubCard>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Grid container justifyContent={'space-between'}>
                        <Typography variant="subtitle1" component="span">
                          {t('financial.generate_creditnote.credit_note_info.identification')}&nbsp;
                        </Typography>
                        <Typography component="span" variant="body2">
                          {selectedInvoice?.person.pers_identification || ''}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <Grid container justifyContent={'space-between'}>
                        <Typography variant="subtitle1" component="span">
                          {t('financial.generate_creditnote.credit_note_info.names')}&nbsp;
                        </Typography>
                        <Typography component="span" variant="body2">
                          {selectedInvoice?.person.pers_full_name || ''}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Grid container justifyContent={'space-between'}>
                        <Typography variant="subtitle1" component="span">
                          {t('financial.generate_creditnote.credit_note_info.mail')}&nbsp;
                        </Typography>
                        <Typography component="span" variant="body2">
                          {selectedInvoice?.person.pers_email || ''}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <Grid container justifyContent={'space-between'}>
                        <Typography variant="subtitle1" component="span">
                          {t('financial.generate_creditnote.credit_note_info.phone')}&nbsp;
                        </Typography>
                        <Typography component="span" variant="body2">
                          {selectedInvoice?.person.pers_phone_home ||
                            selectedInvoice?.person.pers_cell ||
                            ''}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Grid container justifyContent={'space-between'}>
                        <Typography variant="subtitle1" component="span">
                          {t('financial.generate_creditnote.credit_note_info.address')}&nbsp;
                        </Typography>
                        <Typography component="span" variant="body2">
                          {selectedInvoice?.person.pers_direction || ''}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </SubCard>
      </Grid>
    </Grid>
  );
};

export default CreditNoteInformation;
