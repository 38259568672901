import { FC, useEffect, useState } from 'react';

import { Button, CircularProgress, FormHelperText, Grid, TextField } from '@material-ui/core';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

import InputLabel from '../../../../shared/components/extended/Form/InputLabel';

import { BusTour, EditDataTour, HandleEditTourI } from '../../interfaces/buses.interfaces';
import SuccessAlert from '../../../../shared/components/alerts/SuccessAlert';
import Map from '../../../institution-info/components/Map';
// import { CreateRouteFormSchema, UpdateRouteFormSchema } from '../../constants/validationSchemas';

interface Props {
  editTour?: BusTour;
  editData?: EditDataTour;
  routeId: string;
  handleNewTour: (busTour: BusTour) => void;
  onCancel: (e: React.SyntheticEvent) => void;
  handleEditTour: HandleEditTourI;
}

const NewTourForm: FC<Props> = ({
  editTour,
  editData,
  routeId,
  handleNewTour,
  handleEditTour,
  onCancel,
}) => {
  const { t } = useTranslation();

  const [showAlerts, setShowAlerts] = useState(false);

  const { getFieldProps, setFieldValue, errors, touched, handleSubmit, resetForm, values } =
    useFormik({
      initialValues: {
        id: editTour?.id || null,
        name: editTour?.tour_name || '',
        order: editTour?.tour_order || '',
        type: editTour?.tour_type || '',
        coordLat: editTour?.tour_coord_lat || '',
        coordLong: editTour?.tour_coord_long || '',
        busRouteId: editTour?.bus_route_id || routeId,
        statusId: editTour?.status_id || '1',
      },
      // validationSchema: editTour ? UpdateRouteFormSchema : CreateRouteFormSchema,
      onSubmit: async ({ id, name, order, type, busRouteId, coordLat, coordLong, statusId }) => {
        try {
          if (!editTour) {
            handleNewTour({
              id: `t${new Date().getTime()}` as any,
              tour_name: name,
              bus_route_id: busRouteId,
              tour_coord_lat: coordLat || '0',
              tour_coord_long: coordLong || '0',
              status_id: statusId,
              tour_order: order,
              tour_type: type,
            });

            resetForm();
          } else {
            console.log(editData);
            handleEditTour(
              {
                id: id!,
                tour_name: name,
                bus_route_id: busRouteId,
                tour_coord_lat: coordLat || '0',
                tour_coord_long: coordLong || '0',
                status_id: statusId,
                tour_order: order,
                tour_type: type,
              },
              editData?.droppableId!,
              editData?.index!
            );
          }

          setShowAlerts(true);

          //   navigateTopSmooth();
        } catch (error) {
          console.log(error);
        }
      },
    });

  useEffect(() => {
    try {
      navigator.geolocation.getCurrentPosition((position) => {
        if (values.coordLat && values.coordLong) return;
        setFieldValue('coordLat', `${position.coords.latitude}`);
        setFieldValue('coordLong', `${position.coords.longitude}`);
      });
    } catch (error) {
      console.log(error);
    }
  }, [setFieldValue, values.coordLat, values.coordLong]);

  return (
    <form onSubmit={handleSubmit}>
      {/* <Button onClick={() => setValues({ ...initialValues });}>asd</Button> */}

      <Grid container spacing={1} alignItems="flex-start">
        <Grid item>
          {showAlerts && (
            <SuccessAlert
              message={`${
                !editTour
                  ? t('academic.buses.tour.dialog.form.success_create')
                  : t('academic.buses.tour.dialog.form.success_update')
              }`}
              handleDismiss={() => setShowAlerts(false)}
            />
          )}
        </Grid>

        <Grid item xs={12}>
          <InputLabel>{t('academic.buses.tour.dialog.form.name.label')}</InputLabel>
          <TextField
            // type="number"
            // disabled={paymethod.keyword === 'forma-pago-saldo-favor'}
            fullWidth
            // InputProps={{
            //   defaultValue: '',
            //   inputComponent: CurrencyInputMasked as any,
            //   startAdornment: defaultConfiguration?.currency_symbol,
            // }}
            // placeholder={`${defaultConfiguration?.currency_symbol}0.00`}
            {...getFieldProps('name')}
            error={Boolean(errors.name && touched.name)}
          />
          {errors.name && touched.name && <FormHelperText error>{t(errors.name)}</FormHelperText>}
        </Grid>

        <Grid item xs={12}>
          <Map
            googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`}
            containerElement={<div style={{ height: '400px' }} />}
            mapElement={<div style={{ height: '100%' }} />}
            loadingElement={<p>{t('shared.input.loading.status')}</p>}
            latitude={values.coordLat || '0'}
            longitude={values.coordLong || '0'}
            handleChangePosition={(lat, lng) => {
              setFieldValue('coordLat', `${lat || '0'}`);
              setFieldValue('coordLong', `${lng || '0'}`);
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Grid container justifyContent={'flex-end'} spacing={1}>
            <Grid item>
              <Button variant="outlined" onClick={onCancel}>
                {t('financial.debts_payment.pay_dialog.form.cancel_btn')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                // disabled={isCreatingRoute || isUpdatingRoute}
                endIcon={false && <CircularProgress size={20} />}
              >
                {editTour ? t('shared.save_button.label') : t('shared.add_button.label')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default NewTourForm;
