import * as React from 'react';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import { Grid, List, ListItem, ListItemText, Typography } from '@material-ui/core';
import { PaymentData } from './information';
import { ShippingData } from './Identification';
import { useAppSelector } from '../../../../../hooks/useRedux';

//tables
import SubjectsSimpleTable from '../simpleTables/SubjectsSimpleTable';
import AcademicFormationSimpleTable from '../simpleTables/AcademicFormationSimpleTable';
import LaboralExperienceSimpleTable from '../simpleTables/LaboralExperienceSimpleTable';

import {
  useGetAcademicOfferingQuery,
  useGetPeriodAcademicOfferingQuery,
} from '../../../academic-offerings/slices/academicOfferingsApiSlice';
import { useGetHourhandsByPeriodQuery } from '../../../periods/slices/periodsApiSlice';
import { Period } from '../../../academic-offerings/interfaces/periods.interface';
import { useTranslation } from 'react-i18next';

//-----------------------|| FORM WIZARD - VALIDATION  ||-----------------------//

const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: theme.spacing(1, 0),
  },
  total: {
    fontWeight: 700,
  },
  title: {
    marginTop: theme.spacing(2),
  },
}));

export interface Data {
  information: PaymentData;
  identification: ShippingData;
}

export default function Review(props: Data) {
  const { t } = useTranslation();

  const ref = React.useRef<null | HTMLDivElement>(null);

  const classes = useStyles();
  const { information, identification } = props;
  const { currentProfile } = useAppSelector((state) => state.access);
  const {
    listSubjects,
    listAcademicFormation,
    listLaboralExperience,
    userPersonRequisition,
    personRequisition,
  } = useAppSelector((state) => state.requisitionTeacher);

  const profileId = currentProfile?.profileId!;

  const { data: offerData, isLoading: isLoadingOffer } = useGetAcademicOfferingQuery({
    profileId,
    academicOfferingId: information.offerId!,
  });

  const { data: hourhandData, isLoading: isLoadingHourhand } = useGetHourhandsByPeriodQuery({
    profileId,
    periodId: information.newPeriodId!,
  });

  const { data: availablesPeriodsByOffer, isLoading: isLoadingPeriodsByOffer } =
    useGetPeriodAcademicOfferingQuery(
      {
        profileId,
        academicOfferingId: information.offerId!.toString(),
        current: true,
      },
      { skip: !information.offerId! }
    );

  const tipoContrato = [
    {
      label: 'TC',
      name: 'Tiempo completo',
      translateId: 'academic.requisition.edit.wizard.step3.contract_type.full_time',
    },
    {
      label: 'MT',
      name: 'Medio tiempo',
      translateId: 'academic.requisition.edit.wizard.step3.contract_type.half_time',
    },
    {
      label: 'PA',
      name: 'Parcial',
      translateId: 'academic.requisition.edit.wizard.step3.contract_type.partial',
    },
  ];

  React.useEffect(() => {
    if (ref?.current) {
      ref?.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [ref]);

  return (
    <React.Fragment>
      <Typography variant="h5" gutterBottom sx={{ mb: 2 }} ref={ref}>
        {t('academic.requisition.edit.wizard.step3.request')}
      </Typography>
      <Grid container spacing={8}>
        <Grid item xs={6}>
          <List disablePadding>
            <ListItem className={classes.listItem}>
              <ListItemText primary={t('academic.requisition.edit.wizard.step1.name')} />
              <Typography variant="subtitle1" className={classes.total}>
                {userPersonRequisition?.person?.pers_full_name || personRequisition?.pers_full_name}
              </Typography>
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemText primary={identification.identification_type?.name} />
              <Typography variant="subtitle1" className={classes.total}>
                {identification.id}
              </Typography>
            </ListItem>

            <ListItem className={classes.listItem}>
              <ListItemText primary={t('academic.requisition.edit.wizard.step3.schedule')} />
              <Typography variant="subtitle1" className={classes.total}>
                {isLoadingHourhand
                  ? '...'
                  : hourhandData?.find((e) => e.id.toString() === information.hourhandId)
                      ?.hour_description}
              </Typography>
            </ListItem>

            <ListItem className={classes.listItem}>
              <ListItemText primary={t('academic.requisition.edit.wizard.step3.new_period')} />
              <Typography variant="subtitle1" className={classes.total}>
                {isLoadingPeriodsByOffer
                  ? '...'
                  : (availablesPeriodsByOffer as unknown as Period[])?.find(
                      (e) => e.id === information.newPeriodId
                    )?.per_name}
              </Typography>
            </ListItem>

            <ListItem className={classes.listItem}>
              <ListItemText primary={t('academic.requisition.edit.wizard.step3.last_period')} />
              <Typography variant="subtitle1" className={classes.total}>
                {isLoadingPeriodsByOffer
                  ? '...'
                  : (availablesPeriodsByOffer as unknown as Period[])?.find(
                      (e) => e.id === information.beforePeriodId
                    )?.per_name}
              </Typography>
            </ListItem>

            <ListItem className={classes.listItem}>
              <ListItemText primary={t('academic.requisition.edit.wizard.step3.edu_level')} />
              <Typography variant="subtitle1" className={classes.total}>
                {isLoadingOffer
                  ? '...'
                  : offerData?.education_levels.find((e) => e.id === information.educationLevelId)
                      ?.edu_name}
              </Typography>
            </ListItem>
          </List>
        </Grid>

        <Grid item xs={6}>
          <List disablePadding>
            <ListItem className={classes.listItem}>
              <ListItemText primary={t('academic.requisition.edit.wizard.step3.vinc_type')} />
              <Typography variant="subtitle1" className={classes.total}>
                {information.tipoVinculacion === '0'
                  ? t('academic.requisition.edit.wizard.step3.vinc_type.prof_service')
                  : t('academic.requisition.edit.wizard.step3.vinc_type.dep_service')}
              </Typography>
            </ListItem>

            <ListItem className={classes.listItem}>
              <ListItemText primary={t('academic.requisition.edit.wizard.step3.payment')} />
              <Typography variant="subtitle1" className={classes.total}>
                {information.typePayment === 's'
                  ? t('academic.requisition.edit.wizard.step3.payment.salary')
                  : t('academic.requisition.edit.wizard.step3.payment.hour')}
              </Typography>
            </ListItem>

            <ListItem className={classes.listItem}>
              <ListItemText primary={t('academic.requisition.edit.wizard.step3.payment.value')} />
              <Typography variant="subtitle1" className={classes.total}>
                {information.salary}
              </Typography>
            </ListItem>

            <ListItem className={classes.listItem}>
              <ListItemText primary={t('academic.requisition.edit.wizard.step3.type')} />
              <Typography variant="subtitle1" className={classes.total}>
                {information.typeContract === 'pv'
                  ? t('academic.requisition.edit.wizard.step3.type.first_time')
                  : t('academic.requisition.edit.wizard.step3.type.re_entry')}
              </Typography>
            </ListItem>

            <ListItem className={classes.listItem}>
              <ListItemText primary={t('academic.requisition.edit.wizard.step2.contract_type')} />
              <Typography variant="subtitle1" className={classes.total}>
                {t(
                  tipoContrato.find((e) => e.label === information.tipoDedicacion)?.translateId! ||
                    ''
                )}
              </Typography>
            </ListItem>

            <ListItem className={classes.listItem}>
              <ListItemText primary={t('academic.requisition.edit.wizard.step3.start_date')} />
              <Typography variant="subtitle1" className={classes.total}>
                {information.startDate?.toLocaleDateString()}
              </Typography>
            </ListItem>
          </List>
        </Grid>
      </Grid>
      <Grid container mt={4} spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h3" color="initial">
            {t('academic.requisition.edit.wizard.step3.selected_subjects')}
          </Typography>
          <SubjectsSimpleTable data={listSubjects} />
        </Grid>

        <Grid item xs={12} mt={3}>
          <Typography variant="h3" color="initial">
            {t('academic.requisition.edit.wizard.step3.academic_form')}
          </Typography>
          <AcademicFormationSimpleTable data={listAcademicFormation} />
        </Grid>

        <Grid item xs={12} mt={3}>
          <Typography variant="h3" color="initial">
            {t('academic.requisition.edit.wizard.step3.laboral_experience')}
          </Typography>
          <LaboralExperienceSimpleTable data={listLaboralExperience} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
