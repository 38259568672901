import Typography from '@material-ui/core/Typography';

import NavGroup from './NavGroup';

import menuItems from '../../../../menu-items';
import { useAppSelector } from '../../../../../../hooks/useRedux';

const MenuList = () => {
  const {
    currentModule,
    currentProfile,
    currentVirtualClassroom: vc,
  } = useAppSelector((state) => state.access);

  const navItems = menuItems.items.map((item) => {
    switch (item.type) {
      case 'group':
        return currentModule?.key === item.id ? <NavGroup key={item.id} item={item} /> : null;

      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  const isStudent = currentProfile?.keyword === 'estudiante';
  const studentMenu = vc
    ? menuItems.items.find((i) => i.id === 'virtual-classroom-students')!
    : menuItems.items.find((i) => i.id === 'students')!;

  const isTeacher = currentProfile?.keyword === 'docente';
  const teacherMenu = vc
    ? menuItems.items.find((i) => i.id === 'virtual-classroom-teachers')!
    : menuItems.items.find((i) => i.id === 'teachers')!;

  const resultItems = isStudent ? (
    <NavGroup item={studentMenu} />
  ) : isTeacher ? (
    <NavGroup item={teacherMenu} />
  ) : (
    navItems
  );
  return <>{resultItems}</>;
};

export default MenuList;
