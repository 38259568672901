import { parse } from 'querystring';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useAppDispatch } from '../../../hooks/useRedux';
import LoadingScreen from '../../shared/screens/LoadingScreen';
import {
  checkModuleQuantity,
  checkProfileQuantity,
  mapUserProfiles,
  validationProfilesRol,
} from '../helpers';
import { TokenJWT } from '../interfaces/login.interfaces';
import { useValidateGoogleLoginQuery } from '../slices/authApiSlice';
import jwt_decode from 'jwt-decode';
import { loginSync, setGoogleLoginError } from '../slices/authSlice';
import { setCurrentStudentIfExist } from '../helpers/helperStudent';
import { setCollaboratorInfoIfExist } from '../helpers/helperCollaborator';

const AuthGoogleScreen = () => {
  const location = useLocation();
  const history = useHistory();

  const dispatch = useAppDispatch();

  const { data, isSuccess, isError, error } = useValidateGoogleLoginQuery({
    params: parse(location.search.replace('?', '')),
  });

  useEffect(() => {
    if (data && isSuccess) {
      const token = data.access_token;
      localStorage.setItem('x-token', token);

      const { user } = jwt_decode<TokenJWT>(data.access_token);

      /**
       * Valida la existencia de perfiles y roles
       */
      validationProfilesRol(dispatch, user);

      /**
       * Construye profiles que esta dentro de access
       * lo asigna al store y lo retorna
       */
      const profiles = mapUserProfiles(dispatch, user.user_profiles);

      /**
       * Verifica la cantidad de perfiles y si existe uno asigna
       * los valores correspondientes al localStorage y al store.
       * Retorna los modulos de ese perfil.
       *
       * Si existe mas de un perfil se debe de asignar desde
       * el componente ProfileScreen.tsx y retornara un objeto vacio.
       */
      const { modules } = checkProfileQuantity(dispatch, profiles);

      /**
       * Valida que se envie un solo modulo para asignarlo al
       * currentModule en el store.
       * Si existe mas de un modulo se debe aignar desde el componete
       * ModuleScreen.tsx
       */
      checkModuleQuantity(dispatch, modules);

      dispatch(
        loginSync({
          name: user.us_firstname,
          fullname: `${user.us_first_lastname === null ? '' : user.us_first_lastname} ${
            user.us_second_lastname === null ? '' : user.us_second_lastname
          } ${user.us_firstname === null ? '' : user.us_firstname} ${
            user.us_secondname === null ? '' : user.us_secondname
          }`,
          secondname: user.us_secondname === null ? '' : user.us_secondname,
          lastname: user.us_first_lastname === null ? '' : user.us_first_lastname,
          secondlastname: user.us_second_lastname === null ? '' : user.us_second_lastname,
          gender: user.us_gender,
          email: user.email,
          zendesk: user.zendesk,
          token,
          remember_token: user.remember_token,
          id: user.id,
          personId: user.person_id,
          identification: user.us_identification,
          hasSign: user.has_sign,
        })
      );

      /**
       * Verifica si existe el estudiante y asigna los valores
       * al store y al localStorage
       */
      setCurrentStudentIfExist(dispatch, user);

      /**
       * Verifica si existe colaborador y asigna collaboratorInfo al store
       */
      setCollaboratorInfoIfExist(dispatch, user);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, dispatch]);

  useEffect(() => {
    if (isError) {
      dispatch(setGoogleLoginError(error as string));
      history.push('/login');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  return <LoadingScreen />;
};

export default AuthGoogleScreen;
