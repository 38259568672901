import { CircularProgress, Grid } from '@material-ui/core';
import { FC } from 'react';
import ErrorAlert from '../alerts/ErrorAlert';

interface Props {
  isLoading?: boolean;
  isError?: boolean;
  isSuccess?: boolean;
  error?: string;

  errorComponent?: () => Element;
  isLoadingComponent?: () => Element;
}

/**
 *
 * @param param0 Componente wrap para controlar los estados de una peticion al renderizar otro componente hijo y no tener que validar sus estados (isLoading, error, etc)
 * @returns function component
 */
const ComponentFetch: FC<Props> = ({
  children,
  error,
  isError,
  isLoading,
  isSuccess,

  errorComponent,
  isLoadingComponent,
}) => {
  return (
    <>
      {isLoading ? (
        <>
          {isLoadingComponent ? (
            isLoadingComponent()
          ) : (
            <Grid container item xs={12} justifyContent="center" alignItems="center">
              <CircularProgress />
            </Grid>
          )}
        </>
      ) : (
        <>
          {isError && error && (
            <Grid container item xs={12} justifyContent="center" alignItems="center">
              {errorComponent ? errorComponent() : <ErrorAlert message={error} />}
            </Grid>
          )}

          {isSuccess && <>{children}</>}
        </>
      )}
    </>
  );
};

export default ComponentFetch;
