import { FC } from 'react';
import { PermissionsKeys } from '../../interfaces/permissions.interface';
import useSharedGuard from '../../../../hooks/useSharedGuard';
import NotPermissionAlert from './NotPermissionAlert';

interface Props {
  permissions: PermissionsKeys | PermissionsKeys[];
  mode?: 'all' | 'one';

  notPermission?: () => JSX.Element;

  testForceValidation?: boolean;
}

const PermissionObjectCheck: FC<Props> = ({
  children,
  permissions,
  mode,
  notPermission,
  testForceValidation,
}) => {
  const { hasAccess, hasAtLeastOne } = useSharedGuard(permissions);

  const notPermissionComponent = notPermission ? notPermission : () => <NotPermissionAlert />;

  if (mode === 'all')
    return <>{hasAccess(undefined, testForceValidation) ? children : notPermissionComponent()}</>;

  return <>{hasAtLeastOne(undefined, testForceValidation) ? children : notPermissionComponent()}</>;
};

export default PermissionObjectCheck;
