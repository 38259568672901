import { Box, Grid, IconButton, makeStyles, Menu, MenuItem, Typography } from '@material-ui/core';
import { Languages } from '../../../../../../types';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/useRedux';
import { setLanguage } from '../../../../reducers/customizationReducer';
import { useState } from 'react';
import { languages } from './languages';
import { LanguageSelector } from '../../../../../shared/interfaces/material-ui.interfaces';
import { useChangeLanguageMutation } from '../../../../../maintenance/configurations/slices/configurationsApiSlice';

const flagIcons = require.context('../../../../../../assets/images/icons', true);

const useStyles = makeStyles((theme) => ({
  languageButton: {
    width: 50,
    height: 50,
    padding: '.35em',
    '& img': {
      width: '100%',
    },
  },
  languageItem: {
    width: '165px',
    '& img': {
      width: '30px',
    },
  },
}));

interface Props {
  disabled?: boolean;
}

const LanguageSwitcher = ({ disabled = false }: Props) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const dispatch = useAppDispatch();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const customization = useAppSelector((state) => state.customization);

  const languageSelected =
    languages.find((language) => language.value === customization.language) || languages[0];

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const [changeLanguage] = useChangeLanguageMutation();

  const handleLanguageChange = (language: Languages) => {
    setAnchorEl(null);
    dispatch(setLanguage(language));
    changeLanguage({ language });
    setOpen(false);
  };

  return (
    <Box>
      <Grid container>
        <Grid item>
          <IconButton className={classes.languageButton} onClick={handleClick} disabled={disabled}>
            <img
              src={flagIcons(`./${languageSelected.icon}`).default}
              alt={languageSelected.icon}
            />
          </IconButton>
        </Grid>

        <Menu
          id="menu-user-list-style1"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={() => handleLanguageChange(customization.language)}
          variant="selectedMenu"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          sx={{
            width: '200px',
          }}
        >
          {languages.map((language) => (
            <MenuItem
              className={classes.languageItem}
              onClick={() => handleLanguageChange(language.value)}
              selected={language.value === customization.language}
              key={language.value}
            >
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <Grid item>
                  <img src={flagIcons(`./${language.icon}`).default} alt={language.icon} />
                </Grid>
                <Grid item>
                  <Typography>
                    {language.label[customization.language as keyof LanguageSelector]}
                  </Typography>
                </Grid>
              </Grid>
            </MenuItem>
          ))}
        </Menu>
      </Grid>
    </Box>
  );
};

export default LanguageSwitcher;
