import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EnabledSubject } from '../interfaces/enabledSubjects.interfaces';
interface EnabledSubjectsState {
  currentEnabledSubjects: EnabledSubject[];
}
const initialState: EnabledSubjectsState = {
  currentEnabledSubjects: [],
};
const enabledSubjectsSlice = createSlice({
  name: 'enabledSubjects',
  initialState,
  reducers: {
    setCurrentEnabledSubjects: (state, action: PayloadAction<EnabledSubject[]>) => {
      state.currentEnabledSubjects = action.payload;
    },
  },
});
export const { setCurrentEnabledSubjects } = enabledSubjectsSlice.actions;
export default enabledSubjectsSlice.reducer;
