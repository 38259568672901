import { Button, CircularProgress, Grid, Typography, useTheme } from '@material-ui/core';
import { IconCircleCheck, IconFileAlert } from '@tabler/icons';
import { useAppSelector } from '../../../../hooks/useRedux';
import { useRemoveApplicationMutation } from '../../slices/rrhhApiSlice';

interface props {
  handleClose: () => void;
  appId: number;
}

const RemoveAppForm = ({ handleClose, appId }: props) => {
  const theme = useTheme();
  const { currentProfile } = useAppSelector((state) => state.access);

  const [removeApp, { isSuccess, isError, error, isLoading }] = useRemoveApplicationMutation();

  if (isSuccess) {
    return (
      <Grid container gap={4} sx={{ p: 2 }}>
        <Grid item container justifyContent="center" xs={12} gap={1}>
          <IconCircleCheck
            size={60}
            color={theme.palette.success.main}
            style={{
              padding: 12,
              backgroundColor: theme.palette.success.light,
              borderRadius: '50%',
            }}
          />
        </Grid>
        <Grid item container justifyContent="center" xs={12} gap={1}>
          <Typography variant="h4" textAlign="center">
            Se elimino la solicitud correctamente
          </Typography>
        </Grid>
        <Grid item justifyContent="center" container xs={12} gap={1}>
          <Button variant="contained" onClick={handleClose}>
            Aceptar
          </Button>
        </Grid>
      </Grid>
    );
  }

  if (isError) {
    return (
      <Grid container gap={4} sx={{ p: 2 }}>
        <Grid item container justifyContent="center" xs={12} gap={1}>
          <IconFileAlert
            size={60}
            color={theme.palette.error.main}
            style={{
              padding: 12,
              backgroundColor: theme.palette.orange.light,
              borderRadius: '50%',
            }}
          />
        </Grid>
        <Grid item container justifyContent="center" xs={12} gap={1}>
          <Typography variant="h4" textAlign="center">
            {String(error)}
          </Typography>
        </Grid>
        <Grid item justifyContent="center" container xs={12} gap={1}>
          <Button variant="contained" onClick={handleClose}>
            Aceptar
          </Button>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container gap={4} sx={{ p: 2 }}>
      <Grid item container xs={12} gap={1} justifyContent="center">
        <Typography variant="h4">Se eliminará esta solicitud</Typography>
      </Grid>
      <Grid item container xs={12} justifyContent="center" gap={1}>
        <Button
          variant="text"
          size="large"
          color="primary"
          onClick={handleClose}
          disabled={isLoading}
        >
          No, Cancelar
        </Button>
        <Button
          variant="contained"
          size="large"
          onClick={() => {
            removeApp({
              app_id: appId,
              user_profile_id: currentProfile?.profileId!,
            });
          }}
          color="primary"
          autoFocus
          disabled={isLoading}
          endIcon={isLoading && <CircularProgress size={20} />}
        >
          Sí, Eliminar
        </Button>
        {/* {!isSuccess && (
          )} */}

        {/* {isSuccess && (
            <Button variant="contained" size="large" color="primary" onClick={onClose}>
              Continuar
            </Button>
          )} */}

        {/* {!isSuccess && (
            <Button
              variant="contained"
              size="large"
              onClick={actionHandler}
              color="primary"
              autoFocus
              disabled={isLoading || disabledConfirm}
              endIcon={isLoading && <CircularProgress size={20} />}
            >
              {confirmBtn}
            </Button>
          )} */}
      </Grid>
    </Grid>
  );
};

export default RemoveAppForm;
