import { Grid, Typography } from '@material-ui/core';
// import { useAppSelector } from '../../../../hooks/useRedux';
import MainCard from '../../../shared/components/cards/MainCard';
import AssignCoursePostgrade from '../components/AssignCoursesPostgrade';
// import DeleteRoleDialog from '../components/DeleteRoleDialog';
import { useTranslation } from 'react-i18next';

// import AreasTable from '../components/AreasTable';

const AssignCourseScreen = () => {
  // const { roleIdToDelete } = useAppSelector((state) => state.roles);
  const { t } = useTranslation();
  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">{t('academic.assignCoursesPosgrade.title')}</Typography>
      </Grid>
      <AssignCoursePostgrade />
      {/* <AreasTable /> */}
      {/* {roleIdToDelete && <DeleteRoleDialog />} */}
    </Grid>
  );
};

export default AssignCourseScreen;
