import { useHistory } from 'react-router-dom';
import { Grid, Typography, IconButton, useTheme } from '@material-ui/core';
import { IconChevronLeft } from '@tabler/icons';
import MainCardForm from '../../../shared/components/cards/MainCardForm';
import { PeriodStepper } from '../components/create/stepper/PeriodStepper';
import { useTranslation } from 'react-i18next';

const AddPeriodScreen = () => {
  const { t } = useTranslation();

  const theme = useTheme();

  const history = useHistory();

  return (
    <MainCardForm>
      <Grid item xs={12}>
        <Grid item container alignItems="center" gap={1} sx={{ cursor: 'pointer' }}>
          <IconButton onClick={() => history.push('/administrar-periodos')}>
            <IconChevronLeft size={30} color={theme.palette.text.dark} />
          </IconButton>
          <Typography variant="h2">{t('academic.periods_list.add.title')}</Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ mt: 2 }} container>
        <PeriodStepper />
      </Grid>
    </MainCardForm>
  );
};

export default AddPeriodScreen;
