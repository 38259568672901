import React, { FC, useState, useEffect, useMemo, useRef } from 'react';

import {
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Button,
  Checkbox,
  CircularProgress,
  TextField,
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';

import {
  renderErrorOrEmptyRow,
  renderSkeletonRows,
  renderSkeletonTable,
} from '../../../../shared/helpers/render';
// import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import { useGetAvaliblesRolesQuery } from '../../../roles/slices/rolesApiSlice';
import { Role } from '../../../roles/interfaces/roles.interfaces';
import { controlSelectionArr } from '../../../../shared/helpers';
import { useAddUserProfileRolesMutation } from '../../slices/userConfigurationsApiSlice';
import { useAppSelector } from '../../../../../hooks/useRedux';
import ErrorAlert from '../../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../../shared/components/alerts/SuccessAlert';
import { useTranslation } from 'react-i18next';

interface Props {
  userId: number;
  profileId: number;
  handleCloseDialog: (e: React.SyntheticEvent) => void;
}

const UserProfileRolesTable: FC<Props> = (props) => {
  const { t } = useTranslation();
  const { handleCloseDialog, userId, profileId } = props;

  const perfectScrollBarRef = useRef<HTMLElement>();

  const { userProfileRolesList } = useAppSelector((state) => state.usersConfiguration);

  const [selected, setSelected] = useState<number[]>([]);
  const [search, setSearch] = useState('');

  const [showAlerts, setShowAlerts] = useState(true);

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);

    if (perfectScrollBarRef?.current) {
      perfectScrollBarRef.current.scrollTop = 0;
    }
  };

  useEffect(() => {
    setSelected(userProfileRolesList.map((dt) => dt.id) || []);
  }, [userProfileRolesList]);

  //check por fila
  const handleClick = (event: React.ChangeEvent<HTMLInputElement>, rolId: number) => {
    const newSelected = controlSelectionArr(selected, rolId);

    setSelected(newSelected);
  };

  //client data
  const {
    data: rolesData,
    isFetching: isFetchingRL,
    isLoading: isLoadingRL,
    error: errorRL,
  } = useGetAvaliblesRolesQuery({ profile_id: profileId });

  const rolesDataSort = useMemo(() => {
    if (rolesData) {
      return [...rolesData];
    }
  }, [rolesData]);

  //check all
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rolesDataSort?.map((dt) => +dt.id) || [];
      setSelected(newSelected);
      return;
    }

    setSelected([]);
  };

  const [addRoles, { isLoading, isError, isSuccess, error }] = useAddUserProfileRolesMutation();

  const handleSetRoles = async () => {
    await addRoles({
      profileId,
      userId,
      userProfilePayload: { roles: selected },
    });

    setShowAlerts(true);
  };

  const renderRow = (rol: Role) => (
    <TableRow
      hover
      className="table-row"
      key={rol.id.toString()}
      // sx={{ cursor: 'pointer', backgroundColor: selected?.id === rol.id ? '#eee' : '' }}
      onClick={() => {
        // handleSelectrole(rol.id);
      }}
    >
      <TableCell>
        <Checkbox
          checked={selected.some((r) => r === Number(rol.id))}
          onChange={(e) => handleClick(e, Number(rol.id))}
        />
      </TableCell>
      <TableCell>{rol.name}</TableCell>
      {/* <TableCell>{rol.pers_identification}</TableCell> */}
    </TableRow>
  );

  const regexTest = new RegExp(search.toLowerCase() + '.*$');
  const getSortedArr = (value: string, key: number) => {
    if (search && value.toLowerCase().match(regexTest)) {
      return 1;
    }
    if (selected.some((dt) => dt === key)) {
      return 2;
    }
    return 3;
  };
  const renderRows = () => {
    return (
      <>
        {rolesDataSort
          ?.sort((a, b) => getSortedArr(a.name, a.id) - getSortedArr(b.name, b.id))
          .map(renderRow)}
      </>
    );
  };

  // console.log(
  //   rolesDataSort?.sort((a, b) => getSortedArr(a.name, a.id) - getSortedArr(b.name, b.id))
  // );

  return (
    <>
      <Grid container alignContent={'flex-end'} spacing={1}>
        <Grid item xs={12}>
          {isError && showAlerts && (
            <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
          )}

          {isSuccess && showAlerts && (
            <SuccessAlert
              message={t('configuration.users.roles.form.success')}
              handleDismiss={() => setShowAlerts(false)}
            />
          )}
        </Grid>

        <Grid item xs={12}>
          <TextField
            value={search}
            onChange={handleChangeSearch}
            placeholder={t('configuration.users.roles.form.search')}
          />
        </Grid>
        <Grid item xs={12}>
          {isLoadingRL && renderSkeletonTable(5, 4)}
          {!isLoadingRL && (
            <>
              <Grid container>
                <Grid item xs={12} sx={{ maxHeight: '65vh', overflow: 'hidden' }}>
                  <PerfectScrollbar
                    component="div"
                    containerRef={(el) => (perfectScrollBarRef.current = el)}
                  >
                    <TableContainer>
                      <Table stickyHeader>
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              <Checkbox
                                color="primary"
                                indeterminate={Boolean(
                                  selected.length &&
                                    rolesData?.length &&
                                    rolesData?.length > selected.length
                                )}
                                checked={rolesData?.length === selected.length}
                                onChange={handleSelectAllClick}
                                inputProps={{
                                  'aria-label': t('configuration.users.roles.form.select_all'),
                                }}
                              />
                            </TableCell>
                            <TableCell>{t('configuration.users.roles.form.role')}</TableCell>
                            {/* <TableCell>Estado</TableCell>
                      <TableCell>Opciones</TableCell> */}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {isFetchingRL ? renderSkeletonRows(5, 4) : renderRows()}

                          {errorRL && renderErrorOrEmptyRow(4, errorRL as string)}
                          {rolesData?.length === 0 &&
                            renderErrorOrEmptyRow(4, t('configuration.users.no_data'))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </PerfectScrollbar>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>

        <Grid container justifyContent={'flex-end'} spacing={1} mt={1}>
          <Grid item>
            <Button variant="outlined" size="large" onClick={(e) => handleCloseDialog(e)}>
              {t('configuration.users.roles.form.cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              size="large"
              disabled={isLoading}
              endIcon={isLoading && <CircularProgress size={20} />}
              onClick={handleSetRoles}
            >
              {t('configuration.users.roles.form.submit')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default UserProfileRolesTable;
