import {
  Dialog,
  DialogProps,
  InputLabel,
  Autocomplete,
  Grid,
  TextField,
  FormHelperText,
  Button,
  CircularProgress,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useFormik } from 'formik';
import { useAppSelector } from '../../../../../hooks/useRedux';
import TextFieldFetching from '../../../../shared/components/TextFieldFetching';
import {
  useGetCatalogByAcronymQuery,
  useGetCatalogByKeywordQuery,
} from '../../../../shared/slices/catalogApi';
import MainCard from '../../../../shared/components/cards/MainCard';
import { useEffect, useState } from 'react';
import SuccessAlert from '../../../../shared/components/alerts/SuccessAlert';
import { PersonCreateSchema } from '../../../../shared/components/person/constants/PersonCreateSchema';
import { NewRelativesPersonTable } from '../../interfaces/studentRecordAcademic.interfaces';
import { useTranslation } from 'react-i18next';
import { updateItemFromKeyArr } from '../../../../shared/helpers';
import { useGetAvailablesTypeEducationsQuery } from '../../../requisition-teacher/slices/requisitionsApiSlice';

const useStyles = makeStyles((theme) => ({
  paper: { maxWidth: '600px', width: '90%' },
}));

interface Props extends DialogProps {
  studentPersonId: string;
  editData?: NewRelativesPersonTable;
  onClose: () => void;
  onSave: (value: React.SetStateAction<Partial<NewRelativesPersonTable>[]>) => void;
  setNewPersonRelatives?: React.Dispatch<React.SetStateAction<Partial<NewRelativesPersonTable>[]>>;
}

const NewPersonRelativeModal = ({
  studentPersonId,
  editData,
  onClose,
  onSave,
  setNewPersonRelatives,
  ...props
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  console.log(editData);

  const { currentProfile } = useAppSelector((state) => state.access);

  const [showAlerts, setShowAlerts] = useState<boolean>(false);

  const [isSuccess, setIsSuccess] = useState(false);

  //genders
  const {
    data: gendersAvailables,
    isLoading: isLoadingGA,
    isFetching: isFetchingGA,
  } = useGetCatalogByKeywordQuery({
    profileId: currentProfile?.profileId!,
    keyword: 'GE',
  });

  //marital status
  const { data: civilStatusAvailables, isLoading: isLoadingCSA } = useGetCatalogByAcronymQuery({
    profileId: currentProfile?.profileId!,
    acronym: 'SM',
  });

  //religions
  const { data: religionsAvailables, isLoading: isLoadingRA } = useGetCatalogByAcronymQuery({
    profileId: currentProfile?.profileId!,
    acronym: 'TR',
  });

  const {
    data: identificationsAvailables,
    isLoading: isLoadingIA,
    isFetching: isFetchingIA,
  } = useGetCatalogByAcronymQuery({
    profileId: currentProfile?.profileId!,
    acronym: 'TI',
  });

  //Ethnics
  const { data: ethnicsAvailables, isLoading: isLoadingEA } = useGetCatalogByAcronymQuery({
    profileId: currentProfile?.profileId!,
    acronym: 'ET',
  });

  // parentesco
  const {
    data: kinship,
    isLoading: isLoadingkinship,
    isFetching: isFetchingkinship,
  } = useGetCatalogByAcronymQuery({
    acronym: 'P',
  });

  //cities avalibles
  const { data: citiesAvailables = [], isLoading: isLoadingCA } = useGetCatalogByAcronymQuery({
    acronym: 'C',
  });

  //nivel educativo

  const { data: availablesTypeEducations = [], isLoading: isLoadingTypeEducations } =
    useGetAvailablesTypeEducationsQuery({});

  const {
    errors,
    touched,
    handleSubmit,
    resetForm,
    setFieldValue,
    getFieldProps,
    values: formikValues,
    // TODO
  } = useFormik<NewRelativesPersonTable>({
    initialValues: {
      id: editData?.id || '',
      pers_firstname: editData?.pers_firstname || '',
      pers_secondname: editData?.pers_secondname || '',
      pers_first_lastname: editData?.pers_first_lastname || '',
      pers_second_lastname: editData?.pers_first_lastname || '',
      pers_email: editData?.pers_email || '',
      type_identification_id: editData?.type_identification_id || 0,
      pers_identification: editData?.pers_identification || '',
      pers_gender: editData?.pers_gender || '',
      status_marital_id: editData?.status_marital_id || 0,
      ethnic_id: editData?.ethnic_id || 0,
      type_religion_id: editData?.type_religion_id || 0,
      type_kinship_id: editData?.type_kinship_id || 0,
      rel_description: editData?.rel_description || '',
      pers_direction: editData?.pers_direction || '',
      per_job_position: editData?.per_job_position || '',
      per_job_organization: editData?.per_job_organization || '',
      type_education_id: editData?.type_education_id || 0,
      city_id: editData?.city_id || 0,
      emergency_contacts: editData?.emergency_contacts || false,
      pers_phone_home: editData?.pers_phone_home || '',
      pers_cell: editData?.pers_cell || '',
    },
    enableReinitialize: true,
    validationSchema: PersonCreateSchema,
    onSubmit: async (values) => {
      try {
        onSave((prev) => {
          if (!editData)
            return [{ ...values, id: `t${new Date().getTime()}${values.id}` }, ...prev];

          const updated = updateItemFromKeyArr(prev, 'id', values.id!, values);
          return [...updated];
        });

        if (!editData) resetForm();

        setShowAlerts(true);
        setIsSuccess(true);
      } catch (error) {
        setShowAlerts(true);
        setIsSuccess(false);
      }
    },
  });

  useEffect(() => {
    if (!formikValues.emergency_contacts) {
      setFieldValue('pers_phone_home', '');
      setFieldValue('pers_cell', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formikValues.emergency_contacts]);

  return (
    <Dialog {...props} classes={{ paper: classes.paper }} sx={{ p: 3 }} onClose={onClose}>
      <Grid container gap={4} sx={{ p: 2 }}>
        <Grid item sm={12} md={12}>
          <form>
            <MainCard title="Datos personales">
              {isSuccess && showAlerts && (
                <Grid container spacing={2} sx={{ mt: 2, mb: 2 }}>
                  <Grid item md={12} xs={12}>
                    <SuccessAlert
                      message="Persona registrada con éxito"
                      handleDismiss={() => setShowAlerts(false)}
                    />
                  </Grid>
                </Grid>
              )}
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <InputLabel>Tipo Identificación: *</InputLabel>
                  {!isFetchingIA && (
                    <Autocomplete
                      options={identificationsAvailables || []}
                      loading={isLoadingIA}
                      loadingText="Cargando identificaciones..."
                      getOptionLabel={(option) => option.cat_name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={Boolean(
                            errors.type_identification_id && touched.type_identification_id
                          )}
                          placeholder="Seleccione una identificación disponible"
                        />
                      )}
                      value={
                        identificationsAvailables?.find(
                          (dt) => dt.id === formikValues.type_identification_id
                        ) || null
                      }
                      onChange={(_, value) => {
                        setFieldValue('type_identification_id', value?.id || null);
                      }}
                      fullWidth
                      clearIcon={null}
                    />
                  )}
                  {isFetchingIA && <TextFieldFetching />}
                  {errors.type_identification_id && touched.type_identification_id && (
                    <FormHelperText error>{errors.type_identification_id}</FormHelperText>
                  )}
                </Grid>
                <Grid item md={6} xs={12}>
                  <InputLabel>Identificación *</InputLabel>
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Número de identificación"
                    error={Boolean(errors.pers_identification && touched.pers_identification)}
                    {...getFieldProps('pers_identification')}
                    // value={formikValues.pers_identification}
                    // onChange={(e) => setFieldValue('pers_identification', e.target.value)}
                  />
                  {errors.pers_identification && touched.pers_identification && (
                    <FormHelperText error>{errors.pers_identification}</FormHelperText>
                  )}
                </Grid>

                <Grid item md={6} xs={12}>
                  <InputLabel>Nombre *</InputLabel>
                  <TextField
                    fullWidth
                    placeholder="Nombre"
                    variant="outlined"
                    error={Boolean(errors.pers_firstname && touched.pers_firstname)}
                    {...getFieldProps('pers_firstname')}
                    // value={formikValues.pers_firstname}
                    // onChange={(e) => setFieldValue('pers_firstname', e.target.value)}
                  />
                  {errors.pers_firstname && touched.pers_firstname && (
                    <FormHelperText error>{errors.pers_firstname}</FormHelperText>
                  )}
                </Grid>
                <Grid item md={6} xs={12}>
                  <InputLabel>Segundo Nombre *</InputLabel>
                  <TextField
                    fullWidth
                    placeholder="Segundo Nombre"
                    variant="outlined"
                    error={Boolean(errors.pers_secondname && touched.pers_secondname)}
                    {...getFieldProps('pers_secondname')}
                    // value={formikValues.pers_secondname}
                    // onChange={(e) => setFieldValue('pers_secondname', e.target.value)}
                  />
                  {errors.pers_secondname && touched.pers_secondname && (
                    <FormHelperText error>{errors.pers_secondname}</FormHelperText>
                  )}
                </Grid>
                <Grid item md={6} xs={12}>
                  <InputLabel>Apellido *</InputLabel>
                  <TextField
                    fullWidth
                    placeholder="Apellido"
                    variant="outlined"
                    error={Boolean(errors.pers_first_lastname && touched.pers_first_lastname)}
                    {...getFieldProps('pers_first_lastname')}
                    // value={formikValues.pers_first_lastname}
                    // onChange={(e) => setFieldValue('pers_first_lastname', e.target.value)}
                  />
                  {errors.pers_first_lastname && touched.pers_first_lastname && (
                    <FormHelperText error>{errors.pers_first_lastname}</FormHelperText>
                  )}
                </Grid>
                <Grid item md={6} xs={12}>
                  <InputLabel>Segundo Apellido *</InputLabel>
                  <TextField
                    id="secondLastName"
                    fullWidth
                    placeholder="Segundo Apellido"
                    variant="outlined"
                    error={Boolean(errors.pers_second_lastname && touched.pers_second_lastname)}
                    {...getFieldProps('pers_second_lastname')}
                    // value={formikValues.pers_second_lastname}
                    // onChange={(e) => setFieldValue('pers_second_lastname', e.target.value)}
                  />
                  {errors.pers_second_lastname && touched.pers_second_lastname && (
                    <FormHelperText error>{errors.pers_second_lastname}</FormHelperText>
                  )}
                </Grid>

                <Grid item xs={12} md={6}>
                  <InputLabel required>{t('Ciudad')}</InputLabel>
                  {isLoadingCA ? (
                    <TextFieldFetching />
                  ) : (
                    <Autocomplete
                      options={citiesAvailables || []}
                      loading={isLoadingCA}
                      loadingText={t('Cargando ciudades...')}
                      noOptionsText={t('No hay ciudades disponibles')}
                      getOptionLabel={(option) => option.cat_name}
                      value={citiesAvailables.find(
                        ({ id }) => formikValues.city_id + '' === id + ''
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={Boolean(errors.city_id && touched.city_id)}
                          placeholder={t('Seleccione una ciudad')}
                        />
                      )}
                      onChange={(_, value) => {
                        setFieldValue('city_id', value?.id || 0);
                      }}
                      fullWidth
                      clearIcon={null}
                    />
                  )}
                  {errors.city_id && touched.city_id && (
                    <FormHelperText error>{t(errors.city_id)}</FormHelperText>
                  )}
                </Grid>

                <Grid item md={6} xs={12}>
                  <InputLabel>Correo electrónico *</InputLabel>
                  <TextField
                    id="email"
                    fullWidth
                    placeholder="Correo electrónico"
                    variant="outlined"
                    error={Boolean(errors.pers_email && touched.pers_email)}
                    {...getFieldProps('pers_email')}
                    // value={formikValues.email}
                    // onChange={(e) => setFieldValue('email', e.target.value)}
                  />
                  {errors.pers_email && touched.pers_email && (
                    <FormHelperText error>{errors.pers_email}</FormHelperText>
                  )}
                </Grid>

                <Grid item md={6} xs={12}>
                  <InputLabel>Etnia:*</InputLabel>
                  {!isLoadingEA && (
                    <Autocomplete
                      options={ethnicsAvailables || []}
                      loading={isLoadingEA}
                      loadingText="Cargando Estados civil..."
                      getOptionLabel={(option) => option.cat_name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={Boolean(errors.ethnic_id && touched.ethnic_id)}
                          placeholder="Seleccione un Estado civil disponible"
                        />
                      )}
                      value={
                        ethnicsAvailables?.find((dt) => dt.id === formikValues.ethnic_id) || null
                      }
                      onChange={(_, value) => {
                        setFieldValue('ethnic_id', value?.id || '');
                      }}
                      fullWidth
                      clearIcon={null}
                    />
                  )}
                  {isLoadingEA && <TextFieldFetching />}
                  {errors.ethnic_id && touched.ethnic_id && (
                    <FormHelperText error>{errors.ethnic_id}</FormHelperText>
                  )}
                </Grid>

                <Grid item md={6} xs={12}>
                  <InputLabel>Religión:*</InputLabel>
                  {!isLoadingRA && (
                    <Autocomplete
                      options={religionsAvailables || []}
                      loading={isLoadingRA}
                      loadingText="Cargando Religiones..."
                      getOptionLabel={(option) => option.cat_name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={Boolean(errors.type_religion_id && touched.type_religion_id)}
                          placeholder="Seleccione una Religión disponible"
                        />
                      )}
                      value={
                        religionsAvailables?.find(
                          (dt) => Number(dt.id) === Number(formikValues.type_religion_id)
                        ) || null
                      }
                      onChange={(_, value) => {
                        setFieldValue('type_religion_id', value?.id || '');
                      }}
                      fullWidth
                      clearIcon={null}
                    />
                  )}
                  {isLoadingRA && <TextFieldFetching />}
                  {errors.type_religion_id && touched.type_religion_id && (
                    <FormHelperText error>{errors.type_religion_id}</FormHelperText>
                  )}
                </Grid>

                <Grid item md={6} xs={12}>
                  <InputLabel>Estado civil:*</InputLabel>
                  {!isLoadingCSA && (
                    <Autocomplete
                      options={civilStatusAvailables || []}
                      loading={isLoadingCSA}
                      loadingText="Cargando Estados civil..."
                      getOptionLabel={(option) => option.cat_name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={Boolean(errors.status_marital_id && touched.status_marital_id)}
                          placeholder="Seleccione un Estado civil disponible"
                        />
                      )}
                      value={
                        civilStatusAvailables?.find(
                          (dt) => dt.id === formikValues.status_marital_id
                        ) || null
                      }
                      onChange={(_, value) => {
                        setFieldValue('status_marital_id', value?.id || '');
                      }}
                      fullWidth
                      clearIcon={null}
                    />
                  )}
                  {isLoadingCSA && <TextFieldFetching />}
                  {errors.status_marital_id && touched.status_marital_id && (
                    <FormHelperText error>{errors.status_marital_id}</FormHelperText>
                  )}
                </Grid>

                <Grid item md={6} xs={12}>
                  <InputLabel>Género *</InputLabel>

                  {!isFetchingGA && (
                    <Autocomplete
                      options={gendersAvailables || []}
                      loading={isLoadingGA}
                      loadingText="Cargando géneros..."
                      getOptionLabel={(option) => option.cat_name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={Boolean(errors.pers_gender && touched.pers_gender)}
                          placeholder="Seleccione un género"
                        />
                      )}
                      value={
                        gendersAvailables?.find((dt) => dt.cat_name === formikValues.pers_gender) ||
                        null
                      }
                      onChange={(_, value) => {
                        setFieldValue('pers_gender', value?.cat_name || null);
                      }}
                      fullWidth
                      clearIcon={null}
                    />
                  )}
                  {isFetchingGA && <TextFieldFetching />}
                  {errors.pers_gender && touched.pers_gender && (
                    <FormHelperText error>{errors.pers_gender}</FormHelperText>
                  )}
                </Grid>

                <Grid item md={6} xs={12}>
                  <InputLabel>Parentesco:*</InputLabel>
                  {!isLoadingkinship && (
                    <Autocomplete
                      options={kinship || []}
                      loading={isFetchingkinship}
                      loadingText="Cargando Parentescos..."
                      getOptionLabel={(option) => option.cat_name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={Boolean(errors.type_kinship_id && touched.type_kinship_id)}
                          placeholder="Seleccione un parentesco"
                        />
                      )}
                      value={kinship?.find((dt) => dt.id === formikValues.type_kinship_id) || null}
                      onChange={(_, value) => {
                        setFieldValue('type_kinship_id', value?.id || null);
                      }}
                      fullWidth
                      clearIcon={null}
                    />
                  )}
                  {isLoadingRA && <TextFieldFetching />}
                  {errors.type_kinship_id && touched.type_kinship_id && (
                    <FormHelperText error>{errors.type_kinship_id}</FormHelperText>
                  )}
                </Grid>

                <Grid item md={6} xs={12}>
                  <InputLabel>Empresa</InputLabel>
                  <TextField
                    fullWidth
                    placeholder="Ingrese el nombre de la empresa en la que trabaja"
                    variant="outlined"
                    {...getFieldProps('per_job_organization')}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <InputLabel>Cargo</InputLabel>
                  <TextField
                    fullWidth
                    placeholder="Ingrese el cargo que desempeña en la empresa"
                    variant="outlined"
                    {...getFieldProps('per_job_position')}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <InputLabel>Domicilio</InputLabel>
                  <TextField
                    fullWidth
                    placeholder="Ingrese el cargo que desempeña en la empresa"
                    variant="outlined"
                    {...getFieldProps('pers_direction')}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputLabel>{t('Nivel educativo')}</InputLabel>
                  <Autocomplete
                    options={availablesTypeEducations}
                    loading={isLoadingTypeEducations}
                    loadingText={t('Cargando tipo de instituciones')}
                    noOptionsText={t('No hay tipo de instituciones disponibles')}
                    getOptionLabel={(option) => option.typ_edu_name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={Boolean(errors.type_education_id && touched.type_education_id)}
                        placeholder={t('Seleccione un nivel educativo')}
                      />
                    )}
                    value={
                      availablesTypeEducations?.find(
                        ({ id }) => id === formikValues.type_education_id
                      ) || null
                    }
                    onChange={(_, value) => {
                      setFieldValue('type_education_id', value?.id || 0);
                    }}
                    fullWidth
                    clearIcon={null}
                  />
                  {errors.type_education_id && touched.type_education_id && (
                    <FormHelperText error>{errors.type_education_id}</FormHelperText>
                  )}
                </Grid>

                <Grid item xs={12} md={6} alignItems="center">
                  <FormControlLabel
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      height: '100%',
                    }}
                    control={
                      <Checkbox
                        checked={formikValues.emergency_contacts}
                        onChange={(e) => {
                          setFieldValue('emergency_contacts', e.target.checked);
                        }}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    }
                    label="¿Es contacto de emergencia?"
                  />
                </Grid>

                {formikValues.emergency_contacts && (
                  <>
                    <Grid item xs={12} md={6}>
                      <InputLabel>{t('Telefono')}</InputLabel>
                      <TextField
                        {...getFieldProps('pers_phone_home')}
                        error={Boolean(errors.pers_phone_home && touched.pers_phone_home)}
                        placeholder={t('Ingrese el telefono')}
                        fullWidth
                      />
                      {errors.pers_phone_home && touched.pers_phone_home && (
                        <FormHelperText error>{errors.pers_phone_home}</FormHelperText>
                      )}
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <InputLabel>{t('Celular')}</InputLabel>
                      <TextField
                        {...getFieldProps('pers_cell')}
                        error={Boolean(errors.pers_cell && touched.pers_cell)}
                        placeholder={t('Ingrese el celular')}
                        fullWidth
                      />
                      {errors.pers_cell && touched.pers_cell && (
                        <FormHelperText error>{errors.pers_cell}</FormHelperText>
                      )}
                    </Grid>
                  </>
                )}

                <Grid item xs={12}>
                  <InputLabel>{t('Descripción de relación')}</InputLabel>
                  <TextField
                    fullWidth
                    multiline
                    rows={2}
                    variant="outlined"
                    placeholder="Descripción de relación"
                    error={Boolean(errors.rel_description && touched.rel_description)}
                    {...getFieldProps('rel_description')}
                  />
                  {errors.rel_description && touched.rel_description && (
                    <FormHelperText error>{errors.rel_description}</FormHelperText>
                  )}
                </Grid>

                <Grid container item sx={{ justifyContent: 'right' }}>
                  <Grid item>
                    <Button
                      onClick={() => handleSubmit()}
                      // disabled={isLoading}
                      endIcon={false && <CircularProgress size={20} />}
                      variant="contained"
                      color="primary"
                    >
                      Guardar
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </MainCard>
          </form>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default NewPersonRelativeModal;
