export const subPermissionKeys = {
  groupKey: 'events',

  readEvents: 'events-listar-eventos',
  storeEvent: 'events-crear-evento',
  updateEvent: 'events-actualizar-evento',
  destroyEvent: 'events-eliminar-evento',

  showEventsProfile: 'profiles-mostrar-eventos',
  updatePhotoByEvent: 'events-actualizar-evento-photo',
};
