export const days = [
  {
    keyword: 'monday',
    label: 'Lunes',
  },
  {
    keyword: 'tuesday',
    label: 'Martes',
  },
  {
    keyword: 'wednesday',
    label: 'Miércoles',
  },
  {
    keyword: 'thursday',
    label: 'Jueves',
  },
  {
    keyword: 'friday',
    label: 'Viernes',
  },
  {
    keyword: 'saturday',
    label: 'Sabado',
  },
  {
    keyword: 'sunday',
    label: 'Domingo',
  },
];
