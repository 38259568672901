import { Checkbox, CircularProgress } from '@material-ui/core';
import usePaginationAndSearch from '../../follow-up-of-research-project/hooks/usePaginationAndSearch';
import {
  useDeleteCollaboratorByProjectMutation,
  useGetCollaboratorsByProjectIdQuery,
  useGetCollaboratorsByResearchProjectAvailablesQuery,
} from '../slices/InvestigationLinesApiSlice';
import SearchPaginatedTableAndCollapsibleItems from '../../../shared/components/tables/SearchPaginatedTableAndCollapsibleItems';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../hooks/useRedux';
import { useFormikContext } from 'formik';
import { CollaboratorPayloadProject } from '../interfaces/collaboratorPayloadProject.interface';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import { useEffect, useState } from 'react';

const CollaboratorTableSelected = () => {
  const { t } = useTranslation();

  const { isLoadingAddCollaborator } = useAppSelector((state) => state.investigationLines);

  const [collaboratorSeleted, setCollaboratorSeleted] = useState('');
  const [showAlert, setShowAlert] = useState(false);

  const { projectIdSelected } = useAppSelector((state) => state.investigationLines);

  const { setFieldValue, handleSubmit, errors } = useFormikContext<CollaboratorPayloadProject>();

  const { page, perPage, search, setPage, setPerPage, setSearch } = usePaginationAndSearch(1, 10);

  const {
    data: collaboratorsList,
    isLoading,
    isFetching,
    error,
  } = useGetCollaboratorsByProjectIdQuery({
    project_id: projectIdSelected!,
    page,
    size: perPage,
    search,
    sort: 'pers_full_name',
    type_sort: 'asc',
  });

  const { data: collaboratorAvailables = [] } = useGetCollaboratorsByResearchProjectAvailablesQuery(
    {
      projectId: projectIdSelected!,
    }
    // { skip: type !== 'collaborator' }
  );

  const [
    deleteCollaborador,
    { error: errorDeleteCollaborator, isError, isSuccess, isLoading: isLoadingDelete },
  ] = useDeleteCollaboratorByProjectMutation();

  const handleDeleteCollaborator = async (id: string) => {
    await deleteCollaborador({
      collaboratorProjectResearchId: id,
    });
  };

  const datafetch =
    collaboratorsList?.data?.map((item, idx) => ({
      numberItem: (page - 1) * perPage + (idx + 1),
      chck: item,
      name: item.pers_full_name,
    })) || [];

  useEffect(() => {
    if (errors) {
      setShowAlert(true);
    }
  }, [errors]);

  useEffect(() => {
    if (showAlert) {
      setShowAlert(true);
    }
  }, [showAlert]);

  useEffect(() => {
    if (isSuccess) {
      setShowAlert(true);
    }
  }, [isSuccess]);

  return (
    <>
      {errors && Object.values(errors).length > 0 && showAlert && (
        <ErrorAlert
          message={Object.values(errors)
            .map((item) => item)
            .join(', ')}
        />
      )}
      {isError && showAlert && (
        <ErrorAlert
          message={errorDeleteCollaborator as any}
          handleDismiss={() => setShowAlert(false)}
        />
      )}

      {isSuccess && showAlert && (
        <SuccessAlert
          message={t('Docente eliminado correctamente del proyecto')}
          handleDismiss={() => setShowAlert(false)}
        />
      )}

      <SearchPaginatedTableAndCollapsibleItems
        data={collaboratorAvailables && datafetch.length > 0 ? datafetch : []}
        headers={{
          numberItem: t('N°'),
          chck: t('Seleccionar'),
          name: t('maintenance.questions.name'),
        }}
        keyExtractor={(item) => item.name}
        searchPlacehoder={t('Buscar colaborador...')}
        isLoading={isLoading}
        isFetching={isFetching}
        error={error as any}
        perPage={perPage}
        setPerPage={setPerPage}
        total={collaboratorsList?.total || 0}
        page={page}
        setPage={setPage}
        numHeader={6}
        search={search}
        setSearch={setSearch}
        customHeadersCellsProperties={{
          name: {
            align: 'center',
          },
          chck: {
            align: 'center',
          },
          numberItem: {
            align: 'center',
          },
        }}
        customDataCellsProperties={{
          name: {
            align: 'center',
          },
          chck: {
            align: 'center',
          },
          numberItem: {
            align: 'center',
          },
        }}
        customRenderers={{
          chck: (collaborator) => (
            <>
              {(isLoadingDelete || isLoadingAddCollaborator) &&
              collaborator.chck.collaborator_id === collaboratorSeleted ? (
                <CircularProgress size={20} />
              ) : (
                <Checkbox
                  checked={
                    collaboratorAvailables?.find(
                      (item) => item.collaborator_id === collaborator.chck.collaborator_id
                    ) !== undefined
                  }
                  onChange={async (event) => {
                    setCollaboratorSeleted(collaborator.chck.collaborator_id);
                    const isCollaboratorSelected = collaboratorAvailables?.find(
                      (item) => item.collaborator_id === collaborator.chck.collaborator_id
                    );
                    if (isCollaboratorSelected) {
                      handleDeleteCollaborator(
                        isCollaboratorSelected.collaborator_project_research_id
                      );
                    } else {
                      await setFieldValue('collaboratorId', {
                        id: collaborator.chck.collaborator_id,
                      });
                      await handleSubmit();
                    }
                  }}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              )}
            </>
          ),
        }}
      />
    </>
  );
};

export default CollaboratorTableSelected;
