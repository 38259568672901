import {
  Button,
  Grid,
  InputLabel,
  TextField,
  CircularProgress,
  InputAdornment,
} from '@material-ui/core';
import { DatePicker, LocalizationProvider } from '@material-ui/lab';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import esLocale from 'date-fns/locale/es';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useAppSelector } from '../../../../../hooks/useRedux';
import { uploadFilesApi } from '../../../../shared/slices/uploadFilesApi';
import { format } from 'date-fns';
import ErrorAlert from '../../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../../shared/components/alerts/SuccessAlert';
import { isBefore } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { useAddBookMutation, useEditBookMutation } from '../../../books/slices/booskApiSlice';

interface props {
  isActive: boolean;
  handleBack: () => void;
}
const Step4 = ({ handleBack, isActive }: props) => {
  const { bookToEdit, bookBodyForm } = useAppSelector((state) => state.books);
  const navigation = useHistory();

  const [uploadFile, { isLoading: isLoadingUpload }] = uploadFilesApi.useUploadFilesMutation();
  const [
    addBook,
    {
      isSuccess: isSuccessAdd,
      isError: isErrorAdd,
      error: errorAdd,
      isLoading: isLoadingAdd,
      reset,
    },
  ] = useAddBookMutation();

  const [
    editBook,
    {
      isSuccess: isSuccessEdit,
      isError: isErrorEdit,
      error: errorEdit,
      isLoading: isLoadingEdit,
      reset: resetEdit,
    },
  ] = useEditBookMutation();

  const currency_regex = /^[0-9]\d*(((,\d{3}){1})?(\.\d{0,2})?)$/;

  const schema = yup.object().shape({
    isEdit: yup.boolean(),
    num_factura: yup.string(),
    name_factura: yup.string(),
    date_factura: yup.string(),
    cost: yup.string().matches(currency_regex, 'Ingrese un valor correcto'),
    //.required('Costo es requerido'),
    total: yup.string(),
  });

  const { handleSubmit, setFieldValue, values, errors, touched } = useFormik({
    initialValues: {
      isEdit: bookToEdit ? true : false,
      num_factura: '',
      name_factura: '',
      date_factura: '',
      cost: '0',
      total: '',
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      let book_cover: string | undefined;
      let file_url: string | undefined;

      if (bookBodyForm && bookBodyForm.cover && bookBodyForm.cover.length > 0) {
        const res = await uploadFile({
          files: bookBodyForm!.cover[0],
        });
        if (res && 'error' in res) {
          return;
        }
        book_cover = res?.data;
      }

      if (bookBodyForm && bookBodyForm.file && bookBodyForm.file.length > 0) {
        const res = await uploadFile({
          files: bookBodyForm!.file[0],
        });
        if (res && 'error' in res) {
          return;
        }
        file_url = res?.data;
      }

      if (bookToEdit) {
        editBook({
          id: bookToEdit.id!,
          payload: {
            title: bookBodyForm!.title!,
            subtitle: bookBodyForm!.subtitle!,
            isbn: bookBodyForm!.isbn!,
            version: bookBodyForm!.version!,
            initial_copies_number: bookBodyForm!.initial_copies!,
            origin: bookBodyForm!.origin!,
            book_cover: book_cover,
            book_url: file_url,
            institution_barcode: bookBodyForm!.codebar!,
            symbol: undefined,
            publish_date: bookBodyForm!.publish_date!,
            type_book: bookBodyForm!.type_document!,
            status_id: bookBodyForm!.status!,
            reason: bookBodyForm!.reason!,
            procedencia: bookBodyForm!.procedency!,
            description: bookBodyForm!.description!,
            page_count: bookBodyForm!.num_pag!,
            serie: bookBodyForm!.serie!,
            content_notes: bookBodyForm!.content_notes!,
            bib_clasification_id: bookBodyForm!.classification_child!,
            line_research_id: bookBodyForm!.line!,
            invoice: values.date_factura
              ? {
                  num_factura: values!.num_factura!,
                  proveedor_factura: values!.name_factura!,
                  date: values.date_factura
                    ? format(new Date(values.date_factura), 'yyyy-MM-dd')
                    : format(new Date(), 'yyyy-MM-dd'),
                  precio: values!.cost!,
                  total: Number(values!.total!),
                }
              : undefined,

            locations: [Number(bookBodyForm!.location)],
            tags: [
              ...bookBodyForm!.authors!.map((a) => Number(a)),
              Number(bookBodyForm!.city!),
              Number(bookBodyForm!.editorial!),
              Number(bookBodyForm!.idiom!),
            ],
            book_inventory: bookBodyForm?.inventory || '',
            book_collection: bookBodyForm?.collection || '',
          },
        });
      } else {
        addBook({
          payload: {
            title: bookBodyForm!.title!,
            subtitle: bookBodyForm!.subtitle!,
            isbn: bookBodyForm!.isbn!,
            version: bookBodyForm!.version!,
            initial_copies_number: bookBodyForm!.initial_copies!,
            origin: bookBodyForm!.origin!,
            book_cover: book_cover,
            book_url: file_url,
            institution_barcode: bookBodyForm!.codebar!,
            symbol: undefined,
            publish_date: bookBodyForm!.publish_date!,
            type_book: bookBodyForm!.type_document!,
            status_id: bookBodyForm!.status!,
            reason: bookBodyForm!.reason!,
            procedencia: bookBodyForm!.procedency!,
            description: bookBodyForm!.description!,
            page_count: bookBodyForm!.num_pag!,
            serie: bookBodyForm!.serie!,
            content_notes: bookBodyForm!.content_notes!,
            bib_clasification_id: bookBodyForm!.classification_child!,
            line_research_id: bookBodyForm!.line!,
            invoice: values.date_factura
              ? {
                  num_factura: values!.num_factura!,
                  proveedor_factura: values!.name_factura!,
                  date: values.date_factura
                    ? format(new Date(values.date_factura), 'yyyy-MM-dd')
                    : format(new Date(), 'yyyy-MM-dd'),
                  precio: values!.cost!,
                  total: Number(values!.total!),
                }
              : undefined,

            locations: [Number(bookBodyForm!.location)],
            tags: [
              ...bookBodyForm!.authors!.map((a) => Number(a)),
              Number(bookBodyForm!.city!),
              Number(bookBodyForm!.editorial!),
              Number(bookBodyForm!.idiom!),
            ],
            book_inventory: bookBodyForm?.inventory || '',
            book_collection: bookBodyForm?.collection || '',
          },
        });
      }
    },
  });

  const handleFormSubmit = () => {
    handleSubmit();
  };

  useEffect(() => {
    setFieldValue('total', String(Number(bookBodyForm?.initial_copies) * Number(values.cost)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookBodyForm?.initial_copies]);

  if (!isActive) {
    return <div />;
  }

  return (
    <>
      <Grid item xs={12} md={6}>
        <InputLabel>Número de Factura</InputLabel>
        <TextField
          fullWidth
          placeholder="Ingrese Número de Factura"
          value={values.num_factura}
          error={Boolean(errors.num_factura && touched.num_factura)}
          helperText={Boolean(errors.num_factura && touched.num_factura) ? errors.num_factura : ''}
          onChange={({ target }) => setFieldValue('num_factura', target.value)}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <InputLabel>Nombre del Proveedor</InputLabel>
        <TextField
          fullWidth
          placeholder="Ingrese Nombre del Proveedor"
          value={values.name_factura}
          error={Boolean(errors.name_factura && touched.name_factura)}
          helperText={
            Boolean(errors.name_factura && touched.name_factura) ? errors.name_factura : ''
          }
          onChange={({ target }) => setFieldValue('name_factura', target.value)}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <InputLabel>Fecha de Factura</InputLabel>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
          <DatePicker
            value={values.date_factura}
            onChange={(newValue) => {
              setFieldValue('date_factura', newValue);
            }}
            shouldDisableDate={(date) => isBefore(new Date(), new Date(date))}
            renderInput={(params) => (
              <TextField
                {...params}
                error={false}
                helperText=""
                placeholder="Ingrese fecha de factura"
                fullWidth
              />
            )}
          />
        </LocalizationProvider>
      </Grid>

      <Grid item xs={12} md={6}>
        <InputLabel>Precio</InputLabel>
        <TextField
          fullWidth
          placeholder="Ingrese Precio"
          type="number"
          value={values.cost}
          InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
          error={Boolean(errors.cost && touched.cost)}
          helperText={Boolean(errors.cost && touched.cost) ? errors.cost : ''}
          onChange={async ({ target }) => {
            await setFieldValue('cost', target.value);
            await setFieldValue(
              'total',
              Number(target.value) * Number(bookBodyForm ? String(bookBodyForm!.initial_copies) : 0)
            );
          }}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <InputLabel>Total</InputLabel>
        <TextField
          fullWidth
          value={values.total}
          disabled
          InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        {isErrorAdd && <ErrorAlert message={String(errorAdd)} handleDismiss={reset} />}
        {isErrorEdit && <ErrorAlert message={String(errorEdit)} handleDismiss={reset} />}
        {isSuccessAdd && (
          <SuccessAlert message="Libro creado correctamente" handleDismiss={reset} />
        )}
        {isSuccessEdit && (
          <SuccessAlert message="Libro editado correctamente" handleDismiss={resetEdit} />
        )}
      </Grid>
      <Grid item container xs={12} md={12} justifyContent="flex-end">
        <Button
          variant="outlined"
          disabled={isSuccessAdd || isSuccessEdit}
          onClick={handleBack}
          size="large"
        >
          Anterior
        </Button>{' '}
        &nbsp;&nbsp;
        <Button
          variant="contained"
          disabled={
            isLoadingUpload || isLoadingAdd || isSuccessAdd || isLoadingEdit || isSuccessEdit
          }
          onClick={handleFormSubmit}
          size="large"
          endIcon={
            isLoadingUpload || isLoadingEdit || (isLoadingAdd && <CircularProgress size={20} />)
          }
        >
          Guardar
        </Button>
        &nbsp;&nbsp;
        {isSuccessAdd ||
          (isSuccessEdit && (
            <Button variant="contained" onClick={() => navigation.goBack()} size="large">
              Volver a lista de libros
            </Button>
          ))}
      </Grid>
    </>
  );
};

export default Step4;
