import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
} from '@material-ui/core';
import { DatePicker, LocalizationProvider } from '@material-ui/lab';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';

import { useAppSelector } from '../../../../hooks/useRedux';
import { useGetCatalogByAcronymQuery } from '../../../shared/slices/catalogApi';
import { Catalog } from '../../../shared/interfaces/catalogs.interfaces';
import { useGetAvailablesCampusQuery } from '../../campus/slices/campusApiSlice';
import { useUpdateEmissionPointMutation } from '../slices/financialConfigurationApiSlice';
import { EmissionPointSchema } from '../constans/validationSchemas';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import { useHistory, useParams } from 'react-router-dom';
import TextFieldFetching from '../../../shared/components/TextFieldFetching';
import { useGetLocaleDateLang } from '../../../../translation/hooks/useGetLocaleDateLang';

const EditEmissionPointForm = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { lang } = useGetLocaleDateLang();

  const { currentProfile } = useAppSelector((state) => state.access);
  const profileId = currentProfile!.profileId;
  const { emissionPointList } = useAppSelector((state) => state.financialConfigurations);
  const { emissionPointId } = useParams<{ emissionPointId: string }>();
  const [electronicDocuments, setElectronicDocuments] = useState<Catalog[]>([]);
  const [showAlerts, setShowAlerts] = useState(false);

  const epToEdit = emissionPointList.find((epl) => epl.id === Number(emissionPointId));

  const { data: electronicDocumentsAvaliables = [], isLoading: isLoadingEDA } =
    useGetCatalogByAcronymQuery({
      profileId,
      acronym: 'tce',
    });
  const { data: campus = [], isLoading: isLoadingC } = useGetAvailablesCampusQuery({
    profileId,
  });
  const [updateEmissionPoint, { isLoading, isSuccess, isError, error }] =
    useUpdateEmissionPointMutation();

  const { handleSubmit, errors, touched, values, setFieldValue, getFieldProps, initialValues } =
    useFormik({
      initialValues: {
        emission_point: epToEdit?.emission_point || '',
        stablishment_point: epToEdit?.stablishment_point || '',
        secuencial: epToEdit?.secuencial || '',
        is_manual: false,
        effective_date: epToEdit?.effective_date || new Date(),
        expiration_date: epToEdit?.expiration_date || new Date(),
        start_num: 0,
        end_num: 0,
        authorization_num: epToEdit?.authorization_num || '',
        type_document_keyword: epToEdit?.type_document.cat_keyword || '',
        campus_id: epToEdit?.campus.id || 0,
      },
      validationSchema: EmissionPointSchema,
      onSubmit: async (value) => {
        await updateEmissionPoint({
          profileId: currentProfile?.profileId!,
          emissionPointId: epToEdit?.id!,
          emissionPointPayload: {
            emission_point: value.emission_point,
            stablishment_point: value.stablishment_point,
            secuencial: value.secuencial,
            is_manual: value.is_manual,
            effective_date: value.effective_date,
            expiration_date: value.expiration_date,
            start_num: value.start_num,
            end_num: value.end_num,
            authorization_num: value.authorization_num,
            type_document_keyword: value.type_document_keyword,
            campus_id: value.campus_id,
          },
        });
        setShowAlerts(true);
      },
    });

  useEffect(() => {
    if (!isLoadingEDA && electronicDocumentsAvaliables && electronicDocuments.length === 0) {
      const electronicDocumentSelected = electronicDocumentsAvaliables.filter(
        (ea) => ea.cat_keyword === 'fact' || ea.cat_keyword === 'nota-cred'
      );
      setElectronicDocuments(electronicDocumentSelected);
    }
  }, [isLoadingEDA, electronicDocumentsAvaliables, electronicDocuments]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFieldValue('is_manual', e.target.checked);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction="column" gap={2}>
        <Grid item>
          {isError && showAlerts && (
            <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
          )}
          {/* {isErrorSAC && showAlerts && (
        <ErrorAlert message={errorSAC as string} handleDismiss={() => setShowAlerts(false)} />
      )} */}

          {isSuccess && showAlerts && (
            <SuccessAlert
              message={t('financial.emission_point.edit.success_alert')}
              handleDismiss={() => setShowAlerts(false)}
            />
          )}
        </Grid>
        <Grid item container spacing={2} alignContent="center">
          <Grid item xs={6} md={6}>
            <InputLabel>{t('financial.emission_point.add.establishment_code_label')}</InputLabel>
            <TextField
              fullWidth
              InputProps={{ inputProps: { min: 0 } }}
              error={Boolean(errors.stablishment_point && touched.stablishment_point)}
              placeholder={t('financial.emission_point.add.establishment_code_placeholder')}
              {...getFieldProps('stablishment_point')}
            />
            {errors.stablishment_point && touched.stablishment_point && (
              <FormHelperText error>{t(errors.stablishment_point)}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={6} md={6}>
            <InputLabel>{t('financial.emission_point.add.emission_point_code_label')}</InputLabel>
            <TextField
              fullWidth
              InputProps={{ inputProps: { min: 0 } }}
              error={Boolean(errors.emission_point && touched.emission_point)}
              placeholder={t('financial.emission_point.add.emission_point_code_placeholder')}
              {...getFieldProps('emission_point')}
            />
            {errors.emission_point && touched.emission_point && (
              <FormHelperText error>{t(errors.emission_point)}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={6} md={6}>
            <InputLabel>{t('financial.emission_point.add.doc_type_label')}</InputLabel>
            {isLoadingEDA ? (
              <TextFieldFetching />
            ) : (
              <Autocomplete
                options={electronicDocuments ? electronicDocuments : []}
                //loading={isLoadingP}
                defaultValue={electronicDocumentsAvaliables.find(
                  (ed) => ed.id === Number(epToEdit?.type_document_id)
                )}
                getOptionLabel={(option) => option.cat_name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(errors.type_document_keyword && touched.type_document_keyword)}
                    placeholder={t('financial.emission_point.add.doc_type_placeholder')}
                  />
                )}
                onChange={(_, value) => {
                  setFieldValue('type_document_keyword', value?.cat_keyword!);
                }}
                fullWidth
                clearIcon={null}
              />
            )}
            {errors.type_document_keyword && touched.type_document_keyword && (
              <FormHelperText error>{t(errors.type_document_keyword)}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={6} md={6}>
            <InputLabel>{t('financial.emission_point.add.last_sequential_label')}</InputLabel>
            <TextField
              fullWidth
              disabled
              InputProps={{ inputProps: { min: 0 } }}
              error={Boolean(errors.secuencial && touched.secuencial)}
              placeholder={t('financial.emission_point.add.last_sequential_placeholder')}
              {...getFieldProps('secuencial')}
            />
            {errors.secuencial && touched.secuencial && (
              <FormHelperText error>{t(errors.secuencial)}</FormHelperText>
            )}
          </Grid>
          {false && (
            <Grid item xs={6} md={6} sx={{ verticalAlign: 'center' }}>
              <InputLabel>{t('financial.emission_point.add.config_sequential_label')}</InputLabel>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={handleChange}
                    defaultChecked={initialValues.is_manual}
                    id="isManual"
                  />
                }
                label={t('financial.emission_point.add.config_sequential_check_label')}
                labelPlacement="end"
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <InputLabel>{t('financial.emission_point.add.authorization_num_label')}</InputLabel>
            <TextField
              fullWidth
              InputProps={{ inputProps: { min: 0 } }}
              error={Boolean(errors.authorization_num && touched.authorization_num)}
              placeholder={t('financial.emission_point.add.authorization_num_placeholder')}
              {...getFieldProps('authorization_num')}
            />
            {errors.authorization_num && touched.authorization_num && (
              <FormHelperText error>{t(errors.authorization_num)}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={6}>
            <InputLabel>{t('financial.emission_point.add.emission_date_label')}</InputLabel>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={lang}>
              <DatePicker
                value={values.effective_date}
                onChange={(newValue) => {
                  setFieldValue('effective_date', newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    helperText=""
                    placeholder={t('financial.emission_point.add.emission_date_placeholder')}
                    error={Boolean(errors.effective_date && touched.effective_date)}
                    fullWidth
                  />
                )}
                minDate={new Date()}
              />
            </LocalizationProvider>
            {errors.effective_date && touched.effective_date && (
              <FormHelperText error>{t(errors.effective_date as string)}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={6}>
            <InputLabel>{t('financial.emission_point.add.expiration_date_label')}</InputLabel>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={lang}>
              <DatePicker
                value={values.expiration_date}
                onChange={(newValue) => {
                  setFieldValue('expiration_date', newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    helperText=""
                    placeholder={t('financial.emission_point.add.expiration_date_placeholder')}
                    error={Boolean(errors.expiration_date && touched.expiration_date)}
                    fullWidth
                  />
                )}
                minDate={values.effective_date ? new Date(values.effective_date) : new Date()}
              />
            </LocalizationProvider>
            {errors.expiration_date && touched.expiration_date && (
              <FormHelperText error>{t(errors.expiration_date as string)}</FormHelperText>
            )}
          </Grid>
          {false && (
            <>
              <Grid item xs={6} md={6}>
                <InputLabel>{t('financial.emission_point.add.start_from_label')}</InputLabel>
                <TextField
                  fullWidth
                  type="number"
                  InputProps={{ inputProps: { min: 0 } }}
                  error={Boolean(errors.start_num && touched.start_num)}
                  placeholder={t('financial.emission_point.add.start_from_placeholder')}
                  {...getFieldProps('start_num')}
                />
                {errors.start_num && touched.start_num && (
                  <FormHelperText error>{t(errors.start_num as string)}</FormHelperText>
                )}
              </Grid>
              <Grid item xs={6} md={6}>
                <InputLabel>{t('financial.emission_point.add.ends_in_label')}</InputLabel>
                <TextField
                  fullWidth
                  type="number"
                  InputProps={{ inputProps: { min: 0 } }}
                  error={Boolean(errors.end_num && touched.end_num)}
                  placeholder={t('financial.emission_point.add.ends_in_placeholder')}
                  {...getFieldProps('end_num')}
                />
                {errors.end_num && touched.end_num && (
                  <FormHelperText error>{t(errors.end_num as string)}</FormHelperText>
                )}
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <InputLabel>{t('financial.emission_point.add.branch_office_label')}</InputLabel>
            {isLoadingC ? (
              <TextFieldFetching />
            ) : (
              <Autocomplete
                options={Array.isArray(campus) ? campus : []}
                loading={isLoadingC}
                getOptionLabel={(option) => option.cam_name}
                defaultValue={
                  campus.find(
                    (c) =>
                      c.id === Number(epToEdit?.campus_id) || c.id === Number(epToEdit?.campus?.id)
                  ) || null
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(errors.campus_id && touched.campus_id)}
                    placeholder={t('financial.emission_point.add.branch_office_placeholder')}
                  />
                )}
                onChange={(_, value) => {
                  setFieldValue('campus_id', value?.id!);
                }}
                fullWidth
                clearIcon={null}
              />
            )}
            {errors.campus_id && touched.campus_id && (
              <FormHelperText error>{t(errors.campus_id)}</FormHelperText>
            )}
          </Grid>
        </Grid>
        <Grid
          item
          container
          alignItems="center"
          justifyContent="flex-end"
          spacing={2}
          sx={{ mt: 1 }}
        >
          <Grid item>
            <Button
              variant="outlined"
              size="large"
              color="primary"
              onClick={() => history.goBack()}
            >
              {t('shared.go_back_button.label')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              disabled={isLoading}
              endIcon={isLoading && <CircularProgress size={20} />}
            >
              {t('shared.update_button.label')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};
export default EditEmissionPointForm;
