import { Box, Typography, useTheme } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import AddIconButton from '../../../../../../shared/components/buttons/AddIconButton';

import LinesInvestigactionSecundaryItem from './LinesInvestigactionSecundaryItem';
import { EducationLevel } from '../../../../../education-levels/interfaces/educationLevels.interfaces';

interface Props {
  facultadAvailables: EducationLevel[] | undefined;
  isLoadingFacultad: boolean;
}

const LinesInvestigationSecundary = ({ facultadAvailables, isLoadingFacultad }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { values, setFieldValue } = useFormikContext<{
    cityId: number;
    provinceId: number;
    educationLevels: {
      id: number | string | null;
      education_level_id: number;
      is_principal: boolean;
    }[];
    educationLevelsPrincipal: string | undefined;
    linesGroupResearch: string[];
    linesGroupResearchPrincipal: string | undefined;
    investigationLines: string[] | undefined;
    investigationLinesPrincipal: string | undefined;
    typeResearch: string;
  }>();

  const [aditionalLines, setAditionalLines] = useState([
    {
      educationLevelId: '',
      linesId: '',
    },
  ]);

  const handleAddAditional = () => {
    const newAditionalLines = [...aditionalLines, { educationLevelId: '', linesId: '' }];

    setAditionalLines(newAditionalLines);
  };

  const handleRemoveAditional = (index: number) => {
    const isDataDelete = aditionalLines[index];

    if (isDataDelete) {
      const newAditionalLines = aditionalLines.filter((_, i) => i !== index);

      if (isDataDelete.educationLevelId !== values?.educationLevelsPrincipal) {
        setFieldValue(
          `educationLevels`,
          values?.educationLevels?.filter(
            (e) => e.education_level_id !== Number(isDataDelete.educationLevelId)
          )
        );
      }
      setFieldValue(
        `investigationLines`,
        values?.investigationLines?.filter((e) => e !== isDataDelete.linesId.toString())
      );

      setAditionalLines(newAditionalLines);
    }
  };

  return (
    <Box
      sx={{
        paddingTop: '16px',
        paddingLeft: '16px',
        width: '100%',
      }}
    >
      <Box
        alignItems="center"
        sx={{
          width: '100%',
          border: `1px solid ${theme.palette.primary.main}`,
          borderRadius: '8px',
          padding: '25px',
          marginTop: '15px',
        }}
      >
        <Typography variant="h5" color="inherit" mb={2.5}>
          {t('Líneas  de investigación secundarias')}
          <AddIconButton btnText="Agregar opción" onClick={handleAddAditional} />
        </Typography>

        {aditionalLines.map((item, index) => (
          <LinesInvestigactionSecundaryItem
            idx={index}
            item={item}
            handleRemoveAditional={handleRemoveAditional}
            aditionalLines={aditionalLines}
            setAditionalLines={setAditionalLines}
            facultadAvailables={facultadAvailables}
            isLoadingFacultad={isLoadingFacultad}
          />
        ))}
      </Box>
    </Box>
  );
};

export default LinesInvestigationSecundary;
