import { Grid, Typography } from '@material-ui/core';
import { useAppSelector } from '../../../../hooks/useRedux';
import MainCard from '../../../shared/components/cards/MainCard';
import PlanificationListTable from '../components/PlanificationListTable';
import ApprovePlanificationDialog from '../components/dialogs/ApprovePlanificationDialog';
import DeleteProgramDialog from '../components/dialogs/DeleteProgramDialog';
import { useTranslation } from 'react-i18next';

const PlanificationListScreen = () => {
  const { t } = useTranslation();

  const { postgraduateDeleteProgramDialogOpen, postgraduatePlanificationApproveDialogOpen } =
    useAppSelector((state) => state.academicPlanningPosgraduate);

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">{t('posgraduate.table.title')}</Typography>
      </Grid>

      <PlanificationListTable />

      {postgraduatePlanificationApproveDialogOpen && <ApprovePlanificationDialog />}
      {postgraduateDeleteProgramDialogOpen && <DeleteProgramDialog />}
    </Grid>
  );
};

export default PlanificationListScreen;
