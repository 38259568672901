import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import MainCard from '../../../shared/components/cards/MainCard';
import AcademicCounselorsTable from '../components/AcademicCounselorsTable';

const AcademicCounselorsScreen = () => {
  const { t } = useTranslation();

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">{t('academic.academic_counselors.title')}</Typography>
      </Grid>

      <AcademicCounselorsTable />
    </Grid>
  );
};

export default AcademicCounselorsScreen;
