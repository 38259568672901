import {
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Table,
  useTheme,
  Grid,
  InputLabel,
  Typography,
  Button,
} from '@material-ui/core';
import { RemoveRedEye } from '@material-ui/icons';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useGetUrlDownload from '../../../../../../hooks/useGetUrlDownload';
import { useAppSelector } from '../../../../../../hooks/useRedux';
import { ModalDocuments } from './ModalDocuments';

const HistoricalCalifications = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { formUserSelected } = useAppSelector((state) => state.formsEva);
  const { urlDownload } = useGetUrlDownload();

  const [showDocument, setShowDocument] = useState(false);

  const [documentData, setDocumentData] = useState({
    url: '',
    name: '',
  });

  return (
    <>
      <TableContainer component={Paper} sx={{ border: 1 }}>
        <Table aria-label="simple table">
          <TableHead sx={{ backgroundColor: theme.palette.primary[300] }}>
            <TableRow>
              <TableCell align="center">{t('Nombre de Evaluacion')}</TableCell>
              <TableCell align="center">{t('Calificacion')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {formUserSelected?.eva_formulary_details.map((item) => (
              <TableRow key={item.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell align="center">
                  {item.eva_rubrica_detail?.eva_types?.name || 'Sin Observaciones'}
                </TableCell>
                <TableCell align="center">{Number(item.form_value).toFixed(2)}</TableCell>
              </TableRow>
            ))}
            {formUserSelected?.prom_value && formUserSelected?.eva_scale_representative_name && (
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell align="center">{t('Calificacion Final')}</TableCell>
                <TableCell align="center">{`${formUserSelected.prom_value} - ${formUserSelected.eva_scale_representative_name}`}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid
        item
        xs={12}
        sx={{
          mx: 'auto',
        }}
      >
        <InputLabel
          sx={{
            my: 2,
          }}
        >
          {t('Documentos')}
        </InputLabel>
        {Array.isArray(formUserSelected?.eva_formulary_documents) && (
          <Grid
            item
            container
            xs={12}
            sx={{
              mb: 4,
            }}
          >
            {formUserSelected?.eva_formulary_documents.map((item) => (
              <Grid item xs={6} key={item.id}>
                <Button
                  startIcon={<RemoveRedEye />}
                  onClick={() => {
                    setShowDocument(true);
                    setDocumentData({
                      url: `${urlDownload}/${item?.eva_formulary_document_url}#toolbar=0`,
                      name: item?.eva_formulary_type_document.eva_form_tip_doc_name,
                    });
                  }}
                >
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{
                      wordWrap: 'break-word',
                      width: '150px',
                    }}
                  >
                    {item?.eva_formulary_type_document?.eva_form_tip_doc_name}
                  </Typography>
                </Button>
              </Grid>
            ))}
          </Grid>
        )}
      </Grid>
      {showDocument && (
        <ModalDocuments
          open={showDocument}
          handleClose={() => setShowDocument(false)}
          urlFile={documentData.url}
          title={documentData.name}
        />
      )}
    </>
  );
};

export default HistoricalCalifications;
