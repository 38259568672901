import { useEffect, useState } from 'react';

// material-ui
import {
  Button,
  Grid,
  Stack,
  TextField,
  Autocomplete,
  FormHelperText,
  InputAdornment,
  CircularProgress,
} from '@material-ui/core';

import AnimateButton from '../../../../shared/components/extended/AnimateButton';
import InputLabel from '../../../../shared/components/forms/InputLabel';
import { useGetCatalogByAcronymQuery } from '../../../../shared/slices/catalogApi';
import { useAppSelector, useAppDispatch } from '../../../../../hooks/useRedux';
import {
  setCollaboratorRequisition,
  resetAll,
  setListAcademicFormations,
  setListLaboralExperiences,
  resetListAcademicFormations,
  resetListLaboralExperiences,
} from '../../slices/requisitionsSlice';
import { useLazyGetCollaboratorByCiQuery } from '../../../../hr/collaborators/slices/collaboratorsApiSlice';
import { useLazyGetPersonInformationQuery } from '../../slices/requisitionsApiSlice';
import { Collaborator } from '../../../../hr/collaborators/interfaces/collaborators.interfaces';

import ErrorAlert from '../../../../shared/components/alerts/ErrorAlert';
// import SuccessAlert from '../../../../shared/components/alerts/SuccessAlert';

// third-party
import { useFormik } from 'formik';
import * as yup from 'yup';

// import FormCreatePerson from '../FormCreatePerson';
import { useTranslation } from 'react-i18next';

interface DataId {
  name: string;
  id: number;
  keyword: string;
}

export type ShippingData = {
  id?: string;
  identification_type?: DataId | null;
};

const validationSchema = yup.object({
  id: yup.string().required('academic.requisition.edit.wizard.step1.id.required'),
  identificationType: yup
    .object()
    .shape({
      keyword: yup.string(),
      name: yup.string(),
      id: yup.number(),
    })
    .nullable()
    .required('academic.requisition.edit.wizard.step1.id_type.required'),
});

//-----------------------|| FORM WIZARD - VALIDATION  ||-----------------------//

export interface AddressFormProps {
  shippingData: ShippingData;
  setShippingData: (d: ShippingData) => void;
  handleNext: () => void;
  setErrorIndex: (i: number | null) => void;
}

const IdentificationForm = ({
  shippingData,
  setShippingData,
  handleNext,
  setErrorIndex,
}: AddressFormProps) => {
  const { t } = useTranslation();

  const { currentProfile } = useAppSelector((state) => state.access);

  const dispatch = useAppDispatch();

  const profileId = currentProfile?.profileId!;

  const { data: identificationTypes = [], isLoading: isLoadingIT } = useGetCatalogByAcronymQuery({
    profileId,
    acronym: 'TI',
  });

  const [showAlerts, setShowAlerts] = useState(true);
  // const [, setIsAddPersonView] = useState<boolean>(false);
  // const [isPersonCreated] = useState<boolean>(false);

  const [collError, setCollError] = useState<string | null>(null);

  const [
    getCollaboratorByCi,
    { data: dataCollaborator, isSuccess: isSuccessCollaborator, isError: isErrorCollaborator },
  ] = useLazyGetCollaboratorByCiQuery();

  // const {
  //   data: dataPerson,
  //   isSuccess: isSuccesPerson,
  //   isError,
  //   error,
  //   isLoading: isLoadingPerson,
  //   isFetching: isFetchingPerson,
  // } = useLazyGetPersonInformationQuery(
  //   { profileId, id: identification.toString() },
  //   { skip: skipValue }
  // );

  const [
    getPersonTrigger,
    {
      data: dataPerson,
      isSuccess: isSuccesPerson,
      // isLoading: isLoadingPerson,
      isFetching: isFetchingPerson,
      isError,
      error,
    },
  ] = useLazyGetPersonInformationQuery();

  const { getFieldProps, errors, touched, handleSubmit, setFieldValue, values } = useFormik({
    initialValues: {
      id: shippingData.id || '',
      identificationType: shippingData.identification_type || null,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setCollError(null);
      setShowAlerts(false);
      setShippingData({
        id: values.id,
        identification_type: values.identificationType,
      });

      dispatch(resetAll());

      // await setIdentification(values.id);
      if (values.id) getPersonTrigger({ profileId, id: values.id });
      if (values.id) getCollaboratorByCi({ profileId, ci: values.id });
      // setSkipValue(false);
    },
  });

  useEffect(() => {
    setShowAlerts(true);
    if (isErrorCollaborator) {
      setCollError(
        'El usuario no se encuentra registrado como colaborador, por favor comunicarse con el administrador.'
      );
    }
  }, [isSuccesPerson, isSuccessCollaborator, isErrorCollaborator, isError]);

  useEffect(() => {
    if (dataPerson?.pers_full_name || dataPerson?.identification) {
      if (isSuccesPerson && isSuccessCollaborator) {
        dispatch(setCollaboratorRequisition(dataCollaborator!));
        dispatch(
          setListAcademicFormations(
            dataPerson.person_education.map((dt) => ({
              id: dt.id,
              certificate_title: dt.pers_edu_certificate,
              graduation_date: dt.pers_edu_graduation_date,
              education_calification: dt.pers_edu_calification,
              education_observation: dt.pers_edu_observation,
              document_url: dt.pers_edu_document_url,
              institute_id: {
                id: +dt.institute_id,
                name: dt.institute?.inst_name || '',
                typeInstituteId: dt.institute?.type_institute_id
                  ? Number(dt.institute?.type_institute_id)
                  : 0,
              },
              city_id: { id: +dt.city_id, name: dt.city?.cat_name || '' },
              type_education_id: {
                id: +dt.type_education_id,
                name: dt.type_education?.typ_edu_name || '',
              },
              status_id: dt.status_id,
              ies_code: dt.ies_code,
              senescyt_register_number: dt.senescyt_register_number,
              unesco_code: dt.unesco_code,
              start_date: dt.start_date,
              end_date: dt.end_date,
              education_status: dt.education_status,
              country_id: Number(dt.country_id),
            }))
          )
        );
        dispatch(
          setListLaboralExperiences(
            dataPerson.person_job.map((dt) => ({
              ...dt,
              per_job_end_date: dt.per_job_end_date,
              per_job_current: Number(dt.per_job_current),
              per_job_iess_affiliated: Number(dt.per_job_iess_affiliated),
              document_url: dt.per_job_document_url,
              name_institution: dt.name_institution || '',
              subjects_taught: dt.subjects_taught || '',
              city_id: { id: +dt.city_id, name: '' },
              status_id: Number(dt.status_id),
              country_id: Number(dt.country_id),
            }))
          )
        );
        handleNext();
      } else if (isErrorCollaborator && isSuccesPerson) {
        dispatch(setCollaboratorRequisition({} as Collaborator));
        dispatch(resetListAcademicFormations());
        dispatch(resetListLaboralExperiences());

        // handleNext();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccesPerson, isSuccessCollaborator]);

  // useEffect(() => {
  //   if (!isFetchingPerson) {
  //     setIsAddPersonView(isSuccesPerson);
  //   }
  // }, [setIsAddPersonView, isSuccesPerson, isFetchingPerson]);

  // useEffect(() => {
  //   if (isPersonCreated) {
  //     dispatch(setCollaboratorRequisition({} as Collaborator));

  //     handleNext();
  //   }
  // }, [isPersonCreated, handleNext, dispatch]);

  return (
    <>
      {isError && showAlerts && (
        <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
      )}

      {/* {isSuccesPerson  && showAlerts && dataPerson?.id && (
        <SuccessAlert
          message={t('academic.requisition.edit.wizard.step1.success')}
          handleDismiss={() => setShowAlerts(false)}
        />
      )} */}
      {/* {isSuccesPerson && showAlerts && (
        <ErrorAlert
          message={t('academic.requisition.edit.wizard.step1.error')}
          handleDismiss={() => setShowAlerts(false)}
        />
      )} */}

      {isErrorCollaborator && showAlerts && (
        <ErrorAlert
          message={collError as string}
          handleDismiss={() => {
            setShowAlerts(false);
            setCollError(null);
          }}
        />
      )}

      {/* {!isAddPersonview && ( */}
      <form onSubmit={handleSubmit} id="validation-forms">
        <Grid container item xs={12} spacing={4} alignItems="flex-start" mt={1}>
          <Grid item container spacing={2} xs={12} sm={6}>
            <Grid item xs={12} lg={8}>
              <InputLabel>{t('academic.requisition.edit.wizard.step1.id_type')}</InputLabel>
              {isLoadingIT ? (
                <>
                  <TextField
                    disabled
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <CircularProgress size={25} />
                        </InputAdornment>
                      ),
                    }}
                    placeholder={t('academic.requisition.edit.wizard.step1.id_type.loading')}
                  />
                </>
              ) : (
                <Autocomplete
                  options={identificationTypes}
                  loading={isLoadingIT}
                  getOptionSelected={(option, value) => (value ? option.id === value.id : false)}
                  value={
                    values.identificationType === null
                      ? values.identificationType
                      : identificationTypes.find((e) => e.id === values.identificationType?.id)
                  }
                  loadingText={t('academic.requisition.edit.wizard.step1.id_type.loading')}
                  getOptionLabel={(option) => option.cat_name}
                  onChange={(_, value) => {
                    setFieldValue('identificationType', {
                      name: value?.cat_name,
                      id: value?.id,
                      keyword: value?.cat_keyword,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={t('academic.requisition.edit.wizard.step1.id_type.placeholder')}
                      error={Boolean(errors.identificationType && touched.identificationType)}
                    />
                  )}
                  fullWidth
                  clearIcon={null}
                />
              )}
              {errors.identificationType && touched.identificationType && (
                <FormHelperText error>{t(errors.identificationType)}</FormHelperText>
              )}
            </Grid>
            <Grid item xs={12} lg={8}>
              <InputLabel> {t('academic.requisition.edit.wizard.step1.id')}</InputLabel>
              <TextField
                fullWidth
                error={Boolean(errors.id && touched.id)}
                placeholder={t('academic.requisition.edit.wizard.step1.id.placeholder')}
                {...getFieldProps('id')}
              />
              {errors.id && touched.id && <FormHelperText error>{t(errors.id)}</FormHelperText>}
            </Grid>
          </Grid>

          <Grid item container spacing={2} xs={12} sm={6}></Grid>

          <Grid item xs={12}>
            <Stack direction="row" justifyContent="flex-end">
              <AnimateButton>
                <Button
                  variant="contained"
                  sx={{ my: 3, ml: 1 }}
                  type="submit"
                  disabled={isFetchingPerson}
                  endIcon={isFetchingPerson && <CircularProgress size={20} />}
                  onClick={() => isFetchingPerson && setErrorIndex(0)}
                >
                  {t('academic.requisition.edit.wizard.next')}
                </Button>
              </AnimateButton>
            </Stack>
          </Grid>
        </Grid>
      </form>
      {/* )} */}
      {/* {isAddPersonview && !isFetchingPerson && (
        <Grid container>
          <Grid item xs={12}>
            <FormCreatePerson
              idPerson={values.id}
              setPersonView={setIsAddPersonView}
              setIsPersonCreated={setIsPersonCreated}
              identificationType={values.identificationType?.keyword || ''}
            />
          </Grid>
        </Grid>
      )} */}
    </>
  );
};

export default IdentificationForm;
