import { Redirect, Switch } from 'react-router-dom';

import { useAppSelector } from '../../../../hooks/useRedux';
import GuardRoute from '../../../../router/guards/GuardRoute';
import TeachersScreen from '../screens/TeachersScreen';
import AssignCoursesScreen from '../screens/AssignCoursesScreen';
import FileTeacherScreen from '../screens/FileTeacherScreen';
import { subPermissionKeys } from '../constants/permissions';

function TeachersRouter() {
  // Verify permission
  const { currentModule } = useAppSelector((state) => state.access);
  const hasPermission = subPermissionKeys.groupKey in currentModule?.permissions!;

  if (!hasPermission) return <Redirect to="/" />;

  return (
    <>
      <Switch>
        <GuardRoute
          path="/administrar-docentes"
          exact
          component={TeachersScreen}
          permissionKey={subPermissionKeys.groupKey}
          subPermissionKey={subPermissionKeys.read}
        />

        <GuardRoute
          path="/administrar-docentes/:teacherId/asignar-cursos"
          exact
          component={AssignCoursesScreen}
          permissionKey={subPermissionKeys.groupKey}
          subPermissionKey={subPermissionKeys.listCoursesAvailableCollaborator}
        />

        <GuardRoute
          path="/administrar-docentes/:teacherId/ficha"
          exact
          component={FileTeacherScreen}
          permissionKey={subPermissionKeys.groupKey}
          subPermissionKey={subPermissionKeys.show}
        />
      </Switch>
    </>
  );
}

export default TeachersRouter;
