import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISearchTable } from '../../../shared/interfaces/paginatedTable.interface';

export interface BlackboardState {
  blackboardFilesTable: ISearchTable;
}

const initialState: BlackboardState = {
  blackboardFilesTable: {
    page: 1,
    search: '',
    perPage: 10,
  },
};

const blackboardSlice = createSlice({
  name: 'blackboard',
  initialState,
  reducers: {
    updateBlackboardFilesTable: (state, action: PayloadAction<ISearchTable>) => {
      state.blackboardFilesTable = action.payload;
    },
  },
});

export const { updateBlackboardFilesTable } = blackboardSlice.actions;

export default blackboardSlice.reducer;
