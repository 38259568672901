import { Grid } from '@material-ui/core';
import img200 from '../../../assets/images/OK-200.png';

const Ok200Screen = () => {
  return (
    <Grid
      container
      justifyContent="center"
      flexDirection="column"
      sx={{
        width: '100vw',
        height: '100vh',
      }}
      alignItems="center"
    >
      <img src={img200} alt="" />
    </Grid>
  );
};

export default Ok200Screen;
