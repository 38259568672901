import { Box, Modal, Typography } from '@material-ui/core';
import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../../../hooks/useRedux';
import SearchPaginatedTable from '../../../../../shared/components/tables/SearchPaginatedTable';
import { useGetQualificationsQuery } from '../../../slices/virtualClassroomApiSlice';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: '',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

interface Props {
  handleClose: () => void;
  showModal: {
    open: boolean;
    id: string | null;
    name: string | null;
  };
}

export default function ModalNotas({ handleClose, showModal }: Props) {
  const { open, name } = showModal;

  const { t } = useTranslation();

  const [page, setPage] = useState<number>(1);

  const [perPage, setPerPage] = useState<number>(5);

  const [search, setSearch] = useState<string>('');

  const {
    course: { courseId },
  } = useAppSelector((state) => state.virtualClassroomStudentShared);

  const { currentStudent } = useAppSelector((state) => state.access);

  const { data, isLoading, error, isFetching } = useGetQualificationsQuery({
    courseId: courseId?.toString()!,
    studentId: currentStudent?.id.toString()!,
    page,
    size: perPage,
    search,
  });

  const dataFetch =
    data &&
    data?.data?.map((item) => {
      const { course_activity_one } = item;
      return {
        item: item?.id,
        description: item?.act_description!,
        endDate: moment(course_activity_one?.cact_end_date!).format('MMMM Do YYYY, h:mm a'),
        qualification: {
          scoreActivity: item.act_score_activity!,
          score: course_activity_one?.cab_course_student_activity!,
          state: course_activity_one?.cact_end_date!,
        },
      };
    });

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Resultado ¨Promedio {`${name}`}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Estas son las actividades que conforman tu nota {`${name}`}
        </Typography>

        <SearchPaginatedTable
          data={dataFetch!}
          headers={{
            item: t('studentVirtualClassroom.test.code'),
            description: t('studentVirtualClassroom.test.description'),
            endDate: t('studentVirtualClassroom.content.end.date'),
            qualification: t('studentVirtualClassroom.test.qualification'),
          }}
          total={data?.total! || 0}
          keyExtractor={(item) => item.item.toString()}
          isLoading={isLoading}
          isFetching={isFetching}
          page={page}
          setPage={setPage}
          perPage={perPage}
          setPerPage={setPerPage}
          search={search}
          setSearch={setSearch}
          searchPlacehoder={t('studentVirtualClassroom.test.search')}
          error={error}
          numHeader={4}
          showFilter={false}
          customDataCellsProperties={{
            item: {
              align: 'center',
            },
            description: {
              align: 'center',
            },
            endDate: {
              align: 'center',
            },
            qualification: {
              align: 'center',
            },
          }}
          customHeadersCellsProperties={{
            item: {
              align: 'center',
            },
            description: {
              align: 'center',
            },
            endDate: {
              align: 'center',
            },
            qualification: {
              align: 'center',
            },
          }}
          customRenderers={{
            qualification: ({ qualification: { scoreActivity, score, state } }) => (
              <>
                {score.length > 0 && score[0].act_score ? (
                  <Typography>{`${Number(score[0].act_score)}/${Number(
                    scoreActivity
                  )}`}</Typography>
                ) : (
                  <Typography>{`${moment().isBefore(state) ? '--' : '0'}/${Number(
                    scoreActivity
                  )}`}</Typography>
                )}
              </>
            ),
          }}
        />
      </Box>
    </Modal>
  );
}
