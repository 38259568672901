import React, { useEffect } from 'react';
import { useState } from 'react';
import {
  CardActions,
  Button,
  TextField,
  Grid,
  Typography,
  FormControlLabel,
  Radio,
  CircularProgress,
  makeStyles,
} from '@material-ui/core';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import MainCard from '../../../../shared/components/cards/MainCard';
import {
  useGetEvaluationStudentDetailQuery,
  useStudentVCEvaluationUpdateQuestionMutation,
  useStudentVCEvaluationFinishMutation,
  useStudentVCEvaluationSaveQuestionMutation,
  useAddFileBucketMutation,
} from '../../slices/virtualClassroomApiSlice';
import { useHistory, useParams } from 'react-router-dom';
import { renderSkeletonTable } from '../../../../shared/helpers/render';
import CustomPagination from '../../../../shared/components/tables/CustomPagination';
import { useGetCatalogByAcronymChildrenQuery } from '../../../../shared/slices/catalogApi';
import { EvaluationDetailQuestion } from '../interfaces/evaluationDetail.interfaces';
import { Checkbox } from '@material-ui/core';
import {
  resolvedEvaluationQuestionAnswer,
  setAnswerQuestionCommentary,
  setAnswerQuestionMultiple,
  setAnswerQuestionSingle,
  setEvaluationAnswersCompleted,
} from '../../slices/virtualClassroomSlice';
import EvaluationHeaderInfo from './detail/EvaluationHeaderInfo';
import ErrorAlert from '../../../../shared/components/alerts/ErrorAlert';
import { navigateTopSmooth } from '../../../../shared/helpers';
import { useTranslation } from 'react-i18next';
// import DragArea from '../../components/DragArea';
import DragArea from '../../../../shared/components/files/DragArea';
import { useFormik } from 'formik';
import { ImagenFIleEvaluation } from '../../../../teachers/virtual-classroom/materials/constants/validationSchema';

export const useStyles = makeStyles((theme) => ({
  borderCard: {
    boxShadow: `0px 0px 20px #EAF0F7`,
    border: `1px solid ${theme.palette.primary.main}!important`,
    cursor: 'pointer',
  },
}));

const EvaluationDetail = () => {
  const dispatch = useAppDispatch();

  const history = useHistory();

  const [questionNumber, setQuestionNumber] = useState<number>(1);

  const [showAlert, setShowAlert] = useState<boolean>(false);

  const [optionNoSelected, setOptionNoSelected] = useState<boolean>(false);

  const [saveQuestion] = useStudentVCEvaluationSaveQuestionMutation();

  const [editQuestion] = useStudentVCEvaluationUpdateQuestionMutation();

  const [postFileBucket, { data: DataFileUpload }] = useAddFileBucketMutation();

  const {
    values: { file },
    setFieldValue,
  } = useFormik({
    initialValues: {
      file: null,
    },
    validationSchema: ImagenFIleEvaluation,
    onSubmit: () => console.log('perfecto'),
  });

  const [
    finishEvaluation,
    {
      isLoading: isLoadingFinishEvaluation,
      error: errorFinishEvaluation,
      isError: isErrorFinishEvaluation,
    },
  ] = useStudentVCEvaluationFinishMutation();

  const { evaluationId } = useParams<{ evaluationId: string }>();

  const { currentProfile, currentStudent } = useAppSelector((state) => state.access);

  const { userInfo } = useAppSelector((state) => state.auth);

  const { t } = useTranslation();

  /**
   * Finalizar evaluación.
   */
  const handleFinishEvaluation = async () => {
    const err = await finishEvaluation({
      evaluationId: Number(evaluationId),
      studentId: currentStudent?.id!,
      profileId: currentProfile?.profileId!,
    });
    setShowAlert(true);
    navigateTopSmooth();
    if ('error' in err === false) {
      history.replace(`/aula-virtual-estudiante/test/${evaluationId}/finalizado`);
    }
  };

  const { evaluationQuestionAnswers: questionsData, evaluationActive: evaluation } = useAppSelector(
    (state) => state.virtualClassroomStudent.evaluation
  );

  const { isLoading } = useGetEvaluationStudentDetailQuery({
    evaluationId: Number(evaluationId),
    studentId: currentStudent?.id!,
    userId: Number(userInfo?.id!)!,
    userProfileId: currentProfile?.profileId!,
  });

  /**
   * Obtener tipos de respuesta.
   */
  const { data: dataTypeQuestion, isLoading: isLoadingTypeQuestion } =
    useGetCatalogByAcronymChildrenQuery({
      acronym: 'tipo-respuesta',
      profileId: currentProfile?.profileId!,
    });

  const getAnswerSelected = (questionId: number): number[] => {
    const question = questionsData.find((e) => e.id === questionId);
    return question?.option_answers.reduce<number[]>(
      (acc, value) => (value.op_ans_is_answer === true ? acc.concat(value.id) : acc),
      []
    )!;
  };

  /**
   * Guardar múltiples respuestas que el estudiante haya seleccionado.
   * Despachar pregunta como resuelta.
   */
  const handleSaveMultiple = (questionId: number) => {
    const answers = getAnswerSelected(questionId);
    if (answers.length === 0) {
      setOptionNoSelected(true);
      return false;
    }
    dispatch(resolvedEvaluationQuestionAnswer(questionId));

    handleSaveQuestion(questionId, answers);
  };

  /**
   * Actualizar múltiples respuestas que el estudiante haya seleccionado.
   */
  const handleUpdateMultiple = (questionId: number) => {
    const answers = getAnswerSelected(questionId);
    if (answers.length === 0) {
      setOptionNoSelected(true);
      return false;
    }

    handleEditQuestion(questionId, answers);
  };

  /**
   * Guardar una respuesta de tipo única respuesta que el estudiante haya seleccionado.
   */
  const handleSaveSingle = (questionId: number) => {
    const answers = getAnswerSelected(questionId);
    if (answers.length === 0) {
      setOptionNoSelected(true);
      return false;
    }

    dispatch(resolvedEvaluationQuestionAnswer(questionId));

    handleSaveQuestion(questionId, undefined, answers[0]!);
  };

  /**
   * Adelantar una pregunta (avanzar).
   */
  const handleNextQuestion = () => {
    questionNumber === evaluation?.details_activity.length
      ? setQuestionNumber(1)
      : setQuestionNumber(questionNumber + 1);
  };
  /**
   * Actualizar una respuesta de tipo única respuesta que el estudiante haya seleccionado.
   */
  const handleUpdateSingle = (questionId: number) => {
    const answers = getAnswerSelected(questionId);
    if (answers.length === 0) {
      setOptionNoSelected(true);
      return false;
    }
    handleEditQuestion(questionId, undefined, answers[0]!);
  };

  /**
   * Obtener el valor de una pregunta tipo comentario.
   */
  const obtainValueCommentary = (questionId: number): string => {
    return questionsData.find((e) => e.id === questionId)?.op_ans_answer_comment!;
  };

  /**
   * Guardar una respuesta de tipo comentario.
   * Se maneja la actualización de tipo respuesta comentario
   * cada vez que se emite el botón de siguiente.
   */
  const handleSaveCommentary = (questionId: number) => {
    const value = obtainValueCommentary(questionId);
    if (value === '') {
      setOptionNoSelected(true);
      return false;
    }

    dispatch(resolvedEvaluationQuestionAnswer(questionId));

    handleSaveQuestion(questionId, undefined, undefined, value);
  };

  /**@Post guardar iamgen del archivo */
  const handleSaveFile = (questionId: number) => {
    if (DataFileUpload && DataFileUpload.length) {
      const { name, url } = DataFileUpload[0]!;

      handleSaveQuestion(questionId, undefined, undefined, undefined, name! || '', url! || '');
      dispatch(resolvedEvaluationQuestionAnswer(questionId));
      handleNextQuestion();
    }
  };

  /**
   * Actualizar una respuesta de tipo comentario.
   * Se maneja la actualización de tipo respuesta comentario
   * cada vez que se emite el botón de siguiente.
   */
  const handleUpdateCommentary = (questionId: number) => {
    const value = obtainValueCommentary(questionId);
    if (value === '') {
      setOptionNoSelected(true);
      return false;
    }

    handleEditQuestion(questionId, undefined, undefined, value);
  };

  /**
   * Comprobar si la evalaución ha sido completada.
   * Las mismas preguntas del store deberían coincidir
   * con las preguntas resueltas.
   */
  const checkIfEvaluationComplete = (): boolean => {
    const questions = questionsData?.length!;
    const questionsResolved = questionsData.reduce<number[]>(
      (acc, value) => (value.resolved === true ? acc.concat(value.id) : acc),
      []
    )!;
    return questions !== questionsResolved?.length!;
  };

  /**
   *
   * Save FIle in Modal
   */

  const onChangeFile = function (event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.files && event.target.files[0]) {
      setFieldValue('file', event.target.files[0]);

      postFileBucket({
        PayloadFile: {
          files: file,
        },
      });
    }
  };

  /**
   * Persistir respuesta o respuestas de una pregunta,
   * función con parámetros opcionales, dependiendo del tipo
   * de pregunta.
   */
  const handleSaveQuestion = (
    questionId: number,
    multiple?: number[],
    option_answer?: number,
    ans_comment?: string,
    name_file?: string,
    url_file?: string
  ) => {
    saveQuestion({
      evaluationId: Number(evaluationId),
      profileId: currentProfile?.profileId!,
      questionId: questionId,
      studentId: currentStudent?.id!,
      payload: {
        csact_comments: ans_comment || '',
        option_answer: option_answer || null,
        options_answer_multiple: multiple || [],
        user_id: Number(userInfo?.id!),
        name_file: name_file || '',
        url_file: url_file || '',
      },
    });
    dispatch(setEvaluationAnswersCompleted());
    handleNextQuestion();
  };

  /**
   * Actualizar respuesta o respuestas de una pregunta,
   * función con parámetros opcionales, dependiendo del tipo
   * de pregunta.
   */
  const handleEditQuestion = (
    questionId: number,
    multiple?: number[],
    option_answer?: number,
    ans_comment?: string,
    name_file?: string,
    url_file?: string
  ) => {
    editQuestion({
      evaluationId: Number(evaluationId),
      profileId: currentProfile?.profileId!,
      questionId: questionId,
      studentId: currentStudent?.id!,
      payload: {
        csact_comments: ans_comment || '',
        option_answer: option_answer || null,
        options_answer_multiple: multiple || [],
        user_id: Number(userInfo?.id!),
        name_file: name_file || '',
        url_file: url_file || '',
      },
    });
    handleNextQuestion();
  };

  const handleSelectedCheckbox = (questionId: number, answerId: number, selected: boolean) => {
    dispatch(setAnswerQuestionMultiple({ questionId, answerId, selected }));
  };

  const handleSelectedRadio = (questionId: number, answerId: number) => {
    dispatch(setAnswerQuestionSingle({ questionId, answerId, selected: true }));
  };

  const Multiple = ({ id, dta_question, option_answers, resolved }: EvaluationDetailQuestion) => {
    return (
      <React.Fragment key={`${id}-${dta_question}`}>
        <MainCard title={`${dta_question}`}>
          {option_answers.map((element) => (
            <Grid container direction="row" key={element?.op_ans_description}>
              <Grid item>
                <Checkbox
                  checked={element?.op_ans_is_answer!}
                  onChange={(e) => handleSelectedCheckbox(id, element.id, e?.target?.checked)}
                />
              </Grid>
              <Grid item sx={{ mt: 1 }}>
                <Typography variant="h5">{element?.op_ans_description}</Typography>
              </Grid>
            </Grid>
          ))}
        </MainCard>
        <CardActions>
          <Grid container justifyContent="right">
            {resolved ? (
              <Grid item>
                <Button
                  onClick={() => handleUpdateMultiple(id)}
                  variant="outlined"
                  style={{ textTransform: 'unset' }}
                  size="small"
                >
                  {t('studentVirtualClassroom.test.updateAndNext')}
                </Button>
              </Grid>
            ) : (
              <Grid item>
                <Button
                  onClick={() => handleSaveMultiple(id)}
                  variant="outlined"
                  style={{ textTransform: 'unset' }}
                  size="small"
                >
                  {t('studentVirtualClassroom.test.next')}
                </Button>
              </Grid>
            )}
          </Grid>
        </CardActions>
      </React.Fragment>
    );
  };

  const Single = ({ id, dta_question, option_answers, resolved }: EvaluationDetailQuestion) => {
    return (
      <React.Fragment key={`${id}-${dta_question}`}>
        <MainCard title={`${dta_question}`}>
          {option_answers.map((element) => (
            <Grid container direction="row" key={element?.op_ans_description}>
              <Grid item>
                <FormControlLabel
                  onChange={() => handleSelectedRadio(id, element.id)}
                  label=""
                  checked={element.op_ans_is_answer}
                  control={<Radio />}
                />
              </Grid>
              <Grid item sx={{ mt: 1 }}>
                <Typography variant="h5">{element?.op_ans_description}</Typography>
              </Grid>
            </Grid>
          ))}
          <CardActions>
            <Grid container justifyContent="right">
              {resolved ? (
                <Grid item>
                  <Button
                    onClick={() => handleUpdateSingle(id)}
                    variant="outlined"
                    style={{ textTransform: 'unset' }}
                    size="small"
                  >
                    {t('studentVirtualClassroom.test.updateAndNext')}
                  </Button>
                </Grid>
              ) : (
                <Grid item>
                  <Button
                    onClick={() => handleSaveSingle(id)}
                    variant="outlined"
                    style={{ textTransform: 'unset' }}
                    size="small"
                  >
                    {t('studentVirtualClassroom.test.next')}
                  </Button>
                </Grid>
              )}
            </Grid>
          </CardActions>
        </MainCard>
      </React.Fragment>
    );
  };

  const Commentary = ({
    id,
    dta_question,
    resolved,
    op_ans_answer_comment,
  }: EvaluationDetailQuestion) => {
    const [commentary, setCommentary] = useState<string>('');

    useEffect(() => {
      let timer: any;
      if (commentary) {
        timer = setTimeout(() => {
          dispatch(setAnswerQuestionCommentary({ questionId: id, commentary: commentary }));
        }, 2000);
      }
      return () => clearTimeout(timer);
    }, [commentary, id]);

    return (
      <React.Fragment key={`${id}-${dta_question}`}>
        <MainCard title={`${dta_question}`}>
          <Grid container direction="row">
            <Grid item xs={6}>
              <TextField
                id="commentary-answer"
                fullWidth
                rows={5}
                onChange={(e) => setCommentary(e.target.value)}
                defaultValue={op_ans_answer_comment}
                multiline
                placeholder="Argumenta tu respuesta..."
              />
            </Grid>
          </Grid>
        </MainCard>
        <CardActions>
          <Grid container justifyContent="right">
            {resolved ? (
              <Grid item>
                <Button
                  onClick={() => {
                    setAnswerQuestionCommentary({ questionId: id, commentary: commentary });
                    handleUpdateCommentary(id);
                  }}
                  variant="outlined"
                  style={{ textTransform: 'unset' }}
                  size="small"
                >
                  {t('studentVirtualClassroom.test.updateAndNext')}
                </Button>
              </Grid>
            ) : (
              <Grid item>
                <Button
                  onClick={() => {
                    setAnswerQuestionCommentary({ questionId: id, commentary: commentary });
                    handleSaveCommentary(id);
                  }}
                  variant="outlined"
                  style={{ textTransform: 'unset' }}
                  size="small"
                >
                  {t('studentVirtualClassroom.test.next')}
                </Button>
              </Grid>
            )}
          </Grid>
        </CardActions>
      </React.Fragment>
    );
  };

  const QuestionFile = ({
    id,
    dta_question,
    option_answers,
    resolved,
  }: EvaluationDetailQuestion) => {
    return (
      <>
        <MainCard title={`${dta_question}`}>
          <Grid item xs={5}>
            <DragArea handleChange={onChangeFile} />;
          </Grid>
        </MainCard>
        <CardActions>
          <Grid container justifyContent="right">
            (
            <Grid item>
              <Button
                onClick={() => handleSaveFile(id)}
                variant="outlined"
                style={{ textTransform: 'unset' }}
                size="small"
              >
                {t('studentVirtualClassroom.test.next')}
              </Button>
            </Grid>
            )
          </Grid>
        </CardActions>
      </>
    );
  };

  const renderQuestion = () => {
    const question = questionsData![questionNumber - 1]!;
    const typeQuestion = dataTypeQuestion?.find(
      (item) => item.id === Number(question?.type_answer_id!)
    );

    if (typeQuestion?.cat_name! === 'Opcion Multiple') {
      return <Multiple {...question} />;
    } else if (typeQuestion?.cat_name! === 'Opcion Seleccion') {
      return <Single {...question} />;
    } else if (typeQuestion?.cat_name! === 'Archivo - Herramientas') {
      return <QuestionFile {...question} />;
    } else {
      return <Commentary {...question} />;
    }
  };

  /**
   * Remover evaluación activa cuando el component se haya
   * desmontado. La evaluación debería contar como un intento.
   */
  useEffect(() => {
    //return () => {
    //     // dispatch(resetEvaluationActive());
    // }
    // eslint-disable-next-line
    console.log(questionsData);
  }, [questionsData]);

  return (
    <React.Fragment>
      {isLoading || isLoadingTypeQuestion ? (
        renderSkeletonTable(5, 5)
      ) : (
        <>
          <Grid item xs={12}>
            <Grid item xs={12} sx={{ mb: 2 }}>
              {showAlert && isErrorFinishEvaluation && (
                <ErrorAlert
                  message={errorFinishEvaluation as string}
                  handleDismiss={() => setShowAlert(false)}
                />
              )}
            </Grid>
          </Grid>

          <EvaluationHeaderInfo />
          <Grid container spacing={2} sx={{ mt: 3 }} justifyContent="center" alignItems="flex-end">
            <Grid item xs={12} md={12} container spacing={1}>
              <Grid container justifyContent="space-between" alignItems="flex-end" sx={{ mt: 4 }}>
                <Grid item xs={12}>
                  <Grid item xs={12} sx={{ mb: 2 }}>
                    {optionNoSelected && (
                      <ErrorAlert
                        message={t('studentVirtualClassroom.test.chooseAnswer')}
                        handleDismiss={() => setOptionNoSelected(false)}
                      />
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  {renderQuestion()}
                </Grid>
              </Grid>
              <Grid container justifyContent="space-between" alignItems="flex-end" sx={{ mt: 2 }}>
                <Grid item>
                  <Button
                    onClick={() => handleFinishEvaluation()}
                    variant="contained"
                    disabled={checkIfEvaluationComplete() || isLoadingFinishEvaluation}
                    endIcon={isLoadingFinishEvaluation && <CircularProgress size={20} />}
                    style={{ textTransform: 'unset' }}
                    size="large"
                  >
                    {t('studentVirtualClassroom.test.finishTest')}
                  </Button>
                </Grid>
                <Grid item>
                  <CustomPagination
                    shape="circular"
                    color="primary"
                    count={Math.ceil(evaluation?.details_activity?.length!)}
                    page={questionNumber}
                    onChange={(_, newValue) => setQuestionNumber(newValue)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </React.Fragment>
  );
};

export default EvaluationDetail;
