import { mainApi } from '../../../../api/mainApi';
import { AccessState } from '../../../../types';
import { middlewareApi } from '../../../middleware/middlewareApi';
import {
  ParameterCompany,
  ParameterCompanyData,
  ParameterCompanyPayload,
} from '../interfaces/ParameterCompany';

export const parameterCompanyTags = middlewareApi.enhanceEndpoints({
  addTagTypes: ['ParametersCompany'],
});

export const configurationsApi = parameterCompanyTags.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * Parameters Company
     */
    getParametersCompany: builder.query<ParameterCompany, void>({
      queryFn: async () => {
        try {
          const { data } = await mainApi.get('/company-parameters');
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: [{ type: 'ParametersCompany', id: 'LIST' }],
    }),
    addParameterCompany: builder.mutation<ParameterCompanyData, ParameterCompanyPayload>({
      queryFn: async (body) => {
        try {
          const { data } = await mainApi.post('/company-parameters', body);
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'ParametersCompany', id: 'LIST' }],
    }),
    editParameterCompany: builder.mutation<
      ParameterCompanyData,
      {
        parameterId: number;
        body: ParameterCompanyPayload;
      }
    >({
      queryFn: async ({ body, parameterId }) => {
        try {
          const { data } = await mainApi.put(`/company-parameters/${parameterId}`, body);
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'ParametersCompany', id: 'LIST' }],
    }),

    deleteParameterCompany: builder.mutation<
      ParameterCompanyData,
      {
        parameterId: number;
      }
    >({
      queryFn: async ({ parameterId }) => {
        try {
          const { data } = await mainApi.delete(`/company-parameters/${parameterId}`);
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'ParametersCompany', id: 'LIST' }],
    }),

    /**
     * @PUT /company-parameters/{parameterId}/action-disallow
     * @param parameterId
     * @param action_disallow
     * @returns
     * @description
     * 1. action_disallow = 1 => disallow
     */
    parameterCompanyDisallowAction: builder.mutation<
      any,
      {
        parameterId: number;
        action_disallow: ParameterCompanyData['action_disallow'];
      }
    >({
      queryFn: async ({ action_disallow, parameterId }) => {
        try {
          const { data } = await mainApi.put<any>(
            `company-parameters/${parameterId}/action-disallow`,
            {
              action_disallow,
            }
          );
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'ParametersCompany', id: 'LIST' }],
    }),

    /**
     * @GET Zendesk Data
     * @param keyword
     * @returns
     * @description
     * 1. keyword = 'ZEND'
     *
     */
    getCompanyParameterKeyword: builder.query<ParameterCompanyData, { keyword: 'ZEND' }>({
      queryFn: async ({ keyword }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = currentProfile?.profileId;

          const { data } = await mainApi.get(`company-parameters/${keyword}/keywork`, {
            params: {
              user_profile_id,
            },
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    //
  }),
});

export const {
  useGetParametersCompanyQuery,
  useAddParameterCompanyMutation,
  useEditParameterCompanyMutation,
  useDeleteParameterCompanyMutation,
  useParameterCompanyDisallowActionMutation,
  useGetCompanyParameterKeywordQuery,
} = configurationsApi;
