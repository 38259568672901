import * as Yup from 'yup';

export const AssignStagesSchema = Yup.object().shape({
  calificationModelDates: Yup.object().shape({
    calification_model_id: Yup.number().required('El modelo de calificación es requerido'),
    distributions: Yup.array().of(
      Yup.object().shape({
        ref1_supply_id: Yup.string().required('El suministro es requerido'),
        supplies: Yup.array().of(
          Yup.object().shape({
            ref2_supply_id: Yup.string().required('El suministro es requerido'),
            startDate: Yup.date()
              .typeError('La fecha de inicio es requerida')
              .required('La fecha de inicio es requerida'),
            endDate: Yup.date()
              .typeError('La fecha de fin es requerida')
              .required('La fecha de fin es requerida'),
          })
        ),
      })
    ),
  }),
});
