import { Button, CircularProgress, Grid, Typography, useTheme } from '@material-ui/core';
import { TablerIcon } from '@tabler/icons';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../../hooks/useRedux';
import { RegisterSyllabusDataResponse } from '../../../teachers/courses/interfaces/RegisterSyllabusPayload.interface';
import { MatterInfoForSyllabus } from '../../interfaces/matterInfoForSyllabus';
import { RegisterSyllabusData } from '../../interfaces/registerSyllabusData';
import Bibliography from './bibliography';
import ButtonSaveADraft from './ButtonSaveADraft';
import { bibliography, generalDetails, lessonPlan } from './constants';
import WizardSyllabus from './general_details';
import LessonPlan from './lesson_plan';

interface FormRegisterSyllabusProps {
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  isLoadingAddRegisterSyllabus: boolean;
  steps: {
    label: string;
    Icon: TablerIcon;
    url: string;
  }[];
  addRegisterSyllabus: any;
  setShowAlerts: any;
  syllabusData: RegisterSyllabusDataResponse | undefined;
  matterInfoForSyllabus: MatterInfoForSyllabus | undefined;
}

const FormRegisterSyllabus = ({
  handleSubmit,
  isLoadingAddRegisterSyllabus,
  steps,
  addRegisterSyllabus,
  setShowAlerts,
  syllabusData,
  matterInfoForSyllabus,
}: FormRegisterSyllabusProps) => {
  const theme = useTheme();
  const { caseKey, courseId } = useParams<{ caseKey: string; courseId?: string }>();

  const { setFieldValue } = useFormikContext<RegisterSyllabusData>();

  const { userInfo } = useAppSelector((state) => state.auth);

  useEffect(() => {
    if (syllabusData) {
      setFieldValue('syllabus_entries', {
        id: syllabusData.id,
        description_justify: syllabusData.description_justify || '',
        object_study: syllabusData.object_study || '',
        object: syllabusData.object || '',
        result_learning: syllabusData.result_learning || '',
        contents: syllabusData.contents || '',
        metho_mean_evaluation: syllabusData.metho_mean_evaluation || '',
        lesson_plan: syllabusData.lesson_plan || '',
      });
      setFieldValue('syllabus', syllabusData.syllabus);
      setFieldValue('syllabus_bibliogra_basic', syllabusData.syllabus_bibliogra_basic);
      setFieldValue('syllabus_bibliogra_comple', syllabusData.syllabus_bibliogra_comple);
      setFieldValue('syllabus_data_collaborator', {
        id: syllabusData.id,
        experience_area: syllabusData?.syllabus_data_collaborator?.experience_area || '',
        phone_collaborator: syllabusData?.syllabus_data_collaborator?.phone_collaborator || '',
        postgrado: syllabusData?.syllabus_data_collaborator?.postgrado || '',
        specialty_area: syllabusData?.syllabus_data_collaborator?.specialty_area || '',
        collaborator_id: syllabusData?.syllabus_data_collaborator?.collaborator_id || null,
        college_education: syllabusData?.syllabus_data_collaborator?.college_education || '',
        email_collaborator: syllabusData?.syllabus_data_collaborator?.email_collaborator || '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [syllabusData]);

  useEffect(() => {
    if (matterInfoForSyllabus && !syllabusData) {
      setFieldValue('syllabus', {
        date_send: null,
        education_level_id: matterInfoForSyllabus.education_level_id,
        id: null,
        matter_id: matterInfoForSyllabus.matter_id,
        period_id: matterInfoForSyllabus.period_id,
        status_id: null,
        sy_anio: matterInfoForSyllabus.period.per_due_year,
        course_id: courseId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matterInfoForSyllabus, syllabusData]);

  useEffect(() => {
    if (userInfo && !syllabusData) {
      setFieldValue('syllabus_data_collaborator', {
        collaborator_id: userInfo?.collaboratorInfo?.id!,
        college_education: '',
        email_collaborator: userInfo?.email!,
        experience_area: '',
        id: null,
        phone_collaborator: '',
        postgrado: '',
        specialty_area: '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo, syllabusData]);

  return (
    <form
      onSubmit={handleSubmit}
      style={{
        width: '100%',
      }}
    >
      <Grid item container my={2} justifyContent="center" alignItems="center">
        {steps.map(({ url, Icon }) => {
          if (url === caseKey) return <Icon size={40} color={theme.palette.primary.main} />;
          else return '';
        })}
      </Grid>

      <Grid item container justifyContent="center" alignItems="center">
        <Typography fontWeight={2} variant="h3" color="initial">
          {steps.map(({ url, label }) => {
            if (url === caseKey) return label;
            else return '';
          })}
        </Typography>
      </Grid>
      <Grid item xs={9} mt={4} mx="auto" container alignItems="center" justifyContent="center">
        {generalDetails.url === caseKey && <WizardSyllabus />}
        {lessonPlan.url === caseKey && <LessonPlan />}
        {bibliography.url === caseKey && <Bibliography />}
      </Grid>

      <Grid
        item
        container
        xs={8}
        lg={5}
        mx="auto"
        alignItems="center"
        justifyContent="center"
        spacing={2}
        sx={{ mt: 6 }}
      >
        <ButtonSaveADraft
          addRegisterSyllabus={addRegisterSyllabus}
          setShowAlerts={setShowAlerts}
          isLoadingAddRegisterSyllabus={isLoadingAddRegisterSyllabus}
          isEducationLevel={!matterInfoForSyllabus?.education_level_id}
        />
        <Grid item xs={6}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            size="large"
            color="primary"
            disabled={isLoadingAddRegisterSyllabus || !matterInfoForSyllabus?.education_level_id}
            endIcon={isLoadingAddRegisterSyllabus && <CircularProgress size={20} />}
          >
            Guardar
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default FormRegisterSyllabus;
