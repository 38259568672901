import { Grid, Typography } from '@material-ui/core';

import { useAppSelector } from '../../../../hooks/useRedux';
import MainCard from '../../../shared/components/cards/MainCard';

import DeleteGroupAreaDialog from '../components/DeleteGroupAreaDialog';
import GroupAreasTable from '../components/GroupAreasTable';

const GroupAreasScreen = () => {
  const { groupAreaIdToDelete } = useAppSelector((state) => state.groupAreas);

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">Listado de grupos de nivelación</Typography>
      </Grid>
      <GroupAreasTable />

      {groupAreaIdToDelete && <DeleteGroupAreaDialog />}
    </Grid>
  );
};

export default GroupAreasScreen;
