import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import MainCard from '../../../shared/components/cards/MainCard';
import PosgraduatePlanningDayTypeDialog from '../components/PosgraduatePlanningDayTypeDialog';
import PosgraduatePlanningDayTypesTable from '../components/PosgraduatePlanningDayTypesTable';
import PostgraduatePlanningDayTypeDeleteDialog from '../components/PostgraduatePlanningDayTypeDeleteDialog';
import {
  setPosgraduatePlanningDayTypeFormDialogOpen,
  setPosgraduatePlanningDayTypeIdSelected,
} from '../slices/posgraduatePlanningDayTypesSlice';

const PosgraduatePlanningDayTypesScreen = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const {
    posgraduatePlanningDayTypeIdSelected,
    posgraduatePlanningDayTypeDeleteDialogOpen,
    posgraduatePlanningDayTypeFormDialogOpen,
  } = useAppSelector((state) => state.posgraduatePlanningDayTypes);

  const handleCloseFormDialog = () => {
    dispatch(setPosgraduatePlanningDayTypeFormDialogOpen(false));
    dispatch(setPosgraduatePlanningDayTypeIdSelected(null));
  };

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2" mb={2}>
          {t('posgraduate.day_types.title')}
        </Typography>
      </Grid>
      <PosgraduatePlanningDayTypesTable />
      {posgraduatePlanningDayTypeIdSelected && posgraduatePlanningDayTypeDeleteDialogOpen && (
        <PostgraduatePlanningDayTypeDeleteDialog />
      )}
      {posgraduatePlanningDayTypeFormDialogOpen && (
        <PosgraduatePlanningDayTypeDialog open handleCloseDialog={handleCloseFormDialog} />
      )}
    </Grid>
  );
};

export default PosgraduatePlanningDayTypesScreen;
