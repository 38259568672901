import { Grid, Typography } from '@material-ui/core';
import { useAppSelector } from '../../../../hooks/useRedux';
import MainCard from '../../../shared/components/cards/MainCard';

import DeleteHourhandsDialog from '../components/DeleteHourhandsDialog';
import HourhandTable from '../components/HourhandTable';

const HourhandsScreen = () => {
  const { hourhandIdToDelete } = useAppSelector((state) => state.hourhands);

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">Horarios</Typography>
      </Grid>

      <HourhandTable />

      {hourhandIdToDelete && <DeleteHourhandsDialog />}
    </Grid>
  );
};

export default HourhandsScreen;
