import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/useRedux';
import { useTableSearch } from '../../../../../../hooks/useTableSearch';
import AddButton from '../../../../../shared/components/tables/buttons/AddButton';
import DeleteButton from '../../../../../shared/components/tables/buttons/DeleteButton';
import EditButton from '../../../../../shared/components/tables/buttons/EditButton';
import SearchPaginatedTable from '../../../../../shared/components/tables/SearchPaginatedTable';
import { useGetOptionsTemplatesQuery } from '../slices/optionsTemplateApiSlice';
import {
  setOptionsTemplateIdToDelete,
  setOptionsTemplateList,
  setOptionsTemplatesDeleteModalOpen,
  setOptionsTemplateTable,
} from '../slices/optionsTemplateSlice';

const OptionsTemplateTable = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const {
    optionsTemplateList,
    optionsTemplateTable: { page, perPage, search, filterArrow },
  } = useAppSelector((state) => state.optionsTemplate);

  const { setPage, setPerPage, setSearch, setFilterArrow } = useTableSearch({
    page,
    perPage,
    search,
    filterArrow,
    updateFunction: setOptionsTemplateTable,
  });

  const { data, isLoading, isFetching, error } = useGetOptionsTemplatesQuery({
    page,
    perPage,
    search,
    sort: 'uuid',
    typeSort: 'DESC',
    ...(filterArrow && { sort: filterArrow.name, typeSort: filterArrow.direction }),
  });

  useEffect(() => {
    if (data?.data) {
      dispatch(setOptionsTemplateList(data?.data));
    }
  }, [data, dispatch]);

  const datafetch = optionsTemplateList.map((item, idx) => ({
    id: (page - 1) * perPage + (idx + 1),
    description: item?.description,
    options: { id: item.uuid },
  }));

  const goToAdd = () => {
    history.push('/escalas/escalas-calificativa/agregar');
  };

  const handleEdit = (id: string) => {
    history.push(`/escalas/escalas-calificativa/${id}/editar`);
  };

  const handleDelete = (id: string) => {
    dispatch(setOptionsTemplateIdToDelete(id));
    dispatch(setOptionsTemplatesDeleteModalOpen(true));
  };

  return (
    <SearchPaginatedTable
      data={datafetch!}
      headers={{
        id: 'N°',
        description: t('maintenance.options_template.description'),
        options: t('maintenance.options_template.options'),
      }}
      listFilterArrows={{
        description: 'description',
      }}
      filterArrow={filterArrow}
      setFilterArrow={setFilterArrow}
      keyExtractor={(item) => item.id!.toString()}
      searchPlacehoder={t('maintenance.options_template.search')}
      isLoading={isLoading}
      isFetching={isFetching}
      error={error as any}
      perPage={perPage}
      setPerPage={setPerPage}
      total={data?.total || 0}
      page={page}
      setPage={setPage}
      numHeader={5}
      search={search}
      setSearch={setSearch}
      customDataCellsProperties={{
        id: {
          align: 'center',
        },
        description: {
          align: 'center',
        },
        options: {
          align: 'center',
        },
      }}
      customRenderers={{
        // eslint-disable-next-line react/no-unstable-nested-components
        options: (row) => (
          <>
            <EditButton handleEdit={() => handleEdit(row.options.id!)} />
            <DeleteButton handleDelete={() => handleDelete(row.options.id!)} />
          </>
        ),
      }}
      ActionButtons={<AddButton onClick={goToAdd} />}
    />
  );
};

export default OptionsTemplateTable;
