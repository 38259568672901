import { DateRange } from '@material-ui/lab/DateRangePicker/RangeTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { format } from 'date-fns';

import { ISearchTable } from '../../../shared/interfaces/paginatedTable.interface';

import { GeneralApplication } from '../interfaces/generalApplications.interfaces';

interface GeneralApplicationsState {
  generalApplicationList: GeneralApplication[];
  deleteModalOpen: boolean;
  generalApplicationTable: ISearchTable;
  applicationCommentsList: any[];
  nextStatus: string | undefined;
  // gappIdToDelete?: number;

  gAppToReject?: { appId: number; code: string; msg: string; refuseId?: number };
  selectedStudentRecordId?: string | number;

  editDataApp?: boolean;

  educationLevelIdSelected: number | null;
  dateRangeSelected: DateRange<string | null>;
  appTypeIdSelected: string | null;
  appStateIdSelected: string | null;
}

const initialState: GeneralApplicationsState = {
  generalApplicationList: [],
  deleteModalOpen: false,
  generalApplicationTable: {
    page: 1,
    perPage: 5,
    search: '',
  },
  applicationCommentsList: [],
  nextStatus: undefined,

  educationLevelIdSelected: null,
  dateRangeSelected: [format(new Date(), 'dd-MMM-yyyy'), format(new Date(), 'dd-MMM-yyyy')],
  appTypeIdSelected: null,
  appStateIdSelected: null,
};

const generalApplicationSlice = createSlice({
  name: 'generalApplication',
  initialState,
  reducers: {
    setGeneralApplicationList: (state, action: PayloadAction<GeneralApplication[]>) => {
      state.generalApplicationList = action.payload;
    },
    setDeleteModalOpen: (state, action: PayloadAction<boolean>) => {
      state.deleteModalOpen = action.payload;
    },
    // openDeleteModalWith: (state, action: PayloadAction<number>) => {
    //   state.gappIdToDelete = action.payload;
    //   state.deleteModalOpen = true;
    // },
    // resetGappIdToDelete: (state) => {
    //   state.gappIdToDelete = undefined;
    // },

    openRejectAppModalWith: (
      state,
      action: PayloadAction<{ appId: number; code: string; msg: string; refuseId?: number }>
    ) => {
      state.gAppToReject = action.payload;
      state.deleteModalOpen = true;
    },
    resetAppIdToReject: (state) => {
      state.gAppToReject = undefined;
    },
    updateGeneralApplicationTable: (state, action: PayloadAction<ISearchTable>) => {
      state.generalApplicationTable = action.payload;
    },
    setApplicationCommentsList: (state, action: PayloadAction<any[]>) => {
      state.applicationCommentsList = action.payload;
    },
    setNextStatus: (state, action: PayloadAction<string | undefined>) => {
      state.nextStatus = action.payload;
    },
    setSelectedStudentRecordId: (state, action: PayloadAction<string | number | undefined>) => {
      state.selectedStudentRecordId = action.payload;
    },

    setEditDataApp: (state, action: PayloadAction<boolean>) => {
      state.editDataApp = action.payload;
    },

    setEducationLevelIdSelected: (state, action: PayloadAction<number | null>) => {
      state.educationLevelIdSelected = action.payload;
    },
    setDateRangeSelected: (state, action: PayloadAction<DateRange<Date | null>>) => {
      const startDate = action.payload[0] ? format(action.payload[0], 'dd-MMM-yyyy') : null;
      const endDate = action.payload[1] ? format(action.payload[1], 'dd-MMM-yyyy') : null;

      state.dateRangeSelected = [startDate, endDate];
    },
    setAppTypeIdSelected: (state, action: PayloadAction<string | null>) => {
      state.appTypeIdSelected = action.payload;
    },
    setAppStateIdSelected: (state, action: PayloadAction<string | null>) => {
      state.appStateIdSelected = action.payload;
    },
  },
});

export const {
  setGeneralApplicationList,
  setDeleteModalOpen,
  openRejectAppModalWith,
  resetAppIdToReject,
  updateGeneralApplicationTable,
  setApplicationCommentsList,
  setNextStatus,
  setSelectedStudentRecordId,
  setEditDataApp,

  setEducationLevelIdSelected,
  setDateRangeSelected,
  setAppTypeIdSelected,
  setAppStateIdSelected,
} = generalApplicationSlice.actions;

export default generalApplicationSlice.reducer;
