import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISearchTable } from '../../../shared/interfaces/paginatedTable.interface';
import { Publication } from '../interfaces/publications.interfaces';

interface InvestigationLineSliceState {
  publicationsList: Publication[];

  publicationsTable: ISearchTable;
  deleteModalOpen: boolean;
  publicationIdToDelete?: string;
  keywordAddPersonPublication?: string;
  isOpenAddPersonPublicationDialog?: boolean;
}

const initialState: InvestigationLineSliceState = {
  publicationsList: [],
  deleteModalOpen: false,
  publicationsTable: {
    page: 1,
    perPage: 5,
    search: '',
  },
  keywordAddPersonPublication: '',
  isOpenAddPersonPublicationDialog: false,
};

const publicationsSlice = createSlice({
  name: 'publications',
  initialState,
  reducers: {
    setPublicationsList: (state, action: PayloadAction<Publication[]>) => {
      state.publicationsList = action.payload;
    },

    updatePublicationsStates: (state, action: PayloadAction<ISearchTable>) => {
      state.publicationsTable = action.payload;
    },
    setDeleteModalOpen: (state, action: PayloadAction<boolean>) => {
      state.deleteModalOpen = action.payload;
    },
    openDeleteModalWith: (state, action: PayloadAction<string>) => {
      state.publicationIdToDelete = action.payload;
      state.deleteModalOpen = true;
    },
    resetPublicationIdToDelete: (state) => {
      state.publicationIdToDelete = undefined;
    },

    setKeywordAddPersonPublication: (state, action: PayloadAction<string>) => {
      state.keywordAddPersonPublication = action.payload;
    },

    setIsOpenAddPersonPublicationDialog: (state, action: PayloadAction<boolean>) => {
      state.isOpenAddPersonPublicationDialog = action.payload;
    },
  },
});

export const {
  updatePublicationsStates,
  setPublicationsList,
  openDeleteModalWith,
  resetPublicationIdToDelete,
  setDeleteModalOpen,
  setKeywordAddPersonPublication,
  setIsOpenAddPersonPublicationDialog,
} = publicationsSlice.actions;

export default publicationsSlice.reducer;
