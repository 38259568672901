import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// import MainCardForm from '../../../../shared/components/cards/MainCardForm';
import DeleteButton from '../../../../shared/components/tables/buttons/DeleteButton';
import { useGetGroupConfigurationByStudentQuery } from '../../slices/clientApiSlice';
import SubCard from '../../../../shared/components/cards/SubCard';
import { useAppDispatch } from '../../../../../hooks/useRedux';
import { openDeleteModalWith } from '../../slices/clientSlice';
import { renderErrorOrEmptyRow, renderSkeletonRows } from '../../../../shared/helpers/render';
import { useAppSelector } from '../../../../../hooks/useRedux';
import useSharedGuard from '../../../../../hooks/useSharedGuard';
import { groupConfigurationStudentsPermissions } from '../../constants/permissions';

const ListDiscount = () => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const { idPerson } = useParams<{ idPerson: string }>();

  const { clientList } = useAppSelector((state) => state.client);

  const student = clientList.find((c) => c.id === Number(idPerson));

  const { hasAccess } = useSharedGuard(groupConfigurationStudentsPermissions);

  const {
    data: listDiscount = [],
    isFetching,
    error,
  } = useGetGroupConfigurationByStudentQuery({
    studentId: Number(student?.user?.student?.id!),
  });

  const defaultConfiguration = useAppSelector((state) => state.access.defaultConfiguration);

  const renderRow = listDiscount.map((r) => {
    let parent = r.group_configuration.group_configuration_product.find((g) => !g.gcp_parent_id);
    const getValue = () => {
      if (Number(parent?.gcp_amount) > 0) {
        return `${defaultConfiguration?.currency_symbol} ${Number(parent?.gcp_amount)}`;
      } else {
        return `${Number(parent?.gcp_percent)} %`;
      }
    };

    return (
      <TableRow key={r.id}>
        <TableCell width="20%" align="center">
          {r.group_configuration.gc_description}
        </TableCell>
        <TableCell width="20%" align="center">
          {getValue()}
        </TableCell>
        <TableCell width="20%" align="center">
          {r.group_configuration.gc_end_date || '-'}
        </TableCell>
        <TableCell width="20%" align="center">
          {r.group_configuration?.gc_end_date || '-'}
        </TableCell>
        <TableCell width="20%" align="center">
          {hasAccess('delete') && <DeleteButton handleDelete={() => handleDelete(r.id)} />}
        </TableCell>
      </TableRow>
    );
  });

  const handleDelete = async (id: number) => {
    dispatch(openDeleteModalWith(id));
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <TableContainer component={SubCard} content={false}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">{t('financial.client_list.table.type')}</TableCell>
                <TableCell align="center">{t('financial.client_list.table.discount')}</TableCell>
                <TableCell align="center">
                  {t('financial.client_list.table.start_validity')}
                </TableCell>
                <TableCell align="center">
                  {t('financial.client_list.table.end_validity')}
                </TableCell>
                <TableCell align="center">{t('financial.client_list.table.action')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isFetching ? renderSkeletonRows(3, 5) : renderRow}
              {error && renderErrorOrEmptyRow(5, error as string)}
              {!isFetching && listDiscount.length === 0 && renderErrorOrEmptyRow(5)}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default ListDiscount;
