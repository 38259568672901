// import { useState } from 'react';
import { Box, Typography, CircularProgress, Button } from '@material-ui/core';
import { useParams } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import StatusChip from '../../../../shared/components/badges/StatusChip';
import { useAppSelector } from '../../../../../hooks/useRedux';

import { useUpdateEducationLevelMutation } from '../../../education-levels/slices/educationLevelsApiSlice';

import { EducationLevel } from '../../../education-levels/interfaces/educationLevels.interfaces';

const useStyles = makeStyles((theme) => ({
  root: {
    // border: '1px solid red',
    padding: '20px 20px 20px 30px',
    boxShadow: '0px 0px 20px #BFDAF9',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',

    position: 'relative',

    '&::after': {
      content: `""`,
      position: 'absolute',
      top: 0,
      left: 0,
      borderTopLeftRadius: '10px',
      borderBottomLeftRadius: '10px',
      width: '10px',
      height: '100%',
    },
  },
  cardRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    // backgroundColor: theme.palette.background.paper,
  },
  cardTitle: {
    color: theme.palette.dark.dark,
    fontSize: '14px',
    fontWeight: 600,
  },
  cardSubTitle: {
    color: theme.palette.grey[400],
    fontSize: '14px',
    fontWeight: 400,
  },

  subjectType: {
    color: theme.palette.secondary[900],
    fontSize: '12px',
    fontWeight: 400,
  },
  subjectTypeText: {
    color: theme.palette.grey[600],
    fontSize: '12px',
    fontWeight: 400,
  },
  preRequisitesText: {
    color: '#31355C',
    fontSize: '12px',
    fontWeight: 600,
  },
  cardBadges: {
    color: 'white',
    display: 'flex',
    gap: '4px',
    alignItems: 'center',
    flexWrap: 'wrap',
    // justifyContent: 'flex-start',
  },
  subjectPreRequisites: {
    display: 'flex',
    gap: '4px',
    flexWrap: 'wrap',
    marginTop: '0.5rem',
  },
}));

interface Props {
  career: EducationLevel;
}

const SubjectCard = ({ career }: Props) => {
  const classes = useStyles();
  // const dispatch = useAppDispatch();
  const { groupId } = useParams<{ groupId: string }>();

  const { currentProfile } = useAppSelector((state) => state.access);

  const getOpcityValue = () => {
    let opacity = 1;

    return opacity;
  };

  const [UpdateEducationLevel, { isLoading, isSuccess }] = useUpdateEducationLevelMutation();

  const isSelectCareer = async (dataCard: EducationLevel) => {
    await UpdateEducationLevel({
      profileId: currentProfile?.profileId!,
      educationLevelId: dataCard.id,
      educationLevelPayload: {
        edu_name: dataCard.edu_name,
        edu_alias: dataCard.edu_alias,
        edu_order: dataCard.edu_order,
        principal_id: dataCard.principal_id,
        offer_id: dataCard.offer_id,
        status_id: dataCard.status_id,
        group_area_id: groupId,
      },
    });
  };

  return (
    <Box
      className={classes.root}
      sx={{
        // bgcolor: subject.simbology.sim_color,
        bgcolor: isSuccess ? 'lightskyblue' : 'white',
        // background: 'linear-gradient(to left, rgba(119,202,202,0.1), rgba(119,202,202,0.9))',
        opacity: getOpcityValue,
      }}
    >
      <Box>
        <Box className={classes.cardRow}>
          <Typography variant="subtitle1" className={classes.cardTitle}>
            {career.edu_name}
          </Typography>
        </Box>
      </Box>

      {/* <Box className={classes.cardSubTitle}>
        <Typography variant="subtitle2">{career.edu_alias}</Typography>
      </Box> */}

      <Box className={classes.cardRow}>
        <Box sx={{ backgroundColor: 'white', borderRadius: '40px' }}>
          <StatusChip statusId={Number(career.status_id)} />
        </Box>
      </Box>
      <Box className={classes.cardBadges}>
        <Button
          onClick={() => isSelectCareer(career)}
          disabled={!isSuccess && !isLoading ? false : true}
          variant="contained"
          color="primary"
          sx={{ marginTop: 2 }}
        >
          {!isSuccess ? <>Agregar</> : <>Agregado</>}
          {isLoading && <CircularProgress size={17} sx={{ marginLeft: 1 }} />}
        </Button>
      </Box>
      <Box className={classes.cardRow}>
        <Typography variant="subtitle2" className={classes.subjectType}></Typography>
        <Typography variant="subtitle2" className={classes.subjectTypeText}></Typography>
      </Box>
    </Box>
  );
};

export default SubjectCard;
