import { Avatar, Box, ButtonBase, useTheme } from "@material-ui/core"
import { IconHelp } from "@tabler/icons"
import { setFaqsAdmin } from "../../slices/faqSlice";
import { useStyles } from '../../constants/styles';
import { useAppDispatch } from "../../../../../hooks/useRedux";

export const FaqsAdmin = () => {
   const dispatch = useAppDispatch();

   const classes = useStyles();

   const theme = useTheme();

   const handleToggleFaq = () => {
      dispatch(setFaqsAdmin(true));
   }

   return <Box component="span" className={classes.box}>
      <ButtonBase sx={{ borderRadius: '12px' }}>
         <Avatar
            variant="rounded"
            className={classes.headerAvatar}
            onClick={handleToggleFaq}
            color={theme.palette.primary.main}
         >
            <IconHelp stroke={1.5} size="1.3rem" />
         </Avatar>
      </ButtonBase>
   </Box>
}