import {
  Autocomplete,
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core';
import { useState } from 'react';
import { useAppSelector } from '../../../../hooks/useRedux';
import { useGetCollaboratorCoursesQuery } from '../../../hr/collaborators/slices/collaboratorsApiSlice';
import { useGetAvailiablesPeriodsQuery } from '../../../maintenance/periods/slices/periodsApiSlice';
import InputLabel from '../../../shared/components/forms/InputLabel';
import IconTelework from '../../../shared/components/illustrations/IconTelework';
import CustomPagination from '../../../shared/components/tables/CustomPagination';
import SkeletonCard from '../../../students/academic-portfolio/components/skeleton/SkeletonCard';
// import CoursesCards from '../../courses/components/courses/CoursesCards';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import { DebounceInput } from 'react-debounce-input';
import SearchIcon from '@material-ui/icons/Search';
import { IconX } from '@tabler/icons';
import { useTranslation } from 'react-i18next';
import CourseCardNew from '../../courses/components/courses/CourseCardNew';

const CoursesList = () => {
  const { t } = useTranslation();

  const { currentProfile } = useAppSelector((state) => state.access);
  const { userInfo } = useAppSelector((state) => state.auth);
  const [ periodId,setPeriodId] = useState<number | null>();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(12);
  const [search, setSearch] = useState('');
  const theme = useTheme();

  const { data: periodsAvaliables = [], isLoading: isLoadingP } = useGetAvailiablesPeriodsQuery(
    {
      profileId: currentProfile?.profileId!,
      collaborator_id: userInfo?.collaboratorInfo?.id,
    },
    { refetchOnMountOrArgChange: 30 }
  );

  const { data, isFetching: isFetchingC } = useGetCollaboratorCoursesQuery(
    {
      collaboratorId: userInfo?.collaboratorInfo?.id!,
      profileId: currentProfile?.profileId!,
      page,
      perPage,
      search,
    },
    { refetchOnMountOrArgChange: 30 }
  );

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (newPerPage: number) => {
    setAnchorEl(null);
    setPerPage(newPerPage);
  };

  console.log(periodId);

  return (
    <Grid container item xs={12} mt={3}>
      {false && (
        <Grid container justifyContent="flex-start" spacing={2}>
          <Grid item xs={12} md={3}>
            <Grid item>
              <InputLabel>{t('teachers.dashboard.semester')}</InputLabel>
              <Autocomplete
                options={periodsAvaliables}
                loading={isLoadingP}
                noOptionsText={t('teachers.dashboard.semester.no_data')}
                loadingText={t('teachers.dashboard.semester.loading')}
                getOptionLabel={(option) => `${option.per_name}`}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={t('teachers.dashboard.semester.placeholder')}
                  />
                )}
                onChange={(_, value) => {
                  setPeriodId(Number(value?.id));
                }}
              />
            </Grid>
          </Grid>

          <Grid container item xs={12} md={3}>
            <InputLabel sx={{ color: 'white !important' }}>
              {t('teachers.dashboard.search')}
            </InputLabel>
            <DebounceInput
              autoFocus
              minLength={2}
              debounceTimeout={300}
              onChange={({ target }) => setSearch(target.value)}
              value={search}
              element={(props) => (
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon fontSize="small" />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          onClick={() => setSearch('')}
                          // {...(!search && { sx: { cursor: 'initial', opacity: 0 } })}
                        >
                          <IconX size={20} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  placeholder={t('teachers.dashboard.search')}
                  variant="outlined"
                  size="medium"
                  fullWidth
                  {...props}
                />
              )}
            />
          </Grid>
        </Grid>
      )}

      {false && (
        <Grid container item xs={12} mt={3}>
          <Grid item xs={12}>
            <Typography variant="h3">{t('teachers.dashboard.actual_courses')}</Typography>
          </Grid>
        </Grid>
      )}
      <Grid container mt={2} spacing={2}>
        {(!data || data?.data.length === 0) && !isFetchingC && (
          <Grid item xs={12} container alignItems="center" direction="column" spacing={2}>
            <Grid item>
              <Typography variant="subtitle1">{t('teachers.dashboard.no_data')}</Typography>
            </Grid>
            <Grid item container justifyContent={'center'} alignContent={'center'}>
              <Grid container justifyContent={'center'} item xs={8} md={6}>
                <Grid container item xs={12} justifyContent={'center'}>
                  <IconTelework size={300} />
                </Grid>
                <Typography variant={'body1'} textAlign={'center'} lineHeight={2} component={'div'}>
                  <Typography
                    variant={'h4'}
                    sx={{ color: theme.palette.primary.main }}
                    component={'p'}
                    align={'center'}
                    lineHeight={2}
                  >
                    {t('teachers.dashboard.semester.title')}
                  </Typography>
                  {t('teachers.dashboard.semester.subtitle')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}

        {isFetchingC
          ? [...new Array(6)].map((_, index) => <SkeletonCard key={index.toString()} />)
          : data?.data.map((courses) => (
              <CourseCardNew course={courses} withPeriod key={courses.id} />
            ))}

        {data && data.total > 12 && !isFetchingC && (
          <Grid
            item
            xs={12}
            container
            justifyContent="space-between"
            alignItems="flex-end"
            sx={{ mt: 4 }}
          >
            <Grid item>
              <CustomPagination
                shape="rounded"
                color="primary"
                count={Math.ceil(data.data.length / perPage)}
                page={page}
                onChange={(_, newPage) => {
                  setPage(newPage);
                }}
              />
            </Grid>
            <Grid>
              <Box
                component={Button}
                size="large"
                sx={{
                  color: 'grey.900',
                  backgroundColor: 'primary.light',
                  ':hover': { bgcolor: 'primary.light' },
                  px: 2,
                  borderRadius: 4,
                }}
                endIcon={<ExpandMoreRoundedIcon />}
                onClick={handleClick}
              >
                {perPage} {t('teachers.dashboard.records')}
              </Box>

              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={() => handleClose(perPage)}
                variant="selectedMenu"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <MenuItem onClick={() => handleClose(6)}>
                  6 {t('teachers.dashboard.records')}
                </MenuItem>
                <MenuItem onClick={() => handleClose(12)}>
                  12 {t('teachers.dashboard.records')}
                </MenuItem>
                <MenuItem onClick={() => handleClose(18)}>
                  18 {t('teachers.dashboard.records')}
                </MenuItem>
              </Menu>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default CoursesList;
