import { useEffect } from 'react';

import Grid from '@material-ui/core/Grid';
import { Autocomplete, TextField, FormHelperText, Skeleton } from '@material-ui/core';

import InputLabel from '../../../../shared/components/forms/InputLabel';

import { useAppSelector } from '../../../../../hooks/useRedux';

import { useGetCatalogByAcronymQuery } from '../../../../shared/slices/catalogApi';
import { useGetCatalogByKeywordQuery } from '../../../../shared/slices/catalogApi';
import { useFormikContext } from 'formik';
import { Catalog } from '../../interfaces/person.interface';

interface CivilStatusForm {
  civilStatus: Catalog;
  relativeIdentificationType: Catalog;
  relativeIdentification: string;
  relativeFirstName: string;
  relativeSecondName: string;
  relativeLastName: string;
  relativeSecondLastName: string;
  relativeRelationDescription: string;
  typeKinshigKeyword: string;
  relativeGender: Catalog;
  relativeTypeReligion: Catalog;
  relativeEthnic: Catalog;
}

const CivilStatusStep = () => {
  const { currentProfile } = useAppSelector((state) => state.access);

  const { personData } = useAppSelector((state) => state.collaborators);

  const profileId = currentProfile!.profileId;

  const { getFieldProps, setFieldValue, errors, touched, setTouched, values } =
    useFormikContext<CivilStatusForm>();

  const { data: civilStatus = [], isLoading: isLoadingCS } = useGetCatalogByAcronymQuery({
    profileId,
    acronym: 'SM',
  });

  const { data: identificationTypes = [], isLoading: isLoadingIT } = useGetCatalogByAcronymQuery({
    profileId,
    acronym: 'TI',
  });

  const { data: genders = [], isLoading: isLoadingG } = useGetCatalogByKeywordQuery({
    profileId,
    keyword: 'GE',
  });

  const { data: religionTypes = [], isLoading: isLoadingReligion } = useGetCatalogByAcronymQuery({
    profileId,
    acronym: 'TR',
  });

  const { data: etnics = [], isLoading: isLoadingEtnic } = useGetCatalogByAcronymQuery({
    profileId,
    acronym: 'ET',
  });

  useEffect(() => {
    if (personData && !isLoadingG && !values?.civilStatus?.cat_name) {
      const relative = personData?.person_relative_secondary?.person_relative_secondary;

      setFieldValue('civilStatus', personData.status_marital);
      setFieldValue('relativeIdentificationType', relative?.type_identification);
      setFieldValue('relativeIdentification', relative?.pers_identification);
      setFieldValue('relativeFirstName', relative?.pers_firstname);
      setFieldValue('relativeSecondName', relative?.pers_secondname || '');
      setFieldValue('relativeLastName', relative?.pers_first_lastname);
      setFieldValue('relativeSecondLastName', relative?.pers_second_lastname || '');
      setFieldValue(
        'relativeRelationDescription',
        personData.person_relative_secondary?.rel_description
      );
      setFieldValue('typeKinshigKeyword', personData.person_relative_secondary?.type_kinship_id);
      setFieldValue(
        'relativeGender',
        genders?.find((g) => g.cat_name === relative?.pers_gender)
      );
      setFieldValue('relativeTypeReligion', relative?.religion);
      setFieldValue('relativeEthnic', relative?.ethnic);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personData, isLoadingG]);

  useEffect(() => {
    setTouched({});
  }, [setTouched]);

  useEffect(() => {
    values.civilStatus?.cat_keyword === 'casado'
      ? (values.typeKinshigKeyword = 'esposo')
      : (values.typeKinshigKeyword = '');
  }, [values]);

  return (
    <Grid container spacing={4} alignItems="flex-start">
      <Grid item xs={12} md={6} container spacing={2}>
        <Grid item xs={12}>
          <InputLabel>Estado civil</InputLabel>
          {isLoadingCS ? (
            <Skeleton variant="rectangular" sx={{ width: '100%', borderRadius: 3, height: 50 }} />
          ) : (
            <Autocomplete
              options={civilStatus}
              loading={isLoadingCS}
              loadingText="Cargando estados civiles..."
              noOptionsText="No hay estados civiles disponibles"
              getOptionLabel={(option) => option.cat_name}
              value={
                civilStatus.find((cs) => cs.cat_keyword === values.civilStatus?.cat_keyword) || null
              }
              onChange={(_, value) => {
                setFieldValue('civilStatus', value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Seleccione el estado civil"
                  error={Boolean(errors.civilStatus && touched.civilStatus)}
                />
              )}
              fullWidth
              clearIcon={null}
            />
          )}
          {errors.civilStatus && touched.civilStatus && (
            <FormHelperText error>{errors.civilStatus}</FormHelperText>
          )}
        </Grid>

        {values.civilStatus?.cat_keyword === 'casado' && (
          <>
            <Grid item xs={12}>
              <InputLabel>Tipo de Identificación del cónyugue</InputLabel>
              {isLoadingIT ? (
                <Skeleton
                  variant="rectangular"
                  sx={{ width: '100%', borderRadius: 3, height: 50 }}
                />
              ) : (
                <Autocomplete
                  options={identificationTypes}
                  loading={isLoadingIT}
                  loadingText="Cargando tipos de indentificación..."
                  noOptionsText="No hay tipos de indentificación disponibles"
                  getOptionLabel={(option) => option.cat_name}
                  value={values.relativeIdentificationType || null}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Seleccione un tipo de identificación"
                      error={Boolean(
                        errors.relativeIdentificationType && touched.relativeIdentificationType
                      )}
                    />
                  )}
                  onChange={(_, value) => {
                    setFieldValue('relativeIdentificationType', value);
                  }}
                  fullWidth
                  clearIcon={null}
                />
              )}
              {errors.relativeIdentificationType && touched.relativeIdentificationType && (
                <FormHelperText error>{errors.relativeIdentificationType}</FormHelperText>
              )}
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Identificación</InputLabel>
              <TextField
                fullWidth
                placeholder="Ingrese su número de identificación"
                error={Boolean(errors.relativeIdentification && touched.relativeIdentification)}
                {...getFieldProps('relativeIdentification')}
              />
              {errors.relativeIdentification && touched.relativeIdentification && (
                <FormHelperText error>{errors.relativeIdentification}</FormHelperText>
              )}
            </Grid>
            <Grid item xs={12} container spacing={2}>
              <Grid item xs={6}>
                <InputLabel>Primer nombre</InputLabel>
                <TextField
                  fullWidth
                  placeholder="Primer nombre"
                  {...getFieldProps('relativeFirstName')}
                  error={Boolean(errors.relativeFirstName && touched.relativeFirstName)}
                />
                {errors.relativeFirstName && touched.relativeFirstName && (
                  <FormHelperText error>{errors.relativeFirstName}</FormHelperText>
                )}
              </Grid>
              <Grid item xs={6}>
                <InputLabel>Segundo name</InputLabel>
                <TextField
                  fullWidth
                  placeholder="Segundo nombre"
                  {...getFieldProps('relativeSecondName')}
                  error={Boolean(errors.relativeSecondName && touched.relativeSecondName)}
                />
                {errors.relativeSecondName && touched.relativeSecondName && (
                  <FormHelperText error>{errors.relativeSecondName}</FormHelperText>
                )}
              </Grid>
            </Grid>

            <Grid item xs={12} container spacing={2}>
              <Grid item xs={6}>
                <InputLabel>Apellido paterno</InputLabel>
                <TextField
                  fullWidth
                  placeholder="Apellido paterno"
                  {...getFieldProps('relativeLastName')}
                  error={Boolean(errors.relativeLastName && touched.relativeLastName)}
                />
                {errors.relativeLastName && touched.relativeLastName && (
                  <FormHelperText error>{errors.relativeLastName}</FormHelperText>
                )}
              </Grid>
              <Grid item xs={6}>
                <InputLabel>Apellido materno</InputLabel>
                <TextField
                  fullWidth
                  placeholder="Apellido materno"
                  {...getFieldProps('relativeSecondLastName')}
                  error={Boolean(errors.relativeSecondLastName && touched.relativeSecondLastName)}
                />
                {errors.relativeSecondLastName && touched.relativeSecondLastName && (
                  <FormHelperText error>{errors.relativeSecondLastName}</FormHelperText>
                )}
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
      <Grid item xs={12} md={6} container spacing={2}>
        {values.civilStatus?.cat_keyword === 'casado' && (
          <>
            <Grid item xs={12}>
              <InputLabel>Género</InputLabel>
              {isLoadingG ? (
                <Skeleton
                  variant="rectangular"
                  sx={{ width: '100%', borderRadius: 3, height: 50 }}
                />
              ) : (
                <Autocomplete
                  options={genders}
                  loading={isLoadingG}
                  loadingText="Cargando géneros..."
                  noOptionsText="No hay géneros disponibles"
                  getOptionLabel={(option) => option.cat_name || ''}
                  value={values.relativeGender || null}
                  onChange={(_, value) => {
                    setFieldValue('relativeGender', value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Seleccione un género"
                      error={Boolean(errors.relativeGender && touched.relativeGender)}
                    />
                  )}
                  fullWidth
                  clearIcon={null}
                />
              )}
              {errors.relativeGender && touched.relativeGender && (
                <FormHelperText error>{errors.relativeGender}</FormHelperText>
              )}
            </Grid>

            {/* Religion */}
            <Grid item xs={12}>
              <InputLabel>Religión</InputLabel>
              {isLoadingReligion ? (
                <Skeleton
                  variant="rectangular"
                  sx={{ width: '100%', borderRadius: 3, height: 50 }}
                />
              ) : (
                <Autocomplete
                  options={religionTypes}
                  loading={isLoadingReligion}
                  loadingText="Cargando religión..."
                  noOptionsText="No hay religiones disponibles"
                  getOptionLabel={(option) => option.cat_name}
                  value={values.relativeTypeReligion || null}
                  onChange={(_, value) => setFieldValue('relativeTypeReligion', value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Seleccione una religión"
                      error={Boolean(errors.relativeTypeReligion && touched.relativeTypeReligion)}
                    />
                  )}
                  fullWidth
                  clearIcon={null}
                />
              )}
              {errors.relativeTypeReligion && touched.relativeTypeReligion && (
                <FormHelperText error>{errors.relativeTypeReligion}</FormHelperText>
              )}
            </Grid>

            {/* Etnia */}
            <Grid item xs={12}>
              <InputLabel>Etnia</InputLabel>
              {isLoadingEtnic ? (
                <Skeleton
                  variant="rectangular"
                  sx={{ width: '100%', borderRadius: 3, height: 50 }}
                />
              ) : (
                <Autocomplete
                  options={etnics}
                  loading={isLoadingEtnic}
                  loadingText="Cargando etnias..."
                  noOptionsText="No hay etnias disponibles"
                  getOptionLabel={(option) => option.cat_name}
                  value={values.relativeEthnic || null}
                  onChange={(_, value) => setFieldValue('relativeEthnic', value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(errors.relativeEthnic && touched.relativeEthnic)}
                      placeholder="Seleccionar etnia correspondiente"
                    />
                  )}
                  fullWidth
                  clearIcon={null}
                />
              )}
              {errors.relativeEthnic && touched.relativeEthnic && (
                <FormHelperText error>{errors.relativeEthnic}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12}>
              <InputLabel>Descripción</InputLabel>
              <TextField
                multiline
                rows={2}
                fullWidth
                placeholder="Descripción de la relación"
                error={Boolean(
                  errors.relativeRelationDescription && touched.relativeRelationDescription
                )}
                {...getFieldProps('relativeRelationDescription')}
              />
              {errors.relativeRelationDescription && touched.relativeRelationDescription && (
                <FormHelperText error>{errors.relativeRelationDescription}</FormHelperText>
              )}
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default CivilStatusStep;
