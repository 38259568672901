import { FC, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';
import Autocomplete from '@material-ui/core/Autocomplete';
import { useTranslation } from 'react-i18next';

import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import InputLabel from '../../../shared/components/forms/InputLabel';
import { statusAvailables } from '../../../shared/constants/resourceStatus';
import {
  useAddExternalLinkMutation,
  useUpdateExternalLinkMutation,
} from '../slices/externalLinksApiSlice';
import LoadingButton from '../../../shared/components/buttons/LoadingButton';
import DragArea from '../../../shared/components/files/DragArea';
import { ExternalLink } from '../../../students/dashboard/interfaces/dashboard.interface';
import { useUploadFilesMutation } from '../../../shared/slices/uploadFilesApi';
import { ExternalLinkSchema } from '../constants/validation-eschemas';

interface Props {
  editData?: ExternalLink;
}

const ExternalLinksForm: FC<Props> = ({ editData }) => {
  const { t } = useTranslation();

  const history = useHistory();

  const [showAlerts, setShowAlerts] = useState(true);

  const [file, setFile] = useState<File | null>(null);

  const [uploadFile, { isLoading: isUploadingImg }] = useUploadFilesMutation();

  const [
    addExternalLink,
    {
      isLoading: isLoadingCreate,
      isError: isErrorCreate,
      isSuccess: isSuccessCreate,
      error: errorCreate,
    },
  ] = useAddExternalLinkMutation();
  const [
    updExternalLink,
    {
      isLoading: isLoadingUpdate,
      isError: isErrorUpdate,
      isSuccess: isSuccessUpdate,
      error: errorUpdate,
    },
  ] = useUpdateExternalLinkMutation();

  const { getFieldProps, errors, touched, handleSubmit, setFieldValue, values } = useFormik({
    initialValues: {
      ext_lin_title: editData?.ext_lin_title || '',
      ext_lin_description: editData?.ext_lin_description || '',
      ext_lin_image_url: editData?.ext_lin_image_url || '',
      ext_lin_page_url: editData?.ext_lin_page_url || '',
      ext_lin_order: editData?.ext_lin_order || '0',
      status_id: editData?.status_id || '1',
    },
    validationSchema: ExternalLinkSchema,
    onSubmit: async ({ ext_lin_image_url, ..._values }) => {
      let urlDoc = ext_lin_image_url;

      if (!!file)
        urlDoc = await uploadFile({
          files: file!,
        }).unwrap();

      if (!!editData) {
        await updExternalLink({
          externalId: editData!.id,
          externalLinkPayload: {
            ..._values,
            ext_lin_image_url: urlDoc,
          },
        }).unwrap();
      } else {
        await addExternalLink({
          externalLinkPayload: {
            ..._values,
            ext_lin_image_url: urlDoc,
          },
        }).unwrap();
      }

      setShowAlerts(true);
    },
  });

  return (
    <form onSubmit={handleSubmit}>
      <Grid container gap={2}>
        <Grid item xs={12}>
          {(isErrorCreate || isErrorUpdate) && showAlerts && (
            <ErrorAlert
              message={(errorCreate || errorUpdate) as string}
              handleDismiss={() => setShowAlerts(false)}
            />
          )}

          {(isSuccessCreate || isSuccessUpdate) && showAlerts && (
            <SuccessAlert
              message={
                !!editData
                  ? t('academic.external_links.form.update')
                  : t('academic.external_links.form.success')
              }
              handleDismiss={() => setShowAlerts(false)}
            />
          )}
        </Grid>

        <Grid item container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <InputLabel>{t('academic.external_links.form.tittle')}</InputLabel>
            <TextField
              fullWidth
              {...getFieldProps('ext_lin_title')}
              error={Boolean(errors.ext_lin_title && touched.ext_lin_title)}
              placeholder={t('academic.external_links.form.tittle.placeholder')}
            />
            {errors.ext_lin_title && touched.ext_lin_title && (
              <FormHelperText error>{t(errors.ext_lin_title)}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12}>
            <InputLabel>{t('academic.external_links.form.url')}</InputLabel>
            <TextField
              fullWidth
              {...getFieldProps('ext_lin_page_url')}
              error={Boolean(errors.ext_lin_page_url && touched.ext_lin_page_url)}
              placeholder={t('academic.external_links.form.url.placeholder')}
              type="url"
            />
            {errors.ext_lin_page_url && touched.ext_lin_page_url && (
              <FormHelperText error>{t(errors.ext_lin_page_url)}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12}>
            <InputLabel>{t('academic.external_links.form.description')}</InputLabel>
            <TextField
              fullWidth
              {...getFieldProps('ext_lin_description')}
              error={Boolean(errors.ext_lin_description && touched.ext_lin_description)}
              placeholder={t('academic.external_links.form.description.placeholder')}
            />
            {errors.ext_lin_description && touched.ext_lin_description && (
              <FormHelperText error>{t(errors.ext_lin_description)}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12}>
            <DragArea
              handleChange={(e) => {
                setFieldValue('ext_lin_image_url', 'd');
                setFile(e.target.files![0]);
              }}
              label={t('academic.external_links.form.file.drag_label')}
              // description="La foto del director debe ser una imagen de no más de 2MB"
              typesAccepted="image/*"
            />

            {errors.ext_lin_image_url && touched.ext_lin_image_url && (
              <FormHelperText error>{t(errors.ext_lin_image_url)}</FormHelperText>
            )}
          </Grid>

          {!!editData && (
            <Grid item xs={12}>
              <InputLabel>{t('shared.input.status.label')}</InputLabel>
              <Autocomplete
                options={statusAvailables}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField {...params} error={Boolean(errors.status_id && touched.status_id)} />
                )}
                value={
                  statusAvailables.find((status) => status.id === +values.status_id) ||
                  statusAvailables[0]
                }
                onChange={(e, value) => setFieldValue('status_id', value?.id || '')}
                fullWidth
                clearIcon={null}
              />
              {errors.status_id && touched.status_id && (
                <FormHelperText error>{t(errors.status_id)}</FormHelperText>
              )}
            </Grid>
          )}
        </Grid>

        <Grid
          item
          container
          alignItems="center"
          justifyContent="flex-end"
          spacing={2}
          sx={{ mt: 1 }}
        >
          <Grid item>
            <Button
              variant="outlined"
              size="large"
              color="primary"
              onClick={() => history.goBack()}
            >
              {t('shared.cancel_button.label')}
            </Button>
          </Grid>
          <Grid item>
            <LoadingButton
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              isLoading={isLoadingCreate || isLoadingUpdate || isUploadingImg}
            >
              {editData ? t('shared.save_button.label') : t('shared.add_button.tooltip')}
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default ExternalLinksForm;
