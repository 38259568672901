import { Grid, IconButton, Stack, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { TiktokLogo } from 'phosphor-react';
import InstagramInIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import YoutubeInIcon from '@material-ui/icons/YouTube';
import { useAppSelector } from '../../../../hooks/useRedux';
import AnimateButton from '../extended/AnimateButton';

const GradientInstagram = () => (
  <>
    <svg width={0} height={0}>
      <linearGradient
        id="linearColors"
        gradientTransform="rotate(45)" /* x1={1} y1={0} x2={1} y2={1} */
      >
        <stop offset="0%" stopColor="#f09433" />
        <stop offset="25%" stopColor="#e6683c" />
        <stop offset="50%" stopColor="#dc2743" />
        <stop offset="75%" stopColor="#cc2366" />
        <stop offset="100%" stopColor="#bc1888" />
      </linearGradient>
    </svg>
    <InstagramInIcon fontWeight="800" sx={{ fill: 'url(#linearColors)' }} />
  </>
);

const SocialNetworks = () => {
  const { companySocialNetwork } = useAppSelector((state) => state.access);

  const { t } = useTranslation();

  const socialNetworkHandler = (socialNet: string) => {
    window.open(socialNet);
  };

  return (
    <Stack justifyContent="center" alignItems="center">
      <Typography
        variant="body2"
        color="textSecondary"
        textAlign="center"
        sx={{
          my: 1,
        }}
      >
        {t('auth.login.social_network')}
      </Typography>
      <Grid container justifyContent="center" alignItems="center" spacing={2}>
        {companySocialNetwork?.co_facebook && (
          <Grid item xs="auto">
            <AnimateButton>
              <IconButton
                onClick={() => socialNetworkHandler(companySocialNetwork?.co_facebook || '')}
                sx={{
                  width: '25px',
                }}
              >
                <FacebookIcon sx={{ color: '#3b5998' }} />
              </IconButton>
            </AnimateButton>
          </Grid>
        )}

        {companySocialNetwork?.co_instagram && (
          <Grid item xs="auto">
            <AnimateButton>
              <IconButton
                onClick={() => socialNetworkHandler(companySocialNetwork?.co_instagram || '')}
                sx={{
                  width: '25px',
                }}
              >
                <GradientInstagram />
              </IconButton>
            </AnimateButton>
          </Grid>
        )}

        {companySocialNetwork?.co_linkedin && (
          <Grid item xs="auto">
            <AnimateButton>
              <IconButton
                onClick={() => socialNetworkHandler(companySocialNetwork?.co_linkedin || '')}
                sx={{
                  width: '25px',
                }}
              >
                <LinkedInIcon sx={{ color: '#0A66C2' }} />
              </IconButton>
            </AnimateButton>
          </Grid>
        )}

        {companySocialNetwork?.co_tiktok && (
          <Grid item xs="auto">
            <IconButton
              onClick={() => socialNetworkHandler(companySocialNetwork?.co_tiktok || '')}
              sx={{
                p: '0px !important',
              }}
            >
              <TiktokLogo
                style={{
                  color: '#111111',
                  filter: 'drop-shadow(2px 0px 0px #FD3E3E) drop-shadow(-2px -2px 0px #4DE8F4)',
                }}
                weight="fill"
              />
            </IconButton>
          </Grid>
        )}

        {companySocialNetwork?.co_youtube && (
          <Grid item xs="auto">
            <IconButton
              onClick={() => socialNetworkHandler(companySocialNetwork?.co_youtube || '')}
              sx={{
                width: '25px',
                p: '0px !important',
              }}
            >
              <YoutubeInIcon sx={{ color: '#FF0000' }} />
            </IconButton>
          </Grid>
        )}
      </Grid>
    </Stack>
  );
};

export default SocialNetworks;
