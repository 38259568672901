import { useState, useMemo } from 'react';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import { useParams } from 'react-router-dom';

import ProjectStageTable from '../../ProjectStageTable';
import { useGetProjectResearchPrincipalChildQuery } from '../../../../slices/InvestigationLinesApiSlice';
import { useAppSelector } from '../../../../../../../hooks/useRedux';
import { FilterArrow } from '../../../../../../shared/components/tables/SearchPaginatedTable';
import ProjectFilter from '../../ProjectFilter';
import typeProject from '../../../../constants/lists/typeProject';
import { useGetProjectPrincipalQuery } from '../../../../slices/InvestigationLinesApiSlice';
import { Skeleton } from '@material-ui/core';

export interface FilterData {
  project: FilterArrow;
}

export interface FilterPayloads {
  typeProject: string;
  statusProject: string;
  yearProject: string;
  lineResearchId: string;
  typeResearch: string;
}

const StagesTable = () => {
  const { page, perPage, search, filterArrow } = useAppSelector(
    (state) => state.investigationLines.projectTable
  );

  const { t } = useTranslation();

  const { projectId } = useParams<{ projectId: string }>();

  const [filterData, setFilterData] = useState<FilterPayloads>();

  const [sort, setSort] = useState<FilterData>({
    project: { name: 'id', direction: 'asc' },
  });

  const { projectPrincipalList } = useAppSelector((state) => state.investigationLines);

  const projectData = useMemo(
    () => projectPrincipalList?.find(({ id }) => id.toString() === projectId),
    [projectPrincipalList, projectId]
  );

  const { currentModule } = useAppSelector((state) => state.access);

  const { data, error, isLoading, isFetching } = useGetProjectResearchPrincipalChildQuery({
    projectId,
    search,
    sort: filterArrow?.name || '',
    type_sort: filterArrow?.direction || '',
    page,
    size: perPage,
    ...(filterData?.lineResearchId && { lineResearchId: filterData?.lineResearchId }),
    ...(filterData?.statusProject && { statusProject: filterData?.statusProject }),
    ...(filterData?.typeProject
      ? { typeProject: filterData?.typeProject }
      : {
          typeProject:
            typeProject.find(({ label }) => label === currentModule?.name)?.keyword ||
            'vinculacion',
        }),
    // ...(filterData?.typeProject && { typeProject: filterData?.typeProject }),
    ...(filterData?.yearProject && { yearProject: filterData?.yearProject }),
  });

  const { data: dataProject, isLoading: isLoadingProject } = useGetProjectPrincipalQuery(
    { projectId },
    { skip: !!projectData?.id }
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h2" mt={2}>
          {!isLoadingProject ? projectData?.name || dataProject?.name : <Skeleton width={'50%'} />}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <ProjectFilter dispatchFilterData={setFilterData} />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h3" color="inherit" my={2}>
          {t('Etapas')}
        </Typography>
        <ProjectStageTable
          data={data!}
          isLoading={isLoading}
          error={error}
          isFetching={isFetching}
          setSort={setSort}
          sort={sort}
          isStage={true}
        />
      </Grid>
    </Grid>
  );
};

export default StagesTable;
