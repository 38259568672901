import { useAppDispatch } from '../../../../../hooks/useRedux';
import { Grid, Typography } from '@material-ui/core';
import { useAppSelector } from '../../../../../hooks/useRedux';
// import MainCard from '../../../../shared/components/cards/MainCard';
import DeleteCommentDialog from '../../../../teachers/virtual-classroom/forum/screens/DeleteCommentDialog';
import { setShowImageModalOpen } from '../../../../teachers/virtual-classroom/slices/virtualClassroomTeacherSlice';
//import ForumCollapseList from '../components/ForumCollapseList';
import DialogImg from '../../../../shared/components/dialogs/DialogImg';
import ForumInfiniteList from '../components/ForumInfiniteList';
import { useTranslation } from 'react-i18next';
import MainCardForm from '../../../../shared/components/cards/MainCardForm';
const ForumScreen = () => {
  const dispatch = useAppDispatch();
  const { commentIdToDelete, imgModalOpen, imgNameToShow } = useAppSelector(
    (state) => state.virtualClassroomTeacher
  );

  const { t } = useTranslation();

  return (
    <>
      <MainCardForm>
        <Grid sx={{ mb: 3 }}>
          <Typography variant="h2">{t('studentVirtualClassroom.forum.forum')}</Typography>
        </Grid>
        {/*<ForumCollapseList />*/}

        <ForumInfiniteList />
        {commentIdToDelete && <DeleteCommentDialog />}
        {imgModalOpen && (
          <DialogImg
            open={imgModalOpen}
            img={imgNameToShow!}
            onClose={() => dispatch(setShowImageModalOpen(false))}
          />
        )}
      </MainCardForm>
    </>
  );
};
export default ForumScreen;
