import { Divider, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../../../../../hooks/useRedux';
import useFormatDate from '../../../../../../shared/hooks/useFormatDate';

const PreviewEvaluationInfoBox = () => {
  const { t } = useTranslation();

  const { id } = useParams<{ id: string }>();

  const { templateList } = useAppSelector((state) => state.templateEva);

  const infoEvaluationToDo = templateList.find((t) => t.id === Number(id));

  const { formatDate } = useFormatDate();

  return (
    <Grid container spacing={2} mt={2} alignItems="center">
      <Grid item xs={12} md={4}>
        <Typography variant="h2" color="primary">
          {infoEvaluationToDo?.eva_types.name}
        </Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <Typography variant="h6">
          <strong>{t('user_evaluation.evaluation.info.evaluated')}:</strong> -
        </Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <Typography variant="h3" align="right">
          0/
          {infoEvaluationToDo?.eva_evaluations_questions?.length || 1}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h6">
          <strong>{t('user_evaluation.evaluation.info.date')}:</strong>{' '}
          {`${formatDate(new Date())}`}
        </Typography>
        <Typography
          variant="h6"
          sx={{
            mt: 1,
          }}
        >
          <strong>{t('user_evaluation.evaluation.info.enddate')}:</strong> {'  -'}
        </Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        <Typography variant="h6">
          <strong>{t('Periodo')}:</strong> -
        </Typography>
        <Typography variant="h6">
          <strong>{t('Facultad')}:</strong> -
        </Typography>
        <Typography variant="h6">
          <strong>{t('Materia')}:</strong> -
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        sx={{
          mt: 1,
        }}
      >
        <Typography variant="h6">
          Criterios de evaluación de{' '}
          <strong>{`${infoEvaluationToDo?.eva_evaluations_template.options[0].value}`}</strong> a{' '}
          <strong>
            {`${
              infoEvaluationToDo?.eva_evaluations_template.options[
                infoEvaluationToDo?.eva_evaluations_template.options.length - 1
              ].value
            }`}
          </strong>
        </Typography>
        <Typography
          variant="h6"
          sx={{
            mt: 1,
          }}
        >
          Siendo{' '}
          {infoEvaluationToDo?.eva_evaluations_template.options.map((option, idx) => (
            <>
              <strong key={option.uuid}>
                {option.description} = {option.value}
              </strong>
              {idx < infoEvaluationToDo?.eva_evaluations_template.options.length! - 1 ? ' - ' : ''}
            </>
          ))}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default PreviewEvaluationInfoBox;
