import { useEffect, useState } from 'react';

import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

import { newOptionIntervals, newOptionIntervalsEdit } from '../constants/optionsTemplate.constants';
import { representativeScaleFormSchema } from '../validation/optionsTemplate.validation';
import {
  useAddRepresentativeScaleMutation,
  useUpdateRepresentativeScaleMutation,
} from '../slices/optionsTemplateApiSlice';
import { useAppSelector } from '../../../../../../hooks/useRedux';
import {
  Alert,
  Autocomplete,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import SuccessAlert from '../../../../../shared/components/alerts/SuccessAlert';
import ErrorAlert from '../../../../../shared/components/alerts/ErrorAlert';
import AddIconButton from '../../../../../shared/components/buttons/AddIconButton';
import DeleteButton from '../../../../../shared/components/tables/buttons/DeleteButton';
import { EvaluationScaleDetailOptions } from '../interfaces/representativeScale.interface';

const RepresentativeScaleForm = () => {
  const { t } = useTranslation();
  const [showAlerts, setShowAlerts] = useState(false);

  const { id } = useParams<{
    id: string;
  }>();

  const [addOptionsTemplate, { isLoading, isError, error, isSuccess }] =
    useAddRepresentativeScaleMutation();

  const [
    updateOptionsTemplate,
    { isLoading: isLoadingU, isError: isErrorU, error: errorU, isSuccess: isSuccessU },
  ] = useUpdateRepresentativeScaleMutation();

  const { represensativeScaleList, optionsTemplateSideDialogOpen } = useAppSelector(
    (state) => state.optionsTemplate
  );

  const represensativeScaleSelected = represensativeScaleList.find(
    (option) => Number(option.id) === Number(id)
  );

  const statusOptions = [
    { value: 'A', label: t('maintenance.scopes.status.active') },
    { value: 'I', label: t('maintenance.scopes.status.inactive') },
  ];

  const { errors, getFieldProps, handleSubmit, touched, values, setFieldValue, resetForm } =
    useFormik({
      initialValues: {
        description: represensativeScaleSelected?.description || '',
        name: represensativeScaleSelected?.name || '',
        keyword: represensativeScaleSelected?.keyword || '',
        status: represensativeScaleSelected?.status || 'A',
        evaluation_scale_detail: represensativeScaleSelected?.evaluation_scale_detail || [
          newOptionIntervals,
          newOptionIntervals,
        ],
      },
      validationSchema: representativeScaleFormSchema,
      onSubmit: async (currentValues) => {
        if (id) {
          await updateOptionsTemplate({
            id: id,
            payload: {
              ...currentValues,
            },
          });
        } else {
          await addOptionsTemplate({
            ...currentValues,
          });
        }
        setShowAlerts(true);
      },
    });

  useEffect(() => {
    if (isSuccess) {
      resetForm();
    }
  }, [isSuccess, resetForm]);

  const handleAddOption = () => {
    if (id) {
      setFieldValue('evaluation_scale_detail', [
        ...values.evaluation_scale_detail,
        newOptionIntervalsEdit,
      ]);
    } else {
      setFieldValue('evaluation_scale_detail', [
        ...values.evaluation_scale_detail,
        newOptionIntervals,
      ]);
    }
  };

  const handleDeleteOption = (index: number) => {
    setFieldValue(
      'evaluation_scale_detail',
      values.evaluation_scale_detail.filter((_, i) => i !== index)
    );
  };

  return (
    <Grid container spacing={2}>
      {(isSuccess || isSuccessU) && showAlerts && (
        <Grid item xs={12}>
          <SuccessAlert
            message={
              isSuccess
                ? t('maintenance.options_template.add.success')
                : t('maintenance.options_template.edit.success')
            }
            handleDismiss={() => setShowAlerts(false)}
          />
        </Grid>
      )}
      {(isError || isErrorU) && showAlerts && (
        <Grid item xs={12}>
          <ErrorAlert
            message={(error as string) || (errorU as string)}
            handleDismiss={() => setShowAlerts(false)}
          />
        </Grid>
      )}

      <Grid item xs={12}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={optionsTemplateSideDialogOpen ? 12 : 8}>
              <InputLabel>{t('maintenance.options_template.form.name')}</InputLabel>
              <TextField
                placeholder={t('maintenance.options_template.form.name.placeholder')}
                error={Boolean(errors.name && touched.name)}
                {...getFieldProps('name')}
                fullWidth
              />
              {errors.name && touched.name && (
                <FormHelperText error>{t(errors.name)}</FormHelperText>
              )}
            </Grid>
            <Grid item xs={12} md={optionsTemplateSideDialogOpen ? 12 : 8}>
              <InputLabel>{t('Descripción')}</InputLabel>
              <TextField
                placeholder={t('maintenance.options_template.form.name.placeholder')}
                error={Boolean(errors.description && touched.description)}
                {...getFieldProps('description')}
                fullWidth
              />
              {errors.description && touched.description && (
                <FormHelperText error>{t(errors.description)}</FormHelperText>
              )}
            </Grid>
            <Grid item xs={12} md={optionsTemplateSideDialogOpen ? 12 : 8}>
              <InputLabel>{t('Palabra clave (Keyword)')}</InputLabel>
              <TextField
                placeholder={t('maintenance.options_template.form.name.placeholder')}
                error={Boolean(errors.keyword && touched.keyword)}
                {...getFieldProps('keyword')}
                fullWidth
              />
              {errors.keyword && touched.keyword && (
                <FormHelperText error>{t(errors.keyword)}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12} md={optionsTemplateSideDialogOpen ? 12 : 8}>
              <InputLabel>{t('maintenance.scopes.form.status')}</InputLabel>
              <Autocomplete
                options={statusOptions}
                onChange={(event, value) => {
                  setFieldValue('status', value?.value);
                }}
                value={statusOptions.find(
                  (option) => option.value === getFieldProps('status').value
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={t('maintenance.scopes.form.status.placeholder')}
                    fullWidth
                  />
                )}
              />
              {errors.status && touched.status && (
                <FormHelperText error>{t(errors.status)}</FormHelperText>
              )}
            </Grid>

            <Grid
              item
              container
              xs={12}
              md={optionsTemplateSideDialogOpen ? 12 : 8}
              justifyContent="space-between"
              alignItems="center"
              mt={2}
            >
              <Typography variant="h4">{t('maintenance.options_template.form.options')}</Typography>
              <AddIconButton btnText="Agregar opción" onClick={handleAddOption} />
            </Grid>

            <Grid item xs={optionsTemplateSideDialogOpen ? 12 : 8} justifyContent="center">
              <Alert severity="info">{t('maintenance.options_template.form.options.min')}</Alert>
            </Grid>
            <Grid item xs={12} justifyContent="center">
              {values.evaluation_scale_detail?.length > 0 ? (
                <>
                  {values.evaluation_scale_detail.map((option, index) => (
                    <Grid
                      item
                      container
                      xs={12}
                      md={optionsTemplateSideDialogOpen ? 12 : 8}
                      spacing={1}
                      key={`option-${index + 1}`}
                      sx={{
                        my: 1,
                      }}
                    >
                      <Grid item xs={10}>
                        <Grid item>
                          <InputLabel>
                            {t('maintenance.options_template.form.options.name')}
                          </InputLabel>
                          <TextField
                            placeholder={t(
                              'maintenance.options_template.form.options.name.placeholder'
                            )}
                            error={Boolean(
                              (
                                errors.evaluation_scale_detail?.[
                                  index
                                ] as EvaluationScaleDetailOptions
                              )?.name && touched.evaluation_scale_detail?.[index]?.name
                            )}
                            {...getFieldProps(`evaluation_scale_detail[${index}].name`)}
                            fullWidth
                          />
                          {(errors.evaluation_scale_detail?.[index] as EvaluationScaleDetailOptions)
                            ?.name &&
                            touched.evaluation_scale_detail?.[index]?.name && (
                              <FormHelperText error>
                                {t(
                                  (
                                    errors.evaluation_scale_detail?.[
                                      index
                                    ] as EvaluationScaleDetailOptions
                                  ).name
                                )}
                              </FormHelperText>
                            )}
                        </Grid>
                        <Grid
                          item
                          container
                          spacing={1}
                          sx={{
                            mt: 1,
                            mb: 2,
                          }}
                        >
                          <Grid item xs={6}>
                            <InputLabel>{t('Desde')}</InputLabel>
                            <TextField
                              placeholder={t(
                                'maintenance.options_template.form.options.value.placeholder'
                              )}
                              error={Boolean(
                                (
                                  errors.evaluation_scale_detail?.[
                                    index
                                  ] as EvaluationScaleDetailOptions
                                )?.scale_start &&
                                  touched.evaluation_scale_detail?.[index]?.scale_start
                              )}
                              {...getFieldProps(`evaluation_scale_detail[${index}].scale_start`)}
                              fullWidth
                            />
                            {(
                              errors.evaluation_scale_detail?.[
                                index
                              ] as EvaluationScaleDetailOptions
                            )?.scale_start &&
                              touched.evaluation_scale_detail?.[index]?.scale_start && (
                                <FormHelperText error>
                                  {t(
                                    (
                                      errors.evaluation_scale_detail?.[
                                        index
                                      ] as EvaluationScaleDetailOptions
                                    ).scale_start
                                  )}
                                </FormHelperText>
                              )}
                          </Grid>
                          <Grid item xs={6}>
                            <InputLabel>{t('Hasta')}</InputLabel>
                            <TextField
                              placeholder={t(
                                'maintenance.options_template.form.options.value.placeholder'
                              )}
                              error={Boolean(
                                (
                                  errors.evaluation_scale_detail?.[
                                    index
                                  ] as EvaluationScaleDetailOptions
                                )?.scale_end && touched.evaluation_scale_detail?.[index]?.scale_end
                              )}
                              {...getFieldProps(`evaluation_scale_detail[${index}].scale_end`)}
                              fullWidth
                            />
                            {(
                              errors.evaluation_scale_detail?.[
                                index
                              ] as EvaluationScaleDetailOptions
                            )?.scale_end &&
                              touched.evaluation_scale_detail?.[index]?.scale_end && (
                                <FormHelperText error>
                                  {t(
                                    (
                                      errors.evaluation_scale_detail?.[
                                        index
                                      ] as EvaluationScaleDetailOptions
                                    ).scale_end
                                  )}
                                </FormHelperText>
                              )}
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item container xs={2} justifyContent="center" alignItems="center">
                        <DeleteButton handleDelete={() => handleDeleteOption(index)} />
                      </Grid>
                    </Grid>
                  ))}

                  {errors.evaluation_scale_detail &&
                    touched.evaluation_scale_detail &&
                    !Array.isArray(errors.evaluation_scale_detail) && (
                      <FormHelperText error>{t(errors.evaluation_scale_detail)}</FormHelperText>
                    )}
                </>
              ) : (
                <Grid item xs={12} md={optionsTemplateSideDialogOpen ? 12 : 8}>
                  <Typography variant="h5" textAlign="center">
                    {t('maintenance.options_template.form.options.no_data')}
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Grid
              item
              container
              xs={12}
              md={optionsTemplateSideDialogOpen ? 12 : 8}
              justifyContent="flex-end"
              spacing={2}
            >
              <Grid item>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  disabled={isLoading || isLoadingU}
                  endIcon={isLoading || (isLoadingU && <CircularProgress size={12} />)}
                >
                  {!id
                    ? t('maintenance.options_template.add.submit')
                    : t('maintenance.options_template.edit.submit')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default RepresentativeScaleForm;
