import { Grid, Typography } from '@material-ui/core';
//import { useAppSelector } from '../../../../hooks/useRedux';
import MainCard from '../../../shared/components/cards/MainCard';
import BookingTable from '../components/requesteds/BookingTable';
import LendsTable from '../components/requesteds/LendsTable';

const RequestedScreen = () => {
    //const { authorToDelete } = useAppSelector((state) => state.authors);
    return (
        <Grid component={MainCard} sx={{ padding: 2 }}>
            <Grid sx={{ mb: 3 }}>
                <Typography variant="h1">Libros Solicitados Pendientes de Entrega</Typography>
            </Grid>

            <LendsTable />

            <Grid sx={{ mt: 3, mb: 3 }}>
            <hr></hr>
            </Grid>


            <Grid sx={{ mb: 3 }}>
                <Typography variant="h1">Libros Prestados Pendientes de Entrega</Typography>
            </Grid>

            <BookingTable />

            {/* {authorToDelete && <DeleteAuthorDialog />} */}
        </Grid>
    );


}

export default RequestedScreen;