import { useEffect } from 'react';

import { Grid, IconButton, Typography, useTheme } from '@material-ui/core';
import { IconArrowNarrowLeft } from '@tabler/icons';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import MainCardForm from '../../../shared/components/cards/MainCardForm';
import IconComponent from '../../../shared/components/errors-boundary/IconComponent';
import CustomIcon from '../../../shared/components/illustrations/CustomIcon';
import { useGetSubjectsCurriculumStatusQuery } from '../../../students/subjects-register/slices/subjectsRegisterApiSlice';
import AssignGradeDialog from '../components/AssignGradeDialog';
import HeaderAssignGrades from '../components/HeaderAssignGrades';
import ListSubjects from '../components/ListSubjects';
import ListSubjectsApproved from '../components/ListSubjectsApproved';
import { fireDialogAssignGrade, resetAssignGradeState } from '../slices/AssignGradeSlice';
import IconWarn from '../../../../assets/images/illustrations/warn.svg';

const ScreenAssignGrade = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const { showAssignGradeDialog, studentRecord } = useAppSelector((state) => state.assignGrade);
  const history = useHistory();
  const theme = useTheme();

  const {
    data: materias = [],
    isFetching,
    error,
  } = useGetSubjectsCurriculumStatusQuery(
    { studentRecordId: studentRecord?.id!, assign_grades: true },
    { refetchOnMountOrArgChange: 30, skip: !studentRecord?.id }
  );

  useEffect(() => {
    return () => {
      dispatch(resetAssignGradeState());
    };
  }, [dispatch]);

  return (
    <MainCardForm>
      <Grid item xs={12}>
        <Grid item container alignItems="center" gap={1} sx={{ cursor: 'pointer' }}>
          <IconButton onClick={() => history.goBack()}>
            <IconArrowNarrowLeft size={30} color={theme.palette.text.dark} />
          </IconButton>
          <Typography variant="h2">{t('teachers.assign_grades.title')}</Typography>
        </Grid>
      </Grid>

      <Grid item container m={2}>
        <HeaderAssignGrades />
      </Grid>

      {studentRecord?.type_student?.te_acronym.toLowerCase() !== 'NIV'.toLowerCase() ? (
        <Grid container item spacing={2}>
          <Grid item container spacing={2}>
            <Grid item xs={12} md={6}>
              <ListSubjects courses={materias} isFetching={isFetching} error={error} />
            </Grid>
            <Grid item xs={12} md={6}>
              <ListSubjectsApproved courses={materias} isFetching={isFetching} error={error} />
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <IconComponent
          icon={<CustomIcon icon={IconWarn} size={250} />}
          title={t('teachers.assign_grades.no_access')}
          subtitle={t('teachers.assign_grades.no_access.description')}
        />
      )}

      <AssignGradeDialog
        open={showAssignGradeDialog}
        handleCloseDialog={() => dispatch(fireDialogAssignGrade(false))}
      />
    </MainCardForm>
  );
};

export default ScreenAssignGrade;
