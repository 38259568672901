// material ui
import { InputAdornment, IconButton } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

interface Props {
  showPassword: boolean;
  handleShowPassword: () => void;
}

const ToggleVisibility = ({ showPassword, handleShowPassword }: Props) => {
  return (
    <InputAdornment position="end">
      <IconButton
        aria-label="Mostrar contraseña."
        onClick={handleShowPassword}
        edge="end"
      >
        {showPassword ? <VisibilityOff /> : <Visibility />}
      </IconButton>
    </InputAdornment>
  );
};

export default ToggleVisibility;
