import { DateRange } from '@material-ui/lab';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { format } from 'date-fns';

import { ISearchTable } from '../../../shared/interfaces/paginatedTable.interface';
import { PosgraduatePlanningCalendar } from '../interfaces/posgraduatePlanningCalendars.interface';

interface PosgraduatePlanningCalendarsState {
  posgraduatePlanningCalendarsList: PosgraduatePlanningCalendar[];
  posgraduatePlanningCalendarsTable: ISearchTable;
  posgraduatePlanningCalendarIdSelected: string | null;
  posgraduatePlanningCalendarDeleteDialogOpen: boolean;
  posgraduatePlanningCalendarFormDialogOpen: boolean;

  datesArraySelected: DateRange<string | null> | null;
}

const initialState: PosgraduatePlanningCalendarsState = {
  posgraduatePlanningCalendarsList: [],
  posgraduatePlanningCalendarsTable: {
    page: 1,
    perPage: 5,
    search: '',
  },
  posgraduatePlanningCalendarIdSelected: null,
  posgraduatePlanningCalendarDeleteDialogOpen: false,
  posgraduatePlanningCalendarFormDialogOpen: false,

  datesArraySelected: [null, null],
};

const posgraduatePlanningCalendarsSlice = createSlice({
  name: 'posgraduatePlanningPosgraduateCalendar',
  initialState,
  reducers: {
    setPosgraduatePlanningCalendarsList: (state, action: PayloadAction<any[]>) => {
      state.posgraduatePlanningCalendarsList = action.payload;
    },
    setPosgraduatePlanningCalendarsTable: (state, action: PayloadAction<ISearchTable>) => {
      state.posgraduatePlanningCalendarsTable = action.payload;
    },
    setPosgraduatePlanningCalendarIdSelected: (state, action: PayloadAction<string | null>) => {
      state.posgraduatePlanningCalendarIdSelected = action.payload;
    },
    setPosgraduatePlanningCalendarDeleteDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.posgraduatePlanningCalendarDeleteDialogOpen = action.payload;
    },
    setPosgraduatePlanningCalendarFormDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.posgraduatePlanningCalendarFormDialogOpen = action.payload;
    },
    setDatesArraySelected: (state, action: PayloadAction<DateRange<Date | null>>) => {
      const startDate = action.payload[0] ? format(action.payload[0], 'dd-MMM-yyyy') : null;
      const endDate = action.payload[1] ? format(action.payload[1], 'dd-MMM-yyyy') : null;

      state.datesArraySelected = [startDate, endDate];
    },
  },
});

export const {
  setPosgraduatePlanningCalendarsList,
  setPosgraduatePlanningCalendarsTable,
  setPosgraduatePlanningCalendarIdSelected,
  setPosgraduatePlanningCalendarDeleteDialogOpen,
  setPosgraduatePlanningCalendarFormDialogOpen,
  setDatesArraySelected,
} = posgraduatePlanningCalendarsSlice.actions;

export default posgraduatePlanningCalendarsSlice.reducer;
