import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ProfessionalPractices from '../components/ProfessionalPractices';

import MainCard from '../../../shared/components/cards/MainCard';

const ProfessionalPracticesScreen = () => {
  const { t } = useTranslation();

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">
          {t('students.menu.applications.pre-professional-practice')}
        </Typography>
      </Grid>
      <ProfessionalPractices />
    </Grid>
  );
};
export default ProfessionalPracticesScreen;
