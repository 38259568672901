import { Grid, Typography } from '@material-ui/core';
import { useEffect } from 'react';
import { useAppDispatch } from '../../../../hooks/useRedux';
import MainCard from '../../../shared/components/cards/MainCard';
import { setBreadcrumbState } from '../../../shared/reducers/customizationReducer';

const TermsCoditions = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setBreadcrumbState(false));

    return () => {
      dispatch(setBreadcrumbState(true));
    };
  }, [dispatch]);

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">Términos y condiciones</Typography>
      </Grid>
    </Grid>
  );
};

export default TermsCoditions;
