export const permissionKey = 'periods';

export interface SubPermissionKeys {
  read: string;
  create: string;
  edit: string;
  delete: string;
}

export const subPermissionKeys: SubPermissionKeys = {
  read: 'periods-listar-periodos',
  create: 'periods-crear-periodo',
  edit: 'periods-actualizar-periodo',
  delete: 'periods-borrar-periodo',
};

