import Autocomplete from '@material-ui/core/Autocomplete';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LocalizationProvider } from '@material-ui/lab';
import DateRangePicker, { DateRange } from '@material-ui/lab/DateRangePicker';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';

import ErrorAlert from '../../../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../../../shared/components/alerts/SuccessAlert';
import {
  useAddCollaboratorProjectResearchMutation,
  useGetProjectsResearchAvailablesQuery,
} from '../../../slices/InvestigationLinesApiSlice';
import InputLabel from '../../../../../shared/components/forms/InputLabel';
import { collaboratorProjectResearch } from '../../../constants/validationSchemas.projects';
import { useLazyGetCollaboratorsQuery } from '../../../../../../modules/hr/collaborators/slices/collaboratorsApiSlice';
import CustomAsyncPaginatedSelect from '../../../../../shared/components/extended/Form/CustomAsyncPaginatedSelect';
import typeFunctionAvailables from '../../../constants/lists/typeFunction';
import TextFieldFetching from '../../../../../shared/components/TextFieldFetching';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/useRedux';
import {
  setIsLoadingAddCollaborator,
  setIsOpenCollaboratorTableModal,
  setProjectIdSelected,
} from '../../../slices/investigationLinesSlice';
import CollaboratorTableDialog from '../../CollaboratorTableDialog';
interface Props {
  projectId?: string;
}

const AddCollaboratorProjectForm = ({ projectId }: Props) => {
  const { t } = useTranslation();

  const history = useHistory();

  const dispatch = useAppDispatch();

  const [showAlerts, setShowAlerts] = useState(true);

  const { isOpenCollaboratorTableModal } = useAppSelector((state) => state.investigationLines);

  const [addCollaboratorProject, { isLoading, isError, error, isSuccess }] =
    useAddCollaboratorProjectResearchMutation();

  const { data: projectsResearchAvailables, isLoading: isLoadingProjects } =
    useGetProjectsResearchAvailablesQuery({});

  // const [collaboratorValue, setCollaboratorValue] = useState<any | null>(null);

  const [
    getCollaboratorTrigger,
    // { data: institutesAvailables, isLoading: isLoadingInstitutesAvailables },
  ] = useLazyGetCollaboratorsQuery();

  //TODO: crear hook en el componente del select
  const getCollaborators = async (search: string, page: number, perPage: number) => {
    const response = await getCollaboratorTrigger({
      page,
      perPage,
      search,
      coll_type: 'D',
      statusId: 1,
    }).unwrap();

    const hasMore = response.current_page < response.last_page;

    return {
      options: response.data.map((dt) => {
        return {
          value: dt.id,
          label: (dt as any).pers_full_lastname || (dt as any).pers_full_name,
          ...dt,
        };
      }),
      hasMore,
    };
  };

  useEffect(() => {
    dispatch(setIsLoadingAddCollaborator(isLoading));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  return (
    <Formik<{
      projectResearchId: string;
      collaboratorId: string | null;
      status: string;
      startDate: Date;
      endDate: Date;
      numberHours: number;
      isResearcher: boolean;
      typeFunction: string;
    }>
      initialValues={{
        projectResearchId: projectId || '',
        collaboratorId: '',
        status: 'active',
        startDate: new Date(),
        endDate: new Date(),
        numberHours: 0,
        isResearcher: false,
        typeFunction: '',
      }}
      validationSchema={collaboratorProjectResearch}
      onSubmit={async ({
        projectResearchId,
        collaboratorId,
        status,
        startDate,
        endDate,
        numberHours,
        isResearcher,
        typeFunction,
      }) => {
        await addCollaboratorProject({
          collaboratorProjectPayload: {
            collaborator_id: (collaboratorId as any).id || collaboratorId || '0',
            is_researcher: isResearcher,
            number_hours: numberHours,
            project_research_id: projectResearchId,
            start_date: startDate,
            end_date: endDate,
            type_function: typeFunction,
            // status: statusAvailables.find((e) => e.id.toString() === status)?.acronym || '',
            status,
          },
        });
        setShowAlerts(true);
      }}
    >
      {({ values, setFieldValue, handleSubmit, errors, touched, getFieldProps }) => (
        <form onSubmit={handleSubmit}>
          <Grid container direction="column" gap={2}>
            <Grid item xs={12} md={6}>
              <Grid container direction="column" spacing={2}>
                {isError && showAlerts && (
                  <ErrorAlert
                    message={error as string}
                    handleDismiss={() => setShowAlerts(false)}
                  />
                )}

                {isSuccess && showAlerts && (
                  <SuccessAlert
                    message={t('Docente agregado con éxito')}
                    handleDismiss={() => setShowAlerts(false)}
                  />
                )}

                <Grid item xs={12}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateRangePicker
                      startText={null}
                      endText={null}
                      value={[values.startDate, values.endDate]}
                      onChange={(newDatesValues: DateRange<Date>) => {
                        const [start, end] = newDatesValues;
                        // update formik values
                        setFieldValue('startDate', start);
                        setFieldValue('endDate', end);
                      }}
                      renderInput={(startProps, endProps) => (
                        <Grid container spacing={2} alignItems="center">
                          <Grid item xs={12} md={6}>
                            <InputLabel>Fecha de Inicio:</InputLabel>
                            <TextField
                              fullWidth
                              {...startProps}
                              helperText=""
                              error={Boolean(errors.startDate && touched.startDate)}
                              {...getFieldProps('startDate')}
                            />
                            {errors.startDate && touched.startDate && (
                              <FormHelperText error>{errors.startDate}</FormHelperText>
                            )}
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <InputLabel>Fecha de Fin planeación:</InputLabel>
                            <TextField
                              fullWidth
                              {...endProps}
                              helperText=""
                              error={Boolean(errors.endDate && touched.endDate)}
                              {...getFieldProps('endDate')}
                            />

                            {errors.endDate && touched.endDate && (
                              <FormHelperText error>{errors.endDate}</FormHelperText>
                            )}
                          </Grid>
                        </Grid>
                      )}
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={12}>
                  <InputLabel>{t('Proyectos de investigación')}</InputLabel>
                  {isLoadingProjects ? (
                    <TextFieldFetching />
                  ) : (
                    <Autocomplete
                      options={projectsResearchAvailables || []}
                      loading={isLoadingProjects}
                      loadingText={t('academic.subjects.add.edu_level.loading')}
                      noOptionsText={t('academic.subjects.add.edu_level.no_data')}
                      getOptionLabel={(option) => option.name}
                      disabled={!!projectId}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={Boolean(errors.projectResearchId && touched.projectResearchId)}
                          placeholder={t('academic.subjects.add.edu_level.placeholder')}
                        />
                      )}
                      onChange={(_, value) => {
                        if (value) {
                          setFieldValue('projectResearchId', value?.id || '');
                          dispatch(setProjectIdSelected(value?.id || ''));
                        } else {
                          setFieldValue('projectResearchId', '');
                          dispatch(setProjectIdSelected(null));
                        }
                      }}
                      value={projectsResearchAvailables?.find(
                        ({ id }) => id === values.projectResearchId
                      )}
                      fullWidth
                      clearIcon={null}
                    />
                  )}
                  {errors.projectResearchId && touched.projectResearchId && (
                    <FormHelperText error>{t(errors.projectResearchId)}</FormHelperText>
                  )}
                </Grid>

                <Grid item xs={12} md={9}>
                  <InputLabel>{t('Número de Horas')}</InputLabel>
                  <TextField
                    {...getFieldProps('numberHours')}
                    error={Boolean(errors.numberHours && touched.numberHours)}
                    placeholder={t('academic.subjects.add.numberHours.placeholder')}
                    fullWidth
                  />
                  {errors.numberHours && touched.numberHours && (
                    <FormHelperText error>{t(errors.numberHours)}</FormHelperText>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <InputLabel>Tipo de función</InputLabel>
                  <Autocomplete
                    options={typeFunctionAvailables}
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={Boolean(errors.typeFunction && touched.typeFunction)}
                        placeholder="Seleccione un estado disponible"
                      />
                    )}
                    // defaultValue={typeFunctionAvailables[0]}
                    onChange={(e, value) => setFieldValue('typeFunction', value?.keyword || '')}
                    fullWidth
                    clearIcon={null}
                  />
                  {errors.typeFunction && touched.typeFunction && (
                    <FormHelperText error>{errors.typeFunction}</FormHelperText>
                  )}
                </Grid>

                <Grid item xs={12}>
                  {values.typeFunction && values.typeFunction !== 'DIRECTOR' && (
                    <>
                      <Button
                        disabled={!values.projectResearchId}
                        variant="outlined"
                        color="primary"
                        onClick={() => dispatch(setIsOpenCollaboratorTableModal(true))}
                        sx={{ mt: 1, textTransform: 'none' }}
                      >
                        Ir a tabla de colaboradores
                      </Button>
                      <FormHelperText
                        sx={{
                          my: 2,
                        }}
                      >
                        Complete todos los campos antes de ir a la tabla de colaboradores
                      </FormHelperText>
                    </>
                  )}
                </Grid>

                {values.typeFunction === 'DIRECTOR' && (
                  <Grid item xs={12}>
                    <InputLabel>Colaborador</InputLabel>
                    <CustomAsyncPaginatedSelect
                      key={'collaboratorId'}
                      value={values.collaboratorId}
                      isError={
                        'collaboratorId' in errors && touched['collaboratorId'] ? true : false
                      }
                      placeholder={'Escoger colaborador'}
                      setValue={(value) => {
                        setFieldValue('collaboratorId', value);
                      }}
                      fetchFunc={getCollaborators as any}
                    />
                    {errors.collaboratorId && touched.collaboratorId && (
                      <FormHelperText error>{t(errors.collaboratorId)}</FormHelperText>
                    )}
                  </Grid>
                )}
              </Grid>

              <Grid
                item
                container
                alignItems="center"
                justifyContent="center"
                spacing={2}
                sx={{ mt: 6 }}
              >
                <Grid item xs={6}>
                  {!projectId && (
                    <Button
                      fullWidth
                      variant="outlined"
                      size="large"
                      color="primary"
                      onClick={() => history.goBack()}
                    >
                      {t('academic.subjects.add.back')}
                    </Button>
                  )}
                </Grid>
                <Grid item xs={6}>
                  {values.typeFunction === 'DIRECTOR' && (
                    <Button
                      fullWidth
                      type="submit"
                      variant="contained"
                      size="large"
                      color="primary"
                      disabled={isLoading}
                      endIcon={isLoading && <CircularProgress size={20} />}
                    >
                      {t('academic.subjects.add.submit')}
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {isOpenCollaboratorTableModal && <CollaboratorTableDialog />}
        </form>
      )}
    </Formik>
  );
};

export default AddCollaboratorProjectForm;
