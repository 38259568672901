import { Box, Grid, Tab, Tabs, Typography } from '@material-ui/core';
import { useAppSelector } from '../../../../hooks/useRedux';
import { useGetMeshQuery } from '../../../maintenance/meshs/slices/meshsApiSlice';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import LoadingScreen from '../../../shared/screens/LoadingScreen';
import { useEffect, useMemo, useState } from 'react';
import { MatterMesh } from '../../../maintenance/meshs/interfaces/curriculum.interfaces';
import CurriculumTab from './CurriculumTab';
import CurriculumItem from './CurriculumItem';
import { useGetStudentYearsPeriodsQuery } from '../../../maintenance/students/slices/studentsApiSlice';
import { useGetSubjectsCurriculumStatusQuery } from '../../subjects-register/slices/subjectsRegisterApiSlice';
import PeriodBadge from '../../../maintenance/meshs/components/builder/PeriodBadge';
import LoadingChildren from '../../../shared/components/LoadingChildren';

const StudentCurriculum = () => {
  const { currentRecordStudent, currentProfile } = useAppSelector((state) => state.access);

  const [periodId, setPeriodId] = useState<number | null>(null);

  const [activeGroup, setActiveGroup] = useState<number>(1);

  const { data, isLoading, isError } = useGetMeshQuery(
    {
      meshId: +currentRecordStudent?.mesh_id!,
      profileId: currentProfile?.profileId!,
    },
    {
      skip: !currentRecordStudent?.mesh_id || !currentProfile?.profileId,
    }
  );

  //Carga de periodos y studentRecords
  const { data: periods, isLoading: isLoadingPeriods } = useGetStudentYearsPeriodsQuery(
    {
      studentId: currentRecordStudent?.student_id!,
    },
    { skip: !currentRecordStudent?.student_id }
  );

  useEffect(() => {
    if (
      periods &&
      (periods?.length || 0) > 0 &&
      (periods?.[0].periods?.length || 0) > 0 &&
      !periodId
    ) {
      setPeriodId([...periods].reverse()?.[0]?.periods?.[0]?.id!);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periods, periodId]);

  const meshByGroup = useMemo(() => {
    const meshs = data?.matter_mesh?.reduce<{
      [key: number]: MatterMesh[];
    }>((acc, matterMesh) => {
      if (!acc[matterMesh.group]) {
        acc[matterMesh.group] = [];
      }
      acc[matterMesh.group].push(matterMesh);
      return acc;
    }, {});

    return meshs || {};
  }, [data]);

  const { data: mattersStatus, isLoading: isLoadingMattersStatus } =
    useGetSubjectsCurriculumStatusQuery(
      {
        studentRecordId: currentRecordStudent?.id!,
        period_id: periodId!,
      },
      { refetchOnMountOrArgChange: 30, skip: !currentRecordStudent?.id || !periodId }
    );

  const learningComponents =
    data?.matter_mesh.reduce<{ [key: string]: number }>((acc, current) => {
      current.detail_matter_mesh.forEach((dmm) => {
        if (acc[dmm.component.com_acronym]) {
          acc[dmm.component.com_acronym] += Number(dmm.dem_workload);
        } else {
          acc[dmm.component.com_acronym] = Number(dmm.dem_workload);
        }
      });
      return acc;
    }, {}) || {};

  const learningComponentsByGroup =
    meshByGroup[activeGroup]?.reduce<{ [key: string]: number }>((acc, current) => {
      current.detail_matter_mesh.forEach((dmm) => {
        if (acc[dmm.component.com_acronym]) {
          acc[dmm.component.com_acronym] += Number(dmm.dem_workload);
        } else {
          acc[dmm.component.com_acronym] = Number(dmm.dem_workload);
        }
      });
      return acc;
    }, {}) || {};

  console.log('matterStatus', mattersStatus);

  return (
    <>
      <LoadingChildren
        isLoading={isLoading || isLoadingPeriods || isLoadingMattersStatus}
        loadingSkeleton={<LoadingScreen />}
        isEmpty={isError}
        emptyFallback={
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <ErrorAlert message="Error al cargar la malla curricular" type="fixed" />
            </Grid>
          </Grid>
        }
      >
        {data && (
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h3">
                {data.education_level?.edu_name} - {data.mes_name}
              </Typography>
            </Grid>

            <Grid item xs={12} mt={2}>
              <Box
                sx={{
                  display: 'flex',
                  gap: 1,
                  alignItems: 'center',
                  justifyContent: 'center',
                  border: `1px solid gray`,
                  width: 'fit-content',
                  margin: '0 0 0 auto',
                  p: 1,
                  borderRadius: 2,
                }}
              >
                <Typography variant="h5">Total Horas Malla</Typography>
                {Object.keys(learningComponents).map((lcKey) => (
                  <PeriodBadge key={lcKey} label={lcKey} number={learningComponents[lcKey]} />
                ))}
                <PeriodBadge
                  label="Total"
                  number={Object.values(learningComponents).reduce(
                    (acc, current) => acc + current,
                    0
                  )}
                />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Tabs
                value={activeGroup}
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                onChange={(_, newValue) => setActiveGroup(newValue as number)}
                sx={{
                  paddingTop: 5,
                  overflow: 'visible',

                  '& .MuiTabs-scroller': {
                    overflow: 'visible',
                    overflowX: 'scroll',
                  },
                  '& .MuiTab-wrapper': {
                    height: '100%',
                    alignSelf: 'flex-end',
                  },
                }}
              >
                {Object.keys(meshByGroup).map((group) => (
                  <Tab
                    sx={{
                      display: 'flex',
                      overflow: 'visible',
                      padding: 0,
                      paddingTop: 1.4,
                      marginRight: 2,
                    }}
                    key={group}
                    label={
                      <CurriculumTab
                        group={+group}
                        isActive={+group === activeGroup}
                        groupLength={meshByGroup[+group].length}
                      />
                    }
                    value={+group}
                  />
                ))}
              </Tabs>
            </Grid>
            <Grid item xs={12} mt={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 1,
                      alignItems: 'center',
                      justifyContent: 'center',
                      border: `1px solid gray`,
                      width: 'fit-content',
                      margin: '0 0 0 auto',
                      p: 1,
                      borderRadius: 2,
                    }}
                  >
                    <Typography variant="h5">Total Horas Semestre</Typography>
                    {Object.keys(learningComponentsByGroup).map((lcKey) => (
                      <PeriodBadge
                        key={lcKey}
                        label={lcKey}
                        number={learningComponentsByGroup[lcKey]}
                      />
                    ))}
                    <PeriodBadge
                      label="Total"
                      number={Object.values(learningComponentsByGroup).reduce(
                        (acc, current) => acc + current,
                        0
                      )}
                    />
                  </Box>
                </Grid>
                {meshByGroup[activeGroup]?.map((matterMesh) => (
                  <Grid item xs={12} md={4}>
                    <CurriculumItem
                      key={matterMesh.id}
                      data={matterMesh}
                      status={
                        mattersStatus?.find(
                          (matterStatus) => +matterStatus.subject_curriculum_id === +matterMesh.id
                        )!
                      }
                      statusPrerequisite={mattersStatus?.filter((matterStatus) =>
                        matterMesh.matter_mesh_prerequisites?.find(
                          (prerequisite) => +prerequisite.id === +matterStatus.subject_curriculum_id
                        )
                      )}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        )}
      </LoadingChildren>
    </>
  );
};

export default StudentCurriculum;
