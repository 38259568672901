import { Grid, IconButton, Typography } from '@material-ui/core'
import { IconArrowLeft } from '@tabler/icons';
import MainCard from '../../../shared/components/cards/MainCard'
import AddStatusForm from '../components/AddStatusForm';
import { useHistory } from 'react-router-dom'

const RequestsStatusAddScreen = () => {

    const navigate = useHistory();

    return (
        <Grid component={MainCard} sx={{ padding: 2 }}>
            <Grid container sx={{ mb: 3 }} alignItems='center' spacing={1}>
                <Grid item>
                    <IconButton onClick={()=>navigate.goBack()}>
                        <IconArrowLeft />
                    </IconButton>
                </Grid>
                <Grid item>
                    <Typography variant='h2'>Agregar Estado</Typography>
                </Grid>
            </Grid>
            <AddStatusForm />
        </Grid>
    )
}

export default RequestsStatusAddScreen;