import { useEffect, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Autocomplete, Grid, TextField } from '@material-ui/core';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { useGetProjectsStudentQuery } from '../slices/projectsApiSlice';
import StatusChip from '../../../shared/components/badges/StatusChip';
import SearchPaginatedTable from '../../../shared/components/tables/SearchPaginatedTable';
import { setProjectsList, updateProjectsTableStates } from '../slices/projectsSlice';
// import EditButton from '../../../shared/components/tables/buttons/EditButton';
import { useTableSearch } from '../../../../hooks/useTableSearch';
import typeProjectAvailables from '../../../maintenance/lines-of-investigation/constants/lists/typeProject';
import InputLabel from '../../../shared/components/forms/InputLabel';
import { statusAvailables } from '../../../shared/constants/resourceStatus';
import CustomIconBadgeButton from '../../../shared/components/tables/buttons/CustomIconBadgeButton';
import { IconCheckupList } from '@tabler/icons';

const ProjectTable = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  // const history = useHistory();

  const { path } = useRouteMatch();

  const [typeProject, setTypeProject] = useState(typeProjectAvailables[0].keyword);

  const studentId = useAppSelector((state) => state.access.currentStudent?.id);

  const { page, perPage, search, filterArrow } = useAppSelector(
    (state) => state.projectsStudent.projectsTable
  );

  const { setPage, setPerPage, setSearch, setFilterArrow } = useTableSearch({
    page,
    perPage,
    search,
    updateFunction: updateProjectsTableStates,
  });

  const { isLoading, error, data, isFetching } = useGetProjectsStudentQuery({
    page,
    perPage,
    search,
    ...(filterArrow && {
      type_sort: filterArrow?.direction,
      sort: filterArrow?.name,
    }),
    studentId: studentId!,
    typeProject,
  });

  //cargar en un estado la vista actual de la tabla
  useEffect(() => {
    if (data) {
      dispatch(setProjectsList(data.data));
    }
  }, [data, dispatch]);

  const datafetch = data?.data!.map((dt, i, arr) => ({
    id: i + ((page - 1) * perPage + 1),
    name: dt.name,
    description: dt.activity_description,
    startDate: dt.start_date,
    endDate: dt.end_date_real,
    statusId: dt.status,
    options: dt,
  }));

  // const goToAdd = () => {
  //   history.push(`${path}/agregar`);
  // };

  const FilterComponent = () => {
    return (
      <>
        <InputLabel>{t('Tipo de proyecto')}</InputLabel>
        <Autocomplete
          options={typeProjectAvailables || []}
          getOptionLabel={(option) => option.label}
          value={typeProjectAvailables.find(({ keyword }) => keyword === typeProject)}
          renderInput={(params) => (
            <TextField {...params} placeholder={t('Ingrese tipo de proyecto')} />
          )}
          onChange={(_, value) => setTypeProject(value?.keyword || '')}
          fullWidth
          clearIcon={null}
        />
      </>
    );
  };

  return (
    <>
      <SearchPaginatedTable
        data={datafetch!}
        headers={{
          id: 'N°',
          name: t('academic.areas.name'),
          description: t('academic.areas.description'),
          startDate: t('Fecha de inicio'),
          endDate: t('Fecha fin'),
          statusId: t('academic.areas.status'),
          options: t('academic.areas.options'),
        }}
        keyExtractor={(item) => item.options.id.toString()}
        searchPlacehoder={t('Buscar proyectos')}
        isLoading={isLoading}
        isFetching={isFetching}
        error={error}
        perPage={perPage!}
        setPerPage={setPerPage}
        total={data?.total || 0}
        page={page!}
        setPage={setPage}
        numHeader={4}
        search={search!}
        setSearch={setSearch}
        customRenderers={{
          statusId: (row) => (
            <StatusChip
              statusId={statusAvailables.find(({ acronym }) => acronym === row.statusId)?.id || 0}
            />
          ),

          options: (row) => (
            <>
              <Link to={`${path}/${row.options.id}/tareas`}>
                <CustomIconBadgeButton
                  title="Ver tareas"
                  Icon={IconCheckupList}
                  countBadge={Number(row.options.total_tasks)}
                />
              </Link>
            </>
          ),
        }}
        customDataCellsProperties={{
          id: { align: 'center' },
          name: {
            align: 'center',
          },
          description: {
            align: 'center',
          },
          statusId: {
            align: 'center',
          },

          options: {
            align: 'center',
          },
        }}
        customHeadersCellsProperties={{
          options: {
            align: 'center',
          },
        }}
        listFilterArrows={{
          name: 'name',
          description: 'activity_description',
          startDate: 'start_date',
          endDate: 'end_date_real',
          statusId: 'status',
        }}
        filterArrow={filterArrow}
        setFilterArrow={setFilterArrow}
        filtersPos={'top'}
        additionalFilters={
          <Grid item xs={12} md={4} mb={2}>
            <FilterComponent />
          </Grid>
        }
      />
    </>
  );
};

export default ProjectTable;
