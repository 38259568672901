import { Grid, Typography } from '@material-ui/core';
import MainCard from '../../../../shared/components/cards/MainCard';
import StudentsGrado from '../../components/presenciales/StudentsGrado';
//import { useAppSelector } from '../../../../hooks/useRedux';

const StudentsGradoScreen = () => {
  //const { authorToDelete } = useAppSelector((state) => state.authors);
  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">Estudiantes de Grado</Typography>
      </Grid>
      <StudentsGrado />
    </Grid>
  );
};

export default StudentsGradoScreen;
