/* eslint-disable react/no-unstable-nested-components */
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Skeleton,
  TextField,
  TextFieldProps,
  Typography,
} from '@material-ui/core';
import { ExpandMoreRounded, Search } from '@material-ui/icons';

import { IconX } from '@tabler/icons';
import { useEffect, useState } from 'react';
import { DebounceInput } from 'react-debounce-input';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../../../../hooks/useRedux';
import { useTableSearch } from '../../../../../../../hooks/useTableSearch';
import AddButton from '../../../../../../shared/components/buttons/AddIconButton';
import CustomPagination from '../../../../../../shared/components/tables/CustomPagination';
import { useGetEvaluationsQuery } from '../../slices/evaluationsApiSlice';
import { setEvaluationsList, setEvaluationsTable } from '../../slices/evaluationsSlice';
import EvaluationsListItem from './EvaluationsListItem';

const EvaluationsList = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useHistory();

  const {
    evaluationsList,
    evaluationsTable: { page, perPage, search, filterArrow },
    evaTypeIdSelected,
  } = useAppSelector((state) => state.evaluations);

  const { setPage, setPerPage, setSearch } = useTableSearch({
    page,
    perPage,
    search,
    updateFunction: setEvaluationsTable,
  });

  const { isLoading, error, data, isFetching } = useGetEvaluationsQuery(
    {
      page,
      perPage,
      search,
      ...(filterArrow && { sort: filterArrow.name, typeSort: filterArrow.direction }),
      ...(evaTypeIdSelected && { evaTypeId: evaTypeIdSelected }),
    },
    { refetchOnMountOrArgChange: 30 }
  );

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (newPerPage: number) => {
    setAnchorEl(null);
    setPerPage(newPerPage);
  };

  useEffect(() => {
    if (data) {
      dispatch(setEvaluationsList(data.data));
    }
  }, [data, dispatch]);

  const goToAdd = () => {
    navigate.push('/evaluaciones/agregar');
  };

  return (
    <Grid container direction="column" spacing={2}>
      {/* Actions header */}
      <Grid item xs={12} container justifyContent="space-between">
        <Grid item xs={12} sm={4} sx={{ mb: 2 }}>
          <DebounceInput
            autoFocus={Boolean(search)}
            minLength={2}
            debounceTimeout={300}
            onChange={({ target }: { target: { value: string } }) => {
              setPage(1);
              setSearch(target.value);
            }}
            value={search}
            /* eslint-disable */
            element={(props: JSX.IntrinsicAttributes & TextFieldProps) => (
              <TextField
                style={{
                  width: '100%',
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search fontSize="medium" />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        size="small"
                        onClick={() => {
                          setSearch('');
                        }}
                        {...(!search && { sx: { cursor: 'initial', opacity: 0 } })}
                      >
                        <IconX size={20} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                placeholder={t('maintenance.evaluations.search')}
                variant="outlined"
                size="small"
                {...props}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} sx={{ textAlign: 'right' }}>
          <AddButton  onClick={goToAdd} />
        </Grid>
      </Grid>

      <Grid item xs={12} container spacing={4}>
        {isFetching ? (
          <>
            {Array.from(new Array(perPage)).map((n, index) => (
              <Grid key={`${index.toString()}`} item xs={12} sm={6} md={4} lg={3}>
                <Skeleton
                  variant="rectangular"
                  sx={{ borderRadius: 2 }}
                  height={250}
                  width="100%"
                />
              </Grid>
            ))}
          </>
        ) : (
          evaluationsList?.map((evaluation) => (
            <Grid key={evaluation.uuid} item xs={12} sm={6} md={4} lg={3}>
              <EvaluationsListItem evaluation={evaluation} />
            </Grid>
          ))
        )}

        {evaluationsList?.length === 0 && !isFetching && (
          <Grid item xs={12} container justifyContent="center">
            <Typography variant="subtitle1">{t('shared.table.empty')}</Typography>
          </Grid>
        )}
      </Grid>

      {!isLoading && !error && (
        <Grid
          item
          xs={12}
          container
          justifyContent="space-between"
          alignItems="flex-end"
          sx={{ mt: 4 }}
        >
          <Grid item>
            <CustomPagination
              shape="rounded"
              color="primary"
              count={Math.ceil((data?.total || 1) / perPage)}
              disabled={!data?.total}
              page={page}
              onChange={(_, newPage) => {
                setPage(newPage);
              }}
            />
          </Grid>
          <Grid>
            <Box
              component={Button}
              size="large"
              sx={{
                color: 'grey.900',
                backgroundColor: 'primary.light',
                ':hover': { bgcolor: 'primary.light' },
                px: 2,
                borderRadius: 4,
              }}
              endIcon={<ExpandMoreRounded />}
              onClick={handleClick}
            >
              {perPage} {t('shared.search_paginated_table.per_page')}
            </Box>

            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={open}
              onClose={() => handleClose(perPage)}
              variant="selectedMenu"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <MenuItem onClick={() => handleClose(5)}>
                5 {t('shared.search_paginated_table.per_page')}
              </MenuItem>
              <MenuItem onClick={() => handleClose(10)}>
                10 {t('shared.search_paginated_table.per_page')}
              </MenuItem>
              <MenuItem onClick={() => handleClose(15)}>
                15 {t('shared.search_paginated_table.per_page')}
              </MenuItem>
            </Menu>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default EvaluationsList;
