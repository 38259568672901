import { useState, useEffect } from 'react';

import { Grid, IconButton, Tooltip, TextField, Autocomplete, Button } from '@material-ui/core';
import { IconEye } from '@tabler/icons';
import { useRouteMatch } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { DateRange } from '@material-ui/lab/DateRangePicker';
import { format } from 'date-fns';

import SearchPaginatedTable from '../../../shared/components/tables/SearchPaginatedTable';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import Chip from '../../../shared/components/extended/Chip';
import { useTableSearch } from '../../../../hooks/useTableSearch';

import TextFieldFetching from '../../../shared/components/TextFieldFetching';
import { setHRTable } from '../slices/rrhhApplicationsSlice';
import { useGetEducationLevelsParentsAndChildrenQuery } from '../../../maintenance/education-levels/slices/educationLevelsApiSlice';
import { AcroType } from '../../../maintenance/general-applications/constants/types';
import { useGetApplicationStatusByAcronymQuery } from '../../../maintenance/requests-status/slices/requestsStatusApiSlice';
import { useGetGeneralApplicationsQuery } from '../../../maintenance/general-applications/slices/GeneralApplicationsApiSlice';
import { setGeneralApplicationList } from '../../../maintenance/general-applications/slices/GeneralApplicationsSlice';
import { rrhhAppTypes } from '../../../maintenance/general-applications/constants/mockOptions';
import { EducationLevel } from '../../../maintenance/education-levels/interfaces/educationLevels.interfaces';
import LocalizedDateRangePicker from '../../../shared/components/controlled/LocalizedDateRangePicker';

const HHRRApplicationsTable = function () {
  const dispatch = useAppDispatch();

  const { path } = useRouteMatch();

  const { currentProfile, currentRol } = useAppSelector((state) => state.access);
  const { userInfo } = useAppSelector((state) => state.auth);

  const { page, perPage, search, filterArrow } = useAppSelector(
    (state) => state.hrApplications.hrTable
  );
  const { setPage, setPerPage, setSearch, resetTable, setFilterArrow } = useTableSearch({
    page,
    perPage,
    search,
    filterArrow,
    updateFunction: setHRTable,
  });

  const filterByEducationLevel = Boolean(Number(currentRol?.showFilteredRequests));
  const educationLevelCollaborator = userInfo?.collaboratorInfo?.education_level_principal_id;

  const {
    data: educationLevels,
    isLoading: isLoadingELA,
    isFetching: isFetchingELA,
    error: errorELA,
  } = useGetEducationLevelsParentsAndChildrenQuery({ filter: true });

  const [value, setValue] = useState<DateRange<Date>>([null, null]);
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();
  const [appType, setAppType] = useState<AcroType['types'] | undefined>(
    rrhhAppTypes?.[0]?.acro || undefined
  );
  const [appState, setAppState] = useState<string | undefined>(undefined);
  const [faculty, setFaculty] = useState<EducationLevel | undefined>(undefined);
  const [filter, setFilter] = useState<boolean>(true);

  useEffect(() => {
    if (filterByEducationLevel && educationLevelCollaborator) {
      setFaculty(educationLevels?.find((el) => el.id === Number(educationLevelCollaborator)));
    }
  }, [educationLevelCollaborator, educationLevels, filterByEducationLevel]);

  const {
    data: appStatuses,
    isLoading: isLoadingAppStatuses,
    isFetching: isFetchingStatuses,
  } = useGetApplicationStatusByAcronymQuery(
    {
      user_profile_id: currentProfile?.profileId!,
      acronym: appType || '',
    },
    {
      skip: !appType || !currentProfile?.profileId,
    }
  );

  const { data, isLoading, isFetching, error, refetch } = useGetGeneralApplicationsQuery(
    {
      profileId: currentProfile?.profileId!,
      page,
      perPage,
      search,
      acronym: appType,
      ...(filter && startDate && endDate && { startDate: startDate }),
      ...(filter && endDate && startDate && { endDate: endDate }),
      ...(filter && { status: appState }),
      ...(filter && { fa: appState }),
      ...(filter && faculty && { facultyId: faculty.id }),
      ...(filterArrow && { sort: filterArrow.name, type_sort: filterArrow.direction }),
      ...(filterByEducationLevel &&
        Boolean(educationLevelCollaborator) && { facultyId: Number(educationLevelCollaborator) }),
    },
    { refetchOnMountOrArgChange: 120 }
  );

  useEffect(() => {
    if (data) dispatch(setGeneralApplicationList(data.data));
  }, [data, dispatch]);

  const datafetch = data?.data!.map((value, index) => ({
    id: index + ((page - 1) * perPage + 1),
    appCode: value.app_code,
    // studCode: value?.stud_code || '',
    persIdentification: value?.pers_identification || '',
    typeApplication: value.type_application,
    collaborator: value.user,
    // detail: {
    //   per_max_matter_enrollment: value?.per_max_matter_enrollment,
    //   per_min_matter_enrollment: value?.per_min_matter_enrollment,
    //   per_num_fees: value?.per_num_fees,
    //   per_pay_enrollment: value?.per_pay_enrollment,
    // },
    startDate: value.app_register_date,
    status_id: value.current_status,
    options: value,
  }));

  // const handleDelete = function (periodId: number) {
  //   dispatch(openDeleteModalWith(periodId));
  // };

  //filtros
  const handleFilter = () => {
    value[0] && !isNaN(value[0].getTime()) && setStartDate(format(value[0], 'yyyy-MM-dd'));
    value[1] && !isNaN(value[1].getTime()) && setEndDate(format(value[1], 'yyyy-MM-dd'));
    // if (filterStatus) setStatus(filterStatus);

    if (filter) refetch();
    else setFilter(true);
  };
  const handleClearFilter = () => {
    setValue([null, null]);
    setStartDate('');
    setEndDate('');

    // setStatus('');

    setAppType(rrhhAppTypes?.[0]?.acro || undefined);
    setAppState(undefined);

    setFaculty(undefined);

    setFilter(false);
  };
  const onChangeDates = (newDatesValues: DateRange<Date>) => {
    setValue(newDatesValues);
    resetTable();
  };

  // const goToAdd = () => {
  //   history.push('/administrar-periodos/agregar');
  // };

  return (
    <SearchPaginatedTable
      actBtnFullWith
      data={datafetch!}
      headers={{
        id: 'N°',
        appCode: 'Cód. Sol.',
        // studCode: 'Cód. Est.',
        persIdentification: 'Identificación',
        typeApplication: 'Tipo',
        collaborator: 'Colaborador',
        // detail: 'Detalle',
        startDate: 'Fecha de Registro',
        status_id: 'Estado',
        options: 'Opciones',
      }}
      listFilterArrows={{
        collaborator: 'p.pers_full_name',
        startDate: 'app_register_date',
        // status_id: 'current_status',
      }}
      filterArrow={filterArrow}
      setFilterArrow={setFilterArrow}
      keyExtractor={(item) => item.options.app_code + item.options.id.toString()}
      searchPlacehoder="Buscar solicitud"
      isLoading={isLoading}
      isFetching={isFetching}
      error={error}
      perPage={perPage}
      setPerPage={setPerPage}
      total={data?.total || 0}
      page={page}
      setPage={setPage}
      numHeader={9}
      search={search}
      setSearch={setSearch}
      customRenderers={{
        status_id: (row) => (
          <Chip
            label={row.options.current_status}
            size="small"
            chipcolor={
              row.options.current_status === 'Enviado'
                ? 'info'
                : row.options.current_status === 'Rechazado'
                ? 'error'
                : 'success'
            }
          />
        ),

        options: (row) => (
          <>
            {/* {hasAccess('read') && ( */}
            <Link to={`${path}/${row.options.id}/revision`}>
              <Tooltip title="Revisar solicitud">
                <IconButton aria-label="Revisar solicitud" color="default">
                  <IconEye size={20} />
                </IconButton>
              </Tooltip>
            </Link>
            {/* )} */}
          </>
        ),
      }}
      customHeadersCellsProperties={{
        appCode: {
          align: 'center',
        },
        // studCode: {
        //   align: 'center',
        // },
        persIdentification: {
          align: 'center',
        },
        typeApplication: {
          align: 'center',
        },
        collaborator: {
          align: 'center',
        },
        // detail: 'Detalle',
        startDate: {
          align: 'center',
        },
        status_id: {
          align: 'center',
        },
        options: {
          align: 'center',
        },
      }}
      customDataCellsProperties={{
        id: { align: 'center' },
        appCode: {
          align: 'center',
        },
        typeApplication: {
          align: 'center',
        },
        collaborator: {
          align: 'center',
        },
        // detail: 'Detalle',
        startDate: {
          align: 'center',
        },
        status_id: {
          align: 'center',
        },
        options: {
          align: 'center',
        },
      }}
      ActionButtons={
        <>
          <Grid container spacing={1} justifyContent="flex-end">
            <Grid item xs={12} md={4}>
              <LocalizedDateRangePicker value={value} onChange={onChangeDates} />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Autocomplete
                options={rrhhAppTypes}
                loadingText="Cargando solicitudes..."
                getOptionLabel={(option) => option.value}
                renderInput={(params) => <TextField {...params} label="Tipo de solicitud" />}
                value={rrhhAppTypes.find((dt) => dt.acro === appType) || undefined}
                onChange={(_, value) => {
                  setAppType(value?.acro || undefined);
                  setAppState(undefined);
                  resetTable();
                }}
                fullWidth
                clearIcon={null}
                disableClearable
              />
            </Grid>

            <Grid item xs={12} sm={6} md={2}>
              {(isLoadingAppStatuses || isFetchingStatuses) && <TextFieldFetching />}
              {!isLoadingAppStatuses && !isFetchingStatuses && (
                <Autocomplete
                  options={appStatuses || []}
                  loadingText="Cargando estados..."
                  loading={isLoadingAppStatuses || isFetchingStatuses}
                  getOptionLabel={(option) => option.status_name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={appType ? 'Estado' : 'Seleccione un tipo de solicitud'}
                      // placeholder={
                      //   appType ? 'Seleccione un estado' : 'Seleccione un tipo de solicitud'
                      // }
                    />
                  )}
                  value={appStatuses?.find((dt) => dt.status_name === appState) || null}
                  onChange={(_, value) => {
                    const newValue = value?.status_name;
                    setAppState(newValue || undefined);
                    resetTable();
                  }}
                  disabled={isLoadingAppStatuses || isFetchingStatuses || !appType}
                  fullWidth
                />
              )}
              {/* )} */}
            </Grid>

            {/* filtro facultades */}
            <Grid item xs={12} sm={6} md={3}>
              {(isFetchingELA || isLoadingELA) && <TextFieldFetching />}
              {!isFetchingELA && !isLoadingELA && (
                <Autocomplete
                  options={educationLevels || []}
                  loading={isFetchingELA || isLoadingELA}
                  onError={() => errorELA}
                  loadingText="Cargando unidades académicas..."
                  getOptionLabel={(option) => option.edu_name}
                  renderInput={(params) => <TextField {...params} label="Unidades académicas" />}
                  value={faculty || null}
                  disabled={filterByEducationLevel && Boolean(educationLevelCollaborator)}
                  onChange={(_, value) => {
                    if (value) setFaculty(value);
                  }}
                  fullWidth
                />
              )}
            </Grid>

            <Grid container justifyContent="flex-end" spacing={1} mt={1}>
              <Grid item xs={'auto'}>
                <Button variant={'outlined'} onClick={handleClearFilter}>
                  Limpiar
                </Button>
              </Grid>
              <Grid item xs={'auto'}>
                <Button variant={'outlined'} onClick={handleFilter}>
                  Buscar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </>
      }
    />
  );
};

export default HHRRApplicationsTable;
