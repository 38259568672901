import moment from 'moment';
import { useAppSelector } from '../../../../hooks/useRedux';
import { MyEvents } from '../interfaces/events.interfaces';
import useGetDayTypes from './useGetDayTypes';

const useGeneratePIPsWithHolidays = (myEvents: MyEvents[]) => {
  const { holidaysResponse } = useAppSelector((state) => state.academicPlanningPosgraduate);

  const { holiday, initialPlanningPoint } = useGetDayTypes();

  const justHolidaysEvent = myEvents.filter((events) => events.typeId === holiday?.pdt_keywork);

  const justPIPEvents = myEvents.filter(
    (events) => events.typeId === initialPlanningPoint?.pdt_keywork
  );

  const PIPswithHolidays = justPIPEvents.filter((PIPevent) =>
    justHolidaysEvent.some(
      (holidayEvent) =>
        holidayEvent.start >= PIPevent.start &&
        holidayEvent.start <=
          moment(PIPevent.start)
            .add(Number(holidaysResponse?.trabajo_autonomo?.pdt_number_of_days), 'days')
            .toDate()
    )
  );
  return PIPswithHolidays;
};

export default useGeneratePIPsWithHolidays;
