import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import MainCard from '../../../../../shared/components/cards/MainCard';
import LaunchingResultsTable from '../components/LaunchingResultsTable';

const LaunchingResultsScreen = () => {
  const { t } = useTranslation();

  return (
    <Grid
      component={MainCard}
      sx={{
        padding: 2,
        minHeight: '500px',
      }}
    >
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">{t('Resultados de la evaluación individual')}</Typography>
      </Grid>
      <LaunchingResultsTable />
    </Grid>
  );
};

export default LaunchingResultsScreen;
