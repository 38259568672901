import { useAppSelector } from '../../../../hooks/useRedux';
import ConfirmAlert from '../../../shared/components/dialogs/ConfirmAlert';
import { useRecommendBookMutation } from '../slices/userBorrowedBooksApiSlice';

interface Props {
  bookId: number;
  handleClose: () => void;
}

const RecommendBookDialog = ({ bookId, handleClose }: Props) => {
  const { currentProfile } = useAppSelector((state) => state.access);

  const [recommend, { isLoading, isSuccess, isError, error }] = useRecommendBookMutation();

  const onConfirm = async () => {
    await recommend({ bookId, profileId: currentProfile?.profileId! });
  };

  return (
    <ConfirmAlert
      onClose={handleClose}
      handleDelete={onConfirm}
      title="Recomendar libro"
      error={error as string}
      isError={isError}
      isLoading={isLoading}
      isSuccess={isSuccess}
      open
      confirmBtnMessage="Sí, recomendar"
      subtitle="¿Estás seguro de recomendar este libro?"
    />
  );
};

export default RecommendBookDialog;
