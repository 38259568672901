// material-ui
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';

import { renderErrorOrEmptyRow, renderSkeletonRows } from '../../../../shared/helpers/render';

// helpers
import { isPrimitive, objectKeys } from '../../../../shared/helpers';

// types

import {
  CustomCellProperties,
  CustomRenderers,
  TableHeaders,
} from '../../../../shared/interfaces/material-ui.interfaces';

import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { SerializedError } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';

interface Props<T extends {}> {
  headers: TableHeaders<T>;
  data: T[];
  keyExtractor: (item: T) => string;
  customRenderers?: CustomRenderers<T>;
  customDataCellsProperties?: CustomCellProperties<T>;
  customHeadersCellsProperties?: CustomCellProperties<T>;
  isLoading: boolean;
  isFetching: boolean;
  error: FetchBaseQueryError | SerializedError | undefined;
  total: number;
  perPage: number;
  page: number;
  numHeader: number;
  totalRowKeys?: string[];
}

const CareerTable = <T extends {}>({
  data,
  headers,
  customRenderers,
  keyExtractor,
  customDataCellsProperties,
  customHeadersCellsProperties,
  numHeader,
  isLoading,
  isFetching,
  error,
  perPage,
  totalRowKeys,
}: Props<T>) => {
  const { t } = useTranslation();

  const renderRows = () => {
    return (
      <>
        {data?.map(renderRow)}
        {totalRowKeys && totalRowKeys.length > 0 && renderTotalRow(data[0])}
      </>
    );
  };

  const renderTotalRow = (row: T) => {
    return (
      <TableRow key="total">
        {objectKeys(row).map((cell) => {
          if (cell === 'id') {
            return null;
          }

          if (!totalRowKeys?.includes(cell as string)) {
            return (
              <TableCell align="center" key={`${keyExtractor(row)}-${cell as string}`}></TableCell>
            );
          }

          if (isNaN(Number(row[cell]))) {
            return (
              <TableCell align="center" key={`${keyExtractor(row)}-${cell as string}`}></TableCell>
            );
          }

          return (
            <TableCell align="center" key={`${keyExtractor(row)}-${cell as string}`}>
              <b>
                Total:{' '}
                {data?.reduce((acc, curr) => {
                  return acc + (!isNaN(Number(curr[cell])) ? Number(curr[cell]) : 0);
                }, 0)}
              </b>
            </TableCell>
          );
        })}
      </TableRow>
    );
  };

  const renderRow = (row: T) => {
    return (
      <>
        <TableRow className="table-row" key={keyExtractor(row)}>
          {objectKeys(row).map((cell) => {
            const customRenderer = customRenderers?.[cell];

            if (customRenderer) {
              return (
                <TableCell
                  align="center"
                  key={`${keyExtractor(row)}-${cell.toString()}`}
                  {...customDataCellsProperties?.[cell]}
                >
                  {customRenderer(row)}
                </TableCell>
              );
            }

            return (
              <TableCell
                align="center"
                key={`${keyExtractor(row)}-${cell.toString()}`}
                {...customDataCellsProperties?.[cell]}
              >
                {isPrimitive(row[cell]) ? row[cell] : ''}
              </TableCell>
            );
          })}
        </TableRow>
      </>
    );
  };

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            {/* Render headers title */}
            <TableRow>
              <TableCell key="Header2" align="center">
                {t('students.academic_counseling.area')}
              </TableCell>
              <TableCell key="Header5"></TableCell>
              <TableCell key="Header6" align="center">
                {t('students.academic_counseling.subjects')}
              </TableCell>
              <TableCell key="Header7"></TableCell>
              <TableCell key="Header8" align="center">
                {t('students.academic_counseling.details')}
              </TableCell>
            </TableRow>
            <TableRow>
              {Object.keys(headers).map((key) => (
                <TableCell
                  variant="footer"
                  align="center"
                  key={key}
                  {...customHeadersCellsProperties?.[key as keyof T]}
                >
                  {headers[key as keyof T]}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isFetching ? renderSkeletonRows(perPage, numHeader) : renderRows()}

            {error && renderErrorOrEmptyRow(numHeader, error as string)}
            {(!data || data.length === 0) && renderErrorOrEmptyRow(numHeader)}

            {/* Render totals */}
            {/* <TableRow key="details">
              <TableCell variant="footer" key="header0result" align="center">
                TOTALES
              </TableCell>
              <TableCell key="header1result"></TableCell>
              <TableCell key="header2result" align="center">
                1
              </TableCell>
              <TableCell key="header3result" align="center">
                1
              </TableCell>
              <TableCell key="header4result" align="center">
                1
              </TableCell>
              <TableCell key="header5result" align="center">
                1
              </TableCell>
              <TableCell key="header6result" align="center">
                1
              </TableCell>
              <TableCell key="header7result" align="center">
                1
              </TableCell>
            </TableRow> */}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default CareerTable;
