import { Button, Divider, FormHelperText, Grid, Skeleton, Typography } from '@material-ui/core';
import Alert from '@material-ui/core/Alert';
import { IconEdit } from '@tabler/icons';
import { FC } from 'react';
import { useAppSelector } from '../../../../hooks/useRedux';
import InputLabel from '../../../shared/components/extended/Form/InputLabel';
import { BillingData as BillingInfo } from '../interfaces/paymentsHistory.interface';

interface Props {
  BillingData: { data: BillingInfo | undefined; isFetching: boolean };
  handleOpen: () => void;
}

const BillingData: FC<Props> = (props) => {
  const {
    handleOpen,
    BillingData: { data, isFetching },
  } = props;

  const {
    userInfo: { fullname },
  } = useAppSelector((state) => state.auth);

  return (
    <Grid container p={2} spacing={1}>
      <Grid container justifyContent={'space-between'} alignContent={'baseline'}>
        <Grid item>
          <Typography variant="h3">Datos de Facturación</Typography>
        </Grid>
        <Grid item sx={{ textAlign: 'right' }}>
          <Button variant="outlined" size="small" onClick={handleOpen} startIcon={<IconEdit />}>
            Editar
          </Button>
        </Grid>
      </Grid>

      <Grid item>
        <Alert
          severity="info"
          variant="outlined"
          sx={{ borderStyle: 'solid', borderColor: `#2196f3`, borderRadius: '12px' }}
        >
          <FormHelperText sx={{ textAlign: 'justify' }}>
            Revisa que los datos estén correctos para la impresión de la factura. En caso de
            encontrar una novedad, comuníquese con la unidad educativa.
          </FormHelperText>
        </Alert>
      </Grid>

      <Grid item xs={12} my={1}>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        <InputLabel>Nombre y Apellido / Razón Social</InputLabel>
        {isFetching ? (
          <Skeleton variant="rectangular">
            <Typography variant="h5" component="p">
              Mera Solis Roberth Sebastían
            </Typography>
          </Skeleton>
        ) : (
          <Typography variant="h5" component="p">
            {fullname}
          </Typography>
        )}
      </Grid>

      <Grid item xs={12}>
        <InputLabel>Cédula o RUC</InputLabel>
        {isFetching ? (
          <Skeleton variant="rectangular">
            <Typography variant="h5" component="p">
              0000000000
            </Typography>
          </Skeleton>
        ) : (
          <Typography variant="h5" component="p">
            {data?.identificacion}
          </Typography>
        )}
      </Grid>

      <Grid item xs={12}>
        <InputLabel>Teléfono</InputLabel>
        {isFetching ? (
          <Skeleton variant="rectangular">
            <Typography variant="h5" component="p">
              1232132
            </Typography>
          </Skeleton>
        ) : (
          <Typography variant="h5" component="p">
            {data?.telefono}
          </Typography>
        )}
      </Grid>

      <Grid item xs={12}>
        <InputLabel>E-mail</InputLabel>
        {isFetching ? (
          <Skeleton variant="rectangular">
            <Typography variant="h5" component="p">
              test@test.com
            </Typography>
          </Skeleton>
        ) : (
          <Typography variant="h5" component="p">
            {data?.email}
          </Typography>
        )}
      </Grid>

      <Grid item xs={12}>
        <InputLabel>Dirección</InputLabel>
        {isFetching ? (
          <Skeleton variant="rectangular">
            <Typography variant="h5" component="p">
              Las Orquídeas Mz. 1064 Villa 18
            </Typography>
          </Skeleton>
        ) : (
          <Typography variant="h5" component="p">
            {data?.direccion}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default BillingData;
