import {
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  Tooltip,
  Typography,
  useTheme,
} from '@material-ui/core';
import { IconArrowRight } from '@tabler/icons';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import DeleteButton from '../../../../shared/components/tables/buttons/DeleteButton';
import { MyEvents } from '../../interfaces/events.interfaces';
import useFormatDate from '../../../../shared/hooks/useFormatDate';

interface DayEventsDialogProps {
  handleClose: () => void;
  events: MyEvents[];
  date: Date;
  handleDeletePlanning: (id: string) => void;
}

const DayEventsDialog = ({
  handleClose,
  events,
  handleDeletePlanning,
  date,
}: DayEventsDialogProps) => {
  const { t } = useTranslation();

  const theme = useTheme();

  const history = useHistory();

  const { formatDate } = useFormatDate();

  const { path } = useRouteMatch();

  const handleGoPlanning = (id: string) => {
    history.push(`${path}/${id}/planeacion-carrera`);
  };

  return (
    <Dialog open onClose={handleClose}>
      <DialogTitle>
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography variant="h3">
            {t('posgraduate.days_events.title', { date: formatDate(date) })}
          </Typography>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <List>
          {events.map((event) => (
            <>
              <ListItem key={event.id}>
                <Grid container alignItems="center">
                  <Grid item xs={6}>
                    {event.namePeriod || event.title}
                  </Grid>
                  <Grid item container justifyContent="center" xs={2}>
                    {event?.status && event?.status !== '' && (
                      <Grid container justifyContent="flex-end" alignItems="center">
                        <Chip
                          label={event?.status}
                          style={{
                            backgroundColor:
                              event?.status === 'pendiente'
                                ? theme.palette.warning.main
                                : theme.palette.success.main,
                            borderColor:
                              event?.status === 'pendiente'
                                ? theme.palette.warning.main
                                : theme.palette.success.main,
                            color: event?.status === 'pendiente' ? '#000' : '#fff',
                          }}
                          variant="outlined"
                        />
                      </Grid>
                    )}
                  </Grid>
                  <Grid item container xs={4} justifyContent="flex-end">
                    <Tooltip title={t('posgraduate.days_events.go_to_planning')}>
                      <IconButton onClick={() => handleGoPlanning(event.id)}>
                        <IconArrowRight />
                      </IconButton>
                    </Tooltip>
                    <DeleteButton handleDelete={() => handleDeletePlanning(event.id)} />
                  </Grid>
                </Grid>
              </ListItem>
              <Divider
                variant="inset"
                component="li"
                sx={{
                  marginLeft: 0,
                }}
              />
            </>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  );
};

export default DayEventsDialog;
