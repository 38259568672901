import { useEffect, useMemo, useState } from 'react';
import { Autocomplete, Grid, InputLabel, TextField, Typography } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useGetFormByIdQuery, useLazyGetCoursesListAllQuery } from '../slices/formsApiSlice';
import { useTranslation } from 'react-i18next';
import { FileDownload } from '@material-ui/icons';
import { useAppSelector } from '../../../../../../hooks/useRedux';
import { useGetAllIntegralEvaluationsQuery } from '../../integral_evaluation/slices/integralEvaluationApiSlice';
import {
  useGetRepresentativeScaleAllQuery,
  useLazyGetRubricByIDQuery,
} from '../../options_template/slices/optionsTemplateApiSlice';
import { CourseTeacher } from '../interfaces/coursesListAll.interface';
import { RubricData } from '../../integral_evaluation/interfaces/rubrics.interface';
import {
  useGetAcademicOfferingQuery,
  useGetAvailablesOffersQuery,
  useGetPeriodAcademicOfferingQuery,
} from '../../../../../maintenance/academic-offerings/slices/academicOfferingsApiSlice';
import TextFieldFetching from '../../../../../shared/components/TextFieldFetching';
import { Period } from '../../../../../maintenance/academic-offerings/interfaces/periods.interface';
import useGetUrlDownload from '../../../../../../hooks/useGetUrlDownload';
import { useLazyGetAlLCollaboratorByEducationLevelIdByOfferIdQuery } from '../../../../../hr/collaborators/slices/collaboratorsApiSlice';

const FormPreview = () => {
  const { id } = useParams<{
    id: string;
  }>();

  const { data: formById } = useGetFormByIdQuery({
    id,
  });

  const [offerDescription, setOff_description] = useState('');

  const { t } = useTranslation();

  const [evaluated, setEvaluated] = useState<CourseTeacher[]>([]);

  const [results, setResults] = useState<number[]>([]);

  const [ponderation, setPonderation] = useState<number | null>(null);

  const [evaTypes, setEvaTypes] = useState<RubricData>();

  const { currentProfile } = useAppSelector((state) => state.access);

  //filters

  const [getCollaboratorsAll, { data: collaboratorAll, isLoading: isLoadingCollaboratorsAll }] =
    useLazyGetAlLCollaboratorByEducationLevelIdByOfferIdQuery();

  //niveles eduactivos
  const {
    data: offersAvailables = [],
    isLoading: isLoadingPID,
    isFetching: isFetchingPID,
  } = useGetAvailablesOffersQuery(
    {
      profileId: currentProfile?.profileId!,
      off_description: '',
    },
    { refetchOnMountOrArgChange: 30 }
  );

  //periodos
  const {
    data: periodsByOffer = [],
    isLoading: isLoadingCID,
    isFetching: isFetchingCID,
  } = useGetPeriodAcademicOfferingQuery(
    {
      profileId: currentProfile?.profileId!,
      academicOfferingId:
        offersAvailables!
          .find((offer) => offer.off_description === offerDescription)
          ?.id?.toString() || '',
      current: true,
    },
    { skip: !offerDescription, refetchOnMountOrArgChange: 30 }
  );

  const periods = useMemo(() => {
    return Array.isArray(periodsByOffer)
      ? (periodsByOffer as unknown as Period[]).filter(
          (dt) => new Date(dt.end_process).getTime() > new Date().getTime()
        )
      : [];
  }, [periodsByOffer]);

  const {
    data: offerById,
    isLoading: isLoadingOID,
    isFetching: isFetchingOID,
  } = useGetAcademicOfferingQuery(
    {
      profileId: currentProfile?.profileId!,
      academicOfferingId:
        offersAvailables!.find((offer) => offer.off_description === offerDescription)?.id! || 0,
    },
    { skip: !offerDescription, refetchOnMountOrArgChange: 30 }
  );

  //filters

  const { data: integralEvaluations, isLoading: isLoadingIE } = useGetAllIntegralEvaluationsQuery(
    {}
  );

  const [getRepresentativeScaleByID] = useLazyGetRubricByIDQuery();

  const { data: representativeScaleAll, isLoading: isLoadingRepresentativeScaleAll } =
    useGetRepresentativeScaleAllQuery({});

  const [getCoursesListAll, { data: coursesListAll, isLoading: isLoadingCoursesListAll }] =
    useLazyGetCoursesListAllQuery();

  const { urlDownload } = useGetUrlDownload();

  useEffect(() => {
    if (formById && formById.education_level.offer.id && formById.education_level.id) {
      const execute = async () => {
        await getCollaboratorsAll({
          user_profile_id: currentProfile?.profileId!,
          education_level_principal_id: Number(formById.education_level.id),
          offer_id: Number(formById.education_level.offer.id),
        });
      };
      execute();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formById]);

  useEffect(() => {
    console.log(results);
    if (evaTypes && evaTypes.eva_rubrica_detail.length > 0 && results.length > 0) {
      if (evaTypes?.eva_rubrica_detail.length === results.length) {
        const sum = results.reduce((a, b) => a + b, 0);
        setPonderation(sum);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results, evaTypes?.eva_rubrica_detail]);

  useEffect(() => {
    if (formById?.period_id && formById?.education_level_id) {
      getCoursesListAll({
        periodId: formById?.period_id,
        educationLevelId: formById?.education_level_id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formById?.period_id, formById?.education_level_id]);

  useEffect(() => {
    if (formById?.eva_rubrica_id) {
      const execute = async () => {
        try {
          const representative = await getRepresentativeScaleByID({
            id: formById?.eva_rubrica_id,
          }).unwrap();
          setEvaTypes(representative);
        } catch (err) {
          console.log(err);
        }
      };
      execute();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formById?.eva_rubrica_id]);

  useEffect(() => {
    if (Array.isArray(coursesListAll) && coursesListAll.length > 0) {
      const courseSelect = coursesListAll?.find(
        (dt) => Number(dt.id) === Number(formById?.course_id)
      );
      if (courseSelect) {
        setEvaluated(courseSelect?.course_teacher!);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coursesListAll]);

  useEffect(() => {
    if (formById?.eva_formulary_details) {
      const newResults: number[] = [];
      evaTypes?.eva_rubrica_detail.forEach((item, index) => {
        const result = formById?.eva_formulary_details.find(
          (dt) => Number(dt.eva_rubrica_detail_id) === Number(item.id)
        );
        if (result) {
          newResults[index] = (parseFloat(result.form_value) * Number(item.eva_peso)) / 100;
        }
      });
      if (newResults.length > 0) {
        setResults(newResults);
      }
    }
  }, [evaTypes, formById?.eva_formulary_details]);

  useEffect(() => {
    if (formById && formById.education_level && formById.education_level.offer) {
      setOff_description(formById.education_level.offer.off_description);
    }
  }, [formById]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {formById && (
          <form>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <InputLabel>{t('academic.courses.edu_level')}:</InputLabel>
                {!isFetchingPID && (
                  <Autocomplete
                    // key={autocompleteKey.offer}
                    disabled={true}
                    options={offersAvailables}
                    noOptionsText={t('academic.courses.edu_level.no_data')}
                    loading={isLoadingPID}
                    loadingText={t('academic.courses.edu_level.loading')}
                    getOptionLabel={(option) => option.off_name}
                    value={
                      offersAvailables.find((dt) => dt.off_description === offerDescription) || null
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={t('academic.courses.edu_level.placeholder')}
                      />
                    )}
                    onChange={(_, value) => {
                      // setFiltersSearch(false);
                      setOff_description(value?.off_description || '');
                    }}
                    fullWidth
                    clearIcon={null}
                  />
                )}
                {isFetchingPID && <TextFieldFetching />}
              </Grid>

              <Grid item xs={6}>
                <InputLabel>{t('academic.courses.period')}:</InputLabel>
                {!isFetchingCID && (
                  <Autocomplete
                    disabled={true}
                    options={periods}
                    noOptionsText={t('academic.courses.period.no_data')}
                    loading={isLoadingCID}
                    loadingText={t('academic.courses.period.loading')}
                    getOptionLabel={(option) => option.per_name}
                    value={
                      periods.find((dt) => Number(dt.id) === Number(formById?.period_id)) || null
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={t('academic.courses.period.placeholder')}
                      />
                    )}
                    fullWidth
                    clearIcon={null}
                  />
                )}
                {(isFetchingCID || isLoadingCID) && <TextFieldFetching />}
              </Grid>

              <Grid item xs={6}>
                <Grid item xs={8}>
                  <InputLabel>{t('academic.courses.academic_unit')}:</InputLabel>
                </Grid>

                {!isFetchingOID && (
                  <Autocomplete
                    disabled={true}
                    // key={autocompleteKey.educationLvl}
                    options={offerById ? offerById!.education_levels : []}
                    noOptionsText={t('academic.courses.academic_unit.no_data')}
                    loading={isLoadingOID}
                    loadingText={t('academic.courses.academic_unit.loading')}
                    getOptionLabel={(option) => option.edu_name}
                    value={
                      offerById?.education_levels.find(
                        (dt) => dt.id === Number(formById?.education_level_id)
                      ) || null
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={t('academic.courses.academic_unit.placeholder')}
                      />
                    )}
                    fullWidth
                    clearIcon={null}
                  />
                )}
                {isFetchingOID && <TextFieldFetching />}
              </Grid>
              <Grid item xs={6}>
                <InputLabel>{t('Cursos')}</InputLabel>
                {isLoadingCoursesListAll ? (
                  <TextField
                    fullWidth
                    placeholder={t('Seleccione un periodo y la unidad académica ')}
                    disabled
                  />
                ) : (
                  <Autocomplete
                    disabled={true}
                    options={Array.isArray(coursesListAll) ? coursesListAll : []}
                    value={
                      Array.isArray(coursesListAll)
                        ? coursesListAll?.find(
                            (dt) => Number(dt.id) === Number(formById?.course_id)
                          )
                        : null
                    }
                    renderInput={(params) => (
                      <TextField {...params} placeholder={t('Selecciona un curso')} />
                    )}
                    getOptionLabel={(option) => option?.mat_name}
                  />
                )}
              </Grid>
              <Grid item xs={6}>
                <InputLabel>{t('Escenarios de evaluación')}</InputLabel>
                {isLoadingIE ? (
                  <TextField
                    fullWidth
                    placeholder={t('Cargando escenarios de evaluación...')}
                    disabled
                  />
                ) : (
                  <Autocomplete
                    disabled={true}
                    options={integralEvaluations! || []}
                    value={
                      integralEvaluations?.find(
                        (dt) => dt.id === Number(formById?.eva_rubrica_id)
                      ) || null
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={t('Seleccionar escenario de evaluación')}
                      />
                    )}
                    getOptionLabel={(option) => option?.name}
                  />
                )}
              </Grid>
              <Grid item xs={6}>
                <InputLabel>{t('Escala Representativa')}</InputLabel>
                {isLoadingRepresentativeScaleAll ? (
                  <TextField
                    fullWidth
                    placeholder={t('Cargando escala representativa...')}
                    disabled
                  />
                ) : (
                  <Autocomplete
                    disabled={true}
                    options={representativeScaleAll! || []}
                    value={
                      representativeScaleAll?.find(
                        (dt) => dt.id === Number(formById?.eva_scale_representative_id)
                      ) || null
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={t('Seleccione una escala representativa')}
                      />
                    )}
                    getOptionLabel={(option) => option?.name}
                  />
                )}
              </Grid>
              {!formById.course_id && (
                <Grid item xs={6}>
                  <InputLabel>{t('Evaluado')}</InputLabel>
                  {isLoadingCollaboratorsAll ? (
                    <TextField
                      fullWidth
                      placeholder={t('Seleccione un curso para ver los evaluados')}
                      disabled
                    />
                  ) : (
                    <Autocomplete
                      options={Array.isArray(collaboratorAll) ? collaboratorAll : []}
                      value={
                        Array.isArray(collaboratorAll)
                          ? collaboratorAll.find((dt) => {
                              return Number(dt.user_id) === Number(formById?.evaluated_id);
                            })
                          : null
                      }
                      renderInput={(params) => (
                        <TextField {...params} placeholder={t('Seleccione una evaluado')} />
                      )}
                      getOptionLabel={(option) => option?.pers_full_lastname!}
                    />
                  )}
                </Grid>
              )}
              <Grid item xs={6}>
                <InputLabel>{t('Evaluado')}</InputLabel>
                {evaluated.length === 0 ? (
                  <TextField
                    fullWidth
                    placeholder={t('Seleccione un curso para ver los evaluados')}
                    disabled
                  />
                ) : (
                  <Autocomplete
                    disabled={true}
                    options={evaluated}
                    value={
                      evaluated.find(
                        (dt) => Number(dt.collaborator.user_id) === Number(formById?.evaluated_id)
                      ) || null
                    }
                    renderInput={(params) => (
                      <TextField {...params} placeholder={t('Seleccione una evaluado')} />
                    )}
                    getOptionLabel={(option) => option?.collaborator.user.person.pers_full_name!}
                  />
                )}
              </Grid>
              <Grid item container xs={12}>
                <Grid item xs={12}>
                  <InputLabel>{t('Calificación')}</InputLabel>
                </Grid>
                <Grid item xs={12}>
                  {evaTypes?.eva_rubrica_detail.map((item, index) => (
                    <Grid
                      item
                      xs={6}
                      key={item.id}
                      sx={{
                        my: 2,
                        mx: 'auto',
                      }}
                    >
                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          sx={{
                            wordWrap: 'break-word',
                            width: '150px',
                          }}
                        >
                          {item.eva_types.name}
                        </Typography>
                        <TextField
                          type="number"
                          inputProps={{
                            min: 1,
                            max: 100,
                          }}
                          value={parseFloat(
                            formById?.eva_formulary_details[index]?.form_value
                          ).toFixed(2)}
                          sx={{
                            mx: 1,
                            width: '180px',
                          }}
                        />
                        <Grid
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <Typography variant="body2" color="textSecondary">
                            {Number(item.eva_peso).toFixed(1)}% =
                          </Typography>
                        </Grid>
                        {formById?.eva_formulary_details[index]?.form_value ? (
                          <Grid
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <Typography
                              variant="h5"
                              sx={{
                                ml: 5,
                                display: 'flex',
                                textAlign: 'center',
                              }}
                            >
                              {(parseFloat(formById?.eva_formulary_details[index].form_value) *
                                Number(item.eva_peso)) /
                                100}
                            </Typography>
                          </Grid>
                        ) : (
                          <Grid
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <Typography
                              variant="h5"
                              sx={{
                                ml: 5,
                                display: 'flex',
                                textAlign: 'center',
                              }}
                            >
                              0.0
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  ))}

                  {ponderation ? (
                    <Grid
                      item
                      xs={6}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        my: 4,
                        mx: 'auto',
                      }}
                    >
                      <Typography variant="h5">{t('Total')}: &nbsp;</Typography>
                      <Typography variant="h5">{Number(ponderation).toFixed(2)}</Typography>
                      <Typography variant="h5">
                        &nbsp;&nbsp;&nbsp;{formById?.eva_scale_representative_name}
                      </Typography>
                    </Grid>
                  ) : (
                    <Grid
                      item
                      xs={6}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        my: 4,
                        mx: 'auto',
                      }}
                    >
                      <Typography variant="h5">
                        {t(
                          'Seleccione una escala representativa y digite todas las calificaciones para realizar el cálculo del total'
                        )}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                sx={{
                  mx: 'auto',
                }}
              >
                <InputLabel
                  sx={{
                    mb: 2,
                  }}
                >
                  {t('Documentos')}
                </InputLabel>
                {Array.isArray(formById?.eva_formulary_documents) && (
                  <Grid
                    item
                    container
                    xs={12}
                    sx={{
                      mb: 4,
                    }}
                  >
                    {formById?.eva_formulary_documents.map((item) => (
                      <Grid item xs={6} key={item.id}>
                        <a
                          href={`${urlDownload}/${item?.eva_formulary_document_url}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            textDecoration: 'none',
                          }}
                        >
                          <Grid
                            item
                            xs={12}
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              cursor: 'pointer',
                            }}
                          >
                            <FileDownload />
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              sx={{
                                wordWrap: 'break-word',
                                width: '150px',
                              }}
                            >
                              {item?.eva_formulary_type_document?.eva_form_tip_doc_name}
                            </Typography>
                          </Grid>
                        </a>
                      </Grid>
                    ))}
                  </Grid>
                )}
              </Grid>
            </Grid>
          </form>
        )}
      </Grid>
    </Grid>
  );
};

export default FormPreview;
