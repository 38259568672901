import { ButtonProps, IconButton, Tooltip } from '@material-ui/core';
import { GridView } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

interface Props extends ButtonProps {
  btnText?: string;
  selected?: boolean;
}

const CardViewButton = ({ onClick, btnText, ...props }: Props) => {
  const { t } = useTranslation();

  return (
    <Tooltip title={btnText || t('shared.card_view.tooltip')}>
      <IconButton onClick={onClick}>
        <GridView
          sx={{
            color: (theme) =>
              props.selected ? theme.palette.primary.main : theme.palette.grey[500],
          }}
        />
        {/* {btnText || t('shared.add_button.tooltip')} */}
      </IconButton>
    </Tooltip>
  );
};

CardViewButton.defaultProps = {
  btnText: undefined,
};

export default CardViewButton;
