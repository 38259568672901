import {
  IconGizmo,
  IconSchool,
  IconHierarchy,
  IconArticle,
  IconFileSpreadsheet,
  IconFileSearch,
  IconSettings,
} from '@tabler/icons';

import { shared } from '../shared';

// Icon Group
const icons = {
  IconGizmo,
  IconSchool,
  IconHierarchy,
  IconArticle,
  IconFileSpreadsheet,
  IconFileSearch,
  IconSettings,
};

export const research = {
  id: 'research',
  type: 'group',
  title: 'research.menu.title',
  children: [
    {
      id: 'proyectos',
      title: 'Proyectos',
      menuId: 'Administración',
      type: 'collapse',
      url: '/administrar-lineas-de-investigacion/proyectos',
      permissionKey: 'investigacion',
      icon: icons['IconFileSpreadsheet'],
      children: [
        {
          id: 'proyectos',
          title: 'Proyectos',
          menuId: 'Proyectos',
          type: 'item',
          url: '/administrar-lineas-de-investigacion/proyectos',
          permissionKey: 'investigacion',
        },
        {
          id: 'publicaciones',
          title: 'communityOutreach.menu.publications',
          menuId: 'communityOutreach.menu.publications',
          type: 'item',
          url: '/publicaciones',
          permissionKey: 'investigacion',
        },
      ],
    },
    {
      id: 'configuration-proyectos',
      title: 'Configuración de Proyectos',
      menuId: 'Configuración ',
      type: 'collapse',
      permissionKey: 'investigacion',
      icon: icons['IconSettings'],
      children: [
        {
          id: 'administrar-lineas-de-investigacion',
          title: 'communityOutreach.menu.resaerch-lines',
          menuId: 'Líneas',
          type: 'item',
          url: '/administrar-lineas-de-investigacion/lineas-de-proyecto-de-investigacion',
          permissionKey: 'investigacion',
        },
        {
          id: 'administrar-lineas-de-investigacion',
          title: 'communityOutreach.menu.resaerch-lines',
          menuId: 'Grupos',
          type: 'item',
          url: '/administrar-lineas-de-investigacion/grupos-de-proyecto-de-investigacion',
          permissionKey: 'investigacion',
        },
      ],
    },

    // {
    //   id: 'seguimiento-proyecto-investigacion',
    //   title: 'communityOutreach.menu.research-project-follow-up',
    //   menuId: 'communityOutreach.menu.research-project-follow-up',
    //   type: 'item',
    //   url: '/seguimiento-proyecto-investigacion',
    //   permissionKey: 'investigacion',
    //   icon: icons['IconFileSearch'],
    // },
    // shared menu
    ...shared,
  ],
};
