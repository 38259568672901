//import { Redirect } from 'react-router-dom';
import { Switch } from 'react-router-dom';
import GuardRoute from '../../../../router/guards/GuardRoute';
//import { useAppSelector } from '../../../../hooks/useRedux';
import EventListScreen from '../screens/EventListScreen';

const StudentEventsRouter = () => {
  //const { userInfo } = useAppSelector((state) => state.auth);

  // para el correcto funcionamiento de este módulo es contar con:
  // - ID de la malla curricular
  // - ID del record estudiantil
  // En caso de no responder los datos del necesarios del estudiante, redireccionar al dashboard
  // if (!userInfo || !userInfo.studentInfo) {
  //   return <Redirect to="/dashboard" />;
  // }

  return (
    <Switch>
      <GuardRoute exact path="/eventos-visualizar" component={EventListScreen} />
    </Switch>
  );
};

export default StudentEventsRouter;
