import { Avatar, Card, CardContent, Divider, Grid, Typography } from '@material-ui/core';
import { useAppSelector } from '../../../../hooks/useRedux';
import { useGetTermsAndConditionsQuery } from '../slices/institutionalInfoApiSlice';
import { baseUrl } from '../../../../api/mainApi';

const MisionVision = () => {
  const { currentProfile } = useAppSelector((state) => state.access);
  const { data } = useGetTermsAndConditionsQuery({
    user_profile_id: currentProfile?.profileId!,
  });

  return (
    <>
      <Grid sx={{ my: 3 }}>
        <Typography variant="h2">Misión y Visión</Typography>
      </Grid>
      <Grid item xs={12} mt={1}>
        <Divider />
      </Grid>
      <Grid item container xs={12} justifyContent="center" mb={1}>
        <Grid item xs={12} md={6} p={2}>
          <Card
            sx={{
              boxShadow: '1px 1px 5px rgba(0, 0, 0, 0.2)',
            }}
          >
            <CardContent>
              <Typography variant="h4">Misión</Typography>
              <Typography variant="body1" align="center">
                {data?.mision}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} p={2}>
          <Card
            sx={{
              boxShadow: '1px 1px 5px rgba(0, 0, 0, 0.2)',
            }}
          >
            <CardContent>
              <Typography variant="h4">Visión</Typography>
              <Typography variant="body1" align="center">
                {data?.vision}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {data?.co_director_message && (
        <>
          <Grid sx={{ my: 3 }}>
            <Typography variant="h2">Mensaje de la autoridad</Typography>
          </Grid>
          <Grid item xs={12} mt={1}>
            <Divider />
          </Grid>
          <Grid item container xs={12}>
            <Grid item container xs={12} md={4} p={2} justifyContent="flex-end">
              <Avatar
                sx={{
                  height: '100px',
                  width: '100px',
                }}
              >
                <img
                  src={`${baseUrl}/show?name=${data?.co_url_file_director_photo}`}
                  alt=""
                  width="100%"
                />
              </Avatar>
            </Grid>
            <Grid item xs={12} md={6} p={2}>
              <Typography
                variant="body1"
                sx={{
                  fontSize: '1.3em',
                  color: 'text.primary',
                }}
                align="center"
              >
                {data?.co_director_message}
              </Typography>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default MisionVision;
