import { Button, CircularProgress, Grid } from '@material-ui/core';
import { IconChevronRight } from '@tabler/icons';
import { differenceInDays, parseISO } from 'date-fns';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../hooks/useRedux';
import LocalizedDatePicker from '../../../shared/components/controlled/LocalizedDatePicker';
import SearchPaginatedTable from '../../../shared/components/tables/SearchPaginatedTable';
import useGoToVirtualClassroom from '../../academic-portfolio/hooks/useGoToVirtualClassroom';
import { useGetStudentActivitiesNotPagedQuery } from '../../dashboard/slices/dashboardApiSlice';

const ActivitiesList = () => {
  const { t } = useTranslation();

  const { currentStudent: { id: studentId } = { id: undefined } } = useAppSelector(
    (state) => state.access
  );

  const [date, setDate] = useState<Date>(new Date());

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const [search, setSearch] = useState('');

  const [valueCourseId, setValueCourseId] = useState(0);

  const { isFetching: isFetchingGoToAct } = useGoToVirtualClassroom(valueCourseId, 'tareas');

  const { data, isFetching, error, isLoading } = useGetStudentActivitiesNotPagedQuery(
    {
      studentId: studentId!,
      isCalendar: true,
      month: date.getMonth() + 1,
      year: date.getFullYear(),
    },
    { skip: !studentId || !date }
  );

  console.log(data, isFetching);

  const datafetch = data
    ?.map((activity, idx) => ({
      id: idx + 1,
      subject: activity.mat_name,
      description: activity.act_description,
      startDate: activity.cact_start_date,
      endDate: activity.cact_end_date,
      rest: '',
      options: activity,
    }))
    ?.filter((activity) => {
      const today = new Date();
      const endDate = parseISO(activity.endDate!);
      const diffDays = differenceInDays(endDate, today);

      return diffDays >= 0;
    })
    ?.sort((a, b) => {
      const today = new Date();
      const endDateA = parseISO(a.endDate!);
      const endDateB = parseISO(b.endDate!);

      const diffDaysA = differenceInDays(endDateA, today);
      const diffDaysB = differenceInDays(endDateB, today);

      return diffDaysA - diffDaysB;
    });

  return (
    <Grid container>
      <Grid item container xs={12} mb={2}>
        <Grid item xs={12} md={4}>
          <LocalizedDatePicker
            views={['month', 'year']}
            value={date}
            onChange={(date) => {
              setDate(date as Date);
            }}
          />
        </Grid>
      </Grid>
      <SearchPaginatedTable
        data={
          datafetch
            ?.filter(
              (activity) =>
                activity.subject?.toLowerCase().includes(search.toLowerCase()) ||
                activity.description?.toLowerCase().includes(search.toLowerCase())
            )
            ?.slice((page - 1) * perPage, page * perPage) || []
        }
        error={error}
        isLoading={isLoading}
        isFetching={isFetching}
        headers={{
          id: 'N°',
          subject: 'Materia',
          description: 'Descripción',
          startDate: 'Fecha de inicio',
          endDate: 'Fecha de fin',
          rest: 'Días restantes',
          options: 'Opciones',
        }}
        keyExtractor={(row) => row.id.toString()}
        page={page}
        perPage={perPage}
        search={search}
        total={datafetch?.length || 0}
        searchPlacehoder="Buscar"
        setPage={setPage}
        setSearch={setSearch}
        setPerPage={setPerPage}
        customRenderers={{
          rest: (row) => {
            const today = new Date();
            const endDate = parseISO(row.endDate!);

            const diffDays = differenceInDays(endDate, today);

            return (
              <span
                style={{
                  color: diffDays <= 1 ? 'red' : 'green',
                  animation: diffDays <= 1 ? 'blinking 1s infinite' : 'none',
                  fontWeight: 'bold',
                }}
              >
                {diffDays}
              </span>
            );
          },
          options: (row) => (
            <Button
              variant="contained"
              color="primary"
              size="small"
              sx={{ borderRadius: '8px' }}
              disabled={isFetchingGoToAct}
              endIcon={
                isFetchingGoToAct && valueCourseId === Number(row.options.course_id) ? (
                  <CircularProgress size={20} color="secondary" />
                ) : (
                  <IconChevronRight />
                )
              }
              onClick={() => setValueCourseId(Number(row.options.course_id))}
            >
              {t('students.dashboard.week-activities.table_btn')}
            </Button>
          ),
        }}
        customDataCellsProperties={{
          id: { align: 'center' },
          subject: { align: 'center' },
          description: { align: 'center' },
          startDate: { align: 'center' },
          endDate: { align: 'center' },
          rest: { align: 'center' },
          options: { align: 'center' },
        }}
      />
    </Grid>
  );
};

export default ActivitiesList;
