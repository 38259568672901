import { useState } from 'react';
import Grid from '@material-ui/core/Grid';
// import Typography from '@material-ui/core/Typography';

import InvestigationProjectLinesTable from './InvestigationProjectLinesTable';
import { useGetInvestigationLinesQuery } from '../../slices/InvestigationLinesApiSlice';
// import FilterTables from './FilterTables';
import { useAppSelector } from '../../../../../hooks/useRedux';
// import { useTranslation } from 'react-i18next';
import { FilterArrow } from '../../../../shared/components/tables/SearchPaginatedTable';

export const invLines = { id: 1, label: 'Lineas de investigacion' };
export const invGroups = { id: 2, label: 'Grupos de investigacion' };

export interface FilterData {
  group: FilterArrow;
  lines: FilterArrow;
}

const InvestigationProjectLinesMain = () => {
  // const { t } = useTranslation();

  const {
    page: pageLines,
    perPage: perPageLines,
    search: searchLines,
  } = useAppSelector((state) => state.investigationLines.investigationLineTable);

  // Filtro de data

  const [sort, setSort] = useState<FilterData>({
    group: { name: 'name', direction: 'asc' },
    lines: { name: 'name', direction: 'asc' },
  });

  // Se pusieron los querys juntos para el filtro en comun del diseno original

  const {
    data: dataLines,
    error: errorLines,
    isLoading: isLoadingLines,
    isFetching: isFetchingLines,
  } = useGetInvestigationLinesQuery({
    search: searchLines,
    type_sort: sort.lines.direction,
    sort: sort.lines.name,
    page: pageLines,
    size: perPageLines,
  });

  return (
    <Grid container spacing={4}>
      {/* <Grid item xs={12} mt={2}>
        {/* En el diseno original aqui se mostraba un filtro, pero ya fue, se lo deja para un futuro :C 
        <FilterTables filter={filterData} setFilter={setFilterData} />
      </Grid> */}
      <Grid item xs={12}>
        <InvestigationProjectLinesTable
          data={dataLines!}
          isLoading={isLoadingLines}
          error={errorLines}
          isFetching={isFetchingLines}
          setSort={setSort}
          sort={sort}
        />
      </Grid>
    </Grid>
  );
};

export default InvestigationProjectLinesMain;
