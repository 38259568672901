import DeleteAlert from '../../../../shared/components/dialogs/DeleteAlert';

import { useAppDispatch, useAppSelector } from '.././../../../../hooks/useRedux';
import { useDeleteFaqMutation } from '../../slices/faqApiSlice';
import { resetFaqDelete } from '../../slices/faqSlice';

export const FaqDeleteDialog = () => {
   const dispatch = useAppDispatch();

   const { faqCurrent, faqDelete } = useAppSelector((state) => state.faqs);

   const { currentProfile } = useAppSelector((state) => state.access);

   const [deleteFaq, { isLoading, isSuccess, isError, error }] = useDeleteFaqMutation();

   const handleDelete = () => {
      deleteFaq({ profileId: currentProfile?.profileId!, faqId: faqCurrent! });
   };

   const handleClose = () => {
      dispatch(resetFaqDelete());
   };

   return (
      <DeleteAlert
         open={faqDelete}
         onClose={handleClose}
         isLoading={isLoading}
         isSuccess={isSuccess}
         isError={isError}
         handleDelete={handleDelete}
         error={error as string}
      />
   );
};

