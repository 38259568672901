import { Autocomplete, Grid, TextField } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../../../../../../hooks/useRedux';
import { useTableSearch } from '../../../../../../../hooks/useTableSearch';
import LocalizedDatePicker from '../../../../../../shared/components/controlled/LocalizedDatePicker';
import AddButton from '../../../../../../shared/components/tables/buttons/AddButton';
import DeleteButton from '../../../../../../shared/components/tables/buttons/DeleteButton';
import SearchPaginatedTableAndCollapsibleItems from '../../../../../../shared/components/tables/SearchPaginatedTableAndCollapsibleItems';
import { useLazyGetListGroupsBygroupTypeQuery } from '../../slices/listGroupsApiSlice';
import {
  setListGroupTable,
  setStart_date,
  setUuidGroup,
  setOpenDeleteDialog,
  setOpenAddGroupCourse,
  setEnd_date,
} from '../../slices/listGroupsSlice';

const ListGroupsTable = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const {
    listGroupTable: { page, size, search },
    end_date,
    start_date,
  } = useAppSelector((state) => state.listGroups);

  const navigate = useHistory();

  const [groupIdSelected, setGroupIdSelected] = useState<string>();

  const groupsOptions = [
    {
      label: 'Cursos por periodo',
      value: 'EGC',
    },
    {
      label: 'Usuarios por grupo',
      value: 'EGU',
    },
  ];

  const { setPage, setPerPage, setSearch } = useTableSearch({
    page,
    perPage: size,
    search,
    updateFunction: setListGroupTable,
  });

  const [
    getGroups,
    {
      data: listGroups,
      isLoading: isLoadingGroups,
      isFetching: isFetchingGroups,
      error: errorGroups,
    },
  ] = useLazyGetListGroupsBygroupTypeQuery();

  const dataFetch =
    listGroups?.data?.map((item, index) => ({
      id: index + ((page - 1) * size + 1),
      name: item.name,
      groupTypeId: item.eva_group_type_id,
      options: item,
    })) || [];

  console.log(dataFetch);

  const handleAdd = () => {
    if (groupIdSelected === 'EGC') {
      dispatch(setOpenAddGroupCourse(true));
    } else {
      navigate.push('/grupos/usuarios-por-grupo');
    }
  };

  useEffect(() => {
    if (groupIdSelected) {
      const executeGetGroupsByGroupType = async () => {
        await getGroups({
          page,
          size,
          search,
          sort: 'created_at',
          type_sort: 'DESC',
          eva_groups_type_keyword: groupIdSelected,
          start_date: start_date.split('T')[0],
          end_date: end_date.split('T')[0],
          status: '',
        });
      };
      executeGetGroupsByGroupType();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, size, search, groupIdSelected, start_date, end_date]);

  return (
    <>
      <Grid
        xs={4}
        sx={{
          mb: 3,
        }}
        container
      >
        <Grid
          item
          xs={12}
          sx={{
            mb: 2,
          }}
        >
          <Autocomplete
            options={groupsOptions}
            renderInput={(params) => (
              <TextField
                {...params}
                //error={Boolean(errors.tipo_comprobante && touched.tipo_comprobante)}
                placeholder={t('maintenance.groups.select.placeholder')}
              />
            )}
            value={groupsOptions.find((item) => item.value === groupIdSelected) || null}
            onChange={(_, value) => {
              setGroupIdSelected(value?.value!);
            }}
          />
        </Grid>
      </Grid>

      <Grid container item xs={6} spacing={2}>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={6}
          sx={{
            mb: 2,
          }}
        >
          <Grid container spacing={1} alignItems="flex-start">
            <Grid item xs zeroMinWidth>
              <LocalizedDatePicker
                value={start_date}
                onChange={(newValue) => {
                  dispatch(setStart_date((newValue as Date).toISOString()));
                }}
                label={t('financial.deposit_transfers.filters.dates.start_label')}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Grid container spacing={1} alignItems="flex-start">
            <Grid item xs zeroMinWidth>
              <LocalizedDatePicker
                value={end_date}
                onChange={(newValue) => {
                  dispatch(setEnd_date((newValue as Date).toISOString()));
                }}
                label={t('financial.deposit_transfers.filters.dates.end_label')}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <SearchPaginatedTableAndCollapsibleItems
        data={dataFetch}
        headers={{
          id: t('N°'),
          name: t('Nombre del grupo'),
          groupTypeId: t('Id del tipo de grupo'),
          options: t('Opciones'),
        }}
        keyExtractor={(item) => `${item.options.uuid}`}
        searchPlacehoder={t('Buscar grupo...')}
        isLoading={isLoadingGroups}
        isFetching={isFetchingGroups}
        error={errorGroups}
        perPage={size}
        setPerPage={setPerPage}
        total={listGroups?.total || 0}
        page={page}
        setPage={setPage}
        numHeader={6}
        search={search}
        setSearch={setSearch}
        customRenderers={{
          options: (row) => (
            <>
              <DeleteButton
                handleDelete={() => {
                  dispatch(setUuidGroup(row.options.uuid));
                  dispatch(setOpenDeleteDialog(true));
                }}
              />
            </>
          ),
        }}
        customDataCellsProperties={{
          options: {
            align: 'center',
          },
          id: {
            align: 'center',
          },
          name: {
            align: 'center',
          },
          groupTypeId: {
            align: 'center',
          },
        }}
        ActionButtons={<AddButton disabled={!groupIdSelected} onClick={handleAdd} />}
      />
    </>
  );
};

export default ListGroupsTable;
