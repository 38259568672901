import {
  Grid,
  FormHelperText,
  InputLabel,
  Autocomplete,
  CircularProgress,
  FormGroup,
  Button,
  Checkbox,
  InputAdornment,
  FormControlLabel,
  TextField,
  CardActions,
} from '@material-ui/core';
import { IconArrowBarRight } from '@tabler/icons';
import { useFormik } from 'formik';
import { useAppSelector, useAppDispatch } from '../../../../../../hooks/useRedux';
import MainCard from '../../../../../shared/components/cards/MainCard';
import { evaluationLimit } from '../../../evaluations/constants';
import {
  setNextStep,
  setTaskCompleteInfo,
  // setTaskChooseGroup,
  setTaskToCreate,
} from '../../../slices/virtualClassroomTeacherSlice';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import esLocale from 'date-fns/locale/es';
import { DateTimePicker, LocalizationProvider } from '@material-ui/lab';
import {
  useGetCategoriesActivityQuery,
  useGetDistributionsCourseSupplyQuery,
} from '../../../evaluations/slices/evaluationApiSlice';
import { TaskToCreatePayload } from '../../interfaces/task-to-create.interfaces';
import { TaskInfoData } from '../../constants/validationSchemas';
import { useGetCatalogByAcronymChildrenQuery } from '../../../../../shared/slices/catalogApi';
import { useTranslation } from 'react-i18next';
import { useGetCourseVCQuery } from '../../../../../students/virtual-classroom/slices/virtualClassroomApiSlice';
import { parseISO } from 'date-fns';

const TaskCompleteInfo = () => {
  const dispatch = useAppDispatch();

  const { currentProfile } = useAppSelector((state) => state.access);

  const {
    course: { courseId },
  } = useAppSelector((state) => state.virtualClassroomTeacherShared);

  const { taskToCreate } = useAppSelector((state) => state.virtualClassroomTeacher);

  const { data: courseSelected, isLoading: isLoadingCourseSelected } = useGetCourseVCQuery({
    courseId: courseId!,
    profileId: currentProfile?.profileId!,
  });

  /***
   * En la iniciación del formulario previamente se pudo haber guardado
   * información referente al taller.
   */

  const { values, getFieldProps, setFieldValue, handleSubmit, errors, touched } = useFormik({
    initialValues: {
      description: taskToCreate?.act_description || '',
      numTries: taskToCreate?.act_num_tries || 1,
      actIsScore: taskToCreate?.act_is_score || 1,
      supplyId: taskToCreate?.supply_id! || 0,
      visible: taskToCreate?.visible! || '1',
      visible_note: taskToCreate?.visible_note! || '1',
      actScoreActivity: taskToCreate?.act_score_activity! || 0,
      category_activity_id: taskToCreate?.category_activity_id! || 0,
      category_activity_keyword: taskToCreate?.category_activity_keyword! || '',
      statusId: 1,
      distributionId: taskToCreate?.calification_models_distributions_id || 0,
      catIsExtemporaneus: taskToCreate?.course_activity?.cact_is_extemporaneous! || 0,
      limitActScoreActivity: taskToCreate?.limitActScoreActivity || 0,
      startDate: new Date(taskToCreate?.course_activity?.cact_start_date!) || new Date(),
      endDate: new Date(taskToCreate?.course_activity?.cact_end_date!) || new Date(),
    },
    validationSchema: TaskInfoData,
    onSubmit: async () => {
      handleSaveInfoChooseGroup();
    },
  });

  console.log(values);

  /**
   * Obtener categorías tipo actividad.
   */
  const {
    data: dataCategory,
    isLoading: isLoadingCategory,
    isFetching: isFetchingCategory,
  } = useGetCategoriesActivityQuery({
    typeActivity: 'Taller',
    userProfileId: currentProfile?.profileId!,
  });

  // !revisar este api, no se esta usando el data
  console.log(dataCategory);

  /**
   * Obtener distribuciones en base a un curso
   */

  const {
    data: dataDistribution,
    isLoading: isLoadingDistribution,
    isFetching: isFetchingDistribution,
  } = useGetDistributionsCourseSupplyQuery(
    {
      courseId: courseId!,
      userProfileId: currentProfile?.profileId!,
    },
    { skip: courseId === 0 || courseId === undefined }
  );

  /**
   * Obtener tipo de actividad.
   */
  const { data: dataTypeActivity } = useGetCatalogByAcronymChildrenQuery({
    acronym: 'tipo-actividad',
    profileId: currentProfile?.profileId!,
  });

  const filterTareas = dataTypeActivity?.filter((item, index) => index > 7);

  /**
   * Cuando un taller es calificado se muestran campos
   * como la distribución e insumos.
   */
  const handleChangeIsScore = (checked: boolean) => {
    const value = checked ? 1 : 0;
    setFieldValue('actIsScore', value);
  };

  const { t } = useTranslation();

  /**
   * Calcular el total de puntos a utilizar en una distribución seleccionado
   * cuando no se ha seleccionado un insumo, por defecto se accede al primer
   * elemento del insumo.
   */
  const calculateLimitPointTotalDistribution = (distributionId: number): number => {
    const dist = dataDistribution?.find((e) => Number(e.distribution_id) === distributionId);
    const compositionValue = Number(dist?.ref2[0]?.composition_value!);
    const sumScoreActivity = Number(dist?.ref2[0]?.suma_score_activity!);
    const limit = compositionValue * 100 - sumScoreActivity;
    return limit;
  };

  /**
   * Ocultar insumos cuando la distribución contiene un solo
   * elemento del mismo nombre de la distribución.
   */
  const obtainDistributionAndSupply = (): boolean => {
    const dist = dataDistribution?.find((e) => Number(e.distribution_id) === values.distributionId);
    if (dist?.ref2?.length === 1 && dist?.ref2![0]?.ref2!) {
      // setFieldValue('supplyId', dist.ref1_supply_id);
      return false;
    }
    return true;
  };

  /**
   * Calcular el total de puntos a utilizar en una distribución seleccionada
   * con un insumo seleccionado.
   */
  const calculateLimitPointTotalSupply = (distributionId: number, supplyId: number): number => {
    const dist = dataDistribution
      ?.find((e) => Number(e.distribution_id) === distributionId)
      ?.ref2.find((e) => Number(e.ref2_supply_id) === supplyId);
    const compositionValue = Number(dist?.composition_value!);
    const sumScoreActivity = Number(dist?.suma_score_activity!);
    const limit = compositionValue * 100 - sumScoreActivity;
    return limit;
  };

  const onChangeDistribution = (distributionId: number, supplyRefId: number) => {
    setFieldValue('actScoreActivity', 1);
    setFieldValue('distributionId', distributionId);

    const isUniqueDist = obtainDistributionAndSupply();

    if (isUniqueDist) {
      const total = calculateLimitPointTotalDistribution(distributionId);
      setFieldValue('supplyId', supplyRefId);

      setFieldValue('limitActScoreActivity', total);
    }
  };

  const onChangeSupply = (supplyId: number) => {
    setFieldValue('actScoreActivity', 1);
    setFieldValue('supplyId', supplyId);
    const total = calculateLimitPointTotalSupply(values.distributionId, supplyId);
    setFieldValue('limitActScoreActivity', total);
  };

  /**
   * Guardar información del formulario.
   * Seleccionar estudiantes y agruparlos.
   * Desactivar las pantallas de completado de información
   * y selección de preguntas.
   */

  const handleSaveInfoChooseGroup = () => {
    dispatch(setNextStep(1));
    //dispatch(setTaskChooseGroup(true));
    dispatch(setTaskCompleteInfo(true));

    const data: TaskToCreatePayload = {
      act_description: values.description,
      supply_id:
        dataDistribution! &&
        dataDistribution?.find((e) => Number(e.ref1_supply_id) === values.distributionId)!.ref2!
          ?.length! === 1
          ? values.distributionId!
          : values.supplyId,
      act_is_score: values.actIsScore,
      category_activity_id: values.category_activity_id!,
      category_activity_keyword: values.category_activity_keyword!,
      type_activity_id: values.category_activity_id! /*dataTypeActivity?.find(
        (a) =>
          a?.cat_name!.toLowerCase() ===
          dataCategory?.find((e) => e.id === values.category_activity_id)?.cat_name!.toLowerCase()!
      )?.id!*/,
      act_score_activity: values.actScoreActivity,
      act_num_tries: values.numTries,
      status_id: values.statusId,
      calification_models_distributions_id: values.distributionId!,
      limitActScoreActivity: values.limitActScoreActivity,
      course_student: taskToCreate?.course_student! || [],
      detail: taskToCreate?.detail! || [],
      course_activity: {
        cact_start_date: values.startDate.getTime(),
        cact_end_date: values.endDate.getTime(),
        cact_is_extemporaneous: values.catIsExtemporaneus,
      },
      visible: values.visible,
      visible_note: values.visible_note,
      files: undefined,
    };

    dispatch(setTaskToCreate(data));
  };

  return (
    <Grid container spacing={1} justifyContent="center" alignItems="flex-start">
      <Grid item xs={12} md={6} container spacing={1}>
        <Grid item xs={12}>
          <MainCard title={t('teacherVirtualClassroom.tasks.info.title')}>
            {/* Container is qualified */}
            <Grid item xs={12} container spacing={2}>
              <Grid item xs={12}>
                <FormGroup>
                  <Grid display="flex" xs={12} justifyContent={'space-between'}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.actIsScore === 1}
                          onChange={(e) => handleChangeIsScore(e.target.checked)}
                        />
                      }
                      label={t('teacherVirtualClassroom.tasks.questions.calificate')}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.visible === '1'}
                          onChange={(e) =>
                            e.target.checked
                              ? setFieldValue('visible', '1')
                              : setFieldValue('visible', '0')
                          }
                        />
                      }
                      label="Visible"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.visible_note === '1'}
                          onChange={(e) =>
                            e.target.checked
                              ? setFieldValue('visible_note', '1')
                              : setFieldValue('visible_note', '0')
                          }
                        />
                      }
                      label="Mostrar Nota"
                    />
                  </Grid>
                </FormGroup>
              </Grid>
            </Grid>

            {/* Container description */}
            <Grid item xs={12} sx={{ mt: 2 }}>
              <InputLabel>{t('teacherVirtualClassroom.tasks.table.input.title')}</InputLabel>
              <TextField
                fullWidth
                rows={3}
                multiline
                error={Boolean(errors.description && touched.description)}
                placeholder={t('teacherVirtualClassroom.tasks.table.create.title')}
                {...getFieldProps('description')}
              />
              {errors.description && touched.description && (
                <FormHelperText error>{errors.description}</FormHelperText>
              )}
            </Grid>

            {/* Container extemporaneus and num tries */}
            {/* {values.actIsScore === 0 && ( */}
            <Grid container item xs={12} spacing={3}>
              <Grid item xs={6} sx={{ mt: 2 }}>
                <InputLabel>
                  {t('teacherVirtualClassroom.tests.preCreatedAllowToPerformLabel')}
                </InputLabel>
                <Autocomplete
                  options={evaluationLimit! || []}
                  getOptionLabel={(option) => option.name}
                  onChange={(_, value) => setFieldValue('catIsExtemporaneus', value?.value!)}
                  value={
                    evaluationLimit.find((e) => e.id === values.catIsExtemporaneus) ||
                    evaluationLimit[0]
                  }
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Seleccione límite" />
                  )}
                  fullWidth
                  clearIcon={null}
                />
              </Grid>
              <Grid item xs={6} sx={{ mt: 2 }}>
                <InputLabel>
                  {t('teacherVirtualClassroom.tasks.table.input.info.itentos')}
                </InputLabel>
                <TextField
                  id="button-action-search"
                  fullWidth
                  type="number"
                  InputProps={{ inputProps: { min: 1 } }}
                  variant="outlined"
                  {...getFieldProps('numTries')}
                />
                {errors.numTries && touched.numTries && (
                  <FormHelperText error>{errors.numTries}</FormHelperText>
                )}
              </Grid>
            </Grid>
            {/* )} */}

            {/* Container supply */}
            {values.actIsScore === 1 && (
              <Grid container item xs={12} spacing={2} sx={{ mt: 1 }}>
                <Grid item xs={6}>
                  <InputLabel>
                    {t('teacherVirtualClassroom.tasks.table.input.title.distribution')}
                  </InputLabel>
                  {isLoadingDistribution || isFetchingDistribution ? (
                    <>
                      <TextField
                        disabled
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <CircularProgress size={25} />
                            </InputAdornment>
                          ),
                        }}
                        placeholder={t(
                          'teacherVirtualClassroom.tasks.table.input.title.distribution.loading'
                        )}
                      />
                    </>
                  ) : (
                    <Autocomplete
                      options={dataDistribution! || []}
                      loading={isLoadingDistribution}
                      loadingText={t('teacherVirtualClassroom.tests.preCreatedDistributionLoading')}
                      getOptionLabel={(option) => (option ? option?.distribution! : '')}
                      onChange={(_, value) =>
                        onChangeDistribution(
                          Number(value?.ref1_supply_id!),
                          Number(value?.ref2[0].ref2_supply_id)
                        )
                      }
                      value={
                        dataDistribution?.find(
                          (e) => Number(e?.ref1_supply_id!) === values.distributionId
                        )! || null
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={t(
                            'teacherVirtualClassroom.tasks.table.input.title.distribution.loading.select'
                          )}
                        />
                      )}
                      fullWidth
                      clearIcon={null}
                    />
                  )}
                  {errors.distributionId && touched.distributionId && (
                    <FormHelperText error>{errors.distributionId}</FormHelperText>
                  )}
                </Grid>

                {obtainDistributionAndSupply() && values.supplyId !== 0 && (
                  <Grid item xs={6}>
                    <InputLabel>
                      {t('teacherVirtualClassroom.tasks.table.input.title.insumo')}
                    </InputLabel>
                    <Autocomplete
                      options={
                        dataDistribution && values.distributionId !== 0
                          ? dataDistribution.find(
                              (e) => Number(e.ref1_supply_id) === values.distributionId
                            )?.ref2!
                          : []
                      }
                      loading={isLoadingDistribution}
                      loadingText={t(
                        'teacherVirtualClassroom.tasks.table.input.title.insumo.loading'
                      )}
                      getOptionLabel={(option) => (option ? option?.ref2! : '')}
                      onChange={(_, value) => onChangeSupply(Number(value?.ref2_supply_id))}
                      value={
                        dataDistribution
                          ?.find((e) => Number(e.ref1_supply_id) === values.distributionId)
                          ?.ref2.find((e) => Number(e?.ref2_supply_id!) === values.supplyId!)! ||
                        null
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={t(
                            'teacherVirtualClassroom.tasks.table.input.title.insumo.select'
                          )}
                        />
                      )}
                      fullWidth
                      clearIcon={null}
                    />
                  </Grid>
                )}
              </Grid>
            )}

            {/* Container score */}

            <Grid container item xs={12} mt={'5px'} spacing={3}>
              {values.actIsScore === 1 && (
                <Grid item xs={6}>
                  <InputLabel>
                    {t('teacherVirtualClassroom.tasks.questions.puntaje.total')}
                  </InputLabel>
                  <TextField
                    id="button-action-search"
                    fullWidth
                    // disabled={infoEvaluation !== undefined}
                    type="number"
                    InputProps={{ inputProps: { min: 1 } }}
                    variant="outlined"
                    {...getFieldProps('actScoreActivity')}
                  />
                  {errors.actScoreActivity && touched.actScoreActivity && (
                    <FormHelperText error>{errors.actScoreActivity}</FormHelperText>
                  )}
                </Grid>
              )}
              {/**{t('teacherVirtualClassroom.tasks.table.cateogoria.title')} */}
              <Grid item xs={6}>
                <InputLabel>{t('teacherVirtualClassroom.tasks.questions.typeTask')}</InputLabel>
                {isLoadingCategory || isFetchingCategory ? (
                  <>
                    <TextField
                      disabled
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <CircularProgress size={25} />
                          </InputAdornment>
                        ),
                      }}
                      placeholder={t('teacherVirtualClassroom.tasks.table.cateogoria.loading')}
                    />
                  </>
                ) : (
                  <Autocomplete
                    options={filterTareas! || []}
                    loading={isLoadingCategory}
                    loadingText={t('teacherVirtualClassroom.tests.preCreatedActivityLoading')}
                    getOptionLabel={(option) => option.cat_name}
                    onChange={(_, value) => {
                      setFieldValue('category_activity_id', value?.id!);
                      setFieldValue('category_activity_keyword', value?.cat_acronym!);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={t(
                          'teacherVirtualClassroom.tests.preCreatedActivityPlaceholder'
                        )}
                      />
                    )}
                    value={filterTareas?.find((e) => e.id === values.category_activity_id)}
                    fullWidth
                    clearIcon={null}
                  />
                )}
                {errors.category_activity_id && touched.category_activity_id && (
                  <FormHelperText error>{errors.category_activity_id}</FormHelperText>
                )}
              </Grid>
            </Grid>

            {/* Container dates */}
            <Grid container item xs={12} spacing={2}>
              <Grid item xs={6} marginTop={2}>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                  <DateTimePicker
                    loading={isLoadingCourseSelected}
                    minDate={
                      courseSelected?.start_date ? parseISO(courseSelected?.start_date) : null
                    }
                    maxDate={courseSelected?.end_date ? parseISO(courseSelected?.end_date) : null}
                    renderInput={(props) => <TextField fullWidth {...props} helperText="" />}
                    label={t('teacherVirtualClassroom.tasks.table.fechainicio.title')}
                    value={values.startDate}
                    onChange={(newValue: Date | null) => setFieldValue('startDate', newValue)}
                  />
                  {errors.startDate && touched.startDate && (
                    <FormHelperText error>{errors.startDate}</FormHelperText>
                  )}
                </LocalizationProvider>
              </Grid>
              <Grid item xs={6} marginTop={2}>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                  <DateTimePicker
                    loading={isLoadingCourseSelected}
                    minDate={values.startDate || null}
                    minTime={values.startDate || null}
                    maxDate={courseSelected?.end_date ? parseISO(courseSelected?.end_date) : null}
                    renderInput={(props) => <TextField fullWidth {...props} helperText="" />}
                    label={t('teacherVirtualClassroom.tasks.table.fechafinal.title')}
                    value={values.endDate}
                    onChange={(newValue: Date | null) => setFieldValue('endDate', newValue)}
                  />
                  {errors.endDate && touched.endDate && (
                    <FormHelperText error>{errors.endDate}</FormHelperText>
                  )}
                </LocalizationProvider>
              </Grid>
            </Grid>
          </MainCard>
          <CardActions sx={{ justifyContent: 'rigth' }}>
            <Grid item>
              <Button
                onClick={() => handleSubmit()}
                endIcon={<IconArrowBarRight size={20} />}
                style={{ textTransform: 'unset' }}
                variant="contained"
                size="large"
                color="primary"
              >
                {t('teacherVirtualClassroom.tasks.compleinfo.button.title')}
              </Button>
            </Grid>
          </CardActions>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TaskCompleteInfo;
