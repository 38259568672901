import { useHistory } from 'react-router-dom';
import { Grid, IconButton, Typography, useTheme } from '@material-ui/core';
import { IconArrowNarrowLeft } from '@tabler/icons';
import EditRequisitionForm from '../components/EditRequisitionForm';

import MainCardForm from '../../../shared/components/cards/MainCardForm';
// import MainCard from '../../../shared/components/cards/MainCard'
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../hooks/useRedux';
import ObservationDialog from '../components/Dialogs/ObservationDialog';

const AddRequisitionScreen = () => {
  const { t } = useTranslation();

  const theme = useTheme();
  const history = useHistory();

  const { observationDialog } = useAppSelector((state) => state.requisitionTeacher);

  return (
    <MainCardForm>
      <Grid item xs={12}>
        <Grid item container alignItems="center" gap={1} sx={{ cursor: 'pointer' }}>
          <IconButton onClick={() => history.goBack()}>
            <IconArrowNarrowLeft size={30} color={theme.palette.text.dark} />
          </IconButton>
          <Typography variant="h2">{t('academic.requisition.review.title')}</Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} container>
        <EditRequisitionForm />
      </Grid>

      {observationDialog.open && observationDialog.message !== '' && <ObservationDialog />}
    </MainCardForm>
  );
};

export default AddRequisitionScreen;
