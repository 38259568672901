import { useState, useEffect } from 'react';
import { useHistory, useParams, Redirect } from 'react-router-dom';
import { useGetCollectiveEducationLevelQuery } from '../slices/educationLevelsApiSlice';

import SearchPaginatedTable from '../../../shared/components/tables/SearchPaginatedTable';
import AddButton from '../../../shared/components/tables/buttons/AddButton';
import ListCollaboratorsCollective from '../../../shared/components/tables/buttons/ListCollaboratorsCollective';
import EditButton from '../../../shared/components/tables/buttons/EditButton';
import DeleteButton from '../../../shared/components/tables/buttons/DeleteButton';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import {
  setCurrentCollective,
  setOpenDialogCollCollective,
  setDeleteCollectiveModalOpen,
  setEducationLevelCollectives,
} from '../slices/educationLevelsSlice';
import OmniChip from '../../../shared/components/badges/OmniChip';
import { StatusCollectivesList } from '../constants/statusCollectives';

const CollectiveTable = () => {
  const { educationLevelList } = useAppSelector((state) => state.educationLevels);

  const { educationLevelId } = useParams<{ educationLevelId: string }>();

  const dispatch = useAppDispatch();

  const history = useHistory();

  const [page, setPage] = useState(1);

  const [perPage, setPerPage] = useState(5);

  const [search, setSearch] = useState('');

  useEffect(() => {
    setPage(1);
  }, [perPage]);

  const { isLoading, error, data, isFetching } = useGetCollectiveEducationLevelQuery(
    {
      educationLevelId: Number(educationLevelId),
    },
    { refetchOnMountOrArgChange: 30 }
  );

  useEffect(() => {
    if (data) {
      dispatch(setEducationLevelCollectives(data));
    }
  }, [data, dispatch]);

  const goToAdd = () => {
    history.push(`/administrar-unidades-academicas/agregar-colectivo/${educationLevelId}`);
  };

  const gotToListCollaborators = (colectiveId: number) => {
    dispatch(setOpenDialogCollCollective(true));

    dispatch(setCurrentCollective(colectiveId));
  };

  const goToEdit = (collectiveId: number) => {
    dispatch(setCurrentCollective(collectiveId));

    history.push(`/administrar-unidades-academicas/editar-colectivo/${collectiveId}`);
  };

  const goToDelete = (collectiveId: number) => {
    dispatch(setCurrentCollective(collectiveId));

    dispatch(setDeleteCollectiveModalOpen(true));
  };

  const datafetch = data?.map((d) => ({
    id: d.id,
    name: d.name,
    status: d.status.id,
    options: '',
  }));

  if (!educationLevelList) {
    return <Redirect to="/administrar-unidades-academicas" />;
  }

  return (
    <SearchPaginatedTable
      data={datafetch! || []}
      headers={{
        id: 'Código',
        name: 'Nombre',
        status: 'Estado',
        options: 'Opciones',
      }}
      keyExtractor={(item) => item.id.toString()}
      searchPlacehoder="Buscar colectivo."
      isLoading={isLoading}
      isFetching={isFetching}
      error={error}
      perPage={perPage}
      setPerPage={setPerPage}
      total={data?.length || 0}
      page={page}
      setPage={setPage}
      numHeader={8}
      search={search}
      setSearch={setSearch}
      customRenderers={{
        options: (row) => (
          <>
            <ListCollaboratorsCollective onClick={() => gotToListCollaborators(row.id)} />
            <EditButton handleEdit={() => goToEdit(row.id)} />
            <DeleteButton handleDelete={() => goToDelete(row.id)} />
          </>
        ),
        status: (row) => (
          <OmniChip
            variant={0}
            id={StatusCollectivesList.find((e) => e.id === Number(row.status))?.id || 0}
            statusArray={StatusCollectivesList}
          />
        ),
      }}
      customDataCellsProperties={{
        id: { align: 'center' },
        options: { align: 'center' },
      }}
      customHeadersCellsProperties={{
        id: { align: 'center' },
        options: { align: 'center' },
      }}
      ActionButtons={<AddButton size="large" onClick={goToAdd} />}
    />
  );
};

export default CollectiveTable;
