import { Switch, Redirect } from 'react-router-dom';
import { useAppSelector } from '../../../../hooks/useRedux';
import GuardRoute from '../../../../router/guards/GuardRoute';
import { permissionKey, permissionKeyAdmin } from '../constants/permissions';
import MealTicketScreen from '../screens/MealTicketScreen';

const MealTicketRouter = () => {
  const { currentModule } = useAppSelector((state) => state.access);
  const hasPermission =
    permissionKey in currentModule?.permissions! ||
    permissionKeyAdmin in currentModule?.permissions!;

  if (!hasPermission) <Redirect to="/dashboard" />;

  return (
    <Switch>
      <GuardRoute
        exact
        path="/ticket-comida"
        component={MealTicketScreen}
        permissionKey={permissionKey || permissionKeyAdmin}
        //subPermissionKey={subPermissionKeys.read}
      />
    </Switch>
  );
};

export default MealTicketRouter;
