import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import {
  Autocomplete,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  InputLabel,
  Skeleton,
  TextField,
  Typography,
} from '@material-ui/core';

import { useFormik } from 'formik';

import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import DateRangePicker, { DateRange } from '@material-ui/lab/DateRangePicker';
import esLocale from 'date-fns/locale/es';
import { useAppSelector } from '../../../../hooks/useRedux';
import { EventNewSchema } from '../constants/validation-eschemas';
import { useUpdateEventMutation } from '../slices/eventNewApiSlice';
import { useFormStyles } from '../constants/styles';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import { EVENTS_AVAILABLES } from '../constants/events_availables';
import { validateDates } from '../helpers';
import { parseISO } from 'date-fns';
import { useGetAvailablesProfilesQuery } from '../../profiles/slices/profilesApiSlice';
import { statusAvailables } from '../../../shared/constants/resourceStatus';
// import DragArea from '../../../students/virtual-classroom/components/DragArea';
import DragArea from '../../../shared/components/files/DragArea';
import { useUploadFilesMutation } from '../../../shared/slices/uploadFilesApi';

const EventNewEdit = () => {
  const history = useHistory();

  const { eventNewId } = useParams<{ eventNewId: string }>();

  const { eventNewList } = useAppSelector((state) => state.eventNew);
  const { currentProfile, defaultConfiguration } = useAppSelector((state) => state.access);

  const classes = useFormStyles();

  const [showAlerts, setShowAlerts] = useState(false);

  const [updateEventNew, { isLoading: isUpdating, isSuccess, isError, error }] =
    useUpdateEventMutation();

  const { isLoading: isLoadingProfiles, data: profilesAvailables } = useGetAvailablesProfilesQuery({
    profileId: currentProfile?.profileId!,
  });

  const eventNewToEdit = eventNewList.find((p) => p.id === Number(eventNewId));

  const [dates, setDates] = useState<DateRange<Date>>([null, null]);

  const [file, setFile] = useState<File | null>(null);

  const onChangeDates = (newDatesValues: DateRange<Date>) => {
    const [start, end] = newDatesValues;
    //Update formik values
    setFieldValue('start_date', start);
    setFieldValue('end_date', end);

    setDates(newDatesValues);
  };

  const [uploadFile, { isLoading: isLoadingF, isError: isErrorF, error: errorF }] =
    useUploadFilesMutation();

  const { getFieldProps, errors, touched, handleSubmit, setFieldValue, values } = useFormik({
    initialValues: {
      type: eventNewToEdit?.type,
      title: eventNewToEdit?.title || '',
      description: eventNewToEdit?.description || '',
      url_external: eventNewToEdit?.url_external || '',
      start_date: eventNewToEdit?.start_date || '',
      end_date: eventNewToEdit?.end_date || '',
      statusId: eventNewToEdit?.status_id || '',
      profiles: eventNewToEdit?.profiles?.map((value) => value.id) || [],
      fileCount: 1,
    },
    validationSchema: EventNewSchema,
    validate: ({ start_date, end_date }) => {
      return validateDates(start_date, end_date);
    },
    onSubmit: async (values) => {
      try {
        let fileRes = '';

        if (file) {
          fileRes = await uploadFile({
            profileId: currentProfile?.profileId!,
            files: file!,
          }).unwrap();
        }

        await updateEventNew({
          profileId: currentProfile?.profileId!,
          eventId: eventNewToEdit?.id!,
          eventNewPayload: {
            description: values.description,
            title: values.title,
            url_external: values.url_external,
            start_date: values.start_date,
            end_date: values.end_date,
            profiles: values.profiles,
            type: values.type,
            status_id: values.statusId,
            url_image: fileRes || 'null',
          },
        });
      } catch (error) {
        console.log(error);
      }

      window.scrollTo(0, 0);

      setShowAlerts(true);
    },
  });

  useEffect(() => {
    setDates([parseISO(eventNewToEdit?.start_date!), parseISO(eventNewToEdit?.end_date!)]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction="column" gap={2}>
        <Grid item>
          {(isError || isErrorF) && showAlerts && (
            <ErrorAlert
              message={(error || errorF) as string}
              handleDismiss={() => setShowAlerts(false)}
            />
          )}

          {isSuccess && showAlerts && (
            <SuccessAlert
              message="El evento ha sido actualizado correctamente."
              handleDismiss={() => setShowAlerts(false)}
              timeoutCallback={{
                timer: 3,
                callbackFn: () => history.push('/administrar-eventos'),
              }}
            />
          )}
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" component="div" sx={{ mb: 3 }}>
            1. Establezca datos del evento.
          </Typography>
          <InputLabel>Tipo de evento</InputLabel>
          <Autocomplete
            options={EVENTS_AVAILABLES}
            loadingText="Cargando tipos de eventos..."
            getOptionSelected={(option, value) => option.value === value.value}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                error={Boolean(errors.type && touched.type)}
                placeholder="Seleccione un tipo de evento"
              />
            )}
            onChange={(_, value) => {
              setFieldValue('type', value?.value);
            }}
            defaultValue={EVENTS_AVAILABLES.find((p) => p.value === eventNewToEdit?.type)}
            fullWidth
            clearIcon={null}
          />
          {errors.type && touched.type && <FormHelperText error>{errors.type}</FormHelperText>}
        </Grid>

        <Grid item container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>Título</InputLabel>
            <TextField
              fullWidth
              error={Boolean(errors.title && touched.title)}
              placeholder="Ingrese el nombre del evento"
              {...getFieldProps('title')}
            />
            {errors.title && touched.title && <FormHelperText error>{errors.title}</FormHelperText>}
          </Grid>
        </Grid>

        <Grid item container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>Descripción</InputLabel>
            <TextField
              fullWidth
              error={Boolean(errors.title && touched.title)}
              placeholder="Ingrese descripción evento"
              multiline
              rows={4}
              {...getFieldProps('description')}
            />
            {errors.description && touched.description && (
              <FormHelperText error>{errors.description}</FormHelperText>
            )}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <InputLabel>Enlace</InputLabel>
          <TextField fullWidth placeholder="Enlace del evento" {...getFieldProps('url_external')} />
          <FormHelperText>(opcional, puede ser un enlace a un sitio web)</FormHelperText>
        </Grid>

        <Grid item xs={12} marginTop={2}>
          <Typography variant="h5" component="div" sx={{ mb: 3 }}>
            2. Establezca el inicio y fin del evento
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
            <DateRangePicker
              startText={null}
              endText={null}
              value={dates}
              onChange={onChangeDates}
              renderInput={(startProps, endProps) => (
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={3} lg={4} sx={{ pt: { xs: 2, sm: '0 !important' } }}>
                    <InputLabel
                      className={classes.inputLabel}
                      sx={{ textAlign: { xs: 'left', sm: 'right' } }}
                    >
                      Fecha de Inicio:
                    </InputLabel>
                  </Grid>
                  <Grid item xs={12} sm={9} lg={8}>
                    <TextField
                      fullWidth
                      {...startProps}
                      helperText=""
                      error={Boolean(errors.start_date && touched.start_date)}
                      {...getFieldProps('start_date')}
                    />
                    <FormHelperText>A partir de esta fecha comenzará el evento</FormHelperText>

                    {errors.start_date && touched.start_date && (
                      <FormHelperText error>{errors.start_date}</FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={3} lg={4} sx={{ pt: { xs: 2, sm: '0 !important' } }}>
                    <InputLabel
                      className={classes.inputLabel}
                      sx={{ textAlign: { xs: 'left', sm: 'right' } }}
                    >
                      Fecha de Fin:
                    </InputLabel>
                  </Grid>

                  <Grid item xs={12} sm={9} lg={8}>
                    <TextField
                      fullWidth
                      {...endProps}
                      helperText=""
                      error={Boolean(errors.end_date && touched.end_date)}
                      {...getFieldProps('end_date')}
                    />
                    <FormHelperText>En esta fecha el evento finalizará</FormHelperText>
                    {errors.end_date && touched.end_date && (
                      <FormHelperText error>{errors.end_date}</FormHelperText>
                    )}
                  </Grid>
                </Grid>
              )}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" component="div" sx={{ mb: 3 }}>
            3. Asignar perfiles al evento.
          </Typography>
          {isLoadingProfiles ? (
            <Skeleton variant="rectangular" sx={{ width: '100%', borderRadius: 3, height: 50 }} />
          ) : (
            <Autocomplete
              multiple
              options={profilesAvailables!}
              loading={isLoadingProfiles}
              loadingText="Cargando perfiles disponibles..."
              getOptionLabel={(option) => option.pro_name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(errors.profiles && touched.profiles)}
                  placeholder="Seleccione los perfiles disponibles"
                />
              )}
              value={profilesAvailables?.filter((profile) =>
                values.profiles.some((d) => d === profile.id)
              )}
              onChange={(_, values) =>
                setFieldValue('profiles', [...values.map((v) => v.id)] || '')
              }
              fullWidth
            />
          )}

          {errors.profiles && touched.profiles && (
            <FormHelperText error>{errors.profiles}</FormHelperText>
          )}
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" component="div" sx={{ mb: 3 }}>
            4. Establezca el estado evento
          </Typography>
          <InputLabel>Estado de evento</InputLabel>
          <Autocomplete
            options={statusAvailables}
            getOptionLabel={(option) => option.name}
            onChange={(_, value) => setFieldValue('statusId', value?.id || '')}
            clearIcon={null}
            defaultValue={statusAvailables[0]}
            noOptionsText="No hay opciones disponibles"
            renderInput={(params) => (
              <TextField {...params} error={Boolean(errors.statusId && touched.statusId)} />
            )}
            fullWidth
          />
          <FormHelperText>Selecciona un estado de actividad</FormHelperText>
          {errors.statusId && touched.statusId && (
            <FormHelperText error>{errors.statusId}</FormHelperText>
          )}
        </Grid>

        <Grid item xs={12}>
          <DragArea
            handleChange={(e) => setFile(e.target.files![0])}
            label="Subir imagen"
            description={`Arrastre y suelte aquí la foto para el evento (Máximo ${defaultConfiguration?.file_size} MB)`}
            typesAccepted="image/*"
          />
        </Grid>

        <Grid
          item
          container
          alignItems="center"
          justifyContent="flex-end"
          spacing={2}
          sx={{ mt: 1 }}
        >
          <Grid item>
            <Button
              variant="outlined"
              size="large"
              color="primary"
              onClick={() => history.goBack()}
            >
              Regresar
            </Button>
          </Grid>
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              disabled={isUpdating || isLoadingF}
              endIcon={(isUpdating || isLoadingF) && <CircularProgress size={20} />}
            >
              Actualizar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default EventNewEdit;
