import { useEffect, useState, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import {
  FormHelperText,
  Grid,
  TextField,
  Typography,
  Autocomplete,
  Button,
  CircularProgress,
  useTheme,
  FormControlLabel,
  Checkbox,
  TextFieldProps,
  IconButton,
  Switch,
  Skeleton,
  Divider,
} from '@material-ui/core';

import { useFormik, FieldArray, Field, FormikProvider } from 'formik';

import { IconCaretLeft, IconCaretRight } from '@tabler/icons';

import { format, parseISO } from 'date-fns';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import DateRangePicker, { DateRange } from '@material-ui/lab/DateRangePicker';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import esLocale from 'date-fns/locale/es';
import InputLabel from '../../../shared/components/forms/InputLabel';
import SimpleTable from './SimpleTable';
import { useGetCoursesQuery } from '../../courses/slices/courseApiSlice';

import { useAppSelector } from '../../../../hooks/useRedux';

import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';

import ConfirmAlert from '../../../shared/components/dialogs/ConfirmAlert';

import {
  useAssignStagesArrayToPeriodQuotaMutation,
  useAssignStagesArrayToPeriodQualificationMutation,
  useGetAvailableStagesQuery,
  useGetSuppliesByPeriodQuery,
  useAssignStagesArrayToPeriodMutation,
  useLazyGetStartDateOnAdminSubjectRegisterQuery,
  useGetPeriodCalificationModelsQuery,
} from '../slices/periodsApiSlice';

import { useGetEducationLevelsParentsVMQuery } from '../../education-levels/slices/educationLevelsApiSlice';

import { useGetPeriodQuery } from '../slices/periodsApiSlice';

import { StagePeriodAssignmentSchema } from '../constants/validation-schemas';

import { useGetAvailablesParallelsQuery } from '../../parallels/slices/parallelsApiSlice';
import TextFieldFetching from '../../../shared/components/TextFieldFetching';
import { navigateTop } from '../../../teachers/virtual-classroom/evaluations/helpers';
import { useTranslation } from 'react-i18next';

interface Dates {
  courseId?: number;
  startDate?: Date;
  endDate?: Date;
}
export interface CalificationModelDates {
  calification_model_id?: number;
  distributions: Distribution[];
}

export interface Distribution {
  ref1_supply_id: string;
  supplies: Supply[];
}

export interface Supply {
  ref2_supply_id: string;
  startDate: Date | null;
  endDate: Date | null;
}
interface FormValues {
  stageAcronym: string;
  quota: number;
  statusId: number;
  periodStages: Dates[];
  eduLvlId: number;
  parallelsId: number;
  suppliesId: number;
  calificationModelId: number;
  calificationModelDates: CalificationModelDates;
}

interface IdFormat {
  index: number;
  courseId?: number;
}

// data's interface to show in the table
interface PeriodStages {
  ids: IdFormat;
  periodStageName: string;
  dates: Dates;
}

// interface, el error que retorna el back cuando la fecha esta fuera del rango es un objeto
interface ErrorObject {
  information: Array<string>;
  warning: string;
}

// Interface de una lista con el historial  se insumos enviados exitosamente
interface SuppliesToShow {
  id: number;
  name: string;
  startDate: string;
  endDate: string;
}

const AssignStagesForm = () => {
  const { t } = useTranslation();

  const theme = useTheme();

  const history = useHistory();

  const { periodId } = useParams<{ periodId: string }>();

  const { currentProfile } = useAppSelector((state) => state.access);

  // array with check ids
  const [checkIds, setCheckIds] = useState<Array<number>>([]);

  const [showAlerts, setShowAlerts] = useState(false);

  // switch table data by status (period-stage <-> Course)
  const [isCourse, setIsCourse] = useState<boolean>(false);

  // fields to show and init query data available
  const [isQuota, setIsQuota] = useState<boolean>();

  const [isStage, setIsStage] = useState<boolean>();

  const [isEduLvl, setIsEduLvl] = useState<boolean>();

  const [isParallels, setIsParallels] = useState<boolean>();

  const [isSupplies, setIsSupplies] = useState<boolean>();

  //start date when subject register by admin is select
  const [startDate, setStartDate] = useState<Date | null>(null);

  // pagination table
  const [valueRows, setValueRows] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);

  // modal status
  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  const handleClose = () => {
    setConfirmModalOpen(false);
  };

  // Mensajes de error fechas incorrectas
  const [dateInvalid, setDateInvalid] = useState<{ index: number; message: string; show: boolean }>(
    { show: false, index: 0, message: '' }
  );

  //Insumos enviados historial
  const [currentsSupplies, setCurrentsSupplies] = useState<SuppliesToShow[]>([]);

  // querys
  const [
    assignStagesToPeriodQuota,
    {
      isLoading: isAssigningQuota,
      isError: isErrorQuota,
      isSuccess: isSuccessQuota,
      error: errorQuota,
    },
  ] = useAssignStagesArrayToPeriodQuotaMutation();

  const [
    assignStagesToPeriodDefault,
    {
      isLoading: isAssigningDefault,
      isError: isErrorDefault,
      isSuccess: isSuccessDefault,
      error: errorDefault,
    },
  ] = useAssignStagesArrayToPeriodMutation();

  const [
    assignStagesToPeriodQualification,
    {
      isLoading: isAssigningQualification,
      isError: isErrorQualification,
      isSuccess: isSuccessQualification,
      error: errorQualification,
    },
  ] = useAssignStagesArrayToPeriodQualificationMutation();

  const {
    data: stagesAvailables = [],
    isLoading: isLoadingStagesAvailables,
    isFetching: isFetchingStagesAvailables,
  } = useGetAvailableStagesQuery({
    profileId: currentProfile?.profileId!,
  });

  const { data: periodInfo, isLoading: isLoadingPeriodInfo } = useGetPeriodQuery({
    profileId: currentProfile?.profileId!,
    periodId: Number(periodId),
  });

  const { data: parallelsAvailables = [], isLoading: isLoadingParallels } =
    useGetAvailablesParallelsQuery(
      {
        profileId: currentProfile?.profileId!,
      },
      { skip: !isParallels }
    );

  const { data: coursesAvailables } = useGetCoursesQuery(
    {
      periodId: Number(periodId),
      profileId: currentProfile?.profileId!,
    },
    { skip: !isCourse }
  );

  const { data: suppliesAvailables } = useGetSuppliesByPeriodQuery(
    {
      periodId: Number(periodId),
      profileId: currentProfile?.profileId!,
    },
    { skip: !isSupplies }
  );

  const { data: eduLvlParentsAvailables = [], isLoading: isLoadingEduLvlParents } =
    useGetEducationLevelsParentsVMQuery(
      {
        profileId: currentProfile?.profileId!,
        offerId: periodInfo?.offers?.[0]?.id || 0,
      },
      { skip: !isEduLvl }
    );

  const { data: calificationModels, isLoading: isLoadingCalificationModels } =
    useGetPeriodCalificationModelsQuery(
      {
        profileId: currentProfile?.profileId!,
        periodId: Number(periodId),
      },
      { skip: !isSupplies }
    );

  // funcion que crea un array y asigna un N numeros de items
  const arrayPeriodStagesDates = useCallback((quotas: number) => {
    const initialDate: Dates = {
      startDate: undefined,
      endDate: undefined,
    };
    let initialDates: Dates[] = [];
    for (var i = 0; i < quotas; i++) {
      initialDates.push(initialDate);
    }

    return initialDates;
  }, []);

  // Stages types
  const INGRESO_CALIFICACIONES = 'B';
  const GENERACION_CUOTAS = 'RC';

  const formik = useFormik<FormValues>({
    initialValues: {
      stageAcronym: '',
      periodStages: arrayPeriodStagesDates(Number(periodInfo?.per_num_fees)),
      quota: 0,
      statusId: -1,
      eduLvlId: 0,
      parallelsId: 0,
      suppliesId: 0,
      calificationModelId: 0,
      calificationModelDates: {} as CalificationModelDates,
    },
    validationSchema: StagePeriodAssignmentSchema,
    validate: () => {
      return checkDatesAvailables();
    },
    onSubmit: async (values) => {
      if (isQuota) {
        // Registro y pago de cuotas
        await assignStagesToPeriodQuota({
          profileId: currentProfile?.profileId!,
          stagePeriodAssignmentPayload: {
            period_id: Number(periodId),
            stg_acronym: stagesAvailables.find((e) => e.stg_acronym === values.stageAcronym)
              ?.stg_acronym,
            parallel_id: '',
            dates: values.periodStages.map((valuesArray) => ({
              course_id: valuesArray?.courseId || null,
              start_date: format(valuesArray.startDate!, 'yyyy-MM-dd'),
              end_date: format(valuesArray.endDate!, 'yyyy-MM-dd'),
            })),
            // dates: !isCourse
            //   ? values.periodStages.map((valuesArray) => ({
            //     course_id: null,
            //     start_date: format(valuesArray.startDate!, 'yyyy-MM-dd'),
            //     end_date: format(valuesArray.endDate!, 'yyyy-MM-dd'),
            //   }))
            //   : values.periodStages
            //     .filter((e) => !!e.courseId)
            //     .map((valuesArray) => ({
            //       course_id: valuesArray?.courseId || null,
            //       start_date: format(valuesArray.startDate!, 'yyyy-MM-dd'),
            //       end_date: format(valuesArray.endDate!, 'yyyy-MM-dd'),
            //     })),

            //Posgrado
            //...(isParallels && { parallel_id: values.parallelsId || null }),
            ...(isQuota && { number_fees: values.quota.toString() || '' }),
            ...(isEduLvl && { education_level_id: values.eduLvlId || -1 }),
          },
        });
      } else if (values.stageAcronym === INGRESO_CALIFICACIONES) {
        // Apertura de calificaciones
        await assignStagesToPeriodQualification({
          profileId: currentProfile?.profileId!,
          stagePeriodAssignmentPayload: {
            period_id: Number(periodId),
            stg_acronym:
              stagesAvailables.find((e) => e.stg_acronym === values.stageAcronym)?.stg_acronym ||
              '-1',

            // dates: !isCourse
            //   ? values.periodStages.map((valuesArray) => ({
            //       course_id: null,
            //       start_date: format(valuesArray.startDate!, 'yyyy-MM-dd'),
            //       end_date: format(valuesArray.endDate!, 'yyyy-MM-dd'),
            //     }))
            //   : values.periodStages
            //       .filter((e) => !!e.courseId)
            //       .map((valuesArray) => ({
            //         course_id: valuesArray?.courseId || null,
            //         start_date: format(valuesArray.startDate!, 'yyyy-MM-dd'),
            //         end_date: format(valuesArray.endDate!, 'yyyy-MM-dd'),
            //       })),

            // ...(isSupplies && { supply_id: values.suppliesId }),

            //Posgrado
            ...(isParallels && { parallel_id: values.parallelsId }),
            ...(isEduLvl && { education_level_id: values.eduLvlId }),
            dates: !isCourse
              ? (values.calificationModelDates?.distributions?.reduce<any[]>(
                  (acc, curr) => [
                    ...acc,
                    ...curr.supplies?.map((s) => ({
                      supply_id: s.ref2_supply_id,
                      start_date: format(s.startDate!, 'yyyy-MM-dd'),
                      end_date: format(s.endDate!, 'yyyy-MM-dd'),
                    })),
                  ],
                  []
                ) as unknown as any[])
              : (values.periodStages
                  .filter((e) => !!e.courseId)
                  .map((valuesArray) => ({
                    course_id: valuesArray?.courseId || null,
                    start_date: format(valuesArray.startDate!, 'yyyy-MM-dd'),
                    end_date: format(valuesArray.endDate!, 'yyyy-MM-dd'),
                  })) as unknown as any[]),
          },
        }).then((e) => console.log(isSuccessQualification));
      } else {
        // console.log('deafult');
        // console.log(isCourse);
        // console.log(values);
        await assignStagesToPeriodDefault({
          profileId: currentProfile?.profileId!,
          stagePeriodAssignmentPayload: {
            period_id: Number(periodId),
            stg_acronym:
              stagesAvailables.find((e) => e.stg_acronym === values.stageAcronym)?.stg_acronym ||
              '-1',

            dates: !isCourse
              ? values.periodStages.map((valuesArray) => ({
                  course_id: null,
                  start_date: format(valuesArray.startDate!, 'yyyy-MM-dd'),
                  end_date: format(valuesArray.endDate!, 'yyyy-MM-dd'),
                }))
              : values.periodStages
                  .filter((e) => !!e.courseId)
                  .map((valuesArray) => ({
                    course_id: valuesArray?.courseId || null,
                    start_date: format(valuesArray.startDate!, 'yyyy-MM-dd'),
                    end_date: format(valuesArray.endDate!, 'yyyy-MM-dd'),
                  })),

            ...(isSupplies && { supply_id: values.suppliesId }),

            //Posgrado
            ...(isParallels && { parallel_id: values.parallelsId }),
            ...(isEduLvl && { education_level_id: values.eduLvlId }),
          },
        });
      }

      setShowAlerts(true);
      navigateTop();
    },
  });

  useEffect(() => {
    if (!isSuccessQualification) return;

    if (!currentsSupplies?.some((e) => e.id === values?.suppliesId!)) {
      setCurrentsSupplies(
        currentsSupplies.concat({
          id: values?.suppliesId!,
          name:
            suppliesAvailables?.find((e) => e.supply_id === values?.suppliesId!)?.Rename || 'N/A',
          startDate: values?.periodStages?.[0]?.startDate
            ? format(values?.periodStages?.[0]?.startDate, 'yyyy-MM-dd')
            : '',
          endDate: values?.periodStages?.[0]?.endDate
            ? format(values?.periodStages?.[0]?.endDate, 'yyyy-MM-dd')
            : '',
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessQualification]);

  const { getFieldProps, setFieldValue, errors, touched, handleSubmit, values, submitForm } =
    formik;

  const [getStartDateOnAdminSubjectRegister, { isLoading: isLoadingSDT }] =
    useLazyGetStartDateOnAdminSubjectRegisterQuery();

  const setStartDateHandler = (id: number, acronym: string) => {
    getStartDateOnAdminSubjectRegister({ periodId: id, acronym })
      .unwrap()
      .then((response) => {
        setStartDate(parseISO(response.start_date));
      });
  };

  // confirm create stage from modal then OnSubmit
  const handleConfirm = () => {
    setConfirmModalOpen(false);
    submitForm();
  };

  const GenerationQuote = () => {
    return (
      <Grid item container xs={12} spacing={5} alignItems="flex-start">
        <Grid item container md={8} mt={2}>
          <Grid item xs={12}>
            <Typography variant="h5" sx={{ my: 3 }}>
              {'Complete fechas de cuotas'}
            </Typography>
          </Grid>
          <Grid
            p={2}
            sx={{
              border: `1px solid ${theme.palette.primary.main}`,
              borderRadius: '10px',
            }}
            item
            xs={12}
          >
            {arrayPeriodStagesDates(values.quota).map((e, index) => (
              <>
                <FormikProvider value={formik}>
                  <FieldArray
                    name="periodStages"
                    render={(arrayHelpers) => (
                      <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                        <Field
                          name={`periodStages[${index}]`}
                          component={DateRangePicker}
                          startText={null}
                          endText={null}
                          value={[
                            values?.periodStages[index]?.startDate || null,
                            values?.periodStages[index]?.endDate || null,
                          ]}
                          onChange={(e: DateRange<Date>) => {
                            onChangeDatesArray(e, index, null);
                          }}
                          renderInput={(startProps: TextFieldProps, endProps: TextFieldProps) => (
                            <Grid container direction="row">
                              {dateInvalid.show && dateInvalid.index === index && (
                                <Grid
                                  container
                                  spacing={2}
                                  sx={{ mt: 2 }}
                                  justifyContent="left"
                                  alignItems="center"
                                >
                                  <Grid item xs={12} md={12} lg={12}>
                                    <ErrorAlert
                                      message={[`Error en cuota ${index + 1}`, dateInvalid.message]}
                                      handleDismiss={() =>
                                        setDateInvalid({ index: 0, message: '', show: false })
                                      }
                                    />
                                  </Grid>
                                </Grid>
                              )}

                              <Grid
                                container
                                spacing={2}
                                sx={{ mt: 2 }}
                                justifyContent="left"
                                alignItems="center"
                              >
                                <Grid item xs={12} md={6} lg={4}>
                                  <InputLabel>
                                    {t('academic.periods_list.stages.add.fees_dates.placeholder')} #{' '}
                                    <strong>{index + 1}</strong>
                                  </InputLabel>
                                </Grid>
                              </Grid>
                              <Grid
                                container
                                spacing={2}
                                sx={{ mt: 2, mb: 2 }}
                                justifyContent="left"
                                alignItems="center"
                              >
                                <Grid item xs={12} md={6} lg={4}>
                                  <InputLabel>
                                    {t('academic.periods_list.stages.add.fees_dates.end.helper')}:
                                  </InputLabel>
                                  <TextField fullWidth {...startProps} helperText="" />
                                  <FormHelperText>
                                    {t('academic.periods_list.stages.add.fees_dates.start.helper')}
                                  </FormHelperText>
                                  <FormHelperText error>
                                    {t(
                                      (errors?.periodStages?.[index] as unknown as any)?.startDate
                                    )}
                                  </FormHelperText>
                                  {/* <ErrorMessage name={`periodStages[${index}].startDate`} /> */}
                                </Grid>

                                <Grid item xs={12} md={6} lg={4}>
                                  <InputLabel>
                                    {t('academic.periods_list.stages.add.fees_dates.end')}:
                                  </InputLabel>

                                  <TextField fullWidth {...endProps} helperText="" />
                                  <FormHelperText>
                                    {t('academic.periods_list.stages.add.fees_dates.end.helper')}
                                  </FormHelperText>
                                  <FormHelperText error>
                                    {t((errors?.periodStages?.[index] as unknown as any)?.endDate)}
                                  </FormHelperText>
                                  {/* <ErrorMessage name={`periodStages[${index}].endDate`} /> */}
                                </Grid>
                              </Grid>
                            </Grid>
                          )}
                        />
                      </LocalizationProvider>
                    )}
                  />
                </FormikProvider>
                <Divider />
              </>
            ))}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  /* Validar que las fechas seleccionadas sean correctas.
   * 1) Fecha inicio
   * Fecha inicio no puede superar fecha inicio del periodo.
   * Fecha inicio no puede ser menor fecha inicio del periodo.
   * 2) Fecha fin
   * Fecha fin no puede superar fecha de fin del periodo.
   *
   */
  const checkDatesAvailables = () => {
    const errors = {};

    const periodStages = values.periodStages;
    const startPeriod = parseISO(periodInfo?.start_process!);
    const endPeriod = parseISO(periodInfo?.end_process!);
    for (let i = 0; i < periodStages.length; i++) {
      const start = periodStages[i]?.startDate!;
      const end = periodStages[i]?.endDate!;
      if (start > endPeriod) {
        setDateInvalid({
          show: true,
          index: i,
          message: `${t('academic.periods_list.stages.add.date.start.max')} ${
            periodInfo?.end_process
          }`,
        });
        return false;
      }
      if (start < startPeriod) {
        setDateInvalid({
          show: true,
          index: i,
          message: `${t('academic.periods_list.stages.add.date.start.min')} ${
            periodInfo?.start_process
          }`,
        });
        return false;
      }
      if (end > endPeriod) {
        setDateInvalid({
          show: true,
          index: i,
          message: `${t('academic.periods_list.stages.add.date.end.max')} ${
            periodInfo?.end_process
          }`,
        });
        return false;
      }
    }

    return errors;
  };

  // set data in fields from array formik
  const onChangeDatesArray = (
    newDatesValues: DateRange<Date>,
    index: number,
    courseId?: number | null
  ) => {
    const [start, end] = newDatesValues;
    // update formik values

    setFieldValue(`periodStages[${index}].startDate`, start);
    setFieldValue(`periodStages[${index}].endDate`, end);

    //validate id  exist in checks IDs, if exist set id
    // if (checkIds.some((e) => e === index)) {
    setFieldValue(`periodStages[${index}].courseId`, courseId);
    // } else {
    //   setFieldValue(`periodStages[${index}].courseId`, null);
    // }
  };

  // Arreglo que se mostrara en la tabla
  const [arrayTest, setArrayTest] = useState<PeriodStages[]>([
    {
      ids: { index: 0 },
      periodStageName: t('academic.periods_list.stages.add.period_stage'),
      dates: { endDate: undefined, startDate: undefined },
    },
  ]);

  // Switch data (period-stage or course) in table
  useEffect(() => {
    if (!isLoadingPeriodInfo) {
      if (!isCourse) {
        setArrayTest(
          arrayPeriodStagesDates(isQuota ? values.quota : 1).map((e, index) => {
            return {
              ids: { index: index },
              periodStageName: isSupplies
                ? suppliesAvailables?.find((e) => e.supply_id === values.suppliesId)?.Rename ||
                  t('academic.periods_list.stages.add.supplies.helper')
                : t('academic.periods_list.stages.add.period_stage'),
              dates: { endDate: undefined, startDate: undefined },
            };
          })
        );
      } else {
        if (coursesAvailables) {
          setArrayTest(
            coursesAvailables!.map((e, index) => ({
              ids: { index: index, courseId: e.id },
              periodStageName: e.matter.mat_name,
              dates: { endDate: undefined, startDate: undefined },
            }))
          );
        }
      }
    }
  }, [
    values.quota,
    arrayPeriodStagesDates,
    isCourse,
    coursesAvailables,
    isLoadingPeriodInfo,
    periodInfo?.per_num_fees,
    isQuota,
    isSupplies,
    values.suppliesId,
    suppliesAvailables,
    t,
    // values.stageAcronym,
  ]);

  const defaultStateFields = () => {
    setIsQuota(false);
    setIsStage(true);
    setIsSupplies(false);
    setIsEduLvl(false);
    setIsParallels(false);
    setIsCourse(false);
  };

  const offerId = periodInfo?.offers?.[0]?.id;

  // Si el valor de la cuota es 0 (el valor inicial es 0) este cambiara a el numero de cuotas que tiene asignado el periodo
  useEffect(() => {
    if (isQuota && !isLoadingPeriodInfo && values.quota === 0) {
      setFieldValue('quota', Number(periodInfo?.per_num_fees));
    }
  }, [isLoadingPeriodInfo, values.quota, periodInfo, setFieldValue, isQuota]);

  // Asignar actual insumo enviado exitosamente al array de insumos
  // useEffect(() => {
  //   if (!isErrorQualification) {
  //     setCurrentsSupplies(currentsSupplies?.concat(currentSupply!));
  //   }
  // }, [currentSupply, currentsSupplies, isErrorQualification]);

  useEffect(() => {
    if (errorDefault) {
      console.log(errorDefault, 'este es un error por default objecto array');
      console.log((errorDefault as unknown as ErrorObject[])[0].warning);
    }
  }, [errorDefault]);

  useEffect(() => {
    switch (values.stageAcronym) {
      case INGRESO_CALIFICACIONES: // Apertura de calificaciones
        if (offerId === 2 || offerId === 1) {
          // Ofertas de  Grado y nivelacion
          setIsQuota(false);
          setIsStage(true);
          setIsSupplies(true);
          setIsEduLvl(false);
          setIsParallels(false);
          setIsCourse(false);
          setFieldValue('periodStages', []);
        } else if (offerId === 3 || offerId === 4) {
          // Ofertas de posgrado y educacion continua
          setIsQuota(false);
          setIsStage(true);
          setIsSupplies(false);
          setIsEduLvl(true);
          setIsParallels(true);
          setIsCourse(true);
          setFieldValue(
            'periodStages',
            Array(arrayTest.length).fill({
              courseId: null,
              startDate: null,
              endDate: null,
            })
          );
        }
        setFieldValue('periodStages', []);
        break;
      case GENERACION_CUOTAS: // Pago de Cuotas
        if (offerId === 2 || offerId === 1) {
          //Ofertas de Grado y nivelacion
          setIsQuota(true); // debe ser false
          setIsStage(true);
          setIsSupplies(false);
          setIsEduLvl(false);
          setIsParallels(false);
          setIsCourse(false);
        } else if (offerId === 3 || offerId === 4) {
          //Ofertas de posgrado y educacion continua
          setIsQuota(true);
          setIsStage(true);
          setIsSupplies(false);
          setIsEduLvl(true);
          setIsParallels(true);
          setIsCourse(true);
        }
        setFieldValue('periodStages', arrayPeriodStagesDates(values.quota || 1));
        break;
      default:
        setFieldValue('periodStages', arrayPeriodStagesDates(1));
        defaultStateFields();
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.stageAcronym, offerId]);

  useEffect(() => {
    if (
      values.stageAcronym === INGRESO_CALIFICACIONES &&
      isCourse &&
      values.periodStages.length !== arrayTest.length
    ) {
      setFieldValue(
        'periodStages',
        Array(arrayTest.length).fill({
          courseId: null,
          startDate: null,
          endDate: null,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arrayTest, values.stageAcronym, isCourse]);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid container direction="column" gap={2}>
          <Grid item>
            {(isErrorQuota || isErrorQualification || isErrorDefault) && showAlerts && (
              <ErrorAlert
                message={
                  //Qualification
                  (errorQualification &&
                    (errorQualification as unknown as ErrorObject[])[0]!.warning!) ||
                  (errorQualification! as any) ||
                  // quota
                  (errorQuota && (errorQuota as unknown as ErrorObject[])[0]!.warning!) ||
                  (errorQuota! as any) ||
                  //default
                  (errorDefault && (errorDefault as unknown as ErrorObject[])[0]!.warning!) ||
                  (errorDefault as any)
                }
                handleDismiss={() => setShowAlerts(false)}
              />
            )}

            {(isSuccessQuota || isSuccessQualification || isSuccessDefault) && showAlerts && (
              <SuccessAlert
                message={t('academic.periods_list.stages.add.success')}
                handleDismiss={() => setShowAlerts(false)}
              />
            )}
          </Grid>
          <Grid container spacing={5} alignItems="flex-start">
            <Grid item container spacing={1} xs={12} md={12}>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Typography variant="h4">
                  {t('academic.periods_list.stages.add.period')}:
                </Typography>
                <Typography variant="h3">
                  {!isLoadingPeriodInfo ? periodInfo?.per_name : <Skeleton />}{' '}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <Typography variant="h4">{t('academic.periods_list.stages.add.start')}:</Typography>
                <Typography variant="h3">
                  {!isLoadingPeriodInfo ? periodInfo?.start_process : <Skeleton />}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <Typography variant="h4">{t('academic.periods_list.stages.add.end')}:</Typography>
                <Typography variant="h3">
                  {!isLoadingPeriodInfo ? periodInfo?.end_process : <Skeleton />}
                </Typography>
              </Grid>
            </Grid>
            <Divider />
          </Grid>
          <Grid container spacing={5} alignItems="flex-start">
            <Grid item container spacing={5} xs={12} md={12}>
              <>
                {isStage && !isFetchingStagesAvailables && (
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <InputLabel>{t('academic.periods_list.stages.add.stages')}:</InputLabel>
                    <Autocomplete
                      options={stagesAvailables.filter(
                        (stage) => stage.stg_acronym !== INGRESO_CALIFICACIONES
                      )}
                      loading={isLoadingStagesAvailables}
                      loadingText={t('academic.periods_list.stages.add.loading')}
                      getOptionLabel={(option) => option.stg_name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={Boolean(errors.stageAcronym && touched.stageAcronym)}
                        />
                      )}
                      onChange={(_, value) => {
                        setFieldValue('stageAcronym', value?.stg_acronym || '');

                        if (value?.stg_acronym !== INGRESO_CALIFICACIONES) {
                          setFieldValue('calificationModelDates', {});
                        }

                        if (value?.stg_acronym === 'M') {
                          //trigger del get de la fecha de inicio
                          setStartDateHandler(Number(periodId), value?.stg_acronym);
                        }
                      }}
                      fullWidth
                      clearIcon={null}
                      noOptionsText={t('academic.periods_list.stages.add.data')}
                    />
                    <FormHelperText>
                      {t('academic.periods_list.stages.add.stages.helper')}
                    </FormHelperText>
                    {isFetchingStagesAvailables ? <TextFieldFetching /> : null}
                    {errors.stageAcronym && touched.stageAcronym && (
                      <FormHelperText error>{t(errors.stageAcronym)}</FormHelperText>
                    )}
                  </Grid>
                )}
                {isQuota && (
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <InputLabel>{t('academic.periods_list.stages.add.fees')}:</InputLabel>

                    {isLoadingPeriodInfo ? (
                      <TextField
                        type="number"
                        InputProps={{ inputProps: { min: 1, max: 20 } }}
                        fullWidth
                        disabled
                        placeholder={t('academic.periods_list.stages.add.fees.loading')}
                      />
                    ) : (
                      <>
                        <TextField
                          type="number"
                          InputProps={{ inputProps: { min: 1, max: 20 } }}
                          fullWidth
                          disabled={
                            !(offerId === 3 || offerId === 4) ||
                            Number(periodInfo?.per_num_fees) !== 0
                          }
                          // posgrado y educacion continua, solo ellos pueden cambiar el valor de este campo
                          error={Boolean(errors.quota && touched.quota)}
                          placeholder={t('academic.periods_list.stages.add.fees.placeholder')}
                          {...getFieldProps('quota')}
                        />
                        <FormHelperText>
                          {t('academic.periods_list.stages.add.fees.helper')}
                        </FormHelperText>
                        {errors.quota && touched.quota && (
                          <FormHelperText error>{t(errors.quota)}</FormHelperText>
                        )}
                      </>
                    )}
                  </Grid>
                )}

                {isLoadingPeriodInfo ||
                  (isEduLvl && (
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <InputLabel>{t('academic.periods_list.stages.add.program')}</InputLabel>
                      <Autocomplete
                        options={eduLvlParentsAvailables}
                        loading={isLoadingEduLvlParents}
                        loadingText={t('academic.periods_list.stages.add.program.loading')}
                        getOptionLabel={(option) => option.edu_name}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={Boolean(errors.eduLvlId && touched.eduLvlId)}
                          />
                        )}
                        onChange={(_, value) => setFieldValue('eduLvlId', value?.id || '')}
                        fullWidth
                        clearIcon={null}
                        noOptionsText={t('academic.periods_list.stages.add.program.no_data')}
                      />
                      <FormHelperText>
                        {t('academic.periods_list.stages.add.program.helper')}
                      </FormHelperText>
                      {errors.eduLvlId && touched.eduLvlId && (
                        <FormHelperText error>{t(errors.eduLvlId)}</FormHelperText>
                      )}
                    </Grid>
                  ))}

                {isLoadingPeriodInfo ||
                  (isParallels && (
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <InputLabel>{t('academic.periods_list.stages.add.parallels')}</InputLabel>
                      <Autocomplete
                        options={parallelsAvailables}
                        loading={isLoadingParallels}
                        loadingText={t('academic.periods_list.stages.add.parallels.loading')}
                        getOptionLabel={(option) => option.par_name}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={Boolean(errors.parallelsId && touched.parallelsId)}
                          />
                        )}
                        onChange={(_, value) => setFieldValue('parallelsId', value?.id || '')}
                        fullWidth
                        clearIcon={null}
                        noOptionsText={t('academic.periods_list.stages.add.parallels.helper')}
                      />
                      <FormHelperText>
                        {t('academic.periods_list.stages.add.parallels.helper')}
                      </FormHelperText>
                      {errors.parallelsId && touched.parallelsId && (
                        <FormHelperText error>{t(errors.parallelsId)}</FormHelperText>
                      )}
                    </Grid>
                  ))}

                {isLoadingPeriodInfo ||
                  (isSupplies && (
                    // <Grid item xs={12} sm={6} md={4} lg={3}>
                    //   <InputLabel>{t('academic.periods_list.stages.add.supplies')}:</InputLabel>
                    //   <Autocomplete
                    //     options={suppliesAvailables || []}
                    //     loading={isLoadingSupplies}
                    //     loadingText={t('academic.periods_list.stages.add.supplies.loading')}
                    //     getOptionLabel={(option) => option.Rename || 'N/A'}
                    //     getOptionDisabled={(option) =>
                    //       currentsSupplies.some((e) => e.id === option.supply_id)
                    //     }
                    //     renderInput={(params) => (
                    //       <TextField
                    //         {...params}
                    //         error={Boolean(errors.suppliesId && touched.suppliesId)}
                    //       />
                    //     )}
                    //     onChange={(_, value) => setFieldValue('suppliesId', value?.supply_id || '')}
                    //     fullWidth
                    //     clearIcon={null}
                    //     noOptionsText={t('academic.periods_list.stages.add.supplies.no_data')}
                    //   />
                    //   <FormHelperText>
                    //     {t('academic.periods_list.stages.add.supplies.helper')}
                    //   </FormHelperText>
                    //   {errors.suppliesId && touched.suppliesId && (
                    //     <FormHelperText error>{t(errors.suppliesId)}</FormHelperText>
                    //   )}
                    // </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <InputLabel>
                        {t('academic.periods_list.stages.add.calification_model')}:
                      </InputLabel>
                      <Autocomplete
                        options={calificationModels || []}
                        loading={isLoadingCalificationModels}
                        loadingText={t(
                          'academic.periods_list.stages.add.calification_model.loading'
                        )}
                        getOptionLabel={(option) => option.cal_mod_name || 'N/A'}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={Boolean(
                              errors.calificationModelId && touched.calificationModelId
                            )}
                          />
                        )}
                        onChange={(_, value) => {
                          setFieldValue('calificationModelId', value?.id || '');

                          if (value?.id) {
                            setFieldValue('calificationModelDates', {
                              calification_model_id: value?.id,
                              distributions: value?.distribution?.map((e) => ({
                                ref1_supply_id: e.ref1_supply_id,
                                supplies: e.ref2?.map((s) => ({
                                  ref2_supply_id: s.ref2_supply_id,
                                })),
                              })),
                            });
                          }
                        }}
                        fullWidth
                        clearIcon={null}
                        noOptionsText={t(
                          'academic.periods_list.stages.add.calification_model.no_data'
                        )}
                      />
                      <FormHelperText>
                        {t('academic.periods_list.stages.add.calification_model.helper')}
                      </FormHelperText>
                      {errors.calificationModelId && touched.calificationModelId && (
                        <FormHelperText error>{t(errors.calificationModelId)}</FormHelperText>
                      )}
                    </Grid>
                  ))}
              </>
            </Grid>

            {/* Assign dates quote */}
            {values.stageAcronym === GENERACION_CUOTAS && <GenerationQuote />}

            {/* Select range dates */}
            {values.stageAcronym !== GENERACION_CUOTAS &&
              values.stageAcronym !== INGRESO_CALIFICACIONES &&
              values.stageAcronym !== '' && (
                <Grid item container xs={12} spacing={5} alignItems="flex-start">
                  <Grid item container md={8} mt={2}>
                    <Grid item xs={12}>
                      <Typography variant="h5" sx={{ my: 3 }}>
                        {t('academic.periods_list.stages.add.stages_dates')}
                      </Typography>
                    </Grid>
                    <Grid
                      p={2}
                      sx={{
                        border: `1px solid ${theme.palette.primary.main}`,
                        borderRadius: '10px',
                      }}
                      item
                      xs={12}
                    >
                      {arrayPeriodStagesDates(1).map((e, index) => (
                        <FormikProvider value={formik}>
                          <FieldArray
                            name="periodStages"
                            render={(arrayHelpers) => (
                              <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                                <Field
                                  name={`periodStages[${index}]`}
                                  component={DateRangePicker}
                                  maxDate={() =>
                                    periodInfo?.end_process
                                      ? parseISO(periodInfo?.end_process)
                                      : null
                                  }
                                  minDate={() =>
                                    //startDate
                                    values?.stageAcronym === 'M'
                                      ? startDate || null
                                      : periodInfo?.start_process
                                      ? parseISO(periodInfo?.start_process)
                                      : null
                                  }
                                  startText={null}
                                  endText={null}
                                  // disab
                                  value={[
                                    values?.periodStages[index]?.startDate || null,
                                    values?.periodStages[index]?.endDate || null,
                                  ]}
                                  onChange={(e: DateRange<Date>) => {
                                    onChangeDatesArray(e, index, null);
                                  }}
                                  renderInput={(
                                    startProps: TextFieldProps,
                                    endProps: TextFieldProps
                                  ) => (
                                    <Grid container direction="row">
                                      {dateInvalid.show && dateInvalid.index === index && (
                                        <Grid
                                          container
                                          spacing={2}
                                          sx={{ mt: 2 }}
                                          justifyContent="left"
                                          alignItems="center"
                                        >
                                          <Grid item xs={12} md={12} lg={12}>
                                            <ErrorAlert
                                              message={dateInvalid.message}
                                              handleDismiss={() =>
                                                setDateInvalid({
                                                  index: 0,
                                                  message: '',
                                                  show: false,
                                                })
                                              }
                                            />
                                          </Grid>
                                        </Grid>
                                      )}

                                      <Grid
                                        container
                                        spacing={2}
                                        sx={{ mt: 2 }}
                                        justifyContent="left"
                                        alignItems="center"
                                      >
                                        <Grid item xs={12} md={6} lg={4}>
                                          <InputLabel>
                                            {t(
                                              'academic.periods_list.stages.add.stages_dates.placeholder'
                                            )}
                                          </InputLabel>
                                        </Grid>
                                      </Grid>
                                      <Grid
                                        container
                                        spacing={2}
                                        sx={{ mt: 2, mb: 2 }}
                                        justifyContent="left"
                                        alignItems="center"
                                      >
                                        <Grid item xs={12} md={6} lg={4}>
                                          <InputLabel>
                                            {t(
                                              'academic.periods_list.stages.add.stages_dates.start'
                                            )}
                                            :
                                          </InputLabel>
                                          <TextField
                                            fullWidth
                                            {...startProps}
                                            helperText=""
                                            {...getFieldProps(`periodStages[${index}].startDate`)}
                                          />
                                          <FormHelperText>
                                            {t(
                                              'academic.periods_list.stages.add.stages_dates.start.helper'
                                            )}
                                          </FormHelperText>
                                          <FormHelperText error>
                                            {t(
                                              (errors?.periodStages?.[index] as unknown as any)
                                                ?.startDate
                                            )}
                                          </FormHelperText>
                                          {/* <ErrorMessage name={`periodStages[${index}].startDate`} /> */}
                                        </Grid>

                                        <Grid item xs={12} md={6} lg={4}>
                                          <InputLabel>
                                            {t('academic.periods_list.stages.add.stages_dates.end')}
                                            :
                                          </InputLabel>

                                          <TextField
                                            fullWidth
                                            {...endProps}
                                            helperText=""
                                            {...getFieldProps(`periodStages[${index}].endDate`)}
                                          />
                                          <FormHelperText>
                                            {t(
                                              'academic.periods_list.stages.add.stages_dates.end.helper'
                                            )}
                                          </FormHelperText>
                                          <FormHelperText error>
                                            {t(
                                              (errors?.periodStages?.[index] as unknown as any)
                                                ?.endDate
                                            )}
                                          </FormHelperText>
                                          {/* <ErrorMessage name={`periodStages[${index}].endDate`} /> */}
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  )}
                                />
                              </LocalizationProvider>
                            )}
                          />
                        </FormikProvider>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              )}

            {/* Period Stages Table */}
            {values.stageAcronym === INGRESO_CALIFICACIONES ? (
              <Grid item container xs={12} spacing={5} alignItems="flex-start">
                <Grid item container md={8} mt={2}>
                  <Grid item xs={12}>
                    <Typography variant="h5" sx={{ my: 3 }}>
                      {isCourse
                        ? t('academic.periods_list.stages.add.grades.courses')
                        : // : isSupplies
                          // ? `${t('academic.periods_list.stages.add.grades.supplies')} ${
                          //     currentsSupplies.length + 1
                          //   } ${t('academic.periods_list.stages.add.grades.supplies.of')} ${
                          //     suppliesAvailables?.length || '0'
                          //   } ${t('academic.periods_list.stages.add.grades.supplies.date')}`
                          t('academic.periods_list.stages.add.grades.dates')}
                      :
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      disabled
                      control={
                        <Switch
                          checked={isCourse}
                          onChange={(e) => setIsCourse(e.target.checked)}
                          name="gilad"
                        />
                      }
                      label={t('academic.periods_list.stages.add.grades.courses.assign')}
                    />
                  </Grid>

                  {!isLoadingPeriodInfo && isSupplies && (
                    <>
                      {values.calificationModelId !== 0 &&
                        calificationModels
                          ?.find(
                            (calificationModel) =>
                              calificationModel.id === values.calificationModelId
                          )
                          ?.distribution?.map((distribution, distrIndex) => (
                            <Grid
                              item
                              xs={12}
                              sx={{
                                border: `1px solid ${theme.palette.primary.main}`,
                                borderRadius: '10px',
                                mb: 2,
                              }}
                              p={2}
                            >
                              <Typography variant="h4">{distribution?.distribution}</Typography>
                              <SimpleTable
                                title=""
                                data={
                                  distribution?.ref2?.map((ref2, index: number) => ({
                                    ids: {
                                      id: ref2?.id,
                                      index: index + 1,
                                      ref2_supply_id: ref2?.ref2_supply_id,
                                    },
                                    supplyName: ref2?.ref2,
                                    dates: '',
                                  })) || []
                                }
                                headers={{
                                  ids: (
                                    <>
                                      {isCourse ? (
                                        <FormControlLabel
                                          label={`${checkIds.length} ${t(
                                            'academic.periods_list.stages.add.grades.course'
                                          )}${checkIds.length !== 1 ? 's' : ''}`}
                                          control={
                                            <Checkbox
                                              checked={arrayTest.length === checkIds.length}
                                              indeterminate={
                                                checkIds.length > 0 &&
                                                checkIds.length < arrayTest.length
                                              }
                                              onChange={() =>
                                                arrayTest.length === checkIds.length
                                                  ? setCheckIds([])
                                                  : setCheckIds(arrayTest.map((e) => e.ids.index))
                                              }
                                            />
                                          }
                                        />
                                      ) : (
                                        <>#</>
                                      )}
                                    </>
                                  ),
                                  periodStageName: isCourse
                                    ? t('academic.periods_list.stages.add.grades.course')
                                    : isSupplies
                                    ? t('academic.periods_list.stages.add.supply')
                                    : t('academic.periods_list.stages.add.period_stage'),
                                  dates: t('academic.periods_list.stages.add.date'),
                                }}
                                keyExtractor={(e) => e.ids.index.toString()}
                                customRenderers={{
                                  ids: ({ ids }) => (
                                    <>
                                      {isCourse ? (
                                        <Grid container justifyContent="center" alignItems="center">
                                          <FormControlLabel
                                            label=""
                                            control={
                                              <Checkbox
                                                checked={checkIds.some((e) => e === ids.index)}
                                                onChange={() =>
                                                  checkIds.some((e) => e === ids.index)
                                                    ? setCheckIds(
                                                        checkIds.filter((e) => e !== ids.index)
                                                      )
                                                    : setCheckIds(checkIds.concat(ids.index))
                                                }
                                              />
                                            }
                                          />
                                        </Grid>
                                      ) : (
                                        <>{ids.index} </>
                                      )}
                                    </>
                                  ),

                                  dates: (date) => (
                                    <FormikProvider value={formik}>
                                      <FieldArray
                                        name="periodStages"
                                        render={(arrayHelpers) => (
                                          <LocalizationProvider
                                            dateAdapter={AdapterDateFns}
                                            locale={esLocale}
                                          >
                                            <Field
                                              name={`periodStages[${date.ids}]`}
                                              component={DateRangePicker}
                                              startText={null}
                                              endText={null}
                                              value={[
                                                values?.calificationModelDates?.distributions
                                                  ?.find(
                                                    (e) =>
                                                      e?.ref1_supply_id ===
                                                      distribution?.ref1_supply_id
                                                  )
                                                  ?.supplies?.find(
                                                    (e) =>
                                                      e?.ref2_supply_id ===
                                                      date?.ids?.ref2_supply_id
                                                  )?.startDate || null,
                                                values?.calificationModelDates?.distributions
                                                  ?.find(
                                                    (e) =>
                                                      e?.ref1_supply_id ===
                                                      distribution?.ref1_supply_id
                                                  )
                                                  ?.supplies?.find(
                                                    (e) =>
                                                      e?.ref2_supply_id ===
                                                      date?.ids?.ref2_supply_id
                                                  )?.endDate || null,
                                              ]}
                                              onChange={(e: DateRange<Date>) => {
                                                setFieldValue('calificationModelDates', {
                                                  ...values.calificationModelDates,
                                                  distributions:
                                                    values?.calificationModelDates?.distributions?.find(
                                                      (_distribution) =>
                                                        _distribution?.ref1_supply_id ===
                                                        distribution?.ref1_supply_id
                                                    )
                                                      ? values.calificationModelDates.distributions.map(
                                                          (_distribution) => {
                                                            if (
                                                              _distribution?.ref1_supply_id ===
                                                              distribution?.ref1_supply_id
                                                            ) {
                                                              return {
                                                                ..._distribution,
                                                                supplies:
                                                                  _distribution.supplies.find?.(
                                                                    (supply) =>
                                                                      supply?.ref2_supply_id ===
                                                                      date?.ids?.ref2_supply_id
                                                                  )
                                                                    ? _distribution.supplies.map(
                                                                        (supply) => {
                                                                          if (
                                                                            supply?.ref2_supply_id ===
                                                                            date?.ids
                                                                              ?.ref2_supply_id
                                                                          ) {
                                                                            return {
                                                                              ...supply,
                                                                              startDate: e[0],
                                                                              endDate: e[1],
                                                                            };
                                                                          }
                                                                          return supply;
                                                                        }
                                                                      )
                                                                    : _distribution.supplies.concat(
                                                                        {
                                                                          ref2_supply_id:
                                                                            date?.ids
                                                                              ?.ref2_supply_id,
                                                                          startDate: e[0],
                                                                          endDate: e[1],
                                                                        }
                                                                      ),
                                                              };
                                                            }
                                                            return _distribution;
                                                          }
                                                        )
                                                      : values.calificationModelDates.distributions.concat(
                                                          {
                                                            ref1_supply_id:
                                                              distribution?.ref1_supply_id,
                                                            supplies: [
                                                              {
                                                                ref2_supply_id:
                                                                  date?.ids?.ref2_supply_id,
                                                                startDate: e[0],
                                                                endDate: e[1],
                                                              },
                                                            ],
                                                          }
                                                        ),
                                                });
                                              }}
                                              renderInput={(
                                                startProps: TextFieldProps,
                                                endProps: TextFieldProps
                                              ) => (
                                                <Grid
                                                  container
                                                  spacing={2}
                                                  justifyContent="center"
                                                  alignItems="center"
                                                >
                                                  <Grid item xs={12} md={6} lg={4}>
                                                    <InputLabel>
                                                      {t(
                                                        'academic.periods_list.stages.add.stages_dates.start'
                                                      )}
                                                      :
                                                    </InputLabel>
                                                    <TextField
                                                      fullWidth
                                                      {...startProps}
                                                      helperText=""
                                                      {...getFieldProps(
                                                        `periodStages[${date.ids}].startDate`
                                                      )}
                                                    />
                                                    <FormHelperText>
                                                      {t(
                                                        'academic.periods_list.stages.add.stages_dates.start.helper'
                                                      )}
                                                    </FormHelperText>
                                                    <FormHelperText error>
                                                      {t(
                                                        (
                                                          (
                                                            errors?.calificationModelDates
                                                              ?.distributions?.[
                                                              distrIndex
                                                            ] as unknown as Distribution
                                                          )?.supplies?.[
                                                            date.ids.index - 1
                                                          ] as unknown as Supply
                                                        )?.startDate as unknown as string
                                                      )}
                                                    </FormHelperText>
                                                    {/* <ErrorMessage
                                                    name={`periodStages[${date.ids}].startDate`}
                                                  /> */}
                                                  </Grid>

                                                  <Grid item xs={12} md={6} lg={4}>
                                                    <InputLabel>
                                                      {t(
                                                        'academic.periods_list.stages.add.stages_dates.end'
                                                      )}
                                                      :
                                                    </InputLabel>
                                                    <TextField
                                                      fullWidth
                                                      {...endProps}
                                                      helperText=""
                                                      {...getFieldProps(
                                                        `periodStages[${date.ids}].endDate`
                                                      )}
                                                    />
                                                    <FormHelperText>
                                                      {t(
                                                        'academic.periods_list.stages.add.stages_dates.end.helper'
                                                      )}
                                                    </FormHelperText>
                                                    <FormHelperText error>
                                                      {t(
                                                        (
                                                          (
                                                            errors?.calificationModelDates
                                                              ?.distributions?.[
                                                              distrIndex
                                                            ] as unknown as Distribution
                                                          )?.supplies?.[
                                                            date.ids.index - 1
                                                          ] as unknown as Supply
                                                        )?.endDate as unknown as string
                                                      )}
                                                    </FormHelperText>
                                                    {/* <ErrorMessage
                                                    name={`periodStages[${date.ids}].endDate`}
                                                  /> */}
                                                  </Grid>
                                                </Grid>
                                              )}
                                            />
                                          </LocalizationProvider>
                                        )}
                                      />
                                    </FormikProvider>
                                  ),
                                }}
                              />
                            </Grid>
                          ))}
                    </>
                  )}

                  {isLoadingPeriodInfo && <Skeleton sx={{ width: '100%', height: '20vh' }} />}

                  {!isLoadingPeriodInfo && isCourse && (
                    <>
                      <SimpleTable
                        title=""
                        data={arrayTest.slice(valueRows, valueRows + 3)}
                        headers={{
                          ids: (
                            <>
                              {isCourse ? (
                                <FormControlLabel
                                  label={`${checkIds.length} ${t(
                                    'academic.periods_list.stages.add.grades.course'
                                  )}${checkIds.length !== 1 ? 's' : ''}`}
                                  control={
                                    <Checkbox
                                      // checked={arrayTest.length === checkIds.length}
                                      // indeterminate={
                                      //   checkIds.length > 0 && checkIds.length < arrayTest.length
                                      // }
                                      // onChange={() =>
                                      //   arrayTest.length === checkIds.length
                                      //     ? setCheckIds([])
                                      //     : setCheckIds(arrayTest.map((e) => e.ids.index))
                                      // }
                                      checked
                                      disabled
                                    />
                                  }
                                />
                              ) : (
                                <>#</>
                              )}
                            </>
                          ),
                          periodStageName: isCourse
                            ? t('academic.periods_list.stages.add.grades.course')
                            : isSupplies
                            ? t('academic.periods_list.stages.add.supply')
                            : t('academic.periods_list.stages.add.period_stage'),
                          dates: t('academic.periods_list.stages.add.date'),
                        }}
                        keyExtractor={(e) => e.ids.index.toString()}
                        customRenderers={{
                          ids: ({ ids }) => (
                            <>
                              {isCourse ? (
                                <Grid container justifyContent="center" alignItems="center">
                                  <FormControlLabel
                                    label=""
                                    control={
                                      <Checkbox
                                        // checked={checkIds.some((e) => e === ids.index)}
                                        // onChange={() =>
                                        //   checkIds.some((e) => e === ids.index)
                                        //     ? setCheckIds(checkIds.filter((e) => e !== ids.index))
                                        //     : setCheckIds(checkIds.concat(ids.index))
                                        // }
                                        checked
                                        disabled
                                      />
                                    }
                                  />
                                </Grid>
                              ) : (
                                <>{ids.index + 1} </>
                              )}
                            </>
                          ),

                          dates: (date) => (
                            <FormikProvider value={formik}>
                              <FieldArray
                                name="periodStages"
                                render={(arrayHelpers) => (
                                  <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                    locale={esLocale}
                                  >
                                    <Field
                                      name={`periodStages[${date.ids}]`}
                                      component={DateRangePicker}
                                      startText={null}
                                      endText={null}
                                      value={[
                                        values?.periodStages[date.ids.index]?.startDate,
                                        values?.periodStages[date.ids.index]?.endDate,
                                      ]}
                                      onChange={(e: DateRange<Date>) => {
                                        onChangeDatesArray(e, date.ids.index, date.ids.courseId);
                                      }}
                                      renderInput={(
                                        startProps: TextFieldProps,
                                        endProps: TextFieldProps
                                      ) => (
                                        <Grid
                                          container
                                          spacing={2}
                                          justifyContent="center"
                                          alignItems="center"
                                        >
                                          <Grid item xs={12} md={6} lg={4}>
                                            <InputLabel>
                                              {t(
                                                'academic.periods_list.stages.add.stages_dates.start'
                                              )}
                                              :
                                            </InputLabel>
                                            <TextField
                                              fullWidth
                                              {...startProps}
                                              helperText=""
                                              {...getFieldProps(
                                                `periodStages[${date.ids}].startDate`
                                              )}
                                            />
                                            <FormHelperText>
                                              {t(
                                                'academic.periods_list.stages.add.stages_dates.start.helper'
                                              )}
                                            </FormHelperText>
                                            <FormHelperText error>
                                              {
                                                /*eslint-disable-next-line*/
                                                t(
                                                  (
                                                    errors?.periodStages?.[
                                                      date.ids.index as any
                                                    ] as unknown as any
                                                  )?.startDate
                                                )
                                              }
                                            </FormHelperText>
                                            {/* <ErrorMessage
                                              name={`periodStages[${date.ids}].startDate`}
                                            /> */}
                                          </Grid>

                                          <Grid item xs={12} md={6} lg={4}>
                                            <InputLabel>
                                              {t(
                                                'academic.periods_list.stages.add.stages_dates.end'
                                              )}
                                              :
                                            </InputLabel>
                                            <TextField
                                              fullWidth
                                              {...endProps}
                                              helperText=""
                                              {...getFieldProps(
                                                `periodStages[${date.ids}].endDate`
                                              )}
                                            />
                                            <FormHelperText>
                                              {t(
                                                'academic.periods_list.stages.add.stages_dates.end.helper'
                                              )}
                                            </FormHelperText>
                                            <FormHelperText error>
                                              {
                                                /*eslint-disable-next-line*/
                                                t(
                                                  (
                                                    errors?.periodStages?.[
                                                      date.ids.index as any
                                                    ] as unknown as any
                                                  )?.endDate
                                                )
                                              }
                                            </FormHelperText>
                                            {/* <ErrorMessage
                                              name={`periodStages[${date.ids}].endDate`}
                                            /> */}
                                          </Grid>
                                        </Grid>
                                      )}
                                    />
                                  </LocalizationProvider>
                                )}
                              />
                            </FormikProvider>
                          ),
                        }}
                      />

                      {/* Pagination Table */}
                      {arrayTest.length > 3 && (
                        <Grid item container xs={12} justifyContent="center" alignItems="center">
                          <IconButton
                            onClick={() => {
                              setValueRows(valueRows - 3);
                              setCurrentPage(currentPage - 1);
                            }}
                            disabled={valueRows === 0}
                          >
                            <IconCaretLeft />
                          </IconButton>
                          {currentPage}
                          <IconButton
                            onClick={() => {
                              setValueRows(valueRows + 3);
                              setCurrentPage(currentPage + 1);
                            }}
                            disabled={valueRows + 3 >= arrayTest.length}
                          >
                            <IconCaretRight />
                          </IconButton>
                        </Grid>
                      )}
                    </>
                  )}

                  {/* Pagination Table
                  {arrayTest.length > 3 && (
                    <Grid item container xs={12} justifyContent="center" alignItems="center">
                      <IconButton
                        onClick={() => {
                          setValueRows(valueRows - 3);
                          setCurrentPage(currentPage - 1);
                        }}
                        disabled={valueRows === 0}
                      >
                        <IconCaretLeft />
                      </IconButton>
                      {currentPage}
                      <IconButton
                        onClick={() => {
                          setValueRows(valueRows + 3);
                          setCurrentPage(currentPage + 1);
                        }}
                        disabled={valueRows + 3 >= arrayTest.length}
                      >
                        <IconCaretRight />
                      </IconButton>
                    </Grid>
                  )} */}
                  {/* {isSupplies && (
                    <Grid item container xs={12} justifyContent="flex-start" alignItems="center">
                      {currentsSupplies?.map((e) => (
                        <>{`${t('academic.periods_list.stages.add.supply')}: ${e.id} | ${t(
                          'academic.periods_list.stages.add.start'
                        )}: ${e.startDate} ${t('academic.periods_list.stages.add.end')}: ${
                          e.endDate
                        }|`}</>
                      ))}
                    </Grid>
                  )} */}
                </Grid>
              </Grid>
            ) : null}
          </Grid>
          <Grid container alignItems="center" justifyContent="right" spacing={2} sx={{ mt: 2 }}>
            <Grid item>
              <Button
                variant="outlined"
                size="large"
                color="primary"
                onClick={() => history.goBack()}
              >
                {t('academic.periods_list.stages.add.back')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                // type="submit"
                variant="contained"
                color="primary"
                size="large"
                disabled={
                  isAssigningQuota || isAssigningQualification || isAssigningDefault || isLoadingSDT //setting start dat on admin subject register
                }
                // Open confirmation modal
                onClick={() => setConfirmModalOpen(true)}
                endIcon={
                  (isAssigningQuota || isAssigningQualification || isAssigningDefault) && (
                    <CircularProgress size={20} />
                  )
                }
              >
                {t('academic.periods_list.stages.add.stage.submit')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>

      {/* modal confirmation */}
      <ConfirmAlert
        open={confirmModalOpen}
        onClose={handleClose}
        isLoading={isAssigningQuota || isAssigningQualification || isAssigningDefault}
        isSuccess={false}
        error={'Error'}
        isError={false}
        handleDelete={handleConfirm}
        title={
          isSupplies
            ? t('academic.periods_list.stages.add.supply.confirm.title')
            : t('academic.periods_list.stages.add.stage.confirm.title')
        }
        subtitle={
          isSupplies
            ? t('academic.periods_list.stages.add.supply.confirm.subtitle')
            : t('academic.periods_list.stages.add.stage.confirm.subtitle')
        }
      />
    </>
  );
};

export default AssignStagesForm;
