import { Status } from '../../../types';

export const statusAvailables: Status[] = [
  {
    id: 1,
    name: 'Activo',
    acronym: 'active',
    color: 'success',
  },
  {
    id: 2,
    name: 'Inactivo',
    acronym: 'inactive',
    color: 'orange',
  },
  {
    id: 3,
    name: 'Eliminado',
    acronym: 'DELETE',
  },
];

export const transactionStatusAvailables: Status[] = [
  {
    id: 1,
    name: 'payment-active',
    color: 'success',
  },
  {
    id: 2,
    name: 'payment-rejected',
    color: 'orange',
  },
  {
    id: 3,
    name: 'payment-pending',
    color: 'grey',
  },
];

export const virtualClassActivitiesStatus = [
  {
    id: 1,
    name: 'en-proceso',
    label: 'shared.input.resource_status.in_process',
  },
  {
    id: 2,
    name: 'finalizado',
    label: 'shared.input.resource_status.completed',
  },
  {
    id: 3,
    name: 'pendiente',
    label: 'shared.input.resource_status.pending',
  },
];
