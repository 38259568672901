import { Grid, Skeleton, Typography } from '@material-ui/core';
import { useAppSelector } from '../../../../hooks/useRedux';
import MainCard from '../../../shared/components/cards/MainCard';
import CalendarFormCareerPlanning from '../components/CalendarFormCareerPlanning';
import Breadcrumb, { Data } from '../components/Breadcrumbs';
import { useEffect } from 'react';
import { useLazyGetDaysTypesAvailablesQuery } from '../slices/acPlanPostgraduateApiSlice';
import { useTranslation } from 'react-i18next';
import BackButton from '../../../shared/components/buttons/BackButton';

const CareerScreen = () => {
  const { t } = useTranslation();

  const data: Data[] = [
    { name: t('posgraduate.title'), url: '/planeacion-academica-posgrado' },
    { name: t('posgraduate.select_career'), url: '/planeacion-academica-posgrado/fechas-carrera' },
  ];

  const { colorEvents } = useAppSelector((state) => state.academicPlanningPosgraduate);

  const [getDaysTypesAvailables, { isLoading: isLoadingDaysTypes }] =
    useLazyGetDaysTypesAvailablesQuery();

  useEffect(() => {
    if (colorEvents.length === 0) {
      getDaysTypesAvailables({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Grid component={MainCard} sx={{ padding: 2 }}>
        <Grid sx={{ mb: 3 }} container alignItems="center">
          <BackButton />
          <Typography variant="h2">{t('posgraduate.career_planning')}</Typography>
        </Grid>
        <Grid sx={{ mb: 3 }}>
          <Breadcrumb data={data} currentName="Planeación de Programa" />
        </Grid>
        {isLoadingDaysTypes || colorEvents.length === 0 ? (
          <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
            <Grid item xs={8} md={3}>
              <Skeleton variant="rectangular" width="100%" height="10vh" />
            </Grid>

            <Grid item xs={12} mt={2}>
              <Skeleton variant="rectangular" width="100%" height="20vh" />
            </Grid>

            {Array.from(new Array(6)).map((item, index) => (
              <Grid item xs={12} md={4} key={index}>
                <Skeleton variant="rectangular" width="100%" height="400px" />
              </Grid>
            ))}
          </Grid>
        ) : (
          <CalendarFormCareerPlanning />
        )}
      </Grid>
    </div>
  );
};

export default CareerScreen;
