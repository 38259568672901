import { Grid, Typography } from '@material-ui/core';
import MainCard from '../../../shared/components/cards/MainCard';
import NewsList from '../components/NewsList';

import { useTranslation } from 'react-i18next';

const NewsAdminListScreen = function () {
  const { t } = useTranslation();
  
  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">{t('shared.news.title')}</Typography>
      </Grid>
      <NewsList />
    </Grid>
  );
};

export default NewsAdminListScreen;
