import {
  Grid,
  TableCell,
  TableRow,
  TableContainer,
  Table,
  TableBody,
  IconButton,
  TableHead,
  Typography,
} from '@material-ui/core';
import { IconFileCheck } from '@tabler/icons';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../../../../hooks/useRedux';
import { Participant } from '../../../interfaces/DetailActivityStudent';

import { useGetStudentsEvaluationsQuery } from '../../slices/evaluationApiSlice';
const EvaluationStudentsQualify = () => {
  const history = useHistory();

  const { t } = useTranslation();

  const {
    course: { courseId },
  } = useAppSelector((state) => state.virtualClassroomTeacherShared);

  const { evaluationId } = useParams<{ evaluationId: string }>();

  const { data: evaluationList } = useGetStudentsEvaluationsQuery({
    activityId: Number(evaluationId)!,
    cursoId: courseId!,
  });

  const evaluation = evaluationList?.participants;

  const handleQualify = (studentId: number, userId: number) => {
    history.push(
      `/administrar-aula-virtual/test/calificar/${evaluationId}/estudiante/${studentId}/${userId}`
    );
  };

  const RenderRow = (
    {
      student: {
        user: { person },
        id,
        user_id,
      },
      cab_activity_student,
    }: Participant,
    questions: string
  ) => (
    <TableRow
      selected={cab_activity_student !== null}
      hover
      className="table-row"
      key={id.toString()}
    >
      <TableCell width="50%" sx={{ cursor: 'pointer' }}>
        {`${person?.pers_firstname}
                ${person?.pers_secondname}
                ${person?.pers_first_lastname}
                ${person?.pers_second_lastname}`}
      </TableCell>
      <TableCell width="30%" align="center">
        {cab_activity_student === null ? 0 : cab_activity_student?.answer_qualified} / {questions}
      </TableCell>
      <TableCell width="10%" align="center">
        {cab_activity_student?.act_score || 0} / {evaluationList?.act_score_activity}
      </TableCell>
      <TableCell width="10%" align="center">
        <IconButton
          disabled={cab_activity_student === null}
          color="primary"
          aria-label="qualify"
          onClick={() => handleQualify(id, Number(user_id))}
        >
          <IconFileCheck />
        </IconButton>
      </TableCell>
    </TableRow>
  );
  const RenderRows = () => {
    return <>{evaluation?.map((e) => RenderRow(e, evaluationList?.act_num_max_questions!))!}</>;
  };

  return (
    <Grid
      item
      xs={8}
      md={8}
      sx={{ maxHeight: 600, overflow: 'hidden', marginTop: 5, margin: 'auto' }}
    >
      <PerfectScrollbar component="div">
        <TableContainer>
          <Table style={{ marginTop: 10 }}>
            <TableHead>
              <TableRow>
                <TableCell>{t('teacherVirtualClassroom.tasks.grupo.table.estudiante')}</TableCell>
                <TableCell align="center">
                  {t('teacherVirtualClassroom.tests.testHeaderQuestion.calificate')}
                </TableCell>
                <TableCell align="center">
                  {t('teacherVirtualClassroom.tests.testHeaderQuestion.score')}
                </TableCell>
                <TableCell>{t('teacherVirtualClassroom.tests.listHeaderQualify')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!evaluation || evaluation.length === 0 ? (
                <Typography>Sin Informacion</Typography>
              ) : (
                RenderRows()
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </PerfectScrollbar>
    </Grid>
  );
};

export default EvaluationStudentsQualify;
