import { Switch, Redirect } from 'react-router-dom';

import { useAppSelector } from '../../../../hooks/useRedux';

import PaychecksScreen from '../screens/PaychecksScreen';

import GuardRoute from '../../../../router/guards/GuardRoute';

import { permissionKey, subPermissionKeys } from '../constants/permissions';

const PeriodsRouter = () => {
  const { currentModule } = useAppSelector((state) => state.access);

  const hasPermission = 'debt' in currentModule?.permissions!;

  if (!hasPermission) return <Redirect to="/dashboard" />;

  return (
    <Switch>
      {/* Stages */}
      <GuardRoute
        exact
        path="/administrar-cheques"
        component={PaychecksScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.read}
      />
    </Switch>
  );
};

export default PeriodsRouter;
