import { Button, CircularProgress, Grid, Step, StepLabel, Stepper } from '@material-ui/core';

import { Formik } from 'formik';
import { lazy, Suspense, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '../../../../../../../hooks/useRedux';
import Loadable from '../../../../../../shared/components/Loadable';
import {
  setEvaluationsEditFormQuestions,
  setEvaluationsFormConfirmDialogOpen,
  setEvaluationsFormStepActive,
} from '../../slices/evaluationsSlice';
import {
  evaluationFormStep1Schema,
  evaluationFormStep2Schema,
} from '../../validation/evaluations.validation';
import ConfirmAddEvaluation from './ConfirmAddEvaluation';
import EvaluationConfirmation from './steps/EvaluationConfirmation';

const EvaluationInfoForm = Loadable(lazy(() => import('./steps/EvaluationInfoForm')));
const ScopesQuestionsForm = Loadable(lazy(() => import('./steps/ScopesQuestionsForm')));
const PreviewEvaluation = Loadable(lazy(() => import('./steps/EvaluationPreview')));

const EvaluationStepper = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const {
    evaluationsFormStepActive,
    evaluationsFormConfirmDialogOpen,
    evaluationsIdSelected,
    evaluationsList,
  } = useAppSelector((state) => state.evaluations);

  const evaluationToEdit = evaluationsList?.find(
    (evaluation) => evaluation.uuid === evaluationsIdSelected
  );

  const handleNextStep = () => {
    dispatch(setEvaluationsFormStepActive(evaluationsFormStepActive + 1));
  };
  const handleBackStep = () => {
    dispatch(setEvaluationsFormStepActive(evaluationsFormStepActive - 1));
  };

  useEffect(
    () => {
      if (evaluationsIdSelected) {
        const ev = evaluationsList.find((evaluation) => evaluation.uuid === evaluationsIdSelected)!;
        dispatch(setEvaluationsEditFormQuestions(ev.questions!));
      }

      return () => {
        dispatch(setEvaluationsFormStepActive(0));
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const steps = [
    {
      id: 1,
      label: t('maintenance.evaluations.form.steps.step1'),
      content: <EvaluationInfoForm />,
      validationSchema: evaluationFormStep1Schema,
    },
    {
      id: 2,
      label: t('maintenance.evaluations.form.steps.step2'),
      content: <ScopesQuestionsForm />,
      validationSchema: evaluationFormStep2Schema,
    },
    {
      id: 3,
      label: t('maintenance.evaluations.form.steps.step3'),
      content: <PreviewEvaluation />,
    },
    {
      id: 4,
      label: t('maintenance.evaluations.form.steps.step4'),
      content: <EvaluationConfirmation />,
    },
  ];

  return (
    <Grid container>
      <Grid item xs={12}>
        <Stepper activeStep={evaluationsFormStepActive}>
          {steps.map((step) => (
            <Step key={step.id}>
              <StepLabel>{step.label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Grid>
      <Grid item xs={12}>
        <Formik
          initialValues={{
            description: evaluationToEdit?.description || '',
            evaluation_type_id: evaluationToEdit?.eva_type_id || '',
            scopes: evaluationToEdit
              ? Object.values(
                  evaluationToEdit?.questions?.reduce<{
                    [key: string]: any;
                  }>(
                    (acc, question) => ({
                      ...acc,
                      [question.ambitos.uuid]: {
                        uuid: question.ambitos.uuid,
                        description: question.ambitos?.description!,
                        questions: [...(acc[question.ambitos.uuid]?.questions || []), question],
                        weight:
                          evaluationToEdit?.evaluationCategory?.find(
                            (c) => c.category_id === question.ambitos.uuid
                          )?.weight || 0,
                      },
                    }),
                    {}
                  )
                )
              : [],
            integral_evaluation_id: evaluationToEdit?.integral_evaluation?.uuid || '',
          }}
          validationSchema={steps[evaluationsFormStepActive].validationSchema}
          onSubmit={(values) => {
            if (!(steps.length - 2 === evaluationsFormStepActive)) {
              handleNextStep();
            } else {
              dispatch(setEvaluationsFormConfirmDialogOpen(true));
            }
          }}
        >
          {({ values, handleChange, handleSubmit, errors, touched }) => (
            <form onSubmit={handleSubmit}>
              <Grid container>
                <Grid item xs={12} mt={3}>
                  <Suspense fallback={<CircularProgress />}>
                    {steps[evaluationsFormStepActive].content}
                  </Suspense>
                </Grid>
                {evaluationsFormStepActive !== steps.length - 1 && (
                  <Grid item container xs={12} justifyContent="center" mt={3} spacing={2}>
                    {evaluationsFormStepActive !== 0 && (
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          size="large"
                          onClick={handleBackStep}
                        >
                          {t('maintenance.evaluations.form.steps.prev')}
                        </Button>
                      </Grid>
                    )}
                    <Grid item>
                      <Button variant="contained" color="primary" size="large" type="submit">
                        {steps.length - 2 === evaluationsFormStepActive
                          ? t('maintenance.evaluations.form.steps.end')
                          : t('maintenance.evaluations.form.steps.next')}
                      </Button>
                    </Grid>
                  </Grid>
                )}
                {evaluationsFormConfirmDialogOpen && <ConfirmAddEvaluation />}
              </Grid>
            </form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};

export default EvaluationStepper;
