import { Switch, Redirect } from 'react-router-dom';
import { useAppSelector } from '../../../../hooks/useRedux';
import AddParallelScreen from '../screens/AddParallelScreen';
import EditParallelScreen from '../screens/EditParallelScreen';
import ParallelsScreen from '../screens/ParallelsScreen';
import GuardRoute from '../../../../router/guards/GuardRoute';

import { subPermissionKeys } from '../constants/permissions';

const { groupKey: permissionKey } = subPermissionKeys;

const ParallelsRouter = () => {
  const { currentModule } = useAppSelector((state) => state.access);

  const hasPermission = permissionKey in currentModule?.permissions!;

  if (!hasPermission) return <Redirect to="/" />;

  return (
    <Switch>
      <GuardRoute
        exact
        path="/administrar-paralelos"
        component={ParallelsScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.read}
      />
      <GuardRoute
        exact
        path="/administrar-paralelos/agregar"
        component={AddParallelScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.create}
      />
      <GuardRoute
        exact
        path="/administrar-paralelos/:parallelId/editar"
        component={EditParallelScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.edit}
      />
    </Switch>
  );
};

export default ParallelsRouter;
