import DeleteAlert from '../../../../shared/components/dialogs/DeleteAlert';
// import { useTranslation } from 'react-i18next';
import { useDeleteScheduleMutation } from '../../slices/busRouteApiSlice';
// import { resetRoleIdToDelete, setDeleteModalOpen } from '../slices/rolesSlice';

interface Props {
  openModal: boolean;
  scheduleId: number;
  handleCloseModal: () => void;
}

const DeleteScheduleDialog = ({ openModal, scheduleId, handleCloseModal }: Props) => {
  // const { t } = useTranslation();
  const [deleteScheduelle, { isLoading, isSuccess, isError, error }] = useDeleteScheduleMutation();

  const handleDelete = () => {
    deleteScheduelle({ scheduleId });
  };

  const handleClose = () => {
    handleCloseModal();
  };
  //

  return (
    <DeleteAlert
      open={openModal}
      onClose={handleClose}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      handleDelete={handleDelete}
      // deleteTitle={t('pediente')}
      error={error as string}
    />
  );
};

export default DeleteScheduleDialog;
