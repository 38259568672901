import { mainApi } from '../../../../../../api/mainApi';
import { middlewareApi } from '../../../../../middleware/middlewareApi';
import {
  UserByProfileID,
  UserByProfileIDData,
  UserProfile,
} from '../interfaces/userProfile.interfaces';

export const groupsUsersTags = middlewareApi.enhanceEndpoints({
  addTagTypes: ['ListUsers'],
});

export const groupsUsersApi = groupsUsersTags.injectEndpoints({
  endpoints: (builder) => ({
    getListUserProfile: builder.query<UserProfile[], {}>({
      queryFn: async () => {
        try {
          const { data } = await mainApi.get<UserProfile[]>(
            '/profiles?user_profile_id=1&data=all',
            {
              params: {
                data: 'all',
              },
            }
          );

          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    getUsersByProfileId: builder.query<
      UserByProfileID,
      {
        page: number;
        size: number;
        search: string;
        sort?: string;
        type_sort?: string;
        profile_id: number;
      }
    >({
      queryFn: async ({ page, size, search, sort, type_sort, profile_id }) => {
        try {
          const { data } = await mainApi.get<UserByProfileID>(
            '/users-by-profile?user_profile_id=1',
            {
              params: {
                page,
                size,
                search,
                sort,
                type_sort,
                profile_id,
              },
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),
    getAllUsersByProfileId: builder.query<
      UserByProfileIDData[],
      {
        profile_id: number;
      }
    >({
      queryFn: async ({ profile_id }) => {
        try {
          const { data } = await mainApi.get<UserByProfileIDData[]>(
            '/users-by-profile?user_profile_id=1',
            {
              params: {
                data: 'all',
                profile_id,
              },
            }
          );

          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
    }),
  }),
});

export const {
  useGetListUserProfileQuery,
  useLazyGetUsersByProfileIdQuery,
  useLazyGetAllUsersByProfileIdQuery,
} = groupsUsersApi;
