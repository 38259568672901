import * as Yup from 'yup';

export const HourHandSchema = Yup.object().shape({
  hourMonday: Yup.boolean().required('El dato es requerido'),
  hourTuesday: Yup.boolean().required('El dato es requerido'),
  hourWednesday: Yup.boolean().required('El dato es requerido'),
  hourThursday: Yup.boolean().required('El dato es requerido'),
  hourFriday: Yup.boolean().required('El dato es requerido'),
  hourSaturday: Yup.boolean().required('El dato es requerido'),
  hourSunday: Yup.boolean().required('El dato es requerido'),
  hourDescription: Yup.string().required('La descripción es requerida'),
  statusId: Yup.number().required('El estado del permiso es requerido.'),
  workday_id: Yup.number().nullable().required('El tipo de jornada es requerido.'),
});
