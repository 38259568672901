import Grid from '@material-ui/core/Grid';
import SubjectCard from '../components/mallas/SubjectCard';

import { AccordionItem } from '../../../shared/components/extended/Accordion';
import { MatterMesh } from '../../../maintenance/meshs/interfaces/curriculum.interfaces';
import { SubjectCurriculumStatus, KeywordStatusMatter } from '../interfaces/subjects-approveds';
import { Box, Chip } from '@material-ui/core';

export const getCurrcilumPeriods = (
  subjectCurriculums: MatterMesh[] = [],
  subjectCurriculumsStatus: SubjectCurriculumStatus[] = []
): AccordionItem[] => {
  const allGroups = subjectCurriculums.map((sc) => sc.group);
  const group = [...new Set(allGroups)];

  const groupedAccordions = group.map((g, index) => {
    const filter = subjectCurriculums.filter((sc) => sc.group === g);

    const statusFilter = filter
      ?.filter((sc) => subjectCurriculumsStatus.find((s) => s.matter_id === sc.matter_id))
      .map((f) => {
        const status = subjectCurriculumsStatus.find((s) => s.matter_id === f.matter_id);

        return {
          ...f,
          courseExist: status?.course_exist! as 'No disponible' | 'Disponible',
          statusKey: status?.keyword_status_matter || KeywordStatusMatter.NDF,
          statusName: status?.status_matter || 'No definido',
          subject_curriculum_id: status?.subject_curriculum_id!,
          repeatedNumberSubject: status?.repeat_number_subject as number,
          maxRepeatedNumberSubject: status?.max_number_failed_subject as number,
          isRepeatedSubjectAvailable: status?.repeat_subject as boolean,
          mesajevalidate: status?.mesaje_validate as string | null,
        };
      });

    return {
      id: `group-${index + 1}`,
      title: (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Chip
            label={statusFilter.length}
            color="primary"
            sx={{
              marginRight: '10px',
            }}
          />
          Semestre {index + 1}
        </Box>
      ),
      defaultExpand: true,
      content: (
        <Grid
          container
          spacing={2}
          sx={{
            minHeight: '30px',
          }}
        >
          {statusFilter.map((sc) => (
            <Grid key={sc.id} item xs={12} md={6} lg={4}>
              <SubjectCard subject={sc} subjectCurriculumsStatus={subjectCurriculumsStatus} />
            </Grid>
          ))}
        </Grid>
      ),
    };
  });

  if (!groupedAccordions.some((period: any) => period.content?.props?.children?.length > 0)) {
    return [
      {
        id: 'group-1',
        title: 'No hay materias disponibles',
        defaultExpand: true,
        disabled: true,
        expanded: true,
        content: '',
      },
    ];
  }

  return groupedAccordions.filter((period: any) => period.content?.props?.children?.length > 0);
};

export const getCurriculumByArea = (
  subjectCurriculums: MatterMesh[] = [],
  subjectCurriculumsStatus: SubjectCurriculumStatus[] = []
): AccordionItem[] => {
  const allAreas = subjectCurriculums.map((sc) => sc.matter.area?.ar_name);
  const area = [...new Set(allAreas)];

  const groupedAreas = area.map((a, index) => {
    const filter = subjectCurriculums.filter((sc) => sc.matter.area?.ar_name === a);

    const statusFilter = filter.map((f) => {
      const status = subjectCurriculumsStatus.find((s) => s.matter_id === f.matter_id);

      return {
        ...f,
        courseExist: status?.course_exist! as 'No disponible' | 'Disponible',
        statusKey: status?.keyword_status_matter || KeywordStatusMatter.NDF,
        statusName: status?.status_matter || 'No definido',
        subject_curriculum_id: status?.subject_curriculum_id!,
        repeatedNumberSubject: status?.repeat_number_subject as number,
        maxRepeatedNumberSubject: status?.max_number_failed_subject as number,
        isRepeatedSubjectAvailable: status?.repeat_subject as boolean,
        mesajevalidate: status?.mesaje_validate as string | null,
      };
    });

    return {
      id: `group-${index + 1}`,
      title: (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Chip
            label={statusFilter.length}
            color="primary"
            sx={{
              marginRight: '10px',
            }}
          />
          {a}
        </Box>
      ),
      defaultExpand: true,
      content: (
        <Grid
          container
          spacing={2}
          sx={{
            minHeight: '30px',
          }}
        >
          {statusFilter.map((sc) => (
            <Grid key={sc.id} item xs={12} md={6} lg={4}>
              <SubjectCard subject={sc} subjectCurriculumsStatus={subjectCurriculumsStatus} />
            </Grid>
          ))}
        </Grid>
      ),
    };
  });

  if (!groupedAreas.some((period: any) => period.content?.props?.children?.length > 0)) {
    return [
      {
        id: 'group-1',
        title: 'No hay materias disponibles',
        defaultExpand: true,
        disabled: true,
        expanded: true,
        content: '',
      },
    ];
  }

  return groupedAreas.filter((period: any) => period.content?.props?.children?.length > 0);
};
