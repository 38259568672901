import { FC, useEffect, useState } from 'react';

import { Button, Divider, FormHelperText, Grid, Skeleton, Typography } from '@material-ui/core';
import Alert from '@material-ui/core/Alert';
import { IconEdit } from '@tabler/icons';

import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import InputLabel from '../../../shared/components/extended/Form/InputLabel';
import {
  useGetDebtorByPersonIdQuery,
  useGetPersonBillingDataQuery,
} from '../../../shared/slices/debtorPersonApi';
import { isEmptyObject } from '../../../shared/helpers';
// import { setDefaultTempBillingData, resetTemporalBillingData } from '../slices/debtsPaymentSlice';

interface Props {}

const TempBillingData: FC<Props> = () => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  //when debug mode is active, to avoid empty object with debug object
  const [isDebugEmptyObject, setIsDebugEmptyObject] = useState(false);

  // const handleCloseDialog = () => {
  //   setOpen(false);
  // };
  //por si se decide implementar la edicion de los datos de facturacion
  const handleOpenDialog = () => {
    setOpen(true);
    if (open) {
    }
  };

  const { currentClient } = useAppSelector((state) => state.generateDebts);

  const { data: debtorData, isFetching: isFetchingDBD } = useGetDebtorByPersonIdQuery(
    {
      personId: Number(currentClient?.id),
    },
    { skip: !currentClient }
  );

  const { data: defaultBillData, isFetching: isFetchingBillD } = useGetPersonBillingDataQuery(
    {
      debtorId: Number(currentClient?.id),
    },
    { skip: !debtorData || !currentClient }
  );

  useEffect(() => {
    if (defaultBillData) {
      const { debug, ...newBillingData } = defaultBillData as any;
      setIsDebugEmptyObject(isEmptyObject(newBillingData));
    }
    // dispatch(
    //   setDefaultTempBillingData({
    //     tipoIdentificacion: defaultBillData.person.type_identification_id,
    //     identificacion: defaultBillData.person.pers_identification,
    //     direccion: defaultBillData.person.pers_direction,
    //     email: defaultBillData.person.pers_email,
    //     razonSocial: `${defaultBillData.person.pers_firstname || ''} ${
    //       defaultBillData.person.pers_secondname || ''
    //     } ${defaultBillData.person.pers_first_lastname || ''} ${
    //       defaultBillData.person.pers_second_lastname || ''
    //     }`,
    //     telefono: defaultBillData.person.pers_cell,
    //   })
    // );
  }, [defaultBillData, dispatch]);

  return (
    <>
      <Grid container p={2} spacing={1}>
        <Grid container justifyContent={'space-between'} alignContent={'baseline'}>
          <Grid item>
            <Typography variant="h3">{t('financial.generate_debts.temp_billing.title')}</Typography>
          </Grid>
          <Grid item sx={{ textAlign: 'right' }}>
            {false && (
              <Button
                variant="outlined"
                size="small"
                onClick={handleOpenDialog}
                startIcon={<IconEdit />}
              >
                {t('financial.generate_debts.temp_billing.edit_btn')}
              </Button>
            )}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Alert
            severity="info"
            variant="outlined"
            sx={{ borderStyle: 'solid', borderColor: `#2196f3`, borderRadius: '12px' }}
          >
            <FormHelperText sx={{ textAlign: 'justify' }}>
              {t('financial.generate_debts.temp_billing.alert')}
            </FormHelperText>
          </Alert>
        </Grid>

        <Grid item xs={12} my={1}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <InputLabel>{t('financial.generate_debts.temp_billing.name_label')}</InputLabel>
          {isFetchingBillD || isFetchingDBD ? (
            <Skeleton variant="rectangular">
              <Typography variant="h5" component="p">
                Mera Solis Roberth Sebastían
              </Typography>
            </Skeleton>
          ) : (
            <Typography variant="h5" component="p">
              {defaultBillData && !isDebugEmptyObject
                ? defaultBillData?.razonSocial
                : currentClient?.pers_full_name || ''}
            </Typography>
          )}
        </Grid>

        <Grid item xs={12}>
          <InputLabel>{t('financial.generate_debts.temp_billing.dni_label')}</InputLabel>
          {isFetchingBillD || isFetchingDBD ? (
            <Skeleton variant="rectangular">
              <Typography variant="h5" component="p">
                000000000000
              </Typography>
            </Skeleton>
          ) : (
            <Typography variant="h5" component="p">
              {defaultBillData && !isDebugEmptyObject
                ? defaultBillData?.identificacion
                : currentClient?.pers_identification || ''}
            </Typography>
          )}
        </Grid>

        <Grid item xs={12}>
          <InputLabel>{t('financial.generate_debts.temp_billing.phone_label')}</InputLabel>
          {isFetchingBillD || isFetchingDBD ? (
            <Skeleton variant="rectangular">
              <Typography variant="h5" component="p">
                000000000000
              </Typography>
            </Skeleton>
          ) : (
            <Typography variant="h5" component="p">
              {defaultBillData && !isDebugEmptyObject
                ? defaultBillData?.telefono
                : currentClient?.pers_phone_home || currentClient?.pers_cell || ''}
            </Typography>
          )}
        </Grid>

        <Grid item xs={12}>
          <InputLabel>{t('financial.generate_debts.temp_billing.mail_label')}</InputLabel>
          {isFetchingBillD || isFetchingDBD ? (
            <Skeleton variant="rectangular">
              <Typography variant="h5" component="p">
                test@test.com
              </Typography>
            </Skeleton>
          ) : (
            <Typography variant="h5" component="p">
              {defaultBillData && !isDebugEmptyObject
                ? defaultBillData?.email
                : currentClient?.pers_email || ''}
            </Typography>
          )}
        </Grid>

        <Grid item xs={12}>
          <InputLabel>{t('financial.generate_debts.temp_billing.address_label')}</InputLabel>
          {isFetchingBillD || isFetchingDBD ? (
            <Skeleton variant="rectangular">
              <Typography variant="h5" component="p">
                test@test.com
              </Typography>
            </Skeleton>
          ) : (
            <Typography variant="h5" component="p">
              {defaultBillData && !isDebugEmptyObject
                ? defaultBillData?.direccion
                : currentClient?.pers_direction || ''}
            </Typography>
          )}
        </Grid>
      </Grid>

      {/* <TempBillingSideDialog
        // BillingData={{ data: billingData, isFetching: isFetcingBD }}
        open={openBilling}
        handleCloseDialog={handleCloseBilling}
      /> */}
    </>
  );
};

export default TempBillingData;
