import { CircularProgress, Grid } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import useStudentInfo from '../../hooks/useStudentInfo';
import {
  useGetValidateDebtsQuery,
  useGetValidatePaymentQuotaQuery,
} from '../../slices/subjectsRegisterApiSlice';
import { setIsPayment } from '../../slices/subjectsRegisterSlice';
import NoticePayment from './NoticePayment';
import NoticeRequirements from './NoticeRequirements';

const PreregisterScreen = () => {
  const dispatch = useAppDispatch();
  const { currentPeriodId, isOnline } = useAppSelector((state) => state.subjectsRegister);
  const { currentProfile, currentRol } = useAppSelector((state) => state.access);
  const [skipQuote, setSkipQuote] = useState(true);
  //   const [skip, setSkip] = useState(true);
  const studentInfo = useStudentInfo();
  //Validaciones de deudas
  const {
    isSuccess: isSuccessDebts,
    isError: isErrorDebts,
    error: errorDebts,
    isFetching: isFetchingDebts,
  } = useGetValidateDebtsQuery(
    {
      student_record_id: studentInfo?.studentRecordId!,
      user_profile_id: currentProfile?.profileId!,
      period_id: currentPeriodId!,
      person_id: studentInfo?.personId!,
      can_extend_period: currentRol?.canExtendPeriod!,
    },
    { refetchOnMountOrArgChange: 30, skip: !currentPeriodId || isOnline }
  );

  const { isSuccess, isFetching, isError, error } = useGetValidatePaymentQuotaQuery(
    {
      period_id: currentPeriodId!,
      student_record_id: studentInfo?.studentRecordId!,
    },
    {
      refetchOnMountOrArgChange: 30,
      skip: skipQuote,
    }
  );

  useEffect(() => {
    if (isSuccessDebts) setSkipQuote(false);
  }, [isSuccessDebts]);

  useEffect(() => {
    if (isSuccess) dispatch(setIsPayment(true));
  }, [isSuccess, dispatch]);

  return (
    <>
      {currentPeriodId && !isSuccessDebts ? (
        <>
          {isFetchingDebts ? (
            <Grid item container justifyContent={'center'} alignItems={'center'} height={100}>
              <CircularProgress />
            </Grid>
          ) : (
            <NoticePayment error={errorDebts} isError={isErrorDebts} />
          )}
        </>
      ) : currentPeriodId && !isSuccess ? (
        <>
          {isFetching ? (
            <Grid item container justifyContent={'center'} alignItems={'center'} height={100}>
              <CircularProgress />
            </Grid>
          ) : (
            <NoticeRequirements error={error} isError={isError} />
          )}
        </>
      ) : null}
    </>
  );
};

export default PreregisterScreen;
