import { Autocomplete, Grid, InputLabel, TextField, CircularProgress } from '@material-ui/core';
import { useState, useMemo, useEffect } from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import download from 'downloadjs';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import TextFieldFetching from '../../../shared/components/TextFieldFetching';
import { Period } from '../../academic-offerings/interfaces/periods.interface';
import {
  useGetAcademicOfferingQuery,
  useGetPeriodAcademicOfferingQuery,
  useGetAvailablesOffersQuery,
} from '../../academic-offerings/slices/academicOfferingsApiSlice';
import LocalizedDatePicker from '../../../shared/components/controlled/LocalizedDatePicker';
import {
  useGetSyllabusQuery,
  useGetSyllabusStatusQuery,
  useLazyGetMatterByEducationLevelIdQuery,
  useLazyGetPdfSyllabusQuery,
} from '../slices/SyllabusApiSlice';
import { useTableSearch } from '../../../../hooks/useTableSearch';
import {
  setIsOpenChangeStatusModal,
  setIsOpenHistorialSyllabusModal,
  setSyllabusIdSelected,
  updateSyllabusTable,
} from '../slices/SyllabusSlice';
import SearchPaginatedTableAndCollapsibleItems from '../../../shared/components/tables/SearchPaginatedTableAndCollapsibleItems';
import { Edit, PictureAsPdf, Restore, Rule } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import useSharedGuard from '../../../../hooks/useSharedGuard';
import { subPermissionKeys } from '../constants/permissions';

const SyllabusListTable = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const navigate = useHistory();

  const { syllabusIdSelected } = useAppSelector((state) => state.syllabus);

  const [isLoadingFile, setIsLoadingFile] = useState(false);

  const [statusKeywordSelected, setStatusKeywordSelected] = useState<string | null>(null);
  const [matterId, setMatterId] = useState<number | string | null>(null);
  const [selectedDate, handleDateChange] = useState<number | string | null>(null);
  const [periodId, setPeriodId] = useState<number | null>(null);
  const [education_level_id, setEducation_level_id] = useState<number | null>(null);
  const [offerDescription, setOff_description] = useState('');
  const { currentProfile } = useAppSelector((state) => state.access);

  const { hasAccess } = useSharedGuard(subPermissionKeys);

  //filters

  const { data: status, isLoading: isLoadingStatus } = useGetSyllabusStatusQuery();

  //niveles eduactivos
  const {
    data: offersAvailables = [],
    isLoading: isLoadingPID,
    isFetching: isFetchingPID,
  } = useGetAvailablesOffersQuery(
    {
      profileId: currentProfile?.profileId!,
      off_description: '',
    },
    { refetchOnMountOrArgChange: 30 }
  );

  //periodos
  const {
    data: periodsByOffer = [],
    isLoading: isLoadingCID,
    isFetching: isFetchingCID,
  } = useGetPeriodAcademicOfferingQuery(
    {
      profileId: currentProfile?.profileId!,
      academicOfferingId:
        offersAvailables!
          .find((offer) => offer.off_description === offerDescription)
          ?.id?.toString() || '',
      current: true,
    },
    { skip: !offerDescription, refetchOnMountOrArgChange: 30 }
  );

  const periods = useMemo(() => {
    return Array.isArray(periodsByOffer)
      ? (periodsByOffer as unknown as Period[]).filter(
          (dt) => new Date(dt.end_process).getTime() > new Date().getTime()
        )
      : [];
  }, [periodsByOffer]);

  const {
    data: offerById,
    isLoading: isLoadingOID,
    isFetching: isFetchingOID,
  } = useGetAcademicOfferingQuery(
    {
      profileId: currentProfile?.profileId!,
      academicOfferingId:
        offersAvailables!.find((offer) => offer.off_description === offerDescription)?.id! || 0,
    },
    { skip: !offerDescription, refetchOnMountOrArgChange: 30 }
  );

  const [
    getMatterByEducationLevelId,
    {
      data: matterByEducationLevelId,
      isLoading: isLoadingMatterByEducationLevelId,
      isFetching: isFetchingMatterByEducationLevelId,
    },
  ] = useLazyGetMatterByEducationLevelIdQuery();

  const [getPdfSyllabus] = useLazyGetPdfSyllabusQuery();

  const handleDownloadPdf = async (id: string | number) => {
    try {
      setIsLoadingFile(true);
      const filedata = await getPdfSyllabus({ syllabusId: id }).unwrap();

      const blob = new Blob([filedata], { type: 'application/pdf' });

      await download(blob, `syllabus.pdf`, 'application/pdf');
      setIsLoadingFile(false);
    } catch (error) {
      setIsLoadingFile(false);
      console.log(error);
    }
  };

  const {
    syllabusTable: { page, perPage: size, search },
  } = useAppSelector((state) => state.syllabus);

  const { setPage, setPerPage, setSearch } = useTableSearch({
    page,
    perPage: size,
    search,
    updateFunction: updateSyllabusTable,
  });

  const {
    data: syllabusList,
    isLoading,
    isFetching,
    error,
  } = useGetSyllabusQuery({
    profileId: currentProfile?.profileId!,
    page,
    size,
    search,
    ...(statusKeywordSelected && { status_id: statusKeywordSelected }),
    ...(matterId && { matter_id: matterId.toString() }),
    ...(periodId && { period_id: periodId.toString() }),
    ...(education_level_id && { education_level_id: education_level_id.toString() }),
    ...(selectedDate && { sy_anio: selectedDate.toString() }),
  });

  const dataFetch =
    syllabusList?.data.map((matter, index) => ({
      numberRow: (page - 1) * size + (index + 1),
      name: matter?.syllabus?.matter?.mat_name,
      options: matter,
    })) || [];

  useEffect(() => {
    if (education_level_id) {
      const executeGetMattersByEducationLevel = async () => {
        await getMatterByEducationLevelId({
          educationLevelId: education_level_id,
        });
      };
      executeGetMattersByEducationLevel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [education_level_id]);

  return (
    <>
      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} sm={4} md={4} lg={4}>
          <InputLabel>{t('Año')}:</InputLabel>
          <LocalizedDatePicker
            views={['year']}
            value={new Date()}
            maxDate={new Date()}
            onChange={(newValue) => {
              if (newValue) {
                console.log((newValue as Date).getFullYear());
                handleDateChange((newValue as Date).getFullYear());
              }
            }}
          />
        </Grid>

        {hasAccess('readAllOffers') && (
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <InputLabel>{t('academic.courses.edu_level')}:</InputLabel>
            {!isFetchingPID && (
              <Autocomplete
                // key={autocompleteKey.offer}
                options={offersAvailables}
                noOptionsText={t('academic.courses.edu_level.no_data')}
                loading={isLoadingPID}
                loadingText={t('academic.courses.edu_level.loading')}
                getOptionLabel={(option) => option.off_name}
                value={
                  offersAvailables.find((dt) => dt.off_description === offerDescription) || null
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={t('academic.courses.edu_level.placeholder')}
                  />
                )}
                onChange={(_, value) => {
                  if (value) {
                    setOff_description(value.off_description);
                  } else {
                    setOff_description('');
                    setPeriodId(0);
                    setEducation_level_id(0);
                    setMatterId(null);
                  }
                }}
                fullWidth
              />
            )}
            {isFetchingPID && <TextFieldFetching />}
          </Grid>
        )}

        {hasAccess('readPeriodsByOffer') && (
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <InputLabel>{t('academic.courses.period')}:</InputLabel>
            {!isFetchingCID && (
              <Autocomplete
                disabled={!offerDescription}
                options={periods}
                noOptionsText={t('academic.courses.period.no_data')}
                loading={isLoadingCID}
                loadingText={t('academic.courses.period.loading')}
                getOptionLabel={(option) => option.per_name}
                value={periods.find((dt) => dt.id === Number(periodId)) || null}
                renderInput={(params) => (
                  <TextField {...params} placeholder={t('academic.courses.period.placeholder')} />
                )}
                onChange={(_, value) => {
                  if (value) {
                    setPeriodId(value?.id);
                  } else {
                    setPeriodId(0);
                  }
                }}
                fullWidth
              />
            )}
            {isFetchingCID && <TextFieldFetching />}
          </Grid>
        )}

        {hasAccess('readOffers') && (
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <Grid item xs={8}>
              <InputLabel>{t('academic.courses.academic_unit')}:</InputLabel>
            </Grid>
            {!isFetchingOID && (
              <Autocomplete
                disabled={!offerDescription || !periodId}
                options={offerById ? offerById!.education_levels : []}
                noOptionsText={t('academic.courses.academic_unit.no_data')}
                loading={isLoadingOID}
                loadingText={t('academic.courses.academic_unit.loading')}
                getOptionLabel={(option) => option.edu_name}
                value={
                  offerById?.education_levels.find((dt) => dt.id === Number(education_level_id)) ||
                  null
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={t('academic.courses.academic_unit.placeholder')}
                  />
                )}
                onChange={(_, value) => {
                  if (value) {
                    setEducation_level_id(value?.id);
                  } else {
                    setEducation_level_id(0);
                  }
                }}
                fullWidth
              />
            )}
            {isFetchingOID && <TextFieldFetching />}
          </Grid>
        )}

        {hasAccess('readMatterByEducationLevelId') && (
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <InputLabel>{t('Materias')}:</InputLabel>
            {!isLoadingMatterByEducationLevelId && (
              <Autocomplete
                disabled={!education_level_id}
                options={matterByEducationLevelId || []}
                noOptionsText={t('No hay datos')}
                loading={isLoadingPID}
                loadingText={t('Cargando...')}
                getOptionLabel={(option) => option.mat_name}
                value={matterByEducationLevelId?.find((dt) => dt.id === matterId) || null}
                renderInput={(params) => (
                  <TextField {...params} placeholder={t('Seleccione una materia')} />
                )}
                onChange={(_, value) => {
                  if (value) {
                    setMatterId(value.id);
                  }
                }}
                fullWidth
              />
            )}
            {isFetchingMatterByEducationLevelId && <TextFieldFetching />}
          </Grid>
        )}

        {
          // faltan permisos de los estados de syllabus para el filtro
        }

        <Grid item xs={12} sm={4} md={4} lg={4}>
          <InputLabel>{t('Estados')}:</InputLabel>
          <Autocomplete
            loading={isLoadingStatus}
            options={status || []}
            getOptionLabel={(option) => option.st_name}
            onChange={(event, value) => {
              if (value) {
                setStatusKeywordSelected(`${value?.id!}`);
              } else {
                setStatusKeywordSelected('');
              }
            }}
            value={status?.find((option) => Number(option.id) === Number(statusKeywordSelected))}
            renderInput={(params) => (
              <TextField {...params} fullWidth placeholder={t('Seleccione un estado')} />
            )}
          />
        </Grid>
      </Grid>

      <SearchPaginatedTableAndCollapsibleItems
        data={dataFetch}
        headers={{
          numberRow: t('N°'),
          name: t('Nombre'),
          options: t('Opciones'),
        }}
        keyExtractor={(item) => `${item.numberRow}`}
        searchPlacehoder={t('Buscar apertura...')}
        isLoading={isLoading}
        isFetching={isFetching}
        error={error}
        perPage={size}
        setPerPage={setPerPage}
        total={syllabusList?.total || 0}
        page={page}
        setPage={setPage}
        numHeader={6}
        search={search}
        setSearch={setSearch}
        customHeadersCellsProperties={{
          numberRow: {
            align: 'center',
          },
          name: {
            align: 'center',
          },
          options: {
            align: 'center',
          },
        }}
        customDataCellsProperties={{
          numberRow: {
            align: 'center',
          },
          options: {
            align: 'center',
          },
        }}
        customRenderers={{
          options: (row) => (
            <>
              {hasAccess('changeStatusSyllabus') && (
                <Tooltip title="Cambiar de estado">
                  <IconButton
                    aria-label="Aceptar-Rechazar"
                    color="info"
                    onClick={() => {
                      dispatch(setIsOpenChangeStatusModal(true));
                      dispatch(setSyllabusIdSelected(row.options.id));
                    }}
                  >
                    <Rule sx={{ fontSize: '1.3rem' }} />
                  </IconButton>
                </Tooltip>
              )}

              {hasAccess('generatePdfSyllabus') && (
                <Tooltip title="Descargar PDF">
                  <IconButton
                    aria-label="PDF"
                    color="secondary"
                    onClick={() => {
                      dispatch(setSyllabusIdSelected(row.options.id));
                      handleDownloadPdf(row.options.id);
                    }}
                  >
                    {isLoadingFile &&
                    syllabusIdSelected &&
                    Number(syllabusIdSelected) === Number(row.options.id) ? (
                      <CircularProgress size={20} color="secondary" />
                    ) : (
                      <PictureAsPdf sx={{ fontSize: '1.3rem' }} />
                    )}
                  </IconButton>
                </Tooltip>
              )}

              {hasAccess('updateSyllabus') && (
                <Tooltip title="Corregir">
                  <IconButton
                    aria-label="PDF"
                    color="error"
                    onClick={() => {
                      navigate.push(
                        `/silabus/${row.options.syllabus.course_id}/${row.options.syllabus.matter_id}/${row.options.syllabus.period_id}/contenido-general`
                      );
                    }}
                  >
                    <Edit sx={{ fontSize: '1.3rem' }} />
                  </IconButton>
                </Tooltip>
              )}

              {hasAccess('readHistory') && (
                <Tooltip title="Historial">
                  <IconButton
                    aria-label="PDF"
                    color="primary"
                    onClick={() => {
                      dispatch(setIsOpenHistorialSyllabusModal(true));
                      dispatch(setSyllabusIdSelected(row.options.id));
                    }}
                  >
                    <Restore sx={{ fontSize: '1.3rem' }} />
                  </IconButton>
                </Tooltip>
              )}
            </>
          ),
        }}
      />
    </>
  );
};

export default SyllabusListTable;
