// material-ui
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';

// project imports
import MainCard from '../cards/MainCard';

// helpers
import { isPrimitive, objectKeys, objectValues } from '../../helpers';

// types
import { TableHeaders, CustomRenderers } from '../../interfaces/material-ui.interfaces';
import { useTranslation } from 'react-i18next';

interface Props<T extends {}> {
  title?: string;
  headers: TableHeaders<T>;
  data: T[];
  isLoading?: boolean;
  keyExtractor: (item: T) => string;
  customRenderers?: CustomRenderers<T>;
}

const SimpleTable = <T extends {}>({
  title,
  data,
  headers,
  isLoading,
  keyExtractor,
  customRenderers,
}: Props<T>) => {
  const { t } = useTranslation();

  const renderRow = (row: T) => {
    return (
      <TableRow key={keyExtractor(row)}>
        {objectKeys(row).map((cell) => {
          const customRenderer = customRenderers?.[cell];

          if (customRenderer) {
            return (
              <TableCell key={`${keyExtractor(row)}-${String(cell)}`}>
                {customRenderer(row)}
              </TableCell>
            );
          }

          return (
            <TableCell align="center" key={`${keyExtractor(row)}-${String(cell)}`}>
              {isPrimitive(row[cell]) ? row[cell] : ''}
            </TableCell>
          );
        })}
      </TableRow>
    );
  };

  return (
    <MainCard title={title || ''} content={false}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {objectValues(headers).map((headerValue) => (
                <TableCell align="center" key={headerValue}>
                  {headerValue}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!isLoading ? data.map(renderRow) : t('academic.requisition.review.loading')}
          </TableBody>
        </Table>
      </TableContainer>
    </MainCard>
  );
};

export default SimpleTable;
