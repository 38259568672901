import { Switch } from 'react-router-dom';

// import { useAppSelector } from '../../../../hooks/useRedux';

import GuardRoute from '../../../../router/guards/GuardRoute';
// import { permissionKey } from '../constants/permissions';
import ProjectsByCollaboratorRoute from './ProjectsByCollaboratorRoute';

function InvestigationLinesRouter() {
  // Verify permission
  // const { currentModule } = useAppSelector((state) => state.access);
  // const hasPermission = permissionKey in currentModule?.permissions!;

  // if (!hasPermission) return <Redirect to="/" />;

  return (
    <Switch>
      <GuardRoute
        // exact
        path={`/seguimiento-proyecto-investigacion`}
        component={ProjectsByCollaboratorRoute}
        // permissionKey={permissionKey}
        // subPermissionKey={usersSubPermissionKeys.read}
      />
    </Switch>
  );
}

export default InvestigationLinesRouter;
