import { mainApi } from '../../../../api/mainApi';
import { middlewareApi } from '../../../middleware/middlewareApi';
import { Catalog } from '../../courses/interfaces/course.interface';
import { ReceiptPayload, ReceiptsResponse, Reverse } from '../interfaces/receipts.interfaces';

export const receiptTags = middlewareApi.enhanceEndpoints({
  addTagTypes: ['receipts'],
});
export const receiptApi = receiptTags.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * @GET all profiles
     */
    getReceiptsToCancel: builder.query<
      ReceiptsResponse,
      { page: number; perPage: number; receiptPayload: Partial<ReceiptPayload> }
    >({
      queryFn: async ({ page, perPage, receiptPayload }, { getState }) => {
        try {
          const {
            access: {
              currentProfile: { profileId },
            },
          } = getState() as { access: { currentProfile: { profileId: number } } };
          const { data } = await mainApi.get<ReceiptsResponse>('/payments/reverse/receipts', {
            params: {
              user_profile_id: profileId,
              page,
              size: perPage,
              type_sort: 'desc',
              ...receiptPayload,
            },
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'receipts', id } as const)),
              { type: 'receipts', id: 'LIST' },
            ]
          : [{ type: 'receipts', id: 'LIST' }],
    }),

    /**
     * @POST reverse of payment.
     */
    reverseOfPayment: builder.mutation<
      Reverse,
      {
        receiptId: number;
        isBalancePositive?: boolean;
      }
    >({
      queryFn: async ({ receiptId, isBalancePositive = false }, { getState }) => {
        try {
          const {
            access: {
              currentProfile: { profileId },
            },
          } = getState() as { access: { currentProfile: { profileId: number } } };
          const { data } = await mainApi.post(`/payments/reverse/receipts/${receiptId}`, {
            user_profile_id: profileId,
            is_balance_positive: isBalancePositive,
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'receipts', id: 'LIST' }],
    }),

    /**
     * @POST reverse of payment reverse check.
     */
    reverseOfPaymentReceiptsCheck: builder.mutation<
      Reverse,
      {
        receiptId: number;
      }
    >({
      queryFn: async ({ receiptId }, { getState }) => {
        try {
          const {
            access: {
              currentProfile: { profileId },
            },
          } = getState() as { access: { currentProfile: { profileId: number } } };
          const { data } = await mainApi.post(`/payments/reverse/receipts-check/${receiptId}`, {
            user_profile_id: profileId,
            is_balance_positive: true,
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'receipts', id: 'LIST' }],
    }),

    /**
     * @POST debts cancellation.
     */
    debtCancellation: builder.mutation<
      Number[],
      {
        debts_id: number[];
        unlink_period: boolean;
      }
    >({
      queryFn: async ({ debts_id, unlink_period }, { getState }) => {
        try {
          const {
            access: {
              currentProfile: { profileId },
            },
          } = getState() as { access: { currentProfile: { profileId: number } } };

          const { data } = await mainApi.post(`/debts/delete`, {
            user_profile_id: profileId,
            debts_id,
            unlink_period,
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'receipts', id: 'LIST' }],
    }),

    getAvailablePaymentMethods: builder.query<Catalog[], {}>({
      queryFn: async (_, { getState }) => {
        try {
          const {
            access: {
              currentProfile: { profileId },
            },
          } = getState() as { access: { currentProfile: { profileId: number } } };

          const { data } = await mainApi.get<Catalog[]>(`/catalogs/payment-method`, {
            params: {
              user_profile_id: profileId,
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    /**
     * @POST receipt cancellation.
     */
    receiptCancellation: builder.mutation<
      Number[],
      {
        receiptId: number;
        idFact: number;
      }
    >({
      queryFn: async ({ receiptId, idFact }, { getState }) => {
        try {
          const {
            access: {
              currentProfile: { profileId },
            },
          } = getState() as { access: { currentProfile: { profileId: number } } };

          const { data } = await mainApi.post(`/payments/return/receipts/${receiptId}`, {
            user_profile_id: profileId,
            id_fact: idFact,
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'receipts', id: 'LIST' }],
    }),

    //
  }),
});

export const {
  useGetReceiptsToCancelQuery,
  useReverseOfPaymentMutation,
  useDebtCancellationMutation,
  useGetAvailablePaymentMethodsQuery,

  useReceiptCancellationMutation,
  useReverseOfPaymentReceiptsCheckMutation,
} = receiptApi;
