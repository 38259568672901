import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import {
  Alert,
  Autocomplete,
  Button,
  CircularProgress,
  FormControlLabel,
  FormHelperText,
  Grid,
  Switch,
  TextField,
} from '@material-ui/core';

import { useFormik } from 'formik';

import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import InputLabel from '../../../shared/components/forms/InputLabel';

import { useAppSelector } from '../../../../hooks/useRedux';
import { useUpdatePeriodTypeMutation } from '../slices/periodTypesApiSlice';

import { TypePeriodValidationSchema } from '../constants/validation-schemas';
import { statusAvailables } from '../../../shared/constants/resourceStatus';

const EditPeriodTypeForm = () => {
  const history = useHistory();
  const { periodTypeId } = useParams<{ periodTypeId: string }>();

  const { currentProfile } = useAppSelector((state) => state.access);
  const { periodTypesList } = useAppSelector((state) => state.periodTypes);

  const [showAlerts, setShowAlerts] = useState(true);

  const [updatePeriodType, { isLoading, isError, error, isSuccess }] =
    useUpdatePeriodTypeMutation();

  const periodTypeToEdit = periodTypesList.find((pt) => pt.id === Number(periodTypeId));

  const { getFieldProps, errors, touched, handleSubmit, setFieldValue, values } = useFormik({
    initialValues: {
      name: '',
      description: '',
      minMatter: 1,
      maxMatter: 1,
      numFees: 0,
      isPayEnrrollement: false,
      feesPerEnrollement: 0,
      statusId: 1,
      tp_acronym: '',
      isRegisterVinculation: false,
      validationFeesPerEnrollement: 0,
    },
    validationSchema: TypePeriodValidationSchema,
    onSubmit: async (values) => {
      await updatePeriodType({
        profileId: currentProfile?.profileId!,
        periodTypeId: Number(periodTypeId),
        periodPayload: {
          tp_name: values.name,
          tp_description: values.description,
          tp_min_matter_enrollment: values.minMatter.toString(),
          tp_max_matter_enrollment: values.maxMatter.toString(),
          tp_fees_enrollment: values.feesPerEnrollement.toString(),
          tp_pay_enrollment: values.isPayEnrrollement,
          tp_num_fees: values.numFees.toString(),
          status_id: values.statusId.toString(),
          tp_acronym: values.tp_acronym.toUpperCase(),
          isRegisterVinculation: values.isRegisterVinculation,
        },
      });

      setShowAlerts(true);
    },
  });

  useEffect(() => {
    if (periodTypeToEdit) {
      setFieldValue('name', periodTypeToEdit.tp_name);
      setFieldValue('description', periodTypeToEdit.tp_description);
      setFieldValue('minMatter', periodTypeToEdit.tp_min_matter_enrollment);
      setFieldValue('maxMatter', periodTypeToEdit.tp_max_matter_enrollment);
      setFieldValue('numFees', periodTypeToEdit.tp_num_fees);
      setFieldValue('isPayEnrrollement', Boolean(periodTypeToEdit.tp_pay_enrollment));
      setFieldValue('feesPerEnrollement', periodTypeToEdit.tp_fees_enrollment);
      setFieldValue('statusId', periodTypeToEdit.status_id);
      setFieldValue('tp_acronym', periodTypeToEdit.tp_acronym);
      setFieldValue('isRegisterVinculation', Boolean(periodTypeToEdit.isRegisterVinculation));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periodTypeToEdit]);

  useEffect(() => {
    if (periodTypeToEdit && !values.isPayEnrrollement) {
      setFieldValue('feesPerEnrollement', 0);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.isPayEnrrollement, periodTypeToEdit]);

  useEffect(() => {
    if (values.maxMatter) {
      setFieldValue('validationFeesPerEnrollement', Number(values.maxMatter) - 1);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.maxMatter]);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction="column" gap={2}>
        <Grid item>
          {isError && showAlerts && (
            <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
          )}

          {isSuccess && showAlerts && (
            <SuccessAlert
              message="El tipo de período ha sido actualizado correctamente."
              handleDismiss={() => setShowAlerts(false)}
            />
          )}
        </Grid>

        <Grid item container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <InputLabel>Nombre</InputLabel>
            <TextField
              fullWidth
              error={Boolean(errors.name && touched.name)}
              placeholder="Ingrese un nombre para el tipo de período"
              {...getFieldProps('name')}
            />
            {errors.name && touched.name && <FormHelperText error>{errors.name}</FormHelperText>}
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Acrónimo</InputLabel>
            <TextField
              fullWidth
              error={Boolean(errors.tp_acronym && touched.tp_acronym)}
              placeholder="Ingrese un Acrónimo para el tipo de período"
              {...getFieldProps('tp_acronym')}
            />
            {errors.tp_acronym && touched.tp_acronym && (
              <FormHelperText error>{errors.tp_acronym}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Descripción:</InputLabel>
            <TextField
              multiline
              rows={2}
              fullWidth
              error={Boolean(errors.description && touched.description)}
              placeholder="Ingrese una descripción para el tipo de período"
              {...getFieldProps('description')}
            />
            {errors.description && touched.description && (
              <FormHelperText error>{errors.description}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12} container spacing={2}>
            <Grid item xs={12}>
              <Alert severity="info">
                <strong>Nota:</strong> El número de cuotas corresponde a la cantidad de deudas que
                va a generarse dentro de un periodo académico.
              </Alert>
            </Grid>
          </Grid>

          <Grid item xs={12} container spacing={2}>
            <Grid item xs={6}>
              <InputLabel>N.º de cuotas</InputLabel>
              <TextField
                fullWidth
                type="number"
                error={Boolean(errors.numFees && touched.numFees)}
                placeholder="Cantidad de cuotas"
                {...getFieldProps('numFees')}
              />
              {errors.numFees && touched.numFees && (
                <FormHelperText error>{errors.numFees}</FormHelperText>
              )}
            </Grid>
            <Grid item container xs={6}>
              <FormControlLabel
                control={
                  <Switch
                    {...getFieldProps('isPayEnrrollement')}
                    checked={values.isPayEnrrollement}
                  />
                }
                label="¿Generar el valor de matrícula en la cuota inical del periodo académico?"
                // labelPlacement="start"
              />
              {errors.isPayEnrrollement && touched.isPayEnrrollement && (
                <FormHelperText error>{errors.isPayEnrrollement}</FormHelperText>
              )}
            </Grid>
          </Grid>

          {
            // configuracion de materias
          }

          <Grid item xs={12} container spacing={2}>
            <Grid item xs={12}>
              <Alert severity="info">
                <strong>Nota:</strong> N.º materias permitido para el registro en el portal
                académico por parte de estudiantes / administrativos, dentro de un periodo académico
              </Alert>
            </Grid>
          </Grid>
          <Grid item xs={12} container spacing={2}>
            <Grid item xs={3}>
              <InputLabel>Min. Materias</InputLabel>
              <TextField
                type="number"
                fullWidth
                placeholder="Cantidad mínima de materias"
                {...getFieldProps('minMatter')}
                error={Boolean(errors.minMatter && touched.minMatter)}
              />
              {errors.minMatter && touched.minMatter && (
                <FormHelperText error>{errors.minMatter}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={3}>
              <InputLabel>Max. Materias</InputLabel>
              <TextField
                type="number"
                fullWidth
                placeholder="Cantidad máxima de materias"
                {...getFieldProps('maxMatter')}
                error={Boolean(errors.maxMatter && touched.maxMatter)}
              />
              {errors.maxMatter && touched.maxMatter && (
                <FormHelperText error>{errors.maxMatter}</FormHelperText>
              )}
            </Grid>
            <Grid item xs={5}>
              <InputLabel>N.º materias a cobrar (Cuota Inicial) </InputLabel>
              <TextField
                type="number"
                fullWidth
                placeholder="Cantidad de cuotas a pagar en matriculación"
                {...getFieldProps('feesPerEnrollement')}
                disabled={!values.isPayEnrrollement}
                error={Boolean(errors.feesPerEnrollement && touched.feesPerEnrollement)}
              />
              {errors.feesPerEnrollement && touched.feesPerEnrollement && (
                <FormHelperText error>{errors.feesPerEnrollement}</FormHelperText>
              )}
            </Grid>
          </Grid>

          <Grid item container xs={12}>
            <FormControlLabel
              control={<Switch {...getFieldProps('isRegisterVinculation')} />}
              label="¿Permitir a los estudiantes registrados en este tipo de periodo realizar la solicitud de participación en proyectos de vinculación?"
            />
            {errors.isPayEnrrollement && touched.isPayEnrrollement && (
              <FormHelperText error>{errors.isPayEnrrollement}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12}>
            <InputLabel>Estado</InputLabel>
            <Autocomplete
              options={statusAvailables}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField {...params} error={Boolean(errors.statusId && touched.statusId)} />
              )}
              defaultValue={statusAvailables[0]}
              onChange={(e, value) => setFieldValue('statusId', value?.id || '')}
              fullWidth
              clearIcon={null}
            />
            {errors.statusId && touched.statusId && (
              <FormHelperText error>{errors.statusId}</FormHelperText>
            )}
          </Grid>
        </Grid>

        <Grid
          item
          container
          alignItems="center"
          justifyContent="flex-end"
          spacing={2}
          sx={{ mt: 1 }}
        >
          <Grid item>
            <Button
              variant="outlined"
              size="large"
              color="primary"
              onClick={() => history.goBack()}
            >
              Regresar
            </Button>
          </Grid>
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              disabled={isLoading}
              endIcon={isLoading && <CircularProgress size={20} />}
            >
              Actualizar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default EditPeriodTypeForm;
