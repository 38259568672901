import { Autocomplete, Grid, TextField } from '@material-ui/core';
import { format, isBefore, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/useRedux';
import { useTableSearch } from '../../../../../../hooks/useTableSearch';
import LocalizedDatePicker from '../../../../../shared/components/controlled/LocalizedDatePicker';
import AddButton from '../../../../../shared/components/tables/buttons/AddButton';
import SearchPaginatedTableAndCollapsibleItems from '../../../../../shared/components/tables/SearchPaginatedTableAndCollapsibleItems';
import TextFieldFetching from '../../../../../shared/components/TextFieldFetching';
import { useGetListAllPeriodsQuery } from '../../groups/slices/listGroupsApiSlice';
import { useGetLaunchingListQuery } from '../slices/launchingApiSlice';
import {
  setEnd_date,
  setLaunchingTable,
  setPeriodIdSelected,
  setStart_date,
  setStatusKeywordSelected,
} from '../slices/launchingSlice';
import StatusChipLaunching from './StatusChipLaunching';

const statuses = [
  {
    keyword: 'A',
    label: 'Activo',
    id: 1,
  },
  {
    keyword: 'I',
    label: 'Inactivo',
    id: 2,
  },
];

const LaunchingTable = () => {
  const dispatch = useAppDispatch();

  const navigate = useHistory();

  const {
    launchingTable: { page, perPage: size, search },
    end_date,
    start_date,
    statusKeywordSelected,
    periodIdSelected,
  } = useAppSelector((state) => state.launching);

  const { t } = useTranslation();

  const { setPage, setPerPage, setSearch } = useTableSearch({
    page,
    perPage: size,
    search,
    updateFunction: setLaunchingTable,
  });

  const {
    data: launchingList,
    isFetching,
    isLoading,
    error,
  } = useGetLaunchingListQuery({
    page,
    perPage: size,
    search,
    start_date: start_date.split('T')[0],
    end_date: end_date.split('T')[0],
    ...(statusKeywordSelected && { status: statusKeywordSelected }),
    ...(periodIdSelected && { period_id: periodIdSelected }),
  });

  const { data: periods, isLoading: isLoadingP } = useGetListAllPeriodsQuery({});

  const dataFetch =
    launchingList?.data.map(
      ({ id, name, cantidad_lanzamiento, start_date, end_date, status }, index) => ({
        numberRow: (page - 1) * size + (index + 1),
        name,
        cant: cantidad_lanzamiento,
        start_date,
        end_date,
        status,
      })
    ) || [];

  const goToAdd = () => {
    navigate.push('/lanzamiento/lanzar-evaluacion');
  };

  return (
    <>
      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} md={6}>
          <LocalizedDatePicker
            value={start_date}
            onChange={(newValue) => {
              dispatch(setStart_date((newValue as Date).toISOString()));
            }}
            label={t('financial.deposit_transfers.filters.dates.start_label')}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <LocalizedDatePicker
            value={end_date}
            onChange={(newValue) => {
              dispatch(setEnd_date((newValue as Date).toISOString()));
            }}
            label={t('financial.deposit_transfers.filters.dates.end_label')}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Autocomplete
            options={statuses}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('Estado')}
                placeholder={t('Seleccione un estado')}
                variant="outlined"
              />
            )}
            value={statuses.find((status) => status.keyword === statusKeywordSelected)}
            onChange={(event, newValue) => {
              dispatch(setStatusKeywordSelected(newValue?.keyword || null));
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          {isLoadingP ? (
            <TextFieldFetching />
          ) : (
            <Autocomplete
              options={periods || []}
              getOptionLabel={(option) => option.per_name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('Periodo')}
                  placeholder={t('Seleccione un periodo')}
                  variant="outlined"
                />
              )}
              value={periods?.find((status) => status.id === periodIdSelected)}
              onChange={(event, newValue) => {
                dispatch(setPeriodIdSelected(newValue?.id || null));
              }}
            />
          )}
        </Grid>
      </Grid>

      <SearchPaginatedTableAndCollapsibleItems
        data={dataFetch}
        headers={{
          numberRow: t('N°'),
          name: t('Nombre'),
          cant: t('Cantidad de lanzamientos'),
          start_date: t('Fecha de inicio'),
          end_date: t('Fecha de fin'),
          status: t('Estado'),
        }}
        keyExtractor={(item) => `${item.numberRow}`}
        searchPlacehoder={t('Buscar apertura...')}
        isLoading={isLoading}
        isFetching={isFetching}
        error={error}
        perPage={size}
        setPerPage={setPerPage}
        total={launchingList?.total || 0}
        page={page}
        setPage={setPage}
        numHeader={6}
        search={search}
        setSearch={setSearch}
        ActionButtons={<AddButton size="large" onClick={goToAdd} />}
        customRenderers={{
          status: (item) => (
            <>
              <StatusChipLaunching
                statusId={
                  isBefore(new Date(), parseISO(item.end_date.toString()))
                    ? 1
                    : format(new Date(), 'yyyy-MM-dd') ===
                      format(parseISO(item.end_date.toString()), 'yyyy-MM-dd')
                    ? 2
                    : 3
                }
              />
            </>
          ),
        }}
        customDataCellsProperties={{
          cant: {
            align: 'center',
          },
        }}
      />
    </>
  );
};

export default LaunchingTable;
