import { Avatar, Box, Grid, Typography, useTheme } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';
import { obtainDomainStorageImage } from '../../../../shared/helpers';
import { useGetRedlinksAvatarQueryQuery } from '../../../../shared/slices/redlinksApi';
import { StudentsData } from '../interfaces/student.interfaces';
import defaultPhoto from '../../../../../assets/images/users/noloadedavatar.png';
import { useAppSelector } from '../../../../../hooks/useRedux';
import { IconMail } from '@tabler/icons';

const useStyles = makeStyles((theme) => ({
  boxCard: {
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    borderRadius: 12,
    padding: '20px',
  },
  satckContainer: {
    backgroundColor: theme.palette.grey[50],
    padding: '10px',
    borderRadius: '10px',
  },
  stackTitle: {
    color: theme.grey500,
  },
  stackSubtitle: {
    fontWeight: 600,
    color: theme.palette.grey[800],
  },
  title: {
    fontWeight: 700,
    color: theme.palette.primary.main,
  },
  divider: { borderColor: theme.palette.grey[300] },
  iconMail: {
    fontSize: '15px',
    marginRight: '5px',
    color: '#008DE1',
  },
}));

interface Props {
  student: StudentsData;
}

const PartnerCard = ({ student }: Props) => {
  const { currentStudent } = useAppSelector((state) => state.access);

  const { data } = useGetRedlinksAvatarQueryQuery({
    identification: student?.us_username,
  });

  const theme = useTheme();

  const [photo, setPhoto] = useState('');

  useEffect(() => {
    if (data?.result?.foto) {
      setPhoto(data?.result?.foto);
    } else {
      setPhoto(obtainDomainStorageImage(`${student?.stud_photo_path!}`));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const classes = useStyles();
  return (
    <Grid item sm={6} md={3}>
      <Box
        className={classes.boxCard}
        sx={{
          ...(student.id === currentStudent?.id && {
            border: `2px solid ${theme.palette.primary.main}`,
          }),
        }}
      >
        <Box
          sx={{
            mb: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Avatar
            sx={{
              width: '72px',
              height: '72px',
              backgroundColor: '#ccc',
            }}
          >
            <img
              src={photo}
              onError={(e) => setPhoto(defaultPhoto)}
              width="100%"
              height="100%"
              alt="avatar"
            />
          </Avatar>
        </Box>
        <Box
          sx={{
            mb: 1,
          }}
        >
          <Typography variant="h6" align="center">{`${
            student?.pers_full_lastname || student?.pers_full_name
          }`}</Typography>
        </Box>
        <Grid item container justifyContent="center" alignItems="center" gap={1}>
          <IconMail color={'#008DE1'} />
          <Typography
            variant="subtitle2"
            sx={{
              wordBreak: 'break-all',
            }}
          >
            {student?.email}
          </Typography>
        </Grid>
      </Box>
    </Grid>
  );
};

export default PartnerCard;
