import {
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  InputLabel,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import { IconBell } from '@tabler/icons';
import { useFormik } from 'formik';
import { ChangeEvent, useState } from 'react';
import { useAppSelector } from '../../../../hooks/useRedux';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import { SUPPORT_TYPES } from '../../../shared/constants/extensionRequestType';
import { otherAcademicRequestValues } from '../constants/formConstants';
import { OtherAcademicRequestSchema } from '../constants/validationSchemas';
import { useAddOtherRequestMutation } from '../slices/academicRequestsApiSlice';
import useGuard from '../hooks/useGuard';

import { useTranslation } from 'react-i18next';
import DragArea from '../../../shared/components/files/DragArea';

const useStyles = makeStyles((theme) => ({
  alert: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.secondary.light,
    // fontSize: '14px',
    // fontWeight: 600,
    borderRadius: 3,
  },
  uploadFile: {
    borderColor: theme.palette.secondary.light,
    borderWidth: '2px',
    borderStyle: 'solid',
    borderRadius: 20,
    textAlign: 'center',
    width: '100%',
    height: '20vh',
    position: 'relative',
  },
  fileIcon: {
    mt: 3,
  },
  closeButton: {
    position: 'absolute',
    marginTop: 2,
    marginRight: 2,
    right: 0,
  },
}));

const OtherAcademicRequestsForm = () => {
  const { t } = useTranslation();

  const { hasAccess } = useGuard();
  const classes = useStyles();
  const [, setFile] = useState('');
  const { currentProfile } = useAppSelector((state) => state.access);
  const { userInfo } = useAppSelector((state) => state.auth);
  const [showAlerts, setShowAlerts] = useState(true);
  const [target, setTarget] = useState<File>(null!);
  const onChangeFile = function (event: ChangeEvent<HTMLInputElement>) {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e: any) => {
        setFile(e.target.result);
      };
      reader.readAsDataURL(event.target.files[0]);
      setTarget(event.target.files[0]);
    }
  };
  const onRemoveFile = function () {
    setFile('');
    setTarget(null!);
  };
  const [addOtherAcademicRequest, { isLoading, isSuccess, isError, error }] =
    useAddOtherRequestMutation();
  const { handleSubmit, errors, touched, getFieldProps } = useFormik({
    initialValues: otherAcademicRequestValues,
    validationSchema: OtherAcademicRequestSchema,
    validate: (values) => {
      const fileNotAllowed = `${t('students.applications.special_cases.validation1')}`;
      const fileNotFound = `${t('students.applications.special_cases.validation2')}`;
      const errors: any = {};
      if (target) {
        let extension = target?.type;
        if (SUPPORT_TYPES.indexOf(extension) < 0) {
          errors.files = fileNotAllowed;
        }
      } else {
        errors.files = fileNotFound;
      }
      return errors;
    },
    onSubmit: async (values) => {
      await addOtherAcademicRequest({
        profileId: currentProfile?.profileId!,
        userId: String(userInfo?.id!),
        otherAcademicRequestPayload: {
          files: target,
          type_document: 2,
          app_title: values.app_title,
          app_description: values.app_description,
          app_type: 'Otros',
        },
      });
    },
  });

  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction="column" gap={2}>
        <Grid item>
          {isError && showAlerts && (
            <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
          )}
          {isSuccess && showAlerts && (
            <SuccessAlert
              message={t('students.applications.success_message')}
              handleDismiss={() => setShowAlerts(false)}
            />
          )}
        </Grid>
        <Grid item xs={12} className={classes.alert}>
          <Typography variant="body2" color="black">
            <IconBell size={18} />
            {t('students.applications.special_cases.info')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <InputLabel>{t('students.applications.special_cases.title')}</InputLabel>
          <TextField
            fullWidth
            error={Boolean(errors.app_title && touched.app_title)}
            placeholder={t('students.applications.special_cases.title_placeholder')}
            {...getFieldProps('app_title')}
          />
          {errors.app_title && touched.app_title && (
            <FormHelperText error>{errors.app_title}</FormHelperText>
          )}
        </Grid>
        <Grid item xs={12}>
          <InputLabel>{t('students.applications.special_cases.reason')}</InputLabel>
          <TextField
            fullWidth
            error={Boolean(errors.app_description && touched.app_description)}
            placeholder={t('students.applications.special_cases.reason_placeholder')}
            {...getFieldProps('app_description')}
          />
          {errors.app_description && touched.app_description && (
            <FormHelperText error>{errors.app_description}</FormHelperText>
          )}
        </Grid>
        <Grid item xs={12}>
          {/* <InputLabel>{t('students.applications.upload_file')}</InputLabel> */}
          {/* <Grid item xs={12} md={12} container>
            <label className={classes.uploadFile}>
              {file && <IconCircleX className={classes.closeButton} onClick={onRemoveFile} />}
              <br />
              <input name="files[]" type="file" hidden onChange={onChangeFile} />

              <IconFile size={32} />
              <p>{!file ? `${t('students.applications.upload_file')}` : target.name}</p>
            </label>
          </Grid> */}
          <DragArea
            handleChange={onChangeFile}
            label={t('students.applications.upload_file')}
            onRemoveFile={onRemoveFile}
            typesAccepted={'application/pdf'}
            // file={file}
          />
          <Grid item xs={12}>
            {errors.files && touched.files && <FormHelperText error>{errors.files}</FormHelperText>}
          </Grid>
        </Grid>
        <Grid
          item
          container
          alignItems="center"
          justifyContent="flex-end"
          spacing={2}
          sx={{ mt: 1 }}
        >
          {hasAccess('save_other') && (
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                size="large"
                color="primary"
                disabled={isLoading}
                endIcon={isLoading && <CircularProgress size={20} />}
              >
                {t('students.applications.save')}
              </Button>
            </Grid>
          )}
        </Grid>
        {/*<Grid item container spacing={2} alignItems="center">
        </Grid>*/}
      </Grid>
    </form>
  );
};
export default OtherAcademicRequestsForm;
