import { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import { renderSkeletonTable } from '../../../../shared/helpers/render';

import { useHistory, useRouteMatch } from 'react-router-dom';
import { setForumToManage, setForumCurrent } from '../../slices/virtualClassroomSlice';
import NoData from '../../../../shared/components/NoData';
import noContentImage from '../../../../../assets/images/no-debates.png';
import { useTranslation } from 'react-i18next';
import { useGetListForumByCourseQuery } from '../../slices/virtualClassroomApiSlice';
import { Grid, Typography } from '@material-ui/core';
import CardForum from './CardForum';
import { Forum } from '../interfaces/forums.interfaces';

const ForumInfiniteList = () => {
  const dispatch = useAppDispatch();
  const { path } = useRouteMatch();
  const history = useHistory();

  const { currentProfile } = useAppSelector((state) => state.access);
  const { course } = useAppSelector((state) => state.virtualClassroomStudentShared);

  const [limit, setLimit] = useState<number>(2);
  const [offset, setOffset] = useState<number>(0);

  const { t } = useTranslation();

  console.log(offset);
  const { data, isSuccess /*isFetching, error*/ } = useGetListForumByCourseQuery(
    {
      profileId: currentProfile?.profileId!,
      courseId: course?.courseId! + '',
      page: 1,
      perPage: 100,
      search: '',
    },
    { refetchOnMountOrArgChange: 30 }
  );

  const fetchMoreData = () => {
    setOffset(data?.data?.length!);
    setLimit(limit + 3);
  };

  const goToForumView = (foroId: String, forum: Forum) => {
    dispatch(setForumCurrent(forum));
    dispatch(setForumToManage(Number(foroId)));
    history.push(`${path}/${foroId}/view`);
  };

  const renderTimes = () => {
    return (
      <>
        {data?.data?.slice(0, limit + 1).map((data) => (
          <CardForum
            forum={data}
            key={data.id}
            goToForumView={() => goToForumView(data.id.toString(), data)}
          />
        ))}
      </>
    );
  };

  return (
    <Grid
      container
      spacing={4}
      sx={{
        px: 4,
      }}
    >
      <Grid item xs={12} sm={12} md={12}>
        {isSuccess ? (
          <>
            {data?.data?.length! > 0 ? (
              <InfiniteScroll
                dataLength={data?.data?.slice(0, limit + 1).length!}
                next={fetchMoreData}
                hasMore={data?.data?.length! > limit + 1}
                loader={renderSkeletonTable(3, 1)}
                endMessage={
                  <Typography variant="h5" textAlign={'center'} mt={2}>
                    {t('studentVirtualClassroom.forum.notData')}
                  </Typography>
                }
              >
                {renderTimes()}
              </InfiniteScroll>
            ) : (
              <NoData
                image={noContentImage}
                title={t('studentVirtualClassroom.forum.empty')}
                subtitle={t('studentVirtualClassroom.forum.emptyDescription')}
              />
            )}
          </>
        ) : (
          renderSkeletonTable(3, 1)
        )}
      </Grid>
    </Grid>
  );
};
export default ForumInfiniteList;
