import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Autocomplete,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { useFormik } from 'formik';

import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import {
  useAddConfigProductPriceMutation,
  useEditConfigProductPriceMutation,
  useGetAvailableProcessQuery,
  useGetAvailablesPeridosFinancialsQuery,
} from '../slices/configProductPriceApiSlice';
import { useAppSelector } from '../../../../hooks/useRedux';
import { useGetAvailablesEconomicGroupQuery } from '../../institutes/slices/institutesApiSlice';
import { ConfigProductPriceSchema } from '../constants/validation-schemas';
import { useGetAvaliblesEducationLevelsQuery } from '../../education-levels/slices/educationLevelsApiSlice';
import {
  useGetAcademicOfferingsAvailablesQuery,
  useGetAvailiablesPeriodsQuery,
} from '../../periods/slices/periodsApiSlice';
import {
  useGetAvailablesProductsQuery,
  useGetTypeApplicationQuery,
} from '../../maintenance-product/slices/productApiSlice';

import { useGetAvaliblesMattersQuery } from '../../matters/slices/mattersApiSlice';
import { useGetAgreementsAvailablesQuery } from '../../agreements/slices/agreementsApiSlices';
import { useGetAvailablesCurriculumsQuery } from '../../meshs/slices/meshsApiSlice';
import { showWithDecimals } from '../../../shared/helpers';
import { ConfigurationProduct } from '../interfaces/configuration-product.interfaces';
import { statusAvailables } from '../../../shared/constants/resourceStatus';
import TextFieldFetching from '../../../shared/components/TextFieldFetching';

interface Props {
  editData?: ConfigurationProduct;
}

const ConfigProductPriceForm = ({ editData }: Props) => {
  const history = useHistory();
  const { t } = useTranslation();

  const { currentProfile } = useAppSelector((state) => state.access);

  const [showAlerts, setShowAlerts] = useState(true);

  const [
    addConfigProduct,
    { error: errorAdd, isError: isErrorAdd, isLoading: isLoadingAdd, isSuccess: isSuccessAdd },
  ] = useAddConfigProductPriceMutation();

  const [
    editConfigProductPrice,
    { error: errorUpd, isError: isErrorUpd, isLoading: isLoadingUpd, isSuccess: isSuccessUpd },
  ] = useEditConfigProductPriceMutation();

  const { handleSubmit, setFieldValue, errors, touched, getFieldProps, values } = useFormik({
    initialValues: {
      economic_group_id: Number(editData?.economic_group_id) || 0,
      education_level_id: Number(editData?.education_level_id) || 0,
      type_application_keyword: '',
      pc_gross_value: editData?.pc_gross_value
        ? showWithDecimals(Number(editData?.pc_gross_value))
        : 0,
      pc_discount_percent: editData?.pc_discount_percent
        ? showWithDecimals(Number(editData?.pc_discount_percent))
        : 0,
      product_id: Number(editData?.product_id) || 0,
      period_id: Number(editData?.period_id) || 0,
      offer_id: Number(editData?.offer_id) || 0,
      subject_id: Number(editData?.subject_id) || 0,
      status_id: Number(editData?.status_id) || 1,
      financial_period_id: Number(editData?.financial_period_id) || 0,
      agreement_id: Number(editData?.agreement_id) || 0,
      process_id: Number(editData?.process_id) || 0,
      curriculum_id: Number(editData?.curriculum_id) || 0,
      pc_net_value: editData?.pc_net_value ? showWithDecimals(Number(editData?.pc_net_value)) : 0,
      pc_discount_value: editData?.pc_discount_value
        ? showWithDecimals(Number(editData?.pc_discount_value))
        : 0,
    },
    validationSchema: ConfigProductPriceSchema,
    onSubmit: async (values) => {
      if (editData) {
        await editConfigProductPrice({
          user_profile_id: currentProfile?.profileId!,
          productConfig: editData.id,
          configProductPayload: {
            education_level_id: values.education_level_id || null,
            economic_group_id: values.economic_group_id || null,
            type_application_keyword: values.type_application_keyword || null,
            pc_gross_value: values.pc_gross_value || null,
            pc_discount_percent: values.pc_discount_percent || null,
            product_id: values.product_id,
            period_id: values.period_id || null,
            offer_id: values.offer_id || null,
            subject_id: values.subject_id || null,
            status_id: 1,
            financial_period_id: values.financial_period_id || null,
            agreement_id: values.agreement_id || null,
            process_id: values.process_id || null,
            curriculum_id: values.curriculum_id || null,
            pc_net_value: values.pc_net_value || null,
            pc_discount_value: values.pc_discount_value || null,
          },
        });
      } else {
        await addConfigProduct({
          profileId: currentProfile?.profileId!,
          configProductPayload: {
            education_level_id: values.education_level_id || null,
            economic_group_id: values.economic_group_id || null,
            type_application_keyword: values.type_application_keyword || null,
            pc_gross_value: values.pc_gross_value || null,
            pc_discount_percent: values.pc_discount_percent || null,
            product_id: values.product_id,
            period_id: values.period_id || null,
            offer_id: values.offer_id || null,
            subject_id: values.subject_id || null,
            status_id: 1,
            financial_period_id: values.financial_period_id || null,
            agreement_id: values.agreement_id || null,
            process_id: values.process_id || null,
            curriculum_id: values.curriculum_id || null,
            pc_net_value: values.pc_net_value || null,
            pc_discount_value: values.pc_discount_value || null,
          },
        });
      }

      setShowAlerts(true);
      window.scrollTo(0, 0);
    },
  });

  const { data: dataGroupEconomic, isLoading: isLoadingroupEconomic } =
    useGetAvailablesEconomicGroupQuery({
      profileId: currentProfile?.profileId!,
    });

  const { data: dataEducationLevel, isLoading: isLoadingEducationLevel } =
    useGetAvaliblesEducationLevelsQuery({
      profileId: currentProfile?.profileId!,
    });

  const { data: dataTypeApplication, isLoading: isLoadingTypeApplication } =
    useGetTypeApplicationQuery({
      profileId: currentProfile?.profileId!,
      acronym: 'CERTIF',
    });

  const { data: dataAvailablePeriods, isLoading: isLoadingAvailablePeriods } =
    useGetAvailiablesPeriodsQuery({
      profileId: currentProfile?.profileId!,
      // ...(values.offer_id && { offer: values.offer_id }),
    });

  const { data: dataAvailableProducts, isLoading: isLoadingAvailableProducts } =
    useGetAvailablesProductsQuery({
      profileId: currentProfile?.profileId!,
      onlyActive: true,
    });

  const { data: dataOfferAcademic, isLoading: isLoadingOfferAcademic } =
    useGetAcademicOfferingsAvailablesQuery({
      profileId: currentProfile?.profileId!,
    });

  const {
    data: dataSubjects,
    isLoading: isLoadingSubjects,
    isFetching: isFetchingSubjects,
  } = useGetAvaliblesMattersQuery({
    profileId: currentProfile?.profileId!,
  });

  const { data: dataAgreements, isLoading: isLoadingAgreements } = useGetAgreementsAvailablesQuery({
    profileId: currentProfile?.profileId!,
  });

  const { data: dataProcess, isLoading: isLoadingProcess } = useGetAvailableProcessQuery({
    profileId: currentProfile?.profileId!,
  });

  const { data: dataPeriodFinancial, isLoading: isLoadingPeriodFinancial } =
    useGetAvailablesPeridosFinancialsQuery({
      profileId: currentProfile?.profileId!,
    });

  const {
    data: dataCurriculum,
    isLoading: isLoadingCurriculum,
    isFetching: isFetchingCurriculum,
  } = useGetAvailablesCurriculumsQuery({
    profileId: currentProfile?.profileId!,
  });

  useEffect(() => {
    const discountValue = values.pc_gross_value - (values.pc_net_value || 0);

    const discountPercentage = (discountValue * 100) / (values.pc_gross_value || 1);

    setFieldValue('pc_discount_percent', showWithDecimals(discountPercentage));

    setFieldValue('pc_discount_value', showWithDecimals(discountValue));
  }, [values.pc_gross_value, values.pc_net_value, setFieldValue]);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={4} alignItems="flex-start">
        <Grid item xs={12}>
          {(isErrorAdd || isErrorUpd) && showAlerts && (
            <ErrorAlert
              message={(errorAdd || errorUpd) as string}
              handleDismiss={() => setShowAlerts(false)}
            />
          )}

          {(isSuccessAdd || isSuccessUpd) && showAlerts && (
            <SuccessAlert
              message={t('financial.product_config.add.success_alert')}
              handleDismiss={() => setShowAlerts(false)}
            />
          )}
        </Grid>

        <Grid item xs={12} md={6} container spacing={2}>
          {/* Container product id */}
          <Grid item xs={12}>
            <InputLabel>{t('financial.product_config.add.product_label')}</InputLabel>
            {isLoadingAvailableProducts ? (
              <TextFieldFetching />
            ) : (
              <Autocomplete
                options={dataAvailableProducts || []}
                loading={isLoadingAvailableProducts}
                loadingText={t('financial.product_config.add.product_loading')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(errors.product_id && touched.product_id)}
                    placeholder={t('financial.product_config.add.product_placeholder')}
                  />
                )}
                value={
                  dataAvailableProducts?.find((product) => product.id === values.product_id) || null
                }
                getOptionLabel={(option) => option.pr_description}
                onChange={(_, value) => {
                  setFieldValue('product_id', value?.id);
                  setFieldValue('pc_gross_value', value?.amount ? Number(value?.amount) : 0);
                  console.log('value', value);
                }}
                fullWidth
                style={{ marginTop: 5 }}
              />
            )}

            {errors.product_id && touched.product_id && (
              <FormHelperText error>{t(errors.product_id)}</FormHelperText>
            )}
          </Grid>

          {/* Container group education level */}
          <Grid item xs={12}>
            <InputLabel>{t('financial.product_config.add.academic_offer_label')}</InputLabel>
            {isLoadingOfferAcademic ? (
              <TextFieldFetching />
            ) : (
              <Autocomplete
                options={dataOfferAcademic! || []}
                loading={isLoadingOfferAcademic}
                loadingText={t('financial.product_config.add.academic_offer_loading')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(errors.offer_id && touched.offer_id)}
                    placeholder={t('financial.product_config.add.academic_offer_placeholder')}
                  />
                )}
                getOptionLabel={(option) => option?.off_name}
                value={dataOfferAcademic?.find((offer) => offer.id === values.offer_id) || null}
                onChange={(_, value) => {
                  setFieldValue('offer_id', value?.id);
                }}
                fullWidth
                style={{ marginTop: 5 }}
              />
            )}

            {errors.offer_id && touched.offer_id && (
              <FormHelperText error>{t(errors.offer_id)}</FormHelperText>
            )}
          </Grid>

          {/* Container offer */}
          <Grid item xs={12}>
            <InputLabel>{t('financial.product_config.add.education_lvl_label')}</InputLabel>
            {isLoadingEducationLevel ? (
              <TextFieldFetching />
            ) : (
              <Autocomplete
                options={dataEducationLevel! || []}
                loading={isLoadingEducationLevel}
                loadingText={t('financial.product_config.add.education_lvl_loading')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(errors.education_level_id && touched.education_level_id)}
                    placeholder={t('financial.product_config.add.education_lvl_placeholder')}
                  />
                )}
                getOptionLabel={(option) => option?.edu_name}
                value={
                  dataEducationLevel?.find(
                    (educationLevel) => educationLevel.id === values.education_level_id
                  ) || null
                }
                onChange={(_, value) => setFieldValue('education_level_id', value?.id)}
                fullWidth
                style={{ marginTop: 5 }}
              />
            )}

            {errors.education_level_id && touched.education_level_id && (
              <FormHelperText error>{t(errors.education_level_id)}</FormHelperText>
            )}
          </Grid>

          {/* Container periods */}
          <Grid item xs={12}>
            <InputLabel>{t('financial.product_config.add.period_label')}</InputLabel>
            {isLoadingAvailablePeriods ? (
              <TextFieldFetching />
            ) : (
              <Autocomplete
                options={dataAvailablePeriods! || []}
                loading={isLoadingAvailablePeriods}
                loadingText={t('financial.product_config.add.period_loading')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(errors.period_id && touched.period_id)}
                    placeholder={t('financial.product_config.add.period_placeholder')}
                  />
                )}
                getOptionLabel={(option) => option?.per_name}
                value={
                  dataAvailablePeriods?.find((period) => period.id === values.period_id) || null
                }
                onChange={(_, value) => {
                  setFieldValue('period_id', value?.id);
                }}
                fullWidth
                style={{ marginTop: 5 }}
              />
            )}

            {errors.period_id && touched.period_id && (
              <FormHelperText error>{t(errors.period_id)}</FormHelperText>
            )}
          </Grid>

          {/* Container group economic */}
          <Grid item xs={12}>
            <InputLabel>{t('financial.product_config.add.economic_group_label')}</InputLabel>
            {isLoadingroupEconomic ? (
              <TextFieldFetching />
            ) : (
              <Autocomplete
                options={dataGroupEconomic! || []}
                loading={isLoadingroupEconomic}
                loadingText={t('financial.product_config.add.economic_group_loading')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(errors.economic_group_id && touched.economic_group_id)}
                    placeholder={t('financial.product_config.add.economic_group_placeholder')}
                  />
                )}
                getOptionLabel={(option) => option?.eco_gro_name}
                value={
                  dataGroupEconomic?.find(
                    (groupEconomic) => groupEconomic.id === values.economic_group_id
                  ) || null
                }
                onChange={(_, value) => setFieldValue('economic_group_id', value?.id)}
                fullWidth
                style={{ marginTop: 5 }}
              />
            )}

            {errors.economic_group_id && touched.economic_group_id && (
              <FormHelperText error>{t(errors.economic_group_id)}</FormHelperText>
            )}
          </Grid>

          {/* Container group education level */}
          <Grid item xs={12}>
            <InputLabel>{t('financial.product_config.add.subject_label')}</InputLabel>
            {isLoadingSubjects || isFetchingSubjects ? (
              <TextFieldFetching />
            ) : (
              <Autocomplete
                options={dataSubjects! || []}
                loading={isLoadingSubjects}
                loadingText={t('financial.product_config.add.subject_loading')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(errors.subject_id && touched.subject_id)}
                    placeholder={t('financial.product_config.add.subject_placeholder')}
                  />
                )}
                getOptionLabel={(option) => option?.mat_name}
                value={dataSubjects?.find((subject) => subject.id === values.subject_id) || null}
                onChange={(_, value) => setFieldValue('subject_id', value?.id)}
                fullWidth
                style={{ marginTop: 5 }}
              />
            )}

            {errors.subject_id && touched.subject_id && (
              <FormHelperText error>{t(errors.subject_id)}</FormHelperText>
            )}
          </Grid>

          {/* Container group curriculum */}
          <Grid item xs={12}>
            <InputLabel>{t('financial.product_config.add.curriculum_label')}</InputLabel>
            {isLoadingCurriculum || isFetchingCurriculum ? (
              <TextFieldFetching />
            ) : (
              <Autocomplete
                options={dataCurriculum! || []}
                loading={isLoadingCurriculum}
                loadingText={t('financial.product_config.add.curriculum_loading')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(errors.curriculum_id && touched.curriculum_id)}
                    placeholder={t('financial.product_config.add.curriculum_placeholder')}
                  />
                )}
                getOptionLabel={(option) => option?.mes_name}
                value={
                  dataCurriculum?.find((curriculum) => curriculum.id === values.curriculum_id) ||
                  null
                }
                onChange={(_, value) => setFieldValue('curriculum_id', value?.id)}
                fullWidth
                style={{ marginTop: 5 }}
              />
            )}

            {errors.curriculum_id && touched.curriculum_id && (
              <FormHelperText error>{t(errors.curriculum_id)}</FormHelperText>
            )}
          </Grid>
        </Grid>

        {/* Container rigth */}
        <Grid item xs={12} md={6} container spacing={2}>
          {/* Container financial period */}
          <Grid item xs={12}>
            <InputLabel>{t('financial.product_config.add.financial_period_label')}</InputLabel>
            {isLoadingPeriodFinancial ? (
              <TextFieldFetching />
            ) : (
              <Autocomplete
                options={dataPeriodFinancial! || []}
                loading={isLoadingPeriodFinancial}
                loadingText={t('financial.product_config.add.financial_period_loading')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(errors.subject_id && touched.subject_id)}
                    placeholder={t('financial.product_config.add.financial_period_placeholder')}
                  />
                )}
                getOptionLabel={(option) => option?.fp_description}
                value={
                  dataPeriodFinancial?.find(
                    (periodFinancial) => periodFinancial.id === values.financial_period_id
                  ) || null
                }
                onChange={(_, value) => setFieldValue('financial_period_id', value?.id)}
                fullWidth
                style={{ marginTop: 5 }}
              />
            )}

            {errors.financial_period_id && touched.financial_period_id && (
              <FormHelperText error>{t(errors.financial_period_id)}</FormHelperText>
            )}
          </Grid>

          {/* Container process */}
          <Grid item xs={12}>
            <InputLabel>{t('financial.product_config.add.process_label')}</InputLabel>
            {isLoadingProcess ? (
              <TextFieldFetching />
            ) : (
              <Autocomplete
                options={dataProcess! || []}
                loading={isLoadingProcess}
                loadingText={t('financial.product_config.add.process_loading')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(errors.process_id && touched.process_id)}
                    placeholder={t('financial.product_config.add.process_placeholder')}
                  />
                )}
                getOptionLabel={(option) => option?.pr_name}
                value={dataProcess?.find((process) => process.id === values.process_id) || null}
                onChange={(_, value) => setFieldValue('process_id', value?.id)}
                fullWidth
                style={{ marginTop: 5 }}
              />
            )}

            {errors.process_id && touched.process_id && (
              <FormHelperText error>{t(errors.process_id)}</FormHelperText>
            )}
          </Grid>

          {/* Container application_type_keywork */}
          <Grid item xs={12}>
            <InputLabel>{t('financial.product_config.add.certificate_type_label')}</InputLabel>
            {isLoadingroupEconomic ? (
              <TextFieldFetching />
            ) : (
              <Autocomplete
                options={dataTypeApplication?.children || []}
                loading={isLoadingTypeApplication}
                loadingText={t('financial.product_config.add.certificate_type_loading')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(
                      errors.type_application_keyword && touched.type_application_keyword
                    )}
                    placeholder={t('financial.product_config.add.certificate_type_placeholder')}
                  />
                )}
                getOptionLabel={(option) => `${option?.typ_app_name} | ${option?.typ_app_acronym}`}
                value={
                  dataTypeApplication?.children?.find(
                    (typeApplication) =>
                      typeApplication.typ_app_acronym === values.type_application_keyword
                  ) || null
                }
                onChange={(_, value) =>
                  setFieldValue('type_application_keyword', value?.typ_app_acronym)
                }
                fullWidth
                style={{ marginTop: 5 }}
              />
            )}

            {errors.type_application_keyword && touched.type_application_keyword && (
              <FormHelperText error>{t(errors.type_application_keyword)}</FormHelperText>
            )}
          </Grid>

          {/* Container application_type_keywork */}
          <Grid item xs={12}>
            <InputLabel>{t('financial.product_config.add.agreement_label')}</InputLabel>
            {isLoadingAgreements ? (
              <TextFieldFetching />
            ) : (
              <Autocomplete
                options={dataAgreements! || []}
                loading={isLoadingAgreements}
                loadingText={t('financial.product_config.add.agreement_loading')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(errors.agreement_id && touched.agreement_id)}
                    placeholder={t('financial.product_config.add.agreement_placeholder')}
                  />
                )}
                getOptionLabel={(option) => option.agr_name}
                value={dataAgreements?.find((agreement) => agreement.id === values.agreement_id)}
                onChange={(_, value) => setFieldValue('agreement_id', value?.id)}
                fullWidth
                style={{ marginTop: 5 }}
              />
            )}

            {errors.agreement_id && touched.agreement_id && (
              <FormHelperText error>{t(errors.agreement_id)}</FormHelperText>
            )}
          </Grid>

          {/* Price groos and discount */}
          <Grid item xs={12} container spacing={2}>
            <Grid item xs={6}>
              <InputLabel>{t('financial.product_config.add.gross_label')}</InputLabel>
              <TextField
                type="number"
                fullWidth
                placeholder={t('financial.product_config.add.gross_placeholder')}
                {...getFieldProps('pc_gross_value')}
                // disabled
                error={Boolean(errors.pc_gross_value && touched.pc_gross_value)}
              />
              {errors.pc_gross_value && touched.pc_gross_value && (
                <FormHelperText error>{t(errors.pc_gross_value)}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={6}>
              <InputLabel>{t('financial.product_config.add.net_price_label')}</InputLabel>
              <TextField
                // type="number"
                fullWidth
                placeholder={t('financial.product_config.add.net_price_placeholder')}
                {...getFieldProps('pc_net_value')}
                error={Boolean(errors.pc_net_value && touched.pc_net_value)}
              />
              {errors.pc_net_value && touched.pc_net_value && (
                <FormHelperText error>{t(errors.pc_net_value)}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={6}>
              <InputLabel>{t('financial.product_config.add.percentage_label')}</InputLabel>
              <TextField
                // type="number"
                fullWidth
                placeholder={t('financial.product_config.add.percentage_placeholder')}
                {...getFieldProps('pc_discount_percent')}
                error={Boolean(errors.pc_discount_percent && touched.pc_discount_percent)}
                disabled
              />
              {errors.pc_discount_percent && touched.pc_discount_percent && (
                <FormHelperText error>{t(errors.pc_discount_percent)}</FormHelperText>
              )}
            </Grid>
            <Grid item xs={6}>
              <InputLabel>{t('financial.product_config.add.discount_value_label')}</InputLabel>
              <TextField
                // type="number"
                fullWidth
                placeholder={t('financial.product_config.add.discount_value_placeholder')}
                {...getFieldProps('pc_discount_value')}
                error={Boolean(errors.pc_discount_value && touched.pc_discount_value)}
                disabled
              />
              {errors.pc_discount_value && touched.pc_discount_value && (
                <FormHelperText error>{t(errors.pc_discount_value)}</FormHelperText>
              )}
            </Grid>

            {/* Container status */}

            <Grid item xs={12} hidden={!editData}>
              <InputLabel>{t('financial.product_config.edit.config_status_label')}</InputLabel>

              <Autocomplete
                options={statusAvailables || []}
                loadingText={t('financial.product_config.edit.config_status_loading')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(errors.status_id && touched.status_id)}
                    placeholder={t('financial.product_config.edit.config_status_placeholder')}
                  />
                )}
                defaultValue={statusAvailables.find(
                  (value) => value.id === Number(editData?.status_id)
                )}
                getOptionLabel={(option) => option.name}
                onChange={(_, value) => setFieldValue('status_id', value?.id)}
                fullWidth
                style={{ marginTop: 5 }}
              />

              {errors.status_id && touched.status_id && (
                <FormHelperText error>{t(errors.status_id)}</FormHelperText>
              )}
            </Grid>
          </Grid>

          <Grid
            item
            container
            alignItems="center"
            justifyContent="flex-end"
            spacing={2}
            sx={{ mt: 1 }}
          >
            <Grid item>
              <Button
                variant="outlined"
                size="large"
                color="primary"
                onClick={() => history.goBack()}
              >
                {t('shared.go_back_button.label')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                size="large"
                color="primary"
                disabled={isLoadingAdd || isLoadingUpd}
                endIcon={(isLoadingAdd || isLoadingUpd) && <CircularProgress size={20} />}
              >
                {editData ? t('shared.update_button.label') : t('shared.add_button.label')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default ConfigProductPriceForm;
