import * as yup from 'yup';

export const integralEvaluationFormSchema = yup.object().shape({
  name: yup.string().required('maintenance.integral_evaluation.form.description.required'),
  description: yup.string().required('maintenance.integral_evaluation.form.description.required'),
  eva_rubrica_detail: yup
    .array()
    .min(1, 'Agrege al menos una rúbrica')
    .of(
      yup.object().shape({
        eva_types_id: yup
          .string()
          .required('maintenance.integral_evaluation.form.eva_types.required'),
        eva_peso: yup.number().required('Ingrese el peso de cada evaluación'),
      })
    )
    .required('maintenance.integral_evaluation.form.eva_types.required'),
});
