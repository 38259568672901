import { useHistory } from 'react-router-dom';
import {
  Autocomplete,
  Button,
  CircularProgress,
  Grid,
  InputLabel,
  TextField,
} from '@material-ui/core';
import {
  useAddAutorMutation,
  useEditAuthorMutation,
  useGetNacionalidadesQuery,
} from '../slices/authorsApiSlice';
import { statusAvailables } from '../../../shared/constants/resourceStatus';
import { useFormik } from 'formik';
import * as yup from 'yup';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { setRefetchRequest } from '../slices/authorsSlice';
import LocalizedDatePicker from '../../../shared/components/controlled/LocalizedDatePicker';

interface props {
  isModal?: boolean;
  onUpdate?: () => void;
  handleCloseModal?: () => void;
}

const AddAuthor = ({ isModal, onUpdate, handleCloseModal }: props) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { authorToEdit } = useAppSelector((state) => state.authors);

  const { data: nacionalidades } = useGetNacionalidadesQuery({
    acronym: 'NA',
  });

  const { data: paises } = useGetNacionalidadesQuery({
    acronym: 'PS',
  });

  const schema = yup.object().shape({
    name: yup.string().required('authors.form.name.validation'),
    birthday: yup.string().typeError('authors.form.birthdate.validation'),
    departure: yup.string().typeError('authors.form.deceased_date.validation'),
    nacionality: yup.string(),
    country: yup.string(),
    status: yup.string().required('Estado es un campo requerido'),
  });

  const [
    addAutor,
    {
      isLoading: isLoadingAdd,
      isError: isErrorAdd,
      error: errorAdd,
      isSuccess: isSuccessAdd,
      reset,
    },
  ] = useAddAutorMutation();

  const [
    editAuthor,
    {
      isLoading: isLoadingEdit,
      isError: isErrorEdit,
      error: errorEdit,
      isSuccess: isSuccessEdit,
      reset: resetEdit,
    },
  ] = useEditAuthorMutation();

  const formik = useFormik({
    initialValues: {
      name: authorToEdit?.name || '',
      birthday: authorToEdit?.born_date || '',
      departure: authorToEdit?.death_date || '',
      nacionality: authorToEdit?.nationality_id || '',
      country: authorToEdit?.country_id || '',
      status: isModal ? '1' : authorToEdit?.status_id || '',
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      if (authorToEdit) {
        editAuthor({
          id: authorToEdit.id,
          payload: {
            born_date: values.birthday,
            country_id: values.country,
            death_date: values.departure,
            name: values.name,
            nationality_id: values.nacionality,
            status_id: values.status,
            type_tag: 'Autor',
          },
        });
      } else {
        addAutor({
          payload: {
            born_date: values.birthday,
            country_id: values.country,
            death_date: values.departure,
            name: values.name,
            nationality_id: values.nacionality,
            status_id: values.status,
            type_tag: 'Autor',
          },
        });
      }
    },
  });

  useEffect(() => {
    if (isSuccessAdd) {
      dispatch(setRefetchRequest(true));
      formik.resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessAdd, dispatch]);

  useEffect(() => {
    if (isSuccessAdd && isModal && onUpdate) {
      onUpdate();
    }
  }, [isSuccessAdd, isModal, onUpdate]);

  return (
    <Grid container direction="column" gap={2}>
      <Grid item container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <InputLabel>{t('authors.form.name')}</InputLabel>
          <TextField
            //defaultValue={formik.values.name}
            //value={formik.values.name}
            fullWidth
            placeholder={`${t('authors.form.placeholder.enter')} ${t('authors.form.name')}`}
            error={Boolean(formik.errors.name && formik.touched.name)}
            helperText={
              Boolean(formik.errors.name && formik.touched.name)
                ? t(String(formik.errors.name))
                : null
            }
            //onChange={({ target }) => formik.setFieldValue('name', target.value)}
            {...formik.getFieldProps('name')}
          />
        </Grid>

        <Grid item xs={12}>
          <InputLabel>{t('authors.form.birthdate')}</InputLabel>
          <LocalizedDatePicker
            value={formik.values.birthday}
            onChange={(val) => {
              formik.setFieldValue('birthday', val);
            }}
            error={Boolean(formik.errors.birthday && formik.touched.birthday)}
            helperText={
              Boolean(formik.errors.birthday && formik.touched.birthday)
                ? t(String(formik.errors.birthday))
                : undefined
            }
          />
        </Grid>

        <Grid item xs={12}>
          <InputLabel>{t('authors.form.deceased_date')}</InputLabel>
          <LocalizedDatePicker
            value={formik.values.departure || null}
            onChange={(val) => {
              formik.setFieldValue('departure', val);
            }}
            error={Boolean(formik.errors.departure && formik.touched.departure)}
            helperText={
              Boolean(formik.errors.departure && formik.touched.departure)
                ? t(String(formik.errors.departure))
                : undefined
            }
          />
        </Grid>

        <Grid item xs={12}>
          <InputLabel>{t('authors.form.nacionality')}</InputLabel>
          <Autocomplete
            options={
              nacionalidades?.children.map((na) => ({ value: na.id, label: na.cat_name })) || []
            }
            value={
              nacionalidades?.children
                .map((na) => ({ value: na.id, label: na.cat_name }))
                .find((nc) => nc.value === Number(formik.values.nacionality)) || null
            }
            //getOptionLabel={option => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={`${t('authors.form.placeholder.select')} ${t(
                  'authors.form.nacionality'
                )}`}
                error={Boolean(formik.errors.nacionality && formik.touched.nacionality)}
                helperText={
                  Boolean(formik.errors.nacionality && formik.touched.nacionality)
                    ? t(String(formik.errors.nacionality))
                    : null
                }
                {...formik.getFieldProps('nacionality')}
              />
            )}
            //defaultValue={}
            onChange={(_, value) => {
              formik.setFieldValue('nacionality', value?.value);
            }}
            fullWidth
            clearIcon={null}
          />
        </Grid>

        <Grid item xs={12}>
          <InputLabel>{t('authors.form.country')}</InputLabel>
          <Autocomplete
            options={paises?.children.map((na) => ({ value: na.id, label: na.cat_name })) || []}
            value={
              paises?.children
                .map((na) => ({ value: na.id, label: na.cat_name }))
                .find((nc) => nc.value === Number(formik.values.country)) || null
            }
            //getOptionLabel={option => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={`${t('authors.form.placeholder.select')} ${t('authors.form.country')}`}
                error={Boolean(formik.errors.country && formik.touched.country)}
                helperText={
                  Boolean(formik.errors.country && formik.touched.country)
                    ? t(String(formik.errors.country))
                    : null
                }
                {...formik.getFieldProps('country')}
              />
            )}
            //defaultValue={}
            onChange={(_, value) => {
              formik.setFieldValue('country', value?.value);
            }}
            fullWidth
            clearIcon={null}
          />
        </Grid>
        {!isModal && (
          <Grid item xs={12}>
            <InputLabel>{t('authors.form.status')}</InputLabel>
            <Autocomplete
              options={statusAvailables
                .filter((st) => st.id !== 3)
                .map((st) => ({ value: st.id, label: st.name }))}
              //getOptionLabel={option => option.name}
              value={
                statusAvailables
                  .filter((st) => st.id !== 3)
                  .map((st) => ({ value: st.id, label: st.name }))
                  .find((nc) => nc.value === Number(formik.values.status)) || null
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={`${t('authors.form.placeholder.select')} ${t(
                    'authors.form.status'
                  )}`}
                  error={Boolean(formik.errors.status && formik.touched.status)}
                  helperText={
                    Boolean(formik.errors.status && formik.touched.status)
                      ? t(String(formik.errors.status))
                      : null
                  }
                  {...formik.getFieldProps('status')}
                />
              )}
              //defaultValue={}
              onChange={(_, value) => {
                formik.setFieldValue('status', value?.value);
              }}
              fullWidth
              clearIcon={null}
            />
          </Grid>
        )}
      </Grid>

      <Grid item xs={12} md={12}>
        {isErrorAdd && <ErrorAlert message={errorAdd as string} handleDismiss={reset} />}

        {isErrorEdit && <ErrorAlert message={errorEdit as string} handleDismiss={resetEdit} />}

        {isSuccessAdd && (
          <SuccessAlert message={t('authors.add.success.message')} handleDismiss={reset} />
        )}

        {isSuccessEdit && (
          <SuccessAlert message={t('authors.edit.success.message')} handleDismiss={resetEdit} />
        )}
      </Grid>

      <Grid
        item
        container
        alignItems="center"
        justifyContent="center"
        spacing={2}
        sx={{ mt: 1 }}
        xs={12}
        md={6}
      >
        <Grid item>
          <Button
            variant="outlined"
            size="large"
            color="primary"
            onClick={() => {
              if (isModal && handleCloseModal) {
                handleCloseModal();
              } else {
                history.goBack();
              }
            }}
          >
            {isModal ? 'Cancelar' : 'Regresar'}
          </Button>
        </Grid>
        <Grid item>
          <Button
            type="submit"
            variant="contained"
            size="large"
            color="primary"
            onClick={() => formik.handleSubmit()}
            disabled={isLoadingAdd || isLoadingEdit}
            endIcon={isLoadingAdd || (isLoadingEdit && <CircularProgress size={20} />)}
          >
            {authorToEdit ? t('shared.edit_button.tooltip') : t('shared.add_button.tooltip')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AddAuthor;
