import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';

import SearchPaginatedTable, {
  FilterArrow,
} from '../../../shared/components/tables/SearchPaginatedTable';
import { useGetConfigProductsPriceQuery } from '../slices/configProductPriceApiSlice';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import EditButton from '../../../shared/components/tables/buttons/EditButton';
import DeleteButton from '../../../shared/components/tables/buttons/DeleteButton';
import AddButton from '../../../shared/components/tables/buttons/AddButton';
import {
  setConfigProductDeleteModal,
  setConfigProductSelected,
  setConfigProductList,
  updateConfigProductsTableStates,
} from '../slices/configProductPriceSlice';
import { useTableSearch } from '../../../../hooks/useTableSearch';
import { showWithDecimals } from '../../../shared/helpers';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@material-ui/core';
import SubCard from '../../../shared/components/cards/SubCard';

const ConfigProductPriceList = function () {
  const theme = useTheme();
  const history = useHistory();
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { defaultConfiguration } = useAppSelector((state) => state.access);

  const { page, perPage, search } = useAppSelector(
    (state) => state.configProducts.configProductsTable
  );

  const [filterArrow, setFilterArrow] = useState<FilterArrow>(null!);

  const { setPage, setPerPage, setSearch } = useTableSearch({
    page,
    perPage,
    search,
    updateFunction: updateConfigProductsTableStates,
  });

  const { currentProfile } = useAppSelector((state) => state.access);

  const { path } = useRouteMatch();

  const { data, isLoading, error, isFetching } = useGetConfigProductsPriceQuery({
    page: page,
    perPage: perPage,
    profileId: currentProfile?.profileId!,
    search: search,
    ...(filterArrow && {
      sort: filterArrow.name,
      type_sort: filterArrow.direction,
    }),
  });

  const dataFetch =
    data &&
    data.data.map((value, index) => ({
      coll: ' ',
      id: index + (page - 1) * perPage + 1,
      /*product: value?.product?.pr_description,*/
      product: value?.product?.pr_description,
      typeApp: value?.type_application?.typ_app_name || '-',
      offer: value?.offer?.off_name,
      period: '',
      process: value?.process?.pr_name,
      pc_gross_value: value?.pc_gross_value,
      pc_net_value: value?.pc_net_value,
      options: value,
    }));

  useEffect(() => {
    if (data) {
      dispatch(setConfigProductList(data.data));
    }
  }, [data, dispatch]);

  const handleDelete = (confProductId: number) => {
    dispatch(setConfigProductSelected(confProductId));
    dispatch(setConfigProductDeleteModal(true));
  };

  const handleAdd = () => {
    history.push(`${path}/agregar`);
  };

  const handleEdit = (configProductId: number) => {
    history.push(`${path}/editar/${configProductId}`);

    dispatch(setConfigProductSelected(configProductId));
  };

  return (
    <SearchPaginatedTable
      data={dataFetch!}
      headers={{
        coll: '',
        id: 'N°',
        product: t('financial.product_config.table.product'),
        typeApp: t('Tipo de certificado'),
        offer: t('financial.product_config.table.offer_edulevel'),
        period: t('financial.product_config.table.period'),
        process: t('financial.product_config.table.process'),
        pc_gross_value: t('financial.product_config.table.gross_price'),
        pc_net_value: t('financial.product_config.table.net_price'),
        options: t('shared.search_paginated_table.table.options'),
      }}
      listFilterArrows={{
        pc_gross_value: 'pc_gross_value',
        pc_net_value: 'pc_net_value',
      }}
      filterArrow={filterArrow}
      setFilterArrow={setFilterArrow}
      setPage={setPage}
      setSearch={setSearch}
      searchPlacehoder={t('financial.product_config.table.search_placeholder')}
      error={error}
      isFetching={isFetching}
      page={page}
      search={search}
      keyExtractor={(item) => item.id.toString()}
      isLoading={isLoading}
      total={data?.total!}
      perPage={perPage}
      setPerPage={setPerPage}
      numHeader={7}
      customRenderers={{
        offer: (row) => (
          <>
            <Typography variant="body2">{row.options.education_level?.edu_name}</Typography>
            <Typography variant="subtitle2">{row.offer}</Typography>
          </>
        ),
        period: (row) => (
          <>
            {!row.options.financial_period?.fp_description && !row.options.period?.per_name ? (
              <Typography variant="body2" textAlign="center">
                -
              </Typography>
            ) : (
              <>
                <Typography variant="body2">
                  {row.options.financial_period?.fp_description}
                </Typography>
                <Typography variant="subtitle2">{row.options.period?.per_name}</Typography>
              </>
            )}
          </>
        ),
        pc_gross_value: (row) => (
          <>
            {defaultConfiguration.currency_symbol}
            {showWithDecimals(parseFloat(row.pc_gross_value))}
          </>
        ),
        pc_net_value: (row) => (
          <>
            {defaultConfiguration.currency_symbol}
            {showWithDecimals(parseFloat(row.pc_net_value))}
          </>
        ),

        options: (row) => (
          <>
            <EditButton handleEdit={() => handleEdit(row.options.id)} />
            <DeleteButton handleDelete={() => handleDelete(row.options.id)} />
          </>
        ),
      }}
      customHeadersCellsProperties={{
        id: {
          align: 'center',
        },
        offer: {
          align: 'left',
        },
      }}
      customDataCellsProperties={{
        id: {
          align: 'center',
        },
        product: { align: 'center' },
        process: { align: 'center' },
        pc_gross_value: { align: 'center' },
        pc_net_value: { align: 'center' },
        options: { align: 'center' },
      }}
      ActionButtons={<AddButton size="large" onClick={handleAdd} />}
      collapsible={{
        collapsibleBtnColl: 0,
        Rows: (index, row) => (
          <>
            <Box
              margin={1}
              style={{
                width: '100^',
              }}
            >
              <SubCard content={false} sx={{ mb: 2 }}>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Grupo económico</TableCell>
                      <TableCell align="center">Convenio</TableCell>
                      {/* <TableCell align="center">Tipo de certificado</TableCell> */}
                      <TableCell align="center">Materia</TableCell>
                      <TableCell align="center">Malla</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow hover sx={{ backgroundColor: theme.palette.grey[200] }}>
                      <TableCell align="center">
                        {row?.options?.economic_group?.eco_gro_name || '-'}
                      </TableCell>
                      <TableCell align="center">
                        {row?.options?.agreement?.agr_name || '-'}
                      </TableCell>
                      {/* <TableCell align="center">
                          {row?.options?.type_application_id || '-'}
                        </TableCell> */}
                      <TableCell align="center">{row?.options?.subject?.mat_name || '-'}</TableCell>
                      <TableCell align="center">
                        {row?.options?.curriculum?.mes_name || '-'}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </SubCard>
            </Box>
          </>
        ),
      }}
    />
  );
};

export default ConfigProductPriceList;
