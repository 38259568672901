import linesResearch from './linesResearch.json';
import publication from './publication.json';
import menu from './menu.json';

const research = {
  ...menu,
  ...linesResearch,
  ...publication,
};

export default research;
