import { Grid } from '@material-ui/core';
import React from 'react';
import MainCard from '../../../../shared/components/cards/MainCard';
import EvaluationDetail from '../components/EvaluationDetail';

const EvaluationDetailScreen = () => {
  return (
    <React.Fragment>
      <Grid
        component={MainCard}
        sx={{
          borderTopLeftRadius: '0px',
          borderTopRightRadius: '0px',
        }}
      >
        <EvaluationDetail />
      </Grid>
    </React.Fragment>
  );
};

export default EvaluationDetailScreen;
