// material ui
import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LogoGif from '../../../assets/images/nbs-gif.gif';

// project imports
import MinimalLayout from '../layout/MinimalLayout';

// constants
import { gridSpacing } from '../constants/uiConstants';

// style constant
const useStyles = makeStyles({
  boxContainer: {
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    padding: '32px',
  },
});

const ProfilesScreen = () => {
  const classes = useStyles();

  return (
    <MinimalLayout>
      <Box className={classes.boxContainer}>
        <Grid container justifyContent="center" alignItems="center" spacing={gridSpacing}>
          <img src={LogoGif} width="95px" height="95px" alt="Logo" />
        </Grid>
      </Box>
    </MinimalLayout>
  );
};

export default ProfilesScreen;
