import {
  useHistory,
  //Link,
  useRouteMatch,
} from 'react-router-dom';
import { Button, Grid, IconButton, Tooltip } from '@material-ui/core';
import useGuard from '../hooks/useGuard';
import { DateRange } from '@material-ui/lab';

import SearchPaginatedTable from '../../../shared/components/tables/SearchPaginatedTable';
import { useEffect, useState } from 'react';

import { formatDistance, format } from 'date-fns';
import { es } from 'date-fns/locale';
import AddButton from '../../../shared/components/tables/buttons/AddButton';
import {
  useLazyGetLendingsQuery,
  // useReturnLendingMutation,
} from '../../lendings/slices/lendingApiSlice';
import { IconArrowBackUp } from '@tabler/icons';
// import DialogConfirm from '../../../students/virtual-classroom/evaluations/components/detail/Dialog';
import { useAppSelector } from '../../../../hooks/useRedux';
import { useTableSearch } from '../../../../hooks/useTableSearch';
import { updateRefundsTable } from '../slices/refundsSlice';
import LocalizedDateRangePicker from '../../../shared/components/controlled/LocalizedDateRangePicker';
import RefundDialog from './RefundDialog';

const RefundsTable = () => {
  const { hasAccess } = useGuard();
  const { path } = useRouteMatch();
  const history = useHistory();

  const { page, perPage, search, filterArrow } = useAppSelector(
    (state) => state.returns.refundsTable
  );

  const { setPage, setPerPage, setSearch, setFilterArrow } = useTableSearch({
    page,
    perPage,
    search,
    filterArrow,
    updateFunction: updateRefundsTable,
  });

  const [startEndDate, setStartEndDate] = useState<DateRange<Date>>([new Date(), null]);
  const [lendingCode, setLendingCode] = useState<string | undefined>();

  const [getLendings, { data: lendings, isLoading, isFetching, isError, error }] =
    useLazyGetLendingsQuery();

  const goToAdd = () => {
    history.push(`${path}/agregar`);
  };

  // const [
  //   returnLending,
  //   {
  //     isLoading: isLoadingReturn,
  //     isError: isErrorReturn,
  //     error: errorReturn,
  //     isSuccess: isSuccessReturn,
  //   },
  // ] = useReturnLendingMutation();

  const handleGetLendings = () => {
    getLendings({
      borrow_start_date: format(
        new Date(startEndDate[0] ? startEndDate[0] : new Date()),
        'yyyy-MM-dd'
      ),
      borrow_end_date: startEndDate[1]
        ? format(new Date(startEndDate[1]), 'yyyy-MM-dd')
        : undefined,
      not_return: 1,
      page,
      size: perPage,
      search,
      ...(filterArrow && { sort: filterArrow.name, type_sort: filterArrow.direction }),
    });
  };

  // const handleReturn = () => {
  //   const id = lendings?.data.find((ld) => ld.borrow_code === lendingCode)?.id;

  //   try {
  //     if (id) {
  //       returnLending({
  //         borrows: [id],
  //         return_date: format(new Date(), 'yyy-MM-dd'),
  //       });
  //     }
  //   } catch (error) {
  //     alert(String(error));
  //   }
  // };

  useEffect(() => {
    getLendings({
      borrow_start_date: format(
        new Date(startEndDate[0] ? startEndDate[0] : new Date()),
        'yyyy-MM-dd'
      ),
      borrow_end_date: startEndDate[1]
        ? format(new Date(startEndDate[1]), 'yyyy-MM-dd')
        : undefined,
      not_return: 1,
      page,
      size: perPage,
      search,
      ...(filterArrow && { sort: filterArrow.name, type_sort: filterArrow.direction }),
    });
    // eslint-disable-next-line
  }, [filterArrow, page, perPage, search, getLendings]);

  return (
    <>
      <Grid container justifyContent="flex-end" spacing={2}>
        <Grid item xs={6} md={6}>
          <Grid item xs={12}>
            <LocalizedDateRangePicker
              startText="Fecha de Inicio"
              endText="Fecha de Fin"
              value={startEndDate}
              onChange={(newValue) => {
                setStartEndDate(newValue);
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} lg={12} textAlign="right">
          <Button variant="contained" size="medium" color="primary" onClick={handleGetLendings}>
            Buscar
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item md={12}>
          <SearchPaginatedTable
            data={
              lendings?.data.map((ld, index) => ({
                id: index + Number(lendings?.from),
                code: ld.borrow_code,
                books: ld.model_data?.title,
                type: ld.type,
                type_user: ld.user_profile.profile.pro_name,
                borrow_date: ld.borrow_date,
                return_date: ld.estimated_return_date || '-',
                days_lefts: ld.estimated_return_date
                  ? formatDistance(new Date(ld.estimated_return_date), new Date(), {
                      addSuffix: true,
                      locale: es,
                    })
                  : '-',
                //ammount: ld.borrow_books_amount || '-',
                user:
                  ld.user_profile.user?.person.pers_firstname +
                  ' ' +
                  ld.user_profile.user?.person.pers_first_lastname,
                options: '',
              })) || []
            }
            headers={{
              id: 'Nº',
              code: 'Código de Préstamo',
              books: 'Libro',
              type: 'Tipo de Préstamo',
              type_user: 'Tipo de Usuario',
              borrow_date: 'Fecha de Préstamo',
              return_date: 'Fecha de devolución',
              days_lefts: 'Días Restantes',
              //ammount: 'Cantidad',
              user: 'Usuario',
              options: 'Opciones',
            }}
            keyExtractor={(item) => item.code.toString()}
            searchPlacehoder="Buscar devolución"
            listFilterArrows={{
              books: 'books',
              borrow_date: 'borrow_date',
              return_date: 'return_date',
              type: 'type',
              type_user: 'user_profile',
              //ammount: 'borrow_books_amount',
              user: 'user',
            }}
            filterArrow={filterArrow}
            setFilterArrow={setFilterArrow}
            isLoading={isLoading}
            isFetching={isFetching}
            error={isError ? error : undefined}
            perPage={perPage}
            setPerPage={setPerPage}
            total={lendings?.total!}
            page={page}
            setPage={setPage}
            numHeader={10}
            search={search}
            setSearch={setSearch}
            customRenderers={{
              options: (row) => (
                <Tooltip title="Devolver">
                  <IconButton
                    color="primary"
                    onClick={async () => {
                      await setLendingCode(row.code);
                    }}
                  >
                    <IconArrowBackUp />
                  </IconButton>
                </Tooltip>
              ),
            }}
            ActionButtons={
              <>
                {hasAccess('create') && (
                  <AddButton btnText="Nuevo Prestamo" size="large" onClick={goToAdd} />
                )}
              </>
            }
            customDataCellsProperties={{
              id: { align: 'center' },
              code: { align: 'center' },
              type: { align: 'center' },
              type_user: { align: 'center' },
              borrow_date: { align: 'center' },
              return_date: { align: 'center' },
              days_lefts: { align: 'center' },
              //ammount: { align: 'center' },
              user: { align: 'center' },
            }}
          />
        </Grid>
      </Grid>

      {Boolean(lendingCode) && (
        <RefundDialog
          lendings={lendings}
          lendingCode={lendingCode as string}
          handleClose={() => {
            setLendingCode(undefined);
            handleGetLendings();
          }}
        />
      )}

      {/* {Boolean(lendingCode) && (
        <DialogConfirm
          error={String(errorReturn)}
          handleAction={handleReturn}
          isError={isErrorReturn}
          isLoading={isLoadingReturn}
          isSuccess={isSuccessReturn}
          onClose={() => {
            setLendingCode(undefined);
            handleGetLendings();
          }}
          open={Boolean(lendingCode)}
          actionTitle="¿Está seguro de devolver este préstamo?"
          actionSubtitle="Se devolvera el préstamo seleccionado"
          buttonTitle="Sí, devolver"
          successTitle="Se realizó la devolución correctamente"
          successButtonTitle="Aceptar"
        />
      )} */}
    </>
  );
};

export default RefundsTable;
