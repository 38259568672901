import { mainApi } from '../../../../api/mainApi';
import { AccessState } from '../../../../types';
import { middlewareApi } from '../../../middleware/middlewareApi';
import { CalificationTypeAvailable } from '../interfaces/calificationTypes.interfaces';

export const calificationTypesTags = middlewareApi.enhanceEndpoints({
  addTagTypes: ['CalificationTypes'],
});

export const calificationTypesApi = calificationTypesTags.injectEndpoints({
  endpoints: (build) => ({
    /**
     * @GET all calification types to combo box
     */
    getCalificationTypes: build.query<CalificationTypeAvailable[], { profileId?: number }>({
      queryFn: async ({ profileId }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = currentProfile?.profileId;
          const { data } = await mainApi.get<CalificationTypeAvailable[]>('/type-califications', {
            params: {
              user_profile_id: profileId || user_profile_id,
              data: 'all',
            },
          });

          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
    }),
  }),
});

export const { useGetCalificationTypesQuery } = calificationTypesApi;
