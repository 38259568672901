import { Switch, Redirect } from 'react-router-dom';

// import GuardRoute from '../../../../router/guards/GuardRoute';
import CollaboratorsScreen from '../screens/CollaboratorsScreen';
import AddCollaboratorScreen from '../screens/AddCollaboratorScreen';

import EditCollaboratorDataScreen from '../screens/EditCollaboratorDataScreen';
import EditPersonalDataScreen from '../screens/EditPersonalDataScreen';
import { useAppSelector } from '../../../../hooks/useRedux';

import { permissionKey, subPermissionKeys } from '../constants/permissions';
import GuardRoute from '../../../../router/guards/GuardRoute';

const CollaboratorsRouter = () => {
  const { currentModule } = useAppSelector((state) => state.access);

  const hasPermission = 'collaborator-rrhh' in currentModule?.permissions!;

  if (!hasPermission) return <Redirect to="/dashboard" />;

  return (
    <Switch>
      <GuardRoute
        exact
        path="/administrar-colaboradores"
        component={CollaboratorsScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.list}
      />
      <GuardRoute
        exact
        path="/administrar-colaboradores/agregar"
        component={AddCollaboratorScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.create}
      />
      <GuardRoute
        exact
        path="/administrar-colaboradores/:collaboratorId/editar-colaborador"
        component={EditCollaboratorDataScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.edit}
      />
      <GuardRoute
        exact
        path="/administrar-colaboradores/:personId/editar-personal"
        component={EditPersonalDataScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.edit}
      />
    </Switch>
  );
};

export default CollaboratorsRouter;
