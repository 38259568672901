import { useEffect, useState } from 'react';

import { useRouteMatch } from 'react-router-dom';
import { IconFileUpload, IconFileText, IconX } from '@tabler/icons';
import {
  Link as LinkComponent,
  Grid,
  TextField,
  Autocomplete,
  Button,
  InputAdornment,
  IconButton,
} from '@material-ui/core';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import SearchPaginatedTable, {
  FilterArrow,
} from '../../../shared/components/tables/SearchPaginatedTable';
import { useGetAcademicRequestsQuery } from '../slices/academicRequestsApiSlice';
import DeleteButton from '../../../shared/components/tables/buttons/DeleteButton';
import { openDeleteModalWith } from '../slices/academicRequestsSlice';
import InfoButton from '../../../shared/components/tables/buttons/InfoButton';
import { Link } from 'react-router-dom';
import useGuard from '../hooks/useGuard';
import { getAppCode } from '../helpers';
import { ApplicationsType } from '../../../shared/interfaces/applications.interface';
import CustomIconButton from '../../../shared/components/tables/buttons/CustomIconButton';
import { useControlModalStates } from '../../../shared/hooks/useControlModalStates';

import UploadRecordStudent from './Builder/UploadRecordStudent';
import { baseUrl } from '../../../../api/mainApi';

import { useTranslation } from 'react-i18next';
import Chip from '../../../shared/components/extended/Chip';
import { DateRange, DateRangePicker, LocalizationProvider } from '@material-ui/lab';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import { format } from 'date-fns';
import { AcroType } from '../../../maintenance/general-applications/constants/types';
import { useGetApplicationStatusByAcronymQuery } from '../../../maintenance/requests-status/slices/requestsStatusApiSlice';
import { appTypes } from '../../../maintenance/general-applications/constants/mockOptions';
import TextFieldFetching from '../../../shared/components/TextFieldFetching';
import { useGetLocaleDateLang } from '../../../../translation/hooks/useGetLocaleDateLang';

const AcademicRequestsTable = () => {
  const { t } = useTranslation();

  const { lang } = useGetLocaleDateLang();

  const { currentProfile } = useAppSelector((state) => state.access);
  const { userInfo } = useAppSelector((state) => state.auth);

  const dispatch = useAppDispatch();
  const { path } = useRouteMatch();

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const [search, setSearch] = useState('');

  const [value, setValue] = useState<DateRange<Date>>([null, null]);
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();
  const [appType, setAppType] = useState<AcroType['types'] | undefined>(undefined);
  const [appState, setAppState] = useState<string | undefined>(undefined);
  const [filter, setFilter] = useState<boolean>(false);

  const {
    data: appStatuses,
    isLoading: isLoadingAppStatuses,
    isFetching: isFetchingStatuses,
  } = useGetApplicationStatusByAcronymQuery(
    {
      user_profile_id: currentProfile?.profileId!,
      acronym: appType || '',
    },
    {
      skip: !appType || !currentProfile?.profileId,
    }
  );

  const [filterArrow, setFilterArrow] = useState<FilterArrow>({ name: 'id', direction: 'desc' });
  const { hasAccess } = useGuard();

  useEffect(() => {
    setPage(1);
  }, [perPage, search]);

  const { open, dataId, handleOpenModalWith, handleCloseModal } = useControlModalStates();

  const { isLoading, data, error, isFetching, refetch } = useGetAcademicRequestsQuery(
    {
      profileId: currentProfile?.profileId!,
      userId: String(userInfo?.id!),
      page,
      perPage,
      search,
      ...(filter && { acronym: appType }),
      ...(filter && startDate && endDate && { startDate: startDate }),
      ...(filter && endDate && startDate && { endDate: endDate }),
      ...(filter && { status: appState }),

      ...(filterArrow && { sort: filterArrow.name, type_sort: filterArrow.direction }),
    },
    { refetchOnMountOrArgChange: 120 }
  );

  const handleDelete = async (applicationId: number) => {
    dispatch(openDeleteModalWith(applicationId));
  };

  const datafetch = data?.data!.map((d) => ({
    id: d.id,
    code: d.app_code,
    typeApplication: d.type_application,
    ApplicationregisterDate: d.app_register_date,
    statusName: d.current_status,
    options: d,
  }));

  //filtros
  const handleFilter = () => {
    value[0] && !isNaN(value[0].getTime()) && setStartDate(format(value[0], 'yyyy-MM-dd'));
    value[1] && !isNaN(value[1].getTime()) && setEndDate(format(value[1], 'yyyy-MM-dd'));
    // if (filterStatus) setStatus(filterStatus);

    if (filter) refetch();
    else setFilter(true);
  };
  const handleClearFilter = () => {
    setValue([null, null]);
    setStartDate('');
    setEndDate('');

    // setStatus('');

    setAppType(undefined);
    setAppState(undefined);

    setFilter(false);
  };
  const onChangeDates = (newDatesValues: DateRange<Date>) => {
    setValue(newDatesValues);
    // resetTable();
  };

  return (
    <>
      <SearchPaginatedTable
        actBtnFullWith
        data={datafetch!}
        headers={{
          id: 'N°',
          code: `${t('students.applications.academic_applications.applications_table.code')}`,
          typeApplication: `${t(
            'students.applications.academic_applications.applications_table.type'
          )}`,
          ApplicationregisterDate: `${t(
            'students.applications.academic_applications.applications_table.register_date'
          )}`,
          statusName: `${t(
            'students.applications.academic_applications.applications_table.state'
          )}`,
          options: `${t('students.applications.academic_applications.applications_table.options')}`,
        }}
        keyExtractor={(item) => item.id.toString()}
        searchPlacehoder={t(
          'students.applications.academic_applications.applications_table.search'
        )}
        filterArrow={filterArrow}
        setFilterArrow={setFilterArrow}
        isLoading={isLoading}
        isFetching={isFetching}
        error={error}
        perPage={perPage}
        setPerPage={setPerPage}
        total={data?.total || 0}
        page={page}
        setPage={setPage}
        numHeader={6}
        search={search}
        setSearch={setSearch}
        customRenderers={{
          options: (row) => (
            <>
              {hasAccess('get') && (
                <Link to={`${path}/${row.code}/info`}>
                  <InfoButton />
                </Link>
              )}
              {hasAccess('delete') &&
                (row.statusName === 'ENVIADO' || row.statusName === 'Enviado') && (
                  <DeleteButton handleDelete={() => handleDelete(row.id)} />
                )}
              {!hasAccess('get') &&
                !hasAccess('delete') &&
                (row.statusName === 'ENVIADO' || row.statusName === 'Enviado') &&
                'Ninguna'}
              {getAppCode(row.code) === ApplicationsType.HOMEXT && row.options.hasFile && (
                <LinkComponent
                  href={`${baseUrl}/show?name=${row.options.file_url}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <CustomIconButton
                    title="Ver récord estudiantil"
                    color="default"
                    Icon={IconFileText}
                  />
                </LinkComponent>
              )}
              {getAppCode(row.code) === ApplicationsType.HOMEXT && (
                <CustomIconButton
                  title="Cargar récord estudiantil"
                  Icon={IconFileUpload}
                  handleClick={() => handleOpenModalWith(row.id)}
                />
              )}
            </>
          ),
          statusName: (row) => (
            <Chip
              label={row.options.current_status}
              size="small"
              // chipcolor={label === 'En proceso' ? 'warning' : 'success'}
              chipcolor={
                row.options.current_status === 'Enviado'
                  ? 'info'
                  : row.options.current_status === 'Rechazado'
                  ? 'error'
                  : 'success'
              }
            />
          ),
        }}
        customDataCellsProperties={{
          id: {
            align: 'center',
          },
          code: {
            align: 'center',
          },
          typeApplication: {
            align: 'center',
          },
          ApplicationregisterDate: {
            align: 'center',
          },
          statusName: {
            align: 'center',
          },
          options: {
            align: 'center',
          },
        }}
        ActionButtons={
          <>
            <Grid container spacing={1} justifyContent="flex-end">
              <Grid item xs={12} sm={6} md={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={lang}>
                  <DateRangePicker
                    calendars={1}
                    startText="Fecha Inicio"
                    endText="Fecha Fin"
                    value={value}
                    onChange={onChangeDates}
                    PopperProps={{ style: { ...{ left: '-400px' } } }}
                    renderInput={(startProps, endProps) => (
                      <Grid container spacing={1} alignItems="center">
                        <Grid item xs={6}>
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              {...startProps}
                              helperText=""
                              value={value[0]}
                              InputProps={{
                                readOnly: true,
                                endAdornment: value?.[0] && (
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={() => {
                                        setValue([null, null]);
                                        setStartDate('');
                                        setEndDate('');
                                      }}
                                    >
                                      <IconX size={16} />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                              onKeyPress={(e) => e.preventDefault()}
                              onKeyDown={(e) => e.preventDefault()}
                            />
                          </Grid>
                        </Grid>

                        <Grid item xs={6}>
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              {...endProps}
                              helperText=""
                              value={value[1]}
                              InputProps={{
                                readOnly: true,
                                endAdornment: value?.[1] && (
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={() => {
                                        setValue([null, null]);
                                        setStartDate('');
                                        setEndDate('');
                                      }}
                                    >
                                      <IconX size={16} />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                              onKeyPress={(e) => e.preventDefault()}
                              onKeyDown={(e) => e.preventDefault()}
                              disabled={!value?.[0]}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <Autocomplete
                  options={appTypes}
                  loadingText="Cargando solicitudes..."
                  getOptionLabel={(option) => option.value}
                  renderInput={(params) => <TextField {...params} label="Tipo de solicitud" />}
                  value={appTypes.find((dt) => dt.acro === appType) || null}
                  onChange={(_, value) => {
                    setAppType(value?.acro || undefined);
                    setAppState(undefined);
                    // resetTable();
                  }}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                {(isLoadingAppStatuses || isFetchingStatuses) && <TextFieldFetching />}
                {!isLoadingAppStatuses && !isFetchingStatuses && (
                  <Autocomplete
                    options={appStatuses || []}
                    loadingText="Cargando estados..."
                    loading={isLoadingAppStatuses || isFetchingStatuses}
                    getOptionLabel={(option) => option.status_name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={appType ? 'Estado' : 'Seleccione un tipo de solicitud'}
                      />
                    )}
                    value={appStatuses?.find((dt) => dt.status_name === appState) || null}
                    onChange={(_, value) => {
                      const newValue = value?.status_name;
                      setAppState(newValue || undefined);
                      // resetTable();
                    }}
                    disabled={isLoadingAppStatuses || isFetchingStatuses || !appType}
                    fullWidth
                  />
                )}
                {/* )} */}
              </Grid>

              <Grid container justifyContent="flex-end" spacing={1} mt={1}>
                <Grid item xs={'auto'}>
                  <Button variant={'outlined'} onClick={handleClearFilter}>
                    Limpiar
                  </Button>
                </Grid>
                <Grid item xs={'auto'}>
                  <Button variant={'outlined'} onClick={handleFilter}>
                    Buscar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </>
        }
      />

      {open && (
        <UploadRecordStudent
          applicationId={dataId}
          dialogProps={{ open, onClose: handleCloseModal }}
        />
      )}
    </>
  );
};
export default AcademicRequestsTable;
