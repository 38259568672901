import {
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../hooks/useRedux';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import {
  useAddPosgraduatePlanningDayTypeMutation,
  useUpdatePosgraduatePlanningDayTypeMutation,
} from '../slices/posgraduatePlanningDayTypesApiSlice';

const PosgraduatePlanningDayTypesForm = () => {
  const { t } = useTranslation();
  const { currentProfile } = useAppSelector((state) => state.access);

  const { posgraduatePlanningDayTypesList, posgraduatePlanningDayTypeIdSelected } = useAppSelector(
    (state) => state.posgraduatePlanningDayTypes
  );

  const posgraduateDayType = posgraduatePlanningDayTypesList.find(
    (item) => item.id === posgraduatePlanningDayTypeIdSelected
  );

  const [showAlert, setShowAlert] = useState(false);

  const [
    ,
    {
      isLoading: isLoadingAddDayType,
      isSuccess: isSuccessAdd,
      isError: isErrorAdd,
      error: errorAdd,
    },
  ] = useAddPosgraduatePlanningDayTypeMutation();
  const [
    updateDayType,
    {
      isLoading: isLoadingUpdateDayType,
      isSuccess: isSuccessUpdate,
      isError: isErrorUpdate,
      error: errorUpdate,
    },
  ] = useUpdatePosgraduatePlanningDayTypeMutation();

  const { errors, touched, getFieldProps, handleSubmit } = useFormik({
    initialValues: {
      description: posgraduateDayType?.pdt_description_day || '',
      color: posgraduateDayType?.pdt_colour || '',
      keyword: posgraduateDayType?.pdt_keywork || '',
      numberOfDays: posgraduateDayType?.pdt_number_of_days || '0',
      status: posgraduateDayType?.status || '',
    },
    onSubmit: async (values) => {
      if (posgraduateDayType?.id) {
        await updateDayType({
          profileId: currentProfile?.profileId!,
          id: posgraduateDayType?.id,
          description: values.description,
          color: values.color,
          keyword: values.keyword,
          numberOfDays: Number(values.numberOfDays),
          status: values.status,
        });
      } else {
        // await addDayType({
        //   profileId: currentProfile?.profileId!,
        //   modalityId: values.modalityId,
        //   dayTypeId: values.dayTypeId,
        // });
      }

      setShowAlert(true);
    },
  });

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        {showAlert && (isSuccessAdd || isSuccessUpdate) && (
          <Grid item xs={12}>
            <SuccessAlert
              handleDismiss={() => setShowAlert(false)}
              message={
                isSuccessAdd
                  ? t('posgraduate.day_types.add.success')
                  : t('posgraduate.day_types.edit.success')
              }
            />
          </Grid>
        )}
        {showAlert && (isErrorAdd || isErrorUpdate) && (
          <Grid item xs={12}>
            <ErrorAlert
              handleDismiss={() => setShowAlert(false)}
              message={isErrorAdd ? (errorAdd as string) : (errorUpdate as string)}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <InputLabel>{t('posgraduate.day_types.form.description')}</InputLabel>

          <TextField
            fullWidth
            placeholder={t('posgraduate.day_types.form.description.placeholder')}
            {...getFieldProps('description')}
            error={Boolean(touched.description && errors.description)}
          />

          {errors.description && touched.description && (
            <FormHelperText error>{errors.description}</FormHelperText>
          )}
        </Grid>
        <Grid item xs={12}>
          <InputLabel>{t('posgraduate.day_types.form.color')}</InputLabel>
          <TextField type="color" {...getFieldProps('color')} fullWidth />
          {errors.color && touched.color && <FormHelperText error>{errors.color}</FormHelperText>}
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={isLoadingAddDayType || isLoadingUpdateDayType}
            endIcon={
              isLoadingAddDayType || isLoadingUpdateDayType ? <CircularProgress size={14} /> : null
            }
          >
            {t('posgraduate.day_types.form.save')}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default PosgraduatePlanningDayTypesForm;
