import { Switch, Redirect } from 'react-router-dom';

import { useAppSelector } from '../../../../hooks/useRedux';

import HourHandsScreen from '../screens/HourHandsScreen';
import AddHourHandScreen from '../screens/AddHourhandScreen';
import EditHourHandScreen from '../screens/EditHourhandScreen';
import GuardRoute from '../../../../router/guards/GuardRoute';
import { subPermissionKeys } from '../constants/permissions';

const { groupKey: permissionKey } = subPermissionKeys;

const HourhandsRouter = () => {
  const { currentModule } = useAppSelector((state) => state.access);

  const hasPermission = 'hourhand' in currentModule?.permissions!;

  if (!hasPermission) return <Redirect to="/dashboard" />;

  return (
    <Switch>
      <GuardRoute
        exact
        path="/administrar-horarios"
        component={HourHandsScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.read}
      />

      <GuardRoute
        exact
        path="/administrar-horarios/agregar"
        component={AddHourHandScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.create}
      />

      <GuardRoute
        exact
        path="/administrar-horarios/:hourhandId/editar"
        component={EditHourHandScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.edit}
      />
    </Switch>
  );
};

export default HourhandsRouter;
