import { useEffect } from 'react';

import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/core/Alert';
import Typography from '@material-ui/core/Typography';
import { useLocation } from 'react-router-dom';

import CustomIcon from '../illustrations/CustomIcon';
import errorImg from '../../../../assets/images/illustrations/image-no-information.png';
import { useReportErrorMutation } from '../../slices/errorLogApi';

const ErrorFallbackComponentScreen = ({ error }: { error: Error }) => {
  const { pathname } = useLocation();
  // let img = '';
  // try {
  //   img = 'https://i.ibb.co/gwkK0hc/kindpng-4859175.png'; //perrito cheems :D
  // } catch (error) {
  //   img = '';
  // }

  // console.log('mensaje de error', error);

  const [reportError] = useReportErrorMutation();

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      console.log('===============lanzando peticion===================');

      const { message, name } = error;

      reportError({
        logPayload: {
          detail: message,
          exception: name,
          path: pathname,
          file: null,
          line: null,
        },
      });
    }
  }, [error, pathname, reportError]);

  return (
    <Grid
      container
      p={5}
      alignItems="center"
      justifyContent="center"
      height={'50vh'}
      direction="column"
      spacing={2}
    >
      <Grid item>
        <CustomIcon icon={errorImg} customWidth={300} />
      </Grid>
      <Grid item>
        <Alert severity="error">
          <Typography>
            Lo sentimos, Ha ocurrido un error. Ya nos hemos puesto en contacto con el administrador.
          </Typography>
        </Alert>
      </Grid>
    </Grid>
  );
};

export default ErrorFallbackComponentScreen;
