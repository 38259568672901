export const returnNewUrlVc = (url: string): string => {
  // Existe la posibilidad de que las url de aula virtual vengan con un / inicial cuando el hook que redirige al aula virtual ya setea un /
  const newUrl = url[0] === '/' ? url.slice(1) : url;

  // Existe la posibilidad de que las url venga con la ruta configurada ya en el hook, se la quitaria de la peticion
  const newUrlSplit = newUrl.split('/');
  return newUrlSplit[0] === 'aula-virtual-estudiante' ||
    newUrlSplit[0] === 'administrar-aula-virtual'
    ? newUrlSplit?.slice(1).join('/')
    : newUrl;
};
