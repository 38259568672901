interface Ambiente {
    id: number,
    name: string,
}
export const ambienteAvailables: Ambiente[] = [
  {
    id: 1,
    name: 'Desarrollo',
  },
  {
    id: 2,
    name: 'Producción',
  },
];