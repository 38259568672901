import {
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
  TextFieldProps,
  Typography,
} from '@material-ui/core';
import {
  Field,
  FieldArray,
  FormikErrors,
  FormikProvider,
  FormikTouched,
  useFormikContext,
} from 'formik';
import { useCallback } from 'react';
import { PeriodForm, Stage } from '../../../interfaces/period-form.interfaces';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import esLocale from 'date-fns/locale/es';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import DateRangePicker, { DateRange } from '@material-ui/lab/DateRangePicker';
import { GENERACION_CUOTAS } from '../../../constants/stages';
import { StageGenerationQuote } from '../../../constants/validationSchemas';
import { useTranslation } from 'react-i18next';

const GenerationQuote = () => {
  const { t } = useTranslation();

  const { setFieldValue, values, errors, touched } = useFormikContext<PeriodForm>();

  const formik = useFormikContext<PeriodForm>();
  formik.validationSchema = StageGenerationQuote;

  const arrayPeriodStagesDates = useCallback((quotas: number) => {
    const initialDate: Stage = {
      start_date: undefined,
      end_date: undefined,
      education_level_id: null,
      course_id: null,
      parallel_id: null,
      status_id: 1,
      stg_acronym: 'RC',
    };
    let initialDates: Stage[] = [];
    for (var i = 0; i < quotas; i++) {
      initialDates.push(initialDate);
    }

    return initialDates;
  }, []);

  const onChangeDatesArray = (
    newDatesValues: DateRange<Date>,
    index: number,
    courseId?: number | null
  ) => {
    const [start, end] = newDatesValues;
    // update formik values
    const exists = values.generation_quote.find((e, current) => current === index);
    if (!exists) {
      setFieldValue(`generation_quote[${index}].course_id`, null);
      setFieldValue(`generation_quote[${index}].education_level_id`, null);
      setFieldValue(`generation_quote[${index}].parallel_id`, null);
      setFieldValue(`generation_quote[${index}].status_id`, 1);
      setFieldValue(`generation_quote[${index}].stg_acronym`, GENERACION_CUOTAS);
    }

    if (start !== null) {
      setFieldValue(`generation_quote[${index}].start_date`, start);
    }

    if (end !== null) {
      setFieldValue(`generation_quote[${index}].end_date`, end);
    }
  };

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="flex-start">
      <Grid item xs={12} md={12} container spacing={2}>
        <Grid item xs={12} marginTop={2}>
          <Typography variant="h3" component="div">
            {t('academic.periods_list.add.stage_3.tab_1.title')}
          </Typography>
        </Grid>

        <Grid item xs={12} sx={{ mt: 2 }}>
          <Typography variant="h4" component="div" sx={{ mb: 3 }}>
            {t('academic.periods_list.add.stage_3.tab_1.select_dates')}
          </Typography>
          {arrayPeriodStagesDates(values.numFees).map((e, index) => (
            <Grid item key={`generation_quote-${index}-dates`}>
              <FormikProvider value={formik}>
                <FieldArray
                  name="generation_quote"
                  render={(arrayHelpers) => (
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                      <Field
                        name={`generation_quote[${index}]`}
                        component={DateRangePicker}
                        startText={null}
                        endText={null}
                        value={[
                          values?.generation_quote[index]?.start_date || null,
                          values?.generation_quote[index]?.end_date || null,
                        ]}
                        onChange={(e: DateRange<Date>) => {
                          onChangeDatesArray(e, index, null);
                          // if (e[0] && e[1] === null) {
                          //   const startDateDay = e[0]?.getDate();
                          //   const startDateMonth = e[0]?.getMonth();

                          //   if (startDateDay > values.feeCollectionDay) {
                          //     const endDate = new Date(
                          //       e[0]?.getFullYear(),
                          //       startDateMonth + 1,
                          //       values.feeCollectionDay
                          //     );
                          //     onChangeDatesArray([e[0], endDate], index, null);
                          //   } else {
                          //     const endDate = new Date(
                          //       e[0]?.getFullYear(),
                          //       startDateMonth,
                          //       values.feeCollectionDay
                          //     );
                          //     onChangeDatesArray([e[0], endDate], index, null);
                          //   }
                          // } else {
                          // }
                        }}
                        renderInput={(startProps: TextFieldProps, endProps: TextFieldProps) => (
                          <Grid container direction="row">
                            <Grid
                              container
                              spacing={2}
                              sx={{ mt: 2, mb: 1 }}
                              justifyContent="left"
                              alignItems="center"
                            >
                              <Grid item xs={12} md={6} lg={6}>
                                <InputLabel>
                                  {t(
                                    'academic.periods_list.add.stage_3.tab_1.select_dates.placeholder'
                                  )}{' '}
                                  {t('academic.periods_list.add.stage_3.tab_1.select_dates.quote')}{' '}
                                  #<strong>{index + 1}</strong>
                                </InputLabel>
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              spacing={10}
                              sx={{ mb: 2 }}
                              justifyContent="left"
                              alignItems="center"
                            >
                              <Grid item xs={12} md={6} lg={6}>
                                <InputLabel>
                                  {t('academic.periods_list.add.stage_3.tab_1.select_dates.start')}
                                </InputLabel>
                                <TextField
                                  fullWidth
                                  {...startProps}
                                  helperText=""
                                  disabled={values?.disabled}
                                  error={Boolean(
                                    (errors?.generation_quote as FormikErrors<Stage>[])?.[index]
                                      ?.start_date &&
                                      (touched?.generation_quote as FormikTouched<Stage>[])?.[index]
                                        ?.start_date
                                  )}
                                />
                                <FormHelperText>
                                  {t(
                                    'academic.periods_list.add.stage_3.tab_1.select_dates.start.helper'
                                  )}
                                </FormHelperText>
                                {(errors?.generation_quote as FormikErrors<Stage>[])?.[index]
                                  ?.start_date &&
                                  (touched?.generation_quote as FormikTouched<Stage>[])?.[index]
                                    ?.start_date && (
                                    <FormHelperText error>
                                      {
                                        (errors?.generation_quote as FormikErrors<Stage>[])?.[index]
                                          ?.start_date
                                      }
                                    </FormHelperText>
                                  )}
                              </Grid>

                              <Grid item xs={12} md={6} lg={6}>
                                <InputLabel>
                                  {t('academic.periods_list.add.stage_3.tab_1.select_dates.end')}:
                                </InputLabel>

                                <TextField
                                  fullWidth
                                  {...endProps}
                                  helperText=""
                                  error={Boolean(
                                    (errors?.generation_quote as FormikErrors<Stage>[])?.[index]
                                      ?.end_date &&
                                      (touched?.generation_quote as FormikTouched<Stage>[])?.[index]
                                        ?.end_date
                                  )}
                                  disabled={values?.disabled}
                                />
                                <FormHelperText>
                                  {t(
                                    'academic.periods_list.add.stage_3.tab_1.select_dates.end.helper'
                                  )}
                                </FormHelperText>
                                {(errors?.generation_quote as FormikErrors<Stage>[])?.[index]
                                  ?.end_date &&
                                  (touched?.generation_quote as FormikTouched<Stage>[])?.[index]
                                    ?.end_date && (
                                    <FormHelperText error>
                                      {
                                        (errors?.generation_quote as FormikErrors<Stage>[])?.[index]
                                          ?.end_date
                                      }
                                    </FormHelperText>
                                  )}
                              </Grid>
                            </Grid>
                          </Grid>
                        )}
                      />
                    </LocalizationProvider>
                  )}
                />
              </FormikProvider>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default GenerationQuote;
