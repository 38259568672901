import { FC, useEffect, useState } from 'react';
// import {
//   //useHistory,
//   useLocation } from 'react-router-dom';

import {
  Grid,
  Typography,
  TextField,
  Button,
  FormHelperText,
  useTheme,
  CircularProgress,
  Stepper,
  Step,
  StepLabel,
  Autocomplete,
  Stack,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';

import { DetailsProps } from '../../constants/types';
import InputLabel from '../../../../shared/components/forms/InputLabel';
import { openRejectAppModalWith } from '../../slices/GeneralApplicationsSlice';
import {
  ChangeStatusAppPractice,
  ChangeStatusAppPracticeNew,
} from '../../constants/validation-schemas';
import { useAppSelector } from '../../../../../hooks/useRedux';
import {
  BossDetail,
  CompanieDetail,
  Practice as PracticeInt,
  ValuePracticeDetail,
  GeneralApplication,
} from '../../interfaces/generalApplications.interfaces';
import {
  useGetPracticeNivelationsSubjectsQuery,
  useUpdatePracticeApplicationMutation,
} from '../../slices/GeneralApplicationsApiSlice';
import PracticeHistory from './PracticeHistory';
import { usePeriodByStudentRecordIdQuery } from '../../../periods/slices/periodsApiSlice';
import useFormatDate from '../../../../shared/hooks/useFormatDate';
import { IconFile } from '@tabler/icons';
import useGetUrlDownload from '../../../../../hooks/useGetUrlDownload';

import downloadjs from 'downloadjs';
import { isAppChangeStatusAvailable } from '../../helpers';
import { subPermissionKeys } from '../../constants/permissions';
import useSharedGuard from '../../../../../hooks/useSharedGuard';

const Practice: FC<DetailsProps> = (props) => {
  const {
    details: detailsProp,
    spacing,
    appCode,
    appId,
    currentStatus,
    nextStatus,
    updateAlertHandler,
    updateStatusHandler,
    curriculumNew,
    practiceDetails,
  } = props;
  const details = detailsProp as PracticeInt;
  const practice = practiceDetails as GeneralApplication;

  // //CompanieDetail | BossDetail | ValuePracticeDetail
  const companieDetail = practice.applicationDetails[0].value[0] as CompanieDetail;
  const bossDetail = practice.applicationDetails[0].value[1] as BossDetail;
  const positionDetail = practice.applicationDetails[0].value[2] as ValuePracticeDetail;

  const isNotAvailable = !isAppChangeStatusAvailable(currentStatus);

  const theme = useTheme();

  console.log(details);

  const { formatDate } = useFormatDate();

  //const history = useHistory();
  //const location = useLocation();
  const dispatch = useDispatch();

  const { hasAccess } = useSharedGuard(subPermissionKeys);

  const { currentProfile } = useAppSelector((state) => state.access);
  const { selectedStudentRecordId } = useAppSelector((state) => state.applications);

  const [
    updateApplication,
    {
      isLoading: isUpdating,
      error: updateError,
      isError: isErrorUpdate,
      isSuccess: isSuccessUpdate,
    },
  ] = useUpdatePracticeApplicationMutation();

  const { handleSubmit, getFieldProps, errors, touched, validateField, values, setFieldValue } =
    useFormik({
      initialValues: {
        comment: '',
        hours: '',
        subject_curriculum_id: '',
        periodId: '',
      },
      validationSchema: curriculumNew ? ChangeStatusAppPracticeNew : ChangeStatusAppPractice,
      onSubmit: async (values) => {
        await updateApplication({
          profileId: currentProfile?.profileId!,
          ApplicationId: appId,
          applicationPayload: {
            app_code: appCode,
            comment: values.comment,
            ...(curriculumNew && { period_id: Number(values.periodId) }),
            ...(curriculumNew && { practices_hours: Number(values.hours) }),
            ...(curriculumNew && { subject_curriculum_id: Number(values.subject_curriculum_id) }),
          },
        });
        updateAlertHandler(true);
      },
    });

  useEffect(() => {
    if (isErrorUpdate) updateStatusHandler({ state: 'error', msg: updateError as string });
    if (isSuccessUpdate)
      updateStatusHandler({
        state: curriculumNew ? 'success-practice' : 'success',
        msg: 'La solicitud se ha aprobado',
      });
  }, [isErrorUpdate, updateStatusHandler, updateError, isSuccessUpdate, curriculumNew]);

  const handleDelete = (data: { appId: number; code: string; msg: string; refuseId?: number }) => {
    dispatch(openRejectAppModalWith(data));
  };

  const steps = hasAccess('changeApplicationStatus')
    ? ['Datos de la Práctica Preprofesional', 'Verificación de Práctica']
    : ['Datos de la Práctica Preprofesional'];

  const [activeStep, setActiveStep] = useState(0);

  const { data: practiceSubjects, isLoading: isLoadingSubjects } =
    useGetPracticeNivelationsSubjectsQuery(
      {
        student_record_id: selectedStudentRecordId!,
        user_profile_id: currentProfile?.profileId!,
      },
      {
        skip: !selectedStudentRecordId ? true : false,
      }
    );

  const { data: periods, isLoading: isLoadingPeriods } = usePeriodByStudentRecordIdQuery(
    {
      studentRecordId: Number(selectedStudentRecordId!),
      userProfileId: currentProfile?.profileId!,
    },
    {
      skip: !selectedStudentRecordId ? true : false,
    }
  );

  const { urlDownload } = useGetUrlDownload();

  const handleDownload = (index = 0) => {
    if (details[index as keyof typeof details].value) {
      const url = `${urlDownload}/${details[index as keyof typeof details].value}`;
      try {
        downloadjs(url);
      } catch (error) {
        console.log(Error);
      }
    }
  };

  // const handleDownload = () => {
  //   if (details[0].value) {
  //     const url = `${urlDownload}/${details[0].value}`;
  //     try {
  //       downloadjs(url);
  //     } catch (error) {
  //       console.log(Error);
  //     }
  //   }
  // };

  // useEffect(() => {
  //   if (practiceSubjects && practiceSubjects.course_student) {
  //     setTotalHours(
  //       practiceSubjects.course_student
  //         .map((st) => Number(st.final_note))
  //         .reduce((partialSum, a) => partialSum + a, 0)
  //     );
  //   }
  // }, [practiceSubjects]);

  return (
    <Grid container justifyContent="center" alignItems="center">
      <form onSubmit={handleSubmit}>
        <Stepper activeStep={activeStep} sx={{ height: 100, maxWidth: 800, margin: '0 auto' }}>
          {steps.map((label, index) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: {
              optional?: React.ReactNode;
            } = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <>
          {activeStep === 0 && (
            <>
              <Grid
                container
                border="1px solid #eee"
                borderRadius="12px"
                p={4}
                mb={1}
                sx={{ maxWidth: '1000px' }}
              >
                {/* Datos de la compania */}
                <Grid item xs={12}>
                  <Typography variant="h4" color="primary" component="div" sx={{ mb: 2 }}>
                    1. Datos de la compañia
                  </Typography>
                </Grid>

                <Grid container item alignItems={'center'} mb={1}>
                  <Grid item xs={'auto'}>
                    <Typography variant={'body2'} component={'span'}>
                      Ruc:
                    </Typography>
                  </Grid>

                  <Grid item sx={{ pl: spacing }}>
                    <Typography variant={'h5'} component={'span'}>
                      {companieDetail.companie_ruc}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container item alignItems={'center'} mb={1}>
                  <Grid item xs={'auto'}>
                    <Typography variant={'body2'} component={'span'}>
                      Empresa:
                    </Typography>
                  </Grid>

                  <Grid item sx={{ pl: spacing }}>
                    <Typography variant={'h5'} component={'span'}>
                      {companieDetail.companie_name}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container item xs={12} alignItems={'center'} mb={1}>
                  <Grid item xs={'auto'}>
                    <Typography variant={'body2'} component={'span'}>
                      Actividad:
                    </Typography>
                  </Grid>

                  <Grid item xs={'auto'} lg={6} sx={{ pl: spacing }}>
                    <Typography variant={'h5'} component={'span'}>
                      {companieDetail.companie_activity}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container item xs={12} sm={6} alignItems={'center'} mb={1}>
                  <Grid item xs={'auto'}>
                    <Typography variant={'body2'} component={'span'}>
                      Razón Social:
                    </Typography>
                  </Grid>

                  <Grid item xs={'auto'} lg={6} sx={{ pl: spacing }}>
                    <Typography variant={'h5'} component={'span'}>
                      {companieDetail.companie_reason}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container item alignItems={'center'} mb={1}>
                  <Grid item xs={'auto'}>
                    <Typography variant={'body2'} component={'span'}>
                      Dirección:
                    </Typography>
                  </Grid>

                  <Grid item xs={'auto'} lg={6} sx={{ pl: spacing }}>
                    <Typography variant={'h5'} component={'span'}>
                      {companieDetail.companie_address}
                    </Typography>
                  </Grid>
                </Grid>

                {/* datos del jefe */}
                <Grid item xs={12}>
                  <Typography variant="h4" color="primary" component="div" sx={{ my: 2 }}>
                    2. Datos del jefe
                  </Typography>
                </Grid>
                <Grid container item alignItems={'center'} mb={1}>
                  <Grid item xs={'auto'}>
                    <Typography variant={'body2'} component={'span'}>
                      Nombre:
                    </Typography>
                  </Grid>

                  <Grid item xs={'auto'} lg={6} sx={{ pl: spacing }}>
                    <Typography variant={'h5'} component={'span'}>
                      {bossDetail.boss_name}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container item alignItems={'center'} mb={1}>
                  <Grid item xs={'auto'}>
                    <Typography variant={'body2'} component={'span'}>
                      Correo:
                    </Typography>
                  </Grid>

                  <Grid item xs={'auto'} lg={6} sx={{ pl: spacing }}>
                    <Typography variant={'h5'} component={'span'}>
                      {bossDetail.boss_email}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container item alignItems={'center'} mb={1}>
                  <Grid item xs={'auto'}>
                    <Typography variant={'body2'} component={'span'}>
                      Cargo:
                    </Typography>
                  </Grid>

                  <Grid item xs={'auto'} lg={6} sx={{ pl: spacing }}>
                    <Typography variant={'h5'} component={'span'}>
                      {bossDetail.boss_position}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container item alignItems={'center'} mb={1}>
                  <Grid item xs={'auto'}>
                    <Typography variant={'body2'} component={'span'}>
                      Teléfono:
                    </Typography>
                  </Grid>

                  <Grid item xs={'auto'} lg={6} sx={{ pl: spacing }}>
                    <Typography variant={'h5'} component={'span'}>
                      {bossDetail.boss_tlf}
                    </Typography>
                  </Grid>
                </Grid>

                {/* datos de la practica */}
                <Grid item xs={12}>
                  <Typography variant="h4" color="primary" component="div" sx={{ my: 2 }}>
                    3. Datos del puesto
                  </Typography>
                </Grid>

                <Grid container item alignItems={'center'} mb={1}>
                  <Grid item xs={'auto'}>
                    <Typography variant={'body2'} component={'span'}>
                      Cargo:
                    </Typography>
                  </Grid>

                  <Grid item xs={'auto'} lg={6} sx={{ pl: spacing }}>
                    <Typography variant={'h5'} component={'span'}>
                      {positionDetail.practice_position}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container item alignItems={'center'} mb={1}>
                  <Grid item xs={'auto'}>
                    <Typography variant={'body2'} component={'span'}>
                      Horario:
                    </Typography>
                  </Grid>

                  <Grid item xs={'auto'} lg={6} sx={{ pl: spacing }}>
                    <Typography variant={'h5'} component={'span'}>
                      {positionDetail.practice_hourhand}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container item alignItems={'center'} mb={1}>
                  <Grid item xs={'auto'}>
                    <Typography variant={'body2'} component={'span'}>
                      Fecha inicio:
                    </Typography>
                  </Grid>

                  <Grid item xs={'auto'} lg={6} sx={{ pl: spacing }}>
                    <Typography variant={'h5'} component={'span'}>
                      {formatDate(positionDetail.practice_start_date)}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container item alignItems={'center'} mb={1}>
                  <Grid item xs={'auto'}>
                    <Typography variant={'body2'} component={'span'}>
                      Fecha fin:
                    </Typography>
                  </Grid>

                  <Grid item xs={'auto'} lg={6} sx={{ pl: spacing }}>
                    <Typography variant={'h5'} component={'span'}>
                      {formatDate(positionDetail.practice_end_date)}
                    </Typography>
                  </Grid>
                </Grid>

                {!!details[0]?.value && (
                  <Grid item xs={6}>
                    <Stack alignItems="center">
                      <IconFile size={120} />
                      <Button variant="outlined" onClick={() => handleDownload()}>
                        Descargar archivo
                      </Button>
                    </Stack>
                  </Grid>
                )}

                {!!details[1]?.value && (
                  <Grid item xs={6}>
                    <Stack alignItems="center">
                      <IconFile size={120} />
                      <Button variant="outlined" onClick={() => handleDownload(1)}>
                        Descargar archivo
                      </Button>
                    </Stack>
                  </Grid>
                )}
              </Grid>
              <Grid container justifyContent={'flex-end'} mt={2} spacing={2}>
                <Grid item>
                  <Button variant="outlined" size="large" onClick={() => setActiveStep(1)}>
                    Siguiente
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
        </>

        {/* {((currentRol?.keyword === 'decano' &&
          currentApplication!.current_status.toLowerCase() === 'enviado') ||
          (currentRol?.keyword === 'coordinador-facultad' &&
            (currentApplication!.current_status.toLowerCase() === 'revisado por decanato' ||
              currentApplication!.current_status.toLowerCase() ===
                'revisado por consejo facultad'))) &&
          !isSuccess && ( */}
        {/* )} */}

        <>
          {activeStep === 1 && (
            <>
              <Grid item container spacing={2} sx={{ width: '100%' }}>
                {!curriculumNew && (
                  <Grid item xs={8} md={8} lg={8}>
                    <InputLabel>Seleccionar Materia</InputLabel>
                    <Autocomplete
                      disableClearable={true}
                      loading={isLoadingSubjects}
                      options={
                        practiceSubjects?.practices_subjects.map((sj) => ({
                          label: sj.mat_name,
                          value: sj.subject_curriculum_id,
                        })) || []
                      }
                      onChange={(e, val) => {
                        setFieldValue('subject_curriculum_id', val?.value);
                        const horas = Number(
                          practiceSubjects?.practices_subjects.find(
                            (sj) => sj.subject_curriculum_id === val?.value
                          )?.amount_credits
                        );

                        setFieldValue('hours', String(horas));
                      }}
                      renderInput={(props) => (
                        <TextField
                          {...props}
                          placeholder="Seleccione Materia"
                          error={Boolean(
                            errors.subject_curriculum_id && touched.subject_curriculum_id
                          )}
                        />
                      )}
                    />
                    {errors.subject_curriculum_id && touched.subject_curriculum_id && (
                      <FormHelperText error>{errors.subject_curriculum_id}</FormHelperText>
                    )}
                  </Grid>
                )}
                {!curriculumNew && (
                  <Grid item xs={4} md={4} lg={4}>
                    <InputLabel>Horas</InputLabel>
                    <TextField
                      placeholder="Ingrese Horas"
                      fullWidth
                      disabled={true}
                      {...getFieldProps('hours')}
                      error={Boolean(errors.hours && touched.hours)}
                    />
                    {errors.hours && touched.hours && (
                      <FormHelperText error>{errors.hours}</FormHelperText>
                    )}
                  </Grid>
                )}
                {!curriculumNew && (
                  <Grid item xs={12} md={12} lg={12}>
                    <InputLabel>Seleccionar Período</InputLabel>
                    <Autocomplete
                      loading={isLoadingPeriods}
                      options={
                        periods?.map((per) => ({
                          label: per.per_name,
                          value: per.id,
                        })) || []
                      }
                      onChange={(e, val) => {
                        setFieldValue('periodId', val?.value);
                      }}
                      renderInput={(props) => (
                        <TextField
                          {...props}
                          placeholder="Seleccione Período"
                          error={Boolean(errors.periodId && touched.periodId)}
                        />
                      )}
                    />
                    {errors.periodId && touched.periodId && (
                      <FormHelperText error>{errors.periodId}</FormHelperText>
                    )}
                  </Grid>
                )}
                <Grid item xs={12} md={12} lg={12}>
                  <InputLabel>Comentario</InputLabel>
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    error={Boolean(errors.comment && touched.comment)}
                    placeholder="Ingrese el nombre del período"
                    {...getFieldProps('comment')}
                    disabled={isNotAvailable}
                  />
                  {errors.comment && touched.comment && (
                    <FormHelperText error>{errors.comment}</FormHelperText>
                  )}
                </Grid>

                {practiceSubjects &&
                  practiceSubjects.course_student &&
                  practiceSubjects.course_student.length > 0 &&
                  !curriculumNew && <PracticeHistory courses={practiceSubjects.course_student} />}

                {/* {((currentRol?.keyword === 'decano' &&
            currentApplication!.current_status.toLowerCase() === 'enviado') ||
            (currentRol?.keyword === 'coordinador-facultad' &&
              (currentApplication!.current_status.toLowerCase() === 'revisado por decanato' ||
                currentApplication!.current_status.toLowerCase() ===
                  'revisado por consejo facultad'))) &&
            !isSuccess && ( */}

                <Grid item container justifyContent={'flex-end'} mt={2} spacing={2}>
                  <Grid item>
                    <Button variant="outlined" size="large" onClick={() => setActiveStep(0)}>
                      Regresar
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      size="large"
                      {...(!isNotAvailable && {
                        sx: { background: theme.palette.error.main, borderColor: '#EDE7F6' },
                      })}
                      onClick={() => {
                        touched.comment = true;
                        validateField('comment').then(() => {
                          if ((touched.comment && errors.comment) || !values.comment) return;
                          handleDelete({ appId, code: appCode, msg: values.comment, refuseId: 2 });
                        });
                      }}
                      disabled={
                        isUpdating || isNotAvailable || isSuccessUpdate || nextStatus === null
                      }
                    >
                      Anular
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      size="large"
                      {...(!isNotAvailable && {
                        sx: { background: theme.palette.error.main, borderColor: '#EDE7F6' },
                      })}
                      onClick={() => {
                        touched.comment = true;
                        validateField('comment').then(() => {
                          if ((touched.comment && errors.comment) || !values.comment) return;
                          handleDelete({ appId, code: appCode, msg: values.comment });
                        });
                      }}
                      disabled={
                        isUpdating || isNotAvailable || isSuccessUpdate || nextStatus === null
                      }
                    >
                      Rechazar
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      type="submit"
                      variant="contained"
                      size="large"
                      color="primary"
                      disabled={
                        isUpdating || isNotAvailable || isSuccessUpdate || nextStatus === null
                      }
                      endIcon={isUpdating && <CircularProgress size={20} />}
                    >
                      Aprobar
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </>
      </form>
    </Grid>
  );
};

export default Practice;
