import { mainApi } from '../../../../api/mainApi';
import { middlewareApi } from '../../../middleware/middlewareApi';
import { IResponse } from '../../../shared/interfaces/response.interface';
import { BlackboardFile } from '../interfaces/Blacboard.interface';

const blackboardTags = middlewareApi.enhanceEndpoints({
  addTagTypes: ['Blackboard', 'BlackboardFiles'],
});

export const blackboardApi = blackboardTags.injectEndpoints({
  endpoints: (builder) => ({
    getBlackboardFiles: builder.query<
      IResponse<BlackboardFile>,
      {
        page: number;
        perPage: number;
        search: string;
        sort?: string;
        type_sort?: string;
        start_date?: string;
        end_date?: string;
      }
    >({
      queryFn: async ({ page, perPage, search, sort, type_sort, start_date, end_date }) => {
        try {
          const { data } = await mainApi.get<IResponse<BlackboardFile>>(
            'courses/blackboard/migrations',
            {
              params: {
                page,
                per_page: perPage,
                search,
                ...(type_sort && { type_sort }),
                ...(sort && { sort }),
                ...(start_date && { start_date }),
                ...(start_date && end_date && { end_date }),
              },
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),
  }),
});

export const { useGetBlackboardFilesQuery } = blackboardApi;
