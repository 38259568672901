import { middlewareApi } from '../../../middleware/middlewareApi';
import { mainApi } from '../../../../api/mainApi';
import { ProductResponse, ProductPayload, Product } from '../interfaces/products.interface';
import { UseTypesResponse } from '../interfaces/usetypes.interface';
import { ProductByIdResponse } from '../interfaces/product.interface';
import { TypeApplicationResponse } from '../interfaces/type-application';
import { AccessState } from '../../../../types';

export const ProductsTags = middlewareApi.enhanceEndpoints({
  addTagTypes: ['Products'],
});

export const productsApi = ProductsTags.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * @GET all products
     */
    getProducts: builder.query<
      ProductResponse,
      {
        profileId: number;
        page: number;
        size: number;
        sort: string;
        type_sort: string;
        search: string;
      }
    >({
      queryFn: async ({ profileId, page, size, sort, type_sort, search }, { dispatch }) => {
        try {
          const { data } = await mainApi.get<ProductResponse>(`/products`, {
            params: {
              user_profile_id: profileId,
              page: page,
              size: size,
              sort: sort,
              type_sort: type_sort,
              search: search,
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result?.data?.map(({ id }) => ({ type: 'Products', id } as const)),
              { type: 'Products', id: 'LIST' },
            ]
          : [{ type: 'Products', id: 'LIST' }],
    }),

    /**
     * @GET all products availables
     */
    getAvailablesProducts: builder.query<Product[], { profileId: number; onlyActive?: boolean }>({
      queryFn: async ({ profileId, onlyActive }, { dispatch }) => {
        try {
          const { data } = await mainApi.get<Product[]>(`/products`, {
            params: {
              user_profile_id: profileId,
              ...(onlyActive && { onlyActive }),
              data: 'all',
            },
          });

          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    /**
     * @GET all products availables with parameters
     * @category_id for filter product by category
     */
    getAllProducts: builder.query<
      Product[],
      { profileId?: number; category_id?: number; isDebts?: boolean }
    >({
      queryFn: async ({ profileId, category_id, isDebts }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = currentProfile?.profileId;

          const { data } = await mainApi.get<Product[]>(`/products`, {
            params: {
              ...(profileId ? { user_profile_id: profileId } : { user_profile_id }),
              ...(category_id && { category_id }),
              ...(isDebts && { isDebts }),
              data: 'all',
            },
          });

          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'Products', id } as const)),
              { type: 'Products', id: 'LIST' },
            ]
          : [{ type: 'Products', id: 'LIST' }],
    }),

    /**
     * @GET product by id
     */
    getProductById: builder.query<ProductByIdResponse, { profileId: number; productId: number }>({
      queryFn: async ({ profileId, productId }, { dispatch }) => {
        try {
          const { data } = await mainApi.get<ProductByIdResponse>(`/products/${productId}`, {
            params: {
              user_profile_id: profileId,
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result, error, { productId }) => [{ type: 'Products', productId }],
    }),

    /**
     * @GET all categories
     */
    /* getCategories: builder.query<
            CategoryResponse,
            { profileId: number, page: number, size: number, sort: string, type_sort: string, search: string }
        >({
            queryFn: async ({ profileId, page, size, sort, type_sort, search }, { dispatch }) => {
                try {
                    const { data } = await mainApi.get<CategoryResponse>(`/categories`, {
                        params: {
                            user_profile_id: profileId,
                            page: page,
                            size: size,
                            sort: sort,
                            type_sort: type_sort,
                            search: search,
                        },
                    });

                    return { data };
                }
                catch (error: any) {
                    return { error }
                }
            }
        }), */

    /**
     * @GET catalog by acronym
     * @Permission catalogs-obtener-catalog
     */
    getUseTypes: builder.query<UseTypesResponse, { profileId: number; acronym: string }>({
      queryFn: async ({ profileId, acronym }, { dispatch }) => {
        try {
          const { data } = await mainApi.get<UseTypesResponse>(`/catalogs/${acronym}/children`, {
            params: {
              user_profile_id: profileId,
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    /**
     * @GET tYPE applications
     */
    getTypeApplication: builder.query<
      TypeApplicationResponse,
      { profileId: number; acronym: string }
    >({
      queryFn: async ({ profileId, acronym }, { dispatch }) => {
        try {
          const { data } = await mainApi.get<TypeApplicationResponse>(
            `/type-application/${acronym}/children`,
            {
              params: {
                user_profile_id: profileId,
              },
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    /**
     * @POST create product
     */
    addProduct: builder.mutation<ProductPayload, { productPayload: Partial<ProductPayload> }>({
      queryFn: async ({ productPayload }) => {
        try {
          const { data } = await mainApi.post<ProductPayload>('/products', {
            ...productPayload,
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'Products', id: 'LIST' }],
    }),

    /**
     * @PUT update product information
     */
    updateProduct: builder.mutation<
      ProductPayload,
      { productId: number; productPayload: Partial<ProductPayload> }
    >({
      queryFn: async ({ productId, productPayload }) => {
        try {
          const { data } = await mainApi.put<ProductPayload>(`/products/${productId}`, {
            ...productPayload,
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: (result, error, { productId }) => [{ type: 'Products', productId }],
    }),

    /**
     * @DELETE remove product
     */
    deleteProduct: builder.mutation<{}, { profileId: number; productId: number }>({
      queryFn: async ({ profileId, productId }) => {
        try {
          const { data } = await mainApi.delete(`/products/${productId}`, {
            data: {
              user_profile_id: profileId,
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'Products', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetProductByIdQuery,
  useGetProductsQuery,
  //useGetCategoriesQuery,
  useGetAvailablesProductsQuery,
  useGetAllProductsQuery,
  useGetUseTypesQuery,
  useGetTypeApplicationQuery,
  useAddProductMutation,
  useUpdateProductMutation,
  useDeleteProductMutation,
} = productsApi;
