import { Switch } from 'react-router-dom';

import DebtsPaymentsScreen from '../screens/DebtsPaymentsScreen';

import GuardRoute from '../../../../router/guards/GuardRoute';
import { sharedPermissions } from '../../../shared/constants/sharedPermissions';
import { cashCollectionPermissions, studentsPermissions } from '../constants/permissions';

const DebtsPaymentRouter = () => {
  return (
    <Switch>
      <GuardRoute
        exact
        path="/administrar-caja/cobrar-deudas"
        component={DebtsPaymentsScreen}
        subPermissionKey={[
          sharedPermissions.findPerson,
          studentsPermissions.getDebstByStudents,
          cashCollectionPermissions.storeCashCollection,
        ]}
      />
    </Switch>
  );
};

export default DebtsPaymentRouter;
