import { Button, Dialog, DialogContent, Grid, Typography } from '@material-ui/core';
import { Send } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import { setCurrentModule } from '../../../../shared/reducers/accessReducer';
import iconExams from '../../../../../assets/images/no-tests.png';

interface PendingEvaluationsDialogProps {
  pendingEvaluations: number;
  pendingApproves: number;
  handleClose: () => void;
}

const PendingEvaluationsDialog = ({
  pendingEvaluations,
  pendingApproves,
  handleClose,
}: PendingEvaluationsDialogProps) => {
  const history = useHistory();

  const { currentRol } = useAppSelector((state) => state.access);
  const dispatch = useAppDispatch();

  const moduleKey = Object.keys(currentRol?.modules || {})[0];

  const module = currentRol?.modules?.[moduleKey];

  const handleClick = () => {
    if (!module) return;
    dispatch(setCurrentModule(module));
    localStorage.setItem('currentModule', moduleKey);
    history.push('/evaluaciones-usuario');
    handleClose();
  };

  const handleReview = () => {
    if (!module) return;
    dispatch(setCurrentModule(module));
    localStorage.setItem('currentModule', moduleKey);

    history.push('/evaluaciones-usuario/formularios/formularios-usuario');
    handleClose();
  };

  const onClose = () => {
    if (pendingEvaluations === 0 || process.env.NODE_ENV === 'development') {
      handleClose();
    }
  };

  return (
    <Dialog open={pendingEvaluations > 0 || pendingApproves > 0} onClose={onClose}>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h2" color="primary" textAlign="center">
              {pendingEvaluations > 0 && pendingApproves > 0
                ? 'Evaluaciones y aprobaciones de resultados pendientes'
                : pendingEvaluations > 0
                ? 'Evaluaciones pendientes'
                : 'Aprobación de resultados pendientes'}
            </Typography>
          </Grid>
          <Grid item container xs={12} justifyContent="center">
            <img
              style={{
                width: 'auto',
                height: '200px',
              }}
              src={iconExams}
              alt="exams student"
            />
          </Grid>
          <Grid item xs={12}>
            <Typography fontSize="1rem" textAlign="center">
              {pendingEvaluations > 0 && pendingApproves > 0 ? (
                <>
                  {pendingEvaluations === 1 ? 'Existe' : 'Existen'} <b>{pendingEvaluations}</b>{' '}
                  {pendingEvaluations === 1 ? 'evaluación pendiente ' : 'evaluaciones pendientes '}
                  de realizar y <b>{pendingApproves}</b>
                  {pendingApproves === 1 ? 'resultado de evaluación' : 'resultados de evaluaciones'}
                  por revisar
                </>
              ) : pendingEvaluations > 0 ? (
                <>
                  {pendingEvaluations === 1 ? 'Existe' : 'Existen'} <b>{pendingEvaluations}</b>{' '}
                  {pendingEvaluations === 1 ? 'evaluación pendiente ' : 'evaluaciones pendientes '}
                  de realizar
                  <br />
                  Realiza {pendingEvaluations === 1 ? 'la evaluación' : 'las evaluaciones'} para
                  utilizar las funciones de la plataforma.
                </>
              ) : (
                <>
                  {pendingApproves === 1 ? 'Existe' : 'Existen'} <b>{pendingApproves}</b>{' '}
                  {pendingApproves === 1 ? 'resultado de evaluación' : 'resultados de evaluaciones'}{' '}
                  por revisar
                  <br />
                  Revisa {pendingApproves === 1 ? 'el resultado' : 'los resultados'} para utilizar
                  las funciones de la plataforma.
                </>
              )}
            </Typography>
          </Grid>
          <Grid item container xs={12} justifyContent="center">
            {Boolean(pendingEvaluations) && (
              <Button
                onClick={handleClick}
                variant="contained"
                color="primary"
                endIcon={
                  <Send
                    sx={{
                      fontSize: 10,
                    }}
                  />
                }
              >
                {pendingEvaluations === 1 ? 'Realizar evaluación' : 'Realizar evaluaciones'}
              </Button>
            )}
            {Boolean(pendingApproves) && (
              <Button
                onClick={handleReview}
                variant="contained"
                color="primary"
                endIcon={
                  <Send
                    sx={{
                      fontSize: 10,
                    }}
                  />
                }
              >
                {pendingApproves === 1 ? 'Revisar resultado' : 'Revisar resultados'}
              </Button>
            )}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default PendingEvaluationsDialog;
