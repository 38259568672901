import * as yup from 'yup';

export const ClassroomEducationLvlSchema = yup.object().shape({
  campus_id: yup.number().required('El Campus es requerido.').min(1, 'El Campus es requerido.'),
  period_id: yup.number().required('El período es requerido.').min(1, 'El período es requerido.'),
  academicOffer_id: yup
    .number()
    .required('La oferta académica es requerida.')
    .min(1, 'La oferta académica es requerida.'),
  educationLvl_id: yup
    .number()
    .required('La unidad académica es requerida.')
    .min(1, 'La unidad académica es requerida.'),
});

export const CourseScheme = yup.object().shape({
  subjectId: yup
    .number()
    .nullable()
    .required('academic.courses.side.subject.required')
    .typeError('academic.courses.side.subject.required'),
  typeVirtualClassroom: yup
    .string()
    .nullable()
    .required('academic.courses.side.virtual_classroom_type.required'),
  parallelId: yup.number().nullable().required('academic.courses.side.parallel.required'),
  modalityId: yup
    .number()
    .nullable()
    .required('academic.courses.side.modality.required')
    .typeError('academic.courses.side.modality.required'),
  hourhandId: yup
    .number()
    .nullable()
    .required('academic.courses.side.schedule.required')
    .typeError('academic.courses.side.schedule.required'),
  classroomId: yup
    .number()
    .nullable()
    .typeError('academic.courses.side.classroom.required')
    .required('academic.courses.side.classroom.required'),
  max_capacity: yup
    .number()
    .required('academic.courses.side.max_register.required')
    .min(1, 'academic.courses.side.max_register.min'),
  max_registers: yup
    .number()
    .typeError('academic.courses.side.max_register.min')
    .min(1, 'academic.courses.side.max_register.min')
    .required('academic.courses.side.max_register.required'),
  expected_registers: yup
    .number()
    .typeError('academic.courses.side.expected_register.min')
    // .min(1, 'academic.courses.side.expected_register.min')
    .required('academic.courses.side.expected_register.required'),
  // num_fouls: yup.number().min(1, 'No puede ser un valor negativo'),
  // status: yup.object().required('El estado es requerido').nullable(),
  startDate: yup
    .date()
    .nullable()
    .typeError('academic.courses.side.start_date.required')
    .required('academic.courses.side.start_date.required')
    .default(null),
  endDate: yup
    .date()
    .nullable()
    .typeError('academic.courses.side.end_date.required')
    .required('academic.courses.side.end_date.required')
    .default(null),
});

export const CreateCourseSchema = yup.object().shape({
  subject: yup.object().required('La materia es requerida').nullable(),
  parallel: yup.object().required('El  paralelo es requerido').nullable(),
  modality: yup.object().required('La modalidad es requerida').nullable(),
  hourhand: yup.object().required('El horario es requerido').nullable(),
  // gradeType: yup.object().required('El modelo de calificación').nullable(),
  max_capacity: yup
    .number()
    .required('La capacidad máxima es requerida')
    .min(1, 'La capacidad maxima debe ser mayor a 0'),
  num_fouls: yup.number().min(0, 'No puede ser un valor negativo'),
  status: yup.object().required('El estado es requerido').nullable(),
  period_id: yup.number().required('El período es requerido').min(1, 'El período es requerido'),
  startDate: yup.date().nullable().notRequired().default(null),
  endDate: yup.date().nullable().notRequired().default(null),
});

export const UpdateCourseSchema = yup.object().shape({
  subjectId: yup
    .number()
    .required('La materia es requerida')
    .min(1, 'La materia es requerida')
    .nullable(),
  parallel: yup.object().required('El  paralelo es requerido').nullable(),
  modality: yup.object().required('La modalidad es requerida').nullable(),
  hourhand: yup.object().required('El horario es requerido').nullable(),
  gradeType: yup.object().required('El modelo de calificación').nullable(),
  max_capacity: yup
    .number()
    .required('La capacidad máxima es requerida')
    .min(0, 'No puede ser un valor negativo'),
  // num_fouls: yup.number().min(0, 'No puede ser un valor negativo'),
  // status: yup.object().required('El estado es requerido').nullable(),
  period_id: yup.number().required('El período es requerido'),
  startDate: yup.date().nullable().default(null),
  endDate: yup.date().nullable().default(null),
});
