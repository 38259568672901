import { useHistory, Link, useRouteMatch, useParams } from 'react-router-dom';

import { DebounceInput } from 'react-debounce-input';

import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  // Pagination,
  Grid,
  TextField,
  InputAdornment,
  IconButton,
  Stack,
  Typography,
  Skeleton,
  Alert,
  // Box,
} from '@material-ui/core';
// import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import { IconX } from '@tabler/icons';

import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import { useGetEducationLevelSubjectsGroupQuery } from '../../slices/ELSApiSlice';

// import MainCard from '../../../shared/components/cards/MainCard';
import StatusChip from '../../../../shared/components/badges/StatusChip';
import EditButton from '../../../../shared/components/tables/buttons/EditButton';
import DeleteButton from '../../../../shared/components/tables/buttons/DeleteButton';
import AddButton from '../../../../shared/components/tables/buttons/AddButton';

import { renderErrorOrEmptyRow, renderSkeletonRows } from '../../../../shared/helpers/render';

import { ELS } from '../../interfaces/ELS.interfaces';
import {
  openDeleteModalWith,
  setEducationLevelSubjectGroupList,
  updateSubjectsGroupNivelationTableStates,
} from '../../slices/ELSSlice';

import CustomPaginationAndItems from '../../../../shared/components/tables/CustomPaginationAndItems';
import { useTableSearch } from '../../../../../hooks/useTableSearch';
import { useEffect } from 'react';

const ELSTable = () => {
  const dispatch = useAppDispatch();
  const { currentProfile } = useAppSelector((state) => state.access);

  const { page, perPage, search } = useAppSelector(
    (state) => state.educationLevelSubject.subjectsGroupNivelationTable
  );

  const { setPage, setPerPage, setSearch } = useTableSearch({
    page,
    perPage,
    search,
    updateFunction: updateSubjectsGroupNivelationTableStates,
  });

  const { path } = useRouteMatch();
  const history = useHistory();
  const { groupId } = useParams<{ groupId: string }>();

  const { groupAreaList } = useAppSelector((state) => state.groupAreas);

  const groupArea = groupAreaList.find((ga) => ga.id === Number(groupId));

  const { isLoading, error, data, isFetching } = useGetEducationLevelSubjectsGroupQuery(
    {
      profileId: currentProfile?.profileId!,
      page,
      perPage,
      search,
      groupId,
    },
    { refetchOnMountOrArgChange: 30 }
  );

  useEffect(() => {
    if (data) {
      dispatch(setEducationLevelSubjectGroupList(data.data));
    }
  }, [data, dispatch]);

  const handleDelete = async (periodId: number) => {
    dispatch(openDeleteModalWith(periodId));
  };

  const renderRow = (p: ELS, index: number) => (
    <TableRow className="table-row" key={p.id}>
      <TableCell align="center" width="5%">
        {index + ((page - 1) * perPage + 1)}
      </TableCell>
      <TableCell width="10%">{p.subject?.mat_name}</TableCell>
      {/*  <TableCell width="10%">{p.group_area?.arg_name}</TableCell> */}
      <TableCell width="10%">{p.group_area?.arg_description}</TableCell>
      <TableCell width="10%">{p.subject_hours}</TableCell>
      <TableCell width="5%">
        <StatusChip statusId={Number(p.status.id)} />
      </TableCell>
      <TableCell width="10%" align="center">
        <Link to={`${path}/${p.id}/editar`}>
          <EditButton />
        </Link>
        <DeleteButton handleDelete={() => handleDelete(p.id)} />
      </TableCell>
    </TableRow>
  );

  const renderRows = () => {
    return <>{data?.data.map(renderRow)}</>;
  };

  const goToAdd = () => {
    history.push('materias/agregar');
  };

  // if (data && data.data && data.data.length === 0) {
  //   return (

  //   );
  // }

  return (
    <>
      <Alert severity="info">
        <ul>
          <li>Debe tener una unidad académica creada</li>
          <li>Crear materias</li>
          <li>Debe crear una malla y donde deben estar asociadas las materias creadas</li>
          <li>Publicar la malla</li>
        </ul>
      </Alert>
      <Stack
        direction="row"
        spacing={2}
        sx={{
          mb: 2,
          p: 3,
          border: 1,
          borderRadius: '10px',
          borderColor: 'ButtonHighlight',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography variant="h3">Grupo de Nivelación:</Typography>
        <Typography variant="h2">
          {groupArea?.arg_name ? groupArea?.arg_name : <Skeleton width={100} />}
        </Typography>
      </Stack>
      <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
        <Grid item xs={12} sm={6}>
          <DebounceInput
            autoFocus={Boolean(search)}
            minLength={2}
            debounceTimeout={300}
            onChange={({ target }) => setSearch(target.value)}
            value={search}
            element={(props) => (
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon fontSize="small" />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        size="small"
                        onClick={() => setSearch('')}
                        {...(!search && { sx: { cursor: 'initial', opacity: 0 } })}
                      >
                        <IconX size={20} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                placeholder="Buscar materia"
                variant="outlined"
                size="small"
                {...props}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} sx={{ textAlign: 'right' }}>
          <AddButton size="large" onClick={goToAdd} />
        </Grid>
      </Grid>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">N°</TableCell>
              <TableCell>Nombre de la materia</TableCell>
              {/* <TableCell>Grupo</TableCell> */}
              <TableCell>Descripción</TableCell>
              <TableCell>Horas</TableCell>
              <TableCell>Estado</TableCell>
              <TableCell align="center">Opciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isFetching ? renderSkeletonRows(perPage, 7) : renderRows()}

            {error && renderErrorOrEmptyRow(7, error as string)}
            {data?.data.length === 0 && renderErrorOrEmptyRow(5)}
          </TableBody>
        </Table>
      </TableContainer>

      <CustomPaginationAndItems
        error={error}
        isLoading={isLoading}
        total={data?.total || 0}
        perPage={perPage}
        page={page}
        setPage={setPage}
        setPerPage={setPerPage}
        perPageBox
        paginationBox
      />
    </>
  );
};

export default ELSTable;
