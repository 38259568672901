import { Grid, Button } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { useFormik } from 'formik';

import React, { useState } from 'react';
import ErrorAlert from '../../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../../shared/components/alerts/SuccessAlert';
import { CommentSchema } from '../constants/validationSchema';
import { DetailsActivity } from '../interfaces/tasks.interface';
import InputModals from './InputModal';
import { useTranslation } from 'react-i18next';

import ModalUploadTask from './ModalUploadTask';
import { useSaveTaskCommentMutation } from '../../slices/virtualClassroomApiSlice';
import { useAppSelector } from '../../../../../hooks/useRedux';

const ModalComment: React.FC<{
  dataModal: {
    open: boolean;
    type: 'file' | 'comment';
    detailId: number;
  };
  setOpen: React.Dispatch<
    React.SetStateAction<{
      open: boolean;
      type: 'file' | 'comment';
      detailId: number;
    }>
  >;
  details: DetailsActivity[];
  taskId: number;
  refetch: () => void;
}> = ({ dataModal, setOpen, details, taskId, refetch }) => {
  /*States */
  const [showAlerts, setShowAlerts] = useState(false);

  const { currentStudent } = useAppSelector((state) => state.access);

  const [addTaskComment, { isError, isLoading, isSuccess, error }] = useSaveTaskCommentMutation();

  const { t } = useTranslation();

  const {
    values: { comment },
    handleChange,
    handleSubmit,
    resetForm,

    errors,
    touched,
  } = useFormik({
    initialValues: {
      comment: '',
    },
    enableReinitialize: true,
    validationSchema: CommentSchema,

    onSubmit: () => {
      AddTask();
    },
  });

  async function AddTask() {
    try {
      await addTaskComment({
        studentId: Number(currentStudent?.id!),
        taskId: taskId,
        detailId: dataModal.detailId,
        payload: {
          csact_comments: comment,
        },
      });
      setShowAlerts(true);
      isSuccess && resetForm();
    } catch (error) {
      console.log(error);
    }
  }

  /*Formik */

  return (
    <ModalUploadTask
      Open={dataModal.open}
      title={t('students.virtual_classroom.task.modal.input.comment')}
    >
      <>
        <Grid
          item
          container
          sx={{
            mb: 3,
          }}
        >
          <Grid item>
            {isError && showAlerts && (
              <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
            )}

            {isSuccess && showAlerts && (
              <SuccessAlert
                message={'Enviado con exito'}
                handleDismiss={() => setShowAlerts(false)}
              />
            )}
          </Grid>
        </Grid>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3} justifyContent={'space-between'}>
            <Grid item xs={12}>
              <InputModals
                placeholder={t('students.virtual_classroom.task.modal.input.placeholder.comment')}
                Title={t('students.virtual_classroom.task.modal.input.comment')}
                Value={comment}
                name={'comment'}
                setValue={handleChange}
                error={Boolean(errors.comment && touched.comment)}
                messageError={errors.comment}
              />
            </Grid>
          </Grid>
          <Grid justifyContent={'center'} mt={'20px'} textAlign="center">
            <Button
              onClick={() => {
                setShowAlerts(false);
                refetch();
                setOpen((prev) => {
                  return {
                    ...prev,
                    open: false,
                    detailId: 0,
                  };
                });
              }}
              style={{ marginRight: '30px', borderRadius: '30px' }}
              variant="outlined"
            >
              {t('students.virtual_classroom.task.modal.button.back')}
            </Button>
            <LoadingButton
              loading={isLoading}
              loadingIndicator="Subiendo archivo ..."
              type="submit"
              style={{ borderRadius: '30px' }}
              variant="contained"
              disabled={isSuccess}
            >
              {t('students.virtual_classroom.task.modal.button.submit')}
            </LoadingButton>
          </Grid>
        </form>
      </>
    </ModalUploadTask>
  );
};

export default ModalComment;
