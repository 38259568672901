import {
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
} from '@material-ui/core';
import { format } from 'date-fns';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../../../hooks/useRedux';
import ErrorAlert from '../../../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../../../shared/components/alerts/SuccessAlert';
import { IFormOpeningDate } from '../interfaces/openingDates.interface';
import {
  useAddOpeningDateMutation,
  useUpdateOpeningDateMutation,
} from '../slices/openingDateApiSlice';
import { openingDateFormSchema } from '../validation/openingDate.validation';

const OpeningDateForm = () => {
  const { t } = useTranslation();
  const [showAlerts, setShowAlerts] = useState(false);

  const [addOpeningDate, { isLoading, isError, error, isSuccess }] = useAddOpeningDateMutation();

  const [
    updateOpeningDate,
    { isLoading: isLoadingU, isError: isErrorU, error: errorU, isSuccess: isSuccessU },
  ] = useUpdateOpeningDateMutation();

  const { evaluationsIdSelected } = useAppSelector((state) => state.evaluations);

  const { openingDatesList, openingDatesIdSelected } = useAppSelector(
    (state) => state.openingDates
  );

  const openingDatesSelected = openingDatesList.find(
    (option) => option.uuid === openingDatesIdSelected
  );

  const date = new Date();

  const { errors, getFieldProps, handleSubmit, touched, resetForm, values, setValues } =
    useFormik<IFormOpeningDate>({
      initialValues: {
        name: '',
        startDate: openingDatesSelected?.start_date || format(date, 'yyyy-MM-dd'),
        endDate: openingDatesSelected?.end_date || format(date, 'yyyy-MM-dd'),
        evaluationId: evaluationsIdSelected || '',
      },
      validationSchema: openingDateFormSchema,
      onSubmit: async (currentValues) => {
        if (openingDatesIdSelected) {
          await updateOpeningDate({
            id: openingDatesIdSelected,
            name: currentValues.name,
            description: '',
          });
        } else {
          await addOpeningDate({
            name: currentValues.name,
            startDate: currentValues.startDate,
            endDate: currentValues.endDate,
            evaluationId: currentValues.evaluationId,
          });
        }

        setShowAlerts(true);
      },
    });

  useEffect(() => {
    if (isSuccess || isSuccessU) {
      resetForm();
      setValues({
        name: '',
        startDate: '',
        endDate: '',
        evaluationId: '',
      });
    }
  }, [isSuccessU, isSuccess, resetForm, setValues]);

  return (
    <Grid container spacing={2}>
      {(isSuccess || isSuccessU) && showAlerts && (
        <Grid item xs={12}>
          <SuccessAlert
            message={
              isSuccess
                ? t('maintenance.opening_dates.add.success')
                : t('maintenance.opening_dates.edit.success')
            }
            handleDismiss={() => setShowAlerts(false)}
          />
        </Grid>
      )}
      {(isError || isErrorU) && showAlerts && (
        <Grid item xs={12}>
          <ErrorAlert
            message={(error as any) || (errorU as any)}
            handleDismiss={() => setShowAlerts(false)}
          />
        </Grid>
      )}

      <Grid item xs={12}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            {!evaluationsIdSelected && (
              <Grid item xs={12}>
                <InputLabel>{t('maintenance.opening_dates.evaluation')}</InputLabel>
                {/* <TextField
                  {...getFieldProps('evaluationId')}
                  select
                  label
                  variant="outlined"
                  fullWidth
                >
                  1
                </TextField> */}
              </Grid>
            )}

            <Grid item xs={12}>
              <InputLabel htmlFor="name">{t('maintenance.opening_dates.form.name')}</InputLabel>
              <TextField {...getFieldProps('name')} fullWidth />
              {touched.name && errors.name && (
                <FormHelperText error>{t(errors.name)}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12}>
              <InputLabel htmlFor="start-date">
                {t('maintenance.opening_dates.form.start_date')}
              </InputLabel>
              <TextField
                type="date"
                {...getFieldProps('startDate')}
                fullWidth
                inputProps={{
                  max: values.endDate,
                }}
              />
              {touched.startDate && errors.startDate && (
                <FormHelperText error>{t(errors.startDate)}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12}>
              <InputLabel htmlFor="start-date">
                {t('maintenance.opening_dates.form.end_date')}
              </InputLabel>
              <TextField
                type="date"
                {...getFieldProps('endDate')}
                fullWidth
                inputProps={{
                  min: values.startDate,
                }}
              />
              {touched.endDate && errors.endDate && (
                <FormHelperText error>{t(errors.endDate)}</FormHelperText>
              )}
            </Grid>
            {/* <Grid item xs={12}>
              <InputLabel>{t('maintenance.openingDates.form.description')}</InputLabel>
              <TextField
                placeholder={t('maintenance.openingDates.form.description.placeholder')}
                error={Boolean(errors.description && touched.description)}
                {...getFieldProps('description')}
                fullWidth
              />
              {errors.description && touched.description && (
                <FormHelperText error>{t(errors.description)}</FormHelperText>
              )}
            </Grid> */}
            <Grid item container xs={12} justifyContent="flex-end" spacing={2}>
              <Grid item>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  disabled={isLoading || isLoadingU}
                  endIcon={isLoading || (isLoadingU && <CircularProgress size={12} />)}
                >
                  {!openingDatesIdSelected
                    ? t('maintenance.opening_dates.add.submit')
                    : t('maintenance.opening_dates.edit.submit')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default OpeningDateForm;
