import Campus from '../Campus';

const CampusStep = () => {
  return (
    <>
      <Campus />
    </>
  );
};

export default CampusStep;
