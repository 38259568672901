import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISearchTable } from '../../../../../shared/interfaces/paginatedTable.interface';
import { IntegralReport, IntegralsReportsState } from '../interfaces/integralsReports.interface';

const initialState: IntegralsReportsState = {
  integralsReportsList: [],
  integralsReportsIdSelected: null,
  integralsReportsTable: {
    page: 1,
    perPage: 5,
    search: '',
  },

  groupIdSelected: null,
  evaluatedIdSelected: null,
  stageIdSelected: null,

  isEditFilters: true,
};

export const integralReportsSlice = createSlice({
  name: 'userEvaluation',
  initialState,
  reducers: {
    setIntegralsReportsList: (state, action: PayloadAction<IntegralReport[]>) => {
      state.integralsReportsList = action.payload;
    },
    setIntegralsReportsIdSelected: (state, action: PayloadAction<number>) => {
      state.integralsReportsIdSelected = action.payload;
    },
    updateIntegralsReportsTable: (state, action: PayloadAction<ISearchTable>) => {
      state.integralsReportsTable = {
        ...state.integralsReportsTable,
        ...action.payload,
      };
    },
    setGroupIdSelected: (state, action: PayloadAction<string>) => {
      state.groupIdSelected = action.payload;
    },
    setEvaluatedIdSelected: (state, action: PayloadAction<string>) => {
      state.evaluatedIdSelected = action.payload;
    },
    setStageIdSelected: (state, action: PayloadAction<string>) => {
      state.stageIdSelected = action.payload;
    },
    resetFilters: (state) => {
      state.groupIdSelected = null;
      state.evaluatedIdSelected = null;
      state.stageIdSelected = null;
    },
    setIsEditFilters: (state, action: PayloadAction<boolean>) => {
      state.isEditFilters = action.payload;
    },
  },
});

export const {
  setIntegralsReportsList,
  setIntegralsReportsIdSelected,
  updateIntegralsReportsTable,
  setIsEditFilters,
  setGroupIdSelected,
  setEvaluatedIdSelected,
  setStageIdSelected,
  resetFilters,
} = integralReportsSlice.actions;
