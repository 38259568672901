import DeleteAlert from '../../../shared/components/dialogs/DeleteAlert';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { setDeleteCollectiveModalOpen } from '../slices/educationLevelsSlice'
import { useDeleteEducationLevelCollectiveMutation } from '../slices/educationLevelsApiSlice'

const DeleteCollectiveAlert = () => {
  const dispatch = useAppDispatch();

  const { deleteCollectiveModalOpen, currentColective } = useAppSelector((state) => state.educationLevels);

  const [deleteColective, { isLoading, isSuccess, isError, error }] = useDeleteEducationLevelCollectiveMutation();

  const handleDelete = async () => {
    await deleteColective({
      collectiveId: currentColective!
    })
  };

  const handleClose = () => {
    dispatch(setDeleteCollectiveModalOpen(false));
  };

  return (
    <DeleteAlert
      open={deleteCollectiveModalOpen}
      onClose={handleClose}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      handleDelete={handleDelete}
      error={error as string}
    />
  );
};

export default DeleteCollectiveAlert;
