import { ChangeEvent, FC } from 'react';
import { BaseTextFieldProps, CircularProgress, InputAdornment, TextField } from '@material-ui/core';

interface Props extends Partial<BaseTextFieldProps> {
  inputValue?: string;
  setInputValue?: React.Dispatch<React.SetStateAction<string>>;
  placeholderValue?: string;
}

/** Un textfield con un endIcon CircularProgress
 * ## Interface
 * ```jsx
 *  interface Props {
 *    inputValue?: string;
 *    setInputValue?: React.Dispatch<React.SetStateAction<string>>;
 *    }
 * ```
 */
const TextFieldFetching: FC<Props> = (props) => {
  const { inputValue, setInputValue, placeholderValue, ...custom } = props;

  const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    if (setInputValue) setInputValue(e.currentTarget.value);
  };

  return (
    <TextField
      {...custom}
      fullWidth
      disabled={!inputValue}
      value={inputValue}
      onChange={changeHandler}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <CircularProgress size={25} />
          </InputAdornment>
        ),
      }}
      id="input-with-icon-textfield"
      placeholder={placeholderValue || 'Cargando datos ...'}
    />
  );
};

export default TextFieldFetching;
