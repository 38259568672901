import { useEffect, useState } from 'react';
import { useHistory, Link, useRouteMatch } from 'react-router-dom';

import {
  //Button,
  IconButton,
  //Typography,
  Tooltip,
  // Box,
} from '@material-ui/core';
// import { makeStyles } from '@material-ui/core/styles';
import { IconList, IconBuildingBank } from '@tabler/icons';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { useGetGroupAreasQuery } from '../slices/groupAreasApiSlice';

// import MainCard from '../../../shared/components/cards/MainCard';
import StatusChip from '../../../shared/components/badges/StatusChip';
import EditButton from '../../../shared/components/tables/buttons/EditButton';
import DeleteButton from '../../../shared/components/tables/buttons/DeleteButton';
import AddButton from '../../../shared/components/tables/buttons/AddButton';

import {
  openDeleteModalWith,
  setGroupAreaList,
  updateGroupAreasTable,
} from '../slices/groupAreasSlice';

import SearchPaginatedTable, {
  FilterArrow,
} from '../../../shared/components/tables/SearchPaginatedTable';
import { useTableSearch } from '../../../../hooks/useTableSearch';

const GroupsAreaTable = () => {
  const { currentProfile } = useAppSelector((state) => state.access);

  const dispatch = useAppDispatch();

  const { path } = useRouteMatch();
  const history = useHistory();

  const { page, perPage, search } = useAppSelector((state) => state.groupAreas.groupAreasTable);

  const { setPage, setPerPage, setSearch } = useTableSearch({
    page,
    perPage,
    search,
    updateFunction: updateGroupAreasTable,
  });

  const [filterArrow, setFilterArrow] = useState<FilterArrow>(null!);

  const { isLoading, error, data, isFetching } = useGetGroupAreasQuery(
    {
      profileId: currentProfile?.profileId!,
      page,
      perPage,
      search,
      ...(filterArrow && { sort: filterArrow.name, type_sort: filterArrow.direction }),
    },
    { refetchOnMountOrArgChange: 30 }
  );

  useEffect(() => {
    if (data) {
      dispatch(setGroupAreaList(data.data));
    }
  }, [data, dispatch]);

  const handleDelete = async (periodId: number) => {
    dispatch(openDeleteModalWith(periodId));
  };

  const datafetch =
    data?.data?.map((d, index) => ({
      id: index + (page - 1) * perPage + 1,
      group: d.arg_name,
      description: d.arg_description,
      status: d.status_id,
      options: d,
    })) || [];

  const goToAdd = () => {
    history.push('/administrar-grupo-de-areas/agregar');
  };

  return (
    <SearchPaginatedTable
      data={datafetch!}
      headers={{
        id: 'N°',
        group: 'Grupo',
        description: 'Descripción',
        status: 'Estado',
        options: 'Opciones',
      }}
      keyExtractor={(item) => item.id.toString()}
      listFilterArrows={{
        group: 'arg_name',
        description: 'arg_description',
        status: 'status_id',
      }}
      filterArrow={filterArrow}
      setFilterArrow={setFilterArrow}
      searchPlacehoder="Buscar Grupo de nivelación"
      isLoading={isLoading}
      isFetching={isFetching}
      error={error}
      perPage={perPage}
      setPerPage={setPerPage}
      total={data?.total || 0}
      page={page}
      setPage={setPage}
      numHeader={7}
      search={search}
      setSearch={setSearch}
      customHeadersCellsProperties={{
        options: {
          align: 'center',
        },
      }}
      customDataCellsProperties={{
        id: { align: 'center' },

        status: { align: 'center' },
        options: {
          align: 'center',
        },
      }}
      customRenderers={{
        status: ({ status }) => <StatusChip statusId={Number(status)} />,
        options: (row) => (
          <>
            <Link to={`/administrar-carreras-y-materias-de-nivelacion/${row.options.id}/materias`}>
              <Tooltip title="Ver materias asignadas">
                <IconButton>
                  <IconList />
                </IconButton>
              </Tooltip>
            </Link>
            <Link to={`/administrar-carreras-de-nivelacion/${row.options.id}/carreras`}>
              <Tooltip title="Ver carreras asignadas">
                <IconButton>
                  <IconBuildingBank />
                </IconButton>
              </Tooltip>
            </Link>
            <Link to={`${path}/${row.options.id}/editar`}>
              <EditButton />
            </Link>
            <DeleteButton handleDelete={() => handleDelete(row.options.id)} />
          </>
        ),
      }}
      ActionButtons={
        <>
          {/* <Link
            to="/administrar-carreras-y-materias-de-nivelacion"
            style={{ textDecoration: 'none' }}
          ></Link>
          <Link
            to="/administrar-carreras-y-materias-de-nivelacion"
            style={{ textDecoration: 'none' }}
          >
            <Button variant="outlined" color="primary" sx={{ marginX: 2, marginY: 1 }}>
              <Typography variant="subtitle1" color="inherit">
                Lista de materias-Grupo
              </Typography>
            </Button>
          </Link> */}
          <AddButton size="large" onClick={goToAdd} />
        </>
      }
    />
  );
};

export default GroupsAreaTable;
