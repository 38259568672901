import { FC } from 'react';

import { Card, Grid, Typography, IconButton, Tooltip, useTheme, CircularProgress } from '@material-ui/core';
import { IconDownload } from '@tabler/icons';
import { IconFileInfo } from '@tabler/icons'
import { makeStyles } from '@material-ui/core/styles';
import { useAppSelector } from '../../../../hooks/useRedux';
import { useGetTermsAndConditionsDefaultQuery, useGetTermsAndConditionsFileQuery } from '../slices/institutionalInfoApiSlice';
import { LegalPapersFile } from '../interfaces/terms-conditions.interface';

const useStyles = makeStyles((theme) => ({
  followerBlock: {
    padding: '16px',
    background: theme.palette.mode === 'dark' ? theme.palette.dark.main : '',
    border: '1px solid gainsboro',
    borderColor: theme.palette.mode === 'dark' ? 'transparent' : theme.palette.grey[100],
    '&:hover': {
      border: '1px solid' + theme.palette.primary.main,
    },
    minWidth: '200px',
  },
  primaryLight: {
    color: theme.palette.grey[500],
    cursor: 'pointer',
  },
  icon_wrapp: {
    backgroundColor: "#EFF8FF",
    width: 70,
    height: 70,
    borderRadius: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }

  //   active: {
  //     background:
  //       theme.palette.mode === 'dark' ? theme.palette.dark.dark : theme.palette.success.light,
  //     color: theme.palette.success.dark,
  //   },
  //   reject: {
  //     background: theme.palette.mode === 'dark' ? theme.palette.dark.dark : theme.palette.error.light,
  //     color: theme.palette.error.dark,
  //   },
}));

interface Props {
  docDescription?: string;
  legal_paper_file: LegalPapersFile;
}

const DocumentCard: FC<Props> = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  const { currentProfile } = useAppSelector(state => state.access)

  const { data, isLoading: isLoadingDefaultDocument } = useGetTermsAndConditionsDefaultQuery({
    user_profile_id: currentProfile?.profileId!,
    name: props.legal_paper_file.file_route
  }, {
    skip: props.legal_paper_file.id_doc ? true : false
  })

  const { data: data2, isLoading: isLoadingDocument } = useGetTermsAndConditionsFileQuery({
    user_profile_id: currentProfile?.profileId!,
    name: props.legal_paper_file.file_route
  }, {
    skip: props.legal_paper_file.id_doc ? false : true
  })

  return (
    <Grid container item xs={12} md={6} lg={4}>
      <Card className={classes.followerBlock}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <div className={classes.icon_wrapp}>
                  <IconFileInfo size={38} color={theme.palette.primary[900]} />
                </div>
              </Grid>
              <Grid item xs={9}>
                <Grid item xs={12} sx={{ display: { xs: 'none', sm: 'block' }, pl: 1 }}>
                  <DescriptionDocument description={props.legal_paper_file.name} />
                </Grid>
              </Grid>
              <Grid item xs={12} sx={{ display: { sm: 'none', xs: 'block' } }}>
                <DescriptionDocument description={props.legal_paper_file.name} />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container justifyContent={'flex-end'}>
              <form method="get">
                <Tooltip title="Descargar">
                  <IconButton
                    color="primary"
                    type='submit'
                    size='medium'
                    disabled={isLoadingDefaultDocument || isLoadingDocument}
                    onClick={(event) => {
                      event?.preventDefault()
                      const pdfBlob = props.legal_paper_file.id_doc ? data2 : data;
                      var file = new Blob([pdfBlob], { type: 'application/pdf' });
                      var fileURL = URL.createObjectURL(file);
                      window.open(fileURL);
                      //window.open(`/legal-papers-list-files-defaults?user_profile_id=${currentProfile?.profileId!}&name=${props.legal_paper_file.file_route}`)}
                    }}>
                    {isLoadingDefaultDocument || isLoadingDocument
                      ?
                      <CircularProgress size={28} />
                      :
                      <IconDownload size={28} />
                    }
                  </IconButton>
                </Tooltip>
              </form>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};

interface DesDoc {
  description: string;
}
const DescriptionDocument = ({ description }: DesDoc) => {
  return (
    <>
      <Typography variant="h3" >
        {description}
      </Typography>
    </>
  );
};

export default DocumentCard;
