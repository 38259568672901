import { mainApi } from '../../../api/mainApi';
import { middlewareApi } from '../../middleware/middlewareApi';
import { EnterLeaveItem } from '../interfaces/enter.leave.response.interface';
import { RolePayment } from '../interfaces/roles.payments.interface';
import { rrhhApplicationPayload } from '../interfaces/rrhh.applications.payload';

const rrhhApi = middlewareApi.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * @GET entradas-salidas
     */
    getRolePayment: builder.query<
      RolePayment[],
      {
        collaborator_id: number;
        user_profile_id: number;
        month: string;
        year: string;
      }
    >({
      queryFn: async ({ collaborator_id, month, user_profile_id, year }) => {
        try {
          const { data } = await mainApi.post<RolePayment[]>(
            `collaborators/${collaborator_id}/role`,
            {
              month,
              year,
              user_profile_id,
            }
          );
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),
    /**
     * @GET entradas-salidas
     */
    getEnterLeave: builder.query<
      EnterLeaveItem[],
      {
        collaborator_id: number;
        user_profile_id: number;
        start_date: string;
        end_date: string;
      }
    >({
      queryFn: async ({ collaborator_id, start_date, end_date, user_profile_id }) => {
        try {
          const { data } = await mainApi.post<EnterLeaveItem[]>(
            `collaborators/${collaborator_id}/biometric`,
            {
              start_date,
              end_date,
              user_profile_id,
            }
          );
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),
    /**
     * @POST application
     */
    sendApplicationRrHh: builder.mutation<any, rrhhApplicationPayload>({
      queryFn: async ({
        type_application_acronym,
        type_application_id,
        amount,
        collaborator_id,
        director_id,
        discount_in,
        end_date,
        end_hour,
        immediate_boss_id,
        period,
        reason,
        replacement_collaborator_id,
        start_date,
        start_hour,
        user_profile_id,
      }) => {
        try {
          const { data } = await mainApi.post<any>(`collaborators/${collaborator_id}/application`, {
            type_application_acronym,
            type_application_id,
            amount,
            director_id,
            discount_in,
            end_date,
            end_hour,
            immediate_boss_id,
            period,
            reason,
            replacement_collaborator_id,
            start_date,
            start_hour,
            user_profile_id,
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),
    /***
     * @delete Remove application
     */
    removeApplication: builder.mutation<
      any,
      {
        app_id: number;
        user_profile_id: number;
      }
    >({
      queryFn: async ({ app_id, user_profile_id }) => {
        try {
          const { data } = await mainApi.delete<any>(`application/${app_id}`, {
            params: {
              user_profile_id,
            },
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),
    /**
     * @GET ticket de comida
     */
    getMealTicket: builder.query<
      any,
      {
        collaborator_id: number;
        user_profile_id: number;
        nombres: string;
        apellidos: string;
      }
    >({
      queryFn: async ({ collaborator_id, nombres, apellidos, user_profile_id }) => {
        try {
          const { data } = await mainApi.post<any>(`collaborators/${collaborator_id}/meal-ticket`, {
            nombres,
            apellidos,
            user_profile_id,
          });

          if (data.flag && data.flag === 'error') {
            return {
              error: data.msg || 'Opción no disponible',
            };
          }

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),
  }),
});

export const {
  useGetEnterLeaveQuery,
  useLazyGetEnterLeaveQuery,
  useLazyGetRolePaymentQuery,
  useSendApplicationRrHhMutation,
  useRemoveApplicationMutation,
  useLazyGetMealTicketQuery,
} = rrhhApi;
