import { useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { CircularProgress, Grid, IconButton, Typography } from '@material-ui/core';
import MainCard from '../../../shared/components/cards/MainCard';

import { useTranslation } from 'react-i18next';
import HistoryCalificationTable from '../components/StudentHistoryCalificationTable';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { useLazyGetStudentQuery } from '../slices/studentsApiSlice';
import { setStudentsList } from '../slices/studentsSlice';
import { ArrowBack } from '@material-ui/icons';

const StudentAcademicHistoryScreen = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const history = useHistory();

  const { studentId } = useParams<{ studentId: string }>();

  const { studentsList } = useAppSelector((state) => state.students);
  const { currentProfile } = useAppSelector((state) => state.access);

  const [getStudent, { isLoading }] = useLazyGetStudentQuery();

  const student = useMemo(
    () => studentsList.find((student) => student.id === Number(studentId)),
    [studentsList, studentId]
  );

  useEffect(() => {
    if (!student) {
      getStudent({
        profileId: currentProfile?.profileId!,
        studentId: Number(studentId),
      })
        .unwrap()
        .then((response) => {
          dispatch(setStudentsList([response!]));
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studentId]);

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid container sx={{ mb: 3 }} justifyContent="center" alignItems="center" spacing={1}>
        <Grid item container alignItems="center" xs={12}>
          <IconButton onClick={() => history.goBack()}>
            <ArrowBack />
          </IconButton>
          <Typography variant="h2">
            {t('academic.students_list.student.academic_history.title')}
            {Boolean(student?.pers_full_name || (student as any)?.user?.person?.pers_full_name) &&
              ` - ${student?.pers_full_name || (student as any)?.user?.person?.pers_full_name}`}
          </Typography>
        </Grid>
        {/* <Grid item xs={2}>
          <Button onClick={() => history.push(`/asignar-notas/${studentId}`)}>
            {t('academic.students_list.student.assign_grades')}
          </Button>
        </Grid> */}
      </Grid>
      {student && !isLoading && <HistoryCalificationTable />}
      {(!student || isLoading) && (
        <Grid container justifyContent="center" sx={{ mt: 5 }}>
          <CircularProgress />
        </Grid>
      )}
    </Grid>
  );
};

export default StudentAcademicHistoryScreen;
