import { Box, Grid, Typography, useTheme } from '@material-ui/core';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import { CourseStudentStatus } from '../../interfaces/courseStudentStatus';
import {
  setCurrentHourhand,
  setCurrentInfoSubject,
  setShowHourhandAssignModal,
  setShowInfoSubjectModal,
} from '../../slices/subjectsRegisterSlice';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    height: 105,
  },
  boxContainer: {
    width: '100%',
    height: 105,
    borderRadius: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0px 0px 12px 10px rgba(235,232,235,0.5)',
  },
  text: {
    fontWeight: 600,
    color: theme.palette.primary.main,
    marginLeft: 3,
    fontSize:'0.8rem',
  },
  textBlack: {
    fontWeight: 600,
    color: theme.palette.dark.main,
    marginLeft: 3,
    fontSize:'0.8rem',
  },
  textGrey: {
    fontWeight: 500,
    color: theme.palette.grey[500],
    fontSize:'0.8rem',
  },
}));
export interface HourhandCardValues {
  day: string;
  start_hour: string;
  end_hour: string;
}

const HoraPlaceholder = ({ day, start_hour, end_hour }: HourhandCardValues) => {
  const dispatch = useAppDispatch();
  const { currentSubjectsRegistered } = useAppSelector((state) => state.subjectsRegister);
  const theme = useTheme();
  const classes = useStyles();

  const openHourhandRegisterDialog = () => {
    dispatch(setShowHourhandAssignModal(true));
    dispatch(setCurrentHourhand({ day, start_hour, end_hour }));
  };

  const openInfoSubjectDialog = (subjectRegister: CourseStudentStatus) => {
    dispatch(setShowInfoSubjectModal(true));
    dispatch(setCurrentInfoSubject(subjectRegister));
  };

  const [subjectRegister, setSubjectRegister] = useState<CourseStudentStatus[] | null>(null);


  useEffect(() => {
    if (currentSubjectsRegistered) {
      const hourhand = currentSubjectsRegistered.map((cs) => cs.course.hourhand);
      const sliceHourhand = hourhand?.slice(0, hourhand.length);
      const keysHourhand = Object.keys(sliceHourhand[0]);
      const keyDay = keysHourhand.find((kh) => kh.includes(`hour_${day}`));
      const keyHour = keysHourhand.find((kh) => kh.includes(`hour_start_time_${day}`));
      const filterSubject = currentSubjectsRegistered?.filter((cs) => {
        const startHour = start_hour.split(':')[0];
        const auxStart = cs.course.hourhand[keyHour!];
        const start = auxStart ? auxStart.toString().split(':')[0] : undefined;
        return cs.course.hourhand[keyDay!] === '1' && Number(start) === Number(startHour);
      });
      if (filterSubject && filterSubject.length > 0) {
        setSubjectRegister(filterSubject);
      }
    }
  }, [currentSubjectsRegistered, day, start_hour, theme]);

  return (
    <Grid item xs={2} className={classes.gridContainer}>
      <Box
        className={classes.boxContainer}
        onClick={
          subjectRegister
            ? () => openInfoSubjectDialog(subjectRegister![0])
            : openHourhandRegisterDialog
        }
      >
        {subjectRegister ? (
          <Grid item container justifyContent="center">
            <Typography className={classes.text}>
              {subjectRegister[0].subject_curriculum.matter_rename}
            </Typography>
            <Grid item container justifyContent="center">
              <Typography className={classes.textGrey}>Aula</Typography>
              <Typography className={classes.textBlack}>
                {subjectRegister[0].course.classroom.cl_name}
              </Typography>
            </Grid>
            <Grid item container justifyContent="center">
              <Typography className={classes.textGrey}>Paralelo</Typography>
              <Typography className={classes.textBlack}>
                {subjectRegister[0].course.parallel.par_name}
              </Typography>
            </Grid>
          </Grid>
        ) : null}
      </Box>
    </Grid>
  );
};

export default HoraPlaceholder;
