import {
  Stack,
  InputLabel as MUIInputLabel,
  InputLabelProps,
  Tooltip,
  ButtonBase,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import { IconInfoCircle } from '@tabler/icons';

export const useStyles = makeStyles((theme) => ({
  inputLabel: {
    marginBlockEnd: 6,
    fontWeight: 500,
    color: theme.palette.text.primary,
  },
}));

interface Props extends InputLabelProps {
  info?: string;
}

const InputLabel = ({ info, ...props }: Props) => {
  const classes = useStyles();

  if (info) {
    return (
      <Stack direction="row" alignItems="center" spacing={1}>
        <MUIInputLabel {...props} className={classes.inputLabel} />
        <Tooltip title={info} placement="top-start">
          <ButtonBase disableRipple={true}>
            <IconInfoCircle size={16} />
          </ButtonBase>
        </Tooltip>
      </Stack>
    );
  }

  return <MUIInputLabel {...props} className={classes.inputLabel} />;
};

export default InputLabel;
