import { FC, useEffect, useMemo, useState } from 'react';
import {
  Grid,
  TableCell,
  TableContainer,
  TableRow,
  Table,
  TableBody,
  TableHead,
  Typography,
  useTheme,
  Checkbox,
  Alert,
} from '@material-ui/core';
// import { Table } from 'phosphor-react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useAppSelector } from '../../../../../hooks/useRedux';
import { renderErrorOrEmptyRow } from '../../../../shared/helpers/render';
import { PaymentsDebts } from '../../interfaces/paymentsHistory.interface';
// import DatafastPayButton from './datafast-payment/DatafastPayButton';

interface Props {
  selectedFees: number[];
  totalAmount?: number;
  handleSelectFees: (fees: number[]) => void;
  setValidStep: (param: boolean) => void;
  handleTotalAmount: (amount: number) => void;
}

const Checkout: FC<Props> = (props) => {
  const { selectedFees: selected, handleSelectFees, setValidStep, handleTotalAmount } = props;

  const { selectedDebts } = useAppSelector((state) => state.payments);

  const theme = useTheme();
  //
  const [outRangeState, setOutRangeState] = useState<number[]>();
  const [isOutRange, setIsOutRange] = useState<boolean>();

  const totalAmount = useMemo(() => {
    let amount = 0;

    const filteredDebts = selectedDebts.filter((el) =>
      selected.some((sel) => +el.fees_identifier === sel)
    );

    filteredDebts.forEach((el) => {
      amount += +el.fees_balance;
    });

    return amount;
  }, [selectedDebts, selected]);

  useEffect(() => {
    if (totalAmount) handleTotalAmount(totalAmount);
  }, [totalAmount, handleTotalAmount]);

  useEffect(() => {
    if (selected.length) {
      setValidStep(true);
    } else {
      setValidStep(false);
    }
  }, [selected.length, setValidStep]);

  const handleClick = (
    // event: React.MouseEvent<HTMLTableHeaderCellElement, MouseEvent>,
    event: React.ChangeEvent<HTMLInputElement>,
    feeId: number
  ) => {
    const selectedIndex = selected.indexOf(feeId);
    let newSelected: number[] = [];

    let isUnSelected = false;

    const currentData = selectedDebts.find((el) => +el.fees_identifier === feeId);

    console.log(selectedIndex);
    if (selectedIndex === -1) {
      const outRange = selectedDebts.filter(
        (dt) => Date.parse(dt.fees_expiration_date) < Date.parse(currentData?.fees_expiration_date!)
      );

      //comportamiento de bloqueo de check
      console.log(outRange);
      // if (outRange.length) {
      //   if (
      //     !outRange.some((dt) => {
      //       console.log(dt);
      //       return selected.some((sel) => +dt.fees_identifier === sel);
      //     })
      //   ) {
      //     console.log('fuera de rango');
      //     setOutRange(true);
      //     return;
      //   }
      // }
      // hacer un filter de la data en base a los seleccionados y comparar el lenght
      if (outRange.length) {
        const dataSelected = outRange.filter((dt) =>
          selected.some((sel) => sel === +dt.fees_identifier)
        );

        if (dataSelected.length !== outRange.length) {
          setOutRangeState(
            outRange
              .filter((dt) =>
                selectedDebts.some((selDt) => selDt.fees_identifier === dt.fees_identifier)
              )
              .map((fil) => +fil.fees_identifier)
          );
          setIsOutRange(true);
          return;
        } else {
          setOutRangeState([]);
          setIsOutRange(false);
        }
      } else {
        setOutRangeState([]);
        setIsOutRange(false);
      }

      //comportamiento de autoselect de checks
      outRange.forEach((element) => {
        const isStored = selected.some((dt) => dt === +element.fees_identifier);
        if (!isStored) newSelected = newSelected.concat(+element.fees_identifier);
      });

      newSelected = newSelected.concat(selected, feeId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
      isUnSelected = true;
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
      isUnSelected = true;
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
      isUnSelected = true;
    }

    //

    if (isUnSelected) {
      const stored = selectedDebts.filter((dt) =>
        selected.some((sel) => +dt.fees_identifier === sel && +dt.fees_identifier !== feeId)
      );
      stored.forEach((element) => {
        const isOutDate =
          Date.parse(element.fees_expiration_date) > Date.parse(currentData?.fees_expiration_date!);
        if (isOutDate) {
          setIsOutRange(true);
          const outRangeIndex = newSelected.indexOf(+element.fees_identifier);
          newSelected.splice(outRangeIndex, 1);
        } else {
          setIsOutRange(false);
        }
      });
    }

    handleSelectFees(newSelected);
    // dispatch(setSelectedDebtsId(newSelected));
    // setSelected(newSelected);
  };

  const renderRow = (debt: PaymentsDebts) => (
    <TableRow hover className="table-row" key={`item-${debt.fees_identifier}`}>
      <TableCell>{debt.fees_name_identifier}</TableCell>
      <TableCell
        align="center"
        // width="70%"
        // sx={{ cursor: 'pointer' }}
        // onClick={() => {
        //   handleSelectRole(rol.id);
        // }}
      >
        {debt.fees_balance}
        {/* <FormHelperText>{rol.description}</FormHelperText> */}
      </TableCell>
      <TableCell align="center">{debt.fees_expiration_date}</TableCell>
      <TableCell align="center">
        <Checkbox
          //
          checked={selected.some((r) => r === Number(debt.fees_identifier))}
          onChange={(e) => handleClick(e, Number(debt.fees_identifier))}
          {...(outRangeState?.some((dt) => dt === +debt.fees_identifier) && {
            sx: { color: 'red' },
          })}
        />
      </TableCell>
    </TableRow>
  );

  const renderRows = () => {
    return <>{selectedDebts?.map(renderRow)}</>;
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          {isOutRange && (
            <Alert severity="warning">
              Se tiene que seleccionar las deudas en orden de la fecha de vencimiento
            </Alert>
          )}
        </Grid>
      </Grid>

      <Grid container mt={'1rem'}>
        {/* <Grid item xs={12} sx={{ maxHeight: 600, overflow: 'hidden' }}>*/}
        <Grid item xs={12}>
          <PerfectScrollbar component="div">
            <TableContainer sx={{ maxHeight: 600 }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Deuda</TableCell>
                    <TableCell align="center">Valor Subsidiado</TableCell>
                    <TableCell align="center">Fecha de vencimiento</TableCell>
                    <TableCell align="center">Seleccionar</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {renderRows()}
                  {selectedDebts?.length === 0 && renderErrorOrEmptyRow(5)}
                </TableBody>
              </Table>
            </TableContainer>
          </PerfectScrollbar>
        </Grid>
      </Grid>

      <Grid container justifyContent="center" alignItems="stretch" mt={2}>
        <Grid
          container
          item
          alignItems="center"
          justifyContent="center"
          xs={3}
          sx={{
            backgroundColor: theme.palette.primary.main,
            borderBottomLeftRadius: '12px',
            borderTopLeftRadius: '12px',
          }}
          p={2}
        >
          <Typography variant="h5" component="span" color="white">
            TOTAL A PAGAR
          </Typography>
        </Grid>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          item
          xs={3}
          sx={{
            backgroundColor: theme.palette.grey[200],
            borderBottomRightRadius: '12px',
            borderTopRightRadius: '12px',
          }}
          p={2}
        >
          <Typography variant="h3" component="span">
            <Typography variant="h6" component="span" mr={2}>
              $
            </Typography>
            {totalAmount}.00
          </Typography>
        </Grid>
      </Grid>

      {/* <Grid container><DatafastPayButton totalAmount={totalAmount} /></Grid> */}
    </>
  );
};

export default Checkout;
