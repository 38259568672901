import * as yup from 'yup';

export const TasksSchema = yup.object().shape({
  description: yup.string().required('La descripción es requerida.'),
  file: yup.mixed().required('Archivo requerido.'),
  title: yup.string().required('El título es requerido.'),
});
export const CommentSchema = yup.object().shape({
  comment: yup.string().required('El comentario es requerido.'),
});
