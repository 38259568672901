import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISearchTable } from '../../../shared/interfaces/paginatedTable.interface';
import { Client } from '../interfaces/clientsFilter.interface';

interface ClientState {
  clientList: Client[];
  deleteModalOpen: boolean;
  configurationStudentIdToDelete?: number;
  dialogAccountStatus: boolean;
  clientTable: ISearchTable;
  debtsTable: ISearchTable;
  prorrogueTable: ISearchTable;
  debtIdSelected: string | null;
  debtDateSelected: string | null;
  financialRepresentativeSelected: string | null;
}

const initialState: ClientState = {
  clientList: [],
  deleteModalOpen: false,
  dialogAccountStatus: false,
  clientTable: {
    page: 1,
    perPage: 5,
    search: '',
  },
  debtsTable: {
    page: 1,
    perPage: 5,
    search: '',
  },
  prorrogueTable: {
    page: 1,
    perPage: 5,
    search: '',
  },
  debtIdSelected: null,
  debtDateSelected: null,
  financialRepresentativeSelected: null,
};

const clientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    setClientList: (state, action: PayloadAction<Client[]>) => {
      state.clientList = action.payload;
    },
    setDeleteModalOpen: (state, action: PayloadAction<boolean>) => {
      state.deleteModalOpen = action.payload;
    },
    openDeleteModalWith: (state, action: PayloadAction<number>) => {
      state.configurationStudentIdToDelete = action.payload;
      state.deleteModalOpen = true;
    },
    resetConfigurationStudentIdToDelete: (state) => {
      state.configurationStudentIdToDelete = undefined;
    },
    fireDialogAccountStatus: (state, action: PayloadAction<boolean>) => {
      state.dialogAccountStatus = action.payload;
    },
    updateClientTable: (state, action: PayloadAction<ISearchTable>) => {
      state.clientTable = action.payload;
    },
    updateDebtsTable: (state, action: PayloadAction<ISearchTable>) => {
      state.debtsTable = action.payload;
    },
    updateProrrogueTable: (state, action: PayloadAction<ISearchTable>) => {
      state.prorrogueTable = action.payload;
    },
    setDebtIdSelected: (state, action: PayloadAction<string | null>) => {
      state.debtIdSelected = action.payload;
    },
    setDebtDateSelected: (state, action: PayloadAction<string | null>) => {
      state.debtDateSelected = action.payload;
    },
    setFinancialRepresentativeSelected: (state, action: PayloadAction<string | null>) => {
      state.financialRepresentativeSelected = action.payload;
    },
  },
});

export const {
  setClientList,
  setDeleteModalOpen,
  openDeleteModalWith,
  resetConfigurationStudentIdToDelete,
  fireDialogAccountStatus,
  updateClientTable,
  updateDebtsTable,
  updateProrrogueTable,
  setDebtIdSelected,
  setDebtDateSelected,
  setFinancialRepresentativeSelected,
} = clientSlice.actions;

export default clientSlice.reducer;
