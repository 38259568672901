import { Permission } from '../types';

export const hasSubPermission = (
  permissions: { [key: string]: Permission[] },
  pKey: string,
  spKey: string
) => {
  let hasPermission = false;

  try {
    hasPermission = permissions[pKey]?.some((sp) => sp.key === spKey);
  } catch (error) {
    console.log('=========Sin Permisos=========');
    console.log(error);
  }

  return hasPermission ? true : false;
};
