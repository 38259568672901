import { Avatar, Grid, Typography, useTheme } from '@material-ui/core';
import { IconStar } from '@tabler/icons';
import { Module } from '../../interfaces/permissions1.interfaces';

interface Props {
  module: Module;
}

const ModuleItem = ({ module }: Props) => {
  const theme = useTheme();

  return (
    <Grid
      container
      sx={{
        borderRadius: '5px',
        padding: '10px',
        boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.55)',
        height: '60px',
      }}
      alignItems="center"
    >
      <Grid item xs={10}>
        <Typography
          variant="h5"
          sx={{
            color: theme.palette.text.primary,
          }}
        >
          {module.mod_name_translated}
        </Typography>
      </Grid>
      {module?.principal && (
        <Grid item container justifyContent="flex-end" xs={2}>
          <Avatar
            sx={{
              backgroundColor: '#d6be3b',
              color: 'white',
            }}
          >
            <IconStar />
          </Avatar>
        </Grid>
      )}
    </Grid>
  );
};

export default ModuleItem;
