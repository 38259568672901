import {
  Autocomplete,
  Grid,
  InputLabel,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Table,
  CircularProgress,
  Typography,
  Button,
  Alert,
  ToggleButtonGroup,
  ToggleButton,
  useTheme,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import useGoToVirtualClassroom from '../../academic-portfolio/hooks/useGoToVirtualClassroom';
import { Schedule } from '../interfaces/academic-hourhand-interfaces';
import { useGetScheduleQuery } from '../slices/academicHourhandApiSlice';
import { getFixedTime } from '../../../auth/helpers';
import { useTranslation } from 'react-i18next';
import TextFieldFetching from '../../../shared/components/TextFieldFetching';
import StatusCard from './StatusCard';
import { getSubjectStatusColor } from '../../academic-portfolio/helpers/colors';
import { useGetStudentYearsPeriodsQuery } from '../../../maintenance/students/slices/studentsApiSlice';
import { setCourses } from '../slices/academicHourhandSlice';
import AcademicHourhand from './AcademicHourhand';
import { format } from 'date-fns';
import { enUS } from 'date-fns/locale';
import { YearsStudentPeriod } from '../../../maintenance/students/interfaces/students.interface';

const days = [
  {
    label: '',
    keyword: 'mat_name',
  },
  {
    label: 'Lunes',
    keyword: 'monday',
  },
  {
    label: 'Martes',
    keyword: 'tuesday',
  },
  {
    label: 'Miércoles',
    keyword: 'wednesday',
  },
  {
    label: 'Jueves',
    keyword: 'thursday',
  },
  {
    label: 'Viernes',
    keyword: 'friday',
  },
  {
    label: 'Sábado',
    keyword: 'saturday',
  },
  {
    label: 'Domingo',
    keyword: 'sunday',
  },
];

const ScheduleTable = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  const thisDay = format(new Date(), 'EEEE', { locale: enUS }).toLowerCase();
  const thisDayColor = `${theme.palette.primary.main}15`;

  const [periodId, setPeriodId] = useState<number | null>(null);

  const { currentProfile, currentStudent } = useAppSelector((state) => state.access);
  const { userInfo } = useAppSelector((state) => state.auth);
  const [valueCourseId, setValueCourseId] = useState<number>(0);

  const dispatch = useAppDispatch();

  const [view, setView] = useState<'subjects' | 'hours'>('subjects');
  const [periodReverse, setPeriodReverse] = useState<YearsStudentPeriod[]>([]);

  const { data: periods, isLoading: isLoadingPeriods } = useGetStudentYearsPeriodsQuery(
    {
      studentId: currentStudent?.id!.toString(),
    },
    {
      skip: !currentStudent?.id,
    }
  );

  const {
    data: subjects = [],
    isLoading: isLoadingS,
    isFetching: isFetchingS,
  } = useGetScheduleQuery(
    {
      studentRecordId: userInfo?.studentInfo?.studentRecordId!,
      periodId: periodId!,
      user_profile_id: currentProfile?.profileId!,
    },
    {
      skip: !periodId || !userInfo?.studentInfo?.studentRecordId || !currentProfile?.profileId,
    }
  );

  useEffect(() => {
    if (subjects?.length > 0) {
      dispatch(setCourses(subjects));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subjects]);

  useEffect(() => {
    if (
      periods &&
      (periods?.length || 0) > 0 &&
      (periods?.[0].periods?.length || 0) > 0 &&
      !periodId
    ) {
      setPeriodId([...periods].reverse()?.[0]?.periods?.[0]?.id!);
      setPeriodReverse([...periods].reverse());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periods, periodId]);

  const { isFetching } = useGoToVirtualClassroom(valueCourseId, 'inicio');

  if (!userInfo?.studentInfo?.studentRecordId || !currentProfile?.profileId) {
    return (
      <Grid container sx={{ mt: 2 }}>
        <Alert severity="warning">Opción no disponible por el momento.</Alert>
      </Grid>
    );
  }

  return (
    <Grid container>
      <Grid item container xs={12} justifyContent="space-between" alignItems="center">
        <Grid item mb={3} lg={4} md={6} xs={12} mt={1}>
          <InputLabel>{t('shared.schedules.period')}</InputLabel>

          {isLoadingPeriods && <TextFieldFetching />}
          {!isLoadingPeriods && (
            <Autocomplete
              options={periodReverse?.map((period) => period?.periods).flat() || []}
              loading={isLoadingPeriods}
              noOptionsText={t('shared.schedules.period.empty')}
              loadingText={t('shared.schedules.period.loading')}
              getOptionLabel={(option) => `${option?.per_name}`}
              groupBy={(option) => option?.per_current_year}
              value={
                periodReverse
                  ?.find((period) => period?.periods?.find((period) => period?.id === periodId))
                  ?.periods?.find((period) => period?.id === periodId) || null
              }
              renderInput={(params) => (
                <TextField {...params} placeholder={t('shared.schedules.period.placeholder')} />
              )}
              onChange={(_, value) => {
                setPeriodId(value?.id || null);
              }}
            />
          )}
        </Grid>

        <Grid item container lg={4} md={6} xs={12} justifyContent="flex-end" alignItems="center">
          <ToggleButtonGroup
            color="primary"
            value={view}
            exclusive
            onChange={(event, newValue) => {
              if (newValue) {
                setView(newValue as 'subjects' | 'hours');
              }
            }}
          >
            <ToggleButton value="subjects">Por materias</ToggleButton>
            <ToggleButton value="hours">Por horas</ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>

      <StatusCard />

      {periodId && !isLoadingS && !isFetchingS && subjects ? (
        <>
          {view === 'subjects' && subjects.length > 0 && (
            <TableContainer
              sx={{
                mt: 1,
              }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    {days.map((day) => (
                      <TableCell
                        align="center"
                        key={day.keyword}
                        sx={{
                          backgroundColor: day.keyword === thisDay ? thisDayColor : 'white',
                        }}
                      >
                        {t(`utils.${day.keyword}`)}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {subjects.map((subject) => (
                    <TableRow>
                      {days.map((day) => (
                        <TableCell
                          key={day.keyword}
                          align="center"
                          sx={{
                            background: day.keyword === thisDay ? thisDayColor : 'white',
                          }}
                        >
                          {day.keyword === 'mat_name' && (
                            <Grid
                              container
                              sx={{
                                width: '320px',
                                textAlign: 'left',
                              }}
                            >
                              <Grid item xs={12}>
                                <Typography variant="h5" color="primary">
                                  {subject?.approval_status?.st_name && (
                                    <span
                                      style={{
                                        backgroundColor: getSubjectStatusColor(
                                          subject?.approval_status?.st_name || ''
                                        ),
                                        display: 'inline-flex',
                                        width: '16px',
                                        height: '16px',
                                        borderRadius: '50%',
                                        marginRight: '5px',
                                      }}
                                    />
                                  )}
                                  {subject.mat_name}
                                </Typography>
                              </Grid>
                              {subject.tm_name && (
                                <Grid item xs={12}>
                                  <Typography variant="h6">
                                    <strong>{t('shared.schedules.type_matter')}:</strong>{' '}
                                    {subject.tm_name}
                                  </Typography>
                                </Grid>
                              )}
                              <Grid item xs={12}>
                                <Typography variant="h6">
                                  <strong>{t('shared.schedules.campus')}:</strong> {subject.campus}
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Typography variant="h6">
                                  <strong>{t('shared.schedules.classroom')}:</strong>{' '}
                                  {subject.classroom}
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Typography variant="h6">
                                  <strong>{t('shared.schedules.parallel')}:</strong>{' '}
                                  {subject.parallel}
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Typography variant="h6">
                                  <strong>{t('shared.schedules.teacher')}:</strong>{' '}
                                  {subject.collaborator}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} mt={1}>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={() => setValueCourseId(Number(subject.course_id))}
                                  size="small"
                                  disabled={isFetching}
                                  endIcon={
                                    Boolean(
                                      isFetching && valueCourseId === Number(subject.course_id)
                                    ) && <CircularProgress size={12} />
                                  }
                                >
                                  {t('shared.schedules.virtual_classroom')}
                                </Button>
                              </Grid>
                            </Grid>
                          )}
                          {day.keyword !== 'mat_name' &&
                            Boolean(Number(subject[`hour_${day.keyword}` as keyof Schedule])) && (
                              <Typography variant="body1" color="black">
                                {getFixedTime(
                                  subject[
                                    `hour_start_time_${day.keyword}` as keyof Schedule
                                  ]! as string
                                )}{' '}
                                -{' '}
                                {getFixedTime(
                                  subject[
                                    `hour_end_time_${day.keyword}` as keyof Schedule
                                  ]! as string
                                )}
                              </Typography>
                            )}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {view === 'hours' && subjects.length > 0 && <AcademicHourhand />}
          {subjects.length === 0 && (
            <Grid item container justifyContent="center" mt={2}>
              <Typography variant="h5">{t('shared.schedules.empty')}</Typography>
            </Grid>
          )}
        </>
      ) : (
        <Grid item container xs={12} justifyContent="center" mt={2}>
          <CircularProgress />
        </Grid>
      )}
    </Grid>
  );
};

export default ScheduleTable;
