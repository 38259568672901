import { object, string, number, date, boolean, array, mixed } from 'yup';
import { validateCedula } from './validateCedula';

export const CollaboratorTypeSchema = object().shape({
  collaboratorType: string().required('Seleccione un tipo de colaborador para continuar'),
});

export const PersonalDataSchema = object().shape({
  identificationType: object().required('Seleccione un tipo de identificación.').nullable(),
  identification: string()
    .trim()
    .required('El número de identificación es requerido.')
    .when('identificationType', (identificationType, PersonalDataSchema) => {
      return identificationType?.cat_keyword === 'cedula'
        ? PersonalDataSchema.matches(/^\d+$/, 'Solo se aceptan números.')
            .min(10, 'Ingrese al menos 10 dígitos.')
            .test('validateCedula', 'Cédula no válida.', validateCedula)
        : identificationType?.cat_keyword === 'ruc'
        ? PersonalDataSchema.matches(/^\d+$/, 'Solo se aceptan números.').min(
            13,
            'Ingrese al menos 13 dígitos.'
          )
        : PersonalDataSchema.nullable();
    }),
  firstName: string().required('El primer nombre es requerido.').nullable(),
  secondName: string().nullable(),
  lastName: string().required('El apellido paterno es requerido.').nullable(),
  secondLastName: string().nullable(),
  gender: string().required('Seleccione un género.').nullable(),
  birthDate: date()
    .max(new Date(), 'La fecha nacimiento es mayor a fecha actual.')
    .required('La fecha de nacimiento es requerida.')
    .typeError('La fecha es inválida, click para seleccionar.')
    .nullable(),
  homeAddress: string().required('La dirección domiciliaria es requerida.').nullable(),
  personalEmail: string()
    .trim()
    .email('Ingrese un email válido.')
    .required('El email es requerido.')
    .nullable(),
  city: object().required('Seleccione una ciudad.').nullable(),
  currentCity: object().required('Seleccione una ciudad de residencia.').nullable(),
  sector: object().required('Seleccione un sector.').nullable(),
  nacionality: object().required('Seleccione una nacionalidad.').nullable(),
  homePhone: string()
    .required('El teléfono es requerido.')
    .matches(/^\d+$/, 'Solo se aceptan números.')
    .nullable(),
  cellPhone: string()
    .required('El cellular es requerido.')
    .matches(/^\d+$/, 'Solo se aceptan números.')
    .nullable(),
});

export const CivilStatusSchema = object().shape({
  civilStatus: object().required('Seleccione un estado civil.').nullable(),
  relativeIdentificationType: object().when('civilStatus', (civilStatus, CivilStatusSchema) => {
    return civilStatus?.cat_keyword === 'casado'
      ? CivilStatusSchema.required('Seleccione un tipo de identificación.').nullable()
      : CivilStatusSchema.nullable();
  }),
  relativeIdentification: string().when('civilStatus', (civilStatus, CivilStatusSchema) => {
    return civilStatus?.cat_keyword === 'casado'
      ? CivilStatusSchema.required('El número de identificación es requerido.')
          .matches(/^\d+$/, 'Solo se aceptan números.')
          .min(10, 'Ingrese al menos 10 dígitos.')
          .nullable()
      : CivilStatusSchema.nullable();
  }),
  relativeFirstName: string().when('civilStatus', (civilStatus, CivilStatusSchema) => {
    return civilStatus?.cat_keyword === 'casado'
      ? CivilStatusSchema.required('El primer nombre es requerido.').nullable()
      : CivilStatusSchema.nullable();
  }),
  relativeSecondName: string().when('civilStatus', (civilStatus, CivilStatusSchema) => {
    return civilStatus?.cat_keyword === 'casado'
      ? CivilStatusSchema.nullable()
      : CivilStatusSchema.nullable();
  }),
  relativeLastName: string().when('civilStatus', (civilStatus, CivilStatusSchema) => {
    return civilStatus?.cat_keyword === 'casado'
      ? CivilStatusSchema.required('El apellido paterno es requerido.').nullable()
      : CivilStatusSchema.nullable();
  }),
  relativeSecondLastName: string().when('civilStatus', (civilStatus, CivilStatusSchema) => {
    return civilStatus?.cat_keyword === 'casado'
      ? CivilStatusSchema.nullable()
      : CivilStatusSchema.nullable();
  }),
  relativeGender: object().when('civilStatus', (civilStatus, CivilStatusSchema) => {
    return civilStatus?.cat_keyword === 'casado'
      ? CivilStatusSchema.required('Seleccione un género.').nullable()
      : CivilStatusSchema.nullable();
  }),
  relativeTypeReligion: object().when('civilStatus', (civilStatus, CivilStatusSchema) => {
    return civilStatus?.cat_keyword === 'casado'
      ? CivilStatusSchema.required('Seleccione una religión.').nullable()
      : CivilStatusSchema.nullable();
  }),
  relativeEthnic: object().when('civilStatus', (civilStatus, CivilStatusSchema) => {
    return civilStatus?.cat_keyword === 'casado'
      ? CivilStatusSchema.required('Seleccione un tipo de etnia.').nullable()
      : CivilStatusSchema.nullable();
  }),
  relativeRelationDescription: string().when('civilStatus', (civilStatus, CivilStatusSchema) => {
    return civilStatus?.cat_keyword === 'casado'
      ? CivilStatusSchema.required('Descripción de la relación.').nullable()
      : CivilStatusSchema.nullable();
  }),
});

export const ComplementaryDataSchema = object().shape({
  proffesion: string().nullable(),
  studyReason: string().nullable(),
  hasVehicle: boolean(),
  taxPayersHousehold: number().moreThan(-1, 'Número no válido.').nullable(),
  numChild: number().moreThan(-1, 'Número no válido.').nullable(),
  vivienda: object().required('Seleccione un tipo de vivienda.').nullable(),
  numBedrooms: number().moreThan(-1, 'Número no válido.').nullable(),
  religion: object().required('Seleccione una religión.').nullable(),
  etnic: object().required('Seleccione un tipo de etnia.').nullable(),
});

export const DisabilitiesSchema = object().shape({
  hasDisability: boolean(),
  numDisabilityCertificate: string().when('hasDisability', {
    is: true,
    then: string().required('El número del certificado de discapacidad es requerido.'),
  }),
  disabilityPercentage: mixed().when('hasDisability', {
    is: true,
    then: number()
      .min(1, 'El porcentaje mínimo es 1%')
      .max(100, 'El porcentaje máximo es 100%')
      .required('El porcentaje de discapacidad es requerido.'),
  }),
  disabilities: mixed().when('hasDisability', {
    is: true,
    then: array()
      .of(object())
      .required('Debe de seleccionar al menos un tipo de discapacidad.')
      .nullable()
      .min(1, 'Debe de seleccionar al menos un tipo de discapacidad.'),
  }),
});

export const ContractDetailsSchema = object().shape({
  collaboratorType: string(),
  journeyType: object().required('Seleccione un tipo de jornada.').nullable(),
  journeyHours: number().when('journeyType', (journeyType, ContractDetailsSchema) => {
    return journeyType?.id === 'TP'
      ? ContractDetailsSchema.min(1, 'Mínimo 1 hora')
          .max(12, 'Máximo 12 horas')
          .required('La cantidad de horas es requerida.')
      : ContractDetailsSchema.nullable();
  }),
  enterDate: date()
    .required('La fecha de ingreso es requerida.')
    .typeError('La fecha es inválida, click para seleccionar.'),
  // position: object().required('El cargo es requerido.').nullable(),
  campus: array()
    .of(object())
    .required('Debe de seleccionar al menos un campus.')
    .nullable()
    .min(1, 'Debe de seleccionar al menos un campus.'),
  educationLevels: array()
    .of(object())
    .when('collaboratorType', (collaboratorType, schema) => {
      return collaboratorType !== 'A'
        ? schema
            .required('Seleccione una o varias unidades académicas')
            .min(1, 'Seleccione una o varias unidades académicas')
            .typeError('Seleccione una o varias unidades académicas')
        : schema.nullable();
    }),
  principalEducationLevel: object()
    .typeError('Seleccione una unidad académica principal.')
    .when('collaboratorType', (collaboratorType, schema) => {
      return collaboratorType !== 'A'
        ? schema.required('Seleccione una unidad académica principal.').nullable()
        : schema.nullable();
    }),
  collaboratorEmail: string().email('Ingrese un email válido.').required('El email es requerido.'),
  hasNombramiento: boolean(),
  startNombramientoDate: mixed().when('hasNombramiento', {
    is: true,
    then: date()
      .required('La fecha de ingreso es requerida.')
      .typeError('La fecha es inválida, click para seleccionar.'),
  }),
  status: object().required('El estado del colaborador es requerido.').nullable(),
});

/**
 * Esquemas para las validaciones de la edicion
 */

export const PersonalEditDataSchema = object().shape({
  identificationTypeKeyword: string().required('Seleccione un tipo de identificación.'),
  identification: string()
    .trim()
    .required('El número de identificación es requerido.')
    .when('identificationType', (identificationType, PersonalDataSchema) => {
      return identificationType?.cat_keyword === 'cedula'
        ? PersonalDataSchema.matches(/^\d+$/, 'Solo se aceptan números.')
            .min(10, 'Ingrese al menos 10 dígitos.')
            .test('validateCedula', 'Cédula no válida.', validateCedula)
        : identificationType?.cat_keyword === 'ruc'
        ? PersonalDataSchema.matches(/^\d+$/, 'Solo se aceptan números.').min(
            13,
            'Ingrese al menos 13 dígitos.'
          )
        : PersonalDataSchema.nullable();
    }),
  firstName: string().required('El primer nombre es requerido.'),
  lastName: string(),
  gender: string().required('Seleccione un género.'),
  birthDate: date()
    .max(new Date(), 'La fecha nacimiento es mayor a fecha actual.')
    .required('La fecha de nacimiento es requerida.')
    .typeError('La fecha es inválida, click para seleccionar.'),
  homeAddress: string().required('La dirección domiciliaria es requerida.'),
  cityKeyword: string().required('Seleccione una ciudad.'),
  currentCityKeyword: string().required('Seleccione una ciudad de residencia.').nullable(),
  sectorKeyword: string().required('Seleccione un sector.').nullable(),
  nacionalityKeyword: string().required('Seleccione una nacionalidad.').nullable(),
});

export const DisabilitiesEditSchema = object().shape({
  hasDisability: boolean(),
  numDisabilityCertificate: string().when('hasDisability', {
    is: true,
    then: string().required('El número del certificado de discapacidad es requerido.'),
  }),
  disabilityPercentage: mixed().when('hasDisability', {
    is: true,
    then: number()
      .min(1, 'El porcentaje mínimo es 1%')
      .max(100, 'El porcentaje máximo es 100%')
      .required('El porcentaje de discapacidad es requerido.'),
  }),
});

export const ComplementaryEditDataSchema = object().shape({
  proffesion: string(),
  studyReason: string(),
  hasVehicle: boolean(),
  taxPayersHousehold: number()
    .typeError('El valor debe ser numérico')
    .required('Ingrese un valor mayor a 0'),
  numChild: number()
    .typeError('El valor debe ser numérico')
    .required('Ingrese un valor desde 0 en adelante.'),
  viviendaKeyword: string().required('Seleccione un tipo de vivienda.'),
  numBedrooms: number()
    .typeError('El valor debe ser numérico')
    .required('Ingrese un valor mayor a 0'),
  religionKeyword: string().required('Seleccione una religión.'),
  etnicKeyword: string().required('Seleccione un tipo de etnia.'),
  civilStatusKeyword: string().required('Seleccione un tipo de estado civil').nullable(),
});
