import { useAppDispatch, useAppSelector } from '../../../../../../hooks/useRedux';
import CustomSideDialog from '../../../../../shared/components/dialogs/CustomSideDialog';
import { setQuestionsIdSelected, setQuestionsSideDialogOpen } from '../slices/questionSlice';
import QuestionForm from './QuestionForm';

const QuestionSideDialog = () => {
  const dispatch = useAppDispatch();

  const { questionsIdSelected } = useAppSelector((state) => state.questions);

  const handleClose = () => {
    dispatch(setQuestionsSideDialogOpen(false));
    dispatch(setQuestionsIdSelected(null));
  };

  return (
    <CustomSideDialog
      open
      handleCloseDialog={handleClose}
      title={questionsIdSelected ? 'Editar Pregunta' : 'Agregar Pregunta'}
    >
      <QuestionForm />
    </CustomSideDialog>
  );
};

export default QuestionSideDialog;
