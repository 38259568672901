import {
  Autocomplete,
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  TextField,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useAppSelector } from '../../../../hooks/useRedux';
import InputLabel from '../../../shared/components/forms/InputLabel';
import { useGetCatalogByAcronymQuery } from '../../../shared/slices/catalogApi';
import * as yup from 'yup';
import { useGetCollaboratorsQuery } from '../../collaborators/slices/collaboratorsApiSlice';
import { useSendApplicationRrHhMutation } from '../../slices/rrhhApiSlice';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import NoCollaboratorInfo from '../../messages/NoCollaboratorInfo';

const AdvanceForm = () => {
  const { currentProfile } = useAppSelector((state) => state.access);
  const { userInfo } = useAppSelector((state) => state.auth);

  const { data, isLoading } = useGetCatalogByAcronymQuery({
    acronym: 'DE',
    profileId: currentProfile?.profileId!,
  });

  const { data: collaborators, isLoading: isLoadingCollaborators } = useGetCollaboratorsQuery({
    page: 1,
    perPage: 100,
    profileId: currentProfile?.profileId!,
    search: '',
  });

  const currency = /(?=.)^\$?(([1-9][0-9]{0,2}(,[0-9]{3})*)|0)?(\.[0-9]{1,2})?$/;

  const schema = yup.object().shape({
    value: yup
      .string()
      .matches(currency, 'El valor no tiene el formato correcto')
      .required('Valor es requerido'),
    reason: yup.string().required('Motivo es requerido'),
    discount: yup.string().required('Tipo de descuento es requerido'),
    boss: yup.string().required('Jefe Inmediato es requerido'),
  });

  const [
    sendApp,
    {
      isLoading: isLoadingApp,
      isSuccess: isSuccessApp,
      isError: isErrorApp,
      error: errorApp,
      reset,
    },
  ] = useSendApplicationRrHhMutation();

  const formik = useFormik({
    initialValues: {
      value: '',
      reason: '',
      discount: '',
      boss: '',
    },
    validationSchema: schema,
    onSubmit: (values) => {
      sendApp({
        type_application_acronym: 'DOCANT',
        type_application_id: 30,
        collaborator_id: userInfo?.collaboratorInfo?.id!,
        immediate_boss_id: Number(values.boss),
        amount: values.value,
        discount_in: values.discount,
        reason: values.reason,
        user_profile_id: currentProfile?.profileId!,
      });
    },
  });

  if (!userInfo.collaboratorInfo) {
    return <NoCollaboratorInfo />;
  }

  return (
    <Grid
      container
      direction="column"
      spacing={2}
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <Grid item container>
        <Grid item xs={12} md={4}>
          <InputLabel>Valor a anticipar</InputLabel>
          <TextField
            placeholder="Ingrese valor"
            //type="number"
            fullWidth
            InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
            value={formik.values.value}
            onChange={({ target }) => formik.setFieldValue('value', target.value)}
            error={Boolean(formik.errors.value && formik.touched.value)}
            helperText={Boolean(formik.errors.value && formik.touched.value) && formik.errors.value}
          />
        </Grid>
      </Grid>
      <Grid item container>
        <Grid item xs={12} md={4}>
          <InputLabel>Motivo</InputLabel>
          <TextField
            placeholder="Ingrese mótivo"
            multiline
            rows={4}
            fullWidth
            onChange={({ target }) => formik.setFieldValue('reason', target.value)}
            error={Boolean(formik.errors.reason && formik.touched.reason)}
            helperText={
              Boolean(formik.errors.reason && formik.touched.reason) && formik.errors.reason
            }
          />
        </Grid>
      </Grid>
      <Grid item container>
        <Grid item xs={12} md={4}>
          <InputLabel>Descontar en</InputLabel>
          {isLoading ? (
            <TextField placeholder="Cargando..." fullWidth />
          ) : (
            <Autocomplete
              options={
                data?.map((ch) => ({
                  value: ch.id,
                  label: ch.cat_name,
                })) || []
              }
              clearIcon={null}
              onChange={(_, val) => {
                if (val) {
                  formik.setFieldValue('discount', val.value);
                }
              }}
              renderInput={(props) => (
                <TextField
                  {...props}
                  placeholder="Seleccione Decuento"
                  error={Boolean(formik.errors.discount && formik.touched.discount)}
                  helperText={
                    Boolean(formik.errors.discount && formik.touched.discount) &&
                    formik.errors.discount
                  }
                />
              )}
            />
          )}
        </Grid>
      </Grid>
      <Grid item container>
        <Grid item xs={12} md={4}>
          <InputLabel>Jefe Inmediato</InputLabel>
          {isLoadingCollaborators ? (
            <TextField placeholder="Cargando..." fullWidth />
          ) : (
            <Autocomplete
              options={
                collaborators?.data.map((cl) => ({
                  value: cl.id,
                  label: cl.user.person.pers_full_name || '',
                })) || []
              }
              clearIcon={null}
              onChange={(_, val) => {
                if (val) {
                  formik.setFieldValue('boss', val.value);
                }
              }}
              renderInput={(props) => (
                <TextField
                  {...props}
                  placeholder="Seleccione Jefe"
                  error={Boolean(formik.errors.boss && formik.touched.boss)}
                  helperText={
                    Boolean(formik.errors.boss && formik.touched.boss) && formik.errors.boss
                  }
                />
              )}
            />
          )}
        </Grid>
      </Grid>
      <Grid item container>
        <Grid item xs={12} md={4}>
          {isSuccessApp && (
            <SuccessAlert message="Solicitud enviada correctamente" handleDismiss={reset} />
          )}
          {isErrorApp && <ErrorAlert message={String(errorApp)} handleDismiss={reset} />}
        </Grid>
      </Grid>
      <Grid item container>
        <Grid item xs={12} md={4} textAlign="center">
          <Button
            variant="contained"
            onClick={() => formik.handleSubmit()}
            disabled={isLoadingApp}
            endIcon={isLoadingApp && <CircularProgress size={20} />}
          >
            Solicitar
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AdvanceForm;
