import { middlewareApi } from '../../../middleware/middlewareApi';
import { mainApi } from '../../../../api/mainApi';

const thesisApi = middlewareApi.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * @POST
     */
    addThesis: builder.mutation<
      any,
      {
        payload: {
          title: string;
          subtitle: string;
          isbn: string;
          version: string;
          initial_copies_number: 1;
          origin: string;
          book_cover: string | null;
          book_url: string | null;
          institution_barcode: string;
          symbol: null | string;
          publish_date: string;
          type_book: string; //Tesis
          status_id: number;
          reason: string;
          procedencia: string;
          description: string;
          bib_clasification_id: string;
          line_research_id: string;
          student_record_id: number;
          collaborator_id: number;
          locations: number[];
          tags: number[];
        };
      }
    >({
      queryFn: async ({ payload }) => {
        try {
          const { data } = await mainApi.post<any>('books', {
            ...payload,
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),
    /**
     * @PUT  EDIT
     */
    editThesis: builder.mutation<
      any,
      {
        id: number;
        payload: {
          title: string;
          subtitle: string;
          isbn: string;
          version: string;
          initial_copies_number: 1;
          origin: string;
          book_cover: string | null;
          book_url: string | null;
          institution_barcode: string;
          symbol: null | string;
          publish_date: string;
          type_book: string; //Tesis
          status_id: number;
          reason: string;
          procedencia: string;
          description: string;
          bib_clasification_id: string;
          line_research_id: string;
          student_record_id: number;
          collaborator_id: number;
          locations: number[];
          tags: number[];
        };
      }
    >({
      queryFn: async ({ id, payload }) => {
        try {
          const { data } = await mainApi.put<any>(`books/${id}`, {
            ...payload,
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),
  }),
});

export const { useAddThesisMutation, useEditThesisMutation } = thesisApi;
