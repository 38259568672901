import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import ConfirmAlert from '../../../shared/components/dialogs/ConfirmAlert';
import { useReserveBookMutation } from '../slices/libraryDashboardApiSlice';
import { setBookIdToReserve, setReserveBookModalOpen } from '../slices/libraryDashboardSlice';
import { format, add } from 'date-fns';

const ReserveConfirmationDialog = () => {
  const dispatch = useAppDispatch();
  const [reserve, { isLoading, isError, isSuccess, error }] = useReserveBookMutation();

  const { bookIdToReserve } = useAppSelector((state) => state.libraryDashboard);
  const { currentProfile } = useAppSelector((state) => state.access);
  //const { userInfo } = useAppSelector((state) => state.auth);

  const handleConfirm = async () => {
    // bookId: bookIdToReserve!,
    // profileId: currentProfile?.profileId!,
    // userId: Number(userInfo?.id!),
    await reserve({
      payload: {
        user_profile_id: currentProfile?.profileId!,
        borrows: [
          {
            model_type: 'book',
            model_id: Number(bookIdToReserve!),
            borrow_date: format(new Date(), 'yyyy-MM-dd'),
            borrow_return_date: format(add(new Date(), { days: 2 }), 'yyyy-MM-dd'),
            copies_amount: 1,
            type: 'Interno',
            selected_user_profile_id: currentProfile?.profileId!,
            details: '',
          },
        ],
      },
    });
  };

  const handleClose = () => {
    dispatch(setBookIdToReserve(null));
    dispatch(setReserveBookModalOpen(false));
  };

  return (
    <ConfirmAlert
      error={error as string}
      title="¿Desea reservar este libro?"
      subtitle="Este libro será reservado para usted"
      handleDelete={handleConfirm}
      isError={isError}
      isLoading={isLoading}
      isSuccess={isSuccess}
      confirmBtnMessage="Reservar"
      successMessage="Libro reservado con éxito"
      open
      onClose={handleClose}
    />
  );
};

export default ReserveConfirmationDialog;
