import * as Yup from 'yup';

const onlyNumbers = /^[0-9]*$/;

export const CampusInfoSchema = Yup.object().shape({
  name: Yup.string().required('El nombre es requerido.'),
  isVirtual: Yup.boolean().required('El tipo de sede es requerido.'),
  description: Yup.string(),
  direction: Yup.string().when('isVirtual', {
    is: false,
    then: Yup.string().required('La dirección es requerida.'),
  }),
  initials: Yup.string()
    .required('La inicial es requerida.')
    .min(3, 'Deben ser mínimo 3 caracteres'),
  latitude: Yup.number().when('isVirtual', {
    is: false,
    then: Yup.number().required('La latitud es requerida.'),
  }),
  longitude: Yup.number().when('isVirtual', {
    is: false,
    then: Yup.number().required('La longitud es requerida.'),
  }),
  phone: Yup.string()
    .matches(onlyNumbers, 'El número de teléfono solo puede contener números')
    .required('El teléfono es requerido.'),
  cellphone: Yup.string()
    .matches(onlyNumbers, 'El número de celular solo puede contener números')
    .required('El celular es requerido.'),
  statusId: Yup.number().required('El estado de la sede es requerida.'),
});
