import * as React from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import { Form, Formik, FormikConfig, FormikValues } from 'formik';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

export interface FormikStepProps
  extends Pick<FormikConfig<FormikValues>, 'children' | 'validationSchema'> {
  label: string;
}

export const FormikStep = ({ children }: FormikStepProps) => {
  return <>{children}</>;
};

export const CollaboratorsStepper = ({ children, ...props }: FormikConfig<FormikValues>) => {
  const childrenArray = React.Children.toArray(children) as React.ReactElement<FormikStepProps>[];

  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down('md'));

  const [step, setStep] = React.useState(0);
  const [completed, setCompleted] = React.useState(false);

  const currentChild = childrenArray[step];

  const isLastStep = () => step === childrenArray.length - 1;

  return (
    <Formik
      {...props}
      validationSchema={currentChild.props.validationSchema}
      onSubmit={async (values, helpers) => {
        if (isLastStep()) {
          await props.onSubmit(values, helpers);
          setCompleted(true);
        } else {
          setStep((s) => s + 1);
        }
      }}
    >
      {({ isSubmitting }) => (
        <Grid item xs={12} container>
          <Form autoComplete="off" style={{ width: '100%' }}>
            <Stepper
              sx={{ my: 3 }}
              activeStep={step}
              orientation={matches ? 'vertical' : 'horizontal'}
            >
              {childrenArray.map((child, index) => (
                <Step key={child.props.label} completed={step > index || completed}>
                  <StepLabel>{child.props.label}</StepLabel>
                </Step>
              ))}
            </Stepper>

            {currentChild}

            <Grid container spacing={2} justifyContent="flex-end">
              {step > 0 ? (
                <Grid item>
                  <Button
                    disabled={isSubmitting}
                    variant="contained"
                    color="primary"
                    onClick={() => setStep((s) => s - 1)}
                  >
                    Regresar
                  </Button>
                </Grid>
              ) : null}
              <Grid item>
                <Button
                  startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
                  disabled={isSubmitting}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  {isSubmitting ? 'Agregando' : isLastStep() ? 'Finalizar' : 'Siguiente'}
                </Button>
              </Grid>
            </Grid>
          </Form>
        </Grid>
      )}
    </Formik>
  );
};
