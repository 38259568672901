import { useEffect, useState } from 'react';
import { Avatar, Grid, Typography, useTheme, useMediaQuery, Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { IconCalendarEvent, IconHome, IconPictureInPicture, IconSchool } from '@tabler/icons';
import { Book } from 'phosphor-react';

import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import { useGetCourseVCQuery } from '../../slices/virtualClassroomApiSlice';
import { resetVirtualClassroomStudent } from '../../slices/virtualClassroomSlice';
import { renderSkeletonTable } from '../../../../shared/helpers/render';
import { setCurrentVirtualClassroom } from '../../../../shared/reducers/accessReducer';
import {
  setCurrentCourse,
  resetCurrentCourse,
  setIsFinishedCourse,
} from '../../shared/virtualClassroomSharedSlice';
import { VCStudentCourse } from '../../evaluations/interfaces/evaluationPayload.interfaces';
import { useTranslation } from 'react-i18next';
import BlackboardButton from '../../../../shared/VCButtons/BlackboardButton';
import MoodleButton from '../../../../shared/VCButtons/MoodleButton';

export const useStyles = makeStyles((theme) => ({
  avatar: {
    width: '72px',
    height: '72px',
  },
  avatarMobile: {
    width: '50px',
    height: '50px',
  },
  title: {
    marginLeft: '5px',
  },
}));

const VCStudentHeader = () => {
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

  const dispatch = useAppDispatch();

  const classes = useStyles();

  const [course, setCourse] = useState<VCStudentCourse | null>(
    JSON.parse(localStorage.getItem('virtualClassroomStudent')!) || null
  );

  const { t } = useTranslation();

  useEffect(() => {
    setCourse(JSON.parse(localStorage.getItem('virtualClassroomStudent')!) || null);
  }, []);

  useEffect(() => {
    if (course) {
      dispatch(setCurrentCourse(course));
    }
  }, [course, dispatch]);

  const { currentProfile, currentVirtualClassroom } = useAppSelector((state) => state.access);

  const {
    course: {
      collaborator,
      courseParalel,
      courseName,
      courseId,
      courseHourhandDescription,
      collaboratorEmail,
    },
    IsFinishedCourse,
  } = useAppSelector((state) => state.virtualClassroomStudentShared);

  /**
   * Por cada vez que se invoca al detalle del curso de la cabecera
   * el query establece setCurrentVirtualClassroom(true).
   */
  const { data, isLoading } = useGetCourseVCQuery(
    {
      courseId: courseId!,
      profileId: currentProfile?.profileId!,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: courseId === 0 || courseId === undefined,
    }
  );

  useEffect(() => {
    if (!!data?.id) {
      const isFinishedCourse = new Date() > new Date(data?.end_date || '');

      dispatch(setIsFinishedCourse(isFinishedCourse));
    }
  }, [data, dispatch]);

  useEffect(() => {
    if (!currentVirtualClassroom) dispatch(setCurrentVirtualClassroom(true));
  }, [currentVirtualClassroom, dispatch]);

  useEffect(() => {
    return () => {
      localStorage.removeItem('virtualClassroomStudent');
      dispatch(resetCurrentCourse());
      dispatch(resetVirtualClassroomStudent());
      dispatch(setCurrentVirtualClassroom(false));
    };
  }, [dispatch]);

  return (
    <>
      {isLoading ? (
        renderSkeletonTable(1, 3)
      ) : (
        <Grid container justifyContent="space-between" flexDirection="row">
          <Grid item xs={12} md={6} flexDirection="row">
            <Grid item sx={{ ml: 3 }}>
              <Grid item container alignItems="center" spacing={1}>
                <Grid item>
                  <Avatar
                    sx={{ bgcolor: 'white' }}
                    className={!matchDownMd ? classes.avatar : classes.avatarMobile}
                  >
                    <Book size={!matchDownMd ? 40 : 35} />
                  </Avatar>
                </Grid>
                <Grid item xs style={{ marginLeft: 7 }} zeroMinWidth>
                  <Typography
                    variant="h5"
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      display: 'block',
                    }}
                  >
                    {t('studentVirtualClassroom.header.course')}
                  </Typography>
                  <Typography
                    variant={!matchDownMd ? 'h3' : 'h4'}
                    sx={{
                      overflow: 'hidden',
                      wordWrap: 'break-word',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      WebkitLineClamp: 5,
                      WebkitBoxOrient: 'vertical',
                      maxHeight: '10.5em',
                    }}
                  >
                    {courseName}
                  </Typography>
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item container direction="row" alignItems="center">
                      <Typography variant="h5">{collaborator}</Typography>
                    </Grid>
                  </Grid>
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item container direction="row" alignItems="center">
                      <Typography variant="h5">{collaboratorEmail}</Typography>
                    </Grid>
                  </Grid>
                  {IsFinishedCourse && (
                    <Grid item container direction="row" alignItems="center" mt={1}>
                      <Chip
                        label={t('teacherVirtualClassroom.header.isFinished')}
                        color={'error' as any}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4} flexDirection="row">
            <Grid item sx={{ mb: 3 }}>
              <Grid item container alignItems="center" spacing={1}>
                <Grid item container direction="row" alignItems="center">
                  <IconSchool color={theme.palette.primary.dark} />
                  <Typography variant={!matchDownMd ? 'h5' : 'h6'} className={classes.title}>
                    {t('studentVirtualClassroom.header.campus')}: {course?.campusName}
                  </Typography>
                </Grid>
                <Grid item container direction="row" alignItems="center">
                  <IconPictureInPicture color={theme.palette.primary.dark} />
                  <Typography variant={!matchDownMd ? 'h5' : 'h6'} className={classes.title}>
                    {t('studentVirtualClassroom.header.paralel')}: {courseParalel}
                  </Typography>
                </Grid>
                {data && data.classroom && (
                  <Grid item container direction="row" alignItems="center">
                    <IconHome color={theme.palette.primary.dark} />
                    <Typography variant={!matchDownMd ? 'h5' : 'h6'} className={classes.title}>
                      {t('studentVirtualClassroom.header.classroom')}: {data.classroom.cl_name}
                    </Typography>
                  </Grid>
                )}
                <Grid item container direction="row" alignItems="center">
                  <IconCalendarEvent color={theme.palette.primary.dark} />
                  <Typography variant={!matchDownMd ? 'h5' : 'h6'} className={classes.title}>
                    {courseHourhandDescription}
                  </Typography>
                </Grid>

                {course?.vcType === 'blackboard' && (
                  <Grid item container direction="row" mt={2} alignItems="center">
                    <BlackboardButton />
                  </Grid>
                )}

                {course?.vcType === 'moodle' && (
                  <Grid item container direction="row" mt={2} alignItems="center">
                    <MoodleButton />
                  </Grid>
                )}

                {/* <Grid item container alignItems="center">
                  <ButtonBase>
                    <Grid
                      item
                      container
                      direction="row"
                      alignItems="center"
                      width="auto"
                      justifyContent="center"
                      sx={{
                        border: `3px solid ${theme.palette.primary.main}`,
                        borderRadius: '10px',
                      }}
                    >
                      <Grid item marginX={1}>
                        <Typography
                          variant={!matchDownMd ? 'h4' : 'h5'}
                          color="primary"
                          sx={{ cursor: 'pointer' }}
                        >
                          {t('studentVirtualClassroom.header.joinClass')}
                        </Typography>
                        <Typography
                          variant={!matchDownMd ? 'h4' : 'h5'}
                          color="primary"
                          sx={{ cursor: 'pointer' }}
                        >
                          {t('studentVirtualClassroom.header.durationClass')}
                        </Typography>
                      </Grid>
                      <Grid item marginX={1}>
                        <IconButton disableRipple>
                          <LoginOutlined
                            fontSize={!matchDownMd ? 'large' : 'medium'}
                            color="primary"
                          />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </ButtonBase>
                </Grid> */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default VCStudentHeader;
