import DeleteAlert from '../../../shared/components/dialogs/DeleteAlert';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { useDeletePensumMutation } from '../slices/pensumsApiSlice';
import { resetPensumIdToDelete, setDeleteModalOpen } from '../slices/pensumsSlice';

const DeletePensumDialog = () => {
  const dispatch = useAppDispatch();

  const { deleteModalOpen, pensumIdToDelete } = useAppSelector((state) => state.pensums);
  const { currentProfile } = useAppSelector((state) => state.access);

  const [deletePeriod, { isLoading, isSuccess, isError, error }] = useDeletePensumMutation();

  const handleDelete = () => {
    deletePeriod({ profileId: currentProfile?.profileId!, pensumId: pensumIdToDelete! });
  };

  const handleClose = () => {
    dispatch(setDeleteModalOpen(false));
    dispatch(resetPensumIdToDelete());
  };

  return (
    <DeleteAlert
      open={deleteModalOpen}
      onClose={handleClose}
      isLoading={isLoading}
      deleteTitle="Título personalizado"
      isSuccess={isSuccess}
      isError={isError}
      handleDelete={handleDelete}
      error={error as string}
    />
  );
};

export default DeletePensumDialog;
