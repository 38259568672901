import { Button, Grid, Typography, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
//import { DatePicker, LocalizationProvider } from '@material-ui/lab';
//import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import MainCard from '../../../../shared/components/cards/MainCard';
import { useTranslation } from 'react-i18next';

// import DateTimePickers from './DateTimePickers';
//import esLocale from 'date-fns/locale/es';
//import { TextField } from 'formik-material-ui';
const Styles = makeStyles({
  boton: {
    '&.css-sghohy-MuiButtonBase-root-MuiButton-root ': {
      borderRadius: '50px',
    },
    '&.css-sghohy-MuiButtonBase-root-MuiButton-root.Mui-disabled': {
      fontWeight: 'medium',
      color: 'white',
    },
  },
});
const HeroAsistencia: React.FC<{
  img: string;
}> = ({ img }) => {
  const theme = useTheme();
  const [Fecha] = useState<Date | null>(null);
  const styles = Styles();

  const { path } = useRouteMatch();

  const { t } = useTranslation();

  return (
    <Grid
      container
      alignItems={'center'}
      component={MainCard}
      justifyContent="center"
      minHeight="50vh"
      sx={{
        borderTopLeftRadius: '0px',
        borderTopRightRadius: '0px',
      }}
    >
      {/* <Typography variant="h3" textAlign={'center'}>
        {t('teacher_virtual_classroom.assistants_app_list.hero')}
      </Typography> */}
      <Grid container alignItems={'center'} justifyContent={'center'}>
        <Grid item md={8}>
          <img width={'100%'} src={img} alt="" />
        </Grid>
        <Grid item md={8}>
          <Typography variant="h3" textAlign={'center'}>
            {t('teacher_virtual_classroom.assistants_app_list.hero')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {/*
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
              <DatePicker
                value={''}
                onChange={(newValue) => 'sqa'}
                renderInput={(params) => (
                  <TextField
                    placeholder="Fecha de creación"
                    // error={Boolean(errors.creationDate && touched.creationDate)}
                    helperText=""
                    fullWidth
                  />
                )}
              />
            </LocalizationProvider>
                */}
        </Grid>
      </Grid>
      <Grid container justifyContent="center">
        <Grid item justifyContent="center" textAlign={'center'}>
          {/* <DateTimePickers Fecha={Fecha} setFecha={setFecha} /> */}
          <>
            <Button style={{ margin: '10px 0' }} variant="outlined" className={styles.boton}>
              <Link
                style={{ textDecoration: 'none', color: theme.palette.primary.main }}
                to={{ pathname: `${path}/calificar`, state: Fecha }}
                //onChange={() => History.push(`${path}/calificar`)}
              >
                {t('teacher_virtual_classroom.assistants_app_list.hero.calificate')}
              </Link>
            </Button>

            <Button style={{ margin: '10px 10px' }} variant="contained" className={styles.boton}>
              <Link
                style={{ textDecoration: 'none', color: 'white' }}
                to={{ pathname: `${path}/listar`, state: Fecha }}
                //onChange={() => History.push(`${path}/calificar`)}
              >
                {t('teacher_virtual_classroom.assistants_app_list.hero.view_list')}
              </Link>
            </Button>
          </>
        </Grid>
      </Grid>{' '}
    </Grid>
  );
};

export default HeroAsistencia;
