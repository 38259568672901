import { useState } from 'react';

import { IconButton, Tooltip, Chip, Stack, Typography, Skeleton } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { IconCheck, IconPlus, IconTrash, IconX } from '@tabler/icons';
import { useAppSelector } from '../../../../../hooks/useRedux';
import { useGetCareerbyAcademicOfferIdAndSearchQuery } from '../../../academic-offerings/slices/academicOfferingsApiSlice';
import SearchPaginatedTable from '../../../../shared/components/tables/SearchPaginatedTable';
import StatusChip from '../../../../shared/components/badges/StatusChip';
import DeleteAlert from '../../../../shared/components/dialogs/DeleteAlert';
import { useUpdateEducationLevelMutation } from '../../../education-levels/slices/educationLevelsApiSlice';
//import SearchPaginatedTable from '../../../../shared/components/tables/SearchPaginatedTable';

const MeshGridView = () => {
  const { currentProfile } = useAppSelector((state) => state.access);
  const profileId = currentProfile!.profileId;
  const { groupId } = useParams<{ groupId: string }>();
  const [carrerId, setCarrerId] = useState<number | undefined>();

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const [search, setSearch] = useState('');

  const { groupAreaList } = useAppSelector((state) => state.groupAreas);

  const groupArea = groupAreaList.find((ga) => ga.id === Number(groupId));

  const { isLoading, error, data, isFetching } = useGetCareerbyAcademicOfferIdAndSearchQuery(
    {
      profileId,
      page,
      perPage,
      search,
      academicOfferingId: '2',
      group_area_id: groupId,
    },
    { refetchOnMountOrArgChange: 30 }
  );

  const [
    UpdateEducationLevel,
    {
      isLoading: isLoadingRemove,
      isSuccess: isSuccessRemove,
      error: errorUpdate,
      isError: isErrorUpdate,
      reset,
    },
  ] = useUpdateEducationLevelMutation();

  const handleDelete = async () => {
    const carreer = data?.data.find((cr) => cr.id === carrerId);

    if (carreer) {
      await UpdateEducationLevel({
        profileId: currentProfile?.profileId!,
        educationLevelId: carreer.id,
        educationLevelPayload: {
          edu_name: carreer.edu_name,
          edu_alias: carreer.edu_alias,
          edu_order: carreer.edu_order,
          principal_id: carreer.principal_id,
          offer_id: carreer.offer_id,
          status_id: carreer.status_id,
          group_area_id: Boolean(carreer.group_area_id) ? null : groupId,
        },
      });
    }
  };

  return (
    <>
      <Stack
        direction="row"
        spacing={2}
        sx={{
          mb: 2,
          p: 3,
          border: 1,
          borderRadius: '10px',
          borderColor: 'ButtonHighlight',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography variant="h3">Grupo de Nivelación:</Typography>
        <Typography variant="h2">
          {groupArea?.arg_name ? groupArea?.arg_name : <Skeleton width={100} />}
        </Typography>
      </Stack>
      <SearchPaginatedTable
        data={
          data?.data.map((car, index) => ({
            id: data?.from + index,
            name: car.edu_name,
            assign: car.group_area_id,
            status: car.status_id,
            options: '',
          })) || []
        }
        headers={{
          id: 'Nº',
          name: 'Nombre',
          assign: 'Asignado',
          status: 'Estado',
          options: 'Opciones',
        }}
        error={error}
        isFetching={isFetching}
        isLoading={isLoading}
        keyExtractor={(row) => String(row.id)}
        numHeader={5}
        page={page}
        perPage={perPage}
        search=""
        searchPlacehoder="Buscar Carrera"
        setPage={setPage}
        setPerPage={setPerPage}
        setSearch={setSearch}
        total={data?.total!}
        // ActionButtons={
        //   <AddButton
        //     onClick={() => {
        //       history.push('carreras/agregar');
        //     }}
        //   />
        // }
        customRenderers={{
          status: (row) => <StatusChip statusId={Number(row.status)} />,
          assign: (row) => (
            <Chip
              icon={Boolean(row.assign) ? <IconCheck /> : <IconX />}
              label={Boolean(row.assign) ? 'Si' : 'No'}
            />
          ),
          options: (row) => (
            <>
              {Boolean(row.assign) && (
                <Tooltip title="Quitar">
                  <IconButton
                    onClick={() => {
                      setCarrerId(
                        data?.data.find((cr, index) => index === row.id - Number(data?.from!))?.id
                      );
                    }}
                  >
                    <IconTrash />
                  </IconButton>
                </Tooltip>
              )}
              {!Boolean(row.assign) && (
                <Tooltip title="Agregar">
                  <IconButton
                    onClick={() => {
                      setCarrerId(
                        data?.data.find((cr, index) => index === row.id - Number(data?.from!))?.id
                      );
                    }}
                  >
                    <IconPlus />
                  </IconButton>
                </Tooltip>
              )}
            </>
          ),
        }}
        customDataCellsProperties={{
          status: {
            align: 'center',
          },
          options: {
            align: 'center',
          },
        }}
      />
      <DeleteAlert
        open={Boolean(carrerId)}
        error={isErrorUpdate ? String(errorUpdate) : ''}
        isError={isErrorUpdate}
        onClose={() => {
          setCarrerId(undefined);
          reset();
        }}
        handleDelete={handleDelete}
        isLoading={isLoadingRemove}
        isSuccess={isSuccessRemove}
        deleteTitle="Se cambiara el estado de asiganción de la carrera seleccionada"
        deleteSubTitle="¿Desea continar?"
        deleteSuccess="Se actualizado el estado de la carrera correctamente"
        deleteBtnText="Sí. Continuar"
      />
    </>
  );
};

export default MeshGridView;
