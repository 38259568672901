import DeleteAlert from '../../../../../shared/components/dialogs/DeleteAlert';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/useRedux';
import { useDeleteIntegralEvaluationMutation } from '../slices/integralEvaluationApiSlice';
import {
  setIntegralEvaluationsDeleteModalOpen,
  setIntegralEvaluationsIdToDelete,
} from '../slices/integralEvaluationSlice';

const IntegralEvaluationDeleteDialog = () => {
  const dispatch = useAppDispatch();
  const { integralEvaluationsIdToDelete, integralEvaluationsDeleteModalOpen } = useAppSelector(
    (state) => state.integralEvaluation
  );

  const [deleteIntegralEvaluation, { isLoading, isSuccess, error, isError }] =
    useDeleteIntegralEvaluationMutation();

  const handleDelete = () => {
    deleteIntegralEvaluation({
      id: integralEvaluationsIdToDelete!,
    });
  };
  const handleClose = () => {
    dispatch(setIntegralEvaluationsIdToDelete(null));
    dispatch(setIntegralEvaluationsDeleteModalOpen(false));
  };

  return (
    <DeleteAlert
      open={integralEvaluationsDeleteModalOpen}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      error={(error as any)}
      handleDelete={handleDelete}
      onClose={handleClose}
    />
  );
};

export default IntegralEvaluationDeleteDialog;
