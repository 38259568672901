import { Grid, Typography } from '@material-ui/core';
import MainCard from '../../../shared/components/cards/MainCard';
import ListReservations from '../components/ListReservations';

const ReservationsScreen = () => {
  return (
    <Grid component={MainCard} sx={{ p: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">Reservas</Typography>
      </Grid>
      <ListReservations />
    </Grid>
  );
};

export default ReservationsScreen;
