import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/useRedux';
import BackButton from '../../../../../shared/components/buttons/BackButton';
import MainCard from '../../../../../shared/components/cards/MainCard';
import { useLazyGetTemplateEvaQuery } from '../slices/evaTemplatesApiSlice';
import { setTemplatesList } from '../slices/evaTemplateSlice';
import LoadingScreen from '../../../../../shared/screens/LoadingScreen';
import PreviewEvaluationInfoBox from '../components/preview/PreviewEvaluationInfoBox';
import PreviewQuestions from '../components/preview/PreviewQuestions';

const PreviewTemplateScreen = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { id } = useParams<{ id: string }>();

  const { templateList } = useAppSelector((state) => state.templateEva);

  const exist = templateList.find((t) => t.id === Number(id));

  const [getTemplate] = useLazyGetTemplateEvaQuery({});

  useEffect(() => {
    if (!templateList.length || !exist) {
      getTemplate({ id }).then((res) => {
        if (res.data) {
          dispatch(setTemplatesList([res.data]));
        }
      });
    }
  }, [id, exist, getTemplate, templateList.length, dispatch]);

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid container alignItems="center" sx={{ mb: 3 }}>
        <BackButton />
        <Typography variant="h2">
          {t('Previsualizar formato de rúbrica')} {exist ? `- ${exist.name}` : ''}
        </Typography>
      </Grid>
      {!exist ? (
        <LoadingScreen />
      ) : (
        <Grid item xs={12}>
          <PreviewEvaluationInfoBox />
          <PreviewQuestions />
        </Grid>
      )}
    </Grid>
  );
};

export default PreviewTemplateScreen;
