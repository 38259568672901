import { makeStyles } from "@material-ui/core";

export const stylesStudentAsignedDetail = makeStyles((theme) => ({
    container: {
      '&>div:nth-child(3)': {
        justifyContent: 'flex-end',
        '&>div': {
          margin: '0px',
          borderRadius: '0px',
          // maxWidth: '450px',
          maxWidth: '100%',
          maxHeight: '100%',
        },
      },
    },
    paper: {
      minHeight: '100vh',
      width: '45%',
    },
  }));
  

  export const stylesStudentAsignedSendMessage = makeStyles((theme) => ({
    container: {
      '&>div:nth-child(3)': {
        justifyContent: 'flex-end',
        '&>div': {
          margin: '0px',
          borderRadius: '0px',
          // maxWidth: '450px',
          maxWidth: '80%',
          maxHeight: '100%',
        },
      },
    },
    paper: {
      minHeight: '100vh',
      width: '35%',
    },
  }));
  

  export const useStyles = makeStyles((theme) => ({
    textPrimary: {
        color: theme.palette.primary.main
    },
    container: {
        position: 'relative',
        '&>*': {
            position: 'relative',
            zIndex: '5'
        },
        '&:after': {
            content: '""',
            position: 'absolute',
            top: '0',
            left: '43px',
            width: '2px',
            height: '100%',
            background: '#ebebeb',
            zIndex: '1'
        }
    },
    avatar: {
        backgroundColor: theme.palette.grey[100],
        color: theme.palette.primary[300],
        fontSize: '1rem'
    },
    paper: {
        padding: '20px',
        boxShadow: 'none',
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
        border: '1px dashed',
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.dark : theme.palette.primary.dark
    },
    tooltip: {
        backgroundColor: theme.palette.grey[300],
        zIndex: 5,
        border: '1px solid'
    }
}));
