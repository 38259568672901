import { Grid, Typography } from '@material-ui/core';

import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../../hooks/useRedux';
import MainCard from '../../../shared/components/cards/MainCard';
import ListCategories from '../components/ListCategories';
import DeleteDialog from '../components/DeleteDialog';

const MaintenanceCategory = () => {
  const { t } = useTranslation();

  const { categoryIdToDelete } = useAppSelector((state) => state.categories);

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">{t('financial.maintenance_cat.title')}</Typography>
      </Grid>

      <ListCategories />

      {categoryIdToDelete && <DeleteDialog />}
    </Grid>
  );
};

export default MaintenanceCategory;
