import React, { FC, useRef, useState, useEffect } from 'react';

import {
  Grid,
  TextField,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  useTheme,
  IconButton,
  InputAdornment,
  Button,
  Autocomplete,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import { useTranslation } from 'react-i18next';

import {
  renderErrorOrEmptyRow,
  renderSkeletonRows,
  renderSkeletonTable,
} from '../../../../shared/helpers/render';
import {
  SearchPersonParam,
  // SearchPersonParamResponse,
} from '../../../../shared/interfaces/searchPerson.interface';
import CustomPagination from '../../../../shared/components/tables/CustomPagination';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import { useGetPersonsByParamQuery } from '../../../../shared/slices/searchPersonApi';
import {
  resetClient,
  resetClientMassiveDebts,
  setCurrentClient,
  setCurrentClientMassiveDebts,
} from '../../slices/generateDebtsSlice';
import { filterClients } from '../../constants';

interface Props {
  handleCloseDialog: (e: React.SyntheticEvent) => void;
}

const ClientsTable: FC<Props> = (props) => {
  const { handleCloseDialog } = props;
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const { currentTab } = useAppSelector((state) => state.generateDebts);

  const ci = useRef<HTMLInputElement>(null);

  const [selected, setSelected] = useState<SearchPersonParam | null>(null);

  const [clientType, setClientType] = useState<string>('is_student');

  const [clientIdentification, setClientIdentification] = useState<string>();

  const [page, setPage] = useState(1);

  //client data
  const {
    data: clientInfo,
    isFetching: isFetchingCI,
    error: errorCI,
    refetch,
  } = useGetPersonsByParamQuery(
    {
      [clientType]: true,
      page,
      ...(clientIdentification && { ci: clientIdentification }),
    },
    { skip: !clientIdentification }
  );

  const handleSearch = () => {
    if (!!clientIdentification) refetch();
    setClientIdentification(Boolean(ci.current?.value) ? ci.current?.value || '' : ' ');
  };

  const handleSelect = (e: React.SyntheticEvent) => {
    if (selected) {
      if (currentTab === 0) dispatch(setCurrentClient(selected));
      else dispatch(setCurrentClientMassiveDebts(selected));

      handleCloseDialog(e);
    }
  };

  useEffect(() => {
    setPage(1);
  }, [clientIdentification]);

  const renderRow = (clientInfo: SearchPersonParam) => (
    <TableRow
      hover
      className="table-row"
      key={clientInfo.id.toString()}
      sx={{ cursor: 'pointer', backgroundColor: selected?.id === clientInfo.id ? '#eee' : '' }}
      onClick={() => {
        setSelected(clientInfo);
        // handleSelectclientInfoe(clientInfo.id);
      }}
    >
      <TableCell>{clientInfo.user.student?.stud_code}</TableCell>
      <TableCell>{clientInfo.pers_identification}</TableCell>
      <TableCell
      // width="30%"
      // align="center"
      // sx={{ borderLeft: 1, borderColor: 'grey.200', paddingX: '10px' }}
      >
        {`${clientInfo.pers_firstname || ''} ${clientInfo.pers_secondname || ''} ${
          clientInfo.pers_first_lastname || ''
        } ${clientInfo.pers_second_lastname || ''}`}
      </TableCell>
    </TableRow>
  );

  const renderRows = () => {
    return <>{clientInfo?.data?.map(renderRow)}</>;
  };

  return (
    <>
      <Grid container alignContent={'flex-end'} spacing={1} mt={1}>
        <Grid item xs={12} md={6}>
          <Autocomplete
            options={filterClients}
            getOptionLabel={(option) => t(option.label)}
            value={filterClients.find((item) => item.value === clientType)}
            onChange={(_, value) => {
              setClientType(value?.value || '');
              dispatch(resetClientMassiveDebts());
              dispatch(resetClient());
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('financial.generate_debts.client_dialog.filters.label')}
                variant="outlined"
                fullWidth
              />
            )}
            clearIcon={null}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          {/* <InputLabel>Cédula</InputLabel> */}
          <TextField
            inputRef={ci}
            fullWidth
            placeholder={t('financial.generate_debts.client_dialog_table.search_placeholder')}
            // sx={{ '>.MuiOutlinedInput-root': { pr: 0 } }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') handleSearch();
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    onClick={handleSearch}
                    sx={{ borderRadius: '12px!important' }}
                  >
                    <SearchIcon fontSize="small" color="primary" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={12}>
          {isFetchingCI ? (
            renderSkeletonTable(4, 6)
          ) : (
            <>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow sx={{ backgroundColor: theme.palette.grey[200] }}>
                      <TableCell>
                        {t('financial.generate_debts.client_dialog_table.code')}
                      </TableCell>
                      <TableCell>
                        {t('financial.generate_debts.client_dialog_table.identification')}
                      </TableCell>
                      <TableCell>
                        {t('financial.generate_debts.client_dialog_table.name')}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isFetchingCI ? renderSkeletonRows(5, 3) : renderRows()}

                    {errorCI && renderErrorOrEmptyRow(5, errorCI as string)}
                    {(clientInfo?.data?.length === 0 || !clientInfo) && renderErrorOrEmptyRow(5)}
                  </TableBody>
                </Table>
              </TableContainer>

              {!isFetchingCI && !errorCI && clientInfo && (
                <Grid container justifyContent="space-between" alignItems="flex-end" sx={{ mt: 4 }}>
                  <Grid item>
                    <CustomPagination
                      shape="rounded"
                      color="primary"
                      count={Math.ceil(clientInfo!.last_page)}
                      page={page}
                      onChange={(_, newPage) => {
                        setPage(newPage);
                      }}
                    />
                  </Grid>
                </Grid>
              )}
            </>
          )}
        </Grid>

        <Grid container justifyContent={'flex-end'} spacing={1} mt={1}>
          <Grid item>
            <Button variant="outlined" size="large" onClick={(e) => handleCloseDialog(e)}>
              {t('financial.generate_debts.client_dialog.cancel_btn')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              size="large"
              disabled={!selected}
              onClick={(e) => handleSelect(e)}
            >
              {t('financial.generate_debts.client_dialog.save_btn')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ClientsTable;
