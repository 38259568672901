import { Switch } from 'react-router-dom';
import GuardRoute from '../../../../router/guards/GuardRoute';
import ClientsScreen from '../screens/ClientsScreen';
import { sharedPermissions } from '../../../shared/constants/sharedPermissions';
import {
  groupConfigurationPermissions,
  groupConfigurationStudentsPermissions,
} from '../constants/permissions';

const ClientsDiscountRouter = () => {
  // const { currentModule } = useAppSelector((state) => state.access);

  const urlName = 'administrar-clientes-descuento';

  // const hasPermission = 'economicGroup' in currentModule?.permissions!;

  // if (!hasPermission) return <Redirect to="/dashboard" />;
  return (
    <>
      <Switch>
        <GuardRoute
          exact
          path={`/${urlName}`}
          component={ClientsScreen}
          subPermissionKey={[
            sharedPermissions.findPerson,
            groupConfigurationPermissions.read,
            groupConfigurationStudentsPermissions.setMassiveDiscounts,
          ]}
          // permissionKey={permissionKey}
        />
      </Switch>
    </>
  );
};

export default ClientsDiscountRouter;
