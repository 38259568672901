import { Alert, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../../../hooks/useRedux';
import MainCard from '../../../../shared/components/cards/MainCard';
import TypographyFetching from '../../../../shared/components/TypographyFetching';
import {
  useGetCatalogByAcronymQuery,
  useGetDisabilityAvailableQuery,
} from '../../../../shared/slices/catalogApi';
import { useGetAvailablesProvincesQuery } from '../../../institutes/slices/institutesApiSlice';

const PersonalData = () => {
  const { t } = useTranslation();
  // const { collabFile } = useAppSelector((state) => state.teacher);

  const { user } = useAppSelector((state) => state.teacher.collabFile!);

  //id
  const { data: identificationsAvailables = [], isFetching: isFetchingIA } =
    useGetCatalogByAcronymQuery({
      acronym: 'TI',
    });

  //Ethnics
  const { data: ethnicsAvailables = [], isFetching: isFetchingEA } = useGetCatalogByAcronymQuery({
    acronym: 'ET',
  });

  //marital status
  const { data: civilStatusAvailables = [], isFetching: isFetchingCA } =
    useGetCatalogByAcronymQuery({
      acronym: 'SM',
    });

  //religions
  const { data: religionsAvailables = [], isFetching: isFetchingRA } = useGetCatalogByAcronymQuery({
    acronym: 'TR',
  });

  // countries
  const { data: countriesAvailables = [], isFetching: isFetchingCOA } = useGetCatalogByAcronymQuery(
    {
      acronym: 'PS',
    }
  );

  // nacionalities
  const { data: nacionalities = [], isFetching: isFetchingNA } = useGetCatalogByAcronymQuery({
    acronym: 'NA',
  });

  //provincia
  const { data: provincies, isFetching: isFetchingPA } = useGetAvailablesProvincesQuery({});

  //disability
  const { data: disability = [], isFetching: isFetchingDA } = useGetDisabilityAvailableQuery({});

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <MainCard title={t('academic.teachers_list.file.personal_data.title')}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography variant="h4" component="span">
                {t('academic.teachers_list.file.personal_data.type_id')}
              </Typography>
              <TypographyFetching isLoading={isFetchingIA} variant="body2" component="span">
                {
                  identificationsAvailables.find(
                    (item) => item.id === +user.person.type_identification_id
                  )?.cat_name
                }
              </TypographyFetching>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant="h4" component="span">
                {t('academic.teachers_list.file.personal_data.id')}
              </Typography>
              <Typography variant="body2" component="span">
                {user.person.pers_identification}
              </Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant="h4" component="span">
                {t('academic.teachers_list.file.personal_data.name')}
              </Typography>
              <Typography variant="body2" component="span">
                {`${user.person.pers_firstname || ''} ${user.person.pers_secondname || ''}`}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h4" component="span">
                {t('academic.teachers_list.file.personal_data.lastname')}
              </Typography>
              <Typography variant="body2" component="span">
                {`${user.person.pers_first_lastname || ''} ${
                  user.person.pers_second_lastname || ''
                }`}
              </Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant="h4" component="span">
                {t('academic.teachers_list.file.personal_data.mail')}
              </Typography>
              <Typography variant="body2" component="span">
                {user.person.pers_email}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h4" component="span">
                {t('academic.teachers_list.file.personal_data.ethnic')}
              </Typography>
              <TypographyFetching isLoading={isFetchingEA} variant="body2" component="span">
                {ethnicsAvailables.find((item) => item.id === +user.person.ethnic_id)?.cat_name}
              </TypographyFetching>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant="h4" component="span">
                {t('academic.teachers_list.file.personal_data.religion')}
              </Typography>
              <TypographyFetching isLoading={isFetchingRA} variant="body2" component="span">
                {
                  religionsAvailables.find((item) => item.id === +user.person.type_religion_id)
                    ?.cat_name
                }
              </TypographyFetching>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h4" component="span">
                {t('academic.teachers_list.file.personal_data.civil_status')}
              </Typography>
              <TypographyFetching isLoading={isFetchingCA} variant="body2" component="span">
                {
                  civilStatusAvailables.find((item) => item.id === +user.person.status_marital_id)
                    ?.cat_name
                }
              </TypographyFetching>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant="h4" component="span">
                {t('academic.teachers_list.file.personal_data.gender')}
              </Typography>
              <Typography variant="body2" component="span">
                {user.person.pers_gender}
              </Typography>
            </Grid>
          </Grid>
        </MainCard>
      </Grid>

      <Grid item xs={12} md={6}>
        <MainCard title={t('academic.teachers_list.file.birthday_data.title')}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography variant="h4" component="span">
                {t('academic.teachers_list.file.birthday_data.date_bird')}
              </Typography>
              <Typography variant="body2" component="span">
                {user.person.pers_date_birth}
              </Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant="h4" component="span">
                {t('academic.teachers_list.file.birthday_data.country')}
              </Typography>
              <TypographyFetching isLoading={isFetchingCOA} variant="body2" component="span">
                {countriesAvailables.find((item) => item.id === +user.person.country_id)?.cat_name}
              </TypographyFetching>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant="h4" component="span">
                {t('academic.teachers_list.file.birthday_data.nationality')}
              </Typography>
              <TypographyFetching isLoading={isFetchingNA} variant="body2" component="span">
                {nacionalities.find((item) => item.id === +user.person.nationality_id)?.cat_name}
              </TypographyFetching>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h4" component="span">
                {t('academic.teachers_list.file.birthday_data.province')}
              </Typography>
              <TypographyFetching isLoading={isFetchingPA} variant="body2" component="span">
                {
                  provincies?.find((dt) => dt.id.toString() === user.person.province_id?.toString())
                    ?.cat_name
                }
              </TypographyFetching>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant="h4" component="span">
                {t('academic.teachers_list.file.birthday_data.bird_city')}
              </Typography>
              <TypographyFetching isLoading={isFetchingPA} variant="body2" component="span">
                {provincies
                  ?.find((dt) => dt.id.toString() === user.person.province_id?.toString())
                  ?.children?.find(
                    (cityId) => cityId.id.toString() === user.person.city_id?.toString()
                  )?.cat_name || null}
              </TypographyFetching>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h4" component="span">
                {t('academic.teachers_list.file.birthday_data.current_city')}
              </Typography>
              <TypographyFetching isLoading={isFetchingPA} variant="body2" component="span">
                {provincies
                  ?.find((dt) => dt.id.toString() === user.person.province_id?.toString())
                  ?.children?.find(
                    (cityId) => cityId.id.toString() === user.person.current_city_id?.toString()
                  )?.cat_name || null}
              </TypographyFetching>
            </Grid>
          </Grid>
        </MainCard>
      </Grid>

      <Grid item xs={12} md={6}>
        <MainCard title={t('academic.teachers_list.file.disability.title')}>
          <Grid container spacing={2}>
            {!Number(user.person.pers_has_disability) ? (
              <Grid item xs={12}>
                <Alert severity="info">
                  <Typography variant="h5" component="p" align="center">
                    {t('academic.teachers_list.file.disability.no_data')}
                  </Typography>
                </Alert>
              </Grid>
            ) : (
              <>
                <Grid item xs={12} md={4}>
                  <Typography variant="h4" component="span">
                    {t('academic.teachers_list.file.disability.type')}
                  </Typography>
                  <TypographyFetching isLoading={isFetchingDA} variant="body2" component="span">
                    {disability.find((dt) => dt.id === Number(user.person.disease_id))
                      ?.typ_dis_name || null}
                  </TypographyFetching>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography variant="h4" component="span">
                    {t('academic.teachers_list.file.disability.percentage')}
                  </Typography>
                  <Typography variant="body2" component="span">
                    {user.person.pers_disability_percent}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography variant="h4" component="span">
                    {t('academic.teachers_list.file.disability.conadis')}
                  </Typography>
                  <Typography variant="body2" component="span">
                    {user.person.pers_disability_identification}
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
        </MainCard>
      </Grid>
    </Grid>
  );
};

export default PersonalData;
