import { Button, CircularProgress, Grid, Paper, Typography } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import { IconBook2, IconClipboardText, IconMessage } from '@tabler/icons';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useAppSelector } from '../../../../../hooks/useRedux';
import { useGetLocaleDateLang } from '../../../../../translation/hooks/useGetLocaleDateLang';
import MainCard from '../../../../shared/components/cards/MainCard';
import { useGoActivityMoodleMutation } from '../../../../shared/slices/moodle';

interface Additionals {
  instance?: number | null;
  modulename?: string | null;
  date?: string | null;
}

const ItemTask: React.FC<{
  descripcion: string;
  activityId: number;
  type: String;
  additionals: Additionals;
}> = ({ descripcion, activityId, type, additionals }) => {
  const { instance, modulename } = additionals;

  const History = useHistory();

  const { t } = useTranslation();

  const { userInfo } = useAppSelector((state) => state.auth);

  const [getMoodleActivity, { isLoading: isLoadingMoodle }] = useGoActivityMoodleMutation();

  const { lang } = useGetLocaleDateLang();

  const {
    course: { courseId },
  } = useAppSelector((state) => state.virtualClassroomTeacherShared);

  const goToActivity = async (
    thisCourseId: number,
    thisActivityId: number,
    thisInstance?: number | null,
    thisModulename?: string | null
  ) => {
    if (thisInstance && thisModulename) {
      try {
        const moodleActivity = await getMoodleActivity({
          instance: thisInstance,
          modulename: thisModulename,
          userId: userInfo?.id!,
        }).unwrap();

        window.open(moodleActivity);
      } finally {
        // catch (error) {
        // console.log('F');
        // }
        return;
      }
    }

    History.push(
      `/administrar-aula-virtual/actividades/course/${thisCourseId}/task/${thisActivityId}`
    );
    return;
  };

  //console.log(window.location);
  return (
    <Grid xs={12}>
      <Paper style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        {additionals.date && (
          <Grid sm={4} component={MainCard} item>
            <Typography variant="body1" fontWeight={'bold'}>
              {formatDistanceToNow(parseISO(additionals.date!), {
                addSuffix: true,
                locale: lang,
              })}
            </Typography>
          </Grid>
        )}
        <Grid sm={5} container item flexWrap={'nowrap'} alignItems={'center'} flex="true">
          <div style={{ marginRight: '8px' }}>
            {type === 'Tarea individual' ? (
              <IconBook2 />
            ) : type === 'Foros' ? (
              <IconMessage />
            ) : (
              <IconClipboardText />
            )}
          </div>

          <Typography variant="body1" alignItems={'center'} noWrap>
            {descripcion}
          </Typography>
        </Grid>
        <Grid sm={3} item>
          <Button
            variant="contained"
            disabled={isLoadingMoodle}
            endIcon={isLoadingMoodle ? <CircularProgress size={20} /> : <SendIcon />}
            onClick={() => goToActivity(courseId, activityId, instance, modulename)}
          >
            {t('teacherVirtualClassroom.dashboard.button_send')}
          </Button>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default ItemTask;
