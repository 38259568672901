import { Switch, Route, Redirect } from 'react-router-dom';
import { useAppSelector } from '../../../../hooks/useRedux';
import GuardRoute from '../../../../router/guards/GuardRoute';
import { subPermissionKeys } from '../constants/permissions';
import AcademicOfferingScreen from '../screens/AcademicOfferingsScreen';
import AddAcademicOfferingScreen from '../screens/AddAcademicOfferingScreen';
import EditAcademicOfferingScreen from '../screens/EditAcademicOfferingScreen';
import PeriodsScreen from '../screens/PeriodsScreen';

const { groupKey: permissionKey } = subPermissionKeys;

const AcademicOffersRouter = () => {
  const { currentModule } = useAppSelector((state) => state.access);

  const hasPermission = permissionKey in currentModule!.permissions;

  if (!hasPermission) return <Redirect to="/dashboard" />;

  return (
    <Switch>
      <GuardRoute
        exact
        path="/administrar-niveles-educativos"
        component={AcademicOfferingScreen}
        subPermissionKey={subPermissionKeys.read}
        permissionKey={permissionKey}
      />
      <GuardRoute
        exact
        path="/administrar-niveles-educativos/agregar"
        component={AddAcademicOfferingScreen}
        subPermissionKey={subPermissionKeys.read}
        permissionKey={permissionKey}
      />
      <GuardRoute
        exact
        path="/administrar-niveles-educativos/:academicOfferingId/editar"
        component={EditAcademicOfferingScreen}
        subPermissionKey={subPermissionKeys.read}
        permissionKey={permissionKey}
      />
      <Route
        exact
        path="/administrar-niveles-educativos/:academicOfferingId/periodos"
        component={PeriodsScreen}
      />
    </Switch>
  );
};

export default AcademicOffersRouter;
