import { Button, CircularProgress, Grid, makeStyles, Typography } from '@material-ui/core';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
//import { IconBell } from '@tabler/icons';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import { studyCertificateValues } from '../constants/formConstants';
import {
  useAddCertificateMutation,
  useGetCertificateTypesQuery,
  useGetPdfArrayBufferQuery,
  useGetShareCertificateQuery,
} from '../slices/certificateApiSlice';
import { openDebitModalWithObject } from '../slices/certificateSlice';

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  alert: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.primary.light,
    fontSize: '14px',
    fontWeight: 600,
    borderRadius: 20,
    paddingLeft: 40,
    paddingRight: 40,
  },
  pdfGrid: {
    padding: theme.spacing(1),
    borderRadius: 20,
    paddingTop: '6vh',
    paddingBottom: '6vh',
    backgroundColor: theme.palette.primary.light,
  },
  pdfFrame: {
    overflow: 'hidden',
  },
}));
const ConductThirdRegistrationForm = () => {
  const { t } = useTranslation();

  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [showAlerts, setShowAlerts] = useState(false);
  const { currentProfile, currentRecordStudent } = useAppSelector((state) => state.access);
  const { userInfo } = useAppSelector((state) => state.auth);
  const { data: certificateTypes = [], isLoading: isLoadingCT } = useGetCertificateTypesQuery(
    { profileId: currentProfile?.profileId! },
    { refetchOnMountOrArgChange: 30 } // aqui se buscaba validar si el array estaba vacio? porque estaba con === []
  );

  /* Obtenemos el id del tipo de certificado, en este caso usamos el acronimo para encontrarlo */
  const [certificateTypeId, setCertificateTypeId] = useState(0);
  const [deuda, setDeuda] = useState(0);
  const [pdfUrl, setPdfUrl] = useState('');
  const [pdf, setPdf] = useState('');
  const [errorPDf, setErrorPdf] = useState(false);

  const {
    data,
    isLoading: isLoadingCTRC,
    isFetching: isFetchingCTRC,
    isError: isErrorCTRC,
    error: errorCTRC,
  } = useGetShareCertificateQuery(
    {
      profileId: currentProfile?.profileId!,
      userId: String(userInfo?.id!),
      studentRecordId: currentRecordStudent?.id!,
      appId: certificateTypeId,
      type: 1,
    },
    {
      refetchOnMountOrArgChange: 30,
      skip: certificateTypeId === 0,
    }
  );
  const {
    data: pdfBlob,
    isFetching: isFetchingPDF,
    isLoading: isLoadingPDF,
    error: isErrorPDF,
  } = useGetPdfArrayBufferQuery(
    { profileId: currentProfile?.profileId!, url: data?.pdf_url! },
    {
      refetchOnMountOrArgChange: 30,
      skip: certificateTypeId === 0 || data === undefined,
    }
  );

  const [addcertificate, { isLoading, isSuccess, data: certificadoSavedResponse, isError, error }] =
    useAddCertificateMutation();
  const { handleSubmit /*, errors, touched, setFieldValue*/ } = useFormik({
    initialValues: studyCertificateValues,
    onSubmit: async (value) => {
      if (deuda !== 0) {
        dispatch(
          openDebitModalWithObject({ id: certificateTypeId, pdfUrl: pdfUrl, appAcronym: 'CERDYT' })
        );
      } else {
        await addcertificate({
          profileId: currentProfile?.profileId!,
          appId: certificateTypeId,
          studentRecordId: currentRecordStudent?.id!,
          pdfUrl: pdfUrl,
          certificatePayload: {
            lang: '',
            consul: '',
            practice: [],
            period_id: 0,
            to: '',
            app_acronym: 'CERDYT',
          },
        });
      }
      setShowAlerts(true);
    },
  });

  useEffect(() => {
    if (!isFetchingPDF && pdfBlob) {
      let blob = new Blob([pdfBlob], { type: 'application/pdf' });
      let url = window.URL.createObjectURL(blob);
      setPdf(url);
    }
  }, [isFetchingPDF, pdfBlob]);

  useEffect(() => {
    if (!isLoadingCT && certificateTypeId === 0) {
      const certificateType = certificateTypes.filter(
        (certificateType) => certificateType.typ_app_acronym === 'CERDYT'
      );
      if (certificateType.length > 0) setCertificateTypeId(certificateType[0].id);
    }
  }, [isLoadingCT, certificateTypeId, certificateTypes]);

  useEffect(() => {
    if (!isLoadingCTRC && data) {
      setDeuda(Number(data?.deuda!));
      setPdfUrl(data?.pdf_url!);
    }
  }, [isLoadingCTRC, data]);

  useEffect(() => {
    if (isErrorCTRC && errorCTRC && isErrorPDF ) {
      setShowAlerts(true);
    }if(isErrorPDF){
      setErrorPdf(true);
    }
  }, [isErrorCTRC, errorCTRC,isErrorPDF]);

  useEffect(() => {
    if (isSuccess && certificadoSavedResponse) {
      //Actualizando valor inmediato a la generacion del certificado
      //este es valido para el primero como el n-esimo
      setDeuda(Number(certificadoSavedResponse));
    }
  }, [isSuccess, certificadoSavedResponse]);

  const defaultConfiguration = useAppSelector((state) => state.access.defaultConfiguration);

  return (
    <form onSubmit={handleSubmit}>
      <Grid item>
        {isError && showAlerts && (
          <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
        )}
        {isErrorCTRC && showAlerts && (
          <ErrorAlert message={errorCTRC as string} handleDismiss={() => setShowAlerts(false)} />
        )}

        {isSuccess && showAlerts && (
          <SuccessAlert
            message={t('students.certificates.success_message')}
            handleDismiss={() => setShowAlerts(false)}
          />
        )}
      </Grid>
      <Grid container spacing={4} alignContent="flex-start">
        <Grid item xs={12} container spacing={2} margin={2}>
          <Grid item xs={12} container>
            <Grid item xs={12} textAlign="left">
              <Typography variant="h1" sx={{ mb: 2 }}>
                {t('students.certificates.good_behavior.title')}
              </Typography>
            </Grid>

            <Grid item xs={12} container sx={{ mt: 2, pl: 4, pr: 4 }}>
              <Grid item xs={6} textAlign="left">
                <Typography variant="h3" sx={{ mb: 2 }}>
                  {t('students.certificates.info.to_pay')}
                </Typography>
              </Grid>
              <Grid item xs={6} textAlign="right">
                <Typography variant="h3" sx={{ mb: 2 }}>
                  <strong>
                    {defaultConfiguration?.currency_symbol} {deuda > 0 ? deuda : 0}
                  </strong>
                </Typography>
              </Grid>
            </Grid>
            {isErrorPDF ? (
                <Grid
                item
                xs={12}
                container
                justifyContent="center"
                className={classes.pdfGrid}
                sx={{ height: '60vh' }}
              >
                <Typography 
                color='primary.dark'
                variant='h3'
                >
                {isErrorPDF}
                </Typography>
                </Grid>
              ) : (
                <Grid
                  item
                  xs={12}
                  container
                  justifyContent="center"
                  className={classes.pdfGrid}
                  sx={{ height: '60vh' }}
                >
                  {!isLoadingPDF && pdf && !isFetchingCTRC && !isFetchingPDF && (
                    <div style={{ position: 'relative', width: '85%' }}>
                      <div
                        onContextMenu={(e) => e.preventDefault()}
                        style={{
                          position: 'absolute',
                          width: '96%',
                          height: '96%',
                        }}
                      ></div>
                      <iframe
                        title="VistaPrevia"
                        style={{ width: '100%', height: '100%' /*, pointerEvents: 'none'*/ }}
                        src={pdf + '#toolbar=0'}
                      />
                    </div>
                  )}
                  {(isLoadingCTRC || isLoadingPDF || isFetchingCTRC || isFetchingPDF) && (
                    <Grid item xs={12} container justifyContent="center" alignItems="center">
                      <CircularProgress size={20} />
                    </Grid>
                  )}
                  {/* To render pdf*/}
                </Grid>
              )}
          </Grid>
          <Grid item xs={12} textAlign="center" sx={{ mt: 2 }}>
            <Button
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              disabled={isLoading || errorPDf}
              endIcon={isLoading && <CircularProgress size={20} />}
            >
              {t('shared.get_button.label')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};
export default ConductThirdRegistrationForm;
