import imgg from '../../../../assets/images/illustrations/telework.svg';

const SelectionMessage = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <img src={imgg} alt="message" />
      &nbsp; Seleccione un Grupo para listar los estudiantes
    </div>
  );
};

export default SelectionMessage;
