import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { initialDefaultConfiguration } from '../modules/shared/constants/defaultConfiguration';
import { DefaultConfiguration } from '../modules/shared/interfaces/tenants.interfaces';

import resources from './resources';

const localConfig = localStorage.getItem('defaultConfiguration');

const defaultConfiguration = localConfig
  ? (JSON.parse(localConfig) as DefaultConfiguration)
  : initialDefaultConfiguration;

i18n.use(initReactI18next).init({
  resources,
  lng: defaultConfiguration.language,
  fallbackLng: 'es',
  compatibilityJSON: 'v3',
  react: {
    useSuspense: false,
  },
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
