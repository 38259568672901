import type { DraggableLocation } from 'react-beautiful-dnd';

import { CustomChilds } from '../../../shared/interfaces/material-ui.interfaces';
import { BusRoute, BusTour, BusToursSplited } from '../interfaces/buses.interfaces';

export const droppableKeys = {
  reserve: 0,
  ida: 1,
  retorno: 2,
};

export const splitBusTours = (
  busRoute: CustomChilds<BusRoute, { bus_tour: BusTour[] }> | undefined
): BusToursSplited => {
  return busRoute?.bus_tour.reduce((res: any, curr) => {
    if (res[curr.tour_type]) res[curr.tour_type].push(curr);
    else Object.assign(res, { [curr.tour_type]: [curr] });

    return res;
  }, {} || { ida: undefined, retorno: undefined });
};

export const reorderItems = <Type>(list: Type[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const moveItems = <Type>(
  source: Type[],
  destination: Type[],
  droppableSource: DraggableLocation,
  droppableDestination: DraggableLocation
) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result: any = {};
  result[droppableKeys[droppableSource.droppableId as keyof typeof droppableKeys]] = sourceClone;
  result[droppableKeys[droppableDestination.droppableId as keyof typeof droppableKeys]] = destClone;

  return result;
};

export const removeItems = <Type>(source: Type[], droppableId: string, index: number) => {
  const sourceClone = Array.from(source);
  const deleted = sourceClone.splice(index, 1).filter((item: any) => typeof item.id === 'number');

  return { sourceClone, deleted };
};
