import { mainApi } from '../../../../api/mainApi';
import { middlewareApi } from '../../../middleware/middlewareApi';
import { EnabledSubject, EnabledSubjectsPayload } from '../interfaces/enabledSubjects.interfaces';

export const enabledSubjectsTag = middlewareApi.enhanceEndpoints({
  addTagTypes: ['enabledSubjects'],
});

export const enabledSubjectsApi = enabledSubjectsTag.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * @POST filtering enabled subjects by period, workday y hourhands
     */
    filterEnabledSubjects: builder.mutation<
      EnabledSubject[],
      {
        profileId: number;
        studentRecordId: number;
        enabledSubjectsPayload: EnabledSubjectsPayload;
      }
    >({
      queryFn: async ({ profileId, studentRecordId, enabledSubjectsPayload }) => {
        try {
          //To avoid undefined array search
          const hourhandsValue =
            enabledSubjectsPayload?.hourhands.length > 0
              ? enabledSubjectsPayload?.hourhands[0] !== undefined
                ? enabledSubjectsPayload?.hourhands
                : null
              : null;
          const { data } = await mainApi.post<EnabledSubject[]>(
            `/enabled-subject/student-record/${studentRecordId}`,
            {
              user_profile_id: profileId,
              period_id: enabledSubjectsPayload.period_id,
              campus_id: enabledSubjectsPayload.campus_id,
              workday: enabledSubjectsPayload.workday ? enabledSubjectsPayload.workday : null,
              hourhands: hourhandsValue,
            }
          );

          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'enabledSubjects', id: 'LIST' }],
    }),
  }),
});

export const { useFilterEnabledSubjectsMutation } = enabledSubjectsApi;
