import * as yup from 'yup';

export const taskValidationSchema = yup.object().shape({
  title: yup.string().required('El título es requerido'),
  observation: yup.string().required('La observación es requerida'),
  budget: yup
    .number()
    .typeError('El campo debe ser numérico')
    .max(9999999999, 'El presupuesto no puede ser mayor a 9999999999')
    .required('El presupuesto es requerido'),
  numberHours: yup
    .number()
    .typeError('El campo debe ser numérico')
    .max(999, 'El número de horas no puede ser mayor a 999')
    .required('El número de horas es requerido'),
  startDate: yup
    .date()
    .typeError('La fecha de inicio es requerida')
    .required('La fecha de inicio es requerida'),
  endDate: yup
    .date()
    .typeError('La fecha de fin es requerida')
    .required('La fecha de fin es requerida'),
  statusId: yup.number().typeError('El estado es requerido').required('El estado es requerido'),
});

export const studentsAssignTaskValidationSchema = yup.object().shape({
  observation: yup.string().required('La observación es requerida'),
  budget: yup
    .number()
    .typeError('El campo debe ser numérico')
    .max(9999999999, 'El presupuesto no puede ser mayor a 9999999999')
    .required('El presupuesto es requerido'),
  numberHours: yup
    .number()
    .typeError('El campo debe ser numérico')
    .min(1, 'El número de horas no puede ser menor a 1')
    .max(999, 'El número de horas no puede ser mayor a 999')
    .required('El número de horas es requerido'),
  date: yup
    .date()
    .typeError('La fecha de inicio es requerida')
    .required('La fecha de inicio es requerida'),

  students: yup.array().min(1, 'Ingrese al menos un estudiante'),
});
