import { Grid, IconButton, Typography, useTheme } from '@material-ui/core';
import { IconArrowNarrowLeft } from '@tabler/icons';
import { useHistory } from 'react-router-dom';
import { useAppSelector } from '../../../../hooks/useRedux';

import MainCardForm from '../../../shared/components/cards/MainCardForm';
import PersonCreateScreen from '../../../shared/components/person/screens/PersonCreateScreen';
import FormTabs from '../components/formTabs';
// import AddStudentForm from '../components/AddStudentForm';
// import StudentFormTabs from '../components/StudentForm';

const AddStudentScreen = () => {
  const theme = useTheme();

  const history = useHistory();

  const { studentAddUser } = useAppSelector((state) => state.students);

  return (
    <MainCardForm>
      <Grid item xs={12}>
        <Grid item container alignItems="center" gap={1} sx={{ cursor: 'pointer' }}>
          <IconButton onClick={() => history.goBack()}>
            <IconArrowNarrowLeft size={30} color={theme.palette.text.dark} />
          </IconButton>
          <Typography variant="h2">Agregar Estudiante</Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ mt: 2 }}>
        {/* <AddStudentForm /> */}
        <FormTabs />
        {/* <StudentFormTabs /> */}
      </Grid>
      {studentAddUser && <PersonCreateScreen />}
    </MainCardForm>
  );
};

export default AddStudentScreen;
