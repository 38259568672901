import header from './header.json';
import menu from './menu.json';
import content from './content.json';
import activities from './activities.json';
import classmates from './classmates.json';
import test from './test.json';
import forum from './forum.json';
import qualification from './qualification.json';
import schedule from './schedule.json';
import task from './task.json';
import materials from './materials.json';
import attendence from './attendance.json';
import advertisements from './advertisements.json';
const studentVirtualClassroomTranslation = {
  ...header,
  ...menu,
  ...content,
  ...activities,
  ...classmates,
  ...test,
  ...forum,
  ...qualification,
  ...schedule,
  ...task,
  ...materials,
  ...attendence,
  ...advertisements,
};

export default studentVirtualClassroomTranslation;
