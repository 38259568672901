import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import BuilderTitle from '../components/builder/BuilderTitle';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { setSidebarOpened } from '../../../shared/reducers/customizationReducer';
import { Alert, Button, Skeleton, Stack } from '@material-ui/core';
// import PeriodLane from '../components/builder/PeriodLane';
import PeriodsLaneGrid from '../components/builder/PeriodsLaneGrid';
import AddSubjectDialog from '../components/AddSubjectCurriculumDialog';
import {
  fireAssignSubjectCurriculumDialog,
  firePublishCurriculumDialog,
  setCurriculumSelected,
  setSimbologyIdSelected,
} from '../slices/meshSlice';
import { useGetMeshQuery } from '../slices/meshsApiSlice';
import InfoBadge from '../components/builder/InfoBadge';
import SimbologyBadge from '../components/builder/SimbologyBadge';
import { drawerWidth } from '../../../shared/constants/uiConstants';
import DeleteSubjectCurriculumDialog from '../components/DeleteSubjectCurriculum';
import PublishCurriculumDialog from '../components/PublishCurriculumDialog';
import useSharedGuard from '../../../../hooks/useSharedGuard';
import { subPermissionKeys } from '../constants/permissions';
import { useGetEducationLevelQuery } from '../../education-levels/slices/educationLevelsApiSlice';

const MeshBuilderScreen = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const { meshId: meshIdParam } = useParams<{ meshId: string }>();
  const meshId = Number(meshIdParam);

  const { currentProfile } = useAppSelector((state) => state.access);
  const { opened } = useAppSelector((state) => state.customization);
  const profileId = currentProfile!.profileId;

  const { data, isFetching } = useGetMeshQuery({ profileId, meshId });

  const { data: educationLevelFather } = useGetEducationLevelQuery(
    {
      profileId,
      educationLevelId: Number(data?.education_level?.principal_id!),
    },
    { skip: !data || !data?.education_level }
  );

  const {
    assignSubjectCurriculumDialogOpen,
    currentGroup,
    subjectCurriculumSelected,
    curriculumSelected,
  } = useAppSelector((state) => state.meshs);

  const subjectWorkloads =
    data?.matter_mesh?.map((m) =>
      m.detail_matter_mesh?.reduce((acc, current) => +current.dem_workload + acc, 0)
    ) || [];

  console.log('subjectWorkloads', subjectWorkloads);

  const groupedWorkloads = data?.matter_mesh.reduce<{
    [key: string]: { label: string; value: number };
  }>((acc, current) => {
    for (let i = 0; i < current.detail_matter_mesh.length; i++) {
      const acronym = current.detail_matter_mesh[i].component.com_acronym;
      if (!acc[acronym]) {
        acc[acronym] = {
          label: current.detail_matter_mesh[i].component.com_acronym,
          value: 0,
        };
      }
      acc[acronym] = {
        ...acc[acronym],
        value: acc[acronym].value + +current.detail_matter_mesh[i].dem_workload,
      };
    }
    return acc;
  }, {});

  console.log('groupedWorkloads', groupedWorkloads);

  const totalWorkloads = subjectWorkloads?.reduce((acc, current) => current + acc, 0);

  const simbologies =
    data?.education_level?.offer?.simbologies?.map((s) => ({
      label: s.sim_description,
      color: s.sim_color,
      id: s.id,
    })) || [];

  const handleFilter = (id: number | null) => {
    dispatch(setSimbologyIdSelected(id));
  };

  useEffect(() => {
    dispatch(setSidebarOpened(false));
  }, [dispatch]);

  const publishCurriculumHandler = () => {
    dispatch(setCurriculumSelected(meshId));
    dispatch(firePublishCurriculumDialog(true));
  };

  const { hasAccess } = useSharedGuard(subPermissionKeys);

  return (
    <Grid container>
      {/* Header */}
      {isFetching ? (
        <Grid item xs={12} container alignItems="center">
          <Grid item xs={6}>
            <Skeleton
              variant="rectangular"
              sx={{ width: '100%', height: '40px', borderRadius: '12px' }}
            />
          </Grid>
          <Grid item xs={6} container justifyContent="flex-end" spacing={2}>
            <Grid item>
              <Skeleton
                variant="rectangular"
                sx={{ width: '130px', height: '40px', borderRadius: '1.5rem' }}
              />
            </Grid>
            <Grid item>
              <Skeleton
                variant="rectangular"
                sx={{ width: '130px', height: '40px', borderRadius: '1.5rem' }}
              />
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid item xs={12} container alignItems="center" justifyContent="space-between">
          <Grid item>
            <BuilderTitle
              title={data!.mes_name || ''}
              career={data!.education_level?.edu_name || ''}
              educationLevel={educationLevelFather?.edu_name || ''}
              modality={data?.modality?.cat_name || ''}
            />
          </Grid>

          <Grid item xs={12} pt={1} container justifyContent="flex-end" spacing={2}>
            {/* Fix ID 7 === Vigente :( */}
            {data?.status.id !== 7 && hasAccess('edit') ? (
              <>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => history.push('/mallas-curriculares')}
                  >
                    Regresar
                  </Button>
                </Grid>
                {/*  <Grid item>
                  <Button variant="outlined" color="primary" onClick={() => history.goBack()}>
                    Guardar
                  </Button>
                </Grid> */}
                <Grid item>
                  <Button variant="contained" color="primary" onClick={publishCurriculumHandler}>
                    Publicar
                  </Button>
                </Grid>
              </>
            ) : (
              <>
                <Grid item>
                  <Button variant="outlined" color="primary" onClick={() => history.goBack()}>
                    Regresar
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      )}

      {/* Lanes */}
      <PeriodsLaneGrid />

      {/* Info badges */}
      {!isFetching && (
        <Stack
          direction="row"
          spacing={2}
          sx={{
            position: 'fixed',
            left: !opened ? '40px' : `calc(${drawerWidth}px + 20px)`,
            bottom: '80px',
            height: '70px',
          }}
          width={!opened ? `calc(100% - 60px)` : `calc(100% - (${drawerWidth}px + 30px))`}
          justifyContent="space-between"
        >
          <Stack direction="row">
            {/* <InfoBadge title="Total componentes de aprendizaje" number={totalWorkloads!} /> */}
            <InfoBadge title="Total Carga Horaria" number={totalWorkloads!} />
            {Object.values(groupedWorkloads!).map((g) => (
              <InfoBadge title={g.label} number={g.value} />
            ))}
            <SimbologyBadge simbologies={simbologies!} handleFilter={handleFilter} />
          </Stack>

          <Stack direction="row">
            {data?.status.id === 7 && (
              <Alert severity="error">Malla publicada, no se puede asignar materias.</Alert>
            )}
          </Stack>
        </Stack>
      )}
      {/* {!isFetching && (
        <Stack
          direction="row"
          spacing={2}
          sx={{
            position: 'fixed',
            right: '20px',
            bottom: '20px',
          }}
        >
          {data?.status.id === 7 && (
            <Alert severity="error">Malla publicada, no se puede asignar materias.</Alert>
          )}
        </Stack>
      )} */}

      {/* Side dialog */}
      {currentGroup && (
        <AddSubjectDialog
          open={assignSubjectCurriculumDialogOpen}
          handleCloseDialog={() => dispatch(fireAssignSubjectCurriculumDialog(false))}
        />
      )}

      {subjectCurriculumSelected && <DeleteSubjectCurriculumDialog />}
      {curriculumSelected && <PublishCurriculumDialog />}
    </Grid>
  );
};

export default MeshBuilderScreen;
