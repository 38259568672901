import { Grid, IconButton, Typography, useTheme } from '@material-ui/core';
import { IconArrowNarrowLeft } from '@tabler/icons';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import MainCardForm from '../../../shared/components/cards/MainCardForm';
import SimpleFormOneColumn from '../../../shared/components/forms/skeletons/SimpleFormOneColumn';
import EditAgreementForm from '../components/EditAgreementForm';
import { useLazyGetAgreementQuery } from '../slices/agreementsApiSlices';
import { setAgreementList } from '../slices/agreementsSlices';

const EditAgreementScreen = () => {
  const theme = useTheme();
  const history = useHistory();

  const dispatch = useAppDispatch();
  const { agreementId } = useParams<{ agreementId: string }>();

  const { agreementsList } = useAppSelector((state) => state.agreements);

  const [getAgreement, { isFetching }] = useLazyGetAgreementQuery();

  useEffect(() => {
    if (!agreementsList.length && !isFetching) {
      getAgreement({
        agreementId: +agreementId,
      })
        .unwrap()
        .then((response) => {
          if (response) dispatch(setAgreementList([response!]));
        })
        .catch((error) => console.log(error));
    }
  }, [getAgreement, isFetching, agreementId, agreementsList.length, dispatch]);

  return (
    <MainCardForm>
      <Grid item xs={12}>
        <Grid item container alignItems="center" gap={1} sx={{ cursor: 'pointer' }}>
          <IconButton onClick={() => history.goBack()}>
            <IconArrowNarrowLeft size={30} color={theme.palette.text.dark} />
          </IconButton>
          <Typography variant="h2">Editar</Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} md={8} lg={6} sx={{ mt: 2 }}>
        {Boolean(agreementsList.length) ? <EditAgreementForm /> : <SimpleFormOneColumn />}
      </Grid>
    </MainCardForm>
  );
};

export default EditAgreementScreen;
