import { IconButton, Tooltip, useTheme } from '@material-ui/core';
import { IconInfoCircle } from '@tabler/icons';

const InfoButton = ({ handleInfo }: { handleInfo?: () => void }) => {
  const theme = useTheme();

  return (
    <Tooltip title="Información">
      <IconButton aria-label="Informacion" onClick={handleInfo}>
        <IconInfoCircle size={20} color={theme.palette.info.main} />
      </IconButton>
    </Tooltip>
  );
};
export default InfoButton;
