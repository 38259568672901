import { Switch } from 'react-router-dom';

// import { useAppSelector } from '../../../../hooks/useRedux';

import GuardRoute from '../../../../router/guards/GuardRoute';
import FaqCreateScreen from '../screens/FaqCreateScreen';
import FaqEditScreen from '../screens/FaqEditScreen';
import { FaqHelpCenterScreen } from '../screens/FaqHelpCenterScreen';
import FaqListScreen from '../screens/FaqListScreen';

const FaqRouter = () => {
   // const { currentModule } = useAppSelector((state) => state.access);

   // const hasPermission = 'periods' in currentModule?.permissions!;

   // if (!hasPermission) return <Redirect to="/dashboard" />;

   return (
      <Switch>
         <GuardRoute
            exact
            path="/administrar-preguntas-frecuentes"
            component={FaqListScreen}
         // permissionKey={permissionKey}
         // subPermissionKey={subPermissionKeys.read}
         />
         <GuardRoute
            exact
            path="/administrar-preguntas-frecuentes/agregar"
            component={FaqCreateScreen}
         // permissionKey={permissionKey}
         // subPermissionKey={subPermissionKeys.read}
         />
         <GuardRoute
            exact
            path="/administrar-preguntas-frecuentes/editar/:faqId"
            component={FaqEditScreen}
         // permissionKey={permissionKey}
         // subPermissionKey={subPermissionKeys.read}
         />
         <GuardRoute
            path="/centro-asistencia"
            component={FaqHelpCenterScreen}
         // permissionKey={permissionKey}
         // subPermissionKey={subPermissionKeys.read}
         />
      </Switch>
   );
};

export default FaqRouter;
