import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ISearchTable } from '../../../shared/interfaces/paginatedTable.interface';
import { Product } from '../interfaces/products.interface';
interface ProductState {
  deleteModalOpen: boolean;
  productIdToDelete?: number;
  productIdToEdit?: number;
  productList: Product[];
  productsTable: ISearchTable;
}

const initialState: ProductState = {
  deleteModalOpen: false,
  productList: [],
  productsTable: {
    page: 1,
    perPage: 5,
    search: '',
  },
};

const productSlice = createSlice({
  name: 'productSlice',
  initialState,
  reducers: {
    setDeleteModalOpen: (state, action: PayloadAction<boolean>) => {
      state.deleteModalOpen = action.payload;
    },
    openDeleteModalWith: (state, action: PayloadAction<number>) => {
      state.productIdToDelete = action.payload;
      state.deleteModalOpen = true;
    },
    resetProductId: (state) => {
      state.productIdToDelete = undefined;
    },
    setProductToEdit: (state, action: PayloadAction<number>) => {
      state.productIdToEdit = action.payload;
    },
    setProductList: (state, action: PayloadAction<Product[]>) => {
      state.productList = action.payload;
    },
    updateProductsTable: (state, action: PayloadAction<ISearchTable>) => {
      state.productsTable = action.payload;
    },
  },
});

export const {
  resetProductId,
  openDeleteModalWith,
  setDeleteModalOpen,
  setProductToEdit,
  setProductList,
  updateProductsTable,
} = productSlice.actions;

export default productSlice.reducer;
