import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import { DatePicker, LocalizationProvider, TimePicker } from '@material-ui/lab';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import ErrorAlert from '../../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../../shared/components/alerts/SuccessAlert';
import InputLabel from '../../../../shared/components/forms/InputLabel';
import DragAreaFileMultiple from '../../../../students/virtual-classroom/components/DragAreaFileMultiple';
import { useUploadFilesMutation } from '../../../../shared/slices/uploadFilesApi';
import {
  useEditStudentsProjectResearchDetailsMutation,
  useStudentsProjectResearchDetailsMutation,
} from '../../slices/followUpOfResearchProjectApiSlice';
import { BinnacleShcema } from '../../constants/validationsSchema';
import { FormHelperText } from '@material-ui/core';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import { setBinnacleSelected } from '../../slices/followUpOfResearchProjectSlice';

const BinnacleForm = () => {
  const { t } = useTranslation();

  const history = useHistory();
  const dispatch = useAppDispatch();
  const { studentSelected, binnacleSelected } = useAppSelector((state) => state.followUpOfResearch);

  const [showAlerts, setShowAlerts] = useState(true);

  const [files, setFiles] = useState<File[] | null>(null);

  const [uploadFile, { isLoading: isLoadingFile, isError: isErrorFile, error: errorFile }] =
    useUploadFilesMutation();

  const [
    uploadBinnacleStudent,
    {
      isLoading: isLoadingUploadBinnacleStudent,
      isError: isErrorUploadBinnacleStudent,
      isSuccess: isSuccessUploadBinnacleStudent,
      error: errorUploadBinnacleStudent,
    },
  ] = useStudentsProjectResearchDetailsMutation();

  const [updateBinnancle] = useEditStudentsProjectResearchDetailsMutation();

  const { getFieldProps, handleSubmit, values, setFieldValue, errors, touched } = useFormik({
    initialValues: {
      date: binnacleSelected ? new Date(binnacleSelected.date) : new Date(),
      starHour: binnacleSelected
        ? new Date(`${binnacleSelected.date} ${binnacleSelected.start_hour}`)
        : new Date(),
      endHour: binnacleSelected
        ? new Date(`${binnacleSelected.date} ${binnacleSelected.end_hour}`)
        : new Date(),
      observation: '',
      hoursNumber: 0,
      numberFiles: 0,
      project_documents: [],
    },
    validationSchema: BinnacleShcema,
    onSubmit: async (_values) => {
      if (!binnacleSelected) {
        await uploadBinnacleStudent({
          payload: {
            date: _values.date.toISOString().split('T')[0],
            start_hour: _values.starHour.toLocaleTimeString(),
            end_hour: _values.endHour.toLocaleTimeString(),
            observation: _values.observation,
            number_hours: _values.hoursNumber,
            student_project_research_id: studentSelected?.id!,
            project_documents: _values.project_documents,
          },
        }).unwrap();
      } else {
        await updateBinnancle({
          studentProjectResearchDetails: binnacleSelected.id,
          payload: {
            date: _values.date.toISOString().split('T')[0],
            start_hour: _values.starHour.toLocaleTimeString(),
            end_hour: _values.endHour.toLocaleTimeString(),
            observation: _values.observation,
            number_hours: _values.hoursNumber,
            student_project_research_id: studentSelected?.id!,
            project_documents: _values.project_documents,
          },
        }).unwrap();
      }

      window.scrollTo(0, 0);
      setShowAlerts(true);
    },
  });

  useEffect(() => {
    setFieldValue(
      'hoursNumber',
      ((values.endHour.getTime() - values.starHour.getTime()) / 1000 / 60 / 60).toFixed(2)
    );
  }, [values.starHour, values.endHour, setFieldValue]);

  useEffect(() => {
    if (files && files.length > 0) {
      const filesToUpload = files.map(async (file) => {
        try {
          const url = await uploadFile({
            files: file,
          }).unwrap();
          return {
            id: null,
            doc_url: url,
            doc_name_file: file.name,
          };
        } catch (err) {
          console.log(err);
        }
      });
      Promise.all(filesToUpload).then((res) => {
        setFieldValue('project_documents', res);
      });
    }
    //eslint-disable-next-line
  }, [files]);

  const handleDeleteDoc = (name: string) => {
    const docs = values.project_documents.filter((doc: any) => doc.doc_name_file !== name);
    setFieldValue('project_documents', docs);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction="column" gap={2}>
        <Grid item xs={12} md={6}>
          <Grid container direction="column" spacing={2} mb={2}>
            {(isErrorFile || isErrorUploadBinnacleStudent) && showAlerts && (
              <ErrorAlert
                message={(errorFile || errorUploadBinnacleStudent) as string}
                handleDismiss={() => setShowAlerts(false)}
              />
            )}
            {isSuccessUploadBinnacleStudent && showAlerts && (
              <SuccessAlert
                message={t('subido con exito')}
                handleDismiss={() => setShowAlerts(false)}
              />
            )}
          </Grid>

          <Grid item xs={12}>
            <InputLabel>Fecha</InputLabel>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                value={values.date}
                onChange={(newValue) => {
                  setFieldValue('date', newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            {errors.date && touched.date && <FormHelperText error>{t('')}</FormHelperText>}
          </Grid>

          <Grid container item xs={12} spacing={2} mt={1}>
            <Grid item xs={6}>
              <InputLabel>Hora de inicio</InputLabel>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <TimePicker
                  value={values.starHour}
                  onChange={(newValue) => {
                    setFieldValue('starHour', newValue);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
              {errors.starHour && touched.starHour && (
                <FormHelperText error>{t('')}</FormHelperText>
              )}
            </Grid>
            <Grid item xs={6}>
              <InputLabel>Hora de finalización</InputLabel>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <TimePicker
                  minTime={values.starHour}
                  value={values.endHour}
                  onChange={(newValue) => {
                    setFieldValue('endHour', newValue);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
              {errors.endHour && touched.endHour && (
                <FormHelperText error>{`${t(String(errors.endHour))}`}</FormHelperText>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} mt={1}>
            <InputLabel>Número de horas</InputLabel>
            <TextField
              {...getFieldProps('hoursNumber')}
              disabled
              // error={Boolean(touched.description && errors.description)}
              // helperText={touched.description && errors.description}
              type="number"
            />
            {errors.hoursNumber && touched.hoursNumber && (
              <FormHelperText error>{t(errors.hoursNumber || '')}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} mt={1}>
            <InputLabel>Observación</InputLabel>
            <TextField
              {...getFieldProps('observation')}
              // error={Boolean(touched.description && errors.description)}
              // helperText={touched.description && errors.description}
              multiline
              fullWidth
              rows={3}
              variant="outlined"
            />
            {errors.observation && touched.observation && (
              <FormHelperText error>{t(errors.observation || '')}</FormHelperText>
            )}
          </Grid>
          {!binnacleSelected && (
            <Grid item xs={12} my={3}>
              <InputLabel>Documentos:</InputLabel>
              <DragAreaFileMultiple
                handleChange={(e) => {
                  setFieldValue('numberFiles', Array.from(e.target.files!).length);
                  setFiles(Array.from(e.target.files!));
                }}
                label="Documentos relacionados con la bitácora"
                description="Los documentos deben ser de no más de 2MB"
                filesView={values.project_documents}
                handleDeleteFile={handleDeleteDoc}
              />
              {errors.numberFiles && touched.numberFiles && (
                <FormHelperText error>{t(errors.numberFiles || '')}</FormHelperText>
              )}
            </Grid>
          )}

          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            spacing={2}
            sx={{ mt: 2 }}
          >
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="outlined"
                size="large"
                color="primary"
                onClick={() => {
                  dispatch(setBinnacleSelected(null));
                  history.goBack();
                }}
                disabled={isLoadingFile || isLoadingUploadBinnacleStudent}
              >
                {t('academic.subjects.add.back')}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                type="submit"
                variant="contained"
                size="large"
                color="primary"
                disabled={isLoadingFile || isLoadingUploadBinnacleStudent}
                endIcon={
                  (isLoadingFile || isLoadingUploadBinnacleStudent) && (
                    <CircularProgress size={20} />
                  )
                }
              >
                {!binnacleSelected ? t('academic.subjects.add.submit') : t('Editar')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default BinnacleForm;
