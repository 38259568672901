import { FC, useEffect, useMemo } from 'react';

import { Autocomplete, Button, FormHelperText, Grid, TextField } from '@material-ui/core';
import { useFormik } from 'formik';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import DatePicker from '@material-ui/lab/DatePicker';

import { format, parseISO } from 'date-fns';

import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../../../../hooks/useRedux';
import InputLabel from '../../../../../shared/components/forms/InputLabel';

import { FormProps } from '../../../interfaces/components.interface';
import { accountTypes } from '../../../constants';
import { setPayCollection } from '../../../slices/debtsPaymentSlice';
import { epsilonRound, updateItemFromIndexArr } from '../../../../../shared/helpers';
import { useGetFinancialInstitutionsCatalogQuery } from '../../../../../shared/slices/catalogApi';
import TextFieldFetching from '../../../../../shared/components/TextFieldFetching';
import CurrencyInputMasked from '../../shared/CurrencyInputMasked';
import { ChequeMethodSchema } from '../../../constants/validationForms-schemas';
import { useGetFinancialInstitutionAccountQuery } from '../../../../../students/payments-history/slices/paymentsApiSlice';
import { useGetLocaleDateLang } from '../../../../../../translation/hooks/useGetLocaleDateLang';

const ChequeMethod: FC<FormProps> = (props) => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();
  const { lang } = useGetLocaleDateLang();

  const defaultConfiguration = useAppSelector((state) => state.access.defaultConfiguration);

  const { paymethod, indexPayToEdit, setIsPay, onCancel } = props;

  const { currentProfile } = useAppSelector((state) => state.access);

  const { payCollection, totalDebtAmount, totalPayAmount } = useAppSelector(
    (state) => state.debtsPayment
  );

  const {
    data: fIA,
    // isLoading: isLoadingFIA,
    isFetching: isFetchingFIA,
  } = useGetFinancialInstitutionAccountQuery({
    profileId: currentProfile?.profileId!,
  });

  const { data: financialInstitutions, isFetching: isFetchingFI } =
    useGetFinancialInstitutionsCatalogQuery({
      profileId: currentProfile?.profileId!,
    });

  const initialValues = useMemo(() => {
    return {
      observation: indexPayToEdit?.toString() ? payCollection[indexPayToEdit]?.observation : '',
      payAmount: indexPayToEdit?.toString()
        ? payCollection[indexPayToEdit]?.payment_amount
        : epsilonRound(
            totalDebtAmount - totalPayAmount > 0 ? totalDebtAmount - totalPayAmount : 0
          ) || null,

      FinancialInstitucionOriginId: indexPayToEdit?.toString()
        ? payCollection[indexPayToEdit]?.financial_institution_origin_id
        : undefined,
      accountType: 'CC',
      // indexPayToEdit?.toString()? payCollection[indexPayToEdit]?.type_account: undefined,
      chequeNum: indexPayToEdit?.toString()
        ? payCollection[indexPayToEdit]?.cheque_number
        : undefined,
      issuerAccount: indexPayToEdit?.toString()
        ? payCollection[indexPayToEdit]?.cuenta_origen
        : undefined,
      issuedAccount: indexPayToEdit?.toString()
        ? payCollection[indexPayToEdit]?.account_number
        : undefined,
      financialInstitutionTargetId: indexPayToEdit?.toString()
        ? payCollection[indexPayToEdit]?.financial_institution_target_id
        : undefined,
      issuerName: indexPayToEdit?.toString()
        ? payCollection[indexPayToEdit]?.issuer_name
        : undefined,
      checkDrawer: undefined,
      depositDate: indexPayToEdit?.toString()
        ? payCollection[indexPayToEdit]?.start_date_collection
          ? parseISO(payCollection[indexPayToEdit]?.start_date_collection!)
          : new Date()
        : new Date(),
      chequeAmount: indexPayToEdit?.toString()
        ? payCollection[indexPayToEdit]?.cheque_mount
        : epsilonRound(totalDebtAmount - totalPayAmount) || undefined,
      chequeObservation: indexPayToEdit?.toString()
        ? payCollection[indexPayToEdit]?.cheque_observation
        : '',
    };
  }, [indexPayToEdit, payCollection, totalDebtAmount, totalPayAmount]);

  //usar un solo formik e importar los initial state
  const { getFieldProps, errors, touched, handleSubmit, setValues, setFieldValue, values } =
    useFormik({
      initialValues: initialValues,
      validationSchema: ChequeMethodSchema,
      // validate: ({ chequeAmount, payAmount }) => {
      //   const errors: any = {};
      //   if (payAmount && chequeAmount && payAmount > chequeAmount) {
      //     const maxPayAmount =
      //       'financial.debts_payment.pay_dialog.form.cheque_method.amount_max_pay';
      //     errors.payAmount = maxPayAmount;
      //   }

      //   return errors;
      // },
      onSubmit: async (values) => {
        try {
          if (!indexPayToEdit?.toString()) {
            const newPayCollection = [
              {
                payment_amount: +values.payAmount!,
                observation: values.observation,
                payment_way_id: paymethod.id,
                payment_way: paymethod.id,
                payment_way_name: paymethod.name,
                payment_way_keyword: paymethod.keyword,

                financial_institution_origin_id: values.FinancialInstitucionOriginId,
                financial_institution_target_id: values.financialInstitutionTargetId,
                cheque_number: values.chequeNum,
                account_number: values.issuedAccount,
                issuer_name: values.issuerName,
                start_date_collection: format(values.depositDate!, 'yyyy-MM-dd'),
                cheque_mount: values.payAmount!, // values.chequeAmount, // se usará el mismo payAmount por alguna razon que no se
                cheque_observation: values.chequeObservation,
                type_account: values.accountType,

                issues_invoice: 1,

                account_number_check_origin: values.issuerAccount,
              },
              ...payCollection,
            ];

            dispatch(setPayCollection(newPayCollection));
          } else {
            const updatedItem = {
              payment_amount: +values.payAmount!,
              observation: values.observation,
              payment_way_id: paymethod.id,
              payment_way: paymethod.id,
              payment_way_name: paymethod.name,
              payment_way_keyword: paymethod.keyword,

              financial_institution_origin_id: values.FinancialInstitucionOriginId,
              financial_institution_target_id: values.financialInstitutionTargetId,
              cheque_number: values.chequeNum,
              account_number: values.issuedAccount,
              issuer_name: values.issuerName,
              start_date_collection: format(values.depositDate!, 'yyyy-MM-dd'),
              cheque_mount: values.chequeAmount,
              cheque_observation: values.chequeObservation,
              type_account: values.accountType,

              issues_invoice: 1,

              account_number_check_origin: values.issuerAccount,
            };

            const newPayCollection = updateItemFromIndexArr(
              payCollection,
              indexPayToEdit,
              updatedItem
            );

            dispatch(setPayCollection(newPayCollection));
          }

          setIsPay();
        } catch (error) {
          console.log(error);
        }

        //   setShowAlerts(true);
        //   window.scrollTo(0, 0);
      },
    });

  useEffect(() => {
    if (indexPayToEdit?.toString()) {
      setValues({ ...initialValues });
    }
  }, [indexPayToEdit, initialValues, setValues]);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={1} alignItems="flex-start">
        <Grid item xs={12}>
          <InputLabel>
            {t('financial.debts_payment.pay_dialog.form.emission_bank_label')}
          </InputLabel>
          {isFetchingFI ? (
            <TextFieldFetching />
          ) : (
            <Autocomplete
              options={financialInstitutions || []}
              getOptionLabel={(option) => option.fin_int_name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(
                    errors.FinancialInstitucionOriginId && touched.FinancialInstitucionOriginId
                  )}
                  placeholder={t(
                    'financial.debts_payment.pay_dialog.form.emission_bank_placeholder'
                  )}
                />
              )}
              value={
                financialInstitutions?.find(
                  (fn) => fn.id === values.FinancialInstitucionOriginId
                ) || null
              }
              onChange={(_, values) =>
                setFieldValue('FinancialInstitucionOriginId', values?.id || null)
              }
              fullWidth
            />
          )}
          {errors.FinancialInstitucionOriginId && touched.FinancialInstitucionOriginId && (
            <FormHelperText error>{t(errors.FinancialInstitucionOriginId)}</FormHelperText>
          )}
        </Grid>

        <Grid item xs={12}>
          <InputLabel>
            {t('financial.debts_payment.pay_dialog.form.cheque_method.account_type_label')}
          </InputLabel>
          <Autocomplete
            options={accountTypes}
            getOptionLabel={(option) => t(option.label)}
            disabled={true}
            renderInput={(params) => (
              <TextField
                {...params}
                error={Boolean(errors.accountType && touched.accountType)}
                placeholder={t(
                  'financial.debts_payment.pay_dialog.form.cheque_method.account_type_placeholder'
                )}
              />
            )}
            value={accountTypes?.find((acc) => acc.keyword === values.accountType) || null}
            onChange={(_, values) => setFieldValue('accountType', values?.keyword || null)}
            fullWidth
          />
          {errors.accountType && touched.accountType && (
            <FormHelperText error>{t(errors.accountType)}</FormHelperText>
          )}
        </Grid>

        <Grid item xs={12}>
          <InputLabel>
            {t('financial.debts_payment.pay_dialog.form.cheque_method.cheque_number_label')}
          </InputLabel>
          <TextField
            fullWidth
            error={Boolean(errors.chequeNum && touched.chequeNum)}
            placeholder={t(
              'financial.debts_payment.pay_dialog.form.cheque_method.cheque_number_placeholder'
            )}
            {...getFieldProps('chequeNum')}
          />
          {errors.chequeNum && touched.chequeNum && (
            <FormHelperText error>{t(errors.chequeNum)}</FormHelperText>
          )}
        </Grid>

        <Grid item xs={12}>
          <InputLabel>
            {t('financial.debts_payment.pay_dialog.form.cheque_method.issued_account_label')}
          </InputLabel>
          <TextField
            fullWidth
            error={Boolean(errors.issuerAccount && touched.issuerAccount)}
            placeholder={t(
              'financial.debts_payment.pay_dialog.form.cheque_method.issued_account_label'
            )}
            {...getFieldProps('issuerAccount')}
          />
          {errors.issuerAccount && touched.issuerAccount && (
            <FormHelperText error>{t(errors.issuerAccount)}</FormHelperText>
          )}
        </Grid>

        <Grid item xs={12}>
          <InputLabel>
            {t('financial.debts_payment.pay_dialog.form.cheque_method.issuer_name_label')}
          </InputLabel>
          <TextField
            fullWidth
            error={Boolean(errors.issuerName && touched.issuerName)}
            placeholder={t(
              'financial.debts_payment.pay_dialog.form.cheque_method.issuer_name_placeholder'
            )}
            {...getFieldProps('issuerName')}
          />
          {errors.issuerName && touched.issuerName && (
            <FormHelperText error>{t(errors.issuerName)}</FormHelperText>
          )}
        </Grid>

        <Grid item xs={12}>
          <InputLabel>
            {t('financial.debts_payment.pay_dialog.form.cheque_method.account_number_label')}
          </InputLabel>
          {isFetchingFIA ? (
            <TextFieldFetching />
          ) : (
            <Autocomplete
              options={fIA || []}
              getOptionLabel={(option) =>
                option.financial_institution.fin_int_name + ' - ' + option.fia_account_number
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(errors.issuedAccount && touched.issuedAccount)}
                  placeholder={t(
                    'financial.debts_payment.pay_dialog.form.cheque_method.account_number_placeholder'
                  )}
                />
              )}
              value={fIA?.find((fn) => fn.fia_account_number === values.issuedAccount) || null}
              onChange={(_, value) => {
                setFieldValue('financialInstitutionTargetId', value?.financial_institution_id!);
                setFieldValue('issuedAccount', value?.fia_account_number!);
              }}
              fullWidth
              clearIcon={null}
            />
          )}
          {errors.issuedAccount && touched.issuedAccount && (
            <FormHelperText error>{t(errors.issuedAccount)}</FormHelperText>
          )}
        </Grid>

        <Grid item xs={12}>
          <InputLabel>{t('financial.debts_payment.pay_dialog.form.amount_label')}</InputLabel>
          <TextField
            // type="number"
            fullWidth
            InputProps={{
              defaultValue: '',
              inputComponent: CurrencyInputMasked as any,
              startAdornment: defaultConfiguration?.currency_symbol,
            }}
            placeholder={`${defaultConfiguration?.currency_symbol}0.00`}
            {...getFieldProps('payAmount')}
            error={Boolean(errors.payAmount && touched.payAmount)}
          />
          {errors.payAmount && touched.payAmount && (
            <FormHelperText error>{t(errors.payAmount)}</FormHelperText>
          )}
        </Grid>
        {/*  Se oculta uno de los campos */}
        {/* 
        <Grid item xs={12}>
          <InputLabel>
            {t('financial.debts_payment.pay_dialog.form.cheque_method.cheque_amount_label')}
          </InputLabel>
          <TextField
            // type="number"
            fullWidth
            placeholder={`${defaultConfiguration?.currency_symbol}0.00`}
            {...getFieldProps('chequeAmount')}
            InputProps={{
              defaultValue: '',
              inputComponent: CurrencyInputMasked as any,
              startAdornment: defaultConfiguration?.currency_symbol,
            }}
            error={Boolean(errors.chequeAmount && touched.chequeAmount)}
          />
          {errors.chequeAmount && touched.chequeAmount && (
            <FormHelperText error>{t(errors.chequeAmount)}</FormHelperText>
          )}
        </Grid> */}

        <Grid item xs={12}>
          <InputLabel>
            {t('financial.debts_payment.pay_dialog.form.cheque_method.check_writer_label')}
          </InputLabel>
          <TextField
            fullWidth
            // error={Boolean(errors.issuerName && touched.issuerName)}
            placeholder={t(
              'financial.debts_payment.pay_dialog.form.cheque_method.check_writer_placeholder'
            )}
            // {...getFieldProps('issuerName')}
          />
          {/* {errors.issuerName && touched.issuerName && (
            <FormHelperText error>{errors.issuerName}</FormHelperText>
          )} */}
        </Grid>

        {/* Fecha de deposito */}
        <Grid item xs={12}>
          <InputLabel>
            {t('financial.debts_payment.pay_dialog.form.cheque_method.deposit_date_label')}
          </InputLabel>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={lang}>
            <DatePicker
              value={values.depositDate}
              onChange={(newValue) => {
                setFieldValue('depositDate', newValue);
              }}
              minDate={new Date()}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(errors.depositDate && touched.depositDate)}
                  placeholder={t(
                    'financial.debts_payment.pay_dialog.form.cheque_method.deposit_date_placeholder'
                  )}
                  fullWidth
                />
              )}
            />
          </LocalizationProvider>
          {errors.depositDate && touched.depositDate && (
            <FormHelperText error>{t(errors.depositDate as string)}</FormHelperText>
          )}
        </Grid>

        <Grid item xs={12}>
          <InputLabel>
            {t('financial.debts_payment.pay_dialog.form.cheque_method.observation_cheque_label')}
          </InputLabel>
          <TextField
            fullWidth
            multiline
            rows={2}
            error={Boolean(errors.chequeObservation && touched.chequeObservation)}
            placeholder={t(
              'financial.debts_payment.pay_dialog.form.cheque_method.observation_cheque_placeholder'
            )}
            {...getFieldProps('chequeObservation')}
          />
          {errors.chequeObservation && touched.chequeObservation && (
            <FormHelperText error>{t(errors.chequeObservation)}</FormHelperText>
          )}
        </Grid>

        <Grid item xs={12}>
          <InputLabel>
            {t('financial.debts_payment.pay_dialog.form.cheque_method.observation_label')}
          </InputLabel>
          <TextField
            fullWidth
            multiline
            rows={2}
            error={Boolean(errors.observation && touched.observation)}
            placeholder={t(
              'financial.debts_payment.pay_dialog.form.cheque_method.observation_placeholder'
            )}
            {...getFieldProps('observation')}
          />
          {errors.observation && touched.observation && (
            <FormHelperText error>{t(errors.observation)}</FormHelperText>
          )}
        </Grid>

        {/* <Grid item xs={12} marginTop={2}>
              <Typography variant="h5" component="div" sx={{ mb: 3 }}>
                3. Establezca el inicio y fin del periodo
              </Typography>

              <LocalizationProvider dateAdapter={AdapterDateFns} locale={lang}>
                <DateRangePicker
                  startText={null}
                  endText={null}
                  value={periodDate}
                  onChange={onChangesPeriodProcess}
                  renderInput={(startProps, endProps) => (
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12} sm={3} lg={4} sx={{ pt: { xs: 2, sm: '0 !important' } }}>
                        <InputLabel
                          className={classes.inputLabel}
                          sx={{ textAlign: { xs: 'left', sm: 'right' } }}
                        >
                          Fecha de Inicio:
                        </InputLabel>
                      </Grid>
                      <Grid item xs={12} sm={9} lg={8}>
                        <TextField
                          fullWidth
                          {...startProps}
                          helperText=""
                          error={Boolean(errors.start_process && touched.start_process)}
                          {...getFieldProps('start_process')}
                        />
                        <FormHelperText>A partir de esta fecha comenzará el periodo</FormHelperText>

                        {errors.start_process && touched.start_process && (
                          <FormHelperText error>{errors.start_process}</FormHelperText>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={3} lg={4} sx={{ pt: { xs: 2, sm: '0 !important' } }}>
                        <InputLabel
                          className={classes.inputLabel}
                          sx={{ textAlign: { xs: 'left', sm: 'right' } }}
                        >
                          Fecha de Fin:
                        </InputLabel>
                      </Grid>

                      <Grid item xs={12} sm={9} lg={8}>
                        <TextField
                          fullWidth
                          {...endProps}
                          helperText=""
                          error={Boolean(errors.end_process && touched.end_process)}
                          {...getFieldProps('end_process')}
                        />
                        <FormHelperText>En esta fecha el periodo finalizará</FormHelperText>
                        {errors.end_process && touched.end_process && (
                          <FormHelperText error>{errors.end_process}</FormHelperText>
                        )}
                      </Grid>
                    </Grid>
                  )}
                />
              </LocalizationProvider>
            </Grid> */}

        {/* <Grid item xs={12}>
              <InputLabel>Campus</InputLabel>
              <Autocomplete
                multiple
                options={campusAvailables}
                loading={isLoadingCAMP}
                loadingText="Cargando campus disponibles..."
                getOptionLabel={(option) => option.cam_name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(errors.campusIds && touched.campusIds)}
                    placeholder="Seleccione los campus disponibles"
                  />
                )}
                onChange={(_, values) =>
                  setFieldValue('campusIds', [...values.map((v) => v.id)] || '')
                }
                fullWidth
              />
              {errors.campusIds && touched.campusIds && (
                <FormHelperText error>{errors.campusIds}</FormHelperText>
              )}
            </Grid> */}

        <Grid item xs={12}>
          <Grid container justifyContent={'flex-end'} spacing={1}>
            <Grid item>
              <Button variant="outlined" onClick={onCancel}>
                {t('financial.debts_payment.pay_dialog.form.cancel_btn')}
              </Button>
            </Grid>
            <Grid item>
              <Button type="submit" variant="contained">
                {indexPayToEdit?.toString()
                  ? t('financial.debts_payment.pay_dialog.form.update_btn')
                  : t('financial.debts_payment.pay_dialog.form.save_btn')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default ChequeMethod;
