import { useState, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';

import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import StatusChip from '../../../../shared/components/badges/StatusChip';
import EditButton from '../../../../shared/components/tables/buttons/EditButton';
import DeleteButton from '../../../../shared/components/tables/buttons/DeleteButton';
import AddButton from '../../../../shared/components/tables/buttons/AddButton';
import {
  updateInvestigationLinesTableStates,
  openDeleteModalWith,
  setInvestigationLinesList,
} from '../../slices/investigationLinesSlice';
import SearchPaginatedTable, {
  FilterArrow,
} from '../../../../shared/components/tables/SearchPaginatedTable';
import { useTableSearch } from '../../../../../hooks/useTableSearch';
import { InvestigationLineResponse } from '../../interfaces/investigationLine.interfaces';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { SerializedError } from '@reduxjs/toolkit';
import { statusAvailables } from '../../../../shared/constants/resourceStatus';
import DeleteResearchLineDialog from './DeleteResearchLineDialog';
import EditInvestigationLineForm from './forms/EditInvestigationLineForm';
import { FilterData } from './index';
import CustomSideDialog from '../../../../shared/components/dialogs/CustomSideDialog';

interface Props {
  data: InvestigationLineResponse;
  isLoading: boolean;
  isFetching: boolean;
  error: FetchBaseQueryError | SerializedError | undefined;
  setSort: React.Dispatch<React.SetStateAction<FilterData>>;
  sort: FilterData;
}

const InvestigationProjectLinesTable = ({
  data,
  isLoading,
  isFetching,
  error,
  setSort,
  sort,
}: Props) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { path } = useRouteMatch();

  const history = useHistory();

  const [filterArrow, setFilterArrow] = useState<FilterArrow>(sort.group);

  const [openDialog, setOpenDialog] = useState(false);

  const [researchLineId, setResearchLineId] = useState('');

  const { deleteModalOpen } = useAppSelector((state) => state.investigationLines);

  const { page, perPage, search } = useAppSelector(
    (state) => state.investigationLines.investigationLineTable
  );

  const { setPage, setPerPage, setSearch } = useTableSearch({
    page,
    perPage,
    search,
    updateFunction: updateInvestigationLinesTableStates,
  });

  const handleDelete = async (publication: string) => {
    dispatch(openDeleteModalWith(publication));
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleOpen = (id: string) => {
    setOpenDialog(true);
    setResearchLineId(id);
  };

  const goToAdd = () => {
    history.push(`${path}/crear/linea-de-investigacion`);
  };

  const datafetch = data?.data!.map((d, index) => ({
    id: { index: index + 1 + (page - 1) * perPage, id: d.id },
    name: d.name,
    educationLevel: d.education_level,
    statusAcronym: d.status,
    options: '',
  }));

  useEffect(() => {
    if (data) {
      dispatch(setInvestigationLinesList(data.data));
    }
  }, [data, dispatch]);

  useEffect(() => {
    setSort((state) => ({ ...state, lines: filterArrow }));
    //eslint-disable-next-line
  }, [filterArrow]);

  return (
    <>
      <SearchPaginatedTable
        data={datafetch!}
        headers={{
          id: 'N°',
          name: t('academic.subjects.name'),
          educationLevel: t('project.AddCollaboratorProject.studentId.title'),
          statusAcronym: t('academic.subjects.status'),
          options: t('academic.subjects.options'),
        }}
        keyExtractor={(item) => item.id.id.toString()}
        searchPlacehoder={t('lines_research.index.investigationProjectFilter.search.researchLines')}
        isLoading={isLoading}
        isFetching={isFetching}
        listFilterArrows={{
          name: 'name',
          // educationLevel: 'education_level',
          statusAcronym: 'status',
        }}
        filterArrow={filterArrow}
        setFilterArrow={setFilterArrow}
        error={error}
        perPage={perPage}
        setPerPage={setPerPage}
        total={data?.total || 0}
        page={page}
        setPage={setPage}
        numHeader={5}
        search={search}
        // showFilter={false}
        setSearch={setSearch}
        customRenderers={{
          id: (row) => row.id.index,
          educationLevel: (row) =>
            row.educationLevel.map(({ edu_name }) => (
              <Typography variant="subtitle2" color="inherit">
                {edu_name}
              </Typography>
            )),

          statusAcronym: (row) => (
            <StatusChip
              statusId={
                statusAvailables.find(
                  (status) => status.acronym?.toLowerCase() === row.statusAcronym
                )?.id || 0
              }
            />
          ),
          options: (row) => (
            <>
              {/* <Link to={`${path}/${row.id}/editar`}> */}
              <EditButton handleEdit={() => handleOpen(row.id.id)} />
              {/* </Link> */}

              <DeleteButton handleDelete={() => handleDelete(row.id.id)} />
              {/* {!hasAccess('edit') && !hasAccess('delete') && t('academic.subjects.none')} */}
            </>
          ),
        }}
        ActionButtons={
          <>
            <AddButton
              btnText={t('lines_research.investigationProjectLinesTable.add_button')}
              size="large"
              onClick={goToAdd}
            />
          </>
        }
        customDataCellsProperties={{
          id: {
            align: 'center',
          },
          name: {
            align: 'center',
          },
          statusAcronym: {
            align: 'center',
          },
          educationLevel: {
            align: 'center',
          },
          options: {
            align: 'center',
          },
        }}
      />
      {deleteModalOpen && <DeleteResearchLineDialog />}
      {
        <CustomSideDialog
          title={t('lines_research.investigationProjectLinesTable.ExtendDialog.title')}
          open={openDialog}
          handleCloseDialog={handleClose}
        >
          {openDialog ? <EditInvestigationLineForm researchLineId={researchLineId} /> : <></>}
        </CustomSideDialog>
      }
    </>
  );
};

export default InvestigationProjectLinesTable;
