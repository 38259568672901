import { createSlice } from '@reduxjs/toolkit';

interface ConfigurationsState {
  dataConf: [];
}

const initialState: ConfigurationsState = {
  dataConf: [],
};

const configurationSlice = createSlice({
  name: 'configurations',
  initialState,
  reducers: {},
});

// export const {} = configurationSlice.actions;

export default configurationSlice.reducer;
