import {
  Grid,
  useTheme,
  Stack,
  Box,
  ImageList,
  ImageListItem,
  useMediaQuery,
} from '@material-ui/core';
import ProfileStudentCardV2 from '../components/ProfileStudentCardV2';

import MainCard from '../../../shared/components/cards/MainCard';
import RecentSubjects from '../components/RecentSubjects';
import CareerPercentage from '../components/CareerPercentage';
import AcademicAdvisor from '../components/AcademicAdvisor';
import LastPayments from '../components/LastPayments';
import ActivitiesCalendar from '../components/ActivitiesCalendar';
import WeekActivities from '../components/WeekActivities';
import InstitutionBanner from '../components/InstitutionBanner';

import RecentApplications from '../components/RecentApplications';
import RecentCertificates from '../components/RecentCertificates';
import CurriculumCard from '../components/CurriculumCard';
import ExternalLinks from '../components/ExternalLinks';
import ApprovedNivelationInfo from '../components/ApprovedNivelationInfo';
import RecentNews from '../components/RecentNews';
import { useAppSelector } from '../../../../hooks/useRedux';
import AcademicDeanContacts from '../components/AcademicDeanContacts';
import LibraryCard from '../components/LibraryCard';
export interface hourhandData {
  start: string;
  end: string;
  name: string;
}

const DashboardScreen = () => {
  const theme = useTheme();

  const { currentRecordStudent } = useAppSelector((state) => state.access);

  const matchDownLG = useMediaQuery(theme.breakpoints.down('lg'));
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Stack>
      <Grid item sx={{ backgroundColor: `${theme.palette.background}`, maxWidth: '1400px' }}>
        <ProfileStudentCardV2 />
      </Grid>

      <Grid component={MainCard} sx={{ borderRadius: '0px 0px 12px 12px', maxWidth: '1400px' }}>
        <Grid item xs={12} mb={1}>
          <ApprovedNivelationInfo />
        </Grid>

        <Box sx={{ width: '100%', boxSizing: 'border-box' }}>
          <ImageList variant="masonry" cols={matchDownLG ? (matchDownSM ? 1 : 2) : 3} gap={16}>
            <ImageListItem sx={{ width: '100%' }}>
              <CareerPercentage />
            </ImageListItem>
            {currentRecordStudent?.mesh?.link_curriculum && (
              <ImageListItem sx={{ width: '100%' }}>
                <CurriculumCard />
              </ImageListItem>
            )}
            <ImageListItem sx={{ width: '100%' }}>
              <RecentSubjects />
            </ImageListItem>
            <ImageListItem sx={{ width: '100%' }}>
              <LibraryCard />
            </ImageListItem>
            <ImageListItem sx={{ width: '100%' }}>
              <RecentApplications />
            </ImageListItem>
            <ImageListItem sx={{ width: '100%' }}>
              <RecentCertificates />
            </ImageListItem>
            <ImageListItem sx={{ width: '100%' }}>
              <RecentNews />
            </ImageListItem>
            <ImageListItem sx={{ width: '100%' }}>
              <LastPayments />
            </ImageListItem>
            <ImageListItem sx={{ width: '100%' }}>
              <InstitutionBanner />
            </ImageListItem>
            {!!currentRecordStudent?.education_level.father?.collaborator_id && (
              <ImageListItem sx={{ width: '100%' }}>
                <AcademicDeanContacts />
              </ImageListItem>
            )}
            <ImageListItem sx={{ width: '100%' }}>
              <AcademicAdvisor />
            </ImageListItem>
            <ImageListItem sx={{ width: '100%' }}>
              <ActivitiesCalendar />
            </ImageListItem>
          </ImageList>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <ExternalLinks />
          </Grid>
          <Grid item xs={12}>
            <WeekActivities />
          </Grid>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default DashboardScreen;
