import { Box, Button, Grid, Skeleton, Stack, TextField, Typography } from '@material-ui/core';
import Chart from 'react-apexcharts';
import { DateRangePicker, LocalizationProvider } from '@material-ui/lab';
import esLocale from 'date-fns/locale/es';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { format } from 'date-fns';
import MetricsMessage from '../MetricsMessage';
import InputLabel from '../../../../shared/components/forms/InputLabel';
import { useLazyMetricsGenreQuery } from '../../slices/statisticsApiSlice';

const Visits = () => {
  const [getMetrics, { data, isUninitialized, isLoading }] = useLazyMetricsGenreQuery();

  const schema = yup.object().shape({
    ini: yup.string().nullable().required('Fecha de Inicio es requerida'),
    end: yup.string().nullable().required('Fecha de Fin es requerida'),
  });

  const formik = useFormik({
    initialValues: {
      ini: '',
      end: '',
    },
    validationSchema: schema,
    onSubmit: (values) => {
      getMetrics({
        user_profile_id: 1,
        end_date: format(new Date(values.end), 'yyyy-MM-dd'),
        start_date: format(new Date(values.ini), 'yyyy-MM-dd'),
      });
    },
  });

  const options = {
    chart: {
      id: 'basic-bar',
    },
    xaxis: {
      categories: ['Est. Masculino', 'Est. Femenino', 'Prof. Masculino', 'Prof. Femenino'],
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
      },
      title: {
        text: 'Número de Visitas',
      },
    },
  };

  return (
    <Grid container>
      <Grid item container spacing={2} direction="row-reverse">
        <Grid item xs={12} md={8} lg={8}>
          <InputLabel>Fechas</InputLabel>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
            <DateRangePicker
              value={[formik.values.ini, formik.values.end]}
              onChange={(val) => {
                if (val) {
                  formik.setFieldValue('ini', val[0]);
                  formik.setFieldValue('end', val[1]);
                }
              }}
              renderInput={(startProps, endProps) => (
                <>
                  <TextField
                    {...startProps}
                    label={null}
                    fullWidth
                    placeholder="Fecha"
                    error={Boolean(formik.errors.ini && formik.touched.ini)}
                    helperText={
                      Boolean(formik.errors.ini && formik.touched.ini) && formik.errors.ini
                    }
                  />
                  <Box sx={{ mx: 2 }}> - </Box>
                  <TextField
                    {...endProps}
                    label={null}
                    fullWidth
                    placeholder="Fecha"
                    error={Boolean(formik.errors.end && formik.touched.end)}
                    helperText={
                      Boolean(formik.errors.end && formik.touched.end) && formik.errors.end
                    }
                  />
                </>
              )}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={12} md={12} lg={12} textAlign="right">
          <Button
            variant="contained"
            size="large"
            color="primary"
            sx={{ mt: 2 }}
            onClick={() => {
              formik.handleSubmit();
            }}
          >
            Buscar
          </Button>
        </Grid>
      </Grid>
      <br />
      <br />
      <Grid container spacing={6}>
        {isUninitialized ? (
          <Grid item xs={12}>
            <Stack alignItems="center">
              <MetricsMessage />
            </Stack>
          </Grid>
        ) : (
          <>
            <Grid item xs={12} md={12} lg={12}>
              <Typography variant="h3" sx={{ m: 2 }}>
                Visitas de Totales por Género
              </Typography>
              <Box sx={{ maxWidth: 600, margin: '0 auto' }}>
                {isLoading ? (
                  <Skeleton width={400} height={400} />
                ) : (
                  <>
                    {data && (
                      <Chart
                        options={options}
                        series={[
                          {
                            name: 'Total',
                            data: [
                              data.total_estudiante_masculino,
                              data.total_estudiante_femenino,
                              data.total_colaborador_masculino,
                              data.total_colaborador_femenino,
                            ],
                          },
                        ]}
                        type="bar"
                        width="100%"
                      />
                    )}
                  </>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h3" sx={{ m: 2 }}>
                Visitas de Estudiantes por Genero
              </Typography>
              {data && (
                <Chart
                  options={{
                    labels: ['Estudiante Masculino', 'Estudiante Femenino'],
                  }}
                  series={[
                    Number(data.porcentaje_estudiante_masculino),
                    Number(data.porcentaje_estudiante_femenino),
                  ]}
                  type="donut"
                  width="100%"
                />
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h3" sx={{ m: 2 }}>
                Visitas de Docentes por Genero
              </Typography>

              {data && (
                <Chart
                  options={{
                    labels: ['Docente Masculino', 'Profesor Femenino'],
                  }}
                  series={[
                    Number(data.porcentaje_colaborador_masculino),
                    Number(data.porcentaje_colaborador_femenino),
                  ]}
                  type="donut"
                  width="100%"
                />
              )}
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default Visits;
