import { Button, IconButton, Tooltip } from '@material-ui/core';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import SearchPaginatedTable, {
  FilterArrow,
} from '../../../shared/components/tables/SearchPaginatedTable';
import { useGetRequestsStatusListQuery } from '../slices/requestsStatusApiSlice';
import { RemoveRedEye } from '@material-ui/icons';
import { useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { RequestStatus } from '../interfaces/typesApplications.interface';
import { setRequestStatus } from '../slices/requestsStatusSlice';
import { IconPlus } from '@tabler/icons';
import useSharedGuard from '../../../../hooks/useSharedGuard';
import { subPermissionKeys } from '../constants/permissions';

const RequestsStatusTable = () => {
  const { currentProfile } = useAppSelector((state) => state.access);

  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(5);

  const [filterArrow, setFilterArrow] = useState<FilterArrow>(null!);

  const history = useHistory();
  const { path } = useRouteMatch();

  const dispatch = useAppDispatch();

  const { hasAccess } = useSharedGuard(subPermissionKeys);

  const { data, isLoading, isFetching, error } = useGetRequestsStatusListQuery({
    data_: '',
    page: page,
    size: perPage,
    sort: filterArrow?.name || '',
    type_sort: filterArrow?.direction || '',
    user_profile_id: currentProfile?.profileId!,
    search: search,
  });

  const goToShow = (requestStatus: RequestStatus) => {
    dispatch(setRequestStatus(requestStatus));
    history.push(`${path}/detalle/${requestStatus.id}`);
  };

  const goToAdd = () => {
    history.push(`${path}/agregar`);
  };

  const datafetch =
    data?.data.map((el, index) => ({
      id: index + ((page - 1) * perPage + 1),
      name: el.typ_app_name,
      acronym: el.typ_app_acronym,
      description: el.typ_app_description,
      options: el,
    })) || [];

  return (
    <SearchPaginatedTable
      data={datafetch}
      isLoading={isLoading}
      headers={{
        id: 'N°',
        name: 'Nombre',
        acronym: 'Acrónimo',
        description: 'Descripción',
        options: 'Opciones',
      }}
      listFilterArrows={{
        name: 'typ_app_name',
        acronym: 'typ_app_acronym',
        description: 'typ_app_description',
      }}
      filterArrow={filterArrow}
      setFilterArrow={setFilterArrow}
      isFetching={isFetching}
      error={error}
      keyExtractor={(item) => item.options.id.toString()}
      searchPlacehoder="Buscar Estado"
      total={data?.total || 0}
      perPage={perPage}
      setPerPage={setPerPage}
      page={page}
      numHeader={4}
      setPage={setPage}
      search={search}
      setSearch={setSearch}
      ActionButtons={
        <>
          {hasAccess('create') && (
            <Button variant="contained" endIcon={<IconPlus />} onClick={goToAdd}>
              Agregar
            </Button>
          )}
        </>
      }
      customRenderers={{
        options: (row) => (
          <Tooltip title="Visualizar ">
            <IconButton onClick={() => goToShow(row.options)}>
              <RemoveRedEye color="secondary" />
            </IconButton>
          </Tooltip>
        ),
      }}
      customDataCellsProperties={{
        id: { align: 'center' },
        acronym: { align: 'center' },
        options: { align: 'center' },
      }}
    />
  );
};

export default RequestsStatusTable;
