import DeleteAlert from '../../../shared/components/dialogs/DeleteAlert';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { useDeleteMatterTypeMutation } from '../slices/mattersTypesApiSlice';
import { setDeleteModalOpen, resetPeriodIdToDelete } from '../slices/mattersTypesSlice';

const DeletePeriodDialog = () => {
  const dispatch = useAppDispatch();

  const { deleteModalOpen, matterTypeIdToDelete } = useAppSelector((state) => state.mattersTypes);
  const { currentProfile } = useAppSelector((state) => state.access);

  const [deleteMatterType, { isLoading, isSuccess, isError, error }] =
    useDeleteMatterTypeMutation();

  const handleDelete = () => {
    deleteMatterType({
      profileId: currentProfile?.profileId!,
      matterTypeId: matterTypeIdToDelete!,
    });
  };

  const handleClose = () => {
    dispatch(setDeleteModalOpen(false));
    dispatch(resetPeriodIdToDelete());
  };

  return (
    <DeleteAlert
      open={deleteModalOpen}
      onClose={handleClose}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      handleDelete={handleDelete}
      error={error as string}
    />
  );
};

export default DeletePeriodDialog;
