import {
  IconNotebook,
  IconCash,
  IconFileExport,
  IconCertificate,
  IconCalendarEvent,
  IconArchive,
  IconListCheck,
  IconClipboardCheck,
  IconReceipt2,
  IconCreditCard,
  IconNotification,
  IconCalendarTime,
  IconSchool,
  IconActivity,
  IconPackage,
  IconCar,
  IconHome,
  IconBoxMultiple,
  IconFileSpreadsheet,
  IconBus,
  IconSitemap,
  IconNotes,
} from '@tabler/icons';
// import { shared } from './shared';

// Icon Group
const icons = {
  IconNotebook,
  IconCash,
  IconFileExport,
  IconCertificate,
  IconNotification,
  IconCalendarEvent,
  IconArchive,
  IconListCheck,
  IconClipboardCheck,
  IconReceipt2,
  IconActivity,
  IconCreditCard,
  IconCalendarTime,
  IconSchool,
  IconPackage,
  IconCar,
  IconHome,
  IconBoxMultiple,
  IconFileSpreadsheet,
  IconBus,
  IconSitemap,
  IconNotes,
};

// TODO: asignar los permissions key correspondientes

export const students = {
  id: 'students',
  type: 'group',
  title: 'Estudiantes',
  children: [
    {
      id: 'inicio-estudiante',
      title: 'Inicio',
      menuId: 'students.menu.dashboard',
      type: 'item',
      url: '/inicio-estudiante',
      permissionKey: 'student',
      icon: icons['IconHome'],
    },
    {
      id: 'portafolio-academico',
      title: 'Cursos',
      menuId: 'students.menu.courses',
      type: 'item',
      url: '/portafolio-academico',
      permissionKey: 'student',
      icon: icons['IconBoxMultiple'],
    },
    {
      id: 'malla-curricular',
      title: 'Malla curricular',
      menuId: 'students.menu.subjects.curriculum',
      type: 'item',
      url: '/malla-curricular',
      permissionKey: 'student',
      icon: icons['IconSitemap'],
    },
    {
      id: 'materias',
      title: 'Materias',
      menuId: 'students.menu.subjects',
      type: 'collapse',
      permissionKey: 'student',
      icon: icons['IconClipboardCheck'],
      children: [
        {
          id: 'asignaturas-habilitadas',
          title: 'Asignaturas habilitadas',
          menuId: 'students.menu.subjects.enabled',
          type: 'item',
          url: '/asignaturas-habilitadas',
          permissionKey: 'student',
          // icon: icons['IconClipboardCheck'],
        },
        {
          id: 'registro-asignaturas',
          title: 'Registro de asignaturas',
          menuId: 'students.menu.subjects.register',
          type: 'item',
          url: '/registro-asignaturas',
          permissionKey: 'student',
          // icon: icons['IconListCheck'],
        },
      ],
    },
    {
      id: 'asignaturas-horarios',
      title: 'Horarios',
      type: 'item',
      url: '/asignaturas-horarios',
      permissionKey: 'student',
      icon: icons['IconCalendarEvent'],
    },
    {
      id: 'consejeria-academica',
      title: 'Consejería académica',
      menuId: 'students.menu.academic-counseling',
      type: 'item',
      url: '/consejeria-academica',
      permissionKey: 'student',
      icon: icons['IconSchool'],
    },
    {
      id: 'estado-de-cuenta',
      title: 'Estado de cuenta',
      menuId: 'students.menu.account-status',
      type: 'item',
      url: '/estado-de-cuenta',

      permissionKey: 'student',
      icon: icons['IconCreditCard'],
    },
    {
      id: 'solicitudes',
      title: 'Solicitudes',
      menuId: 'students.menu.applications',
      type: 'collapse',
      permissionKey: 'student',
      icon: icons['IconFileExport'],
      children: [
        {
          id: 'académicas',
          title: 'Académicas',
          menuId: 'students.menu.applications.academic',
          type: 'collapse',
          permissionKey: 'student',
          children: [
            {
              id: 'solicitudes/examen-atrasado',
              title: 'Examen atrasado',
              menuId: 'students.menu.applications.academic.exam-late',
              type: 'item',
              url: '/solicitudes/examen-atrasado',
              permissionKey: 'student',
            },
            {
              id: '/solicitudes/examen-recuperacion',
              title: 'Examen de recuperación',
              menuId: 'students.menu.applications.academic.exam-recovery',
              type: 'item',
              url: '/solicitudes/examen-recuperacion',
              permissionKey: 'student',
            },
            {
              id: 'solicitudes/retiro-materia',
              title: 'Retiro de materia',
              menuId: 'students.menu.applications.academic.subject-retire',
              type: 'item',
              url: '/solicitudes/retiro-materia',
              permissionKey: 'student',
            },
            {
              id: 'solicitudes/recalificacion',
              title: 'Recalificación de notas',
              menuId: 'students.menu.applications.academic.recalification',
              type: 'item',
              url: '/solicitudes/recalificacion',
              permissionKey: 'student',
            },
            {
              id: 'solicitudes/cambio-facultad-carrera',
              title: 'Cambio de facultad o carrera',
              menuId: 'students.menu.applications.academic.change-faculty-carrer',
              type: 'item',
              url: '/solicitudes/cambio-facultad-carrera',
              permissionKey: 'student',
            },
            {
              id: 'solicitudes/registro-tercera-matricula',
              title: 'Registro de tercera matrícula',
              menuId: 'students.menu.applications.academic.third-registration',
              type: 'item',
              url: '/solicitudes/registro-tercera-matricula',
              permissionKey: 'student',
            },
            {
              id: 'solicitudes/otros-casos',
              title: 'Otros casos especiales',
              menuId: 'students.menu.applications.academic.other-cases',
              type: 'item',
              url: '/solicitudes/otros-casos',
              permissionKey: 'student',
            },
          ],
        },
        {
          id: 'practicas-preprofesionales',
          title: 'Prácticas preprofesionales',
          menuId: 'students.menu.applications.pre-professional-practice',
          type: 'collapse',
          permissionKey: 'student',
          children: [
            {
              id: 'solicitudes/ingreso-practica-preprofesional',
              title: 'Ingreso a práctica preprofesional',
              menuId: 'students.menu.applications.pre-professional-practice.entry-practice',
              type: 'item',
              url: '/solicitudes/ingreso-practica-preprofesional',
              permissionKey: 'student',
            },
          ],
        },
        {
          id: 'vinculacion',
          title: 'Vinculación',
          menuId: 'students.menu.applications.vinculation',
          type: 'collapse',
          permissionKey: 'student',
          children: [
            {
              id: 'solicitudes/ingreso-vinculacion',
              title: 'Ingreso a vinculación',
              menuId: 'students.menu.applications.vinculation.entry-vinculation',
              type: 'item',
              url: '/solicitudes/ingreso-vinculacion',
              permissionKey: 'student',
            },
          ],
        },
        {
          id: 'titulacion',
          title: 'Titulación',
          menuId: 'students.menu.applications.title',
          type: 'collapse',
          permissionKey: 'student',
          children: [
            {
              id: 'solicitudes/registro-anteproyecto',
              title: 'Registro de anteproyecto',
              menuId: 'students.menu.applications.title.anteproject-register',
              type: 'item',
              url: '/solicitudes/registro-anteproyecto',
              permissionKey: 'student',
            },
          ],
        },
        {
          id: 'financiero',
          title: 'Financiero',
          menuId: 'students.menu.applications.financial',
          type: 'collapse',
          permissionKey: 'student',
          children: [
            {
              id: 'solicitudes/convenio-pago',
              title: 'Convenio de pago',
              menuId: 'students.menu.applications.financial.payment-agreement',
              type: 'item',
              url: '/solicitudes/convenio-pago',
              permissionKey: 'student',
            },
          ],
        },
        {
          id: 'solicitudes/consultar-solicitudes',
          title: 'Consultar solicitudes',
          menuId: 'students.menu.applications.consult-requests',
          type: 'item',
          url: '/solicitudes/consultar-solicitudes',
          permissionKey: 'student',
        },
      ],
    },
    {
      id: 'certificados',
      title: 'Certificados',
      menuId: 'students.menu.certificates',
      type: 'collapse',
      permissionKey: 'student',
      icon: icons['IconCertificate'],
      children: [
        {
          id: 'académicos',
          title: 'Académicos',
          menuId: 'students.menu.certificates.academic',
          type: 'collapse',
          permissionKey: 'student',
          children: [
            {
              id: 'certificados/record-academico',
              title: 'Record académico',
              menuId: 'students.menu.certificates.academic.academic-record',
              type: 'item',
              url: '/certificados/record-academico',
              permissionKey: 'student',
            },
            {
              id: 'certificados/certificado-estudio',
              title: 'Certificado de estudio',
              menuId: 'students.menu.certificates.academic.study-certificate',
              type: 'item',
              url: '/certificados/certificado-estudio',
              permissionKey: 'student',
            },
            {
              id: 'certificados/disciplina-tercera-matricula',
              title: 'Certificado de disciplina y tercera matrícula',
              menuId: 'students.menu.certificates.academic.third-semester-discipline',
              type: 'item',
              url: '/certificados/disciplina-tercera-matricula',
              permissionKey: 'student',
            },
            {
              id: 'certificados/materias-aprovadas',
              title: 'Materias aprobadas',
              menuId: 'students.menu.certificates.academic.approved-subjects',
              type: 'item',
              url: '/certificados/materias-aprovadas',
              permissionKey: 'student',
            },
          ],
        },
        {
          id: 'certificados/practicas-preprofesionales',
          title: 'Prácticas preprofesionales',
          menuId: 'students.menu.certificates.pre-professional-practice',
          type: 'item',
          permissionKey: 'student',
          url: '/certificados/practicas-preprofesionales',
        },
        {
          id: 'certificados/certificados-pagados',
          title: 'Certificados Pagados',
          menuId: 'students.menu.certificates.history-certificates',
          type: 'item',
          permissionKey: 'student',
          url: '/certificados/certificados-pagados',
        },
        {
          id: 'personal-process',
          title: 'Trámites personales',
          type: 'collapse',
          menuId: 'students.menu.certificates.personal-process',
          permissionKey: 'student',
          children: [
            {
              id: 'certificados/credit',
              title: 'Crédito educativo',
              menuId: 'students.menu.certificates.personal-process.credit',
              type: 'item',
              url: '/certificados/credit',
              permissionKey: 'student',
            },
            {
              id: 'certificados/consulado',
              title: 'Consulado',
              menuId: 'students.menu.certificates.personal-process.consulado',
              type: 'item',
              url: '/certificados/consulado',
              permissionKey: 'student',
            },
          ],
        },
      ],
    },
    {
      id: 'eventos-visualizar',
      title: 'Eventos',
      menuId: 'students.menu.events',
      type: 'item',
      url: '/eventos-visualizar',
      permissionKey: 'student',
      icon: icons['IconNotification'],
    },
    {
      id: 'Projectos',
      title: 'Projects',
      menuId: 'students.menu.project',
      type: 'item',
      url: '/proyectos-estudiante',
      permissionKey: 'student',
      icon: icons['IconFileSpreadsheet'],
    },
    {
      id: 'noticias-visualizar',
      title: 'Noticias',
      menuId: 'students.menu.news',
      type: 'item',
      url: '/noticias-visualizar',
      permissionKey: 'student',
      icon: icons['IconCalendarTime'],
    },
    {
      id: 'bus-route',
      title: 'Ruta de buses',
      menuId: 'academic.menu.bus_route',
      type: 'item',
      url: '/bus-route',
      permissionKey: 'student',
      icon: icons['IconBus'],
    },
    {
      id: 'biblioteca',
      title: 'Biblioteca',
      menuId: 'students.menu.library',
      type: 'collapse',
      // url: 'https://biblio.ecotec.edu.ec/apps',
      url: '/biblioteca/inicio',
      permissionKey: 'student',
      icon: icons['IconNotebook'],
      children: [
        {
          id: 'biblioteca/inicio',
          title: 'Libros',
          menuId: 'students.menu.library.books',
          type: 'item',
          url: '/biblioteca/inicio',
          permissionKey: 'student',
        },
        {
          id: 'biblioteca-reservas',
          title: 'Reservas',
          menuId: 'students.menu.library.reservations',
          type: 'item',
          url: '/biblioteca-reservas',
          permissionKey: 'student',
        },
        {
          id: 'biblioteca-prestamos',
          title: 'Préstamos',
          menuId: 'students.menu.library.loans',
          type: 'item',
          url: '/biblioteca-prestamos',
          permissionKey: 'student',
        },
      ],
    },
    // {
    //   id: 'seguimiento-graduados',
    //   title: 'Seguimiento Graduados',
    //   type: 'item',
    //   target: true,
    //   external: 'https://sisacademico.ecotec.edu.ec/index.php#&panel1-3',
    //   url: 'https://sisacademico.ecotec.edu.ec/index.php#&panel1-3',
    //   icon: icons['IconPackage'],
    //   permissionKey: 'student',
    // },
    // {
    //   id: 'biblioteca',
    //   title: 'Biblioteca',
    //   menuId: 'students.menu.library',
    //   type: 'item',
    //   // url: 'https://biblio.ecotec.edu.ec/apps',
    //   url: 'https://biblio2.ecotec.edu.ec/',
    //   external: 'https://biblio2.ecotec.edu.ec/',
    //   target: true,
    //   icon: icons['IconNotebook'],
    //   permissionKey: 'student',
    // },
    // {
    //   id: 'ecobuses',
    //   title: 'Ecobuses',
    //   type: 'item',
    //   url: 'https://atrium.ecotec.edu.ec/docente/ecobuses',
    //   external: 'https://atrium.ecotec.edu.ec/docente/ecobuses',
    //   target: true,
    //   icon: icons['IconCar'],
    //   permissionKey: 'student',
    // },
    // shared menu
    {
      id: 'informacion-institucional',
      title: 'Información institucional',
      type: 'item',
      url: '/informacion-institucional',
      icon: icons['IconNotes'],
      permissionKey: 'shared',
    },
    // ...shared,  // comentado porque no quierer reportes en estudiantes, hasta que habiliten los permisos
  ],
};
