import { Grid, Typography } from '@material-ui/core';
import MainCard from '../../../shared/components/cards/MainCard';
import ListStudentsNivelation from '../components/ListStudentsNivelation';

const RegisterNivelationScreen = () => {
  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">Listado de Estudiantes de Nivelación</Typography>
      </Grid>
      <ListStudentsNivelation />
    </Grid>
  );
};

export default RegisterNivelationScreen;
