import { IconButton, IconButtonProps, Tooltip, Badge } from '@material-ui/core';
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone';
import type { TablerIcon } from '@tabler/icons';

interface Props extends IconButtonProps {
  handleClick?: () => void;
  title?: string;
  // color?: 'inherit' | 'secondary' | 'default' | 'primary' | 'info' | undefined;

  Icon?: TablerIcon;
  countBadge?: number;
}

const CustomIconButton = ({
  handleClick,
  title,
  color,
  Icon,
  countBadge,
  ...iconBtnProps
}: Props) => {
  return (
    <Tooltip title={title ? title : 'Editar'}>
      <IconButton
        aria-label={title ? title : 'Editar'}
        color={color ? color : 'secondary'}
        onClick={handleClick}
        {...iconBtnProps}
      >
        <Badge badgeContent={countBadge} color="primary">
          {Icon ? <Icon size={20} /> : <EditTwoToneIcon sx={{ fontSize: '1.3rem' }} />}
        </Badge>
      </IconButton>
    </Tooltip>
  );
};

export default CustomIconButton;
