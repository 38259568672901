import { Autocomplete, Button, Grid, InputLabel, TextField } from '@material-ui/core';
import { useAppSelector, useAppDispatch } from '../../../../../hooks/useRedux';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { setBodyForm } from '../../../books/slices/booksSlice';

interface props {
  isActive: boolean;
  handleNext: () => void;
  handleBack: () => void;
}
const Step3 = ({ handleNext, handleBack, isActive }: props) => {
  const dispatch = useAppDispatch();
  const reasons = [
    { value: 'Devolucion de usuario', label: 'Devolución de usuario' },
    { value: 'Prestamo a usuario', label: 'Prestamo a usuario' },
    { value: 'Canje', label: 'Canje' },
    { value: 'Compra', label: 'Compra' },
    { value: 'Donacion', label: 'Donación' },
    { value: 'Daño', label: 'Daño' },
    { value: 'Migracion', label: 'Migración' },
  ];

  const { bookToEdit } = useAppSelector((state) => state.books);

  const schema = yup.object().shape({
    isEdit: yup.boolean(),
    reason: yup.string().required('Tipo de Procedencia es requerido'),
    procedency: yup.string(),
    initial_copies: yup
      .number()
      .moreThan(0, 'La cantiadad debe ser mayor a 0')
      .required('Cantidad es requerida'),
  });

  const { handleSubmit, setFieldValue, values, errors, touched } = useFormik({
    initialValues: {
      isEdit: bookToEdit ? true : false,
      reason: '',
      procedency: '',
      initial_copies: 0,
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      await dispatch(setBodyForm(values));
      await handleNext();
    },
  });

  const handleFormSubmit = () => {
    handleSubmit();
  };

  if (!isActive) {
    return <div />;
  }

  return (
    <>
      <Grid item xs={12} md={6}>
        <InputLabel>Tipo de Procedencia</InputLabel>
        <Autocomplete
          options={reasons}
          getOptionLabel={(option) => option.label}
          value={reasons.find((rs) => rs.value === values.reason)}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Seleccione Tipo de Procedencia..."
              error={Boolean(errors.reason && touched.reason)}
              helperText={Boolean(errors.reason && touched.reason) ? errors.reason : ''}
            />
          )}
          //defaultValue={}
          onChange={(e, value) => {
            setFieldValue('reason', value?.value);
          }}
          fullWidth
          clearIcon={null}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <InputLabel>Procedencia</InputLabel>
        <TextField
          fullWidth
          placeholder="Ingrese Procedencia..."
          value={values.procedency}
          onChange={({ target }) => setFieldValue('procedency', target.value)}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <InputLabel>Cantidad</InputLabel>
        <TextField
          type="number"
          fullWidth
          placeholder="Ingrese Cantidad"
          value={values.initial_copies}
          error={Boolean(errors.initial_copies && touched.initial_copies)}
          helperText={
            Boolean(errors.initial_copies && touched.initial_copies) ? errors.initial_copies : ''
          }
          onChange={({ target }) => setFieldValue('initial_copies', target.value)}
        />
      </Grid>
      <Grid item container xs={12} md={12} justifyContent="flex-end">
        <Button variant="outlined" onClick={handleBack} size="large">
          Anterior
        </Button>
        &nbsp;&nbsp;
        <Button variant="contained" onClick={handleFormSubmit} size="large">
          Siguiente
        </Button>
      </Grid>
    </>
  );
};

export default Step3;
