import { Button, CircularProgress, FormHelperText, Grid, InputLabel } from '@material-ui/core';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SuccessAlert from '../../../../shared/components/alerts/SuccessAlert';
import CustomAsyncPaginatedSelect from '../../../../shared/components/extended/Form/CustomAsyncPaginatedSelect';
import { useLazyGetPeriodWithHourhandsQuery } from '../../slices/periodsApiSlice';
import { sethourhandsSelected } from '../../slices/periodsSlice';
import { useAppDispatch } from '../../../../../hooks/useRedux';

interface Props {
  handleClose: () => void;
  orderImports: () => void;
}

const ImportHourhandsForm = ({ handleClose, orderImports }: Props) => {
  const { t } = useTranslation();

  const [showAlerts, setShowAlerts] = useState(false);

  const [hourhandsValue, setHourhandsValue] = useState<any | null>(null);

  const [getPeriodWithHourHandTrigger] = useLazyGetPeriodWithHourhandsQuery();

  const dispatch = useAppDispatch();

  const getHourhands = async (search: string, page: number, perPage: number) => {
    const response = await getPeriodWithHourHandTrigger({
      page,
      perPage,
      search,
    }).unwrap();

    const hasMore = response.current_page < response.last_page;

    return {
      options: response.data.map((dt) => {
        return {
          value: dt.hourhands,
          label: dt.per_name,
          ...dt,
        };
      }),
      hasMore,
    };
  };

  const { errors, setFieldValue, handleSubmit, touched } = useFormik({
    initialValues: {
      hourhands: [],
    },
    onSubmit: async (currentValues) => {
      await dispatch(sethourhandsSelected(currentValues.hourhands));

      setTimeout(async () => {
        await handleClose();
        await orderImports();
      }, 500);

      setShowAlerts(true);
    },
  });

  useEffect(() => {
    if (hourhandsValue) {
      const hourHandsIds = hourhandsValue.value.map((dt: any) => dt.id);
      setFieldValue('hourhands', hourHandsIds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hourhandsValue]);

  return (
    <Grid container spacing={2}>
      {showAlerts && (
        <Grid item xs={12}>
          <SuccessAlert
            message={t('Importado correctamente')}
            handleDismiss={() => setShowAlerts(false)}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputLabel>Periodos</InputLabel>
              <CustomAsyncPaginatedSelect
                key={'id'}
                value={hourhandsValue}
                isError={false}
                placeholder={'Escoger periodo'}
                setValue={setHourhandsValue}
                fetchFunc={getHourhands}
              />
              {errors.hourhands && touched.hourhands && (
                <FormHelperText error>{t(errors.hourhands)}</FormHelperText>
              )}
            </Grid>

            <Grid
              item
              container
              xs={12}
              justifyContent="flex-end"
              spacing={2}
              sx={{
                mt: 2,
              }}
            >
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  disabled={false}
                  endIcon={false && <CircularProgress size={12} />}
                  onClick={handleClose}
                >
                  {t('Cancelar')}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  disabled={false}
                  endIcon={false && <CircularProgress size={12} />}
                >
                  {t('Importar')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default ImportHourhandsForm;
