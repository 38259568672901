import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.bubble.css'; // Add css for bubble theme
import { useEffect } from 'react';
import { DeltaStatic } from 'quill';
import { Box, makeStyles } from '@material-ui/core';

interface Props {
  text: DeltaStatic;
}

const useStyles = makeStyles((theme) => ({
  editor: {
    '& > div.ql-editor': {
      paddingLeft: '0',
      paddingRight: '0',
    },
  },
}));

const EditorReadText = ({ text }: Props) => {
  const classes = useStyles();
  const { quill, quillRef } = useQuill({
    theme: 'bubble',
    readOnly: true,
    modules: { toolbar: false },
  });

  useEffect(() => {
    quill?.setContents(text);
  }, [quill, text]);

  return <Box className={classes.editor} ref={quillRef}></Box>;
};

export default EditorReadText;
