import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISearchTable } from '../../../shared/interfaces/paginatedTable.interface';
import { Hourhand } from '../interfaces/hourhands.interfaces';

interface HourhandsState {
  hourhandList: Hourhand[];
  deleteModalOpen: boolean;
  hourhandIdToDelete?: number;
  hourhandsTable: ISearchTable;
}

const initialState: HourhandsState = {
  hourhandList: [],
  deleteModalOpen: false,
  hourhandsTable: {
    page: 1,
    perPage: 5,
    search: '',
  },
};

const hourhandsSlice = createSlice({
  name: 'hourhands',
  initialState,
  reducers: {
    setHourhandList: (state, action: PayloadAction<Hourhand[]>) => {
      state.hourhandList = action.payload;
    },
    setDeleteModalOpen: (state, action: PayloadAction<boolean>) => {
      state.deleteModalOpen = action.payload;
    },
    openDeleteModalWith: (state, action: PayloadAction<number>) => {
      state.hourhandIdToDelete = action.payload;
      state.deleteModalOpen = true;
    },
    resetHourhandIdToDelete: (state) => {
      state.hourhandIdToDelete = undefined;
    },
    updateHourhandsTable: (state, action: PayloadAction<ISearchTable>) => {
      state.hourhandsTable = action.payload;
    },
  },
});

export const {
  setHourhandList,
  setDeleteModalOpen,
  openDeleteModalWith,
  resetHourhandIdToDelete,
  updateHourhandsTable,
} = hourhandsSlice.actions;

export default hourhandsSlice.reducer;
