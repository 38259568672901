import { Button, Grid, CircularProgress } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { RegisterSyllabusData } from '../../interfaces/registerSyllabusData';

interface Props {
  addRegisterSyllabus: any;
  setShowAlerts: (value: React.SetStateAction<boolean>) => void;
  isLoadingAddRegisterSyllabus: boolean;
  isEducationLevel?: boolean;
}

const ButtonSaveADraft = ({
  addRegisterSyllabus,
  setShowAlerts,
  isLoadingAddRegisterSyllabus,
  isEducationLevel,
}: Props) => {
  const { values } = useFormikContext<RegisterSyllabusData>();

  const handleAddRegisterSyllabus = async () => {
    try {
      const { temp, ...formValues } = values;
      await addRegisterSyllabus({
        payload: {
          ...formValues,
          syllabus: {
            ...formValues.syllabus,
            date_send: null,
          },
        },
      }).unwrap();
      setShowAlerts(true);
    } catch (error) {
      setShowAlerts(true);
      console.log(error);
    }
  };

  return (
    <Grid item xs={6}>
      <Button
        fullWidth
        variant="outlined"
        size="large"
        color="primary"
        onClick={handleAddRegisterSyllabus}
        disabled={isLoadingAddRegisterSyllabus || isEducationLevel}
        endIcon={isLoadingAddRegisterSyllabus && <CircularProgress size={20} />}
      >
        Guardar borrador
      </Button>
    </Grid>
  );
};

export default ButtonSaveADraft;
