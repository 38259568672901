import { NavItemType } from '../../shared/interfaces/material-ui.interfaces';

import { configuration } from './superadmin/configuration';
import { hr } from './superadmin/hr';
import { financial } from './superadmin/financial';
import { academic } from './superadmin/academic';
import { virtualClassroomStudents } from './virtual-classroom/students';
import { virtualClassroomTeacher } from './virtual-classroom/teachers';
import { crm_socialtray } from './superadmin/crm-socialtray';
import { communityOutreach } from './superadmin/communityOutreach';
import { research } from './superadmin/research';

import { library } from './superadmin/library';

// Independientes
import { students } from './students';
import { teachers } from './teachers';
import { dashboard } from './dashboard';
import { evaluations } from './superadmin/evaluations';

// Aula virtual

const menuItems: { items: NavItemType[] } = {
  items: [
    dashboard,
    configuration,
    financial,
    evaluations,
    hr,
    academic,
    virtualClassroomTeacher,
    virtualClassroomStudents,
    students,
    teachers,
    crm_socialtray,
    communityOutreach,
    library,
    research,
  ],
};

export default menuItems;
