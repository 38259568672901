import {
  Button,
  Grid,
  //Stack,
  TextField,
  Typography,
} from '@material-ui/core';
import { DatePicker, LocalizationProvider } from '@material-ui/lab';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import { IconSearch } from '@tabler/icons';
import { es } from 'date-fns/locale';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../../../hooks/useRedux';
import InputLabel from '../../../shared/components/forms/InputLabel';
import { useLazyGetRolePaymentQuery } from '../../slices/rrhhApiSlice';
import imge from '../../../../assets/images/illustrations/telework.svg';
import SearchPaginatedTable from '../../../shared/components/tables/SearchPaginatedTable';
import NoCollaboratorInfo from '../../messages/NoCollaboratorInfo';

const months = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
];

const RolesList = () => {
  const { userInfo } = useAppSelector((state) => state.auth);
  const { currentProfile } = useAppSelector((state) => state.access);
  //const [monthYear, setMonthYear] = useState<string | null>(null);
  const [monthYear, setMonthYear] = useState<string | null>(new Date().toDateString());

  const [getRole, { data, isLoading, isFetching, error, isUninitialized }] =
    useLazyGetRolePaymentQuery();

  const handleGetRoles = () => {
    if (monthYear) {
      getRole({
        collaborator_id: userInfo.collaboratorInfo?.id!,
        user_profile_id: currentProfile?.profileId!,
        year: String(new Date(monthYear).getFullYear()),
        month: months[new Date(monthYear).getMonth()],
      });
    }
  };

  useEffect(() => {
    handleGetRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!userInfo.collaboratorInfo) {
    return <NoCollaboratorInfo />;
  }

  return (
    <Grid container>
      <Grid container justifyContent="flex-end" spacing={2}>
        <Grid item xs={12} md={6}>
          <InputLabel>Mes y Año</InputLabel>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
            <DatePicker
              views={['month', 'year']}
              onChange={(val) => {
                setMonthYear(val);
              }}
              value={monthYear}
              renderInput={(props) => (
                <TextField
                  {...props}
                  label=""
                  placeholder=""
                  helperText={null}
                  error={false}
                  fullWidth
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} md={12} lg={12} textAlign="right">
          <Button
            endIcon={<IconSearch />}
            variant="contained"
            onClick={handleGetRoles}
            disabled={Boolean(!monthYear)}
          >
            Buscar
          </Button>
        </Grid>
      </Grid>
      <Grid container justifyContent="center">
        {!monthYear || isUninitialized ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img src={imge} style={{ width: 300 }} alt="select" />
            <Typography variant="h4">
              &nbsp;Seleccione mes y año para visualizar sus roles de pagos
            </Typography>
          </div>
        ) : (
          <>
            <SearchPaginatedTable
              // data={
              //   data?.slice(0, -1).map((el, index) => ({
              //     num: index + 1,
              //     desc: el.descrip,
              //     enter: el.ingreso ? `$ ${String(el.ingreso) !== '' ? el.ingreso : '-'}` : '-',
              //     leave: el.egreso ? `$ ${String(el.egreso) !== '' ? el.egreso : '-'}` : '-',
              //   })) || []
              // }
              data={
                data?.map((el, index) => ({
                  num: index + 1,
                  desc: el.descrip,
                  enter: el.ingreso
                    ? `$ ${String(el.ingreso) !== '' ? Number(el.ingreso).toFixed(2) : '-'}`
                    : '-',
                  leave: el.egreso
                    ? `$ ${String(el.egreso) !== '' ? Number(el.egreso).toFixed(2) : '-'}`
                    : '-',
                })) || []
              }
              headers={{
                num: 'Nº',
                desc: 'Descripción',
                enter: 'Ingreso',
                leave: 'Egreso',
              }}
              error={error}
              isFetching={isFetching}
              isLoading={isLoading}
              keyExtractor={(row) => String(row)}
              numHeader={3}
              page={1}
              perPage={data?.length || 10}
              search=""
              searchPlacehoder=""
              setPage={undefined}
              setPerPage={undefined}
              setSearch={undefined}
              total={data?.length || 10}
              showFilter={false}
              paginationBox={false}
              perPageBox={false}
              customDataCellsProperties={{
                desc: { align: 'center' },
                num: { align: 'center' },
                enter: { align: 'center' },
                leave: { align: 'center' },
              }}
            />
            {!isLoading && !isFetching && data && data.length > 1 && (
              <div style={{ flex: 1 }}>
                {/* <Stack
                  direction="row"
                  justifyContent="space-between"
                  flex={1}
                  height={60}
                  border={1}
                  alignItems="center"
                  borderRadius="1rem"
                  borderColor="dodgerblue"
                >
                  <div style={{ flex: 0.8, textAlign: 'center' }}>NETO A RECIBIR</div>
                  <div style={{ textAlign: 'center', flex: 0.2 }}>
                    ${data[data.length - 1].egreso}
                  </div>
                </Stack> */}
                <p>
                  <small>**Valores que aplican IESS adicional</small>
                </p>
              </div>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default RolesList;
