import { useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom';

import {
  Autocomplete,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  TextField,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Typography,
  Switch,
} from '@material-ui/core';

// import { useFormik, FieldArray, Field, FormikProvider, ErrorMessage } from 'formik';

import { useFormik } from 'formik';

import esLocale from 'date-fns/locale/es';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import MainCard from '../../../../shared/components/cards/MainCard';

import { forumSchema } from './../validation-schemas';
import InputLabel from '../../../../shared/components/forms/InputLabel';
import { useHistory } from 'react-router-dom';

import DateRangePicker, { DateRange } from '@material-ui/lab/DateRangePicker';
import { LocalizationProvider } from '@material-ui/lab';

import { format } from 'date-fns';
// import ReactQuill from 'react-quill';

import 'react-quill/dist/quill.snow.css';
import ErrorAlert from '../../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../../shared/components/alerts/SuccessAlert';
import { useAppSelector } from '../../../../../hooks/useRedux';
import {
  useAddForumMutation,
  useGetDistributionsByCourseQuery,
  useGetStudentsAvailablesQuery,
} from '../../slices/virtualClassroomTeacherApiSlice';
import Transitions from '../../../../shared/components/extended/Transitions';
import { useTranslation } from 'react-i18next';

const AddForumForm = () => {
  const { currentProfile } = useAppSelector((state) => state.access);
  const { userInfo } = useAppSelector((state) => state.auth);

  const {
    course: { courseId },
  } = useAppSelector((state) => state.virtualClassroomTeacherShared);

  const history = useHistory();
  const { t } = useTranslation();

  const [showAlerts, setShowAlerts] = useState<boolean>(true);

  const [isAllStudents, setIsAllStudents] = useState<boolean>(true);

  const [isCreateGroups, setIsCreateGroups] = useState<boolean>(false);

  const [numberGroups, setNumberGroups] = useState<number>(1);

  const handleChangeAllStudents = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsAllStudents(event.target.checked);
  };

  const handleIsScore = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue('actIsScore', event.target.checked);
  };

  const handleIsExtemp = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue('cactIsExtemporaneous', event.target.checked);
  };
  const handleChangeIsCreateGroup = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsCreateGroups(event.target.checked);
  };
  const handleChangeNumberGroups = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNumberGroups(Number(event.target.value));
  };

  const [addForum, { isLoading, isError, error, isSuccess }] = useAddForumMutation();

  const { data: distributionAvailables, isLoading: isLoadingDistribution } =
    useGetDistributionsByCourseQuery({
      profileId: currentProfile?.profileId!,
      courseId: courseId!.toString(),
    });

  const { data: studentsAvailables, isLoading: isLoadingStudents } = useGetStudentsAvailablesQuery({
    profileId: currentProfile?.profileId!,
    course: courseId!,
  });

  const [forumDate, setForumDate] = useState<DateRange<Date>>([null, null]);

  const onChangesForumDate = (newDatesValues: DateRange<Date>) => {
    const [start, end] = newDatesValues;
    //Update formik values
    setFieldValue('cactStartDate', start);
    setFieldValue('cactEndDate', end);

    setForumDate(newDatesValues);
  };

  console.log(distributionAvailables);

  const validateDates = (cactStartDate: string, cactEndDate: string) => {
    const errors: any = {};

    if (new Date(cactStartDate)! >= new Date(cactEndDate)!) {
      errors.cactStartDate = t('teacher_virtual_classroom.AddForum.lower_date');
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      actDescription: '',
      // typeActivityId: 0,
      // catalogoId: 0,
      dtaQuestion: '',
      cactStartDate: '',
      cactEndDate: '',
      studentId: [],
      statusId: 1,
      actIsScore: false,
      actScoreActivity: 1,
      cactIsExtemporaneous: false,
      distributionId: '',
      supplyId: '',
      visible: true,
    },
    validationSchema: forumSchema,
    validate: ({ cactStartDate, cactEndDate }) => {
      return validateDates(cactStartDate, cactEndDate);
    },
    onSubmit: async (values) => {
      try {
        await addForum({
          profileId: currentProfile?.profileId!,
          courseId: courseId!,
          ForumPayload: {
            act_description: values.actDescription,
            // type_activity_id: 604,
            user_id: Number(userInfo?.id!),
            act_is_score: values.actIsScore === true ? 1 : 0,
            course_activity: {
              cact_start_date: format(new Date(values.cactStartDate), 'yyyy-MM-dd'),
              cact_end_date: format(new Date(values.cactEndDate), 'yyyy-MM-dd'),
              cact_is_extemporaneous: values.cactIsExtemporaneous === true ? 1 : 0,
            },
            status_id: values.statusId,
            detail: { type_answer_id: 612, dta_question: values.dtaQuestion },
            // course_student: values.studentId.map((e) => ...e),
            course_student: values.studentId.flat(1),

            ...(values.actIsScore && {
              calification_models_distributions_id: Number(values.distributionId),
              supply_id:
                values.supplyId === ''
                  ? Number(
                      distributionAvailables?.find(
                        (e) => Number(e.distribution_id) === Number(values.distributionId)
                      )?.ref2[0].ref2_supply_id
                    )
                  : Number(values.supplyId),
              act_score_activity: Number(values.actScoreActivity),
            }),
            visible: formik.values.visible ? '1' : '0',
          },
        }).unwrap();

        resetForm();
        history.goBack();
      } catch (error) {
        setShowAlerts(true);
        window.scrollTo(0, 0);
      }
    },
  });

  /*Destructurin formik */
  const { getFieldProps, errors, touched, handleSubmit, setFieldValue, values, resetForm } = formik;

  /// Cuando todos los estudiantes son seleccionados

  useEffect(() => {
    if (isAllStudents && !isLoadingStudents) {
      setFieldValue('studentId', [
        [
          ...(studentsAvailables?.map((student) => ({
            student_id: student.id,
            grupo: 0,
          })) || '-1'),
        ],
      ]);
    } else setFieldValue('studentId', []);
  }, [isAllStudents, setFieldValue, studentsAvailables, isLoadingStudents]);

  // useEffect(() => {
  //   if (values.distributionId) {
  //     setFieldValue('supplyId', '');
  //   }
  // }, [values.distributionId, setFieldValue]);

  const obtainDistributionAndSupply = (): boolean => {
    const dist = distributionAvailables?.find(
      (e) => Number(e.distribution_id) === Number(values.distributionId)
    );

    if (dist?.ref2?.length === 1 && dist?.ref2![0]?.ref2!) {
      //setFieldValue('supplyId', dist.ref2![0]?.ref2_supply_id);
      console.log(values);
      return false;
    }
    return true;
  };
  return (
    <MainCard
      sx={{
        borderTopLeftRadius: '0px',
        borderTopRightRadius: '0px',
      }}
    >
      <Grid container xs={12} justifyContent={'center'}>
        <Grid item textAlign={'center'} container alignItems="center" gap={1} marginY={2}>
          <Typography textAlign={'center'} variant="h3">
            {t('teacher_virtual_classroom.AddForum.title')}
          </Typography>
        </Grid>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={4} justifyContent={'center'} alignItems="flex-start">
            <Grid item container justifyContent={'center'} xs={12} md={8} spacing={2}>
              <Grid item xs={12}>
                {isError && showAlerts && (
                  <ErrorAlert
                    message={error as string}
                    handleDismiss={() => setShowAlerts(false)}
                  />
                )}

                {isSuccess && showAlerts && (
                  <SuccessAlert
                    message={t('teacher_virtual_classroom.AddForum.success_message')}
                    handleDismiss={() => setShowAlerts(false)}
                  />
                )}
              </Grid>
              {/* <Grid item xs={12}>
            <InputLabel>Tema</InputLabel>
            <ReactQuill
              theme="snow"
              placeholder="Ingrese un tema..."
              onChange={(e) => setFieldValue('actDescription', e)}
              value={values.actDescription}
            />
            {errors.actDescription && touched.actDescription && (
              <FormHelperText error>{errors.actDescription}</FormHelperText>
            )}
          </Grid> */}

              <Grid item xs={12}>
                <InputLabel>{t('teacher_virtual_classroom.AddForum.subject_label')}</InputLabel>
                <TextField
                  multiline
                  minRows={2}
                  fullWidth
                  error={Boolean(errors.actDescription && touched.actDescription)}
                  placeholder={t('teacher_virtual_classroom.AddForum.subject_placeholder')}
                  {...getFieldProps('actDescription')}
                />
                <FormHelperText>
                  {t('teacher_virtual_classroom.AddForum.subject_helper')}
                </FormHelperText>
                {errors.actDescription && touched.actDescription && (
                  <FormHelperText error>{errors.actDescription}</FormHelperText>
                )}
              </Grid>

              <Grid item xs={12}>
                <InputLabel>{t('teacher_virtual_classroom.AddForum.detail_label')}</InputLabel>
                {/* <ReactQuill
                theme="snow"
                placeholder="Ingrese detalles..."
                onChange={(e) => setFieldValue('dtaQuestion', e)}
                value={values.dtaQuestion}
              /> */}
                <TextField
                  multiline
                  minRows={2}
                  fullWidth
                  error={Boolean(errors.dtaQuestion && touched.dtaQuestion)}
                  placeholder={t('teacher_virtual_classroom.AddForum.detail_placeholder')}
                  {...getFieldProps('dtaQuestion')}
                />
                {errors.dtaQuestion && touched.dtaQuestion && (
                  <FormHelperText error>{errors.dtaQuestion}</FormHelperText>
                )}
              </Grid>

              {/* <Grid item xs={12}>
            <InputLabel>Pregunta</InputLabel>
            <TextField
              multiline
              minRows={2}
              fullWidth
              error={Boolean(errors.dtaQuestion && touched.dtaQuestion)}
              placeholder="Ingrese la pregunta del foro"
              {...getFieldProps('dtaQuestion')}
            />
          </Grid> */}

              <Grid item xs={12} marginTop={2}>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                  <DateRangePicker
                    startText={null}
                    clearable
                    endText={null}
                    calendars={2}
                    clearText
                    disableAutoMonthSwitching
                    value={forumDate}
                    onChange={onChangesForumDate}
                    renderInput={(startProps, endProps) => (
                      <Grid container spacing={2} alignItems="center">
                        <Grid item container md={6}>
                          <Grid
                            item
                            xs={12}
                            sm={3}
                            lg={12}
                            sx={{ pt: { xs: 2, sm: '0 !important' } }}
                          >
                            <InputLabel>
                              {t('teacher_virtual_classroom.AddForum.start_date_label')}:
                            </InputLabel>
                          </Grid>
                          <Grid item xs={12} sm={9} lg={12}>
                            <TextField
                              fullWidth
                              {...startProps}
                              helperText=""
                              error={Boolean(errors.cactStartDate && touched.cactStartDate)}
                              {...getFieldProps('cactStartDate')}
                            />
                            <FormHelperText>
                              {t('teacher_virtual_classroom.AddForum.start_date_helper')}
                            </FormHelperText>

                            {errors.cactStartDate && touched.cactStartDate && (
                              <FormHelperText error>{errors.cactStartDate}</FormHelperText>
                            )}
                          </Grid>
                        </Grid>

                        <Grid md={6} item container>
                          <Grid
                            item
                            xs={12}
                            sm={3}
                            lg={12}
                            sx={{ pt: { xs: 2, sm: '0 !important' } }}
                          >
                            <InputLabel>
                              {t('teacher_virtual_classroom.AddForum.end_date_label')}:
                            </InputLabel>
                          </Grid>
                          <Grid item xs={12} sm={9} lg={12}>
                            <TextField
                              fullWidth
                              {...endProps}
                              helperText=""
                              error={Boolean(errors.cactEndDate && touched.cactEndDate)}
                              {...getFieldProps('cactEndDate')}
                            />
                            <FormHelperText>
                              {t('teacher_virtual_classroom.AddForum.end_date_helper')}
                            </FormHelperText>
                            {errors.cactEndDate && touched.cactEndDate && (
                              <FormHelperText error>{errors.cactEndDate}</FormHelperText>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox checked={values.cactIsExtemporaneous} onChange={handleIsExtemp} />
                    }
                    label={t('teacher_virtual_classroom.AddForum.students_can_comments')}
                  />
                </FormGroup>
                <FormHelperText error>{errors.cactIsExtemporaneous}</FormHelperText>
              </Grid>

              <Grid item xs={12}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox checked={isAllStudents} onChange={handleChangeAllStudents} />
                    }
                    label={t('teacher_virtual_classroom.AddForum.all_students_can_watch_forum')}
                  />
                </FormGroup>
                <Transitions type="collapse" in={!isAllStudents}>
                  {!isCreateGroups && (
                    <>
                      <InputLabel>
                        {t(
                          'teacher_virtual_classroom.AddForum.students_who_can_view_this_forum_label'
                        )}
                      </InputLabel>
                      <Autocomplete
                        multiple
                        options={studentsAvailables || []}
                        loading={isLoadingStudents}
                        loadingText={t(
                          'teacher_virtual_classroom.AddForum.students_who_can_view_this_forum_loading'
                        )}
                        getOptionLabel={(option) => option.pers_full_name}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={Boolean(errors.studentId && touched.studentId)}
                            placeholder={t(
                              'teacher_virtual_classroom.AddForum.students_who_can_view_this_forum_placeholder'
                            )}
                          />
                        )}
                        onChange={(_, values) =>
                          setFieldValue(
                            'studentId',
                            [
                              [
                                ...values.map((student) => ({
                                  student_id: student.id,
                                  grupo: '',
                                })),
                              ],
                            ] || ''
                          )
                        }
                        fullWidth
                      />
                      {errors.studentId && touched.studentId && (
                        <FormHelperText error>{errors.studentId}</FormHelperText>
                      )}
                    </>
                  )}
                  {false && (
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch onChange={handleChangeIsCreateGroup} value={isCreateGroups} />
                        }
                        label={t('teacher_virtual_classroom.AddForum.create_groups')}
                      />
                    </FormGroup>
                  )}
                  {isCreateGroups && (
                    <Grid item xs={12} md={6} lg={3}>
                      <InputLabel>
                        {t('teacher_virtual_classroom.AddForum.groups_label')}
                      </InputLabel>
                      <TextField
                        type="number"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ inputProps: { min: 1, max: 20 } }}
                        onChange={handleChangeNumberGroups}
                        value={numberGroups}
                        fullWidth
                        error={Boolean(errors.studentId && touched.studentId)}
                        placeholder={t('teacher_virtual_classroom.AddForum.groups_placeholder')}
                      />
                    </Grid>
                  )}
                  {isCreateGroups &&
                    Array.from({ length: numberGroups }, (v, i) => (
                      <Grid item mt={1}>
                        <InputLabel>
                          {t('teacher_virtual_classroom.AddForum.group_number') + i + 1}
                        </InputLabel>
                        <Autocomplete
                          multiple
                          options={studentsAvailables || []}
                          loading={isLoadingStudents}
                          loadingText={t(
                            'teacher_virtual_classroom.AddForum.available_students_loading'
                          )}
                          getOptionLabel={(option) => option.pers_full_name}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={Boolean(errors.studentId && touched.studentId)}
                              placeholder={t(
                                'teacher_virtual_classroom.AddForum.available_students_placeholder'
                              )}
                            />
                          )}
                          onChange={(_, values) =>
                            setFieldValue(
                              `studentId[${i}]`,
                              [
                                ...values.map((v, index) => ({
                                  student_id: v.id,
                                  grupo: i + 1,
                                })),
                              ] || []
                            )
                          }
                          fullWidth
                        />
                        {errors.studentId && touched.studentId && (
                          <FormHelperText error>
                            {errors.studentId.toString().split(',')[i]}
                            {/* {errors.studentId} */}
                          </FormHelperText>
                        )}
                      </Grid>
                    ))}
                </Transitions>
              </Grid>

              <Grid item xs={12}>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox checked={values.actIsScore} onChange={handleIsScore} />}
                    label={t('teacher_virtual_classroom.AddForum.forum_evaluated_label')}
                  />
                </FormGroup>
                <Transitions type="collapse" in={values.actIsScore}>
                  <Grid item xs={4} marginTop={2}>
                    <InputLabel>
                      {t('teacher_virtual_classroom.AddForum.distribution_label')}
                    </InputLabel>
                    <Autocomplete
                      options={distributionAvailables || []}
                      getOptionLabel={(option) => option.distribution}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={Boolean(errors.distributionId && touched.distributionId)}
                        />
                      )}
                      loading={isLoadingDistribution}
                      onChange={(e, value) =>
                        setFieldValue('distributionId', value?.distribution_id || '')
                      }
                      fullWidth
                      clearIcon={null}
                    />
                    <FormHelperText>
                      {t('teacher_virtual_classroom.AddForum.distribution_helper')}
                    </FormHelperText>
                    {errors.distributionId && touched.distributionId && (
                      <FormHelperText error>{errors.distributionId}</FormHelperText>
                    )}
                  </Grid>

                  {obtainDistributionAndSupply() && values.supplyId !== '' && (
                    <Grid item xs={4} marginTop={2}>
                      <InputLabel>
                        {t('teacher_virtual_classroom.AddForum.supplies_label')}
                      </InputLabel>
                      <Autocomplete
                        options={
                          distributionAvailables?.find(
                            (e) => e.distribution_id === values.distributionId
                          )?.ref2 || []
                        }
                        getOptionLabel={(option) => option.ref2}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={Boolean(errors.supplyId && touched.supplyId)}
                          />
                        )}
                        loading={isLoadingDistribution}
                        onChange={(e, value) =>
                          setFieldValue('supplyId', value?.ref2_supply_id || '')
                        }
                        fullWidth
                        clearIcon={null}
                      />
                      <FormHelperText>
                        {t('teacher_virtual_classroom.AddForum.supplies_helper')}
                      </FormHelperText>
                      {errors.supplyId && touched.supplyId && (
                        <FormHelperText error>{errors.supplyId}</FormHelperText>
                      )}
                    </Grid>
                  )}

                  <Grid item xs={12} md={4}>
                    <InputLabel>
                      {t('teacher_virtual_classroom.AddForum.grade_forum_label')}
                    </InputLabel>
                    <TextField
                      type="number"
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        inputProps: {
                          min: 1,
                        },
                      }}
                      fullWidth
                      error={Boolean(errors.actScoreActivity && touched.actScoreActivity)}
                      placeholder={t('teacher_virtual_classroom.AddForum.grade_forum_placeholder')}
                      {...getFieldProps('actScoreActivity')}
                    />
                    <FormHelperText></FormHelperText>
                    {errors.actScoreActivity && touched.actScoreActivity && (
                      <FormHelperText error>{errors.actScoreActivity}</FormHelperText>
                    )}
                  </Grid>
                </Transitions>
                <FormControlLabel
                  label={t('teacherVirtualClassroom.tasks.button.visible')}
                  control={
                    <Checkbox
                      checked={values.visible}
                      onChange={(value) => setFieldValue('visible', value.target.checked)}
                    />
                  }
                />
              </Grid>

              {/* test */}

              <Grid
                container
                display="flex"
                alignItems="center"
                justifyContent="center"
                gap={2}
                sx={{ mt: 1 }}
              >
                <Button
                  variant="outlined"
                  size="small"
                  style={{ fontSize: '15px' }}
                  color="primary"
                  // onClick={() => history.goBack()}
                  onClick={() => history.goBack()}
                >
                  {t('teacher_virtual_classroom.AddForum.return')}
                </Button>

                <Button
                  type="submit"
                  style={{ fontSize: '15px' }}
                  variant="contained"
                  size="small"
                  color="primary"
                  disabled={isLoading}
                  endIcon={isLoading && <CircularProgress size={20} />}
                >
                  {t('teacher_virtual_classroom.AddForum.add')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </MainCard>
  );
};

export default AddForumForm;
