import {
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  Button,
  Typography,
  useTheme,
  InputAdornment,
  TextField,
} from '@material-ui/core';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import { setFaqs } from '../../slices/faqSlice';
import { useStyles } from '../../constants/styles';
import { IconChevronRight } from '@tabler/icons';
import { useHistory } from 'react-router-dom';
import { useGetTagsFaqsAvailablesQuery, useFilterFaqsQuery } from '../../slices/faqApiSlice';
import { DebounceInput } from 'react-debounce-input';
import SearchIcon from '@material-ui/icons/Search';
import { useState } from 'react';
import { setSidebarOpened } from '../../../../shared/reducers/customizationReducer';

export const FaqsSearch = () => {
  const theme = useTheme();

  const styles = useStyles();

  const history = useHistory();

  const dispatch = useAppDispatch();

  const { currentProfile } = useAppSelector((state) => state.access);

  const { faqs } = useAppSelector((state) => state.faqs);

  const [search, setSearch] = useState<string>('');
  const [searchAll, setSearchAll] = useState<boolean>(false);

  const handleCloseDialog = () => {
    dispatch(setFaqs(false));
  };

  const handleGoToHelpCenter = async () => {
    history.push('/centro-asistencia');

    await dispatch(setFaqs(false));

    await dispatch(setSidebarOpened(false));
  };

  const { data: tags, isLoading: isLoadingTags } = useGetTagsFaqsAvailablesQuery({
    profileId: currentProfile?.profileId!,
    faqKeyword: window.location.pathname.replace(/([0-9]+)/g, 'id'),
  });

  const {
    data: faqsData,
    isLoading: isLoadingFaqs,
    isFetching: isFetchingFaqs,
  } = useFilterFaqsQuery(
    {
      search: search,
      profileId: currentProfile?.profileId!,
      ...(!searchAll && { urlRelative: window.location.pathname.replace(/([0-9]+)/g, 'id') }),
    },
    { skip: search === '', refetchOnMountOrArgChange: true }
  );

  const handleHelpCenter = async (faqId: number) => {
    history.push(`/centro-asistencia/detalle/${faqId}`);

    await dispatch(setFaqs(false));
  };

  return (
    <Dialog
      open={faqs}
      keepMounted
      onClose={() => handleCloseDialog()}
      className={styles.faqsSearchContainer}
      classes={{
        paper: styles.faqsSearchContainerPaper,
      }}
    >
      <DialogContent
        sx={{
          display: 'flex',
          height: '100%',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Grid
          container
          item
          xs={12}
          sx={{
            maxHeight: '600px',
          }}
        >
          <Grid item>
            <Typography variant="h2" color="white">
              ¿Necesitas ayuda?
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ mb: search ? 2 : 6, mt: 1 }}>
            <Grid item>
              <DebounceInput
                autoFocus={true}
                minLength={3}
                debounceTimeout={300}
                onChange={({ target }) => {
                  setSearch(target.value);
                }}
                value={search}
                element={(props) => (
                  <TextField
                    style={{
                      width: '100%',
                      border: '0px solid #065cab',
                      boxShadow: 'none',
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <SearchIcon fontSize="small" />
                        </InputAdornment>
                      ),
                    }}
                    placeholder="Introduce tu tema..."
                    variant="outlined"
                    size="small"
                    {...props}
                  />
                )}
              />
            </Grid>
          </Grid>
          {search !== '' && (
            <Grid item xs={12} sx={{ mb: 4 }} spacing={2}>
              {isLoadingFaqs || isFetchingFaqs ? (
                <div className="lds-ellipsis">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              ) : (
                faqsData?.data?.map((e, index) => (
                  <Grid item key={`result-faq-${index}`}>
                    <Button
                      onClick={() => handleHelpCenter(e.id)}
                      variant="text"
                      size="small"
                      style={{
                        textTransform: 'unset',
                        textDecoration: 'underline',
                        color: 'white',
                      }}
                    >
                      {e?.faq_question}
                    </Button>
                  </Grid>
                ))
              )}
            </Grid>
          )}
        </Grid>
        <Grid container item xs={12}>
          <Grid container item justifyContent="space-between" direction="row">
            <Grid item>
              <Typography variant="h5" color="white">
                Buscar por categorías
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant={searchAll ? 'contained' : 'text'}
                color="secondary"
                onClick={() => setSearchAll((prev) => !prev)}
              >
                Todas
              </Button>
              {/* <Typography variant="caption" color="white"></Typography> */}
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={styles.faqsSearchActions}>
        <Grid container justifyContent="flex-start" alignContent="left">
          <Grid container spacing={1} item xs={12} justifyContent="start">
            {isLoadingTags ? (
              <Grid item>
                <Typography variant="h5" color="primary">
                  Cargando categorías...
                </Typography>
              </Grid>
            ) : (
              tags?.map((e, index) => (
                <Grid item key={`item-tag-${index}`}>
                  <Button
                    variant="text"
                    size="small"
                    style={{
                      textTransform: 'unset',
                      textJustify: 'initial',
                      color: theme.palette.text.primary,
                    }}
                    color="primary"
                  >
                    {e}
                  </Button>
                </Grid>
              ))
            )}
          </Grid>
          <Grid container item xs={12} justifyContent="flex-start" sx={{ mt: 1 }}>
            <Grid item>
              <Button
                endIcon={<IconChevronRight />}
                onClick={handleGoToHelpCenter}
                variant="text"
                size="large"
                style={{ textTransform: 'unset' }}
                color="primary"
              >
                Ir al centro de asistencia
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
