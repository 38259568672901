import { FC } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Grid, Typography, Skeleton, Button, Link } from '@material-ui/core';

import { DetailsProps } from '../../constants/types';
import InputLabel from '../../../../shared/components/forms/InputLabel';
import { ExternalHomologationInterface } from '../../interfaces/generalApplications.interfaces';
import { firstLetterUppercase } from '../../../../shared/helpers';
import { useGetMeshQuery } from '../../../meshs/slices/meshsApiSlice';
import { useAppSelector } from '../../../../../hooks/useRedux';
import { baseUrl } from '../../../../../api/mainApi';

const ExternalHomologation: FC<DetailsProps> = (props) => {
  const { details: detailsProp, spacing, appDescription } = props;
  const details = detailsProp as ExternalHomologationInterface;

  const history = useHistory();
  const location = useLocation();

  const { currentProfile } = useAppSelector((state) => state.access);

  const {
    data: destinedCurriculum,
    // isLoading: isLoadingDM,
    isFetching: isFetchingDM,
  } = useGetMeshQuery({
    profileId: currentProfile?.profileId!,
    meshId: details.curriculum.id || -1,
  });

  return (
    <Grid container item xs={12}>
      <Grid item mb={1}>
        <InputLabel>{firstLetterUppercase(appDescription)}</InputLabel>
      </Grid>
      <Grid container border="1px solid #eee" borderRadius="12px" p={2} mb={1}>
        <Grid container item xs={12} alignItems={'center'} mb={1}>
          <Grid item xs={'auto'}>
            <Typography variant={'body2'} component={'span'}>
              Unidad Académica:
            </Typography>
          </Grid>
          {isFetchingDM ? (
            <Grid item xs={6}>
              <Skeleton
                variant="text"
                component={'span'}
                sx={{ width: '100%', height: 30, ml: 1 }}
              />
            </Grid>
          ) : (
            <Grid item xs={'auto'} lg={6} sx={{ pl: spacing }}>
              <Typography variant={'h5'} component={'span'}>
                {destinedCurriculum?.education_level?.offer?.off_name}
              </Typography>
            </Grid>
          )}
        </Grid>

        <Grid container item xs={12} sm={6} alignItems={'center'} mb={1}>
          <Grid item xs={'auto'}>
            <Typography variant={'body2'} component={'span'}>
              Programa de destino:
            </Typography>
          </Grid>
          {isFetchingDM ? (
            <Grid item xs={6}>
              <Skeleton
                variant="text"
                component={'span'}
                sx={{ width: '100%', height: 30, ml: 1 }}
              />
            </Grid>
          ) : (
            <Grid item xs={'auto'} lg={6} sx={{ pl: spacing }}>
              <Typography variant={'h5'} component={'span'}>
                {destinedCurriculum?.education_level?.edu_name}
              </Typography>
            </Grid>
          )}
        </Grid>

        <Grid container item xs={12} sm={6} alignItems={'center'} mb={1}>
          <Grid item xs={'auto'}>
            <Typography variant={'body2'} component={'span'}>
              Instituto:
            </Typography>
          </Grid>
          {isFetchingDM ? (
            <Grid item xs={6}>
              <Skeleton
                variant="text"
                component={'span'}
                sx={{ width: '100%', height: 30, ml: 1 }}
              />
            </Grid>
          ) : (
            <Grid item xs={'auto'} lg={6} sx={{ pl: spacing }}>
              <Typography variant={'h5'} component={'span'}>
                {`${details?.institute?.inst_name || ''}`}
              </Typography>
            </Grid>
          )}
        </Grid>

        {details?.[2] && (
          <Grid container item xs={12} alignItems={'center'} mb={1}>
            <Grid item xs={'auto'}>
              <Typography variant={'body2'} component={'span'}>
                Récord académico:
              </Typography>
            </Grid>

            <Grid item xs={'auto'} lg={6} sx={{ pl: spacing }}>
              <Typography variant={'h5'} component={'span'}>
                <Link
                  href={`${baseUrl}/show?name=${details?.[2]?.value as string}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Ver
                </Link>
              </Typography>
            </Grid>
          </Grid>
        )}
        {/* {((currentRol?.keyword === 'decano' &&
          currentApplication!.current_status.toLowerCase() === 'enviado') ||
          (currentRol?.keyword === 'coordinador-facultad' &&
            (currentApplication!.current_status.toLowerCase() === 'revisado por decanato' ||
              currentApplication!.current_status.toLowerCase() ===
                'revisado por consejo facultad'))) &&
          !isSuccess && ( */}
      </Grid>
      {/* )} */}

      <Grid container spacing={2}>
        <Grid container justifyContent={'flex-end'} mt={2} spacing={2}>
          <Grid item>
            <Button
              variant="outlined"
              size="large"
              onClick={() => history.push(`/${location.pathname.split('/')[1]}`)}
            >
              Volver
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ExternalHomologation;
