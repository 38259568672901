import {
  Button,
  CircularProgress,
  Dialog,
  DialogProps,
  Grid,
  Typography,
  useTheme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { IconCircleCheck, IconReportMoney, IconReport } from '@tabler/icons';

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  paper: { maxWidth: '600px', width: '90%' },
}));

interface Props extends DialogProps {
  onClose: () => void;
  handleDebit: () => void;
  handleRedirect: () => void;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  error: string;
  debitTitle?: string;
  debitSubTitle?: string;
  debitSuccess?: string;
}

const DebitAlert = ({
  onClose,
  handleDebit,
  handleRedirect,
  isLoading,
  isSuccess,
  isError,
  error,
  debitTitle,
  debitSubTitle,
  debitSuccess,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const title =
    debitTitle !== undefined ? debitTitle : `${t('students.certificates.dialog.debt_question')}`;
  const subtitle =
    debitSubTitle !== undefined
      ? debitSubTitle
      : `${t('students.certificates.dialog.debt_info_and_question')}`;
  const success =
    debitSuccess !== undefined
      ? debitSuccess
      : `${t('students.certificates.dialog.debt_info_confirmation')}`;

  return (
    <Dialog {...props} classes={{ paper: classes.paper }} sx={{ p: 3 }} onClose={onClose}>
      <Grid container gap={4} sx={{ p: 2 }}>
        <Grid item container xs={12} alignItems="center" justifyContent="center">
          {!isError && !isSuccess && !isLoading && (
            <IconReportMoney
              size={60}
              color={theme.palette.error.main}
              style={{
                padding: 12,
                backgroundColor: theme.palette.orange.light,
                borderRadius: '50%',
              }}
            />
          )}
          {isLoading && <CircularProgress size={50} />}
          {isSuccess && (
            <IconCircleCheck
              size={60}
              color={theme.palette.success.main}
              style={{
                padding: 12,
                backgroundColor: theme.palette.success.light,
                borderRadius: '50%',
              }}
            />
          )}
          {isError && (
            <IconReport
              size={60}
              color={theme.palette.error.main}
              style={{
                padding: 12,
                backgroundColor: theme.palette.orange.light,
                borderRadius: '50%',
              }}
            />
          )}
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Typography variant="h2" sx={{ color: theme.palette.primary.main, mb: 1 }}>
            {!isError && !isSuccess && title}
            {isSuccess && success}
            {isError && error}
          </Typography>
          <Typography variant="body2">
            {!isError && !isSuccess && subtitle}
            {isError && error}
          </Typography>
        </Grid>
        <Grid item container xs={12} justifyContent="center" gap={1}>
          {!isSuccess && !isError && (
            <Button
              variant="text"
              size="large"
              color="primary"
              onClick={onClose}
              disabled={isLoading}
            >
              {t('students.certificates.dialog.cancel_generate_debt')}
            </Button>
          )}

          {(isSuccess || isError) && (
            <>
              <Button variant="contained" size="large" color="primary" onClick={handleRedirect}>
                {t('students.certificates.dialog.got_to_debt')}
              </Button>
              <Button variant="contained" size="large" color="primary" onClick={onClose}>
                {t('students.certificates.dialog.continue')}
              </Button>
            </>
          )}

          {!isSuccess && !isError && (
            <Button
              variant="contained"
              size="large"
              onClick={handleDebit}
              color="primary"
              autoFocus
              disabled={isLoading}
            >
              {t('students.certificates.dialog.confirm_generate_debt')}
            </Button>
          )}
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default DebitAlert;
