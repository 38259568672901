import * as yup from 'yup';

export const evaluationFormStep1Schema = yup.object().shape({
  description: yup.string().required('maintenance.evaluations.form.description.required'),
  evaluation_type_id: yup
    .string()
    .typeError('maintenance.evaluations.form.evaluation_type.required')
    .required('maintenance.evaluations.form.evaluation_type.required'),
});

export const evaluationFormStep2Schema = yup.object().shape({
  scopes: yup
    .array()
    .of(
      yup.object().shape({
        uuid: yup.string().required('maintenance.evaluations.form.scope.id.required'),
        questions: yup
          .array()
          .of(
            yup.object().shape({
              uuid: yup.string().required('maintenance.evaluations.form.question.id.required'),
            }),
          )
          .min(1, 'maintenance.evaluations.form.scopes.questions.required'),
        weight: yup
          .number()
          .typeError('La ponderación debe ser un número')
          .test(
            'scopesMaxWeight',
            'La suma de los pesos debe ser igual a 100',
            (_, context: any) => {
              let sum = 0;
              const { scopes } = context.from[1].value;

              scopes?.map?.((scope: any) => {
                sum += Number(scope.weight) || 0;
                return scope;
              });

              if (sum > 100 || sum < 100) {
                return false;
              }
              return true;
            },
          ),
      }),
    )
    .min(1, 'maintenance.evaluations.form.scopes.required'),
});
