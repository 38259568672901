import { Grid, Skeleton, Tab, Tabs, useTheme } from '@material-ui/core';
import { useState } from 'react';
import { useGetTopBooksQuery } from '../../slices/libraryDashboardApiSlice';
import BookItem from './BookItem';

const tabs = [
  {
    label: 'Libros Nuevos',
    keyword: 'new_books',
    id: 1,
  },
  {
    label: 'Más Leídos',
    keyword: 'top_reads',
    id: 2,
  },
  {
    label: 'Libros Recomendados',
    keyword: 'recommended',
    id: 3,
  },
];

const BooksTabs = () => {
  const theme = useTheme();

  const [activeTab, setActiveTab] = useState(1);

  const { data: topBooks, isLoading } = useGetTopBooksQuery({});

  return (
    <Grid
      container
      justifyContent="center"
      sx={{
        position: 'relative',
      }}
      pt={2}
    >
      <Grid item xs={9}>
        <Tabs centered value={activeTab} onChange={(_, value) => setActiveTab(value)}>
          {tabs.map((tab) => (
            <Tab
              label={tab.label}
              value={tab.id}
              sx={{
                backgroundColor: theme.palette.contentBg.main,
                color: theme.palette.primary.main,
                borderRadius: '24px 24px 0 0',
                padding: '12px 24px',
                textTransform: 'capitalize',
                '&.Mui-selected': {
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.background.paper,
                },
              }}
            />
          ))}
        </Tabs>
      </Grid>
      <Grid
        item
        container
        xs={12}
        pt={2}
        pb={3}
        sx={{
          background: `linear-gradient(182.34deg, ${theme.palette.primary.main} 36.66%, ${theme.palette.secondary.main} 149.9%)`,
          '&::after': {
            content: '""',
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            height: '50px',
            background: `linear-gradient(to top, ${theme.palette.primary.main} 36.66%, ${theme.palette.secondary.main} 149.9%)`,
            zIndex: 1,
          },
        }}
        justifyContent="center"
      >
        <Grid
          item
          xs={9}
          sx={{
            zIndex: 2,
          }}
        >
          {isLoading && (
            <Grid container spacing={2} ml={0} mt={0} justifyContent="space-around" pl={0} pr={4}>
              {Array.from(new Array(5)).map(() => (
                <Grid item container xs={12} sm={4} md={2}>
                  <Skeleton variant="rectangular" width="100%" height="150px" />
                </Grid>
              ))}
            </Grid>
          )}

          {}

          {!isLoading && topBooks && (
            <>
              <Grid
                container
                spacing={2}
                ml={0}
                mt={0}
                justifyContent="space-around"
                pl={0}
                pr={4}
                sx={{
                  display: activeTab === 1 ? 'flex' : 'none',
                }}
              >
                {topBooks?.new_books?.map((book) => (
                  <Grid item container xs={12} sm={4} md={2}>
                    <BookItem book={book} />
                  </Grid>
                ))}
              </Grid>
              <Grid
                container
                spacing={2}
                ml={0}
                mt={0}
                justifyContent="space-around"
                pl={0}
                pr={4}
                sx={{
                  display: activeTab === 2 ? 'flex' : 'none',
                }}
              >
                {topBooks?.top_reads?.map((book) => (
                  <Grid item container xs={12} sm={4} md={2}>
                    <BookItem book={book} />
                  </Grid>
                ))}
              </Grid>
              <Grid
                container
                spacing={2}
                ml={0}
                mt={0}
                justifyContent="space-around"
                pl={0}
                pr={4}
                sx={{
                  display: activeTab === 3 ? 'flex' : 'none',
                }}
              >
                {topBooks?.recommended?.map((book) => (
                  <Grid item container xs={12} sm={4} md={2}>
                    <BookItem book={book} />
                  </Grid>
                ))}
              </Grid>

              {/* <Grid container spacing={2} ml={0} mt={0} justifyContent="space-around" pl={0} pr={4}>
            {topBooks[tabs.find((tab) => tab.id === activeTab)?.keyword! as keyof TopBooks]?.map(
              (book) => (
                <Grid item xs={12} sm={4} md={2}>
                  <BookItem book={book} />
                </Grid>
              )
            )}
          </Grid> */}
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BooksTabs;
