import { mainApi } from '../../../../../../api/mainApi';
import { middlewareApi } from '../../../../../middleware/middlewareApi';
import { IResponse } from '../../../../../shared/interfaces/response.interface';
import { IScope } from '../interfaces/scopes.interface';

export const scopesTags = middlewareApi.enhanceEndpoints({
  addTagTypes: ['Scopes'],
});

export const scopesApi = scopesTags.injectEndpoints({
  endpoints: (builder) => ({
    getScopes: builder.query<
      IResponse<IScope>,
      {
        page: number;
        perPage: number;
        search: string;
        sort?: string;
        typeSort?: string;
        status?: string;
      }
    >({
      queryFn: async ({ page, perPage, search, sort, typeSort, status }, { dispatch }) => {
        try {
          const { data } = await mainApi.get<IResponse<IScope>>('/eva/ambitos', {
            params: {
              page,
              size: perPage,
              search,
              ...(sort && { sort }),
              ...(typeSort && { type_sort: typeSort }),
              ...(status && { status }),
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) =>
        result && result.data
          ? [
              ...result.data.map(({ uuid: id }) => ({ type: 'Scopes', id } as const)),
              { type: 'Scopes', id: 'LIST' },
            ]
          : [{ type: 'Scopes', id: 'LIST' }],
    }),

    getAllScopes: builder.query<IScope[], {}>({
      // eslint-disable-next-line no-empty-pattern
      queryFn: async ({}, { dispatch }) => {
        try {
          const { data } = await mainApi.get<IScope[]>('/eva/ambitos', {
            params: {
              data: 'all',
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ uuid: id }) => ({ type: 'Scopes', id } as const)),
              { type: 'Scopes', id: 'LIST' },
            ]
          : [{ type: 'Scopes', id: 'LIST' }],
    }),

    getScope: builder.query<
      IScope,
      {
        id: string;
      }
    >({
      queryFn: async ({ id }, { dispatch }) => {
        try {
          const { data } = await mainApi.get<IScope>(`/eva/ambitos/${id}`);
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),
    addScope: builder.mutation<
      IScope,
      {
        description: string;
        name: string;
        status: string;
      }
    >({
      queryFn: async ({ description, name, status }, { dispatch }) => {
        try {
          const { data } = await mainApi.post<IScope>('/eva/ambitos', {
            description,
            name,
            status,
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'Scopes', id: 'LIST' }],
    }),

    updateScope: builder.mutation<
      IScope,
      {
        id: string;
        description: string;
        name: string;
        status: string;
      }
    >({
      queryFn: async ({ id, description, name, status }, { dispatch }) => {
        try {
          const { data } = await mainApi.put<IScope>(`/eva/ambitos/${id}`, {
            description,
            name,
            status,
          });
          return { data };
        } catch (error: any) {
          return {
            error,
          };
        }
      },
      invalidatesTags: [{ type: 'Scopes', id: 'LIST' }],
    }),
    deleteScope: builder.mutation<
      IScope,
      {
        id: string;
      }
    >({
      queryFn: async ({ id }, { dispatch }) => {
        try {
          const { data } = await mainApi.delete<IScope>(`/eva/ambitos/${id}`);
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'Scopes', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetScopesQuery,
  useLazyGetScopesQuery,
  useGetAllScopesQuery,
  useLazyGetAllScopesQuery,
  useGetScopeQuery,
  useLazyGetScopeQuery,
  useAddScopeMutation,
  useUpdateScopeMutation,
  useDeleteScopeMutation,
} = scopesApi;
