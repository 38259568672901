import { Button /*, FormHelperText*/, Grid, Typography } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { useFormik } from 'formik';
import DragArea from '../../../../shared/components/files/DragArea';
// import DragArea from '../../components/DragArea';

import React, { useState } from 'react';
import ErrorAlert from '../../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../../shared/components/alerts/SuccessAlert';
import { TasksSchema } from '../constants/validationSchema';
import { DetailsActivity } from '../interfaces/tasks.interface';
import InputModals from './InputModal';
import { useTranslation } from 'react-i18next';

import ModalUploadTask from './ModalUploadTask';
import { useSaveTaskFileMutation } from '../../slices/virtualClassroomApiSlice';
import { useAppSelector } from '../../../../../hooks/useRedux';

const ModalArchivos: React.FC<{
  dataModal: {
    open: boolean;
    type: 'file' | 'comment';
    detailId: number;
  };
  setOpen: React.Dispatch<
    React.SetStateAction<{
      open: boolean;
      type: 'file' | 'comment';
      detailId: number;
    }>
  >;
  details: DetailsActivity[];
  taskId: number;
  refetch: () => void;
}> = ({ dataModal, setOpen, details, taskId, refetch }) => {
  /*States */
  const [showAlerts, setShowAlerts] = useState(false);

  const { currentStudent } = useAppSelector((state) => state.access);
  const {
    course: { courseId },
  } = useAppSelector((state) => state.virtualClassroomStudentShared);

  const [addTask, { isError, isLoading, isSuccess, error }] = useSaveTaskFileMutation();
  const { t } = useTranslation();

  const {
    values: { description, file, title },
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm,

    errors,
    touched,
  } = useFormik({
    initialValues: {
      title: '',
      description: '',
      file: null,
    },

    validationSchema: TasksSchema,

    onSubmit: () => {
      AddTask();
    },
  });

  async function AddTask() {
    try {
      await addTask({
        studentId: Number(currentStudent?.id!),
        courseId: Number(courseId!),
        taskId: taskId,
        detailId: dataModal.detailId,
        payload: {
          files: file!,
          description: description,
          title: title,
          type_document: 18,
        },
      });
      setShowAlerts(true);
      isSuccess && resetForm();
    } catch (error) {
      setShowAlerts(true);
    }
  }

  /*Formik */

  const onChangeFile = function (event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.files && event.target.files[0]) {
      setFieldValue('file', event.target.files[0]);
    }
  };

  return (
    <ModalUploadTask
      Open={dataModal.open}
      title={t('students.virtual_classroom.task.modal.title.file')}
    >
      <>
        <Grid
          item
          container
          sx={{
            mb: 3,
          }}
        >
          <Grid item>
            {isError && showAlerts && (
              <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
            )}

            {isSuccess && showAlerts && (
              <SuccessAlert
                message={'Enviado con exito'}
                handleDismiss={() => setShowAlerts(false)}
              />
            )}
          </Grid>
        </Grid>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3} justifyContent={'space-between'}>
            <Grid item xs={12} sm={6}>
              <InputModals
                placeholder={t('students.virtual_classroom.task.modal.file.title.placeholder')}
                Title={t('students.virtual_classroom.task.modal.file.title')}
                Value={title}
                setValue={handleChange}
                name={'title'}
                error={Boolean(errors.title && touched.title)}
                messageError={errors.title}
              />
              <DragArea handleChange={onChangeFile} />
            </Grid>

            <Grid item xs={12} sm={6}>
              <InputModals
                placeholder={t(
                  'students.virtual_classroom.task.modal.file.description.placeholder'
                )}
                Title={t('students.virtual_classroom.task.modal.file.description')}
                Value={description}
                name={'description'}
                setValue={handleChange}
                error={Boolean(errors.description && touched.description)}
                messageError={errors.description}
              />
              <Grid
                container
                style={{
                  backgroundColor: '#E6F8F4',
                  padding: '5px',
                  overflow: 'hidden',
                  borderRadius: '20px',
                }}
              >
                <Grid
                  item
                  mt={'5px'}
                  textOverflow={'ellipsis'}
                  sx={{
                    px: 2,
                    py: 1,
                  }}
                >
                  <Typography color={'primary'} variant="h5">
                    {t('students.virtual_classroom.task.modal.file.extensions.title')}
                  </Typography>
                  <Typography mt={2} color={'primary'} variant="body2" noWrap={false}>
                    {t('students.virtual_classroom.task.modal.file.extensions.description')}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                style={{
                  backgroundColor: '#FCEBEA',
                  padding: '5px',
                  marginTop: '15px',
                  borderRadius: '20px',
                }}
              >
                <Grid
                  item
                  mt={'5px'}
                  textOverflow={'ellipsis'}
                  sx={{
                    px: 2,
                    py: 1,
                  }}
                >
                  <Typography color={'primary'} variant="h5">
                    {t('students.virtual_classroom.task.modal.file.recomendations.title')}
                  </Typography>
                  <Typography mt={2} color={'primary'} variant="body2" noWrap={false}>
                    {t('students.virtual_classroom.task.modal.file.recomendations.description')}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid justifyContent={'center'} mt={'20px'} textAlign="center">
            <Button
              onClick={() => {
                setShowAlerts(false);
                refetch();
                setOpen((prev) => {
                  return {
                    ...prev,
                    open: false,
                    detailId: 0,
                  };
                });
              }}
              style={{ marginRight: '30px', borderRadius: '30px' }}
              variant="outlined"
            >
              {t('students.virtual_classroom.task.modal.button.back')}
            </Button>
            <LoadingButton
              loading={isLoading}
              disabled={isSuccess}
              type="submit"
              style={{ borderRadius: '30px' }}
              variant="contained"
            >
              {t('students.virtual_classroom.task.modal.button.submit')}
            </LoadingButton>
          </Grid>
        </form>
      </>
    </ModalUploadTask>
  );
};

export default ModalArchivos;
