import { useFormikContext } from 'formik';
import Grid from '@material-ui/core/Grid';
import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  TextField,
  Typography,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { ArrowUpward, ImportExport } from '@material-ui/icons';
import InputSecondaryLabelAdd from '../../../../../shared/components/forms/InputSecondaryLabelAdd';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/useRedux';
import { useGetAcademicOfferingsAvailablesQuery } from '../../../slices/periodsApiSlice';
import useSharedGuard from '../../../../../../hooks/useSharedGuard';
import { subPermissionKeys } from '../../../../hourhands/constants/permissions';
// import { useGetAvailablesHourhandQuery } from '../../../../hourhands/slices/hourhandsApiSlice';
import { useGetAvailablesCampusQuery } from '../../../../campus/slices/campusApiSlice';
import { PeriodForm } from '../../../interfaces/period-form.interfaces';
import { useTranslation } from 'react-i18next';
import SearchPaginatedTableAndCollapsibleItems from '../../../../../shared/components/tables/SearchPaginatedTableAndCollapsibleItems';
import {
  useGetAvailablesHourhandQuery,
  useGetHourhandsQuery,
} from '../../../../hourhands/slices/hourhandsApiSlice';
import { useTableSearch } from '../../../../../../hooks/useTableSearch';
import { setHourhandsTable, sethourhandsSelected } from '../../../slices/periodsSlice';
import { useEffect, useState, useRef } from 'react';
import ImportHourhandsDialog from '../../dialogs/ImportHourhandsDialog';

const PeriodOfferAcademic = () => {
  const { t } = useTranslation();

  const [checkAll, setCheckALl] = useState(false);
  const [openDialogImport, setOpenDialogImport] = useState(false);
  const [update, setUpdate] = useState(false);

  const [datafetch, setDatafetch] = useState<
    {
      numberItem: number;
      chck: any;
      name: string;
    }[]
  >([]);

  const buttonRef = useRef<HTMLButtonElement>(null);

  const { currentProfile } = useAppSelector((state) => state.access);

  const dispatch = useAppDispatch();
  const {
    hourhandsTable: { page, perPage, search, filterArrow },
    hourhandsSelected,
  } = useAppSelector((state) => state.periods);

  const { setPage, setPerPage, setSearch } = useTableSearch({
    page,
    perPage,
    search,
    filterArrow,
    updateFunction: setHourhandsTable,
  });

  const { setFieldValue, values, errors, touched } = useFormikContext<PeriodForm>();

  const { hasAccess: hasAccessHourhand } = useSharedGuard(subPermissionKeys);

  const { data: acdOfferingsAvailables = [], isLoading: isLoadingACD } =
    useGetAcademicOfferingsAvailablesQuery({
      profileId: currentProfile?.profileId!,
    });

  const { data: campusAvailables = [], isLoading: isLoadingCAMP } = useGetAvailablesCampusQuery({
    statusId: 1,
    profileId: currentProfile?.profileId!,
  });

  const { data: hourhandsAvailablesAll = [] } = useGetAvailablesHourhandQuery({
    profileId: currentProfile?.profileId!,
  });

  const {
    data: hourhandsAvailables,
    isLoading: isLoadingHA,
    isFetching,
    error,
  } = useGetHourhandsQuery({
    page,
    perPage,
    search,
    sort: 'hour_description',
    type_sort: 'desc',
  });

  useEffect(() => {
    if (Array.isArray(hourhandsAvailablesAll)) {
      setCheckALl(
        hourhandsSelected.filter((item) =>
          hourhandsAvailablesAll!.find((item2) => item2.id === item)
        ).length === hourhandsAvailablesAll.length
      );
    } else {
      setCheckALl(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hourhandsAvailablesAll]);

  useEffect(() => {
    setUpdate(true);
    setTimeout(() => {
      setUpdate(false);
    }, 50);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hourhandsSelected]);

  useEffect(() => {
    if (hourhandsSelected.length) {
      setFieldValue('hourhandsIds', hourhandsSelected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hourhandsSelected]);

  const handleClickOrder = async () => {
    if (hourhandsSelected.length && hourhandsAvailables && hourhandsAvailablesAll.length) {
      await setUpdate(true);

      const hourhandsFilter = hourhandsAvailablesAll.filter((item) =>
        hourhandsSelected.find((item2) => item2 === item.id)
      );

      const hourhandsFilter2 = hourhandsAvailables?.data.filter(
        (item) => !hourhandsSelected.find((item2) => item2 === item.id)
      );

      const hourHandsFinal = [...hourhandsFilter, ...hourhandsFilter2].map((e, index) => ({
        numberItem: index + 1,
        chck: e,
        name: `${e.hour_description}`,
      }));

      setDatafetch(hourHandsFinal);

      setTimeout(() => {
        setUpdate(false);
      }, 70);
    }
  };

  useEffect(() => {
    if (hourhandsAvailables) {
      setDatafetch(
        hourhandsAvailables?.data?.map((e, index) => ({
          numberItem: index + 1,
          chck: e,
          name: `${e.hour_description}`,
        }))
      );
    }
  }, [hourhandsAvailables]);

  return (
    <Grid container spacing={4} justifyContent="center" alignItems="flex-start">
      <Grid item xs={12} md={6} container spacing={2} marginTop={2}>
        <Grid item xs={12}>
          <InputLabel>Oferta Académica</InputLabel>
          <Autocomplete
            options={acdOfferingsAvailables}
            loading={isLoadingACD}
            loadingText="Cargando ofertas académicas..."
            getOptionLabel={(option) => option.off_name}
            renderInput={(params) => (
              <TextField
                {...params}
                error={Boolean(errors.academicOfferId && touched.academicOfferId)}
                placeholder="Seleccione una oferta académica disponible"
              />
            )}
            value={acdOfferingsAvailables.find((a) => a.id === values.academicOfferId) || null}
            onChange={(_, value) => {
              setFieldValue('academicOfferId', value?.id);
            }}
            fullWidth
            clearIcon={null}
          />
          {errors.academicOfferId && touched.academicOfferId && (
            <FormHelperText error>{t(errors.academicOfferId)}</FormHelperText>
          )}
        </Grid>
        <Grid item xs={12}>
          <InputLabel>Campus</InputLabel>
          <Autocomplete
            multiple
            options={campusAvailables}
            loading={isLoadingCAMP}
            loadingText="Cargando campus disponibles..."
            getOptionLabel={(option) => option.cam_name}
            renderInput={(params) => (
              <TextField
                {...params}
                error={Boolean(errors.campusIds && touched.campusIds)}
                placeholder="Seleccione los campus disponibles"
              />
            )}
            value={
              campusAvailables?.filter((c) => values.campusIds.some((dt) => dt === c.id)) || []
            }
            onChange={(_, values) => {
              setFieldValue('campusIds', [...values.map((v) => v.id)] || '');
            }}
            fullWidth
          />
          {errors.campusIds && touched.campusIds && (
            <FormHelperText error>{t(errors.campusIds)}</FormHelperText>
          )}
        </Grid>
        <Grid item xs={12}>
          <InputSecondaryLabelAdd
            label={'Horario'}
            title={'Agrega nuevo Horario'}
            link={'/administrar-horarios/agregar'}
            hasAccess={hasAccessHourhand('create')}
          />

          <Typography
            variant="body2"
            color="textSecondary"
            sx={{
              my: 2,
            }}
          >
            Horarios seleccionados: {hourhandsSelected.length}
          </Typography>
          {/* <Autocomplete
            multiple
            options={hourhandsAvailablesMap}
            loading={isLoadingHA}
            loadingText="Cargando horarios disponibles..."
            getOptionLabel={(option) => option.hour_description}
            renderInput={(params) => (
              <TextField
                {...params}
                error={Boolean(errors.hourhandsIds && touched.hourhandsIds)}
                placeholder="Seleccione los horarios disponibles"
              />
            )}
            value={
              hourhandsAvailablesMap.filter((h) => values.hourhandsIds.some((d) => d === h.id)) ||
              []
            }
            onChange={(_, values) => {
              setFieldValue('hourhandsIds', [...values.map((v) => v.id)] || '');
            }}
            fullWidth
          /> */}

          <SearchPaginatedTableAndCollapsibleItems
            data={datafetch!}
            headers={{
              numberItem: t('N°'),
              chck: (
                <FormControlLabel
                  sx={{
                    '& .MuiTypography-body1': {
                      fontSize: '0.8rem',
                    },
                    mx: 0,
                  }}
                  control={
                    <Checkbox
                      inputProps={{ 'aria-label': 'controlled' }}
                      checked={checkAll}
                      onChange={(e) => {
                        setCheckALl(e.target.checked);
                        if (e.target.checked) {
                          const newQuestionsSelected = hourhandsSelected.filter(
                            (item) =>
                              !Boolean(hourhandsAvailablesAll!.find((item2) => item2.id === item))
                          );
                          const hourhandsAvailablesAllIds = hourhandsAvailablesAll!.map(
                            (item) => item.id!
                          );

                          dispatch(
                            sethourhandsSelected([
                              ...newQuestionsSelected,
                              ...hourhandsAvailablesAllIds!,
                            ])
                          );
                        } else {
                          const newQuestionsSelected = hourhandsSelected.filter(
                            (item) =>
                              !Boolean(hourhandsAvailablesAll!.find((item2) => item2.id === item))
                          );
                          dispatch(sethourhandsSelected(newQuestionsSelected));
                        }
                      }}
                    />
                  }
                  label="Todos"
                />
              ) as any,
              name: t('maintenance.questions.name'),
            }}
            keyExtractor={(item) => item.chck.id!.toString()}
            searchPlacehoder={t('maintenance.questions.search')}
            isLoading={isLoadingHA}
            isFetching={isFetching}
            error={error as any}
            perPage={perPage}
            setPerPage={setPerPage}
            total={hourhandsAvailables?.total || 0}
            page={page}
            setPage={setPage}
            numHeader={6}
            search={search}
            setSearch={setSearch}
            customHeadersCellsProperties={{
              name: {
                align: 'center',
              },
              chck: {
                align: 'center',
              },
              numberItem: {
                align: 'center',
              },
            }}
            customDataCellsProperties={{
              name: {
                align: 'center',
              },
              chck: {
                align: 'center',
              },
              numberItem: {
                align: 'center',
              },
            }}
            customRenderers={{
              chck: ({ chck }) => (
                <>
                  {update ? null : (
                    <Checkbox
                      inputProps={{ 'aria-label': 'controlled' }}
                      checked={Boolean(hourhandsSelected.find((item) => item === chck.id))}
                      onChange={() => {
                        const isSelected = hourhandsSelected.find((item) => item === chck.id);
                        if (isSelected) {
                          const newQuestionsSelected = hourhandsSelected.filter(
                            (item) => item !== chck.id
                          );
                          setCheckALl(false);
                          dispatch(sethourhandsSelected(newQuestionsSelected));
                        } else {
                          dispatch(sethourhandsSelected([...hourhandsSelected, chck.id]));
                        }
                      }}
                    />
                  )}
                </>
              ),
            }}
            filtersPos="top"
            ActionButtons={
              <Grid container spacing={1} justifyContent="flex-end">
                <Grid item xs={12} sm={6} md={12}>
                  <Button
                    ref={buttonRef}
                    variant="outlined"
                    size="large"
                    color="primary"
                    onClick={handleClickOrder}
                    disabled={update}
                    startIcon={update ? <CircularProgress size={15} /> : <ArrowUpward />}
                  >
                    Seleccionados
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6} md={12}>
                  <Button
                    variant="outlined"
                    size="large"
                    color="primary"
                    onClick={() => {
                      setOpenDialogImport(true);
                    }}
                    disabled={update}
                    startIcon={update ? <CircularProgress size={15} /> : <ImportExport />}
                  >
                    Importar
                  </Button>
                </Grid>
              </Grid>
            }
          />

          {errors.hourhandsIds && touched.hourhandsIds && (
            <FormHelperText error>{t(errors.hourhandsIds)}</FormHelperText>
          )}
        </Grid>
      </Grid>
      {openDialogImport && (
        <ImportHourhandsDialog
          handleCloseDialog={() => setOpenDialogImport(false)}
          orderImports={() => {
            buttonRef.current?.click();
          }}
        />
      )}
    </Grid>
  );
};

export default PeriodOfferAcademic;
