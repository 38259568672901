import { ChangeEvent, useState, useEffect } from 'react';

import {
  Checkbox,
  Toolbar,
  Typography,
  makeStyles,
  TableCell,
  Grid,
  Alert,
  Stepper,
  useMediaQuery,
  Step,
  StepLabel,
  useTheme,
} from '@material-ui/core';
import clsx from 'clsx';

import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import SearchPaginatedTable from '../../../../shared/components/tables/SearchPaginatedTable';
import { useGetStudentDebtsQuery } from '../../slices/paymentsApiSlice';
import { PaymentsDebts } from '../../interfaces/paymentsHistory.interface';
import { setDebtorId, setSelectedDebts, setSelectedDebtsId } from '../../slices/paymentsSlice';
import { checkoutSteps as steps } from '../../constants';

//import StatusProntoPagoChip from '../../../../shared/components/badges/StatusProntoPagoChip';

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight: {
    color: theme.palette.secondary.main,
  },
  title: {
    flex: '1 1 100%',
  },
}));

const DebtsTab = () => {
  const { currentProfile, currentStudent, currentRecordStudent } = useAppSelector(
    (state) => state.access
  );
  const { selectedDebtsId: selected } = useAppSelector((state) => state.payments);

  const dispatch = useAppDispatch();
  const theme = useTheme();

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [perPage, setPerPage] = useState(10);
  //
  const [outRangeState, setOutRangeState] = useState<number[]>();
  const [isOutRange, setIsOutRange] = useState<boolean>();

  //   const [selected, setSelected] = useState<number[]>([]);

  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    data,
    isFetching,
    isLoading,
    error,
    // refetch,
  } = useGetStudentDebtsQuery({
    profileId: currentProfile?.profileId!,
    studentId: currentStudent?.id!,
    page,
    perPage,
    search,
    ...(currentRecordStudent && { student_record: currentRecordStudent?.id }),
  });

  // const totalAmount = useMemo(() => {
  //   let amount = 0;

  //   const filterDebts =
  //     data?.data.filter((debt) => selected.some((select) => +debt.fees_identifier === select)) ||
  //     [];

  //   filterDebts.forEach((el) => {
  //     amount += +el.fees_balance;
  //   });

  //   return amount;
  // }, [data, selected]);

  //tabla content structure
  const datafetch = data?.data?.map((value) => ({
    description: value.fees_name_identifier,
    year:
      value.per_current_year && value.per_due_year
        ? `${value.per_current_year}-${value.per_due_year}`
        : '',
    fees_start_date: value.fees_start_date,
    end_data: value.fees_expiration_date,
    //prontopago: value.apply_early_payment,
    fees_net_amount: value.fees_net_amount,
    desc_percent_prontopago: value.apply_early_payment
      ? value.discount_early_payment_is_percent
        ? value.discount_value_early_payment //value.discount_early_payment
        : value.discount_early_payment //value.discount_percent_early_payment
      : '0.00',
    //fees_balance: value.fees_balance,
    fees_payment: value.fees_payment,
    net_paid_value: value.apply_early_payment ? value.net_value_early_payment : value.fees_balance,
    options: value,
  }));

  //select all checkbox
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelectedId = data?.data?.map((n) => +n.fees_identifier) || [];
      dispatch(setSelectedDebtsId(newSelectedId));
      //   setSelected(newSelectedId);
      return;
    }

    dispatch(setSelectedDebtsId([]));
    // setSelected([]);
  };

  const handleClick = (
    // event: React.MouseEvent<HTMLTableHeaderCellElement, MouseEvent>,
    event: React.ChangeEvent<HTMLInputElement>,
    feeId: number
  ) => {
    const selectedIndex = selected.indexOf(feeId);
    let newSelected: number[] = [];

    let isUnSelected = false;

    const currentData = data!.data.find((el) => +el.fees_identifier === feeId);

    if (selectedIndex === -1) {
      const outRange = data!.data.filter(
        (dt) => Date.parse(dt.fees_expiration_date) < Date.parse(currentData?.fees_expiration_date!)
      );

      //comportamiento de bloqueo
      // hacer un filter de la data en base a los seleccionados y comparar el lenght
      if (outRange.length) {
        const dataSelected = outRange.filter((dt) =>
          selected.some((sel) => sel === +dt.fees_identifier)
        );

        if (dataSelected.length !== outRange.length) {
          setOutRangeState(
            outRange
              .filter((dt) =>
                data!.data.some((selDt) => selDt.fees_identifier === dt.fees_identifier)
              )
              .map((fil) => +fil.fees_identifier)
          );
          setIsOutRange(true);
          return;
        } else {
          setOutRangeState([]);
          setIsOutRange(false);
        }
      } else {
        setOutRangeState([]);
        setIsOutRange(false);
      }

      //comportamiento de autoselect
      outRange.forEach((element) => {
        const isStored = selected.some((dt) => dt === +element.fees_identifier);
        if (!isStored) newSelected = newSelected.concat(+element.fees_identifier);
      });

      newSelected = newSelected.concat(selected, feeId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
      isUnSelected = true;
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
      isUnSelected = true;
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
      isUnSelected = true;
    }

    //

    if (isUnSelected) {
      const stored = data!.data.filter((dt) =>
        selected.some((sel) => +dt.fees_identifier === sel && +dt.fees_identifier !== feeId)
      );
      stored.forEach((element) => {
        const isOutDate =
          Date.parse(element.fees_expiration_date) > Date.parse(currentData?.fees_expiration_date!);
        if (isOutDate) {
          setIsOutRange(true);
          const outRangeIndex = newSelected.indexOf(+element.fees_identifier);
          newSelected.splice(outRangeIndex, 1);
        } else {
          setIsOutRange(false);
        }
      });
    }

    dispatch(setSelectedDebtsId(newSelected));
    // setSelected(newSelected);
  };

  useEffect(() => {
    if (data?.data?.length) {
      const filterDebts =
        data?.data.filter((debt) => selected.some((select) => +debt.fees_identifier === select)) ||
        [];

      dispatch(setSelectedDebts(filterDebts));
      if (filterDebts.length) dispatch(setDebtorId(Number(filterDebts[0].debtor_id)));
    }
  }, [data?.data, dispatch, selected]);

  const defaultConfiguration = useAppSelector((state) => state.access.defaultConfiguration);

  return (
    <>
      <Grid container justifyContent={'center'}>
        <Grid item xs={12} md={10} lg={7}>
          <Stepper
            activeStep={0}
            sx={{ pt: 3, pb: 5 }}
            orientation={matchDownSM ? 'vertical' : 'horizontal'}
          >
            {steps.map((label, index) => {
              const labelProps: { error?: boolean; optional?: React.ReactNode } = {};

              // if (index === errorIndex) {
              //   labelProps.optional = (
              //     <Typography variant="caption" color="error">
              //       Error
              //     </Typography>
              //   );

              //   labelProps.error = true;
              // }

              return (
                <Step key={label}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Grid>
      </Grid>

      <Grid container my={1}>
        <Grid item xs={12}>
          {isOutRange && (
            <Alert severity="warning">
              Se tiene que seleccionar las deudas en orden de la fecha de vencimiento
            </Alert>
          )}
        </Grid>
      </Grid>

      <SearchPaginatedTable
        data={datafetch || []}
        headers={{
          description: 'Deuda',
          year: 'Período lectivo',
          fees_start_date: 'Fecha de inicio de cobro',
          end_data: 'Fecha de vencimiento',
          //prontopago: 'Desc. Prontopago',
          fees_net_amount: `${defaultConfiguration?.currency_symbol} Valor`,
          desc_percent_prontopago: `${defaultConfiguration?.currency_symbol} Descuento`,
          //fees_balance: 'Valor final sin descuento',
          fees_payment: `${defaultConfiguration?.currency_symbol} Abono`,
          net_paid_value: `${defaultConfiguration?.currency_symbol} Valor final`,
          options: (
            <Checkbox
              color="primary"
              disabled={page > 1}
              indeterminate={selected.length > 0 && selected.length < (data?.data?.length || 0)}
              checked={
                (data?.data?.length || 0) > 0 && selected.length === (data?.data?.length || 0)
              }
              onChange={handleSelectAllClick}
              inputProps={{
                'aria-label': 'select all desserts',
              }}
            />
          ) as any,
        }}
        keyExtractor={(item) => item.options.fees_identifier}
        searchPlacehoder="Buscar deuda"
        isLoading={isLoading}
        isFetching={isFetching}
        error={error}
        perPage={perPage}
        setPerPage={setPerPage}
        total={data?.total || 0}
        page={page}
        setPage={setPage}
        numHeader={9}
        search={search}
        setSearch={setSearch}
        replaceHeader={Boolean(selected.length)}
        newHeader={
          <EnhancedTableToolbar
            numSelected={selected.length}
            selected={selected}
            data={data?.data || []}
            page={page}
            handleSelectAllClick={handleSelectAllClick}
          />
        }
        customRenderers={{
          /*prontopago: (row) => <StatusProntoPagoChip prontoPago={row.prontopago!} />,*/
          // detail: ({ detail }) => (
          //   <Grid container item direction="column" gap={1}>
          //     <Typography>Máximo materias: {detail.per_max_matter_enrollment}</Typography>
          //     <Typography>Mínimo materias: {detail.per_min_matter_enrollment}</Typography>
          //     <Typography>Cuota/matrícula : {detail.per_pay_enrollment}</Typography>
          //     <Typography>Número cuotas : {detail.per_num_fees}</Typography>
          //   </Grid>
          // ),
          options: (row) => (
            <>
              <Checkbox
                disabled={page > 1}
                {...(outRangeState?.some((dt) => dt === +row.options.fees_identifier) && {
                  sx: { color: 'red' },
                })}
                checked={selected.some((r) => r === Number(row.options.fees_identifier))}
                onChange={(e) => handleClick(e, Number(row.options.fees_identifier))}
              />
            </>
          ),
        }}
        customHeadersCellsProperties={{
          options: {
            align: 'center',
            width: '10%',
          },
          fees_net_amount: {
            align: 'center',
          },
          /*fees_balance: {
              align: 'center',
            },*/
          fees_payment: {
            align: 'center',
          },
          end_data: {
            align: 'center',
          },
          year: {
            align: 'center',
          },
        }}
        customDataCellsProperties={{
          fees_net_amount: {
            align: 'center',
          },
          /*fees_balance: {
              align: 'center',
            },*/
          fees_payment: {
            align: 'center',
          },
          options: {
            align: 'center',
            width: '10%',
          },
          end_data: {
            align: 'center',
          },
          /*prontopago: {
              align: 'center',
            },*/
          desc_percent_prontopago: {
            align: 'center',
          },
          year: {
            align: 'center',
          },
          net_paid_value: {
            align: 'center',
          },
        }}
        // ActionButtons={
        //   <>
        //     {Boolean(selected.length) && (
        //       <>
        //         <Typography variant="h4" component="span">
        //           Total: {totalAmount}{' '}
        //         </Typography>
        //         <Button variant="contained" onClick={goToPay}>
        //           Ir a pagar
        //         </Button>
        //       </>
        //     )}
        //   </>
        // }
      />

      {/* float */}
      {/* {Boolean(totalAmount) && false && (
        <Box
          sx={{
            border: `1px solid ${theme.palette.primary.main}`,
            backgroundColor: theme.palette.primary.main,
            borderRadius: '12px',
            position: 'fixed',
            bottom: 0,
            width: '70vw',
            padding: 2,
          }}
        >
          <Grid
            container
            justifyContent={'flex-end'}
            alignItems={'center'}
            color={'white'}
            spacing={2}
          >
            <Grid item>
              <Typography>Total Seleccionado</Typography>
            </Grid>
            <Grid item>
              <Typography>{totalAmount ? `$${totalAmount}` : 0}</Typography>
            </Grid>
            <Grid item>
              <Button
                style={{
                  background: 'white',
                  // background: theme.palette.dark.light,
                  borderColor: '#EDE7F6',
                  // color: 'white',
                }}
                // onClick={goToPay}
              >
                Continuar
              </Button>
            </Grid>
          </Grid>
        </Box>
      )} */}
    </>
  );
};

export default DebtsTab;

const EnhancedTableToolbar = (props: {
  numSelected: number;
  selected: number[];
  data: PaymentsDebts[];
  page: number;

  handleSelectAllClick: (event: ChangeEvent<HTMLInputElement>) => void;
}) => {
  const classes = useToolbarStyles();
  const { numSelected, selected, data, page, handleSelectAllClick } = props;

  return (
    <>
      <TableCell padding="none" colSpan={8} align="right">
        <Toolbar
          className={clsx(classes.root, {
            [classes.highlight]: numSelected > 0,
          })}
        >
          {numSelected > 0 ? (
            <Typography className={classes.title} color="inherit" variant="h4" component="div">
              {numSelected} Seleccionado{numSelected > 1 ? 's' : ''}
            </Typography>
          ) : (
            <Typography
              className={classes.title}
              variant="h6"
              id="tableTitle"
              component="div"
            ></Typography>
          )}

          {/* {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton sx={{ p: 2.25 }}>
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )} */}
        </Toolbar>
      </TableCell>
      <TableCell padding="checkbox" sx={{ pl: 3 }}>
        <Checkbox
          color="primary"
          disabled={page > 1}
          indeterminate={selected.length > 0 && selected.length < (data.length || 0)}
          checked={(data.length || 0) > 0 && selected.length === (data.length || 0)}
          onChange={handleSelectAllClick}
          inputProps={{
            'aria-label': 'select all desserts',
          }}
        />
      </TableCell>
    </>
  );
};
