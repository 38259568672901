import { Grid, Typography } from '@material-ui/core';
import MainCard from '../../../shared/components/cards/MainCard';
import HHRRApplicationsTable from '../../applications/components/HHRRApplicationsTable';

const ApplicationsListScreen = () => {
  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">Listado de Solicitudes</Typography>
      </Grid>

      <HHRRApplicationsTable />
    </Grid>
  );
};

export default ApplicationsListScreen;
