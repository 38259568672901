import * as yup from 'yup';

export const formAddSchema = yup.object().shape({
  period_id: yup.string().required('El periodo es requerido'),
  education_level_id: yup.string().required('La unidad académica es requerida'),
  course_id: yup.string().nullable(),
  eva_scale_representative_id: yup.string().required('La escala es requerida'),
  eva_rubrica_id: yup.string().required('El escenario de evaluación es requerido'),
  evaluated_id: yup.string().required('El evaluado es requerido'),
  eva_scale_representative_name: yup
    .string()
    .required('El nombre de la escala representativa es requerido'),
  eva_rubrica_detail: yup
    .array()
    .of(
      yup.object().shape({
        eva_rubrica_detail_id: yup
          .string()
          .required('El detalle del escenario de evaluación es requerido'),
        form_value: yup.string().required('El valor de la evaluación es requerido'),
      })
    )
    .min(1, 'Debe ingresar al menos un detalle del escenario de evaluación'),
  eva_formulary_document: yup
    .array()
    .of(
      yup.object().shape({
        eva_form_tip_doc_id: yup.string(),
        eva_formulary_document_url: yup
          .string()
          .when('eva_form_tip_doc_id', (eva_form_tip_doc_id: string, schema: any) => {
            
            if (Number(eva_form_tip_doc_id) === 1) {
              return schema.required('El documento es requerido');
            }
            return schema;
          }),
      })
    )
    .min(1, 'Debe ingresar al menos un documento'),
});
