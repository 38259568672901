import { mainApi } from '../../../../api/mainApi';
import { middlewareApi } from '../../../middleware/middlewareApi';
import { IBook } from '../../books/interfaces/books.interfaces';
import {
  BooksResponse,
  Category,
  Directory,
  LibraryInfo,
  TopBooks,
} from '../interfaces/dashboard.interfaces';

export const libraryDashboardTags = middlewareApi.enhanceEndpoints({
  addTagTypes: ['LibraryDashboardBooks'],
});

export const libraryDashboardApi = libraryDashboardTags.injectEndpoints({
  endpoints: (builder) => ({
    getBooksBySearch: builder.query<
      BooksResponse,
      { search: string; perPage: number; page: number }
    >({
      queryFn: async ({ search, perPage, page }) => {
        try {
          const { data } = await mainApi.get<BooksResponse>('/books', {
            params: {
              search,
              size: perPage,
              page,
            },
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'LibraryDashboardBooks', id } as const)),
              { type: 'LibraryDashboardBooks', id: 'LIST' },
            ]
          : [{ type: 'LibraryDashboardBooks', id: 'LIST' }],
    }),

    getAllBooks: builder.query<IBook[], {}>({
      queryFn: async () => {
        try {
          const { data } = await mainApi.get<IBook[]>('/books', {
            params: {
              data: 'all',
            },
          });
          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) => [{ type: 'LibraryDashboardBooks', id: 'LIST' }],
    }),

    getBooksCategories: builder.query<Category[], {}>({
      queryFn: async () => {
        try {
          const { data } = await mainApi.get<Category[]>('books-tags', {
            params: {
              type_tag: 'Categoria',
              data: 'all',
            },
          });
          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    getBooksByCategory: builder.query<BooksResponse, { category: string }>({
      queryFn: async ({ category }) => {
        try {
          const { data } = await mainApi.get<BooksResponse>('/books', {
            params: {
              category,
            },
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    getTopBooks: builder.query<TopBooks, {}>({
      queryFn: async () => {
        try {
          const { data } = await mainApi.get<TopBooks>('/books/library/tags');
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    getLibraryInfo: builder.query<LibraryInfo, {}>({
      queryFn: async () => {
        try {
          const { data } = await mainApi.get<LibraryInfo>('/books/library/info');
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    registerVisitStudent: builder.mutation<
      any,
      {
        date: string;
        personId: number;
        educationLevelId: number;
        studentRecordId: number;
        type: 'open access' | 'library' | 'catalog';
      }
    >({
      queryFn: async ({ date, personId, educationLevelId, studentRecordId, type }) => {
        try {
          const data = await mainApi.post<any>('/library-metrics', {
            date_visit: date,
            person_id: personId, // del estudiante
            services: [
              {
                model: type,
              },
            ],
            type_person: 'Student',
            type_visit: 'Virtual',
            education_level_id: educationLevelId, // del estudiante
            student_record_id: studentRecordId, // del estudiante
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),
    /* reserveBook: builder.mutation<any, { bookId: number; profileId: number; userId: number }>({
      queryFn: async ({ bookId, userId, profileId }) => {
        try {
          const data = await mainApi.post<any>('/applications/book-borrow', {
            user_profile_id: profileId,
            user_id: userId,
            app_description: 'Solicitud de prestamo de libro',
            books: [
              {
                book_id: bookId,
                amount: 1,
              },
            ],
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }), */
    reserveBook: builder.mutation<
      any,
      {
        payload: {
          user_profile_id: number;
          borrows: {
            model_type: string;
            model_id: number;
            borrow_date: string;
            borrow_return_date: string;
            copies_amount: number;
            type: string;
            selected_user_profile_id: number;
            details: string;
          }[];
        };
      }
    >({
      queryFn: async ({ payload }) => {
        try {
          const data = await mainApi.post<any>('/borrows', {
            ...payload,
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: (result) =>
        result
          ? [{ type: 'LibraryDashboardBooks', id: result?.id }]
          : [
              {
                type: 'LibraryDashboardBooks',
                id: 'LIST',
              },
            ],
    }),

    getDirectories: builder.query<Directory[], {}>({
      queryFn: async () => {
        try {
          const { data } = await mainApi.get<Directory[]>('/books/library/third-party');
          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
    }),
  }),
});

export const {
  useGetBooksBySearchQuery,
  useGetAllBooksQuery,
  useLazyGetBooksBySearchQuery,
  useGetBooksCategoriesQuery,
  useGetTopBooksQuery,
  useGetLibraryInfoQuery,
  useReserveBookMutation,
  useGetDirectoriesQuery,
  useRegisterVisitStudentMutation,
} = libraryDashboardApi;
