import { Switch, Redirect } from 'react-router-dom';
import { useAppSelector } from '../../../../hooks/useRedux';
import GuardRoute from '../../../../router/guards/GuardRoute';
import { permissionKey, subPermissionKeys } from '../constants/permissions';
import AcademicRecordScreen from '../screens/AcademicRecordScreen';
import ConductThirdRegistrationScreen from '../screens/ConductThirdRegistrationScreen';
import ConsulateScreen from '../screens/ConsulateScreen';
import CreditScreen from '../screens/CreditScreen';
import PaidCertificateScreen from '../screens/PaidCertificateScreen';
import ProfessionalPracticeScreen from '../screens/ProfessionalPracticesScreen';
import StudyCertificateScreen from '../screens/StudyCertificateScreen';
import SubjectApprovedScreen from '../screens/SubjectApprovedScreen';

const CertificatesRouter = () => {
  const { currentModule } = useAppSelector((state) => state.access);

  const hasPermission = permissionKey in currentModule?.permissions!;
  if (!hasPermission) return <Redirect to="/dashboard" />;
  return (
    <Switch>
      {/* Certificados academicos */}
      <GuardRoute
        exact
        path="/certificados/record-academico"
        component={AcademicRecordScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.read}
      />
      <GuardRoute
        exact
        path="/certificados/certificado-estudio"
        component={StudyCertificateScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.read}
      />
      <GuardRoute
        exact
        path="/certificados/disciplina-tercera-matricula"
        component={ConductThirdRegistrationScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.read}
      />
      <GuardRoute
        exact
        path="/certificados/materias-aprovadas"
        component={SubjectApprovedScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.read}
      />
      {/* Certificados de practicas preprofesionales */}
      <GuardRoute
        exact
        path="/certificados/practicas-preprofesionales"
        component={ProfessionalPracticeScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.read}
      />
      {/* Certificados de credito educativo */}
      <GuardRoute
        exact
        path="/certificados/credit"
        component={CreditScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.read}
      />
      <GuardRoute
        exact
        path="/certificados/consulado"
        component={ConsulateScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.read}
      />

      <GuardRoute
        exact
        path="/certificados/certificados-pagados"
        component={PaidCertificateScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.read}
      />
    </Switch>
  );
};

export default CertificatesRouter;
