import {
  Accordion,
  AccordionDetails,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import { cyan } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/styles';

import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import { ContentMaterials, ToolsMaterial } from '../../interfaces/contentMaterials.interfaces';
import {
  useAddFolderMaterialsMutation,
  useGetContentMaterialsQuery,
} from '../../slices/virtualClassroomTeacherApiSlice';

import { DescripcionSchema } from '../constants/validationSchema';
import ItemFileAccordeon from './ItemFileAccordeon';
import ModalArchivos from './ModalArchivos';
import ModalImport from './ModalImport';
import { useTranslation } from 'react-i18next';
import AccordeonRaiz from './Accordeons/AccordeonRaiz';
import { openDeleteModalFolders } from '../../slices/virtualClassroomTeacherSlice';
import ModalClassroomLink from './ModalClassroomLink';

const useStyles = makeStyles({
  accordeon: {
    '& .css-10kwz4s-MuiButtonBase-root-MuiAccordionSummary-root': {
      backgroundColor: 'transparent',
      border: '1px solid ' + cyan[200],
      borderRadius: '15px',
    },
    '&.css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded': {
      borderRadius: '15px',
    },
    '&..css-1elwnq4-MuiPaper-root-MuiAccordion-root:first-of-type': {
      borderRadius: '15px',
    },
    '& .css-1elwnq4-MuiPaper-root-MuiAccordion-root': {
      borderRadius: '15px',
    },
  },
  input: {
    '& .css-tptuxb-MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
});
const ItemAcorrdeon: React.FC<{
  nameFolder: string;
  subCarpetas: ContentMaterials[];
  childrenfiles: ToolsMaterial[];
  id: number;
  actions?: boolean;
  isChildren?: boolean;
}> = ({ nameFolder, childrenfiles, subCarpetas, id, actions = true }) => {
  const [ModalArchivo, setModalArchivo] = useState<{
    id: number;
    modal: boolean;
    nameFolder: string;
  }>({
    id: 0,
    modal: false,
    nameFolder: '',
  });

  const [ModalClassroomVideos, setModalClassroomVideos] = useState<{
    id: number;
    modal: boolean;
    nameFolder: string;
  }>({
    id: 0,
    modal: false,
    nameFolder: '',
  });
  const infoperfil = useAppSelector((state) => state.virtualClassroomTeacherShared);

  const { t } = useTranslation();

  const classes = useStyles();
  const { data, refetch } = useGetContentMaterialsQuery(
    {
      courseId: infoperfil.course.courseId,
    },
    {
      skip: false,
    }
  );

  // const [DeleteFolderRaiz, { isError, isLoading, isSuccess }] = useDeleteFolderMaterialsMutation();
  const [Open, setOpen] = useState({ id: 0, modal: false });

  const dispatch = useAppDispatch();
  // async function deleteFolder(id: number) {
  //   await DeleteFolderRaiz({ id: id });
  //   setModalDeleteOpen({ id: 0, modalDelete: false });
  // }

  const [FoldersId, setFolders] = useState<number[]>([]);

  const {
    values: { NameFolder },
    handleChange,
    errors,
    touched,
    submitForm,
    resetForm,
  } = useFormik({
    initialValues: {
      NameFolder: '',
    },
    validationSchema: DescripcionSchema,
    onSubmit: () => {
      SubmitFormFolder();
      resetForm();
    },
  });

  const [CreateFolder, StateFolder] = useAddFolderMaterialsMutation();

  const { currentProfile } = useAppSelector((state) => state.access);

  async function SubmitFormFolder() {
    await CreateFolder({
      name_content: NameFolder,
      CursoId: infoperfil.course.courseId,
      user_profile_id: currentProfile?.profileId,
      reply_to: FoldersId,
      parent_id: Open.id,
    });
    setOpen({ ...Open, modal: false });
    refetch();
  }

  const handleClickDeleteFolder = (id: number) => {
    dispatch(openDeleteModalFolders(id));
  };

  return (
    <Grid>
      {/* {modalDeleteOpen.modalDelete && (
        <DeleteAlert
          title={t('teacherVirtualClassroom.materials.delete.descripcion')}
          open={modalDeleteOpen.modalDelete}
          handleDelete={() => deleteFolder(modalDeleteOpen.id)}
          isError={isError}
          isLoading={isLoading}
          isSuccess={isSuccess}
          onClose={() => setModalDeleteOpen({ ...modalDeleteOpen, modalDelete: false })}
          error=""
        />
      )} */}
      <Grid width={'30%'}>
        <ModalImport title={t('teacherVirtualClassroom.folder.modal.title')} Open={Open.modal}>
          <Grid>
            <Grid style={{ margin: '15px 0' }}>
              <Typography
                variant="body1"
                textAlign="left"
                mb="15px"
                fontWeight={'regular'}
                color="gray"
              >
                {t('teacherVirtualClassroom.folder.modal.title')}
              </Typography>
              <TextField
                helperText={errors.NameFolder}
                error={(errors.NameFolder && touched.NameFolder) !== null}
                value={NameFolder}
                onChange={handleChange}
                name="NameFolder"
                fullWidth
                className={classes.input}
                style={{
                  borderRadius: '50px',
                  backgroundColor: 'lightgray',
                  outline: 'none',
                  overflow: 'hidden',
                }}
                placeholder={t('teacherVirtualClassroom.folder.modal.input.placeholder')}
              />
              <Grid style={{ margin: '15px 0' }}>
                <Typography color={'gray'}>
                  {t('teacherVirtualClassroom.folder.modal.duplicar')}
                </Typography>
                <FormGroup style={{ display: 'flex', flexDirection: 'row' }}>
                  {data?.map((item) => {
                    return (
                      <FormControlLabel
                        label={item.act_description}
                        style={{ color: 'GrayText' }}
                        control={
                          <Checkbox
                            defaultChecked={item.id === id}
                            value={item.id}
                            onChange={(value) => {
                              console.log(value.target.value);
                              setFolders([...FoldersId, item.id]);
                            }}
                          />
                        }
                      />
                    );
                  })}
                </FormGroup>
                <Grid marginTop={4} justifyContent={'center'} textAlign="center">
                  <Button
                    variant="outlined"
                    style={{ marginRight: 5, borderRadius: '15px' }}
                    color="primary"
                    onClick={() => setOpen({ ...Open, modal: false })}
                  >
                    {t('teacherVirtualClassroom.materials.modal.button.regresar')}
                  </Button>
                  <Button
                    endIcon={StateFolder.isLoading && <CircularProgress size={18} />}
                    onClick={submitForm}
                    type="submit"
                    style={{ marginLeft: 5, borderRadius: '15px' }}
                    variant="contained"
                    color="primary"
                  >
                    {t('teacherVirtualClassroom.materials.create.save')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ModalImport>
      </Grid>

      <ModalArchivos Open={ModalArchivo} setOpen={setModalArchivo} />
      <ModalClassroomLink Open={ModalClassroomVideos} setOpen={setModalClassroomVideos} />

      <Accordion
        style={{ margin: '15px 0' }}
        sx={{
          '&:before': {
            height: '0px',
          },
        }}
        className={classes.accordeon}
      >
        {/*Carpeta principal - header */}
        <AccordeonRaiz
          title={nameFolder}
          total={childrenfiles.length + subCarpetas.length}
          handleFile={() => setModalArchivo({ id: id, modal: true, nameFolder: nameFolder })}
          handleFolder={() => setOpen({ modal: true, id: id })}
          handleVideoUrl={() =>
            setModalClassroomVideos({ id: id, modal: true, nameFolder: nameFolder })
          }
          actions={actions}
          HandleRemove={() => handleClickDeleteFolder(id)}
        >
          <AccordionDetails
            sx={{
              pr: 0,
            }}
          >
            {childrenfiles.length > 0
              ? childrenfiles.map((item) => {
                  return (
                    <ItemFileAccordeon
                      id={item.id}
                      link={item.tool_ms_link}
                      urlFile={item.tool_ms_url_file}
                      tool_ms_description={item.tool_ms_title}
                      upload_date={item.upload_date}
                      course={item.tool_ms_title}
                    />
                  );
                })
              : null}
            {/*Subcarpeta */}

            {/* {subCarpetas.length > 0
              ? subCarpetas.map((item) => {
                  return (
                    <Accordion>
                      <AccordeonRaiz
                        total={item.tools_materials.length + item.children_content.length}
                        title={item.act_description}
                        handleFile={() =>
                          setModalArchivo({
                            id: item.id,
                            modal: true,
                            nameFolder: item.act_description,
                          })
                        }
                        isChildren
                        handleFolder={() => setOpen({ modal: true, id: item.id })}
                        actions={actions}
                        HandleRemove={() => handleClickDeleteFolder(item.id)}
                      >
                        {item.tools_materials.length > 0 ? (
                          item.tools_materials.map((itemmaterial) => {
                            return (
                              <ItemFileAccordeon
                                id={itemmaterial.id}
                                urlFile={itemmaterial.tool_ms_url_file}
                                tool_ms_description={itemmaterial.tool_ms_title}
                                upload_date={itemmaterial.upload_date}
                                course={itemmaterial.tool_ms_title}
                              />
                            );
                          })
                        ) : (
                          <Typography textAlign={'center'}>No hay Informacion</Typography>
                        )}
                      </AccordeonRaiz>
                    </Accordion>
                  );
                })
              : null} */}
            {subCarpetas.length > 0 &&
              subCarpetas.map((item) => (
                <ItemAcorrdeon
                  nameFolder={item.act_description}
                  childrenfiles={item.tools_materials}
                  subCarpetas={item.children_content} //tools_materials
                  id={item.id}
                />
              ))}
            {/*item  */}
          </AccordionDetails>
        </AccordeonRaiz>
      </Accordion>
    </Grid>
  );
};

export default ItemAcorrdeon;
