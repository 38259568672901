import { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { format } from 'date-fns';

import { useTranslation } from 'react-i18next';

import SelectClient from './SelectClient';
import AddProductsTable from './AddProductsTable';
import SelectDates from './SelectDates';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import { Products } from '../../interfaces/payload.interface';
import { useGenerateDebtsOnCashPointMutation } from '../../slices/generateDebtsApiSlice';
import { resetClient, resetDate, resetProductsCollection } from '../../slices/generateDebtsSlice';
import ErrorAlert from '../../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../../shared/components/alerts/SuccessAlert';

const GenerateDebts = () => {
  const dispatch = useAppDispatch();
  const { debtor, productsCollection, currentStudentRecord, currentClient, dates } = useAppSelector(
    (state) => state.generateDebts
  );

  const { t } = useTranslation();

  const [showAlerts, setShowAlerts] = useState(true);

  const [generateDebts, { isLoading, isError, error, isSuccess }] =
    useGenerateDebtsOnCashPointMutation();

  const generateDebtHandler = async () => {
    try {
      let products: Products[] = [];

      productsCollection.forEach((pr) => {
        if (pr?.quantity === 1) {
          products.push({ product_id: pr.product?.id!, optional_product: [] });
        } else {
          let aux = pr?.quantity!;
          while (aux > 0) {
            products.push({ product_id: pr.product?.id!, optional_product: [] });
            aux--;
          }
        }
      });

      const payloadDebt = {
        student_record_id: Number(currentStudentRecord?.id),
        person_id: currentClient?.id!,
        products,
        start_date: format(dates[0]!, 'yyyy-MM-dd'),
        end_date: format(dates[1]!, 'yyyy-MM-dd'),
        debtor_id: debtor!,
      };

      await generateDebts({
        generateDebtsPayload: payloadDebt,
      }).unwrap();

      dispatch(resetClient());
      dispatch(resetDate());
      dispatch(resetProductsCollection());

      setShowAlerts(true);
    } catch (error) {
      console.log(error);

      setShowAlerts(true);
    }
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {isError && showAlerts && (
            <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
          )}

          {isSuccess && showAlerts && (
            <SuccessAlert
              message={t('financial.generate_debts.unitary.success_alert')}
              handleDismiss={() => setShowAlerts(false)}
            />
          )}
        </Grid>

        <Grid item xs={12}>
          <SelectClient currentClient={currentClient} />
        </Grid>

        <Grid item xs={12}>
          <AddProductsTable />
        </Grid>

        <Grid item xs={12}>
          <SelectDates />
        </Grid>

        <Grid item xs={12} sx={{ textAlign: 'right' }}>
          <Button
            variant="contained"
            size="large"
            onClick={generateDebtHandler}
            disabled={!productsCollection.length || isLoading}
            endIcon={isLoading && <CircularProgress size={20} />}
          >
            {t('financial.generate_debts.unitary.generate_debt_btn')}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default GenerateDebts;
