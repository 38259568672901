export const subPermissionKeys = {
  groupKey: 'applications',

  read: 'application-listar-solicitudes',
  readIntApps: 'applications-internal-homologations-listar-configuracion-homologacion-interna',
  readExtApps: 'applications-external-homologations-listar-solicitudes-homologacion-externa',
  editIntApp: 'applications-internal-homologations-cambiar-estado',
  editExtApp: 'applications-external-homologations-cambio-estado',
};

export const subPermissionKeys2 = {
  groupKey: 'internalHomologation',

  read: 'application-listar-solicitudes',
  deleteMatters: 'internal-homologation-eliminar-materias-malla-de-homologacion-interna',
  editMatters: 'internal-homologation-actualizar-materias-malla-de-homologacion-interna',
  asignMatters: 'internal-homologation-asignar-materias-malla-de-homologacion=interna',
  getMatters: 'internal-homologation-obtener-materias-malla-de-homologacion-interna',
  readMatters: 'internal-homologation-listar-materias-malla-de-homologacion-interna',
};

//external-homologations-obtener-homologacion-externa //obtener una
export const subPermissionKeys3 = {
  groupKey: 'externalHomologation',

  read: 'external-homologations-listar-homologacion-externa',
  create: 'external-homologations-crear-homologacion-externa',
  edit: 'external-homologations-actualizar-homologacion-externa',
  delete: 'external-homologations-borrar-homologacion-externa',
};
