import { mainApi } from '../../../../api/mainApi';
import { AccessState } from '../../../../types';
import { middlewareApi } from '../../../middleware/middlewareApi';
import {
  Agreement,
  AgreementsResponse,
  AgreementAvailable,
} from '../interfaces/agreements.interfaces';

export const agreementsTags = middlewareApi.enhanceEndpoints({
  addTagTypes: ['Agreements'],
});

export const agreementsApi = agreementsTags.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * @GET all agreements
     */
    getagreements: builder.query<
      AgreementsResponse,
      {
        profileId: number;
        page: number;
        perPage: number;
        search: string;
        sort?: string;
        type_sort?: string;
        statusId?: number;
        agrName?: string;
        instName?: string;
        typeAgreement?: string;
        instituteId?: number;
        startDate?: string;
        endDate?: string;
      }
    >({
      queryFn: async ({
        profileId,
        page,
        search,
        perPage,
        sort,
        type_sort,
        statusId,
        agrName,
        instituteId,
        typeAgreement,
        startDate,
        endDate,
      }) => {
        try {
          const { data } = await mainApi.get<AgreementsResponse>('/agreements', {
            params: {
              user_profile_id: profileId,
              page,
              size: perPage,
              type_sort: 'desc',
              search,
              ...(type_sort && { type_sort }),
              ...(sort && { sort }),
              ...(statusId && { status_id: statusId }),
              ...(agrName && { agr_name: agrName }),
              ...(instituteId && { institute_id: instituteId }),
              ...(typeAgreement && { type_agreement: typeAgreement }),
              ...(startDate && { agr_start_date: startDate }),
              ...(endDate && { agr_end_date: endDate }),
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'Agreements', id } as const)),
              { type: 'Agreements', id: 'LIST' },
            ]
          : [{ type: 'Agreements', id: 'LIST' }],
    }),

    /**
     * @GET agreement availables
     */
    getAgreementsAvailables: builder.query<AgreementAvailable[], { profileId: number }>({
      queryFn: async ({ profileId }) => {
        try {
          const { data } = await mainApi.get<AgreementAvailable[]>('/agreements', {
            params: {
              user_profile_id: profileId,
              data: 'all',
            },
          });

          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result, error, { profileId }) => [{ type: 'Agreements', profileId }],
    }),

    /**
     * @GET agreement by id
     */
    getAgreement: builder.query<Agreement, { profileId?: number; agreementId: number }>({
      queryFn: async ({ profileId, agreementId }, { dispatch, getState }) => {
        const {
          access: { currentProfile },
        } = getState() as { access: AccessState };
        const user_profile_id = profileId || currentProfile?.profileId;

        try {
          const { data } = await mainApi.get<Agreement>(`/agreements/${agreementId}`, {
            params: {
              user_profile_id,
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result, error, { profileId }) => [{ type: 'Agreements', profileId }],
    }),

    /**
     * @POST create agreement
     */
    addAgreement: builder.mutation<
      Agreement,
      { profileId: number; agreementPayload: Partial<Agreement> }
    >({
      queryFn: async ({ profileId, agreementPayload }) => {
        try {
          const { data } = await mainApi.post<Agreement>('/agreements', {
            user_profile_id: profileId,
            ...agreementPayload,
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'Agreements', id: 'LIST' }],
    }),

    /**
     * @PUT update agreement
     */
    updateAgreement: builder.mutation<
      Agreement,
      { profileId: number; agreementId: number; agreementPayload: Partial<Agreement> }
    >({
      queryFn: async ({ profileId, agreementId, agreementPayload }) => {
        try {
          const { data } = await mainApi.put<Agreement>(`/agreements/${agreementId}`, {
            user_profile_id: profileId,
            ...agreementPayload,
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'Agreements', id: 'LIST' }],
    }),

    /**
     * @DELETE remove agreement
     */
    deleteAgreement: builder.mutation<Agreement, { profileId: number; agreementId: number }>({
      queryFn: async ({ profileId, agreementId }) => {
        try {
          const { data } = await mainApi.delete(`/agreements/${agreementId}`, {
            data: {
              user_profile_id: profileId,
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'Agreements', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetagreementsQuery,
  useAddAgreementMutation,
  useDeleteAgreementMutation,
  useGetAgreementQuery,
  useUpdateAgreementMutation,
  useGetAgreementsAvailablesQuery,
  useLazyGetAgreementQuery,
} = agreementsApi;
