import MisionVision from '../MisionVision';

const HomeInstInfoStep = () => {
  return (
    <>
      <MisionVision />
    </>
  );
};

export default HomeInstInfoStep;
