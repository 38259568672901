import DeleteAlert from '../../../shared/components/dialogs/DeleteAlert';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { useDeleteServicesCompanyMutation } from '../slices/companiesApiSlice';
import { setServicesCompanyIdToDelete, setServicesDeleteModalOpen } from '../slices/companiesSlice';

const DialogDeleteServicesCompany = () => {
  const dispatch = useAppDispatch();

  const { servicesDeleteModalOpen, servicesCompanyIdToDelete } = useAppSelector(
    (state) => state.companies
  );

  const [deleteServicesCompany, { isLoading, isSuccess, isError, error }] =
    useDeleteServicesCompanyMutation();

  const handleDelete = () => {
    deleteServicesCompany({
      companyId: servicesCompanyIdToDelete!,
    });
  };

  const handleClose = () => {
    dispatch(setServicesDeleteModalOpen(false));
    dispatch(setServicesCompanyIdToDelete(null));
  };
  return (
    <DeleteAlert
      open={servicesDeleteModalOpen}
      onClose={handleClose}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      handleDelete={handleDelete}
      error={error as string}
    />
  );
};

export default DialogDeleteServicesCompany;
