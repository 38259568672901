import React from 'react';

import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  Slide,
  SlideProps,
  Typography,
  useTheme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { useAppSelector } from '../../../../hooks/useRedux';
import { IconInfoCircle } from '@tabler/icons';

const useStyles = makeStyles({
  paper: {
    width: 'fit-content',
    height: 'fit-content',
  },
  formContainer: {
    width: '100%',
  },
});

export const Transition = React.forwardRef(function Transition(props: SlideProps, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

interface Props {
  open: boolean;
  handleCloseDialog: () => void;
}

const NotAvailableRegisterDialog = ({ open, handleCloseDialog }: Props) => {
  const { unavailableRegisterDialog } = useAppSelector((state) => state.subjectsRegister);

  const classes = useStyles();

  const theme = useTheme();

  return (
    <Dialog open={open} onClose={handleCloseDialog} classes={{ paper: classes.paper }}>
      <DialogContent>
        <Grid
          container
          flexDirection="column"
          spacing={2}
          alignItems="center"
          justifyContent="center"
        >
          <Grid item>
            <IconInfoCircle
              size={60}
              color={theme.palette.error.main}
              style={{
                padding: 12,
                backgroundColor: theme.palette.orange.light,
                borderRadius: '50%',
              }}
            />
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Typography variant="h2" sx={{ color: theme.palette.primary.main, mb: 1 }}>
              Registro de materia
            </Typography>
            <Typography variant="body2">{unavailableRegisterDialog?.message}</Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent="center" gap={1} mt={2}>
          <Button variant="contained" size="large" color="primary" onClick={handleCloseDialog}>
            Cerrar
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default NotAvailableRegisterDialog;
