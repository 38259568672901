import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ELS } from '../interfaces/ELS.interfaces';
// import { EducationLevel } from '../../education-levels/interfaces/educationLevels.interfaces';

interface EducationLevelCareersState {
  educationLevelSubjectList: ELS[];
  educationLevelSubjectGroupList: ELS[];
  // careerGroup: EducationLevel[];
  deleteModalOpen: boolean;
  educationLevelSubjectIdToDelete?: number;
}

const initialState: EducationLevelCareersState = {
  educationLevelSubjectList: [],
  educationLevelSubjectGroupList: [],
  // careerGroup: [],
  deleteModalOpen: false,
};

const educationLevelCareersSlice = createSlice({
  name: 'EducationLevelCareer',
  initialState,
  reducers: {
    setEducationLevelCareerList: (state, action: PayloadAction<ELS[]>) => {
      state.educationLevelSubjectList = action.payload;
    },
    setEducationLevelCareerGroupList: (state, action: PayloadAction<ELS[]>) => {
      state.educationLevelSubjectGroupList = action.payload.concat(
        state.educationLevelSubjectGroupList
      );
    },
    // setCareerGroup: (state, action: PayloadAction<EducationLevel[]>) => {
    //   state.careerGroup = action.payload;
    // },
    setDeleteModalOpen: (state, action: PayloadAction<boolean>) => {
      state.deleteModalOpen = action.payload;
    },
    openDeleteModalWith: (state, action: PayloadAction<number>) => {
      state.educationLevelSubjectIdToDelete = action.payload;
      state.deleteModalOpen = true;
    },
    resetEducationLevelCareerIdToDelete: (state) => {
      state.educationLevelSubjectIdToDelete = undefined;
    },
  },
});

export const {
  setEducationLevelCareerList,
  // setCareerGroup,
  setEducationLevelCareerGroupList,
  setDeleteModalOpen,
  openDeleteModalWith,
  resetEducationLevelCareerIdToDelete,
} = educationLevelCareersSlice.actions;

export default educationLevelCareersSlice.reducer;
