export const getSubjectStatusColor = (variant: string) => {
  switch (variant.toLowerCase()) {
    case 'cursando':
      return '#ffcf3d';
    case 'aprobado':
      return '#00aaf7';
    case 'reprobado':
      return '#5b5350';
    case 'retirado':
      return '#d74114';
    case 'homologado':
      return '#ff9800';
    default:
      return '#9e9e9e';
  }
};
