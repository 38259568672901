import {
  Autocomplete,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  InputLabel,
  Skeleton,
  TextField,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useAppSelector } from '../../../../hooks/useRedux';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import { statusAvailables } from '../../../shared/constants/resourceStatus';
import { useFormStyles } from '../constants/styles';
import { InstituteInfoSchema } from '../constants/validationSchemas';
import {
  useGetAvailablesProvincesQuery,
  useGetAvailablesEconomicGroupQuery,
  useGetAvailablesTypeInstituteQuery,
  useUpdateInstituteMutation,
  useGetCategoriesOfInstitutesQuery,
} from '../slices/institutesApiSlice';
import TextFieldFetching from '../../../shared/components/TextFieldFetching';

const EditInstituteForm = () => {
  const history = useHistory();
  const { instituteId } = useParams<{ instituteId: string }>();

  const { instituteList } = useAppSelector((state) => state.institutes);
  const { currentProfile, defaultConfiguration } = useAppSelector((state) => state.access);

  const classes = useFormStyles();

  const [showAlerts, setShowAlerts] = useState(false);

  const [updateInstitute, { isLoading: isUpdating, isSuccess, isError, error }] =
    useUpdateInstituteMutation();

  const { data: typeInstituteAvailables = [], isLoading: isLoadingTI } =
    useGetAvailablesTypeInstituteQuery({
      profileId: currentProfile?.profileId!,
    });

  const { data: categoriesOfInstitutes, isFetching: isFetchingCatOfIns } =
    useGetCategoriesOfInstitutesQuery();

  const { data: provincesAvailables = [], isLoading: isLoadingP } = useGetAvailablesProvincesQuery({
    profileId: currentProfile?.profileId!,
  });

  const { data: economicGroupAvailables = [], isLoading: isLoadingEG } =
    useGetAvailablesEconomicGroupQuery({
      profileId: currentProfile?.profileId!,
    });

  const instituteToEdit = instituteList.find((p) => p.id === Number(instituteId));
  const { getFieldProps, errors, touched, handleSubmit, setFieldValue, initialValues, values } =
    useFormik({
      initialValues: {
        name: instituteToEdit?.inst_name || '',
        province_id: instituteToEdit?.province_id || '',
        economic_group_id: instituteToEdit?.economic_group_id || '',
        type_institute_id: instituteToEdit?.type_institute_id || 1,
        cat_institute_id: instituteToEdit?.cat_institute_id || 1,
        has_agreement: instituteToEdit?.has_agreement === '1' ? true : false,
        status_id: instituteToEdit?.status_id || 1,
      },
      validationSchema: InstituteInfoSchema,
      onSubmit: async (values) => {
        await updateInstitute({
          profileId: currentProfile?.profileId!,
          instituteId: instituteToEdit?.id!,
          institutePayload: {
            inst_name: values.name,
            province_id: values.province_id.toString(),
            economic_group_id: values.economic_group_id.toString(),
            type_institute_id: values.type_institute_id.toString(),
            cat_institute_id: values.cat_institute_id.toString(),
            has_agreement: values.has_agreement,
            status_id: values.status_id.toString(),
          },
        });

        setShowAlerts(true);
      },
    });
  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction="column" gap={2}>
        <Grid item>
          {isError && showAlerts && (
            <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
          )}

          {isSuccess && showAlerts && (
            <SuccessAlert
              message="El instituto ha sido actualizado correctamente."
              handleDismiss={() => setShowAlerts(false)}
            />
          )}
        </Grid>

        <Grid item container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>Nombre</InputLabel>
            <TextField
              fullWidth
              error={Boolean(errors.name && touched.name)}
              placeholder="Ingrese el nombre del instituto"
              {...getFieldProps('name')}
            />
            {errors.name && touched.name && <FormHelperText error>{errors.name}</FormHelperText>}
          </Grid>

          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>Tipo de Institutos</InputLabel>

            {isLoadingTI ? (
              <Skeleton variant="rectangular" sx={{ width: '100%', borderRadius: 3, height: 50 }} />
            ) : (
              <Autocomplete
                options={typeInstituteAvailables}
                loading={isLoadingTI}
                loadingText="Cargando tipos de institutos..."
                getOptionLabel={(option) => option.tin_name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(errors.type_institute_id && touched.type_institute_id)}
                    placeholder="Seleccione el tipo de instituto disponible"
                  />
                )}
                defaultValue={typeInstituteAvailables.find(
                  (p) => p.id === Number(instituteToEdit?.type_institute_id)
                )}
                onChange={(_, value) => setFieldValue('type_institute_id', value?.id || '')}
                fullWidth
                clearIcon={null}
              />
            )}

            {errors.type_institute_id && touched.type_institute_id && (
              <FormHelperText error>{errors.type_institute_id}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>Categoría de Instituto</InputLabel>
            {isFetchingCatOfIns ? (
              <TextFieldFetching variant="standard" />
            ) : (
              <Autocomplete
                options={categoriesOfInstitutes || []}
                getOptionLabel={(option) => option.cat_inst_name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Seleccione una categoría de instituto disponible"
                    error={Boolean(errors?.cat_institute_id && touched?.cat_institute_id)}
                  />
                )}
                value={
                  categoriesOfInstitutes
                    ? categoriesOfInstitutes?.find(
                        (dt) => dt.id === Number(values.cat_institute_id)
                      )
                    : null
                }
                onChange={(_, value) => {
                  setFieldValue('cat_institute_id', value?.id || '');
                }}
                fullWidth
              />
            )}
            {errors.cat_institute_id && touched.cat_institute_id && (
              <FormHelperText error>{errors.cat_institute_id}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>Provincia</InputLabel>

            {isLoadingP ? (
              <Skeleton variant="rectangular" sx={{ width: '100%', borderRadius: 3, height: 50 }} />
            ) : (
              <Autocomplete
                options={provincesAvailables}
                loading={isLoadingP}
                loadingText="Cargando provincias disponibles..."
                getOptionLabel={(option) => option.cat_name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(errors.province_id && touched.province_id)}
                    placeholder="Seleccione una provincia disponible"
                  />
                )}
                defaultValue={provincesAvailables.find(
                  (p) => p.id === Number(instituteToEdit?.province_id)
                )}
                onChange={(_, value) => setFieldValue('province_id', value?.id || '')}
                fullWidth
                clearIcon={null}
              />
            )}
            {errors.province_id && touched.province_id && (
              <FormHelperText error>{errors.province_id}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>Grupo económico</InputLabel>

            {isLoadingEG ? (
              <Skeleton variant="rectangular" sx={{ width: '100%', borderRadius: 3, height: 50 }} />
            ) : (
              <Autocomplete
                options={economicGroupAvailables}
                loading={isLoadingEG}
                loadingText="Cargando grupos económicos disponibles..."
                getOptionLabel={(option) =>
                  `${option.eco_gro_name} - ${option.eco_gro_description} (${defaultConfiguration?.currency_symbol}${option.eco_gro_min_amount} - ${defaultConfiguration?.currency_symbol}${option.eco_gro_max_amount})`
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(errors.economic_group_id && touched.economic_group_id)}
                    placeholder="Seleccione un grupo económico disponible"
                  />
                )}
                defaultValue={economicGroupAvailables.find(
                  (p) => p.id === Number(instituteToEdit?.economic_group_id)
                )}
                onChange={(_, value) => setFieldValue('economic_group_id', value?.id || '')}
                fullWidth
                clearIcon={null}
              />
            )}
            {errors.economic_group_id && touched.economic_group_id && (
              <FormHelperText error>{errors.economic_group_id}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>Estado</InputLabel>
            <Autocomplete
              options={statusAvailables}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(errors.status_id && touched.status_id)}
                  placeholder="Seleccione un estado disponible"
                />
              )}
              defaultValue={statusAvailables.find(
                (e) => e.id.toString() === instituteToEdit?.status_id
              )}
              onChange={(e, value) => setFieldValue('status_id', value?.id || '')}
              fullWidth
              clearIcon={null}
            />
            {errors.status_id && touched.status_id && (
              <FormHelperText error>{errors.status_id}</FormHelperText>
            )}
          </Grid>

          <Grid item container xs={12} justifyContent="flex-start">
            <FormControlLabel
              control={
                <Switch
                  defaultChecked={initialValues.has_agreement}
                  {...getFieldProps('has_agreement')}
                />
              }
              label="¿Tiene convenio?"
            />
            {errors.has_agreement && touched.has_agreement && (
              <FormHelperText error>{errors.has_agreement}</FormHelperText>
            )}
          </Grid>
        </Grid>

        <Grid
          item
          container
          alignItems="center"
          justifyContent="flex-end"
          spacing={2}
          sx={{ mt: 1 }}
        >
          <Grid item>
            <Button
              variant="outlined"
              size="large"
              color="primary"
              onClick={() => history.goBack()}
            >
              Regresar
            </Button>
          </Grid>
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              disabled={isUpdating}
              endIcon={isUpdating && <CircularProgress size={20} />}
            >
              Actualizar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default EditInstituteForm;
