import academicCounseling from './academicCounseling.json';
import applications from './applications.json';
import certificates from './certificates.json';
import courses from './courses.json';
import dashboard from './dashboard.json';
import menu from './menu.json';
import subjects from './subjects.json';
import studentVirtualClassroomTranslation from './virtualClassroom/index';

const studentTranslations = {
  ...academicCounseling,
  ...applications,
  ...certificates,
  ...courses,
  ...dashboard,
  ...menu,
  ...subjects,
  ...studentVirtualClassroomTranslation,
};

export default studentTranslations;
