import { useEffect, useState } from 'react';

import Autocomplete from '@material-ui/core/Autocomplete';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../../hooks/useRedux';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import InputSecondaryLabel from '../../../shared/components/forms/InputSecondaryLabel';
import { statusAvailables } from '../../../shared/constants/resourceStatus';
import {
  useGetAvailablesOffersQuery,
  useGetInfoOfferQuery,
} from '../../academic-offerings/slices/academicOfferingsApiSlice';
import { typePaymentsAvailables } from '../constants/optionsTypePayments';
import { useFormStyles } from '../constants/styles';
import { MatterInfoSchema } from '../constants/validationSchemas';

import { subPermissionKeys } from '../constants/permissions';
import {
  useAddMatterMutation,
  useGetAvailablesAreaQuery,
  useGetAllMattersTypesQuery,
  useGetJointAcademicsQuery,
} from '../slices/mattersApiSlice';
import TextFieldFetching from '../../../shared/components/TextFieldFetching';
// import { useGetCalificationTypesQuery } from '../../califications-types/slices/calificationsTypesApiSlice';
// import { useGetCatalogByAcronymQuery } from '../../../shared/slices/catalogApi';
import { useGetGenerateCodeSubjectQuery } from '../slices/mattersApiSlice';
import useSharedGuard from '../../../../hooks/useSharedGuard';
import { subPermissionKeys as subPermissionKeysArea } from '../../areas/constants/permissions';

const AddMatterForm = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useFormStyles();

  const { hasAccess: hasAccessMatterType } = useSharedGuard(subPermissionKeys);

  const { hasAccess: hasAccessArea } = useSharedGuard(subPermissionKeysArea);

  const { currentProfile } = useAppSelector((state) => state.access);

  const [showAlerts, setShowAlerts] = useState(true);

  const [addMatter, { isLoading, isError, error, isSuccess }] = useAddMatterMutation();

  const [payment, setPayment] = useState<{ isPay: string; type: string }>();

  // const { data: calificationTypesAvailables = [], isLoading: isLoadingCT } =
  //   useGetCalificationTypesQuery({
  //     profileId: currentProfile?.profileId!,
  //   });

  const { data: areasAvailable = [], isLoading: isLoadingA } = useGetAvailablesAreaQuery({
    profileId: currentProfile?.profileId!,
  });

  // const { data: catalogModality, isLoading: isLoadingModality } = useGetCatalogByAcronymQuery({
  //   acronym: 'M',
  // });

  const { getFieldProps, errors, touched, handleSubmit, setFieldValue, setValues, values } =
    useFormik({
      initialValues: {
        name: '',
        acronym: '',
        traslate: '',
        description: '',
        codMatterMigration: '',
        codOldMigration: '',
        typeMatter: 0,
        educationLevel: 0,
        // typeModalityId: null,
        typePayment: 'Ninguno',
        area: 0,
        offerDescription: '',
        offerId: 1,
        statusId: 1,
        jointAcademicId: 0,
      },
      validationSchema: MatterInfoSchema,
      onSubmit: async (values) => {
        await addMatter({
          profileId: currentProfile?.profileId!,
          matterPayload: {
            mat_name: values.name,
            cod_matter_migration: values.codMatterMigration,
            cod_old_migration: values.codOldMigration,
            mat_acronym: values.acronym,
            mat_translate: values.traslate,
            mat_description: values.description,
            type_matter_id: values.typeMatter.toString(),
            education_level_id: values.educationLevel.toString(),
            ...(!!values.area && { area_id: values.area.toString() }),
            status_id: values.statusId.toString(),
            mat_payment_type: values.typePayment,
            ...(values.offerDescription !== 'oferta_postgrado' && {
              joint_academic_id: values.jointAcademicId.toString(),
            }),
            // ...(values.offerDescription === 'oferta_postgrado' && {
            //   type_modality_id: values.typeModalityId,
            // }),
          },
        });
        window.scrollTo(0, 0);
        setShowAlerts(true);
      },
    });

  const { data: offersAvailable = [], isLoading: isLoadingOF } = useGetAvailablesOffersQuery({
    profileId: currentProfile?.profileId!,
  });

  const { data: jointAcademics = [], isLoading: isLoadingJointAcademics } =
    useGetJointAcademicsQuery({
      profileId: currentProfile?.profileId!,
    });

  const { data: mattersTypesAvailable = [], isLoading: isLoadingMatterTypeList } =
    useGetAllMattersTypesQuery({
      profileId: currentProfile?.profileId!,
      offerId: values.offerId.toString(),
    });

  const {
    data: offersInfoAvailable = [],
    isLoading: isLoadingOI,
    isFetching: isFetchingOI,
    isSuccess: isSuccesLoadingInfo,
    isFetching: isFetchingInfo,
  } = useGetInfoOfferQuery({
    profileId: currentProfile?.profileId!,
    offerId: values.offerId.toString(),
    filter: true,
  });

  const {
    data: generatedCode,
    isLoading: isLoadingCode,
    isFetching: isFetchingCode,
  } = useGetGenerateCodeSubjectQuery(
    {
      areaId: Number(values.area!),
    },
    { skip: !values.area }
  );

  useEffect(() => {
    if (generatedCode) {
      setFieldValue('codMatterMigration', generatedCode.codNewMigration);
      setFieldValue('codOldMigration', generatedCode.codOldMigration);
    }
  }, [generatedCode, setFieldValue]);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction="column" gap={2}>
        <Grid item xs={12} md={6}>
          <Grid container direction="column" spacing={2}>
            {isError && showAlerts && (
              <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
            )}

            {isSuccess && showAlerts && (
              <SuccessAlert
                message={t('academic.subjects.add.success')}
                handleDismiss={() => setShowAlerts(false)}
              />
            )}
            <Grid item xs={12} md={9}>
              <InputLabel className={classes.inputLabel}>
                {t('academic.subjects.add.name')}
              </InputLabel>
              <TextField
                {...getFieldProps('name')}
                error={Boolean(errors.name && touched.name)}
                placeholder={t('academic.subjects.add.name.placeholder')}
                fullWidth
              />
              {errors.name && touched.name && (
                <FormHelperText error>{t(errors.name)}</FormHelperText>
              )}
            </Grid>

            <Grid item>
              <InputLabel className={classes.inputLabel}>
                {t('academic.subjects.add.translation')}
              </InputLabel>
              <TextField
                multiline
                rows={2}
                placeholder={t('academic.subjects.add.translation.placeholder')}
                error={Boolean(errors.traslate && touched.traslate)}
                fullWidth
                {...getFieldProps('traslate')}
              />
              {errors.traslate && touched.traslate && (
                <FormHelperText error>{t(errors.traslate)}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12} md={3}>
              <InputLabel className={classes.inputLabel}>
                {t('academic.subjects.add.acronym')}
              </InputLabel>
              <TextField
                {...getFieldProps('acronym')}
                error={Boolean(errors.acronym && touched.acronym)}
                fullWidth
                placeholder={t('academic.subjects.add.acronym.placeholder')}
              />
              {errors.acronym && touched.acronym && (
                <FormHelperText error>{t(errors.acronym)}</FormHelperText>
              )}
            </Grid>

            <Grid item>
              <InputLabel className={classes.inputLabel}>
                {t('academic.subjects.add.description')}
              </InputLabel>
              <TextField
                multiline
                rows={2}
                placeholder={t('academic.subjects.add.description.placeholder')}
                error={Boolean(errors.description && touched.description)}
                fullWidth
                {...getFieldProps('description')}
              />
              {errors.description && touched.description && (
                <FormHelperText error>{t(errors.description)}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12}>
              <InputLabel className={classes.inputLabel}>
                {t('academic.subjects.add.edu_level')}
              </InputLabel>
              <Autocomplete
                options={offersAvailable}
                loading={isLoadingOF}
                loadingText={t('academic.subjects.add.edu_level.loading')}
                noOptionsText={t('academic.subjects.add.edu_level.no_data')}
                getOptionLabel={(option) => option.off_name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(errors.offerId && touched.offerId)}
                    placeholder={t('academic.subjects.add.edu_level.placeholder')}
                  />
                )}
                onChange={(_, value) => {
                  setValues({
                    ...values,
                    typeMatter: 0,
                    typePayment: '',
                  });
                  setPayment(undefined);
                  setFieldValue('educationLevel', '');

                  setFieldValue('offerId', value?.id || '');
                  setFieldValue('offerDescription', value?.off_description || '');
                  if (value?.off_description === 'oferta_postgrado') {
                    setFieldValue('jointAcademicId', null);
                  }
                }}
                fullWidth
                clearIcon={null}
              />
              {errors.offerId && touched.offerId && (
                <FormHelperText error>{t(errors.offerId)}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12}>
              {isSuccesLoadingInfo && !isFetchingInfo ? (
                <>
                  <InputLabel className={classes.inputLabel}>
                    {t('academic.subjects.add.academic_unit')}
                  </InputLabel>
                  {isFetchingOI ? (
                    <TextFieldFetching />
                  ) : (
                    <Autocomplete
                      options={offersInfoAvailable}
                      loading={isLoadingOI}
                      getOptionSelected={(option, value) => option.id === value.id}
                      loadingText={t('academic.subjects.add.academic_unit.loading')}
                      noOptionsText={t('academic.subjects.add.academic_unit.no_data')}
                      getOptionLabel={(option) => option.edu_name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={Boolean(errors.educationLevel && touched.educationLevel)}
                          placeholder={t('academic.subjects.add.academic_unit.placeholder')}
                        />
                      )}
                      value={
                        offersInfoAvailable.find((dt) => values.educationLevel === dt.id) || null
                      }
                      onChange={(e, value) => setFieldValue('educationLevel', value?.id || '')}
                      fullWidth
                      clearIcon={null}
                    />
                  )}
                  {errors.educationLevel && touched.educationLevel && (
                    <FormHelperText error>{t(errors.educationLevel)}</FormHelperText>
                  )}
                </>
              ) : (
                <>
                  <InputLabel className={classes.inputLabel}>
                    {t('academic.subjects.add.academic_unit')}
                  </InputLabel>
                  <TextField
                    disabled
                    fullWidth
                    error={Boolean(errors.educationLevel && touched.educationLevel)}
                    placeholder={t('academic.subjects.add.academic_unit.edu_level')}
                  />
                  {errors.educationLevel && touched.educationLevel && (
                    <FormHelperText error>{t(errors.educationLevel)}</FormHelperText>
                  )}
                </>
              )}
            </Grid>

            {/* {values.offerDescription === 'oferta_postgrado' && (
              <Grid item xs={12}>
                <InputLabel className={classes.inputLabel}>
                  {t('academic.subjects.add.modality.label')}
                </InputLabel>
                <Autocomplete
                  loadingText={t('academic.subjects.add.modality.loading')}
                  noOptionsText={t('academic.subjects.add.modality.noOptions')}
                  options={catalogModality || []}
                  loading={isLoadingModality}
                  getOptionLabel={(option) => option?.cat_name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(errors.typeModalityId && touched.typeModalityId)}
                      placeholder={t('academic.subjects.add.modality.placeholder')}
                    />
                  )}
                  onChange={(e, value) =>
                    setFieldValue(
                      'typeModalityId',

                      value?.id || ''
                    )
                  }
                  fullWidth
                />
                {errors.typeModalityId && touched.typeModalityId && (
                  <FormHelperText error>{errors.typeModalityId}</FormHelperText>
                )}
              </Grid>
            )} */}

            <Grid item xs={12}>
              <InputSecondaryLabel
                label={t('academic.subjects.add.area')}
                title={t('academic.subjects.add.area.add')}
                link={'/administrar-areas/agregar'}
                hasAccess={hasAccessArea('create')}
              />

              <Autocomplete
                options={areasAvailable}
                loading={isLoadingA}
                loadingText={t('academic.subjects.add.area.loading')}
                noOptionsText={t('academic.subjects.add.area.no_data')}
                getOptionLabel={(option) => option.ar_name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(errors.area && touched.area)}
                    placeholder={t('academic.subjects.add.area.placeholder')}
                  />
                )}
                onChange={(_, value) => setFieldValue('area', value?.id || '')}
                fullWidth
                clearIcon={null}
              />
              {errors.area && touched.area && (
                <FormHelperText error>{t(errors.area)}</FormHelperText>
              )}
            </Grid>

            <Grid container item xs={12} spacing={2}>
              <Grid item xs={12} md={6}>
                <InputLabel className={classes.inputLabel}>
                  {t('academic.subjects.add.current_code')}
                </InputLabel>
                {isLoadingCode || isFetchingCode ? (
                  <TextFieldFetching />
                ) : (
                  <>
                    <TextField
                      {...getFieldProps('codMatterMigration')}
                      error={Boolean(errors.codMatterMigration && touched.codMatterMigration)}
                      value={values.codMatterMigration}
                      fullWidth
                      placeholder={t('academic.subjects.add.current_code.placeholder')}
                    />
                    {errors.codMatterMigration && touched.codMatterMigration && (
                      <FormHelperText error>{t(errors.codMatterMigration)}</FormHelperText>
                    )}
                  </>
                )}
              </Grid>

              <Grid item xs={12} md={6}>
                <InputLabel className={classes.inputLabel}>
                  {t('academic.subjects.add.prev_code')}
                </InputLabel>
                {isLoadingCode || isFetchingCode ? (
                  <TextFieldFetching />
                ) : (
                  <>
                    <TextField
                      {...getFieldProps('codOldMigration')}
                      error={Boolean(errors.codOldMigration && touched.codOldMigration)}
                      value={values.codOldMigration}
                      fullWidth
                      placeholder={t('academic.subjects.add.prev_code.placeholder')}
                    />
                    {errors.codOldMigration && touched.codOldMigration && (
                      <FormHelperText error>{t(errors.codOldMigration)}</FormHelperText>
                    )}
                  </>
                )}
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <InputSecondaryLabel
                label={t('academic.subjects.add.subject_type')}
                title={t('academic.subjects.add.subject_type.add')}
                link={'/administrar-tipos-materias/agregar'}
                hasAccess={hasAccessMatterType('create')}
              />
              <Autocomplete
                // options={
                //   values.offerDescription === 'oferta_postgrado'
                //     ? mattersTypesAvailable.filter((e) => Number(e.offer_id) === values.offerId)
                //     : mattersTypesAvailable.filter((e) => !e.offer?.off_description)
                // }
                options={mattersTypesAvailable}
                loading={isLoadingMatterTypeList}
                loadingText={t('academic.subjects.add.subject_type.loading')}
                noOptionsText={t('academic.subjects.add.subject_type.no_data')}
                getOptionLabel={(option) => option.tm_name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(errors.typeMatter && touched.typeMatter)}
                    placeholder={t('academic.subjects.add.subject_type.placeholder')}
                  />
                )}
                value={mattersTypesAvailable.find((dt) => dt.id === values.typeMatter) || null}
                onChange={(_, value) => {
                  setValues({
                    ...values,
                    typeMatter: value?.id || 0,
                    typePayment:
                      value?.tm_acronym === 'nv' || values.offerDescription === 'oferta_postgrado'
                        ? typePaymentsAvailables[2]?.value
                        : typePaymentsAvailables[1]?.value,
                  });
                  // setFieldValue('typeMatter', value?.id || '');
                  // setFieldValue('typePayment', value?.id || '');
                  setPayment({ isPay: value?.tm_cobro!, type: value?.tm_acronym! } || undefined);
                }}
                fullWidth
                clearIcon={null}
              />
              {errors.typeMatter && touched.typeMatter && (
                <FormHelperText error>{t(errors.typeMatter)}</FormHelperText>
              )}
            </Grid>

            {values.offerDescription !== 'oferta_postgrado' && (
              <Grid item xs={12}>
                <InputLabel className={classes.inputLabel}>
                  {t('academic.subjects.add.joint_academic')}
                </InputLabel>
                <Autocomplete
                  options={jointAcademics}
                  loading={isLoadingJointAcademics}
                  loadingText={t('academic.subjects.add.joint_academic.loading')}
                  noOptionsText={t('academic.subjects.add.joint_academic.no_data')}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(errors.jointAcademicId && touched.jointAcademicId)}
                      placeholder={t('academic.subjects.add.joint_academic.placeholder')}
                    />
                  )}
                  onChange={(_, value) => setFieldValue('jointAcademicId', value?.id || '')}
                  multiple={false}
                  value={jointAcademics.find((e) => e.id === values.jointAcademicId)}
                  fullWidth
                  clearIcon={null}
                />
                {errors.jointAcademicId && touched.jointAcademicId && (
                  <FormHelperText error>{t(errors.jointAcademicId)}</FormHelperText>
                )}
              </Grid>
            )}

            {payment?.isPay ? (
              <Grid item xs={12}>
                <InputLabel className={classes.inputLabel}>
                  {values.offerDescription !== 'oferta_postgrado'
                    ? t('academic.subjects.add.payment_type')
                    : t('academic.subjects.add.payment_type.postgrade')}
                </InputLabel>
                <Autocomplete
                  options={typePaymentsAvailables}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(errors.typePayment && touched.typePayment)}
                    />
                  )}
                  // inputValue={}
                  // defaultValue={typePaymentsAvailables[0]}
                  value={typePaymentsAvailables.find((dt) => dt.value === values.typePayment)}
                  onChange={(e, value) => setFieldValue('typePayment', value?.value || '')}
                  fullWidth
                  clearIcon={null}
                />
                {errors.typePayment && touched.typePayment && (
                  <FormHelperText error>{t(errors.typePayment)}</FormHelperText>
                )}
              </Grid>
            ) : null}

            <Grid item xs={12}>
              <InputLabel className={classes.inputLabel}>
                {t('academic.subjects.add.status')}
              </InputLabel>
              <Autocomplete
                options={statusAvailables}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField {...params} error={Boolean(errors.statusId && touched.statusId)} />
                )}
                defaultValue={statusAvailables[0]}
                onChange={(e, value) => setFieldValue('statusId', value?.id || '')}
                fullWidth
                clearIcon={null}
              />
              {errors.statusId && touched.statusId && (
                <FormHelperText error>{t(errors.statusId)}</FormHelperText>
              )}
            </Grid>
          </Grid>

          <Grid
            item
            container
            alignItems="center"
            justifyContent="flex-end"
            spacing={2}
            sx={{ mt: 1 }}
          >
            <Grid item>
              <Button
                variant="outlined"
                size="large"
                color="primary"
                onClick={() => history.goBack()}
              >
                {t('academic.subjects.add.back')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                size="large"
                color="primary"
                disabled={isLoading}
                endIcon={isLoading && <CircularProgress size={20} />}
              >
                {t('academic.subjects.add.submit')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default AddMatterForm;
