import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import DeleteAlert from '../../../shared/components/dialogs/DeleteAlert';
import { useDeleteDocumentByBinnancleMutation } from '../slices/followUpOfResearchProjectApiSlice';

import {
  setDocumentIdByBinnacle,
  setDeleteDocumentDialog,
} from '../slices/followUpOfResearchProjectSlice';

const DeleteDocumentByBinnancleDialog = () => {
  const dispatch = useAppDispatch();

  const { deleteDocumentDialog, documentIdByBinnacle } = useAppSelector(
    (state) => state.followUpOfResearch
  );

  const handleClose = () => {
    dispatch(setDocumentIdByBinnacle(null));
    dispatch(setDeleteDocumentDialog(false));
  };

  const [deleteDocument, { isError, isLoading, isSuccess, error }] =
    useDeleteDocumentByBinnancleMutation();

  const handleDeleteFile = async () => {
    try {
      await deleteDocument({
        projectResearchDocument: documentIdByBinnacle!,
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <DeleteAlert
      open={deleteDocumentDialog}
      onClose={handleClose}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      handleDelete={handleDeleteFile}
      error={error as string}
    />
  );
};

export default DeleteDocumentByBinnancleDialog;
