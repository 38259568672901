import { Tab, Tabs } from '@material-ui/core';
import { useState } from 'react';
import { ActivityProjectResearch } from '../../../../../interfaces/tasks.interfaces';
import ExtendDialog from '../../../../ExtendDialog';
import ActivityList from '../ActivityList';
import ActivityForm from './ActivityForm';

interface TaskActivitiesDialogProps {
  activities: ActivityProjectResearch[];
  taskId: number;
  open: boolean;
  handleClose: () => void;
}

const TaskActivitiesDialog = ({ handleClose, activities, taskId }: TaskActivitiesDialogProps) => {
  console.log(activities);
  const [tabSelected, setTabSelected] = useState('list');

  const [editDisabled, setEditDisabled] = useState(true);

  const [activityIdSelected, setActivityIdSelected] = useState<number | null>(null);

  console.log(setEditDisabled);

  const changeTab = (event: React.ChangeEvent<{}>, newValue: string) => {
    if (tabSelected === 'edit') {
      setEditDisabled(true);
      setActivityIdSelected(null);
    }
    setTabSelected(newValue);
  };

  const handleEdit = (id: number) => {
    setEditDisabled(false);
    setTabSelected('edit');
    setActivityIdSelected(id);
  };

  return (
    <ExtendDialog open handleCloseDialog={handleClose} title="Actividades">
      <>
        <Tabs value={tabSelected} onChange={changeTab}>
          <Tab value="list" label="Listado" />
          <Tab value="add" label="Agregar" />
          {!editDisabled && <Tab value="edit" label="Editar" disabled={editDisabled} />}
        </Tabs>

        {tabSelected === 'list' && <ActivityList activities={activities} handleEdit={handleEdit} />}
        {tabSelected === 'add' && <ActivityForm taskId={taskId} />}
        {tabSelected === 'edit' && (
          <ActivityForm
            taskId={taskId}
            activityToEdit={activities?.find((activity) => activity.id === activityIdSelected)}
          />
        )}
      </>
    </ExtendDialog>
  );
};

export default TaskActivitiesDialog;
