import { EmissionPointPayload } from '../interfaces/financialConfiguration.interfaces';

export const emissionPointValues: EmissionPointPayload = {
  emission_point: '',
  stablishment_point: '',
  secuencial: '',
  is_manual: false,
  effective_date: new Date(),
  expiration_date: new Date(),
  start_num: 0,
  end_num: 0,
  authorization_num: '',
  type_document_keyword: '',
  campus_id: 0,
};
