import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import MainCard from '../../../shared/components/cards/MainCard';
import ClientsDiscountList from '../components/ClientsDiscountList';
const ClientScreen = () => {
  const { t } = useTranslation();

  return (
    <>
      <Grid component={MainCard} sx={{ padding: 2 }}>
        <Grid sx={{ mb: 3 }}>
          <Typography variant="h2">{t('Clientes descuento')}</Typography>
        </Grid>
        <ClientsDiscountList />
      </Grid>
    </>
  );
};

export default ClientScreen;
