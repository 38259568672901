import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISearchTable } from '../../interfaces/paginatedTable.interface';
import { EvaluationQuestions } from '../interfaces/evaluationQuestions.interface';
import {
  IAnswer,
  IUserEvaluation,
  UserEvaluationsState,
} from '../interfaces/userEvaluations.interface';

const initialState: UserEvaluationsState = {
  userEvaluationsList: [],
  userEvaluationListView: 'list',
  userEvaluationIdSelected: null,
  userEvaluationTable: {
    page: 1,
    perPage: 5,
    search: '',
  },
  evaluatedsTable: {
    page: 1,
    perPage: 5,
    search: '',
  },
  groupIdSelected: null,
  stageIdSelected: null,
  evaluationIdSelected: null,
  evaluatedIdSelected: null,

  infoEvaluationToDo: null,
  evaluationQuestionsAnswered: [],
  observationAnswer: '',
  evaluationQuestionToAnswer: 0,

  evaluationUploadDialogOpen: false,

  evaluationIncompleteQuestionsOpen: false,
};

export const userEvaluationsSlice = createSlice({
  name: 'userEvaluation',
  initialState,
  reducers: {
    setUserEvaluationsList: (state, action: PayloadAction<IUserEvaluation[]>) => {
      state.userEvaluationsList = action.payload;
    },
    setUserEvaluationIdSelected: (state, action: PayloadAction<number | null>) => {
      state.userEvaluationIdSelected = action.payload;
    },
    updateUserEvaluationTable: (state, action: PayloadAction<ISearchTable>) => {
      state.userEvaluationTable = {
        ...state.userEvaluationTable,
        ...action.payload,
      };
    },
    updateEvaluatedsTable: (state, action: PayloadAction<ISearchTable>) => {
      state.evaluatedsTable = {
        ...state.evaluatedsTable,
        ...action.payload,
      };
    },
    setGroupIdSelected: (state, action: PayloadAction<string | null>) => {
      state.groupIdSelected = action.payload;
    },
    setStageIdSelected: (state, action: PayloadAction<string | null>) => {
      state.stageIdSelected = action.payload;
    },
    setEvaluationIdSelected: (state, action: PayloadAction<string | null>) => {
      state.evaluationIdSelected = action.payload;
    },
    setEvaluatedIdSelected: (state, action: PayloadAction<string | null>) => {
      state.evaluatedIdSelected = action.payload;
    },
    setInfoEvaluationToDo: (state, action: PayloadAction<EvaluationQuestions | null>) => {
      state.infoEvaluationToDo = action.payload;
    },
    setEvaluationQuestionsAnswered: (state, action: PayloadAction<IAnswer>) => {
      const ans = state.evaluationQuestionsAnswered.find(
        (a) => a.questionId === action.payload.questionId
      );

      if (ans) {
        ans.answerId = action.payload.answerId;
      } else {
        state.evaluationQuestionsAnswered.push(action.payload);
      }
    },
    resetEvaluationQuestionsAnswered: (state) => {
      state.evaluationQuestionsAnswered = [];
    },
    setEvaluationQuestionToAnswer: (state, action: PayloadAction<number>) => {
      state.evaluationQuestionToAnswer = action.payload;
    },
    setEvaluationUploadDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.evaluationUploadDialogOpen = action.payload;
    },

    setUserEvaluationListView: (state, action: PayloadAction<'list' | 'table'>) => {
      state.userEvaluationListView = action.payload;
    },

    setObservationAnswer: (state, action: PayloadAction<string>) => {
      state.observationAnswer = action.payload;
    },

    setEvaluationIncompleteQuestionsOpen: (state, action: PayloadAction<boolean>) => {
      state.evaluationIncompleteQuestionsOpen = action.payload;
    },
  },
});

export const {
  setUserEvaluationsList,
  setUserEvaluationIdSelected,
  updateUserEvaluationTable,
  updateEvaluatedsTable,
  setGroupIdSelected,
  setStageIdSelected,
  setEvaluationIdSelected,
  setEvaluatedIdSelected,
  setInfoEvaluationToDo,
  setEvaluationQuestionsAnswered,
  resetEvaluationQuestionsAnswered,
  setEvaluationQuestionToAnswer,
  setEvaluationUploadDialogOpen,
  setUserEvaluationListView,
  setObservationAnswer,
  setEvaluationIncompleteQuestionsOpen,
} = userEvaluationsSlice.actions;
