import { useState, useEffect, useRef } from 'react';

// material-ui
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  TextField,
  useTheme,
  InputAdornment,
  IconButton,
  Collapse,
  Box,
  // Tooltip,
} from '@material-ui/core';
import type {
  TableProps,
  TableContainerProps,
  TableRowProps,
  TableBodyProps,
  TableHeadProps,
  TableCellProps,
} from '@material-ui/core';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { SerializedError } from '@reduxjs/toolkit';
import { DebounceInput } from 'react-debounce-input';
import { IconX, IconArrowNarrowDown, IconArrowNarrowUp } from '@tabler/icons';

import SearchIcon from '@material-ui/icons/Search';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import { renderErrorOrEmptyRow, renderSkeletonRows } from '../../../shared/helpers/render';

// helpers
import { objectKeys, showNumberStringWithDecimals, traverseObject } from '../../helpers';
import useFormatDate from '../../hooks/useFormatDate';

// types
import {
  CustomCellProperties,
  CustomRenderers,
  TableHeaders,
} from '../../interfaces/material-ui.interfaces';
import CustomPaginationAndItems from './CustomPaginationAndItems';
import ExportFilesMenu from './ExportFilesMenu';
import PortalAction from '../PortalAction';

const defaultPortalExportsId = 'default-table-exports-portal';

interface OnCLickI {
  // funcion trigger
  onClickFunc: (arg0: string | number) => void;
  // isSelection y selected se usaran por si se quiere shadows en la fila seleccionada
  isSelection?: boolean;
  selected?: string | number;
  /** key que identificara cual sera el parametro para la funcion y cual sera la key del match */
  funcKey: string;
}

export interface FilterArrow {
  name: string;
  direction: string;
}

interface MuiTableProps {
  table?: TableProps;
  tableContainer?: TableContainerProps;

  tableHead?: TableHeadProps;
  tableHeadRows?: TableRowProps;
  tableHeadCells?: TableCellProps;

  tableRows?: TableRowProps;
  tableCells?: TableCellProps;
  tableBody?: TableBodyProps;
}

interface MuiTableProps {
  table?: TableProps;
  tableContainer?: TableContainerProps;

  tableHead?: TableHeadProps;
  tableHeadRows?: TableRowProps;
  tableHeadCells?: TableCellProps;

  tableRows?: TableRowProps;
  tableCells?: TableCellProps;
  tableBody?: TableBodyProps;
}

export interface ExportsTypes {
  /**
   * opcion de excel
   * @default false
   * */
  excel?: boolean;
  /**
   * opcion de pdf
   * @default false
   * */
  pdf?: boolean;
}
export interface Exports<T extends {}> {
  /**
   * Nombre del archivo
   * @default 'Reporte'
   * @example 'Reporte de actividades.xlsx'
   * @example 'Reporte de actividades.pdf'
   * @example 'Reporte de actividades.csv'
   * @example 'Reporte de actividades.xls'
   * @example 'Reporte de actividades.doc'
   * @example 'Reporte de actividades.docx'
   * @example 'Reporte de actividades.txt'
   * */
  fileName?: string;

  portalId?: typeof defaultPortalExportsId | string;

  variant?: 'menu' | 'buttons' | undefined;

  files?: ExportsTypes;

  batch?: boolean;

  customHeaders?: TableHeaders<T>;

  allDataFetchHandler?: () => Promise<Omit<T, 'options'>[]>;
}
interface CollapseProps {
  numHeader: number;
}

/**
 * ## bjeto para determinar que la tabla es colapsable
 */
interface CollapsibleTable<T extends {}> {
  /**
   * index posicion a reemplazar con el icono para colapsar la tabla y
   * undefined hara referencia a que no habra boton de despligue
   * y el despliegue lo hara un click en la fila
   */
  collapsibleBtnColl?: number;

  /**
   * Filas del colapsable de la tabla
   */
  Rows: (index: number, row: T, props: CollapseProps) => JSX.Element;
}

export interface PropsSearchTable<T extends {}> {
  headers: TableHeaders<T>;
  data: T[];
  keyExtractor: (item: T) => string;
  customRenderers?: CustomRenderers<T>;
  searchPlacehoder: string;
  customDataCellsProperties?: CustomCellProperties<T>;
  customHeadersCellsProperties?: CustomCellProperties<T>;
  isLoading: boolean;
  isFetching: boolean;
  error: FetchBaseQueryError | SerializedError | undefined;
  total: number;
  perPage: number;
  /** ```undefined``` solo si no se usara paginada la tabla */
  setPerPage:
    | React.Dispatch<React.SetStateAction<number>>
    | ((perPage: number) => void)
    | undefined;
  page: number;
  numHeader?: number;
  /** ```undefined``` solo si no se usara paginada la tabla */
  setPage: React.Dispatch<React.SetStateAction<number>> | ((page: number) => void) | undefined;
  search: string;
  /** ```undefined``` solo si no se usara paginada la tabla */
  setSearch: React.Dispatch<React.SetStateAction<string>> | ((search: string) => void) | undefined;
  ActionButtons?: JSX.Element;
  actBtnFullWith?: boolean;
  replaceHeader?: boolean;
  selectedCount?: number;
  newHeader?: JSX.Element;
  showFilter?: boolean;
  listFilterArrows?: Partial<TableHeaders<T>>;
  setFilterArrow?:
    | React.Dispatch<React.SetStateAction<FilterArrow>>
    | (() => void)
    | ((filterArrow: FilterArrow) => void);
  /** Objeto con funcion y parametro a ejecutar al dar click en una fila
   * ### Interface
   * ```jsx
   * interface OnCLickI {
   * //funcion trigger
   * onClickFunc: (arg0: string | number) => void;
   *  // isSelection y selected se usaran por si se quiere sombrear la fila seleccionada
   * isSelection?: boolean;
   * selected?: string | number;
   * // key que identificara cual sera el parametro para la funcion y cual sera la key del match
   * funcKey: string;
   * }
   * ```
   */
  onClickObj?: OnCLickI;
  /** enviar false si no se quiere visualizar el numero de registros por pagina */
  perPageBox?: Boolean;
  /** enviar false si no se quiere visualizar la paginación */
  paginationBox?: Boolean;
  /** ### Props de los componentes de la tabla en base a material ui
   * ```jsx
   * interface MuiTableProps = {
   * //Todas las interfaces son extensiones del type respectivo de su componente de material ui
   *    table?: TableProps;
   *    tableContainer?: TableContainerProps;
   *
   *    tableHead?: TableHeadProps;
   *    tableHeadRows?: TableRowProps;
   *    tableHeadCells?: TableCellProps; //celdas del row head
   *
   *    tableRows?: TableRowProps;
   *    tableBody?: TableBodyProps;
   *    tableCells?: TableCellProps;
   * }
   * ```
   */
  tableProps?: MuiTableProps;

  /**
   * Nueva fila para filtros opcionales
   */
  additionalFilters?: JSX.Element;
  /**
   * Para determinar la posicion de la fila de filtros (encima o debajo del search)
   */
  filtersPos?: 'top' | 'bottom';
  filterArrow?: FilterArrow;

  /** Dat format string if exist any date */
  customDateFormat?: string;

  /**
   * Objeto para determinar que la tabla es colapsable (para validar que filas 
   * deben tener el icono de colapsar se puede validar en el custom renderer enviando null)
   * 
   * ### Interface
   * ```jsx
   * interface CollapsibleTable<T extends {}> {
   * //index posicion a reemplazar con el icono para colapsar la tabla y
   * //undefined hara referencia a que no habra boton de despligue
   * //y el despliegue lo hara un click en la fila
   * collapsibleBtnColl?: number;
   * //Filas del colapsable de la tabla
   * Rows: (index: number, row: T, props: CollapseProps) => JSX.Element;
   * }
   * ```
   * @example
   * ```jsx
   * collapsible={{
   * collapsibleBtnColl: 0,
   * Rows: (index, row, props) => {
   * return (
   * <Grid container spacing={2}>
   * <Grid item xs={12} sm={6}>
   * --- content ---
   * </Grid>
   * </Grid>
   * );},}}
   * ```
   */
  collapsible?: CollapsibleTable<T>;
  customLastRow?: JSX.Element;
  totalRowKeys?: Array<keyof T>;
  totalRowLabel?: string;
  customTotalRowLabels?: Partial<TableHeaders<T>>;

  /** Objeto para exportar la tabla
   * si se envia ```{}``` se habilitara todas las opciones de exportacion por defecto
   *
   * ### Interface
   * ```jsx
   * interface Exports {
   * //Nombre del archivo
   * fileName?: string;
   * //opcion de excel
   * excel?: boolean;
   * }
   * ```
   * @example
   * ```jsx
   * exportProps={{
   * fileName: 'Reporte de actividades',
   * files: {excel: true}
   * }}
   * ```
   *  @example
   * ```jsx
   * exportProps={{fileName: 'Reporte de actividades'}}
   * ```
   * @example
   * ```jsx
   * exportProps={{}}
   * ```
   *
   */
  exportProps?: Exports<T>;
}

/**
 * Componente de tabla con paginacion y busqueda
 * @param data - array de datos a mostrar
 * @param headers - array de headers de la tabla
 * @param customRenderers - objeto con funciones para renderizar celdas
 * @param keyExtractor - funcion para extraer la key de cada objeto
 * @param searchPlacehoder - placeholder del input de busqueda
 * @param customDataCellsProperties - objeto con propiedades de estilos para las celdas de datos
 * @param customHeadersCellsProperties  - objeto con propiedades de estilos para las celdas de headers
 * @param numHeader - numero de headers que tendra la tabla
 * @param isLoading - booleano para determinar si se esta cargando los datos
 * @param isFetching - booleano para determinar si se esta cargando mas datos
 * @param error - error de la peticion
 * @param perPage - numero de registros por pagina
 * @param setPerPage - funcion para cambiar el numero de registros por pagina
 * @param total - numero total de registros
 * @param page - numero de pagina actual
 * @param setPage - funcion para cambiar la pagina actual
 * @param search - string de busqueda
 * @param setSearch - funcion para cambiar el string de busqueda
 * @param ActionButtons - componente de botones de accion
 * @param actBtnFullWith - booleano para determinar si los botones de accion ocuparan todo el ancho de la tabla
 * @param replaceHeader - booleano para determinar si se reemplazara el header de la tabla
 * @param newHeader - componente para reemplazar el header de la tabla
 * @param showFilter - booleano para determinar si se mostrara el filtro de busqueda
 * @param listFilterArrows - objeto con los keys de los headers que tendran el filtro
 * @param setFilterArrow - funcion para cambiar el filtro de busqueda
 * @param onClickObj - objeto con la funcion y parametro a ejecutar al dar click en una fila
 * @param perPageBox - booleano para determinar si se mostrara el selector de registros por pagina
 * @param paginationBox - booleano para determinar si se mostrara la paginacion
 * @param tableProps - objeto con props de los componentes de la tabla en base a material ui
 * @param additionalFilters - nueva fila para filtros opcionales
 * @param filtersPos - para determinar la posicion de la fila de filtros (encima o debajo del search)
 * @param filterArrow - objeto con el filtro de busqueda
 * @param customDateFormat - formato de fecha si existe alguna fecha en los datos
 * @param collapsible - objeto con las propiedades para el componente de tabla colapsable
 * @param customLastRow - componente para agregar una fila al final de la tabla
 *
 */
const SearchPaginatedTable = <T extends {}>({
  data,
  headers,
  customRenderers,
  keyExtractor,
  searchPlacehoder,
  customDataCellsProperties,
  customHeadersCellsProperties,
  // numHeader,
  isLoading,
  isFetching,
  error,
  perPage,
  setPerPage = () => {},
  total,
  page,
  setPage = () => {},
  search,
  setSearch = () => {},
  ActionButtons,
  actBtnFullWith,
  replaceHeader,
  // selectedCount,
  newHeader,
  showFilter = true,
  listFilterArrows = {} as Partial<TableHeaders<T>>,
  setFilterArrow: setFilterArrowDispatch,
  onClickObj,
  perPageBox = true,
  paginationBox = true,
  tableProps,
  additionalFilters,
  filtersPos = 'bottom',
  filterArrow: customFilterArrow,
  customDateFormat,
  collapsible,
  customLastRow,
  totalRowKeys,
  totalRowLabel = 'Total: ',
  exportProps,
  customTotalRowLabels,
}: PropsSearchTable<T>) => {
  const tableRef = useRef<any>(null);

  const numHeader = Object.keys(headers).length;

  const theme = useTheme();

  // temp search
  const [searchFirst, setSearchFirst] = useState(search);

  const { formatTableDate } = useFormatDate();

  useEffect(() => {
    if (page === 1) {
      //set search only if page is 1 -> page change to one on search input
      setSearch(searchFirst);
    }
  }, [page, searchFirst, setSearch]);

  const renderTotalRow = (row: T) => {
    return (
      <TableRow key="total">
        {objectKeys(row).map((cell) => {
          if (cell === 'id') {
            return null;
          }

          if (!totalRowKeys?.includes(cell)) {
            return (
              <TableCell align="center" key={`${keyExtractor(row)}-${cell as string}`}></TableCell>
            );
          }

          if (isNaN(Number(row[cell]))) {
            return (
              <TableCell align="center" key={`${keyExtractor(row)}-${cell as string}`}></TableCell>
            );
          }

          const total = data?.reduce((acc, curr) => {
            return acc + (!isNaN(Number(curr[cell])) ? Number(curr[cell]) : 0);
          }, 0);

          return (
            <TableCell align="center" key={`${keyExtractor(row)}-${cell as string}`}>
              <b>
                {customTotalRowLabels?.[cell as keyof T] || totalRowLabel}
                {showNumberStringWithDecimals(total)}
              </b>
            </TableCell>
          );
        })}
      </TableRow>
    );
  };

  const renderRows = () => {
    try {
      return (
        <>
          {data!.map((row, i) => (
            <RenderRow key={`parentRow-${i}`} position={i} row={row} />
          ))}
          {totalRowKeys && totalRowKeys.length > 0 && renderTotalRow(data[0])}
        </>
      );
    } catch (error) {
      console.log(error);
    }
  };

  const [filterArrow, setFilterArrow] = useState<FilterArrow>(customFilterArrow || null!);

  const handleFilterArrowDown = (name: string, direction: string, nameHeader: string) => {
    setFilterArrow({ name: nameHeader, direction });
    if (setFilterArrowDispatch) setFilterArrowDispatch({ name, direction });
  };

  const handleFilterArrowUp = (name: string, direction: string, nameHeader: string) => {
    setFilterArrow({ name: nameHeader, direction });
    if (setFilterArrowDispatch) setFilterArrowDispatch({ name, direction });
  };

  const RenderRow = (props: { row: T; position: number }) => {
    const [open, setOpen] = useState(false);
    const [index, setIndex] = useState<number>(-1);
    const { row, position } = props;

    const openCollHandler = () => {
      setIndex(!open ? position : -1);
      setOpen(!open);
    };

    return (
      <>
        <TableRow
          {...(tableProps && { ...tableProps.tableRows })}
          className="table-row"
          key={keyExtractor(row)}
          {...((onClickObj || (collapsible && collapsible.collapsibleBtnColl === undefined)) && {
            hover: true,
            onClick: () => {
              if (collapsible?.collapsibleBtnColl === undefined) openCollHandler();

              if (onClickObj)
                onClickObj.onClickFunc(
                  traverseObject(row, onClickObj.funcKey) as unknown as string
                );
            },
            sx: {
              cursor: 'pointer',
              ...((onClickObj?.isSelection || collapsible?.collapsibleBtnColl === undefined) && {
                backgroundColor:
                  (onClickObj &&
                    (onClickObj?.selected as unknown) ===
                      (traverseObject(row, onClickObj.funcKey) as unknown)) ||
                  open
                    ? '#eee'
                    : '',
              }),
            }, //color provisional
          })}
        >
          {objectKeys(row).map((cell, i) => {
            const customRenderer = customRenderers?.[cell];

            const returnCustomRendered = () => (
              <TableCell
                {...(tableProps && { ...tableProps.tableCells })}
                key={`${keyExtractor(row)}-${String(cell)}`}
                {...customDataCellsProperties?.[cell]}
              >
                {customRenderer?.(row)}
              </TableCell>
            );

            // si se decide reemplazar una columna con el icono de colapsar
            if (
              collapsible?.collapsibleBtnColl !== undefined &&
              collapsible?.collapsibleBtnColl === i
            ) {
              if (customRenderer && customRenderer?.(row) === null) {
                return returnCustomRendered();
              }

              return (
                <TableCell key={`${keyExtractor(row)}-rowtrigger`} sx={{ pl: 3 }} width="5%">
                  <IconButton aria-label="expand row" size="small" onClick={openCollHandler}>
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </IconButton>
                </TableCell>
              );
            }

            if (customRenderer) {
              return returnCustomRendered();
            }

            return (
              <TableCell
                {...(tableProps && { ...tableProps.tableCells })}
                key={`${keyExtractor(row)}-${String(cell)}`}
                {...customDataCellsProperties?.[cell]}
              >
                {formatTableDate(row[cell], customDateFormat)}
              </TableCell>
            );
          })}

          {/* Si no se reemplaza el arrow collapsable por defecto saldra en la ultima columan */}
          {collapsible?.collapsibleBtnColl === -1 && (
            <TableCell key={`${keyExtractor(row)}-rowtrigger`} sx={{ pl: 3 }} width="5%">
              <IconButton aria-label="expand row" size="small" onClick={openCollHandler}>
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell>
          )}
        </TableRow>

        {collapsible && (
          <TableRow className="table-row" key={`${keyExtractor(row)}-collapsiblerow`}>
            <TableCell
              style={{ paddingBottom: 0, paddingTop: 0 }}
              colSpan={numHeader}
              sx={{ backgroundColor: theme.palette.primary.light }}
            >
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Box p={4}>
                  {collapsible && collapsible.Rows(index, row, { numHeader: numHeader || 0 })}
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        )}
      </>
    );
  };

  return (
    <>
      <PortalAction containerId={exportProps?.portalId || defaultPortalExportsId}>
        <ExportFilesMenu
          tableRef={tableRef.current}
          fileName={exportProps?.fileName}
          files={exportProps?.files}
          variant={exportProps?.variant}
          batch={exportProps?.batch}
          headers={exportProps?.customHeaders || headers}
          allDataFetchHandler={exportProps?.allDataFetchHandler}
        />
      </PortalAction>

      <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
        {additionalFilters && filtersPos === 'top' && (
          <Grid item xs={12}>
            {additionalFilters}
          </Grid>
        )}

        <Grid item xs={12} sm={4}>
          {showFilter && (
            <DebounceInput
              autoFocus={Boolean(search)}
              minLength={2}
              debounceTimeout={300}
              onChange={({ target }) => {
                setPage(1);
                setSearchFirst(target.value);
                // setSearch(target.value);
              }}
              value={search}
              element={(props) => (
                <TextField
                  style={{
                    width: '100%',
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon fontSize="small" />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          onClick={() => {
                            setSearchFirst('');
                            setSearch('');
                          }}
                          {...(!search && { sx: { cursor: 'initial', opacity: 0 } })}
                        >
                          <IconX size={20} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  placeholder={searchPlacehoder}
                  variant="outlined"
                  size="small"
                  {...props}
                />
              )}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={!actBtnFullWith && 6}>
          <Grid container alignItems="center" justifyContent="flex-end" spacing={2}>
            {exportProps &&
              (!exportProps.portalId || exportProps.portalId === defaultPortalExportsId) && (
                <Grid item xs={'auto'} id={defaultPortalExportsId}></Grid>
              )}
            <Grid item xs={'auto'}>
              {ActionButtons}
            </Grid>
          </Grid>
        </Grid>

        {additionalFilters && filtersPos === 'bottom' && (
          <Grid item xs={12}>
            {additionalFilters}
          </Grid>
        )}
      </Grid>
      <TableContainer
        {...(tableProps && { ...tableProps.tableContainer })}
        {...(replaceHeader && !tableProps?.tableContainer && { sx: { mt: 2 } })}
      >
        <Table ref={tableRef} {...(tableProps && { ...tableProps.table })}>
          <TableHead {...(tableProps && { ...tableProps.tableHead })}>
            <TableRow {...(tableProps && { ...tableProps.tableHeadRows })}>
              {replaceHeader && <>{newHeader}</>}
              {!replaceHeader && (
                <>
                  {Object.keys(headers).map((key) => (
                    <TableCell
                      key={key}
                      {...(tableProps && { ...tableProps.tableHeadCells })}
                      {...customHeadersCellsProperties?.[key as keyof T]}
                    >
                      <Grid container justifyContent="center" alignItems="center">
                        <Grid item>{headers[key as keyof {}]}</Grid>
                        {Object.keys(listFilterArrows).some((e) => e === key) && (
                          <Grid item>
                            <IconArrowNarrowDown
                              style={{
                                color:
                                  filterArrow?.direction === 'asc' &&
                                  filterArrow?.name === listFilterArrows?.[key as keyof {}]
                                    ? theme.palette.primary.main
                                    : 'inherit',
                                cursor: 'pointer',
                              }}
                              stroke={
                                filterArrow?.direction === 'asc' &&
                                filterArrow?.name === listFilterArrows[key as keyof {}]
                                  ? 2
                                  : 1
                              }
                              size={18}
                              onClick={() =>
                                handleFilterArrowUp(
                                  listFilterArrows[key as keyof {}],
                                  'asc',
                                  listFilterArrows[key as keyof {}]
                                )
                              }
                            />
                            <IconArrowNarrowUp
                              style={{
                                color:
                                  filterArrow?.direction === 'desc' &&
                                  filterArrow?.name === listFilterArrows[key as keyof {}]
                                    ? theme.palette.primary.main
                                    : 'inherit',
                                cursor: 'pointer',
                              }}
                              stroke={
                                filterArrow?.direction === 'desc' &&
                                filterArrow?.name === listFilterArrows[key as keyof T]
                                  ? 2
                                  : 1
                              }
                              size={18}
                              onClick={() =>
                                handleFilterArrowDown(
                                  listFilterArrows[key as keyof {}],
                                  'desc',
                                  listFilterArrows[key as keyof {}]
                                )
                              }
                            />
                          </Grid>
                        )}
                      </Grid>
                    </TableCell>
                  ))}

                  {collapsible?.collapsibleBtnColl === -1 && <TableCell></TableCell>}
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody {...(tableProps && { ...tableProps.tableBody })}>
            {isFetching ? renderSkeletonRows(perPage, numHeader) : renderRows()}

            {error && renderErrorOrEmptyRow(numHeader, error as string)}
            {data?.length === 0 && !isFetching && renderErrorOrEmptyRow(numHeader)}
            {customLastRow && customLastRow}
          </TableBody>
        </Table>
      </TableContainer>
      <CustomPaginationAndItems
        error={error}
        isLoading={isLoading}
        total={total}
        perPage={perPage}
        page={page}
        setPage={setPage}
        setPerPage={setPerPage}
        perPageBox={perPageBox}
        paginationBox={paginationBox}
      />
    </>
  );
};

export default SearchPaginatedTable;
