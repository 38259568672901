import { mainApi } from '../../../../api/mainApi';
import { AccessState } from '../../../../types';
import { middlewareApi } from '../../../middleware/middlewareApi';
import { IResponse } from '../../interfaces/response.interface';
import { EvaluationByEvaluated } from '../interfaces/evaluationByEvaluated.interface';
import { EvaluationQuestions, EvaluationUpload } from '../interfaces/evaluationQuestions.interface';
import { IEvaluationToDo } from '../interfaces/evaluationToDo.interface';
import {
  EvaluationPendingResponse,
  IEvaluated,
  UserEvaluation,
} from '../interfaces/userEvaluations.interface';

export const userEvaluationsTags = middlewareApi.enhanceEndpoints({
  addTagTypes: ['USER_EVALUATION'],
});

export const userEvaluationsApi = userEvaluationsTags.injectEndpoints({
  endpoints: (builder) => ({
    getUserEvaluations: builder.query<
      IResponse<UserEvaluation>,
      {
        page: number;
        perPage: number;
        search: string;
        sort?: string;
        typeSort?: string;
      }
    >({
      queryFn: async ({ sort, typeSort, page, perPage, search }, { dispatch, getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as {
            access: AccessState;
          };

          const user_profile_id = currentProfile?.profileId;

          const { data } = await mainApi.get<IResponse<UserEvaluation>>(
            `eva/evaluations/evaluator/evaluated`,
            {
              params: {
                page,
                size: perPage,
                search,
                ...(sort && { sort }),
                ...(typeSort && { type_sort: typeSort }),
                user_profile_id,
              },
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: [{ type: 'USER_EVALUATION', id: 'LIST' }],
    }),

    getEvaluatedsByGroupAndStage: builder.query<
      IResponse<IEvaluated>,
      {
        groupId: string;
        stageId: string;
        sort?: string;
        typeSort?: string;
      }
    >({
      queryFn: async ({ groupId, stageId, sort, typeSort }, { dispatch }) => {
        try {
          const { data } = await mainApi.get<IResponse<IEvaluated>>(
            `/eva/people/group/${groupId}/term/${stageId}`,
            {
              params: {
                ...(sort && { sort }),
                ...(typeSort && { type_sort: typeSort }),
              },
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: [{ type: 'USER_EVALUATION', id: 'LIST' }],
    }),

    getEvaluationToDo: builder.query<EvaluationQuestions, { id: string }>({
      queryFn: async ({ id }, { dispatch, getState }) => {
        try {
          const { data } = await mainApi.get<EvaluationQuestions[]>(
            `/eva/evaluations/get-question-evaluator/${id}`
          );

          return { data: data?.[0] || null! };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    uploadEvaluation: builder.mutation<IResponse<IEvaluationToDo>, EvaluationUpload>({
      queryFn: async (payload) => {
        try {
          const { data } = await mainApi.post<IResponse<IEvaluationToDo>>(
            `/eva/evaluations/save-evaluation-of-evaluator`,
            payload
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'USER_EVALUATION', id: 'LIST' }],
    }),

    useGetEvaluationByEvaluated: builder.query<
      IResponse<EvaluationByEvaluated>,
      {
        page: number;
        perPage: number;
        search: string;
        sort?: string;
        typeSort?: string;
      }
    >({
      queryFn: async ({ sort, typeSort, page, perPage, search }) => {
        try {
          const { data } = await mainApi.get<IResponse<EvaluationByEvaluated>>(
            `eva/evaluations/evaluator/evaluated`,
            {
              params: {
                page,
                size: perPage,
                search,
                ...(sort && { sort }),
                ...(typeSort && { type_sort: typeSort }),
              },
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: [{ type: 'USER_EVALUATION', id: 'LIST' }],
    }),

    getUserPendingEvaluationsCount: builder.query<
      EvaluationPendingResponse,
      {
        profileId: number;
      }
    >({
      queryFn: async () => {
        try {
          const { data } = await mainApi.get<EvaluationPendingResponse>(
            `eva/evaluations/get-number-evaluation-pending`
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: [{ type: 'USER_EVALUATION', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetUserEvaluationsQuery,
  useGetEvaluatedsByGroupAndStageQuery,
  useGetEvaluationToDoQuery,
  useUploadEvaluationMutation,
  useUseGetEvaluationByEvaluatedQuery,
  useGetUserPendingEvaluationsCountQuery,
} = userEvaluationsApi;
