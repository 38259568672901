/* import {
    useHistory,
    Link,
    useRouteMatch
} from 'react-router-dom'; */
import {
  Autocomplete,
  //Checkbox,
  Button,
  Grid,
  Typography,
  TextField,
  /* TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Table,
    CircularProgress,
    FormHelperText,
    Collapse,
    Box,
    useTheme, */
  // FormControlLabel,
} from '@material-ui/core';
//import EditButton from '../../../shared/components/tables/buttons/EditButton';
import DeleteButton from '../../../shared/components/tables/buttons/DeleteButton';
//import AddButton from '../../../shared/components/tables/buttons/AddButton';

import { openDeleteModalWith } from '../slices/lendingsSlice';
import useGuard from '../hooks/useGuard';
import { DatePicker, LocalizationProvider } from '@material-ui/lab';
import esLocale from 'date-fns/locale/es';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
//import StatusChip from '../../../shared/components/badges/StatusChip';
import SearchPaginatedTable from '../../../shared/components/tables/SearchPaginatedTable';
import { useAppDispatch } from '../../../../hooks/useRedux';
import InputLabel from '../../../shared/components/forms/InputLabel';
import { useState } from 'react';

const BookingTable = () => {
  const dispatch = useAppDispatch();

  const { hasAccess } = useGuard();
  //const { path } = useRouteMatch();
  //const history = useHistory();

  const handleDelete = async (periodId: number) => {
    dispatch(openDeleteModalWith(periodId));
  };

  /* const goToAdd = () => {
        history.push(`${path}/agregar`);
    }; */

  const [displayFilters, setDisplayFilters] = useState(true);

  const data = [
    {
      id: 1,
      user: 'Usuario',
      type_user: 'Estudiante',
      code: '2021536',
      author: 'Autor',
      title: 'Titulo',
      source: 'Web',
      total: '1',
      date: '20/10/2022',
      options: '',
    },
    {
      id: 1,
      user: 'Usuario',
      type_user: 'Estudiante',
      code: '2021536',
      author: 'Autor',
      title: 'Titulo',
      source: 'Web',
      total: '2',
      date: '20/10/2022',
      options: '',
    },
    {
      id: 1,
      user: 'Usuario',
      type_user: 'Estudiante',
      code: '2021536',
      author: 'Autor',
      title: 'Titulo',
      source: 'Web',
      total: '3',
      date: '20/10/2022',
      options: '',
    },
  ];

  return (
    <>
      <Grid item xs={12} hidden={!displayFilters}>
        <Grid container direction="column" gap={2}>
          <Grid item container spacing={2} alignItems="center">
            <Grid item xs={12} md={6}>
              <InputLabel>Fecha de Salida de Prestamo</InputLabel>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                <DatePicker
                  value=""
                  onChange={(newValue) => {
                    /* const value = newValue as any;
                                        setFieldValue('pers_date_birth', value);
                  
                                        if (value ? value!.getTime() > 0 : false) {
                                          setInputsData((prevState) => {
                                            return {
                                              ...prevState,
                                              pers_date_birth: format(value, 'yyyy-MM-dd'),
                                            };
                                          });
                                        } */
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={false}
                      //error={Boolean(errors.pers_date_birth && touched.pers_date_birth)}
                      helperText=""
                      placeholder="Fecha de creación"
                      fullWidth
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel>Persona</InputLabel>
              <Autocomplete
                options={[]}
                placeholder="Seleccione Persona..."
                //getOptionLabel={option => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    //error={Boolean(errors.statusId && touched.statusId)}
                    placeholder="Seleccione Persona"
                  />
                )}
                //defaultValue={}
                onChange={
                  (e, value) => {}
                  //setFieldValue('statusId', value?.id || '')
                }
                fullWidth
                clearIcon={null}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {displayFilters && (
        <Grid
          item
          container
          alignItems="center"
          justifyContent="flex-end"
          spacing={2}
          sx={{ mt: 1 }}
        >
          <Grid item>
            <Button
              size="large"
              onClick={() => {
                setDisplayFilters(false);
              }}
              //disabled={!searchBtn}
            >
              Ocultar filtros
            </Button>
            <Button
              variant="contained"
              size="large"
              color="primary"
              onClick={() => {
                /* if (filtersSearch) {
                                    refetchClassroomData();
                                } else {
                                    setDisplayFilters(false);
                                }
                                setFiltersSearch(true); */
              }}
              //disabled={!searchBtn}
            >
              Buscar
            </Button>
          </Grid>
        </Grid>
      )}
      {/**Filtros Sleccionados - Solo Lectura */}
      {!displayFilters && (
        <Grid
          container
          item
          xs={12}
          sx={{ border: `1px solid #008DE1`, borderRadius: '5px', py: '10px', px: '14px' }}
        >
          <Grid container item md={12} lg={10} alignContent={'center'} spacing={2}>
            <Grid item xs={12} sm={6} md={6} lg={'auto'}>
              <InputLabel sx={{ m: '0px!important' }}>
                Fecha de Salida de Prestamo:{' '}
                <Typography variant={'h5'} component={'span'}></Typography>
              </InputLabel>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={'auto'}>
              <InputLabel sx={{ m: '0px!important' }}>
                Persona: <Typography variant={'h5'} component={'span'}></Typography>
              </InputLabel>
            </Grid>
          </Grid>
          <Grid container item xs={12} lg={2} justifyContent={'flex-end'}>
            <Button onClick={() => setDisplayFilters(true)}>Editar distribución</Button>
          </Grid>
        </Grid>
      )}
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item md={12} sx={{ borderTop: '1px solid gainsboro' }}>
          <SearchPaginatedTable
            data={data}
            headers={{
              id: 'Código',
              user: 'Usuario',
              type_user: 'Tipo de Usuario',
              code: 'Código de Libro',
              author: 'Autor',
              title: 'Titulo',
              source: 'Fuente',
              total: 'Total',
              date: 'Fecha',
              options: 'Opciones',
            }}
            keyExtractor={(item) => item.id.toString()}
            searchPlacehoder="Buscar prestamo"
            isLoading={false}
            isFetching={false}
            error={undefined}
            perPage={1}
            setPerPage={() => {}}
            total={0}
            page={1}
            setPage={() => {}}
            numHeader={7}
            search={''}
            setSearch={() => {}}
            customRenderers={{
              // id: (row) => row.id.id,

              options: (row) => (
                <>
                  {hasAccess('delete') && <DeleteButton handleDelete={() => handleDelete(1)} />}
                  {!hasAccess('edit') && !hasAccess('delete') && 'Ninguna'}
                </>
              ),
            }}
            //ActionButtons={<>{hasAccess('create') && <AddButton size="large" onClick={goToAdd} />}</>}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default BookingTable;
