import { Grid, Typography } from '@material-ui/core';
import MainCard from '../../../../shared/components/cards/MainCard';
import Docentes from '../../components/presenciales/Docentes';

const DocentesScreen = () => {
  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">Docentes</Typography>
      </Grid>
      <Docentes />
    </Grid>
  );
};

export default DocentesScreen;
