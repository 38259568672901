import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import DeleteAlert from '../../../shared/components/dialogs/DeleteAlert';
import { useDeleteCourseStudentMutation } from '../slices/subjectsRegisterApiSlice';
import {
  resetCourseStudentIdToDelete,
  setCurrentSubjectsRegistered,
  setDeleteModalOpen,
} from '../slices/subjectsRegisterSlice';

const DeleteCourseStudentDialog = () => {
  const dispatch = useAppDispatch();

  const { deleteModalOpen, courseStudentIdToDelete, currentSubjectsRegistered } = useAppSelector(
    (state) => state.subjectsRegister
  );

  const [deleteCourse, { isLoading, isSuccess, isError, error }] = useDeleteCourseStudentMutation();

  const handleDelete = () => {
    deleteCourse({ courseStudentId: courseStudentIdToDelete! });
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch(
        setCurrentSubjectsRegistered(
          (currentSubjectsRegistered || [])?.filter((c) => c.id !== courseStudentIdToDelete)
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, dispatch]);

  const handleClose = () => {
    dispatch(setDeleteModalOpen(false));
    dispatch(resetCourseStudentIdToDelete());
  };

  return (
    <DeleteAlert
      open={deleteModalOpen}
      onClose={handleClose}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      handleDelete={handleDelete}
      error={error as string}
    />
  );
};

export default DeleteCourseStudentDialog;
