import { useState, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Tooltip, IconButton } from '@material-ui/core';
import { IconLayoutList } from '@tabler/icons';

import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import StatusChip from '../../../../shared/components/badges/StatusChip';
import EditButton from '../../../../shared/components/tables/buttons/EditButton';
import DeleteButton from '../../../../shared/components/tables/buttons/DeleteButton';
import AddButton from '../../../../shared/components/tables/buttons/AddButton';
import {
  updateProjectPrincipalTableStates,
  openDeleteModalWithProject,
  setProjectPrincipalList,
} from '../../slices/investigationLinesSlice';
import SearchPaginatedTable from '../../../../shared/components/tables/SearchPaginatedTable';
import { useTableSearch } from '../../../../../hooks/useTableSearch';
import { ProjectPrincipalResponse } from '../../interfaces/projectPrincipal.interfaces';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { SerializedError } from '@reduxjs/toolkit';
import { statusAvailables } from '../../../../shared/constants/resourceStatus';
import ExtendDialog from '../ExtendDialog';
import OmniChipAcronym from '../../../../shared/components/badges/OmniChipAcronym';
import StatusProject from '../../constants/lists/statusProject';
import ListAssignPersonDelete from './TabAssignPerson';
import ListDocument from './lateral-dialogs/ListDocument';
import { FilterData } from './index';
import ProjectPrincipalForm from './forms/ProjectPrincipalForm';
import DeleteProjectPrincipalDialog from './DeleteProjectPricipalDialog';

interface Props {
  data: ProjectPrincipalResponse;
  isLoading: boolean;
  isFetching: boolean;
  error: FetchBaseQueryError | SerializedError | undefined;
  setSort: React.Dispatch<React.SetStateAction<FilterData>>;
  sort: FilterData;
  isStage?: boolean;
}

const ProjectPrincipalTable = ({
  data,
  isLoading,
  isFetching,
  error,
  setSort,
  sort,
  isStage,
}: Props) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { path } = useRouteMatch();

  const history = useHistory();

  const [openDialog, setOpenDialog] = useState(false);

  const [openDialogAssignPerson, setOpenDialogAssignPerson] = useState(false);

  const [openDialogDocument, setOpenDialogDocument] = useState(false);

  const [projectToEdit, setProjectToEdit] = useState('');

  // const [filterArrow, setFilterArrow] = useState(sort.project);

  const { page, perPage, search, filterArrow } = useAppSelector(
    (state) => state.investigationLines.projectPrincipalTable
  );

  const { deleteModalOpenProject } = useAppSelector((state) => state.investigationLines);

  const { setPage, setPerPage, setSearch, setFilterArrow } = useTableSearch({
    page,
    perPage,
    search,
    updateFunction: updateProjectPrincipalTableStates,
  });

  const handleDelete = async (projectId: string) => {
    dispatch(openDeleteModalWithProject(projectId));
  };

  const handleOpenDialog = (projectId: string) => {
    setOpenDialog(true);
    setProjectToEdit(projectId);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleCloseDialogAssignPerson = () => {
    setOpenDialogAssignPerson(false);
  };

  const handleCloseDialogDocument = () => {
    setOpenDialogDocument(false);
  };

  const goToAdd = () => {
    history.push(`${path}/crear/crear-proyecto`);
  };

  const goToStages = (projectId: string) => {
    history.push(`proyecto/${projectId}/etapas`);
  };

  const datafetch = data?.data!.map((d, index) => ({
    id: { index: index + 1 + (page - 1) * perPage, id: d.id },
    name: d.name,
    observation: d.observation,
    startDate: d.start_date,
    endDate: d.end_date,
    statusAcronym: d.status_id,
    statusProject: d.status_project,
    options: '',
  }));

  useEffect(() => {
    if (data) {
      dispatch(setProjectPrincipalList(data.data));
    }
  }, [data, dispatch]);

  // useEffect(() => {
  //   setSort((state) => ({ ...state, project: filterArrow }));
  //   //eslint-disable-next-line
  // }, [filterArrow]);

  return (
    <>
      <SearchPaginatedTable
        data={datafetch!}
        headers={{
          id: t('N°'),
          name: t('academic.subjects.name'),
          observation: 'Observación',
          startDate: 'Fecha de inicio',
          endDate: 'Fecha fin',
          statusAcronym: t('academic.subjects.status'),
          statusProject: 'Estado del proyecto',
          options: t('academic.subjects.options'),
        }}
        keyExtractor={(item) => item.id.id.toString()}
        searchPlacehoder={t('lines_research.index.investigationProjectFilter.search.project')}
        isLoading={isLoading}
        isFetching={isFetching}
        error={error}
        perPage={perPage}
        setPerPage={setPerPage}
        total={data?.total || 0}
        page={page}
        setPage={setPage}
        numHeader={7}
        search={search}
        listFilterArrows={{
          name: 'name',
          statusProject: 'status_project',
          startDate: 'start_date',
          endDate: 'end_date',
          statusAcronym: 'status_id',
        }}
        filterArrow={filterArrow}
        setFilterArrow={setFilterArrow}
        setSearch={setSearch}
        customRenderers={{
          id: (row) => row.id.index,

          statusAcronym: (row) => (
            <StatusChip
              statusId={
                statusAvailables.find((status) => status.id.toString() === row.statusAcronym)?.id ||
                0
              }
            />
          ),

          statusProject: (row) => (
            <OmniChipAcronym
              acronym={row.statusProject}
              statusArray={StatusProject.map(({ keyword, label, color }) => ({
                name: label,
                acronym: keyword,
                color,
              }))}
            />
          ),

          options: (row) => (
            <>
              <EditButton
                disabled={row.statusProject === 'FINALIZADO'}
                handleEdit={() => handleOpenDialog(row.id.id.toString())}
              />
              {row.statusProject === 'FINALIZADO' && 'Finalizado'}

              <Tooltip title={t('Etapas')}>
                <IconButton
                  aria-label="Etapas"
                  color="primary"
                  onClick={() => goToStages(row.id.id.toString())}
                >
                  <IconLayoutList />
                </IconButton>
              </Tooltip>

              <DeleteButton handleDelete={() => handleDelete(row.id.id.toString())} />
            </>
          ),
        }}
        ActionButtons={
          <>
            <AddButton size="large" onClick={goToAdd} btnText={'Agregar'} />
          </>
        }
        customDataCellsProperties={{
          id: {
            align: 'center',
          },
          name: {
            align: 'center',
          },
          observation: {
            align: 'center',
          },
          startDate: {
            align: 'center',
          },
          endDate: {
            align: 'center',
          },
          statusAcronym: {
            align: 'center',
          },
          statusProject: {
            align: 'center',
          },
          options: {
            align: 'center',
          },
        }}
      />
      {deleteModalOpenProject && <DeleteProjectPrincipalDialog />}
      <ExtendDialog open={openDialog} handleCloseDialog={handleCloseDialog} title="Editar proyecto">
        {/* <>{openDialog && <WizardProject isEdit={true} projectId={projectToEdit} />}</> */}
        <>
          {openDialog && (
            <ProjectPrincipalForm
              data={data?.data.find(({ id }) => projectToEdit === id + '')}
              isEdit
              projectId={projectToEdit}
            />
          )}
        </>
      </ExtendDialog>

      <ExtendDialog
        open={openDialogAssignPerson}
        handleCloseDialog={handleCloseDialogAssignPerson}
        title="Personas asignadas"
      >
        <>{openDialogAssignPerson && <ListAssignPersonDelete projectId={projectToEdit} />}</>
      </ExtendDialog>

      <ExtendDialog open={openDialog} handleCloseDialog={handleCloseDialog} title="Editar proyecto">
        {/* <>{openDialog && <WizardProject isEdit projectId={projectToEdit} isPrincipal={true} />}</> */}
        <>
          {openDialog && (
            <ProjectPrincipalForm
              data={data?.data.find(({ id }) => projectToEdit === id + '')}
              isEdit
              projectId={projectToEdit}
            />
          )}
        </>
      </ExtendDialog>

      <ExtendDialog
        open={openDialogDocument}
        handleCloseDialog={handleCloseDialogDocument}
        title="Documentos"
      >
        <>{openDialogDocument && <ListDocument projectId={projectToEdit} />}</>
      </ExtendDialog>
    </>
  );
};

export default ProjectPrincipalTable;
