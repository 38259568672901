import { Stack, Typography } from '@material-ui/core';
import imge from '../../../../assets/images_optimized/imagen-selecciona-una-opcion_optimized.png';

const MetricsMessage = () => {
  return (
    <Stack alignItems="center">
      <img src={imge} alt="IMG" style={{ width: 440 }} />
      <Typography variant="h5">Seleccione los filtros para visuzalizar los gráficos</Typography>
    </Stack>
  );
};

export default MetricsMessage;
