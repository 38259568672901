export const months = [
  {
    text: 'Enero',
    value: '01',
  },
  {
    text: 'Febrero',
    value: '02',
  },
  {
    text: 'Marzo',
    value: '03',
  },
  {
    text: 'Abril',
    value: '04',
  },
  {
    text: 'Mayo',
    value: '05',
  },
  {
    text: 'Junio',
    value: '06',
  },
  {
    text: 'Julio',
    value: '07',
  },
  {
    text: 'Agosto',
    value: '08',
  },
  {
    text: 'Septiembre',
    value: '09',
  },
  {
    text: 'Octubre',
    value: '10',
  },
  {
    text: 'Noviembre',
    value: '11',
  },
  {
    text: 'Diciembre',
    value: '12',
  },
];

export const FaltaJustificada = 'Falta justificada';
export const FaltaInjustificada = 'Falta injustificada';
export const AtrasoJustificado = 'Atraso justificado';
export const AtrasoInjustificado = 'Atraso injustificado';
export const FaltaRectificada = 'Falta rectificada';
