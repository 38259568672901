import React from 'react';
import { Link, useHistory } from 'react-router-dom';

// material-ui
import { useTheme } from '@material-ui/core/styles';
import {
  Avatar,
  Badge,
  Box,
  Button,
  ButtonBase,
  CardActions,
  CardContent,
  Chip,
  ClickAwayListener,
  Divider,
  Grid,
  Paper,
  Popper,
  Stack,
  Typography,
  useMediaQuery,
} from '@material-ui/core';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MainCard from '../../../../components/cards/MainCard';
import Transitions from '../../../../components/extended/Transitions';
import NotificationList from './NotificationList';

// assets
import { IconBell } from '@tabler/icons';
import { useStyles } from './constants/styles';
import { useAppSelector } from '../../../../../../hooks/useRedux';

//-----------------------|| NOTIFICATION ||-----------------------//

const NotificationSection = () => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const matchesXs = useMediaQuery(theme.breakpoints.down('sm'));

  const { counter } = useAppSelector((state) => state.access.notifications);

  const [open, setOpen] = React.useState(false);
  // const [value, setValue] = React.useState('');
  const [notificationsViewState, setNotificationsViewState] = React.useState(false);
  /**
   * anchorRef is used on different componets and specifying one type leads to other components throwing an error
   * */
  const anchorRef = React.useRef<any>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<HTMLDivElement> | MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  // const handleChange = (
  //   event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined
  // ) => {
  //   event?.target.value && setValue(event?.target.value);
  // };

  const goToNotifications = () => {
    history.push('/notificaciones');
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Box component="span" className={classes.box}>
        <Badge badgeContent={counter} color={open ? 'primary' : 'secondary'}>
          <ButtonBase sx={{ borderRadius: '12px' }}>
            <Avatar
              variant="rounded"
              className={classes.headerAvatar}
              ref={anchorRef}
              aria-controls={open ? 'menu-list-grow' : undefined}
              aria-haspopup="true"
              onClick={handleToggle}
              color="primary"
            >
              <IconBell stroke={1.5} size="1.3rem" />
            </Avatar>
          </ButtonBase>
        </Badge>
      </Box>
      <Popper
        placement={matchesXs ? 'bottom' : 'bottom-end'}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [matchesXs ? 5 : 0, 20],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  border={false}
                  elevation={16}
                  content={false}
                  boxShadow
                  shadow={theme.shadows[16]}
                >
                  <CardContent className={classes.cardContent}>
                    <Grid container direction="column" spacing={2}>
                      <Grid item xs={12}>
                        <div className={classes.bodyPPacing}>
                          <Grid container alignItems="center" justifyContent="space-between">
                            <Grid container alignItems="center" justifyContent="space-between">
                              <Grid item>
                                <Stack direction="row" spacing={2}>
                                  <Typography variant="subtitle1">
                                    Notificaciones no leídas
                                  </Typography>
                                  <Chip
                                    size="small"
                                    label={counter}
                                    color="primary"
                                    className={classes.notificationChip}
                                  />
                                </Stack>
                              </Grid>
                              <Grid item style={{ marginLeft: 6 }} sx={{ visibility: 'hidden' }}>
                                <Typography
                                  component={Link}
                                  to="#"
                                  variant="subtitle2"
                                  color="primary"
                                >
                                  Marcar como leído
                                </Typography>
                              </Grid>
                            </Grid>

                            <Grid item style={{ marginLeft: 6 }} sx={{ visibility: 'hidden' }}>
                              <Typography
                                component={Link}
                                to="#"
                                variant="subtitle2"
                                color="primary"
                              >
                                Marcar como leído
                              </Typography>
                            </Grid>

                            <Grid item xs={12}>
                              <Stack pl={2} direction="row" spacing={2}>
                                <Grid item>
                                  <Button
                                    variant={!notificationsViewState ? 'contained' : 'outlined'}
                                    onClick={() => setNotificationsViewState(false)}
                                  >
                                    Todas
                                  </Button>
                                </Grid>
                                <Grid item>
                                  <Button
                                    variant={notificationsViewState ? 'contained' : 'outlined'}
                                    onClick={() => setNotificationsViewState(true)}
                                  >
                                    No leídos
                                  </Button>
                                </Grid>
                              </Stack>
                            </Grid>
                          </Grid>
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <PerfectScrollbar
                          id="notificationScrollable"
                          className={classes.ScrollHeight}
                        >
                          <Grid container direction="column" spacing={2}>
                            <Grid item xs={12} p={0}>
                              <Divider className={classes.divider} />
                            </Grid>
                            <Grid item xs={12}>
                              <NotificationList
                                handleClose={() => setOpen(false)}
                                notificationsViewState={notificationsViewState}
                              />
                            </Grid>
                          </Grid>
                        </PerfectScrollbar>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <Divider />
                  <CardActions className={classes.cardAction}>
                    <Button
                      size="small"
                      color="primary"
                      disableElevation
                      onClick={goToNotifications}
                    >
                      Ver todo
                    </Button>
                  </CardActions>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </React.Fragment>
  );
};

export default NotificationSection;
