import { mainApi } from '../../../../api/mainApi';
import { middlewareApi } from '../../../middleware/middlewareApi';
import { setAcademicHourhand } from './academicHourhandSlice';
import { AcademicHourhandResponse, Schedule } from '../interfaces/academic-hourhand-interfaces';

export const hourhandTags = middlewareApi.enhanceEndpoints({
  addTagTypes: ['AcademicHourhands'],
});
// Define a service using a base URL and expected endpoints
export const hourhandApi = hourhandTags.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * @GET hourhand student
     */
    getAcademicHourhandByRecordStudents: builder.query<
      AcademicHourhandResponse,
      { studentRecord: number; user_profile_id: number; current?: boolean }
    >({
      queryFn: async ({ studentRecord, user_profile_id, current }, { dispatch }) => {
        try {
          const { data } = await mainApi.get<AcademicHourhandResponse>(
            `/students/student-record/${studentRecord}/course-student`,
            {
              params: {
                studentRecord: studentRecord,
                user_profile_id: user_profile_id,
                ...(current && { period_active: 1 }),
              },
            }
          );

          data.debug && delete data.debug;

          console.log(data);

          dispatch(setAcademicHourhand(data));
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),
    getSchedule: builder.query<
      Schedule[],
      {
        studentRecordId: number;
        user_profile_id: number;
        periodId: number;
      }
    >({
      queryFn: async ({ studentRecordId, user_profile_id, periodId }, { dispatch }) => {
        try {
          const { data } = await mainApi.get<Schedule[]>(
            `student-record/${studentRecordId}/hourhands`,
            {
              params: {
                user_profile_id: user_profile_id,
                period: periodId,
              },
            }
          );
          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
    }),
  }),
});

export const { useGetAcademicHourhandByRecordStudentsQuery, useGetScheduleQuery } = hourhandApi;
