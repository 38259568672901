import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import DeleteAlert from '../../../shared/components/dialogs/DeleteAlert';
import { useDeletePosgraduatePlanningCalendarMutation } from '../slices/posgraduatePlanningCalendarApiSlice';
import {
  setPosgraduatePlanningCalendarDeleteDialogOpen,
  setPosgraduatePlanningCalendarIdSelected,
} from '../slices/posgraduatePlanningCalendarSlice';

const PostgraduatePlanningCalendarDeleteDialog = () => {
  const dispatch = useAppDispatch();

  const { currentProfile } = useAppSelector((state) => state.access);

  const { posgraduatePlanningCalendarIdSelected } = useAppSelector(
    (state) => state.posgraduatePlanningCalendars
  );

  const [deleteCalendar, { isLoading, isError, error, isSuccess }] =
    useDeletePosgraduatePlanningCalendarMutation();

  const handleDelete = () => {
    deleteCalendar({
      profileId: currentProfile?.profileId!,
      id: posgraduatePlanningCalendarIdSelected!,
    });
  };

  const handleClose = () => {
    dispatch(setPosgraduatePlanningCalendarDeleteDialogOpen(false));
    dispatch(setPosgraduatePlanningCalendarIdSelected(null));
  };

  return (
    <DeleteAlert
      open
      handleDelete={handleDelete}
      onClose={handleClose}
      isLoading={isLoading}
      isError={isError}
      error={error as string}
      isSuccess={isSuccess}
    />
  );
};

export default PostgraduatePlanningCalendarDeleteDialog;
