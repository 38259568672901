import { Switch, Redirect } from 'react-router-dom';
import LearningComponentsScreen from '../screens/LearningComponentsScreen';
import AddEducationLevelScreen from '../screens/AddLearningComponentsScreen';
import EditEducationLevelScreen from '../screens/EditLearningComponentsScreen';
import { useAppSelector } from '../../../../hooks/useRedux';

import { subPermissionKeys } from '../constants/permissions';
import GuardRoute from '../../../../router/guards/GuardRoute';

const { groupKey: permissionKey } = subPermissionKeys;

const LearningComponentsRouter = () => {
  const { currentModule } = useAppSelector((state) => state.access);

  const hasPermission = permissionKey in currentModule!.permissions;

  if (!hasPermission) <Redirect to="/dashboard" />;

  const namePath = 'administrar-componentes-de-aprendizaje';

  return (
    <Switch>
      <GuardRoute
        exact
        path={`/${namePath}`}
        component={LearningComponentsScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.read}
      />
      <GuardRoute
        exact
        path={`/${namePath}/agregar`}
        component={AddEducationLevelScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.create}
      />
      <GuardRoute
        exact
        path={`/${namePath}/:componentId/editar`}
        component={EditEducationLevelScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.edit}
      />
    </Switch>
  );
};

export default LearningComponentsRouter;
