import { BondingPayload } from '../interfaces/bonding.interfaces';
import { InternalHomologationsPayload } from '../interfaces/changeCareer.interfaces';
import { MissedExamPayload } from '../interfaces/missedExam.interfaces';
import { OtherAcademicRequestPayload } from '../interfaces/otherAcademicRequest.interfaces';
import { PaymentAgreementPayload } from '../interfaces/paymentAgreement.interface';
import { RecoveryExamPayload } from '../interfaces/recoveryExam.interfaces';
import { RequalificationRequestPayload } from '../interfaces/requalification.interface';
import { SubjectWithDrawalPayload } from '../interfaces/subjectWithDrawal.interfaces';
import { ThirdRegistrationPayload } from '../interfaces/thirdTimeRegistration.interfaces';
import { TitulationPayload } from '../interfaces/titulation.interfaces';
import { ApprovePracticesPayload } from '../interfaces/approvePractices.interfaces';
// import { number } from 'yup';

export const internalHomologationValues: InternalHomologationsPayload = {
  education_level_current_id: 0,
  curriculum_new_id: 0,
};

export const otherAcademicRequestValues: OtherAcademicRequestPayload = {
  files: null!,
  type_document: 0,
  app_title: '',
  app_description: '',
  app_type: '',
};
export const thirdRegistrationValues: ThirdRegistrationPayload = {
  app_description: '',
  course_id: 0,
  subject_curriculum_id: 0,
  period_id: 0,
};
export const subjectWithDrawalValues: SubjectWithDrawalPayload = {
  files: null!,
  app_description: '',
  tipo_retiro: '',
  course_student_id: 0,
};
export const requalificationRequestValues: RequalificationRequestPayload = {
  app_description: '',
  course_student_id: 0,
  supplie_id: 0,
};
export const bondingValues: BondingPayload = {
  files: null!,
  type_document: 0,
  app_description: '',
  project_name: '',
  project_research_id: '',
};

export const missedExamValues: MissedExamPayload = {
  files: null!,
  app_description: '',
  course_student_id: 0,
  partial_id: 0,
  motivo: '',
};

export const approvePracticesValues: ApprovePracticesPayload = {
  file_url_cert: null!,
  file_url_report: null!,
  student_record_id: 0,
  app_description: '',
};

export const paymentAgreementSchema: PaymentAgreementPayload = {
  app_description: '',
  agreement_value: 0,
  payment_date: new Date(),
  period_id: 0,
};

export const recoveryExamSchema: RecoveryExamPayload = {
  app_description: '',
  course_student_id: 0,
  period_id: 0,
};
export const titulationValues: TitulationPayload = {
  app_description: '',
  period_id: 0,
  si: null!,
  cc: null!,
  cp: null!,
  ca: null!,
};
