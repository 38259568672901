import Autocomplete from '@material-ui/core/Autocomplete';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { useFormik } from 'formik';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ErrorAlert from '../../../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../../../shared/components/alerts/SuccessAlert';
import { statusAvailables } from '../../../../../shared/constants/resourceStatus';
import {
  useAddCollaboratorGroupResearchMutation,
  useGetGroupResearchAllAvailablesQuery,
} from '../../../slices/InvestigationLinesApiSlice';
import { useLazyGetCollaboratorsQuery } from '../../../../../hr/collaborators/slices/collaboratorsApiSlice';
import CustomAsyncPaginatedSelect from '../../../../../shared/components/extended/Form/CustomAsyncPaginatedSelect';
import { CollaboratorGroupResearchSchema } from '../../../constants/validationSchemas.investigationLines';
import InputLabel from '../../../../../shared/components/forms/InputLabel';

interface CollaboratorGroup {
  researchGroupId?: string;
  isEdit?: boolean;
}

const AddCollaboratorGroupResearchForm = ({ isEdit, researchGroupId }: CollaboratorGroup) => {
  const { t } = useTranslation();

  const history = useHistory();

  const [showAlerts, setShowAlerts] = useState(true);

  const [collaboratorValue, setCollaboratorValue] = useState<any | null>(null);

  const [
    getCollaboratorTrigger,
    // { data: institutesAvailables, isLoading: isLoadingInstitutesAvailables },
  ] = useLazyGetCollaboratorsQuery();

  //TODO: crear hook en el componente del select
  const getCollaborators = async (search: string, page: number, perPage: number) => {
    const response = await getCollaboratorTrigger({
      page,
      perPage,
      search,
      coll_type: 'D',
      statusId: 1,
    }).unwrap();

    const hasMore = response.current_page < response.last_page;

    return {
      options: response.data.map((dt) => {
        return {
          value: dt.id,
          label: (dt as any).pers_full_name,
          ...dt,
        };
      }),
      hasMore,
    };
  };
  const [addCollaboratorGroupResearch, { isLoading, isError, error, isSuccess }] =
    useAddCollaboratorGroupResearchMutation();

  const { data: GroupResearchAvailables, isLoading: isLoadingGroupResearchAvailables } =
    useGetGroupResearchAllAvailablesQuery({});

  const { errors, touched, handleSubmit, setFieldValue, values } = useFormik({
    initialValues: {
      collaboratorId: '',
      groupResearchId: isEdit ? researchGroupId || '' : '',
      status: 1,
    },
    validationSchema: CollaboratorGroupResearchSchema,
    onSubmit: async ({ groupResearchId, status, collaboratorId }) => {
      await addCollaboratorGroupResearch({
        collaboratorGroupResearchPayload: {
          // name,
          group_research_id: groupResearchId,
          collaborator_id: collaboratorId,
          status: statusAvailables.find((e) => e.id === status)?.acronym || '0',
        },
      });
      window.scrollTo(0, 0);
      setShowAlerts(true);
    },
  });

  useEffect(() => {
    setFieldValue('collaboratorId', (collaboratorValue as any)?.value || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collaboratorValue]);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction="column" gap={2}>
        <Grid item xs={12} md={6}>
          <Grid container direction="column" spacing={2}>
            {isError && showAlerts && (
              <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
            )}

            {isSuccess && showAlerts && (
              <SuccessAlert
                message={t('lines_research.AddCollaboratorGroupResearch.add.success')}
                handleDismiss={() => setShowAlerts(false)}
              />
            )}

            <Grid item xs={12}>
              <InputLabel>
                {t('lines_research.AddCollaboratorGroupResearch.groupResearchId.title')}
              </InputLabel>
              <Autocomplete
                options={GroupResearchAvailables || []}
                loading={isLoadingGroupResearchAvailables}
                noOptionsText={t('')}
                value={
                  GroupResearchAvailables?.find(({ id }) => id === values.groupResearchId) || null
                }
                disabled={isEdit}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(errors.groupResearchId && touched.groupResearchId)}
                    placeholder={t(
                      'lines_research.AddCollaboratorGroupResearch.groupResearchId.placeholder'
                    )}
                  />
                )}
                onChange={(_, value) => {
                  setFieldValue('groupResearchId', value?.id || '');
                }}
                fullWidth
                clearIcon={null}
              />
              {errors.groupResearchId && touched.groupResearchId && (
                <FormHelperText error>{t(errors.groupResearchId)}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12}>
              <InputLabel>
                {t('lines_research.AddCollaboratorGroupResearch.collaboratorId.title')}
              </InputLabel>
              <CustomAsyncPaginatedSelect
                key={'collaboratorId'}
                value={collaboratorValue}
                isError={'collaboratorId' in errors && touched['collaboratorId'] ? true : false}
                placeholder={t(
                  'lines_research.AddCollaboratorGroupResearch.collaboratorId.placeholder'
                )}
                setValue={setCollaboratorValue}
                fetchFunc={getCollaborators}
              />
              {errors.collaboratorId && touched.collaboratorId && (
                <FormHelperText error>{t(errors.collaboratorId)}</FormHelperText>
              )}
            </Grid>
          </Grid>

          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            spacing={2}
            sx={{ mt: 6 }}
          >
            <Grid item xs={6}>
              {!isEdit && (
                <Button
                  fullWidth
                  variant="outlined"
                  size="large"
                  color="primary"
                  onClick={() => history.goBack()}
                >
                  {t('academic.subjects.add.back')}
                </Button>
              )}
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                type="submit"
                variant="contained"
                size="large"
                color="primary"
                disabled={isLoading}
                endIcon={isLoading && <CircularProgress size={20} />}
              >
                {t('academic.subjects.add.submit')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default AddCollaboratorGroupResearchForm;
