import { mainApi } from '../../../../api/mainApi';
import { AccessState } from '../../../../types';
import { middlewareApi } from '../../../middleware/middlewareApi';
import { HistorialSyllabus } from '../interfaces/HistorialSyllabus.interface';
import { MatterByEducationLevel } from '../interfaces/MatterByEducationLevel.interface';
import { SyllabusResponseData } from '../interfaces/SyllabusResponseData.interface';
import { SyllabusStatus } from '../interfaces/SyllabusStatus.interfaces';

export const syllabusTags = middlewareApi.enhanceEndpoints({
  addTagTypes: ['Syllabus'],
});

export const syllabusApi = syllabusTags.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * @GET syllabus
     */
    /**
     * @GET MatterByEducationLevel
     */
    getMatterByEducationLevelId: builder.query<
      MatterByEducationLevel[],
      { educationLevelId: number | string }
    >({
      queryFn: async ({ educationLevelId }, { dispatch }) => {
        try {
          const { data } = await mainApi.get<MatterByEducationLevel[]>(
            `/get-subject-by-education-level/${educationLevelId}`
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    /**
     * @Get Syllabus
     *
     */

    getSyllabus: builder.query<
      SyllabusResponseData,
      {
        profileId: number | string;
        page: number | string;
        size: number | string;
        sy_anio?: number | string;
        education_level_id?: number | string;
        period_id?: number | string;
        matter_id?: number | string;
        status_id?: number | string;
        search?: string;
        sort?: string;
        type_sort?: string;
      }
    >({
      queryFn: async (
        {
          profileId,
          sy_anio,
          education_level_id,
          period_id,
          matter_id,
          status_id,
          search,
          sort,
          type_sort,
          page,
          size,
        },
        { dispatch }
      ) => {
        try {
          const { data } = await mainApi.get<SyllabusResponseData>(`/syllabus`, {
            params: {
              user_profile_id: profileId,
              sy_anio,
              education_level_id,
              period_id,
              matter_id,
              status_id,
              search,
              sort,
              type_sort,
              page,
              size,
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),
    getSyllabusStatus: builder.query<SyllabusStatus[], void>({
      queryFn: async () => {
        try {
          const { data } = await mainApi.get<SyllabusStatus[]>(
            `/status/category?cat_name=Syllabus`
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),
    changeStateSyllabus: builder.mutation<
      { message: string },
      { syllabusId: number | string; statusId: number | string }
    >({
      queryFn: async ({ syllabusId, statusId }, { dispatch }) => {
        try {
          const { data } = await mainApi.put<{ message: string }>(
            `/syllabus/change-status/${syllabusId}`,
            {
              status_id: statusId,
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),
    getHistorialSyllabus: builder.query<
      HistorialSyllabus[],
      { syllabusId: number | string; profileId?: number | string }
    >({
      queryFn: async ({ syllabusId, profileId }, { dispatch, getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = profileId || currentProfile?.profileId;

          const { data } = await mainApi.get<HistorialSyllabus[]>(
            `/syllabus/history/${syllabusId}`,
            {
              params: {
                user_profile_id,
                data: 'all',
              },
            }
          );

          return { data: Array.isArray(data) ? data : []};
        } catch (error: any) {
          return { error };
        }
      },
    }),

    getPdfSyllabus: builder.query<
      any,
      { syllabusId: number | string; profileId?: number | string }
    >({
      queryFn: async ({ syllabusId, profileId }, { dispatch, getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = profileId || currentProfile?.profileId;

          const { data } = await mainApi.get<any>(`/syllabus/${syllabusId}/generate-pdf`, {
            responseType: 'arraybuffer',
            params: {
              user_profile_id,
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),
  }),
});

export const {
  useGetMatterByEducationLevelIdQuery,
  useLazyGetMatterByEducationLevelIdQuery,
  useGetSyllabusQuery,
  useGetSyllabusStatusQuery,
  useChangeStateSyllabusMutation,
  useGetHistorialSyllabusQuery,
  useLazyGetPdfSyllabusQuery,
} = syllabusApi;
