import { useState } from 'react';

import { Box, Button, useTheme } from '@material-ui/core';
import {
  SubdirectoryArrowRightOutlined,
  KeyboardArrowUpOutlined,
  KeyboardArrowDownOutlined,
} from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

import useExpandAccordion from '../../hooks/useExpandAccordion';
import CommentCard from './CommentCard';

import {
  useAddCommentsMutation,
  useDeleteCommentMutation,
  useUpdateCommentMutation,
} from '../../slices/virtualClassroomApiSlice';
import EditorText from './EditorText';
import { useAppSelector } from '../../../../../hooks/useRedux';
import { AnswersActivity } from '../interfaces/forums.interfaces';
import Replies from './Replies';

interface Props {
  comment: AnswersActivity;
  forumId: string;
  refetch: () => void;
}
const CommentsForum = ({ comment, forumId, refetch }: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const {
    id,
    csact_comments,
    csact_name_file,
    children,
    created_at,
    user_id,
    user: {
      person: { pers_first_lastname, pers_second_lastname, pers_firstname },
    },
  } = comment;

  const data = {
    name: `${pers_first_lastname} ${pers_second_lastname} ${pers_firstname}`,
    comment: csact_comments,
    images: [{ img: csact_name_file }],
    replies: children,
  };
  const profile = {
    id: user_id.toString(),
    avatar: 'N/A',
    name: `${pers_first_lastname} ${pers_second_lastname} ${pers_firstname}`,
    time: created_at.toLocaleString(),
  };

  const { expanded, handleClickToogle } = useExpandAccordion();

  const { userInfo } = useAppSelector((state) => state.auth);
  const { /*currentProfile ,*/ currentStudent } = useAppSelector((state) => state.access);

  const [viewEditor, setViewEditor] = useState<boolean>(false);
  const [editorType, setEditorType] = useState<'add' | 'edit'>('add');

  const [createComment /*{ isSuccess: isSuccessComment, isError: isErrorComment, error }*/] =
    useAddCommentsMutation();

  const [editComment] = useUpdateCommentMutation();
  const [deleteComment] = useDeleteCommentMutation();

  const handleDeleteComment = async () => {
    await deleteComment({
      commentId: id,
    });
    refetch();
  };

  const handleEditComment = async (data: { comment: string; files: File | undefined }) => {
    await editComment({
      commentId: id,
      CommentPayload: {
        csact_comments: data.comment,
        files: data.files,
        user_id: Number(userInfo?.id!),
        student_id: Number(currentStudent?.id!),
        type_document: 12,
      },
    });
    refetch();
    setEditorType('add');
    setViewEditor(false);
  };

  const replyAdd = async (data: { comment: string; files: File | undefined }) => {
    await createComment({
      forumId: Number(forumId),
      CommentPayload: {
        csact_comments: data.comment,
        files: data.files,
        user_id: Number(userInfo?.id!),
        student_id: Number(currentStudent?.id!),
        type_document: 12,
        parent_id: id.toString(),
      },
    });
    refetch();
    // setShowAlerts(true);
  };

  const setSubReplies = (children_content: AnswersActivity[]) => {
    if (Array.isArray(children_content)) {
      return (
        <>
          {children_content.map((children) => {
            const { children: replies } = children;
            return (
              <Replies forumId={forumId} reply={children} refetch={refetch}>
                {replies ? setSubReplies(replies) : <></>}
              </Replies>
            );
          })}
        </>
      );
    } else {
      return <></>;
    }
  };

  const menuData = {
    isViewMenu: profile.id === userInfo.id.toString(),
    data: [
      {
        name: t('studentVirtualClassroom.forum.editor.edit'),
        handleClick: () => {
          setViewEditor(true);
          setEditorType('edit');
        },
      },
      {
        name: t('studentVirtualClassroom.forum.editor.delete'),
        handleClick: () => {
          handleDeleteComment();
        },
      },
    ],
  };
  let text: any;
  if (!data.comment?.includes('{') || !data.comment?.includes('[')) {
    text = {
      ops: [
        {
          insert: data.comment,
        },
      ],
    };
  } else {
    text = JSON.parse(data.comment);
  }

  return (
    <Box>
      <Box
        sx={{
          width: '100%',
          borderRadius: 3,
          borderLeft: `10px solid ${theme.palette.secondary.main}`,
          px: 3,
          py: 1,
          boxShadow: '0px 0px 5px 0px #777',
          mb: 3,
        }}
      >
        <Box>
          {data && (
            <CommentCard id={id.toString()} data={data} profile={profile} menuData={menuData} />
          )}
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            borderTop: `1px solid #ccc`,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {data && data.replies && data.replies.length > 0 && (
              <Button
                startIcon={expanded ? <KeyboardArrowUpOutlined /> : <KeyboardArrowDownOutlined />}
                onClick={handleClickToogle}
              >
                {expanded
                  ? t('studentVirtualClassroom.forum.editor.Hide')
                  : t('studentVirtualClassroom.forum.editor.Show')}
              </Button>
            )}
            <Button
              startIcon={<SubdirectoryArrowRightOutlined />}
              onClick={() => setViewEditor(true)}
            >
              {t('studentVirtualClassroom.forum.editor.reply')}
            </Button>
          </Box>
        </Box>
      </Box>
      {expanded && data && data.replies && setSubReplies(data.replies)}
      {viewEditor && (
        <EditorText
          commentAdd={replyAdd}
          setViewEditor={() => setViewEditor(false)}
          commentEdit={handleEditComment}
          quillCurrent={text}
          type={editorType}
        />
      )}
    </Box>
  );
};

export default CommentsForum;
