import { Grid, Box, Stepper, StepLabel, Step } from '@material-ui/core';

import { useState } from 'react';
import Step1 from './steps/Step1';
import Step2 from './steps/Step2';

const AddThesis = () => {
  const steps = ['Datos del Estudiante', 'Datos de la Tesis'];
  const [activeStep, setActiveStep] = useState(0);
  const handleNext = () => setActiveStep(activeStep + 1);
  const handleBack = () => setActiveStep(activeStep - 1);

  return (
    <>
      <Box sx={{ width: '100%', mb: 3 }}>
        <Stepper activeStep={activeStep} sx={{ maxWidth: '1000px', margin: '0 auto' }}>
          {steps.map((label, index) => {
            const stepProps: { completed?: boolean } = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Box>
      <Grid container gap={2}>
        <Grid item container xs={12} md={12} lg={12} xl={12} spacing={3} alignItems="center">
          <Step1 isActive={Boolean(activeStep === 0)} handleNext={handleNext} />
          <Step2 isActive={Boolean(activeStep === 1)} handleBack={handleBack} />
        </Grid>
      </Grid>
    </>
  );
};

export default AddThesis;
