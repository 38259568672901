import { Autocomplete, Box, Button, CircularProgress, Grid, TextField } from '@material-ui/core';
import { DateRangePicker, DateTimePicker, LocalizationProvider } from '@material-ui/lab';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import { es } from 'date-fns/locale';
import { useFormik } from 'formik';
import InputLabel from '../../../shared/components/forms/InputLabel';
import * as yup from 'yup';
import { useGetCollaboratorsQuery } from '../../../hr/collaborators/slices/collaboratorsApiSlice';
import { useGetAcademicOfferingQuery } from '../../../maintenance/academic-offerings/slices/academicOfferingsApiSlice';
import { useAppSelector } from '../../../../hooks/useRedux';
import { useSendApplicationActaMutation } from '../slices/GeneralApplicationsApiSlice';
import { format } from 'date-fns';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import { useState } from 'react';
import useSharedGuard from '../../../../hooks/useSharedGuard';
import { subPermissionKeys } from '../constants/permissions';

const ActaApplicationForm = () => {
  const { currentProfile } = useAppSelector((state) => state.access);

  const { hasAccess } = useSharedGuard(subPermissionKeys);

  const { data: collaborators, isLoading: isLoadingCollaborators } = useGetCollaboratorsQuery({
    page: 1,
    perPage: 2000,
    search: '',
  });

  const { data: faculties, isLoading: isLoadingFaculties } = useGetAcademicOfferingQuery({
    profileId: currentProfile?.profileId!,
    academicOfferingId: 2,
  });

  const [showAlert, setShowAlert] = useState(false);

  const [
    application,
    {
      data: pdfData,
      isLoading: isLoadingApplication,
      isSuccess: isSuccessApplication,
      isError: isErrorApplication,
      error: errorApplication,
    },
  ] = useSendApplicationActaMutation();

  // console.log({
  //   pdfData,
  //   isLoadingApplication,
  //   isSuccessApplication,
  //   isErrorApplication,
  //   errorApplication,
  // });

  // console.log(pdfData);

  const schema = yup.object().shape({
    date: yup.string().nullable().required('Fecha y Hora son requeridas'),
    start: yup.string().nullable().required('Fecha Inicio es requerida'),
    end: yup.string().nullable().required('Fecha Fin es requerida'),
    asistente: yup.string().required('Asistente es requerido'),
    cargo: yup.string().required('Cargo es requerido'),
    fac: yup.string().required('Facultad es requerida'),
  });

  const { values, setFieldValue, errors, touched, handleSubmit, getFieldProps } = useFormik({
    initialValues: {
      date: '',
      start: '',
      end: '',
      asistente: '',
      cargo: '',
      fac: '',
    },
    validationSchema: schema,
    // onReset: reset,
    onSubmit: async (values) => {
      try {
        await application({
          payload: {
            asistentes: [Number(values.asistente)],
            faculty_id: Number(values.fac),
            fecha: format(new Date(values.date), 'yyyy-MM-dd'),
            fecha_fin: format(new Date(values.end), 'yyyy-MM-dd'),
            fecha_ini: format(new Date(values.start), 'yyyy-MM-dd'),
            hora: format(new Date(values.date), 'hh:mm'),
            lugar: 'eco sambo',
            user_profile_id: currentProfile?.profileId!,
          },
        }).unwrap();

        // resetForm();
      } finally {
        setShowAlert(true);
      }
    },
  });

  return (
    <form onSubmit={handleSubmit}>
      <Grid item container xs={12} md={6} direction="column" spacing={3}>
        <Grid item xs={12} md={12}>
          <InputLabel>Fecha y Hora de Consejo</InputLabel>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
            <DateTimePicker
              value={values.date || null}
              onChange={(val) => val && setFieldValue('date', val)}
              renderInput={(props) => (
                <TextField
                  {...props}
                  {...getFieldProps('date')}
                  fullWidth
                  error={Boolean(errors.date && touched.date)}
                  helperText={Boolean(errors.date && touched.date) && errors.date}
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} md={12}>
          <InputLabel>Fechas</InputLabel>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
            <DateRangePicker
              value={[values.start || null, values.end || null]}
              onChange={async (val) => {
                await setFieldValue('start', val[0]);
                await setFieldValue('end', val[1]);
              }}
              renderInput={(startProps, endProps) => (
                <>
                  <TextField
                    {...startProps}
                    {...getFieldProps('start')}
                    fullWidth
                    label="Inicio"
                    error={Boolean(errors.start && touched.start)}
                    helperText={Boolean(errors.start && touched.start) && errors.start}
                  />
                  <Box sx={{ mx: 2 }}> - </Box>
                  <TextField
                    {...endProps}
                    {...getFieldProps('end')}
                    fullWidth
                    label="Fin"
                    error={Boolean(errors.end && touched.end)}
                    helperText={Boolean(errors.end && touched.end) && errors.end}
                  />
                </>
              )}
            />
          </LocalizationProvider>
        </Grid>
        {hasAccess('readAssistants') && (
          <Grid item xs={12} md={12}>
            <InputLabel>Asistente</InputLabel>
            {isLoadingCollaborators ? (
              <TextField placeholder="Cargando..." fullWidth />
            ) : (
              <Autocomplete
                options={
                  collaborators?.data?.map((el) => ({
                    label: el.user.person.pers_full_name,
                    value: el.user.person.id,
                  })) || []
                }
                onChange={(_, val) => {
                  setFieldValue('asistente', val?.value || '');
                }}
                value={
                  collaborators?.data
                    ?.map((el) => ({
                      label: el.user.person.pers_full_name,
                      value: el.user.person.id,
                    }))
                    ?.find((item) => Number(item.value) === Number(values.asistente)) || null
                }
                clearIcon={null}
                renderInput={(props) => (
                  <TextField
                    {...props}
                    {...getFieldProps('asistente')}
                    placeholder="Seleccione Asistente"
                    fullWidth
                    error={Boolean(errors.asistente && touched.asistente)}
                    helperText={Boolean(errors.asistente && touched.asistente) && errors.asistente}
                  />
                )}
              />
            )}
          </Grid>
        )}
        <Grid item xs={12} md={12}>
          <InputLabel>Cargo</InputLabel>
          <TextField
            {...getFieldProps('cargo')}
            error={Boolean(errors.cargo && touched.cargo)}
            placeholder="Ingrese Cargo"
            fullWidth
            helperText={Boolean(errors.cargo && touched.cargo) && errors.cargo}
          />
        </Grid>
        {hasAccess('readEducationLevel') && (
          <Grid item xs={12} md={12}>
            <InputLabel>Unidad Académica</InputLabel>
            {isLoadingFaculties ? (
              <TextField placeholder="Cargando..." fullWidth />
            ) : (
              <Autocomplete
                options={
                  faculties?.education_levels?.map((fc) => ({
                    value: fc.id,
                    label: fc.edu_name,
                  })) || []
                }
                onChange={(e, val) => {
                  setFieldValue('fac', val?.value || '');
                }}
                value={
                  faculties?.education_levels
                    ?.map((fc) => ({
                      value: fc.id,
                      label: fc.edu_name,
                    }))
                    ?.find((item) => Number(item.value) === Number(values.fac)) || null
                }
                clearIcon={null}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(errors.fac && touched.fac)}
                    helperText={Boolean(errors.fac && touched.fac) && errors.fac}
                    placeholder="Seleccione Facultad"
                    {...getFieldProps('fac')}
                  />
                )}
              />
            )}
          </Grid>
        )}

        <Grid item xs={12} md={12}>
          {showAlert && isSuccessApplication && (
            <SuccessAlert
              message="La solicitud se generó correctamente"
              handleDismiss={() => {
                setShowAlert(false);
              }}
            />
          )}
          {showAlert && isErrorApplication && (
            <ErrorAlert
              message={errorApplication as string}
              handleDismiss={() => {
                setShowAlert(false);
              }}
            />
          )}
        </Grid>
        <Grid item xs={12} md={12} textAlign="center">
          <Button
            variant="contained"
            disabled={isLoadingApplication}
            endIcon={isLoadingApplication && <CircularProgress size={20} />}
            type="submit"
          >
            Enviar
          </Button>
          &nbsp;
          {isSuccessApplication && (
            <>
              <Button
                variant="contained"
                // disabled={isLoadingApplication}
                // endIcon={isLoadingApplication && <CircularProgress size={20} />}
                onClick={() => {
                  const file = new Blob([pdfData], { type: 'application/pdf' });
                  const fileURL = URL.createObjectURL(file);

                  window.open(fileURL);
                }}
              >
                Visualizar Certificado
              </Button>
            </>
          )}
        </Grid>
      </Grid>
    </form>
  );
};

export default ActaApplicationForm;
