import { Grid, Typography } from '@material-ui/core';
import MainCard from '../../../../shared/components/cards/MainCard';
import Entries from '../../components/virtuales/Entries';

const EntriesScreen = () => {
  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">Ingresos a la biblioteca</Typography>
      </Grid>
      <Entries />
    </Grid>
  );
};

export default EntriesScreen;
