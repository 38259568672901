import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import { Box, Tab, Tabs, Typography } from '@material-ui/core';

// assets
import DescriptionTwoToneIcon from '@material-ui/icons/DescriptionTwoTone';
import ReceiptTwoToneIcon from '@material-ui/icons/ReceiptTwoTone';
import PersonOutlineTwoToneIcon from '@material-ui/icons/PersonOutlineTwoTone';
// import PanoramaTwoToneIcon from '@material-ui/icons/PanoramaTwoTone';
// import PeopleAltTwoToneIcon from '@material-ui/icons/PeopleAltTwoTone';
// import RecentActorsTwoToneIcon from '@material-ui/icons/RecentActorsTwoTone';

import CreditNoteInformation from './CreditNoteInformation';
import InvoiceDetail from './InvoiceDetail';
import CreditNoteDetail from './CreditNoteDetail';

// tab content customize
function TabPanel({
  children,
  value,
  index,
  ...other
}: {
  children: React.ReactElement | string;
  value: number;
  index: number;
}) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

// style constant
const useStyles = makeStyles((theme) => ({
  accountTab: {
    marginBottom: '24px',
    '& a': {
      minHeight: 'auto',
      minWidth: '10px',
      padding: '12px 8px',
      marginRight: '18px',
      color: theme.palette.grey[600],
    },
    '& a > span': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '& a > span > svg': {
      marginBottom: '0px !important',
      marginRight: '10px',
    },
    '& a > span > span + svg': {
      margin: '0px 0px 0px auto !important',
      width: '14px',
      height: '14px',
    },
  },
  badgeSecondary: {
    color: theme.palette.secondary.main,
    background: theme.palette.secondary.light,
    marginLeft: '10px',
  },
}));

//================================|| UI TABS - COLOR ||================================//

const InvoiceDetailsTabs = () => {
  const classes = useStyles();

  const { t } = useTranslation();

  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <React.Fragment>
      <Tabs
        value={value}
        variant="scrollable"
        onChange={handleChange}
        className={classes.accountTab}
        textColor="primary"
        indicatorColor="primary"
      >
        <Tab
          component={RouterLink}
          to="#"
          icon={<PersonOutlineTwoToneIcon sx={{ fontSize: '1.3rem' }} />}
          label={t('financial.generate_creditnote.tab_information_credit_note.label')}
          {...a11yProps(0)}
        />
        <Tab
          component={RouterLink}
          to="#"
          icon={<DescriptionTwoToneIcon sx={{ fontSize: '1.3rem' }} />}
          label={t('financial.generate_creditnote.tab_invoice_detail.label')}
          {...a11yProps(1)}
        />
        <Tab
          component={RouterLink}
          to="#"
          icon={<ReceiptTwoToneIcon sx={{ fontSize: '1.3rem' }} />}
          label={
            t('financial.generate_creditnote.tab_detail_credit_note.label')
            // <React.Fragment>
            //   friends <Chip label="01" size="small" className={classes.badgeSecondary} />
            // </React.Fragment>
          }
          {...a11yProps(2)}
        ></Tab>
      </Tabs>
      <TabPanel value={value} index={0}>
        <CreditNoteInformation />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <InvoiceDetail />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <CreditNoteDetail />
      </TabPanel>
    </React.Fragment>
  );
};

export default InvoiceDetailsTabs;
