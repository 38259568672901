import { useMemo, useState } from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import Stack from '@material-ui/core/Stack';
import useTheme from '@material-ui/core/styles/useTheme';

import { IconBook2, IconUser } from '@tabler/icons';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { renderSkeletonTable } from '../../../shared/helpers/render';
import { useGetRecentSubjectsQuery } from '../slices/dashboardApiSlice';
import { useAppSelector } from '../../../../hooks/useRedux';
import useGoToVirtualClassroom from '../../academic-portfolio/hooks/useGoToVirtualClassroom';
import { CircularProgress } from '@material-ui/core';
import { scrollStyles } from '../constants/styles';

interface Subject {
  id: number;
  subject_name: string;
  teacher: string;
  modality: string;
  course_id: string;
  campus: string;
  classroom: string;
  parallel: string;
  type_matter?: string;
}

interface ISubjectItem {
  subject: Subject;
}
const SubjectItem = ({ subject }: ISubjectItem) => {
  const theme = useTheme();

  const [valueCourseId, setValueCourseId] = useState(0);

  const { isFetching } = useGoToVirtualClassroom(valueCourseId, 'inicio');

  const { t } = useTranslation();

  return (
    <Grid
      item
      key={subject!.id.toString()}
      sx={{
        cursor: 'pointer',
        borderRadius: '8px', //theme.shape.borderRadius,
        '&:hover': {
          backgroundColor: `${theme.palette.grey[100]}`,
        },
      }}
      onClick={() => setValueCourseId(Number(subject.course_id))}
    >
      <Stack direction="row" spacing={2} alignItems="stretch">
        <Box
          sx={{
            // border: `1px solid ${theme.palette.primary[800]}`,
            backgroundColor: `${theme.palette.primary.main}`,
            padding: 1,
            borderRadius: '8px', //theme.shape.borderRadius,
          }}
        >
          <Grid container item alignItems="center" height="100%">
            {isFetching ? (
              <CircularProgress size={25} color="secondary" />
            ) : (
              <IconBook2 color="white" />
            )}
          </Grid>
        </Box>
        <Box>
          <Stack spacing={1} p={1}>
            <Typography component="span" variant="h4">
              {subject.subject_name}
            </Typography>
            <FormHelperText>
              <b>{<IconUser size={12} color={theme.palette.primary.main} />}</b> {subject.teacher}
            </FormHelperText>
            {subject?.type_matter && (
              <FormHelperText sx={{ color: `${theme.palette.primary.main}` }}>
                {t('students.dashboard.recent_subjects.type_matter')}: {subject.type_matter}
              </FormHelperText>
            )}
            <FormHelperText sx={{ color: `${theme.palette.primary.main}` }}>
              {t('students.dashboard.recent_subjects.campus')}: {subject.campus}
            </FormHelperText>
            <FormHelperText sx={{ color: `${theme.palette.primary.main}` }}>
              {t('students.dashboard.recent_subjects.classroom')}: {subject.classroom}
            </FormHelperText>
            <FormHelperText sx={{ color: `${theme.palette.primary.main}` }}>
              {t('students.dashboard.recent_subjects.parallel')}: {subject.parallel}
            </FormHelperText>
            <FormHelperText sx={{ color: `${theme.palette.primary.main}` }}>
              {t('students.dashboard.recent_subjects.modality')}: {subject.modality}
            </FormHelperText>
          </Stack>
        </Box>
      </Stack>
    </Grid>
  );
};

const RecentSubjects = () => {
  const theme = useTheme();
  const classes = scrollStyles();

  const { t } = useTranslation();

  const { currentRecordStudent } = useAppSelector((state) => state.access);

  const { data, isLoading, isError, error } = useGetRecentSubjectsQuery(
    {
      page: 1,
      perPage: 10,
      student_record: currentRecordStudent?.id!,
    },
    { skip: !currentRecordStudent }
  );

  const datafetch: Subject[] | undefined = useMemo(() => {
    return data?.data?.map((dt) => {
      return {
        id: dt.id,
        subject_name: dt?.subject?.mat_name,
        teacher: dt.pers_full_name || 'N/A',
        modality: dt.modality,
        course_id: dt.course_id,
        campus: dt.cam_name,
        classroom: dt.cl_name || 'N/A',
        parallel: dt.par_name || 'N/A',
        type_matter: dt.subject?.type_matter?.tm_name || 'N/A',
      };
    });
  }, [data]);

  const renderSubjectList = (subject: Subject) => {
    return <SubjectItem subject={subject} key={`subject-list-${subject!.id.toString()}`} />;
  };

  const renderSubjectsList = () => {
    return <>{datafetch?.map(renderSubjectList)}</>;
  };

  return (
    <Box
      sx={{
        border: `1px solid ${theme.palette.secondary.main}`,
        padding: 2,
        height: '100%',
        borderRadius: '12px',
      }}
    >
      <Typography component="h3" variant="h3">
        {t('students.dashboard.recent_subjects.title')}
      </Typography>

      <>
        {isLoading ? (
          renderSkeletonTable(1, 5)
        ) : (
          <Grid container pt={2}>
            <Grid item xs={12} sx={{ maxHeight: 550, overflow: 'hidden' }}>
              <PerfectScrollbar component={'div'} className={classes.scrollRoot}>
                <Stack spacing={2}>
                  {isError ? (
                    <Typography>{error}</Typography>
                  ) : datafetch && !!datafetch.length ? (
                    renderSubjectsList()
                  ) : (
                    <Typography component={'p'} align="center">
                      {t('students.dashboard.recent_subjects.no_data')}
                    </Typography>
                  )}
                </Stack>
              </PerfectScrollbar>
            </Grid>
          </Grid>
        )}
      </>
    </Box>
  );
};

export default RecentSubjects;
