import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../hooks/useRedux';
import MainCard from '../../../shared/components/cards/MainCard';
import DeleteborrowBookDialog from '../components/DeleteReservationDialog';
import UserBorrowedBooksTable from '../components/UserBorrowedBooksTable';

const StudentBorrowedBooks = () => {
  const { t } = useTranslation();

  const { borrowedBookIdSelected, deleteBorrowedBookDialogOpen } = useAppSelector(
    (state) => state.userBorrowedBooks
  );

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">{t('Mis préstamos')}</Typography>
      </Grid>
      <UserBorrowedBooksTable />
      <DeleteborrowBookDialog
        openModal={deleteBorrowedBookDialogOpen}
        reservationId={borrowedBookIdSelected!}
      />
    </Grid>
  );
};

export default StudentBorrowedBooks;
