import { Switch, Redirect } from 'react-router-dom';


import { useAppSelector } from '../../../../hooks/useRedux';

//import { permissionKey, subPermissionKeys } from '../constants/permissions';
import GuardRoute from '../../../../router/guards/GuardRoute';
import AdministrationRolesScreen from '../screens/AdministrationRolesScreen';

const AdministrationRolesRouter = () => {
   const { currentModule } = useAppSelector((state) => state.access);

   const hasPermission = 'crm' in currentModule?.permissions!;

   if (!hasPermission) return <Redirect to="/dashboard" />;

   return (
      <Switch>
         <GuardRoute
            exact
            path="/administracion-roles"
            component={AdministrationRolesScreen}
         /* permissionKey={permissionKey}
         subPermissionKey={subPermissionKeys.create} */
         />

      </Switch>
   )
}

export default AdministrationRolesRouter;