export const meetingsTypes = {
  recurrent: {
    id: 8,
    label: 'Recurrente',
  },
  scheduled: {
    id: 2,
    label: 'Programada',
  },
};

export const approvalType = [
  {
    value: 0,
    label: 'Aprobación automatica',
  },
  { value: 1, label: 'Aprobación manual' },
  { value: 2, label: 'no es necesaria' },
];

export const jbhTime = [
  {
    value: 0,
    label: 'Unirse en cualquier momento',
  },
  { value: 5, label: 'Unirse desde 5 minutos antes' },
  { value: 10, label: 'Unirse desde 10 minutos antes' },
];
