import { Box, Typography, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Attendance } from '../interfaces/attendance.interfaces';
import TableDetailsItemAttendance from './TableDetailsItemAttendance';

interface Props {
  attendance: Attendance[];
}

const TableDetailsAttendance = ({ attendance }: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        width: '90%',
        mt: 5,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          pb: 2,
        }}
      >
        <Box
          sx={{
            width: '50%',
          }}
        >
          <Typography
            variant="h4"
            sx={{
              color: theme.palette.primary.dark,
              fontWeight: 'bold',
              ml: 7,
            }}
          >
            {t('studentVirtualClassroom.attendance.filter.details.type')}
          </Typography>
        </Box>
        <Box
          sx={{
            width: '20%',
          }}
        >
          <Typography
            variant="h4"
            sx={{
              color: theme.palette.primary.dark,
              fontWeight: 'bold',
              ml: 7,
            }}
          >
            Estado
          </Typography>
        </Box>
        <Box
          sx={{
            width: '30%',
          }}
        >
          <Typography
            variant="h4"
            sx={{
              color: theme.palette.primary.dark,
              fontWeight: 'bold',
            }}
          >
            {t('studentVirtualClassroom.attendance.filter.details.date')}
          </Typography>
        </Box>
      </Box>
      {attendance.map((item) => (
        <TableDetailsItemAttendance key={item.id} attendance={item} />
      ))}
    </Box>
  );
};

export default TableDetailsAttendance;
