import { Grid, IconButton, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { IconArrowNarrowLeft } from '@tabler/icons';

import MainCard from '../../../shared/components/cards/MainCard';
import TaskProjectTable from '../components/TaskProjectTable';
import InfoProjectTask from '../components/InfoProjectTask';

const TasksScreen = () => {
  const { t } = useTranslation();

  const history = useHistory();

  const location = useLocation();

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid item container alignItems="center" gap={1}>
        <IconButton onClick={() => history.push(`/${location.pathname.split('/')[1]}`)}>
          <IconArrowNarrowLeft size={30} />
        </IconButton>
        <Typography variant="h2">{t('Tareas')}</Typography>
      </Grid>

      <Grid sx={{ mb: 2 }}>
        <InfoProjectTask />
      </Grid>

      <TaskProjectTable />
    </Grid>
  );
};

export default TasksScreen;
