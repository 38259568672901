import { Switch } from 'react-router-dom';
import PeriodsScreen from '../screens/InstitutesScreen';
import AddInstituteScreen from '../screens/AddInstituteScreen';
import EditInstituteScreen from '../screens/EditInstituteScreen';

import GuardRoute from '../../../../router/guards/GuardRoute';

import { institutesPermissions } from '../constants/permissions';

const PeriodsRouter = () => {
  return (
    <Switch>
      <GuardRoute
        exact
        path="/administrar-institutos"
        component={PeriodsScreen}
        permissionKey={institutesPermissions.groupKey}
        subPermissionKey={institutesPermissions.read}
      />

      <GuardRoute
        exact
        path="/administrar-institutos/agregar"
        component={AddInstituteScreen}
        permissionKey={institutesPermissions.groupKey}
        subPermissionKey={institutesPermissions.create}
      />

      <GuardRoute
        exact
        path="/administrar-institutos/:instituteId/editar"
        component={EditInstituteScreen}
        permissionKey={institutesPermissions.groupKey}
        subPermissionKey={institutesPermissions.edit}
      />
    </Switch>
  );
};

export default PeriodsRouter;
