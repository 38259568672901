import { isValid } from 'date-fns';
import moment from 'moment';
import { MyEvents } from '../interfaces/events.interfaces';

// create a function to reorder the array using the new index
export const reorder = <T>(list: T[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const sortAsc = <T>(list: T[], key: keyof T) => {
  const listToSort = [...list];
  const listSorted = listToSort.sort((a, b) => {
    if (isValid(a[key]) && isValid(b[key])) {
      var c = new Date((a as any).start);
      var d = new Date((b as any).start);
      if (c > d) {
        return 1;
      }
      if (c < d) {
        return -1;
      }
      return 0;
    }

    return a > b ? 1 : -1;
  });
  return listSorted;
};

export const getUnavailableDatesStyle = ({
  myEvents,
  pipWithHolidays,
  IPAKewyword,
  numDays,
  day,
  style,
}: {
  myEvents: MyEvents[];
  pipWithHolidays: MyEvents[];
  IPAKewyword?: string;
  numDays: string;
  day: any;
  style: any;
}) => {
  // calcultate how much days are before the sunday of the week
  const daysBeforeSunday = moment(day).day() === 0 ? 0 : 6 - moment(day).day() + 1;
  // calculate the sunday of the week

  return myEvents
    .filter((event) => event.typeId === IPAKewyword)
    .some(
      (ipaEvent) =>
        ipaEvent.start < day &&
        day <
          moment(ipaEvent.start)
            .add(
              ipaEvent.addExtraWeek
                ? parseInt(numDays) + 7 - daysBeforeSunday
                : parseInt(numDays) - daysBeforeSunday,
              'days'
            )
            .toDate()
    )
    ? style
    : {};
};
