import { Grid, Typography } from '@material-ui/core';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../../../hooks/useRedux';
import MainCard from '../../../../shared/components/cards/MainCard';
import { useGetCatalogByAcronymQuery } from '../../../../shared/slices/catalogApi';

import AcademicFormationSimpleTable from '../../../requisition-teacher/components/simpleTables/AcademicFormationSimpleTable';

const AcademicData = () => {
  const { t } = useTranslation();
  const { dataPersonColl } = useAppSelector((state) => state.teacher);

  const { user } = useAppSelector((state) => state.teacher.collabFile!);

  //id
  const { data: identificationsAvailables = [] } = useGetCatalogByAcronymQuery({
    acronym: 'TI',
  });

  const listAcademicFormation = useMemo(
    () =>
      dataPersonColl.data?.person_education.map((dt) => ({
        id: dt.id,
        certificate_title: dt.pers_edu_certificate,
        graduation_date: dt.pers_edu_graduation_date,
        education_calification: dt.pers_edu_calification,
        education_observation: dt.pers_edu_observation,
        document_url: dt.pers_edu_document_url,
        institute_id: {
          id: +dt.institute_id,
          name: dt.institute?.inst_name || '',
          typeInstituteId: dt.institute?.type_institute_id
            ? Number(dt.institute?.type_institute_id)
            : 0,
        },
        city_id: { id: +dt.city_id, name: dt.city?.cat_name || '' },
        type_education_id: {
          id: +dt.type_education_id,
          name: dt.type_education?.typ_edu_name || '',
        },
        status_id: dt.status_id,
        ies_code: dt.ies_code,
        senescyt_register_number: dt.senescyt_register_number,
        unesco_code: dt.unesco_code,
        start_date: dt.start_date,
        end_date: dt.end_date,
        education_status: dt.education_status,
        country_id: Number(dt.country_id),
      })) || [],
    [dataPersonColl.data?.person_education]
  );

  return (
    <Grid container spacing={2}>
      {false && (
        <Grid item xs={12} md={6}>
          <MainCard title={t('academic.teachers_list.file.personal_data.title')}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Typography variant="h4" component="span">
                  {t('academic.teachers_list.file.personal_data.type_id')}
                </Typography>
                <Typography variant="body2" component="span">
                  {
                    identificationsAvailables.find(
                      (item) => item.id === +user.person.type_identification_id
                    )?.cat_name
                  }
                </Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography variant="h4" component="span">
                  {t('academic.teachers_list.file.personal_data.id')}
                </Typography>
                <Typography variant="body2" component="span">
                  {user.person.pers_identification}
                </Typography>
              </Grid>
            </Grid>
          </MainCard>
        </Grid>
      )}
      <Grid item xs={12} md={10}>
        <MainCard title={t('academic.requisition.edit.wizard.step2.academic_form')}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <AcademicFormationSimpleTable data={listAcademicFormation} readOnly={true} />
            </Grid>
          </Grid>
        </MainCard>
      </Grid>
    </Grid>
  );
};

export default AcademicData;
