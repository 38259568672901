import { Grid, Typography } from '@material-ui/core';
import MainCard from '../../../shared/components/cards/MainCard';
import BirthdayCalendar from '../../../shared/components/calendar/BirthdayCalendar';
import { useTranslation } from 'react-i18next';

const BirthdayScreen = () => {
  const { t } = useTranslation();

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">{t('birthdays.title')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <BirthdayCalendar />
      </Grid>
    </Grid>
  );
};
export default BirthdayScreen;
