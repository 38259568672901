import { useEffect, useState } from 'react';
import { useHistory, useParams, Redirect } from 'react-router-dom';
import {
  Grid,
  Typography,
  InputLabel,
  Button,
  CircularProgress,
  FormHelperText,
  TextField,
  Autocomplete,
} from '@material-ui/core';
import DateRangePicker, { DateRange } from '@material-ui/lab/DateRangePicker';
import { LocalizationProvider } from '@material-ui/lab';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import { useFormik } from 'formik';
import { useFormStyles } from '../../constants/styles';
import { statusAvailables } from '../../../../shared/constants/resourceStatus';
import { stageDates } from '../../constants/validation-schemas';
import esLocale from 'date-fns/locale/es';
import { usePeriodStageEditMutation } from '../../slices/periodsApiSlice';
import ErrorAlert from '../../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../../shared/components/alerts/SuccessAlert';
import { useAppSelector } from '../../../../../hooks/useRedux';
import { format, parseISO } from 'date-fns';
import { useGetPeriodQuery } from '../../slices/periodsApiSlice';

interface FormValues {
  name: string;
  stageId: number;
  startDate: Date | null;
  endDate: Date | null;
  statusId: number;
}

const PeriodStageEdit = function () {
  const { currentProfile } = useAppSelector((state) => state.access);

  const { periodId } = useParams<{ periodId: string }>();
  const { stageId } = useParams<{ stageId: string }>();

  const { periodStagesList } = useAppSelector((state) => state.periods);
  const periodStageToEdit = periodStagesList.find((value) => value.stage_id === stageId);

  const classes = useFormStyles();
  const history = useHistory();

  const [value, setValue] = useState<DateRange<Date>>([null, null]);

  const [showAlerts, setShowAlerts] = useState(false);

  const [periodStageEdit, { isLoading: isUpdating, isError, isSuccess, error }] =
    usePeriodStageEditMutation();

  const { data: periodInfo } = useGetPeriodQuery({
    profileId: currentProfile?.profileId!,
    periodId: Number(periodId),
  });

  const { getFieldProps, setFieldValue, errors, touched, handleSubmit } = useFormik<FormValues>({
    initialValues: {
      name: periodStageToEdit?.stage?.stg_name!,
      stageId: Number(stageId),
      startDate: new Date(periodStageToEdit?.start_date!) || null,
      endDate: new Date(periodStageToEdit?.end_date!) || null,
      statusId: Number(periodStageToEdit?.status_id) || 1,
    },
    validationSchema: stageDates,
    onSubmit: async (values) => {
      await periodStageEdit({
        periodstage: Number(periodStageToEdit?.id),
        periodStagePayload: {
          user_profile_id: currentProfile?.profileId!,
          start_date: format(values.startDate!, 'yyyy-MM-dd'),
          end_date: format(values.endDate!, 'yyyy-MM-dd'),
        },
      });

      setShowAlerts(true);
    },
  });

  const onChangeDates = (newDatesValues: DateRange<Date>) => {
    const [start, end] = newDatesValues;

    // update formik values
    setFieldValue('startDate', start);
    setFieldValue('endDate', end);

    setValue(newDatesValues);
    // console.log( typeof newDatesValues)
  };

  useEffect(() => {
    setValue([parseISO(periodStageToEdit?.start_date!), parseISO(periodStageToEdit?.end_date!)]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, ['']);

  if (!periodStageToEdit) {
    return <Redirect to="/administrar-periodos" />;
  }

  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction="column" gap={2}>
        {/* Sow messages error or success */}
        <Grid item>
          {isError && showAlerts && (
            <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
          )}

          {isSuccess && showAlerts && (
            <SuccessAlert
              message="La etapa se ha actualizado correctamente."
              handleDismiss={() => setShowAlerts(false)}
            />
          )}
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" component="div" sx={{ mb: 3 }}>
            1. Datos de la etapa:
          </Typography>
          <InputLabel className={classes.inputLabel}>Nombre</InputLabel>
          <TextField
            disabled
            fullWidth
            placeholder="Nombre de la etapa"
            {...getFieldProps('name')}
          />
        </Grid>

        {/* Date start and end */}
        <Grid item xs={12}>
          <Typography variant="h5" component="div" sx={{ mb: 3 }}>
            2. Establezca las fechas para la etapa:
          </Typography>

          <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
            <DateRangePicker
              startText={null}
              endText={null}
              value={value}
              onChange={onChangeDates}
              maxDate={new Date(periodInfo?.end_process!)}
              minDate={new Date(periodInfo?.start_process!)}
              renderInput={(startProps, endProps) => (
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} sm={3} lg={4} sx={{ pt: { xs: 2, sm: '0 !important' } }}>
                    <InputLabel
                      className={classes.inputLabel}
                      sx={{ textAlign: { xs: 'left', sm: 'right' } }}
                    >
                      Fecha de Inicio:
                    </InputLabel>
                  </Grid>
                  <Grid item xs={12} sm={9} lg={6}>
                    <TextField
                      fullWidth
                      {...startProps}
                      helperText=""
                      error={Boolean(errors.startDate && touched.startDate)}
                    />
                    <FormHelperText>Fecha de comienzo de la etapa</FormHelperText>

                    {errors.startDate && touched.startDate && (
                      <FormHelperText error>{errors.startDate}</FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={3} lg={4} sx={{ pt: { xs: 2, sm: '0 !important' } }}>
                    <InputLabel
                      className={classes.inputLabel}
                      sx={{ textAlign: { xs: 'left', sm: 'right' } }}
                    >
                      Fecha de Fin:
                    </InputLabel>
                  </Grid>

                  <Grid item xs={12} sm={9} lg={6}>
                    <TextField
                      fullWidth
                      {...endProps}
                      helperText=""
                      error={Boolean(errors.endDate && touched.endDate)}
                    />
                    <FormHelperText>En esta fecha la etapa finalizará</FormHelperText>
                    {errors.endDate && touched.endDate && (
                      <FormHelperText error>{errors.endDate}</FormHelperText>
                    )}
                  </Grid>
                </Grid>
              )}
            />
          </LocalizationProvider>
        </Grid>

        {/* Status section */}
        <Grid item xs={12}>
          <Typography variant="h5" component="div" sx={{ mb: 3 }}>
            3. Elige el estado de la etapa del periodo:
          </Typography>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={3} lg={4} sx={{ pt: { xs: 2, sm: '0 !important' } }}>
              <InputLabel
                className={classes.inputLabel}
                sx={{ textAlign: { xs: 'left', sm: 'right' } }}
              >
                Estado:
              </InputLabel>
            </Grid>
            <Grid item xs={12} sm={9} lg={6}>
              <Autocomplete
                options={statusAvailables}
                defaultValue={statusAvailables.find(
                  (value) => value.id === periodStageToEdit?.status.id
                )}
                getOptionLabel={(option) => option.name}
                onChange={(_, value) => setFieldValue('statusId', value?.id || '')}
                clearIcon={null}
                noOptionsText="No hay opciones disponibles"
                renderInput={(params) => (
                  <TextField {...params} error={Boolean(errors && touched)} />
                )}
                disabled
                fullWidth
              />
              {/* <FormHelperText>Selección de estado</FormHelperText> */}
              {/* {errors.statusId && touched.statusId && (
                <FormHelperText error>{errors.statusId}</FormHelperText>
              )} */}
            </Grid>
          </Grid>
        </Grid>

        {/* Asign action forms */}
        <Grid container alignItems="center" justifyContent="center" spacing={2} sx={{ mt: 2 }}>
          <Grid item>
            <Button
              variant="outlined"
              size="large"
              color="primary"
              onClick={() => history.goBack()}
            >
              Regresar
            </Button>
          </Grid>
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              disabled={isUpdating}
              endIcon={isUpdating && <CircularProgress size={20} />}
            >
              Actualizar etapa
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default PeriodStageEdit;
