import React, { Ref } from 'react';

// material-ui
import { useTheme } from '@material-ui/core/styles';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  CardProps,
  CardHeaderProps,
  CardContentProps,
} from '@material-ui/core';

// project imports
import { KeyedObject } from '../../../shared/interfaces/material-ui.interfaces';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallbackComponentScreen from '../errors-boundary/ErrorFallbackComponentScreen';

// constant
const headerSX = {
  '& .MuiCardHeader-action': { mr: 0 },
};

//-----------------------|| CUSTOM MAIN CARD ||-----------------------//

export interface MainCardProps extends KeyedObject {
  border?: boolean;
  boxShadow?: boolean;
  children: React.ReactNode | string;
  style?: React.CSSProperties;
  content?: boolean;
  className?: string;
  contentClass?: string;
  contentSX?: CardContentProps['sx'];
  darkTitle?: boolean;
  sx?: CardProps['sx'];
  secondary?: CardHeaderProps['action'];
  shadow?: string;
  elevation?: number;
  title?: React.ReactNode | string;
  onClick?: () => void;
}

const MainCard = React.forwardRef(
  (
    {
      border = false,
      boxShadow,
      children,
      content = true,
      contentClass = '',
      contentSX = {},
      darkTitle,
      secondary,
      shadow,
      sx = {},
      title,
      onClick,
      ...others
    }: MainCardProps,
    ref: Ref<HTMLDivElement>
  ) => {
    const theme = useTheme();

    return (
      <ErrorBoundary FallbackComponent={ErrorFallbackComponentScreen}>
        <Card
          ref={ref}
          {...others}
          sx={{
            border: border ? '1px solid' : 'none',
            borderColor:
              theme.palette.mode === 'dark'
                ? theme.palette.background.default
                : theme.palette.grey[100],
            ':hover': {
              boxShadow: boxShadow
                ? shadow
                  ? shadow
                  : theme.palette.mode === 'dark'
                  ? '0 2px 14px 0 rgb(33 150 243 / 10%)'
                  : '0 2px 14px 0 rgb(32 40 45 / 8%)'
                : 'inherit',
              ...(onClick && { cursor: 'pointer', opacity: 0.8 }),
            },
            ...sx,
          }}
          {...(onClick && { onClick })}
        >
          {/* card header and action */}
          {!darkTitle && title && <CardHeader sx={headerSX} title={title} action={secondary} />}
          {darkTitle && title && (
            <CardHeader
              sx={headerSX}
              title={<Typography variant="h3">{title}</Typography>}
              action={secondary}
            />
          )}

          {/* content & header divider */}
          {title && <Divider />}

          {/* card content */}
          {content && (
            <CardContent sx={contentSX} className={contentClass}>
              {children}
            </CardContent>
          )}
          {!content && children}
        </Card>
      </ErrorBoundary>
    );
  }
);

export default MainCard;
