import { useState, useCallback, useRef, useMemo } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import {
  Button,
  FormHelperText,
  Grid,
  Stack,
  IconButton,
  Typography,
  Box,
} from '@material-ui/core';
import { IconTrash, IconCornerDownRight, IconRotateClockwise2 } from '@tabler/icons';
import { useFormik } from 'formik';
import { Calendar, momentLocalizer, CalendarProps } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import 'moment/locale/es';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';

import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import { useAppSelector, useAppDispatch } from '../../../../hooks/useRedux';
import { drawerWidth } from '../../../shared/constants/uiConstants';
import Simbologies from './symbologies/Symbologies';
import {
  setTemplateAcademicEvents,
  setInitialProgramsAndEvents,
} from '../slices/acPlanPostgraduateSlice';
import useOnScreen from '../hooks/useOnScreen';
import Transitions from '../../../shared/components/extended/Transitions';
import { MyEvents, InitialProgramsAndEvents } from '../interfaces/events.interfaces';

const useStyles = makeStyles((theme) => ({
  calendar: {
    '& .rbc-btn-group': { display: 'none' },
  },
}));

const DnDCalendar = withDragAndDrop(Calendar as React.ComponentType<CalendarProps>);

const CalendarFormTemplate = () => {
  const history = useHistory();

  const dispatch = useAppDispatch();

  const classes = useStyles();

  const { opened } = useAppSelector((state) => state.customization);

  const { initialInfoPlanning, initialEventsPlanning, templateAcademicEvents, initialPrograms } =
    useAppSelector((state) => state.academicPlanningPosgraduate);

  const { colorEvents } = useAppSelector((state) => state.academicPlanningPosgraduate);

  const { dragEvent } = useAppSelector((state) => state.academicPlanningPosgraduate);

  const ref = useRef<HTMLDivElement>(null!);

  const isVisible = useOnScreen(ref);

  const localizer = momentLocalizer(moment);

  const [showAlerts, setShowAlerts] = useState(false);

  const [selectedtEvent, setSelectedEvent] = useState<MyEvents>(null!);

  const [currentDate, setCurrentDate] = useState<Date>(new Date());

  // La libreria  me obliga a tener este estado pese a que no se lo utiliza :'C
  if (false) {
    console.log(currentDate);
  }

  const { firstDay, titleWorkshop, firstWeek, selfEmployment, holiday } = useMemo(
    () => ({
      firstDay: colorEvents.find((event) => event.pdt_keywork === 'IDA'),
      holiday: colorEvents.find((event) => event.pdt_keywork === 'FDS'),
      firstWeek: colorEvents.find((event) => event.pdt_keywork === 'PAU'),
      titleWorkshop: colorEvents.find((event) => event.pdt_keywork === 'TST'),
      selfEmployment: colorEvents.find((event) => event.pdt_keywork === 'TAU'),
      startProgram: colorEvents.find((event) => event.pdt_keywork === 'IPA'),
    }),
    //eslint-disable-next-line
    [colorEvents]
  );

  const [myEvents, setMyEvents] = useState<MyEvents[]>(templateAcademicEvents);

  const moveEvent = useCallback(
    ({ event, start, end }) => {
      setMyEvents((prev: any) => {
        const existing = prev.find((ev: any) => ev.id === event.id) ?? {};
        const filtered = prev.filter((ev: any) => ev.id !== event.id);
        return [...filtered, { ...existing, start, end, title: event.title, color: event.color }];
      });
    },
    [setMyEvents]
  );

  const handleDeleteEvent = (id: string) => {
    const eventFilter = myEvents.filter((event) => !(event.id === id));
    setMyEvents(eventFilter);
    setSelectedEvent(null!);
  };

  const { errors, handleSubmit } = useFormik({
    initialValues: {
      cant: 0,
    },
    onSubmit: async (values) => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      setShowAlerts(true);
      dispatch(setTemplateAcademicEvents(myEvents));
      generateAcademicEventsAllPrograms();
      return <Redirect to="/planeacion-academica-posgrado/fechas-carrera" />;
    },
  });

  const validateTypeEvent = (eventType: string) => {
    let color, name, typeId: string;
    let dayInit, days: number;
    switch (eventType) {
      case firstDay?.pdt_keywork:
        color = firstDay?.pdt_colour || '';
        name = firstDay?.pdt_description_day || '';
        typeId = firstDay?.pdt_keywork || '';
        dayInit = 0;
        days = 1;
        break;
      case titleWorkshop?.pdt_keywork:
        color = titleWorkshop?.pdt_colour || '';
        name = titleWorkshop?.pdt_description_day || '';
        typeId = titleWorkshop?.pdt_keywork || '';
        dayInit = 5;
        days = 1;
        break;
      case firstWeek?.pdt_keywork:
        color = firstWeek?.pdt_colour || '';
        name = firstWeek?.pdt_description_day || '';
        typeId = firstWeek?.pdt_keywork || '';
        dayInit = 1;
        days = 6;
        break;
      default:
        color = selfEmployment?.pdt_colour || '';
        name = selfEmployment?.pdt_description_day || '';
        typeId = firstWeek?.pdt_keywork || '';
        dayInit = 6;
        days = 28;
    }
    return { color, name, typeId, dayInit, days };
  };

  const generatorAcademicEvents = () => {
    const academicEvents: MyEvents[] = []!;

    initialEventsPlanning
      .filter((events) => events.typeId === 'PIP')
      .forEach((program, index) => {
        [
          firstDay?.pdt_keywork!,
          titleWorkshop?.pdt_keywork!,
          firstWeek?.pdt_keywork!,
          selfEmployment?.pdt_keywork!,
        ].map((e) => {
          let start = moment(program.start).add(validateTypeEvent(e).dayInit, 'days').toDate();

          return academicEvents.push({
            start,
            end: moment(program.end)
              .add(
                validateTypeEvent(e).days +
                  (validateTypeEvent(e).name === selfEmployment?.pdt_description_day &&
                  initialEventsPlanning
                    .filter((event) => event.typeId === holiday?.pdt_keywork)
                    .some(
                      (holidayEvent) =>
                        holidayEvent.start >= program.start &&
                        holidayEvent.start <= moment(program.start).add(28, 'days').toDate()
                    )
                    ? 7
                    : 0),
                'days'
              )
              .toDate(),
            color: validateTypeEvent(e).color,
            title: validateTypeEvent(e).name,
            id: `generate${e}${index}`,
            typeId: validateTypeEvent(e).typeId,
            droppedOnAllDaySlot: true,
          });
        });
      });

    setMyEvents(academicEvents);
  };

  if (!initialInfoPlanning.facultad || !initialEventsPlanning.length) {
    return <Redirect to="/planeacion-academica-posgrado/fechas-carrera" />;
  }

  const generateAcademicEventsAllPrograms = () => {
    // recorrer los programas iniciales y validar desde el valor minimo hasta el maxima
    try {
      const initialEventsPlanningPIP = initialEventsPlanning.filter(
        (event) => event.typeId === 'PIP'
      );

      const sortInitialPlanningPIP = initialEventsPlanningPIP.sort((a, b) => {
        var c = new Date(a.start);
        var d = new Date(b.start);
        if (c > d) {
          return 1;
        }
        if (c < d) {
          return -1;
        }
        return 0;
      });

      const initialProgramsAndEvents: InitialProgramsAndEvents[] = initialPrograms.map(
        (program, idx) => {
          const filterSortInitialPlanningPIP = sortInitialPlanningPIP.filter(
            (sortPlanning) => sortPlanning.start >= program.start
          );

          const careerId = program.eduLevelId || '0';
          const courses = Number(
            initialInfoPlanning?.career.find((career) => career.id + '' === careerId + '')
              ?.meshs?.[0]?.mes_number_matter || 0
          );

          return {
            title: program.title,
            id: program.id,
            courses,
            events: myEvents.filter((event) =>
              filterSortInitialPlanningPIP.length >= Number(courses)
                ? event.start >= program.start &&
                  event.start < filterSortInitialPlanningPIP[Number(courses) - 1].end
                : event.start >= program.start
            ),
          };
        }
      );

      dispatch(setInitialProgramsAndEvents(initialProgramsAndEvents));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction="row" justifyContent="center" alignItems="center" gap={2}>
        <Grid item>
          {showAlerts && (
            <SuccessAlert
              message="Se agregó correctamente esta plantilla a los programas asignados"
              handleDismiss={() => setShowAlerts(false)}
            />
          )}
        </Grid>

        <Grid
          item
          container
          mt={2}
          xs={10}
          spacing={2}
          justifyContent="center"
          alignItems="baseline"
          ref={ref}
        >
          <Box>
            <Typography align="center" variant="h3" component="div" mb={1}>
              {initialInfoPlanning.facultad?.edu_name}
            </Typography>
            <Typography align="center" variant="h2" component="div" mb={4}>
              {initialInfoPlanning.date.getFullYear()}
            </Typography>
            {initialInfoPlanning.career.map((program) => (
              <Typography align="center" component="div">
                {program.edu_name}
              </Typography>
            ))}
          </Box>
        </Grid>

        <Grid item container md={10} spacing={2} justifyContent="left" alignItems="center" mt={2}>
          <Grid item sx={{ width: '100%' }}>
            <Simbologies
              simbologies={colorEvents
                .filter(
                  (infoEvent) =>
                    !['IPA', 'FDS', 'PIP'].some((acronym) => acronym === infoEvent.pdt_keywork)
                )
                .map((infoEvent) => ({
                  label: infoEvent.pdt_description_day,
                  color: infoEvent.pdt_colour,
                  index: myEvents.length,
                  typeId: infoEvent.pdt_keywork,
                }))}
            />
          </Grid>
        </Grid>

        <Grid item container xs={10} spacing={2} justifyContent="right" alignItems="center">
          <Grid item>
            <Button
              variant="contained"
              color={'primary'}
              endIcon={<IconRotateClockwise2 />}
              onClick={() => generatorAcademicEvents()}
            >
              Generar
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              color={'primary'}
              onClick={() => setMyEvents([] as MyEvents[])}
            >
              Limpiar
            </Button>
          </Grid>
        </Grid>

        {!isVisible && (
          <Grid
            item
            container
            md={6}
            spacing={2}
            justifyContent="center"
            alignItems="center"
            mt={2}
            sx={{
              position: 'fixed',
              left: !opened ? '70px' : `calc(${drawerWidth}px + 20px )`,
              bottom: '10px',
              // backgroundColor: 'white',
              zIndex: '10',
            }}
          >
            <Grid item xs={12}>
              <Simbologies
                simbologies={colorEvents
                  .filter(
                    (infoEvent) =>
                      !['IPA', 'FDS', 'PIP'].some((acronym) => acronym === infoEvent.pdt_keywork)
                  )
                  .map((infoEvent) => ({
                    label: infoEvent.pdt_description_day,
                    color: infoEvent.pdt_colour,
                    index: myEvents.length,
                    typeId: infoEvent.pdt_keywork,
                  }))}
              />
            </Grid>
          </Grid>
        )}

        <Transitions type="fade" in={!!selectedtEvent} position="bottom" direction={'left'}>
          <Stack
            direction="column"
            spacing={2}
            sx={{
              position: 'fixed',
              right: '50px',
              bottom: '10vh',
              zIndex: '10',
            }}
          >
            <Grid
              container
              sx={{
                boxShadow: '0px 0px 20px #CADDF2',
                backgroundColor: '#fff',
                padding: 1,
                borderRadius: '10px 0 0 10px',
                borderStyle: `solid`,
                borderColor: `${selectedtEvent ? selectedtEvent?.color : '#fff'}`,
                borderWidth: '0 8px 0 0',
              }}
            >
              <Typography align="right" color="inherit" variant="h5">
                {selectedtEvent && selectedtEvent.title}
              </Typography>
            </Grid>
            <Grid container justifyContent="flex-end" alignItems="center">
              <IconButton
                aria-label="delete"
                color="primary"
                size="medium"
                sx={{ boxShadow: '0px 0px 20px #CADDF2', backgroundColor: '#fff' }}
                onClick={() => handleDeleteEvent(selectedtEvent.id)}
              >
                <IconTrash />
              </IconButton>
            </Grid>
            <Grid container justifyContent="flex-end" alignItems="center">
              <IconButton
                aria-label="delete"
                color="primary"
                size="medium"
                sx={{ boxShadow: '0px 0px 20px #CADDF2', backgroundColor: '#fff' }}
              >
                <IconCornerDownRight />
              </IconButton>
            </Grid>
          </Stack>
        </Transitions>

        {errors && (
          <FormHelperText error>
            {Object.keys(errors).map((error) => errors?.[error as keyof typeof errors])}
          </FormHelperText>
        )}

        <Grid item container md={10} spacing={4} justifyContent="center" alignItems="center" mt={4}>
          {Array.from(
            new Array(Number(initialInfoPlanning.curriculum.mes_number_matter) + 3 || 14)
          ).map((e, index) => (
            <Grid item xs={12} md={6} lg={4} key={index}>
              <DnDCalendar
                views={['month']}
                events={myEvents}
                selectable
                dayPropGetter={(day) => {
                  var style = {
                    // backgroundColor: '#E6E6E6',
                    display: 'block',
                  };
                  var styleFDS = {
                    backgroundColor: '#ffff0070',
                    display: 'block',
                  };
                  var styleDayPlanning = {
                    backgroundColor: `lighten($ff0000, 10%)`,
                    display: 'block',
                  };
                  return {
                    style: initialEventsPlanning
                      .filter((event) => event.typeId === 'PIP')
                      .some(
                        (ipaEvent) =>
                          moment(ipaEvent.start).format('YYYY-MM-DD') ===
                          moment(day).format('YYYY-MM-DD')
                      )
                      ? styleDayPlanning
                      : initialEventsPlanning
                          .filter((event) => event.typeId === 'FDS')
                          .some(
                            (ipaEvent) =>
                              moment(ipaEvent.start).format('YYYY-MM-DD') ===
                              moment(day).format('YYYY-MM-DD')
                          )
                      ? styleFDS
                      : style,
                  };
                }}
                key={index}
                className={classes.calendar}
                date={moment(new Date(initialInfoPlanning.date)).add(index, 'month').toDate()}
                localizer={localizer}
                onNavigate={(date) => setCurrentDate(date)}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 400 }}
                onSelectEvent={(e) => setSelectedEvent(e as unknown as MyEvents)}
                onSelectSlot={() => setSelectedEvent(null!)}
                onDropFromOutside={(event) =>
                  setMyEvents([
                    ...myEvents,
                    {
                      start: new Date(event.start),
                      end: new Date(event.start),
                      color: dragEvent.color!,
                      id: `${myEvents.length + 1}`,
                      title: dragEvent.title!,
                      droppedOnAllDaySlot: true,
                      typeId: dragEvent.typeId!,
                    },
                  ])
                }
                eventPropGetter={(event) => {
                  var backgroundColor = (event as unknown as MyEvents)?.color;
                  var style = {
                    backgroundColor: backgroundColor,
                    borderRadius: '0px',
                    opacity: 0.8,
                    color: 'black',
                    border: '0px',
                    display: 'block',
                  };
                  return {
                    style: style,
                  };
                }}
                onEventDrop={moveEvent}
              />
            </Grid>
          ))}
        </Grid>

        <Grid
          item
          container
          alignItems="center"
          justifyContent="flex-end"
          spacing={2}
          sx={{ mt: 1 }}
        >
          <Grid item>
            <Button
              variant="outlined"
              size="large"
              color="primary"
              onClick={() => history.goBack()}
            >
              Regresar
            </Button>
          </Grid>
          <Grid item>
            <Button type="submit" variant="contained" size="large" color="primary">
              Guardar plantilla
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default CalendarFormTemplate;
