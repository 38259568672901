import { Alert, IconButton, AlertProps } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

interface Props extends AlertProps {
   message: string | string[];
   handleDismiss?: () => void;
}

const InfoAlert = ({ message, handleDismiss, ...props }: Props) => {
   return (
      <Alert
         icon={false}
         severity="info"
         action={
            handleDismiss ? (
               <IconButton size="small" aria-label="close" color="inherit" onClick={handleDismiss}>
                  <CloseIcon fontSize="small" />
               </IconButton>
            ) : null
         }
         {...props}
      >
         {Array.isArray(message) ? message.map((m) => <li key={m}>{m}</li>) : message}
      </Alert>
   );
};

export default InfoAlert;
