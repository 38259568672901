import { FC } from 'react';
import { createPortal } from 'react-dom';

interface Props {
  containerId: string;
}

const PortalAction: FC<Props> = ({ children, containerId }) => {
  const container = document.getElementById(containerId);
  return container ? createPortal(<>{children}</>, container!) : <></>;
};

export default PortalAction;
