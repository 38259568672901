import * as Yup from 'yup';

export const EconomicGroupInfoSchema = Yup.object().shape({
  name: Yup.string().required('financial.economic_group.add.name_required'),
  description: Yup.string(),
  statusId: Yup.number().required('financial.economic_group.add.description_required'),
  eco_gro_max_amount: Yup.number()
    .required('financial.economic_group.add.max_amount_required')
    .positive('financial.economic_group.add.max_amount_invalid'),
  eco_gro_min_amount: Yup.number()
    .required('financial.economic_group.add.min_amount_required')
    .positive('financial.economic_group.add.min_amount_invalid'),
});
