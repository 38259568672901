import { TreeItem } from '@material-ui/lab';
import { Link, useRouteMatch } from 'react-router-dom';
import EditButton from '../../../shared/components/tables/buttons/EditButton';
import { Children, Status, Position } from '../interfaces/departments.interface';
import { Grid, Typography } from '@material-ui/core';
import useGuard from '../hooks/useGuard';
import StatusChip from '../../../shared/components/badges/StatusChip';
//import { useState } from 'react';

export interface Department {
  id: number;
  dep_name: string;
  parent_id: string | null | number;
  dep_description: null;
  status_id: string;
  status: Status;
  children: Children[];
  positions?: Position[];
}
const DepartmentItem = ({ departamento }: { departamento: Department }) => {
  const { path } = useRouteMatch();
  const { hasAccess } = useGuard();
  //const [mouseHover, setMouseHover] = useState(false);
  return (
    <TreeItem
      //onMouseOver={() => setMouseHover(true)}
      //onMouseLeave={() => setMouseHover(false)}
      key={departamento.id}
      nodeId={'' + departamento.id}
      sx={{ borderRadius: 4 }}
      label={
        <Grid container alignItems="center" justifyContent="center">
          <Grid item xs={7} sm={9} sx={{ textAlign: 'left', verticalAlign: 'middle' }}>
            <Typography variant="body2">{departamento.dep_name}</Typography>
          </Grid>
          <Grid
            item
            xs={5}
            sm={3}
            container
            justifyContent="center"
            alignItems="center"
            sx={{ height: '50px' }}
          >
            <Grid item>
              <StatusChip statusId={Number(departamento.status_id)} />
            </Grid>

            <Grid item>
              {hasAccess('edit') && (
                <Link to={`${path}/${departamento.id}/editar`}>
                  <EditButton />
                </Link>
              )}
            </Grid>
          </Grid>
        </Grid>
      }
    >
      {departamento?.children?.map((d) => (
        <DepartmentItem departamento={d} />
      ))}
    </TreeItem>
  );
};
export default DepartmentItem;
