import { useState } from 'react';
import Grid from '@material-ui/core/Grid';

// import PublicationsFilter from './PublicationsFilter';
import PublicationsTable from './PublicationsTable';
import { useGetPublicationsResearchQuery } from '../slices/publicationsApiSlice';
// import { FilterData } from '../interfaces/filterDateProjectLinesInvestigation.interfaces';
import { useAppSelector } from '../../../../hooks/useRedux';
import { FilterArrow } from '../../../shared/components/tables/SearchPaginatedTable';

export interface FilterData {
  publication: FilterArrow;
}

const ProjectsMain = () => {
  const { page, perPage, search } = useAppSelector((state) => state.publications.publicationsTable);

  const [sort, setSort] = useState<FilterData>({
    publication: { name: 'title', direction: 'asc' },
  });
  const { data, error, isLoading, isFetching } = useGetPublicationsResearchQuery({
    search,
    type_sort: sort.publication.direction,
    sort: sort.publication.name,
    page,
    size: perPage,
  });

  return (
    <Grid container spacing={2}>
      {/* <Grid item xs={12}>
        <PublicationsFilter dispatchFilterData={setFilterData} searchFunction={() => refetch()} />
      </Grid> */}
      <Grid item xs={12}>
        <PublicationsTable
          data={data!}
          isLoading={isLoading}
          error={error}
          isFetching={isFetching}
          setSort={setSort}
          sort={sort}
        />
      </Grid>
    </Grid>
  );
};

export default ProjectsMain;
