import { useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import DeleteAlert from '../../../shared/components/dialogs/DeleteAlert';
import { useDeleteMatterMeshMutation, useGetMeshQuery } from '../slices/meshsApiSlice';
import {
  fireDeleteSubjectCurriculumDialog,
  resetSubjectCurriculumSelected,
} from '../slices/meshSlice';

const DeleteSubjectCurriculumDialog = () => {
  const dispatch = useAppDispatch();

  const { meshId: meshIdParam } = useParams<{ meshId: string }>();
  const meshId = Number(meshIdParam);

  const { currentProfile } = useAppSelector((state) => state.access);
  const profileId = currentProfile!.profileId;

  const { subjectCurriculumSelected, deleteSubjectCurriculumDialogOpened } = useAppSelector(
    (state) => state.meshs
  );

  const [deleteMatterMesh, { isLoading: isDeleting, isSuccess, isError, error }] =
    useDeleteMatterMeshMutation();

  const { data } = useGetMeshQuery({ profileId, meshId });

  const handleDelete = () => {
    deleteMatterMesh({
      profileId: currentProfile?.profileId!,
      matterMeshId: subjectCurriculumSelected!,
    });
  };

  const handleClose = () => {
    dispatch(fireDeleteSubjectCurriculumDialog(false));
    dispatch(resetSubjectCurriculumSelected());
  };

  const subjectCurriculumToDelete = data?.matter_mesh.find(
    (sc) => sc.id === subjectCurriculumSelected
  );

  return (
    <DeleteAlert
      open={deleteSubjectCurriculumDialogOpened}
      onClose={handleClose}
      isLoading={isDeleting}
      isSuccess={isSuccess}
      isError={isError}
      handleDelete={handleDelete}
      error={error as string}
      deleteTitle="¿Estás seguro de quitar esta materia?"
      deleteSubTitle={`La materia ${subjectCurriculumToDelete?.matter_rename} será removida de la malla curricular`}
    />
  );
};

export default DeleteSubjectCurriculumDialog;
