import { Switch, Redirect } from 'react-router-dom';

import { useAppSelector } from '../../../../hooks/useRedux';

import ELSScreen from '../screens/ELCScreen';
import AddELSScreen from '../screens/AddELCScreen';

import ELSGroupScreen from '../screens/ELCGroupScreen';
import AddELSGroupScreen from '../screens/AddELCGroupScreen';
import EditELSGroupScreen from '../screens/EditELCGroupScreen';
import GuardRoute from '../../../../router/guards/GuardRoute';

const ELSRouter = () => {
  const { currentModule } = useAppSelector((state) => state.access);

  const hasPermission = 'areaGroup' in currentModule?.permissions!;

  if (!hasPermission) return <Redirect to="/dashboard" />;

  return (
    <Switch>
      <GuardRoute
        exact
        path="/administrar-carreras-de-nivelacion"
        component={ELSScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.read}
      />
      <GuardRoute
        exact
        path="/administrar-carreras-de-nivelacion/agregar"
        component={AddELSScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.read}
      />

      {/* Group */}
      <GuardRoute
        exact
        path="/administrar-carreras-de-nivelacion/:groupId/carreras"
        component={ELSGroupScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.read}
      />
      <GuardRoute
        exact
        path="/administrar-carreras-de-nivelacion/:groupId/carreras/agregar"
        component={AddELSGroupScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.read}
      />
      <GuardRoute
        exact
        path="/administrar-carreras-de-nivelacion/:groupId/carreras/:ELSId/editar"
        component={EditELSGroupScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.read}
      />
    </Switch>
  );
};

export default ELSRouter;
