import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import MainCard from '../../../shared/components/cards/MainCard';
import ReceiptsTable from '../components/ReceiptsTable';

const ReceiptScreen = () => {
  const { t } = useTranslation();

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">{t('financial.receipts.title')}</Typography>
      </Grid>

      <ReceiptsTable />
    </Grid>
  );
};
export default ReceiptScreen;
