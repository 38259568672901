export interface Subject {
  matter_id: string;
  matter_rename: string;
  keyword_status_matter: KeywordStatusMatter;
  status_matter: StatusMatter;
  st_name: StName;
  status_id: null | string;
  cod_matter_migration: string;
}

export enum KeywordStatusMatter {
  APR = 'APR',
  Ndf = 'NDF',
}

export enum StName {
  Activo = 'Activo',
}

export enum StatusMatter {
  Aprobado = 'Aprobado',
  NoCursada = 'No cursada',
}
