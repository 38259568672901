import { createSlice } from '@reduxjs/toolkit';

interface AcademicHourhandState {
  academicHourhandData: [];
}

const initialState: AcademicHourhandState = {
  academicHourhandData: [],
};

const academicHourhandSlice = createSlice({
  name: 'hourhands',
  initialState,
  reducers: {
    setAcademicHourhand: (state, action) => {
      state.academicHourhandData = action.payload;
    },
  },
});

export const { setAcademicHourhand } = academicHourhandSlice.actions;

export default academicHourhandSlice.reducer;
