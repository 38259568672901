import { Grid, Typography } from '@material-ui/core';
import MainCard from '../../../../shared/components/cards/MainCard';
import MostViewed from '../../components/borrows/MostViewed';
//import { useAppSelector } from '../../../../hooks/useRedux';

const MostViewedScreen = () => {
  //const { authorToDelete } = useAppSelector((state) => state.authors);
  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">Libros más solicitados</Typography>
      </Grid>

      <MostViewed />
    </Grid>
  );
};

export default MostViewedScreen;
