import React, { useEffect, useState } from 'react';

import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Grid,
  Slide,
  SlideProps,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

// import PerfectScrollbar from 'react-perfect-scrollbar';

import SubjectRegisteredCard from './SubjectRegisteredCard';
import {
  useGetCourseAprovalStatusQuery,
  useLazyGetRegisterSubjectPDFQuery,
} from '../slices/subjectsRegisterApiSlice';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import IconTelework from '../../../shared/components/illustrations/IconTelework';
import useStudentInfo from '../hooks/useStudentInfo';
import { setCurrentSubjectsRegistered } from '../slices/subjectsRegisterSlice';

import ExtendedSideDialog from '../../../shared/components/dialogs/ExtendedSideDialog';
import { useParams } from 'react-router-dom';
import download from 'downloadjs';
import { IconDownload } from '@tabler/icons';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';

export const useStyles = makeStyles((theme) => ({
  userAddDialog: {
    '&>div:nth-child(3)': {
      justifyContent: 'flex-end',
      '&>div': {
        margin: '0px',
        borderRadius: '0px',
        maxWidth: '450px',
        maxHeight: '100%',
      },
    },
  },
  paper: {
    minHeight: '100vh',
    width: '33%',
  },
  learningComponents: {
    fontSize: '16px',
    fontWeight: 600,
    color: theme.palette.primary.main,
    marginBottom: '8px',
  },
  selectSubject: {
    fontSize: '16px',
    fontWeight: 600,
    color: theme.palette.primary[800],
    marginBottom: '8px',
  },
  selectSubjectType: {
    fontSize: '14px',
    fontWeight: 600,
    color: theme.palette.secondary.main,
    marginBottom: '8px',
  },
}));

export const Transition = React.forwardRef(function Transition(props: SlideProps, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

interface Props {
  open: boolean;
  // period?: string;
  handleCloseDialog: () => void;
}

const SubjectsRegisteredDialog = ({ open, handleCloseDialog }: Props) => {
  const { t } = useTranslation();

  // const { userInfo } = useAppSelector((state) => state.auth);
  const studentInfo = useStudentInfo();

  const theme = useTheme();

  const { currentStudent } = useAppSelector((state) => state.access);

  const { currentPeriodId } = useAppSelector((state) => state.subjectsRegister);

  const dispatch = useAppDispatch();

  const { studentId } = useParams<{ studentId?: string }>();

  const [isDownloadingPDF, setIsDownloadingPDF] = useState(false);

  const [downloadPdfError, setDownloadPdfError] = useState(null);

  const classes = useStyles();

  const [skip, setSkip] = useState(true);

  const { data: courseAvailables = [], isLoading: isLoadingC } = useGetCourseAprovalStatusQuery(
    {
      studentRecordId: studentInfo?.studentRecordId!,
      approval_status: 'CU',
      period_id: currentPeriodId!,
    },
    { skip, refetchOnMountOrArgChange: 30 }
  );

  // console.log(courseAvailables);
  const subjectCoursing = courseAvailables.map((c) => {
    return <SubjectRegisteredCard course={c} key={c.id} />;
  });

  useEffect(() => {
    if (currentPeriodId && studentInfo) setSkip(false);
  }, [currentPeriodId, studentInfo]);

  useEffect(() => {
    if (courseAvailables.length > 0) dispatch(setCurrentSubjectsRegistered(courseAvailables));
  }, [dispatch, courseAvailables]);

  const [getPDF] = useLazyGetRegisterSubjectPDFQuery();

  const handleDownloadPDF = async () => {
    try {
      setIsDownloadingPDF(true);
      const data = await getPDF({
        periodId: currentPeriodId?.toString() || '',
        studentId: studentId || currentStudent?.id.toString() || '',
      }).unwrap();

      if (!data) throw new Error('Ha ocurrido un error');

      const blob = new Blob([data], { type: 'application/pdf' });

      download(blob, `cursos_registrados_${currentPeriodId}.pdf`, 'application/pdf');
      setDownloadPdfError(null);
    } catch (error: any) {
      if ('message' in error) setDownloadPdfError((error as any).message as any);
      else setDownloadPdfError(error as any);
    }

    setIsDownloadingPDF(false);
  };

  return (
    <ExtendedSideDialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => {
        handleCloseDialog();
      }}
      className={classes.userAddDialog}
      classes={{
        paper: classes.paper,
      }}
    >
      {/* <PerfectScrollbar> */}
      <DialogContent>
        {downloadPdfError !== null && (
          <Grid item xs={12}>
            <ErrorAlert
              type="snackbar"
              message={downloadPdfError as unknown as string}
              handleDismiss={() => setDownloadPdfError(null)}
            />
          </Grid>
        )}
        <Typography variant="h2">
          {t('students.subjects.registration.registered_subjects.modal.title')}
        </Typography>

        {courseAvailables.length !== 0 ? (
          <Stack spacing={2} sx={{ mt: 4 }}>
            {isLoadingC ? 'Cargando...' : subjectCoursing}
          </Stack>
        ) : (
          <Box>
            <Grid
              container
              justifyContent={'center'}
              alignContent={'center'}
              sx={{ height: '80vh' }}
            >
              <Grid container justifyContent={'center'} item xs={8} md={6}>
                <Grid container item xs={12} justifyContent={'center'}>
                  <IconTelework size={300} />
                </Grid>
                <Typography variant={'body1'} textAlign={'center'} lineHeight={2} component={'div'}>
                  <Typography
                    variant={'h4'}
                    sx={{ color: theme.palette.primary.main }}
                    component={'p'}
                    align={'center'}
                    lineHeight={2}
                  >
                    {t(
                      'students.subjects.registration.registered_subjects.modal.period.placeholder'
                    )}
                  </Typography>
                  {t('students.subjects.registration.registered_subjects.modal.period.subtitle')}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        )}

        <Grid container justifyContent={'center'} alignContent={'center'} mt={5}>
          <Tooltip title={t('Reporte de Materias Registradas')}>
            <Button
              onClick={() => handleDownloadPDF()}
              disabled={isDownloadingPDF}
              startIcon={<IconDownload />}
            >
              Descargar reporte
            </Button>
          </Tooltip>
        </Grid>
      </DialogContent>
      {/* </PerfectScrollbar> */}
      <DialogActions>
        <Stack direction="row" spacing={2}>
          <Button
            variant="text"
            onClick={() => {
              handleCloseDialog();
            }}
            color="primary"
          >
            {t('students.subjects.registration.registered_subjects.modal.cancel')}
          </Button>
        </Stack>
      </DialogActions>
    </ExtendedSideDialog>
  );
};

export default SubjectsRegisteredDialog;
