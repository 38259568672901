import { Grid, Typography } from '@material-ui/core';
import MainCard from '../../../shared/components/cards/MainCard';
import PersonalDayForm from '../forms/PersonalDayForm';

const PersonalDayScreen = () => {
  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid item sx={{ mb: 3 }} container justifyContent="space-between" alignItems="center">
        <Typography variant="h2">Solicitud de Día Personal</Typography>
      </Grid>
      <Grid item container>
        <PersonalDayForm />
      </Grid>
    </Grid>
  );
};

export default PersonalDayScreen;
