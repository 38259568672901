import { useState, useCallback, useMemo, useEffect } from 'react';
import {
  useHistory,
  useLocation,
  // Redirect,
  useParams,
} from 'react-router-dom';
import {
  Button,
  Grid,
  Stack,
  IconButton,
  Typography,
  Box,
  Skeleton,
  Chip,
  // ToggleButtonGroup,
  // ToggleButton,
  FormControlLabel,
  Switch,
  useTheme,
  TextField,
  Autocomplete,
  // Tooltip,
} from '@material-ui/core';
import { IconTrash, IconX } from '@tabler/icons';
import moment from 'moment';
import { Calendar, momentLocalizer, CalendarProps, EventProps, Event } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import 'moment/locale/es';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';

import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import { useAppSelector, useAppDispatch } from '../../../../hooks/useRedux';
import { drawerWidth } from '../../../shared/constants/uiConstants';
import { makeStyles } from '@material-ui/core/styles';
import Simbologies from './symbologies/Symbologies';
import {
  setEduLevelsAvailables,
  setHolidaysList,
  setHolidaysMove,
  setHolidaysResponse,
  setHourhandIdSelected,
  setInitialEventsPlanning,
  setInitialPrograms,
  setInitialProgramsAndEvents,
} from '../slices/acPlanPostgraduateSlice';
import Transitions from '../../../shared/components/extended/Transitions';
import { MyEvents, InitialProgramsAndEvents } from '../interfaces/events.interfaces';
import { CourseData } from '../interfaces/programPlanningPayload.interfaces';
import CareerPlanningSideDialog from './CareerPlanningSideDialog';
import {
  useGetCurriculumHourhandsQuery,
  useGetPlanningTemporaryQuery,
  useGetPosgraduatePlanificationByTransactionIdQuery,
  useGetPosgraduateSubjectsByCurriculumQuery,
  useLazyGetHolidaysAvailablesQuery,
} from '../slices/acPlanPostgraduateApiSlice';
import { sortAsc } from '../helpers/helpers';
import { useInView } from 'react-intersection-observer';
import { useLazyGetEducationLevelsParentsAndChildrenQuery } from '../../education-levels/slices/educationLevelsApiSlice';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import { useTranslation } from 'react-i18next';
import { useGetLocaleDateLang } from '../../../../translation/hooks/useGetLocaleDateLang';
import useGetDayTypes from '../hooks/useGetDayTypes';
import useGetHolidays from '../hooks/useGetHolidays';
import { format } from 'date-fns';
import { Hourhand } from '../../hourhands/interfaces/hourhands.interfaces';

const useStyles = makeStyles((theme) => ({
  calendar: {
    '& .rbc-btn-group': { display: 'none' },
    // '& .rbc-month-row': {
    //   display: 'inline-table !important',
    //   flex: '0 0 0 !important',
    //   minHeight: '90px !important',
    // },
  },
  dragBox: {
    boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.75)',
    borderRadius: '12px',
  },
}));

const DnDCalendar = withDragAndDrop(Calendar as React.ComponentType<CalendarProps>);

const CalendarFormCareerPlanning = () => {
  const { t } = useTranslation();

  const theme = useTheme();
  const classes = useStyles();

  const history = useHistory();

  const dispatch = useAppDispatch();

  const { langKey } = useGetLocaleDateLang();

  const { careerId } = useParams<{ careerId: string }>();

  const { opened } = useAppSelector((state) => state.customization);
  const { currentProfile } = useAppSelector((state) => state.access);

  const { search } = useLocation();

  const params = new URLSearchParams(search);

  const {
    initialInfoPlanning,
    initialEventsPlanning,
    initialPrograms,
    initialProgramsAndEvents,
    eduLevelsAvailables,
    holidaysList,
    holidaysMove,
    holidaysResponse,
    colorEvents,
    dragEvent,
    calendarIdSelected,
    hourhandIdSelected,
  } = useAppSelector((state) => state.academicPlanningPosgraduate);

  const { ref, inView: isVisible } = useInView();

  const [getEducationLevels, { isLoading: isLoadingEducationLevels }] =
    useLazyGetEducationLevelsParentsAndChildrenQuery();

  const careerInfo = useMemo(
    () => initialProgramsAndEvents.find((career) => career.id === careerId),
    [initialProgramsAndEvents, careerId]
  );

  const month = useMemo(
    () => params.get('month'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const year = useMemo(
    () => params.get('year'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const completePlanning = useMemo(
    () => Boolean(params.get('complete')),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const localizer = momentLocalizer(moment);

  const [showAlerts, setShowAlerts] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);

  const [Event, setSelectedEvent] = useState<MyEvents>(null!);

  const [currentDate, setCurrentDate] = useState<Date>(() =>
    year && month
      ? new Date(parseInt(year), parseInt(month) - 1)
      : new Date(initialPrograms.find((program) => program.id === careerId)?.start || new Date())
  );

  const [, setIsAChange] = useState(false);

  const [firstUpdate, setFirstUpdate] = useState(true);

  const [courseData, setCourseData] = useState<CourseData[]>([]);

  const [calendarView] = useState<'month' | 'year'>('year');

  const handleHolidaysGenerator = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setHolidaysMove(event.target.checked));
    if (event.target.checked) {
      dispatch(setHolidaysList(holidaysResponse['move-holidays']));
    } else {
      dispatch(setHolidaysList(holidaysResponse.holidays));
    }
  };

  // const handleChangeCalendarView = (
  //   event: React.MouseEvent<HTMLElement>,
  //   newCalendarView: string
  // ) => {
  //   if (newCalendarView !== null) {
  //     setCalendarView(newCalendarView as 'month' | 'year');
  //   } else {
  //     setCalendarView('year');
  //   }
  // };

  const program = useMemo(() => {
    return initialPrograms.find((program) => program.id === careerId);
  }, [initialPrograms, careerId]);

  const eduLevelFather = eduLevelsAvailables.find(
    (eduLevel) => eduLevel.id === Number(program?.eduLevelFatherId)
  );

  const eduLevel = eduLevelFather?.children.find(
    (child) => child.id === Number(program?.eduLevelId)
  );

  const curriculumId =
    initialInfoPlanning?.career?.find((career) => career.edu_name === careerInfo?.title)?.meshs?.[0]
      ?.id || eduLevel?.meshs?.[0]?.id;

  const [getHolidays, { isLoading: isLoadingHolidays }] = useLazyGetHolidaysAvailablesQuery();

  useEffect(() => {
    if (eduLevelsAvailables?.length === 0) {
      getEducationLevels({ postgraduate: true, filter: true }).then((res) => {
        dispatch(setEduLevelsAvailables(res?.data || []));
      });
    }
    if (holidaysList.length === 0) {
      getHolidays({
        currentCountry: 'pais-ec',
        year: currentDate.getFullYear(),
        yearPlusOne: true,
        generateHoliday: true,
        ...(calendarIdSelected && { calendarId: calendarIdSelected }),
      }).then((res) => {
        if (res?.data) {
          dispatch(setHolidaysResponse(res.data));
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    firstDay,
    titleWorkshop,
    firstWeek,
    selfEmployment,
    holiday,
    initialPlanningPoint,
    startProgram,
    presencialClasses,
    onlineClasses,
  } = useGetDayTypes();

  const modality = useMemo(() => {
    return eduLevel?.meshs?.[0]?.modality?.cat_keyword || '';
  }, [eduLevel]);

  const { data: dataTemporary, isLoading: isLoadingTemporary } = useGetPlanningTemporaryQuery({
    page: 1,
    size: 1000,
    sort: 'id',
    typeSort: 'desc',
    year: currentDate.getFullYear(),
    month: currentDate.getMonth() + 1,
    year_plus_one: true,
    generate_holiday: true,
    ...(calendarIdSelected && { calendarId: calendarIdSelected }),
  });

  const {
    data: prePlannification,
    isLoading: isLoadingPrePlannification,
    refetch,
  } = useGetPosgraduatePlanificationByTransactionIdQuery(
    {
      profileId: currentProfile?.profileId!,
      id: program?.idTransactionPlanning!,
    },
    {
      skip: !program?.idTransactionPlanning || !currentProfile?.profileId,
    }
  );

  const hourhand = useMemo(() => {
    return (
      prePlannification?.postgraduate_planning_additional_information
        ?.postgraduate_planning_hourhands?.[0]?.hourhand || null!
    );
  }, [prePlannification]);

  const { data: curriculumHourhands = [], isLoading: isLoadingHourhands } =
    useGetCurriculumHourhandsQuery(
      {
        profileId: currentProfile?.profileId!,
        curriculumId: curriculumId!,
      },
      {
        skip: !currentProfile?.profileId || !curriculumId,
      }
    );

  useEffect(() => {
    if (prePlannification?.postgraduate_academic_planing) {
      const events: MyEvents[] = prePlannification?.postgraduate_academic_planing
        ?.filter(
          (event) =>
            event.posgraduate_day_type.pdt_keywork !== holiday?.pdt_keywork &&
            event.posgraduate_day_type.pdt_keywork !== initialPlanningPoint?.pdt_keywork
        )
        ?.map((pre) => ({
          start: moment(pre.pap_start_date).toDate(),
          end: moment(pre.pap_end_date).toDate(),
          color: validateTypeEvent(pre.posgraduate_day_type.pdt_keywork).color,
          title: validateTypeEvent(pre.posgraduate_day_type.pdt_keywork).name,
          id: pre.holiday_id || pre.id,
          typeId: validateTypeEvent(pre.posgraduate_day_type.pdt_keywork).typeId,
          droppedOnAllDaySlot: true,
          addExtraWeek: Boolean(Number(pre?.check_day_start_point_planning)),
        }));
      setMyEvents(events);

      setCourseData(
        events
          .filter((event) => event.typeId === 'IDA')
          .sort((a, b) => {
            var c = new Date(a.start);
            var d = new Date(b.start);
            if (c > d) {
              return 1;
            }
            if (c < d) {
              return -1;
            }
            return 0;
          })
          .map((event, index) => ({
            eventId: event.id!,
            eventTitle: event.title!,
            subjectId: Number(
              prePlannification?.postgraduate_academic_planing.find((pre) => pre.id === event.id)
                ?.subject_curriculum_id || 0
            ),
            teacherId: Number(
              prePlannification?.postgraduate_academic_planing.find((pre) => pre.id === event.id)
                ?.collaborator_id || 0
            ),
            typeClassrooomVirtual:
              prePlannification?.postgraduate_academic_planing.find((pre) => pre.id === event.id)
                ?.type_classroom_virtual || 'blackboard',
          }))
      );
    }

    // eslint-disable-next-line
  }, [prePlannification]);

  const { data: subjects, isLoading: isLoadingSubjects } =
    useGetPosgraduateSubjectsByCurriculumQuery(
      {
        profileId: currentProfile?.profileId!,
        curriculumId: Number(eduLevel?.meshs?.[0]?.id!),
      },
      {
        skip: !eduLevel?.meshs?.[0]?.id || !currentProfile?.profileId,
        refetchOnMountOrArgChange: true,
      }
    );

  const DaysEvents: MyEvents[] = useGetHolidays();

  useEffect(() => {
    if (dataTemporary) {
      const EventsDaysRecover: MyEvents[] = dataTemporary!
        .data!.filter((event) =>
          [initialPlanningPoint?.id].some((eventId) => eventId === event.postgraduate_day_type_id)
        )!
        .map((eventPIP) => ({
          start: new Date(eventPIP.start_date + 'T00:00:00'),
          end: new Date(eventPIP.start_date + 'T00:00:00'),
          title:
            eventPIP?.posgraduate_day_type?.pdt_description_day ||
            eventPIP?.holiday.pdt_description_day ||
            '-1',
          color:
            eventPIP?.posgraduate_day_type?.pdt_colour || eventPIP.holiday.pdt_colour || '#ff0000',
          id: eventPIP.id || '-1',
          typeId:
            eventPIP?.posgraduate_day_type?.pdt_keywork || eventPIP?.holiday.pdt_keywork || '-1',
          droppedOnAllDaySlot: false,
          addExtraWeek: Boolean(Number(eventPIP?.check_day_start_point_planning)),
        }));

      const EventInitialProgramRecover: MyEvents[] = dataTemporary!
        .data!.filter((event) => event.postgraduate_day_type_id === startProgram?.id)!
        .map((eventPIP) => {
          return {
            start: new Date(eventPIP.start_date + 'T00:00:00'),
            end: new Date(eventPIP.start_date + 'T00:00:00'),
            title:
              eduLevelsAvailables
                ?.find((e) => Number(e.id) === Number(eventPIP.education_level_id))
                ?.children?.find((e) => Number(e.id) === Number(eventPIP.education_level_carrer_id))
                ?.edu_name || 'Punto de inicio de programa',
            color: eventPIP?.posgraduate_day_type?.pdt_colour || '#ff0000',
            id: eventPIP.id || '-1',
            typeId: eventPIP?.posgraduate_day_type?.pdt_keywork || '-1',
            droppedOnAllDaySlot: false,
            eduLevelId: eventPIP.education_level_carrer_id || 0,
            eduLevelFatherId: eventPIP.education_level_id || 0,
            curriculumId: eventPIP.curriculum_id || 0,
            idTransactionPlanning: eventPIP.id_transaction_planning,
            status: eventPIP?.postgraduate_planning_status?.status_planning || '',
            namePeriod:
              eventPIP?.postgraduate_planning_additional_information?.name_period ||
              eventPIP?.name_period_temporary,
            hourhandsMove: Boolean(
              Number(eventPIP?.postgraduate_planning_additional_information?.hourhands_move)
            ),
            addExtraWeek: Boolean(Number(eventPIP?.check_day_start_point_planning)),
          };
        });

      const program = initialPrograms.find((program) => program.id === careerId)!;
      if (!program) {
        dispatch(setInitialPrograms(EventInitialProgramRecover));
      }

      dispatch(setInitialEventsPlanning([...DaysEvents, ...EventsDaysRecover]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataTemporary, DaysEvents, eduLevelsAvailables]);

  useEffect(() => {
    dispatch(setHolidaysMove(Boolean(Number(prePlannification?.hourhands_move))));
    if (prePlannification?.postgraduate_academic_planing?.length) {
      const holidays: MyEvents[] =
        prePlannification?.postgraduate_academic_planing
          ?.filter((e) => e.posgraduate_day_type.pdt_keywork === holiday?.pdt_keywork)
          ?.map((thisHoliday) => ({
            start: thisHoliday.pap_start_date,
            end: thisHoliday.pap_end_date,
            title: thisHoliday.posgraduate_day_type?.pdt_description_day,
            color: holiday?.pdt_colour || '#ff0000',
            id: thisHoliday?.holiday_id || thisHoliday.id,
            typeId: thisHoliday.posgraduate_day_type?.pdt_keywork,
            droppedOnAllDaySlot: false,
          })) || [];

      const events: MyEvents[] =
        prePlannification?.postgraduate_academic_planing
          ?.filter((event) =>
            [initialPlanningPoint?.id].some((eventId) => eventId === event.postgraduate_day_type_id)
          )
          ?.map((eventPIP) => ({
            start: new Date(eventPIP.pap_start_date + 'T00:00:00'),
            end: new Date(eventPIP.pap_start_date + 'T00:00:00'),
            title: eventPIP?.posgraduate_day_type?.pdt_description_day || '-1',
            color: eventPIP?.posgraduate_day_type?.pdt_colour || '#ff0000',
            id: eventPIP.id || '-1',
            typeId: eventPIP?.posgraduate_day_type?.pdt_keywork || '-1',
            droppedOnAllDaySlot: false,
            addExtraWeek: Boolean(Number(eventPIP?.check_day_start_point_planning)),
          })) || [];

      const EventsDaysRecover: MyEvents[] =
        dataTemporary?.data
          ?.filter((event) =>
            [initialPlanningPoint?.id].some((eventId) => eventId === event.postgraduate_day_type_id)
          )!
          .map((eventPIP) => ({
            start: new Date(eventPIP.start_date + 'T00:00:00'),
            end: new Date(eventPIP.start_date + 'T00:00:00'),
            title:
              eventPIP?.posgraduate_day_type?.pdt_description_day ||
              eventPIP?.holiday.pdt_description_day ||
              '-1',
            color:
              eventPIP?.posgraduate_day_type?.pdt_colour ||
              eventPIP.holiday.pdt_colour ||
              '#ff0000',
            id: eventPIP.id || '-1',
            typeId:
              eventPIP?.posgraduate_day_type?.pdt_keywork || eventPIP?.holiday.pdt_keywork || '-1',
            droppedOnAllDaySlot: false,
            addExtraWeek: Boolean(Number(eventPIP?.check_day_start_point_planning)),
          })) || [];

      dispatch(setInitialEventsPlanning([...holidays, ...events, ...EventsDaysRecover]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prePlannification, dataTemporary]);

  const [myEvents, setMyEvents] = useState<MyEvents[]>(careerInfo?.events || []);

  const validateTypeEvent = (eventType: string) => {
    let color, name, typeId: string;
    let dayInit, days: number;
    switch (eventType) {
      case firstDay?.pdt_keywork:
        color = firstDay?.pdt_colour || '';
        name = firstDay?.pdt_description_day || '';
        typeId = firstDay?.pdt_keywork || '';
        dayInit = 0;
        days = 1;
        break;
      case titleWorkshop?.pdt_keywork:
        color = titleWorkshop?.pdt_colour || '';
        name = titleWorkshop?.pdt_description_day || '';
        typeId = titleWorkshop?.pdt_keywork || '';
        dayInit = 5;
        days = 1;
        break;
      case firstWeek?.pdt_keywork:
        color = firstWeek?.pdt_colour || '';
        name = firstWeek?.pdt_description_day || '';
        typeId = firstWeek?.pdt_keywork || '';
        dayInit = 1;
        days = 6;
        break;
      case presencialClasses?.pdt_keywork:
        color = presencialClasses?.pdt_colour || '';
        name = presencialClasses?.pdt_description_day || '';
        typeId = presencialClasses?.pdt_keywork || '';
        dayInit = 1;
        days = 1;
        break;
      case onlineClasses?.pdt_keywork:
        color = onlineClasses?.pdt_colour || '';
        name = onlineClasses?.pdt_description_day || '';
        typeId = onlineClasses?.pdt_keywork || '';
        dayInit = 1;
        days = 1;
        break;
      default:
        color = selfEmployment?.pdt_colour || '';
        name = selfEmployment?.pdt_description_day || '';
        typeId = selfEmployment?.pdt_keywork || '';
        dayInit = 6;
        days = Number(holidaysResponse?.trabajo_autonomo?.pdt_number_of_days);
    }
    return { color, name, typeId, dayInit, days };
  };

  const moveEvent = useCallback(
    ({ event, start, end }) => {
      const hourhand = curriculumHourhands.find(
        (hourhand) => Number(hourhand.id) === hourhandIdSelected
      );

      const dayText = format(start as unknown as Date, 'EEEE').toLowerCase();

      if (hourhand?.[`hour_${dayText}` as keyof Hourhand] === '0') {
        return;
      }

      if (
        ((event.typeId === presencialClasses?.pdt_keywork ||
          event.typeId === onlineClasses?.pdt_keywork) &&
          myEvents.some(
            (e) =>
              moment(e.start).format('YYYY-MM-DD') === moment(start).format('YYYY-MM-DD') &&
              (e.typeId === presencialClasses?.pdt_keywork ||
                e.typeId === onlineClasses?.pdt_keywork)
          )) ||
        !myEvents.some(
          (_e) =>
            moment(start).isSameOrAfter(moment(_e.start)) &&
            moment(start).isSameOrBefore(moment(_e.end)) &&
            _e.typeId === selfEmployment?.pdt_keywork
        )
      ) {
        return;
      }

      setMyEvents((prev: any) => {
        const existing = prev.find((ev: any) => ev.id === event.id) ?? {};
        const filtered = prev.filter((ev: any) => ev.id !== event.id);
        return [...filtered, { ...existing, start, end, title: event.title, color: event.color }];
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setMyEvents]
  );

  const handleDeleteEvent = (id: string) => {
    const eventFilter = myEvents.filter((event) => !(event.id === id));
    setMyEvents(eventFilter);
    setSelectedEvent(null!);
  };

  const generateAcademicEventsAllPrograms = () => {
    // recorrer los programas iniciales y validar desde el valor minimo hasta el maxima

    const initialEventsPlanningPIP = initialEventsPlanning.filter(
      (event) => event.typeId === 'PIP'
    );

    const sortInitialPlanningPIP = initialEventsPlanningPIP.sort((a, b) => {
      var c = new Date(a.start);
      var d = new Date(b.start);
      if (c > d) {
        return 1;
      }
      if (c < d) {
        return -1;
      }
      return 0;
    });
    const program = initialPrograms.find((program) => program.id === careerId)!;

    const filterSortInitialPlanningPIP = sortInitialPlanningPIP.filter(
      (sortPlanning) => sortPlanning.start >= program.start
    );

    const education_level_id = Number(program?.eduLevelId) || 0;
    const courses = Number(
      initialInfoPlanning?.career?.find((career) => career.id === education_level_id)?.meshs?.[0]
        ?.mes_number_matter ||
        eduLevel?.meshs?.[0]?.mes_number_matter ||
        0
    );

    const programAndEvents: InitialProgramsAndEvents = {
      title: program.title,
      id: program.id,
      courses,
      events: myEvents.filter((event) =>
        filterSortInitialPlanningPIP.length >= Number(courses)
          ? event.start >= program.start &&
            event.start < filterSortInitialPlanningPIP[Number(courses) - 1].end
          : event.start >= program.start
      ),
    };

    const exists = initialProgramsAndEvents.find((program) => program.id === careerId);

    if (exists) {
      dispatch(
        setInitialProgramsAndEvents([
          ...initialProgramsAndEvents.filter((program) => program.id !== careerId),
          programAndEvents,
        ])
      );
    } else {
      dispatch(setInitialProgramsAndEvents([...initialProgramsAndEvents, programAndEvents]));
    }

    generatorAcademicEvents(courses);
  };

  const generatorAcademicEvents = (courses: number) => {
    const academicEvents: MyEvents[] = []!;

    const pipSorted = sortAsc(
      initialEventsPlanning.filter(
        (events) => events.typeId === 'PIP' && events.start >= currentDate
      ),
      'start'
    );

    pipSorted.forEach((program, index) => {
      [
        firstDay?.pdt_keywork!,
        titleWorkshop?.pdt_keywork!,
        firstWeek?.pdt_keywork!,
        selfEmployment?.pdt_keywork!,
      ].map((e) => {
        if (index < courses) {
          let start = moment(program.start).add(validateTypeEvent(e).dayInit, 'days').toDate();
          let end = null;

          let daysToMonday = 0;

          const programStartDay = moment(program.start).day();

          // si el dia de inicio del programa es mayor a lunes entonces se le resta los dias que faltan para llegar a lunes
          if (programStartDay > 1) {
            daysToMonday = programStartDay - 1;
          }

          // si el dia de inicio del programa es menor a lunes entonces se le suma los dias que faltan para llegar a lunes
          if (programStartDay < 1) {
            daysToMonday = 7 - programStartDay;
          }

          if (validateTypeEvent(e).name === titleWorkshop?.pdt_description_day) {
            let days = moment(program.start).day() === 0 ? 6 : 5;
            start = moment(program.start).subtract(daysToMonday, 'days').add(days, 'days').toDate();

            // si el dia de inicio es menor al dia del programa se le suma una semana
            // si el dia de inicio es sabado se le suma una semana
            if (moment(start).isBefore(moment(program.start)) || moment(start).day() === 6) {
              days = moment(start).day() === 0 ? 8 : 7;
              start = moment(start).add(days, 'days').toDate();
            }

            end = moment(start).add(1, 'days').toDate();
          }

          return academicEvents.push({
            start,
            end:
              end ||
              moment(program.end)
                .add(
                  validateTypeEvent(e).days +
                    (validateTypeEvent(e).name === selfEmployment?.pdt_description_day &&
                    program?.addExtraWeek
                      ? 7
                      : 0) -
                    (validateTypeEvent(e).name === selfEmployment?.pdt_description_day
                      ? daysToMonday
                      : 0),
                  'days'
                )
                .toDate(),
            color: validateTypeEvent(e).color,
            title: validateTypeEvent(e).name,
            id: `generate${e}${index}`,
            typeId: validateTypeEvent(e).typeId,
            droppedOnAllDaySlot: true,
          });
        }
        return null;
      });
    });

    setMyEvents(academicEvents);
    dispatch(
      setInitialProgramsAndEvents(
        initialProgramsAndEvents.map((program) => {
          if (program.id === careerId) {
            return {
              ...program,
              events: [...academicEvents],
            };
          }
          return program;
        })
      )
    );

    const subjectsSorted = sortAsc(subjects || [], 'id');

    setCourseData(
      academicEvents
        .filter((event) => event.typeId === 'IDA')
        .sort((a, b) => {
          var c = new Date(a.start);
          var d = new Date(b.start);
          if (c > d) {
            return 1;
          }
          if (c < d) {
            return -1;
          }
          return 0;
        })
        .map((event, index) => ({
          eventId: event.id!,
          eventTitle: event.title!,
          subjectId: subjectsSorted?.[index]?.id!,
          teacherId: 0,
          typeClassrooomVirtual: 'blackboard',
        }))
    );
  };

  useEffect(() => {
    if (firstUpdate) {
      setFirstUpdate(false);
      return;
    }
    setIsAChange(true);
    //eslint-disable-next-line
  }, [myEvents]);

  const rename = useMemo(() => {
    if (
      prePlannification &&
      prePlannification.postgraduate_planning_additional_information &&
      prePlannification.postgraduate_planning_additional_information.rename_period
    ) {
      return prePlannification.postgraduate_planning_additional_information.rename_period;
    }
    return '';
  }, [prePlannification]);

  useEffect(() => {
    if (
      !isLoadingTemporary &&
      !isLoadingSubjects &&
      !isLoadingPrePlannification &&
      !isLoadingEducationLevels &&
      !isLoadingHolidays &&
      eduLevelsAvailables.length > 0 &&
      completePlanning
    ) {
      setOpenDialog(true);
    }
    //eslint-disable-next-line
  }, [
    isLoadingTemporary,
    isLoadingSubjects,
    isLoadingPrePlannification,
    isLoadingEducationLevels,
    isLoadingHolidays,
    eduLevelsAvailables,
    completePlanning,
  ]);

  const numSubjects = Number(eduLevel?.meshs?.[0]?.mes_number_matter || 0);

  const countIPA =
    initialEventsPlanning.filter(
      (event) =>
        event.typeId === 'PIP' &&
        moment(event.start).format('YYYY-MM-DD') >= moment(currentDate).format('YYYY-MM-DD') &&
        moment(event.start).format('YYYY-MM-DD') <=
          moment(currentDate)
            .add(numSubjects * 35, 'days')
            .format('YYYY-MM-DD')
    )?.length || 0;

  const IPAfaults = numSubjects - countIPA;

  const customEvent = ({ event: e }: EventProps<Event>) => (
    <Grid container>
      <Grid
        item
        container
        xs={12}
        alignItems="center"
        sx={{
          flexWrap: 'nowrap',
          height: '12px',
        }}
      >
        {/* <Tooltip title={(e as unknown as MyEvents).title}> */}
        <Typography
          sx={{
            color: 'transparent',
          }}
        >
          {(e as unknown as MyEvents).title}
        </Typography>
        {/* </Tooltip> */}
      </Grid>
    </Grid>
  );

  useEffect(
    () => {
      dispatch(setHourhandIdSelected(hourhand?.id ? Number(hourhand?.id) : null));
    },
    //eslint-disable-next-line
    [hourhand]
  );

  return (
    <>
      {(isLoadingTemporary ||
        isLoadingSubjects ||
        isLoadingPrePlannification ||
        isLoadingEducationLevels ||
        eduLevelsAvailables.length === 0 ||
        isLoadingHolidays) && (
        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
          <Grid item xs={8} md={3}>
            <Skeleton variant="rectangular" width="100%" height="10vh" />
          </Grid>

          <Grid item xs={12} mt={2}>
            <Skeleton variant="rectangular" width="100%" height="20vh" />
          </Grid>

          {Array.from(new Array(6)).map((item, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Skeleton variant="rectangular" width="100%" height="400px" />
            </Grid>
          ))}
        </Grid>
      )}
      {!isLoadingTemporary &&
        !isLoadingSubjects &&
        !isLoadingPrePlannification &&
        !isLoadingEducationLevels &&
        !isLoadingHolidays &&
        eduLevelsAvailables.length > 0 && (
          <Grid container direction="row" justifyContent="center" alignItems="center" gap={2}>
            <Grid item xs={10}>
              {showAlerts && (
                <SuccessAlert
                  message="Se agregó correctamente"
                  handleDismiss={() => setShowAlerts(false)}
                />
              )}
            </Grid>

            {prePlannification?.postgraduate_planning_status && (
              <Grid item container xs={10} justifyContent="center">
                <Chip
                  label={prePlannification?.postgraduate_planning_status?.status_planning}
                  style={{
                    backgroundColor:
                      prePlannification?.postgraduate_planning_status?.status_planning ===
                      'pendiente'
                        ? theme.palette.warning.main
                        : theme.palette.success.main,
                    borderColor:
                      prePlannification?.postgraduate_planning_status?.status_planning ===
                      'pendiente'
                        ? theme.palette.warning.main
                        : theme.palette.success.main,
                    color:
                      prePlannification?.postgraduate_planning_status?.status_planning ===
                      'pendiente'
                        ? '#000'
                        : '#fff',
                  }}
                  variant="outlined"
                />
              </Grid>
            )}

            <Grid
              item
              container
              mt={2}
              xs={10}
              spacing={2}
              justifyContent="center"
              alignItems="baseline"
              ref={ref}
            >
              <Box>
                <Typography align="center" variant="h3" component="div" mb={1}>
                  {eduLevelFather?.edu_name}
                </Typography>
                <Typography align="center" variant="h2" component="div" mb={4}>
                  {currentDate.getFullYear() || new Date().getFullYear()}
                </Typography>
                <Typography align="center" component="div" variant="h2">
                  {prePlannification?.postgraduate_planning_additional_information?.name_period ||
                    prePlannification?.name_period_temporary}
                </Typography>

                <Typography align="center" component="div" variant="h4">
                  {rename}
                </Typography>
              </Box>
            </Grid>

            {modality === 'modalidad-hibrida' && (
              <Grid
                item
                container
                xs={10}
                md={3}
                justifyContent="center"
                hidden={modality !== 'modalidad-hibrida'}
              >
                {isLoadingHourhands || curriculumHourhands.length === 0 ? (
                  <TextField disabled placeholder={t('posgraduate.schedule.loading')} fullWidth />
                ) : (
                  <Autocomplete
                    options={curriculumHourhands || []}
                    getOptionLabel={(option) => `${option.hour_description}`}
                    loading={isLoadingHourhands}
                    loadingText={t('posgraduate.schedule.loading')}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        placeholder={t('posgraduate.schedule.placeholder')}
                        // error={Boolean(touched.hourhandsId && errors.hourhandsId)}
                      />
                    )}
                    defaultValue={
                      curriculumHourhands?.find(
                        (h) => Number(h.id) === Number(hourhand?.id || 0)
                      ) || null!
                    }
                    // value={
                    //   curriculumHourhands?.find(
                    //     (hourhand) => Number(hourhand.id) === Number(hourhandIdSelected)
                    //   ) || null!
                    // }
                    multiple={false}
                    // value={curriculumHourhands?.find(
                    //   (hourhand) => hourhand?.id === hourhandIdSelected
                    // )}
                    onChange={(event, newValue) => {
                      dispatch(setHourhandIdSelected(Number(newValue?.id) || null));
                      // setFieldValue('hourhandsId', [Number(newValue.id)]);
                      setMyEvents((prev) =>
                        prev.filter(
                          (event) =>
                            ![
                              presencialClasses?.pdt_keywork!,
                              onlineClasses?.pdt_keywork!,
                            ].includes(event.typeId)
                        )
                      );
                    }}
                    fullWidth
                    disableClearable
                  />
                )}
              </Grid>
            )}

            <Grid
              item
              container
              md={10}
              spacing={2}
              justifyContent="left"
              alignItems="center"
              mt={2}
            >
              <Grid item sx={{ width: '100%' }}>
                <Simbologies
                  simbologies={colorEvents
                    .filter(
                      (infoEvent) =>
                        !(
                          eduLevel?.meshs?.[0]?.modality.cat_keyword === 'modalidad-hibrida'
                            ? [
                                'IPA',
                                'FDS',
                                'PIP',
                                ...(hourhandIdSelected && myEvents.length > 0
                                  ? []
                                  : ['CPR', 'CEL']),
                              ]
                            : ['IPA', 'FDS', 'PIP', 'CPR', 'CEL']
                        ).some((acronym) => acronym === infoEvent.pdt_keywork)
                    )
                    .map((infoEvent) => ({
                      label: infoEvent.pdt_description_day,
                      color: infoEvent.pdt_colour,
                      index: myEvents.length,
                      typeId: infoEvent.pdt_keywork,
                    }))}
                />
              </Grid>
            </Grid>

            {IPAfaults > 0 && (
              <ErrorAlert
                message={t('posgraduate.career_planning.fault_ipa', { IPAfaults })}
                handleDismiss={() => {}}
              />
            )}

            <Grid
              item
              container
              xs={10}
              spacing={2}
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <FormControlLabel
                  control={<Switch checked={holidaysMove} onChange={handleHolidaysGenerator} />}
                  label="Feriados por normativa"
                  labelPlacement={'start'}
                  disabled
                />
              </Grid>
              {prePlannification?.postgraduate_planning_status?.status_planning !== 'ejecutada' && (
                <>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => generateAcademicEventsAllPrograms()}
                      disabled={IPAfaults > 0}
                    >
                      {t('posgraduate.career_planning.generate')}
                    </Button>
                  </Grid>
                  {/* <Grid item>
                      <Button
                        variant="contained"
                        color={'primary'}
                        endIcon={<IconDeviceFloppy />}
                        disabled={!isAChange}
                        onClick={() => saveChangesEvents()}
                      >
                        Guardar
                      </Button>
                    </Grid> */}
                  <Grid item>
                    <Button
                      variant="outlined"
                      color={'primary'}
                      onClick={() => setMyEvents([] as MyEvents[])}
                    >
                      {t('posgraduate.career_planning.clear')}
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>

            {!isVisible && (
              <Grid
                item
                container
                md={6}
                spacing={2}
                justifyContent="center"
                alignItems="center"
                mt={2}
                sx={{
                  position: 'fixed',
                  left: !opened ? '70px' : `calc(${drawerWidth}px + 20px )`,
                  top: '80px',
                  zIndex: '10',
                }}
              >
                <Grid item xs={12}>
                  <Simbologies
                    simbologies={colorEvents
                      .filter(
                        (infoEvent) =>
                          !(
                            eduLevel?.meshs?.[0]?.modality.cat_keyword === 'modalidad-hibrida'
                              ? [
                                  'IPA',
                                  'FDS',
                                  'PIP',
                                  ...(hourhandIdSelected && myEvents.length > 0
                                    ? []
                                    : ['CPR', 'CEL']),
                                ]
                              : ['IPA', 'FDS', 'PIP', 'CPR', 'CEL']
                          ).some((acronym) => acronym === infoEvent.pdt_keywork)
                      )
                      .map((infoEvent) => ({
                        label: infoEvent.pdt_description_day,
                        color: infoEvent.pdt_colour,
                        index: myEvents.length,
                        typeId: infoEvent.pdt_keywork,
                      }))}
                  />
                </Grid>
              </Grid>
            )}

            <Transitions type="fade" in={Event} position="bottom" direction={'left'}>
              <Stack
                direction="column"
                spacing={2}
                sx={{
                  position: 'fixed',
                  right: '50px',
                  bottom: '10vh',
                  zIndex: '10',
                }}
              >
                <Grid
                  container
                  sx={{
                    boxShadow: '0px 0px 20px #CADDF2',
                    backgroundColor: '#fff',
                    padding: 1,
                    borderRadius: '10px 0 0 10px',
                    borderStyle: `solid`,
                    borderColor: `${Event ? Event?.color : '#fff'}`,
                    borderWidth: '0 8px 0 0',
                  }}
                >
                  <Typography align="right" color="inherit" variant="h5">
                    {Event && (Event?.namePeriod || Event.title)}
                  </Typography>
                </Grid>
                <Grid container justifyContent="flex-end" alignItems="center"></Grid>
                {prePlannification?.postgraduate_planning_status?.status_planning !==
                  'ejecutada' && (
                  <Grid container justifyContent="flex-end" alignItems="center">
                    <IconButton
                      aria-label="delete"
                      color="primary"
                      size="medium"
                      sx={{ boxShadow: '0px 0px 20px #CADDF2', backgroundColor: '#fff' }}
                      onClick={() => handleDeleteEvent(Event.id)}
                    >
                      <IconTrash />
                    </IconButton>
                  </Grid>
                )}
                {/* <Grid container justifyContent="flex-end" alignItems="center">
                  <IconButton
                    aria-label="delete"
                    color="primary"
                    size="medium"
                    sx={{ boxShadow: '0px 0px 20px #CADDF2', backgroundColor: '#fff' }}
                  >
                    <IconCornerDownRight />
                  </IconButton>
                </Grid> */}
                <Grid container justifyContent="flex-end" alignItems="center">
                  <IconButton
                    aria-label="delete"
                    color="primary"
                    size="medium"
                    sx={{ boxShadow: '0px 0px 20px #CADDF2', backgroundColor: '#fff' }}
                    onClick={() => setSelectedEvent(null!)}
                  >
                    <IconX />
                  </IconButton>
                </Grid>
              </Stack>
            </Transitions>

            <Grid
              item
              container
              md={10}
              spacing={4}
              justifyContent="center"
              alignItems="center"
              mt={4}
            >
              {Array.from(
                new Array(
                  calendarView === 'month' ? 1 : numSubjects + 3 || 12
                  // 12
                )
              ).map((e, index) => (
                <Grid
                  item
                  xs={12}
                  md={calendarView === 'month' ? 12 : 6}
                  lg={calendarView === 'month' ? 12 : 4}
                  key={index}
                >
                  <DnDCalendar
                    views={['month']}
                    events={myEvents}
                    selectable
                    dayPropGetter={(day) => {
                      var style = {
                        // backgroundColor: '#E6E6E6',
                        display: 'block',
                      };
                      var styleFDS = {
                        backgroundColor: `${holiday?.pdt_colour}60`,
                        display: 'block',
                      };
                      var styleDayPlanning = {
                        backgroundColor: `${initialPlanningPoint?.pdt_colour}60`,
                        display: 'block',
                      };
                      return {
                        style: initialEventsPlanning
                          .filter((event) => event.typeId === 'PIP')
                          .some(
                            (ipaEvent) =>
                              moment(ipaEvent.start).format('YYYY-MM-DD') ===
                              moment(day).format('YYYY-MM-DD')
                          )
                          ? styleDayPlanning
                          : initialEventsPlanning
                              .filter((event) => event.typeId === 'FDS')
                              .some(
                                (ipaEvent) =>
                                  moment(ipaEvent.start).format('YYYY-MM-DD') ===
                                  moment(day).format('YYYY-MM-DD')
                              )
                          ? styleFDS
                          : style,
                      };
                    }}
                    key={index}
                    className={calendarView === 'year' ? classes.calendar : ''}
                    date={moment(currentDate).add(index, 'month').toDate()}
                    localizer={localizer}
                    onNavigate={(date) => setCurrentDate(date)}
                    startAccessor="start"
                    endAccessor="end"
                    style={{ height: calendarView === 'month' ? 500 : 500 }}
                    onSelectEvent={(e) => setSelectedEvent(e as unknown as MyEvents)}
                    onSelectSlot={() => setSelectedEvent(null!)}
                    onDropFromOutside={(event) => {
                      const hourhand = curriculumHourhands.find(
                        (hourhand) => Number(hourhand.id) === hourhandIdSelected
                      );

                      const dayText = format(event.start as unknown as Date, 'EEEE').toLowerCase();

                      if (hourhand?.[`hour_${dayText}` as keyof Hourhand] === '0') {
                        return;
                      }

                      if (
                        ((dragEvent.typeId === presencialClasses?.pdt_keywork ||
                          dragEvent.typeId === onlineClasses?.pdt_keywork) &&
                          myEvents.some(
                            (e) =>
                              moment(e.start).format('YYYY-MM-DD') ===
                                moment(event.start).format('YYYY-MM-DD') &&
                              (e.typeId === presencialClasses?.pdt_keywork ||
                                e.typeId === onlineClasses?.pdt_keywork)
                          )) ||
                        !myEvents.some(
                          (_e) =>
                            moment(event.start).isSameOrAfter(moment(_e.start)) &&
                            moment(event.start).isSameOrBefore(moment(_e.end)) &&
                            _e.typeId === selfEmployment?.pdt_keywork
                        )
                      ) {
                        return;
                      }

                      setMyEvents([
                        ...myEvents,
                        {
                          start: new Date(event.start),
                          end: new Date(event.start),
                          color: dragEvent.color!,
                          id: `${myEvents.length + 1}`,
                          title: dragEvent.title!,
                          droppedOnAllDaySlot: true,
                          typeId: dragEvent.typeId!,
                        },
                      ]);
                    }}
                    eventPropGetter={(event) => {
                      var backgroundColor = (event as unknown as MyEvents)?.color;
                      var style = {
                        backgroundColor: backgroundColor,
                        borderRadius: '0px',
                        opacity: 0.8,
                        color: 'black',
                        border: '0px',
                        display: 'block',
                      };
                      return {
                        style: style,
                      };
                    }}
                    onEventDrop={moveEvent}
                    onEventResize={moveEvent}
                    messages={{
                      next: t('posgraduate.next'),
                      previous: t('posgraduate.prev'),
                      today: t('posgraduate.today'),
                      month: t('posgraduate.month'),
                      week: t('posgraduate.week'),
                      day: t('posgraduate.day'),
                    }}
                    culture={langKey as string}
                    components={{
                      event: customEvent,
                    }}
                  />
                </Grid>
              ))}
            </Grid>

            <Grid
              item
              container
              alignItems="center"
              justifyContent="flex-end"
              spacing={2}
              sx={{ mt: 1 }}
            >
              <Grid item>
                <Button
                  variant="outlined"
                  size="large"
                  color="primary"
                  onClick={() => history.goBack()}
                >
                  {t('posgraduate.back')}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  disabled={myEvents.length === 0 && courseData.length === 0}
                  variant="contained"
                  size="large"
                  color="primary"
                  onClick={() => setOpenDialog(true)}
                >
                  {t('posgraduate.career_planning.complete')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}

      {openDialog && (
        <CareerPlanningSideDialog
          open={openDialog}
          colorEvents={colorEvents}
          courseData={courseData}
          setCourseData={setCourseData}
          curriculumId={curriculumId!}
          eduLevel={eduLevel!}
          handleClose={() => setOpenDialog(false)}
          initialEventsPlanning={initialEventsPlanning}
          initialInfoPlanning={initialInfoPlanning}
          myEvents={myEvents}
          year={currentDate?.getFullYear()}
          additionalInformation={prePlannification?.postgraduate_planning_additional_information}
          idTransactionPlanning={prePlannification?.id_transaction_planning}
          status={prePlannification?.postgraduate_planning_status?.status_planning || ''}
          feeCollectionDay={
            prePlannification?.postgraduate_planning_status?.fee_collection_day_max
              ? Number(prePlannification?.postgraduate_planning_status?.fee_collection_day_max)
              : 1
          }
          namePeriod={
            prePlannification?.postgraduate_planning_additional_information?.name_period ||
            prePlannification?.name_period_temporary ||
            ''
          }
          refetchPlanning={refetch}
        />
      )}
    </>
  );
};

export default CalendarFormCareerPlanning;
