import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Author } from '../interfaces/authors.interface';

interface AuthorState {
  authorToEdit?: Author;
  deleteModalOpen: boolean;
  authorToDelete?: number;
  refetchRequest: boolean;
}

const initialState: AuthorState = {
  deleteModalOpen: false,
  refetchRequest: false,
};

const authorsSlice = createSlice({
  name: 'authors',
  initialState,
  reducers: {
    setAuthorToEdit: (state, action: PayloadAction<Author | undefined>) => {
      state.authorToEdit = action.payload;
    },
    setDeleteModalOpen: (state, action: PayloadAction<boolean>) => {
      state.deleteModalOpen = action.payload;
    },
    openDeleteModalWith: (state, action: PayloadAction<number>) => {
      state.authorToDelete = action.payload;
      state.deleteModalOpen = true;
    },
    setRefetchRequest: (state, action: PayloadAction<boolean>) => {
      state.refetchRequest = action.payload;
    },
    resetAuthorToDelete: (state) => {
      state.authorToDelete = undefined;
    },
  },
});

export const {
  setAuthorToEdit,
  setDeleteModalOpen,
  openDeleteModalWith,
  setRefetchRequest,
  resetAuthorToDelete,
} = authorsSlice.actions;

export default authorsSlice.reducer;
