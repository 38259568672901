import { useEffect, useState } from 'react';

import { IScope } from '../interfaces/scopes.interface';
import { scopeFormSchema } from '../validation/scopes.validation';
import { useAddScopeMutation, useUpdateScopeMutation } from '../slices/scopeApiSlice';
import { useAppSelector } from '../../../../../../hooks/useRedux';
import { useFormik } from 'formik';
import {
  Autocomplete,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
} from '@material-ui/core';
import SuccessAlert from '../../../../../shared/components/alerts/SuccessAlert';
import ErrorAlert from '../../../../../shared/components/alerts/ErrorAlert';
import { useTranslation } from 'react-i18next';

const ScopeForm = () => {
  const { t } = useTranslation();
  const [showAlerts, setShowAlerts] = useState(false);

  const [addScope, { isLoading, isError, error, isSuccess }] = useAddScopeMutation();

  const [
    updateScope,
    { isLoading: isLoadingU, isError: isErrorU, error: errorU, isSuccess: isSuccessU },
  ] = useUpdateScopeMutation();

  const { scopesList, scopesIdSelected } = useAppSelector((state) => state.scopes);

  const scopesSelected = scopesList.find((option) => option.uuid === scopesIdSelected);

  const statusOptions = [
    { value: 'A', label: t('maintenance.scopes.status.active') },
    { value: 'I', label: t('maintenance.scopes.status.inactive') },
  ];

  const { errors, getFieldProps, setFieldValue, handleSubmit, touched, resetForm } =
    useFormik<IScope>({
      initialValues: {
        name: scopesSelected?.name || '',
        status: scopesSelected?.status ? scopesSelected?.status : 'A',
        description: scopesSelected?.description || '',
      },
      validationSchema: scopeFormSchema,
      onSubmit: async (currentValues) => {
        if (scopesIdSelected) {
          await updateScope({
            id: scopesIdSelected,
            name: currentValues.name,
            status: currentValues.status,
            description: currentValues.description,
          });
        } else {
          await addScope({
            name: currentValues.name,
            status: currentValues.status,
            description: currentValues.description,
          });
        }

        setShowAlerts(true);
      },
    });

  useEffect(() => {
    if (isSuccess) {
      resetForm();
    }
  }, [isSuccess, resetForm]);

  return (
    <Grid container spacing={2}>
      {(isSuccess || isSuccessU) && showAlerts && (
        <Grid item xs={12}>
          <SuccessAlert
            message={
              isSuccess ? t('maintenance.scopes.add.success') : t('maintenance.scopes.edit.success')
            }
            handleDismiss={() => setShowAlerts(false)}
          />
        </Grid>
      )}
      {(isError || isErrorU) && showAlerts && (
        <Grid item xs={12}>
          <ErrorAlert
            message={(error as any) || (errorU as any)}
            handleDismiss={() => setShowAlerts(false)}
          />
        </Grid>
      )}

      <Grid item xs={12}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputLabel>{t('maintenance.scopes.form.name')}</InputLabel>
              <TextField
                placeholder={t('maintenance.scopes.form.name.placeholder')}
                error={Boolean(errors.name && touched.name)}
                {...getFieldProps('name')}
                fullWidth
              />
              {errors.name && touched.name && (
                <FormHelperText error>{t(errors.name)}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12}>
              <InputLabel>{t('maintenance.scopes.form.description')}</InputLabel>
              <TextField
                placeholder={t('maintenance.scopes.form.description.placeholder')}
                error={Boolean(errors.description && touched.description)}
                {...getFieldProps('description')}
                fullWidth
              />
              {errors.description && touched.description && (
                <FormHelperText error>{t(errors.description)}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12}>
              <InputLabel>{t('maintenance.scopes.form.status')}</InputLabel>
              <Autocomplete
                options={statusOptions}
                onChange={(event, value) => {
                  setFieldValue('status', value?.value);
                }}
                value={statusOptions.find(
                  (option) => option.value === getFieldProps('status').value
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={t('maintenance.scopes.form.status.placeholder')}
                    fullWidth
                  />
                )}
              />
              {errors.status && touched.status && (
                <FormHelperText error>{t(errors.status)}</FormHelperText>
              )}
            </Grid>

            <Grid item container xs={12} justifyContent="flex-end" spacing={2}>
              <Grid item>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  disabled={isLoading || isLoadingU}
                  endIcon={isLoading || (isLoadingU && <CircularProgress size={12} />)}
                >
                  {!scopesIdSelected
                    ? t('maintenance.scopes.add.submit')
                    : t('maintenance.scopes.edit.submit')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default ScopeForm;
