import { Box, Tooltip } from '@material-ui/core';
import { baseUrl } from '../../../../../api/mainApi';
import { useAppDispatch } from '../../../../../hooks/useRedux';
import { IBook } from '../../../books/interfaces/books.interfaces';
import { setBookIdToReserve, setReserveBookModalOpen } from '../../slices/libraryDashboardSlice';

import bookCover from '../../../../../assets/images/nbs-book-empty-cover.jpg';

interface BookItemProps {
  book: IBook;
}

const BookItem = ({ book }: BookItemProps) => {
  const dispatch = useAppDispatch();

  const handleReserveBook = () => {
    dispatch(setBookIdToReserve(book.id));
    dispatch(setReserveBookModalOpen(true));
  };
  return (
    <Tooltip title={book.title}>
      <Box
        sx={{
          height: '100%',
          borderRadius: '12px',
          '&:hover': {
            transform: 'scale(1.1)',
            cursor: 'pointer',
          },
          '& > img': {
            borderRadius: '12px',
          },
        }}
        onClick={handleReserveBook}
      >
        <img
          src={book.book_cover ? `${baseUrl}/show?name=${book.book_cover}` : bookCover}
          alt="book"
          width="100%"
          height="100%"
          style={{
            height: '100%',
          }}
        />
      </Box>
    </Tooltip>
  );
};

export default BookItem;
