import { useEffect, useState } from 'react';
import { EvaluationDataSearch } from '../interfaces/evaluation-search.interfaces';
import { useGetEvaluationsTeacherQuery } from '../slices/evaluationApiSlice';
import EvaluationListTable from './tables/EvaluationListTable';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import { renderSkeletonTable } from '../../../../shared/helpers/render';
import { setEvaluations } from '../slices/evaluationSlice';

const EvaluationList = function () {
  const dispatch = useAppDispatch();

  const [page, setPage] = useState<number>(1);

  const [perPage, setPerPage] = useState<number>(5);

  const [supply, setSupply] = useState<number | null>(null);
  const [status, setStatus] = useState<string | null>(null);

  const DataSearchBlank: EvaluationDataSearch = { courseId: 0, periodId: 0, year: 0 };

  const [dataSearch, setDataSearch] = useState<EvaluationDataSearch>(DataSearchBlank);

  const { currentProfile } = useAppSelector((state) => state.access);

  const {
    course: { courseId },
  } = useAppSelector((state) => state.virtualClassroomTeacherShared);

  const { data, isLoading, isFetching, error } = useGetEvaluationsTeacherQuery(
    {
      page: page,
      perPage: perPage,
      courseId: dataSearch.courseId === 0 ? courseId! : dataSearch.courseId,
      userProfileId: currentProfile?.profileId!,
      ...(supply && { supply }),
      ...(status && { estado: status }),
    },
    { skip: courseId === 0 || courseId === undefined }
  );

  useEffect(() => {
    if (data) {
      dispatch(setEvaluations(data?.data!));
    }
  }, [data, dispatch]);

  return (
    <>
      {isLoading ? (
        renderSkeletonTable(5, 7)
      ) : (
        <EvaluationListTable
          data={data?.data!}
          isLoading={isLoading}
          isFetching={isFetching}
          error={error}
          perPage={perPage}
          setPerPage={setPerPage}
          total={data?.total! || 0}
          page={page}
          setPage={setPage}
          numHeader={5}
          dataSearch={dataSearch}
          setDataSearch={setDataSearch}
          setSupply={setSupply}
          setStatus={setStatus}
        />
      )}
    </>
  );
};

export default EvaluationList;
