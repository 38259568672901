import { mainApi } from '../../../api/mainApi';
import { mainApiOauth } from '../../../api/mainApiOauth';

import {
  resetProfile,
  setCompanyNet,
  // setCurrentModule,
  // setCurrentProfile,
  // setCurrentRol,
  setCurrentVirtualClassroom,
  setDefaultConfiguration,
  setMsjBusinessHours,
  setServiceCredentials,
} from '../../shared/reducers/accessReducer';
import { loginSync, logoutSync, setTenant } from './authSlice';
import { middlewareApi } from '../../middleware/middlewareApi';

import {
  buildSessionStorage,
  checkModuleQuantity,
  checkProfileQuantity,
  mapUserProfiles,
  validationProfilesRol,
  // mapPermissions,
} from '../helpers';

import jwt_decode from 'jwt-decode';

import { GoogleLoginUrlResponse, LoginResponse, TokenJWT } from '../interfaces/login.interfaces';
import { VerifyCookieResponse, VerifyResponse } from '../interfaces/verify.interfaces';
import { LogoutResponse } from '../interfaces/logout.interfaces';
import { ResetPassword } from '../interfaces/resetPassword.interfaces';
import { setCollaboratorInfoIfExist } from '../helpers/helperCollaborator';
import { setCurrentStudentIfExist } from '../helpers/helperStudent';
import { Permission } from '../../shared/interfaces/permission.interfaces';
import { AccessState, Languages } from '../../../types';
import { setLanguage, setPresetColor } from '../../shared/reducers/customizationReducer';
import { initialDefaultConfiguration } from '../../shared/constants/defaultConfiguration';
import { DefaultConfiguration, TenantsResponse } from '../../shared/interfaces/tenants.interfaces';
import { getProtocol } from '../../../helpers/urlHelpers';
import { ParsedUrlQuery } from 'querystring';

export const authApi = middlewareApi.injectEndpoints({
  // reducerPath: 'authApi',
  // baseQuery: fetchBaseQuery(),
  endpoints: (builder) => ({
    login: builder.mutation<LoginResponse, { username: string; password: string }>({
      queryFn: async ({ username, password }, { dispatch }) => {
        try {
          const trimmedPassword = password.trim();
          const { data } = await mainApi.post<LoginResponse>('/login', {
            us_username: username,
            password: trimmedPassword,
          });

          const token = data.access_token;
          localStorage.setItem('x-token', token);

          const { user } = jwt_decode<TokenJWT>(data.access_token);

          /**
           * Valida la existencia de perfiles y roles
           */
          validationProfilesRol(dispatch, user);

          /**
           * Construye profiles que esta dentro de access
           * lo asigna al store y lo retorna
           */
          const profiles = mapUserProfiles(dispatch, user.user_profiles);

          /**
           * Verifica la cantidad de perfiles y si existe uno asigna
           * los valores correspondientes al localStorage y al store.
           * Retorna los modulos de ese perfil.
           *
           * Si existe mas de un perfil se debe de asignar desde
           * el componente ProfileScreen.tsx y retornara un objeto vacio.
           */
          const { modules } = checkProfileQuantity(dispatch, profiles);

          /**
           * Valida que se envie un solo modulo para asignarlo al
           * currentModule en el store.
           * Si existe mas de un modulo se debe aignar desde el componete
           * ModuleScreen.tsx
           */
          checkModuleQuantity(dispatch, modules);

          dispatch(
            loginSync({
              name: user.us_firstname,
              fullname: `${user.us_first_lastname === null ? '' : user.us_first_lastname} ${
                user.us_second_lastname === null ? '' : user.us_second_lastname
              } ${user.us_firstname === null ? '' : user.us_firstname} ${
                user.us_secondname === null ? '' : user.us_secondname
              }`,
              secondname: user.us_secondname === null ? '' : user.us_secondname,
              lastname: user.us_first_lastname === null ? '' : user.us_first_lastname,
              secondlastname: user.us_second_lastname === null ? '' : user.us_second_lastname,
              gender: user.us_gender,
              email: user.email,
              zendesk: user.zendesk,
              token,
              remember_token: user.remember_token,
              id: user.id,
              personId: user.person_id,
              identification: user.us_identification,
              hasSign: user.has_sign,
            })
          );

          /**
           * Verifica si existe el estudiante y asigna los valores
           * al store y al localStorage
           */
          setCurrentStudentIfExist(dispatch, user);

          /**
           * Verifica si existe colaborador y asigna collaboratorInfo al store
           */
          console.log(user);
          setCollaboratorInfoIfExist(dispatch, user);

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    loginGoogle: builder.mutation<LoginResponse, { gmail: string }>({
      queryFn: async ({ gmail }, { dispatch }) => {
        try {
          const { data } = await mainApi.post<LoginResponse>('/login-gmail', {
            email: gmail,
          });

          const token = data.access_token;
          localStorage.setItem('x-token', token);
          const { user } = jwt_decode<TokenJWT>(data.access_token);

          /**
           * Valida la existencia de perfiles y roles
           */
          validationProfilesRol(dispatch, user);

          /**
           * Construye profiles que esta dentro de access
           * lo asigna al store y lo retorna
           */
          const profiles = mapUserProfiles(dispatch, user.user_profiles);

          /**
           * Verifica la cantidad de perfiles y si existe uno asigna
           * los valores correspondientes al localStorage y al store.
           * Retorna los modulos de ese perfil.
           *
           * Si existe mas de un perfil se debe de asignar desde
           * el componente ProfileScreen.tsx y retornara un objeto vacio.
           */
          const { modules } = checkProfileQuantity(dispatch, profiles);

          /**
           * Valida que se envie un solo modulo para asignarlo al
           * currentModule en el store.
           * Si existe mas de un modulo se debe aignar desde el componete
           * ModuleScreen.tsx
           */
          checkModuleQuantity(dispatch, modules);

          dispatch(
            loginSync({
              name: user.us_firstname,
              fullname: `${user.us_first_lastname === null ? '' : user.us_first_lastname} ${
                user.us_second_lastname === null ? '' : user.us_second_lastname
              } ${user.us_firstname === null ? '' : user.us_firstname} ${
                user.us_secondname === null ? '' : user.us_secondname
              }`,
              secondname: user.us_secondname === null ? '' : user.us_secondname,
              lastname: user.us_first_lastname === null ? '' : user.us_first_lastname,
              secondlastname: user.us_second_lastname === null ? '' : user.us_second_lastname,
              gender: user.us_gender,
              email: user.email,
              zendesk: user.zendesk,
              token,
              remember_token: user.remember_token,
              id: user.id,
              personId: user.person_id,
              identification: user.us_identification,
              hasSign: user.has_sign,
            })
          );

          /**
           * Verifica si existe el estudiante y asigna los valores
           * al store y al localStorage
           */
          setCurrentStudentIfExist(dispatch, user);

          /**
           * Verifica si existe colaborador y asigna collaboratorInfo al store
           */
          console.log(user);
          setCollaboratorInfoIfExist(dispatch, user);

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    loginOauth: builder.mutation<LoginResponse, { username: string; password: string }>({
      queryFn: async ({ username, password }, { dispatch }) => {
        try {
          // const protocol = getProtocol();

          const trimmedPassword = password.trim();
          const { data } = await mainApiOauth.post<LoginResponse>(
            '/api/oauth/token',
            {
              username,
              password: trimmedPassword,
            },
            {
              withCredentials:
                process.env.REACT_APP_COOKIES_WITH_CREDENTIALS?.toLowerCase() === 'true'
                  ? true
                  : false,
            }
          );

          const token = data.access_token;
          localStorage.setItem('x-token', token);

          const { user } = jwt_decode<TokenJWT>(data.access_token);

          /**
           * Valida la existencia de perfiles y roles
           */
          validationProfilesRol(dispatch, user);

          /**
           * Construye profiles que esta dentro de access
           * lo asigna al store y lo retorna
           */

          const profiles = mapUserProfiles(dispatch, user.user_profiles);

          /**
           * Verifica la cantidad de perfiles y si existe uno asigna
           * los valores correspondientes al localStorage y al store.
           * Retorna los modulos de ese perfil.
           *
           * Si existe mas de un perfil se debe de asignar desde
           * el componente ProfileScreen.tsx y retornara un objeto vacio.
           */
          const { modules } = checkProfileQuantity(dispatch, profiles);

          /**
           * Valida que se envie un solo modulo para asignarlo al
           * currentModule en el store.
           * Si existe mas de un modulo se debe aignar desde el componete
           * ModuleScreen.tsx
           */
          checkModuleQuantity(dispatch, modules);

          dispatch(
            loginSync({
              name: user.us_firstname,
              fullname: `${user.us_first_lastname === null ? '' : user.us_first_lastname} ${
                user.us_second_lastname === null ? '' : user.us_second_lastname
              } ${user.us_firstname === null ? '' : user.us_firstname} ${
                user.us_secondname === null ? '' : user.us_secondname
              }`,
              secondname: user.us_secondname === null ? '' : user.us_secondname,
              lastname: user.us_first_lastname === null ? '' : user.us_first_lastname,
              secondlastname: user.us_second_lastname === null ? '' : user.us_second_lastname,
              gender: user.us_gender,
              email: user.email,
              zendesk: user.zendesk,
              token,
              remember_token: user.remember_token,
              id: user.id,
              personId: user.person_id,
              identification: user.us_identification,
              hasSign: user.has_sign,
            })
          );

          /**
           * Verifica si existe el estudiante y asigna los valores
           * al store y al localStorage
           */
          setCurrentStudentIfExist(dispatch, user);

          /**
           * Verifica si existe colaborador y asigna collaboratorInfo al store
           */
          console.log(user);
          setCollaboratorInfoIfExist(dispatch, user);

          return { data };
        } catch (error: any) {
          console.log('error show', error);
          return { error };
        }
      },
    }),

    resetPassword: builder.mutation<ResetPassword, { username: string }>({
      queryFn: async ({ username }) => {
        try {
          const { data } = await mainApi.post<ResetPassword>('/olvidar-clave', {
            us_username: username,
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    sendNewPassword: builder.mutation<
      ResetPassword,
      { token: string; password: string; passwordConfirmation: string; email: string }
    >({
      queryFn: async ({ token, password, passwordConfirmation, email }, { dispatch }) => {
        try {
          const trimmedPassword = password.trim();
          const trimmedPasswordConfirmation = passwordConfirmation.trim();

          const { data } = await mainApi.post<ResetPassword>(
            '/restablecer-clave',
            {
              token,
              password: trimmedPassword,
              password_confirmation: trimmedPasswordConfirmation,
            },
            {
              params: {
                email: email,
              },
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    validationTokenEmail: builder.query<ResetPassword, { email: string; token: string }>({
      queryFn: async ({ email, token }) => {
        try {
          const { data } = await mainApi.get<ResetPassword>(`/restablecer-clave/${token}`, {
            params: {
              email,
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    verifyTokenCookie: builder.mutation<VerifyCookieResponse, {}>({
      queryFn: async () => {
        try {
          const { data } = await mainApi.post<VerifyCookieResponse>(
            '/token/verify',
            {},
            {
              withCredentials:
                process.env.REACT_APP_COOKIES_WITH_CREDENTIALS?.toLowerCase() === 'true'
                  ? true
                  : false,
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    verifySession: builder.mutation<VerifyResponse, string>({
      queryFn: async (token, { dispatch }) => {
        // let noRoles = false;

        try {
          const { data } = await mainApi.get<VerifyResponse>('/whoami');

          /**
           * Valida la existencia de perfiles y roles
           */
          validationProfilesRol(dispatch, data);

          /**
           * Construye profiles que esta dentro de access
           * y lo asigna al store
           */
          const profiles = mapUserProfiles(dispatch, data.user_profiles);

          /**
           * Se contruye la session con las keys que estan en el localStorage
           */
          buildSessionStorage(dispatch, profiles);

          dispatch(
            loginSync({
              name: data.us_firstname,
              fullname: `${data.us_first_lastname === null ? '' : data.us_first_lastname} ${
                data.us_second_lastname === null ? '' : data.us_second_lastname
              } ${data.us_firstname === null ? '' : data.us_firstname} ${
                data.us_secondname === null ? '' : data.us_secondname
              }`,
              secondname: data.us_secondname === null ? '' : data.us_secondname,
              lastname: data.us_first_lastname === null ? '' : data.us_first_lastname,
              secondlastname: data.us_second_lastname === null ? '' : data.us_second_lastname,
              gender: data.us_gender,
              email: data.email,
              zendesk: data.zendesk,
              token,
              remember_token: data.remember_token,
              id: data.id,
              personId: data.person_id,
              identification: data.us_identification,
              hasSign: data.has_sign,
            })
          );
          /**
           * Estudiante
           */

          const defaultConfiguration = localStorage.getItem('defaultConfiguration')
            ? (JSON.parse(localStorage.getItem('defaultConfiguration')!) as DefaultConfiguration)
            : initialDefaultConfiguration;

          const language =
            data.app_language === 'NULL'
              ? (defaultConfiguration.language as Languages)
              : (data.app_language as Languages);

          dispatch(setLanguage(language));

          setCurrentStudentIfExist(dispatch, data);

          /**
           * Verifica si existe colaborador y asigna collaboratorInfo al store
           */
          console.log(data);
          setCollaboratorInfoIfExist(dispatch, data);

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    logout: builder.mutation<LogoutResponse, { noSync?: boolean }>({
      queryFn: async ({ noSync }, { dispatch }) => {
        try {
          if (!noSync) {
            dispatch(logoutSync());
            dispatch(resetProfile());
            dispatch(setCurrentVirtualClassroom(false));
          }

          const { data } = await mainApi.post(
            '/logout',
            {},
            {
              withCredentials:
                process.env.REACT_APP_COOKIES_WITH_CREDENTIALS?.toLowerCase() === 'true'
                  ? true
                  : false,
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        } finally {
          if (!noSync) {
            localStorage.removeItem('x-token');
            localStorage.removeItem('currentRol');
            localStorage.removeItem('currentProfile');
            localStorage.removeItem('currentStudentRecord');
            localStorage.removeItem('currentModule');
            localStorage.removeItem('currentCourseStudent');
          }
        }
      },
    }),

    /**
     * @GET  permissions by module
     */
    getPermissionsByModule: builder.query<
      Permission[],
      { profileId?: number; keyword: string; rolId: string; token_client: string }
    >({
      queryFn: async ({ profileId, keyword, rolId, token_client }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = profileId || currentProfile?.profileId;

          // const rolId = JSON.parse(localStorage.getItem('currentRol')!).id!;
          const { data } = await mainApi.get<Permission[]>(`/modules/${keyword}/permissions`, {
            params: {
              user_profile_id,
              role: JSON.parse(rolId).id!,
              token_client,
            },
          });
          // await mapPermissions(dispatch, data);

          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    getTenant: builder.query<TenantsResponse, {}>({
      // eslint-disable-next-line no-empty-pattern
      queryFn: async ({}, { dispatch }) => {
        try {
          const protocol = getProtocol();

          const { data } = await mainApi.get<TenantsResponse>(
            `${protocol}://${
              process.env.REACT_APP_LANDLORD_API_URL
            }/api/tenants?search=${window.location.origin.replace(':3000', '')}`,
            {}
          );

          localStorage.setItem('apiUrl', data.domain);
          localStorage.setItem('logoPath', data.logo_path);
          localStorage.setItem('banners', JSON.stringify(data.banners));
          localStorage.setItem('defaultConfiguration', JSON.stringify(data.default_configuration));
          localStorage.setItem('serviceCredentials', JSON.stringify(data.service_credentials));
          localStorage.setItem('msjBusinessHours', data.msj_business_hours || '');
          // localStorage.setItem('companySocialNetwork', JSON.stringify(data.companySocialNetwork));

          // console.log(data);
          dispatch(setDefaultConfiguration(data.default_configuration));
          dispatch(setServiceCredentials(data.service_credentials));
          dispatch(setTenant(data.name));
          dispatch(setPresetColor(data.name));
          dispatch(setMsjBusinessHours(data.msj_business_hours || ''));
          dispatch(setCompanyNet(data.companySocialNetwork));
          // localStorage.setItem('defauletConfiguration', JSON.strieengify(data.default_configuration));

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    getGoogleLoginUrl: builder.query<GoogleLoginUrlResponse, {}>({
      queryFn: async () => {
        try {
          const { data } = await mainApi.get<GoogleLoginUrlResponse>('/auth-gmail');

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    validateGoogleLogin: builder.query<
      LoginResponse,
      {
        params: ParsedUrlQuery;
      }
    >({
      queryFn: async ({ params }) => {
        try {
          const { data } = await mainApi.get<LoginResponse>(`/auth-gmail/callback`, {
            params,
            withCredentials:
              process.env.REACT_APP_COOKIES_WITH_CREDENTIALS?.toLowerCase() === 'true'
                ? true
                : false,
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),
  }),
});

export const {
  useLoginMutation,
  useLoginGoogleMutation,
  useLoginOauthMutation,
  useVerifySessionMutation,
  useLogoutMutation,
  useResetPasswordMutation,
  useValidationTokenEmailQuery,
  useSendNewPasswordMutation,
  useGetPermissionsByModuleQuery,
  useLazyGetTenantQuery,
  useVerifyTokenCookieMutation,
  useGetGoogleLoginUrlQuery,
  useValidateGoogleLoginQuery,
} = authApi;
