import {
  Box,
  TableCell,
  TableRow,
  TableContainer,
  Table,
  TableHead,
  TableBody,
} from '@material-ui/core';
import SubCard from '../../../../shared/components/cards/SubCard';
import { Datehistoryfees } from '../../../../students/payments-history/interfaces/paymentsHistory.interface';
import { useTranslation } from 'react-i18next';
import { renderErrorOrEmptyRow } from '../../../../shared/helpers/render';
import { useTheme } from '@material-ui/styles';

interface CollapsibleContentRowProps {
  date_history_fees: Datehistoryfees[];
}

const CollapsibleContentRow = ({ date_history_fees }: CollapsibleContentRowProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Box margin={1}>
      <TableContainer>
        <SubCard content={false} sx={{ mb: 2 }}>
          <Table size="small" aria-label="purchases">
            <TableHead>
              <TableRow hover sx={{ backgroundColor: (theme as any).palette.grey[200] }}>
                <TableCell>{t('Nombre')}</TableCell>
                <TableCell>{t('Identificacion')}</TableCell>
                <TableCell>{t('Fecha anterior')}</TableCell>
                <TableCell>{t('Fecha actualizada')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {date_history_fees.length > 0
                ? date_history_fees?.map((detail) => (
                    <TableRow>
                      <TableCell>{detail.pers_full_name}</TableCell>
                      <TableCell>{detail.pers_identification}</TableCell>
                      <TableCell>{detail.expiration_date_old}</TableCell>
                      <TableCell>{detail?.expiration_date_new}</TableCell>
                    </TableRow>
                  ))
                : renderErrorOrEmptyRow(4)}
            </TableBody>
          </Table>
        </SubCard>
      </TableContainer>
    </Box>
  );
};

export default CollapsibleContentRow;
