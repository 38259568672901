import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import MainCard from '../../../../shared/components/cards/MainCard';
import MaterialList from '../components/MaterialList';

const MaterialScreen = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Grid
        component={MainCard}
        sx={{
          borderTopLeftRadius: '0px',
          borderTopRightRadius: '0px',
        }}
      >
        <Grid sx={{ mb: 3 }}>
          <Typography variant="h2">{t('studentVirtualClassroom.materials.title')}</Typography>
        </Grid>
        <MaterialList />
      </Grid>
    </React.Fragment>
  );
};

export default MaterialScreen;
