import DeleteAlert from '../../../shared/components/dialogs/DeleteAlert';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { useDeleteEducationLevelMutation } from '../slices/educationLevelsApiSlice';
import { resetEducationLevelIdToDelete, setDeleteModalOpen } from '../slices/educationLevelsSlice';

const DeletePeriodDialog = () => {
  const dispatch = useAppDispatch();

  const { deleteModalOpen, educationLevelIdToDelete } = useAppSelector(
    (state) => state.educationLevels
  );
  const { currentProfile } = useAppSelector((state) => state.access);

  const [deletePeriod, { isLoading, isSuccess, isError, error }] =
    useDeleteEducationLevelMutation();

  const handleDelete = () => {
    deletePeriod({
      profileId: currentProfile?.profileId!,
      educationLevelId: educationLevelIdToDelete!,
    });
  };

  const handleClose = () => {
    dispatch(setDeleteModalOpen(false));
    dispatch(resetEducationLevelIdToDelete());
  };

  return (
    <DeleteAlert
      open={deleteModalOpen}
      onClose={handleClose}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      handleDelete={handleDelete}
      error={error as string}
    />
  );
};

export default DeletePeriodDialog;
