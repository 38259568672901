import { Button, ButtonProps } from '@material-ui/core';
import { Plus } from 'phosphor-react';
import { useTranslation } from 'react-i18next';

interface Props extends ButtonProps {
  btnText?: string;
}

const AddButton = ({ onClick, btnText, ...props }: Props) => {
  const { t } = useTranslation();

  return (
    <Button variant="contained" startIcon={<Plus size={15} />} onClick={onClick} {...props}>
      {btnText ? btnText : t('shared.add_button.tooltip')}
    </Button>
  );
};

export default AddButton;
