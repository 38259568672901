import * as yup from 'yup';

export const headerSchema = yup.object().shape({
  educationLvl: yup.object().required('Campo es requerido.').nullable(),
  period: yup.object().required('Campo es requerido.').nullable(),
  // teachers: yup.array().min(1, 'Escoja al menos un docente.'),
  journey: yup.object().required('Campo es requerido.'),
});

export const hoursSummariesSchema = yup.object().shape({
  journey: yup.string().required('Jornada requerida'),
  hourSummary: yup.array().when('journey', (journey, schema) => {
    return journey === 'TC'
      ? schema.of(tcSchema)
      : journey === 'MT'
      ? schema.of(mtSchema)
      : journey === 'TP'
      ? schema.of(tpSchema)
      : schema.nullable();
  }),
});

/**
 *Schema tiempo completo
 */
const tcSchema = yup.object().shape({
  hs_classes: yup.number().required('Requerido').moreThan(-1, 'No válido'),
  hs_classes_examns_preparation: yup.number().required('Requerido').moreThan(-1, 'No válido'),
  hs_tutoring: yup.number().required('Requerido').moreThan(-1, 'No válido'),
  hs_bonding: yup.number().required('Requerido').moreThan(-1, 'No válido'),
  hs_academic_management: yup.number().required('Requerido').moreThan(-1, 'No válido'),
  hs_research: yup.number().required('Requerido').moreThan(-1, 'No válido'),
  total: yup.number().test('validateHours', 'Requiere 40 horas.', (value: any) => {
    if (value !== 40 && value !== 0) return false;
    return true;
  }),
});
/**
 * Schema medio tiempo
 */
const mtSchema = yup.object().shape({
  hs_classes: yup.number().required('Requerido').moreThan(-1, 'No válido'),
  hs_classes_examns_preparation: yup.number().required('Requerido').moreThan(-1, 'No válido'),
  hs_tutoring: yup.number().required('Requerido').moreThan(-1, 'No válido'),
  hs_bonding: yup.number().required('Requerido').moreThan(-1, 'No válido'),
  hs_academic_management: yup.number().required('Requerido').moreThan(-1, 'No válido'),
  hs_research: yup.number().required('Requerido').moreThan(-1, 'No válido'),
  total: yup.number().test('validateHours', 'Requiere 20 horas.', (value: any) => {
    if (value !== 20 && value !== 0) return false;
    return true;
  }),
});
/**
 * Schema tiempo parcial
 */
const tpSchema = yup.object().shape({
  hs_classes: yup.number().required('Requerido').moreThan(-1, 'No válido'),
  hs_classes_examns_preparation: yup.number().required('Requerido').moreThan(-1, 'No válido'),
  hs_tutoring: yup.number().required('Requerido').moreThan(-1, 'No válido'),
  hs_bonding: yup.number().required('Requerido').moreThan(-1, 'No válido'),
  hs_academic_management: yup.number().required('Requerido').moreThan(-1, 'No válido'),
  hs_research: yup.number().required('Requerido').moreThan(-1, 'No válido'),
  total: yup.number().test('validateHours', 'Requiere min 20 horas.', (value: any) => {
    if (value > 20 && value === 0) return false;
    return true;
  }),
});

export const editHoursSummariesSchema = yup.object().shape({
  periods: yup.object().required('Escoja un periodo.').nullable(),
  educationLevels: yup.object().required('Escoja una unidad académica.').nullable(),
  hclasses: yup.number().required('Valor requerido.').moreThan(-1, 'No válido'),
  htutoring: yup.number().required('Valor requerido.').moreThan(-1, 'No válido'),
  hbonding: yup.number().required('Valor requerido.').moreThan(-1, 'No válido'),
  hAcademicManagement: yup.number().required('Valor requerido.').moreThan(-1, 'No válido'),
  hPreparation: yup.number().required('Valor requerido.').moreThan(-1, 'No válido'),
  hResearch: yup.number().required('Valor requerido.').moreThan(-1, 'No válido'),
  journeyDescription: yup.string().required('Tipo de Jornada requerido'),
});
