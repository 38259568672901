/**
 * MUI Components whose styles are override as per theme
 * @param {JsonObject} theme Plain Json Object
 */
import { FontStyle } from '@material-ui/core/styles/createTypography';
import i18next from 'i18next';

// project imports
import { CustomTypography } from './types';

export const componentStyleOverrides = (theme: CustomTypography) => {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          textTransform: 'capitalize' as FontStyle['textTransform'],
          borderRadius: '1.5rem',
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          backgroundImage: 'none',
        },
        rounded: {
          borderRadius: theme?.customization?.borderRadius + 'px',
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          color: theme.colors?.textDark,
          padding: '24px',
        },
        title: {
          fontSize: '1.125rem',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '24px',
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: '24px',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          alignItems: 'center',
        },
        outlined: {
          border: '1px dashed',
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          '&.MuiListItem-root': {
            color: theme.darkTextPrimary,
            paddingTop: '10px',
            paddingBottom: '10px',
            '&.Mui-selected': {
              color: theme.menuSelected,
              backgroundColor: theme.menuSelectedBack,
              '&:hover': {
                backgroundColor: theme.menuSelectedBack,
              },
              '& .MuiListItemIcon-root': {
                color: theme.menuSelected,
              },
            },
            '&:hover': {
              backgroundColor: theme.menuSelectedBack,
              color: theme.menuSelected,
              '& .MuiListItemIcon-root': {
                color: theme.menuSelected,
              },
            },
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: theme.darkTextPrimary,
          minWidth: '36px',
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          color: theme.textDark,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          color: theme.textDark,
          '&::placeholder': {
            color: theme.darkTextSecondary,
            fontSize: '0.875rem',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          background: theme?.customization?.outlinedFilled
            ? theme?.customization?.themeMode === 'dark'
              ? theme.colors?.darkBackground
              : theme.colors?.grey50
            : 'transparent',
          borderRadius: theme?.customization?.borderRadius + 'px',
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor:
              theme?.customization?.themeMode === 'dark'
                ? theme.colors?.darkTextPrimary! + 28
                : theme.colors?.grey400,
          },
          '&:hover $notchedOutline': {
            borderColor: theme.colors?.primaryLight,
          },
          '&.MuiInputBase-multiline': {
            padding: 1,
          },
        },
        input: {
          fontWeight: 500,
          background: theme?.customization?.outlinedFilled
            ? theme?.customization?.themeMode === 'dark'
              ? theme.colors?.darkBackground
              : theme.colors?.grey50
            : 'transparent',
          padding: '15.5px 14px',
          borderRadius: theme?.customization?.borderRadius + 'px',
          '&.MuiInputBase-inputSizeSmall': {
            padding: '10px 14px',
            '&.MuiInputBase-inputAdornedStart': {
              paddingLeft: 0,
            },
          },
        },
        inputAdornedStart: {
          paddingLeft: 4,
        },
        notchedOutline: {
          borderRadius: theme?.customization?.borderRadius + 'px',
        },
      },
      defaultProps: {
        fullWidth: true,
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color:
              theme?.customization?.themeMode === 'dark'
                ? theme.colors?.darkTextPrimary! + 50
                : theme.colors?.grey300,
          },
        },
        mark: {
          backgroundColor: theme.paper,
          width: '4px',
        },
        valueLabel: {
          color: theme.colors?.primaryMain,
        },
      },
    },
    MuiAutocomplete: {
      /**
       * Se sobreescriben los valores por defecto para los textos
       * de cargando y sin resultados
       */
      defaultProps: {
        noOptionsText: i18next.t('utils.no_options'),
        loadingText: i18next.t('utils.loading_options'),
      },
      styleOverrides: {
        root: {
          '& .MuiAutocomplete-tag': {
            background:
              theme?.customization?.themeMode === 'dark'
                ? theme.colors?.darkTextPrimary! + 20
                : theme.colors?.secondaryLight,
            borderRadius: 4,
            color: theme.textDark,
            '.MuiChip-deleteIcon': {
              color:
                theme?.customization?.themeMode === 'dark'
                  ? theme.colors?.darkTextPrimary! + 80
                  : theme.colors?.secondary200,
            },
          },
        },
        popper: {
          borderRadius: theme?.customization?.borderRadius + 'px',
          boxShadow:
            '0px 8px 10px -5px rgb(0 0 0 / 20%), 0px 16px 24px 2px rgb(0 0 0 / 14%), 0px 6px 30px 5px rgb(0 0 0 / 12%)',
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: theme.divider,
          opacity: theme?.customization?.themeMode === 'dark' ? 0.2 : 1,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          '&:focus': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          /** checked not prop
           *"&.Mui-checked": {
           *    fontSize: "28px"
           *}
           */
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          color:
            theme?.customization?.themeMode === 'dark'
              ? theme.colors?.darkLevel1
              : theme.colors?.primaryDark,
          background:
            theme?.customization?.themeMode === 'dark'
              ? theme.darkTextPrimary
              : theme.colors?.primary200,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          '&.MuiChip-deletable .MuiChip-deleteIcon': {
            color: 'inherit',
          },
        },
      },
    },
    MuiTimelineContent: {
      styleOverrides: {
        root: {
          color: theme.textDark,
          fontSize: '16px',
        },
      },
    },
    MuiTreeItem: {
      styleOverrides: {
        label: {
          marginTop: 14,
          marginBottom: 14,
        },
      },
    },
    MuiTimelineDot: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiDatePicker: {
      defaultProps: {
        InputProps: {
          placeholder: 'dd/mm/a',
        },
      },
    },
    MuiInternalDateTimePickerTabs: {
      styleOverrides: {
        tabs: {
          backgroundColor:
            theme?.customization?.themeMode === 'dark'
              ? theme.colors?.darkPaper
              : theme.colors?.primaryLight,
          '& .MuiTabs-flexContainer': {
            borderColor:
              theme?.customization?.themeMode === 'dark'
                ? theme?.colors?.darkTextPrimary! + 20
                : theme.colors?.primary200,
          },
          '& .MuiTab-root': {
            color:
              theme?.customization?.themeMode === 'dark'
                ? theme.colors?.darkTextSecondary
                : theme.colors?.grey900,
          },
          '& .MuiTabs-indicator': {
            backgroundColor: theme.colors?.primaryDark,
          },
          '& .Mui-selected': {
            color: theme.colors?.primaryDark,
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        flexContainer: {
          borderBottom: '1px solid',
          borderColor:
            theme?.customization?.themeMode === 'dark'
              ? theme.colors?.darkTextPrimary! + 20
              : theme.colors?.grey200,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          padding: '12px 0 12px 0',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderColor:
            theme?.customization?.themeMode === 'dark'
              ? theme.colors?.darkTextPrimary! + 15
              : theme.colors?.grey200,
          '&.MuiTableCell-head': {
            fontSize: '0.875rem',
            color: theme.heading,
            fontWeight: 500,
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          color:
            theme?.customization?.themeMode === 'dark' ? theme.colors?.darkLevel1 : theme.paper,
          background:
            theme?.customization?.themeMode === 'dark'
              ? theme.colors?.grey50
              : theme.colors?.grey700,
        },
      },
    },
  };
};
