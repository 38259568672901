import { mainApi } from '../../../../api/mainApi';
import { middlewareApi } from '../../../middleware/middlewareApi';
import { LendingsDetailResponse } from '../interfaces/lendings.detail.interface';
import { LendingsResponse } from '../interfaces/lendings.interface';
import { UsersResponse } from '../interfaces/users.interface';

const lendingTags = middlewareApi.enhanceEndpoints({
  addTagTypes: ['Lendings'],
});

const lendingsApi = lendingTags.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * @GET borrows
     */
    getLendings: builder.query<
      LendingsResponse,
      {
        not_return?: number;
        borrow_start_date?: string;
        borrow_end_date?: string;
        page?: number;
        size?: number;
        search?: string;
        sort?: string;
        type_sort?: string;
        profile_user_id?: number;
      }
    >({
      queryFn: async ({
        not_return,
        borrow_start_date,
        borrow_end_date,
        page,
        size,
        search,
        sort,
        type_sort,
        profile_user_id,
      }) => {
        try {
          const { data } = await mainApi.get<LendingsResponse>('borrows', {
            params: {
              not_return,
              borrow_start_date,
              borrow_end_date,
              page,
              size,
              search,
              sort,
              type_sort,
              ...(profile_user_id && { profile_user_id }),
            },
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: [
        {
          type: 'Lendings',
          id: 'TABLE',
        },
      ],
    }),
    /**
     * @GET lendings details
     */
    getLendingsDetails: builder.query<
      LendingsDetailResponse,
      {
        borrow_code: string;
      }
    >({
      queryFn: async ({ borrow_code }) => {
        try {
          const { data } = await mainApi.get<LendingsDetailResponse>(`borrows/${borrow_code}`);
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),
    /**
     * @POST devolver prestamo
     */
    returnLending: builder.mutation<
      any,
      {
        return_date: string;
        borrows: string[] | number[];
      }
    >({
      queryFn: async ({ return_date, borrows }) => {
        try {
          const { data } = await mainApi.post<any>('borrows/loan-return', {
            return_date,
            borrows,
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),
    /**
     * @GET users
     */
    getPersons: builder.query<
      UsersResponse,
      {
        page?: number;
        perPage?: number;
        search?: string;
        user_profile_id: number;
      }
    >({
      queryFn: async ({ search, user_profile_id, page, perPage }) => {
        try {
          const { data } = await mainApi.get<UsersResponse>('persons', {
            params: {
              search,
              page,
              size: perPage,
              user_profile_id,
            },
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),
    /**
     * @POST
     */
    addLending: builder.mutation<
      any,
      {
        payload: {
          user_profile_id: number;
          borrows: {
            model_type: 'book';
            model_id: number;
            borrow_date: string;
            borrow_return_date: string;
            copies_amount: 1;
            type: string;
            selected_user_profile_id: number;
            details: string;
          }[];
        };
      }
    >({
      queryFn: async ({ payload }) => {
        try {
          const { data } = await mainApi.post<any>('borrows', {
            ...payload,
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),
    /**
     * @POST
     */
    renewalLending: builder.mutation<
      any,
      {
        id: string;
        new_date: string;
      }
    >({
      queryFn: async ({ new_date, id }) => {
        try {
          const { data } = await mainApi.post<any>(`borrows/${id}/renewal`, {
            new_date,
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: (result) =>
        result
          ? [
              {
                id: 'TABLE',
                type: 'Lendings',
              },
            ]
          : [],
    }),
  }),
});

export const {
  useGetLendingsQuery,
  useLazyGetLendingsQuery,
  useGetLendingsDetailsQuery,
  useReturnLendingMutation,
  useGetPersonsQuery,
  useLazyGetPersonsQuery,
  useAddLendingMutation,
  useRenewalLendingMutation,
} = lendingsApi;
