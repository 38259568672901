export const SendDebtsWizard = 'enviar-deudas';
export const SendPaysWizard = 'enviar-pagos';
export const UpdateBillsWizard = 'actualizar-facturas';

export type WizardKeywords =
  | typeof SendDebtsWizard
  | typeof SendPaysWizard
  | typeof UpdateBillsWizard;

export const steps: WizardKeywords[] = [SendDebtsWizard, SendPaysWizard, UpdateBillsWizard];

const generateRandomDecimal = (noDecimalPlaces: boolean = false) => {
  const randomDecimal = Math.random() * 9999 + 1;

  if (noDecimalPlaces) {
    return randomDecimal.toFixed(0);
  }

  return randomDecimal.toFixed(2);
};

export const likeAfterRegexMatch = (search: string) => new RegExp(search.toLowerCase() + '.*$');

export const returnDummyData = () => {
  return [
    {
      month: 1,
      endMonth: 'Enero',
      debts: generateRandomDecimal(true),
      amount: generateRandomDecimal(),
      options: '',
    },
    {
      month: 2,
      endMonth: 'Febrero',

      debts: generateRandomDecimal(true),

      amount: generateRandomDecimal(),
      options: '',
    },

    {
      month: 3,
      endMonth: 'Marzo',

      debts: generateRandomDecimal(true),

      amount: generateRandomDecimal(),
      options: '',
    },
    {
      month: 4,
      endMonth: 'Abril',

      debts: generateRandomDecimal(true),

      amount: generateRandomDecimal(),
      options: '',
    },

    {
      month: 5,
      endMonth: 'Mayo',

      debts: generateRandomDecimal(true),

      amount: generateRandomDecimal(),
      options: '',
    },
    {
      month: 6,
      endMonth: 'Junio',

      debts: generateRandomDecimal(true),

      amount: generateRandomDecimal(),
      options: '',
    },
    {
      month: 7,
      endMonth: 'Julio',

      debts: generateRandomDecimal(true),

      amount: generateRandomDecimal(),
      options: '',
    },
    {
      month: 8,
      endMonth: 'Agosto',

      debts: generateRandomDecimal(true),

      amount: generateRandomDecimal(),
      options: '',
    },
    {
      month: 9,
      endMonth: 'Septiembre',

      debts: generateRandomDecimal(true),

      amount: generateRandomDecimal(),
      options: '',
    },
    {
      month: 10,
      endMonth: 'Octubre',

      debts: generateRandomDecimal(true),

      amount: generateRandomDecimal(),
      options: '',
    },
    {
      month: 11,
      endMonth: 'Noviembre',

      debts: generateRandomDecimal(true),

      amount: generateRandomDecimal(),
      options: '',
    },
    {
      month: 12,
      endMonth: 'Diciembre',

      debts: generateRandomDecimal(true),

      amount: generateRandomDecimal(),
      options: '',
    },
  ];
};

const personNames = [
  'Elena Garcia Vargas',
  'Max Hernandez Perez',
  'Ava Martinez Diaz',
  'Lucas Gonzalez Ramirez',
  'Emma Sanchez Castillo',
  'David Rodriguez Mendoza',
  'Sophia Lopez Alvarez',
  'Gabriel Perez Gomez',
  'Isabella Flores Castro',
  'Dylan Rivera Hernandez',
  'Olivia Torres Ortiz',
  'Miguel Gonzalez Sanchez',
  'Avery Martinez Rivera',
  'Noah Ramirez Torres',
  'Madison Lopez Ruiz',
  'Sebastian Perez Hernandez',
  'Chloe Garcia Jimenez',
  'Anthony Fernandez Ortiz',
  'Emily Perez Castillo',
  'Levi Flores Mendoza',
];

function pickRandomName(personNames: string[]) {
  const randomIndex = Math.floor(Math.random() * personNames.length);
  return personNames[randomIndex];
}

export const returnDummyData2 = () => {
  return [
    {
      codigo: 1,
      //generate random person name
      cliente: pickRandomName(personNames),
      producto: 'Cuota 9/10',
      //rounded ramdon decimal between 1.00 and 10000.00
      amount: generateRandomDecimal(),
      updateState: '',
      state: 'POR MIGRAR',
      options: '',
    },
    {
      codigo: 2,
      //generate random person name
      cliente: pickRandomName(personNames),
      producto: 'Cuota 9/10',
      //rounded ramdon decimal between 1.00 and 10000.00
      amount: generateRandomDecimal(),
      updateState: '',
      state: 'POR MIGRAR',
      options: '',
    },
    {
      codigo: 3,
      //generate random person name
      cliente: pickRandomName(personNames),
      producto: 'Cuota 9/10',
      //rounded ramdon decimal between 1.00 and 10000.00

      amount: generateRandomDecimal(),
      updateState: '',
      state: 'POR MIGRAR',
      options: '',
    },
    {
      codigo: 4,
      //generate random person name
      cliente: pickRandomName(personNames),
      producto: 'Cuota 9/10',
      //rounded ramdon decimal between 1.00 and 10000.00
      amount: generateRandomDecimal(),
      updateState: '',
      state: 'POR MIGRAR',
      options: '',
    },

    {
      codigo: 5,
      //generate random person name
      cliente: pickRandomName(personNames),
      producto: 'Cuota 9/10',
      //rounded ramdon decimal between 1.00 and 10000.00
      amount: generateRandomDecimal(),
      updateState: '',
      state: 'POR MIGRAR',
      options: '',
    },
  ];
};
