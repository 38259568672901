import { useEffect } from 'react';
import { Redirect } from 'react-router-dom';

const RedirectTest = () => {
  useEffect(() => {
    console.log('redirect');
  }, []);
  return <Redirect to="/aula-virtual-estudiante/test" />;
};

export default RedirectTest;
