import { Grid, TextField, FormHelperText, Autocomplete, Button } from '@material-ui/core';
import { DatePicker, LocalizationProvider } from '@material-ui/lab';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import { useFormik } from 'formik';
import { IconSearch } from '@tabler/icons';

import InputLabel from '../../../../shared/components/forms/InputLabel';
import { useGetInvestigationLinesAllAvailablesQuery } from '../../slices/InvestigationLinesApiSlice';
import { FilterPayloads } from './index';
import statusProject from '../../constants/lists/statusProject';
import typeResearch from '../../constants/lists/typeResearch';
import typeProject from '../../constants/lists/typeProject';
import { format } from 'date-fns';

interface Props {
  dispatchFilterData: React.Dispatch<React.SetStateAction<FilterPayloads | undefined>>;
}

const statusProjectList = statusProject;
const typeResearchList = typeResearch;
const typeProjectList = typeProject;

const ProjectFilter = ({ dispatchFilterData }: Props) => {
  const { data: availablesResearchLines, isLoading: isLoadingResearchLines } =
    useGetInvestigationLinesAllAvailablesQuery({});

  const { setFieldValue, errors, touched, values } = useFormik({
    initialValues: {
      investigationLine: '',
      statusProject: '',
      typeProject: '',
      typeResearch: '',
      yearProject: new Date(),
    },
    onSubmit: async (value) => {},
  });

  const sendFilterData = () => {
    dispatchFilterData({
      lineResearchId: values.investigationLine.toString(),
      statusProject: values.statusProject,
      typeProject: values.typeProject,
      typeResearch: values.typeResearch,
      yearProject: !!values.yearProject?.getMonth ? format(values.yearProject, 'yyyy') : '',
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item container spacing={8} alignItems="center">
        {false && (
          <Grid item xs={12} md={4}>
            <InputLabel>Tipo de proyecto</InputLabel>
            <Autocomplete
              options={typeProjectList}
              getOptionLabel={(option) => option?.label!}
              renderInput={(params) => (
                <TextField
                  placeholder="Ingrese un tipo de proyecto"
                  {...params}
                  error={Boolean(errors.typeProject && touched.typeProject)}
                />
              )}
              value={typeProjectList?.find((data) => data.keyword === values.typeProject)}
              onChange={(e, value) => setFieldValue('typeProject', value?.keyword || '')}
              fullWidth
              clearIcon={null}
            />
            {errors.typeProject && touched.typeProject && (
              <FormHelperText error>(errors.typeProject)</FormHelperText>
            )}
          </Grid>
        )}
        <Grid item xs={12} md={4}>
          <InputLabel>Linea de investigación</InputLabel>
          <Autocomplete
            options={availablesResearchLines!}
            getOptionLabel={(option) => option?.name || ''}
            loading={isLoadingResearchLines}
            renderInput={(params) => (
              <TextField
                placeholder="Ingrese un grupo de investigación"
                {...params}
                error={Boolean(errors.investigationLine && touched.investigationLine)}
              />
            )}
            value={availablesResearchLines?.find(
              (data) => data.id.toString() === values.investigationLine
            )}
            onChange={(e, value) => setFieldValue('investigationLine', value?.id.toString() || '')}
            fullWidth
            clearIcon={null}
          />
          {errors.investigationLine && touched.investigationLine && (
            <FormHelperText error>(errors.investigationLine)</FormHelperText>
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          <InputLabel>Estado de proyecto</InputLabel>
          <Autocomplete
            options={statusProjectList!}
            getOptionLabel={(option) => option?.label!}
            renderInput={(params) => (
              <TextField
                placeholder="Ingrese un estado de proyecto"
                {...params}
                error={Boolean(errors.statusProject && touched.statusProject)}
              />
            )}
            value={statusProjectList?.find((data) => data.keyword === values.statusProject)}
            onChange={(e, value) => setFieldValue('statusProject', value?.keyword || '')}
            fullWidth
            clearIcon={null}
          />
          {errors.statusProject && touched.statusProject && (
            <FormHelperText error>(errors.statusProject)</FormHelperText>
          )}
        </Grid>
      </Grid>

      <Grid item container spacing={8} alignItems="center">
        <Grid item xs={12} md={4}>
          <InputLabel>Anio de proyecto</InputLabel>

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              views={['year']}
              value={values.yearProject}
              onChange={(newValue) => {
                console.log(newValue);
                setFieldValue('yearProject', newValue);
              }}
              renderInput={(params) => <TextField fullWidth {...params} helperText={null} />}
            />
          </LocalizationProvider>
          {errors.yearProject && touched.yearProject && (
            <FormHelperText error>(errors.yearProject)</FormHelperText>
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          <InputLabel>Tipo de investigación</InputLabel>
          <Autocomplete
            options={typeResearchList}
            getOptionLabel={(option) => option?.label!}
            renderInput={(params) => (
              <TextField
                placeholder="Ingrese un tipo de investigación"
                {...params}
                error={Boolean(errors.typeResearch && touched.typeResearch)}
              />
            )}
            value={typeResearchList?.find((data) => data.keyword === values.typeResearch)}
            onChange={(e, value) => setFieldValue('typeResearch', value?.keyword || '')}
            fullWidth
            clearIcon={null}
          />
          {errors.typeResearch && touched.typeResearch && (
            <FormHelperText error>(errors.typeResearch)</FormHelperText>
          )}
        </Grid>
        <Grid item container xs={12} md={4} spacing={2} alignItems="center">
          <Grid item mt={2}>
            <Button
              onClick={sendFilterData}
              variant="outlined"
              size="large"
              startIcon={<IconSearch />}
            >
              Buscar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProjectFilter;
