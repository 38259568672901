import { Switch } from 'react-router-dom';
import GuardRoute from '../../../../../router/guards/GuardRoute';
import StudentAsignedHistoryAdsScreen from '../screens/StudentAsignedHistoryAnnouncementsScreen';

import StudentAsignedListScreen from '../screens/StudentAsignedListScreen';
import UserHistoryAnnouncementsScreen from '../screens/UserHistoryAnnouncementsScreen';

const StudentAsignedRouter = () => {
  return (
    <Switch>
      <GuardRoute
        exact
        path="/administrar-estudiantes-asignados"
        component={StudentAsignedListScreen}
      />
      <GuardRoute
        exact
        path="/administrar-estudiantes-asignados/:collId/historial-anuncios/:studentId"
        component={StudentAsignedHistoryAdsScreen}
      />
      <GuardRoute
        exact
        path="/administrar-estudiantes-asignados/historial-anuncios-enviados"
        component={UserHistoryAnnouncementsScreen}
      />
    </Switch>
  );
};

export default StudentAsignedRouter;
