import { useEffect, useState } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import { useAppSelector } from '../../../../hooks/useRedux';
import {
  useFaqSatisfactionMutation,
  useFaqVisitMutation,
  useGetFaqByIdQuery,
} from '../../../maintenance/faqs/slices/faqApiSlice';
import { IconChevronLeft, IconDownload, IconThumbUp, IconVideo } from '@tabler/icons';
import { useTheme, Grid, IconButton, Typography, Button, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { renderSkeletonTable } from '../../../shared/helpers/render';
import useGetUrlDownload from '../../../../hooks/useGetUrlDownload';
import downloadjs from 'downloadjs';

// import draftToHtml from 'draftjs-to-html'
// import { convertToRaw, ContentState, EditorState } from 'draft-js'
// import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
// import htmlToDraft from "html-to-draftjs";

const styles = makeStyles((theme) => ({
  squareButton: {
    borderRadius: '4px',
    mr: 2,
    ml: 2,
  },
}));

export const HelpCenterFaq = () => {
  const classes = styles();

  const theme = useTheme();

  const history = useHistory();

  const { faqId } = useParams<{ faqId: string }>();

  const { currentProfile } = useAppSelector((state) => state.access);

  const { faqDetail } = useAppSelector((state) => state.faqs);

  const [showAlert, setShowAlert] = useState<boolean>(false);

  const [addVisit, { isLoading: isLoadingVisit }] = useFaqVisitMutation();

  const [addSatisfaction, { isLoading: isLoadingSatisfaction, isSuccess: isSuccessSatisfaction }] =
    useFaqSatisfactionMutation();

  const { isLoading: isLoadingFaq } = useGetFaqByIdQuery(
    {
      faqId: Number(faqId),
      profileId: currentProfile?.profileId!,
    },
    { refetchOnMountOrArgChange: true }
  );

  const addVisitFaq = async () => {
    await addVisit({
      faqId: Number(faqId),
      profileId: currentProfile?.profileId!,
    });
  };

  const addFastifactionFaq = async (satisfaction: 'faq_useful' | 'faq_not_useful') => {
    await addSatisfaction({
      faqId: Number(faqId),
      profileId: currentProfile?.profileId!,
      satisfaction: 'faq_useful',
    });
    setShowAlert(true);
  };

  const Tag = ({ name }: { name: string }) => {
    return (
      <Grid item>
        <Button
          variant="contained"
          size="small"
          sx={{
            color: '#9e9e9e',
            backgroundColor: '#F2F3F4',
            borderRadius: '5px',
            boxShadow: 'none',
          }}
          color="primary"
        >
          {name}
        </Button>
      </Grid>
    );
  };

  const Category = ({ name }: { name: string }) => {
    return (
      <Grid item>
        <Button
          variant="contained"
          size="small"
          sx={{
            color: '#9e9e9e',
            backgroundColor: '#F2F3F4',
            borderRadius: '5px',
            boxShadow: 'none',
          }}
          color="primary"
        >
          {name}
        </Button>
      </Grid>
    );
  };

  const RenderTags = () => {
    return (
      <>
        {faqDetail?.faq_tags?.split(',')?.map((e, index) => (
          <Tag key={`category-faq-${index}`} name={e} />
        ))}
      </>
    );
  };

  const RenderCategories = () => {
    return (
      <>
        {faqDetail?.faq_tags?.split(',')?.map((e, index) => (
          <Category key={`tag-faq-${index}`} name={e} />
        ))}
      </>
    );
  };

  // const drafToHmtlContent = (content: string) => {
  //    const contentBlock = htmlToDraft(content);
  //    const contentState = ContentState.createFromBlockArray(
  //       contentBlock.contentBlocks
  //    );
  //    const editorState = EditorState.createWithContent(contentState)
  //    return draftToHtml(convertToRaw(editorState.getCurrentContent()), {
  //       trigger: '#',
  //       separator: ' ',
  //    }, true);
  // }

  useEffect(() => {
    if (currentProfile) {
      addVisitFaq();
    }
    // eslint-disable-next-line
  }, []);

  const { urlDownload } = useGetUrlDownload();

  const handleDownload = (urlFile: string | null) => {
    if (!urlFile) {
      return;
    }

    const url = `${urlDownload}/${urlFile}`;
    try {
      downloadjs(url);
    } catch (error) {
      console.log(Error);
    }
  };

  return (
    <>
      {isLoadingFaq ? (
        renderSkeletonTable(5, 5)
      ) : (
        <Grid container item xs={12}>
          <Grid
            item
            container
            justifyContent="left"
            alignItems="center"
            gap={1}
            sx={{ cursor: 'pointer' }}
          >
            <Grid item>
              <IconButton edge="start" onClick={() => history.push('/centro-asistencia')}>
                <IconChevronLeft size={30} color={theme.palette.text.dark} />
              </IconButton>
            </Grid>
            <Grid item>
              <Typography variant="h2">{faqDetail?.faq_question}</Typography>
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={12}
            justifyContent="space-between"
            alignItems="start"
            spacing={5}
            sx={{ mt: 3 }}
          >
            <Grid container item xs={8}>
              <Grid container item xs={12}>
                <Typography variant="body1" id="content-faq" paragraph={true} align="justify">
                  {faqDetail?.faq_answer!}
                </Typography>
              </Grid>
              <Grid container item direction="row" justifyContent="">
                {faqDetail?.faq_link_video && (
                  <Grid item>
                    <Link target="_blank" to={{ pathname: `${faqDetail?.faq_link_video}` }}>
                      <Button endIcon={<IconVideo />} variant="text" color="primary">
                        video
                      </Button>
                    </Link>
                  </Grid>
                )}
                {faqDetail?.faq_url_file && (
                  <Grid item>
                    {/* <Link
                      target="_blank"
                      to={{ pathname: `${baseUrl}/${faqDetail?.faq_url_file}` }}
                    > */}
                    <Button
                      endIcon={<IconDownload />}
                      variant="text"
                      onClick={() => handleDownload(faqDetail?.faq_url_file)}
                    >
                      Documento
                    </Button>
                    {/* </Link> */}
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid container item xs={4}>
              <Grid container item xs={12}>
                <Typography variant="h4">CATEGORÍAS</Typography>
              </Grid>
              <Grid container item xs={12} spacing={1} sx={{ mt: 1 }}>
                <RenderTags />
              </Grid>
              <Grid container item xs={12} spacing={1} sx={{ mt: 3, mb: 3 }}>
                <Divider sx={{ width: '100%', color: '#9e9e9e' }} />
              </Grid>
              <Grid container item xs={12}>
                <Typography variant="h4">ETIQUETAS</Typography>
              </Grid>
              <Grid container item xs={12} spacing={1} sx={{ mt: 1 }}>
                <RenderCategories />
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            container
            justifyContent="left"
            alignItems="center"
            gap={1}
            spacing={2}
            sx={{ mt: 5 }}
          >
            <Grid item>
              <Typography variant="h5" color="primary">
                ¿Ésta información te fue útil?
              </Typography>
            </Grid>
            <Grid item>
              <Button
                onClick={() => addFastifactionFaq('faq_useful')}
                className={classes.squareButton}
                size="small"
                disabled={isLoadingSatisfaction || isLoadingVisit || isSuccessSatisfaction}
                variant="contained"
              >
                Si
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={() => addFastifactionFaq('faq_not_useful')}
                className={classes.squareButton}
                size="small"
                disabled={isLoadingSatisfaction || isLoadingVisit || isSuccessSatisfaction}
                variant="outlined"
              >
                No
              </Button>
            </Grid>
            {showAlert && isSuccessSatisfaction && (
              <Grid container item direction="row" alignItems="center" spacing={1}>
                <Grid item>
                  <Typography variant="h5" color="primary">
                    Gracias por enviarnos tu respuesta!
                  </Typography>
                </Grid>
                <Grid item>
                  <IconThumbUp size={30} color={theme.palette.primary.main} />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};
