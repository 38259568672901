import * as Yup from 'yup';

const decimalRegex = new RegExp('^[1-9]\\d*(\\.\\d+)?$');

export const ChangeStatusApp = Yup.object().shape({
  comment: Yup.string().required('El comentario es requerido'),
});
export const ChangeStatusAppSubjectRet = Yup.object().shape({
  comment: Yup.string().required('El comentario es requerido'),
  debtPercent: Yup.number()
    .test(
      'is-decimal',
      'Valor no válido',
      (value = 0) => !!(value + '').match(/^\d*\.{1}\d*$/) || !!(value + '').match(/^[0-9]+$/)
    )
    .required('Porcentaje de penalidad es requerido.')
    .min(0, 'Porcentaje de penalidad no puede ser menor a cero')
    .max(100, 'Valor no válido, límite excedido (100%).')
    // .typeError('Ingrese un número válido')
    .nullable(),
});

export const ChangeStatusAppPractice = Yup.object().shape({
  comment: Yup.string().required('El comentario es requerido'),
  hours: Yup.string().required('Las horas son requeridas'),
  subject_curriculum_id: Yup.string().required('La materia es requerida'),
  periodId: Yup.string().required('El período es requerido'),
});

export const ChangeStatusAppPracticeNew = Yup.object().shape({
  comment: Yup.string().required('El comentario es requerido'),
});

export const ChangeStatusINGVIB = Yup.object().shape({
  comment: Yup.string().required('El comentario es requerido'),
  startDate: Yup.string().required('La fecha de inicio es requerida'),
  endDate: Yup.string().required('La fecha de fin es requerida'),
  totalHours: Yup.string()
    .matches(
      decimalRegex,
      'Debe ser numérico mayor a 0 y utilizar un punto (.) como separador decimal'
    )
    .required('El total de horas es requerido'),
});
