import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DialogAlert from '../../../shared/components/dialogs/DialogAlert';
import {
  useDebtCancellationMutation,
  useReverseOfPaymentMutation,
} from '../slices/receiptApiSlice';
import { Reverse } from '../interfaces/receipts.interfaces';

interface Props {
  open: boolean;
  transactionId: number;
  handleClose: () => void;
}
const ReverseDialog = ({ open, transactionId, handleClose }: Props) => {
  const { t } = useTranslation();
  const [reverse, setReverse] = useState<Partial<Reverse> | null>(null);

  const [
    reverseRequest,
    {
      isLoading: isLoadingReverse,
      isSuccess: isSuccessReverse,
      // data: dataReverse,
      isError: isErrorReverse,
      error: errorReverse,
    },
  ] = useReverseOfPaymentMutation();

  const [
    debtsCancellationRequest,
    { isLoading: isLoadingDC, isSuccess: isSuccessDC, isError: isErrorDC, error: errorDC },
  ] = useDebtCancellationMutation();

  //trigger reverse payment
  const handleDelete = async () => {
    await reverseRequest({
      receiptId: transactionId,
      isBalancePositive: true,
    })
      .unwrap()
      .then((response) => {
        console.log('response', response);
        if (response.debts.length || Object.keys(response.debts).length > 0) setReverse(response);
      })
      .catch((error) => console.log(error));
  };
  const handleDelete2 = async () => {
    await reverseRequest({
      receiptId: transactionId,
      isBalancePositive: false,
    })
      .unwrap()
      .then((response) => {
        console.log('response2', response);
        if (response.debts.length || Object.keys(response.debts).length > 0) setReverse(response);
      })
      .catch((error) => console.log(error));
  };

  //trigger cancelation debts
  const handleCancellation = () => {
    debtsCancellationRequest({
      debts_id: reverse?.debts!,
      unlink_period: reverse?.unlink_period!,
    });
  };

  return (
    <>
      {!reverse && (
        <DialogAlert
          open={open} //{openDeleteReceipt}
          onClose={handleClose}
          handleAction={handleDelete}
          handleAction2={handleDelete2}
          isLoading={isLoadingReverse}
          isSuccess={isSuccessReverse}
          isError={isErrorReverse}
          error={errorReverse as string}
          title={t('financial.receipts.reverse_dialog.title')}
          bodyText={
            (
              <>
                <b>{t('financial.receipts.reverse_dialog.prebody')}</b>
                {t('financial.receipts.reverse_dialog.body')}
                <br />
                <b>{t('financial.receipts.reverse_dialog.prebody2')}</b>
                {t('financial.receipts.reverse_dialog.body2')}
              </>
            ) as any
          }
          updateSuccess={t('financial.receipts.reverse_dialog.success')}
          customTitleSendButton={t(
            'financial.receipts.reverse_dialog.delete_debts.btn_positive_balance'
          )}
          customTitleSendButton2={t('financial.receipts.reverse_dialog.delete_debts.btn_cancel')}
          customTitleCancelButton={t('shared.cancel_button.label')}
        />
      )}

      {reverse && (
        <DialogAlert
          open={open} //{openDebtsCancellation}
          isCloseClickOutComponent={false}
          onClose={handleClose}
          handleAction={handleCancellation}
          isLoading={isLoadingDC}
          isSuccess={isSuccessDC}
          isError={isErrorDC}
          error={errorDC as string}
          title={t('financial.receipts.reverse_dialog.delete_debts.title')}
          bodyText={t('financial.receipts.reverse_dialog.delete_debts.body')}
          updateSuccess={t('financial.receipts.reverse_dialog.delete_debts.success')}
          customTitleSendButton={t('shared.accept_button.label')}
          customTitleCancelButton={t('shared.cancel_button.label')}
        />
      )}
    </>
  );
};

export default ReverseDialog;
