import { Switch, Redirect } from 'react-router-dom';

import { useAppSelector } from '../../../../hooks/useRedux';

//import { permissionKey, subPermissionKeys } from '../constants/permissions';
import GuardRoute from '../../../../router/guards/GuardRoute';
import MostViewedScreen from '../screens/borrows/MostViewedScreen';
import DatabaseByEducationLevelScreen from '../screens/databases/DatabaseByEducationLevelScreen';
import SearchByDatabaseScreen from '../screens/databases/SearchByDatabaseScreen';

import DocentesScreen from '../screens/presenciales/DocentesScreen';
import StatisticsScreen from '../screens/presenciales/StatisticsScreen';
import StudentsGradoScreen from '../screens/presenciales/StudentGradoScreen';
import StudentsPosgradoScreen from '../screens/presenciales/StudentsPosgradoScreen';
import CatalogsScreen from '../screens/virtuales/CatalogsScreen';
import EntriesScreen from '../screens/virtuales/EntriesScreen';
import OpenAccessScreen from '../screens/virtuales/OpenAcessScreen';
import TotalVisitsScreen from '../screens/virtuales/TotalVisitsScreen';

const StatisticsRouter = () => {
  const { currentModule } = useAppSelector((state) => state.access);

  const hasPermission = 'borrows' in currentModule?.permissions!;

  if (!hasPermission) return <Redirect to="/dashboard" />;

  return (
    <Switch>
      <GuardRoute
        exact
        path="/estadisticas-total-genero"
        component={StatisticsScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.create}
      />

      <GuardRoute
        exact
        path="/estadisticas-estudiantes-grado"
        component={StudentsGradoScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.create}
      />

      <GuardRoute
        exact
        path="/estadisticas-estudiantes-posgrado"
        component={StudentsPosgradoScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.create}
      />

      <GuardRoute
        exact
        path="/estadisticas-docentes"
        component={DocentesScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.create}
      />
      <GuardRoute
        exact
        path="/estadisticas-catalogos"
        component={CatalogsScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.create}
      />
      <GuardRoute
        exact
        path="/estadisticas-open-access"
        component={OpenAccessScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.create}
      />
      <GuardRoute
        exact
        path="/estadisticas-biblioteca-virtual"
        component={EntriesScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.create}
      />
      <GuardRoute
        exact
        path="/estadisticas-visitas-biblioteca-virtual"
        component={TotalVisitsScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.create}
      />
      <GuardRoute
        exact
        path="/estadisticas-libros-mas-solicitados"
        component={MostViewedScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.create}
      />
      <GuardRoute
        exact
        path="/estadisticas-visitas-bases"
        component={SearchByDatabaseScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.create}
      />
      <GuardRoute
        exact
        path="/estadisticas-visitas-bases-facultad"
        component={DatabaseByEducationLevelScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.create}
      />
    </Switch>
  );
};

export default StatisticsRouter;
