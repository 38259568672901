import {
  IconUsers,
  IconSchool,
  IconHierarchy,
  IconBook,
  IconBookmark,
  IconClipboard,
  IconArrowForward,
  IconArrowBackUp,
  IconFileAnalytics,
  IconChartArea,
  IconChartDonut,
  IconFileExport,
  IconFileCertificate,
  IconTags,
  IconFileImport,
  IconNotebook,
  IconChecklist,
  IconSettings,
  IconEdit,
  IconChartBar,
} from '@tabler/icons';
import { shared } from '../shared';
//import { shared } from '../shared';

// Icon Group
const icons = {
  IconUsers,
  IconSchool,
  IconHierarchy,
  IconBook,
  IconBookmark,
  IconClipboard,
  IconArrowForward,
  IconArrowBackUp,
  IconFileAnalytics,
  IconChartArea,
  IconChartDonut,
  IconFileExport,
  IconFileCertificate,
  IconTags,
  IconFileImport,
  IconNotebook,
  IconChecklist,
  IconSettings,
  IconEdit,
  IconChartBar,
};

export const library = {
  id: 'library',
  title: 'Biblioteca',
  type: 'group',
  url: '/dashboard',
  children: [
    {
      id: 'administrar-registrar-visitas',
      title: 'Registrar Visitas',
      type: 'item',
      url: '/administrar-registrar-visitas',
      permissionKey: 'borrows',
      icon: icons['IconEdit'],
    },
    {
      id: 'administrar-prestamos',
      title: 'Préstamos',
      type: 'item',
      url: '/administrar-prestamos',
      permissionKey: 'borrows',
      icon: icons['IconFileImport'],
    },
    {
      id: 'administrar-devoluciones',
      title: 'Devolución',
      type: 'item',
      url: '/administrar-devoluciones',
      permissionKey: 'borrows',
      icon: icons['IconFileExport'],
    },
    {
      id: 'administrar-reservaciones',
      title: 'Reservas',
      type: 'item',
      url: '/administrar-reservaciones',
      permissionKey: 'borrows',
      icon: icons['IconNotebook'],
    },
    {
      id: 'reportes-graficos',
      title: 'Reportes Gráficos',
      type: 'collapse',
      url: '/reportes-graficos',
      permissionKey: 'borrows',
      icon: icons['IconChartBar'],
      children: [
        {
          id: 'estadisticas-visitas-presenciales',
          title: 'Visitas Presenciales',
          type: 'collapse',
          url: '/estadisticas-visitas-presenciales',
          permissionKey: 'borrows',
          children: [
            {
              id: 'estadisticas-total-genero',
              title: 'Total por Género',
              type: 'item',
              url: '/estadisticas-total-genero',
              permissionKey: 'borrows',
              // icon: icons['IconNotebook'],
            },
            {
              id: 'estadisticas-estudiantes-grado',
              title: 'Estudiantes de Grado',
              type: 'item',
              url: '/estadisticas-estudiantes-grado',
              permissionKey: 'borrows',
              // icon: icons['IconNotebook'],
            },
            {
              id: 'estadisticas-estudiantes-posgrado',
              title: 'Estudiantes de Posgrado',
              type: 'item',
              url: '/estadisticas-estudiantes-posgrado',
              permissionKey: 'borrows',
              // icon: icons['IconNotebook'],
            },
            {
              id: 'estadisticas-docentes',
              title: 'Docentes',
              type: 'item',
              url: '/estadisticas-docentes',
              permissionKey: 'borrows',
              // icon: icons['IconNotebook'],
            },
          ],
        },
        {
          id: 'estadisticas-visitas-virtuales',
          title: 'Visitas Virtuales',
          type: 'collapse',
          url: '/estadisticas-visitas-virtuales',
          permissionKey: 'borrows',
          children: [
            {
              id: 'estadisticas-catalogos',
              title: 'Catálogos',
              type: 'item',
              url: '/estadisticas-catalogos',
              permissionKey: 'borrows',
              // icon: icons['IconNotebook'],
            },
            {
              id: 'estadisticas-open-access',
              title: 'Open Access',
              type: 'item',
              url: '/estadisticas-open-access',
              permissionKey: 'borrows',
              // icon: icons['IconNotebook'],
            },
            {
              id: 'estadisticas-biblioteca-virtual',
              title: 'Biblioteca Virtual',
              type: 'item',
              url: '/estadisticas-biblioteca-virtual',
              permissionKey: 'borrows',
              // icon: icons['IconNotebook'],
            },
            {
              id: 'estadisticas-visitas-biblioteca-virtual',
              title: 'Total visitas',
              type: 'item',
              url: '/estadisticas-visitas-biblioteca-virtual',
              permissionKey: 'borrows',
              // icon: icons['IconNotebook'],
            },
          ],
        },
        {
          id: 'estadisticas-visitas-bases-datos',
          title: 'Visitas Bases de Datos',
          type: 'collapse',
          url: '/estadisticas-visitas-bases-datos',
          permissionKey: 'borrows',
          children: [
            {
              id: 'estadisticas-busquedas-bases',
              title: 'Búsquedas por Bases',
              type: 'item',
              url: '/estadisticas-visitas-bases',
              permissionKey: 'borrows',
              // icon: icons['IconNotebook'],
            },
            {
              id: 'estadisticas-bases-facultad',
              title: 'Base por Facultad',
              type: 'item',
              url: '/estadisticas-visitas-bases-facultad',
              permissionKey: 'borrows',
              // icon: icons['IconNotebook'],
            },
          ],
        },
        {
          id: 'estadisticas-prestamos',
          title: 'Prestamo de libros',
          type: 'collapse',
          url: '/estadisticas-prestamos',
          permissionKey: 'borrows',
          children: [
            {
              id: 'estadisticas-libros-mas-solicitados',
              title: 'Libros más solicitados',
              type: 'item',
              url: '/estadisticas-libros-mas-solicitados',
              permissionKey: 'borrows',
              // icon: icons['IconNotebook'],
            },
          ],
        },
      ],
    },
    {
      id: 'administracion',
      title: 'Administración',
      type: 'collapse',
      icon: icons['IconChecklist'],
      children: [
        {
          id: 'administrar-libros',
          title: 'Libros',
          type: 'item',
          url: '/administrar-libros',
          // icon: icons['Stack'],
          permissionKey: 'borrows',
        },
        {
          id: 'administrar-tesis',
          title: 'Tesis',
          type: 'item',
          url: '/administrar-tesis',
          // icon: icons['Files'],
          permissionKey: 'borrows',
        },
        {
          id: 'administrar-recursos',
          title: 'Recursos',
          type: 'item',
          url: '/administrar-recursos',
          // icon: icons['Files'],
          permissionKey: 'borrows',
        },
      ],
    },
    {
      id: 'configuracion',
      title: 'Configuración',
      type: 'collapse',
      icon: icons['IconSettings'],
      children: [
        {
          id: 'administrar-clasificaciones',
          title: 'Clasificaciones',
          type: 'item',
          url: '/administrar-clasificaciones',
          // icon: icons['Stack'],
          permissionKey: 'borrows',
        },
        {
          id: 'administrar-etiquetas',
          title: 'Etiquetas',
          type: 'item',
          url: '/administrar-etiquetas',
          // icon: icons['Files'],
          permissionKey: 'borrows',
        },
        {
          id: 'administrar-autores',
          title: 'Autores',
          type: 'item',
          url: '/administrar-autores',
          // icon: icons['Files'],
          permissionKey: 'borrows',
        },
        {
          id: 'administrar-estantes',
          title: 'Estantes',
          type: 'item',
          url: '/administrar-estantes',
          // icon: icons['Files'],
          permissionKey: 'borrows',
        },
      ],
    },

    ...shared,
  ],
};
