import { useState, useMemo } from 'react';
import { Box, Grid, Tab, Tabs } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { useStyles } from '../../helpers/styles';
import MatterDetailStudent from './MatterDetailPartner';
import {
  RecentActorsTwoTone,
  EmojiObjectsTwoTone,
  Comment,
  Task,
  ViewAgenda,
  People,
  TextsmsTwoTone,
} from '@material-ui/icons';
import MatterDetailNote from './MatterDetailNote';
import MatterDetailMaterial from './MatterDetailMaterial';
import { useParams } from 'react-router-dom';
import MatterDetailForum from './MatterDetailForum';

interface ArrayItems {
  id: number;
  name: string;
  path: string;
}

const MatterDetailIndex = function () {
  const { menuOption } = useParams<{ menuOption: string }>();

  const arrayItems: ArrayItems[] = useMemo(
    () => [
      { id: 0, name: 'Notas', path: 'notas' },
      { id: 1, name: 'Materiales', path: 'materiales' },
      { id: 2, name: 'Tareas', path: 'tareas' },
      { id: 3, name: 'Agenda', path: 'agenda' },
      { id: 4, name: 'Sala de Debate', path: 'sala-de-debate' },
      { id: 5, name: 'Test', path: 'test' },
      { id: 6, name: 'Compañeros', path: 'companeros' },
    ],
    []
  );

  const [value, setValue] = useState(arrayItems.find((e) => e.path === menuOption)?.id || 0);

  const classes = useStyles();

  const TabPanel = (props: { children: React.ReactElement; value: number; index: number }) => {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={0}>{children}</Box>}
      </div>
    );
  };

  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  const handleChange = (event: React.SyntheticEvent | null, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Grid>
      <Grid item xs={12} sx={{ mt: 2, width: 1 }}>
        <Box width="100%" sx={{ maxWidth: '100%', width: 1 }} style={{ marginTop: 30 }}>
          <Tabs
            value={value}
            indicatorColor="primary"
            className={classes.containerTab}
            aria-label="Consejería Académica"
            variant="fullWidth"
            onChange={handleChange}
          >
            <Tab
              icon={<RecentActorsTwoTone sx={{ fontSize: '1.3rem' }} />}
              component={RouterLink}
              to={arrayItems[0].path}
              label={arrayItems[0].name}
              {...a11yProps(arrayItems[0].id)}
            />
            <Tab
              icon={<EmojiObjectsTwoTone sx={{ fontSize: '1.3rem' }} />}
              component={RouterLink}
              to={arrayItems[1].path}
              label={arrayItems[1].name}
              {...a11yProps(arrayItems[1].id)}
            />
            <Tab
              icon={<Task sx={{ fontSize: '1.3rem' }} />}
              component={RouterLink}
              to={arrayItems[2].path}
              label={arrayItems[2].name}
              {...a11yProps(arrayItems[2].id)}
            />
            <Tab
              icon={<ViewAgenda sx={{ fontSize: '1.3rem' }} />}
              component={RouterLink}
              to={arrayItems[3].path}
              label={arrayItems[3].name}
              {...a11yProps(arrayItems[3].id)}
            />
            <Tab
              icon={<Comment sx={{ fontSize: '1.3rem' }} />}
              component={RouterLink}
              to={arrayItems[4].path}
              label={arrayItems[4].name}
              {...a11yProps(arrayItems[4].id)}
            />
            <Tab
              icon={<People sx={{ fontSize: '1.3rem' }} />}
              component={RouterLink}
              to={arrayItems[5].path}
              label={arrayItems[5].name}
              {...a11yProps(arrayItems[5].id)}
            />
            <Tab
              icon={<TextsmsTwoTone sx={{ fontSize: '1.3rem' }} />}
              component={RouterLink}
              to={arrayItems[6].path}
              label={arrayItems[6].name}
              {...a11yProps(arrayItems[6].id)}
            />
          </Tabs>
          <TabPanel value={value} index={0}>
            <MatterDetailNote />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <MatterDetailMaterial />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <h1>Contenido tareas</h1>
          </TabPanel>
          <TabPanel value={value} index={3}>
            <h1>Contenido agenda</h1>
          </TabPanel>
          <TabPanel value={value} index={4}>
            <MatterDetailForum />
          </TabPanel>
          <TabPanel value={value} index={5}>
            <h1>Contenido test</h1>
          </TabPanel>
          <TabPanel value={value} index={6}>
            <MatterDetailStudent />
          </TabPanel>
        </Box>
      </Grid>
    </Grid>
  );
};

export default MatterDetailIndex;
