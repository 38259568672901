import { Button, CircularProgress, Grid } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../../hooks/useRedux';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import { useUploadFilesMutation } from '../../../shared/slices/uploadFilesApi';
import DragAreaFileMultiple from '../../../students/virtual-classroom/components/DragAreaFileMultiple';
import {
  useUploadFilesProjectsMutation,
  useUploadFilesProjectsByStudentMutation,
} from '../slices/followUpOfResearchProjectApiSlice';

const AddDocumentByBinnancle = () => {
  const { t } = useTranslation();
  const [files, setFiles] = useState<File[] | null>(null);
  const [showAlerts, setShowAlerts] = useState(false);
  const { projectId } = useParams<{ projectId: string }>();

  const { binnacleSelected } = useAppSelector((state) => state.followUpOfResearch);

  const [filesView, setFilesView] = useState<
    {
      [key: string]: any | null;
      doc_name_file: string;
    }[]
  >([]);

  const [uploadFile, { isLoading: isLoadingFile, isError: isErrorFile, error: errorFile }] =
    useUploadFilesMutation();

  const [
    uploadFileProject,
    { isLoading: isLoadingFileProject, isError: isErrorFileProject, error: errorFileProject },
  ] = useUploadFilesProjectsMutation();

  const [
    uploadStudentFiles,
    {
      isLoading: isLoadingStudentFiles,
      isError: isErrorStudentFiles,
      error: errorStudentFiles,
      isSuccess: isSuccessStudentFiles,
    },
  ] = useUploadFilesProjectsByStudentMutation();

  const handleDeleteDoc = (name: string) => {
    const docs = filesView.filter((doc: any) => doc.doc_name_file !== name);
    setFilesView(docs);
  };

  useEffect(() => {
    if (files && files.length > 0) {
      const filesToUpload = files.map(async (file) => {
        try {
          const url = await uploadFile({
            files: file,
          }).unwrap();
          return {
            id: null,
            doc_url: url,
            doc_name_file: file.name,
          };
        } catch (err) {
          console.log(err);
        }
      });
      Promise.all(filesToUpload).then((res) => {
        setFilesView(res as never[]);
      });
    }
    //eslint-disable-next-line
  }, [files]);

  const handleClickUpload = () => {
    const arrayUrl = filesView.map(async (file) => {
      try {
        const fileData = await uploadFileProject({
          projectId: projectId,
          payload: {
            doc_url: file.doc_url,
            doc_name_file: file.doc_name_file,
          },
        }).unwrap();
        return fileData.id;
      } catch (error) {
        setShowAlerts(true);
      }
    });
    Promise.all(arrayUrl)
      .then(async (res) => {
        await uploadStudentFiles({
          studentProjectResearchDetails: binnacleSelected?.id!,
          payload: {
            list_project_research_document: res.filter((item) => item !== undefined) as never[],
          },
        });
      })
      .catch((err) => {
        setShowAlerts(true);
      });
    setFiles([]);
    setFilesView([]);
    setShowAlerts(true);
  };

  return (
    <Grid>
      {isErrorFile && isErrorFileProject && isErrorStudentFiles && showAlerts && (
        <ErrorAlert
          message={(errorFile || errorFileProject || errorStudentFiles) as string}
          handleDismiss={() => setShowAlerts(false)}
        />
      )}
      {isSuccessStudentFiles && showAlerts && (
        <SuccessAlert message={t('Subido con exito')} handleDismiss={() => setShowAlerts(false)} />
      )}
      <DragAreaFileMultiple
        handleChange={(e) => {
          setFiles(Array.from(e.target.files!));
        }}
        label="Documentos relacionados con la bitácora"
        description="Los documentos deben ser de no más de 2MB"
        filesView={filesView}
        handleDeleteFile={handleDeleteDoc}
      />
      <Button
        sx={{
          mt: 3,
        }}
        fullWidth
        variant="contained"
        size="large"
        color="primary"
        disabled={
          isLoadingFile || isLoadingFileProject || isLoadingStudentFiles || filesView.length === 0
        }
        endIcon={
          (isLoadingFile || isLoadingFileProject || isLoadingStudentFiles) && (
            <CircularProgress size={20} />
          )
        }
        onClick={handleClickUpload}
      >
        {!isLoadingFile ? t('Subir') : t('Subiendo')}
      </Button>
    </Grid>
  );
};

export default AddDocumentByBinnancle;
