export const subPermissionKeys = {
  groupKey: 'internalHomologation',

  read: 'internal-homologation-listar-materias-malla-de-homologacion-interna',
  edit: 'internal-homologation-actualizar-materias-malla-de-homologacion-interna',
  delete: 'internal-homologation-eliminar-materias-malla-de-homologacion-interna',
  getOne: 'internal-homologation-obtener-materias-malla-de-homologacion-interna',
  assign: 'internal-homologation-asignar-materias-malla-de-homologacion=interna',
  //
}; // satisfies PermissionsKeys;

