import {
  Divider,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
  TextFieldProps,
  Typography,
} from '@material-ui/core';
import { ErrorMessage, Field, FieldArray, FormikProvider, useFormikContext } from 'formik';
// import {
//   useCallback,
//   // useEffect
// } from 'react';
import { PeriodForm, Stage } from '../../../interfaces/period-form.interfaces';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import esLocale from 'date-fns/locale/es';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import DateRangePicker, { DateRange } from '@material-ui/lab/DateRangePicker';
import { GENERACION_CUOTAS } from '../../../constants/stages';
import { StageGenerationQuote } from '../../../constants/validation-schemas';
import { useTranslation } from 'react-i18next';
import InfoAlert from '../../../../../shared/components/alerts/InfoAlert';

const newDate: Stage = {
  start_date: null,
  end_date: null,
  education_level_id: null,
  course_id: null,
  parallel_id: null,
  status_id: 1,
  stg_acronym: 'RC',
};

const GenerationQuote = () => {
  const { t } = useTranslation();

  const { setFieldValue, values, errors, touched, getFieldProps } = useFormikContext<PeriodForm>();

  const formik = useFormikContext<PeriodForm>();
  formik.validationSchema = StageGenerationQuote;

  // const arrayPeriodStagesDates = useCallback((quotas: number) => {
  //   const initialDate: Stage = {
  //     start_date: null,
  //     end_date: null,
  //     education_level_id: null,
  //     course_id: null,
  //     parallel_id: null,
  //     status_id: 1,
  //     stg_acronym: 'RC',
  //   };
  //   let initialDates: Stage[] = [];
  //   for (var i = 0; i < quotas; i++) {
  //     initialDates.push(initialDate);
  //   }

  //   return initialDates;
  // }, []);

  // useEffect(() => {
  //   if (values.numFees > 0) {
  //     setFieldValue('generation_quote', arrayPeriodStagesDates(values.numFees));
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const onChangeDatesArray = (
    newDatesValues: DateRange<Date>,
    index: number,
    courseId?: number | null
  ) => {
    const [start, end] = newDatesValues;
    // update formik values
    const exists = values.generation_quote?.find((e, current) => current === index);
    if (!exists) {
      setFieldValue(`generation_quote[${index}].course_id`, null);
      setFieldValue(`generation_quote[${index}].education_level_id`, null);
      setFieldValue(`generation_quote[${index}].parallel_id`, null);
      setFieldValue(`generation_quote[${index}].status_id`, 1);
      setFieldValue(`generation_quote[${index}].stg_acronym`, GENERACION_CUOTAS);
    }

    if (start !== null) {
      setFieldValue(`generation_quote[${index}].start_date`, start);
    }

    if (end !== null) {
      setFieldValue(`generation_quote[${index}].end_date`, end);
    }
  };

  const validateNumFees = (value: number) => {
    const tp_num_fees = values.tp_num_fees;
    const tp_pay_enrollment = values.tp_pay_enrollment;
    const feesEnrollmentUnique = values.feesEnrollmentUnique;
    const numFees = Number(value || 0);

    if (
      tp_pay_enrollment === '1' &&
      feesEnrollmentUnique === '1' &&
      numFees < Number(tp_num_fees || 0) + 1
    ) {
      return `Número de cuotas debe ser mínimo ${Number(tp_num_fees || 0) + 1}`;
    }

    if (
      ((tp_pay_enrollment === '1' && feesEnrollmentUnique === '0') || tp_pay_enrollment === '0') &&
      numFees < Number(tp_num_fees || 0)
    ) {
      return `Número de cuotas debe ser mínimo ${Number(tp_num_fees || 0)}`;
    }

    return undefined;
  };

  // setFieldValue('generation_quote', arrayPeriodStagesDates(values.numFees));

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="flex-start">
      <Grid item xs={12} md={12} container spacing={2}>
        <Grid item xs={12} marginTop={2}>
          <InfoAlert
            message={t('academic.periods_list.add.generation_quote.start_date.required')}
          />
          <Divider
            light
            style={{ width: '100%', marginTop: 20, marginBottom: 20, borderRadius: 2 }}
          />
          <Typography variant="h3" component="div">
            {t('academic.periods_list.add.stage_3.tab_1.title')}
          </Typography>
          <Grid item xs={12} sx={{ mt: 5, p: 2 }}>
            <Grid container spacing={10} alignItems="center">
              <Grid item xs={12} md={6} lg={6}>
                <InputLabel>{t('academic.periods_list.add.stage_3.tab_1.num_fees')}</InputLabel>
                <TextField
                  fullWidth
                  type="number"
                  error={Boolean(errors.numFees && touched.numFees)}
                  // InputProps={{
                  //   inputProps: {
                  //     min: 1 + Number(formik.values.feesEnrollmentUnique),
                  //   },
                  // }}
                  {...getFieldProps('numFees')}
                  placeholder={t('academic.periods_list.add.stage_3.tab_1.num_fees.placeholder')}
                  value={values.numFees}
                  onChange={(e) => {
                    let actualNumFees = Number(values.numFees);
                    let newNumFees = Number(e.target.value) > 0 ? Number(e.target.value) : 0;

                    setFieldValue('numFees', newNumFees);

                    if (newNumFees > actualNumFees) {
                      setFieldValue('generation_quote', [...values.generation_quote, newDate]);
                    } else {
                      if (values.generation_quote?.length > 0) {
                        setFieldValue('generation_quote', values.generation_quote.slice(0, -1));
                      } else {
                        setFieldValue('generation_quote', []);
                      }
                    }

                    formik.setFieldError('numFees', validateNumFees(newNumFees));
                  }}
                  onBlur={(e) => {}}
                />
                {errors.numFees && touched.numFees && (
                  <FormHelperText error>{errors.numFees}</FormHelperText>
                )}
              </Grid>
              {/* <Grid item xs={12} md={6} lg={6}>
                <InputLabel>Ref. número de cuotas</InputLabel>
                <TextField
                  fullWidth
                  type="number"
                  error={Boolean(errors.numFees && touched.numFees)}
                  placeholder="Cantidad de cuotas"
                  value={values.numFees}
                  onChange={(e) => {
                    setFieldValue('numFees', Number(e.target.value));
                  }}
                />
                {errors.numFees && touched.numFees && (
                  <FormHelperText error>{errors.numFees}</FormHelperText>
                )}
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sx={{ mt: 2 }}>
          {values.generation_quote?.length > 0 && (
            <Typography variant="h3" component="div" sx={{ mb: 3 }}>
              {t('academic.periods_list.add.stage_3.tab_1.select_dates')}
            </Typography>
          )}
          {values?.generation_quote?.map((e, index) => (
            <Grid item key={`generation_quote-${index}-dates`}>
              <FormikProvider value={formik}>
                <FieldArray
                  name="generation_quote"
                  render={(arrayHelpers) => (
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                      <Field
                        name={`generation_quote[${index}]`}
                        component={DateRangePicker}
                        startText={null}
                        endText={null}
                        value={[
                          values?.generation_quote[index]?.start_date || null,
                          values?.generation_quote[index]?.end_date || null,
                        ]}
                        onChange={(e: DateRange<Date>) => {
                          onChangeDatesArray(e, index, null);
                        }}
                        renderInput={(startProps: TextFieldProps, endProps: TextFieldProps) => (
                          <Grid container direction="row">
                            <Grid
                              container
                              spacing={2}
                              sx={{ mt: 2, mb: 1 }}
                              justifyContent="left"
                              alignItems="center"
                            >
                              <Grid item xs={12} md={6} lg={6}>
                                <InputLabel>
                                  {t(
                                    'academic.periods_list.add.stage_3.tab_1.select_dates.placeholder'
                                  )}{' '}
                                  {values.additional_fees.length > 0 &&
                                  index < values.additional_fees.length ? (
                                    <strong>{values.additional_fees[index].pr_description}</strong>
                                  ) : (
                                    <>
                                      {t(
                                        'academic.periods_list.add.stage_3.tab_1.select_dates.quote'
                                      )}{' '}
                                      #
                                      <strong>
                                        {values.additional_fees.length > 0
                                          ? index - (values.additional_fees.length - 1)
                                          : index + 1}
                                      </strong>
                                    </>
                                  )}
                                </InputLabel>
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              spacing={10}
                              sx={{ mb: 2 }}
                              justifyContent="left"
                              alignItems="center"
                            >
                              <Grid item xs={12} md={6} lg={6}>
                                <InputLabel>
                                  {t('academic.periods_list.add.stage_3.tab_1.select_dates.start')}
                                </InputLabel>
                                <TextField fullWidth {...startProps} helperText="" />
                                <FormHelperText>
                                  {t(
                                    'academic.periods_list.add.stage_3.tab_1.select_dates.start.helper'
                                  )}
                                </FormHelperText>
                                <ErrorMessage name={`generation_quote[${index}].start_date`} />
                              </Grid>

                              <Grid item xs={12} md={6} lg={6}>
                                <InputLabel>
                                  {t('academic.periods_list.add.stage_3.tab_1.select_dates.end')}:
                                </InputLabel>

                                <TextField fullWidth {...endProps} helperText="" />
                                <FormHelperText>
                                  {t(
                                    'academic.periods_list.add.stage_3.tab_1.select_dates.end.helper'
                                  )}
                                </FormHelperText>
                                <ErrorMessage name={`generation_quote[${index}].end_date`} />
                              </Grid>
                            </Grid>
                          </Grid>
                        )}
                      />
                    </LocalizationProvider>
                  )}
                />
              </FormikProvider>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default GenerationQuote;
