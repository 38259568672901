import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/core/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Stack from '@material-ui/core/Stack';
import { makeStyles } from '@material-ui/core/styles';
import { Form, Formik } from 'formik';

import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import { Campus } from '../../../../maintenance/campus/interfaces/campus.interfaces';
import { Days } from '../../constants/daysAvalaibles';
import useRegisterSubjectByCurriculumOptions from '../../hooks/useRegisterSubjectByCurriculumOptions';
import { resetCurrentHourhand } from '../../slices/subjectsRegisterSlice';
import DialogLoading from '../DialogLoading';
import { ReactComponent as StudentExamsBg } from '../../../../../assets/images/illustrations/exams-students.svg';
import { ReactComponent as WarnBg } from '../../../../../assets/images/illustrations/warn.svg';
import { ReactComponent as SearchBg } from '../../../../../assets/images/illustrations/search.svg';
import { useGetCoursesByHourhandQuery } from '../../slices/subjectsRegisterApiSlice';
import InputLabel from '../../../../shared/components/forms/InputLabel';
import CourseByHourhandCard from './CourseByHourhandCard';
import useStudentInfo from '../../hooks/useStudentInfo';

const useStyles = makeStyles({
  paper: {
    width: '90%',
    maxWidth: 980,
    height: '80%',
    minHeight: '80%',
    maxHeight: '80%',
  },
  formContainer: {
    width: '100%',
  },
});

interface Props {
  open: boolean;
  handleCloseDialog: () => void;
}

interface FormValues {
  campus: Campus | null;
}

const initialValues: FormValues = {
  campus: null,
};

const RegisterByHourhandDialog = ({ open, handleCloseDialog }: Props) => {
  const { currentHourhand } = useAppSelector((state) => state.subjectsRegister);
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const studentInfo = useStudentInfo()

  const [campusId, setCampusId] = useState<null | number>(null);

  const onClose = () => {
    handleCloseDialog();
    dispatch(resetCurrentHourhand());
  };

  const currentDay = Days.find((d) => d.id === currentHourhand?.day);

  const { campus, isLoadingCampus, isFetchingOptions } = useRegisterSubjectByCurriculumOptions();

  const {
    data: availableCoursesHourhand = [],
    isFetching,
    isError,
    isSuccess,
    error,
  } = useGetCoursesByHourhandQuery(
    {
      campus_id: campusId!,
      start_hour: currentHourhand?.start_hour || '',
      end_hour: currentHourhand?.end_hour || '',
      day: currentHourhand?.day || '',
      curriculum_id: studentInfo?.curriculumId!,
      studentRecordId: studentInfo?.studentRecordId!,
    },
    { skip: !campusId }
  );

  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.paper }}>
      <DialogTitle>
        <Grid container direction="row">
          <Typography variant="h2" sx={{ mr: 1 }}>
            Registrar
          </Typography>
          <Typography color="primary" variant="h2">
            {`${currentDay?.name} ${currentHourhand?.start_hour} - ${currentHourhand?.end_hour}`}
          </Typography>
        </Grid>
      </DialogTitle>
      <DialogContent>
        {isFetchingOptions ? (
          <DialogLoading />
        ) : (
          <Grid container>
            <Grid item xs={12} container>
              <Formik
                initialValues={initialValues}
                onSubmit={(values) => {
                  setCampusId(values.campus!.id);
                }}
              >
                {({ values, setFieldValue }) => (
                  <Form className={classes.formContainer}>
                    <Grid item xs={12} container spacing={2}>
                      {/* Campus obligatorio */}
                      <Grid item xs={6}>
                        <InputLabel htmlFor="campus">Seleccionar campus</InputLabel>
                        <Autocomplete
                          id="campus"
                          loading={isLoadingCampus}
                          options={campus}
                          getOptionLabel={(option) => option.cam_name}
                          noOptionsText="No hay campus disponibles"
                          value={values.campus}
                          onChange={(_, value) => setFieldValue('campus', value)}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </Grid>
                      <Grid item xs={12} container justifyContent="flex-end">
                        <Button type="submit" variant="contained" color="primary">
                          Buscar
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Grid>
            <Grid item xs={12} container sx={{ mt: 2 }}>
              {!isError && !isSuccess && !isFetching && (
                <Grid item xs={12} container justifyContent="center">
                  <Stack alignItems="center">
                    <Typography variant="h4" sx={{ color: 'grey.700' }} textAlign="center">
                      Para visualizar los cursos debes seleccionar el campus
                    </Typography>
                    <StudentExamsBg width={300} height={300} />
                  </Stack>
                </Grid>
              )}
              {isFetching ? (
                <Grid item xs={12} container justifyContent="center">
                  <Stack alignItems="center">
                    <Typography variant="h4" sx={{ color: 'grey.700' }}>
                      Buscando opciones...
                    </Typography>
                    <SearchBg width={300} height={300} />
                  </Stack>
                </Grid>
              ) : isError ? (
                <Grid item xs={12} container justifyContent="center">
                  <Stack alignItems="center">
                    <Typography variant="h4" sx={{ color: 'grey.700' }} textAlign="center">
                      {error}
                    </Typography>
                    <WarnBg width={300} height={300} />
                  </Stack>
                </Grid>
              ) : (
                <Grid container spacing={2}>
                  {availableCoursesHourhand?.map((ac) => (
                    <Grid item xs={6} key={ac.id}>
                      <CourseByHourhandCard course={ac} />
                    </Grid>
                  ))}
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default RegisterByHourhandDialog;
