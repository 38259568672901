import { Route, Switch } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallbackComponentScreen from '../../shared/components/errors-boundary/ErrorFallbackComponentScreen';

import ClassificationsRouter from '../classifications/router';
import AuthorsRouter from '../authors/router/index';
import ShelftsRouter from '../shelfs/router';
import BooksRouter from '../books/router';
import ThesisRouter from '../thesis/router';
import LendingsRouter from '../lendings/router';
import RefundsRouter from '../refunds/router';
import ReportsRouter from '../reports/router';
import StatisticsRouter from '../statistics/router';
import DashboardRouter from '../dashboard/router';
import TagsRouter from '../tags/router';
import ReservationsRouter from '../reservations/router';
import ReservedBooksRouter from '../user-reserved-books/router';
import BorrowedBooksRouter from '../user-borrowed-books/router';
import OthersRouter from '../others/router';
import VisitsRouter from '../visits/router';
import VisitsLibraryRouter from '../../shared/visits-library/router';

const LibraryRouter = () => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallbackComponentScreen}>
      <Switch>
        <Route path="/biblioteca" component={DashboardRouter} />
        <Route path="/biblioteca-reservas" component={ReservedBooksRouter} />
        <Route path="/biblioteca-prestamos" component={BorrowedBooksRouter} />
        <Route path="/biblioteca-visitas" component={VisitsLibraryRouter} />
        <Route path="/administrar-clasificaciones" component={ClassificationsRouter} />
        <Route path="/administrar-autores" component={AuthorsRouter} />
        <Route path="/administrar-estantes" component={ShelftsRouter} />
        <Route path="/administrar-libros" component={BooksRouter} />
        <Route path="/administrar-tesis" component={ThesisRouter} />
        <Route path="/administrar-solicitados" component={LendingsRouter} />
        <Route path="/administrar-prestamos" component={LendingsRouter} />
        <Route path="/administrar-reservas" component={LendingsRouter} />
        <Route path="/administrar-devoluciones" component={RefundsRouter} />
        <Route path="/administrar-prestamos-atrasados" component={RefundsRouter} />
        <Route path="/administrar-reporteria" component={ReportsRouter} />

        <Route path="/administrar-etiquetas" component={TagsRouter} />
        <Route path="/administrar-reservaciones" component={ReservationsRouter} />
        <Route path="/administrar-recursos" component={OthersRouter} />
        <Route path="/administrar-registrar-visitas" component={VisitsRouter} />

        {/*Estadisticas */}
        <Route path="/estadisticas-total-genero" component={StatisticsRouter} />
        <Route path="/estadisticas-estudiantes-grado" component={StatisticsRouter} />
        <Route path="/estadisticas-estudiantes-posgrado" component={StatisticsRouter} />
        <Route path="/estadisticas-docentes" component={StatisticsRouter} />
        <Route path="/estadisticas-catalogos" component={StatisticsRouter} />
        <Route path="/estadisticas-open-access" component={StatisticsRouter} />
        <Route path="/estadisticas-biblioteca-virtual" component={StatisticsRouter} />
        <Route path="/estadisticas-visitas-biblioteca-virtual" component={StatisticsRouter} />
        <Route path="/estadisticas-visitas-bases" component={StatisticsRouter} />
        <Route path="/estadisticas-visitas-bases-facultad" component={StatisticsRouter} />
        <Route path="/estadisticas-libros-mas-solicitados" component={StatisticsRouter} />
      </Switch>
    </ErrorBoundary>
  );
};

export default LibraryRouter;
