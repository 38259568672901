import { Button, CircularProgress } from '@material-ui/core';
import { useAppSelector } from '../../../hooks/useRedux';
import { useAuthMoodleMutation } from '../slices/moodle';

const MoodleButton = () => {
  const { currentProfile, currentRol } = useAppSelector((state) => state.access);
  const { userInfo } = useAppSelector((state) => state.auth);
  const { course: courseTeacher } = useAppSelector((state) => state.virtualClassroomTeacherShared);
  const { course: courseStudent } = useAppSelector((state) => state.virtualClassroomStudentShared);

  const [authMoodle, { isLoading }] = useAuthMoodleMutation();

  const handleAuthMoodle = () => {
    let courseId = 0;

    if (currentRol?.keyword === 'docente') {
      courseId = courseTeacher?.courseId!;
    }

    if (currentRol?.keyword === 'estudiante') {
      courseId = courseStudent?.courseId!;
    }

    authMoodle({
      profileId: currentProfile?.profileId!,
      userId: Number(userInfo?.id!),
      courseId: courseId,
    })
      .unwrap()
      .then((res) => {
        window.open(res, '_blank');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={handleAuthMoodle}
      disabled={isLoading}
      endIcon={isLoading ? <CircularProgress size={12} /> : null}
    >
      Ir a Moodle
    </Button>
  );
};

export default MoodleButton;
