import { Switch, Redirect } from 'react-router-dom';
import { useAppSelector } from '../../../../hooks/useRedux';
import GuardRoute from '../../../../router/guards/GuardRoute';
import { permissionKey } from '../constants/permissions';
import ApplicationsListScreen from '../screens/ApplicationsListScreen';
import PreviewApplicationScreen from '../../../maintenance/general-applications/screens/PreviewApplicationScreen';

const MaintenanceApplicationsRRHHRouter = () => {
  const { currentModule } = useAppSelector((state) => state.access);
  const hasPermission = permissionKey in currentModule?.permissions!;

  if (!hasPermission) <Redirect to="/dashboard" />;

  return (
    <Switch>
      <GuardRoute
        exact
        path="/administrar-solicitudes-rrhh"
        component={ApplicationsListScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.read}
      />
      <GuardRoute
        exact
        path="/administrar-solicitudes-rrhh/:app/revision"
        component={PreviewApplicationScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.read}
      />
    </Switch>
  );
};

export default MaintenanceApplicationsRRHHRouter;
