// import { useState } from 'react';

import { IconCheckbox } from '@tabler/icons';
import { useTranslation } from 'react-i18next';

import { useParameterCompanyDisallowActionMutation } from '../slices/parameterCompanyApiSlice';
import CustomIconButton from '../../../shared/components/tables/buttons/CustomIconButton';
// import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
// import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';

interface Props {
  parameterId: number;
  disabled?: boolean;
}

const DisallowActionCompaniesBtn = ({ parameterId, disabled }: Props) => {
  const { t } = useTranslation();

  //   const [showAlerts, setShowAlerts] = useState(false);

  const [disallowAction, { isLoading /* isSuccess, isError, error */ }] =
    useParameterCompanyDisallowActionMutation();

  const disallowActionHandler = async () => {
    await disallowAction({ parameterId, action_disallow: '1' });

    // setShowAlerts(true);
  };

  return (
    <>
      {/* se desmonta el componente de la tabla, no funciona el alert aqui pero no parece ser muy
      necesario */}
      {/* {isSuccess && showAlerts && (
        <SuccessAlert
          message={t('Regla permanente establecida correctamente')}
          handleDismiss={() => setShowAlerts(false)}
        />
      )}
      {isError && showAlerts && (
        <ErrorAlert message={error as any} handleDismiss={() => setShowAlerts(false)} />
      )} */}
      <CustomIconButton
        Icon={IconCheckbox}
        handleClick={disallowActionHandler}
        isLoading={isLoading}
        title={t('Bloquear parametro')}
        {...(disabled && { disabled: true })}
      />
    </>
  );
};

export default DisallowActionCompaniesBtn;
