import { isBefore, parseISO } from 'date-fns';

import { isEmptyObject } from '../../../shared/helpers';
import { Hourhand } from '../interfaces/course.interface';

const propName: unique symbol = Symbol();

interface HourhandsRegistered {
  [propName]?: HourhandsConflict | undefined;
}
interface HourhandsConflict {
  [propName]?: number;
}

/**
 * Funcion que devolvera un objeto de objetos donde cada propiedad sera el id del horario que tendra
 * un objeto con los id de los horarios con los que tenga conflicto.
 *
 * Con el parametro opcional ```discriminate``` se podra condicionar para que en el primer nivel solo retornen los
 * horarios que no tengan conflicto con los demas del primer nivel.
 * @param hourhands array de horarios
 * @param discriminate booleano para discriminar del primer nivel los horarios que ya esten en conflico con otros del primer nivel
 * @returns HourhandsRegistered
 */
export const conflictHourhands = (hourhands: Hourhand[], discriminate?: boolean) => {
  let hourhandsToReturn: HourhandsRegistered = {};
  //ciclo por el array A para comparar cada horario con cada horario de si mismo
  hourhands.forEach((hourhandA) => {
    //parametro para no guardar en el nivel principal los horarios que esten ya en conflicto con otros del primer nivel
    if (discriminate && !isEmptyObject(hourhandsToReturn)) {
      let found = false;
      Object.keys(hourhandsToReturn).forEach((hourh) => {
        //si el horario de primer nivel que se recorrera actualmente ya esta en otro de primer nivel se saltara el ciclo
        if (hourhandA.id in hourhandsToReturn[hourh as unknown as keyof HourhandsRegistered]!) {
          found = true;
          return;
        }
      });
      if (found) return;
    }
    //ciclo por el array B para comparar cada horario con cada horario de si mismo
    hourhands.forEach((hourhandB) => {
      //   ciclo por cada dia de la semana
      if (hourhandA.id !== hourhandB.id) {
        ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].forEach(
          (key) => {
            //condicional de si el horario A tiene horario en ese dia
            if (Boolean(Number(hourhandA[`hour_${key}` as keyof Hourhand]))) {
              //condicional de si el horario B tiene horario en ese dia
              if (Boolean(Number(hourhandB[`hour_${key}` as keyof Hourhand]))) {
                //Condicional de las horas de cada horario que no tengan conflicto
                if (
                  //string to number?
                  //   HOURHAND A BEETWEEN HOURHAND B
                  // start hourhand A beetweem hourhand b
                  (hourhandA[`hour_start_time_${key}` as keyof Hourhand]! >
                    hourhandB[`hour_start_time_${key}` as keyof Hourhand]! &&
                    hourhandA[`hour_start_time_${key}` as keyof Hourhand]! <
                      hourhandB[`hour_end_time_${key}` as keyof Hourhand]!) ||
                  //end hourhand A beetween horuhand b
                  (hourhandA[`hour_end_time_${key}` as keyof Hourhand]! >
                    hourhandB[`hour_start_time_${key}` as keyof Hourhand]! &&
                    hourhandA[`hour_end_time_${key}` as keyof Hourhand]! <
                      hourhandB[`hour_end_time_${key}` as keyof Hourhand]!) ||
                  //   HOURHAND B BEETWEEN HOURHAND A
                  // start hourhand B beetweem hourhand A
                  (hourhandB[`hour_start_time_${key}` as keyof Hourhand]! >
                    hourhandA[`hour_start_time_${key}` as keyof Hourhand]! &&
                    hourhandB[`hour_start_time_${key}` as keyof Hourhand]! <
                      hourhandA[`hour_end_time_${key}` as keyof Hourhand]!) ||
                  //end hourhand B beetween horuhand A
                  (hourhandB[`hour_end_time_${key}` as keyof Hourhand]! >
                    hourhandA[`hour_start_time_${key}` as keyof Hourhand]! &&
                    hourhandB[`hour_end_time_${key}` as keyof Hourhand]! <
                      hourhandA[`hour_end_time_${key}` as keyof Hourhand]!) ||
                  //horhand A-B same hours
                  hourhandB[`hour_start_time_${key}` as keyof Hourhand]! ===
                    hourhandA[`hour_start_time_${key}` as keyof Hourhand]! ||
                  hourhandB[`hour_end_time_${key}` as keyof Hourhand]! ===
                    hourhandA[`hour_end_time_${key}` as keyof Hourhand]!
                ) {
                  const hourhandId = hourhandA.id as unknown;
                  const hourhandConflict = hourhandB.id as unknown as keyof HourhandsConflict;

                  hourhandsToReturn[hourhandId as keyof HourhandsRegistered] = {
                    ...hourhandsToReturn[hourhandId as keyof HourhandsRegistered],
                    [hourhandConflict]: hourhandConflict,
                  };

                  return;
                } else {
                  return;
                }
              }
            } else {
              // dayAvailable = true;
            }
          }
        );
      }
    });
  });

  return hourhandsToReturn;
};

export const isOutRangeDate = (date: string | Date, currDate?: string | Date) => {
  if (date) {
    let currentDate = currDate || new Date();

    if (typeof currentDate === 'string') currentDate = parseISO(currentDate);

    return isBefore(typeof date === 'string' ? parseISO(date) : date, currentDate);
  }

  return false;
};

// export const hmsTOSeconds = (str: string) => {
//   var p = str.split(':'),
//     s = 0,
//     m = 1;

//   while (p.length > 0) {
//     s += m * parseInt(p?.pop(), 10);
//     m *= 60;
//   }

//   return s;
// };
