import { useAppSelector, useAppDispatch } from '../../../../../../hooks/useRedux';
import DeleteAlert from '../../../../../shared/components/dialogs/DeleteAlert';
import { useDeleteEvaluationMutation } from '../slices/evaluationsApiSlice';
import {
  setEvaluationsDeleteModalOpen,
  setEvaluationsIdToDelete,
} from '../slices/evaluationsSlice';

const EvaluationDeleteDialog = () => {
  const dispatch = useAppDispatch();
  const { evaluationsIdToDelete, evaluationsDeleteModalOpen } = useAppSelector(
    (state) => state.evaluations
  );

  const [deleteEvaluation, { isLoading, isSuccess, error, isError }] =
    useDeleteEvaluationMutation();

  const handleDelete = () => {
    deleteEvaluation({
      id: evaluationsIdToDelete!,
    });
  };
  const handleClose = () => {
    dispatch(setEvaluationsIdToDelete(null));
    dispatch(setEvaluationsDeleteModalOpen(false));
  };

  return (
    <DeleteAlert
      open={evaluationsDeleteModalOpen}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      error={(error as any)}
      handleDelete={handleDelete}
      onClose={handleClose}
    />
  );
};

export default EvaluationDeleteDialog;
