import { Box, Grid, Typography, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Collaborator } from '../../../hr/collaborators/interfaces/collaborators.interfaces';
import { ITeacher } from '../interfaces/teachers.interface';
import { useGetEducationLevelQuery } from '../../education-levels/slices/educationLevelsApiSlice';

interface Props {
  /** Diversas interfaces devueltas por el back en razon de los parametros enviados en la peticion */
  teacher: ITeacher | Collaborator;
}

const PersonalDataT = ({ teacher }: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const { data } = useGetEducationLevelQuery({
    educationLevelId: +teacher.education_level_principal_id,
  });

  return (
    <Box sx={{ border: `1px solid ${theme.palette.primary.main}`, borderRadius: '8px' }} p={2}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography align="right">
            <strong>{t('academic.teachers_list.assign_course.peronal_data.workload')}: </strong>
            {teacher.workload_total}
          </Typography>
        </Grid>
        <Grid item>
          <Typography>
            <strong>{t('academic.teachers_list.assign_course.peronal_data.name')}: </strong>
            {(teacher as ITeacher).pers_full_name ||
              (teacher as Collaborator).user.person.pers_full_name}
          </Typography>
        </Grid>
        <Grid item>
          <Typography>
            <strong>{t('academic.teachers_list.assign_course.peronal_data.faculty')}: </strong>
            {data?.edu_name}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PersonalDataT;
