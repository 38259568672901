import { Grid, Typography } from '@material-ui/core';
import TempBillingData from '../../generate-debts/components/TempBillingData';
import { useTranslation } from 'react-i18next';
import DebtorTable from '../components/DebtorTable';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useGetPersonsByParamQuery } from '../../../shared/slices/searchPersonApi';
import { useAppSelector, useAppDispatch } from '../../../../hooks/useRedux';
import { setCurrentClient } from '../../generate-debts/slices/generateDebtsSlice';

const FinancialRepresentative = () => {
  const { t } = useTranslation();

  const { currentClient } = useAppSelector((state) => state.generateDebts);
  const dispatch = useAppDispatch();

  const { dni } = useParams<{
    studentId: string;
    dni: string;
  }>();

  //client data
  const { data: clientInfo } = useGetPersonsByParamQuery(
    {
      is_student: true,
      ...(dni && { ci: dni }),
    },
    { skip: !dni }
  );

  useEffect(() => {
    if (!currentClient && clientInfo) {
      dispatch(setCurrentClient(clientInfo.data[0]));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientInfo]);

  return (
    <Grid container>
      <Grid item xs={12} md={'auto'} lg={8} sx={{ pr: { lg: 1 } }}>
        <Grid item xs={12} sx={{ padding: 4, bgcolor: 'background.paper', borderRadius: 2 }}>
          <Grid item xs={12}>
            <Grid item container alignItems="center" gap={1} sx={{ cursor: 'pointer' }}>
              <Typography variant="h2">{t('Representate financiero')}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ mt: 5 }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <DebtorTable />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={'auto'} lg={4} sx={{ pt: { xs: 1, lg: 0 } }}>
        {/* display: activeStep !== 1 ? 'none' : '' */}
        <Grid item xs={12} sx={{ padding: 1, bgcolor: 'background.paper', borderRadius: 2 }}>
          <TempBillingData />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FinancialRepresentative;
