import { Grid, Typography } from '@material-ui/core';
import MainCard from '../../../../shared/components/cards/MainCard';
import StudentsPosgrado from '../../components/presenciales/StudentsPosgrado';

const StudentsPosgradoScreen = () => {
  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">Estudiantes de Posgrado</Typography>
      </Grid>
      <StudentsPosgrado />
    </Grid>
  );
};

export default StudentsPosgradoScreen;
