import { Grid, Typography } from '@material-ui/core';
//import { useAppSelector } from '../../../../hooks/useRedux';
import MainCard from '../../../shared/components/cards/MainCard';
import LendsDelayTable from '../components/LendsDelayTable';

const RefundScreen = () => {
    //const { authorToDelete } = useAppSelector((state) => state.authors);
    return (
        <Grid component={MainCard} sx={{ padding: 2 }}>
            <Grid sx={{ mb: 3 }}>
                <Typography variant="h1">Prestamos Atrasados</Typography>
            </Grid>

            <LendsDelayTable />

            {/* {authorToDelete && <DeleteAuthorDialog />} */}
        </Grid>
    );


}

export default RefundScreen;