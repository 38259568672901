import React from 'react';

import {
  // Button,
  // DialogActions,
  DialogContent,
  Slide,
  SlideProps,
  Stack,
  Typography,
  // Grid,
  Card,
  CardContent,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExtendedSideDialog from '../../../../../shared/components/dialogs/ExtendedSideDialog';

import { useTranslation } from 'react-i18next';
import ScrollBar from 'react-perfect-scrollbar';

const useStyles = makeStyles((theme) => ({
  userAddDialog: {
    '&>div:nth-child(3)': {
      justifyContent: 'flex-end',
      '&>div': {
        margin: '0px',
        borderRadius: '0px',
        maxWidth: '450px',
        maxHeight: '100%',
      },
    },
  },
  paper: {
    minHeight: '100vh',
    width: '100%',
  },

  card: {
    backgroundColor: theme.palette.primary.light,
    borderRadius: '20px',
  },
}));

interface infoData {
  configName: string;
  description: string;
  color: string;
}

const Transition = React.forwardRef(function Transition(props: SlideProps, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

interface Props {
  open: boolean;
  handleCloseDialog: () => void;
}

const JustifyDialog = ({ open, handleCloseDialog }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const infoData: infoData[] = [
    {
      configName: t('Mejor Nota'),
      description:
        'Se obtendrá del score más alto obtenido entre las actividades seleccionada del insumo.',
      color: '#EFFFA0',
    },
    {
      configName: 'Promedio',
      description:
        'Se obtendrá del promedio de los score de las actividades seleccionadas del insumo.',
      color: '#A0FFE1',
    },
    {
      configName: 'Suma',
      description:
        'Se obtendrá luego de referenciar la nota con un peso pasado en la configuración y sumando los resultados.',
      color: '#FFAAAA',
    },
    {
      configName: 'Mediana',
      description:
        'Se obtendrá calculando la mediana entre los score obtenidos entre las actividades seleccionadas.',
      color: '#E2CAFF',
    },
  ];

  // const dispatch = useAppDispatch();

  return (
    <ExtendedSideDialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => {
        handleCloseDialog();
      }}
      className={classes.userAddDialog}
      classes={{
        paper: classes.paper,
      }}
    >
      <ScrollBar>
        <DialogContent>
          <Typography variant="h2">Información de configuración de insumos </Typography>

          <Stack spacing={2} sx={{ mt: 4, paddingY: 2 }}>
            {infoData.map((item, index) => (
              <Card
                key={index}
                sx={{
                  minWidth: 275,
                  // backgroundColor: item.color,
                  borderLeft: `10px solid ${item.color}`,
                  boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                }}
              >
                <CardContent color="primary">
                  <Typography sx={{ fontSize: 14 }} color="black" gutterBottom>
                    {item.configName}:
                  </Typography>
                  {/* <Typography variant="h5" component="div">
                  test
                </Typography> */}
                  <Typography variant="body2" mt={2} color="primary">
                    {item.description}
                  </Typography>
                </CardContent>
              </Card>
            ))}
          </Stack>
        </DialogContent>
      </ScrollBar>

      {/* <DialogActions>
        <Stack direction="row" spacing={2}>
          <Button
            variant="text"
            onClick={() => {
              handleCloseDialog();
            }}
            color="primary"
          >
            {t('academic.requisition.edit.wizard.step2.add_academic_form.dialog.cancel')}
          </Button>
        </Stack>
      </DialogActions> */}
    </ExtendedSideDialog>
  );
};

export default JustifyDialog;
