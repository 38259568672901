import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISearchTable } from '../../../shared/interfaces/paginatedTable.interface';
import { InvestigationLine } from '../interfaces/investigationLine.interfaces';
import { GroupResearch } from '../interfaces/groupResearch.interfaces';
import { Project } from '../interfaces/projectResearch.interfaces';
import { Application } from '../interfaces/applications.interfaces';
import { ProjectPrincipal } from '../interfaces/projectPrincipal.interfaces';

interface InvestigationLineSliceState {
  investigationLineList: InvestigationLine[];
  groupResearchList: GroupResearch[];
  projectList: Project[];
  projectPrincipalList: ProjectPrincipal[];
  applicationsList: Application[];

  investigationLineTable: ISearchTable;
  groupResearchTable: ISearchTable;
  projectTable: ISearchTable;
  projectPrincipalTable: ISearchTable;
  applicationsTable: ISearchTable;

  tasksTable: ISearchTable;

  deleteModalOpen: boolean;
  researchLineIdToDelete?: string;

  deleteModalOpenGroupResearch: boolean;
  groupResearchToDelete?: string;

  deleteModalOpenProject: boolean;
  projectIdToDelete?: string;
  isOpenCollaboratorTableModal: boolean;
  isLoadingAddCollaborator: boolean;
  projectIdSelected: string | null;
}

const initialState: InvestigationLineSliceState = {
  investigationLineList: [],
  groupResearchList: [],
  projectList: [],
  projectPrincipalList: [],
  applicationsList: [],

  deleteModalOpen: false,

  isOpenCollaboratorTableModal: false,

  deleteModalOpenGroupResearch: false,

  isLoadingAddCollaborator: false,

  deleteModalOpenProject: false,

  projectIdSelected: null,

  groupResearchTable: {
    page: 1,
    perPage: 5,
    search: '',
  },
  investigationLineTable: {
    page: 1,
    perPage: 5,
    search: '',
  },
  projectTable: {
    page: 1,
    perPage: 5,
    search: '',
    filterArrow: { direction: 'asc', name: 'id' },
  },
  projectPrincipalTable: {
    page: 1,
    perPage: 5,
    search: '',
    filterArrow: { direction: 'asc', name: 'id' },
  },
  applicationsTable: {
    page: 1,
    perPage: 5,
    search: '',
    filterArrow: { direction: 'asc', name: 'id' },
  },
  tasksTable: {
    page: 1,
    perPage: 5,
    search: '',
  },
};

const investigationLinesSlice = createSlice({
  name: 'investigationLines',
  initialState,
  reducers: {
    setInvestigationLinesList: (state, action: PayloadAction<InvestigationLine[]>) => {
      state.investigationLineList = action.payload;
    },
    setGroupResearchList: (state, action: PayloadAction<GroupResearch[]>) => {
      state.groupResearchList = action.payload;
    },
    setProjectList: (state, action: PayloadAction<Project[]>) => {
      state.projectList = action.payload;
    },
    setProjectPrincipalList: (state, action: PayloadAction<ProjectPrincipal[]>) => {
      state.projectPrincipalList = action.payload;
    },
    setApplicationsList: (state, action: PayloadAction<Application[]>) => {
      state.applicationsList = action.payload;
    },

    updateInvestigationLinesTableStates: (state, action: PayloadAction<ISearchTable>) => {
      state.investigationLineTable = action.payload;
    },
    updateGroupResearchTableStates: (state, action: PayloadAction<ISearchTable>) => {
      state.groupResearchTable = action.payload;
    },
    updateProjectTableStates: (state, action: PayloadAction<ISearchTable>) => {
      state.projectTable = action.payload;
    },
    updateProjectPrincipalTableStates: (state, action: PayloadAction<ISearchTable>) => {
      state.projectPrincipalTable = action.payload;
    },
    updateApplicationTableStates: (state, action: PayloadAction<ISearchTable>) => {
      state.applicationsTable = action.payload;
    },

    setDeleteModalOpen: (state, action: PayloadAction<boolean>) => {
      state.deleteModalOpen = action.payload;
    },
    openDeleteModalWith: (state, action: PayloadAction<string>) => {
      state.researchLineIdToDelete = action.payload;
      state.deleteModalOpen = true;
    },
    resetResearchLineIdToDelete: (state) => {
      state.researchLineIdToDelete = undefined;
    },

    //Group
    setDeleteModalOpenResearchGroup: (state, action: PayloadAction<boolean>) => {
      state.deleteModalOpenGroupResearch = action.payload;
    },
    openDeleteModalWithResearchGroup: (state, action: PayloadAction<string>) => {
      state.groupResearchToDelete = action.payload;
      state.deleteModalOpenGroupResearch = true;
    },
    resetGroupResearchIdToDelete: (state) => {
      state.groupResearchToDelete = undefined;
    },

    //Project
    setDeleteModalOpenProject: (state, action: PayloadAction<boolean>) => {
      state.deleteModalOpenProject = action.payload;
    },
    openDeleteModalWithProject: (state, action: PayloadAction<string>) => {
      state.projectIdToDelete = action.payload;
      state.deleteModalOpenProject = true;
    },
    resetProjectIdToDelete: (state) => {
      state.projectIdToDelete = undefined;
    },
    //Applications

    // Tasks
    updateTasksTable: (state, action: PayloadAction<ISearchTable>) => {
      state.tasksTable = action.payload;
    },

    //CollaboratorTableModal
    setIsOpenCollaboratorTableModal: (state, action: PayloadAction<boolean>) => {
      state.isOpenCollaboratorTableModal = action.payload;
    },

    //ProjectIdSelected
    setProjectIdSelected: (state, action: PayloadAction<string | null>) => {
      state.projectIdSelected = action.payload;
    },

    //isLoadingAddCollaborator
    setIsLoadingAddCollaborator: (state, action: PayloadAction<boolean>) => {
      state.isLoadingAddCollaborator = action.payload;
    },
  },
});

export const {
  setGroupResearchList,
  setInvestigationLinesList,
  setProjectList,
  setProjectPrincipalList,
  setApplicationsList,

  updateProjectTableStates,
  updateInvestigationLinesTableStates,
  updateProjectPrincipalTableStates,
  updateApplicationTableStates,
  openDeleteModalWith,

  setDeleteModalOpen,
  resetResearchLineIdToDelete,

  updateGroupResearchTableStates,

  openDeleteModalWithResearchGroup,
  resetGroupResearchIdToDelete,
  setDeleteModalOpenResearchGroup,

  openDeleteModalWithProject,
  resetProjectIdToDelete,
  setDeleteModalOpenProject,

  updateTasksTable,
  setIsOpenCollaboratorTableModal,
  setProjectIdSelected,
  setIsLoadingAddCollaborator,
} = investigationLinesSlice.actions;

export default investigationLinesSlice.reducer;
