import { useState } from 'react';
import { Grid, MobileStepper, useTheme, Typography } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { RegisterSyllabusData } from '../../../interfaces/registerSyllabusData';
import BasicBibliography from './BasicBibliography';
import ComplementaryBibliography from './ComplementaryBibliography';
import AddIconButton from '../../buttons/AddIconButton';
import TeacherData from './TeacherData';

const stepsArray = [
  { id: 1, name: 'Bibliográfica Básica' },
  { id: 2, name: 'Bibliográfica Complementaria' },
  { id: 3, name: 'Datos del profesor' },
];

const Bibliography = () => {
  const theme = useTheme();

  const [activeStep, setActiveStep] = useState(1);

  const { values, setFieldValue } = useFormikContext<RegisterSyllabusData>();

  console.log(values);
  const handleAddComplementaryBibliography = () => {
    setFieldValue('syllabus_bibliogra_comple', [
      ...values.syllabus_bibliogra_comple,
      {
        id: null,
        syllabu_id: null,
        book_name: '',
        book_autor: '',
        book_editor: '',
      },
    ]);
  };

  return (
    <Grid container justifyContent="center" alignItems="center" direction="column">
      <Grid container justifyContent="center" alignItems="center" my={2}>
        <Typography variant="h4" color="primary">
          Paso {activeStep} de {stepsArray.length}
        </Typography>
        <Grid item xs={12}>
          <MobileStepper
            variant="progress"
            steps={3}
            position="static"
            activeStep={activeStep - 1}
            LinearProgressProps={{
              sx: {
                width: '100%',
                height: 10,
                borderRadius: 5,
                backgroundColor: `${theme.palette.grey[300]} !important`,
              },
            }}
            backButton
            nextButton
          />
        </Grid>
        <Grid item container xs={12} justifyContent="space-between" alignItems="center" mt={2}>
          <Typography variant="h4" color="inherit" my={2}>
            {stepsArray[activeStep - 1]?.name}
          </Typography>
          {activeStep === 2 && (
            <AddIconButton btnText="Agregar opción" onClick={handleAddComplementaryBibliography} />
          )}
        </Grid>
      </Grid>
      <Grid container direction="column">
        <div hidden={activeStep !== 1}>
          <BasicBibliography isBack setNext={setActiveStep} />
        </div>
        <div hidden={activeStep !== 2}>
          <ComplementaryBibliography isBack setNext={setActiveStep} />
        </div>
        <div hidden={activeStep !== 3}>
          <TeacherData isBack setNext={setActiveStep} />
        </div>
      </Grid>
    </Grid>
  );
};

export default Bibliography;
