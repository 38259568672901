import { Grid, Typography } from '@material-ui/core';
// import BackButton from '@shared-components/buttons/BackButton';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import BackButton from '../../components/buttons/BackButton';
import MainCard from '../../components/cards/MainCard';
import IncompleteQuestionsDialog from '../components/dialogs/IncompleteQuestionsDialog';

import EvaluationInfoBox from '../components/evaluation/EvaluationInfoBox';
import EvaluationUploadDIalog from '../components/evaluation/EvaluationUploadDialog';
import QuestionGroupContainer from '../components/evaluation/QuestionGroupContainer';
import { useGetEvaluationToDoQuery } from '../slices/userEvaluationsApiSlice';
import {
  resetEvaluationQuestionsAnswered,
  setEvaluationQuestionToAnswer,
  setInfoEvaluationToDo,
} from '../slices/userEvaluationsSlice';

const EvaluationScreen = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { id } = useParams<{ id: string }>();

  const { evaluationUploadDialogOpen, evaluationIncompleteQuestionsOpen } = useAppSelector(
    (state) => state.userEvaluations
  );

  const { data, isLoading } = useGetEvaluationToDoQuery({
    id,
  });

  useEffect(() => {
    if (data) {
      dispatch(setInfoEvaluationToDo(data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(
    () => () => {
      dispatch(setInfoEvaluationToDo(null));
      dispatch(resetEvaluationQuestionsAnswered());
      dispatch(setEvaluationQuestionToAnswer(0));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <Grid
      component={MainCard}
      sx={{
        padding: 2,
      }}
    >
      <Grid item container xs={12} alignItems="center">
        <BackButton />
      </Grid>
      <Grid item xs={12}>
        {isLoading && (
          <Typography variant="h2">{t('user_evaluation.evaluation.loading')}</Typography>
        )}
        {!isLoading && data && (
          <>
            <EvaluationInfoBox />
            <QuestionGroupContainer />
          </>
        )}
      </Grid>
      {evaluationUploadDialogOpen && <EvaluationUploadDIalog />}
      {evaluationIncompleteQuestionsOpen && <IncompleteQuestionsDialog />}
    </Grid>
  );
};

export default EvaluationScreen;
