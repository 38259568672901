import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@material-ui/lab';
import { Grid, Typography, useTheme } from '@material-ui/core';

import GroupIcon from '@material-ui/icons/Group';
import AssignmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import { blue } from '@material-ui/core/colors';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const TimelineOnce: React.FC<{
  Descripcion: string;
  FechaInicio: Date;
  FechaEnd: Date;
  Category: string;
}> = ({ Descripcion, FechaInicio, FechaEnd, Category }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Grid sx={{ mb: 2 }}>
      <TimelineItem>
        <TimelineOppositeContent style={{ content: '', display: 'none' }}></TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot sx={{ bgcolor: blue[900] }}>
            <AssignmentOutlinedIcon sx={{ color: theme.palette.grey[50], fontSize: '20px' }} />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Grid container alignItems="center">
            <Grid item container xs={9}>
              <Grid sx={{ mb: 1 }} width="100%">
                <Typography variant="h4">{Category}</Typography>
              </Grid>
              <Grid sx={{ mb: 1 }} item container>
                <Typography variant="h5">{Descripcion}</Typography>
              </Grid>

              <Grid container>
                <Typography sx={{ pr: 2 }} fontWeight={'bold'}>
                  {t('studentVirtualClassroom.content.startDate')}
                </Typography>
                <Typography>{moment(FechaInicio).format('LLL')}</Typography>
              </Grid>
              <Grid container>
                <Typography sx={{ pr: 2 }} fontWeight={'bold'}>
                  {t('studentVirtualClassroom.content.endDate')}
                </Typography>
                <Typography>{moment(FechaEnd).format('LLL')}</Typography>
              </Grid>
            </Grid>
            <Grid item container xs={3}>
              <Grid>
                <GroupIcon sx={{ color: theme.palette.grey[500] }} />
              </Grid>
            </Grid>
          </Grid>
        </TimelineContent>
      </TimelineItem>
    </Grid>
  );
};

export default TimelineOnce;
