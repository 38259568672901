// import { useState } from 'react';
// import { Tooltip, IconButton } from '@material-ui/core';
// import { IconPlayerPlay } from '@tabler/icons';
// import { useGetEvaluationsStudentQuery } from '../../slices/virtualClassroomApiSlice';
import { useState } from 'react';
import { useAppSelector } from '../../../../../hooks/useRedux';
import { useGetMaterialsStudentQuery } from '../../slices/virtualClassroomApiSlice';
import noContentImage from '../../../../../assets/images/no-hay-tareas.png';
import { ToolsMaterial } from '../interfaces/materialsStudent.interfaces';
import MaterialItem from './MaterialItem';
import ModalDownload from './ModalDownload';
import NoData from '../../../../shared/components/NoData';
import { useTranslation } from 'react-i18next';

const MaterialList = () => {
  const { t } = useTranslation();
  const { currentProfile } = useAppSelector((state) => state.access);
  const {
    course: { courseId },
  } = useAppSelector((state) => state.virtualClassroomStudentShared);

  const [open, setOpen] = useState(false);
  const [dataModal, setDataModal] = useState<ToolsMaterial>();
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const { data: materials } = useGetMaterialsStudentQuery({
    userProfileId: currentProfile?.profileId!,
    courseId: courseId!,
  });
  return (
    <>
      {Array.isArray(materials) ? (
        materials?.map((parcial) => (
          <MaterialItem parcial={parcial} modalOptions={{ handleOpen, setDataModal }} />
        ))
      ) : (
        <NoData
          image={noContentImage}
          title={t('studentVirtualClassroom.materials.content')}
          subtitle={t('studentVirtualClassroom.materials.content.description')}
        />
      )}
      <ModalDownload open={open} handleClose={handleClose} dataModal={dataModal} />
    </>
  );
};

export default MaterialList;
