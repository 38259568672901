import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISearchTable } from '../../../shared/interfaces/paginatedTable.interface';
import { Matter } from '../interfaces/matters.interface';

interface MattersState {
  mattersList: Matter[];
  deleteModalOpen: boolean;
  matterIdToDelete?: number;
  mattersTable: ISearchTable;

  matterTypeIdSelected: number | null;
  educationLevelIdSelected: number | null;
}

const initialState: MattersState = {
  mattersList: [],
  deleteModalOpen: false,
  mattersTable: {
    page: 1,
    perPage: 5,
    search: '',
  },
  matterTypeIdSelected: null,
  educationLevelIdSelected: null,
};

const mattersSlice = createSlice({
  name: 'mattersTypes',
  initialState,
  reducers: {
    setMattersList: (state, action: PayloadAction<Matter[]>) => {
      state.mattersList = action.payload;
    },
    setDeleteModalOpen: (state, action: PayloadAction<boolean>) => {
      state.deleteModalOpen = action.payload;
    },
    openDeleteModalWith: (state, action: PayloadAction<number>) => {
      state.matterIdToDelete = action.payload;
      state.deleteModalOpen = true;
    },
    resetMatterIdToDelete: (state) => {
      state.matterIdToDelete = undefined;
    },
    updateMattersTable: (state, action: PayloadAction<ISearchTable>) => {
      state.mattersTable = action.payload;
    },
    setMatterTypeIdSelected: (state, action: PayloadAction<number | null>) => {
      state.matterTypeIdSelected = action.payload;
    },
    setEducationLevelIdSelected: (state, action: PayloadAction<number | null>) => {
      state.educationLevelIdSelected = action.payload;
    },
  },
});

export const {
  setMattersList,
  setDeleteModalOpen,
  openDeleteModalWith,
  resetMatterIdToDelete,
  updateMattersTable,
  setMatterTypeIdSelected,
  setEducationLevelIdSelected,
} = mattersSlice.actions;

export default mattersSlice.reducer;
