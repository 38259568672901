import {
  Button,
  Dialog,
  Grid,
  Typography,
  Avatar,
  CircularProgress,
  useTheme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AnimateButton from './../../../shared/components/extended/AnimateButton';
import { ChangeEvent, useState } from 'react';
import { resetStudenToAddAvatar, setModalAddAvatar } from '../slices/studentsSlice';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { useAddAvatarStudentMutation } from '../slices/studentsApiSlice';
import { IconCircleCheck } from '@tabler/icons';
import { baseUrl } from '../../../../api/mainApi';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  paper: { maxWidth: '600px', width: '90%' },
  accountAvatar: {
    width: '100px',
    height: '100px',
    margin: '0 auto',
  },
}));

const StudentAddAvatar = function () {
  const { t } = useTranslation();

  const { studentsList } = useAppSelector((state) => state.students);

  const classes = useStyles();
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const [file, setFile] = useState('');
  const [target, setTarget] = useState<File>(null!);
  const { currentProfile } = useAppSelector((state) => state.access);

  const onChangePicture = function (event: ChangeEvent<HTMLInputElement>) {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e: any) => {
        setFile(e.target.result);
      };
      reader.readAsDataURL(event.target.files[0]);
      setTarget(event.target.files[0]);
    }
  };

  const handleClose = () => {
    dispatch(setModalAddAvatar(false));
    dispatch(resetStudenToAddAvatar());
  };

  const [addAvatar, { isLoading, isSuccess, isError, error }] = useAddAvatarStudentMutation();

  const { avatarModalOpen, studentIdToAddAvatar, studentImgToAddAvatar } = useAppSelector(
    (state) => state.students
  );

  const student = studentsList.find((student) => student.id === Number(studentIdToAddAvatar));

  const handleAddAvatar = function () {
    const fileSelected: File[] = [];
    fileSelected.push(target);
    addAvatar({
      avatarStudentPayload: {
        files: target,
        period: 1,
        type_document: 6,
        user_profile_id: currentProfile!.profileId,
      },
      studentId: studentIdToAddAvatar!,
    });
  };
  const handleClickAvatar = function () {
    document.getElementById('btnFile')?.click();
  };

  return (
    <Dialog
      open={avatarModalOpen}
      onClose={handleClose}
      classes={{ paper: classes.paper }}
      sx={{ p: 3 }}
    >
      <Grid container gap={4} sx={{ p: 2 }}>
        <Grid item container xs={12} alignItems="center" justifyContent="center">
          <Typography variant="h2" sx={{ color: theme.palette.primary.main, mb: 1 }}>
            {!isError && !isSuccess && t('academic.students_list.student.add_photo.title')}
          </Typography>
          {!isError && !isSuccess && Boolean(student?.pers_full_name) && (
            <Typography variant="h3" sx={{ color: theme.palette.primary.main, mb: 1 }}>
              {' '}
              {student?.pers_full_name}
            </Typography>
          )}
          {isSuccess && (
            <IconCircleCheck
              size={60}
              color={theme.palette.success.main}
              style={{
                padding: 12,
                backgroundColor: theme.palette.success.light,
                borderRadius: '50%',
              }}
            />
          )}
          <Grid item xs={12} textAlign="center" style={{ marginTop: 15 }}>
            <Typography variant="h2" sx={{ color: theme.palette.primary.main, mb: 1 }}>
              {isSuccess && t('academic.students_list.student.add_photo.success')}
            </Typography>
          </Grid>
        </Grid>

        {!isSuccess && (
          <Grid item container xs={12} alignItems="center" justifyContent="center">
            <Grid style={{ marginBottom: 30 }}>
              <Avatar
                onClick={handleClickAvatar}
                className={classes.accountAvatar}
                alt="foto de perfil"
                style={{ cursor: 'pointer' }}
                // src={
                //   (file && file) || studentImgToAddAvatar
                //     ? `${baseUrl}/show?name=Web/1/LOGOS/${studentImgToAddAvatar}`
                //     : file
                // }
                src={
                  file ||
                  (studentImgToAddAvatar &&
                    `${baseUrl}/show?name=Web/1/LOGOS/${studentImgToAddAvatar}`)
                }
              ></Avatar>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 6, marginBottom: 10 }}>
              <Typography variant="subtitle2" align="center">
                {t('academic.students_list.student.add_photo.description')}
              </Typography>
            </Grid>
            <Grid>
              <AnimateButton>
                <Button
                  disabled={isLoading}
                  style={{ marginBottom: 50 }}
                  variant="contained"
                  color="primary"
                  component={'label'}
                  size="small"
                >
                  {!file
                    ? t('academic.students_list.student.add_photo.upload')
                    : t('academic.students_list.student.add_photo.change')}
                  <input type="file" id="btnFile" hidden onChange={onChangePicture} />
                </Button>
              </AnimateButton>
            </Grid>
          </Grid>
        )}
      </Grid>
      {/*  */}
      <Grid item xs={12} textAlign="center">
        <Grid item container xs={12} justifyContent="center" gap={1}>
          {!isSuccess && !isError && (
            <Button
              variant="text"
              size="large"
              color="primary"
              onClick={handleClose}
              disabled={isLoading}
            >
              {t('academic.students_list.student.add_photo.cancel')}
            </Button>
          )}

          {(isSuccess || error) && (
            <Button variant="contained" size="large" color="primary" onClick={handleClose}>
              {t('academic.students_list.student.add_photo.continue')}
            </Button>
          )}

          {!isSuccess && !isError && (
            <Button
              variant="contained"
              size="large"
              onClick={handleAddAvatar}
              color="primary"
              autoFocus
              disabled={isLoading || !file}
              endIcon={isLoading && <CircularProgress size={20} />}
            >
              {t('academic.students_list.student.add_photo.submit')}
            </Button>
          )}
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default StudentAddAvatar;
