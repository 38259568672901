import { Switch } from 'react-router-dom';

import PeriodsScreen from '../screens/PeriodsScreen';
import AddPeriodScreen from '../screens/AddPeriodScreen';
import EditPeriodScreen from '../screens/EditPeriodScreen';
import AssignStagesScreen from '../screens/AssignStagesScreen';
import PeriodStageScreen from '../screens/PeriodStageScreen';
import PeriodStageEditScreen from '../screens/PeriodStageEditScreen';

import GuardRoute from '../../../../router/guards/GuardRoute';

import { subPermissionKeys } from '../constants/permissions';
import AssignQualificationScreen from '../screens/AssignQualificationScreen';

const PeriodsRouter = () => {
  return (
    <Switch>
      {/* Stages */}
      <GuardRoute
        exact
        path="/administrar-periodos/:periodId/etapa/:stageId/editar"
        component={PeriodStageEditScreen}
        permissionKey={subPermissionKeys.groupKey}
        subPermissionKey={subPermissionKeys.read}
      />

      <GuardRoute
        exact
        path="/administrar-periodos/:periodId/asignar-etapas"
        component={AssignStagesScreen}
        permissionKey={subPermissionKeys.groupKey}
        subPermissionKey={subPermissionKeys.read}
      />

      <GuardRoute
        exact
        path="/administrar-periodos/etapas/:periodId"
        component={PeriodStageScreen}
        permissionKey={subPermissionKeys.groupKey}
        subPermissionKey={subPermissionKeys.read}
      />
      <GuardRoute
        exact
        path="/administrar-periodos/:periodId/permisos-calificaciones"
        component={AssignQualificationScreen}
        permissionKey={subPermissionKeys.groupKey}
        subPermissionKey={subPermissionKeys.read}
      />
      {/* End stages */}
      <GuardRoute
        exact
        path="/administrar-periodos/agregar"
        component={AddPeriodScreen}
        permissionKey={subPermissionKeys.groupKey}
        subPermissionKey={subPermissionKeys.create}
      />
      <GuardRoute
        exact
        path="/administrar-periodos/:periodId/editar"
        component={EditPeriodScreen}
        permissionKey={subPermissionKeys.groupKey}
        subPermissionKey={subPermissionKeys.edit}
      />
      <GuardRoute
        exact
        path="/administrar-periodos"
        component={PeriodsScreen}
        permissionKey={subPermissionKeys.groupKey}
        subPermissionKey={subPermissionKeys.read}
      />
    </Switch>
  );
};

export default PeriodsRouter;
