import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../../hooks/useRedux';
import MainCard from '../../../shared/components/cards/MainCard';
import DeleteEmissionPointDialog from '../components/DeleteEmissionPointDialog';
import FinancialConfigurationTable from '../components/FinancialConfigurationTable';
import UnlinkUserDialog from '../components/UnlinkUserDialog';

const FinancialConfigurationScreen = () => {
  const { emissionPointIdToDelete, userIdToUnlink } = useAppSelector(
    (state) => state.financialConfigurations
  );

  const { t } = useTranslation();

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">{t('financial.emission_point.title')}</Typography>
      </Grid>
      <FinancialConfigurationTable />
      {emissionPointIdToDelete && <DeleteEmissionPointDialog />}
      {userIdToUnlink && <UnlinkUserDialog />}
    </Grid>
  );
};
export default FinancialConfigurationScreen;
