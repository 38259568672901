import {
  Collapse,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { FC, useState } from 'react';
import { IconChevronDown, IconChevronUp } from '@tabler/icons';
import { useTranslation } from 'react-i18next';

import { DataPostMassiveDebtsResponse } from '../../interfaces/productStudentRecord.interface';

interface Props {
  data?: DataPostMassiveDebtsResponse | null;
  onClose?: () => void;
}

const DetailsGeneratedDebtsDialog: FC<Props> = ({ data }) => {
  const [collapseStatus, setCollapseStatus] = useState({
    feesGenerated: false,
    feesHavePayment: false,
    feesDeleted: false,
    feesPreviewedGenerated: false,
  });

  const { t } = useTranslation();

  const toogleCollapseHandler = (param: string) => {
    setCollapseStatus((prevState) => {
      return { ...prevState, [param]: !prevState[param as keyof typeof collapseStatus] };
    });
  };

  return (
    <Grid container p={3}>
      <Grid item xs={12}>
        <Grid container justifyContent={'space-between'}>
          <Typography variant="h3" color="initial">
            {t('financial.generate_debts.massive.dialog.fees_generated')}
            {data?.fees_generated.length}
          </Typography>
          <IconButton onClick={() => toogleCollapseHandler('feesGenerated')}>
            {collapseStatus.feesGenerated ? (
              <IconChevronUp size={20} />
            ) : (
              <IconChevronDown size={20} />
            )}
          </IconButton>
        </Grid>
        <Collapse in={collapseStatus.feesGenerated} timeout="auto" unmountOnExit>
          {/* imprimir la data dentro de un componente de tabla */}
          <TableDetails data={data?.fees_generated!} />
        </Collapse>
      </Grid>

      <Grid item xs={12}>
        <Grid container justifyContent={'space-between'}>
          <Typography variant="h3" color="initial">
            {t('financial.generate_debts.massive.dialog.debts_have_payment')}
            {data?.debts_have_payment.length}
          </Typography>
          <IconButton onClick={() => toogleCollapseHandler('feesHavePayment')}>
            {collapseStatus.feesHavePayment ? (
              <IconChevronUp size={20} />
            ) : (
              <IconChevronDown size={20} />
            )}
          </IconButton>
        </Grid>
        <Collapse in={collapseStatus.feesHavePayment} timeout="auto" unmountOnExit>
          {/* imprimir la data dentro de un componente de tabla */}
          <TableDetails data={data?.debts_have_payment!} />
        </Collapse>
      </Grid>

      <Grid item xs={12}>
        <Grid container justifyContent={'space-between'}>
          <Typography variant="h3" color="initial">
            {t('financial.generate_debts.massive.dialog.debts_deleted')}
            {data?.debts_deleted.length}
          </Typography>
          <IconButton onClick={() => toogleCollapseHandler('feesDeleted')}>
            {collapseStatus.feesDeleted ? (
              <IconChevronUp size={20} />
            ) : (
              <IconChevronDown size={20} />
            )}
          </IconButton>
        </Grid>
        <Collapse in={collapseStatus.feesDeleted} timeout="auto" unmountOnExit>
          <TableDetails data={data?.debts_deleted!} />
        </Collapse>
      </Grid>

      <Grid item xs={12}>
        <Grid container justifyContent={'space-between'}>
          <Typography variant="h3" color="initial">
            {t('financial.generate_debts.massive.dialog.fees_previewed_generated')}
            {data?.fees_previewed_generated.length}
          </Typography>
          <IconButton onClick={() => toogleCollapseHandler('feesPreviewedGenerated')}>
            {collapseStatus.feesPreviewedGenerated ? (
              <IconChevronUp size={20} />
            ) : (
              <IconChevronDown size={20} />
            )}
          </IconButton>
        </Grid>
        <Collapse in={collapseStatus.feesPreviewedGenerated} timeout="auto" unmountOnExit>
          <TableDetails data={data?.fees_previewed_generated!} />
        </Collapse>
      </Grid>
    </Grid>
  );
};

interface ITableDetails {
  data: IDataTableDetails[];
}

interface IDataTableDetails {
  person_full_name: string;
  student_Code: string;
}

const TableDetails = ({ data }: ITableDetails) => {
  const { t } = useTranslation();

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('financial.generate_debts.massive.dialog.name')}</TableCell>
            <TableCell align="center">
              {t('financial.generate_debts.massive.dialog.code')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((fee, i) => (
            <TableRow key={`${fee.student_Code}-${i}`}>
              <TableCell>{fee.person_full_name}</TableCell>
              <TableCell align="center">{fee.student_Code}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DetailsGeneratedDebtsDialog;
