import { useState, useCallback, useEffect } from 'react';
import { useHistory, Redirect, useRouteMatch } from 'react-router-dom';

import {
  Button,
  FormHelperText,
  Grid,
  Stack,
  IconButton,
  Typography,
  Box,
  TextField,
  Autocomplete,
  LinearProgress,
  Skeleton,
  Snackbar,
  Alert,
  ToggleButtonGroup,
  ToggleButton,
  Chip,
  Avatar,
  useTheme,
} from '@material-ui/core';
import {
  IconTrash,
  IconCornerDownRight,
  IconTemplate,
  IconChevronDown,
  IconX,
} from '@tabler/icons';
import { useFormik } from 'formik';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import { Calendar, momentLocalizer, CalendarProps, EventProps, Event } from 'react-big-calendar';
import 'moment/locale/es';
import TextFieldFetching from '../../../shared/components/TextFieldFetching';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import InputLabel from '../../../shared/components/forms/InputLabel';
import { useAppSelector, useAppDispatch } from '../../../../hooks/useRedux';
import {
  setInitialPrograms,
  setInitialInfoPlanning,
  setEduLevelsAvailables,
  setInitialEventsPlanning,
  setPostgraduateDeleteProgramDialogOpen,
  setPostgraduateDeleteProgramDialogIdSelected,
  setCurrentDateSelected,
  setShowEventsDialog,
  setHolidaysResponse,
  setHolidaysList,
} from '../slices/acPlanPostgraduateSlice';
import { CalendarInitialSchema } from '../constants/validationSchemas';
import { drawerWidth } from '../../../shared/constants/uiConstants';
import SymbologiesGroup from './symbologies/SymbologiesGroup';
import Transitions from '../../../shared/components/extended/Transitions';
import { MyEvents } from '../interfaces/events.interfaces';
import { useGetLocaleDateLang } from '../../../../translation/hooks/useGetLocaleDateLang';
import { Mesh, EducationLevel } from '../../education-levels/interfaces/educationLevels.interfaces';
import { useGetEducationLevelsParentsAndChildrenQuery } from '../../education-levels/slices/educationLevelsApiSlice';
import {
  useEditPlanningTemporaryMutation,
  useGetHolidaysAvailablesQuery,
  useGetPlanningTemporaryQuery,
} from '../slices/acPlanPostgraduateApiSlice';
import { DatePicker, LocalizationProvider } from '@material-ui/lab';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import { useInView } from 'react-intersection-observer';
import DayEventsDialog from './dialogs/DayEventsDialog';
import { useTranslation } from 'react-i18next';
import { Modality } from '../../meshs/interfaces/curriculums.interfaces';
import { useGetStudyModalitiesQuery } from '../../meshs/slices/meshsApiSlice';
import useGetHolidays from '../hooks/useGetHolidays';
import useGetDayTypes from '../hooks/useGetDayTypes';
import { parse } from 'date-fns';

const useStyles = makeStyles((theme) => ({
  calendar: {
    '& .rbc-btn-group': { display: 'none' },
  },
}));

const DnDCalendar = withDragAndDrop(Calendar as React.ComponentType<CalendarProps>);

const CalendarFormChooseInitCareer = () => {
  const { t } = useTranslation();

  const theme = useTheme();
  const history = useHistory();

  const { path } = useRouteMatch();

  const dispatch = useAppDispatch();

  const { lang, langKey } = useGetLocaleDateLang();

  const { currentProfile } = useAppSelector((state) => state.access);
  const { opened } = useAppSelector((state) => state.customization);

  const {
    initialInfoPlanning,
    initialEventsPlanning,
    dragEvent,
    initialPrograms,
    holidaysMove,
    holidaysResponse,
    currentDateSelected,
    showEventsDialog,
    calendarIdSelected,
  } = useAppSelector((state) => state.academicPlanningPosgraduate);

  const classes = useStyles();

  const handleSetInitalPrograms = (events: MyEvents[]) => {
    dispatch(setInitialPrograms(events));
  };

  const { data: educationLevelsAvailables, isLoading: isEducationLevelsLoading } =
    useGetEducationLevelsParentsAndChildrenQuery({ postgraduate: true, filter: true });

  const { ref, inView: isVisible } = useInView();

  const localizer = momentLocalizer(moment);

  const [showAlerts, setShowAlerts] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);

  const [selectedtEvent, setSelectedEvent] = useState<MyEvents>(null!);

  const [currentDate] = useState<Date>(
    moment(currentDateSelected).set('date', 1).toDate() ||
      moment(new Date()).set('date', 1).toDate()
  );

  const [isInitProcess, setInitProcess] = useState(!!initialInfoPlanning.facultad);

  const [showEditProcess, setShowEditProcess] = useState(false);

  const [firstRender, setFirstRender] = useState(true);

  const [calendarView, setCalendarView] = useState<'month' | 'year'>('year');

  const handleCloseEventsDialog = () => {
    dispatch(
      setShowEventsDialog({
        open: false,
        events: [],
        date: null,
      })
    );
  };

  const handleChangeCalendarView = (
    event: React.MouseEvent<HTMLElement>,
    newCalendarView: string
  ) => {
    if (newCalendarView !== null) {
      setCalendarView(newCalendarView as 'month' | 'year');
    } else {
      setCalendarView('year');
    }
  };

  useEffect(() => {
    setSelectedEvent(null!);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarIdSelected]);

  useEffect(() => {
    dispatch(setCurrentDateSelected(currentDate));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDate]);

  const [
    addTemporaryInitialCareerPlanning,
    { isLoading: isLoadingAddTemporary, isError: isErrorAddTemporary, error: errorAddTemporary },
  ] = useEditPlanningTemporaryMutation();

  useEffect(() => {
    if (isErrorAddTemporary) {
      setOpenErrorSnackbar(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isErrorAddTemporary]);

  useEffect(() => {
    if (!holidaysResponse['move-holidays'] && !holidaysResponse.holidays) return;
    if (holidaysMove) {
      dispatch(setHolidaysList(holidaysResponse['move-holidays']));
    } else {
      dispatch(setHolidaysList(holidaysResponse.holidays));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [holidaysMove, holidaysResponse]);

  // La libreria  me obliga a tener este estado pese a que no se lo utiliza :'C
  if (false) {
    console.log(currentDate);
  }

  const { startProgram, firstDay, holiday, initialPlanningPoint } = useGetDayTypes();

  const moveEvent = useCallback(
    ({ event, start, end }) => {
      const existing = initialPrograms.find((ev) => ev.id === event.id) ?? ({} as MyEvents);
      const filtered = initialPrograms.filter((ev) => ev.id !== event.id);
      const eventsMove = [...filtered, { ...existing, start, end }];

      const isPip = initialEventsPlanning
        .filter((event) => event.typeId === 'PIP')
        .find((ipaEvent) => moment(ipaEvent.start).isSame(moment(start)));

      if (isPip) {
        handleSetInitalPrograms(eventsMove);
      }
    },

    //eslint-disable-next-line
    [initialPrograms]
  );

  const handleDeleteEvent = (id: string) => {
    setSelectedEvent(null!);
    dispatch(setPostgraduateDeleteProgramDialogIdSelected(id));
    dispatch(setPostgraduateDeleteProgramDialogOpen(true));
  };

  const { errors, handleSubmit, values, setFieldValue, touched } = useFormik({
    initialValues: {
      facultad: {} as EducationLevel,
      career: [] as EducationLevel[],
      curriculum: {} as Mesh,
      modality: {} as Modality,
      year: currentDateSelected
        ? parse(currentDateSelected, 'dd-MMM-yyyy', new Date())
        : new Date(),
    },
    validationSchema: CalendarInitialSchema,
    onSubmit: async (values) => {
      setShowAlerts(true);
      return <Redirect to="/planeacion-academica-posgrado/fechas-carrera" />;
    },
  });

  const { data: modalities, isLoading: isLoadingModalities } = useGetStudyModalitiesQuery(
    {
      profileId: currentProfile?.profileId!,
    },
    {
      skip: !currentProfile?.profileId,
    }
  );

  const {
    // error
    data,
    isLoading: isLoadingHolidays,
  } = useGetHolidaysAvailablesQuery({
    currentCountry: 'pais-ec',
    year: values.year.getFullYear(),
    yearPlusOne: true,
    generateHoliday: true,
    ...(calendarIdSelected && { calendarId: calendarIdSelected }),
  });

  useEffect(() => {
    if (data) {
      // dispatch(setHolidaysList(data?.holidays || []));
      dispatch(setHolidaysResponse(data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, dispatch]);

  const { data: dataTemporary, isLoading: isLoadingTemporary } = useGetPlanningTemporaryQuery(
    {
      page: 1,
      size: 50,
      sort: 'id',
      typeSort: 'desc',
      year: values.year.getFullYear(),
      month: values.year.getMonth() + 1,
      year_plus_one: true,
      generate_holiday: true,
      ...(calendarIdSelected && { calendarId: calendarIdSelected }),
    },
    { skip: !data }
  );

  const DaysEvents: MyEvents[] = useGetHolidays();

  useEffect(() => {
    if (dataTemporary?.data) {
      const EventsDaysRecover: MyEvents[] = dataTemporary!
        .data!.filter((event) =>
          [initialPlanningPoint?.id, holiday?.id].some(
            (eventId) => eventId === event.postgraduate_day_type_id
          )
        )!
        .map((eventPIP) => ({
          start: new Date(eventPIP.start_date + 'T00:00:00'),
          end: new Date(eventPIP.start_date + 'T00:00:00'),
          title:
            eventPIP?.posgraduate_day_type?.pdt_description_day ||
            eventPIP?.holiday.pdt_description_day ||
            '-1',
          color:
            eventPIP?.posgraduate_day_type?.pdt_colour || eventPIP.holiday.pdt_colour || '#ff0000',
          id: eventPIP.id || '-1',
          typeId:
            eventPIP?.posgraduate_day_type?.pdt_keywork || eventPIP?.holiday.pdt_keywork || '-1',
          droppedOnAllDaySlot: false,
          addExtraWeek: Boolean(Number(eventPIP?.check_day_start_point_planning)),
        }));

      const EventInitialProgramRecover: MyEvents[] = dataTemporary!
        .data!.filter((event) => event.postgraduate_day_type_id === startProgram?.id)!
        .map((eventPIP) => {
          return {
            start: new Date(eventPIP.start_date + 'T00:00:00'),
            end: new Date(eventPIP.start_date + 'T00:00:00'),
            title:
              eventPIP?.name_period_temporary ||
              educationLevelsAvailables
                ?.find((e) => Number(e.id) === Number(eventPIP.education_level_id))
                ?.children?.find((e) => Number(e.id) === Number(eventPIP.education_level_carrer_id))
                ?.edu_name ||
              'Punto de inicio de programa',
            color: eventPIP?.posgraduate_day_type?.pdt_colour || '#ff0000',
            id: eventPIP.id || '-1',
            typeId: eventPIP?.posgraduate_day_type?.pdt_keywork || '-1',
            droppedOnAllDaySlot: false,
            eduLevelId: eventPIP.education_level_carrer_id || 0,
            eduLevelFatherId: eventPIP.education_level_id || 0,
            curriculumId: eventPIP.curriculum_id || 0,
            idTransactionPlanning: eventPIP.id_transaction_planning,
            status: eventPIP?.postgraduate_planning_status?.status_planning || '',
            namePeriod:
              eventPIP?.postgraduate_planning_additional_information?.name_period ||
              eventPIP?.name_period_temporary,
            hourhandsMove: Boolean(
              Number(eventPIP?.postgraduate_planning_additional_information?.hourhands_move)
            ),
            addExtraWeek: Boolean(Number(eventPIP?.check_day_start_point_planning)),
          };
        });

      dispatch(setInitialPrograms(EventInitialProgramRecover));

      dispatch(setInitialEventsPlanning([...DaysEvents, ...EventsDaysRecover]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataTemporary, data, DaysEvents, educationLevelsAvailables]);

  useEffect(() => {
    if (openSnackbar) {
      setTimeout(() => {
        setOpenSnackbar(false);
      }, 3000);
    }
  }, [openSnackbar]);

  useEffect(() => {
    setShowAlerts(false);
    if (firstRender) {
      setFirstRender(false);

      return;
    }
    setShowEditProcess(true);
    const addFunction = () => {
      if (
        initialPrograms.filter((ip) =>
          moment(ip.start).isBetween(
            moment(currentDateSelected),
            moment(currentDateSelected).add(12, 'months')
          )
        ).length === 0
      ) {
        setShowEditProcess(false);
        return;
      }

      addTemporaryInitialCareerPlanning({
        academicPlanningPayload: {
          planning_start_program_point_temp: [
            ...initialPrograms
              .filter((ip) =>
                moment(ip.start).isBetween(
                  moment(currentDateSelected).add(-1, 'months'), // permitir agregar hasta en un mes anterior
                  moment(currentDateSelected).add(12, 'months')
                )
              )
              .map((event) => {
                return {
                  start_date: event.start,
                  end_date: event.end,
                  postgraduate_day_type_id: startProgram?.id || '',
                  colour: event.color,
                  id: event.id.includes('generate') ? null : event.id,
                  education_level_id: event?.eduLevelFatherId,
                  curriculum_id: event?.curriculumId,
                  education_level_carrer_id: event?.eduLevelId,
                  hourhands_move: event?.hourhandsMove,
                  ...(currentDateSelected && {
                    planning_calendar_id: calendarIdSelected,
                  }),
                };
              }),
          ],
          ...(calendarIdSelected && { calendarId: calendarIdSelected }),
        },
      });

      setShowEditProcess(false);
    };
    const AddTemporaryInitialTimeout = setTimeout(addFunction, 3000);

    return () => {
      clearTimeout(AddTemporaryInitialTimeout);
    };
    //eslint-disable-next-line
  }, [initialPrograms.length]);

  useEffect(() => {
    dispatch(setEduLevelsAvailables(educationLevelsAvailables!));
    dispatch(
      setInitialPrograms(
        initialPrograms.map((event) => {
          const eduLevel = educationLevelsAvailables
            ?.find((eduLevel) => eduLevel.id === Number(event.eduLevelFatherId || 0))
            ?.children?.find((eduLevel) => eduLevel.id === Number(event.eduLevelId || 0));

          return {
            ...event,
            title: eduLevel?.edu_name || event.title,
          };
        })
      )
    );
    // eslint-disable-next-line
  }, [educationLevelsAvailables, initialPrograms.length]);

  const customEvent = ({ event: e }: EventProps<Event>) => (
    <Grid container>
      <Grid
        item
        container
        xs={12}
        alignItems="center"
        sx={{
          flexWrap: 'nowrap',
        }}
      >
        {(e as unknown as MyEvents).status && (e as unknown as MyEvents).status !== '' && (
          <Avatar
            sx={{
              mr: 1,
              width: 10,
              height: 10,
              backgroundColor:
                (e as unknown as MyEvents).status === 'pendiente'
                  ? theme.palette.warning.main
                  : theme.palette.success.main,
            }}
          >
            {''}
          </Avatar>
        )}{' '}
        <Typography variant="body2" color="black">
          {(e as unknown as MyEvents).namePeriod || e.title}
        </Typography>
      </Grid>
    </Grid>
  );

  return (
    <>
      {(isLoadingTemporary || isLoadingHolidays || isEducationLevelsLoading) && (
        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
          <Grid item xs={8} md={3}>
            <Skeleton variant="rectangular" width="100%" height="10vh" />
          </Grid>

          <Grid item xs={12} mt={2}>
            <Skeleton variant="rectangular" width="100%" height="20vh" />
          </Grid>

          {Array.from(new Array(6)).map((item, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Skeleton variant="rectangular" width="100%" height="400px" />
            </Grid>
          ))}
        </Grid>
      )}
      {!isLoadingTemporary &&
        !isLoadingHolidays &&
        initialEventsPlanning.length > 0 &&
        !isEducationLevelsLoading && (
          <form onSubmit={handleSubmit}>
            <Grid container direction="row" justifyContent="center" alignItems="center" gap={2}>
              <Grid item>
                {showAlerts && (
                  <SuccessAlert
                    message={t('posgraduate.success_added')}
                    handleDismiss={() => setShowAlerts(false)}
                  />
                )}
              </Grid>

              {!isInitProcess && (
                <Grid
                  item
                  container
                  xs={10}
                  spacing={2}
                  justifyContent="left"
                  alignItems="baseline"
                >
                  {/* <Grid item xs={12} md={6} lg={2}>
              <InputLabel>Inicio de cursos</InputLabel>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={lang}>
                <Stack spacing={3}>
                  <DatePicker
                    views={['year', 'month', 'day']}
                    minDate={new Date()}
                    value={values.year}
                    onChange={(newValue) => {
                      setFieldValue('year', newValue);
                    }}
                    renderInput={(params) => <TextField {...params} helperText={null} />}
                  />
                </Stack>
              </LocalizationProvider>
            </Grid> */}

                  <Grid item xs={12} md={6} lg={4}>
                    <InputLabel>{t('posgraduate.academic_unit')}</InputLabel>
                    {isEducationLevelsLoading ? (
                      <TextFieldFetching />
                    ) : (
                      <>
                        <Autocomplete
                          loadingText={t('posgraduate.academic_unit.loading')}
                          noOptionsText={t('posgraduate.academic_unit.no_data')}
                          options={educationLevelsAvailables!}
                          loading={isEducationLevelsLoading}
                          getOptionLabel={(option) => option?.edu_name}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={Boolean(errors.facultad && touched.facultad)}
                              placeholder={t('posgraduate.academic_unit.placeholder')}
                            />
                          )}
                          onChange={(e, value) => setFieldValue('facultad', value || '')}
                          fullWidth
                        />
                        {errors.facultad && touched.facultad && (
                          <FormHelperText error>{errors.facultad}</FormHelperText>
                        )}
                      </>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <InputLabel>{t('posgraduate.modality')}</InputLabel>
                    {isLoadingModalities ? (
                      <TextFieldFetching />
                    ) : (
                      <>
                        <Autocomplete
                          loadingText={t('posgraduate.modality.loading')}
                          noOptionsText={t('posgraduate.modality.no_data')}
                          options={modalities || []}
                          loading={isLoadingModalities}
                          getOptionLabel={(option) => option?.cat_name}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={Boolean(errors.modality && touched.modality)}
                              placeholder={t('posgraduate.modality.placeholder')}
                            />
                          )}
                          onChange={(e, value) =>
                            setFieldValue('modality', value || ({} as Modality))
                          }
                          fullWidth
                        />
                        {errors.modality && touched.modality && (
                          <FormHelperText error>{errors.modality}</FormHelperText>
                        )}
                      </>
                    )}
                  </Grid>
                  <Grid item xs={12} md={4} lg={4}>
                    <InputLabel>{t('posgraduate.program')}</InputLabel>
                    {isEducationLevelsLoading ? (
                      <TextFieldFetching />
                    ) : (
                      <>
                        <Autocomplete
                          multiple
                          loadingText={t('posgraduate.program.loading')}
                          noOptionsText={t('posgraduate.program.no_data')}
                          options={
                            educationLevelsAvailables
                              ?.find((facultad) => facultad.id === values.facultad.id)
                              ?.children?.filter((career) => (career.meshs?.length || 0) > 0)
                              ?.filter((career) =>
                                values.modality?.id
                                  ? Number(career.meshs?.[0]?.mes_modality_id) ===
                                    values.modality.id
                                  : true
                              ) || []
                          }
                          loading={isEducationLevelsLoading}
                          getOptionLabel={(option) => option?.edu_name}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={Boolean(errors.career && touched.career)}
                              placeholder={t('posgraduate.program.placeholder')}
                            />
                          )}
                          onChange={(e, values) =>
                            setFieldValue('career', values.map((value) => value) || [])
                          }
                          fullWidth
                        />
                        {errors.career && touched.career && (
                          <FormHelperText error>{errors.career}</FormHelperText>
                        )}
                      </>
                    )}
                  </Grid>
                </Grid>
              )}

              <Grid
                item
                container
                xs={10}
                spacing={2}
                justifyContent="right"
                alignItems="center"
                ref={ref}
              >
                <Grid item>
                  <Button
                    variant={isInitProcess ? 'text' : 'contained'}
                    color={'primary'}
                    endIcon={isInitProcess && <IconChevronDown />}
                    onClick={() => {
                      if (!isInitProcess) {
                        dispatch(
                          setInitialInfoPlanning({
                            ...initialInfoPlanning,
                            date: values.year,
                            facultad: values.facultad,
                            career: values.career,
                            curriculum: values.curriculum,
                          })
                        );
                      }
                      setInitProcess(!isInitProcess);
                    }}
                  >
                    {isInitProcess ? t('posgraduate.choose_career') : t('posgraduate.save')}
                  </Button>
                </Grid>
              </Grid>

              {isInitProcess && (
                <>
                  <Grid
                    item
                    container
                    mt={2}
                    xs={10}
                    spacing={2}
                    justifyContent="center"
                    alignItems="baseline"
                    ref={ref}
                  >
                    <Box>
                      <Typography align="center" variant="h3" component="div" mb={1}>
                        {initialInfoPlanning.facultad?.edu_name}
                      </Typography>
                      <Typography align="center" variant="h2" component="div" mb={4}>
                        {initialInfoPlanning.date.getFullYear()}
                      </Typography>
                      {initialInfoPlanning.career.map((program) => (
                        <Typography align="center" component="div">
                          {program.edu_name}
                        </Typography>
                      ))}
                    </Box>
                  </Grid>

                  {Boolean(initialInfoPlanning.career.length) && (
                    <Grid
                      item
                      container
                      md={10}
                      spacing={2}
                      justifyContent="left"
                      alignItems="center"
                      ml={2}
                    >
                      <Grid item sx={{ width: '100%' }}>
                        <InputLabel>{t('posgraduate.drag_ipa')}:</InputLabel>

                        <SymbologiesGroup
                          gridProps={{ sx: { backgroundColor: '#fff' } }}
                          simbologies={initialInfoPlanning.career.map((eventInfo) => {
                            return {
                              label: eventInfo.edu_name,
                              color: firstDay?.pdt_colour || '',
                              index: eventInfo.id,
                              typeId: firstDay?.pdt_keywork || '',
                              cantCourse:
                                Number(eventInfo.offer.number_periods_year_postgd) -
                                initialPrograms.filter((e) => e.title === eventInfo.edu_name)
                                  .length,
                            };
                          })}
                        />
                      </Grid>
                    </Grid>
                  )}

                  <Grid
                    item
                    container
                    xs={10}
                    spacing={2}
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    {showEditProcess && (
                      <Grid item>
                        <Typography variant="body1" color="initial" component={'span'}>
                          {t('posgraduate.editing')}
                        </Typography>
                      </Grid>
                    )}
                    {isLoadingAddTemporary && (
                      <Grid item>
                        <Typography variant="body1" color="initial" component={'span'}>
                          {t('posgraduate.saving')}
                        </Typography>
                        <LinearProgress />
                      </Grid>
                    )}
                    {false && (
                      <>
                        <Grid item>
                          <Button
                            variant="contained"
                            color={'primary'}
                            endIcon={<IconTemplate size={15} />}
                            onClick={() => history.push(`${path}/plantilla`)}
                          >
                            Crear plantilla
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            variant="outlined"
                            color={'primary'}
                            onClick={() => handleSetInitalPrograms([] as MyEvents[])}
                          >
                            Limpiar
                          </Button>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </>
              )}

              {/* {showEditProcess && (
          <Grid item container xs={10}>
            <Typography variant="body1" color="initial" component={'span'}>
              Editando ...
            </Typography>
          </Grid>
        )}
        {isLoadingAddTemporary && (
          <Grid item container xs={10}>
            <Typography variant="body1" color="initial" component={'span'}>
              Guardando
            </Typography>
            <LinearProgress />
          </Grid>
        )} */}

              {!isVisible && isInitProcess && (
                <Grid
                  item
                  container
                  md={6}
                  spacing={2}
                  justifyContent="flex-start"
                  alignItems="center"
                  mt={2}
                  ml={2}
                  sx={{
                    position: 'fixed',
                    left: !opened ? '120px' : `calc(${drawerWidth}px + 40px)`,
                    bottom: '10px',
                    zIndex: '10',
                  }}
                >
                  {showEditProcess && (
                    <Grid item>
                      <Typography variant="body1" color="initial" component={'span'}>
                        {t('posgraduate.editing')}
                      </Typography>
                    </Grid>
                  )}
                  {isLoadingAddTemporary && (
                    <Grid item>
                      <Typography variant="body1" color="initial" component={'span'}>
                        {t('posgraduate.saving')}
                      </Typography>
                      <LinearProgress />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <SymbologiesGroup
                      simbologies={initialInfoPlanning.career.map((eventInfo) => ({
                        label: eventInfo.edu_name,
                        color: firstDay?.pdt_colour || '',
                        index: eventInfo.id,
                        typeId: firstDay?.pdt_keywork || '',
                        cantCourse:
                          Number(eventInfo.offer.number_periods_year_postgd) -
                          initialPrograms.filter((e) => e.title === eventInfo.edu_name).length,
                      }))}
                    />
                  </Grid>
                </Grid>
              )}

              <Grid
                item
                container
                xs={12}
                spacing={2}
                justifyContent="center"
                alignItems="center"
                ref={ref}
                mt={2}
                // sx={{ border: '1px solid blue' }}
              >
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={lang}>
                  <DatePicker
                    views={['month', 'year']}
                    inputFormat="MMMM yyyy"
                    label={t('posgraduate.month_year')}
                    openTo="month"
                    onChange={(newValue) => {
                      setFieldValue('year', newValue);
                      dispatch(
                        setCurrentDateSelected(
                          moment(newValue).set('date', 1).toDate() ||
                            (currentDateSelected
                              ? parse(currentDateSelected!, 'dd-MMM-yyyy', new Date())
                              : new Date())
                        )
                      );
                    }}
                    renderInput={(params) => <TextField {...params} helperText={null} />}
                    value={values.year}
                  />
                </LocalizationProvider>
                {/* <Typography align="center" variant="h1" color={'GrayText'}>
              {format(values.year, 'MMMM', {
                locale: lang,
              })}
            </Typography> */}
              </Grid>

              <Transitions type="fade" in={!!selectedtEvent} position="bottom" direction={'left'}>
                <Stack
                  direction="column"
                  spacing={2}
                  sx={{
                    position: 'fixed',
                    right: '50px',
                    bottom: '10vh',
                    zIndex: '10',
                  }}
                >
                  <Grid
                    container
                    sx={{
                      boxShadow: '0px 0px 20px #CADDF2',
                      backgroundColor: '#fff',
                      padding: 1,
                      borderRadius: '10px 0 0 10px',
                      borderStyle: `solid`,
                      borderColor: `${selectedtEvent ? selectedtEvent?.color : '#fff'}`,
                      borderWidth: '0 8px 0 0',
                    }}
                  >
                    <Typography align="right" color="inherit" variant="h5">
                      {selectedtEvent?.namePeriod || selectedtEvent?.title}
                    </Typography>
                  </Grid>
                  {selectedtEvent?.status && selectedtEvent?.status !== '' && (
                    <Grid container justifyContent="flex-end" alignItems="center">
                      <Chip
                        label={selectedtEvent?.status}
                        style={{
                          backgroundColor:
                            selectedtEvent?.status === 'pendiente'
                              ? theme.palette.warning.main
                              : theme.palette.success.main,
                          borderColor:
                            selectedtEvent?.status === 'pendiente'
                              ? theme.palette.warning.main
                              : theme.palette.success.main,
                          color: selectedtEvent?.status === 'pendiente' ? '#000' : '#fff',
                        }}
                        variant="outlined"
                      />
                    </Grid>
                  )}
                  {selectedtEvent?.status !== 'ejecutada' && (
                    <Grid container justifyContent="flex-end" alignItems="center">
                      <IconButton
                        aria-label="delete"
                        color="primary"
                        size="medium"
                        sx={{ boxShadow: '0px 0px 20px #CADDF2', backgroundColor: '#fff' }}
                        onClick={() => handleDeleteEvent(selectedtEvent.id)}
                      >
                        <IconTrash />
                      </IconButton>
                    </Grid>
                  )}
                  <Grid container justifyContent="flex-end" alignItems="center">
                    <IconButton
                      aria-label="goTo"
                      color="primary"
                      size="medium"
                      sx={{ boxShadow: '0px 0px 20px #CADDF2', backgroundColor: '#fff' }}
                      onClick={() =>
                        history.push(`${path}/${selectedtEvent.id}/planeacion-carrera`)
                      }
                    >
                      <IconCornerDownRight />
                    </IconButton>
                  </Grid>
                  <Grid container justifyContent="flex-end" alignItems="center">
                    <IconButton
                      aria-label="delete"
                      color="primary"
                      size="medium"
                      sx={{ boxShadow: '0px 0px 20px #CADDF2', backgroundColor: '#fff' }}
                      onClick={() => setSelectedEvent(null!)}
                    >
                      <IconX />
                    </IconButton>
                  </Grid>
                </Stack>
              </Transitions>

              {errors && (
                <FormHelperText error>
                  {Object.keys(errors).map((error) => errors?.[error as keyof typeof errors])}
                </FormHelperText>
              )}

              <Grid
                item
                container
                md={10}
                spacing={4}
                justifyContent="flex-end"
                alignItems="center"
                mt={2}
              >
                <ToggleButtonGroup
                  value={calendarView}
                  onChange={handleChangeCalendarView}
                  exclusive
                >
                  <ToggleButton value="month">{t('posgraduate.month')}</ToggleButton>
                  <ToggleButton value="year">{t('posgraduate.year')}</ToggleButton>
                </ToggleButtonGroup>
              </Grid>

              <Grid
                item
                container
                md={10}
                spacing={4}
                justifyContent="center"
                alignItems="center"
                mt={4}
              >
                {Array.from(new Array(calendarView === 'month' ? 1 : 12)).map((e, index) => (
                  <Grid
                    item
                    xs={12}
                    md={calendarView === 'month' ? 12 : 6}
                    lg={calendarView === 'month' ? 12 : 4}
                    key={index}
                  >
                    <DnDCalendar
                      views={['month']}
                      events={initialPrograms}
                      selectable
                      dayPropGetter={(day) => {
                        var style = {
                          backgroundColor: '#E6E6E6',
                          display: 'block',
                        };
                        var styleFDS = {
                          backgroundColor: `${holiday?.pdt_colour}60`,
                          display: 'block',
                        };
                        var styleDayPlanning = {
                          backgroundColor: `${initialPlanningPoint?.pdt_colour}60`,
                          display: 'block',
                        };
                        return {
                          style: initialEventsPlanning
                            .filter((event) => event.typeId === 'PIP')
                            .some(
                              (ipaEvent) =>
                                moment(ipaEvent.start).format('YYYY-MM-DD') ===
                                moment(day).format('YYYY-MM-DD')
                            )
                            ? styleDayPlanning
                            : initialEventsPlanning
                                .filter((event) => event.typeId === 'FDS')
                                .some(
                                  (ipaEvent) =>
                                    moment(ipaEvent.start).format('YYYY-MM-DD') ===
                                    moment(day).format('YYYY-MM-DD')
                                )
                            ? styleFDS
                            : style,
                          // style: style,
                        };
                      }}
                      key={index}
                      className={calendarView === 'year' ? classes.calendar : ''}
                      date={moment(new Date(values.year)).add(index, 'month').toDate()}
                      localizer={localizer}
                      onNavigate={(date) => setFieldValue('year', date)}
                      startAccessor="start"
                      endAccessor="end"
                      style={{ height: calendarView === 'month' ? 500 : 400 }}
                      onSelectEvent={(e) => {
                        setSelectedEvent(e as unknown as MyEvents);
                      }}
                      onSelectSlot={() => setSelectedEvent(null!)}
                      onDoubleClickEvent={() => {
                        const program = initialPrograms.find((e) => e.id === selectedtEvent?.id);

                        if (program?.idTransactionPlanning) {
                          history.push(`${path}/${selectedtEvent.id}/planeacion-carrera`);
                        } else {
                          setOpenSnackbar(true);
                        }
                      }}
                      onDropFromOutside={(event) => {
                        const isPip = initialEventsPlanning
                          .filter((event) => event.typeId === 'PIP')
                          .find((ipaEvent) => moment(ipaEvent.start).isSame(moment(event.start)));

                        const eduLevelFather = educationLevelsAvailables?.find((eduLevel) =>
                          eduLevel.children.find(
                            (children) => children.edu_name === dragEvent.title
                          )
                        );

                        const eduLevel = eduLevelFather?.children.find(
                          (children) => children.edu_name === dragEvent.title
                        );

                        if (isPip) {
                          handleSetInitalPrograms([
                            ...initialPrograms,
                            {
                              start: new Date(event.start),
                              end: new Date(event.start),
                              color: dragEvent.color!,
                              id: `${dragEvent.id}-${Date.now()}|generate`,
                              title: dragEvent.title!,
                              droppedOnAllDaySlot: true,
                              typeId: dragEvent.typeId!,
                              eduLevelId: eduLevel?.id || 0,
                              curriculumId: eduLevel?.meshs?.[0]?.id || 0,
                              eduLevelFatherId: eduLevelFather?.id || 0,
                              hourhandsMove: holidaysMove,
                            },
                          ]);
                        }
                      }}
                      eventPropGetter={(event) => {
                        var backgroundColor = (event as unknown as MyEvents)?.color;
                        var style = {
                          backgroundColor: backgroundColor,
                          borderRadius: '0px',
                          opacity: 0.8,
                          color: 'black',
                          border: '0px',
                          display: 'block',
                        };
                        return {
                          style: style,
                        };
                      }}
                      onEventDrop={moveEvent}
                      messages={{
                        next: t('posgraduate.next'),
                        previous: t('posgraduate.prev'),
                        today: t('posgraduate.today'),
                        month: t('posgraduate.month'),
                        week: t('posgraduate.week'),
                        day: t('posgraduate.day'),
                      }}
                      components={{
                        event: customEvent,
                      }}
                      onShowMore={(events, date) => {
                        dispatch(
                          setShowEventsDialog({
                            open: true,
                            events: events as unknown as MyEvents[],
                            date,
                          })
                        );
                        return;
                      }}
                      culture={langKey as string}
                    />
                  </Grid>
                ))}
              </Grid>

              <Grid
                item
                container
                alignItems="center"
                justifyContent="flex-end"
                spacing={2}
                sx={{ mt: 1 }}
              >
                <Grid item>
                  <Button
                    variant="outlined"
                    size="large"
                    color="primary"
                    onClick={() => history.push('/planeacion-academica-posgrado')}
                  >
                    {t('posgraduate.back')}
                  </Button>
                </Grid>
                {/* <Grid item>
                <Button type="submit" variant="contained" size="large" color="primary">
                  Enviar planificación
                </Button>
              </Grid> */}
              </Grid>
            </Grid>
          </form>
        )}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        sx={{ position: 'fixed', top: '100px' }}
        // message="Debes tener minimo 12 puntos de inicio"
        action={
          <>
            <Button color="secondary" size="small" onClick={() => setOpenSnackbar(false)}>
              {t('posgraduate.catched')}
            </Button>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setOpenSnackbar(false)}
            >
              <IconX fontSize="small" />
            </IconButton>
          </>
        }
      >
        <Alert onClose={() => setOpenSnackbar(false)} severity="error" sx={{ width: '100%' }}>
          {t('posgraduate.loading_wait')}
        </Alert>
      </Snackbar>

      {/* Error Snackbar */}
      <Snackbar
        open={openErrorSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenErrorSnackbar(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        sx={{ position: 'fixed', top: '100px' }}
        // message="Debes tener minimo 12 puntos de inicio"
        action={
          <>
            <Button color="secondary" size="small" onClick={() => setOpenErrorSnackbar(false)}>
              {t('posgraduate.catched')}
            </Button>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setOpenErrorSnackbar(false)}
            >
              <IconX fontSize="small" />
            </IconButton>
          </>
        }
      >
        <Alert onClose={() => setOpenErrorSnackbar(false)} severity="error" sx={{ width: '100%' }}>
          {errorAddTemporary}
        </Alert>
      </Snackbar>

      {showEventsDialog?.open && (
        <DayEventsDialog
          handleClose={handleCloseEventsDialog}
          events={showEventsDialog.events}
          date={showEventsDialog.date!}
          handleDeletePlanning={handleDeleteEvent}
        />
      )}
    </>
  );
};

export default CalendarFormChooseInitCareer;
