import {
  Autocomplete,
  Button,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { useMemo, useState } from 'react';
import { useAppSelector } from '../../../../../hooks/useRedux';
import MainCard from '../../../../shared/components/cards/MainCard';
import SearchPaginatedTable from '../../../../shared/components/tables/SearchPaginatedTable';
import { useUseGetActivitiesbyCourseTeacherQuery } from '../../slices/virtualClassroomTeacherApiSlice';
import FeedOutlinedIcon from '@material-ui/icons/FeedOutlined';
import moment from 'moment';
import ListAltIcon from '@material-ui/icons/ListAltOutlined';

import { useHistory } from 'react-router-dom';
import { IconBook2, IconClipboardText, IconMessage } from '@tabler/icons';
import { useTranslation } from 'react-i18next';
function ViewNotesScreen() {
  const [page, setPage] = useState<number>(1);

  const [perPage, setPerPage] = useState<number>(5);

  const [search, setSearch] = useState<string>('');

  const {
    course: { courseId },
  } = useAppSelector((state) => state.virtualClassroomTeacherShared);
  const {
    userInfo: { collaboratorInfo },
  } = useAppSelector((state) => state.auth);

  const { data, isLoading, error, isFetching } = useUseGetActivitiesbyCourseTeacherQuery({
    collaboratorID: collaboratorInfo?.id!,
    courseId: courseId,
    size: perPage,
    page: page,
  });

  const { t } = useTranslation();
  const History = useHistory();

  function NavigatePage(activityId: number) {
    const path = window.location.pathname;

    History.push(`${path}/activities/${activityId}`);
  }

  const dataFetch = useMemo(() => {
    return (Array.isArray(data?.data!) ? data?.data! : [])?.map(
      (e) =>
        ({
          item: e?.type_activity?.cat_name!,
          Nombre: e?.act_description!,
          Fecha_Enviada: moment(e?.course_activity_one?.cact_start_date!).format('L'),
          Fecha_calficacion: moment(e?.course_activity_one?.cact_end_date!).format('L'),
          accion: e?.id!,
        } || [])
    );
  }, [data]);

  console.log(data, dataFetch.length);
  return (
    <MainCard
      title={t('teacher_virtual_classroom.califications.main.header.califications')}
      sx={{
        borderTopLeftRadius: '0px',
        borderTopRightRadius: '0px',
      }}
    >
      <Grid display={'flex'} justifyContent="space-around" alignItems={'center'}>
        <Typography fontWeight="bold" color={'primary'} variant="body1">
          {t('teacher_virtual_classroom.califications.filters.filter')}
        </Typography>
        <Grid display={'flex'} alignItems={'center'}>
          <Typography fontWeight="bold" color={'primary'} variant="body1">
            {t('teacher_virtual_classroom.califications.filters.partial')}
          </Typography>
          <Autocomplete
            options={[]}
            fullWidth
            style={{ width: '160px', marginLeft: '8px' }}
            renderInput={(props) => (
              <TextField
                {...props}
                label={t('teacher_virtual_classroom.califications.filters.partial')}
              />
            )}
          />
        </Grid>
        <Grid display={'flex'} alignItems={'center'}>
          <Typography fontWeight="bold" color={'primary'} variant="body1">
            {t('teacher_virtual_classroom.califications.filters.state')}
          </Typography>
          <Autocomplete
            style={{ width: '160px', marginLeft: '8px' }}
            options={[]}
            fullWidth
            renderInput={(props) => (
              <TextField
                {...props}
                label={t('teacher_virtual_classroom.califications.filters.activity')}
              />
            )}
          />
        </Grid>
        <Button variant="contained">
          {t('teacher_virtual_classroom.califications.calificate.button.search')}{' '}
        </Button>
      </Grid>

      <SearchPaginatedTable
        headers={{
          item: t('teacher_virtual_classroom.califications.table.item.item'),
          Nombre: t('teacher_virtual_classroom.califications.table.item.Nombre'),
          Fecha_Enviada: t('teacher_virtual_classroom.califications.table.item.fechaEnviada'),
          Fecha_calficacion: t(
            'teacher_virtual_classroom.califications.table.item.fechaCalificacion'
          ),
          accion: '',
        }}
        total={data?.total! || 0}
        data={dataFetch}
        searchPlacehoder="Buscar tarea"
        keyExtractor={(item) => item.accion.toString()}
        setPage={setPage}
        perPage={perPage}
        page={page}
        isFetching={isFetching}
        isLoading={isLoading}
        error={error}
        setPerPage={setPerPage}
        search={search}
        setSearch={setSearch}
        numHeader={5}
        ActionButtons={<></>}
        showFilter={false}
        perPageBox={true}
        paginationBox={true}
        customDataCellsProperties={{
          item: {
            align: 'center',
          },
          Nombre: {
            align: 'center',
            width: '20%',
          },
          Fecha_Enviada: {
            align: 'center',
          },
          Fecha_calficacion: {
            align: 'center',
          },
          accion: {
            align: 'center',
          },
        }}
        customRenderers={{
          accion: (row) => (
            <Tooltip title={t('teacher_virtual_classroom.califications.table.tooltip')}>
              <IconButton onClick={() => NavigatePage(row.accion)}>
                <FeedOutlinedIcon />
              </IconButton>
            </Tooltip>
          ),
          item: (row) =>
            row.item === 'Evaluacion' ? (
              <IconClipboardText />
            ) : row.item === 'Tarea individual' ? (
              <IconBook2 />
            ) : row.item === 'Foros' ? (
              <ListAltIcon />
            ) : (
              <IconMessage />
            ),
        }}
      />
    </MainCard>
  );
}

export default ViewNotesScreen;
