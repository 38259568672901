import { mainApi } from '../../../../../../api/mainApi';
import { middlewareApi } from '../../../../../middleware/middlewareApi';
import { IResponse } from '../../../../../shared/interfaces/response.interface';
import { Courses } from '../interfaces/coursesListAll.interface';
import { FormsData } from '../interfaces/forms.interfaces';
import { FormsPayload } from '../interfaces/formsPayload.interface';
import { FormularyByID } from '../interfaces/formularyById.interface';
import { IForcmUser } from '../interfaces/formUser.interface';
import { ResultsEva } from '../interfaces/results.interface';
import { TyepDocumentForm } from '../interfaces/typeDocuments.interface';

export const formsTags = middlewareApi.enhanceEndpoints({
  addTagTypes: ['FormsList'],
});

export const formsApi = formsTags.injectEndpoints({
  endpoints: (builder) => ({
    getFormsList: builder.query<
      IResponse<FormsData>,
      {
        page: number;
        perPage: number;
        search: string;
        sort?: string;
        typeSort?: string;
      }
    >({
      queryFn: async ({ page, perPage, search, sort, typeSort }, { dispatch }) => {
        try {
          const { data } = await mainApi.get<IResponse<FormsData>>('/eva/formulary', {
            params: {
              page,
              size: perPage,
              search,
              ...(sort && { sort }),
              ...(typeSort && { type_sort: typeSort }),
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),
    addForm: builder.mutation<any, FormsPayload>({
      queryFn: async (body, { dispatch }) => {
        try {
          const { data } = await mainApi.post<any>('/eva/formulary', body);

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: ['FormsList'],
    }),
    editForm: builder.mutation<
      any,
      {
        id: number | string;
        body: FormsPayload;
      }
    >({
      queryFn: async ({ id, body }, { dispatch }) => {
        try {
          const { data } = await mainApi.put<any>(`/eva/formulary/${id}`, body);

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: ['FormsList'],
    }),
    getCoursesListAll: builder.query<
      Courses[],
      {
        periodId: number | string;
        educationLevelId: number | string;
      }
    >({
      queryFn: async ({ periodId, educationLevelId }, { dispatch }) => {
        try {
          const { data } = await mainApi.get<Courses[]>(
            `/periods/${periodId}/education-levels/${educationLevelId}/courses?user_profile_id=1`,
            {
              params: {
                data: 'all',
              },
            }
          );

          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
    }),
    getTypeDocumentListAll: builder.query<TyepDocumentForm[], {}>({
      queryFn: async () => {
        try {
          const { data } = await mainApi.get<TyepDocumentForm[]>(
            `/eva/formulary/form-type-document`,
            {
              params: {
                data: 'all',
              },
            }
          );

          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
    }),
    getFormsUserList: builder.query<
      IResponse<IForcmUser>,
      {
        page: number;
        perPage: number;
        search: string;
        sort?: string;
        typeSort?: string;
        start_date?: string;
        end_date?: string;
      }
    >({
      queryFn: async (
        { page, perPage, search, sort, typeSort, end_date, start_date },
        { dispatch }
      ) => {
        try {
          const { data } = await mainApi.get<IResponse<IForcmUser>>('/eva/formulary/list-results', {
            params: {
              page,
              size: perPage,
              search,
              end_date,
              start_date,
              ...(sort && { sort }),
              ...(typeSort && { type_sort: typeSort }),
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    acceptEvaluation: builder.mutation<any, { id: number | string }>({
      queryFn: async ({ id }, { dispatch }) => {
        try {
          const { data } = await mainApi.post<any>(`/eva/formulary/accept-results/${id}`);
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    getResultsEva: builder.query<
      ResultsEva,
      {
        page: number;
        perPage: number;
        search: string;
        sort?: string;
        typeSort?: string;
        start_date?: string;
        end_date?: string;
        period_id?: number | string;
        education_level_id?: number | string;
        eva_types_id?: number | string;
        evaluated_id?: number | string;
        eva_scale_representative_id?: number | string;
        rubrica_id?: number | string;
        date_accept_result?: boolean;
      }
    >({
      queryFn: async (
        {
          page,
          perPage,
          search,
          sort,
          typeSort,
          end_date,
          start_date,
          education_level_id,
          eva_types_id,
          evaluated_id,
          period_id,
          rubrica_id,
          date_accept_result,
          eva_scale_representative_id,
        },
        { dispatch }
      ) => {
        try {
          const { data } = await mainApi.get<ResultsEva>('/eva/formulary/results-evaluations', {
            params: {
              page,
              size: perPage,
              search,
              end_date,
              start_date,
              ...(sort && { sort }),
              ...(typeSort && { type_sort: typeSort }),
              ...(education_level_id && { education_level_id }),
              ...(eva_types_id && { eva_types_id }),
              ...(evaluated_id && { evaluated_id }),
              ...(rubrica_id && { rubrica_id }),
              ...(period_id && { period_id }),
              ...(date_accept_result && { date_accept_result }),
              ...(eva_scale_representative_id && { eva_scale_representative_id }),
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: [{ type: 'FormsList', id: 'LIST' }],
    }),

    getFormById: builder.query<FormularyByID, { id: number | string }>({
      queryFn: async ({ id }, { dispatch }) => {
        try {
          const { data } = await mainApi.get<FormularyByID>(`/eva/formulary/${id}`);

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),
    deleteFormById: builder.mutation<any, { id: number | string }>({
      queryFn: async ({ id }, { dispatch }) => {
        try {
          const { data } = await mainApi.delete<any>(`/eva/formulary/${id}`);

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: ['FormsList'],
    }),
  }),
});

export const {
  useGetFormsListQuery,
  useAddFormMutation,
  useLazyGetCoursesListAllQuery,
  useGetTypeDocumentListAllQuery,
  useGetFormsUserListQuery,
  useAcceptEvaluationMutation,
  useGetResultsEvaQuery,
  useGetFormByIdQuery,
  useLazyGetFormByIdQuery,
  useDeleteFormByIdMutation,
  useEditFormMutation,
} = formsApi;
