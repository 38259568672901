import { useReactToPrint } from 'react-to-print';
import { Button, Dialog, Grid, TextField, Typography, Autocomplete } from '@material-ui/core';
import { forwardRef, useRef, useState } from 'react';
import BarCode from 'react-barcode';
import PerfectScrollbar from 'react-perfect-scrollbar';

interface BarCodeDialogProps {
  title?: string;
  value: string;
  classification: string;
  author?: string;
  handleClose: () => void;
}

const comboOptions = [
  {
    label: 'Ambos',
    value: 'ambos',
  },
  {
    label: 'Marbete',
    value: 'mambrete',
  },
  {
    label: 'Código de barras',
    value: 'barcode',
  },
];

const replaceArticles = (value: string) => {
  const articles = [
    'el',
    'la',
    'los',
    'las',
    'lo',
    'El',
    'La',
    'Los',
    'Las',
    'Lo',
    'EL',
    'LA',
    'LOS',
    'LAS',
    'LO',
  ];

  let newValue = value;
  articles.forEach((article) => {
    newValue = newValue.replaceAll(article, '');
  });
  return newValue;
};

const BarCodeDialog = ({
  title,
  author,
  value,
  classification,
  handleClose,
}: BarCodeDialogProps) => {
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(1);
  const [toPrint, setToPrint] = useState('ambos');

  const getValueWithoutNumeration = () => {
    const splittedValue = value.split('-');
    if (splittedValue[splittedValue.length - 1] === '0') {
      splittedValue.pop();
    }
    return splittedValue.join('-');
  };

  const getCode = () => {
    let code = '';
    const titleFirstLetter = replaceArticles(title || '')?.[0]?.toLowerCase() || '';
    if (author && author !== '') {
      code = `${author?.slice(0, 3)}${titleFirstLetter}`;
    }
    return code;
  };

  const ComponentToPrint = forwardRef((props, ref) => {
    return (
      <div ref={ref as any}>
        {Array.from({ length: to - from + 1 }).map((_, index) => (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
              flexDirection: 'column',
              height: '4cm',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                flexDirection: 'column',
                padding: 2,
                overflow: 'hidden',
                height: '1.8cm',
              }}
            >
              <p
                style={{
                  margin: 0,
                  textAlign: 'center',
                  fontSize: '11px',
                  fontWeight: 'bold',
                  color: 'black',
                  lineHeight: 1.3,
                }}
              >
                ECOTEC-BIBLIO
              </p>
              <p
                style={{
                  margin: 0,
                  textAlign: 'center',
                  fontSize: '11px',
                  fontWeight: 'bold',
                  color: 'black',
                  lineHeight: 1.3,
                }}
              >
                {getCode()}
              </p>
              <p
                style={{
                  margin: 0,
                  textAlign: 'center',
                  fontSize: '11px',
                  fontWeight: 'bold',
                  color: 'black',
                  lineHeight: 1.3,
                }}
              >
                {classification}
              </p>
              <p
                style={{
                  margin: 0,
                  textAlign: 'center',
                  fontSize: '11px',
                  fontWeight: 'bold',
                  color: 'black',
                  lineHeight: 1.3,
                }}
              >
                Ej.{from + index - 1}
              </p>
              <p
                style={{
                  margin: 0,
                  textAlign: 'center',
                  fontSize: '11px',
                  fontWeight: 'bold',
                  color: 'black',
                  lineHeight: 1.3,
                }}
              >
                {getValueWithoutNumeration()}-{from + index - 1}
              </p>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                flexDirection: 'column',
                height: '1.8cm',
                overflow: 'hidden',
              }}
            >
              <p style={{ margin: 0, textAlign: 'center', color: 'black' }}>UNIVERSIDAD ECOTEC</p>
              <BarCode
                value={`${getValueWithoutNumeration()}-${from + index - 1}`}
                width={1.5}
                height={50}
              />
            </div>
          </div>
        ))}
      </div>
    );
  });

  const OnlyMambrete = forwardRef((props, ref) => {
    return (
      <div ref={ref as any}>
        {Array.from({ length: to - from + 1 }).map((_, index) => (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
              flexDirection: 'column',
              height: '2cm',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                flexDirection: 'column',
                padding: 2,
                overflow: 'hidden',
                height: '1.8cm',
              }}
            >
              <p
                style={{
                  margin: 0,
                  textAlign: 'center',
                  fontSize: '11px',
                  fontWeight: 'bold',
                  color: 'black',
                  lineHeight: 1.3,
                }}
              >
                ECOTEC-BIBLIO
              </p>
              <p
                style={{
                  margin: 0,
                  textAlign: 'center',
                  fontSize: '11px',
                  fontWeight: 'bold',
                  color: 'black',
                  lineHeight: 1.3,
                }}
              >
                {getCode()}
              </p>
              <p
                style={{
                  margin: 0,
                  textAlign: 'center',
                  fontSize: '11px',
                  fontWeight: 'bold',
                  color: 'black',
                  lineHeight: 1.3,
                }}
              >
                {classification}
              </p>
              <p
                style={{
                  margin: 0,
                  textAlign: 'center',
                  fontSize: '11px',
                  fontWeight: 'bold',
                  color: 'black',
                  lineHeight: 1.3,
                }}
              >
                Ej.{from + index - 1}
              </p>
              <p
                style={{
                  margin: 0,
                  textAlign: 'center',
                  fontSize: '11px',
                  fontWeight: 'bold',
                  color: 'black',
                  lineHeight: 1.3,
                }}
              >
                {getValueWithoutNumeration()}-{from + index - 1}
              </p>
            </div>
          </div>
        ))}
      </div>
    );
  });

  const OnlyBarcode = forwardRef((props, ref) => {
    return (
      <div ref={ref as any}>
        {Array.from({ length: to - from + 1 }).map((_, index) => (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
              flexDirection: 'column',
              height: '2cm',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                flexDirection: 'column',
                height: '1.8cm',
                overflow: 'hidden',
              }}
            >
              <p style={{ margin: 0, textAlign: 'center', color: 'black' }}>UNIVERSIDAD ECOTEC</p>
              <BarCode
                value={`${getValueWithoutNumeration()}-${from + index - 1}`}
                width={1.5}
                height={50}
              />
            </div>
          </div>
        ))}
      </div>
    );
  });

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef?.current || null,
  });

  return (
    <Dialog
      onClose={handleClose}
      open
      sx={{
        '& .MuiDialog-paper': {
          padding: 2,
        },
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h3" align="center" color="primary">
            Código de barras - {title}
          </Typography>
        </Grid>
        <Grid item container justifyContent="center" xs={12} spacing={2}>
          <Grid item xs={12} sm={3}>
            <TextField
              label="Desde"
              type="number"
              value={from}
              inputProps={{
                min: 1,
                max: to,
              }}
              fullWidth
              onChange={(e) => setFrom(Number(e.target.value))}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              label="Hasta"
              type="number"
              value={to}
              inputProps={{
                min: from,
              }}
              fullWidth
              onChange={(e) => setTo(Number(e.target.value))}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              options={comboOptions}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => <TextField {...params} label="Opciones" />}
              value={comboOptions.find((option) => option.value === toPrint) || comboOptions[0]}
              onChange={(e, value) => setToPrint(value?.value || 'ambos')}
            />
          </Grid>
        </Grid>
        <Grid
          item
          container
          justifyContent="center"
          xs={12}
          sx={{
            height: '4.5cm',
          }}
        >
          <PerfectScrollbar>
            {toPrint === 'ambos' && <ComponentToPrint ref={componentRef} />}
            {toPrint === 'barcode' && <OnlyBarcode ref={componentRef} />}
            {toPrint === 'mambrete' && <OnlyMambrete ref={componentRef} />}
          </PerfectScrollbar>
        </Grid>
        <Grid item container justifyContent="center" xs={12}>
          <Button variant="contained" color="primary" onClick={handlePrint}>
            Imprimir
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default BarCodeDialog;
