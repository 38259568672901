import { useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import Stack from '@material-ui/core/Stack';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import useTheme from '@material-ui/core/styles/useTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { useTranslation } from 'react-i18next';

import { IconChevronUp, IconChevronDown } from '@tabler/icons';

import PerfectScrollbar from 'react-perfect-scrollbar';
import { renderSkeletonTable } from '../../../shared/helpers/render';
import { useAppSelector } from '../../../../hooks/useRedux';
import { useGetStudentDebtsQuery } from '../../payments-history/slices/paymentsApiSlice';
import { Button } from '@material-ui/core';
import useFormatDate from '../../../shared/hooks/useFormatDate';

// style constant
const useStyles = makeStyles((theme) => ({
  scrollRoot: {
    '& >div.ps__rail-y': {
      '& :hover': {
        backgroundColor: 'black',
      },
    },

    '& >div.ps__rail-y > div.ps__thumb-y': {
      backgroundColor: theme.palette.primary[200],
      '& :hover': {
        backgroundColor: 'black',
      },
    },
    // '& .scrollbar-container .ps__rail-y:hover > .ps__thumb-y, .scrollbar-container .ps__rail-y:focus > .ps__thumb-y, .scrollbar-container .ps__rail-y.ps--clicking .ps__thumb-y':
    //   { backgroundColor: 'red' },
  },
}));

// const DUMMY_DATA = [
//   {
//     id: 1,
//     quota: 'Tercera matrícula',
//     deadline: 'vencío 30 de marzo',
//     amount: '1300.00',
//     detail1: 'Detalle 1',
//     detail2: 'Detalle 2',
//   },
// ];

// const generateDummyData = () => {
//   const data: typeof DUMMY_DATA = [];

//   for (let index = 0; index < 10; index++) {
//     data.push({
//       id: index,
//       quota: 'Tercera matrícula',
//       deadline: `Materia ${index}`,
//       amount: '1300.00',
//       detail1: 'Detalle 1',
//       detail2: 'Detalle 2',
//     });
//   }

//   return data;
// };

interface IItemList {
  payment: IPayment;
}
interface IPayment {
  id: number;
  description: string;
  amount: string;
  endDate: string;
}
const ItemList = ({ payment }: IItemList) => {
  const theme = useTheme();

  const { defaultConfiguration } = useAppSelector((state) => state.access);

  const { formatDate } = useFormatDate();

  const [openPayment, setOpenPayment] = useState(false);

  const toogleOpenPayment = () => {
    setOpenPayment(!openPayment);
  };

  return (
    <Grid
      item
      key={payment.id.toString()}
      borderBottom={`1px solid ${theme.palette.primary[200]}`}
      py={1}
    >
      <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="flex-start">
        <Box>
          <Typography>{payment.description}</Typography>
          <FormHelperText>{formatDate(payment.endDate)}</FormHelperText>
        </Box>

        <Box>
          <Stack spacing={1} direction="row">
            <Typography>
              {defaultConfiguration.currency_symbol}
              {payment.amount}
            </Typography>
            {false && (
              <IconButton onClick={toogleOpenPayment} component="span" size="small">
                {openPayment ? <IconChevronUp size={15} /> : <IconChevronDown size={15} />}
              </IconButton>
            )}
          </Stack>
        </Box>
      </Stack>

      {false && (
        <Collapse in={openPayment} timeout="auto" unmountOnExit sx={{ width: '100%' }}>
          <Stack>
            <p>Detalle 1</p>
            <p>Detalle 2</p>
          </Stack>
        </Collapse>
      )}
    </Grid>
  );
};

const LastPayments = () => {
  const theme = useTheme();
  const classes = useStyles();

  const history = useHistory();

  const { currentRecordStudent, currentStudent } = useAppSelector((state) => state.access);

  const { t } = useTranslation();

  const {
    data,
    // isFetching,
    isLoading,
    // error,
    // refetch,
  } = useGetStudentDebtsQuery(
    {
      studentId: currentStudent?.id!,
      page: 1,
      perPage: 10,
      search: '',
      ...(currentRecordStudent && { student_record: currentRecordStudent?.id }),
    },
    { skip: !currentRecordStudent }
  );

  const datafetch: IPayment[] | undefined = useMemo(() => {
    return data?.data?.map((dt) => {
      return {
        id: +dt.fees_identifier,
        description: dt.fees_name_identifier,
        amount: dt.fees_balance,
        endDate: dt.fees_expiration_date,
      };
    });
  }, [data]);

  const itemList = (payment: IPayment) => {
    return <ItemList payment={payment} key={payment!.id.toString()} />;
  };

  const renderPaymentList = () => {
    return <>{datafetch?.map(itemList)}</>;
  };

  const handleGoToPaymentsHistory = () => history.push('/estado-de-cuenta');

  return (
    <Box
      sx={{
        border: `1px solid ${theme.palette.primary[200]}`,
        padding: 2,
        height: '100%',
        borderRadius: '12px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography component="h3" variant="h3">
          {t('students.dashboard.payments.title')}
        </Typography>
        <Button onClick={handleGoToPaymentsHistory}>
          {t('students.dashboard.payments.title.button')}
        </Button>
      </Box>

      <>
        {isLoading ? (
          renderSkeletonTable(1, 2)
        ) : (
          <Grid container pt={2} pb={1}>
            <Grid
              item
              xs={12}
              sx={{
                maxHeight: 140,
                overflow: 'hidden',
                [theme.breakpoints.down('md')]: {
                  maxHeight: 280,
                },
              }}
            >
              <PerfectScrollbar component={'div'} className={classes.scrollRoot}>
                <Stack spacing={2}>
                  {datafetch ? (
                    renderPaymentList()
                  ) : (
                    <Typography component={'p'} align="center">
                      {t('students.dashboard.payments.no_data')}
                    </Typography>
                  )}
                </Stack>
              </PerfectScrollbar>
            </Grid>
          </Grid>
        )}
      </>
    </Box>
  );
};

export default LastPayments;
