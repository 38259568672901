import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Autocomplete,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  TextField,
  Skeleton,
  FormControlLabel,
  Switch,
  Typography,
} from '@material-ui/core';
import { useFormik } from 'formik';

import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import InputLabel from '../../../shared/components/forms/InputLabel';
import SubjectCardNew from './SubjectCardNewPeriod';
import {
  useLazyGetStudentInfoQuery,
  useGetCoursesByPeriodChangeQuery,
  useAddNewPeriodStudentMutation,
} from '../slices/ChangePeriodApiSlice';
import { ChangePeriodSchema } from '../constants/validationSchemas';
// import TextFieldFetching from '../../../shared/components/TextFieldFetching';
import { useGetAvailiablesPeriodsQuery } from '../../periods/slices/periodsApiSlice';
import { PassData } from '../interfaces/passData.interfaces';
import ConfirmAlertDialog from './ConfirmAlertDialog';
import CustomAsyncPaginatedSelect from '../../../shared/components/extended/Form/CustomAsyncPaginatedSelect';
import { useLazyGetStudentsQuery } from '../../students/slices/studentsApiSlice';

const ChangePeriod = () => {
  const history = useHistory();

  const [showAlerts, setShowAlerts] = useState(true);
  const [showAlertsStudent, setShowAlertsStudent] = useState(true);

  const [courseListId, setCourseListId] = useState<PassData[]>([] as PassData[]);

  const [openDialogConfirm, setOpenDialogConfirm] = useState(false);

  const [ChangePeriodStudent, { isLoading, isError, error, isSuccess }] =
    useAddNewPeriodStudentMutation();

  const [
    getStudentAvailable,
    {
      data: studentInfo,
      // isLoading: isLoadingStudent,
      isError: isErrorStudent,
      isSuccess: isSuccessStudent,
      error: errorStudent,
    },
  ] = useLazyGetStudentInfoQuery();

  const { getFieldProps, errors, touched, handleSubmit, setFieldValue, values } = useFormik({
    initialValues: {
      studentId: {},
      periodId: '',
      assignedCourses: 0,
      canUpdateDates: false,
    },
    validationSchema: ChangePeriodSchema,
    onSubmit: async (values) => {
      setOpenDialogConfirm(true);
    },
  });

  const {
    data: coursePeriodAvailables,
    isLoading: isLoadingPeriods,
    isUninitialized,
    error: errorPeriod,
    isError: isErrorPeriod,
  } = useGetCoursesByPeriodChangeQuery(
    {
      periodId: Number(values.periodId),
      curriculumId: (studentInfo as any)?.mesh_id || 1,
      studentRecordId: !!studentInfo?.course_student?.length
        ? Number((studentInfo as any)?.course_student[0]?.student_record_id)
        : 0 || 0,
      // subjectId: Number(values.subjectId),
    },
    { skip: !studentInfo?.id || !values.periodId || !(studentInfo as any)?.mesh_id }
  );

  const { data: periodsAvailablesCurrents, isLoading: isLoadingPeriodsCurrents } =
    useGetAvailiablesPeriodsQuery(
      {
        current: true,
      },
      { skip: !isSuccessStudent }
    );

  const [getStudentsTrigger] = useLazyGetStudentsQuery();

  //TODO: crear hook en el componente del select
  const getStudents = async (search: string, page: number, perPage: number) => {
    const response = await getStudentsTrigger({
      page,
      perPage,
      search,
    }).unwrap();

    const hasMore = response.current_page < response.last_page;

    return {
      options: response.data.map((dt) => {
        return {
          value: dt.id,
          label:
            (dt as any)?.pers_firstname +
            ' ' +
            (dt as any)?.pers_first_lastname +
            ' ' +
            (dt as any)?.pers_second_lastname,
          ...dt,
        };
      }),
      hasMore,
    };
  };

  const handleSubjectCourse = (data: PassData) => {
    if (courseListId?.some(({ subjectId }) => data.subjectId === subjectId)) {
      const deleteCourse = courseListId.filter(({ subjectId }) => !(subjectId === data.subjectId));
      setCourseListId(deleteCourse);
    } else {
      setCourseListId((courseList) => (!courseList?.length ? [data] : [...courseList, data]));
    }
  };

  const onSubmitConfirm = async () => {
    await ChangePeriodStudent({
      currentPeriod: Number(studentInfo?.course_student[0].period_id) || 0,
      studentRecordId: Number(studentInfo?.course_student[0].student_record_id) || 0,
      AssignPeriodPayload: {
        can_update_dates: values.canUpdateDates,
        courses: [
          ...courseListId.map(({ courseId, subjectId }) => ({
            course_id: courseId,
            subject_id: subjectId,
          })),
        ],
        newPeriod: Number(values.periodId),
      },
    });

    setShowAlerts(true);
  };

  useEffect(() => {
    setCourseListId(null!);
  }, [values.periodId]);

  useEffect(() => {
    setFieldValue('assignedCourses', courseListId?.length || 0);
  }, [setFieldValue, courseListId]);

  useEffect(() => {
    if (!!(values.studentId as unknown as any)?.pers_identification) {
      getStudentAvailable({
        identification: (values.studentId as unknown as any).pers_identification,
        offerDescription: 'oferta_postgrado',
      });
      setShowAlertsStudent(true);
      setFieldValue('periodId', '');
    }
  }, [values.studentId, getStudentAvailable, setFieldValue]);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid container direction="column" gap={2}>
          <Grid item>
            {isError && showAlerts && (
              <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
            )}

            {isSuccess && showAlerts && (
              <SuccessAlert
                message="El estudiante ha sido agregado correctamente."
                handleDismiss={() => setShowAlerts(false)}
              />
            )}

            {isErrorStudent && showAlertsStudent && !studentInfo?.id && (
              <ErrorAlert
                message={
                  (errorStudent as string) ||
                  'El estudiante no cumple con los parametros requeridos'
                }
                handleDismiss={() => setShowAlertsStudent(false)}
              />
            )}
          </Grid>

          <Grid item container spacing={2} alignItems="center">
            {isSuccessStudent && studentInfo?.id && (
              <>
                {false && (
                  <Grid item xs={12} md={6}>
                    <InputLabel>Estudiante</InputLabel>
                    <Typography variant="h5" color="initial">
                      {studentInfo?.student?.user?.person?.pers_full_name}
                    </Typography>
                  </Grid>
                )}

                <Grid item xs={12} md={6}>
                  <InputLabel>Período actual</InputLabel>
                  <Typography variant="h5" color="initial">
                    {!!studentInfo?.course_student?.length &&
                      studentInfo?.course_student[0]?.period?.per_name}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <InputLabel>Programa</InputLabel>
                  <Typography variant="h5" color="initial">
                    {studentInfo?.education_level?.edu_name}
                  </Typography>
                </Grid>
              </>
            )}

            <Grid item xs={12} md={6}>
              <InputLabel>Estudiante</InputLabel>
              <CustomAsyncPaginatedSelect
                key={'studentId'}
                value={values.studentId as any}
                isError={'studentId' in errors && touched['studentId'] ? true : false}
                placeholder={'Escoger estudiante'}
                setValue={(value) => setFieldValue('studentId', value)}
                fetchFunc={getStudents}
              />
              {errors.studentId && touched.studentId && (
                <FormHelperText error>{errors.studentId}</FormHelperText>
              )}
            </Grid>

            {/* {false && (
              <Grid item xs={12} md={6}>
                <InputLabel>Identificación del estudiante</InputLabel>
                {isLoadingStudent ? (
                  <TextFieldFetching placeholderValue={values.studentId} />
                ) : (
                  <TextField
                    fullWidth
                    error={Boolean(errors.studentId && touched.studentId)}
                    placeholder="Ingrese la identifición del estudiante"
                    {...getFieldProps('studentId')}
                  />
                )}
                {errors.studentId && touched.studentId && (
                  <FormHelperText error>{errors.studentId}</FormHelperText>
                )}
              </Grid>
            )} */}
            {isSuccessStudent && studentInfo?.id && (
              <>
                <Grid item xs={12} md={6}>
                  <InputLabel>Período nuevo</InputLabel>
                  <Autocomplete
                    options={periodsAvailablesCurrents || []}
                    loading={isLoadingPeriodsCurrents}
                    value={periodsAvailablesCurrents?.find(
                      ({ id }) => id.toString() === values.periodId || null
                    )}
                    loadingText="Cargando períodos disponibles..."
                    disabled={!values.studentId}
                    getOptionLabel={(option) => option.per_name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={Boolean(errors.periodId && touched.periodId)}
                        placeholder="Seleccione los períodos disponibles"
                      />
                    )}
                    onChange={(e, values) => setFieldValue('periodId', values?.id)}
                    fullWidth
                  />
                  {errors.periodId && touched.periodId && (
                    <FormHelperText error>{errors.periodId}</FormHelperText>
                  )}
                </Grid>

                <Grid item xs={12} md={6}>
                  <InputLabel>¿Deseas actualizar fecha de pago?</InputLabel>

                  <FormControlLabel
                    {...getFieldProps('canUpdateDates')}
                    onChange={(e: any) => {
                      setFieldValue('canUpdateDates', e.target.checked ? true : false);
                    }}
                    control={<Switch checked={values.canUpdateDates} />}
                    label={values.canUpdateDates ? 'Si' : 'No'}
                  />
                </Grid>

                <Grid item xs={12}>
                  <InputLabel>Cursos diponibles</InputLabel>
                </Grid>
                {isLoadingPeriods &&
                  Array.from(new Array(4)).map((e, index) => (
                    <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                      <Skeleton
                        key={index}
                        variant="rectangular"
                        sx={{ borderRadius: 2 }}
                        height={250}
                        width="100%"
                      />
                    </Grid>
                  ))}

                {isUninitialized ? (
                  <Grid item container xs={12} justifyContent="center" alignItems="center">
                    Selecciona un período reprobado para cargar los cursos
                  </Grid>
                ) : isErrorPeriod ? (
                  <Grid item container xs={12} justifyContent="center" alignItems="center">
                    {errorPeriod}
                  </Grid>
                ) : (
                  <Grid item container spacing={2}>
                    {coursePeriodAvailables?.map((course) => (
                      <SubjectCardNew
                        coursesSubjectId={courseListId}
                        handlecourseSubject={handleSubjectCourse}
                        course={course}
                        // period={e}
                      />
                    ))}
                  </Grid>
                )}
              </>
            )}
            <Grid
              item
              container
              alignItems="center"
              justifyContent="flex-end"
              spacing={2}
              sx={{ mt: 1 }}
            >
              <Grid item>
                <Button
                  variant="outlined"
                  size="large"
                  color="primary"
                  onClick={() => history.goBack()}
                >
                  Regresar
                </Button>
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  color="primary"
                  disabled={isLoading || !!Object.values(errors).length}
                  endIcon={isLoading && <CircularProgress size={20} />}
                >
                  Asignar período
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
      <ConfirmAlertDialog
        error={error}
        isError={isError}
        isLoading={isLoading}
        open={openDialogConfirm}
        setOpen={setOpenDialogConfirm}
        isSuccess={isSuccess}
        periodName={
          periodsAvailablesCurrents?.find(({ id }) => Number(values.periodId) === id)?.per_name ||
          'N/A'
        }
        event={onSubmitConfirm}
        subjectsName={
          coursePeriodAvailables
            ?.filter(({ id }) => courseListId?.some(({ courseId }) => courseId === id))
            .map(({ matter }) => matter.mat_name) || []
        }
      />
    </>
  );
};

export default ChangePeriod;
