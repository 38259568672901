import {
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useAppSelector } from '../../../../hooks/useRedux';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import { useFormStyles } from '../constants/styles';
import { PermissionInfoSchema } from '../constants/validationSchemas';
import { useUpdatePermissionMutation } from '../slices/permissionsApiSlice';
import { useTranslation } from 'react-i18next';

const EditPermissionForm = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { permissionId } = useParams<{ permissionId: string }>();

  const { permissionsList } = useAppSelector((state) => state.permissions);
  const { currentProfile } = useAppSelector((state) => state.access);

  const classes = useFormStyles();

  const [showAlerts, setShowAlerts] = useState(false);

  const [updatePeriod, { isLoading: isUpdating, isSuccess, isError, error }] =
    useUpdatePermissionMutation();

  const pemissionToEdit = permissionsList.find((p) => p.id === Number(permissionId));

  const { getFieldProps, errors, touched, handleSubmit } = useFormik({
    initialValues: {
      name: pemissionToEdit?.name || '',
      description: pemissionToEdit?.description || '',
      alias: pemissionToEdit?.alias || '',
      parentName: pemissionToEdit?.parent_name || '',
      statusId: pemissionToEdit?.status_id || 1,
    },
    validationSchema: PermissionInfoSchema,
    onSubmit: async (values) => {
      await updatePeriod({
        profileId: currentProfile?.profileId!,
        permissionId: pemissionToEdit?.id!,
        permissionPayload: {
          name: values.name,
          description: values.description,
          alias: values.alias,
          parent_name: values.parentName,
          status_id: values.statusId.toString(),
        },
      });

      setShowAlerts(true);
    },
  });
  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction="column" gap={2}>
        <Grid item>
          {isError && showAlerts && (
            <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
          )}

          {isSuccess && showAlerts && (
            <SuccessAlert
              message={t('configuration.permissions.edit.success')}
              handleDismiss={() => setShowAlerts(false)}
            />
          )}
        </Grid>

        <Grid item container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>
              {t('configuration.permissions.form.name')}
            </InputLabel>
            <TextField
              fullWidth
              error={Boolean(errors.name && touched.name)}
              placeholder={t('configuration.permissions.form.name.placeholder')}
              {...getFieldProps('name')}
            />
            {errors.name && touched.name && <FormHelperText error>{t(errors.name)}</FormHelperText>}
          </Grid>

          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>
              {t('configuration.permissions.form.description')}
            </InputLabel>
            <TextField
              multiline
              rows={2}
              fullWidth
              placeholder={t('configuration.permissions.form.description.placeholder')}
              {...getFieldProps('description')}
            />
          </Grid>

          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>
              {t('configuration.permissions.form.alias')}
            </InputLabel>
            <TextField
              fullWidth
              error={Boolean(errors.alias && touched.alias)}
              placeholder={t('configuration.permissions.form.alias.placeholder')}
              {...getFieldProps('alias')}
            />
            {errors.alias && touched.alias && (
              <FormHelperText error>{t(errors.alias)}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>
              {t('configuration.permissions.form.parent')}
            </InputLabel>
            <TextField
              fullWidth
              error={Boolean(errors.parentName && touched.parentName)}
              placeholder={t('configuration.permissions.form.parent.placeholder')}
              {...getFieldProps('parentName')}
            />
            {errors.parentName && touched.parentName && (
              <FormHelperText error>{t(errors.parentName)}</FormHelperText>
            )}
          </Grid>
        </Grid>

        <Grid
          item
          container
          alignItems="center"
          justifyContent="flex-end"
          spacing={2}
          sx={{ mt: 1 }}
        >
          <Grid item>
            <Button
              variant="outlined"
              size="large"
              color="primary"
              onClick={() => history.goBack()}
            >
              {t('configuration.permissions.edit.back')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              disabled={isUpdating}
              endIcon={isUpdating && <CircularProgress size={20} />}
            >
              {t('configuration.permissions.edit.submit')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default EditPermissionForm;
