import { useState } from 'react';
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Skeleton,
  TextField,
  Typography,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import { DebounceInput } from 'react-debounce-input';
import SubjectCard from '../cards/SubjectCardAdd';
import CustomPagination from '../../../../shared/components/tables/CustomPagination';
import { IconX } from '@tabler/icons';
import { useAppSelector } from '../../../../../hooks/useRedux';
import { useGetCareerbyAcademicOfferIdQuery } from '../../../academic-offerings/slices/academicOfferingsApiSlice';

const MeshGridView = () => {
  const { currentProfile } = useAppSelector((state) => state.access);
  const profileId = currentProfile!.profileId;

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(8);
  const [search, setSearch] = useState('');

  const { isLoading, error, data, isFetching } = useGetCareerbyAcademicOfferIdQuery(
    {
      profileId,
      page,
      perPage,
      search,
      academicOfferingId: '2',
    },
    { refetchOnMountOrArgChange: 30 }
  );

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (newPerPage: number) => {
    setAnchorEl(null);
    setPerPage(newPerPage);
  };

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item xs={12} container>
        <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
          <DebounceInput
            autoFocus
            minLength={2}
            debounceTimeout={300}
            onChange={({ target }) => setSearch(target.value)}
            value={search}
            element={(props) => (
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon fontSize="small" />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        size="small"
                        onClick={() => setSearch('')}
                        {...(!search && { sx: { cursor: 'initial', opacity: 0 } })}
                      >
                        <IconX size={20} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                placeholder="Buscar carrera"
                variant="outlined"
                size="small"
                {...props}
              />
            )}
          />
        </Grid>
      </Grid>

      <Grid item xs={12} container spacing={4}>
        {isFetching ? (
          <>
            {Array.from(new Array(8)).map((n, index) => (
              <Grid key={index} item xs={12} sm={6} md={3} lg={3}>
                <Skeleton
                  variant="rectangular"
                  sx={{ borderRadius: 2 }}
                  height={250}
                  width="100%"
                />
              </Grid>
            ))}
          </>
        ) : (
          <>
            {data?.data!.map((ap) => (
              <Grid key={ap.id} item xs={12} sm={6} md={3} lg={2}>
                <SubjectCard career={ap} />
              </Grid>
            ))}
          </>
        )}

        {data?.data!.length === 0 && !isFetching && (
          <Grid item xs={12} container justifyContent="center">
            <Typography variant="subtitle1">¡No hay elementos que mostrar!</Typography>
          </Grid>
        )}
      </Grid>

      {!isLoading && !error && (
        <Grid
          item
          xs={12}
          container
          justifyContent="space-between"
          alignItems="flex-end"
          sx={{ mt: 4 }}
        >
          <Grid item>
            <CustomPagination
              shape="rounded"
              color="primary"
              count={Math.ceil(data!.total / perPage)}
              page={page}
              onChange={(_, newPage) => {
                setPage(newPage);
              }}
            />
          </Grid>
          <Grid>
            <Box
              component={Button}
              size="large"
              sx={{
                color: 'grey.900',
                backgroundColor: 'primary.light',
                ':hover': { bgcolor: 'primary.light' },
                px: 2,
                borderRadius: 4,
              }}
              endIcon={<ExpandMoreRoundedIcon />}
              onClick={handleClick}
            >
              {perPage} Registros
            </Box>

            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={open}
              onClose={() => handleClose(perPage)}
              variant="selectedMenu"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <MenuItem onClick={() => handleClose(4)}>4 Registros</MenuItem>
              <MenuItem onClick={() => handleClose(8)}>8 Registros</MenuItem>
              <MenuItem onClick={() => handleClose(12)}>12 Registros</MenuItem>
            </Menu>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default MeshGridView;
