import { Grid, Typography } from '@material-ui/core';
import MainCard from '../../../shared/components/cards/MainCard';
import MediumManagementTable from '../components/MediumManagementTable';

const ConfigMediumManagementScreen = () => {

   return (
      <Grid component={MainCard} sx={{ padding: 2 }}>
         <Grid sx={{ mb: 3 }}>
            <Typography variant="h2">Configuración</Typography>
            <Typography variant="h2" color="gray" fontWeight={400}>Medios de Gestión</Typography>
            <br />
            <MediumManagementTable />

         </Grid>

      </Grid>
   )
}

export default ConfigMediumManagementScreen;