import * as Yup from 'yup';

export const CalificationModelInfoSchema = Yup.object().shape({
  name: Yup.string().required('El nombre es requerido.'),
  acronym: Yup.string()
    .required('El acrónimo es requerido.')
    .max(10, 'La cantidad máxima de caracteres es 10.'),
  equivalence: Yup.number(),
  statusId: Yup.number().required('El estado del permiso es requerido.'),
});
