import { Grid, Typography } from '@material-ui/core';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/useRedux';
import MainCard from '../../../../../shared/components/cards/MainCard';
import BackButton from '../../../../../shared/components/buttons/BackButton';
import EvaluationPreview from '../components/preview';
import { useLazyGetEvaluationQuery } from '../slices/evaluationsApiSlice';
import { setEvaluationsIdSelected, setEvaluationsList } from '../slices/evaluationsSlice';

const PreviewEvaluationScreen = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();

  const { evaluationsIdSelected, evaluationsList } = useAppSelector((state) => state.evaluations);

  const [getEvaluation, { isLoading }] = useLazyGetEvaluationQuery();

  useEffect(() => {
    if (!evaluationsIdSelected && id) {
      dispatch(setEvaluationsIdSelected(id!));
    }

    if (!evaluationsList?.length && id) {
      getEvaluation({ id }).then((evaluation) => {
        dispatch(setEvaluationsList([evaluation.data!]));
      });
    }

    return () => {
      dispatch(setEvaluationsIdSelected(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid container alignItems="center" sx={{ mb: 3 }}>
        <BackButton />
        <Typography variant="h2">{t('maintenance.evaluations.preview.title')}</Typography>
      </Grid>

      {evaluationsIdSelected && evaluationsList?.length && !isLoading && <EvaluationPreview />}
    </Grid>
  );
};

export default PreviewEvaluationScreen;
