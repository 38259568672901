import { Grid, IconButton, Typography, useTheme } from '@material-ui/core';
import { useAppSelector } from '../../../../hooks/useRedux';
import MainCard from '../../../shared/components/cards/MainCard';

import DeleteMaterialsDialog from './DeleteMaterialsDialog';
import ListMaterials from './ListMaterials';
//import NavScreenMateriales from './Components/NavScreenMateriales';
import { IconArrowNarrowLeft } from '@tabler/icons';

import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const MaterialScreen = () => {
  const theme = useTheme();
  const History = useHistory();

  const { t } = useTranslation();
  const { materialIdToDelete } = useAppSelector((state) => state.virtualClassroomTeacher);
  return (
    <MainCard
      sx={{
        borderTopLeftRadius: '0px',
        borderTopRightRadius: '0px',
      }}
    >
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">{t('teacherVirtualClassroom.materials.title')}</Typography>
      </Grid>
      <Grid container spacing={2} direction="column">
        {/*
        <NavScreenMateriales />
         <Grid item>
         <AddMaterials />
        </Grid>*/}
        <Grid item>
          <IconButton onClick={() => History.goBack()}>
            <IconArrowNarrowLeft size={30} color={theme.palette.text.dark} />
            <Typography variant="body1">
              {t('teacherVirtualClassroom.materials.modal.button.regresar')}
            </Typography>
          </IconButton>
          <ListMaterials />
        </Grid>
        {materialIdToDelete && <DeleteMaterialsDialog />}
      </Grid>
    </MainCard>
  );
};

export default MaterialScreen;
