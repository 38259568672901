import { Button, FormHelperText, Grid, InputLabel, TextField } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { RegisterSyllabusData } from '../../../interfaces/registerSyllabusData';
import { useAppSelector } from '../../../../../hooks/useRedux';

interface Props {
  setNext: React.Dispatch<React.SetStateAction<number>>;
  isBack?: boolean;
  aditionalFunctionInNext?: () => void;
  finalStep?: boolean;
}

const TeacherData = ({ setNext, finalStep, isBack, aditionalFunctionInNext }: Props) => {
  const { getFieldProps, errors, touched } = useFormikContext<RegisterSyllabusData>();

  const { userInfo } = useAppSelector((state) => state.auth);

  const { t } = useTranslation();

  const handleBack = () => {
    setNext((step) => {
      if (step === 1) {
        return step;
      }
      return step - 1;
    });
  };

  return (
    <Grid container direction="column">
      <Grid item container xs={12} md={6} spacing={2}>
        <Grid item xs={12} md={6}>
          <InputLabel>{t('Nombres')}</InputLabel>
          <TextField value={`${userInfo.name} ${userInfo.secondname}`} disabled fullWidth />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputLabel>{t('Apellidos')}</InputLabel>
          <TextField value={`${userInfo.lastname} ${userInfo.secondlastname}`} disabled fullWidth />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputLabel>{t('Educación Universitaria')}</InputLabel>
          <TextField
            {...getFieldProps('syllabus_data_collaborator.college_education')}
            fullWidth
            error={Boolean(
              errors?.syllabus_data_collaborator?.college_education &&
                touched?.syllabus_data_collaborator?.college_education
            )}
          />
          {errors?.syllabus_data_collaborator?.college_education &&
            touched?.syllabus_data_collaborator?.college_education && (
              <FormHelperText error>
                {t(errors?.syllabus_data_collaborator?.college_education)}
              </FormHelperText>
            )}
        </Grid>
        <Grid item xs={12} md={6}>
          <InputLabel>{t('Postgrado')}</InputLabel>
          <TextField
            {...getFieldProps('syllabus_data_collaborator.postgrado')}
            fullWidth
            error={Boolean(
              errors?.syllabus_data_collaborator?.postgrado &&
                touched?.syllabus_data_collaborator?.postgrado
            )}
          />
          {errors?.syllabus_data_collaborator?.postgrado &&
            touched?.syllabus_data_collaborator?.postgrado && (
              <FormHelperText error>
                {t(errors?.syllabus_data_collaborator?.postgrado)}
              </FormHelperText>
            )}
        </Grid>
        <Grid item xs={12} md={6}>
          <InputLabel>{t('Área de Especialidad')}</InputLabel>
          <TextField
            {...getFieldProps('syllabus_data_collaborator.specialty_area')}
            fullWidth
            error={Boolean(
              errors?.syllabus_data_collaborator?.specialty_area &&
                touched?.syllabus_data_collaborator?.specialty_area
            )}
          />
          {errors?.syllabus_data_collaborator?.specialty_area &&
            touched?.syllabus_data_collaborator?.specialty_area && (
              <FormHelperText error>
                {t(errors?.syllabus_data_collaborator?.specialty_area)}
              </FormHelperText>
            )}
        </Grid>
        <Grid item xs={12} md={6}>
          <InputLabel>{t('Experiencia en el área')}</InputLabel>
          <TextField
            {...getFieldProps('syllabus_data_collaborator.experience_area')}
            fullWidth
            error={Boolean(
              errors?.syllabus_data_collaborator?.experience_area &&
                touched?.syllabus_data_collaborator?.experience_area
            )}
          />
          {errors?.syllabus_data_collaborator?.experience_area &&
            touched?.syllabus_data_collaborator?.experience_area && (
              <FormHelperText error>
                {t(errors?.syllabus_data_collaborator?.experience_area)}
              </FormHelperText>
            )}
        </Grid>
        <Grid item xs={12} md={6}>
          <InputLabel>{t('Teléfonos')}</InputLabel>
          <TextField
            {...getFieldProps('syllabus_data_collaborator.phone_collaborator')}
            fullWidth
            error={Boolean(
              errors?.syllabus_data_collaborator?.phone_collaborator &&
                touched?.syllabus_data_collaborator?.phone_collaborator
            )}
          />
          {errors?.syllabus_data_collaborator?.phone_collaborator &&
            touched?.syllabus_data_collaborator?.phone_collaborator && (
              <FormHelperText error>
                {t(errors?.syllabus_data_collaborator?.phone_collaborator)}
              </FormHelperText>
            )}
        </Grid>
        <Grid item xs={12} md={6}>
          <InputLabel>{t('Email')}</InputLabel>
          <TextField
            {...getFieldProps('syllabus_data_collaborator.email_collaborator')}
            error={Boolean(
              errors?.syllabus_data_collaborator?.email_collaborator &&
                touched?.syllabus_data_collaborator?.email_collaborator
            )}
            fullWidth
          />
          {errors?.syllabus_data_collaborator?.email_collaborator &&
            touched?.syllabus_data_collaborator?.email_collaborator && (
              <FormHelperText error>
                {t(errors?.syllabus_data_collaborator?.email_collaborator)}
              </FormHelperText>
            )}
        </Grid>
        <Grid
          item
          xs={12}
          container
          alignItems="center"
          justifyContent="center"
          spacing={2}
          sx={{ mt: 6 }}
        >
          {isBack && (
            <Grid item>
              <Button
                fullWidth
                variant="outlined"
                size="large"
                color="primary"
                onClick={handleBack}
              >
                <ArrowBack />
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TeacherData;
