import { Box, Button, CircularProgress, Grid, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { IconClock, IconNotebook, IconWriting } from '@tabler/icons';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppSelector } from '../../../../../hooks/useRedux';
import { CoursesCollaborator } from '../../../../maintenance/courses/interfaces/coursesCollaborator.interface';
// import { getSubjectStatusColor } from '../../../../students/academic-portfolio/helpers/colors';
import useGoToVirtualClassroom from '../../../../students/academic-portfolio/hooks/useGoToVirtualClassroom';
import { useLazyGetResponseSyllabusQuery } from '../../slices/coursesTeacherApiSlice';
// import { setRegisterSyllabusData } from '../../slices/coursesTeacherSlice';

const useStyles = makeStyles((theme) => ({
  root: {
    // border: '1px solid red',
    padding: '20px 20px 20px 30px',
    boxShadow: '0px 0px 20px #BFDAF9',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: '20px',
    width: '100%',

    position: 'relative',

    '&::after': {
      backgroundColor: theme.palette.primary.main,
      content: `""`,
      position: 'absolute',
      top: 0,
      left: 0,
      borderTopLeftRadius: '10px',
      borderBottomLeftRadius: '10px',
      width: '10px',
      height: '100%',
    },
  },
  cardRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  cardCollapse: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  cardTitle: {
    color: theme.palette.primary.main,
    fontSize: '14px',
    fontWeight: 600,
    overflow: 'hidden',
    wordWrap: 'break-word',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    height: '3em',
  },
  cardSubTitle: {
    color: theme.palette.grey[500],
    fontSize: '14px',
    fontWeight: 400,
  },

  subjectType: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.main,
    fontSize: '12px',
    fontWeight: 400,
  },
  subjectTypeText: {
    color: theme.palette.grey[600],
    fontSize: '12px',
    fontWeight: 400,
  },
  preRequisitesText: {
    color: '#31355C',
    fontSize: '12px',
    fontWeight: 600,
  },
  cardBadges: {
    display: 'flex',
    gap: '4px',
    alignItems: 'center',
    flexWrap: 'wrap',
    // justifyContent: 'flex-start',
  },
  subjectPreRequisites: {
    display: 'flex',
    gap: '4px',
    flexWrap: 'wrap',
    marginTop: '0.5rem',
  },
  hourhandContainer: {
    backgroundColor: theme.palette.grey[200],
    padding: '10px',
    borderRadius: '8px',
  },
  gradeContainer: {
    backgroundColor: theme.palette.grey[200],
    padding: '10px',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
}));

interface Props {
  course: CoursesCollaborator;
  withPeriod?: boolean;
}

const CourseCardNew = ({ course, withPeriod = false }: Props) => {
  const classes = useStyles();

  const navigate = useHistory();

  const [valueCourseId, setValueCourseId] = useState<number>(0);

  const { isFetching } = useGoToVirtualClassroom(valueCourseId, undefined, true);

  const { periodId } = useAppSelector((state) => state.courseTeacher);

  const [getSyllabus, { data: syllabus, isLoading: loadingSyllabus }] =
    useLazyGetResponseSyllabusQuery();

  const handleRegisterSyllabus = async () => {
    try {
      await getSyllabus({
        id: course?.matter.id,
        period_id: Number(periodId!),
      }).unwrap();
      console.log(syllabus);

      navigate.push(`/silabus/${course?.id}/${course?.matter.id}/${periodId!}/contenido-general`);
    } catch (error) {
      navigate.push(`/silabus/${course?.id}/${course?.matter.id}/${periodId!}/contenido-general`);
      console.log(error);
    }
  };

  return (
    <Grid item xs={12} sm={6} md={4} xl={3}>
      <Box
        className={classes.root}
        sx={{
          '&::after': {
            //   bgcolor: subject.simbology.sim_color,
            // bgcolor: getSubjectStatusColor(course?.status?.st_name || ''),
          },
          // minHeight: 250,
          opacity: 1,
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12} className={classes.cardRow}>
            <Tooltip title={course?.matter?.mat_name}>
              <Typography variant="subtitle1" className={classes.cardTitle}>
                {course?.matter?.mat_name}
              </Typography>
            </Tooltip>
          </Grid>

          {course?.code_migration && (
            <Grid item xs={12} className={classes.cardRow}>
              <Typography variant="subtitle2" className={classes.cardSubTitle}>
                {course?.code_migration}
              </Typography>
            </Grid>
          )}
          {course?.matter?.type_matter?.tm_name && (
            <Grid item xs={12} className={classes.cardRow}>
              <Typography variant="subtitle2" className={classes.cardSubTitle}>
                Tipo: {course?.matter?.type_matter?.tm_name}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} className={classes.cardRow}>
            <Typography variant="subtitle2" className={classes.cardSubTitle}>
              Paralelo: {course?.parallel?.par_name}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.cardRow}>
            <Typography variant="subtitle2" className={classes.cardSubTitle}>
              N° alumnos: {course?.num_stud || '0'}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.cardRow}>
            <Typography variant="subtitle2" className={classes.cardSubTitle}>
              Campus: {course?.classroom?.campus?.cam_name}
            </Typography>
          </Grid>
          {withPeriod && (
            <Grid item xs={12} className={classes.cardRow}>
              <Typography variant="subtitle2" className={classes.cardSubTitle}>
                Periodo: {course?.period?.per_name}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} className={classes.cardRow}>
            <Box className={classes.hourhandContainer}>
              <Typography variant="subtitle2" className={classes.subjectType}>
                <IconClock size={24} /> {course?.hourhand?.hour_description}
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={2} xs={12} justifyContent="center">
          <Grid item xs={12} md={12} lg={6}>
            <Button
              sx={{
                width: '100%',
              }}
              startIcon={isFetching ? <CircularProgress size={14} /> : <IconNotebook />}
              variant="contained"
              size="small"
              // fullWidth
              // onClick={() => goToVirtualClassroom(subject, Number(subject.course_id))}
              onClick={() => setValueCourseId(Number(course?.id))}
              disabled={isFetching}
            >
              Aula virtual
            </Button>
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <Button
              sx={{
                width: '100%',
              }}
              startIcon={isFetching ? <CircularProgress size={14} /> : <IconWriting />}
              variant="contained"
              size="small"
              // fullWidth
              // onClick={() => goToVirtualClassroom(subject, Number(subject.course_id))}
              onClick={handleRegisterSyllabus}
              disabled={loadingSyllabus}
            >
              Syllabus
            </Button>
          </Grid>
        </Grid>

        <Grid>
          <Typography variant="subtitle2">{course?.classroom?.cl_name}</Typography>
        </Grid>

        {/* <Collapse in={openCollapse} timeout="auto" unmountOnExit>
          <Grid container item spacing={2} flexDirection={'column'}>
            <Divider />
            <Grid item>
              <Typography variant="subtitle2" className={classes.cardTitle}>
                Aula:
              </Typography>
              <Typography variant="subtitle2">{subject?.course?.classroom?.cl_name}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle2" className={classes.cardTitle}>
                Estado:
              </Typography>
              <SubjectStatusChip keyStatus={subject?.approval_status?.st_name} />
            </Grid>
          </Grid>
        </Collapse> */}
      </Box>
    </Grid>
  );
};

export default CourseCardNew;
