import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  SearchPersonParam,
  StudentRecord,
} from '../../../shared/interfaces/searchPerson.interface';
import {
  Details,
  ElectronicDocument,
} from '../../electronic-documents/interfaces/electronicDocuments.interfaces';
import { Reason } from '../interfaces/payload.interface';

interface generateCreditNoteState {
  // cliente seleccionado
  currentClient?: SearchPersonParam;
  // student record del cliente seleccionado
  currentStudentRecord?: StudentRecord;

  // factura selecionada
  selectedInvoice?: ElectronicDocument;
  //array de cuotas para el json del post
  selectedFees: number[];
  // total fees Selected
  totalFees: number;
  // total reverte fees
  totalRevertedFees: number;
  // motivo credit note
  motivo?: Reason;
}

const initialState: generateCreditNoteState = {
  currentClient: undefined,
  currentStudentRecord: undefined,

  selectedInvoice: undefined,
  selectedFees: [],
  totalFees: 0,
  totalRevertedFees: 0,
  motivo: undefined,
};

const generateCreditNoteSlice = createSlice({
  name: 'generateCreditNote',
  initialState,
  reducers: {
    //student info
    setCurrentClient: (state, action: PayloadAction<SearchPersonParam>) => {
      state.currentClient = action.payload;

      const studentRecords = action.payload.user.student?.student_records;
      if (studentRecords?.length) state.currentStudentRecord = studentRecords[0];

      generateCreditNoteSlice.caseReducers.resetInvoice(state);
    },
    setCurrentStudentRecord: (state, action: PayloadAction<StudentRecord>) => {
      state.currentStudentRecord = action.payload;

      generateCreditNoteSlice.caseReducers.resetInvoice(state);
    },
    resetClient: (state) => {
      state.currentClient = undefined;
      state.currentStudentRecord = undefined;
      generateCreditNoteSlice.caseReducers.resetInvoice(state);
    },

    setInvoice: (state, action: PayloadAction<ElectronicDocument>) => {
      state.selectedInvoice = action.payload;
      generateCreditNoteSlice.caseReducers.resetSelectedFees(state);

      const totalRevertedFees = (action.payload?.details as Details[])
        .filter((fee) => fee.isRevert === '1')
        .map((fee) => +fee.precioTotalSinImpuesto)
        .reduce((partialSum, a) => partialSum + a, 0);

      state.totalRevertedFees = totalRevertedFees;
    },
    resetInvoice: (state) => {
      state.selectedInvoice = undefined;
      generateCreditNoteSlice.caseReducers.resetSelectedFees(state);

      state.totalRevertedFees = 0;
    },

    setSelectedFees: (state, action: PayloadAction<number[]>) => {
      state.selectedFees = action.payload;
    },
    setTotalFees: (state, action: PayloadAction<number>) => {
      state.totalFees = action.payload;
    },
    resetSelectedFees: (state) => {
      state.selectedFees = [];
      state.totalFees = 0;
    },

    setMotivo: (state, action: PayloadAction<Reason | undefined>) => {
      state.motivo = action.payload;
    },
  },
});

export const {
  setCurrentClient,
  setCurrentStudentRecord,
  resetClient,
  setInvoice,
  resetInvoice,
  setSelectedFees,
  setTotalFees,
  resetSelectedFees,
  setMotivo,
} = generateCreditNoteSlice.actions;

export default generateCreditNoteSlice.reducer;
