import {
  Grid,
  Typography,
  //Tooltip,
  IconButton,
} from '@material-ui/core';
import {
  //IconLayoutGrid,
  //IconList,
  IconArrowNarrowLeft,
} from '@tabler/icons';
import { useAppSelector } from '../../../../hooks/useRedux';
import MainCard from '../../../shared/components/cards/MainCard';
import { useHistory } from 'react-router-dom';

import DeleteELSDialog from '../components/DeleteELSDialog';
import ELSTable from '../components/group-subjects/ELSTable';
//import ELSGridView from '../components/group-subjects/ELSGridView';
// import AddSubjects from './AddSubjects';

const ELSScreen = () => {
  const { educationLevelSubjectIdToDelete } = useAppSelector(
    (state) => state.educationLevelSubject
  );
  const history = useHistory();

  return (
    <Grid component={MainCard} sx={{ p: 3 }}>
      <Grid item container alignItems="center" justifyContent="space-between" gap={1}>
        <Grid item>
          <Grid item container alignItems="center" spacing={4}>
            <IconButton onClick={() => history.goBack()} sx={{ display: 'block' }}>
              <IconArrowNarrowLeft size={30} />
            </IconButton>
            <Typography variant="h2">Materias de grupo de nivelación</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <ELSTable />
      </Grid>

      {educationLevelSubjectIdToDelete && <DeleteELSDialog />}
    </Grid>
  );
};

export default ELSScreen;
