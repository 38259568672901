import { useHistory } from 'react-router-dom';

// material-ui
import { makeStyles } from '@material-ui/core/styles';

import { useAppDispatch } from '../../../../hooks/useRedux';
// import { setCurrentModule, setCurrentProfile, setCurrentRol } from '../../reducers/accessReducer';

import { IconArrowNarrowRight } from '@tabler/icons';
import { useTheme, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';

// import { fireDialog } from '../../reducers/accessReducer';
import { Profile } from '../../../../types';
import { ThunkDispatch } from 'redux-thunk';
import { checkProfileQuantity } from '../../../auth/helpers';

// style constant
const useStyles = makeStyles((theme) => ({
  moduleTitle: {
    fontWeight: 'bold',
    marginBottom: '15px',
    color: '#657F98',
    display: 'flex',
    alignItems: 'end',
  },
  moduleIcon: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    width: '52px',
    height: '52px',
    background:
      theme.palette.mode === 'dark' ? theme.palette.dark[800] : theme.palette.primary.light,
    color: theme.palette.primary.main,
    '& > svg': {
      width: '32px',
      height: '32px',
    },
  },
}));

interface Props {
  profile: { [key: string]: Profile };
}

const ProfileItem = ({ profile }: Props) => {
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [key] = Object.keys(profile);

  const handleProfile = (
    dispatch: ThunkDispatch<any, any, any>,
    profile: { [key: string]: Profile }
  ) => {
    checkProfileQuantity(dispatch, profile);
    history.push('/modulos');
  };

  return (
    <ListItem button onClick={() => handleProfile(dispatch, profile)}>
      <ListItemIcon className={classes.moduleIcon}>
        {profile[key].name.toUpperCase().slice(0, 2)}
      </ListItemIcon>
      <ListItemText
        sx={{ marginLeft: '1rem', marginRight: '4rem' }}
        primary={
          <Typography
            sx={{ display: 'block', mb: 1 }}
            component="span"
            variant="h4"
            color={theme.palette.grey[700]}
          >
            {profile[key].name}
          </Typography>
        }
        secondary={
          <Typography sx={{ display: 'block', fontWeight: '200' }} component="span">
            {profile[key].description}
          </Typography>
        }
      />
      <ListItemText
        primary={
          <>
            <Typography color="#065CAB" sx={{ display: 'flex', justifyContent: 'end' }}>
              <IconArrowNarrowRight size={25} />
            </Typography>
          </>
        }
      />
    </ListItem>
  );
};

export default ProfileItem;
