import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Parallel } from '../interfaces/parallels.interface';

interface ParallelsState {
  parallelList: Parallel[];
  deleteModalOpen: boolean;
  parallelIdToDelete?: number;
}

const initialState: ParallelsState = {
  parallelList: [],
  deleteModalOpen: false,
};

const ParallelsSlice = createSlice({
  name: 'Parallels',
  initialState,
  reducers: {
    setParallelList: (state, action: PayloadAction<Parallel[]>) => {
      state.parallelList = action.payload;
    },
    setDeleteModalOpen: (state, action: PayloadAction<boolean>) => {
      state.deleteModalOpen = action.payload;
    },
    openDeleteModalWith: (state, action: PayloadAction<number>) => {
      state.parallelIdToDelete = action.payload;
      state.deleteModalOpen = true;
    },
    resetParallelIdToDelete: (state) => {
      state.parallelIdToDelete = undefined;
    },
  },
});

export const { setParallelList, setDeleteModalOpen, openDeleteModalWith, resetParallelIdToDelete } =
  ParallelsSlice.actions;

export default ParallelsSlice.reducer;
