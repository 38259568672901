import { useTheme } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';
import { Chip } from '@material-ui/core';

import { useParams, useHistory } from 'react-router-dom';

import {
  setShowCurriculumAssignModal,
  setUnavailableRegisterDialog,
} from '../../slices/subjectsRegisterSlice';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';

import {
  setSubjectHoverId,
  resetSubjectHoverId,
  setCurrentSubject,
} from '../../slices/subjectsRegisterSlice';
import { MatterMesh } from '../../../../maintenance/meshs/interfaces/curriculum.interfaces';
import { KeywordStatusMatter, SubjectCurriculumStatus } from '../../interfaces/subjects-approveds';
import { useState } from 'react';
import { useControlModalStates } from '../../../../shared/hooks/useControlModalStates';
import DialogAlert from '../../../../shared/components/dialogs/DialogAlert';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '20px',
    boxShadow: '0px 0px 20px #BFDAF9',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  cardRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  cardTitle: {
    color: theme.palette.primary.dark,
    fontSize: '14px',
    fontWeight: 600,
    wordBreak: 'break-all',
    // overflowWrap: 'break-word',
  },
  cardSubTitle: {
    color: theme.palette.grey[400],
    fontSize: '14px',
    fontWeight: 400,
  },

  subjectType: {
    color: theme.palette.secondary.main,
    fontSize: '12px',
    fontWeight: 400,
  },
  subjectTypeText: {
    color: theme.palette.grey[600],
    fontSize: '12px',
    fontWeight: 400,
  },
  preRequisitesText: {
    color: '#31355C',
    fontSize: '12px',
    fontWeight: 600,
  },
  cardBadges: {
    display: 'flex',
    gap: '4px',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  subjectPreRequisites: {
    display: 'flex',
    gap: '4px',
    flexWrap: 'wrap',
    marginTop: '0.5rem',
  },
}));

export interface SubjectCurriculumWithStatus extends MatterMesh {
  statusKey: KeywordStatusMatter;
  courseExist: 'No disponible' | 'Disponible';
  statusName: string;
  subject_curriculum_id: string;
  repeatedNumberSubject: number;
  maxRepeatedNumberSubject: number;
  isRepeatedSubjectAvailable: boolean;
  mesajevalidate: string | null;
}

interface Props {
  subject: SubjectCurriculumWithStatus;
  subjectCurriculumsStatus?: SubjectCurriculumStatus[];
}

const SubjectCard = ({ subject, subjectCurriculumsStatus }: Props) => {
  const {
    id,
    matter_mesh_prerequisites,
    matter_rename,
    simbology,
    statusKey,
    courseExist,
    statusName,
    isRepeatedSubjectAvailable,
    repeatedNumberSubject,
    maxRepeatedNumberSubject,
    mesajevalidate,
  } = subject;

  const { studentId } = useParams<{ studentId: string }>();
  const history = useHistory();
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [validatePrerequisite, setValidatePrerequisite] = useState(true);

  const { subjectHoverId } = useAppSelector((state) => state.subjectsRegister);

  const getBorderValue = () => {
    let border = `0`;

    if (subjectHoverId && subjectHoverId === Number(id)) {
      border = `2px solid ${theme.palette.secondary.main}`;
    }
    return border;
  };

  // if (subject.matter_id == '1556') {
  //   console.log('matter_mesh_prerequisites: ', matter_mesh_prerequisites);
  // }

  const canRegisterStudentOrAdmin = () => {
    if (studentId) {
      return subject?.matter?.type_matter?.register_administrator === '1';
    }
    return subject?.matter?.type_matter?.register_student === '1';
  };

  const getTypeRegisterUnavailable = () => {
    if (studentId) {
      return 'El registro de esta materia está habilitado solo para el estudiante';
    }
    return 'El registro de esta materia está habilitado solo para el coordinador';
  };

  const handleValidation = () => {
    const numPrerequisites = matter_mesh_prerequisites.length;
    const filterPrerequisites = matter_mesh_prerequisites?.map((pr) =>
      subjectCurriculumsStatus?.find((sc) => pr.matter_id === sc.matter_id)
    );
    const prerequisitesAproved = filterPrerequisites.filter(
      (pr) => pr?.status_matter === 'Aprobado'
    );

    if (numPrerequisites === prerequisitesAproved.length) {
      setValidatePrerequisite(true);
    } else {
      setValidatePrerequisite(false);
    }
  };

  const openRegisterDialog = () => {
    dispatch(setCurrentSubject(subject));
    dispatch(setShowCurriculumAssignModal(true));
  };

  const {
    open: openThirRegisterAlert,
    handleOpenModalWith: handleOpenThirRegisterAlert,
    handleCloseModal: handleCloseThirRegisterAlert,
  } = useControlModalStates();

  const openApplicationAlert = () => {
    handleOpenThirRegisterAlert();
  };

  const goToThirdRegistrationRequest = () => {
    history.push('/solicitudes/registro-tercera-matricula');
  };

  const getBgColorChip = () => {
    let color = '';

    // '#00AAF5' //celeste
    // '#608b9fd9' //gris azulado
    // '#ffe57f' //amarillo
    // '#D84315' //rojo
    // '#00C853' //verde
    // '#2c221fc7', // negro

    switch (statusKey) {
      case 'APR':
        color = '#00AAF5';
        break;

      case 'CUR':
        if (statusName === 'Registrado') color = '#608b9fd9';
        else color = '#ffe57f';

        break;

      case 'NDF':
      case 'RPR':
        if (courseExist === 'No disponible') color = '#D84315';
        else color = '#00C853';
        break;

      default:
        color = '#D84315';
        break;
    }

    return color;
  };

  return (
    <>
      <Box
        className={classes.root}
        sx={{
          outline: getBorderValue(),
          // cursor: validatePrerequisite && subject.statusName === 'No cursada' ? 'pointer' : 'default',
          cursor: validatePrerequisite && isRepeatedSubjectAvailable ? 'pointer' : 'default',
        }}
        onMouseOver={() => (matter_mesh_prerequisites.length !== 0 ? handleValidation() : null)}
        onClick={() => {
          console.log('mensajeValidate', mesajevalidate);
          // validatePrerequisite && subject.statusName === 'No cursada' ? openRegisterDialog() : null
          return validatePrerequisite && isRepeatedSubjectAvailable
            ? mesajevalidate === null
              ? !canRegisterStudentOrAdmin()
                ? dispatch(
                    setUnavailableRegisterDialog({
                      open: true,
                      message: getTypeRegisterUnavailable(),
                    })
                  )
                : openRegisterDialog()
              : // ? openRegisterDialog()
                openApplicationAlert()
            : null;
        }}
      >
        <Box>
          <Box className={classes.cardRow} sx={{ alignItems: 'flex-start' }}>
            <Typography variant="subtitle1" className={classes.cardTitle}>
              {matter_rename}
            </Typography>

            <Chip
              label={statusKey === 'NDF' || statusKey === 'RPR' ? courseExist : statusName}
              sx={{ bgcolor: 'grey.50' }}
              size="small"
              icon={
                <Box
                  sx={{
                    width: 14,
                    height: 14,
                    borderRadius: '50%',
                    bgcolor: getBgColorChip(),
                    // statusKey === 'APR'
                    //   ? '#00AAF5' //celeste
                    //   : statusKey === 'CUR'
                    //   ? statusName === 'Registrado'
                    //     ? '#608b9fd9' //gris azulado
                    //     : '#ffe57f' //amarillo
                    //   : statusKey === 'NDF'
                    //   ? courseExist === 'No disponible'
                    //     ? '#D84315' //rojo
                    //     : '#00C853' //verde
                    //   : courseExist === 'No disponible'
                    //   ? '#D84315' //rojo
                    //   : '#00C853', //verde
                    // : '#2c221fc7', // negro
                  }}
                />
              }
            />
          </Box>
          <Box className={classes.cardRow}>
            <Typography variant="subtitle2" className={classes.cardSubTitle}>
              {simbology?.sim_description || ''}
            </Typography>
          </Box>
        </Box>

        {matter_mesh_prerequisites.length !== 0 && (
          <Box>
            <Typography className={classes.preRequisitesText}>Prerequisitos</Typography>
            <Box className={classes.subjectPreRequisites}>
              {matter_mesh_prerequisites.map((pr) => (
                <Box
                  key={pr.id}
                  onMouseOver={() => dispatch(setSubjectHoverId(pr.id))}
                  onMouseLeave={() => dispatch(resetSubjectHoverId())}
                  sx={{
                    width: '100%',
                  }}
                >
                  <Chip
                    label={pr.matter_rename}
                    variant="outlined"
                    color="primary"
                    size="small"
                    sx={{
                      width: '100%',
                    }}
                  />
                </Box>
              ))}
            </Box>
          </Box>
        )}

        {repeatedNumberSubject > 0 && maxRepeatedNumberSubject > 0 && (
          <Box className={classes.cardRow} sx={{ alignItems: 'flex-start' }}>
            <Typography>
              Reprobada: {repeatedNumberSubject} veces / Max: {maxRepeatedNumberSubject}
            </Typography>
          </Box>
        )}
      </Box>

      {openThirRegisterAlert && (
        <DialogAlert
          open={openThirRegisterAlert}
          onClose={handleCloseThirRegisterAlert}
          title={mesajevalidate as string}
          bodyText={' '}
          btnText={!studentId ? 'Ir a Solicitudes' : ''}
          handleSimpleAction={!studentId ? goToThirdRegistrationRequest : undefined}
        />
      )}
    </>
  );
};

export default SubjectCard;
