import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import MainCard from '../../../../shared/components/cards/MainCard';
import EvaluationStudentsQualify from '../components/qualify/EvaluationStudentsQualify';

import { useTranslation } from 'react-i18next';
import ButtonBack from '../../Components/ButtonBack';

const EvaluationDetailScreen = function () {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Grid
        component={MainCard}
        sx={{
          borderTopLeftRadius: '0px',
          borderTopRightRadius: '0px',
        }}
      >
        <Grid sx={{ mb: 3 }} display="flex" justifyContent={'space-between'} alignItems="center">
          <Typography variant="h2">
            {t('teacherVirtualClassroom.tests.listHeaderQualify')}
          </Typography>

          <ButtonBack />
        </Grid>
        <EvaluationStudentsQualify />
      </Grid>
    </React.Fragment>
  );
};

export default EvaluationDetailScreen;
