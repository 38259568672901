import {
  Autocomplete,
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import { useGetCoursesCollaboratorQuery } from '../../../../maintenance/courses/slices/courseApiSlice';
import { useGetAvailiablesPeriodsQuery } from '../../../../maintenance/periods/slices/periodsApiSlice';
import InputLabel from '../../../../shared/components/forms/InputLabel';
import IconTelework from '../../../../shared/components/illustrations/IconTelework';
import CustomPagination from '../../../../shared/components/tables/CustomPagination';
import SkeletonCard from '../../../../students/academic-portfolio/components/skeleton/SkeletonCard';
// import CoursesCards from './CoursesCards';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import { DebounceInput } from 'react-debounce-input';
import SearchIcon from '@material-ui/icons/Search';
import { IconX } from '@tabler/icons';
import { useTranslation } from 'react-i18next';
import CourseCardNew from './CourseCardNew';
import { setPeriodForCourse } from '../../slices/coursesTeacherSlice';

const CoursesList = () => {
  const { t } = useTranslation();

  const { currentProfile } = useAppSelector((state) => state.access);
  const { userInfo } = useAppSelector((state) => state.auth);
  const [periodId, setPeriodId] = useState<number | null>();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(6);
  const [search, setSearch] = useState('');
  const theme = useTheme();

  const dispatch = useAppDispatch();

  const { data: periodsAvaliables = [], isLoading: isLoadingP } = useGetAvailiablesPeriodsQuery(
    {
      profileId: currentProfile?.profileId!,
      collaborator_id: userInfo?.collaboratorInfo?.id,
    },
    { refetchOnMountOrArgChange: 30 }
  );

  const { data, isFetching: isFetchingC } = useGetCoursesCollaboratorQuery(
    {
      collId: userInfo?.collaboratorInfo?.id!,
      periodId: periodId!,
      size: perPage,
      page,
      type_sort: 'desc',
      search,
    },
    { refetchOnMountOrArgChange: 30, skip: !periodId }
  );

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (periodsAvaliables.length > 0) {
      setPeriodId(periodsAvaliables[0].id);
      dispatch(
        setPeriodForCourse({
          periodId: periodsAvaliables[0].id,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periodsAvaliables]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (newPerPage: number) => {
    setAnchorEl(null);
    setPerPage(newPerPage);
  };

  return userInfo?.collaboratorInfo !== undefined ? (
    <Grid container item xs={12} mt={3}>
      <Grid container justifyContent="flex-start" spacing={2}>
        <Grid item xs={12} md={3}>
          <Grid item>
            <InputLabel>{t('teachers.courses.semester')}</InputLabel>
            <Autocomplete
              options={
                Array.isArray(periodsAvaliables)
                  ? periodsAvaliables
                      .slice()
                      .sort((a, b) => Number(b.per_current_year) - Number(a.per_current_year))
                  : []
              }
              loading={isLoadingP}
              noOptionsText={t('teachers.courses.semester.no_data')}
              loadingText={t('teachers.courses.semester.loading')}
              groupBy={(option) => option.per_current_year}
              getOptionLabel={(option) => `${option.per_name}`}
              renderInput={(params) => (
                <TextField {...params} placeholder={t('teachers.courses.semester.placeholder')} />
              )}
              value={periodsAvaliables.find((p) => p.id === periodId) || null}
              onChange={(_, value) => {
                setPeriodId(Number(value?.id));
                dispatch(
                  setPeriodForCourse({
                    periodId: Number(value?.id),
                  })
                );
              }}
            />
          </Grid>
        </Grid>

        <Grid container item xs={12} md={3}>
          <InputLabel sx={{ color: 'white !important' }}>{t('teachers.courses.search')}</InputLabel>
          <DebounceInput
            autoFocus
            minLength={2}
            debounceTimeout={300}
            onChange={({ target }) => setSearch(target.value)}
            value={search}
            element={(props) => (
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon fontSize="small" />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        size="small"
                        onClick={() => setSearch('')}
                        // {...(!search && { sx: { cursor: 'initial', opacity: 0 } })}
                      >
                        <IconX size={20} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                placeholder={t('teachers.courses.search')}
                variant="outlined"
                size="medium"
                fullWidth
                {...props}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} mt={3}>
        <Grid item xs={12}>
          <Typography variant="h3">{t('teachers.courses.current_courses')}</Typography>
        </Grid>
      </Grid>

      <Grid container mt={2} spacing={2}>
        {(!data || data?.data.length === 0) && !isFetchingC && (
          <Grid item xs={12} container alignItems="center" direction="column" spacing={2}>
            <Grid item>
              <Typography variant="subtitle1">{t('teachers.courses.no_data')}</Typography>
            </Grid>
            <Grid item container justifyContent={'center'} alignContent={'center'}>
              <Grid container justifyContent={'center'} item xs={8} md={6}>
                <Grid container item xs={12} justifyContent={'center'}>
                  <IconTelework size={300} />
                </Grid>
                <Typography variant={'body1'} textAlign={'center'} lineHeight={2} component={'div'}>
                  <Typography
                    variant={'h4'}
                    sx={{ color: theme.palette.primary.main }}
                    component={'p'}
                    align={'center'}
                    lineHeight={2}
                  >
                    {t('teachers.courses.select.title')}
                  </Typography>
                  {t('teachers.courses.select.subtitle')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}

        {isFetchingC
          ? [...new Array(6)].map((_, index) => <SkeletonCard key={index.toString()} />)
          : data?.data.map((courses) => <CourseCardNew course={courses} key={courses.id} />)}

        {data && data.data.length > 0 && !isFetchingC && (
          <Grid
            item
            xs={12}
            container
            justifyContent="space-between"
            alignItems="flex-end"
            sx={{ mt: 4 }}
          >
            <Grid item>
              <CustomPagination
                shape="rounded"
                color="primary"
                count={Math.ceil(data!.total / perPage)}
                page={page}
                onChange={(_, newPage) => {
                  setPage(newPage);
                }}
              />
            </Grid>
            <Grid>
              <Box
                component={Button}
                size="large"
                sx={{
                  color: 'grey.900',
                  backgroundColor: 'primary.light',
                  ':hover': { bgcolor: 'primary.light' },
                  px: 2,
                  borderRadius: 4,
                }}
                endIcon={<ExpandMoreRoundedIcon />}
                onClick={handleClick}
              >
                {perPage} {t('teachers.courses.records')}
              </Box>

              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={() => handleClose(perPage)}
                variant="selectedMenu"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <MenuItem onClick={() => handleClose(6)}>
                  6 {t('teachers.courses.records')}
                </MenuItem>
                <MenuItem onClick={() => handleClose(12)}>
                  12 {t('teachers.courses.records')}
                </MenuItem>
                <MenuItem onClick={() => handleClose(18)}>
                  18 {t('teachers.courses.records')}
                </MenuItem>
              </Menu>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  ) : (
    <Box>
      <Grid container justifyContent={'center'} alignContent={'center'} sx={{ height: '50vh' }}>
        <Grid container justifyContent={'center'} item xs={8} md={6}>
          <Grid container item xs={12} justifyContent={'center'}>
            <IconTelework size={300} />
          </Grid>
          <Typography variant={'body1'} textAlign={'center'} lineHeight={2} component={'div'}>
            <Typography
              variant={'h1'}
              sx={{ color: theme.palette.error.main }}
              component={'p'}
              align={'center'}
              lineHeight={2}
            >
              {t('teachers.dashboard.error-data')}
            </Typography>
            <Typography variant={'h3'} sx={{ color: theme.palette.primary.main }}>
              {t('teachers.dashboard.error-data-detail')}
            </Typography>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CoursesList;
