import Chip from '../../../shared/components/extended/Chip';

interface Props {
  statusName: string;
}

const MeshStatusChip = ({ statusName }: Props) => {
  let chipcolor = '';

  switch (statusName) {
    case 'Vigente':
      chipcolor = 'success';
      break;
    case 'No Vigente':
      chipcolor = 'orange';
      break;
    default:
      chipcolor = 'info';
  }

  return <Chip label={statusName} size="small" chipcolor={chipcolor} />;
};

export default MeshStatusChip;
