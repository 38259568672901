import React, { useEffect, useState } from 'react';
import { Box, Grid, Menu, MenuItem, useTheme, Button } from '@material-ui/core';
import InfoEventsNewsCard from '../../../shared/components/cards/InfoEventsNewsCard';
import { useGetProfilesEventsNewsQuery } from '../../../maintenance/profiles/slices/profilesApiSlice';
import { useAppSelector } from '../../../../hooks/useRedux';
import InfoEventNewsSkeleton from '../../../shared/components/cards/Skeleton/InfoEventsNewCardSkeleton';
import CustomPagination from '../../../shared/components/tables/CustomPagination';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import InfoEventsNewsEmpty from '../../../shared/components/cards/InfoEventNewsEmpty';

const NewsList = function () {
  const theme = useTheme();
  const [page, setPage] = useState(1);
  // const [search, setSearch] = useState('');
  const [perPage, setPerPage] = useState(5);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { currentProfile } = useAppSelector((state) => state.access);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const { data, isLoading, isError } = useGetProfilesEventsNewsQuery({
    page: page,
    perPage: perPage,
    profileId: currentProfile?.keywordId!,
    search: 'notices',
  });

  const dataFetch = data?.data.map((value) => ({
    id: value.id,
    title: value.title,
    description: value.description,
    url_image: value.url_image!,
    start_date: value.start_date,
    end_date: value.end_date,
  }));

  const handleClose = (newPerPage: number) => {
    setAnchorEl(null);
    setPerPage(newPerPage);
  };

  useEffect(() => {
    setPage(1);
  }, [perPage]);

  return (
    <React.Fragment>
      {isLoading ? (
        <InfoEventNewsSkeleton />
      ) : (
        <Grid container direction="row" spacing={3}>
          {dataFetch &&
            dataFetch.map((value) => (
              <Grid key={value.id} item xs={12} sm={6} lg={4} xl={3}>
                <InfoEventsNewsCard
                  id={value.id}
                  title={value.title}
                  description={value.description}
                  start_date={value.start_date}
                  url_image={value.url_image}
                  key={value.title}
                  end_date={value.end_date}
                />
              </Grid>
            ))}
        </Grid>
      )}

      {!isLoading && !isError && (
        <Grid container justifyContent="space-between" alignItems="flex-end" sx={{ mt: 4 }}>
          <Grid item>
            <CustomPagination
              shape="rounded"
              color="primary"
              count={Math.ceil(data?.total! / perPage)}
              page={page}
              onChange={(_, newPage) => {
                setPage(newPage);
              }}
            />
          </Grid>
          <Grid>
            <Box
              component={Button}
              size="large"
              sx={{
                color: theme.palette.grey[900],
                backgroundColor: theme.palette.primary.light,
                ':hover': { bgcolor: theme.palette.primary.light },
                px: 2,
                borderRadius: 4,
              }}
              endIcon={<ExpandMoreRoundedIcon />}
              onClick={handleClick}
            >
              {perPage} Registros
            </Box>

            <Menu
              id="menu-user-list-style1"
              anchorEl={anchorEl}
              keepMounted
              open={open}
              onClose={() => handleClose(perPage)}
              variant="selectedMenu"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <MenuItem onClick={() => handleClose(5)}> 5 Registros</MenuItem>
              <MenuItem onClick={() => handleClose(10)}> 10 Registros</MenuItem>
              <MenuItem onClick={() => handleClose(15)}> 15 Registros</MenuItem>
            </Menu>
          </Grid>
        </Grid>
      )}

      {data?.data.length === 0 && <InfoEventsNewsEmpty type="notices" />}
    </React.Fragment>
  );
};

export default NewsList;
