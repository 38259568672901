import { Grid, IconButton, Tooltip } from '@material-ui/core';
import { IconCheckupList } from '@tabler/icons';
import { parseISO } from 'date-fns';
import { useAppSelector } from '../../../../hooks/useRedux';
import { useTableSearch } from '../../../../hooks/useTableSearch';
import SearchInput from '../../components/controlled/SearchInput';
import CustomPaginationAndItems from '../../components/tables/CustomPaginationAndItems';
import SearchPaginatedTable from '../../components/tables/SearchPaginatedTable';
import useFormatDate from '../../hooks/useFormatDate';
import { useUseGetEvaluationByEvaluatedQuery } from '../slices/userEvaluationsApiSlice';
import { updateUserEvaluationTable } from '../slices/userEvaluationsSlice';
import EvaluationCard from './EvaluationCard';

const UserEvaluationList = () => {
  const { userEvaluationListView } = useAppSelector((state) => state.userEvaluations);
  const { formatDate } = useFormatDate();

  const { page, perPage, search, filterArrow } = useAppSelector(
    (state) => state.userEvaluations.userEvaluationTable
  );

  const { setPage, setPerPage, setSearch, setFilterArrow } = useTableSearch({
    page,
    perPage,
    search,
    filterArrow,
    updateFunction: updateUserEvaluationTable,
  });

  const { data, error, isLoading, isFetching } = useUseGetEvaluationByEvaluatedQuery({
    page,
    perPage,
    search,
    ...(filterArrow && {
      sort: filterArrow.name,
      order: filterArrow.direction,
    }),
  });

  const datafetch =
    data?.data?.map((item, index) => ({
      id: index + 1,
      name: item.eva_release?.name || '',
      startDate: item.eva_release?.start_date
        ? formatDate(parseISO(item.eva_release?.start_date.toString()))
        : '',
      endDate: item.eva_release?.end_date
        ? formatDate(parseISO(item.eva_release?.end_date.toString()))
        : '',
      faculty: item.course.matter.education_level.edu_name || '',
      course: item.course_name || '',
      evaluated: item.user?.person?.pers_full_lastname || '',
      options: item,
    })) || [];

  return (
    <>
      {userEvaluationListView === 'list' && (
        <>
          <Grid container spacing={2}>
            <Grid item container xs={12}>
              <Grid item xs={12} md={4}>
                <SearchInput
                  search={search}
                  setSearch={setSearch}
                  setPage={setPage}
                  searchPlaceholder="Buscar evaluación"
                />
              </Grid>
            </Grid>
            {datafetch?.map((item) => (
              <Grid item xs={12} md={6} lg={4} key={item.id}>
                <EvaluationCard evaluation={item.options} />
              </Grid>
            ))}
            <Grid item xs={12}>
              <CustomPaginationAndItems
                error={error}
                isLoading={isLoading}
                page={page}
                setPage={setPage}
                perPage={perPage}
                setPerPage={setPerPage}
                total={data?.total || 0}
                paginationBox
                perPageBox
              />
            </Grid>
          </Grid>
        </>
      )}
      {userEvaluationListView === 'table' && (
        <SearchPaginatedTable
          data={datafetch}
          headers={{
            id: 'N°',
            name: 'Nombre',
            startDate: 'Fecha de inicio',
            endDate: 'Fecha de fin',
            faculty: 'Facultad',
            course: 'Curso',
            evaluated: 'Evaluado',
            options: 'Opciones',
          }}
          error={error}
          isLoading={isLoading}
          isFetching={isFetching}
          filterArrow={filterArrow}
          setFilterArrow={setFilterArrow}
          search={search}
          setSearch={setSearch}
          page={page}
          setPage={setPage}
          perPage={perPage}
          setPerPage={setPerPage}
          total={data?.total || 0}
          keyExtractor={(item) => item.id.toString()}
          numHeader={2}
          searchPlacehoder="Buscar evaluación"
          customRenderers={{
            options: (item) => (
              <>
                <Tooltip title="Realizar evaluación">
                  <IconButton>
                    <IconCheckupList />
                  </IconButton>
                </Tooltip>
              </>
            ),
          }}
          customDataCellsProperties={{
            id: { align: 'center' },
            name: { align: 'center' },
            startDate: { align: 'center' },
            endDate: { align: 'center' },
            options: { align: 'center' },
          }}
        />
      )}
    </>
  );
};

export default UserEvaluationList;
