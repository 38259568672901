import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISearchTable } from '../../../shared/interfaces/paginatedTable.interface';
import { EducationLevel } from '../interfaces/educationLevels.interfaces';
import { EducationLevelCollectiveResponse } from '../interfaces/educationLevelsCollective.interfaces';

interface educationLevelsState {
  educationLevelList: EducationLevel[];
  availablesEducationLevelList: EducationLevel[];
  deleteModalOpen: boolean;
  deleteCollectiveModalOpen: boolean;
  educationLevelIdToDelete?: number;
  currentEducationLevelId?: number;
  educationLevelsTable: ISearchTable;
  //Collective
  educationLevelCollectives: EducationLevelCollectiveResponse[];
  currentColective?: number;
  openCollaboratorsCollective?: boolean;

  offerIdSelected: number | null;
  principalEducationLevelIdSelected: number | null;
  statusIdSelected: number | null;
}

const initialState: educationLevelsState = {
  educationLevelCollectives: [],
  educationLevelList: [],
  availablesEducationLevelList: [],
  deleteModalOpen: false,
  deleteCollectiveModalOpen: false,
  educationLevelsTable: {
    page: 1,
    perPage: 5,
    search: '',
  },
  offerIdSelected: null,
  principalEducationLevelIdSelected: null,
  statusIdSelected: null,
};

const educationLevelsSlice = createSlice({
  name: 'educationLevels',
  initialState,
  reducers: {
    setEducationLevelList: (state, action: PayloadAction<EducationLevel[]>) => {
      state.educationLevelList = action.payload;
    },
    setAvailablesEducationLevelList: (state, action: PayloadAction<EducationLevel[]>) => {
      state.availablesEducationLevelList = action.payload;
    },
    setcurrentEducationLevelId: (state, action: PayloadAction<number>) => {
      state.currentEducationLevelId = action.payload;
    },
    setCurrentCollective: (state, action: PayloadAction<number>) => {
      state.currentColective = action.payload;
    },
    resetCurrentColective: (state) => {
      state.currentColective = undefined;
    },
    setDeleteModalOpen: (state, action: PayloadAction<boolean>) => {
      state.deleteModalOpen = action.payload;
    },
    setEducationLevelCollectives: (
      state,
      action: PayloadAction<EducationLevelCollectiveResponse[]>
    ) => {
      state.educationLevelCollectives = action.payload;
    },
    setOpenDialogCollCollective: (state, action: PayloadAction<boolean>) => {
      state.openCollaboratorsCollective = action.payload;
    },
    openDeleteModalWith: (state, action: PayloadAction<number>) => {
      state.educationLevelIdToDelete = action.payload;
      state.deleteModalOpen = true;
    },
    resetEducationLevelIdToDelete: (state) => {
      state.educationLevelIdToDelete = undefined;
    },
    setDeleteCollectiveModalOpen: (state, action: PayloadAction<boolean>) => {
      state.deleteCollectiveModalOpen = action.payload;
    },
    updateEducationLevelsTable: (state, action: PayloadAction<ISearchTable>) => {
      state.educationLevelsTable = action.payload;
    },
    setOfferIdSelected: (state, action: PayloadAction<number | null>) => {
      state.offerIdSelected = action.payload;
    },
    setPrincipalEducationLevelIdSelected: (state, action: PayloadAction<number | null>) => {
      state.principalEducationLevelIdSelected = action.payload;
    },
    setStatusIdSelected: (state, action: PayloadAction<number | null>) => {
      state.statusIdSelected = action.payload;
    },
  },
});

export const {
  setEducationLevelList,
  setAvailablesEducationLevelList,
  setDeleteModalOpen,
  setCurrentCollective,
  resetCurrentColective,
  setOpenDialogCollCollective,
  openDeleteModalWith,
  setEducationLevelCollectives,
  setcurrentEducationLevelId,
  setDeleteCollectiveModalOpen,
  resetEducationLevelIdToDelete,
  updateEducationLevelsTable,
  setOfferIdSelected,
  setPrincipalEducationLevelIdSelected,
  setStatusIdSelected,
} = educationLevelsSlice.actions;

export default educationLevelsSlice.reducer;
