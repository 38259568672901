// material-ui
import { Card, CardContent, Grid, Stack } from '@material-ui/core';
import type { CardProps } from '@material-ui/core';
import Skeleton from '@material-ui/core/Skeleton';

//-----------------------|| SKELETON EARNING CARD ||-----------------------//

interface Props extends Partial<CardProps> {}

const ProfileSkeletonCard = ({ className, sx }: Props) => {
  //   const classes = useStyles();
  return (
    <Card className={className} sx={sx}>
      <CardContent>
        <Stack>
          <Grid item>
            <Grid container spacing={1} justifyContent="space-between">
              <Grid item xs={10}>
                <Skeleton variant="rectangular" width="100%" height={44} />
              </Grid>
              <Grid item xs={2}>
                <Skeleton variant="rectangular" width="100%" height={34} />
              </Grid>
            </Grid>
          </Grid>

          <Grid container item xs={12} justifyContent="center" pt={1}>
            <Skeleton variant="rectangular" width="80%" height={40} />
          </Grid>

          <Grid container item xs={12} justifyContent="center" pt={1}>
            <Skeleton variant="circular" width={100} height={100} />
          </Grid>

          <Grid container item xs={12} justifyContent="flex-end" pt={1}>
            <Skeleton variant="rectangular" width="90%" height={20} />
          </Grid>
          <Grid container item xs={12} justifyContent="flex-end" pt={1}>
            <Skeleton variant="rectangular" width="70%" height={20} />
          </Grid>
          <Grid container item xs={12} justifyContent="flex-end" pt={1}>
            <Skeleton variant="rectangular" width="50%" height={20} />
          </Grid>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default ProfileSkeletonCard;
