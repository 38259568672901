import { forwardRef, useState, useEffect } from 'react';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Slide,
  SlideProps,
  Typography,
  useTheme,
  useMediaQuery,
  Autocomplete,
  TextField,
  Divider,
  Alert,
} from '@material-ui/core';
// import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
// import { SerializedError } from '@reduxjs/toolkit';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { IconArrowNarrowLeft } from '@tabler/icons';

import { useTranslation } from 'react-i18next';

// third-party
// import clsx from 'clsx';

// import AnimateButton from '../../../../shared/components/extended/AnimateButton';
// import { useDispatch } from 'react-redux';
import { useAppSelector, useAppDispatch } from '../../../../../hooks/useRedux';
import InputLabel from '../../../../shared/components/forms/InputLabel';
import TextFieldFetching from '../../../../shared/components/TextFieldFetching';
// import useGuard from '../../hooks/useGuard';
import {
  PaymethodTypesComponent,
  payValidMethodsType,
} from '../../interfaces/components.interface';

//forms
import CashMethod from './forms/CashMethod';
import ChequeMethod from './forms/ChequeMethod';
import CreditDebitCardMethod from './forms/CreditDebitCardMethod';
import { useGetPayMethodsByStudentQuery } from '../../slices/debtsPaymentApiSlice';
import { PayBalance, PayMethodsStudent } from '../../interfaces/debtsPayment.interface';
import { payValidMethods } from '../../constants';
import TransferDepositMethod from './forms/TransferDepositMethod';
import { setEarlyPayment } from '../../slices/debtsPaymentSlice';
import ExtendedSideDialog from '../../../../shared/components/dialogs/ExtendedSideDialog';
import { paymentMethods } from '../../../../shared/constants/globals';

//considerar crear el diccionario en un bucle con la data del request

const FormsDict: PaymethodTypesComponent = {
  [paymentMethods.cash as unknown as keyof PaymethodTypesComponent]: CashMethod,
  [paymentMethods.creditBalance as unknown as keyof PaymethodTypesComponent]: CashMethod,
  [paymentMethods.creditCard as unknown as keyof PaymethodTypesComponent]: CreditDebitCardMethod,
  [paymentMethods.debitCard as unknown as keyof PaymethodTypesComponent]: CreditDebitCardMethod,
  [paymentMethods.deposit as unknown as keyof PaymethodTypesComponent]: TransferDepositMethod,
  [paymentMethods.transfer as unknown as keyof PaymethodTypesComponent]: TransferDepositMethod,
  // [paymentMethods.web as unknown as keyof PaymethodTypesComponent]: CreditDebitCardMethod,
  [paymentMethods.cheque as unknown as keyof PaymethodTypesComponent]: ChequeMethod,
};

// style constant
const useStyles = makeStyles((theme) => ({
  userAddDialog: {
    '&>div:nth-child(3)': {
      justifyContent: 'flex-end',
      '&>div': {
        margin: '0px',
        borderRadius: '0px',
        maxWidth: '650px',
        maxHeight: '100%',
        '&>div.MuiDialogContent-root>div.scrollbar-container': {
          paddingLeft: '20px',
          overflow: 'hidden',
        },
      },
    },
  },
  paper: {
    minHeight: '100vh',
    width: '37%',
  },
  paperTable: {
    minHeight: '100vh',
    width: '50%!important',
  },
  movilPaper: {
    minHeight: '100vh',
    width: '100%',
  },
}));

// animation
const Transition = forwardRef(function Transition(props: SlideProps, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export interface Props {
  open: boolean;
  indexPayToEdit?: number;
  isEdit?: boolean;
  handleCloseDialog: (e: React.SyntheticEvent) => void;
  handleSetEditPayment?: (param: number | undefined) => void;
}

const AddPaymountMethod = ({
  open,
  indexPayToEdit,
  isEdit,
  handleCloseDialog,
  handleSetEditPayment,
}: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const isSm = useMediaQuery(theme.breakpoints.down('md'));

  const { currentProfile } = useAppSelector((state) => state.access);
  const { payCollection, currentClient, isPositiveBalanceTransac } = useAppSelector(
    (state) => state.debtsPayment
  );

  const [isAddPay, setIsAddPay] = useState(false);
  const [isAddPayOnEdit, setIsAddPayOnEdit] = useState(false);

  //reinciiar isAddPayOnEdit
  useEffect(() => {
    //como alternativa renderizar el dialog condicionalmente para que se reinicien los estados
    if (open) setIsAddPayOnEdit(false);
  }, [open]);

  //controlled autocomplete
  const [paymethodValue, setPaymethodValue] = useState<PayMethodsStudent>();

  const [inputValue, setInputValue] = useState('');

  const setIsPay = () => {
    setIsAddPay(true);
    if (indexPayToEdit?.toString()) setIsAddPayOnEdit(true);
  };

  useEffect(() => {
    if (isAddPay) setPaymethodValue(undefined);
  }, [isAddPay]);

  //   const { hasAccess } = useGuard();

  const { data: paymethods, isFetching } = useGetPayMethodsByStudentQuery({
    profileId: currentProfile?.profileId!,
    studentId: currentClient?.user.student?.id!,
  });

  // mandar por prop el positive balance y en cash method condicionar si es positive balance desabilitar el textfield
  const [positiveBalance, setPositiveBalance] = useState<number>();

  //TODO: centralizar (paymethods[1] as PayMethodsStudent)?.children en una variable

  // set early payment slice
  useEffect(() => {
    if (paymethods) {
      if (Number((paymethods[0] as PayBalance).pay_balance) > 0) {
        setPositiveBalance(Number((paymethods[0] as PayBalance).pay_balance));
      }

      const earlyPayment = (paymethods[1] as PayMethodsStudent).children.find(
        (payment) => payment.cat_keyword === paymentMethods.earlyPayment
      );

      if (earlyPayment) dispatch(setEarlyPayment(earlyPayment));
    }
  }, [dispatch, paymethods]);

  //change autocomplete value+

  useEffect(() => {
    if (indexPayToEdit?.toString() && open) {
      setPaymethodValue(
        paymethods &&
          (paymethods[1] as PayMethodsStudent)?.children.find(
            (e) => e.id === payCollection[indexPayToEdit].payment_way
          )
      );
      setInputValue(
        (paymethods &&
          (paymethods[1] as PayMethodsStudent)?.children.find(
            (e) => e.id === payCollection[indexPayToEdit].payment_way
          )?.cat_name) ||
          ''
      );
    }
  }, [indexPayToEdit, open, payCollection, paymethods]);

  const PaymethodForm =
    FormsDict[paymethodValue?.cat_keyword as unknown as keyof PaymethodTypesComponent];

  return (
    <ExtendedSideDialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCloseDialog}
      className={classes.userAddDialog}
      {...(!isSm && { classes: { paper: classes.paper } })}
      {...(isSm && { classes: { paper: classes.movilPaper } })}
      // {...classes={
      //   courseSideModalMode !== 'view'
      //     ? {
      //         paper: classes.paper,
      //       }
      //     : {
      //         paper: classes.paperTable,
      //       }
      // }}
    >
      <DialogTitle disableTypography>
        <Grid item container alignItems="center" gap={1}>
          <IconButton
            onClick={(e) => {
              handleCloseDialog(e);
            }}
          >
            <IconArrowNarrowLeft size={30} color={theme.palette.text.dark} />
          </IconButton>
          <Typography variant="h3">{t('financial.debts_payment.pay_dialog.title')}</Typography>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <PerfectScrollbar component={'div'}>
          {open && (
            <Grid container spacing={1}>
              {positiveBalance && !isPositiveBalanceTransac && (
                <Grid item xs={12}>
                  <Alert security="success">
                    {t('financial.debts_payment.pay_dialog.positive_balance_alert')}
                  </Alert>
                </Grid>
              )}
              <Grid item xs={12}>
                <InputLabel>
                  {t('financial.debts_payment.pay_dialog.payment_method_label')}
                </InputLabel>
                {isFetching ? (
                  <TextFieldFetching />
                ) : (
                  <Autocomplete
                    disabled={Boolean(indexPayToEdit?.toString()) && isEdit}
                    options={
                      paymethods
                        ? (paymethods[1] as PayMethodsStudent)?.children.filter((pm) =>
                            payValidMethods.some((b) => {
                              // conditional if positive balance is already in paycollection array oo
                              // isPositiveBalanceTransac is true no devuelve el metodo saldo a favor
                              if (
                                payCollection.some(
                                  (payc) =>
                                    payc.payment_way_keyword === paymentMethods.creditBalance
                                ) ||
                                isPositiveBalanceTransac
                              )
                                return pm.cat_keyword === b && b !== paymentMethods.creditBalance;

                              if (
                                payCollection.some(
                                  (payc) => payc.payment_way_keyword === paymentMethods.cash
                                )
                              ) {
                                return pm.cat_keyword === b && b !== paymentMethods.cash;
                              }

                              return pm.cat_keyword === b;
                            })
                          )
                        : []
                    }
                    loading={isFetching}
                    loadingText={t('financial.debts_payment.pay_dialog.payment_method_loading')}
                    getOptionLabel={(option) => option.cat_name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        // error={Boolean(errors.campusIds && touched.campusIds)}
                        placeholder={t(
                          'financial.debts_payment.pay_dialog.payment_method_placeholder'
                        )}
                      />
                    )}
                    value={paymethodValue || null}
                    inputValue={inputValue}
                    onChange={(_, values) => {
                      setPaymethodValue(values || undefined);
                      setIsAddPay(false);
                    }}
                    onInputChange={(event, newInputValue) => {
                      setInputValue(newInputValue);
                    }}
                    fullWidth
                  />
                )}
              </Grid>

              {paymethodValue && !isAddPayOnEdit ? (
                <>
                  <Grid item xs={12} my={1}>
                    <Typography variant="h3" component="p" mb={1} fontWeight={'normal'}>
                      {t('financial.debts_payment.pay_dialog.form_label_title')}
                    </Typography>
                    <Divider />
                  </Grid>

                  <Grid item xs={12}>
                    {/* formularios */}
                    <PaymethodForm
                      paymethod={{
                        id: paymethodValue?.id,
                        name: paymethodValue?.cat_name,
                        keyword: paymethodValue?.cat_keyword as payValidMethodsType,
                      }}
                      indexPayToEdit={indexPayToEdit}
                      setIsPay={setIsPay}
                      onCancel={handleCloseDialog}
                      handleSetEditPayment={handleSetEditPayment}
                      positiveBalance={positiveBalance}
                    />
                  </Grid>
                </>
              ) : (
                <Grid container p={2}>
                  {isAddPay ? (
                    isAddPayOnEdit ? (
                      <Grid item xs={12}>
                        <Alert severity="success">
                          {t('financial.debts_payment.pay_dialog.payment_update_label')}
                        </Alert>
                      </Grid>
                    ) : (
                      <Grid item xs={12}>
                        <Alert severity="success">
                          {t('financial.debts_payment.pay_dialog.payment_added_label')}
                        </Alert>
                      </Grid>
                    )
                  ) : (
                    <Grid item xs={12}>
                      <Alert severity="info">
                        {t('financial.debts_payment.pay_dialog.payment_add_label')}
                      </Alert>
                    </Grid>
                  )}
                </Grid>
              )}
            </Grid>
          )}
        </PerfectScrollbar>
      </DialogContent>
      <DialogActions>
        {/* <AnimateButton>
          <Button variant="contained" color="primary">
            Create
          </Button>
        </AnimateButton>
        <Button variant="text" onClick={handleCloseDialog} color="primary">
          Close
        </Button> */}
      </DialogActions>
    </ExtendedSideDialog>
  );
};

export default AddPaymountMethod;
