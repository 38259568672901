import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { IconButton, Grid, useTheme, Typography } from '@material-ui/core';
import { IconArrowNarrowLeft } from '@tabler/icons';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import EditPeriodForm from '../components/EditEducationLevelForm';
import MainCardForm from '../../../shared/components/cards/MainCardForm';
import { useLazyGetEducationLevelQuery } from '../slices/educationLevelsApiSlice';
import { setEducationLevelList } from '../slices/educationLevelsSlice';
import SimpleFormOneColumn from '../../../shared/components/forms/skeletons/SimpleFormOneColumn';

const EditPeriodScreen = () => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const { educationLevelId } = useParams<{ educationLevelId: string }>();
  const { educationLevelList } = useAppSelector((state) => state.educationLevels);

  const [getEducationLevel, { isFetching }] = useLazyGetEducationLevelQuery();

  useEffect(() => {
    if (!educationLevelList.length && !isFetching) {
      getEducationLevel({
        educationLevelId: +educationLevelId,
      })
        .unwrap()
        .then((response) => {
          if (response) dispatch(setEducationLevelList([response!]));
        })
        .catch((error) => console.log(error));
    }
  }, [getEducationLevel, isFetching, educationLevelId, educationLevelList.length, dispatch]);

  return (
    <MainCardForm>
      <Grid item xs={12}>
        <Grid item container alignItems="center" gap={1} sx={{ cursor: 'pointer' }}>
          <IconButton onClick={() => history.goBack()}>
            <IconArrowNarrowLeft size={30} color={theme.palette.text.dark} />
          </IconButton>
          <Typography variant="h2">Editar</Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} md={8} lg={6} sx={{ mt: 2 }}>
        {Boolean(educationLevelList.length) ? <EditPeriodForm /> : <SimpleFormOneColumn />}
      </Grid>
    </MainCardForm>
  );
};

export default EditPeriodScreen;
