import { Box, Button, ButtonGroup, Grid, Typography } from '@material-ui/core';
import { FileDownloadOutlined, UploadFileOutlined, MessageOutlined } from '@material-ui/icons';
import downloadjs from 'downloadjs';

import {
  DetailsActivity,
  ToolsMaterial,
  CabActivityStudent,
} from '../../interfaces/tasks.interface';
import ModalArchivos from '../ModalArchivos';
import ModalComment from '../ModalComment';
import { useTranslation } from 'react-i18next';
import useGetUrlDownload from '../../../../../../hooks/useGetUrlDownload';

interface Props {
  infoBody: {
    detailsActivity: DetailsActivity[];
    toolsMaterials: ToolsMaterial[];
    calification: CabActivityStudent | null;
    taskId: number;
    numTries: string | number | null;
    numTriesRealized: string | number | null;
  };
  isUploadTask: boolean;
  isDateEnterRange: boolean;
  modalOptions: {
    viewModal: {
      open: boolean;
      type: 'comment' | 'file';
      detailId: number;
    };
    setViewModal: React.Dispatch<
      React.SetStateAction<{
        open: boolean;
        type: 'comment' | 'file';
        detailId: number;
      }>
    >;
    refetch: () => void;
  };
}

const TaskItemBody = ({ infoBody, isUploadTask, modalOptions, isDateEnterRange }: Props) => {
  const { calification, detailsActivity, numTries, numTriesRealized } = infoBody;
  const { viewModal, setViewModal, refetch } = modalOptions;

  const { t } = useTranslation();
  // const obtainDomainFile = function (url_image: string) {
  //   return `${baseUrl}/show?name=${url_image}`;
  // };

  // const downloadFiles = () => {
  //   toolsMaterials.forEach((tool) => {
  //     try {
  //       const url = obtainDomainFile(tool.tool_ms_url_file);
  //       downloadjs(url, tool.tool_ms_name_file);
  //     } catch (error) {
  //       console.log(Error);
  //     }
  //   });
  // };

  const { urlDownload } = useGetUrlDownload();

  const downloadSubmittedAssignments = () => {
    detailsActivity.forEach((tool) => {
      try {
        let position =
          tool.answered_activities.length > 0 ? tool.answered_activities.length - 1 : 0;
        const { csact_url_file: url } = tool.answered_activities[position];
        const urlComplete = `${urlDownload}/${url}`;
        console.log(urlComplete);
        downloadjs(urlComplete);
      } catch (error) {
        console.log(Error);
      }
    });
  };

  const downloadFileByUrl = (url: string) => {
    try {
      const urlComplete = `${urlDownload}/${url}`;
      downloadjs(urlComplete);
    } catch (error) {
      console.log(Error);
    }
  };
  console.log(downloadSubmittedAssignments);

  return (
    <Box>
      {/* {Number(infoBody?.calification?.num_tries_realized) > 0 && (
        <Box
          sx={{
            p: 2,
            borderTop: '1px solid #e0e0e0',
            borderBottom: '1px solid #e0e0e0',
          }}
        >
          <Button
            startIcon={<FileDownloadOutlined />}
            fullWidth
            variant="contained"
            sx={{
              backgroundColor: '#313B43',
              '&:hover': {
                backgroundColor: '#313B43',
              },
            }}
            onClick={downloadSubmittedAssignments}
          >
            {t('students.virtual_classroom.task.modal.file.recomendations.task.sends')}
          </Button>
        </Box>
      )} */}
      {/* {toolsMaterials.length > 0 ? (
        <>
          <Box
            sx={{
              p: 2,
              borderTop: '1px solid #e0e0e0',
              borderBottom: '1px solid #e0e0e0',
            }}
          >
            <Button
              startIcon={<FileDownloadOutlined />}
              fullWidth
              variant="contained"
              sx={{
                backgroundColor: '#313B43',
                '&:hover': {
                  backgroundColor: '#313B43',
                },
              }}
              onClick={downloadFiles}
            >
              {t('students.virtual_classroom.task.card.download')}
            </Button>
          </Box>
        </>
      ) : ( */}
      <Box
        sx={{
          pl: 6,
          pr: 2,
          py: 2,
          borderTop: '1px solid #e0e0e0',
          borderBottom: '1px solid #e0e0e0',
        }}
      >
        <Typography
          sx={{
            fontWeight: 'bold',
          }}
        >
          {t('students.virtual_classroom.task.card.indications')}:
        </Typography>
        {detailsActivity.map((detail) => {
          return (
            <Grid container alignItems="center" justifyContent="space-between" mt={2}>
              <Grid item xs={12} md={6} lg={8}>
                <Typography key={`${detail.id}`}>{detail.dta_question}.</Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                {(calification?.answer_qualified !== '0' ||
                  calification?.answer_qualified !== null) &&
                  (detail.type_answer.cat_acronym === 'TPR3' ? (
                    <Button
                      startIcon={<MessageOutlined />}
                      variant="contained"
                      sx={{
                        backgroundColor: '#313B43',
                        '&:hover': {
                          backgroundColor: '#313B43',
                        },
                      }}
                      onClick={() =>
                        setViewModal({
                          open: true,
                          type: 'comment',
                          detailId: detail.id,
                        })
                      }
                      disabled={Number(numTriesRealized) >= Number(numTries) || !isDateEnterRange}
                    >
                      {t('students.virtual_classroom.task.card.comment')}
                    </Button>
                  ) : !detail.answered_activities?.length ? (
                    <Button
                      startIcon={<UploadFileOutlined />}
                      fullWidth
                      variant="contained"
                      sx={{
                        backgroundColor: '#313B43',
                        '&:hover': {
                          backgroundColor: '#313B43',
                        },
                      }}
                      onClick={() =>
                        setViewModal({
                          open: true,
                          type: 'file',
                          detailId: detail.id,
                        })
                      }
                      disabled={Number(numTriesRealized) >= Number(numTries) || !isDateEnterRange}
                    >
                      {t('students.virtual_classroom.task.card.upload')}
                    </Button>
                  ) : (
                    <ButtonGroup>
                      <Button
                        startIcon={<UploadFileOutlined />}
                        fullWidth
                        variant="contained"
                        sx={{
                          backgroundColor: '#313B43',
                          '&:hover': {
                            backgroundColor: '#313B43',
                          },
                        }}
                        onClick={() =>
                          setViewModal({
                            open: true,
                            type: 'file',
                            detailId: detail.id,
                          })
                        }
                        disabled={Number(numTriesRealized) >= Number(numTries) || !isDateEnterRange}
                      >
                        {t('students.virtual_classroom.task.card.upload')}
                      </Button>

                      <Button
                        startIcon={<FileDownloadOutlined />}
                        fullWidth
                        variant="contained"
                        // color=""
                        // sx={{
                        //   backgroundColor: '#313B43',
                        //   '&:hover': {
                        //     backgroundColor: '#313B43',
                        //   },
                        // }}
                        disabled={!detail.answered_activities[0].csact_url_file}
                        onClick={() =>
                          downloadFileByUrl(detail.answered_activities[0].csact_url_file)
                        }
                      />
                    </ButtonGroup>
                  ))}
              </Grid>
            </Grid>
          );
        })}
      </Box>
      {/* )} */}
      {/* {isDateEnterRange &&
        (calification?.answer_qualified !== '0' || calification?.answer_qualified !== null) &&
        !isUploadTask &&
        detailsActivity.map((detail) => {
          if (detail.type_answer_id === '625') {
            return (
              <Box
                sx={{
                  p: 2,
                }}
              >
                <Button
                  startIcon={<MessageOutlined />}
                  fullWidth
                  variant="contained"
                  sx={{
                    backgroundColor: '#313B43',
                    '&:hover': {
                      backgroundColor: '#313B43',
                    },
                  }}
                  onClick={() =>
                    setViewModal({
                      open: true,
                      type: 'comment',
                      detailId: detail.id,
                    })
                  }
                >
                  {t('students.virtual_classroom.task.card.comment')}
                </Button>
              </Box>
            );
          }
          return (
            <Box
              sx={{
                p: 2,
              }}
            >
              <Button
                startIcon={<UploadFileOutlined />}
                fullWidth
                variant="contained"
                sx={{
                  backgroundColor: '#313B43',
                  '&:hover': {
                    backgroundColor: '#313B43',
                  },
                }}
                onClick={() =>
                  setViewModal({
                    open: true,
                    type: 'file',
                    detailId: detail.id,
                  })
                }
              >
                {t('students.virtual_classroom.task.card.upload')}
              </Button>
            </Box>
          );
        })} */}

      {viewModal.open && viewModal.type === 'file' && (
        <ModalArchivos
          dataModal={viewModal}
          setOpen={setViewModal}
          details={detailsActivity}
          taskId={infoBody.taskId}
          refetch={refetch}
        />
      )}

      {viewModal.open && viewModal.type === 'comment' && (
        <ModalComment
          dataModal={viewModal}
          setOpen={setViewModal}
          details={detailsActivity}
          taskId={infoBody.taskId}
          refetch={refetch}
        />
      )}
    </Box>
  );
};

export default TaskItemBody;
