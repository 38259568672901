import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../../hooks/useRedux';
import MainCard from '../../../shared/components/cards/MainCard';
import ChangeStatusDialog from '../components/ChangeStatusDialog';
import PaychecksTable from '../components/PaychecksTable';

const PeriodsScreen = () => {
  const { t } = useTranslation();

  const { payCheckId } = useAppSelector((state) => state.payCheck);

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">{t('financial.pay_checks.title')}</Typography>
      </Grid>

      <PaychecksTable />

      {payCheckId && <ChangeStatusDialog />}
    </Grid>
  );
};

export default PeriodsScreen;
