import DeleteAlert from '../../../shared/components/dialogs/DeleteAlert';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { resetConfigProductSelected, setConfigProductDeleteModal } from '../slices/configProductPriceSlice';
import { useDeleteConfigProductPriceMutation } from '../slices/configProductPriceApiSlice';

const ConfigProductPriceDeleteDialog = () => {
  const dispatch = useAppDispatch();

  const { configProductSelected, configProductDeleteModal} = useAppSelector((state) => state.configProducts);

  const {currentProfile} = useAppSelector((state)=>state.access)
  
  const [deleteConfigProduct, {isLoading, isSuccess, isError, error}] = useDeleteConfigProductPriceMutation();

  const handleDelete = async () => {
    await deleteConfigProduct({
        productConfig: configProductSelected!,
        user_profile_id: currentProfile?.profileId!
    })
  };

  const handleClose = () => {
    dispatch(setConfigProductDeleteModal(false));
    dispatch(resetConfigProductSelected());
  };

  return (
    <DeleteAlert
      open={configProductDeleteModal}
      onClose={handleClose}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      handleDelete={handleDelete}
      error={error as string}
    />
  );
};

export default ConfigProductPriceDeleteDialog;
