import * as Yup from 'yup';

export const PensumInfoSchema = Yup.object().shape({
  name: Yup.string().required('El nombre es requerido.'),
  description: Yup.string().required('La descripción es requerida.'),
  acronym: Yup.string()
    .required('El acrónimo es requerido.')
    .min(2, 'Mínimo 2 caracteres.')
    .max(3, 'Maximo 3 caracteres.'),
  anio: Yup.number().required('El año es requerido.'),
  statusId: Yup.number().required('El estado del permiso es requerido.'),
});