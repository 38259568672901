import { Grid, Typography } from '@material-ui/core';
import MainCard from '../../../shared/components/cards/MainCard';
import MissedExamForm from '../components/MissedExamForm';

import { useTranslation } from 'react-i18next';

const MissedExamScreen = () => {
  const { t } = useTranslation();

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">{t('students.menu.applications.academic.exam-late')}</Typography>
      </Grid>
      <Grid item xs={12} md={8} lg={6} sx={{ mt: 2, minHeight: '55vh' }}>
        <MissedExamForm />
      </Grid>
    </Grid>
  );
};
export default MissedExamScreen;
