import { Grid, Typography } from '@material-ui/core';
// import { useParams } from 'react-router-dom';
// import { useAppSelector } from '../../../../hooks/useRedux';
import MainCard from '../../../shared/components/cards/MainCard';
// import PeriodStagesDelete from '../components/PeriodStagesDelete';
import GeneralApplicationsTable from '../components/GeneralApplicationsTable';

const PeriodStageScreen = () => {
  // const { periodId } = useParams<{ periodId: string }>();
  // const { periodList } = useAppSelector((state) => state.periods);
  // const period = periodList.find((value) => value.id === Number(periodId));

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">Listado de Solicitudes</Typography>
      </Grid>

      <GeneralApplicationsTable />

      {/* {periodStageIdToDelete && <PeriodStagesDelete />} */}
    </Grid>
  );
};

export default PeriodStageScreen;
