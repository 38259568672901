import { ConsulateCertificatePayload } from '../interfaces/consulateCertificate.interfaces';
import { CreditCertificatePayload } from '../interfaces/creditCertificate.interfaces';
import { ProfessionalPracticeCertificatePayload } from '../interfaces/professionalPracticeCertificate.interfaces';
import { StudentRecordPayload } from '../interfaces/studentRecordCertificate.interfaces';
import { StudyCertificatePayload } from '../interfaces/studyCertificate.interfaces';

export const studentRecordCertificateValues: StudentRecordPayload = {
  lang: '',
};

export const studyCertificateValues: StudyCertificatePayload = {
  period: '',
};

export const creditCertificateValues: CreditCertificatePayload = {
  to: '',
  period_id: 0,
};

export const consulateCertificateValues: ConsulateCertificatePayload = {
  consul: '',
};

export const professionalPracticeCertificateValues: ProfessionalPracticeCertificatePayload = {
  degree: '',
  name: '',
  position: '',
  work_location: '',
  city: '',
};
