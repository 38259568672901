import { mainApi } from '../../../../api/mainApi';
import { middlewareApi } from '../../../middleware/middlewareApi';
import { ApplicationStatus } from '../interfaces/applicationStatus.interface';
import { RolToAssign } from '../interfaces/rol-assing.interface';
import { StatusApplication } from '../interfaces/statusApplication.interface';
import { RequestsStatusResponse } from '../interfaces/typesApplications.interface';

export const requestsStatusTags = middlewareApi.enhanceEndpoints({
  addTagTypes: ['RequestsStatus'],
});

export const requestsStatusApi = requestsStatusTags.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * @GET requests status list
     */
    getRequestsStatusList: builder.query<
      RequestsStatusResponse,
      {
        user_profile_id: number;
        page: number;
        size: number;
        sort: string;
        type_sort: string;
        data_: string;
        search: string;
      }
    >({
      queryFn: async ({ user_profile_id, page, size, sort, type_sort, data_, search }) => {
        try {
          const { data } = await mainApi.get<RequestsStatusResponse>(
            '/type-application/all/children-applications',
            {
              params: {
                user_profile_id,
                page,
                size,
                sort,
                type_sort,
                data: data_,
                search,
              },
            }
          );
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    /**
     * @GET details status
     */
    getDetailsStatus: builder.query<
      StatusApplication[],
      {
        user_profile_id: number;
        id_application: number;
        page?: number;
        size?: number;
        sort?: string;
        type_sort?: string;
        data_?: string;
        search?: string;
      }
    >({
      queryFn: async ({
        user_profile_id,
        id_application,
        page,
        size,
        sort,
        type_sort,
        data_,
        search,
      }) => {
        try {
          const { data } = await mainApi.get<StatusApplication[]>(
            `/type-application/${id_application}/status`,
            {
              params: {
                user_profile_id,
                page,
                size,
                sort,
                type_sort,
                data: data_,
                search,
              },
            }
          );
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    /**
     * @GET details status
     */
    getApplicationStatusByAcronym: builder.query<
      StatusApplication[],
      {
        user_profile_id: number;
        acronym: string;
      }
    >({
      queryFn: async ({ user_profile_id, acronym }) => {
        try {
          const { data } = await mainApi.get<StatusApplication[]>(`/type-application/0/status`, {
            params: {
              user_profile_id,
              acronym_app: acronym,
              data: 'all',
            },
          });
          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    /**
     * @GET list Application Status
     */
    getApplicationStatus: builder.query<
      ApplicationStatus[],
      {
        user_profile_id: number;
      }
    >({
      queryFn: async ({ user_profile_id }) => {
        try {
          const { data } = await mainApi.get<ApplicationStatus[]>(`/application-status`, {
            params: {
              user_profile_id,
              data: 'all',
            },
          });
          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
    }),
    /**
     * @POST add status request
     */
    addStatusApplication: builder.mutation<
      any,
      {
        user_profile_id: string | number;
        st_name: string;
      }
    >({
      queryFn: async ({ user_profile_id, st_name }) => {
        try {
          const { data } = await mainApi.post<any>('/application-status', {
            user_profile_id,
            st_name,
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),
    /**
     *@POST UPDATE status
     */
    updateStatusApplicationStatus: builder.mutation<
      any,
      {
        user_profile_id: string | number;
        type_application_id: string | number;
        status_id: string[];
      }
    >({
      queryFn: async ({ user_profile_id, type_application_id, status_id }) => {
        try {
          const { data } = await mainApi.post<any>('type-application-status', {
            user_profile_id,
            type_application_id,
            status_id,
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),
    /**
     * @GET list roles to assing
     */
    getRolesToAssing: builder.query<
      RolToAssign[],
      {
        user_profile_id: string | number;
      }
    >({
      queryFn: async ({ user_profile_id }) => {
        try {
          const { data } = await mainApi.get<RolToAssign[]>('roles/availables/to-assign', {
            params: {
              user_profile_id,
            },
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),
    /**
     * @POST assing roles to status
     */
    assingRolesToStatus: builder.mutation<
      any,
      {
        user_profile_id: string | number;
        type_application_status_id: string | number;
        roles: number[];
      }
    >({
      queryFn: async ({ user_profile_id, type_application_status_id, roles }) => {
        try {
          const { data } = await mainApi.post<any>('type-application-status-roles', {
            user_profile_id,
            type_application_status_id,
            roles,
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),
  }),
});

export const {
  useGetRequestsStatusListQuery,
  useGetDetailsStatusQuery,
  useGetApplicationStatusQuery,
  useAddStatusApplicationMutation,
  useUpdateStatusApplicationStatusMutation,
  useGetRolesToAssingQuery,
  useAssingRolesToStatusMutation,
  useGetApplicationStatusByAcronymQuery,
} = requestsStatusApi;
