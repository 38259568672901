import { useState, useMemo, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import SearchPaginatedTable, {
  FilterArrow,
} from '../../../../../shared/components/tables/SearchPaginatedTable';
import {
  useDeleteTaskMutation,
  useGetTaskTeacherQuery,
  useVisibilityTaskMutation,
} from '../../../slices/virtualClassroomTeacherApiSlice';
import { useAppSelector } from '../../../../../../hooks/useRedux';
import { x } from '../../interfaces/task-list.interfaces';

import {
  Button,
  ButtonGroup,
  Grid,
  IconButton,
  Typography,
  Checkbox,
  Autocomplete,
  TextField,
  Tooltip,
  Dialog,
} from '@material-ui/core';
import {
  IconBrandWindows,
  IconListDetails,
  IconPlus,
  IconTrash,
  IconCalendarEvent,
} from '@tabler/icons';
import { makeStyles } from '@material-ui/styles';
import FeedOutlinedIcon from '@material-ui/icons/FeedOutlined';
import { getTime } from 'date-fns';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import { useTranslation } from 'react-i18next';
import CardTask from '../CardtTask';
import ErrorAlert from '../../../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../../../shared/components/alerts/SuccessAlert';
import { useGetSupplyCourseQuery } from '../../../evaluations/slices/evaluationApiSlice';
import { ResetTask, setTaskDetail } from '../../../slices/virtualClassroomTeacherSlice';
import Chip from '../../../../../shared/components/extended/Chip';
import EditDate from '../EditDate/EditDate';
import { setTasksList } from '../../slices/virtualClassroomsTeachersTasksSlice';
import { virtualClassActivitiesStatus } from '../../../../../shared/constants/resourceStatus';

const Style = makeStyles({
  combolist: {
    '& .css-1o2jng6-MuiOutlinedInput-notchedOutline': {
      border: 'none',
      overflow: 'hidden',
    },
    '& .css-1kty9di-MuiFormLabel-root-MuiInputLabel-root': {
      fontSize: 0,
    },
  },
});

const TaskList = () => {
  const { path } = useRouteMatch();

  const dispatch = useDispatch();

  const history = useHistory();

  const [page, setPage] = useState<number>(1);

  const [perPage, setPerPage] = useState<number>(5);

  const [search, setSearch] = useState<string>('');

  const [filterArrow, setFilterArrow] = useState<FilterArrow>(null!);

  const [showAlerts, setShowAlerts] = useState<boolean>(false);

  const { currentProfile } = useAppSelector((state) => state.access);

  const { isFinishedCourse } = useAppSelector((state) => state.virtualClassroomTeacherShared);

  const [Table, setTable] = useState(true);

  const [supply, setSupply] = useState<number | null>(null);
  const [status, setStatus] = useState<string | null>(null);

  const { t } = useTranslation();

  const [updateDate, setUpdateDate] = useState<{
    modal: boolean;
    endDate: Date;
    starDate: Date;
    activity: number;
  }>({
    modal: false,
    endDate: new Date(),
    starDate: new Date(),
    activity: 0,
  });

  const {
    course: { courseId },
  } = useAppSelector((state) => state.virtualClassroomTeacherShared);

  //{ isLoading, isSuccess, isError, error }
  const [DeleteAction, InfoDeleteTask] = useDeleteTaskMutation();

  const { data, isLoading, error, isFetching } = useGetTaskTeacherQuery(
    {
      courseId: courseId!,
      page: page,
      perPage: perPage,
      search: search,
      userProfileId: currentProfile?.profileId!,
      ...(supply && { supply }),
      ...(status && { estado: status }),
      ...(filterArrow && {
        sort: filterArrow.name,
        type_sort: filterArrow.direction,
      }),
    },
    { skip: courseId === 0 || courseId === undefined }
  );

  useEffect(() => {
    if (data?.data) {
      dispatch(setTasksList(data.data));
    }
  }, [dispatch, data]);

  const Supplies = useGetSupplyCourseQuery({
    course: courseId!,
  });

  const estilos = Style();

  const ELiminarTask = async (taskId: number) => {
    await DeleteAction({
      courseId: courseId!,
      taskId: taskId,
    });
    setShowAlerts(true);
  };

  const [UpdateVisible, InfoVisible] = useVisibilityTaskMutation();

  const UpdateVisibilityNote = async (taskID: number, visibleNote: string) => {
    await UpdateVisible({
      taskId: taskID,
      visiblePayload: {
        visible: visibleNote,
        type: 'visible_note',
      },
    });
    setShowAlerts(true);
  };
  const OpenModalUpdate = (open: boolean, activityId: number, starDate: Date, endDate: Date) => {
    setUpdateDate({ activity: activityId, endDate: endDate, starDate: starDate, modal: open });
  };

  async function UpdateVisibleTask(id: number, visible: string) {
    await UpdateVisible({
      taskId: id,
      visiblePayload: {
        visible: visible,
        type: 'visible',
      },
    });
    setShowAlerts(true);
  }

  const UpdateDate = () => {
    return (
      <Dialog
        onClose={() => setUpdateDate({ ...updateDate, modal: false })}
        open={updateDate.modal}
      >
        <Grid padding={'8px 16px'}>
          <Typography variant="h3" textAlign={'center'} marginBottom="15px">
            {t('teacherVirtualClassroom.tasks.tooltip.updateDate')}
          </Typography>
          <EditDate
            courseId={courseId}
            taskId={updateDate.activity}
            fechaInicio={updateDate.starDate}
            fechaFin={updateDate.endDate}
          />
        </Grid>
      </Dialog>
    );
  };

  const dataFetch = useMemo(() => {
    // TODO: Parche temporal
    return (Array.isArray(data?.data as unknown as x[]) ? (data?.data as unknown as x[]) : [])?.map(
      (e) =>
        ({
          id: e.id,
          nombre: e.act_description,
          Estado: 'Pendiente',
          Descripcion: {
            fechainicio: e.course_activity_one.cact_start_date,
            fechafin: e.course_activity_one.cact_end_date,
          },
          Visible: {
            id: e.id,
            visible: e.visible,
          },
          Visible_note: e,

          Acciones: '',
        } || [])
    );
  }, [data]);

  const handleTaskCreate = () => {
    dispatch(ResetTask());
    history.push(`${path}/crear`);
  };

  const TableData = () => {
    return (
      <SearchPaginatedTable
        data={dataFetch}
        headers={{
          id: `${t('teacherVirtualClassroom.tasks.table.cod')}`,
          nombre: '',
          Estado: `${t('teacherVirtualClassroom.tasks.table.estado')}`,
          Descripcion: t('students.applications.bonding.projects_table.dates'),
          Visible: t('teacherVirtualClassroom.tasks.button.visible'),
          Visible_note: t('teacherVirtualClassroom.tasks.button.visible-note'),
          Acciones: '',
        }}
        page={page}
        listFilterArrows={{
          nombre: 'act_description',
        }}
        filterArrow={filterArrow}
        setFilterArrow={setFilterArrow}
        setPage={setPage}
        perPage={perPage}
        setPerPage={setPerPage}
        search={search}
        setSearch={setSearch}
        total={Math.ceil(data?.total!) || 0}
        isLoading={isLoading}
        error={error}
        searchPlacehoder="Buscar tarea"
        keyExtractor={(item) => item.id.toString()}
        isFetching={isFetching}
        numHeader={5}
        ActionButtons={<></>}
        showFilter={false}
        perPageBox={true}
        paginationBox={true}
        customDataCellsProperties={{
          id: {
            align: 'center',
          },
          Estado: {
            align: 'center',
          },
          Visible: {
            align: 'center',
          },

          Visible_note: {
            align: 'center',
          },
        }}
        customHeadersCellsProperties={{
          id: {
            align: 'center',
          },
        }}
        customRenderers={{
          // id: (row) => row.id.id,
          Visible_note: (row) => (
            <Checkbox
              onChange={() =>
                UpdateVisibilityNote(row.id, row.Visible_note.visible_note === '1' ? '0' : '1')
              }
              checked={Boolean(row.Visible_note.visible_note === '1')}
            />
          ),
          Visible: (row) => (
            <Checkbox
              checked={Boolean(row.Visible.visible === '1')}
              onChange={() => UpdateVisibleTask(row.id, row.Visible.visible === '1' ? '0' : '1')}
            />
          ),

          Descripcion: (row) => (
            <Grid
              display="flex"
              justifyContent={'center'}
              width="100%"
              alignItems={'center'}
              direction="row"
              gap={'12px'}
            >
              <Grid item xs={6} textAlign={'justify'}>
                <Typography variant="body1" fontSize={'small'} fontWeight={'bold'}>
                  {t('teacherVirtualClassroom.tasks.fecha.inicio')}
                </Typography>
                <Typography variant="body2">
                  {moment(row.Descripcion.fechainicio).format('LLL')}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography fontSize={'small'} variant="body1" fontWeight={'bold'}>
                  {t('teacherVirtualClassroom.tasks.fecha.fin')}
                </Typography>
                <Typography variant="body2">
                  {moment(row.Descripcion.fechafin).format('LLL')}
                </Typography>
              </Grid>

              {/*
              
              <Grid marginRight={'5px'} item alignItems={'center'} display={'flex'}>
                <Typography variant="subtitle1" color="grey" marginRight={'8px'}>
                  {t('teacherVirtualClassroom.tasks.fecha.inicio')} :
                </Typography>
                <Typography variant="body1">
                  {moment(row.Descripcion.fechainicio).format('L')}
                </Typography>
              </Grid>
              <Grid item alignItems={'center'} display={'flex'}>
                <Typography variant="subtitle1" marginRight={'8px'} color="grey">
                  {t('teacherVirtualClassroom.tasks.fecha.fin')}:
                </Typography>
                <Typography variant="body1">
                  {moment(row.Descripcion.fechafin).format('L')}
                </Typography>

            

              </Grid>
              */}
            </Grid>
          ),
          Acciones: (row) => (
            <Grid style={{ display: 'flex', alignItems: 'center' }}>
              <ButtonGroup>
                <Tooltip title={t('teacherVirtualClassroom.tasks.tooltip.update')}>
                  <IconButton
                    disabled={isFinishedCourse}
                    onClick={() =>
                      OpenModalUpdate(
                        true,
                        row.id,
                        row.Descripcion.fechainicio,
                        row.Descripcion.fechafin
                      )
                    }
                  >
                    <IconCalendarEvent color="gray" />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t('teacherVirtualClassroom.tasks.tooltip.Detail')}>
                  <IconButton
                    onClick={() => {
                      dispatch(setTaskDetail(row.Visible_note));
                      history.push(`/administrar-aula-virtual/actividades/course/detail/${row.id}`);
                    }}
                  >
                    <FeedOutlinedIcon sx={{ color: 'gray' }} />
                  </IconButton>
                </Tooltip>

                {/* <Tooltip title={t('teacherVirtualClassroom.tasks.tooltip.edit')}>
                  <IconButton
                    onClick={() =>
                      history.push(
                        `/administrar-aula-virtual/actividades/course/${courseId}/task/${row.id}`
                      )
                    }
                  >
                    <IconListCheck color="gray" />
                  </IconButton>
                </Tooltip> */}
                <Tooltip title={t('teacherVirtualClassroom.tasks.tooltip.delete')}>
                  <IconButton
                    disabled={isFinishedCourse}
                    onClick={() => ELiminarTask(row.id)}
                    title={t('teacherVirtualClassroom.tasks.tooltip.delete')}
                  >
                    <IconTrash color="red" />
                  </IconButton>
                </Tooltip>
              </ButtonGroup>
            </Grid>
          ),
          Estado: (row) => (
            <>
              {getTime(Date.now()) > getTime(new Date(row.Descripcion.fechafin)) ? (
                <Chip label={t('shared.input.resource_status.completed')} chipcolor="error" />
              ) : getTime(Date.now()) > getTime(new Date(row.Descripcion.fechainicio!)) ? (
                <Chip label={t('shared.input.resource_status.in_process')} chipcolor="warning" />
              ) : (
                <Chip label={t('shared.input.resource_status.pending')} chipcolor="success" />
              )}
            </>
          ),
        }}
      />
    );
  };
  const CardData = () => {
    return (
      <Grid container mt={'20px'} padding={'15px 20px'} spacing={2}>
        {dataFetch.map((item) => (
          <CardTask
            id={item.id}
            isLoading={InfoDeleteTask.isLoading}
            handleSubmit={() => ELiminarTask(item.id)}
            handleUpdate={() =>
              OpenModalUpdate(
                true,
                item.id,
                item.Descripcion.fechainicio,
                item.Descripcion.fechafin
              )
            }
            nombre={item.nombre}
            startDate={item.Descripcion.fechainicio}
            endDate={item.Descripcion.fechafin}
          />
        ))}
      </Grid>
    );
  };
  return (
    <>
      <Grid container alignItems={'center'} padding={'0 20px'} justifyContent={'space-between'}>
        <Grid width={'40%'} item style={{ display: 'flex' }}>
          <Grid
            width={'100%'}
            style={{ display: 'flex', flexDirection: 'row' }}
            alignItems={'center'}
            gap={1}
          >
            <Typography fontWeight="bold" color={'primary'} variant="body1">
              {t('teacherVirtualClassroom.tasks.table.input.title.insumo')}
            </Typography>

            <Autocomplete
              style={{ backgroundColor: 'lightgray', borderRadius: '25px' }}
              className={estilos.combolist}
              disablePortal
              loading={Supplies.isLoading}
              getOptionLabel={(option) => option.name}
              options={Supplies.data ? Supplies.data : []}
              fullWidth
              sx={{ mr: '15px' }}
              onChange={(_, value) => {
                setSupply(value?.id || null);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('teacherVirtualClassroom.tasks.table.input.title.insumo.select')}
                />
              )}
            />
          </Grid>

          <Grid
            width={'100%'}
            style={{ display: 'flex', flexDirection: 'row' }}
            alignItems={'center'}
            gap={1}
          >
            <Typography fontWeight="bold" color={'primary'} variant="body1">
              {t('teacherVirtualClassroom.tasks.table.input.title.status')}
            </Typography>

            <Autocomplete
              style={{ backgroundColor: 'lightgray', borderRadius: '25px' }}
              className={estilos.combolist}
              disablePortal
              getOptionLabel={(option) => t(option.label)}
              options={virtualClassActivitiesStatus}
              fullWidth
              sx={{ mr: '15px' }}
              onChange={(_, value) => {
                setStatus(value?.name || null);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('teacherVirtualClassroom.tasks.table.input.title.status.select')}
                />
              )}
            />
          </Grid>
          {/*

          <ComboListComponent label={t('teacherVirtualClassroom.tasks.modalidad')} />
          <ComboListComponent label={t('teacherVirtualClassroom.tasks.horario')} />*/}
        </Grid>
        <Grid item>
          <Button
            startIcon={<IconPlus />}
            onClick={handleTaskCreate}
            color={'primary'}
            variant="contained"
            disabled={isFinishedCourse}
            sx={{ borderRadius: '30px' }}
          >
            {t('teacherVirtualClassroom.tasks.button.agregar')}
          </Button>
        </Grid>
        <ButtonGroup>
          <IconButton onClick={() => setTable(true)}>
            <IconListDetails />
          </IconButton>
          <IconButton onClick={() => setTable(false)}>
            <IconBrandWindows />
          </IconButton>
        </ButtonGroup>
      </Grid>

      <Grid margin="15px 0">
        {InfoDeleteTask.isError && showAlerts && (
          <ErrorAlert
            message={InfoDeleteTask.error as string}
            handleDismiss={() => {
              setShowAlerts(false);
            }}
          />
        )}
        {InfoDeleteTask.isSuccess && showAlerts && (
          <SuccessAlert
            message={'Eliminada Correctamente'}
            handleDismiss={() => {
              setShowAlerts(false);
            }}
          />
        )}

        {InfoVisible.isError && showAlerts && (
          <ErrorAlert
            message={` ${InfoVisible.error as string}`}
            handleDismiss={() => {
              setShowAlerts(false);
            }}
          />
        )}
        {InfoVisible.isSuccess && showAlerts && (
          <SuccessAlert
            message={'Visibilidad actualizada'}
            handleDismiss={() => {
              setShowAlerts(false);
            }}
          />
        )}
      </Grid>
      {updateDate.modal ? UpdateDate() : ''}

      {Table ? <TableData /> : <CardData />}
    </>
  );
};

export default TaskList;
