import { useState, ChangeEvent } from 'react';
import {
  Dialog,
  DialogContent,
  Typography,
  Button,
  DialogActions,
  Grid,
  CardMedia,
  CircularProgress,
} from '@material-ui/core';
import AnimateButton from '../../../shared/components/extended/AnimateButton';
import { useAppSelector } from '../../../../hooks/useRedux';
import noPhoto from '../../../../assets/images/pages/no_photo.jpg';
import { stylesDialogEventNews } from '../constants/styles';
import { useAddImageEventNewsMutation } from '../slices/eventNewApiSlice';
import useGetBaseUrl from '../../../../hooks/useGetBaseUrl';

interface Props {
  open: boolean;
  handleCloseDialog: () => void;
}

const EventNewsImage = function ({ open, handleCloseDialog }: Props) {
  const styles = stylesDialogEventNews();

  const [file, setFile] = useState('');

  const [target, setTarget] = useState<File>(null!);

  const { eventNewIdToAddImage, eventNewList } = useAppSelector((state) => state.eventNew);

  const [addImageEventNews, { isLoading, isError, isSuccess }] = useAddImageEventNewsMutation();
  const { baseUrl } = useGetBaseUrl();

  const eventNew = eventNewList.find((value) => value.id === eventNewIdToAddImage);

  const onChangePicture = function (event: ChangeEvent<HTMLInputElement>) {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e: any) => {
        setFile(e.target.result);
      };
      reader.readAsDataURL(event.target.files[0]);
      setTarget(event.target.files[0]);
    }
  };

  const handleAddImageEventNew = function () {
    addImageEventNews({
      imagePayload: {
        eventId: eventNewIdToAddImage!,
        image: target,
        periodId: 1,
      },
    });
  };

  function obtainDomainImage(baseImage: string) {
    return `${baseUrl}/show?name=${baseImage}`;
  }

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={() => handleCloseDialog()}
      className={styles.userAddDialog}
      classes={{
        paper: styles.paper,
      }}
    >
      {/* <PerfectScrollbar> */}
      <DialogContent>
        <Typography variant="h2">Agregar imagen referente</Typography>

        <Grid container spacing={2} style={{ marginTop: 120 }}>
          {eventNew?.url_image !== null && (
            <Grid item>
              <CardMedia
                style={{ cursor: 'pointer' }}
                component="img"
                src={file ? file : obtainDomainImage(eventNew?.url_image!)}
                title="image"
              />
            </Grid>
          )}
          {eventNew?.url_image === null && (
            <Grid item>
              <CardMedia
                style={{ cursor: 'pointer' }}
                component="img"
                src={file ? file : noPhoto}
                title="image"
              />
            </Grid>
          )}
          <Grid item xs minWidth={200}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography align="left" component="div" variant="subtitle1">
                  {eventNew?.title}
                </Typography>
                <Typography align="left" component="div" variant="caption">
                  {eventNew?.start_date} | {eventNew?.end_date}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography align="left" component="div" variant="body2">
                  {eventNew?.description}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container justifyContent="center">
          <Grid style={{ marginTop: 20 }}>
            <AnimateButton>
              <Button
                disabled={false}
                style={{ marginBottom: 50 }}
                variant="contained"
                color="primary"
                component={'label'}
                size="small"
              >
                {!file ? ' Seleccionar imagen' : 'Cambiar foto'}
                <input type="file" id="btnFile" hidden onChange={onChangePicture} />
              </Button>
            </AnimateButton>
          </Grid>
        </Grid>
      </DialogContent>
      {/* </PerfectScrollbar> */}
      <DialogActions>
        <Grid item xs={12} textAlign="center">
          <Grid item container xs={12} justifyContent="center" gap={1}>
            {!isSuccess && !isError && (
              <Button
                variant="text"
                size="large"
                color="primary"
                onClick={() => handleCloseDialog()}
                disabled={isLoading}
              >
                No, Cancelar
              </Button>
            )}

            {(isSuccess || isError) && (
              <Button
                variant="contained"
                size="large"
                color="primary"
                onClick={() => handleCloseDialog()}
              >
                Continuar
              </Button>
            )}
            {!isSuccess && !isError && (
              <Button
                variant="contained"
                size="large"
                onClick={() => handleAddImageEventNew()}
                color="primary"
                autoFocus
                disabled={isLoading || !file}
                endIcon={isLoading && <CircularProgress size={20} />}
              >
                Sí, agregar
              </Button>
            )}
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default EventNewsImage;
