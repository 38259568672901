export const permissionKey = 'collaborator';
export const permissionKeyAdmin = 'department';

export interface SubPermissionKeys {
  read: string;
}

export const subPermissionKeys: SubPermissionKeys = {
  read: 'collaborators-birth-month',
};
