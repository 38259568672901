import { Box, Button, Grid, Menu, MenuItem, Typography, useTheme } from '@material-ui/core';
import React, { useState } from 'react';
import CustomPagination from './CustomPagination';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import { useTranslation } from 'react-i18next';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { SerializedError } from '@reduxjs/toolkit';

interface Props {
  error: FetchBaseQueryError | SerializedError | undefined;
  isLoading: boolean;
  total: number;
  page: number;
  perPage: number;
  setPage: (page: number) => void;
  setPerPage: (perPage: number) => void;
  perPageBox?: Boolean;
  paginationBox?: Boolean;
}

const CustomPaginationAndItems = ({
  error,
  isLoading,
  page,
  perPage,
  setPage,
  setPerPage,
  total,
  perPageBox = true,
  paginationBox = true,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const theme = useTheme();
  const open = Boolean(anchorEl);
  const { t } = useTranslation();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (newPerPage: number) => {
    setAnchorEl(null);
    setPerPage(newPerPage);
  };

  return (
    <>
      {!isLoading && !error && (paginationBox || perPageBox) && (
        <Grid container justifyContent="space-between" alignItems="flex-end" sx={{ mt: 4 }}>
          {paginationBox && (
            <Grid item>
              <CustomPagination
                shape="rounded"
                color="primary"
                count={Math.ceil(total / perPage) || 1}
                page={page}
                onChange={(_, newPage) => {
                  if (newPage) {
                    setPage(newPage);
                  }
                }}
              />
            </Grid>
          )}
          {perPageBox && (
            <Grid>
              <Grid container alignItems="center">
                <Typography
                  sx={{
                    mr: 2,
                  }}
                >
                  {page * perPage - perPage + (total > 0 ? 1 : 0)} -{' '}
                  {page * perPage > total ? total : page * perPage} {t('shared.table.of')} {total}
                </Typography>

                <Box
                  component={Button}
                  size="large"
                  sx={{
                    color: theme.palette.grey[900],
                    backgroundColor: theme.palette.primary.light,
                    ':hover': { bgcolor: theme.palette.primary.light },
                    px: 2,
                    borderRadius: 4,
                  }}
                  endIcon={<ExpandMoreRoundedIcon />}
                  onClick={handleClick}
                >
                  {perPage} {t('shared.search_paginated_table.per_page')}
                </Box>

                <Menu
                  id="menu-user-list-style1"
                  anchorEl={anchorEl}
                  keepMounted
                  open={open}
                  onClose={() => handleClose(perPage)}
                  variant="selectedMenu"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <MenuItem onClick={() => handleClose(5)}>
                    {' '}
                    5 {t('shared.search_paginated_table.per_page')}
                  </MenuItem>
                  <MenuItem onClick={() => handleClose(10)}>
                    {' '}
                    10 {t('shared.search_paginated_table.per_page')}
                  </MenuItem>
                  <MenuItem onClick={() => handleClose(15)}>
                    {' '}
                    15 {t('shared.search_paginated_table.per_page')}
                  </MenuItem>
                </Menu>
              </Grid>
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
};

export default CustomPaginationAndItems;
