import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISearchTable } from '../../../shared/interfaces/paginatedTable.interface';
import { Permission } from '../interfaces/permissions.interfaces';

interface PermissionsState {
  permissionsList: Permission[];
  deleteModalOpen: boolean;
  permissionIdToDelete?: number;
  permissionsTable: ISearchTable;

  permissionIdSelected?: number;
  modulesDialogOpen: boolean;
}

const initialState: PermissionsState = {
  permissionsList: [],
  deleteModalOpen: false,
  permissionsTable: {
    page: 1,
    perPage: 5,
    search: '',
  },
  modulesDialogOpen: false,
};

const permissionsSlice = createSlice({
  name: 'Permissions',
  initialState,
  reducers: {
    setPermissionList: (state, action: PayloadAction<Permission[]>) => {
      state.permissionsList = action.payload;
    },
    setDeleteModalOpen: (state, action: PayloadAction<boolean>) => {
      state.deleteModalOpen = action.payload;
    },
    openDeleteModalWith: (state, action: PayloadAction<number>) => {
      state.permissionIdToDelete = action.payload;
      state.deleteModalOpen = true;
    },
    resetPermissionIdToDelete: (state) => {
      state.permissionIdToDelete = undefined;
    },
    updatePermissionsTable: (state, action: PayloadAction<ISearchTable>) => {
      state.permissionsTable = action.payload;
    },
    setPermissionIdSelected: (state, action: PayloadAction<number | undefined>) => {
      state.permissionIdSelected = action.payload;
    },
    setModulesDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.modulesDialogOpen = action.payload;
    },
  },
});

export const {
  setPermissionList,
  setDeleteModalOpen,
  openDeleteModalWith,
  resetPermissionIdToDelete,
  updatePermissionsTable,
  setPermissionIdSelected,
  setModulesDialogOpen,
} = permissionsSlice.actions;

export default permissionsSlice.reducer;
