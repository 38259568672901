import { Grid, Typography } from '@material-ui/core';
import { useAppSelector } from '../../../../hooks/useRedux';
import MainCard from '../../../shared/components/cards/MainCard';
import DeleteStudentDialog from '../components/DeleteStudentDialog';
import StudentsTable from '../components/StudentsTable';
import StudentAddAvatar from '../components/StudenAddAvatar';

import { useTranslation } from 'react-i18next';

const StudentsScreen = () => {
  const { t } = useTranslation();

  const { studentIdToDelete, studentIdToAddAvatar } = useAppSelector((state) => state.students);

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">{t('academic.students_list.title')}</Typography>
      </Grid>
      <StudentsTable />
      {studentIdToDelete && <DeleteStudentDialog />}
      {studentIdToAddAvatar && <StudentAddAvatar />}
    </Grid>
  );
};

export default StudentsScreen;
