import DeleteAlert from '../../../shared/components/dialogs/DeleteAlert';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import {
  resetFinancialPeriodIdToDelete,
  setDeleteModalOpen,
} from '../slices/financialPeriodsSlice';
import { useDeleteFinancialPeriodMutation } from '../slices/financialPeriodsApiSlice';

const DeleteFinancialPeriodDialog = () => {
  const dispatch = useAppDispatch();

  const { deleteModalOpen, financialPeriodIdToDelete } = useAppSelector(
    (state) => state.financialPeriods
  );
  const { currentProfile } = useAppSelector((state) => state.access);

  const [deletePeriod, { isLoading, isSuccess, isError, error }] =
    useDeleteFinancialPeriodMutation();

  const handleDelete = () => {
    deletePeriod({
      profileId: currentProfile?.profileId!,
      fperiodId: financialPeriodIdToDelete!,
    });
  };

  const handleClose = () => {
    dispatch(setDeleteModalOpen(false));
    dispatch(resetFinancialPeriodIdToDelete());
  };

  return (
    <DeleteAlert
      open={deleteModalOpen}
      onClose={handleClose}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      handleDelete={handleDelete}
      error={error as string}
    />
  );
};

export default DeleteFinancialPeriodDialog;
