import useFormatSizeFile from '../modules/shared/hooks/useFormatSizeFile';
import { useAppSelector } from './useRedux';

const dictionary = {
  '.p12': 'application/x-pkcs12',
};

const useFileFormatValidation = () => {
  type imgDimenssionsType =
    | 'square'
    | {
        height: number;
        width: number;
      };

  const { defaultConfiguration } = useAppSelector((state) => state.access);

  const { formatBytes } = useFormatSizeFile();

  /**
   *
   * @param file
   * @param acceptedFileTypes
   * @returns string
   *  - '' if the file is valid
   * - 'error message' if the file is invalid
   * - 'file is not defined' if the file is undefined
   *
   */
  const validateFormatFile = (file: File, acceptedFileTypes: string = '*') => {
    if (!file) {
      return 'Archivo inválido';
    }

    const { size: fileSize = 0, type: fileType = '' } = file;

    const size = formatBytes(fileSize);
    const [sizeNumber, sizeUnit] = size.split(' ');

    if (
      fileSize &&
      (sizeUnit === 'MB' || sizeUnit === 'GB') &&
      Number(sizeNumber) > Number(defaultConfiguration.file_size)
    ) {
      return `El tamaño del archivo no puede ser mayor a ${defaultConfiguration.file_size} MB`;
    }

    if (
      acceptedFileTypes === '*' ||
      acceptedFileTypes.split(',').some((e) => e.replace('.', '') === fileType.split('/')?.[1]) ||
      acceptedFileTypes === fileType ||
      dictionary[acceptedFileTypes as keyof typeof dictionary]
    ) {
      return '';
    } else if (
      acceptedFileTypes.split('/')?.[1] === '*' &&
      acceptedFileTypes.split('/')?.[0] === fileType.split('/')?.[0]
    ) {
      return '';
    } else {
      return `El tipo de archivo no es válido`;
    }
  };

  /**
   * @param file
   * @param imgDimenssions - 'square' | { height: number; width: number }
   * @returns string
   * - '' if the file is valid
   * - 'error message' if the file is invalid
   * - 'file is not defined' if the file is undefined
   *
   */

  const validateImageDimenssions = async (file: File, imgDimenssions: imgDimenssionsType) => {
    if (!file) {
      return 'Archivo inválido';
    }

    const img = new Image();
    img.src = URL.createObjectURL(file);

    const { width, height } = await new Promise<{
      width: number;
      height: number;
    }>((resolve) => {
      img.onload = () => {
        const width = img.naturalWidth;
        const height = img.naturalHeight;
        URL.revokeObjectURL(img.src);
        resolve({ width, height });
      };
    });

    if (imgDimenssions === 'square' && width !== height) {
      return `La imagen debe ser cuadrada`;
    }

    if (
      imgDimenssions !== 'square' &&
      (width !== imgDimenssions.width || height !== imgDimenssions.height)
    ) {
      return `La imagen debe ser de ${imgDimenssions.width} x ${imgDimenssions.height} px`;
    }

    return '';
  };

  return {
    validateFormatFile,
    validateImageDimenssions,
  };
};

export default useFileFormatValidation;
