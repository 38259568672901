import {
    Grid,
    Skeleton,
} from '@material-ui/core';

const skeletonList = () => {

    return (
        <Grid container>

            <Grid container>
                <Grid item xs={12} md={6} sx={{ p: 1 }}>
                    <Skeleton variant='rectangular' height={40}></Skeleton>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} md={6} sx={{ p: 1 }}>
                    <Skeleton variant='rectangular' height={40}></Skeleton>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} md={6} sx={{ p: 1 }}>
                    <Skeleton variant='rectangular' height={40}></Skeleton>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} md={6} sx={{ p: 1 }}>
                    <Skeleton variant='rectangular' height={40}></Skeleton>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} md={6} sx={{ p: 1 }}>
                    <Skeleton variant='rectangular' height={40}></Skeleton>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default skeletonList;