import { useEffect, useState } from 'react';
import { IconButton, Grid, useTheme, Typography } from '@material-ui/core';
import { IconArrowNarrowLeft } from '@tabler/icons';

import EditHourhandForm from '../components/EditHourhandForm';
import MainCardForm from '../../../shared/components/cards/MainCardForm';
import { useHistory, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { useGetHourhandQuery } from '../slices/hourhandsApiSlice';
import { setHourhandList } from '../slices/hourhandsSlice';
import SimpleFormOneColumn from '../../../shared/components/forms/skeletons/SimpleFormOneColumn';

const EditHourhandScreen = () => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { hourhandId } = useParams<{ hourhandId: string }>();

  const { hourhandList } = useAppSelector((state) => state.hourhands);

  const hourhandToEdit = hourhandList.find((h) => h.id === Number(hourhandId));

  const [, setIsReadyForRemount] = useState(false);

  const { data: response, isLoading } = useGetHourhandQuery({
    hourhandId: +hourhandId,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps

  const handleRemount = () => {
    setIsReadyForRemount(false);
  };

  useEffect(() => {
    if (response) {
      dispatch(setHourhandList([response!]));
      setIsReadyForRemount(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response, dispatch]);

  return (
    <MainCardForm>
      <Grid item xs={12}>
        <Grid item container alignItems="center" gap={1} sx={{ cursor: 'pointer' }}>
          <IconButton onClick={() => history.goBack()}>
            <IconArrowNarrowLeft size={30} color={theme.palette.text.dark} />
          </IconButton>
          <Typography variant="h2">Editar</Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} md={10} lg={8} sx={{ mt: 2 }}>
        {Boolean(hourhandList.length) && !isLoading && hourhandToEdit ? (
          <EditHourhandForm handleRemount={handleRemount} />
        ) : (
          <SimpleFormOneColumn />
        )}
      </Grid>
    </MainCardForm>
  );
};

export default EditHourhandScreen;
