import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import {
  Autocomplete,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Divider,
  useTheme,
  makeStyles,
  Alert,
} from '@material-ui/core';

import { useFormik } from 'formik';

import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import InputLabel from '../../../shared/components/forms/InputLabel';
import { useFormStyles } from '../constants/styles';

import { useAppSelector } from '../../../../hooks/useRedux';
import { useUpdateHourhandMutation } from '../slices/hourhandsApiSlice';
import { DaysWeeks } from '../constants/mockOptions';

import { HourHandSchema } from '../constants/validationSchemas';
import { statusAvailables } from '../../../shared/constants/resourceStatus';

import { LocalizationProvider, StaticTimePicker } from '@material-ui/lab';
import esLocale from 'date-fns/locale/es';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import { Hourhand } from '../interfaces/hourhands.interfaces';
import Transitions from '../../../shared/components/extended/Transitions';
import { useGetCatalogByAcronymQuery } from '../../../shared/slices/catalogApi';
import TextFieldFetching from '../../../shared/components/TextFieldFetching';
import { format, isAfter, set } from 'date-fns';
import isBefore from 'date-fns/isBefore';

const useStyles = makeStyles((theme) => ({
  timePicker: {
    '& .MuiInternalClockNumber-inner': { color: 'inherit' },
    '& .Mui-disabled': { color: theme.palette.error.main },
  },
}));

const EditHourhandForm = ({ handleRemount }: { handleRemount?: () => void }) => {
  const history = useHistory();

  const classesStyle = useStyles();

  const theme = useTheme();

  const { hourhandId } = useParams<{ hourhandId: string }>();

  const { currentProfile } = useAppSelector((state) => state.access);
  const { hourhandList } = useAppSelector((state) => state.hourhands);
  const hourhandToEdit = hourhandList.find((h) => h.id === Number(hourhandId));

  const [showAlerts, setShowAlerts] = useState(true);
  const [updateHourhand, { isLoading, isError, error, isSuccess }] = useUpdateHourhandMutation();
  const classes = useFormStyles();

  const { data: catalogModality, isLoading: isLoadingModality } = useGetCatalogByAcronymQuery({
    acronym: 'M',
  });

  const { data: daytripsAvailables = [], isLoading: isLoadingDA } = useGetCatalogByAcronymQuery({
    profileId: currentProfile?.profileId!,
    acronym: 'J',
  });

  const checkAvaibleTime = function (start: Date, end: Date) {
    if (!start || !end) return false;

    const startTime = start.getTime();
    const endTime = end.getTime();

    if (startTime >= endTime) {
      return false;
    }

    return true;
  };

  const { getFieldProps, errors, touched, handleSubmit, setFieldValue, handleChange, values } =
    useFormik({
      initialValues: {
        is_posgrade: hourhandToEdit?.is_posgrade || false,
        workday_id: hourhandToEdit?.workday_id || 0,
        type_modality_id: hourhandToEdit?.type_modality_id,
        hourDescription: hourhandToEdit?.hour_description,
        ...DaysWeeks.reduce((object, day) => {
          const startTime =
            hourhandToEdit?.[
              `hour_start_time_${day.day.toLowerCase()}` as keyof typeof hourhandToEdit
            ];

          const endTime =
            hourhandToEdit?.[
              `hour_end_time_${day.day.toLowerCase()}` as keyof typeof hourhandToEdit
            ];

          return {
            ...object,
            [`hour${day.day}`]:
              hourhandToEdit?.[`hour_${day.day.toLowerCase()}` as keyof typeof hourhandToEdit] ===
              '1',
            [`hour_start_time_${day.day.toLowerCase()}`]: new Date(
              0,
              0,
              0,
              Number(startTime?.toString().split(':')[0]) || 0,
              Number(startTime?.toString().split(':')[1]) || 0
            ),
            [`hour_end_time_${day.day.toLowerCase()}`]: new Date(
              0,
              0,
              0,
              Number(endTime?.toString().split(':')[0]) || 0,
              Number(endTime?.toString().split(':')[1]) || 0
            ),
          };
        }, {}),
        statusId: hourhandToEdit?.status_id,
      },
      validationSchema: HourHandSchema,
      validate: () => {
        const timeNotAvaible = 'Hora final no debe superar o igualar a la hora de inicio.';
        const errors: any = {};

        DaysWeeks.forEach((day) => {
          if (values[`hour${day.day}` as keyof typeof values]) {
            const isValid = checkAvaibleTime(
              values[
                `hour_start_time_${day.day.toLowerCase()}` as keyof typeof values
              ] as unknown as Date,
              values[
                `hour_end_time_${day.day.toLowerCase()}` as keyof typeof values
              ] as unknown as Date
            );
            if (!isValid) {
              errors[`hour${day.day}`] = timeNotAvaible;
            }
          }
        });
        return errors;
      },

      onSubmit: async (values) => {
        await updateHourhand({
          profileId: currentProfile?.profileId!,
          hourhandId: hourhandToEdit?.id!,
          hourhandPayload: {
            ...DaysWeeks.reduce((object, day) => {
              const hoursDay = values[`hour${day.day}` as keyof typeof values];
              const startTime =
                values[`hour_start_time_${day.day.toLowerCase()}` as keyof typeof values];
              const endTime =
                values[`hour_end_time_${day.day.toLowerCase()}` as keyof typeof values];
              return {
                ...object,
                ...{
                  [`hour_${day.day.toLowerCase()}` as keyof Hourhand]: hoursDay ? '1' : '0',
                },
                ...(hoursDay && {
                  [`hour_start_time_${day.day.toLowerCase()}`]: (
                    startTime as unknown as Date
                  ).toLocaleTimeString(),
                }),
                ...(hoursDay && {
                  [`hour_end_time_${day.day.toLowerCase()}`]: (
                    endTime as unknown as Date
                  ).toLocaleTimeString(),
                }),
              };
            }, {}),
            hour_description: values.hourDescription,
            type_modality_id: values.type_modality_id,
            status_id: values.statusId,
            workday_id: values.workday_id,
            is_posgrade: values.is_posgrade,
          },
        });

        handleRemount && handleRemount();

        setShowAlerts(true);
      },
    });

  const dependanceArray = [
    values['hour_start_time_monday' as keyof typeof values],
    values['hour_start_time_tuesday' as keyof typeof values],
    values['hour_start_time_wednesday' as keyof typeof values],
    values['hour_start_time_thursday' as keyof typeof values],
    values['hour_start_time_friday' as keyof typeof values],
    values['hour_start_time_saturday' as keyof typeof values],
    values['hour_start_time_sunday' as keyof typeof values],
    DaysWeeks,
  ];

  useEffect(() => {
    const workdays = DaysWeeks.reduce((object, day) => {
      const startDate = values[`hour_start_time_${day.day.toLowerCase()}` as keyof typeof values];
      const thisDay = new Date();

      if (!values[`hour${day.day}` as keyof typeof values]) {
        return object;
      }

      if (
        isAfter(
          startDate as unknown as Date,
          set(thisDay, {
            hours: 6,
          })
        ) &&
        isBefore(
          startDate as unknown as Date,
          set(thisDay, {
            hours: 12,
          })
        )
      ) {
        return {
          ...object,
          'seccion-matutina': 'Mañana',
        };
      }

      if (
        isAfter(
          startDate as unknown as Date,
          set(new Date(), {
            hours: 12,
          })
        ) &&
        isBefore(
          startDate as unknown as Date,
          set(new Date(), {
            hours: 18,
          })
        )
      ) {
        return {
          ...object,
          'seccion-vespertina': 'Tarde',
        };
      }

      if (
        isAfter(
          startDate as unknown as Date,
          set(new Date(), {
            hours: 18,
          })
        )
      ) {
        return {
          ...object,
          'seccion-nocturna': 'Noche',
        };
      }

      return object;
    }, {});

    const workdayKeywords = Object.keys(workdays);

    if (workdayKeywords.length === 1) {
      setFieldValue(
        'workday_id',
        daytripsAvailables.find((daytrip) => daytrip.cat_keyword === workdayKeywords[0])?.id
      );
    } else if (workdayKeywords.length > 1) {
      setFieldValue(
        'workday_id',
        daytripsAvailables.find((daytrip) => daytrip.cat_keyword === 'secccion-mixta')?.id
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependanceArray);

  const setSuggestedDescription = (isVerify?: boolean) => {
    const selectedDays: {
      name: string;
      id: string;
      day: string;
    }[] = [];
    let suggest = '';

    for (const day of DaysWeeks) {
      if (values[`hour${day.day}` as keyof typeof values]) {
        if (isVerify) {
          return true;
        } else {
          selectedDays.push(day);
        }
      }
    }

    if (selectedDays.length) {
      suggest = selectedDays.reduce<string>((acc, day, i) => {
        const start = format(
          values[`hour_start_time_${day.day.toLowerCase()}` as keyof typeof values] as any,
          'HH:mm'
        );

        const end = format(
          values[`hour_end_time_${day.day.toLowerCase()}` as keyof typeof values] as any,
          'HH:mm'
        );

        if (i === 0) {
          return `${day.name} (${start} - ${end})`;
        }

        return `${acc}, ${day.name} (${start} - ${end})`;
      }, '');
    }

    return suggest;
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction="column" gap={2}>
        <Grid item>
          {isError && showAlerts && (
            <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
          )}

          {isSuccess && showAlerts && (
            <SuccessAlert
              message="El horario ha sido actualizado correctamente."
              handleDismiss={() => setShowAlerts(false)}
            />
          )}
        </Grid>
        <Grid item container spacing={2} alignItems="center">
          <Grid item xs={12}>
            {setSuggestedDescription(true) && (
              <Alert severity="info">
                <Grid item container justifyContent="space-between" alignItems="center">
                  <Grid item>{`Descripción sugerida: ${setSuggestedDescription()}`}</Grid>
                  <Grid item>
                    <Button
                      onClick={() => setFieldValue('hourDescription', setSuggestedDescription())}
                    >
                      Aceptar
                    </Button>
                  </Grid>
                </Grid>
              </Alert>
            )}

            <InputLabel className={classes.inputLabel}>Descripción</InputLabel>
            <TextField
              fullWidth
              error={Boolean(errors.hourDescription && touched.hourDescription)}
              placeholder="Ingrese descripción del horario"
              {...getFieldProps('hourDescription')}
            />
            {errors && touched.hourDescription && (
              <FormHelperText error>{errors.hourDescription}</FormHelperText>
            )}
          </Grid>

          <Grid
            item
            xs={12}
            mt={2}
            ml={2}
            sx={{
              border: `1px solid ${theme.palette.primary.main}`,
              borderRadius: '10px',
              backgroundColor: theme.palette.primary.light,
            }}
          >
            <InputLabel className={classes.inputLabel}>Días</InputLabel>
            {DaysWeeks.map((day) => (
              <FormControlLabel
                key={day.day}
                control={
                  <Checkbox
                    onChange={handleChange}
                    checked={Boolean(values[`hour${day.day}` as keyof typeof values])}
                    id={`hour${day.day}`}
                  />
                }
                label={day.name}
                labelPlacement="end"
              />
            ))}
          </Grid>

          {DaysWeeks.map((day) => {
            const startTime =
              values[`hour_start_time_${day.day.toLowerCase()}` as keyof typeof values];

            const endTime = values[`hour_end_time_${day.day.toLowerCase()}` as keyof typeof values];

            return (
              values[`hour${day.day}` as keyof typeof values] && (
                <Transitions
                  type="collapse"
                  in={Boolean(values[`hour${day.day}` as keyof typeof values])}
                  position="top-left"
                  direction={'left'}
                >
                  <Grid
                    container
                    mt={2}
                    ml={2}
                    sx={{
                      border: `1px solid ${theme.palette.primary.main}`,
                      borderRadius: '10px',
                      overflow: 'hidden',
                    }}
                  >
                    <Grid item xs={12} py={1} sx={{ backgroundColor: theme.palette.primary.light }}>
                      <Divider>{day.name}</Divider>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <StaticTimePicker
                            ampm={false}
                            className={classesStyle.timePicker}
                            label="Hora de inicio"
                            orientation="landscape"
                            openTo="hours"
                            value={startTime}
                            onChange={(newValue) => {
                              setFieldValue(`hour_start_time_${day.day.toLowerCase()}`, newValue);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <Grid item xs={12}>
                        {errors[`hour${day.day}` as keyof typeof errors] &&
                          touched[`hour${day.day}` as keyof typeof touched] && (
                            <FormHelperText error>
                              {errors[`hour${day.day}` as keyof typeof errors]}
                            </FormHelperText>
                          )}
                      </Grid>
                      <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <StaticTimePicker
                            ampm={false}
                            label="Hora fin"
                            orientation="landscape"
                            openTo="hours"
                            value={endTime}
                            className={classesStyle.timePicker}
                            minTime={
                              startTime &&
                              new Date(0, 0, 0, (startTime as unknown as Date).getHours())
                            }
                            maxTime={
                              startTime &&
                              // A la hora de inicio se le suma 6 horas como hora maxima y si pasa de las 18h se le asigna como hora maxima las 23h
                              new Date(
                                0,
                                0,
                                0,
                                (startTime as unknown as Date).getHours() < 18
                                  ? (startTime as unknown as Date).getHours() + 6
                                  : 23,
                                // Como la hora maxima no puede ser las 24h se le agrega 59 min a las 23h
                                (startTime as unknown as Date).getHours() >= 19 ? 59 : 0
                              )
                            }
                            onChange={(newValue) => {
                              setFieldValue(`hour_end_time_${day.day.toLowerCase()}`, newValue);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      </LocalizationProvider>
                    </Grid>
                  </Grid>
                </Transitions>
              )
            );
          })}

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            {/* <InputLabel className={classes.inputLabel}>Horario para posgrado</InputLabel> */}
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) => {
                    setFieldValue('is_posgrade', e.target.checked);
                  }}
                  checked={!!Number(values.is_posgrade)}
                />
              }
              label={'Horario para posgrado'}
              labelPlacement="end"
            />
          </Grid>

          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>Jornada</InputLabel>
            {isLoadingModality ? (
              <TextFieldFetching />
            ) : (
              <Autocomplete
                loadingText="Cargando Datos"
                noOptionsText="No hay opciones"
                options={daytripsAvailables || []}
                loading={isLoadingDA}
                getOptionLabel={(option) => option?.cat_name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(errors.workday_id && touched.workday_id)}
                    placeholder="Ninguna jornada asignada"
                  />
                )}
                onChange={(e, value) =>
                  setFieldValue(
                    'workday_id',

                    value?.id || ''
                  )
                }
                value={
                  daytripsAvailables?.find((dt) => Number(dt.id) === Number(values.workday_id)) ||
                  null
                }
                fullWidth
                disabled // TODO: Descomentar cuando se tenga la funcionalidad de check para habilitar el campo
              />
            )}
            {errors.workday_id && touched.workday_id && (
              <FormHelperText error>{errors.workday_id}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12}>
            <InputLabel>Modalidad</InputLabel>
            {isLoadingModality ? (
              <TextFieldFetching />
            ) : (
              <>
                <Autocomplete
                  loadingText="Cargando Datos"
                  noOptionsText="No hay opciones"
                  options={catalogModality || []}
                  loading={isLoadingModality}
                  getOptionLabel={(option) => option?.cat_name}
                  value={catalogModality?.find((p) => p.id === Number(values.type_modality_id))}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(errors.type_modality_id && touched.type_modality_id)}
                      placeholder="Ninguna modalidad asignado"
                    />
                  )}
                  onChange={(e, value) => setFieldValue('type_modality_id', value?.id || '')}
                  fullWidth
                />
                {errors.type_modality_id && touched.type_modality_id && (
                  <FormHelperText error>{errors.type_modality_id}</FormHelperText>
                )}
              </>
            )}
          </Grid>

          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>Estado</InputLabel>
            <Autocomplete
              options={statusAvailables}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField {...params} error={Boolean(errors.statusId && touched.statusId)} />
              )}
              value={
                statusAvailables.find((status) => status.id === Number(values.statusId)) ||
                statusAvailables[0]
              }
              onChange={(e, value) => setFieldValue('statusId', value?.id || '')}
              fullWidth
              clearIcon={null}
            />
            {errors.statusId && touched.statusId && (
              <FormHelperText error>{errors.statusId}</FormHelperText>
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid item container alignItems="center" justifyContent="flex-end" spacing={2} sx={{ mt: 1 }}>
        <Grid item>
          <Button variant="outlined" size="large" color="primary" onClick={() => history.goBack()}>
            Regresar
          </Button>
        </Grid>
        <Grid item>
          <Button
            type="submit"
            variant="contained"
            size="large"
            color="primary"
            disabled={isLoading}
            endIcon={isLoading && <CircularProgress size={20} />}
          >
            Editar
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default EditHourhandForm;
