import { createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import {
  SearchPersonParam,
  StudentRecord,
} from '../../../shared/interfaces/searchPerson.interface';
import { PaymentsDebts } from '../../../students/payments-history/interfaces/paymentsHistory.interface';
import { PayMethodsStudent } from '../interfaces/debtsPayment.interface';
import { AmountCollectionPayload, TemporalBillingData } from '../interfaces/payloads.interface';

interface DebtsList {
  data?: PaymentsDebts[] | undefined;
  isFetching: boolean;
  error: FetchBaseQueryError | SerializedError | undefined;
}

interface debtsPaymentState {
  currentClient?: SearchPersonParam;
  currentStudentRecord?: StudentRecord;
  debtsList?: DebtsList;

  /**
   * is positive balance transaction
   * Cuando en la seleccion del cliente se selecciona solo para abonar saldo
   * */
  isPositiveBalanceTransac: boolean;

  //debtor cuando no se seleccionan cuotas
  debtorStudentId?: number;

  //selected fees
  selectedDebtsId: number[];

  //confirm selected fees
  selectedDebts: PaymentsDebts[];

  //pay collection
  payCollection: AmountCollectionPayload[];

  //total to pay
  totalPayAmount: number;

  //total debt
  totalDebtAmount: number;
  //total debt discount
  totalDebtDiscount: number;

  //optional billing data
  defaultTempBillingData?: TemporalBillingData;
  tempBillingData?: TemporalBillingData;

  //html voucher
  voucher?: unknown;

  //early payment method
  earlyPayment?: PayMethodsStudent;
}

const initialState: debtsPaymentState = {
  isPositiveBalanceTransac: false,

  selectedDebts: [],
  selectedDebtsId: [],
  payCollection: [],

  totalPayAmount: 0,
  totalDebtAmount: 0,
  totalDebtDiscount: 0,
};

const debtsPaymentSlice = createSlice({
  name: 'debtsPayment',
  initialState,
  reducers: {
    //student info
    setCurrentClient: (state, action: PayloadAction<SearchPersonParam>) => {
      state.currentClient = action.payload;

      const studentRecords = action.payload.user.student?.student_records;
      if (studentRecords?.length) state.currentStudentRecord = studentRecords[0];
    },
    setCurrentStudentRecord: (state, action: PayloadAction<StudentRecord>) => {
      state.currentStudentRecord = action.payload;

      // debtsPaymentSlice.caseReducers.debtsList(state);
    },

    setIsPositiveBalanceTransac: (state, action: PayloadAction<boolean>) => {
      state.isPositiveBalanceTransac = action.payload;
      //limpiar demas estados (los de deudas seleccionadas)
      state.payCollection = [];
      state.selectedDebtsId = [];
      state.selectedDebts = [];
      state.totalPayAmount = 0;
      state.totalDebtAmount = 0;
      state.totalDebtDiscount = 0;
      state.tempBillingData = undefined;
      state.defaultTempBillingData = undefined;
      state.debtorStudentId = undefined;
    },

    setDebtorStudentId: (state, action: PayloadAction<number | undefined>) => {
      state.debtorStudentId = action.payload;
    },

    setDebtsList: (state, action: PayloadAction<DebtsList>) => {
      state.debtsList = action.payload;
    },

    resetDebtsList: (state) => {
      state.debtsList = undefined;
    },

    //objetos de deudas confirmadas
    setSelectedDebts: (state, action: PayloadAction<PaymentsDebts[]>) => {
      state.selectedDebts = action.payload;
      // state.selectedDebts = action.payload.sort((a, b) => {
      //   return Date.parse(a.fees_expiration_date) - Date.parse(b.fees_expiration_date);
      // });

      state.totalDebtAmount = action.payload
        .map((debt) =>
          debt.apply_early_payment
            ? Number(debt.net_value_early_payment)
            : Number(debt.fees_balance)
        )
        .reduce((partial_sum, a) => partial_sum + a, 0);

      state.totalDebtDiscount = action.payload
        .map((debt) =>
          debt.apply_early_payment &&
          Number(debt.net_value_early_payment) === Number(debt.fees_value_payment)
            ? Number(debt.discount_value_early_payment)
            : 0
        )
        .reduce((partial_sum, a) => partial_sum + a, 0);
    },

    //id de deudas seleccionadas
    setSelectedDebtsId: (state, action: PayloadAction<number[]>) => {
      state.selectedDebtsId = action.payload;
    },

    setPayCollection: (state, action: PayloadAction<AmountCollectionPayload[]>) => {
      state.payCollection = action.payload.sort((a, b) => a.payment_way_id! - b.payment_way_id!);

      state.totalPayAmount = action.payload
        .map((paid) => Number(paid.payment_amount))
        .reduce((partial_sum, a) => partial_sum + a, 0);
    },

    resetPayments: (state) => {
      state.payCollection = [];
      state.selectedDebtsId = [];
      state.selectedDebts = [];
      // state.currentClient = undefined;
      // state.debtsList = undefined;
      state.totalPayAmount = 0;
      state.totalDebtAmount = 0;
      state.totalDebtDiscount = 0;
      state.tempBillingData = undefined;
      state.defaultTempBillingData = undefined;
      state.isPositiveBalanceTransac = false;
      state.debtorStudentId = undefined;
    },
    resetCurrentClient: (state) => {
      state.currentClient = undefined;
    },

    setTemporalBillingData: (state, action: PayloadAction<TemporalBillingData>) => {
      state.tempBillingData = action.payload;
    },

    resetTemporalBillingData: (state) => {
      state.tempBillingData = undefined;
    },

    setDefaultTempBillingData: (state, action: PayloadAction<TemporalBillingData>) => {
      state.defaultTempBillingData = action.payload;
    },

    setVoucherData: (state, action: PayloadAction<unknown>) => {
      state.voucher = action.payload;
    },

    resetVoucher: (state) => {
      state.voucher = undefined;
    },

    setEarlyPayment: (state, action: PayloadAction<PayMethodsStudent>) => {
      state.earlyPayment = action.payload;
    },
  },
});

export const {
  setCurrentClient,
  setCurrentStudentRecord,

  setIsPositiveBalanceTransac,
  setDebtorStudentId,

  setDebtsList,
  resetDebtsList,
  setSelectedDebts,
  setSelectedDebtsId,
  setPayCollection,
  resetPayments,
  resetCurrentClient,
  setTemporalBillingData,
  resetTemporalBillingData,
  setDefaultTempBillingData,
  setVoucherData,
  resetVoucher,
  setEarlyPayment,
} = debtsPaymentSlice.actions;

export default debtsPaymentSlice.reducer;
