import * as Yup from 'yup';

export const ConfigProductPriceSchema = Yup.object().shape({
  product_id: Yup.number()
    .min(1, 'financial.product_config.add.product_required')
    .required('financial.product_config.add.product_required'),
  curriculum_id: Yup.number()
    .min(0, 'financial.product_config.add.curriculum_required')
    .required('financial.product_config.add.curriculum_required'),
  process_id: Yup.number()
    .min(0, 'financial.product_config.add.process_required')
    .required('financial.product_config.add.process_required'),
  offer_id: Yup.number()
    .min(0, 'financial.product_config.add.academic_offer_required')
    .required('financial.product_config.add.academic_offer_required'),
  education_level_id: Yup.number()
    .min(0, 'financial.product_config.add.education_lvl_required')
    .required('financial.product_config.add.education_lvl_required'),
  period_id: Yup.number()
    .min(0, 'financial.product_config.add.period_required')
    .required('financial.product_config.add.period_required'),
  financial_period_id: Yup.number()
    .min(0, 'financial.product_config.add.financial_period_required')
    .required('financial.product_config.add.financial_period_required'),
  economic_group_id: Yup.number()
    .min(0, 'financial.product_config.add.economic_group_required')
    .required('financial.product_config.add.economic_group_required'),
  agreement_id: Yup.number()
    .min(0, 'financial.product_config.add.agreement_required')
    .required('financial.product_config.add.agreement_required'),
  subject_id: Yup.number()
    .min(0, 'financial.product_config.add.subject_required')
    .required('financial.product_config.add.subject_required'),
  //type_application_keyword: Yup.string().required('El acrónimo de catálogo es requerido.'),
  pc_gross_value: Yup.number().required('financial.product_config.add.gross_required'),
  pc_net_value: Yup.string()
    .required('financial.product_config.add.net_price_required')
    .matches(/^\d{1,100}(\.\d{1,4})?$/, 'shared.validation.only_numbers_max_decimals.four')
    .test('min', 'shared.validation.only_positive_numbers', (val) => Number(val) >= 0)
    .test('max', 'financial.product_config.add.net_price_smaller_than_gross', (val, context) => {
      const gross = context.parent.pc_gross_value;

      return Number(val) <= Number(gross);
    }),
  pc_discount_percent: Yup.string()
    .required('financial.product_config.add.percentage_required')
    .matches(/^\d{1,100}(\.\d{1,4})?$/, 'shared.validation.only_numbers_max_decimals.four')
    .test(
      'isValid',
      'shared.validation.percentage_limit_smaller_than_100',
      (val) => Number(val) <= 100
    ),
  status_id: Yup.number(),
});
