import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import {
  Autocomplete,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
  // Skeleton,
} from '@material-ui/core';

import { useFormik } from 'formik';

import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';

import { useAppSelector } from '../../../../hooks/useRedux';

import { GroupAreaSchema } from '../constants/validation-schemas';

import { useUpdateGroupAreaMutation } from '../slices/groupAreasApiSlice';
import { statusAvailables } from '../../../shared/constants/resourceStatus';

import { useFormStyles } from '../constants/styles';

const EditGroupAreaForm = () => {
  const history = useHistory();
  const { groupAreaId } = useParams<{ groupAreaId: string }>();

  const { groupAreaList } = useAppSelector((state) => state.groupAreas);
  const { currentProfile } = useAppSelector((state) => state.access);

  const classes = useFormStyles();

  const [showAlerts, setShowAlerts] = useState(false);

  const [updateGroupArea, { isLoading: isUpdating, isSuccess, isError, error }] =
    useUpdateGroupAreaMutation();

  const groupAreaToEdit = groupAreaList.find((p) => p.id === Number(groupAreaId));

  const { getFieldProps, errors, touched, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      argName: groupAreaToEdit?.arg_name || '',
      argDescription: groupAreaToEdit?.arg_description || '',
      statusId: groupAreaToEdit?.status_id || '1',
    },
    validationSchema: GroupAreaSchema,
    onSubmit: async (values) => {
      await updateGroupArea({
        profileId: currentProfile?.profileId!,
        groupAreaId: groupAreaToEdit?.id!,
        groupAreaPayload: {
          arg_name: values.argName.toUpperCase(),
          arg_description: values.argDescription,
          arg_keywords: values.argName.toLowerCase().replaceAll(' ', '-'),
          status_id: values.statusId,
        },
      });

      setShowAlerts(true);
    },
  });

  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction="column" gap={2}>
        <Grid item>
          {isError && showAlerts && (
            <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
          )}

          {isSuccess && showAlerts && (
            <SuccessAlert
              message="El grupo ha sido actualizado correctamente."
              handleDismiss={() => setShowAlerts(false)}
            />
          )}
        </Grid>

        <Grid item container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>Nombre del Grupo</InputLabel>
            <TextField
              fullWidth
              error={Boolean(errors.argName && touched.argName)}
              placeholder="Ingrese el nombre del grupo"
              {...getFieldProps('argName')}
            />
            {errors.argName && touched.argName && (
              <FormHelperText error>{errors.argName}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>Descripción del Grupo</InputLabel>
            <TextField
              fullWidth
              error={Boolean(errors.argDescription && touched.argDescription)}
              placeholder="Ingrese la descripción del grupo"
              {...getFieldProps('argDescription')}
            />
            {errors.argDescription && touched.argDescription && (
              <FormHelperText error>{errors.argDescription}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>Estado</InputLabel>
            <Autocomplete
              options={statusAvailables}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField {...params} error={Boolean(errors.statusId && touched.statusId)} />
              )}
              value={statusAvailables.find((p) => p.id === Number(groupAreaToEdit?.status_id))}
              onChange={(e, value) => setFieldValue('statusId', value?.id || '')}
              fullWidth
              clearIcon={null}
            />
            {errors.statusId && touched.statusId && (
              <FormHelperText error>{errors.statusId}</FormHelperText>
            )}
          </Grid>
        </Grid>

        <Grid
          item
          container
          alignItems="center"
          justifyContent="flex-end"
          spacing={2}
          sx={{ mt: 1 }}
        >
          <Grid item>
            <Button
              variant="outlined"
              size="large"
              color="primary"
              onClick={() => history.goBack()}
            >
              Regresar
            </Button>
          </Grid>
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              disabled={isUpdating}
              endIcon={isUpdating && <CircularProgress size={20} />}
            >
              Actualizar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default EditGroupAreaForm;
