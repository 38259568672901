import { Grid, Typography } from '@material-ui/core';
import { useAppSelector } from '../../../../hooks/useRedux';
import MainCard from '../../../shared/components/cards/MainCard';
import DeleteRoleDialog from '../components/DeleteRoleDialog';

import RolesTable from '../components/RolesTable';
import { useTranslation } from 'react-i18next';

const RolesScreen = () => {
  const { t } = useTranslation();

  const { roleIdToDelete } = useAppSelector((state) => state.roles);
  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">{t('configuration.roles.title')}</Typography>
      </Grid>
      <RolesTable />
      {roleIdToDelete && <DeleteRoleDialog />}
    </Grid>
  );
};

export default RolesScreen;
