import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

// RTK Query - Middlewares

// import { academicOfferingsApi } from '../modules/maintenance/academic-offerings/slices/academicOfferingsApiSlice';
// import { academicPortfolioApi } from '../modules/students/academic-portfolio/slice/AcademicPortfolioApiSlice';
// import { academicRequestsApi } from '../modules/students/academic-requests/slices/academicRequestsApiSlice';
// import { agreementsApi } from '../modules/maintenance/agreements/slices/agreementsApiSlices';
// import { authApi } from '../modules/auth/slices/authApiSlice';
// import { calificationModelsApi } from '../modules/maintenance/calification-models/slices/calificationModelsApiSlice';
// import { calificationTypesApi } from '../modules/maintenance/califications-types/slices/calificationsTypesApiSlice';
// import { campusApi } from '../modules/maintenance/campus/slices/campusApiSlice';
// import { catalogApi } from '../modules/shared/slices/catalogApi';
// import { classroomEdLvlApi } from '../modules/maintenance/classrooms-educational-levels/slices/classroomEdLvlApiSlice';
// import { classroomsApi } from '../modules/maintenance/classrooms/slices/classroomsApiSlice';
// import { collaboratorsApi } from '../modules/hr/collaborators/slices/collaboratorsApiSlice';
// import { companiesApi } from '../modules/maintenance/companies/slices/companiesApiSlice';
// import { configurationsApi } from '../modules/maintenance/configurations/slices/configurationsApiSlice';
// import { courseApi } from '../modules/maintenance/courses/slices/courseApiSlice';
// import { departmentsApi } from '../modules/maintenance/departments/slices/departmentsApiSlice';
// import { economicGroupsApi } from '../modules/maintenance/economic-groups/slices/economicGroupsApiSlice';
// import { educationLevelCareersApi } from '../modules/maintenance/education-level-career/slices/ELCApiSlice';
// import { educationLevelsApi } from '../modules/maintenance/education-levels/slices/educationLevelsApiSlice';
// import { educationLevelSubjectsApi } from '../modules/maintenance/education-level-subject/slices/ELSApiSlice';
// import { groupAreasApi } from '../modules/maintenance/groups-area/slices/groupAreasApiSlice';
// import { HomologationsApi } from '../modules/maintenance/homologations/slices/homologationsApiSlice';
// import { hourhandsApi } from '../modules/maintenance/hourhands/slices/hourhandsApiSlice';
// import { hoursTeachersApi } from '../modules/hr/hours-to-teachers/slice/hoursTeacherApiSlice';
// import { institutesApi } from '../modules/maintenance/institutes/slices/institutesApiSlice';
// import { mattersApi } from '../modules/maintenance/matters/slices/mattersApiSlice';
// import { mattersTypesApi } from '../modules/maintenance/matters-types/slices/mattersTypesApiSlice';
// import { meshsApi } from '../modules/maintenance/meshs/slices/meshsApiSlice';
// import { parallelsApi } from '../modules/maintenance/parallels/slices/parallelsApiSlice';
// import { pensumApi } from '../modules/maintenance/pensums/slices/pensumsApiSlice';
// import { periodsApi } from '../modules/maintenance/periods/slices/periodsApiSlice';
// import { periodTypesApi } from '../modules/maintenance/periods-types/slices/periodTypesApiSlice';
// import { permissionsApi } from '../modules/maintenance/permissions/slices/permissionsApiSlice';
// import { positionsApi } from '../modules/maintenance/positions/slices/PositionsApiSlice';
// import { profilesApi } from '../modules/maintenance/profiles/slices/profilesApiSlice';
// import { requisitionTeacherApi } from '../modules/maintenance/requisition-teacher/slices/requisitionsApiSlice';
// import { rolesApi } from '../modules/maintenance/roles/slices/rolesApiSlice';
// import { studentsApi } from '../modules/maintenance/students/slices/studentsApiSlice';
// import { subjectsRegisterApi } from '../modules/students/subjects-register/slices/subjectsRegisterApiSlice';
// import { eventNewApi } from '../modules/maintenance/events-news/slices/eventNewApiSlice';
// import { hourhandApi } from '../modules/students/academic-hourhands/slices/academicHourhandApiSlice';
import { middlewareApi } from '../modules/middleware/middlewareApi';

// root reducer
import reducer from './reducer';

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middlewareApi.middleware),
  // devTools: process.env.NODE_ENV !== 'production',
  // middleware: (getDefaultMiddleware) =>
  //   getDefaultMiddleware().concat(
  //     authApi.middleware,
  //     academicOfferingsApi.middleware,
  //     academicPortfolioApi.middleware,
  //     academicRequestsApi.middleware,
  //     agreementsApi.middleware,
  //     calificationModelsApi.middleware,
  //     calificationTypesApi.middleware,
  //     campusApi.middleware,
  //     catalogApi.middleware,
  //     classroomEdLvlApi.middleware,
  //     classroomsApi.middleware,
  //     collaboratorsApi.middleware,
  //     companiesApi.middleware,
  //     configurationsApi.middleware,
  //     courseApi.middleware,
  //     departmentsApi.middleware,
  //     economicGroupsApi.middleware,
  //     educationLevelCareersApi.middleware,
  //     educationLevelsApi.middleware,
  //     educationLevelSubjectsApi.middleware,
  //     groupAreasApi.middleware,
  //     HomologationsApi.middleware,
  //     hourhandsApi.middleware,
  //     hoursTeachersApi.middleware,
  //     institutesApi.middleware,
  //     mattersApi.middleware,
  //     mattersTypesApi.middleware,
  //     meshsApi.middleware,
  //     parallelsApi.middleware,
  //     pensumApi.middleware,
  //     periodsApi.middleware,
  //     periodTypesApi.middleware,
  //     permissionsApi.middleware,
  //     positionsApi.middleware,
  //     profilesApi.middleware,
  //     // rolesApi.middleware,
  //     studentsApi.middleware,
  //     subjectsRegisterApi.middleware,
  //     requisitionTeacherApi.middleware,
  //     eventNewApi.middleware,
  //     // hourhandApi.middleware,
  //   ),
});

setupListeners(store.dispatch);

// root redux types based on store
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
