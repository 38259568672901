export const subPermissionKeys = {
  groupKey: 'permisoPendiente',
  readCampus: 'campus-listar-sedes',
  readOffers: 'offers-listar-ofertas',
  readOffersById: 'offers-obtener-oferta',
  readOfferByPeriod: 'offers-listar-periodos-por-oferta',
  read: 'classroom_education_levels-listar-aula-niveleconomico',
  create: 'classroom_education_levels-crear-aula-niveleconomico',
  edit: 'classroom_education_levels-actualizar-aula-niveleconomico',
  delete: 'classroom_education_levels-borrar-aula-niveleconomico',

  readClassroom: 'campus-obtener-aulas-filtradas-sedes',
};
