import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  useTheme,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useGetDependenceByMatterQuery } from '../../../teachers/courses/slices/coursesTeacherApiSlice';
import { MatterInfoForSyllabus } from '../../interfaces/matterInfoForSyllabus';

interface Props {
  matterData?: MatterInfoForSyllabus;
}

const MatterTableInfo = ({ matterData }: Props) => {
  const theme = useTheme();

  const { id } = useParams<{ id?: string }>();

  const { data: dependences } = useGetDependenceByMatterQuery({
    materia_id: id!,
  });

  const { t } = useTranslation();

  return (
    <TableContainer component={Paper} sx={{ border: 1 }}>
      <Table
        sx={{
          minWidth: '650px',
        }}
        aria-label="simple table"
      >
        <TableHead sx={{ backgroundColor: theme.palette.primary[300] }}>
          <TableRow>
            <TableCell align="center">{t('MATERIA')}</TableCell>
            <TableCell align="center">{t('CÓDIGO')}</TableCell>
            <TableCell align="center">{t('PRE-REQUISITO POR CARRERA')}</TableCell>
            <TableCell align="center">{t('PERIODO LECTIVO')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell align="center">{matterData?.materia || ''}</TableCell>
            <TableCell align="center">{matterData?.cod_materia || ''}</TableCell>
            <TableCell align="center">
              {dependences &&
                Array.isArray(dependences.subject_dependencies) &&
                dependences.subject_dependencies.map((dependence, index) => (
                  <p key={`${dependence.mat_name} - ${index}`}>{dependence.mat_name}</p>
                ))}

              {!dependences?.subject_dependencies && <p>{t('NO HAY PRE-REQUISITOS')}</p>}
            </TableCell>
            <TableCell align="center">{matterData?.per_name || ''}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MatterTableInfo;
