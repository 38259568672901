import { Switch, Redirect } from 'react-router-dom';


import { useAppSelector } from '../../../../hooks/useRedux';

//import { permissionKey, subPermissionKeys } from '../constants/permissions';
import GuardRoute from '../../../../router/guards/GuardRoute';
import MarketingTemplatesScreen from '../screens/MarketingTemplatesScreen';

const MarketingTemplatesRouter = () => {
    const { currentModule } = useAppSelector((state) => state.access);

    const hasPermission = 'crm' in currentModule?.permissions!;

    if (!hasPermission) return <Redirect to="/dashboard" />;

    return (
        <Switch>
            <GuardRoute
                exact
                path="/marketing-plantillas"
                component={MarketingTemplatesScreen}
                /* permissionKey={permissionKey}
                subPermissionKey={subPermissionKeys.create} */
            />
            
        </Switch>
    )
}

export default MarketingTemplatesRouter;