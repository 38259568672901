import { useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { IconButton, Grid, useTheme, Typography } from '@material-ui/core';
import { IconArrowNarrowLeft } from '@tabler/icons';
import { useTranslation } from 'react-i18next';

import MainCardForm from '../../../shared/components/cards/MainCardForm';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { useLazyGetConfigProductPriceQuery } from '../slices/configProductPriceApiSlice';
import { setConfigProductList } from '../slices/configProductPriceSlice';
import SimpleFormOneColumn from '../../../shared/components/forms/skeletons/SimpleFormOneColumn';
import ConfigProductPriceForm from '../components/ConfigProductPriceForm';

const ConfigProductPriceEditScreen = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  const history = useHistory();

  const { configProduct } = useParams<{ configProduct: string }>();

  const { configProductList } = useAppSelector((state) => state.configProducts);

  const configProductToEdit = configProductList.find((value) => value.id === Number(configProduct));

  const dispatch = useAppDispatch();

  const location = useLocation();

  const [getConfigProductPrice, { isFetching }] = useLazyGetConfigProductPriceQuery();

  useEffect(() => {
    if (!configProductList.length && !isFetching) {
      getConfigProductPrice({
        configProductId: +configProduct,
      })
        .unwrap()
        .then((response) => {
          if (response) dispatch(setConfigProductList([response!]));
        })
        .catch((error) => console.log(error));
    }
  }, [getConfigProductPrice, isFetching, configProduct, configProductList.length, dispatch]);

  console.log(configProductToEdit);
  return (
    <MainCardForm>
      <Grid item xs={12}>
        <Grid item container alignItems="center" gap={1} sx={{ cursor: 'pointer' }}>
          <IconButton onClick={() => history.push(`/${location.pathname.split('/')[1]}`)}>
            <IconArrowNarrowLeft size={30} color={theme.palette.text.dark} />
          </IconButton>
          <Typography variant="h2">
            {t('financial.product_config.edit.title')} -{' '}
            {configProductToEdit?.product?.pr_description ||
              t('financial.product_config.edit.loading')}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} container>
        {!!configProductToEdit ? (
          <ConfigProductPriceForm editData={configProductToEdit} />
        ) : (
          <SimpleFormOneColumn />
        )}
      </Grid>
    </MainCardForm>
  );
};

export default ConfigProductPriceEditScreen;
