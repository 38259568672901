import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/useRedux';
import { useTableSearch } from '../../../../../../hooks/useTableSearch';
import AddButton from '../../../../../shared/components/buttons/AddIconButton';
import DeleteButton from '../../../../../shared/components/tables/buttons/DeleteButton';
import EditButton from '../../../../../shared/components/tables/buttons/EditButton';
import SearchPaginatedTable from '../../../../../shared/components/tables/SearchPaginatedTable';
import { ISearchTable } from '../../../../../shared/interfaces/paginatedTable.interface';
import { useGetOpeningDatesQuery } from '../slices/openingDateApiSlice';
import {
  setOpeningDatesDeleteModalOpen,
  setOpeningDatesIdSelected,
  setOpeningDatesIdToDelete,
  setOpeningDatesList,
  setOpeningDatesSideDialogOpen,
  setOpeningDatesTable,
} from '../slices/openingDateSlice';

const OpeningDateTable = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const {
    openingDatesList,
    openingDatesTable: { page, perPage, search },
  } = useAppSelector((state) => state.openingDates);

  const { setPage, setPerPage, setSearch } = useTableSearch({
    page,
    perPage,
    search,
    updateFunction: setOpeningDatesTable as ActionCreatorWithPayload<ISearchTable, string>,
  });

  const { data, isLoading, isFetching, error } = useGetOpeningDatesQuery({
    page,
    perPage,
    search,
  });

  useEffect(() => {
    if (data?.data) {
      dispatch(setOpeningDatesList(data?.data));
    }
  }, [data, dispatch]);

  const datafetch = openingDatesList.map((item, idx) => ({
    id: (page - 1) * perPage + (idx + 1),
    description: item.description,
    created_at: item.created_at?.split('T')[0],
    options: { id: item.uuid },
  }));

  const goToAdd = () => {
    dispatch(setOpeningDatesSideDialogOpen(true));
  };

  const handleEdit = (id: string) => {
    dispatch(setOpeningDatesIdSelected(id));
    dispatch(setOpeningDatesSideDialogOpen(true));
  };

  const handleDelete = (id: string) => {
    dispatch(setOpeningDatesIdToDelete(id));
    dispatch(setOpeningDatesDeleteModalOpen(true));
  };

  return (
    <SearchPaginatedTable
      data={datafetch!}
      headers={{
        id: t('maintenance.opening_dates.id'),
        description: t('maintenance.opening_dates.description'),
        created_at: t('maintenance.opening_dates.creation'),
        options: t('maintenance.opening_dates.options'),
      }}
      keyExtractor={(item) => item.id!.toString()}
      searchPlacehoder={t('maintenance.opening_dates.search')}
      isLoading={isLoading}
      isFetching={isFetching}
      error={error as any}
      perPage={perPage}
      setPerPage={setPerPage}
      total={data?.total || 0}
      page={page}
      setPage={setPage}
      numHeader={5}
      search={search}
      setSearch={setSearch}
      customDataCellsProperties={{
        id: {
          align: 'center',
        },
        description: {
          align: 'left',
        },
        created_at: {
          align: 'center',
        },
        options: {
          align: 'center',
        },
      }}
      customRenderers={{
        // eslint-disable-next-line react/no-unstable-nested-components
        options: (row) => (
          <>
            <EditButton handleEdit={() => handleEdit(row.options.id!)} />
            <DeleteButton handleDelete={() => handleDelete(row.options.id!)} />
          </>
        ),
      }}
      ActionButtons={<AddButton onClick={goToAdd} />}
    />
  );
};

export default OpeningDateTable;
