// Generated by https://quicktype.io

export interface SubjectCurriculumStatus {
  subject_curriculum_id: string;
  matter_id: string;
  matter_rename: string;
  keyword_status_matter: KeywordStatusMatter;
  approval_reason_id: string;
  status_matter: string;
  course_exist: 'No disponible' | 'Disponible';
  st_name: StName;
  status_id: null | string;
  cat_name: string;
  cat_keyword: string;
  note: string;
  course_student_id: string;
  equivalences: Equivalence[];
  max_number_failed_subject: number;
  repeat_number_subject: number;
  repeat_subject: boolean;
  mesaje_validate: string | null;
}

export interface Equivalence {
  id: number;
  calification_model_id: string;
  min_value: string;
  max_value: string;
  qualitative: string;
  isapproved: string;
  created_at: string;
  updated_at: string;
  deleted_at: null;
}

export enum KeywordStatusMatter {
  APR = 'APR',
  RPR = 'RPR',
  RTD = 'RTD',
  CUR = 'CUR',
  NDF = 'NDF',
}

export enum StName {
  Activo = 'Activo',
}
