import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ISearchTable } from '../../../shared/interfaces/paginatedTable.interface';
import { Category } from '../interfaces/category.interface';

interface CategoryState {
  categories: Category[];
  deleteModalOpen: boolean;
  categoryIdToDelete?: number;
  categoriesTable: ISearchTable;
}

const initialState: CategoryState = {
  categories: [],
  deleteModalOpen: true,
  categoriesTable: {
    page: 1,
    perPage: 5,
    search: '',
  },
};

const categorySlice = createSlice({
  name: 'categorySlice',
  initialState,
  reducers: {
    setCategories: (state, action: PayloadAction<Category[]>) => {
      state.categories = action.payload;
    },
    setDeleteModalOpen: (state, action: PayloadAction<boolean>) => {
      state.deleteModalOpen = action.payload;
    },
    openDeleteModalWith: (state, action: PayloadAction<number>) => {
      state.categoryIdToDelete = action.payload;
      state.deleteModalOpen = true;
    },
    resetCategoryId: (state) => {
      state.categoryIdToDelete = undefined;
    },
    updateCategoriesTable: (state, action: PayloadAction<ISearchTable>) => {
      state.categoriesTable = action.payload;
    },
  },
});

export const {
  resetCategoryId,
  openDeleteModalWith,
  setDeleteModalOpen,
  setCategories,
  updateCategoriesTable,
} = categorySlice.actions;

export default categorySlice.reducer;
