import { Grid, IconButton, Typography, useTheme } from '@material-ui/core';
import { IconArrowNarrowLeft } from '@tabler/icons';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import MainCardForm from '../../../shared/components/cards/MainCardForm';
import SimpleFormOneColumn from '../../../shared/components/forms/skeletons/SimpleFormOneColumn';
import EditAcademicOfferingForm from '../components/EditAcademicOfferingForm';
import { useLazyGetAcademicOfferingQuery } from '../slices/academicOfferingsApiSlice';
import { setAcademicOfferingsList } from '../slices/academicOfferingsSlice';

const EditAcademicOfferingScreen = () => {
  const theme = useTheme();
  const history = useHistory();

  const dispatch = useAppDispatch();
  const { academicOfferingId } = useParams<{ academicOfferingId: string }>();

  const { academicOfferingsList } = useAppSelector((state) => state.academicOfferings);

  const [getAcademicOffering, { isFetching }] = useLazyGetAcademicOfferingQuery();

  useEffect(() => {
    if (!academicOfferingsList.length && !isFetching) {
      getAcademicOffering({
        academicOfferingId: +academicOfferingId,
      })
        .unwrap()
        .then((response) => {
          if (response) dispatch(setAcademicOfferingsList([response!]));
        })
        .catch((error) => console.log(error));
    }
  }, [getAcademicOffering, isFetching, academicOfferingId, academicOfferingsList.length, dispatch]);

  return (
    <MainCardForm>
      <Grid item xs={12}>
        <Grid item container alignItems="center" gap={1} sx={{ cursor: 'pointer' }}>
          <IconButton onClick={() => history.goBack()}>
            <IconArrowNarrowLeft size={30} color={theme.palette.text.dark} />
          </IconButton>
          <Typography variant="h2">Editar</Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} md={8} lg={6} sx={{ mt: 2 }}>
        {!!academicOfferingsList.length ? <EditAcademicOfferingForm /> : <SimpleFormOneColumn />}
      </Grid>
    </MainCardForm>
  );
};

export default EditAcademicOfferingScreen;
