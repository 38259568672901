import { useFormikContext } from 'formik';
import Grid from '@material-ui/core/Grid';
import {
  Autocomplete,
  FormHelperText,
  InputAdornment,
  InputLabel,
  TextField,
} from '@material-ui/core';
import { FaqForm } from '../../interfaces/faq-form-interfaces';
import { IconVideo } from '@tabler/icons';
import menuItems from '../../../../shared/menu-items';
import { useTranslation } from 'react-i18next';

export const FaqEditInfo = () => {
  const { t } = useTranslation();

  const { setFieldValue, values, errors, touched } = useFormikContext<FaqForm>();

  const items = menuItems.items
    .map((a) => {
      return a.children?.map((e) => ({ title: e.title?.toString()! }!))!;
    })
    .flat(1);

  const itemsTags = [...new Set(items.map((a) => a.title.toString()!))];

  const tags = itemsTags.map((e) => ({ title: e }));

  return (
    <>
      <Grid container spacing={4} justifyContent="center" alignItems="flex-start">
        <Grid item xs={12} md={6} container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={12}>
            <InputLabel>{t('configuration.faqs.stepper.step2.question')}</InputLabel>
            <TextField
              fullWidth
              error={Boolean(errors.faq_question && touched.faq_question)}
              placeholder={t('configuration.faqs.stepper.step2.question.placeholder')}
              value={values.faq_question}
              onChange={(e) => {
                setFieldValue('faq_question', e.target.value);
              }}
            />
            {errors.faq_question && touched.faq_question && (
              <FormHelperText error>{t(errors.faq_question)}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={12}>
            <InputLabel>{t('configuration.faqs.stepper.step2.description')}</InputLabel>
            <TextField
              fullWidth
              multiline
              rows={7}
              error={Boolean(errors.faq_answer && touched.faq_answer)}
              placeholder={t('configuration.faqs.stepper.step2.description.placeholder')}
              value={values.faq_answer}
              onChange={(e) => {
                setFieldValue('faq_answer', e.target.value);
              }}
            />
            {errors.faq_answer && touched.faq_answer && (
              <FormHelperText error>{t(errors.faq_answer)}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={12}>
            <InputLabel>{t('configuration.faqs.stepper.step2.video_link')}</InputLabel>
            <TextField
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <IconVideo fontSize="smal" />
                  </InputAdornment>
                ),
              }}
              fullWidth
              error={Boolean(errors.faq_link_video && touched.faq_link_video)}
              placeholder={t('configuration.faqs.stepper.step2.video_link.placeholder')}
              value={values.faq_link_video}
              onChange={(e) => {
                setFieldValue('faq_link_video', e.target.value);
              }}
            />
            {errors.faq_link_video && touched.faq_link_video && (
              <FormHelperText error>{t(errors.faq_link_video)}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} sx={{ mt: 2 }}>
            <InputLabel>{t('configuration.faqs.stepper.step2.tags')}</InputLabel>
            <Autocomplete
              multiple
              options={tags}
              loadingText={t('configuration.faqs.stepper.step2.tags.loading')}
              noOptionsText={t('configuration.faqs.stepper.step2.tags.no_data')}
              getOptionLabel={(option) => option?.title!}
              renderInput={(params) => (
                <TextField
                  placeholder={t('configuration.faqs.stepper.step2.tags.placeholder')}
                  {...params}
                  error={Boolean(errors.faq_tags && touched.faq_tags)}
                />
              )}
              value={tags?.filter((t) => values?.faq_tags?.some((dt) => dt === t.title)) || []}
              onChange={(_, values) => {
                setFieldValue('faq_tags', [...values.map((v) => v.title)] || '');
              }}
              fullWidth
              clearIcon={null}
            />
            {errors.faq_tags && touched.faq_tags && (
              <FormHelperText error>{t(errors.faq_tags)}</FormHelperText>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
