import { ChangeEvent, useRef, useEffect, useState, FC, useMemo, useCallback } from 'react';

import {
  Alert,
  Box,
  Grid,
  Typography,
  TextField,
  Autocomplete,
  FormHelperText,
  useTheme,
  Button,
  Stack,
  IconButton,
  CircularProgress,
  Skeleton,
} from '@material-ui/core';
import { IconFile } from '@tabler/icons';
import { useFormik } from 'formik';
import DatePicker from '@material-ui/lab/DatePicker';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import esLocale from 'date-fns/locale/es';
import CloseIcon from '@material-ui/icons/Close';
import PictureAsPdf from '@material-ui/icons/PictureAsPdf';
import {
  useGetFinancialInstitutionAccountQuery,
  useGetFinancialInstitutionsQuery,
  useGetStudentBillingDataQuery,
} from '../../slices/paymentsApiSlice';
import InputLabel from '../../../../shared/components/forms/InputLabel';
import AnimateButton from '../../../../shared/components/extended/AnimateButton';
import { useAddDepositVoucherMutation } from '../../slices/paymentsApiSlice';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import SuccessAlert from '../../../../shared/components/alerts/SuccessAlert';
import { PaymentsDebts } from '../../interfaces/paymentsHistory.interface';
import { TransferPaymentSchema } from '../../constants/validation-schemas';
import { Fee } from '../../interfaces/payload.interface';
import { setSelectedDebts, setSelectedDebtsId } from '../../slices/paymentsSlice';
import TextFieldFetching from '../../../../shared/components/TextFieldFetching';
import ErrorAlertStudentPays from '../builder/ErrorAlertStudentPays';

interface Props {
  handleBack: () => void;
  handleNext: () => void;
  setErrorIndex: (i: number | null) => void;
}

const DepositPayment: FC<Props> = (props) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const { handleBack } = props;

  const { currentProfile, defaultConfiguration, msj_business_hours, currentRecordStudent } =
    useAppSelector((state) => state.access);
  const { selectedDebts: selected, debtorId } = useAppSelector((state) => state.payments);

  // input file ref
  const fileRef = useRef<HTMLInputElement>(null);

  //file data
  const [voucherFile, setVoucherFile] = useState<File>();
  //file url
  const [imgSrc, setImgSrc] = useState<string>();
  //file size alert
  const [imgAlert, setImgAlert] = useState<boolean>();
  const [typeAlert, setTypeAlert] = useState<boolean>();
  const [alertFile, setAlertFile] = useState<boolean>();
  const [showAlerts, setShowAlerts] = useState<boolean>();

  //array error
  const [errorString, setErrorString] = useState<string[]>([]);

  const { data: fIA = [], isLoading: isLoadingFIA } = useGetFinancialInstitutionAccountQuery({
    profileId: currentProfile?.profileId!,
  });

  const { data: fI = [], isLoading: isLoadingFI } = useGetFinancialInstitutionsQuery({
    profileId: currentProfile?.profileId!,
  });

  //billing Data
  const { data: billingData } = useGetStudentBillingDataQuery({
    profileId: currentProfile?.profileId!,
    debtorId,
  });

  const [uploadVoucher, { isLoading, isError, error, isSuccess }] = useAddDepositVoucherMutation();

  const restartSelectedDebts = useCallback(() => {
    if (selected) {
      dispatch(setSelectedDebts([]));
      dispatch(setSelectedDebtsId([]));
    }
  }, [selected, dispatch]);
  // simulate input file click
  const onClickUpload = () => {
    if (!fileRef) return;
    fileRef!.current!.click();
  };

  // trigger onChange del input file al cargar archivos
  const onFilechange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      setVoucherFile(undefined);
      return;
    }

    const files = e.target.files;

    if (files[0].size > 10485760) {
      setImgAlert(true);
      setVoucherFile(undefined);
      return;
    }

    if (!checkExtension(files[0].name)) {
      setTypeAlert(true);
      setVoucherFile(undefined);
      return;
    }

    setImgAlert(false);
    setTypeAlert(false);
    setVoucherFile(files[0]);
  };

  //Return extension file
  const getExtension = (filename: string) => {
    var parts = filename.split('.');
    return parts[parts.length - 1];
  };

  //Check the extension
  const checkExtension = (filename: string) => {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'pdf':
        return true;
    }
    return false;
  };

  const fees: Fee[] = selected.map((s: PaymentsDebts) => {
    return {
      student_record_id: currentRecordStudent?.id!,
      fees_identifier: s.fees_identifier!,
      fees_start_date: new Date(s.fees_start_date!),
      fees_expiration_date: new Date(s.fees_expiration_date!),
      fees_name_identifier: s.fees_name_identifier!,
      period_id: s.period_id!,
      per_name: s.per_name!,
      per_reference: s.per_reference!,
      per_current_year: s.per_current_year!,
      per_due_year: s.per_due_year!,
      debtor_id: s.debtor_id!,
      debtor_student_id: s.debtor_student_id!,
      student_id: s.student_id!,
      fees_balance: s.fees_balance!,
      o_fees_balance: s.fees_balance!,
      apply_early_payment: s.apply_early_payment === true,
      o_discount_early_payment: s.o_discount_early_payment!,
      o_discount_early_payment_is_percent: s.o_discount_early_payment_is_percent === true,
      o_discount_value_early_payment: s.o_discount_value_early_payment!,
      o_net_value_early_payment: s.o_net_value_early_payment!,
      o_discount_percent_early_payment: s.o_discount_percent_early_payment!,
      o_fees_net_amount: s.o_fees_net_amount,
      fees_payment: s.fees_payment,
    };
  });

  const totalAmount = useMemo(() => {
    let amount = 0;

    selected.forEach((el) => {
      amount += +el?.apply_early_payment!
        ? Number(el.net_value_early_payment)
        : Number(el.fees_balance);
    });

    return amount;
  }, [selected]);

  const { getFieldProps, errors, touched, handleSubmit, setFieldValue, values } = useFormik({
    initialValues: {
      depositBank: 0,
      accountBank: '',
      depositAmount: 0.0,
      depositDate: new Date(),
      depositNumber: '',
      accountOwner: '',
      bankOwner: 0,
    },
    validationSchema: TransferPaymentSchema,
    // validate: ({ start_process, end_process, startDate, endDate }) => {
    //   return validateDates(start_process, end_process, startDate, endDate);
    // },
    onSubmit: async (values) => {
      await uploadVoucher({
        profileId: currentProfile?.profileId!,
        depositPayload: {
          user_profile_id: currentProfile?.profileId!,
          files: voucherFile!,
          type_document: 11,
          fees: fees,
          collections: [
            {
              voucher_number: values.depositNumber,
              cuenta_destino: values.accountBank,
              financial_institution_origin_id: values.bankOwner,
              name_holder_origin_account: values.accountOwner,
              financial_institution_target_id: values.depositBank,
              payment_date: values.depositDate,
            },
          ],
          temporal: {
            tipoIdentificacion: billingData?.tipoIdentificacion!,
            identificacion: billingData?.identificacion!,
            razonSocial: billingData?.razonSocial!,
            direccion: billingData?.direccion!,
            telefono: billingData?.telefono!,
            email: billingData?.email!,
          },
          /*depositPayment: {
            cash_collection_id: 0,
            cuenta_destino: values.accountBank,
            voucher_number: values.depositNumber,
            financial_institution_origin_id: values.bankOwner,
            financial_institution_target_id: values.depositBank,
            name_holder_origin_account: values.accountOwner,
            collections: [
              //ProntoPago Payment
              {
                payment_way: prontoPagoPaymentId,
                value: totalAmountProntoPago,
                payment_date: values.depositDate,
                students: {
                  debtor_student_id: Number(selected[0]?.debtor_student_id!),
                  balance_positive: null,
                  fees: feesProntoPago!,
                },
              },
              //Transfer Payment
              {
                payment_way: transferPaymentId,
                value: totalAmount,
                payment_date: values.depositDate,
                students: {
                  debtor_student_id: Number(selected[0]?.debtor_student_id!),
                  balance_positive: null,
                  fees: fees!,
                },
              },
            ],
          },*/
        },
      });
      setShowAlerts(true);
    },
  });

  useEffect(() => {
    if (isSuccess) {
      let time = setTimeout(() => restartSelectedDebts(), 5000);
      return () => {
        clearTimeout(time);
      };
    } /*else if (isError) {
      console.log('quedate en la pantalla');
    }*/
  }, [isSuccess, restartSelectedDebts /*, isError*/]);

  // controlar mensajes de error al cargar archivo
  useEffect(() => {
    if (isError && !isLoading) {
      setAlertFile(true);
      setVoucherFile(undefined);
      fileRef.current!.value = '';
    } else {
      setAlertFile(false);
    }
  }, [isError, isLoading]);

  //side effect for set img preview
  useEffect(() => {
    // create the preview
    if (!voucherFile) {
      setImgSrc(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(voucherFile);
    setImgSrc(objectUrl);
    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [voucherFile]);

  //handle error
  useEffect(() => {
    if (isError && error) {
      setErrorString(error as string[]);
      setAlertFile(true);
    }
  }, [isError, error]);

  useEffect(() => {
    if (fIA && fIA.length === 1) {
      setFieldValue('depositBank', fIA[0].financial_institution_id!);
      setFieldValue('accountBank', fIA[0].fia_account_number!);
    }
  }, [fIA, setFieldValue]);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={12} container justifyContent="center">
          <Grid item xs={12} md={6}>
            <Typography variant="h5" align="center">
              TRANSFERENCIA BANCARIA
            </Typography>
          </Grid>
        </Grid>

        {msj_business_hours && (
          <Grid item xs={12} container justifyContent="center">
            <Grid item xs={12} md={6}>
              <Alert severity="info" icon={false} sx={{ paddingX: 2 }}>
                <Typography component="p" align="center">
                  AVISO
                </Typography>
                <Typography component="p">{msj_business_hours}</Typography>
              </Alert>
            </Grid>
          </Grid>
        )}

        <Grid item xs={12} container justifyContent="center">
          <Grid item xs={12} md={6}>
            <Alert severity="warning" icon={false} sx={{ paddingX: 4 }}>
              <Typography component="p" align="center">
                CONSIDERACIONES
              </Typography>
              <ul>
                <li>El comprobante es requerido</li>
                <li>Archivos admitidos JPG, PNG o PDF (max {defaultConfiguration?.file_size}Mb)</li>
                <li>Tomar Foto con buena Iluminación</li>
                <li>Toma de documento completo y legible</li>
                <li>
                  Aplica para el pago total de la deuda, caso contrario notificar al departamento
                  financiero
                </li>
              </ul>
            </Alert>
          </Grid>
        </Grid>

        {imgAlert && (
          <Grid item xs={12} container justifyContent="center">
            <Grid item xs={12} md={6}>
              <Alert
                severity="error"
                action={
                  <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={() => {
                      setImgAlert(false);
                    }}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                }
              >
                <Typography component="p" align="center">
                  El peso de la imagen sobrepasa los {defaultConfiguration?.file_size}Mb
                </Typography>
              </Alert>
            </Grid>
          </Grid>
        )}

        {alertFile && (
          <Grid item xs={12} container justifyContent="center">
            <Grid item xs={12} md={6}>
              <ErrorAlertStudentPays
                errorString={errorString}
                isError={isError}
                setAlertFile={() => setAlertFile(false)}
              />
            </Grid>
          </Grid>
        )}

        {typeAlert && (
          <Grid item xs={12} container justifyContent="center">
            <Grid item xs={12} md={6}>
              <Alert
                severity="error"
                action={
                  <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={() => {
                      setTypeAlert(false);
                    }}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                }
              >
                <Typography component="p" align="center">
                  El tipo de archivo no es correcto
                </Typography>
              </Alert>
            </Grid>
          </Grid>
        )}

        {!imgSrc && (
          <Grid item xs={12} container justifyContent="center">
            <Grid item xs={12} md={6}>
              <Box
                border={`1px dashed ${theme.palette.primary.main}`}
                borderRadius="12px"
                sx={{
                  cursor: 'pointer',
                  userSelect: 'none',
                  ':hover': {
                    backgroundColor: '#eee',
                  },
                }}
                p={4}
                justifyContent="center"
                onClick={onClickUpload}
              >
                <Typography align="center" color="primary">
                  <IconFile />
                </Typography>
                <Typography align="center" color="primary">
                  CARGAR COMPROBANTE
                </Typography>
              </Box>
            </Grid>
          </Grid>
        )}

        {isSuccess && showAlerts && (
          <Grid item xs={12} container justifyContent="center">
            <Grid item xs={12} md={6}>
              <SuccessAlert
                message="Datos de la transferencia guardados correctamente."
                handleDismiss={() => setShowAlerts(false)}
              />
            </Grid>
          </Grid>
        )}

        <Grid item xs={12} container justifyContent="center">
          <Grid item xs={12} md={6}>
            <Grid container spacing={1}>
              {imgSrc && (
                <>
                  {!isLoading ? (
                    <Grid item xs={12} md={12}>
                      {voucherFile && voucherFile.type === 'application/pdf' ? (
                        <Alert
                          severity="warning"
                          icon={false}
                          sx={{ flexDirection: 'row', alignContent: 'center' }}
                        >
                          <PictureAsPdf fontSize="small" sx={{ mr: 1 }} />
                          <span>{voucherFile.name}</span>
                        </Alert>
                      ) : (
                        <img src={imgSrc} alt="comprobante" width="100%" />
                      )}
                    </Grid>
                  ) : (
                    <Skeleton
                      variant="rectangular"
                      sx={{ borderRadius: '12px' }}
                      height="150px"
                      width="100%"
                    />
                  )}
                  <Grid container item xs={12} justifyContent="flex-end">
                    <Button
                      variant="outlined"
                      onClick={onClickUpload}
                      disabled={isLoading}
                      endIcon={isLoading && <CircularProgress size={20} />}
                    >
                      Cargar Nueva imagen
                    </Button>
                  </Grid>
                  <FormHelperText>
                    Archivos admitidos JPG, PNG, JPEG o PDF (max {defaultConfiguration?.file_size}
                    Mb)
                  </FormHelperText>
                </>
              )}
              <Grid item hidden>
                <input
                  type="file"
                  ref={fileRef}
                  //   inputRef={fileRef}
                  onChange={onFilechange}
                  accept=".png, .jpg, .jpeg, .pdf"
                  //   inputProps={{ accept: 'image/jpg, image/png' }}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel>Seleccione el Banco al que acreditaste el monto</InputLabel>
                {!isLoadingFIA ? (
                  <Autocomplete
                    options={Array.isArray(fIA) ? fIA : []}
                    loading={isLoadingFIA}
                    loadingText="Cargando bancos..."
                    getOptionLabel={(option) =>
                      option.financial_institution.fin_int_name + ' - ' + option.fia_account_number
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={Boolean(errors.depositBank && touched.depositBank)}
                        placeholder="Seleccione el banco destino"
                      />
                    )}
                    onChange={(_, value) => {
                      setFieldValue('depositBank', value?.financial_institution_id!);
                      setFieldValue('accountBank', value?.fia_account_number!);
                    }}
                    fullWidth
                    clearIcon={null}
                    defaultValue={fIA.length === 1 ? fIA[0] : null}
                  />
                ) : (
                  <TextFieldFetching />
                )}
                {errors.depositBank && touched.depositBank && (
                  <FormHelperText error>{errors.depositBank}</FormHelperText>
                )}
              </Grid>

              <Grid item xs={12} sm={6}>
                <InputLabel>Fecha del Comprobante</InputLabel>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                  <DatePicker
                    value={values.depositDate}
                    onChange={(newValue) => {
                      setFieldValue('depositDate', newValue);
                    }}
                    maxDate={new Date()}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        helperText=""
                        error={Boolean(errors.depositDate && touched.depositDate)}
                        fullWidth
                      />
                    )}
                  />
                </LocalizationProvider>
                {errors.depositDate && touched.depositDate && (
                  <FormHelperText error>{errors.depositDate}</FormHelperText>
                )}
              </Grid>

              <Grid item xs={12} sm={6}>
                <InputLabel>Valor total a pagar</InputLabel>
                <TextField
                  fullWidth
                  disabled
                  error={Boolean(errors.depositAmount && touched.depositAmount)}
                  type="number"
                  value={totalAmount}
                />
              </Grid>

              <Grid item xs={12}>
                <InputLabel>No. de Papeleta</InputLabel>
                <TextField
                  fullWidth
                  error={Boolean(errors.depositNumber && touched.depositNumber)}
                  placeholder="Ingrese el número de papeleta"
                  {...getFieldProps('depositNumber')}
                />
                {errors.depositNumber && touched.depositNumber && (
                  <FormHelperText error>{errors.depositNumber}</FormHelperText>
                )}
              </Grid>

              <Grid item xs={12}>
                <InputLabel>Titular de la Cuenta</InputLabel>
                <TextField
                  fullWidth
                  error={Boolean(errors.accountOwner && touched.accountOwner)}
                  placeholder="Ingrese el nombre del titular"
                  {...getFieldProps('accountOwner')}
                />
                {errors.accountOwner && touched.accountOwner && (
                  <FormHelperText error>{errors.accountOwner}</FormHelperText>
                )}
              </Grid>

              <Grid item xs={12}>
                <InputLabel>Seleccione el Banco del titular de la cuenta</InputLabel>
                <Autocomplete
                  options={Array.isArray(fI) ? fI : []}
                  loading={isLoadingFI}
                  loadingText="Cargando bancos..."
                  getOptionLabel={(option) => option.fin_int_name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(errors.bankOwner && touched.bankOwner)}
                      placeholder="Seleccione el banco del titular de la cuenta"
                    />
                  )}
                  onChange={(_, value) => {
                    setFieldValue('bankOwner', value?.id!);
                  }}
                  fullWidth
                  clearIcon={null}
                />
                {errors.bankOwner && touched.bankOwner && (
                  <FormHelperText error>{errors.bankOwner}</FormHelperText>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="center">
            <Button sx={{ my: 3, ml: 1 }} disabled={isLoading} onClick={handleBack}>
              Regresar
            </Button>
            <AnimateButton>
              <Button
                variant="contained"
                type="submit"
                sx={{ my: 3, ml: 1 }}
                disabled={isLoading || !imgSrc || isSuccess}
                endIcon={isLoading && <CircularProgress size={20} />}
              >
                Pagar
              </Button>
            </AnimateButton>
          </Stack>
        </Grid>
      </Grid>
    </form>
  );
};

export default DepositPayment;
