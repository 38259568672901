import * as Yup from 'yup';

export const ChangePeriodSchema = Yup.object().shape({
  studentId: Yup.object().required('El estudiante es requerido.'),
  periodId: Yup.string().required('El período es requerido.'),
  assignedCourses: Yup.number()
    .min(1, 'Debes seleccionar al menos una materia')
    .required('El programa es requerido.'),
  canUpdateDates: Yup.boolean().required('El campo actualizacion de  fecha es requerida.'),
});
