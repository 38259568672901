import { useState, useEffect } from 'react';

import { Alert, Grid, Button, Typography, CircularProgress, Collapse } from '@material-ui/core';

import { parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';

import {
  useOpenCashPointMutation,
  useGetIsAvailableCashPointQuery,
} from '../../../../close-cash-point/slices/cashPointsApiSlice';

import { useAppSelector } from '../../../../../../hooks/useRedux';
import useSharedGuard from '../../../../../../hooks/useSharedGuard';
import { cashPointPermissions } from '../../../constants/permissions';

const OpenCashPoint = () => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(true);
  const [isOpenAvailable, setIsOpenAvailable] = useState(true);
  const [alertMsg, setAlertMsg] = useState<string>();

  const { hasAccess } = useSharedGuard(cashPointPermissions);

  const { currentProfile } = useAppSelector((state) => state.access);
  const {
    userInfo: { id: userId },
  } = useAppSelector((state) => state.auth);

  const [
    openCashPointTrigger,
    { isLoading, isError: isErrorTrigger, error: errorTrigger, isSuccess },
  ] = useOpenCashPointMutation();

  const openCashPointHandler = async () => {
    await openCashPointTrigger({
      profileId: currentProfile?.profileId!,
    });
  };

  const {
    data: cashPoint,
    isError,
    error,
    // codeError,
  } = useGetIsAvailableCashPointQuery({
    profileId: currentProfile?.profileId!,
    userId: Number(userId),
  });

  useEffect(() => {
    if (error && isError) {
      if ((error as any[])[0] === '0') {
        setAlertMsg(t('financial.debts_payment.wizard.open_cash_point.is_closed'));
        setIsOpen(false);
      }
    } else if (cashPoint) {
      if (
        parseISO(cashPoint.opening_date!).getDate() < new Date().getDate() ||
        parseISO(cashPoint.opening_date!).getMonth() < new Date().getMonth()
      ) {
        setAlertMsg(t('financial.debts_payment.wizard.open_cash_point.expire_alert'));
        setIsOpenAvailable(false);
        setIsOpen(false);
      }
    }
  }, [cashPoint, error, isError, t]);

  useEffect(() => {
    if (isSuccess) {
      let timer = setTimeout(() => {
        setIsOpen(false);
      }, 3000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [isSuccess]);

  return (
    <>
      {!isOpen && (
        <Grid container>
          <Grid item xs={12}>
            <Collapse in={!isOpen}>
              <Alert
                severity={
                  !isErrorTrigger && !isSuccess
                    ? 'warning'
                    : isErrorTrigger && !isSuccess
                    ? 'error'
                    : !isErrorTrigger && isSuccess
                    ? 'success'
                    : 'warning'
                }
              >
                {!isErrorTrigger && !isSuccess && <Typography>{alertMsg}</Typography>}
                {isErrorTrigger && !isSuccess && <Typography>{errorTrigger}</Typography>}
                {!isErrorTrigger && isSuccess && (
                  <Typography>
                    {t('financial.debts_payment.wizard.open_cash_point.opened_alert')}
                  </Typography>
                )}
                {!isSuccess && isOpenAvailable && (
                  <>
                    {hasAccess('openCashPoint') ? (
                      <Button
                        onClick={openCashPointHandler}
                        disabled={isLoading}
                        endIcon={isLoading && <CircularProgress size={20} />}
                      >
                        {t('financial.debts_payment.wizard.open_cash_point.open_btn')}
                      </Button>
                    ) : (
                      <Typography>
                        {t(
                          'financial.debts_payment.wizard.open_cash_point.open_btn.permissions_denied'
                        )}
                      </Typography>
                    )}
                  </>
                )}
              </Alert>
            </Collapse>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default OpenCashPoint;
