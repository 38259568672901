import { Grid, Button } from '@material-ui/core';
import { ArrowBack, ArrowForward } from '@material-ui/icons';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

interface Props {
  data?: string;
  setNext: React.Dispatch<React.SetStateAction<number>>;
  isBack?: boolean;
  setData: (value: any) => void;
  aditionalFunctionInNext?: () => void;
  finalStep?: boolean;
  errorComponent?: JSX.Element;
}

const EditorTextSylabus = ({
  data,
  setNext,
  isBack = false,
  finalStep,
  setData,
  aditionalFunctionInNext,
  errorComponent,
}: Props) => {
  const handleNext = () => {
    setNext((step) => {
      if (finalStep) {
        return step;
      }
      return step + 1;
    });
    aditionalFunctionInNext && aditionalFunctionInNext();
  };

  const handleBack = () => {
    setNext((step) => step - 1);
  };

  const handleSubmit = () => {
    handleNext();
  };

  return (
    <Grid container direction="column">
      <Grid item container xs={12} md={6} spacing={2}>
        <Grid item xs={12} mb={2}>
          <SunEditor
            autoFocus
            setContents={data}
            onChange={(e) => {
              setData(e);
            }}
            setOptions={{
              height: '350px',
              buttonList: [
                ['undo', 'redo'],
                ['font', 'fontSize', 'formatBlock'],
                ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                ['fontColor', 'hiliteColor', 'textStyle'],
                ['removeFormat'],
                ['outdent', 'indent'],
                ['align', 'horizontalRule', 'list', 'lineHeight'],
                ['table', 'link', 'image', 'video', 'audio'],
                ['fullScreen', 'showBlocks', 'codeView'],
                ['preview', 'print'],
                ['save', 'template'],
              ],
            }}
          />
        </Grid>

        {errorComponent && (
          <Grid item xs={12} mb={2}>
            {errorComponent}
          </Grid>
        )}
        <Grid item xs={12} container alignItems="center" justifyContent="center" spacing={2}>
          {isBack && (
            <Grid item>
              <Button
                fullWidth
                variant="outlined"
                size="large"
                color="primary"
                onClick={handleBack}
              >
                <ArrowBack />
              </Button>
            </Grid>
          )}
          <Grid item>
            <Button
              fullWidth
              variant="outlined"
              size="large"
              color="primary"
              onClick={handleSubmit}
              // disabled={isLoading}
              // endIcon={isLoading && <CircularProgress size={20} />}
            >
              <ArrowForward />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EditorTextSylabus;
