import { FormHelperText, Grid, InputLabel, Switch, TextField } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { useFormStyles } from '../../constants/styles';
// import { useAppSelector } from '../../../../../hooks/useRedux';

interface DisabilitiesForm {
  hasDisability: boolean;
  numDisabilityCertificate: string;
  disabilityPercentage: string;
}

const DisabilitiesDataEdit = () => {
  //   const { currentProfile } = useAppSelector((state) => state.access);

  const { values, getFieldProps, errors, touched, setTouched } =
    useFormikContext<DisabilitiesForm>();

  const classes = useFormStyles();

  //   const profileId = currentProfile!.profileId;

  useEffect(() => {
    setTouched({});
  }, [setTouched]);

  return (
    <Grid container spacing={4} alignItems="flex-start">
      <Grid item xs={12} md={6} container spacing={2}>
        <Grid item xs={12}>
          <InputLabel className={classes.inputLabel}>¿Posee discapacidad?</InputLabel>
          <Switch checked={values.hasDisability} {...getFieldProps('hasDisability')} />
        </Grid>

        {values.hasDisability && (
          <>
            <Grid item xs={12}>
              <InputLabel className={classes.inputLabel}>N° Certificado de discapacidad</InputLabel>
              <TextField
                fullWidth
                placeholder="Ej: N° CONADIS"
                {...getFieldProps('numDisabilityCertificate')}
                error={Boolean(errors.numDisabilityCertificate && touched.numDisabilityCertificate)}
              />
              {errors.numDisabilityCertificate && touched.numDisabilityCertificate && (
                <FormHelperText error>{errors.numDisabilityCertificate}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12}>
              <InputLabel className={classes.inputLabel}>Porcentaje de discapacidad</InputLabel>
              <TextField
                fullWidth
                type="number"
                placeholder="Porcentaje indicado en el cetificado"
                InputProps={{
                  endAdornment: '%',
                }}
                inputProps={{
                  min: 1,
                }}
                {...getFieldProps('disabilityPercentage')}
                error={Boolean(errors.disabilityPercentage && touched.disabilityPercentage)}
              />
              {errors.disabilityPercentage && touched.disabilityPercentage && (
                <FormHelperText error>{errors.disabilityPercentage}</FormHelperText>
              )}
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default DisabilitiesDataEdit;
