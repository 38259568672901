import logoERP from '../../../assets/images/logo-web-NBS.svg';
import logoFormalERP from '../../../assets/images/logo-web-NBS-white-formal.svg';

interface Props {
  formal?: boolean;

  width?: string;
  height?: string;

  sx?: {
    width?: string;
    height?: string;
    maxWidth?: string;
    maxHeight?: string;
    minWidth?: string;
    minHeight?: string;
    margin?: string;
    padding?: string;
  };
}

const LogoERP = ({ formal: formalValue, height, sx, width }: Props) => {
  const formal = formalValue !== undefined ? formalValue : false;

  // por si se quiere agregar versiones dark y light
  const logo = logoERP;
  const logoFormal = logoFormalERP;

  return (
    <img
      src={formal ? logoFormal : logo}
      onError={(e) => {}}
      alt="Links S.A"
      width={width || 'auto'}
      height={height || '55px'}
      // style={{ maxHeight: '50px' }}

      {...(sx && { style: { ...sx } })}
    />
  );
};

export default LogoERP;
