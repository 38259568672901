import * as yup from 'yup';
import { SUPPORT_TYPES } from '../../../shared/constants/extensionRequestType';

export const InternalHomologationSchema = yup.object().shape({
  education_level_current_id: yup
    .number()
    .required('La carrera es requerida.')
    .min(1, 'La carrera es requerida.'),
  curriculum_new_id: yup
    .number()
    .required('La carrera a homologar es requerida')
    .min(1, 'La carrera a homologar es requerida.'),
});

export const OtherAcademicRequestSchema = yup.object().shape({
  app_title: yup
    .string()
    .required('El título de la solicitud es requerido.')
    .min(1, 'El título de la solicitud es requerido.'),
  app_description: yup.string().required('El motivo de la solicitud es requerida.'),
});
export const ThirdRegistrationSchema = yup.object().shape({
  app_description: yup.string().required('El motivo de la solicitud es requerida.'),
  course_id: yup.number().required('El curso es requerido').min(1, 'El curso es requerido.'),
  subject_curriculum_id: yup
    .number()
    .required('La materia reprobada es requerida')
    .min(1, 'La materia reprobada es requerida.'),
  period_id: yup.number().required('El periodo es requerido').min(1, 'El periodo es requerido.'),
});

/*Validacion de Practices*/
export const EnterpriseSchema = yup.object().shape({
  enterpriseName: yup.string().required('El nombre de la empresa es requerido'),
  ruc: yup
    .string()
    .matches(/^\d+$/, 'Debe contener solo digitos')
    .length(13, 'Debe contener 13 caracteres exactamente')
    .required('El ruc de la empresa es requerido'),
  address: yup.string().required('La dirección de la empresa es requerido'),
  reason: yup.string().required('La razón social de la empresa es requerida'),
  activity: yup.string().required('Seleccione la actividad'),
});

export const BossSchema = yup.object().shape({
  bossName: yup.string().required('El nombre del empleador es requerido'),
  bossPosition: yup.string().required('La posición del empleador es requerido'),
  bossEmail: yup
    .string()
    .email('Ingrese un correo electrónico válido.')
    .required('El correo electrónico del empleador es requerido'),
  bossTlf: yup
    .string()
    .matches(/^\d+$/, 'Debe contener solo digitos')
    .required('El teléfono del empleador es requerido'),
});

export const PracticeSchema = yup.object().shape({
  practicePosition: yup.string().required('La posición del practicante es requerido'),
  practiceStartDate: yup
    .date()
    .required('La fecha de inicio de la práctica es requerida')
    .typeError('La fecha es inválida, click para seleccionar.'),
  practiceEndDate: yup
    .date()
    .required('La fecha de fin de la práctica es requerida')
    .typeError('La fecha es inválida, click para seleccionar.'),
});

export const fileSchema = yup.object().shape({
  files: yup
    .mixed()
    .required('El archivo con la firma de la solicitud de práctica es requerida')
    .test(
      'fileFormat',
      'Formato no soportado, ingresar archivos en formato PDF',
      (value) => value && SUPPORT_TYPES.includes(value.type)
    ),
});

/* retirementSubject*/
export const subjectWithDrawalSchema = yup.object().shape({
  tipo_retiro: yup.string().required('El tipo de retiro es requerido.'),
  course_student_id: yup
    .number()
    .required('La materia es requerida.')
    .min(1, 'La materia es requerida.'),
  app_description: yup.string().required('El motivo de la solicitud es requerida.'),
});

/* requalification */
export const requalificationSchema = yup.object().shape({
  supplie_id: yup.number().required('El parcial es requerido.').min(1, 'El parcial es requerido'),
  course_student_id: yup
    .number()
    .required('La materia es requerida.')
    .min(1, 'La materia es requerida.'),
  app_description: yup.string().required('El motivo de la solicitud es requerida.'),
});
/* vinculacion */
export const bondingSchema = yup.object().shape({
  project_name: yup
    .string()
    .required('El título del proyecto es requerido.')
    .min(1, 'El título del proyecto es requerido.'),
});
/* examen atrasado */
export const missedExamSchema = yup.object().shape({
  course_student_id: yup
    .number()
    .required('La materia es requerida.')
    .min(1, 'La materia es requerida.'),
  partial_id: yup.number().required('La parcial es requerido.').min(1, 'La parcial es requerido.'),
  motivo: yup.string().required('El motivo de la solicitud es requerida.'),
});

/* Practicas aprobadas */
export const approvePracticesSchema = yup.object().shape({
  // file_url: yup.string().required('El documento es requerida.'),
  app_description: yup.string().required('Descripción de la solicitud es requerida.'),
});

/* convenio de pago */
export const PaymentAgreementSchema = yup.object().shape({
  app_description: yup.string().required('El motivo de la solicitud es requerida.'),
  payment_date: yup
    .date()
    .required('La fecha de pago es requerida.')
    .typeError('La fecha es inválida, click para seleccionar.'),
  agreement_value: yup
    .number()
    .required('El valor del convenio es requerido.')
    .min(1, 'El valor del convenio debe ser mayor a cero.'),
});
/* Examen de recuperacion */
export const RecoveryExamSchema = yup.object().shape({
  course_student_id: yup
    .number()
    .required('La materia es requerida.')
    .min(1, 'La materia es requerida.'),
});
/* Titulacion */
export const TitulationSchema = yup.object().shape({
  period_id: yup.number().required('El período es requerido.').min(1, 'El período es requerido.'),
});
