import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../../../hooks/useRedux';

const useStyles = makeStyles((theme) => ({
  total: {
    // color: '#313b43',
    fontWeight: 700,
  },
}));

interface Props {
  cashChange: number;
}
const BillingDataReview = ({ cashChange }: Props) => {
  const classes = useStyles();

  const { t } = useTranslation();

  const {
    totalDebtAmount,
    totalPayAmount,
    totalDebtDiscount,
    tempBillingData,
    defaultTempBillingData,
    isPositiveBalanceTransac,
  } = useAppSelector((state) => state.debtsPayment);

  const defaultConfiguration = useAppSelector((state) => state.access.defaultConfiguration);

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom sx={{ mb: 2 }}>
          {t('financial.debts_payment.wizard.billing_data.title')}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Grid container justifyContent={'space-between'}>
                  <Typography variant="subtitle1" component="span">
                    {t('financial.debts_payment.wizard.billing_data.identification_label')} &nbsp;
                  </Typography>
                  <Typography component="span" variant="body2" className={classes.total}>
                    {tempBillingData
                      ? tempBillingData?.identificacion
                      : defaultTempBillingData?.identificacion}
                  </Typography>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container justifyContent={'space-between'}>
                  <Typography variant="subtitle1" component="span">
                    {t('financial.debts_payment.wizard.billing_data.name_label')} &nbsp;
                  </Typography>
                  <Typography component="span" variant="body2" className={classes.total}>
                    {tempBillingData
                      ? tempBillingData?.razonSocial
                      : defaultTempBillingData?.razonSocial}
                  </Typography>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container justifyContent={'space-between'}>
                  <Typography variant="subtitle1" component="span">
                    {t('financial.debts_payment.wizard.billing_data.address_label')} &nbsp;
                  </Typography>
                  <Typography component="span" variant="body2" className={classes.total}>
                    {tempBillingData
                      ? tempBillingData?.direccion
                      : defaultTempBillingData?.direccion}
                  </Typography>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container justifyContent={'space-between'}>
                  <Typography variant="subtitle1" component="span">
                    {t('financial.debts_payment.wizard.billing_data.phone_label')} &nbsp;
                  </Typography>
                  <Typography component="span" variant="body2" className={classes.total}>
                    {tempBillingData ? tempBillingData?.telefono : defaultTempBillingData?.telefono}
                  </Typography>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container justifyContent={'space-between'}>
                  <Typography variant="subtitle1" component="span">
                    {t('financial.debts_payment.wizard.billing_data.mail_label')} &nbsp;
                  </Typography>
                  <Typography component="span" variant="body2" className={classes.total}>
                    {tempBillingData ? tempBillingData?.email : defaultTempBillingData?.email}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} lg={6}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Grid container justifyContent={'space-between'}>
                  <Typography variant="subtitle1" component="span">
                    {t('financial.debts_payment.wizard.billing_data.total_label')} &nbsp;
                  </Typography>
                  <Typography component="span" variant="body2" className={classes.total}>
                    {defaultConfiguration?.currency_symbol} {totalDebtAmount.toFixed(2)}
                  </Typography>
                </Grid>
              </Grid>

              {isPositiveBalanceTransac ? (
                <Grid item xs={12}>
                  <Grid container justifyContent={'space-between'}>
                    <Typography variant="subtitle1" component="span">
                      {t('financial.debts_payment.wizard.billing_data.positive_balance_label')}
                      &nbsp;
                    </Typography>
                    <Typography component="span" variant="body2" className={classes.total}>
                      {defaultConfiguration?.currency_symbol} {totalPayAmount.toFixed(2) || '0.00'}
                    </Typography>
                  </Grid>
                </Grid>
              ) : (
                <>
                  <Grid item xs={12}>
                    <Grid container justifyContent={'space-between'}>
                      <Typography variant="subtitle1" component="span">
                        {t('financial.debts_payment.wizard.billing_data.total_discount_label')}
                        &nbsp;
                      </Typography>
                      <Typography component="span" variant="body2" className={classes.total}>
                        {defaultConfiguration?.currency_symbol} {totalDebtDiscount.toFixed(2)}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container justifyContent={'space-between'}>
                      <Typography variant="subtitle1" component="span">
                        {t('financial.debts_payment.wizard.billing_data.paid_payment_label')} &nbsp;
                      </Typography>
                      <Typography component="span" variant="body2" className={classes.total}>
                        {defaultConfiguration?.currency_symbol} {totalPayAmount.toFixed(2)}
                      </Typography>
                    </Grid>
                  </Grid>

                  {!!cashChange && (
                    <Grid item xs={12}>
                      <Grid container justifyContent={'space-between'}>
                        <Typography variant="subtitle1" component="span">
                          {t('financial.debts_payment.wizard.billing_data.change_label')} &nbsp;
                        </Typography>
                        <Typography component="span" variant="body2" className={classes.total}>
                          {defaultConfiguration?.currency_symbol}
                          {cashChange ? cashChange.toFixed(2) : '0.00'}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <Grid container justifyContent={'space-between'}>
                      <Typography variant="subtitle1" component="span">
                        {t('financial.debts_payment.wizard.billing_data.positive_balance_label')}{' '}
                        &nbsp;
                      </Typography>
                      <Typography component="span" variant="body2" className={classes.total}>
                        {defaultConfiguration?.currency_symbol}
                        {totalPayAmount > totalDebtAmount
                          ? (totalPayAmount - totalDebtAmount - cashChange).toFixed(2)
                          : '0.00'}
                      </Typography>
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default BillingDataReview;
