import {
  Autocomplete,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppSelector } from '../../../../hooks/useRedux';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import { statusAvailables } from '../../../shared/constants/resourceStatus';
import { useFormStyles } from '../constants/styles';
import { ParallelsInfoSchema } from '../constants/validationSchemas';
import { useAddParallelMutation } from '../slices/parallelsApiSlice';
import { useTranslation } from 'react-i18next';

const AddParallelForm = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useFormStyles();
  const { currentProfile } = useAppSelector((state) => state.access);

  const [showAlerts, setShowAlerts] = useState(true);

  const [addParallel, { isLoading, isError, error, isSuccess }] = useAddParallelMutation();

  const { getFieldProps, errors, touched, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      name: '',
      description: '',
      acronym: '',
      statusId: 1,
    },
    validationSchema: ParallelsInfoSchema,
    onSubmit: async (values) => {
      await addParallel({
        profileId: currentProfile?.profileId!,
        parallelPayload: {
          par_name: values.name,
          par_description: values.description,
          par_acronym: values.acronym,
          status_id: values.statusId.toString(),
        },
      });

      setShowAlerts(true);
    },
  });
  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction="column" gap={2}>
        <Grid item>
          {isError && showAlerts && (
            <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
          )}

          {isSuccess && showAlerts && (
            <SuccessAlert
              message={t('academic.parallels.add.success')}
              handleDismiss={() => setShowAlerts(false)}
            />
          )}
        </Grid>

        <Grid item container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>
              {t('academic.parallels.add.name')}
            </InputLabel>
            <TextField
              fullWidth
              error={Boolean(errors.name && touched.name)}
              placeholder={t('academic.parallels.add.name.placeholder')}
              {...getFieldProps('name')}
            />
            {errors.name && touched.name && <FormHelperText error>{t(errors.name)}</FormHelperText>}
          </Grid>
          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>
              {t('academic.parallels.add.description')}
            </InputLabel>
            <TextField
              multiline
              rows={2}
              fullWidth
              placeholder={t('academic.parallels.add.description.placeholder')}
              {...getFieldProps('description')}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>
              {t('academic.parallels.add.acronym')}
            </InputLabel>
            <TextField
              fullWidth
              error={Boolean(errors.acronym && touched.acronym)}
              placeholder={t('academic.parallels.add.acronym.placeholder')}
              {...getFieldProps('acronym')}
            />
            {errors.acronym && touched.acronym && (
              <FormHelperText error>{t(errors.acronym)}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>
              {t('academic.parallels.add.status')}
            </InputLabel>
            <Autocomplete
              options={statusAvailables}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField {...params} error={Boolean(errors.statusId && touched.statusId)} />
              )}
              defaultValue={statusAvailables[0]}
              onChange={(e, value) => setFieldValue('statusId', value?.id || '')}
              fullWidth
              clearIcon={null}
            />
            {errors.statusId && touched.statusId && (
              <FormHelperText error>{errors.statusId}</FormHelperText>
            )}
          </Grid>
        </Grid>

        <Grid
          item
          container
          alignItems="center"
          justifyContent="flex-end"
          spacing={2}
          sx={{ mt: 1 }}
        >
          <Grid item>
            <Button
              variant="outlined"
              size="large"
              color="primary"
              onClick={() => history.goBack()}
            >
              {t('academic.parallels.add.back')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              disabled={isLoading}
              endIcon={isLoading && <CircularProgress size={20} />}
            >
              {t('academic.parallels.add.submit')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default AddParallelForm;
