import { FormHelperText, Grid, InputLabel } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { ChangeEvent } from 'react';

import { useTranslation } from 'react-i18next';
import DragArea from '../../../../shared/components/files/DragArea';
interface FileDataForm {
  files: File;
}

const FileStep = () => {
  const { t } = useTranslation();

  const { setFieldValue, errors, touched, values } = useFormikContext<FileDataForm>();

  const onChangeFile = function (event: ChangeEvent<HTMLInputElement>) {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      setFieldValue('files', event.target.files[0]);
    }
  };
  const onRemoveFile = function () {
    setFieldValue('files', null);
  };

  return (
    <Grid container spacing={4} alignContent="flex-start">
      <Grid item xs={12} md={6} container spacing={2}>
        <Grid item xs={12} container spacing={2}>
          <Grid item xs={12}>
            <InputLabel>{t('students.applications.upload_file')} (pdf)</InputLabel>
            <Grid item xs={12} md={12} container>
              <DragArea
                handleChange={onChangeFile}
                label={t('students.applications.upload_file')}
                onRemoveFile={onRemoveFile}
                typesAccepted={'.pdf'}
                file={values.files}
              />
            </Grid>
            <Grid item xs={12}>
              {errors.files && touched.files && (
                <FormHelperText error>{errors.files}</FormHelperText>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default FileStep;
