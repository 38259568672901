export type TypePublication =
  | 'LIBROS'
  // | 'ARTICULOS'
  | 'PAPERS'
  // | 'PONENCIAS'
  | 'MEMORIAS'
  | 'CAPITULO LIBRO';

const typePublicationAvailables: { label: string; keyword: TypePublication }[] = [
  { label: 'Libros', keyword: 'LIBROS' },
  /*{ label: 'Artículos', keyword: 'ARTICULOS' },*/
  { label: 'Papers', keyword: 'PAPERS' },
  // { label: 'Ponencias', keyword: 'PONENCIAS' },
  { label: 'Memorias ', keyword: 'MEMORIAS' },
  { label: 'Capítulo libro', keyword: 'CAPITULO LIBRO' },
];

export default typePublicationAvailables;
