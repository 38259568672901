import { useMemo, useState } from 'react';

import { Autocomplete, Button, FormHelperText, Grid, TextField } from '@material-ui/core';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { IconX } from '@tabler/icons';

import CustomAsyncPaginatedSelect from '../../../../shared/components/extended/Form/CustomAsyncPaginatedSelect';
import InputLabel from '../../../../shared/components/forms/InputLabel';
import DeleteButton from '../../../../shared/components/tables/buttons/DeleteButton';
import SearchPaginatedTable from '../../../../shared/components/tables/SearchPaginatedTable';
import TextFieldFetching from '../../../../shared/components/TextFieldFetching';
import { removeItemFromIndexArr } from '../../../../shared/helpers';
import { useControlModalStates } from '../../../../shared/hooks/useControlModalStates';
import { CustomChilds } from '../../../../shared/interfaces/material-ui.interfaces';
import { SearchPersonParam } from '../../../../shared/interfaces/searchPerson.interface';
import { useGetCatalogByAcronymQuery } from '../../../../shared/slices/catalogApi';
import { useLazyGetPersonsByParamQuery } from '../../../../shared/slices/searchPersonApi';
import { PropsFormEdit } from './';
import { EmergencyContactPersonData } from '../../constants/validationSchemas';
import {
  useAddStudentEmergencyContactMutation,
  useGetEmergencyContactsByPersonQuery,
} from '../../slices/studentsApiSlice';
import {
  EmergencyContact,
  EmergencyContactNews,
} from '../../interfaces/studentRecordAcademic.interfaces';
import LoadingButton from '../../../../shared/components/buttons/LoadingButton';
import DeleteEmergencyContDialog from './DeleteEmergencyContDialog';
import ErrorAlert from '../../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../../shared/components/alerts/SuccessAlert';
import EditButton from '../../../../shared/components/tables/buttons/EditButton';
import EditEmergencyContactDialog from './EditEmergencyContactDialog';

const filters = [
  { name: 'ci', value: 'Cédula de identidad' },
  { name: 'firstname', value: 'Primer nombre' },
  { name: 'secondname', value: 'Segundo nombre' },
  { name: 'first_lastname', value: 'Primer apellido' },
  { name: 'second_lastname', value: 'Segundo apellido' },
];

const EmergencyContactsForm = ({ data }: PropsFormEdit) => {
  const { t } = useTranslation();

  const [filter, setFilter] = useState('ci');
  const [personSelect, setPersonSelect] = useState<CustomChilds<
    SearchPersonParam,
    { id: number; label: string }
  > | null>(null);

  const [newContacts, setNewContacts] = useState<EmergencyContactNews[]>([]);

  const [showAlerts, setShowAlerts] = useState(false);

  const { open, handleOpenModalWithMultipleIds, multipleIds, handleCloseModal } =
    useControlModalStates();

  const {
    open: openEditState,
    handleOpenModalWithMultipleIds: openEditHandler,
    multipleIds: editModalData,
    handleCloseModal: closeEditHandler,
  } = useControlModalStates<{
    newContact?: EmergencyContactNews;
    existingContact?: EmergencyContact;
  }>();

  const [getPersonsTrigger] = useLazyGetPersonsByParamQuery();

  const [saveContacts, { isLoading, error: errorUpdate, isSuccess, isError }] =
    useAddStudentEmergencyContactMutation();

  const {
    data: emrTable,
    isLoading: isLoadingEmrTable,
    isFetching,
    error: errorTable,
  } = useGetEmergencyContactsByPersonQuery({
    personId: data?.user.person_id,
    studentId: data?.user.id.toString(),
  });

  // parentesco
  const { data: kinship, isLoading: isLoadingParentesco } = useGetCatalogByAcronymQuery({
    acronym: 'P',
  });

  const getPersons = async (search: string, page: number, perPage: number) => {
    if (!filter || !search)
      return {
        options: [],
        hasMore: false,
      };

    const response = await getPersonsTrigger({
      page,
      perPage,
      [filter]: search,
      // statusId: 1,
    }).unwrap();

    const hasMore = response.current_page < response.last_page;

    return {
      options: response.data.map((dt) => {
        return {
          value: dt.id,
          label: `${dt.pers_full_name} - ${dt.pers_identification}`,
          ...dt,
        };
      }),
      hasMore,
    };
  };

  const { errors, touched, values, getFieldProps, handleSubmit, setFieldValue, resetForm } =
    useFormik<EmergencyContactNews>({
      initialValues: {
        id: 0,
        em_ct_name: '',
        em_ct_first_phone: '',
        em_ct_second_phone: '',
        person_id: '',
        status_id: '1',
        type_kinship_id: '',
        person_name_relative: '',
      },
      validationSchema: EmergencyContactPersonData,
      onSubmit: async (values) => {
        // const { faculty, ...value } = values;

        setNewContacts((prev) => [
          { ...values, id: `t${new Date().getTime()}${values.id}` as any },
          ...prev,
        ]);

        resetForm();
        setPersonSelect(null);
      },
    });

  const changePersonHandler = (
    newValue: CustomChilds<SearchPersonParam, { id: number; label: string }> | null
  ) => {
    setPersonSelect(newValue);

    setFieldValue('person_id', newValue?.id || '');
    setFieldValue('person_name_relative', newValue?.pers_full_name || '');
  };

  const saveContacsHandler = async () => {
    try {
      await saveContacts({
        studentId: data!.user.student.id.toString(),
        studentPersonId: data!.user.person_id, // deshuso
        payload: newContacts.map((item) => ({
          em_ct_name: item.em_ct_name,
          type_kinship_id: item.type_kinship_id,
          em_ct_first_phone: item.em_ct_first_phone,
          em_ct_second_phone: item.em_ct_second_phone,
          person_id: data!.user.person_id,
          status_id: item.status_id,
        })),
      }).unwrap();
      setNewContacts([]);
      setShowAlerts(true);
    } catch (error) {
      console.log(error);
    }
  };

  const datafetch = useMemo(() => {
    return [...newContacts, ...(emrTable || [])].map((item, i) => ({
      idx: i + 1,
      em_ct_name: item.em_ct_name,
      type_kinship_id:
        item.cat_name ||
        kinship?.find((dt) => Number(dt.id) === Number(item.type_kinship_id))?.cat_name ||
        '...',
      em_ct_first_phone: item.em_ct_first_phone,
      em_ct_second_phone: item.em_ct_second_phone,
      // status_id: item.status_id,
      options: item,
    }));

    // return toFetch;
  }, [newContacts, emrTable, kinship]);

  const removeRelativeHandler = (
    index: number,
    data: { emergencyContactId: string; studentId: string }
  ) => {
    if (data.emergencyContactId[0] === 't')
      return setNewContacts((prev) => removeItemFromIndexArr(prev, index));

    return handleOpenModalWithMultipleIds({
      emergencyContactId: data.emergencyContactId,
      studentId: data.studentId,
    });
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        {isError && showAlerts && (
          <ErrorAlert message={errorUpdate as string} handleDismiss={() => setShowAlerts(false)} />
        )}

        {isSuccess && showAlerts && (
          <SuccessAlert
            message={t('Contactos de emergencia actualizado exitosamente')}
            handleDismiss={() => setShowAlerts(false)}
          />
        )}

        <Grid container spacing={2}>
          {false && (
            <>
              <Grid item xs={12} md={6}>
                <InputLabel>{t('Tipo de búsqueda')}</InputLabel>
                <Autocomplete
                  options={filters}
                  getOptionLabel={(option) => option.value}
                  renderInput={(params) => (
                    <TextField {...params} placeholder={t('Seleccione el tipo de filtro')} />
                  )}
                  value={filters.find((item) => item.name === filter) || null}
                  onChange={(_, value) => {
                    setFilter((prev) => value?.name || '');
                    setPersonSelect(null);
                  }}
                  fullWidth
                  clearIcon={null}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <InputLabel>{t('Persona')}</InputLabel>
                <CustomAsyncPaginatedSelect
                  key={`async-select-${filter}`}
                  value={personSelect}
                  isError={false}
                  placeholder={`Buscar persona por ${
                    filters.find((dt) => dt.name === filter)?.value
                  }`}
                  // noOptionsMessage={''}
                  setValue={(newValue) =>
                    changePersonHandler(
                      newValue as CustomChilds<
                        SearchPersonParam,
                        { id: number; label: string }
                      > | null
                    )
                  }
                  fetchFunc={getPersons}
                />
                {/* {errors.person_id && touched.person_id && (
                  <FormHelperText error>{t(errors.person_id)}</FormHelperText>
                )} */}
              </Grid>
            </>
          )}

          <Grid item xs={12} md={6}>
            <InputLabel>{t('Parentesco')}</InputLabel>
            {isLoadingParentesco ? (
              <TextFieldFetching />
            ) : (
              <Autocomplete
                options={kinship || []}
                loadingText="Cargando parentescos..."
                getOptionLabel={(option) => (option ? option.cat_name! : '')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(errors.type_kinship_id && touched.type_kinship_id)}
                    placeholder="Cargando parentescos"
                  />
                )}
                value={kinship?.find((dt) => dt.id === Number(values.type_kinship_id)) || null}
                onChange={(_, value) => {
                  setFieldValue('type_kinship_id', value?.id || '');
                }}
                fullWidth
                clearIcon={null}
              />
            )}
            {errors.type_kinship_id && touched.type_kinship_id && (
              <FormHelperText error>{errors.type_kinship_id}</FormHelperText>
            )}
          </Grid>

          {false && (
            <Grid item xs={12} md={6}>
              <InputLabel>Nombres</InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Nombres del familiar"
                disabled
                value={personSelect?.pers_full_name || ''}
              />
            </Grid>
          )}

          <Grid item xs={12} md={6}>
            <InputLabel>{t('Nombre')}</InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Nombre del contacto"
              error={Boolean(errors.em_ct_name && touched.em_ct_name)}
              {...getFieldProps('em_ct_name')}
            />
            {errors.em_ct_name && touched.em_ct_name && (
              <FormHelperText error>{errors.em_ct_name}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            <InputLabel>{t('Teléfono principal')}</InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Agregue teléfono principal"
              error={Boolean(errors.em_ct_first_phone && touched.em_ct_first_phone)}
              {...getFieldProps('em_ct_first_phone')}
            />
            {errors.em_ct_first_phone && touched.em_ct_first_phone && (
              <FormHelperText error>{errors.em_ct_first_phone}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <InputLabel>{t('Teléfono secundario')}</InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Agregue teléfono secundario"
              error={Boolean(errors.em_ct_second_phone && touched.em_ct_second_phone)}
              {...getFieldProps('em_ct_second_phone')}
            />
            {errors.em_ct_second_phone && touched.em_ct_second_phone && (
              <FormHelperText error>{errors.em_ct_second_phone}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12}>
            <Grid container alignItems="center" justifyContent="flex-end" spacing={2} mt={1}>
              <Button type="submit" variant="outlined" size="large" color="primary">
                Agregar
              </Button>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <SearchPaginatedTable
              data={datafetch || []}
              error={errorTable}
              headers={{
                idx: '#',
                em_ct_name: 'Nombre',
                type_kinship_id: 'Parentesco',
                em_ct_first_phone: 'Teléfono principal',
                em_ct_second_phone: 'Teléfono secundario',
                options: 'Opciones',
              }}
              isFetching={isFetching}
              isLoading={isLoadingEmrTable}
              page={1}
              perPage={100}
              search={''}
              setPage={() => {}}
              setPerPage={() => {}}
              setSearch={() => {}}
              keyExtractor={(item) => `emergency-contact-file-student-table-${item.idx}`}
              searchPlacehoder={''}
              total={0}
              showFilter={false}
              paginationBox={false}
              perPageBox={false}
              customRenderers={{
                options: (row) => (
                  <>
                    <EditButton
                      handleEdit={() => {
                        if (typeof row.options.id === 'number')
                          return openEditHandler({
                            existingContact: row.options as EmergencyContact,
                          });

                        return openEditHandler({ newContact: row.options as EmergencyContactNews });
                      }}
                    />

                    <DeleteButton
                      handleDelete={() =>
                        removeRelativeHandler(row.idx - 1, {
                          emergencyContactId: row.options.id!.toString(),
                          studentId: data!.user.student.id.toString(),
                        })
                      }
                      CustomIcon={
                        typeof row.options.id === 'string' && row.options.id[0] === 't'
                          ? IconX
                          : null
                      }
                    />
                  </>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Grid container alignItems="center" justifyContent="flex-end" spacing={2} mt={1}>
              <LoadingButton
                onClick={saveContacsHandler}
                isLoading={isLoading}
                disabled={isLoading || !newContacts.length}
                variant="contained"
                size="large"
                color="primary"
              >
                Guardar
              </LoadingButton>
            </Grid>
          </Grid>

          {/* / */}
        </Grid>
      </form>

      <EditEmergencyContactDialog
        open={openEditState}
        data={editModalData}
        cases={
          (editModalData?.existingContact && 'existentingContact') ||
          (editModalData?.newContact && 'newContact')
        }
        studentId={data?.user.student.id.toString() || '0'}
        setNewContacts={setNewContacts}
        handleCloseDialog={closeEditHandler}
      />

      <DeleteEmergencyContDialog
        dataId={multipleIds?.emergencyContactId || '0'}
        studentId={multipleIds?.studentId || '0'}
        handleCloseModal={handleCloseModal}
        openModal={open}
      />
    </>
  );
};

export default EmergencyContactsForm;
