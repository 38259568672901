import { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/core/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Stack from '@material-ui/core/Stack';

import DialogLoading from '../DialogLoading';
import InputLabel from '../../../../shared/components/forms/InputLabel';
import CourseOptionCard from '../CourseOptionCard';

import { makeStyles } from '@material-ui/core/styles';

import useRegisterSubjectByCurriculumOptions from '../../hooks/useRegisterSubjectByCurriculumOptions';
import {
  useLazyGetAvailablesCoursesQuery,
  useGetSubjectPeriodParallelsQuery,
} from '../../slices/subjectsRegisterApiSlice';

import { Campus } from '../../../../maintenance/campus/interfaces/campus.interfaces';
import { Catalog } from '../../../../shared/interfaces/catalogs.interfaces';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import { resetCurrentSubject } from '../../slices/subjectsRegisterSlice';

import { ReactComponent as StudentExamsBg } from '../../../../../assets/images/illustrations/exams-students.svg';
import { ReactComponent as WarnBg } from '../../../../../assets/images/illustrations/warn.svg';
import { ReactComponent as SearchBg } from '../../../../../assets/images/illustrations/search.svg';
import useStudentInfo from '../../hooks/useStudentInfo';

import { useTranslation } from 'react-i18next';
import { Parallel } from '../../../../maintenance/courses/interfaces/course.interface';

interface Props {
  open: boolean;
  handleCloseDialog: () => void;
}

const useStyles = makeStyles({
  paper: {
    width: '90%',
    maxWidth: 980,
    height: '80%',
    minHeight: '80%',
    maxHeight: '80%',
  },
  formContainer: {
    width: '100%',
  },
});

interface FormValues {
  campus: Campus | null;
  jornada: Catalog | null;
  parallel: Parallel | null;
}

const initialValues: FormValues = {
  campus: null,
  jornada: null,
  parallel: null,
};

const RegisterByCurriculumDialog = ({ open, handleCloseDialog }: Props) => {
  const { t } = useTranslation();

  const classes = useStyles();
  const studentInfo = useStudentInfo();
  const dispatch = useAppDispatch();

  const { currentProfile } = useAppSelector((state) => state.access);

  const { currentSubject, isOnline, currentPeriodId } = useAppSelector(
    (state) => state.subjectsRegister
  );

  const { campus, jornadas, isLoadingCampus, isLoadingJornadas, isFetchingOptions } =
    useRegisterSubjectByCurriculumOptions();

  const [typeOffer, setTypeOffer] = useState<string | undefined>();

  useEffect(() => {
    if (isOnline) setTypeOffer('online');
  }, [isOnline]);

  const [getAvailableCourses, { data: availableCourses, isFetching, isError, isSuccess, error }] =
    useLazyGetAvailablesCoursesQuery();

  const { data: parallels, isLoading: isLoadingParallels } = useGetSubjectPeriodParallelsQuery({
    subjectId: currentSubject!.matter.id!,
    periodId: currentPeriodId!,
    profileId: currentProfile?.profileId!,
  });

  const onClose = () => {
    handleCloseDialog();
    dispatch(resetCurrentSubject());
  };

  const handleGetAvailableCourses = async (values: FormValues) => {
    try {
      await getAvailableCourses({
        subjectId: currentSubject!.matter.id,
        campusId: values.campus!.id,
        jornadaId: values.jornada?.id || null,
        parallelId: values.parallel?.id || null,
        type_offer: typeOffer,
        period_id: currentPeriodId,
        student_record_id: studentInfo?.studentRecordId!,
      });
    } catch (er) {
      console.log(er);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.paper }}>
      <DialogTitle>
        <Grid container direction="row">
          <Typography variant="h2" sx={{ mr: 1 }}>
            {t('students.subjects.registration.curriculum.dialog.register')}
          </Typography>
          <Typography color="primary" variant="h2">
            {currentSubject?.matter_rename}
          </Typography>
        </Grid>
      </DialogTitle>
      <DialogContent>
        {isFetchingOptions ? (
          <DialogLoading />
        ) : (
          <Grid container>
            <Grid item xs={12} container>
              <Formik
                initialValues={initialValues}
                onSubmit={(values) => {
                  handleGetAvailableCourses(values);
                }}
              >
                {({ values, setFieldValue }) => (
                  <Form className={classes.formContainer}>
                    {!isOnline && (
                      <Grid item xs={12} container spacing={2}>
                        {/* Campus obligatorio */}
                        <Grid item xs={4}>
                          <InputLabel htmlFor="campus">
                            {t(
                              'students.subjects.registration.curriculum.dialog.campus.placeholder'
                            )}
                          </InputLabel>
                          <Autocomplete
                            id="campus"
                            loading={isLoadingCampus}
                            options={campus}
                            loadingText={t(
                              'students.subjects.registration.curriculum.dialog.campus.loading'
                            )}
                            getOptionLabel={(option) => option.cam_name}
                            noOptionsText={t(
                              'students.subjects.registration.curriculum.dialog.campus.no_data'
                            )}
                            value={values.campus}
                            onChange={(_, value) => setFieldValue('campus', value)}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </Grid>
                        {/* Jornada (Opcional) */}
                        <Grid item xs={4}>
                          <InputLabel htmlFor="jornadas">
                            {t('students.subjects.registration.curriculum.dialog.time.placeholder')}
                          </InputLabel>
                          <Autocomplete
                            id="jornadas"
                            loading={isLoadingJornadas}
                            options={jornadas}
                            getOptionLabel={(option) => option.cat_name}
                            loadingText={t(
                              'students.subjects.registration.curriculum.dialog.time.loading'
                            )}
                            noOptionsText={t(
                              'students.subjects.registration.curriculum.dialog.time.no_data'
                            )}
                            value={values.jornada}
                            onChange={(_, value) => setFieldValue('jornada', value)}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <InputLabel htmlFor="paralelo">
                            {t(
                              'students.subjects.registration.curriculum.dialog.parallel.placeholder'
                            )}
                          </InputLabel>
                          <Autocomplete
                            id="paralelo"
                            loading={isLoadingParallels}
                            options={parallels || []}
                            getOptionLabel={(option) => option.par_name}
                            loadingText={t(
                              'students.subjects.registration.curriculum.dialog.parallel.loading'
                            )}
                            noOptionsText={t(
                              'students.subjects.registration.curriculum.dialog.parallel.no_data'
                            )}
                            value={values.parallel}
                            onChange={(_, value) => setFieldValue('parallel', value)}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </Grid>
                        <Grid item xs={12} container justifyContent="flex-end">
                          <Button
                            type="submit"
                            disabled={!values.campus}
                            variant="contained"
                            color="primary"
                          >
                            {t('students.subjects.registration.curriculum.dialog.search')}
                          </Button>
                        </Grid>
                      </Grid>
                    )}
                  </Form>
                )}
              </Formik>
            </Grid>
            <Grid item xs={12} container sx={{ mt: 2 }}>
              {!isError && !isSuccess && !isFetching && (
                <Grid item xs={12} container justifyContent="center">
                  <Stack alignItems="center">
                    <Typography variant="h4" sx={{ color: 'grey.700' }} textAlign="center">
                      {t('students.subjects.registration.curriculum.dialog.no_campus')}
                    </Typography>
                    <StudentExamsBg width={300} height={300} />
                  </Stack>
                </Grid>
              )}
              {isFetching ? (
                <Grid item xs={12} container justifyContent="center">
                  <Stack alignItems="center">
                    <Typography variant="h4" sx={{ color: 'grey.700' }}>
                      {t('students.subjects.registration.curriculum.dialog.searching')}
                    </Typography>
                    <SearchBg width={300} height={300} />
                  </Stack>
                </Grid>
              ) : isError ? (
                <Grid item xs={12} container justifyContent="center">
                  <Stack alignItems="center">
                    <Typography variant="h4" sx={{ color: 'grey.700' }} textAlign="center">
                      {error}
                    </Typography>
                    <WarnBg width={300} height={300} />
                  </Stack>
                </Grid>
              ) : (
                <Grid container spacing={2}>
                  {availableCourses?.map((ac) => (
                    <Grid item xs={6} key={ac.id}>
                      <CourseOptionCard course={ac} />
                    </Grid>
                  ))}
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default RegisterByCurriculumDialog;
