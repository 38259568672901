import { useState } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogProps,
  Grid,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { IconCircleCheck, IconFileAlert, IconFileInfo } from '@tabler/icons';

const useStyles = makeStyles((theme) => ({
  paper: { maxWidth: '600px', width: '90%' },
}));

interface Props extends DialogProps {
  title?: string;
  bodyText?: string;
  onClose: () => void;
  handleAction?: () => void;
  /** aditional button */
  handleAction2?: () => void;
  btnText?: string;
  handleSimpleAction?: () => void;
  /** True (default) si se quiere que el componente de desmonte al dar click fuera de el */
  isCloseClickOutComponent?: boolean;
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  error?: string;
  updateSuccess?: string;
  customTitleSendButton?: string;
  /** Additional button */
  customTitleSendButton2?: string;
  customTitleCancelButton?: string;
  children?: React.ReactElement;
  /**
   * Si se envia true, no se actualizara el nextStep interno
   */
  noNextStep?: boolean;
  //Use when you want to attach message as rate of service for example
  setObservation?: React.Dispatch<React.SetStateAction<string>>;
  handleRefuse?: () => void;

  isHideComfirmButton?: boolean;
}

const DialogAlert = ({
  title,
  bodyText,
  onClose,
  handleAction,
  handleAction2,
  /** Fn para accionar al dar en el boton de continuar en reemplazo del onClose
   * sin provocar cambios en el dialogo ej: una redireccion  */
  handleSimpleAction,
  btnText,
  isLoading,
  isSuccess,
  isCloseClickOutComponent = true,
  isError,
  error,
  updateSuccess = 'Actualización de estado realizado satisfactoriamente',
  customTitleSendButton = 'Sí, Actualizar',
  customTitleSendButton2 = '',
  customTitleCancelButton = 'No, Cancelar',
  children,
  noNextStep,
  setObservation,
  handleRefuse,
  isHideComfirmButton = false,
  ...props
}: Props) => {
  const classes = useStyles();

  const theme = useTheme();

  const [nextStep, setNextStep] = useState(false);

  const handleActionNextStep = async () => {
    if (handleAction) {
      await handleAction();
      if (!noNextStep) setNextStep(true);
    }
  };

  const handleActionNextStep2 = async () => {
    if (handleAction2) {
      await handleAction2();
      if (!noNextStep) setNextStep(true);
    }
  };

  const onCloseNextStep = async () => {
    await onClose();
    setNextStep(false);
  };

  return (
    <Dialog
      {...props}
      classes={{ paper: classes.paper }}
      sx={{ p: 3 }}
      {...(isCloseClickOutComponent && { onClose: onCloseNextStep })}
    >
      <Grid container gap={4} sx={{ p: 2 }}>
        {handleAction ? (
          <>
            <Grid item container xs={12} alignItems="center" justifyContent="center">
              {(!isError || !nextStep) && (!isSuccess || !nextStep) && !isLoading && (
                <IconFileInfo
                  size={60}
                  color={theme.palette.error.main}
                  style={{
                    padding: 12,
                    backgroundColor: theme.palette.orange.light,
                    borderRadius: '50%',
                  }}
                />
              )}
              {isLoading && <CircularProgress size={50} />}
              {nextStep && !isLoading && isSuccess && (
                <IconCircleCheck
                  size={60}
                  color={theme.palette.success.main}
                  style={{
                    padding: 12,
                    backgroundColor: theme.palette.success.light,
                    borderRadius: '50%',
                  }}
                />
              )}
              {isError && nextStep && (
                <IconFileAlert
                  size={60}
                  color={theme.palette.error.main}
                  style={{
                    padding: 12,
                    backgroundColor: theme.palette.orange.light,
                    borderRadius: '50%',
                  }}
                />
              )}
            </Grid>
            <Grid item xs={12} textAlign="center">
              <Typography variant="h2" sx={{ color: theme.palette.primary.main, mb: 1 }}>
                {(!nextStep || isLoading) && title}
                {nextStep && isSuccess && !isLoading && updateSuccess}
                {nextStep && isError && error}
              </Typography>
              <Typography variant="body2">
                {!isError && !isSuccess && bodyText}
                {/* {isError && error} */}
              </Typography>

              {!!children && !nextStep && (
                <Grid item xs={12} mt={2}>
                  {children}
                </Grid>
              )}

              {setObservation && !isError && !isSuccess && (
                <Grid item xs={12} mt={2}>
                  <TextField
                    multiline
                    rows={2}
                    fullWidth
                    placeholder="Ingrese el motivo"
                    onChange={(e) => setObservation(e.target.value)}
                  />
                </Grid>
              )}
            </Grid>
            <Grid item container xs={12} justifyContent="center" gap={1}>
              {/* {!isSuccess && !isError && ( */}
              {(!nextStep || isLoading) && (
                <Button
                  variant="outlined"
                  size="large"
                  style={{ color: 'red', borderColor: 'red' }}
                  onClick={
                    handleRefuse
                      ? () => {
                          handleRefuse();
                          setNextStep(true);
                        }
                      : onCloseNextStep
                  }
                  disabled={isLoading}
                >
                  {customTitleCancelButton}
                </Button>
              )}

              {nextStep && !isLoading && (isSuccess || isError) && (
                <Button variant="contained" size="large" color="primary" onClick={onCloseNextStep}>
                  {!!btnText ? btnText : 'Continuar'}
                </Button>
              )}

              {/* {!isSuccess && !isError && ( */}
              {(!nextStep || isLoading) && !isHideComfirmButton && (
                <Button
                  variant="contained"
                  size="large"
                  // onClick={handleAction}
                  onClick={handleActionNextStep}
                  color="primary"
                  autoFocus
                  disabled={isLoading}
                >
                  {customTitleSendButton}
                </Button>
              )}

              {/* {!isSuccess && !isError && ( */}
              {(!nextStep || isLoading) && handleAction2 && customTitleSendButton2 && (
                <Button
                  variant="contained"
                  size="large"
                  // onClick={handleAction}
                  onClick={handleActionNextStep2}
                  color="primary"
                  autoFocus
                  disabled={isLoading}
                >
                  {customTitleSendButton2}
                </Button>
              )}
            </Grid>
          </>
        ) : (
          <>
            <Grid item container xs={12} alignItems="center" justifyContent="center">
              <IconFileAlert
                size={60}
                color={theme.palette.error.main}
                style={{
                  padding: 12,
                  backgroundColor: theme.palette.orange.light,
                  borderRadius: '50%',
                }}
              />
            </Grid>
            <Grid item xs={12} textAlign="center">
              <Typography variant="h2" sx={{ color: theme.palette.primary.main, mb: 1 }}>
                {title ? title : <>No se te han asignado roles para este perfil</>}
              </Typography>
              <Typography variant="body2">
                {bodyText ? (
                  bodyText
                ) : (
                  <>Para obtener ayuda comunícate con los administradores de tu institución</>
                )}
              </Typography>
            </Grid>
            <Grid item container xs={12} justifyContent="center" gap={1}>
              <Button
                variant="contained"
                size="large"
                color="primary"
                onClick={handleSimpleAction || onCloseNextStep}
                autoFocus
              >
                {!!btnText ? btnText : 'Continuar'}
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </Dialog>
  );
};

export default DialogAlert;
