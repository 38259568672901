import { Grid, Typography } from '@material-ui/core';
import { useAppSelector } from '../../../../hooks/useRedux';
import MainCard from '../../../shared/components/cards/MainCard';
import ClassroomTable from '../components/ClassroomTable';
import DeleteClassroomDialog from '../components/DeleteClassroomDialog';
import { useTranslation } from 'react-i18next';
import { classroomExportPortalId } from '../constants';

const ClassroomsScreen = () => {
  const { t } = useTranslation();

  const { classroomIdToDelete } = useAppSelector((state) => state.classrooms);
  return (
    <div>
      <Grid component={MainCard} sx={{ padding: 2 }}>
        <Grid container justifyContent="space-between" sx={{ mb: 3 }}>
          <Typography variant="h2">{t('academic.classrooms.title')}</Typography>
          <div id={classroomExportPortalId} />
        </Grid>

        <ClassroomTable />

        {classroomIdToDelete && <DeleteClassroomDialog />}
      </Grid>
    </div>
  );
};

export default ClassroomsScreen;
