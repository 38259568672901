import { useMemo, useState, useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { IconNews } from '@tabler/icons';

import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core';

// import EditButton from '../../../shared/components/tables/buttons/EditButton';
// import DeleteButton from '../../../shared/components/tables/buttons/DeleteButton';
// import AddButton from '../../../shared/components/tables/buttons/AddButton';
import StatusChip from '../../../shared/components/badges/StatusChip';
import {
  renderErrorOrEmptyRow,
  renderSkeletonRows,
  renderSkeletonTable,
} from '../../../shared/helpers/render';
import { setUserProfilesList } from '../slices/userConfigurationsSlice';
// import useGuard from '../hooks/useGuard';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { useGetUserProfilesQuery } from '../slices/userConfigurationsApiSlice';
import { UserProfile } from '../interfaces/usersConfiguration.interfaces';
import AddProfileDialog from './AddProfileDialog';
import AddButton from '../../../shared/components/tables/buttons/AddButton';
import DeleteUserProfileDialog from './DeleteUserProfileDialog';
import { useModalUserConfig } from '../hooks/useModalUserConfig';
import DeleteButton from '../../../shared/components/tables/buttons/DeleteButton';
import EditButton from '../../../shared/components/tables/buttons/EditButton';
// import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const UserProfilesTable = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { userId } = useParams<{ userId: string }>();
  const [profileId, setProfileId] = useState(0);

  const [open, setOpen] = useState(false);

  const handleCloseDialog = () => {
    setOpen(false);
    setProfileId(0);
  };
  const handleOpenDialog = (profileId?: number) => {
    setOpen(true);
    if (profileId) setProfileId(profileId);
  };

  const {
    open: openDeleteUserProfileMod,
    handleCloseModal,
    handleOpenModal,
  } = useModalUserConfig();

  const history = useHistory();

  const { usersList } = useAppSelector((state) => state.usersConfiguration);

  // const { path } = useRouteMatch();
  // const match = matchPath(history.location.pathname, {
  //   // You can share this string as a constant if you want
  //   path: path,
  // });

  const currentUser = useMemo(() => {
    return usersList.find((dt) => dt.id === Number(userId));
  }, [userId, usersList]);

  const { isLoading, error, data, isFetching } = useGetUserProfilesQuery({
    // page,
    // perPage,
    // search,
    userId: currentUser?.id!,
  });

  // const { hasAccessUsers } = useGuard();

  const handleDelete = async (profileId: number) => {
    setProfileId(profileId);
    handleOpenModal();
  };

  //cargar en un estado la vista actual de la tabla
  useEffect(() => {
    if (data) {
      dispatch(setUserProfilesList(data.user_profiles));
    }
  }, [data, dispatch]);

  const renderRow = (usr: UserProfile) => (
    <TableRow className="table-row" key={usr.id}>
      <TableCell align="center" width="5%">
        {usr.id}
      </TableCell>
      <TableCell>{usr.profile.pro_name}</TableCell>
      <TableCell>{usr.email}</TableCell>

      <TableCell width="5%">
        <StatusChip statusId={Number(usr.status_id)} />
      </TableCell>

      <TableCell align="center">
        <>
          {/* {hasAccess('edit') && ( */}
          <Link to={`${history.location.pathname}/${usr.profile.id}/roles`}>
            <Tooltip title={t('configuration.users.profiles.view_roles')}>
              <IconButton aria-label={t('configuration.users.profiles.view_roles')} color="primary">
                <IconNews size={20} />
              </IconButton>
            </Tooltip>
          </Link>
          {/* )} */}
          <EditButton
            title={t('configuration.users.profiles.edit_profile')}
            handleEdit={() => handleOpenDialog(usr.profile.id)}
          />
          <DeleteButton handleDelete={() => handleDelete(usr.profile.id)} />
          {/* {!hasAccess('edit') && !hasAccess('delete') && 'Ninguna'} */}
        </>
      </TableCell>
    </TableRow>
  );

  const renderRows = () => {
    return <>{data?.user_profiles.map(renderRow)}</>;
  };

  // const datafetch = data?.user_profiles!.map((dt) => ({
  //   // id: dt.id,
  //   // name:
  //   //   d.user.person.pers_firstname +
  //   //   ' ' +
  //   //   d.user.person.pers_secondname +
  //   //   ' ' +
  //   //   d.user.person.pers_first_lastname +
  //   //   ' ' +
  //   //   d.user.person.pers_second_lastname,
  //   // mail: d.coll_email,
  //   // gender: d.user.person.pers_gender,
  //   name: dt.profile.pro_name,
  //   // birthday: d.user.person.pers_date_birth,
  //   statusId: dt.status_id,
  //   options: dt,
  // }));

  // const goToAdd = () => {
  //   history.push(`${path}/agregar`);
  // };

  return (
    <>
      <Grid container justifyContent="center">
        <Grid item xs={12} sx={{ textAlign: 'right' }}>
          <AddButton
            onClick={() => handleOpenDialog()}
            btnText={t('configuration.users.profiles.add')}
          />
        </Grid>
        <Grid item sm={12} overflow={'hidden'}>
          {isLoading && renderSkeletonTable(5, 5)}
          {!isLoading && (
            <>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">{t('configuration.users.profiles.code')}</TableCell>
                      <TableCell>{t('configuration.users.profiles.profile')}</TableCell>
                      <TableCell>{t('configuration.users.profiles.email')}</TableCell>
                      <TableCell>{t('configuration.users.profiles.status')}</TableCell>
                      <TableCell align="center">
                        {t('configuration.users.profiles.options')}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isFetching ? renderSkeletonRows(5, 5) : renderRows()}

                    {error && renderErrorOrEmptyRow(5, error as string)}
                    {data?.user_profiles.length === 0 && renderErrorOrEmptyRow(5)}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </Grid>
      </Grid>

      {open && (
        <AddProfileDialog
          open={open}
          profileId={profileId}
          userId={Number(userId)}
          handleCloseDialog={handleCloseDialog}
          setProfileId={setProfileId}
        />
      )}

      {openDeleteUserProfileMod && (
        <DeleteUserProfileDialog
          openModal={openDeleteUserProfileMod}
          userId={Number(userId)}
          profileId={Number(profileId)}
          handleCloseModal={handleCloseModal}
          setProfileId={setProfileId}
        />
      )}
    </>
  );

  // return isLoading ? (
  //   renderSkeletonTable(perPage, 7)
  // ) : (
  //   <SearchPaginatedTable
  //     data={datafetch!}
  //     headers={{
  //       // id: 'Código',
  //       // name: 'Nombre',
  //       // mail: 'Email',
  //       // gender: 'Género',
  //       name: 'Perfil',
  //       // address: 'Dirección',
  //       // birthday: 'Cumpleaños',
  //       statusId: 'Estado',
  //       options: 'Opciones',
  //     }}
  //     keyExtractor={(item) => item.options.id.toString()}
  //     searchPlacehoder="Buscar usuario"
  //     isLoading={isLoading}
  //     isFetching={isFetching}
  //     error={error}
  //     perPage={perPage}
  //     setPerPage={setPerPage}
  //     total={5 || 0}
  //     page={page}
  //     setPage={setPage}
  //     numHeader={4}
  //     search={search}
  //     setSearch={setSearch}
  //     customRenderers={{
  //       // id: (row) => row.id.id,

  //       statusId: (row) => <StatusChip statusId={Number(row.statusId)} />,
  //       // name: (row) => (
  //       //   <>
  //       //     {row.name}
  //       //     <Typography variant="subtitle2" color="grey">
  //       //       {row.options.email}
  //       //     </Typography>
  //       //     <Typography variant="subtitle2" color="grey">
  //       //       {row.options.gender}
  //       //     </Typography>
  //       //   </>
  //       // ),
  //       //

  //       options: (row) => (
  //         <>
  //           {/* {hasAccess('edit') && ( */}
  //           <Link to={`${path}/user/${row.options.id}/profiles`}>
  //             <Tooltip title="Ver Perfiles">
  //               <IconButton aria-label="Editar" color="secondary">
  //                 <IconSettings size={20} />
  //               </IconButton>
  //             </Tooltip>
  //             {/* <EditButton title="Editar información colaborador" /> */}
  //           </Link>
  //           {/* )} */}
  //           {/* {!hasAccess('edit') && !hasAccess('delete') && 'Ninguna'} */}
  //         </>
  //       ),
  //     }}
  //     // ActionButtons={<>{hasAccess('create') && <AddButton size="large" onClick={goToAdd} />}</>}
  //   />
  // );
};

export default UserProfilesTable;
