import { useTranslation } from 'react-i18next';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { useAppDispatch } from '../../../../hooks/useRedux';
import ApproveButton from '../../../shared/components/tables/buttons/ApproveButton';
import BinnacleButton from '../../../shared/components/tables/buttons/BinnacleButton';
import SearchPaginatedTableAndCollapsibleItems from '../../../shared/components/tables/SearchPaginatedTableAndCollapsibleItems';
import usePaginationAndSearch from '../hooks/usePaginationAndSearch';
import { useGetStudentsByProjectsQuery } from '../slices/followUpOfResearchProjectApiSlice';
import {
  setStudentSelectedByFollowUpOfResearchProject,
  setApproveBinnacleDialog,
} from '../slices/followUpOfResearchProjectSlice';
import HistoricalBinnancleStudent from './HistoricalBinnancleStudent';

const TableStudentsByProjects = () => {
  const { t } = useTranslation();
  const isCollapsible = true;
  const { projectId } = useParams<{ projectId: string }>();

  const { path } = useRouteMatch();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const { page, perPage, search, setPage, setPerPage, setSearch } = usePaginationAndSearch();

  const { data, isLoading, isFetching, error } = useGetStudentsByProjectsQuery({
    projectId: projectId,
    search,
    page,
    type_sort: 'asc',
    size: perPage,
  });

  const datafetch = data?.data.map((item, index) => {
    return {
      id: { index: index + 1 + (page - 1) * perPage, id: item.id, studentId: item.student_id },
      startDate: item.start_date,
      endDate: item.end_date,
      name: item.pers_full_name,
      options: item,
    };
  });

  const addBinnacle = (studentId: string | number, projectId: string | number) => {
    const url = path.replace(':projectId', projectId.toString());
    history.push(`${url}/${studentId}/bitacora`);
  };

  const expandedFuncAdditional = (row: any) => {
    dispatch(setStudentSelectedByFollowUpOfResearchProject(row.options));
  };

  return (
    <SearchPaginatedTableAndCollapsibleItems
      data={datafetch!}
      headers={{
        id: t('academic.subjects.code'),
        startDate: t('Fecha de inicio'),
        endDate: t('Fecha de finalización'),
        name: t('academic.subjects.name'),
        options: t('academic.subjects.options'),
      }}
      customDataCellsProperties={{
        id: { align: 'center' },
        startDate: { align: 'center' },
        endDate: { align: 'center' },
        name: { align: 'center' },
        options: { align: 'center' },
      }}
      customHeadersCellsProperties={{
        id: { align: 'center' },
        startDate: { align: 'center' },
        endDate: { align: 'center' },
        name: { align: 'center' },
        options: { align: 'center' },
      }}
      keyExtractor={(item) => item.id.studentId!}
      searchPlacehoder={t('Buscar Proyectos')}
      isLoading={isLoading}
      isFetching={isFetching}
      error={error}
      perPage={perPage}
      setPerPage={setPerPage}
      total={data?.total || 0}
      page={page}
      setPage={setPage}
      numHeader={9}
      search={search}
      setSearch={setSearch}
      isCollapsible={isCollapsible}
      customRenderers={{
        options: (row) => (
          <>
            <BinnacleButton
              handleClick={() => {
                dispatch(setStudentSelectedByFollowUpOfResearchProject(row.options));
                addBinnacle(row.id.id, projectId);
              }}
            />
            <ApproveButton
              handleClick={() => {
                dispatch(setStudentSelectedByFollowUpOfResearchProject(row.options));
                dispatch(setApproveBinnacleDialog(true));
              }}
            />
          </>
        ),
        id: ({ id: { index } }) => index,
      }}
      expandedFuncAdditional={expandedFuncAdditional}
      collapsibleItems={data?.data?.map((student) => (
        <HistoricalBinnancleStudent
          key={student.student_id}
          student={student}
          studentId={student.student_id}
          studentSearchProjectId={student.id}
          projectId={student.project_research_id}
        />
      ))}
    />
  );
};

export default TableStudentsByProjects;
