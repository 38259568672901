import { GenerateDebt } from '../interfaces/payload.interface';

export const periodStagesAvailables = {
  M: {},
  R: {},
};

export const filterClients: { label: string; value: GenerateDebt['type_client'] }[] = [
  { label: 'financial.generate_debts.client_dialog.filters.student', value: 'is_student' },
  { label: 'financial.generate_debts.client_dialog.filters.teacher', value: 'is_teacher' },
  {
    label: 'financial.generate_debts.client_dialog.filters.administrative',
    value: 'is_administrative',
  },
  { label: 'financial.generate_debts.client_dialog.filters.external', value: 'is_external' },
];
