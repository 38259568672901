import { Grid, Typography } from '@material-ui/core';
import MainCard from '../../../shared/components/cards/MainCard';
import OthersList from '../components/OthersList';

const OthersListScreen = () => {
  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">Recursos</Typography>
      </Grid>
      <OthersList />
    </Grid>
  );
};

export default OthersListScreen;
