import { Switch } from 'react-router-dom';
import GuardRoute from '../../../../router/guards/GuardRoute';

import ClassroomsEducationLevelsScreen from '../screens/ClassroomsEducationLevelsScreen';
import { subPermissionKeys } from '../constants/permission';

const ClassroomEdLvlRouter = () => {
  return (
    <Switch>
      <GuardRoute
        exact
        path="/administrar-distribucion-aulas"
        component={ClassroomsEducationLevelsScreen}
        permissionKey={subPermissionKeys.groupKey}
        subPermissionKey={subPermissionKeys.readClassroom}
      />
    </Switch>
  );
};

export default ClassroomEdLvlRouter;
