import { Divider, Grid, Typography } from '@material-ui/core';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../../hooks/useRedux';
import useFormatDate from '../../../hooks/useFormatDate';
import { Option } from '../../interfaces/evaluationToDo.interface';

const EvaluationInfoBox = () => {
  const { t } = useTranslation();

  const [oderOprionsByMaxValue, setOderOprionsByMaxValue] = useState<Option[]>([]);

  const { infoEvaluationToDo, evaluationQuestionsAnswered } = useAppSelector(
    (state) => state.userEvaluations
  );

  const { formatDate } = useFormatDate();

  useEffect(() => {
    if (infoEvaluationToDo) {
      const reverseOptions = [
        ...infoEvaluationToDo.eva_release.eva_evaluation.eva_evaluations_template.options,
      ]?.sort((a, b) => Number(b.value) - Number(a.value));

      setOderOprionsByMaxValue(reverseOptions);
    }
  }, [infoEvaluationToDo]);

  return (
    <Grid container spacing={2} mt={2} alignItems="center">
      <Grid item xs={12} md={4}>
        <Typography variant="h2" color="primary">
          {infoEvaluationToDo?.type_evaluation_name}
        </Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <Typography variant="h6">
          <strong>{t('user_evaluation.evaluation.info.evaluated')}:</strong>{' '}
          {infoEvaluationToDo?.user?.person?.pers_full_lastname}
        </Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <Typography variant="h3" align="right">
          {evaluationQuestionsAnswered.length}/
          {infoEvaluationToDo?.eva_release.eva_evaluation?.eva_evaluations_questions?.length || 1}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h6">
          <strong>{t('user_evaluation.evaluation.info.date')}:</strong>{' '}
          {`${formatDate(new Date())}`}
        </Typography>
        <Typography
          variant="h6"
          sx={{
            mt: 1,
          }}
        >
          <strong>{t('user_evaluation.evaluation.info.enddate')}:</strong>
          {''}
          {formatDate(infoEvaluationToDo?.end_date!)}
        </Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        <Typography variant="h6">
          <strong>{t('Periodo')}:</strong> {infoEvaluationToDo?.eva_release?.period?.per_name}
        </Typography>
        <Typography variant="h6">
          <strong>{t('Facultad')}:</strong> {infoEvaluationToDo?.course.education_level.edu_name}
        </Typography>
        <Typography variant="h6">
          <strong>{t('Curso')}:</strong> {infoEvaluationToDo?.course_name}
        </Typography>
        <Typography variant="h6">
          <strong>{t('Materia')}:</strong> {infoEvaluationToDo?.course?.matter?.mat_name!}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        sx={{
          mt: 1,
        }}
      >
        <Typography variant="h6">
          Criterios de evaluación de{' '}
          <strong>
            {`${infoEvaluationToDo?.eva_release.eva_evaluation.eva_evaluations_template.options[0].value}`}
          </strong>{' '}
          a{' '}
          <strong>
            {`${
              infoEvaluationToDo?.eva_release.eva_evaluation.eva_evaluations_template.options[
                infoEvaluationToDo?.eva_release.eva_evaluation.eva_evaluations_template.options
                  .length - 1
              ].value
            }`}
          </strong>
        </Typography>
        <Typography
          variant="h6"
          sx={{
            mt: 1,
          }}
        >
          Siendo{' '}
          {oderOprionsByMaxValue.map((option, idx) => (
            <>
              <strong key={option.uuid}>
                {option.description} = {option.value}
              </strong>
              {idx <
              infoEvaluationToDo?.eva_release.eva_evaluation.eva_evaluations_template.options
                .length! -
                1
                ? ' - '
                : ''}
            </>
          ))}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default EvaluationInfoBox;
