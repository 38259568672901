// material-ui
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  // useTheme,
  // Collapse,
  // Box,
} from '@material-ui/core';

import { renderErrorOrEmptyRow, renderSkeletonRows } from '../../../../../shared/helpers/render';

// helpers
import { isPrimitive, objectKeys } from '../../../../../shared/helpers/index';

// types
import {
  CustomCellProperties,
  CustomRenderers,
  TableHeaders,
} from '../../../../../shared/interfaces/material-ui.interfaces';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { SerializedError } from '@reduxjs/toolkit';

interface Props<T extends {}> {
  headers: TableHeaders<T>;
  data: T[];
  keyExtractor: (item: T) => string;
  customRenderers?: CustomRenderers<T>;
  customDataCellsProperties?: CustomCellProperties<T>;
  customHeadersCellsProperties?: CustomCellProperties<T>;
  newHeader?: JSX.Element;
  replaceHeader?: boolean;
  isLoading: boolean;
  numHeader: number;
  isFetching: boolean;
  error: FetchBaseQueryError | SerializedError | undefined;
}

const SimpleTable = <T extends {}>({
  data,
  headers,
  customRenderers,
  keyExtractor,
  customDataCellsProperties,
  customHeadersCellsProperties,
  replaceHeader,
  isLoading,
  isFetching,
  numHeader,
  error,
  newHeader,
}: Props<T>) => {
  // const theme = useTheme();

  const renderRows = () => {
    return <>{data!.map(renderRow)}</>;
  };

  const renderRow = (row: T) => {
    return (
      <>
        <TableRow hover className="table-row" key={keyExtractor(row)}>
          {objectKeys(row).map((cell) => {
            const customRenderer = customRenderers?.[cell];

            if (customRenderer) {
              return (
                <TableCell
                  key={`${keyExtractor(row)}-${String(cell)}`}
                  {...customDataCellsProperties?.[cell]}
                >
                  {customRenderer(row)}
                </TableCell>
              );
            }

            return (
              <TableCell
                key={`${keyExtractor(row)}-${String(cell)}`}
                {...customDataCellsProperties?.[cell]}
              >
                {isPrimitive(row[cell]) ? row[cell] : ''}
              </TableCell>
            );
          })}
        </TableRow>
      </>
    );
  };

  return (
    <TableContainer {...(replaceHeader && { sx: { mt: 2 } })}>
      <Table>
        <TableHead>
          <TableRow>
            {replaceHeader && <>{newHeader}</>}
            {!replaceHeader &&
              Object.keys(headers).map((key) => (
                <TableCell key={key} {...customHeadersCellsProperties?.[key as keyof T]}>
                  {headers[key as keyof T]}
                </TableCell>
              ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {isFetching ? renderSkeletonRows(7, 7) : renderRows()}

          {error && renderErrorOrEmptyRow(numHeader, error as string)}
          {data.length === 0 && !isFetching && renderErrorOrEmptyRow(numHeader)}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SimpleTable;
