import { useMemo, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { useGetUserProfileRolesQuery } from '../slices/userConfigurationsApiSlice';
// import EditButton from '../../../shared/components/tables/buttons/EditButton';
// import DeleteButton from '../../../shared/components/tables/buttons/DeleteButton';
// import AddButton from '../../../shared/components/tables/buttons/AddButton';

// import useGuard from '../hooks/useGuard';

import {
  renderErrorOrEmptyRow,
  renderSkeletonRows,
  renderSkeletonTable,
} from '../../../shared/helpers/render';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { UserProfileRole } from '../interfaces/usersConfiguration.interfaces';
import AddRolesDialog from './AddRolesDialog';
import AddButton from '../../../shared/components/tables/buttons/AddButton';
import { setUserProfileRolesList } from '../slices/userConfigurationsSlice';
// import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const UserProfileRolesTable = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const { userId, profileId } = useParams<{ userId: string; profileId: string }>();

  const [open, setOpen] = useState(false);

  const handleCloseDialog = () => {
    setOpen(false);
  };
  const handleOpenDialog = () => {
    setOpen(true);
  };

  const { usersList, userProfileList } = useAppSelector((state) => state.usersConfiguration);

  const currentUser = useMemo(() => {
    return usersList.find((dt) => dt.id === Number(userId));
  }, [userId, usersList]);

  const currentProfile = useMemo(() => {
    return userProfileList.find((dt) => dt.profile.id === Number(profileId));
  }, [profileId, userProfileList]);

  const { isLoading, error, data, isFetching } = useGetUserProfileRolesQuery({
    userId: currentUser?.id!,
    profileId: Number(currentProfile?.profile_id),
  });

  // const { hasAccessUsers } = useGuard();

  //cargar en un estado la vista actual de la tabla\
  useEffect(() => {
    if (data) {
      dispatch(setUserProfileRolesList(data.roles));
    }

    return () => {
      dispatch(setUserProfileRolesList([]));
    };
  }, [data, dispatch]);

  const renderRow = (rol: UserProfileRole) => (
    <TableRow className="table-row" key={rol.id}>
      <TableCell align="center" width="5%">
        {rol.id}
      </TableCell>
      <TableCell align="center">{rol.name}</TableCell>
    </TableRow>
  );

  const renderRows = () => {
    return <>{data?.roles.map(renderRow)}</>;
  };

  return (
    <>
      <Grid container justifyContent="center">
        <Grid item xs={12} sx={{ textAlign: 'right' }}>
          {/* ###edit icon */}
          <AddButton onClick={handleOpenDialog} btnText={t('configuration.users.roles.edit')} />
        </Grid>
        <Grid item sm={12} overflow={'hidden'}>
          {isLoading && renderSkeletonTable(5, 4)}
          {!isLoading && (
            <>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">{t('configuration.users.roles.code')}</TableCell>
                      <TableCell align="center">{t('configuration.users.roles.role')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isFetching ? renderSkeletonRows(5, 4) : !error && renderRows()}

                    {error && renderErrorOrEmptyRow(4, error as string)}
                    {data?.roles.length === 0 && renderErrorOrEmptyRow(4)}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </Grid>
      </Grid>

      <AddRolesDialog
        open={open}
        handleCloseDialog={handleCloseDialog}
        userId={Number(userId)}
        profileId={Number(profileId)}
      />
    </>
  );
};

export default UserProfileRolesTable;
