import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISearchTable } from '../../../../../shared/interfaces/paginatedTable.interface';
import { IEvaluation } from '../../../maintenance/evaluations/interfaces/evaluations.interface';
import { HeteroevaluationsReportsState } from '../interfaces/heteroevaluationsReports.interface';

const initialState: HeteroevaluationsReportsState = {
  heteroevaluationsReportsList: [],
  heteroevaluationsReportsIdSelected: null,
  heteroevaluationsReportsTable: {
    page: 1,
    perPage: 5,
    search: '',
  },

  groupIdSelected: null,
  evaluatedIdSelected: null,
  stageIdSelected: null,

  isEditFilters: true,
};

export const heteroevaluationReportsSlice = createSlice({
  name: 'userEvaluation',
  initialState,
  reducers: {
    setHeteroevaluationsReportsList: (state, action: PayloadAction<IEvaluation[]>) => {
      state.heteroevaluationsReportsList = action.payload;
    },
    setHeteroevaluationsReportsIdSelected: (state, action: PayloadAction<number>) => {
      state.heteroevaluationsReportsIdSelected = action.payload;
    },
    updateHeteroevaluationsReportsTable: (state, action: PayloadAction<Partial<ISearchTable>>) => {
      state.heteroevaluationsReportsTable = {
        ...state.heteroevaluationsReportsTable,
        ...action.payload,
      };
    },
    setGroupIdSelected: (state, action: PayloadAction<string>) => {
      state.groupIdSelected = action.payload;
    },
    setEvaluatedIdSelected: (state, action: PayloadAction<string>) => {
      state.evaluatedIdSelected = action.payload;
    },
    setStageIdSelected: (state, action: PayloadAction<string>) => {
      state.stageIdSelected = action.payload;
    },
    resetFilters: (state) => {
      state.groupIdSelected = null;
      state.evaluatedIdSelected = null;
      state.stageIdSelected = null;
    },
    setIsEditFilters: (state, action: PayloadAction<boolean>) => {
      state.isEditFilters = action.payload;
    },
  },
});

export const {
  setHeteroevaluationsReportsList,
  setHeteroevaluationsReportsIdSelected,
  updateHeteroevaluationsReportsTable,
  setIsEditFilters,
  setGroupIdSelected,
  setEvaluatedIdSelected,
  setStageIdSelected,
  resetFilters,
} = heteroevaluationReportsSlice.actions;
