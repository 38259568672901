import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISearchTable } from '../../../shared/interfaces/paginatedTable.interface';
import { MattersTypes } from '../interfaces/matterTypes.interface';

interface MattersTypesState {
  mattersTypesList: MattersTypes[];
  deleteModalOpen: boolean;
  matterTypeIdToDelete?: number;
  matterTypeTable: ISearchTable;
}

const initialState: MattersTypesState = {
  mattersTypesList: [],
  deleteModalOpen: false,
  matterTypeIdToDelete: undefined,
  matterTypeTable: {
    page: 1,
    perPage: 5,
    search: '',
  },
};

const mattersTypesSlice = createSlice({
  name: 'mattersTypes',
  initialState,
  reducers: {
    setMattersTypesList: (state, action: PayloadAction<MattersTypes[]>) => {
      state.mattersTypesList = action.payload;
    },
    setDeleteModalOpen: (state, action: PayloadAction<boolean>) => {
      state.deleteModalOpen = action.payload;
    },
    openDeleteModalWith: (state, action: PayloadAction<number>) => {
      state.matterTypeIdToDelete = action.payload;
      state.deleteModalOpen = true;
    },
    resetPeriodIdToDelete: (state) => {
      state.matterTypeIdToDelete = undefined;
    },
    updateMatterTypeTable: (state, action: PayloadAction<ISearchTable>) => {
      state.matterTypeTable = action.payload;
    },
  },
});

export const {
  setMattersTypesList,
  setDeleteModalOpen,
  openDeleteModalWith,
  resetPeriodIdToDelete,
  updateMatterTypeTable,
} = mattersTypesSlice.actions;

export default mattersTypesSlice.reducer;
