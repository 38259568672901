import { FC, useEffect, useState } from 'react';
import { Box, CircularProgress, LinearProgress, Typography } from '@material-ui/core';

import { useIncrementalValue } from '../../../shared/hooks/useIncrementalValue';
import { useLazyGetEventChunkQuery } from '../../../shared/slices/chunkStatesApi';
import { ChunkStatesCategories } from '../../../shared/interfaces/chunkStates.interface';

interface Props {
  chunkCode: string | null;
  categoryChunks: ChunkStatesCategories;
  isSuccess: boolean;
  customDuration?: number;
  children?:
    | ((props: { displayValue: number; currentChunks: number; totalChunk: number }) => JSX.Element)
    | null;
}

const defaultDuration = 5000;

/**
 * Componente para mostrar el progreso de un chunk
 * @param chunkCode Codigo del chunk
 * @param isSuccess Indica si la peticion fue exitosa
 * @param categoryChunks Categoria del chunk
 * @param customDuration Duracion de la animacion
 * @param children Componente personalizado
 *
 * ### Example without children
 * ```
 * <EventsChunksDisplay
 * chunkCode={chunkCode}
 * isSuccess={isSuccess}
 * categoryChunks={ChunkStatesCategories.EVENTS}
 * customDuration={5000}
 * />
 * ```
 *
 * ### Example with children
 * ```
 * <EventsChunksDisplay
 *  chunkCode={chunkCode}
 * isSuccess={isSuccess}
 * categoryChunks={ChunkStatesCategories.EVENTS}
 * customDuration={5000}
 * >
 * {({ displayValue, currentChunks, totalChunk }) => (
 *  <>
 *   <Typography component="p" align="center">
 *    {displayValue} de {totalChunk}
 *  </Typography>
 * <LinearProgress variant="determinate" value={normalize(displayValue)} />
 * </>
 * )}
 * </EventsChunksDisplay>
 * ```
 */
const EventsChunksDisplay: FC<Props> = ({
  chunkCode,
  isSuccess,
  categoryChunks,
  customDuration = defaultDuration,
  children,
}) => {
  const [shouldRestart, setShouldRestart] = useState<string>('');

  const [currentChunks, setCurrentChunks] = useState<number>(0);
  const [totalChunk, setTotalChunk] = useState<number>(0);

  const [firstChunk, setFirstChunk] = useState<number>(0);

  const [lastRequestTime, setLastRequestTime] = useState(Date.now());

  const duration = !firstChunk ? customDuration / 2 : customDuration;

  const { value: displayValue, setFinalValue, setDuration } = useIncrementalValue(0, duration);

  const [getChunk] = useLazyGetEventChunkQuery();

  useEffect(() => {
    setLastRequestTime(Date.now());
  }, []);

  useEffect(() => {
    if (isSuccess) {
      setCurrentChunks(totalChunk);
      setDuration(500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, setDuration, totalChunk]);

  useEffect(() => {
    if (!firstChunk) setFirstChunk(currentChunks);
    else setFinalValue(currentChunks);
  }, [currentChunks, firstChunk, setFinalValue]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (chunkCode) {
      timeoutId = setTimeout(async () => {
        try {
          // /events/${code}/progress
          await getChunk({
            code: chunkCode,
            category: categoryChunks,
          })
            .unwrap()
            .then((response) => {
              const timeSinceLastRequest = Date.now() - lastRequestTime;
              const timeToWait = timeSinceLastRequest;
              setDuration(Math.max(Math.abs(timeToWait), duration));
              setLastRequestTime(Date.now());

              if (!response.total_notified) return setShouldRestart(Math.random().toString(16));

              setCurrentChunks(Number(response.total_notified));
              setTotalChunk(Number(response.total_receivers));

              if (
                Number(response.total_notified) >=
                Number(response.total_receivers) - firstChunk
              ) {
                setDuration(500);
                setCurrentChunks(Number(response.total_receivers));
              }
            });
        } catch (error) {
          console.log(error);
        }
      }, duration);
    }

    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chunkCode, shouldRestart, lastRequestTime]);

  const normalize = (value: number) => ((value - 0) * 100) / ((totalChunk || 100) - 0);

  const DisplayComponent = children ? (
    <>{children({ displayValue, currentChunks, totalChunk })}</>
  ) : (
    <>
      {!totalChunk || isSuccess || displayValue >= totalChunk ? (
        <CircularProgress size={50} />
      ) : (
        <Box sx={{ width: '100%' }}>
          <Typography component="p" align="center">
            {displayValue}/{totalChunk || 0}
          </Typography>
          <LinearProgress variant="determinate" value={normalize(displayValue)} />
        </Box>
      )}
    </>
  );

  return <>{DisplayComponent}</>;
};

export default EventsChunksDisplay;
