import { Box } from '@material-ui/core';
import { AdvertisementsData } from '../interfaces/as.interfaces';
import AdvertisementsItem from './AdvertisementsItem';

interface Props {
  advertisements: AdvertisementsData[];
}

const AdvertisementsList = ({ advertisements }: Props) => {
  return (
    <Box>
      {advertisements.map((advertisement) => (
        <AdvertisementsItem key={`${advertisement.id}`} advertisement={advertisement} />
      ))}
    </Box>
  );
};

export default AdvertisementsList;
