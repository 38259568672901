import { Paper, Grid, Typography, Button, Alert } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useAppSelector } from '../../../../hooks/useRedux';
import { renderSkeletonTable } from '../../../shared/helpers/render';
import { useGetDetailsStatusQuery } from '../slices/requestsStatusApiSlice';
import { Edit } from '@material-ui/icons';
import StatusRolItem from './StatusRolItem';
import useSharedGuard from '../../../../hooks/useSharedGuard';
import { subPermissionKeys } from '../constants/permissions';

const DetailsStatus = () => {
  const { id } = useParams<{ id: string }>();
  const { currentProfile } = useAppSelector((state) => state.access);

  const history = useHistory();

  const { hasAccess } = useSharedGuard(subPermissionKeys);

  const { data, isSuccess, isLoading } = useGetDetailsStatusQuery(
    {
      user_profile_id: currentProfile?.profileId!,
      id_application: Number(id!),
      data_: 'all',
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const [keys, setKeys] = useState<string[]>([]);

  useEffect(() => {
    if (isSuccess && data && data.length > 0) {
      let uniq = (temp: string[]) => [...new Set(data.map((el) => el.status_name))];
      setKeys(uniq);
    }
  }, [isSuccess, data]);

  return (
    <>
      {isSuccess && data && data.length > 0 ? (
        <Grid container mt={4} mb={2}>
          <Grid item xs={6} md={6}>
            <Typography variant="h2" color="primary">
              {data[0].type_application_name}
            </Typography>
          </Grid>
          {hasAccess('edit') && (
            <Grid item xs={6} md={6} textAlign="right">
              <Button
                variant="contained"
                startIcon={<Edit />}
                onClick={() => {
                  history.push(`/administrar-estados-solicitudes/editar/${id}`);
                }}
              >
                Editar Estados
              </Button>
            </Grid>
          )}
        </Grid>
      ) : null}
      <Grid container spacing={2} sx={{ height: '100%' }}>
        {isLoading ? (
          <>
            <Grid item xs={12} md={6} lg={3}>
              <Paper elevation={2} sx={{ p: 1 }}>
                {renderSkeletonTable(1, 1)}
              </Paper>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Paper elevation={2} sx={{ p: 1 }}>
                {renderSkeletonTable(1, 1)}
              </Paper>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Paper elevation={2} sx={{ p: 1 }}>
                {renderSkeletonTable(1, 1)}
              </Paper>
            </Grid>
          </>
        ) : null}
        {keys && keys.length > 0 && data
          ? keys.map((key, index) => (
              <Grid item xs={12} md={6} lg={3} key={key} height="100%">
                <StatusRolItem index={index} keyStatusName={key} roles={data} idStatus={id!} />
              </Grid>
            ))
          : null}
        {!isLoading && keys.length === 0 && (
          <Alert severity="error">No existen estados disponibles</Alert>
        )}
      </Grid>
    </>
  );
};

export default DetailsStatus;
