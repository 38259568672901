export const certificateLanguages = [
  {
    id: 'es',
    label: 'Español',
  },
  {
    id: 'en',
    label: 'Ingles',
  },
];
