import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../../../hooks/useRedux';
// import { useAppSelector } from '../../../../../../hooks/useRedux';
import MainCard from '../../../../../shared/components/cards/MainCard';
import AddGroupsCoursesSideDialog from '../components/courses/AddGroupsCoursesSideDialog';
import GroupDeleteDialog from '../components/list-groups/GroupDeleteDialog';
import ListGroupsTable from '../components/list-groups/ListGroupsTable';

const ListGroupsScreen = () => {
  const { t } = useTranslation();

  const { openDeleteDialog, openAddGroupCourse } = useAppSelector((state) => state.listGroups);

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid container alignItems="center" sx={{ mb: 3 }}>
        <Typography variant="h2">{t('maintenance.groups.list')}</Typography>
      </Grid>
      <ListGroupsTable />
      {openDeleteDialog && <GroupDeleteDialog />}
      {openAddGroupCourse && <AddGroupsCoursesSideDialog />}
    </Grid>
  );
};

export default ListGroupsScreen;
