import { useAppDispatch, useAppSelector } from '../../../../../../hooks/useRedux';
import DeleteAlert from '../../../../../shared/components/dialogs/DeleteAlert';
import { useDeleteRubricByIdMutation } from '../slices/integralEvaluationApiSlice';
import { setDeleteIdSelected, setIsOpenDeleteDialog } from '../slices/integralEvaluationSlice';

const RubricDeleteDialog = () => {
  const dispatch = useAppDispatch();
  const { deleteIdSelected, isOpenDeleteDialog } = useAppSelector(
    (state) => state.integralEvaluation
  );

  const [deleteRubric, { isLoading, isSuccess, error, isError }] = useDeleteRubricByIdMutation();

  const handleDelete = () => {
    deleteRubric({
      id: deleteIdSelected!,
    });
  };
  const handleClose = () => {
    dispatch(setDeleteIdSelected(null));
    dispatch(setIsOpenDeleteDialog(false));
  };

  return (
    <DeleteAlert
      open={isOpenDeleteDialog}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      error={error as any}
      handleDelete={handleDelete}
      onClose={handleClose}
    />
  );
};

export default RubricDeleteDialog;
