import {
  FormHelperText,
  Grid,
  InputLabel,
  Autocomplete,
  TextField,
  Button,
  CircularProgress,
  // Typography,
  // makeStyles,
  // Table,
  // TableBody,
  // TableContainer,
  // TableRow,
  // TableCell,
  Box,
  Typography,
  // TableHead,
} from '@material-ui/core';
import { IconChevronRight } from '@tabler/icons';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../../../hooks/useRedux';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import { internalHomologationValues } from '../constants/formConstants';
import { InternalHomologationSchema } from '../constants/validationSchemas';
import { InternalHomologationsPayload } from '../interfaces/changeCareer.interfaces';
import { StatusMatter } from '../interfaces/subject.interfaces';
// import { InternalHomologation } from '../interfaces/internalHomologations.interfaces';
import {
  useAddInternalHomologationMutation,
  useGetStudentRecordsQuery,
  useGetSubjectByStudentRecordQuery,
  useGetSubjectByEducationLevelQuery,
} from '../slices/academicRequestsApiSlice';
// import PerfectScrollbar from 'react-perfect-scrollbar';
import SubCard from '../../../shared/components/cards/SubCard';
// import { renderErrorOrEmptyRow, renderSkeletonRows } from '../../../shared/helpers/render';
import { useGetEducationLevelsParentsVMQuery } from '../../../maintenance/education-levels/slices/educationLevelsApiSlice';
import useGuard from '../hooks/useGuard';
import IconTelework from '../../../shared/components/illustrations/IconTelework';
import TextFieldFetching from '../../../shared/components/TextFieldFetching';

import { useTranslation } from 'react-i18next';
import PeriodBadge from '../../../maintenance/meshs/components/builder/PeriodBadge';
import SearchPaginatedTable from '../../../shared/components/tables/SearchPaginatedTable';
import WarningAlert from '../../../shared/components/alerts/WarningAlert';

// const useStyles = makeStyles((theme) => ({
//   subject: {
//     color: theme.palette.success.dark,
//     fontSize: '14px',
//     fontWeight: 600,
//   },
// }));

const ChangeCareerForm = () => {
  const { t } = useTranslation();

  const { hasAccess } = useGuard();
  // const classes = useStyles();
  const { currentProfile, currentStudent, currentRol } = useAppSelector((state) => state.access);
  const { userInfo } = useAppSelector((state) => state.auth);

  const [showAlerts, setShowAlerts] = useState(true);

  const [currentSubjectsPage, setCurrentSubjectsPage] = useState(1);
  const [currentSubjectsSearch, setCurrentSubjectsSearch] = useState('');
  const [newSubjectsPage, setNewSubjectsPage] = useState(1);
  const [newSubjectsSearch, setNewSubjectsSearch] = useState('');

  // const [canRegister, setCanRegister] = useState(false);

  const {
    data: educationLevelsAvaliables = [],
    isLoading: isLoadingELA,
    isError: isErrorELA,
    error: errorELA,
  } = useGetEducationLevelsParentsVMQuery(
    { profileId: currentProfile?.profileId!, offerId: userInfo?.studentInfo?.offerId! },
    { refetchOnMountOrArgChange: 30 }
  );

  const { data: StudentRecordsResponse } = useGetStudentRecordsQuery(
    { profileId: currentProfile?.profileId!, stud_code: currentStudent?.stud_code! },
    { refetchOnMountOrArgChange: 30 }
  );
  //informacion sobre los records estudiantiles por estudiante y carrera para listar
  //materias  y sus estados

  const [addInternalHomologation, { isLoading: isLoadingIH, isError, error, isSuccess }] =
    useAddInternalHomologationMutation();

  const { data: studentRecordsResponse, isLoading: isLoadingStudentRecords } =
    useGetStudentRecordsQuery(
      { profileId: currentProfile?.profileId!, stud_code: currentStudent?.stud_code! },
      { refetchOnMountOrArgChange: 30 }
    );
  //getFieldProps,,  values
  const { handleSubmit, errors, touched, setFieldValue, values } =
    useFormik<InternalHomologationsPayload>({
      initialValues: internalHomologationValues,
      validationSchema: InternalHomologationSchema,
      onSubmit: async (values) => {
        await addInternalHomologation({
          profileId: currentProfile?.profileId!,
          studCode: currentStudent?.stud_code!,
          userId: String(userInfo?.id!),
          roleId: currentRol?.rolId!,
          internalHomologationPayload: {
            education_level_current_id: values.education_level_current_id,
            curriculum_new_id: values.curriculum_new_id,
          },
        });

        setShowAlerts(true);
      },
    });
  const filterHeaders = educationLevelsAvaliables.filter((edu) => edu.principal_id === null);

  const optionsEducationLvls = filterHeaders.map((op) => op.children).flat(1);

  //parent group carreras agrupadas por facultad
  const groupedOptions = optionsEducationLvls.map((op) => {
    const parent = educationLevelsAvaliables.filter(
      (parent) => Number(op.principal_id) === Number(parent.id)
    );
    const meshChild = op.meshs || [];
    const meshs = meshChild.filter(
      (meshObject) => Number(op.id) === Number(meshObject.level_edu_id)
    );
    const parentMap = parent.map((parent) => parent.edu_name);
    const meshYearMap = meshs.map((mesh) => mesh.anio);
    const meshIdMap = meshs.map((mesh) => mesh.id);
    const meshNameMap = meshs.map((mesh) => mesh.mes_name);

    const parentName = parentMap ? parentMap[0] : '';
    const meshyear = meshYearMap ? meshYearMap[0] : '';
    const meshId = meshIdMap ? meshIdMap[0] : '';
    const meshName = meshNameMap ? meshNameMap[0] : '';
    return {
      parentName,
      ...op,
      meshyear,
      meshId,
      meshName,
    };
  });

  const studentRecordChoice = StudentRecordsResponse?.data.find(
    (sr) => Number(sr.education_level_id) === Number(values.education_level_current_id)
  );

  const {
    data: subjects = [],
    error: errorSub,
    isFetching: isFechingSub,
  } = useGetSubjectByStudentRecordQuery(
    { profileId: currentProfile?.profileId!, studentRecords: studentRecordChoice?.id! },
    { skip: !studentRecordChoice, refetchOnMountOrArgChange: 30 }
  );

  // const subjectsId = useMemo(
  //   () => (errorSub === undefined ? subjects.map((s) => s.matter_id) : []),
  //   [subjects, errorSub]
  // );

  // const renderCurrentSubjects = (subject: Subject, index: number) => (
  //   <TableRow hover className="table-row" key={subject.matter_id.toString()}>
  //     <TableCell width="20%" align="center">
  //       <Typography variant="subtitle1">{index + 1}</Typography>
  //     </TableCell>
  //     <TableCell width="80%" align="left">
  //       {/* {newSubjectsId?.includes(subject.matter_id) ? (
  //         <Typography variant="subtitle1" className={classes.subject}>
  //           {subject.matter_rename}
  //         </Typography>
  //       ) : ( */}
  //       <Typography variant="subtitle1">
  //         {subject.cod_matter_migration} - {subject.matter_rename}
  //       </Typography>
  //       {/* )} */}
  //     </TableCell>
  //     {/* <TableCell width="40%" align="center">
  //       <Typography
  //         display={{ xs: 'none', sm: 'none', md: 'block', lg: 'block' }}
  //         variant="subtitle1"
  //       >
  //         {subject.status_matter}
  //       </Typography>
  //     </TableCell> */}
  //   </TableRow>
  // );

  // const renderCurrentSubjectsRows = () => (
  //   <>
  //     {[...(subjects || [])]
  //       ?.sort((a, b) => {
  //         // render first if is in current subjects
  //         if (newSubjectsId?.includes(a.matter_id) && !newSubjectsId?.includes(b.matter_id)) {
  //           return -1;
  //         }
  //         if (!newSubjectsId?.includes(a.matter_id) && newSubjectsId?.includes(b.matter_id)) {
  //           return 1;
  //         }
  //         return 0;
  //       })
  //       ?.filter(
  //         (subject) =>
  //           currentSubjectsSearch === '' ||
  //           subject.matter_rename.toLowerCase().includes(currentSubjectsSearch.toLowerCase()) ||
  //           subject.cod_matter_migration.toLowerCase().includes(currentSubjectsSearch.toLowerCase())
  //       )
  //       ?.map(renderCurrentSubjects)}
  //   </>
  // );

  const newEducationLevelChoice = groupedOptions.find(
    (el) => Number(el.meshId) === Number(values.curriculum_new_id)
  );

  const {
    data: newSubjects,
    error: errorNewSubjects,
    isFetching: isFetchingNewSub,
  } = useGetSubjectByEducationLevelQuery(
    {
      profileId: currentProfile?.profileId!,
      educationLevel: newEducationLevelChoice?.id!,
      studentRecordId: studentRecordChoice?.id!,
    },
    { skip: !newEducationLevelChoice || !studentRecordChoice, refetchOnMountOrArgChange: 30 }
  );

  // const newSubjectsId = useMemo(
  //   () => (errorNewSubjects === undefined ? newSubjects?.data.map((s) => s.matter_id) : []),
  //   [newSubjects, errorNewSubjects]
  // );

  // const renderNewSubjects = (subject: InternalHomologation, index: number) => (
  //   <TableRow hover className="table-row" key={subject.matter_id.toString()}>
  //     <TableCell width="20%" align="center">
  //       <Typography variant="subtitle1">{index + 1}</Typography>
  //     </TableCell>
  //     <TableCell width="80%" align="left">
  //       {(subject.internal_homologation?.length || 0) > 0 ? (
  //         <Typography variant="subtitle1" className={classes.subject}>
  //           {subject?.matter?.cod_matter_migration} - {subject?.matter?.mat_name}
  //         </Typography>
  //       ) : (
  //         <Typography variant="subtitle1">
  //           {subject?.matter?.cod_matter_migration} - {subject?.matter?.mat_name}
  //         </Typography>
  //       )}
  //     </TableCell>
  //   </TableRow>
  // );
  // const renderNewSubjectsRows = () => (
  //   <>
  //     {[...(newSubjects?.data || [])]
  //       ?.sort((a, b) => {
  //         // render first if is in current subjects
  //         if (
  //           (a.internal_homologation?.length || 0) > 0 &&
  //           !((b.internal_homologation?.length || 0) > 0)
  //         ) {
  //           return -1;
  //         }
  //         if (
  //           !((a.internal_homologation?.length || 0) > 0) &&
  //           (b.internal_homologation?.length || 0) > 0
  //         ) {
  //           return 1;
  //         }
  //         return 0;
  //       })
  //       ?.filter(
  //         (subject) =>
  //           newSubjectsSearch === '' ||
  //           subject?.matter?.mat_name.toLowerCase().includes(newSubjectsSearch.toLowerCase()) ||
  //           subject?.matter?.cod_matter_migration
  //             .toLowerCase()
  //             .includes(newSubjectsSearch.toLowerCase())
  //       )
  //       ?.map(renderNewSubjects)}
  //   </>
  // );

  useEffect(() => {
    if (isErrorELA && errorELA) {
      setShowAlerts(true);
    }
  }, [isErrorELA, errorELA]);

  useEffect(() => {
    if (
      studentRecordsResponse &&
      studentRecordsResponse.data &&
      studentRecordsResponse.data.length === 1
    ) {
      setFieldValue(
        'education_level_current_id',
        studentRecordsResponse.data[0].education_level_id || ''
      );
    }
  }, [setFieldValue, studentRecordsResponse]);

  // useEffect(() => {
  //   const isValid = subjectsId?.some((s) => newSubjectsId?.includes(s));
  //   setCanRegister(isValid);
  // }, [subjectsId, newSubjectsId]);

  return userInfo?.studentInfo?.typeStudent !== 'NIV' &&
    userInfo?.studentInfo?.offerDescription === 'oferta_grado' ? (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2} alignItems="flex-start">
        <Grid item>
          {isError && showAlerts && (
            <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
          )}
          {isErrorELA && showAlerts && (
            <ErrorAlert message={errorELA as string} handleDismiss={() => setShowAlerts(false)} />
          )}
          {isSuccess && showAlerts && (
            <SuccessAlert
              message={t('students.applications.success_message')}
              handleDismiss={() => setShowAlerts(false)}
            />
          )}
        </Grid>
        <Grid item xs={12} md={12} container spacing={2} marginBottom={2}>
          <Grid item xs={5}>
            <InputLabel>{t('students.applications.career_change.from_education_level')}</InputLabel>
            {!isLoadingStudentRecords ? (
              <Autocomplete
                options={studentRecordsResponse?.data! || []}
                loading={isLoadingStudentRecords}
                loadingText="Cargando niveles educativos..."
                getOptionLabel={(option) => `${option.edu_name} (${option.mesh.mes_name})`}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(
                      errors.education_level_current_id && touched.education_level_current_id
                    )}
                    placeholder={t(
                      'students.applications.career_change.from_education_level_placeholder'
                    )}
                  />
                )}
                onChange={(_, value) =>
                  setFieldValue('education_level_current_id', value?.education_level_id || '')
                }
                fullWidth
                clearIcon={null}
                defaultValue={
                  studentRecordsResponse && studentRecordsResponse.data.length === 1
                    ? studentRecordsResponse?.data[0]
                    : null
                }
              />
            ) : (
              <TextFieldFetching />
            )}
            {errors.education_level_current_id && touched.education_level_current_id && (
              <FormHelperText error>{errors.education_level_current_id}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={2} container alignItems="center" spacing={0}>
            <Grid item container justifyContent="center">
              <IconChevronRight size={30} />
            </Grid>
          </Grid>
          <Grid item xs={5}>
            <InputLabel>{t('students.applications.career_change.to_education_level')}</InputLabel>
            <Autocomplete
              options={groupedOptions}
              loading={isLoadingELA}
              loadingText="Cargando niveles educativos disponibles..."
              getOptionLabel={(option) => `${option.edu_name} (${option.meshName})`}
              groupBy={(option) => option.parentName}
              getOptionSelected={(option) => option.meshId === values?.curriculum_new_id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(errors.curriculum_new_id && touched.curriculum_new_id)}
                  placeholder={t(
                    'students.applications.career_change.to_education_level_placeholder'
                  )}
                />
              )}
              onChange={(_, value) => setFieldValue('curriculum_new_id', value?.meshId || '')}
              fullWidth
              clearIcon={null}
            />
            {errors.curriculum_new_id && touched.curriculum_new_id && (
              <FormHelperText error>{errors.curriculum_new_id}</FormHelperText>
            )}
            {values.curriculum_new_id !== 0 && (
              <Typography variant="caption" color="error" fontWeight="bold">
                N° de materias permitidas a homologar:{' '}
                {
                  groupedOptions.find((option) => option.meshId === values.curriculum_new_id)
                    ?.meshs?.[0]?.mes_quantity_internal_matter_homologate
                }
              </Typography>
            )}
          </Grid>
        </Grid>
        {values?.curriculum_new_id !== 0 && values?.education_level_current_id !== 0 && (
          <Grid item xs={12} md={12} container spacing={2}>
            <Grid item xs={12}>
              <WarningAlert message="Las materias presentadas en esta sección son utilizadas como referencia del estudiante, esta información será verificada por el personal correspondiente." />
            </Grid>
            <Grid item xs={5}>
              <SubCard
                title={
                  <>
                    <Grid item container xs={12}>
                      <Grid item container xs={12} mb={2} spacing={1}>
                        {/* <Grid item>
                          <PeriodBadge label="Total materias" number={subjects?.length || 0} />
                        </Grid> */}
                        <Grid item>
                          <PeriodBadge
                            label="Materias aprobadas"
                            number={
                              subjects?.filter(
                                (subject) => subject.status_matter === StatusMatter.Aprobado
                              )?.length || 0
                            }
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            label="Buscar"
                            variant="outlined"
                            size="small"
                            fullWidth
                            value={currentSubjectsSearch}
                            onChange={(e) => setCurrentSubjectsSearch(e.target.value)}
                          />
                        </Grid>
                        {/* <Grid item>
                          <PeriodBadge
                            label="Materias a homologar"
                            number={
                              subjects?.filter((subject) =>
                                newSubjectsId?.includes(subject.matter_id)
                              )?.length || 0
                            }
                          />
                        </Grid> */}
                      </Grid>
                      {/* <Grid item xs={3}>
                        <Typography textAlign="center" variant="subtitle2">
                          N°
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={9}
                        display={{ xs: 'none', sm: 'none', md: 'block', lg: 'block' }}
                      >
                        <Typography textAlign="center" variant="subtitle2">
                          {t('students.applications.career_change.subject')}
                        </Typography>
                      </Grid> */}
                    </Grid>
                  </>
                }
              >
                <Grid item xs={12}>
                  <SearchPaginatedTable
                    data={
                      subjects
                        ?.filter(
                          (subject) =>
                            currentSubjectsSearch === '' ||
                            subject?.cod_matter_migration
                              .toLowerCase()
                              .includes(currentSubjectsSearch.toLowerCase()) ||
                            subject?.matter_rename
                              ?.toLowerCase()
                              ?.includes(currentSubjectsSearch.toLowerCase())
                        )
                        ?.sort((a, b) =>
                          newSubjects?.data?.find(
                            (subject) =>
                              subject.matter?.cod_matter_migration === a.cod_matter_migration
                          )
                            ? -1
                            : 1
                        )
                        ?.slice((currentSubjectsPage - 1) * 5, currentSubjectsPage * 5)
                        ?.map((subject, index: number) => ({
                          id: {
                            idx: index + (currentSubjectsPage - 1) * 5 + 1,
                            code: subject.cod_matter_migration,
                          },
                          name: `${subject.cod_matter_migration} - ${subject.matter_rename}`,
                        })) || []
                    }
                    keyExtractor={(item) => item.id.toString()}
                    error={errorSub}
                    numHeader={2}
                    searchPlacehoder={t('students.applications.career_change.search')}
                    isLoading={false}
                    isFetching={isFechingSub}
                    search={currentSubjectsSearch}
                    setSearch={setCurrentSubjectsSearch}
                    headers={{
                      id: 'N°',
                      name: t('students.applications.career_change.subject'),
                    }}
                    page={currentSubjectsPage}
                    setPage={setCurrentSubjectsPage}
                    perPage={5}
                    setPerPage={() => {}}
                    total={subjects?.length || 0}
                    showFilter={false}
                    perPageBox={false}
                    customRenderers={{
                      id: (row) => <Typography variant="body1">{row.id.idx}</Typography>,
                      name: (row) => (
                        <>
                          {newSubjects?.data?.find(
                            (subject) => subject.matter?.cod_matter_migration === row.id.code
                          ) !== undefined ? (
                            <Typography
                              variant="body1"
                              sx={{
                                color: 'primary.main',
                              }}
                            >
                              {row.name}
                            </Typography>
                          ) : (
                            <Typography variant="body1">{row.name}</Typography>
                          )}
                        </>
                      ),
                    }}
                  />
                  {/* <PerfectScrollbar component="div">
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell align="center" width="20%">
                              N°
                            </TableCell>
                            <TableCell align="center" width="80%">
                              {t('students.applications.career_change.subject')}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {errorSub === undefined &&
                            (isFechingSub ? renderSkeletonRows(5, 2) : renderCurrentSubjectsRows())}
                          {errorSub && renderErrorOrEmptyRow(5, errorSub as string)}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </PerfectScrollbar> */}
                </Grid>
              </SubCard>
            </Grid>
            <Grid item container xs={2} alignItems="center"></Grid>
            <Grid item xs={5}>
              <SubCard
                title={
                  <Grid item container xs={12}>
                    <Grid item container xs={12} mb={2} spacing={1}>
                      <Grid item>
                        <PeriodBadge
                          label="Total materias"
                          number={newSubjects?.data?.length || 0}
                        />
                      </Grid>
                      <Grid item>
                        <PeriodBadge
                          label="Materias disponibles"
                          number={
                            newSubjects?.data?.filter(
                              (subject) =>
                                (subject.internal_homologation?.length || 0) > 0 ||
                                subjects.find(
                                  (subject2) =>
                                    subject2.cod_matter_migration ===
                                    subject.matter?.cod_matter_migration
                                )
                            )?.length || 0
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label="Buscar"
                          variant="outlined"
                          size="small"
                          fullWidth
                          value={newSubjectsSearch}
                          onChange={(e) => setNewSubjectsSearch(e.target.value)}
                        />
                      </Grid>
                    </Grid>

                    {/* <Grid item xs={12}>
                      <Typography textAlign="center" variant="subtitle2">
                        {t('students.applications.career_change.subject')}
                      </Typography>
                    </Grid> */}
                  </Grid>
                }
              >
                <Grid item xs={12} justifyContent="center">
                  <SearchPaginatedTable
                    data={
                      [...(newSubjects?.data || [])]
                        ?.sort((a, b) => {
                          if (
                            (a.internal_homologation?.length || 0) > 0 &&
                            !((b.internal_homologation?.length || 0) > 0)
                          ) {
                            return -1;
                          }
                          if (
                            !((a.internal_homologation?.length || 0) > 0) &&
                            (b.internal_homologation?.length || 0) > 0
                          ) {
                            return 1;
                          }

                          if (
                            subjects.find(
                              (subject) =>
                                subject.cod_matter_migration === a.matter?.cod_matter_migration
                            )
                          ) {
                            return -1;
                          }

                          return 1;
                        })
                        ?.filter(
                          (subject) =>
                            newSubjectsSearch === '' ||
                            subject?.matter?.mat_name
                              .toLowerCase()
                              .includes(newSubjectsSearch.toLowerCase()) ||
                            subject?.matter?.cod_matter_migration
                              .toLowerCase()
                              .includes(newSubjectsSearch.toLowerCase())
                        )
                        ?.slice((newSubjectsPage - 1) * 5, newSubjectsPage * 5)
                        ?.map((subject, index: number) => ({
                          id: {
                            idx: index + (newSubjectsPage - 1) * 5 + 1,
                            code: subject.matter?.cod_matter_migration,
                            canInternalHomologate: (subject.internal_homologation?.length || 0) > 0,
                          },
                          name: `${subject.matter?.cod_matter_migration} - ${subject.matter?.mat_name}`,
                        })) || []
                    }
                    keyExtractor={(item) => item.id.toString()}
                    error={errorNewSubjects}
                    numHeader={2}
                    searchPlacehoder={t('students.applications.career_change.search')}
                    isLoading={false}
                    isFetching={isFetchingNewSub}
                    search={newSubjectsSearch}
                    setSearch={setNewSubjectsSearch}
                    headers={{
                      id: 'N°',
                      name: t('students.applications.career_change.subject'),
                    }}
                    page={newSubjectsPage}
                    setPage={setNewSubjectsPage}
                    perPage={5}
                    setPerPage={() => {}}
                    total={newSubjects?.data?.length || 0}
                    showFilter={false}
                    customRenderers={{
                      id: (row) => <Typography variant="body1">{row.id.idx}</Typography>,
                      name: (row) => (
                        <>
                          {row.id.canInternalHomologate ? (
                            <Typography
                              variant="body1"
                              sx={{
                                color: 'success.main',
                              }}
                            >
                              {row.name}
                            </Typography>
                          ) : subjects.find(
                              (subject) => subject.cod_matter_migration === row.id.code
                            ) !== undefined ? (
                            <Typography
                              variant="body1"
                              sx={{
                                color: 'primary.main',
                              }}
                            >
                              {row.name}
                            </Typography>
                          ) : (
                            <Typography variant="body1">{row.name}</Typography>
                          )}
                        </>
                      ),
                    }}
                    perPageBox={false}
                  />
                  {/* <PerfectScrollbar component="div">
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell align="center" width="20%">
                              N°
                            </TableCell>
                            <TableCell align="center" width="80%">
                              {t('students.applications.career_change.subject')}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {isFetchingNewSub ? renderSkeletonRows(5, 1) : renderNewSubjectsRows()}
                          {errorNewSubjects && renderErrorOrEmptyRow(5, errorNewSubjects as string)}
                          {newSubjects?.data.length === 0 && renderErrorOrEmptyRow(5)}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </PerfectScrollbar> */}
                </Grid>
              </SubCard>
            </Grid>
          </Grid>
        )}

        <Grid
          item
          container
          alignItems="center"
          justifyContent="flex-end"
          spacing={2}
          sx={{ mt: 1 }}
        >
          {hasAccess('save_change_career') && (
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                size="large"
                color="primary"
                disabled={isLoadingIH /*|| !canRegister*/}
                endIcon={isLoadingIH && <CircularProgress size={20} />}
              >
                {t('students.applications.save')}
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </form>
  ) : (
    <Grid container item mt={3} justifyContent="center">
      <SubCard title="Aviso importante" darkTitle={true}>
        <Grid item>
          <ErrorAlert message={t('students.applications.career_change.error_message')} />
        </Grid>
        <Box>
          <Grid container justifyContent="center" alignContent="center">
            <Grid container justifyContent="center" item xs={8} md={6} spacing={2}>
              <Grid container item xs={12} justifyContent="center">
                <IconTelework size={300} />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </SubCard>
    </Grid>
  );
};
export default ChangeCareerForm;
