import React, { useState } from 'react';
import {
  Autocomplete,
  Button,
  CardActions,
  CircularProgress,
  Divider,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  TextField,
  TableContainer,
  Table,
  TableRow,
  TableBody,
  TableHead,
  TableCell,
  Typography,
  Checkbox,
  Box,
  Stepper,
  StepLabel,
  Step,
  FormGroup,
  FormControlLabel,
} from '@material-ui/core';
import { IconCopy, IconFolder, IconPlus, IconTrash } from '@tabler/icons';
import { useFormik } from 'formik';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
  setEvaluationQuestionCurrent,
  setInfoEvaluation,
  resetQuestions,
  setChoose,
  setQuestionAdded,
  resetInfoEvaluation,
} from '../../../slices/evaluationSlice';
import { EvaluationQuestion, InfoEvaluation } from '../../../interfaces/evaluations.interfaces';
import { DateTimePicker, LoadingButton, LocalizationProvider } from '@material-ui/lab';
import esLocale from 'date-fns/locale/es';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import EvaluationChooseAnswer from '../choose/EvaluationChooseAnswer';
import EvaluationEditQuestion from '../edit/EvaluationEditQuestion';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  useCreateEvaluationMutation,
  useGetCategoriesActivityQuery,
  useGetDistributionsCourseSupplyQuery,
} from '../../../slices/evaluationApiSlice';
import { navigateBottomSmooth, navigateTop, selectTypeQuestion } from '../../../helpers';
import { evaluationLimit } from '../../../constants';
import EvaluationQuestionAddedPreview from '../../preview/EvaluationQuestionAddedPreview';
import moment from 'moment';
import EvaluationStudentsList from '../../students/list/EvaluationStudentsList';
import EvaluationHeaderInfoCreate from '../../info/EvaluationHeaderInfoCreate';
import { useAppDispatch, useAppSelector } from '../../../../../../../hooks/useRedux';
import { useGetAllStudentsCourseQuery } from '../../../../../../maintenance/courses/slices/courseApiSlice';
import { useGetCatalogByAcronymChildrenQuery } from '../../../../../../shared/slices/catalogApi';
import ErrorAlert from '../../../../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../../../../shared/components/alerts/SuccessAlert';
import MainCard from '../../../../../../shared/components/cards/MainCard';
import Accordion from '../../../../../../shared/components/extended/Accordion';
import { renderSkeletonRows } from '../../../../../../shared/helpers/render';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  setBackStep,
  setNextStep,
  setResetStep,
} from '../../../../slices/virtualClassroomTeacherSlice';
import { SchemaEvaluationsEdit } from '../../../constants/schemaEvaluations';
import { useGetCourseVCQuery } from '../../../../../../students/virtual-classroom/slices/virtualClassroomApiSlice';
import { parseISO } from 'date-fns';
const PreCreatedQuestionOptions = () => {
  const dispatch = useAppDispatch();

  /**
   * Manejo de la lista de opciones dinámicas.
   * La propiedad correct se puede utilizar más luego para poder seleccionar la opción correcta.
   */
  const [optionsList, setOptionsList] = useState([{ option: 'Opción...', correct: false }]);

  const [showAlert, setShowAlert] = useState<boolean>(false);

  /**
   * Manejo del copiado de la propiedad.
   */
  const [copy, setCopy] = useState<boolean>(false);

  /**
   * Manejo de visualización de lista estudiantes.
   */

  /**
   * Determinar cuando la información del formulario de test ha sido modificada.
   */
  const [editedInfoForm, setEditedInfoForm] = useState<boolean>(false);

  /**
   * Determinar cuando una pregunta ha sido removida con sus opciones.
   */
  const [questionRemoved, setQuestionRemoved] = useState<boolean>(false);

  /**
   * Opciones de selección de tipo de pregunta.
   */
  const [option, setOption] = useState<string>('Opcion Multiple');

  /**
   * Propiedad que permite mostrar cuando el puntaje es inválido.
   */
  const [pointInvalid, setPointInvalid] = useState<boolean>(false);

  const [createEvaluation, { isLoading, error, isError, isSuccess }] =
    useCreateEvaluationMutation();

  const { currentProfile } = useAppSelector((state) => state.access);
  const { StepEdit } = useAppSelector((state) => state.virtualClassroomTeacher);

  const { userInfo } = useAppSelector((state) => state.auth);

  const History = useHistory();

  const { t } = useTranslation();

  const {
    evaluation: {
      choose,
      typeQuestion,
      infoEvaluation,
      questionAdded,
      questions,
      chooseAnswerEdit,
    },
  } = useAppSelector((state) => state.evaluationTeacher);

  const {
    course: { courseId },
  } = useAppSelector((state) => state.virtualClassroomTeacherShared);

  const { data: courseSelected, isLoading: isLoadingCourseSelected } = useGetCourseVCQuery({
    courseId: courseId!,
    profileId: currentProfile?.profileId!,
  });

  const { values, handleSubmit, getFieldProps, setFieldValue, errors, touched } = useFormik({
    initialValues: {
      typeActivityId: infoEvaluation?.typeActivityId! || 0,
      categoryActivityId: infoEvaluation?.categoryActivityId! || 0,
      cactIsExtemporaneous: infoEvaluation?.cactIsExtemporaneous! || 0,
      distributionId: infoEvaluation?.distributionId! || 0,
      supplyId: infoEvaluation?.supplyId! || 0,
      description: infoEvaluation?.description! || '',
      actNumTries: infoEvaluation?.actNumTries! || 1,
      startDate: infoEvaluation?.startDate! ? new Date(infoEvaluation?.startDate!) : new Date(),
      endDate: infoEvaluation?.endDate! ? new Date(infoEvaluation?.endDate!) : new Date(),
      actScoreActivity: infoEvaluation?.actScoreActivity! || 0,
      limitActScoreActivity: infoEvaluation?.limitActScoreActivity! || 0,
      question: '',
      students: infoEvaluation?.students! || [],
      visible_note: infoEvaluation?.visible_note! || '1',
      questions_random: infoEvaluation?.questions_random! || '1',
    },
    validationSchema: SchemaEvaluationsEdit,
    onSubmit: async () => {
      handleSaveInfoEvaluation();
      // setShowAlert(true);
      navigateTop();
      //dispatch(setNextStep(1));
    },
  });

  async function CrearEvaluacion() {
    const questionData = questions.map((e) => ({
      catalogo_id: e?.catalogId!,
      dta_question: e?.question!,
      dta_score_evaluated: e?.point,
      is_manual: e?.typeQuestion === 'comentary' ? 1 : 0,
      option_answers: e?.options.map((e) => ({
        op_ans_description: e?.option,
        op_ans_comment: '',
        op_ans_is_answer: e?.correct ? 1 : 0,
      })),
    }));

    const courseStudents = infoEvaluation?.students.map((e) => ({
      student_id: e?.student?.student_id!,
      grupo: 0,
    }));

    await createEvaluation({
      course: courseId!,
      payload: {
        act_description: infoEvaluation?.description!,
        category_activity_id: infoEvaluation?.categoryActivityId!,
        supply_id: infoEvaluation?.supplyId!,
        calification_models_distributions_id: infoEvaluation?.distributionId!,
        act_is_score: 1,
        type_activity_id: infoEvaluation?.typeActivityId!,
        act_score_activity: infoEvaluation?.actScoreActivity!,
        act_num_max_questions: questions?.length!,
        act_num_tries: infoEvaluation?.actNumTries!,
        user_id: Number(userInfo?.id!),
        status_id: 1,
        course_activity: {
          cact_start_date: moment(new Date(infoEvaluation?.startDate!)).format(
            'YYYY-MM-DD HH:mm:ss'
          ),
          cact_end_date: moment(new Date(infoEvaluation?.endDate!)).format('YYYY-MM-DD HH:mm:ss'),
          cact_is_extemporaneous: infoEvaluation?.cactIsExtemporaneous!,
        },
        course_student: courseStudents!,
        detail: questionData,
        questions_random: values.questions_random!,
        visible_note: values.visible_note!,
      },
    });

    if (!isError) {
      handleResetChanges();
      dispatch(setResetStep());

      History.goBack();
    }
  }

  /**
   * Obtener distribuciones en base a un curso
   */
  const {
    data: dataDistribution,
    isLoading: isLoadingDistribution,
    isFetching: isFetchingDistribution,
  } = useGetDistributionsCourseSupplyQuery({
    courseId: courseId!,
    userProfileId: currentProfile?.profileId!,
  });

  /**
   * Obtener los estudiantes de un curso
   */
  const { data: dataStudents, isLoading: isLoadingStudents } = useGetAllStudentsCourseQuery({
    courseId: courseId!,
  });

  /**
   * Obtener tipo de actividad.
   */
  const { data: dataTypeActivity } = useGetCatalogByAcronymChildrenQuery({
    acronym: 'tipo-actividad',
    profileId: currentProfile?.profileId!,
  });

  /**
   * Obtener tipos de respuesta.
   */
  const {
    data: dataTypeQuestion,
    isLoading: isLoadingTypeQuestion,
    isFetching: isFetchingTypeQuestion,
  } = useGetCatalogByAcronymChildrenQuery({
    acronym: 'tipo-respuesta',
    profileId: currentProfile?.profileId!,
  });

  /**
   * Obtener categorías tipo actividad.
   */
  const {
    data: dataCategory,
    isLoading: isLoadingCategory,
    isFetching: isFetchingCategory,
  } = useGetCategoriesActivityQuery({
    typeActivity: 'Evaluacion',
    userProfileId: currentProfile?.profileId!,
  });

  /**
   * Evento emitido cuando el tipo de pregunta cambia.
   */
  const handleChangeFilter = (value: string) => {
    setOption(value);
    selectTypeQuestion(value, dispatch);
  };

  /**
   * Función que permite actualizar el valor determinado de un textfield
   * dinámico en el formulario.
   */
  const handleInputChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined,
    index: number
  ) => {
    const value = e?.target?.value!;
    const list = [...optionsList];
    list[index] = { option: value, correct: false };
    setOptionsList(list);
  };

  /**
   * Función que permite agregar un textfield al formulario.
   */
  const handleAdd = () => {
    setOptionsList([...optionsList, { option: '', correct: false }]);
  };

  /**
   *
   *
   * Función que permite remover un textfield del formulario.
   */

  const handleRemove = (index: number) => {
    const list = [...optionsList];
    list.splice(index, 1);
    setOptionsList(list);
  };

  /**
   * Función que permite seleccionar un catálogo id en base
   * al tipo de pregunta que se haya seleccionado.
   */
  const handleSelectCatalog = (): number => {
    return dataTypeQuestion?.find((e) => e.cat_name === option)?.id!;
  };

  console.log(dataTypeQuestion);
  /**
   * Función que permite actualizar el estado del componente y
   * mostrar formulario de escoger respuesta.
   * El formulario de escoger respuesta chequea que tipo de
   * pregunta ha sido escogida previamente.
   */
  const handleChooseAnswer = async () => {
    const data: EvaluationQuestion = {
      id: questions?.length! + 1,
      question: values.question,
      catalogId: handleSelectCatalog(),
      options: optionsList,
      point: 0,
    };
    await dispatch(setEvaluationQuestionCurrent(data));
    await dispatch(setChoose(true));
    handleResetForm();
  };
  /**
   * Función que permite escoger el puntaje de una pregunta cuando es de tipo comentario.
   * Por defecto activa el formulario de ecoger respuesta.
   */
  const handleChoosePoint = async () => {
    const data: EvaluationQuestion = {
      id: questions?.length! + 1,
      question: values.question,
      catalogId: handleSelectCatalog(),
      options: [],
      point: 0,
    };
    await dispatch(setEvaluationQuestionCurrent(data));
    await dispatch(setChoose(true));
    handleResetForm();
  };

  /**
   * Función que permite remover una pregunta y mostrar el componente
   * de escribir pregunta y escoger opciones nuevamente.
   */
  const handleRemoveQuestion = async () => {
    await dispatch(setChoose(false));
    setQuestionRemoved(true);
    handleResetForm();
  };
  /**
   * Suma de los puntos de la evalución, se sumará solo
   * si la pregunta ha sido guardada.
   */
  const sumPointsEvaluation = (): number => {
    return questions.reduce((acc, value) => acc + value.point, 0);
  };

  const handleResetForm = () => {
    setFieldValue('question', '');
    setOptionsList([]);
  };

  /**
   * Calcular el total de puntos a utilizar en una distribución seleccionado
   * cuando no se ha seleccionado un insumo, por defecto se accede al primer
   * elemento del insumo.
   */
  const calculateLimitPointTotalDistribution = (distributionId: number): number => {
    const dist = dataDistribution?.find((e) => Number(e.distribution_id) === distributionId);
    const compositionValue = Number(dist?.ref2[0]?.composition_value!);
    const sumScoreActivity = Number(dist?.ref2[0]?.suma_score_activity!);
    const limit = compositionValue * 100 - sumScoreActivity;
    return limit;
  };

  /**
   * Calcular el total de puntos a utilizar en una distribución seleccionada
   * con un insumo seleccionado.
   */
  const calculateLimitPointTotalSupply = (distributionId: number, supplyId: number): number => {
    const dist = dataDistribution
      ?.find((e) => Number(e.distribution_id) === distributionId)
      ?.ref2.find((e) => Number(e.ref2_supply_id) === supplyId);
    const compositionValue = Number(dist?.composition_value!);
    const sumScoreActivity = Number(dist?.suma_score_activity!);
    const limit = compositionValue * 100 - sumScoreActivity;
    return limit;
  };

  const onChangeDistribution = (distributionId: number) => {
    setFieldValue('actScoreActivity', 1);
    setFieldValue('distributionId', distributionId);
    const isUniqueDist = obtainDistributionAndSupply();
    if (isUniqueDist) {
      const total = calculateLimitPointTotalDistribution(distributionId);
      setFieldValue('limitActScoreActivity', total);
      setFieldValue('supplyId', distributionId);
    }
  };

  const onChangeSupply = (supplyId: number) => {
    setFieldValue('actScoreActivity', 1);
    setFieldValue('supplyId', supplyId);
    const total = calculateLimitPointTotalSupply(values.distributionId, supplyId);
    setFieldValue('limitActScoreActivity', total);
  };

  const handleInfoEvaluation = async () => {
    const data: InfoEvaluation = {
      typeActivityId: dataTypeActivity?.find(({ cat_name }) => cat_name === 'Evaluacion')?.id!,
      categoryActivityId:
        values.categoryActivityId === 0 ? dataCategory![0]?.id! : values.categoryActivityId,
      supplyId:
        values.supplyId === 0
          ? Number(
              dataDistribution?.find(
                (item) => Number(item?.distribution_id) === values?.distributionId
              )?.ref2[0]?.ref2_supply_id!
            )
          : values.supplyId,
      distributionId: values.distributionId,
      cactIsExtemporaneous:
        values.cactIsExtemporaneous === 0 ? evaluationLimit![0]?.id! : values.cactIsExtemporaneous,
      description: values.description,
      startDate: values.startDate.getTime(),
      endDate: values.endDate.getTime(),
      actScoreActivity: values.actScoreActivity,
      limitActScoreActivity: values.limitActScoreActivity,
      actNumTries: values.actNumTries,
      students: values.students.length === 0 ? getStudentsList() : values.students!,
      visible_note: values.visible_note!,
      questions_random: values.questions_random!,
    };
    dispatch(setInfoEvaluation(data));
  };

  /**
   * Función que permite editar la información de una test.
   * Solo se enviará la información previa de la test.
   */

  /**
   * Función que permite guardar la información de una test.
   * * Solo se enviará la información previa de la test.
   */
  const handleSaveInfoEvaluation = () => {
    handleInfoEvaluation();
    navigateBottomSmooth();
    dispatch(setNextStep(1));
  };

  /**
   * Resetear formulario de test.
   */
  const handleResetChanges = async () => {
    await dispatch(resetQuestions());
    await dispatch(resetInfoEvaluation());
    handleResetForm();
  };

  const getStudentsList = () => {
    return dataStudents?.map((e) => ({
      student: {
        student_id: e?.id!,
        student_email: e?.email!,
        student_full_name: e?.pers_full_lastname!,
      },
      grupo: 0,
    }))!;
  };

  /**
   * Eliminar estudiantes de la lista.
   */
  const handleDeleteStudentList = (id: number) => {
    const students = values?.students?.filter((e) => e.student.student_id !== id);
    setFieldValue('students', students);
  };

  /*const RenderEvaluationFinished = () => {
    return (
      <React.Fragment>
        <Button
          onClick={handleResetChanges}
          disabled={questions.length === 0}
          style={{ textTransform: 'unset' }}
          variant="outlined"
          size="large"
          color="primary"
        >
          {t('teacherVirtualClassroom.tests.preCreatedQuestionEraseChanges')}
        </Button>
      </React.Fragment>
    );
  };*/

  const RenderOptionsMultiple = () => {
    return (
      <React.Fragment>
        <Grid item justifyContent="center">
          <Button
            onClick={handleChooseAnswer}
            disabled={!values.question}
            startIcon={<IconFolder fontSize="0.875rem" />}
            style={{ textTransform: 'unset' }}
            variant="outlined"
            size="small"
            color="primary"
          >
            {t('teacherVirtualClassroom.tests.preCreatedChooseQuestion')}
          </Button>
        </Grid>
        <Grid item>
          <Button style={{ textTransform: 'unset' }} variant="text" size="small" color="primary">
            {`${optionsList?.length!} ${optionsList.length > 1 ? 'opciones' : 'opción'}`}
          </Button>
        </Grid>
        <Grid item>
          <Button style={{ textTransform: 'unset' }} variant="text" size="small" color="primary">
            0 {t('teacherVirtualClassroom.tests.chooseAnswerScore')}
          </Button>
        </Grid>
        {false && (
          <Button disabled={!values.question}>
            <CopyToClipboard onCopy={() => setCopy(true)} text={values.question}>
              <IconCopy style={{ cursor: 'pointer' }} />
            </CopyToClipboard>
          </Button>
        )}
        <Button disabled={!values.question}>
          <IconTrash onClick={handleRemoveQuestion} style={{ cursor: 'pointer' }} />
        </Button>
      </React.Fragment>
    );
  };

  /**
   * Renderizar estudiantes, ya sea en el primer fetch o si
   * ya se ha guardado previamente la lista.
   */
  const { data: students } = useGetAllStudentsCourseQuery(
    {
      courseId: courseId!,
    },
    { skip: courseId === 0 || courseId === undefined }
  );
  const renderStudents = () => {
    return (
      <>
        {students && students?.length > 0 ? (
          <>
            {students.map((e) => (
              <EvaluationStudentsList
                student={{
                  student: {
                    student_id: e.id,
                    student_full_name: e.pers_full_lastname,
                    student_email: e.email,
                  },
                }}
                key={e?.id!}
                onClick={() => handleDeleteStudentList(e?.id!)}
              />
            ))}
          </>
        ) : (
          <>
            <TableRow>
              <TableCell colSpan={3}>
                <Typography variant="body2" color="textSecondary" component="p" textAlign="center">
                  No hay estudiantes en este curso.
                </Typography>
              </TableCell>
            </TableRow>
          </>
        )}
      </>
    );
  };

  /**
   * Ocultar insumos cuando la distribución contiene un solo
   * elemento del mismo nombre de la distribución.
   */
  const obtainDistributionAndSupply = (): boolean => {
    const dist = dataDistribution?.find((e) => Number(e.distribution_id) === values.distributionId);
    if (dist?.ref2?.length === 1 && dist?.ref2![0]?.ref2!) {
      return false;
    }
    return true;
  };

  const dataInfoEvaluation = [
    {
      id: 'form-info-evaluation',
      title: 'Expandir formulario',
      defaultExpand: true,
      content: (
        <React.Fragment>
          <Grid item xs={12} sx={{ mt: 2 }}>
            <InputLabel>{t('teacherVirtualClassroom.tasks.table.descripcion')}</InputLabel>
            <TextField
              fullWidth
              rows={3}
              multiline
              error={Boolean(errors.description && touched.description)}
              placeholder={t('teacherVirtualClassroom.tasks.table.descripcion')}
              {...getFieldProps('description')}
            />
            {errors.description && touched.description && (
              <FormHelperText error>{errors.description}</FormHelperText>
            )}
          </Grid>

          {/* Container supply */}
          <Grid container item xs={12} spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={6}>
              <InputLabel>
                {t('teacherVirtualClassroom.tasks.table.input.title.distribution')}
              </InputLabel>
              {isLoadingDistribution || isFetchingDistribution ? (
                <>
                  <TextField
                    disabled
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <CircularProgress size={25} />
                        </InputAdornment>
                      ),
                    }}
                    placeholder={t(
                      'teacherVirtualClassroom.tasks.table.input.title.distribution.loading'
                    )}
                  />
                </>
              ) : (
                <Autocomplete
                  options={dataDistribution! || []}
                  loading={isLoadingDistribution}
                  loadingText={t(
                    'teacherVirtualClassroom.tasks.table.input.title.distribution.loading'
                  )}
                  getOptionLabel={(option) => (option ? option?.distribution! : '')}
                  onChange={(_, value) => onChangeDistribution(Number(value?.distribution_id!))}
                  value={
                    dataDistribution?.find(
                      (e) => Number(e?.distribution_id!) === values.distributionId
                    )! || null
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={t(
                        'teacherVirtualClassroom.tasks.table.input.title.distribution.loading.select'
                      )}
                    />
                  )}
                  fullWidth
                  clearIcon={null}
                />
              )}
            </Grid>

            {obtainDistributionAndSupply() && (
              <Grid item xs={6}>
                <InputLabel>
                  {t('teacherVirtualClassroom.tasks.table.input.title.insumo')}
                </InputLabel>
                <Autocomplete
                  options={
                    dataDistribution && values.distributionId !== 0
                      ? dataDistribution.find(
                          (e) => Number(e.distribution_id) === values.distributionId
                        )?.ref2!
                      : []
                  }
                  loading={isLoadingDistribution}
                  loadingText={t('teacherVirtualClassroom.tasks.table.input.title.insumo.loading')}
                  getOptionLabel={(option) => (option ? option?.ref2! : '')}
                  onChange={(_, value) => onChangeSupply(Number(value?.ref2_supply_id))}
                  value={
                    dataDistribution
                      ?.find((e) => Number(e.distribution_id) === values.distributionId)
                      ?.ref2.find((e) => Number(e?.ref2_supply_id!) === values.supplyId!)! || null
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={t(
                        'teacherVirtualClassroom.tasks.table.input.title.insumo.select'
                      )}
                    />
                  )}
                  fullWidth
                  clearIcon={null}
                />
              </Grid>
            )}
          </Grid>

          {/* Container type activity and category activity*/}
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={6} sx={{ mt: 2 }}>
              Score
              <TextField
                id="button-action-search"
                fullWidth
                type="number"
                InputProps={{ inputProps: { min: 1, max: values.limitActScoreActivity } }}
                variant="outlined"
                {...getFieldProps('actScoreActivity')}
              />
              {errors.actScoreActivity && touched.actScoreActivity && (
                <FormHelperText error>{errors.actScoreActivity}</FormHelperText>
              )}
            </Grid>
            <Grid item xs={6} sx={{ mt: 2 }}>
              <InputLabel>{t('teacherVirtualClassroom.tests.preCreatedActivityLabel')}</InputLabel>
              {isLoadingCategory || isFetchingCategory ? (
                <>
                  <TextField
                    disabled
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <CircularProgress size={25} />
                        </InputAdornment>
                      ),
                    }}
                    placeholder={t('teacherVirtualClassroom.tests.preCreatedActivityLoading')}
                  />
                </>
              ) : (
                <Autocomplete
                  options={dataCategory! || []}
                  loading={isLoadingCategory}
                  loadingText="Cargando categorías..."
                  getOptionLabel={(option) => option.cat_name}
                  onChange={(_, value) => setFieldValue('categoryActivityId', value?.id!)}
                  value={
                    dataCategory?.find((e) => e.id === values.categoryActivityId) ||
                    dataCategory![0]
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={t('teacherVirtualClassroom.tests.preCreatedActivityPlaceholder')}
                    />
                  )}
                  fullWidth
                  clearIcon={null}
                />
              )}
            </Grid>
          </Grid>
          {/* Container categorie type activity */}
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={6} sx={{ mt: 2 }}>
              <InputLabel>Permitir realizar</InputLabel>
              <Autocomplete
                options={evaluationLimit! || []}
                getOptionLabel={(option) => option.name}
                onChange={(_, value) => setFieldValue('cactIsExtemporaneous', value?.value!)}
                value={
                  evaluationLimit.find((e) => e.id === values.cactIsExtemporaneous) ||
                  evaluationLimit[0]
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={t(
                      'teacherVirtualClassroom.tests.preCreatedAllowToPerformPlaceholder'
                    )}
                  />
                )}
                fullWidth
                clearIcon={null}
              />
              {errors.cactIsExtemporaneous && touched.cactIsExtemporaneous && (
                <FormHelperText error>{errors.cactIsExtemporaneous}</FormHelperText>
              )}
            </Grid>
            <Grid item xs={6} sx={{ mt: 2 }}>
              <InputLabel>
                {t('teacherVirtualClassroom.tasks.table.input.title.intentos')}
              </InputLabel>
              <TextField
                id="button-action-search"
                fullWidth
                type="number"
                InputProps={{ inputProps: { min: 1 } }}
                variant="outlined"
                {...getFieldProps('actNumTries')}
              />
              {errors.actNumTries && touched.actNumTries && (
                <FormHelperText error>{errors.actNumTries}</FormHelperText>
              )}
            </Grid>
          </Grid>

          {/* Container dates */}
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={6} marginTop={2}>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                <DateTimePicker
                  renderInput={(props) => <TextField fullWidth {...props} helperText="" />}
                  loading={isLoadingCourseSelected}
                  minDate={courseSelected?.start_date ? parseISO(courseSelected?.start_date) : null}
                  maxDate={courseSelected?.end_date ? parseISO(courseSelected?.end_date) : null}
                  label={t('teacherVirtualClassroom.tasks.table.fechainicio.title')}
                  value={values.startDate}
                  onChange={(newValue: Date | null) => setFieldValue('startDate', newValue)}
                />
                {errors.startDate && touched.startDate && (
                  <FormHelperText error>{errors.startDate}</FormHelperText>
                )}
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6} marginTop={2}>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                <DateTimePicker
                  loading={isLoadingCourseSelected}
                  minDate={values.startDate || null}
                  minTime={values.startDate || null}
                  maxDate={courseSelected?.end_date ? parseISO(courseSelected?.end_date) : null}
                  renderInput={(props) => <TextField fullWidth {...props} helperText="" />}
                  label={t('teacherVirtualClassroom.tasks.table.fechafinal.title')}
                  value={values.endDate}
                  onChange={(newValue: Date | null) => setFieldValue('endDate', newValue)}
                />
                {errors.endDate && touched.endDate && (
                  <FormHelperText error>{errors.endDate}</FormHelperText>
                )}
              </LocalizationProvider>
            </Grid>
          </Grid>
        </React.Fragment>
      ),
    },
  ];

  const steps = [
    t('teacherVirtualClassroom.evaluation.steps.datos'),
    t('teacherVirtualClassroom.tasks.steps.estudiante'),
    t('teacherVirtualClassroom.tasks.steps.preguntas'),
    t('teacherVirtualClassroom.tasks.steps.Confirmacion'),
  ];

  //  const [step, setActiveStep] = React.useState(1);

  const handleBack = () => {
    dispatch(setBackStep(1));
  };

  const handleReset = () => {
    //handleResetChanges();
    dispatch(setBackStep(1));
  };

  const RenderOptionAddPoint = () => {
    return (
      <Grid container spacing={4} sx={{ mt: 1 }}>
        <Grid item xs={12}>
          <Button
            onClick={handleChoosePoint}
            startIcon={<IconPlus fontSize="0.875rem" />}
            style={{ textTransform: 'unset' }}
            variant="text"
            size="small"
            color="primary"
          >
            {t('teacherVirtualClassroom.tests.preCreatedAddScore')}
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <form>
      <React.Fragment>
        <Grid component={MainCard} sx={{ mt: 1 }}>
          <Box sx={{ width: '100%' }}>
            <Stepper activeStep={StepEdit}>
              {steps.map((label) => {
                const stepProps: { completed?: boolean } = {};
                const labelProps: {
                  optional?: React.ReactNode;
                } = {};

                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </Box>
        </Grid>
      </React.Fragment>
      <Grid container spacing={1} alignItems="flex-start" justifyContent={'center'}>
        <Grid item xs={12} md={6} container spacing={1}>
          {infoEvaluation && <EvaluationHeaderInfoCreate />}
          <Grid item xs={12}>
            <Grid item xs={12} sx={{ mb: 2 }}>
              {isError && showAlert && (
                <ErrorAlert message={error as string} handleDismiss={() => setShowAlert(false)} />
              )}

              {isSuccess && showAlert && (
                <SuccessAlert
                  message={"t('alerts.evaluation')"}
                  handleDismiss={() => setShowAlert(false)}
                />
              )}

              {editedInfoForm && (
                <SuccessAlert
                  message="Información del test editado."
                  handleDismiss={() => setEditedInfoForm(false)}
                />
              )}
              {questionAdded && (
                <SuccessAlert
                  message={t('alerts.evaluation.alert.succes')}
                  handleDismiss={() => {
                    dispatch(setQuestionAdded(false));
                  }}
                />
              )}
            </Grid>
            {StepEdit === 0 && (
              <MainCard>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.visible_note === '1'}
                        onChange={(e) =>
                          e.target.checked
                            ? setFieldValue('visible_note', '1')
                            : setFieldValue('visible_note', '0')
                        }
                      />
                    }
                    label="Dejar ver la Nota ?"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.questions_random === '1'}
                        onChange={(e) =>
                          e.target.checked
                            ? setFieldValue('questions_random', '1')
                            : setFieldValue('questions_random', '0')
                        }
                      />
                    }
                    label="Preguntas en Aletorio ? "
                  />
                </FormGroup>
                <Accordion data={dataInfoEvaluation} />
              </MainCard>
            )}
          </Grid>
        </Grid>
      </Grid>
      {StepEdit === 3 && (
        <MainCard title={t('teacherVirtualClassroom.tests.preCreateResumen')}>
          <Grid style={{ height: '50vh', overflowY: 'scroll' }}>
            <Accordion data={dataInfoEvaluation} />
          </Grid>
          <EvaluationQuestionAddedPreview />
        </MainCard>
      )}
      {/* Se enviará al componente de seleccionar de respuesta, 
            el componente determina el tipo de pregunta*/}
      {/* Edición de una pregunta*/}
      {StepEdit === 1 && (
        <Grid item xs={12} sm={12} container spacing={2}>
          <Grid item xs={12} md={12} sx={{ maxHeight: 600, overflow: 'hidden', marginTop: 3 }}>
            <PerfectScrollbar component="div">
              <TableContainer>
                <InputLabel>
                  {t('teacherVirtualClassroom.tests.preCreatedStudentsListLabel')}
                </InputLabel>
                <Table style={{ marginTop: 10 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell key={'headname'}>
                        {t('teacherVirtualClassroom.tests.testHeaderStudent')}
                      </TableCell>
                      <TableCell align="center" key={'headOptions'}>
                        {t('teacherVirtualClassroom.tests.listHeaderOptions')}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isLoadingStudents ? renderSkeletonRows(3, 3) : renderStudents()}
                  </TableBody>
                </Table>
              </TableContainer>
            </PerfectScrollbar>
          </Grid>
        </Grid>
      )}
      {StepEdit === 2 && (
        <Grid container spacing={4} justifyContent="center" alignItems="center">
          <Grid item xs={12} md={6} justifyContent="center" container spacing={1}>
            <Grid item xs={12}>
              <MainCard xs={12}>
                {copy && (
                  <Grid item xs={12} sx={{ mb: 2 }}>
                    <SuccessAlert
                      message={t('alerts.evaluation.alert.copy:')}
                      handleDismiss={() => setCopy(false)}
                    />
                  </Grid>
                )}

                {questionRemoved && (
                  <Grid item xs={12} sx={{ mb: 2 }}>
                    <SuccessAlert
                      message={t('alerts.evaluation.alert.remove:')}
                      handleDismiss={() => setQuestionRemoved(false)}
                    />
                  </Grid>
                )}

                {pointInvalid && (
                  <Grid item xs={12} sx={{ mb: 2 }}>
                    <ErrorAlert
                      message={t('alerts.evaluation.alert.error:')}
                      handleDismiss={() => setPointInvalid(false)}
                    />
                  </Grid>
                )}

                <Grid item textAlign={'center'} xs={12} sx={{ mb: 1 }}>
                  <TextField
                    fullWidth
                    error={Boolean(errors.question && touched.question)}
                    placeholder={t('teacherVirtualClassroom.tests.preCreatedAddDescription')}
                    {...getFieldProps('question')}
                  />
                </Grid>

                {/* Se muestra opciones de selección de puntos
                                cuando la pergunta es de tipo comentario.
                                Preguntas de tipo comentario no contiene opciones*/}
                {values.question && typeQuestion === 'comentary' && <RenderOptionAddPoint />}

                {/* Se muestra opciones de agregar campos de texto como opciones
                                cuando la pregunta es de tipo múltiple o selección.
                                Preguntas de tipo comentario no contiene opciones*/}
                {values.question && typeQuestion !== 'comentary' && (
                  <Grid container spacing={4}>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      container
                      spacing={1}
                      justifyContent="space-between"
                      alignItems="flex-end"
                    >
                      {optionsList.map(({ option }, index) => (
                        <React.Fragment key={index}>
                          <Grid item xs={6} sx={{ mt: 2 }}>
                            <TextField
                              name={`question-${index.toString()}`}
                              variant="standard"
                              fullWidth
                              onChange={(e) => handleInputChange(e, index)}
                              placeholder={t('teacherVirtualClassroom.tests.listHeaderOptions')}
                            />
                          </Grid>
                          <Grid item>
                            <IconTrash
                              size={20}
                              onClick={() => handleRemove(index)}
                              style={{ cursor: 'pointer' }}
                            />
                          </Grid>
                        </React.Fragment>
                      ))}
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        onClick={handleAdd}
                        startIcon={<IconPlus fontSize="0.875rem" />}
                        style={{ textTransform: 'unset' }}
                        variant="text"
                        size="small"
                        color="primary"
                      >
                        {t('teacherVirtualClassroom.tests.listHeaderOptions.agregate')}
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </MainCard>
              <EvaluationQuestionAddedPreview />

              <Divider />
              <CardActions sx={{ justifyContent: 'space-between' }}>
                {typeQuestion !== 'comentary' && <RenderOptionsMultiple />}
              </CardActions>
            </Grid>
          </Grid>
          {!chooseAnswerEdit && choose && <EvaluationChooseAnswer />}

          {chooseAnswerEdit && <EvaluationEditQuestion />}

          <Grid item xs={12} md={12}>
            <Grid xs={12} justifyContent="center">
              {isLoadingTypeQuestion || isFetchingTypeQuestion ? (
                <>
                  <TextField
                    disabled
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <CircularProgress size={25} />
                        </InputAdornment>
                      ),
                    }}
                    placeholder={t('teacherVirtualClassroom.tests.loadingInfo')}
                  />
                </>
              ) : (
                <Grid display="flex" gap="5px" textAlign="center" justifyContent="center">
                  {dataTypeQuestion &&
                    dataTypeQuestion?.map((item) => {
                      return (
                        <Button
                          onClick={() => handleChangeFilter(item?.cat_name)}
                          style={{ textTransform: 'unset', borderRadius: 0 }}
                          variant={handleSelectCatalog() === item?.id ? 'contained' : 'outlined'}
                        >
                          {item?.cat_name}
                        </Button>
                      );
                    })}
                </Grid>

                /*
                <Autocomplete
                  options={dataTypeQuestion! || []}
                  loading={isLoadingTypeQuestion}
                  loadingText={t('teacherVirtualClassroom.tests.listHeaderOptions.loading')}
                  value={dataTypeQuestion?.find((e) => e?.cat_name! === option)}
                  getOptionLabel={(option) => option.cat_name}
                  onChange={(_, value) => handleChangeFilter(value?.cat_name!)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={t('teacherVirtualClassroom.tasks.grupo.table.seleccionar')}
                    />
                  )}
                  fullWidth
                  clearIcon={null}
                />*/
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
      <React.Fragment>
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
          <Grid container justifyContent={'center'}>
            {!(StepEdit === steps.length) ? (
              <>
                <Button disabled={StepEdit === 1} variant="outlined" onClick={handleBack}>
                  {t('teacherVirtualClassroom.tasks.compleinfo.button.regresar')}
                </Button>
                <Button
                  variant="contained"
                  style={{ margin: '0 15px' }}
                  onClick={() => handleSubmit()}
                >
                  {StepEdit === steps.length - 1
                    ? t('teacherVirtualClassroom.tasks.questions.button.finish')
                    : t('teacherVirtualClassroom.tasks.compleinfo.button.title')}
                </Button>
              </>
            ) : (
              /*            disabled={
                    sumPointsEvaluation() !== infoEvaluation?.actScoreActivity! || isLoading
                  } */
              <React.Fragment>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Box sx={{ flex: '1 1 auto' }} />
                  <LoadingButton
                    loading={isLoading}
                    variant="contained"
                    style={{ margin: '0 15px' }}
                    onClick={() => CrearEvaluacion()}
                    disabled={
                      sumPointsEvaluation() !== infoEvaluation?.actScoreActivity! || isLoading
                    }
                    loadingIndicator="Subiendo evaluación ... "
                  >
                    {t('teacherVirtualClassroom.tests.preCreatedQuestionEnd')}
                  </LoadingButton>
                  <Button variant="outlined" onClick={handleReset}>
                    {t('teacherVirtualClassroom.tasks.compleinfo.button.regresar')}
                  </Button>
                </Box>
              </React.Fragment>
            )}
          </Grid>
        </Box>
      </React.Fragment>
    </form>
  );
};

export default PreCreatedQuestionOptions;
