import React from 'react';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

// project imports
import MainCard from '../../../shared/components/cards/MainCard';
import SkeletonEarningCard from '../../../shared/components/cards/Skeleton/EarningCard';
// assets

// style constant
const useStyles = makeStyles((theme) => ({
  card: {
    color: theme.palette.primary.main,
    overflow: 'hidden',
    position: 'relative',
  },
  content: {
    backgroundColor: theme.palette.primary.light,
    padding: '20px !important',
    boxShadow: '0px 0px 15px #BFDAF9',
    margin: '20px',
    borderRadius: '10px',
    // border: `${theme.palette.primary.main} 1px solid`,
  },
  avatar: {
    ...theme.typography.commonAvatar,
    ...theme.typography.largeAvatar,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary[800],
    marginTop: '8px',
  },
  avatarRight: {
    ...theme.typography.commonAvatar,
    ...theme.typography.mediumAvatar,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.dark.dark : theme.palette.secondary.dark,
    color: theme.palette.secondary[200],
    zIndex: 1,
  },
  cardHeading: {
    fontSize: '1.5rem',
    fontWeight: 300,
    marginRight: '8px',
    marginTop: '14px',
    marginBottom: '6px',
  },
  cardHeadingLite: {
    fontSize: '1.2rem',
    fontWeight: 300,
    marginRight: '8px',
    marginTop: '5px',
    marginBottom: '5px',
  },
  subHeading: {
    fontSize: '0.85rem',
    fontWeight: 300,
    color: theme.palette.primary.main,
  },
  avatarCircle: {
    cursor: 'pointer',
    ...theme.typography.smallAvatar,
    backgroundColor: theme.palette.secondary[200],
    color: theme.palette.secondary.dark,
  },
  circleIcon: {
    transform: 'rotate3d(1, 1, 1, 45deg)',
  },
  menuItem: {
    marginRight: '14px',
    fontSize: '1.25rem',
  },
}));

//===========================|| DASHBOARD DEFAULT - EARNING CARD ||===========================//

export interface EarningCardProps {
  isLoading: boolean;
  name: string;
}

const HourhandCard = ({ isLoading, name }: EarningCardProps) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      {isLoading ? (
        <SkeletonEarningCard />
      ) : (
        <MainCard border={false} className={classes.card} contentClass={classes.content}>
          <Grid container direction="column">
            <Grid item>
              <Grid container alignItems="center">
                <Grid item>
                  <Typography className={classes.cardHeadingLite}>{name}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              sx={{
                mb: 1.25,
                backgroundColor: 'white',
                borderRadius: '10px',
                minHeight: '50px',
                padding: '10px',
              }}
            >
              <Typography className={classes.subHeading}>
                <strong>4:00 - 15:00</strong>
                {'\u00A0'}Materia test
              </Typography>
              <Typography className={classes.subHeading}>
                <strong>4:00 - 15:00</strong>
              </Typography>
            </Grid>
          </Grid>
        </MainCard>
      )}
    </React.Fragment>
  );
};

export default HourhandCard;
