import { useTranslation } from 'react-i18next';

import DeleteAlert from '../../../shared/components/dialogs/DeleteAlert';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { useUnlinkUserCashierMutation } from '../slices/financialConfigurationApiSlice';
import { resetUserIdToUnlink, setDeleteModalOpen } from '../slices/financialConfigurationSlice';

const UnlinkUserDialog = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { userIdToUnlink, deleteModalOpen } = useAppSelector(
    (state) => state.financialConfigurations
  );
  const { currentProfile } = useAppSelector((state) => state.access);

  const [unlinkUser, { isLoading, isSuccess, isError, error }] = useUnlinkUserCashierMutation();

  const handleDelete = () => {
    unlinkUser({
      profileId: currentProfile?.profileId!,
      userId: userIdToUnlink!,
    });
  };

  const handleClose = () => {
    dispatch(setDeleteModalOpen(false));
    dispatch(resetUserIdToUnlink());
  };

  const deleteTitle = t('financial.emission_point.unlink_user_dialog.title');
  const deleteSubTitle = t('financial.emission_point.unlink_user_dialog.body');
  const deleteSuccess = t('financial.emission_point.unlink_user_dialog.success');

  return (
    <DeleteAlert
      open={deleteModalOpen}
      onClose={handleClose}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      handleDelete={handleDelete}
      deleteTitle={deleteTitle}
      deleteSubTitle={deleteSubTitle}
      deleteSuccess={deleteSuccess}
      error={error as string}
    />
  );
};

export default UnlinkUserDialog;
