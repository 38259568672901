import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/core/Autocomplete';
import Grid from '@material-ui/core/Grid';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DateRange } from '@material-ui/lab/DateRangePicker';

import InputLabel from '../../../../../../shared/components/forms/InputLabel';
import { ProjectStepsPayload, Project } from '../../../../interfaces/projectResearch.interfaces';
import {
  GeneralDetailsSchema,
  GeneralDetailsSchemaPrincipal,
} from '../../../../constants/wizard.validationSchemas';
import typeProject, {
  investigationType,
  vinculationType,
} from '../../../../constants/lists/typeProject';
import { Reseteable } from '.';
import LocalizedDateRangePicker from '../../../../../../shared/components/controlled/LocalizedDateRangePicker';
import { Switch } from '@material-ui/core';

interface Props extends Reseteable {
  setData: React.Dispatch<React.SetStateAction<ProjectStepsPayload>>;
  setNext: React.Dispatch<React.SetStateAction<number>>;
  isEdit?: boolean;
  dataEdit?: Project;
  isPrincipal?: boolean;
  projectType?: typeof investigationType | typeof vinculationType;
}

const GeneralDetailsForm = ({
  setData,
  setNext,
  isEdit = false,
  dataEdit,
  isReset,
  isPrincipal,
  projectType,
}: Props) => {
  const { t } = useTranslation();

  const history = useHistory();

  const { projectId } = useParams<{ projectId?: string }>();

  const handleNext = () => {
    setNext((step) => step + 1);
  };

  const { getFieldProps, errors, touched, handleSubmit, setFieldValue, values, resetForm } =
    useFormik({
      initialValues: {
        name: isEdit ? dataEdit?.name || '' : '',
        typeProject: isEdit ? dataEdit?.type_project || '' : projectType || '',
        // programName: isEdit ? dataEdit?.program_name || '' : '',
        programGoal: isEdit ? dataEdit?.program_goal || '' : '',
        ///Principal
        startDatePrincipal: new Date(),
        endDatePrincipal: new Date(),
        hasManyStages: false,
      },
      validationSchema: isPrincipal ? GeneralDetailsSchemaPrincipal : GeneralDetailsSchema,
      onSubmit: async ({
        name,
        programGoal,
        // programName,
        typeProject,
        hasManyStages,
        endDatePrincipal,
        startDatePrincipal,
      }) => {
        setData((data) => ({
          ...data,
          generalDetails: {
            name,
            type_project: typeProject,
            program_goal: programGoal,
            // program_name: programName,
            ///Is principal
            has_many_stages: hasManyStages,
            project_research_principal_id: projectId || null,
            ...(isPrincipal && {
              end_date_principal: endDatePrincipal,
              start_date_principal: startDatePrincipal,
            }),
          },
        }));

        handleNext();
      },
    });

  const onChangeDates = (newDatesValues: DateRange<Date>) => {
    const [start, end] = newDatesValues;
    setFieldValue('startDatePrincipal', start);
    setFieldValue('endDatePrincipal', end);
  };

  useEffect(() => {
    if (isReset) {
      resetForm();
    }
  }, [isReset, resetForm]);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction="column">
        <Grid item container xs={12} md={8} spacing={2}>
          {isPrincipal && (
            <>
              <Grid item xs={6}>
                <InputLabel>¿Tiene etapas?</InputLabel>
                <Switch checked={values.hasManyStages} {...getFieldProps('hasManyStages')} />
              </Grid>
              <Grid item xs={12}>
                <LocalizedDateRangePicker
                  hideLabel
                  showInputLabel
                  value={[values.startDatePrincipal, values.endDatePrincipal]}
                  onChange={onChangeDates}
                />
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <InputLabel>{t('project.GeneralDetailForm.name.placeholder')}</InputLabel>
            <TextField
              {...getFieldProps('name')}
              error={Boolean(errors.name && touched.name)}
              placeholder={t('academic.subjects.add.name.placeholder')}
              fullWidth
            />
            {errors.name && touched.name && <FormHelperText error>{t(errors.name)}</FormHelperText>}
          </Grid>

          <Grid item xs={12} {...(!!projectType && { hidden: true })} /*  md={6} */>
            <InputLabel> {t('project.GeneralDetailForm.typeProject.title')} </InputLabel>
            <Autocomplete
              options={typeProject}
              disabled={!!projectType}
              loadingText={t('academic.subjects.add.edu_level.loading')}
              value={typeProject.find(({ keyword }) => keyword === values.typeProject) || null}
              noOptionsText={t('project.GeneralDetailForm.typeProject.no_option ')}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(errors.typeProject && touched.typeProject)}
                  placeholder={t('project.GeneralDetailForm.typeProject.placeholder')}
                />
              )}
              onChange={(_, value) => {
                setFieldValue('typeProject', value?.keyword);
              }}
              fullWidth
              clearIcon={null}
            />
            {errors.typeProject && touched.typeProject && (
              <FormHelperText error>{errors.typeProject}</FormHelperText>
            )}
          </Grid>

          {/* <Grid item xs={12} md={6}>
            <InputLabel> {t('project.GeneralDetailForm.programName.title')} </InputLabel>
            <TextField
              {...getFieldProps('programName')}
              error={Boolean(errors.programName && touched.programName)}
              placeholder={t('project.GeneralDetailForm.programName.placeholder')}
              fullWidth
            />
            {errors.programName && touched.programName && (
              <FormHelperText error>{t(errors.programName)}</FormHelperText>
            )}
          </Grid> */}

          <Grid item xs={12}>
            <InputLabel> {t('project.GeneralDetailForm.programGoal.title')} </InputLabel>
            <TextField
              {...getFieldProps('programGoal')}
              error={Boolean(errors.programGoal && touched.programGoal)}
              placeholder={t('project.GeneralDetailForm.programGoal.placeholder')}
              fullWidth
            />
            {errors.programGoal && touched.programGoal && (
              <FormHelperText error>{t(errors.programGoal)}</FormHelperText>
            )}
          </Grid>

          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            spacing={2}
            sx={{ mt: 6 }}
          >
            <Grid item xs={6}>
              {false && (
                <Button
                  fullWidth
                  variant="outlined"
                  size="large"
                  color="primary"
                  onClick={() => history.goBack()}
                >
                  {t('academic.subjects.add.back')}
                </Button>
              )}
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                type="submit"
                variant="contained"
                size="large"
                color="primary"
                // disabled={isLoading}
                // endIcon={isLoading && <CircularProgress size={20} />}
              >
                {t('lines_research.researchGroupTable.next')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default GeneralDetailsForm;
