import { Switch, Redirect } from 'react-router-dom';
import { useAppSelector } from '../../../../hooks/useRedux';
import MattersScreen from '../screens/MattersScreen';
import AddMatterScreen from '../screens/AddMatterScreen';
import EditMatterScreen from '../screens/EditMatterScreen';
import GuardRoute from '../../../../router/guards/GuardRoute';
import { subPermissionKeys } from '../constants/permissions';

const { groupKey: permissionKey } = subPermissionKeys;

function MattersRouter() {
  // Verify permission
  const { currentModule } = useAppSelector((state) => state.access);
  const hasPermission = permissionKey in currentModule?.permissions!;

  if (!hasPermission) return <Redirect to="/dashboard" />;

  return (
    <Switch>
      <GuardRoute
        exact
        path="/administrar-materias"
        component={MattersScreen}
        subPermissionKey={subPermissionKeys.read}
        permissionKey={permissionKey}
      />
      <GuardRoute
        exact
        path="/administrar-materias/agregar"
        component={AddMatterScreen}
        subPermissionKey={subPermissionKeys.create}
        permissionKey={permissionKey}
      />
      <GuardRoute
        exact
        path="/administrar-materias/:matterId/editar"
        component={EditMatterScreen}
        subPermissionKey={subPermissionKeys.edit}
        permissionKey={permissionKey}
      />
    </Switch>
  );
}

export default MattersRouter;
