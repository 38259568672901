import {
  Autocomplete,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useAppSelector } from '../../../../hooks/useRedux';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import { statusAvailables } from '../../../shared/constants/resourceStatus';
import { useFormStyles } from '../constants/styles';
import { ParallelsInfoSchema } from '../constants/validationSchemas';
import { useUpdateParallelMutation } from '../slices/parallelsApiSlice';
import { useTranslation } from 'react-i18next';

const EditParallelForm = () => {
  const { t } = useTranslation();

  const history = useHistory();
  const { parallelId } = useParams<{ parallelId: string }>();

  const { parallelList } = useAppSelector((state) => state.parallels);
  const { currentProfile } = useAppSelector((state) => state.access);

  const classes = useFormStyles();

  const [showAlerts, setShowAlerts] = useState(false);

  const [updateParallel, { isLoading: isUpdating, isSuccess, isError, error }] =
    useUpdateParallelMutation();

  const parallelToEdit = parallelList.find((p) => p.id === Number(parallelId));

  const { getFieldProps, errors, touched, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      name: parallelToEdit?.par_name || '',
      description: parallelToEdit?.par_description || '',
      acronym: parallelToEdit?.par_acronym || '',
      statusId: parallelToEdit?.status_id ? Number(parallelToEdit?.status_id) : 1,
    },
    validationSchema: ParallelsInfoSchema,
    onSubmit: async (values) => {
      await updateParallel({
        profileId: currentProfile?.profileId!,
        parallelId: parallelToEdit?.id!,
        parallelPayload: {
          par_name: values.name,
          par_description: values.description,
          par_acronym: values.acronym,
          status_id: values.statusId.toString(),
        },
      });

      setShowAlerts(true);
    },
  });

  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction="column" gap={2}>
        <Grid item>
          {isError && showAlerts && (
            <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
          )}

          {isSuccess && showAlerts && (
            <SuccessAlert
              message={t('academic.parallels.edit.success')}
              handleDismiss={() => setShowAlerts(false)}
            />
          )}
        </Grid>

        <Grid item container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>
              {t('academic.parallels.edit.name')}
            </InputLabel>
            <TextField
              fullWidth
              error={Boolean(errors.name && touched.name)}
              placeholder={t('academic.parallels.edit.name.placeholder')}
              {...getFieldProps('name')}
            />
            {errors.name && touched.name && <FormHelperText error>{t(errors.name)}</FormHelperText>}
          </Grid>
          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>
              {t('academic.parallels.edit.description')}
            </InputLabel>
            <TextField
              multiline
              rows={2}
              fullWidth
              error={Boolean(errors.description && touched.description)}
              placeholder={t('academic.parallels.edit.description.placeholder')}
              {...getFieldProps('description')}
            />
            {errors.description && touched.description && (
              <FormHelperText error>{t(errors.description)}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>
              {t('academic.parallels.edit.acronym')}
            </InputLabel>
            <TextField
              multiline
              rows={2}
              fullWidth
              error={Boolean(errors.acronym && touched.acronym)}
              placeholder={t('academic.parallels.edit.acronym.placeholder')}
              {...getFieldProps('acronym')}
            />
            {errors.acronym && touched.acronym && (
              <FormHelperText error>{t(errors.acronym)}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>
              {t('academic.parallels.edit.status')}
            </InputLabel>
            <Autocomplete
              options={statusAvailables}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField {...params} error={Boolean(errors.statusId && touched.statusId)} />
              )}
              value={statusAvailables.find((s) => s.id === getFieldProps('statusId').value)}
              onChange={(e, value) => setFieldValue('statusId', value?.id || '')}
              fullWidth
              clearIcon={null}
            />
            {errors.statusId && touched.statusId && (
              <FormHelperText error>{t(errors.statusId)}</FormHelperText>
            )}
          </Grid>
        </Grid>

        <Grid
          item
          container
          alignItems="center"
          justifyContent="flex-end"
          spacing={2}
          sx={{ mt: 1 }}
        >
          <Grid item>
            <Button
              variant="outlined"
              size="large"
              color="primary"
              onClick={() => history.goBack()}
            >
              {t('academic.parallels.edit.back')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              disabled={isUpdating}
              endIcon={isUpdating && <CircularProgress size={20} />}
            >
              {t('academic.parallels.edit.submit')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default EditParallelForm;
