import { useMemo, useState } from 'react';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Stack from '@material-ui/core/Stack';
import Button from '@material-ui/core/Button';
import useTheme from '@material-ui/core/styles/useTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { IconBook2, IconBulb, IconChevronRight } from '@tabler/icons';

import { useTranslation } from 'react-i18next';

import { format, parseISO } from 'date-fns';

import PerfectScrollbar from 'react-perfect-scrollbar';
import { renderSkeletonTable } from '../../../shared/helpers/render';
import SearchPaginatedTable from '../../../shared/components/tables/SearchPaginatedTable';
import { useGetStudentActivitiesNotPagedQuery } from '../slices/dashboardApiSlice';
import { useAppSelector } from '../../../../hooks/useRedux';
import useGoToVirtualClassroom from '../../academic-portfolio/hooks/useGoToVirtualClassroom';
import { CircularProgress } from '@material-ui/core';
import { useGoActivityMoodleMutation } from '../../../shared/slices/moodle';

// style constant
const useStyles = makeStyles((theme) => ({
  scrollRoot: {
    '& >div.ps__rail-y': {
      '& :hover': {
        backgroundColor: 'black',
      },
    },

    '& >div.ps__rail-y > div.ps__thumb-y': {
      backgroundColor: theme.palette.primary[200],
      '& :hover': {
        backgroundColor: 'black',
      },
    },
    // '& .scrollbar-container .ps__rail-y:hover > .ps__thumb-y, .scrollbar-container .ps__rail-y:focus > .ps__thumb-y, .scrollbar-container .ps__rail-y.ps--clicking .ps__thumb-y':
    //   { backgroundColor: 'red' },
  },
}));

// const DUMMY_DATA = [
//   {
//     id: 1,
//     name: 'Proyecto integrador de sistemas',
//     datail1: '1300.00',
//     date: '1300.00',
//   },
// ];

// const generateDummyData = () => {
//   const data: typeof DUMMY_DATA = [];

//   for (let index = 0; index < 10; index++) {
//     data.push({
//       id: index,
//       name: 'Proyecto integrador de sistemas',
//       datail1: '1300.00',
//       date: '1300.00',
//     });
//   }

//   return data;
// };

const WeekActivities = () => {
  const theme = useTheme();
  const classes = useStyles();

  const { t } = useTranslation();

  const [valueCourseId, setValueCourseId] = useState(0);
  const [activityId, setActivityId] = useState<number>(0);
  // console.log('activityId', activityId);

  const { userInfo } = useAppSelector((state) => state.auth);

  const { isFetching: isFetchingGoToAct } = useGoToVirtualClassroom(valueCourseId, 'tareas');

  const { currentStudent: { id: studentId } = { id: undefined } } = useAppSelector(
    (state) => state.access
  );

  const [getMoodleActivity, { isLoading: isLoadingMoodle }] = useGoActivityMoodleMutation();

  const { data, isFetching, error } = useGetStudentActivitiesNotPagedQuery(
    {
      studentId: studentId!,
    },
    { skip: !studentId }
  );

  const datafetch = useMemo(
    () =>
      data?.map((activity, i) => {
        return {
          item: i + 1,
          detail: activity.act_description,
          credits: activity.act_score_activity,
          date: {
            start: format(parseISO(activity.cact_start_date), '	dd MMM - HH:mm'),
            end: activity.cact_end_date
              ? format(parseISO(activity.cact_end_date), '	dd MMM - HH:mm')
              : '',
          },
          options: activity,
        };
      }) || [],
    [data]
  );

  const goToActivity = async (
    courseId: number,
    activityId: number,
    instance?: number | null,
    modulename?: string | null
  ) => {
    setActivityId(activityId);

    if (instance && modulename) {
      try {
        const moodleActivity = await getMoodleActivity({
          instance,
          modulename,
          userId: userInfo?.id!,
        }).unwrap();

        window.open(moodleActivity);
      } finally {
        // catch (error) {
        // console.log('F');
        // }
        return;
      }
    }

    setValueCourseId(courseId);

    return;
  };

  return (
    <Box
      sx={{
        // border: `1px solid ${theme.palette.primary[200]}`,
        padding: 2,
        height: '100%',
        borderRadius: '12px',
      }}
    >
      <Typography component="h3" variant="h3">
        {t('students.dashboard.week-activities.title')}
      </Typography>

      <>
        <Grid container pt={2} pb={1}>
          <Grid item xs={12} sx={{ maxHeight: 300, overflow: 'hidden' }}>
            <PerfectScrollbar component={'div'} className={classes.scrollRoot}>
              {isFetching ? (
                renderSkeletonTable(5, 4)
              ) : (
                <SearchPaginatedTable
                  data={datafetch!}
                  headers={{
                    item: '',
                    detail: '',
                    credits: '',
                    date: '',
                    options: '',
                  }}
                  keyExtractor={(item) => item.options?.activity_id!.toString()}
                  searchPlacehoder="Buscar período"
                  isLoading={isFetching}
                  isFetching={isFetching}
                  error={error}
                  perPage={4}
                  setPerPage={undefined}
                  total={datafetch.length || 0}
                  page={1}
                  setPage={undefined}
                  numHeader={4}
                  search={''}
                  showFilter={false}
                  setSearch={undefined}
                  perPageBox={false}
                  paginationBox={false}
                  customRenderers={{
                    detail: (row) => (
                      <Stack direction="row" spacing={1} alignItems="center">
                        {/* <Box
                          sx={{
                            p: 1,
                            backgroundColor: theme.palette.grey[100],
                            borderRadius: '50%',
                          }}
                        > */}
                        <Box
                          display="flex"
                          alignItems="center"
                          borderRadius="50%"
                          p="5px"
                          sx={{ backgroundColor: `${theme.palette.grey[200]}` }}
                        >
                          <IconBook2 size={20} />
                        </Box>
                        {/* </Box> */}
                        <Typography>{row.detail}</Typography>
                      </Stack>
                    ),
                    credits: (row) => (
                      <Stack direction="row" spacing={1} alignItems="center">
                        {/* <Box
                          sx={{
                            p: 1,
                            backgroundColor: theme.palette.grey[100],
                            borderRadius: '50%',
                          }}
                        > */}
                        <Box
                          display="flex"
                          alignItems="center"
                          // sx={{ backgroundColor: `${theme.palette.grey[200]}` }}
                        >
                          <IconBulb color={theme.palette.secondary.main} size={20} />
                        </Box>
                        {/* </Box> */}
                        <Typography>{row.credits}</Typography>
                      </Stack>
                    ),
                    date: (row) => (
                      <Stack direction="row" spacing={1} alignItems="center">
                        <Typography>
                          <strong>{t('students.dashboard.week-activities.start')}</strong>
                          {row.date.start}{' '}
                          <strong>{t('students.dashboard.week-activities.end')}</strong>
                          {row.date.end}
                        </Typography>
                      </Stack>
                    ),
                    options: (row) => (
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        sx={{ borderRadius: '8px' }}
                        disabled={isFetchingGoToAct || isLoadingMoodle}
                        endIcon={
                          (isFetchingGoToAct || isLoadingMoodle) &&
                          activityId === Number(row.options.activity_id) ? (
                            <CircularProgress size={20} color="secondary" />
                          ) : (
                            <IconChevronRight />
                          )
                        }
                        onClick={() =>
                          goToActivity(
                            Number(row.options.course_id),
                            Number(row.options.activity_id),
                            row.options.instance,
                            row.options.modulename
                          )
                        }
                      >
                        {t('students.dashboard.week-activities.table_btn')}
                      </Button>
                    ),
                  }}
                  customHeadersCellsProperties={{
                    detail: {
                      align: 'center',
                    },
                    credits: {
                      align: 'center',
                    },
                  }}
                  customDataCellsProperties={{
                    detail: {
                      align: 'center',
                    },
                    credits: {
                      align: 'center',
                    },
                  }}
                  // ActionButtons={
                  //    <>{hasAccess('create') && <AddButton size="large" onClick={goToAdd} />}</>
                  // }
                />
              )}
            </PerfectScrollbar>
          </Grid>
        </Grid>
      </>
    </Box>
  );
};

export default WeekActivities;
