import { Chip } from '@material-ui/core';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { useTableSearch } from '../../../../hooks/useTableSearch';
import DeleteButton from '../../../shared/components/tables/buttons/DeleteButton';
import SearchPaginatedTable from '../../../shared/components/tables/SearchPaginatedTable';
import { useGetReservedBooksByUserQuery } from '../slices/userReservedBooksApiSlice';
import {
  openDeleteReserveBookDialog,
  updateReservedBooksTable,
} from '../slices/userReservedbooksSlice';

const UserReservedBooksTable = () => {
  const dispatch = useAppDispatch();

  const { userInfo } = useAppSelector((state) => state.auth);

  const { page, perPage, search, filterArrow } = useAppSelector(
    (state) => state.userReservedBooks.reservedBooksTable
  );

  const { setSearch, setPage, setPerPage, setFilterArrow } = useTableSearch({
    search,
    filterArrow,
    page,
    perPage,
    updateFunction: updateReservedBooksTable,
  });

  const { data, isLoading, isFetching, error } = useGetReservedBooksByUserQuery({
    userId: Number(userInfo?.id),
    page,
    perPage,
    search,
    ...(filterArrow && {
      sort: filterArrow?.name,
      typeSort: filterArrow?.direction,
    }),
  });

  const datafetch =
    data?.data?.map((item, index) => ({
      id: index + (page - 1) * perPage + 1,
      name: item.model_data.title,
      type: `${item.model_data.type_book} ${item.model_data.origin}`,
      reservedDate: item.reservation_date,
      availables: item.model_data.available_copies_number,
      status: item.reservation_status,
      options: item,
    })) || [];

  const handleDelete = (id: string) => {
    dispatch(openDeleteReserveBookDialog(id));
  };

  return (
    <SearchPaginatedTable
      data={datafetch}
      isLoading={isLoading}
      isFetching={isFetching}
      error={error}
      page={page}
      perPage={perPage}
      search={search}
      setSearch={setSearch}
      setPage={setPage}
      setPerPage={setPerPage}
      setFilterArrow={setFilterArrow}
      filterArrow={filterArrow}
      total={data?.total || 0}
      keyExtractor={(item) => item.id.toString()}
      numHeader={5}
      searchPlacehoder="Buscar"
      headers={{
        id: 'N°',
        name: 'Título',
        type: 'Tipo',
        reservedDate: 'Fecha de reserva',
        availables: 'Copias disponibles',
        status: 'Estado de reserva',
        options: 'Opciones',
      }}
      listFilterArrows={{
        reservedDate: 'reservation_date',
        status: 'reservation_status',
      }}
      customDataCellsProperties={{
        id: { align: 'center' },
        name: { align: 'center' },
        type: { align: 'center' },
        reservedDate: { align: 'center' },
        availables: { align: 'center' },
        status: { align: 'center' },
        options: { align: 'center' },
      }}
      customHeadersCellsProperties={{
        id: { align: 'center' },
        name: { align: 'center' },
        type: { align: 'center' },
        reservedDate: { align: 'center' },
        availables: { align: 'center' },
        status: { align: 'center' },
        options: { align: 'center' },
      }}
      customRenderers={{
        status: (value) => (
          <Chip
            label={value.status}
            // color={value.status === 'Pendiente' ? 'warning' : 'success'}
            sx={{
              backgroundColor: value.status === 'Pendiente' ? 'warning.main' : 'success.main',
              color: value.status === 'Pendiente' ? 'black' : 'white',
            }}
          />
        ),
        options: (value) => (
          <>
            <DeleteButton handleDelete={() => handleDelete(value.options.id)} />
          </>
        ),
      }}
    />
  );
};

export default UserReservedBooksTable;
