import { Autocomplete, FormHelperText, Grid, TextField } from '@material-ui/core';
import { useEffect, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../../hooks/useRedux';
import { useGetStudentRecordsByStudentQuery } from '../../../maintenance/students/slices/studentsApiSlice';
import InputLabel from '../../../shared/components/forms/InputLabel';
import { setStudentRecordAssignGrade } from '../slices/AssignGradeSlice';
import { useTranslation } from 'react-i18next';

const HeaderAssignGrades = () => {
  const { t } = useTranslation();

  const { studentId } = useParams<{ studentId: string }>();

  const { search } = useLocation();

  const params = new URLSearchParams(search);

  const currentRecordStudentId = useMemo(
    () => Number(params.get('eduLvlId')) || null,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const dispatch = useAppDispatch();
  //Carga el student record desde el admin
  const { data: studentRecordsAvaliables = [], isLoading: isLoadingER } =
    useGetStudentRecordsByStudentQuery(
      { studentId },
      { refetchOnMountOrArgChange: 30, skip: !studentId }
    );

  useEffect(() => {
    if (studentRecordsAvaliables && studentRecordsAvaliables.length === 1) {
      dispatch(setStudentRecordAssignGrade(studentRecordsAvaliables[0]));
    }
  }, [dispatch, studentRecordsAvaliables]);

  useEffect(() => {
    if (currentRecordStudentId) {
      dispatch(
        setStudentRecordAssignGrade(
          studentRecordsAvaliables.find((record) => record.id === currentRecordStudentId)
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRecordStudentId, studentRecordsAvaliables]);

  //console.log(studentRecordsAvaliables)
  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <InputLabel>{t('teachers.assign_grades.header.academic_unit')}</InputLabel>
        {studentRecordsAvaliables.length === 1 ? (
          <Autocomplete
            id="combo-box-demo"
            options={studentRecordsAvaliables}
            defaultValue={studentRecordsAvaliables[0]}
            getOptionLabel={(option) => option.education_level.edu_name}
            //style={{ width: 300 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('teachers.assign_grades.header.academic_unit')}
                variant="outlined"
              />
            )}
          />
        ) : (
          <>
            <Autocomplete
              options={studentRecordsAvaliables}
              loading={isLoadingER}
              loadingText={t('teachers.assign_grades.header.academic_unit_loading')}
              noOptionsText={t('teachers.assign_grades.header.academic_unit.no_data')}
              getOptionLabel={(option) => `${option.education_level.edu_name}`}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={t('teachers.assign_grades.header.academic_unit.placeholder')}
                />
              )}
              onChange={(_, value) => {
                dispatch(setStudentRecordAssignGrade(value || undefined));
              }}
            />
            <FormHelperText>
              {t('teachers.assign_grades.header.academic_unit.placeholder')}
            </FormHelperText>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default HeaderAssignGrades;
