export const subPermissionKeys = {
  groupKey: 'applications',

  read: 'application-listar-solicitudes',
  show: 'application-obtener-solicitudes',
  create: 'periods-crear-periodo',
  edit: 'periods-actualizar-periodo',
  delete: 'periods-borrar-periodo',
  hPerPeriod: 'periods-listar-horarios-por-periodo',
  createActa: 'applications-certificates-actApplication',
  readAssistants: 'collaborators-listar-colaborador-no-tienen-hora-asignada',
  readEducationLevel: 'offers-obtener-oferta',

  getEduLvls: 'education-levels-obtener-nivel-educativo',
  getAllStatusRoles: 'type-application-obtener-tipo-solicitudes-roles',
  generatePDF: 'applications-external-homologations-generar-pdf',

  changeApplicationStatus: 'applications-late-exam-change-status',
};

export const subPermissionCreateActa = 'applications-certificates-actApplication';
