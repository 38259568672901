import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IEvaluationType, EvaluationTypeState } from '../interfaces/evaluationType.interface';

const initialState: EvaluationTypeState = {
  evaluationTypeList: [],
  evaluationTypeIdSelected: null,
  evaluationTypeIdToDelete: null,
  evaluationTypeDeleteModalOpen: false,
  evaluationTypeSideDialogOpen: false,
  evaluationTypeTable: {
    page: 1,
    perPage: 5,
    search: '',
  },
  status:'A'
};

export const evaluationTypeSlice = createSlice({
  name: 'evaluationType',
  initialState,
  reducers: {
    setEvaluationTypeList: (state, action: PayloadAction<IEvaluationType[]>) => {
      state.evaluationTypeList = action.payload;
    },
    setEvaluationTypeIdSelected: (state, action: PayloadAction<string | null>) => {
      state.evaluationTypeIdSelected = action.payload;
    },
    setEvaluationTypeIdToDelete: (state, action: PayloadAction<string | null>) => {
      state.evaluationTypeIdToDelete = action.payload;
    },
    setEvaluationTypeSideDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.evaluationTypeSideDialogOpen = action.payload;
    },
    setEvaluationTypeDeleteModalOpen: (state, action: PayloadAction<boolean>) => {
      state.evaluationTypeDeleteModalOpen = action.payload;
    },
    setEvaluationTypeTable: (
      state,
      action: PayloadAction<{ page?: number; perPage?: number; search?: string }>,
    ) => {
      state.evaluationTypeTable = {
        ...state.evaluationTypeTable,
        ...action.payload,
      };
    },
    setStatusEvaluationType: (state, action: PayloadAction<string>) => {
      state.status = action.payload;
    },
  },
});

export const {
  setEvaluationTypeList,
  setEvaluationTypeIdSelected,
  setEvaluationTypeIdToDelete,
  setEvaluationTypeDeleteModalOpen,
  setEvaluationTypeSideDialogOpen,
  setEvaluationTypeTable,
  setStatusEvaluationType,
} = evaluationTypeSlice.actions;
