import * as Yup from 'yup';

export const collaboratorProjectResearch = Yup.object().shape({
  projectResearchId: Yup.string().required('La línea de investigacion es requerida'),
  collaboratorId: Yup.object()
    .typeError('El colaborador es requerido')
    .required('El colaborador es requerido'),
  status: Yup.string().required('El estado es requerido'),
  startDate: Yup.date().required('La fecha de inicio es requerido'),
  endDate: Yup.string().required('La fecha fin es requerida'),
  numberHours: Yup.number().required('El número de horas es requerido'),
  isResearcher: Yup.boolean().required('Es  es requerido'),
  typeFunction: Yup.string().required('El tipo de función es requerido'),
  // status: Yup.number()
  //   .integer('Solo se permiten números enteros.')
  //   .required('Debe seleccionar el estado del instituto.')
  //   .min(1, 'Debe seleccionar el estado del instituto.'),
});

export const studentProjectResearch = Yup.object().shape({
  projectResearchId: Yup.string().required('El proyecto es requerido'),
  studentId: Yup.object().required('El estudiante es requerido'),
  startDate: Yup.date().required('La fecha de inicio es requerido'),
  endDate: Yup.date().required('La fecha fin es requerido'),
  numberHours: Yup.number().required('El nombre es requerido'),
  // isResearcher: Yup.boolean().required('El nombre es requerido'),
  status: Yup.string().required('El nombre es requerido'),

  // typeFunction: Yup.string().required('El nombre es requerido'),
  // status: Yup.number()
  //   .integer('Solo se permiten números enteros.')
  //   .required('Debe seleccionar el estado del instituto.')
  //   .min(1, 'Debe seleccionar el estado del instituto.'),
});

export const projectPrincipal = Yup.object().shape({
  name: Yup.string().required('El nombre es requerido'),
  observation: Yup.string().required('La observación es requerido'),
  hasManyStages: Yup.boolean().required('Las estapas requerido'),
  statusProject: Yup.string().required('El estado del proyecto es requerido'),
  startDate: Yup.date().required('La fecha de inicio es requerido'),
  endDate: Yup.date().required('La fecha fin es requerido'),
  statusId: Yup.string().required('El nombre es requerido'),
});
