import { useState } from 'react';
import {
  Box,
  Button,
  Grid,
  Typography,
  useTheme,
  TextField,
  Autocomplete,
  FormHelperText,
} from '@material-ui/core';
import SubCard from '../../../../shared/components/cards/SubCard';
import IconTelework from '../../../../shared/components/illustrations/IconTelework';
import { IconCircleCheck } from '@tabler/icons';
import ErrorAlert from '../../../../shared/components/alerts/ErrorAlert';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import { setShowRegisterDebtsModal } from '../../slices/subjectsRegisterSlice';
import DialogConfirmRegister from './DialogConfirmRegister';
import useStudentInfo from '../../hooks/useStudentInfo';
import ReviewQuotasTable from './ReviewQuotasTable';
import {
  useGetPreviewFeesQuery,
  useGetDebtorsStudentQuery,
} from '../../slices/subjectsRegisterApiSlice';
import { useParams } from 'react-router-dom';
import TextFieldFetching from '../../../../shared/components/TextFieldFetching';

import { useTranslation } from 'react-i18next';

interface Props {
  isError: boolean;
  error: any;
}

const NoticePayment = ({ isError, error }: Props) => {
  const { t } = useTranslation();

  const { showRegisterDebtsModal, currentPeriodId } = useAppSelector(
    (state) => state.subjectsRegister
  );

  const { currentStudent } = useAppSelector((state) => state.access);
  const studentInfo = useStudentInfo();
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const { studentId } = useParams<{ studentId: string }>();

  const {
    data: previewData,
    error: errorPreviewData,
    isLoading: isLoadingPreviewData,
  } = useGetPreviewFeesQuery({
    period_id: currentPeriodId!,
    student_id: Number(studentId) || Number(currentStudent?.id),
    student_record: studentInfo?.studentRecordId!,
  });

  const {
    data: AvailablesDebtors,
    isLoading: isLoadingDebtors,
    error: errorDeudor,
  } = useGetDebtorsStudentQuery({
    studentId: Number(studentId) || Number(currentStudent?.id),
  });

  const [deudor, setDeudor] = useState<string>('');

  const handleSetDeudor = (deudors: string) => {
    setDeudor(deudors);
  };

  const errorArray = (error + '')?.split('|');

  // const codes = {
  //   externalHomologation: 'SOLTC-HOM-EXT',
  //   expiredPeriod: 'REG-STG-FIN',
  // };

  return (
    <Grid container item mt={3} justifyContent="center">
      <SubCard title={t('students.subjects.registration.important_warning')} darkTitle={true}>
        <Grid item>{isError && <ErrorAlert message={errorArray[0]} />}</Grid>
        <Box>
          <Grid container justifyContent="center" alignContent="center">
            <Grid container justifyContent="center" item xs={8} md={12} spacing={2}>
              <Grid container item xs={12} justifyContent="center" mt={2}>
                {!(studentInfo?.typeStudent === 'NIV') && errorArray.length === 1 ? (
                  <>
                    <Typography variant="body1" textAlign="center" lineHeight={2} component="div">
                      <Typography
                        variant="h3"
                        sx={{ color: theme.palette.primary.main }}
                        component="p"
                        align="center"
                        lineHeight={2}
                      >
                        {t('students.subjects.registration.warning')}
                      </Typography>
                      {t('students.subjects.registration.actual_period_register')}
                    </Typography>
                    <Grid item container xs={12} mt={1}>
                      <Grid item xs={12}>
                        <Typography
                          variant="body2"
                          sx={{ color: theme.palette.primary.main }}
                          align="right"
                          lineHeight={3}
                          textAlign="left"
                        >
                          {t('students.subjects.registration.defaulter')}
                          {/* <Typography variant="body1" textAlign="start" lineHeight={2} component="div"> */}
                        </Typography>
                      </Grid>

                      <Grid item container xs={12} md={4} mb={2}>
                        {isLoadingDebtors ? (
                          <TextFieldFetching variant="standard" />
                        ) : (
                          <>
                            <Autocomplete
                              loadingText={t('students.subjects.registration.defaulter.loading')}
                              noOptionsText={t('students.subjects.registration.defaulter.no_data')}
                              options={AvailablesDebtors || []}
                              loading={isLoadingDebtors}
                              getOptionLabel={(option) => option?.person?.pers_full_name}
                              disabled={AvailablesDebtors?.length! <= 1}
                              defaultValue={
                                AvailablesDebtors?.length === 1 ? AvailablesDebtors[0]! : undefined
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder={t(
                                    'students.subjects.registration.defaulter.placeholder'
                                  )}
                                  variant="standard"
                                />
                              )}
                              onChange={(e, value) => handleSetDeudor(value?.id.toString() || '')}
                              fullWidth
                            />
                            {errorDeudor && <FormHelperText error>{errorDeudor}</FormHelperText>}
                          </>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <ReviewQuotasTable
                          data={previewData!}
                          error={errorPreviewData as string}
                          isLoading={isLoadingPreviewData}
                        />
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <IconTelework size={300} />
                )}
              </Grid>
              {!(studentInfo?.typeStudent === 'NIV') && errorArray.length === 1 && (
                <>
                  {/* <Grid item>
                    <Typography variant="body1" textAlign="center" lineHeight={2} component="div">
                      <Typography
                        variant="h3"
                        sx={{ color: theme.palette.primary.main }}
                        component="p"
                        align="center"
                        lineHeight={2}
                      >
                        Advertencia
                      </Typography>
                      ¿Deseas registrarte en el <strong>período actual</strong>?
                    </Typography>
                  </Grid> */}

                  <Grid container item xs={12} justifyContent="center">
                    <Button
                      onClick={() => dispatch(setShowRegisterDebtsModal(true))}
                      variant="contained"
                      disabled={!deudor && AvailablesDebtors?.length! > 1}
                      startIcon={<IconCircleCheck size={20} />}
                    >
                      {t('students.subjects.registration.register')}
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Box>
      </SubCard>

      {showRegisterDebtsModal && (
        <DialogConfirmRegister
          open={true}
          onClose={() => dispatch(setShowRegisterDebtsModal(false))}
          debtorId={deudor}
        />
      )}
    </Grid>
  );
};

export default NoticePayment;
