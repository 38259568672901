import * as Yup from 'yup';

export const positionSchema = Yup.object().shape({
  posName: Yup.string().required('El nombre es requerido.'),
  posDescription: Yup.string(),
  roleId: Yup.number().required('El rol es requerido'),
  departmentId: Yup.number().required('El departamento es requerida'),
  posKeyword: Yup.string().required('La abreviatura es requerido'),
  statusId: Yup.number().required('El estado del permiso es requerido.'),
});
