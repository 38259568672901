import { useAppDispatch } from '../../../../hooks/useRedux';
import CustomSideDialog from '../../../shared/components/dialogs/CustomSideDialog';
import { setIsOpenModalAdd, setPcIdSelected } from '../slices/parameterCompanySlice';
import ParametersCompanyForm from './ParametersCompanyForm';

const AddParametersCompanyDialog = () => {
  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(setPcIdSelected(null));
    dispatch(setIsOpenModalAdd(false));
  };

  return (
    <CustomSideDialog open handleCloseDialog={handleClose} title="Agregar Parámetro de Compañía">
      <ParametersCompanyForm />
    </CustomSideDialog>
  );
};

export default AddParametersCompanyDialog;
