import {
  Autocomplete,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  TextField,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { ChangeEvent, useState } from 'react';
import useFileFormatValidation from '../../../../hooks/useFileFormatValidation';
import { useAppSelector } from '../../../../hooks/useRedux';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import InputLabel from '../../../shared/components/forms/InputLabel';
import {
  useAddMaterialsMutation,
  useGetDistributionsByCourseQuery,
} from '../slices/virtualClassroomTeacherApiSlice';
import { MaterialSchema } from './constants/validationSchema';

interface IntialValues {
  act_description: string;
  ref1_supply_id: string;
  file: File | null;
}
const AddMaterials = () => {
  const {
    course: { courseId },
  } = useAppSelector((state) => state.virtualClassroomTeacherShared);

  const { currentProfile } = useAppSelector((state) => state.access);

  const { validateFormatFile } = useFileFormatValidation();

  const [errorFile, setErrorFile] = useState<string>('');

  const [showAlerts, setShowAlerts] = useState(false);
  const [addMaterials, { isLoading, isError, isSuccess, error }] = useAddMaterialsMutation();

  const { data: distributionAvailables, isLoading: isLoadingDistribution } =
    useGetDistributionsByCourseQuery({
      profileId: currentProfile?.profileId!,
      courseId: courseId!.toString(),
    });
  if (!isLoadingDistribution) console.log(distributionAvailables);

  const { handleSubmit, errors, touched, setFieldValue, getFieldProps } = useFormik<IntialValues>({
    initialValues: {
      act_description: '',
      ref1_supply_id: '0',
      file: null,
    },
    validationSchema: MaterialSchema,
    //   validate: (values) => {
    //     const fileNotAllowed = 'Tipo de archivo no válido, ingresar un archivo con extensión pdf.';
    //     const fileNotFound = 'El archivo es requerido.';
    //     const errors: any = {};
    //     if (target) {
    //       let extension = target?.type;
    //       if (SUPPORT_TYPES_RM.indexOf(extension) < 0) {
    //         errors.files = fileNotAllowed;
    //       }
    //     } else {
    //       errors.files = fileNotFound;
    //     }
    //     return errors;
    //   },
    onSubmit: async (values) => {
      await addMaterials({
        courseId: courseId!,
        PayloadMaterials: {
          file: values.file!,
          description: values.act_description,
        },
      });
      setShowAlerts(true);
    },
  });

  const onChangeFile = function (event: ChangeEvent<HTMLInputElement>) {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      const error = validateFormatFile(file, '*');

      if (error !== '') {
        setErrorFile(error);
        return;
      }

      setFieldValue('file', event.target.files[0]);
      //   let reader = new FileReader();
      //   reader.onload = (e: any) => {
      //     setFile(e.target.result);
      //   };
      //   reader.readAsDataURL(event.target.files[0]);
      //   setTarget(event.target.files[0]);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item container>
          <Grid item>
            {isError && showAlerts && (
              <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
            )}

            {errorFile !== '' && (
              <ErrorAlert message={errorFile} handleDismiss={() => setErrorFile('')} />
            )}

            {isSuccess && showAlerts && (
              <SuccessAlert
                message="El material se agrego correctamente."
                handleDismiss={() => setShowAlerts(false)}
              />
            )}
          </Grid>
        </Grid>
        <Grid item container justifyContent={'left'} alignItems={'end'} spacing={2}>
          <Grid item container md={6} sm={12} spacing={1}>
            <InputLabel>Descripción</InputLabel>
            <TextField
              fullWidth
              placeholder="Ingrese descripción del documento"
              error={Boolean(errors.act_description && touched.act_description)}
              {...getFieldProps('act_description')}
            />
            {errors.act_description && touched.act_description && (
              <FormHelperText error>{errors.act_description}</FormHelperText>
            )}

            <InputLabel>Distribución</InputLabel>
            <Autocomplete
              options={distributionAvailables! || []}
              getOptionLabel={(option) => option.distribution}
              renderInput={(params) => (
                <TextField
                  // label="Seleccione una distribución"
                  {...params}
                  error={Boolean(errors.ref1_supply_id && touched.ref1_supply_id)}
                />
              )}
              onChange={(e, value) => setFieldValue('ref1_supply_id', value?.ref1_supply_id!)}
              fullWidth
              clearIcon={null}
              loading={isLoadingDistribution}
            />
            {errors.ref1_supply_id && touched.ref1_supply_id && (
              <FormHelperText error>{errors.ref1_supply_id}</FormHelperText>
            )}
          </Grid>
          <Grid item md={6} sm={12}>
            <InputLabel>Documento</InputLabel>
            <TextField
              fullWidth
              type="file"
              onChange={onChangeFile}
              // error={Boolean(errors.file && touched.file)}
              //   placeholder="Ingrese el acrónimo del modelo de calificación"
              // {...getFieldProps('file')}
            />
            {errors.file && touched.file && <FormHelperText error>{errors.file}</FormHelperText>}
          </Grid>
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              disabled={isLoading}
              endIcon={isLoading && <CircularProgress size={20} />}
            >
              Agregar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default AddMaterials;
