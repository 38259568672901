import { memo } from 'react';

import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@material-ui/lab';

import { BusTour, HandleEditTourI } from '../../interfaces/buses.interfaces';
import { Draggable } from 'react-beautiful-dnd';
import { Box, Grid, Stack, Typography, useTheme } from '@material-ui/core';
import EditButton from '../../../../shared/components/tables/buttons/EditButton';
import DeleteButton from '../../../../shared/components/tables/buttons/DeleteButton';

interface Props {
  tour: BusTour;
  index: number;
  droppableId: string;

  position: 'left' | 'right' | 'up';
  last: boolean;

  isLoading: boolean;

  handleDeleteTour: (droppableId: string, index: number) => void;
  handleOpenEdit: HandleEditTourI;
}

const Tour = ({
  tour,
  index,
  droppableId,

  position,
  last = false,
  isLoading = false,

  handleOpenEdit,
  handleDeleteTour,
}: Props) => {
  const theme = useTheme();
  const positioning: Partial<Record<typeof position, any>> = {
    left: <ArrowDownwardIcon />,
    right: <ArrowDownwardIcon />,
  };

  return (
    <Draggable draggableId={`drag-${tour.id}`} index={index}>
      {(provided, snapshot) => (
        <Box
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          sx={{
            width: position === 'up' ? '300px' : '100%',
            // border: '1px solid grey',
            marginBottom: '8px',
            backgroundColor: theme.palette.background.paper,
            boxShadow: snapshot.isDragging
              ? '0px 0px 8px 5px rgb(0 0 0 / 60%)'
              : '0px 0px 5px 0px rgb(0 0 0 / 34%)',
            padding: '8px',
          }}
        >
          {index !== 0 && !last && position !== 'up' && (
            <TimelineItem>
              <TimelineOppositeContent
                style={{ flex: '0.1', maxWidth: '1px', paddingLeft: '0px', paddingRight: '0px' }}
              ></TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot color="primary">
                  <LocationOnIcon />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  spacing={2}
                >
                  <Typography
                    variant="h4"
                    component="span"
                    // color={'secondary'}
                    marginX={1}
                  >
                    {tour.tour_name}
                  </Typography>

                  <Grid item>
                    <EditButton
                      disabled={isLoading}
                      handleEdit={() => handleOpenEdit(tour, droppableId, index)}
                    />

                    <DeleteButton
                      disabled={isLoading}
                      handleDelete={() => handleDeleteTour(droppableId, index)}
                    />
                  </Grid>
                </Stack>
              </TimelineContent>
            </TimelineItem>
          )}

          {(index === 0 || last || position === 'up') && (
            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
              <Typography
                variant="h3"
                color={'secondary'}
                // align={position === 'left' ? 'right' : 'left'}
                marginX={1}
                // component="span"
              >
                {!last && position === 'left' && positioning[position]}
                {tour.tour_name}
                {!last && position === 'right' && positioning[position]}
              </Typography>

              <Grid item>
                <EditButton
                  disabled={isLoading}
                  handleEdit={() => handleOpenEdit(tour, droppableId, index)}
                />

                <DeleteButton
                  disabled={isLoading}
                  handleDelete={() => handleDeleteTour(droppableId, index)}
                />
              </Grid>
            </Stack>
          )}
        </Box>
      )}
    </Draggable>
  );
};

const TourList = ({
  tours,
  droppableId,
  position,
  isLoading,
  handleOpenEdit,
  handleDeleteTour,
}: {
  tours: BusTour[];
  droppableId: string;

  position: 'left' | 'right' | 'up';
  isLoading: boolean;

  handleOpenEdit: HandleEditTourI;
  handleDeleteTour: (droppableId: string, index: number) => void;
}) => {
  return (
    <>
      {tours.map((tour, index) => {
        const isLast = index === tours.length - 1;

        return (
          <Tour
            tour={tour}
            index={index}
            key={tour.id}
            droppableId={droppableId}
            position={position}
            isLoading={isLoading}
            last={isLast}
            handleOpenEdit={handleOpenEdit}
            handleDeleteTour={handleDeleteTour}
          />
        );
      })}
    </>
  );
};

export default memo(TourList);
