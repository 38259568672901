import { Switch } from 'react-router-dom';
import AddPensumScreen from '../screens/AddPensumScreen';
import EditPensumScreen from '../screens/EditPensumScreen';
import PensumsScreen from '../screens/PensumsScreen';

import GuardRoute from '../../../../router/guards/GuardRoute';

import { permissionKey, subPermissionKeys } from '../constants/permissions';

const PensumsRouter = () => {
  return (
    <Switch>
      <GuardRoute
        exact
        path="/administrar-pensums"
        component={PensumsScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.read}
      />
      <GuardRoute
        exact
        path="/administrar-pensums/agregar"
        component={AddPensumScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.create}
      />
      <GuardRoute
        exact
        path="/administrar-pensums/:pensumId/editar"
        component={EditPensumScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.edit}
      />
    </Switch>
  );
};

export default PensumsRouter;
