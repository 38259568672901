import { Switch, Redirect } from 'react-router-dom';

import { useAppSelector } from '../../../../hooks/useRedux';

import CampusScreen from '../screens/CampusScreen';
import AddCampusScreen from '../screens/AddCampusScreen';
import EditCampusScreen from '../screens/EditCampusScreen';

import GuardRoute from '../../../../router/guards/GuardRoute';

import { subPermissionKeys } from '../constants/permissions';

const { groupKey: permissionKey } = subPermissionKeys;

const CampusRouter = () => {
  const { currentModule } = useAppSelector((state) => state.access);

  const hasPermission = permissionKey in currentModule?.permissions!;

  if (!hasPermission) return <Redirect to="/dashboard" />;

  return (
    <Switch>
      <GuardRoute
        exact
        path="/administrar-campus"
        component={CampusScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.read}
      />
      <GuardRoute
        exact
        path="/administrar-campus/agregar"
        component={AddCampusScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.create}
      />

      <GuardRoute
        exact
        path="/administrar-campus/:campusId/editar"
        component={EditCampusScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.edit}
      />
    </Switch>
  );
};

export default CampusRouter;
