import { Grid, Typography } from '@material-ui/core';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../hooks/useRedux';
import MainCard from '../../../shared/components/cards/MainCard';
import { setSidebarOpened } from '../../../shared/reducers/customizationReducer';
import PaidCertificatesTable from '../components/PaidCertificatesTable';
// import DebitCertificateDialog from '../components/DebitCertificateDialog';

const PaidCertificateScreen = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(setSidebarOpened(false));
  }, [dispatch]);

  const { t } = useTranslation();
  //   const { certificateToDebit } = useAppSelector((state) => state.certificate);
  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">{t('students.certificates.paid_certificates.title')}</Typography>
      </Grid>

      <PaidCertificatesTable />

      {/* {periodIdToDelete && <DeletePeriodDialog />} */}
    </Grid>
  );
};
export default PaidCertificateScreen;
