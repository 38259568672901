import { useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import {
  Autocomplete,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { EvaRubricaDetail, FormsPayload } from '../interfaces/formsPayload.interface';
import {
  useAddFormMutation,
  useGetTypeDocumentListAllQuery,
  useLazyGetCoursesListAllQuery,
  useLazyGetFormByIdQuery,
} from '../slices/formsApiSlice';
import SuccessAlert from '../../../../../shared/components/alerts/SuccessAlert';
import ErrorAlert from '../../../../../shared/components/alerts/ErrorAlert';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../../../hooks/useRedux';
import { useGetAllIntegralEvaluationsQuery } from '../../integral_evaluation/slices/integralEvaluationApiSlice';
import {
  useGetRepresentativeScaleAllQuery,
  useLazyGetRubricByIDQuery,
} from '../../options_template/slices/optionsTemplateApiSlice';
import { CourseTeacher } from '../interfaces/coursesListAll.interface';
import { RubricData } from '../../integral_evaluation/interfaces/rubrics.interface';
import DragArea from '../../../../../shared/components/files/DragArea';
import { useUploadFilesMutation } from '../../../../../shared/slices/uploadFilesApi';
import { EvaluationScaleDetail } from '../../options_template/interfaces/scale.interface';
import {
  useGetAcademicOfferingQuery,
  useGetAvailablesOffersQuery,
  useGetPeriodAcademicOfferingQuery,
} from '../../../../../maintenance/academic-offerings/slices/academicOfferingsApiSlice';
import TextFieldFetching from '../../../../../shared/components/TextFieldFetching';
import { Period } from '../../../../../maintenance/academic-offerings/interfaces/periods.interface';
import { formAddSchema } from '../validation/formAddSchema.validation';
import { useLazyGetAlLCollaboratorByEducationLevelIdByOfferIdQuery } from '../../../../../hr/collaborators/slices/collaboratorsApiSlice';
import useGetUrlDownload from '../../../../../../hooks/useGetUrlDownload';
import { FileDownload } from '@material-ui/icons';

const FormsAdd = () => {
  const [showAlerts, setShowAlerts] = useState(false);
  const [educationLevelId, setEducationLevelId] = useState<string>('');
  const [file, setFile] = useState<
    {
      file: File | null;
      eva_form_tip_doc_id: string | number;
    }[]
  >([]);
  const theme = useTheme();

  const { id } = useParams<{
    id: string;
  }>();

  const [getFormID, { data: formById }] = useLazyGetFormByIdQuery();

  const [offerDescription, setOff_description] = useState('');

  const { t } = useTranslation();

  const [evaluated, setEvaluated] = useState<CourseTeacher[]>([]);

  const [results, setResults] = useState<number[]>([]);

  const [evaluationScaleDetail, setEvaluationScaleDetail] = useState<EvaluationScaleDetail[]>([]);

  const [ponderation, setPonderation] = useState<number | null>(null);

  const [escalaSelected, setEscalaSelected] = useState('');

  const [evaTypes, setEvaTypes] = useState<RubricData>();

  const { currentProfile } = useAppSelector((state) => state.access);

  //filters

  const [getCollaboratorsAll, { data: collaboratorAll, isLoading: isLoadingCollaboratorsAll }] =
    useLazyGetAlLCollaboratorByEducationLevelIdByOfferIdQuery();

  //niveles eduactivos
  const {
    data: offersAvailables = [],
    isLoading: isLoadingPID,
    isFetching: isFetchingPID,
  } = useGetAvailablesOffersQuery(
    {
      profileId: currentProfile?.profileId!,
      off_description: '',
    },
    { refetchOnMountOrArgChange: 30 }
  );

  //periodos
  const {
    data: periodsByOffer = [],
    isLoading: isLoadingCID,
    isFetching: isFetchingCID,
  } = useGetPeriodAcademicOfferingQuery(
    {
      profileId: currentProfile?.profileId!,
      academicOfferingId:
        offersAvailables!
          .find((offer) => offer.off_description === offerDescription)
          ?.id?.toString() || '',
      current: true,
    },
    { skip: !offerDescription, refetchOnMountOrArgChange: 30 }
  );

  const periods = useMemo(() => {
    return Array.isArray(periodsByOffer)
      ? (periodsByOffer as unknown as Period[]).filter(
          (dt) => new Date(dt.end_process).getTime() > new Date().getTime()
        )
      : [];
  }, [periodsByOffer]);

  const {
    data: offerById,
    isLoading: isLoadingOID,
    isFetching: isFetchingOID,
  } = useGetAcademicOfferingQuery(
    {
      profileId: currentProfile?.profileId!,
      academicOfferingId:
        offersAvailables!.find((offer) => offer.off_description === offerDescription)?.id! || 0,
    },
    { skip: !offerDescription, refetchOnMountOrArgChange: 30 }
  );

  //filters

  const { data: integralEvaluations, isLoading: isLoadingIE } = useGetAllIntegralEvaluationsQuery(
    {}
  );

  const { data: typeDocumentListAll = [] } = useGetTypeDocumentListAllQuery({});

  const [getRepresentativeScaleByID, { isLoading: isLoadingRepresentativeScaleByID }] =
    useLazyGetRubricByIDQuery();

  const [uploadFile, { isLoading: isUploading }] = useUploadFilesMutation();

  const { data: representativeScaleAll, isLoading: isLoadingRepresentativeScaleAll } =
    useGetRepresentativeScaleAllQuery({});

  const [getCoursesListAll, { data: coursesListAll, isLoading: isLoadingCoursesListAll }] =
    useLazyGetCoursesListAllQuery();

  const { urlDownload } = useGetUrlDownload();

  const [addForm, { isLoading, isError, error, isSuccess }] = useAddFormMutation();

  const { errors, handleSubmit, setFieldValue, touched, values, resetForm } =
    useFormik<FormsPayload>({
      initialValues: {
        period_id: '',
        education_level_id: '',
        course_id: formById?.course_id || '',
        eva_rubrica_id: '',
        eva_scale_representative_id: '',
        evaluated_id: '',
        eva_rubrica_detail: [],
        eva_formulary_document: [],
        eva_scale_representative_name: '',
      },
      validationSchema: formAddSchema,
      onSubmit: async (currentValues) => {
        await addForm(currentValues);
        setShowAlerts(true);
      },
    });

  console.log(errors);

  useEffect(() => {
    if (typeDocumentListAll.length > 0) {
      setFieldValue('eva_formulary_document', [
        {
          eva_form_tip_doc_id: typeDocumentListAll[0]?.id,
          eva_formulary_document_url: '',
        },
        {
          eva_form_tip_doc_id: typeDocumentListAll[1]?.id,
          eva_formulary_document_url: '',
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeDocumentListAll]);

  useEffect(() => {
    if (id) {
      getFormID({
        id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (formById) {
      setFieldValue('period_id', formById.period_id);
      setFieldValue('education_level_id', formById.education_level_id);
      setFieldValue('course_id', formById.course_id);
      setFieldValue('eva_rubrica_id', formById.eva_rubrica_id);
      setFieldValue('eva_scale_representative_id', formById.eva_scale_representative_id);
      setFieldValue('evaluated_id', formById.evaluated_id);
      setFieldValue('eva_rubrica_detail', formById.eva_formulary_details);
      setFieldValue(
        'eva_formulary_document',
        formById.eva_formulary_documents.map((dt) => ({
          eva_form_tip_doc_id: dt.eva_form_tip_doc_id,
          eva_formulary_document_url: dt.eva_formulary_document_url,
        }))
      );
      setFieldValue('eva_scale_representative_name', formById.eva_scale_representative_name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formById]);

  useEffect(() => {
    if (formById && representativeScaleAll) {
      const scale = representativeScaleAll.find(
        (dt) => Number(dt.id) === Number(formById.eva_scale_representative_id)
      );
      if (scale) {
        setEvaluationScaleDetail(scale.evaluation_scale_detail);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formById, representativeScaleAll]);

  useEffect(() => {
    if (formById && values.eva_rubrica_detail.length > 0) {
      const newResults: number[] = [];
      evaTypes?.eva_rubrica_detail.forEach((item, index) => {
        const result = values?.eva_rubrica_detail.find(
          (dt) => Number(dt.eva_rubrica_detail_id) === Number(item.id)
        );
        if (result) {
          newResults[index] = (parseFloat(result.form_value) * Number(item.eva_peso)) / 100;
        }
      });
      if (newResults.length > 0) {
        setResults(newResults);
      }
    }
  }, [evaTypes, formById, values.eva_rubrica_detail]);

  useEffect(() => {
    if (educationLevelId && values.education_level_id) {
      const execute = async () => {
        await getCollaboratorsAll({
          user_profile_id: currentProfile?.profileId!,
          education_level_principal_id: Number(values.education_level_id),
          offer_id: Number(educationLevelId),
        });
      };
      execute();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [educationLevelId, values.education_level_id]);

  useEffect(() => {
    if (ponderation && evaluationScaleDetail.length > 0) {
      const escalaRepresentative = evaluationScaleDetail.find(
        (item) =>
          ponderation >= parseFloat(item.scale_start) && ponderation <= parseFloat(item.scale_end)
      );

      const evaluationScaleDetailMaxStartEnd = evaluationScaleDetail.reduce((prev, current) =>
        prev.scale_end > current.scale_end ? prev : current
      );

      if (escalaRepresentative) {
        setEscalaSelected(escalaRepresentative?.name);
        setFieldValue('eva_scale_representative_name', escalaRepresentative?.name);
      } else {
        setEscalaSelected(evaluationScaleDetailMaxStartEnd?.name);
        setFieldValue('eva_scale_representative_name', evaluationScaleDetailMaxStartEnd?.name);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ponderation, evaluationScaleDetail]);

  useEffect(() => {
    if (evaTypes && evaTypes.eva_rubrica_detail.length > 0 && results.length > 0) {
      if (evaTypes?.eva_rubrica_detail.length === results.length) {
        const sum = results.reduce((a, b) => a + b, 0);
        setPonderation(sum);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results, evaTypes?.eva_rubrica_detail]);

  useEffect(() => {
    if (file.length > 0) {
      const filesToUpload = file.map(async (item) => {
        try {
          let url = '';
          if (item.file) {
            url = await uploadFile({
              files: item.file,
            }).unwrap();
          }
          return url;
        } catch (err) {
          console.log(err);
        }
      });
      Promise.all(filesToUpload).then((res) => {
        const New_eva_formulary_document = file.map((item, index) => {
          return {
            eva_form_tip_doc_id: item.eva_form_tip_doc_id,
            eva_formulary_document_url: res[index],
          };
        });

        setFieldValue('eva_formulary_document', New_eva_formulary_document);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  useEffect(() => {
    if (values.period_id && values.education_level_id) {
      getCoursesListAll({
        periodId: values.period_id,
        educationLevelId: values.education_level_id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.period_id, values.education_level_id]);

  useEffect(() => {
    if (values.eva_rubrica_id) {
      const execute = async () => {
        try {
          const representative = await getRepresentativeScaleByID({
            id: values.eva_rubrica_id,
          }).unwrap();
          setEvaTypes(representative);
        } catch (err) {
          console.log(err);
        }
      };
      execute();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.eva_rubrica_id]);

  useEffect(() => {
    if (
      !formById &&
      evaTypes &&
      evaTypes.eva_rubrica_detail &&
      evaTypes.eva_rubrica_detail.length > 0
    ) {
      setFieldValue(
        'eva_rubrica_detail',
        evaTypes.eva_rubrica_detail.map((item) => {
          return {
            eva_rubrica_detail_id: '',
            form_value: '',
          };
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [evaTypes]);

  useEffect(() => {
    if (isSuccess) {
      resetForm();
      setOff_description('');
    }
  }, [isSuccess, resetForm]);

  console.log(errors);

  return (
    <Grid container spacing={2}>
      {isSuccess && showAlerts && (
        <Grid item xs={12}>
          <SuccessAlert
            message={t('Formulario registrado exitosamente')}
            handleDismiss={() => setShowAlerts(false)}
          />
        </Grid>
      )}
      {isError && showAlerts && (
        <Grid item xs={12}>
          <ErrorAlert message={error as any} handleDismiss={() => setShowAlerts(false)} />
        </Grid>
      )}
      <Grid item xs={12}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <InputLabel>{t('academic.courses.edu_level')}:</InputLabel>
              {!isFetchingPID && (
                <Autocomplete
                  // key={autocompleteKey.offer}
                  options={offersAvailables}
                  noOptionsText={t('academic.courses.edu_level.no_data')}
                  loading={isLoadingPID}
                  loadingText={t('academic.courses.edu_level.loading')}
                  getOptionLabel={(option) => option.off_name}
                  value={
                    offersAvailables.find((dt) => dt.off_description === offerDescription) || null
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={t('academic.courses.edu_level.placeholder')}
                    />
                  )}
                  onChange={(_, value) => {
                    // setFiltersSearch(false);
                    if (value) {
                      setOff_description(value?.off_description);
                      setEducationLevelId(value?.id?.toString());
                    } else {
                      setOff_description('');
                      setEducationLevelId('');
                    }
                  }}
                  fullWidth
                />
              )}
              {isFetchingPID && <TextFieldFetching />}
            </Grid>

            <Grid item xs={6}>
              <InputLabel>{t('academic.courses.period')}:</InputLabel>
              {!isFetchingCID && (
                <Autocomplete
                  options={periods}
                  noOptionsText={t('academic.courses.period.no_data')}
                  loading={isLoadingCID}
                  loadingText={t('academic.courses.period.loading')}
                  disabled={!offerDescription}
                  getOptionLabel={(option) => option.per_name}
                  value={periods.find((dt) => dt.id === Number(values.period_id)) || null}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={t('academic.courses.period.placeholder')}
                      error={Boolean(errors.period_id && touched.period_id)}
                    />
                  )}
                  onChange={(_, value) => {
                    setFieldValue('period_id', value?.id || 0);
                  }}
                  fullWidth
                  clearIcon={null}
                />
              )}
              {isFetchingCID && <TextFieldFetching />}
              {errors.period_id && touched.period_id && (
                <FormHelperText error>{t(errors.period_id)}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={6}>
              <Grid item xs={8}>
                <InputLabel>{t('academic.courses.academic_unit')}:</InputLabel>
              </Grid>

              {!isFetchingOID && (
                <Autocomplete
                  // key={autocompleteKey.educationLvl}
                  options={offerById ? offerById!.education_levels : []}
                  noOptionsText={t('academic.courses.academic_unit.no_data')}
                  loading={isLoadingOID}
                  disabled={!values.period_id}
                  loadingText={t('academic.courses.academic_unit.loading')}
                  getOptionLabel={(option) => option.edu_name}
                  value={
                    offerById?.education_levels.find(
                      (dt) => dt.id === Number(values.education_level_id)
                    ) || null
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={t('academic.courses.academic_unit.placeholder')}
                      error={Boolean(errors.education_level_id && touched.education_level_id)}
                    />
                  )}
                  onChange={(_, value) => {
                    setFieldValue('education_level_id', value?.id || 0);
                  }}
                  fullWidth
                  clearIcon={null}
                />
              )}
              {isFetchingOID && <TextFieldFetching />}
              {errors.education_level_id && touched.education_level_id && (
                <FormHelperText error>{t(errors.education_level_id)}</FormHelperText>
              )}
            </Grid>
            <Grid item xs={6}>
              <InputLabel>{t('Cursos')}</InputLabel>
              {isLoadingCoursesListAll || !values.period_id || !values.education_level_id ? (
                <TextField
                  fullWidth
                  placeholder={t('Seleccione un periodo y la unidad académica ')}
                  disabled
                />
              ) : (
                <Autocomplete
                  options={Array.isArray(coursesListAll) ? coursesListAll : []}
                  value={
                    Array.isArray(coursesListAll)
                      ? coursesListAll?.find((dt) => dt.id === Number(values.course_id))
                      : null
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(errors.course_id && touched.course_id)}
                      placeholder={t('Selecciona un curso')}
                    />
                  )}
                  getOptionLabel={(option) => option?.mat_name}
                  onChange={(_, value) => {
                    if (value) {
                      setEvaluated(value?.course_teacher!);
                      setFieldValue('course_id', value?.id);
                    } else {
                      setFieldValue('course_id', '');
                      setEvaluated([]);
                    }
                  }}
                />
              )}
              {errors.course_id && touched.course_id && (
                <FormHelperText error>{t(errors.course_id)}</FormHelperText>
              )}
            </Grid>
            <Grid item xs={6}>
              <InputLabel>{t('Escenario de evaluación')}</InputLabel>
              {isLoadingIE ? (
                <TextField
                  fullWidth
                  placeholder={t('Cargando escenarios de evaluación...')}
                  disabled
                />
              ) : (
                <Autocomplete
                  options={integralEvaluations! || []}
                  value={
                    integralEvaluations?.find((dt) => dt.id === Number(values.eva_rubrica_id)) ||
                    null
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(errors.eva_rubrica_id && touched.eva_rubrica_id)}
                      placeholder={t('Seleccionar escenario de evaluación')}
                    />
                  )}
                  getOptionLabel={(option) => option?.name}
                  onChange={(_, value) => {
                    setFieldValue('eva_rubrica_id', value?.id!);
                  }}
                />
              )}
              {errors.eva_rubrica_id && touched.eva_rubrica_id && (
                <FormHelperText error>{t(errors.eva_rubrica_id)}</FormHelperText>
              )}
            </Grid>
            <Grid item xs={6}>
              <InputLabel>{t('Escala Representativa')}</InputLabel>
              {isLoadingRepresentativeScaleAll ? (
                <TextField
                  fullWidth
                  placeholder={t('Cargando escala representativa...')}
                  disabled
                />
              ) : (
                <Autocomplete
                  options={representativeScaleAll! || []}
                  value={
                    representativeScaleAll?.find(
                      (dt) => dt.id === Number(values.eva_scale_representative_id)
                    ) || null
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(
                        errors.eva_scale_representative_id && touched.eva_scale_representative_id
                      )}
                      placeholder={t('Seleccione una escala representativa')}
                    />
                  )}
                  getOptionLabel={(option) => option?.name}
                  onChange={(_, value) => {
                    if (value) {
                      setEvaluationScaleDetail(value?.evaluation_scale_detail!);
                      setFieldValue('eva_scale_representative_id', value?.id!);
                    } else {
                      setEvaluationScaleDetail([]);
                      setFieldValue('eva_scale_representative_id', '');
                    }
                  }}
                />
              )}
              {errors.eva_scale_representative_id && touched.eva_scale_representative_id && (
                <FormHelperText error>{t(errors.eva_scale_representative_id)}</FormHelperText>
              )}
            </Grid>
            {!values.course_id && (
              <Grid item xs={6}>
                <InputLabel>{t('Evaluado')}</InputLabel>
                {isLoadingCollaboratorsAll ? (
                  <TextField
                    fullWidth
                    placeholder={t('Seleccione un curso para ver los evaluados')}
                    disabled
                  />
                ) : (
                  <Autocomplete
                    options={Array.isArray(collaboratorAll) ? collaboratorAll : []}
                    value={
                      Array.isArray(collaboratorAll)
                        ? collaboratorAll.find((dt) => {
                            return Number(dt.user_id) === Number(values.evaluated_id);
                          })
                        : null
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={Boolean(errors.evaluated_id && touched.evaluated_id)}
                        placeholder={t('Seleccione una evaluado')}
                      />
                    )}
                    getOptionLabel={(option) => option?.pers_full_lastname!}
                    onChange={(_, value) => setFieldValue('evaluated_id', value?.user_id!)}
                  />
                )}
                {errors.evaluated_id && touched.evaluated_id && (
                  <FormHelperText error>{t(errors.evaluated_id)}</FormHelperText>
                )}
              </Grid>
            )}
            {values.course_id && (
              <Grid item xs={6}>
                <InputLabel>{t('Evaluado')}</InputLabel>
                {evaluated.length === 0 ? (
                  <TextField
                    fullWidth
                    placeholder={t('Seleccione un curso para ver los evaluados')}
                    disabled
                  />
                ) : (
                  <Autocomplete
                    options={evaluated}
                    value={
                      evaluated.find((dt) => {
                        return Number(dt.collaborator.user_id!) === Number(values.evaluated_id);
                      }) || null
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={Boolean(errors.evaluated_id && touched.evaluated_id)}
                        placeholder={t('Seleccione una evaluado')}
                      />
                    )}
                    getOptionLabel={(option) => option?.collaborator.user.person.pers_full_name!}
                    onChange={(_, value) =>
                      setFieldValue('evaluated_id', value?.collaborator.user_id!)
                    }
                  />
                )}
                {errors.evaluated_id && touched.evaluated_id && (
                  <FormHelperText error>{t(errors.evaluated_id)}</FormHelperText>
                )}
              </Grid>
            )}
            <Grid item container xs={12}>
              <Grid item xs={12}>
                <InputLabel>{t('Calificación')}</InputLabel>
              </Grid>
              {!values.eva_rubrica_id || isLoadingRepresentativeScaleByID ? (
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    placeholder={t(
                      'Seleccione un escenario de evaluación para realizar la calificación'
                    )}
                    disabled
                    variant="standard"
                  />
                </Grid>
              ) : (
                <Grid item xs={12}>
                  {evaTypes?.eva_rubrica_detail.map((item, index) => (
                    <Grid
                      item
                      xs={6}
                      key={item.id}
                      sx={{
                        my: 2,
                        mx: 'auto',
                      }}
                    >
                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          sx={{
                            wordWrap: 'break-word',
                            width: '150px',
                          }}
                        >
                          {item.eva_types.name}
                        </Typography>
                        <TextField
                          type="number"
                          value={values.eva_rubrica_detail[index]?.form_value || '00.00'}
                          error={Boolean(
                            (errors.eva_rubrica_detail?.[index] as unknown as EvaRubricaDetail)
                              ?.form_value && touched.eva_rubrica_detail?.[index]?.form_value
                          )}
                          onChange={(e) => {
                            const { value } = e.target;
                            const newResults = [...results];
                            newResults[index] = (parseFloat(value) * Number(item.eva_peso)) / 100;
                            setResults(newResults);

                            setFieldValue(`eva_rubrica_detail[${index}]`, {
                              eva_rubrica_detail_id: item.id,
                              form_value: value,
                            });
                          }}
                          sx={{
                            mx: 1,
                            width: '180px',
                          }}
                        />
                        <Grid
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <Typography variant="body2" color="textSecondary">
                            {Number(item.eva_peso).toFixed(1)}% =
                          </Typography>
                        </Grid>
                        {values.eva_rubrica_detail[index]?.form_value ? (
                          <Grid
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <Typography
                              variant="h5"
                              sx={{
                                ml: 5,
                                display: 'flex',
                                textAlign: 'center',
                              }}
                            >
                              {(
                                (parseFloat(values.eva_rubrica_detail[index].form_value) *
                                  Number(item.eva_peso)) /
                                100
                              ).toFixed(2)}
                            </Typography>
                          </Grid>
                        ) : (
                          <Grid
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <Typography
                              variant="h5"
                              sx={{
                                ml: 5,
                                display: 'flex',
                                textAlign: 'center',
                              }}
                            >
                              0.0
                            </Typography>
                          </Grid>
                        )}
                      </Grid>

                      {errors.eva_rubrica_detail?.[index] &&
                        touched.eva_rubrica_detail?.[index]?.form_value && (
                          <FormHelperText
                            error
                            sx={{
                              mx: 'auto',
                              textAlign: 'center',
                              my: 2,
                            }}
                          >
                            {t(
                              (errors.eva_rubrica_detail?.[index] as unknown as EvaRubricaDetail)
                                ?.form_value
                            )}
                          </FormHelperText>
                        )}
                    </Grid>
                  ))}

                  {ponderation && escalaSelected && evaluationScaleDetail.length > 0 ? (
                    <Grid
                      item
                      xs={6}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        my: 4,
                        mx: 'auto',
                      }}
                    >
                      <Typography variant="h5">{t('Total')}: &nbsp;</Typography>
                      <Typography
                        variant="h5"
                        sx={{
                          color: `${theme.palette.primary.main}`,
                        }}
                      >
                        {Number(ponderation).toFixed(2)}
                      </Typography>
                      <Typography
                        variant="h5"
                        sx={{
                          color: `${theme.palette.primary.main}`,
                        }}
                      >
                        &nbsp;&nbsp;&nbsp;{escalaSelected}
                      </Typography>
                    </Grid>
                  ) : (
                    <Grid
                      item
                      xs={6}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        my: 4,
                        mx: 'auto',
                      }}
                    >
                      <Typography variant="h5">
                        {t(
                          'Seleccione una escala representativa y digite todas las calificaciones para realizar el cálculo del total'
                        )}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              )}
            </Grid>

            {Array.isArray(typeDocumentListAll) && !formById && (
              <Grid
                item
                container
                xs={12}
                sx={{
                  mb: 2,
                }}
              >
                {typeDocumentListAll.map((item, index) => (
                  <Grid item xs={6} key={item.id}>
                    <DragArea
                      handleChange={(e) => {
                        const newFileArray = [...file];
                        newFileArray[index] = {
                          file: e.target.files![0],
                          eva_form_tip_doc_id: item.id,
                        };
                        setFile(
                          newFileArray || {
                            file: null,
                            eva_form_tip_doc_id: '',
                          }
                        );
                      }}
                      label={item.eva_form_tip_doc_name}
                      typesAccepted="application/pdf"
                    />
                    {errors.eva_formulary_document?.[index] &&
                      touched.eva_formulary_document?.[index]?.eva_formulary_document_url && (
                        <FormHelperText
                          error
                          sx={{
                            mt: 6,
                          }}
                        >
                          {t(
                            (errors.eva_formulary_document?.[index] as any)
                              ?.eva_formulary_document_url
                          )}
                        </FormHelperText>
                      )}
                  </Grid>
                ))}
              </Grid>
            )}

            {formById && (
              <Grid
                item
                xs={12}
                sx={{
                  mx: 'auto',
                }}
              >
                <InputLabel
                  sx={{
                    mb: 2,
                  }}
                >
                  {t('Documentos')}
                </InputLabel>
                {Array.isArray(formById?.eva_formulary_documents) && (
                  <Grid
                    item
                    container
                    xs={12}
                    sx={{
                      mb: 4,
                    }}
                  >
                    {formById?.eva_formulary_documents.map((item) => (
                      <Grid item xs={6} key={item.id}>
                        <a
                          href={`${urlDownload}/${item?.eva_formulary_document_url}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            textDecoration: 'none',
                          }}
                        >
                          <Grid
                            item
                            xs={12}
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              cursor: 'pointer',
                            }}
                          >
                            <FileDownload />
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              sx={{
                                wordWrap: 'break-word',
                                width: '150px',
                              }}
                            >
                              {item?.eva_formulary_type_document?.eva_form_tip_doc_name}
                            </Typography>
                          </Grid>
                        </a>
                      </Grid>
                    ))}
                  </Grid>
                )}
              </Grid>
            )}

            <Grid
              item
              container
              xs={12}
              justifyContent="flex-end"
              spacing={2}
              sx={{
                mt: 2,
              }}
            >
              <Grid item>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  disabled={isLoading || isUploading}
                  endIcon={isLoading && <CircularProgress size={12} color="primary" />}
                >
                  {t('maintenance.evaluation_type.add.submit')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default FormsAdd;
