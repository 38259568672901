import {
  Autocomplete,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../../../../hooks/useRedux';
import ErrorAlert from '../../../../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../../../../shared/components/alerts/SuccessAlert';
import { useGetAllEvaluationTypesQuery } from '../../../evaluation_type/slices/evaluationTypeApiSlice';
import { useAddGroupsUsersMutation } from '../../slices/listGroupsApiSlice';
import { groupsUsersSchema } from '../../validation/GroupsUsers.validation';

const AddGroupsUsersForm = () => {
  const { t } = useTranslation();
  const [showAlerts, setShowAlerts] = useState(false);

  const [addGroupUsers, { isLoading, isError, error, isSuccess }] = useAddGroupsUsersMutation();

  const { data: evaluationTypes, isLoading: isLoadingEvaluationTypes } =
    useGetAllEvaluationTypesQuery({});

  const { groupsUserList } = useAppSelector((state) => state.groupsUser);

  console.log(groupsUserList);

  const { errors, getFieldProps, setFieldValue, handleSubmit, touched, resetForm } = useFormik({
    initialValues: {
      name: '',
      keyword: 'EGU',
      eva_type_uuid: '',
    },
    validationSchema: groupsUsersSchema,
    onSubmit: async (currentValues) => {
      await addGroupUsers({
        name: currentValues.name,
        keyword: currentValues.keyword,
        eva_type_uuid: currentValues.eva_type_uuid,
        user: groupsUserList,
      });

      setShowAlerts(true);
    },
  });

  useEffect(() => {
    if (isSuccess) {
      resetForm();
    }
  }, [isSuccess, resetForm]);

  return (
    <Grid container spacing={2}>
      {isSuccess && showAlerts && (
        <Grid item xs={12}>
          <SuccessAlert
            message={t('maintenance.groups.add.success')}
            handleDismiss={() => setShowAlerts(false)}
          />
        </Grid>
      )}
      {isError && showAlerts && (
        <Grid item xs={12}>
          <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
        </Grid>
      )}

      <Grid item xs={12}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputLabel>{t('maintenance.scopes.form.name')}</InputLabel>
              <TextField
                placeholder={t('maintenance.scopes.form.name.placeholder')}
                error={Boolean(errors.name && touched.name)}
                {...getFieldProps('name')}
                fullWidth
              />
              {errors.name && touched.name && (
                <FormHelperText error>{t(errors.name)}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12}>
              <InputLabel>{t('Palabra clave (keyword)')}</InputLabel>
              <TextField
                disabled
                placeholder={t('Palabra clave (keyword)')}
                error={Boolean(errors.keyword && touched.keyword)}
                {...getFieldProps('keyword')}
                fullWidth
              />
              {errors.keyword && touched.keyword && (
                <FormHelperText error>{t(errors.keyword)}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12}>
              {isLoadingEvaluationTypes ? (
                <TextField fullWidth placeholder={t('shared.filters.eva_type.loading')} disabled />
              ) : (
                <Autocomplete
                  options={evaluationTypes || []}
                  getOptionLabel={(option) => option.description}
                  value={
                    evaluationTypes?.find(
                      (evaluationType) =>
                        evaluationType.uuid === getFieldProps('eva_type_uuid').value
                    ) || null
                  }
                  onChange={(event, newValue) => {
                    setFieldValue('eva_type_uuid', newValue?.uuid);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="medium"
                      fullWidth
                      error={Boolean(errors.eva_type_uuid && touched.eva_type_uuid)}
                      placeholder={t('shared.filters.eva_type.placeholder')}
                    />
                  )}
                />
              )}
              {errors.eva_type_uuid && touched.eva_type_uuid && (
                <FormHelperText error>{t(errors.eva_type_uuid)}</FormHelperText>
              )}
            </Grid>

            <Grid item container xs={12} justifyContent="flex-end" spacing={2}>
              <Grid item>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  disabled={isLoading}
                  endIcon={isLoading && <CircularProgress size={12} />}
                >
                  {t('maintenance.scopes.add.submit')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default AddGroupsUsersForm;
