import * as yup from 'yup';

export const MaterialSchema = yup.object().shape({
  description: yup.string().required('La descripción es requerida.'),
  file: yup.mixed().required('Archivo requerido.'),
  title: yup.string().required('El título es requerido.'),
});

export const MaterialLinkSchema = yup.object().shape({
  description: yup.string().required('La descripción es requerida.'),
  url: yup.string().required('El link es requerido.'),
});

export const DescripcionSchema = yup.object().shape({
  NameFolder: yup.string().required('La descripción es requerida.'),

  //   file: yup.object().nullable().required('Archivo requerido.'),
});
export const ImagenFIleEvaluation = yup.object().shape({
  file: yup.mixed().required('Archivo requerido.'),
});
