import { FC, useState, useEffect } from 'react';

import {
  Button,
  CircularProgress,
  Collapse,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';

import { IconChevronDown, IconChevronUp } from '@tabler/icons';
import { useTranslation } from 'react-i18next';

import PaymentsTableReview from './components/PaymentsTableReview';
import FeesTableReview from './components/FeesTableReview';
import { useAppSelector, useAppDispatch } from '../../../../../hooks/useRedux';
// import { showWithDecimals } from '../../../../shared/helpers';
import { useProcessPaymentCashierPointMutation } from '../../slices/debtsPaymentApiSlice';
import { resetPayments, setVoucherData } from '../../slices/debtsPaymentSlice';
import { AmountCollectionPayload, Fees } from '../../interfaces/payloads.interface';
import ErrorAlert from '../../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../../shared/components/alerts/SuccessAlert';
import BillingDataReview from './BillingDataReview';
import { epsilonRound } from '../../../../shared/helpers';

interface Props {
  handleNext: () => void;
  handleBack: () => void;
}

const PayReview: FC<Props> = (props) => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const collapseBtnSize = 20;

  const { handleBack, handleNext } = props;

  const { currentProfile } = useAppSelector((state) => state.access);
  const {
    totalDebtAmount,
    totalPayAmount,
    // totalDebtDiscount,
    tempBillingData,
    defaultTempBillingData,
    // currentClient,
    selectedDebts,
    payCollection,
    earlyPayment,
    debtorStudentId,
    isPositiveBalanceTransac,
    currentStudentRecord,
  } = useAppSelector((state) => state.debtsPayment);

  // si tomo el ultimo item del paycollection, siendo efectivo y el totalpay es mayor que el total
  // debt significa que es cambio

  const [openFees, setOpenFees] = useState(true);
  const [openPayments, setOpenPayments] = useState(true);

  const [showAlerts, setShowAlerts] = useState(true);

  const [cashChange, setCashChange] = useState(0);

  useEffect(() => {
    if (totalPayAmount > totalDebtAmount) {
      const cashMethod = payCollection.find(
        (payColl) => payColl.payment_way_keyword === 'forma-pago-efectivo'
      );
      const positiveBalance = payCollection.find(
        (payColl) => payColl.payment_way_keyword === 'forma-pago-saldo-favor'
      );

      if (
        Number(cashMethod?.payment_amount) + +(positiveBalance?.payment_amount || 0) >
        totalDebtAmount
      ) {
        setCashChange(
          Number(cashMethod?.payment_amount) +
            Number(positiveBalance?.payment_amount || 0) -
            totalDebtAmount
        );
      } else setCashChange(0);
    } else setCashChange(0);
  }, [payCollection, totalDebtAmount, totalPayAmount]);

  const toogleOpenFees = () => {
    setOpenFees(!openFees);
  };

  const toogleOpenPayments = () => {
    setOpenPayments(!openPayments);
  };

  const [processPayment, { isLoading, isSuccess, data, isError, error }] =
    useProcessPaymentCashierPointMutation();

  const handleSubmitPayment = async () => {
    //se filtran y mapean las cuotas
    const feesMapped = selectedDebts
      //se filtran las cuotas que solo tengan pagos > 0
      .filter((filtered) => filtered.fees_value_payment)
      .map((debt) => {
        return {
          fees_identifier: +debt.fees_identifier,
          fees_value_payment: debt.apply_early_payment
            ? //condicionar cuando aplica prontopago
              debt.fees_value_payment! === Number(debt.net_value_early_payment)
              ? Number(debt.net_value_early_payment)
              : +debt.fees_value_payment!
            : debt.fees_value_payment! === +debt.fees_balance
            ? Number(debt.fees_balance)
            : +debt.fees_value_payment!,
          // condicionar si el value_payment es igual al fee_balance enviar el o_feeBalance u o_net_balance si es early payment
          o_fees_value_payment: debt.apply_early_payment
            ? debt.fees_value_payment! === Number(debt.net_value_early_payment)
              ? Number(debt.o_net_value_early_payment)
              : +debt.fees_value_payment!
            : debt.fees_value_payment! === +debt.fees_balance
            ? Number(debt.o_fees_balance)
            : +debt.fees_value_payment!,
        };
      });

    console.log(selectedDebts);

    //early payment, cuotas cuando el pronto pago se aplica
    const earlyPaymentFees: AmountCollectionPayload[] | undefined =
      earlyPayment &&
      selectedDebts
        .filter((fee) => {
          return (
            fee.apply_early_payment &&
            Number(fee.net_value_early_payment) === Number(fee.fees_value_payment)
          );
        })
        .map((debt) => {
          // let earlyPaymentItemAmount = 0;
          // earlyPaymentAmount += Number(debt.discount_value_early_payment);
          return {
            payment_way_id: earlyPayment.id,
            payment_way_keyword: earlyPayment?.cat_keyword as any, //'forma-pronto-pago',
            payment_way_name: earlyPayment?.cat_name,
            payment_amount: Number(debt.o_discount_value_early_payment),
            students: {
              debtor_student_id: Number(selectedDebts[0].debtor_student_id) || debtorStudentId!,
              balance_positive: null,
              fees: [
                {
                  fees_identifier: +debt.fees_identifier,
                  fees_value_payment: Number(debt.o_discount_value_early_payment),
                },
              ],
            },
          };
        });
    // const newEarlyPaymentCollection: AmountCollectionPayload | undefined = earlyPayment && {
    //   payment_way_id: earlyPayment.id,
    //   payment_way_keyword: earlyPayment?.cat_keyword as any, //'forma-pronto-pago',
    //   payment_way_name: earlyPayment?.cat_name,
    //   payment_amount: earlyPaymentAmount,
    //   students: {
    //     debtor_student_id: Number(selectedDebts[0].debtor_student_id),
    //     balance_positive: null,
    //     fees: earlyPaymentFees,
    //   },
    // };

    /// collections array fees, se convierte el arreglo de cuotas en un objeto
    const reduceObjFees: {
      [key: string]: { fees_value_payment: number; o_fees_value_payment: number };
    } = {
      ...feesMapped.reduce(
        (a, v) => ({
          ...a,
          [v.fees_identifier.toString()]: {
            fees_value_payment: v.fees_value_payment,
            o_fees_value_payment: v.o_fees_value_payment,
          },
        }),
        {}
      ),
    };

    //copy fees
    let toMutateFees: {
      [key: number]: { fees_value_payment: number; o_fees_value_payment: number };
    } = { ...reduceObjFees };

    //Se arma el arreglo de metodos de pagos con cuotas
    const collectionsWithFees = payCollection.map((pay) => {
      //pago total del metodo
      let currentValue = +pay.payment_amount;
      //arreglo de cuotas con su distribucion de pagos
      let newFees: Fees[] = [];

      if (
        pay.payment_way_keyword === 'forma-pago-saldo-favor' &&
        pay.payment_amount > totalDebtAmount
      ) {
        currentValue = totalDebtAmount;
      }

      for (const key in toMutateFees) {
        // redondeo de mas de 2 decimales
        // const fixedCurrentValue = Math.round((currentValue + Number.EPSILON) * 100) / 100;

        //si no hay monto de pago se rompe el ciclo
        if (currentValue > 0) {
          //si el pago de la cuota es 0 se salta el index
          if (toMutateFees[key].fees_value_payment > 0) {
            //si el monto del metodo de pago es mayor que el pago de la cuota, se paga toda la cuota, caso contrario se agregar
            //el totdal del monto de pago (que seria un abono al monto de la cuota)
            if (epsilonRound(currentValue) >= toMutateFees[key].fees_value_payment) {
              newFees.push({
                fees_identifier: Number(key),
                fees_value_payment: toMutateFees[key].o_fees_value_payment,
              });
              //se le resta el valor de la cuota al monto del metodo de pago
              currentValue -= toMutateFees[key].fees_value_payment;
              //se setea en 0 la cuota para no volver a iterarla
              toMutateFees[key].fees_value_payment = 0;
              toMutateFees[key].o_fees_value_payment = 0;
            } else {
              // newFees.push({ fees_identifier: Number(key), fees_value_payment:  currentValue });
              newFees.push({
                fees_identifier: Number(key),
                fees_value_payment: epsilonRound(currentValue),
              });
              toMutateFees[key].fees_value_payment -= epsilonRound(currentValue);
              toMutateFees[key].o_fees_value_payment -= epsilonRound(currentValue);
              currentValue = 0;
            }
          } else {
            // continue;
          }
        } else {
          // break;
        }
      }

      //
      return {
        ...pay,
        //si el saldo a favor es mayor que la deuda solo se envia como pago de saldo a favor la cantidad de la deuda misma
        // ...(pay.payment_way_keyword === 'forma-pago-saldo-favor' &&
        //   pay.payment_amount > totalDebtAmount && { payment_amount: totalDebtAmount }),
        students: {
          //TODO: debtor student provisional hasta que se analice en back
          debtor_student_id: Number(selectedDebts[0]?.debtor_student_id || debtorStudentId),
          balance_positive: null,
          fees: newFees,
        },
      };
    });

    //
    // handleNext();
    // return;
    await processPayment({
      profileId: currentProfile?.profileId!,
      // studentId: currentClient?.user?.student?.id!,
      datafastPayload: {
        student_record_id: currentStudentRecord?.id!,
        ...(earlyPaymentFees?.length
          ? //quitar el exclamacion en earlyPaymentFees cuando no esta comentado el return
            { collections: [...earlyPaymentFees, ...collectionsWithFees] }
          : { collections: [...collectionsWithFees] }),

        ...(tempBillingData && {
          temporal: tempBillingData ? tempBillingData : defaultTempBillingData,
        }),
      },
    })
      .unwrap()
      .then((response) => {
        dispatch(resetPayments());
      });

    setShowAlerts(true);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch(setVoucherData(data));
      handleNext();
    }
  }, [data, dispatch, handleNext, isSuccess]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {isError && showAlerts && (
          <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
        )}

        {isSuccess && showAlerts && (
          <SuccessAlert
            message={t('financial.debts_payment.wizard.pay_review.success_payment_alert')}
            handleDismiss={() => setShowAlerts(false)}
          />
        )}
      </Grid>

      <BillingDataReview cashChange={cashChange} />

      <Grid item xs={12}>
        <Grid container mt={4} spacing={5}>
          {!isPositiveBalanceTransac && (
            <Grid item xs={12}>
              <Grid container justifyContent={'space-between'}>
                <Typography variant="h3" color="initial">
                  {t('financial.debts_payment.wizard.fees_table.title')}
                </Typography>
                <IconButton onClick={toogleOpenFees}>
                  {openFees ? (
                    <IconChevronUp size={collapseBtnSize} />
                  ) : (
                    <IconChevronDown size={collapseBtnSize} />
                  )}
                </IconButton>
              </Grid>
              <Collapse in={openFees} timeout="auto" unmountOnExit>
                <FeesTableReview />
              </Collapse>
            </Grid>
          )}

          <Grid item xs={12}>
            <Grid container justifyContent={'space-between'}>
              <Typography variant="h3" color="initial">
                {t('financial.debts_payment.wizard.pay_method_table.title')}
              </Typography>
              <IconButton onClick={toogleOpenPayments}>
                {openPayments ? (
                  <IconChevronUp size={collapseBtnSize} />
                ) : (
                  <IconChevronDown size={collapseBtnSize} />
                )}
              </IconButton>
            </Grid>
            <Collapse in={openPayments} timeout="auto" unmountOnExit>
              <PaymentsTableReview />
            </Collapse>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container justifyContent={'center'} spacing={1}>
          <Grid item>
            <Button variant="outlined" onClick={handleBack}>
              {t('financial.debts_payment.wizard.pay_review.back_btn')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              onClick={handleSubmitPayment}
              disabled={isLoading}
              endIcon={isLoading && <CircularProgress size={20} />}
            >
              {t('financial.debts_payment.wizard.pay_review.pay_btn')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PayReview;
