import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RequisitionTable } from '../interfaces/requisitionTable.interfaces';
import { Collaborator } from '../../../hr/collaborators/interfaces/collaborators.interfaces';
import { UserPersonPayload } from '../interfaces/userPerson.interface';

import { subjectsTable } from '../interfaces/subjectsRegistered.interfaces';
import { AcademicFormation } from '../interfaces/academicFormation.interface';
import { LaboralExperience } from '../interfaces/laboralExperience.interface';
import { PersonSearch } from '../interfaces/person.interfaces';

interface RequisitionState {
  requisitionList: RequisitionTable[];
  deleteModalOpen: boolean;
  requisitionIdToDelete?: number;

  //Data user person
  userPersonRequisition: UserPersonPayload;

  //Data user person
  personRequisition: PersonSearch;

  //Data Collaborator
  collaboratorRequisition: Collaborator;

  //Subject Registered
  showSubjectsRegistered: boolean;
  listSubjects: subjectsTable[];
  //Academic Formation
  showAcademicFormation: boolean;
  listAcademicFormation: AcademicFormation[];
  //Laboral Experience
  showLaboralExperience: boolean;
  listLaboralExperience: LaboralExperience[];
  // Education Lvl Id Choose
  educationLvlId: number;
  periodId: number;

  observationDialog: {
    open: boolean;
    title: string;
    message: string;
  };

  isCollaborator: boolean;
}

const initialState: RequisitionState = {
  requisitionList: [],
  deleteModalOpen: false,
  //Data Person
  userPersonRequisition: {} as UserPersonPayload,

  //Data User
  personRequisition: {} as PersonSearch,

  collaboratorRequisition: {} as Collaborator,
  //Subject Registered
  showSubjectsRegistered: false,
  listSubjects: [],
  //Academic Formation
  showAcademicFormation: false,
  listAcademicFormation: [],
  //Laboral Experience
  showLaboralExperience: false,
  listLaboralExperience: [],
  //Education Lvl Id Choose
  educationLvlId: 0,
  periodId: 0,

  observationDialog: {
    open: false,
    title: '',
    message: '',
  },

  isCollaborator: false,
};

const requisitionsSlice = createSlice({
  name: 'requisitions',
  initialState,
  reducers: {
    setRequisitionList: (state, action: PayloadAction<RequisitionTable[]>) => {
      state.requisitionList = action.payload;
    },

    //Data Person
    setPersonRequisition: (state, action: PayloadAction<PersonSearch>) => {
      state.personRequisition = action.payload;
    },

    //Data Person
    setUserPersonRequisition: (state, action: PayloadAction<UserPersonPayload>) => {
      state.userPersonRequisition = action.payload;
    },

    //Data Collaborator
    setCollaboratorRequisition: (state, action: PayloadAction<Collaborator>) => {
      state.collaboratorRequisition = action.payload;
    },
    //Subject Registered
    fireDialogSubjectsRegistered: (state, action: PayloadAction<boolean>) => {
      state.showSubjectsRegistered = action.payload;
    },
    setListSubjects: (state, action: PayloadAction<subjectsTable[]>) => {
      state.listSubjects = action.payload.concat(state.listSubjects);
    },
    updateListSubjects: (state, action: PayloadAction<subjectsTable>) => {
      state.listSubjects = state.listSubjects.map((item) => {
        if (item.id === action.payload.id) {
          return action.payload;
        }
        return item;
      });
    },
    resetListSubjects: (state) => {
      state.listSubjects = [];
    },
    deleteListSubject: (state, action: PayloadAction<subjectsTable>) => {
      state.listSubjects = state.listSubjects.filter((item) => item.id !== action.payload.id);
    },
    //Academic Formation
    fireDialogAcademicFormation: (state, action: PayloadAction<boolean>) => {
      state.showAcademicFormation = action.payload;
    },
    setListAcademicFormations: (state, action: PayloadAction<AcademicFormation[]>) => {
      state.listAcademicFormation = action.payload.concat(state.listAcademicFormation);
    },
    updateListAcademicFormations: (state, action: PayloadAction<AcademicFormation>) => {
      state.listAcademicFormation = state.listAcademicFormation.map((item) => {
        if (item?.id === action.payload.id) {
          return action.payload;
        }
        return item;
      });
    },
    resetListAcademicFormations: (state) => {
      state.listAcademicFormation = [];
    },
    deleteListAcademicFormation: (state, action: PayloadAction<AcademicFormation>) => {
      state.listAcademicFormation = state.listAcademicFormation.filter(
        (item) => item?.id !== action.payload.id
      );
    },
    //Laboral Experience
    fireDialogLaboralExperience: (state, action: PayloadAction<boolean>) => {
      state.showLaboralExperience = action.payload;
    },
    setListLaboralExperiences: (state, action: PayloadAction<LaboralExperience[]>) => {
      state.listLaboralExperience = action.payload.concat(state.listLaboralExperience);
    },
    updateListLaboralExperiences: (state, action: PayloadAction<LaboralExperience>) => {
      state.listLaboralExperience = state.listLaboralExperience.map((item) => {
        if (item.id === action.payload.id) {
          return action.payload;
        }
        return item;
      });
    },
    resetListLaboralExperiences: (state) => {
      state.listLaboralExperience = [];
    },
    setEducationLvlId: (state, action: PayloadAction<number>) => {
      state.educationLvlId = action.payload;
    },
    setPeriodId: (state, action: PayloadAction<number>) => {
      state.periodId = action.payload;
    },
    deleteListLaboralExperience: (state, action: PayloadAction<LaboralExperience>) => {
      state.listLaboralExperience = state.listLaboralExperience.filter(
        (item) => item.id !== action.payload.id
      );
    },
    resetAll: (state) => {
      state.listLaboralExperience = [];
      state.listAcademicFormation = [];
      state.listSubjects = [];
      state.userPersonRequisition = {} as UserPersonPayload;
      state.personRequisition = {} as PersonSearch;
    },

    setObservationDialog: (
      state,
      action: PayloadAction<{ open: boolean; title: string; message: string }>
    ) => {
      state.observationDialog = action.payload;
    },

    setIsCollaborator: (state, action: PayloadAction<boolean>) => {
      state.isCollaborator = action.payload;
    },
  },
});

export const {
  setRequisitionList,
  setUserPersonRequisition,
  setCollaboratorRequisition,
  fireDialogSubjectsRegistered,
  setListSubjects,
  updateListSubjects,
  deleteListSubject,
  resetListSubjects,
  fireDialogAcademicFormation,
  setListAcademicFormations,
  updateListAcademicFormations,
  deleteListAcademicFormation,
  resetListAcademicFormations,
  fireDialogLaboralExperience,
  setListLaboralExperiences,
  updateListLaboralExperiences,
  deleteListLaboralExperience,
  resetListLaboralExperiences,
  setEducationLvlId,
  setPeriodId,
  setPersonRequisition,
  resetAll,
  setObservationDialog,
  setIsCollaborator,
} = requisitionsSlice.actions;

export default requisitionsSlice.reducer;
