import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../hooks/useRedux';
import MainCard from '../../../shared/components/cards/MainCard';
import BooksTable from '../components/BooksTable';
import DeleteBookDialog from '../components/DeleteBookDialog';

const BooksScreen = () => {
  const { t } = useTranslation();
  const { bookToDelete } = useAppSelector((state) => state.books);
  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">{t('books.title')}</Typography>
      </Grid>

      <BooksTable />

      {bookToDelete && <DeleteBookDialog />}
    </Grid>
  );
};

export default BooksScreen;
