import { useTranslation } from 'react-i18next';
import DialogAlert from '../../../shared/components/dialogs/DialogAlert';
import { useReverseOfPaymentReceiptsCheckMutation } from '../slices/receiptApiSlice';

interface Props {
  open: boolean;
  receiptId: number;
  handleClose: () => void;
}
const ReverseDialogCheck = ({ open, receiptId, handleClose }: Props) => {
  const { t } = useTranslation();

  const [
    debtsReverseCheckRequest,
    { isLoading: isLoadingDC, isSuccess: isSuccessDC, isError: isErrorDC, error: errorDC },
  ] = useReverseOfPaymentReceiptsCheckMutation();

  //trigger cancelation debts
  const handleCancellation = () => {
    debtsReverseCheckRequest({
      receiptId,
    });
  };

  return (
    <>
      <DialogAlert
        open={open} //{openDebtsCancellation}
        isCloseClickOutComponent={false}
        onClose={handleClose}
        handleAction={handleCancellation}
        isLoading={isLoadingDC}
        isSuccess={isSuccessDC}
        isError={isErrorDC}
        error={errorDC as string}
        title={t('financial.receipts.reverse_dialog.revert_check.title')}
        bodyText={t('financial.receipts.reverse_dialog.revert_check.body')}
        updateSuccess={t('financial.receipts.reverse_dialog.revert_check.success')}
        customTitleSendButton={t('shared.accept_button.label')}
        customTitleCancelButton={t('shared.cancel_button.label')}
      />
    </>
  );
};

export default ReverseDialogCheck;
