import { useState } from 'react';
import { Grid, Stepper, StepLabel, Step, Box } from '@material-ui/core';

import Step1 from './steps/Step1';
import Step2 from './steps/Step2';
import Step3 from './steps/Step3';
import Step4 from './steps/Step4';

const AddBook = () => {
  const steps = [
    'Información General del Libro',
    'Ubicación de Material Bibliográfico',
    'Procedencia',
    'Datos del Proveedor',
  ];
  const [activeStep, setActiveStep] = useState(0);
  const handleNext = () => setActiveStep(activeStep + 1);
  const handleBack = () => setActiveStep(activeStep - 1);

  return (
    <>
      <Box sx={{ width: '100%', mb: 3 }}>
        <Stepper activeStep={activeStep} sx={{ maxWidth: '1000px', margin: '0 auto' }}>
          {steps.map((label, index) => {
            const stepProps: { completed?: boolean } = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Box>
      <Grid container gap={2}>
        <Grid item container xs={12} md={12} spacing={2} alignItems="center">
          <Step1 isActive={Boolean(activeStep === 0)} handleNext={handleNext} />
          <Step2
            isActive={Boolean(activeStep === 1)}
            handleNext={handleNext}
            handleBack={handleBack}
          />
          <Step3
            isActive={Boolean(activeStep === 2)}
            handleNext={handleNext}
            handleBack={handleBack}
          />
          <Step4 isActive={Boolean(activeStep === 3)} handleBack={handleBack} />
        </Grid>
      </Grid>
    </>
  );
};

export default AddBook;
