import { useMemo } from 'react';
import IconButton from '@material-ui/core/IconButton';
import useTheme from '@material-ui/core/styles/useTheme';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { IconArrowNarrowLeft } from '@tabler/icons';
import { useHistory, useParams } from 'react-router-dom';

import MainCard from '../../../../../../shared/components/cards/MainCard';
import TasksTable from '../components/TasksTable';
import { useAppSelector } from '../../../../../../../hooks/useRedux';
import { useGetProjectQuery } from '../../../../slices/InvestigationLinesApiSlice';
import { Skeleton } from '@material-ui/core';

const TasksScreen = () => {
  const theme = useTheme();

  const history = useHistory();

  const { projectId } = useParams<{ projectId: string }>();

  const { projectList } = useAppSelector((state) => state.investigationLines);

  const projectData = useMemo(
    () => projectList?.find(({ id }) => id === projectId),
    [projectList, projectId]
  );

  const { data, isLoading } = useGetProjectQuery({ projectId }, { skip: !!projectData?.id });

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid item container alignItems="center" gap={1} sx={{ cursor: 'pointer' }}>
        <IconButton onClick={() => history.goBack()}>
          <IconArrowNarrowLeft size={30} color={theme.palette.text.dark} />
        </IconButton>
        <Typography variant="h2">Tareas</Typography>
      </Grid>

      {!isLoading ? (
        <TasksTable data={projectData || data!} />
      ) : (
        <Skeleton variant="rectangular" height="30vh" />
      )}
    </Grid>
  );
};

export default TasksScreen;
