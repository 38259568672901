import { Grid, IconButton, Typography } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useAppSelector } from '../../../../hooks/useRedux';
import MainCard from '../../../shared/components/cards/MainCard';
import PosgraduatePlanningCohortsForm from '../components/PosgraduatePlanningCohortsForm';
import { useLazyGetPosgraduatePlanningCohortQuery } from '../slices/posgraduatePlanningCohortApiSlice';
import { setPosgraduatePlanningCohortsList } from '../slices/posgraduatePlanningCohortSlice';

const EditPosgraduatePlanningCohortsScreen = () => {
  const history = useHistory();

  const { currentProfile } = useAppSelector((state) => state.access);
  const { id } = useParams<{ id: string }>();

  const { posgraduatePlanningCohortsList, posgraduatePlanningCohortIdSelected } = useAppSelector(
    (state) => state.posgraduatePlanningCohorts
  );

  const [getPosgraduatePlanningCohort, { isLoading }] = useLazyGetPosgraduatePlanningCohortQuery();

  useEffect(() => {
    if (posgraduatePlanningCohortsList.length === 0) {
      getPosgraduatePlanningCohort({
        profileId: currentProfile?.profileId!,
        id: posgraduatePlanningCohortIdSelected || id,
      }).then((res) => {
        setPosgraduatePlanningCohortsList([res!]);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid component={MainCard} sx={{ p: 2 }}>
      <Grid container alignItems="center" sx={{ mb: 3 }}>
        <IconButton
          onClick={() => {
            history.goBack();
          }}
        >
          <ArrowBack />
        </IconButton>
        <Typography variant="h2">Editar horarios de posgrado</Typography>
      </Grid>
      {!isLoading && posgraduatePlanningCohortsList.length > 0 && (
        <PosgraduatePlanningCohortsForm />
      )}
    </Grid>
  );
};

export default EditPosgraduatePlanningCohortsScreen;
