import { useState } from 'react';

import { Button, Dialog, Grid } from '@material-ui/core';

import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import {
  useProcessMassiveDebtsByStudentMutation,
  useProcessMassiveDebtsMutation,
} from '../../slices/generateDebtsApiSlice';
import SelectClient from '../GenerateDebts/SelectClient';
import SelectPeriodTable from './SelectPeriodTable';
import ErrorAlert from '../../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../../shared/components/alerts/SuccessAlert';
import {
  resetClientMassiveDebts,
  setCurrentPeriodIdMassiveDebts,
} from '../../slices/generateDebtsSlice';
import { DataPostMassiveDebtsResponse } from '../../interfaces/productStudentRecord.interface';
import DetailsGeneratedDebtsDialog from './DetailsGeneratedDebtsDialog';
import { useControlModalStates } from '../../../../shared/hooks/useControlModalStates';

const GenerateMassiveDebts = () => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const {
    currentClientMassiveDebts,
    currentPeriodIdMassiveDebts,
    currentStudentRecordMassiveDebts,
  } = useAppSelector((state) => state.generateDebts);

  const [showAlerts, setShowAlerts] = useState<number>(1);
  const [triggerState, setTriggerState] = useState<undefined | 'MD' | 'MDS'>();
  // MD = deudas masivas sin estudiante; MDS = deudas con estudiante
  // const [data, setData] = useState<any[] | DataPostMassiveDebtsResponse>([]);
  const [data, setData] = useState<DataPostMassiveDebtsResponse | null>(null);
  //Condicionar mensaje si es masiva o por estudiante
  const [isMassive, setIsMassive] = useState(false);

  const { open, handleOpenModalWith, handleCloseModal } = useControlModalStates();

  const [
    trigger,
    {
      // data: dataDebtsByStudent,
      error: errorDebtsByStudent,
      isError: isErrorDebtsByStudent,
      isLoading: isLoadingDebtsByStudent,
      isSuccess: isSuccessDebtsByStudent,
    },
  ] = useProcessMassiveDebtsByStudentMutation();

  const [
    triggerMassiveDebts,
    {
      // data: dataMassiveDebts,
      error: errorMassiveDebts,
      isError: isErrorMassiveDebts,
      isLoading: isLoadingMassiveDebts,
      isSuccess: isSuccessMassiveDebts,
    },
  ] = useProcessMassiveDebtsMutation();

  const massiveDebtsByStudentHandler = async (customPeriodId?: number) => {
    try {
      if (customPeriodId) dispatch(setCurrentPeriodIdMassiveDebts(customPeriodId));

      if (currentClientMassiveDebts) {
        // MD = deudas masivas sin estudiante; MDS = deudas con estudiante
        setTriggerState('MDS');
        const response = await trigger({
          periodId: customPeriodId || currentPeriodIdMassiveDebts!,
          studentId: currentClientMassiveDebts?.user.student?.id!,
          studentRecordId: currentStudentRecordMassiveDebts?.id!,
        }).unwrap();

        setIsMassive(false);
        setData(response);
      } else {
        // MD = deudas masivas sin estudiante; MDS = deudas con estudiante
        setTriggerState('MD');
        const response = await triggerMassiveDebts({
          periodId: customPeriodId || currentPeriodIdMassiveDebts!,
        }).unwrap();

        setIsMassive(true);
        setData(response);
      }

      dispatch(resetClientMassiveDebts());
      setShowAlerts(2);
      window.scrollTo(0, 0);
    } catch (error) {
      setShowAlerts(1);
      window.scrollTo(0, 0);
    }
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {(isErrorDebtsByStudent || isErrorMassiveDebts) && showAlerts && showAlerts === 1 && (
            <ErrorAlert
              message={
                triggerState === 'MD'
                  ? (errorMassiveDebts as string)
                  : triggerState === 'MDS'
                  ? (errorDebtsByStudent as string)
                  : ''
              }
              handleDismiss={() => setShowAlerts(0)}
            />
          )}

          {(isSuccessDebtsByStudent || isSuccessMassiveDebts) && showAlerts && showAlerts === 2 && (
            <SuccessAlert
              messageComponent={
                isMassive ? (
                  <>
                    <p>{t('financial.generate_debts.massive.success_alert')}</p>
                    <ul>
                      <li>
                        {t('financial.generate_debts.massive.success_alert_fees_generated')}
                        {data?.fees_generated.length}
                      </li>
                      <li>
                        {t('financial.generate_debts.massive.success_alert_debts_have_payment')}
                        {data?.debts_have_payment.length}
                      </li>
                      <li>
                        {t(
                          'financial.generate_debts.massive.success_alert_fees_previewed_generated'
                        )}
                        {data?.fees_previewed_generated.length}{' '}
                      </li>
                      <li>
                        {t('financial.generate_debts.massive.success_alert_debts_deleted')}
                        {data?.debts_deleted.length}
                      </li>
                    </ul>
                    <Button onClick={() => handleOpenModalWith()}>
                      {t('financial.generate_debts.massive.success_alert_view_details_btn')}
                    </Button>
                  </>
                ) : (
                  <>{t('financial.generate_debts.massive.success_alert')}</>
                )
              }
              handleDismiss={() => setShowAlerts(0)}
            />
          )}
        </Grid>

        <Grid item xs={12}>
          <SelectClient currentClient={currentClientMassiveDebts} isOptional />
        </Grid>

        <Grid item xs={12}>
          <SelectPeriodTable
            massiveDebtsByStudentHandler={massiveDebtsByStudentHandler}
            isLoading={isLoadingDebtsByStudent || isLoadingMassiveDebts}
          />
        </Grid>

        {/* {false && (
          <Grid item xs={12} sx={{ textAlign: 'right' }}>
            <Button
              variant="contained"
              size="large"
              onClick={() => massiveDebtsByStudentHandler()}
              disabled={
                !currentPeriodIdMassiveDebts || isLoadingDebtsByStudent || isLoadingMassiveDebts
              }
              endIcon={
                (isLoadingDebtsByStudent || isLoadingMassiveDebts) && <CircularProgress size={20} />
              }
            >
              {t('financial.generate_debts.massive.generate_debts_btn')}
            </Button>
          </Grid>
        )} */}
      </Grid>

      <Dialog
        open={open}
        onClose={handleCloseModal}
        sx={{ p: 3, paper: { maxWidth: '800px', width: '90%' } }}
      >
        {open && <DetailsGeneratedDebtsDialog data={data} />}
      </Dialog>
    </>
  );
};

export default GenerateMassiveDebts;
