import { ThunkDispatch } from 'redux-thunk';
import { setTypeQuestion } from '../slices/evaluationSlice';

/**
 *
 * @param value Establecer el tipo de selección de pregunta.
 * @param dispatch
 */
export const selectTypeQuestion = async (value: string, dispatch: ThunkDispatch<any, any, any>) => {
  switch (value) {
    case 'Opcion Seleccion':
      await dispatch(setTypeQuestion('single'));
      break;
    case 'Opcion Multiple':
      await dispatch(setTypeQuestion('multiple'));
      break;
    case 'Comentario':
      await dispatch(setTypeQuestion('comentary'));
      break;
      case 'Archivo - Herramientas':
      await dispatch(setTypeQuestion('imagen'));
      break;
  }
};

export const navigateBottom = function () {
  window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: 'smooth' });
};

export const navigateBottomSmooth = function () {
  setTimeout(() => {
    window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: 'smooth' });
  });
};

export const navigateTop = () => {
  window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
};
