import { Grid, useMediaQuery, useTheme } from '@material-ui/core';
// import { CircleRounded } from '@material-ui/icons';
import { IQuestion } from '../../../question/interfaces/questions.interface';

interface EvaluationPreviewScopeQuestionProps {
  question: IQuestion;
}

const EvaluationPreviewScopeQuestion = ({ question }: EvaluationPreviewScopeQuestionProps) => {
  const theme = useTheme();

  const isSmall = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Grid
      container
      p={2}
      alignItems="center"
      justifyContent="space-between"
      sx={{
        background: theme.palette.background.paper,
        borderRadius: '12px',
      }}
    >
      <Grid item xs={12} md={6} mb={isSmall ? 1 : 0}>
        {question.description}
      </Grid>
      {/* <Grid item>
        <RadioGroup
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: 1,
          }}
          name={`${question.uuid}-option-response`}
        >
          {question.template?.options?.map((option) => (
            <FormControlLabel
              control={
                <Radio
                  icon={<CircleRounded color="disabled" />}
                  checkedIcon={<CircleRounded color="primary" />}
                />
              }
              value={option.uuid}
              label={option.description || `${option.value}`}
              labelPlacement="top"
            />
          ))}
        </RadioGroup>
      </Grid> */}
    </Grid>
  );
};

export default EvaluationPreviewScopeQuestion;
