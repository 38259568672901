import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISearchTable } from '../../../shared/interfaces/paginatedTable.interface';

interface DepartmentsState {
  sendDebtsTable: ISearchTable;
  sendPaysTable: ISearchTable;
  updateBillsTable: ISearchTable;
  migrationDialogTable: ISearchTable;
}

const initialState: DepartmentsState = {
  sendDebtsTable: {
    page: 1,
    perPage: 5,
    search: '',
  },
  sendPaysTable: {
    page: 1,
    perPage: 5,
    search: '',
  },
  updateBillsTable: {
    page: 1,
    perPage: 5,
    search: '',
  },
  migrationDialogTable: {
    page: 1,
    perPage: 5,
    search: '',
  },
};

const contificoMigrationsSlice = createSlice({
  name: 'contificoMigrations',
  initialState,
  reducers: {
    updateSendDebtsTable: (state, action: PayloadAction<ISearchTable>) => {
      state.sendDebtsTable = action.payload;
    },
    updateSendPaysTable: (state, action: PayloadAction<ISearchTable>) => {
      state.sendPaysTable = action.payload;
    },
    updateUpdateBillTable: (state, action: PayloadAction<ISearchTable>) => {
      state.updateBillsTable = action.payload;
    },
    updateMigrationDialogTable: (state, action: PayloadAction<ISearchTable>) => {
      state.migrationDialogTable = action.payload;
    },
  },
});

export const {
  updateSendDebtsTable,
  updateSendPaysTable,
  updateUpdateBillTable,
  updateMigrationDialogTable,
} = contificoMigrationsSlice.actions;

export default contificoMigrationsSlice.reducer;
