import { mainApi } from '../../../../api/mainApi';
import { middlewareApi } from '../../../middleware/middlewareApi';

const visitsApi = middlewareApi.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * @POST
     */
    registerVisit: builder.mutation<
      any,
      {
        payload: {
          type_visit: string;
          date_visit: string;
          type_person: string;
          person_id: number;
          education_level_id?: number;
          student_record_id?: number;
          services: {
            model: string;
            model_id?: number;
          }[];
        };
      }
    >({
      queryFn: async ({ payload }) => {
        try {
          const { data } = await mainApi.post<any>('library-metrics', {
            ...payload,
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),
  }),
});

export const { useRegisterVisitMutation } = visitsApi;
