import { Autocomplete, Grid, IconButton, TextField, Tooltip, Typography } from '@material-ui/core';
import { Comment, VisibilityOffOutlined } from '@material-ui/icons';
import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../../../hooks/useRedux';
import Chip from '../../../../../shared/components/extended/Chip';
import InputLabel from '../../../../../shared/components/forms/InputLabel';
import SearchPaginatedTable from '../../../../../shared/components/tables/SearchPaginatedTable';
import { useGetCatalogByAcronymQuery } from '../../../../../shared/slices/catalogApi';
import { useGetQualificationsQuery } from '../../../slices/virtualClassroomApiSlice';

const QualificationsVirtualClassroomList = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState<number>(1);

  const [perPage, setPerPage] = useState<number>(5);

  const [search, setSearch] = useState<string>('');

  const [activityType, setActivityType] = useState(0);

  const {
    course: { courseId },
  } = useAppSelector((state) => state.virtualClassroomStudentShared);

  const { currentStudent } = useAppSelector((state) => state.access);

  const { data, isLoading, error, isFetching } = useGetQualificationsQuery({
    courseId: courseId?.toString()!,
    studentId: currentStudent?.id.toString()!,
    page,
    size: perPage,
    search,
    activityType,
  });

  const { data: activityCatalog, isLoading: isLoadingCat } = useGetCatalogByAcronymQuery({
    acronym: 'AVTA',
  });

  const dataFetch =
    data &&
    data?.data?.map((item) => {
      const { course_activity_one } = item;
      return {
        item: item?.id,
        description: item?.act_description!,
        endDate: moment(course_activity_one?.cact_end_date!).format('MMMM Do YYYY, h:mm a'),
        state: course_activity_one?.cact_end_date!,
        qualification: {
          scoreActivity: item.act_score_activity!,
          score: course_activity_one?.cab_course_student_activity!,
          isVisible: Boolean(Number(item?.visible_note)),
        },
        numTries: {
          numTries: item.act_num_tries!,
          numtriesRealizated: course_activity_one?.cab_course_student_activity!,
        },
        comments: course_activity_one?.cab_course_student_activity!,
      };
    });

  return (
    <Grid>
      <SearchPaginatedTable
        data={dataFetch!}
        headers={{
          item: t('studentVirtualClassroom.test.code'),
          description: t('studentVirtualClassroom.test.description'),
          endDate: t('studentVirtualClassroom.content.end.date'),
          state: t('studentVirtualClassroom.test.status'),
          qualification: t('studentVirtualClassroom.test.qualification'),
          numTries: t('studentVirtualClassroom.test.numberTries'),
          comments: t('studentVirtualClassroom.test.options'),
        }}
        total={data?.total! || 0}
        keyExtractor={(item) => item.item.toString()}
        isLoading={isLoading}
        isFetching={isFetching}
        page={page}
        setPage={setPage}
        perPage={perPage}
        setPerPage={setPerPage}
        search={search}
        setSearch={setSearch}
        searchPlacehoder={t('studentVirtualClassroom.test.search')}
        error={error}
        numHeader={7}
        showFilter={false}
        customDataCellsProperties={{
          item: {
            align: 'center',
          },
          description: {
            align: 'center',
          },
          endDate: {
            align: 'center',
          },
          state: {
            align: 'center',
          },
          qualification: {
            align: 'center',
          },
          numTries: {
            align: 'center',
          },
          comments: {
            align: 'center',
          },
        }}
        customHeadersCellsProperties={{
          item: {
            align: 'center',
          },
          description: {
            align: 'center',
          },
          endDate: {
            align: 'center',
          },
          state: {
            align: 'center',
          },
          qualification: {
            align: 'center',
          },
          numTries: {
            align: 'center',
          },
          comments: {
            align: 'center',
          },
        }}
        customRenderers={{
          qualification: ({ qualification: { scoreActivity, score, isVisible }, state }) => (
            <>
              {isVisible ? (
                score.length > 0 && score[0].act_score ? (
                  <Typography>{`${Number(score[0].act_score)}/${Number(
                    scoreActivity
                  )}`}</Typography>
                ) : (
                  <Typography>{`${moment().isBefore(state) ? '--' : '0'}/${Number(
                    scoreActivity
                  )}`}</Typography>
                )
              ) : (
                <Tooltip title={t('studentVirtualClassroom.test.card.description.hideNote')}>
                  <span style={{ cursor: 'pointer' }}>
                    <IconButton
                      disabled={true}
                      aria-label={t('studentVirtualClassroom.test.startTest')}
                      color="secondary"
                    >
                      <VisibilityOffOutlined />
                    </IconButton>
                  </span>
                </Tooltip>
              )}
            </>
          ),
          numTries: ({ numTries: { numTries, numtriesRealizated } }) => (
            <>
              {numtriesRealizated.length > 0 &&
              numtriesRealizated[0].num_tries_realized !== null ? (
                <Typography>{`${Number(numtriesRealizated[0].num_tries_realized)}/${Number(
                  numTries
                )}`}</Typography>
              ) : (
                <Typography>{`0/${numTries}`}</Typography>
              )}
            </>
          ),
          state: ({ state }) => (
            <>
              {moment().isBefore(state) ? (
                <Chip
                  label={t('studentVirtualClassroom.test.active')}
                  size="small"
                  chipcolor="success"
                />
              ) : (
                <Chip
                  label={t('studentVirtualClassroom.test.finished')}
                  size="small"
                  chipcolor="error"
                />
              )}
            </>
          ),

          comments: ({ comments }) => (
            <>
              {comments.length > 0 && comments[0].comments !== null ? (
                <Tooltip title={comments[0].comments!}>
                  <span>
                    <IconButton
                      disabled={false}
                      aria-label={t('studentVirtualClassroom.test.startTest')}
                      color="secondary"
                    >
                      <Comment />
                    </IconButton>
                  </span>
                </Tooltip>
              ) : (
                <Tooltip title={''}>
                  <span style={{ cursor: 'pointer' }}>
                    <IconButton
                      disabled={true}
                      aria-label={t('studentVirtualClassroom.test.startTest')}
                      color="secondary"
                    >
                      <Comment />
                    </IconButton>
                  </span>
                </Tooltip>
              )}
            </>
          ),
        }}
        additionalFilters={
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <InputLabel>
                {/* {t('teacherVirtualClassroom.tasks.table.input.title.status')} */}
                Tipo de actividad
              </InputLabel>
            </Grid>

            <Grid item>
              <Autocomplete
                disablePortal
                getOptionLabel={(option) => option.cat_name}
                loading={isLoadingCat}
                options={activityCatalog || []}
                fullWidth
                onChange={(_, value) => {
                  setActivityType(value?.id || 0);
                }}
                sx={{
                  minWidth: 300,
                }}
                renderInput={(params) => <TextField {...params} label={'Actividad'} />}
              />
            </Grid>
          </Grid>
        }
      />
    </Grid>
  );
};

export default QualificationsVirtualClassroomList;
