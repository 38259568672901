import { Switch } from 'react-router-dom';

// import { useAppSelector } from '../../../../hooks/useRedux';
import GuardRoute from '../../../../router/guards/GuardRoute';
import ContificoMigrationsScreen from '../screen/ContificoMigrationsScreen';

function ContificoMigrationsRouter() {
  //   const { currentModule } = useAppSelector((state) => state.access);
  //   const hasPermission = permissionKey in currentModule?.permissions!;
  //   console.log(hasPermission);

  return (
    <Switch>
      <GuardRoute exact path="/migraciones-contifico" component={ContificoMigrationsScreen} />
      <GuardRoute exact path="/migraciones-contifico/:step" component={ContificoMigrationsScreen} />
    </Switch>
  );
}

export default ContificoMigrationsRouter;
