import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StudentInfo } from '../../../../types';
import { MatterMesh } from '../../../maintenance/meshs/interfaces/curriculum.interfaces';
import { HourhandCardValues } from '../components/horarios/HoraPlaceholder';
import { SubjectCurriculumWithStatus } from '../components/mallas/SubjectCard';
import { CourseAvailable } from '../interfaces/coursesAvailables.interfaces';
import { CourseStudentStatus } from '../interfaces/courseStudentStatus';

interface UnavailableRegisterDialog {
  open: boolean;
  message: string;
}
interface SubjectsRegisterState {
  showSubjectsRegistered: boolean;
  showCurriculumAssignModal: boolean;
  showHourhandAssignModal: boolean;
  showInfoSubjectModal: boolean;
  showRegisterDebtsModal: boolean;
  showSubjectsOnline: boolean;
  deleteModalOpen: boolean;
  subjectHoverId?: number;
  currentSubject?: SubjectCurriculumWithStatus;
  currentPeriodId?: number;
  courseStudentIdToDelete?: number;
  currentHourhand?: HourhandCardValues;
  currentSubjectsRegistered?: CourseStudentStatus[];
  currentInfoSubject?: CourseStudentStatus;
  studentInfoAcademic?: StudentInfo;
  isPayment?: boolean;
  isOnline?: boolean;
  carSubjects: CourseAvailable[];
  subjectsCurriculum?: MatterMesh[];

  subjectStatusIdSelected: string | null;

  unavailableRegisterDialog: UnavailableRegisterDialog;
}

const initialState: SubjectsRegisterState = {
  showSubjectsRegistered: false,
  showCurriculumAssignModal: false,
  showHourhandAssignModal: false,
  deleteModalOpen: false,
  showInfoSubjectModal: false,
  showRegisterDebtsModal: false,
  showSubjectsOnline: false,
  carSubjects: [] as CourseAvailable[],

  subjectStatusIdSelected: null,

  unavailableRegisterDialog: {
    open: false,
    message: '',
  },
};

const subjectsRegisterSlice = createSlice({
  name: 'subjectsRegister',
  initialState,
  reducers: {
    resetSubjectsRegisterState: () => initialState,
    resetEstudentInfoAcademic: (state) => {
      state.studentInfoAcademic = undefined;
    },
    resetCurrentSubjectsRegistered: (state) => {
      state.currentSubjectsRegistered = undefined;
    },
    resetCurrentPeriodId: (state) => {
      state.currentPeriodId = undefined;
    },
    fireDialogSubjectsRegistered: (state, action: PayloadAction<boolean>) => {
      state.showSubjectsRegistered = action.payload;
    },
    setShowCurriculumAssignModal: (state, action: PayloadAction<boolean>) => {
      state.showCurriculumAssignModal = action.payload;
    },
    setSubjectHoverId: (state, action: PayloadAction<number>) => {
      state.subjectHoverId = action.payload;
    },
    resetSubjectHoverId: (state) => {
      state.subjectHoverId = undefined;
    },
    setCurrentPeriodId: (state, action: PayloadAction<number>) => {
      state.currentPeriodId = action.payload;
    },
    setCurrentSubject: (state, action: PayloadAction<SubjectCurriculumWithStatus>) => {
      state.currentSubject = action.payload;
    },
    resetCurrentSubject: (state) => {
      state.currentSubject = undefined;
    },
    setDeleteModalOpen: (state, action: PayloadAction<boolean>) => {
      state.deleteModalOpen = action.payload;
    },
    openDeleteModalWith: (state, action: PayloadAction<number>) => {
      state.courseStudentIdToDelete = action.payload;
      state.deleteModalOpen = true;
    },
    resetCourseStudentIdToDelete: (state) => {
      state.courseStudentIdToDelete = undefined;
    },
    setShowHourhandAssignModal: (state, action: PayloadAction<boolean>) => {
      state.showHourhandAssignModal = action.payload;
    },
    setCurrentHourhand: (state, action: PayloadAction<HourhandCardValues>) => {
      state.currentHourhand = action.payload;
    },
    resetCurrentHourhand: (state) => {
      state.currentHourhand = undefined;
    },
    setCurrentSubjectsRegistered: (state, action: PayloadAction<CourseStudentStatus[]>) => {
      state.currentSubjectsRegistered = action.payload;
    },
    setShowInfoSubjectModal: (state, action: PayloadAction<boolean>) => {
      state.showInfoSubjectModal = action.payload;
    },
    setCurrentInfoSubject: (state, action: PayloadAction<CourseStudentStatus>) => {
      state.currentInfoSubject = action.payload;
    },
    resetCurrentInfoSubject: (state) => {
      state.currentInfoSubject = undefined;
    },
    setShowRegisterDebtsModal: (state, action: PayloadAction<boolean>) => {
      state.showRegisterDebtsModal = action.payload;
    },
    setEstudentInfoAcademic: (state, action: PayloadAction<StudentInfo>) => {
      state.studentInfoAcademic = action.payload;
    },
    setIsPayment: (state, action: PayloadAction<boolean>) => {
      state.isPayment = action.payload;
    },
    setIsOnline: (state, action: PayloadAction<boolean>) => {
      state.isOnline = action.payload;
    },
    fireDialogSubjectsOnline: (state, action: PayloadAction<boolean>) => {
      state.showSubjectsOnline = action.payload;
    },
    setCarSubjects: (state, action: PayloadAction<CourseAvailable>) => {
      state.carSubjects = [...state.carSubjects, action.payload];
    },
    setSubjectsCurriculum: (state, action: PayloadAction<MatterMesh[]>) => {
      state.subjectsCurriculum = action.payload;
    },
    setCarSubjectsList: (state, action: PayloadAction<CourseAvailable[]>) => {
      state.carSubjects = action.payload;
    },
    setSubjectStatusIdSelected: (state, action: PayloadAction<string | null>) => {
      state.subjectStatusIdSelected = action.payload;
    },

    setUnavailableRegisterDialog: (state, action: PayloadAction<UnavailableRegisterDialog>) => {
      state.unavailableRegisterDialog = action.payload;
    },
  },
});

export const {
  fireDialogSubjectsRegistered,
  setShowCurriculumAssignModal,
  setSubjectHoverId,
  resetSubjectHoverId,
  setCurrentSubject,
  resetCurrentSubject,
  setCurrentPeriodId,
  resetCurrentPeriodId,
  setDeleteModalOpen,
  openDeleteModalWith,
  resetCourseStudentIdToDelete,
  setShowHourhandAssignModal,
  setCurrentHourhand,
  resetCurrentHourhand,
  setCurrentSubjectsRegistered,
  setShowInfoSubjectModal,
  setCurrentInfoSubject,
  resetCurrentInfoSubject,
  setShowRegisterDebtsModal,
  setEstudentInfoAcademic,
  resetEstudentInfoAcademic,
  resetCurrentSubjectsRegistered,
  setIsPayment,
  resetSubjectsRegisterState,
  setIsOnline,
  fireDialogSubjectsOnline,
  setCarSubjects,
  setSubjectsCurriculum,
  setCarSubjectsList,
  setSubjectStatusIdSelected,

  setUnavailableRegisterDialog,
} = subjectsRegisterSlice.actions;

export default subjectsRegisterSlice.reducer;
