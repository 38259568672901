import {
  Alert,
  Button,
  CircularProgress,
  //FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  //Checkbox,
  //Autocomplete,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../../hooks/useRedux';
import InputLabel from '../../../shared/components/forms/InputLabel';
//import { StatusApplication } from '../interfaces/statusApplication.interface';
import {
  useGetApplicationStatusQuery,
  useGetDetailsStatusQuery,
  useUpdateStatusApplicationStatusMutation,
} from '../slices/requestsStatusApiSlice';
import CheckBoxControl from './CheckBoxControl';
import * as Yup from 'yup';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
// interface optionType {
//   label: string;
//   value: string | number;
// }

const EditRequestStatus = () => {
  const { id } = useParams<{ id: string }>();
  const [showAlerts, setShowAlerts] = useState(true);
  //const { requestStatus } = useAppSelector(state => state.requestsStatus);
  const { currentProfile } = useAppSelector((state) => state.access);

  const [statusIdDefault, setStatusIdDefault] = useState<string[]>([]);
  const [statusChecked, setStatusChecked] = useState<string[]>([]);
  //const [statusOptions, setStatusOptions] = useState<optionType[]>();

  const { data, isSuccess, isLoading } = useGetDetailsStatusQuery(
    {
      user_profile_id: currentProfile?.profileId!,
      id_application: Number(id),
      data_: 'all',
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const {
    data: applicationStatus,
    isSuccess: isSuccessAS,
    isLoading: isLoadingAS,
  } = useGetApplicationStatusQuery(
    {
      user_profile_id: currentProfile?.profileId!,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const [
    updateStatus,
    {
      isSuccess: isSuccessUpdate,
      isLoading: isLoadingUpdate,
      isError: isErrorUpdate,
      error: errorUpdate,
    },
  ] = useUpdateStatusApplicationStatusMutation();

  useEffect(() => {
    if (data && data.length > 0) {
      let uniqe = [...new Set(data.map((d) => d.status_id))];
      setStatusIdDefault(uniqe);
    }
  }, [data]);

  const updateSchema = Yup.object().shape({
    status_id: Yup.array().min(1).required('Seleccione los estados'),
  });

  const formik = useFormik({
    validationSchema: updateSchema,
    initialValues: {
      status_id: statusChecked,
    },
    onSubmit: (values) => {
      //alert(JSON.stringify(values))

      updateStatus({
        status_id: values.status_id,
        user_profile_id: currentProfile?.profileId!,
        type_application_id: Number(id),
      });
    },
  });

  useEffect(() => {
    formik.setValues({
      status_id: statusChecked,
    });
  }, [statusChecked]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <form onSubmit={formik.handleSubmit}>
      {!isLoading && isSuccess && isSuccessAS && (
        <Grid container item direction="column" xs={12} md={6} spacing={3}>
          <Grid item xs={12} md={6}>
            {isErrorUpdate && showAlerts && (
              <ErrorAlert
                message={errorUpdate as string}
                handleDismiss={() => setShowAlerts(false)}
              />
            )}
            {isSuccessUpdate && showAlerts && (
              <SuccessAlert
                message="El estado se ha actualizado correctamente"
                handleDismiss={() => setShowAlerts(false)}
              />
            )}
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <InputLabel>Nombre Solicitud</InputLabel>
            <TextField
              value={data ? data[0].type_application_name : ''}
              contentEditable={false}
              fullWidth
            />
          </Grid>

          <Grid container item xs={12} md={6} lg={6} spacing={2}>
            <Grid item xs={12} md={6}>
              <InputLabel>Estados</InputLabel>
              {!isLoadingAS && isSuccessAS && applicationStatus?.length === 0 && (
                <Alert severity="error">No existen estados disponibles</Alert>
              )}

              {!isLoadingAS && isSuccessAS && applicationStatus && statusIdDefault.length > 0 ? (
                <FormGroup>
                  {applicationStatus.map((as) => (
                    <CheckBoxControl
                      key={as.id}
                      label={as.st_name}
                      id={String(as.id)}
                      isChecked={Boolean(statusIdDefault.find((st) => st === String(as.id)))}
                      states={statusChecked}
                      setStates={setStatusChecked}
                    />
                  ))}
                </FormGroup>
              ) : null}
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel></InputLabel>
              <div
                style={{
                  border: '1px solid gainsboro',
                  padding: 14,
                  backgroundColor: 'whitesmoke',
                  height: '100%',
                  borderRadius: 10,
                }}
              >
                <label htmlFor="" style={{ fontWeight: 600 }}>
                  Orden de estados seleccionados
                </label>
                {statusChecked.length === 0 && <p>No ha seleccionado ningún estado</p>}
                {statusChecked.length > 0 && (
                  <ul>
                    {statusChecked.map((st, index) => {
                      if (
                        applicationStatus?.find((el) => el.id === Number(st))?.st_name ===
                        'Rechazado'
                      ) {
                        return (
                          <li key={st}>
                            {applicationStatus?.find((el) => el.id === Number(st))?.st_name || ''}
                          </li>
                        );
                      } else {
                        return (
                          <li key={st}>
                            {`${
                              index +
                              (applicationStatus?.find((el) => el.id === Number(statusChecked[0]))
                                ?.st_name === 'Rechazado'
                                ? 0
                                : 1)
                            }). `}
                            {applicationStatus?.find((el) => el.id === Number(st))?.st_name || ''}
                          </li>
                        );
                      }
                    })}
                  </ul>
                )}
              </div>
            </Grid>
            {formik.errors.status_id && formik.touched.status_id && (
              <p style={{ color: 'red' }}>{formik.errors.status_id}</p>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Button
              variant="contained"
              type="submit"
              disabled={isLoadingUpdate}
              endIcon={isLoadingUpdate && <CircularProgress size={20} />}
            >
              Guardar
            </Button>
          </Grid>
        </Grid>
      )}
    </form>
  );
};

export default EditRequestStatus;
