// import { Redirect } from 'react-router-dom';
import { Switch } from 'react-router-dom';
import GuardRoute from '../../../../router/guards/GuardRoute';
// import { useAppSelector } from '../../../../hooks/useRedux';

import SubjectsRegisterScreen from '../screens/SubjectsRegisterScreen';

const SubjectsRegisterRouter = () => {
  // const { userInfo } = useAppSelector((state) => state.auth);

  // para el correcto funcionamiento de este módulo es contar con:
  // - ID de la malla curricular
  // - ID del record estudiantil
  // En caso de no responder los datos del necesarios del estudiante, redireccionar al dashboard
  // if (!userInfo || !userInfo.studentInfo) {
  //   return <Redirect to="/dashboard" />;
  // }

  return (
    <Switch>
      <GuardRoute exact path="/registro-asignaturas" component={SubjectsRegisterScreen} />
      <GuardRoute
        exact
        path="/registro-asignaturas/:studentId/registro"
        component={SubjectsRegisterScreen}
      />
    </Switch>
  );
};

export default SubjectsRegisterRouter;
