import { Switch } from 'react-router-dom';

import DepositsTransfersScreen from '../screens/DepositsTransfersScreen';

import GuardRoute from '../../../../router/guards/GuardRoute';

import permissions from '../constants/permissions';

const PeriodsRouter = () => {
  // const { currentModule } = useAppSelector((state) => state.access);

  // const hasPermission = 'debt' in currentModule?.permissions!;

  // if (!hasPermission) return <Redirect to="/dashboard" />;

  return (
    <Switch>
      {/* Stages */}
      <GuardRoute
        exact
        path="/administrar-depositos-transferencias"
        component={DepositsTransfersScreen}
        permissionKey={permissions.cashCollection.groupKey}
        subPermissionKey={permissions.cashCollection.collectionPaymentMethod}
      />
    </Switch>
  );
};

export default PeriodsRouter;
