import { Grid, Typography } from '@material-ui/core';

import {
  useGetCollaboratorsByResearchProjectAvailablesQuery,
  useGetStudentsByResearchProjectAvailablesQuery,
} from '../../../slices/InvestigationLinesApiSlice';
import AssignPersonsDelete from './AssignPersonCard';

interface Props {
  projectId: string;
  type?: 'S' | 'T' | 'A';
}

const ListStudentsAssign = ({ projectId, type = 'A' }: Props) => {
  const { data: studentsAvailables, isLoading: isLoadingStudents } =
    useGetStudentsByResearchProjectAvailablesQuery({
      projectId,
    });

  const { data: collaboratorAvailables, isLoading: isLoadingCollaborator } =
    useGetCollaboratorsByResearchProjectAvailablesQuery({
      projectId,
    });

  return (
    <Grid container>
      {(type === 'S' || type === 'A') && (
        <Grid item xs={12} spacing={2}>
          <Typography variant="h4" color="inherit">
            Estudiantes
          </Typography>
          {!studentsAvailables?.length && !isLoadingStudents && (
            <Typography variant="subtitle1" color="initial">
              No hay estudiantes disponibles
            </Typography>
          )}

          {isLoadingStudents && (
            <Typography variant="subtitle1" color="initial">
              Cargando información
            </Typography>
          )}

          {studentsAvailables?.map((data) => (
            <AssignPersonsDelete
              name={data?.pers_full_name || 'N/A'}
              key={data.id}
              type={'student'}
              id={data.id}
              hours={data.number_hours}
            />
          ))}
        </Grid>
      )}
      {(type === 'T' || type === 'A') && (
        <Grid item xs={12} mt={2}>
          <Typography variant="h4" color="inherit">
            Docentes
          </Typography>
          {collaboratorAvailables?.map((data) => (
            <AssignPersonsDelete
              name={data?.pers_full_name || 'N/A'}
              key={data.id}
              type={'collaborator'}
              id={data.collaborator_project_research_id}
              collTyp={data.type_function}
              hours={data.number_hours}
            />
          ))}
          {!collaboratorAvailables?.length && !isLoadingCollaborator && (
            <Typography variant="subtitle1" color="initial">
              No hay docentes disponibles
            </Typography>
          )}

          {isLoadingCollaborator && (
            <Typography variant="subtitle1" color="initial">
              Cargando información docentes
            </Typography>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default ListStudentsAssign;
