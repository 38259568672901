import projectPermissions from '../../../shared/constants/projectPermissions';

const permissions = {
  catalog: {
    groupKey: projectPermissions.catalog.groupKey,

    getChildrenCatalogByAcro: projectPermissions.catalog.getChildrenCatalogByAcro,
    getpaymentArray: projectPermissions.catalog.getpaymentArray,
    getChildrenByAcronymOther: projectPermissions.catalog.getChildrenByAcronymOther,
  },

  cashPoint: {
    groupKey: projectPermissions.cashPoint.groupKey,

    getUsersCash: projectPermissions.cashPoint.getUsersCash,
  },

  financialInstitutions: {
    groupKey: projectPermissions.financialInstitutions.groupKey,

    read: projectPermissions.financialInstitutions.read,
    getFinancialInstituteChildren:
      projectPermissions.financialInstitutions.getFinancialInstituteChildren,
  },

  cashCollection: {
    groupKey: projectPermissions.cashCollection.groupKey,

    collectionPaymentMethod: projectPermissions.cashCollection.collectionPaymentMethod,
    collectionStatus: projectPermissions.cashCollection.collectionStatus,
    updateInfoTransferOrDeposit: projectPermissions.cashCollection.updateInfoTransferOrDeposit,
    methodPreviousMonths: projectPermissions.cashCollection.methodPreviousMonths,
  },
};

export default Object.freeze(permissions);

// export const subPermissionKeys = {
//   read: 'debts-listar-deudas',
//   create: 'debts-crear-deuda',
//   edit: 'debts-actualizar-deuda',
//   delete: 'debts-eliminar-deuda',
// };
// debt
