export const permissionKey = 'profiles';

export interface SubPermissionKeys {
  read: string;
  create: string;
  edit: string;
  delete: string;
}

export const subPermissionKeys: SubPermissionKeys = {
  read: 'profiles-listar-perfil',
  create: 'profiles-crear-perfil',
  edit: 'profiles-actualizar-perfil',
  delete: 'profiles-borrar-un-perfil',
};
