import { Divider, Grid, makeStyles, Stack, TextField, useTheme } from '@material-ui/core';
import { format, parseISO } from 'date-fns';
import { IconX } from '@tabler/icons';

import { formatDatesLangs } from '../../../../../translation/hooks/useGetLocaleDateLang';
import LocalizedStaticTimePicker from '../../../../shared/components/controlled/LocalizedStaticTimePicker';
import DeleteButton from '../../../../shared/components/tables/buttons/DeleteButton';

import { BusScheduleDetail, BusScheduleDetailNew } from '../../interfaces/buses.interfaces';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  timePicker: {
    '& .MuiInternalClockNumber-inner': { color: 'inherit' },
    '& .Mui-disabled': { color: theme.palette.error.main },
  },
}));

interface Props {
  id: number | string;
  idx: number;
  data: BusScheduleDetailNew | BusScheduleDetail;
  removeItemHandler: (idx: number) => void;
  changeHoursHandler: (index: number, newHours: BusScheduleDetailNew | BusScheduleDetail) => void;
  deleteHandler: (dataIds: { id: number; idx: number }) => void;
}

const ScheduleTimeComponent = ({
  idx,
  id,
  data,
  removeItemHandler,
  changeHoursHandler,
  deleteHandler,
}: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles();

  const changeHandler = async (key: string, value: Date | null) => {
    const newValue = value ? format(value, formatDatesLangs.normalizadDateTime) : null;

    return changeHoursHandler(idx, {
      ...data,
      [key as keyof typeof data]: newValue,
      ...(!!(data as BusScheduleDetail)?.id && { isEdited: true }),
    });
  };

  const removeHandler = () => {
    if (typeof id === 'number') return deleteHandler({ id, idx });

    return removeItemHandler(idx);
  };

  return (
    <Stack direction={'row'} key={id} mt={2} gap={1}>
      <Grid
        item
        xs={6}
        sx={{
          border: `1px solid ${theme.palette.primary.main}`,
          borderRadius: '10px',
          overflow: 'hidden',
          backgroundColor: theme.palette.primary.light,
        }}
      >
        <Grid container alignItems="flex-end" height="100%">
          <Grid item xs={12} py={1}>
            <Grid container alignItems={'center'}>
              {(!!idx || typeof id === 'number') && (
                <Grid item xs="auto">
                  <DeleteButton
                    size="small"
                    handleDelete={removeHandler}
                    {...(typeof id === 'string' && { CustomIcon: IconX })}
                  />
                </Grid>
              )}
              <Grid item xs>
                <Divider>{t('academic.buses.schedules.form.timer.go.label')}</Divider>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6}>
            <LocalizedStaticTimePicker
              ampm={false}
              className={classes.timePicker}
              label={t('academic.buses.schedules.form.timer.go_depature.label')}
              orientation="landscape"
              openTo="hours"
              value={data.hour_departure_go ? parseISO(data.hour_departure_go) : null}
              onChange={(newValue) => {
                changeHandler('hour_departure_go', newValue as Date | null);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            {/* <Grid item xs={12}>
          {errors[`hour${day.day}` as keyof typeof errors] &&
            touched[`hour${day.day}` as keyof typeof touched] && (
              <FormHelperText error>
                {errors[`hour${day.day}` as keyof typeof errors]}
              </FormHelperText>
            )}
        </Grid> */}
            <LocalizedStaticTimePicker
              ampm={false}
              className={classes.timePicker}
              label={t('academic.buses.schedules.form.timer.go_arrival.label')}
              orientation="landscape"
              openTo="hours"
              value={data.hour_arrival_go ? parseISO(data.hour_arrival_go) : null}
              onChange={(newValue) => {
                changeHandler('hour_arrival_go', newValue as Date | null);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </Grid>
        </Grid>
      </Grid>

      {/* // */}
      <Grid
        item
        xs={6}
        sx={{
          border: `1px solid ${theme.palette.primary.main}`,
          borderRadius: '10px',
          overflow: 'hidden',
          backgroundColor: theme.palette.primary.light,
        }}
      >
        <Grid container alignItems="flex-end" height="100%">
          <Grid item xs={12} py={1}>
            <Divider>{t('academic.buses.schedules.form.timer.return.label')}</Divider>
          </Grid>
          <Grid item xs={12} lg={6}>
            <LocalizedStaticTimePicker
              ampm={false}
              className={classes.timePicker}
              label={t('academic.buses.schedules.form.timer.return_depature.label')}
              orientation="landscape"
              openTo="hours"
              value={data.hour_departure_return ? parseISO(data.hour_departure_return) : null}
              onChange={(newValue) => {
                changeHandler('hour_departure_return', newValue as Date | null);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            {/* <Grid item xs={12}>
          {errors[`hour${day.day}` as keyof typeof errors] &&
            touched[`hour${day.day}` as keyof typeof touched] && (
              <FormHelperText error>
                {errors[`hour${day.day}` as keyof typeof errors]}
              </FormHelperText>
            )}
        </Grid> */}
            <LocalizedStaticTimePicker
              ampm={false}
              className={classes.timePicker}
              label={t('academic.buses.schedules.form.timer.return_arrival.label')}
              orientation="landscape"
              openTo="hours"
              value={data.hour_arrival_return ? parseISO(data.hour_arrival_return) : null}
              onChange={(newValue) => {
                changeHandler('hour_arrival_return', newValue as Date | null);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </Grid>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default ScheduleTimeComponent;
