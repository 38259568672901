import * as Yup from 'yup';

export const ParallelsInfoSchema = Yup.object().shape({
  name: Yup.string().required('academic.parallels.add.name.required'),
  description: Yup.string(),
  acronym: Yup.string()
    .required('academic.parallels.add.acronym.required')
    .min(2, 'academic.parallels.add.acronym.min')
    .max(3, 'academic.parallels.add.acronym.max'),
  maxMatter: Yup.number()
    .integer('Solo se permiten números enteros.')
    .min(Yup.ref('minMatter'), 'Max. Materias debe ser mayor o igual al Min. Materias.'),
  statusId: Yup.number().required('academic.parallels.add.status.required'),
});
