import { Grid, IconButton, Typography } from '@material-ui/core'
import { IconArrowLeft } from '@tabler/icons';
import { useHistory } from 'react-router-dom';
import MainCard from '../../../shared/components/cards/MainCard'
import EditRequestStatus from '../components/EditRequestStatus';

const RequestsStatusEditScreen = () => {
  const navigate = useHistory();
  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid container sx={{ mb: 3 }} alignItems='center' spacing={1}>
        <Grid item>
          <IconButton onClick={() => navigate.goBack()}>
            <IconArrowLeft />
          </IconButton>
        </Grid>
        <Grid item>
          <Typography variant='h2'>Editar Estado</Typography>
        </Grid>
      </Grid>
      <EditRequestStatus />
    </Grid>
  )
}

export default RequestsStatusEditScreen;