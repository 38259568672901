export const permissionKey = 'companies';

export interface SubPermissionKeys {
  read: string;
  create: string;
  edit: string;
  delete: string;
}

export const subPermissionKeys: SubPermissionKeys = {
  read: 'companies-listar-companias',
  create: 'companies-crear-compania',
  edit: 'companies-actualizar-compania',
  delete: 'companies-borrar-compania',
};
