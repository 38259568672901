import { LanguagesOptions } from '../../../../translation/interfaces';

interface OpenStates {
  id?: number;
  value: '1' | '0';
  label: LanguagesOptions<string>;
}

export const openStates: OpenStates[] = [
  {
    id: 1,
    value: '1',
    label: {
      en: 'Opened',
      es: 'Abiertas',
    },
  },
  {
    id: 2,
    value: '0',
    label: {
      en: 'Closed',
      es: 'Cerradas',
    },
  },
];
