import menu from './menu.json';
import dashboard from './dashboard.json';
import courses from './courses.json';
import schedules from './schedules.json';
import virtualClassroom from './virtualClassroom';
import academicCounseling from './academicCounseling.json';
import birthdays from './birthdays.json';
import assignGrades from './assignGrades.json';

const teachersTraanslations = {
  ...menu,
  ...dashboard,
  ...courses,
  ...schedules,
  ...virtualClassroom,
  ...academicCounseling,
  ...birthdays,
  ...assignGrades,
};

export default teachersTraanslations;
