import { Avatar, Card, Grid, IconButton, Typography, useTheme } from '@material-ui/core';
// import { MailOutlineOutlined } from "@material-ui/icons";
import { IconArrowNarrowLeft } from '@tabler/icons';
import { /*Redirect,*/ useHistory, useParams } from 'react-router-dom';
import { useAppSelector } from '../../../../../hooks/useRedux';
import { useStyles } from '../../helpers/styles';
import { obtainDomainStorageImage } from '../../../../shared/helpers';
import { useGetCourseStudentByIdQuery } from '../../../subjects-register/slices/subjectsRegisterApiSlice';
import { useEffect, useState } from 'react';
import { SubjectsByPeriod } from '../../interfaces/subjectsByPeriods';

const MatterDetailHeader = function () {
  const { courseId } = useParams<{ courseId: string }>();
  const theme = useTheme();
  const classes = useStyles();

  const history = useHistory();

  const [currentMatter, setCurrentMatter] = useState<SubjectsByPeriod | null>(null);

  const { currentStudent } = useAppSelector((state) => state.access);

  const { userInfo } = useAppSelector((state) => state.auth);

  const { portfolioMatters } = useAppSelector((state) => state.academicPortfolio);

  const currentCourseStudent = portfolioMatters.find((value) => value.id === Number(courseId));

  const { data: courseStudent = null, isLoading: isLoadingCourseStudent } =
    useGetCourseStudentByIdQuery(
      {
        courseStudentId: Number(courseId),
      },
      { skip: currentCourseStudent !== undefined }
    );

  useEffect(() => {
    if (!currentCourseStudent) {
      if (!isLoadingCourseStudent && courseStudent) {
        setCurrentMatter(courseStudent);
        console.log(courseStudent);
      } else {
        console.log(courseStudent);
      }
    } else {
      setCurrentMatter(currentCourseStudent);
    }
  }, [currentCourseStudent, courseStudent, isLoadingCourseStudent]);

  return (
    <Grid container justifyContent="space-between" flexDirection="row">
      <Grid item xs={4}>
        <Grid item container gap={1} sx={{ cursor: 'pointer' }} alignItems="center">
          <IconButton onClick={() => history.push('/portafolio-academico')}>
            <IconArrowNarrowLeft size={30} color={theme.palette.text.dark} />
          </IconButton>
          <Typography variant="h2">Atrás</Typography>
        </Grid>
      </Grid>

      <Grid item xs={4}>
        <Grid gridRow={2} gap={1} spacing={2}>
          <Typography variant="h3">
            {currentMatter && currentMatter.subject_curriculum.matter_rename}
          </Typography>
          <Typography variant="body2">
            Paralelo {currentMatter && currentMatter.course.parallel.par_name} | Aula{' '}
            {currentMatter && currentMatter.course.classroom.cl_name}
          </Typography>
          {/* <Typography variant="body2">Horario Lun, Mar | 8h35 9h35</Typography> */}
        </Grid>
      </Grid>

      <Grid container item xs={4} justifyContent="end">
        <Card>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container>
                <Grid item>
                  <Avatar
                    className={classes.avatar}
                    alt="User 1"
                    src={obtainDomainStorageImage(currentStudent?.stud_photo_path!)}
                  />
                </Grid>
                <Grid item xs style={{ marginLeft: 7 }} zeroMinWidth>
                  <Typography
                    variant="h5"
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      display: 'block',
                    }}
                  >
                    {userInfo?.name}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      mt: 0.5,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      display: 'block',
                    }}
                  >
                    Estudiante
                  </Typography>
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item>
                      {/* <Typography
                                            sx={{
                                                width: '20px',
                                                height: '20px',
                                                color: theme.palette.secondary.main,
                                            }}
                                        >
                                            <MailOutlineOutlined />
                                        </Typography> */}
                      <Typography
                        variant="h5"
                        sx={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          display: 'block',
                        }}
                      >
                        {userInfo?.email}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

export default MatterDetailHeader;
