import { Grid, IconButton, Paper, Tooltip, Typography } from '@material-ui/core';

import { IconBook, IconCalendarEvent, IconListCheck, IconTrash } from '@tabler/icons';
import moment from 'moment';

import { useAppSelector } from '../../../../../hooks/useRedux';
import { getTime } from 'date-fns';
import Chip from '../../../../shared/components/extended/Chip';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const CardTask: React.FC<{
  nombre: string;
  startDate?: Date;
  endDate: Date;
  isLoading: boolean;
  handleSubmit: any;
  handleUpdate: any;
  id: any;
}> = ({ nombre, startDate, endDate, handleSubmit, isLoading, handleUpdate, id }) => {
  console.log(endDate);

  const History = useHistory();

  const dateCompare = getTime(new Date(endDate!));

  const { t } = useTranslation();

  const nowDate = Date.now() > dateCompare;

  console.log(Date.now(), dateCompare);
  const {
    course: { courseId },
  } = useAppSelector((state) => state.virtualClassroomTeacherShared);

  return (
    <Grid sm={6} md={5} lg={3} xs={12} item>
      <Grid spacing={3}>
        <Paper style={{ borderRadius: '10px' }} elevation={5}>
          <Grid container style={{ padding: '18px 8px ' }}>
            <Grid item xs={3} alignItems="flex-end">
              <IconButton style={{ backgroundColor: 'lightgray' }}>
                <IconBook />
              </IconButton>
            </Grid>
            <Grid container xs={9}>
              <Grid mt={'4px'} item xs={12}>
                <Typography color={'gray'} textAlign={'justify'} fontWeight="bold" variant="body2">
                  {t('teacherVirtualClassroom.tasks.title')}
                </Typography>
              </Grid>
              <Grid mt={'4px'} item>
                <Typography variant="h5" fontWeight="bold" textAlign={'justify'} color="#34414E">
                  {nombre}
                </Typography>
              </Grid>

              <Grid mt={'4px'} spacing={1} item container>
                <Grid item textAlign={'justify'}>
                  <Typography variant="body1" fontSize={'small'} fontWeight={'bold'}>
                    {t('teacherVirtualClassroom.tasks.fecha.inicio')}
                  </Typography>
                  <Typography variant="body2">{moment(startDate).format('LLL')}</Typography>
                </Grid>
                <Grid item>
                  <Typography fontSize={'small'} variant="body1" fontWeight={'bold'}>
                    {t('teacherVirtualClassroom.tasks.fecha.fin')}
                  </Typography>
                  <Typography variant="body2">{moment(endDate).format('LLL')}</Typography>
                </Grid>
              </Grid>

              <Grid
                item
                container
                display="flex"
                direction="row"
                alignItems={'center'}
                gap={1}
                marginTop="15px"
                justifyContent={'space-between'}
              >
                <Grid item>
                  {nowDate ? (
                    <Chip
                      chipcolor="error"
                      label={t('teacherVirtualClassroom.tasks.state.finish')}
                    />
                  ) : (
                    <Chip
                      chipcolor="success"
                      label={t('teacherVirtualClassroom.tasks.state.inProcess')}
                    />
                  )}
                </Grid>
                <Grid display="flex" alignItems="center" item>
                  <Tooltip title={t('teacherVirtualClassroom.tasks.tooltip.edit')}>
                    <IconButton
                      onClick={() =>
                        History.push(
                          `/administrar-aula-virtual/actividades/course/${courseId}/task/${id}`
                        )
                      }
                    >
                      <IconListCheck color="gray" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t('teacherVirtualClassroom.tasks.tooltip.delete')}>
                    <IconButton onClick={handleSubmit} color="inherit">
                      <IconTrash color="red" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t('teacherVirtualClassroom.tasks.tooltip.update')}>
                    <IconButton onClick={handleUpdate}>
                      <IconCalendarEvent color="gray" />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};
export default CardTask;
