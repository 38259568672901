export const permissionKey = 'roles';

export interface SubPermissionKeys {
  read: string;
  create: string;
  edit: string;
  delete: string;
  getOne: string;
}

export const subPermissionKeys: SubPermissionKeys = {
  read: 'roles-listar-roles',
  create: 'roles-crear-rol',
  edit: 'roles-actualizar-rol',
  delete: 'roles-borrar-rol',
  getOne: 'roles-obtener-rol',
};
