import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  Autocomplete,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  TextField,
} from '@material-ui/core';

import { useFormik } from 'formik';
import DateRangePicker, { DateRange } from '@material-ui/lab/DateRangePicker';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import { format } from 'date-fns';

import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import InputLabel from '../../../shared/components/forms/InputLabel';

import { useAppSelector } from '../../../../hooks/useRedux';

import { ValidationSchema } from '../constants/validationSchemas';
// import { statusAvailables } from '../../../shared/constants/resourceStatus';
import { financialPeriodsTypes } from '../constants/shared';
import {
  useAddFinancialPeriodLevelMutation,
  useGetAvailablesFinancialPeriodsQuery,
} from '../slices/financialPeriodsApiSlice';
import TextFieldFetching from '../../../shared/components/TextFieldFetching';
import { useGetLocaleDateLang } from '../../../../translation/hooks/useGetLocaleDateLang';

const AddFinancialPeriodForm = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const { lang } = useGetLocaleDateLang();

  const { currentProfile } = useAppSelector((state) => state.access);

  const [showAlerts, setShowAlerts] = useState(true);

  const [addFinancialPeriod, { isLoading, isError, error, isSuccess }] =
    useAddFinancialPeriodLevelMutation();

  const { getFieldProps, errors, touched, handleSubmit, setFieldValue, values } = useFormik({
    initialValues: {
      fperiod_name: '',
      fperiod_parent: null,
      fperiod_type: 'A',
      fp_startDate: new Date(),
      fp_endDate: null,
      status_id: 1,
    },
    validationSchema: ValidationSchema,
    onSubmit: async (values) => {
      await addFinancialPeriod({
        profileId: currentProfile?.profileId!,
        financialPeriodPayload: {
          fp_description: values.fperiod_name,
          fp_parent: values.fperiod_parent,
          fp_type: values.fperiod_type,
          fp_start_date: format(new Date(values.fp_startDate!), 'yyyy-MM-dd'),
          fp_end_date: format(new Date(values.fp_endDate!), 'yyyy-MM-dd'),
          status_id: values.status_id.toString(),
        },
      });

      setShowAlerts(true);
    },
  });

  //date range
  const [value, setValue] = useState<DateRange<Date>>([new Date(), null]);

  const onChangeDates = (newDatesValues: DateRange<Date>) => {
    const [start, end] = newDatesValues;
    // update formik values
    setFieldValue('fp_startDate', start);
    setFieldValue('fp_endDate', end);

    setValue(newDatesValues);
  };

  const {
    data: financialPeriods,
    isLoading: isLoadingFP,
    isFetching: isFetchingFP,
  } = useGetAvailablesFinancialPeriodsQuery({
    profileId: currentProfile?.profileId!,
  });

  return (
    <form onSubmit={handleSubmit}>
      <Grid container gap={2}>
        <Grid item xs={12}>
          {isError && showAlerts && (
            <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
          )}

          {isSuccess && showAlerts && (
            <SuccessAlert
              message={t('financial.periods.add.success_alert')}
              handleDismiss={() => setShowAlerts(false)}
            />
          )}
        </Grid>

        <Grid item container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <InputLabel>{t('financial.periods.add.name_label')}</InputLabel>
            <TextField
              fullWidth
              {...getFieldProps('fperiod_name')}
              error={Boolean(errors.fperiod_name && touched.fperiod_name)}
              placeholder={t('financial.periods.add.name_placeholder')}
            />
            {errors.fperiod_name && touched.fperiod_name && (
              <FormHelperText error>{t(errors.fperiod_name)}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={12}>
            <InputLabel>{t('financial.periods.add.type_label')}</InputLabel>
            <Autocomplete
              options={financialPeriodsTypes}
              defaultValue={financialPeriodsTypes[0]}
              getOptionSelected={(option, value) => option.keyword === value.keyword}
              getOptionLabel={(option) => t(option.label)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(errors.fperiod_type && touched.fperiod_type)}
                />
              )}
              onChange={(e, value) => setFieldValue('fperiod_type', value?.keyword || null)}
              fullWidth
              clearIcon={null}
            />
          </Grid>
          {values.fperiod_type !== 'A' && (
            <Grid item xs={12}>
              <InputLabel>{t('financial.periods.add.principal_period_label')}</InputLabel>
              {isFetchingFP ? (
                <TextFieldFetching />
              ) : (
                <Autocomplete
                  options={financialPeriods || []}
                  loading={isLoadingFP}
                  loadingText={t('financial.periods.add.principal_period_loading')}
                  getOptionLabel={(option) => option.fp_description}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(errors.fperiod_parent && touched.fperiod_parent)}
                      placeholder={t('financial.periods.add.principal_period_placeholder')}
                    />
                  )}
                  onChange={(e, value) => setFieldValue('fperiod_parent', value?.id || null)}
                  fullWidth
                  clearIcon={null}
                />
              )}
              {errors.fperiod_parent && touched.fperiod_parent && (
                <FormHelperText error>{t(errors.fperiod_parent)}</FormHelperText>
              )}
            </Grid>
          )}

          <Grid item xs={12}>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={lang}>
              <DateRangePicker
                startText={null}
                endText={null}
                value={value}
                onChange={onChangeDates}
                renderInput={(startProps, endProps) => (
                  <Grid container spacing={1} alignItems="center">
                    <Grid item xs={12}>
                      <InputLabel>{t('financial.periods.add.start_period_label')}</InputLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        {...startProps}
                        helperText=""
                        error={Boolean(errors.fp_startDate && touched.fp_startDate)}
                        {...getFieldProps('fp_startDate')}
                      />

                      {errors.fp_startDate && touched.fp_startDate && (
                        <FormHelperText error>{t(errors.fp_startDate as string)}</FormHelperText>
                      )}
                    </Grid>

                    <Grid item xs={12}>
                      <InputLabel>{t('financial.periods.add.end_period_label')}</InputLabel>
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        {...endProps}
                        helperText=""
                        error={Boolean(errors.fp_endDate && touched.fp_endDate)}
                        {...getFieldProps('fp_endDate')}
                      />
                      {/* <FormHelperText>En esta fecha el periodo finalizará</FormHelperText> */}
                      {errors.fp_endDate && touched.fp_endDate && (
                        <FormHelperText error>{t(errors.fp_endDate as string)}</FormHelperText>
                      )}
                    </Grid>
                  </Grid>
                )}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
        <Grid
          item
          container
          alignItems="center"
          justifyContent="flex-end"
          spacing={2}
          sx={{ mt: 1 }}
        >
          <Grid item>
            <Button
              variant="outlined"
              size="large"
              color="primary"
              onClick={() => history.goBack()}
            >
              {t('shared.go_back_button.label')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              disabled={isLoading}
              endIcon={isLoading && <CircularProgress size={20} />}
            >
              {t('shared.add_button.label')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default AddFinancialPeriodForm;
