import { Switch, Redirect } from 'react-router-dom';
import { useAppSelector } from '../../../../hooks/useRedux';
import GuardRoute from '../../../../router/guards/GuardRoute';
import { subPermissionKeys } from '../constants/permissions';
import MainScreen from '../screens/MainScreen';
import ChooseInitCareerScreen from '../screens/ChooseInitCareerScreen';
import TemplateScreen from '../screens/TemplateScreen';
import CareerPlanningScreen from '../screens/CareerPlanningScreen';

const AcPlanPostgraduateRouter = () => {
  const { currentModule } = useAppSelector((state) => state.access);

  const hasPermission = subPermissionKeys.groupKey in currentModule!.permissions;

  if (!hasPermission) return <Redirect to="/dashboard" />;

  return (
    <Switch>
      <GuardRoute
        exact
        path="/planeacion-academica-posgrado"
        component={MainScreen}
        subPermissionKey={subPermissionKeys.readCalendar}
        permissionKey={subPermissionKeys.groupKey}
      />
      <GuardRoute
        exact
        path="/planeacion-academica-posgrado/fechas-carrera"
        component={ChooseInitCareerScreen}
        subPermissionKey={subPermissionKeys.readCalendar}
        permissionKey={subPermissionKeys.groupKey}
      />
      <GuardRoute
        exact
        path="/planeacion-academica-posgrado/fechas-carrera/plantilla"
        component={TemplateScreen}
        subPermissionKey={subPermissionKeys.readCalendar}
        permissionKey={subPermissionKeys.groupKey}
      />
      <GuardRoute
        exact
        path="/planeacion-academica-posgrado/fechas-carrera/:careerId/planeacion-carrera"
        component={CareerPlanningScreen}
        subPermissionKey={subPermissionKeys.getPlaningPostgraduate}
        permissionKey={subPermissionKeys.groupKey}
      />
    </Switch>
  );
};

export default AcPlanPostgraduateRouter;
