import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../../../../hooks/useRedux';
import { showWithDecimals } from '../../../../../shared/helpers';
import { renderErrorOrEmptyRow, renderSkeletonRows } from '../../../../../shared/helpers/render';
import { AmountCollectionPayload } from '../../../interfaces/payloads.interface';

const PaymentsTableReview = () => {
  const { t } = useTranslation();

  const defaultConfiguration = useAppSelector((state) => state.access.defaultConfiguration);

  const { payCollection } = useAppSelector((state) => state.debtsPayment);

  const renderRow = (paids: AmountCollectionPayload, i: number) => (
    <TableRow
      hover
      className="table-row"
      key={`${paids.payment_amount.toString()}-${i}`}
      //   sx={{ cursor: 'pointer', backgroundColor: selected === paids.id ? '#eee' : '' }}
      onClick={() => {
        // setSelected(paids.id);
      }}
    >
      <TableCell>{paids.payment_way_name}</TableCell>
      <TableCell>
        {defaultConfiguration?.currency_symbol}
        {showWithDecimals(paids.payment_amount)}
      </TableCell>
    </TableRow>
  );

  const renderRows = () => {
    return <>{payCollection?.map(renderRow)}</>;
  };

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                {t('financial.debts_payment.wizard.pay_review.payments_table_review.pay_method')}
              </TableCell>
              <TableCell>
                {t('financial.debts_payment.wizard.pay_review.payments_table_review.amount')}
              </TableCell>
              {/* <TableCell>Opciones</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {!payCollection ? renderSkeletonRows(5, 2) : renderRows()}

            {payCollection?.length === 0 &&
              renderErrorOrEmptyRow(
                2,
                t('financial.debts_payment.wizard.pay_review.payments_table_review.no_data')
              )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default PaymentsTableReview;
