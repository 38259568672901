import { Grid, Typography } from '@material-ui/core';
import { useAppSelector } from '../../../../hooks/useRedux';
import MainCard from '../../../shared/components/cards/MainCard';

import DeletePeriodDialog from '../components/DeletePositionDialog';
import PeriodsTable from '../components/PositionsTable';

const PositionsScreen = () => {
  const { positionIdToDelete } = useAppSelector((state) => state.positions);

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">Listado de Cargos y Roles</Typography>
      </Grid>

      <PeriodsTable />

      {positionIdToDelete && <DeletePeriodDialog />}
    </Grid>
  );
};

export default PositionsScreen;
