import { Grid, Typography } from '@material-ui/core';
import { useAppSelector } from '../../../../hooks/useRedux';
import MainCard from '../../../shared/components/cards/MainCard';
import ShelfsTable from '../components/ShelfsTable';
import DeleteShelftDialog from '../components/DeleteShelftDialog';
import { useTranslation } from 'react-i18next';

const ShelftsScreens = () => {
  const { t } = useTranslation();
  const { shelftToDelete } = useAppSelector((state) => state.shelfts);
  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">{t('shelfs.title')}</Typography>
      </Grid>

      <ShelfsTable />

      {shelftToDelete && <DeleteShelftDialog />}
    </Grid>
  );
};

export default ShelftsScreens;
