import {
  Table,
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
  TableBody,
  Grid,
  InputAdornment,
  TextField,
  IconButton,
} from '@material-ui/core';
import { useState } from 'react';
import { DebounceInput } from 'react-debounce-input';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import StatusChip from '../../../shared/components/badges/StatusChip';
import DeleteButton from '../../../shared/components/tables/buttons/DeleteButton';
import EditButton from '../../../shared/components/tables/buttons/EditButton';
import { Pensum } from '../interfaces/pensums.interface';
import { useGetPensumsQuery } from '../slices/pensumsApiSlice';
import { openDeleteModalWith } from '../slices/pensumsSlice';
import SearchIcon from '@material-ui/icons/Search';
import AddButton from '../../../shared/components/tables/buttons/AddButton';
import { renderErrorOrEmptyRow, renderSkeletonRows } from '../../../shared/helpers/render';
import { IconX } from '@tabler/icons';
import CustomPaginationAndItems from '../../../shared/components/tables/CustomPaginationAndItems';

const PensumTable = () => {
  const { currentProfile } = useAppSelector((state) => state.access);

  const dispatch = useAppDispatch();

  const { path } = useRouteMatch();
  const history = useHistory();

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const [search, setSearch] = useState('');

  const { isLoading, error, data, isFetching } = useGetPensumsQuery(
    {
      profileId: currentProfile?.profileId!,
      page,
      perPage,
      search,
    },
    { refetchOnMountOrArgChange: 30 }
  );

  const handleDelete = async (pensumId: number) => {
    dispatch(openDeleteModalWith(pensumId));
  };

  const renderRow = (p: Pensum) => (
    <TableRow className="table-row" key={p.id}>
      <TableCell align="center" width="10%">
        {p.id}
      </TableCell>
      <TableCell width="20%">{p.pen_name}</TableCell>
      <TableCell width="25%">{p.pen_description}</TableCell>
      <TableCell align="center" width="10%">
        {p.pen_acronym}
      </TableCell>
      <TableCell align="center" width="10%">
        {p.anio}
      </TableCell>
      <TableCell width="10%" align="center">
        <StatusChip statusId={Number(p.status_id)} />
      </TableCell>
      <TableCell width="15%" align="center">
        <Link to={`${path}/${p.id}/editar`}>
          <EditButton />
        </Link>
        <DeleteButton handleDelete={() => handleDelete(p.id)} />
      </TableCell>
    </TableRow>
  );

  const renderRows = () => {
    return <>{data?.data.map(renderRow)}</>;
  };

  const goToAdd = () => {
    history.push('/administrar-pensums/agregar');
  };

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
        <Grid item xs={12} sm={6}>
          <DebounceInput
            autoFocus={Boolean(search)}
            minLength={2}
            debounceTimeout={300}
            onChange={({ target }) => setSearch(target.value)}
            value={search}
            element={(props) => (
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon fontSize="small" />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        size="small"
                        onClick={() => setSearch('')}
                        {...(!search && { sx: { cursor: 'initial', opacity: 0 } })}
                      >
                        <IconX size={20} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                placeholder="Buscar pensum"
                variant="outlined"
                size="small"
                {...props}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} sx={{ textAlign: 'right' }}>
          <AddButton size="large" onClick={goToAdd} />
        </Grid>
      </Grid>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">Código</TableCell>
              <TableCell>Nombre</TableCell>
              <TableCell>Descripción</TableCell>
              <TableCell align="center">Acrónimo</TableCell>
              <TableCell align="center">Año</TableCell>
              <TableCell align="center">Estado</TableCell>
              <TableCell align="center">Opciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isFetching ? renderSkeletonRows(perPage, 7) : renderRows()}

            {error && renderErrorOrEmptyRow(7, error as string)}
            {data?.data.length === 0 && renderErrorOrEmptyRow(7)}
          </TableBody>
        </Table>
      </TableContainer>

      <CustomPaginationAndItems
        error={error}
        isLoading={isLoading}
        total={data?.total || 0}
        perPage={perPage}
        page={page}
        setPage={setPage}
        setPerPage={setPerPage}
        perPageBox
        paginationBox
      />
    </>
  );
};

export default PensumTable;
