import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISearchTable } from '../../../shared/interfaces/paginatedTable.interface';
import { FinancialPeriod } from '../interfaces/financialPeriods.interfaces';

interface financialPeriodsState {
  financialPeriodList: FinancialPeriod[];
  deleteModalOpen: boolean;
  financialPeriodIdToDelete?: number;
  currentFinancialPeriodId?: number;

  fpTableCurrentPage: number;

  financialPeriodsTable: ISearchTable;
}

const initialState: financialPeriodsState = {
  financialPeriodList: [],
  deleteModalOpen: false,

  fpTableCurrentPage: 1,
  financialPeriodsTable: {
    page: 1,
    perPage: 5,
    search: '',
  },
};

const financialPeriodsSlice = createSlice({
  name: 'financialPeriods',
  initialState,
  reducers: {
    setFinancialPeriodList: (state, action: PayloadAction<FinancialPeriod[]>) => {
      state.financialPeriodList = action.payload;
    },
    setcurrentFinancialPeriodId: (state, action: PayloadAction<number>) => {
      state.currentFinancialPeriodId = action.payload;
    },

    setCurrentFinancialPeriodPage: (state, action: PayloadAction<number>) => {
      state.fpTableCurrentPage = action.payload;
    },

    setDeleteModalOpen: (state, action: PayloadAction<boolean>) => {
      state.deleteModalOpen = action.payload;
    },

    openDeleteModalWith: (state, action: PayloadAction<number>) => {
      state.financialPeriodIdToDelete = action.payload;
      state.deleteModalOpen = true;
    },
    resetFinancialPeriodIdToDelete: (state) => {
      state.financialPeriodIdToDelete = undefined;
    },
    setFinancialPeriodsTable: (state, action: PayloadAction<ISearchTable>) => {
      state.financialPeriodsTable = action.payload;
    },
  },
});

export const {
  setFinancialPeriodList,
  setDeleteModalOpen,
  openDeleteModalWith,
  setCurrentFinancialPeriodPage,
  setcurrentFinancialPeriodId,
  resetFinancialPeriodIdToDelete,
  setFinancialPeriodsTable,
} = financialPeriodsSlice.actions;

export default financialPeriodsSlice.reducer;
