import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISearchTable } from '../../../../../shared/interfaces/paginatedTable.interface';
import { TemplateEvaData, TemplateState } from '../interfaces/TemplateEva.interface';

const initialState: TemplateState = {
  templateList: [],
  templateTable: {
    page: 1,
    perPage: 5,
    search: '',
  },
  start_date: new Date().toISOString(),
  end_date: new Date().toISOString(),
  questionsSelected: [],
  isAllSelectedQuestions: false,

  evaTypeIdSelected: null,
  statusIdSelected: null,
};

export const templateSlice = createSlice({
  name: 'templateEva',
  initialState,
  reducers: {
    setTemplatesList: (state, action: PayloadAction<TemplateEvaData[]>) => {
      state.templateList = action.payload;
    },
    setTemplateTable: (state, action: PayloadAction<ISearchTable>) => {
      state.templateTable = {
        ...state.templateTable,
        ...action.payload,
      };
    },
    setStart_date: (state, action: PayloadAction<string>) => {
      state.start_date = action.payload;
    },
    setEnd_date: (state, action: PayloadAction<string>) => {
      state.end_date = action.payload;
    },
    setQuestionsSelected: (state, action: PayloadAction<any[]>) => {
      state.questionsSelected = action.payload;
    },
    setIsAllSelectedQuestions(state, action: PayloadAction<boolean>) {
      state.isAllSelectedQuestions = action.payload;
    },
    setEvaTypeIdSelected(state, action: PayloadAction<string | null>) {
      state.evaTypeIdSelected = action.payload;
    },
    setStatusIdSelected(state, action: PayloadAction<number | null>) {
      state.statusIdSelected = action.payload;
    },
  },
});

export const {
  setTemplatesList,
  setTemplateTable,
  setStart_date,
  setEnd_date,
  setQuestionsSelected,
  setIsAllSelectedQuestions,
  setEvaTypeIdSelected,
  setStatusIdSelected,
} = templateSlice.actions;
