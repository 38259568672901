import Chip from '../../../shared/components/extended/Chip';

export interface Status {
  id: number;
  name: string;
  color?: string;
}

interface Props {
  id: number;
  statusArray: Status[];
  disabled?: boolean;
  variant?: number;
}

const OmniChip = ({ id, statusArray, disabled, variant = 1 }: Props) => {
  const status = statusArray.find((status) => status.id === id);
  return (
    <Chip
      label={status?.name}
      size="small"
      disabled={disabled || false}
      chipcolor={status?.color}
      variant={variant === 0 ? 'filled' : 'outlined'}
    />
  );
};

export default OmniChip;
