import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StudentRecords } from '../../../maintenance/students/interfaces/studentRecords.interface';
import { SubjectCurriculumStatus } from '../../../students/subjects-register/interfaces/subjects-approveds';

interface StudentAsignedState {
  studentRecord?: StudentRecords;
  showAssignGradeDialog: boolean;
  subjectAssignGrade?: SubjectCurriculumStatus;
}

const initialState: StudentAsignedState = {
  showAssignGradeDialog: false,
};

const assignGradeSlice = createSlice({
  name: 'assignGrade',
  initialState,
  reducers: {
    resetAssignGradeState: () => initialState,
    setStudentRecordAssignGrade: (state, action: PayloadAction<StudentRecords | undefined>) => {
      state.studentRecord = action.payload;
    },
    fireDialogAssignGrade: (state, action: PayloadAction<boolean>) => {
      state.showAssignGradeDialog = action.payload;
    },
    setSubjectAssignGrade: (state, action: PayloadAction<SubjectCurriculumStatus | undefined>) => {
      state.subjectAssignGrade = action.payload;
    },
  },
});

export const {
  resetAssignGradeState,
  setStudentRecordAssignGrade,
  fireDialogAssignGrade,
  setSubjectAssignGrade,
} = assignGradeSlice.actions;

export default assignGradeSlice.reducer;
