import {
  Table,
  Autocomplete,
  Grid,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  CircularProgress,
  Button,
  FormHelperText,
  TableContainer,
  // Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../../hooks/useRedux';
import ErrorAlert from '../../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../../shared/components/alerts/SuccessAlert';
import SubCard from '../../../../shared/components/cards/SubCard';
import InputLabel from '../../../../shared/components/forms/InputLabel';
import { AssignDiscountSchema } from '../../constants/validationSchema';
import {
  DiscountGroupConfigurations,
  GroupConfigurationProduct,
} from '../../interfaces/groupConfigurations';
import {
  useAssignDiscountMutation,
  useGetDiscountGroupConfigurationsQuery,
} from '../../slices/clientApiSlice';
import { StudentRecord } from '../../interfaces/clientsFilter.interface';

export const useStyles = makeStyles((theme) => ({
  boxContainer: {
    width: '100%',
    height: 105,
    borderRadius: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0px 0px 12px 10px rgba(235,232,235,0.5)',
  },
}));

interface InitialValues {
  group_configuration: DiscountGroupConfigurations | null;
  student_record: StudentRecord | null;
}

const AssignDiscount = () => {
  const { currentProfile } = useAppSelector((state) => state.access);
  const { clientList } = useAppSelector((state) => state.client);
  const [groupConfiguration, setGroupConfiguration] = useState<GroupConfigurationProduct | null>();
  const { idPerson } = useParams<{ idPerson: string }>();
  const { t } = useTranslation();

  const student = clientList.find((c) => c.id === Number(idPerson));

  const { data: discountConfiguration = [], isLoading: isLoadingDC } =
    useGetDiscountGroupConfigurationsQuery(
      {
        user_profile_id: currentProfile?.profileId,
        data: 'all',
        type_discount_keyword: 'TIPDES_G',
      },
      { refetchOnMountOrArgChange: 30 }
    );

  const [showAlerts, setShowAlerts] = useState(true);

  const [assignDiscount, { isLoading: isLoadingAD, error, isError, isSuccess }] =
    useAssignDiscountMutation();

  const { handleSubmit, values, setFieldValue, touched, errors } = useFormik<InitialValues>({
    initialValues: {
      student_record: null,
      group_configuration: null,
    },
    validationSchema: AssignDiscountSchema,
    onSubmit: async (values) => {
      await assignDiscount({
        user_profile_id: currentProfile?.profileId!,
        group_configuration_id: values.group_configuration?.id!,
        student_record_id: values.student_record?.id!,
        student_id: Number(student?.user?.student?.id!),
        is_apply: true,
      });
      setShowAlerts(true);
    },
  });

  const defaultConfiguration = useAppSelector((state) => state.access.defaultConfiguration);

  const getAmount = (gc: GroupConfigurationProduct) => {
    if (Number(gc.gcp_amount) > 0) {
      return `${defaultConfiguration?.currency_symbol} ${Number(gc.gcp_amount)}`;
    } else {
      return `${Number(gc.gcp_percent)} %`;
    }
  };

  return (
    <>
      {!!clientList.length ? (
        <Grid container>
          <Grid item xs={12}>
            <form onSubmit={handleSubmit}>
              <Grid container item xs={12} spacing={2} direction="row">
                <Grid item xs={6}>
                  <Grid item mb={showAlerts ? 2 : 0}>
                    {isError && showAlerts && (
                      <ErrorAlert
                        message={error as string}
                        handleDismiss={() => setShowAlerts(false)}
                      />
                    )}

                    {isSuccess && showAlerts && (
                      <SuccessAlert
                        message={t('financial.client_list.asign_discount.form.success_alert')}
                        handleDismiss={() => setShowAlerts(false)}
                      />
                    )}
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <InputLabel>
                        {t('financial.client_list.asign_discount.form.config_label')}
                      </InputLabel>
                      <Autocomplete
                        options={discountConfiguration}
                        loading={isLoadingDC}
                        loadingText={t('financial.client_list.asign_discount.form.config_loading')}
                        noOptionsText={t(
                          'financial.client_list.asign_discount.form.config_no_options'
                        )}
                        getOptionLabel={(option) => option.gc_description || ''}
                        onChange={(_, value) => {
                          setFieldValue('group_configuration', value);
                          setGroupConfiguration(value?.group_configuration_product[0]);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder={t(
                              'financial.client_list.asign_discount.form.config_placeholder'
                            )}
                          />
                        )}
                        fullWidth
                        clearIcon={null}
                      />
                      {errors.group_configuration && touched.group_configuration && (
                        <FormHelperText error>{t(errors.group_configuration)}</FormHelperText>
                      )}
                    </Grid>

                    <Grid item xs={9}>
                      <InputLabel>
                        {t('financial.client_list.asign_discount.form.student_record_label')}
                      </InputLabel>
                      <Autocomplete
                        options={student?.user?.student?.student_records || []}
                        noOptionsText={t(
                          'financial.client_list.asign_discount.form.student_record_no_options'
                        )}
                        getOptionLabel={(option) => option.education_level.edu_name || ''}
                        onChange={(_, value) => {
                          setFieldValue('student_record', value);
                          // setGroupConfiguration(value?.student_record_product[0]);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder={t(
                              'financial.client_list.asign_discount.form.student_record_placeholder'
                            )}
                          />
                        )}
                        fullWidth
                        clearIcon={null}
                      />
                      {errors.student_record && touched.student_record && (
                        <FormHelperText error>{t(errors.student_record)}</FormHelperText>
                      )}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  {/* <Box className={classes.boxContainer}> */}
                  <TableContainer component={SubCard} content={false}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            {t('financial.client_list.asign_discount.table.student')}
                          </TableCell>
                          <TableCell align="center">
                            {t('financial.client_list.table.type')}
                          </TableCell>
                          <TableCell align="center">
                            {t('financial.client_list.table.discount')}
                          </TableCell>
                          <TableCell align="center">
                            {t('financial.client_list.table.start_validity')}
                          </TableCell>
                          <TableCell align="center">
                            {t('financial.client_list.table.end_validity')}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>{`${student?.pers_first_lastname || ''} ${
                            student?.pers_second_lastname || ''
                          } ${student?.pers_firstname || ''} ${
                            student?.pers_secondname || ''
                          }`}</TableCell>
                          <TableCell align="center">
                            {values.group_configuration?.type_discount.cat_name || '-'}
                          </TableCell>
                          <TableCell align="center">
                            {groupConfiguration ? getAmount(groupConfiguration) : null || '-'}
                          </TableCell>
                          <TableCell align="center">
                            {values.group_configuration?.gc_start_date || '-'}
                          </TableCell>
                          <TableCell align="center">
                            {values.group_configuration?.gc_end_date || '-'}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {/* </Box> */}
                </Grid>

                <Grid item sx={{ mt: 1 }}>
                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    color="primary"
                    disabled={isLoadingAD}
                    endIcon={isLoadingAD && <CircularProgress size={20} />}
                  >
                    {t('shared.assign_button.label')}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
    </>
  );
};

export default AssignDiscount;
