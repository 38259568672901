import AcademicCounselingIndex from '../components/AcademicCounselingIndex';
import { useAppSelector } from '../../../../hooks/useRedux';
import AcademicCareerDetail from '../components/AcademicCareerDetail';
import React from 'react';

const AcademicCounselingIndexScreen = function () {
  const { openModalMatterDetail } = useAppSelector((state) => state.academicCounseling);

  return (
    <React.Fragment>
      <AcademicCounselingIndex />
      {openModalMatterDetail && <AcademicCareerDetail />}
    </React.Fragment>
  );
};

export default AcademicCounselingIndexScreen;
