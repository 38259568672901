import {
  IconButton,
  Grid,
  TextField,
  Typography,
  useTheme,
  CircularProgress,
  Button,
  Paper,
  Autocomplete,
} from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { IconSearch, IconZoomQuestion } from '@tabler/icons';
import { format } from 'date-fns';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import { useTableSearch } from '../../../../../hooks/useTableSearch';
import CustomPaginationAndItems from '../../../../shared/components/tables/CustomPaginationAndItems';
import { useGetAvailableClassificationsQuery } from '../../../classifications/slices/classificationsApiSlice';
import {
  useLazyGetBooksBySearchQuery,
  useRegisterVisitStudentMutation,
} from '../../slices/libraryDashboardApiSlice';
import {
  setBooksSearched,
  setShowBooksSearch,
  updateBooksFilters,
} from '../../slices/libraryDashboardSlice';
import BookCard from '../Books/BookCard';
// import PopularSearch from './PopularSearch';

// const popularSearchs = [
//   {
//     text: 'Biología',
//   },
//   {
//     text: 'Medicina',
//   },
//   {
//     text: 'La naturaleza I',
//   },
// ];

const Search = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const { userInfo, studentRecordReference } = useAppSelector((state) => state.auth);

  const [registerVisit] = useRegisterVisitStudentMutation();

  const handleVisit = () => {
    registerVisit({
      date: format(new Date(), 'yyyy-MM-dd'),
      educationLevelId: studentRecordReference?.[0]?.education_level_id
        ? Number(studentRecordReference?.[0]?.education_level_id)
        : 0,
      personId: Number(userInfo?.personId!),
      studentRecordId: userInfo?.studentInfo?.studentRecordId
        ? Number(userInfo?.studentInfo?.studentRecordId)
        : 0,
      type: 'catalog',
    });
  };

  const {
    booksFilters: { search, page, perPage },
    showBooksSearch,
  } = useAppSelector((state) => state.libraryDashboard);

  const { data: categories = [], error } = useGetAvailableClassificationsQuery({
    parent_id: 1,
  });

  const { setSearch, setPerPage, setPage } = useTableSearch({
    search,
    page,
    perPage,
    updateFunction: updateBooksFilters,
  });

  const handleSearch = (_search: string) => {
    handleVisit();
    dispatch(setShowBooksSearch(true));
    getBooks({
      search: _search,
      page,
      perPage,
    }).then(({ data }) => {
      dispatch(setBooksSearched(data?.data || []));
    });
  };

  const handleShowDashboard = () => {
    dispatch(setShowBooksSearch(false));
    dispatch(setBooksSearched([]));
    setSearch('');
  };

  const [getBooks, { data, isLoading, isFetching }] = useLazyGetBooksBySearchQuery();

  useEffect(
    () => {
      if (showBooksSearch) {
        getBooks({
          search,
          page,
          perPage,
        }).then(({ data }) => {
          dispatch(setBooksSearched(data?.data || []));
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page, perPage]
  );

  return (
    <>
      <Grid
        container
        sx={{
          backgroundColor: theme.palette.primary.light,
        }}
        justifyContent="center"
        px={2}
        py={4}
      >
        <Grid item xs={10} md={8}>
          <Typography variant="h4" mb={1}>
            Buscar
          </Typography>
          <Paper component="form" sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <Autocomplete
              options={categories || []}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  sx={{ width: '100%' }}
                  placeholder="Seleccionar categoría"
                />
              )}
              sx={{
                width: '30%',
                '.MuiInputBase-root': {
                  borderRadius: 0,
                },
                '.MuiInputBase-input': {
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                },
                '.MuiOutlinedInput-notchedOutline': {
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                },
              }}
            />
            <TextField
              variant="outlined"
              placeholder="Ingrese el nombre de un libro"
              sx={{
                height: '53.13px',
                width: '65%',
                '.MuiInputBase-root': {
                  height: '53.13px',
                },
                '.MuiInputBase-input': {
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                  height: '53.13px',
                  padding: '0 20px',
                },
                '.MuiOutlinedInput-notchedOutline': {
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                },
              }}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <IconButton
              sx={{
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.common.white,
                borderRadius: '0 12px 12px 0 !important',
                //width: '5%',
                '&:hover': {
                  cursor: 'pointer',
                  backgroundColor: `${theme.palette.primary.main}95`,
                  color: theme.palette.common.white,
                },
                '&.Mui-disabled': {
                  backgroundColor: theme.palette.grey[200],
                  border: `1px solid ${theme.palette.grey[500]}`,
                },
                height: '53.13px',
              }}
              onClick={() => handleSearch(search)}
              disabled={search.length < 3}
            >
              <IconSearch size={24} />
            </IconButton>
          </Paper>

          <Grid container xs={12} justifyContent="center" mt={2}>
            <Grid item>
              <Button
                variant="outlined"
                color="secondary"
                startIcon={<IconZoomQuestion size={24} />}
                onClick={() => history.push('/biblioteca/preguntas-frecuentes')}
              >
                Preguntas frecuentes
              </Button>
            </Grid>
          </Grid>
        </Grid>

        {/* <Grid item container xs={8} alignItems="center" mt={2}>
          <Typography variant="h5" sx={{ mr: 2 }}>
            Busquedas populares:
          </Typography>
          {popularSearchs?.map((search, index) => (
            <Grid item key={index}>
              <PopularSearch handleSearch={handleSearch} text={search.text} />
            </Grid>
          ))}
        </Grid> */}
      </Grid>

      {showBooksSearch && (
        <Grid item container xs={12} p={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleShowDashboard}
            startIcon={<ArrowBack />}
          >
            Volver al inicio
          </Button>
        </Grid>
      )}
      {isLoading ||
        (isFetching && (
          <Grid item container xs={12} justifyContent="center" p={2}>
            <CircularProgress size={40} />
          </Grid>
        ))}
      {!isLoading && !isFetching && data && showBooksSearch && (
        <>
          <Grid item container xs={12} p={2}>
            <Typography variant="h5" sx={{ mr: 2 }}>
              Resultados de la búsqueda:
            </Typography>
            {data?.data?.length === 0 && (
              <Typography variant="body1" sx={{ mr: 2 }}>
                No se encontraron resultados para la búsqueda.
              </Typography>
            )}
            {data?.data?.length > 0 && (
              <Grid item container xs={12} spacing={2} mt={2}>
                {data?.data?.map((book) => (
                  <Grid item xs={4}>
                    <BookCard book={book} />
                  </Grid>
                ))}
              </Grid>
            )}
          </Grid>

          <Grid item xs={12} p={2}>
            <CustomPaginationAndItems
              error={error}
              isLoading={isLoading}
              page={page}
              setPage={setPage}
              perPage={perPage}
              setPerPage={setPerPage}
              total={data?.total}
              paginationBox
              perPageBox
            />
          </Grid>
        </>
      )}
    </>
  );
};

export default Search;
