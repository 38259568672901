import { Redirect, Switch } from 'react-router-dom';
import { useAppSelector } from '../../../../../../hooks/useRedux';
import GuardRoute from '../../../../../../router/guards/GuardRoute';
import LauchingAddScreen from '../screen/LauchingAddScreen';
import LaunchingResultsScreen from '../screen/LaunchingResultsScreen';
import LaunchingScreen from '../screen/LaunchingScreen';
// eslint-disable-next-line import/no-cycle

export const LaunchingRoutes = () => {
  const { currentModule } = useAppSelector((state) => state.access);

  const hasPermission = 'evaluation' in currentModule?.permissions!;

  if (!hasPermission) return <Redirect to="/dashboard" />;

  return (
    <Switch>
      <GuardRoute
        exact
        path="/lanzamiento"
        component={LaunchingScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.list}
      />
      <GuardRoute
        exact
        path="/lanzamiento/lanzamiento-resultados"
        component={LaunchingResultsScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.list}
      />
      <GuardRoute
        exact
        path="/lanzamiento/lanzar-evaluacion"
        component={LauchingAddScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.create}
      />
    </Switch>
  );
};
