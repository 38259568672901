import { mainApi } from '../../../../api/mainApi';
import { AccessState } from '../../../../types';
import { middlewareApi } from '../../../middleware/middlewareApi';
import { CreditNotePayload } from '../interfaces/payload.interface';

export const generateCreditNoteTags = middlewareApi.enhanceEndpoints({
  addTagTypes: [
    'PaymentsDebtsHistory', //
    'electronicDocuments', //
    'StudentsDebts', //data all de deudas del estudiante
  ],
});
// Define a service using a base URL and expected endpoints
export const generateCreditNoteApi = generateCreditNoteTags.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * @POST generar deuda
     */
    generateCreditNote: builder.mutation<any, { generateCreditNotePayload: CreditNotePayload }>({
      queryFn: async ({ generateCreditNotePayload }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = currentProfile?.profileId;

          const { data } = await mainApi.post<any>('/cash-collection/generate/creditNote', {
            user_profile_id,
            ...generateCreditNotePayload,
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [
        //considerar cambiar el id: LIST -> studentId del payload
        { type: 'PaymentsDebtsHistory', id: 'LIST' },
        { type: 'electronicDocuments', id: 'LIST' },
        { type: 'StudentsDebts', id: 'LIST' },
      ],
    }),

    //
  }),
});

export const { useGenerateCreditNoteMutation } = generateCreditNoteApi;
