import DeleteAlert from '../../../shared/components/dialogs/DeleteAlert';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { useDeleteCollaboratorMutation } from '../slices/collaboratorsApiSlice';
import { resetCollabIdToDelete, setDeleteModalOpen } from '../slices/collaboratorsSlice';

const DeleteCollaboratorDialog = () => {
  const dispatch = useAppDispatch();

  const { deleteModalOpen, collaboratorToDelete } = useAppSelector((state) => state.collaborators);
  const { currentProfile } = useAppSelector((state) => state.access);

  const [deleteCollaborator, { isLoading, isSuccess, isError, error }] =
    useDeleteCollaboratorMutation();

  const handleDelete = async () => {
    await deleteCollaborator({
      profileId: currentProfile?.profileId!,
      collabId: collaboratorToDelete!,
    });
  };

  const handleClose = () => {
    dispatch(setDeleteModalOpen(false));
    dispatch(resetCollabIdToDelete());
  };

  return (
    <DeleteAlert
      open={deleteModalOpen}
      onClose={handleClose}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      handleDelete={handleDelete}
      error={error as string}
    />
  );
};

export default DeleteCollaboratorDialog;
