import { middlewareApi } from '../../../middleware/middlewareApi';
import { mainApi } from '../../../../api/mainApi';

import { AccessState } from '../../../../types';
import {
  ProjectDocumentsPayload,
  ProjectsbyCollaborator,
  StudentsProjectDetails,
  StudentsProjectResearchDetailsPayload,
} from '../interfaces/projectsByCollaborator.interfaces';
import {
  DocumentsByBinnancle,
  ResponseUploadFilesProjectsByStudent,
  StudentsByProjects,
} from '../interfaces/studentsByProjects.interfaces';
import { ResponseUploadFilesProjects } from '../interfaces/responseUploadFileProject.interfaces';
import { Project } from '../interfaces/project.interfaces';
import { ProjectFollowResponse } from '../interfaces/projectFollow.interfaces';

export const InvestigationLinesTags = middlewareApi.enhanceEndpoints({
  addTagTypes: [
    'ProjectsByCollaborator',
    'StudentsByProjects',
    'StudentsByProjectsDetails',
    'DocumentsByBinnancle',
    'projectInfo',
  ],
});

export const ProjectsByCollaboratorApi = InvestigationLinesTags.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * @GET Projects By Collaborator response
     */
    getProjectsByCollaborator: builder.query<
      ProjectsbyCollaborator,
      {
        collaboratorId: string | number;
        profileId?: number;
        page?: number;
        size?: number;
        sort?: string;
        type_sort: string;
        search: string;
      }
    >({
      queryFn: async (
        { collaboratorId, profileId, page, size, sort, type_sort, search },
        { getState }
      ) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = profileId || currentProfile?.profileId;

          const { data } = await mainApi.get<ProjectsbyCollaborator>(
            `/collaborator-research-projects-follow/${collaboratorId}`,
            {
              params: {
                user_profile_id,
                page: page,
                size: size,
                sort: sort,
                type_sort: type_sort,
                search: search,
              },
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'ProjectsByCollaborator', id } as const)),
              { type: 'ProjectsByCollaborator', id: 'LIST' },
            ]
          : [{ type: 'ProjectsByCollaborator', id: 'LIST' }],
    }),

    getStudentsByProjects: builder.query<
      StudentsByProjects,
      {
        projectId: string;
        profileId?: number;
        page?: number;
        size?: number;
        sort?: string;
        type_sort: string;
        search: string;
      }
    >({
      queryFn: async (
        { projectId, profileId, page, size, sort, type_sort, search },
        { getState }
      ) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = profileId || currentProfile?.profileId;

          const { data } = await mainApi.get<StudentsByProjects>(
            `/projects-research/${projectId}/students`,
            {
              params: {
                user_profile_id,
                page: page,
                size: size,
                sort: sort,
                type_sort: type_sort,
                search: search,
              },
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'StudentsByProjects', id } as const)),
              { type: 'StudentsByProjects', id: 'LIST' },
            ]
          : [{ type: 'StudentsByProjects', id: 'LIST' }],
    }),

    getProject: builder.query<
      Project,
      {
        projectId: string;
      }
    >({
      queryFn: async ({ projectId }) => {
        try {
          const { data } = await mainApi.get<Project>(`/projects-research/${projectId}`);

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: () => [{ type: 'projectInfo', id: 'info' }],
    }),

    getProjectsFollow: builder.query<
      ProjectFollowResponse,
      {
        profileId?: number;
        page?: number;
        size?: number;
        sort?: string;
        type_sort: string;
        search: string;
        start_date?: Date | null;
        end_date?: Date | null;
      }
    >({
      queryFn: async (
        { profileId, page, size, sort, type_sort, search, start_date, end_date },
        { getState }
      ) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = profileId || currentProfile?.profileId;

          const { data } = await mainApi.get<ProjectFollowResponse>(`/projects-research-follow`, {
            params: {
              user_profile_id,
              page: page,
              size: size,
              sort: sort,
              type_sort: type_sort,
              search: search,
              ...(start_date && { start_date }),
              ...(end_date && { end_date }),
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(
                ({ project_research_id }) => ({ type: 'projectInfo', project_research_id } as const)
              ),
              { type: 'projectInfo', id: 'LIST' },
            ]
          : [{ type: 'projectInfo', id: 'LIST' }],
    }),

    getStudentsByProjectsDetails: builder.query<
      StudentsProjectDetails[],
      {
        projectId: string | number;
        studentId: string | number;
      }
    >({
      queryFn: async ({ projectId, studentId }) => {
        try {
          const { data } = await mainApi.get<StudentsProjectDetails[]>(
            `/student-research-projects-follow/${studentId}/project/${projectId}`
          );

          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'StudentsByProjectsDetails', id } as const)),
              { type: 'StudentsByProjectsDetails', id: 'LIST' },
            ]
          : [{ type: 'StudentsByProjectsDetails', id: 'LIST' }],
    }),

    getDocuementsByBinnancle: builder.query<
      DocumentsByBinnancle[],
      {
        studentProjectResearchDetails: string | number;
      }
    >({
      queryFn: async ({ studentProjectResearchDetails }) => {
        try {
          const { data } = await mainApi.get<DocumentsByBinnancle[]>(
            `/students-project-research-details/${studentProjectResearchDetails}/documents`
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) =>
        result && Array.isArray(result)
          ? [
              ...result.map(({ id }) => ({ type: 'DocumentsByBinnancle', id } as const)),
              { type: 'DocumentsByBinnancle', id: 'LIST' },
            ]
          : [{ type: 'DocumentsByBinnancle', id: 'LIST' }],
    }),

    deleteDocumentByBinnancle: builder.mutation<
      any,
      {
        projectResearchDocument: string | number;
      }
    >({
      queryFn: async ({ projectResearchDocument }) => {
        try {
          const { data } = await mainApi.delete<any>(
            `/projects-research-documents/${projectResearchDocument}`
          );
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: ['DocumentsByBinnancle'],
    }),

    uploadFilesProjects: builder.mutation<
      ResponseUploadFilesProjects,
      {
        projectId: string | number;
        payload: ProjectDocumentsPayload;
      }
    >({
      queryFn: async ({ projectId, payload }) => {
        try {
          const { data } = await mainApi.post<ResponseUploadFilesProjects>(
            `/projects-research-documents`,
            {
              project_research_id: projectId,
              ...payload,
            }
          );
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),
    uploadFilesProjectsByStudent: builder.mutation<
      any,
      {
        studentProjectResearchDetails: string | number;
        payload: ResponseUploadFilesProjectsByStudent;
      }
    >({
      queryFn: async ({ studentProjectResearchDetails, payload }) => {
        try {
          const { data } = await mainApi.post<any>(
            `/students-project-research-details/${studentProjectResearchDetails}/documents`,
            {
              ...payload,
            }
          );
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: ['DocumentsByBinnancle'],
    }),
    studentsProjectResearchDetails: builder.mutation<
      any,
      {
        payload: StudentsProjectResearchDetailsPayload;
      }
    >({
      queryFn: async ({ payload }) => {
        try {
          const { data } = await mainApi.post<any>(`/students-project-research-details`, {
            ...payload,
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: ['StudentsByProjectsDetails'],
    }),
    editStudentsProjectResearchDetails: builder.mutation<
      any,
      {
        studentProjectResearchDetails: string | number;
        payload: StudentsProjectResearchDetailsPayload;
      }
    >({
      queryFn: async ({ studentProjectResearchDetails, payload }) => {
        try {
          const { data } = await mainApi.put<any>(
            `/students-project-research-details/${studentProjectResearchDetails}`,
            {
              ...payload,
            }
          );
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: ['StudentsByProjectsDetails'],
    }),

    deleteStudentsProjectResearchDetails: builder.mutation<
      any,
      {
        studentProjectResearchDetails: string | number;
      }
    >({
      queryFn: async ({ studentProjectResearchDetails }) => {
        try {
          const { data } = await mainApi.delete<any>(
            `/students-project-research-details/${studentProjectResearchDetails}`
          );
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: ['StudentsByProjectsDetails'],
    }),

    approveSubjectCommunityEngagement: builder.mutation<
      any,
      {
        projectId: string | number;
        studentId: string | number;
      }
    >({
      queryFn: async ({ projectId, studentId }) => {
        try {
          const { data } = await mainApi.post<any>(
            `/approve-subject-community-engagement/${studentId}`,
            {
              project_research_id: projectId,
            }
          );
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    //
  }),
});

export const {
  useGetProjectsByCollaboratorQuery,
  useGetStudentsByProjectsQuery,
  useUploadFilesProjectsMutation,
  useStudentsProjectResearchDetailsMutation,
  useGetStudentsByProjectsDetailsQuery,
  useApproveSubjectCommunityEngagementMutation,
  useDeleteStudentsProjectResearchDetailsMutation,
  useEditStudentsProjectResearchDetailsMutation,
  useGetDocuementsByBinnancleQuery,
  useDeleteDocumentByBinnancleMutation,
  useUploadFilesProjectsByStudentMutation,
  useGetProjectQuery,
  useGetProjectsFollowQuery,
} = ProjectsByCollaboratorApi;
