import { Grid, Typography } from '@material-ui/core';
import MainCard from '../../../../shared/components/cards/MainCard';
function HeroData({ img = '' }) {
  return (
    <Grid
      container
      alignItems={'center'}
      component={MainCard}
      justifyContent="center"
      minHeight="50vh"
    >
      <Grid container alignItems={'center'} justifyContent={'center'}>
        <Grid item md={6}>
          <img width={'100%'} src={img} alt="" />
        </Grid>
        <Grid item md={3}>
          <Typography variant="h4">Aun no tienes contenido</Typography>
          <Typography variant="body1">
            Puedes Empezar a realizar tus actividades o , revisar tu lista de alumnos
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default HeroData;
