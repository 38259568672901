import DebitAlert from '../../../shared/components/dialogs/DebitAlert';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { useAddCertificateDebitMutation } from '../slices/certificateApiSlice';
import { resetCertificateToDebit, setDebitModalOpen } from '../slices/certificateSlice';
import { useHistory /*, useRouteMatch*/ } from 'react-router-dom';

const DebitCertificateDialog = () => {
  const history = useHistory();
  //const { path } = useRouteMatch();
  const dispatch = useAppDispatch();
  const { currentRecordStudent } = useAppSelector((state) => state.access);
  const { certificateToDebit, debitModalOpen } = useAppSelector((state) => state.certificate);
  const { currentProfile } = useAppSelector((state) => state.access);

  const [debitCertificate, { isLoading, isSuccess, isError, error }] =
    useAddCertificateDebitMutation();

  const handleDebit = () => {
    debitCertificate({
      profileId: currentProfile?.profileId!,
      appId: certificateToDebit?.id!,
      pdfUrl: certificateToDebit?.pdfUrl!,
      studentRecordId: currentRecordStudent?.id!,
      app_acronym: certificateToDebit?.appAcronym!,
    });
  };

  const handleClose = () => {
    dispatch(setDebitModalOpen(false));
    dispatch(resetCertificateToDebit());
  };
  const handleRedirect = () => {
    dispatch(setDebitModalOpen(false));
    dispatch(resetCertificateToDebit());
    history.push(`./../../estado-de-cuenta`);
  };
  return (
    <DebitAlert
      open={debitModalOpen}
      onClose={handleClose}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      handleDebit={handleDebit}
      handleRedirect={handleRedirect}
      error={error as string}
    />
  );
};

export default DebitCertificateDialog;
