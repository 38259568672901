import {
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { IconEdit, IconTrash } from '@tabler/icons';
import { useHistory } from 'react-router-dom';
import { useAppDispatch } from '../../../../../hooks/useRedux';
import MeshStatusChip from '../../../../shared/components/badges/MeshStatusChip';
import { journeyTypes } from '../../../../shared/constants/journeyType';
import useGuard from '../../hooks/useGuard';
import { openDeleteModalWith } from '../../slice/hoursTeacherSlice';
import { useRouteMatch } from 'react-router-dom';

interface Props {
  apId: number;
  apName: string;
  educationLevel: string;
  status: string;
  apDegree: string;
  hClasses: number;
  hPreparation: number;
  hTutoring: number;
  hBonding: number;
  hAcademicManagement: number;
  hResearch: number;
  identification: string;
  period: string;
  hTotal: number;
  journey: string;
}

const useStyles = makeStyles((theme) => ({
  cardBox: {
    boxShadow: `0px 0px 20px #EAF0F7`,
    border: `1px solid ${theme.palette.secondary[200]}`,
    borderRadius: 12,
    overflow: 'hidden',
  },
  cardHeader: {
    backgroundColor: theme.palette.primary.main,
    padding: '24px 24px 16px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  codeText: {
    color: '#fff',
    fontWeight: 400,
  },
  headerText: {
    color: '#fff',
    lineHeight: 1.5,
  },
  subtitleText: {
    color: theme.palette.secondary[200],
  },
  apDegree: {
    color: theme.palette.primary[800],
    marginBlockEnd: 8,
  },
  cardBody: {
    padding: '16px 24px 24px',
  },
  detailText: {
    color: theme.palette.primary[800],
  },
  cardDetails: {},
}));

const TeacherCard = ({
  apId,
  apName,
  educationLevel,
  status,
  apDegree,
  hClasses,
  hPreparation,
  hTutoring,
  hBonding,
  hAcademicManagement,
  hResearch,
  identification,
  period,
  hTotal,
  journey,
}: Props) => {
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const matchUpLg = useMediaQuery(theme.breakpoints.up('xl'));
  const journeyObj = journeyTypes.find((j) => j.id === journey);

  const { path } = useRouteMatch();

  const { hasAccess } = useGuard();

  const dispatch = useAppDispatch();

  const handleDelete = async (hoursTeacherId: number) => {
    dispatch(openDeleteModalWith(hoursTeacherId));
  };

  return (
    <Box className={classes.cardBox}>
      <Grid container direction="column">
        <Grid item>
          <Box className={classes.cardHeader}>
            <Box>
              <Typography variant="h3" component="h2" className={classes.headerText}>
                {apName}
              </Typography>
              <Typography variant="h4" className={classes.subtitleText}>
                {identification}
              </Typography>
              <Typography variant="subtitle1" className={classes.subtitleText}>
                {educationLevel}
              </Typography>
              <Typography variant="subtitle2" className={classes.subtitleText}>
                {period}
              </Typography>
              <Typography variant="subtitle2" className={classes.subtitleText}>
                {journeyObj?.label}
              </Typography>
            </Box>
            <Box>
              <MeshStatusChip statusName={status} />
            </Box>
          </Box>
        </Grid>
        <Grid item>
          <Box className={classes.cardBody}>
            <Grid container direction="column" spacing={2}>
              {/* Title */}
              <Grid item>
                <Typography variant="h4" className={classes.apDegree}>
                  {apDegree}: <Chip label={hTotal} />
                </Typography>
              </Grid>

              {/* Details */}
              <Grid item container spacing={1}>
                <Grid item xs={4}>
                  <Typography variant="subtitle1" className={classes.detailText}>
                    Clases
                  </Typography>
                  <Typography variant="subtitle2">{hClasses}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="subtitle1" className={classes.detailText}>
                    Preparación
                  </Typography>
                  <Typography variant="subtitle2">{hPreparation}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="subtitle1" className={classes.detailText}>
                    Tutorias
                  </Typography>
                  <Typography variant="subtitle2">{hTutoring}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="subtitle1" className={classes.detailText}>
                    Vinculación
                  </Typography>
                  <Typography variant="subtitle2">{hBonding}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="subtitle1" className={classes.detailText}>
                    Gestión Acádemica
                  </Typography>
                  <Typography variant="subtitle2">{hAcademicManagement}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="subtitle1" className={classes.detailText}>
                    Investigación
                  </Typography>
                  <Typography variant="subtitle2">{hResearch}</Typography>
                </Grid>
              </Grid>

              {/* Buttons */}
              <Grid item container spacing={1} alignItems="center">
                {hasAccess('edit') && (
                  <Grid item>
                    <Button
                      variant="outlined"
                      size={matchUpLg ? 'medium' : 'small'}
                      startIcon={<IconEdit size={20} />}
                      onClick={() => history.push(`${path}/${apId}/editar`)}
                    ></Button>
                  </Grid>
                )}
                {hasAccess('delete') && (
                  <Grid item>
                    <IconButton
                      onClick={() => handleDelete(apId)}
                      size={matchUpLg ? 'medium' : 'small'}
                      sx={{ color: 'error.main' }}
                    >
                      <IconTrash size={20} />
                    </IconButton>
                  </Grid>
                )}
                {!hasAccess('edit') && !hasAccess('delete') && 'Ninguna'}
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TeacherCard;
