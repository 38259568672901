import { Switch, Redirect } from 'react-router-dom';
import { useAppSelector } from '../../../../hooks/useRedux';

//screens
import RegisterNivelationScreen from '../screens/RegisterNivelationScreen';
import AddRegisterNivelationScreen from '../screens/AddRegisterNivelationScreen';

import GuardRoute from '../../../../router/guards/GuardRoute';
import { permissionKey, subPermissionKeys } from '../constants/permissions';

const RegisterNivelationRouter = () => {
  const { currentModule } = useAppSelector((state) => state.access);

  const hasPermission = 'periods' in currentModule?.permissions!;

  if (!hasPermission) return <Redirect to="/dashboard" />;

  return (
    <Switch>
      <GuardRoute
        exact
        path="/administrar-registro-nivelacion/:studentId/registrar/:periodId"
        component={AddRegisterNivelationScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.create}
      />
      <GuardRoute
        exact
        path="/administrar-registro-nivelacion"
        component={RegisterNivelationScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.read}
      />
    </Switch>
  );
};

export default RegisterNivelationRouter;
