//DBConfig.js|tsx

export const DBConfig = {
  name: 'nbsLocalStorageDb',
  version: 1,
  objectStoresMeta: [
    {
      store: 'config',
      storeConfig: { keyPath: 'id', autoIncrement: true },
      storeSchema: [
        { name: 'token_client', keypath: 'token_client', options: { unique: true } },
        { name: 'keyword', keypath: 'keyword', options: { unique: true } },
      ],
    },
  ],
};
