import { IconButton, Tooltip } from '@material-ui/core';
import { Assignment } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

const PreviewButton = ({
  handlePreview,
  title,
}: {
  handlePreview?: () => void;
  title?: string;
}) => {
  const { t } = useTranslation();

  return (
    <Tooltip title={title || t('shared.preview_button.tooltip')}>
      <IconButton aria-label="preview" color="default" onClick={handlePreview}>
        <Assignment sx={{ fontSize: '1.3rem' }} />
      </IconButton>
    </Tooltip>
  );
};

PreviewButton.defaultProps = {
  handlePreview: () => {},
  title: '',
};

export default PreviewButton;
