import { mainApi } from '../../../../../../api/mainApi';
import { middlewareApi } from '../../../../../middleware/middlewareApi';
import { IResponse } from '../../../../../shared/interfaces/response.interface';
import { IntitutesByOffers } from '../interfaces/institutesByOffer.interface';
import { EscalePayload, IIntegralEvaluation } from '../interfaces/integralEvaluations.interface';
import { Ofertas } from '../interfaces/oferts.interface';
import { TypeProfileByID } from '../interfaces/typesProfileById.interface';
import { RubricData } from '../interfaces/rubrics.interface';

export const integralEvaluationsTags = middlewareApi.enhanceEndpoints({
  addTagTypes: ['IntegralEvaluations'],
});

export const integralEvaluationsApi = integralEvaluationsTags.injectEndpoints({
  endpoints: (builder) => ({
    getIntegralEvaluations: builder.query<
      IResponse<RubricData>,
      {
        page: number;
        perPage: number;
        search: string;
        sort?: string;
        typeSort?: string;
      }
    >({
      queryFn: async ({ page, perPage, search, sort, typeSort }, { dispatch }) => {
        try {
          const { data } = await mainApi.get<IResponse<RubricData>>('/eva/evaluations-rubrica', {
            params: {
              page,
              size: perPage,
              search,
              ...(sort && { sort }),
              ...(typeSort && { type_sort: typeSort }),
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) =>
        result && result.data
          ? [
              ...result.data.map(({ id }) => ({ type: 'IntegralEvaluations', id } as const)),
              { type: 'IntegralEvaluations', id: 'LIST' },
            ]
          : [{ type: 'IntegralEvaluations', id: 'LIST' }],
    }),

    getAllIntegralEvaluations: builder.query<RubricData[], {}>({
      // eslint-disable-next-line no-empty-pattern
      queryFn: async ({}, { dispatch }) => {
        try {
          const { data } = await mainApi.get<RubricData[]>('/eva/evaluations-rubrica', {
            params: {
              data: 'all',
            },
          });

          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'IntegralEvaluations', id } as const)),
              { type: 'IntegralEvaluations', id: 'LIST' },
            ]
          : [{ type: 'IntegralEvaluations', id: 'LIST' }],
    }),
    getTypeProfile: builder.query<
      IIntegralEvaluation[],
      {
        key: string | number;
      }
    >({
      // eslint-disable-next-line no-empty-pattern
      queryFn: async ({ key }, { dispatch }) => {
        try {
          const { data } = await mainApi.get<IIntegralEvaluation[]>(`/eva/type-profile/${key}`);

          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    getIntegralEvaluation: builder.query<
      IIntegralEvaluation,
      {
        id: string;
      }
    >({
      queryFn: async ({ id }, { dispatch }) => {
        try {
          const { data } = await mainApi.get<IIntegralEvaluation>(
            `/eva/integral-evaluations/${id}`
          );
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),
    addIntegralEvaluation: builder.mutation<IIntegralEvaluation, EscalePayload>({
      queryFn: async (payload, { dispatch }) => {
        try {
          const { data } = await mainApi.post<IIntegralEvaluation>(
            '/eva/evaluations-rubrica',
            payload
          );
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'IntegralEvaluations', id: 'LIST' }],
    }),

    updateIntegralEvaluation: builder.mutation<
      IIntegralEvaluation,
      { id: number | string; payload: EscalePayload }
    >({
      queryFn: async ({ id, payload }, { dispatch }) => {
        try {
          const { data } = await mainApi.put<IIntegralEvaluation>(
            `/eva/evaluations-rubrica/${id}`,
            payload
          );
          return { data };
        } catch (error: any) {
          return {
            error,
          };
        }
      },
      invalidatesTags: [{ type: 'IntegralEvaluations', id: 'LIST' }],
    }),
    deleteIntegralEvaluation: builder.mutation<
      IIntegralEvaluation,
      {
        id: string;
      }
    >({
      queryFn: async ({ id }, { dispatch }) => {
        try {
          const { data } = await mainApi.delete<IIntegralEvaluation>(`/integral-evaluations/${id}`);
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'IntegralEvaluations', id: 'LIST' }],
    }),

    getTypeProfileByProfileId: builder.query<
      TypeProfileByID,
      {
        id: string | number;
      }
    >({
      queryFn: async ({ id }) => {
        try {
          const { data } = await mainApi.get<TypeProfileByID>(`/eva/type-profile/${id}`);
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),
    getOferts: builder.query<Ofertas[], {}>({
      queryFn: async () => {
        try {
          const { data } = await mainApi.get<Ofertas[]>(
            `/offers?user_profile_id=1&data=all&off_description=`
          );
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),
    getInstitutesByOffer: builder.query<
      IntitutesByOffers,
      {
        key: string | number;
      }
    >({
      queryFn: async ({ key }) => {
        try {
          const { data } = await mainApi.get<IntitutesByOffers>(`offers/${key}?user_profile_id=1`);
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),
    deleteRubricById: builder.mutation<
      IIntegralEvaluation,
      {
        id: string | number;
      }
    >({
      queryFn: async ({ id }) => {
        try {
          const { data } = await mainApi.delete<IIntegralEvaluation>(
            `/eva/evaluations-rubrica/detalle/${id}`
          );
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'IntegralEvaluations', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetIntegralEvaluationsQuery,
  useLazyGetIntegralEvaluationsQuery,
  useGetAllIntegralEvaluationsQuery,
  useLazyGetAllIntegralEvaluationsQuery,
  useGetIntegralEvaluationQuery,
  useLazyGetIntegralEvaluationQuery,
  useAddIntegralEvaluationMutation,
  useUpdateIntegralEvaluationMutation,
  useDeleteIntegralEvaluationMutation,
  useLazyGetTypeProfileByProfileIdQuery,
  useGetOfertsQuery,
  useLazyGetInstitutesByOfferQuery,
  useDeleteRubricByIdMutation,
} = integralEvaluationsApi;
