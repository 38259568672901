import {
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
  Autocomplete,
  Button,
  CircularProgress,
  makeStyles,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useAppSelector } from '../../../../hooks/useRedux';
import { thirdRegistrationValues } from '../constants/formConstants';
import { ThirdRegistrationSchema } from '../constants/validationSchemas';
import { Period, ThirdRegistrationPayload } from '../interfaces/thirdTimeRegistration.interfaces';
import {
  useGetSubjectCurriculumsByStudentIdQuery,
  useAddThirdRegistrationMutation,
  useGetCoursesAvaliableByMatterIdQuery,
} from '../slices/academicRequestsApiSlice';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import TextFieldFetching from '../../../shared/components/TextFieldFetching';
import useGuard from '../hooks/useGuard';

import { useTranslation } from 'react-i18next';
import { useGetPeriodAcademicOfferingQuery } from '../../../maintenance/academic-offerings/slices/academicOfferingsApiSlice';

const useStyles = makeStyles((theme) => ({
  list: {
    listStyleType: 'none',
    margin: 0,
    padding: 0,
  },
  table: {
    //width: '100%',
    border: '1px solid black',
  },
  th: {
    textAlign: 'center',
    border: '1px solid black',
  },
  td: {
    textAlign: 'center',
    border: '1px solid black',
  },
  title: {
    fontWeight: 'bold',
  },
}));
const ThirdTimeRegistrationForm = () => {
  const { t } = useTranslation();

  const { hasAccess } = useGuard();
  const classes = useStyles();
  const { currentProfile, currentStudent, currentRol, currentRecordStudent } = useAppSelector(
    (state) => state.access
  );
  const { userInfo } = useAppSelector((state) => state.auth);
  const [showAlerts, setShowAlerts] = useState(true);
  const [autocompleteKey, setAutocompleteKey] = useState<{
    matter: number;
    course: number;
  }>({ matter: 0, course: 0 });

  const {
    data: subjectCurriculums = [],
    isLoading: isLoadingSubjectCurriculums,
    isFetching: isFetchingSC,
    isError: isErrorSC,
    error: errorSC,
  } = useGetSubjectCurriculumsByStudentIdQuery(
    { profileId: currentProfile?.profileId!, studentId: currentStudent?.id! },
    { refetchOnMountOrArgChange: 30 }
  );
  const [addThirdRegistration, { isLoading: isLoadingTR, isError, error, isSuccess }] =
    useAddThirdRegistrationMutation();
  const { handleSubmit, errors, touched, setFieldValue, getFieldProps, values } =
    useFormik<ThirdRegistrationPayload>({
      initialValues: thirdRegistrationValues,
      validationSchema: ThirdRegistrationSchema,
      onSubmit: async (values) => {
        await addThirdRegistration({
          profileId: currentProfile?.profileId!,
          userId: String(userInfo?.id!),
          roleId: currentRol?.rolId!,
          thirdRegistrationPayload: {
            app_description: values.app_description,
            course_id: values.course_id,
            subject_curriculum_id: values.subject_curriculum_id,
            period_id: values.period_id,
          },
        });
        setShowAlerts(true);
      },
    });
  const subjectCurriculumsChoice = subjectCurriculums.find(
    (sc) => Number(sc.id) === Number(values.subject_curriculum_id)
  );

  const {
    data: coursesAvaliable = [],
    isLoading: isLoadingCA,
    isFetching: isFetchingCA,
    isError: isErrorCA,
    error: errorCA,
  } = useGetCoursesAvaliableByMatterIdQuery(
    {
      profileId: currentProfile?.profileId!,
      studentId: currentStudent?.id!,
      matterId: subjectCurriculumsChoice?.matter_id!,
    },
    { skip: !subjectCurriculumsChoice, refetchOnMountOrArgChange: 30 }
  );
  const { data: periods = [], isLoading: isLoadingP } = useGetPeriodAcademicOfferingQuery(
    {
      profileId: currentProfile?.profileId!,
      academicOfferingId: currentRecordStudent?.education_level?.offer_id!,
      current: true,
    },
    {
      skip: !currentRecordStudent?.education_level?.offer_id,
    }
  );

  useEffect(() => {
    if ((isErrorSC && errorSC) || (errorCA && isErrorCA)) {
      setShowAlerts(true);
    }
  }, [isErrorSC, errorSC, isErrorCA, errorCA]);

  useEffect(() => {
    if (subjectCurriculums && subjectCurriculums.length === 1) {
      setAutocompleteKey({
        ...autocompleteKey,
        course: Math.random(),
      });
      setFieldValue('subject_curriculum_id', subjectCurriculums[0].id);
      setFieldValue('course_id', 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFieldValue, subjectCurriculums]);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction="column" gap={2}>
        <Grid item>
          {isError && showAlerts && (
            <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
          )}
          {isErrorSC && showAlerts && (
            <ErrorAlert message={errorSC as string} handleDismiss={() => setShowAlerts(false)} />
          )}
          {isErrorCA && showAlerts && (
            <ErrorAlert message={errorCA as string} handleDismiss={() => setShowAlerts(false)} />
          )}
          {isSuccess && showAlerts && (
            <SuccessAlert
              message={t('students.applications.success_message')}
              handleDismiss={() => setShowAlerts(false)}
            />
          )}
        </Grid>
        <Grid item container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <InputLabel>{t('students.applications.third_time_register.period')}</InputLabel>
            <Autocomplete
              options={(periods as Period[]) || []}
              loading={isLoadingP}
              getOptionLabel={(option) => option.per_name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(errors.period_id && touched.period_id)}
                  placeholder={t('students.applications.third_time_register.period_placeholder')}
                />
              )}
              onChange={(_, value) => {
                setAutocompleteKey({
                  ...autocompleteKey,
                  course: Math.random(),
                });
                setFieldValue('period_id', value?.id!);
                setFieldValue('course_id', 0);
              }}
              fullWidth
              clearIcon={null}
            />
            {errors.period_id && touched.period_id && (
              <FormHelperText error>{errors.period_id}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={12}>
            <InputLabel>{t('students.applications.third_time_register.subject')}</InputLabel>
            {!isFetchingSC ? (
              <Autocomplete
                options={subjectCurriculums}
                loading={isLoadingSubjectCurriculums}
                loadingText={t(
                  'students.applications.third_time_register.failed_subject_loading_placeholder'
                )}
                getOptionLabel={(option) => option.matter_rename}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(errors.subject_curriculum_id && touched.subject_curriculum_id)}
                    placeholder={t('students.applications.third_time_register.subject_placeholder')}
                  />
                )}
                onChange={(_, value) => {
                  setAutocompleteKey({
                    ...autocompleteKey,
                    course: Math.random(),
                  });
                  setFieldValue('subject_curriculum_id', value?.id!);
                  setFieldValue('course_id', 0);
                }}
                fullWidth
                clearIcon={null}
                defaultValue={subjectCurriculums.length === 1 ? subjectCurriculums[0] : null}
              />
            ) : (
              <TextFieldFetching />
            )}
            {errors.subject_curriculum_id && touched.subject_curriculum_id && (
              <FormHelperText error>{errors.subject_curriculum_id}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={12}>
            <InputLabel>{t('students.applications.third_time_register.course')}</InputLabel>
            {!isFetchingCA ? (
              <Autocomplete
                // key={isFetching}
                options={
                  subjectCurriculumsChoice !== undefined && errorCA === undefined
                    ? coursesAvaliable
                    : []
                }
                loading={isLoadingCA}
                loadingText={t(
                  'students.applications.third_time_register.course_loading_placeholder'
                )}
                getOptionLabel={(option) =>
                  'Sede: ' +
                  option.classroom.campus.cam_name +
                  ' - Aula: ' +
                  option.classroom.cl_name +
                  ' - Paralelo: ' +
                  option.parallel.par_name
                }
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option?.id.toString()}>
                      <ul className={classes.list}>
                        <li>
                          <span className={classes.title}>
                            {t('students.applications.third_time_register.campus')}:{' '}
                          </span>{' '}
                          {option.classroom.campus.cam_name}
                        </li>
                        <li>
                          <span className={classes.title}>
                            {t('students.applications.third_time_register.period')}:{' '}
                          </span>
                          {' ' + option.period?.per_name}
                        </li>
                        <li>
                          <span className={classes.title}>
                            {t('students.applications.third_time_register.type_period')}:{' '}
                          </span>
                          {' ' + option.period.type_period.tp_name}
                        </li>
                        <li>
                          <span className={classes.title}>
                            {t('students.applications.third_time_register.classroom')}:{' '}
                          </span>{' '}
                          {option.classroom.cl_name}
                        </li>
                        <li>
                          <span className={classes.title}>
                            {t('students.applications.third_time_register.parallel')}:{' '}
                          </span>{' '}
                          {option.parallel.par_name}
                        </li>
                        <li>
                          <span className={classes.title}>
                            {t('students.applications.third_time_register.hourhand')}:{' '}
                          </span>{' '}
                          {option.hourhand.hour_description}
                        </li>
                      </ul>
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(errors.course_id && touched.course_id)}
                    placeholder={t('students.applications.third_time_register.course_placeholder')}
                  />
                )}
                onChange={(_, value) => {
                  setFieldValue('course_id', value?.id!);
                }}
                fullWidth
                clearIcon={null}
              />
            ) : (
              <TextFieldFetching />
            )}
            {errors.course_id && touched.course_id && (
              <FormHelperText error>{errors.course_id}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12}>
            <InputLabel>{t('students.applications.third_time_register.reason')}</InputLabel>
            <TextField
              multiline
              rows={2}
              fullWidth
              error={Boolean(errors.app_description && touched.app_description)}
              placeholder={t('students.applications.third_time_register.reason_placeholder')}
              {...getFieldProps('app_description')}
            />
            {errors.app_description && touched.app_description && (
              <FormHelperText error>{errors.app_description}</FormHelperText>
            )}
          </Grid>
        </Grid>
        <Grid
          item
          container
          alignItems="center"
          justifyContent="flex-end"
          spacing={2}
          sx={{ mt: 1 }}
        >
          {hasAccess('save_third_registration') && (
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                size="large"
                color="primary"
                disabled={isLoadingTR}
                endIcon={isLoadingTR && <CircularProgress size={20} />}
              >
                {t('students.applications.save')}
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </form>
  );
};
export default ThirdTimeRegistrationForm;
