import DeleteAlert from '../../../shared/components/dialogs/DeleteAlert';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';

import { useDeleteMeshMutation } from '../slices/meshsApiSlice';
import { fireDeleteCurriculumDialog, resetCurriculumSelected } from '../slices/meshSlice';

const DeleteCurriculumDialog = () => {
  const dispatch = useAppDispatch();

  const { deleteCurriculumDialogOpened, curriculumSelected } = useAppSelector(
    (state) => state.meshs
  );
  const { currentProfile } = useAppSelector((state) => state.access);
  const profileId = currentProfile!.profileId;

  const [deletePeriod, { isLoading, isSuccess, isError, error }] = useDeleteMeshMutation();

  const handleDelete = () => {
    deletePeriod({ profileId, meshId: curriculumSelected! });
  };

  const handleClose = () => {
    dispatch(fireDeleteCurriculumDialog(false));
    dispatch(resetCurriculumSelected());
  };

  return (
    <DeleteAlert
      open={deleteCurriculumDialogOpened}
      onClose={handleClose}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      handleDelete={handleDelete}
      error={error as string}
    />
  );
};

export default DeleteCurriculumDialog;
