import { Box, Button, Typography } from '@material-ui/core';
import CustomIcon from '../../../../shared/components/illustrations/CustomIcon';
import icon from '../../../../../assets/images/no-tests.png';
import { useTranslation } from 'react-i18next';
import { StarBorderOutlined, ArticleOutlined } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';

const NotesDetails = () => {
  const history = useHistory();

  const { t } = useTranslation();
  return (
    <Box
      sx={{
        minHeight: '350px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          mb: 5,
        }}
      >
        <CustomIcon icon={icon} customWidth={350} />
        <Typography
          variant="h3"
          sx={{
            mt: 1,
          }}
        >
          {t('studentVirtualClassroom.content.description')}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          mt: 2,
        }}
      >
        <Button
          variant="contained"
          sx={{
            mr: 2,
          }}
          startIcon={<StarBorderOutlined />}
          onClick={() => history.push('/aula-virtual-estudiante/calificaciones')}
        >
          {t('studentVirtualClassroom.content.qualifications')}
        </Button>
        <Button
          variant="outlined"
          startIcon={<ArticleOutlined />}
          onClick={() => history.push('/aula-virtual-estudiante/calificaciones-aula-virtual')}
        >
          {t('studentVirtualClassroom.content.aula')}
        </Button>
      </Box>
    </Box>
  );
};

export default NotesDetails;
