import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISearchTable } from '../../../../../shared/interfaces/paginatedTable.interface';

const initialState = {
  listGroupTable: {
    page: 1,
    size: 5,
    search: '',
  },
  start_date: new Date().toISOString(),
  end_date: new Date().toISOString(),
  uuidGroup: '',
  openDeleteDialog: false,
  openAddGroupCourse: false,
};

export const listGroupsSlice = createSlice({
  name: 'listGroups',
  initialState,
  reducers: {
    setListGroupTable: (state, action: PayloadAction<ISearchTable>) => {
      state.listGroupTable = {
        page: action.payload.page,
        size: action.payload.perPage,
        search: action.payload.search,
      };
    },
    setStart_date: (state, action: PayloadAction<string>) => {
      state.start_date = action.payload;
    },
    setEnd_date: (state, action: PayloadAction<string>) => {
      state.end_date = action.payload;
    },
    setUuidGroup: (state, action: PayloadAction<string>) => {
      state.uuidGroup = action.payload;
    },
    setOpenDeleteDialog: (state, action: PayloadAction<boolean>) => {
      state.openDeleteDialog = action.payload;
    },
    setOpenAddGroupCourse: (state, action: PayloadAction<boolean>) => {
      state.openAddGroupCourse = action.payload;
    },
  },
});

export const {
  setListGroupTable,
  setStart_date,
  setEnd_date,
  setOpenDeleteDialog,
  setUuidGroup,
  setOpenAddGroupCourse,
} = listGroupsSlice.actions;
