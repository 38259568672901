import { middlewareApi } from '../../../middleware/middlewareApi';
import { mainApi } from '../../../../api/mainApi';
import { AccessState } from '../../../../types';
import {
  PublicationsResponse,
  Publication,
  PublicationPostRes,
  PublicationDocument,
} from '../interfaces/publications.interfaces';
import { PublicationPayload } from '../interfaces/publicationsPayload.interfaces';

export const PublicationsTags = middlewareApi.enhanceEndpoints({
  addTagTypes: ['Publications'],
});

export const publicationsApi = PublicationsTags.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * @GET investigation lines response
     */
    getPublicationsResearch: builder.query<
      PublicationsResponse,
      {
        profileId?: number;
        page?: number;
        size?: number;
        sort?: string;
        type_sort: string;
        search: string;
      }
    >({
      queryFn: async ({ profileId, page, size, sort, type_sort, search }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = profileId || currentProfile?.profileId;

          const { data } = await mainApi.get<PublicationsResponse>(`/scientific-publication`, {
            params: {
              user_profile_id,
              page: page,
              size: size,
              sort: sort,
              type_sort: type_sort,
              search: search,
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'Publications', id } as const)),
              { type: 'Publications', id: 'LIST' },
            ]
          : [{ type: 'Publications', id: 'LIST' }],
    }),

    /**
     * @POST save document publication information
     */
    savePubDocumentInfo: builder.mutation<any, { document: PublicationDocument }>({
      queryFn: async ({ document }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = currentProfile?.profileId;

          const { data } = await mainApi.post<any>(`/scientific-publication-document`, {
            user_profile_id,
            ...document,
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'Publications', id: 'LIST' }],
    }),

    /**
     * @POST create publication
     */
    addPublication: builder.mutation<
      PublicationPostRes,
      { publicationPayload: PublicationPayload }
    >({
      queryFn: async ({ publicationPayload }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = currentProfile?.profileId;

          const { data } = await mainApi.post<PublicationPostRes>(`/scientific-publication`, {
            user_profile_id,
            ...publicationPayload,
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'Publications', id: 'LIST' }],
    }),

    /**
     * @PUT create publication
     */
    updatePublication: builder.mutation<
      PublicationPostRes,
      { publicationPayload: PublicationPayload; publicationId: string }
    >({
      queryFn: async ({ publicationPayload, publicationId }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = currentProfile?.profileId;

          const { data } = await mainApi.put<PublicationPostRes>(
            `/scientific-publication/${publicationId}`,
            {
              user_profile_id,
              ...publicationPayload,
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'Publications', id: 'LIST' }],
    }),

    /**
     * @DELETE remove publication
     */
    deletePublication: builder.mutation<Publication, { profileId?: number; publicationId: string }>(
      {
        queryFn: async ({ profileId, publicationId }, { getState }) => {
          try {
            const {
              access: { currentProfile },
            } = getState() as { access: AccessState };

            const user_profile_id = profileId || currentProfile?.profileId;

            const { data } = await mainApi.delete(`/scientific-publication/${publicationId}`, {
              data: {
                user_profile_id,
              },
            });

            return { data };
          } catch (error: any) {
            return { error };
          }
        },
        invalidatesTags: [{ type: 'Publications', id: 'LIST' }],
      }
    ),

    /**
     * @DELETE  remove author publication
     */
    deleteAuthorPublication: builder.mutation<
      Publication,
      { profileId?: number; authorId: string }
    >({
      queryFn: async ({ profileId, authorId }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = profileId || currentProfile?.profileId;

          const { data } = await mainApi.delete(`/scientific-publication-author/${authorId}`, {
            data: {
              user_profile_id,
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'Publications', id: 'LIST' }],
    }),

    //
  }),
});

export const {
  useGetPublicationsResearchQuery,
  useAddPublicationMutation,
  useDeletePublicationMutation,
  useUpdatePublicationMutation,
  useDeleteAuthorPublicationMutation,
  useSavePubDocumentInfoMutation,
} = publicationsApi;
