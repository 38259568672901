import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import {
  Avatar,
  Button,
  CardActions,
  CardContent,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core';
import { IconArrowNarrowLeft } from '@tabler/icons';
import { useAppDispatch } from '../../../../../hooks/useRedux';
import { stylesStudentAsignedSendMessage } from '../constants/styles';
import { useGetStudentAnnouncementsHistoryPreviewQuery } from '../slices/studentsAsignedApiSlice';
import {
  resetStudentSelected,
  setOpenModalStudentHistoryAnnouncements,
} from '../slices/studentsAsignedSlice';
import { useAppSelector } from '../../../../../hooks/useRedux';
import { renderSkeletonTable } from '../../../../shared/helpers/render';
import { obtainDomainStorageImage } from '../../../../shared/helpers';
import WatchLaterTwoToneIcon from '@material-ui/icons/WatchLaterTwoTone';
import MainCard from '../../../../shared/components/cards/MainCard';
import ExtendedSideDialog from '../../../../shared/components/dialogs/ExtendedSideDialog';
import { useTranslation } from 'react-i18next';

interface StudentAnnouncement {
  id: number;
  ans_message: string;
  ans_date_send: string;
}

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    '&>*': {
      position: 'relative',
      // zIndex: '5'
    },
    '&:after': {
      position: 'absolute',
      top: '0',
      left: '110px',
      height: '100%',
      // background: '#ebebeb',
    },
  },
  timeIcon: {
    fontSize: '0.875rem',
    marginRight: '2px',
    verticalAlign: 'sub',
  },
}));

const StudentHistoryAnnouncements = function () {
  const { t } = useTranslation();

  const { collaboratorId } = useParams<{ collaboratorId: string }>();

  const history = useHistory();

  // const { path } = useRouteMatch();

  const classes = useStyles();

  const theme = useTheme();

  const styles = stylesStudentAsignedSendMessage();

  const dispatch = useAppDispatch();

  const { studentSelected, studentsList } = useAppSelector((state) => state.studentsAsigned);

  const student = studentsList.find((value) => Number(value.user.user_id) === studentSelected);

  const { currentProfile } = useAppSelector((state) => state.access);

  const { data, isLoading, isFetching } = useGetStudentAnnouncementsHistoryPreviewQuery({
    user_profile_id: currentProfile?.profileId!,
    studentId: studentSelected!,
  });

  const dataFetch = data?.map((value) => ({
    id: value.id,
    ans_message: value.ans_message,
    ans_date_send: value.ans_date_send,
  }));

  const handleClose = () => {
    dispatch(setOpenModalStudentHistoryAnnouncements(false));

    dispatch(resetStudentSelected());
  };

  const handleHistory = () => {
    history.push(
      `/administrar-estudiantes-asignados/${collaboratorId}/historial-anuncios/${studentSelected}`
    );
  };

  const RenderRow = ({ id, ans_date_send, ans_message }: StudentAnnouncement) => {
    return (
      <Grid item xs={12} key={`${id}-${ans_date_send}`}>
        <Grid container spacing={2}>
          <Grid item xs zeroMinWidth>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <Typography align="left" variant="caption">
                  <WatchLaterTwoToneIcon className={classes.timeIcon} />
                  {moment(ans_date_send).fromNow()}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography align="left" variant="body2">
                  {ans_message}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const RenderRows = () => {
    return <>{dataFetch?.slice(0, 6).map(RenderRow)}</>;
  };

  return (
    <ExtendedSideDialog
      open={true}
      keepMounted
      onClose={() => handleClose()}
      className={styles.container}
      classes={{
        paper: styles.paper,
      }}
    >
      <Grid item xs={12} style={{ marginLeft: 7 }}>
        <Grid item container alignItems="center" gap={1} sx={{ cursor: 'pointer' }}>
          <IconButton onClick={() => handleClose()}>
            <IconArrowNarrowLeft size={30} color={theme.palette.text.dark} />
          </IconButton>
          <Typography variant="h3">
            {t('teachers.academic_counseling.students_assigned.history.back')}
          </Typography>
        </Grid>
      </Grid>
      <MainCard title="Anuncios enviados" boxShadow={true}>
        <Grid item xs={12} style={{ marginLeft: 20, marginBottom: 15 }}>
          <Grid container spacing={2}>
            <Grid item>
              <Avatar
                alt={student?.user?.person?.pers_first_name!}
                src={obtainDomainStorageImage(student?.stud_photo_path!)}
              />
            </Grid>
            <Grid item xs zeroMinWidth>
              <Typography align="left" component="div" variant="h3">
                {student?.user?.person?.pers_first_name}{' '}
                {student?.user?.person?.pers_first_lastname}
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs zeroMinWidth>
                  <Typography align="left" component="div" variant="subtitle2">
                    {student?.user?.email}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <CardContent>
          <Grid container spacing={3} alignItems="center" className={classes.container}>
            {isLoading || isFetching ? renderSkeletonTable(6, 6) : RenderRows()}
          </Grid>
        </CardContent>
      </MainCard>
      <Divider />
      <CardActions sx={{ justifyContent: 'flex-end' }}>
        <Button
          style={{ textTransform: 'unset' }}
          onClick={handleHistory}
          variant="text"
          size="small"
          color="primary"
        >
          {t('teachers.academic_counseling.students_assigned.history.view_history')}
        </Button>
      </CardActions>
    </ExtendedSideDialog>
  );
};

export default StudentHistoryAnnouncements;
