import { Box, Typography, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { VisibilityOffOutlined } from '@material-ui/icons';

interface Props {
  type: 'good' | 'regular' | 'bad' | 'procesing';
  calification: string;
  calificationBase: string;
  setColor: (type: string) => any;
  hideNote?: boolean;
}

const CardNote = ({ type, calification, calificationBase, setColor, hideNote = false }: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const getTitleAndDescription = () => {
    if (type === 'bad') {
      return {
        title: t('studentVirtualClassroom.test.card.title.bad'),
        description: t('studentVirtualClassroom.test.card.description.bad'),
      };
    } else if (type === 'regular') {
      return {
        title: t('studentVirtualClassroom.test.card.title.regular'),
        description: t('studentVirtualClassroom.test.card.description.regular'),
      };
    } else if (type === 'good') {
      return {
        title: t('studentVirtualClassroom.test.card.title.good'),
        description: t('studentVirtualClassroom.test.card.description.good'),
      };
    }
    return {
      title: t('studentVirtualClassroom.test.card.title.pending'),
      description: t('studentVirtualClassroom.test.card.description.pending'),
    };
  };
  const { title, description } = getTitleAndDescription();
  return (
    <Box
      sx={{
        borderRadius: '12px',
        width: '300px',
        boxShadow: '-4px 4px 18px 0px rgba(0,0,0,0.75);',
      }}
    >
      <Box
        sx={{
          borderRadius: '12px 12px 0 0',
          color: '#fff',
          backgroundColor: setColor(type),
          px: 3,
          py: 2,
        }}
      >
        <Typography
          sx={{
            fontSize: '22px',
            textAlign: 'center',
            fontWeight: 'bold',
          }}
        >
          {!hideNote ? title : t('studentVirtualClassroom.test.card.title.hideNote')}
        </Typography>
      </Box>
      <Box>
        <Typography
          sx={{
            fontSize: '54px',
            color: setColor(type),
            px: 4,
            py: 6,
            fontWeight: 'bold',
            textAlign: 'center',
          }}
        >
          {!hideNote ? (
            `${calification}/${Number(calificationBase)}`
          ) : (
            <VisibilityOffOutlined
              sx={{
                fontSize: '64px',
              }}
            />
          )}
        </Typography>
      </Box>
      {description && (
        <Box
          sx={{
            px: 1,
            pb: 3,
          }}
        >
          <Typography
            sx={{
              textAlign: 'center',
              color: theme.palette.primary.dark,
              fontSize: '16px',
            }}
          >
            {!hideNote ? description : t('studentVirtualClassroom.test.card.description.hideNote')}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default CardNote;
