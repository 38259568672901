import { Redirect, Switch } from 'react-router-dom';
import { useAppSelector } from '../../../../hooks/useRedux';
import GuardRoute from '../../../../router/guards/GuardRoute';
import { permissionKey, subPermissionKeys } from '../constants/permissions';
import AddPosgraduatePlanningHourhandsScreen from '../screens/AddPosgraduatePlanningHourhandsScreen';
import EditPosgraduatePlanningHourhandsScreen from '../screens/EditPosgraduatePlanningHourhandsScreen';
import PosgraduatePlanningHourhandsScreen from '../screens/PosgraduatePlanningDayTypesScreen';

const PlanningPosgraduateHourhandsRouter = () => {
  const { currentModule } = useAppSelector((state) => state.access);

  const hasPermission = permissionKey in currentModule!.permissions;

  if (!hasPermission) return <Redirect to="/dashboard" />;

  return (
    <Switch>
      <GuardRoute
        exact
        path="/planeacion-tipos-dia-posgrado"
        component={PosgraduatePlanningHourhandsScreen}
        subPermissionKey={subPermissionKeys.read}
        permissionKey={permissionKey}
      />
      <GuardRoute
        exact
        path="/planeacion-tipos-dia-posgrado/agregar"
        component={AddPosgraduatePlanningHourhandsScreen}
        subPermissionKey={subPermissionKeys.read}
        permissionKey={permissionKey}
      />
      <GuardRoute
        exact
        path="/planeacion-tipos-dia-posgrado/:id/editar"
        component={EditPosgraduatePlanningHourhandsScreen}
        subPermissionKey={subPermissionKeys.read}
        permissionKey={permissionKey}
      />
    </Switch>
  );
};

export default PlanningPosgraduateHourhandsRouter;
