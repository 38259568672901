import { Status } from '../../../shared/components/badges/OmniChip';

export const statusList: Status[] = [
  { id: 0, name: 'Rechazado', color: 'orange' },
  { id: 1, name: 'Enviado', color: 'disabled' },
  { id: 2, name: 'Revisado', color: 'secondary' },
  { id: 3, name: 'Registrado', color: 'success' },
  { id: 4, name: 'Aprobado', color: 'success' },
];

export const studyStatusList: { label: string; value: string }[] = [
  { label: 'En Curso', value: 'En Curso' },
  { label: 'Finalizado', value: 'Finalizado' },
];
