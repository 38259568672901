//import axios from 'axios';
import { mainApi } from '../../../../api/mainApi';
import { AccessState } from '../../../../types';
import { middlewareApi } from '../../../middleware/middlewareApi';
import {
  Certificate,
  CertificatePayload,
  CertificateQueryResponse,
  //FirstCertificate,
} from '../interfaces/certificate.interfaces';
import { PaidCertificateResponse } from '../interfaces/paidCertificates.interface';
import { PeriodStudentRecord } from '../interfaces/period.interfaces';
import { setCertificateList } from './certificateSlice';

export const certificateApi = middlewareApi.injectEndpoints({
  //tagTypes: ['','',''],
  endpoints: (builder) => ({
    /**
     * @GET all certificate types
     */
    getCertificateTypes: builder.query<Certificate[], { profileId: number }>({
      queryFn: async ({ profileId }, { dispatch }) => {
        try {
          const { data } = await mainApi.get<Certificate[]>(`/certificates/types`, {
            params: {
              user_profile_id: profileId,
            },
          });

          dispatch(setCertificateList(data));
          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          console.log(error);
          return { error };
        }
      },
    }),

    /**
     * @GET a student record certificate
     */
    getStudentRecordCertificate: builder.query<
      CertificateQueryResponse,
      {
        profileId: number;
        userId: string;
        studentRecordId: number;
        appId: number;
        lang: string;
      }
    >({
      queryFn: async ({ profileId, userId, studentRecordId, appId, lang }) => {
        try {
          const { data } = await mainApi.get<CertificateQueryResponse>(
            `/certificates/academic-record/preview`,
            {
              params: {
                user_profile_id: profileId,
                user_id: userId,
                student_record_id: studentRecordId,
                app_id: appId,
                lang: lang,
              },
            }
          );
          return { data };
        } catch (error: any) {
          console.log(error);
          return { error };
        }
      },
    }),

    /**
     * @GET a study certificate
     */
    getStudyCertificate: builder.query<
      CertificateQueryResponse,
      {
        profileId: number;
        userId: string;
        studentRecordId: number;
        appId: number;
        period: string;
      }
    >({
      queryFn: async ({ profileId, userId, studentRecordId, appId, period }) => {
        try {
          const { data } = await mainApi.get<CertificateQueryResponse>(
            `/certificates/study/preview`,
            {
              params: {
                user_profile_id: profileId,
                user_id: userId,
                student_record_id: studentRecordId,
                app_id: appId,
                period: period,
              },
            }
          );
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    /**
     * @GET a conduct third registration or Subject register certificate
     */
    getShareCertificate: builder.query<
      CertificateQueryResponse,
      {
        profileId: number;
        userId: string;
        studentRecordId: number;
        appId: number;
        type: 1 | 2;
      }
    >({
      queryFn: async ({ profileId, userId, studentRecordId, appId, type }) => {
        try {
          const types = {
            1: 'good-behavior',
            2: 'approved-subjects',
          };

          const { data } = await mainApi.get<CertificateQueryResponse>(
            `/certificates/${types[type]}/preview`,
            {
              params: {
                user_profile_id: profileId,
                user_id: userId,
                student_record_id: studentRecordId,
                app_id: appId,
              },
            }
          );
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    /**
     * @GET a professional practice certificate
     */
    getProfessionalPracticeCertificate: builder.query<
      CertificateQueryResponse,
      {
        profileId: number;
        userId: string;
        studentRecordId: number;
        appId: number;
        degree: string;
        name: string;
        position: string;
        work_location: string;
        city: string;
      }
    >({
      queryFn: async ({
        profileId,
        userId,
        studentRecordId,
        appId,
        degree,
        name,
        position,
        work_location,
        city,
      }) => {
        try {
          const { data } = await mainApi.get<CertificateQueryResponse>(
            `/certificates/practical-training/preview`,
            {
              params: {
                user_profile_id: profileId,
                user_id: userId,
                student_record_id: studentRecordId,
                app_id: appId,
                practices: [degree, name, position, work_location, city],
              },
            }
          );
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    /**
     * @GET a period by  student record
     */
    getPeriodByStudentRecord: builder.query<
      PeriodStudentRecord[],
      {
        profileId: number;
        studentRecordId: number;
      }
    >({
      queryFn: async ({ profileId, studentRecordId }) => {
        try {
          const { data } = await mainApi.get<PeriodStudentRecord[]>(
            `/certificates/student-record/${studentRecordId}/periods`,
            {
              params: {
                user_profile_id: profileId,
                type_sort: 'DESC',
              },
            }
          );
          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    /**
     * @GET a credit certificate
     */
    getCreditCertificate: builder.query<
      CertificateQueryResponse,
      {
        profileId: number;
        userId: string;
        studentRecordId: number;
        appId: number;
        to: string;
        period_id: number;
      }
    >({
      queryFn: async ({ profileId, userId, studentRecordId, appId, to, period_id }) => {
        try {
          const { data } = await mainApi.get<CertificateQueryResponse>(
            `/certificates/school-grant/preview`,
            {
              params: {
                user_profile_id: profileId,
                user_id: userId,
                student_record_id: studentRecordId,
                app_id: appId,
                to: to,
                period_id: period_id,
              },
            }
          );
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    /**
     * @GET a consulate certificate
     */
    getConsulateCertificate: builder.query<
      CertificateQueryResponse,
      {
        profileId: number;
        userId: string;
        studentRecordId: number;
        appId: number;
        consul: string;
      }
    >({
      queryFn: async ({ profileId, userId, studentRecordId, appId, consul }) => {
        try {
          const { data } = await mainApi.get<CertificateQueryResponse>(
            `/certificates/consulate/preview`,
            {
              params: {
                user_profile_id: profileId,
                user_id: userId,
                student_record_id: studentRecordId,
                app_id: appId,
                consul: consul,
              },
            }
          );
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    /**
     * @GET a pdf as arraybuffer
     */
    getPdfArrayBuffer: builder.query<
      any,
      {
        profileId: number;
        period_id?: number;
        url: string;
      }
    >({
      queryFn: async ({ profileId, period_id, url }) => {
        try {
          const { data } = await mainApi.get(
            `${url?.includes('/api/') ? url.split('/api/')[1] : '/' + url}`,
            {
              responseType: 'arraybuffer',
              headers: {
                'Content-Type': 'application/json',
                Accept: 'application/pdf',
              },
              params: {
                user_profile_id: profileId,
                ...(period_id && { period_id }),
              },
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    /**
     * @POST save first certificate
     */
    addCertificate: builder.mutation<
      string,
      {
        profileId: number;
        appId: number;
        studentRecordId: number;
        pdfUrl: string;
        certificatePayload: CertificatePayload;
      }
    >({
      queryFn: async ({ profileId, appId, studentRecordId, pdfUrl, certificatePayload }) => {
        try {
          const { data } = await mainApi.post<string>('/certificates', {
            user_profile_id: profileId,
            user_id: 1,
            app_id: appId,
            student_record_id: studentRecordId,
            pdf_url: pdfUrl,
            ...certificatePayload,
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    /**
     * @POST save n certificate
     */
    addCertificateDebit: builder.mutation<
      string,
      {
        profileId: number;
        studentRecordId: number;
        appId: number;
        pdfUrl: string;
        app_acronym: string;
      }
    >({
      queryFn: async ({ profileId, appId, pdfUrl, studentRecordId, app_acronym }) => {
        try {
          const { data } = await mainApi.post<string>('/certificates', {
            user_profile_id: profileId,
            student_record_id: studentRecordId,
            app_id: appId,
            pdf_url: pdfUrl,
            accept_debt: 1,
            app_acronym,
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    /**
     *  @GET get paid history certificates
     */
    getPaidCertificates: builder.query<
      PaidCertificateResponse,
      { userId: number; page: number; perPage: number; search: string }
    >({
      queryFn: async ({ userId, page, search, perPage }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = currentProfile?.profileId!;

          const { data } = await mainApi.get<PaidCertificateResponse>(
            `/user/${userId}/certificates/paid`,
            {
              params: {
                user_profile_id,
                page,
                size: perPage,
                type_sort: 'desc',
                search,
              },
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    /**
     *  @GET get paid history certificates to download
     */
    getDownloadPaidCertificates: builder.query<any, { certificateId: number }>({
      queryFn: async ({ certificateId }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = currentProfile?.profileId!;

          const { data } = await mainApi.get<any>(
            `/certificates/pdf/redrawcertificates/${certificateId}`,
            {
              responseType: 'arraybuffer',
              headers: {
                Accept: 'application/pdf',
                'Content-Type': 'application/pdf',
              },
              params: {
                user_profile_id,
              },
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),
  }),
});
export const {
  useGetCertificateTypesQuery,
  useGetStudentRecordCertificateQuery,
  useGetStudyCertificateQuery,
  useGetShareCertificateQuery,
  useGetProfessionalPracticeCertificateQuery,
  useGetPeriodByStudentRecordQuery,
  useGetCreditCertificateQuery,
  useGetConsulateCertificateQuery,
  useGetPdfArrayBufferQuery,
  useAddCertificateMutation,
  useAddCertificateDebitMutation,

  useGetPaidCertificatesQuery,
  useGetDownloadPaidCertificatesQuery,
  useLazyGetDownloadPaidCertificatesQuery,
} = certificateApi;
