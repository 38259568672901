import { useState, useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import {
  // Autocomplete,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  TextField,
  useTheme,
  Typography,
  List,
  ListItem,
  ListItemText,
  Skeleton,
  makeStyles,
} from '@material-ui/core';

import { useFormik } from 'formik';

import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import InputLabel from '../../../shared/components/forms/InputLabel';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';

import {
  useChangeStatusMutation,
  useGetAvailablesTypeEducationsQuery,
  useGetRequisitionCoursesQuery,
} from '../slices/requisitionsApiSlice';

import { ChangeStatusSchema } from '../constants/validation-schemas';
import {
  useGetAcademicOfferingQuery,
  useGetPeriodAcademicOfferingQuery,
} from '../../academic-offerings/slices/academicOfferingsApiSlice';

import OmniChip, { Status } from '../../../shared/components/badges/OmniChip';
import { statusList } from '../constants/statusRequisition';
import { useGetRequisitionByIdQuery } from '../slices/requisitionsApiSlice';
import SimpleTable from '../../../shared/components/tables/SimpleTable';

import { Period } from '../../academic-offerings/interfaces/academicOfferings.interfaces';
import { IconButton, Tooltip } from '@material-ui/core';
import { IconEye, IconNotes } from '@tabler/icons';
import { useTranslation } from 'react-i18next';

// Required data for tables

import CommentsList from './CommentsList';
// import { useGetHourhandsByPeriodQuery } from '../../periods/slices/periodsApiSlice';
import { useGetAvailableInstitutesQuery } from '../../institutes/slices/institutesApiSlice';
import { setObservationDialog } from '../slices/requisitionsSlice';
import { useGetHourhandQuery } from '../../hourhands/slices/hourhandsApiSlice';
import useFormatDate from '../../../shared/hooks/useFormatDate';
// import { useGetCatalogByAcronymQuery } from '../../../shared/slices/catalogApi';
// import { statusAvailables } from '../../../shared/constants/resourceStatus';

const tipoContrato = [
  {
    label: 'TC',
    name: 'Tiempo completo',
    translateId: 'academic.requisition.edit.wizard.step3.contract_type.full_time',
  },
  {
    label: 'MT',
    name: 'Medio tiempo',
    translateId: 'academic.requisition.edit.wizard.step3.contract_type.half_time',
  },
  {
    label: 'P',
    name: 'Parcial',
    translateId: 'academic.requisition.edit.wizard.step3.contract_type.partial',
  },
];

const useStyles = makeStyles((theme) => ({
  title: {
    width: '30%',
    wordBreak: 'break-word',
  },
  description: {
    wordBreak: 'break-word',
    width: '65%',
  },
}));

const EditRequisitionForm = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const classes = useStyles();

  const history = useHistory();

  const { code } = useParams<{ code: string }>();

  const theme = useTheme();

  const { formatDate } = useFormatDate();

  const { currentProfile, currentRol } = useAppSelector((state) => state.access);

  const profileId = currentProfile?.profileId!;

  const { requisitionList } = useAppSelector((state) => state.requisitionTeacher);

  const [showAlerts, setShowAlerts] = useState(true);

  const [statusRequisition, setStatusRequisition] = useState<number>();

  const [changeStatus, { isLoading, isError, error, isSuccess }] = useChangeStatusMutation();

  const requisitionInfo = requisitionList.filter((e) => e.id === Number(code));

  const { data: requisitionData, isLoading: isLoadingRequisitionData } = useGetRequisitionByIdQuery(
    {
      profileId,
      id: code,
    }
  );

  /// Data Available

  const { isLoading: isLoadingInfoOffer, data: availableInfoOffer } = useGetAcademicOfferingQuery(
    {
      profileId,
      academicOfferingId: requisitionData?.applicationDetails[0].value.offer_id || 0,
    },
    { skip: !requisitionData?.applicationDetails[0].value.offer_id }
  );

  // Required data for tables

  const { isLoading: isLoadingSubject, data: availableSubjects } = useGetRequisitionCoursesQuery({
    profileId,
    appCode: code,
  });

  const { data: availablesPeriodsByOffer = [], isLoading: isLoadingPeriodsByOffer } =
    useGetPeriodAcademicOfferingQuery(
      {
        profileId,
        academicOfferingId: requisitionData?.applicationDetails[0].value.offer_id.toString() || '',
        current: true,
      },
      { skip: !requisitionData?.applicationDetails[0].value.offer_id }
    );

  const { data: hourhandData, isLoading: isLoadingH } = useGetHourhandQuery(
    {
      profileId,
      hourhandId: Number(requisitionData?.applicationDetails[0]?.value?.hourhand_id || 0),
    },
    {
      skip:
        !requisitionData?.applicationDetails[0]?.value?.hourhand_id ||
        requisitionData?.applicationDetails[0]?.value?.hourhand_id === '1',
    }
  );

  // const { data: availablesCities = [], isLoading: isLoadingCities } = useGetCatalogByAcronymQuery({
  //   profileId,
  //   acronym: 'C',
  // });

  const { data: availablesInstitutes = [], isLoading: isLoadingInstitutes } =
    useGetAvailableInstitutesQuery({
      profileId,
    });

  const { data: availablesTypeEducations = [], isLoading: isLoadingTypeEducations } =
    useGetAvailablesTypeEducationsQuery({
      profileId,
    });

  const estado = requisitionData?.current_status.toLowerCase();

  const [buttonName, seButtonName] = useState('Revisar');
  const [statusObj, setStatusObject] = useState<Status>();

  const statusListMemo = useMemo(() => statusList, []);

  const [onApprove, setOnApprove] = useState<number>(0);

  const subjectsList = availableSubjects?.map((value) => ({
    matterId: value.matter.mat_name,
    numParalels: value.parallel.par_name,
    sede: value.classroom.campus.cam_name,
    hPlanificadas: value.horas_planificadas,
  }));

  const academicFormationList = requisitionData?.applicationDetails[3].value.map((value) => ({
    // certificateTitle: value.certificate_title,
    // cityId: value.city_name,
    // documentUrl: value.document_url,
    // educationCalification: value.education_calification,
    // educationObservation: value.education_observation,
    // graduationDate: value.graduation_date,
    // instituteId: value.institute_id,
    // statusId: value.status_id,
    // typeEducation_id: value.type_education_id,
    //
    academicInformation: {
      city: value.city_name,
      institute: value.institute_id,
      title: value.certificate_title,
    },
    eduType: value.type_education_id,
    graduation: {
      calification: value.education_calification,
      date: value.graduation_date as unknown as string,
    },
    options: {
      observation: value.education_observation,
      documentUrl: value.document_url,
    },
  }));

  const laboralExperience = requisitionData?.applicationDetails[4].value.map((value) => ({
    // perJobOrganization: value.per_job_organization,
    // perJobPosition: value.per_job_position,
    // perJobPhone: value.per_job_phone,
    // perJobStartDate: value.per_job_start_date,
    // perJobCurrent: value.per_job_current,
    // perJobIessAffiliated: value.per_job_iess_affiliated,
    // documentUrl: value.document_url,
    // cityId: value.city_name,
    // statusId: value.status_id,

    // empresa/cargo
    jobInformation: {
      city: value.city_name,
      organization: value.per_job_organization,
      position: value.per_job_position,
      phone: value.per_job_phone,
    },
    jobStartDate: value.per_job_start_date as unknown as string,
    jobCurrent: value.per_job_current,
    jobIessAffiliated: value.per_job_iess_affiliated,
    options: {
      documentUrl: value.document_url,
    },

    // fecha inicio
  }));

  // const { data: hourhandData, isLoading: isLoadingHourhand } = useGetHourhandsByPeriodQuery(
  //   {
  //     profileId,
  //     periodId: requisitionData?.applicationDetails[0]?.value?.new_period_id!,
  //   },
  //   {
  //     skip: !requisitionData?.applicationDetails[0]?.value?.new_period_id,
  //   }
  // );

  useEffect(() => {
    switch (estado) {
      case 'enviado':
        seButtonName(t('academic.requisition.review.btn_review'));
        setStatusObject(statusListMemo[1 + onApprove]);
        break;
      case 'revisado':
        seButtonName(t('academic.requisition.review.btn_register'));
        setStatusObject(statusListMemo[2 + onApprove]);
        break;
      case 'registrado':
        seButtonName(t('academic.requisition.review.btn_approve'));
        setStatusObject(statusListMemo[3 + onApprove]);
        break;
      case 'aprobado':
        seButtonName(t('academic.requisition.review.btn_approved'));
        setStatusObject(statusListMemo[4]);
        break;

      case 'rechazado':
        // seButtonName('Ya Registrado');
        setStatusObject(statusListMemo[0]);
        break;

      default:
        break;
    }
  }, [requisitionInfo, estado, statusListMemo, onApprove, t]);

  const { getFieldProps, errors, touched, handleSubmit } = useFormik({
    initialValues: {
      comment: '',
    },
    validationSchema: ChangeStatusSchema,
    onSubmit: async (values) => {
      await changeStatus({
        profileId: currentProfile?.profileId!,
        changeStatusPayload: {
          app_code: requisitionData?.app_code!,
          comment: values.comment,
          refused: statusRequisition!,
          role_id: currentRol?.rolId,
        },
      });

      if (statusRequisition === 0) {
        setOnApprove(onApprove + 1);
      }

      if (statusRequisition === 1) {
        setOnApprove(-statusObj?.id!);
      }

      setShowAlerts(true);
    },
  });

  return (
    <>
      {isLoadingRequisitionData ? (
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Skeleton variant="rectangular" width="100%">
              <Grid style={{ paddingTop: '70%' }} />
            </Skeleton>
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="rectangular" width="100%">
              <Grid style={{ paddingTop: '20%' }} />
            </Skeleton>
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="rectangular" width="100%">
              <Grid style={{ paddingTop: '20%' }} />
            </Skeleton>
          </Grid>
        </Grid>
      ) : (
        <>
          <Grid item container xs={12} md={8} direction="column" gap={2} marginTop={4}>
            <form
              onSubmit={handleSubmit}
              style={{
                width: '100%',
              }}
            >
              <Grid item>
                {isError && showAlerts && (
                  <ErrorAlert
                    message={error as string}
                    handleDismiss={() => setShowAlerts(false)}
                  />
                )}

                {isSuccess && showAlerts && (
                  <SuccessAlert
                    message={t('academic.requisition.review.success')}
                    handleDismiss={() => setShowAlerts(false)}
                  />
                )}
              </Grid>
              <Grid item container xs={12}>
                <Grid item xs={12} md={6}>
                  <List
                    disablePadding
                    sx={{
                      gap: '5%',
                    }}
                  >
                    <ListItem>
                      <ListItemText
                        className={classes.title}
                        primary={t('academic.requisition.edit.wizard.step1.name')}
                      />
                      <Typography variant="subtitle1" className={classes.description}>
                        {requisitionData?.person?.pers_full_name}
                      </Typography>
                    </ListItem>
                    {/* <ListItem>
                    <ListItemText className={classes.title} primary={requisitionData?.} />
                    <Typography variant="subtitle1" className={classes.description}>{identification.id}</Typography>
                  </ListItem> */}
                    <ListItem>
                      <ListItemText
                        className={classes.title}
                        primary={t('academic.requisition.review.code')}
                      />
                      <Typography variant="subtitle1" className={classes.description}>
                        {requisitionData?.app_code}
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        className={classes.title}
                        primary={t('academic.requisition.review.status')}
                      />
                      <Typography variant="subtitle1" className={classes.description}>
                        <OmniChip id={statusObj?.id!} statusArray={statusList} variant={1} />
                        {/* {requisitionInfo[0]?.current_status!} */}
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        className={classes.title}
                        primary={t('academic.requisition.review.register_date')}
                      />
                      <Typography variant="subtitle1" className={classes.description}>
                        {requisitionData?.app_register_date}
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        className={classes.title}
                        primary={t('academic.requisition.review.type')}
                      />
                      <Typography variant="subtitle1" className={classes.description}>
                        {requisitionData?.type_application}
                      </Typography>
                    </ListItem>
                  </List>

                  <List disablePadding>
                    <ListItem>
                      <ListItemText
                        className={classes.title}
                        primary={t('academic.requisition.review.salary')}
                      />
                      <Typography variant="subtitle1" className={classes.description}>
                        ${requisitionData?.applicationDetails[1].value.salary || ''}
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        className={classes.title}
                        primary={t('academic.requisition.review.dedication_type')}
                      />
                      <Typography variant="subtitle1" className={classes.description}>
                        {t(
                          tipoContrato.find(
                            (e) =>
                              e.label ===
                              requisitionData?.applicationDetails[1].value.tipo_dedicacion
                          )?.translateId! || ''
                        )}
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        className={classes.title}
                        primary={t('academic.requisition.review.vinculation_type')}
                      />
                      <Typography variant="subtitle1" className={classes.description}>
                        {requisitionData?.applicationDetails[1].value.tipo_vinculacion === '0'
                          ? t('academic.requisition.edit.wizard.step3.vinc_type.prof_service')
                          : t('academic.requisition.edit.wizard.step3.vinc_type.dep_service')}
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        className={classes.title}
                        primary={t('academic.requisition.review.contract_type')}
                      />
                      <Typography variant="subtitle1" className={classes.description}>
                        {requisitionData?.applicationDetails[1].value.type_contract === 'pv'
                          ? t('academic.requisition.edit.wizard.step3.type.first_time')
                          : t('academic.requisition.edit.wizard.step3.type.re_entry')}
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        className={classes.title}
                        primary={t('academic.requisition.review.payment_type')}
                      />
                      <Typography variant="subtitle1" className={classes.description}>
                        {requisitionData?.applicationDetails[1].value.type_payment === 's'
                          ? t('academic.requisition.edit.wizard.step3.payment.salary')
                          : t('academic.requisition.edit.wizard.step3.payment.hour')}
                      </Typography>
                    </ListItem>
                  </List>
                </Grid>

                {/* Segunda Columna  */}
                <Grid item xs={12} md={6}>
                  <List disablePadding>
                    <ListItem>
                      <ListItemText
                        className={classes.title}
                        primary={t('academic.requisition.review.user')}
                      />
                      <Typography variant="subtitle1" className={classes.description}>
                        {/* {isLoadingPerson
                        ? '...'
                        : `${personData?.pers_firstname} ${personData?.pers_secondname} ${personData?.pers_first_lastname} ${personData?.pers_second_lastname}`} */}
                        {requisitionData?.applicationDetails.user.us_username}
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        className={classes.title}
                        primary={t('academic.requisition.review.email')}
                      />
                      <Typography variant="subtitle1" className={classes.description}>
                        {requisitionData?.applicationDetails.user.email || ''}
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        className={classes.title}
                        primary={t('academic.requisition.review.offer')}
                      />
                      <Typography variant="subtitle1" className={classes.description}>
                        {isLoadingInfoOffer ? (
                          <Skeleton sx={{ width: '70px' }} />
                        ) : (
                          availableInfoOffer?.off_name
                        )}
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        className={classes.title}
                        primary={t('academic.requisition.review.academic_unit')}
                      />
                      <Typography variant="subtitle1" className={classes.description}>
                        {isLoadingInfoOffer ? (
                          <Skeleton sx={{ width: '70px' }} />
                        ) : (
                          availableInfoOffer?.education_levels.find(
                            (e) =>
                              e.id ===
                              requisitionData?.applicationDetails[0].value.education_level_id
                          )?.edu_name || ''
                        )}
                        {/* {(requisitionInfo[0]?.applicationDetails[1]?.value as PurpleValue)
                    ?.education_level_id || ''} */}
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        className={classes.title}
                        primary={t('academic.requisition.review.new_period')}
                      />
                      <Typography variant="subtitle1" className={classes.description}>
                        {isLoadingPeriodsByOffer ? (
                          <Skeleton sx={{ width: '70px' }} />
                        ) : (
                          (availablesPeriodsByOffer as unknown as Period[])?.find(
                            (e) =>
                              e.id === requisitionData?.applicationDetails[0].value.new_period_id
                          )?.per_name ||
                          requisitionData?.applicationDetails[0].value.new_period?.per_name ||
                          ''
                        )}
                        {/* {(requisitionInfo[0]?.applicationDetails[1]?.value as PurpleValue)
                    ?.new_period_id || ''} */}
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        className={classes.title}
                        primary={t('academic.requisition.review.prev_period')}
                      />
                      <Typography variant="subtitle1" className={classes.description}>
                        {isLoadingPeriodsByOffer ? (
                          <Skeleton sx={{ width: '70px' }} />
                        ) : (
                          (availablesPeriodsByOffer as unknown as Period[])?.find(
                            (e) =>
                              e.id === requisitionData?.applicationDetails[0].value.before_period_id
                          )?.per_name ||
                          requisitionData?.applicationDetails[0].value.before_period?.per_name ||
                          ''
                        )}
                        {/* {(requisitionInfo[0]?.applicationDetails[1]?.value as PurpleValue)
                    ?.before_period_id || ''} */}
                      </Typography>
                    </ListItem>
                    {requisitionData?.applicationDetails[0].value.hourhand_id !== '1' && (
                      <ListItem>
                        <ListItemText
                          className={classes.title}
                          primary={t('academic.requisition.review.schedule')}
                        />
                        {isLoadingH ? (
                          <Skeleton sx={{ width: '70px' }} />
                        ) : (
                          <Typography variant="subtitle1" className={classes.description}>
                            {hourhandData?.hour_description || ''}
                          </Typography>
                        )}
                      </ListItem>
                    )}
                  </List>
                </Grid>

                <Grid item xs={12} md={6}>
                  <List>
                    <ListItem>
                      <ListItemText
                        className={classes.title}
                        primary={t('academic.requisition.review.description')}
                      />
                      <Typography variant="subtitle1" className={classes.description}>
                        {requisitionInfo[0]?.app_description! || ''}
                      </Typography>
                    </ListItem>
                  </List>
                </Grid>

                {/* Lista Materias */}
                <Grid item xs={12}>
                  <SimpleTable
                    title="Materias"
                    data={subjectsList || []}
                    isLoading={isLoadingSubject}
                    headers={{
                      matterId: t('academic.requisition.review.subject'),
                      numParalels: t('academic.requisition.review.parallels'),
                      sede: t('academic.requisition.review.campus'),
                      hPlanificadas: t('academic.requisition.review.hours'),
                    }}
                    keyExtractor={(item) => item.matterId?.toString() || '1'}
                  />
                </Grid>

                {/* Lista Formación Académica */}
                {!!academicFormationList?.length && (
                  <Grid item xs={12} marginTop={2}>
                    <SimpleTable
                      title="Formación Académica"
                      data={academicFormationList || []}
                      headers={{
                        academicInformation: t('academic.requisition.review.academic_form.title'),
                        eduType: t('academic.requisition.review.academic_form.edu_type'),
                        graduation: t('academic.requisition.review.academic_form.grade'),
                        options: t('academic.requisition.review.academic_form.options'),
                      }}
                      keyExtractor={(item) => item.options.documentUrl?.toString() || '1'}
                      customRenderers={{
                        academicInformation: (item) => (
                          <>
                            {isLoadingInstitutes && t('academic.requisition.review.loading')}
                            {!isLoadingInstitutes && (
                              <>
                                <Typography>{item.academicInformation?.city || ''}</Typography>
                                <Typography>
                                  {availablesInstitutes?.find(
                                    (e) => e.id === item.academicInformation?.institute
                                  )?.inst_name || ''}
                                </Typography>
                                <Typography>{item.academicInformation?.title || ''}</Typography>
                              </>
                            )}
                          </>
                        ),
                        eduType: (item) => (
                          <>
                            {isLoadingTypeEducations && t('academic.requisition.review.loading')}

                            {!isLoadingTypeEducations && (
                              <Typography textAlign="center">
                                {availablesTypeEducations?.find((e) => e.id === item.eduType)
                                  ?.typ_edu_name || ''}
                              </Typography>
                            )}
                          </>
                        ),
                        graduation: (item) => (
                          <>
                            <Typography>
                              Calificación: <b>{item.graduation?.calification || ''}</b>
                            </Typography>
                            <Typography>Fecha: {formatDate(item.graduation?.date)},</Typography>
                          </>
                        ),
                        options: (item) => (
                          <Grid container justifyContent="center" spacing={1}>
                            <Grid item>
                              <a href={item.options.documentUrl} target="_blank" rel="noreferrer">
                                <Tooltip
                                  title={t('academic.requisition.review.academic_form.see_doc')}
                                >
                                  <IconButton aria-label={'documento'} color="default">
                                    <IconNotes />
                                  </IconButton>
                                </Tooltip>
                              </a>
                            </Grid>

                            <Grid item>
                              <Tooltip
                                title={t(
                                  'academic.requisition.review.academic_form.view_observation'
                                )}
                              >
                                <IconButton
                                  onClick={() =>
                                    dispatch(
                                      setObservationDialog({
                                        open: true,
                                        message: item.options.observation,
                                        title: 'Observación',
                                      })
                                    )
                                  }
                                >
                                  <IconEye />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                          </Grid>
                        ),
                      }}
                    />
                  </Grid>
                )}

                {/* Lista Experiencia Laboral */}
                {!!laboralExperience?.length && (
                  <Grid item xs={12} marginTop={2}>
                    <SimpleTable
                      title="Experiencia Laboral"
                      data={laboralExperience || []}
                      headers={{
                        jobInformation: 'Información del trabajo',
                        jobStartDate: t(
                          'academic.requisition.review.laboral_experience.start_date'
                        ),
                        jobCurrent: t(
                          'academic.requisition.review.laboral_experience.current_work'
                        ),
                        jobIessAffiliated: t(
                          'academic.requisition.review.laboral_experience.affiliated'
                        ),
                        options: t('academic.requisition.review.academic_form.options'),
                      }}
                      keyExtractor={(item) => item.options.documentUrl?.toString() || '1'}
                      customRenderers={{
                        jobInformation: (item) => (
                          <>
                            <Typography>{item.jobInformation?.city || ''}</Typography>
                            <Typography>{item.jobInformation?.organization || ''}</Typography>
                            <Typography>{item.jobInformation?.position || ''}</Typography>
                            <Typography>{item.jobInformation?.phone || ''}</Typography>
                          </>
                        ),
                        jobStartDate: (item) => (
                          <Typography textAlign="center">
                            {formatDate(item.jobStartDate)}
                          </Typography>
                        ),
                        jobCurrent: (item) => (
                          <Typography textAlign="center">
                            {Number(item.jobCurrent) ? 'Si' : 'No'}
                          </Typography>
                        ),
                        jobIessAffiliated: (item) => (
                          <Typography textAlign="center">
                            {Number(item.jobIessAffiliated) ? 'Si' : 'No'}
                          </Typography>
                        ),
                        options: (item) => (
                          <Grid container justifyContent="center" spacing={1}>
                            <Grid item>
                              <a href={item.options.documentUrl} target="_blank" rel="noreferrer">
                                <Tooltip
                                  title={t(
                                    'academic.requisition.review.laboral_experience.see_doc'
                                  )}
                                >
                                  <IconButton aria-label={'documento'} color="default">
                                    <IconNotes />
                                  </IconButton>
                                </Tooltip>
                              </a>
                            </Grid>
                          </Grid>
                        ),
                      }}
                    />
                  </Grid>
                )}
              </Grid>

              <Grid item container spacing={2} alignItems="center" marginTop={2}>
                <Grid item xs={12}>
                  <InputLabel>{t('academic.requisition.review.comment')}</InputLabel>
                  <TextField
                    multiline
                    rows={2}
                    fullWidth
                    error={Boolean(errors.comment && touched.comment)}
                    placeholder={t('academic.requisition.review.comment.placeholder')}
                    {...getFieldProps('comment')}
                  />
                  {errors.comment && touched.comment && (
                    <FormHelperText error>{t(errors.comment)}</FormHelperText>
                  )}
                </Grid>
              </Grid>

              <Grid
                item
                container
                alignItems="center"
                justifyContent="flex-end"
                spacing={2}
                sx={{ mt: 1 }}
                direction="row-reverse"
              >
                <Grid
                  item
                  container
                  xs={12}
                  md={6}
                  alignItems="center"
                  justifyContent={'flex-end'}
                  spacing={1}
                >
                  <Grid item>
                    <Button
                      onClick={() => setStatusRequisition(0)}
                      type="submit"
                      variant="contained"
                      size="large"
                      color="primary"
                      disabled={isLoading || !requisitionData?.next_status}
                      endIcon={
                        isLoading && statusRequisition === 0 && <CircularProgress size={20} />
                      }
                    >
                      {buttonName}
                      {/* Revisar */}
                    </Button>
                  </Grid>

                  <Grid item>
                    <Button
                      onClick={(e) => setStatusRequisition(1)}
                      type="submit"
                      variant="contained"
                      size="large"
                      sx={{
                        backgroundColor: theme.palette.orange.dark,
                        '&:hover': { backgroundColor: theme.palette.orange.main },
                      }}
                      disabled={isLoading || !requisitionData?.next_status}
                      endIcon={
                        isLoading && statusRequisition === 1 && <CircularProgress size={20} />
                      }
                    >
                      {t('academic.requisition.review.reject')}
                    </Button>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Button
                    variant="outlined"
                    size="large"
                    color="primary"
                    onClick={() => history.goBack()}
                  >
                    {t('academic.requisition.review.back')}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
          <Grid item xs={12} md={4}>
            <CommentsList
              applicationCommentsList={requisitionData?.app_comments || []}
              nextStatus={requisitionData?.next_status}
            />
          </Grid>
        </>
      )}
    </>
  );
};

export default EditRequisitionForm;
