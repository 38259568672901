import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import {
  Autocomplete,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
  InputAdornment,
} from '@material-ui/core';

import { useFormik } from 'formik';

import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';

import { useAppSelector } from '../../../../hooks/useRedux';

import { positionSchema } from '../constants/validation-schemas';

import { useUpdatePositionMutation, useGetAvailableRolesQuery } from '../slices/PositionsApiSlice';

import { statusAvailables } from '../../../shared/constants/resourceStatus';
import { useFormStyles } from '../constants/styles';
import { useGetAvailableDepartmentsQuery } from '../../departments/slices/departmentsApiSlice';

const EditPeriodForm = () => {
  const history = useHistory();
  const { positionId } = useParams<{ positionId: string }>();

  const { positionList } = useAppSelector((state) => state.positions);
  const { currentProfile } = useAppSelector((state) => state.access);

  const classes = useFormStyles();

  const [showAlerts, setShowAlerts] = useState(false);

  const [updatePosition, { isLoading: isUpdating, isSuccess, isError, error }] =
    useUpdatePositionMutation();

  const { data: rolesAvalaibles = [], isLoading: isLoadingRoles } = useGetAvailableRolesQuery({
    profileId: currentProfile?.profileId!,
  });

  const { data: departmentAvalaibles = [], isLoading: isLoadingDepartment } =
    useGetAvailableDepartmentsQuery({
      profileId: currentProfile?.profileId!,
    });

  const positionToEdit = positionList.find((p) => p.id === Number(positionId));

  const { getFieldProps, errors, touched, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      posName: positionToEdit?.pos_name || '',
      posDescription: positionToEdit?.pos_description || '',
      roleId: positionToEdit?.role_id || 1,
      posKeyword: positionToEdit?.pos_keyword || '',
      departmentId: positionToEdit?.department_id || '',
      statusId: positionToEdit?.status_id || 1,
    },
    validationSchema: positionSchema,
    onSubmit: async (values) => {
      await updatePosition({
        profileId: currentProfile?.profileId!,
        positionId: positionToEdit?.id!,
        positionPayload: {
          pos_name: values.posName,
          pos_description: values.posDescription,
          role_id: values.roleId.toString(),
          pos_keyword: values.posKeyword,
          department_id: values.departmentId,
          status_id: values.statusId.toString(),
        },
      });

      setShowAlerts(true);
    },
  });

  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction="column" gap={2}>
        <Grid item>
          {isError && showAlerts && (
            <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
          )}

          {isSuccess && showAlerts && (
            <SuccessAlert
              message="El cargo ha sido actualizado correctamente."
              handleDismiss={() => setShowAlerts(false)}
            />
          )}
        </Grid>

        <Grid item container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>Nombre del Cargo</InputLabel>
            <TextField
              fullWidth
              error={Boolean(errors.posName && touched.posName)}
              placeholder="Ingrese el nombre del cargo"
              {...getFieldProps('posName')}
            />
            {errors.posName && touched.posName && (
              <FormHelperText error>{errors.posName}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>Descripción</InputLabel>
            <TextField
              multiline
              rows={2}
              fullWidth
              error={Boolean(errors.posDescription && touched.posDescription)}
              placeholder="Ingrese una descripción para el cargo"
              {...getFieldProps('posDescription')}
            />
            {errors.posDescription && touched.posDescription && (
              <FormHelperText error>{errors.posDescription}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>Abreviatura</InputLabel>
            <TextField
              fullWidth
              error={Boolean(errors.posKeyword && touched.posKeyword)}
              placeholder="Ingrese la abreviatura"
              {...getFieldProps('posKeyword')}
            />
            {errors.posKeyword && touched.posKeyword && (
              <FormHelperText error>{errors.posKeyword}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>Rol</InputLabel>

            {isLoadingRoles ? (
              <>
                <TextField
                  disabled
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <CircularProgress size={25} />
                      </InputAdornment>
                    ),
                  }}
                  error={Boolean(errors.roleId && touched.roleId)}
                  placeholder="Cargando datos ..."
                />
                {errors.roleId && touched.roleId && (
                  <FormHelperText error>{errors.roleId}</FormHelperText>
                )}
              </>
            ) : (
              <Autocomplete
                options={rolesAvalaibles}
                loading={isLoadingRoles}
                loadingText="Cargando roles..."
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField {...params} error={Boolean(errors.roleId && touched.roleId)} />
                )}
                defaultValue={rolesAvalaibles.find((p) => p.id === Number(positionToEdit?.role_id))}
                onChange={(_, value) => setFieldValue('roleId', value?.id || '')}
                fullWidth
                clearIcon={null}
              />
            )}

            {errors.roleId && touched.roleId && (
              <FormHelperText error>{errors.roleId}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>Departamento</InputLabel>

            {isLoadingDepartment ? (
              <>
                <TextField
                  disabled
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <CircularProgress size={25} />
                      </InputAdornment>
                    ),
                  }}
                  error={Boolean(errors.roleId && touched.roleId)}
                  placeholder="Cargando datos ..."
                />
                {errors.roleId && touched.roleId && (
                  <FormHelperText error>{errors.roleId}</FormHelperText>
                )}
              </>
            ) : (
              <Autocomplete
                options={departmentAvalaibles}
                loading={isLoadingDepartment}
                loadingText="Cargando Departamentos..."
                getOptionLabel={(option) => option.dep_name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(errors.departmentId && touched.departmentId)}
                  />
                )}
                defaultValue={departmentAvalaibles.find(
                  (p) => p.id === Number(positionToEdit?.department_id)
                )}
                onChange={(_, value) => setFieldValue('departmentId', value?.id || '')}
                fullWidth
                clearIcon={null}
              />
            )}
            {errors.roleId && touched.roleId && (
              <FormHelperText error>{errors.roleId}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>Estado</InputLabel>
            <Autocomplete
              options={statusAvailables}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField {...params} error={Boolean(errors.statusId && touched.statusId)} />
              )}
              defaultValue={statusAvailables.find(
                (s) => s.id === Number(positionToEdit?.status_id)
              )}
              onChange={(e, value) => setFieldValue('statusId', value?.id || '')}
              fullWidth
              clearIcon={null}
            />
            {errors.statusId && touched.statusId && (
              <FormHelperText error>{errors.statusId}</FormHelperText>
            )}
          </Grid>
        </Grid>

        <Grid
          item
          container
          alignItems="center"
          justifyContent="flex-end"
          spacing={2}
          sx={{ mt: 1 }}
        >
          <Grid item>
            <Button
              variant="outlined"
              size="large"
              color="primary"
              onClick={() => history.goBack()}
            >
              Regresar
            </Button>
          </Grid>
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              disabled={isUpdating}
              endIcon={isUpdating && <CircularProgress size={20} />}
            >
              Actualizar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default EditPeriodForm;
