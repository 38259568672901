import { useState } from 'react';
import {
  Tooltip,
  IconButton,
  Typography,
  Dialog,
  Grid,
  Button,
  TextField,
  Autocomplete,
} from '@material-ui/core';
import { IconPlayerPlay } from '@tabler/icons';

import DateRangePicker, { DateRange } from '@material-ui/lab/DateRangePicker';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import esLocale from 'date-fns/locale/es';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import { format } from 'date-fns';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { VisibilityOffOutlined } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';

import { useGetEvaluationsStudentQuery } from '../../slices/virtualClassroomApiSlice';
import { useAppSelector, useAppDispatch } from '../../../../../hooks/useRedux';
import { renderSkeletonTable } from '../../../../shared/helpers/render';
import Chip from '../../../../shared/components/extended/Chip';
import {
  setCurrentEvaluation,
  setEvaluationConfirmStartDialog,
  setInfoModalEvaluation,
} from '../../slices/virtualClassroomSlice';
import NoData from '../../../../shared/components/NoData';
import noContentImage from '../../../../../assets/images/no-tests.png';
import SearchPaginatedTable from '../../../../shared/components/tables/SearchPaginatedTable';
import InputLabel from '../../../../shared/components/forms/InputLabel';
import { virtualClassActivitiesStatus } from '../../../../shared/constants/resourceStatus';

const useStyles = makeStyles((theme) => ({
  paper: { maxWidth: '600px', width: '90%' },
}));

const EvaluationList = () => {
  const dispatch = useAppDispatch();

  const [page, setPage] = useState<number>(1);

  const [perPage, setPerPage] = useState<number>(10);

  const [search, setSearch] = useState<string>('');

  //date range
  const [valuesDates, setValuesDates] = useState<DateRange<Date>>([null, null]);
  const [status, setStatus] = useState<string | null>(null);

  const { currentStudent } = useAppSelector((state) => state.access);

  const { language } = useAppSelector((state) => state.customization);

  const classes = useStyles();

  const { t } = useTranslation();

  const {
    course: { courseId },
  } = useAppSelector((state) => state.virtualClassroomStudentShared);
  const { modalInfoEvaluation } = useAppSelector((state) => state.virtualClassroomStudent);

  const { data, isLoading, error, isFetching } = useGetEvaluationsStudentQuery(
    {
      courseId: courseId!,
      studentId: currentStudent?.id!,
      page: page,
      size: perPage,
      search,
      ...(status && { estado: status }),
      ...(valuesDates[0] && valuesDates[1] && { fdesde: format(valuesDates[0], 'yyyy-MM-dd') }),
      ...(valuesDates[1] && valuesDates[0] && { fhasta: format(valuesDates[1], 'yyyy-MM-dd') }),
    },
    { skip: courseId === 0 || courseId === undefined }
  );

  const dataFetch =
    data &&
    data?.data?.map((e) => ({
      id: e?.id,
      /*Descripción de actividad */
      actDescription: e?.act_description,
      /*Fecha de comienzo de evaluación */
      actStartDate: moment(e?.course_activity_one?.cact_start_date!)
        .locale(language)
        .format('dddd, D MMMM YYYY, h:mm a'),
      /*Puntaje de evaluación total sobre el puntaje obtenido cuando haya realizado evaluación. */
      actScoreActivity:
        e?.visible_note === '1'
          ? `${
              e?.cab_activity_student === null ? 0 : Number(e?.cab_activity_student?.act_score!)
            } / ${Number(e?.act_score_activity)}`
          : '--',

      /* Preguntas calificadas sobre el total de preguntas de la evaluación.*/
      // actQuestionsQualified: `${
      //   e?.cab_activity_student === null ? 0 : e?.cab_activity_student?.answer_qualified
      // }
      //    / ${e?.act_num_max_questions!}`,
      /* Número de intentos realizados sobre el total de intentos de la evaluación.*/
      actNumTries: `${e.num_tries_realized} / ${e?.act_num_tries}`,
      /* Estado de la evaluación basada en la fecha.*/
      status: '',
      options: {
        startDate: e?.course_activity_one?.cact_start_date!,
        endDate: e?.course_activity_one?.cact_end_date!,
      },
    }));

  const handleDetailEvaluation = (evaluationId: number) => {
    dispatch(setCurrentEvaluation(evaluationId));
    dispatch(setEvaluationConfirmStartDialog(true));
  };

  const InfoValue = (nameValue: string, value: string) => {
    return (
      <Grid container mt={'12px'}>
        <Grid item xs={7}>
          <Typography variant="h4" textAlign={'center'} color="black">
            {nameValue}
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography variant="body1" color="black">
            {value}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const OnCloseModal = () => {
    dispatch(
      setInfoModalEvaluation({
        ...modalInfoEvaluation!,
        modal: false,
      })
    );
  };

  const onChangeDates = (newDatesValues: DateRange<Date>) => {
    setValuesDates(newDatesValues);
  };

  return (
    <>
      {isLoading ? (
        renderSkeletonTable(5, 5)
      ) : (
        <>
          <Dialog
            open={modalInfoEvaluation?.modal!}
            classes={{ paper: classes.paper }}
            onClose={OnCloseModal}
          >
            <Grid xs={12}>
              <Grid padding="8px 12px">
                <Typography textAlign={'center'} color="primary" variant="h2">
                  {t('studentVirtualClassroom.test.modal.detail.title')}
                </Typography>

                {dataFetch &&
                  dataFetch?.map((item) => {
                    return (
                      item.id === modalInfoEvaluation?.idEvaluation! && (
                        <>
                          <Grid container>
                            {InfoValue(
                              t('studentVirtualClassroom.test.modal.detail.name.title'),
                              item?.actDescription
                            )}
                          </Grid>
                          <Grid container>
                            {InfoValue(
                              t('studentVirtualClassroom.test.modal.detail.dateStart.title'),
                              moment(item?.options?.startDate).format('LL')
                            )}
                          </Grid>
                          <Grid container>
                            {InfoValue(
                              t('studentVirtualClassroom.test.modal.detail.dateEnd.title'),
                              moment(item?.options?.endDate!).format('LL')
                            )}
                          </Grid>
                          <Grid container>
                            {InfoValue(
                              t('studentVirtualClassroom.test.modal.detail.state.title'),
                              moment().isBefore(item?.options?.endDate!)
                                ? t('studentVirtualClassroom.test.active')
                                : t('studentVirtualClassroom.test.finished')
                            )}
                          </Grid>
                        </>
                      )
                    );
                  })}
              </Grid>

              <Grid display="flex" gap={1} mt={'15px'} justifyContent={'center'}>
                <Button variant="text" onClick={OnCloseModal}>
                  {t('studentVirtualClassroom.test.modal.detail.close.title')}
                </Button>
                <Button
                  disabled={!moment().isBefore(modalInfoEvaluation?.endDate)}
                  endIcon={<IconPlayerPlay />}
                  onClick={() => handleDetailEvaluation(modalInfoEvaluation?.idEvaluation!)}
                  variant="contained"
                >
                  {' '}
                  {t('studentVirtualClassroom.test.startTest')}
                </Button>
              </Grid>
            </Grid>
          </Dialog>

          {dataFetch?.length! > 0 || status || valuesDates[0] || search ? (
            <SearchPaginatedTable
              data={dataFetch!}
              headers={{
                id: t('studentVirtualClassroom.test.code'),
                actDescription: t('studentVirtualClassroom.test.description'),
                actStartDate: t('studentVirtualClassroom.test.tentativeDate'),
                actScoreActivity: t('studentVirtualClassroom.test.qualification'),
                actNumTries: t('studentVirtualClassroom.test.numberTries'),
                status: t('studentVirtualClassroom.test.status'),
                options: t('studentVirtualClassroom.test.options'),
              }}
              total={data?.total! || 0}
              keyExtractor={(item) => item.id.toString()}
              isLoading={isLoading}
              isFetching={isFetching}
              page={page}
              setPage={setPage}
              perPage={perPage}
              setPerPage={setPerPage}
              search={search}
              setSearch={setSearch}
              searchPlacehoder={t('studentVirtualClassroom.test.search')}
              error={error}
              numHeader={7}
              // showFilter={false}
              customDataCellsProperties={{
                actDescription: {
                  align: 'center',
                },
                actStartDate: {
                  align: 'center',
                },
                actNumTries: {
                  align: 'center',
                },
                actScoreActivity: {
                  align: 'center',
                },
                options: {
                  align: 'center',
                },
                status: {
                  align: 'center',
                },
                // actQuestionsQualified: {
                //   align: 'center',
                // },
              }}
              customHeadersCellsProperties={{
                actNumTries: {
                  align: 'center',
                },
                actScoreActivity: {
                  align: 'center',
                },
                options: {
                  align: 'center',
                },
                status: {
                  align: 'center',
                },
                // actQuestionsQualified: {
                //   align: 'center',
                // },
              }}
              customRenderers={{
                options: ({ id, options: { startDate, endDate } }) => (
                  <>
                    <Tooltip title={t('studentVirtualClassroom.test.startTest')}>
                      <span style={{ cursor: 'pointer' }}>
                        <IconButton
                          disabled={moment().isBefore(startDate) || moment().isAfter(endDate)}
                          onClick={() => handleDetailEvaluation(id)}
                          aria-label={t('studentVirtualClassroom.test.startTest')}
                          color="secondary"
                        >
                          <IconPlayerPlay />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </>
                ),
                status: ({ options: { endDate, startDate } }) => (
                  <>
                    {moment().isBefore(endDate) ? (
                      <Chip
                        label={t('studentVirtualClassroom.test.active')}
                        size="small"
                        chipcolor="success"
                      />
                    ) : (
                      <Chip
                        label={t('studentVirtualClassroom.test.finished')}
                        size="small"
                        chipcolor="error"
                      />
                    )}
                  </>
                ),
                actScoreActivity: ({ actScoreActivity }) => (
                  <>
                    {actScoreActivity === '--' ? (
                      <Tooltip title={t('studentVirtualClassroom.test.card.description.hideNote')}>
                        <span style={{ cursor: 'pointer' }}>
                          <IconButton
                            disabled={true}
                            aria-label={t('studentVirtualClassroom.test.startTest')}
                            color="secondary"
                          >
                            <VisibilityOffOutlined />
                          </IconButton>
                        </span>
                      </Tooltip>
                    ) : (
                      <Typography>{actScoreActivity}</Typography>
                    )}
                  </>
                ),
              }}
              additionalFilters={
                <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                  <Grid item xs={4}>
                    <InputLabel>
                      {t('teacherVirtualClassroom.tasks.table.input.title.status')}
                    </InputLabel>

                    <Autocomplete
                      style={{ backgroundColor: 'lightgray', borderRadius: '25px' }}
                      disablePortal
                      getOptionLabel={(option) => t(option.label)}
                      options={virtualClassActivitiesStatus}
                      fullWidth
                      onChange={(_, value) => {
                        setStatus(value?.name || null);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t('teacherVirtualClassroom.tasks.table.input.title.status.select')}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                      <DateRangePicker
                        startText={null}
                        endText={null}
                        value={valuesDates}
                        onChange={onChangeDates}
                        renderInput={(startProps, endProps) => (
                          <Grid container spacing={2} alignItems="center">
                            <Grid item xs={6}>
                              <InputLabel>
                                {t('teacher_virtual_classroom.meetings.add.table.startTime')}:
                              </InputLabel>
                              <TextField fullWidth {...startProps} helperText="" />
                            </Grid>

                            <Grid item xs={6}>
                              <InputLabel>
                                {t('teacher_virtual_classroom.meetings.add.table.endTime')}:
                              </InputLabel>
                              <TextField fullWidth {...endProps} helperText="" />
                            </Grid>
                          </Grid>
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              }
            />
          ) : (
            <NoData
              image={noContentImage}
              title={t('studentVirtualClassroom.test.empty')}
              subtitle={t('studentVirtualClassroom.test.emptyDescription')}
            />
          )}
        </>
      )}
    </>
  );
};

export default EvaluationList;
