import { Grid, Typography } from '@material-ui/core';
import MainCard from '../../../shared/components/cards/MainCard';
import CampaingsTable from '../components/CampaingsTable';

const MarketingCampaingsScreen = () => {
    return (
        <Grid component={MainCard} sx={{ padding: 2 }}>
            <Grid sx={{ mb: 3 }}>
                <Typography variant="h2">Marketing</Typography>
                <Typography variant="h2" color="gray" fontWeight={400}>Campañas</Typography>
                <br />

                <CampaingsTable/>

            </Grid>

        </Grid>
    )
}

export default MarketingCampaingsScreen;