import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import GoBackBtnTitle from '../../../shared/components/buttons/GoBackBtnTitle';

import MainCard from '../../../shared/components/cards/MainCard';
import BusSchedulesList from '../components/BusSchedulesList';
import { useAppSelector } from '../../../../hooks/useRedux';

const BusSchedulesScreen = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const { currentProfile } = useAppSelector((state) => state.access);

  if (currentProfile?.keyword !== 'administrativo' && currentProfile?.keyword !== 'docente') {
    history.goBack();
  }

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <GoBackBtnTitle
        title={t('academic.buses.schedules.title')}
        itemContainerProps={{ sx: { mb: 3 } }}
      />
      <BusSchedulesList />
    </Grid>
  );
};

export default BusSchedulesScreen;
