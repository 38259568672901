import { Skeleton, Grid } from '@material-ui/core';

const SkeletonList = () => {
  const table = [];
  for (let i = 0; i < 5; i++) {
    table.push(
      <Grid key={i} container justifyContent={'center'} sx={{ mt: 1 }}>
        <Grid item sm={6} md={3} sx={{ p: 1 }}>
          <Skeleton variant="rectangular" height={20} />
        </Grid>
        <Grid item sm={6} md={3} sx={{ p: 1 }}>
          <Skeleton variant="rectangular" height={20} />
        </Grid>
        <Grid item sm={6} md={3} sx={{ p: 1 }}>
          <Skeleton variant="rectangular" height={20} />
        </Grid>
        <Grid item sm={6} md={3} sx={{ p: 1 }}>
          <Skeleton variant="rectangular" height={20} />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container>
      {/* <Grid item sm={12} md={6} sx={{ p: 1 }}>
                <Skeleton variant="rectangular" height={50} />
            </Grid>
            <Grid item sm={12} md={6} sx={{ p: 1 }}>
                <Skeleton variant="rectangular" height={50} />
            </Grid>
            <Grid item sm={12} md={6} sx={{ p: 1 }}>
                <Skeleton variant="rectangular" height={50} />
            </Grid> */}
      {table}
    </Grid>
  );
};

export default SkeletonList;
