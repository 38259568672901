/* eslint-disable no-empty-pattern */

import { mainApi } from '../../../../../../api/mainApi';
import { middlewareApi } from '../../../../../middleware/middlewareApi';
import { IEvaluationType, IEvaluationTypeResponse } from '../interfaces/evaluationType.interface';
import { GroupType } from '../interfaces/groupType.interface';

export const evaluationTypeTags = middlewareApi.enhanceEndpoints({
  addTagTypes: ['EvaluationType'],
});

export const evaluationTypeApi = evaluationTypeTags.injectEndpoints({
  endpoints: (builder) => ({
    getEvaluationTypes: builder.query<
      IEvaluationTypeResponse,
      {
        page: number;
        perPage: number;
        search: string;
        status?: string;
      }
    >({
      queryFn: async ({ page, perPage, search, status }, { dispatch }) => {
        try {
          const { data } = await mainApi.get<IEvaluationTypeResponse>('/eva/eva-types', {
            params: {
              page,
              size: perPage,
              search,
              ...(status && { status }),
            },
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) =>
        result && result.data
          ? [
              ...result.data.map(({ uuid: id }) => ({ type: 'EvaluationType', id } as const)),
              { type: 'EvaluationType', id: 'LIST' },
            ]
          : [{ type: 'EvaluationType', id: 'LIST' }],
    }),
    getAllEvaluationTypes: builder.query<IEvaluationType[], {}>({
      queryFn: async ({}, { dispatch }) => {
        try {
          const { data } = await mainApi.get<IEvaluationType[]>('/eva/eva-types', {
            params: {
              data: 'all',
            },
          });
          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) => [{ type: 'EvaluationType', id: 'LIST' }],
    }),
    getEvaluationType: builder.query<
      IEvaluationType,
      {
        id: string;
      }
    >({
      queryFn: async ({ id }, { dispatch }) => {
        try {
          const { data } = await mainApi.get<IEvaluationType>(`/eva/eva-types/${id}`);
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: [{ type: 'EvaluationType', id: 'LIST' }],
    }),
    addEvaluationType: builder.mutation<
      IEvaluationType,
      {
        name: string;
        status: string;
        description: string;
        acronym: string;
        eva_group_type_id: string | number;
      }
    >({
      queryFn: async ({ description, acronym, name, status, eva_group_type_id }, { dispatch }) => {
        try {
          const { data } = await mainApi.post<IEvaluationType>('/eva/eva-types', {
            name,
            status,
            description,
            acronym,
            eva_group_type_id,
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'EvaluationType', id: 'LIST' }],
    }),
    updateEvaluationType: builder.mutation<
      IEvaluationType,
      {
        id: string;
        name: string;
        status: string;
        description: string;
        acronym: string;
        eva_group_type_id: string | number;
      }
    >({
      queryFn: async (
        { description, acronym, id, name, status, eva_group_type_id },
        { dispatch }
      ) => {
        try {
          const { data } = await mainApi.put<IEvaluationType>(`/eva/eva-types/${id}`, {
            name,
            status,
            description,
            acronym,
            eva_group_type_id,
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'EvaluationType', id: 'LIST' }],
    }),

    deleteEvaluationType: builder.mutation<
      IEvaluationType,
      {
        id: string;
      }
    >({
      queryFn: async ({ id }, { dispatch }) => {
        try {
          const { data } = await mainApi.delete<IEvaluationType>(`/eva/eva-types/${id}`);

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'EvaluationType', id: 'LIST' }],
    }),

    getGroupsTypesAll: builder.query<GroupType[], {}>({
      queryFn: async ({}, { dispatch }) => {
        try {
          const { data } = await mainApi.get<GroupType[]>('eva/eva-group-types', {
            params: {
              data: 'all',
            },
          });
          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
    }),
  }),
});

export const {
  useGetEvaluationTypesQuery,
  useLazyGetEvaluationTypesQuery,
  useGetAllEvaluationTypesQuery,
  useGetEvaluationTypeQuery,
  useLazyGetEvaluationTypeQuery,
  useAddEvaluationTypeMutation,
  useUpdateEvaluationTypeMutation,
  useDeleteEvaluationTypeMutation,
  useGetGroupsTypesAllQuery,
} = evaluationTypeApi;
