import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import MainCard from '../../../shared/components/cards/MainCard';
import TeachersTable from '../components/TeachersTable';

const TeachersScreen = () => {
  const { t } = useTranslation();

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">{t('academic.teachers_list.title')}</Typography>
      </Grid>
      <TeachersTable />
    </Grid>
  );
};

export default TeachersScreen;
