import { useFormik } from 'formik';
import {
  Autocomplete,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
} from '@material-ui/core';
import {
  useChangeStateSyllabusMutation,
  useGetSyllabusStatusQuery,
} from '../slices/SyllabusApiSlice';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../hooks/useRedux';

const ChangeStatusSyllabusForm = () => {
  const { t } = useTranslation();

  const { syllabusIdSelected } = useAppSelector((state) => state.syllabus);

  const [showAlerts, setShowAlerts] = useState(false);

  const { data: status, isLoading: isLoadingStatus } = useGetSyllabusStatusQuery();

  const [changeStateSyllabus, { isLoading, isSuccess, isError, error }] =
    useChangeStateSyllabusMutation();

  const { values, errors, setFieldValue, handleSubmit, touched } = useFormik({
    initialValues: {
      status_id: '',
    },
    // validationSchema: scopeFormSchema,
    onSubmit: async (currentValues) => {
      await changeStateSyllabus({
        statusId: currentValues.status_id,
        syllabusId: syllabusIdSelected!,
      });
      setShowAlerts(true);
    },
  });

  return (
    <Grid container spacing={2}>
      {isSuccess && showAlerts && (
        <Grid item xs={12}>
          <SuccessAlert
            message={t('Estado actualizado correctamente')}
            handleDismiss={() => setShowAlerts(false)}
          />
        </Grid>
      )}
      {isError && showAlerts && (
        <Grid item xs={12}>
          <ErrorAlert message={error as any} handleDismiss={() => setShowAlerts(false)} />
        </Grid>
      )}

      <Grid item xs={12}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputLabel>{t('maintenance.scopes.form.status')}</InputLabel>
              <Autocomplete
                loading={isLoadingStatus}
                options={status || []}
                getOptionLabel={(option) => option.st_name}
                onChange={(event, value) => {
                  setFieldValue('status_id', value?.id);
                }}
                value={status?.find((option) => Number(option.id) === Number(values.status_id))}
                renderInput={(params) => <TextField {...params} placeholder={t('')} fullWidth />}
              />
              {errors.status_id && touched.status_id && (
                <FormHelperText error>{t(errors.status_id)}</FormHelperText>
              )}
            </Grid>
            <Grid item container xs={12} justifyContent="flex-end" spacing={2}>
              <Grid item>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  disabled={isLoading}
                  endIcon={isLoading && <CircularProgress size={12} />}
                >
                  Cambiar estado
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default ChangeStatusSyllabusForm;
