import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';

import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../../../../hooks/useRedux';

import SubCard from '../../../../../shared/components/cards/SubCard';
import { Details } from '../../../../electronic-documents/interfaces/electronicDocuments.interfaces';
import { renderErrorOrEmptyRow } from '../../../../../shared/helpers/render';

const InvoiceDetail = () => {
  const { t } = useTranslation();

  const defaultConfiguration = useAppSelector((state) => state.access.defaultConfiguration);

  const { selectedInvoice } = useAppSelector((state) => state.generateCreditNote);

  const renderRow = (details: Details, i: number) => (
    <TableRow hover className="table-row" key={`${details.id.toString()}-${i}`}>
      <TableCell>{details.descripcion}</TableCell>
      <TableCell align="center">
        {defaultConfiguration?.currency_symbol}
        {details.precioUnitario}
      </TableCell>
      <TableCell align="center">{Number(details.cantidad)}</TableCell>
      <TableCell align="center">{details.descuento}</TableCell>
      <TableCell align="center">
        {defaultConfiguration?.currency_symbol}
        {details.precioTotalSinImpuesto || (0).toFixed(2)}
      </TableCell>
      {/* <TableCell align="center">
        {false && <EditButton handleEdit={() => handleEditPay(i)} />}
        <DeleteButton handleDelete={() => handleDeletePay(i)} />
      </TableCell> */}
    </TableRow>
  );

  const renderRows = () => {
    return <>{selectedInvoice?.details?.map(renderRow)}</>;
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <SubCard>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Grid container justifyContent={'space-between'}>
                        <Typography variant="subtitle1" component="span">
                          {t('financial.generate_creditnote.invoice_detail.num_invoice')}
                          &nbsp;
                        </Typography>
                        <Typography component="span" variant="body2">
                          {`${selectedInvoice?.establecimiento || '000'}-${
                            selectedInvoice?.ptoEmision || '000'
                          }-${selectedInvoice?.secuencial || '00000000'}`}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container justifyContent={'space-between'}>
                        <Typography variant="subtitle1" component="span">
                          {t('financial.generate_creditnote.invoice_detail.code_invoice')}
                          &nbsp;
                        </Typography>
                        <Typography component="span" variant="body2">
                          {selectedInvoice?.secuencial || ''}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Grid container justifyContent={'space-between'}>
                        <Typography variant="subtitle1" component="span">
                          {t('financial.generate_creditnote.invoice_detail.total_invoice')}
                          &nbsp;
                        </Typography>
                        <Typography component="span" variant="body2">
                          {selectedInvoice?.importeTotal || ''}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container justifyContent={'space-between'}>
                        <Typography variant="subtitle1" component="span">
                          {t(
                            'financial.generate_creditnote.invoice_detail.total_without_taxes_invoice'
                          )}
                          &nbsp;
                        </Typography>
                        <Typography component="span" variant="body2">
                          {selectedInvoice?.importeTotal || ''}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container justifyContent={'space-between'}>
                        <Typography variant="subtitle1" component="span">
                          {t('financial.generate_creditnote.invoice_detail.total_paid')}&nbsp;
                        </Typography>
                        <Typography component="span" variant="body2">
                          {selectedInvoice?.importeTotal || ''}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </SubCard>
      </Grid>
      <Grid item xs={12}>
        <SubCard title="Items" content={false}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        {t('financial.generate_creditnote.invoice_detail.table.product')}
                      </TableCell>
                      <TableCell align="center">
                        {t('financial.generate_creditnote.invoice_detail.table.price')}
                        {`(${defaultConfiguration?.currency_symbol})`}
                      </TableCell>
                      <TableCell align="center">
                        {t('financial.generate_creditnote.invoice_detail.table.quantity')}
                      </TableCell>
                      <TableCell align="center">
                        {t('financial.generate_creditnote.invoice_detail.table.discount')}
                      </TableCell>
                      <TableCell align="center">
                        {t('financial.generate_creditnote.invoice_detail.table.subtotal')}
                        {`(${defaultConfiguration?.currency_symbol})`}
                      </TableCell>
                      {/* <TableCell align="center">Remover</TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedInvoice?.details && renderRows()}
                    {!selectedInvoice && renderErrorOrEmptyRow(5)}
                    {selectedInvoice?.details && selectedInvoice?.details?.length !== 0 && (
                      <>
                        {/* <TableRow>
                          <TableCell colSpan={6} align="right">
                            <b>Total:</b>
                          </TableCell>
                          <TableCell> {showWithDecimals(totalNetAmout)} </TableCell>
                          <TableCell>{showWithDecimals(totalPayAmount)} </TableCell>
                        </TableRow> */}
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </SubCard>
      </Grid>
    </Grid>
  );
};

export default InvoiceDetail;
