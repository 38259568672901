import {
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Skeleton,
  Typography,
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useAppSelector } from '../../../../hooks/useRedux';
import { useGetAvailablesCampusQuery } from '../../campus/slices/campusApiSlice';
import Map from './Map';

const Campus = () => {
  const { currentProfile } = useAppSelector((state) => state.access);

  const [campusIdSelected, setCampusIdSelected] = useState(0);

  const { data, isLoading } = useGetAvailablesCampusQuery(
    {
      profileId: currentProfile?.profileId!,
    },
    {
      skip: !currentProfile?.profileId,
    }
  );

  useEffect(() => {
    if (data) {
      setCampusIdSelected(data[0]?.id);
    }
  }, [data]);

  const campusSelected = data?.find((campus) => campus.id === campusIdSelected);

  return (
    <>
      <Grid sx={{ my: 3 }}>
        <Typography variant="h2">Campus</Typography>
      </Grid>
      <Grid item xs={12} mt={1}>
        <Divider />
      </Grid>
      {isLoading ? (
        <Grid item xs={12}>
          <Skeleton variant="rectangular" width={'100%'} height={'200px'} />
        </Grid>
      ) : (
        <Grid item container xs={12}>
          <Grid item xs={12} md={4} p={2}>
            <Grid container flexDirection="column" spacing={1}>
              <Grid item xs={12}>
                <List
                  sx={{
                    width: '100%',
                  }}
                >
                  {data?.map((campus, idx) => (
                    <React.Fragment key={`informacion-institucional-campus${idx}`}>
                      <ListItem
                        button
                        key={campus.id}
                        selected={campusIdSelected === campus.id}
                        onClick={() => setCampusIdSelected(campus.id)}
                      >
                        <ListItemText primary={campus.cam_name} />
                      </ListItem>
                      <Divider variant="inset" component="li" />
                    </React.Fragment>
                  ))}
                </List>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container xs={12} md={8} p={2}>
            {campusSelected && (
              <Grid item xs={12} p={2}>
                <Typography variant="body1">
                  <b>Dirección:</b> {campusSelected?.cam_direction || 'No disponible'}
                </Typography>
                <Typography variant="body1">
                  <b>Teléfono:</b> {campusSelected?.cam_phone || 'No disponible'}
                </Typography>
                <Typography variant="body1">
                  <b>Celular:</b> {campusSelected?.can_cellphone || 'No disponible'}
                </Typography>
              </Grid>
            )}

            {campusSelected && campusSelected?.cam_latitude && campusSelected?.cam_longitude && (
              <Map
                googleMapURL={`${
                  process.env.REACT_APP_GOOGLE_MAPS_API_URL ||
                  'https://maps.googleapis.com/maps/api/js?v=3.exp&key='
                }${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`} //&callback=initMap
                containerElement={<div style={{ height: '400px' }} />}
                mapElement={<div style={{ height: '100%' }} />}
                loadingElement={<p>Cargando...</p>}
                latitude={campusSelected?.cam_latitude!}
                longitude={campusSelected?.cam_longitude!}
              />
            )}

            {campusSelected && !campusSelected?.cam_latitude && !campusSelected?.cam_longitude && (
              <Typography variant="h5">No se ha asignado una ubicación para este campus</Typography>
            )}

            {!campusSelected && (
              <Typography align="center" variant="h5">
                Seleccione un campus para ver su ubicación
              </Typography>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default Campus;
