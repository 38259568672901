import { Grid, Typography, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import MainCard from '../../../shared/components/cards/MainCard';
import InvestigationProjectsLinesRoute from '../router/investigationProjectsLinesRoute';

const InvestigationLines = () => {
  const { t } = useTranslation();

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">
          {/* El */}
          {window.location.pathname.split('/')?.some((e) => e === 'proyectos')
            ? t('Proyectos')
            : window.location.pathname.split('/')?.some((e) => e.includes('grupos'))
            ? t('Grupos de investigación')
            : t('lines_research.FilterTables.investigationLines.title')}
        </Typography>
      </Grid>
      <Box p={2}>
        <InvestigationProjectsLinesRoute />
      </Box>
    </Grid>
  );
};

export default InvestigationLines;
