import { makeStyles } from '@material-ui/core/styles';

export const useFormStyles = makeStyles((theme) => ({
  inputLabel: {
    marginBlockEnd: 6,
    fontWeight: 500,
    color: theme.palette.text.primary,
  },

  labelHeader: {
    marginBlockEnd: 6,
    fontWeight: 500,
    color: theme.palette.text.dark,
  },
  labelTitle: {
    marginBlockEnd: 6,
    fontWeight: 500,
    color: theme.palette.grey[700],
  },
  labelCollapse: {
    fontWeight: 500,
    color: theme.palette.text.primary,
  },
}));
