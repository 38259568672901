import { Route, Switch } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';

import ErrorFallbackComponentScreen from '../../shared/components/errors-boundary/ErrorFallbackComponentScreen';

import ProfilesRouter from '../profiles/router';
import PermissionsRouter from '../permissions/router';
import PeriodsRouter from '../periods/router';
import EconomicGroupsRouter from '../economic-groups/router';
import MattersRoutes from '../matters/routes';
import InstitutesRouter from '../institutes/router';
import EducationLevelsRouter from '../education-levels/router';
import AcademicOfferings from '../academic-offerings/router';
import CurriculumsRouter from '../meshs/router';
import PensumsRouter from '../pensums/router';
import PeriodTypesRouter from '../periods-types/router';
import HourhandsRouter from '../hourhands/router';

import MattersTypesRoutes from '../matters-types/routes';
import ParallelsRouter from '../parallels/router';
import ClassroomsRouter from '../classrooms/router';
import StudentsRouter from '../students/router';
import PositionsRouter from '../positions/router';
import AgreementsRouter from '../agreements/router';
import RolesRouter from '../roles/router';
import DepartmentRouter from '../departments/router';
import CalificationModelRouter from '../calification-models/router';
import CampusRouter from '../campus/router';
import ClassroomsEducationLvlRouter from '../classrooms-educational-levels/router';
import CompaniesRouter from '../companies/router';
import ConfigurationsRouter from '../configurations/router';
import EducationLevelSubjectRouter from '../education-level-subject/router';
import GroupsAreaRouter from '../groups-area/router';
import EducationLevelCareerRouter from '../education-level-career/router';
import CoursesRouter from '../courses/router';
import HomologationsRouter from '../homologations/router';
import RequisitionTeacher from '../requisition-teacher/router';
import EventNewRouter from '../events-news/router';
import generalApplicationsRouter from '../general-applications/router';
import RegisterNivelationRouter from '../register-nivelation/router';
import TrackingNivelationRouter from '../tracking-nivelation/router';
import MaintenanceCategoryRouter from '../maintenance-category/router';
import MaintenanceProductRouter from '../maintenance-product/router';
import ClientsRouter from '../clients/router';
import ConfigProductsRouter from '../configuration-product-price/router';
import PaychecksRouter from '../paychecks/router';
import DepositsTransfersRouter from '../deposits-transfers/router';
import ElectronicDocumentRouter from '../electronic-documents/router';
import FinancialConfigurationRouter from '../financial-configurations/router';
import institutionInfoRouter from '../institution-info/router';
import DebtsPaymentsRouter from '../debts-payment/router';
import FinancialPeriodsRouter from '../financial-periods/router';
import GenerateDebtsRouter from '../generate-debts/router';
import GenerateCreditNoteRouter from '../generate-credit-note/router';
import cashPointRouter from '../close-cash-point/router';
import LearningComponentsRouter from '../learning-components/router';
import UsersConfigurationRouter from '../users-configuration/router';
import ReceiptsRouter from '../receipts/router';
import TermsConditionsRouter from '../terms-conditions/router';
import AreaRouter from '../areas/router';
import FaqRouter from '../faqs/router';
import HelpCenterRouter from '../help-center/router';
import AssignCoursesPostgradeRouter from '../assign-courses-postgrade/router';
import ChangePeriodRouter from '../change-period/router';
import AcademicPlanningPostgraduateRouter from '../academic-planning-postgraduate/router';
import RequestsStatusRouter from '../requests-status/router';
import StudentsGraduateRouter from '../students_graduate/router';
import LinesOfInvestigationRouter from '../lines-of-investigation/router';
import FollowUpOfResearchProjectRouter from '../follow-up-of-research-project/router';
import PublicationsRouter from '../publications/router';
import TeachersRouter from '../teachers/router';
import PlanningPosgraduateHourhandsRouter from '../academic-planning-postgraduate-hourhands/router';
import PlanningPosgraduateDayTypesRouter from '../academic-planning-postgraduate-day-types/router';
import PlanningPosgraduateCohortRouter from '../academic-planning-postgraduate-cohorte/router';
import BlackboardRouter from '../blackboard/router';
import PosgradatePlanificationListRouter from '../academic-planning-postgraduate/router/PosgraduatePlanificationListRouter';
import PlanningPosgraduateCalendarRouter from '../academic-planning-postgraduate-calendars/router';
import NotificationRouter from '../notifications/router';
import BusRouteRouter from '../bus-route/router';
import AcademicCounselorRouter from '../academic-counselors/router';
import MiProfile from '../my-profile/screens/MiProfile';
import ClientsDiscountRouter from '../clients-discount/router';
import ModulesReportRouter from '../module-reports/router';
import SyllabusRouter from '../syllabus/router';
import CompanyParametersRouter from '../company-parameters/router';
import ContificoMigrationsRouter from '../contifico-migrations/router';
import PermissionsGradesRouter from '../permissions-grades/router';
import ExternalLinkConfigRouter from '../external-link-config/router';

const MaintenanceRoutes = () => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallbackComponentScreen}>
      <Switch>
        <Route path="/administrar-roles" component={RolesRouter} />
        <Route path="/administrar-companias" component={CompaniesRouter} />
        <Route path="/administrar-perfiles" component={ProfilesRouter} />
        <Route path="/administrar-permisos" component={PermissionsRouter} />
        <Route path="/administrar-periodos" component={PeriodsRouter} />
        <Route path="/administrar-paralelos" component={ParallelsRouter} />
        <Route path="/administrar-grupos-economicos" component={EconomicGroupsRouter} />
        <Route path="/administrar-aulas" component={ClassroomsRouter} />
        <Route path="/administrar-institutos" component={InstitutesRouter} />
        <Route path="/administrar-tipos-materias" component={MattersTypesRoutes} />
        <Route path="/administrar-unidades-academicas" component={EducationLevelsRouter} />
        <Route path="/administrar-niveles-educativos" component={AcademicOfferings} />
        <Route path="/administrar-materias" component={MattersRoutes} />
        <Route path="/mallas-curriculares" component={CurriculumsRouter} />
        <Route path="/administrar-pensums" component={PensumsRouter} />
        <Route path="/administrar-tipos-periodos" component={PeriodTypesRouter} />
        <Route path="/administrar-horarios" component={HourhandsRouter} />
        <Route path="/administrar-estudiantes" component={StudentsRouter} />
        <Route path="/graduar-estudiantes" component={StudentsGraduateRouter} />
        <Route path="/administrar-cargos" component={PositionsRouter} />
        <Route path="/administrar-convenios" component={AgreementsRouter} />
        <Route path="/administrar-departamentos" component={DepartmentRouter} />
        <Route path="/administrar-modelo-calificacion" component={CalificationModelRouter} />
        <Route path="/administrar-campus" component={CampusRouter} />
        <Route path="/administrar-distribucion-aulas" component={ClassroomsEducationLvlRouter} />
        <Route path="/configuraciones" component={ConfigurationsRouter} />
        <Route
          path="/administrar-carreras-y-materias-de-nivelacion"
          component={EducationLevelSubjectRouter}
        />
        <Route path="/administrar-carreras-de-nivelacion" component={EducationLevelCareerRouter} />
        <Route path="/administrar-grupo-de-areas" component={GroupsAreaRouter} />
        <Route path="/administrar-cursos" component={CoursesRouter} />
        <Route path="/administrar-homologaciones" component={HomologationsRouter} />
        <Route path="/administrar-eventos" component={EventNewRouter} />
        <Route path="/administrar-registro-nivelacion" component={RegisterNivelationRouter} />
        <Route path="/administrar-requisiciones-docentes" component={RequisitionTeacher} />
        <Route path="/administrar-solicitudes" component={generalApplicationsRouter} />
        <Route path="/administrar-acta-solicitud" component={generalApplicationsRouter} />
        <Route path="/administrar-seguimiento-nivelacion" component={TrackingNivelationRouter} />
        <Route path="/administrar-mantenimiento-categoria" component={MaintenanceCategoryRouter} />
        <Route path="/administrar-mantenimiento-producto" component={MaintenanceProductRouter} />
        <Route path="/administrar-configuracion-productos" component={ConfigProductsRouter} />
        <Route path="/administrar-mantenimiento-categoria" component={MaintenanceCategoryRouter} />
        <Route path="/administrar-mantenimiento-producto" component={MaintenanceProductRouter} />
        <Route path="/administrar-cheques" component={PaychecksRouter} />
        <Route path="/administrar-depositos-transferencias" component={DepositsTransfersRouter} />
        <Route path="/administrar-clientes" component={ClientsRouter} />
        <Route path="/administrar-documentos-electronicos" component={ElectronicDocumentRouter} />
        <Route path="/administrar-recibos" component={ReceiptsRouter} />
        {/* configuraciones financieras */}
        <Route path="/administrar-puntos-cobro" component={FinancialConfigurationRouter} />
        <Route path="/informacion-institucional" component={institutionInfoRouter} />
        <Route path="/administrar-caja/cobrar-deudas" component={DebtsPaymentsRouter} />
        <Route path="/administrar-periodos-financieros" component={FinancialPeriodsRouter} />
        <Route path="/administrar-caja/generar-deudas" component={GenerateDebtsRouter} />
        <Route path="/administrar-caja/generar-nota-credito" component={GenerateCreditNoteRouter} />
        <Route path="/administrar-caja/listar-cajas" component={cashPointRouter} />
        <Route
          path="/administrar-componentes-de-aprendizaje"
          component={LearningComponentsRouter}
        />
        <Route path="/administrar-usuarios" component={UsersConfigurationRouter} />
        <Route path="/terminos-condiciones" component={TermsConditionsRouter} />
        <Route path="/Mi-perfil" component={MiProfile} />
        <Route path="/administrar-areas" component={AreaRouter} />
        <Route path="/administrar-preguntas-frecuentes" component={FaqRouter} />
        <Route path="/centro-asistencia" component={HelpCenterRouter} />
        <Route path="/asignar-cursos-posgrado" component={AssignCoursesPostgradeRouter} />
        <Route path="/cambiar-periodo" component={ChangePeriodRouter} />
        <Route
          path="/planeacion-horarios-posgrado"
          component={PlanningPosgraduateHourhandsRouter}
        />
        <Route
          path="/planeacion-tipos-dia-posgrado"
          component={PlanningPosgraduateDayTypesRouter}
        />
        <Route
          path="/planeacion-academica-posgrado"
          component={AcademicPlanningPostgraduateRouter}
        />

        <Route
          path="/listado-planeacion-academica-posgrado"
          component={PosgradatePlanificationListRouter}
        />

        <Route path="/planeacion-cohorte-posgrado" component={PlanningPosgraduateCohortRouter} />
        <Route
          path="/planeacion-calendario-posgrado"
          component={PlanningPosgraduateCalendarRouter}
        />

        <Route path="/administrar-estados-solicitudes" component={RequestsStatusRouter} />
        <Route path="/administrar-lineas-de-investigacion" component={LinesOfInvestigationRouter} />
        <Route
          path="/seguimiento-proyecto-investigacion"
          component={FollowUpOfResearchProjectRouter}
        />
        <Route path="/publicaciones" component={PublicationsRouter} />
        <Route path="/administrar-docentes" component={TeachersRouter} />
        <Route path="/administrar-consejeros" component={AcademicCounselorRouter} />

        <Route path="/blackboard" component={BlackboardRouter} />

        <Route path="/notificaciones" component={NotificationRouter} />

        <Route path="/bus-route" component={BusRouteRouter} />
        <Route path="/administrar-clientes-descuento" component={ClientsDiscountRouter} />
        <Route path="/reportes" component={ModulesReportRouter} />
        <Route path="/syllabus" component={SyllabusRouter} />
        <Route path="/parametros-companias" component={CompanyParametersRouter} />

        <Route path="/migraciones-contifico" component={ContificoMigrationsRouter} />
        <Route path="/permisos-notas" component={PermissionsGradesRouter} />

        <Route path="/administrar-enlaces-externos" component={ExternalLinkConfigRouter} />
      </Switch>
    </ErrorBoundary>
  );
};

export default MaintenanceRoutes;
