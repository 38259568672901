import { useState, useEffect } from 'react';

import Grid from '@material-ui/core/Grid';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import useTheme from '@material-ui/core/styles/useTheme';
import Button from '@material-ui/core/Button';

import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import {
  renderErrorOrEmptyRow,
  renderSkeletonRows,
  renderSkeletonTable,
} from '../../../../shared/helpers/render';
import { useGetElectronicDocumentsAuthorizedQuery } from '../../../electronic-documents/slices/electronicDocumentsApiSlice';
import CustomPagination from '../../../../shared/components/tables/CustomPagination';
import { ElectronicDocument } from '../../../electronic-documents/interfaces/electronicDocuments.interfaces';
import { setInvoice } from '../../slices/generateCreditNoteSlice';

interface Props {
  handleCloseDialog: (e: React.SyntheticEvent) => void;
}

const InvoicesTable = ({ handleCloseDialog }: Props) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const { currentClient } = useAppSelector((state) => state.generateCreditNote);

  const [selected, setSelected] = useState<ElectronicDocument | null>(null);

  const [page, setPage] = useState(1);
  // const [search, setSearch] = useState('');
  // const [perPage, setPerPage] = useState(5);

  const { data, isLoading, isFetching, error } = useGetElectronicDocumentsAuthorizedQuery(
    {
      page,
      perPage: 10,
      search: currentClient?.pers_identification,
    },
    {
      skip: !currentClient,
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    setPage(1);
  }, [currentClient]);

  // const datafetch = data?.data!.map((dt) => ({
  //   id: dt.id + ',' + dt.establecimiento + '-' + dt.ptoEmision + '-' + dt.secuencial,
  //   person: `${dt.person?.pers_firstname || ''} ${dt.person?.pers_first_lastname || ''}`,
  //   tipo: dt.tipoEmision,
  //   totalDoc: Number(dt.importeTotal).toFixed(2),
  //   DocState: dt.sri_estado_documento,
  //   EmissionDate: dt.fechaEmision,
  //   options: '',
  // }));

  const handleSelect = (e: React.SyntheticEvent) => {
    if (selected) {
      dispatch(setInvoice(selected));
      handleCloseDialog(e);
    }
  };

  const renderRow = (dt: ElectronicDocument) => (
    <TableRow
      hover
      className="table-row"
      key={dt.id.toString()}
      sx={{ cursor: 'pointer', backgroundColor: selected?.id === dt.id ? '#eee' : '' }}
      onClick={() => {
        setSelected(dt);
        // handleSelectdatae(data.id);
      }}
    >
      <TableCell>{dt.establecimiento + '-' + dt.ptoEmision + '-' + dt.secuencial}</TableCell>
      <TableCell>{`${dt.person?.pers_firstname || ''} ${
        dt.person?.pers_first_lastname || ''
      }`}</TableCell>
      <TableCell>{Number(dt.importeTotal).toFixed(2)}</TableCell>
      <TableCell>{dt.fechaEmision}</TableCell>
    </TableRow>
  );

  const renderRows = () => {
    return <>{data?.data?.map(renderRow)}</>;
  };

  return (
    <>
      <Grid container alignContent={'flex-end'} spacing={1}>
        <Grid item xs={12}>
          {isLoading ? (
            renderSkeletonTable(5, 7)
          ) : (
            <>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow sx={{ backgroundColor: theme.palette.grey[200] }}>
                      <TableCell>
                        {t('financial.generate_creditnote.invoices_dialog.table.code')}
                      </TableCell>
                      <TableCell>
                        {t('financial.generate_creditnote.invoices_dialog.table.person')}
                      </TableCell>
                      <TableCell>
                        {t('financial.generate_creditnote.invoices_dialog.table.total')}
                      </TableCell>
                      <TableCell>
                        {t('financial.generate_creditnote.invoices_dialog.table.emission_date')}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isFetching ? renderSkeletonRows(5, 4) : renderRows()}

                    {error && renderErrorOrEmptyRow(5, error as string)}
                    {(data?.data?.length === 0 || !data) && renderErrorOrEmptyRow(5)}
                  </TableBody>
                </Table>
              </TableContainer>

              {!isFetching && !error && data && (
                <Grid container justifyContent="space-between" alignItems="flex-end" sx={{ mt: 4 }}>
                  <Grid item>
                    <CustomPagination
                      shape="rounded"
                      color="primary"
                      count={Math.ceil(data!.last_page)}
                      page={page}
                      onChange={(_, newPage) => {
                        setPage(newPage);
                      }}
                    />
                  </Grid>
                </Grid>
              )}
            </>
          )}
        </Grid>

        <Grid container justifyContent={'flex-end'} spacing={1} mt={1}>
          <Grid item>
            <Button variant="outlined" size="large" onClick={(e) => handleCloseDialog(e)}>
              {t('financial.generate_creditnote.invoices_dialog.table.cancel_btn')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              size="large"
              disabled={!selected}
              onClick={(e) => handleSelect(e)}
            >
              {t('financial.generate_creditnote.invoices_dialog.table.select_btn')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default InvoicesTable;
