import * as Yup from 'yup';

export const MatterTypeInfoSchema = Yup.object().shape({
  name: Yup.string().trim().required('El nombre es requerido.'),
  acronym: Yup.string()
    .trim()
    .min(2, 'El acrónimo puede tener mínimo 2 caracteres')
    .max(3, 'El acrónimo puede tener máximo 3 caracteres')
    .required('El acrónimo es requerido'),
  description: Yup.string().trim(),
  offId: Yup.number().nullable().min(1, 'La oferta es requerida'),
  calificationTypeId: Yup.number().nullable().required('El tipo de calificación es requerido.'),
});
