import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../../../hooks/useRedux';
import MainCard from '../../../../../shared/components/cards/MainCard';
import ScopeDeleteDialog from '../components/ScopeDeleteDialog';
import ScopeSideDialog from '../components/ScopeSideDialog';
import ScopesTable from '../components/ScopesTable';

const ScopesScreen = () => {
  const { t } = useTranslation();

  const { scopesSideDialogOpen, scopesDeleteModalOpen } = useAppSelector((state) => state.scopes);

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">{t('maintenance.scopes.title')}</Typography>
        <Typography variant="body1" color="textSecondary">
          {t('Las secciones permiten clasificar las preguntas de la rúbrica.')}
        </Typography>
      </Grid>

      <ScopesTable />

      {scopesSideDialogOpen && <ScopeSideDialog />}
      {scopesDeleteModalOpen && <ScopeDeleteDialog />}
    </Grid>
  );
};

export default ScopesScreen;
