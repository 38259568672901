import { Redirect, Switch } from 'react-router-dom';

import { useAppSelector } from '../../../../hooks/useRedux';
import GuardRoute from '../../../../router/guards/GuardRoute';
import StudentAcademicHistoryScreen from '../../students/screens/StudentAcademicHistoryScreen';
// import StudentAsignedHistoryAdsScreen from '../../../teachers/academic-couseling/students-asigned/screens/StudentAsignedHistoryAnnouncementsScreen';
// import UserHistoryAnnouncementsScreen from '../../../teachers/academic-couseling/students-asigned/screens/UserHistoryAnnouncementsScreen';
import { subPermissionKeys } from '../constants/permissions';
import AcademicCounselorsScreen from '../screens/AcademicCounselorsScreen';
import CounselorsStudentsScreen from '../screens/CounselorsStudentsScreen';

function AcademicCounselorRouter() {
  // Verify permission
  const { currentModule } = useAppSelector((state) => state.access);
  const hasPermission = subPermissionKeys.groupKey in currentModule?.permissions!;

  if (!hasPermission) return <Redirect to="/" />;

  return (
    <>
      <Switch>
        <GuardRoute
          path="/administrar-consejeros"
          exact
          component={AcademicCounselorsScreen}
          permissionKey={subPermissionKeys.groupKey}
          subPermissionKey={subPermissionKeys.getCollaboratorAdviser}
        />

        <GuardRoute
          path="/administrar-consejeros/:collaboratorId/estudiantes"
          exact
          component={CounselorsStudentsScreen}
          permissionKey={subPermissionKeys.groupKey}
          subPermissionKey={subPermissionKeys.showCollaborator}
        />

        <GuardRoute
          exact
          path={`/administrar-consejeros/:collaboratorId/estudiantes/:studentId/historial-academico`}
          component={StudentAcademicHistoryScreen}
          permissionKey={subPermissionKeys.groupKey}
          subPermissionKey={[
            subPermissionKeys.showStudent,
          ]}
        />

        {/* <GuardRoute
          exact
          path="/administrar-consejeros/:collaboratorId/administrar-estudiantes-asignados/historial-anuncios/:studentId"
          component={StudentAsignedHistoryAdsScreen}
        /> */}
        {/* <GuardRoute
          exact
          path="/administrar-consejeros/:collaboratorId/administrar-estudiantes-asignados/historial-anuncios-enviados"
          component={UserHistoryAnnouncementsScreen}
        /> */}

        {/* <GuardRoute
          exact
          path="/administrar-consejeros/historial-anuncios/:studentId"
          component={StudentAsignedHistoryAdsScreen}
        /> */}
      </Switch>
    </>
  );
}

export default AcademicCounselorRouter;
