import { Avatar, AvatarGroup, Grid } from "@material-ui/core";
import { obtainDomainStorageImage } from "../../../../../shared/helpers";
import { ReceiverElement } from "../../interfaces/student-announcement.interfaces";

interface UserAnnouncement {
    id: number;
    receiver: ReceiverElement[];
    ans_message: string;
    and_date_send: string;
}

const RenderAvatars = (receivers: UserAnnouncement) => {
    return <>
        <Grid item >
            <Grid container>
                <AvatarGroup max={10} >
                    {receivers.receiver.map((value) => (
                        <Avatar
                        sx={{width: '33px', height: '33px'}}
                        key={`avatar-detail-${value.id}-${value.rec_date_read}`}
                        src={obtainDomainStorageImage(value?.receiver?.student?.stud_photo_path!)}>
                        </Avatar>
                    ))}
                </AvatarGroup>
            </Grid>
        </Grid>
    </>
}

export default RenderAvatars