import React, { useState, ChangeEvent, useEffect } from 'react';

import {
  Button,
  DialogActions,
  DialogContent,
  Slide,
  SlideProps,
  Stack,
  Typography,
  Grid,
  TextField,
  FormHelperText,
  InputAdornment,
  CircularProgress,
  Autocomplete,
  Switch,
  Tooltip,
  IconButton,
  // IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import InputLabel from '../../../../shared/components/forms/InputLabel';
import AnimateButton from '../../../../shared/components/extended/AnimateButton';

// import { IconSearch } from '@tabler/icons';

import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import {
  setListLaboralExperiences,
  updateListLaboralExperiences,
} from '../../slices/requisitionsSlice';
import {
  useGetCatalogByAcronymQuery,
  useLazyGetCatalogByAcronymChildrenQuery,
} from '../../../../shared/slices/catalogApi';
import { useSendFilesRequisitionMutation } from '../../slices/requisitionsApiSlice';
import { baseUrl } from '../../../../../api/mainApi';

import ErrorAlert from '../../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../../shared/components/alerts/SuccessAlert';

import { statusAvailables } from '../../../../shared/constants/resourceStatus';
import { IconX } from '@tabler/icons';

import { useFormik } from 'formik';
import * as yup from 'yup';
import ExtendedSideDialog from '../../../../shared/components/dialogs/ExtendedSideDialog';
import { useTranslation } from 'react-i18next';
import { Catalog } from '../../../../shared/interfaces/catalogs.interfaces';
import LocalizedDatePicker from '../../../../shared/components/controlled/LocalizedDatePicker';
import { LaboralExperience } from '../../interfaces/laboralExperience.interface';
import { parseISO } from 'date-fns';

const validationSchema = yup.object({
  jobOrganization: yup
    .string()
    .required('academic.requisition.edit.wizard.step2.add_laboral_experience.dialog.org.required'),
  jobPosition: yup
    .string()
    .required(
      'academic.requisition.edit.wizard.step2.add_laboral_experience.dialog.position.required'
    ),
  jobPhone: yup
    .number()
    .required('academic.requisition.edit.wizard.step2.add_laboral_experience.dialog.phone.required')
    .typeError('academic.requisition.edit.wizard.step2.add_laboral_experience.dialog.phone.valid'),

  jobStartDate: yup
    .date()
    .required(
      'academic.requisition.edit.wizard.step2.add_laboral_experience.dialog.start_date.required'
    ),

  jobEndDate: yup
    .date()
    .required(
      'academic.requisition.edit.wizard.step2.add_laboral_experience.dialog.end_date.required'
    ),

  jobCurrent: yup
    .boolean()
    .required(
      'academic.requisition.edit.wizard.step2.add_laboral_experience.dialog.current_work.required'
    ),
  jobAffilated: yup
    .boolean()
    .required(
      'academic.requisition.edit.wizard.step2.add_laboral_experience.dialog.affiliate.required'
    ),
  institution: yup.string(),
  subjects: yup.string(),
  documentUrl: yup.string(),
  cityId: yup.object({
    id: yup.number(),
    name: yup.string(),
  }),
  statusId: yup
    .number()
    .required(
      'academic.requisition.edit.wizard.step2.add_laboral_experience.dialog.status.required'
    ),
  countryId: yup.number(),
  // .required('academic.requisition.edit.wizard.step2.add_academic_form.dialog.country.required')
  // .min(1, 'academic.requisition.edit.wizard.step2.add_academic_form.dialog.country.required'),
});

const useStyles = makeStyles((theme) => ({
  userAddDialog: {
    '&>div:nth-child(3)': {
      justifyContent: 'flex-end',
      '&>div': {
        margin: '0px',
        borderRadius: '0px',
        maxWidth: '450px',
        maxHeight: '100%',
      },
    },
  },
  paper: {
    minHeight: '100vh',
    width: '100%',
  },
  learningComponents: {
    fontSize: '16px',
    fontWeight: 600,
    color: theme.palette.primary.main,
    marginBottom: '8px',
  },
  selectSubject: {
    fontSize: '16px',
    fontWeight: 600,
    color: theme.palette.primary[800],
    marginBottom: '8px',
  },
  selectSubjectType: {
    fontSize: '14px',
    fontWeight: 600,
    color: theme.palette.secondary.main,
    marginBottom: '8px',
  },
}));

const Transition = React.forwardRef(function Transition(props: SlideProps, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

interface Props {
  open: boolean;
  handleCloseDialog: () => void;
  laboralExperience?: LaboralExperience;
}

const LaboralExperienceDialog = ({ open, handleCloseDialog, laboralExperience }: Props) => {
  const { t } = useTranslation();

  const classes = useStyles();
  const dispatch = useAppDispatch();

  const { currentProfile } = useAppSelector((state) => state.access);

  const [availablesCities, setAvailablesCities] = useState<Catalog[]>([]);

  const profileId = currentProfile?.profileId!;

  const [
    sendFile,
    { isLoading: isLoadingFile, isError: isErrorFile, isSuccess: isSuccessFile, error },
  ] = useSendFilesRequisitionMutation();

  const { data: countries = [], isLoading: isLoadingCountries } = useGetCatalogByAcronymQuery({
    profileId,
    acronym: 'PS',
  });

  const [getProvinces, { isLoading: isLoadingCities }] = useLazyGetCatalogByAcronymChildrenQuery();

  const { errors, touched, handleSubmit, getFieldProps, setFieldValue, values, resetForm } =
    useFormik({
      initialValues: {
        jobOrganization: laboralExperience?.per_job_organization || '',
        jobPosition: laboralExperience?.per_job_position || '',
        jobPhone: laboralExperience?.per_job_phone || '',
        jobStartDate: laboralExperience?.per_job_start_date
          ? parseISO(laboralExperience?.per_job_start_date as string)
          : new Date(),
        jobEndDate: laboralExperience?.per_job_end_date
          ? parseISO(laboralExperience?.per_job_end_date as string)
          : new Date(),
        jobCurrent: Boolean(laboralExperience?.per_job_current) || false,
        jobAffilated: Boolean(laboralExperience?.per_job_iess_affiliated) || false,
        institution: laboralExperience?.name_institution || '',
        subjects: laboralExperience?.subjects_taught || '',
        documentUrl: '',
        cityId: laboralExperience?.city_id || { id: 0, name: '' },
        statusId: 1,
        countryId: laboralExperience?.country_id || 0,
      },
      validationSchema: validationSchema,
      onSubmit: async (values) => {
        var sendFileW;

        if (target) {
          sendFileW = await sendFile({
            filePayload: {
              files: target!,
              type_document: 6,
            },
            profileId,
          }).unwrap();
        }

        const dataArray = sendFileW?.split('/');

        const item = {
          id: laboralExperience?.id || Date.now(),
          per_job_organization: values.jobOrganization,
          per_job_position: values.jobPosition,
          per_job_phone: values.jobPhone.toString(),
          per_job_start_date: values.jobStartDate.toISOString().split('T')[0],
          per_job_end_date: values.jobEndDate.toISOString().split('T')[0],
          per_job_current: values.jobCurrent === true ? 1 : 0,
          per_job_iess_affiliated: values.jobAffilated === true ? 1 : 0,
          document_url: dataArray
            ? `${baseUrl}/show?name=${dataArray[0]}/${dataArray[1]}/${dataArray[2]}`
            : '',
          city_id: values.cityId,
          name_institution: values.institution,
          subjects_taught: values.subjects,
          status_id: values.statusId,
          country_id: values.countryId,
        };

        if (laboralExperience?.id) {
          dispatch(updateListLaboralExperiences(item));
        } else {
          dispatch(setListLaboralExperiences([item]));
        }
        resetForm();
        handleCloseDialog();
      },
    });

  const [showAlerts, setShowAlerts] = useState(true);

  const [, setFile] = useState('');

  const [target, setTarget] = useState<File>(null!);

  const onChangePicture = function (event: ChangeEvent<HTMLInputElement>) {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e: any) => {
        setFile(e.target.result);
      };
      reader.readAsDataURL(event.target.files[0]);
      setTarget(event.target.files[0]);
    }
  };

  const cleanFiles = () => {
    setFile('');
    setTarget(null!);
    setFieldValue('documentUrl', '');
  };

  useEffect(() => {
    if (values.countryId && values.countryId !== 0) {
      getProvinces({
        profileId,
        acronym: countries?.find((country) => country.id === values.countryId)?.cat_keyword!,
      })
        .unwrap()
        .then((provinces) => {
          const cities = provinces?.reduce((acc: Catalog[], province) => {
            return [...acc, ...province.children];
          }, []);
          setAvailablesCities(cities);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.countryId]);

  return (
    <ExtendedSideDialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => {
        handleCloseDialog();
      }}
      className={classes.userAddDialog}
      classes={{
        paper: classes.paper,
      }}
    >
      {/* <PerfectScrollbar> */}
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Typography variant="h2">
            {t('academic.requisition.edit.wizard.step2.laboral_experience')}{' '}
          </Typography>

          <Stack spacing={2} sx={{ mt: 4 }}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <InputLabel>
                  {t('academic.requisition.edit.wizard.step2.add_laboral_experience.dialog.org')}
                </InputLabel>
                <TextField
                  fullWidth
                  placeholder={t(
                    'academic.requisition.edit.wizard.step2.add_laboral_experience.dialog.org.placeholder'
                  )}
                  {...getFieldProps('jobOrganization')}
                  error={Boolean(errors.jobOrganization && touched.jobOrganization)}
                />
                {errors.jobOrganization && touched.jobOrganization && (
                  <FormHelperText error>{t(errors.jobOrganization)}</FormHelperText>
                )}
              </Grid>
              <Grid item xs={12}>
                <InputLabel>
                  {t(
                    'academic.requisition.edit.wizard.step2.add_laboral_experience.dialog.position'
                  )}
                </InputLabel>
                <TextField
                  fullWidth
                  placeholder={t(
                    'academic.requisition.edit.wizard.step2.add_laboral_experience.dialog.position.placeholder'
                  )}
                  {...getFieldProps('jobPosition')}
                  error={Boolean(errors.jobPosition && touched.jobPosition)}
                />
                {errors.jobPosition && touched.jobPosition && (
                  <FormHelperText error>{t(errors.jobPosition)}</FormHelperText>
                )}
              </Grid>
              <Grid item xs={12}>
                <InputLabel>
                  {t('academic.requisition.edit.wizard.step2.add_academic_form.dialog.country')}
                </InputLabel>
                {isLoadingCountries ? (
                  <TextField
                    disabled
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <CircularProgress size={25} />
                        </InputAdornment>
                      ),
                    }}
                    placeholder={t(
                      'academic.requisition.edit.wizard.step2.add_academic_form.dialog.country.loading'
                    )}
                  />
                ) : (
                  <Autocomplete
                    options={countries}
                    loading={isLoadingCountries}
                    loadingText={t(
                      'academic.requisition.edit.wizard.step2.add_academic_form.dialog.country.loading'
                    )}
                    noOptionsText={t(
                      'academic.requisition.edit.wizard.step2.add_academic_form.dialog.country.no_data'
                    )}
                    getOptionLabel={(option) => option.cat_name}
                    value={countries.find((e) => e.id === values.countryId) || null}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={t(
                          'academic.requisition.edit.wizard.step2.add_academic_form.dialog.country.placeholder'
                        )}
                        error={Boolean(errors.countryId && touched.countryId)}
                      />
                    )}
                    onChange={(e, value) => setFieldValue('countryId', value?.id)}
                    fullWidth
                    clearIcon={null}
                  />
                )}
              </Grid>

              <Grid item xs={12}>
                <InputLabel>
                  {t('academic.requisition.edit.wizard.step2.add_academic_form.dialog.city')}
                </InputLabel>
                {isLoadingCities ? (
                  <>
                    <TextField
                      disabled
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <CircularProgress size={25} />
                          </InputAdornment>
                        ),
                      }}
                      placeholder={t(
                        'academic.requisition.edit.wizard.step2.add_academic_form.dialog.city.loading'
                      )}
                    />
                  </>
                ) : (
                  <>
                    <Autocomplete
                      options={availablesCities}
                      loading={isLoadingCities}
                      loadingText={t(
                        'academic.requisition.edit.wizard.step2.add_academic_form.dialog.city.loading'
                      )}
                      noOptionsText={t(
                        'academic.requisition.edit.wizard.step2.add_academic_form.dialog.city.no_data'
                      )}
                      getOptionLabel={(option) => option.cat_name}
                      value={availablesCities.find((e) => e.id === values.cityId.id) || null}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={t(
                            'academic.requisition.edit.wizard.step2.add_academic_form.dialog.city.placeholder'
                          )}
                          error={Boolean(errors.cityId && touched.cityId)}
                        />
                      )}
                      onChange={(e, value) =>
                        setFieldValue('cityId', {
                          id: value?.id,
                          name: value?.cat_name,
                        })
                      }
                      fullWidth
                      clearIcon={null}
                    />

                    {errors && touched.cityId && (
                      <FormHelperText error>{t(errors.cityId as string)}</FormHelperText>
                    )}
                  </>
                )}
              </Grid>
              <Grid item xs={12}>
                <InputLabel>
                  {t('academic.requisition.edit.wizard.step2.add_laboral_experience.dialog.phone')}
                </InputLabel>
                <TextField
                  fullWidth
                  placeholder={t(
                    'academic.requisition.edit.wizard.step2.add_laboral_experience.dialog.phone.placeholder'
                  )}
                  inputProps={{
                    maxLength: 14,
                  }}
                  {...getFieldProps('jobPhone')}
                  error={Boolean(errors.jobPhone && touched.jobPhone)}
                />
                {errors.jobPhone && touched.jobPhone && (
                  <FormHelperText error>{t(errors.jobPhone)}</FormHelperText>
                )}
              </Grid>
              <Grid item xs={12}>
                <InputLabel>
                  {t(
                    'academic.requisition.edit.wizard.step2.add_laboral_experience.dialog.start_date'
                  )}
                </InputLabel>
                <LocalizedDatePicker
                  value={values.jobStartDate}
                  onChange={(newValue) => {
                    setFieldValue('jobStartDate', newValue);
                  }}
                  helperText=""
                  placeholder={t(
                    'academic.requisition.edit.wizard.step2.add_laboral_experience.dialog.start_date.placeholder'
                  )}
                  error={Boolean(errors.jobStartDate && touched.jobStartDate)}
                />

                {errors.jobStartDate && touched.jobStartDate && (
                  <FormHelperText error>{t(errors.jobStartDate as string)}</FormHelperText>
                )}
              </Grid>
              <Grid item xs={12}>
                <InputLabel>
                  {t(
                    'academic.requisition.edit.wizard.step2.add_laboral_experience.dialog.current_work'
                  )}
                </InputLabel>
                <Switch checked={values.jobCurrent} {...getFieldProps('jobCurrent')} />
              </Grid>
              {!values.jobCurrent && (
                <Grid item xs={12}>
                  <InputLabel>
                    {t(
                      'academic.requisition.edit.wizard.step2.add_laboral_experience.dialog.end_date'
                    )}
                  </InputLabel>
                  <LocalizedDatePicker
                    value={values.jobEndDate}
                    onChange={(newValue) => {
                      setFieldValue('jobEndDate', newValue);
                    }}
                    helperText=""
                    placeholder={t(
                      'academic.requisition.edit.wizard.step2.add_laboral_experience.dialog.end_date.placeholder'
                    )}
                    error={Boolean(errors.jobEndDate && touched.jobEndDate)}
                  />

                  {errors.jobEndDate && touched.jobEndDate && (
                    <FormHelperText error>{t(errors.jobEndDate as string)}</FormHelperText>
                  )}
                </Grid>
              )}
              <Grid item xs={12}>
                <InputLabel>
                  {t(
                    'academic.requisition.edit.wizard.step2.add_laboral_experience.dialog.affiliate'
                  )}
                </InputLabel>
                <Switch checked={values.jobAffilated} {...getFieldProps('jobAffilated')} />
              </Grid>
              <Grid item xs={12}>
                <InputLabel>
                  {t(
                    'academic.requisition.edit.wizard.step2.add_laboral_experience.dialog.institution'
                  )}
                </InputLabel>
                <TextField
                  fullWidth
                  placeholder={t(
                    'academic.requisition.edit.wizard.step2.add_laboral_experience.dialog.institution.placeholder'
                  )}
                  {...getFieldProps('institution')}
                  error={Boolean(errors.institution && touched.institution)}
                />
                {errors.institution && touched.institution && (
                  <FormHelperText error>{t(errors.institution)}</FormHelperText>
                )}
              </Grid>
              <Grid item xs={12}>
                <InputLabel>
                  {t(
                    'academic.requisition.edit.wizard.step2.add_laboral_experience.dialog.subjects'
                  )}
                </InputLabel>
                <TextField
                  fullWidth
                  placeholder={t(
                    'academic.requisition.edit.wizard.step2.add_laboral_experience.dialog.subjects.placeholder'
                  )}
                  maxRows={3}
                  multiline
                  {...getFieldProps('subjects')}
                  error={Boolean(errors.subjects && touched.subjects)}
                />
                {errors.subjects && touched.subjects && (
                  <FormHelperText error>{t(errors.subjects)}</FormHelperText>
                )}
              </Grid>
              <Grid item xs={12}>
                <InputLabel>
                  {t(
                    'academic.requisition.edit.wizard.step2.add_laboral_experience.dialog.document'
                  )}
                </InputLabel>
                {isErrorFile && showAlerts && (
                  <ErrorAlert
                    message={error as string}
                    handleDismiss={() => setShowAlerts(false)}
                  />
                )}
                {isSuccessFile && showAlerts && (
                  <SuccessAlert
                    message={t(
                      'academic.requisition.edit.wizard.step2.add_laboral_experience.dialog.document.success'
                    )}
                    handleDismiss={() => setShowAlerts(false)}
                  />
                )}
                <TextField
                  sx={{ overflow: 'hidden' }}
                  fullWidth
                  value={values.documentUrl}
                  placeholder={t(
                    'academic.requisition.edit.wizard.step2.add_laboral_experience.dialog.document.placeholder'
                  )}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <label htmlFor="contained-button-file2">
                          <input
                            style={{ display: 'none' }}
                            accept="image/*"
                            id="contained-button-file2"
                            type="file"
                            onChange={(event) => {
                              onChangePicture(event);
                              setFieldValue('documentUrl', event.target.files![0]?.name);
                            }}
                          />
                          <Button
                            variant="contained"
                            component="span"
                            disabled={isLoadingFile}
                            endIcon={isLoadingFile && <CircularProgress size={20} />}
                            sx={{
                              height: '50px',
                              borderRadius: '12px 00px 0px 12px',
                              marginLeft: -1.8,
                            }}
                          >
                            {t(
                              'academic.requisition.edit.wizard.step2.add_laboral_experience.dialog.document.upload'
                            )}
                          </Button>
                        </label>
                      </InputAdornment>
                    ),
                    endAdornment: target && (
                      <InputAdornment position="end" onClick={() => cleanFiles()}>
                        <Tooltip
                          title={t(
                            'academic.requisition.edit.wizard.step2.add_laboral_experience.dialog.document'
                          )}
                        >
                          <IconButton>
                            <IconX size={16} />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                  error={Boolean(errors.documentUrl && touched.documentUrl)}
                />
                {errors.documentUrl && touched.documentUrl && (
                  <FormHelperText error>{t(errors.documentUrl)}</FormHelperText>
                )}
              </Grid>
              {false && (
                <Grid item xs={12}>
                  <InputLabel>
                    {t(
                      'academic.requisition.edit.wizard.step2.add_laboral_experience.dialog.status'
                    )}
                  </InputLabel>
                  <Autocomplete
                    options={statusAvailables}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField {...params} error={Boolean(errors.statusId && touched.statusId)} />
                    )}
                    onChange={(e, value) => setFieldValue('statusId', value?.id)}
                    defaultValue={statusAvailables[0]}
                    fullWidth
                    clearIcon={null}
                  />
                  {errors.statusId && touched.statusId && (
                    <FormHelperText error>{t(errors.statusId as string)}</FormHelperText>
                  )}
                </Grid>
              )}
            </Grid>
          </Stack>
        </DialogContent>
        {/* </PerfectScrollbar> */}
        <DialogActions>
          <Stack direction="row" spacing={2}>
            <Button
              variant="text"
              onClick={() => {
                handleCloseDialog();
              }}
              color="primary"
            >
              {t('academic.requisition.edit.wizard.step2.add_laboral_experience.dialog.cancel')}
            </Button>

            <AnimateButton>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isLoadingFile}
                endIcon={isLoadingFile && <CircularProgress size={20} />}
              >
                {laboralExperience
                  ? t('academic.requisition.edit.wizard.step2.add_laboral_experience.dialog.edit')
                  : t('academic.requisition.edit.wizard.step2.add_laboral_experience.dialog.add')}
              </Button>
            </AnimateButton>
          </Stack>
        </DialogActions>
      </form>
    </ExtendedSideDialog>
  );
};

export default LaboralExperienceDialog;
