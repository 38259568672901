import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import MainCard from '../../../../shared/components/cards/MainCard';
import { useTranslation } from 'react-i18next';

// import CourseDetailScreen from "../../header/CourseDetailScreen"

import EvaluationList from '../components/EvaluationsList';
import EvaluationDeleteDialog from '../components/dialogs/EvaluationDeleteDialog';
import { useAppSelector } from '../../../../../hooks/useRedux';

const EvaluationsListScreen = function () {
  const { t } = useTranslation();

  const { deleteModalOpen } = useAppSelector((state) => state.evaluationTeacher.evaluation);

  return (
    <React.Fragment>
      <Grid
        component={MainCard}
        sx={{
          borderTopLeftRadius: '0px',
          borderTopRightRadius: '0px',
        }}
      >
        <Grid sx={{ mb: 3 }}>
          <Typography variant="h2">{t('teacherVirtualClassroom.tests.title')}</Typography>
        </Grid>

        <EvaluationList />

        {deleteModalOpen && <EvaluationDeleteDialog />}
      </Grid>
    </React.Fragment>
  );
};

export default EvaluationsListScreen;
