import * as Yup from 'yup';

export const AssignDiscountSchema = Yup.object().shape({
  group_configuration: Yup.object()
    .nullable()
    .required('financial.client_list.form.group_config_required'),
  student_record: Yup.object()
    .nullable()
    .required('financial.client_list.form.student_record_required'),
});
