import { Autocomplete, Grid, TextField } from '@material-ui/core';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// import { useNavigate } from 'react-router-dom';
import { useGetQuestionsQuery } from '../slices/questionApiSlice';
import {
  setQuestionsDeleteModalOpen,
  setQuestionsIdSelected,
  setQuestionsIdToDelete,
  setQuestionsList,
  setQuestionsSideDialogOpen,
  setQuestionsTable,
  setScopeIdSelected,
  setStatusQuestions,
} from '../slices/questionSlice';
import { useGetAllScopesQuery } from '../../scope/slices/scopeApiSlice';
import { useTableSearch } from '../../../../../../hooks/useTableSearch';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/useRedux';
import EditButton from '../../../../../shared/components/tables/buttons/EditButton';
import DeleteButton from '../../../../../shared/components/tables/buttons/DeleteButton';
import AddButton from '../../../../../shared/components/tables/buttons/AddButton';
import SearchPaginatedTable from '../../../../../shared/components/tables/SearchPaginatedTable';
import StatusChip from '../../../../../shared/components/badges/StatusChip';

const QuestionsTable = () => {
  const { t } = useTranslation();
  // const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    questionsList,
    scopeIdSelected,
    status,
    questionsTable: { page, perPage, search, filterArrow },
  } = useAppSelector((state) => state.questions);

  const { setPage, setPerPage, setSearch, setFilterArrow } = useTableSearch({
    page,
    perPage,
    search,
    filterArrow,
    updateFunction: setQuestionsTable,
  });

  const { data, isLoading, isFetching, error } = useGetQuestionsQuery({
    page,
    perPage,
    search,
    ...(filterArrow && { sort: filterArrow.name, typeSort: filterArrow.direction }),
    ...(scopeIdSelected && { scopeId: scopeIdSelected }),
    ...(status && { status }),
  });

  const { data: scopes, isLoading: isLoadingS } = useGetAllScopesQuery({});

  useEffect(() => {
    if (data?.data) {
      dispatch(setQuestionsList(data?.data));
    }
  }, [data, dispatch]);

  const statusOptions = [
    { value: 'A', label: t('maintenance.scopes.status.active') },
    { value: 'I', label: t('maintenance.scopes.status.inactive') },
  ];

  const datafetch = questionsList.map((item, idx) => ({
    id: (page - 1) * perPage + (idx + 1),
    name: item.name,
    // description: item.description,
    scope_id: item.ambitos.name,
    status: item.status,
    options: { id: item.uuid },
  }));

  const goToAdd = () => {
    // navigate('agregar');
    dispatch(setQuestionsSideDialogOpen(true));
  };

  const handleEdit = (id: string) => {
    // navigate(`${id}/editar`);
    dispatch(setQuestionsIdSelected(id));
    dispatch(setQuestionsSideDialogOpen(true));
  };

  const handleDelete = (id: string) => {
    dispatch(setQuestionsIdToDelete(id));
    dispatch(setQuestionsDeleteModalOpen(true));
  };

  return (
    <SearchPaginatedTable
      data={datafetch!}
      headers={{
        id: 'N°',
        name: t('maintenance.questions.name'),
        // description: t('maintenance.questions.description'),
        scope_id: t('maintenance.questions.scope'),
        status: t('maintenance.scopes.status'),
        options: t('maintenance.questions.options'),
      }}
      listFilterArrows={{
        // description: 'description',
        status: 'status',
      }}
      filterArrow={filterArrow}
      setFilterArrow={setFilterArrow}
      keyExtractor={(item) => item.id!.toString()}
      searchPlacehoder={t('maintenance.questions.search')}
      isLoading={isLoading}
      isFetching={isFetching}
      error={error as any}
      perPage={perPage}
      setPerPage={setPerPage}
      total={data?.total || 0}
      page={page}
      setPage={setPage}
      numHeader={6}
      search={search}
      setSearch={setSearch}
      customDataCellsProperties={{
        id: {
          align: 'center',
        },
        // description: {
        //   align: 'center',
        // },
        scope_id: {
          align: 'center',
        },
        status: {
          align: 'center',
        },
        options: {
          align: 'center',
        },
      }}
      customRenderers={{
        // eslint-disable-next-line react/no-unstable-nested-components
        status: (row) => (
          <>
            <StatusChip statusId={row.status === 'A' ? 1 : 2} />
          </>
        ),
        options: (row) => (
          <>
            <EditButton handleEdit={() => handleEdit(row.options.id!)} />
            <DeleteButton handleDelete={() => handleDelete(row.options.id!)} />
          </>
        ),
      }}
      ActionButtons={<AddButton size="large" onClick={goToAdd} />}
      additionalFilters={
        <>
          <Grid container spacing={1}>
            <Grid item xs={12} md={4}>
              <Autocomplete
                options={scopes || []}
                getOptionLabel={(option) => option.name || ''}
                loading={isLoadingS}
                onChange={(_, value) => {
                  dispatch(setScopeIdSelected(value?.uuid || ''));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('maintenance.questions.scope')}
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Autocomplete
                options={statusOptions}
                value={statusOptions.find((item) => item.value === status)}
                onChange={(_, value) => {
                  dispatch(setStatusQuestions(value?.value!));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('maintenance.scopes.form.status')}
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </Grid>
          </Grid>
        </>
      }
    />
  );
};

export default QuestionsTable;
