import { useHistory, Link, useRouteMatch } from 'react-router-dom';
import EditButton from '../../../shared/components/tables/buttons/EditButton';
import DeleteButton from '../../../shared/components/tables/buttons/DeleteButton';
import AddButton from '../../../shared/components/tables/buttons/AddButton';
import { openDeleteModalWith, setClassificationToEdit } from '../slices/classificationsSlice';
import useGuard from '../hooks/useGuard';
import SearchPaginatedTable, {
  FilterArrow,
} from '../../../shared/components/tables/SearchPaginatedTable';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';

import { useGetClassificationsQuery } from '../slices/classificationsApiSlice';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const ClassificationsTable = () => {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(5);
  const [search, setSearch] = useState('');
  const [filterArrow, setFilterArrow] = useState<FilterArrow>(null!);
  const { t } = useTranslation();

  const { classificationToDelete } = useAppSelector((state) => state.classifications);

  const {
    data: classifications,
    isLoading,
    isFetching,
    isError,
    error,
    refetch,
  } = useGetClassificationsQuery(
    {
      page,
      size,
      search,
      parent_id: 1,
      ...(filterArrow && { sort: filterArrow.name, type_sort: filterArrow.direction }),
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const { data: classificationsParents } = useGetClassificationsQuery({});

  const dispatch = useAppDispatch();

  const { hasAccess } = useGuard();
  const { path } = useRouteMatch();
  const history = useHistory();

  const handleDelete = async (classificationId: string) => {
    dispatch(openDeleteModalWith(classificationId));
  };

  const goToAdd = async () => {
    await dispatch(setClassificationToEdit(undefined));
    history.push(`${path}/agregar`);
  };

  useEffect(() => {
    if (classificationToDelete === undefined) {
      refetch();
    }
  }, [classificationToDelete, refetch]);

  return (
    <SearchPaginatedTable
      data={
        classifications?.data
          //.filter((cl) => Boolean(cl.parent_id))
          .map((cl, index) => ({
            id: Number(classifications?.from) + index,
            code: cl.code,
            name: cl.name,
            classifications:
              classificationsParents?.data?.find((cl2) => cl2.id === cl.parent_id)?.name || '',
            options: '',
          })) || []
      }
      headers={{
        id: 'Nº',
        code: 'Código',
        name: 'Nombre',
        classifications: 'Materia',
        options: t('shared.search_paginated_table.table.options'),
      }}
      keyExtractor={(item) => item.id.toString()}
      searchPlacehoder={t('classifications.search')}
      listFilterArrows={{
        name: 'name',
        code: 'code',
      }}
      filterArrow={filterArrow}
      setFilterArrow={setFilterArrow}
      isLoading={isLoading}
      isFetching={isFetching}
      error={isError ? error : undefined}
      perPage={size}
      setPerPage={setSize}
      //total={classifications?.total!}
      total={classifications?.total!}
      page={page}
      setPage={setPage}
      numHeader={5}
      search={search}
      setSearch={setSearch}
      customRenderers={{
        // id: (row) => row.id.id,

        options: (row) => (
          <>
            {hasAccess('edit') && (
              <Link to={`${path}/editar`}>
                <EditButton
                  handleEdit={() => {
                    dispatch(
                      //setClassificationToEdit(classifications?.data.find((cl) => cl.id === row.id))
                      setClassificationToEdit(
                        classifications?.data
                          .filter((cl) => Boolean(cl.parent_id))
                          .find((_, index) => index === row.id - Number(classifications?.from))
                      )
                    );
                  }}
                />
              </Link>
            )}
            {hasAccess('delete') && (
              <DeleteButton
                handleDelete={() =>
                  handleDelete(
                    classifications?.data.find(
                      (_, index) => index === row.id - Number(classifications?.from)
                    )?.id!
                  )
                }
              />
            )}
            {!hasAccess('edit') && !hasAccess('delete') && 'Ninguna'}
          </>
        ),
      }}
      ActionButtons={<>{hasAccess('create') && <AddButton size="large" onClick={goToAdd} />}</>}
      customDataCellsProperties={{
        id: { align: 'center' },
        code: { align: 'center' },
        name: { align: 'center' },
        classifications: { align: 'center' },
        options: { align: 'center' },
      }}
      customHeadersCellsProperties={{
        name: { align: 'center' },
      }}
    />
  );
};

export default ClassificationsTable;
