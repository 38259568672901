import { Grid, Typography } from '@material-ui/core';
import MainCard from '../../../shared/components/cards/MainCard';
import RolesTable from '../components/RolesTable';

const AdministrationRolesScrenn = () => {
   return (
      <Grid component={MainCard} sx={{ padding: 2 }}>
         <Grid sx={{ mb: 3 }}>
            <Typography variant="h2">Administración</Typography>
            <Typography variant="h2" color="gray" fontWeight={400}>Roles</Typography>
            <br />
            <RolesTable />

         </Grid>

      </Grid>
   )
}

export default AdministrationRolesScrenn;