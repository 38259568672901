import { Grid } from '@material-ui/core';
import FaqItem from './FaqItem';
import { faqsEcotec } from './faqsEcotec';

const FaqsList = () => {
  return (
    <Grid container spacing={1}>
      {faqsEcotec.map((faq, index) => (
        <Grid item xs={12}>
          <FaqItem faq={faq} index={index} />
        </Grid>
      ))}
    </Grid>
  );
};

export default FaqsList;
