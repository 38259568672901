import { Switch, Redirect } from 'react-router-dom';
import { useAppSelector } from '../../../../hooks/useRedux';
import GuardRoute from '../../../../router/guards/GuardRoute';
import { permissionKey } from '../constants/permissions';
import AddTagScreen from '../screens/AddTagScreen';
import EditTagScreen from '../screens/EditTagScreen';
import TagsScreen from '../screens/TagsScreen';

const TagsRouter = () => {
  const { currentModule } = useAppSelector((state) => state.access);

  const hasPermission = permissionKey in currentModule!.permissions;

  if (!hasPermission) return <Redirect to="/dashboard" />;

  return (
    <Switch>
      <GuardRoute exact path="/administrar-etiquetas" component={TagsScreen} />
      <GuardRoute exact path="/administrar-etiquetas/agregar" component={AddTagScreen} />
      <GuardRoute exact path="/administrar-etiquetas/editar" component={EditTagScreen} />
    </Switch>
  );
};

export default TagsRouter;
