import {
  Alert,
  AlertTitle,
  Avatar,
  Button,
  CardActions,
  CircularProgress,
  Dialog,
  DialogProps,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { ChangeEvent, useState } from 'react';
import useFileFormatValidation from '../../../../../hooks/useFileFormatValidation';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import { setCurrentProfile } from '../../../reducers/accessReducer';
import { useAddPhotoPersonMutation } from '../../../slices/searchPersonApi';
import ErrorAlert from '../../alerts/ErrorAlert';
import SuccessAlert from '../../alerts/SuccessAlert';
import MainCard from '../../cards/MainCard';
import AnimateButton from '../../extended/AnimateButton';

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: '100px',
    height: '100px',
    margin: '0 auto',
  },
  paper: { maxWidth: '600px', width: '90%' },
}));

interface Props extends DialogProps {
  onClose: () => void;
}

const PersonChangePhoto = ({ onClose, ...props }: Props) => {
  const classes = useStyles();

  const dispatch = useAppDispatch();

  const { currentProfile, defaultConfiguration } = useAppSelector((state) => state.access);

  const [showAlerts, setShowAlerts] = useState<boolean>(false);

  const [showFileAlert, setShowFileAlert] = useState<{
    message: string;
    open: boolean;
  }>({
    message: '',
    open: false,
  });

  const [addPhoto, { isLoading, isSuccess, isError, error }] = useAddPhotoPersonMutation();

  const [file, setFile] = useState('');

  const [target, setTarget] = useState<File>(null!);

  const { validateFormatFile } = useFileFormatValidation();

  const acceptedFileTypes = 'image/*';

  const onChangePicture = function (event: ChangeEvent<HTMLInputElement>) {
    setShowFileAlert({
      message: '',
      open: false,
    });

    if (event.target.files && event.target.files[0]) {
      const message = validateFormatFile(event.target.files[0], acceptedFileTypes);

      if (message !== '') {
        setShowFileAlert({
          message,
          open: true,
        });
        return;
      }

      let reader = new FileReader();
      reader.onload = (e: any) => {
        setFile(e.target.result);
      };
      reader.readAsDataURL(event.target.files[0]);
      setTarget(event.target.files[0]);
    }
  };

  const handleAddAvatar = async () => {
    setShowAlerts(true);

    const result: { data: string } | { error: FetchBaseQueryError | SerializedError } =
      await addPhoto({
        file: target!,
        userProfileId: currentProfile?.profileId!,
      });

    if ('error' in result === false) {
      const photo = 'data' in result ? result.data : '';
      const profileAddedImage = [currentProfile]?.map((e) => ({ ...e!, imgProfile: photo }))[0]!;
      console.log({ profileAddedImage });
      dispatch(setCurrentProfile(profileAddedImage));
    }
  };

  return (
    <Dialog {...props} classes={{ paper: classes.paper }} sx={{ p: 3 }} onClose={onClose}>
      <MainCard title="Cambiar foto">
        {showAlerts && isSuccess && (
          <Grid item xs={12}>
            <SuccessAlert
              message="Imagen subida con éxito."
              handleDismiss={() => setShowAlerts(false)}
            />
          </Grid>
        )}

        {showAlerts && isError && (
          <Grid item xs={12}>
            <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
          </Grid>
        )}

        {showFileAlert.open && (
          <Grid item xs={12}>
            <ErrorAlert
              message={showFileAlert.message}
              handleDismiss={() =>
                setShowFileAlert({
                  message: '',
                  open: false,
                })
              }
            />
          </Grid>
        )}

        <Grid container gap={4} sx={{ p: 2 }}>
          <Grid item container xs={12} alignItems="center" justifyContent="center">
            <Grid item xs={12} style={{ marginBottom: 30 }}>
              <Avatar
                className={classes.avatar}
                alt="foto de perfil"
                sx={{ bgcolor: 'grey', color: 'white' }}
                style={{ cursor: 'pointer' }}
                src={file}
              />
            </Grid>
            <Grid>
              {!defaultConfiguration.redLinksPhotoId ? (
                <AnimateButton>
                  <Button
                    disabled={false}
                    style={{ marginBottom: 50 }}
                    variant="contained"
                    color="primary"
                    component={'label'}
                    size="small"
                  >
                    {!file ? ' Seleccionar foto' : 'Cambiar foto'}
                    <input
                      accept=".png, .jpg, .jpeg"
                      type="file"
                      id="btnFile"
                      hidden
                      onChange={onChangePicture}
                    />
                  </Button>
                </AnimateButton>
              ) : (
                <Alert severity="warning">
                  <AlertTitle>Atención</AlertTitle>
                  No se permite realizar cambios a la foto de perfil, acercate a un punto de Redlinks para obtener tu carnet.
                </Alert>
              )}
            </Grid>
            <Grid item container xs={12} justifyContent="center">
              <Grid item xs={8}>
                <Typography variant="subtitle1" textAlign="center">
                  Tamaño máximo de archivo: {defaultConfiguration.file_size} MB
                </Typography>
                <Typography variant="subtitle1" textAlign="center">
                  Formatos permitidos: .png, .jpg, .jpeg
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Divider />
        <CardActions>
          <Grid container justifyContent="right">
            <Grid item>
              <Button
                variant="text"
                size="large"
                color="primary"
                onClick={onClose}
                disabled={isLoading}
              >
                No, Cancelar
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                size="large"
                onClick={handleAddAvatar}
                color="primary"
                autoFocus
                disabled={isLoading || !file}
                endIcon={isLoading && <CircularProgress size={20} />}
              >
                Sí, agregar
              </Button>
            </Grid>
          </Grid>
        </CardActions>
      </MainCard>
    </Dialog>
  );
};

export default PersonChangePhoto;
