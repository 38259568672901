import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BookItem } from '../../books/interfaces/books.response.interface';

interface ThesisState {
  thesisToEdit?: BookItem;
  deleteModalOpen: boolean;
  thesisToDelete?: number;
  studentId?: number;
}

const initialState: ThesisState = {
  deleteModalOpen: false,
};

const thesisSlice = createSlice({
  name: 'thesis',
  initialState,
  reducers: {
    setThesisToEdit: (state, action: PayloadAction<BookItem | undefined>) => {
      state.thesisToEdit = action.payload;
    },
    setDeleteModalOpen: (state, action: PayloadAction<boolean>) => {
      state.deleteModalOpen = action.payload;
    },
    openDeleteModalWith: (state, action: PayloadAction<number>) => {
      state.thesisToDelete = action.payload;
      state.deleteModalOpen = true;
    },
    setStudentId: (state, action: PayloadAction<number | undefined>) => {
      state.studentId = action.payload;
    },
    resetThesisToDelete: (state) => {
      state.thesisToDelete = undefined;
    },
  },
});

export const {
  setDeleteModalOpen,
  openDeleteModalWith,
  resetThesisToDelete,
  setThesisToEdit,
  setStudentId,
} = thesisSlice.actions;

export default thesisSlice.reducer;
