import { IconButton, Tooltip } from '@material-ui/core';
import { IconCalendar } from '@tabler/icons';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import { useTableSearch } from '../../../../../hooks/useTableSearch';
import SearchPaginatedTable from '../../../../shared/components/tables/SearchPaginatedTable';
import { useGetStudentDebtsQuery } from '../../../../students/payments-history/slices/paymentsApiSlice';
import {
  setDebtDateSelected,
  setDebtIdSelected,
  updateProrrogueTable,
} from '../../slices/clientSlice';
import ProrrogueDialog from './ProrrogueDialog';
import useSharedGuard from '../../../../../hooks/useSharedGuard';
import { feesPermissions } from '../../constants/permissions';
import CollapsibleContentRow from './CollapsibleContentRow';

const ProrrogueTab = () => {
  const dispatch = useAppDispatch();

  const { clientId } = useParams<{ clientId: string }>();

  const { hasAccess } = useSharedGuard(feesPermissions);

  const { id } = useParams<{ id: string }>();

  const { currentProfile, defaultConfiguration } = useAppSelector((state) => state.access);

  const { clientList } = useAppSelector((state) => state.client);
  const client = clientList.find((client) => client.id === Number(id));

  const { page, perPage, search, filterArrow } = useAppSelector(
    (state) => state.client.prorrogueTable
  );
  const { debtIdSelected } = useAppSelector((state) => state.client);

  const { setPage, setPerPage, setSearch, setFilterArrow } = useTableSearch({
    page,
    perPage,
    search,
    filterArrow,
    updateFunction: updateProrrogueTable,
  });

  const { data, isLoading, isFetching, error } = useGetStudentDebtsQuery({
    profileId: currentProfile?.profileId!,
    page,
    perPage,
    search,
    history_fees: true,
    ...(filterArrow && { sort: filterArrow.name, type_sort: filterArrow.direction }),

    studentId: Number(client?.user?.student?.id || clientId),
  });

  const datafetch =
    data?.data?.map((value, index) => ({
      collapsibleTitle: '',
      id: data?.from + index,
      description: value.fees_name_identifier,
      year:
        value.per_current_year && value.per_due_year
          ? `${value.per_current_year}-${value.per_due_year}`
          : '',
      fees_start_date: value.fees_start_date,
      end_data: value.fees_expiration_date,
      //prontopago: value.apply_early_payment,
      fees_net_amount: value.fees_net_amount,
      desc_percent_prontopago: value.apply_early_payment
        ? value.discount_early_payment_is_percent
          ? value.discount_value_early_payment //value.discount_early_payment
          : value.discount_early_payment //value.discount_percent_early_payment
        : '0.00',
      //fees_balance: value.fees_balance,
      fees_payment: value.fees_payment,
      net_paid_value: value.apply_early_payment
        ? value.net_value_early_payment
        : value.fees_balance,
      options: value,
    })) || [];

  const handleProrrogue = (id: string, date: string) => {
    dispatch(setDebtIdSelected(id));
    dispatch(setDebtDateSelected(date));
  };

  return (
    <>
      <SearchPaginatedTable
        data={datafetch || []}
        headers={{
          collapsibleTitle: 'Ver cambios',
          id: 'N°',
          description: 'Deuda',
          year: 'Período lectivo',
          fees_start_date: 'Fecha de inicio de cobro',
          end_data: 'Fecha de vencimiento',
          //prontopago: 'Desc. Prontopago',
          fees_net_amount: `${defaultConfiguration?.currency_symbol} Valor`,
          desc_percent_prontopago: `${defaultConfiguration?.currency_symbol} Descuento`,
          //fees_balance: 'Valor final sin descuento',
          fees_payment: `${defaultConfiguration?.currency_symbol} Abono`,
          net_paid_value: `${defaultConfiguration?.currency_symbol} Valor final`,
          options: 'Opciones',
        }}
        error={error}
        isFetching={isFetching}
        isLoading={isLoading}
        page={page}
        perPage={perPage}
        search={search}
        filterArrow={filterArrow}
        setPage={setPage}
        setPerPage={setPerPage}
        setSearch={setSearch}
        setFilterArrow={setFilterArrow}
        total={data?.total || 0}
        keyExtractor={(item) => item.id.toString()}
        customRenderers={{
          options: (value) => (
            <>
              {hasAccess('setDateExtension') && (
                <Tooltip title="Cambiar fecha de vencimiento">
                  <IconButton
                    onClick={() =>
                      handleProrrogue(
                        value.options.fees_identifier,
                        value.options.fees_expiration_date
                      )
                    }
                  >
                    <IconCalendar />
                  </IconButton>
                </Tooltip>
              )}
              {/* <DeleteButton handleDelete={() => {}} /> */}
            </>
          ),
        }}
        customDataCellsProperties={{
          id: { align: 'center' },
          description: { align: 'center' },
          year: { align: 'center' },
          fees_start_date: { align: 'center' },
          end_data: { align: 'center' },
          //prontopago: { align: 'center' },
          fees_net_amount: { align: 'center' },
          desc_percent_prontopago: { align: 'center' },
          //fees_balance: { align: 'center' },
          fees_payment: { align: 'center' },
          net_paid_value: { align: 'center' },
          options: { align: 'center' },
        }}
        searchPlacehoder={'Buscar'}
        numHeader={10}
        collapsible={{
          Rows: (index, { options: { date_history_fees } }) => (
            <CollapsibleContentRow date_history_fees={date_history_fees} />
          ),
          collapsibleBtnColl: 0,
        }}
      />
      {debtIdSelected && <ProrrogueDialog />}
    </>
  );
};

export default ProrrogueTab;
