import { Grid, Typography } from '@material-ui/core';
import MainCard from '../../../shared/components/cards/MainCard';
import FormsTable from '../components/FormsTable';

const MarketingFormsScreen = () => {
    return (
        <Grid component={MainCard} sx={{ padding: 2 }}>
            <Grid sx={{ mb: 3 }}>
                <Typography variant="h2">Marketing</Typography>
                <Typography variant="h2" color="gray" fontWeight={400}>Formularios</Typography>
                <br />
                <FormsTable />
            </Grid>

        </Grid>
    )
}

export default MarketingFormsScreen;