import { useState } from 'react';
import { Grid } from '@material-ui/core';

// import ForumList from './ForumList';

import ForumInfiniteList from '../components/ForumInfiniteList';
import ForumView from './ForumView';
import { useAppSelector, useAppDispatch } from '../../../../../hooks/useRedux';
import DeleteForumDialog from './DeleteForumDialog';
import DeleteCommentDialog from './DeleteCommentDialog';
import DialogImg from '../../../../shared/components/dialogs/DialogImg';

// import Transitions from '../../../../shared/components/extended/Transitions';
import { setShowImageModalOpen } from '../../slices/virtualClassroomTeacherSlice';

import { setSidebarOpened } from '../../../../shared/reducers/customizationReducer';

import MainCard from '../../../../shared/components/cards/MainCard';
import ForumDeleteDialog from '../components/DeleteForumDialog';

const ForoScreen = () => {
  // const classes = useStyles();
  const [isForumView, setIsForumView] = useState<boolean>(false);
  const [forumId, setForumId] = useState<number>();

  const dispatch = useAppDispatch();

  const { forumIdToDelete, commentIdToDelete, imgModalOpen, imgNameToShow, forumDeleteDialogOpen } =
    useAppSelector((state) => state.virtualClassroomTeacher);

  const goToForumView = (state = true) => {
    setIsForumView(state);
    dispatch(setSidebarOpened(true));
  };

  return (
    <Grid component={MainCard}>
      <Grid item container xs={12}>
        {!isForumView && (
          <ForumInfiniteList setForumId={setForumId} goToForumView={goToForumView} />
        )}
        {isForumView && (
          <Grid item container xs={12}>
            {/* <Transitions type="slide" in={isForumView} position="top-left" direction={'left'}> */}
            <ForumView goToForumView={goToForumView} forumId={forumId!} />
            {/* </Transitions> */}
          </Grid>
        )}
        {forumIdToDelete && <DeleteForumDialog />}
        {commentIdToDelete && <DeleteCommentDialog />}
        {imgModalOpen && (
          <DialogImg
            open={imgModalOpen}
            img={imgNameToShow!}
            onClose={() => dispatch(setShowImageModalOpen(false))}
          />
        )}
        {forumDeleteDialogOpen && <ForumDeleteDialog />}
      </Grid>
    </Grid>
  );
};

export default ForoScreen;
