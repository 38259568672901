import { Redirect, Switch } from 'react-router-dom';
import { useAppSelector } from '../../../../../../hooks/useRedux';
import GuardRoute from '../../../../../../router/guards/GuardRoute';
import AddTemplateScreen from '../screen/AddTemplateScreen';
import TemplateScreen from '../screen/TemplateScreen';
import PreviewTemplateScreen from '../screen/PreviewTemplateScreen';
import EditTemplateScreen from '../screen/EditTemplateScreen';
// eslint-disable-next-line import/no-cycle

export const TemplateRoutes = () => {
  const { currentModule } = useAppSelector((state) => state.access);

  const hasPermission = 'evaluation' in currentModule?.permissions!;

  if (!hasPermission) return <Redirect to="/dashboard" />;

  return (
    <Switch>
      <GuardRoute
        exact
        path="/plantilla"
        component={TemplateScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.list}
      />
      <GuardRoute
        exact
        path="/plantilla/agregar"
        component={AddTemplateScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.list}
      />
      <GuardRoute
        exact
        path="/plantilla/:id/editar"
        component={EditTemplateScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.list}
      />
      <GuardRoute
        exact
        path="/plantilla/:id/previsualizar"
        component={PreviewTemplateScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.list}
      />
    </Switch>
  );
};
