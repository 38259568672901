import { Grid, Typography } from '@material-ui/core';
import MainCard from '../../../shared/components/cards/MainCard';
import ContactSourcesTable from '../components/ContactSourcesTable';

const ConfigContactSourcesScreen = () => {
   return (
      <Grid component={MainCard} sx={{ padding: 2 }}>
         <Grid sx={{ mb: 3 }}>
            <Typography variant="h2">Configuración</Typography>
            <Typography variant="h2" color="gray" fontWeight={400}>Fuentes de Contacto</Typography>
            <br />
            <ContactSourcesTable />

         </Grid>

      </Grid>
   )
}

export default ConfigContactSourcesScreen;