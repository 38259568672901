import {
  Autocomplete,
  Button,
  Grid,
  InputLabel,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Table,
  CircularProgress,
  Collapse,
  Typography,
  Box,
  useTheme,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../../../hooks/useRedux';
import { PrincipalEducationLevel } from '../../../maintenance/education-levels/interfaces/educationLevels.interfaces';
import { useGetPrincipalEducationLevelsQuery } from '../../../maintenance/education-levels/slices/educationLevelsApiSlice';
import { Period } from '../../../maintenance/periods/interfaces/periods.interfaces';
import { useGetAvailiablesPeriodsQuery } from '../../../maintenance/periods/slices/periodsApiSlice';
import TextFieldFetching from '../../../shared/components/TextFieldFetching';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import SubCard from '../../../shared/components/cards/SubCard';
import { journeyTypes } from '../../../shared/constants/journeyType';
import { useFormStyles } from '../constants/styles';
import { hoursSummariesSchema } from '../constants/validationSchemas';
import { HourSummaryPayload } from '../interfaces/hoursCollaborators';
import {
  useAddHoursTeacherArrayMutation,
  useGetHoursSummariesFilterQuery,
} from '../slice/hoursTeacherApiSlice';

import { IconChevronDown } from '@tabler/icons';
import { IconChevronRight } from '@tabler/icons';
import IconTelework from '../../../shared/components/illustrations/IconTelework';
import CustomPagination from '../../../shared/components/tables/CustomPagination';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import { HoursSummaries } from '../interfaces/hoursSummariesResponse.interface';
import CustomFieldArray from './CustomFieldArray';

export interface DataForm {
  journey?: string;
  educationLevels: PrincipalEducationLevel;
  period: Period;
  teachers: HoursSummaries[];
  hourSummary: HSummaries[];
}

interface HSummaries {
  collaborator_id: number;
  hs_name: string;
  hs_classes: number;
  hs_classes_examns_preparation: number;
  hs_tutoring: number;
  hs_bonding: number;
  hs_academic_management: number;
  hs_research: number;
  total: number;
}

// interface HeaderDataForm {
//   educationLvl: PrincipalEducationLevel | null;
//   period: Period | null;
//   journey: JourneyType | null;
// }

interface JourneyType {
  id: string;
  label: string;
}

const AddHoursSummariesForm = () => {
  const { currentProfile } = useAppSelector((state) => state.access);
  const classes = useFormStyles();
  const [hoursSummaries, setHoursSummaries] = useState<HSummaries[] | undefined>([]);

  const [flat, setFlat] = useState(false);
  const [showAlerts, setShowAlerts] = useState(true);
  const [collapse, setCollapse] = useState(true);

  const [journey, setJourney] = useState<JourneyType | null>(null);
  const [period, setPeriod] = useState<Period | null>(null);
  const [educationLvl, setEducationLvl] = useState<PrincipalEducationLevel | null>(null);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [skip, setSkip] = useState(true);

  const theme = useTheme();

  const { data: educationLevelsAvailables = [], isLoading: isLoadingEL } =
    useGetPrincipalEducationLevelsQuery({
      profileId: currentProfile?.profileId!,
    });

  const { data: periodsAvailables = [], isLoading: isLoadingPeriods } =
    useGetAvailiablesPeriodsQuery({
      profileId: currentProfile?.profileId!,
      current: true,
    });

  const {
    data: teachersAvailables,
    isFetching: isFetchingTeachers,
    isLoading: isLoadingTeachers,
  } = useGetHoursSummariesFilterQuery(
    {
      profileId: currentProfile?.profileId!,
      type_working: journey?.id || '',
      coll_type: 'D',
      period_id: period?.id.toString() || '',
      education_level: educationLvl?.id.toString() || '',
      size: perPage,
      page,
    },
    {
      refetchOnMountOrArgChange: 30,
      skip,
    }
  );

  useEffect(() => {
    if (period && educationLvl && journey) return setSkip(false);
    setSkip(true);
  }, [period, educationLvl, journey]);

  useEffect(() => {
    if (period && educationLvl && journey) return setFlat(true);
    setFlat(false);
  }, [period, educationLvl, journey]);

  useEffect(() => {
    if (isFetchingTeachers) return setHoursSummaries(undefined);
  }, [isFetchingTeachers]);

  const [addHoursTeachers, { isLoading, isError, error, isSuccess }] =
    useAddHoursTeacherArrayMutation();

  useEffect(() => {
    if (teachersAvailables) {
      let hs: HSummaries[] | undefined = teachersAvailables?.data?.map((e) => {
        let summaries: HSummaries = {
          collaborator_id: e.id,
          hs_name: `${e.user.person.pers_first_lastname} ${e.user.person.pers_second_lastname} ${e.user.person.pers_firstname} ${e.user.person.pers_secondname}`,
          hs_classes: 0,
          hs_classes_examns_preparation: 0,
          hs_tutoring: 0,
          hs_bonding: 0,
          hs_academic_management: 0,
          hs_research: 0,
          total: 0,
        };
        return summaries;
      });
      setHoursSummaries(hs);
      setFlat(true);
      setCollapse(false);
    }
  }, [teachersAvailables, isSuccess]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (newPerPage: number) => {
    setAnchorEl(null);
    setPerPage(newPerPage);
  };

  const initialValues: DataForm = {
    journey: journey?.id,
    educationLevels: educationLvl!,
    teachers: teachersAvailables?.data!,
    period: period!,
    hourSummary: hoursSummaries!,
  };

  return (
    <Grid container direction="column" gap={2}>
      <Grid item xs={12} md={6} container>
        <Grid item>
          {isError && showAlerts && (
            <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
          )}

          {isSuccess && showAlerts && (
            <SuccessAlert
              message="Se asignó correctamente las horas a los docentes."
              handleDismiss={() => setShowAlerts(false)}
            />
          )}
        </Grid>
      </Grid>
      <SubCard
        title={'Seleccione los filtros'}
        darkTitle
        secondary={
          <Grid item container direction="row" alignItems="center">
            {collapse ? (
              <>
                <Typography sx={{ mr: 1 }} className={classes.labelCollapse}>
                  Minimizar filtros
                </Typography>
                <Button onClick={() => setCollapse(!collapse)}>
                  <IconChevronDown stroke={1.5} size="1.3rem" />
                </Button>
              </>
            ) : (
              <>
                <Typography sx={{ mr: 1 }} className={classes.labelCollapse}>
                  Editar filtros
                </Typography>
                <Button
                  onClick={() => {
                    setCollapse(!collapse);
                    setFlat(false);
                  }}
                >
                  <IconChevronRight stroke={1.5} size="1.3rem" />
                </Button>
              </>
            )}
          </Grid>
        }
      >
        {!collapse && (
          <Grid container item direction="row" xs={12} justifyContent="space-between">
            <Grid item>
              <Grid container direction="row">
                <Typography sx={{ mr: 1 }} className={classes.labelTitle}>
                  Unidad académica
                </Typography>
                <Typography className={classes.labelHeader}>
                  {educationLvl?.edu_name || 'Ninguno'}
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="row">
                <Typography sx={{ mr: 1 }} className={classes.labelTitle}>
                  Período
                </Typography>
                <Typography className={classes.labelHeader}>
                  {period?.per_name || 'Ninguno'}
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="row">
                <Typography sx={{ mr: 1 }} className={classes.labelTitle}>
                  Tipo de jornada
                </Typography>
                <Typography className={classes.labelHeader}>
                  {journey?.label || 'Ninguno'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}

        <Collapse in={collapse} timeout="auto" unmountOnExit>
          <Grid item container spacing={2}>
            <Grid item container spacing={2}>
              <Grid item xs={12} md={4}>
                <InputLabel className={classes.inputLabel}>Unidad académica</InputLabel>
                {isLoadingEL ? (
                  <TextFieldFetching />
                ) : (
                  <Autocomplete
                    options={educationLevelsAvailables}
                    loading={isLoadingEL}
                    loadingText="Cargando unidades académicas..."
                    getOptionLabel={(option) => option.edu_name}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Seleccione una unidad académica" />
                    )}
                    onChange={(_, value) => {
                      setEducationLvl(value || null);
                      if (!value) setJourney(null);
                    }}
                    fullWidth
                  />
                )}
              </Grid>
              <Grid item xs={12} md={4}>
                <InputLabel className={classes.inputLabel}>Períodos</InputLabel>
                {isLoadingPeriods ? (
                  <TextFieldFetching />
                ) : (
                  <Autocomplete
                    options={periodsAvailables}
                    loading={isLoadingPeriods}
                    loadingText="Cargando los períodos..."
                    getOptionLabel={(option) => option.per_name}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Seleccione el período" />
                    )}
                    onChange={(_, value) => {
                      setPeriod(value!);
                      if (!value) setJourney(null);
                    }}
                    fullWidth
                  />
                )}
              </Grid>

              <Grid item xs={12} md={4}>
                <InputLabel className={classes.inputLabel}>Tipo de jornada</InputLabel>
                <Autocomplete
                  options={journeyTypes}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Seleccione la jornada laboral" />
                  )}
                  onChange={(_, value) => {
                    setJourney(value || null);
                  }}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
        </Collapse>
      </SubCard>

      {flat ? (
        <SubCard content={false} title={'Asignar horas a docentes'} darkTitle>
          {!hoursSummaries || isFetchingTeachers ? (
            <Grid container justifyContent={'center'} m={3}>
              <CircularProgress />
            </Grid>
          ) : (
            <Formik
              initialValues={initialValues}
              validationSchema={hoursSummariesSchema}
              onSubmit={async (values) => {
                const filteredHourSummary = values.hourSummary.filter((h) => h.total > 0);

                await addHoursTeachers({
                  profileId: currentProfile?.profileId!,
                  hoursTeacherPayload: filteredHourSummary.map((hs) => {
                    let hsummaries: HourSummaryPayload = {
                      hs_name: hs.hs_name,
                      collaborator_id: hs.collaborator_id,
                      hs_classes: hs.hs_classes,
                      hs_classes_examns_preparation: hs.hs_classes_examns_preparation,
                      hs_tutoring: hs.hs_tutoring,
                      hs_bonding: hs.hs_bonding,
                      hs_academic_management: hs.hs_academic_management,
                      hs_research: hs.hs_research,
                      period_id: values.period.id,
                      education_level_id: values.educationLevels.id,
                      requisition_id: 1,
                      status_id: 1,
                    };
                    return hsummaries;
                  }),
                });
                setHoursSummaries(undefined);
                setShowAlerts(true);
              }}
              validateOnChange={false}
            >
              {({ values, handleSubmit, setFieldValue }) => (
                <form onSubmit={handleSubmit}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell colSpan={1}></TableCell>
                          <TableCell align="center" colSpan={4}>
                            Actividades de Docencia
                          </TableCell>
                          <TableCell align="center" colSpan={1}>
                            Actividades de Gestión Acádemica
                          </TableCell>
                          <TableCell align="center" colSpan={1}>
                            Actividades de Investigación
                          </TableCell>
                          <TableCell colSpan={1}></TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Nombre del docente</TableCell>
                          <TableCell align="center">Clases</TableCell>
                          <TableCell align="center">Preparación</TableCell>
                          <TableCell align="center">Tutorías</TableCell>
                          <TableCell align="center">Vinculación</TableCell>
                          <TableCell align="center">Gestión Académica</TableCell>
                          <TableCell align="center">Investigación</TableCell>
                          <TableCell align="center">Total</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <CustomFieldArray
                          name="hourSummary"
                          values={values}
                          setFieldValue={setFieldValue}
                        />
                      </TableBody>
                    </Table>
                    {hoursSummaries.length > 0 && (
                      <>
                        <Grid container justifyContent={'end'} p={3}>
                          <Button
                            variant="contained"
                            size="large"
                            color="primary"
                            type="submit"
                            disabled={isLoading}
                            endIcon={isLoading && <CircularProgress size={20} />}
                          >
                            Guardar
                          </Button>
                        </Grid>
                        {!isLoadingTeachers && !error && (
                          <Grid
                            item
                            container
                            justifyContent="space-between"
                            alignItems="flex-end"
                            p={3}
                          >
                            <Grid item>
                              <CustomPagination
                                shape="rounded"
                                color="primary"
                                count={
                                  teachersAvailables?.total
                                    ? Math.ceil(teachersAvailables.total / perPage || 1)
                                    : 0
                                }
                                page={page}
                                onChange={(_, newPage) => {
                                  setPage(newPage);
                                }}
                              />
                            </Grid>
                            <Grid>
                              <Box
                                component={Button}
                                size="large"
                                sx={{
                                  color: 'grey.900',
                                  backgroundColor: 'primary.light',
                                  ':hover': { bgcolor: 'primary.light' },
                                  px: 2,
                                  borderRadius: 4,
                                }}
                                endIcon={<ExpandMoreRoundedIcon />}
                                onClick={handleClick}
                              >
                                {perPage} Registros
                              </Box>

                              <Menu
                                anchorEl={anchorEl}
                                keepMounted
                                open={open}
                                onClose={() => handleClose(perPage)}
                                variant="selectedMenu"
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'right',
                                }}
                                transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'right',
                                }}
                              >
                                <MenuItem onClick={() => handleClose(5)}>5 Registros</MenuItem>
                                <MenuItem onClick={() => handleClose(10)}>10 Registros</MenuItem>
                                <MenuItem onClick={() => handleClose(15)}>15 Registros</MenuItem>
                              </Menu>
                            </Grid>
                          </Grid>
                        )}
                      </>
                    )}
                  </TableContainer>
                </form>
              )}
            </Formik>
          )}
        </SubCard>
      ) : (
        <Box>
          <Grid container justifyContent={'center'} alignContent={'center'} sx={{ height: '50vh' }}>
            <Grid container justifyContent={'center'} item xs={8} md={6}>
              <Grid container item xs={12} justifyContent={'center'}>
                <IconTelework size={300} />
              </Grid>
              <Typography variant={'body1'} textAlign={'center'} lineHeight={2} component={'div'}>
                <Typography
                  variant={'h4'}
                  sx={{ color: theme.palette.primary.main }}
                  component={'p'}
                  align={'center'}
                  lineHeight={2}
                >
                  Selecciona los filtros
                </Typography>
                Con los siguientes filtros de selección, escoge <strong>los docentes</strong> para
                asignarle sus respectivas <strong>horas</strong>.
              </Typography>
            </Grid>
          </Grid>
        </Box>
      )}
    </Grid>
  );
};

export default AddHoursSummariesForm;
