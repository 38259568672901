import { useState } from "react";
import {
    Autocomplete,
    Button,
    FormHelperText,
    Grid,
    InputLabel,
    TextField,
    TableContainer,
    Table,
    TableRow,
    TableCell,
    TableBody,
    Skeleton,
    IconButton,
    Tooltip,
    useTheme,
    Checkbox,
    TableHead,
    CircularProgress,
    AutocompleteChangeReason
} from "@material-ui/core"
import { useFormik } from "formik";
import { useHistory, Redirect } from 'react-router-dom'
import { IconTrash } from '@tabler/icons';
import { useAppSelector } from "../../../../hooks/useRedux";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Collaborator } from '../../../hr/collaborators/interfaces/collaborators.interfaces'
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import { renderSkeletonRows } from '../../../shared/helpers/render'
import { CollectiveInfoSchema } from "../constants/validationSchemas";
import { useGetAvailablesCollaboratorsByEdLvlQuery } from '../../../hr/collaborators/slices/collaboratorsApiSlice'
import { useUpdateEducationLevelCollectiveMutation } from '../slices/educationLevelsApiSlice'
import ErrorAlert from "../../../shared/components/alerts/ErrorAlert";
import { CollectiveEditForm } from '../interfaces/educationLevelsForms.interfaces'
import { checkCollaboratorPincipal } from "../helpers";

const EditCollective = function () {

    const theme = useTheme();

    const history = useHistory();

    const { currentProfile } = useAppSelector((state) => state.access);

    const { educationLevelCollectives, currentColective, currentEducationLevelId } = useAppSelector((state) => state.educationLevels)

    const [removed, setRemoved] = useState<boolean>(false);

    const [showAlerts, setShowAlerts] = useState<boolean>(false);

    const { isLoading: isLoadingCollaborators, data: dataCollaborator } = useGetAvailablesCollaboratorsByEdLvlQuery(
        {
            profileId: currentProfile?.profileId!,
            edLvlId: currentEducationLevelId!
        },
        { refetchOnMountOrArgChange: 30 }
    );

    const collectiveToEdit = educationLevelCollectives.find((value) => value.id === currentColective);

    const [updateCollective, { isLoading, isSuccess, error, isError }] = useUpdateEducationLevelCollectiveMutation()

    const { values, handleSubmit, getFieldProps, errors, touched, setFieldValue } = useFormik<CollectiveEditForm>({
        initialValues: {
            title: collectiveToEdit?.name || '',
            collaborators: collectiveToEdit?.collaborators! || [],
            collaboratorPrincipal: collectiveToEdit?.collaborator.id || 0,
        },
        validationSchema: CollectiveInfoSchema,
        validate: (values) => {
            return checkCollaboratorPincipal(values.collaboratorPrincipal);
        },
        onSubmit: async (values) => {
            const collaborators = values.collaborators.map((e) => e.id);

            //Remove element not contain in origin array
            const originalCollaborators = collectiveToEdit?.collaborators!.filter((a) => values.collaborators.some((e) => a === e));
            const selected = collectiveToEdit?.collaborators!.filter((e) => !originalCollaborators?.some((a) => a === e));
            const collaboratorsRemoves = selected?.map((a) => a.id);

            await updateCollective({
                collective: currentColective!,
                payload: {
                    collaborators_join_acd_pric: values.collaboratorPrincipal,
                    collaborators_list: collaborators,
                    name: values.title,
                    collaborators_delete: collaboratorsRemoves! || [],
                    status_id: 1
                }
            })

            setShowAlerts(true);
        },
    });

    const handleDelete = (collId: number) => {
        const currentValues = values.collaborators.filter((value) => value.id !== collId);

        setFieldValue('collaborators', currentValues);
        
        setFieldValue('collaboratorPrincipal', 0);

        setRemoved(true)
    }

    const uniqueSelected = (collaborator: number): boolean => {
        return values.collaboratorPrincipal === collaborator;
    }

    const RenderCollective = (coll: Collaborator, principal: number) => {
        return (
            < TableRow hover selected={principal === coll.id} className="table-row" key={coll.id.toString()} >
                <TableCell
                    width="70%"
                    sx={{ cursor: 'pointer' }}
                >
                    {`${coll.user.person.pers_firstname} ${coll.user.person.pers_first_lastname}`}
                    <FormHelperText>{coll.coll_email}</FormHelperText>
                </TableCell>
                <TableCell width="50%%" align="center">
                    <Tooltip title="Asignar colaborador principal">
                        <Checkbox checked={uniqueSelected(coll.id)} onChange={() => setFieldValue('collaboratorPrincipal', coll.id)} />
                    </Tooltip>
                </TableCell>
                <TableCell
                    width="30%"
                    align="center"
                >
                    <Tooltip title="Remover del listado">
                        <IconButton aria-label="Eliminar" onClick={() => handleDelete(coll.id)}>
                            <IconTrash size={20} color={theme.palette.error.main} />
                        </IconButton>
                    </Tooltip>
                </TableCell>
            </TableRow>
        )
    }

    const RenderCollectives = () => {
        return <>{values.collaborators.map((e) => RenderCollective(e, values.collaboratorPrincipal))}</>
    }

    if (!collectiveToEdit) {
        return <Redirect to="/administrar-niveles-educativos" />
    }

    return (
        <form onSubmit={handleSubmit}>
            <Grid container spacing={4} alignItems="flex-start">
                <Grid item xs={12} md={6} sm={12} container spacing={2}>

                    {/* Show messages */}
                    {removed && (
                        <Grid item xs={12}>
                            <SuccessAlert
                                message="Colaborador removido de la lista."
                                handleDismiss={() => setRemoved(false)}
                            />
                        </Grid>
                    )}

                    {isError && showAlerts && (
                        <Grid item xs={12}>
                            <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
                        </Grid>
                    )}

                    {isSuccess && showAlerts && (
                        <Grid item xs={12}>
                            <SuccessAlert
                                message="El grupo colectivo se ha sido modificado correctamente."
                                handleDismiss={() => setShowAlerts(false)}
                            />
                        </Grid>
                    )}

                    {/* Title collective to edit  */}
                    <Grid item xs={12}>
                        <InputLabel>Nombre del colectivo</InputLabel>
                        <TextField
                            style={{ marginTop: 5 }}
                            fullWidth
                            error={Boolean(errors.title && touched.title)}
                            placeholder="Ingrese el nombre del colectivo"
                            {...getFieldProps('title')}
                        />
                        {errors.title && touched.title && <FormHelperText error>{errors.title}</FormHelperText>}
                    </Grid>

                    {/* Collective collaborators */}
                    <Grid item xs={12}>
                        <InputLabel>Colaboradores</InputLabel>
                        {isLoadingCollaborators ? (
                            <Skeleton variant="rectangular" sx={{ width: '100%', borderRadius: 3, height: 50 }} />
                        ) : (
                            <Autocomplete
                                multiple
                                options={dataCollaborator! || []}
                                loading={isLoadingCollaborators}
                                loadingText="Cargando colaboradores disponibles..."
                                clearIcon={false}
                                getOptionLabel={(option) => `${option.user.person.pers_firstname} ${option.user.person.pers_first_lastname}`}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        error={Boolean(errors.collaborators && touched.collaborators)}
                                        placeholder="Seleccione los colaboradores disponibles"
                                    />
                                )}
                                value={values.collaborators && values.collaborators}
                                onChange={(_, values, reason: AutocompleteChangeReason) => {
                                    if (reason === "removeOption"){
                                        setFieldValue('collaboratorPrincipal', 0);
                                    }
                                    setFieldValue('collaborators', [...values.map((col) => col)] || '');
                                    }
                                }
                                getOptionSelected={(option, value) =>
                                    { return option.id === value.id || option?.user?.person?.pers_firstname?.toLowerCase() === value?.user?.person?.pers_firstname?.toLowerCase() }}
                                fullWidth
                                style={{ marginTop: 5 }}
                            />
                        )}

                        {errors.collaborators && touched.collaborators && (
                            <FormHelperText error>{errors.collaborators}</FormHelperText>
                        )}
                    </Grid>

                    {/* Action buttons */}
                    <Grid item
                        container
                        alignItems="center"
                        justifyContent="flex-end"
                        spacing={2}
                        sx={{ mt: 3 }}>
                        <Grid item>
                            <Button
                                variant="outlined"
                                size="large"
                                color="primary"
                                onClick={() => history.goBack()}
                            >
                                Regresar
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                type="submit"
                                variant="contained"
                                size="large"
                                color="primary"
                                disabled={isLoading}
                                endIcon={isLoading && <CircularProgress size={20} />}
                            >
                                Editar
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} md={6} sm={12} container spacing={2}>
                    <Grid item xs={12} md={12} sx={{ maxHeight: 600, overflow: 'hidden', marginTop: 3 }}>
                        <PerfectScrollbar component="div">
                            <TableContainer>
                                <InputLabel>Colaboradores asignados al colectivo.</InputLabel>
                                <Table style={{ marginTop: 10 }}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell key={'headEditColaborator'}>Colaborador</TableCell>
                                            <TableCell align="center" key={'headEditPrincipal'}>Principal</TableCell>
                                            <TableCell align="center" key={'headEditOptions'}>Opciones</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {isLoadingCollaborators ? renderSkeletonRows(3, 3) : RenderCollectives()}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </PerfectScrollbar>
                    </Grid>
                </Grid>

            </Grid>
        </form>
    )
}

export default EditCollective