import { NamesTabs } from '../components/formTabs';

const handleNextStep = (
  setCurrentTab: React.Dispatch<React.SetStateAction<number>> | undefined,
  currentTab: NamesTabs | undefined,
  nameCurrentTab: NamesTabs,
  stepNumber = 1
) => {
  if (!!setCurrentTab && currentTab === nameCurrentTab) {
    setCurrentTab((e) => e + stepNumber);
  }
};

const handleReturnStep = (
  setCurrentTab: React.Dispatch<React.SetStateAction<number>> | undefined,
  stepNumber = -1
) => {
  !!setCurrentTab && setCurrentTab((e) => e + stepNumber);
};

export { handleNextStep, handleReturnStep };
