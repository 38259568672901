import {
  Button,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  Paper,
  Switch,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CustomAsyncPaginatedSelect from '../../../../shared/components/extended/Form/CustomAsyncPaginatedSelect';
import { useState } from 'react';
import { AuthorsScientificPublication } from '../../interfaces/publicationsPayload.interfaces';
import { useFormikContext } from 'formik';
import { useDeleteAuthorPublicationMutation } from '../../slices/publicationsApiSlice';
import { useLazyGetCollaboratorsQuery } from '../../../../hr/collaborators/slices/collaboratorsApiSlice';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { setKeywordAddPersonPublication } from '../../slices/publicationsSlice';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import { IInitialValuesAssigns } from '../../interfaces/initialValuesAssigns.interface';
import { useLazyGetStudentsQuery } from '../../../students/slices/studentsApiSlice';

interface AuthorName extends AuthorsScientificPublication {
  name: string;
}
interface Props {
  listAddAuthor: AuthorName[];
  setListAddAuthor: React.Dispatch<React.SetStateAction<AuthorName[]>>;
}

const FormStudentAndCollaboratorPublication = ({ listAddAuthor, setListAddAuthor }: Props) => {
  const { t } = useTranslation();

  const { keywordAddPersonPublication } = useAppSelector((state) => state.publications);

  const dispatch = useAppDispatch();

  const { values, errors, touched, setFieldValue } = useFormikContext<IInitialValuesAssigns>();

  const [addAuthor, setAddAuthor] = useState({} as AuthorName);

  const [getStudentsTrigger] = useLazyGetStudentsQuery();

  const [deleteAuthorTrigger, { isLoading: isLoadingDeleteAuthor }] =
    useDeleteAuthorPublicationMutation();

  const [getCollaboratorTrigger] = useLazyGetCollaboratorsQuery();

  const handleAddStudent = () => {
    if (listAddAuthor.some(({ student_id }) => student_id === addAuthor.student_id)) {
      const listFilter = listAddAuthor.filter(
        ({ student_id }) => !(student_id === addAuthor.student_id)
      );

      setListAddAuthor([...listFilter, addAuthor]);
    } else if (!!addAuthor.student_id) {
      setListAddAuthor((list) => [...list, addAuthor]);
    }

    setAddAuthor({} as AuthorName);
  };

  const handleAddCollaborator = () => {
    console.log('click collaborator');
    if (
      listAddAuthor.some(({ collaborator_id }) => collaborator_id === addAuthor.collaborator_id)
    ) {
      const listFilter = listAddAuthor.filter(
        ({ collaborator_id }) => !(collaborator_id === addAuthor.collaborator_id)
      );

      setListAddAuthor([...listFilter, addAuthor]);
      console.log(' no agrego');
    } else if (!!addAuthor.collaborator_id) {
      console.log('agrego');
      setListAddAuthor((list) => [...list, addAuthor]);
    } else {
      console.log('no agrego');
    }

    setAddAuthor({} as AuthorName);
  };

  const handleDeleteEvent = async (id: number, type: string, authorId?: string | null) => {
    if (type === 'student') {
      if (!!authorId) {
        await deleteAuthorTrigger({ authorId });
      }
      setListAddAuthor((list) => list.filter(({ student_id }) => !(student_id === id)));
    }
    if (type === 'collaborator') {
      if (!!authorId) {
        await deleteAuthorTrigger({ authorId });
      }
      setListAddAuthor((list) => list.filter(({ collaborator_id }) => !(collaborator_id === id)));
    }
  };

  //TODO: crear hook en el componente del select
  const getStudents = async (search: string, page: number, perPage: number) => {
    const response = await getStudentsTrigger({
      page,
      perPage,
      search,
    }).unwrap();

    const hasMore = response.current_page < response.last_page;

    return {
      options: response.data.map((dt) => {
        return {
          value: dt.id,
          label: (dt as any).pers_full_lastname || (dt as any).pers_full_name,
          ...dt,
        };
      }),
      hasMore,
    };
  };

  //TODO: crear hook en el componente del select
  const getCollaborators = async (search: string, page: number, perPage: number) => {
    const response = await getCollaboratorTrigger({
      page,
      perPage,
      search,
      coll_type: 'D',
      statusId: 1,
    }).unwrap();

    const hasMore = response.current_page < response.last_page;

    return {
      options: response.data.map((dt) => {
        return {
          value: dt.id,
          label: (dt as any).pers_full_name,
          ...dt,
        };
      }),
      hasMore,
    };
  };

  return (
    <Grid container direction="column">
      <Grid item container xs={12} md={6} spacing={2}>
        {keywordAddPersonPublication !== '' && (
          <>
            <Grid item xs={12}>
              {keywordAddPersonPublication === 'student' && (
                <>
                  <InputLabel> {t('publication.AssignsForm.student.title')} </InputLabel>
                  <CustomAsyncPaginatedSelect
                    key={'studentId'}
                    value={values.studentId}
                    isError={'studentId' in errors && touched['studentId'] ? true : false}
                    placeholder={'Escoger estudiante'}
                    setValue={(value) => {
                      setFieldValue('studentId', value);
                      setAddAuthor({
                        id: null,
                        collaborator_id: null,
                        student_id: (value as any).id || '',
                        is_coauthor: !!addAuthor?.is_coauthor ? 1 : 0,
                        status: 'active',
                        name: `${(value as any).pers_firstname}  ${
                          (value as any).pers_first_lastname
                        }`,
                      });
                    }}
                    fetchFunc={getStudents as any}
                  />
                  {errors.studentId && touched.studentId && (
                    <FormHelperText error>{t(errors.studentId)}</FormHelperText>
                  )}
                </>
              )}

              {keywordAddPersonPublication === 'collaborator' && (
                <Grid item xs={12}>
                  <InputLabel>
                    {t('lines_research.AddCollaboratorGroupResearch.collaboratorId.title')}
                  </InputLabel>
                  <CustomAsyncPaginatedSelect
                    key={'collaboratorId'}
                    value={values.collaboratorId}
                    isError={'collaboratorId' in errors && touched['collaboratorId'] ? true : false}
                    placeholder={t(
                      'lines_research.AddCollaboratorGroupResearch.collaboratorId.placeholder'
                    )}
                    setValue={(value) => {
                      setFieldValue('collaboratorId', value);
                      setAddAuthor({
                        id: null,
                        collaborator_id: (value as any).id || '',
                        student_id: null,
                        is_coauthor: !!addAuthor?.is_coauthor ? 1 : 0,
                        status: 'active',
                        name: `${(value as any).pers_firstname}  ${
                          (value as any).pers_first_lastname
                        }`,
                      });
                    }}
                    fetchFunc={getCollaborators as any}
                  />
                  {errors.collaboratorId && touched.collaboratorId && (
                    <FormHelperText error>{t(errors.collaboratorId)}</FormHelperText>
                  )}
                </Grid>
              )}
            </Grid>
            <Grid item xs={12}>
              <InputLabel> {t('publication.AssignsForm.isCoauthor.title')} </InputLabel>
              <Switch
                checked={!!addAuthor.is_coauthor ? true : false}
                onChange={({ target }) =>
                  setAddAuthor((data) => ({ ...data, is_coauthor: target.checked ? 1 : 0 }))
                }
              />
            </Grid>
            <Grid item xs={3}>
              {keywordAddPersonPublication === 'student' && (
                <Button
                  fullWidth
                  variant="contained"
                  size="small"
                  color="primary"
                  onClick={handleAddStudent}
                >
                  {t('publication.AssignsForm.button.add')}
                </Button>
              )}
              {keywordAddPersonPublication === 'collaborator' && (
                <Button
                  fullWidth
                  variant="contained"
                  size="small"
                  color="primary"
                  onClick={handleAddCollaborator}
                >
                  {t('publication.AssignsForm.button.add')}
                </Button>
              )}
            </Grid>
            <Grid item xs={3}>
              <Button
                fullWidth
                variant="outlined"
                size="small"
                color="primary"
                onClick={() => {
                  dispatch(setKeywordAddPersonPublication(''));
                }}
              >
                {t('publication.AssignsForm.button.cancell')}
              </Button>
            </Grid>
          </>
        )}

        {keywordAddPersonPublication === 'collaborator' && (
          <Grid
            item
            container
            xs={12}
            sx={{
              my: 2,
            }}
          >
            {listAddAuthor
              .filter(({ collaborator_id }) => !!collaborator_id)
              .map(({ student_id, is_coauthor, name, collaborator_id, id }) => (
                <Grid
                  item
                  xs={12}
                  sx={{
                    my: 1,
                  }}
                >
                  <Paper elevation={2}>
                    <Grid container justifyContent="space-between" alignItems="center">
                      <Grid item pt={2} px={2}>
                        <Typography variant="body1" color="primary">
                          {t('Colaborador')}: {name}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <IconButton
                          aria-label="test"
                          disabled={isLoadingDeleteAuthor}
                          onClick={() =>
                            handleDeleteEvent(
                              student_id || collaborator_id || 0,
                              'collaborator',
                              id
                            )
                          }
                        >
                          <HighlightOffIcon color="primary" />
                        </IconButton>
                      </Grid>
                      <Grid item xs={12} sm={6} px={2} pb={1}>
                        <Typography variant="body1" color="inherit">
                          {!!is_coauthor && t('publication.AssignsForm.isCoauthor.title.label')}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              ))}
          </Grid>
        )}

        {keywordAddPersonPublication === 'student' && (
          <Grid
            item
            container
            xs={12}
            sx={{
              my: 2,
            }}
          >
            {listAddAuthor
              .filter(({ student_id }) => !!student_id)
              .map(({ student_id, is_coauthor, name, collaborator_id, id }) => (
                <Grid
                  item
                  xs={12}
                  sx={{
                    my: 1,
                  }}
                >
                  <Paper elevation={2}>
                    <Grid container justifyContent="space-between" alignItems="center">
                      <Grid item pt={2} px={2}>
                        <Typography variant="body1" color="primary">
                          {t('publication.AssignsForm.student.title')}: {name}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <IconButton
                          aria-label="test"
                          disabled={isLoadingDeleteAuthor}
                          onClick={() =>
                            handleDeleteEvent(student_id || collaborator_id || 0, 'student', id)
                          }
                        >
                          <HighlightOffIcon color="primary" />
                        </IconButton>
                      </Grid>
                      <Grid item xs={12} sm={6} px={2} pb={1}>
                        <Typography variant="body1" color="inherit">
                          {!!is_coauthor && t('publication.AssignsForm.isCoauthor.title.label')}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              ))}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default FormStudentAndCollaboratorPublication;
