import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@material-ui/lab';
import { ButtonBase, CircularProgress, Grid, Typography, useTheme } from '@material-ui/core';

import GroupIcon from '@material-ui/icons/Group';
import AssignmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import { blue } from '@material-ui/core/colors';
import moment from 'moment';

const TimelineOnce: React.FC<{
  Descripcion: string;
  FechaInicio: Date;
  FechaEnd: Date;
  Category: string;

  isLoading?: boolean;
  onClickHandler?: () => void;
}> = ({ Descripcion, FechaInicio, FechaEnd, Category, isLoading = false, onClickHandler }) => {
  const theme = useTheme();

  return (
    <Grid sx={{ mb: 2 }}>
      <TimelineItem>
        <TimelineOppositeContent style={{ content: '', display: 'none' }}></TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot sx={{ bgcolor: blue[900] }}>
            <AssignmentOutlinedIcon sx={{ color: theme.palette.grey[50] }} />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <ButtonBase disabled={!onClickHandler} onClick={onClickHandler}>
            <Grid container alignItems="center">
              <Grid item container xs={9}>
                <Grid sx={{ mb: 1 }} width="100%">
                  <Typography variant="h3">{Category}</Typography>
                </Grid>
                <Grid sx={{ mb: 1 }} item container>
                  <Typography variant="h4">{Descripcion}</Typography>
                </Grid>

                <Grid container>
                  <Typography
                    sx={{ pr: 2 }}
                    variant="body1"
                    fontWeight={'bold'}
                    fontSize={'medium'}
                  >
                    Fecha inicio
                  </Typography>
                  <Typography>{moment(FechaInicio).format('LLL')}</Typography>
                </Grid>
                <Grid container>
                  <Typography
                    sx={{ pr: 2 }}
                    variant="body1"
                    fontWeight={'bold'}
                    fontSize={'medium'}
                  >
                    Fecha Fin
                  </Typography>
                  <Typography>{moment(FechaEnd).format('LLL')}</Typography>
                </Grid>
              </Grid>
              <Grid item container xs={3}>
                <Grid>
                  {isLoading ? (
                    <CircularProgress />
                  ) : (
                    <GroupIcon sx={{ color: theme.palette.grey[500] }} />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </ButtonBase>
        </TimelineContent>
      </TimelineItem>
    </Grid>
  );
};

export default TimelineOnce;
