import { useState } from 'react';

// import { useHistory } from 'react-router-dom';
import { Grid, Tab, Tabs, Typography } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
// import { IconArrowNarrowLeft } from '@tabler/icons';

import { useTranslation } from 'react-i18next';
// import MainCardForm from '../../../shared/components/cards/MainCardForm';
// import Checkout from '../components/Checkout';

import { useAppDispatch } from '../../../../hooks/useRedux';
import GenerateDebts from '../components/GenerateDebts';
import TempBillingData from '../components/TempBillingData';
import CustomTabPanel from '../../../shared/components/tabs/CustomTabPanel';
import GenerateMassiveDebts from '../components/GenerateMassiveDebts';
import { setCurrentTab } from '../slices/generateDebtsSlice';

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const DebtsPaymentsScreen = () => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  //tabs states
  const [tabValue, setTabValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    dispatch(setCurrentTab(newValue));
  };

  return (
    <Grid container>
      <Grid item xs={12} md={'auto'} lg={tabValue === 1 ? 10 : 8} sx={{ pr: { lg: 1 } }}>
        <Grid item xs={12} sx={{ padding: 4, bgcolor: 'background.paper', borderRadius: 2 }}>
          <Grid item xs={12}>
            <Grid item container alignItems="center" gap={1} sx={{ cursor: 'pointer' }}>
              {/* <IconButton onClick={() => history.push(`/${location.pathname.split('/')[1]}`)}>
                <IconArrowNarrowLeft size={30} color={theme.palette.text.dark} />
              </IconButton> */}
              <Typography variant="h2">{t('financial.generate_debts.title')}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ mt: 2 }}>
            <Tabs
              value={tabValue}
              variant="scrollable"
              onChange={handleChange}
              // className={classes.accountTab}
              textColor="primary"
              indicatorColor="primary"
            >
              <Tab
                component={RouterLink}
                to="#"
                label={t('financial.generate_debts.tab_unitary_debts')}
                {...a11yProps(0)}
              />
              <Tab
                component={RouterLink}
                to="#"
                label={t('financial.generate_debts.tab_massive_debts')}
                {...a11yProps(1)}
              />
            </Tabs>
            <CustomTabPanel value={tabValue} index={0}>
              <GenerateDebts />
            </CustomTabPanel>
            <CustomTabPanel keepmounted value={tabValue} index={1}>
              <GenerateMassiveDebts />
            </CustomTabPanel>
            {/* {false && <Checkout />} */}
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        md={'auto'}
        lg={4}
        sx={{ pt: { xs: 1, lg: 0 }, display: tabValue === 1 ? 'none' : '' }}
      >
        {/* display: activeStep !== 1 ? 'none' : '' */}
        <Grid item xs={12} sx={{ padding: 1, bgcolor: 'background.paper', borderRadius: 2 }}>
          <TempBillingData />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DebtsPaymentsScreen;
