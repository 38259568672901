import { useMemo, FC, useState, useEffect } from 'react';

import { Button, Grid, Typography } from '@material-ui/core';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { useGetAvailableStudentDebtsQuery } from '../slices/paymentsApiSlice';
import { setDebtorId, setSelectedDebts } from '../slices/paymentsSlice';
import { useGetPayMethodsByStudentQuery } from '../../../maintenance/debts-payment/slices/debtsPaymentApiSlice';
import { PayBalance } from '../../../maintenance/debts-payment/interfaces/debtsPayment.interface';
import DownloadAccountState from './DownloadAccountStatus';
import useFormatDate from '../../../shared/hooks/useFormatDate';
interface Props {
  goToPay: () => void;
}

const AccountStatus: FC<Props> = (props) => {
  const { goToPay } = props;

  const dispatch = useAppDispatch();

  const { formatDate } = useFormatDate();

  const [positiveBalance, setPositiveBalance] = useState('');
  const { currentRecordStudent, currentProfile, currentStudent } = useAppSelector(
    (state) => state.access
  );

  const { data = [] } = useGetAvailableStudentDebtsQuery({
    profileId: currentProfile?.profileId!,
    studentId: currentStudent?.id!,
    ...(currentRecordStudent && { student_record: currentRecordStudent?.id }),
  });

  const { data: positiveBalances = [], isLoading: isLoadingPBs } = useGetPayMethodsByStudentQuery({
    profileId: currentProfile?.profileId!,
    studentId: currentStudent?.id!,
  });

  const totalAmount = useMemo(() => {
    let amount = 0;
    //amount = Math.trunc(amount * 100) / 100;
    data.forEach((el) => {
      amount += +el?.apply_early_payment!
        ? Number(el.net_value_early_payment)
        : Number(el.fees_balance);
    });

    return amount.toFixed(2);
  }, [data]);

  const handleGoToPay = () => {
    if (!data.length) return;
    dispatch(setSelectedDebts(data));
    if (data.length) dispatch(setDebtorId(Number(data[0].debtor_id)));
    goToPay();
  };
  useEffect(() => {
    if (!isLoadingPBs && positiveBalances) {
      let positiveBalanceValue = Number((positiveBalances[0] as PayBalance)?.pay_balance!);
      setPositiveBalance(positiveBalanceValue.toFixed(2));
    }
  }, [isLoadingPBs, positiveBalances]);

  return (
    <Grid container p={1} spacing={1} justifyContent={'space-between'}>
      <Grid item xs={6}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h4" component="p">
              Datos del alumno
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" component="p" color="primary">
              FACULTAD: {currentRecordStudent?.education_level.father?.edu_name.toUpperCase() || ''}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" component="p" color="primary">
              FECHA: {formatDate(new Date(), { withTime: true })}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={6} sx={{ textAlign: 'right' }}>
        <Grid container direction="column" spacing={1} justifyContent={'space-between'}>
          {/* <Grid item xs={12}>
            <Typography variant="h4" component="p">
              Valor pendiente de pago
            </Typography>
          </Grid> */}
          <Grid item xs={12}>
            <Typography variant="h4" component="p" fontWeight={'normal'} color="primary">
              Saldo pendiente a pagar: <b>${totalAmount || '0'}</b>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4" component="p" fontWeight={'normal'} color="primary">
              Saldo a favor: <b>${positiveBalance || '0'}</b>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              onClick={handleGoToPay}
              disabled={!data.length}
              sx={{
                textTransform: 'none',
              }}
            >
              Pagar ahora
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ textAlign: 'center' }}>
        <Grid container direction="column" spacing={1} justifyContent={'space-between'}>
          <Grid item xs={12}>
            <DownloadAccountState
              studentId={currentStudent?.id!}
              student_record={currentRecordStudent?.id}
              isIcon={false}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AccountStatus;
