import { useState, useEffect, useCallback, useMemo } from 'react';

import {
  Autocomplete,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  useTheme,
  Checkbox,
  Button,
  CircularProgress,
  MenuList,
  MenuItem,
} from '@material-ui/core';
import { IconLock, IconLockOpen, IconClipboardList, IconReceiptRefund } from '@tabler/icons';
import { useHistory, useRouteMatch } from 'react-router-dom';
import PictureAsPdfOutlinedIcon from '@material-ui/icons/PictureAsPdfOutlined';
import DatePicker from '@material-ui/lab/DatePicker';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import { format, isValid, parseISO } from 'date-fns';

import { useTranslation } from 'react-i18next';
import download from 'downloadjs';

import { useAppSelector, useAppDispatch } from '../../../../hooks/useRedux';
import { useGetCashPointsQuery, useLazyGetReportPdfQuery } from '../slices/cashPointsApiSlice';
import CustomPopper from '../../../shared/components/popper/CustomPopper';

import {
  openCloseBatchModalWith,
  openCloseModalWith,
  openStartModalWith,
  setCashPointList,
  setCurrentCashPointCpState,
  setCurrentCashPointDateFilter,
  setTotalAmount,
  updateCashPointsTable,
  setCahsPointToRefund,
  setOpenRefundModal,
  setIsSuccessRefund,
} from '../slices/cashPointsSlice';

import useGuard from '../hooks/useGuard';
import SearchPaginatedTable, {
  FilterArrow,
} from '../../../shared/components/tables/SearchPaginatedTable';
import Chip from '../../../shared/components/extended/Chip';
import { openStates } from '../constants';
import {
  controlSelectionArr,
  showNumberStringWithDecimals,
  showWithDecimals,
} from '../../../shared/helpers';
import { useTableSearch } from '../../../../hooks/useTableSearch';
import { useGetLocaleDateLang } from '../../../../translation/hooks/useGetLocaleDateLang';
import { returnValidDate } from '../../../shared/helpers/dates';
// import { LanguagesOptions } from '../../../../translation/interfaces';

const CashPointsTable = () => {
  const theme = useTheme();
  const { path } = useRouteMatch();
  const history = useHistory();

  const { t } = useTranslation();
  const {
    lang,
    // langKey
  } = useGetLocaleDateLang();

  const {
    cpTableCpState: cashPointState,
    cpTableDateFilter: deadline,
    isSuccessRefund,
  } = useAppSelector((state) => state.cashPoints);

  const dispatch = useAppDispatch();

  //checkbox selected
  const [selected, setSelected] = useState<number[]>([]);
  const [isLoadingDownloadPdf, setIsLoadingDownloadPdf] = useState(false);
  const [downloadingPdfId, setDownloadingPdfId] = useState<string | number | null>(null);
  const [filterArrow, setFilterArrow] = useState<FilterArrow>(null!);

  const [getPDF] = useLazyGetReportPdfQuery();

  //funciones filtro
  const { page, perPage, search } = useAppSelector((state) => state.cashPoints.cashPointsTable);
  const { setPage, setPerPage, setSearch, resetTable } = useTableSearch({
    page,
    perPage,
    search,
    updateFunction: updateCashPointsTable,
  });

  const setCashPointState = useCallback(
    (cashPointState: '1' | '0') => {
      dispatch(setCurrentCashPointCpState(cashPointState));
    },
    [dispatch]
  );

  const setDeadlineDate = useCallback(
    (date: string) => {
      dispatch(setCurrentCashPointDateFilter(date));
    },
    [dispatch]
  );

  const { isLoading, error, data, isFetching } = useGetCashPointsQuery(
    {
      page,
      perPage,
      search,
      ...(deadline && isValid(parseISO(deadline)) && { deadline }),
      ...(cashPointState && { is_open: cashPointState }),
      ...(filterArrow && { sort: filterArrow.name, type_sort: filterArrow.direction }),
    },
    { refetchOnMountOrArgChange: 30 }
  );

  const openedCashPoints = useMemo(() => {
    if (data?.data) return data?.data.filter((dt) => Number(dt.is_closed) === 0);
    else return [];
  }, [data?.data]);

  //cargar en un estado la vista actual de la tabla
  useEffect(() => {
    if (data) {
      dispatch(setCashPointList(data.data));
      if (data.total_cash_amount) dispatch(setTotalAmount(+data.total_cash_amount));
    }
  }, [data, dispatch]);

  const closeCashPoint = async (cashPointId: number) => {
    dispatch(openCloseModalWith(cashPointId));
  };

  const openCashPoint = async (
    emission_point: string,
    stablishment_point: string,
    user_id: string
  ) => {
    dispatch(openStartModalWith({ emission_point, stablishment_point, user_id }));
  };

  //cerrar cajas en lote
  const closeBatchCashPoints = () => {
    dispatch(openCloseBatchModalWith(selected));
    setSelected([]);
  };

  const { hasAccess } = useGuard();

  //
  //control de checkboxs

  //resetea la seleccion al cambiar de estado de la data en la tabla
  useEffect(() => {
    setSelected([]);
  }, [perPage, cashPointState]);

  //check por fila
  const handleClick = (event: React.ChangeEvent<HTMLInputElement>, cashPointId: number) => {
    const newSelected = controlSelectionArr(selected, cashPointId);

    setSelected(newSelected);
  };

  //check all
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelectedId =
        data?.data
          .filter((dt) => Number(dt.is_closed) === 0 && !selected.includes(dt.id))
          .map((n) => +n.id) || [];
      setSelected((prevState) => [...prevState, ...newSelectedId]);
      return;
    }

    // if (event.target.indeterminate) {

    // }

    // si ya esta el select all y solo se quiere quitar la seleccion de la pagina actual
    const mappedData = data?.data.map((n) => +n.id) || [];
    const newSelection = selected.filter((sel) => !mappedData.some((mp) => mp === sel));

    setSelected(newSelection);
  };

  const goToReport = (cashPointId: number) => {
    history.push(`${path}/${cashPointId}/reporte`);
  };

  const datafetch = data?.data!.map((cp, i) => ({
    select: cp,
    user: cp.name_user_cash_point || '-',
    openingDate: cp.opening_date,
    closeDate: cp.deadline,
    status: cp.is_closed,
    amountCollected: showWithDecimals(parseFloat(cp.amount_collected)),
    amountReversed: showWithDecimals(parseFloat(cp.amount_rejected!)),
    total: showWithDecimals(parseFloat(cp.cashPointValue?.toString()!)),
    options: { data: cp, index: i },
  }));

  // Open refund modal
  const openRefundModal = async (cashPointId: number) => {
    await dispatch(setCahsPointToRefund(cashPointId));
    await dispatch(setOpenRefundModal(true));
  };

  const downloadPdf = async (id: string | number, grouped: number) => {
    setIsLoadingDownloadPdf(true);
    setDownloadingPdfId(id);
    const { data } = await getPDF({
      id,
      grouped,
    });
    const blob = new Blob([data], { type: 'application/pdf' });
    download(blob, `Report-${id}.pdf`, 'application/pdf');
    setIsLoadingDownloadPdf(false);
    setDownloadingPdfId(null);
  };

  // Reset date and state of the table
  useEffect(() => {
    if (isSuccessRefund) {
      setDeadlineDate(format(new Date(), 'yyyy-MM-dd'));
      setCashPointState('1');
      dispatch(setIsSuccessRefund(false));
    }
  }, [isSuccessRefund, setCashPointState, setDeadlineDate, dispatch]);

  return (
    <SearchPaginatedTable
      data={datafetch!}
      headers={{
        select: (
          <Checkbox
            color="primary"
            disabled={!openedCashPoints.length}
            indeterminate={
              data?.data &&
              !!selected.length &&
              selected.filter((sel) => openedCashPoints.some((dt) => dt.id === sel)).length <
                (openedCashPoints?.length || 0)
            }
            checked={
              data?.data &&
              !!selected.length &&
              !!openedCashPoints?.length &&
              !!openedCashPoints?.every((dt) => selected.some((sel) => sel === dt.id)) &&
              selected.length >= (openedCashPoints?.length || 0)
            }
            onChange={handleSelectAllClick}
            inputProps={{
              'aria-label': 'select all cash points',
            }}
          />
        ) as any,
        user: t('financial.close_cash_point.table.user'),
        openingDate: t('financial.close_cash_point.table.opening_date'),
        closeDate: t('financial.close_cash_point.table.close_date'),
        status: t('financial.close_cash_point.table.status'),
        amountCollected: t('financial.close_cash_point.table.amount_collected'),
        amountReversed: t('financial.close_cash_point.table.amount_reversed'),
        total: t('financial.close_cash_point.table.amount_total'),
        options: t('financial.close_cash_point.table.options'),
      }}
      listFilterArrows={{
        user: 'name_user_cash_point',
        openingDate: 'opening_date',
        closeDate: 'deadline',
        status: 'is_closed',
        amountCollected: 'o_amount_collected',
      }}
      totalRowKeys={['amountCollected', 'amountReversed', 'total']}
      totalRowLabel="Total: $"
      filterArrow={filterArrow}
      setFilterArrow={setFilterArrow}
      keyExtractor={(item) => `${item.options.data.id.toString()}-${item.options.index}`}
      searchPlacehoder={t('financial.close_cash_point.table.search_placeholder')}
      isLoading={isLoading}
      isFetching={isFetching}
      error={error}
      perPage={perPage}
      setPerPage={setPerPage}
      total={data?.total || 0}
      page={page}
      setPage={setPage}
      numHeader={7}
      search={search}
      setSearch={setSearch}
      customRenderers={{
        select: (row) => (
          <>
            <Checkbox
              checked={selected.some((r) => r === Number(row.options.data.id))}
              onChange={(e) => handleClick(e, Number(row.options.data.id))}
              disabled={Number(row.status) === 1}
            />
          </>
        ),
        status: (row) => (
          <>
            {Number(row.status) === 0 && (
              <Chip
                chipcolor="success"
                label={t('financial.close_cash_point.table.status.open_badge')}
              />
            )}
            {Number(row.status) === 1 && (
              <Chip
                chipcolor="error"
                label={t('financial.close_cash_point.table.status.close_badge')}
              />
            )}
          </>
        ),
        amountCollected: (row) => `$${showNumberStringWithDecimals(row.amountCollected)}`,
        amountReversed: (row) => `$${showNumberStringWithDecimals(row.amountReversed)}`,
        total: (row) => `$${showNumberStringWithDecimals(row.total)}`,
        options: (row) => (
          <>
            {hasAccess('delete') &&
              Number(row.select.is_closed) === 1 &&
              // Number(row.select.number_ca) !== Number(row.select.numberTxCash) ||
              Number(row.select.total_ca) !== Number(row.select.cashPointValue) && (
                <Tooltip title={t('financial.close_cash_point.table.options.refund')}>
                  <IconButton onClick={() => openRefundModal(row.select.id)}>
                    <IconReceiptRefund size={20} color={theme.palette.error.main} />
                  </IconButton>
                </Tooltip>
              )}

            {/* <IconButton onClick={() => downloadPdf(row.select.id)}></IconButton> */}

            {/* {Number(row.select.is_closed) === 1 && ( */}

            <CustomPopper
              iconButton={
                isLoadingDownloadPdf && downloadingPdfId === row.select.id ? (
                  <CircularProgress size={20} />
                ) : (
                  <PictureAsPdfOutlinedIcon />
                )
              }
              titleButton="Descargar Pdf"
            >
              <MenuList id="split-button-menu" autoFocusItem>
                <MenuItem key={'details'} onClick={() => downloadPdf(row.select.id, 0)}>
                  Reporte detallado
                </MenuItem>

                <MenuItem key={'group'} onClick={() => downloadPdf(row.select.id, 1)}>
                  Reporte agrupado
                </MenuItem>
              </MenuList>
            </CustomPopper>

            {/* )} */}

            {hasAccess('delete') && +row.status === 0 && (
              <Tooltip title={t('financial.close_cash_point.table.options.close_cash_tooltip')}>
                <IconButton onClick={() => closeCashPoint(row.options.data.id)}>
                  <IconLock size={20} color={theme.palette.primary.main} />
                </IconButton>
              </Tooltip>
            )}
            {hasAccess('delete') /*+row.status === 1 && */ && (
              <Tooltip title={t('financial.close_cash_point.table.options.report_cash_tooltip')}>
                <IconButton onClick={() => goToReport(row.options.data.id)}>
                  <IconClipboardList size={20} color={theme.palette.secondary.main} />
                </IconButton>
              </Tooltip>
            )}
            {hasAccess('edit') && +row.status === 1 && (
              <Tooltip title={t('financial.close_cash_point.table.options.open_cash_tooltip')}>
                <IconButton
                  onClick={() =>
                    openCashPoint(
                      row.options.data.emission_point,
                      row.options.data.stablishment_point,
                      row.options.data.user_id
                    )
                  }
                >
                  <IconLockOpen size={20} color={theme.palette.primary.main} />
                </IconButton>
              </Tooltip>
            )}
            {/* {row.principalId === null && hasAccess('edit') && (
              <ListCollective onClick={() => listAllCollectives(row.id)} />
            )} */}
            {/* {!hasAccess('edit') && !hasAccess('delete') && 'Ninguna'} */}
          </>
        ),
      }}
      customHeadersCellsProperties={{
        options: {
          align: 'left',
        },
        user: {
          align: 'center',
        },
        amountCollected: {
          align: 'center',
        },
      }}
      customDataCellsProperties={{
        options: {
          align: 'left',
        },
        user: {
          align: 'center',
        },
        amountCollected: {
          align: 'center',
        },
        amountReversed: {
          align: 'center',
        },
        total: {
          align: 'center',
        },
      }}
      ActionButtons={
        <Grid container alignItems={'center'} justifyContent="flex-end" spacing={1}>
          {hasAccess('delete') && !!selected.length && (
            <Grid item xs={4}>
              <Button variant="contained" startIcon={<IconLock />} onClick={closeBatchCashPoints}>
                {t('financial.close_cash_point.table.action_btn.close_cash')}
              </Button>
            </Grid>
          )}
          <Grid item xs={hasAccess('delete') && !!selected.length ? 4 : 6}>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={lang}>
              <DatePicker
                value={returnValidDate(deadline)}
                onChange={(newValue) => {
                  if (newValue) {
                    setDeadlineDate(format(newValue, 'yyyy-MM-dd'));
                    resetTable();
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    helperText=""
                    label={t('financial.close_cash_point.table.filter.close_date_label')}
                    // disabled
                    placeholder={t('financial.close_cash_point.table.filter.close_date')}
                    onKeyPress={(e) => e.preventDefault()}
                    onKeyDown={(e) => e.preventDefault()}
                    onKeyUp={(e) => e.preventDefault()}
                    fullWidth
                  />
                )}
                clearable
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={hasAccess('delete') && !!selected.length ? 4 : 6}>
            <Autocomplete
              options={openStates}
              getOptionLabel={(option) =>
                option.label[
                  // langKey as unknown as LanguagesOptions<string> ||
                  'es'
                ]
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('financial.close_cash_point.table.filter.states')}
                  placeholder={t('financial.close_cash_point.table.filter.states_placeholder')}
                />
              )}
              value={openStates.find((st) => st.value === cashPointState) || null}
              onChange={(_, values) => {
                if (values) setCashPointState(values?.value);

                resetTable();
              }}
              fullWidth
              clearIcon={null}
            />
          </Grid>
        </Grid>
      }
      // replaceHeader={true}
      // newHeader={
      //   <>
      //     <TableCell>
      //       <Checkbox
      //         color="primary"
      //         indeterminate={
      //           data?.data && selected.length > 0 && selected.length < (data?.data.length || 0)
      //         }
      //         checked={
      //           data?.data &&
      //           (data?.data.length || 0) > 0 &&
      //           selected.length >= (data?.data.length || 0)
      //         }
      //         onChange={handleSelectAllClick}
      //         inputProps={{
      //           'aria-label': 'select all cash points',
      //         }}
      //       />
      //     </TableCell>
      //     <TableCell>Usuario</TableCell>
      //     <TableCell>Fecha Apertura</TableCell>
      //     <TableCell>Fecha Vencimiento</TableCell>
      //     <TableCell>Estado</TableCell>
      //     <TableCell>Recaudación</TableCell>
      //     <TableCell>Opciones</TableCell>
      //   </>
      // }
    />
  );
};

export default CashPointsTable;
