import { Switch } from 'react-router-dom';
import GuardRoute from '../../../../router/guards/GuardRoute';
import AcademicMatterDetailScreen from '../screens/AcademicMatterDetailScreen';
import AcademicPortofolioScreen from '../screens/AcademicPortfolioScreen';

const AcademicPortfolioRouter = () => {
  return (
    <Switch>
      <GuardRoute exact path="/portafolio-academico" component={AcademicPortofolioScreen} />
      <GuardRoute
        exact
        path="/portafolio-academico/cursos/:courseId/:menuOption"
        component={AcademicMatterDetailScreen}
      />
    </Switch>
  );
};

export default AcademicPortfolioRouter;
