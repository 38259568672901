import { Box, Grid } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../../../../hooks/useRedux';
import CustomPaginationAndItems from '../../../../shared/components/tables/CustomPaginationAndItems';
import { useGetAllTasksStudentQuery } from '../../slices/virtualClassroomApiSlice';
import TaskFilter from './TaskFilter';
import TaskList from './TaskList';
import { TasksData } from '../interfaces/tasks.interface';
import { SkeletonGrid } from './SkeletonGrid';
import icon from '../../../../../assets/images/no-hay-tareas.png';
import { useTranslation } from 'react-i18next';
import NoData from '../../../../shared/components/NoData';

const TaskDetail = () => {
  const { t } = useTranslation();
  const [task, setTask] = useState<TasksData[]>();
  const [viewModal, setViewModal] = useState<{
    open: boolean;
    type: 'comment' | 'file';
    detailId: number;
  }>({
    open: false,
    type: 'file',
    detailId: 0,
  });
  const [typeTaks, setTypeTaks] = useState<number>(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(5);

  const {
    course: { courseId },
  } = useAppSelector((state) => state.virtualClassroomStudentShared);

  const { currentStudent } = useAppSelector((state) => state.access);

  const {
    data,
    isLoading: isLoadingTaks,
    error,
    refetch,
  } = useGetAllTasksStudentQuery({
    courseId: courseId!,
    studentId: Number(currentStudent?.id),
    page,
    size: perPage,
    tasks_recent: typeTaks === 1 ? true : false,
    tasks_expire: typeTaks === 2 ? true : false,
  });

  useEffect(() => {
    if (data) {
      setTask(data.data);
    }
  }, [data]);

  return (
    <Box>
      {task && task.length > 0 ? (
        <>
          <TaskFilter
            handleChangeSelect={setTypeTaks}
            valueStateSelect={typeTaks}
            setTask={setTask}
          />
          {!isLoadingTaks && task ? (
            <>
              <TaskList
                tasks={task!}
                modalOptions={{
                  viewModal,
                  setViewModal,
                  refetch,
                }}
              />
              <CustomPaginationAndItems
                error={error}
                isLoading={isLoadingTaks}
                page={page}
                perPage={perPage}
                setPage={setPage}
                setPerPage={setPerPage}
                total={data?.total || 0}
                perPageBox={true}
                paginationBox={true}
              />
            </>
          ) : (
            <Grid container spacing={2}>
              {SkeletonGrid(perPage)}
            </Grid>
          )}
        </>
      ) : (
        <NoData
          image={icon}
          subtitle={t('students.virtual_classroom.task.no.info.description')}
          title={t('students.virtual_classroom.task.no.info.title')}
        />
      )}
    </Box>
  );
};

export default TaskDetail;
