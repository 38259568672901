// import { Course } from '../interfaces/subjectsByPeriods';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { setCurrentCourse as setCurrentCourseStudent } from '../../virtual-classroom/slices/virtualClassroomSlice';
import { useHistory } from 'react-router-dom';
import { useGetCourseQuery } from '../../../maintenance/courses/slices/courseApiSlice';
import { setCurrentCourse as setCurrentCourseTeacher } from '../../../teachers/virtual-classroom/shared/virtualClassroomSharedSlice';
// import { tenantsVc } from '../../../shared/constants/virtualClassroomDict';
interface activity {
  isTeacher: boolean;
  activityId?: number;
}
const useGoToVirtualClassroom = (
  courseId: number,
  path: string = 'inicio',
  /**
   * TODO: el parametro 'Teacher' esta mal ejecutado en este componente (no es reusable), con el parametro isTeacher se efectuara el mismo comportamiento que con estudiante
   *  para el path que se envie sea armado fuera de el hook y el hook solo reciba la ruta e intercale la raiz en base al rol.
   */
  isTeacher: boolean = false,
  /**
   * !No usar este parametro, refactorizarlo por el "isTeacher" en el dasboard de aula virtual del docente
   */
  Teacher: activity = { activityId: 0, isTeacher: false }
): { isFetching: boolean } => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const { currentProfile } = useAppSelector((state) => state.access);
  // const { tenant } = useAppSelector((state) => state.auth);

  const { data: dataCourse, isFetching } = useGetCourseQuery(
    {
      profileId: currentProfile?.profileId!,
      courseId,
    },
    { skip: !courseId }
  );

  if (courseId && dataCourse?.id) {
    const data = {
      courseParalel: dataCourse?.parallel?.par_name!,
      courseHourhandDescription: dataCourse?.hourhand?.hour_description!,
      courseId: dataCourse?.id!,
      courseName: dataCourse?.matter?.mat_name!,
      collaborator: dataCourse?.collaborator?.user?.person?.pers_full_name!,
      MatterId: dataCourse?.matter?.id!,
      periodName: dataCourse?.period?.per_name!,
      vcType: dataCourse?.type_classroom_virtual || 'native',
      campusName: dataCourse?.classroom?.campus?.cam_name || 'N/A',
      collaboratorIdentification: dataCourse?.collaborator?.user?.person?.pers_identification!,
      collaboratorEmail: dataCourse?.collaborator?.user?.email || 'N/A',
      start_date: dataCourse?.start_date,
      end_date: dataCourse?.end_date,
    };

    // FIX: se verifica que exista el parametro sdk_secret que contiene el link a moodle
    // si existe se redirecciona a moodle
    // si no existe se redirecciona a la pagina de inicio de aula virtual

    // if (dataCourse.type_classroom_virtual !== 'native' && serviceCredentials?.sdk_secret) {
    //   window.open(serviceCredentials?.sdk_secret, '_blank');
    // }
    // // !Parche temporal con las url no nativas de aula virtual quemadas (hasta que se determine como debe ser ese flujo)
    // else if (dataCourse?.type_classroom_virtual !== 'native' && tenant in tenantsVc)
    //   window.open(tenantsVc[tenant].url);
    // else {
    if (isTeacher) {
      dispatch(setCurrentCourseTeacher(data));
      localStorage.setItem('virtualClassroomTeacher', JSON.stringify(data));
      history.push(`/administrar-aula-virtual/${path === 'inicio' ? 'contenido' : path}`);
    } else {
      dispatch(setCurrentCourseStudent(data));
      localStorage.setItem('virtualClassroomStudent', JSON.stringify(data));

      Teacher.isTeacher
        ? history.push(`/administrar-aula-virtual/actividades/${Teacher.activityId}/detail`)
        : history.push(`/aula-virtual-estudiante/${path}`);
    }
    // }
  }

  return { isFetching };
};

export default useGoToVirtualClassroom;
