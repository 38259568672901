import { Switch, Redirect } from 'react-router-dom';

import { useAppSelector } from '../../../../hooks/useRedux';

import GeneralApplicationsScreen from '../screens/GeneralApplicationsScreen';
import PreviewApplication from '../screens/PreviewApplicationScreen';
import ActaApplicationScreen from '../screens/ActaApplicationScreen';

import GuardRoute from '../../../../router/guards/GuardRoute';

import {
  subPermissionKeys,
  //subPermissionCreateActa,
} from '../constants/permissions';

const GeneralApplicationsRouter = () => {
  const { currentModule } = useAppSelector((state) => state.access);

  const hasPermission = subPermissionKeys.groupKey in currentModule?.permissions!;

  if (!hasPermission) return <Redirect to="/dashboard" />;

  return (
    <Switch>
      <GuardRoute
        exact
        path="/administrar-solicitudes"
        component={GeneralApplicationsScreen}
        permissionKey={subPermissionKeys.groupKey}
        subPermissionKey={subPermissionKeys.read}
      />
      <GuardRoute
        exact
        path="/administrar-solicitudes/:app/revision"
        component={PreviewApplication}
        permissionKey={subPermissionKeys.groupKey}
        subPermissionKey={subPermissionKeys.show}
      />
      <GuardRoute
        exact
        path="/administrar-acta-solicitud"
        component={ActaApplicationScreen}
        permissionKey={subPermissionKeys.groupKey}
        // subPermissionKey={subPermissionKeys.read}
        subPermissionKey={subPermissionKeys.createActa}
      />
    </Switch>
  );
};

export default GeneralApplicationsRouter;
