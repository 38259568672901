import { useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { IconButton, Grid, useTheme, Typography } from '@material-ui/core';
import { IconArrowNarrowLeft } from '@tabler/icons';

import EditCampusForm from '../components/EditCampusForm';

import MainCardForm from '../../../shared/components/cards/MainCardForm';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { useLazyGetCampusByIdQuery } from '../slices/campusApiSlice';
import { setCampusList } from '../slices/campusSlice';
import SimpleFormOneColumn from '../../../shared/components/forms/skeletons/SimpleFormOneColumn';

const EditCampusScreen = () => {
  const theme = useTheme();
  const history = useHistory();

  const dispatch = useAppDispatch();

  const location = useLocation();

  const { campusId } = useParams<{ campusId: string }>();

  const { campusList } = useAppSelector((state) => state.campus);

  const [getCampus, { isFetching }] = useLazyGetCampusByIdQuery();

  useEffect(() => {
    if (!campusList.length && !isFetching) {
      getCampus({
        campusId: +campusId,
      })
        .unwrap()
        .then((response) => {
          if (response) dispatch(setCampusList([response!]));
        })
        .catch((error) => console.log(error));
    }
  }, [getCampus, isFetching, campusId, campusList.length, dispatch]);

  return (
    <MainCardForm>
      <Grid item xs={12}>
        <Grid item container alignItems="center" gap={1} sx={{ cursor: 'pointer' }}>
          <IconButton onClick={() => history.push(`/${location.pathname.split('/')[1]}`)}>
            <IconArrowNarrowLeft size={30} color={theme.palette.text.dark} />
          </IconButton>
          <Typography variant="h2">Editar</Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} md={8} lg={6} sx={{ mt: 2 }}>
        {!!campusList.length ? <EditCampusForm /> : <SimpleFormOneColumn />}
      </Grid>
    </MainCardForm>
  );
};

export default EditCampusScreen;
