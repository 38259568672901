import { Box, Card, Divider, Grid, Typography } from '@material-ui/core';

import { renderSkeletonTable } from '../../../../shared/helpers/render';
import { useAppSelector } from '../../../../../hooks/useRedux';
import { useGetTermsAndConditionsQuery } from '../../slices/institutionalInfoApiSlice';
import DocumentCard from '../DocumentCard';

const TermsStep = () => {
  const { currentProfile } = useAppSelector((state) => state.access);
  const { data, isLoading, isSuccess } = useGetTermsAndConditionsQuery({
    user_profile_id: currentProfile?.profileId!,
  });

  return (
    <>
      <Grid sx={{ my: 3 }}>
        <Typography variant="h2">Listado de Documentos</Typography>
      </Grid>

      <Divider sx={{ mt: '16px' }} />

      {/* classroom cards */}
      <Box sx={{ mt: '1rem' }}>
        <Grid container>
          {isLoading
            ? [...Array(3)].map((el, id) => (
                <Grid key={id} container item xs={12} md={6} lg={4} justifyContent={'center'} p={1}>
                  <Card
                    sx={{
                      width: '100%',
                      height: '100%',
                      border: '1px solid gainsboro',
                      marginX: 6,
                    }}
                  >
                    {renderSkeletonTable(2, 1)}
                  </Card>
                </Grid>
              ))
            : null}

          {isSuccess && data && data.legal_papers_file.length > 0
            ? data.legal_papers_file.map((el) => (
                <DocumentCard key={el.file_route} legal_paper_file={el} />
              ))
            : null}
        </Grid>
      </Box>
    </>
  );
};

export default TermsStep;
