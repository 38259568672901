import { Grid, Typography } from '@material-ui/core';
import MainCard from '../../../shared/components/cards/MainCard';
import ThirdTimeRegistrationForm from '../components/ThirdTimeRegistrationForm';

import { useTranslation } from 'react-i18next';

const ThirdTimeRegistrationScreen = () => {
  const { t } = useTranslation();

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">
          {t('students.menu.applications.academic.third-registration')}
        </Typography>
      </Grid>
      <Grid item xs={12} md={8} lg={6} sx={{ mt: 2, minHeight: '55vh' }}>
        <ThirdTimeRegistrationForm />
      </Grid>
    </Grid>
  );
};
export default ThirdTimeRegistrationScreen;
