import {
  Autocomplete,
  FormHelperText,
  Grid,
  InputLabel,
  Skeleton,
  TextField,
} from '@material-ui/core';
import { DatePicker, LocalizationProvider } from '@material-ui/lab';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { useAppSelector } from '../../../../../hooks/useRedux';
import { useGetCatalogByAcronymQuery } from '../../../../shared/slices/catalogApi';
import { useGetCatalogByKeywordQuery } from '../../../../shared/slices/catalogApi';
import esLocale from 'date-fns/locale/es';
import { useFormStyles } from '../../constants/styles';

interface PersonalDataForm {
  // Datos personales
  identificationTypeKeyword: string;
  identification: string;
  firstName: string;
  secondName: string;
  lastName: string;
  secondLastName: string;
  gender: string;
  birthDate: Date;
  homeAddress: string;
  homePhone: string;
  cellPhone: string;
  nacionalityKeyword: number;
  cityKeyword: number;
  currentCityKeyword: number;
  sectorKeyword: number;
}

const PersonalEditDataStep = () => {
  const { currentProfile } = useAppSelector((state) => state.access);

  const profileId = currentProfile!.profileId;

  const classes = useFormStyles();

  const { getFieldProps, setFieldValue, errors, touched, setTouched, values } =
    useFormikContext<PersonalDataForm>();

  const { data: identificationTypes = [], isLoading: isLoadingIT } = useGetCatalogByAcronymQuery({
    profileId,
    acronym: 'TI',
  });

  const { data: genders = [], isLoading: isLoadingG } = useGetCatalogByKeywordQuery({
    profileId,
    keyword: 'GE',
  });

  const { data: cities = [], isLoading: isLoadingCities } = useGetCatalogByAcronymQuery({
    profileId,
    acronym: 'C',
  });

  const { data: nacionalities = [], isLoading: isLoadingNacionalities } =
    useGetCatalogByAcronymQuery({
      profileId,
      acronym: 'NA',
    });

  const { data: sectores = [], isLoading: isLoadingSectores } = useGetCatalogByAcronymQuery({
    profileId,
    acronym: 'S',
  });

  useEffect(() => {
    setTouched({});
  }, [setTouched]);

  return (
    <Grid container spacing={4} alignItems="flex-start">
      <Grid item xs={12} md={6} container spacing={2}>
        <Grid item xs={12}>
          <InputLabel className={classes.inputLabel}>Tipo de Identificación</InputLabel>
          {isLoadingIT ? (
            <Skeleton variant="rectangular" sx={{ width: '100%', borderRadius: 3, height: 50 }} />
          ) : (
            <Autocomplete
              options={identificationTypes}
              loading={isLoadingIT}
              loadingText="Cargando tipos de indentificación..."
              getOptionLabel={(option) => option.cat_name}
              defaultValue={identificationTypes.find(
                (it) => it.cat_keyword === values.identificationTypeKeyword
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Seleccione un tipo de identificación"
                  error={Boolean(
                    errors.identificationTypeKeyword && touched.identificationTypeKeyword
                  )}
                />
              )}
              onChange={(_, value) =>
                setFieldValue('identificationTypeKeyword', value?.cat_keyword)
              }
              fullWidth
              clearIcon={null}
            />
          )}
          {errors.identificationTypeKeyword && touched.identificationTypeKeyword && (
            <FormHelperText error>{errors.identificationTypeKeyword}</FormHelperText>
          )}
        </Grid>
        <Grid item xs={12}>
          <InputLabel className={classes.inputLabel}>Identificación</InputLabel>
          <TextField
            fullWidth
            placeholder="Ingrese su número de identificación"
            error={Boolean(errors.identification && touched.identification)}
            {...getFieldProps('identification')}
          />
          {errors.identification && touched.identification && (
            <FormHelperText error>{errors.identification}</FormHelperText>
          )}
        </Grid>
        <Grid item xs={12} container spacing={2}>
          <Grid item xs={6}>
            <InputLabel className={classes.inputLabel}>Primer nombre</InputLabel>
            <TextField
              fullWidth
              placeholder="Primer nombre"
              {...getFieldProps('firstName')}
              error={Boolean(errors.firstName && touched.firstName)}
            />
            {errors.firstName && touched.firstName && (
              <FormHelperText error>{errors.firstName}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={6}>
            <InputLabel className={classes.inputLabel}>Segundo nombre</InputLabel>
            <TextField
              fullWidth
              placeholder="Segundo nombre"
              {...getFieldProps('secondName')}
              error={Boolean(errors.secondName && touched.secondName)}
            />
            {errors.secondName && touched.secondName && (
              <FormHelperText error>{errors.secondName}</FormHelperText>
            )}
          </Grid>
        </Grid>

        <Grid item xs={12} container spacing={2}>
          <Grid item xs={6}>
            <InputLabel className={classes.inputLabel}>Apellido paterno</InputLabel>
            <TextField
              fullWidth
              placeholder="Apellido paterno"
              {...getFieldProps('lastName')}
              error={Boolean(errors.lastName && touched.lastName)}
            />
            {errors.lastName && touched.lastName && (
              <FormHelperText error>{errors.lastName}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={6}>
            <InputLabel className={classes.inputLabel}>Apellido materno</InputLabel>
            <TextField
              fullWidth
              placeholder="Apellido materno"
              {...getFieldProps('secondLastName')}
              error={Boolean(errors.secondLastName && touched.secondLastName)}
            />
            {errors.secondLastName && touched.secondLastName && (
              <FormHelperText error>{errors.secondLastName}</FormHelperText>
            )}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <InputLabel className={classes.inputLabel}>Teléfono convencional</InputLabel>
          <TextField
            fullWidth
            placeholder="Télefono convencional"
            {...getFieldProps('homePhone')}
            error={Boolean(errors.homePhone && touched.homePhone)}
          />
          {errors.homePhone && touched.homePhone && (
            <FormHelperText error>{errors.homePhone}</FormHelperText>
          )}
        </Grid>

        <Grid item xs={12}>
          <InputLabel className={classes.inputLabel}>Celular</InputLabel>
          <TextField
            fullWidth
            placeholder="Celular"
            {...getFieldProps('cellPhone')}
            error={Boolean(errors.cellPhone && touched.cellPhone)}
          />
          {errors.cellPhone && touched.cellPhone && (
            <FormHelperText error>{errors.cellPhone}</FormHelperText>
          )}
        </Grid>

        <Grid item xs={12}>
          <InputLabel className={classes.inputLabel}>Género</InputLabel>
          {isLoadingG ? (
            <Skeleton variant="rectangular" sx={{ width: '100%', borderRadius: 3, height: 50 }} />
          ) : (
            <Autocomplete
              options={genders}
              loading={isLoadingG}
              loadingText="Cargando géneros..."
              getOptionLabel={(option) => option.cat_name}
              defaultValue={genders.find((g) => g.cat_name === values.gender)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Seleccione un género"
                  error={Boolean(errors.gender && touched.gender)}
                />
              )}
              onChange={(_, value) => setFieldValue('gender', value?.cat_name)}
              fullWidth
              clearIcon={null}
            />
          )}
          {errors.gender && touched.gender && (
            <FormHelperText error>{errors.gender}</FormHelperText>
          )}
        </Grid>

        <Grid item xs={12}>
          <InputLabel className={classes.inputLabel}>Fecha de nacimiento</InputLabel>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
            <DatePicker
              value={values.birthDate}
              onChange={(newValue) => {
                setFieldValue('birthDate', newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  helperText=""
                  placeholder="Fecha de nacimiento"
                  error={Boolean(errors.birthDate && touched.birthDate)}
                  fullWidth
                />
              )}
            />
          </LocalizationProvider>
          {errors.birthDate && touched.birthDate && (
            <FormHelperText error>{errors.birthDate}</FormHelperText>
          )}
        </Grid>
      </Grid>

      {/* Container align rigth */}
      <Grid item xs={12} md={6} container spacing={2}>
        <Grid item xs={12}>
          <InputLabel className={classes.inputLabel}>Nacionalidad</InputLabel>
          {isLoadingNacionalities ? (
            <Skeleton variant="rectangular" sx={{ width: '100%', borderRadius: 3, height: 50 }} />
          ) : (
            <Autocomplete
              options={nacionalities}
              loading={isLoadingNacionalities}
              loadingText="Cargando nacionalidades..."
              getOptionLabel={(option) => option.cat_name}
              defaultValue={nacionalities.find((n) => n.id === values.nacionalityKeyword)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(errors.nacionalityKeyword && touched.nacionalityKeyword)}
                  placeholder="Seleccionar una nacionalidad"
                />
              )}
              onChange={(_, value) => setFieldValue('nacionalityKeyword', value?.id)}
              fullWidth
              clearIcon={null}
            />
          )}
          {errors.nacionalityKeyword && touched.nacionalityKeyword && (
            <FormHelperText error>{errors.nacionalityKeyword}</FormHelperText>
          )}
        </Grid>

        <Grid item xs={12}>
          <InputLabel className={classes.inputLabel}>Ciudad natal</InputLabel>
          {isLoadingCities ? (
            <Skeleton variant="rectangular" sx={{ width: '100%', borderRadius: 3, height: 50 }} />
          ) : (
            <Autocomplete
              options={cities}
              loading={isLoadingCities}
              loadingText="Cargando ciudades..."
              getOptionLabel={(option) => option.cat_name}
              defaultValue={cities.find((c) => c.id === values.cityKeyword)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(errors.cityKeyword && touched.cityKeyword)}
                  placeholder="Seleccionar una ciudad"
                />
              )}
              onChange={(_, value) => setFieldValue('cityKeyword', value?.id)}
              fullWidth
              clearIcon={null}
            />
          )}
          {errors.cityKeyword && touched.cityKeyword && (
            <FormHelperText error>{errors.cityKeyword}</FormHelperText>
          )}
        </Grid>

        <Grid item xs={12}>
          <InputLabel className={classes.inputLabel}>Ciudad de residencia</InputLabel>
          {isLoadingCities ? (
            <Skeleton variant="rectangular" sx={{ width: '100%', borderRadius: 3, height: 50 }} />
          ) : (
            <Autocomplete
              options={cities}
              loading={isLoadingCities}
              loadingText="Cargando ciudades..."
              getOptionLabel={(option) => option.cat_name}
              defaultValue={cities.find((c) => c.id === values.currentCityKeyword)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(errors.currentCityKeyword && touched.currentCityKeyword)}
                  placeholder="Seleccionar una ciudad"
                />
              )}
              onChange={(_, value) => setFieldValue('currentCityKeyword', value?.id)}
              fullWidth
              clearIcon={null}
            />
          )}
          {errors.currentCityKeyword && touched.currentCityKeyword && (
            <FormHelperText error>{errors.currentCityKeyword}</FormHelperText>
          )}
        </Grid>

        <Grid item xs={12}>
          <InputLabel className={classes.inputLabel}>Dirección</InputLabel>
          <TextField
            fullWidth
            placeholder="Dirección domiciliaria"
            error={Boolean(errors.homeAddress && touched.homeAddress)}
            {...getFieldProps('homeAddress')}
          />

          {errors.homeAddress && touched.homeAddress && (
            <FormHelperText error>{errors.homeAddress}</FormHelperText>
          )}
        </Grid>

        <Grid item xs={12}>
          <InputLabel className={classes.inputLabel}>Sector</InputLabel>
          {isLoadingSectores ? (
            <Skeleton variant="rectangular" sx={{ width: '100%', borderRadius: 3, height: 50 }} />
          ) : (
            <Autocomplete
              options={sectores}
              loading={isLoadingSectores}
              loadingText="Cargando sectores..."
              getOptionLabel={(option) => option.cat_name}
              defaultValue={sectores.find((s) => s.id === values.sectorKeyword)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(errors.sectorKeyword && touched.sectorKeyword)}
                  placeholder="Seleccionar un sector"
                />
              )}
              onChange={(_, value) => setFieldValue('sectorKeyword', value?.id)}
              fullWidth
              clearIcon={null}
            />
          )}
          {errors.sectorKeyword && touched.sectorKeyword && (
            <FormHelperText error>{errors.sectorKeyword}</FormHelperText>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PersonalEditDataStep;
