import { mainApi } from '../../../../api/mainApi';
import { middlewareApi } from '../../../middleware/middlewareApi';
import { BooksResponse, BookItem } from '../interfaces/books.response.interface';
import { Classification } from '../interfaces/classifications.interface';

const booksApi = middlewareApi.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * @GET books
     */
    getBooks: builder.query<
      BooksResponse,
      {
        avaialable_copies?: number;
        page?: number;
        size?: number;
        search?: string;
        sort?: string;
        type_sort?: string;
        type_book?: string;
        type_all?: 1 | 0;
        line_research?: string | number;
        institution_barcode?: string;
        tags?: number[];
        studentId?: number;
        tutorId?: number;
        careerId?: number;
        facultyId?: number;
      }
    >({
      queryFn: async ({
        avaialable_copies,
        page,
        size,
        search,
        sort,
        type_sort,
        type_book,
        type_all = 1,
        institution_barcode,
        line_research,
        tags,
        studentId,
        tutorId,
        careerId,
        facultyId,
      }) => {
        try {
          const { data } = await mainApi.get<BooksResponse>('books', {
            params: {
              avaialable_copies,
              page,
              size,
              search,
              sort,
              type_sort,
              type_book,
              type_all,
              institution_barcode,
              line_research,
              tags,
              studentId,
              tutorId,
              careerId,
              facultyId,
            },
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),
    /**
     * @GET all books
     */
    getAllBooks: builder.query<
      BookItem[],
      {
        avaialable_copies?: number;

        type_sort?: string;
        type_book?: string;
      }
    >({
      queryFn: async ({ avaialable_copies, type_sort, type_book }) => {
        try {
          const { data } = await mainApi.get<BookItem[]>('books?data=all', {
            params: {
              avaialable_copies,

              type_sort,
              type_book,
            },
          });
          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
    }),
    /**
     * @POST add book
     */
    addBook: builder.mutation<
      any,
      {
        payload: {
          title: string;
          subtitle: string;
          isbn: string;
          version: string;
          initial_copies_number: number;
          origin: string;
          book_cover?: string;
          book_url?: string;
          institution_barcode: string;
          symbol?: string;
          publish_date: string;
          type_book: string;
          status_id: string;
          reason: string;
          procedencia: string;
          description?: string;
          page_count?: number;
          serie?: string;
          content_notes?: string;
          bib_clasification_id: string;
          line_research_id: string;
          invoice?: {
            num_factura?: string;
            proveedor_factura?: string;
            date?: string;
            precio?: string;
            total?: number;
          };

          locations: string[] | number[];
          tags: number[];
          book_inventory: string;
          book_collection: string;
        };
      }
    >({
      queryFn: async ({ payload }) => {
        try {
          const { data } = await mainApi.post<any>('books', {
            ...payload,
            tags: payload.tags.filter((tag) => tag !== 0),
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),
    /**
     * @PUT add book
     */
    editBook: builder.mutation<
      any,
      {
        id: number;
        payload: {
          title: string;
          subtitle: string;
          isbn: string;
          version: string;
          initial_copies_number: number;
          origin: string;
          book_cover?: string;
          book_url?: string;
          institution_barcode: string;
          symbol?: string;
          publish_date: string;
          type_book: string;
          status_id: string;
          reason: string;
          procedencia: string;
          description?: string;
          page_count?: number;
          serie?: string;
          content_notes?: string;
          bib_clasification_id: string;
          line_research_id: string;
          invoice?: {
            num_factura?: string;
            proveedor_factura?: string;
            date?: string;
            precio?: string;
            total?: number;
          };

          locations: string[] | number[];
          tags: number[];
          book_inventory?: string;
          book_collection?: string;
        };
      }
    >({
      queryFn: async ({ id, payload }) => {
        try {
          const { data } = await mainApi.put<any>(`books/${id}`, {
            ...payload,
            tags: payload.tags.filter((tag) => tag !== 0),
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),
    /**
     * @DELETE book
     */
    deleteBook: builder.mutation<
      any,
      {
        id: number;
      }
    >({
      queryFn: async ({ id }) => {
        try {
          const { data } = await mainApi.delete(`books/${id}`);
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),
    /**
     * @GET materias y classifications
     */
    getSubjectsClassifications: builder.query<
      Classification[],
      {
        user_profile_id: number;
      }
    >({
      queryFn: async ({ user_profile_id }) => {
        try {
          const { data } = await mainApi.get<Classification[]>('classifications', {
            params: {
              user_profile_id,
              data: 'all',
              get_books: 0,
            },
          });

          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
    }),
  }),
});

export const {
  useGetBooksQuery,
  useLazyGetBooksQuery,
  useGetAllBooksQuery,
  useAddBookMutation,
  useEditBookMutation,
  useDeleteBookMutation,
  useGetSubjectsClassificationsQuery,
} = booksApi;
