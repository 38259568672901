import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/useRedux';
import CustomSideDialog from '../../../../../shared/components/dialogs/CustomSideDialog';
import {
  setIntegralEvaluationsIdSelected,
  setIntegralEvaluationsSideDialogOpen,
} from '../slices/integralEvaluationSlice';
import IntegralEvaluationForm from './IntegralEvaluationForm';

const IntegralEvaluationSideDialog = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { integralEvaluationsIdSelected } = useAppSelector((state) => state.integralEvaluation);

  const handleClose = () => {
    dispatch(setIntegralEvaluationsSideDialogOpen(false));
    dispatch(setIntegralEvaluationsIdSelected(null));
  };

  return (
    <CustomSideDialog
      open
      handleCloseDialog={handleClose}
      title={
        integralEvaluationsIdSelected
          ? t('maintenance.integral_evaluation.edit.title')
          : t('maintenance.integral_evaluation.add.title')
      }
    >
      <IntegralEvaluationForm />
    </CustomSideDialog>
  );
};

export default IntegralEvaluationSideDialog;
