import { useEffect, useState } from 'react';

import { Alert, createStyles, makeStyles, Snackbar } from '@material-ui/core';
import {
  Route,
  Switch,
  //  Redirect,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { useIndexedDB } from 'react-indexed-db';

// routes
import PrivateRouter from './PrivateRouter';
import AuthRouter from './AuthRouter';

// guards
import PrivateRoute from './guards/PrivateRoute';
import PublicRoute from './guards/PublicRoute';

// screens
import ProfilesScreen from '../modules/shared/screens/ProfilesScreen';
import ProfileCardSkeleton from '../modules/shared/screens/LoadingScreen';
import {
  useLazyGetTenantQuery,
  useVerifySessionMutation,
  useLogoutMutation,
  useVerifyTokenCookieMutation,
} from '../modules/auth/slices/authApiSlice';
import ModulesScreen from '../modules/shared/screens/ModulesScreen';
import { useAppSelector, useAppDispatch } from '../hooks/useRedux';
import FirstChangePasswordScreen from '../modules/maintenance/configurations/screens/FirstChangePasswordScreen';
import { useGetPermissionsByModuleQuery } from '../modules/auth/slices/authApiSlice';
import { mapPermissions } from '../modules/auth/helpers';
import { useControlModalStates } from '../modules/shared/hooks/useControlModalStates';
import { TransitionToRight } from '../modules/shared/helpers/Transitions';
import Ok200Screen from '../modules/auth/screens/Ok200Screen';
import PendingEvaluationsDialog from '../modules/shared/user-evaluations/components/dialogs/PendingEvaluationsDialog';
import { useGetUserPendingEvaluationsCountQuery } from '../modules/shared/user-evaluations/slices/userEvaluationsApiSlice';

//modificaciones a los estilos globales
const useStyles = makeStyles(() =>
  createStyles({
    '@global': {
      // '#body': {
      //   overscrollBehavior: 'auto',
      // },
      // ocultar panel zoom
      '#zmmtg-root': {
        display: 'none',
      },
    },
  })
);

const authRoutes = [
  '/login',
  '/recuperar-clave',
  '/restablecer-clave/:token',
  '/saml',
  '/saml/:googleSSO',
  '/auth/google',
];

const AppRouter = () => {
  useStyles();

  const {
    isAuth,
    userInfo: { remember_token },
  } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();

  const { open, handleCloseModal, handleOpenModalWith } = useControlModalStates();

  const { currentModule, currentProfile } = useAppSelector((state) => state.access);

  const [openPendingEvaluations, setOpenPendingEvaluations] = useState(false);

  const [verifyTokenSession, { isLoading }] = useVerifyTokenCookieMutation();
  const [verifySession, { isLoading: isLoadingVerifyCookie, isUninitialized }] =
    useVerifySessionMutation();
  const [logout] = useLogoutMutation();
  const [getTenant] = useLazyGetTenantQuery();

  const { getByIndex, add, update } = useIndexedDB('config');

  const module = localStorage.getItem('currentModule');
  const currentRol = localStorage.getItem('currentRol');

  const [tokenClient, setTokenClient] = useState('');

  useEffect(() => {
    getByIndex('keyword', 'token').then((data) => {
      setTokenClient(data?.token_client || '');
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const [isPermission, setIsPermission] = useState<boolean>(false);

  const {
    isFetching: isLoadingPermissions,
    isError: isErrorPermissions,
    error: errorPermissions,
    data: dataPermissions,
    refetch: refetchPermissions,
    // isUninitialized: isUninitializedPermissiones,
  } = useGetPermissionsByModuleQuery(
    {
      profileId: currentProfile?.profileId!,
      keyword: module!,
      rolId: currentRol!,
      token_client: tokenClient || '',
    },
    { skip: !module || !currentProfile?.profileId }
  );

  const {
    data: countUPEC,
    isLoading: isLoadingUPEC,
    isUninitialized: isUninitializedUPEC,
  } = useGetUserPendingEvaluationsCountQuery(
    {
      profileId: currentProfile?.profileId!,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    if (location.pathname.split('/')[1] === 'dashboard' && isErrorPermissions) refetchPermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    const verify = async () => {
      getTenant({});

      let cookieToken = '';
      await verifyTokenSession({})
        .unwrap()
        .then((newToken) => {
          if (newToken) {
            localStorage.setItem('x-token', newToken.access_token);
            cookieToken = newToken.access_token;
          }
        })
        .catch((error) => console.log('Error Verify', error));

      const token = cookieToken || localStorage.getItem('x-token')!;

      verifySession(token)
        // .unwrap()
        .then((response) => {
          if ('error' in response) {
            throw new Error(response.error as any);
          }
        })
        .catch((error) => {
          logout({});
        });
    };

    verify();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if ((countUPEC?.number_evaluation_pending || 0) > 0) {
      const open =
        ((countUPEC?.number_evaluation_pending || 0) > 0 ||
          (countUPEC?.number_evaluation_integral_pending || 0) > 0) &&
        !authRoutes?.some((route) => route === location.pathname) &&
        !location.pathname.includes('evaluaciones');
      setOpenPendingEvaluations(open);
    }
  }, [countUPEC, location]);

  useEffect(() => {
    if (
      (countUPEC?.number_evaluation_integral_pending || 0) > 0 &&
      (countUPEC?.number_evaluation_pending || 0) === 0
    ) {
      const open =
        ((countUPEC?.number_evaluation_pending || 0) > 0 ||
          (countUPEC?.number_evaluation_integral_pending || 0) > 0) &&
        !authRoutes?.some((route) => route === location.pathname);
      setOpenPendingEvaluations(open);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countUPEC, location]);

  const handleClosePendingDialog = () => {
    setOpenPendingEvaluations(false);
  };

  useEffect(() => {
    if (dataPermissions && isAuth && module) {
      // ?se trata la data para obtener el token cliente de los permisos (ultimo objeto en el arreglo)
      const permissions = [...dataPermissions];
      const tokenClient = permissions.pop();

      if ((tokenClient as any).token_client)
        if (!tokenClient)
          add({ token_client: (tokenClient as any).token_client, keyword: 'token' }).then(
            (event) => {
              // console.log('ID Generated: ', event.target.result);
              mapPermissions(dispatch, permissions);
            },
            (error) => {
              console.log(error);
            }
          );
        else
          update({ id: 1, token_client: (tokenClient as any).token_client, keyword: 'token' }).then(
            (event) => {
              // console.log('ID Generated: ', event.target.result);
              mapPermissions(dispatch, permissions);
            },
            (error) => {
              console.log(error);
            }
          );
      else mapPermissions(dispatch, permissions);

      // setIsPermission(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataPermissions, dispatch, isAuth, module]);

  // console.log(isPermission);

  useEffect(() => {
    if (isErrorPermissions) {
      // localStorage.removeItem('currentModule');
      history.replace('/modulos');
      handleOpenModalWith();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isErrorPermissions]);
  // if (isErrorPermissions) return <Redirect to="/modulos?err=f" />;

  if (
    isLoading ||
    isLoadingPermissions ||
    isUninitialized ||
    isLoadingVerifyCookie ||
    isLoadingUPEC
  )
    return <ProfileCardSkeleton />;

  if (isAuth && !remember_token) return <FirstChangePasswordScreen />;


  return (
    <>
      {isErrorPermissions && (
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleCloseModal}
          // action={action}
          TransitionComponent={TransitionToRight}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <Alert severity="warning">{errorPermissions}</Alert>
        </Snackbar>
      )}

      {!isLoadingUPEC && !isUninitializedUPEC && openPendingEvaluations && (
        <PendingEvaluationsDialog
          pendingEvaluations={countUPEC?.number_evaluation_pending!}
          pendingApproves={countUPEC?.number_evaluation_integral_pending!}
          handleClose={handleClosePendingDialog}
        />
      )}

      <Switch>
        <Route exact path="/200" component={Ok200Screen} />
        <PublicRoute component={AuthRouter} path={authRoutes} />

        <PrivateRoute exact component={ProfilesScreen} path="/perfiles" />

        <PrivateRoute exact component={ModulesScreen} path="/modulos" />

        <PrivateRoute
          component={
            //si no existe perfil y modulo seleccionado retornara una de esas screens
            currentProfile && currentModule && !isErrorPermissions
              ? PrivateRouter
              : currentProfile
              ? ModulesScreen
              : ProfilesScreen
          }
          path="/"
        />
      </Switch>
    </>
  );
};

export default AppRouter;
