import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import InputLabel from '../../../shared/components/forms/InputLabel';
import Autocomplete from '@material-ui/core/Autocomplete';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import {
  fireDialogSubjectsOnline,
  setCurrentPeriodId,
  setEstudentInfoAcademic,
  setIsPayment,
  setSubjectStatusIdSelected,
} from '../slices/subjectsRegisterSlice';
import { Period } from '../../../maintenance/periods/interfaces/periods.interfaces';
import { StudentRecords } from '../../../maintenance/students/interfaces/studentRecords.interface';
import { Button, Chip, FormHelperText, Typography } from '@material-ui/core';
import { useEffect } from 'react';
import { IconList } from '@tabler/icons';
import { useTranslation } from 'react-i18next';
import TextFieldFetching from '../../../shared/components/TextFieldFetching';
import { subjectsRegisterStatus } from '../../../teachers/academic-couseling/students-asigned/constants';

export const useStyles = makeStyles((theme) => ({
  labelTitle: {
    marginBlockEnd: 6,
    fontWeight: 500,
    color: theme.palette.grey[700],
  },
  labelHeader: {
    marginBlockEnd: 6,
    fontWeight: 500,
    color: theme.palette.text.dark,
  },
}));

interface Props {
  periodsAvailables: Period[];
  isLoadingPeriods: boolean;
  isLoadingER: boolean;
  studentRecordsAvaliables: StudentRecords[];
}

const PreProcessRegisterScreen = ({
  periodsAvailables,
  isLoadingPeriods,
  isLoadingER,
  studentRecordsAvaliables,
}: Props) => {
  const { t } = useTranslation();

  const { studentId } = useParams<{ studentId: string }>();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { userInfo, studentRecordReference } = useAppSelector((state) => state.auth);
  const { isOnline, isPayment, currentPeriodId, carSubjects, subjectStatusIdSelected } =
    useAppSelector((state) => state.subjectsRegister);

  const studentRecord = studentRecordReference?.find(
    (sr) => sr.id === userInfo?.studentInfo?.studentRecordId
  );

  useEffect(() => {
    if (periodsAvailables.length === 1) {
      dispatch(setCurrentPeriodId(periodsAvailables[0].id));
    }
    // console.log('personId', studentRecordsAvaliables[0].student.user?.person_id);
    if (studentRecordsAvaliables.length === 1) {
      dispatch(
        setEstudentInfoAcademic({
          studentRecordId: Number(studentRecordsAvaliables[0].id),
          curriculumId: Number(studentRecordsAvaliables[0].mesh_id),
          offerId: Number(studentRecordsAvaliables[0].education_level.offer_id),
          typeStudent: studentRecordsAvaliables[0].type_student.te_acronym,
          offerName: studentRecordsAvaliables[0].education_level.offer?.off_name,
          personId: Number(studentRecordsAvaliables[0].student.user?.person_id),
          name: studentRecordsAvaliables[0].student?.user?.person?.pers_full_name,
        })
      );
    }
  }, [periodsAvailables, studentRecordsAvaliables, dispatch]);

  useEffect(() => {
    if (periodsAvailables && periodsAvailables.length === 1) {
      dispatch(setCurrentPeriodId(periodsAvailables[0].id));
      if (!isOnline) dispatch(setIsPayment(false));
    }
  }, [dispatch, isOnline, periodsAvailables]);

  return (
    <>
      {periodsAvailables.length === 1 && studentRecordsAvaliables.length === 1 ? (
        <Grid container item spacing={2} flexDirection="row" mb={2}>
          <Grid item>
            <Grid container flexDirection="row">
              <Typography sx={{ mr: 1 }} className={classes.labelTitle}>
                {t('students.subjects.registration.career')}
              </Typography>
              <Typography className={classes.labelHeader}>
                {studentRecordsAvaliables[0]?.education_level.edu_name ||
                  studentRecord?.education_level.edu_name}
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container flexDirection="row">
              <Typography sx={{ mr: 1 }} className={classes.labelTitle}>
                {t('students.subjects.registration.period')}
              </Typography>
              <Typography className={classes.labelHeader}>
                {periodsAvailables[0]?.per_name || t('students.subjects.registration.no_period')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          justifyContent={'space-between'}
          spacing={2}
          alignItems={'center'}
          sx={{ mb: 3 }}
        >
          <Grid item container spacing={2} xs={12} md={7}>
            {studentId && studentRecordsAvaliables.length > 1 && (
              <Grid item xs={12} md={6}>
                <InputLabel>{t('students.subjects.registration.career')}</InputLabel>
                <Autocomplete
                  options={studentRecordsAvaliables}
                  loading={isLoadingER}
                  loadingText={t('students.subjects.registration.loading')}
                  noOptionsText={t('students.subjects.registration.no_data')}
                  getOptionLabel={(option) => `${option.education_level.edu_name}`}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={t('students.subjects.registration.career.placeholder')}
                    />
                  )}
                  onChange={(_, value) => {
                    if (value) {
                      dispatch(
                        setEstudentInfoAcademic({
                          studentRecordId: Number(value.id),
                          curriculumId: Number(value.mesh_id),
                          offerId: Number(value.education_level.offer_id),
                          typeStudent: value.type_student.te_acronym,
                          offerName: value.education_level.offer?.off_name,
                          personId: Number(studentRecordsAvaliables[0].student.user?.person_id),
                        })
                      );
                    }
                  }}
                />
                <FormHelperText>{t('students.subjects.registration.career.helper')}</FormHelperText>
              </Grid>
            )}
            {/* Seleccion del periodo */}
            <Grid item xs={12} md={6}>
              <InputLabel>{t('students.subjects.registration.period')}</InputLabel>
              {!isLoadingPeriods ? (
                <Autocomplete
                  options={periodsAvailables}
                  loading={isLoadingPeriods}
                  loadingText={t('students.subjects.registration.period.loading')}
                  noOptionsText={t('students.subjects.registration.period.no_data')}
                  getOptionLabel={(option) => `${option.per_name}`}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={t('students.subjects.registration.period.placeholder')}
                    />
                  )}
                  //value={
                  //  (periodsAvailables as any).find(
                  //    (periods: any) => periods.id === currentPeriodId
                  //  ) || null
                  //}
                  onChange={(_, value) => {
                    if (value) {
                      dispatch(setCurrentPeriodId(value.id));
                      if (!isOnline) dispatch(setIsPayment(false));
                    }
                  }}
                  defaultValue={periodsAvailables.length === 1 ? periodsAvailables[0] : null}
                />
              ) : (
                <TextFieldFetching />
              )}
              <FormHelperText>{t('students.subjects.registration.period.helper')}</FormHelperText>
            </Grid>
            {/* Seleccion del estado */}{' '}
            {isPayment && (
              <Grid item xs={12} md={6}>
                <InputLabel>{t('utils.status')}</InputLabel>
                {!isLoadingPeriods ? (
                  <Autocomplete
                    options={subjectsRegisterStatus}
                    loading={isLoadingPeriods}
                    loadingText={t('utils.loading')}
                    noOptionsText={t('utils.no_data')}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField {...params} placeholder={t('utils.status.placeholder')} />
                    )}
                    value={
                      subjectsRegisterStatus.find((cs) => cs === subjectStatusIdSelected) || null
                    }
                    onChange={(_, value) => {
                      dispatch(setSubjectStatusIdSelected(value || null));
                    }}
                  />
                ) : (
                  <TextFieldFetching />
                )}
                <FormHelperText>{t('Seleccione el estado')}</FormHelperText>
              </Grid>
            )}
          </Grid>
          {isPayment && isOnline && currentPeriodId && (
            <Grid item>
              <Button
                variant="contained"
                startIcon={<IconList size={20} />}
                endIcon={
                  carSubjects.length > 0 ? (
                    <Chip label={carSubjects.length} sx={{ color: '#ffff', fontSize: 'small' }} />
                  ) : null
                }
                onClick={() => dispatch(fireDialogSubjectsOnline(true))}
              >
                {t('students.subjects.online_cart')}
              </Button>
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
};

export default PreProcessRegisterScreen;
