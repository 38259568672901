import { useEffect, useState, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import {
  Autocomplete,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
  FormControlLabel,
  Switch,
  Skeleton,
  Checkbox,
  Typography,
} from '@material-ui/core';

import { useFormik } from 'formik';

import ErrorAlert from '../../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../../shared/components/alerts/SuccessAlert';
import DateRangePicker, { DateRange } from '@material-ui/lab/DateRangePicker';

import esLocale from 'date-fns/locale/es';

import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';

import { PeriodUpdateShema } from '../../constants/validation-schemas';

import {
  useUpdatePeriodMutation,
  useGetAcademicOfferingsAvailablesQuery,
} from '../../slices/periodsApiSlice';

import { useGetAvailablePeriodTypesQuery } from '../../../periods-types/slices/periodTypesApiSlice';

import { statusAvailables } from '../../../../shared/constants/resourceStatus';
import { useFormStyles } from '../../constants/styles';
import {
  useGetAvailablesHourhandQuery,
  useGetHourhandsQuery,
} from '../../../hourhands/slices/hourhandsApiSlice';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import { parseISO } from 'date-fns';
import { useGetAvailablesCampusQuery } from '../../../campus/slices/campusApiSlice';
import { useTranslation } from 'react-i18next';
import SearchPaginatedTableAndCollapsibleItems from '../../../../shared/components/tables/SearchPaginatedTableAndCollapsibleItems';
import { useTableSearch } from '../../../../../hooks/useTableSearch';
import { setHourhandsTable, sethourhandsSelected } from '../../slices/periodsSlice';
import { ArrowUpward, ImportExport } from '@material-ui/icons';
import ImportHourhandsDialog from '../dialogs/ImportHourhandsDialog';

const EditPeriodForm = () => {
  const { t } = useTranslation();

  const history = useHistory();
  const { periodId } = useParams<{ periodId: string }>();

  const { periodList } = useAppSelector((state) => state.periods);
  const { currentProfile } = useAppSelector((state) => state.access);

  const {
    hourhandsTable: { page, perPage, search, filterArrow },
    hourhandsSelected,
  } = useAppSelector((state) => state.periods);

  const { setPage, setPerPage, setSearch } = useTableSearch({
    page,
    perPage,
    search,
    filterArrow,
    updateFunction: setHourhandsTable,
  });

  const classes = useFormStyles();

  const [showAlerts, setShowAlerts] = useState(false);
  const [checkAll, setCheckALl] = useState(false);
  const [update, setUpdate] = useState(false);
  const [openDialogImport, setOpenDialogImport] = useState(false);

  const [datafetch, setDatafetch] = useState<
    {
      numberItem: number;
      chck: any;
      name: string;
    }[]
  >([]);

  const buttonRef = useRef<HTMLButtonElement>(null);

  const [updatePeriod, { isLoading: isUpdating, isSuccess, isError, error }] =
    useUpdatePeriodMutation();

  const { data: periodTypesAvailables = [], isLoading: isLoadingTP } =
    useGetAvailablePeriodTypesQuery({ profileId: currentProfile?.profileId! });

  const { data: campusAvailables = [], isLoading: isLoadingCAMP } = useGetAvailablesCampusQuery({
    profileId: currentProfile?.profileId!,
  });

  const { data: hourhandsAvailablesAll = [] } = useGetAvailablesHourhandQuery({
    profileId: currentProfile?.profileId!,
  });

  const {
    data: hourhandsAvailables,
    isLoading: isLoadingHA,
    isFetching,
    error: errorHA,
  } = useGetHourhandsQuery({
    page,
    perPage,
    search,
    sort: 'hour_description',
    type_sort: 'desc',
  });

  const dispatch = useAppDispatch();

  const { data: acdOfferingsAvailables = [], isLoading: isLoadingACD } =
    useGetAcademicOfferingsAvailablesQuery({
      profileId: currentProfile?.profileId!,
    });

  const periodToEdit = periodList.find((p) => p.id === Number(periodId));

  const [periodDate, setPeriodDate] = useState<DateRange<Date>>([null, null]);

  const onChangesPeriodProcess = (newDatesValues: DateRange<Date>) => {
    const [start, end] = newDatesValues;
    //Update formik values
    setFieldValue('start_process', start);
    setFieldValue('end_process', end);

    setPeriodDate(newDatesValues);
  };

  const handleClickOrder = async () => {
    if (hourhandsSelected.length && hourhandsAvailables && hourhandsAvailablesAll.length) {
      await setUpdate(true);

      const hourhandsFilter = hourhandsAvailablesAll.filter((item) =>
        hourhandsSelected.find((item2) => item2 === item.id)
      );

      console.log(hourhandsFilter);
      const hourhandsFilter2 = hourhandsAvailables?.data.filter(
        (item) => !hourhandsSelected.find((item2) => item2 === item.id)
      );

      const hourHandsFinal = [...hourhandsFilter, ...hourhandsFilter2].map((e, index) => ({
        numberItem: index + 1,
        chck: e,
        name: `${e.hour_description}`,
      }));

      setDatafetch(hourHandsFinal);

      setTimeout(() => {
        setUpdate(false);
      }, 70);
    }
  };

  const { getFieldProps, errors, touched, handleSubmit, setFieldValue, values } = useFormik({
    initialValues: {
      name: periodToEdit?.per_name || '',
      minMatter: periodToEdit?.per_min_matter_enrollment || 1,
      maxMatter: periodToEdit?.per_max_matter_enrollment || 1,
      periodTypeId: periodToEdit?.type_period_id || 1,
      campus: periodToEdit?.campus.map((el) => el.id) || [],
      offers: periodToEdit?.offers.map((el) => el.id) || [],
      hourhandsIds: periodToEdit?.hourhands.map((el) => el.id) || [],
      numFees: periodToEdit?.per_num_fees || 0,
      numFouls: periodToEdit?.num_fouls || 0,
      start_process: periodToEdit?.start_process,
      end_process: periodToEdit?.end_process,
      isPayEnrollment: Number(periodToEdit?.per_pay_enrollment) === 1 ? true : false,
      feesPerEnrollement: periodToEdit?.per_fees_enrollment || 0,
      statusId: periodToEdit?.status_id || 1,
    },
    validationSchema: PeriodUpdateShema,
    onSubmit: async (values) => {
      await updatePeriod({
        profileId: currentProfile?.profileId!,
        periodId: periodToEdit?.id!,
        periodPayload: {
          per_name: values.name,
          start_process: values.start_process,
          end_process: values.end_process,
          per_min_matter_enrollment: values.minMatter.toString(),
          per_max_matter_enrollment: values.maxMatter.toString(),
          per_num_fees: values.numFees.toString(),
          per_fees_enrollment: values.feesPerEnrollement.toString(),
          per_pay_enrollment: values.isPayEnrollment ? 1 : 0,
          status_id: values.statusId.toString(),
          campus: values.campus,
          offers: values.offers,
          hourhands: values.hourhandsIds,
          type_period_id: values.periodTypeId.toString(),
          num_fouls: values.numFouls,
        },
      });
      setShowAlerts(true);
      window.scrollTo(0, 0);
    },
  });

  useEffect(() => {
    if (hourhandsAvailables) {
      setDatafetch(
        hourhandsAvailables?.data?.map((e, index) => ({
          numberItem: index + 1,
          chck: e,
          name: `${e.hour_description}`,
        }))
      );
    }
  }, [hourhandsAvailables]);

  useEffect(() => {
    setUpdate(true);
    setTimeout(() => {
      setUpdate(false);
    }, 50);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hourhandsSelected]);

  useEffect(() => {
    if (Array.isArray(hourhandsAvailablesAll)) {
      setCheckALl(
        hourhandsSelected.filter((item) =>
          hourhandsAvailablesAll!.find((item2) => item2.id === item)
        ).length === hourhandsAvailablesAll.length
      );
    } else {
      setCheckALl(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hourhandsAvailablesAll]);

  useEffect(() => {
    if (hourhandsSelected.length) {
      setFieldValue('hourhandsIds', hourhandsSelected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hourhandsSelected]);

  useEffect(() => {
    if (periodToEdit) {
      dispatch(sethourhandsSelected(periodToEdit.hourhands.map((el) => el.id)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periodToEdit]);

  useEffect(() => {
    setPeriodDate([parseISO(periodToEdit?.start_process!), parseISO(periodToEdit?.end_process!)]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction="column" gap={2}>
        <Grid item>
          {isError && showAlerts && (
            <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
          )}

          {isSuccess && showAlerts && (
            <SuccessAlert
              message={t('academic.periods_list.edit.success')}
              handleDismiss={() => setShowAlerts(false)}
              timeoutCallback={{
                timer: 3,
                callbackFn: () => {
                  history.push('/administrar-periodos');
                },
              }}
            />
          )}
        </Grid>

        <Grid item container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>
              {t('academic.periods_list.edit.name')}
            </InputLabel>
            <TextField
              fullWidth
              error={Boolean(errors.name && touched.name)}
              placeholder={t('academic.periods_list.edit.name.placeholder')}
              {...getFieldProps('name')}
            />
            {errors.name && touched.name && <FormHelperText error>{t(errors.name)}</FormHelperText>}
          </Grid>

          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>
              {t('academic.periods_list.edit.period_type')}
            </InputLabel>

            {isLoadingTP ? (
              <Skeleton variant="rectangular" sx={{ width: '100%', borderRadius: 3, height: 50 }} />
            ) : (
              <Autocomplete
                options={periodTypesAvailables}
                loading={isLoadingTP}
                loadingText={t('academic.periods_list.edit.period_type.loading')}
                getOptionLabel={(option) => option.tp_name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(errors.periodTypeId && touched.periodTypeId)}
                  />
                )}
                defaultValue={periodTypesAvailables.find(
                  (p) => p.id === Number(periodToEdit?.type_period_id)
                )}
                onChange={(_, value) => setFieldValue('periodTypeId', value?.id || '')}
                fullWidth
                clearIcon={null}
              />
            )}

            {errors.periodTypeId && touched.periodTypeId && (
              <FormHelperText error>{t(errors.periodTypeId)}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12} container spacing={2}>
            <Grid item xs={6}>
              <InputLabel className={classes.inputLabel}>
                {t('academic.periods_list.edit.min_subjects')}
              </InputLabel>
              <TextField
                disabled
                type="number"
                fullWidth
                placeholder={t('academic.periods_list.edit.min_subjects.placeholder')}
                {...getFieldProps('minMatter')}
                error={Boolean(errors.minMatter && touched.minMatter)}
              />
              {errors.minMatter && touched.minMatter && (
                <FormHelperText error>{t(errors.minMatter)}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={6}>
              <InputLabel className={classes.inputLabel}>
                {t('academic.periods_list.edit.max_subjects')}
              </InputLabel>
              <TextField
                type="number"
                disabled
                fullWidth
                placeholder={t('academic.periods_list.edit.max_subjects.placeholder')}
                {...getFieldProps('maxMatter')}
                error={Boolean(errors.maxMatter && touched.maxMatter)}
              />
              {errors.maxMatter && touched.maxMatter && (
                <FormHelperText error>{t(errors.maxMatter)}</FormHelperText>
              )}
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <InputLabel>{t('academic.periods_list.edit.num_fauls')}</InputLabel>
            <TextField
              type="number"
              fullWidth
              InputProps={{ inputProps: { min: 0 } }}
              placeholder={t('academic.periods_list.edit.num_fauls.placeholder')}
              {...getFieldProps('numFouls')}
              error={Boolean(errors.numFouls && touched.numFouls)}
            />
            {errors.numFouls && touched.numFouls && (
              <FormHelperText error>{t(errors.numFouls)}</FormHelperText>
            )}
          </Grid>

          <Grid item container xs={12} justifyContent="flex-end">
            <FormControlLabel
              control={<Switch {...getFieldProps('isPayEnrollment')} />}
              label={t('academic.periods_list.edit.quote_enrollment')}
              checked={values.isPayEnrollment}
            />
            {errors.isPayEnrollment && touched.isPayEnrollment && (
              <FormHelperText error>{t(errors.isPayEnrollment)}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12} container spacing={2}>
            <Grid item xs={6}>
              <InputLabel className={classes.inputLabel}>
                {t('academic.periods_list.edit.num_fees')}
              </InputLabel>
              <TextField
                fullWidth
                type="number"
                error={Boolean(errors.numFees && touched.numFees)}
                placeholder={t('academic.periods_list.edit.num_fees.placeholder')}
                {...getFieldProps('numFees')}
              />
              {errors.numFees && touched.numFees && (
                <FormHelperText error>{t(errors.numFees)}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={6}>
              <InputLabel className={classes.inputLabel}>
                {t('academic.periods_list.edit.quote_enrollment')}
              </InputLabel>
              <TextField
                type="number"
                fullWidth
                placeholder={t('academic.periods_list.edit.quote_enrollment.placeholder')}
                {...getFieldProps('feesPerEnrollement')}
                disabled={!values.isPayEnrollment}
                error={Boolean(errors.feesPerEnrollement && touched.feesPerEnrollement)}
              />
              {errors.feesPerEnrollement && touched.feesPerEnrollement && (
                <FormHelperText error>{t(errors.feesPerEnrollement)}</FormHelperText>
              )}
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>
              {t('academic.periods_list.edit.status')}
            </InputLabel>
            <Autocomplete
              options={statusAvailables}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField {...params} error={Boolean(errors.statusId && touched.statusId)} />
              )}
              defaultValue={statusAvailables.find((sa) => sa.id === periodToEdit?.status.id)}
              onChange={(e, value) => setFieldValue('statusId', value?.id || '')}
              fullWidth
              clearIcon={null}
            />
            {errors.statusId && touched.statusId && (
              <FormHelperText error>{t(errors.statusId)}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>
              {t('academic.periods_list.edit.campus')}
            </InputLabel>

            {isLoadingCAMP ? (
              <Skeleton variant="rectangular" sx={{ width: '100%', borderRadius: 3, height: 50 }} />
            ) : (
              <Autocomplete
                multiple
                options={campusAvailables}
                loading={isLoadingCAMP}
                loadingText={t('academic.periods_list.edit.campus.loading')}
                noOptionsText={t('academic.periods_list.edit.campus.no_data')}
                getOptionLabel={(option) => option.cam_name}
                renderInput={(params) => (
                  <TextField {...params} error={Boolean(errors.campus && touched.campus)} />
                )}
                value={
                  campusAvailables.filter((camp) => values.campus.some((dt) => dt === camp.id)) ||
                  campusAvailables[0]
                }
                onChange={(_, values) =>
                  setFieldValue('campus', [...values.map((v) => v.id)] || '')
                }
                fullWidth
                clearIcon={null}
              />
            )}
            {errors.campus && touched.campus && (
              <FormHelperText error>{t(errors.campus)}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>
              {t('academic.periods_list.edit.edu_levels')}
            </InputLabel>
            <Autocomplete
              multiple
              options={acdOfferingsAvailables}
              loading={isLoadingACD}
              loadingText={t('academic.periods_list.edit.edu_levels.loading')}
              getOptionLabel={(option) => option.off_name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(errors.offers && touched.offers)}
                  placeholder={t('academic.periods_list.edit.edu_levels.placeholder')}
                />
              )}
              value={
                acdOfferingsAvailables.filter((camp) =>
                  values.offers.some((dt) => dt === camp.id)
                ) || acdOfferingsAvailables[0]
              }
              onChange={(_, values) => setFieldValue('offers', [...values.map((v) => v.id)] || '')}
              fullWidth
              clearIcon={null}
            />
            {errors.offers && touched.offers && (
              <FormHelperText error>{t(errors.offers)}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12} marginTop={2}>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
              <DateRangePicker
                startText={null}
                endText={null}
                value={periodDate}
                onChange={onChangesPeriodProcess}
                renderInput={(startProps, endProps) => (
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={3} lg={4} sx={{ pt: { xs: 2, sm: '0 !important' } }}>
                      <InputLabel
                        className={classes.inputLabel}
                        sx={{ textAlign: { xs: 'left', sm: 'right' } }}
                      >
                        {t('academic.periods_list.edit.start_date')}:
                      </InputLabel>
                    </Grid>
                    <Grid item xs={12} sm={9} lg={8}>
                      <TextField
                        fullWidth
                        {...startProps}
                        helperText=""
                        error={Boolean(errors.start_process && touched.start_process)}
                        {...getFieldProps('start_process')}
                      />
                      <FormHelperText>
                        {t('academic.periods_list.edit.start_date.helper')}
                      </FormHelperText>

                      {errors.start_process && touched.start_process && (
                        <FormHelperText error>{t(errors.start_process)}</FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={3} lg={4} sx={{ pt: { xs: 2, sm: '0 !important' } }}>
                      <InputLabel
                        className={classes.inputLabel}
                        sx={{ textAlign: { xs: 'left', sm: 'right' } }}
                      >
                        {t('academic.periods_list.edit.end_date')}:
                      </InputLabel>
                    </Grid>

                    <Grid item xs={12} sm={9} lg={8}>
                      <TextField
                        fullWidth
                        {...endProps}
                        helperText=""
                        error={Boolean(errors.end_process && touched.end_process)}
                        {...getFieldProps('end_process')}
                      />
                      <FormHelperText>
                        {t('academic.periods_list.edit.end_date.helper')}
                      </FormHelperText>
                      {errors.end_process && touched.end_process && (
                        <FormHelperText error>{t(errors.end_process)}</FormHelperText>
                      )}
                    </Grid>
                  </Grid>
                )}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>
              {t('academic.periods_list.edit.schedules')}
            </InputLabel>

            <Typography
              variant="body2"
              color="textSecondary"
              sx={{
                my: 2,
              }}
            >
              Horarios seleccionados: {hourhandsSelected.length}
            </Typography>

            {/* {isLoadingHA ? (
              <Skeleton variant="rectangular" sx={{ width: '100%', borderRadius: 3, height: 50 }} />
            ) : (
              <Autocomplete
                multiple
                options={hourhandsAvailables}
                loading={isLoadingHA}
                loadingText={t('academic.periods_list.edit.schedules.loading')}
                getOptionLabel={(option) => option.hour_description}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(errors.hourhandsIds && touched.hourhandsIds)}
                    placeholder={t('academic.periods_list.edit.schedules.placeholder')}
                  />
                )}
                value={
                  hourhandsAvailables.filter((horarios) =>
                    values.hourhandsIds.some((dt) => dt === horarios.id)
                  ) || hourhandsAvailables[0]
                }
                onChange={(_, values) =>
                  setFieldValue('hourhandsIds', [...values.map((v) => v.id)] || '')
                }
                fullWidth
              />
            )} */}
            <SearchPaginatedTableAndCollapsibleItems
              data={datafetch!}
              headers={{
                numberItem: t('N°'),
                chck: (
                  <FormControlLabel
                    sx={{
                      '& .MuiTypography-body1': {
                        fontSize: '0.8rem',
                      },
                      mx: 0,
                    }}
                    control={
                      <Checkbox
                        inputProps={{ 'aria-label': 'controlled' }}
                        checked={checkAll}
                        onChange={(e) => {
                          setCheckALl(e.target.checked);
                          if (e.target.checked) {
                            const newQuestionsSelected = hourhandsSelected.filter(
                              (item) =>
                                !Boolean(hourhandsAvailablesAll!.find((item2) => item2.id === item))
                            );
                            const hourhandsAvailablesAllIds = hourhandsAvailablesAll!.map(
                              (item) => item.id!
                            );

                            dispatch(
                              sethourhandsSelected([
                                ...newQuestionsSelected,
                                ...hourhandsAvailablesAllIds!,
                              ])
                            );
                          } else {
                            const newQuestionsSelected = hourhandsSelected.filter(
                              (item) =>
                                !Boolean(hourhandsAvailablesAll!.find((item2) => item2.id === item))
                            );
                            dispatch(sethourhandsSelected(newQuestionsSelected));
                          }
                        }}
                      />
                    }
                    label="Todos"
                  />
                ) as any,
                name: t('maintenance.questions.name'),
              }}
              keyExtractor={(item) => item.chck.id!.toString()}
              searchPlacehoder={t('maintenance.questions.search')}
              isLoading={isLoadingHA}
              isFetching={isFetching}
              error={errorHA as any}
              perPage={perPage}
              setPerPage={setPerPage}
              total={hourhandsAvailables?.total || 0}
              page={page}
              setPage={setPage}
              numHeader={6}
              search={search}
              setSearch={setSearch}
              customHeadersCellsProperties={{
                name: {
                  align: 'center',
                },
                chck: {
                  align: 'center',
                },
                numberItem: {
                  align: 'center',
                },
              }}
              customDataCellsProperties={{
                name: {
                  align: 'center',
                },
                chck: {
                  align: 'center',
                },
                numberItem: {
                  align: 'center',
                },
              }}
              customRenderers={{
                chck: ({ chck }) => (
                  <>
                    {update ? null : (
                      <Checkbox
                        inputProps={{ 'aria-label': 'controlled' }}
                        checked={Boolean(hourhandsSelected.find((item) => item === chck.id))}
                        onChange={() => {
                          const isSelected = hourhandsSelected.find((item) => item === chck.id);
                          if (isSelected) {
                            const newQuestionsSelected = hourhandsSelected.filter(
                              (item) => item !== chck.id
                            );
                            setCheckALl(false);
                            dispatch(sethourhandsSelected(newQuestionsSelected));
                          } else {
                            dispatch(sethourhandsSelected([...hourhandsSelected, chck.id]));
                          }
                        }}
                      />
                    )}
                  </>
                ),
              }}
              filtersPos="top"
              ActionButtons={
                <Grid container spacing={1} justifyContent="flex-end">
                  <Grid item xs={12} sm={6} md={12}>
                    <Button
                      ref={buttonRef}
                      variant="outlined"
                      size="large"
                      color="primary"
                      onClick={handleClickOrder}
                      disabled={update}
                      startIcon={update ? <CircularProgress size={15} /> : <ArrowUpward />}
                    >
                      Seleccionados
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={6} md={12}>
                    <Button
                      variant="outlined"
                      size="large"
                      color="primary"
                      onClick={() => {
                        setOpenDialogImport(true);
                      }}
                      disabled={update}
                      startIcon={update ? <CircularProgress size={15} /> : <ImportExport />}
                    >
                      Importar
                    </Button>
                  </Grid>
                </Grid>
              }
            />

            {errors.hourhandsIds && touched.hourhandsIds && (
              <FormHelperText error>{t(errors.hourhandsIds as string)}</FormHelperText>
            )}
          </Grid>
        </Grid>

        <Grid
          item
          container
          alignItems="center"
          justifyContent="flex-end"
          spacing={2}
          sx={{ mt: 1 }}
        >
          <Grid item>
            <Button
              variant="outlined"
              size="large"
              color="primary"
              onClick={() => history.goBack()}
            >
              {t('academic.periods_list.edit.back')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              disabled={isUpdating}
              endIcon={isUpdating && <CircularProgress size={20} />}
            >
              {t('academic.periods_list.edit.submit')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      {openDialogImport && (
        <ImportHourhandsDialog
          handleCloseDialog={() => setOpenDialogImport(false)}
          orderImports={() => {
            buttonRef.current?.click();
          }}
        />
      )}
    </form>
  );
};

export default EditPeriodForm;
