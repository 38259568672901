import DeleteAlert from '../../../../../shared/components/dialogs/DeleteAlert';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/useRedux';
import { useDeleteScopeMutation } from '../slices/scopeApiSlice';
import { setScopesDeleteModalOpen, setScopesIdToDelete } from '../slices/scopeSlice';

const ScopeDeleteDialog = () => {
  const dispatch = useAppDispatch();
  const { scopesIdToDelete, scopesDeleteModalOpen } = useAppSelector((state) => state.scopes);

  const [deleteScope, { isLoading, isSuccess, error, isError }] = useDeleteScopeMutation();

  const handleDelete = () => {
    deleteScope({
      id: scopesIdToDelete!,
    });
  };
  const handleClose = () => {
    dispatch(setScopesIdToDelete(null));
    dispatch(setScopesDeleteModalOpen(false));
  };

  return (
    <DeleteAlert
      open={scopesDeleteModalOpen}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      error={(error as any)}
      handleDelete={handleDelete}
      onClose={handleClose}
    />
  );
};

export default ScopeDeleteDialog;
