import * as Yup from 'yup';

export const InstituteInfoSchema = Yup.object().shape({
  name: Yup.string().required('El nombre es requerido.'),
  province_id: Yup.number()
    .integer('Solo se permiten números enteros.')
    .required('Debe seleccionar una ciudad.')
    .min(1, 'Debe seleccionar una ciudad.'),
  economic_group_id: Yup.number()
    .integer('Solo se permiten números enteros.')
    .required('Debe seleccionar un grupo económico.')
    .min(1, 'Debe seleccionar un grupo económico.'),
  type_institute_id: Yup.number()
    .integer('Solo se permiten números enteros.')
    .required('Debe seleccionar el tipo de instituto.')
    .min(1, 'Debe seleccionar el tipo de instituto.'),
  cat_institute_id: Yup.number()
    .integer('Solo se permiten números enteros.')
    .required('Debe seleccionar la categoría del instituto.')
    .min(1, 'Debe seleccionar la categoría del instituto.'),
  has_agreement: Yup.boolean().required('La opción de convenio es requerida'),
  status_id: Yup.number()
    .integer('Solo se permiten números enteros.')
    .required('Debe seleccionar el estado del instituto.')
    .min(1, 'Debe seleccionar el estado del instituto.'),
});
