export const subPermissionKeys = {
  groupKey: 'matters',

  read: 'matters-listar-matters',
  create: 'matters-crear-matter',
  edit: 'matters-actualizar-matter',
  delete: 'matters-borrar-matter',

  getMatterTypes: 'type-matters-listar-type-matters',
  getEducationLevels: 'education-levels-obtener-nivel-educativo',

  //
}; // satisfies PermissionsKeys;
