import {
  Autocomplete,
  Grid,
  Typography,
  TextField,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Box,
  TableBody,
  useTheme,
  InputAdornment,
  IconButton,
  Checkbox,
  Tooltip,
} from '@material-ui/core';
import { IconSettingsAutomation, IconX } from '@tabler/icons';
import SearchIcon from '@material-ui/icons/Search';
import { useEffect, useState } from 'react';
import { DebounceInput } from 'react-debounce-input';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { useTableSearch } from '../../../../hooks/useTableSearch';
import SubCard from '../../../shared/components/cards/SubCard';
import CustomCollapsibleTable from '../../../shared/components/tables/CustomCollapsibleTable';
// import { renderErrorOrEmptyRow } from '../../../shared/helpers/render';
// import SearchPaginatedTable from '../../../shared/components/tables/SearchPaginatedTable';
import { useGetStudyModalitiesQuery } from '../../meshs/slices/meshsApiSlice';
import { useGetAvailablesParallelsQuery } from '../../parallels/slices/parallelsApiSlice';
import { useGetHourhandsByPeriodQuery } from '../../periods/slices/periodsApiSlice';
import {
  useLazyGetCoursesByFiltersQuery,
  useAssignCoursesMoodleMutation,
  useLazyGetCoursesByFiltersAllQuery,
} from '../slices/courseApiSlice';
import {
  setCourseList,
  setCoursesByFilterResponse,
  updateCoursesFilter,
  updateCoursesTable,
  setCourseToEdit,
  setCourseSideModalOpen,
  openDeleteModalWith,
} from '../slices/courseSlice';
import EditButton from '../../../shared/components/tables/buttons/EditButton';
import DeleteButton from '../../../shared/components/tables/buttons/DeleteButton';
import { useTranslation } from 'react-i18next';
import { isOutRangeDate } from '../helpers';
import { virtualClassrooms } from '../../../shared/constants/virtualClassroomDict';
import ConfirmAlertDialog from './ConfirmAlertDialog';

const CoursesTable = () => {
  const { t } = useTranslation();

  const theme = useTheme();

  const { currentProfile, serviceCredentials } = useAppSelector((state) => state.access);

  const availableMoodle = serviceCredentials?.moodle?.sdk_secret;

  const { coursesFilter, coursesByFilterResponse } = useAppSelector((state) => state.courses);

  const {
    periodId,
    campusId,
    offerDescription,
    educationLevelId,
    parallelId,
    modalityId,
    hourhandId,
    periodEndProcess,
    search,
  } = coursesFilter;

  const dispatch = useAppDispatch();

  const [getCoursesByFilter, { data: coursesData, isLoading, isFetching, error }] =
    useLazyGetCoursesByFiltersQuery();

  const [checked, setChecked] = useState<number[]>([]);

  const [checkAll, setCheckAll] = useState<boolean>(false);

  const [openDialogConfirm, setOpenDialogConfirm] = useState(false);

  const { page, perPage, filterArrow } = useAppSelector((state) => state.courses.coursesTable);
  const { setPage, setPerPage, resetTable, setFilterArrow } = useTableSearch({
    page,
    perPage,
    filterArrow,
    updateFunction: updateCoursesTable,
    search: search || '',
  });

  const dataFetch = coursesByFilterResponse?.data!.map((course, index) => ({
    check: <></>,
    detail: '',
    id: { index: index + ((page - 1) * perPage + 1), id: course.id },
    mat_name: `${course?.cod_matter_migration || ''} - ${course.mat_name || ''}`,
    course_info: `${course.cl_name || ''} - ${course.par_name || ''}`,
    availability: `${course.occupied}/${course.max_capacity}`,
    teacher: course?.pers_full_name || 'N/A',
    start_date: course.start_date,
    end_date: course.end_date,
    actions: course.id,
  }));

  const {
    data: studyModalities = [],
    isLoading: isLoadingM,
    isFetching: isFetchingM,
    // error: errorM,
  } = useGetStudyModalitiesQuery({
    profileId: currentProfile?.profileId!,
  });

  const {
    data: parallels = [],
    isLoading: isLoadingP,
    isFetching: isFetchingP,
    // error: errorM,
  } = useGetAvailablesParallelsQuery({
    profileId: currentProfile?.profileId!,
  });

  const {
    data: hourhands = [],
    isLoading: isLoadingH,
    isFetching: isFetchingH,
  } = useGetHourhandsByPeriodQuery({
    profileId: currentProfile?.profileId!,
    periodId: periodId!,
  });

  const [getAllCourses, { isFetching: isLoadingAllCourses, data: allCoursesAvailables }] =
    useLazyGetCoursesByFiltersAllQuery();

  const [
    assignCoursesMoodle,
    {
      isLoading: isLoadingMoodle,
      isError: isErrorMoodle,
      isSuccess: isSuccessMoodle,
      error: errorMoodle,
    },
  ] = useAssignCoursesMoodleMutation();

  const handleAllCheck = () => {
    if (checkAll) {
      setChecked((list) => [
        ...list.filter((item) => !coursesData?.data.some(({ id }) => id === item)),
      ]);
    } else {
      setChecked((list) => [
        ...new Set([...(coursesData?.data.map(({ id }) => id) || []), ...list]),
      ]);
    }
  };

  const handleCheck = (id: number) => {
    if (checked.some((item) => item === id)) {
      setChecked((list) => list.filter((item) => !(item === id)));
    } else {
      setChecked((list) => [...list, id]);
    }
  };

  useEffect(() => {
    if (
      !!checked.length &&
      checked.filter((item) => coursesData?.data.some((e) => e.id === item)).length ===
        coursesData?.data.length
    ) {
      setCheckAll(true);
    } else {
      setCheckAll(false);
    }
  }, [coursesData, checked]);

  useEffect(() => {
    setChecked([]);
  }, [coursesFilter]);

  useEffect(() => {
    if (!isFetching && coursesData) {
      dispatch(setCoursesByFilterResponse(coursesData));
      dispatch(setCourseList(coursesData.data));
    }
  }, [coursesData, dispatch, isFetching]);

  useEffect(() => {
    // if (!isFetching)
    getCoursesByFilter(
      {
        profileId: currentProfile!.profileId!,
        campusId: campusId!,
        periodId,
        offerDescription,
        educationLevelId,
        ...(parallelId && { parallelId }),
        ...(modalityId && { modalityId }),
        ...(hourhandId && { hourhandId }),
        ...(search && { search }),
        ...(filterArrow && { sort: filterArrow.name, type_sort: filterArrow.direction }),
        page,
        perPage,
      },
      true
    ).then(({ data }) => {
      dispatch(setCoursesByFilterResponse(data!));
      dispatch(setCourseList(data!.data));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parallelId, modalityId, filterArrow, hourhandId, search, page, perPage]);

  const handleFilterChange = (object: any) => {
    dispatch(
      updateCoursesFilter({
        ...coursesFilter,
        ...object,
      })
    );
  };

  const handleEdit = (id: number) => {
    dispatch(setCourseToEdit(id));
    dispatch(setCourseSideModalOpen(true));
  };

  const handleDelete = (id: number) => {
    dispatch(openDeleteModalWith(id));
  };

  const handleMoodleMigration = async () => {
    try {
      const courses = await getAllCourses({
        campusId,
        educationLevelId,
        hourhandId,
        modalityId,
        offerDescription,
        parallelId,
        periodId,
        search,
      }).unwrap();

      await assignCoursesMoodle({
        coursesMoodlePayload: {
          courses: courses.map((dt) => dt.id),
        },
        periodId,
      }).unwrap();
    } catch (error) {
      // TODO: accion de error
      console.log('Ha ocurrido un error');
      console.log(error);
    }
  };

  const CollapsibleContentRow = (id: number) => (
    <Box margin={1} key="id">
      <TableContainer>
        <SubCard content={false} sx={{ mb: 2 }}>
          <Table size="small" aria-label="purchases">
            <TableHead>
              <TableRow hover sx={{ backgroundColor: theme.palette.grey[200] }}>
                <TableCell>{t('academic.courses.table.collapse.schedule')}</TableCell>
                <TableCell>{t('academic.courses.table.modality')}</TableCell>
                <TableCell>{t('academic.courses.table.teacher')}</TableCell>
                <TableCell>{t('academic.courses.side.virtual_classroom_type')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{coursesByFilterResponse?.data[id]?.hour_description}</TableCell>
                <TableCell>{coursesByFilterResponse?.data[id]?.modality}</TableCell>
                <TableCell>{coursesByFilterResponse?.data[id]?.pers_full_name}</TableCell>
                <TableCell>
                  {
                    virtualClassrooms?.find(
                      (item) =>
                        item.keyword === coursesByFilterResponse?.data[id]?.type_classroom_virtual
                    )?.label
                  }
                </TableCell>
              </TableRow>
              {/* {dataFetch && id !== -1 && dataFetch[id] === undefined && renderErrorO
                rEmptyRow(2)} */}
            </TableBody>
          </Table>
        </SubCard>
      </TableContainer>
    </Box>
  );

  return (
    <>
      <Grid container>
        <Grid item xs={12} my={2}>
          <Typography variant="h4">
            {t('academic.courses.table.title')}:{' '}
            <span>
              {coursesByFilterResponse?.total} {t('academic.courses.table.courses')}
            </span>
          </Typography>
        </Grid>
        <Grid item container xs={12} alignItems="center">
          <Grid item container spacing={2} xs={9}>
            <Grid item xs={12} md={3}>
              <Grid container flexDirection="row">
                <Typography variant="body2" color="textSecondary">
                  {t('academic.courses.table.parallel')}
                </Typography>
                <Autocomplete
                  options={parallels}
                  noOptionsText={t('academic.courses.table.parallel.no_data')}
                  loading={isLoadingP || isFetchingP}
                  loadingText={t('academic.courses.table.parallel.loading')}
                  getOptionLabel={(option) => option.par_name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={t('academic.courses.table.parallel.placeholder')}
                    />
                  )}
                  value={parallels.find((parallel) => parallel.id === parallelId) || null}
                  onChange={(_, value) => {
                    // setFiltersSearch(false);
                    handleFilterChange({ parallelId: value?.id || 0 });
                    resetTable();
                  }}
                  fullWidth
                  // clearIcon={null}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={3}>
              <Grid container flexDirection="row">
                <Typography variant="body2" color="textSecondary">
                  {t('academic.courses.table.modality')}
                </Typography>
                <Autocomplete
                  options={studyModalities}
                  noOptionsText={t('academic.courses.table.modality.no_data')}
                  loading={isFetchingM || isLoadingM}
                  loadingText={t('academic.courses.table.modality.loading')}
                  getOptionLabel={(option) => option.cat_name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={t('academic.courses.table.modality.placeholder')}
                    />
                  )}
                  value={studyModalities.find((modality) => modality.id === modalityId) || null}
                  onChange={(_, value) => {
                    // setFiltersSearch(false);
                    handleFilterChange({ modalityId: value?.id || 0 });
                    resetTable();
                  }}
                  fullWidth
                  // clearIcon={null}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} md={3}>
              <Grid container flexDirection="row">
                <Typography variant="body2" color="textSecondary">
                  {t('academic.courses.table.schedule')}
                </Typography>
                <Autocomplete
                  options={hourhands}
                  noOptionsText={t('academic.courses.table.schedule.no_data')}
                  loading={isFetchingH || isLoadingH}
                  loadingText={t('academic.courses.table.schedule.loading')}
                  getOptionLabel={(option) => option.hour_description}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={t('academic.courses.table.schedule.placeholder')}
                    />
                  )}
                  value={hourhands.find((hourhand) => hourhand.id === hourhandId) || null}
                  onChange={(_, value) => {
                    // setFiltersSearch(false);
                    handleFilterChange({ hourhandId: value?.id || 0 });
                    resetTable();
                  }}
                  fullWidth
                  // clearIcon={null}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={3}>
              <Grid container flexDirection="row">
                <Typography variant="body2" color="textSecondary" sx={{ color: 'transparent' }}>
                  1
                </Typography>
                <DebounceInput
                  autoFocus={Boolean(search)}
                  minLength={3}
                  debounceTimeout={300}
                  onChange={({ target }) => {
                    setPage(1);
                    handleFilterChange({ search: target.value });
                  }}
                  value={search}
                  element={(props: any) => (
                    <TextField
                      style={{
                        width: '100%',
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon fontSize="small" />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              size="small"
                              onClick={() => handleFilterChange({ search: '' })}
                              {...(!search && { sx: { cursor: 'initial', opacity: 0 } })}
                            >
                              <IconX size={20} />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      placeholder={t('academic.courses.table.search.placeholder')}
                      variant="outlined"
                      size="medium"
                      {...props}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid item xs={12} md={3} sx={{ justifySelf: 'flex-end' }}>
            <Stack alignItems="center" justifyContent="flex-end">
              <Grid item>
                {availableMoodle && (
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={isLoadingMoodle}
                    endIcon={
                      (isLoadingMoodle || isLoadingAllCourses) && <CircularProgress size={16} />
                    }
                    onClick={() =>
                      !!checked.length
                        ? assignCoursesMoodle({
                            periodId,
                            coursesMoodlePayload: {
                              courses: checked,
                            },
                          })
                        : hamdleMoodleMigration()
                    }
                  >
                    {t('academic.courses.table.migrate.moodle')}
                  </Button>

                  // <Button variant="contained" onClick={hamdleMoodleMigration}>
                  //   {t('academic.courses.table.migrate.moodle')}
                  // </Button>
                )}
              </Grid>
            </Stack>
          </Grid> */}
        </Grid>
        <Grid item xs={12}>
          <CustomCollapsibleTable
            data={dataFetch!}
            headers={{
              check: (
                <>
                  <Checkbox
                    disabled={!availableMoodle}
                    checked={checkAll}
                    onChange={handleAllCheck}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                </>
              ) as any,
              detail: t('academic.courses.table.collapse.detail'),
              id: t('N°'),
              mat_name: t('academic.courses.table.collapse.subject'),
              course_info: t('academic.courses.table.collapse.classroom'),
              availability: t('academic.courses.table.collapse.availability'),
              teacher: t('academic.courses.table.collapse.teacher'),
              start_date: t('academic.courses.table.collapse.start_date'),
              end_date: t('academic.courses.table.collapse.end_date'),
              actions: (
                <>
                  {t('academic.courses.table.collapse.options')}
                  {availableMoodle && (
                    <Tooltip title={t('academic.courses.table.migrate.moodle')}>
                      <IconButton
                        color="primary"
                        disabled={isLoadingMoodle || isLoadingAllCourses}
                        // onClick={() =>
                        //   !!checked.length
                        //     ? assignCoursesMoodle({
                        //         periodId,
                        //         coursesMoodlePayload: {
                        //           courses: checked,
                        //         },
                        //       })
                        //     : handleMoodleMigration()
                        // }
                        onClick={() => setOpenDialogConfirm(true)}
                      >
                        <IconSettingsAutomation />
                      </IconButton>
                    </Tooltip>
                  )}
                </>
              ) as any,
            }}
            keyExtractor={(item) => `${item.id}-${item.mat_name}-${item.course_info}`}
            listFilterArrows={{
              code: 's.cod_matter_migration',
              mat_name: 's.mat_name',
              hourhand: 'h.hour_description',
            }}
            filterArrow={filterArrow}
            setFilterArrow={setFilterArrow}
            searchPlacehoder={t('academic.courses.table.collapse.search')}
            isLoading={isFetching || isLoading}
            isFetching={isFetching || isLoading}
            error={error}
            perPage={perPage}
            setPerPage={setPerPage}
            total={coursesByFilterResponse?.total || 0}
            page={page}
            setPage={setPage}
            numHeader={10}
            search={search || ''}
            setSearch={() => {}}
            customRenderers={{
              id: (item) => <> {item.id.index}</>,
              check: (item) => (
                <Checkbox
                  disabled={!availableMoodle}
                  checked={checked.some((e) => e === item.id.id)}
                  onChange={() => handleCheck(item.id.id)}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              ),
              actions: (item) => (
                <>
                  <EditButton
                    disabled={isOutRangeDate(periodEndProcess)}
                    handleEdit={() => handleEdit(item.actions)}
                  />
                  <DeleteButton
                    disabled={isOutRangeDate(periodEndProcess)}
                    handleDelete={() => handleDelete(item.actions)}
                  />
                </>
              ),
            }}
            customDataCellsProperties={{
              teacher: { align: 'center' },
            }}
            // id: (row) => row.id.id,
            showFilter={false}
            isCollapsible
            overwriteRowC={1}
            CollapsibleItems={CollapsibleContentRow}
          />
        </Grid>
      </Grid>

      <ConfirmAlertDialog
        error={errorMoodle}
        isError={isErrorMoodle}
        isLoading={isLoadingMoodle || isLoadingAllCourses}
        open={openDialogConfirm}
        setOpen={setOpenDialogConfirm}
        isSuccess={isSuccessMoodle}
        event={() =>
          !!checked.length
            ? assignCoursesMoodle({
                periodId,
                coursesMoodlePayload: {
                  courses: checked,
                },
              })
            : handleMoodleMigration()
        }
        subjectsLength={checked.length || allCoursesAvailables?.length || 0}
      />
    </>
  );
};

export default CoursesTable;
