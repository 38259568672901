export const permissionKey = 'borrows';

export interface SubPermissionKeys {
  read: string;
  list: string;
  create: string;
  edit: string;
  delete: string;
}

export const subPermissionKeys: SubPermissionKeys = {
  read: 'prestamos-listar-prestamos-libros',
  list: 'prestamos-listar-prestamos-libros',
  create: 'prestamos-listar-prestamos-libros',
  edit: 'prestamos-listar-prestamos-libros',
  delete: 'prestamos-listar-prestamos-libros',
};
