import { Autocomplete, Grid, TextField } from '@material-ui/core';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/useRedux';
import { useTableSearch } from '../../../../../../hooks/useTableSearch';
import StatusChip from '../../../../../shared/components/badges/StatusChip';
import AddButton from '../../../../../shared/components/tables/buttons/AddButton';
import DeleteButton from '../../../../../shared/components/tables/buttons/DeleteButton';
import EditButton from '../../../../../shared/components/tables/buttons/EditButton';
import SearchPaginatedTable from '../../../../../shared/components/tables/SearchPaginatedTable';
import { useGetScopesQuery } from '../slices/scopeApiSlice';
import {
  setScopesDeleteModalOpen,
  setScopesIdSelected,
  setScopesIdToDelete,
  setScopesList,
  setScopesSideDialogOpen,
  setScopesTable,
  setStatusScopes,
} from '../slices/scopeSlice';

const ScopesTable = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const {
    scopesList,
    scopesTable: { page, perPage, search, filterArrow },
    status,
  } = useAppSelector((state) => state.scopes);

  const { setPage, setPerPage, setSearch, setFilterArrow } = useTableSearch({
    page,
    perPage,
    search,
    filterArrow,
    updateFunction: setScopesTable,
  });

  const { data, isLoading, isFetching, error } = useGetScopesQuery({
    page,
    perPage,
    search,
    ...(filterArrow && { sort: filterArrow.name, typeSort: filterArrow.direction }),
    ...(status && { status }),
  });

  useEffect(() => {
    if (data?.data) {
      dispatch(setScopesList(data?.data));
    }
  }, [data, dispatch]);

  const datafetch = scopesList.map((item, idx) => ({
    id: (page - 1) * perPage + (idx + 1),
    name: item.name,
    description: item.description,
    status: item.status,
    options: { id: item.uuid },
  }));

  const goToAdd = () => {
    dispatch(setScopesSideDialogOpen(true));
  };

  const handleEdit = (id: string) => {
    dispatch(setScopesIdSelected(id));
    dispatch(setScopesSideDialogOpen(true));
  };

  const handleDelete = (id: string) => {
    dispatch(setScopesIdToDelete(id));
    dispatch(setScopesDeleteModalOpen(true));
  };

  const statusOptions = [
    { value: 'A', label: t('maintenance.scopes.status.active') },
    { value: 'I', label: t('maintenance.scopes.status.inactive') },
  ];

  return (
    <SearchPaginatedTable
      data={datafetch!}
      headers={{
        id: 'N°',
        name: t('maintenance.scopes.name'),
        description: t('maintenance.scopes.description'),
        status: t('maintenance.scopes.status'),
        options: t('maintenance.scopes.options'),
      }}
      listFilterArrows={{
        description: 'description',
      }}
      filterArrow={filterArrow}
      setFilterArrow={setFilterArrow}
      keyExtractor={(item) => item.id!.toString()}
      searchPlacehoder="Buscar sección"
      isLoading={isLoading}
      isFetching={isFetching}
      error={error as any}
      perPage={perPage}
      setPerPage={setPerPage}
      total={data?.total || 0}
      page={page}
      setPage={setPage}
      numHeader={5}
      search={search}
      setSearch={setSearch}
      customDataCellsProperties={{
        id: {
          align: 'center',
        },
        name: {
          align: 'center',
        },
        description: {
          align: 'center',
        },
        status: {
          align: 'center',
        },
        options: {
          align: 'center',
        },
      }}
      customRenderers={{
        // eslint-disable-next-line react/no-unstable-nested-components
        options: (row) => (
          <>
            <EditButton handleEdit={() => handleEdit(row.options.id!)} />
            <DeleteButton handleDelete={() => handleDelete(row.options.id!)} />
          </>
        ),
        status: (row) => (
          <>
            <StatusChip statusId={row.status === 'A' ? 1 : 2} />
          </>
        ),
      }}
      ActionButtons={<AddButton size="large" onClick={goToAdd} />}
      additionalFilters={
        <>
          <Grid container spacing={1}>
            <Grid item xs={12} md={4}>
              <Autocomplete
                options={statusOptions}
                value={statusOptions.find((item) => item.value === status)}
                onChange={(_, value) => {
                  dispatch(setStatusScopes(value?.value!));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('maintenance.scopes.form.status')}
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </Grid>
          </Grid>
        </>
      }
    />
  );
};

export default ScopesTable;
