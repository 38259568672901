import { Grid, Typography } from '@material-ui/core';
import MainCard from '../../../shared/components/cards/MainCard';
import { useTranslation } from 'react-i18next';
import AssignQualiCoursesForm from '../components/AssignQualiCoursesForm';

const PermissionsGradesScreen = () => {
  const { t } = useTranslation();

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid item sx={{ mb: 3 }}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item xs={6}>
            <Typography variant="h2">{t('Ingreso de calificaciones')}</Typography>
            <Typography variant="h5">
              {t('Apertura de etapa para registro de los docentes')}
            </Typography>
          </Grid>

          <Grid item>
            <div id="add-stage-portal" />
          </Grid>
        </Grid>
      </Grid>

      <AssignQualiCoursesForm />
    </Grid>
  );
};

export default PermissionsGradesScreen;
