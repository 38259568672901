import { IconButton, Tooltip, useTheme } from '@material-ui/core';
import { IconUsers } from '@tabler/icons';

import { useTranslation } from 'react-i18next';

const StudentsButton = ({ handleClick }: { handleClick?: () => void }) => {
  const theme = useTheme();

  const { t } = useTranslation();

  return (
    <Tooltip title={t('Ver estudiantes')}>
      <IconButton aria-label="Ver-Estudiantes" onClick={handleClick}>
        <IconUsers size={20} color={theme.palette.primary.main} />
      </IconButton>
    </Tooltip>
  );
};

export default StudentsButton;
