import { useCallback, useEffect, useState } from 'react';

import { ActionCreatorWithPayload } from '@reduxjs/toolkit';

import { ISearchTable } from '../modules/shared/interfaces/paginatedTable.interface';
import { useAppDispatch } from './useRedux';
import { FilterArrow } from '../modules/shared/components/tables/SearchPaginatedTable';

interface TableSearchProps {
  page: number;
  perPage: number;
  search: string;
  filterArrow?: FilterArrow;
  updateFunction: ActionCreatorWithPayload<ISearchTable, string>;
  otherFilters?: {
    [key: string]: number | string | boolean | undefined | null;
  };
}

export const defaultPerPage = 5;

export const useTableSearch = ({
  page,
  perPage,
  search,
  filterArrow,
  updateFunction,
  otherFilters,
}: TableSearchProps) => {
  const [customFilters, setCustomFilters] = useState(otherFilters || {});

  const dispatch = useAppDispatch();

  const setPage = useCallback(
    (page: number) => {
      dispatch(updateFunction({ page, perPage, search, filterArrow }));
    },
    [dispatch, perPage, search, filterArrow, updateFunction]
  );
  const setPerPage = useCallback(
    (perPage: number) => {
      dispatch(updateFunction({ page: 1, perPage, search, filterArrow }));
    },
    [dispatch, search, filterArrow, updateFunction]
  );
  const setSearch = useCallback(
    (search: string) => {
      dispatch(updateFunction({ page: 1, perPage, search, filterArrow }));
    },
    [dispatch, perPage, filterArrow, updateFunction]
  );

  const setFilterArrow = useCallback(
    (filterArrow: FilterArrow) => {
      dispatch(updateFunction({ page, perPage, search, filterArrow }));
    },
    [dispatch, updateFunction, page, perPage, search]
  );

  const resetTable = useCallback(
    (all = false) => {
      const newPerPage = all ? 5 : perPage;
      const newSearch = all ? '' : search;
      const newFilterArr = all ? undefined : filterArrow;

      if (page > 1)
        dispatch(
          updateFunction({
            page: 1,
            perPage: newPerPage,
            search: newSearch,
            filterArrow: newFilterArr,
          })
        );
    },
    [dispatch, page, perPage, search, filterArrow, updateFunction]
  );

  useEffect(() => {
    if (otherFilters) {
      const keys = Object.keys(otherFilters);

      const hasChanges = keys.some((key) => {
        if (otherFilters[key] !== customFilters[key]) {
          return true;
        }
        return false;
      });

      if (!hasChanges) return;

      resetTable();
      setCustomFilters(otherFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otherFilters, resetTable]);

  return {
    setPage,
    setPerPage,
    setSearch,
    setFilterArrow,
    resetTable,
  };
};
