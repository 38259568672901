import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import MainCard from '../../../shared/components/cards/MainCard';
import AccountStatusDialog from '../components/AccountStatusDialog';
import ClientsList from '../components/ClientsList';
import { fireDialogAccountStatus } from '../slices/clientSlice';
const ClientScreen = () => {
  const { dialogAccountStatus } = useAppSelector((state) => state.client);
  const dispatch = useAppDispatch();

  const {t} = useTranslation();

  return (
    <>
      <Grid component={MainCard} sx={{ padding: 2 }}>
        <Grid sx={{ mb: 3 }}>
          <Typography variant="h2">{t('financial.client_list.title')}</Typography>
        </Grid>
        <ClientsList />
      </Grid>

      <AccountStatusDialog
        open={dialogAccountStatus}
        handleCloseDialog={() => dispatch(fireDialogAccountStatus(false))}
      />
    </>
  );
};

export default ClientScreen;
