import { useEffect } from 'react';
import { IconButton, Grid, useTheme, Typography } from '@material-ui/core';
import { IconArrowNarrowLeft } from '@tabler/icons';

import EditELSForm from '../components/EditELSForm';

import { useHistory, useLocation, useParams } from 'react-router-dom';
import MainCardForm from '../../../shared/components/cards/MainCardForm';
import { useLazyGetSubjectGroupAreaQuery } from '../slices/ELSApiSlice';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { setEducationLevelSubjectList } from '../slices/ELSSlice';
import SimpleFormOneColumn from '../../../shared/components/forms/skeletons/SimpleFormOneColumn';

const EditELSScreen = () => {
  const theme = useTheme();
  const history = useHistory();

  const dispatch = useAppDispatch();

  const location = useLocation();

  const { ELSId } = useParams<{ ELSId: string }>();

  const { educationLevelSubjectList } = useAppSelector((state) => state.educationLevelSubject);

  const [getSubjectGroupArea, { isFetching }] = useLazyGetSubjectGroupAreaQuery();

  useEffect(() => {
    if (!educationLevelSubjectList.length && !isFetching) {
      getSubjectGroupArea({
        nivelationSubjectId: +ELSId,
      })
        .unwrap()
        .then((response) => {
          if (response) dispatch(setEducationLevelSubjectList([response!]));
        })
        .catch((error) => console.log(error));
    }
  }, [getSubjectGroupArea, isFetching, ELSId, educationLevelSubjectList.length, dispatch]);

  return (
    <MainCardForm>
      <Grid item xs={12}>
        <Grid item container alignItems="center" gap={1} sx={{ cursor: 'pointer' }}>
          <IconButton onClick={() => history.push(`/${location.pathname.split('/')[1]}`)}>
            <IconArrowNarrowLeft size={30} color={theme.palette.text.dark} />
          </IconButton>
          <Typography variant="h2">Editar</Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} md={8} lg={6} sx={{ mt: 2 }}>
        {!!educationLevelSubjectList.length ? <EditELSForm /> : <SimpleFormOneColumn />}
      </Grid>
    </MainCardForm>
  );
};

export default EditELSScreen;
