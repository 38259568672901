import * as Yup from 'yup';

export const GroupAreaSchema = Yup.object().shape({
  argName: Yup.string()
    .required('El nombre del grupo es requerido.')
    .min(5, 'El nombre debe tener minimo 5 caracteres'),
  argDescription: Yup.string(),
  subjects: Yup.array().of(
    Yup.object().shape({ subject_id: Yup.number(), status_id: Yup.number() }).required()
  ),
  statusId: Yup.number().required('El estado del grupo es requerido.'),
});
