import { Box, Tab } from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { useState } from 'react';
import { escalasOptions } from '../constants/escalas.contants';

const OptionsTabScale = () => {
  const [value, setValue] = useState<string>('1');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <TabContext value={value}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <TabList
          variant="scrollable"
          scrollButtons="auto"
          onChange={handleChange}
          aria-label="configurations in contact center"
        >
          {escalasOptions.map((option) => (
            <Tab
              key={`${option.value}-${option.label}`}
              label={option.label}
              value={option.value}
            />
          ))}
        </TabList>
      </Box>
      {escalasOptions.map(({ value: valueOption, label, Component }) => (
        <TabPanel key={`${valueOption}-${label}`} value={valueOption}>
          <Component />
        </TabPanel>
      ))}
    </TabContext>
  );
};

export default OptionsTabScale;
