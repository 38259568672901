import { Redirect, Switch, useRouteMatch } from 'react-router-dom';

import CurriculumsScreen from '../screens/CurriculumsScreen';
import AddCurriculumScreen from '../screens/AddCurriculumScreen';
import EditCurriculumScreen from '../screens/EditCurriculumScreen';
import CurriculumBuilderScreen from '../screens/CurriculumBuilderScreen';
import HomolagateScreen from '../screens/HomologateScreen';

import GuardRoute from '../../../../router/guards/GuardRoute';

import { useAppSelector } from '../../../../hooks/useRedux';

import { subPermissionKeys } from '../constants/permissions';

const { groupKey: permissionKey } = subPermissionKeys;

const CurriculumsRouter = () => {
  const { path } = useRouteMatch();

  const { currentModule } = useAppSelector((state) => state.access);

  const hasPermission = permissionKey in currentModule?.permissions!;

  if (!hasPermission) return <Redirect to="/dashboard" />;

  return (
    <Switch>
      <GuardRoute
        exact
        path={path}
        component={CurriculumsScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.read}
      />

      <GuardRoute
        exact
        path={`${path}/agregar`}
        component={AddCurriculumScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.create}
      />

      <GuardRoute
        exact
        path={`${path}/:meshId/editar`}
        component={EditCurriculumScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.edit}
      />

      <GuardRoute
        exact
        path={`${path}/:meshId/asignar-materias`}
        component={CurriculumBuilderScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.getOne}
      />
      <GuardRoute
        exact
        path={`${path}/:meshId/materias-homologadas`}
        component={HomolagateScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.getOne}
      />
    </Switch>
  );
};

export default CurriculumsRouter;
