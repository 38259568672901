import { JSXElementConstructor, memo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Redirect, Route, RouteProps, useRouteMatch } from 'react-router-dom';
import { useAppSelector } from '../../hooks/useRedux';
import ErrorFallbackComponentScreen from '../../modules/shared/components/errors-boundary/ErrorFallbackComponentScreen';

interface Props extends RouteProps {
  component: JSXElementConstructor<RouteProps>;
  permissionKey?: string;
  subPermissionKey?: string | string[];
  /** ```Default: true```  false if no need of error component fallback */
  needErrorComponent?: boolean;
  isView?: boolean;
}

const ValidateGuardRoute = ({
  component: Component,
  subPermissionKey,
  permissionKey,
  needErrorComponent = true,
  isView = false,
  ...rest
}: Props) => {
  const { path } = useRouteMatch();

  const { currentModule } = useAppSelector((state) => state.access);

  const hasPermission = () => {
    try {
      if (permissionKey && subPermissionKey) {
        const subPermissionKeys = currentModule?.permissions![permissionKey].map((p) => p.key);

        if (Array.isArray(subPermissionKey)) {
          return subPermissionKey.every((item) => subPermissionKeys?.includes(item));
        }

        return Boolean(subPermissionKeys?.includes(subPermissionKey));
      } else {
        return true;
      }
    } catch (error) {
      console.log('===========Sin permisos===========');
      console.log(error);
      return false;
    }
  };

  return (
    <Route
      {...rest}
      render={(props) =>
        hasPermission() && isView ? (
          needErrorComponent ? (
            <ErrorBoundary FallbackComponent={ErrorFallbackComponentScreen}>
              <Component {...props} />
            </ErrorBoundary>
          ) : (
            <Component {...props} />
          )
        ) : (
          <Redirect to={`${path}`} />
        )
      }
    />
  );
};

export default memo(ValidateGuardRoute);
