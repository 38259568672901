import {
  Table,
  Grid,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import { renderErrorOrEmptyRow } from '../../../../shared/helpers/render';
import { LaboralExperience } from '../../interfaces/laboralExperience.interface';
import { IconX } from '@tabler/icons';
import { deleteListLaboralExperience } from '../../slices/requisitionsSlice';
import { useAppDispatch } from '../../../../../hooks/useRedux';
import { IconFile } from '@tabler/icons';
// import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import EditButton from '../../../../shared/components/tables/buttons/EditButton';

interface props {
  data: LaboralExperience[];
  readOnly?: boolean;
  selectLaboralExperience?: (id: number) => void;
}

const LaboralExperienceTable = (props: props) => {
  const { t } = useTranslation();

  const { data, readOnly, selectLaboralExperience } = props;
  const dispatch = useAppDispatch();

  const renderRow = (p: LaboralExperience) => (
    <TableRow className="table-row" key={data.indexOf(p)}>
      <TableCell align="center" width="5%">
        {data.indexOf(p) + 1}
      </TableCell>
      <TableCell width="10%">{p.per_job_organization}</TableCell>
      <TableCell width="10%">{p.per_job_position}</TableCell>
      <TableCell width="5%">{p.city_id.name}</TableCell>
      <TableCell align="center" width="5%">
        {p.per_job_iess_affiliated === 0 ? 'No' : 'Si'}
      </TableCell>
      <TableCell align="center" width="5%">
        {p.per_job_start_date}
      </TableCell>
      <TableCell align="center" width="5%">
        {p.per_job_phone}
      </TableCell>
      <TableCell align="center" width="5%">
        {p.document_url && (
          <a href={p.document_url} target="_blank" rel="noreferrer">
            <Tooltip
              title={t('academic.requisition.edit.wizard.step2.add_laboral_experience.see_doc')}
            >
              <IconButton>
                <IconFile />
              </IconButton>
            </Tooltip>
          </a>
        )}
        {/* {p.document_url} */}
      </TableCell>
      {!readOnly && (
        <TableCell align="center" width="5%">
          <Tooltip title={t('academic.requisition.edit.wizard.step2.add_laboral_experience.exit')}>
            <IconButton>
              <IconX
                onClick={() => {
                  dispatch(deleteListLaboralExperience(p));
                }}
                color="red"
              />
            </IconButton>
          </Tooltip>
          {selectLaboralExperience && (
            <EditButton handleEdit={() => selectLaboralExperience(p.id!)} />
          )}
        </TableCell>
      )}
    </TableRow>
  );

  const renderRows = () => {
    return <>{data.map(renderRow)}</>;
  };

  return (
    <>
      <Grid container justifyContent="center">
        <Grid item sm={12} overflow={'hidden'}>
          <>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">#</TableCell>
                    <TableCell align="center">
                      {t('academic.requisition.edit.wizard.step2.add_laboral_experience.org')}
                    </TableCell>
                    <TableCell align="center">
                      {t('academic.requisition.edit.wizard.step2.add_laboral_experience.charge')}
                    </TableCell>
                    <TableCell align="center">
                      {t('academic.requisition.edit.wizard.step2.add_laboral_experience.city')}
                    </TableCell>
                    <TableCell align="center">
                      {t(
                        'academic.requisition.edit.wizard.step2.add_laboral_experience.current_work'
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {t(
                        'academic.requisition.edit.wizard.step2.add_laboral_experience.start_date'
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {t('academic.requisition.edit.wizard.step2.add_laboral_experience.phone')}
                    </TableCell>
                    <TableCell align="center">
                      {t('academic.requisition.edit.wizard.step2.add_laboral_experience.doc')}
                    </TableCell>

                    {selectLaboralExperience && (
                      <TableCell align="center">
                        {t('shared.search_paginated_table.table.options')}
                      </TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {renderRows()}
                  {data.length === 0 && renderErrorOrEmptyRow(11)}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        </Grid>
      </Grid>
    </>
  );
};

export default LaboralExperienceTable;
