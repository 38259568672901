import { number, object, string } from 'yup';

export const EmissionPointSchema = object().shape({
  emission_point: string()
    .required('financial.emission_point.add.emission_point_code_required')
    .matches(/[0-9]{3}$/, 'financial.emission_point.add.code_inrange_validation')
    .max(3, 'financial.emission_point.add.code_max_validation'),
  stablishment_point: string()
    .required('financial.emission_point.add.establishment_code_required')
    .matches(/[0-9]{3}$/, 'financial.emission_point.add.code_inrange_validation')
    .max(3, 'financial.emission_point.add.code_max_validation'),
  secuencial: string()
    .required('financial.emission_point.add.code_required_validation')
    .matches(/^[0-9]+$/, 'shared.validation.only_numbers')
    .max(9, 'financial.emission_point.add.last_sequential_max'),
  /*start_num: number()
    .required('El valor inicial del secuencial es requerido')
    .min(1, 'El valor inicial del secuencial debe ser mayor a 0'),
  end_num: number()
    .required('El valor máximo del secuencial es requerido')
    .min(1, 'El valor máximo del secuencial debe ser mayor a 0'),*/
  authorization_num: string()
    .required('financial.emission_point.add.code_required_validation')
    .matches(/^[0-9]+$/, 'shared.validation.only_numbers'),
  type_document_keyword: string().required('financial.emission_point.add.doc_type_required'),
  campus_id: number()
    .required('financial.emission_point.add.branch_office_required')
    .min(1, 'financial.emission_point.add.branch_office_required'),
});
