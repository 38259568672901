import { useState } from 'react';

import {
  Avatar,
  Badge,
  Button,
  CardActions,
  CardContent,
  CircularProgress,
  Divider,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core';
import { IconArrowNarrowLeft } from '@tabler/icons';
import LayersTwoToneIcon from '@material-ui/icons/LayersTwoTone';
import { useFormik } from 'formik';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import moment from 'moment';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from '../../../../../hooks/useRedux';
import { stylesStudentAsignedSendMessage } from '../constants/styles';
import { resetStudentSelected, setOpenModalStudentAds } from '../slices/studentsAsignedSlice';
import { useAppSelector } from '../../../../../hooks/useRedux';
import { obtainDomainStorageImage } from '../../../../shared/helpers';
import MainCard from '../../../../shared/components/cards/MainCard';
import { useAddStudentAnnouncementMutation } from '../slices/studentsAsignedApiSlice';
import { StudentAdsInfoSchema } from '../constants/validation-schema';
import SuccessAlert from '../../../../shared/components/alerts/SuccessAlert';
import ErrorAlert from '../../../../shared/components/alerts/ErrorAlert';

import ExtendedSideDialog from '../../../../shared/components/dialogs/ExtendedSideDialog';
const useStyles = makeStyles((theme) => ({
  projectTableMain: {
    position: 'relative',
    '&>*': {
      position: 'relative',
      zIndex: '5',
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      top: '0',
      left: '35%',
      width: '2px',
      height: '100%',
      background: '#ebebeb',
      zIndex: '1',
    },
  },
}));

const StudentAsignedSendAnnouncement = function () {
  const { t } = useTranslation();

  const { collaboratorId } = useParams<{ collaboratorId: string }>();

  const history = useHistory();

  const theme = useTheme();

  const classes = useStyles();

  const styles = stylesStudentAsignedSendMessage();

  const dispatch = useAppDispatch();

  const [showAlerts, setShowAlerts] = useState<boolean>(false);

  const { currentProfile } = useAppSelector((state) => state.access);

  const { studentSelected, studentsList } = useAppSelector((state) => state.studentsAsigned);

  const { userInfo } = useAppSelector((state) => state.auth);

  const student = studentsList.find(
    (value) => value?.user?.user_id === studentSelected?.toString()
  );

  const handleClose = () => {
    dispatch(resetStudentSelected());

    dispatch(setOpenModalStudentAds(false));
  };

  const handleHistoryAds = () => {
    history.push(
      `/administrar-estudiantes-asignados/${collaboratorId}/historial-anuncios/${studentSelected}`
    );
  };

  const [addStudentAds, { error, isError, isSuccess, isLoading }] =
    useAddStudentAnnouncementMutation();

  const { getFieldProps, errors, touched, handleSubmit, resetForm } = useFormik({
    initialValues: {
      ans_message: '',
    },
    validationSchema: StudentAdsInfoSchema,
    onSubmit: async (values) => {
      await addStudentAds({
        profileId: currentProfile?.profileId!,
        studentAdsPayload: {
          acronym: 'CONS',
          ans_message: values.ans_message,
          receiver: [Number(student?.user.user_id!)],
          user_profile_id: currentProfile?.profileId!,
          user_id: Number(userInfo?.id),
        },
      });
      setShowAlerts(true);
    },
  });

  return (
    <ExtendedSideDialog
      open={true}
      keepMounted
      onClose={() => handleClose()}
      className={styles.container}
      classes={{
        paper: styles.paper,
      }}
    >
      <form onSubmit={handleSubmit}>
        <Grid item xs={12} style={{ marginLeft: 25 }}>
          <Grid item container alignItems="center" gap={1} sx={{ cursor: 'pointer' }}>
            <IconButton onClick={() => handleClose()}>
              <IconArrowNarrowLeft size={30} color={theme.palette.text.dark} />
            </IconButton>
            <Typography variant="h3">
              {t('teachers.academic_counseling.students_assigned.send_announce.back')}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ marginLeft: 20, marginRight: 20 }}>
          <Grid item xs={12}>
            {isError && showAlerts && (
              <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
            )}

            {isSuccess && showAlerts && (
              <SuccessAlert
                message={t('teachers.academic_counseling.students_assigned.send_announce.success')}
                handleDismiss={() => {
                  setShowAlerts(false);
                  resetForm();
                }}
              />
            )}
          </Grid>
        </Grid>

        <MainCard
          title={t('teachers.academic_counseling.students_assigned.send_announce.send')}
          content={false}
          sx={{ mt: 2 }}
        >
          <CardContent>
            <Grid container spacing={3} alignItems="center" className={classes.projectTableMain}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs zeroMinWidth>
                        <Typography align="left" variant="caption">
                          {student?.lastActivity === null
                            ? t(
                                'teachers.academic_counseling.students_assigned.send_announce.no_contact'
                              )
                            : `${t(
                                'teachers.academic_counseling.students_assigned.send_announce.contacted'
                              )} ${moment(student?.lastActivity).fromNow()}`}
                        </Typography>
                      </Grid>
                      <Grid item sx={{ ml: 7 }}>
                        <Badge
                          overlap="circular"
                          badgeContent={
                            <FiberManualRecordIcon
                              sx={{
                                cursor: 'pointer',
                                color: theme.palette.success.dark,
                                verticalAlign: 'middle',
                                fontSize: '14px',
                              }}
                            />
                          }
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                          }}
                        >
                          <Avatar
                            alt="coverimage"
                            src={obtainDomainStorageImage(student?.stud_photo_path!)}
                          />
                        </Badge>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs zeroMinWidth>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Typography component="div" align="left" variant="subtitle1">
                          {student?.user?.person?.pers_first_name}{' '}
                          {student?.user?.person?.pers_first_lastname}
                        </Typography>
                        <Typography component="div" align="left" variant="subtitle2">
                          {t(
                            'teachers.academic_counseling.students_assigned.send_announce.destinate'
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions sx={{ justifyContent: 'flex-end' }}>
            <Button onClick={handleHistoryAds} variant="text" size="small" color="primary">
              {t('teachers.academic_counseling.students_assigned.send_announce.history')}
            </Button>
          </CardActions>
        </MainCard>

        <Grid item xs={12}>
          <MainCard>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <InputLabel>
                  {t('teachers.academic_counseling.students_assigned.send_announce.message')}
                </InputLabel>
                <TextField
                  id="outlined-textarea"
                  placeholder={`${t(
                    'teachers.academic_counseling.students_assigned.send_announce.what_announce'
                  )} ${student?.user?.person?.pers_first_name} ?`}
                  rows={4}
                  fullWidth
                  multiline
                  {...getFieldProps('ans_message')}
                />
                {errors.ans_message && touched.ans_message && (
                  <FormHelperText error>{errors.ans_message}</FormHelperText>
                )}
              </Grid>
              <Grid item xs={12}>
                <Grid container justifyContent="right" spacing={3}>
                  <Grid item>
                    <Button
                      type="submit"
                      size="large"
                      variant="contained"
                      color="primary"
                      startIcon={<LayersTwoToneIcon />}
                      disabled={isLoading}
                      endIcon={isLoading && <CircularProgress size={20} />}
                    >
                      {t('teachers.academic_counseling.students_assigned.send_announce.submit')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </MainCard>
        </Grid>
      </form>
    </ExtendedSideDialog>
  );
};

export default StudentAsignedSendAnnouncement;
