import { Box, Typography, Stack, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useAppSelector } from '../../../../../hooks/useRedux';

const useStyles = makeStyles((theme) => ({
  boxContainer: {
    borderRadius: 5,
    backgroundColor: '#fff',
    paddingInline: 16,
    paddingBlock: 10,
    boxShadow: '0px 0px 20px #EAF0F7',
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    width: '50rem',
    height: '100%',
  },
  number: {
    fontWeight: 800,
    color: theme.palette.primary.main,
    fontSize: 16,
  },
  title: {
    color: theme.palette.grey[800],
    fontSize: 14,
  },
  simbologyText: {
    fontWeight: 600,
  },
  square: {
    width: 20,
    height: 16,
    borderRadius: 4,
  },
}));

interface Props {
  simbologies: {
    color: string;
    label: string;
    id: number;
  }[];
  handleFilter: (id: number | null) => void;
}

const SimbologyBadge = ({ simbologies, handleFilter }: Props) => {
  const classes = useStyles();
  const { simbologyIdSelected } = useAppSelector((state) => state.meshs);

  // useGetSymbologiesQuery
  // useGetSymbologiesQuery()
  // const simbologias  = useGetSymbologiesQuery({profileId: 2})

  // console.log(simbologias)

  //Caso 1 hacer el filtro de forma manual para utilizar la api a menos que exista un metodo para llamar a la api

  // const smb = simbologies;

  //Caso 1 hacer el filtro de forma manual para utilizar la api a menos que exista un metodo para llamar a la api

  // {simbologies.map((s) => (
  //   <Stack key={s.label} direction="row">
  //     <Button>
  //     <Box className={classes.square} sx={{ bgcolor: s.color, mr: '4px' }}></Box>
  //     <Typography className={classes.simbologyText} sx={{ color: s.color }}>
  //       {s.label}
  //     </Typography>
  //     </Button>
  //   </Stack>
  // ))}

  return (
    <Box className={classes.boxContainer}>
      <Typography className={classes.title}>Simbologias</Typography>
      {simbologies.map((d) => {
        return (
          <Stack direction="row">
            <Button
              onClick={() => handleFilter(simbologyIdSelected === d.id ? null : d.id)}
              sx={{
                backgroundColor: simbologyIdSelected === d.id ? 'primary.light' : 'transparent',
                '&:hover': {
                  backgroundColor: simbologyIdSelected === d.id ? 'primary.light' : 'transparent',
                },
              }}
            >
              <Box className={classes.square} sx={{ bgcolor: `${d.color}`, mr: '4px' }}></Box>
              <Typography color="black">{d.label}</Typography>
            </Button>
          </Stack>
        );
      })}
      {/*
       */}
    </Box>
  );
};

export default SimbologyBadge;
