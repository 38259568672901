import { useAppDispatch, useAppSelector } from '../../../../../../hooks/useRedux';
import TaskChooseGroup from './TaskChooseGroup';
import TaskChooseQuestions from './TaskChooseQuestion';
import { useTranslation } from 'react-i18next';

import TaskCompleteInfo from './TaskCompleteInfo';
import { Stepper, StepLabel, Step, Box, Button, Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import { setResetStep } from '../../../slices/virtualClassroomTeacherSlice';
import GoBackBtnTitle from '../../../../../shared/components/buttons/GoBackBtnTitle';

const TaskCreate = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const steps = [
    t('teacherVirtualClassroom.tasks.steps.datos'),
    t('teacherVirtualClassroom.tasks.steps.estudiante'),
    t('teacherVirtualClassroom.tasks.steps.preguntas'),
    // t('teacherVirtualClassroom.tasks.steps.Confirmacion'),
  ];
  const { taskChooseGroup, StepEdit } = useAppSelector((state) => state.virtualClassroomTeacher);
  /*taskCompleteInfo, taskChooseQuestions */

  console.log(taskChooseGroup);
  /*const handleNext = () => {
    if (
      (activeStep === 1 && taskCompleteInfo) ||
      (activeStep === 2 && taskChooseGroup) ||
      (activeStep === 3 && taskChooseQuestions)
    ) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };*/
  /*

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
*/

  useEffect(() => {
    return () => {
      dispatch(setResetStep());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Grid item sx={{ mb: 3 }}>
          <GoBackBtnTitle title={t('teacherVirtualClassroom.tasks.createTask')} />
        </Grid>

        <Stepper activeStep={StepEdit}>
          {steps.map((label) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: {
              optional?: React.ReactNode;
            } = {};

            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {StepEdit === steps.length ? (
          <React.Fragment>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button>si</Button>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {StepEdit === 0 && <TaskCompleteInfo />}
            {StepEdit === 1 && <TaskChooseGroup />}
            {StepEdit === 2 && <TaskChooseQuestions />}

            {/*<Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 1}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />

              <Button onClick={handleNext}>
                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
              </Button>
        </Box>*/}
          </React.Fragment>
        )}
      </Box>
    </>
  );
};

export default TaskCreate;
