import { Avatar, Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { UserWeightingReport } from '../../interfaces/integralsReports.interface';

interface Props {
  user?: UserWeightingReport | null;
  period?: string | null;
}

const WeightingHeader = ({ user, period }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Grid
      container
      sx={{
        backgroundColor: 'primary.light',
        padding: 2,
      }}
      alignItems="center"
    >
      <Grid item container justifyContent={isSmall ? 'center' : 'flex-end'} xs={12} md={3} mr={3}>
        <Avatar>MD</Avatar>
      </Grid>
      <Grid item xs={12} md={3}>
        <Typography variant="h3">{t('reports.integrals.weighting.evaluated')}</Typography>
        <Typography variant="h4">{user?.person.name}</Typography>
      </Grid>
      <Grid item xs={12} md={3}>
        <Typography variant="h3">{t('reports.integrals.weighting.stage')}</Typography>
        <Typography variant="h4">{period}</Typography>
      </Grid>
      {!isSmall && <Grid item xs={12} md={3} />}
      <Grid
        item
        xs={12}
        md={6}
        mt={1}
        p={2}
        sx={{
          backgroundColor: `${theme.palette.secondary.main}50`,
          borderRadius: '12px',
        }}
      >
        <Typography variant="body1" color="CaptionText">
          <strong>Estimado Docente:</strong> A continuación se resumen los resultados de su
          evaluación por actividades e integral del periodo. Agradecemos su compormiso con el ISTA.{' '}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default WeightingHeader;
