import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PaymentsDebts } from '../interfaces/paymentsHistory.interface';

interface PaymentsHistoryState {
  paymentsHistoryList: [];
  selectedDebts: PaymentsDebts[];
  selectedDebtsId: number[];
  debtorId: number;
  receipt?: unknown;
  accountStateDialogOpen: boolean;
}

const initialState: PaymentsHistoryState = {
  paymentsHistoryList: [],
  selectedDebts: [],
  selectedDebtsId: [],
  debtorId: 0,
  accountStateDialogOpen: false,
};

const paymentsHistorySlice = createSlice({
  name: 'paymentsHistory',
  initialState,
  reducers: {
    setPaymentsHistory: (state, action) => {
      state.paymentsHistoryList = action.payload;
    },
    setSelectedDebts: (state, action: PayloadAction<PaymentsDebts[]>) => {
      state.selectedDebts = action.payload;
      // state.selectedDebts = action.payload.sort((a, b) => {
      //   return Date.parse(a.fees_expiration_date) - Date.parse(b.fees_expiration_date);
      // });
    },
    setDebtorId: (state, action: PayloadAction<number>) => {
      state.debtorId = action.payload;
    },
    setSelectedDebtsId: (state, action: PayloadAction<number[]>) => {
      state.selectedDebtsId = action.payload;
    },
    setReceiptData: (state, action: PayloadAction<unknown>) => {
      state.receipt = action.payload;
    },

    resetReceipt: (state) => {
      state.receipt = undefined;
    },
    fireAccountStateDialog: (state, action: PayloadAction<boolean>) => {
      state.accountStateDialogOpen = action.payload;
    },
  },
});

export const {
  setPaymentsHistory,
  setSelectedDebts,
  setSelectedDebtsId,
  setDebtorId,
  fireAccountStateDialog,
} = paymentsHistorySlice.actions;

export default paymentsHistorySlice.reducer;
