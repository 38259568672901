import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISearchTable } from '../../../shared/interfaces/paginatedTable.interface';

interface ElectronicDocumentsState {
  updateModalOpen: boolean;
  resendModalOpen: boolean;
  electronicDocumentIdUpdate?: number;
  electronicDocumentsTable: ISearchTable;
}
const initialState: ElectronicDocumentsState = {
  updateModalOpen: false,
  resendModalOpen: false,
  electronicDocumentsTable: {
    page: 1,
    perPage: 5,
    search: '',
  },
};
const electronicDocumentsSlice = createSlice({
  name: 'electronicDocuments',
  initialState,
  reducers: {
    setUpdateModalOpen: (state, action: PayloadAction<boolean>) => {
      state.updateModalOpen = action.payload;
    },
    openUpdateModalWith: (state, action: PayloadAction<number>) => {
      state.electronicDocumentIdUpdate = action.payload;
      state.updateModalOpen = true;
    },
    resetElectronicDocumentIdUpdate: (state) => {
      state.electronicDocumentIdUpdate = undefined;
    },
    setResendModalOpen: (state, action: PayloadAction<boolean>) => {
      state.resendModalOpen = action.payload;
    },
    openResendModalWith: (state, action: PayloadAction<number>) => {
      state.electronicDocumentIdUpdate = action.payload;
      state.resendModalOpen = true;
    },
    updateElectronicDocumentsTable: (state, action: PayloadAction<ISearchTable>) => {
      state.electronicDocumentsTable = action.payload;
    },
  },
});
export const {
  setUpdateModalOpen,
  openUpdateModalWith,
  resetElectronicDocumentIdUpdate,
  setResendModalOpen,
  openResendModalWith,
  updateElectronicDocumentsTable,
} = electronicDocumentsSlice.actions;
export default electronicDocumentsSlice.reducer;
