export const faqsEcotec = [
  {
    question: 'Me han robado o he perdido algo. ¿A quién debo dirigirme?',
    answer:
      'La Biblioteca no se hace responsable por las pérdidas de objetos personales dentro de la misma, sin embargo, el usuario podrá comunicar la pérdida del objeto a la persona responsable. Le aconsejamos mantener sus objetos personales al alcance de su vista.',
  },
  {
    question: '¿Cuáles son los servicios que presta la Biblioteca?',
    answer: `<ul>   
                <li>Bases de datos bibliográficas: SCOPUS, Proquest, E-brary, BiblioTechnia y Fielweb.
                </li><li>Préstamos de libros a domicilio.</li>
                <li>Préstamos de notebooks para uso exclusivo en la sala de la Biblioteca.</li>
                <li>Computadores para uso académico-científico.</li>
                <li>Cubículos para estudio grupales.</li>
                <li>Mesas bipersonales en sala para trabajo.</li>
                <li>Hemeroteca; con revista científica Res non Verba y revistas de suscripción.</li>
                <li>Capacitaciones en la utilización de bases de datos y servicios Bibliotecarios.</li>
            </ul>`,
  },
  {
    question: '¿Qué necesito para usar los servicios que presta la Biblioteca?',
    answer:
      'Para el uso de los servicios de la Biblioteca será preciso presentar el carné vigente que lo acredita como miembro de la comunidad universitaria de ECOTEC. En el caso de los usuarios externos, deberán presentar su cédula de ciudadanía y el carné de la institución de la cual proviene, lo que les permitirá realizar consultas, exclusivamente, al interior de la Biblioteca.',
  },
  {
    question: 'Si he terminado mis estudios, ¿puedo seguir usando la Biblioteca?',
    answer:
      'El ser graduado de la Universidad ECOTEC te permite utilizar todos los servicios de la Biblioteca en las mismas condiciones que los usuarios externos.',
  },
  {
    question: '¿Qué contiene el catálogo de la Biblioteca?',
    answer:
      'El catálogo contiene registros bibliográficos de los documentos disponibles en la Biblioteca y que son de distintos tipos (libros, revistas, tesis, bases de datos, etc.) y en distintos soportes (impresos, CDs, DVDs, en línea, etc.). Proporciona la descripción y la localización y/o el acceso a todos estos recursos, así como información sobre su disponibilidad: número de ejemplares, si están prestados, si son solamente de consulta en la sala de lectura, etc.',
  },
  {
    question: '¿En la Biblioteca hay computadoras disponibles para los usuarios?',
    answer:
      'Contamos con 10 computadoras y 5 notebooks para trabajar y realizar consultas académicas-científicas en las bases de datos que la Universidad ECOTEC mantiene y otras webs relacionadas con las temáticas de interés.',
  },
  {
    question: '¿Cuál es el proceso para reservar un cubículo de la Biblioteca?',
    answer: `La mayoría de los documentos están distribuidos por ESTANTERÍAS y NIVELES para que el usuario pueda consultarlos directamente. Son colocados de acuerdo al sistema de clasificación denominado Sistema de Clasificación Decimal Dewey (SCDD) son números decimales asignados a una disciplina del conocimiento, parten del 000 al 900.
    <ul><li>000 Obras Generales</li><li>Diccionarios, enciclopedias, colecciones</li><li>100 Filosofía y psicología</li><li>200 Religión.</li><li>300 Ciencias Sociales</li><li>400 Lenguas.</li><li>500 Ciencias naturales y matemáticas</li><li>600 Tecnología (Ciencias Aplicadas)</li><li>700 Artes (bellas artes y artes decorativas).</li><li>800 Literatura y Retórica.</li><li>900 Geografía e Historia</li></ul>
    Cada clase principal consta de 10 divisiones, también numeradas del 0 a 9 y esta división se subdivide en 10 secciones. Este código es un marbete que se encuentra en la parte inferior del lomo de libro.
    `,
  },
  {
    question:
      '¿Cómo puedo ingresar al catálogo de libros para realizar mi búsqueda de información?',
    answer:
      'Para revisar los libros que la universidad dispone tanto físicos como digitales debe ingresar ya sea directamente del ATRIUM o por medio de la página web, en la pestaña de iniciar sesión, donde deberá ingresar con su usuario y clave de ingreso al ATRIUM.',
  },
  {
    question: '¿Qué debo hacer para solicitar en préstamo a domicilio un libro?',
    answer:
      'Una vez que se haya encontrado el material necesario, el usuario deberá acercarse al mostrador de la Biblioteca y presentar su carnet vigente que se quedará en la Biblioteca hasta la devolución del material bibliográfico solicitado. Las obras consultadas por el usuario en sala, sólo para ser utilizadas en las dependencias de la Biblioteca y serán depositadas en los carritos de la Biblioteca inmediatamente al concluir la consulta.',
  },
  {
    question:
      '¿Hasta cuántos libros puedo solicitar en préstamo a domicilio y cuánto tiempo tengo para devolverlos?',
    answer:
      'Se pueden solicitar hasta un máximo de tres libros por persona por hasta 48 horas. Sólo se prestarán a domicilio aquellas obras que no sean de consulta frecuente o aquellas de las cuales la Biblioteca tenga varias copias o ejemplares. No podrán prestarse a domicilio los diccionarios, enciclopedias, los libros que sean ediciones únicas, revistas, periódicos y videos.',
  },
  {
    question: 'Si necesito los libros por más tiempo, ¿puedo renovarlos?',
    answer:
      'Los libros se pueden renovar, por el mismo tiempo por el que fueron prestados, siempre y cuando no tengan reserva y no haya expirado el plazo de devolución.',
  },
  {
    question: 'Si necesito los libros por más tiempo, ¿puedo extender el tiempo del préstamo?',
    answer:
      'El préstamo de los libros puede extenderse por una única ocasión por hasta 48 horas adicionales, cuando el usuario solicite su renovación antes del vencimiento del mismo vía telefónica o por correo electrónico; siempre y cuando el préstamo no esté vencido o exista un usuario en lista de espera.',
  },
  {
    question: '¿Dónde dejo los libros que ya he consultado?',
    answer:
      'Una vez consultado el material bibliográfico, no deberá ser depositado en las estanterías, sino en los carritos que están ubicados estratégicamente o consultar con el personal a cargo, para su debido control.',
  },
  {
    question: '¿Qué son las bases de datos bibliográficas?',
    answer:
      'Las bases de datos Bibliográficas son recopilaciones de publicaciones (artículos científicos, libros, capítulos de libros, tesis, congresos, etc.) con varios contenidos temáticos que tienen como objetivo reunir toda la producción bibliográfica posible sobre cada área de conocimiento.',
  },
  {
    question: '¿Cuáles son las bases de datos bibliográficas que mantiene la Universidad ECOTEC?',
    answer: 'SCOPUS, Proquest, E-book, Bibliotechnia y Fielweb.',
  },
];
