import { Switch, Route } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';

import ErrorFallbackComponentScreen from '../../shared/components/errors-boundary/ErrorFallbackComponentScreen';

import AcademicPortfolioRouter from '../academic-portfolio/router';
import PaymentsRouter from '../payments-history/router';
import SubjectsEnablesRouter from '../subjects-enables/router';
import SubjectsRegisterRouter from '../subjects-register/router';
import CertificatesRouter from '../certificates/router';
import AcademicHourhandsRouter from '../academic-hourhands/router';
import AcademicRequestsRouter from '../academic-requests/router';
import StudentNewsRouter from '../news/router';
import StudentEventsRouter from '../events/router';
import AcademicCounselingRouter from '../academic-counseling/router';
import VirtualClassroomStudentRouter from '../virtual-classroom/router';
import DashboardRouter from '../dashboard/router';
import ProjectsStudentRouter from '../projects/router';
import MyActivitiesRouter from '../my-activities/router';
import StudentCurriculumRouter from '../curriculum/router/iindex';
// import BusRouteRouter from '../../maintenance/bus-route/router';

const StudentsRouter = () => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallbackComponentScreen}>
      <Switch>
        {/* Academico */}
        <Route path="/registro-asignaturas" component={SubjectsRegisterRouter} />
        <Route path="/portafolio-academico" component={AcademicPortfolioRouter} />
        <Route path="/asignaturas-habilitadas" component={SubjectsEnablesRouter} />
        <Route path="/asignaturas-horarios" component={AcademicHourhandsRouter} />
        <Route path="/malla-curricular" component={StudentCurriculumRouter} />
        <Route path="/proyectos-estudiante" component={ProjectsStudentRouter} />

        {/* estados de cuenta */}
        <Route path="/estado-de-cuenta" component={PaymentsRouter} />

        {/* Solicitudes y Certificados */}
        <Route path="/solicitudes" component={AcademicRequestsRouter} />
        <Route path="/certificados" component={CertificatesRouter} />

        {/* Consejería académica */}
        <Route path="/consejeria-academica" component={AcademicCounselingRouter} />

        {/* Noticias y eventos */}
        <Route path="/eventos-visualizar" component={StudentEventsRouter} />
        <Route path="/noticias-visualizar" component={StudentNewsRouter} />

        {/* Aula virtual */}
        {/* Nota, una ves que se consolide el módulo de aula virtual remover "/evaluaciones" */}
        {/* /aula-virtual-estudiante debería exponer toda la lógica de aula virtual estudiante*/}
        <Route path="/aula-virtual-estudiante" component={VirtualClassroomStudentRouter} />

        {/* Pantalla de inicio */}
        <Route path="/inicio-estudiante" component={DashboardRouter} />

        {/* Rutas de mis actividades */}
        <Route path="/mis-actividades" component={MyActivitiesRouter} />
        {/* <Route path="/abus-route" component={BusRouteRouter} /> */}
      </Switch>
    </ErrorBoundary>
  );
};

export default StudentsRouter;
