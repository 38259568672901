import { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { IconInfoCircle } from '@tabler/icons';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import {
  setProjectFollowList,
  updateProjectFollowTableStates,
} from '../slices/followUpOfResearchProjectSlice';
import SearchPaginatedTable from '../../../shared/components/tables/SearchPaginatedTable';
import { useTableSearch } from '../../../../hooks/useTableSearch';

import { useGetProjectsFollowQuery } from '../slices/followUpOfResearchProjectApiSlice';
import OmniChipAcronym from '../../../shared/components/badges/OmniChipAcronym';
import StatusProject from '../../lines-of-investigation/constants/lists/statusProject';
import ProjectFollowFilter from './ProjectFollowFilter';
import { DataFilterProjectFollow } from './ProjectFollowFilter';
import StudentsButton from '../../../shared/components/tables/buttons/StudentsButton';
import CustomIconButton from '../../../shared/components/tables/buttons/CustomIconButton';

const ProjectFollowTable = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { path } = useRouteMatch();

  const history = useHistory();

  const [dataFilter, setDataFilter] = useState<DataFilterProjectFollow>();

  const { page, perPage, search, filterArrow } = useAppSelector(
    (state) => state.followUpOfResearch.projectFollowTable
  );

  const { data, isLoading, isFetching, error } = useGetProjectsFollowQuery({
    search,
    type_sort: filterArrow?.direction || '',
    sort: filterArrow?.name || '',
    page,
    size: perPage,
    ...(dataFilter?.rangeDate?.[0] && { start_date: dataFilter.rangeDate[0] }),
    ...(dataFilter?.rangeDate?.[1] && { end_date: dataFilter.rangeDate[1] }),
    ...(dataFilter?.statusProject && { end_date: dataFilter.rangeDate[1] }),
  });

  const { setPage, setPerPage, setSearch, setFilterArrow } = useTableSearch({
    page,
    perPage,
    search,
    updateFunction: updateProjectFollowTableStates,
    filterArrow,
  });

  const viewStudents = (collaboratorId: string | number) => {
    history.push(`${path}/${collaboratorId}/estudiantes`);
  };

  const viewInfoProject = (collaboratorId: string | number) => {
    history.push(`${path}/${collaboratorId}`);
  };

  const datafetch = data?.data!.map((d, index) => ({
    id: { index: index + 1 + (page - 1) * perPage, id: d.project_research_id },
    startDate: d.project_research_start_date,
    endDate: d.project_research_end_date,
    name: d.project_research_name,
    description: d.project_research_activity_description,
    coordinator: d.project_research_coordinator,
    statusProject: d.project_research_status,
    options: d,
  }));

  useEffect(() => {
    if (data) {
      dispatch(setProjectFollowList(data.data));
    }
  }, [data, dispatch]);

  console.log('dataFilter', dataFilter, dataFilter?.rangeDate?.[0]);

  return (
    <>
      <Grid container item xs={12} my={2}>
        <ProjectFollowFilter dispatchFilterData={setDataFilter} />
      </Grid>
      <SearchPaginatedTable
        data={datafetch!}
        headers={{
          id: t('N°'),
          startDate: t('Fecha de inicio'),
          endDate: t('Fecha de finalización'),
          name: t('academic.subjects.name'),
          description: t('Descripción de la actividad'),
          coordinator: t('Coordinador'),
          statusProject: t('academic.subjects.status'),
          options: t('academic.subjects.options'),
        }}
        keyExtractor={(item) => item.id.index.toString()}
        searchPlacehoder={t('lines_research.index.investigationProjectFilter.search.project')}
        isLoading={isLoading}
        isFetching={isFetching}
        error={error}
        perPage={perPage}
        setPerPage={setPerPage}
        total={data?.total || 0}
        page={page}
        setPage={setPage}
        numHeader={7}
        search={search}
        listFilterArrows={{
          description: 'project_research_activity_description',
          startDate: 'project_research_start_date',
          endDate: 'project_research_end_date',
          statusProject: 'project_research_status',
          name: 'project_research_name',
          coordinator: 'project_research_coordinator',
        }}
        filterArrow={filterArrow}
        setFilterArrow={setFilterArrow}
        setSearch={setSearch}
        customRenderers={{
          id: (row) => row.id.index,
          statusProject: (row) => (
            <OmniChipAcronym
              acronym={row.statusProject}
              statusArray={StatusProject.map(({ keyword, label, color }) => ({
                name: label,
                acronym: keyword,
                color,
              }))}
            />
          ),

          options: (row) => (
            <>
              <StudentsButton
                handleClick={() => {
                  viewStudents(row.id.id);
                }}
              />
              <CustomIconButton
                title="Información de proyecto"
                Icon={IconInfoCircle}
                handleClick={() => viewInfoProject(row.id.id)}
              />
            </>
          ),
        }}
        customDataCellsProperties={{
          id: {
            align: 'center',
          },
          name: {
            align: 'center',
          },
          description: {
            align: 'center',
          },
          coordinator: {
            align: 'center',
          },
          statusProject: {
            align: 'center',
          },
          options: {
            align: 'center',
          },
        }}
      />
    </>
  );
};

export default ProjectFollowTable;
