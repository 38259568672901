import { Avatar, Grid, IconButton, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core';
import { IconArrowNarrowLeft } from '@tabler/icons';
import { useAppDispatch } from '../../../../../hooks/useRedux';
import { stylesStudentAsignedDetail } from '../constants/styles';
import {
  resetCourseSelected,
  resetStudentSelected,
  setOpenModalStudentDetailCourse,
} from '../slices/studentsAsignedSlice';
import { useAppSelector } from '../../../../../hooks/useRedux';
import { useGetStudentCourseQuery } from '../slices/studentsAsignedApiSlice';
import { renderSkeletonTable } from '../../../../shared/helpers/render';
import PerfectScrollbar from 'react-perfect-scrollbar';
import OmniChip from '../../../../shared/components/badges/OmniChip';
import { courseStudentStatus } from '../constants';
import { obtainDomainStorageImage } from '../../../../shared/helpers';
import SimpleTable from './tables/SimpleTable';
import ExtendedSideDialog from '../../../../shared/components/dialogs/ExtendedSideDialog';
import { useTranslation } from 'react-i18next';

const StudentAsignedCourseDetail = function () {
  const { t } = useTranslation();

  const theme = useTheme();

  const styles = stylesStudentAsignedDetail();

  const dispatch = useAppDispatch();

  const { studentSelected, studentsList, courseSelected } = useAppSelector(
    (state) => state.studentsAsigned
  );

  const student = studentsList.find(
    (value) => value?.user?.user_id === studentSelected?.toString()
  );

  const course = student?.courses.find((value) => Number(value.course_id) === courseSelected);

  const { data, isLoading, error, isFetching } = useGetStudentCourseQuery({
    courseId: courseSelected!,
    studentId: studentSelected!,
  });

  const handleClose = () => {
    dispatch(resetStudentSelected());

    dispatch(resetCourseSelected());

    dispatch(setOpenModalStudentDetailCourse(false));
  };

  const dataFetch =
    data &&
    data?.map((value) => ({
      supply: value?.supply?.name,
      finalNote: value?.course_student?.final_note,
      status: value?.course_student?.approval_status.id,
    }));

  return (
    <ExtendedSideDialog
      open={true}
      keepMounted
      onClose={() => handleClose()}
      className={styles.container}
      classes={{
        paper: styles.paper,
      }}
    >
      <Grid item xs={12} style={{ marginLeft: 25 }}>
        <Grid item container alignItems="center" gap={1} sx={{ cursor: 'pointer' }}>
          <IconButton onClick={() => handleClose()}>
            <IconArrowNarrowLeft size={30} color={theme.palette.text.dark} />
          </IconButton>
          <Typography variant="h3">
            {t('teachers.academic_counseling.students_assigned.detail.back')}
          </Typography>
        </Grid>
      </Grid>

      <Grid item xs={12} style={{ marginLeft: 40, marginTop: 40 }}>
        <Grid container spacing={2}>
          <Grid item>
            <Avatar
              alt={student?.user?.person?.pers_first_name!}
              src={obtainDomainStorageImage(student?.stud_photo_path!)}
            />
          </Grid>
          <Grid item xs zeroMinWidth>
            <Typography align="left" component="div" variant="h3">
              {student?.user?.person?.pers_first_name} {student?.user?.person?.pers_first_lastname}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs zeroMinWidth>
                <Typography align="left" component="div" variant="subtitle2">
                  {t('teachers.academic_counseling.students_assigned.detail.students')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} style={{ marginLeft: 40, marginTop: 40 }}>
        <Grid container spacing={2}>
          <Grid item xs zeroMinWidth>
            <Typography align="left" component="div" variant="h3">
              {course?.mat_name}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs zeroMinWidth>
                <Typography align="left" component="div" variant="subtitle2">
                  {t('teachers.academic_counseling.students_assigned.detail.course_detail')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid style={{ marginLeft: 40, marginTop: 30, marginRight: 40 }}>
        <PerfectScrollbar>
          {isLoading ? (
            renderSkeletonTable(4, 3)
          ) : (
            <SimpleTable
              data={dataFetch! || []}
              headers={{
                supply: t('teachers.academic_counseling.students_assigned.detail.supply'),
                finalNote: t('teachers.academic_counseling.students_assigned.detail.final_grade'),
                status: t('teachers.academic_counseling.students_assigned.detail.status'),
              }}
              numHeader={5}
              isLoading={isLoading}
              isFetching={isFetching}
              error={error}
              keyExtractor={(item) => `${item.supply}-${item.status.toString()}`}
              customRenderers={{
                status: ({ status }) => (
                  <OmniChip variant={1} id={Number(status)} statusArray={courseStudentStatus} />
                ),
              }}
              customDataCellsProperties={{
                status: {
                  align: 'center',
                },
              }}
              customHeadersCellsProperties={{
                status: {
                  align: 'center',
                },
              }}
            />
          )}
        </PerfectScrollbar>
      </Grid>
    </ExtendedSideDialog>
  );
};

export default StudentAsignedCourseDetail;
