import { useEffect, useMemo, useState } from 'react';

// material-ui
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Avatar, Grid, Stack, Typography, useMediaQuery } from '@material-ui/core';

import MailIcon from '@material-ui/icons/Mail';

// assets
import Noloadedavatar from './../../../../../src/assets/images/users/noloadedavatar.png';
import { useAppSelector } from '../../../../hooks/useRedux';

import bgImg from '../../../../assets/images/fondo-student-dashboard-default.jpg';

import useGetBaseUrl from '../../../../hooks/useGetBaseUrl';
import { LoginBanner } from '../../../shared/interfaces/tenants.interfaces';

// style constant
const useStyles = makeStyles((theme) => ({
  card: {
    color: '#fff',
    overflow: 'hidden',
    position: 'relative',
  },
  content: {
    padding: '0px !important',
    marginLeft: '10px !important',
  },
  avatar: {
    ...theme.typography.commonAvatar,
    ...theme.typography.largeAvatar,
    margin: '8px',
    height: 'auto',
    width: 'auto',
    backgroundColor: 'transparent',
    cursor: 'default',
    // backgroundColor: theme.palette.secondary[200],
    padding: '0px 15px',
  },
  avatarRight: {
    ...theme.typography.commonAvatar,
    ...theme.typography.mediumAvatar,
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.secondary[200],
    zIndex: 1,
  },
  cardHeading: {
    fontSize: '1.1rem',
    fontWeight: 800,
    marginRight: '8px',
    marginTop: '14px',
    marginBottom: '6px',
    color: theme.palette.primary.main,
  },
  cardHeadingLite: {
    fontSize: '1.1rem',
    fontWeight: 500,
    marginRight: '8px',
    marginTop: '5px',
    marginBottom: '5px',
    // color: theme.palette.primary.main,
  },
  subHeading: {
    fontSize: '1rem',
    fontWeight: 300,
    color: theme.palette.primary.main,
  },
  avatarCircle: {
    cursor: 'pointer',
    ...theme.typography.smallAvatar,
    backgroundColor: theme.palette.secondary[200],
    color: theme.palette.secondary.dark,
  },
  transformAvatar: {
    transform: 'scale(1.2) translateY(-20px)',
    padding: '0px 25px',
  },
  circleIcon: {
    transform: 'rotate3d(1, 1, 1, 45deg)',
  },
  menuItem: {
    marginRight: '14px',
    fontSize: '1.25rem',
  },
}));

//===========================|| DASHBOARD DEFAULT - EARNING CARD ||===========================//
const ProfileStudentCard = () => {
  const classes = useStyles();
  const theme = useTheme();

  const isNotSm = useMediaQuery(theme.breakpoints.up('md'));

  const { urlDownload } = useGetBaseUrl();

  const { userInfo } = useAppSelector((state) => state.auth);
  const { currentRecordStudent, currentProfile /* currentStudent */ } = useAppSelector(
    (state) => state.access
  );

  const [photo, setPhoto] = useState(Noloadedavatar);

  // const { data: redlinksInfo } = useGetRedlinksAvatarQueryQuery(
  //   {
  //     identification: userInfo.identification,
  //   },
  //   { skip: !userInfo.identification }
  // );

  // TODO: refactorizar el flujo del onError de la imagen en un componente separado que tome el string e internamente se encargue de controlar el error
  const handleResetPhoto = () => {
    setPhoto(Noloadedavatar);
  };

  useEffect(() => {
    if (currentProfile?.imgProfile) setPhoto(`${urlDownload}${currentProfile?.imgProfile}`);
  }, [currentProfile?.imgProfile, urlDownload]);

  // useEffect(() => {
  //   if (redlinksInfo?.result?.foto) setPhoto(redlinksInfo?.result?.foto);
  // }, [redlinksInfo]);

  const storage = localStorage.getItem('banners');

  const { baseUrl } = useGetBaseUrl();

  const banner = useMemo(() => {
    const banners = storage ? (JSON.parse(storage) as LoginBanner[]) : undefined;

    if (banners && banners.length && banners.find((dt) => dt.banner_type === 'DASHBOARD')) {
      return `${baseUrl}/show?name=${
        banners.find((dt) => dt.banner_type === 'DASHBOARD')?.banner_url
      }`;
    } else {
      return bgImg;
    }
  }, [baseUrl, storage]);

  return (
    <Grid
      container
      // justifyContent="center"
      alignItems="center"
      sx={{
        backgroundColor: `${theme.palette.background.paper}`, //'white',
        // borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0px 0px`,
        borderRadius: `12px 12px 0px 0px`,
        paddingBottom: '10px',
      }}
    >
      <Grid item xs={12}>
        <img
          src={bgImg}
          srcSet={`${banner} 1.5x,${banner} 2x`}
          style={{ width: '100%', borderRadius: '12px 12px 0px 0px' }}
          alt="fondo inicio"
        />
      </Grid>

      <Grid container px={2}>
        <Grid item>
          <Avatar
            variant="rounded"
            className={`${classes.avatar} ${isNotSm ? classes.transformAvatar : ''}`}
          >
            <img
              style={{
                height: '140px',
                width: '140px',
                borderRadius: '50%',
                // backgroundColor: `${theme.palette.grey[300]}`,
              }}
              src={photo}
              onError={handleResetPhoto}
              alt={userInfo?.fullname ? userInfo.fullname : 'Estudiante'}
            />
          </Avatar>
        </Grid>

        <Grid item>
          <Typography className={classes.cardHeading}>
            {/* {`Bienvenid${userInfo?.gender === 'Masculino' ? 'o' : 'a'}`}  */}
            {userInfo?.fullname}
          </Typography>
          <Stack direction="row" alignItems="center" spacing={1}>
            <MailIcon />
            <Typography component="span" className={classes.cardHeadingLite}>
              {userInfo?.email}
            </Typography>
          </Stack>

          <Typography>Estudiante de {userInfo.studentInfo?.offerName?.toLowerCase()}</Typography>
          <Typography sx={{ marginBottom: '6px' }}>
            Código de estudiante: {currentRecordStudent?.stud_code_record}
          </Typography>

          <Typography
            className={classes.subHeading}
            sx={{
              mb: 0.5,
            }}
          >
            {currentRecordStudent?.education_level?.father?.edu_name || ''}
          </Typography>
          <Typography className={classes.subHeading}>
            {currentRecordStudent?.education_level.edu_name} -{' '}
            {currentRecordStudent?.mesh?.mes_name}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProfileStudentCard;
