import { payValidMethodsType } from '../interfaces/components.interface';

export const checkoutSteps = [
  'financial.debts_payment.wizard.checkout_steps.client',
  'financial.debts_payment.wizard.checkout_steps.outstanding_debts',
  'financial.debts_payment.wizard.checkout_steps.billing',
  'financial.debts_payment.wizard.checkout_steps.payment',
];

export const payValidMethods: payValidMethodsType[] = [
  'forma-pago-efectivo',
  'forma-pago-tarjeta-cred',
  'forma-pago-tarjeta-deb',
  'forma-pago-deposito',
  'forma-pago-transferencia',
  'forma-pago-cheque',
  'forma-pago-saldo-favor',
];

export const accountTypes = [
  {
    id: 1,
    label: 'financial.debts_payment.pay_dialog.form.cheque_method.account_type.savings_account', //Savings account
    keyword: 'CA',
  },
  {
    id: 2,
    label: 'financial.debts_payment.pay_dialog.form.cheque_method.account_type.checking_account', //checking account
    keyword: 'CC',
  },
];
