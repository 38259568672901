import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISearchTable } from '../../../shared/interfaces/paginatedTable.interface';
import { BusRoute, BusRoutesCampus, BusSchedule } from '../interfaces/buses.interfaces';
import { CustomChilds } from '../../../shared/interfaces/material-ui.interfaces';
import { Campus } from '../../campus/interfaces/campus.interfaces';

interface BusesSliceState {
  busRouteIdSelected: number | null;
  busRouteData: Partial<BusRoute> | null;

  campusSelected: BusRoutesCampus | null;

  busSchedulesList: BusSchedule[];
  busSchedulesTable: ISearchTable;

  busRoutesList: CustomChilds<BusRoute, { campus: Campus }>[];
  busRoutesTable: ISearchTable;
}

const initialState: BusesSliceState = {
  busRouteIdSelected: null,
  busRouteData: null,

  campusSelected: null,

  busSchedulesList: [],
  busSchedulesTable: {
    page: 1,
    perPage: 5,
    search: '',
  },

  busRoutesList: [],
  busRoutesTable: {
    page: 1,
    perPage: 5,
    search: '',
  },
};

const busRouteSlice = createSlice({
  name: 'busRoute',
  initialState,
  reducers: {
    // setAreasList: (state, action: PayloadAction<Area[]>) => {
    //   state.areasList = action.payload;
    // },

    setBusRouteIdSelected: (state, action: PayloadAction<number | null>) => {
      state.busRouteIdSelected = action.payload;
    },

    setBusRouteData: (state, action: PayloadAction<Partial<BusRoute> | null>) => {
      state.busRouteData = action.payload;
    },

    setBusCampusData: (state, action: PayloadAction<BusRoutesCampus | null>) => {
      state.campusSelected = action.payload;
    },

    setBuseSchedulesList: (state, action: PayloadAction<BusSchedule[]>) => {
      state.busSchedulesList = action.payload;
    },
    updateScheduleTableStates: (state, action: PayloadAction<ISearchTable>) => {
      state.busSchedulesTable = action.payload;
    },

    setBusRoutesList: (
      state,
      action: PayloadAction<CustomChilds<BusRoute, { campus: Campus }>[]>
    ) => {
      state.busRoutesList = action.payload;
    },
    updateBusRoutesTableStates: (state, action: PayloadAction<ISearchTable>) => {
      state.busRoutesTable = action.payload;
    },
  },
});

export const {
  setBusRouteIdSelected,
  setBusRouteData,
  setBusCampusData,
  setBuseSchedulesList,
  updateScheduleTableStates,

  setBusRoutesList,
  updateBusRoutesTableStates,
} = busRouteSlice.actions;

export default busRouteSlice.reducer;
