import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CircularProgress from '@material-ui/core/CircularProgress';

import Typography from '@material-ui/core/Typography';
import {
  useDeleteStudentByProjectMutation,
  useDeleteCollaboratorByProjectMutation,
} from '../../../slices/InvestigationLinesApiSlice';

interface Props {
  name: string;
  id: string;
  type: UserType;
  hours?: string;
  collTyp?: string;
}

type UserType = 'student' | 'collaborator';

const AssignPersonsDelete = ({ name, id, type, hours, collTyp }: Props) => {
  const [deleteStudent, { isLoading: isLoadingStudentDeleted }] =
    useDeleteStudentByProjectMutation();

  const [deleteCollaborator, { isLoading: isLoadingCollaboratorDelete }] =
    useDeleteCollaboratorByProjectMutation();

  const handleDelete = (id: string) => {
    if (type === 'student') {
      deleteStudent({ studentProjectResearchId: id });
    } else if (type === 'collaborator') {
      deleteCollaborator({ collaboratorProjectResearchId: id });
    }
  };

  return (
    <Grid item xs={12} mt={2}>
      <Paper elevation={2} sx={{ width: '100%', padding: '5px' }}>
        <Grid container justifyContent="space-between" alignItems="center" p={1} spacing={1}>
          <Grid item>
            <Typography variant="subtitle1" color="initial">
              {name}
            </Typography>
          </Grid>
          <Grid item>
            <Tooltip title={'Quitar persona'}>
              <IconButton aria-label="test" onClick={() => handleDelete(id)}>
                {isLoadingStudentDeleted || isLoadingCollaboratorDelete ? (
                  <CircularProgress size={20} />
                ) : (
                  <HighlightOffIcon color="primary" />
                )}
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={6}>
            horas:{' '}
            <Typography variant="subtitle1" component={'span'}>
              {hours}
            </Typography>
          </Grid>
          {!!collTyp && (
            <Grid item xs={6}>
              Tipo:{' '}
              <Typography variant="subtitle1" component={'span'}>
                {collTyp}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Paper>
    </Grid>
  );
};

export default AssignPersonsDelete;
