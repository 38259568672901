import { CircularProgress, IconButton, Tooltip } from '@material-ui/core';
import { IconFileDescription } from '@tabler/icons';

import { useTranslation } from 'react-i18next';

import { useLazyGetDownloadPaidCertificatesQuery } from '../slices/certificateApiSlice';
//import { useEffect,  useState } from 'react';
interface Props {
  certificateId: number;
}
const DownloadPaidCertificate = ({ certificateId }: Props) => {
  const { t } = useTranslation();

  const [
    getPdf,
    {
      //   data: pdfData,
      isLoading: isLoadingPDFA,
      isFetching: isFetchingPDFA,
      //refetch,
      // request,
    },
  ] = useLazyGetDownloadPaidCertificatesQuery();

  const handleDownload = () => {
    getPdf(
      {
        certificateId,
      },
      true
    )
      .unwrap()
      .then((response) => {
        console.log(response);
        const file = new Blob([response], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      })
      .catch((error) => console.log(error));
  };

  return (
    <>
      <Tooltip title={t('students.certificates.paid_certificates.table.download')}>
        {isLoadingPDFA || isFetchingPDFA ? (
          <CircularProgress size={20} />
        ) : (
          <IconButton
            color="inherit"
            onClick={handleDownload}
            disabled={isLoadingPDFA || isFetchingPDFA}
            //endIcon={(isLoadingPDFA || isFetchingPDFA) && <CircularProgress size={20} />}
          >
            <IconFileDescription />
          </IconButton>
        )}
      </Tooltip>
    </>
  );
};
export default DownloadPaidCertificate;
