import React, { Suspense } from 'react';

import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';

import MainCard from '../../../../shared/components/cards/MainCard';
const MeetingsList = React.lazy(() => import('../components/MeetingsList'));

const MeetingsScreen = function () {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Grid
        component={MainCard}
        sx={{
          borderTopLeftRadius: '0px',
          borderTopRightRadius: '0px',
        }}
      >
        <Grid sx={{ mb: 3 }}>
          <Typography variant="h2">{t('teacher_virtual_classroom.meetings.title')}</Typography>
        </Grid>

        <Suspense fallback={<div>loading...</div>}>
          <MeetingsList />
        </Suspense>
      </Grid>
    </React.Fragment>
  );
};

export default MeetingsScreen;
