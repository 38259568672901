import { Switch, Redirect } from 'react-router-dom';
import { useAppSelector } from '../../../../hooks/useRedux';

import MaintenanceCategory from '../screens/MaintenanceCategory';
import MaintenanceCategoryAdd from '../screens/MaintenanceCategoryAdd';
import MaintenanceCategoryEdit from '../screens/MaintenanceCategoryEdit';

import GuardRoute from '../../../../router/guards/GuardRoute';
import { permissionKey, subPermissionKeys } from '../constants/permissions';

const MaintenanceCategoryRouter = () => {
    const { currentModule } = useAppSelector((state) => state.access);

    const hasPermission = 'economicGroup' in currentModule?.permissions!;

    if (!hasPermission) return <Redirect to="/dashboard" />;

    return (
        <Switch>
            <GuardRoute
                exact
                path="/administrar-mantenimiento-categoria"
                component={MaintenanceCategory}
                permissionKey={permissionKey}
                subPermissionKey={subPermissionKeys.read}
            />
            <GuardRoute
                exact
                path="/administrar-mantenimiento-categoria/agregar"
                component={MaintenanceCategoryAdd}
                permissionKey={permissionKey}
                subPermissionKey={subPermissionKeys.create}
            />
            <GuardRoute
                exact
                path="/administrar-mantenimiento-categoria/:categoryId/editar"
                component={MaintenanceCategoryEdit}
                permissionKey={permissionKey}
                subPermissionKey={subPermissionKeys.edit}
            />
        </Switch>
    )
}

export default MaintenanceCategoryRouter;