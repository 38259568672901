import { useEffect } from 'react';

import { useAppSelector } from './useRedux';
import { useGetCompanyParameterKeywordQuery } from '../modules/maintenance/company-parameters/slices/parameterCompanyApiSlice';

const toggleVisibilityIframes = (state: boolean) => {
  const frame = document.querySelector('[data-product="web_widget"]');
  const frameBox = document.getElementById('webWidget');
  const btnFrame = document.getElementById('launcher');
  if (frame) frame.setAttribute('hidden', `${state ? 'true' : ''}`);
  if (frameBox) frameBox.hidden = state;
  if (btnFrame) btnFrame.hidden = state;
};

export const useZendesk = () => {
  const { userInfo } = useAppSelector((state) => state.auth);

  const { data } = useGetCompanyParameterKeywordQuery({ keyword: 'ZEND' });

  //si se planea manipular en mayor medida el head de la app, considerar usar react helmet para setear titles, metas, etc.
  useEffect(() => {
    let script = document.createElement('script');

    if (/* userInfo.zendesk && */ data?.url) {
      toggleVisibilityIframes(false); // linkssupport Zendesk Widget script
      script.src = `${data.url}`; // 'https://static.zdassets.com/ekr/snippet.js?key=af62c2f9-0a53-40dd-909c-222363612120';
      script.async = true;

      script.id = 'ze-snippet';
    }
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
      toggleVisibilityIframes(true);

      // const zendeskComponents = document.querySelectorAll(
      //   'body > div[style="visibility: visible;"]'
      // );

      // if (zendeskComponents.length === 1) {
      //   // if has nodes remove them  ==> ocultar mejor que remover
      //   zendeskComponents[0].remove();
      // } else if (zendeskComponents.length > 0) {
      //   zendeskComponents.forEach((element) => {
      //     // if has nodes search that has child div and insidee that div iframe node
      //     if (element.children[0].children[0].nodeName === 'IFRAME') {
      //       element.remove();
      //     }
      //   });
      // }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo.zendesk, data]);
};
