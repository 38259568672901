import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Shelf } from '../interfaces/shlefs.interface';

interface ShelftState {
  shelfToEdit?: Shelf;
  deleteModalOpen: boolean;
  shelftToDelete?: number;
}

const initialState: ShelftState = {
  deleteModalOpen: false,
};

const shelftsSlice = createSlice({
  name: 'shelfts',
  initialState,
  reducers: {
    setShelfToEdit: (state, action: PayloadAction<Shelf | undefined>) => {
      state.shelfToEdit = action.payload;
    },
    setDeleteModalOpen: (state, action: PayloadAction<boolean>) => {
      state.deleteModalOpen = action.payload;
    },
    openDeleteModalWith: (state, action: PayloadAction<number>) => {
      state.shelftToDelete = action.payload;
      state.deleteModalOpen = true;
    },
    resetShelfToDelete: (state) => {
      state.shelftToDelete = undefined;
    },
  },
});

export const { setShelfToEdit, setDeleteModalOpen, openDeleteModalWith, resetShelfToDelete } =
  shelftsSlice.actions;

export default shelftsSlice.reducer;
