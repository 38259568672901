import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../../hooks/useRedux';
import MainCard from '../../../shared/components/cards/MainCard';
import ElectronicDocumentTable from '../components/ElectronicDocumentTable';
import ResendElectronicDocumentDialog from '../components/ResendElectronicDocumentDialog';
import UpdateStateElectronicDocumentDialog from '../components/UpdateStateElectronicDocumentDialog';

const ElectronicDocumentScreen = () => {
  const { t } = useTranslation();

  const { electronicDocumentIdUpdate, updateModalOpen, resendModalOpen } = useAppSelector(
    (state) => state.electronicDocuments
  );

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">{t('financial.electronic_docs.title')}</Typography>
      </Grid>

      <ElectronicDocumentTable />

      {electronicDocumentIdUpdate && updateModalOpen && <UpdateStateElectronicDocumentDialog />}
      {electronicDocumentIdUpdate && resendModalOpen && <ResendElectronicDocumentDialog />}
    </Grid>
  );
};
export default ElectronicDocumentScreen;
