import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// types
import { AccessState, Profile, Role, Module, Permission, Student } from '../../../types';
import { StudentRecord } from '../../auth/interfaces/student-record.interfaces';

import { initialDefaultConfiguration } from '../constants/defaultConfiguration';
import {
  CompanyData,
  DefaultConfiguration,
  ServiceCredentials,
} from '../interfaces/tenants.interfaces';
// import { StudentRecord } from '../../auth/interfaces/login.interfaces';

const defaultConfiguration = localStorage.getItem('defaultConfiguration');

const initialState: AccessState = {
  currentRol: undefined,
  currentProfile: undefined,
  currentVirtualClassroom: false,
  currentModule: undefined,
  currentRecordStudent: undefined,
  currentStudent: undefined,
  changePhoto: false,
  currentEducationLevelId: undefined,
  currentPrincipalEducationLevel: undefined,
  profiles: {},
  errorDialogOpen: false,
  defaultConfiguration: defaultConfiguration
    ? JSON.parse(defaultConfiguration)
    : initialDefaultConfiguration,
  companySocialNetwork: null,
  serviceCredentials: {
    moodle: {
      sdk_secret: '',
    },
    pusher: {
      api_key: '',
      cluster: '',
    },
  },
  notifications: {
    counter: 0,
  },
  msj_business_hours: '',
};

// let currentProfile = localStorage.getItem('currentProfile');

// let currentRol = localStorage.getItem('currentRol');
// let currentModule = localStorage.getItem('currentModule');

const accessSlice = createSlice({
  name: 'access',
  initialState,
  reducers: {
    setCurrentProfile: (state, action: PayloadAction<Profile>) => {
      state.currentProfile = action.payload;
    },
    setCurrentRol: (state, action: PayloadAction<Role>) => {
      state.currentRol = action.payload;
    },
    setCurrentModule: (state, action: PayloadAction<Module>) => {
      state.currentModule = action.payload;
    },
    setCurrentPermissions: (
      state,
      action: PayloadAction<{
        [key: string]: Permission[];
      }>
    ) => {
      state.currentModule!.permissions = action.payload;
      state.currentRol!.modules[localStorage.getItem('currentModule')!].permissions =
        action.payload;
      state.currentProfile!.roles[JSON.parse(localStorage.getItem('currentRol')!).keyword!].modules[
        localStorage.getItem('currentModule')!
      ].permissions = action.payload;
    },
    resetCurrentModule: (state) => {
      state.currentModule = undefined;
    },
    setCurrentRecordStudent: (state, action: PayloadAction<StudentRecord>) => {
      state.currentRecordStudent = action.payload;
    },
    setCurrentStudent: (state, action: PayloadAction<Student>) => {
      state.currentStudent = action.payload;
    },
    setChangePhoto: (state, action: PayloadAction<boolean>) => {
      state.changePhoto = action.payload;
    },
    setCurrentEducationLevelId: (state, action: PayloadAction<number>) => {
      state.currentEducationLevelId = action.payload;
    },
    setCurrentPrincipalEducationLevel: (
      state,
      action: PayloadAction<{ id: number | null; name?: string }>
    ) => {
      state.currentPrincipalEducationLevel = action.payload;
    },
    setCurrentVirtualClassroom: (state, action: PayloadAction<boolean>) => {
      state.currentVirtualClassroom = action.payload;
    },
    saveProfiles: (state, action: PayloadAction<{ [key: string]: Profile }>) => {
      state.profiles = action.payload;
    },
    setPermissions: (
      state,
      action: PayloadAction<{
        [key: string]: Permission[];
      }>
    ) => {
      // state.profiles[localStorage.getItem('currentProfile')!].roles[
      //   JSON.parse(localStorage.getItem('currentRol')!).keyword!
      // ].modules[localStorage.getItem('currentModule')!].permissions = action.payload;
    },

    setPermissionsArr: (state, action: PayloadAction<Permission[]>) => {
      state.currentModule!.permissionsArr = action.payload;
      state.currentRol!.modules[localStorage.getItem('currentModule')!].permissionsArr =
        action.payload;
      state.currentProfile!.roles[JSON.parse(localStorage.getItem('currentRol')!).keyword!].modules[
        localStorage.getItem('currentModule')!
      ].permissionsArr = action.payload;
    },
    fireDialog: (state, action: PayloadAction<boolean>) => {
      state.errorDialogOpen = action.payload;
    },
    resetProfile: (state) => {
      const { companySocialNetwork, ...rest } = initialState;
      return { ...rest, companySocialNetwork: state.companySocialNetwork };
    },
    setDefaultConfiguration: (state, action: PayloadAction<DefaultConfiguration>) => {
      state.defaultConfiguration = action.payload;
    },
    setServiceCredentials: (state, action: PayloadAction<ServiceCredentials>) => {
      state.serviceCredentials = action.payload;
    },

    setNotificationCounter: (state, action: PayloadAction<number>) => {
      state.notifications.counter = action.payload;
    },
    addNotificationCounter: (state, action?: PayloadAction<number | undefined>) => {
      state.notifications.counter = state.notifications.counter + (action?.payload || 1);
    },

    setMsjBusinessHours: (state, action: PayloadAction<string>) => {
      state.msj_business_hours = action?.payload;
    },

    setCompanyNet: (state, action: PayloadAction<CompanyData | null>) => {
      state.companySocialNetwork = action.payload;
    },
  },
});

export const {
  saveProfiles,
  setCurrentProfile,
  setCurrentRol,
  resetProfile,
  fireDialog,
  setCurrentModule,
  resetCurrentModule,
  setCurrentRecordStudent,
  setCurrentStudent,
  setCurrentEducationLevelId,
  setChangePhoto,
  setCurrentPrincipalEducationLevel,
  setCurrentVirtualClassroom,
  setCurrentPermissions,
  setPermissions,
  setDefaultConfiguration,
  setServiceCredentials,

  addNotificationCounter,
  setNotificationCounter,
  setMsjBusinessHours,
  setCompanyNet,
  setPermissionsArr,
} = accessSlice.actions;

export default accessSlice.reducer;
