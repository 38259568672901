// import { useEffect, useState } from 'react';
// import { useHistory, useRouteMatch } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import StatusChip from '../../../shared/components/badges/StatusChip';
import { useGetPermissionsQuery } from '../slices/permissionsApiSlice';
import {
  setModulesDialogOpen,
  setPermissionIdSelected,
  updatePermissionsTable,
} from '../slices/permissionsSlice';
// import AddButton from '../../../shared/components/tables/buttons/AddButton';
import SearchPaginatedTable from '../../../shared/components/tables/SearchPaginatedTable';
import useGuard from '../hooks/useGuard';
import { useTableSearch } from '../../../../hooks/useTableSearch';
import { useTranslation } from 'react-i18next';
import { IconButton, Tooltip } from '@material-ui/core';
import { IconAbacus } from '@tabler/icons';
import ModulesDialog from './ModulesDialog';

const PermissionsTable = () => {
  const { t } = useTranslation();
  const { currentProfile } = useAppSelector((state) => state.access);

  // const { path } = useRouteMatch();
  // const history = useHistory();
  const dispatch = useAppDispatch();

  const { modulesDialogOpen } = useAppSelector((state) => state.permissions);

  const { page, perPage, search } = useAppSelector((state) => state.permissions.permissionsTable);

  const { setPage, setPerPage, setSearch } = useTableSearch({
    page,
    perPage,
    search,
    updateFunction: updatePermissionsTable,
  });

  const { isLoading, error, data, isFetching } = useGetPermissionsQuery(
    {
      profileId: currentProfile?.profileId!,
      page,
      perPage,
      search,
    },
    { refetchOnMountOrArgChange: 30 }
  );

  // const handleDelete = async (periodId: number) => {
  //   dispatch(openDeleteModalWith(periodId));
  // };

  // const goToAdd = () => {
  //   history.push(`${path}/agregar`);
  // };

  const datafetch = data?.data!.map((d, index) => ({
    id: index + (page - 1) * perPage + 1,
    alias: d?.alias,
    description: d?.description,
    parentName: d?.parent_name_translated,
    statusId: d?.status_id,
    options: d,
  }));

  const { hasAccess } = useGuard();

  const handleOpenModulesDialog = (id: number) => {
    dispatch(setPermissionIdSelected(id));
    dispatch(setModulesDialogOpen(true));
  };

  const handleCloseModulesDialog = () => {
    dispatch(setModulesDialogOpen(false));
    dispatch(setPermissionIdSelected(undefined));
  };

  return (
    <>
      <SearchPaginatedTable
        data={datafetch!}
        headers={{
          id: 'N°',
          alias: t('configuration.permissions.name'),
          description: t('configuration.permissions.description'),
          parentName: t('configuration.permissions.parent'),
          statusId: t('configuration.permissions.status'),
          options: t('configuration.permissions.options'),
        }}
        listFilterArrows={{}}
        keyExtractor={(item) => item.id.toString()}
        searchPlacehoder={t('configuration.permissions.search')}
        isLoading={isLoading}
        isFetching={isFetching}
        error={error}
        perPage={perPage}
        setPerPage={setPerPage}
        total={data?.total || 0}
        page={page}
        setPage={setPage}
        numHeader={6}
        search={search}
        setSearch={setSearch}
        customRenderers={{
          statusId: (row) => <StatusChip statusId={Number(row.statusId)} />,
          options: (row) => (
            <>
              {hasAccess('edit') && (
                // <Link to={`${path}/${row.options.id}/editar`}>
                //   <EditButton />
                // </Link>
                <Tooltip title="Módulos">
                  <IconButton onClick={() => handleOpenModulesDialog(row.options.id)}>
                    <IconAbacus />
                  </IconButton>
                </Tooltip>
              )}
              {/* {hasAccess('delete') && (
              <DeleteButton handleDelete={() => handleDelete(row.options.id)} />
            )} */}
            </>
          ),
        }}
        // ActionButtons={hasAccess('create') ? <AddButton size="large" onClick={goToAdd} /> : <></>}
      />
      {modulesDialogOpen && (
        <ModulesDialog open={modulesDialogOpen} handleClose={handleCloseModulesDialog} />
      )}
    </>
  );
};

export default PermissionsTable;
