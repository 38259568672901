import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import {
  Autocomplete,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  TextField,
  InputAdornment,
  Switch,
} from '@material-ui/core';

import { useFormik } from 'formik';

import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import InputLabel from '../../../shared/components/forms/InputLabel';

import { useAppSelector } from '../../../../hooks/useRedux';
import { useAddEducationLevelMutation } from '../slices/educationLevelsApiSlice';

import { ValidationSchema } from '../constants/validationSchemas';
import { statusAvailables } from '../../../shared/constants/resourceStatus';
import { useFormStyles } from '../constants/styles';
import {
  useGetAvailablesOffersQuery,
  useGetInfoOfferQuery,
} from '../../academic-offerings/slices/academicOfferingsApiSlice';

const AddEducationLevelForm = ({ handleClose }: { handleClose?: () => void }) => {
  const history = useHistory();
  const classes = useFormStyles();

  const { currentProfile } = useAppSelector((state) => state.access);

  //const [contentUpdated, setContentUpdated] = useState(false);

  const [showAlerts, setShowAlerts] = useState(true);
  const [valueUnidadAcademica, setValueUnidadAcademica] = useState('el Nivel Educativo');

  const [isCareer, setIsCareer] = useState(false);

  const [addEducationLevel, { isLoading, isError, error, isSuccess }] =
    useAddEducationLevelMutation();

  const { data: offersAvailables = [], isLoading: isLoadingOffers } = useGetAvailablesOffersQuery({
    profileId: currentProfile?.profileId!,
    off_description: 'oferta_niv',
  });

  const { getFieldProps, errors, touched, handleSubmit, setFieldValue, values, resetForm } =
    useFormik({
      initialValues: {
        edu_name: '',
        edu_alias: '',
        principal_id: '',
        offer_id: -1,
        status_id: 1,
        checked: false,
        show_in_admissions: false,
      },
      validationSchema: ValidationSchema,
      onSubmit: async (values) => {
        setIsCareer(values.checked);
        await addEducationLevel({
          profileId: currentProfile?.profileId!,
          educationLevelPayload: {
            edu_name: values.edu_name,
            edu_alias: values.edu_alias,
            principal_id: values.checked ? values.principal_id : null,
            offer_id: values.offer_id.toString(),
            status_id: values.status_id.toString(),
            show_in_admissions: values.show_in_admissions ? 1 : 0,
          },
        });

        setShowAlerts(true);
      },
    });

  const checked = values.checked;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, field: string = 'checked') => {
    setFieldValue(field, event.target.checked);
    if (checked && field === 'checked') {
      setFieldValue('principal_id', '');
    }
  };

  useEffect(() => {
    if (checked) {
      setValueUnidadAcademica('de carrera / programa');
    } else {
      setValueUnidadAcademica('de Unidad Académica');
    }
  }, [checked]);

  const {
    data: offerInfo,
    isLoading: isLoadingOfferInfo,
    // isSuccess: isSuccessInfo,
    isFetching: isFetchingInfo,
    refetch: refetchInfo,
  } = useGetInfoOfferQuery(
    {
      profileId: currentProfile?.profileId!,
      offerId: values.offer_id!.toString(),
      filter: true,
    },
    { skip: values.offer_id === -1 || !checked }
  );

  useEffect(() => {
    if (isSuccess) {
      refetchInfo();
      resetForm();
    }
  }, [isSuccess, refetchInfo, resetForm]);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container gap={2}>
        <Grid item xs={12}>
          {isError && showAlerts && (
            <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
          )}

          {isSuccess && showAlerts && (
            <SuccessAlert
              message={
                isCareer
                  ? 'La carrera / programa se ha agregado correctamente'
                  : 'La unidad académica se ha agregado correctamente'
              }
              // handleDismiss={() => setShowAlerts(false)}
              timeoutCallback={{
                timer: 3,
                callbackFn: () => {
                  history.push('/administrar-unidades-academicas');
                },
              }}
            />
          )}
        </Grid>

        <Grid item container spacing={2} alignItems="center">
          <Grid item xs={12} md={8}>
            <InputLabel className={classes.inputLabel}>Nivel educativo</InputLabel>
            {isLoadingOffers ? (
              <>
                <TextField
                  disabled
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <CircularProgress size={25} />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Cargando datos ..."
                />
              </>
            ) : (
              <>
                <Autocomplete
                  options={offersAvailables}
                  loading={isLoadingOffers}
                  loadingText="Cargando niveles educativos disponibles..."
                  getOptionLabel={(option) => option.off_name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(errors.offer_id && touched.offer_id)}
                      placeholder="Seleccione un nivel educativo"
                    />
                  )}
                  onChange={(e, value) => {
                    if (value?.off_description !== 'oferta_niv')
                      setFieldValue('offer_id', value?.id || '');
                    setFieldValue('principal_id', '');
                  }}
                  value={offersAvailables.find((offer) => offer.id === values.offer_id) || null}
                  fullWidth
                  clearIcon={null}
                />
                {errors.offer_id && touched.offer_id && (
                  <FormHelperText error>{errors.offer_id}</FormHelperText>
                )}
              </>
            )}
          </Grid>
          <Grid item xs={12} md={4}>
            <InputLabel className={classes.inputLabel}>Agregar una carrera</InputLabel>

            <Switch
              checked={checked}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </Grid>

          {checked && (
            <Grid item xs={12}>
              {isLoadingOfferInfo || isFetchingInfo ? (
                <>
                  <InputLabel className={classes.inputLabel}>Unidad Académica</InputLabel>
                  <TextField
                    disabled
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <CircularProgress size={25} />
                        </InputAdornment>
                      ),
                    }}
                    placeholder="Cargando datos ..."
                  />
                </>
              ) : (
                <>
                  <InputLabel className={classes.inputLabel}>Unidad Académica</InputLabel>
                  <Autocomplete
                    options={offerInfo || []}
                    loading={isLoadingOfferInfo}
                    getOptionSelected={(option, value) => option.id === value.id}
                    // value={offerInfo?.find((e) => e.id.toString() === values?.principal_id) || null}
                    loadingText="Cargando unidades académicas disponibles..."
                    getOptionLabel={(option) => option.edu_name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={Boolean(errors.principal_id && touched.principal_id)}
                      />
                    )}
                    onChange={(e, value) => setFieldValue('principal_id', value?.id || '')}
                    fullWidth
                    clearIcon={null}
                  />
                </>
              )}
              {errors.principal_id && touched.principal_id && (
                <FormHelperText error>{errors.principal_id}</FormHelperText>
              )}
            </Grid>
          )}

          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}> Nombre {valueUnidadAcademica}</InputLabel>
            <TextField
              fullWidth
              error={Boolean(errors.edu_name && touched.edu_name)}
              placeholder={`Ingrese un nombre ${valueUnidadAcademica}`}
              {...getFieldProps('edu_name')}
            />
            {errors.edu_name && touched.edu_name && (
              <FormHelperText error>{errors.edu_name}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>Alias {valueUnidadAcademica}</InputLabel>
            <TextField
              fullWidth
              error={Boolean(errors.edu_alias && touched.edu_alias)}
              placeholder={`Ingrese un alias ${valueUnidadAcademica}`}
              {...getFieldProps('edu_alias')}
            />
            {errors.edu_alias && touched.edu_alias && (
              <FormHelperText error>{errors.edu_alias}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>
              Mostar disponibilidad de carrera en el sistema de admisiones
            </InputLabel>

            <Switch
              checked={!!values.show_in_admissions}
              onChange={(e) => handleChange(e, 'show_in_admissions')}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </Grid>

          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>Estado</InputLabel>
            <Autocomplete
              options={statusAvailables}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField {...params} error={Boolean(errors.status_id && touched.status_id)} />
              )}
              defaultValue={statusAvailables[0]}
              onChange={(e, value) => setFieldValue('status_id', value?.id || '')}
              fullWidth
              clearIcon={null}
            />
            {errors.status_id && touched.status_id && (
              <FormHelperText error>{errors.status_id}</FormHelperText>
            )}
          </Grid>
        </Grid>
        <Grid
          item
          container
          alignItems="center"
          justifyContent="flex-end"
          spacing={2}
          sx={{ mt: 1 }}
        >
          <Grid item>
            <Button
              variant="outlined"
              size="large"
              color="primary"
              onClick={() => {
                if (handleClose) {
                  return handleClose();
                }

                return history.goBack();
              }}
            >
              Regresar
            </Button>
          </Grid>
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              disabled={isLoading}
              endIcon={isLoading && <CircularProgress size={20} />}
            >
              Agregar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default AddEducationLevelForm;
