import {
  Button,
  CircularProgress,
  Dialog,
  DialogProps,
  FormHelperText,
  Grid,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { IconBook, IconCircleCheck, IconFileAlert } from '@tabler/icons';
import { useFormik } from 'formik';
import { useAppSelector } from '../../../../../hooks/useRedux';

import InputLabel from '../../../../shared/components/forms/InputLabel';
import { MatterMesh } from '../../../meshs/interfaces/curriculum.interfaces';
import { AddNewSubjectHomologationConfirm } from '../../constants/validationSchemas';
import { useAddSubInstHomologationMutation } from '../../slices/homologationsApiSlice';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  paper: { maxWidth: '600px', width: '90%' },
}));

interface Props extends DialogProps {
  data: { instSubject: MatterMesh; subject: MatterMesh };

  onClose: () => void;
}

const ConfirmSubjectHomologation = ({ onClose, ...props }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    data: { instSubject, subject },
  } = props;

  const theme = useTheme();

  const { currentProfile } = useAppSelector((state) => state.access);

  //   const [showAlerts, setShowAlerts] = useState(true);

  const [addSubjectHomologation, { isLoading, isError, error, isSuccess }] =
    useAddSubInstHomologationMutation();

  const { getFieldProps, errors, touched, handleSubmit, values } = useFormik({
    initialValues: {
      instSubjectId: instSubject.id,
      subjectId: subject.matter_id,
      relationPct: 0,
      comment: '',
      statusId: 1,
    },
    validationSchema: AddNewSubjectHomologationConfirm,
    onSubmit: async (values) => {
      await addSubjectHomologation({
        profileId: currentProfile?.profileId!,
        subjectHomologationPayload: {
          external_homologation: [
            {
              inst_subject_id: values.instSubjectId,
              subject_id: Number(values.subjectId),
              relation_pct: values.relationPct,
              comments: values.comment,
              status_id: values.statusId,
            },
          ],
        },
      });
    },
  });

  //crear dialog jsx
  return (
    <Dialog {...props} classes={{ paper: classes.paper }} sx={{ p: 3 }} onClose={onClose}>
      <Grid container gap={4} sx={{ p: 2 }}>
        <Grid item container xs={12} alignItems="center" justifyContent="center">
          {!isError && !isSuccess && !isLoading && (
            <IconBook
              size={60}
              color={theme.palette.primary.main}
              style={{
                padding: 12,
                backgroundColor: theme.palette.primary.light,
                borderRadius: '50%',
              }}
            />
          )}
          {isLoading && <CircularProgress size={50} />}
          {isSuccess && (
            <IconCircleCheck
              size={60}
              color={theme.palette.success.main}
              style={{
                padding: 12,
                backgroundColor: theme.palette.success.light,
                borderRadius: '50%',
              }}
            />
          )}
          {isError && (
            <IconFileAlert
              size={60}
              color={theme.palette.error.main}
              style={{
                padding: 12,
                backgroundColor: theme.palette.orange.light,
                borderRadius: '50%',
              }}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <form onSubmit={handleSubmit}>
            <Grid item xs={12} textAlign={!isError && !isSuccess ? 'start' : 'center'}>
              <Typography
                variant="h2"
                align="center"
                sx={{ color: theme.palette.primary.main, mb: 1 }}
              >
                {!isError && !isSuccess && 'Homologar Materias'}
                {isSuccess && 'Materias Homologadas con exito'}
                {isError && '¡Ha ocurrido un error!'}
              </Typography>
              {!isError && !isSuccess && (
                <>
                  <Grid item container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                      <InputLabel>
                        {t(
                          'academic.homologation.external.edit.confirm_subject.relation_percentage'
                        )}
                      </InputLabel>
                      <TextField
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        type="number"
                        InputProps={{ inputProps: { min: 0, max: 100 } }}
                        {...getFieldProps('relationPct')}
                      />
                      {errors.relationPct && touched.relationPct && (
                        <FormHelperText error>{t(errors.relationPct)}</FormHelperText>
                      )}
                    </Grid>

                    <Grid item xs={12}>
                      <InputLabel>
                        {t('academic.homologation.external.edit.confirm_subject.comment')}
                      </InputLabel>
                      <TextField
                        fullWidth
                        multiline
                        rows={3}
                        InputProps={{ inputProps: { maxLength: 500 } }}
                        error={Boolean(errors.comment && touched.comment)}
                        placeholder={t(
                          'academic.homologation.external.edit.confirm_subject.comment.placeholder'
                        )}
                        {...getFieldProps('comment')}
                      />
                      <FormHelperText sx={{ textAlign: 'right' }}>
                        {values.comment.length} / 500
                      </FormHelperText>
                      {errors.comment && touched.comment && (
                        <FormHelperText error>{t(errors.comment)}</FormHelperText>
                      )}
                    </Grid>
                  </Grid>
                </>
              )}
              {isError && error}
            </Grid>
            <Grid item container xs={12} justifyContent="center" gap={1}>
              {!isSuccess && !isError && (
                <Button
                  variant="text"
                  size="large"
                  color="primary"
                  onClick={onClose}
                  disabled={isLoading}
                >
                  {t('academic.homologation.external.edit.confirm_subject.cancel')}
                </Button>
              )}

              {(isSuccess || isError) && (
                <Button variant="contained" size="large" color="primary" onClick={onClose}>
                  {t('academic.homologation.external.edit.confirm_subject.continue')}
                </Button>
              )}

              {!isSuccess && !isError && (
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  color="primary"
                  autoFocus
                  disabled={isLoading}
                >
                  {t('academic.homologation.external.edit.confirm_subject.submit')}
                </Button>
              )}
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default ConfirmSubjectHomologation;
