import { Grid, IconButton, Stack, Typography, useTheme } from '@material-ui/core';
import { CourseAvailable } from '../../interfaces/coursesAvailables.interfaces';
import { makeStyles } from '@material-ui/core/styles';
import { IconBuilding, IconSchool, IconTrash } from '@tabler/icons';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import { setCarSubjectsList } from '../../slices/subjectsRegisterSlice';

const useStyles = makeStyles((theme) => ({
  boxContainer: {
    boxShadow: '0px 4px 20px 15px rgba(234, 240, 246, 0.5);',
    borderRadius: 10,
    padding: '20px',
  },
}));

interface Props {
  course: CourseAvailable;
}

const SubjectOnlineCard = ({ course }: Props) => {
  const dispatch = useAppDispatch();
  const { carSubjects } = useAppSelector((state) => state.subjectsRegister);

  const classes = useStyles();
  const theme = useTheme();

  const handleDelete = (course: CourseAvailable) => {
    let listCourses = [...carSubjects];
    const i = listCourses.indexOf(course);
    listCourses.splice(i, 1);
    dispatch(setCarSubjectsList(listCourses));
  };

  return (
    <Grid container className={classes.boxContainer}>
      <Grid item xs={12} sx={{ mb: 2 }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h3" sx={{ color: 'primary.main', mb: 1 }}>
              {course?.matter?.mat_name}
            </Typography>
          </Grid>
          <IconButton onClick={() => handleDelete(course)}>
            <IconTrash size={20} color={theme.palette.error.main} />
          </IconButton>
        </Stack>
      </Grid>
      <Grid item xs={12} container>
        {course.collaborator && (
          <Grid item xs={6}>
            <Stack direction="row" spacing={1.5} alignItems="center">
              <IconSchool color={theme.palette.grey[700]} size={20} />
              <Stack spacing={0.5}>
                <Typography sx={{ fontWeight: 600 }}>Docente</Typography>
                <Typography>
                  {course.collaborator
                    ? `${course.collaborator?.user?.person?.pers_first_lastname} ${course.collaborator?.user?.person?.pers_second_lastname} ${course.collaborator?.user?.person?.pers_firstname}`
                    : 'No hay docente'}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
        )}
        <Grid item xs={6}>
          <Stack direction="row" spacing={1.5} alignItems="center">
            <IconBuilding color={theme.palette.grey[700]} size={20} />
            <Stack spacing={0.5}>
              <Typography sx={{ fontWeight: 600 }}>Campus</Typography>
              <Typography>{course?.classroom?.campus?.cam_name}</Typography>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SubjectOnlineCard;
