import {
  IconUser,
  IconUsers,
  IconLock,
  IconBuilding,
  IconSchool,
  IconCalendarEvent,
  IconNotebook,
  IconBook,
  IconBuildingBank,
  IconStairs,
  IconNotes,
  IconFileCertificate,
  IconCalendarTime,
  IconId,
  IconHelp,
  IconSettings,
  IconExternalLink,
} from '@tabler/icons';
import MeetingRoomOutlinedIcon from '@material-ui/icons/MeetingRoomOutlined';
import { UserCircle, ClockAfternoon, UsersThree, ChalkboardSimple, BookOpen } from 'phosphor-react';
import { shared } from '../shared';

// Icon Group
const icons = {
  IconUser,
  IconUsers,
  IconLock,
  IconBuilding,
  UserCircle,
  IconSchool,
  ClockAfternoon,
  IconCalendarEvent,
  IconNotebook,
  MeetingRoomOutlinedIcon,
  UsersThree,
  IconBook,
  IconBuildingBank,
  IconStairs,
  IconNotes,
  ChalkboardSimple,
  BookOpen,
  IconHelp,
  IconFileCertificate,
  IconCalendarTime,
  IconId,
  IconSettings,
  IconExternalLink,
};

export const configuration = {
  id: 'configuration',
  type: 'group',
  title: 'Configuración',
  children: [
    {
      id: 'accesos',
      title: 'Accesos',
      menuId: 'configuration.menu.access',
      type: 'collapse',
      icon: icons['IconLock'],
      children: [
        {
          id: 'administrar-usuarios',
          title: 'Usuarios',
          menuId: 'configuration.menu.access.users',
          type: 'item',
          url: '/administrar-usuarios',
          permissionKey: 'user-config', //users esta en otro modulo
        },
        {
          id: 'administrar-roles',
          title: 'Roles',
          menuId: 'configuration.menu.access.roles',
          type: 'item',
          url: '/administrar-roles',
          permissionKey: 'roles',
        },
        {
          id: 'administrar-perfiles',
          title: 'Perfiles',
          menuId: 'configuration.menu.access.profiles',
          type: 'item',
          url: '/administrar-perfiles',
          permissionKey: 'profiles',
        },
        {
          id: 'administrar-permisos',
          title: 'Permisos',
          menuId: 'configuration.menu.access.permissions',
          type: 'item',
          url: '/administrar-permisos',
          permissionKey: 'permissions',
        },
      ],
      // shared menu
    },

    {
      id: 'configuracion-companias',
      title: 'Configuración',
      menuId: 'Configuración',
      type: 'collapse',
      icon: icons['IconSettings'],
      children: [
        {
          id: 'administrar-companias',
          title: 'Compañía',
          menuId: 'Compañía',
          type: 'item',
          url: '/administrar-companias',
          permissionKey: 'companies',
        },
        {
          id: 'parametros-companias',
          title: 'Parámetros de Compañías',
          menuId: 'Parámetros',
          type: 'item',
          url: '/parametros-companias',
          permissionKey: 'companies',
        },
        {
          id: 'external-links',
          title: 'configuration.menu.external_links',
          menuId: 'Externos',
          type: 'item',
          url: '/administrar-enlaces-externos',
          permissionKey: 'permissions',
        },
        {
          id: 'administrar-preguntas-frecuentes',
          title: 'Preguntas frecuentes',
          menuId: 'configuration.menu.faqs',
          type: 'item',
          url: '/administrar-preguntas-frecuentes',
          permissionKey: 'permissions',
        },
      ],
    },
    // {
    //   id: 'organizacional',
    //   title: 'Organizacional',
    //   type: 'collapse',
    //   icon: icons['IconBuilding'],
    //   children: [
    //     {
    //       id: 'administrar-companias',
    //       title: 'Compañías',
    //       type: 'item',
    //       url: '/administrar-companias',
    //       permissionKey: 'companies',
    //     },
    //     {
    //       id: 'administrar-institutos',
    //       title: 'Institutos',
    //       type: 'item',
    //       url: '/administrar-institutos',
    //       permissionKey: 'institutes',
    //     },
    //     {
    //       id: 'administrar-paralelos',
    //       title: 'Paralelos',
    //       type: 'item',
    //       url: '/administrar-paralelos',
    //       permissionKey: 'parallels',
    //     },
    //     {
    //       id: 'administrar-aulas',
    //       title: 'Aulas',
    //       type: 'item',
    //       url: '/administrar-aulas',
    //       permissionKey: 'classrooms',
    //     },
    //     {
    //       id: 'administrar-campus',
    //       title: 'Campus',
    //       type: 'item',
    //       url: '/administrar-campus',
    //       permissionKey: 'campus',
    //     },
    //     {
    //       id: 'administrar-convenios',
    //       title: 'Convenios',
    //       type: 'item',
    //       url: '/administrar-convenios',
    //       permissionKey: 'agreement',
    //     },
    //     {
    //       id: 'administrar-departamentos',
    //       title: 'Departamentos',
    //       type: 'item',
    //       url: '/administrar-departamentos',
    //       permissionKey: 'department',
    //     },
    //     {
    //       id: 'administrar-cargos',
    //       title: 'Cargos',
    //       type: 'item',
    //       url: '/administrar-cargos',
    //       permissionKey: 'position',
    //     },
    //   ],
    // },
    // {
    //   id: 'periodos',
    //   title: 'Periodos',
    //   type: 'collapse',
    //   icon: icons['IconCalendarEvent'],
    //   children: [
    //     {
    //       id: 'administrar-tipos-periodos',
    //       title: 'Tipos de Periodos',
    //       type: 'item',
    //       url: '/administrar-tipos-periodos',
    //       permissionKey: 'typePeriods',
    //     },
    //     {
    //       id: 'administrar-periodos',
    //       title: 'Listado de Periodos',
    //       type: 'item',
    //       url: '/administrar-periodos',
    //       permissionKey: 'periods',
    //     },
    //   ],
    // },
    // {
    //   id: 'materias',
    //   title: 'Materias',
    //   type: 'collapse',
    //   icon: icons['BookOpen'],
    //   children: [
    //     {
    //       id: 'administrar-tipos-materias',
    //       title: 'Tipos de Materias',
    //       type: 'item',
    //       url: '/administrar-tipos-materias',
    //       permissionKey: 'typeMatters',
    //     },
    //     {
    //       id: 'administrar-materias',
    //       title: 'Materias',
    //       type: 'item',
    //       url: '/administrar-materias',
    //       permissionKey: 'matters',
    //     },
    //     {
    //       id: 'administrar-grupo-de-areas',
    //       title: 'Nivelación',
    //       type: 'item',
    //       url: '/administrar-grupo-de-areas',
    //       permissionKey: 'areaGroup',
    //     },
    //     // {
    //     //   id: 'administrar-carreras-y-materias-de-nivelacion',
    //     //   title: 'Grupos y Materias de Nivelación',
    //     //   type: 'item',
    //     //   url: '/administrar-carreras-y-materias-de-nivelacion',
    //     //   permissionKey: 'educationLevelSubject',
    //     // },
    //   ],
    // },
    // {
    //   id: 'academico',
    //   title: 'Académico',
    //   type: 'collapse',
    //   icon: icons['ChalkboardSimple'],
    //   children: [
    //     {
    //       id: 'administrar-ofertas-academicas',
    //       title: 'Unidades Académicas',
    //       type: 'item',
    //       url: '/administrar-ofertas-academicas',
    //       permissionKey: 'offers',
    //     },
    //     {
    //       id: 'administrar-mallas',
    //       title: 'Mallas',
    //       type: 'item',
    //       url: '/mallas-curriculares',
    //       permissionKey: 'meshs',
    //       breadcrumbs: false,
    //     },
    //     /*
    //     No se utilizo porque el pensum es equivalente al año simplemente
    //     {
    //       id: 'administrar-pensums',
    //       title: 'Pensums',
    //       type: 'item',
    //       url: '/administrar-pensums',
    //       permissionKey: 'pensums',
    //     },*/
    //     {
    //       id: 'administrar-niveles-educativos',
    //       title: 'Niveles Educativos',
    //       type: 'item',
    //       url: '/administrar-niveles-educativos',
    //       permissionKey: 'educationLevel',
    //     },
    //     {
    //       id: 'administrar-horarios',
    //       title: 'Horarios',
    //       type: 'item',
    //       url: '/administrar-horarios',
    //       permissionKey: 'hourhand',
    //     },
    //     {
    //       id: 'administrar-modelo-calificacion',
    //       title: 'Modelo de Calificación',
    //       type: 'item',
    //       url: '/administrar-modelo-calificacion',
    //       permissionKey: 'calificationModel',
    //     },
    //     {
    //       id: 'administrar-estudiantes',
    //       title: 'Estudiantes',
    //       type: 'item',
    //       url: '/administrar-estudiantes',
    //       permissionKey: 'student',
    //     },
    //     {
    //       id: 'administrar-distribucion-aulas',
    //       title: 'distribución de aulas',
    //       type: 'item',
    //       url: '/administrar-distribucion-aulas',
    //       permissionKey: 'classroomEducationLevel',
    //     },
    //     {
    //       id: 'administrar-cursos',
    //       title: 'Cursos',
    //       type: 'item',
    //       url: '/administrar-cursos',
    //       permissionKey: 'course',
    //     },
    //   ],
    // },
    // {
    //   id: 'configuraciones',
    //   title: 'Configuraciones',
    //   type: 'collapse',
    //   icon: icons['IconSettings'],
    //   children: [
    //     {
    //       id: 'cambiar-clave',
    //       title: 'Cambiar contraseña',
    //       type: 'item',
    //       url: '/configuraciones/cambiar-clave',
    //       permissionKey: 'config-cambiar-clave',
    //     },
    //   ],
    // },
    ...shared,
  ],
};
