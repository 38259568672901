import DeleteAlert from '../../../shared/components/dialogs/DeleteAlert';
import { useDeleteUserAreaMutation } from '../slices/areasApiSlice';
import { useTranslation } from 'react-i18next';
// import { resetRoleIdToDelete, setDeleteModalOpen } from '../slices/rolesSlice';

interface Props {
  openModal: boolean;
  areaId: number;
  handleCloseModal: () => void;
}

const DeleteAreaDialog = ({ openModal, areaId, handleCloseModal }: Props) => {
  const { t } = useTranslation();
  const [deleteArea, { isLoading, isSuccess, isError, error }] = useDeleteUserAreaMutation();

  const handleDelete = () => {
    deleteArea({ areaId });
  };

  const handleClose = () => {
    handleCloseModal();
  };
  //

  return (
    <DeleteAlert
      open={openModal}
      onClose={handleClose}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      handleDelete={handleDelete}
      deleteTitle={t('academic.areas.delete.title')}
      error={error as string}
    />
  );
};

export default DeleteAreaDialog;
