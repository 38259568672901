import { useState, useEffect } from 'react';
import { useHistory, Link, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAppSelector, useAppDispatch } from '../../../../hooks/useRedux';
import {
  useGetAvailablesFinancialPeriodsQuery,
  useGetFinancialPeriodsQuery,
} from '../slices/financialPeriodsApiSlice';

// import StatusChip from '../../../shared/components/badges/StatusChip';
import EditButton from '../../../shared/components/tables/buttons/EditButton';
import DeleteButton from '../../../shared/components/tables/buttons/DeleteButton';
import AddButton from '../../../shared/components/tables/buttons/AddButton';

import {
  openDeleteModalWith,
  setFinancialPeriodList,
  setFinancialPeriodsTable,
} from '../slices/financialPeriodsSlice';

import useGuard from '../hooks/useGuard';
import SearchPaginatedTable, {
  FilterArrow,
} from '../../../shared/components/tables/SearchPaginatedTable';
import { useTableSearch } from '../../../../hooks/useTableSearch';

const FinancialPeriodTable = () => {
  const { t } = useTranslation();

  const { currentProfile } = useAppSelector((state) => state.access);
  const { page, perPage, search } = useAppSelector(
    (state) => state.financialPeriods.financialPeriodsTable
  );

  const dispatch = useAppDispatch();

  const { path } = useRouteMatch();
  const history = useHistory();

  const { setPage, setPerPage, setSearch } = useTableSearch({
    page,
    perPage,
    search,
    updateFunction: setFinancialPeriodsTable,
  });

  const [filterArrow, setFilterArrow] = useState<FilterArrow>(null!);

  const { isLoading, error, data, isFetching } = useGetFinancialPeriodsQuery(
    {
      profileId: currentProfile?.profileId!,
      page,
      perPage,
      search,
      ...(filterArrow && {
        sort: filterArrow.name,
        type_sort: filterArrow.direction,
      }),
    },
    { refetchOnMountOrArgChange: 30 }
  );

  //
  //cargar en un estado la vista actual de la tabla
  useEffect(() => {
    if (data) {
      dispatch(setFinancialPeriodList(data.data));
    }
  }, [data, dispatch]);

  const handleDelete = async (financialPeriodId: number) => {
    dispatch(openDeleteModalWith(financialPeriodId));
  };

  const { hasAccess } = useGuard();

  const goToAdd = () => {
    history.push(`${path}/agregar`);
  };

  const { data: financialPeriods = [], isFetching: isFetchingFP } =
    useGetAvailablesFinancialPeriodsQuery({
      profileId: currentProfile?.profileId!,
    });

  const datafetch = data?.data!.map((fp, index) => ({
    id: index + (page - 1) * perPage + 1,
    name: fp.fp_description,
    principalId: fp.fp_parent,
    startDate: fp.fp_start_date,
    endDate: fp.fp_end_date,
    // statusId: fp.status_id,
    options: fp,
  }));

  return (
    <SearchPaginatedTable
      data={datafetch!}
      headers={{
        id: 'N°',
        name: t('financial.periods.table.description'),
        principalId: t('financial.periods.table.principal'),
        startDate: t('financial.periods.table.start_date'),
        endDate: t('financial.periods.table.end_date'),
        // statusId: 'Estado',
        options: t('shared.search_paginated_table.table.options'),
      }}
      listFilterArrows={{
        name: 'fp_description',
        principalId: 'fp_parent',
        startDate: 'fp_start_date',
        endDate: 'fp_end_date',
      }}
      filterArrow={filterArrow}
      setFilterArrow={setFilterArrow}
      keyExtractor={(item) => item.id.toString()}
      searchPlacehoder={t('financial.periods.table.search_placeholder')}
      isLoading={isLoading}
      isFetching={isFetching}
      error={error}
      perPage={perPage}
      setPerPage={setPerPage}
      total={data?.total || 0}
      page={page}
      setPage={setPage}
      numHeader={5}
      search={search}
      setSearch={setSearch}
      customRenderers={{
        principalId: (row) =>
          isFetchingFP ? (
            <h4>{t('financial.periods.table.principal_loading')}</h4>
          ) : (
            financialPeriods.map((fp) => {
              if (fp.id.toString() === row.principalId) {
                return fp.fp_description;
              }
              return null;
            })
          ),

        // statusId: (row) => <StatusChip statusId={Number(row.statusId)} />,
        options: (row) => (
          <>
            {hasAccess('edit') && (
              <Link to={`${path}/${row.options.id}/editar`}>
                <EditButton />
              </Link>
            )}
            {hasAccess('delete') && (
              <DeleteButton handleDelete={() => handleDelete(row.options.id)} />
            )}
            {/* {row.principalId === null && hasAccess('edit') && (
              <ListCollective onClick={() => listAllCollectives(row.id)} />
            )} */}
            {!hasAccess('edit') &&
              !hasAccess('delete') &&
              t('shared.search_paginated_table.table.options.no_options')}
          </>
        ),
      }}
      customHeadersCellsProperties={{
        options: {
          align: 'left',
        },
      }}
      customDataCellsProperties={{
        id: { align: 'center' },
        name: { align: 'center' },
        principalId: { align: 'center' },
        startDate: { align: 'center' },
        endDate: { align: 'center' },
        options: {
          align: 'center',
        },
      }}
      ActionButtons={<>{hasAccess('create') && <AddButton size="large" onClick={goToAdd} />}</>}
    />
  );
};

export default FinancialPeriodTable;
