import {
  // Avatar,
  Card,
  CardContent,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  Button,
  Skeleton,
  Autocomplete,
  TextField,
  InputLabel,
} from '@material-ui/core';
import { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import { useGetListForumByCourseQuery } from '../../../../teachers/virtual-classroom/slices/virtualClassroomTeacherApiSlice';
// import { obtainDomainStorageImage } from '../../../../shared/helpers';
import { IconBulb, IconMessage, IconPencil } from '@tabler/icons';
import { Forum } from '../../../../teachers/virtual-classroom/interfaces/forums.interfaces';
import { useHistory, useRouteMatch } from 'react-router-dom';
// import { setForumToManage } from '../../../../students/virtual-classroom/slices/virtualClassroomSlice';
// import { IconMessages, IconPencil } from '@tabler/icons';
import { IconMessages } from '@tabler/icons';
import { Plus } from 'phosphor-react';
// import PerfectScrollbar from 'react-perfect-scrollbar';
// import { es } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';
import DeleteButton from '../../../../shared/components/tables/buttons/DeleteButton';
import {
  setForumDeleteDialogOpen,
  setForumIdToDelete,
} from '../../slices/virtualClassroomTeacherSlice';
import { useGetSupplyCourseQuery } from '../../evaluations/slices/evaluationApiSlice';
import { virtualClassActivitiesStatus } from '../../../../shared/constants/resourceStatus';
import useFormatDate from '../../../../shared/hooks/useFormatDate';
// import DeleteButton from '../../../../shared/components/tables/buttons/DeleteButton';

const useStyles = makeStyles((theme) => ({
  container: {
    border: '1px solid red',
    padding: '5px',
  },
  card: {
    border: `2px solid ${theme.palette.grey[50]}`,
    // width: 'maxcontent',
    textAlign: 'left',
    cursor: 'pointer',
    marginTop: 10,
    boxShadow: '0px 0px 20px #BFDAF9',
    margin: 3,
    height: 'maxcontent',
  },
  cardContent: {
    marginTop: '15px',
    paddingTop: '0 !important',
  },
  cardHeader: {
    paddingBottom: '2 !important',
  },
}));

interface Props {
  goToForumView: (data: boolean) => void;
  setForumId: React.Dispatch<React.SetStateAction<number | undefined>>;
}
const ForumInfiniteList = ({ goToForumView, setForumId }: Props) => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();

  const { formatDate } = useFormatDate();

  // const dispatch = useAppDispatch();
  const { path } = useRouteMatch();
  const history = useHistory();

  const { currentProfile } = useAppSelector((state) => state.access);
  const { course, isFinishedCourse: IsFinishedCourse } = useAppSelector(
    (state) => state.virtualClassroomTeacherShared
  );

  const [limit, setLimit] = useState<number>(4);
  // const [offset, setOffset] = useState<number>(0);

  const [supply, setSupply] = useState<number | null>(null);
  const [status, setStatus] = useState<string | null>('en-proceso');

  const { data, isSuccess /*isFetching, error*/ } = useGetListForumByCourseQuery(
    {
      profileId: currentProfile?.profileId!,
      courseId: course?.courseId! + '',
      page: 1,
      perPage: 100,
      search: '',
      ...(supply && { supply }),
      ...(status && { estado: status }),
    },
    { refetchOnMountOrArgChange: 30 }
  );

  const fetchMoreData = () => {
    // setOffset(data?.data?.length!);
    setLimit(limit + 3);
  };

  // const goToForumView = (foroId: String) => {
  //   dispatch(setForumToManage(Number(foroId)));
  //   history.push(`${path}/${foroId}/view`);
  // };

  const goToAddForumView = () => {
    history.push(`${path}/agregar`);
  };

  const { data: supplies, isLoading: isLoadingSupp } = useGetSupplyCourseQuery({
    course: course.courseId!,
  });

  const handleDelete = (forumId: number) => {
    dispatch(setForumIdToDelete(forumId));
    dispatch(setForumDeleteDialogOpen(true));
  };

  const renderTime = ({
    id,
    act_description,
    course_activity_one: { cact_start_date },
    answers_activity_count,
    act_is_score,
    detail_forum: { dta_question },
    author: {
      person: { pers_firstname, pers_first_lastname, pers_secondname, pers_second_lastname },
    },
  }: Forum) => {
    return (
      <Grid item key={id} xs={12} sm={6} md={4}>
        <Grid container style={{ display: 'grid', gridTemplateColumns: '' }}>
          <Card key={id} className={classes.card}>
            <CardContent className={classes.cardContent}>
              <Grid
                container
                justifyContent={'center'}
                // onClick={() => {
                //   //goToForumView(true);
                //   history.push(`${path}/${id}/view`);
                //   //setForumId(id);
                // }}
              >
                <Grid
                  item
                  container
                  xs={12}
                  onClick={() => {
                    //goToForumView(true);
                    history.push(`${path}/${id}/view`);
                    //setForumId(id);
                  }}
                >
                  <Grid item xs={4}>
                    <Grid
                      item
                      container
                      justifyContent={'center'}
                      alignItems={'center'}
                      sx={{
                        borderRadius: '50%',
                        height: '60px',
                        width: '60px',
                        backgroundColor: theme.palette.grey[200],
                      }}
                    >
                      <IconMessages size={30} />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={8}
                    sx={{
                      // height: '60px',
                      overflow: 'hidden',
                      wordWrap: 'break-word',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      WebkitLineClamp: 3,
                      WebkitBoxOrient: 'vertical',
                      height: '4em',
                    }}
                  >
                    <Grid>
                      <Typography
                        variant="h5"
                        fontSize={13}
                        sx={{ color: theme.palette.grey[400] }}
                      >
                        <strong>{t('teacher_virtual_classroom.ForumInfiniteList.subject')}</strong>
                      </Typography>
                    </Grid>
                    <Grid mb={2}>
                      <Typography variant="h4">{act_description}</Typography>
                    </Grid>
                    <Grid sx={{ height: '30px' }}>
                      <Grid>
                        <Typography
                          variant="h5"
                          fontSize={13}
                          sx={{ color: theme.palette.grey[400] }}
                        >
                          {t('teacher_virtual_classroom.ForumInfiniteList.creation')}
                        </Typography>
                      </Grid>
                      <Grid mb={2}>
                        <Typography variant="subtitle2">
                          {formatDate(cact_start_date, {
                            withTime: true,
                          })}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container justifyContent={'right'} textAlign={'right'} sx={{ marginTop: 1 }}>
                  <Tooltip title={t('teacher_virtual_classroom.ForumInfiniteList.main_comments')}>
                    <IconButton
                      onClick={() => {
                        // console.log('id', id);
                        // setForumId(id);
                        // goToForumView(true);
                        history.push(`${path}/${id}/view`);
                      }}
                    >
                      <IconMessage size={20} color={theme.palette.grey[700]} />
                      <Typography variant="subtitle1">{answers_activity_count + ' '}</Typography>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t('teacher_virtual_classroom.ForumInfiniteList.is_qualified')}>
                    <IconButton onClick={() => console.log('')}>
                      <IconBulb size={20} color={theme.palette.grey[700]} />
                      <Typography variant="subtitle1">
                        {act_is_score === '1'
                          ? t('teacher_virtual_classroom.ForumInfiniteList.yes')
                          : 'No '}
                      </Typography>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t('teacher_virtual_classroom.ForumInfiniteList.edit')}>
                    <IconButton onClick={() => history.push(`${path}/${id}/editar`)}>
                      <IconPencil size={20} color={theme.palette.grey[700]} />
                    </IconButton>
                  </Tooltip>

                  <DeleteButton handleDelete={() => handleDelete(id)} />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  };

  const renderTimes = () => {
    return (
      <Grid container spacing={2} p={2}>
        {data?.data?.slice(0, limit + 1).map(renderTime)}
      </Grid>
    );
  };

  const SkeletonGrid = (cantidad: number) => {
    return [...new Array(cantidad)].map((_, index) => (
      <Grid item xs={12} sm={6} md={6} lg={4} xl={3} key={index}>
        <Skeleton sx={{ height: '250px' }} />
      </Grid>
    ));
  };

  return (
    <Grid container spacing={1}>
      <Grid container justifyContent={'space-between'}>
        <Typography variant="h2">
          {t('teacher_virtual_classroom.ForumList.forum_list_name')}
        </Typography>
        <Button
          variant="contained"
          startIcon={<Plus size={15} />}
          onClick={goToAddForumView}
          disabled={IsFinishedCourse}
        >
          {t('teacher_virtual_classroom.ForumInfiniteList.add')}
        </Button>
      </Grid>
      <Grid container spacing={2}>
        <Grid
          item
          container
          style={{ marginBottom: 20, marginTop: 10 }}
          alignItems="center"
          spacing={1}
        >
          <Grid item xs={12} md={3}>
            <InputLabel
              sx={{
                color: theme.palette.primary.main,
              }}
            >
              {t('teacherVirtualClassroom.tasks.table.input.title.insumo')}
            </InputLabel>
            <Autocomplete
              // style={{ width: '60%' }}
              options={supplies || []}
              loading={isLoadingSupp}
              loadingText={t('teacherVirtualClassroom.tests.filterparcialLoading')}
              getOptionLabel={(option) => option.name}
              onChange={(_, value) => {
                setSupply(value?.id || null);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={t('teacherVirtualClassroom.tasks.table.input.title.insumo.select')}
                />
              )}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <InputLabel
              sx={{
                color: theme.palette.primary.main,
              }}
            >
              {t('teacherVirtualClassroom.tasks.table.input.title.status')}
            </InputLabel>
            <Autocomplete
              // style={{ width: '60%' }}
              options={virtualClassActivitiesStatus}
              loadingText={t('teacherVirtualClassroom.tests.filterparcialLoading')}
              getOptionLabel={(option) => t(option.label)}
              onChange={(_, value) => {
                setStatus(value?.name || null);
              }}
              value={virtualClassActivitiesStatus?.find((item) => item.name === status) || null}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={t('teacherVirtualClassroom.tasks.table.input.title.status.select')}
                />
              )}
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          {isSuccess ? (
            <InfiniteScroll
              dataLength={data?.data?.slice(0, limit + 1).length!}
              next={fetchMoreData}
              hasMore={data?.data?.length! > limit + 1}
              loader={<Grid container>{SkeletonGrid(6)}</Grid>}
              endMessage={
                <Typography variant="h5" textAlign={'center'} mt={2}>
                  {t('teacher_virtual_classroom.ForumInfiniteList.no_more_data')}
                </Typography>
              }
            >
              <Grid item xs={12}>
                {renderTimes()}
              </Grid>
            </InfiniteScroll>
          ) : (
            SkeletonGrid(6)
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
export default ForumInfiniteList;
