import { Grid, Typography } from '@material-ui/core';
import HourPlaceholder from './HourPlaceholder';

const AcademicHourhand = function () {
  const date = new Date();

  const handleHoursHand = (): number[] => {
    const arrayHours: number[] = [];
    for (let i = 0; i < 16; i++) {
      arrayHours.push(date.setHours(7 + i, 0, 0, 0));
    }
    return arrayHours;
  };

  const convertTime = (datetime: number): number => {
    const hour = new Date(datetime);
    return hour.getHours();
  };

  const handleRows = handleHoursHand().map((h) => {
    return (
      <Grid item xs={12} container sx={{ mt: 4 }} alignItems="center" key={h}>
        <Grid item xs={1}>
          <Typography variant="h5">{`${convertTime(h)}:00 - ${convertTime(h) + 1}:00`}</Typography>
        </Grid>
        <Grid item xs={11} container spacing={2}>
          <HourPlaceholder
            day={'mo'}
            start_hour={
              convertTime(h) < 10 ? `0${convertTime(h)}:00:00` : `${convertTime(h)}:00:00`
            }
            end_hour={
              convertTime(h) < 10 ? `0${convertTime(h) + 1}:00:00` : `${convertTime(h) + 1}:00:00`
            }
          />
          <HourPlaceholder
            day={'tu'}
            start_hour={
              convertTime(h) < 10 ? `0${convertTime(h)}:00:00` : `${convertTime(h)}:00:00`
            }
            end_hour={
              convertTime(h) < 10 ? `0${convertTime(h) + 1}:00:00` : `${convertTime(h) + 1}:00:00`
            }
          />
          <HourPlaceholder
            day={'we'}
            start_hour={
              convertTime(h) < 10 ? `0${convertTime(h)}:00:00` : `${convertTime(h)}:00:00`
            }
            end_hour={
              convertTime(h) < 10 ? `0${convertTime(h) + 1}:00:00` : `${convertTime(h) + 1}:00:00`
            }
          />
          <HourPlaceholder
            day={'th'}
            start_hour={
              convertTime(h) < 10 ? `0${convertTime(h)}:00:00` : `${convertTime(h)}:00:00`
            }
            end_hour={
              convertTime(h) < 10 ? `0${convertTime(h) + 1}:00:00` : `${convertTime(h) + 1}:00:00`
            }
          />
          <HourPlaceholder
            day={'fr'}
            start_hour={
              convertTime(h) < 10 ? `0${convertTime(h)}:00:00` : `${convertTime(h)}:00:00`
            }
            end_hour={
              convertTime(h) < 10 ? `0${convertTime(h) + 1}:00:00` : `${convertTime(h) + 1}:00:00`
            }
          />
          <HourPlaceholder
            day={'sa'}
            start_hour={
              convertTime(h) < 10 ? `0${convertTime(h)}:00:00` : `${convertTime(h)}:00:00`
            }
            end_hour={
              convertTime(h) < 10 ? `0${convertTime(h) + 1}:00:00` : `${convertTime(h) + 1}:00:00`
            }
          />
        </Grid>
      </Grid>
    );
  });
  return (
    <Grid container mt={4}>
      <Grid item xs={12} container>
        <Grid item xs={1}></Grid>
        <Grid item xs={11} container>
          <Grid item xs={2}>
            <Typography variant="h4" textAlign="center">
              Lunes
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="h4" textAlign="center">
              Martes
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="h4" textAlign="center">
              Miércoles
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="h4" textAlign="center">
              Jueves
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="h4" textAlign="center">
              Viernes
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="h4" textAlign="center">
              Sábado
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      {/* Rows */}
      {handleRows}
    </Grid>
  );
};

export default AcademicHourhand;
