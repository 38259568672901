import { Grid, Typography, useTheme, makeStyles, useMediaQuery } from '@material-ui/core';
import CustomIcon from './illustrations/CustomIcon';

import telework from '../../../assets/images/illustrations/telework.svg';

export const useStyles = makeStyles((theme) => ({
  avatar: {
    width: '72px',
    height: '72px',
  },
  avatarMobile: {
    width: '50px',
    height: '50px',
  },
  title: {
    marginLeft: '5px',
  },
}));
interface NoDataProps {
  image?: string;
  title: string;
  subtitle: string;

  imgWitdh?: number;
}

const NoData = ({ image = telework, title, subtitle, imgWitdh }: NoDataProps) => {
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Grid container justifyContent="center">
      <Grid item container justifyContent="center" xs={10}>
        <CustomIcon icon={image} customWidth={imgWitdh || 400} />
      </Grid>
      <Grid item container justifyContent="center" xs={10}>
        <Typography variant={!matchDownMd ? 'h4' : 'h5'}>{title}</Typography>
      </Grid>
      <Grid item container justifyContent="center" xs={8} textAlign="center">
        <Typography variant={!matchDownMd ? 'body2' : 'h6'}>{subtitle}</Typography>
      </Grid>
    </Grid>
  );
};

export default NoData;
