import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import { useUpdateApplicationMutation } from '../../slices/homologationsApiSlice';
import { resetInternalAppIdToDelete, setConfirmModalOpen } from '../../slices/homologationsSlice';
import ConfirmAlert from '../../../../shared/components/dialogs/ConfirmAlert';
import { useEffect, useState } from 'react';
// import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ConfirmAppDialog = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { confirmModalOpen, internalAppToConfirm } = useAppSelector((state) => state.homologations);

  const { currentProfile } = useAppSelector((state) => state.access);

  const [initialized, setInitialized] = useState(false);

  const [rejectApplication, { isLoading, isSuccess, isError, error }] =
    useUpdateApplicationMutation();

  const handleConfirm = () => {
    setInitialized(true);
    rejectApplication({
      profileId: currentProfile?.profileId!,
      applicationPayload: {
        app_code: internalAppToConfirm!.code,
        comment: internalAppToConfirm!.msg,
        subjects: internalAppToConfirm!.subjects,
      },
    });
  };

  const handleClose = () => {
    dispatch(setConfirmModalOpen(false));
    dispatch(resetInternalAppIdToDelete());
    // setInitialized(false);
    // return <Redirect to="/administrar-homologaciones" />;
  };

  useEffect(() => {
    if (confirmModalOpen) setInitialized(false);
  }, [confirmModalOpen]);

  return (
    <ConfirmAlert
      open={confirmModalOpen}
      onClose={handleClose}
      isLoading={isLoading && initialized}
      isSuccess={isSuccess && initialized}
      isError={isError && initialized}
      handleDelete={handleConfirm}
      error={error as string}
      title={t('academic.homologation.internal.confirm_dialog.title')}
      subtitle={t('academic.homologation.internal.confirm_dialog.subtitle')}
      successMessage={t('academic.homologation.internal.confirm_dialog.success')}
    />
  );
};

export default ConfirmAppDialog;
