import optionsTemplate from './optionsTemplate.json';
import scopes from './scopes.json';
import evaluationType from './evaluationType.json';
import evaluations from './evaluations.json';
import questions from './questions.json';
import users from './users.json';
import groups from './groups.json';
import openingDates from './openingDates.json';
import integralEvaluations from './integralEvaluations.json';
import menu from './menu.json';
import launching from './launching.json';
import { reportsTranslations } from './reports';
import { userTranslations } from './rol';

const evaluationsTranslations = {
  ...evaluations,
  ...evaluationType,
  ...integralEvaluations,
  ...optionsTemplate,
  ...scopes,
  ...questions,
  ...users,
  ...groups,
  ...openingDates,
  ...menu,
  // user
  ...userTranslations,

  // reports
  ...reportsTranslations,
  ...launching
};

export default evaluationsTranslations;
