import { useState } from 'react';
import { Dialog, DialogContent, Grid, Typography, Button } from '@material-ui/core';
import LocalizedDatePicker from '../../../shared/components/controlled/LocalizedDatePicker';

interface Props {
  open: boolean;
  onClose: () => void;
}

const ChangesBirthday = ({ open, onClose }: Props) => {
  const [mesYear, setMesYear] = useState<Date | null>(null);

  return (
    <Dialog open={open} scroll="body" onClose={onClose} maxWidth="xs" fullWidth>
      <DialogContent>
        <Grid container spacing={4}>
          <Grid item display="flex" container xs={12} justifyContent="center">
            <Grid item display="flex" direction="column" gap={4}>
              <Typography variant="h4" color="primary" align="center">
                Cumpleaños
              </Typography>
              <Typography>Fecha actual de cumpleaños:</Typography>
              <Grid xs={12} gap={4}>
                <Grid xs={12}>
                  <LocalizedDatePicker
                    views={['year', 'day', 'month']}
                    label="Seleccione la fecha"
                    maxDate={new Date()}
                    value={mesYear}
                    onChange={(newValue) => {
                      setMesYear(newValue as Date);
                    }}
                    placeholder="Seleccione la fecha"
                  />
                </Grid>
              </Grid>
              <Grid item display="flex" justifyContent="flex-end" spacing={4}>
                <Button type="submit" variant="contained" size="large" color="primary">
                  Actualizar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ChangesBirthday;
