import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISearchTable } from '../../../shared/interfaces/paginatedTable.interface';
import { HoursSummaries } from '../interfaces/collaborator.interfaces';

interface TeachersState {
  teachersList: HoursSummaries[];
  deleteModalOpen: boolean;
  teacherIdToDelete?: number;
  teachersTable: ISearchTable;

  educationLevelIdSelected: number | null;
  periodIdSelected: number | null;
}

const initialState: TeachersState = {
  teachersList: [],
  deleteModalOpen: false,
  teachersTable: {
    page: 1,
    perPage: 5,
    search: '',
  },
  educationLevelIdSelected: null,
  periodIdSelected: null,
};

const HoursTeachersSlice = createSlice({
  name: 'teachers',
  initialState,
  reducers: {
    setTeachersList: (state, action: PayloadAction<HoursSummaries[]>) => {
      state.teachersList = action.payload;
    },
    setDeleteModalOpen: (state, action: PayloadAction<boolean>) => {
      state.deleteModalOpen = action.payload;
    },
    openDeleteModalWith: (state, action: PayloadAction<number>) => {
      state.teacherIdToDelete = action.payload;
      state.deleteModalOpen = true;
    },
    resetTeacherIdToDelete: (state) => {
      state.teacherIdToDelete = undefined;
    },
    updateTeachersTable: (state, action: PayloadAction<ISearchTable>) => {
      state.teachersTable = action.payload;
    },
    setEducationLevelIdSelected: (state, action: PayloadAction<number | null>) => {
      state.educationLevelIdSelected = action.payload;
    },
    setPeriodIdSelected: (state, action: PayloadAction<number | null>) => {
      state.periodIdSelected = action.payload;
    },
  },
});

export const {
  setTeachersList,
  setDeleteModalOpen,
  openDeleteModalWith,
  resetTeacherIdToDelete,
  updateTeachersTable,
  setEducationLevelIdSelected,
  setPeriodIdSelected,
} = HoursTeachersSlice.actions;

export default HoursTeachersSlice.reducer;
