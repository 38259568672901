import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { setEducationLevelIdSelected } from '../slices/studentsGraduateSlice';

/**
 * @returns Si puede cambiar el filtro de facultad en base a si tiene facuiltad principal
 */
const useVerificationColl = () => {
  const dispatch = useAppDispatch();

  const [blockFilter, setBlockFilter] = useState(false);

  const { collaboratorInfo } = useAppSelector((state) => state.auth.userInfo);

  useEffect(() => {
    if (collaboratorInfo && collaboratorInfo.education_level_principal_id) {
      dispatch(setEducationLevelIdSelected(+collaboratorInfo.education_level_principal_id));
      setBlockFilter(true);
    }
  }, [collaboratorInfo, dispatch]);

  return { blockFilter };
};

export default useVerificationColl;
