import { useEffect, useState } from 'react';
import { useHistory, Link, useRouteMatch } from 'react-router-dom';

// import { Chip, Grid } from '@material-ui/core';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { useGetPeriodTypesQuery } from '../slices/periodTypesApiSlice';
import { openDeleteModalWith } from '../slices/periodTypesSlice';

import StatusChip from '../../../shared/components/badges/StatusChip';
import EditButton from '../../../shared/components/tables/buttons/EditButton';
import DeleteButton from '../../../shared/components/tables/buttons/DeleteButton';
import AddButton from '../../../shared/components/tables/buttons/AddButton';

import useSharedGuard from '../../../../hooks/useSharedGuard';
import { subPermissionKeys } from '../constants/permissions';
import SearchPaginatedTable, {
  FilterArrow,
} from '../../../shared/components/tables/SearchPaginatedTable';
import { Grid, Typography } from '@material-ui/core';

const PeriodTypesTable = () => {
  const history = useHistory();
  const { path } = useRouteMatch();

  const { currentProfile } = useAppSelector((state) => state.access);
  const dispatch = useAppDispatch();

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const [search, setSearch] = useState('');

  const [filterArrow, setFilterArrow] = useState<FilterArrow>(null!);

  useEffect(() => {
    setPage(1);
  }, [perPage, search]);

  const { hasAccess } = useSharedGuard(subPermissionKeys);

  const { isLoading, error, data, isFetching } = useGetPeriodTypesQuery(
    {
      profileId: currentProfile?.profileId!,
      page,
      perPage,
      ...(filterArrow && { sort: filterArrow?.name, type_sort: filterArrow?.direction }),
      search,
    },
    { refetchOnMountOrArgChange: 30 }
  );

  const handleDelete = async (periodId: number) => {
    dispatch(openDeleteModalWith(periodId));
  };

  const goToAdd = () => {
    history.push(`${path}/agregar`);
  };

  const datafetch = data?.data!.map((d, index) => ({
    id: index + ((page - 1) * perPage + 1),
    name: d.tp_name,
    description: d.tp_description,
    detail: {
      tp_min_matter_enrollment: d.tp_min_matter_enrollment,
      tp_max_matter_enrollment: d.tp_max_matter_enrollment,
      tp_num_fees: d.tp_num_fees,
    },
    status_id: d.status_id,
    options: d,
  }));

  return (
    <SearchPaginatedTable
      data={datafetch!}
      headers={{
        id: 'N°',
        name: 'Nombre',
        description: 'Descripción',
        detail: 'Detalle',
        status_id: 'Estado',
        options: 'Opciones',
      }}
      keyExtractor={(item) => item.id.toString()}
      listFilterArrows={{
        name: 'tp_name',
        description: 'tp_description',
        status_id: 'status_id',
      }}
      filterArrow={filterArrow}
      setFilterArrow={setFilterArrow}
      searchPlacehoder="Buscar periodo"
      isLoading={isLoading}
      isFetching={isFetching}
      error={error}
      perPage={perPage}
      setPerPage={setPerPage}
      total={data?.total || 0}
      page={page}
      setPage={setPage}
      numHeader={10}
      search={search}
      setSearch={setSearch}
      customRenderers={{
        status_id: (row) => <StatusChip statusId={Number(row.status_id)} />,
        options: (row) => (
          <>
            {hasAccess('edit') && (
              <Link to={`${path}/${row.options.id}/editar`}>
                <EditButton />
              </Link>
            )}
            {hasAccess('delete') && (
              <DeleteButton handleDelete={() => handleDelete(row.options.id)} />
            )}
            {!hasAccess('edit') && !hasAccess('delete') && 'Ninguna'}
          </>
        ),
        detail: ({ detail }) => (
          <Grid container item direction="column" gap={1}>
            <Typography>Máximo materias: {detail.tp_max_matter_enrollment}</Typography>
            <Typography>Mínimo materias: {detail.tp_min_matter_enrollment}</Typography>
            <Typography>Número cuotas: {detail.tp_num_fees}</Typography>
          </Grid>
        ),
      }}
      ActionButtons={<>{hasAccess('create') && <AddButton size="large" onClick={goToAdd} />}</>}
      customDataCellsProperties={{
        id: {
          width: '5%',
          align: 'center',
        },
        detail: {
          align: 'left',
        },
        status_id: {
          align: 'center',
        },
        options: {
          align: 'center',
        },
      }}
      customHeadersCellsProperties={{
        options: {
          align: 'center',
        },
      }}
    />
  );
};

export default PeriodTypesTable;
