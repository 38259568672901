import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import MainCard from '../../../../shared/components/cards/MainCard';
import { useTranslation } from 'react-i18next';
import NotesDetails from '../components/NotesDetails';

const NoteScreen = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Grid
        component={MainCard}
        sx={{
          borderTopLeftRadius: '0px',
          borderTopRightRadius: '0px',
        }}
      >
        <Grid sx={{ mb: 3 }}>
          <Typography variant="h2">
            {t('studentVirtualClassroom.qualification.qualification')}
          </Typography>
        </Grid>

        <NotesDetails />
      </Grid>
    </React.Fragment>
  );
};

export default NoteScreen;
