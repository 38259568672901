import { Skeleton } from '@material-ui/core';
import { useState, useEffect } from 'react';
import { useAppSelector } from '../../../../../hooks/useRedux';

import { useGetCommentsQuery } from '../../slices/virtualClassroomApiSlice';
import ForoSelected from './ForoSelected';
import { Forum } from '../interfaces/forums.interfaces';

interface Props {
  goToForumView: () => void;
  forumId: number;
}
const ForumContent = (props: Props) => {
  const { forumId } = props;

  const {
    course: { courseId },
  } = useAppSelector((state) => state.virtualClassroomStudentShared);

  const CourseTeacher = useAppSelector((state) => state.virtualClassroomTeacherShared);

  const {
    data,
    isLoading: isLoadingFR,
    refetch,
  } = useGetCommentsQuery({
    courseId: CourseTeacher?.course?.courseId! === 0 ? courseId! : CourseTeacher?.course?.courseId!,
    forumId: forumId!,
  });

  const [posts, setPosts] = useState<Forum>();

  useEffect(() => {
    if (data) {
      setPosts(data);
    }
  }, [data]);

  return !isLoadingFR && posts ? (
    <>
      <ForoSelected forumCurrent={posts} refetch={refetch} />
    </>
  ) : (
    <Skeleton
      variant="rectangular"
      sx={{ width: '100%', borderRadius: 3, height: '40vh', marginTop: 2 }}
    />
  );
};
export default ForumContent;
