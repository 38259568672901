import { ThunkDispatch } from 'redux-thunk';
import { User } from '../interfaces/login.interfaces';
import { VerifyResponse } from '../interfaces/verify.interfaces';
import { setCollaboratorInfo } from '../slices/authSlice';

export const setCollaboratorInfoIfExist = (
  dispatch: ThunkDispatch<any, any, any>,
  data: VerifyResponse | User
) => {
  if (data.collaborator) {
    const { collaborator } = data;
    const { id, coll_type, education_level_principal_id, position_company_id, code_rrhh } =
      collaborator;
    dispatch(
      setCollaboratorInfo({
        code_rrhh,
        id,
        coll_type,
        education_level_principal_id,
        position_company_id,
      })
    );
  }
};

export const fixTime = (time: string) => {
  const hours = time.split(':')[0];

  if (hours.length === 1) {
    return `0${time}`;
  }
  return time;
};
