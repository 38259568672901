import { useRef, useState } from 'react';

import {
  Dialog,
  DialogProps,
  Grid,
  Button,
  Typography,
  useTheme,
  DialogTitle,
  Stack,
  TextField,
  FormHelperText,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
// import image from '../../../../assets/images/illustrations/telework.svg';
import MainCard from '../../../../shared/components/cards/MainCard';
import SuccessAlert from '../../../../shared/components/alerts/SuccessAlert';
import ErrorAlert from '../../../../shared/components/alerts/ErrorAlert';
import { useRenameProgramMutation } from '../../slices/acPlanPostgraduateApiSlice';
import { useFormik } from 'formik';
import LoadingButton from '../../../../shared/components/buttons/LoadingButton';
import * as Yup from 'yup';
import WarningAlert from '../../../../shared/components/alerts/WarningAlert';
import { useAppSelector } from '../../../../../hooks/useRedux';

const useStyles = makeStyles((theme) => ({
  paper: { maxWidth: '600px', width: '90%', padding: 0 },
  /////////////

  card: {
    // backgroundColor:
    //   theme.palette.mode === 'dark' ? theme.palette.dark.dark : theme.palette.primary.main,
    color: '#fff',
    overflow: 'hidden',
    position: 'relative',
    '&>div': {
      position: 'relative',
      zIndex: 5,
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      width: '210px',
      height: '210px',
      // background:
      //   theme.palette.mode === 'dark'
      //     ? 'linear-gradient(210.04deg, ' +
      //       theme.palette.primary.dark +
      //       ' -50.94%, rgba(144, 202, 249, 0) 95.49%)'
      //     : theme.palette.primary[200],
      borderRadius: '50%',
      zIndex: 1,
      top: '-85px',
      right: '-95px',
      [theme.breakpoints.down('xs')]: {
        top: '-105px',
        right: '-140px',
      },
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      zIndex: 1,
      width: '210px',
      height: '210px',
      // background:
      //   theme.palette.mode === 'dark'
      //     ? 'linear-gradient(140.9deg, ' +
      //       theme.palette.primary.dark +
      //       ' -14.02%, rgba(144, 202, 249, 0) 82.50%)'
      //     : theme.palette.primary[200],
      borderRadius: '50%',
      top: '-125px',
      right: '-15px',
      opacity: 0.5,
      [theme.breakpoints.down('xs')]: {
        top: '-155px',
        right: '-70px',
      },
    },
  },
  content: {
    padding: '20px !important',
  },
  avatar: {
    ...theme.typography.commonAvatar,
    ...theme.typography.largeAvatar,
    cursor: 'default',
    // margin: '8px',
    height: 'auto',
    width: 'auto',
    backgroundColor: 'transparent',
    // backgroundColor: theme.palette.secondary[200],
    // padding: '0px 15px',
  },
}));

interface Props extends DialogProps {
  onClose: () => void;
  title: string;
  programId: string;
}

const ChangeProgramRenameDialog = ({ onClose, title, programId, ...props }: Props) => {
  const classes = useStyles();
  const theme = useTheme();

  const [showAlerts, setShowAlerts] = useState(false);

  const containerRef = useRef<HTMLDivElement | null>(null);

  const [renameProgram, { isSuccess, isError, isLoading, error }] = useRenameProgramMutation();

  const { postGraduatePlanificationList } = useAppSelector(
    (state) => state.academicPlanningPosgraduate
  );

  const planning = postGraduatePlanificationList.find((p) => p.id === programId);

  console.log(planning);

  const { getFieldProps, handleSubmit, errors, touched } = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Este campo es requerido'),
    }),
    onSubmit: async (values) => {
      await renameProgram({
        id: programId,
        name: values.name,
      });
      setShowAlerts(true);
    },
  });

  console.log(setShowAlerts);

  return (
    <Dialog
      {...props}
      classes={{ paper: classes.paper }}
      sx={{ backgroundColor: 'transparent' }}
      onClose={onClose}
    >
      <DialogTitle>
        <Typography variant="h2" textAlign="center" sx={{ color: theme.palette.primary.main }}>
          {title}
        </Typography>
      </DialogTitle>
      <form onSubmit={handleSubmit}>
        <MainCard border={false} contentClass={classes.content} ref={containerRef} mb={2}>
          <Stack spacing={2} width="100%">
            <Grid item xs={12}>
              <WarningAlert message="Este nombre será utilizado como alias del programa" />
            </Grid>
            {isSuccess && showAlerts && (
              <Grid item xs={12}>
                <SuccessAlert
                  message="Se ha cambiado el nombre del programa exitosamente"
                  handleDismiss={() => setShowAlerts(false)}
                />
              </Grid>
            )}
            {isError && showAlerts && (
              <Grid item xs={12}>
                <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
              </Grid>
            )}
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item>
                  <Typography
                    // fontSize="1.1rem"
                    variant="subtitle1"
                    fontWeight="bold"
                    component="span"
                    sx={{ color: theme.palette.primary.main }}
                  >
                    Nombre del programa
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    {...getFieldProps('name')}
                    placeholder="Ingrese el nuevo nombre"
                    variant="outlined"
                    error={Boolean(touched.name && errors.name)}
                  />
                  {errors.name && touched.name && (
                    <FormHelperText error>{errors.name}</FormHelperText>
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid item container xs={12} justifyContent="center" gap={1}>
              <Button
                variant="text"
                size="large"
                color="primary"
                onClick={onClose}
                disabled={isLoading}
              >
                Cerrar
              </Button>

              <LoadingButton
                isLoading={isLoading}
                type="submit"
                variant="contained"
                color="primary"
                disabled={isLoading || isSuccess}
              >
                Guardar
              </LoadingButton>
            </Grid>
          </Stack>
        </MainCard>
      </form>
    </Dialog>
  );
};

export default ChangeProgramRenameDialog;
