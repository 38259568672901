import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  separate: {
    marginBottom: '20px',
  },
  accountTab: {
    marginBottom: '24px',
    '& a': {
      minHeight: 'auto',
      minWidth: '10px',
      padding: '12px 8px',
      marginRight: '18px',
      color: theme.palette.grey[600],
    },
    '& a.Mui-selected': {
      color: theme.palette.primary.main,
    },
    '& a > span': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '& a > span > svg': {
      marginBottom: '0px !important',
      marginRight: '10px',
    },
    '& a > span > span + svg': {
      margin: '0px 0px 0px auto !important',
      width: '14px',
      height: '14px',
    },
  },
}));

export const stylesDialogMatterDetail = makeStyles((theme) => ({
  container: {
    '&>div:nth-child(3)': {
      justifyContent: 'flex-end',
      '&>div': {
        margin: '0px',
        borderRadius: '0px',
        // maxWidth: '450px',
        maxWidth: '100%',
        maxHeight: '100%',
      },
    },
  },
  paper: {
    minHeight: '100vh',
    width: '48%',
  },
}));

export const styleTimeline = makeStyles((theme) => ({
  container: {
    position: 'relative',
    '&>*': {
      position: 'relative',
      zIndex: '5',
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      top: '0',
      left: '43px',
      width: '2px',
      height: '100%',
      background: '#ebebeb',
      zIndex: '1',
    },
  },
  avatar: {
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.primary[300],
    fontSize: '1rem',
  },
  paper: {
    padding: '20px',
    boxShadow: 'none',
    background:
      theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
    border: '1px dashed',
    borderColor:
      theme.palette.mode === 'dark' ? theme.palette.dark.dark : theme.palette.primary.dark,
    '&:hover': {
      border: '1px solid',
    },
  },
}));

export const styleTimelineByStudentMessage = makeStyles((theme) => ({
  container: {
    position: 'relative',
    '&>*': {
      position: 'relative',
      zIndex: '5',
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      top: '0',
      left: '43px',
      width: '2px',
      height: '100%',
      background: '#ebebeb',
      zIndex: '1',
    },
  },
  avatar: {
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.primary[300],
    fontSize: '1rem',
  },
  paper: {
    padding: '20px',
    boxShadow: 'none',
    background:
      theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
    border: '1px dashed',
    borderColor:
      theme.palette.mode === 'dark' ? theme.palette.dark.dark : theme.palette.primary.dark,
    '&:hover': {
      border: '1px solid',
    },
  },
}));
