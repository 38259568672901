import { FC, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import {
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Checkbox,
  Tooltip,
  IconButton,
  CircularProgress,
  useTheme,
  Button,
  Typography,
  FormHelperText,
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { IconChevronsRight, IconTrash, IconNotes } from '@tabler/icons';
import { useHistory } from 'react-router-dom';

import {
  useDeletePreconfigSubjectMutation,
  useGetPreconfigSubjectsQuery,
} from '../../slices/homologationsApiSlice';
import { useAppSelector } from '../../../../../hooks/useRedux';
import { PreconfigSubjects } from '../../interfaces/homologations.interface';
import { Score } from '../../interfaces/payload.interface';
import { renderErrorOrEmptyRow, renderSkeletonRows } from '../../../../shared/helpers/render';
import {
  setPreconfigSubjectToEdit,
  openExternalConfirmModalWith,
} from '../../slices/homologationsSlice';
import ErrorAlert from '../../../../shared/components/alerts/ErrorAlert';
import { useTranslation } from 'react-i18next';

interface Props {
  curriculumId: number;
  instituteId: number;
  agreementId?: number;

  maxSubjects?: number;

  hasAgreedment?: boolean;

  change?: boolean;
  setChange?: () => void;

  /** Homologation Id */
  hId: number;

  //si el mensaje se quiere mostrar al inicio de la pagina y no en el encabezado de la tabla
  onShowAlert?: (state: boolean) => void;
  handleUpdateAppStatus?: (state: { status: 'error' | 'success'; message: string }) => void;

  openDialog: (mode: 'person' | 'institute' | 'subjectInstitute' | 'editSubjectPreconfig') => void;
}

const HomologationList: FC<Props> = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const { currentProfile } = useAppSelector((state) => state.access);
  const { externalPreconfigSubjectScore } = useAppSelector((state) => state.homologations);

  const [selected, setSelected] = useState<number[]>([]);
  const [isError, setIsError] = useState<boolean>();

  const {
    curriculumId,
    instituteId,
    hId,
    openDialog,
    maxSubjects,
    hasAgreedment,
    agreementId,
    change,
    setChange,
  } = props;

  useEffect(() => {
    if (change) {
      setSelected([]);
      setChange!();
    }
  }, [change, setChange]);
  //
  useEffect(() => {
    if (Boolean(externalPreconfigSubjectScore)) {
      window.addEventListener('beforeunload', alertUser);
      return () => {
        window.removeEventListener('beforeunload', alertUser);
      };
    }
  }, [externalPreconfigSubjectScore]);
  const alertUser = (e: any) => {
    e.preventDefault();
    return 'sadsa';
  };

  //Materias preconfiguradas
  const {
    data: preconfig = [],
    // isLoading: isLoadingPC,
    isFetching: isFetchingPC,
    error: errorPreconfig,
  } = useGetPreconfigSubjectsQuery({
    profileId: currentProfile?.profileId!,
    curriculumId,
    instituteId,
  });

  const handleClick = (
    // event: React.MouseEvent<HTMLTableHeaderCellElement, MouseEvent>,
    event: React.ChangeEvent<HTMLInputElement>,
    subjectId: number
  ) => {
    const selectedIndex = selected.indexOf(subjectId);
    let newSelected: number[] = [];

    if (selectedIndex === -1) {
      if (hasAgreedment)
        if (maxSubjects && maxSubjects <= selected.length) {
          return;
        }
      newSelected = newSelected.concat(selected, subjectId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleHomologation = () => {
    if (!Boolean(selected.length)) {
      setIsError(true);
      return;
    }
    setIsError(false);
    dispatch(
      openExternalConfirmModalWith({
        dataList: preconfig,
        selectedData: selected,
        homId: hId,
        curriculumId: curriculumId,
        agreementId: agreementId,
      })
    );
  };

  const handleEditClick = (data: { id: number; instSubject: string; subject: string }) => {
    dispatch(setPreconfigSubjectToEdit(data));
    openDialog('editSubjectPreconfig');
  };

  const renderRow = (preconfigSubjects: PreconfigSubjects) => {
    const preconfigId = preconfigSubjects.id as unknown;
    return (
      <TableRow hover className="table-row" key={preconfigSubjects.id.toString()}>
        <TableCell>
          <Checkbox
            // disabled={!available || false}
            checked={selected.some((r) => r === Number(preconfigSubjects.id))}
            onChange={(e) => handleClick(e, Number(preconfigSubjects.id))}
            // {...(!available && { sx: { color: `${theme.palette.error.main}!important` } })}
          />
        </TableCell>
        <TableCell
        // width="60%"
        // {...(!available && { sx: { color: `${theme.palette.error.main}` } })}
        >
          {preconfigSubjects.subject.subject_curriculum.matter_rename}
        </TableCell>
        <TableCell
          // width="30%"
          align="center"
          // {...(!available && { sx: { color: `${theme.palette.error.main}` } })}
        >
          {preconfigSubjects.status_id}
        </TableCell>

        <TableCell>
          <IconChevronsRight color={theme.palette.primary.main} />
        </TableCell>
        <TableCell>{preconfigSubjects.institution_subject.name}</TableCell>
        <TableCell align="center">
          {externalPreconfigSubjectScore
            ? externalPreconfigSubjectScore[preconfigId as keyof Score]
              ? externalPreconfigSubjectScore[preconfigId as keyof Score]!.score
              : '0'
            : '0'}
        </TableCell>
        <TableCell>
          <Tooltip title={t('academic.homologation.external.list.edit_note')}>
            <IconButton
              aria-label={t('academic.homologation.external.list.edit_note')}
              color="primary"
              onClick={() => {
                handleEditClick({
                  id: preconfigSubjects.id,
                  instSubject: preconfigSubjects.institution_subject.name,
                  subject: preconfigSubjects.subject.mat_name,
                });
              }}
            >
              <IconNotes size={20} />
            </IconButton>
          </Tooltip>
          <DeleteIcon
            subjectId={preconfigSubjects.id}
            currentProfile={currentProfile!.profileId!}
          />
        </TableCell>
      </TableRow>
    );
  };

  const renderRows = () => {
    return <>{preconfig?.map(renderRow)}</>;
  };

  return (
    <>
      <Grid container item mb={2} justifyContent="space-between">
        <Grid item>
          <Typography variant="h4" color={theme.palette.primary.main}>
            {t('academic.homologation.external.list.subjects_homologated')}
          </Typography>
        </Grid>

        {hasAgreedment && (
          <Grid item>
            <FormHelperText
              sx={{ color: selected.length === maxSubjects ? theme.palette.error.main : '' }}
            >
              {t('academic.homologation.external.list.subjects_selected')} {selected.length} /{' '}
              {maxSubjects}
            </FormHelperText>
          </Grid>
        )}
      </Grid>

      {isError && (
        <ErrorAlert
          message={t('academic.homologation.external.list.error')}
          handleDismiss={() => setIsError(false)}
        />
      )}

      <Grid item xs={12} sx={{ maxHeight: 600, overflow: 'hidden' }}>
        <PerfectScrollbar component="div">
          <TableContainer
            sx={{
              border: '1px solid #eee',
              borderRadius: '12px!important',
              maxHeight: '500px',
            }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>{t('academic.homologation.external.list.current_subject')}</TableCell>
                  <TableCell>{t('academic.homologation.external.list.current_status')}</TableCell>
                  <TableCell></TableCell>
                  <TableCell>
                    {t('academic.homologation.external.list.destinate_subject')}
                  </TableCell>
                  <TableCell>{t('academic.homologation.external.list.calification')}</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isFetchingPC ? renderSkeletonRows(5, 7) : renderRows()}
                {errorPreconfig && renderErrorOrEmptyRow(5, errorPreconfig as string)}
                {preconfig?.length === 0 && renderErrorOrEmptyRow(5)}
              </TableBody>
            </Table>
          </TableContainer>
        </PerfectScrollbar>
      </Grid>

      <Grid container justifyContent={'flex-end'} mt={2} spacing={2}>
        <Grid item>
          <Button variant="outlined" size="large" color="primary" onClick={() => history.goBack()}>
            {t('academic.homologation.external.list.cancel')}
          </Button>
        </Grid>
        <Grid item>
          {/* <Button
            variant="contained"
            size="large"
            style={{ background: theme.palette.error.main, borderColor: '#EDE7F6' }}
            onClick={() => {
              // touched.comment = true;
              // validateField('comment').then(() => {
              //   if (touched.comment && errors.comment) return;
              //   handleDelete({ code: currentApplication!.app_code, msg: values.comment });
              // });
            }}
          >
            Rechazar
          </Button> */}
        </Grid>
        <Grid item>
          <Button variant="contained" size="large" color="primary" onClick={handleHomologation}>
            {t('academic.homologation.external.list.approve')}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

interface DeleteProps {
  subjectId: number;
  currentProfile: number;
}
const DeleteIcon: FC<DeleteProps> = (props) => {
  const { t } = useTranslation();
  const { subjectId, currentProfile } = props;

  const [deletePreconfigSubject, { isLoading }] = useDeletePreconfigSubjectMutation();

  const handleDelete = () => {
    deletePreconfigSubject({ profileId: currentProfile, subjectId });
  };

  return (
    <>
      {!isLoading ? (
        <Tooltip title={t('academic.homologation.external.list.delete_subject')}>
          <IconButton
            aria-label={t('academic.homologation.external.list.delete_subject')}
            color="default"
            onClick={handleDelete}
          >
            <IconTrash size={20} />
          </IconButton>
        </Tooltip>
      ) : (
        <CircularProgress size={20} />
      )}
    </>
  );
};

export default HomologationList;

// const HomologationList = () => {
//   return <></>;
// };

// export default HomologationList;
