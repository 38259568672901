import Autocomplete from '@material-ui/core/Autocomplete';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ErrorAlert from '../../../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../../../shared/components/alerts/SuccessAlert';
import { statusAvailables } from '../../../../../shared/constants/resourceStatus';
import { useUpdateProjectPrincipalMutation } from '../../../slices/InvestigationLinesApiSlice';
import InputLabel from '../../../../../shared/components/forms/InputLabel';
import { projectPrincipal } from '../../../constants/validationSchemas.projects';
import { ProjectPrincipal } from '../../../interfaces/projectPrincipal.interfaces';
import LocalizedDateRangePicker from '../../../../../shared/components/controlled/LocalizedDateRangePicker';
import { DateRange } from '@material-ui/lab';
import { Switch } from '@material-ui/core';
import statusProjectAvailables from '../../../constants/lists/statusProject';

interface Props {
  projectId?: string;
  isEdit?: boolean;
  data?: ProjectPrincipal;
}

const ProjectPrincipalForm = ({ projectId, isEdit, data }: Props) => {
  const { t } = useTranslation();

  const history = useHistory();

  const [showAlerts, setShowAlerts] = useState(true);

  const [
    updateProjectPrincipal,
    { isLoading: isLoadingEdit, isError: isErrorEdit, error: errorEdit, isSuccess: isSuccessEdit },
  ] = useUpdateProjectPrincipalMutation();

  const { getFieldProps, errors, touched, handleSubmit, setFieldValue, values } = useFormik({
    initialValues: {
      name: isEdit ? data?.name || '' : '',
      observation: isEdit ? data?.observation || '' : '',
      hasManyStages: isEdit ? (data?.has_many_stages === '1' ? true : false) : false,
      statusProject: isEdit ? data?.status_project || '' : '',
      // projectResearchCount: isEdit ? data?.project_research_count || '' : '',
      statusId: isEdit ? data?.status_id || '' : statusAvailables[0]?.id + '' || '',
      startDate: isEdit ? data?.start_date || new Date() : new Date(),
      endDate: isEdit ? data?.end_date || new Date() : new Date(),
    },
    validationSchema: projectPrincipal,
    onSubmit: async ({
      name,
      observation,
      endDate,
      startDate,
      hasManyStages,
      // projectResearchCount,
      statusId,
      statusProject,
    }) => {
      await updateProjectPrincipal({
        projectId: projectId || '',
        projectPrincipalResearchPayload: {
          name,
          observation,
          has_many_stages: hasManyStages ? '1' : '0',
          // project_research_count: projectResearchCount,
          end_date: endDate,
          start_date: startDate,
          status_id: statusId,
          status_project: statusProject,
        },
      });
      window.scrollTo(0, 0);
      setShowAlerts(true);
    },
  });

  const onChangeDates = (newDatesValues: DateRange<Date>) => {
    const [start, end] = newDatesValues;
    // update formik values
    const startValue = start?.setHours(0, 0, 0, 0);
    const endValue = end?.setHours(0, 0, 0, 0);
    setFieldValue('startDate', startValue);
    setFieldValue('endDate', endValue);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction="column" gap={2}>
        <Grid item xs={12} md={6}>
          <Grid container direction="column" spacing={2}>
            {isErrorEdit && showAlerts && (
              <ErrorAlert
                message={errorEdit as string}
                handleDismiss={() => setShowAlerts(false)}
              />
            )}

            {isSuccessEdit && showAlerts && (
              <SuccessAlert
                message={t('El proyecto se actualizó con exito')}
                handleDismiss={() => setShowAlerts(false)}
              />
            )}

            <Grid item xs={12}>
              <InputLabel>{t('Nombre del proyecto')}</InputLabel>
              <TextField
                {...getFieldProps('name')}
                error={Boolean(errors.name && touched.name)}
                placeholder={t('ingrese nombre')}
                fullWidth
              />
              {errors.name && touched.name && (
                <FormHelperText error>{t(errors.name)}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12}>
              <InputLabel>¿Tiene etapas?</InputLabel>
              <Switch checked={values.hasManyStages} {...getFieldProps('hasManyStages')} />
            </Grid>

            <Grid item xs={12}>
              <InputLabel>{t('Observación')}</InputLabel>
              <TextField
                {...getFieldProps('observation')}
                error={Boolean(errors.observation && touched.observation)}
                placeholder={t('Ingrese observación')}
                fullWidth
              />
              {errors.observation && touched.observation && (
                <FormHelperText error>{t(errors.observation)}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12}>
              <LocalizedDateRangePicker
                hideLabel
                showInputLabel
                onChange={onChangeDates}
                value={[values.startDate, values.endDate]}
              />
            </Grid>

            <Grid item xs={12}>
              <InputLabel> {t('lines_research.statusProject.title')} </InputLabel>
              <Autocomplete
                options={
                  // isEdit
                  //   ? statusProjectAvailables.filter(
                  //       ({ keyword }) => keyword === 'EN EJECUCION' || keyword === 'EN ESPERA'
                  //     )
                  //   :
                  statusProjectAvailables
                }
                value={
                  statusProjectAvailables.find(({ keyword }) => keyword === values.statusProject) ||
                  null
                }
                loadingText={t('lines_research.statusProject.loading')}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(errors.statusProject && touched.statusProject)}
                    placeholder={t('lines_research.statusAvailables.placeholder')}
                  />
                )}
                onChange={(_, value) => {
                  setFieldValue('statusProject', value?.keyword);
                }}
                fullWidth
                clearIcon={null}
              />
              {errors.statusProject && touched.statusProject && (
                <FormHelperText error>{errors.statusProject}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12}>
              <InputLabel>Estado</InputLabel>
              <Autocomplete
                options={statusAvailables}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(errors.statusId && touched.statusId)}
                    placeholder="Seleccione un estado disponible"
                  />
                )}
                defaultValue={statusAvailables[0]}
                onChange={(e, value) => setFieldValue('stat.statusId', value?.acronym || '')}
                fullWidth
                clearIcon={null}
              />
              {errors.statusId && touched.statusId && (
                <FormHelperText error>{errors.statusId}</FormHelperText>
              )}
            </Grid>
          </Grid>

          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            spacing={2}
            sx={{ mt: 6 }}
          >
            <Grid item xs={6}>
              {!projectId && (
                <Button
                  fullWidth
                  variant="outlined"
                  size="large"
                  color="primary"
                  onClick={() => history.goBack()}
                >
                  {t('academic.subjects.add.back')}
                </Button>
              )}
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                type="submit"
                variant="contained"
                size="large"
                color="primary"
                disabled={isLoadingEdit}
                endIcon={isLoadingEdit && <CircularProgress size={20} />}
              >
                {t('academic.subjects.add.submit')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default ProjectPrincipalForm;
