export const statusAvailablesByDate = [
  {
    id: 1,
    label: 'En proceso',
    acronym: 'active',
    color: 'success',
  },
  {
    id: 2,
    label: 'Por Finalizar',
    acronym: 'standby',
    color: 'orange',
  },
  {
    id: 3,
    label: 'Finalizado',
    acronym: 'inactive',
    color: 'red',
  },
];
