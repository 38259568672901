import { Grid, Typography } from '@material-ui/core';
import { CourseStudent } from '../../interfaces/practice-subjects.interface';

interface props {
  courses: CourseStudent[];
}

const PracticeHistory = ({ courses }: props) => {
  return (
    <Grid item container sx={{ m: 2 }}>
      <Grid item xs={12} md={12} lg={12} sx={{ mb: 2 }}>
        <Typography variant="h4">Historial de materias</Typography>
      </Grid>
      <Grid
        item
        container
        xs={12}
        md={12}
        lg={12}
        sx={{ borderBottom: 1, borderColor: 'gainsboro', mb: 1, pb: 1 }}
      >
        <Grid item xs={6} md={6}>
          <Typography variant="h5">Materia</Typography>
        </Grid>
        <Grid item xs={6} md={6} textAlign="center">
          <Typography variant="h5">Horas</Typography>
        </Grid>
      </Grid>
      {courses.map((cr) => (
        <Grid item container xs={12} md={12} lg={12}>
          <Grid item xs={6} md={6}>
            {cr.subject.mat_name}
          </Grid>
          <Grid item xs={6} md={6} textAlign="center">
            {cr.observation}
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default PracticeHistory;
