import Chip from '../extended/Chip';
import { transactionStatusAvailables } from '../../constants/resourceStatus';
interface Props {
  keyword: string;
  name: string;
}
const TransactionStatusChip = ({ keyword, name }: Props) => {
  const status = transactionStatusAvailables.find((status) => status.name === keyword);

  return <Chip label={name} size="small" chipcolor={status?.color} />;
};

export default TransactionStatusChip;
