import { Grid, Typography } from '@material-ui/core';
import MainCard from '../../../shared/components/cards/MainCard';
import AgreementsTable from '../components/AgreementsTable';

const AgreementsScreen = () => {
  return (
    <div>
      <Grid component={MainCard} sx={{ padding: 2 }}>
        <Grid sx={{ mb: 3 }}>
          <Typography variant="h2">Convenios</Typography>
        </Grid>
        <AgreementsTable />

        {/* {classroomIdToDelete && <DeleteClassroomDialog />} */}
      </Grid>
    </div>
  );
};

export default AgreementsScreen;
