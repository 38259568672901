import { Route, Switch } from 'react-router-dom';
import GuardRoute from '../../../../router/guards/GuardRoute';
import AddClientScreen from '../screens/AddClientScreen';
import AssignDiscountScreen from '../screens/AssignDiscountScreen';
import ClientAccountStatusScreen from '../screens/ClientAccountStatusScreen';
import ClientsScreen from '../screens/ClientsScreen';
import FinancialRepresentative from '../screens/FinancialRepresentative';
import { sharedPermissions } from '../../../shared/constants/sharedPermissions';
import {
  debtorsPermissions,
  financialInformationPermissions,
  studentPermissions,
} from '../constants/permissions';

const ClientsRouter = () => {
  return (
    <>
      <Switch>
        <GuardRoute
          exact
          path="/administrar-clientes"
          component={ClientsScreen}
          subPermissionKey={sharedPermissions.findPerson}
        />
        <GuardRoute
          exact
          path="/administrar-clientes/:idPerson/asignar-descuento/:optionMenu"
          component={AssignDiscountScreen}
          subPermissionKey={sharedPermissions.findPerson}
        />
        <GuardRoute
          exact
          path="/administrar-clientes/:clientId/:id/estado-cuenta"
          component={ClientAccountStatusScreen}
          subPermissionKey={studentPermissions.getDebstByStudents}
          permissionKey={studentPermissions.groupKey}
        />
        <GuardRoute
          exact
          path="/administrar-clientes/agregar"
          component={AddClientScreen}
          subPermissionKey={sharedPermissions.createPerson}
        />
        <GuardRoute
          exact
          path="/administrar-clientes/:dni/:personId/:studentId/representate-financiero"
          component={FinancialRepresentative}
          subPermissionKey={[
            sharedPermissions.findPerson,
            debtorsPermissions.update,
            debtorsPermissions.getDebtorsByStudent,
            debtorsPermissions.getDebtorPerPerson,
            financialInformationPermissions.getIdDebtor,
          ]}
        />
        <Route path="/administrar-clientes/*" component={ClientsScreen} />
      </Switch>
    </>
  );
};

export default ClientsRouter;
