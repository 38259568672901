import { useTranslation } from 'react-i18next';
import { Autocomplete, Button, FormHelperText, Grid, TextField } from '@material-ui/core';
import { useFormik } from 'formik';

import InputLabel from '../../../../shared/components/forms/InputLabel';
import { PersDisability } from '../../interfaces/studentsStore.interface';
import { DisabilitySchema } from '../../constants/validationSchemas';
import { useGetDisabilityAvailableQuery } from '../../../../shared/slices/catalogApi';

export interface Props {
  setData: (data: PersDisability) => void;
}

const AddDisabilityForm = ({ setData }: Props) => {
  const { t } = useTranslation();

  //disability
  const { data: disability = [], isLoading: isLoadingDisability } = useGetDisabilityAvailableQuery(
    {}
  );

  const { getFieldProps, errors, touched, setFieldValue, values, submitForm, resetForm } =
    useFormik<PersDisability>({
      initialValues: {
        type_disability: 0,
        percentage_disability: 0,
        observation: '',
      },
      validationSchema: DisabilitySchema,
      onSubmit: ({ observation, percentage_disability, type_disability }) => {
        setData({ observation, percentage_disability, type_disability });
        resetForm();
      },
    });

  return (
    <Grid container direction="column" gap={2}>
      <Grid item container spacing={2} alignItems="center">
        <Grid item xs={12} md={6}>
          <InputLabel>{t('Tipo de discapacidad')}</InputLabel>
          <Autocomplete
            options={disability || []}
            loading={isLoadingDisability}
            loadingText={t('Cargando tipos de discapacidad')}
            noOptionsText={t('No hay tipos de discapacidad disponibles')}
            value={disability.find(({ id }) => id === values.type_disability) || null}
            getOptionLabel={(option) => option.typ_dis_name}
            renderInput={(params) => (
              <TextField
                {...params}
                error={Boolean(errors.type_disability && touched.type_disability)}
                placeholder={t('Selecciona un tipo de discapacidad')}
              />
            )}
            onChange={(_, value) => {
              setFieldValue('type_disability', value?.id || '');
            }}
            fullWidth
            clearIcon={null}
          />
          {errors.type_disability && touched.type_disability && (
            <FormHelperText error>{t(errors.type_disability)}</FormHelperText>
          )}
        </Grid>

        <Grid item xs={12} md={6}>
          <InputLabel>{t('Porcentaje de discapacidad')}</InputLabel>
          <TextField
            {...getFieldProps('percentage_disability')}
            error={Boolean(errors.percentage_disability && touched.percentage_disability)}
            placeholder={t('Ingrese el porcentaje de discapacidad')}
            fullWidth
          />
          {errors.percentage_disability && touched.percentage_disability && (
            <FormHelperText error>{t(errors.percentage_disability)}</FormHelperText>
          )}
        </Grid>

        <Grid item xs={12}>
          <InputLabel>{t('Observación')}</InputLabel>
          <TextField
            {...getFieldProps('observation')}
            error={Boolean(errors.observation && touched.observation)}
            placeholder={t('Ingrese una observación')}
            fullWidth
          />
          {errors.observation && touched.observation && (
            <FormHelperText error>{t(errors.observation)}</FormHelperText>
          )}
        </Grid>
      </Grid>

      <Grid item container alignItems="center" justifyContent="flex-end" spacing={2}>
        <Grid item>
          <Button variant="contained" size="large" color="primary" onClick={submitForm}>
            Agregar
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AddDisabilityForm;
