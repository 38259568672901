import { Typography, useTheme } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { useFormikContext } from 'formik';
import { PeriodForm, Stage } from '../../../interfaces/period-form.interfaces';
import { useAppSelector } from '../../../../../../hooks/useRedux';
import { STAGES } from '../../../constants/stages';
import { format } from 'date-fns';
import InfoAlert from '../../../../../shared/components/alerts/InfoAlert';
import { useTranslation } from 'react-i18next';

const PeriodFormConfirm = () => {
  const { t } = useTranslation();

  const theme = useTheme();

  const { values, errors } = useFormikContext<PeriodForm>();

  const { academicOfferConfig } = useAppSelector((state) => state.periods);

  const config = academicOfferConfig?.map((e) => e.stg_acronym);

  const RenderErrors = () => {
    return (
      <>
        <Grid item xs={12} sx={{ mt: 2, width: 1 }}>
          <InfoAlert message={Object.values(errors).map((e) => e.toString())!} />
        </Grid>
      </>
    );
  };

  const obtainStartDate = (): string => {
    const dates: Date[] = [];
    for (let i = 0; i < STAGES.length; i++) {
      if (config.includes('RC')) {
        for (let i = 0; i < values.generation_quote?.length; i++) {
          const start_date = values.generation_quote[i]?.start_date!;
          if (start_date !== null) {
            dates.push(start_date);
          }
        }
      }
      if (config.includes(STAGES[i].use_case)) {
        const { start_date } = values[STAGES[i].field as unknown as keyof PeriodForm] as Stage;
        if (start_date !== null) {
          dates.push(start_date!);
        }
      }
    }
    const sorted = dates?.sort((a, b) => a.getTime() - b.getTime())[0];
    return format(sorted! || new Date(), 'yyyy-MM-dd');
  };

  const obtainEndDate = (): string => {
    const dates: Date[] = [];
    for (let i = 0; i < STAGES.length; i++) {
      if (config.includes('RC')) {
        for (let i = 0; i < values.generation_quote?.length; i++) {
          const end_date = values.generation_quote[i]?.end_date!;
          if (end_date !== null) {
            dates.push(end_date);
          }
        }
      }
      if (config.includes(STAGES[i].use_case)) {
        const { end_date } = values[STAGES[i].field as unknown as keyof PeriodForm] as Stage;
        if (end_date !== null) {
          dates.push(end_date!);
        }
      }
    }
    const sorted = dates?.sort((a, b) => b.getTime() - a.getTime())[0];
    return format(sorted! || new Date(), 'yyyy-MM-dd');
  };

  return (
    <Grid container spacing={4} justifyContent="center" sx={{ mt: 5, mb: 5 }}>
      <Grid item xs={12}>
        {Object.values(errors)?.length !== 0 && <RenderErrors />}
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h3" textAlign="center" sx={{ color: theme.palette.primary.main }}>
          {t('academic.perios_list.add.stage_4.title')}
        </Typography>
      </Grid>
      <Grid item xs={12} container justifyContent="center" gap={3}>
        <Grid item>
          <Typography variant="h4" component="div">
            {t('academic.perios_list.add.stage_4.period')} <strong>{values.name}</strong>{' '}
            {t('academic.perios_list.add.stage_4.starts')} <strong>{obtainStartDate()}</strong>{' '}
            {t('academic.perios_list.add.stage_4.ends')} <strong>{obtainEndDate()}</strong>
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} container justifyContent="center" gap={3}>
        <Grid item>
          <Typography variant="h5" component="div" sx={{ mb: 3 }}>
            {t('academic.perios_list.add.stage_4.warning')}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PeriodFormConfirm;
