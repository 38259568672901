import { Grid } from '@material-ui/core';
import { MatterMesh } from '../../../../maintenance/meshs/interfaces/curriculum.interfaces';
import { SubjectCurriculumStatus } from '../../interfaces/subjects-approveds';
import SkeletonPeriod from './SkeletonPeriod';
import Stack from '@material-ui/core/Stack';
import AccordionSubjects from './AccordionSubjects';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import { fireDialogSubjectsRegistered } from '../../slices/subjectsRegisterSlice';
import SubjectsRegisteredDialog from '../SubjectsRegisteredDialog';

interface Props {
  subjectCurriculums?: MatterMesh[];
  subjectCurriculumsStatus: SubjectCurriculumStatus[];
  isLoading: boolean;
  isLoadingMateriasAprobadas: boolean;
  curriculum_new?: string;
}

const MeshContainer = ({
  subjectCurriculums,
  subjectCurriculumsStatus,
  isLoading,
  isLoadingMateriasAprobadas,
  curriculum_new,
}: Props) => {
  const dispatch = useAppDispatch();
  const { showSubjectsRegistered } = useAppSelector((state) => state.subjectsRegister);
  return (
    <Grid container>
      <Grid item xs={12} md={10}>
        {isLoading || isLoadingMateriasAprobadas ? (
          <Stack spacing={2}>
            <SkeletonPeriod />
            <SkeletonPeriod />
            <SkeletonPeriod />
            <SkeletonPeriod />
          </Stack>
        ) : (
          <AccordionSubjects
            subjectCurriculums={subjectCurriculums}
            subjectCurriculumsStatus={subjectCurriculumsStatus}
            curriculum_new={!!Number(curriculum_new)}
          />
        )}
      </Grid>

      <SubjectsRegisteredDialog
        open={showSubjectsRegistered}
        handleCloseDialog={() => dispatch(fireDialogSubjectsRegistered(false))}
      />
    </Grid>
  );
};

export default MeshContainer;
