import * as React from 'react';
import { Checkbox, FormControl, FormControlLabel, FormGroup } from '@material-ui/core';
import { useState } from 'react';
import { RolToAssign } from '../../interfaces/rol-assing.interface';

interface props {
  rolesTo: RolToAssign[];
  setRoles: React.Dispatch<React.SetStateAction<number[]>>;
}

const RolesCheckGroup = ({ rolesTo, setRoles }: props) => {
  const [ids, setIds] = useState<number[]>([]);

  const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setIds((ids) => [...ids, rolesTo.find((rol) => rol.name === e.target.name)?.id!]);
      setRoles((ids) => [...ids, rolesTo.find((rol) => rol.name === e.target.name)?.id!]);
    } else {
      const index = ids.findIndex(
        (rol) => rol === rolesTo.find((rol) => rol.name === e.target.name)?.id!
      );
      const temp = [...ids];
      temp.splice(index, 1);
      setIds(temp);
      setRoles(temp);
    }
  };

  return (
    <div style={{ maxHeight: 400, overflowY: 'scroll' }}>
      <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
        <FormGroup>
          {/* <FormControlLabel
            control={
              <Checkbox checked={gilad} onChange={handleChange} name="gilad" />
            }
            label="Gilad Gray"
          /> */}
          {[...rolesTo]
            ?.sort((a, b) => (a.name > b.name ? 1 : -1))
            .map((r) => (
              <FormControlLabel
                control={<Checkbox name={r.name} onChange={handleCheck} />}
                label={r.name}
              />
            ))}
        </FormGroup>
      </FormControl>
    </div>
  );
};

export default RolesCheckGroup;
