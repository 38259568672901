import { Alert, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const NotPermissionAlert = () => {
  const { t } = useTranslation();

  return (
    <Alert severity="error">
      <Typography variant="body2" align="center" paddingX={1} paddingY={3}>
        {t('shared.components.not_permissions.alert.label')}
      </Typography>
    </Alert>
  );
};

export default NotPermissionAlert;
