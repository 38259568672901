import { useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { useGetPeriodsQuery } from '../../slices/periodsApiSlice';
import SearchPaginatedTable from '../../../../shared/components/tables/SearchPaginatedTable';
import StatusChip from '../../../../shared/components/badges/StatusChip';
import AddButton from '../../../../shared/components/tables/buttons/AddButton';
import DeleteButton from '../../../../shared/components/tables/buttons/DeleteButton';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import { Autocomplete, Grid, IconButton, TextField, Tooltip, Typography } from '@material-ui/core';
import { IconClipboardList, IconClipboardTypography } from '@tabler/icons';
import {
  openDeleteModalWith,
  setPeriodsTable,
  setStatusIdSelected,
} from '../../slices/periodsSlice';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import EditButton from '../../../../shared/components/tables/buttons/EditButton';
import { useTableSearch } from '../../../../../hooks/useTableSearch';
import { statusAvailables } from '../../../../shared/constants/resourceStatus';
import useFormatDate from '../../../../shared/hooks/useFormatDate';
import LocalizedDatePicker from '../../../../shared/components/controlled/LocalizedDatePicker';
import { useGetAvailablePeriodTypesQuery } from '../../../periods-types/slices/periodTypesApiSlice';
import { subPermissionKeys } from '../../constants/permissions';
import useSharedGuard from '../../../../../hooks/useSharedGuard';

const PeriodsTable = function () {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { path } = useRouteMatch();

  const history = useHistory();

  const { formatDate } = useFormatDate();

  const { hasAccess } = useSharedGuard(subPermissionKeys);

  const { currentProfile } = useAppSelector((state) => state.access);
  const { statusIdSelected } = useAppSelector((state) => state.periods);
  const [mesYear, setMesYear] = useState<Date | null>(new Date());
  const [typePeriod, setTypePeriod] = useState('');

  const { page, perPage, search, filterArrow } = useAppSelector(
    (state) => state.periods.periodsTable
  );

  const { data: periodType } = useGetAvailablePeriodTypesQuery({
    profileId: currentProfile?.profileId!,
  });

  const { setPage, setPerPage, setSearch, setFilterArrow, resetTable } = useTableSearch({
    page,
    perPage,
    search,
    filterArrow,
    updateFunction: setPeriodsTable,
    otherFilters: {
      statusId: statusIdSelected,
      typePeriodId: typePeriod,
      currentYear: mesYear ? Number(format(mesYear, 'yyyy') as unknown as number) : undefined,
    },
  });

  const { data, isLoading, isFetching, error } = useGetPeriodsQuery(
    {
      profileId: currentProfile?.profileId!,
      page,
      perPage,
      search,
      currentYear: mesYear ? Number(format(mesYear, 'yyyy') as unknown as number) : undefined,
      ...(filterArrow && { sort: filterArrow.name, type_sort: filterArrow.direction }),
      ...(statusIdSelected && { statusId: statusIdSelected }),
      typePeriodId: typePeriod,
    },
    { refetchOnMountOrArgChange: 30 }
  );

  const datafetch = data?.data!.map((value, index) => ({
    id: index + ((page - 1) * perPage + 1),
    per_name: value.per_name,
    per_current_year: value?.per_current_year,
    per_type: value.type_period?.tp_name,
    dates: `${formatDate(value.start_process)} - ${formatDate(value.end_process)}`,
    detail: {
      per_max_matter_enrollment: value?.per_max_matter_enrollment,
      per_min_matter_enrollment: value?.per_min_matter_enrollment,
      per_num_fees: value?.per_num_fees,
      per_pay_enrollment: value?.per_pay_enrollment,
      start_date: value?.start_process,
    },
    status_id: value.status_id,
    options: value,
  }));

  const handleDelete = function (periodId: number) {
    dispatch(openDeleteModalWith(periodId));
  };

  const goToAdd = () => {
    history.push('/administrar-periodos/agregar');
  };

  return (
    <Grid item xs={12}>
      <SearchPaginatedTable
        data={datafetch!}
        headers={{
          id: 'N°',
          per_name: t('academic.periods_list.name'),
          per_current_year: t('academic.periods_list.year'),
          per_type: t('academic.periods_list.type'),
          dates: t('academic.periods_list.dates'),
          detail: t('academic.periods_list.details'),
          status_id: t('academic.periods_list.status'),
          options: t('academic.periods_list.options'),
        }}
        keyExtractor={(item) => item.id.toString()}
        searchPlacehoder={t('academic.periods_list.search')}
        listFilterArrows={{
          per_name: 'per_name',
          status_id: 'status_id',
          dates: 'start_process',
        }}
        filterArrow={filterArrow}
        setFilterArrow={setFilterArrow}
        isLoading={isLoading}
        isFetching={isFetching}
        error={error}
        perPage={perPage}
        setPerPage={setPerPage}
        total={data?.total || 0}
        page={page}
        setPage={setPage}
        numHeader={7}
        search={search}
        setSearch={setSearch}
        customRenderers={{
          status_id: (row) => <StatusChip statusId={Number(row.status_id)} />,
          detail: ({ detail }) => (
            <Grid container item direction="column" gap={1}>
              <Typography>
                {t('academic.periods_list.details.max')}: {detail.per_max_matter_enrollment}
              </Typography>
              <Typography>
                {t('academic.periods_list.details.min')}: {detail.per_min_matter_enrollment}
              </Typography>
              <Typography>
                {t('academic.periods_list.details.quote')}: {detail.per_pay_enrollment}
              </Typography>
              <Typography>
                {t('academic.periods_list.details.quotes_number')}: {detail.per_num_fees}
              </Typography>
            </Grid>
          ),

          options: ({ options: { id }, detail }) => (
            <>
              {hasAccess('edit') && (
                <Link to={`${path}/etapas/${id}`}>
                  <Tooltip title={t('academic.periods_list.stages.tooltip')}>
                    <IconButton aria-label="Ver etapas" color="default">
                      <IconClipboardList size={20} />
                    </IconButton>
                  </Tooltip>
                </Link>
              )}

              {hasAccess('edit') && false && (
                <Link to={`${path}/${id}/permisos-calificaciones`}>
                  <Tooltip title={t('academic.periods_list.stages.tooltip')}>
                    <IconButton aria-label="Ver etapas calificación algo xd" color="default">
                      <IconClipboardTypography size={20} />
                    </IconButton>
                  </Tooltip>
                </Link>
              )}

              {hasAccess('edit') && (
                <EditButton
                  disabled={new Date().getTime() > new Date(detail.start_date).getTime()}
                  handleEdit={() => history.push(`${path}/${id}/editar`)}
                />
              )}
              {hasAccess('delete') && <DeleteButton handleDelete={() => handleDelete(id)} />}
              {/* {!hasAccess('edit') && !hasAccess('delete') && 'Ninguna'} */}
            </>
          ),
        }}
        customHeadersCellsProperties={{
          options: {
            align: 'center',
          },
          id: {
            align: 'center',
          },
        }}
        customDataCellsProperties={{
          id: {
            align: 'center',
          },
          per_name: {
            width: '30%',
          },
          dates: { align: 'center' },
          status_id: { align: 'center' },
          options: {
            align: 'center',
          },
        }}
        ActionButtons={<>{hasAccess('create') && <AddButton size="large" onClick={goToAdd} />}</>}
        additionalFilters={
          <Grid container spacing={2}>
            {/* Filtro por tipo */}
            {hasAccess('getPeriodsTypes') && (
              <Grid item xs={3}>
                <Autocomplete
                  options={periodType || []}
                  getOptionLabel={(option) => option.tp_name}
                  value={periodType?.find(({ id }) => id.toString() === typePeriod.toString())}
                  onChange={(event, value) => {
                    setTypePeriod(value ? value.id.toString() : '');
                    console.log(value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t('utils.type')}
                      placeholder={t('utils.status.placeholder.type')}
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
            )}

            {/* Filtro por fecha  */}
            <Grid item xs={3}>
              <LocalizedDatePicker
                views={['year']}
                label="año"
                maxDate={new Date()}
                value={mesYear}
                onChange={(newValue) => {
                  resetTable();
                  setMesYear(newValue as Date);
                }}
                placeholder="Seleccione un año"
              />
            </Grid>

            {/* Filtro por estado */}
            <Grid item xs={3}>
              <Autocomplete
                options={statusAvailables}
                getOptionLabel={(option) => option.name}
                value={statusAvailables.find((option) => option.id === statusIdSelected)}
                onChange={(event, newValue) => {
                  dispatch(setStatusIdSelected(newValue?.id || null));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('utils.status')}
                    placeholder={t('utils.status.placeholder')}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
          </Grid>
        }
      />
    </Grid>
  );
};

export default PeriodsTable;
