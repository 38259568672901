import { Switch, Redirect } from 'react-router-dom';

import { useAppSelector } from '../../../../hooks/useRedux';

import AreasScreen from '../screens/AreasScreen';
import AddAreaScreen from '../screens/AddAreaScreen';
import EditAreaScreen from '../screens/EditAreaScreen';

// import EditRoleScreen from '../screens/EditRoleScreen';
// import AddRoleScreen from '../screens/AddRoleScreen';
import GuardRoute from '../../../../router/guards/GuardRoute';

import { subPermissionKeys } from '../constants/permissions';

const { groupKey: permissionKey } = subPermissionKeys;

function RolesRouter() {
  // Verify permission
  const { currentModule } = useAppSelector((state) => state.access);
  const hasPermission = subPermissionKeys.groupKey in currentModule?.permissions!;

  if (!hasPermission) return <Redirect to="/" />;

  return (
    <Switch>
      <GuardRoute
        exact
        path={`/administrar-areas`}
        component={AreasScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.read}
      />
      <GuardRoute
        exact
        path={`/administrar-areas/agregar`}
        component={AddAreaScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.create}
      />
      <GuardRoute
        exact
        path={`/administrar-areas/:areaId/editar`}
        component={EditAreaScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.edit}
      />
    </Switch>
  );
}

export default RolesRouter;
