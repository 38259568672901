import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useGetCollaboratorQuery } from '../../../../../hr/collaborators/slices/collaboratorsApiSlice';
import { useDeleteCollaboratorGroupMutation } from '../../../slices/InvestigationLinesApiSlice';
import Typography from '@material-ui/core/Typography';
import { CollaboratorGroupResearch } from '../../../interfaces/groupResearch.interfaces';

interface Props {
  collaboratorGR: CollaboratorGroupResearch;
}

const CollaboratorResearchDelete = ({ collaboratorGR }: Props) => {
  const { isLoading, data } = useGetCollaboratorQuery({
    collaboratorId: Number(collaboratorGR.collaborator_id),
  });

  const [deleteCollaboratorGroup, { isLoading: isLoadingDelete }] =
    useDeleteCollaboratorGroupMutation();

  const handleDelete = (id: string) => {
    deleteCollaboratorGroup({ collaboratorGroupId: id });
  };

  return (
    <Grid item xs={12} mt={2}>
      <Paper elevation={2} sx={{ width: '100%', padding: '5px' }}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item p={2}>
            <Typography variant="subtitle1" color="initial">
              {isLoading ? '...Cargando colaborador' : data?.user.person.pers_full_name}
            </Typography>
          </Grid>
          <Grid item>
            <Tooltip title={'Quitar Colaborador'}>
              <IconButton aria-label="test" onClick={() => handleDelete(collaboratorGR.id)}>
                {isLoading || isLoadingDelete ? (
                  <CircularProgress size={20} />
                ) : (
                  <HighlightOffIcon color="primary" />
                )}
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default CollaboratorResearchDelete;
