import { MeshFormFields } from '../interfaces/payloads.interfaces';

export const meshFormInitialValues: MeshFormFields = {
  name: '',
  description: '',
  casRes: '',
  ocasRes: '',
  creationDate: new Date(),
  year: new Date(),
  title: '',
  itinerary: '',
  numPeriods: null,
  levelEduId: null,
  statusId: null,
  modalityId: null,
  calificationTypeId: null,
  internalHomologationSubjects: null,
  externalHomologationSubjects: null,
  codCareer: '',
  learningComponents: [],
  subjectsToPP: null,
  maxFailedSubject: null,
  percentage_titulation1: 0,
  percentage_titulation2: 0,
  isRequiredCalificationType: true,
  facultyId: null,
  vigencyDate: null,
  cacesResolutionDate: null,
  cacesResolutionNumber: '',
  offerId: null,
  // amount_credits: 0,
};
