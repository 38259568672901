const typeProjectAvailables = [
  { label: 'Vinculación', keyword: 'VINCULACION' },
  { label: 'Investigación', keyword: 'INVESTIGACION' },
];
export const investigationType = 'INVESTIGACION';
export const vinculationType = 'VINCULACION';

type TypesProject = typeof investigationType | typeof vinculationType;
type ModulesKeys = 'research' | 'vinculacion';

export const typeProjectModules: {
  [key in ModulesKeys]: TypesProject;
} = {
  research: investigationType,
  vinculacion: vinculationType,
};

export default typeProjectAvailables;
