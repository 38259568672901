import { Switch } from 'react-router-dom';
import GuardRoute from '../../../../router/guards/GuardRoute';

import AddEmissionPointScreen from '../screens/AddEmissionPointScreen';
import EditEmissionPointScreen from '../screens/EditEmissionPointScreen';
import FinancialConfigurationScreen from '../screens/FinancialConfigurationScreen';

const FinancialConfigurationRouter = () => {
  return (
    <Switch>
      <GuardRoute
        exact
        path="/administrar-puntos-cobro/punto-emision"
        component={FinancialConfigurationScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.read}
      />
      <GuardRoute
        exact
        path="/administrar-puntos-cobro/punto-emision/agregar"
        component={AddEmissionPointScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.read}
      />
      <GuardRoute
        exact
        path="/administrar-puntos-cobro/punto-emision/:emissionPointId/editar"
        component={EditEmissionPointScreen}
      />
    </Switch>
  );
};
export default FinancialConfigurationRouter;
