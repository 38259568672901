import { Box, Typography } from '@material-ui/core';
import { ExpandLessOutlined, ExpandMoreOutlined } from '@material-ui/icons';
import { useTheme } from '@material-ui/core';
import useExpandAccordion from '../../hooks/useExpandAccordion';

interface Props {
  children: JSX.Element | JSX.Element[] | undefined;
  title: string;
  amount: number;
}

const Accordion = ({ children, title, amount }: Props) => {
  const { expanded, handleClickToogle } = useExpandAccordion(false);
  const theme = useTheme();
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          border: `1px solid ${theme.palette.secondary.main}`,
          borderRadius: '8px',
          cursor: 'pointer',
          paddingLeft: '1rem',
          py: 1,
          marginBottom: '0.5rem',
        }}
        onClick={handleClickToogle}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              fontWeight: theme.typography.fontWeightBold,
              color: theme.palette.primary.dark,
            }}
          >
            {title}
          </Typography>
          <Box
            sx={{
              borderRadius: '50%',
              backgroundColor: theme.palette.secondary.main,
              ml: 2.5,
              p: 0.5,
              px: 0.75,
            }}
          >
            <Typography
              sx={{
                fontWeight: theme.typography.fontWeightBold,
                fontSize: '10px',
                color: '#fff',
              }}
            >
              {amount}
            </Typography>
          </Box>
        </Box>
        <Typography
          sx={{
            color: theme.palette.secondary[600],
            paddingX: '1rem',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {expanded ? <ExpandLessOutlined /> : <ExpandMoreOutlined />}
        </Typography>
      </Box>
      {expanded && (
        <Box
          sx={{
            paddingLeft: '1rem',
          }}
        >
          {children}
        </Box>
      )}
    </Box>
  );
};

export default Accordion;
