import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../../../../../hooks/useRedux';
import SearchPaginatedTable from '../../../../../../shared/components/tables/SearchPaginatedTable';
import { updateTasksTable } from '../../../../slices/investigationLinesSlice';
import { useGetTasksByProjectStageQuery } from '../../../../slices/InvestigationLinesApiSlice';
import { useTableSearch } from '../../../../../../../hooks/useTableSearch';
import { Project } from '../../../../interfaces/projectResearch.interfaces';
import AddButton from '../../../../../../shared/components/tables/buttons/AddButton';
import { useState } from 'react';
import ResearchLinesDialog from '../../../ExtendDialog';
import TaskForm from './TaskForm';
import EditButton from '../../../../../../shared/components/tables/buttons/EditButton';
import StatusChip from '../../../../../../shared/components/badges/StatusChip';
import DeleteTaskDialog from './DeleteTaskDialog';
import DeleteButton from '../../../../../../shared/components/tables/buttons/DeleteButton';
import { IconButton } from '@material-ui/core';
import { IconCheckupList, IconUsers } from '@tabler/icons';
import TaskActivitiesDialog from './TaskActivities';
import TaskStudentsDialog from './TaskStudents';

interface Props {
  data: Project;
}

const ApplicationProjectsTable = ({ data: dataProject }: Props) => {
  const { t } = useTranslation();

  const { currentProfile } = useAppSelector((state) => state.access);

  const { projectId } = useParams<{ projectId: string }>();

  const { page, perPage, search, filterArrow } = useAppSelector(
    (state) => state.investigationLines.tasksTable
  );

  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openActivitiesDialog, setOpenActivitiesDialog] = useState(false);
  const [openStudentsAssignDialog, setOpenStudentsAssignsDialog] = useState(false);
  const [taskIdSelected, setTaskIdSelected] = useState<number | null>(null);

  const { setPage, setPerPage, setSearch, setFilterArrow } = useTableSearch({
    page,
    perPage,
    search,
    filterArrow,
    updateFunction: updateTasksTable,
  });

  const { isLoading, error, data, isFetching } = useGetTasksByProjectStageQuery({
    profileId: currentProfile?.profileId!,
    page,
    perPage,
    search,
    projectId,
    ...(filterArrow && {
      type_sort: filterArrow?.direction,
      sort: filterArrow?.name,
    }),
  });

  const datafetch =
    data?.data?.map((dt, i) => ({
      id: i + ((page - 1) * perPage + 1),
      // name: dt.project_name,
      title: dt.tas_title,
      observation: dt.tas_observation,
      budget: dt.tas_budget,
      numberHours: dt.tas_number_hours,
      startDate: dt.tas_start_date,
      endDate: dt.tas_end_date,
      numActivities: dt.activity_project_research.length,
      statusId: dt.status?.acronym || '',
      options: dt,
    })) || [];

  const handleAddTask = () => {
    setOpenAddDialog(true);
  };

  const handleEditTask = (taskId: number | null) => {
    setTaskIdSelected(taskId);
    setOpenEditDialog(true);
  };
  const handleDeleteTask = (taskId: number | null) => {
    setTaskIdSelected(taskId);
    setOpenDeleteDialog(true);
  };

  const handleOpenActivities = (taskId: number | null) => {
    setTaskIdSelected(taskId);
    setOpenActivitiesDialog(true);
  };

  const handleOpenAssignStudentTask = (taskId: number | null) => {
    setTaskIdSelected(taskId);
    setOpenStudentsAssignsDialog(true);
  };

  return (
    <>
      <SearchPaginatedTable
        data={datafetch!}
        headers={{
          id: 'N°',
          title: t('Título'),
          observation: t('Observación'),
          budget: t('Presupuesto'),
          numberHours: t('Horas'),
          startDate: t('F. inicio'),
          endDate: t('F. fin'),
          numActivities: t('N° actividades'),
          statusId: t('Estado'),
          options: t('academic.areas.options'),
        }}
        keyExtractor={(item) => item.id.toString()}
        searchPlacehoder={t('academic.areas.search')}
        isLoading={isLoading}
        isFetching={isFetching}
        error={error}
        perPage={perPage!}
        setPerPage={setPerPage}
        total={data?.total || 0}
        page={page!}
        setPage={setPage}
        numHeader={4}
        search={search!}
        setSearch={setSearch}
        customRenderers={{
          options: (row) => (
            <>
              <EditButton handleEdit={() => handleEditTask(row.options.id)} />
              <DeleteButton handleDelete={() => handleDeleteTask(row.options.id)} />

              <IconButton onClick={() => handleOpenActivities(row.options.id)}>
                <IconCheckupList />
              </IconButton>

              <IconButton onClick={() => handleOpenAssignStudentTask(row.options.id)}>
                <IconUsers />
              </IconButton>
            </>
          ),
          statusId: (row) => <StatusChip statusId={row.options.status?.id} />,
        }}
        ActionButtons={
          <>
            <AddButton onClick={handleAddTask} />
          </>
        }
        customDataCellsProperties={{
          id: { align: 'center' },

          statusId: {
            align: 'center',
          },

          options: {
            align: 'center',
          },
          // index: {
          //   align: 'center',
          // },
        }}
        customHeadersCellsProperties={{
          options: {
            align: 'center',
          },
          // index: {
          //   align: 'center',
          // },
        }}
        listFilterArrows={
          {
            // statusId: 'current_status',
          }
        }
        filterArrow={filterArrow}
        setFilterArrow={setFilterArrow}
      />

      {openAddDialog && (
        <ResearchLinesDialog
          open
          handleCloseDialog={() => setOpenAddDialog(false)}
          title="Agregar tarea"
        >
          <TaskForm />
        </ResearchLinesDialog>
      )}
      {openEditDialog && taskIdSelected && (
        <ResearchLinesDialog
          open
          handleCloseDialog={() => {
            setOpenEditDialog(false);
            setTaskIdSelected(null);
          }}
          title="Editar tarea"
        >
          <TaskForm taskToEdit={data?.data?.find((task) => task.id === taskIdSelected)} />
        </ResearchLinesDialog>
      )}
      {openDeleteDialog && taskIdSelected && (
        <DeleteTaskDialog
          taskId={taskIdSelected}
          handleClose={() => {
            setTaskIdSelected(null);
            setOpenDeleteDialog(false);
          }}
        />
      )}
      {openActivitiesDialog && taskIdSelected && (
        <TaskActivitiesDialog
          open
          handleClose={() => setOpenActivitiesDialog(false)}
          activities={
            data?.data?.find((task) => task.id === taskIdSelected)?.activity_project_research || []
          }
          taskId={taskIdSelected}
        />
      )}

      {openStudentsAssignDialog && taskIdSelected && (
        <TaskStudentsDialog
          open
          handleClose={() => setOpenStudentsAssignsDialog(false)}
          projectId={projectId}
          taskId={taskIdSelected}
        />
      )}
    </>
  );
};

export default ApplicationProjectsTable;
