import { FC, useState } from 'react';

// material-ui
import {
  Button,
  // Step,
  // Stepper,
  Stack,
  Grid,
  // useTheme,
  // useMediaQuery,
  // StepButton,
} from '@material-ui/core';
// import { useHistory, useLocation } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
// import { IconSend } from '@tabler/icons';

// project imports
import AnimateButton from '../../../../shared/components/extended/AnimateButton';

import {
  SendDebtsWizard,
  SendPaysWizard,
  UpdateBillsWizard,
  WizardKeywords,
  steps,
} from '../../constants/helpers';
import SendDebts from './SendDebts';
import SendPays from './SendPays';
import UpdateBills from './UpdateBills';

function getStepContent(
  step: WizardKeywords

  //   handleValidStep: (param: boolean) => void,
  //go step back
  //   handleBack: () => void,
  //go next step
  //   handleNext: () => void,
  // set index error
  //   setErrorIndex: (i: number | null) => void,
  // set total amount
  // handleTotalAmount: (amount: number) => void,
  // open Client Side Dialog
  //   handleOpenClientSideDialog: () => void
) {
  switch (step) {
    case SendDebtsWizard:
      return <SendDebts />;
    case SendPaysWizard:
      return <SendPays />; //<SendPays />;
    case UpdateBillsWizard:
      return <UpdateBills />; //<UpdateBills />;

    default:
      throw new Error('Unknown step');
  }
}

//-----------------------|| FORMS WIZARD - BASIC ||-----------------------//
interface Props {
  keyword: WizardKeywords;
}

const ContificoMigrationsWizard: FC<Props> = (props) => {
  const { keyword } = props;

  const { t } = useTranslation();

  // const theme = useTheme();
  // const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));

  const activeStep = steps.findIndex((item) => item === keyword);

  //   const history = useHistory();
  //   const location = useLocation();

  // const { selectedDebtsId} = useAppSelector((state) => state.payments);
  // const { isPositiveBalanceTransac } = useAppSelector((state) => state.debtsPayment);
  const [validStep, setValidStep] = useState<boolean>();

  // const [errorIndex, setErrorIndex] = useState<number | null>(null);

  //   const handleSetError = (i: number | null) => {
  //     setErrorIndex(i);
  //   };

  //   const handleActiveStep = (step: number) => {
  //     setActiveStep(step);
  //   };

  const handleNext = () => {
    // setActiveStep(activeStep + 1);
    // setErrorIndex(null);
  };

  // const handleBack = () => {
  //   setActiveStep(activeStep - 1);
  // };

  // const handleSelecteFees = (fees: number[]) => {
  //   // setSelectedFees([...fees]);
  // };

  const handleValidStep = (param: boolean) => {
    setValidStep(param);
  };
  console.log(handleValidStep);

  // const handleStep = (step: number) => () => {
  //   console.log({ path, step });
  //   history.replace(`${path.replace(':step', steps[step])}`);
  //   // setActiveStep(step);
  // };

  // const handleTotalAmount = (amount: number) => {
  //   // setTotalAmount(amount);
  // };

  //   useEffect(() => {
  //     if (!selectedDebts.length) history.push(`/${location.pathname.split('/')[1]}`);
  //   }, [history, location.pathname, selectedDebts.length]);

  return (
    <Grid container>
      {/* <Grid container justifyContent={'center'}>
        <Grid
          item
          xs={12}
          // md={activeStep !== 2 || isPositiveBalanceTransac ? 10 : 12}
          // lg={activeStep !== 2 || isPositiveBalanceTransac ? 7 : 12}
        >
          <Stepper
            nonLinear
            activeStep={activeStep}
            sx={{ pt: 3, pb: 5 }}
            orientation={matchDownSM ? 'vertical' : 'horizontal'}
          >
            {steps.map((label, index) => {
              //   const labelProps: { error?: boolean; optional?: React.ReactNode } = {};

              //   if (index === errorIndex) {
              //     labelProps.optional = (
              //       <Typography variant="caption" color="error">
              //         {t('financial.debts_payment.wizard.steps_error')}
              //       </Typography>
              //     );

              //     labelProps.error = true;
              //   }

              return (
                <Step key={label}>
                  <StepButton
                    color="inherit"
                    onClick={handleStep(index)}
                    icon={
                      <IconSend
                        style={
                          index === activeStep
                            ? {
                                backgroundColor: theme.palette.primary.light,
                                borderRadius: '100%',
                                padding: '5px',
                                color: theme.palette.primary.main,
                              }
                            : {}
                        }
                      />
                    }
                  >
                    {t(label)}
                  </StepButton>
                </Step>
              );
            })}
          </Stepper>
        </Grid>
      </Grid> */}
      <Grid item xs={12}>
        <>
          <>
            {getStepContent(
              keyword
              // selectedFees,
              // totalAmount,
              // handleSelecteFees,
              //   handleValidStep
              //   handleBack,
              //   handleNext,
              //   setErrorIndex,
              // handleTotalAmount,
              //   handleOpenClientSideDialog
            )}
            {activeStep < steps.length - 1 && (
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                // justifyContent={activeStep !== 0 ? 'space-between' : 'flex-end'}
              >
                {/* <WarningAlert
                  message={'Las deudas deben ser seleccionadas por orden de fechas de vencimiento.'}
                  sx={{ width: '100%' }}
                /> */}
                {activeStep !== 0 && false && (
                  <Button
                    onClick={() => {
                      // if (activeStep > 1)
                      //   handleBack();
                      // else history.push(`/${location.pathname.split('/')[1]}`);
                    }}
                    sx={{ my: 3, ml: 1 }}
                  >
                    {t('financial.debts_payment.wizard.steps_back_btn')}
                  </Button>
                )}
                {false && (
                  <AnimateButton>
                    <Button
                      variant="contained"
                      onClick={handleNext}
                      sx={{ my: 1, ml: 1 }}
                      disabled={!validStep}
                    >
                      {activeStep === steps.length - 1
                        ? t('financial.debts_payment.wizard.steps_pay_btn')
                        : t('financial.debts_payment.wizard.steps_next_btn')}
                    </Button>
                  </AnimateButton>
                )}
              </Stack>
            )}
          </>
        </>
      </Grid>

      {/* // */}
    </Grid>
  );
};

export default ContificoMigrationsWizard;
