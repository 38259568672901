import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISearchTable } from '../../../../../shared/interfaces/paginatedTable.interface';
import { IQuestion, QuestionsState } from '../interfaces/questions.interface';

const initialState: QuestionsState = {
  questionsList: [],
  questionsIdSelected: null,
  questionsIdToDelete: null,
  questionsSideDialogOpen: false,
  questionsDeleteModalOpen: false,
  questionsTable: {
    page: 1,
    perPage: 5,
    search: '',
  },
  status: 'A',
  scopeIdSelected: null,
  optionsTemplateIdSelected: null,
};

export const questionsSlice = createSlice({
  name: 'questions',
  initialState,
  reducers: {
    setQuestionsList: (state, action: PayloadAction<IQuestion[]>) => {
      state.questionsList = action.payload;
    },
    setQuestionsIdSelected: (state, action: PayloadAction<string | null>) => {
      state.questionsIdSelected = action.payload;
    },
    setQuestionsIdToDelete: (state, action: PayloadAction<string | null>) => {
      state.questionsIdToDelete = action.payload;
    },
    setQuestionsDeleteModalOpen: (state, action: PayloadAction<boolean>) => {
      state.questionsDeleteModalOpen = action.payload;
    },
    setQuestionsTable: (state, action: PayloadAction<ISearchTable>) => {
      state.questionsTable = {
        ...state.questionsTable,
        ...action.payload,
      };
    },
    setQuestionsSideDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.questionsSideDialogOpen = action.payload;
    },

    setScopeIdSelected: (state, action: PayloadAction<string | null>) => {
      state.scopeIdSelected = action.payload;
    },
    setOptionsTemplateIdSelected: (state, action: PayloadAction<string | null>) => {
      state.optionsTemplateIdSelected = action.payload;
    },
    setStatusQuestions: (state, action: PayloadAction<string>) => {
      state.status = action.payload;
    },
  },
});

export const {
  setQuestionsList,
  setQuestionsIdSelected,
  setQuestionsIdToDelete,
  setQuestionsDeleteModalOpen,
  setQuestionsTable,
  setQuestionsSideDialogOpen,
  setStatusQuestions,
  setScopeIdSelected,
  setOptionsTemplateIdSelected,
} = questionsSlice.actions;
