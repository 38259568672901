import { useState, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import {
  Autocomplete,
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Skeleton,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core';

import SearchIcon from '@material-ui/icons/Search';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';

import { DebounceInput } from 'react-debounce-input';

import AddButton from '../../../shared/components/tables/buttons/AddButton';
import AcademicProgramCard from './cards/AcademicProgramCard';
import CustomPagination from '../../../shared/components/tables/CustomPagination';

import { IconX } from '@tabler/icons';

import { useGetCurriculumsQuery } from '../slices/meshsApiSlice';
import useSharedGuard from '../../../../hooks/useSharedGuard';
import { subPermissionKeys } from '../constants/permissions';
import { useAppSelector } from '../../../../hooks/useRedux';
import { useTableSearch } from '../../../../hooks/useTableSearch';
import { updateCurriculumTable } from '../slices/meshSlice';
import { EducationLevel } from '../../education-levels/interfaces/educationLevels.interfaces';
import { useGetEducationLevelsParentsAndChildrenQuery } from '../../education-levels/slices/educationLevelsApiSlice';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import esLocale from 'date-fns/locale/es';
import { DatePicker, LocalizationProvider } from '@material-ui/lab';
import format from 'date-fns/format';
import { useGetAvaliblesMattersQuery } from '../../matters/slices/mattersApiSlice';
import TextFieldFetching from '../../../shared/components/TextFieldFetching';
import { useGetCatalogByAcronymQuery } from '../../../shared/slices/catalogApi';

const MeshGridView = () => {
  const { currentProfile } = useAppSelector((state) => state.access);

  const [parentEducationLevelId, setParentEducationLevelId] = useState('');

  const [childrenIdByParentId, setChildrenIdByParentId] = useState('');

  const [subjectIdByParentId, setSubjectIdByParentId] = useState('');

  const [mesYear, setMesYear] = useState<Date | null>(null);

  const [childrenByParent = [], setChildrenByParent] = useState<EducationLevel[]>([]);

  const history = useHistory();
  const { path } = useRouteMatch();

  const [searchFirst, setSearchFirst] = useState('');

  const [modality, setModality] = useState('');

  const theme = useTheme();

  const { page, perPage, search } = useAppSelector((state) => state.meshs.curriculumTable);

  const { setPage, setPerPage, setSearch, resetTable } = useTableSearch({
    page,
    perPage,
    search,
    updateFunction: updateCurriculumTable,
  });

  const { isLoading, error, data, isFetching, isError } = useGetCurriculumsQuery({
    page,
    perPage,
    search,
    education_lvl_father_id: Number(parentEducationLevelId),
    education_lvl_id: Number(childrenIdByParentId),
    mes_year: mesYear ? Number(format(mesYear, 'yyyy') as unknown as number) : undefined,
    subject_id: Number(subjectIdByParentId),
    modalityId: modality,
  });

  const { isLoading: isLoadingModality, data: dataModality } = useGetCatalogByAcronymQuery({
    acronym: 'M',
  });

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (newPerPage: number) => {
    setAnchorEl(null);
    setPerPage(newPerPage);
  };

  const { hasAccess } = useSharedGuard(subPermissionKeys);

  useEffect(() => {
    if (page === 1) {
      setSearch(searchFirst);
    }
  }, [page, searchFirst, setSearch]);

  //Education Level Parent
  const {
    data: parentEducationLevel = [],
    isLoading: isLoadingPEL,
    isFetching: isFetchingPEL,
  } = useGetEducationLevelsParentsAndChildrenQuery({
    profileId: currentProfile?.profileId!,
    filter: true,
  });

  //Materias por facultad
  const {
    data: subjectsAvailables = [],
    isLoading: isLoadingSBA,
    isFetching: isFetchingSBA,
  } = useGetAvaliblesMattersQuery({
    profileId: currentProfile?.profileId!,
    educationLevelId: parentEducationLevelId,
  });

  //
  return (
    <Grid container direction="column" spacing={2} mb={2}>
      {/* Actions header */}
      <Grid item xs={12} container>
        {/* Filtros */}
        <Grid container alignItems={'baseline'} spacing={2}>
          {
            <>
              <Grid item xs={12}>
                <Typography
                  variant="h4"
                  component={'span'}
                  sx={{ color: `${theme.palette.primary.main}` }}
                >
                  Filtrar Datos
                </Typography>
              </Grid>

              {/*  filter by parent of education level */}
              {hasAccess('getParentEducationLevel') && (
                <Grid item xs={'auto'}>
                  <Typography
                    component={'span'}
                    sx={{ fontWeight: 500, color: theme.palette.text.primary }}
                  >
                    Unidades académicas y Programas
                  </Typography>
                  {isFetchingPEL && <TextFieldFetching />}
                  {!isFetchingPEL && (
                    <Autocomplete
                      options={parentEducationLevel}
                      loading={isLoadingPEL}
                      loadingText="Cargando unidades académicas y programas..."
                      getOptionLabel={(option) => option.edu_name}
                      onChange={(_, value) => {
                        resetTable();
                        setParentEducationLevelId(value ? value.id.toString() : '');
                        setChildrenByParent(value ? value.children : []);
                        setChildrenIdByParentId('');
                      }}
                      sx={{ width: 310 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Seleccione una unidad académica o programa"
                        />
                      )}
                      fullWidth
                    />
                  )}
                </Grid>
              )}
              {/* filter by modality class */}
              {hasAccess('getModalities') && (
                <Grid item xs={'auto'}>
                  <Typography
                    component={'span'}
                    sx={{ fontWeight: 500, color: theme.palette.text.primary }}
                  >
                    Modalidades
                  </Typography>
                  {isLoadingModality && <TextFieldFetching />}
                  {!isLoadingModality && (
                    <Autocomplete
                      options={dataModality || []}
                      loading={isLoadingModality}
                      loadingText="Cargando unidades académicas y programas..."
                      getOptionLabel={(option) => option.cat_name}
                      onChange={(_, value) => {
                        resetTable();
                        setModality(value ? value.id.toString() : '');
                      }}
                      value={dataModality?.find(({ id }) => id.toString() === modality.toString())}
                      sx={{ width: 310 }}
                      renderInput={(params) => (
                        <TextField {...params} placeholder="Seleccione la modalidad" />
                      )}
                      fullWidth
                    />
                  )}
                </Grid>
              )}

              {/*  filter by childreen of parent */}
              {hasAccess('getCareerAndPrograms') && (
                <Grid item xs={'auto'}>
                  <Typography
                    component={'span'}
                    sx={{ fontWeight: 500, color: theme.palette.text.primary }}
                  >
                    Carreras y programas
                  </Typography>
                  {isLoadingPEL && <TextFieldFetching />}
                  {!isLoadingPEL && (
                    <Autocomplete
                      options={childrenByParent}
                      inputValue={
                        childrenByParent.find((dt) => dt.id === Number(childrenIdByParentId))
                          ?.edu_name || ''
                      }
                      loading={isLoadingPEL}
                      loadingText="Cargando Unidades Académicas..."
                      getOptionLabel={(option) => option.edu_name}
                      sx={{ width: 310 }}
                      onChange={(_, value) => {
                        resetTable();
                        setChildrenIdByParentId(value ? value.id.toString() : '');
                      }}
                      renderInput={(params) => (
                        <TextField {...params} placeholder="Seleccione una unidad académica" />
                      )}
                      fullWidth
                    />
                  )}
                </Grid>
              )}

              {/* Filter by year */}
              <Grid item xs={'auto'}>
                {/* <InputLabel>Año</InputLabel> */}
                <Typography
                  component={'span'}
                  sx={{ fontWeight: 500, color: theme.palette.text.primary }}
                >
                  Año
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                  <DatePicker
                    views={['year']}
                    value={mesYear}
                    maxDate={new Date()}
                    onChange={(newValue) => {
                      resetTable();
                      setMesYear(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        helperText=""
                        placeholder="Seleccione un año"
                        fullWidth
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              {/* Filter by subject */}
              {hasAccess('getSubjects') && (
                <Grid item xs={'auto'}>
                  <Typography
                    component={'span'}
                    sx={{ fontWeight: 500, color: theme.palette.text.primary }}
                  >
                    Materias
                  </Typography>
                  {isFetchingSBA && <TextFieldFetching />}
                  {!isFetchingSBA && (
                    <Autocomplete
                      options={subjectsAvailables}
                      loading={isLoadingSBA}
                      loadingText="Cargando Materias..."
                      getOptionLabel={(option) => option.mat_name}
                      sx={{ width: 310 }}
                      onChange={(_, value) => {
                        resetTable();
                        setSubjectIdByParentId(value ? value.id.toString() : '');
                      }}
                      renderInput={(params) => (
                        <TextField {...params} placeholder="Seleccione una materia" />
                      )}
                      fullWidth
                    />
                  )}
                </Grid>
              )}
            </>
          }
        </Grid>

        <Grid item xs={12} sm={6} sx={{ mb: 2, mt: 2 }}>
          <DebounceInput
            autoFocus
            minLength={2}
            debounceTimeout={300}
            onChange={({ target }) => {
              setPage(1);

              setSearchFirst(target.value);
            }}
            value={search}
            element={(props) => (
              <TextField
                // inputRef={searchFirst}
                // onChange={({ target }) => setSearchFirst(target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon fontSize="small" />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        size="small"
                        onClick={() => setSearch('')}
                        {...(!search && { sx: { cursor: 'initial', opacity: 0 } })}
                      >
                        <IconX size={20} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                placeholder="Buscar malla curricular"
                variant="outlined"
                size="small"
                {...props}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} sx={{ textAlign: 'right' }}>
          {hasAccess('create') && (
            <AddButton size="large" onClick={() => history.push(`${path}/agregar`)} />
          )}
        </Grid>
      </Grid>

      <Grid item xs={12} container spacing={4}>
        {isFetching ? (
          <>
            {Array.from(new Array(perPage)).map((n, index) => (
              <Grid key={index} item xs={12} md={6} lg={4}>
                <Skeleton
                  variant="rectangular"
                  sx={{ borderRadius: 2 }}
                  height={250}
                  width="100%"
                />
              </Grid>
            ))}
          </>
        ) : (
          <>
            {data?.data.map((ap) => (
              <Grid key={ap.id} item xs={12} md={6} lg={4}>
                <AcademicProgramCard
                  apId={ap.id}
                  apName={ap.mes_name}
                  status={ap.status?.st_name || ''}
                  apCode={ap.mes_cod_career}
                  educationLevel={ap.education_level.edu_name}
                  educationLevelFather={ap.education_level.father?.edu_name || ''}
                  apDegree={ap.mes_title}
                  numPeriods={Number(ap.mes_number_period)}
                  numSubjects={Number(ap.mes_number_matter)}
                  modality={ap.modality.cat_name}
                  casResolution={ap.mes_res_cas}
                  ocasResolution={ap.mes_res_ocas}
                  creationDate={ap.mes_creation_date}
                  linkCurriculum={ap?.link_curriculum}
                />
              </Grid>
            ))}
          </>
        )}

        {data?.data.length === 0 && !isFetching && (
          <Grid item xs={12} container justifyContent="center">
            <Typography variant="subtitle1">¡No hay elementos que mostrar!</Typography>
          </Grid>
        )}

        {isError && (
          <Grid item xs={12} container justifyContent="center">
            <Typography variant="subtitle1">{error}</Typography>
          </Grid>
        )}
      </Grid>

      {!isLoading && !error && (
        <Grid
          item
          xs={12}
          container
          justifyContent="space-between"
          alignItems="flex-end"
          sx={{ mt: 4 }}
        >
          <Grid item>
            <CustomPagination
              shape="rounded"
              color="primary"
              count={Math.ceil(data!.total / perPage)}
              page={page}
              onChange={(_, newPage) => {
                setPage(newPage);
              }}
            />
          </Grid>
          <Grid>
            <Box
              component={Button}
              size="large"
              sx={{
                color: 'grey.900',
                backgroundColor: 'primary.light',
                ':hover': { bgcolor: 'primary.light' },
                px: 2,
                borderRadius: 4,
              }}
              endIcon={<ExpandMoreRoundedIcon />}
              onClick={handleClick}
            >
              {perPage} Registros
            </Box>

            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={open}
              onClose={() => handleClose(perPage)}
              variant="selectedMenu"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <MenuItem onClick={() => handleClose(6)}>6 Registros</MenuItem>
              <MenuItem onClick={() => handleClose(8)}>8 Registros</MenuItem>
              <MenuItem onClick={() => handleClose(12)}>12 Registros</MenuItem>
            </Menu>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default MeshGridView;
