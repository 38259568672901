import { Button, CircularProgress, Dialog, Grid, Typography, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { IconCircleCheck, IconFileAlert, IconCalendarEvent } from '@tabler/icons';
import { useEffect } from 'react';
import { useAddOrEditPlanningMutation } from '../../slices/acPlanPostgraduateApiSlice';

const useStyles = makeStyles((theme) => ({
  paper: { maxWidth: '600px', width: '90%' },
}));

interface Props {
  onClose: () => void;
  newPlanningEvent: any;
  planningPayload: any;
  autoHandle?: boolean;
  title?: string;
  subtitle?: string;
  successMessage?: string;
  open: boolean;
  confirmBtnMessage?: string;
  successEvent?: (newPlanningEvent: any, addExtraWeek: boolean) => void;
  /**
   * Custom action al ser exitosa la peticion
   */
  customSuccessAction?: () => void;
  customSuccessBtnText?: string;
}

const AddPlanningPointDialog = ({
  open,
  onClose,
  newPlanningEvent,
  planningPayload,
  autoHandle = false,
  successEvent,
}: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const titleText =
    'Existe una configuración de día de feriado en este punto de inicio de planificación';
  const subtitleText =
    '¿Está seguro de agregar una semana adicional al punto de inicio de planificación?';
  const success = 'Se ha agregado el punto de planificación con éxito';

  const [addOrEditPlanning, { isLoading, isError, isSuccess, error, reset }] =
    useAddOrEditPlanningMutation();

  const handleClick = async (addWeek: boolean = false) => {
    addOrEditPlanning({
      ...planningPayload,
      datePayload: {
        ...planningPayload.datePayload,
        check_day_start_point_planning: addWeek,
      },
    })
      .then((res) => {
        successEvent && successEvent(newPlanningEvent, addWeek);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        reset();

        autoHandle && onClose();
      });
  };

  useEffect(() => {
    if (autoHandle) {
      handleClick(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {}, [isSuccess]);

  return (
    <>
      {!autoHandle && (
        <Dialog
          open={open}
          classes={{ paper: classes.paper }}
          sx={{ p: 3 }}
          onClose={() => {
            onClose();
          }}
        >
          <Grid container gap={4} sx={{ p: 2 }}>
            <Grid item container xs={12} alignItems="center" justifyContent="center">
              {!isError && !isSuccess && !isLoading && (
                <IconCalendarEvent
                  size={60}
                  color={theme.palette.primary.main}
                  style={{
                    padding: 12,
                    backgroundColor: theme.palette.primary.light,
                    borderRadius: '50%',
                  }}
                />
              )}
              {isLoading && <CircularProgress size={50} />}
              {isSuccess && (
                <IconCircleCheck
                  size={60}
                  color={theme.palette.success.main}
                  style={{
                    padding: 12,
                    backgroundColor: theme.palette.success.light,
                    borderRadius: '50%',
                  }}
                />
              )}
              {isError && (
                <IconFileAlert
                  size={60}
                  color={theme.palette.error.main}
                  style={{
                    padding: 12,
                    backgroundColor: theme.palette.orange.light,
                    borderRadius: '50%',
                  }}
                />
              )}
            </Grid>
            <Grid item xs={12} textAlign="center">
              <Typography variant="h2" sx={{ color: theme.palette.primary.main, mb: 1 }}>
                {!isError && !isSuccess && titleText}
                {isSuccess && success}
                {isError && '¡Ha ocurrido un error!'}
              </Typography>
              <Typography variant="body2">
                {!isError && !isSuccess && subtitleText}
                {isError && error}
              </Typography>
            </Grid>
            <Grid item container xs={12} justifyContent="center" gap={1}>
              {!isSuccess && !isError && (
                <Button
                  variant="text"
                  size="large"
                  color="primary"
                  onClick={() => {
                    handleClick(false);
                  }}
                  disabled={isLoading}
                >
                  No
                </Button>
              )}

              {(isSuccess || isError) && (
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  onClick={() => {
                    onClose();
                  }}
                >
                  {'Continuar'}
                </Button>
              )}

              {!isSuccess && !isError && (
                <Button
                  variant="contained"
                  size="large"
                  onClick={() => handleClick(true)}
                  color="primary"
                  autoFocus
                  disabled={isLoading}
                >
                  {'Sí, Agregar'}
                </Button>
              )}
            </Grid>
          </Grid>
        </Dialog>
      )}
    </>
  );
};

export default AddPlanningPointDialog;
