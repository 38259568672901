import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { StudentsProjectDetails } from '../interfaces/projectsByCollaborator.interfaces';
import { Student } from '../interfaces/studentsByProjects.interfaces';
import { ISearchTable } from '../../../shared/interfaces/paginatedTable.interface';
import { ProjectFollow } from '../interfaces/projectFollow.interfaces';

interface followUpOfResearchProjectState {
  teacherId: string | number | null;
  documentIdByBinnacle: string | number | null;
  studentSelected: Student | null;
  approveBinnacleDialog: boolean;
  binnacleSelected: StudentsProjectDetails | null;
  deleteBinnancleDialog: boolean;
  deleteDocumentDialog: boolean;
  documentsBinnancleDialog: boolean;
  addDocumentDialog: boolean;
  projectFollowTable: ISearchTable;
  projectFollowList: ProjectFollow[];
}

const initialState: followUpOfResearchProjectState = {
  teacherId: null,
  studentSelected: null,
  approveBinnacleDialog: false,
  deleteBinnancleDialog: false,
  deleteDocumentDialog: false,
  addDocumentDialog: false,
  documentsBinnancleDialog: false,
  binnacleSelected: null,
  documentIdByBinnacle: null,
  projectFollowList: [],
  projectFollowTable: {
    page: 1,
    perPage: 5,
    search: '',
    filterArrow: undefined,
  },
};

const followUpOfResearchProjectSlice = createSlice({
  name: 'investigationLines',
  initialState,
  reducers: {
    setTeacherIdByFollowUpOfResearchProject: (
      state,
      action: PayloadAction<string | number | null>
    ) => {
      state.teacherId = action.payload;
    },
    setStudentSelectedByFollowUpOfResearchProject: (
      state,
      action: PayloadAction<Student | null>
    ) => {
      state.studentSelected = action.payload;
    },
    setApproveBinnacleDialog(state, action: PayloadAction<boolean>) {
      state.approveBinnacleDialog = action.payload;
    },
    setBinnacleSelected(state, action: PayloadAction<StudentsProjectDetails | null>) {
      state.binnacleSelected = action.payload;
    },
    setDeleteBinnancleDialog(state, action: PayloadAction<boolean>) {
      state.deleteBinnancleDialog = action.payload;
    },
    setDocumentsBinnancleDialog(state, action: PayloadAction<boolean>) {
      state.documentsBinnancleDialog = action.payload;
    },
    setDeleteDocumentDialog(state, action: PayloadAction<boolean>) {
      state.deleteDocumentDialog = action.payload;
    },
    setDocumentIdByBinnacle(state, action: PayloadAction<string | number | null>) {
      state.documentIdByBinnacle = action.payload;
    },
    setAddDocumentDialog(state, action: PayloadAction<boolean>) {
      state.addDocumentDialog = action.payload;
    },
    updateProjectFollowTableStates: (state, action: PayloadAction<ISearchTable>) => {
      state.projectFollowTable = action.payload;
    },
    setProjectFollowList: (state, action: PayloadAction<ProjectFollow[]>) => {
      state.projectFollowList = action.payload;
    },
  },
});

export const {
  setTeacherIdByFollowUpOfResearchProject,
  setApproveBinnacleDialog,
  setStudentSelectedByFollowUpOfResearchProject,
  setBinnacleSelected,
  setDeleteBinnancleDialog,
  setDocumentsBinnancleDialog,
  setDeleteDocumentDialog,
  setDocumentIdByBinnacle,
  setAddDocumentDialog,
  updateProjectFollowTableStates,
  setProjectFollowList,
} = followUpOfResearchProjectSlice.actions;

export default followUpOfResearchProjectSlice.reducer;
