import { Grid, Typography } from '@material-ui/core';
// import { useAppSelector } from '../../../../hooks/useRedux';
import MainCard from '../../../shared/components/cards/MainCard';
// import DeleteRoleDialog from '../components/DeleteRoleDialog';

import UsersTable from '../components/UsersTable';
import { useTranslation } from 'react-i18next';

const RolesScreen = () => {
  const { t } = useTranslation();
  // const { roleIdToDelete } = useAppSelector((state) => state.roles);
  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">{t('configuration.users.title')}</Typography>
      </Grid>
      <UsersTable />
      {/* {roleIdToDelete && <DeleteRoleDialog />} */}
    </Grid>
  );
};

export default RolesScreen;
