import { ButtonProps, IconButton, Tooltip } from '@material-ui/core';
import { Link } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

interface Props extends ButtonProps {
  btnText?: string;
}

const LinkButton = ({ onClick, btnText, ...props }: Props) => {
  const { t } = useTranslation();

  return (
    <Tooltip title={btnText || t('shared.add_button.tooltip')}>
      <IconButton onClick={onClick}>
        <Link />
        {/* {btnText || t('shared.add_button.tooltip')} */}
      </IconButton>
    </Tooltip>
  );
};

LinkButton.defaultProps = {
  btnText: '',
};

export default LinkButton;
