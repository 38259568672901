import { useTranslation } from 'react-i18next';
import Chip from '../extended/Chip';

export interface Status {
  acronym: string;
  name: string;
  color?: string;
}

interface Props {
  acronym: string;
  statusArray: Status[];
  disabled?: boolean;
  variant?: number;
}

const OmniChip = ({ acronym, statusArray, disabled, variant = 1 }: Props) => {
  const { t } = useTranslation();
  console.log(t);
  const status = statusArray.find((status) => status.acronym === acronym);
  return (
    <Chip
      label={status?.name}
      size="small"
      disabled={disabled || false}
      chipcolor={status?.color}
      variant={variant === 0 ? 'filled' : 'outlined'}
    />
  );
};

export default OmniChip;
