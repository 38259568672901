import { Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useAppDispatch } from '../../../../../hooks/useRedux';
import { setEventDrag } from '../../slices/acPlanPostgraduateSlice';
import { MyEvents } from '../../interfaces/events.interfaces';

const useStyles = makeStyles((theme) => ({
  boxContainer: {
    borderRadius: 5,
    backgroundColor: '#fff',
    paddingInline: 16,
    paddingBlock: 10,
    boxShadow: '0px 0px 20px #EAF0F7',
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    flexWrap: 'wrap',
    marginBottom: '1rem',
    border: `1px solid ${theme.palette.primary.main}`,
    maxWidth: 'calc(100vw - 100px)',
  },
  number: {
    fontWeight: 800,
    color: theme.palette.primary.main,
    fontSize: 16,
  },
  title: {
    color: theme.palette.grey[800],
    fontSize: 20,
  },
  simbologyText: {
    fontWeight: 600,
    fontSize: 14,
  },
  square: {
    width: 16,
    height: 16,
    borderRadius: '10px',
  },
}));

interface Props {
  simbologies: {
    color: string;
    label: string;
    index: number;
    typeId: string;
  }[];
  disabled?: boolean;
}

const Simbologies = ({ simbologies, disabled }: Props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const handleDrag = (event: Partial<MyEvents>) => {
    dispatch(setEventDrag(event));
  };

  return (
    <Box className={classes.boxContainer}>
      {simbologies.map((s, i) => (
        <Button
          variant="text"
          draggable
          key={i}
          onDragStart={(e) =>
            handleDrag({
              color: s.color,
              title: s.label,
              id: `${s.index + 1}`,
              typeId: s.typeId,
            })
          }
          disabled={disabled}
          startIcon={
            <Box
              key={i}
              className={classes.square}
              sx={{ bgcolor: s.color, mr: '8px', boxShadow: s.color === '#FFF' ? 2 : '' }}
            ></Box>
          }
        >
          {s.label}
        </Button>
      ))}
    </Box>
  );
};

export default Simbologies;
