import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISearchTable } from '../../../shared/interfaces/paginatedTable.interface';

interface LibraryReservationsSliceState {
  libraryReservationsTable: ISearchTable;
}

const initialState: LibraryReservationsSliceState = {
  libraryReservationsTable: {
    page: 1,
    perPage: 5,
    search: '',
  },
};

const busRouteSlice = createSlice({
  name: 'busRoute',
  initialState,
  reducers: {
    updateLibraryReservationsTable: (state, action: PayloadAction<ISearchTable>) => {
      state.libraryReservationsTable = action.payload;
    },
  },
});

export const { updateLibraryReservationsTable } = busRouteSlice.actions;

export default busRouteSlice.reducer;
