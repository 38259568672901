import { useState } from 'react';
import { Autocomplete, FormHelperText, Grid, TextField } from '@material-ui/core';
import { useFormik } from 'formik';

import ErrorAlert from '../../../../../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../../../../../shared/components/alerts/SuccessAlert';
import LoadingButton from '../../../../../../../shared/components/buttons/LoadingButton';
import { ProjectTaskPayload } from '../../../../../interfaces/tasks.interfaces';
import {
  useAddStudentProjectTaskMutation,
  useGetStudentsByResearchProjectAvailablesQuery,
} from '../../../../../slices/InvestigationLinesApiSlice';
import { studentsAssignTaskValidationSchema } from '../../constants/validationSchemas';
import InputLabel from '../../../../../../../shared/components/extended/Form/InputLabel';

interface ActivityFormProps {
  projectId: string;
  projectTaskToEdit?: ProjectTaskPayload;
  taskId: number;
}

const AssignStudentsForm = ({ projectTaskToEdit, projectId, taskId }: ActivityFormProps) => {
  const [showAlert, setShowAlert] = useState(false);

  const [
    addStudentTask,
    { isLoading: isAddingStudent, isError: isErrorAdd, isSuccess: isSuccessAdd, error: errorAdd },
  ] = useAddStudentProjectTaskMutation();

  const { data: studentsAvailables, isLoading: isLoadingStudents } =
    useGetStudentsByResearchProjectAvailablesQuery({ projectId });

  const { getFieldProps, handleSubmit, errors, touched, setFieldValue, values } = useFormik({
    initialValues: {
      observation: projectTaskToEdit?.observation || '',
      date: projectTaskToEdit?.date || new Date(),
      budget: projectTaskToEdit?.budget || 0,
      numberHours: projectTaskToEdit?.number_hours || 0,
      hasAttendance: true,
      students: projectTaskToEdit?.students || [],
    },
    validationSchema: studentsAssignTaskValidationSchema,
    onSubmit: async (_values) => {
      if (projectTaskToEdit) {
        console.log('test');
      } else {
        await addStudentTask({
          taskId,
          projectId,
          studentProjecTasktPayload: {
            budget: _values.budget,
            date: _values.date,
            has_attendance: _values.hasAttendance,
            number_hours: _values.numberHours,
            observation: _values.observation,
            students: _values.students,
          },
        });
      }

      setShowAlert(true);
    },
  });

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        {isErrorAdd && showAlert && (
          <Grid item xs={12}>
            <ErrorAlert message={errorAdd as string} handleDismiss={() => setShowAlert(false)} />
          </Grid>
        )}

        {isSuccessAdd && showAlert && (
          <Grid item xs={12}>
            <SuccessAlert
              message="Estudiantes asignados correctamente"
              handleDismiss={() => setShowAlert(false)}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <InputLabel>Estudiantes</InputLabel>
          <Autocomplete
            multiple
            options={studentsAvailables || []}
            loading={isLoadingStudents}
            getOptionLabel={(option) => option.pers_full_name}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Ingresar estudiantes"
                error={Boolean(errors.students && touched.students)}
              />
            )}
            value={
              studentsAvailables?.filter(({ student_id }) =>
                values.students.some((e) => e + '' === student_id + '')
              ) || []
            }
            onChange={(e, value) =>
              setFieldValue('students', [
                ...value?.map(({ student_id }) => student_id),
                ...values.students,
              ])
            }
            fullWidth
            clearIcon={null}
          />
          {errors.students && touched.students && (
            <FormHelperText error>{errors.students}</FormHelperText>
          )}
        </Grid>

        {/* {isErrorUpdate && showAlert && (
          <Grid item xs={12}>
            <ErrorAlert message={errorUpdate as string} handleDismiss={() => setShowAlert(false)} />
          </Grid>
        )}

        {isSuccessUpdate && showAlert && (
          <Grid item xs={12}>
            <SuccessAlert
              message="Tarea actualizada correctamente"
              handleDismiss={() => setShowAlert(false)}
            />
          </Grid>
        )} */}

        <Grid item xs={12}>
          <InputLabel htmlFor="title">Observación</InputLabel>
          <TextField
            {...getFieldProps('observation')}
            placeholder="Ingrese el título"
            error={Boolean(errors.observation && touched.observation)}
            fullWidth
          />
          {errors.observation && touched.observation && (
            <FormHelperText error>{errors.observation}</FormHelperText>
          )}
        </Grid>

        <Grid item xs={12}>
          <InputLabel htmlFor="title">Presupuesto</InputLabel>
          <TextField
            {...getFieldProps('budget')}
            placeholder="Ingrese el título"
            error={Boolean(errors.budget && touched.budget)}
            fullWidth
          />
          {errors.budget && touched.budget && (
            <FormHelperText error>{errors.budget}</FormHelperText>
          )}
        </Grid>

        <Grid item xs={12}>
          <InputLabel htmlFor="title">Cantidad de horas</InputLabel>
          <TextField
            {...getFieldProps('numberHours')}
            placeholder="Ingrese el título"
            error={Boolean(errors.numberHours && touched.numberHours)}
            fullWidth
          />
          {errors.numberHours && touched.numberHours && (
            <FormHelperText error>{errors.numberHours}</FormHelperText>
          )}
        </Grid>

        {/* <Grid item xs={12}>
          <InputLabel htmlFor="observation">Observación</InputLabel>
          <TextField
            {...getFieldProps('observation')}
            error={Boolean(errors.observation && touched.observation)}
            placeholder="Ingrese la observación"
            fullWidth
          />
          {errors.observation && touched.observation && (
            <FormHelperText error>{errors.observation}</FormHelperText>
          )}
        </Grid> */}

        <Grid item xs={12}>
          <LoadingButton
            type="submit"
            variant="contained"
            color="primary"
            // isLoading={isAddingStudent || isUpdatingTask}
            isLoading={isAddingStudent}
            title="Guardar"
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default AssignStudentsForm;
