import {
  DepositsTransfersPayload,
  DepositsTransfersStatusPayload,
} from '../interfaces/depositsTransfers.interface';

export const depositsTransfersValues: DepositsTransfersPayload = {
  start_date: new Date(),
  end_date: new Date(),
  approved_dates: true,
  approved_start_date: null,
  approved_end_date: null,
  transaction_dates: true,
  transaction_start_date: null,
  transaction_end_date: null,
  user_id: undefined,
  financial_institution_target_id: undefined,
  payment_way_id: undefined,
  payment_status_id: null,
  search: '',
};
export const payChecksValues: Partial<DepositsTransfersPayload> = {
  start_date: new Date(),
  end_date: new Date(),
  start_date_collection: new Date(),
  end_date_collection: new Date(),
  payment_status_id: 0,
  search: '',
  status_check: '',
};

export const depositsTransfersStatusValues: DepositsTransfersStatusPayload = {
  payments_status_id: 0,
  observation: '',
};
