import DeleteAlert from '../../../shared/components/dialogs/DeleteAlert';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
//import { useDeleteCampusMutation } from '../slices/campusApiSlice';
import { resetClassificationToDelete, setDeleteModalOpen } from '../slices/classificationsSlice';
import { useTranslation } from 'react-i18next';
import { useDeleteClassificationMutation } from '../slices/classificationsApiSlice';

const DeleteClassificationDialog = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { deleteModalOpen, classificationToDelete } = useAppSelector(
    (state) => state.classifications
  );

  //const [deleteAuthor, { isLoading, isSuccess, isError, error }] = useDeleteAuthorMutation();
  const [deleteAuthor, { isLoading, isSuccess, isError, error }] =
    useDeleteClassificationMutation();

  const handleDelete = () => {
    deleteAuthor({
      id: classificationToDelete!,
    });
  };

  const handleClose = () => {
    dispatch(setDeleteModalOpen(false));
    dispatch(resetClassificationToDelete());
  };

  return (
    <DeleteAlert
      open={deleteModalOpen}
      onClose={handleClose}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      handleDelete={handleDelete}
      error={String(error)}
      deleteTitle={t('classifications.delete.title')}
      deleteSuccess={t('classifications.delete.success.message')}
    />
  );
};

export default DeleteClassificationDialog;
