import { Grid } from '@material-ui/core';

import { useState } from 'react';

import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import CalendarPicker from '@material-ui/lab/CalendarPicker';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import { makeStyles } from '@material-ui/styles';
import { useGetTaskRecentQuery } from '../../slices/virtualClassroomTeacherApiSlice';

import { CalendarPickerSkeleton, PickersDay } from '@material-ui/lab';
import CaleandarPopperTeacher from './CalendarPopperTeacher';
import { useAppSelector } from '../../../../../hooks/useRedux';
import { useGetLocaleDateLang } from '../../../../../translation/hooks/useGetLocaleDateLang';
import { getTime, getMonth, getYear, setMonth } from 'date-fns';
const useStyles = makeStyles({
  root: {
    '&.css-3ok8xw': {
      width: '80%',
      color: 'black',
    },

    '& div': {
      justifyContent: 'space-between',
      color: 'black',
    },
    '& div > * ': {
      color: 'black',
    },
    '& .MuiPickersDay-root': {
      color: 'black',
    },
    '& .MuiPickersDay-root.Mui-selected': {
      color: 'white !important',
    },
  },
});

function CalendarComponent() {
  const [date, setDate] = useState(new Date());

  // const [Highlihts] = useState([8, 2, 30, 30, 30, 2]);
  const {
    course: { courseId },
  } = useAppSelector((state) => state.virtualClassroomTeacherShared);
  const estilos = useStyles();

  const { data, isLoading } = useGetTaskRecentQuery({
    año: getYear(date),
    month: getMonth(date) + 1,
    profileId: 4,
    cursoId: courseId!,
  });

  console.log(data);

  // function OnchangeMonth(): number[] {
  //   const FechaTasks = data?.map((item) => moment(item.cact_start_date).date());
  //   return FechaTasks!;
  // }

  const { lang } = useGetLocaleDateLang();
  //data?.map((item) => console.log(item));

  const changeMonthHandler = (month: Date) => {
    if (date) return setDate(setMonth(date, getMonth(month)));

    return setDate(new Date());
  };

  const [openDay, setOpenDay] = useState(-1);
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={lang}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          {isLoading ? (
            <CalendarPickerSkeleton />
          ) : (
            <CalendarPicker
              onMonthChange={changeMonthHandler}
              className={estilos.root}
              date={date}
              onChange={(newDate) => {
                console.log(newDate);
                setDate(newDate!);
              }}
              renderDay={(day, _value, DayComponentProps) => {
                // const isSelected =
                //   !DayComponentProps.outsideCurrentMonth &&
                //   OnchangeMonth()!.indexOf(day.getDate()) > 0;
                // console.log(isSelected);
                const dayNumber = getTime(day);
                return (
                  <CaleandarPopperTeacher
                    key={day.toString()}
                    day={dayNumber}
                    data={data || []}
                    // hasEvents={isSelected}
                    dayDate={day}
                    openDay={openDay}
                    setOpenDay={setOpenDay}
                    PickerDayComponent={PickersDay}
                    dayComponentProps={DayComponentProps}
                  />
                );
              }}
              allowSameDateSelection
            />
          )}
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
}

export default CalendarComponent;
