import { mainApi } from '../../../../api/mainApi';
import { middlewareApi } from '../../../middleware/middlewareApi';
import { setAcademicHourhand } from './academicHourhandSlice';
import { HourhandResponse, TeacherPeriod } from '../interfaces/academicHourhand.interface';
import { Schedule } from '../../../students/academic-hourhands/interfaces/academic-hourhand-interfaces';

export const hourhandTags = middlewareApi.enhanceEndpoints({
  addTagTypes: ['AcademicHourhands'],
});
// Define a service using a base URL and expected endpoints
export const hourhandApi = hourhandTags.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * @GET hourhand student
     */
    getAcademicHourhand: builder.query<
      HourhandResponse[],
      { collaboratorId?: number; user_profile_id?: number }
    >({
      queryFn: async ({ collaboratorId, user_profile_id }, { dispatch }) => {
        try {
          const { data } = await mainApi.get<HourhandResponse[]>(
            `/collaborators/${collaboratorId}/hourhands`,
            {
              params: {
                user_profile_id: user_profile_id,
              },
            }
          );

          dispatch(setAcademicHourhand(data));
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    getTeacherSchedule: builder.query<
      Schedule[],
      { collaboratorId: number; user_profile_id: number; periodId: number }
    >({
      queryFn: async ({ collaboratorId, user_profile_id, periodId }, { dispatch }) => {
        try {
          const { data } = await mainApi.get<Schedule[]>(
            `/collaborator/${collaboratorId}/schedule`,
            {
              params: {
                user_profile_id: user_profile_id,
                period: periodId,
              },
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    getTeacherPeriods: builder.query<
      TeacherPeriod[],
      { collaboratorId: number; user_profile_id: number }
    >({
      queryFn: async ({ collaboratorId, user_profile_id }, { dispatch }) => {
        try {
          const { data } = await mainApi.get<TeacherPeriod[]>(
            `/collaborator/${collaboratorId}/periods`,
            {
              params: {
                user_profile_id: user_profile_id,
              },
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),
  }),
});

export const {
  useGetAcademicHourhandQuery,
  useGetTeacherScheduleQuery,
  useGetTeacherPeriodsQuery,
} = hourhandApi;
