import { Redirect, Switch } from 'react-router-dom';
import { useAppSelector } from '../../../../hooks/useRedux';
import GuardRoute from '../../../../router/guards/GuardRoute';
import { permissionKey, subPermissionKeys } from '../constants/permissions';
import AddCalificationModelScreen from '../screens/AddCalificationModelScreen';
import CalificationModelScreen from '../screens/CalificationModelScreen';
import EditCalificationModelScreen from '../screens/EditCalificationModelScreen';

const CalificationModelRouter = () => {
  const { currentModule } = useAppSelector((state) => state.access);

  const hasPermission = 'calificationModel' in currentModule?.permissions!;

  if (!hasPermission) return <Redirect to="/dashboard" />;
  return (
    <>
      <Switch>
        <GuardRoute
          exact
          path="/administrar-modelo-calificacion"
          component={CalificationModelScreen}
          subPermissionKey={subPermissionKeys.read}
          permissionKey={permissionKey}
        />
        <GuardRoute
          exact
          path="/administrar-modelo-calificacion/agregar"
          component={AddCalificationModelScreen}
          subPermissionKey={subPermissionKeys.create}
          permissionKey={permissionKey}
        />
        <GuardRoute
          exact
          path="/administrar-modelo-calificacion/:calificationModelId/editar"
          component={EditCalificationModelScreen}
          subPermissionKey={subPermissionKeys.edit}
          permissionKey={permissionKey}
        />
      </Switch>
    </>
  );
};

export default CalificationModelRouter;
