import { Route, Switch } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallbackComponentScreen from '../../shared/components/errors-boundary/ErrorFallbackComponentScreen';

import DashboarSocialtrayRouter from '../dashboard/router';
import ManageLeadsRouter from '../manage-leads/router';
import ManageClientsRouter from '../manage-clients/router';
import MarketingImportDataRouter from '../marketing-import-data/router';
import MarketingCampaingsRouter from '../marketing-campaings/router';
import MarketingFormsRouter from '../marketing-forms/router';
import MarketingMailingRouter from '../marketing-mailing/router';
import MarketingTemplatesRouter from '../marketing-templates/router';
import ReportManagementRouter from '../report-management/router';
import AdministrationPermissionsRouter from '../administration-permissions/router';
import AdministrationRolesRouter from '../administration-roles/router';
import AdministrationPermissionRolRouter from '../administration-permission-rol/router';
import AdministrationUsersRouter from '../administration-users/router';
import AdministrationSedesRouter from '../administration-sedes/router';
import AdministrationCompaniesRouter from '../administration-companies/router';

import ConfigContactSourcesRouter from '../config-contact-sources/router/indes';
import ConfigMediumManagamentRouter from '../config-medium-management/router';
import ConfigProductsRouter from '../config-products/router';
import ConfigServicesRouter from '../config-services/router';
import ConfigQuestionsSurveysRouter from '../config-questions-surveys/router';

import AppZoomRouter from "../app-zoom/router";
import AppSlackRouter from '../app-slack/router';


const SocialtrayRouter = () => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallbackComponentScreen}>
      <Switch>
        <Route path="/dashboard-socialtray" component={DashboarSocialtrayRouter} />
        <Route path="/gestionar-leads" component={ManageLeadsRouter} />
        <Route path="/gestionar-clientes" component={ManageClientsRouter} />
        <Route path="/marketing-importar-datos" component={MarketingImportDataRouter} />
        <Route path="/marketing-campanias" component={MarketingCampaingsRouter} />
        <Route path="/marketing-formularios" component={MarketingFormsRouter} />
        <Route path="/marketing-mailing" component={MarketingMailingRouter} />
        <Route path="/marketing-plantillas" component={MarketingTemplatesRouter} />
        <Route path="/reporte-gestion" component={ReportManagementRouter} />
        <Route path="/administracion-permisos" component={AdministrationPermissionsRouter} />
        <Route path="/administracion-roles" component={AdministrationRolesRouter} />
        <Route path="/administracion-permisos-rol" component={AdministrationPermissionRolRouter} />
        <Route path="/administracion-usuarios" component={AdministrationUsersRouter} />
        <Route path="/administracion-sedes" component={AdministrationSedesRouter} />
        <Route path="/administracion-empresas" component={AdministrationCompaniesRouter} />

        <Route path="/configuracion-fuentes-contacto" component={ConfigContactSourcesRouter} />
        <Route path="/configuracion-medios-gestion" component={ConfigMediumManagamentRouter} />
        <Route path="/configuracion-productos" component={ConfigProductsRouter} />
        <Route path="/configuracion-servicios" component={ConfigServicesRouter} />
        <Route path="/configuracion-preguntas-encuestas" component={ConfigQuestionsSurveysRouter} />
        <Route path="/aplicaciones-zoom" component={AppZoomRouter} />
        <Route path="/aplicaciones-slack" component={AppSlackRouter} />

      </Switch>
    </ErrorBoundary>
  );
};

export default SocialtrayRouter;
