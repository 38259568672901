import { Switch } from 'react-router-dom';
import GuardRoute from '../../../../router/guards/GuardRoute';
import MyActivitiesScreen from '../screens/MyActivitiesScreen';

const MyActivitiesRouter = () => {
  return (
    <Switch>
      <GuardRoute exact path="/mis-actividades" component={MyActivitiesScreen} />
    </Switch>
  );
};

export default MyActivitiesRouter;
