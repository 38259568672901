import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IOpeningDate, OpeningDatesState } from '../interfaces/openingDates.interface';

const initialState: OpeningDatesState = {
  openingDatesList: [],
  openingDatesIdSelected: null,
  openingDatesIdToDelete: null,
  openingDatesSideDialogOpen: false,
  openingDatesDeleteModalOpen: false,
  openingDatesTable: {
    page: 1,
    perPage: 5,
    search: '',
  },
};

export const openingDatesSlice = createSlice({
  name: 'openingDates',
  initialState,
  reducers: {
    setOpeningDatesList: (state, action: PayloadAction<IOpeningDate[]>) => {
      state.openingDatesList = action.payload;
    },
    setOpeningDatesIdSelected: (state, action: PayloadAction<string | null>) => {
      state.openingDatesIdSelected = action.payload;
    },
    setOpeningDatesIdToDelete: (state, action: PayloadAction<string | null>) => {
      state.openingDatesIdToDelete = action.payload;
    },
    setOpeningDatesDeleteModalOpen: (state, action: PayloadAction<boolean>) => {
      state.openingDatesDeleteModalOpen = action.payload;
    },
    setOpeningDatesTable: (
      state,
      action: PayloadAction<{ page?: number; perPage?: number; search?: string }>
    ) => {
      state.openingDatesTable = {
        ...state.openingDatesTable,
        ...action.payload,
      };
    },
    setOpeningDatesSideDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.openingDatesSideDialogOpen = action.payload;
    },
  },
});

export const {
  setOpeningDatesList,
  setOpeningDatesIdSelected,
  setOpeningDatesIdToDelete,
  setOpeningDatesDeleteModalOpen,
  setOpeningDatesTable,
  setOpeningDatesSideDialogOpen,
} = openingDatesSlice.actions;
