import React, { useCallback, useEffect, useMemo, useState } from 'react';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  GridProps,
  Theme,
  Accordion as AccordionMui,
  AccordionSummary,
  AccordionDetails,
  AccordionProps,
  Checkbox,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { controlSelectionArr } from '../../helpers';

// assets

// style constant
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography?.pxToRem(16)!,
    fontWeight: theme.typography?.fontWeightRegular!,
  },
}));

//-----------------------|| ACCORDION ||-----------------------//

export type AccordionItem = {
  id: string;
  title: React.ReactElement | string;
  content: React.ReactElement | string;
  disabled?: boolean;
  expanded?: boolean;
  defaultExpand?: boolean | undefined;
};

export interface accordionProps {
  data: AccordionItem[];
  defaultExpandedId?: string | boolean | null;
  expandIcon?: React.ReactElement;
  square?: boolean;
  toggle?: boolean;
  container?: Partial<GridProps>;
  containerChild?: Partial<GridProps>;
  accordionProps?: Partial<AccordionProps>;

  defaultSelectoin?: number[];
  changeSelection?: (selection: number[]) => void;
}

const AccordionWithSelection = ({
  data,
  defaultExpandedId = null,
  toggle,
  expandIcon,
  container,
  containerChild,
  accordionProps,
  defaultSelectoin,
  changeSelection,
}: accordionProps) => {
  const classes = useStyles();

  const [selection, setSelection] = useState<number[]>([]);

  const changeHandler = useCallback(
    (e: any, id: number) => {
      // e.preventDefault();

      const newSelection = controlSelectionArr(selection, id);

      setSelection(newSelection);
      changeSelection?.(newSelection); // igual temrina re renderizando todo con el padre
    },
    [changeSelection, selection]
  );

  useEffect(() => {
    if (!selection.length) setSelection(defaultSelectoin || []);
  }, [defaultSelectoin, selection.length]);

  const content = useMemo(() => {
    return data?.map((item: AccordionItem) => {
      return (
        <Grid container {...container} alignItems="baseline">
          {!!changeSelection && (
            <Grid item xs={'auto'}>
              <Checkbox
                checked={selection.some((schId) => Number(schId) === Number(item.id))}
                onChange={(e) => changeHandler(e, Number(item.id))}
              />
            </Grid>
          )}

          <Grid item xs {...containerChild}>
            <AccordionMui {...accordionProps}>
              <AccordionSummary expandIcon={expandIcon ? expandIcon : <ExpandMoreIcon />}>
                {item.title}
              </AccordionSummary>

              <AccordionDetails>{item.content}</AccordionDetails>
            </AccordionMui>
          </Grid>
        </Grid>
      );
    });
  }, [
    accordionProps,
    changeHandler,
    changeSelection,
    container,
    containerChild,
    data,
    expandIcon,
    selection,
  ]);

  return <div className={classes.root}>{content}</div>;
};

export default React.memo(AccordionWithSelection);
