import { FC, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import {
  Grid,
  Typography,
  TextField,
  Button,
  FormHelperText,
  useTheme,
  CircularProgress,
  Link,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';

import { DetailsProps } from '../../constants/types';
import InputLabel from '../../../../shared/components/forms/InputLabel';
import { openRejectAppModalWith } from '../../slices/GeneralApplicationsSlice';
import { ChangeStatusINGVIB } from '../../constants/validation-schemas';
import { useAppSelector } from '../../../../../hooks/useRedux';
import { AppLinkDetail, ProjectResearch } from '../../interfaces/generalApplications.interfaces';
import { useUpdateLinkApplicationMutation } from '../../slices/GeneralApplicationsApiSlice';
import { firstLetterUppercase } from '../../../../shared/helpers';
import { baseUrl } from '../../../../../api/mainApi';
import { DatePicker, LocalizationProvider } from '@material-ui/lab';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import { useGetLocaleDateLang } from '../../../../../translation/hooks/useGetLocaleDateLang';
import { isAppChangeStatusAvailable } from '../../helpers';
import { subPermissionKeys } from '../../constants/permissions';
import useSharedGuard from '../../../../../hooks/useSharedGuard';

//ingreso de vinculacion
const LinkIngress: FC<DetailsProps> = (props) => {
  const {
    details: detailsProp,
    spacing,
    appCode,
    appId,
    appDescription,
    currentStatus,
    nextStatus,
    updateAlertHandler,
    updateStatusHandler,
  } = props;
  const details = detailsProp as AppLinkDetail;

  const isNotAvailable = !isAppChangeStatusAvailable(currentStatus);

  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const { hasAccess } = useSharedGuard(subPermissionKeys);

  const { currentProfile } = useAppSelector((state) => state.access);

  const [
    updateApplication,
    {
      isLoading: isUpdating,
      error: updateError,
      isError: isErrorUpdate,
      isSuccess: isSuccessUpdate,
    },
  ] = useUpdateLinkApplicationMutation();

  const { lang } = useGetLocaleDateLang();

  const { handleSubmit, getFieldProps, errors, touched, validateField, values, setFieldValue } =
    useFormik({
      initialValues: {
        comment: '',
        startDate: new Date(),
        endDate: new Date(),
        totalHours: '0',
      },
      validationSchema: ChangeStatusINGVIB,
      onSubmit: async (values) => {
        await updateApplication({
          profileId: currentProfile?.profileId!,
          ApplicationId: appId,
          applicationPayload: {
            app_code: appCode,
            comment: values.comment,
            start_date: values.startDate,
            end_date: values.endDate,
            total_hours: values.totalHours,
          },
        });
        updateAlertHandler(true);
      },
    });

  useEffect(() => {
    if (isErrorUpdate) updateStatusHandler({ state: 'error', msg: updateError as string });
    if (isSuccessUpdate)
      updateStatusHandler({ state: 'success', msg: 'La solicitud se ha aprobado' });
  }, [isErrorUpdate, updateStatusHandler, updateError, isSuccessUpdate]);

  const handleDelete = (data: { appId: number; code: string; msg: string }) => {
    dispatch(openRejectAppModalWith(data));
  };

  return (
    <Grid container item xs={12}>
      <form onSubmit={handleSubmit}>
        <Grid item mb={1}>
          <InputLabel>{firstLetterUppercase(appDescription)}</InputLabel>
        </Grid>
        <Grid container border="1px solid #eee" borderRadius="12px" p={2} mb={1}>
          <Grid container item xs={12} alignItems={'center'} mb={1}>
            <Grid item xs={'auto'}>
              <Typography variant={'body2'} component={'span'}>
                Nombre del Proyecto:
              </Typography>
            </Grid>

            <Grid item xs={'auto'} lg={6} sx={{ pl: spacing }}>
              <Typography variant={'h5'} component={'span'}>
                {(details['projectResearch'] as ProjectResearch).name}
              </Typography>
            </Grid>
          </Grid>

          <Grid container item xs={12} alignItems={'center'} mb={1}>
            <Grid item xs={'auto'}>
              <Typography variant={'body2'} component={'span'}>
                Descripción del Proyecto:
              </Typography>
            </Grid>

            <Grid item xs={'auto'} lg={6} sx={{ pl: spacing }}>
              <Typography variant={'h5'} component={'span'}>
                {(details['projectResearch'] as ProjectResearch).activity_description}
              </Typography>
            </Grid>
          </Grid>

          <Grid container item xs={12} alignItems={'center'} mb={1}>
            <Grid item xs={'auto'}>
              <Typography variant={'body2'} component={'span'}>
                Estado del Proyecto:
              </Typography>
            </Grid>

            <Grid item xs={'auto'} lg={6} sx={{ pl: spacing }}>
              <Typography variant={'h5'} component={'span'}>
                {(details['projectResearch'] as ProjectResearch).status_project}
              </Typography>
            </Grid>
          </Grid>

          <Grid container item xs={12} alignItems={'center'} mb={1}>
            <Grid item xs={'auto'}>
              <Typography variant={'body2'} component={'span'}>
                Sitio del Proyecto:
              </Typography>
            </Grid>

            <Grid item xs={'auto'} lg={6} sx={{ pl: spacing }}>
              <Typography variant={'h5'} component={'span'}>
                <Link
                  href={`${baseUrl}/show?name=${details[0].value as string}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Ver
                </Link>
              </Typography>
              {/* <Typography variant={'h5'} component={'span'}>
                <a href={details[1].value as string} target="_blank" rel="noopener noreferrer">
                  {details[1].value as string}
                </a>
              </Typography> */}
            </Grid>
          </Grid>

          {/* {((currentRol?.keyword === 'decano' &&
          currentApplication!.current_status.toLowerCase() === 'enviado') ||
          (currentRol?.keyword === 'coordinador-facultad' &&
            (currentApplication!.current_status.toLowerCase() === 'revisado por decanato' ||
              currentApplication!.current_status.toLowerCase() ===
                'revisado por consejo facultad'))) &&
          !isSuccess && ( */}
        </Grid>
        {/* )} */}

        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <InputLabel>Fecha de inicio</InputLabel>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={lang}>
              <DatePicker
                value={values.startDate}
                onChange={(newValue) => {
                  setFieldValue('startDate', newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    helperText=""
                    placeholder="Fecha de inicio"
                    error={Boolean(errors.startDate && touched.startDate)}
                    fullWidth
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={4}>
            <InputLabel>Fecha de fin</InputLabel>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={lang}>
              <DatePicker
                value={values.endDate}
                onChange={(newValue) => {
                  setFieldValue('endDate', newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    helperText=""
                    placeholder="Fecha de fin"
                    error={Boolean(errors.endDate && touched.endDate)}
                    fullWidth
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={4}>
            <InputLabel>Total de horas</InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              {...getFieldProps('totalHours')}
              error={Boolean(errors.totalHours && touched.totalHours)}
            />
            {errors.totalHours && touched.totalHours && (
              <FormHelperText error>
                {errors.totalHours && touched.totalHours ? errors.totalHours : null}
              </FormHelperText>
            )}
          </Grid>

          {hasAccess('changeApplicationStatus') && (
            <>
              <Grid item xs={12}>
                <InputLabel>Comentario</InputLabel>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  error={Boolean(errors.comment && touched.comment)}
                  placeholder="Ingrese el comentario"
                  {...getFieldProps('comment')}
                  disabled={isNotAvailable}
                />
                {errors.comment && touched.comment && (
                  <FormHelperText error>{errors.comment}</FormHelperText>
                )}
              </Grid>
              {/* {((currentRol?.keyword === 'decano' &&
          //   currentApplication!.current_status.toLowerCase() === 'enviado') ||
          //   (currentRol?.keyword === 'coordinador-facultad' &&
          //     (currentApplication!.current_status.toLowerCase() === 'revisado por decanato' ||
          //       currentApplication!.current_status.toLowerCase() ===
          //         'revisado por consejo facultad'))) &&
          //   !isSuccess && ( */}
              <Grid container justifyContent={'flex-end'} mt={2} spacing={2}>
                <Grid item>
                  <Button
                    variant="outlined"
                    size="large"
                    onClick={() => history.push(`/${location.pathname.split('/')[1]}`)}
                  >
                    Cancelar
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    size="large"
                    {...(!isNotAvailable && {
                      sx: { background: theme.palette.error.main, borderColor: '#EDE7F6' },
                    })}
                    onClick={() => {
                      touched.comment = true;
                      validateField('comment').then(() => {
                        if ((touched.comment && errors.comment) || !values.comment) return;
                        handleDelete({ appId, code: appCode, msg: values.comment });
                      });
                    }}
                    disabled={
                      isUpdating || isNotAvailable || isSuccessUpdate || nextStatus === null
                    }
                  >
                    Rechazar
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    color="primary"
                    disabled={
                      isUpdating || isNotAvailable || isSuccessUpdate || nextStatus === null
                    }
                    endIcon={isUpdating && <CircularProgress size={20} />}
                  >
                    Aprobar
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
          {/* )} */}
        </Grid>
      </form>
    </Grid>
  );
};

export default LinkIngress;
