import Skeleton from '@material-ui/core/Skeleton';
import { Grid } from '@material-ui/core';

const InfoEventNewsSkeleton = () => {
  return (
    <Grid container direction="row" spacing={3} style={{marginTop:20 , marginLeft: 20}} gap={3}>
      <Skeleton variant="rectangular" width={300} height={300} sx={{ borderRadius: 2 }} />
      <Skeleton variant="rectangular" width={300} height={300} sx={{ borderRadius: 2 }} />
      <Skeleton variant="rectangular" width={300} height={300} sx={{ borderRadius: 2 }} />
    </Grid>
  );
};

export default InfoEventNewsSkeleton;
