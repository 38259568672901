import React, { FC, useRef, useState } from 'react';

import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import {
  Grid,
  TextField,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  useTheme,
  IconButton,
  InputAdornment,
  Button,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import { useTranslation } from 'react-i18next';

import {
  renderErrorOrEmptyRow,
  renderSkeletonRows,
  renderSkeletonTable,
} from '../../../../shared/helpers/render';
import {
  SearchPersonParam,
  SearchPersonParamResponse,
} from '../../../../shared/interfaces/searchPerson.interface';
import CustomPagination from '../../../../shared/components/tables/CustomPagination';
import { resetDebtsList, resetPayments } from '../../slices/debtsPaymentSlice';
import { useAppDispatch } from '../../../../../hooks/useRedux';

interface Props {
  clientInfo: SearchPersonParamResponse | undefined;
  clientInfoPage: number;
  errorClientInfo: FetchBaseQueryError | SerializedError | undefined;
  isFetchingClientInfo: boolean;
  handleSetClientId: (clientId: number | undefined) => void;
  setClientInfoPage: React.Dispatch<React.SetStateAction<number>>;
  handleSetClientCI: (param: string) => void;
  handleCloseDialog: (e: React.SyntheticEvent) => void;
}

const ClientsTable: FC<Props> = (props) => {
  const {
    clientInfo,
    isFetchingClientInfo,
    errorClientInfo,
    clientInfoPage,
    setClientInfoPage,
    handleSetClientCI,
    handleSetClientId,
    handleCloseDialog,
  } = props;
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const ci = useRef<HTMLInputElement>(null);

  const [selected, setSelected] = useState<number>();

  // const datafetch =
  //   clientInfo?.data!.map((value) => ({
  //     id: value.user.student?.stud_code,
  //     identification: value.pers_identification,
  //     values: value, //nombre && all
  //   })) || [];

  const handleSearch = () => {
    handleSetClientCI(Boolean(ci.current?.value) ? ci.current?.value || '' : ' ');
  };

  const handleSelect = (e: React.SyntheticEvent) => {
    if (selected) {
      handleSetClientId(-1);
      handleSetClientId(selected);
      dispatch(resetPayments());
      dispatch(resetDebtsList());
    }
    handleCloseDialog(e);
    // setSelected(undefined);
  };

  // useEffect(() => {
  //   return () => {
  //     handleSetClientCI('');
  //     setSelected(undefined);
  //   };
  // }, [handleSetClientCI]);

  const renderRow = (clientInfo: SearchPersonParam) => (
    <TableRow
      hover
      className="table-row"
      key={clientInfo.id.toString()}
      sx={{
        cursor: 'pointer',
        backgroundColor: selected === clientInfo.id ? '#eee' : '',
      }}
      onClick={() => {
        setSelected(clientInfo.id);
        // handleSelectclientInfoe(clientInfo.id);
      }}
    >
      <TableCell>{clientInfo.user?.student?.stud_code}</TableCell>
      <TableCell>{clientInfo.pers_identification}</TableCell>
      <TableCell
      // width="30%"
      // align="center"
      // sx={{ borderLeft: 1, borderColor: 'grey.200', paddingX: '10px' }}
      >
        {`${clientInfo.pers_firstname || ''} ${clientInfo.pers_secondname || ''} ${
          clientInfo.pers_first_lastname || ''
        } ${clientInfo.pers_second_lastname || ''}`}
      </TableCell>
    </TableRow>
  );

  const renderRows = () => {
    return <>{clientInfo?.data?.map(renderRow)}</>;
  };

  return (
    <>
      <Grid container alignContent={'flex-end'} spacing={1}>
        <Grid item>
          {/* <InputLabel>Cédula</InputLabel> */}
          <TextField
            inputRef={ci}
            fullWidth
            placeholder={t('financial.debts_payment.client_dialog.table.search_placeholder')}
            onKeyPress={(e) => {
              if (e.key === 'Enter') handleSearch();
            }}
            // sx={{ '>.MuiOutlinedInput-root': { pr: 0 } }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    onClick={handleSearch}
                    sx={{ borderRadius: '12px!important' }}
                  >
                    <SearchIcon fontSize="small" color="primary" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={12}>
          {isFetchingClientInfo ? (
            renderSkeletonTable(4, 6)
          ) : (
            <>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow sx={{ backgroundColor: theme.palette.grey[200] }}>
                      <TableCell>{t('financial.debts_payment.client_dialog.table.code')}</TableCell>
                      <TableCell>
                        {t('financial.debts_payment.client_dialog.table.identification')}
                      </TableCell>
                      <TableCell>{t('financial.debts_payment.client_dialog.table.name')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isFetchingClientInfo ? renderSkeletonRows(5, 3) : renderRows()}

                    {errorClientInfo && renderErrorOrEmptyRow(5, errorClientInfo as string)}
                    {(clientInfo?.data?.length === 0 || !clientInfo) && renderErrorOrEmptyRow(5)}
                  </TableBody>
                </Table>
              </TableContainer>

              {!isFetchingClientInfo && !errorClientInfo && clientInfo && (
                <Grid container justifyContent="space-between" alignItems="flex-end" sx={{ mt: 4 }}>
                  <Grid item>
                    <CustomPagination
                      shape="rounded"
                      color="primary"
                      count={Math.ceil(clientInfo!.last_page)}
                      page={clientInfoPage}
                      onChange={(_, newPage) => {
                        setClientInfoPage(newPage);
                      }}
                    />
                  </Grid>
                </Grid>
              )}
            </>
          )}
        </Grid>

        <Grid container justifyContent={'flex-end'} spacing={1} mt={1}>
          <Grid item>
            <Button variant="outlined" size="large" onClick={(e) => handleCloseDialog(e)}>
              {t('financial.debts_payment.client_dialog.table.cancel_btn')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              size="large"
              disabled={!selected}
              onClick={(e) => handleSelect(e)}
            >
              {t('financial.debts_payment.client_dialog.table.save_btn')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ClientsTable;
