export const posgraduateStatus = [
  {
    value: 'pendiente',
    label: 'posgraduate.status.pending',
  },
  {
    value: 'ejecutada',
    label: 'posgraduate.status.approved',
  },
];
