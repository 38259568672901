import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import Stack from '@material-ui/core/Stack';
import useTheme from '@material-ui/core/styles/useTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { useTranslation } from 'react-i18next';

import PerfectScrollbar from 'react-perfect-scrollbar';
import { renderSkeletonTable } from '../../../shared/helpers/render';
import { useAppSelector } from '../../../../hooks/useRedux';
import { Button } from '@material-ui/core';
import { useGetProfilesEventsNewsQuery } from '../../../maintenance/profiles/slices/profilesApiSlice';
import useFormatDate from '../../../shared/hooks/useFormatDate';

// style constant
const useStyles = makeStyles((theme) => ({
  scrollRoot: {
    '& >div.ps__rail-y': {
      '& :hover': {
        backgroundColor: 'black',
      },
    },

    '& >div.ps__rail-y > div.ps__thumb-y': {
      backgroundColor: theme.palette.primary[200],
      '& :hover': {
        backgroundColor: 'black',
      },
    },
    // '& .scrollbar-container .ps__rail-y:hover > .ps__thumb-y, .scrollbar-container .ps__rail-y:focus > .ps__thumb-y, .scrollbar-container .ps__rail-y.ps--clicking .ps__thumb-y':
    //   { backgroundColor: 'red' },
  },
}));

interface IEvent {
  id: number;
  name: string;
  description: string;
  date: string;
}

interface IItemList {
  event: IEvent;
}

const ItemList = ({ event }: IItemList) => {
  const theme = useTheme();

  const { formatDate } = useFormatDate();

  return (
    <Grid
      item
      key={event.id.toString()}
      borderBottom={`1px solid ${theme.palette.primary[200]}`}
      py={1}
    >
      <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="flex-start">
        <Box>
          <Typography>{event.name}</Typography>
          <FormHelperText
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              lineClamp: 2,
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
            }}
          >
            {event.description}
          </FormHelperText>
          <FormHelperText>{formatDate(event.date)}</FormHelperText>
        </Box>
      </Stack>
    </Grid>
  );
};

const RecentNews = () => {
  const theme = useTheme();
  const classes = useStyles();

  const history = useHistory();

  const { t } = useTranslation();

  const { currentProfile } = useAppSelector((state) => state.access);

  const { data, isLoading } = useGetProfilesEventsNewsQuery({
    page: 1,
    perPage: 5,
    profileId: currentProfile?.keywordId!,
    search: 'notices',
  });
  const datafetch: IEvent[] | undefined = useMemo(() => {
    return (
      data?.data?.map((item) => ({
        id: item.id,
        name: item.title,
        description: item.description,
        date: item.start_date,
      })) || undefined
    );
  }, [data]);

  const itemList = (event: IEvent) => {
    return <ItemList event={event} key={event!.id.toString()} />;
  };

  const rendernewList = () => {
    return <>{datafetch?.map(itemList)}</>;
  };

  const handleGoToNews = () => history.push('/noticias-visualizar');

  return (
    <Box
      sx={{
        border: `1px solid ${theme.palette.primary[200]}`,
        padding: 2,
        height: '100%',
        borderRadius: '12px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography component="h3" variant="h3">
          {t('students.dashboard.recent_news.title')}
        </Typography>
        <Button onClick={handleGoToNews}>{t('students.dashboard.recent_news.title.button')}</Button>
      </Box>

      <>
        {isLoading ? (
          renderSkeletonTable(1, 2)
        ) : (
          <Grid container pt={2} pb={1}>
            <Grid
              item
              xs={12}
              sx={{
                maxHeight: 140,
                overflow: 'hidden',
                [theme.breakpoints.down('md')]: {
                  maxHeight: 280,
                },
              }}
            >
              <PerfectScrollbar component={'div'} className={classes.scrollRoot}>
                <Stack spacing={2}>
                  {(datafetch?.length || 0) > 0 ? (
                    rendernewList()
                  ) : (
                    <Typography component={'p'} align="center">
                      {t('students.dashboard.recent_news.no_data')}
                    </Typography>
                  )}
                </Stack>
              </PerfectScrollbar>
            </Grid>
          </Grid>
        )}
      </>
    </Box>
  );
};

export default RecentNews;
