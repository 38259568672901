import {
  Autocomplete,
  Button,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  TextField,
  Typography,
  Checkbox,
  FormHelperText,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useLazyGetPersonsQuery } from '../../lendings/slices/lendingApiSlice';
import * as yup from 'yup';
import { DatePicker, LocalizationProvider } from '@material-ui/lab';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import { es } from 'date-fns/locale';
import { useRegisterVisitMutation } from '../slices/visitsApiSlice';
import { format } from 'date-fns';
// import { useAppSelector } from '../../../../hooks/useRedux';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import { UserItem } from '../../lendings/interfaces/users.interface';
import CustomAsyncPaginatedSelect from '../../../shared/components/extended/Form/CustomAsyncPaginatedSelect';
import { useAppSelector } from '../../../../hooks/useRedux';

const RegisterVisits = () => {
  const { currentProfile } = useAppSelector((state) => state.access);

  const [register, { isLoading, isSuccess, isError, error, reset }] = useRegisterVisitMutation();

  const schema = yup.object().shape({
    user: yup.string().required('Usuario es requerido'),
    type_user: yup.string().required('Tipo de Usuario es requerido'),
    isStudent: yup.boolean(),
    faculty: yup.string().when('isStudent', {
      is: true,
      then: (schema) => schema.required('Facultad es requerida'),
    }),
    career: yup.string().when('isStudent', {
      is: true,
      then: (schema) => schema.required('Facultad es requerida'),
    }),
    date: yup.string().required('Fecha es requerida'),
    services: yup.array().min(1, 'Servicios son requeridos'),
  });

  const formik = useFormik({
    initialValues: {
      user: '',
      type_user: '',
      profile_id: '',
      isStudent: false,
      faculty: '',
      faculty_id: '',
      career: '',
      //date: '',
      date: new Date(),
      services: new Array<string>(),
      stud_record: '',
    },
    validationSchema: schema,
    onSubmit: (values) => {
      register({
        payload: {
          date_visit: format(new Date(values.date), 'yyyy-MM-dd'),
          person_id: Number(values.user),
          services: values.services.map((s) => ({
            model: s,
          })),
          type_person: values.type_user.toLowerCase() === 'estudiante' ? 'Student' : 'Collaborator',
          type_visit: 'Presencial',
          education_level_id:
            values.type_user.toLowerCase() === 'estudiante' ? Number(values.career) : undefined,
          student_record_id:
            values.type_user.toLowerCase() === 'estudiante'
              ? Number(values.stud_record)
              : undefined,
        },
      });
    },
  });

  const [userValue, setUserValue] = useState<any | null>(null);

  const [getUsersTrigger] = useLazyGetPersonsQuery();

  const getUsers = async (search: string, page: number, perPage: number) => {
    const response = await getUsersTrigger({
      page,
      perPage,
      search,
      user_profile_id: currentProfile?.profileId!,
    }).unwrap();

    const hasMore = response.current_page < response.last_page;

    return {
      options: response.data.map((dt) => {
        return {
          value: dt.id,
          label: dt.pers_full_lastname || '',
          ...dt,
        };
      }),
      hasMore,
    };
  };

  const [options, setOptions] = useState([
    { label: 'Lectura', enum: 'read', value: false },
    { label: 'Libro/Consulta de Tesis', enum: 'book', value: false },
    { label: 'Internet/Computadora', enum: 'computers', value: false },
    { label: 'Cubículo', enum: 'cubicles', value: false },
  ]);

  const handleChangeOption = async (index: number) => {
    const temp = [...options];
    let item = temp[index];
    item.value = !item.value;

    temp[index] = item;
    await setOptions(temp);
    await formik.setFieldValue(
      'services',
      temp.filter((el) => el.value).map((el) => el.enum)
    );
  };

  return (
    <Grid container spacing={4}>
      <Grid item container spacing={2} xs={12} md={6} alignContent="flex-start">
        <Grid item xs={12}>
          <Typography variant="h4">Datos de Usuario</Typography>
        </Grid>
        <Grid item xs={12}>
          <InputLabel>Buscar Usuario</InputLabel>
          <CustomAsyncPaginatedSelect
            key={'id'}
            value={userValue}
            isError={false}
            placeholder="Ingrese (Nombre, Apellido o Cédula)"
            setValue={(value) => {
              formik.setFieldValue('user', (value as any).id);
              setUserValue(value);
            }}
            fetchFunc={getUsers}
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel>Tipo de Usuario</InputLabel>
          <Autocomplete
            options={
              formik.values.user !== ''
                ? (userValue as UserItem)?.user?.user_profiles.map((el) => ({
                    label: el.profile.pro_name,
                    value: el.profile_id,
                  })) || []
                : []
            }
            renderInput={(props) => (
              <TextField
                {...props}
                placeholder="Seleccione Tipo de usuario"
                error={Boolean(formik.errors.type_user && formik.touched.type_user)}
                helperText={
                  Boolean(formik.errors.type_user && formik.errors.type_user) &&
                  formik.errors.type_user
                }
              />
            )}
            onChange={async (_, val) => {
              if (val) {
                await formik.setFieldValue('profile_id', val.value);
                await formik.setFieldValue('type_user', val.label);
                if (val.label.toLowerCase() === 'estudiante') {
                  formik.setFieldValue('isStudent', true);
                }
              } else {
                formik.setFieldValue('profile_id', '');
                formik.setFieldValue('isStudent', false);
              }
            }}
          />
        </Grid>
        {formik.values.isStudent && (
          <>
            <Grid item xs={12}>
              <InputLabel>Carrera</InputLabel>
              <Autocomplete
                options={
                  formik.values.user !== ''
                    ? (userValue as UserItem)?.user?.student?.student_records?.map((el) => ({
                        label: el.education_level?.edu_name,
                        value: el.education_level?.id,
                        faculty: el.education_level?.father?.edu_name,
                        faculty_id: el.education_level?.father?.edu_name,
                        stud_record: el.id,
                      })) || []
                    : []
                }
                renderInput={(props) => (
                  <TextField
                    {...props}
                    error={Boolean(formik.errors.career && formik.touched.career)}
                    placeholder="Seleccione Carrera"
                    helperText={
                      Boolean(formik.errors.career && formik.touched.career) && formik.errors.career
                    }
                  />
                )}
                onChange={async (_, value) => {
                  if (value) {
                    await formik.setFieldValue('career', value.value);
                    await formik.setFieldValue('faculty', value.faculty);
                    await formik.setFieldValue('faculty_id', value.faculty_id);
                    await formik.setFieldValue('stud_record', value.stud_record);
                  }
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Facultad</InputLabel>
              <TextField
                value={formik.values.faculty}
                disabled
                placeholder="Seleccione Carrera"
                error={Boolean(formik.errors.faculty && formik.touched.faculty)}
                helperText={
                  Boolean(formik.errors.faculty && formik.touched.faculty) && formik.errors.faculty
                }
                fullWidth
              />
            </Grid>
          </>
        )}
      </Grid>
      <Grid item container spacing={2} xs={12} md={6} alignContent="flex-start">
        <Grid item xs={12}>
          <Typography variant="h4">Datos de Registro</Typography>
        </Grid>
        <Grid item xs={12}>
          <InputLabel>Fecha</InputLabel>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
            <DatePicker
              value={formik.values.date}
              renderInput={(props) => (
                <TextField
                  {...props}
                  fullWidth
                  error={Boolean(formik.errors.date && formik.touched.date)}
                  helperText={
                    Boolean(formik.errors.date && formik.touched.date) && formik.errors.date
                  }
                />
              )}
              onChange={(val) => {
                if (val) {
                  formik.setFieldValue('date', val);
                }
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12}>
          <InputLabel>Servicios que solicita (Puede seleccionar más de uno)</InputLabel>
          <FormGroup>
            {options.map((opt, index) => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={opt.value}
                    onChange={() => {
                      handleChangeOption(index);
                    }}
                    name={opt.label}
                  />
                }
                label={opt.label}
              />
            ))}
          </FormGroup>
          <FormHelperText error={Boolean(formik.errors.services && formik.touched.services)}>
            {formik.errors.services}
          </FormHelperText>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {isSuccess && (
          <SuccessAlert handleDismiss={reset} message="Se registro la visita correctamente" />
        )}
        {isError && <ErrorAlert handleDismiss={reset} message={String(error)} />}
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          onClick={() => formik.handleSubmit()}
          endIcon={isLoading && <CircularProgress size={20} />}
          disabled={isLoading}
        >
          Registrar
        </Button>
      </Grid>
    </Grid>
  );
};

export default RegisterVisits;
