import { Grid } from '@material-ui/core';
import LoadingChildren from '../../../../shared/components/LoadingChildren';
import SkeletonGrids from '../../../../shared/components/forms/skeletons/SkeletonGrids';
import { useGetCompanyQuery } from '../../../companies/slices/companiesApiSlice';
import ServiceCard from '../ServiceCard';

const ServicesStep = () => {
  const { data, isLoading } = useGetCompanyQuery({});

  return (
    <LoadingChildren
      isLoading={isLoading}
      loadingSkeleton={<SkeletonGrids columns={5} customHeight={100} rows={3} />}
    >
      <Grid container justifyContent="center" spacing={4}>
        {data?.company_services.map((service, idx) => (
          <ServiceCard
            key={`inst-info-company-service-${idx}`}
            description={service.description}
            service={service.service}
            url={service.url}
          />
        ))}
      </Grid>
    </LoadingChildren>
  );
};

export default ServicesStep;
