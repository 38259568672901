import { useEffect, useState } from 'react';
import { useHistory, Link, useRouteMatch } from 'react-router-dom';

import { DebounceInput } from 'react-debounce-input';

import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  // Pagination,
  Grid,
  TextField,
  InputAdornment,
  IconButton,
  // Box,
} from '@material-ui/core';
// import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import { IconX } from '@tabler/icons';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { useGetEducationLevelSubjectsQuery } from '../../education-level-subject/slices/ELSApiSlice';

// import MainCard from '../../../shared/components/cards/MainCard';
import StatusChip from '../../../shared/components/badges/StatusChip';
import EditButton from '../../../shared/components/tables/buttons/EditButton';
import DeleteButton from '../../../shared/components/tables/buttons/DeleteButton';
import AddButton from '../../../shared/components/tables/buttons/AddButton';

import { renderErrorOrEmptyRow, renderSkeletonRows } from '../../../shared/helpers/render';

import { ELS } from '../interfaces/ELS.interfaces';
import { openDeleteModalWith } from '../slices/ELCSlice';

import CustomPaginationAndItems from '../../../shared/components/tables/CustomPaginationAndItems';

const ELSTable = () => {
  const { currentProfile } = useAppSelector((state) => state.access);

  const dispatch = useAppDispatch();

  const { path } = useRouteMatch();
  const history = useHistory();

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const [search, setSearch] = useState('');

  useEffect(() => {
    setPage(1);
  }, [perPage, search]);

  const { isLoading, error, data, isFetching } = useGetEducationLevelSubjectsQuery(
    {
      profileId: currentProfile?.profileId!,
      page,
      perPage,
      search,
    },
    { refetchOnMountOrArgChange: 30 }
  );

  const handleDelete = async (periodId: number) => {
    dispatch(openDeleteModalWith(periodId));
  };

  const renderRow = (p: ELS, index: number) => (
    <TableRow className="table-row" key={p.id}>
      <TableCell align="center" width="5%">
        {index + ((page - 1) * perPage + 1)}
      </TableCell>
      <TableCell width="10%">{p.subject.mat_name}</TableCell>
      <TableCell width="10%">{p.group_area !== null ? p.group_area.arg_name : ''}</TableCell>
      <TableCell width="5%">
        <StatusChip statusId={Number(p.status.id)} />
      </TableCell>
      <TableCell width="10%" align="center">
        <Link to={`${path.replace(':groupId', p.group_nivelation_id)}/${p.id}/editar`}>
          <EditButton />
        </Link>
        <DeleteButton handleDelete={() => handleDelete(p.id)} />
      </TableCell>
    </TableRow>
  );

  const renderRows = () => {
    return <>{data?.data.map(renderRow)}</>;
  };

  const goToAdd = () => {
    history.push('/administrar-carreras-y-materias-de-nivelacion/agregar');
  };

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
        <Grid item xs={12} sm={6}>
          <DebounceInput
            autoFocus={Boolean(search)}
            minLength={2}
            debounceTimeout={300}
            onChange={({ target }) => setSearch(target.value)}
            value={search}
            element={(props) => (
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon fontSize="small" />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        size="small"
                        onClick={() => setSearch('')}
                        {...(!search && { sx: { cursor: 'initial', opacity: 0 } })}
                      >
                        <IconX size={20} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                placeholder="Buscar materia"
                variant="outlined"
                size="small"
                {...props}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} sx={{ textAlign: 'right' }}>
          <AddButton size="large" onClick={goToAdd} />
        </Grid>
      </Grid>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">N°</TableCell>
              <TableCell>Nombre de la materia</TableCell>
              <TableCell>Grupo</TableCell>
              <TableCell>Estado de la materia</TableCell>
              <TableCell align="center">Opciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isFetching ? renderSkeletonRows(perPage, 5) : renderRows()}

            {error && renderErrorOrEmptyRow(5, error as string)}
            {data?.data.length === 0 && renderErrorOrEmptyRow(5)}
          </TableBody>
        </Table>
      </TableContainer>

      <CustomPaginationAndItems
        error={error}
        isLoading={isLoading}
        total={data?.total || 0}
        perPage={perPage}
        page={page}
        setPage={setPage}
        setPerPage={setPerPage}
        perPageBox
        paginationBox
      />
    </>
  );
};

export default ELSTable;
