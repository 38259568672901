import { useEffect, useState } from 'react';

import { useHistory, useRouteMatch } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Autocomplete, Grid, InputLabel, TextField } from '@material-ui/core';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import StatusChip from '../../../shared/components/badges/StatusChip';
import AddButton from '../../../shared/components/tables/buttons/AddButton';
import DeleteButton from '../../../shared/components/tables/buttons/DeleteButton';
import EditButton from '../../../shared/components/tables/buttons/EditButton';
import {
  useGetAllClassroomCapacitiesQuery,
  useGetAvailableClassroomTypesQuery,
  useGetClassroomsQuery,
  useLazyGetAllClassroomsQuery,
} from '../slices/classroomsApiSlice';
import {
  openDeleteModalWith,
  setCampusId,
  setCapacity,
  setClassroomList,
  setClassroomTypeId,
  updateClassroomTableStates,
} from '../slices/classroomsSlices';
import useSharedGuard from '../../../../hooks/useSharedGuard';
import { subPermissionKeys } from '../constants/permissions';
import SearchPaginatedTable, {
  FilterArrow,
} from '../../../shared/components/tables/SearchPaginatedTable';
import { useTableSearch } from '../../../../hooks/useTableSearch';
import { useTranslation } from 'react-i18next';
import { useGetAvailablesCampusQuery } from '../../campus/slices/campusApiSlice';
import { statusAvailables } from '../../../shared/constants/resourceStatus';

const ClassroomTable = () => {
  const { t } = useTranslation();

  const { currentProfile } = useAppSelector((state) => state.access);

  const { hasAccess } = useSharedGuard(subPermissionKeys);

  const dispatch = useAppDispatch();

  const { path } = useRouteMatch();
  const history = useHistory();

  const [filterArrow, setFilterArrow] = useState<FilterArrow>({
    name: 'cl_name',
    direction: 'asc',
  });

  const { page, perPage, search } = useAppSelector((state) => state.classrooms.classroomTable);
  const campusId = useAppSelector((state) => state.classrooms.campusId);
  const classroomTypeId = useAppSelector((state) => state.classrooms.classroomTypeId);
  const capacity = useAppSelector((state) => state.classrooms.capacity);

  const { setPage, setPerPage, setSearch } = useTableSearch({
    page,
    perPage,
    search,

    updateFunction: updateClassroomTableStates,
    otherFilters: {
      campusId,
      classroomTypeId,
      capacity,
    },
  });

  const { data: campuses, isLoading: isLoadingCampus } = useGetAvailablesCampusQuery({
    profileId: currentProfile?.profileId!,
  });

  const { data: classroomTypes, isLoading: isLoadingCT } = useGetAvailableClassroomTypesQuery({
    profileId: currentProfile?.profileId!,
  });

  const { data: classroomsCapacities, isLoading: isLoadingCapacities } =
    useGetAllClassroomCapacitiesQuery({
      profileId: currentProfile?.profileId!,
      ...(campusId && {
        campusId,
      }),
      ...(classroomTypeId && {
        classroomTypeId,
      }),
    });

  const [getAllClassrooom] = useLazyGetAllClassroomsQuery();

  const { isLoading, error, data, isFetching } = useGetClassroomsQuery(
    {
      profileId: currentProfile?.profileId!,
      page,
      perPage,
      search,
      ...(filterArrow && {
        type_sort: filterArrow?.direction,
        sort: filterArrow?.name,
      }),
      ...(campusId && {
        campusId,
      }),
      ...(classroomTypeId && {
        classroomTypeId,
      }),
      ...(capacity && {
        capacity,
      }),
    },
    { refetchOnMountOrArgChange: 30 }
  );

  useEffect(() => {
    if (data) {
      dispatch(setClassroomList(data.data));
    }
  }, [data, dispatch]);

  const handleDelete = async (classroomId: number) => {
    dispatch(openDeleteModalWith(classroomId));
  };

  const goToAdd = () => {
    history.push(`${path}/agregar`);
  };

  const datafetch = data?.data!.map((d, index) => ({
    id: index + ((page - 1) * perPage + 1),
    name: d.cl_name,
    description: d.cl_description,
    capacityMax: d.cl_cap_max,
    campusName: d.campus?.cam_name,
    block: d.cl_block,
    classroomTypeName: d.classroom_type?.clt_name,
    statusId: d.status_id,
    options: d,
  }));

  const getAllDatafetch = async () => {
    try {
      const classroomsData = await getAllClassrooom(
        {
          search,
          ...(filterArrow && {
            type_sort: filterArrow?.direction,
            sort: filterArrow?.name,
          }),
          ...(campusId && {
            campusId,
          }),
          ...(classroomTypeId && {
            classroomTypeId,
          }),
          ...(capacity && {
            capacity,
          }),
        },
        true
      ).unwrap();

      return classroomsData.map((d, index) => ({
        id: index + ((page - 1) * perPage + 1),
        name: d.cl_name,
        description: d.cl_description,
        capacityMax: d.cl_cap_max,
        campusName: d.campus?.cam_name,
        block: d.cl_block,
        classroomTypeName: d.classroom_type?.clt_name,
        statusId:
          statusAvailables.find((status) => status.id === Number(d.status_id))?.name || d.status_id,
      }));
    } catch (error) {
      return [];
    }
  };

  return (
    <Grid container spacing={2}>
      {hasAccess('getCampus') && (
        <Grid item xs={3}>
          <InputLabel>Campus</InputLabel>
          {isLoadingCampus ? (
            <TextField fullWidth variant="outlined" placeholder="Cargando..." disabled />
          ) : (
            <Autocomplete
              options={campuses || []}
              getOptionLabel={(option) => option.cam_name}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" placeholder="Seleccione un campus" />
              )}
              loading={isLoadingCampus}
              loadingText={'Cargando...'}
              value={campuses?.find((c) => c.id === campusId) || null}
              onChange={(e, value) => {
                dispatch(setCampusId(value?.id || null));
              }}
            />
          )}
        </Grid>
      )}
      {hasAccess('getClassroomType') && (
        <Grid item xs={3}>
          <InputLabel>Tipo de clase</InputLabel>
          {isLoadingCT ? (
            <TextField fullWidth variant="outlined" placeholder="Cargando..." disabled />
          ) : (
            <Autocomplete
              options={classroomTypes || []}
              getOptionLabel={(option) => option.clt_name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Seleccione un tipo de clase"
                />
              )}
              loading={isLoadingCT}
              loadingText={'Cargando...'}
              value={classroomTypes?.find((c) => c.id === classroomTypeId) || null}
              onChange={(e, value) => {
                dispatch(setClassroomTypeId(value?.id || null));
              }}
            />
          )}
        </Grid>
      )}
      {hasAccess('getCapacity') && (
        <Grid item xs={3}>
          <InputLabel>Capacidad</InputLabel>
          {isLoadingCapacities ? (
            <TextField fullWidth variant="outlined" placeholder="Cargando..." disabled />
          ) : (
            <Autocomplete
              options={classroomsCapacities || []}
              getOptionLabel={(option) => option.toString()}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" placeholder="Seleccione una capacidad" />
              )}
              loading={isLoadingCapacities}
              loadingText={'Cargando...'}
              value={classroomsCapacities?.find((c) => c === capacity) || null}
              onChange={(e, value) => {
                dispatch(setCapacity(value || null));
              }}
            />
          )}
        </Grid>
      )}
      <Grid item xs={12}>
        <SearchPaginatedTable
          data={datafetch!}
          headers={{
            id: 'N°',
            name: t('academic.classrooms.name'),
            description: t('academic.classrooms.description'),
            capacityMax: t('academic.classrooms.capacity'),
            campusName: t('academic.classrooms.campus'),
            block: t('academic.classrooms.block'),
            classroomTypeName: t('academic.classrooms.class_type'),
            statusId: t('academic.classrooms.status'),
            options: t('academic.classrooms.option'),
          }}
          exportProps={{
            fileName: t('academic.classrooms.download_list'),
            files: { excel: true },
            // variant: 'buttons',
            // portalId: classroomExportPortalId,
            batch: true,
            allDataFetchHandler: getAllDatafetch,
          }}
          keyExtractor={(item) => item.id.toString()}
          listFilterArrows={{
            name: 'cl_name',
            description: 'cl_description',
            capacityMax: 'cl_cap_max',
            // campusName: 'campus.cam_name',
            // block: 'cl_block',
            // classroomTypeName: 'classroom_type.clt_name',
            statusId: 'status_id',
          }}
          filterArrow={filterArrow}
          setFilterArrow={setFilterArrow}
          searchPlacehoder={t('academic.classrooms.search')}
          isLoading={isLoading}
          isFetching={isFetching}
          error={error}
          perPage={perPage}
          setPerPage={setPerPage}
          total={data?.total || 0}
          page={page}
          setPage={setPage}
          numHeader={9}
          search={search}
          setSearch={setSearch}
          customHeadersCellsProperties={{
            options: {
              align: 'center',
            },
          }}
          customDataCellsProperties={{
            id: {
              align: 'center',
            },
            name: {
              align: 'center',
            },
            description: {
              align: 'center',
            },
            capacityMax: {
              align: 'center',
            },
            campusName: {
              align: 'center',
            },
            block: {
              align: 'center',
            },
            classroomTypeName: {
              align: 'center',
            },
            statusId: {
              align: 'center',
            },
            options: {
              align: 'center',
            },
          }}
          customRenderers={{
            statusId: (row) => <StatusChip statusId={Number(row.statusId)} />,
            options: (row) => (
              <>
                {hasAccess('edit') && (
                  <Link to={`${path}/${row.options.id}/editar`}>
                    <EditButton />
                  </Link>
                )}
                {hasAccess('delete') && (
                  <DeleteButton handleDelete={() => handleDelete(row.options.id)} />
                )}
                {!hasAccess('edit') && !hasAccess('delete') && t('academic.classrooms.none')}
              </>
            ),
          }}
          ActionButtons={<>{hasAccess('create') && <AddButton size="large" onClick={goToAdd} />}</>}
        />
      </Grid>
    </Grid>
  );
};

export default ClassroomTable;
