import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISearchTable } from '../../../shared/interfaces/paginatedTable.interface';
import { IStudentGraduate, StudentsGraduateState } from '../interfaces/interface';

const initialState: StudentsGraduateState = {
  studentsList: [],
  educationLevelIdSelected: null,
  studentsGraduateTable: {
    page: 1,
    perPage: 5,
    search: '',
  },
  graduatedStudentsTable: {
    page: 1,
    perPage: 5,
    search: '',
  },
  studentGraduateSideDialogOpen: false,
  studentIdSelected: null,
  graduationDateSelected: null,
  tableTypeSelected: 'to_graduate',
  careerIdSelected: null,
  isPaid: false,
};

const studentsGraduateSlice = createSlice({
  name: 'studentsGraduate',
  initialState,
  reducers: {
    setStudentsList: (state, action: PayloadAction<IStudentGraduate[]>) => {
      state.studentsList = action.payload;
    },
    updateStudentsGraduateTable: (state, action: PayloadAction<ISearchTable>) => {
      state.studentsGraduateTable = { ...state.studentsGraduateTable, ...action.payload };
    },
    setEducationLevelIdSelected: (state, action: PayloadAction<number | null>) => {
      state.educationLevelIdSelected = action.payload;
    },
    setStudentGraduateSideDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.studentGraduateSideDialogOpen = action.payload;
    },
    setStudentIdSelected: (state, action: PayloadAction<number | null>) => {
      state.studentIdSelected = action.payload;
    },
    updateGraduatedStudentsTable: (state, action: PayloadAction<ISearchTable>) => {
      state.graduatedStudentsTable = { ...state.graduatedStudentsTable, ...action.payload };
    },
    setTableTypeSelected: (state, action: PayloadAction<'to_graduate' | 'graduated'>) => {
      state.tableTypeSelected = action.payload;
    },
    setGraduationDateSelected: (state, action: PayloadAction<Date | null>) => {
      state.graduationDateSelected = action.payload;
    },
    setCareerIdSelected: (state, action: PayloadAction<number | null>) => {
      state.careerIdSelected = action.payload;
    },
    setIsPaid: (state, action: PayloadAction<boolean>) => {
      state.isPaid = action.payload;
    },
  },
});

export const {
  setStudentsList,
  updateStudentsGraduateTable,
  setEducationLevelIdSelected,
  setStudentGraduateSideDialogOpen,
  setStudentIdSelected,
  updateGraduatedStudentsTable,
  setTableTypeSelected,
  setGraduationDateSelected,
  setCareerIdSelected,
  setIsPaid,
} = studentsGraduateSlice.actions;

export default studentsGraduateSlice.reducer;
