import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../../../hooks/useRedux';
import { useTableSearch } from '../../../../../hooks/useTableSearch';
import DeleteButton from '../../../../shared/components/tables/buttons/DeleteButton';
import SearchPaginatedTable from '../../../../shared/components/tables/SearchPaginatedTable';
import { useGetStudentDebtsQuery } from '../../../../students/payments-history/slices/paymentsApiSlice';
import { updateDebtsTable } from '../../slices/clientSlice';

const DebtsTab = () => {
  const { id, clientId } = useParams<{ id: string; clientId: string }>();

  const { currentProfile, defaultConfiguration } = useAppSelector((state) => state.access);

  const { page, perPage, search, filterArrow } = useAppSelector((state) => state.client.debtsTable);
  const { clientList } = useAppSelector((state) => state.client);

  const client = clientList.find((client) => client.id === Number(id));

  const { setPage, setPerPage, setSearch, setFilterArrow } = useTableSearch({
    page,
    perPage,
    search,
    filterArrow,
    updateFunction: updateDebtsTable,
  });

  const { data, isLoading, isFetching, error } = useGetStudentDebtsQuery({
    profileId: currentProfile?.profileId!,
    page,
    perPage,
    search,
    ...(filterArrow && { sort: filterArrow.name, type_sort: filterArrow.direction }),

    studentId: Number(client?.user?.student?.id || clientId),
  });

  const datafetch =
    data?.data?.map((value, index) => ({
      id: data?.from + index,
      description: value.fees_name_identifier,
      year:
        value.per_current_year && value.per_due_year
          ? `${value.per_current_year}-${value.per_due_year}`
          : '',
      fees_start_date: value.fees_start_date,
      end_data: value.fees_expiration_date,
      //prontopago: value.apply_early_payment,
      fees_net_amount: value.fees_net_amount,
      desc_percent_prontopago: value.apply_early_payment
        ? value.discount_early_payment_is_percent
          ? value.discount_value_early_payment //value.discount_early_payment
          : value.discount_early_payment //value.discount_percent_early_payment
        : '0.00',
      //fees_balance: value.fees_balance,
      fees_payment: value.fees_payment,
      net_paid_value: value.apply_early_payment
        ? value.net_value_early_payment
        : value.fees_balance,
      options: value,
    })) || [];

  return (
    <SearchPaginatedTable
      data={datafetch || []}
      headers={{
        id: 'N°',
        description: 'Deuda',
        year: 'Período lectivo',
        fees_start_date: 'Fecha de inicio de cobro',
        end_data: 'Fecha de vencimiento',
        //prontopago: 'Desc. Prontopago',
        fees_net_amount: `${defaultConfiguration?.currency_symbol} Valor`,
        desc_percent_prontopago: `${defaultConfiguration?.currency_symbol} Descuento`,
        //fees_balance: 'Valor final sin descuento',
        fees_payment: `${defaultConfiguration?.currency_symbol} Abono`,
        net_paid_value: `${defaultConfiguration?.currency_symbol} Valor final`,
        options: 'Opciones',
      }}
      error={error}
      isFetching={isFetching}
      isLoading={isLoading}
      page={page}
      perPage={perPage}
      search={search}
      filterArrow={filterArrow}
      setPage={setPage}
      setPerPage={setPerPage}
      setSearch={setSearch}
      setFilterArrow={setFilterArrow}
      total={data?.total || 0}
      keyExtractor={(item) => item.id.toString()}
      customRenderers={{
        options: (value) => (
          <>
            <DeleteButton handleDelete={() => {}} />
          </>
        ),
      }}
      customDataCellsProperties={{
        id: { align: 'center' },
        description: { align: 'center' },
        year: { align: 'center' },
        fees_start_date: { align: 'center' },
        end_data: { align: 'center' },
        //prontopago: { align: 'center' },
        fees_net_amount: { align: 'center' },
        desc_percent_prontopago: { align: 'center' },
        //fees_balance: { align: 'center' },
        fees_payment: { align: 'center' },
        net_paid_value: { align: 'center' },
        options: { align: 'center' },
      }}
      searchPlacehoder={'Buscar'}
      numHeader={1}
    />
  );
};

export default DebtsTab;
