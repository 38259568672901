import { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/core/Autocomplete';
import Grid from '@material-ui/core/Grid';
import { useFormik } from 'formik';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import DatePicker from '@material-ui/lab/DatePicker';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import esLocale from 'date-fns/locale/es';
import InputLabel from '../../../../../shared/components/forms/InputLabel';
import { PublicationStepsPayload } from '../../../interfaces/publicationsPayload.interfaces';
import { Publication } from '../../../interfaces/publications.interfaces';
import { GeneralDetailsSchema } from '../../../constants/validationSchemas';
import typePublication, { TypePublication } from '../../../constants/typePublication';
import publicationStatus from '../../../constants/publicationStatus';
// import { statusAvailables } from '../../../../../shared/constants/resourceStatus';
import { useGetPrincipalEducationLevelsQuery } from '../../../../education-levels/slices/educationLevelsApiSlice';
import { useGetCatalogByAcronymQuery } from '../../../../../shared/slices/catalogApi';
import TextFieldFetching from '../../../../../shared/components/TextFieldFetching';

interface Props {
  setData: React.Dispatch<React.SetStateAction<PublicationStepsPayload>>;
  setNext: React.Dispatch<React.SetStateAction<number>>;
  isEdit?: boolean;
  dataEdit?: Publication;
}

const GeneralDetailsForm = ({ setData, setNext, isEdit = false, dataEdit }: Props) => {
  const { t } = useTranslation();

  const history = useHistory();

  const handleNext = () => {
    setNext((step) => step + 1);
  };

  const { data: eduLvlAvailables, isLoading: isLoadingEduLvl } =
    useGetPrincipalEducationLevelsQuery({});

  // countries
  const { data: countriesAvailables, isLoading: isLoadingCountries } = useGetCatalogByAcronymQuery({
    acronym: 'PS',
  });

  const { getFieldProps, errors, touched, handleSubmit, setFieldValue, values } = useFormik({
    initialValues: {
      yearPublication: isEdit ? new Date(dataEdit?.year_publication || '') : new Date(),
      title: isEdit ? dataEdit?.title || '' : '',
      code: isEdit ? dataEdit?.code || '' : '',
      details: isEdit ? dataEdit?.details || '' : '',
      eventDate: isEdit ? new Date(dataEdit?.event_date || '') : new Date(),
      typePublication: (isEdit ? dataEdit?.type_publication || '' : '') as TypePublication,
      educationLevelId: isEdit ? Number(dataEdit?.education_level_id) || 0 : 0,
      countryId: isEdit ? Number(dataEdit?.country_id) || 0 : 0,
      cityId: isEdit ? dataEdit?.city_id || '' : '',
      status: isEdit ? dataEdit?.status || 'active' : 'active',
      provinceId: 0,
      publicationStatus: isEdit ? dataEdit?.publication_status || 'PUBLICADO' : 'PUBLICADO',
    },
    validationSchema: GeneralDetailsSchema,
    onSubmit: async ({
      code,
      countryId,
      cityId,
      details,
      educationLevelId,
      eventDate,
      status,
      title,
      typePublication,
      yearPublication,
      publicationStatus,
    }) => {
      setData((data) => ({
        ...data,
        generalDetails: {
          code,
          country_id: countryId,
          city_id: cityId,
          details,
          education_level_id: educationLevelId,
          event_date: eventDate,
          status,
          title,
          type_publication: typePublication,
          date_publication: yearPublication,
          year_publication: yearPublication.getFullYear(),
          publication_status: publicationStatus,
        },
      }));

      handleNext();
    },
  });

  useEffect(() => {
    if (isEdit) {
      const country = countriesAvailables?.find((country) =>
        country.children.some((province) =>
          province.children.some((city) => Number(city.id) === Number(values.cityId))
        )
      )?.id;

      const province = countriesAvailables
        ?.find((itemCountry) => itemCountry.id === country)
        ?.children.find((province) =>
          province.children.some((city) => city.id + '' === values.cityId)
        )?.id;

      setFieldValue('countryId', country);
      setFieldValue('provinceId', province);
    }

    //eslint-disable-next-line
  }, [countriesAvailables]);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction="column">
        <Grid item container xs={12} md={6} spacing={2}>
          <Grid item xs={12} md={6}>
            <InputLabel>{t('publication.GeneralDetailsForm.title.title')}</InputLabel>
            <TextField
              {...getFieldProps('title')}
              error={Boolean(errors.title && touched.title)}
              placeholder={t('publication.GeneralDetailsForm.title.placeholder')}
              fullWidth
            />
            {errors.title && touched.title && (
              <FormHelperText error>{t(errors.title)}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            <InputLabel>{t('publication.GeneralDetailsForm.code.title')}</InputLabel>
            <TextField
              {...getFieldProps('code')}
              error={Boolean(errors.code && touched.code)}
              placeholder={t('publication.GeneralDetailsForm.code.placeholder')}
              fullWidth
            />
            {errors.code && touched.code && <FormHelperText error>{t(errors.code)}</FormHelperText>}
          </Grid>

          <Grid item xs={12} md={6}>
            <InputLabel> {t('publication.GeneralDetailsForm.publicationYear.title')} </InputLabel>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
              <DatePicker
                // views={['year']}
                value={values.yearPublication}
                onChange={(newValue) => {
                  setFieldValue('yearPublication', newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    helperText=""
                    placeholder={t('publication.GeneralDetailsForm.publicationYear.title')}
                    error={Boolean(errors.yearPublication && touched.yearPublication)}
                    fullWidth
                  />
                )}
              />
            </LocalizationProvider>
            {errors.yearPublication && touched.yearPublication && (
              <FormHelperText error>{errors.yearPublication}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12}>
            <InputLabel>{t('publication.GeneralDetailsForm.details.title')}</InputLabel>
            <TextField
              {...getFieldProps('details')}
              error={Boolean(errors.details && touched.details)}
              placeholder={t('publication.GeneralDetailsForm.details.placeholder')}
              fullWidth
            />
            {errors.details && touched.details && (
              <FormHelperText error>{t(errors.details)}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            <InputLabel> {t('publication.GeneralDetailsForm.career.title')} </InputLabel>
            {isLoadingEduLvl ? (
              <TextFieldFetching />
            ) : (
              <Autocomplete
                options={eduLvlAvailables || []}
                loading={isLoadingEduLvl}
                value={eduLvlAvailables?.find(({ id }) => id === values.educationLevelId)}
                loadingText={t('academic.subjects.add.edu_level.loading')}
                noOptionsText={t('publication.GeneralDetailsForm.career.noOptions')}
                getOptionLabel={(option) => option.edu_name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(errors.educationLevelId && touched.educationLevelId)}
                    placeholder={t('publication.GeneralDetailsForm.career.placeholder')}
                  />
                )}
                onChange={(_, value) => {
                  setFieldValue('educationLevelId', value?.id);
                }}
                fullWidth
                clearIcon={null}
              />
            )}
            {errors.educationLevelId && touched.educationLevelId && (
              <FormHelperText error>{errors.educationLevelId}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            <InputLabel> {t('publication.GeneralDetailsForm.typePublication.title')} </InputLabel>
            <Autocomplete
              options={typePublication}
              loadingText={t('academic.subjects.add.edu_level.loading')}
              noOptionsText={t('publication.GeneralDetailsForm.typePublication.noOptions')}
              value={typePublication.find(({ keyword }) => keyword === values.typePublication)}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(errors.typePublication && touched.typePublication)}
                  placeholder={t('publication.GeneralDetailsForm.typePublication.placeholder')}
                />
              )}
              onChange={(_, value) => {
                setFieldValue('typePublication', value?.keyword);
              }}
              fullWidth
              clearIcon={null}
            />
            {errors.typePublication && touched.typePublication && (
              <FormHelperText error>{errors.typePublication}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            <InputLabel> {t('publication.GeneralDetailsForm.countryId.title')} </InputLabel>
            {isLoadingCountries ? (
              <TextFieldFetching />
            ) : (
              <Autocomplete
                options={countriesAvailables || []}
                loading={isLoadingCountries}
                loadingText={t('publication.GeneralDetailsForm.countryId.loading')}
                noOptionsText={t('publication.GeneralDetailsForm.countryId.noOptions')}
                getOptionLabel={(option) => option?.cat_name || ''}
                value={countriesAvailables?.find(({ id }) => id === values.countryId) || null}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(errors.countryId && touched.countryId)}
                    placeholder={t('publication.GeneralDetailsForm.countryId.placeholder')}
                  />
                )}
                onChange={(_, value) => {
                  setFieldValue('countryId', value?.id);
                }}
                fullWidth
                clearIcon={null}
              />
            )}
            {errors.countryId && touched.countryId && (
              <FormHelperText error>{errors.countryId}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            <InputLabel> {t('publication.GeneralDetailsForm.provinceId.title')} </InputLabel>

            {isLoadingCountries ? (
              <TextFieldFetching />
            ) : (
              <Autocomplete
                options={
                  countriesAvailables?.find((item) => item.id === values.countryId)?.children || []
                }
                loading={isLoadingCountries}
                loadingText={t('publication.GeneralDetailsForm.provinceId.placeholder')}
                noOptionsText={t('publication.GeneralDetailsForm.provinceId.noOptions')}
                getOptionLabel={(option) => option.cat_name}
                value={
                  countriesAvailables
                    ?.find((item) => item.id === values.countryId)
                    ?.children?.find(({ id }) => values.provinceId + '' === id + '') || null
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(errors.provinceId && touched.provinceId)}
                    placeholder={t('publication.GeneralDetailsForm.provinceId.placeholder')}
                  />
                )}
                onChange={(_, value) => {
                  setFieldValue('provinceId', value?.id);
                }}
                fullWidth
                clearIcon={null}
              />
            )}
            {errors.provinceId && touched.provinceId && (
              <FormHelperText error>{errors.provinceId}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            <InputLabel> {t('publication.GeneralDetailsForm.cityId.title')} </InputLabel>
            {isLoadingCountries ? (
              <TextFieldFetching />
            ) : (
              <Autocomplete
                options={
                  countriesAvailables
                    ?.find((country) => country.id === values.countryId)
                    ?.children.find((province) => province.id === Number(values.provinceId))
                    ?.children || []
                }
                loading={isLoadingCountries}
                loadingText={t('publication.GeneralDetailsForm.cityId.loading')}
                noOptionsText={t('publication.GeneralDetailsForm.cityId.noOptions')}
                getOptionLabel={(option) => option.cat_name}
                value={
                  countriesAvailables
                    ?.find((country) => country.id === values.countryId)
                    ?.children.find((province) => province.id === Number(values.provinceId))
                    ?.children.find(({ id }) => id + '' === values.cityId + '') || null
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(errors.cityId && touched.cityId)}
                    placeholder={t('publication.GeneralDetailsForm.cityId.placeholder')}
                  />
                )}
                onChange={(_, value) => {
                  setFieldValue('cityId', value?.id);
                }}
                fullWidth
                clearIcon={null}
              />
            )}
            {errors.cityId && touched.cityId && (
              <FormHelperText error>{errors.cityId}</FormHelperText>
            )}
          </Grid>

          {/* <Grid item xs={12} md={6}>
            <InputLabel> {t('publication.GeneralDetailsForm.status.title')} </InputLabel>
            <Autocomplete
              options={statusAvailables}
              loadingText={t('publication.GeneralDetailsForm.status.loading')}
              noOptionsText={t('publication.GeneralDetailsForm.status.noOptions')}
              getOptionLabel={(option) => option.name}
              value={statusAvailables.find(
                ({ acronym }) => acronym?.toLowerCase() === values.status
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(errors.status && touched.status)}
                  placeholder={t('publication.GeneralDetailsForm.status.placeholder')}
                />
              )}
              onChange={(_, value) => {
                setFieldValue('status', value?.acronym?.toLowerCase());
              }}
              fullWidth
              clearIcon={null}
            />
            {errors.status && touched.status && (
              <FormHelperText error>{errors.status}</FormHelperText>
            )}
          </Grid> */}

          <Grid item xs={12} md={6}>
            <InputLabel> {t('Estado de la publicación')} </InputLabel>
            <Autocomplete
              options={publicationStatus}
              loadingText={t('publication.GeneralDetailsForm.status.loading')}
              noOptionsText={t('publication.GeneralDetailsForm.status.noOptions')}
              getOptionLabel={(option) => option.label}
              value={publicationStatus.find(({ keyword }) => keyword === values.publicationStatus)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(errors.status && touched.status)}
                  placeholder={t('publication.GeneralDetailsForm.status.placeholder')}
                />
              )}
              onChange={(_, value) => {
                setFieldValue('publicationStatus', value?.keyword);
              }}
              fullWidth
              clearIcon={null}
            />
            {errors.status && touched.status && (
              <FormHelperText error>{errors.status}</FormHelperText>
            )}
          </Grid>

          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            spacing={2}
            sx={{ mt: 6 }}
          >
            <Grid item xs={6}>
              {false && (
                <Button
                  fullWidth
                  variant="outlined"
                  size="large"
                  color="primary"
                  onClick={() => history.goBack()}
                >
                  {t('academic.subjects.add.back')}
                </Button>
              )}
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                type="submit"
                variant="contained"
                size="large"
                color="primary"
                // disabled={isLoading}
                // endIcon={isLoading && <CircularProgress size={20} />}
              >
                {t('publication.AssignsForm.button.next')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default GeneralDetailsForm;
