import { FormHelperText, Grid } from '@material-ui/core';
import { useFormikContext } from 'formik';
import DragArea from '../../../../../shared/components/files/DragArea';
import { IInitialValuesAssigns } from '../../../interfaces/initialValuesAssigns.interface';

interface Props {
  isLoading: boolean;
  setFile: (value: React.SetStateAction<File>) => void;
}

const UploadFileByAssignsForm = ({ isLoading, setFile }: Props) => {
  const { errors, touched, setFieldValue } = useFormikContext<IInitialValuesAssigns>();

  const onChangeFile = function (event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.files && event.target.files[0]) {
      setFile(event.target.files[0]);

      setFieldValue('publicationDocuments', []);
    }
    setFieldValue('typeFile', event?.target?.files?.[0]?.type || '');
  };

  return (
    <Grid item xs={12}>
      <DragArea typesAccepted=".pdf" handleChange={onChangeFile} isLoading={isLoading} />

      {errors.projectDocuments && touched.projectDocuments && (
        <FormHelperText error>{errors.projectDocuments}</FormHelperText>
      )}

      {errors.typeFile && touched.typeFile && (
        <FormHelperText error>{errors.typeFile}</FormHelperText>
      )}
    </Grid>
  );
};

export default UploadFileByAssignsForm;
