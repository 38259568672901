import { Alert, Box, Grid } from '@material-ui/core';
import ReportIframeViewer from './ReportIframe';
import { Report } from '../interfaces/reports.interface';
import { useTranslation } from 'react-i18next';

const vhTOpx = (value: number) => {
  var w = window,
    d = document,
    e = d.documentElement,
    g = d.getElementsByTagName('body')[0],
    // x = w.innerWidth || e.clientWidth || g.clientWidth,
    y = w.innerHeight || e.clientHeight || g.clientHeight;

  var result = (y * value) / 100;
  return result;
};

interface Props {
  reportView: Report;
}

const ReportViewer = ({ reportView }: Props) => {
  const { t } = useTranslation();
  // console.log(
  //   splitUrl(
  //     'http://reportes.nbserp.com/ReportNBS/Pages/ReportViewer.aspx?rs:Command=Render&amp;rs:Format=HTML5&%2fReporte_Prueba&id_base=10013'
  //     'http://reportes.nbserp.com/ReportNBS/Pages/ReportViewer.aspx?Reporte_Informe_Proceso_Admision&id_base=O5IAXVAS'
  //   )
  // );

  const splitUrl = (url: string) => {
    let reportUrl = '',
      idBase = '',
      serverUrl = '',
      errorMsg = '';

    try {
      // const splittedUrl = url.split('.aspx');
      const splittedUrl = url.split('?');

      // serverUrl = splittedUrl[0] + '.aspx';
      serverUrl = splittedUrl[0];

      const commands = splittedUrl[1].split('&');

      reportUrl = commands[0];
      // idBase = commands[1].split('=')[0]; //key
      idBase = commands[1].split('=')[1]; //key
    } catch (error) {
      console.log('invalid url');
      errorMsg = t('No se ha encontrado la url especificada'); //The specified url was not found
    } finally {
      return { idBase, reportUrl, serverUrl, errorMsg };
    }
  };

  const { idBase, reportUrl, serverUrl, errorMsg } = splitUrl(reportView.rep_url);

  return (
    <Grid container>
      <Grid item xs={12}>
        {!!errorMsg && <Alert severity="error">{errorMsg}</Alert>}
        <Box position="relative" minHeight={'50vh'}>
          <ReportIframeViewer
            reportServer={serverUrl} //"http://reportes.nbserp.com/ReportNBS/Pages/ReportViewer.aspx"
            reportUrl={reportUrl} //"reporte_cuatro"
            showParameters={true}
            parameters={{
              id_base: idBase, //'10013',
              // name: 'ECOTEC',
            }}
            toolbar={true}
            language="en-us"
            width="100%"
            height={`${vhTOpx(75)}px`}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default ReportViewer;
