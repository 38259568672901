import {
   useHistory,
   Link,
   useRouteMatch
} from 'react-router-dom';
import {
   //Autocomplete,
   //Checkbox,
   //Button,
   Grid,
   //Typography,
   //TextField,
   /* TableBody,
   TableCell,
   TableContainer,
   TableHead,
   TableRow,
   Table,
   CircularProgress,
   FormHelperText,
   Collapse,
   Box,
   useTheme, */
   // FormControlLabel,
} from '@material-ui/core';
//import { useState } from 'react';
//import InputLabel from '../../../shared/components/forms/InputLabel';
import EditButton from '../../../shared/components/tables/buttons/EditButton';
import DeleteButton from '../../../shared/components/tables/buttons/DeleteButton';
import AddButton from '../../../shared/components/tables/buttons/AddButton';

//import { openDeleteModalWith } from '../slices/booksSlice';
import useGuard from '../hooks/useGuard';

//import StatusChip from '../../../shared/components/badges/StatusChip';
import SearchPaginatedTable from '../../../shared/components/tables/SearchPaginatedTable';
import StatusChip from '../../../shared/components/badges/StatusChip';
//import { useAppDispatch } from '../../../../hooks/useRedux';

const UsersTable = () => {
   const { hasAccess } = useGuard();
   const { path } = useRouteMatch();
   const history = useHistory();

   const handleDelete = async (periodId: number) => {
      //dispatch(openDeleteModalWith(periodId));
   };

   const goToAdd = () => {
      history.push(`${path}/agregar`);
   };
   const data = [
      {
         id: '001',
         name: 'Usuario1 ',
         rol: 'Administrador',
         sede: 'Centro',
         status: 1,
         options: ''
      },
   ]

   return (
      <>
         <Grid
            container
            spacing={2}
            sx={{ mt: 4 }}
         >
            <Grid
               item
               md={12}
            >
               <SearchPaginatedTable
                  data={data.flatMap(e => [e, e, e])}
                  headers={{
                     id: 'ID',
                     name: 'Nombre',
                     rol: 'Rol',
                     sede: 'Sede',
                     status: 'Estado',
                     options: 'Opciones'
                  }}
                  keyExtractor={(item) => item.id.toString()}
                  searchPlacehoder="Buscar usuario"
                  isLoading={false}
                  isFetching={false}
                  error={undefined}
                  perPage={1}
                  setPerPage={() => { }}
                  total={0}
                  page={1}
                  setPage={() => { }}
                  numHeader={7}
                  search={""}
                  setSearch={() => { }}
                  customRenderers={{
                     // id: (row) => row.id.id,

                     status: (row) => <StatusChip statusId={row.status} />,

                     options: (row) => (
                        <>
                           {hasAccess('edit') && (
                              <Link to={`${path}/editar`}>
                                 <EditButton title="Editar" />
                              </Link>
                           )}
                           {hasAccess('delete') && <DeleteButton handleDelete={() => handleDelete(1)} />}
                           {!hasAccess('edit') && !hasAccess('delete') && 'Ninguna'}
                        </>
                     ),
                  }}
                  ActionButtons={<>{hasAccess('create') && <AddButton size="large" onClick={goToAdd} />}</>}
               />
            </Grid>
         </Grid>
      </>
   )
}

export default UsersTable;