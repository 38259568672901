import { useState } from 'react';

const useExpandAccordion = (data?: boolean) => {
  const [expanded, setExpanded] = useState<boolean>(data || false);
  const handleClickToogle = () => {
    setExpanded(!expanded);
  };
  return {
    expanded,
    handleClickToogle,
  };
};

export default useExpandAccordion;
