export const languageName = [
  {
    id: 'es',
    label: 'Español',
  },
  {
    id: 'en',
    label: 'Ingles',
  },
];

export const periodType = [
  {
    id: 'per',
    label: 'Por período',
  },
];

export const consulateName = [
  {
    id: 'CONSULADO AMERICADO',
    label: 'Consulado Americano',
  },
  {
    id: 'CONSULADO CANADIENSE',
    label: 'Consulado Canadiense',
  },
  {
    id: 'CONSULADO EUROPEO',
    label: 'Consulado Europeo',
  },
  {
    id: 'CONSULADO DE INGLATERRA',
    label: 'Consulado de Inglaterra',
  },
  {
    id: 'CONSULADO DE VISA SHENGZEN',
    label: 'Consulado de Visa Shengzen',
  },
  {
    id: 'EMBAJADA DE ITALIA',
    label: 'Embajada de Italia',
  },
];

export const degreeName = [
  {
    id: 'Phd',
    label: 'Phd',
  },
  {
    id: 'Mgtr',
    label: 'Mgtr',
  },
  {
    id: 'Dr',
    label: 'Dr',
  },
  {
    id: 'Ing',
    label: 'Ing',
  },
  {
    id: 'Lic',
    label: 'Lic',
  },
];
