import {
  Button,
  Grid,
  Autocomplete,
  FormHelperText,
  CircularProgress,
  Box,
  Typography,
  useTheme,
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { useEffect, useState } from 'react';
import InputLabel from '../../../shared/components/forms/InputLabel';
import TextFieldFetching from '../../../shared/components/TextFieldFetching';
import useEnabledSubjectsByFilterOptions from '../hooks/useEnabledSubjectsByFilterOptions';
import MatterEnableCard from './MatterEnableCard';
import IconTelework from '../../../shared/components/illustrations/IconTelework';
import { useTranslation } from 'react-i18next';

const SubjectEnables = function () {
  const { t } = useTranslation();

  const [displayFilter, setDisplayFilter] = useState<boolean>(true);
  const theme = useTheme();
  const {
    periods,
    isLoadingPeriods,
    campus,
    isLoadingCampus,
    jornadas,
    isLoadingJornadas,
    hourhands,
    isLoadingHourhands,
    isFetchingHourhands,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
    values,
    handleResetForm,
    isLoadingFilter,
    enabledSubjectsResponse,
    isError,
  } = useEnabledSubjectsByFilterOptions();

  useEffect(() => {
    if (periods && periods.length === 1) {
      setFieldValue('period_id', periods[0].id);
    }
  }, [periods, setFieldValue]);

  useEffect(() => {
    if (hourhands && hourhands.length === 1) {
      setFieldValue('hourhands', Array.of(hourhands[0].id));
    }
  }, [hourhands, setFieldValue]);

  return (
    <Grid item xs={12} mt={3}>
      <form onSubmit={handleSubmit}>
        {displayFilter && (
          <Grid item xs={12} hidden={!displayFilter}>
            <Grid container direction="column" gap={2}>
              <Grid item container spacing={2} alignItems="center">
                {/* Select period */}
                <Grid item xs={12} md={3} height="15vh" onClick={() => setDisplayFilter(true)}>
                  <InputLabel>{t('students.subjects.available.period')}:</InputLabel>
                  {!isLoadingPeriods ? (
                    <Autocomplete
                      options={Array.isArray(periods) ? periods : []}
                      loading={isLoadingPeriods}
                      noOptionsText={t('students.subjects.available.no_data')}
                      loadingText={t('students.subjects.available.period.loading')}
                      getOptionLabel={(option) => option.per_name}
                      value={periods.find((period) => period.id === values.period_id) || null}
                      //value={periods.find((period) => period.id === values.period_id) || null}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={Boolean(errors.period_id && touched.period_id)}
                          placeholder={t('students.subjects.available.period.placeholder')}
                        />
                      )}
                      onChange={(_, value) => {
                        setFieldValue('period_id', value?.id);
                      }}
                    />
                  ) : (
                    <TextFieldFetching />
                  )}
                  {errors.period_id && touched.period_id && (
                    <FormHelperText error>{t(errors.period_id)}</FormHelperText>
                  )}
                </Grid>

                {/* Select campus */}
                <Grid item xs={12} md={3} height="15vh" onClick={() => setDisplayFilter(true)}>
                  <InputLabel>{t('students.subjects.available.campus')}:</InputLabel>
                  {!isLoadingCampus ? (
                    <Autocomplete
                      options={campus || []}
                      loading={isLoadingCampus}
                      noOptionsText={t('students.subjects.available.no_data')}
                      loadingText={t('students.subjects.available.campus.loading')}
                      getOptionLabel={(option) => option.cam_name}
                      value={campus?.find((campus) => campus.id === values.campus_id) || null}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={Boolean(errors.campus_id && touched.campus_id)}
                          placeholder={t('students.subjects.available.campus.placeholder')}
                        />
                      )}
                      onChange={(_, value) => {
                        setFieldValue('campus_id', value?.id);
                      }}
                    />
                  ) : (
                    <TextFieldFetching />
                  )}
                  {errors.campus_id && touched.campus_id && (
                    <FormHelperText error>{t(errors.campus_id)}</FormHelperText>
                  )}
                </Grid>

                {/* Select hourhand */}
                <Grid item xs={12} md={3} height="15vh" onClick={() => setDisplayFilter(true)}>
                  <InputLabel>{t('students.subjects.available.schedule')}:</InputLabel>
                  {!isFetchingHourhands && (
                    <Autocomplete
                      options={hourhands}
                      loading={isLoadingHourhands}
                      noOptionsText={t('students.subjects.available.schedule.no_data')}
                      loadingText={t('students.subjects.available.schedule.loading')}
                      getOptionLabel={(option) => option.hour_description}
                      value={
                        hourhands.find((hourhand) => hourhand.id === values.hourhands?.[0]) || null
                      }
                      //value={
                      //  hourhands.find((hourhand) => values?.hourhands!.includes(hourhand.id)) ||
                      //  null
                      //}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={Boolean(errors.hourhands && touched.hourhands)}
                          placeholder={t('students.subjects.available.schedule.placeholder')}
                        />
                      )}
                      onChange={(_, value) => {
                        setFieldValue('hourhands', Array.of(value?.id));
                      }}
                    />
                  )}
                  {isFetchingHourhands && <TextFieldFetching />}
                  {errors.hourhands && touched.hourhands && (
                    <FormHelperText error>{errors.hourhands}</FormHelperText>
                  )}
                </Grid>
                {/* Select journally */}
                <Grid item xs={12} md={3} height="15vh" onClick={() => setDisplayFilter(true)}>
                  <InputLabel>{t('students.subjects.available.modality')}:</InputLabel>
                  <Autocomplete
                    options={Array.isArray(jornadas) ? jornadas : []}
                    loading={isLoadingJornadas}
                    loadingText={t('students.subjects.available.modality.loading')}
                    noOptionsText={t('students.subjects.available.modality.no_data')}
                    getOptionLabel={(option) => option.cat_name}
                    value={
                      jornadas.find((jornada) => jornada.id === Number(values.workday)) || null
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={Boolean(errors.workday && touched.workday)}
                        placeholder={t('students.subjects.available.modality.placeholder')}
                      />
                    )}
                    onChange={(_, value) => setFieldValue('workday', value?.id)}
                    fullWidth
                    clearIcon={null}
                  />
                  {errors.workday && touched.workday && (
                    <FormHelperText error>{errors.workday}</FormHelperText>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
        {displayFilter && (
          <Grid
            item
            container
            alignItems="center"
            justifyContent="flex-end"
            spacing={2}
            sx={{ mt: 1 }}
          >
            <Grid item>
              <Button
                size="large"
                onClick={handleResetForm}
                //disabled={!searchBtn}
              >
                {t('students.subjects.available.delete_entries')}
              </Button>
              <Button
                type="submit"
                variant="contained"
                size="large"
                color="primary"
                disabled={isLoadingFilter}
                endIcon={isLoadingFilter && <CircularProgress size={20} />}
              >
                {t('students.subjects.available.search')}
              </Button>
            </Grid>
          </Grid>
        )}
      </form>

      <Grid container mt={2} spacing={2}>
        {!isLoadingFilter ? (
          enabledSubjectsResponse?.length! > 0 || !isError ? (
            enabledSubjectsResponse?.map((enabledSubject) => (
              <MatterEnableCard enableSubject={enabledSubject} />
            ))
          ) : (
            <Box sx={{ width: '100%' }}>
              <Grid container justifyContent="center" alignContent="center">
                <Grid container justifyContent="center" item xs={8} md={6} spacing={2}>
                  <Grid container item xs={12} justifyContent="center">
                    <IconTelework size={300} />
                  </Grid>
                  <Grid item>
                    <Typography variant="body1" textAlign="center" lineHeight={2} component="div">
                      <Typography
                        variant="h3"
                        sx={{ color: theme.palette.primary.main }}
                        component="p"
                        align="center"
                        lineHeight={2}
                      >
                        {t('students.subjects.available.warning')}
                      </Typography>
                      {t('students.subjects.available.no_data')}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          )
        ) : (
          <Grid container alignItems="center" justifyContent="center" height="200px">
            <CircularProgress />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default SubjectEnables;
