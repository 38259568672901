import { CourseStudentStatus } from '../interfaces/students-asigned.interfaces';

export const courseStudentStatus: CourseStudentStatus[] = [
  { id: 10, name: 'Aprobado', color: 'success' },
  { id: 11, name: 'Reprobado', color: 'error' },
  { id: 12, name: 'Retirado', color: 'error' },
  { id: 13, name: 'Cursando', color: 'info' },
];

export const subjectsRegisterStatus: string[] = [
  'Disponible',
  'No disponible',
  'Registrada',
  'Aprobada',
];
