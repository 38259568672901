import * as Yup from 'yup';

export const FinancialInformationSchema = Yup.object().shape({
  identificationType: Yup.string().required('Seleccione un tipo de identificación'),
  identification: Yup.string()
    .required('La identificación es requerida')
    .min(10, 'faltan dígitos')
    .max(13),
  telf: Yup.string().required('El teléfono es requerido').min(6, 'faltan dígitos').max(13),
  email: Yup.string().required('El correo es requerido').email('Correo no válido'),
  address: Yup.string().required('La dirección es requerida'),
  socialReason: Yup.string().required('La razón social es requerida'),
});

export const DepositPaymentSchema = Yup.object().shape({
  depositBank: Yup.number()
    .required('Seleccione el banco de acreditación.')
    .min(1, 'Seleccione el banco de acreditación.'),
  depositNumber: Yup.string().required('El número de papeleta es requerido'),
});

export const TransferPaymentSchema = Yup.object().shape({
  depositBank: Yup.number()
    .required('Seleccione el banco de acreditación.')
    .min(1, 'Seleccione el banco de acreditación.'),
  depositNumber: Yup.string().required('El número de papeleta es requerido'),
  accountOwner: Yup.string().required('El nombre del titular de la cuenta es requerido'),
  bankOwner: Yup.number()
    .required('Seleccione el banco del titular de la cuenta.')
    .min(1, 'Seleccione el banco del titular de la cuenta.'),
});
