import { CourseHourhand } from '../../interfaces/coursesByHourhand.interface';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Stack from '@material-ui/core/Stack';
import Typography from '@material-ui/core/Typography';
import { useAddCourseStudentMutation } from '../../slices/subjectsRegisterApiSlice';
import { useState } from 'react';
import ErrorAlert from '../../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../../shared/components/alerts/SuccessAlert';
import { CircularProgress, IconButton } from '@material-ui/core';
import { IconBuilding, IconCirclePlus, IconSchool, IconCertificate } from '@tabler/icons';
import HourshandCourse from '../HourshandCourse';
import { useAppSelector } from '../../../../../hooks/useRedux';
import { Hourhand } from '../../interfaces/coursesAvailables.interfaces';
import useStudentInfo from '../../hooks/useStudentInfo';

interface Props {
  course: CourseHourhand;
}

const useStyles = makeStyles((theme) => ({
  boxContainer: {
    boxShadow: '0px 4px 20px 15px rgba(234, 240, 246, 0.5);',
    borderRadius: 10,
    padding: '20px',
  },
}));

const CourseByHourhandCard = ({ course }: Props) => {
  const { userInfo } = useAppSelector((state) => state.auth);
  const { currentProfile } = useAppSelector((state) => state.access);
  const { currentPeriodId, studentInfoAcademic } = useAppSelector(
    (state) => state.subjectsRegister
  );
  const [showAlerts, setShowAlerts] = useState<Boolean>(true);
  const classes = useStyles();
  const theme = useTheme();

  const studentInfo = useStudentInfo();

  const teacher = course.collaborator;

  const [addCourseStudent, { isLoading, error, isSuccess }] = useAddCourseStudentMutation();

  return (
    <Grid item className={classes.boxContainer}>
      <Grid item container>
        {error && showAlerts && (
          <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
        )}

        {isSuccess && showAlerts && (
          <SuccessAlert
            message="La asignatura se ha registrado correctamente."
            handleDismiss={() => setShowAlerts(false)}
          />
        )}
      </Grid>
      <Grid item xs={12} sx={{ mb: 2 }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h3" sx={{ color: 'primary.main' }}>
            {course.rename}
          </Typography>
          <Grid item>
            {isLoading ? (
              <Grid container item direction="row" alignItems="center">
                <CircularProgress />
              </Grid>
            ) : (
              <Grid container item direction="row" alignItems="center">
                <Typography>Registrar</Typography>
                <IconButton
                  onClick={async () => {
                    await addCourseStudent({
                      profileId: Number(currentProfile?.profileId),
                      courseStudentPayload: {
                        course_id: course.id,
                        student_record_id: studentInfo?.studentRecordId,
                        final_note: 0,
                        observation: null,
                        num_fouls: 0,
                        subject_id: Number(course.matter_id),
                        period_id: currentPeriodId,
                        curriculum_id: Number(studentInfo?.curriculumId),
                        person_id: studentInfoAcademic?.personId || Number(userInfo.personId),
                        subject_curriculum_id: course.subject_curriculum_id,
                        approval_status: 13,
                        approval_reason_id: 380,
                        status_id: 1,
                        generation_fees: false,
                        expiration_date: null,
                      },
                    });
                    setShowAlerts(true);
                  }}
                >
                  <IconCirclePlus size={20} color={theme.palette.primary.main} />
                </IconButton>
              </Grid>
            )}
          </Grid>
        </Stack>
      </Grid>
      <Grid item xs={12} container spacing={1.5}>
        <Grid item xs={6}>
          <Stack direction="row" spacing={1.5} alignItems="center">
            <IconSchool color={theme.palette.grey[700]} size={20} />
            <Typography>
              {teacher
                ? `${teacher.user.person.pers_first_lastname} ${teacher.user.person.pers_second_lastname} ${teacher.user.person.pers_firstname}`
                : null}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack direction="row" spacing={1.5} alignItems="center">
            <IconBuilding color={theme.palette.grey[700]} size={20} />
            <Typography>{course.classroom.campus.cam_name}</Typography>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack direction="row" spacing={1.5} alignItems="center">
            <IconCertificate color={theme.palette.grey[700]} size={20} />
            <Typography>{course.classroom.cl_name}</Typography>
          </Stack>
        </Grid>
        <Grid container item xs={12}>
          {course.hourhand ? <HourshandCourse hourhand={course.hourhand as Hourhand} /> : null}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CourseByHourhandCard;
