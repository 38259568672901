import { useEffect, useState } from 'react';

import { Grid, Autocomplete, TextField } from '@material-ui/core';

import { useHistory, Link, useRouteMatch } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from '../../../../hooks/useRedux';
import {
  useGetAvailablesEconomicGroupQuery,
  useGetAvailablesTypeInstituteQuery,
  useGetInstitutesQuery,
} from '../slices/institutesApiSlice';

import StatusChip from '../../../shared/components/badges/StatusChip';
import EditButton from '../../../shared/components/tables/buttons/EditButton';
import DeleteButton from '../../../shared/components/tables/buttons/DeleteButton';
import AddButton from '../../../shared/components/tables/buttons/AddButton';

import {
  openDeleteModalWith,
  setEconomicGroupIdSelected,
  setinstituteList,
  setStatusIdSelected,
  setTypeInstituteIdSelected,
  setValueMinSelected,
  setValueMaxSelected,
  updateInstitutesTable,
} from '../slices/institutesSlice';
import SearchPaginatedTable, {
  FilterArrow,
} from '../../../shared/components/tables/SearchPaginatedTable';
import { useTableSearch } from '../../../../hooks/useTableSearch';
import { statusAvailables } from '../../../shared/constants/resourceStatus';
import useSharedGuard from '../../../../hooks/useSharedGuard';
import { economicGroupPermissions, institutesPermissions } from '../constants/permissions';

const InstitutesTable = () => {
  const { currentProfile, defaultConfiguration } = useAppSelector((state) => state.access);

  const dispatch = useAppDispatch();

  const { path } = useRouteMatch();
  const history = useHistory();

  const { page, perPage, search } = useAppSelector((state) => state.institutes.institutesTable);
  const {
    economicGroupIdSelected,
    typeInstituteIdSelected,
    statusIdSelected,
    valueMinSelected,
    valueMaxSelected,
  } = useAppSelector((state) => state.institutes);

  const [filterArrow, setFilterArrow] = useState<FilterArrow>(null!);

  const { setPage, setPerPage, setSearch } = useTableSearch({
    page,
    perPage,
    search,
    updateFunction: updateInstitutesTable,
    otherFilters: {
      economicGroupIdSelected,
      typeInstituteIdSelected,
      statusIdSelected,
      valueMinSelected,
      valueMaxSelected,
    },
  });

  const { data: economicGroups, isLoading: isLoadingEconomicGroups } =
    useGetAvailablesEconomicGroupQuery({
      profileId: currentProfile?.profileId!,
    });

  const { data: typeInstitutes, isLoading: isLoadingTypeInstitutes } =
    useGetAvailablesTypeInstituteQuery({
      profileId: currentProfile?.profileId!,
    });

  const { hasAccess } = useSharedGuard(institutesPermissions);
  const { hasAccess: hasAccessEcoGroup } = useSharedGuard(economicGroupPermissions);

  const { isLoading, error, data, isFetching } = useGetInstitutesQuery(
    {
      profileId: currentProfile?.profileId!,
      page,
      perPage,
      search,
      ...(filterArrow && { sort: filterArrow?.name, type_sort: filterArrow?.direction }),
      ...(economicGroupIdSelected && { economicGroupId: economicGroupIdSelected }),
      ...(typeInstituteIdSelected && { typeInstituteId: typeInstituteIdSelected }),
      ...(statusIdSelected && { statusId: statusIdSelected }),
      ...(valueMinSelected && { valueMin: valueMinSelected }),
      ...(valueMaxSelected && { valueMax: valueMaxSelected }),
    },
    { refetchOnMountOrArgChange: 30 }
  );

  useEffect(() => {
    if (data?.data) {
      dispatch(setinstituteList(data.data));
    }
    //eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    setPage(1);
    //eslint-disable-next-line
  }, [economicGroupIdSelected, typeInstituteIdSelected, statusIdSelected]);

  const handleDelete = async (periodId: number) => {
    dispatch(openDeleteModalWith(periodId));
  };

  const goToAdd = () => {
    history.push(`${path}/agregar`);
  };

  const datafetch =
    data?.data?.map((d, index) => ({
      id: index + ((page - 1) * perPage + 1),
      name: d.inst_name,
      typeInstituteName: d.type_institute?.tin_name,
      catInst: d.category_institute?.cat_inst_name,
      provinceName: d.province ? d.province?.cat_name : 'No asignada',
      economicGroupName: `${d.economic_group?.eco_gro_name} - ${d.economic_group?.eco_gro_description}`,
      minmax: `${defaultConfiguration?.currency_symbol}${d.economic_group?.eco_gro_min_amount} - ${defaultConfiguration?.currency_symbol}${d.economic_group?.eco_gro_max_amount}`,
      statusId: d.status_id,
      options: d,
    })) || [];

  const minFilter = economicGroups?.map((ec) => {
    return `${ec.eco_gro_min_amount}`;
  });

  const minimos =
    minFilter
      ?.filter((valor, indice) => {
        return minFilter.indexOf(valor) === indice;
      })
      .sort((a, b) => (a > b ? 1 : -1)) || [];

  const maxFilter = economicGroups?.map((ec) => {
    return `${ec.eco_gro_max_amount}`;
  });

  const maximos =
    maxFilter
      ?.filter((valor, indice) => {
        return maxFilter.indexOf(valor) === indice;
      })
      .sort((a, b) => (a > b ? 1 : -1)) || [];

  return (
    <Grid item xs={12}>
      <SearchPaginatedTable
        data={datafetch!}
        headers={{
          id: 'N°',
          name: 'Instituto',
          typeInstituteName: 'Tipo de institución',
          catInst: 'Categoría de institución',
          provinceName: 'Provincia',
          economicGroupName: 'Grupo económico',
          minmax: 'Min / Max',
          statusId: 'Estado',
          options: 'Opciones',
        }}
        keyExtractor={(item) => item.id.toString()}
        listFilterArrows={{
          name: 'inst_name',
          typeInstituteName: 'type_institute_id',
          economicGroupName: 'economic_group_id',
          statusId: 'status_id',
        }}
        filterArrow={filterArrow}
        setFilterArrow={setFilterArrow}
        searchPlacehoder="Buscar instituto"
        isLoading={isLoading}
        isFetching={isFetching}
        error={error}
        perPage={perPage}
        setPerPage={setPerPage}
        total={data?.total || 0}
        page={page}
        setPage={setPage}
        numHeader={7}
        search={search}
        setSearch={setSearch}
        customRenderers={{
          statusId: (row) => <StatusChip statusId={Number(row.statusId)} />,
          options: (row) => (
            <>
              {hasAccess('edit') && (
                <Link to={`${path}/${row.options.id}/editar`}>
                  <EditButton />
                </Link>
              )}
              {hasAccess('delete') && (
                <DeleteButton handleDelete={() => handleDelete(row.options.id)} />
              )}
              {!hasAccess(['delete', 'edit']) && 'Ninguna'}
            </>
          ),
        }}
        ActionButtons={<>{hasAccess('create') && <AddButton size="large" onClick={goToAdd} />}</>}
        customDataCellsProperties={{
          id: { align: 'center' },
          typeInstituteName: { align: 'center' },
          provinceName: { align: 'center' },
          economicGroupName: { align: 'center' },
          minmax: { align: 'center' },
          statusId: { align: 'center' },
          options: { align: 'center' },
        }}
        additionalFilters={
          <Grid container spacing={2}>
            {hasAccess('readTypes') && (
              <Grid item xs={12} md={3}>
                {isLoadingTypeInstitutes ? (
                  <TextField fullWidth placeholder="Cargando..." disabled variant="outlined" />
                ) : (
                  <Autocomplete
                    options={typeInstitutes || []}
                    getOptionLabel={(option) => option.tin_name}
                    loading={isLoadingTypeInstitutes}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={'Seleccione un tipo de institución'}
                        variant="outlined"
                        fullWidth
                      />
                    )}
                    onChange={(event, value) => {
                      dispatch(setTypeInstituteIdSelected(value?.id || null));
                    }}
                    value={typeInstitutes?.find((e) => e.id === typeInstituteIdSelected) || null}
                  />
                )}
              </Grid>
            )}

            {hasAccessEcoGroup('read') && (
              <>
                <Grid item xs={12} md={3}>
                  {isLoadingEconomicGroups ? (
                    <TextField fullWidth placeholder="Cargando..." disabled variant="outlined" />
                  ) : (
                    <Autocomplete
                      options={economicGroups || []}
                      getOptionLabel={(option) =>
                        `${option.eco_gro_name} - ${option.eco_gro_description}`
                      }
                      loading={isLoadingEconomicGroups}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={'Seleccione grupo economico'}
                          variant="outlined"
                          fullWidth
                        />
                      )}
                      onChange={(event, value) => {
                        dispatch(setEconomicGroupIdSelected(value?.id || null));
                      }}
                      value={economicGroups?.find((e) => e.id === economicGroupIdSelected) || null}
                    />
                  )}
                </Grid>
                <Grid item container xs={12} md={3} spacing={2}>
                  <Grid item xs={6}>
                    {isLoadingEconomicGroups ? (
                      <TextField fullWidth placeholder="Cargando..." disabled variant="outlined" />
                    ) : (
                      <Autocomplete
                        options={minimos || []}
                        loading={isLoadingEconomicGroups}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder={'valor min'}
                            variant="outlined"
                            fullWidth
                          />
                        )}
                        onChange={(event, value) => {
                          dispatch(setValueMinSelected(value || null));
                        }}
                        value={minimos?.find((e) => e === valueMinSelected) || null}
                      />
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    {isLoadingEconomicGroups ? (
                      <TextField fullWidth placeholder="Cargando..." disabled variant="outlined" />
                    ) : (
                      <Autocomplete
                        options={maximos || []}
                        // getOptionLabel={(option) => option.tin_name}
                        loading={isLoadingEconomicGroups}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder={'valor max'}
                            variant="outlined"
                            fullWidth
                          />
                        )}
                        onChange={(event, value) => {
                          dispatch(setValueMaxSelected(value || null));
                        }}
                        value={maximos?.find((e) => e === valueMaxSelected) || null}
                      />
                    )}
                  </Grid>
                </Grid>
              </>
            )}

            <Grid item xs={12} md={3}>
              <Autocomplete
                options={statusAvailables || []}
                getOptionLabel={(option) => option.name || ''}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Seleccione un estado"
                    variant="outlined"
                    fullWidth
                  />
                )}
                onChange={(event, newValue) => {
                  dispatch(setStatusIdSelected(newValue?.id || null));
                }}
                value={statusAvailables?.find((o) => o.id === statusIdSelected) || null}
              />
            </Grid>
          </Grid>
        }
      />
    </Grid>
  );
};

export default InstitutesTable;
