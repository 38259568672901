import { useHistory } from 'react-router-dom';
import { IconButton, Grid, useTheme, Typography } from '@material-ui/core';
import { IconArrowNarrowLeft } from '@tabler/icons';

import PreviewIntHomologation from '../../components/internal/PreviewIntHomologation';

import MainCardForm from '../../../../shared/components/cards/MainCardForm';
import { useAppSelector } from '../../../../../hooks/useRedux';
import RejectAppDialog from '../../components/internal/RejectAppDialog';
// import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const PreviewIntHomologationScreen = () => {
  const { t } = useTranslation();

  const theme = useTheme();
  const history = useHistory();

  const { internalAppToReject } = useAppSelector((state) => state.homologations);

  // if (!Boolean(internalHomologationsList.length))
  //   return <Redirect to="/administrar-homologaciones" />;

  return (
    <MainCardForm>
      <Grid item xs={12}>
        <Grid item container alignItems="center" gap={1} sx={{ cursor: 'pointer' }}>
          <IconButton onClick={() => history.push('/administrar-homologaciones')}>
            <IconArrowNarrowLeft size={30} color={theme.palette.text.dark} />
          </IconButton>
          <Typography variant="h2">{t('academic.homologation.internal.preview')}</Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} md={10} lg={8} xl={6} sx={{ mt: 2 }}>
        <PreviewIntHomologation />

        {internalAppToReject && <RejectAppDialog />}
      </Grid>
    </MainCardForm>
  );
};

export default PreviewIntHomologationScreen;
