import { Box, Grid, IconButton, Tooltip, Typography } from '@material-ui/core';
import { IconCheck, IconFileDownload, IconX } from '@tabler/icons';
import { useTranslation } from 'react-i18next';
import { getProtocol } from '../../../../helpers/urlHelpers';
import useGetBaseUrl from '../../../../hooks/useGetBaseUrl';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { imageExtensions } from '../constants/extensions';
import { CatName } from '../interfaces/depositsTransfers.interface';
import {
  openChangeStatusModalWith,
  setDepositTransferId,
  setViewDepositTransferModalOpen,
} from '../slices/depositsTransfersSlice';
import useSharedGuard from '../../../../hooks/useSharedGuard';
import permissions from '../constants/permissions';

const ViewDepositTransfer = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const apiUrl = localStorage.getItem('apiUrl');

  const { depositTransferId, depositTransferList, editDepositTransferModalOpen } = useAppSelector(
    (state) => state.depositsTransfers
  );

  const depositTransfer = depositTransferList.find((item) => item.id === depositTransferId);

  const fileExtension = depositTransfer?.url_file?.split('.').pop() || '';

  const { baseUrl } = useGetBaseUrl();

  const { hasAccess: hasCashCollection } = useSharedGuard(permissions.cashCollection);

  const handleEdit = (transactionId: number) => {
    dispatch(openChangeStatusModalWith(transactionId));
  };

  const handleClose = () => {
    dispatch(setViewDepositTransferModalOpen(false));
    dispatch(setDepositTransferId(null!));
  };

  return (
    <Box
      sx={{
        height: '100%',
      }}
    >
      {!editDepositTransferModalOpen && (
        <>
          <Typography
            variant="h3"
            sx={{
              mb: 2,
            }}
          >
            Detalles - {depositTransfer?.debtor?.person?.pers_full_name}
          </Typography>

          <Grid container spacing={2} justifyContent="flex-end">
            {depositTransfer?.url_file && (
              <Tooltip title={t('financial.deposit_transfers.table.options.download_doc_tooltip')}>
                <IconButton
                  onClick={() => {
                    window.open(
                      `${getProtocol()}://${apiUrl}/api/show?name=${depositTransfer?.url_file!}`,
                      '_blank'
                    );
                  }}
                >
                  <IconFileDownload />
                </IconButton>
              </Tooltip>
            )}
            {hasCashCollection('collectionStatus') &&
              depositTransfer?.pay?.status_payments?.cat_name !== CatName.Aprobado &&
              depositTransfer?.pay?.status_payments?.cat_name !== CatName.Rechazado && (
                <Tooltip title="Cambiar estado">
                  <IconButton onClick={() => handleEdit(depositTransfer?.id!)}>
                    <IconCheck />
                  </IconButton>
                </Tooltip>
              )}
            <Tooltip title="Cerrar">
              <IconButton onClick={handleClose}>
                <IconX />
              </IconButton>
            </Tooltip>
          </Grid>
        </>
      )}

      {imageExtensions.includes(fileExtension) && (
        <img
          src={`${baseUrl}/show?name=${depositTransfer?.url_file!}`}
          alt="Imagen"
          style={{ width: '100%' }}
        />
      )}
      {!imageExtensions.includes(fileExtension) && (
        <iframe
          title="frame"
          style={{ width: '100%', height: !editDepositTransferModalOpen ? '720px' : '100%' }}
          src={`${baseUrl}/show?name=${depositTransfer?.url_file}`}
        />
      )}
    </Box>
  );
};

export default ViewDepositTransfer;
