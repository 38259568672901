import { useState } from 'react';
import {
  Grid,
  InputLabel,
  TextField,
  FormHelperText,
  Autocomplete,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Button,
  CircularProgress,
  InputAdornment,
} from '@material-ui/core';
import { useFormik } from 'formik';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import {
  useGetAllAvailablesCategoriesQuery,
  useUpdateCategoryMutation,
} from '../slices/categoryApiSlice';
import { useAppSelector } from '../../../../hooks/useRedux';
import { /* useHistory, */ Redirect, useParams } from 'react-router-dom';
import { CategoryValidationSchema } from '../constants/CategoryValidationSchema';
import { statusAvailables } from '../../../shared/constants/resourceStatus';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import { renderSkeletonTable } from '../../../shared/helpers/render';

const EditForm = () => {
  const history = useHistory();

  const { t } = useTranslation();

  const { categoryId } = useParams<{ categoryId: string }>();

  const { currentProfile } = useAppSelector((state) => state.access);

  const [showAlerts, setShowAlerts] = useState(true);

  const [updateCategory, { isLoading, isError, error, isSuccess }] = useUpdateCategoryMutation();

  const { categories: categoriesStore } = useAppSelector((state) => state.categories);

  const categorieToEdit = categoriesStore.find((e) => e.id === Number(categoryId));
  // console.log({ categorieToEdit })

  //Categories
  const { data: categories, isLoading: loadingCategories } = useGetAllAvailablesCategoriesQuery({
    profileId: currentProfile?.profileId!,
    dataAll: 'all',
    sort: 'id',
    type_sort: 'asc',
    search: '',
  });

  //tipo de uso
  const typesUse: any[] = [
    { id: 1, label: 'PROD' },
    { id: 2, label: 'SERV' },
  ];

  //Form
  const { getFieldProps, values, errors, touched, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      name: categorieToEdit?.cat_name || '',
      description: categorieToEdit?.cat_description! || '',
      statusId: Number(categorieToEdit?.status_id) || '',
      categoryId: categorieToEdit?.cat_parent! || '',
      contifico: categorieToEdit?.contifico_id === null ? false : true,
      contificoId: categorieToEdit?.contifico_id || '',
      typesUse: categorieToEdit?.cat_type_product! || '',
    },
    validationSchema: CategoryValidationSchema,
    onSubmit: async (values) => {
      await updateCategory({
        categoryId: categoryId,
        categoryPayload: {
          user_profile_id: currentProfile?.profileId!,
          cat_group: false,
          cat_description: values.description,
          cat_name: values.name,
          cat_parent: Number(values.categoryId) === 0 ? null : Number(values.categoryId),
          cat_type_product: values.typesUse,
          status_id: Number(values.statusId),
          contifico: values.contificoId === '' ? values.contifico : false,
          contifico_id: values.contificoId !== null ? values.contificoId : undefined,
        },
      });
      setShowAlerts(true);
    },
  });

  if (!categoriesStore) {
    return <Redirect to="/administrar-mantenimiento-categoria" />;
  }

  return (
    <>
      {loadingCategories ? (
        renderSkeletonTable(5, 5)
      ) : (
        <form onSubmit={handleSubmit}>
          <Grid container>
            <Grid item xs={6}>
              {isError && showAlerts && (
                <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
              )}

              {isSuccess && showAlerts && (
                <SuccessAlert
                  message={t('financial.maintenance_cat.edit.success_alert')}
                  handleDismiss={() => setShowAlerts(false)}
                />
              )}
            </Grid>
            <Grid item container spacing={2} alignItems="center">
              <Grid item xs={12} md={6} sx={{ mb: 1 }}>
                <InputLabel>{t('financial.maintenance_cat.add.name_label')}</InputLabel>
                <TextField
                  fullWidth
                  error={Boolean(errors.name && touched.name)}
                  placeholder={t('financial.maintenance_cat.add.name_placeholder')}
                  {...getFieldProps('name')}
                />
                {errors.name && touched.name && (
                  <FormHelperText error>{t(errors.name)}</FormHelperText>
                )}
              </Grid>
            </Grid>
            <Grid item container spacing={2} alignItems="center">
              <Grid item xs={12} md={6} sx={{ mb: 1 }}>
                <InputLabel>{t('financial.maintenance_cat.add.category_label')}</InputLabel>
                {loadingCategories ? (
                  <>
                    <TextField
                      disabled
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <CircularProgress size={25} />
                          </InputAdornment>
                        ),
                      }}
                      placeholder={t('financial.maintenance_cat.add.category_loading')}
                    />
                  </>
                ) : (
                  <Autocomplete
                    options={
                      categories ? categories?.filter((e) => e.id !== Number(categoryId)) : []
                    }
                    loading={loadingCategories}
                    loadingText={t('financial.maintenance_cat.add.category_loading')}
                    getOptionLabel={(option) => (option ? option.cat_name : '')}
                    onChange={(e, value) => setFieldValue('categoryId', value?.id || null)}
                    value={categories?.find((e) => e.id === Number(values.categoryId))! || null}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={t('financial.maintenance_cat.add.category_placeholder')}
                      />
                    )}
                    fullWidth
                  />
                )}
              </Grid>
            </Grid>
            <Grid item container spacing={2} alignItems="center">
              <Grid item xs={12} md={6} sx={{ mb: 1 }}>
                <InputLabel>{t('financial.maintenance_cat.add.type_use_label')}</InputLabel>
                <Autocomplete
                  options={typesUse}
                  getOptionLabel={(option) => (option ? option.label : '')}
                  renderInput={(params) => (
                    <TextField
                      placeholder={t('financial.maintenance_cat.add.type_use_placeholder')}
                      {...params}
                    />
                  )}
                  value={typesUse?.find((e) => e.label === values.typesUse)! || null}
                  onChange={(e, value) => setFieldValue('typesUse', value?.label || '')}
                  fullWidth
                  clearIcon={null}
                />
              </Grid>
            </Grid>
            <Grid item container spacing={2} alignItems="center">
              <Grid item xs={12} md={6} sx={{ mb: 1 }}>
                <InputLabel>{t('financial.maintenance_cat.add.description_label')}</InputLabel>
                <TextField
                  fullWidth
                  error={Boolean(errors.description && touched.description)}
                  placeholder={t('financial.maintenance_cat.add.description_placeholder')}
                  {...getFieldProps('description')}
                />
                {errors.description && touched.description && (
                  <FormHelperText error>{t(errors.description)}</FormHelperText>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} container spacing={2}>
              <Grid item xs={12} md={6} sx={{ mb: 1 }}>
                <InputLabel>{t('financial.maintenance_cat.add.status_label')}</InputLabel>
                <Autocomplete
                  options={statusAvailables}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => <TextField {...params} />}
                  value={statusAvailables.find((dt) => dt.id === values.statusId) || null}
                  onChange={(e, value) => setFieldValue('statusId', value?.id || '')}
                  fullWidth
                  clearIcon={null}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} container spacing={2}>
              <Grid item xs={4}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={values.contificoId !== ''}
                        checked={values.contifico}
                        onChange={(event) => setFieldValue('contifico', event.target.checked)}
                      />
                    }
                    label={t('financial.maintenance_cat.add.contifico_switch_label')}
                  />
                </FormGroup>
              </Grid>
            </Grid>

            <Grid
              item
              container
              alignItems="center"
              justifyContent="flex-end"
              spacing={2}
              sx={{ mt: 1 }}
            >
              <Grid item>
                <Button
                  variant="outlined"
                  size="large"
                  color="primary"
                  onClick={() => history.goBack()}
                >
                  {t('financial.maintenance_cat.edit.go_back_btn')}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  color="primary"
                  disabled={isLoading}
                  endIcon={isLoading && <CircularProgress size={20} />}
                >
                  {t('financial.maintenance_cat.edit.edit_btn')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}
    </>
  );
};

export default EditForm;
