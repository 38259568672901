import {
  Autocomplete,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { t } from 'i18next';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppSelector } from '../../../../hooks/useRedux';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import { statusAvailables } from '../../../shared/constants/resourceStatus';
import { useFormStyles } from '../constants/styles';
import { PermissionInfoSchema } from '../constants/validationSchemas';
import { useAddPermissionMutation } from '../slices/permissionsApiSlice';

const AddPermissionForm = () => {
  const history = useHistory();
  const classes = useFormStyles();
  const { currentProfile } = useAppSelector((state) => state.access);

  const [showAlerts, setShowAlerts] = useState(true);

  const [addPermission, { isLoading, isError, error, isSuccess }] = useAddPermissionMutation();

  const { getFieldProps, errors, touched, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      name: '',
      description: '',
      alias: '',
      parentName: '',
      statusId: 1,
    },
    validationSchema: PermissionInfoSchema,
    onSubmit: async (values) => {
      await addPermission({
        profileId: currentProfile?.profileId!,
        permissionPayload: {
          name: values.name,
          description: values.description,
          alias: values.alias,
          parent_name: values.parentName,
          status_id: values.statusId.toString(),
        },
      });

      setShowAlerts(true);
    },
  });

  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction="column" gap={2}>
        <Grid item>
          {isError && showAlerts && (
            <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
          )}

          {isSuccess && showAlerts && (
            <SuccessAlert
              message={t('configuration.permissions.add.success')}
              handleDismiss={() => setShowAlerts(false)}
            />
          )}
        </Grid>

        <Grid item container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>
              {t('configuration.permissions.form.name')}
            </InputLabel>
            <TextField
              fullWidth
              error={Boolean(errors.name && touched.name)}
              placeholder={t('configuration.permissions.form.name.placeholder')}
              {...getFieldProps('name')}
            />
            {errors.name && touched.name && <FormHelperText error>{t(errors.name)}</FormHelperText>}
          </Grid>
          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>
              {t('configuration.permissions.form.description')}
            </InputLabel>
            <TextField
              multiline
              rows={2}
              fullWidth
              placeholder={t('configuration.permissions.form.description.placeholder')}
              {...getFieldProps('description')}
            />
          </Grid>

          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>
              {t('configuration.permissions.form.alias')}
            </InputLabel>
            <TextField
              fullWidth
              error={Boolean(errors.alias && touched.alias)}
              placeholder={t('configuration.permissions.form.alias.placeholder')}
              {...getFieldProps('alias')}
            />
            {errors.alias && touched.alias && (
              <FormHelperText error>{t(errors.alias)}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>
              {t('configuration.permissions.form.parent')}
            </InputLabel>
            <TextField
              fullWidth
              error={Boolean(errors.parentName && touched.parentName)}
              placeholder={t('configuration.permissions.form.parent.placeholder')}
              {...getFieldProps('parentName')}
            />
            {errors.parentName && touched.parentName && (
              <FormHelperText error>{t(errors.parentName)}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>
              {t('configuration.permissions.form.status')}
            </InputLabel>
            <Autocomplete
              options={statusAvailables}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField {...params} error={Boolean(errors.statusId && touched.statusId)} />
              )}
              defaultValue={statusAvailables[0]}
              onChange={(e, value) => setFieldValue('statusId', value?.id || '')}
              fullWidth
              clearIcon={null}
            />
            {errors.statusId && touched.statusId && (
              <FormHelperText error>{t(errors.statusId)}</FormHelperText>
            )}
          </Grid>
        </Grid>

        <Grid
          item
          container
          alignItems="center"
          justifyContent="flex-end"
          spacing={2}
          sx={{ mt: 1 }}
        >
          <Grid item>
            <Button
              variant="outlined"
              size="large"
              color="primary"
              onClick={() => history.goBack()}
            >
              {t('configuration.permissions.add.back')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              disabled={isLoading}
              endIcon={isLoading && <CircularProgress size={20} />}
            >
              {t('configuration.permissions.add.submit')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default AddPermissionForm;
