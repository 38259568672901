import { useState, useEffect } from 'react';

export function useIncrementalValue(
  initialValue: number = 0,
  defaultDuration?: number,
  finalValueDefault?: number
) {
  const [value, setValue] = useState(initialValue);
  const [duration, setDuration] = useState(defaultDuration || 3000);
  const [finalValue, setFinalValue] = useState(finalValueDefault || initialValue);

  useEffect(() => {
    const incrementPerMs = (Number(finalValue) - initialValue) / duration;
    const intervalId = setInterval(() => {
      setValue((v) => {
        const newValue = v + incrementPerMs;
        return newValue >= finalValue ? finalValue : newValue;
      });
    }, 1);

    return () => clearInterval(intervalId);
  }, [initialValue, finalValue, duration]);

  return { value: Math.floor(value), setFinalValue, setDuration };
}
