import { Grid, Modal, Typography } from '@material-ui/core';
import React from 'react';

const ModalUploadTask: React.FC<{
  Open: boolean;
  title: string;
  children: JSX.Element;
}> = ({ Open, children, title }) => {
  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    overflow: 'hidden',
    width: '50vw',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    borderRadius: '15px',
    p: 5,
    boxShadow: 24,
  };
  return (
    <Modal open={Open}>
      <>
        <Grid sx={style}>
          <Typography
            variant="h2"
            color={'primary'}
            fontSize={'30px'}
            fontWeight="bold"
            textAlign="center"
            m={'15px 0'}
          >
            {title}
          </Typography>
          {children}
        </Grid>
      </>
    </Modal>
  );
};

export default ModalUploadTask;
