import { Grid, TextField, Autocomplete, Button, Box, Paper, Typography, FormGroup, FormControlLabel, Checkbox } from "@material-ui/core";
import InputLabel from "../../../shared/components/forms/InputLabel";

const PermissionRol = () => {

   const opts = [
      {
         id: 1,
         category: 'Gestionar',
         options: [
            { id: 1, category: 'Leads' },
            { id: 2, category: 'Clientes' },
         ]
      },
      {
         id: 2,
         category: 'Marketing',
         options: [
            { id: 1, category: 'Importar Datos' },
            { id: 2, category: 'Campañas' },
            { id: 3, category: 'Formularios' },
            { id: 4, category: 'Mailing' },
            { id: 5, category: 'Plantillas' },
            { id: 6, category: 'Respuestas Automáticas' },
         ]
      },
      {
         id: 3,
         category: 'Reporte',
         options: [
            { id: 1, category: 'Gestión' },
         ]
      },
      {
         id: 4,
         category: 'Administración',
         options: [
            { id: 1, category: 'Permisos' },
            { id: 2, category: 'Roles' },
            { id: 3, category: 'Permisos por Rol' },
            { id: 4, category: 'Usuarios' },
            { id: 5, category: 'Empresas' },
            { id: 6, category: 'Sedes' },
         ]
      },
      {
         id: 5,
         category: 'Configuración',
         options: [
            { id: 1, category: 'Fuentes de Contacto' },
            { id: 2, category: 'Medios de Gestión' },
            { id: 3, category: 'Productos' },
            { id: 4, category: 'Servicios' },
            { id: 5, category: 'Preguntas Encuestas' },
         ]
      },
      {
         id: 6,
         category: 'Aplicaciones',
         options: [
            { id: 1, category: 'Zoom' },
            { id: 2, category: 'Slack' },
         ]
      },
   ]

   return (
      <>
         <Grid container spacing={2}>
            <Grid item xs={12} md={6} >
               <InputLabel>Rol</InputLabel>
               <Autocomplete
                  options={[]}
                  placeholder="Seleccione Rol..."
                  //getOptionLabel={option => option.name}
                  renderInput={params => (
                     <TextField {...params}
                        //error={Boolean(errors.statusId && touched.statusId)} 
                        placeholder="Seleccione Rol"
                     />
                  )}
                  //defaultValue={}
                  onChange={(e, value) => { }
                     //setFieldValue('statusId', value?.id || '')
                  }
                  fullWidth
                  clearIcon={null}
               />
            </Grid>
            <Grid item container direction="row" alignItems="center" justifyContent="flex-end" xs={12} md={6} >
               <Button
                  variant="contained"
                  size="large"
               >
                  Guardar
               </Button>
            </Grid>
         </Grid>
         <Grid container spacing={3} sx={{ mt: 2 }}>
            {opts.map(el => (
               <Grid item xs={12} md={4} key={el.id}>
                  <Box sx={{
                     '& > :not(style)': {
                        m: 1,
                        width: '100%',
                        minHeight: '350px',
                     },
                  }}>
                     <Paper elevation={3} sx={{ p: 3 }}>
                        <Typography variant='h3' color='primary'>{el.category}</Typography>
                        <FormGroup>
                           {
                              el.options.map(opt => (
                                 <FormControlLabel key={opt.id} control={<Checkbox defaultChecked />} label={opt.category} />
                              ))
                           }
                        </FormGroup>
                     </Paper>
                  </Box>
               </Grid>
            ))}
         </Grid>
      </>
   )
}

export default PermissionRol;