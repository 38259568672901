import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISearchTable } from '../../../shared/interfaces/paginatedTable.interface';
import { Classroom } from '../interfaces/classrooms.interface';

interface ClassroomsState {
  classroomsList: Classroom[];
  deleteModalOpen: boolean;
  classroomIdToDelete?: number;
  classroomTable: ISearchTable;
  campusId: number | null;
  classroomTypeId: number | null;
  capacity: number | null;
}

const initialState: ClassroomsState = {
  classroomsList: [],
  deleteModalOpen: false,
  classroomTable: {
    page: 1,
    perPage: 5,
    search: '',
  },
  campusId: null,
  classroomTypeId: null,
  capacity: null,
};

const ClassroomsSlice = createSlice({
  name: 'classrooms',
  initialState,
  reducers: {
    setClassroomList: (state, action: PayloadAction<Classroom[]>) => {
      state.classroomsList = action.payload;
    },
    setDeleteModalOpen: (state, action: PayloadAction<boolean>) => {
      state.deleteModalOpen = action.payload;
    },
    openDeleteModalWith: (state, action: PayloadAction<number>) => {
      state.classroomIdToDelete = action.payload;
      state.deleteModalOpen = true;
    },
    resetClassroomIdToDelete: (state) => {
      state.classroomIdToDelete = undefined;
    },
    updateClassroomTableStates: (state, action: PayloadAction<ISearchTable>) => {
      state.classroomTable = action.payload;
    },
    setCampusId: (state, action: PayloadAction<number | null>) => {
      state.campusId = action.payload;
    },
    setClassroomTypeId: (state, action: PayloadAction<number | null>) => {
      state.classroomTypeId = action.payload;
    },
    setCapacity: (state, action: PayloadAction<number | null>) => {
      state.capacity = action.payload;
    },
  },
});

export const {
  setClassroomList,
  setDeleteModalOpen,
  openDeleteModalWith,
  resetClassroomIdToDelete,
  updateClassroomTableStates,
  setCampusId,
  setClassroomTypeId,
  setCapacity,
} = ClassroomsSlice.actions;

export default ClassroomsSlice.reducer;
