import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ExternalHomologation,
  InternalHomologation,
  PreconfigSubjects,
} from '../interfaces/homologations.interface';
import { Score } from '../interfaces/payload.interface';

interface HomologationsState {
  internalHomologationsList: InternalHomologation[];
  externalHomologationsList: ExternalHomologation[];
  deleteModalOpen: boolean;
  confirmModalOpen: boolean;
  homologationIdToDelete?: number;

  instituteIdSubject?: number;

  preconfigSubjectTEdit?: { id: number; instSubject: string; subject: string };
  externalPreconfigSubjectScore?: Score;
  externalAppToConfirm?: {
    dataList: PreconfigSubjects[];
    selectedData: number[];
    homId: number;
    curriculumId: number;
    agreementId?: number;
  };

  internalAppToReject?: { code: string; msg: string };
  internalAppToConfirm?: { code: string; msg: string; subjects: number[] };

  //view state: 'internal|external'
  viewMode: 'internal' | 'external';
}

const initialState: HomologationsState = {
  internalHomologationsList: [],
  externalHomologationsList: [],
  deleteModalOpen: false,
  confirmModalOpen: false,

  viewMode: 'internal',
};

const homologationsSlice = createSlice({
  name: 'homologation',
  initialState,
  reducers: {
    setDeleteModalOpen: (state, action: PayloadAction<boolean>) => {
      state.deleteModalOpen = action.payload;
    },
    setConfirmModalOpen: (state, action: PayloadAction<boolean>) => {
      state.confirmModalOpen = action.payload;
    },
    openDeleteModalWith: (state, action: PayloadAction<number>) => {
      state.homologationIdToDelete = action.payload;
      state.deleteModalOpen = true;
    },
    resetApplicationHomologationToDelete: (state) => {
      state.homologationIdToDelete = undefined;
    },

    //institute id for create subject
    setInstituteId: (state, action: PayloadAction<number | undefined>) => {
      state.instituteIdSubject = action.payload;
    },

    // set preconfig subject info to edit
    setPreconfigSubjectToEdit: (
      state,
      action: PayloadAction<{ id: number; instSubject: string; subject: string }>
    ) => {
      state.preconfigSubjectTEdit = action.payload;
    },
    setExternalPreconfigSubjectScore: (state, action: PayloadAction<Score | undefined>) => {
      state.externalPreconfigSubjectScore = action.payload;
    },
    openExternalConfirmModalWith: (
      state,
      action: PayloadAction<{
        dataList: PreconfigSubjects[];
        selectedData: number[];
        homId: number;
        curriculumId: number;
        agreementId?: number;
      }>
    ) => {
      state.externalAppToConfirm = action.payload;
      state.confirmModalOpen = true;
    },
    resetExternalAppIdToConfirm: (state) => {
      state.externalAppToConfirm = undefined;
    },

    //internal homologation
    setInternatHomologationsList: (state, action: PayloadAction<InternalHomologation[]>) => {
      state.internalHomologationsList = action.payload;
    },
    openInternalDeleteModalWith: (state, action: PayloadAction<{ code: string; msg: string }>) => {
      state.internalAppToReject = action.payload;
      state.deleteModalOpen = true;
    },
    openInternalConfirmModalWith: (
      state,
      action: PayloadAction<{ code: string; msg: string; subjects: number[] }>
    ) => {
      state.internalAppToConfirm = action.payload;
      state.confirmModalOpen = true;
    },
    resetInternalAppIdToDelete: (state) => {
      state.internalAppToReject = undefined;
    },
    resetInternalAppIdToConfirm: (state) => {
      state.internalAppToConfirm = undefined;
    },

    //external homologation
    setExternalHomologationsList: (state, action: PayloadAction<ExternalHomologation[]>) => {
      state.externalHomologationsList = action.payload;
    },

    //table mode view
    setViewMode: (state, action: PayloadAction<'internal' | 'external'>) => {
      state.viewMode = action.payload;
    },
  },
});

export const {
  setDeleteModalOpen,
  openDeleteModalWith,
  resetApplicationHomologationToDelete,
  setConfirmModalOpen,

  setInstituteId,

  setPreconfigSubjectToEdit,
  setExternalPreconfigSubjectScore,
  openExternalConfirmModalWith,
  resetExternalAppIdToConfirm,

  setInternatHomologationsList,
  openInternalDeleteModalWith,
  resetInternalAppIdToDelete,
  openInternalConfirmModalWith,
  resetInternalAppIdToConfirm,

  setExternalHomologationsList,

  setViewMode,
} = homologationsSlice.actions;

export default homologationsSlice.reducer;
