import React, { useEffect } from 'react';

import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/core/Autocomplete';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { LocalizationProvider } from '@material-ui/lab';
import DateRangePicker, { DateRange } from '@material-ui/lab/DateRangePicker';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';

import InputLabel from '../../../../../../shared/components/forms/InputLabel';
import { ProjectStepsPayload, Project } from '../../../../interfaces/projectResearch.interfaces';
import { ProjectPlanningSchema } from '../../../../constants/wizard.validationSchemas';
import { useGetFinancialSourcesAllAvailablesQuery } from '../../../../slices/InvestigationLinesApiSlice';
import scopeAvailable from '../../../../constants/lists/scope';
import TextFieldFetching from '../../../../../../shared/components/TextFieldFetching';
import { Reseteable } from '.';
import { Typography } from '@material-ui/core';
import { addYears } from 'date-fns';

interface Props extends Reseteable {
  setData: React.Dispatch<React.SetStateAction<ProjectStepsPayload>>;
  setNext: React.Dispatch<React.SetStateAction<number>>;
  isEdit?: boolean;
  dataEdit?: Project;
}

const ProjectPlanningForm = ({ setData, setNext, isEdit = false, dataEdit, isReset }: Props) => {
  const { t } = useTranslation();

  const { data: financialSourcesAvailables, isLoading: isLoadingFinancialSource } =
    useGetFinancialSourcesAllAvailablesQuery({});

  const handleNext = () => {
    setNext((step) => step + 1);
  };
  const handleReturn = () => {
    setNext((step) => step - 1);
  };

  const { getFieldProps, errors, touched, handleSubmit, setFieldValue, values, resetForm } =
    useFormik({
      initialValues: {
        // ?considerar fechas formateadas (timezone)
        startDate: isEdit ? dataEdit?.start_date || new Date() : new Date(),
        endDateReal: isEdit ? dataEdit?.end_date_real || new Date() : new Date(),
        endDatePlanned: isEdit
          ? dataEdit?.end_date_planned || addYears(new Date(), 3)
          : addYears(new Date(), 3),
        financialSourceId: isEdit ? dataEdit?.financial_source_id || '' : '',
        scope: isEdit ? dataEdit?.scope || '' : '',
      },
      validationSchema: ProjectPlanningSchema,
      onSubmit: async ({ endDatePlanned, endDateReal, financialSourceId, startDate, scope }) => {
        setData((data) => ({
          ...data,
          projectPlanning: {
            end_date_planned: endDatePlanned,
            end_date_real: endDatePlanned,
            // end_date_real: endDateReal,
            financial_source_id: financialSourceId,
            start_date: startDate,
            scope,
          },
        }));

        handleNext();
        // window.scrollTo(0, 0);
      },
    });

  const onChangeDates = (newDatesValues: DateRange<Date>) => {
    const [start, end] = newDatesValues;
    // update formik values
    setFieldValue('startDate', start);
    setFieldValue('endDatePlanned', end);
  };

  useEffect(() => {
    if (isReset) {
      resetForm();
    }
  }, [isReset, resetForm]);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction="column">
        <Grid item container xs={12} md={6} spacing={2}>
          {false && (
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateRangePicker
                  startText={null}
                  endText={null}
                  value={[values.startDate, values.endDatePlanned]}
                  onChange={onChangeDates}
                  renderInput={(startProps, endProps) => (
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12} md={6}>
                        <InputLabel> {t('project.ProjectPlanning.startDate.title')} </InputLabel>
                        <TextField
                          fullWidth
                          {...startProps}
                          helperText=""
                          error={Boolean(errors.startDate && touched.startDate)}
                          {...getFieldProps('startDate')}
                        />
                        {errors.startDate && touched.startDate && (
                          <FormHelperText error>{errors.startDate}</FormHelperText>
                        )}
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <InputLabel> {t('project.ProjectPlanning.endDateReal.title')} </InputLabel>
                        <TextField
                          fullWidth
                          {...endProps}
                          helperText=""
                          error={Boolean(errors.endDatePlanned && touched.endDatePlanned)}
                          {...getFieldProps('endDatePlanned')}
                        />

                        {errors.endDatePlanned && touched.endDatePlanned && (
                          <FormHelperText error>{errors.endDatePlanned}</FormHelperText>
                        )}
                      </Grid>
                    </Grid>
                  )}
                />
              </LocalizationProvider>
            </Grid>
          )}

          <Grid item xs={12} md={6}>
            <InputLabel> {t('project.ProjectPlanning.financialSourceId.title')} </InputLabel>

            {isLoadingFinancialSource ? (
              <TextFieldFetching />
            ) : (
              <Autocomplete
                options={financialSourcesAvailables || []}
                loading={isLoadingFinancialSource}
                value={
                  financialSourcesAvailables?.find(({ id }) => id === values.financialSourceId) ||
                  null
                }
                loadingText={t('academic.subjects.add.edu_level.loading')}
                noOptionsText={t('project.ProjectPlanning.financialSourceId.no_option')}
                getOptionLabel={(option) => option.description}
                renderInput={(params) => (
                  <TextField
                    sx={{ wordBreak: 'break-word' }}
                    {...params}
                    multiline={true}
                    error={Boolean(errors.financialSourceId && touched.financialSourceId)}
                    placeholder={t('project.ProjectPlanning.financialSourceId.placeholder')}
                  />
                )}
                renderOption={(props, option, state) => {
                  return (
                    <li {...props}>
                      <Grid item xs={12}>
                        <Typography variant="body2" sx={{ wordBreak: 'break-word' }}>
                          {`${option.description}`}
                        </Typography>
                      </Grid>
                    </li>
                  );
                }}
                onChange={(_, value) => {
                  setFieldValue('financialSourceId', value?.id);
                }}
                fullWidth
                clearIcon={null}
              />
            )}
            {errors.financialSourceId && touched.financialSourceId && (
              <FormHelperText error>{errors.financialSourceId}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            <InputLabel> {t('project.ProjectPlanning.scope.title')} </InputLabel>
            <Autocomplete
              options={scopeAvailable}
              noOptionsText={t('project.ProjectPlanning.scope.no_option')}
              value={scopeAvailable.find(({ keyword }) => keyword === values.scope) || null}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(errors.scope && touched.scope)}
                  placeholder={t('project.ProjectPlanning.scope.placeholder')}
                />
              )}
              onChange={(_, value) => {
                setFieldValue('scope', value?.keyword);
              }}
              fullWidth
              clearIcon={null}
            />
            {errors.scope && touched.scope && <FormHelperText error>{errors.scope}</FormHelperText>}
          </Grid>

          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            spacing={2}
            sx={{ mt: 6 }}
          >
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="outlined"
                size="large"
                color="primary"
                onClick={handleReturn}
              >
                {t('academic.subjects.add.back')}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                type="submit"
                variant="contained"
                size="large"
                color="primary"
                // disabled={isLoading}
                // endIcon={isLoading && <CircularProgress size={20} />}
              >
                Siguiente
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default ProjectPlanningForm;
