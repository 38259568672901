import Grid from '@material-ui/core/Grid';

import SubCard from '../../../shared/components/cards/SubCard';
import LogoutButton from '../../../shared/components/tables/buttons/LogoutButton';
import ChangePasswordScreen from './ChangePasswordScreen';

const FirstChangePasswordScreen = () => {
  return (
    <Grid container justifyContent="center" alignItems="center" height="100vh">
      <LogoutButton />
      <Grid item xs={12} md={8}>
        <SubCard>
          <ChangePasswordScreen />
        </SubCard>
      </Grid>
    </Grid>
  );
};

export default FirstChangePasswordScreen;
