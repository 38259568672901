import React, { useState } from 'react';

import {
  Button,
  DialogActions,
  DialogContent,
  Slide,
  SlideProps,
  Stack,
  Typography,
  Grid,
  FormHelperText,
  TextField,
  Chip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useAppSelector } from '../../../../../hooks/useRedux';
import ErrorAlert from '../../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../../shared/components/alerts/SuccessAlert';
// import DragArea from '../../components/DragArea';
import DragArea from '../../../../shared/components/files/DragArea';
import { useFormik } from 'formik';
import ExtendedSideDialog from '../../../../shared/components/dialogs/ExtendedSideDialog';
import { useAddJustifyAttendanceMutation } from '../../slices/virtualClassroomApiSlice';

import { useTranslation } from 'react-i18next';
import InputLabel from '../../../../shared/components/extended/Form/InputLabel';
import { Attendance } from '../interfaces/attendance.interfaces';
import { justifyFormSchema } from '../constants/justifySchemas';

const useStyles = makeStyles((theme) => ({
  userAddDialog: {
    '&>div:nth-child(3)': {
      justifyContent: 'flex-end',
      '&>div': {
        margin: '0px',
        borderRadius: '0px',
        maxWidth: '450px',
        maxHeight: '100%',
      },
    },
  },
  paper: {
    minHeight: '100vh',
    width: '100%',
  },

  card: {
    backgroundColor: theme.palette.primary.light,
    borderRadius: '20px',
  },
}));

const Transition = React.forwardRef(function Transition(props: SlideProps, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

interface Props {
  open: boolean;
  handleCloseDialog: () => void;
  data: Attendance[];
}

const JustifyDialog = ({ open, handleCloseDialog, data }: Props) => {
  const { t } = useTranslation();

  const classes = useStyles();

  // const dispatch = useAppDispatch();

  const [showAlerts, setShowAlerts] = useState(true);

  const { attendancesToJustify } = useAppSelector((state) => state.virtualClassroomStudent);

  const { userInfo } = useAppSelector((state) => state.auth);

  const { currentRecordStudent } = useAppSelector((state) => state.access);

  const [addJustify, { isLoading, isError, isSuccess, error }] = useAddJustifyAttendanceMutation();

  const onChangeFile = function (event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.files && event.target.files[0]) {
      setFieldValue('fileUrl', event.target.files[0]);
      //   let reader = new FileReader();
      //   reader.onload = (e: any) => {
      //     setFile(e.target.result);
      //   };
      //   reader.readAsDataURL(event.target.files[0]);
      //   setTarget(event.target.files[0]);
    }
  };

  const { errors, touched, handleSubmit, setFieldValue, getFieldProps, values } = useFormik({
    initialValues: {
      appDescription: '',
      userId: userInfo.id || null!,
      faultAssistans: attendancesToJustify || []!,
      studentRecordId: currentRecordStudent?.id || null!,
      fileUrl: '',
    },
    validationSchema: justifyFormSchema,
    onSubmit: async (values) => {
      await addJustify({
        // courseId: course.courseId,
        PayloadJustify: {
          app_description: values.appDescription,
          fault_assistans: values.faultAssistans,
          user_id: Number(values.userId),
          file_url: values.fileUrl,
          student_record_id: values.studentRecordId,
        },
      });
    },
  });

  return (
    <ExtendedSideDialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => {
        handleCloseDialog();
      }}
      className={classes.userAddDialog}
      classes={{
        paper: classes.paper,
      }}
    >
      {/* <PerfectScrollbar> */}

      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Typography variant="h2">Justificar Asistencia</Typography>

          <Stack spacing={2} sx={{ mt: 4 }}>
            <Grid item>
              {isError && showAlerts && (
                <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
              )}

              {isSuccess && showAlerts && (
                <SuccessAlert
                  message="La justificación se ha subido correctamente"
                  handleDismiss={() => setShowAlerts(false)}
                />
              )}
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <InputLabel>Descripción</InputLabel>
                <TextField
                  fullWidth
                  placeholder={'Ingrese descripcion'}
                  {...getFieldProps('appDescription')}
                  error={Boolean(errors.appDescription && touched.appDescription)}
                />
                {errors.appDescription && touched.appDescription && (
                  <FormHelperText error>{t(errors.appDescription)}</FormHelperText>
                )}
              </Grid>
              <Grid item xs={12} mt={1}>
                <InputLabel>Fechas a justificar:</InputLabel>
                {data
                  .filter((attendance) =>
                    values.faultAssistans.some((checks) => checks === attendance.id)
                  )
                  .map((info) => (
                    <>
                      <Chip label={info.assistant_date} variant="outlined" sx={{ margin: '2px' }} />
                    </>
                  ))}
              </Grid>
              <DragArea
                handleChange={onChangeFile}
                description="Depositar su justificación lista en esta sección:"
                label="Subir justificación"
              />
              {errors.fileUrl && touched.fileUrl && (
                <FormHelperText error>{errors.fileUrl}</FormHelperText>
              )}
              <Grid container className={classes.card}>
                <Grid item xs={12} mt={'5px'} textOverflow={'ellipsis'}>
                  <Typography color={'primary'} variant="h5">
                    {t('teacherVirtualClassroom.materials.modal.file.info.format.title')}
                  </Typography>
                  <Typography color={'primary'} variant="body2">
                    {t('teacherVirtualClassroom.materials.modal.file.info.format.descripcion')}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                style={{
                  backgroundColor: '#FCEBEA',
                  padding: '5px',
                  marginTop: '15px',
                  borderRadius: '20px',
                }}
              >
                <Grid style={{ padding: '5px 12px' }} item mt={'5px'} textOverflow={'ellipsis'}>
                  <Typography color={'primary'} variant="h5">
                    {t('teacherVirtualClassroom.materials.modal.file.info.recomendate.title')}
                  </Typography>
                  <Typography mt={2} color={'primary'} variant="body2" noWrap={false}>
                    {t('teacherVirtualClassroom.materials.modal.file.info.recomendate.description')}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Stack>
        </DialogContent>
        {/* </PerfectScrollbar> */}
        <DialogActions>
          <Stack direction="row" spacing={2}>
            <Button
              variant="text"
              onClick={() => {
                // dispatch(resetCurrentGroup());
                // dispatch(resetSubjectCurriculumSelected());
                handleCloseDialog();
              }}
              color="primary"
            >
              {t('academic.requisition.edit.wizard.step2.add_academic_form.dialog.cancel')}
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isLoading}
              // endIcon={isLoadingFile}
            >
              {t('academic.requisition.edit.wizard.step2.add_academic_form.dialog.add')}
            </Button>
          </Stack>
        </DialogActions>
      </form>
    </ExtendedSideDialog>
  );
};

export default JustifyDialog;
