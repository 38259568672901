import { useEffect } from 'react';

import { Grid, IconButton, Typography, useTheme } from '@material-ui/core';
import { IconArrowNarrowLeft } from '@tabler/icons';
import { useHistory, useParams, useLocation } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from '../../../../hooks/useRedux';
import MainCard from '../../../shared/components/cards/MainCard';
// import DeleteRoleDialog from '../components/DeleteRoleDialog';

import {
  useLazyGetUserQuery,
  useLazyGetUserProfileQuery,
} from '../slices/userConfigurationsApiSlice';
import { setUsersList, setUserProfilesList } from '../slices/userConfigurationsSlice';
import { renderSkeletonTable } from '../../../shared/helpers/render';
import UserProfileRolesTable from '../components/UserProfileRolesTable';
import { useTranslation } from 'react-i18next';

const UserProfileRolesScreen = () => {
  const { t } = useTranslation();
  // const { roleIdToDelete } = useAppSelector((state) => state.roles);

  const theme = useTheme();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  const { userId, profileId } = useParams<{ userId: string; profileId: string }>();

  const { usersList, userProfileList } = useAppSelector((state) => state.usersConfiguration);

  const [getUser, { data, isFetching }] = useLazyGetUserQuery();
  const [getUserProfile, { data: dataProfile, isFetching: isFetchingProfile }] =
    useLazyGetUserProfileQuery();

  //refactorizar los useEffect repetitivos
  useEffect(() => {
    if (!usersList.length && !isFetching) {
      getUser({
        userId: +userId,
      });
    }
  }, [getUser, isFetching, userId, usersList.length]);

  useEffect(() => {
    if (data) dispatch(setUsersList([data!]));
  }, [data, dispatch]);

  useEffect(() => {
    if (!userProfileList.length && !isFetchingProfile) {
      getUserProfile({
        userId: +userId,
        profileId: +profileId,
      });
    }
  }, [getUserProfile, isFetchingProfile, profileId, userId, userProfileList.length]);

  useEffect(() => {
    if (dataProfile) dispatch(setUserProfilesList([dataProfile!]));
  }, [dataProfile, dispatch]);

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid item xs={12} sx={{ mb: 3 }}>
        <Grid item container alignItems="center" gap={1} sx={{ cursor: 'pointer' }}>
          <IconButton
            onClick={() =>
              history.push(
                `/${location.pathname.split('/')[1]}/${location.pathname.split('/')[2]}/${
                  location.pathname.split('/')[3]
                }/${location.pathname.split('/')[4]}`
              )
            }
          >
            <IconArrowNarrowLeft size={30} color={theme.palette.text.dark} />
          </IconButton>
          <Typography variant="h2">{t('configuration.users.roles')}</Typography>
        </Grid>
      </Grid>

      {Boolean(usersList.length) && Boolean(userProfileList.length) ? (
        <UserProfileRolesTable />
      ) : (
        renderSkeletonTable(5, 4)
      )}
      {/* {roleIdToDelete && <DeleteRoleDialog />} */}
    </Grid>
  );
};

export default UserProfileRolesScreen;
