import { Autocomplete, FormHelperText, TextField, Grid } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { FaqForm } from '../../interfaces/faq-form-interfaces';
import { Routes } from '../../../../shared/constants/router';
import { useTranslation } from 'react-i18next';

export const FaqEditSelectModule = () => {
  const { t } = useTranslation();

  const { setFieldValue, errors, touched, values } = useFormikContext<FaqForm>();

  const modules = Routes.map((a) => {
    return a.children?.map(
      (e) => ({ title: e.action?.toString()!, parent: a.action?.toString()!, path: e.url, key: a.id }!)
    );
  }).flat(1);

  return (
    <Grid container spacing={4} justifyContent="center" alignItems="flex-start">
      <Grid item xs={12} md={6} container spacing={2}>
        <Grid item xs={12} sx={{ mt: 5, mb: 5 }}>
          {modules && (
            <Autocomplete
              options={modules}
              loading={modules.length === 0}
              loadingText={t('configuration.faqs.stepper.step1.actions')}
              noOptionsText={t('configuration.faqs.stepper.step1.actions.no_data')}
              groupBy={(option) => option?.parent!}
              getOptionSelected={(option, value) => option.title === value.title}
              getOptionLabel={(option) => option?.title!}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(errors.faq_keyword && touched.faq_keyword)}
                  placeholder={t('configuration.faqs.stepper.step1.actions.placeholder')}
                />
              )}
              value={modules.find((e) => e?.path === values.faq_keyword) || null}
              onChange={(_, value) => {
                setFieldValue('faq_keyword', value?.path);
                setFieldValue('faq_module',value?.key);
              }}
              fullWidth
              clearIcon={null}
            />
          )}
          {errors.faq_keyword && touched.faq_keyword && (
            <FormHelperText error>{t(errors.faq_keyword)}</FormHelperText>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
