import { Grid, Typography } from '@material-ui/core';
import { useAppSelector } from '../../../../hooks/useRedux';
import MattersTable from '../components/MattersTable';
import DeleteMatterDialog from '../components/DeleteMatterDialog';
import MainCard from '../../../shared/components/cards/MainCard';
import { useTranslation } from 'react-i18next';

const MattersScreen = () => {
  const { t } = useTranslation();
  const { matterIdToDelete } = useAppSelector((state) => state.matters);

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">{t('academic.subjects.title')}</Typography>
      </Grid>

      <MattersTable />

      {matterIdToDelete && <DeleteMatterDialog />}
    </Grid>
  );
};

export default MattersScreen;
