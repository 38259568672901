import { CircularProgress, IconButton, Tooltip } from '@material-ui/core';
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone';
import { TablerIcon } from '@tabler/icons';
import { useTranslation } from 'react-i18next';

const EditButton = ({
  handleEdit,
  title,
  disabled,
  color,
  Icon,
  isLoading,
}: {
  handleEdit?: () => void;
  title?: string;
  disabled?: boolean;
  color?:
    | 'default'
    | 'inherit'
    | 'error'
    | 'success'
    | 'warning'
    | 'primary'
    | 'secondary'
    | 'info'
    | undefined;
  Icon?: TablerIcon;
  isLoading?: boolean;
}) => {
  const { t } = useTranslation();

  const icon = Icon ? <Icon /> : <EditTwoToneIcon sx={{ fontSize: '1.3rem' }} />;

  return (
    <Tooltip title={title ? title : t('shared.edit_button.tooltip')}>
      <IconButton
        disabled={disabled || isLoading || false}
        aria-label="Editar"
        color={color ? color : 'secondary'}
        onClick={handleEdit}
      >
        {isLoading ? <CircularProgress size={20} /> : icon}
      </IconButton>
    </Tooltip>
  );
};

export default EditButton;
