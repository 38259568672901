import Grid from '@material-ui/core/Grid';
import Stack from '@material-ui/core/Stack';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTranslation } from 'react-i18next';

const DialogLoading = () => {
  const { t } = useTranslation();

  return (
    <Grid container justifyContent="center" sx={{ height: 200 }}>
      <Stack spacing={2} alignItems="center">
        <Typography variant="h5" textAlign="center">
          {t('utils.loading_options')}
        </Typography>
        <CircularProgress size={30} />
      </Stack>
    </Grid>
  );
};

export default DialogLoading;
