import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/core/Skeleton';
import { useParams } from 'react-router-dom';

import { useGetProjectQuery } from '../slices/followUpOfResearchProjectApiSlice';
import Typography from '@material-ui/core/Typography';

interface Props {
  label: string;
  info?: string;
}

const ItemLabel = ({ info, label }: Props) => {
  return (
    <>
      <Typography variant="h5" component={'span'} color={'inherit'} id="label">
        {label}:
      </Typography>{' '}
      <Typography variant="h5" component={'span'}>
        {info}
      </Typography>
    </>
  );
};

const ProjectInfo = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const { data: projectData, isLoading: isLoadingProject } = useGetProjectQuery({
    projectId,
  });

  return (
    <Grid container spacing={2}>
      {isLoadingProject ? (
        <Skeleton sx={{ width: '100%', height: '50vh' }} />
      ) : (
        <Grid item container xs={12} spacing={1} mt={2}>
          <Grid item container xs={12} spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <ItemLabel label="Nombre" info={projectData?.name} />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <ItemLabel label="Programa" info={projectData?.program_name} />
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <ItemLabel label="Ciudad" info={projectData?.city.cat_name} />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <ItemLabel label="Descripción" info={projectData?.activity_description} />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <ItemLabel label="Provincia" info={projectData?.province.cat_name} />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <ItemLabel label="Objetivo" info={projectData?.program_goal} />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <ItemLabel label="Detalle" info={projectData?.field_detailed} />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <ItemLabel
                label="Fuente de financiación"
                info={projectData?.financial_source.description}
              />
            </Grid>
          </Grid>

          <Grid item container xs={12} spacing={3} mt={2}>
            <Grid item xs={12} md={6} lg={4}>
              <ItemLabel label="Alcance" info={projectData?.scope} />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <ItemLabel label="Tipo de proyecto" info={projectData?.type_project} />
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <ItemLabel label="Tipo de investigación" info={projectData?.type_research} />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <ItemLabel label="Estado del proyecto" info={projectData?.status_project} />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <ItemLabel label="Estado" info={projectData?.status} />
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <ItemLabel label="Fecha de inicio" info={projectData?.start_date.toLocaleString()} />
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <ItemLabel
                label="Fecha final planeada"
                info={projectData?.end_date_planned.toLocaleString()}
              />
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <ItemLabel
                label="Fecha final real"
                info={projectData?.end_date_real.toLocaleString()}
              />
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <ItemLabel label="Presupuesto" info={'$' + projectData?.planned_budget} />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default ProjectInfo;
