import { FormHelperText, Grid, TextField, Switch, Autocomplete, Skeleton } from '@material-ui/core';
import { useFormikContext } from 'formik';
import InputLabel from '../../../../shared/components/forms/InputLabel';

import DatePicker from '@material-ui/lab/DatePicker';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import esLocale from 'date-fns/locale/es';
import { journeyTypes } from '../../../../shared/constants/journeyType';
import { useAppSelector } from '../../../../../hooks/useRedux';
import { useGetEducationLevelsParentsAndChildrenQuery } from '../../../../maintenance/education-levels/slices/educationLevelsApiSlice';
import { statusAvailables } from '../../../../shared/constants/resourceStatus';
// import { Position } from '../../interfaces/collaborators.interfaces';
import { DepartmentAvailable } from '../../interfaces/departments.interfaces';
import { EducationLevel } from '../../../../maintenance/education-levels/interfaces/educationLevels.interfaces';
import { Campus } from '../../../../maintenance/campus/interfaces/campus.interfaces';
import { useGetAvailablesCampusQuery } from '../../../../maintenance/campus/slices/campusApiSlice';
import { useGetAvailableDepartmentsQuery } from '../../../../maintenance/departments/slices/departmentsApiSlice';
import { useEffect, useState } from 'react';
import { parseISO } from 'date-fns';
import { Position } from '../../../../maintenance/departments/interfaces/departments.interface';

interface JourneyType {
  id: string;
  label: string;
}
interface Status {
  id: number;
  name: string;
  color: string;
}
interface ContractDetailsForm {
  enterDate: Date;
  leftDate: Date;
  hasNombramiento: boolean;
  startNombramientoDate: Date;
  endNombramientoDate: Date;
  journeyType: JourneyType;
  dependencyRelation: boolean;
  journeyHours: number;
  collaboratorEmail: string;
  department: DepartmentAvailable;
  position: DepartmentAvailable;
  educationLevels: EducationLevel[];
  principalEducationLevel: EducationLevel;
  campus: Campus[];
  status: Status;
  isProvider: boolean;
}

const ContractDetailsStep = () => {
  const { currentProfile } = useAppSelector((state) => state.access);
  const { collaboratorData } = useAppSelector((state) => state.collaborators);
  const [positions, setPositions] = useState<Partial<Position>[]>([]);

  const profileId = currentProfile!.profileId;

  const { data: departments = [], isLoading: isLoadingDepartments } =
    useGetAvailableDepartmentsQuery({
      profileId,
    });

  const { data: educationLevels = [], isLoading: isLoadingEL } =
    useGetEducationLevelsParentsAndChildrenQuery({ profileId, filter: true });

  const { data: campus = [], isLoading: isLoadingCampus } = useGetAvailablesCampusQuery({
    profileId,
  });

  const { values, setFieldValue, errors, touched, getFieldProps, setTouched } = useFormikContext<
    ContractDetailsForm & { collaboratorType: string }
  >();

  useEffect(() => {
    if (departments && departments.length)
      setPositions(departments.find((d) => d.id === values.department?.id)?.positions || []);
  }, [departments, values]);

  useEffect(() => {
    if (collaboratorData && !values?.journeyType?.label) {
      alert('collaboratorData');
      setFieldValue(
        'journeyType',
        journeyTypes.find((jt) => jt.id === collaboratorData.coll_journey_description)
      );
      setFieldValue(
        'enterDate',
        collaboratorData.coll_entering_date ? parseISO(collaboratorData.coll_entering_date) : null
      );
      setFieldValue(
        'leftDate',
        collaboratorData.coll_leaving_date ? parseISO(collaboratorData.coll_leaving_date) : null
      );
      setFieldValue(
        'startNombramientoDate',
        collaboratorData.coll_nomination_entering_date
          ? parseISO(collaboratorData.coll_nomination_entering_date)
          : null
      );
      setFieldValue(
        'endNombramientoDate',
        collaboratorData.coll_nomination_leaving_date
          ? parseISO(collaboratorData.coll_nomination_leaving_date)
          : null
      );
      setFieldValue('hasNombramiento', Boolean(Number(collaboratorData.coll_has_nomination)));
      setFieldValue('journeyHours', collaboratorData.coll_journey_hours);
      setFieldValue('collaboratorEmail', collaboratorData.coll_email);
      setFieldValue('dependencyRelation', Boolean(Number(collaboratorData.coll_dependency)));
      setFieldValue('position', collaboratorData.position);
      setFieldValue('department', collaboratorData.position?.department);
      setFieldValue('principalEducationLevel', collaboratorData.education_level_principal);
      setFieldValue(
        'educationLevels',
        collaboratorData.education_levels?.map((el) => el.education_level)
      );
      setFieldValue(
        'campus',
        collaboratorData.campus.map((c) => (c as any).campus)
      );

      setFieldValue(
        'status',
        statusAvailables.find((s) => s.id === Number(collaboratorData.status_id))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collaboratorData]);

  useEffect(() => {
    setTouched({});
  }, [setTouched]);

  return (
    <Grid container spacing={4} alignItems="flex-start">
      <Grid item xs={12} md={6} container spacing={2}>
        <Grid item xs={12}>
          <InputLabel>Tipo de jornada</InputLabel>
          <Autocomplete
            {...getFieldProps('journeyType')}
            options={journeyTypes}
            getOptionLabel={(option) => option.label || ''}
            noOptionsText="No hay opciones"
            multiple={false}
            value={values.journeyType || null}
            onChange={(_, value) => {
              if (value && value.id === 'TP') {
                setFieldValue('isProvider', true);
              } else {
                setFieldValue('isProvider', false);
              }

              setFieldValue('journeyType', value);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                {...getFieldProps('journeyType')}
                placeholder="Seleccione jornada"
                error={Boolean(errors.journeyType && touched.journeyType)}
              />
            )}
            fullWidth
            clearIcon={null}
          />
          {errors.journeyType && touched.journeyType && (
            <FormHelperText error>{errors.journeyType}</FormHelperText>
          )}
        </Grid>

        {values.journeyType?.id === 'MT' && (
          <Grid item xs={12}>
            <InputLabel>¿Trabajará bajo relación de dependencia?</InputLabel>
            <Switch checked={values.dependencyRelation} {...getFieldProps('dependencyRelation')} />
          </Grid>
        )}

        {values.journeyType?.id === 'TP' && (
          <Grid item xs={12}>
            <InputLabel>Cant. Horas</InputLabel>
            <TextField
              {...getFieldProps('journeyHours')}
              type="number"
              fullWidth
              placeholder="Tiempo de jornada en horas"
              error={Boolean(errors.journeyHours && touched.journeyHours)}
            />
            {errors.journeyHours && touched.journeyHours && (
              <FormHelperText error>{errors.journeyHours}</FormHelperText>
            )}
          </Grid>
        )}

        <Grid item xs={12}>
          <InputLabel>Fecha de ingreso</InputLabel>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
            <DatePicker
              {...getFieldProps('enterDate')}
              value={values.enterDate}
              onChange={(newValue) => {
                setFieldValue('enterDate', newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  {...getFieldProps('enterDate')}
                  helperText=""
                  placeholder="Fecha de nacimiento"
                  error={Boolean(errors.enterDate && touched.enterDate)}
                  fullWidth
                />
              )}
            />
          </LocalizationProvider>
          {errors.enterDate && touched.enterDate && (
            <FormHelperText error>{errors.enterDate}</FormHelperText>
          )}
        </Grid>

        <Grid item xs={12}>
          <InputLabel>Fecha de salida</InputLabel>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
            <DatePicker
              {...getFieldProps('leftDate')}
              value={values.leftDate}
              onChange={(newValue) => {
                setFieldValue('leftDate', newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  {...getFieldProps('leftDate')}
                  helperText=""
                  placeholder="Fecha de nacimiento"
                  error={Boolean(errors.leftDate && touched.leftDate)}
                  fullWidth
                />
              )}
            />
          </LocalizationProvider>
          {errors.leftDate && touched.leftDate && (
            <FormHelperText error>{errors.leftDate}</FormHelperText>
          )}
        </Grid>

        {/* {values.collaboratorType !== 'D' && (
          <> */}
        <Grid item xs={12}>
          <InputLabel>Departamento(Opcional)</InputLabel>
          {isLoadingDepartments ? (
            <Skeleton variant="rectangular" sx={{ width: '100%', borderRadius: 3, height: 50 }} />
          ) : (
            <Autocomplete
              {...getFieldProps('department')}
              options={departments}
              loading={isLoadingDepartments}
              getOptionLabel={(option) => option.dep_name || ''}
              multiple={false}
              value={values.department || null}
              noOptionsText="No hay opciones"
              onChange={(_, value) => setFieldValue('department', value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Seleccione un departamento"
                  error={Boolean(errors.department && touched.department)}
                  {...getFieldProps('department')}
                />
              )}
              fullWidth
              clearIcon={null}
            />
          )}
          {errors.department && touched.department && (
            <FormHelperText error>{errors.department}</FormHelperText>
          )}
        </Grid>

        <Grid item xs={12}>
          <InputLabel>Cargo o posición(Opcional)</InputLabel>
          <Autocomplete
            {...getFieldProps('position')}
            options={positions}
            noOptionsText={
              'No ha seleccionado departamento o no existen cargos asociados al departamento.'
            }
            getOptionLabel={(option) => option.pos_name || ''}
            value={values.position || []}
            onChange={(_, value) => setFieldValue('position', value)}
            renderInput={(params) => (
              <TextField
                {...params}
                {...getFieldProps('position')}
                placeholder="Seleccione un cargo o posición"
                error={Boolean(errors.position && touched.position)}
              />
            )}
            fullWidth
            clearIcon={null}
          />
          {errors.position && touched.position && (
            <FormHelperText error>{errors.position}</FormHelperText>
          )}
        </Grid>
        <Grid item xs={12}>
          <InputLabel>Unidades académicas</InputLabel>
          {isLoadingEL ? (
            <Skeleton variant="rectangular" sx={{ width: '100%', borderRadius: 3, height: 50 }} />
          ) : (
            <Autocomplete
              {...getFieldProps('educationLevels')}
              options={educationLevels}
              noOptionsText="No hay opciones"
              multiple
              loading={isLoadingEL}
              getOptionLabel={(option) => option.edu_name || ''}
              value={values.educationLevels || []}
              onChange={(_, values) => setFieldValue('educationLevels', [...values.map((v) => v)])}
              renderInput={(params) => (
                <TextField
                  {...params}
                  {...getFieldProps('educationLevels')}
                  placeholder="Seleccione las unidades académicas"
                  error={Boolean(errors.educationLevels && touched.educationLevels)}
                />
              )}
              fullWidth
              clearIcon={null}
            />
          )}
          {errors.educationLevels && touched.educationLevels && (
            <FormHelperText error>{errors.educationLevels}</FormHelperText>
          )}
        </Grid>

        <Grid item xs={12}>
          <InputLabel>Unidad académica principal</InputLabel>
          {isLoadingEL ? (
            <Skeleton variant="rectangular" sx={{ width: '100%', borderRadius: 3, height: 50 }} />
          ) : (
            <Autocomplete
              {...getFieldProps('principalEducationLevel')}
              options={values.educationLevels || []}
              loading={isLoadingEL}
              getOptionLabel={(option) => option.edu_name || ''}
              noOptionsText="No hay opciones"
              value={values.principalEducationLevel || null}
              renderInput={(params) => (
                <TextField
                  {...params}
                  {...getFieldProps('principalEducationLevel')}
                  placeholder="Seleccione una unidad académica principal"
                  error={Boolean(errors.principalEducationLevel && touched.principalEducationLevel)}
                />
              )}
              onChange={(_, value) => setFieldValue('principalEducationLevel', value)}
              fullWidth
              clearIcon={null}
            />
          )}
          {errors.principalEducationLevel && touched.principalEducationLevel && (
            <FormHelperText error>{errors.principalEducationLevel}</FormHelperText>
          )}
        </Grid>

        <Grid item xs={12}>
          <InputLabel>Campus</InputLabel>
          {isLoadingCampus ? (
            <Skeleton variant="rectangular" sx={{ width: '100%', borderRadius: 3, height: 50 }} />
          ) : (
            <Autocomplete
              {...getFieldProps('campus')}
              options={campus}
              noOptionsText="No hay opciones"
              multiple
              loading={isLoadingCampus}
              getOptionLabel={(option) => option.cam_name || ''}
              value={values.campus || []}
              onChange={(_, values) => setFieldValue('campus', [...values.map((v) => v)])}
              renderInput={(params) => (
                <TextField
                  {...params}
                  {...getFieldProps('campus')}
                  placeholder="Seleccione un campus"
                  error={Boolean(errors.campus && touched.campus)}
                />
              )}
              fullWidth
              clearIcon={null}
            />
          )}
          {errors.campus && touched.campus && (
            <FormHelperText error>{errors.campus}</FormHelperText>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12} md={6} container spacing={2}>
        <Grid item xs={12}>
          <InputLabel>Correo institucional</InputLabel>
          <TextField
            fullWidth
            placeholder="Ej: alberto@org.edu.ec"
            error={Boolean(errors.collaboratorEmail && touched.collaboratorEmail)}
            {...getFieldProps('collaboratorEmail')}
          />
          {errors.collaboratorEmail && touched.collaboratorEmail && (
            <FormHelperText error>{errors.collaboratorEmail}</FormHelperText>
          )}
        </Grid>

        <Grid item xs={12}>
          <InputLabel>¿Posee nombramiento?</InputLabel>
          <Switch checked={values.hasNombramiento} {...getFieldProps('hasNombramiento')} />
        </Grid>

        {values.hasNombramiento && (
          <>
            <Grid item xs={12}>
              <InputLabel>Fecha de nombramiento</InputLabel>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                <DatePicker
                  {...getFieldProps('startNombramientoDate')}
                  value={values.startNombramientoDate}
                  onChange={(newValue) => {
                    setFieldValue('startNombramientoDate', newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      {...getFieldProps('startNombramientoDate')}
                      helperText=""
                      placeholder="Fecha de nombramiento"
                      error={Boolean(errors.startNombramientoDate && touched.startNombramientoDate)}
                      fullWidth
                    />
                  )}
                />
              </LocalizationProvider>
              {errors.startNombramientoDate && touched.startNombramientoDate && (
                <FormHelperText error>{errors.startNombramientoDate}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12}>
              <InputLabel>Fin de nombramiento</InputLabel>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                <DatePicker
                  {...getFieldProps('endNombramientoDate')}
                  value={values.endNombramientoDate}
                  onChange={(newValue) => {
                    setFieldValue('endNombramientoDate', newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      {...getFieldProps('endNombramientoDate')}
                      helperText=""
                      placeholder="Fin de nombramiento"
                      error={Boolean(errors.endNombramientoDate && touched.endNombramientoDate)}
                      fullWidth
                    />
                  )}
                />
              </LocalizationProvider>
              {errors.endNombramientoDate && touched.endNombramientoDate && (
                <FormHelperText error>{errors.endNombramientoDate}</FormHelperText>
              )}
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <InputLabel>Estado</InputLabel>
          <Autocomplete
            {...getFieldProps('status')}
            options={statusAvailables}
            noOptionsText="No hay opciones"
            getOptionLabel={(option) => option.name || ''}
            value={values.status || null}
            defaultValue={statusAvailables?.find(
              (statusAvailable) => statusAvailable.id === Number(collaboratorData?.status_id || 0)
            )}
            onChange={(e, value) => setFieldValue('status', value)}
            renderInput={(params) => (
              <TextField
                {...params}
                {...getFieldProps('status')}
                placeholder="Seleccione un estado"
                error={Boolean(errors.status && touched.status)}
              />
            )}
            fullWidth
            clearIcon={null}
          />
          {errors.status && touched.status && (
            <FormHelperText error>{errors.status}</FormHelperText>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ContractDetailsStep;
