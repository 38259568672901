import { Switch, Redirect } from 'react-router-dom';

import { useAppSelector } from '../../../../hooks/useRedux';

import RequisitionScreen from '../screens/RequisitionScreen';
import AddRequisitionScreen from '../screens/AddRequisitionScreen';
import editRequisitionScreen from '../screens/EditRequisitionScreen';

import GuardRoute from '../../../../router/guards/GuardRoute';

import { subPermissionKeys } from '../constants/permissions';

const RequisitionRouter = () => {
  const { currentModule } = useAppSelector((state) => state.access);

  const hasPermission = subPermissionKeys.groupKey in currentModule?.permissions!;

  if (!hasPermission) return <Redirect to="/dashboard" />;

  return (
    <Switch>
      <GuardRoute
        exact
        path="/administrar-requisiciones-docentes"
        component={RequisitionScreen}
        permissionKey={subPermissionKeys.groupKey}
        subPermissionKey={subPermissionKeys.read}
      />
      <GuardRoute
        exact
        path="/administrar-requisiciones-docentes/agregar"
        component={AddRequisitionScreen}
        permissionKey={subPermissionKeys.groupKey}
        subPermissionKey={subPermissionKeys.create}
      />
      <GuardRoute
        exact
        path="/administrar-requisiciones-docentes/:code/revisar-solicitud"
        component={editRequisitionScreen}
        permissionKey={subPermissionKeys.groupKey}
        subPermissionKey={subPermissionKeys.edit}
      />
    </Switch>
  );
};

export default RequisitionRouter;
