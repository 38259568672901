import * as Yup from 'yup';

export const AreaFormSchema = Yup.object().shape({
  ar_name: Yup.string().required('academic.areas.add.name.required'),
  ar_description: Yup.string().required('academic.areas.add.description.required'),
  ar_keywords: Yup.string()
    .length(3, 'academic.areas.add.keyword.max')
    .required('academic.areas.add.keyword.required'),
  status_id: Yup.number().required('academic.areas.add.status.required'),
});
