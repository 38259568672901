import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Pensum } from '../interfaces/pensums.interface';

interface PensumsState {
  pensumsList: Pensum[];
  deleteModalOpen: boolean;
  pensumIdToDelete?: number;
}

const initialState: PensumsState = {
  pensumsList: [],
  deleteModalOpen: false,
};

const PensumsSlice = createSlice({
  name: 'Pensums',
  initialState,
  reducers: {
    setPensumList: (state, action: PayloadAction<Pensum[]>) => {
      state.pensumsList = action.payload;
    },
    setDeleteModalOpen: (state, action: PayloadAction<boolean>) => {
      state.deleteModalOpen = action.payload;
    },
    openDeleteModalWith: (state, action: PayloadAction<number>) => {
      state.pensumIdToDelete = action.payload;
      state.deleteModalOpen = true;
    },
    resetPensumIdToDelete: (state) => {
      state.pensumIdToDelete = undefined;
    },
  },
});

export const {
  setPensumList,
  setDeleteModalOpen,
  openDeleteModalWith,
  resetPensumIdToDelete,
} = PensumsSlice.actions;

export default PensumsSlice.reducer;