export const permissionKey = 'student';

export interface SubPermissionKeys {
  read: string;
  create: string;
  edit: string;
  delete: string;
}

export const subPermissionKeys: SubPermissionKeys = {
  read: 'student-listar-estudiante',
  create: 'student-listar-estudiante',
  edit: 'student-listar-estudiante',
  delete: 'student-listar-estudiante',
};