import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISearchTable } from '../../../shared/interfaces/paginatedTable.interface';
import { GroupArea } from '../interfaces/groupAreas.interfaces';

interface GroupAreasState {
  groupAreaList: GroupArea[];
  deleteModalOpen: boolean;
  groupAreaIdToDelete?: number;
  groupAreasTable: ISearchTable;
}

const initialState: GroupAreasState = {
  groupAreaList: [],
  deleteModalOpen: false,
  groupAreasTable: {
    page: 1,
    perPage: 5,
    search: '',
  },
};

const groupAreasSlice = createSlice({
  name: 'groupAreas',
  initialState,
  reducers: {
    setGroupAreaList: (state, action: PayloadAction<GroupArea[]>) => {
      state.groupAreaList = action.payload;
    },
    setDeleteModalOpen: (state, action: PayloadAction<boolean>) => {
      state.deleteModalOpen = action.payload;
    },
    openDeleteModalWith: (state, action: PayloadAction<number>) => {
      state.groupAreaIdToDelete = action.payload;
      state.deleteModalOpen = true;
    },
    resetGroupAreaIdToDelete: (state) => {
      state.groupAreaIdToDelete = undefined;
    },
    updateGroupAreasTable: (state, action: PayloadAction<ISearchTable>) => {
      state.groupAreasTable = action.payload;
    },
  },
});

export const {
  setGroupAreaList,
  setDeleteModalOpen,
  openDeleteModalWith,
  resetGroupAreaIdToDelete,
  updateGroupAreasTable,
} = groupAreasSlice.actions;

export default groupAreasSlice.reducer;
