import { useHistory } from 'react-router-dom';

import { IconButton, Grid, useTheme, Typography } from '@material-ui/core';
import { IconArrowNarrowLeft } from '@tabler/icons';
import { useAppSelector } from '../../../../hooks/useRedux';
import CollectiveTable from '../components/CollectiveTable'
import MainCard from '../../../shared/components/cards/MainCard';
import DialogCollectives from '../components/dialog/DialogCollCollectives';
import DeleteCollectiveAlert from '../components/DeleteCollective';

const CollectiveTableScreen = () => {
  const theme = useTheme();

  const history = useHistory();

  const { currentEducationLevelId,
    educationLevelList,
    openCollaboratorsCollective,
    deleteCollectiveModalOpen
  } = useAppSelector((state) => state.educationLevels);

  const educationLevelSelected = educationLevelList.find((value) => value.id === currentEducationLevelId);

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid item container alignItems="center" gap={1} sx={{ cursor: 'pointer', marginBottom: 5 }}>
        <IconButton onClick={() => history.goBack()}>
          <IconArrowNarrowLeft size={30} color={theme.palette.text.dark} />
        </IconButton>
        <Typography variant="h2">Listado Colectivos - {educationLevelSelected && educationLevelSelected.edu_name}</Typography>
      </Grid>
      <CollectiveTable />
      {openCollaboratorsCollective && <DialogCollectives />}
      {deleteCollectiveModalOpen && <DeleteCollectiveAlert />}
    </Grid>
  );
};

export default CollectiveTableScreen;
