import { Autocomplete, Box, FormHelperText, InputLabel, TextField } from '@material-ui/core';
import TextFieldFetching from '../../../../../../shared/components/TextFieldFetching';
import DeleteButton from '../../../../../../shared/components/tables/buttons/DeleteButton';
import { useLazyGetInvestigationLinesByEducationLevelQuery } from '../../../../slices/InvestigationLinesApiSlice';
import { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { EducationLevel } from '../../../../../education-levels/interfaces/educationLevels.interfaces';

interface LinesInvestigactionSecundaryItemProps {
  idx: number;
  item: {
    educationLevelId: string;
    linesId: string;
  };
  handleRemoveAditional: (index: number) => void;
  aditionalLines: {
    educationLevelId: string;
    linesId: string;
  }[];
  setAditionalLines: React.Dispatch<
    React.SetStateAction<
      {
        educationLevelId: string;
        linesId: string;
      }[]
    >
  >;
  facultadAvailables: EducationLevel[] | undefined;
  isLoadingFacultad: boolean;
}

const LinesInvestigactionSecundaryItem = ({
  idx,
  item,
  handleRemoveAditional,
  aditionalLines,
  setAditionalLines,
  facultadAvailables,
  isLoadingFacultad,
}: LinesInvestigactionSecundaryItemProps) => {
  const { t } = useTranslation();

  const { values, errors, touched, setFieldValue } = useFormikContext<{
    cityId: number;
    provinceId: number;
    educationLevels: {
      id: number | string | null;
      education_level_id: number;
      is_principal: boolean;
    }[];
    educationLevelsPrincipal: string | undefined;
    linesGroupResearch: string[];
    linesGroupResearchPrincipal: string | undefined;
    investigationLines: string[] | undefined;
    investigationLinesPrincipal: string | undefined;
    typeResearch: string;
  }>();

  const [educationLevelId, setEducationLevelId] = useState<number | null>(null);

  const [
    getInvestigationLinesByEducationalLevel,
    { data: investigationLinesAvailables, isLoading: isLoadingInvestigationLines },
  ] = useLazyGetInvestigationLinesByEducationLevelQuery();

  useEffect(() => {
    if (educationLevelId) {
      const executeGetInvestigationLines = async () => {
        await getInvestigationLinesByEducationalLevel({
          educationLevel: educationLevelId,
        });
      };
      executeGetInvestigationLines();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [educationLevelId]);

  return (
    <Box
      key={idx}
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap',
        marginBottom: '15px',
      }}
    >
      {/* unidad academica */}
      <Box
        sx={{
          width: '40%',
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
      >
        <InputLabel>{t('academic.subjects.add.academic_unit')}</InputLabel>
        {isLoadingFacultad ? (
          <TextFieldFetching />
        ) : (
          <Autocomplete
            options={facultadAvailables || []}
            loading={isLoadingFacultad}
            loadingText={t('academic.subjects.add.edu_level.loading')}
            noOptionsText={t('academic.subjects.add.edu_level.no_data')}
            getOptionLabel={(option) => option.edu_name}
            renderInput={(params) => (
              <TextField
                {...params}
                error={Boolean(errors.educationLevels && touched.educationLevels)}
                placeholder={t('academic.subjects.add.edu_level.placeholder')}
              />
            )}
            value={
              facultadAvailables?.find(({ id }) => id === Number(item.educationLevelId)) || null
            }
            onChange={(_, value) => {
              const isExistEducationLevelId = values?.educationLevels?.find(
                (e) => Number(e.education_level_id) === Number(value?.id)
              );

              if (!isExistEducationLevelId) {
                setEducationLevelId(value?.id || null);
                setFieldValue('educationLevels', [
                  ...values.educationLevels,
                  {
                    id: null,
                    education_level_id: value?.id,
                    is_principal: false,
                  },
                ]);
              }

              const newAdicionalLines = [...aditionalLines];

              newAdicionalLines[idx].educationLevelId = value?.id.toString()!;

              setAditionalLines(newAdicionalLines);
            }}
            fullWidth
            clearIcon={null}
          />
        )}
        {errors.educationLevels && touched.educationLevels && (
          <FormHelperText error>{errors.educationLevels}</FormHelperText>
        )}
      </Box>
      {/* lineas de investigacion */}
      <Box
        sx={{
          width: '40%',
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
      >
        <InputLabel> {t('project.specificDetail.investigationLines.title')} </InputLabel>
        {isLoadingInvestigationLines ? (
          <TextFieldFetching />
        ) : (
          <Autocomplete
            options={
              investigationLinesAvailables
                ? investigationLinesAvailables.filter(
                    (line) => values?.investigationLines?.find((id) => id === line.id) === undefined
                  )
                : []
            }
            loading={isLoadingInvestigationLines}
            value={
              investigationLinesAvailables?.find(({ id }) => id === item.linesId.toString()) || null
            }
            loadingText={t('academic.subjects.add.edu_level.loading')}
            noOptionsText={t('project.specificDetail.investigationLines.no_option')}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                error={Boolean(errors.investigationLines && touched.investigationLines)}
                placeholder={t('project.specificDetail.investigationLines.placeholder')}
              />
            )}
            onChange={(_, value) => {
              const newAdicionalLines = [...aditionalLines];

              newAdicionalLines[idx].linesId = value?.id.toString()!;

              const isExistLinesId = values?.investigationLines?.find(
                (e) => e === value?.id.toString()
              );

              if (!isExistLinesId) {
                setFieldValue('investigationLines', [...values.investigationLines!, value?.id]);
              }
              setAditionalLines(newAdicionalLines);
            }}
            fullWidth
            clearIcon={null}
          />
        )}
        {errors.investigationLines && touched.investigationLines && (
          <FormHelperText error>{errors.investigationLines}</FormHelperText>
        )}
      </Box>
      {/* eliminar */}
      <Box
        sx={{
          width: '10%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <DeleteButton
          handleDelete={() => {
            handleRemoveAditional(idx);
          }}
        />
      </Box>
    </Box>
  );
};

export default LinesInvestigactionSecundaryItem;
