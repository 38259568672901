import { Box, useTheme } from '@material-ui/core';
import { useMemo, useState } from 'react';
import TableDetailsAttendance from './TableDetailsAttendance';
import TableHeaderAttendance from './TableHeaderAttendance';
import { Attendance } from '../interfaces/attendance.interfaces';
import {
  AtrasoInjustificado,
  AtrasoJustificado,
  FaltaInjustificada,
  FaltaJustificada,
  FaltaRectificada,
  months,
} from '../constants';
import CustomPaginationAndItems from '../../../../shared/components/tables/CustomPaginationAndItems';
import { useGetAttendancePaginationQuery } from '../../slices/virtualClassroomApiSlice';
import { useAppSelector } from '../../../../../hooks/useRedux';
import { useTranslation } from 'react-i18next';

interface Props {
  attendance: Attendance[];
  selectDate: string;
}

const TableAttendance = ({ attendance, selectDate }: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [viewDetails, setViewDetails] = useState<boolean>(true);
  const handleClick = () => {
    setViewDetails(!viewDetails);
  };
  const { fj, fi, aj, ai, fr } = useMemo(() => {
    const fj = attendance.filter((item) => item.type_fault === FaltaJustificada).length;
    const fi = attendance.filter((item) => item.type_fault === FaltaInjustificada).length;
    const aj = attendance.filter((item) => item.type_fault === AtrasoJustificado).length;
    const ai = attendance.filter((item) => item.type_fault === AtrasoInjustificado).length;
    const fr = attendance.filter((item) => item.type_fault === FaltaRectificada).length;
    return {
      fj,
      fi,
      aj,
      ai,
      fr,
    };
  }, [attendance]);

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(5);
  const {
    course: { courseId },
  } = useAppSelector((state) => state.virtualClassroomStudentShared);

  const { currentStudent } = useAppSelector((state) => state.access);

  const { data, isLoading, error } = useGetAttendancePaginationQuery({
    courseId: courseId?.toString()!,
    studentId: currentStudent?.id.toString()!,
    month: selectDate,
    page,
    size,
  });

  const getMonth = () => {
    return months.find((item) => item.value === selectDate)?.text!;
  };

  return (
    <>
      {data && data.data && (
        <Box
          sx={{
            border: `1px solid ${theme.palette.secondary.main}`,
            borderRadius: '8px',
            mt: 6,
          }}
        >
          <TableHeaderAttendance
            onClick={handleClick}
            data={{
              month: getMonth(),
              fi: `${t('studentVirtualClassroom.attendance.unjustified.absence')}: ${fi}`,
              fj: `${t('studentVirtualClassroom.attendance.justified.absence')}: ${fj}`,
              fr: `${t('studentVirtualClassroom.attendance.rectified.absence')}: ${fr}`,
              ai: `${t('studentVirtualClassroom.attendance.unjustified.delay')}: ${ai}`,
              aj: `${t('studentVirtualClassroom.attendance.justified.delay')}: ${aj}`,
            }}
            viewDetails={viewDetails}
          />
          {viewDetails && <TableDetailsAttendance attendance={data.data} />}
        </Box>
      )}
      <CustomPaginationAndItems
        error={error}
        isLoading={isLoading}
        page={page}
        perPage={size}
        setPage={setPage}
        setPerPage={setSize}
        total={data?.total!}
        paginationBox={true}
        perPageBox={true}
      />
    </>
  );
};

export default TableAttendance;
