import {
  Autocomplete,
  Badge,
  Chip,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@material-ui/core';
import { IconCalendar, IconCheck, IconEdit, IconEye, IconUserPlus } from '@tabler/icons';
import { isValid, parseISO } from 'date-fns';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { useTableSearch } from '../../../../hooks/useTableSearch';
import SubCard from '../../../shared/components/cards/SubCard';
import LocalizedDateRangePicker from '../../../shared/components/controlled/LocalizedDateRangePicker';
import DeleteButton from '../../../shared/components/tables/buttons/DeleteButton';
import CustomCollapsibleTable from '../../../shared/components/tables/CustomCollapsibleTable';
import { FilterArrow } from '../../../shared/components/tables/SearchPaginatedTable';
import TextFieldFetching from '../../../shared/components/TextFieldFetching';
import { useGetAvailablesCampusQuery } from '../../campus/slices/campusApiSlice';
import { useGetStudyModalitiesQuery } from '../../meshs/slices/meshsApiSlice';
import { days } from '../constants/days';
import { posgraduateStatus } from '../constants/status';
import { Hourhand } from '../interfaces/postgraduatePlanification.interface';
import { useGetPosgraduatePlanificationsListQuery } from '../slices/acPlanPostgraduateApiSlice';
import {
  setCalendarIdSelected,
  setCampusIdSelected,
  setDatesArraySelected,
  setModalityIdSelected,
  setPostgraduateDeleteProgramDialogIdSelected,
  setPostgraduateDeleteProgramDialogOpen,
  setPostGraduatePlanificationList,
  // setPostgraduatePlanificationApproveDialogOpen,
  // setPostGraduatePlanificationIdSelected,
  setPostgraduatePlanificationTable,
  setStatusIdSelected,
} from '../slices/acPlanPostgraduateSlice';
import ChangeProgramRenameDialog from './dialogs/ChangeProgramRenameDialog';
import useSharedGuard from '../../../../hooks/useSharedGuard';
import { subPermissionKeys } from '../constants/permissions';

const PlanificationListTable = () => {
  const { t } = useTranslation();

  const history = useHistory();
  const theme = useTheme();

  const { currentProfile } = useAppSelector((state) => state.access);
  const {
    postgraduatePlanificationTable,
    datesArraySelected,
    campusIdSelected,
    statusIdSelected,
    modalityIdSelected,
  } = useAppSelector((state) => state.academicPlanningPosgraduate);

  const [filterArrow, setFilterArrow] = useState<FilterArrow>({
    name: 'id',
    direction: 'asc',
  });

  const [openDialogRename, setOpenDialogRename] = useState(false);
  const [programIdSelected, setProgramIdSelected] = useState<string | null>(null);

  const dispatch = useAppDispatch();

  const { hasAccess } = useSharedGuard(subPermissionKeys);

  const { page, perPage, search } = postgraduatePlanificationTable;

  const { setPage, setPerPage, setSearch } = useTableSearch({
    page,
    perPage,
    search,
    updateFunction: setPostgraduatePlanificationTable,
  });
  // eslint-disable-next-line
  const { data, error, isLoading, isFetching } = useGetPosgraduatePlanificationsListQuery(
    {
      profileId: currentProfile?.profileId!,
      page,
      search,
      size: perPage,
      sort: filterArrow?.name || 'id',
      typeSort: filterArrow?.direction || 'asc',
      ...(campusIdSelected && { campusId: campusIdSelected }),
      ...(statusIdSelected && { status: statusIdSelected }),
      ...(modalityIdSelected && { modalityId: modalityIdSelected }),
      ...(datesArraySelected?.[0] &&
        isValid(parseISO(datesArraySelected[0])) && {
          startDate: datesArraySelected[0],
        }),
      ...(datesArraySelected?.[1] &&
        isValid(parseISO(datesArraySelected[1])) && {
          endDate: datesArraySelected[1],
        }),
    },
    {
      skip: !currentProfile?.profileId,
    }
  );

  const { data: campusAvailables, isLoading: isLoadingC } = useGetAvailablesCampusQuery({
    profileId: currentProfile?.profileId!,
  });

  const { data: modalityAvailables, isLoading: isLoadingM } = useGetStudyModalitiesQuery({
    profileId: currentProfile?.profileId!,
  });

  useEffect(() => {
    if (data?.data) {
      dispatch(setPostGraduatePlanificationList(data.data!));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const dataFetch =
    data?.data?.map((item, index) => ({
      details: '',
      id: index + ((page - 1) * perPage + 1),
      namePeriod: item.name_period,
      campus: item.campus?.cam_name,
      modality: item.modality?.cat_name,
      numFees: item.number_fees_period_stages,
      start: item.start_process,
      end: item.end_process,
      statusPlanning: item.postgraduate_planning_status?.status_planning,
      options: {
        id: item.id,
        programId: item.postgraduate_temporary_planning?.id,
        idTransactionPlanning: item.id_transaction_planning,
        validateTeacher: item.validate_teacher ? Number(item.validate_teacher) : 0,
        calendarId: item.postgraduate_temporary_planning?.planning_calendar_id,
        renamePeriod: item.rename_period,
      },
    })) || [];

  const handleApprove = (id: string) => {
    history.push(`/listado-planeacion-academica-posgrado/${id}/fechas`);
    // dispatch(setPostGraduatePlanificationIdSelected(id));
    // dispatch(setPostgraduatePlanificationApproveDialogOpen(true));
  };

  const handleReview = (id: string, month: number, year: number, calendarId: string) => {
    dispatch(setCalendarIdSelected(calendarId));
    history.push(
      `/planeacion-academica-posgrado/fechas-carrera/${id}/planeacion-carrera?month=${month}&year=${year}`
    );
  };

  const handleAddTeacher = (id: string, month: number, year: number, calendarId: string) => {
    dispatch(setCalendarIdSelected(calendarId));
    history.push(
      `/planeacion-academica-posgrado/fechas-carrera/${id}/planeacion-carrera?month=${month}&year=${year}&complete=1`
    );
  };

  const handleDelete = (id: string) => {
    dispatch(setPostgraduateDeleteProgramDialogIdSelected(id));
    dispatch(setPostgraduateDeleteProgramDialogOpen(true));
  };

  const renderCollapsibleItems = (index: number) => (
    <TableContainer>
      <SubCard
        sx={{
          mb: 2,
        }}
      >
        <Table>
          <TableHead>
            <TableRow hover sx={{ backgroundColor: theme.palette.grey[200] }}>
              {days.map((day, index) => (
                <TableCell align="center" key={index}>
                  {t(`utils.${day.keyword}`)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.data?.[index]?.postgraduate_planning_hourhands?.map((ph) => (
              <TableRow key={index}>
                {days.map((day, index) => (
                  <TableCell align="center" key={index}>
                    {ph?.hourhand?.[`hour_${day.keyword}` as keyof Hourhand] === '1' ? (
                      <>
                        {t('posgraduate.table.from')}:{' '}
                        {ph?.hourhand?.[`hour_start_time_${day.keyword}` as keyof Hourhand]} <br />
                        {t('posgraduate.table.to')}:{' '}
                        {ph?.hourhand?.[`hour_end_time_${day.keyword}` as keyof Hourhand]}
                      </>
                    ) : (
                      <> - </>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </SubCard>
      {data?.data?.[index]?.period && (
        <SubCard>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">{t('posgraduate.table.subject')}</TableCell>
                <TableCell align="center">{t('posgraduate.table.classroom_parallel')}</TableCell>
                <TableCell align="center">{t('posgraduate.table.quota')}</TableCell>
                <TableCell align="center">{t('posgraduate.table.teacher')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.data?.[index]?.period?.courses?.map((item) => (
                <TableRow key={item.id}>
                  <TableCell align="center">{item?.matter?.mat_description}</TableCell>
                  <TableCell align="center">
                    {data?.data?.[index]?.class_room?.cl_name} -{' '}
                    {data?.data?.[index]?.parallel?.par_name}{' '}
                  </TableCell>
                  <TableCell align="center">{data?.data?.[index]?.capacity}</TableCell>
                  <TableCell align="center">
                    {item?.collaborator?.user?.person?.pers_full_name}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </SubCard>
      )}
    </TableContainer>
  );

  useEffect(
    () => {
      setPage(1);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [datesArraySelected, campusIdSelected, statusIdSelected, modalityIdSelected]
  );

  return (
    <Grid item xs={12}>
      <CustomCollapsibleTable
        data={dataFetch}
        keyExtractor={(item) => item.id.toString()}
        numHeader={10}
        headers={{
          details: t('posgraduate.table.details'),
          id: t('posgraduate.table.id'),
          namePeriod: t('posgraduate.table.name_period'),
          campus: t('posgraduate.table.campus'),
          modality: t('posgraduate.table.modality'),
          numFees: t('posgraduate.table.num_fees'),
          start: t('posgraduate.table.start'),
          end: t('posgraduate.table.end'),
          statusPlanning: t('posgraduate.table.status'),
          options: t('posgraduate.table.options'),
        }}
        listFilterArrows={{
          namePeriod: 'name_period',
          numFees: 'number_fees_period_stages',
          start: 'start_process',
          end: 'end_process',
        }}
        filterArrow={filterArrow}
        setFilterArrow={setFilterArrow}
        customRenderers={{
          statusPlanning: (item) => (
            <Chip
              label={item.statusPlanning}
              style={{
                backgroundColor: item.statusPlanning === 'pendiente' ? '#ffc107' : '#28a745',
                borderColor: item.statusPlanning === 'pendiente' ? '#ffc107' : '#28a745',
                color: item.statusPlanning === 'pendiente' ? '#000' : '#fff',
              }}
              variant="outlined"
            />
          ),
          namePeriod: (item) => (
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="body2">{item.namePeriod}</Typography>
                {item.options.renamePeriod && (
                  <Typography variant="subtitle1">{item.options.renamePeriod}</Typography>
                )}
              </Grid>
            </Grid>
          ),
          options: (item) => (
            <>
              {item.options.validateTeacher > 0 ? (
                <>
                  {hasAccess('getPlaningPostgraduate') && (
                    <Tooltip title={t('posgraduate.table.add_teacher')}>
                      <IconButton
                        onClick={() =>
                          handleAddTeacher(
                            item.options.programId,
                            new Date(item.start).getMonth() + 1,
                            new Date(item.start).getFullYear(),
                            item.options.calendarId
                          )
                        }
                      >
                        <Badge badgeContent={item.options.validateTeacher} color="error">
                          <IconUserPlus />
                        </Badge>
                      </IconButton>
                    </Tooltip>
                  )}
                </>
              ) : (
                <>
                  {hasAccess('planingsProgramsChangeStatus') && (
                    <Tooltip
                      title={
                        item.statusPlanning === 'ejecutada'
                          ? t('posgraduate.table.view_dates')
                          : t('posgraduate.approve_planning')
                      }
                    >
                      <IconButton onClick={() => handleApprove(item.options.idTransactionPlanning)}>
                        {item.statusPlanning === 'ejecutada' ? <IconCalendar /> : <IconCheck />}
                      </IconButton>
                    </Tooltip>
                  )}
                </>
              )}

              {hasAccess('getPlaningPostgraduate') && (
                <Tooltip title={t('posgraduate.table.review')}>
                  <IconButton
                    onClick={() =>
                      handleReview(
                        item.options.programId,
                        new Date(item.start).getMonth() + 1,
                        new Date(item.start).getFullYear(),
                        item.options.calendarId
                      )
                    }
                  >
                    <IconEye />
                  </IconButton>
                </Tooltip>
              )}

              {item.statusPlanning !== 'ejecutada' && (
                <>
                  {hasAccess('renamePeriod') && (
                    <Tooltip title={t('Editar nombre')}>
                      <IconButton
                        onClick={() => {
                          setOpenDialogRename(true);
                          setProgramIdSelected(item.options.id);
                        }}
                      >
                        <IconEdit />
                      </IconButton>
                    </Tooltip>
                  )}
                  {hasAccess('getPlaningPostgraduate') && (
                    <DeleteButton handleDelete={() => handleDelete(item.options.programId)} />
                  )}
                </>
              )}
            </>
          ),
        }}
        customDataCellsProperties={{
          id: {
            align: 'center',
          },
          campus: {
            align: 'center',
          },
          modality: {
            align: 'center',
          },

          numFees: {
            align: 'center',
          },

          start: {
            align: 'center',
          },
          end: {
            align: 'center',
          },
          statusPlanning: {
            align: 'center',
          },
          options: {
            align: 'center',
          },
        }}
        customHeadersCellsProperties={{
          id: {
            align: 'center',
          },
          namePeriod: {
            align: 'center',
          },
          campus: {
            align: 'center',
          },

          numFees: {
            align: 'center',
          },

          start: {
            align: 'center',
          },
          end: {
            align: 'center',
          },
          statusPlanning: {
            align: 'center',
          },
          options: {
            align: 'center',
          },
        }}
        error={error}
        page={page}
        perPage={perPage}
        search={search}
        setPage={setPage}
        setPerPage={setPerPage}
        setSearch={setSearch}
        searchPlacehoder={t('posgraduate.table.search')}
        isLoading={isLoading}
        isFetching={isFetching}
        total={data?.total || 0}
        isCollapsible
        CollapsibleItems={renderCollapsibleItems}
        overwriteRowC={0}
        additionalFilters={
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <LocalizedDateRangePicker
                startText="Fecha de inicio"
                endText="Fecha de fin"
                value={datesArraySelected!}
                onChange={(newValue) => {
                  dispatch(setDatesArraySelected(newValue));
                }}
              />
            </Grid>
            {hasAccess('showCampus') && (
              <Grid item xs={12} md={3}>
                {isLoadingC ? (
                  <TextFieldFetching />
                ) : (
                  <Autocomplete
                    options={campusAvailables || []}
                    getOptionLabel={(option) => option.cam_name}
                    value={campusAvailables?.find((item) => item.id === campusIdSelected) || null}
                    onChange={(event, newValue) => {
                      dispatch(setCampusIdSelected(newValue?.id || null));
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          style: {
                            padding: '8px',
                          },
                        }}
                        label={t('posgraduate.table.campus')}
                        placeholder={t('posgraduate.table.campus.placeholder')}
                        fullWidth
                      />
                    )}
                  />
                )}
              </Grid>
            )}
            <Grid item xs={12} md={3}>
              <Autocomplete
                options={posgraduateStatus || []}
                getOptionLabel={(option) => t(option.label)}
                value={posgraduateStatus?.find((item) => item.value === statusIdSelected) || null}
                onChange={(event, newValue) => {
                  dispatch(setStatusIdSelected(newValue?.value || null));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      style: {
                        padding: '8px',
                      },
                    }}
                    label={t('posgraduate.table.status')}
                    placeholder={t('posgraduate.table.status.placeholder')}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              {isLoadingM ? (
                <TextFieldFetching />
              ) : (
                <Autocomplete
                  options={modalityAvailables || []}
                  getOptionLabel={(option) => option.cat_name}
                  value={modalityAvailables?.find((item) => item.id === modalityIdSelected) || null}
                  onChange={(event, newValue) => {
                    dispatch(setModalityIdSelected(newValue?.id || null));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        style: {
                          padding: '8px',
                        },
                      }}
                      label={t('posgraduate.table.modality')}
                      placeholder={t('posgraduate.table.modality.placeholder')}
                      fullWidth
                    />
                  )}
                />
              )}
            </Grid>
          </Grid>
        }
      />
      {openDialogRename && programIdSelected && (
        <ChangeProgramRenameDialog
          open={openDialogRename}
          onClose={() => {
            setOpenDialogRename(false);
            setProgramIdSelected(null);
          }}
          title={t('Cambiar nombre')}
          programId={programIdSelected}
        />
      )}
    </Grid>
  );
};

export default PlanificationListTable;
