import { Grid, Typography } from '@material-ui/core';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import MainCard from '../../../shared/components/cards/MainCard';
import PosgraduatePlanningCalendarDialog from '../components/PosgraduatePlanningCalendarDialog';
import PosgraduatePlanningCalendarsTable from '../components/PosgraduatePlanningCalendarTable';
import PostgraduatePlanningCalendarDeleteDialog from '../components/PostgraduatePlanningCalendarDeleteDialog';
import {
  setPosgraduatePlanningCalendarFormDialogOpen,
  setPosgraduatePlanningCalendarIdSelected,
} from '../slices/posgraduatePlanningCalendarSlice';

const PosgraduatePlanningCalendarsScreen = () => {
  const dispatch = useAppDispatch();

  const {
    posgraduatePlanningCalendarIdSelected,
    posgraduatePlanningCalendarDeleteDialogOpen,
    posgraduatePlanningCalendarFormDialogOpen,
  } = useAppSelector((state) => state.posgraduatePlanningCalendars);

  const handleCloseFormDialog = () => {
    dispatch(setPosgraduatePlanningCalendarFormDialogOpen(false));
    dispatch(setPosgraduatePlanningCalendarIdSelected(null));
  };

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2" mb={2}>
          Planificación de calendarios
        </Typography>
      </Grid>
      <PosgraduatePlanningCalendarsTable />
      {posgraduatePlanningCalendarIdSelected && posgraduatePlanningCalendarDeleteDialogOpen && (
        <PostgraduatePlanningCalendarDeleteDialog />
      )}
      {posgraduatePlanningCalendarFormDialogOpen && (
        <PosgraduatePlanningCalendarDialog open handleCloseDialog={handleCloseFormDialog} />
      )}
    </Grid>
  );
};

export default PosgraduatePlanningCalendarsScreen;
