import { IconButton, Tooltip, useTheme } from '@material-ui/core';
import { IconFileCheck } from '@tabler/icons';

import { useTranslation } from 'react-i18next';

const ApproveButton = ({ handleClick }: { handleClick?: () => void }) => {
  const theme = useTheme();

  const { t } = useTranslation();

  return (
    <Tooltip title={t('Aprobar')}>
      <IconButton aria-label="bitacora" onClick={handleClick}>
        <IconFileCheck size={20} color={theme.palette.primary.main} />
      </IconButton>
    </Tooltip>
  );
};

export default ApproveButton;
