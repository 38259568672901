import { mainApi } from '../../../../api/mainApi';
import { AccessState } from '../../../../types';
import { middlewareApi } from '../../../middleware/middlewareApi';
import { ClientsResponse, PayloadDiscount } from '../interfaces/clients.interface';
import { Client } from '../interfaces/clientsFilter.interface';
import { ClientsFilterResponse, PayloadFilterClients } from '../interfaces/clientsFilter.interface';
import {
  DiscountGroupConfigurations,
  ParamsGroupConfigurations,
} from '../interfaces/groupConfigurations';
import { ListDiscounts } from '../interfaces/listDiscounts.interface';

export const clientsTags = middlewareApi.enhanceEndpoints({
  addTagTypes: ['Clients', 'ListDiscounts', 'ListDiscountsDisabled', 'PaymentsDebtsHistory'],
});

export const clientsApi = clientsTags.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * @GET all clients
     */
    getClients: builder.query<
      ClientsResponse,
      {
        profileId: number;
        page: number;
        size: number;
        sort: string;
        type_sort: string;
        search: string;
      }
    >({
      queryFn: async ({ profileId, page, size, sort, type_sort, search }) => {
        try {
          const { data } = await mainApi.get<ClientsResponse>('/persons', {
            params: {
              user_profile_id: profileId,
              page,
              size,
              sort,
              type_sort,
              search,
            },
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: [
        {
          type: 'Clients',
          id: 'LIST',
        },
      ],
    }),

    /**
     * @GET client by filters
     * @Permission persons-obtener-person-by-params
     */
    getClientByFilters: builder.query<ClientsFilterResponse, PayloadFilterClients>({
      queryFn: async (PayloadFilterClients, { dispatch }) => {
        try {
          const { data } = await mainApi.get<ClientsFilterResponse>(
            '/persons/search-person/params',
            {
              params: {
                ...PayloadFilterClients,
              },
            }
          );

          // dispatch(setClientList(data.data));
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'Clients', id } as const)),
              { type: 'Clients', id: 'LIST' },
            ]
          : [{ type: 'Clients', id: 'LIST' }],
    }),

    getClientById: builder.query<Client, { id: number }>({
      queryFn: async ({ id }) => {
        try {
          const { data } = await mainApi.get<Client>(`/persons/${id}`);
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    /**
     * @GET obtiene las configuraciones de descuento para estudiantes
     * @Permission group-configurations-obtener-todos
     */
    getDiscountGroupConfigurations: builder.query<
      DiscountGroupConfigurations[],
      ParamsGroupConfigurations
    >({
      queryFn: async (ParamsGroupConfigurations) => {
        try {
          const { data } = await mainApi.get<DiscountGroupConfigurations[]>(
            '/group-configurations',
            {
              params: {
                ...ParamsGroupConfigurations,
              },
            }
          );
          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'Clients', id } as const)),
              { type: 'Clients', id: 'LIST' },
            ]
          : [{ type: 'Clients', id: 'LIST' }],
    }),

    /**
     * @GET obtiene las configuraciones de descuento por estudiantes
     * @Permission group-configuration-students-obtener-configuracion-por-estudiante
     */
    getGroupConfigurationByStudent: builder.query<ListDiscounts[], { studentId: number }>({
      queryFn: async ({ studentId }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = currentProfile?.profileId;
          const { data } = await mainApi.get<ListDiscounts[]>(
            `/group-configuration-students/${studentId}/student`,
            {
              params: {
                user_profile_id,
              },
            }
          );
          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'ListDiscounts', id } as const)),
              { type: 'ListDiscounts', id: 'LIST' },
            ]
          : [{ type: 'ListDiscounts', id: 'LIST' }],
    }),

    /**
     * @GET obtiene las configuraciones de descuento por estudiantes
     * @Permission group-configuration-students-desactivar-por-estudiante
     */
    getGroupConfigurationByStudentDisabled: builder.query<ListDiscounts[], { studentId: number }>({
      queryFn: async ({ studentId }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = currentProfile?.profileId;
          const { data } = await mainApi.get<ListDiscounts[]>(
            `/group-configuration-students/${studentId}/disabled`,
            {
              params: {
                user_profile_id,
              },
            }
          );
          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'ListDiscountsDisabled', id } as const)),
              { type: 'ListDiscountsDisabled', id: 'LIST' },
            ]
          : [{ type: 'ListDiscountsDisabled', id: 'LIST' }],
    }),

    /**
     * @DELETE descuento de cliente
     * @Permission group-configuration-students-destroy
     */
    deleteConfigurationStudent: builder.mutation<ListDiscounts, { configurationStudentId: number }>(
      {
        queryFn: async ({ configurationStudentId }, { getState }) => {
          try {
            const {
              access: { currentProfile },
            } = getState() as { access: AccessState };

            const user_profile_id = currentProfile?.profileId;
            const { data } = await mainApi.delete(
              `/group-configuration-students/${configurationStudentId}`,
              {
                data: {
                  user_profile_id,
                },
              }
            );
            return { data };
          } catch (error: any) {
            return { error };
          }
        },
        invalidatesTags: [{ type: 'ListDiscounts', id: 'LIST' }],
      }
    ),

    /**
     * @POST asigna descuento al estudiante
     * @Permission group-configuration-students-crear
     */
    assignDiscount: builder.mutation<PayloadDiscount, PayloadDiscount>({
      queryFn: async (PayloadDiscount) => {
        try {
          const { data } = await mainApi.post<PayloadDiscount>('/group-configuration-students', {
            ...PayloadDiscount,
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [
        { type: 'ListDiscounts', id: 'LIST' },
        {
          type: 'ListDiscountsDisabled',
          id: 'LIST',
        },
        {
          type: 'Clients',
          id: 'LIST',
        },
      ],
    }),

    /**
     * 
     * @Permission fees-setNameIdentifier
     */
    prorogueDebt: builder.mutation<
      any,
      {
        profileId: number;
        feeId: string;
        date: string;
      }
    >({
      queryFn: async ({ profileId, feeId, date }) => {
        try {
          const { data } = await mainApi.put<any>(`/fees/date-extension`, {
            user_profile_id: profileId,
            fees_identifier: feeId,
            fees_expiration_date: date,
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [
        {
          type: 'PaymentsDebtsHistory',
          id: 'LIST',
        },
      ],
    }),

    //
  }),
});

export const {
  useGetClientsQuery,
  useGetClientByFiltersQuery,
  useGetDiscountGroupConfigurationsQuery,
  useAssignDiscountMutation,
  useGetGroupConfigurationByStudentQuery,
  useDeleteConfigurationStudentMutation,
  useGetGroupConfigurationByStudentDisabledQuery,
  useLazyGetClientByIdQuery,
  useProrogueDebtMutation,
} = clientsApi;
