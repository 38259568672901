import { Breadcrumbs, Typography, Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

export interface Data {
  name: string;
  url: string;
}
export interface Props {
  data: Data[];
  currentName: string;
}

const Breadcrumb = ({ data, currentName }: Props) => {
  return (
    <Breadcrumbs maxItems={4} aria-label="breadcrumb">
      {data.map((item, index) => (
        <RouterLink
          to={`${item.url}`}
          key={index}
          style={{ textDecoration: 'none', color: 'inherit' }}
        >
          <Link underline="hover" color="inherit" key={index}>
            {item.name}
          </Link>
        </RouterLink>
      ))}
      <Typography color="text.primary">{currentName}</Typography>
    </Breadcrumbs>
  );
};

export default Breadcrumb;
