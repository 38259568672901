import { Skeleton, Timeline } from '@material-ui/lab';
import { Box, Grid } from '@material-ui/core';

import { useAppSelector } from '../../../../../hooks/useRedux';

import { useGetActivitiesByCourseAndStudentQuery } from '../../slices/virtualClassroomApiSlice';
import TimelineOnce from './TimelineOnce';
import { useTranslation } from 'react-i18next';
import NoData from '../../../../shared/components/NoData';
import image from '../../../../../assets/images/no-tests.png';

export default function TimeLineActivities() {
  const {
    course: { courseId },
  } = useAppSelector((state) => state.virtualClassroomStudentShared);

  const { t } = useTranslation();

  const { currentStudent } = useAppSelector((state) => state.access);

  const { data, isLoading } = useGetActivitiesByCourseAndStudentQuery(
    {
      courseId: courseId?.toString()!,
      studentId: Number(currentStudent?.id!),
    },
    { skip: courseId === 0 || courseId === undefined }
  );
  return (
    <Box>
      {isLoading ? (
        <Skeleton variant="rectangular" sx={{ width: '100%', borderRadius: 3, height: '100%' }} />
      ) : data && data.length > 0 ? (
        <Timeline position="right">
          {data.map((item) => (
            <TimelineOnce
              Category={item?.type_activity?.cat_description!}
              Descripcion={item?.act_description}
              FechaInicio={new Date(item?.course_activity_one.cact_start_date)}
              FechaEnd={new Date(item?.course_activity_one.cact_end_date)}
            />
          ))}
        </Timeline>
      ) : (
        <Grid container alignItems="center" mt={10}>
          <NoData
            imgWitdh={150}
            image={image}
            title={t('students.dashboard.activitiesByStart.no-list')}
            subtitle={t('students.dashboard.activitiesByStart.no-list.description')}
          />
        </Grid>
      )}
    </Box>
  );
}
