import React from 'react';

import {
  Button,
  DialogActions,
  DialogContent,
  Slide,
  SlideProps,
  Stack,
  Typography,
  Grid,
  TextField,
  FormHelperText,
  CircularProgress,
  InputAdornment,
  Autocomplete,
  Divider,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

import InputLabel from '../../../../shared/components/forms/InputLabel';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import { setListSubjects, updateListSubjects } from '../../slices/requisitionsSlice';
import { useGetAvailablesCampusQuery } from '../../../campus/slices/campusApiSlice';
// import { useLazyGetMattersQuery } from '../../../matters/slices/mattersApiSlice';
import { Matter } from '../../../matters/interfaces/matters.interface';
// import PerfectScrollbar from 'react-perfect-scrollbar';
import AnimateButton from '../../../../shared/components/extended/AnimateButton';
import ExtendedSideDialog from '../../../../shared/components/dialogs/ExtendedSideDialog';
// import CustomAsyncPaginatedSelect from '../../../../shared/components/extended/Form/CustomAsyncPaginatedSelect';
import { useGetCoursesRequisitionQuery } from '../../slices/requisitionsApiSlice';
import { subjectsTable } from '../../interfaces/subjectsRegistered.interfaces';

const validationSchema = yup.object({
  // subject: yup.object({
  //   id: yup.number(),
  //   name: yup.string(),
  // }),
  // .required('academic.requisition.edit.wizard.step2.add_subject.dialog.subjects.required'),
  parallels: yup
    .number()
    .required('academic.requisition.edit.wizard.step2.add_subject.dialog.parallels.required'),
  campus: yup
    .object({
      id: yup.number(),
      name: yup.string(),
    })
    .test(({ id }) => {
      if (!id || id <= 0) {
        return new yup.ValidationError(
          'academic.requisition.edit.wizard.step2.add_subject.dialog.campus.required',
          null,
          'typeEducationId'
        );
      }
      return true;
    })
    .required('academic.requisition.edit.wizard.step2.add_subject.dialog.campus.required'),
  hours: yup
    .number()
    .required('academic.requisition.edit.wizard.step2.add_subject.dialog.hours.required'),

  courseId: yup
    .number()
    .typeError('academic.requisition.edit.wizard.step2.add_subject.dialog.course_id.required')
    .required('academic.requisition.edit.wizard.step2.add_subject.dialog.course_id.required'),
});

const useStyles = makeStyles((theme) => ({
  userAddDialog: {
    '&>div:nth-child(3)': {
      justifyContent: 'flex-end',
      '&>div': {
        margin: '0px',
        borderRadius: '0px',
        maxWidth: '450px',
        maxHeight: '100%',
      },
    },
  },
  paper: {
    minHeight: '100vh',
    width: '100%',
  },
  learningComponents: {
    fontSize: '16px',
    fontWeight: 600,
    color: theme.palette.primary.main,
    marginBottom: '8px',
  },
  selectSubject: {
    fontSize: '16px',
    fontWeight: 600,
    color: theme.palette.primary[800],
    marginBottom: '8px',
  },
  selectSubjectType: {
    fontSize: '14px',
    fontWeight: 600,
    color: theme.palette.secondary.main,
    marginBottom: '8px',
  },
}));

const Transition = React.forwardRef(function Transition(props: SlideProps, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

interface Props {
  open: boolean;
  period?: string;
  handleCloseDialog: () => void;
  subject?: subjectsTable;
}

const SubjectsRegisteredDialog = ({ open, handleCloseDialog, subject }: Props) => {
  const { t } = useTranslation();

  const classes = useStyles();
  const dispatch = useAppDispatch();

  const { currentProfile } = useAppSelector((state) => state.access);
  const { educationLvlId, periodId, listSubjects } = useAppSelector(
    (state) => state.requisitionTeacher
  );

  const profileId = currentProfile!.profileId;

  const { data: availablesCampus = [], isLoading: isLoadingCampus } = useGetAvailablesCampusQuery({
    profileId,
  });

  // const { data: availablesMatter = [], isLoading: isLoadingMatters } = useGetMattersQuery(
  //   {
  //     profileId,
  //     educationLevelId: educationLvlId,
  //   },
  //   { skip: !educationLvlId }
  // );

  // const [getSubjectsTrigger] = useLazyGetMattersQuery();

  //TODO: crear hook en el componente del select
  // const getSubjects = async (search: string, page: number, perPage: number) => {
  //   const response = await getSubjectsTrigger({
  //     educationLevelId: educationLvlId,
  //     page,
  //     perPage,
  //     search,
  //   }).unwrap();

  //   const hasMore = response.current_page < response.last_page;

  //   return {
  //     options: response.data.map((dt) => {
  //       return {
  //         value: dt.id,
  //         label: (dt as Matter)?.mat_name,
  //         ...dt,
  //       };
  //     }),
  //     hasMore,
  //   };
  // };

  const { errors, touched, handleSubmit, getFieldProps, setFieldValue, values, resetForm } =
    useFormik({
      initialValues: {
        subject: subject?.subject || (null! as Matter),
        parallels: subject?.parallels || 1,
        campus: subject?.campus || { id: 0, name: 'N/A' },
        hours: subject?.hours || '',
        courseId: subject?.courseId || 0,
        courseName: subject?.subject?.name || '',
        parallel: subject?.parallel || '',
        hourhand: subject?.hourhand || '',
      },
      validationSchema: validationSchema,
      onSubmit: (values) => {
        const item = {
          id: subject?.id || Date.now(),
          subject: {
            id: (values.subject as unknown as Matter)?.id,
            name: values.courseName,
          },
          parallels: 1,
          campus: values.campus,
          hours: values.hours,
          hours_total: Number(values.hours),
          courseId: values.courseId,
          parallel: values.parallel,
          hourhand: values.hourhand,
        };

        if (subject) {
          dispatch(updateListSubjects(item));
        } else {
          dispatch(setListSubjects([item]));
        }
        resetForm();
        handleCloseDialog();
      },
    });

  const { data: courses, isLoading: isLoadingCourses } = useGetCoursesRequisitionQuery(
    {
      profileId,
      educationLevelId: educationLvlId,
      campusId: values.campus?.id,
      periodId,
    },
    {
      skip: !educationLvlId || !values.campus?.id || !periodId,
    }
  );

  const totalHours =
    listSubjects.reduce((acc, curr) => {
      if (curr.id === subject?.id) {
        return acc;
      }
      return acc + Number(curr.hours);
    }, 0) + Number(values.hours);

  return (
    <ExtendedSideDialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => {
        // dispatch(resetCurrentGroup());
        // dispatch(resetSubjectCurriculumSelected());
        handleCloseDialog();
      }}
      className={classes.userAddDialog}
      classes={{
        paper: classes.paper,
      }}
    >
      {/* <PerfectScrollbar> */}
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Typography variant="h2">
            {t('academic.requisition.edit.wizard.step2.add_subject.dialog.subjects_selected')}
          </Typography>

          <Stack spacing={2} sx={{ mt: 4 }}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <InputLabel>
                  {t('academic.requisition.edit.wizard.step2.add_subject.dialog.campus')}
                </InputLabel>
                {isLoadingCampus ? (
                  <>
                    <TextField
                      disabled
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <CircularProgress size={25} />
                          </InputAdornment>
                        ),
                      }}
                      placeholder={t(
                        'academic.requisition.edit.wizard.step2.add_subject.dialog.campus.loading'
                      )}
                    />
                  </>
                ) : (
                  <>
                    <Autocomplete
                      options={availablesCampus}
                      loading={isLoadingCampus}
                      loadingText={t(
                        'academic.requisition.edit.wizard.step2.add_subject.dialog.campus.loading'
                      )}
                      noOptionsText={t(
                        'academic.requisition.edit.wizard.step2.add_subject.dialog.campus.no_data'
                      )}
                      getOptionLabel={(option) => option.cam_name}
                      value={availablesCampus.find((e) => e.id === values.campus?.id) || null}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={Boolean(errors.campus && touched.campus)}
                          placeholder={t(
                            'academic.requisition.edit.wizard.step2.add_subject.dialog.campus.placeholder'
                          )}
                        />
                      )}
                      onChange={(e, value) => {
                        setFieldValue('campus', { id: value?.id, name: value?.cam_name } || '');
                        setFieldValue('courseId', null);
                        setFieldValue('courseName', null);
                      }}
                      fullWidth
                      clearIcon={null}
                    />
                    {errors.campus && touched.campus && (
                      <FormHelperText error>{t(errors.campus as string)}</FormHelperText>
                    )}
                  </>
                )}
              </Grid>

              <Grid item xs={12}>
                <InputLabel>
                  {t('academic.requisition.edit.wizard.step2.add_subject.dialog.subjects')}
                </InputLabel>
                {/* {educationLvlId ? (
                  <CustomAsyncPaginatedSelect
                    key={'subject'}
                    value={values.subject}
                    isError={'subject' in errors && touched['subject'] ? true : false}
                    placeholder={'Escoger materia'}
                    setValue={(value) => setFieldValue('subject', value)}
                    fetchFunc={getSubjects}
                  />
                ) : (
                  <TextField
                    disabled
                    fullWidth
                    placeholder={t(
                      'academic.requisition.edit.wizard.step2.add_subject.dialog.subjects.edu_level_first'
                    )}
                  />
                )} */}

                {!Boolean(values.campus?.id) && (
                  <TextField
                    disabled
                    fullWidth
                    placeholder={t(
                      'academic.requisition.edit.wizard.step2.add_subject.dialog.subjects.campus_first'
                    )}
                  />
                )}

                {Boolean(values.campus?.id) && isLoadingCourses && (
                  <TextField
                    disabled
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <CircularProgress size={25} />
                        </InputAdornment>
                      ),
                    }}
                    placeholder={t(
                      'academic.requisition.edit.wizard.step2.add_subject.dialog.subjects.loading'
                    )}
                  />
                )}

                {Boolean(values.campus?.id) && !isLoadingCourses && (
                  <Autocomplete
                    options={
                      courses?.filter((c) => !listSubjects?.find((s) => s.courseId === c.id)) || []
                    }
                    loading={isLoadingCourses}
                    loadingText={t(
                      'academic.requisition.edit.wizard.step2.add_subject.dialog.subjects.loading'
                    )}
                    noOptionsText={t(
                      'academic.requisition.edit.wizard.step2.add_subject.dialog.subjects.no_data'
                    )}
                    getOptionLabel={(option) =>
                      `${option?.matter?.cod_matter_migration} - ${option?.matter?.mat_name} - ${option?.parallel?.par_name}`
                    }
                    value={courses?.find((e) => e.id === values.courseId) || null}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={Boolean(errors.courseId && touched.courseId)}
                        placeholder={t(
                          'academic.requisition.edit.wizard.step2.add_subject.dialog.subjects.placeholder'
                        )}
                      />
                    )}
                    onChange={(e, value) => {
                      setFieldValue('courseId', value?.id || 0);
                      setFieldValue('courseName', value?.matter?.mat_name || '');
                      setFieldValue('parallel', value?.parallel?.par_name || '');
                      setFieldValue('hourhand', value?.hourhand?.hour_description || '');
                    }}
                    fullWidth
                    clearIcon={null}
                  />
                )}

                {errors.courseId && touched.courseId && (
                  <FormHelperText error>{t(errors.courseId)}</FormHelperText>
                )}
              </Grid>

              {Boolean(values.courseId) && (
                <>
                  <Grid item xs={12} mt={2} mb={1}>
                    <Divider />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="h4" color="primary">
                      {t('academic.requisition.edit.wizard.step2.add_subject.dialog.subject_info')}
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <InputLabel>
                      {t('academic.requisition.edit.wizard.step2.add_subject.dialog.schedule')}
                    </InputLabel>

                    <TextField
                      fullWidth
                      disabled
                      value={
                        courses?.find((e) => e.id === values.courseId)?.hourhand?.hour_description
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel>
                      {t('academic.requisition.edit.wizard.step2.add_subject.dialog.classroom')}
                    </InputLabel>
                    <TextField
                      fullWidth
                      disabled
                      value={courses?.find((e) => e.id === values.courseId)?.classroom?.cl_name}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel>
                      {t(
                        'academic.requisition.edit.wizard.step2.add_subject.dialog.principal_teacher'
                      )}
                    </InputLabel>
                    <TextField
                      fullWidth
                      disabled
                      value={
                        courses?.find((e) => e.id === values.courseId)?.collaborator?.user?.person
                          ?.pers_full_name || 'No asignado'
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel>
                      {t('academic.requisition.edit.wizard.step2.add_subject.dialog.parallel')}
                    </InputLabel>
                    <TextField
                      fullWidth
                      disabled
                      value={
                        courses?.find((e) => e.id === values.courseId)?.parallel?.par_name ||
                        'No asignado'
                      }
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <InputLabel>
                      {t('academic.requisition.edit.wizard.step2.add_subject.dialog.schedule')}
                    </InputLabel>

                    <TextField
                      fullWidth
                      disabled
                      value={
                        courses?.find((e) => e.id === values.courseId)?.hourhand?.hour_description
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel>
                      {t('academic.requisition.edit.wizard.step2.add_subject.dialog.classroom')}
                    </InputLabel>
                    <TextField
                      fullWidth
                      disabled
                      value={courses?.find((e) => e.id === values.courseId)?.classroom?.cl_name}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel>
                      {t(
                        'academic.requisition.edit.wizard.step2.add_subject.dialog.principal_teacher'
                      )}
                    </InputLabel>
                    <TextField
                      fullWidth
                      disabled
                      value={
                        courses?.find((e) => e.id === values.courseId)?.collaborator?.user?.person
                          ?.pers_full_name || 'No asignado'
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel>
                      {t('academic.requisition.edit.wizard.step2.add_subject.dialog.parallel')}
                    </InputLabel>
                    <TextField
                      fullWidth
                      disabled
                      value={
                        courses?.find((e) => e.id === values.courseId)?.parallel?.par_name ||
                        'No asignado'
                      }
                    />
                  </Grid>
                  <Grid item xs={12} mt={2} mb={1}>
                    <Divider />
                  </Grid>
                </>
              )}

              {/* <Grid item xs={12}>
                <InputLabel>
                  {t('academic.requisition.edit.wizard.step2.add_subject.dialog.subjects')}
                </InputLabel>
                {availablesMatter.length ? (
                  <>
                    {isLoadingMatters ? (
                      <TextField
                        disabled
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <CircularProgress size={25} />
                            </InputAdornment>
                          ),
                        }}
                        placeholder={t(
                          'academic.requisition.edit.wizard.step2.add_subject.dialog.subjects.loading'
                        )}
                      />
                    ) : (
                      <>
                        <Autocomplete
                          options={availablesMatter}
                          loading={isLoadingMatters}
                          loadingText={t(
                            'academic.requisition.edit.wizard.step2.add_subject.dialog.subjects.loading'
                          )}
                          noOptionsText={t(
                            'academic.requisition.edit.wizard.step2.add_subject.dialog.subjects.no_data'
                          )}
                          getOptionLabel={(option) => option.mat_name}
                          value={
                            availablesMatter.find(
                              (e) => e.ID_Matter === values.subject.id.toString()
                            ) || null
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={Boolean(errors.subject && touched.subject)}
                            />
                          )}
                          onChange={(e, value) =>
                            setFieldValue(
                              'subject',
                              { id: value?.ID_Matter, name: value?.mat_name } || ''
                            )
                          }
                          fullWidth
                          clearIcon={null}
                        />
                        {errors.subject && touched.subject && (
                          <FormHelperText error>{t(errors.subject as string)}</FormHelperText>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <TextField
                    disabled
                    fullWidth
                    placeholder={t(
                      'academic.requisition.edit.wizard.step2.add_subject.dialog.subjects.edu_level_first'
                    )}
                  />
                )}
              </Grid> */}

              {/* <Grid item xs={12}>
                <InputLabel>
                  {t('academic.requisition.edit.wizard.step2.add_subject.dialog.parallels')}
                </InputLabel>
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ inputProps: { min: 1, max: 20 } }}
                  type="number"
                  placeholder={t(
                    'academic.requisition.edit.wizard.step2.add_subject.dialog.parallels.placeholder'
                  )}
                  {...getFieldProps('parallels')}
                  error={Boolean(errors.parallels && touched.parallels)}
                />
                {errors.parallels && touched.parallels && (
                  <FormHelperText error>{t(errors.parallels)}</FormHelperText>
                )}
              </Grid> */}

              <Grid item xs={12}>
                <InputLabel>
                  {t('academic.requisition.edit.wizard.step2.add_subject.dialog.hours')}
                </InputLabel>
                <TextField
                  type="number"
                  fullWidth
                  InputProps={{ inputProps: { min: 1, max: 100, maxLength: 3 } }}
                  inputProps={{
                    maxLength: 4,
                  }}
                  placeholder={t(
                    'academic.requisition.edit.wizard.step2.add_subject.dialog.hours.placeholder'
                  )}
                  {...getFieldProps('hours')}
                  error={Boolean(errors.hours && touched.hours)}
                />
                {errors.hours && touched.hours && (
                  <FormHelperText error>{t(errors.hours)}</FormHelperText>
                )}
              </Grid>

              <Grid item xs={4}>
                <InputLabel>
                  {t('academic.requisition.edit.wizard.step2.add_subject.dialog.total_hours')}
                </InputLabel>
                <TextField
                  value={totalHours}
                  fullWidth
                  placeholder={t(
                    'academic.requisition.edit.wizard.step2.add_subject.dialog.total_hours.placeholder'
                  )}
                />
              </Grid>
            </Grid>
          </Stack>
        </DialogContent>
        {/* </PerfectScrollbar> */}
        <DialogActions>
          <Stack direction="row" spacing={2}>
            <Button
              variant="text"
              onClick={() => {
                // dispatch(resetCurrentGroup());
                // dispatch(resetSubjectCurriculumSelected());
                handleCloseDialog();
              }}
              color="primary"
            >
              {t('academic.requisition.edit.wizard.step2.add_subject.dialog.cancel')}
            </Button>

            <AnimateButton>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                // disabled={isAssigning || isUpdating}
                // endIcon={(isAssigning || isUpdating) && <CircularProgress size={20} />}
              >
                {subject
                  ? t('academic.requisition.edit.wizard.step2.add_subject.dialog.edit')
                  : t('academic.requisition.edit.wizard.step2.add_subject.dialog.add')}
              </Button>
            </AnimateButton>
          </Stack>
        </DialogActions>
      </form>
    </ExtendedSideDialog>
  );
};

export default SubjectsRegisteredDialog;
