import {
  Box,
  Button,
  FormHelperText,
  Grid,
  InputLabel,
  Modal,
  OutlinedInput,
  Typography,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { LoadingButton } from '@material-ui/lab';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useAppSelector } from '../../../hooks/useRedux';
// import DragArea from '../../students/virtual-classroom/components/DragArea';
import DragArea from './files/DragArea';
import { useAddElectronicSignatureMutation } from '../slices/registerElectronicSignatureApi';
import ErrorAlert from './alerts/ErrorAlert';
import InfoAlert from './alerts/InfoAlert';
import SuccessAlert from './alerts/SuccessAlert';
import ToggleVisibility from './controlled/ToggleVisibility';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: '50vw',
  bgcolor: 'background.paper',
  borderRadius: '8px',
  boxShadow: 24,
  p: 4,
};

const RegisterElectronicSignatureSchema = Yup.object().shape({
  file: Yup.mixed().required('shared.modal.electronic.file'),
  password: Yup.string().required('auth.login.form.password.required'),
});

interface Props {
  open: boolean;
  handleToggleOpen: () => void;
}

const ModalElectronicSignatureRegistration = ({ open, handleToggleOpen }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { currentProfile } = useAppSelector((state) => state.access);

  const { userInfo } = useAppSelector((state) => state.auth);

  const [showAlerts, setShowAlerts] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => setShowPassword(!showPassword);

  const [addElectronicSignature, { isSuccess, isLoading, isError, error }] =
    useAddElectronicSignatureMutation();

  const {
    values: { password, file },
    handleSubmit,
    setFieldValue,
    getFieldProps,
    resetForm,
    errors,
    touched,
  } = useFormik({
    initialValues: {
      password: '',
      file: null,
    },

    validationSchema: RegisterElectronicSignatureSchema,

    onSubmit: () => {
      registerElectronicSignature();
    },
  });

  async function registerElectronicSignature() {
    try {
      await addElectronicSignature({
        file,
        password,
        userProfileId: currentProfile?.profileId!,
        userId: userInfo?.id!,
      });
      setShowAlerts(true);
      isSuccess && resetForm();
    } catch (error) {
      setShowAlerts(true);
    }
  }

  const onChangeFile = function (event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.files && event.target.files[0]) {
      setFieldValue('file', event.target.files[0]);
    }
  };

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h2"
          component="h2"
          color={theme.palette.primary.main}
          sx={{
            textAlign: 'center',
          }}
        >
          {t('shared.header.menu.register_certificate')}
        </Typography>
        <>
          <Grid
            item
            container
            sx={{
              my: 3,
            }}
          >
            <Grid item>
              {!!userInfo.hasSign && (
                <InfoAlert
                  message={t('shared.header.menu.has_certificate_alert')}
                  handleDismiss={() => setShowAlerts(false)}
                />
              )}
            </Grid>
            <Grid item>
              {isError && showAlerts && (
                <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
              )}

              {isSuccess && showAlerts && (
                <SuccessAlert
                  message={t('shared.modal.electronic.signature.success')}
                  handleDismiss={() => setShowAlerts(false)}
                />
              )}
            </Grid>
          </Grid>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3} justifyContent={'space-between'}>
              <Grid item xs={12}>
                <DragArea
                  handleChange={onChangeFile}
                  description={t('shared.modal.electronic.signature.description')}
                  label={t('shared.modal.electronic.signature.label')}
                  marginTop={1}
                  typesAccepted=".p12"
                />
                {errors.file && touched.file && (
                  <FormHelperText error id="fileError">
                    {t(errors.file)}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputLabel htmlFor="password">{t('auth.login.form.password')}</InputLabel>
                <OutlinedInput
                  id="password"
                  type={showPassword ? 'text' : 'password'}
                  endAdornment={
                    <ToggleVisibility
                      showPassword={showPassword}
                      handleShowPassword={handleShowPassword}
                    />
                  }
                  error={Boolean(errors.password && touched.password)}
                  // fullWidth
                  {...getFieldProps('password')}
                  value={password.trim()}
                />
                {errors.password && touched.password && (
                  <FormHelperText error id="passwordError">
                    {t(errors.password)}
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
            <Grid justifyContent={'center'} mt={'20px'} textAlign="center">
              <Button
                onClick={() => {
                  setShowAlerts(false);
                  handleToggleOpen();
                }}
                style={{ marginRight: '30px', borderRadius: '30px' }}
                variant="outlined"
              >
                {t('students.virtual_classroom.task.modal.button.back')}
              </Button>
              <LoadingButton
                loading={isLoading}
                disabled={isSuccess}
                type="submit"
                style={{ borderRadius: '30px' }}
                variant="contained"
              >
                {t('students.virtual_classroom.task.modal.button.submit')}
              </LoadingButton>
            </Grid>
          </form>
        </>
      </Box>
    </Modal>
  );
};

export default ModalElectronicSignatureRegistration;
