import { Grid, IconButton, Typography, useTheme } from '@material-ui/core';
import { IconArrowNarrowLeft } from '@tabler/icons';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { useAppSelector, useAppDispatch } from '../../../../hooks/useRedux';
import DiscountTab from '../components/assign-discount/DiscountTab';
import MainCardForm from '../../../shared/components/cards/MainCardForm';
import DeleteDiscountDialog from '../components/assign-discount/DeleteDiscountDialog';
import { useLazyGetClientByIdQuery } from '../slices/clientApiSlice';
import { setClientList } from '../slices/clientSlice';

const AssignDiscountScreen = () => {
  const { configurationStudentIdToDelete, clientList } = useAppSelector((state) => state.client);

  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const theme = useTheme();
  const history = useHistory();

  const { idPerson } = useParams<{ idPerson: string }>();

  const [getClient, { isFetching }] = useLazyGetClientByIdQuery();

  const client = clientList.find((client) => client.id === +idPerson);

  useEffect(() => {
    if (!clientList.length && !isFetching) {
      getClient({
        id: +idPerson,
      })
        .unwrap()
        .then((response) => {
          if (response) dispatch(setClientList([response!]));
        })
        .catch((error) => console.log(error));
    }
  }, [getClient, isFetching, idPerson, clientList.length, dispatch]);

  return (
    <MainCardForm>
      <Grid item xs={12}>
        <Grid item container alignItems="center" gap={1} sx={{ cursor: 'pointer' }}>
          <IconButton onClick={() => history.push('/administrar-clientes')}>
            <IconArrowNarrowLeft size={30} color={theme.palette.text.dark} />
          </IconButton>
          <Typography variant="h2">
            {t('financial.client_list.asign_discount.title')}
            {client ? ` - ${client.pers_full_name}` : ''}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} container>
        {!!clientList.length && <DiscountTab />}
      </Grid>
      {configurationStudentIdToDelete && <DeleteDiscountDialog />}
    </MainCardForm>
  );
};

export default AssignDiscountScreen;
