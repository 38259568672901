import { useAppDispatch, useAppSelector } from '../../../../../../../hooks/useRedux';
import DeleteAlert from '../../../../../../shared/components/dialogs/DeleteAlert';

import { useDeleteGroupMutation } from '../../slices/listGroupsApiSlice';
import { setUuidGroup, setOpenDeleteDialog } from '../../slices/listGroupsSlice';

const GroupDeleteDialog = () => {
  const dispatch = useAppDispatch();
  const { uuidGroup, openDeleteDialog } = useAppSelector((state) => state.listGroups);

  const [deleteGroupType, { isLoading, isSuccess, error, isError }] = useDeleteGroupMutation();

  const handleDelete = () => {
    deleteGroupType({
      id: uuidGroup!,
    });
  };
  const handleClose = () => {
    dispatch(setUuidGroup(''));
    dispatch(setOpenDeleteDialog(false));
  };

  return (
    <DeleteAlert
      open={openDeleteDialog}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      error={error as any}
      handleDelete={handleDelete}
      onClose={handleClose}
    />
  );
};

export default GroupDeleteDialog;
