import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import Autocomplete from '@material-ui/core/Autocomplete';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';

import InputLabel from '../../../../shared/components/forms/InputLabel';
import { EducationForm as EducationInterface } from '../../interfaces/studentsStore.interface';
import { RecordAcademicSchema } from '../../constants/validationSchemas';
import { NamesTabs, PropsForm, listTabs } from './index';
import errorValidationFunction from '../../constants/errorValidation';
import { handleNextStep, handleReturnStep } from '../../constants/navigateFunctionsForm';
import { useGetAvailablesOffersQuery } from '../../../academic-offerings/slices/academicOfferingsApiSlice';
import { useGetAvaliblesEducationLevelsVigentCurriculumQuery } from '../../../education-levels/slices/educationLevelsApiSlice';
import { useGetCurrentPeriodQuery } from '../../../periods/slices/periodsApiSlice';
import { useGetEntryTypesQuery } from '../../slices/studentsApiSlice';
import LoadingButton from '../../../../shared/components/buttons/LoadingButton';

const ThisTabName: NamesTabs = 'student-record';

const StudentRecordForm = ({
  setData,
  data,
  currentTab,
  errorValidation,
  setCurrentTab,
  submit,
  errorObject,
  isEdit,
}: PropsForm) => {
  const { t } = useTranslation();

  const [firstShow, setFirstShow] = useState(false);

  const notErrorForm = !errorObject?.some((e) => e.error);

  const isCompleteOthersTabs = errorObject?.length! >= listTabs.length - 1;

  const [isAFormChange, setIsAFormChange] = useState(false);

  console.log(notErrorForm, isCompleteOthersTabs);

  const { getFieldProps, errors, touched, handleSubmit, setFieldValue, submitForm, values } =
    useFormik<EducationInterface & { faculty: number }>({
      initialValues: {
        education_level_id: '',
        offer_id: 0,
        id_period: 0,
        type_entry_id: 0,
        can_generate_debt: false,
        faculty: 0,
      },
      validationSchema: RecordAcademicSchema,
      onSubmit: async (values) => {
        const { faculty, ...value } = values;
        // setData((data) => ({ ...data, ...values }));
        if (notErrorForm && isCompleteOthersTabs && isAFormChange) {
          setData((data) => ({ ...data, education: value }));
          // submit?.setSubmit();
          // window.scrollTo(0, 0);
        } else if (isAFormChange) {
          setData((data) => ({ ...data, education: value }));
          handleNextStep(setCurrentTab, currentTab, ThisTabName);
        }
        setIsAFormChange(false);
      },
    });

  //ofertas
  const { data: offersAvailables, isFetching: isFetchingPID } = useGetAvailablesOffersQuery({
    off_description: '',
    ficha: 'oferta_ficha',
  });

  //Education Levels
  const { data: educationLevelsAvailables = [], isLoading: isLoadingEDA } =
    useGetAvaliblesEducationLevelsVigentCurriculumQuery(
      {
        offerId: values.offer_id,
      },
      { skip: !values.offer_id }
    );

  //Periods Availables
  const { data: periodsAvailables, isFetching: isLoadingPA } = useGetCurrentPeriodQuery(
    {
      offerId: values.offer_id || 0,
      current: true,
    },
    { skip: !values.offer_id }
  );

  // Ingress Types
  const { data: ingressAvailables, isLoading: isLoadingTI } = useGetEntryTypesQuery({
    offerKey: offersAvailables?.find(({ id }) => id === values.offer_id)?.off_description || '',
  });

  useEffect(() => {
    if (currentTab === ThisTabName) {
      setFirstShow(true);
    }

    if (firstShow && currentTab !== ThisTabName) {
      submitForm();
    }

    //eslint-disable-next-line
  }, [currentTab]);

  // Despacha un objecto a la tab si existe un error en el formulario
  useEffect(() => {
    errorValidationFunction(errors, ThisTabName, firstShow, errorValidation);

    //eslint-disable-next-line
  }, [errors]);

  useEffect(() => {
    setIsAFormChange(true);
    //eslint-disable-next-line
  }, [values]);

  return (
    <form onSubmit={handleSubmit}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <InputLabel>{t('Oferta académica')}</InputLabel>
            <Autocomplete
              options={offersAvailables || []}
              loading={isFetchingPID}
              loadingText={t('Cargando ofertas...')}
              noOptionsText={t('No hay ofertas disponibles')}
              getOptionLabel={(option) => option.off_name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(errors.offer_id && touched.offer_id)}
                  placeholder={t('Seleccione una oferta académica')}
                />
              )}
              onChange={(_, value) => {
                setFieldValue('offer_id', value?.id || '');
              }}
              fullWidth
              clearIcon={null}
            />
            {errors.offer_id && touched.offer_id && (
              <FormHelperText error>{t(errors.offer_id)}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            <InputLabel>{t('Facultad')}</InputLabel>
            <Autocomplete
              options={educationLevelsAvailables}
              loading={isLoadingEDA}
              loadingText={t('Cargando facultades...')}
              noOptionsText={t('No hay facultades disponibles')}
              getOptionLabel={(option) => option.edu_name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(errors.faculty && touched.faculty)}
                  placeholder={t('Seleccione una facultad')}
                />
              )}
              onChange={(_, value) => {
                setFieldValue('faculty', value?.id || '');
              }}
              fullWidth
              clearIcon={null}
            />
            {errors.faculty && touched.faculty && (
              <FormHelperText error>{t(errors.faculty)}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            <InputLabel>{t('Carrera')}</InputLabel>
            <Autocomplete
              options={
                educationLevelsAvailables.find(({ id }) => id === values.faculty)?.children || []
              }
              loading={isLoadingEDA}
              loadingText={t('Cargando carreras...')}
              noOptionsText={t('No hay carreras disponibles')}
              getOptionLabel={(option) => option.edu_name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(errors.education_level_id && touched.education_level_id)}
                  placeholder={t('Seleccione una carrera')}
                />
              )}
              onChange={(_, value) => {
                setFieldValue('education_level_id', value?.id || '');
              }}
              fullWidth
              clearIcon={null}
            />
            {errors.education_level_id && touched.education_level_id && (
              <FormHelperText error>{t(errors.education_level_id)}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            <InputLabel>{t('Período académico')}</InputLabel>
            <Autocomplete
              options={periodsAvailables || []}
              loading={isLoadingPA}
              loadingText={t('Cargando períodos...')}
              noOptionsText={t('No hay períodos disponibles')}
              getOptionLabel={(option) => option.per_name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(errors.id_period && touched.id_period)}
                  placeholder={t('Seleccione un período académico')}
                />
              )}
              onChange={(_, value) => {
                setFieldValue('id_period', value?.id || '');
              }}
              fullWidth
              clearIcon={null}
            />
            {errors.id_period && touched.id_period && (
              <FormHelperText error>{t(errors.id_period)}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            <InputLabel>{t('Tipo de ingreso')}</InputLabel>
            <Autocomplete
              options={ingressAvailables?.data || []}
              loading={isLoadingTI}
              loadingText={t('Cargando tipos de ingreso...')}
              noOptionsText={t('No hay tipos de ingreso disponibles')}
              getOptionLabel={(option) => option.ent_ty_name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(errors.type_entry_id && touched.type_entry_id)}
                  placeholder={t('Seleccione un tipo de ingreso')}
                />
              )}
              onChange={(_, value) => {
                setFieldValue('type_entry_id', value?.id || '');
              }}
              fullWidth
              clearIcon={null}
            />
            {errors.type_entry_id && touched.type_entry_id && (
              <FormHelperText error>{t(errors.type_entry_id)}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            <InputLabel>{t('Puede generar debito')}</InputLabel>

            <FormControlLabel
              control={
                <Switch
                  checked={values.can_generate_debt}
                  {...getFieldProps('can_generate_debt')}
                />
              }
              label={values.can_generate_debt ? 'Si' : 'No'}
            />
          </Grid>
        </Grid>

        <Grid
          item
          container
          alignItems="center"
          justifyContent="start"
          spacing={2}
          xs={4}
          sx={{ mt: 6 }}
        >
          <Grid item xs={6}>
            <Button
              fullWidth
              variant="outlined"
              size="large"
              color="primary"
              onClick={() => handleReturnStep(setCurrentTab)}
            >
              {t('academic.subjects.add.back')}
            </Button>
          </Grid>
          {notErrorForm && isCompleteOthersTabs && (
            <Grid item xs={6}>
              <LoadingButton
                fullWidth
                type="submit"
                variant="contained"
                size="large"
                color="primary"
                isLoading={!!submit?.isLoading}
                disabled={notErrorForm && isCompleteOthersTabs && !isAFormChange}
              >
                Guardar
                {/* {t('academic.subjects.add.submit')} */}
                {/* {notErrorForm && isCompleteOthersTabs ? 'Guardar' : 'Siguiente'} */}
              </LoadingButton>
            </Grid>
          )}

          {/* <Grid item xs={6}>
            <LoadingButton
              onClick={() => !!submit?.setSubmit && submit?.setSubmit()}
              isLoading={!!submit?.isLoading}
            />
          </Grid> */}
        </Grid>
      </Grid>
    </form>
  );
};

export default StudentRecordForm;
