// material-ui
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Box, Button, ButtonBase, Tooltip } from '@material-ui/core';

// project imports
import LogoSection from '../LogoSection';
import ProfileSection from './ProfileSection';

// assets
import { IconMenu2, IconChevronLeft } from '@tabler/icons';
import NotificationSection from './NotificationSection';
import { useHistory } from 'react-router-dom';
import { useAppSelector } from '../../../../../hooks/useRedux';
import LogoERP from '../../../components/LogoERP';
import PersonChangePhotoScreen from '../../../components/person/screens/PersonChangePhotoScreen';
import { FaqCreateDialog } from '../../../../maintenance/faqs/component/create/dialog/FaqCreateDialog';
import { FaqsAdmin } from '../../../../maintenance/faqs/component/buttons/FaqsAdmin';
import { FaqsSearch } from '../../../../maintenance/faqs/component/detail/FaqsSearch';
import { FaqsClient } from '../../../../maintenance/faqs/component/buttons/FaqsClient';
import LanguageSwitcher from './LanguageSwitcher';
import { useTranslation } from 'react-i18next';
import { EvaluationButton } from '../../../components/buttons/EvaluationButton';

// style constant
const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  headerAvatar: {
    ...theme.typography.commonAvatar,
    ...theme.typography.mediumAvatar,
    transition: 'all .2s ease-in-out',
    background:
      theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
    color:
      theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.primary.main,
    '&:hover': {
      background:
        theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.primary.main,
      color:
        theme.palette.mode === 'dark' ? theme.palette.secondary.light : theme.palette.primary.light,
    },
  },
  boxContainer: {
    width: '228px',
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      width: 'auto',
    },
  },
  buttonContainer: {
    width: '228px',
    display: 'flex',
    marginLeft: '16px',
    [theme.breakpoints.down('md')]: {
      width: 'auto',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  logoERPContainer: {
    display: 'flex',
    marginLeft: '16px',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

export interface HeaderProps {
  handleLeftDrawerToggle: () => void;
}

const Header = ({ handleLeftDrawerToggle }: HeaderProps) => {
  const { t } = useTranslation();

  const classes = useStyles();

  const history = useHistory();

  const { currentModule, currentProfile, changePhoto, currentRol, defaultConfiguration } =
    useAppSelector((state) => state.access);

  const { faqsAdmin, faqs } = useAppSelector((state) => state.faqs);

  const { opened } = useAppSelector((state) => state.customization);

  return (
    <>
      {/* logo & toggler button */}
      <div className={classes.boxContainer}>
        <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
          <LogoSection />
        </Box>
        <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
          <Avatar
            variant="rounded"
            className={classes.headerAvatar}
            onClick={handleLeftDrawerToggle}
          >
            <IconMenu2 stroke={1.5} size="1.3rem" />
          </Avatar>
        </ButtonBase>
      </div>

      <Box className={classes.logoERPContainer}>
        <LogoERP />
      </Box>

      {Object.keys(currentRol?.modules || {}).length > 1 && (
        <Box className={classes.buttonContainer}>
          <Tooltip title="Regresar a módulos">
            <Button
              // variant="outlined"
              size="large"
              color="primary"
              startIcon={<IconChevronLeft />}
              onClick={() => history.push('/modulos')}
              autoFocus
            >
              {t(`shared.modules.${currentModule?.key}`)}
            </Button>
          </Tooltip>
        </Box>
      )}

      <div className={classes.grow} />
      <div className={classes.grow} />

      <EvaluationButton />

      {currentProfile?.keyword !== 'estudiante' && currentProfile?.keyword !== 'docente' && (
        <FaqsAdmin />
      )}

      {!opened && <FaqsClient />}

      {true && <NotificationSection />}

      {Boolean(defaultConfiguration?.multilanguage) && <LanguageSwitcher />}

      <ProfileSection />

      {faqsAdmin && <FaqCreateDialog />}

      {faqs && <FaqsSearch />}

      {changePhoto && <PersonChangePhotoScreen />}

      {/* mobile header */}
      <Box sx={{ display: { xs: 'block', sm: 'none' } }}></Box>
    </>
  );
};

export default Header;
