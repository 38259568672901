import { useAppDispatch, useAppSelector } from '../../../../../../hooks/useRedux';
import DeleteAlert from '../../../../../shared/components/dialogs/DeleteAlert';
import { useDeleteEvaluationMutation } from '../../slices/evaluationApiSlice';
import {
  setEvaluationDeleteModalOpen,
  setEvaluationIdToDelete,
} from '../../slices/evaluationSlice';

const EvaluationDeleteDialog = () => {
  const dispatch = useAppDispatch();

  const { evaluationIdToDelete, deleteModalOpen } = useAppSelector(
    (state) => state.evaluationTeacher.evaluation
  );

  const [deleteEvaluation, { isLoading, isSuccess, isError, error }] =
    useDeleteEvaluationMutation();

  const handleDelete = () => {
    deleteEvaluation({ evaluationId: evaluationIdToDelete! });
  };

  const handleClose = () => {
    dispatch(setEvaluationDeleteModalOpen(false));
    dispatch(setEvaluationIdToDelete(null));
  };
  return (
    <DeleteAlert
      open={deleteModalOpen}
      onClose={handleClose}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      handleDelete={handleDelete}
      error={error as string}
    />
  );
};

export default EvaluationDeleteDialog;
