import { Switch, Redirect } from 'react-router-dom';

import { useAppSelector } from '../../../../hooks/useRedux';

import DepartmentsScreen from '../screens/DepartmentsScreen';
import AddDepartmentScreen from '../screens/AddDepartmentScreen';
import EditDepartmentScreen from '../screens/EditDeparmentScreen';

import { permissionKey, subPermissionKeys } from '../constants/permissions';

import GuardRoute from '../../../../router/guards/GuardRoute';

const DepartmentsRouter = () => {
  const { currentModule } = useAppSelector((state) => state.access);

  const hasPermission = permissionKey in currentModule?.permissions!;

  if (!hasPermission) return <Redirect to="/dashboard" />;

  return (
    <Switch>
      <GuardRoute
        exact
        path="/administrar-departamentos"
        component={DepartmentsScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.read}
      />

      <GuardRoute
        exact
        path="/administrar-departamentos/agregar"
        component={AddDepartmentScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.create}
      />

      <GuardRoute
        exact
        path="/administrar-departamentos/:departmentId/editar"
        component={EditDepartmentScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.edit}
      />
    </Switch>
  );
};

export default DepartmentsRouter;
