import { IconUsers, IconSchool, IconHierarchy, IconFiles, IconFileReport } from '@tabler/icons';
import { shared } from '../shared';

// Icon Group
const icons = {
  IconUsers,
  IconSchool,
  IconHierarchy,
  IconFiles,
  IconFileReport,
};

export const hr = {
  id: 'rrhh',
  type: 'group',
  title: 'Recursos Humanos',
  children: [
    {
      id: 'administrar-colaboradores',
      title: 'Colaboradores',
      type: 'item',
      url: '/administrar-colaboradores',
      permissionKey: 'collaborator-rrhh',
      icon: icons['IconUsers'],
    },
    {
      id: 'administrar-horas-docentes',
      title: 'Asignar horas a docencia',
      type: 'item',
      url: '/administrar-horas-docentes',
      permissionKey: 'hourSummary',
      icon: icons['IconSchool'],
    },
    {
      id: 'administrar-cargos',
      title: 'Cargos',
      type: 'item',
      url: '/administrar-cargos',
      permissionKey: 'position',
      icon: icons['IconHierarchy'],
    },
    {
      id: 'administrar-departamentos',
      title: 'Departamentos',
      type: 'item',
      url: '/administrar-departamentos',
      permissionKey: 'department',
      icon: icons['IconHierarchy'],
    },
    {
      id: 'administrar-solicitudes-rrhh',
      title: 'Solicitudes',
      type: 'item',
      url: '/administrar-solicitudes-rrhh',
      permissionKey: 'department',
      icon: icons['IconHierarchy'],
    },

    {
      id: 'talento-humano',
      title: 'Talento Humano',
      type: 'collapse',
      permissionKey: 'department',
      icon: icons['IconFileReport'],
      children: [
        {
          id: 'entradas-salidas',
          title: 'Marcaciones',
          type: 'item',
          url: '/entradas-salidas',
          permissionKey: 'department',
        },
        {
          id: 'roles-pagos',
          title: 'Roles de Pago',
          type: 'item',
          url: '/roles-pagos',
          permissionKey: 'department',
        },
        {
          id: 'ticket-comida',
          title: 'Ticket de comida',
          type: 'item',
          url: '/ticket-comida',
          permissionKey: 'department',
        },
      ],
    },
    {
      id: 'solicitudes',
      title: 'Solicitudes',
      type: 'collapse',
      permissionKey: 'department',
      icon: icons['IconFiles'],
      children: [
        {
          id: 'anticipo',
          title: 'Anticipo',
          type: 'item',
          url: '/solicitud/anticipo',
          permissionKey: 'department',
        },
        {
          id: 'solictudvacaciones',
          title: 'Vacaciones',
          type: 'item',
          url: '/solicitud/vacaciones',
          permissionKey: 'department',
        },
        {
          id: 'solictuddiapersonal',
          title: 'Día Personal',
          type: 'item',
          url: '/solicitud/dia-personal',
          permissionKey: 'department',
        },
        {
          id: 'solictudhappybirthdayhour',
          title: 'Happy Birthday Hour',
          type: 'item',
          url: '/solicitud/happy-birthday-hour',
          permissionKey: 'department',
        },
        {
          id: 'solictud-estados',
          title: 'Listado',
          type: 'item',
          url: '/solicitud/estados',
          permissionKey: 'department',
        },
      ],
    },
    // shared menu
    ...shared,
  ],
};
