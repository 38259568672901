import { Redirect } from 'react-router-dom';
import { useAppSelector } from '../../../../hooks/useRedux';
import { Switch } from 'react-router-dom';
import AddMatterTypeScreen from '../screens/AddMatterTypeScreen';
import EditMatterTypeScreen from '../screens/EditMatterTypeScreen';
import MattersTypesScreen from '../screens/MattersTypesScreen';

import GuardRoute from '../../../../router/guards/GuardRoute';

import { subPermissionKeys } from '../constants/permissions';

const { groupKey: permissionKey } = subPermissionKeys;

function MatterTypesRouter() {
  // Verify permission
  const { currentModule } = useAppSelector((state) => state.access);
  const hasPermission = 'typeMatters' in currentModule?.permissions!;

  if (!hasPermission) return <Redirect to="/" />;

  return (
    <Switch>
      <GuardRoute
        exact
        path="/administrar-tipos-materias"
        component={MattersTypesScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.read}
      />

      <GuardRoute
        exact
        path={`/administrar-tipos-materias/agregar`}
        component={AddMatterTypeScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.create}
      />

      <GuardRoute
        exact
        path={`/administrar-tipos-materias/:matterTypeId/editar`}
        component={EditMatterTypeScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.edit}
      />
    </Switch>
  );
}

export default MatterTypesRouter;
