import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISearchTable } from '../../../../../shared/interfaces/paginatedTable.interface';
import { IScope, ScopesState } from '../interfaces/scopes.interface';

const initialState: ScopesState = {
  scopesList: [],
  scopesIdSelected: null,
  scopesIdToDelete: null,
  scopesSideDialogOpen: false,
  scopesDeleteModalOpen: false,
  scopesTable: {
    page: 1,
    perPage: 5,
    search: '',
  },
  status: 'A',
};

export const scopesSlice = createSlice({
  name: 'scopes',
  initialState,
  reducers: {
    setScopesList: (state, action: PayloadAction<IScope[]>) => {
      state.scopesList = action.payload;
    },
    setScopesIdSelected: (state, action: PayloadAction<string | null>) => {
      state.scopesIdSelected = action.payload;
    },
    setScopesIdToDelete: (state, action: PayloadAction<string | null>) => {
      state.scopesIdToDelete = action.payload;
    },
    setScopesDeleteModalOpen: (state, action: PayloadAction<boolean>) => {
      state.scopesDeleteModalOpen = action.payload;
    },
    setScopesTable: (state, action: PayloadAction<ISearchTable>) => {
      state.scopesTable = {
        ...state.scopesTable,
        ...action.payload,
      };
    },
    setScopesSideDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.scopesSideDialogOpen = action.payload;
    },
    setStatusScopes: (state, action: PayloadAction<string>) => {
      state.status = action.payload;
    },
  },
});

export const {
  setScopesList,
  setScopesIdSelected,
  setScopesIdToDelete,
  setScopesDeleteModalOpen,
  setScopesTable,
  setScopesSideDialogOpen,
  setStatusScopes,
} = scopesSlice.actions;
