import { StatusMatterPredefined } from '../interfaces/student-history-calification.interfaces';

interface Year {
  id: number;
  value: string;
}

interface Period {
  id: number;
  name: string;
}

export const YearList: Year[] = [
  { id: 1, value: '2011' },
  { id: 2, value: '2012' },
  { id: 3, value: '2013' },
  { id: 4, value: '2014' },
  { id: 5, value: '2015' },
  { id: 6, value: '2016' },
  { id: 7, value: '2017' },
  { id: 8, value: '2018' },
  { id: 9, value: '2019' },
  { id: 10, value: '2020' },
  { id: 11, value: '2021' },
  { id: 12, value: '2022' }, //considerar data dinamica
];

export const PeriodList: Period[] = [
  { id: 1, name: 'Primer período' },
  { id: 2, name: 'Segundo período' },
];

export const statusHistoryAcademic: StatusMatterPredefined[] = [
  { id: 10, name: 'Aprobado', color: 'success' },
  { id: 11, name: 'Reprobado', color: 'orange' },
  { id: 12, name: 'Retirado', color: 'warning' },
  { id: 13, name: 'Cursando', color: 'warning' },
];

interface CareerAcademic {
  id: number;
  area: string;
  credit_required: number;
  credit_ausent: number;
  credit_aprobed: number;
  matter_required: number;
  matter_aprobed: number;
  matter_ausent: number;
  detail: string;
}

export const careerAcademicList: CareerAcademic[] = [
  {
    id: 1,
    area: 'Área 1',
    credit_required: 0,
    credit_aprobed: 12,
    credit_ausent: 12,
    matter_required: 10,
    matter_aprobed: 5,
    matter_ausent: 1,
    detail: '',
  },
  {
    id: 2,
    area: 'Área 2',
    credit_required: 0,
    credit_aprobed: 12,
    credit_ausent: 12,
    matter_required: 10,
    matter_aprobed: 5,
    matter_ausent: 1,
    detail: '',
  },
  {
    id: 3,
    area: 'Área 3',
    credit_required: 0,
    credit_aprobed: 12,
    credit_ausent: 12,
    matter_required: 10,
    matter_aprobed: 5,
    matter_ausent: 1,
    detail: '',
  },
];

interface MatterDetailList {
  code: string;
  matter: string;
  note: string;
  status: number;
  pre_requisite: string;
  credit_hours: number;
}

export const FilterAnnouncements = [
  {
    key: 'all',
    value: 'all',
    label: 'Todos',
  },
  {
    key: 'new',
    value: 'new',
    label: 'Más recientes',
  },
];

export const matterDetailList: MatterDetailList[] = [
  {
    code: 'CODE001',
    matter: 'Fundamentos Programación',
    note: '10',
    status: 1,
    pre_requisite: 'Prerequisito',
    credit_hours: 4.2,
  },
  {
    code: 'CODE002',
    matter: 'Fundamentos Matemática',
    note: '4',
    status: 2,
    pre_requisite: 'Prerequisito',
    credit_hours: 6.2,
  },
];

interface StatusMatter {
  id: number;
  name: string;
  color: 'orange' | 'warning' | 'success' | 'gray';
}

export const matterStatus: StatusMatter[] = [
  { id: 13, name: 'Cursando', color: 'success' },
  { id: 1, name: 'Aprobado', color: 'success' },
  { id: 2, name: 'Reprobado', color: 'orange' },
  { id: 3, name: 'No cursada', color: 'gray' },
  { id: 4, name: 'Retirado', color: 'warning' },
];
