import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  boxContainer: {
    borderRadius: 5,
    backgroundColor: '#fff',
    paddingInline: 16,
    paddingBlock: 10,
    boxShadow: '0px 0px 20px #EAF0F7',
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
  },
  number: {
    fontWeight: 800,
    color: theme.palette.primary.main,
    fontSize: 16,
  },
  title: {
    color: theme.palette.grey[800],
    fontSize: 14,
  },
}));

interface Props {
  title: string;
  number: number;
}

const InfoBadge = ({ title, number }: Props) => {
  const classes = useStyles();

  return (
    <Box className={classes.boxContainer}>
      <Typography className={classes.title}>{title}</Typography>
      <Typography className={classes.number}>{number}</Typography>
    </Box>
  );
};

export default InfoBadge;
