import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RequestStatus } from '../interfaces/typesApplications.interface';

interface initialStateProps {
  requestStatus?: RequestStatus;
  statusNameToAddRol?: string;
}

const initialState: initialStateProps = {};

const requestsStatusSlice = createSlice({
  name: 'requestsStatus',
  initialState,
  reducers: {
    setRequestStatus: (state, action: PayloadAction<RequestStatus>) => {
      state.requestStatus = action.payload;
    },
    setStatusNameToAddRol: (state, action: PayloadAction<string>) => {
      state.statusNameToAddRol = action.payload;
    },
  },
});

export const { setRequestStatus, setStatusNameToAddRol } = requestsStatusSlice.actions;

export default requestsStatusSlice.reducer;
