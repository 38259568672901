export const permissionKey = 'certificates';

export interface SubPermissionKeys {
  read: string;
  get: string;
  create: string;
  edit: string;
  delete: string;
}

export const subPermissionKeys: SubPermissionKeys = {
  read: 'applications-certificates-eduCerPreview',
  create: 'applications-certificates-store',
  edit: 'application-actualizar-solicitudes',
  get: 'application-obtener-solicitudes',
  delete: 'application-borrar-solicitudes',
};
