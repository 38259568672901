import { useMemo } from 'react';

import { Grid, IconButton, Stack, Tooltip, useTheme } from '@material-ui/core';
import { useParams } from 'react-router-dom';
// import { PDFDownloadLink } from '@react-pdf/renderer';

// import { useAppDispatch, useAppSelector } from '@src/hooks/useRedux';
import IconPDF from '../../../../../../../assets/icons/pdf.svg';

import ActivitiesReport from './ActivitiesReport';
import WeightingHeader from './WeightingHeader';
import { useGetIntegralWeightingReportQuery } from '../../slices/integralReportsApiSlice';
import { WeightingReportTable } from '../../interfaces/integralsReports.interface';
import ActivitiesTotalReport from './ActivitiesTotalReport';
import { createPdfReport } from '../../helpers/createPdfReport';
// import ReportTablePdf from './ReportTablePdf';

const Weighting = () => {
  const theme = useTheme();

  const { evaId, userId } = useParams<{ evaId: string; userId: string }>();

  const { data } = useGetIntegralWeightingReportQuery({
    evaId: evaId!,
    userId: userId!,
  });

  const user = useMemo(
    () =>
      data?.data.length
        ? {
            info: data?.data?.[0]?.user || 'N/A',
            period: `Desde ${data.data?.[0]?.date_range?.start_date || 'N/A'} hasta ${
              data.data?.[0]?.date_range?.end_date || 'N/A'
            }`,
          }
        : null,
    [data]
  );

  const datatable: WeightingReportTable[] = useMemo(() => {
    const tempData: { [key: string]: WeightingReportTable } = {};

    if (data?.data.length) {
      for (const el of data.data) {
        if (el.category.description in tempData) {
          tempData[el.category.description] = {
            ...tempData[el.category.description],
            [el.eva_type.acronym?.toLowerCase() as string]: {
              weight: el.weight,
              weighing: el.weighing,
              calification: el.calification,
              ...el.eva_type,
            },
          };

          // eslint-disable-next-line no-continue
          continue;
        }

        tempData[el.category.description] = {
          uuid: el.uuid,
          integral_evaluation_cab_uuid: el.integral_evaluation_cab_uuid,
          category_uuid: el.category_uuid,
          evaluated_user_uuid: el.eva_typ_uuid,
          eva_typ_uuid: el.eva_typ_uuid,
          description: el.category.description,
          date_range: el.date_range,

          integral_evaluation_cab: el.integral_evaluation_cab,

          [el.eva_type.acronym?.toLowerCase() as string]: {
            weight: el.weight,
            weighing: el.weighing,
            calification: el.calification,
            ...el.eva_type,
          },
        };
      }
    }

    return Object.values(tempData);
  }, [data]);

  return (
    <Stack
      sx={{
        p: 2,
        border: `2px solid ${theme.palette.secondary.main}`,
        borderRadius: '12px',
      }}
      spacing={5}
    >
      <Grid item>
        <WeightingHeader user={user?.info} period={user?.period} />
      </Grid>
      <Grid item>
        <ActivitiesReport data={datatable} />
      </Grid>
      <Grid item>
        <ActivitiesTotalReport data={datatable} />
      </Grid>

      {/* {true && (
        <Grid item>
          <Button onClick={() => createPdfReport({ data: datatable })}>createPdfReport</Button>
        </Grid>
      )} */}

      <Grid item xs={12}>
        <Grid container justifyContent="center">
          <Grid item id="report-export-excel" />
          <Grid item id="report-export-pdf">
            <Tooltip title="Descargar PDF">
              <IconButton onClick={() => createPdfReport({ data: datatable })}>
                <img
                  style={{
                    width: '24px',
                    height: '24px',
                  }}
                  src={IconPDF}
                  alt=""
                />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default Weighting;
