import { useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
  Autocomplete,
  Typography,
  Alert,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../../../hooks/useRedux';
import SuccessAlert from '../../../../../shared/components/alerts/SuccessAlert';
import ErrorAlert from '../../../../../shared/components/alerts/ErrorAlert';
import { integralEvaluationFormSchema } from '../validation/integralEvaluation.validation';
import {
  useAddIntegralEvaluationMutation,
  useUpdateIntegralEvaluationMutation,
} from '../slices/integralEvaluationApiSlice';
import { useGetAllEvaluationTypesQuery } from '../../evaluation_type/slices/evaluationTypeApiSlice';
import { useParams } from 'react-router-dom';
import { IEvaluationType } from '../../evaluation_type/interfaces/evaluationType.interface';

const IntegralEvaluationForm = () => {
  const { id } = useParams<{
    id: string;
  }>();

  const { t } = useTranslation();
  const [showAlerts, setShowAlerts] = useState(false);

  const [evaTypes, setEvaTypes] = useState<
    {
      id?: string | number;
      eva_uuid: string;
      eva_name: string;
      eva_peso: string | number;
    }[]
  >([]);

  const [autocompleteEvaType, setAutocompleteEvaType] = useState<IEvaluationType[]>([]);

  console.log(autocompleteEvaType);

  const { data: evaluationTypes, isLoading: isLoadingEvaluationTypes } =
    useGetAllEvaluationTypesQuery({});

  const [addIntegralEvaluation, { isLoading, isError, error, isSuccess }] =
    useAddIntegralEvaluationMutation();

  const [
    updateIntegralEvaluation,
    { isLoading: isLoadingU, isError: isErrorU, error: errorU, isSuccess: isSuccessU },
  ] = useUpdateIntegralEvaluationMutation();

  const { integralEvaluationsList } = useAppSelector((state) => state.integralEvaluation);

  const rubricSelected = integralEvaluationsList.find((option) => option.id!.toString() === id);

  const { errors, getFieldProps, handleSubmit, touched, resetForm, setFieldValue } = useFormik<{
    name: string;
    description: string;
    eva_rubrica_detail: {
      eva_types_id: string;
      eva_peso: string | number;
      id?: string | number;
    }[];
  }>({
    initialValues: {
      name: rubricSelected?.name || '',
      description: rubricSelected?.description || '',
      eva_rubrica_detail: [],
    },
    validationSchema: integralEvaluationFormSchema,
    onSubmit: async (currentValues) => {
      if (rubricSelected) {
        await updateIntegralEvaluation({
          payload: {
            ...currentValues,
            eva_rubrica_detail: currentValues.eva_rubrica_detail.map((evaType, index) => ({
              id: evaType.id || null,
              eva_types_id: evaType.eva_types_id,
              eva_peso: evaType.eva_peso,
            })),
          },
          id: rubricSelected.id,
        });
      } else {
        await addIntegralEvaluation({
          ...currentValues,
          eva_rubrica_detail: currentValues.eva_rubrica_detail.map((evaType, index) => ({
            eva_types_id: evaType.eva_types_id,
            eva_peso: evaType.eva_peso,
          })),
        });
      }

      setShowAlerts(true);
    },
  });

  useEffect(() => {
    if (rubricSelected && evaluationTypes && evaluationTypes.length > 0) {
      const evaTypesFilter = evaluationTypes?.filter((evaType) =>
        rubricSelected.eva_rubrica_detail?.find(
          (eva) => eva?.eva_types?.uuid!.toString() === evaType?.uuid!.toString()
        )
      );

      setAutocompleteEvaType(evaTypesFilter || []);
      setEvaTypes(
        evaTypesFilter?.map((evaType) => ({
          eva_uuid: evaType.uuid!,
          eva_name: evaType.name!,
          eva_peso:
            rubricSelected.eva_rubrica_detail?.find(
              (eva) => eva?.eva_types?.uuid! === evaType?.uuid!
            )?.eva_peso || 1,
          id: rubricSelected.eva_rubrica_detail?.find(
            (eva) => eva?.eva_types?.uuid! === evaType?.uuid!
          )?.id,
        })) || []
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rubricSelected, evaluationTypes]);

  useEffect(() => {
    if (evaTypes.length > 0) {
      const eva_types_map = evaTypes.map((evaType) => ({
        id: evaType.id,
        eva_types_id: evaType.eva_uuid,
        eva_peso: evaType.eva_peso,
      }));
      setFieldValue('eva_rubrica_detail', eva_types_map);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [evaTypes]);

  useEffect(() => {
    if (isSuccess) {
      setEvaTypes([]);
      setAutocompleteEvaType([]);
      resetForm();
    }
  }, [isSuccess, resetForm]);

  return (
    <Grid container spacing={2}>
      {(isSuccess || isSuccessU) && showAlerts && (
        <Grid item xs={12}>
          <SuccessAlert
            message={
              isSuccess
                ? t('Se agrego correctamente el escenario de evaluación')
                : t('Se editó correctamente el escenario de evaluación')
            }
            handleDismiss={() => setShowAlerts(false)}
          />
        </Grid>
      )}
      {(isError || isErrorU) && showAlerts && (
        <Grid item xs={12}>
          <ErrorAlert
            message={(error || errorU) as string}
            handleDismiss={() => setShowAlerts(false)}
          />
        </Grid>
      )}

      <Grid item xs={12}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputLabel>{t('maintenance.integral_evaluation.form.name')}</InputLabel>
              <TextField
                placeholder={t('maintenance.integral_evaluation.form.name.placeholder')}
                error={Boolean(errors.name && touched.name)}
                {...getFieldProps('name')}
                fullWidth
              />
              {errors.name && touched.name && (
                <FormHelperText error>{t(errors.name)}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12}>
              <InputLabel>{t('maintenance.integral_evaluation.description')}</InputLabel>
              <TextField
                placeholder={t('maintenance.integral_evaluation.description.placeholder')}
                error={Boolean(errors.description && touched.description)}
                {...getFieldProps('description')}
                fullWidth
              />
              {errors.description && touched.description && (
                <FormHelperText error>{t(errors.description)}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12}>
              <InputLabel>{t('maintenance.integral_evaluation.evaTypes')}</InputLabel>
              {isLoadingEvaluationTypes ? (
                <TextField fullWidth placeholder={t('shared.filters.eva_type.loading')} disabled />
              ) : (
                <Autocomplete
                  multiple
                  value={rubricSelected && autocompleteEvaType}
                  options={evaluationTypes || []}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, newValue) => {
                    if (newValue && !(newValue.length < evaTypes.length)) {
                      setAutocompleteEvaType(newValue);
                      const lastElement = newValue[newValue.length - 1];
                      setEvaTypes([
                        ...evaTypes,
                        {
                          eva_uuid: lastElement?.uuid!,
                          eva_name: lastElement?.name!,
                          eva_peso: 1,
                        },
                      ]);
                    } else if (newValue.length < evaTypes.length) {
                      setAutocompleteEvaType(newValue);
                      const newEvaTypes = evaTypes.filter((evaType) =>
                        newValue.find((option) => option.uuid === evaType.eva_uuid)
                      );
                      setEvaTypes(newEvaTypes);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="medium"
                      fullWidth
                      placeholder={t('shared.filters.eva_type.placeholder')}
                    />
                  )}
                />
              )}
            </Grid>

            <Grid container item xs={12}>
              <Grid
                item
                xs={12}
                sx={{
                  mb: 2,
                }}
              >
                <InputLabel>
                  {t('maintenance.integral_evaluation.evaQuestions_and_eva_peso')}
                </InputLabel>
                <Grid
                  item
                  xs={12}
                  sx={{
                    my: 1,
                  }}
                >
                  <Alert severity="info">
                    <Typography align="left">{t('La suma de los pesos debe ser 100%')}</Typography>
                  </Alert>
                </Grid>
              </Grid>
              <Grid item container xs={12}>
                {evaTypes.length === 0 ? (
                  <Grid item xs={12}>
                    <FormHelperText error>{t('Seleccione las rubricas a utilizar')}</FormHelperText>
                  </Grid>
                ) : (
                  <Grid item xs={12}>
                    {evaTypes.map((evaType, index) => (
                      <Grid
                        item
                        xs={6}
                        key={evaType.eva_uuid}
                        sx={{
                          my: 2,
                          mx: 'auto',
                        }}
                      >
                        <Grid
                          item
                          xs={12}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <Typography
                            variant="h5"
                            color="textSecondary"
                            sx={{
                              wordWrap: 'break-word',
                              width: '150px',
                            }}
                          >
                            {evaType.eva_name}
                          </Typography>
                          <TextField
                            variant="outlined"
                            type="number"
                            inputProps={{
                              min: 1,
                              max: 100,
                            }}
                            placeholder={t('Ingrese un peso')}
                            value={Number(evaType.eva_peso)}
                            onChange={(event) => {
                              const evaTypesCopy = [...evaTypes];
                              evaTypesCopy[index].eva_peso = event.target.value;
                              setEvaTypes(evaTypesCopy);
                            }}
                            sx={{
                              mx: 1,
                              width: '80px',
                            }}
                          />
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                )}
                {evaTypes.length > 0 && (
                  <Grid
                    item
                    xs={6}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      my: 4,
                      mx: 'auto',
                    }}
                  >
                    <Typography variant="h5">{t('Total')}: &nbsp;</Typography>
                    <Typography variant="h5">
                      {evaTypes.reduce((acc, evaType) => acc + Number(evaType.eva_peso), 0)}
                    </Typography>
                  </Grid>
                )}
              </Grid>

              <Grid
                item
                container
                xs={12}
                justifyContent="flex-end"
                spacing={2}
                sx={{
                  mt: 3,
                }}
              >
                <Grid item>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="large"
                    disabled={isLoading || isLoadingU}
                    endIcon={(isLoading || isLoadingU) && <CircularProgress size={12} />}
                  >
                    {!rubricSelected
                      ? t('maintenance.integral_evaluation.add.submit')
                      : t('maintenance.integral_evaluation.edit.submit')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default IntegralEvaluationForm;
