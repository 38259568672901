import { Box, Grid, Tab, Tabs } from '@material-ui/core';
import { useMemo, useState } from 'react';
import { IconUsers, IconNotes, IconFlag } from '@tabler/icons';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Link as RouterLink } from 'react-router-dom';
import AssignDiscount from './AssignDiscount';
import ListDiscount from './ListDiscount';
import DisabledDiscount from './DisabledDiscount';
import PermissionsCheck from '../../../../shared/components/PermissionCheck';
import {
  groupConfigurationPermissions,
  groupConfigurationStudentsPermissions,
} from '../../constants/permissions';

interface ArrayItems {
  id: number;
  name: string;
  path: string;
  icon: JSX.Element;
}

const TabPanel = (props: { children: React.ReactElement; value: number; index: number }) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={0}>{children}</Box>}
    </div>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

export const useStyles = makeStyles((theme) => ({
  accountTab: {
    marginBottom: '24px',
    '& a': {
      minHeight: 'auto',
      minWidth: '10px',
      padding: '12px 8px',
      marginRight: '18px',
      color: theme.palette.grey[600],
    },
    '& a.Mui-selected': {
      color: theme.palette.primary.main,
    },
    '& a > span': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '& a > span > svg': {
      marginBottom: '0px !important',
      marginRight: '10px',
    },
    '& a > span > span + svg': {
      margin: '0px 0px 0px auto !important',
      width: '14px',
      height: '14px',
    },
  },
}));

const DiscountTab = () => {
  const { optionMenu } = useParams<{ optionMenu: string }>();

  const { t } = useTranslation();

  const arrayItems: ArrayItems[] = useMemo(
    () => [
      {
        id: 0,
        name: 'financial.client_list.discount_tab.new_assign',
        path: 'asignacion',
        icon: <IconFlag />,
      },
      {
        id: 1,
        name: 'financial.client_list.discount_tab.assigned_discounts',
        path: 'lista',
        icon: <IconUsers />,
      },
      {
        id: 2,
        name: 'financial.client_list.discount_tab.inactive_discounts',
        path: 'inactivos',
        icon: <IconNotes />,
      },
    ],
    []
  );

  const [value, setValue] = useState(arrayItems.find((e) => e.path === optionMenu)?.id || 0);
  const classes = useStyles();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={8} sx={{ mt: 2, width: 1 }}>
          <Box width="100%" sx={{ maxWidth: '100%', width: 1 }} style={{ marginTop: 20 }}>
            <Tabs
              value={value}
              indicatorColor="primary"
              className={classes.accountTab}
              aria-label="Asignacion de descuentos"
              variant="fullWidth"
              onChange={handleChange}
            >
              {arrayItems.map((item) => (
                <Tab
                  key={`client-assing-discount-tabs-${item.id}`}
                  icon={item.icon}
                  component={RouterLink}
                  to={item.path}
                  label={t(item.name)}
                  {...a11yProps(item.id)}
                />
              ))}
            </Tabs>
            <TabPanel value={value} index={0}>
              <PermissionsCheck
                permissions={[
                  groupConfigurationPermissions.read,
                  groupConfigurationPermissions.store,
                ]}
              >
                <AssignDiscount />
              </PermissionsCheck>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <PermissionsCheck
                permissions={[groupConfigurationStudentsPermissions.groupConfigurationByStudent]}
              >
                <ListDiscount />
              </PermissionsCheck>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <PermissionsCheck permissions={[groupConfigurationStudentsPermissions.disabledGroup]}>
                <DisabledDiscount />
              </PermissionsCheck>
            </TabPanel>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default DiscountTab;
