import { useState } from 'react';

// material-ui
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Grid,
  TextField,
  useTheme,
  InputAdornment,
  IconButton,
  Collapse,
} from '@material-ui/core';

import SearchIcon from '@material-ui/icons/Search';
import { renderErrorOrEmptyRow, renderSkeletonRows } from '../../helpers/render';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

// helpers
import { objectKeys } from '../../helpers';

// types
import {
  CustomCellProperties,
  CustomRenderers,
  TableHeaders,
} from '../../interfaces/material-ui.interfaces';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { SerializedError } from '@reduxjs/toolkit';
import { DebounceInput } from 'react-debounce-input';
import { IconArrowNarrowDown, IconArrowNarrowUp, IconX } from '@tabler/icons';
import CustomPaginationAndItems from './CustomPaginationAndItems';
import useFormatDate from '../../hooks/useFormatDate';

export interface FilterArrow {
  name: string;
  direction: string;
}

interface ListFilterArrow {}

interface Props<T extends {}> {
  headers: TableHeaders<T>;
  data: T[];
  keyExtractor: (item: T) => string;
  customRenderers?: CustomRenderers<T>;
  searchPlacehoder: string;
  customDataCellsProperties?: CustomCellProperties<T>;
  customHeadersCellsProperties?: CustomCellProperties<T>;
  isLoading: boolean;
  isFetching: boolean;
  error: FetchBaseQueryError | SerializedError | undefined;
  total: number;
  perPage: number;
  setPerPage: React.Dispatch<React.SetStateAction<number>> | ((perPage: number) => void);
  page: number;
  numHeader: number;
  setPage: React.Dispatch<React.SetStateAction<number>> | ((page: number) => void);
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>> | ((search: string) => void);
  ActionButtons?: JSX.Element;
  actBtnFullWith?: boolean;
  replaceHeader?: boolean;
  selectedCount?: number;
  newHeader?: JSX.Element;
  isCollapsible?: boolean;
  overwriteRowC?: number;
  CollapsibleItems?: (id: number) => JSX.Element;
  showFilter?: boolean;
  listFilterArrows?: ListFilterArrow;
  setFilterArrow?:
    | React.Dispatch<React.SetStateAction<FilterArrow>>
    | (() => void)
    | ((filterArrow: FilterArrow) => void);
  filterArrow?: FilterArrow;
  /**
   * Nueva fila para filtros opcionales
   */
  additionalFilters?: JSX.Element;
  /**
   * Para determinar la posicion de la fila de filtros (encima o debajo del search)
   */
  filtersPos?: 'top' | 'bottom';
}

const CustomCollapsibleTable = <T extends {}>({
  data,
  headers,
  customRenderers,
  keyExtractor,
  searchPlacehoder,
  customDataCellsProperties,
  customHeadersCellsProperties,
  // numHeader,
  isLoading,
  isFetching,
  error,
  perPage,
  setPerPage,
  total,
  page,
  setPage,
  search,
  setSearch,
  ActionButtons,
  actBtnFullWith,
  replaceHeader,
  newHeader,
  // if you use collapsible table
  //you must use isCollapsible

  additionalFilters,
  filtersPos = 'bottom',

  isCollapsible,
  overwriteRowC,
  CollapsibleItems,
  showFilter = true,
  listFilterArrows = {},
  filterArrow: customFilterArrow,
  setFilterArrow: setFilterArrowDispatch,
}: Props<T>) => {
  const numHeader = Object.keys(headers).length;

  const theme = useTheme();

  const { formatTableDate } = useFormatDate();

  const RenderRows = () => {
    return (
      <>
        {data!.map((row, i) => (
          <RenderRow key={`parentRow-${i}`} position={i} row={row} />
        ))}
      </>
    );
  };

  const [filterArrow, setFilterArrow] = useState<FilterArrow>(customFilterArrow || null!);

  const handleFilterArrowDown = (name: string, direction: string, nameHeader: string) => {
    setFilterArrow({ name: nameHeader, direction });
    if (setFilterArrowDispatch) setFilterArrowDispatch({ name, direction });
  };

  const handleFilterArrowUp = (name: string, direction: string, nameHeader: string) => {
    setFilterArrow({ name: nameHeader, direction });
    if (setFilterArrowDispatch) setFilterArrowDispatch({ name, direction });
  };

  const RenderRow = (props: { row: T; position: number }) => {
    const [open, setOpen] = useState(false);
    const [id, setId] = useState<number>(-1);
    const { row, position } = props;
    return (
      <>
        <TableRow className="table-row" key={`${keyExtractor(row)}-row-coll`}>
          {objectKeys(row).map((cell, i) => {
            const customRenderer = customRenderers?.[cell];
            if (isCollapsible && overwriteRowC !== undefined && overwriteRowC === i) {
              return (
                <TableCell key={`${keyExtractor(row)}-rowtrigger`} sx={{ pl: 3 }} width="5%">
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => {
                      setId(!open ? position : -1);
                      setOpen(!open);
                    }}
                  >
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </IconButton>
                </TableCell>
              );
            }
            if (customRenderer) {
              return (
                <TableCell
                  key={`${keyExtractor(row)}-${String(cell)}`}
                  {...customDataCellsProperties?.[cell]}
                >
                  {customRenderer(row)}
                </TableCell>
              );
            }

            return (
              <>
                <TableCell
                  key={`${keyExtractor(row)}-${String(cell)}`}
                  {...customDataCellsProperties?.[cell]}
                >
                  {formatTableDate(row[cell])}
                </TableCell>
              </>
            );
          })}
          {isCollapsible && overwriteRowC === undefined && (
            <TableCell key={`${keyExtractor(row)}-rowtrigger`} sx={{ pl: 3 }} width="5%">
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => {
                  setId(!open ? position : -1);
                  setOpen(!open);
                }}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell>
          )}
        </TableRow>
        {isCollapsible && (
          <TableRow className="table-row" key={`${keyExtractor(row)}-collapsiblerow`}>
            <TableCell
              style={{ paddingBottom: 0, paddingTop: 0 }}
              colSpan={numHeader}
              sx={{ backgroundColor: theme.palette.primary.light }}
            >
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Box p={4}>{CollapsibleItems && CollapsibleItems(id)}</Box>
              </Collapse>
            </TableCell>
          </TableRow>
        )}
      </>
    );
  };

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
        {additionalFilters && filtersPos === 'top' && (
          <Grid item xs={12}>
            {additionalFilters}
          </Grid>
        )}
        <Grid item xs={12} sm={4}>
          {showFilter && (
            <DebounceInput
              autoFocus={Boolean(search)}
              minLength={2}
              debounceTimeout={300}
              onChange={({ target }) => setSearch(target.value)}
              value={search}
              element={(props) => (
                <TextField
                  style={{
                    width: '100%',
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon fontSize="small" />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          onClick={() => setSearch('')}
                          {...(!search && { sx: { cursor: 'initial', opacity: 0 } })}
                        >
                          <IconX size={20} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  placeholder={searchPlacehoder}
                  variant="outlined"
                  size="small"
                  {...props}
                />
              )}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={!actBtnFullWith && 6} sx={{ textAlign: 'right' }}>
          {ActionButtons}
        </Grid>
        {additionalFilters && filtersPos === 'bottom' && (
          <Grid item xs={12}>
            {additionalFilters}
          </Grid>
        )}
      </Grid>
      <TableContainer {...(replaceHeader && { sx: { mt: 2 } })}>
        <Table>
          <TableHead>
            <TableRow>
              {replaceHeader && <>{newHeader}</>}
              {!replaceHeader &&
                Object.keys(headers).map((key) => (
                  <TableCell key={key} {...customHeadersCellsProperties?.[key as keyof T]}>
                    {/* {headers[key as keyof T]} */}
                    <Grid container justifyContent="space-around" alignItems="center">
                      <Grid item>{headers[key as keyof T]}</Grid>
                      {Object.keys(listFilterArrows).some((e) => e === key) && (
                        <Grid item>
                          <IconArrowNarrowDown
                            style={{
                              color:
                                filterArrow?.direction === 'asc' &&
                                filterArrow?.name === listFilterArrows[key as keyof ListFilterArrow]
                                  ? theme.palette.primary.main
                                  : 'inherit',
                              cursor: 'pointer',
                            }}
                            stroke={
                              filterArrow?.direction === 'asc' &&
                              filterArrow?.name === listFilterArrows[key as keyof ListFilterArrow]
                                ? 2
                                : 1
                            }
                            size={18}
                            onClick={() =>
                              handleFilterArrowUp(
                                listFilterArrows[key as keyof ListFilterArrow],
                                'asc',
                                listFilterArrows[key as keyof ListFilterArrow]
                              )
                            }
                          />
                          <IconArrowNarrowUp
                            style={{
                              color:
                                filterArrow?.direction === 'desc' &&
                                filterArrow?.name === listFilterArrows[key as keyof ListFilterArrow]
                                  ? theme.palette.primary.main
                                  : 'inherit',
                              cursor: 'pointer',
                            }}
                            stroke={
                              filterArrow?.direction === 'desc' &&
                              filterArrow?.name === listFilterArrows[key as keyof ListFilterArrow]
                                ? 2
                                : 1
                            }
                            size={18}
                            onClick={() =>
                              handleFilterArrowDown(
                                listFilterArrows[key as keyof ListFilterArrow],
                                'desc',
                                listFilterArrows[key as keyof ListFilterArrow]
                              )
                            }
                          />
                        </Grid>
                      )}
                    </Grid>
                  </TableCell>
                ))}
              {isCollapsible && <TableCell></TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {isFetching ? renderSkeletonRows(perPage, numHeader) : <RenderRows />}

            {error && renderErrorOrEmptyRow(numHeader, error as string)}
            {data.length === 0 && !isFetching && renderErrorOrEmptyRow(numHeader)}
          </TableBody>
        </Table>
      </TableContainer>

      <CustomPaginationAndItems
        error={error}
        isLoading={isLoading}
        total={total}
        perPage={perPage}
        page={page}
        setPage={setPage}
        setPerPage={setPerPage}
        perPageBox
        paginationBox
      />
    </>
  );
};

export default CustomCollapsibleTable;