import { useEffect, useState } from 'react';

// material-ui
import {
  Grid,
  TextField,
  InputAdornment,
  Button,
  IconButton,
  Autocomplete,
  CircularProgress,
  //Avatar,
  Tooltip,
  Typography,
  MenuList,
  MenuItem,
  Stack,
} from '@material-ui/core';

import moment from 'moment';

import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { SerializedError } from '@reduxjs/toolkit';
import { IconHistory, IconMessage, IconDotsVertical, IconList } from '@tabler/icons';
import { useTranslation } from 'react-i18next';

// types
import {
  CustomCellProperties,
  CustomRenderers,
} from '../../../../../shared/interfaces/material-ui.interfaces';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/useRedux';
import {
  StudentAsignedDataSearch,
  StudentsAsignedResponse,
  // StudentAsigned,
  // Course,
} from '../../interfaces/students-asigned.interfaces';
import {
  useGetAvailablesOffersQuery,
  useGetPeriodAcademicOfferingQuery,
} from '../../../../../maintenance/academic-offerings/slices/academicOfferingsApiSlice';
// import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
// import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
// import SubCard from '../../../../../shared/components/cards/SubCard';
import CustomPopper from '../../../../../shared/components/popper/CustomPopper';
//import { obtainDomainStorageImage } from '../../../../../shared/helpers';
import {
  setStudentSelected,
  setOpenModalStudentAds,
  setNotifyAll,
  setOpenModalUserAnnouncements,
  setOpenModalStudentHistoryAnnouncements,
} from '../../slices/studentsAsignedSlice';
import { useGetEducationLevelsParentsVMQuery } from '../../../../../maintenance/education-levels/slices/educationLevelsApiSlice';
import SearchPaginatedTable from '../../../../../shared/components/tables/SearchPaginatedTable';
import { useHistory, useParams } from 'react-router-dom';

interface Props<T extends {}> {
  data: StudentsAsignedResponse;
  customRenderers?: CustomRenderers<T>;
  searchPlacehoder: string;
  customDataCellsProperties?: CustomCellProperties<T>;
  customHeadersCellsProperties?: CustomCellProperties<T>;
  isLoading: boolean;
  isFetching: boolean;
  error: FetchBaseQueryError | SerializedError | undefined;
  total: number;
  perPage: number;
  setPerPage: React.Dispatch<React.SetStateAction<number>> | ((page: number) => void);
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>> | ((page: number) => void);
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>> | ((page: string) => void);
  replaceHeader?: boolean;
  selectedCount?: number;
  newHeader?: JSX.Element;
  dataSearch: StudentAsignedDataSearch;
  setDataSearch: React.Dispatch<React.SetStateAction<StudentAsignedDataSearch>>;
}

const StudentAsignedCouselingTable = <T extends {}>({
  data,
  customRenderers,
  searchPlacehoder,
  isLoading,
  isFetching,
  error,
  perPage,
  setPerPage,
  total,
  page,
  setPage,
  search,
  setSearch,
  replaceHeader,
  dataSearch,
  setDataSearch,
  newHeader,
}: Props<T>) => {
  const { t } = useTranslation();

  const dispacth = useAppDispatch();

  const { collaboratorId } = useParams<{ collaboratorId: string }>();
  const history = useHistory();

  const { collaboratorInfo } = useAppSelector((state) => state.auth.userInfo);

  // ? auxiliar para lanzar refetch de la peticion
  // const [isReftched, setIsRefetched] = useState<number>(0);

  const [periodId, setPeriodId] = useState<number>(dataSearch.periodId || 0);

  const [educationLevelId, setEducationLevelId] = useState<number>(
    dataSearch.educationLevelId || 0
  );

  const [academic, setAcademic] = useState<number>(dataSearch.academic || 0);

  // const theme = useTheme();

  const { currentProfile } = useAppSelector((state) => state.access);

  useEffect(() => {
    setAcademic(
      collaboratorInfo?.education_level_principal_id
        ? Number(collaboratorInfo?.education_level_principal_id)
        : 0
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collaboratorInfo?.education_level_principal_id]);

  //const [toggleCollapse, setToggleCollapse] = useState(false);
  // const [arrayToggleCollapse, setArrayToggleCollapse] = useState<boolean[]>(
  //   data?.data.map((dt) => false) || []
  // );

  // const handleToggleCollapse = async (index: number) => {
  //   //await setToggleCollapse(!toggleCollapse);
  //   if (data) {
  //     const arrayClone = [...arrayToggleCollapse];
  //     arrayClone[index] = !arrayClone[index];
  //     await setArrayToggleCollapse(arrayClone);
  //   }
  // };

  const {
    data: PeriodsList,
    isLoading: isLoadingPeriods,
    isFetching: isFetchingPeriods,
  } = useGetPeriodAcademicOfferingQuery(
    {
      profileId: currentProfile?.profileId!,
      academicOfferingId: educationLevelId.toString(),
    },
    { skip: educationLevelId === 0 }
  );

  const {
    data: dataOffers,
    isLoading: isLoadingOffers,
    isFetching: isFetchingOffers,
  } = useGetAvailablesOffersQuery({
    profileId: currentProfile?.profileId!,
  });

  const {
    data: dataSubjects,
    isLoading: isLoadingSubjects,
    isFetching: isFetchingSubjects,
  } = useGetEducationLevelsParentsVMQuery(
    {
      profileId: currentProfile?.profileId!,
      offerId: educationLevelId,
    },
    { skip: educationLevelId === 0 }
  );

  // const { studentSelected } = useAppSelector((state) => state.studentsAsigned);

  const dataSearchBlank: StudentAsignedDataSearch = {
    periodId: 0,
    educationLevelId: 0,
    academic: 0,
  };

  const dataSearchStudent: StudentAsignedDataSearch = {
    periodId: periodId,
    educationLevelId: educationLevelId,
    academic: academic,
  };

  // const handleDetailCourse = (studentId: number, courseId: number) => {
  //   dispacth(setOpenModalStudentDetailCourse(true));

  //   dispacth(setStudentSelected(studentId));

  //   dispacth(setCourseSelected(courseId));
  // };

  const handleStudentSendMessage = (studentId: number) => {
    dispacth(setStudentSelected(studentId));

    dispacth(setOpenModalStudentAds(true));
  };

  const handleStudentHistoryAds = (studentId: number) => {
    dispacth(setStudentSelected(studentId));

    dispacth(setOpenModalStudentHistoryAnnouncements(true));
  };

  const handleStudentAcademicHistory = (studentId: number) => {
    history.push(
      `/administrar-consejeros/${collaboratorId}/estudiantes/${studentId}/historial-academico`
    );
  };

  const handleUserAnnouncements = () => {
    dispacth(setOpenModalUserAnnouncements(true));
  };

  const handleStudentNotifyAll = () => {
    dispacth(setNotifyAll(true));
  };

  const obtainRelativeTime = (lastActivity: string): string => {
    return lastActivity === null
      ? t('teachers.academic_counseling.students_assigned.no_contact')
      : moment(lastActivity).fromNow();
  };

  // const RenderRowCourses = (props: { course: Course; studentId: number }) => {
  //   const { course, studentId } = props;

  //   return (
  //     <TableRow>
  //       <TableCell>{course?.mat_name}</TableCell>
  //       <TableCell>
  //         <Tooltip
  //           onClick={() => handleDetailCourse(studentId, Number(course.course_id))}
  //           title={t('teachers.academic_counseling.students_assigned.view_grades')}
  //         >
  //           <IconButton
  //             aria-label={t('teachers.academic_counseling.students_assigned.view_grades')}
  //             color="default"
  //           >
  //             <IconClipboardList size={20} />
  //           </IconButton>
  //         </Tooltip>
  //       </TableCell>
  //     </TableRow>
  //   );
  // };

  // const RenderRow = (props: { p: StudentAsigned; index: number }) => {
  //   const [open, setOpen] = useState(false);
  //   const { p } = props;

  //   return (
  //     <>
  //       <TableRow
  //         selected={p.user.user_id === studentSelected?.toString()}
  //         className="table-row"
  //         key={props.index + Number(data.from)}
  //       >
  //         {/* lista de cursos */}
  //         {false && (
  //           <TableCell sx={{ pl: 3 }} align="center">
  //             {p.courses.length !== 0 ? (
  //               <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
  //                 {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
  //               </IconButton>
  //             ) : (
  //               t('teachers.academic_counseling.students_assigned.no_courses')
  //             )}
  //           </TableCell>
  //         )}
  //       </TableRow>
  //       {/* Collapse table courses */}
  //       <TableRow>
  //         <TableCell
  //           style={{ paddingBottom: 0, paddingTop: 0 }}
  //           colSpan={10}
  //           sx={{ backgroundColor: theme.palette.primary.light }}
  //           key={`${props.index + Number(data.from)}-collapse`}
  //         >
  //           <Collapse in={open} timeout="auto" unmountOnExit>
  //             <Box margin={1}>
  //               <TableContainer>
  //                 <SubCard content={false} sx={{ mb: 2, mt: 2 }}>
  //                   <Table>
  //                     <TableHead>
  //                       <TableRow hover>
  //                         <TableCell>
  //                           {t('teachers.academic_counseling.students_assigned.collapse.name')}
  //                         </TableCell>
  //                         <TableCell>
  //                           {t('teachers.academic_counseling.students_assigned.collapse.options')}
  //                         </TableCell>
  //                       </TableRow>
  //                     </TableHead>
  //                     <TableBody>
  //                       {p.courses.map((value) => (
  //                         <RenderRowCourses
  //                           key={`${value.id}-${p?.user?.user_id}-${value.mat_name}`}
  //                           course={value}
  //                           studentId={Number(p?.user?.user_id)}
  //                         />
  //                       ))}
  //                     </TableBody>
  //                   </Table>
  //                 </SubCard>
  //               </TableContainer>
  //             </Box>
  //           </Collapse>
  //         </TableCell>
  //       </TableRow>
  //     </>
  //   );
  // };

  const datafetch = data?.data?.map((item, i) => {
    return {
      arrow: '',
      id: i + ((page - 1) * perPage + 1),
      // viewCourses: t('teachers.academic_counseling.students_assigned.view_courses'),
      student: item?.user?.person?.pers_full_lastname,
      // faculty: item.faculty,
      study: { faculty: item.faculty, career: item.career },
      subjects: item.courseTotal,
      lastContact: obtainRelativeTime(item?.lastActivity),
      // lastPeriod:item.lastPeriod,
      options: item,
    };
  });

  return (
    <>
      {/* Button notify all students */}

      <SearchPaginatedTable
        data={datafetch}
        headers={{
          arrow: '',
          id: 'N°',
          // viewCourses: t('teachers.academic_counseling.students_assigned.view_courses'),
          student: t('teachers.academic_counseling.students_assigned.student'),
          study: 'Carrera',
          subjects: t('teachers.academic_counseling.students_assigned.subjects'),
          lastContact: t('teachers.academic_counseling.students_assigned.last_contact'),
          // lastPeriod:t('teachers.academic_counseling.students_assigned.last_contact'),
          options: (
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography component="span">
                {t('teachers.academic_counseling.students_assigned.options')}
              </Typography>
              <CustomPopper
                iconButton={<IconDotsVertical size={20} />}
                titleButton="Opción de anuncios"
              >
                <MenuList id="split-button-menu" autoFocusItem>
                  <MenuItem key={'details'} onClick={handleStudentNotifyAll}>
                    {t('teachers.academic_counseling.students_assigned.notify_all')}
                  </MenuItem>

                  <MenuItem key={'group'} onClick={handleUserAnnouncements}>
                    {t('teachers.academic_counseling.students_assigned.sent_announces')}
                  </MenuItem>
                </MenuList>
              </CustomPopper>
            </Stack>
          ) as any,
        }}
        numHeader={6}
        keyExtractor={(item) => `${item.id}-${item.options.stud_code}`}
        searchPlacehoder={searchPlacehoder}
        isLoading={isLoading}
        isFetching={isFetching}
        error={error}
        perPage={perPage}
        setPerPage={setPerPage}
        total={total}
        page={page}
        setPage={setPage}
        search={search}
        setSearch={setSearch}
        customRenderers={{
          student: ({ options: row }) => (
            <Grid container spacing={2}>
              <Grid item xs zeroMinWidth>
                <Typography align="left" component="div" variant="subtitle1">
                  {`${row?.user?.person?.pers_first_lastname || ''} ${
                    row?.user?.person?.pers_second_lastname || ''
                  } ${row?.user?.person?.pers_first_name || ''} ${
                    row?.user?.person?.pers_secondname || ''
                  }`}
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs zeroMinWidth>
                    <Typography align="left" component="div" variant="subtitle2">
                      {t('teachers.academic_counseling.students_assigned.student.stud_code')} :{' '}
                      {row?.stud_code}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs zeroMinWidth>
                    <Typography align="left" component="div" variant="subtitle2">
                      {row?.user?.email}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ),
          study: ({ study }) => (
            <Stack spacing={1}>
              <Grid item>
                <Typography align="left" component="div" variant="subtitle1">
                  {study.faculty}
                </Typography>
              </Grid>
              <Grid item>
                <Typography align="left" component="div" variant="subtitle2">
                  {study.career}
                </Typography>
              </Grid>
            </Stack>
          ),
          subjects: ({ options: row }) => (
            <Stack spacing={1}>
              <Grid item>
                <Typography align="left" component="div" variant="subtitle1">
                  {t('teachers.academic_counseling.students_assigned.subject_registered')}:{' '}
                  {row?.courses?.length}
                </Typography>
              </Grid>
              <Grid item>
                <Typography align="left" component="div" variant="subtitle1">
                  {t('teachers.academic_counseling.students_assigned.subject_curriculum')}:{' '}
                  {row?.cantSubjects}
                </Typography>
              </Grid>
              <Grid item>
                <Typography align="left" component="div" variant="subtitle1">
                  {t('teachers.academic_counseling.students_approved.subject_curriculum')}:{' '}
                  {row?.courseApproveds}
                </Typography>
              </Grid>
            </Stack>
          ),
          options: ({ options: row }) => (
            <>
              <Tooltip
                onClick={() => handleStudentSendMessage(Number(row?.user?.user_id))}
                title={t('teachers.academic_counseling.students_assigned.send_announce')}
              >
                <IconButton
                  aria-label={t('teachers.academic_counseling.students_assigned.send_announce')}
                  color="primary"
                >
                  <IconMessage size={20} />
                </IconButton>
              </Tooltip>
              <Tooltip
                onClick={() => handleStudentHistoryAds(Number(row?.user?.user_id))}
                title={t('teachers.academic_counseling.students_assigned.history')}
              >
                <IconButton
                  aria-label={t('teachers.academic_counseling.students_assigned.history')}
                  color="primary"
                >
                  <IconHistory size={20} />
                </IconButton>
              </Tooltip>
              <Tooltip
                onClick={() => handleStudentAcademicHistory(Number(row?.student_id))}
                title={t('teachers.academic_counseling.students_assigned.academic_history')}
              >
                <IconButton
                  aria-label={t('teachers.academic_counseling.students_assigned.academic_history')}
                  color="primary"
                >
                  <IconList size={20} />
                </IconButton>
              </Tooltip>
            </>
          ),
        }}
        customDataCellsProperties={{ id: { align: 'center' } }}
        customHeadersCellsProperties={{ id: { align: 'center' } }}
        // collapsible={{
        //   collapsibleBtnColl: 0,
        //   Rows: (index, row, props) => (
        //     <RenderCollapseRow key={`${index + Number(data.from)}-coll`} p={row} index={index} />
        //   ),
        // }}
        additionalFilters={
          <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
            <Grid item container spacing={2} xs={12}>
              <Grid item container spacing={2} xs={12} sm={6}>
                {/* Academic level */}
                <Grid item xs={6}>
                  {isLoadingOffers || isFetchingOffers ? (
                    <>
                      <TextField
                        disabled
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <CircularProgress size={25} />
                            </InputAdornment>
                          ),
                        }}
                        placeholder={t(
                          'teachers.academic_counseling.students_assigned.academic_level.loading'
                        )}
                      />
                    </>
                  ) : (
                    <Autocomplete
                      options={dataOffers || []}
                      loading={isLoadingOffers}
                      loadingText={t(
                        'teachers.academic_counseling.students_assigned.academic_level.loading'
                      )}
                      noOptionsText={t(
                        'teachers.academic_counseling.students_assigned.academic_level.no_data'
                      )}
                      value={
                        (dataOffers && dataOffers?.find((e) => e.id === educationLevelId)) || null
                      }
                      getOptionLabel={(option) => option.off_name}
                      onChange={(_, value) => {
                        setEducationLevelId(value?.id || 0);
                        setPeriodId(0);
                        setAcademic(0);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t('teachers.academic_counseling.students_assigned.academic_level')}
                          placeholder={t(
                            'teachers.academic_counseling.students_assigned.academic_level.placeholder'
                          )}
                        />
                      )}
                      fullWidth
                    />
                  )}
                </Grid>

                {/* Period filter */}
                <Grid item xs={6}>
                  {isLoadingSubjects || isFetchingSubjects ? (
                    <>
                      <TextField
                        disabled
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <CircularProgress size={25} />
                            </InputAdornment>
                          ),
                        }}
                        placeholder={t(
                          'teachers.academic_counseling.students_assigned.academic_unit.loading'
                        )}
                      />
                    </>
                  ) : (
                    <Autocomplete
                      options={dataSubjects! || []}
                      loading={isLoadingSubjects}
                      loadingText={t(
                        'teachers.academic_counseling.students_assigned.academic_unit.loading'
                      )}
                      noOptionsText={t(
                        'teachers.academic_counseling.students_assigned.academic_unit.no_data'
                      )}
                      value={(dataSubjects && dataSubjects?.find((e) => e.id === academic)) || null}
                      getOptionLabel={(option) => option.edu_name}
                      onChange={(_, value) => setAcademic(value?.id || 0)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t('teachers.academic_counseling.students_assigned.academic_unit')}
                          placeholder={t(
                            'teachers.academic_counseling.students_assigned.academic_unit.placeholder'
                          )}
                        />
                      )}
                      fullWidth
                    />
                  )}
                </Grid>
              </Grid>

              {/* Academic */}
              <Grid item container spacing={2} xs={12} sm={3}>
                <Grid item xs={12}>
                  {isLoadingPeriods || isFetchingPeriods ? (
                    <>
                      <TextField
                        disabled
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <CircularProgress size={25} />
                            </InputAdornment>
                          ),
                        }}
                        placeholder={t(
                          'teachers.academic_counseling.students_assigned.period.loading'
                        )}
                      />
                    </>
                  ) : (
                    <Autocomplete
                      options={PeriodsList?.data! || []}
                      loading={isLoadingPeriods}
                      loadingText={t(
                        'teachers.academic_counseling.students_assigned.period.loading'
                      )}
                      noOptionsText={t(
                        'teachers.academic_counseling.students_assigned.period.no_data'
                      )}
                      value={
                        (PeriodsList && PeriodsList?.data.find((e) => e.id === periodId)) || null
                      }
                      getOptionLabel={(option) => option.per_name}
                      onChange={(_, value) => setPeriodId(value?.id || 0)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t('teachers.academic_counseling.students_assigned.period')}
                          placeholder={t(
                            'teachers.academic_counseling.students_assigned.period.placeholder'
                          )}
                        />
                      )}
                      fullWidth
                    />
                  )}
                </Grid>
              </Grid>

              {/* Action button filter */}
              <Grid
                item
                container
                spacing={1}
                direction="row"
                justifyContent="center"
                alignItems="center"
                xs={12}
                sm={3}
              >
                <Grid item xs={4} md={4}>
                  <Button
                    onClick={() => {
                      setPeriodId(0);
                      setEducationLevelId(0);
                      setAcademic(0);
                      setDataSearch(dataSearchBlank);
                    }}
                    fullWidth
                    variant="outlined"
                  >
                    {t('teachers.academic_counseling.students_assigned.delete_filters')}
                  </Button>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Button
                    fullWidth
                    onClick={() => {
                      setDataSearch(dataSearchStudent);
                    }}
                    variant="contained"
                  >
                    {t('teachers.academic_counseling.students_assigned.filter')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
      />
    </>
  );
};

export default StudentAsignedCouselingTable;
