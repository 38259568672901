// import { useState } from 'react';
import { Grid, Button } from '@material-ui/core';
import { IconArrowNarrowLeft, IconBooks, IconUserPlus } from '@tabler/icons';
import { useHistory, useParams } from 'react-router-dom';
import { useTheme, Typography } from '@material-ui/core';

import MainCard from '../../../../../shared/components/cards/MainCard';
import WizardProject from './wizard-project';
import AddCollaboratorProjectForm from './AddCollaboratorProjectForm';
import AddStudentProjectForm from './AddStudentProjectForm';
import { useAppSelector } from '../../../../../../hooks/useRedux';
import { typeProjectModules } from '../../../constants/lists/typeProject';

// const useStyles = makeStyles((theme) => ({
//   stepStyle: {
//     '& >button>span>span>span>span.css-1kzwirt-MuiTypography-root-MuiStepLabel-label.Mui-active': {
//       color: theme.palette.primary.main,
//       fontWeight: '800',
//     },
//   },
//   test: {
//     color: 'GrayText',
//   },
// }));

const createStage = {
  label: 'Crear proyecto',
  Icon: IconBooks,
  url: 'crear-etapa',
};

const createProject = {
  label: 'Crear proyecto',
  Icon: IconBooks,
  url: 'crear-proyecto',
};

const assignTeacher = {
  label: 'Asignar Docentes',
  Icon: IconUserPlus,
  url: 'asignar-docente',
};
const assignStudents = {
  label: 'Asignar Estudiante',
  Icon: IconUserPlus,
  url: 'asignar-estudiante',
};

const steps = [createProject, assignTeacher, assignStudents];

const ProjectStepperScreen = () => {
  const history = useHistory();

  // const classes = useStyles();

  // const { path } = useRouteMatch();

  const { caseKey } = useParams<{ caseKey: string }>();

  const { projectId } = useParams<{ projectId?: string }>();

  const { currentModule } = useAppSelector((state) => state.access);

  const theme = useTheme();

  // const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

  // const activeStep = steps.findIndex((step) => step.url === caseKey) || 0;

  // const [completed, setCompleted] = useState<{
  //   [k: number]: boolean;
  // }>({});

  // const handleStep = (step: number) => () => {
  //   history.push(
  //     path
  //       .replace(':caseKey', steps[step].url || createProject.url)
  //       .replace(':projectId', projectId ? projectId : '')
  //   );
  //   // setActiveStep(step);
  // };

  // console.log(setCompleted);

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Button
          variant="text"
          onClick={() =>
            history.push(
              projectId
                ? `/administrar-lineas-de-investigacion/proyecto/${projectId}/etapas`
                : '/administrar-lineas-de-investigacion/proyectos'
            )
          }
          // onClick={() => history.goBack()}
          startIcon={<IconArrowNarrowLeft />}
        >
          Ir a la página principal
        </Button>
      </Grid>
      {/* <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={10}>
          <Stepper
            nonLinear
            activeStep={activeStep}
            orientation={matchUpMd ? 'horizontal' : 'vertical'}
          >
            {steps.map(({ label, Icon }, index) => (
              <Step key={label} completed={completed[index]} className={classes.test}>
                <StepButton
                  color="inherit"
                  onClick={handleStep(index)}
                  icon={
                    <Icon
                      style={
                        index === activeStep
                          ? {
                              backgroundColor: theme.palette.primary.light,
                              borderRadius: '100%',
                              padding: '5px',
                              color: theme.palette.primary.main,
                            }
                          : {}
                      }
                    />
                  }
                >
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </Grid>
      </Grid> */}
      <Grid container justifyContent="center" alignItems="center" mt={4}>
        <Grid item container m={2} justifyContent="center" alignItems="center">
          {steps.map(({ url, Icon }) => {
            if (url === caseKey) return <Icon size={40} color={theme.palette.primary.main} />;
            else return '';
          })}
        </Grid>

        <Grid item container justifyContent="center" alignItems="center">
          <Typography fontWeight={2} variant="h3" color="initial">
            {steps.map(({ url, label }) => {
              if (url === caseKey) return label;
              else return '';
            })}
          </Typography>
        </Grid>

        <Grid item xs={8} mt={4}>
          {(createProject.url === caseKey || createStage.url === caseKey) && (
            <WizardProject
              projectType={
                typeProjectModules[currentModule?.key as keyof typeof typeProjectModules]
              }
            />
          )}
          {assignTeacher.url === caseKey && <AddCollaboratorProjectForm />}
          {assignStudents.url === caseKey && <AddStudentProjectForm />}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProjectStepperScreen;
