import { useState, useEffect } from 'react';
import { useHistory, Link, useRouteMatch } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from '../../../../hooks/useRedux';
import {
  useGetEducationLevelsQuery,
  useGetAvaliblesEducationLevelsQuery,
  useGetPrincipalEducationLevelsQuery,
} from '../slices/educationLevelsApiSlice';

import StatusChip from '../../../shared/components/badges/StatusChip';
import EditButton from '../../../shared/components/tables/buttons/EditButton';
import DeleteButton from '../../../shared/components/tables/buttons/DeleteButton';
import AddButton from '../../../shared/components/tables/buttons/AddButton';

import { FilterArrow } from '../../../shared/components/tables/SearchPaginatedTable';

import {
  openDeleteModalWith,
  setcurrentEducationLevelId,
  setEducationLevelList,
  setOfferIdSelected,
  setPrincipalEducationLevelIdSelected,
  setStatusIdSelected,
  updateEducationLevelsTable,
} from '../slices/educationLevelsSlice';

import useSharedGuard from '../../../../hooks/useSharedGuard';
import { subPermissionKeys } from '../constants/permissions';
import SearchPaginatedTable from '../../../shared/components/tables/SearchPaginatedTable';
import { useGetAvailablesOffersQuery } from '../../academic-offerings/slices/academicOfferingsApiSlice';
import ListCollective from '../../../shared/components/tables/buttons/ListCollective';
import { useTableSearch } from '../../../../hooks/useTableSearch';
import {
  Autocomplete,
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';
import { useGetAcademicOfferingsAvailablesQuery } from '../../periods/slices/periodsApiSlice';
import { statusAvailables } from '../../../shared/constants/resourceStatus';
import { IconUserCircle } from '@tabler/icons';
import ContactDeanDialog from './ContactDeanDialog';
import { useControlModalStates } from '../../../shared/hooks/useControlModalStates';

const EducationLevelsTable = () => {
  const { currentProfile } = useAppSelector((state) => state.access);

  const dispatch = useAppDispatch();

  const { path } = useRouteMatch();
  const history = useHistory();

  const { hasAccess } = useSharedGuard(subPermissionKeys);

  const {
    open: openDean,
    dataId: modalEducationLevelId,
    handleCloseModal: handleCloseDeanModal,
    handleOpenModalWith: handleOpenDeanModalWith,
  } = useControlModalStates();

  const [eduLevelType, setEduLevelType] = useState('career');

  const { page, perPage, search } = useAppSelector(
    (state) => state.educationLevels.educationLevelsTable
  );

  const { offerIdSelected, principalEducationLevelIdSelected, statusIdSelected } = useAppSelector(
    (state) => state.educationLevels
  );

  const { setPage, setPerPage, setSearch } = useTableSearch({
    page,
    perPage,
    search,
    updateFunction: updateEducationLevelsTable,
    otherFilters: {
      offerIdSelected,
      principalEducationLevelIdSelected,
      statusIdSelected,
    },
  });

  const [filterArrow, setFilterArrow] = useState<FilterArrow>(null!);

  const { isLoading, error, data, isFetching } = useGetEducationLevelsQuery(
    {
      profileId: currentProfile?.profileId!,
      page,
      perPage,
      search,
      ...(filterArrow && {
        type_sort: filterArrow?.direction,
        sort: filterArrow?.name,
      }),
      ...(offerIdSelected && { offerId: offerIdSelected }),
      ...(principalEducationLevelIdSelected &&
        eduLevelType === 'career' && {
          principalEducationLevelId: principalEducationLevelIdSelected,
        }),
      ...(statusIdSelected && { statusId: statusIdSelected }),
      type: eduLevelType,
    },
    { refetchOnMountOrArgChange: 30 }
  );

  const { data: educationLevelsAvailables, isFetching: isFetchingEDLVL } =
    useGetAvaliblesEducationLevelsQuery({
      profileId: currentProfile?.profileId!,
    });

  useEffect(() => {
    if (data) {
      dispatch(setEducationLevelList(data?.data!));
    }
  }, [data, dispatch]);

  const handleDelete = async (periodId: number) => {
    dispatch(openDeleteModalWith(periodId));
  };

  const listAllCollectives = (educationLevelId: number) => {
    dispatch(setcurrentEducationLevelId(educationLevelId));
    history.push(`${path}/listar-colectivos/${educationLevelId}`);
  };

  const { data: academicOfferingsAvailables, isLoading: isLoadingAC } =
    useGetAcademicOfferingsAvailablesQuery({
      profileId: currentProfile?.profileId!,
      statusId: 1,
    });

  const { data: principalsEL, isLoading: isLoadingPrincipalsEL } =
    useGetPrincipalEducationLevelsQuery({
      profileId: currentProfile?.profileId!,
    });

  const { data: offersAvailables = [], isLoading: isLoadingOffers } = useGetAvailablesOffersQuery({
    profileId: currentProfile?.profileId!,
  });

  const goToAdd = () => {
    history.push(`${path}/agregar`);
  };

  const datafetch = data?.data!.map((d, index) => ({
    id: index + ((page - 1) * perPage + 1),
    name: d.edu_name,
    alias: d.edu_alias,
    principalId: d.principal_id,
    offerId: d.offer_id,
    statusId: d.status_id,
    options: d,
  }));

  const handleChangeEduLevelType = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEduLevelType((event.target as HTMLInputElement).value);
  };

  return (
    <>
      <Grid container spacing={2} mb={2}>
        {hasAccess('getEducationLevel') && (
          <Grid item xs={12} md={3}>
            {isLoadingAC && <TextField fullWidth label="Cargando..." disabled />}
            {!isLoadingAC && (
              <Autocomplete
                options={academicOfferingsAvailables || []}
                getOptionLabel={(option) => option.off_name || ''}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Seleccione un nivel educativo"
                    variant="outlined"
                    fullWidth
                  />
                )}
                onChange={(event, newValue) => {
                  dispatch(setOfferIdSelected(newValue?.id || null));
                }}
                value={academicOfferingsAvailables?.find((o) => o.id === offerIdSelected) || null}
              />
            )}
          </Grid>
        )}
        {eduLevelType === 'career' && hasAccess('getCareer') && (
          <Grid item xs={12} md={3}>
            {isLoadingPrincipalsEL && <TextField fullWidth label="Cargando..." disabled />}
            {!isLoadingPrincipalsEL && (
              <Autocomplete
                options={principalsEL || []}
                getOptionLabel={(option) => option.edu_name || ''}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Seleccione una unidad académica principal"
                    variant="outlined"
                    fullWidth
                  />
                )}
                onChange={(event, newValue) => {
                  dispatch(setPrincipalEducationLevelIdSelected(newValue?.id || null));
                }}
                value={
                  principalsEL?.find((o) => o.id === principalEducationLevelIdSelected) || null
                }
              />
            )}
          </Grid>
        )}
        <Grid item xs={12} md={3}>
          <Autocomplete
            options={statusAvailables || []}
            getOptionLabel={(option) => option.name || ''}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Seleccione un estado"
                variant="outlined"
                fullWidth
              />
            )}
            onChange={(event, newValue) => {
              dispatch(setStatusIdSelected(newValue?.id || null));
            }}
            value={statusAvailables?.find((o) => o.id === statusIdSelected) || null}
          />
        </Grid>
      </Grid>

      <SearchPaginatedTable
        data={datafetch!}
        headers={{
          id: 'N°',
          name: 'Nombre',
          alias: 'Alias',
          principalId: 'Principal',
          offerId: 'Nivel Educativo',
          statusId: 'Estado',
          options: 'Opciones',
        }}
        keyExtractor={(item) => item.id.toString()}
        searchPlacehoder="Buscar unidades académicas"
        listFilterArrows={{
          name: 'edu_name',
          alias: 'edu_alias',
          principalId: 'principal_id',
          offerId: 'offer_id',
          statusId: 'status_id',
        }}
        filterArrow={filterArrow}
        setFilterArrow={setFilterArrow}
        isLoading={isLoading}
        isFetching={isFetching}
        error={error}
        perPage={perPage}
        setPerPage={setPerPage}
        total={data?.total || 0}
        page={page}
        setPage={setPage}
        numHeader={7}
        search={search}
        setSearch={setSearch}
        customRenderers={{
          principalId: (row) =>
            isFetchingEDLVL ? (
              <h4>Cargando</h4>
            ) : (
              educationLevelsAvailables?.map(function (dt) {
                if (dt.id.toString() === row.principalId) {
                  return dt.edu_name;
                }
                return null;
              })
            ),
          offerId: (row) =>
            isLoadingOffers ? (
              <h4>Cargando</h4>
            ) : (
              offersAvailables.map(function (data) {
                if (data.id === Number(row.offerId)) {
                  return data.off_name;
                }
                return null;
              })
            ),
          statusId: (row) => <StatusChip statusId={Number(row.statusId)} />,
          options: (row) => (
            <>
              {hasAccess('edit') && (
                <Link to={`${path}/${row.options.id}/editar`}>
                  <EditButton />
                </Link>
              )}
              {hasAccess('delete') && (
                <DeleteButton handleDelete={() => handleDelete(row.options.id)} />
              )}
              {row.principalId === null && hasAccess('edit') && (
                <ListCollective onClick={() => listAllCollectives(row.options.id)} />
              )}
              {row.principalId === null && hasAccess('edit') && (
                <EditButton
                  title="Datos de contacto"
                  color="default"
                  Icon={IconUserCircle}
                  handleEdit={() => {
                    handleOpenDeanModalWith(row.options.id);
                  }}
                />
              )}
              {!hasAccess('edit') && !hasAccess('delete') && 'Ninguna'}
            </>
          ),
        }}
        customHeadersCellsProperties={{
          id: { align: 'center' },
          name: { align: 'center' },
          alias: { align: 'center' },
          principalId: { align: 'center' },
          offerId: { align: 'center' },
          statusId: { align: 'center' },
          options: { align: 'center' },
        }}
        customDataCellsProperties={{
          id: { align: 'center' },
          name: { align: 'center' },
          alias: { align: 'center' },
          principalId: { align: 'center' },
          offerId: { align: 'center' },
          statusId: { align: 'center' },
          options: { align: 'center' },
        }}
        ActionButtons={
          <Grid container justifyContent="flex-end" spacing={1}>
            <RadioGroup value={eduLevelType} onChange={handleChangeEduLevelType} row>
              <FormControlLabel value="career" control={<Radio />} label="Carreras" />
              <FormControlLabel value="faculty" control={<Radio />} label="Unidades académicas" />
            </RadioGroup>
            <Divider
              orientation="vertical"
              flexItem
              sx={{
                mr: 1,
              }}
            />
            {hasAccess('create') && <AddButton size="large" onClick={goToAdd} />}
          </Grid>
        }
      />

      <ContactDeanDialog
        handleCloseDialog={handleCloseDeanModal}
        open={openDean}
        educationLevelId={modalEducationLevelId}
      />
    </>
  );
};

export default EducationLevelsTable;
