import { mainApi } from '../../../../api/mainApi';
import { AccessState } from '../../../../types';
import { middlewareApi } from '../../../middleware/middlewareApi';
import {
  AddUserCashierPayload,
  CashierUser,
} from '../interfaces/financialConfiguration.interfaces';
import { PostAddUserCashier } from '../interfaces/financialConfiguration.interfaces';
import { AvailableCashierUser } from '../interfaces/financialConfiguration.interfaces';
import {
  EmissionPointResponse,
  EmissionPointPayload,
  EmissionPoint,
} from '../interfaces/financialConfiguration.interfaces';

export const financialConfigurationTags = middlewareApi.enhanceEndpoints({
  addTagTypes: ['EmisionPoints', 'CashierUsersAvailables'],
});
export const financialConfigurationApi = financialConfigurationTags.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * @GET all emision point
     */
    getEmisionPoints: builder.query<
      EmissionPointResponse,
      {
        profileId: number;
        page: number;
        perPage: number;
        search: string;
        sort?: string;
        type_sort?: string;
      }
    >({
      queryFn: async ({ profileId, page, search, perPage, sort, type_sort }) => {
        try {
          const { data } = await mainApi.get<EmissionPointResponse>('/financial-configurations', {
            params: {
              user_profile_id: profileId,
              page,
              size: perPage,
              ...(sort && { sort }),
              type_sort: type_sort || 'desc',
              search,
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'EmisionPoints', id } as const)),
              { type: 'EmisionPoints', id: 'LIST' },
            ]
          : [{ type: 'EmisionPoints', id: 'LIST' }],
    }),
    /**
     * @GET emision point by id
     */
    getEmisionPoint: builder.query<EmissionPoint, { profileId?: number; emissionPointId: number }>({
      queryFn: async ({ profileId, emissionPointId }, { dispatch, getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = profileId || currentProfile?.profileId;
          const { data } = await mainApi.get<EmissionPoint>(
            `/financial-configurations/${emissionPointId}`,
            {
              params: {
                user_profile_id,
              },
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result, error, { profileId }) => [{ type: 'EmisionPoints', profileId }],
    }),
    /**
     * @POST add emission point
     */
    addEmissionPoint: builder.mutation<
      EmissionPointPayload,
      { profileId: number; emissionPointPayload: Partial<EmissionPointPayload> }
    >({
      queryFn: async ({ profileId, emissionPointPayload }) => {
        try {
          const { data } = await mainApi.post('/financial-configurations', {
            user_profile_id: profileId,
            ...emissionPointPayload,
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'EmisionPoints', id: 'LIST' }],
    }),

    /**
     * @PUT update emission point
     */
    updateEmissionPoint: builder.mutation<
      EmissionPointPayload,
      {
        profileId: number;
        emissionPointId: number;
        emissionPointPayload: Partial<EmissionPointPayload>;
      }
    >({
      queryFn: async ({ profileId, emissionPointId, emissionPointPayload }) => {
        try {
          const { data } = await mainApi.put(`/financial-configurations/${emissionPointId}`, {
            user_profile_id: profileId,
            ...emissionPointPayload,
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'EmisionPoints', id: 'LIST' }],
    }),

    /**
     * @DELETE remove emission point
     */
    deleteEmissionPoint: builder.mutation<
      EmissionPoint,
      { profileId: number; emissionPointId: number }
    >({
      queryFn: async ({ profileId, emissionPointId }) => {
        try {
          const { data } = await mainApi.delete(`/financial-configurations/${emissionPointId}`, {
            data: {
              user_profile_id: profileId,
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'EmisionPoints', id: 'LIST' }],
    }),

    /**
     * @GET Get available user for cashiers
     */
    getUsersAvailableCashiers: builder.query<AvailableCashierUser[], { profileId: number }>({
      queryFn: async ({ profileId }) => {
        try {
          const { data } = await mainApi.get<AvailableCashierUser[]>(`/cash-point/cashiers/users`, {
            params: {
              user_profile_id: profileId,
              availables_users: 1,
              data: 'all',
            },
          });

          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result, error, { profileId }) => [
        { type: 'CashierUsersAvailables', id: 'LIST' },
      ],
    }),

    /**
     * @GET Get available user for cashiers
     */
    getUsersCashiers: builder.query<CashierUser[], { profileId?: number }>({
      queryFn: async ({ profileId }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = profileId || currentProfile?.profileId;

          const { data } = await mainApi.get<CashierUser[]>(`/cash-point/list-users`, {
            params: {
              user_profile_id,
              // data: 'all',
            },
          });

          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result, error, { profileId }) => [
        { type: 'CashierUsersAvailables', id: 'LIST' },
      ],
    }),

    /**
     * @POST add user to emission point
     */
    addUserCashier: builder.mutation<
      PostAddUserCashier,
      { profileId: number; addUserCashierPayload: Partial<AddUserCashierPayload> }
    >({
      queryFn: async ({ profileId, addUserCashierPayload }) => {
        try {
          const { data } = await mainApi.post('/cash-point/cashiers/assign-user', {
            user_profile_id: profileId,
            ...addUserCashierPayload,
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [
        { type: 'CashierUsersAvailables', id: 'LIST' },
        { type: 'EmisionPoints', id: 'LIST' },
      ],
    }),

    /**
     * @POST unlink user to emission point
     */
    unlinkUserCashier: builder.mutation<any, { profileId: number; userId: number }>({
      queryFn: async ({ profileId, userId }) => {
        try {
          const { data } = await mainApi.post('/cash-point/cashiers/unlink-user', {
            user_profile_id: profileId,
            cashier_id: userId,
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [
        { type: 'CashierUsersAvailables', id: 'LIST' },
        { type: 'EmisionPoints', id: 'LIST' },
      ],
    }),

    //
  }),
});
export const {
  useGetEmisionPointsQuery,
  useAddEmissionPointMutation,
  useUpdateEmissionPointMutation,
  useDeleteEmissionPointMutation,
  useLazyGetEmisionPointQuery,

  useGetUsersAvailableCashiersQuery,
  useAddUserCashierMutation,
  useUnlinkUserCashierMutation,
  useGetUsersCashiersQuery,
} = financialConfigurationApi;
