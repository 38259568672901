import {
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
} from '@material-ui/core';
import { DatePicker, LocalizationProvider } from '@material-ui/lab';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import esLocale from 'date-fns/locale/es';
import format from 'date-fns/format';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useAppSelector } from '../../../../hooks/useRedux';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import { paymentAgreementSchema } from '../constants/formConstants';
import { PaymentAgreementSchema } from '../constants/validationSchemas';
import { PaymentAgreementPayload } from '../interfaces/paymentAgreement.interface';
import { useAddPaymentAggrementRequestMutation } from '../slices/academicRequestsApiSlice';
import useGuard from '../hooks/useGuard';

import { useTranslation } from 'react-i18next';
import PeriodStudentSelect from './Builder/PeriodStudentSelect';

const PaymentAgreementForm = () => {
  const { t } = useTranslation();

  const [studentPeriod, setStudentPeriod] = useState(0);

  const { hasAccess } = useGuard();
  const { currentProfile, currentRecordStudent } = useAppSelector((state) => state.access);
  const { userInfo } = useAppSelector((state) => state.auth);
  const [showAlerts, setShowAlerts] = useState(true);
  const [addPaymentAgreement, { isLoading: isLoadingPA, isError, error, isSuccess }] =
    useAddPaymentAggrementRequestMutation();
  const { handleSubmit, errors, touched, setFieldValue, getFieldProps, values } =
    useFormik<PaymentAgreementPayload>({
      initialValues: paymentAgreementSchema,
      validationSchema: PaymentAgreementSchema,
      onSubmit: async (values) => {
        await addPaymentAgreement({
          profileId: currentProfile?.profileId!,
          userId: String(userInfo?.id!),
          studentRecordId: currentRecordStudent?.id!,
          paymentAgreementPayload: {
            app_description: values.app_description,
            agreement_value: values.agreement_value,
            payment_date: format(values.payment_date as Date, 'yyyy-MM-dd') as string,
            period_id: studentPeriod,
          },
        });
        setShowAlerts(true);
      },
    });

  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction="column" gap={2}>
        <Grid item>
          {isError && showAlerts && (
            <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
          )}
          {isSuccess && showAlerts && (
            <SuccessAlert
              message={t('students.applications.success_message')}
              handleDismiss={() => setShowAlerts(false)}
            />
          )}
        </Grid>
        <Grid item xs={12} container spacing={4} alignContent="flex-start">
          <Grid item xs={12}>
            <PeriodStudentSelect changeHandler={setStudentPeriod} />
          </Grid>

          <Grid item xs={12} md={6}>
            <InputLabel>{t('students.applications.payment_agreement.payment_amount')}</InputLabel>
            <TextField
              fullWidth
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
              error={Boolean(errors.agreement_value && touched.agreement_value)}
              placeholder={t('students.applications.payment_agreement.payment_amount')}
              {...getFieldProps('agreement_value')}
            />
            {errors.agreement_value && touched.agreement_value && (
              <FormHelperText error>{errors.agreement_value}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <InputLabel>{t('students.applications.payment_agreement.pay_date')}</InputLabel>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
              <DatePicker
                value={values.payment_date}
                onChange={(newValue) => {
                  setFieldValue('payment_date', newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    helperText=""
                    placeholder={t('students.applications.payment_agreement.pay_date')}
                    error={Boolean(errors.payment_date && touched.payment_date)}
                    fullWidth
                  />
                )}
                minDate={new Date()}
              />
            </LocalizationProvider>
            {errors.payment_date && touched.payment_date && (
              <FormHelperText error>{errors.payment_date}</FormHelperText>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <InputLabel>{t('students.applications.payment_agreement.reason')}</InputLabel>
          <TextField
            multiline
            rows={2}
            fullWidth
            error={Boolean(errors.app_description && touched.app_description)}
            placeholder={t('students.applications.payment_agreement.reason_placeholder')}
            {...getFieldProps('app_description')}
          />
          {errors.app_description && touched.app_description && (
            <FormHelperText error>{errors.app_description}</FormHelperText>
          )}
        </Grid>
        <Grid
          item
          container
          alignItems="center"
          justifyContent="flex-end"
          spacing={2}
          sx={{ mt: 1 }}
        >
          {hasAccess('save_payment_agreement') && (
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                size="large"
                color="primary"
                disabled={isLoadingPA || !studentPeriod}
                endIcon={isLoadingPA && <CircularProgress size={20} />}
              >
                {t('students.applications.save')}
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </form>
  );
};
export default PaymentAgreementForm;
