import { FC, useEffect, useState } from 'react';

import {
  Autocomplete,
  Button,
  CircularProgress,
  FormControlLabel,
  FormHelperText,
  Grid,
  Switch,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useFormik } from 'formik';

import ErrorAlert from '../../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../../shared/components/alerts/SuccessAlert';
import InputLabel from '../../../../shared/components/forms/InputLabel';
import { useAppSelector } from '../../../../../hooks/useRedux';
import {
  useAddInstituteMutation,
  useGetAvailablesEconomicGroupQuery,
  useGetAvailablesProvincesQuery,
  useGetAvailablesTypeInstituteQuery,
} from '../../../institutes/slices/institutesApiSlice';
import { AddInstituteInfoSchema } from '../../constants/validationSchemas';
import { statusAvailables } from '../../../../shared/constants/resourceStatus';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  inputLabel: {
    marginBlockEnd: 6,
    fontWeight: 500,
    color: theme.palette.text.primary,
  },
}));

interface Props {
  onCancel: () => void;
}

const AddInstituteForm: FC<Props> = (props) => {
  const { t } = useTranslation();

  const classes = useStyles();
  const { onCancel } = props;

  const { currentProfile } = useAppSelector((state) => state.access);

  const [showAlerts, setShowAlerts] = useState(true);

  const [autocompleteKey, setAutocompleteKey] = useState<{
    instType: number;
    province: number;
    economicGroup: number;
    agreement: number;
    status: number;
  }>({ instType: 0, province: 0, economicGroup: 0, agreement: 0, status: 0 });

  const [addInstitute, { isLoading, isError, error, isSuccess }] = useAddInstituteMutation();

  const { data: typeInstituteAvailables = [], isLoading: isLoadingTI } =
    useGetAvailablesTypeInstituteQuery({
      profileId: currentProfile?.profileId!,
    });

  const { data: provincesAvailables = [], isLoading: isLoadingP } = useGetAvailablesProvincesQuery({
    profileId: currentProfile?.profileId!,
  });

  const { data: economicGroupAvailables = [], isLoading: isLoadingEG } =
    useGetAvailablesEconomicGroupQuery({
      profileId: currentProfile?.profileId!,
    });

  const { getFieldProps, errors, touched, handleSubmit, setFieldValue, resetForm, values } =
    useFormik({
      initialValues: {
        name: '',
        province_id: -1,
        economic_group_id: -1,
        type_institute_id: -1,
        has_agreement: false,
        status_id: 1,
      },
      validationSchema: AddInstituteInfoSchema,
      onSubmit: async (values) => {
        await addInstitute({
          profileId: currentProfile?.profileId!,
          institutePayload: {
            inst_name: values.name,
            province_id: values.province_id.toString(),
            economic_group_id: values.economic_group_id.toString(),
            type_institute_id: values.type_institute_id.toString(),
            has_agreement: values.has_agreement,
            status_id: values.status_id.toString(),
          },
        });

        setShowAlerts(true);
      },
    });

  useEffect(() => {
    if (isSuccess) {
      setAutocompleteKey({
        instType: Math.random(),
        province: Math.random(),
        economicGroup: Math.random(),
        agreement: Math.random(),
        status: Math.random(),
      });
      resetForm();
    }
  }, [isSuccess, resetForm]);

  return (
    <Grid container direction="column" gap={2}>
      <form onSubmit={handleSubmit}>
        <Grid item>
          {isError && showAlerts && (
            <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
          )}

          {isSuccess && showAlerts && (
            <SuccessAlert
              message={t('academic.homologation.external.side.add_institute.success')}
              handleDismiss={() => setShowAlerts(false)}
            />
          )}
        </Grid>

        <Grid item container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>
              {t('academic.homologation.external.side.add_institute.name')}
            </InputLabel>
            <TextField
              fullWidth
              error={Boolean(errors.name && touched.name)}
              placeholder={t('academic.homologation.external.side.add_institute.name.placeholder')}
              {...getFieldProps('name')}
            />
            {errors.name && touched.name && <FormHelperText error>{t(errors.name)}</FormHelperText>}
          </Grid>

          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>
              {t('academic.homologation.external.side.add_institute.type')}
            </InputLabel>
            <Autocomplete
              key={autocompleteKey.instType}
              options={typeInstituteAvailables}
              loading={isLoadingTI}
              loadingText={t('academic.homologation.external.side.add_institute.type.loading')}
              noOptionsText={t('academic.homologation.external.side.add_institute.type.no_data')}
              getOptionLabel={(option) => option.tin_name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(errors.type_institute_id && touched.type_institute_id)}
                  placeholder={t(
                    'academic.homologation.external.side.add_institute.type.placeholder'
                  )}
                />
              )}
              onChange={(_, value) => setFieldValue('type_institute_id', value?.id || '')}
              fullWidth
              clearIcon={null}
            />
            {errors.type_institute_id && touched.type_institute_id && (
              <FormHelperText error>{t(errors.type_institute_id)}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>
              {t('academic.homologation.external.side.add_institute.province')}
            </InputLabel>
            <Autocomplete
              key={autocompleteKey.province}
              options={provincesAvailables}
              loading={isLoadingP}
              loadingText={t('academic.homologation.external.side.add_institute.province.loading')}
              noOptionsText={t(
                'academic.homologation.external.side.add_institute.province.no_data'
              )}
              getOptionLabel={(option) => option.cat_name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(errors.province_id && touched.province_id)}
                  placeholder={t(
                    'academic.homologation.external.side.add_institute.province.placeholder'
                  )}
                />
              )}
              onChange={(_, value) => setFieldValue('province_id', value?.id || '')}
              fullWidth
              clearIcon={null}
            />
            {errors.province_id && touched.province_id && (
              <FormHelperText error>{t(errors.province_id)}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>
              {t('academic.homologation.external.side.add_institute.economic_group')}
            </InputLabel>
            <Autocomplete
              key={autocompleteKey.economicGroup}
              options={economicGroupAvailables}
              loading={isLoadingEG}
              loadingText={t(
                'academic.homologation.external.side.add_institute.economic_group.loading'
              )}
              noOptionsText={t(
                'academic.homologation.external.side.add_institute.economic_group.no_data'
              )}
              getOptionLabel={(option) => option.eco_gro_name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(errors.economic_group_id && touched.economic_group_id)}
                  placeholder={t(
                    'academic.homologation.external.side.add_institute.economic_group.placeholder'
                  )}
                />
              )}
              value={
                economicGroupAvailables.find((dt) => dt.id === values.economic_group_id) || null
              }
              onChange={(_, value) => setFieldValue('economic_group_id', value?.id || '')}
              fullWidth
              clearIcon={null}
            />
            {errors.economic_group_id && touched.economic_group_id && (
              <FormHelperText error>{t(errors.economic_group_id)}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>
              {t('academic.homologation.external.side.add_institute.status')}
            </InputLabel>
            <Autocomplete
              key={autocompleteKey.status}
              options={statusAvailables}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(errors.status_id && touched.status_id)}
                  placeholder={t(
                    'academic.homologation.external.side.add_institute.status.placeholder'
                  )}
                />
              )}
              defaultValue={statusAvailables[0]}
              onChange={(e, value) => setFieldValue('status_id', value?.id || '')}
              fullWidth
              clearIcon={null}
            />
            {errors.status_id && touched.status_id && (
              <FormHelperText error>{t(errors.status_id)}</FormHelperText>
            )}
          </Grid>

          <Grid item container xs={12} justifyContent="flex-start">
            <FormControlLabel
              key={autocompleteKey.agreement}
              control={<Switch {...getFieldProps('has_agreement')} />}
              label={t('academic.homologation.external.side.add_institute.agreement')}
              // labelPlacement="start"
            />
            {errors.has_agreement && touched.has_agreement && (
              <FormHelperText error>{t(errors.has_agreement)}</FormHelperText>
            )}
          </Grid>
        </Grid>

        <Grid
          item
          container
          alignItems="center"
          justifyContent="flex-end"
          spacing={2}
          sx={{ mt: 1 }}
        >
          <Grid item>
            <Button variant="outlined" size="large" color="primary" onClick={onCancel}>
              {t('academic.homologation.external.side.add_institute.cancel')}
            </Button>
          </Grid>

          <Grid item>
            <Button
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              disabled={isLoading}
              endIcon={isLoading && <CircularProgress size={20} />}
            >
              {t('academic.homologation.external.side.add_institute.add')}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

export default AddInstituteForm;
