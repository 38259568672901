import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import DeleteAlert from '../../../../shared/components/dialogs/DeleteAlert';
import { resetPeriodSTageIdToDelete, setDeleteModalOpen } from '../../slices/periodsSlice';
import { usePeriodStageDeleteMutation } from '../../slices/periodsApiSlice';

const PeriodStagesDelete = function () {
  const dispatch = useAppDispatch();

  const { deleteModalOpen, periodStageIdToDelete } = useAppSelector((state) => state.periods);

  const { currentProfile } = useAppSelector((state) => state.access);

  const [deletePeriodStage, { isLoading, isSuccess, isError, error }] =
    usePeriodStageDeleteMutation();

  const handleDelete = () => {
    deletePeriodStage({
      periodStage: periodStageIdToDelete!,
      user_profile_id: currentProfile?.profileId!,
    });
  };

  const handleClose = () => {
    dispatch(setDeleteModalOpen(false));
    dispatch(resetPeriodSTageIdToDelete());
  };

  return (
    <DeleteAlert
      open={deleteModalOpen}
      onClose={handleClose}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      handleDelete={handleDelete}
      error={error as string}
    />
  );
};

export default PeriodStagesDelete;
