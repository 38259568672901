import { FC, useEffect, useMemo, useState } from 'react';

import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Typography,
  useTheme,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { IconCreditCard, IconBuildingBank, IconArrowsRightLeft } from '@tabler/icons';

import { methodTypes } from '../../interfaces/types.interface';
import paycards from '../../../../../assets/images/illustrations/paycards.png';
import { useAppSelector } from '../../../../../hooks/useRedux';

const useStyles = makeStyles((theme) => ({
  btn: {
    boxShadow: '0px 0px 20px #CBD9E8',
    padding: '16px;',
    width: '100%',
    height: '100%',
  },
  img: {
    width: '100%',
    // height: '100%',
    maxWidth: '300px',
  },
}));

interface Props {
  selectedMethod: methodTypes;
  totalAmount?: number;
  onSelectMethod: (method: methodTypes) => void;
  setValidStep: (param: boolean) => void;
}

const PayMethods: FC<Props> = (props) => {
  const { selectedMethod, onSelectMethod, setValidStep } = props;
  const classes = useStyles();
  const theme = useTheme();

  const { selectedDebts } = useAppSelector((state) => state.payments);

  const [checkedTerms, setCheckedTerms] = useState(false);
  const handleChangeChkTerms = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedTerms(event.target.checked);
  };

  const totalAmount = useMemo(() => {
    let amount = 0;

    selectedDebts.forEach((el) => {
      amount += +el?.apply_early_payment!
        ? Number(el.net_value_early_payment)
        : Number(el.fees_balance);
    });

    return amount;
  }, [selectedDebts]);

  useEffect(() => {
    if (selectedMethod && checkedTerms) {
      setValidStep(true);
    } else {
      setValidStep(false);
    }
  }, [selectedMethod, setValidStep, checkedTerms]);

  return (
    <Grid container minHeight="40vh" alignItems="center">
      <Grid container justifyContent={'space-between'} alignItems={'center'}>
        <Grid item>
          <Typography variant="h4">Valor Total Seleccionado a pagar</Typography>
          <Typography variant="body2">
            {selectedDebts.length}
            {` Transacci${selectedDebts.length > 1 ? 'ones' : 'ón'} seleccionada${
              selectedDebts.length > 1 ? 's' : ''
            }`}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h3" component="span" color={theme.palette.primary.main}>
            ${totalAmount}
          </Typography>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid container justifyContent="space-around" alignItems="stretch" spacing={5}>
        <Grid item xs={12}>
          <Typography variant="h3" align="center">
            Seleccione su método de pago
          </Typography>
        </Grid>

        <Grid item sm={12} md={4}>
          <Button
            variant={selectedMethod === 'credito' ? 'contained' : 'text'}
            size="large"
            className={classes.btn}
            onClick={() => onSelectMethod('credito')}
          >
            <Grid container justifyContent={'center'}>
              <Grid item xs={12}>
                <IconCreditCard size={30} />
              </Grid>
              <Grid item xs={12}>
                Tarjeta de Crédito o Débito
              </Grid>
              <Grid item sm={4} md={12} mt={1}>
                <img src={paycards} className={classes.img} alt="tarjetas aceptadas" />
              </Grid>
            </Grid>
          </Button>
        </Grid>

        <Grid item xs={12} md={4}>
          <Button
            variant={selectedMethod === 'deposito' ? 'contained' : 'text'}
            size="large"
            className={classes.btn}
            onClick={() => onSelectMethod('deposito')}
          >
            <Grid container justifyContent={'center'}>
              <Grid item xs={12}>
                <IconBuildingBank size={30} />
              </Grid>
              <Grid item xs={12}>
                Depósito Bancario
              </Grid>
            </Grid>
          </Button>
        </Grid>

        <Grid item xs={12} md={4}>
          <Button
            variant={selectedMethod === 'transferencia' ? 'contained' : 'text'}
            size="large"
            className={classes.btn}
            onClick={() => onSelectMethod('transferencia')}
          >
            <Grid container justifyContent={'center'}>
              <Grid item xs={12}>
                <IconArrowsRightLeft size={30} />
              </Grid>
              <Grid item xs={12}>
                Transferencia Online
              </Grid>
            </Grid>
          </Button>
        </Grid>

        <Grid container justifyContent="center" alignItems="center" mt={2}>
          <FormControl>
            <FormControlLabel
              control={<Checkbox checked={checkedTerms} onChange={handleChangeChkTerms} />}
              value={'network'}
              label={
                <Typography component="span">
                  Acepto{' '}
                  <Link
                    to="/informacion-institucional"
                    target={'_blank'}
                    style={{ textDecorationLine: 'none', color: theme.palette.primary.main }}
                  >
                    Términos y Condiciones
                  </Link>
                </Typography>
              }
            />
          </FormControl>
          <Grid item></Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PayMethods;
