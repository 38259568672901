import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../../../hooks/useRedux';
import MainCard from '../../../../../shared/components/cards/MainCard';
import IntegralEvaluationDeleteDialog from '../components/IntegralEvaluationDeleteDialog';
import IntegralEvaluationSideDialog from '../components/IntegralEvaluationSideDialog';
import IntegralEvaluationsTable from '../components/IntegralEvaluationTable';
import RubricDeleteDialog from '../components/RubricDeleteDialog';

const IntegralEvaluationsScreen = () => {
  const { t } = useTranslation();

  const {
    integralEvaluationsSideDialogOpen,
    integralEvaluationsDeleteModalOpen,
    isOpenDeleteDialog,
  } = useAppSelector((state) => state.integralEvaluation);

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">{t('maintenance.integral_evaluation.title')}</Typography>
        <Typography variant="body1" color="textSecondary">
          {t('Los escenarios permiten definir la ponderación a aplicarse por cada rúbrica.')}
        </Typography>
      </Grid>
      <IntegralEvaluationsTable />

      {integralEvaluationsSideDialogOpen && <IntegralEvaluationSideDialog />}
      {integralEvaluationsDeleteModalOpen && <IntegralEvaluationDeleteDialog />}
      {isOpenDeleteDialog && <RubricDeleteDialog />}
    </Grid>
  );
};

export default IntegralEvaluationsScreen;
