import * as React from 'react';
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  // Box,
  Grid,
  GridProps,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { setEventDrag } from '../../slices/acPlanPostgraduateSlice';
import { MyEvents } from '../../interfaces/events.interfaces';
import { useAppDispatch } from '../../../../../hooks/useRedux';
// import { makeStyles } from '@material-ui/core/styles';

// const useStyles = makeStyles((theme) => ({
//   square: {
//     width: 20,
//     height: 20,
//     borderRadius: '10px',
//   },
// }));

interface Props {
  simbologies: {
    color: string;
    label: string;
    index: number;
    cantCourse: number;
    typeId: string;
  }[];
  gridProps?: GridProps;
}
// const options = ['Create a merge commit', 'Squash and merge', 'Rebase and merge'];

export default function SplitButton({ simbologies, gridProps }: Props) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(simbologies[0]?.index);
  const dispatch = useAppDispatch();
  // const classes = useStyles();

  const handleClick = () => {
    console.info(`You clicked ${simbologies.find((e) => e.index === selectedIndex)?.label}`);
  };

  const handleDrag = (event: Partial<MyEvents>) => {
    dispatch(setEventDrag(event));
  };

  const currentProgram = simbologies.find((e) => e.index === selectedIndex);

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  return (
    <Grid {...gridProps}>
      <ButtonGroup
        variant="outlined"
        ref={anchorRef}
        aria-label="split button"
        sx={{ backgroundColor: '#fff', borderRadius: '25px' }}
      >
        <Button
          draggable
          // disabled={!simbologies.find((e) => e.index === selectedIndex)?.cantCourse}
          // startIcon={
          //   <Box
          //     className={classes.square}
          //     sx={{
          //       bgcolor: currentProgram?.color,
          //       mr: '8px',
          //       boxShadow: currentProgram?.color === '#FFF' ? 2 : '',
          //       fontSize: '12.5px !important',
          //       color: '#fff',
          //       fontWeight: '3',
          //     }}
          //   >
          //     {simbologies.find((e) => e.index === selectedIndex)?.cantCourse}
          //   </Box>
          // }
          onDragStart={(e) =>
            handleDrag({
              color: currentProgram?.color || '',
              title: currentProgram?.label || '',
              id: `${currentProgram?.index || 0 + 1}`,
              typeId: currentProgram?.typeId || '',
            })
          }
          onClick={handleClick}
        >
          {simbologies.find((e) => e.index === selectedIndex)?.label}
        </Button>
        <Button
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{ zIndex: 5 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {simbologies.map((option, index) => (
                    <MenuItem
                      key={option.label}
                      // disabled={!simbologies.find((e) => e.index === option.index)?.cantCourse}
                      selected={option.index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, option.index)}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Grid>
  );
}
