export const permissionKey = 'offers';

export interface SubPermissionKeys {
  read: string;
  create: string;
  edit: string;
  delete: string;
}

export const subPermissionKeys: SubPermissionKeys = {
  read: 'offers-listar-ofertas',
  create: 'offers-crear-oferta-None',
  edit: 'offers-actualizar-oferta-None',
  delete: 'offers-borrar-oferta-None',
};
