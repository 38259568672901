import { FormHelperText, Grid, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import React from 'react';

const estilos = makeStyles({
  input: {
    '& .css-1o2jng6-MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
});

const InputModals: React.FC<{
  placeholder: string;
  setValue?: any;
  Title: string;
  name?: string;
  disabled?: boolean;
  error?: boolean;
  messageError?: string;
}> = ({ setValue, disabled = false, Title, placeholder, name, error, messageError }) => {
  const style = estilos();

  return (
    <Grid container marginBottom={'15px'}>
      <Grid xs={12}>
        <Typography style={{ marginBottom: '10px' }} fontWeight={'bold'} color="#657F98">
          {Title}
        </Typography>
      </Grid>
      <Grid xs={12}>
        <TextField
          fullWidth
          error={error}
          disabled={disabled}
          name={name}
          className={style.input}
          placeholder={placeholder}
          style={{
            backgroundColor: '#F2F3F4',
            borderRadius: '15px',
            outline: 'none',
            border: 0,
          }}
          onChange={setValue}
        />
        {error && <FormHelperText error>{messageError}</FormHelperText>}
      </Grid>
    </Grid>
  );
};

export default InputModals;
