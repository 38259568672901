import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import { useGetPeriodsQuery } from '../slices/periodsApiSlice';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  makeStyles,
  TableCell,
  TableRow,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TextField,
  Tooltip,
  Typography,
  useTheme,
  Checkbox,
  FormControlLabel,
  Stack,
  Alert,
} from '@material-ui/core';
import { IconSearch, IconEye, IconEdit, IconCash, IconCheck, IconMessage } from '@tabler/icons';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { ChevronRight } from '@material-ui/icons';
import { format, parseISO } from 'date-fns';

import { useGetUseTypesQuery } from '../../maintenance-product/slices/productApiSlice';
import { useAppSelector } from '../../../../hooks/useRedux';
import { Child } from '../../maintenance-product/interfaces/usetypes.interface';
import { depositsTransfersValues } from '../constants/formConstants';
import {
  CatName,
  DepositsTransfersPayload,
  Detail,
} from '../interfaces/depositsTransfers.interface';
import { useDepositsTransfersFilterQuery } from '../slices/depositsTransfersApiSlice';
import {
  openChangeStatusModalWith,
  setChangePaymentMethodModalOpen,
  setDepositTransferId,
  setDepositTransferList,
  setEditDepositTransferModalOpen,
  setObservationDialogOpen,
  setPaymentStatus,
  setViewDepositTransferModalOpen,
} from '../slices/depositsTransfersSlice';
import SubCard from '../../../shared/components/cards/SubCard';
import { renderErrorOrEmptyRow } from '../../../shared/helpers/render';
import TransactionStatusChip from '../../../shared/components/badges/TransactionStatusChip';
import ViewDepositTransfer from './ViewDepositTransfer';
import SearchPaginatedTable, {
  FilterArrow,
} from '../../../shared/components/tables/SearchPaginatedTable';
import { useGetUsersCashiersQuery } from '../../financial-configurations/slices/financialConfigurationApiSlice';
import { useGetFinancialInstitutionAccountQuery } from '../../../students/payments-history/slices/paymentsApiSlice';
import { useGetListedPaymentMethodsQuery } from '../../../shared/slices/catalogApi';
import LocalizedDateRangePicker from '../../../shared/components/controlled/LocalizedDateRangePicker';
import { getDatesInRange } from '../../../shared/helpers/dates';
import useSharedGuard from '../../../../hooks/useSharedGuard';
import permissions from '../constants/permissions';

const useStyles = makeStyles((theme) => ({
  searchBlock: {
    border: '3px solid',
    borderColor: theme.palette.primary.light,
    width: '100%',
  },
  cardHeader: {
    backgroundColor: theme.palette.primary.light,
    paddingBottom: 2,
    paddingTop: 2,
  },
  labelTypo: {
    fontWeight: 'bold',
    textAlign: 'right',
    width: '100%',
    marginRight: 5,
  },
}));
const DepositsTransfersTable = function () {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();

  const { hasAccess: hasCashPoint } = useSharedGuard(permissions.cashPoint);
  const { hasAccess: hasCatalog } = useSharedGuard(permissions.catalog);
  const { hasAccess: hasFinacialInst } = useSharedGuard(permissions.financialInstitutions);
  const { hasAccess: hasCashCollection, hasAtLeastOne: hasOneCashCollectionPerm } = useSharedGuard(
    permissions.cashCollection
  );

  const { currentProfile, defaultConfiguration } = useAppSelector((state) => state.access);

  const { depositTransferId, viewDepositTransferModalOpen } = useAppSelector(
    (state) => state.depositsTransfers
  );

  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [perPage, setPerPage] = useState(5);

  const [advanceSearch, setAdvanceSearch] = useState(false);

  const [filterArrow, setFilterArrow] = useState<FilterArrow>(null!);
  //const [collapseItems, setCollapseItems] = useState<JSX.Element[]>();
  const [usePaymentState, setUsePaymentState] = useState<Child[]>([]);
  const [depositsTransfersPayload, setDepositsTransfersPayload] =
    useState<Partial<DepositsTransfersPayload> | null>(null);

  const { data: paymentStates, isLoading: isLoadingPS } = useGetUseTypesQuery({
    profileId: currentProfile?.profileId!,
    acronym: 'STP',
  });

  const { data: cashiers, isFetching: isFetchingCash } = useGetUsersCashiersQuery({
    profileId: currentProfile?.profileId!,
  });

  const { data: paymentMethods, isFetching: isFetchingPM } = useGetListedPaymentMethodsQuery({
    catalog_array: ['forma-pago-deposito', 'forma-pago-transferencia', 'forma-pago-web'],
  });

  const {
    data: fIA,
    // isLoading: isLoadingFIA,
    isFetching: isFetchingFIA,
  } = useGetFinancialInstitutionAccountQuery(
    {
      profileId: currentProfile?.profileId!,
    }
    /*{ skip: paymethod.keyword !== 'forma-pago-deposito' }*/
  );

  useEffect(() => {
    setPage(1);
  }, [perPage, search]);

  const { data, refetch, isLoading, isFetching, error } = useDepositsTransfersFilterQuery(
    {
      page,
      perPage,
      search,
      depositsTransfersPayload: {
        ...depositsTransfersPayload,
      },
      ...(filterArrow && { sort: filterArrow.name, type_sort: filterArrow.direction }),
    },
    { refetchOnMountOrArgChange: 30, skip: depositsTransfersPayload === null }
  );

  const datafetch = data?.data!.map((value, index) => {
    return {
      id: {
        index: index + ((page - 1) * perPage + 1),
        otherMethodPayment: value.other_methods_payment?.cat_keyword,
      },
      student: {
        name: value.student_full_name,
        identification: value.student_identification,
        studCode: value?.stud_code,
      },
      debtor: {
        name: value.debtor.person?.pers_full_name,
        identification: value.debtor?.person?.pers_identification,
      },
      institution_target: {
        name: value.financial_institution_target?.fin_int_name,
        account: value.cuenta_destino,
        pay_type: value.payment_way?.cat_name,
      },
      bill: value.num_doc_fact,

      voucher: value.voucher_number,

      dates: {
        pay_date: value.payment_date
          ? format(parseISO(value.payment_date), 'yyyy-MM-dd HH:mm:ss')
          : null,
        transaction: value.transaction_date
          ? format(parseISO(value.transaction_date), 'yyyy-MM-dd HH:mm:ss')
          : null,
        approval: value.approval_rejection_date
          ? format(parseISO(value.approval_rejection_date), 'yyyy-MM-dd HH:mm:ss')
          : null,
      },

      fees_amount: value.o_detaill_collect?.fees_amount_sin_desc,
      discount: value.o_detaill_collect?.sum_descu,
      finalPay: value.o_payment_amount,
      creditBalance:
        // ?se valida que exista y sea mayor de 0
        // ?para en caso de que sea negativo extraewr el signo
        value.pay.o_pay_balance && 0 > Number(value.pay.o_pay_balance)
          ? value.pay.o_pay_balance.slice(1)
          : value.pay.o_pay_balance,
      status_id: value.pay?.status_payments?.cat_keyword!,
      options: value,
    };
  });

  useEffect(() => {
    if (data?.data) {
      dispatch(setDepositTransferList(data?.data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleEdit = (transactionId: number) => {
    dispatch(openChangeStatusModalWith(transactionId));
  };

  const CollapsibleContentRow = (
    id: {
      index: number;
      otherMethodPayment?: string | 'forma-pago-meses-anteriores';
      creditBalance?: string;
    },
    details?: Detail[],
    dates?: { pay_date: string | null; transaction: string | null; approval: string | null }
  ) => {
    return (
      <Box margin={1}>
        <Alert severity="info">
          {t('financial.deposit_transfers.table.credit_balance_msg')}{' '}
          {defaultConfiguration?.currency_symbol}
          {id.creditBalance}
        </Alert>

        <TableContainer>
          <SubCard content={false} sx={{ mb: 2 }}>
            <Table size="small" aria-label="purchases">
              <TableHead>
                <TableRow hover sx={{ backgroundColor: theme.palette.grey[200] }}>
                  <TableCell>{t('financial.deposit_transfers.collapsible_table.debt')}</TableCell>
                  <TableCell>
                    {t('financial.deposit_transfers.collapsible_table.payment_method')}
                  </TableCell>
                  <TableCell>{t('Meses anteriores')}</TableCell>
                  <TableCell>{t('financial.deposit_transfers.table.transaction_date')}</TableCell>
                  <TableCell>{t('financial.deposit_transfers.table.approval_date')}</TableCell>
                  <TableCell>
                    ({defaultConfiguration?.currency_symbol})
                    {t('financial.deposit_transfers.collapsible_table.amount')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {details
                  ? details?.map((detail) => (
                      <TableRow>
                        <TableCell>{detail.fees_name_identifier}</TableCell>
                        <TableCell>{detail.cat_name}</TableCell>
                        <TableCell>
                          {Boolean(id.otherMethodPayment)
                            ? `${
                                id.otherMethodPayment === 'forma-pago-meses-anteriores'
                                  ? 'Si'
                                  : 'No'
                              }`
                            : ' - '}
                        </TableCell>
                        <TableCell>{dates?.transaction}</TableCell>
                        <TableCell>{dates?.approval}</TableCell>
                        <TableCell>
                          {defaultConfiguration?.currency_symbol}
                          {detail.o_amount}
                        </TableCell>
                      </TableRow>
                    ))
                  : renderErrorOrEmptyRow(6)}
              </TableBody>
            </Table>
          </SubCard>
        </TableContainer>
      </Box>
    );
  };

  const { handleSubmit, values, getFieldProps, setFieldValue } = useFormik({
    initialValues: depositsTransfersValues,
    //validationSchema: electronicDocumentSchema,
    onSubmit: async (value) => {
      const depositsTransfersP: Partial<DepositsTransfersPayload> = {
        search: value.search,
        start_date: getDatesInRange(
          { start: value.start_date, end: value.end_date },
          'start',
          true
        ),
        end_date: getDatesInRange({ start: value.start_date, end: value.end_date }, 'end', true),

        ...(advanceSearch
          ? {
              approved_start_date: value.approved_dates
                ? getDatesInRange(
                    { start: value.approved_start_date, end: value.approved_end_date },
                    'start',
                    true
                  )
                : undefined,
              approved_end_date: value.approved_dates
                ? getDatesInRange(
                    { start: value.approved_start_date, end: value.approved_end_date },
                    'end',
                    true
                  )
                : undefined,

              transaction_start_date: value.transaction_dates
                ? getDatesInRange(
                    { start: value.transaction_start_date, end: value.transaction_end_date },
                    'start',
                    true
                  )
                : undefined,
              transaction_end_date: value.transaction_dates
                ? getDatesInRange(
                    { start: value.transaction_start_date, end: value.transaction_end_date },
                    'end',
                    true
                  )
                : undefined,

              user_id: value.user_id || undefined,
              financial_institution_target_id: value.financial_institution_target_id || undefined,
              payment_way_id: value.payment_way_id || undefined,
            }
          : {}),

        payment_status_id: value.payment_status_id,
        payment_dep_transf: 'true',
      };

      if (JSON.stringify(depositsTransfersP) === JSON.stringify(depositsTransfersPayload)) {
        refetch();
      } else {
        setDepositsTransfersPayload(depositsTransfersP);
      }
    },
  });

  const handleViewDepositTransfer = (id: number) => {
    if (depositTransferId === id && viewDepositTransferModalOpen) {
      dispatch(setDepositTransferId(null!));
      dispatch(setViewDepositTransferModalOpen(false));

      return;
    }

    dispatch(setDepositTransferId(id));
    dispatch(setViewDepositTransferModalOpen(true));
  };

  useEffect(() => {
    if (paymentStates) {
      const data = paymentStates?.children || [];
      setUsePaymentState(data);
      dispatch(setPaymentStatus(data));
    }
  }, [paymentStates, dispatch]);

  useEffect(() => {
    dispatch(setDepositTransferId(null!));
    dispatch(setViewDepositTransferModalOpen(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const handleEditDepositTransfer = (id: number) => {
    dispatch(setDepositTransferId(id));
    dispatch(setEditDepositTransferModalOpen(true));
  };

  const handleChangePaymentMethod = (id: number) => {
    dispatch(setDepositTransferId(id));
    dispatch(setChangePaymentMethodModalOpen(true));
  };

  const handleViewObservation = (id: number) => {
    dispatch(setDepositTransferId(id));
    dispatch(setObservationDialogOpen(true));
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={3} alignContent="flex-start" mt={1}>
        <Grid item xs={12} container spacing={2}>
          <Card className={classes.searchBlock}>
            <CardHeader
              avatar={<IconSearch />}
              title={t('financial.deposit_transfers.filters.title')}
              className={classes.cardHeader}
            />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={6} container spacing={2}>
                  {/* container */}
                  <Grid item xs={12} container alignItems="center">
                    <Grid item xs={12} md={3} xl={4} container sx={{ alignContent: 'center' }}>
                      <Typography variant="h5" className={classes.labelTypo}>
                        {t('financial.deposit_transfers.filters.dates.label')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={9} xl={8} container>
                      <Grid item xs={12}>
                        <LocalizedDateRangePicker
                          value={[values.start_date, values.end_date]}
                          onChange={(date) => {
                            setFieldValue('start_date', date[0]);
                            setFieldValue('end_date', date[1]);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* container */}

                  {!advanceSearch ? (
                    <Grid item xs={12}></Grid>
                  ) : (
                    <>
                      <Grid item xs={12} container alignItems="center">
                        <Grid item xs={12} md={3} xl={4} container sx={{ alignContent: 'center' }}>
                          <Typography
                            variant="h5"
                            className={classes.labelTypo}
                            alignContent="center"
                          >
                            {t('financial.deposit_transfers.filters.dates.aprobation.label')}

                            <Checkbox
                              value={values.approved_dates}
                              checked={values.approved_dates}
                              onChange={(e) => setFieldValue('approved_dates', e.target.checked)}
                            />
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={9} xl={8} container>
                          <Grid item xs={12}>
                            <LocalizedDateRangePicker
                              value={[values.approved_start_date, values.approved_end_date]}
                              onChange={(date) => {
                                setFieldValue('approved_start_date', date[0]);
                                setFieldValue('approved_end_date', date[1]);
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={12} container alignItems="center">
                        <Grid item xs={12} md={3} xl={4} container sx={{ alignContent: 'center' }}>
                          <Typography variant="h5" className={classes.labelTypo}>
                            {t('financial.deposit_transfers.filters.dates.transaction.label')}

                            <Checkbox
                              value={values.transaction_dates}
                              checked={values.transaction_dates}
                              onChange={(e) => setFieldValue('transaction_dates', e.target.checked)}
                            />
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={9} xl={8} container>
                          <Grid item xs={12}>
                            <LocalizedDateRangePicker
                              value={[values.transaction_start_date, values.transaction_end_date]}
                              onChange={(date) => {
                                setFieldValue('transaction_start_date', date[0]);
                                setFieldValue('transaction_end_date', date[1]);
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  )}

                  <Grid item xs={12}></Grid>
                  <Grid item xs={12}></Grid>

                  {/* container */}
                </Grid>

                <Grid item xs={12} md={12} lg={6} container spacing={2}>
                  {/*container*/}
                  <Grid item xs={12} container>
                    <Grid item xs={12} md={3} xl={4} container sx={{ alignContent: 'center' }}>
                      <Typography variant="h5" className={classes.labelTypo}>
                        {t('financial.deposit_transfers.filters.name_label')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={9} xl={8} container>
                      <TextField
                        fullWidth
                        //error={Boolean(errors.to && touched.to)}
                        placeholder={t('financial.deposit_transfers.filters.name_placeholder')}
                        {...getFieldProps('search')}
                      />
                    </Grid>
                  </Grid>

                  {!advanceSearch ? null : (
                    <>
                      {/* // cajero */}
                      {hasCashPoint('getUsersCash') && (
                        <Grid item xs={12} container>
                          <Grid
                            item
                            xs={12}
                            md={3}
                            xl={4}
                            container
                            sx={{ alignContent: 'center' }}
                          >
                            <Typography variant="h5" className={classes.labelTypo}>
                              {t('financial.deposit_transfers.filters.cashier')}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} md={9} xl={8} container>
                            <Autocomplete
                              options={cashiers || []}
                              loading={isFetchingCash}
                              getOptionLabel={(option) =>
                                `${option.pers_identification || ''} - ${
                                  option.pers_full_name || ''
                                }`
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  //error={Boolean(errors.tipo_comprobante && touched.tipo_comprobante)}
                                  placeholder={t(
                                    'financial.deposit_transfers.filters.cashier_placeholder'
                                  )}
                                />
                              )}
                              onChange={(_, value) => {
                                setFieldValue('user_id', value?.user_id || undefined);
                              }}
                              fullWidth
                              // clearIcon={null}
                            />
                          </Grid>
                        </Grid>
                      )}
                      {/* // tipo de pago */}
                      {hasCatalog('getpaymentArray') && (
                        <Grid item xs={12} container>
                          <Grid
                            item
                            xs={12}
                            md={3}
                            xl={4}
                            container
                            sx={{ alignContent: 'center' }}
                          >
                            <Typography variant="h5" className={classes.labelTypo}>
                              {t('financial.deposit_transfers.filters.dates.type_pay.label')}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} md={9} xl={8} container>
                            <Autocomplete
                              options={paymentMethods || []}
                              loading={isFetchingPM}
                              getOptionLabel={(option) => option.cat_name}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  //error={Boolean(errors.tipo_comprobante && touched.tipo_comprobante)}
                                  placeholder={t(
                                    'financial.deposit_transfers.filters.dates.type_pay.placeholder'
                                  )}
                                />
                              )}
                              onChange={(_, value) => {
                                setFieldValue('payment_way_id', value?.id || undefined);
                              }}
                              fullWidth
                              // clearIcon={null}
                            />
                          </Grid>
                        </Grid>
                      )}
                      {/* // bancos beneficiarios */}
                      {hasFinacialInst('read') && (
                        <Grid item xs={12} container>
                          <Grid
                            item
                            xs={12}
                            md={3}
                            xl={4}
                            container
                            sx={{ alignContent: 'center' }}
                          >
                            <Typography variant="h5" className={classes.labelTypo}>
                              {t('financial.deposit_transfers.filters.dates.beneficiary.label')}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} md={9} xl={8} container>
                            <Autocomplete
                              options={fIA || []}
                              loading={isFetchingFIA}
                              getOptionLabel={(option) =>
                                option.financial_institution.fin_int_name +
                                ' - ' +
                                option.fia_account_number
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  //error={Boolean(errors.tipo_comprobante && touched.tipo_comprobante)}
                                  placeholder={t(
                                    'financial.deposit_transfers.filters.dates.beneficiary.placeholder'
                                  )}
                                />
                              )}
                              onChange={(_, value) => {
                                setFieldValue(
                                  'financial_institution_target_id',
                                  value?.financial_institution_id || undefined
                                );
                              }}
                              fullWidth
                              // clearIcon={null}
                            />
                          </Grid>
                        </Grid>
                      )}
                    </>
                  )}

                  {/* estado */}
                  {hasCatalog('getChildrenCatalogByAcro') && (
                    <Grid item xs={12} container>
                      <Grid item xs={12} md={3} xl={4} container sx={{ alignContent: 'center' }}>
                        <Typography variant="h5" className={classes.labelTypo}>
                          {t('financial.deposit_transfers.filters.status_label')}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={9} xl={8} container>
                        <Autocomplete
                          options={usePaymentState}
                          loading={isLoadingPS}
                          getOptionLabel={(option) => option.cat_name}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              //error={Boolean(errors.tipo_comprobante && touched.tipo_comprobante)}
                              placeholder={t(
                                'financial.deposit_transfers.filters.status_placeholder'
                              )}
                            />
                          )}
                          onChange={(_, value) => {
                            setFieldValue('payment_status_id', value?.id!);
                          }}
                          fullWidth
                          // clearIcon={null}
                        />
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid item xs={12} textAlign="right" mt={2}>
                  {(hasCashPoint('getUsersCash') ||
                    hasCatalog('getpaymentArray') ||
                    hasFinacialInst('read')) && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={advanceSearch}
                          onChange={(e) => setAdvanceSearch(e.target.checked)}
                        />
                      }
                      label={t('financial.deposit_transfers.filters.advance_search')}
                    />
                  )}

                  <Button
                    type="submit"
                    variant="contained"
                    //disabled={isLoading}
                    //endIcon={isLoading && <CircularProgress size={20} />}
                    //fullWidth
                  >
                    <IconSearch />
                  </Button>
                  {/* <Grid item xs={12} md={6} xl={6} container sx={{ alignContent: 'center' }}>
                      
                    </Grid>
                    <Grid item xs={12} md={6} xl={6} container>
                      
                    </Grid> */}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          xs={viewDepositTransferModalOpen ? 7 : 12}
          container
          spacing={2}
          sx={
            {
              // transition: 'all .5s ease-in-out',
            }
          }
        >
          <SearchPaginatedTable
            data={datafetch ? datafetch : []}
            headers={{
              id: t('financial.deposit_transfers.table.detail'),
              student: t('financial.deposit_transfers.table.student'),
              // studCode: t('financial.deposit_transfers.table.stud_code'),
              debtor: t('financial.deposit_transfers.table.debtor'),
              institution_target: t('financial.deposit_transfers.table.destined_institution'),
              bill: t('financial.deposit_transfers.table.bill'),
              voucher: t('financial.deposit_transfers.table.voucher'),
              // paycheck_name: t('financial.deposit_transfers.table.type'),
              // otherMethodPayment: 'Meses anteriores',
              dates: t('financial.deposit_transfers.table.pay_date'),
              // account_target: t('financial.deposit_transfers.table.destined_account'),
              fees_amount: `(${defaultConfiguration?.currency_symbol})${t(
                'financial.deposit_transfers.table.amount'
              )}`,
              discount: `(${defaultConfiguration?.currency_symbol})${t(
                'financial.deposit_transfers.table.discount'
              )}`,
              finalPay: `(${defaultConfiguration?.currency_symbol})${t(
                'financial.deposit_transfers.table.total'
              )}`,
              creditBalance: `(${defaultConfiguration?.currency_symbol})${t(
                'financial.deposit_transfers.table.credit_balance'
              )}`,
              status_id: t('financial.deposit_transfers.table.status'),
              options: t('shared.search_paginated_table.table.options'),
            }}
            listFilterArrows={{
              // finalPay: 'o_payment_amount',
              institution_target: 'cuenta_destino',
            }}
            filterArrow={filterArrow}
            setFilterArrow={setFilterArrow}
            keyExtractor={(item) => item.options.id.toString()}
            searchPlacehoder={t('financial.deposit_transfers.table.search_placeholder')}
            isLoading={isLoading}
            isFetching={isFetching}
            error={error}
            perPage={perPage}
            setPerPage={setPerPage}
            total={data?.total || 0}
            page={page}
            setPage={setPage}
            numHeader={14}
            search={search}
            setSearch={setSearch}
            //apply collapsible function, in specific row (optional)
            collapsible={{
              Rows: (index, { id, options, dates, creditBalance }) =>
                CollapsibleContentRow({ ...id, creditBalance }, options.details, dates),
              collapsibleBtnColl: 0,
            }}
            showFilter={false}
            customRenderers={{
              dates: ({ dates }) => (
                <Stack spacing={1}>
                  <Typography>{dates.pay_date}</Typography>
                </Stack>
              ),
              student: ({ student }) => (
                <Stack spacing={1}>
                  <Typography>{student.name}</Typography>
                  <Typography>
                    <b>{t('financial.deposit_transfers.table.identification')}: </b>
                    {student.identification}
                  </Typography>
                  <Typography>
                    <b>{t('financial.deposit_transfers.table.stud_code')}: </b>
                    {student.studCode}
                  </Typography>
                </Stack>
              ),
              debtor: ({ debtor }) => (
                <Stack spacing={1}>
                  <Typography>{debtor.name}</Typography>
                  <Typography>
                    <b>{t('financial.deposit_transfers.table.identification')}: </b>
                    {debtor.identification}
                  </Typography>
                </Stack>
              ),
              institution_target: ({ institution_target }) => (
                <Stack spacing={1}>
                  <Typography>
                    <b>{institution_target.pay_type}</b>
                  </Typography>
                  <Typography>{institution_target.name}</Typography>
                  <Typography>
                    <b>{t('financial.deposit_transfers.table.destined_account')}: </b>
                    {institution_target.account}
                  </Typography>
                </Stack>
              ),

              status_id: (row) => (
                <TransactionStatusChip
                  name={row.options?.pay?.status_payments?.cat_name!}
                  keyword={row.status_id}
                />
              ),
              options: (row) => (
                <>
                  {row.options?.url_file && (
                    <Tooltip title={t('financial.deposit_transfers.table.options.detail_tooltip')}>
                      <IconButton onClick={() => handleViewDepositTransfer(row.options.id)}>
                        {depositTransferId === row.options.id && viewDepositTransferModalOpen ? (
                          <ChevronRight />
                        ) : (
                          <IconEye />
                        )}
                      </IconButton>
                    </Tooltip>
                  )}

                  {hasCashCollection('collectionStatus') &&
                    row.options?.pay?.status_payments?.cat_name !== CatName.Aprobado &&
                    row.options?.pay?.status_payments?.cat_name !== CatName.Rechazado && (
                      <Tooltip title="Cambiar estado">
                        <IconButton onClick={() => handleEdit(row.options?.id!)}>
                          <IconCheck />
                        </IconButton>
                      </Tooltip>
                    )}

                  {hasCashCollection('collectionStatus') &&
                    row.options?.pay?.status_payments?.cat_name !== CatName.Aprobado &&
                    row.options?.pay?.status_payments?.cat_name !== CatName.Rechazado && (
                      <Tooltip title={t('financial.deposit_transfers.table.options.edit_tooltip')}>
                        <IconButton onClick={() => handleEditDepositTransfer(row.options.id)}>
                          <IconEdit />
                        </IconButton>
                      </Tooltip>
                    )}

                  {hasCatalog('getChildrenByAcronymOther') &&
                    hasCashCollection('methodPreviousMonths') &&
                    row.options?.pay?.status_payments?.cat_name !== CatName.Aprobado &&
                    row.options?.pay?.status_payments?.cat_name !== CatName.Rechazado && (
                      <Tooltip title={t('financial.deposit_transfers.change_payment_method')}>
                        <IconButton onClick={() => handleChangePaymentMethod(row.options.id)}>
                          <IconCash />
                        </IconButton>
                      </Tooltip>
                    )}

                  {row.options?.observation && (
                    <Tooltip title={t('financial.deposit_transfers.observation')}>
                      <IconButton onClick={() => handleViewObservation(row.options.id)}>
                        <IconMessage />
                      </IconButton>
                    </Tooltip>
                  )}

                  {!hasCatalog('getChildrenByAcronymOther') &&
                    !hasOneCashCollectionPerm(['methodPreviousMonths', 'collectionStatus']) &&
                    t('shared.search_paginated_table.table.options.no_options')}
                </>
              ),
            }}
            customHeadersCellsProperties={{
              options: {
                align: 'center',
              },

              id: {
                align: 'center',
              },
            }}
            customDataCellsProperties={{
              id: {
                align: 'center',
              },

              options: {
                align: 'center',
              },
            }}
          />
        </Grid>
        {depositTransferId && viewDepositTransferModalOpen && (
          <Grid
            item
            xs={5}
            sx={{
              height: 'auto',
              transition: 'all .5s ease-in-out',
            }}
          >
            <ViewDepositTransfer />
          </Grid>
        )}
      </Grid>
      {}
    </form>
  );
};

export default DepositsTransfersTable;
