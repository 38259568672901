export const subPermissionKeys = {
  groupKey: 'course',

  read: 'collaborators-listar-colaborador',
  show: 'collaborators-obtener-colaborador',
  showAcademicUnits: 'education-levels-obtener-nivel-educativo',
  jointAcademic: 'joint-academics-search-education-level',
  listCoursesAvailableCollaborator:
    'collaborators-courses-available-listar-cursos-disponibles-para-asignar-a-un-colaborador',
};
