import { Grid } from '@material-ui/core';

import { PropsFormEdit } from './';
import EducationForm from './EducationForm';
import EducationCard from './EducationCard';

const Education = ({ data }: PropsFormEdit) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <EducationForm data={data} />
      </Grid>
      <Grid item xs={12}>
        <EducationCard data={data} />
      </Grid>
    </Grid>
  );
};

export default Education;
