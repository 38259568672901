import { Switch, Redirect } from 'react-router-dom';

import { useAppSelector } from '../../../../hooks/useRedux';

import GuardRoute from '../../../../router/guards/GuardRoute';
import OthersListScreen from '../screens/OthersListScreen';
import AddOtherScreen from '../screens/AddOtherScreen';
import EditOtherScreen from '../screens/EditOtherScreen';

const OthersRouter = () => {
  const { currentModule } = useAppSelector((state) => state.access);

  const hasPermission = 'borrows' in currentModule?.permissions!;

  if (!hasPermission) return <Redirect to="/dashboard" />;

  return (
    <Switch>
      <GuardRoute exact path="/administrar-recursos" component={OthersListScreen} />
      <GuardRoute exact path="/administrar-recursos/agregar" component={AddOtherScreen} />
      <GuardRoute exact path="/administrar-recursos/editar" component={EditOtherScreen} />
    </Switch>
  );
};

export default OthersRouter;
