import {
  Grid,
  Menu,
  MenuItem,
  Box,
  useTheme,
  Button,
  InputAdornment,
  TextField,
  IconButton,
  Typography,
} from '@material-ui/core';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useState } from 'react';
import { useAppSelector } from '../../../../hooks/useRedux';
import { useGetDepartmentsQuery } from '../slices/departmentsApiSlice';
import { TreeView } from '@material-ui/lab';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import AddButton from '../../../shared/components/tables/buttons/AddButton';
import DepartmentItem from './DepartmentItem';
import PerfectScrollbar from 'react-perfect-scrollbar';
import useGuard from '../hooks/useGuard';
import { renderSkeletonTable } from '../../../shared/helpers/render';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import CustomPagination from '../../../shared/components/tables/CustomPagination';
import { updateDepartmentTable } from '../slices/departmentsSlice';
import { useTableSearch } from '../../../../hooks/useTableSearch';
import { DebounceInput } from 'react-debounce-input';
import { Search } from '@material-ui/icons';
import { IconX } from '@tabler/icons';

const DepartmentsTree = () => {
  const { hasAccess } = useGuard();
  const { currentProfile } = useAppSelector((state) => state.access);

  const history = useHistory();
  const { path } = useRouteMatch();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const { page, perPage, search } = useAppSelector((state) => state.departments.departmentTable);

  const { setPage, setPerPage, setSearch } = useTableSearch({
    page,
    perPage,
    search,
    updateFunction: updateDepartmentTable,
  });

  const { isLoading, isFetching, data, error } = useGetDepartmentsQuery(
    {
      profileId: currentProfile?.profileId!,
      page,
      perPage,
      search,
    },
    { refetchOnMountOrArgChange: 30 }
  );
  const goToAdd = () => {
    history.push(`${path}/agregar`);
  };

  const handleClose = (newPerPage: number) => {
    setAnchorEl(null);
    setPerPage(newPerPage);
  };

  const theme = useTheme();

  return (
    <Grid container>
      {isLoading ? (
        <Grid item xs={12} md={6}>
          {renderSkeletonTable(5, 1)}
        </Grid>
      ) : (
        <>
          <Grid item container xs={12} alignItems="center" justifyContent="space-between">
            <Grid item xs={3}>
              <DebounceInput
                autoFocus={Boolean(search)}
                minLength={3}
                debounceTimeout={300}
                onChange={({ target }) => setSearch(target.value)}
                value={search}
                element={(props) => (
                  <TextField
                    style={{
                      width: '100%',
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search fontSize="small" />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            size="small"
                            onClick={() => setSearch('')}
                            {...(!search && { sx: { cursor: 'initial', opacity: 0 } })}
                          >
                            <IconX size={20} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    placeholder={'Buscar departamento'}
                    variant="outlined"
                    size="small"
                    {...props}
                  />
                )}
              />
            </Grid>
            <Grid item>{hasAccess('create') && <AddButton size="large" onClick={goToAdd} />}</Grid>
          </Grid>
          <Grid item xs={12} md={8} lg={6} sx={{ mt: 2 }}>
            <TreeView
              aria-label="file system navigator"
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
              sx={{ flexGrow: 1, overflowY: 'auto' }}
            >
              <PerfectScrollbar component="div">
                {!isLoading &&
                  !isFetching &&
                  data?.data?.map((d) => <DepartmentItem departamento={d} />)}

                {!isLoading && !isFetching && data?.data?.length === 0 && (
                  <Typography variant="body2" color="textSecondary" align="center">
                    No se encontraron resultados
                  </Typography>
                )}

                {isFetching && (
                  <Grid item xs={12}>
                    {renderSkeletonTable(5, 1)}
                  </Grid>
                )}
              </PerfectScrollbar>
              {!isLoading && !error && (
                <Grid container justifyContent="space-between" alignItems="flex-end" sx={{ mt: 4 }}>
                  <Grid item>
                    <CustomPagination
                      shape="rounded"
                      color="primary"
                      count={Math.ceil(data!.total / perPage)}
                      page={page}
                      onChange={(_, newPage) => {
                        setPage(newPage);
                      }}
                    />
                  </Grid>
                  <Grid>
                    <Box
                      component={Button}
                      size="large"
                      sx={{
                        color: theme.palette.grey[900],
                        backgroundColor: theme.palette.primary.light,
                        ':hover': { bgcolor: theme.palette.primary.light },
                        px: 2,
                        borderRadius: 4,
                      }}
                      endIcon={<ExpandMoreRoundedIcon />}
                      onClick={handleClick}
                    >
                      {perPage} Registros
                    </Box>

                    <Menu
                      id="menu-user-list-style1"
                      anchorEl={anchorEl}
                      keepMounted
                      open={open}
                      onClose={() => handleClose(perPage)}
                      variant="selectedMenu"
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                    >
                      <MenuItem onClick={() => handleClose(5)}> 5 Registros</MenuItem>
                      <MenuItem onClick={() => handleClose(10)}> 10 Registros</MenuItem>
                      <MenuItem onClick={() => handleClose(15)}> 15 Registros</MenuItem>
                    </Menu>
                  </Grid>
                </Grid>
              )}
            </TreeView>
          </Grid>
        </>
      )}
    </Grid>
  );
};
export default DepartmentsTree;
