import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISearchTable } from '../../../../../shared/interfaces/paginatedTable.interface';
import { LaunchingState } from '../interfaces/launchingList.interface';

const initialState: LaunchingState = {
  launchingTable: {
    page: 1,
    perPage: 5,
    search: '',
  },
  start_date: new Date().toISOString(),
  end_date: new Date().toISOString(),
  statusKeywordSelected: null,
  periodIdSelected: null,
};

export const launchingSlice = createSlice({
  name: 'launching',
  initialState,
  reducers: {
    setLaunchingTable: (state, action: PayloadAction<ISearchTable>) => {
      state.launchingTable = {
        ...state.launchingTable,
        ...action.payload,
      };
    },
    setStart_date: (state, action: PayloadAction<string>) => {
      state.start_date = action.payload;
    },
    setEnd_date: (state, action: PayloadAction<string>) => {
      state.end_date = action.payload;
    },
    setStatusKeywordSelected: (state, action: PayloadAction<string | null>) => {
      state.statusKeywordSelected = action.payload;
    },
    setPeriodIdSelected: (state, action: PayloadAction<number | null>) => {
      state.periodIdSelected = action.payload;
    },
  },
});

export const {
  setLaunchingTable,
  setStart_date,
  setEnd_date,
  setStatusKeywordSelected,
  setPeriodIdSelected,
} = launchingSlice.actions;
