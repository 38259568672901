import { Grid, Stack, Typography, makeStyles } from '@material-ui/core';
import MainCard from '../../../shared/components/cards/MainCard';
import { useRef } from 'react';

import { useHistory } from 'react-router-dom';
import { IconBooks } from '@tabler/icons';
import { useTranslation } from 'react-i18next';
import { ArrowForward } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.dark.dark : theme.palette.primary.main,
    color: '#fff',
    overflow: 'hidden',
    position: 'relative',
    '&>div': {
      position: 'relative',
      zIndex: 5,
    },
    // '&:after': {
    //   content: '""',
    //   position: 'absolute',
    //   width: '210px',
    //   height: '210px',
    //   background:
    //     theme.palette.mode === 'dark'
    //       ? 'linear-gradient(210.04deg, ' +
    //         theme.palette.primary.dark +
    //         ' -50.94%, rgba(144, 202, 249, 0) 95.49%)'
    //       : theme.palette.primary[200],
    //   borderRadius: '50%',
    //   zIndex: 1,
    //   top: '-85px',
    //   left: '-95px',
    //   [theme.breakpoints.down('xs')]: {
    //     top: '-105px',
    //     right: '-140px',
    //   },
    // },
    // '&:before': {
    //   content: '""',
    //   position: 'absolute',
    //   zIndex: 1,
    //   width: '210px',
    //   height: '210px',
    //   background:
    //     theme.palette.mode === 'dark'
    //       ? 'linear-gradient(140.9deg, ' +
    //         theme.palette.primary.dark +
    //         ' -14.02%, rgba(144, 202, 249, 0) 82.50%)'
    //       : theme.palette.primary[200],
    //   borderRadius: '50%',
    //   top: '-125px',
    //   left: '-15px',
    //   opacity: 0.5,
    //   [theme.breakpoints.down('xs')]: {
    //     top: '-155px',
    //     right: '-70px',
    //   },
    // },
  },
  content: {
    padding: '20px !important',
  },
  avatar: {
    ...theme.typography.commonAvatar,
    ...theme.typography.largeAvatar,
    cursor: 'default',
    // margin: '8px',
    height: 'auto',
    width: 'auto',
    backgroundColor: 'transparent',
    // backgroundColor: theme.palette.secondary[200],
    // padding: '0px 15px',
  },
}));

const LibraryCard = () => {
  const { t } = useTranslation();

  const classes = useStyles();
  const containerRef = useRef<HTMLDivElement | null>(null);

  const history = useHistory();

  return (
    <MainCard
      border={false}
      className={classes.card}
      contentClass={classes.content}
      ref={containerRef}
    >
      <Stack
        spacing={1}
        pt={1}
        onClick={() => {
          history.push('/biblioteca/inicio');
        }}
        sx={{
          cursor: 'pointer',
        }}
      >
        <Grid container alignItems="center">
          <Grid
            item
            xs={11}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Grid
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <IconBooks size={54} />
            </Grid>
            <Grid
              container
              direction="column"
              sx={{
                marginLeft: '8px',
              }}
            >
              <Grid item>
                <Typography fontSize="1.2rem" fontWeight="bold">
                  {t('Biblioteca')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  fontSize="0.9rem"
                  sx={{
                    color: 'secondary.main',
                    wordBreak: 'break-word',
                  }}
                >
                  {t('Accede a nuestra selección de libros')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1}>
            <ArrowForward />
          </Grid>
        </Grid>
      </Stack>
    </MainCard>
  );
};

export default LibraryCard;
