import { useState } from 'react';
import { Grid, Button } from '@material-ui/core';
import { useAppSelector } from '../../../../../hooks/useRedux';
import { useGetAttendanceQuery } from '../../slices/virtualClassroomApiSlice';
import FilterMonth from '../components/FilterMonth';
import TableAttendance from '../components/TableAttendance';
import image from '../../../../../assets/images/no-tests.png';
import { useTranslation } from 'react-i18next';
import NoData from '../../../../shared/components/NoData';
import JustifyDialog from './JustifyDialog';
import { IconChecklist } from '@tabler/icons';

const AttendanceDetail = () => {
  const [selectDate, setSelectDate] = useState<string>('01');

  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const { t } = useTranslation();

  const handleSetOpenDialog = () => {
    setOpenDialog(!openDialog);
  };

  const {
    course: { courseId },
  } = useAppSelector((state) => state.virtualClassroomStudentShared);

  const { attendancesToJustify } = useAppSelector((state) => state.virtualClassroomStudent);

  const { currentStudent } = useAppSelector((state) => state.access);

  const { data, isLoading } = useGetAttendanceQuery({
    courseId: courseId?.toString()!,
    studentId: currentStudent?.id.toString()!,
    month: selectDate,
  });
  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center">
        <FilterMonth valueState={selectDate} handleMonth={setSelectDate} />
        {false && (
          <Button
            variant="contained"
            onClick={() => handleSetOpenDialog()}
            disabled={!attendancesToJustify.length}
            endIcon={<IconChecklist />}
          >
            justificar falta
          </Button>
        )}
      </Grid>

      {!isLoading && data && Array.isArray(data) && data.length > 0 ? (
        <>
          <TableAttendance attendance={data} selectDate={selectDate} />
        </>
      ) : (
        <NoData
          image={image}
          subtitle={t('studentVirtualClassroom.attendance.noinfo.title')}
          title={t('studentVirtualClassroom.attendance.noinfo.description')}
        />
      )}
      {openDialog && (
        <JustifyDialog
          data={data || []}
          open={openDialog}
          handleCloseDialog={handleSetOpenDialog}
        />
      )}
    </>
  );
};

export default AttendanceDetail;
