import {
  Autocomplete,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { useFormik } from 'formik';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppSelector } from '../../../../hooks/useRedux';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import { statusAvailables } from '../../../shared/constants/resourceStatus';
import { useFormStyles } from '../constants/styles';
import { EconomicGroupInfoSchema } from '../constants/validationSchemas';
import { useAddEconomicGroupMutation } from '../slices/economicGroupsApiSlice';

const AddEconomicGroupForm = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { currentProfile } = useAppSelector((state) => state.access);
  const classes = useFormStyles();
  const [showAlerts, setShowAlerts] = useState(true);

  const [addEconomicGroup, { isLoading, isError, error, isSuccess }] =
    useAddEconomicGroupMutation();

  const checkAvailablesAmount = function (eco_gro_min_amount: number, eco_gro_max_amount: number) {
    const errors: any = {};
    const errorhigherAmmount = 'Monto mínimo no puede superar al monto máximo.';
    if (eco_gro_min_amount > eco_gro_max_amount) {
      errors.eco_gro_min_amount = errorhigherAmmount;
    }
    return errors;
  };

  const { getFieldProps, errors, touched, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      name: '',
      description: '',
      statusId: 1,
      eco_gro_min_amount: 0,
      eco_gro_max_amount: 0,
    },
    validationSchema: EconomicGroupInfoSchema,
    validate: ({ eco_gro_min_amount, eco_gro_max_amount }) => {
      return checkAvailablesAmount(eco_gro_min_amount, eco_gro_max_amount);
    },
    onSubmit: async (values) => {
      const eco_gro_min_amount_tofloat = parseFloat(values.eco_gro_min_amount.toString());
      const eco_gro_max_amount_tofloat = parseFloat(values.eco_gro_max_amount.toString());
      await addEconomicGroup({
        profileId: currentProfile?.profileId!,
        economicGroupPayload: {
          eco_gro_name: values.name,
          eco_gro_description: values.description,
          status_id: values.statusId.toString(),
          eco_gro_min_amount: eco_gro_min_amount_tofloat,
          eco_gro_max_amount: eco_gro_max_amount_tofloat,
        },
      });

      setShowAlerts(true);
    },
  });

  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction="column" gap={2}>
        <Grid item>
          {isError && showAlerts && (
            <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
          )}

          {isSuccess && showAlerts && (
            <SuccessAlert
              message={t('financial.economic_group.add.success_alert')}
              handleDismiss={() => setShowAlerts(false)}
            />
          )}
        </Grid>

        <Grid item container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>
              {t('financial.economic_group.add.name_label')}
            </InputLabel>
            <TextField
              fullWidth
              error={Boolean(errors.name && touched.name)}
              placeholder={t('financial.economic_group.add.name_placeholder')}
              {...getFieldProps('name')}
            />
            {errors.name && touched.name && <FormHelperText error>{t(errors.name)}</FormHelperText>}
          </Grid>
          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>
              {t('financial.economic_group.add.description_label')}
            </InputLabel>
            <TextField
              multiline
              rows={2}
              fullWidth
              placeholder={t('financial.economic_group.add.description_placeholder')}
              {...getFieldProps('description')}
            />
          </Grid>

          <Grid item xs={12} container spacing={2}>
            <Grid item xs={6}>
              <InputLabel>{t('financial.economic_group.add.min_amount_label')}</InputLabel>
              <TextField
                fullWidth
                type="number"
                error={Boolean(errors.eco_gro_min_amount && touched.eco_gro_min_amount)}
                placeholder={t('financial.economic_group.add.min_amount_placeholder')}
                {...getFieldProps('eco_gro_min_amount')}
              />
              {errors.eco_gro_min_amount && touched.eco_gro_min_amount && (
                <FormHelperText error>{t(errors.eco_gro_min_amount)}</FormHelperText>
              )}
            </Grid>
            <Grid item xs={6}>
              <InputLabel>{t('financial.economic_group.add.max_amount_label')}</InputLabel>
              <TextField
                type="number"
                fullWidth
                placeholder={t('financial.economic_group.add.max_amount_placeholder')}
                {...getFieldProps('eco_gro_max_amount')}
                error={Boolean(errors.eco_gro_max_amount && touched.eco_gro_max_amount)}
              />
              {errors.eco_gro_max_amount && touched.eco_gro_max_amount && (
                <FormHelperText error>{t(errors.eco_gro_max_amount)}</FormHelperText>
              )}
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>{t('shared.input.status.label')}</InputLabel>
            <Autocomplete
              options={statusAvailables}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField {...params} error={Boolean(errors.statusId && touched.statusId)} />
              )}
              defaultValue={statusAvailables[0]}
              onChange={(e, value) => setFieldValue('statusId', value?.id || '')}
              fullWidth
              clearIcon={null}
            />
            {errors.statusId && touched.statusId && (
              <FormHelperText error>{t(errors.statusId)}</FormHelperText>
            )}
          </Grid>
        </Grid>

        <Grid
          item
          container
          alignItems="center"
          justifyContent="flex-end"
          spacing={2}
          sx={{ mt: 1 }}
        >
          <Grid item>
            <Button
              variant="outlined"
              size="large"
              color="primary"
              onClick={() => history.goBack()}
            >
              {t('shared.go_back_button.label')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              disabled={isLoading}
              endIcon={isLoading && <CircularProgress size={20} />}
            >
              {t('shared.add_button.label')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default AddEconomicGroupForm;
