import { Grid, Typography } from '@material-ui/core';
import evaluationSuccess from '../../../../../../../../assets/images/evaluation_success.png';
import { useTranslation } from 'react-i18next';

const EvaluationConfirmation = () => {
  const { t } = useTranslation();

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item>
        <img src={evaluationSuccess} alt="Evaluation success" />
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h3" color="primary">
              {t('maintenance.evaluations.form.success.title')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              {t('maintenance.evaluations.form.success.subtitle')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EvaluationConfirmation;
