import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';

import Typography from '@material-ui/core/Typography';
import { ActivityProjectResearch } from '../../../../../interfaces/tasks.interfaces';
import EditButton from '../../../../../../../shared/components/tables/buttons/EditButton';

interface Props {
  activity: ActivityProjectResearch;
  handleEdit: (id: number) => void;
}

const ActivityCard = ({ activity, handleEdit }: Props) => {
  // const handleDelete = (id: number) => {};

  return (
    <Grid item xs={12} mt={2} px={1}>
      <Paper elevation={2} sx={{ width: '100%', padding: '5px' }}>
        <Grid container justifyContent="space-between" alignItems="center" p={1} spacing={1}>
          <Grid item>
            <Typography variant="subtitle1" color="initial">
              {activity.act_title}
            </Typography>
            <Typography variant="subtitle2" color="initial">
              {activity.act_observation}
            </Typography>
          </Grid>
          <Grid item>
            <Tooltip title={'Editar persona'}>
              <EditButton handleEdit={() => handleEdit(activity.id)} />
            </Tooltip>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default ActivityCard;
