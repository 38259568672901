import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Grid,
  IconButton,
  Typography,
  Badge,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@material-ui/core';
import { IconArrowNarrowLeft, IconGift, IconChevronRight } from '@tabler/icons';
import { useTheme } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import FiberManualCamera from '@material-ui/icons/PhotoCamera';
import { baseUrl } from '../../../../api/mainApi';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { checkProfileQuantity, checkModuleQuantity } from '../../../auth/helpers';
import { setCurrentVirtualClassroom } from '../../../shared/reducers/accessReducer';
import { Profile, Role } from '../../../../types';
import MainCard from '../../../shared/components/cards/MainCard';
import { setStudentRecordInfo } from '../../../auth/helpers/helperStudent';
import { StudentRecord } from '../../../auth/interfaces/student-record.interfaces';
import {
  setChangePhoto,
  resetCurrentModule,
  setCurrentRol,
  setCurrentProfile,
} from '../../../shared/reducers/accessReducer';
import { ThunkDispatch } from 'redux-thunk';
import ChangesBirthday from './ChangeBirthday';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useTranslation } from 'react-i18next';

interface OptionsProps {
  name: string;
  id: number;
  description: string | undefined;
  keyword: string;
}

const MiProfile = () => {
  const dispatch = useAppDispatch();

  const theme = useTheme();

  const history = useHistory();

  const { t } = useTranslation();

  const { auth, access } = useAppSelector((state) => state);

  const { currentProfile, profiles, currentRol, currentRecordStudent } = access;

  const ROL_TEACHER: string = 'DOCENTE';

  const ROL_ESTUDENT: string = 'ESTUDIANTE';

  const [open, setOpen] = useState(false);

  const [openBirthday, setOpenBirthday] = useState(false);

  const [selectedProfile, setSelectedProfile] = useState<null | string>(null);

  const { userInfo, studentRecordReference } = auth;

  const [showData, setShowData] = useState(false);

  const profilesAvailables = Object.keys(profiles).map((pk) => profiles[pk]);

  const obtainImageDomain = (image: string) => {
    return `${baseUrl}/show?name=${image}`;
  };

  const handleChangePhoto = () => {
    dispatch(setChangePhoto(true));
  };

  const handleProfile = (dispatch: ThunkDispatch<any, any, any>, profileName: string) => {
    const profile: { [key: string]: Profile } = { [`${profileName}`]: profiles[profileName] };
    checkProfileQuantity(dispatch, profile);
    history.push('/modulos');
    dispatch(setCurrentVirtualClassroom(false));
  };

  // const currentRolText = JSON.parse(localStorage.getItem('currentRol')!)
  //   .keyword?.split('-')
  //   .join(' ') as string;

  // const rolesText = `${currentRolText.charAt(0).toUpperCase()}${currentRolText.slice(1)}`;

  const obtainImageByDefault = (name: string | undefined) => {
    return `https://ui-avatars.com/api/?name=${name}&background=0D8ABC&color=fff`;
  };

  const handleSelectProfile = (keyword: string) => {
    // dispatch(resetCurrentModule());
    // localStorage.removeItem('currentModule');

    const pf: { [key: string]: Profile } = { [`${keyword}`]: profiles[keyword] };

    // setea el primer modulo del current rol
    if (currentRol && currentRol?.modules && Object.keys(currentRol?.modules).length === 1) {
      checkModuleQuantity(dispatch, currentRol.modules);
      // return <Redirect to="/dashboard" />;
    } else {
      // history.replace('/modulos');
    }

    setSelectedProfile(keyword);
    if (pf && Object.keys(pf[keyword].roles).length <= 1) checkProfileQuantity(dispatch, pf);
    setShowData(true);
  };

  const handleSelectStudentRecord = (studentRecord: StudentRecord, current: boolean) => {
    if (!current) {
      handleProfile(dispatch, 'estudiante');
    }

    //cambia el perfil por el tomado temporalmente en las opciones de perfil
    history.push('/modulos');

    setStudentRecordInfo(dispatch, studentRecord);
    setShowData(false);
  };

  const handleSelectRol = (rol: Role) => {
    dispatch(resetCurrentModule());
    localStorage.removeItem('currentModule');

    dispatch(setCurrentVirtualClassroom(false));
    dispatch(setCurrentProfile(profiles[selectedProfile!]));
    dispatch(setCurrentRol(rol));

    localStorage.setItem('currentRol', JSON.stringify({ id: rol.rolId, keyword: rol.keyword }));
    localStorage.setItem('currentProfile', selectedProfile!);

    setOpen(false);

    history.replace('/modulos');

    setShowData(false);
  };

  const RenderAvatarMenu = function () {
    const image = currentProfile?.imgProfile
      ? obtainImageDomain(currentProfile?.imgProfile)
      : obtainImageByDefault(userInfo?.name);

    return (
      <>
        <Badge
          onClick={handleChangePhoto}
          overlap="circular"
          badgeContent={
            <FiberManualCamera
              sx={{
                cursor: 'pointer',
                color: theme.palette.dark.dark,
                verticalAlign: 'middle',
                fontSize: '30px',
              }}
            />
          }
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <Avatar
            src={image}
            alt={`Usuario ${userInfo?.name} avatar`}
            sx={{ width: 100, height: 100 }}
          ></Avatar>
        </Badge>
      </>
    );
  };

  const renderProfilesAdmin = ({ description, id, name, keyword }: OptionsProps) => {
    return (
      <ListItem
        key={id}
        onClick={() => handleSelectProfile(keyword)}
        sx={{
          borderRadius: 5,
          padding: 3,
        }}
        button
      >
        <ListItemIcon
          {...(currentProfile?.profileId === id && {
            style: {
              backgroundColor: theme.palette.secondary.light,
              color: theme.palette.primary.main,
              padding: 10,
              borderRadius: 50,
              marginLeft: -10,
              gap: 20,
            },
          })}
        >
          {name.toUpperCase().slice(0, 2)}
        </ListItemIcon>
        <ListItemText
          primary={
            <>
              <Typography
                variant="body1"
                fontWeight={700}
                {...(currentProfile?.profileId === id && {
                  sx: { color: 'primary.main' },
                })}
              >
                {name}
              </Typography>
              <Typography
                variant="body2"
                {...(currentProfile?.profileId === id && {
                  sx: { color: 'primary.200' },
                })}
              >
                {description}
              </Typography>
            </>
          }
        />
        <Box sx={{ px: 1 }}>
          <IconChevronRight />
        </Box>
      </ListItem>
    );
  };

  const StudentRecordOptions = (sr: StudentRecord) => {
    return (
      <ListItem
        key={sr.id}
        onClick={() => handleSelectStudentRecord(sr, currentRecordStudent?.id! === sr.id)}
      >
        <ListItemIcon
          {...(currentRecordStudent?.id === sr.id && {
            style: {
              backgroundColor: theme.palette.secondary.light,
              color: theme.palette.primary.main,
              padding: 10,
              borderRadius: 50,
              marginLeft: -10,
              gap: 20,
            },
          })}
        >
          {sr.education_level.edu_name.toUpperCase().slice(0, 2)}
        </ListItemIcon>
        <ListItemText
          primary={
            <>
              <Typography
                variant="body1"
                fontWeight={700}
                {...(currentRecordStudent?.id === sr.id && {
                  sx: { color: 'primary.main' },
                })}
              >
                {sr.education_level.edu_name}
              </Typography>
            </>
          }
        />
      </ListItem>
    );
  };

  const renderRolesAdmin = (rol: Role) => {
    return (
      <ListItem key={rol.rolId} onClick={() => handleSelectRol(rol)}>
        <ListItemIcon
          {...(currentRol?.rolId === rol.rolId && {
            style: {
              backgroundColor: theme.palette.secondary.light,
              color: theme.palette.primary.main,
              padding: 10,
              borderRadius: 50,
              marginLeft: -10,
              gap: 20,
            },
          })}
        >
          {rol.name.toUpperCase().slice(0, 2)}
        </ListItemIcon>
        <ListItemText
          primary={
            <>
              <Typography
                variant="body1"
                fontWeight={700}
                {...(currentRol?.rolId! === rol.rolId && {
                  sx: { color: 'primary.main' },
                })}
              >
                {rol.name}
              </Typography>
              <Typography
                variant="body2"
                {...(currentRol?.rolId! === rol.rolId && {
                  sx: { color: 'primary.400' },
                })}
              >
                {rol.description}
              </Typography>
            </>
          }
        />
      </ListItem>
    );
  };

  const NavigateTeacherOrStudent = () => {
    /**
     * Navegar entre los records estudiantiles de un estudiante.
     * Mostrar el perfil de docente.
     */
    return (
      <PerfectScrollbar component={'div'}>
        <Grid container sx={{ maxHeight: '45vh', width: '100%' }}>
          <Grid item xs={12}>
            <ListItemIcon>
              <List component="nav">
                {!showData || currentRol?.name === ROL_TEACHER
                  ? profilesAvailables?.map(({ name, profileId: id, description, keyword }) =>
                      renderProfiles({ name, id, description, keyword })
                    )
                  : studentRecordReference && studentRecordReference.map(StudentRecordOptions)}
              </List>
            </ListItemIcon>
          </Grid>
        </Grid>
      </PerfectScrollbar>
    );
  };

  const NavigateAdmin = () => {
    // Permite navegar entre los módulo a un superadministrador
    return (
      <PerfectScrollbar component={'div'}>
        <Grid container sx={{ maxHeight: '45vh', gap: '20px', width: '100%' }}>
          <Grid item xs={12}>
            <List component="nav">
              {!showData
                ? profilesAvailables.map(({ name, profileId: id, description, keyword }) =>
                    renderProfilesAdmin({ name, id, description, keyword })
                  )
                : Object.keys(profiles[selectedProfile!].roles).map((rolKey) => {
                    const rol = profiles[selectedProfile!].roles[rolKey];
                    return renderRolesAdmin(rol);
                  })}
            </List>
          </Grid>
        </Grid>
      </PerfectScrollbar>
    );
  };

  const RenderOptionsInfo = () => {
    // Opciones generales de navegación, manejo de la navegacion entre los
    // módulos cargados.
    return (
      <Grid container sx={{ pt: 2 }}>
        {showData ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              cursor: 'pointer',
            }}
            onClick={() => setShowData(false)}
          >
            <IconArrowNarrowLeft size={20} />
            <Typography variant="h5" fontWeight={700} color={theme.palette.grey[600]}>
              {t('shared.header.menu.back')}
            </Typography>
          </Box>
        ) : (
          <Typography variant="h5" fontWeight={700} color={theme.palette.grey[600]}>
            {t('shared.header.menu.change_profile')}
          </Typography>
        )}
      </Grid>
    );
  };

  const renderOptionSimple = ({ description, id, name, keyword }: OptionsProps) => {
    return (
      <ListItem onClick={() => handleProfile(dispatch, keyword)} key={id}>
        <ListItemIcon
          {...(currentProfile?.profileId === id && {
            style: {
              backgroundColor: theme.palette.secondary.light,
              color: theme.palette.primary.main,
              padding: 10,
              borderRadius: 50,
              marginLeft: -10,
              gap: 20,
            },
          })}
        >
          {name.toUpperCase().slice(0, 2)}
        </ListItemIcon>
        <ListItemText
          primary={
            <>
              <Typography
                variant="body1"
                fontWeight={700}
                {...(currentProfile?.profileId === id && {
                  sx: { color: 'primary.main' },
                })}
              >
                {name}..
              </Typography>
              <Typography
                variant="body2"
                {...(currentProfile?.profileId === id && {
                  sx: { color: 'primary.200' },
                })}
              >
                {description}
              </Typography>
            </>
          }
        />
      </ListItem>
    );
  };

  const renderOptionWithSelect = ({ description, id, name, keyword }: OptionsProps) => {
    return (
      <ListItem
        key={id}
        onClick={() => {
          // handleSelectProfile(keyword);
          setShowData(true);
        }}
      >
        <ListItemIcon
          {...(currentProfile?.profileId === id && {
            style: {
              backgroundColor: theme.palette.secondary.light,
              color: theme.palette.primary.main,
              padding: 10,
              borderRadius: 50,
              marginLeft: -10,
              gap: 20,
            },
          })}
        >
          {name.toUpperCase().slice(0, 2)}
        </ListItemIcon>
        <ListItemText
          primary={
            <>
              <Typography
                variant="body1"
                fontWeight={700}
                {...(currentProfile?.profileId === id && {
                  sx: { color: 'primary.main' },
                })}
              >
                {name}
              </Typography>
              <Typography
                variant="body2"
                {...(currentProfile?.profileId === id && {
                  sx: { color: 'primary.200' },
                })}
              >
                {description}
              </Typography>
            </>
          }
        />
        <Box sx={{ px: 1 }}>
          <IconChevronRight />
        </Box>
      </ListItem>
    );
  };

  const renderProfiles = ({ description, id, name, keyword }: OptionsProps) => {
    // alwalys student or teacher
    return (
      <div key={name}>
        {keyword !== ROL_ESTUDENT.toLowerCase()
          ? renderOptionSimple({ description, id, name, keyword })
          : renderOptionWithSelect({ description, id, name, keyword })}
      </div>
    );
  };

  const renderNavigation = function () {
    if (currentRol?.name === ROL_ESTUDENT) {
      // || currentRol?.name === ROL_TEACHER) {
      return <NavigateTeacherOrStudent />;
    } else {
      return <NavigateAdmin />;
    }
  };

  return (
    <>
      <Grid
        component={MainCard}
        sx={{ padding: 2 }}
        aria-controls={open ? 'menu-list-grow' : undefined}
      >
        <Grid item xs={12} spacing={2}>
          <Grid item container alignItems="center" gap={1} sx={{ cursor: 'pointer' }}>
            <IconButton onClick={() => history.goBack()}>
              <IconArrowNarrowLeft size={30} color={theme.palette.text.dark} />
            </IconButton>
            <Typography variant="h1">Perfíl</Typography>
          </Grid>
        </Grid>
        <Grid xs={12} display="flex" justifyContent="center">
          <RenderAvatarMenu />
        </Grid>
        <Grid item sx={{ padding: 2 }} justifyContent="center">
          <Box sx={{ textAlign: 'center' }}>
            <Box sx={{ padding: 0.5 }}>
              <Typography variant="h5" fontWeight={700}>
                {userInfo?.fullname}
              </Typography>
            </Box>
            <Box>
              <Typography variant="h4" align="center">
                C.I: {userInfo.identification}
              </Typography>
            </Box>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item>
                <Typography variant="h5" fontWeight={600}>
                  Modifica tu fecha de cumpleaños
                </Typography>
              </Grid>
              <Grid item>
                <IconButton onClick={() => setOpenBirthday(true)}>
                  <IconGift size={20} />
                </IconButton>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid xs={12} display="flex" gap={2}>
          <Grid item xs={6}>
            <Grid display="flex" gap={2} spacing={2} direction="column">
              <RenderOptionsInfo />
              {renderNavigation()}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ChangesBirthday
        open={openBirthday}
        onClose={() => {
          setOpenBirthday(false);
        }}
      />
    </>
  );
};

export default MiProfile;
