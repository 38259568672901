import { useEffect } from 'react';
import { setGeneralApplicationList } from '../slices/GeneralApplicationsSlice';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { IconButton, Grid, useTheme, Typography } from '@material-ui/core';
import { IconArrowNarrowLeft } from '@tabler/icons';

import PreviewApplication from '../components/PreviewApplication';

import MainCardForm from '../../../shared/components/cards/MainCardForm';
// import { useAppSelector } from '../../../../hooks/useRedux';
import RejectAppDialog from '../components/RejectAppDialog';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { useLazyGetGeneralApplicationHomologationByIdQuery } from '../slices/GeneralApplicationsApiSlice';
//import HomologationComments from '../components/applicationDetails/HomologationComments';
// import { Redirect } from 'react-router-dom';

const PreviewIntHomologationScreen = () => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const { gAppToReject } = useAppSelector((state) => state.applications);

  // if (!Boolean(internalHomologationsList.length))
  //   return <Redirect to="/administrar-homologaciones" />;

  const { app } = useParams<{ app: string }>();
  const { generalApplicationList } = useAppSelector((state) => state.applications);

  const [getGeneralApplication, { isFetching }] =
    useLazyGetGeneralApplicationHomologationByIdQuery();

  useEffect(() => {
    if (!generalApplicationList.length && !isFetching) {
      getGeneralApplication({
        ApplicationHomId: +app,
      })
        .unwrap()
        .then((response) => {
          if (response) dispatch(setGeneralApplicationList([response!]));
        })
        .catch((error) => console.log(error));
    }
  }, [getGeneralApplication, isFetching, app, generalApplicationList.length, dispatch]);

  return (
    <MainCardForm>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid item container alignItems="center" gap={1} sx={{ cursor: 'pointer' }}>
            <IconButton onClick={() => history.push(`/${location.pathname.split('/')[1]}`)}>
              <IconArrowNarrowLeft size={30} color={theme.palette.text.dark} />
            </IconButton>
            <Typography variant="h2">Solicitud</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} lg={12} xl={12} sx={{ mt: 1 }}>
          <PreviewApplication />

          {gAppToReject && <RejectAppDialog />}
        </Grid>
        {/* <Grid item xs={12} md={4} lg={5} xl={6} sx={{ mt: 2 }}>
          <HomologationComments />
        </Grid> */}
      </Grid>
    </MainCardForm>
  );
};

export default PreviewIntHomologationScreen;
