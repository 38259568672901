import { IconButton, Tooltip } from '@material-ui/core';
import { AddCircleOutline } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

interface Props {
  btnText?: string;
  onClick: () => void;
}

const AddIconButton = ({ onClick, btnText, ...props }: Props) => {
  const { t } = useTranslation();

  return (
    <Tooltip title={btnText || t('shared.add_button.tooltip')}>
      <IconButton onClick={() => onClick()}>
        <AddCircleOutline />
        {/* {btnText || t('shared.add_button.tooltip')} */}
      </IconButton>
    </Tooltip>
  );
};

AddIconButton.defaultProps = {
  btnText: '',
};

export default AddIconButton;
