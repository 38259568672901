// catalogo FP
export const paymentMethods = {
  cash: 'forma-pago-efectivo',
  creditBalance: 'forma-pago-saldo-favor',
  earlyPayment: 'forma-pronto-pago',
  transfer: 'forma-pago-transferencia',
  deposit: 'forma-pago-deposito',
  creditCard: 'forma-pago-tarjeta-cred',
  debitCard: 'forma-pago-tarjeta-deb',
  cheque: 'forma-pago-cheque',
  web: 'forma-pago-web',
};

export const catalogsKeyword = {
  //FP
  payments: paymentMethods,
};

export const personfilters = [
  { name: 'ci', value: 'Cédula de identidad' },
  { name: 'firstname', value: 'Primer nombre' },
  { name: 'secondname', value: 'Segundo nombre' },
  { name: 'first_lastname', value: 'Primer apellido' },
  { name: 'second_lastname', value: 'Segundo apellido' },
];
