import DeleteAlert from '../../../shared/components/dialogs/DeleteAlert';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { useDeletePositionMutation } from '../slices/PositionsApiSlice';
import { resetPositionIdToDelete, setDeleteModalOpen } from '../slices/PositionsSlice';

const DeletePositionDialog = () => {
  const dispatch = useAppDispatch();

  const { deleteModalOpen, positionIdToDelete } = useAppSelector((state) => state.positions);
  const { currentProfile } = useAppSelector((state) => state.access);

  const [deletePosition, { isLoading, isSuccess, isError, error }] = useDeletePositionMutation();

  const handleDelete = () => {
    deletePosition({ profileId: currentProfile?.profileId!, positionId: positionIdToDelete! });
  };

  const handleClose = () => {
    dispatch(setDeleteModalOpen(false));
    dispatch(resetPositionIdToDelete());
  };

  return (
    <DeleteAlert
      open={deleteModalOpen}
      onClose={handleClose}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      handleDelete={handleDelete}
      error={error as string}
    />
  );
};

export default DeletePositionDialog;
