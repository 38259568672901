import { Autocomplete, Grid, IconButton, TextField, Tooltip } from '@material-ui/core';
import { IconEye } from '@tabler/icons';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/useRedux';
import { useTableSearch } from '../../../../../../hooks/useTableSearch';
// import LocalizedDatePicker from '../../../../../shared/components/controlled/LocalizedDatePicker';
import AddButton from '../../../../../shared/components/tables/buttons/AddButton';
import EditButton from '../../../../../shared/components/tables/buttons/EditButton';
import SearchPaginatedTableAndCollapsibleItems from '../../../../../shared/components/tables/SearchPaginatedTableAndCollapsibleItems';
import TextFieldFetching from '../../../../../shared/components/TextFieldFetching';
import { useGetAllEvaluationTypesQuery } from '../../evaluation_type/slices/evaluationTypeApiSlice';
import { useGetTemplateEvaListQuery } from '../slices/evaTemplatesApiSlice';
import {
  setEvaTypeIdSelected,
  setTemplatesList,
  // setEnd_date, setStart_date,
  setTemplateTable,
} from '../slices/evaTemplateSlice';

const TemplateTable = () => {
  const dispatch = useAppDispatch();
  const navigate = useHistory();

  const {
    templateTable: { page, perPage: size, search },
    // end_date,
    // start_date,
    evaTypeIdSelected,
  } = useAppSelector((state) => state.templateEva);

  const { t } = useTranslation();

  const { setPage, setPerPage, setSearch } = useTableSearch({
    page,
    perPage: size,
    search,
    updateFunction: setTemplateTable,
  });

  const {
    data: templateList,
    isFetching,
    isLoading,
    error,
  } = useGetTemplateEvaListQuery({
    page,
    perPage: size,
    search,
    ...(evaTypeIdSelected && { eva_type_id: evaTypeIdSelected }),
    // start_date: start_date.split('T')[0],
    // end_date: end_date.split('T')[0],
  });

  const { data: evaluationTypes, isLoading: isLoadingET } = useGetAllEvaluationTypesQuery({});

  const dataFetch =
    templateList?.data.map(
      ({ name, description, eva_types, point_end, eva_evaluations_template, id }, index) => ({
        numberRow: (page - 1) * size + (index + 1),
        name,
        description,
        typeEva: eva_types.name,
        typeEvaAcronym: eva_types.acronym,
        points: Number(point_end).toFixed(2),
        escalaName: eva_evaluations_template.description,
        options: {
          id,
        },
      })
    ) || [];

  useEffect(() => {
    if (templateList && templateList?.data.length > 0) {
      dispatch(setTemplatesList(templateList?.data));
    } else {
      dispatch(setTemplatesList([]));
    }
  }, [templateList, dispatch]);

  const goToAdd = () => {
    navigate.push('/plantilla/agregar');
  };

  const handlePreview = (id: number) => {
    navigate.push(`/plantilla/${id}/previsualizar`);
  };

  const handleEditTemplate = (id: number) => {
    navigate.push(`/plantilla/${id}/editar`);
  };

  return (
    <>
      {/* <Grid container item xs={6} spacing={2}>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={6}
          sx={{
            mb: 2,
          }}
        >
          <Grid container spacing={1} alignItems="flex-start">
            <Grid item xs zeroMinWidth>
              <LocalizedDatePicker
                value={start_date}
                onChange={(newValue) => {
                  dispatch(setStart_date((newValue as Date).toISOString()));
                }}
                label={t('financial.deposit_transfers.filters.dates.start_label')}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Grid container spacing={1} alignItems="flex-start">
            <Grid item xs zeroMinWidth>
              <LocalizedDatePicker
                value={end_date}
                onChange={(newValue) => {
                  dispatch(setEnd_date((newValue as Date).toISOString()));
                }}
                label={t('financial.deposit_transfers.filters.dates.end_label')}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid> */}
      <Grid container spacing={2} my={2}>
        <Grid item xs={12} md={4}>
          {isLoadingET && <TextFieldFetching />}
          {!isLoadingET && (
            <Autocomplete
              options={evaluationTypes || []}
              getOptionLabel={(option) => option.name || option.description || '-'}
              renderInput={(params) => (
                <TextField {...params} label={t('Rúbrica')} variant="outlined" />
              )}
              value={evaluationTypes?.find((item) => item.uuid === evaTypeIdSelected) || null}
              onChange={(event, newValue) => {
                dispatch(setEvaTypeIdSelected(newValue?.uuid || null));
              }}
            />
          )}
        </Grid>
      </Grid>

      <SearchPaginatedTableAndCollapsibleItems
        data={dataFetch}
        headers={{
          numberRow: t('N°'),
          name: t('Nombre'),
          description: t('Descripción'),
          typeEva: t('Rúbrica'),
          typeEvaAcronym: t('Abreviatura'),
          points: t('Puntos'),
          escalaName: t('Escala'),
          options: t('Opciones'),
        }}
        keyExtractor={(item) => `${item.numberRow} - ${item.name}`}
        searchPlacehoder={t('Buscar formato de rúbrica...')}
        isLoading={isLoading}
        isFetching={isFetching}
        error={error}
        perPage={size}
        setPerPage={setPerPage}
        total={templateList?.total || 0}
        page={page}
        setPage={setPage}
        numHeader={6}
        search={search}
        setSearch={setSearch}
        ActionButtons={<AddButton size="large" onClick={goToAdd} />}
        customDataCellsProperties={{
          options: {
            align: 'center',
          },
        }}
        customRenderers={{
          options: (item) => (
            <>
              <Tooltip title={t('Ver formato de rúbrica')}>
                <IconButton onClick={() => handlePreview(item.options.id)}>
                  <IconEye />
                </IconButton>
              </Tooltip>

              <EditButton handleEdit={() => handleEditTemplate(item.options.id)} />
            </>
          ),
        }}
      />
    </>
  );
};

export default TemplateTable;
