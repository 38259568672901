import { mainApi } from '../../../../api/mainApi';
import { middlewareApi } from '../../../middleware/middlewareApi';
import { AccessState, AuthState } from '../../../../types';
import { StudentRecordPeriod } from '../interfaces/studentRecordsPeriods.interface';
import { SubjectByPeriodPayload, SubjectsByPeriod } from '../interfaces/subjectsByPeriods';
import { setPortfolioAcademicMatters, setForums } from './academicPortfolioSlice';
import {
  academicPortfolioMaterials,
  AcademicPortfolioMatterDetailMaterial,
  AcademicPortfolioMatterDetailNote,
  academicPortfolioMatterDetailNotes,
  AcademicPortfolioPartners,
} from '../interfaces/AcademicPortfolio.interfaces';
import { AnswerForum, ForumResponse } from '../../../shared/interfaces/forum.interfaces';

export const academicPortfolioTags = middlewareApi.enhanceEndpoints({
  addTagTypes: [
    'StudentRecorsPeriods',
    'academicPortfolioPartner',
    'academicPortfolioMatterDetailNote',
    'academicPortfolioMatterDetailMaterials',
  ],
});
export const academicPortfolioApi = academicPortfolioTags.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * @GET obtiene array de periodos en cuales el estudiante se ha registrado
     */
    getStudentRecordPeriods: builder.query<StudentRecordPeriod[], { user_profile_id: number }>({
      queryFn: async ({ user_profile_id }, { getState }) => {
        try {
          const { auth } = getState() as { access: AccessState; auth: AuthState };

          // El usuario autenticado debe ser un estudiante
          if (auth.userInfo && auth.userInfo.studentInfo) {
            const { studentRecordId } = auth.userInfo.studentInfo;

            const { data } = await mainApi.get<StudentRecordPeriod[]>(
              `/students/student-records/${studentRecordId}/student-records-period`,
              {
                params: {
                  user_profile_id,
                },
              }
            );
            return { data: Array.isArray(data) ? data : [] };
          } else {
            return { error: 'No se encontró el estudiante' };
          }
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'StudentRecorsPeriods', id } as const)),
              { type: 'StudentRecorsPeriods', id: 'LIST' },
            ]
          : [{ type: 'StudentRecorsPeriods', id: 'LIST' }],
    }),

    getSubjectByPeriods: builder.query<SubjectsByPeriod[], SubjectByPeriodPayload>({
      queryFn: async (SubjectByPeriodPayload, { getState, dispatch }) => {
        try {
          const { auth } = getState() as { access: AccessState; auth: AuthState };

          // El usuario autenticado debe ser un estudiante
          if (auth.userInfo && auth.userInfo.studentInfo) {
            const { studentRecordId } = auth.userInfo.studentInfo;

            const { data: dataFixed } = await mainApi.get<SubjectsByPeriod[]>(
              `/students/course-students/${studentRecordId}/period/${SubjectByPeriodPayload.periodId}`,
              {
                params: {
                  ...SubjectByPeriodPayload,
                },
              }
            );
            const data = Array.isArray(dataFixed) ? dataFixed : [];
            dispatch(setPortfolioAcademicMatters(data));

            return { data };
          } else {
            return { error: 'No se encontraron períodos' };
          }
        } catch (error: any) {
          return { error };
        }
      },
    }),

    /**
     * @GET protfolio partners
     */
    getAcademicPortfolioPartnert: builder.query<
      AcademicPortfolioPartners[],
      { course: number; profileId: number }
    >({
      queryFn: async ({ course, profileId }, { dispatch }) => {
        try {
          const { data } = await mainApi.get<AcademicPortfolioPartners[]>(
            `/course/${course}/students`,
            {
              params: {
                user_profile_id: profileId,
              },
            }
          );

          //dispatch(setHistoryCalification(data!));
          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'academicPortfolioPartner', id } as const)),
              { type: 'academicPortfolioPartner', id: 'LIST' },
            ]
          : [{ type: 'academicPortfolioPartner', id: 'LIST' }],
    }),

    /**
     * @GET portfolio matter detail note
     */
    getAcademicPortfolioMatterDetailNote: builder.query<AcademicPortfolioMatterDetailNote[], void>({
      queryFn: async (_, { dispatch }) => {
        try {
          //   const { data } = await mainApi.get<[]>(
          //     `/students/${student}/education-levels/${educationLevel}/course-students`,
          //     {
          //       params: {
          //         user_profile_id: profileId,
          //       },
          //     }
          //   );

          //dispatch(setHistoryCalification(data!));
          const data = academicPortfolioMatterDetailNotes;
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(
                ({ id }) => ({ type: 'academicPortfolioMatterDetailNote', id } as const)
              ),
              { type: 'academicPortfolioMatterDetailNote', id: 'LIST' },
            ]
          : [{ type: 'academicPortfolioMatterDetailNote', id: 'LIST' }],
    }),
    /**
     * @GET portfolio matter detail note
     */
    getAcademicPorfolioMatterDetailMaterials: builder.query<
      AcademicPortfolioMatterDetailMaterial[],
      void
    >({
      queryFn: async (_, { dispatch }) => {
        try {
          //   const { data } = await mainApi.get<[]>(
          //     `/students/${student}/education-levels/${educationLevel}/course-students`,
          //     {
          //       params: {
          //         user_profile_id: profileId,
          //       },
          //     }
          //   );

          //dispatch(setHistoryCalification(data!));
          const data = academicPortfolioMaterials;
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(
                ({ id }) => ({ type: 'academicPortfolioMatterDetailMaterials', id } as const)
              ),
              { type: 'academicPortfolioMatterDetailMaterials', id: 'LIST' },
            ]
          : [{ type: 'academicPortfolioMatterDetailMaterials', id: 'LIST' }],
    }),

    /**
     * @GET forums
     */
    getForums: builder.query<ForumResponse, { course: number; profileId: number }>({
      queryFn: async ({ course, profileId }, { dispatch }) => {
        try {
          const { data } = await mainApi.get<ForumResponse>(`/courses/${course}/forums`, {
            params: {
              user_profile_id: profileId,
            },
          });
          dispatch(setForums(data?.data!));
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    /**
     * @GET forum comments
     */
    getForumComments: builder.query<
      AnswerForum,
      { course: number; forumId: number; profileId: number }
    >({
      queryFn: async ({ course, forumId, profileId }, { dispatch }) => {
        try {
          const { data } = await mainApi.get<AnswerForum>(`/courses/${course}/forums/${forumId}`, {
            params: {
              user_profile_id: profileId,
            },
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    getStatusSyllabus: builder.query<
      any,
      { course: number | string; matter: number | string; profileId?: number | string }
    >({
      queryFn: async ({ course, matter, profileId }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = profileId || currentProfile?.profileId;

          const { data } = await mainApi.get<any>(`/getStatusSyllabus/${course}/${matter}`, {
            responseType: 'arraybuffer',
            params: {
              user_profile_id,
            },
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),
  }),
});

export const {
  useGetStudentRecordPeriodsQuery,
  useGetSubjectByPeriodsQuery,
  useGetAcademicPortfolioPartnertQuery,
  useGetAcademicPortfolioMatterDetailNoteQuery,
  useGetAcademicPorfolioMatterDetailMaterialsQuery,
  useGetForumsQuery,
  useGetForumCommentsQuery,
  useLazyGetSubjectByPeriodsQuery,
  useGetStatusSyllabusQuery,
} = academicPortfolioApi;
