import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import {
  Autocomplete,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
  InputAdornment,
  Switch,
} from '@material-ui/core';

import { useFormik } from 'formik';

import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';

import { useAppSelector } from '../../../../hooks/useRedux';
import { ValidationSchema } from '../constants/validationSchemas';

import { useUpdateEducationLevelMutation } from '../slices/educationLevelsApiSlice';

import { statusAvailables } from '../../../shared/constants/resourceStatus';
import { useFormStyles } from '../constants/styles';
import {
  useGetAvailablesOffersQuery,
  useGetInfoOfferQuery,
} from '../../academic-offerings/slices/academicOfferingsApiSlice';
import TextFieldFetching from '../../../shared/components/TextFieldFetching';

const EditEducationLevelForm = () => {
  const history = useHistory();
  const { educationLevelId } = useParams<{ educationLevelId: string }>();

  const { educationLevelList } = useAppSelector((state) => state.educationLevels);
  const { currentProfile } = useAppSelector((state) => state.access);

  const classes = useFormStyles();

  const [showAlerts, setShowAlerts] = useState(false);
  const [valueUnidadAcademica, setValueUnidadAcademica] = useState('el Nivel Educativo');

  const [updateEducationLevel, { isLoading: isUpdating, isSuccess, isError, error }] =
    useUpdateEducationLevelMutation();

  const { data: offersAvailables = [], isLoading: isLoadingOffers } = useGetAvailablesOffersQuery({
    profileId: currentProfile?.profileId!,
    off_description: 'oferta_niv',
  });

  const educationLevelToEdit = educationLevelList.find((p) => p.id === Number(educationLevelId));

  const { getFieldProps, errors, touched, handleSubmit, setFieldValue, values } = useFormik({
    initialValues: {
      edu_name: educationLevelToEdit?.edu_name || '',
      edu_alias: educationLevelToEdit?.edu_alias || '',
      principal_id: educationLevelToEdit?.principal_id || '0',
      offer_id: educationLevelToEdit?.offer_id || -1,
      status_id: educationLevelToEdit?.status_id || -1,
      checked: educationLevelToEdit?.principal_id ? true : false,
      collectives: [],
      show_in_admissions: Number(educationLevelToEdit?.show_in_admissions) || false,
    },
    validationSchema: ValidationSchema,
    validate: (values) => {
      const errors: any = {};
      const principalSelected = values.principal_id.toString();
      if (principalSelected === educationLevelId) {
        errors.principal_id = 'La unidad académica principal no puede asignarse a sí misma.';
      }
      return errors;
    },
    onSubmit: async (values) => {
      await updateEducationLevel({
        profileId: currentProfile?.profileId!,
        educationLevelId: educationLevelToEdit?.id!,
        educationLevelPayload: {
          edu_name: values.edu_name,
          edu_alias: values.edu_alias,
          principal_id:
            values.principal_id.toString() === '0' ? null : values.principal_id.toString(),
          offer_id: values.offer_id.toString(),
          status_id: values.status_id.toString(),
          show_in_admissions: values.show_in_admissions ? 1 : 0,
        },
      });
      setShowAlerts(true);
    },
  });

  const checked = values.checked;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, field: string = 'checked') => {
    setFieldValue(field, event.target.checked);
    if (checked && field === 'checked') {
      setFieldValue('principal_id', '');
    }
  };

  useEffect(() => {
    if (checked) {
      setValueUnidadAcademica('de la carrera');
    } else {
      setValueUnidadAcademica('el Nivel Educativo');
    }
  }, [checked]);

  const {
    data: offerInfo,
    isLoading: isLoadingOfferInfo,
    isSuccess: isSuccesLoadingInfo,
    isFetching: isFetchingInfo,
  } = useGetInfoOfferQuery(
    {
      profileId: currentProfile?.profileId!,
      offerId: values.offer_id!.toString(),
      filter: true,
    },
    { skip: !checked }
  );

  return (
    <form onSubmit={handleSubmit}>
      <Grid container gap={2}>
        <Grid item xs={12}>
          {isError && showAlerts && (
            <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
          )}

          {isSuccess && showAlerts && (
            <SuccessAlert
              message={`La ${
                checked ? 'carrera' : 'unidad académica'
              } se actualizado correctamente.`}
              handleDismiss={() => setShowAlerts(false)}
            />
          )}
        </Grid>
        <Grid item container spacing={2} alignItems="center">
          <Grid xs={12} item container spacing={2}>
            <Grid item xs={12} md={8}>
              <InputLabel className={classes.inputLabel}>Nivel Educativo</InputLabel>
              {isLoadingOffers ? (
                <TextFieldFetching />
              ) : (
                <Autocomplete
                  options={offersAvailables}
                  loading={isLoadingOffers}
                  loadingText="Cargando ofertas académicas disponibles..."
                  getOptionLabel={(option) => option.off_name}
                  renderInput={(params) => (
                    <TextField {...params} error={Boolean(errors.offer_id && touched.offer_id)} />
                  )}
                  defaultValue={
                    offersAvailables.find((p) => p.id === Number(educationLevelToEdit?.offer_id)) ||
                    offersAvailables[0]
                  }
                  onChange={(e, value) => setFieldValue('offer_id', value?.id || '')}
                  fullWidth
                  clearIcon={null}
                />
              )}
              {errors.offer_id && touched.offer_id && (
                <FormHelperText error>{errors.offer_id}</FormHelperText>
              )}
            </Grid>
            <Grid item xs={12} md={4}>
              <InputLabel className={classes.inputLabel}>Agregar una carrera</InputLabel>
              <Switch
                checked={checked}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </Grid>
            {checked && (
              <Grid item xs={12}>
                {isSuccesLoadingInfo && !isFetchingInfo && (
                  <>
                    <InputLabel className={classes.inputLabel}>Unidad Académica</InputLabel>
                    <Autocomplete
                      options={offerInfo || []}
                      loading={isLoadingOfferInfo}
                      getOptionSelected={(option, value) => option.id === value.id}
                      loadingText="Cargando principales  disponibles..."
                      getOptionLabel={(option) => option.edu_name}
                      defaultValue={offerInfo!.find(
                        (p) => p.id === Number(educationLevelToEdit?.principal_id)
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={Boolean(errors.principal_id && touched.principal_id)}
                        />
                      )}
                      onChange={(e, value) => setFieldValue('principal_id', value?.id || 0)}
                      fullWidth
                      clearIcon={null}
                    />
                    {errors.principal_id && touched.principal_id && (
                      <FormHelperText error>{errors.principal_id}</FormHelperText>
                    )}
                  </>
                )}
                {isFetchingInfo && (
                  <>
                    <InputLabel className={classes.inputLabel}>Unidad Académica</InputLabel>
                    <TextField
                      fullWidth
                      disabled
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <CircularProgress size={25} />
                          </InputAdornment>
                        ),
                      }}
                      id="input-with-icon-textfield"
                      error={Boolean(errors.principal_id && touched.principal_id)}
                      placeholder="Cargando datos ..."
                    />

                    {errors.principal_id && touched.principal_id && (
                      <FormHelperText error>{errors.principal_id}</FormHelperText>
                    )}
                  </>
                )}
                {!isLoadingOfferInfo && !isSuccesLoadingInfo && (
                  <>
                    <InputLabel className={classes.inputLabel}>Unidad Académica</InputLabel>
                    <TextField
                      fullWidth
                      disabled
                      error={Boolean(errors.principal_id && touched.principal_id)}
                      placeholder="No hay datos que mostrar"
                    />

                    {errors.principal_id && touched.principal_id && (
                      <FormHelperText error>{errors.principal_id}</FormHelperText>
                    )}
                  </>
                )}
              </Grid>
            )}
          </Grid>

          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>Nombre {valueUnidadAcademica}</InputLabel>
            <TextField
              fullWidth
              error={Boolean(errors.edu_name && touched.edu_name)}
              placeholder={`Ingrese un nombre ${valueUnidadAcademica}`}
              {...getFieldProps('edu_name')}
            />
            {errors.edu_name && touched.edu_name && (
              <FormHelperText error>{errors.edu_name}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>Alias {valueUnidadAcademica}</InputLabel>
            <TextField
              fullWidth
              error={Boolean(errors.edu_alias && touched.edu_alias)}
              placeholder={`Ingrese un alias ${valueUnidadAcademica}`}
              {...getFieldProps('edu_alias')}
            />
            {errors.edu_alias && touched.edu_alias && (
              <FormHelperText error>{errors.edu_alias}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>
              Mostar disponibilidad de carrera en el sistema de admisiones
            </InputLabel>

            <Switch
              checked={!!values.show_in_admissions}
              onChange={(e) => handleChange(e, 'show_in_admissions')}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </Grid>

          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>Estado</InputLabel>
            <Autocomplete
              options={statusAvailables}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField {...params} error={Boolean(errors.status_id && touched.status_id)} />
              )}
              defaultValue={
                statusAvailables.find((p) => p.id === Number(educationLevelToEdit?.status_id)) ||
                statusAvailables[0]
              }
              onChange={(e, value) => setFieldValue('status_id', value?.id || '')}
              fullWidth
              clearIcon={null}
            />
            {errors.status_id && touched.status_id && (
              <FormHelperText error>{errors.status_id}</FormHelperText>
            )}
          </Grid>
        </Grid>
        <Grid
          item
          container
          alignItems="center"
          justifyContent="flex-end"
          spacing={2}
          sx={{ mt: 1 }}
        >
          <Grid item>
            <Button
              variant="outlined"
              size="large"
              color="primary"
              onClick={() => history.goBack()}
            >
              Regresar
            </Button>
          </Grid>
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              disabled={isUpdating}
              endIcon={isUpdating && <CircularProgress size={20} />}
            >
              Actualizar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default EditEducationLevelForm;
