import { Grid, IconButton, Typography, useTheme } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { IconArrowNarrowLeft } from '@tabler/icons';
import MainCardForm from '../../../shared/components/cards/MainCardForm';
import EditHoursSummariesForm from '../components/EditHoursSummariesForm';

const EditHoursScreen = () => {
  const theme = useTheme();
  const history = useHistory();

  return (
    <MainCardForm>
      <Grid item xs={12}>
        <Grid item container alignItems="center" gap={1} sx={{ cursor: 'pointer' }}>
          <IconButton onClick={() => history.goBack()}>
            <IconArrowNarrowLeft size={30} color={theme.palette.text.dark} />
          </IconButton>
          <Typography variant="h2">Editar</Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} md={8} lg={6} sx={{ mt: 2 }}>
        <EditHoursSummariesForm />
      </Grid>
    </MainCardForm>
  );
};

export default EditHoursScreen;
