import {
    Grid, Typography
} from '@material-ui/core';

import { useTranslation } from 'react-i18next';

import MainCard from '../../../shared/components/cards/MainCard';
import AddForm from '../components/AddForm';

const MaintenanceProductAdd = () => {
    const { t } = useTranslation();

    return (
        <Grid component={MainCard} sx={{ padding: 2 }}>
            <Grid sx={{ mb: 3 }}>
                <Typography variant="h2">{t('financial.maintenance_prod.add.title')}</Typography>
            </Grid>

            <AddForm />
        </Grid>
    );
}

export default MaintenanceProductAdd;