import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISearchTable } from '../../../shared/interfaces/paginatedTable.interface';
import { Curriculum } from '../interfaces/curriculums.interfaces';

interface PeriodsState {
  curriculumList: Curriculum[];

  curriculumSelected?: number;
  subjectCurriculumSelected?: number;
  subjectInternalHomologateSelected?: number;

  deleteCurriculumDialogOpened: boolean;
  deleteSubjectCurriculumDialogOpened: boolean;
  deleteSubjectInternalHomologateDialogOpened: boolean;

  currentGroup?: number;
  assignSubjectCurriculumDialogOpen: boolean;

  subjectHoverId?: number;

  publishCurriculumDialogOpen: boolean;
  curriculumTable: ISearchTable;

  simbologyIdSelected: number | null;

  curriculumViewMode: 'table' | 'grid';

  addEducationLevelDialogOpen: boolean;
}

const initialState: PeriodsState = {
  curriculumList: [],
  deleteCurriculumDialogOpened: false,
  deleteSubjectCurriculumDialogOpened: false,
  deleteSubjectInternalHomologateDialogOpened: false,
  assignSubjectCurriculumDialogOpen: false,
  publishCurriculumDialogOpen: false,
  curriculumTable: {
    page: 1,
    perPage: 5,
    search: '',
  },
  curriculumViewMode: 'table',
  addEducationLevelDialogOpen: false,


  simbologyIdSelected: null
};

const meshSlice = createSlice({
  name: 'periods',
  initialState,
  reducers: {
    setCurriculumList: (state, action: PayloadAction<Curriculum[]>) => {
      state.curriculumList = action.payload;
    },

    // resources selected
    setCurriculumSelected: (state, action: PayloadAction<number>) => {
      state.curriculumSelected = action.payload;
    },
    resetCurriculumSelected: (state) => {
      state.curriculumSelected = undefined;
    },
    setSubjectCurriculumSelected: (state, action: PayloadAction<number>) => {
      state.subjectCurriculumSelected = action.payload;
    },
    resetSubjectCurriculumSelected: (state) => {
      state.subjectCurriculumSelected = undefined;
    },

    // curriculum
    fireDeleteCurriculumDialog: (state, action: PayloadAction<boolean>) => {
      state.deleteCurriculumDialogOpened = action.payload;
    },
    fireDeleteSubjectCurriculumDialog: (state, action: PayloadAction<boolean>) => {
      state.deleteSubjectCurriculumDialogOpened = action.payload;
    },

    // homologate internal subjects

    fireDeleteSubjectInternalHomologateDialog: (state, action: PayloadAction<boolean>) => {
      state.deleteSubjectInternalHomologateDialogOpened = action.payload;
    },

    //--- add homologate subject interna
    resetSubjectInternalHomologateSelected: (state) => {
      state.subjectInternalHomologateSelected = undefined;
    },
    setSubjectInternalHomologateSelected: (state, action: PayloadAction<number>) => {
      state.subjectInternalHomologateSelected = action.payload;
    },

    // add subjects
    fireAssignSubjectCurriculumDialog: (state, action: PayloadAction<boolean>) => {
      state.assignSubjectCurriculumDialogOpen = action.payload;
    },
    resetCurrentGroup: (state) => {
      state.currentGroup = undefined;
    },

    // group selected
    setCurrentGroup: (state, action: PayloadAction<number>) => {
      state.currentGroup = action.payload;
    },

    // prerequisites hover
    setSubjectHoverId: (state, action: PayloadAction<number>) => {
      state.subjectHoverId = action.payload;
    },
    resetSubjectHoverId: (state) => {
      state.subjectHoverId = undefined;
    },

    // publish
    firePublishCurriculumDialog: (state, action: PayloadAction<boolean>) => {
      state.publishCurriculumDialogOpen = action.payload;
    },

    updateCurriculumTable: (state, action: PayloadAction<ISearchTable>) => {
      state.curriculumTable = action.payload;
    },
    setCurriculumViewMode: (state, action: PayloadAction<'table' | 'grid'>) => {
      state.curriculumViewMode = action.payload;
    },
    setAddEducationLevelDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.addEducationLevelDialogOpen = action.payload;
    },

    setSimbologyIdSelected: (state, action: PayloadAction<number | null >) => {
      state.simbologyIdSelected = action.payload;
    }, 

  },
});

export const {
  setCurriculumList,

  fireAssignSubjectCurriculumDialog,
  fireDeleteCurriculumDialog,
  fireDeleteSubjectCurriculumDialog,

  setCurriculumSelected,
  resetCurriculumSelected,

  setSubjectCurriculumSelected,
  resetSubjectCurriculumSelected,

  setCurrentGroup,
  resetCurrentGroup,
  setSubjectHoverId,
  resetSubjectHoverId,

  firePublishCurriculumDialog,

  fireDeleteSubjectInternalHomologateDialog,
  resetSubjectInternalHomologateSelected,
  setSubjectInternalHomologateSelected,
  updateCurriculumTable,

  setCurriculumViewMode,
  setAddEducationLevelDialogOpen,

  setSimbologyIdSelected
} = meshSlice.actions;

export default meshSlice.reducer;
