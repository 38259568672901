import { useEffect, useState } from 'react';

import { useFormikContext } from 'formik';
import { Autocomplete, Button, FormHelperText, Grid, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import {
  useGetAvailableStagesQuery,
  useGetAvailiablesPeriodsQuery,
  useGetPeriodCalificationModelCoursesQuery,
  useGetPeriodCalificationModelsQuery,
} from '../../../periods/slices/periodsApiSlice';
import { AssignQualiCoursesFormValues } from '../AssignQualiCoursesForm';
import InputLabel from '../../../../shared/components/forms/InputLabel';
import TextFieldFetching from '../../../../shared/components/TextFieldFetching';
import { useGetCoursesQuery } from '../../../courses/slices/courseApiSlice';
import CustomAsyncPaginatedSelect from '../../../../shared/components/extended/Form/CustomAsyncPaginatedSelect';
import { useLazyGetCollaboratorsQuery } from '../../../../hr/collaborators/slices/collaboratorsApiSlice';
import { CustomChilds } from '../../../../shared/interfaces/material-ui.interfaces';
import { Collaborator } from '../../../../hr/collaborators/interfaces/collaborators.interfaces';
import PortalAction from '../../../../shared/components/PortalAction';
import useSharedGuard from '../../../../../hooks/useSharedGuard';
import { subPermissionKeys } from '../../constants/permissions';

interface Props {
  modelAcronym: string;
}

const FiltersQualificationCourses = ({ modelAcronym }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { values, errors, touched, setFieldValue } =
    useFormikContext<AssignQualiCoursesFormValues>();

  const { hasAccess } = useSharedGuard(subPermissionKeys);

  const [collaboratorValue, setCollaboratorValue] = useState<CustomChilds<
    Collaborator,
    { id: number; label: string }
  > | null>(null);

  const {
    data: periodAvailables,
    isLoading: isLoadingPeriodsAvailables,
    isFetching: isFetchingPeriodsAvailables,
  } = useGetAvailiablesPeriodsQuery({});

  const {
    data: modelsInfo,
    isLoading: isLoadingModelsInfo,
    isFetching: isFetchingModelInfo,
  } = useGetPeriodCalificationModelsQuery(
    {
      periodId: values.period_id,
    },
    { skip: !values.period_id }
  );
  const {
    data: modelsCoursesInfo,
    isLoading: isLoadingModelsCoursesInfo,
    isFetching: isFetchingModelsCoursesInfo,
  } = useGetPeriodCalificationModelCoursesQuery(
    {
      periodId: values.period_id,
    },
    { skip: !values.period_id }
  );

  const {
    data: stagesAvailables,
    isLoading: isLoadingStagesAvailables,
    isFetching: isFetchingStagesAvailables,
  } = useGetAvailableStagesQuery({});

  useEffect(() => {
    if (stagesAvailables) {
      setFieldValue(
        'stageInfo',
        stagesAvailables.find((stg) => stg.stg_acronym === modelAcronym)
      );
    }
  }, [stagesAvailables, setFieldValue, modelAcronym]);

  const {
    data: coursesList,
    isLoading: isLoadingCourses,
    isFetching: isFetchingCourses,
  } = useGetCoursesQuery({
    periodId: values.period_id,
  });

  const [
    getCollaboratorTrigger,
    // { data: institutesAvailables, isLoading: isLoadingInstitutesAvailables },
  ] = useLazyGetCollaboratorsQuery();

  //TODO: crear hook en el componente del select
  const getCollaborators = async (search: string, page: number, perPage: number) => {
    const response = await getCollaboratorTrigger({
      page,
      perPage,
      search,
      statusId: 1,
    }).unwrap();

    const hasMore = response.current_page < response.last_page;

    return {
      options: response.data.map((dt) => {
        return {
          value: dt.id,
          label: dt.user.person.pers_full_name || dt.user.person.pers_full_lastname,
          ...dt,
        };
      }),
      hasMore,
    };
  };

  const resetCoursesSelection = () => {
    setFieldValue('courses', []);
  };

  const goToPeriods = () => {
    history.push(`administrar-periodos/${values.period_id}/permisos-calificaciones`);
  };

  return (
    <>
      <Grid container spacing={3}>
        {hasAccess('create') && !!values.period_id && (
          <PortalAction containerId="add-stage-portal">
            <Button variant="contained" onClick={goToPeriods}>
              Agregar
            </Button>
          </PortalAction>
        )}

        {hasAccess('readPeriods') && (
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <InputLabel>{t('Periodos')}:</InputLabel>
            {isFetchingPeriodsAvailables ? (
              <TextFieldFetching />
            ) : (
              <Autocomplete
                options={periodAvailables || []}
                loading={isLoadingPeriodsAvailables}
                loadingText={t('Cargando periodos...')}
                getOptionLabel={(option) => option.per_name}
                // value={values.}
                renderInput={(params) => (
                  <TextField {...params} error={Boolean(errors.stageInfo && touched.stageInfo)} />
                )}
                onChange={(_, value) => {
                  setFieldValue('period_id', value?.id || 0);
                }}
                fullWidth
                clearIcon={null}
                noOptionsText={t('Sin datos')}
              />
            )}
            {/* {errors.stageInfo && touched.stageInfo && (
                  <FormHelperText error>{t(errors.stageInfo)}</FormHelperText>
                )} */}
          </Grid>
        )}

        {hasAccess('readModels') && (
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <InputLabel>{t('academic.periods_list.stages.add.calification_model')}:</InputLabel>

            {isLoadingModelsInfo || isLoadingModelsCoursesInfo || isLoadingStagesAvailables ? (
              <TextFieldFetching />
            ) : (
              <Autocomplete
                options={modelsInfo || []}
                loading={
                  isFetchingModelInfo || isFetchingModelsCoursesInfo || isFetchingStagesAvailables
                }
                loadingText={t('academic.periods_list.stages.add.calification_model.loading')}
                getOptionLabel={(option) => option.cal_mod_name || 'N/A'}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(errors.calificationModelId && touched.calificationModelId)}
                  />
                )}
                onChange={(_, value) => {
                  setFieldValue('calificationModelId', value?.id || '');

                  setFieldValue('modelInfo', value || null);

                  const modelCourses =
                    modelsCoursesInfo?.course_data_information.find(
                      (subI) => Number(subI.qualification_model_id) === Number(value?.id)
                    ) || null;

                  setFieldValue('modelCourses', modelCourses);

                  if (value?.id) {
                    setFieldValue('calificationModelDates', {
                      calification_model_id: value?.id,
                      distributions: value?.distribution?.map((e) => ({
                        ref1_supply_id: e.ref1_supply_id,
                        supplies: e.ref2?.map((s) => ({
                          ref2_supply_id: s.ref2_supply_id,
                        })),
                      })),
                    });
                  }
                }}
                fullWidth
                clearIcon={null}
                noOptionsText={t('academic.periods_list.stages.add.calification_model.no_data')}
              />
            )}
            <FormHelperText>
              {t('academic.periods_list.stages.add.calification_model.helper')}
            </FormHelperText>
            {errors.calificationModelId && touched.calificationModelId && (
              <FormHelperText error>{t(errors.calificationModelId)}</FormHelperText>
            )}
          </Grid>
        )}

        {
          //falta el permiso del filtro de courses
        }

        <Grid item xs={12} sm={6} md={4} lg={3}>
          <InputLabel>{t('Cursos')}:</InputLabel>
          {isLoadingCourses ? (
            <TextFieldFetching />
          ) : (
            <Autocomplete
              options={coursesList || []}
              loading={isFetchingCourses}
              loadingText={t('Cargando cursos...')}
              getOptionLabel={(option) => option.course_description}
              // value={values.}
              renderInput={(params) => (
                <TextField {...params} error={Boolean(errors.stageInfo && touched.stageInfo)} />
              )}
              onChange={(_, value) => {
                setFieldValue('course_id', value?.id || 0);
                resetCoursesSelection();
              }}
              fullWidth
              // clearIcon={null}
              noOptionsText={t('Sin datos')}
            />
          )}
          {/* {errors.stageInfo && touched.stageInfo && (
                  <FormHelperText error>{t(errors.stageInfo)}</FormHelperText>
                )} */}
        </Grid>

        {hasAccess('readCollaborators') && (
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <InputLabel>Colaborador</InputLabel>
            <CustomAsyncPaginatedSelect
              key={'id'}
              value={collaboratorValue}
              isError={false}
              placeholder={'Escoger colaborador'}
              setValue={(value: any) => {
                setCollaboratorValue(value);
                setFieldValue('collaborator_id', value?.id || 0);
                resetCoursesSelection();
              }}
              fetchFunc={getCollaborators}
              clearable={true}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default FiltersQualificationCourses;
