import { FC, useState } from 'react';

// material-ui
import {
  Button,
  Step,
  Stepper,
  StepLabel,
  Stack,
  Typography,
  Grid,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
// import { useHistory, useLocation } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

// project imports
import AnimateButton from '../../../../shared/components/extended/AnimateButton';
import { useAppDispatch } from '../../../../../hooks/useRedux';
import SelectClient from './SelectClient';
import { checkoutSteps as steps } from '../../constants';
import { SearchPersonParamResponse } from '../../../../shared/interfaces/searchPerson.interface';
import ConfirmFees from './ConfirmFees';
import PayReview from './PayReview';
import VoucherView from './VoucherView';
import { resetPayments } from '../../slices/debtsPaymentSlice';
import TempBillingDataHoc from './TempBillingDataHoc';
import WarningAlert from '../../../../shared/components/alerts/WarningAlert';

function getStepContent(
  step: number,
  // fees to pay
  // selectedDebts: number[],
  // total fees amount
  // totalAmount: number | undefined,

  // selec fees func
  // handleSelecteFees: (fees: number[]) => void,
  // valid continue de
  handleValidStep: (param: boolean) => void,
  //go step back
  handleBack: () => void,
  //go next step
  handleNext: () => void,
  // set index error
  setErrorIndex: (i: number | null) => void,
  // set total amount
  // handleTotalAmount: (amount: number) => void,
  // open Client Side Dialog
  handleOpenClientSideDialog: () => void
) {
  switch (step) {
    case 0:
      return (
        <SelectClient
          handleOpenClientSideDialog={handleOpenClientSideDialog}
          handleValidStep={handleValidStep}
        />
      );
    case 1:
      return <ConfirmFees handleValidStep={handleValidStep} />;
    case 2:
      return <TempBillingDataHoc handleValidStep={handleValidStep} />;
    case 3:
      //   if (selectedMethod === 'credito')
      //     return <SelectClient totalAmount={totalAmount} handleBack={handleBack} />;
      return <PayReview handleNext={handleNext} handleBack={handleBack} />;

    default:
      throw new Error('Unknown step');
  }
}

//-----------------------|| FORMS WIZARD - BASIC ||-----------------------//
interface Props {
  clientInfo: SearchPersonParamResponse | undefined;
  handleOpenClientSideDialog: () => void;

  activeStep: number;
  errorIndex: number | null;
  setErrorIndex: (i: number | null) => void;
  handleNext: () => void;
  handleBack: () => void;
  setActiveStep: (step: number) => void;
}

const CheckoutWizard: FC<Props> = (props) => {
  const {
    activeStep,
    errorIndex,
    setActiveStep,
    handleBack,
    handleNext,
    setErrorIndex,
    handleOpenClientSideDialog,
  } = props;

  const { t } = useTranslation();

  //   const history = useHistory();
  //   const location = useLocation();
  const dispatch = useAppDispatch();

  // const { selectedDebtsId} = useAppSelector((state) => state.payments);
  // const { isPositiveBalanceTransac } = useAppSelector((state) => state.debtsPayment);
  const [validStep, setValidStep] = useState<boolean>();

  // const [totalAmount, setTotalAmount] = useState<number>();

  // const [selectedFees, setSelectedFees] = useState<number[]>([...selectedDebtsId]); //selectedDebtsId cuando se usaba el cart de seleccionados previamente (step 1)

  // const [activeStep, setActiveStep] = useState(0);
  // const [errorIndex, setErrorIndex] = useState<number | null>(null);

  //   const handleSetError = (i: number | null) => {
  //     setErrorIndex(i);
  //   };

  //   const handleActiveStep = (step: number) => {
  //     setActiveStep(step);
  //   };

  // const handleNext = () => {
  //   setActiveStep(activeStep + 1);
  //   setErrorIndex(null);
  // };

  // const handleBack = () => {
  //   setActiveStep(activeStep - 1);
  // };

  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));

  // const handleSelecteFees = (fees: number[]) => {
  //   // setSelectedFees([...fees]);
  // };

  const handleValidStep = (param: boolean) => {
    setValidStep(param);
  };

  // const handleTotalAmount = (amount: number) => {
  //   // setTotalAmount(amount);
  // };

  const resetPayment = () => {
    dispatch(resetPayments());
    setActiveStep(0);
  };

  //   useEffect(() => {
  //     if (!selectedDebts.length) history.push(`/${location.pathname.split('/')[1]}`);
  //   }, [history, location.pathname, selectedDebts.length]);

  return (
    <Grid container>
      <Grid container justifyContent={'center'}>
        <Grid
          item
          xs={12}
          // md={activeStep !== 2 || isPositiveBalanceTransac ? 10 : 12}
          // lg={activeStep !== 2 || isPositiveBalanceTransac ? 7 : 12}
        >
          {/* md={activeStep !== 1 ? 10 : 12} lg={activeStep !== 1 ? 7 : 12}> */}
          <Stepper
            activeStep={activeStep}
            sx={{ pt: 3, pb: 5 }}
            orientation={matchDownSM ? 'vertical' : 'horizontal'}
          >
            {steps.map((label, index) => {
              const labelProps: { error?: boolean; optional?: React.ReactNode } = {};

              if (index === errorIndex) {
                labelProps.optional = (
                  <Typography variant="caption" color="error">
                    {t('financial.debts_payment.wizard.steps_error')}
                  </Typography>
                );

                labelProps.error = true;
              }

              return (
                <Step key={label}>
                  <StepLabel {...labelProps}>{t(label)}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <>
          {activeStep === steps.length ? (
            <>
              <VoucherView />
              <Stack direction="row" justifyContent="center">
                <AnimateButton>
                  {/**
                   * wrong prop color="error"
                   * color?: "inherit" | "primary" | "secondary" | undefined
                   * placeholder style={{color: 'red', borderColor: '#EDE7F6'}}
                   */}
                  <Button
                    variant="contained"
                    style={{ background: theme.palette.error.main, borderColor: '#EDE7F6' }}
                    color="primary"
                    onClick={resetPayment}
                    sx={{ my: 3, ml: 1 }}
                  >
                    {t('financial.debts_payment.wizard.new_payment_process')}
                  </Button>
                </AnimateButton>
              </Stack>
            </>
          ) : (
            <>
              {getStepContent(
                activeStep,
                // selectedFees,
                // totalAmount,
                // handleSelecteFees,
                handleValidStep,
                handleBack,
                handleNext,
                setErrorIndex,
                // handleTotalAmount,
                handleOpenClientSideDialog
              )}
              {activeStep < steps.length - 1 && (
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  // justifyContent={activeStep !== 0 ? 'space-between' : 'flex-end'}
                >
                  <WarningAlert
                    message={
                      'Las deudas deben ser seleccionadas por orden de fechas de vencimiento.'
                    }
                    sx={{ width: '100%' }}
                  />
                  {activeStep !== 0 && (
                    <Button
                      onClick={() => {
                        // if (activeStep > 1)
                        handleBack();
                        // else history.push(`/${location.pathname.split('/')[1]}`);
                      }}
                      sx={{ my: 3, ml: 1 }}
                    >
                      {t('financial.debts_payment.wizard.steps_back_btn')}
                    </Button>
                  )}
                  <AnimateButton>
                    <Button
                      variant="contained"
                      onClick={handleNext}
                      sx={{ my: 1, ml: 1 }}
                      disabled={!validStep}
                    >
                      {activeStep === steps.length - 1
                        ? t('financial.debts_payment.wizard.steps_pay_btn')
                        : t('financial.debts_payment.wizard.steps_next_btn')}
                    </Button>
                  </AnimateButton>
                </Stack>
              )}
            </>
          )}
        </>
      </Grid>

      {/* // */}
    </Grid>
  );
};

export default CheckoutWizard;
