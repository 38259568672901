import { Button, Grid, InputLabel, TextField } from '@material-ui/core';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import * as yup from 'yup';
import ErrorAlert from '../../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../../shared/components/alerts/SuccessAlert';
import { useAddAutorMutation } from '../../../authors/slices/authorsApiSlice';

interface props {
  onUpdate: () => void;
  handleClose: () => void;
}

const FormAddEditorial = ({ onUpdate, handleClose }: props) => {
  const [
    addTag,
    {
      isLoading: isLoadingAdd,
      isSuccess: isSuccessAdd,
      isError: isErrorAdd,
      error: errorAdd,
      reset,
    },
  ] = useAddAutorMutation();

  const schema = yup.object().shape({
    name: yup.string().required('Nombre es requerido'),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      addTag({
        payload: {
          name: values.name,
          business_name: values.name,
          status_id: '1',
          type_tag: 'Editorial',
        },
      });
    },
  });

  useEffect(() => {
    onUpdate();
  }, [isSuccessAdd, onUpdate]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <InputLabel>Nombre de Editorial</InputLabel>
        <TextField
          placeholder="Ingrese Nombre"
          fullWidth
          error={Boolean(formik.errors.name && formik.touched.name)}
          helperText={Boolean(formik.errors.name && formik.touched.name) && formik.errors.name}
          onChange={({ target }) => formik.setFieldValue('name', target.value)}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        {isSuccessAdd && (
          <SuccessAlert handleDismiss={reset} message="Editorial agregada correctamente" />
        )}
        {isErrorAdd && <ErrorAlert handleDismiss={reset} message={String(errorAdd)} />}
      </Grid>
      <Grid item xs={12} md={12} textAlign="center">
        <Button variant="outlined" onClick={handleClose}>
          Cancelar
        </Button>{' '}
        &nbsp;
        <Button variant="contained" onClick={() => formik.handleSubmit()} disabled={isLoadingAdd}>
          Agregar
        </Button>
      </Grid>
    </Grid>
  );
};

export default FormAddEditorial;
