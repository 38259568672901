import Autocomplete from '@material-ui/core/Autocomplete';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ErrorAlert from '../../../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../../../shared/components/alerts/SuccessAlert';
import { useGetEducationLevelsParentsVMQuery } from '../../../../education-levels/slices/educationLevelsApiSlice';
import { useAddInvestigationLinesMutation } from '../../../slices/InvestigationLinesApiSlice';
import InputLabel from '../../../../../shared/components/forms/InputLabel';
import { InvestigationLinesSchema } from '../../../constants/validationSchemas.investigationLines';

const AddInvestigationLineForm = () => {
  const { t } = useTranslation();

  const history = useHistory();

  const [showAlerts, setShowAlerts] = useState(true);

  const [addInvestigationLine, { isLoading, isError, error, isSuccess }] =
    useAddInvestigationLinesMutation();

  const { data: facultadAvailables, isLoading: isLoadingFalcultad } =
    useGetEducationLevelsParentsVMQuery({});

  const { getFieldProps, errors, touched, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      name: '',
      description: '',
      educationLevel: [],
      status: 1,
    },
    validationSchema: InvestigationLinesSchema,
    onSubmit: async ({ description, educationLevel, name, status }) => {
      await addInvestigationLine({
        investigationLinePayload: {
          name,
          description,
          education_levels: educationLevel,
          status: status,
        },
      });
      window.scrollTo(0, 0);
      setShowAlerts(true);
    },
  });

  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction="column" gap={2}>
        <Grid item xs={12} md={6}>
          <Grid container direction="column" spacing={2}>
            {isError && showAlerts && (
              <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
            )}
            {isSuccess && showAlerts && (
              <SuccessAlert
                message={t('lines_research.AddInvestigationLineForm.add.success')}
                handleDismiss={() => setShowAlerts(false)}
              />
            )}
            <Grid item xs={12} md={9}>
              <InputLabel>{t('lines_research.AddInvestigationLineForm.name.title')}</InputLabel>
              <TextField
                {...getFieldProps('name')}
                error={Boolean(errors.name && touched.name)}
                placeholder={t('lines_research.AddInvestigationLineForm.name.placeholder')}
                fullWidth
              />
              {errors.name && touched.name && (
                <FormHelperText error>{t(errors.name)}</FormHelperText>
              )}
            </Grid>
            <Grid item xs={12} md={9}>
              <InputLabel>
                {t('lines_research.AddInvestigationLineForm.description.title')}{' '}
              </InputLabel>
              <TextField
                {...getFieldProps('description')}
                error={Boolean(errors.description && touched.description)}
                placeholder={t('lines_research.AddInvestigationLineForm.description.placeholder')}
                fullWidth
              />
              {errors.description && touched.description && (
                <FormHelperText error>{t(errors.description)}</FormHelperText>
              )}
            </Grid>
            <Grid item xs={12}>
              <InputLabel>{t('academic.subjects.add.academic_unit')}</InputLabel>
              <Autocomplete
                multiple
                options={facultadAvailables || []}
                loading={isLoadingFalcultad}
                loadingText={t('academic.subjects.add.edu_level.loading')}
                noOptionsText={t('academic.subjects.add.edu_level.no_data')}
                getOptionLabel={(option) => option.edu_name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(errors.educationLevel && touched.educationLevel)}
                    placeholder={t('academic.subjects.add.edu_level.placeholder')}
                  />
                )}
                onChange={(_, value) => {
                  setFieldValue(
                    'educationLevel',
                    value.map((item) => item.id)
                  );
                }}
                fullWidth
                clearIcon={null}
              />
              {errors.educationLevel && touched.educationLevel && (
                <FormHelperText error>{t(errors.educationLevel)}</FormHelperText>
              )}
            </Grid>
          </Grid>

          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            spacing={2}
            sx={{ mt: 6 }}
          >
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="outlined"
                size="large"
                color="primary"
                onClick={() => history.goBack()}
              >
                {t('academic.subjects.add.back')}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                type="submit"
                variant="contained"
                size="large"
                color="primary"
                disabled={isLoading}
                endIcon={isLoading && <CircularProgress size={20} />}
              >
                {t('academic.subjects.add.submit')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default AddInvestigationLineForm;
