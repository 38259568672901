import { DataForm } from './AddHoursSummariesForm';
import { Field, FieldArray } from 'formik';
import { TextField as TextFieldFormik } from 'formik-material-ui';
import { TableCell, TableRow } from '@material-ui/core';
// import DeleteButton from '../../../shared/components/tables/buttons/DeleteButton';
import CalculatedField from './CalculatedField';

interface Props {
  name: string;
  values: DataForm;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}

const CustomFieldArray = ({ name, values, setFieldValue }: Props) => {
  return values.hourSummary.length > 0 ? (
    <FieldArray
      name={name}
      render={(arrayHelpers) => (
        <>
          {values.hourSummary.map((hs, index) => {
            return (
              <TableRow className="table-row" key={hs.collaborator_id}>
                <TableCell align="left" width="10%">
                  {hs.hs_name}
                </TableCell>
                <TableCell align="left" width="5%">
                  <Field
                    component={TextFieldFormik}
                    type="number"
                    name={`hourSummary[${index}].hs_classes`}
                  />
                </TableCell>
                <TableCell align="left" width="5%">
                  <Field
                    component={TextFieldFormik}
                    type="number"
                    name={`hourSummary[${index}].hs_classes_examns_preparation`}
                  />
                </TableCell>
                <TableCell align="left" width="5%">
                  <Field
                    component={TextFieldFormik}
                    type="number"
                    name={`hourSummary[${index}].hs_tutoring`}
                  />
                </TableCell>
                <TableCell align="left" width="5%">
                  <Field
                    component={TextFieldFormik}
                    type="number"
                    name={`hourSummary[${index}].hs_bonding`}
                  />
                </TableCell>
                <TableCell align="left" width="5%">
                  <Field
                    component={TextFieldFormik}
                    type="number"
                    name={`hourSummary[${index}].hs_academic_management`}
                  />
                </TableCell>
                <TableCell align="left" width="5%">
                  <Field
                    component={TextFieldFormik}
                    type="number"
                    name={`hourSummary[${index}].hs_research`}
                    value={values.hourSummary[index].hs_research}
                  />
                </TableCell>
                <TableCell align="center" width="5%">
                  <CalculatedField
                    index={index}
                    values={values}
                    setFieldValue={setFieldValue}
                    key={index.toString()}
                  />
                </TableCell>
                {/* <TableCell align="center" width="5%">
                  <DeleteButton
                    handleDelete={() => {
                      arrayHelpers.remove(index);
                    }}
                  />
                </TableCell> */}
              </TableRow>
            );
          })}
        </>
      )}
    />
  ) : (
    <TableRow>
      <TableCell colSpan={8} style={{ textAlign: 'center' }}>
        No hay registros que mostrar
      </TableCell>
    </TableRow>
  );
};

export default CustomFieldArray;
