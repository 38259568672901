import { Switch, Redirect } from 'react-router-dom';
import FinancialPeriodsScreen from '../screens/FinancialPeriodsScreen';
import AddFinancialPeriodScreen from '../screens/AddFinancialPeriodScreen';
import EditFinancialPeriodScreen from '../screens/EditFinancialPeriodScreen';
import { useAppSelector } from '../../../../hooks/useRedux';

import { permissionKey, subPermissionKeys } from '../constants/permissions';

import GuardRoute from '../../../../router/guards/GuardRoute';

const EducationLevelsRouter = () => {
  const { currentModule } = useAppSelector((state) => state.access);

  const hasPermission = permissionKey in currentModule!.permissions;

  if (!hasPermission) <Redirect to="/dashboard" />;

  return (
    <Switch>
      <GuardRoute
        exact
        path="/administrar-periodos-financieros"
        component={FinancialPeriodsScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.read}
      />
      <GuardRoute
        exact
        path="/administrar-periodos-financieros/agregar"
        component={AddFinancialPeriodScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.create}
      />
      <GuardRoute
        exact
        path="/administrar-periodos-financieros/:financialPeriodId/editar"
        component={EditFinancialPeriodScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.edit}
      />
    </Switch>
  );
};

export default EducationLevelsRouter;
