import React, { useState } from 'react';
import { useHistory, Redirect, Link } from 'react-router-dom';

import { makeStyles, useTheme } from '@material-ui/core/styles';

import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import CardContent from '@material-ui/core/CardContent';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Typography from '@material-ui/core/Typography';
import PerfectScrollbar from 'react-perfect-scrollbar';

import {
  IconChevronDown,
  IconChevronRight,
  IconLogout,
  IconArrowNarrowLeft,
  IconKey,
  IconClipboard,
  IconUser,
  IconWriting,
  IconCreditCard,
} from '@tabler/icons';

import { useAppDispatch, useAppSelector } from '../../../../../../hooks/useRedux';

import Transitions from '../../../../components/extended/Transitions';
import MainCard from '../../../../components/cards/MainCard';

import { Role, Profile } from '../../../../../../types';
import {
  resetCurrentModule,
  setChangePhoto,
  setCurrentProfile,
  setCurrentRol,
  setCurrentVirtualClassroom,
} from '../../../../reducers/accessReducer';
import { useLogoutMutation } from '../../../../../auth/slices/authApiSlice';
import { baseUrl } from '../../../../../../api/mainApi';
import { Badge, IconButton, Tooltip } from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import FiberManualCamera from '@material-ui/icons/PhotoCamera';
// import { StudentRecord } from '../../../../../auth/interfaces/login.interfaces';
import { setStudentRecordInfo } from '../../../../../auth/helpers/helperStudent';
// import { checkProfileQuantity } from '../../../../../auth/helpers';
import { ThunkDispatch } from 'redux-thunk';
import { checkModuleQuantity, checkProfileQuantity } from '../../../../../auth/helpers';
import { StudentRecord } from '../../../../../auth/interfaces/student-record.interfaces';
import { useTranslation } from 'react-i18next';
import ModalElectronicSignatureRegistration from '../../../../components/ModalElectronicSignatureRegistration';
import useGuardProfileSection from '../../../../hooks/useGuardProfileSection';
import RedlinksCard from './RedlinksCard';

interface OptionsProps {
  name: string;
  id: number;
  description: string | undefined;
  keyword: string;
}

const useStyles = makeStyles((theme) => ({
  navContainer: {
    width: '100%',
    maxWidth: '350px',
    minWidth: '300px',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '10px',
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
    },
  },
  headerAvatar: {
    cursor: 'pointer',
    ...theme.typography.mediumAvatar,
    margin: '8px 0 8px 8px !important',
  },
  profileChip: {
    height: '48px',
    alignItems: 'center',
    borderRadius: '27px',
    transition: 'all .2s ease-in-out',
    borderColor:
      theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
    '&[aria-controls="menu-list-grow"], &:hover': {
      borderColor: theme.palette.primary.main,
      background: theme.palette.primary.main + '!important',
      color: theme.palette.primary.light,
      '& svg': {
        stroke: theme.palette.primary.light,
      },
    },
  },
  profileLabel: {
    lineHeight: 0,
    padding: '12px',
  },
  listItem: {
    marginTop: '5px',
  },
  cardContent: {
    padding: '16px !important',
    minWidth: 350,
    maxWidth: 350,
  },
  card: {
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.dark[800] : theme.palette.primary.light,
    marginBottom: '16px',
    marginTop: '16px',
  },
  searchControl: {
    width: '100%',
    paddingRight: '8px',
    paddingLeft: '16px',
    marginBottom: '16px',
    marginTop: '16px',
  },
  startAdornment: {
    fontSize: '1rem',
    color: theme.palette.grey[500],
  },
  flex: {
    display: 'flex',
  },
  name: {
    marginLeft: '2px',
    fontWeight: 400,
  },
  ScrollHeight: {
    height: '100%',
    maxHeight: 'calc(100vh - 250px)',
    overflowTeacherOrStudentData: 'hidden',
  },
  badgeWarning: {
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.dark.dark : theme.palette.warning.dark,
    color: '#fff',
  },
  profileContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
  },
  avatarContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.25rem',
    cursor: 'pointer',
  },
  listItemContainer: {
    borderTop: '1px solid #F0F0F0',
    cursor: 'pointer',
    paddingBlock: 14,
    paddingInline: 0,
    '&:nth-last-child(2)': {
      borderBottom: '1px solid #F0F0F0',
    },
    // '&:hover': {
    //   backgroundColor: theme.palette.primary.light,
    // },
  },
  moduleIcon: {
    marginInlineEnd: 16,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    width: '52px',
    height: '52px',
    fontWeight: 700,
    backgroundColor: theme.palette.grey[100],
    '& > svg': {
      width: '32px',
      height: '32px',
    },
  },
  itemTitle: {
    textTransform: 'capitalize',
  },
  itemSubtitle: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  popUpContainer: {
    width: '90%',
    maxWidth: 350,
  },
}));

const ProfileSection = () => {
  const { t } = useTranslation();

  const classes = useStyles();

  const theme = useTheme();

  const [logout] = useLogoutMutation();

  const dispatch = useAppDispatch();

  const history = useHistory();

  const { hasAccess } = useGuardProfileSection();

  const { customization, auth, access } = useAppSelector((state) => state);

  const { currentProfile, currentRol, profiles, currentRecordStudent } = access;

  const { userInfo, studentRecordReference } = auth;

  const [open, setOpen] = useState(false);
  const [openRegisterSignature, setOpenRegisterSignature] = useState(false);

  const [openRedlinks, setOpenRedlinks] = useState(false);

  const ROL_ESTUDENT: string = 'ESTUDIANTE';

  // const ROL_SUPERADMIN: string = 'SUPERADMINISTRADOR';

  const ROL_TEACHER: string = 'DOCENTE';

  /**
   * anchorRef is used on different componets and specifying one type leads to other components throwing an error
   * */
  const anchorRef = React.useRef<any>(null);

  // const { data: redlinksData } = useGetRedlinksAvatarQueryQuery({
  //   identification: userInfo?.identification,
  // });

  const handleChangePassword = () => {
    return (
      <>
        <Redirect to="/configuraciones/cambiar-clave" />
      </>
    );
  };
  const handleGoToTermsConditions = () => {
    return (
      <>
        <Redirect to="/terminos-condiciones" />
      </>
    );
  };

  const handleChangePhoto = () => {
    // if (!redlinksData?.result?.foto) {
    dispatch(setChangePhoto(true));
    // }
  };

  const handleLogout = async () => {
    await logout({});
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleToggleViewRegisterSignature = () => {
    setOpenRegisterSignature((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<HTMLDivElement> | MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setShowData(false);

    setOpen(false);
  };

  const prevOpen = React.useRef(open);

  const [showData, setShowData] = useState(false);

  const [selectedProfile, setSelectedProfile] = useState<null | string>(null);

  const handleSelectProfile = (keyword: string) => {
    // dispatch(resetCurrentModule());
    // localStorage.removeItem('currentModule');

    const pf: { [key: string]: Profile } = { [`${keyword}`]: profiles[keyword] };

    // setea el primer modulo del current rol
    if (currentRol && currentRol?.modules && Object.keys(currentRol?.modules).length === 1) {
      checkModuleQuantity(dispatch, currentRol.modules);
      // return <Redirect to="/dashboard" />;
    } else {
      // history.replace('/modulos');
    }

    setSelectedProfile(keyword);
    if (pf && Object.keys(pf[keyword].roles).length <= 1) checkProfileQuantity(dispatch, pf);
    setShowData(true);
  };

  const handleSelectRol = (rol: Role) => {
    dispatch(resetCurrentModule());
    localStorage.removeItem('currentModule');

    dispatch(setCurrentVirtualClassroom(false));
    dispatch(setCurrentProfile(profiles[selectedProfile!]));
    dispatch(setCurrentRol(rol));

    localStorage.setItem('currentRol', JSON.stringify({ id: rol.rolId, keyword: rol.keyword }));
    localStorage.setItem('currentProfile', selectedProfile!);

    setOpen(false);

    history.replace('/modulos');

    setShowData(false);
  };

  /**
   * Establecer el estado actual actual del perfil con
   * el keyword del perfil seleccionado.
   */
  const handleProfile = (dispatch: ThunkDispatch<any, any, any>, profileName: string) => {
    const profile: { [key: string]: Profile } = { [`${profileName}`]: profiles[profileName] };
    checkProfileQuantity(dispatch, profile);
    history.push('/modulos');
    dispatch(setCurrentVirtualClassroom(false));
  };

  /**
   * Función que permite cambiar de record estudiantil, si no se encuentra
   * seleccionado perfil estudiante, se asigna el perfil actual estudiante.
   */
  const handleSelectStudentRecord = (studentRecord: StudentRecord, current: boolean) => {
    if (!current) {
      handleProfile(dispatch, 'estudiante');
    }

    //cambia el perfil por el tomado temporalmente en las opciones de perfil
    history.push('/modulos');

    setStudentRecordInfo(dispatch, studentRecord);
    setShowData(false);
  };

  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const profilesAvailables = Object.keys(profiles).map((pk) => profiles[pk]);

  const currentRolText = JSON.parse(localStorage.getItem('currentRol')!)
    .keyword?.split('-')
    .join(' ') as string;

  const rolesText = `${currentRolText.charAt(0).toUpperCase()}${currentRolText.slice(1)}`;

  const obtainImageDomain = (image: string) => {
    return `${baseUrl}/show?name=${image}`;
  };

  const obtainImageByDefault = (name: string | undefined) => {
    return `https://ui-avatars.com/api/?name=${name}&background=0D8ABC&color=fff`;
  };

  const RenderOptionsAuth = function () {
    return (
      <React.Fragment>
        <Link to="/configuraciones/cambiar-clave" style={{ textDecoration: 'none' }}>
          <ListItem
            className={classes.listItem}
            sx={{
              borderRadius: customization.borderRadius + 'px',
              textDecoration: 'none',
            }}
            button
            onClick={handleChangePassword}
          >
            <ListItemIcon>
              <IconKey stroke={1.5} size="1.3rem" />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography variant="body2">{t('shared.header.menu.change_password')}</Typography>
              }
            />
          </ListItem>
        </Link>
        <Link to="/terminos-condiciones" style={{ textDecoration: 'none' }}>
          <ListItem
            className={classes.listItem}
            sx={{
              borderRadius: customization.borderRadius + 'px',
              textDecoration: 'none',
            }}
            button
            onClick={handleGoToTermsConditions}
          >
            <ListItemIcon>
              <IconClipboard stroke={1.5} size="1.3rem" />
            </ListItemIcon>
            <ListItemText
              primary={<Typography variant="body2">{t('shared.header.menu.terms')}</Typography>}
            />
          </ListItem>
        </Link>
        {hasAccess('uploadCertificate', 'electronicSignature') && (
          <ListItem
            className={classes.listItem}
            sx={{
              borderRadius: customization.borderRadius + 'px',
            }}
            button
            onClick={handleToggleViewRegisterSignature}
          >
            <ListItemIcon>
              <IconWriting stroke={1.5} size="1.3rem" />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography variant="body2">
                  {t('shared.header.menu.register_certificate')}
                </Typography>
              }
            />
          </ListItem>
        )}
        {/* <ListItem
          className={classes.listItem}
          sx={{
            borderRadius: customization.borderRadius + 'px',
          }}
          button
          onClick={handleChangePhoto}
        >
          <ListItemIcon>
            <IconCamera stroke={1.5} size="1.3rem" />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body2">{t('shared.header.menu.change_photo')}</Typography>
            }
          />
        </ListItem> */}
        <Link to="/Mi-perfil" style={{ textDecoration: 'none' }}>
          <ListItem
            className={classes.listItem}
            sx={{
              borderRadius: customization.borderRadius + 'px',
            }}
            button
          >
            <ListItemIcon>
              <IconUser stroke={1.5} size="1.3rem" />
            </ListItemIcon>
            <ListItemText
              primary={<Typography variant="body2">{t('shared.header.menu.perfil')}</Typography>}
            />
          </ListItem>
        </Link>
        <ListItem
          className={classes.listItem}
          sx={{
            borderRadius: customization.borderRadius + 'px',
          }}
          button
          onClick={handleLogout}
        >
          <ListItemIcon>
            <IconLogout stroke={1.5} size="1.3rem" />
          </ListItemIcon>
          <ListItemText
            primary={<Typography variant="body2">{t('shared.header.menu.logout')}</Typography>}
          />
        </ListItem>
      </React.Fragment>
    );
  };

  const isImageRedlinks = () => {
    if (currentProfile?.imgProfile) {
      return obtainImageDomain(currentProfile?.imgProfile);
    }

    // if (redlinksData?.result?.foto) {
    //   return redlinksData?.result?.foto;
    // }

    return obtainImageByDefault(userInfo?.name);
  };

  const RenderAvatarHeader = function () {
    const image = isImageRedlinks();

    return (
      <>
        <Badge
          overlap="circular"
          badgeContent={
            <FiberManualRecordIcon
              sx={{
                cursor: 'pointer',
                color: theme.palette.success.dark,
                verticalAlign: 'middle',
                fontSize: '14px',
              }}
            />
          }
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <Avatar src={image} alt={`Usuario ${userInfo?.name} avatar`} />
        </Badge>
      </>
    );
  };

  const RenderAvatarMenu = function () {
    const image = isImageRedlinks();

    return (
      <>
        <Badge
          onClick={handleChangePhoto}
          overlap="circular"
          badgeContent={
            /*  !redlinksData?.result?.foto */ /* defaultConfiguration.redLinksPhotoId && */ <FiberManualCamera
              sx={{
                cursor: 'pointer',
                color: theme.palette.dark.dark,
                verticalAlign: 'middle',
                fontSize: '25px',
              }}
            />
          }
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <Avatar
            src={image}
            alt={`Usuario ${userInfo?.name} avatar`}
            sx={{ width: 96, height: 96 }}
          ></Avatar>
        </Badge>
      </>
    );
  };

  const StudentRecordOptions = (sr: StudentRecord) => {
    return (
      <ListItem
        className={classes.listItemContainer}
        key={sr.id}
        onClick={() => handleSelectStudentRecord(sr, currentRecordStudent?.id! === sr.id)}
      >
        <ListItemIcon
          className={classes.moduleIcon}
          {...(currentRecordStudent?.id === sr.id && {
            style: {
              backgroundColor: theme.palette.secondary.light,
              color: theme.palette.primary.main,
            },
          })}
        >
          {sr.education_level.edu_name.toUpperCase().slice(0, 2)}
        </ListItemIcon>
        <ListItemText
          primary={
            <>
              <Typography
                variant="body1"
                fontWeight={700}
                className={classes.itemTitle}
                {...(currentRecordStudent?.id === sr.id && {
                  sx: { color: 'primary.main' },
                })}
              >
                {sr.education_level.edu_name}
              </Typography>
            </>
          }
        />
      </ListItem>
    );
  };

  const renderOptionSimple = ({ description, id, name, keyword }: OptionsProps) => {
    return (
      <ListItem
        className={classes.listItemContainer}
        onClick={() => handleProfile(dispatch, keyword)}
        key={id}
      >
        <ListItemIcon
          className={classes.moduleIcon}
          {...(currentProfile?.profileId === id && {
            style: {
              backgroundColor: theme.palette.secondary.light,
              color: theme.palette.primary.main,
            },
          })}
        >
          {name.toUpperCase().slice(0, 2)}
        </ListItemIcon>
        <ListItemText
          primary={
            <>
              <Typography
                variant="body1"
                fontWeight={700}
                className={classes.itemTitle}
                {...(currentProfile?.profileId === id && {
                  sx: { color: 'primary.main' },
                })}
              >
                {name}..
              </Typography>
              <Typography
                variant="body2"
                className={classes.itemSubtitle}
                {...(currentProfile?.profileId === id && {
                  sx: { color: 'primary.200' },
                })}
              >
                {description}
              </Typography>
            </>
          }
        />
      </ListItem>
    );
  };

  const renderOptionWithSelect = ({ description, id, name, keyword }: OptionsProps) => {
    return (
      <ListItem
        className={classes.listItemContainer}
        key={id}
        onClick={() => {
          // handleSelectProfile(keyword);
          setShowData(true);
        }}
      >
        <ListItemIcon
          className={classes.moduleIcon}
          {...(currentProfile?.profileId === id && {
            style: {
              backgroundColor: theme.palette.secondary.light,
              color: theme.palette.primary.main,
            },
          })}
        >
          {name.toUpperCase().slice(0, 2)}
        </ListItemIcon>
        <ListItemText
          primary={
            <>
              <Typography
                variant="body1"
                fontWeight={700}
                className={classes.itemTitle}
                {...(currentProfile?.profileId === id && {
                  sx: { color: 'primary.main' },
                })}
              >
                {name}
              </Typography>
              <Typography
                variant="body2"
                className={classes.itemSubtitle}
                {...(currentProfile?.profileId === id && {
                  sx: { color: 'primary.200' },
                })}
              >
                {description}
              </Typography>
            </>
          }
        />
        <Box sx={{ px: 1 }}>
          <IconChevronRight />
        </Box>
      </ListItem>
    );
  };

  const renderProfilesAdmin = ({ description, id, name, keyword }: OptionsProps) => {
    return (
      <ListItem
        className={classes.listItemContainer}
        key={id}
        onClick={() => handleSelectProfile(keyword)}
      >
        <ListItemIcon
          className={classes.moduleIcon}
          {...(currentProfile?.profileId === id && {
            style: {
              backgroundColor: theme.palette.secondary.light,
              color: theme.palette.primary.main,
            },
          })}
        >
          {name.toUpperCase().slice(0, 2)}
        </ListItemIcon>
        <ListItemText
          primary={
            <>
              <Typography
                variant="body1"
                fontWeight={700}
                className={classes.itemTitle}
                {...(currentProfile?.profileId === id && {
                  sx: { color: 'primary.main' },
                })}
              >
                {name}
              </Typography>
              <Typography
                variant="body2"
                className={classes.itemSubtitle}
                {...(currentProfile?.profileId === id && {
                  sx: { color: 'primary.200' },
                })}
              >
                {description}
              </Typography>
            </>
          }
        />
        <Box sx={{ px: 1 }}>
          <IconChevronRight />
        </Box>
      </ListItem>
    );
  };

  const RenderOptionsInfo = () => {
    // Opciones generales de navegación, manejo de la navegacion entre los
    // módulos cargados.
    return (
      <Grid container sx={{ pt: 2 }}>
        {showData ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              cursor: 'pointer',
            }}
            onClick={() => setShowData(false)}
          >
            <IconArrowNarrowLeft size={20} />
            <Typography variant="h5" fontWeight={700} color={theme.palette.grey[600]}>
              {t('shared.header.menu.back')}
            </Typography>
          </Box>
        ) : (
          <Typography variant="h5" fontWeight={700} color={theme.palette.grey[600]}>
            {t('shared.header.menu.change_profile')}
          </Typography>
        )}
      </Grid>
    );
  };

  const renderRolesAdmin = (rol: Role) => {
    return (
      <ListItem
        className={classes.listItemContainer}
        key={rol.rolId}
        onClick={() => handleSelectRol(rol)}
      >
        <ListItemIcon
          className={classes.moduleIcon}
          {...(currentRol?.rolId === rol.rolId && {
            style: {
              backgroundColor: theme.palette.secondary.light,
              color: theme.palette.primary.main,
            },
          })}
        >
          {rol.name.toUpperCase().slice(0, 2)}
        </ListItemIcon>
        <ListItemText
          primary={
            <>
              <Typography
                variant="body1"
                fontWeight={700}
                className={classes.itemTitle}
                {...(currentRol?.rolId! === rol.rolId && {
                  sx: { color: 'primary.main' },
                })}
              >
                {rol.name}
              </Typography>
              <Typography
                variant="body2"
                className={classes.itemSubtitle}
                {...(currentRol?.rolId! === rol.rolId && {
                  sx: { color: 'primary.400' },
                })}
              >
                {rol.description}
              </Typography>
            </>
          }
        />
      </ListItem>
    );
  };

  const NavigateTeacherOrStudent = () => {
    /**
     * Navegar entre los records estudiantiles de un estudiante.
     * Mostrar el perfil de docente.
     */
    return (
      <PerfectScrollbar component={'div'}>
        <Grid container sx={{ maxHeight: '45vh' }}>
          <Grid item xs={12}>
            <List component="nav">
              {!showData || currentRol?.name === ROL_TEACHER
                ? profilesAvailables?.map(({ name, profileId: id, description, keyword }) =>
                    renderProfiles({ name, id, description, keyword })
                  )
                : studentRecordReference && studentRecordReference.map(StudentRecordOptions)}
              <RenderOptionsAuth />
            </List>
          </Grid>
        </Grid>
      </PerfectScrollbar>
    );
  };

  const NavigateAdmin = () => {
    // Permite navegar entre los módulo a un superadministrador
    return (
      <PerfectScrollbar component={'div'}>
        <Grid container sx={{ maxHeight: '45vh' }}>
          <Grid item xs={12}>
            <List component="nav">
              {!showData
                ? profilesAvailables.map(({ name, profileId: id, description, keyword }) =>
                    renderProfilesAdmin({ name, id, description, keyword })
                  )
                : Object.keys(profiles[selectedProfile!].roles).map((rolKey) => {
                    const rol = profiles[selectedProfile!].roles[rolKey];
                    return renderRolesAdmin(rol);
                  })}
              <RenderOptionsAuth />
            </List>
          </Grid>
        </Grid>
      </PerfectScrollbar>
    );
  };

  const renderNavigation = function () {
    if (currentRol?.name === ROL_ESTUDENT) {
      // || currentRol?.name === ROL_TEACHER) {
      return <NavigateTeacherOrStudent />;
    } else {
      return <NavigateAdmin />;
    }
  };

  const renderProfiles = ({ description, id, name, keyword }: OptionsProps) => {
    // alwalys student or teacher
    return (
      <React.Fragment key={name}>
        {keyword !== ROL_ESTUDENT.toLowerCase()
          ? renderOptionSimple({ description, id, name, keyword })
          : renderOptionWithSelect({ description, id, name, keyword })}
      </React.Fragment>
    );
  };

  const winHeight = window.innerHeight;

  return (
    <React.Fragment>
      <Box
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        className={classes.profileContainer}
      >
        <Box sx={{ textAlign: 'right' }}>
          <Typography variant="h5" fontWeight={700}>
            {userInfo?.name}
          </Typography>
          <Typography variant="body2">{rolesText}</Typography>
        </Box>

        <Box className={classes.avatarContainer}>
          <RenderAvatarHeader />
          <IconChevronDown size={20} stroke={3} color={theme.palette.primary.main} />
        </Box>
      </Box>

      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 14],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  border={false}
                  elevation={16}
                  content={false}
                  boxShadow
                  shadow={theme.shadows[16]}
                >
                  <CardContent className={classes.cardContent}>
                    <Grid container sx={{ py: 2, overscrollBehaviorY: 'auto' }}>
                      <Grid
                        item
                        container
                        xs={12}
                        gap={2}
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Grid
                          item
                          xs={winHeight > 619 ? 12 : 5}
                          container={winHeight > 619}
                          justifyContent="center"
                        >
                          <RenderAvatarMenu />
                        </Grid>
                        <Grid
                          item
                          xs={winHeight > 619 ? 12 : 5}
                          container={winHeight > 619}
                          justifyContent="center"
                        >
                          <Box sx={{ textAlign: 'center' }}>
                            <Typography variant="h5" fontWeight={700}>
                              {userInfo?.fullname}
                            </Typography>
                            <Grid container justifyContent="center" alignItems="center">
                              <Grid item>
                                <Typography variant="body2">{rolesText}</Typography>
                              </Grid>
                              <Grid item>
                                <Tooltip title={t('shared.header.menu.profile.digital_card')}>
                                  <IconButton onClick={() => setOpenRedlinks(true)}>
                                    <IconCreditCard />
                                  </IconButton>
                                </Tooltip>
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                    <RenderOptionsInfo />
                    {renderNavigation()}
                  </CardContent>
                  {
                    <ModalElectronicSignatureRegistration
                      open={openRegisterSignature}
                      handleToggleOpen={handleToggleViewRegisterSignature}
                    />
                  }
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>

      {openRedlinks && (
        <RedlinksCard
          // redlinksData={redlinksData}
          open={openRedlinks}
          onClose={() => {
            setOpenRedlinks(false);
          }}
        />
      )}
    </React.Fragment>
  );
};

export default ProfileSection;
