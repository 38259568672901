import { Paper, Typography, Tooltip, IconButton } from '@material-ui/core';
import { StatusApplication } from '../interfaces/statusApplication.interface';
import { Edit } from '@material-ui/icons';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useAppDispatch } from '../../../../hooks/useRedux';
import { setStatusNameToAddRol } from '../slices/requestsStatusSlice';
import useSharedGuard from '../../../../hooks/useSharedGuard';
import { subPermissionKeys } from '../constants/permissions';

interface props {
  roles: StatusApplication[];
  keyStatusName: string;
  index: number;
  idStatus: string;
}

const StatusRolItem = ({ roles, keyStatusName, index }: props) => {
  const history = useHistory();
  const { path } = useRouteMatch();

  const dispatch = useAppDispatch();

  const { hasAccess } = useSharedGuard(subPermissionKeys);

  const gotoedit = (id: string) => {
    dispatch(setStatusNameToAddRol(keyStatusName));
    history.push(`${path.replace('/:id', '')}/editar/${id}`);
  };

  return (
    <Paper elevation={2} sx={{ p: 2 }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          borderBottom: '1px solid gainsboro',
          alignItems: 'center',
          padding: 10,
        }}
      >
        <div style={{ flex: 1 }}>
          <Typography variant="h4" color="primary">
            {index === 0 ? null : index + '. '} {keyStatusName}
          </Typography>
        </div>
        <div>
          {keyStatusName !== 'Rechazado' && hasAccess('edit') && (
            <Tooltip title="Editar Roles">
              <IconButton
                size="small"
                onClick={() =>
                  gotoedit(
                    roles.find((r) => r.status_name === keyStatusName)?.type_application_status_id!
                  )
                }
              >
                <Edit color="secondary" />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>
      <br />
      <label htmlFor="" style={{ marginLeft: 10, fontWeight: 600 }}>
        Roles
      </label>
      <ul>
        {roles!
          .filter((el) => el.status_name === keyStatusName)
          .sort(function (a, b) {
            return a.role > b.role ? 1 : -1;
          })
          .map((el2) => (
            <li>{el2.role}</li>
          ))}
      </ul>
    </Paper>
  );
};

export default StatusRolItem;
