import ConfirmAlert from '../../../shared/components/dialogs/ConfirmAlert';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';

import { usePublishCurrciculumMutation } from '../slices/meshsApiSlice';
import { fireDeleteCurriculumDialog, resetCurriculumSelected } from '../slices/meshSlice';

const PublishCurriculumDialog = () => {
  const dispatch = useAppDispatch();

  const { publishCurriculumDialogOpen, curriculumSelected } = useAppSelector(
    (state) => state.meshs
  );
  const { currentProfile } = useAppSelector((state) => state.access);
  const profileId = currentProfile!.profileId;

  const [publishCurriculum, { isLoading, isSuccess, isError, error }] =
    usePublishCurrciculumMutation();

  const handlePublish = () => {
    publishCurriculum({ profileId, meshId: curriculumSelected! });
  };

  const handleClose = () => {
    dispatch(fireDeleteCurriculumDialog(false));
    dispatch(resetCurriculumSelected());
  };

  return (
    <ConfirmAlert
      open={publishCurriculumDialogOpen}
      onClose={handleClose}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      handleDelete={handlePublish}
      error={error as string}
      title="¿Quieres poner en vigencia esta malla?"
      subtitle="La malla actual pasará a estado Vigente. (Si la carrera ya contaba con una malla vigente, esta pasara a No Vigente)"
      successMessage="La malla ha sido publicada"
    />
  );
};

export default PublishCurriculumDialog;
