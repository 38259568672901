import { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { setEconomicGroupList } from '../slices/economicGroupSlice';

import StatusChip from '../../../shared/components/badges/StatusChip';
import AddButton from '../../../shared/components/tables/buttons/AddButton';
import DeleteButton from '../../../shared/components/tables/buttons/DeleteButton';
import EditButton from '../../../shared/components/tables/buttons/EditButton';

import { useGetEconomicGroupsQuery } from '../slices/economicGroupsApiSlice';
import { openDeleteModalWith, updateEconomicGroupeTableStates } from '../slices/economicGroupSlice';
import useGuard from '../hooks/useGuard';
import SearchPaginatedTable, {
  FilterArrow,
} from '../../../shared/components/tables/SearchPaginatedTable';
import { useTableSearch } from '../../../../hooks/useTableSearch';

const EconomicGroupsTable = () => {
  const { t } = useTranslation();
  const { currentProfile } = useAppSelector((state) => state.access);
  const { hasAccess } = useGuard();
  const dispatch = useAppDispatch();

  const { path } = useRouteMatch();
  const history = useHistory();

  const { page, perPage, search } = useAppSelector(
    (state) => state.economicGroups.EconomicGroupTable
  );

  const { setPage, setPerPage, setSearch } = useTableSearch({
    page,
    perPage,
    search,
    updateFunction: updateEconomicGroupeTableStates,
  });

  const [filterArrow, setFilterArrow] = useState<FilterArrow>(null!);

  // useEffect(() => {
  //   setPage(1);
  // }, [perPage, search, setPage]);

  const { isLoading, error, data, isFetching } = useGetEconomicGroupsQuery(
    {
      profileId: currentProfile?.profileId!,
      page,
      perPage,
      search,
      ...(filterArrow && {
        sort: filterArrow.name,
        type_sort: filterArrow.direction,
      }),
    },
    { refetchOnMountOrArgChange: 30 }
  );

  //cargar en un estado la vista actual de la tabla
  useEffect(() => {
    if (data) {
      dispatch(setEconomicGroupList(data.data));
    }
  }, [data, dispatch]);

  const handleDelete = async (economicGroupId: number) => {
    dispatch(openDeleteModalWith(economicGroupId));
  };

  const goToAdd = () => {
    history.push(`${path}/agregar`);
  };

  const datafetch = data?.data!.map((d, index) => ({
    id: index + ((page - 1) * perPage + 1),
    name: d.eco_gro_name,
    description: d.eco_gro_description,
    minMax: `${d.eco_gro_min_amount} - ${d.eco_gro_max_amount}`,
    statusId: d.status_id,
    options: d,
  }));

  return (
    <SearchPaginatedTable
      data={datafetch!}
      headers={{
        id: 'N°',
        name: t('financial.economic_group.table.name'),
        description: t('financial.economic_group.table.description'),
        minMax: `Min / Max`,
        statusId: t('financial.economic_group.table.status'),
        options: t('shared.search_paginated_table.table.options'),
      }}
      listFilterArrows={{
        name: 'eco_gro_name',
        description: 'eco_gro_description',
        minMax: 'eco_gro_min_amount',
        statusId: 'status_id',
      }}
      filterArrow={filterArrow}
      setFilterArrow={setFilterArrow}
      keyExtractor={(item) => item.id.toString()}
      searchPlacehoder={t('financial.economic_group.table.search_placeholder')}
      isLoading={isLoading}
      isFetching={isFetching}
      error={error}
      perPage={perPage}
      setPerPage={setPerPage}
      total={data?.total || 0}
      page={page}
      setPage={setPage}
      numHeader={5}
      search={search}
      setSearch={setSearch}
      customRenderers={{
        statusId: (row) => <StatusChip statusId={Number(row.statusId)} />,
        options: (row) => (
          <>
            {hasAccess('edit') && (
              <Link to={`${path}/${row.options.id}/editar`}>
                <EditButton />
              </Link>
            )}
            {hasAccess('delete') && (
              <DeleteButton handleDelete={() => handleDelete(row.options.id)} />
            )}
            {!hasAccess('edit') &&
              !hasAccess('delete') &&
              t('shared.search_paginated_table.table.options.no_options')}
          </>
        ),
      }}
      ActionButtons={<>{hasAccess('create') && <AddButton size="large" onClick={goToAdd} />}</>}
      customDataCellsProperties={{
        id: { align: 'center' },
        name: { align: 'center' },
        description: { align: 'center' },
        minMax: { align: 'center' },
        statusId: { align: 'center' },
        options: { align: 'center' },
      }}
    />
  );
};

export default EconomicGroupsTable;
