import {
  Divider,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  List,
  Button,
  Skeleton,
  Tooltip,
} from '@material-ui/core';
import SubCard from '../../../shared/components/cards/SubCard';
import Logo from '../../../shared/components/Logo';

import MailTwoToneIcon from '@material-ui/icons/MailTwoTone';
import LanguageIcon from '@material-ui/icons/Language';
import IdIcon from '@material-ui/icons/CreditCard';
import { useAppSelector } from '../../../../hooks/useRedux';
import { IconEdit } from '@tabler/icons';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { FC } from 'react';
import { LocalPhone, PhoneInTalk, WhatsApp } from '@material-ui/icons';

interface Props {
  isLoading: boolean;
}

const PrincipalCard: FC<Props> = (props) => {
  const { company } = useAppSelector((state) => state.companies);
  const { path } = useRouteMatch();
  const history = useHistory();

  const { isLoading } = props;

  return (
    <Grid
      item
      sx={{
        width: '100%',
      }}
    >
      <SubCard
        content={false}
        title={
          <Grid container alignItems="center">
            <Grid item sx={{ width: '130px' }}>
              <Logo />
            </Grid>
            <Grid item xs zeroMinWidth>
              <Typography align="left" variant="subtitle1">
                {company?.co_name}
              </Typography>
              <Typography align="left" variant="subtitle2">
                Nombre de la compañía
              </Typography>
            </Grid>
            <Grid item>
              <Tooltip title="Editar esta sección" placement="top-end">
                <Button onClick={() => history.push(`${path}/editar-principal`)}>
                  <IconEdit stroke={1.5} size="1.3rem" />
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
        }
      >
        {isLoading ? (
          <>
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
          </>
        ) : (
          <List component="nav" aria-label="main mailbox folders">
            <>
              <ListItem>
                <ListItemIcon>
                  <IdIcon sx={{ fontSize: '1.3rem' }} />
                </ListItemIcon>
                <ListItemText primary={<Typography variant="subtitle1">Ruc</Typography>} />
                <ListItemSecondaryAction>
                  <Typography variant="subtitle2" align="right">
                    {company?.co_ruc || ''}
                  </Typography>
                </ListItemSecondaryAction>
              </ListItem>
              <Divider />
            </>

            <>
              <ListItem>
                <ListItemIcon>
                  <MailTwoToneIcon sx={{ fontSize: '1.3rem' }} />
                </ListItemIcon>
                <ListItemText primary={<Typography variant="subtitle1">Email</Typography>} />
                <ListItemSecondaryAction>
                  <Typography variant="subtitle2" align="right">
                    {company?.co_email || ''}
                  </Typography>
                </ListItemSecondaryAction>
              </ListItem>
              <Divider />
            </>

            <>
              <ListItem>
                <ListItemIcon>
                  <LanguageIcon sx={{ fontSize: '1.3rem' }} />
                </ListItemIcon>
                <ListItemText primary={<Typography variant="subtitle1">Website</Typography>} />
                <ListItemSecondaryAction>
                  <Typography variant="subtitle2" align="right">
                    {company?.co_website || ''}
                  </Typography>
                </ListItemSecondaryAction>
              </ListItem>
              <Divider />
            </>
            <>
              <ListItem>
                <ListItemIcon>
                  <LocalPhone sx={{ fontSize: '1.3rem' }} />
                </ListItemIcon>
                <ListItemText primary={<Typography variant="subtitle1">Celular</Typography>} />
                <ListItemSecondaryAction>
                  <Typography variant="subtitle2" align="right">
                    {company?.co_phone || ''}
                  </Typography>
                </ListItemSecondaryAction>
              </ListItem>
              <Divider />
            </>
            <>
              <ListItem>
                <ListItemIcon>
                  <WhatsApp sx={{ fontSize: '1.3rem' }} />
                </ListItemIcon>
                <ListItemText primary={<Typography variant="subtitle1">WhatsApp</Typography>} />
                <ListItemSecondaryAction>
                  <Typography variant="subtitle2" align="right">
                    {company?.co_whatsapp || ''}
                  </Typography>
                </ListItemSecondaryAction>
              </ListItem>
              <Divider />
            </>
            <>
              <ListItem>
                <ListItemIcon>
                  <PhoneInTalk sx={{ fontSize: '1.3rem' }} />
                </ListItemIcon>
                <ListItemText primary={<Typography variant="subtitle1">Pbx</Typography>} />
                <ListItemSecondaryAction>
                  <Typography variant="subtitle2" align="right">
                    {company?.co_pbx || ''}
                  </Typography>
                </ListItemSecondaryAction>
              </ListItem>
            </>
            {/* {company.co_ruc && company.co_email && company.co_website ? null : (
              <Typography>No hay datos para mostar</Typography>
            )} */}
          </List>
        )}
      </SubCard>
    </Grid>
  );
};

export default PrincipalCard;
