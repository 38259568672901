import { useHistory, useRouteMatch } from 'react-router-dom';

import { useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import MeshStatusChip from '../../../../shared/components/badges/MeshStatusChip';
import { Button, IconButton } from '@material-ui/core';
import { IconEdit, IconList, IconTrash, IconArrowsRightLeft, IconLink } from '@tabler/icons';
import { useAppDispatch } from '../../../../../hooks/useRedux';
import { fireDeleteCurriculumDialog, setCurriculumSelected } from '../../slices/meshSlice';

import useSharedGuard from '../../../../../hooks/useSharedGuard';
import { subPermissionKeys } from '../../constants/permissions';
import { subPermissionKeys as subPermissionKeysHomologation } from '../../constants/permissionsIntHomologation';

interface Props {
  apId: number;
  apCode: string;
  apName: string;
  educationLevel: string;
  educationLevelFather: string;
  status: string;
  apDegree: string;
  numPeriods: number;
  numSubjects: number;
  modality: string;
  casResolution?: string | null;
  ocasResolution?: string | null;
  creationDate: string;
  linkCurriculum?: string;
}

const useStyles = makeStyles((theme) => ({
  cardBox: {
    boxShadow: `0px 0px 20px #EAF0F7`,
    border: `1px solid ${theme.palette.secondary[200]}`,
    borderRadius: 12,
    overflow: 'hidden',
  },
  cardHeader: {
    backgroundColor: theme.palette.primary.main,
    padding: '24px 24px 16px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  codeText: {
    color: '#fff',
    fontWeight: 400,
  },
  headerText: {
    color: '#fff',
    lineHeight: 1.5,
  },
  subtitleText: {
    color: theme.palette.secondary[200],
  },
  apDegree: {
    color: theme.palette.primary[800],
    marginBlockEnd: 8,
  },
  cardBody: {
    padding: '16px 24px 24px',
  },
  detailText: {
    color: theme.palette.primary[800],
  },
  cardDetails: {},
}));

const AcademicProgramCard = ({
  apId,
  apName,
  apCode,
  educationLevel,
  educationLevelFather,
  status,
  apDegree,
  numPeriods,
  numSubjects,
  modality,
  casResolution,
  ocasResolution,
  creationDate,
  linkCurriculum,
}: Props) => {
  const { path } = useRouteMatch();
  const history = useHistory();

  const dispatch = useAppDispatch();

  const classes = useStyles();

  const theme = useTheme();

  const matchUpLg = useMediaQuery(theme.breakpoints.up('xl'));

  const handleDelete = async (meshId: number) => {
    dispatch(setCurriculumSelected(meshId));
    dispatch(fireDeleteCurriculumDialog(true));
  };

  const { hasAccess } = useSharedGuard(subPermissionKeys);
  const { hasAccess: hasAccessIntHom } = useSharedGuard(subPermissionKeysHomologation);

  return (
    <Box className={classes.cardBox}>
      <Grid container direction="column">
        <Grid item>
          <Box className={classes.cardHeader}>
            <Box>
              <Typography variant="h5" component="h2" className={classes.codeText}>
                {apCode}
              </Typography>
              <Typography variant="h3" component="h2" className={classes.headerText}>
                {apName}
              </Typography>
              <Typography variant="subtitle1" className={classes.subtitleText}>
                {educationLevelFather}
              </Typography>
              <Typography variant="subtitle2" className={classes.subtitleText}>
                {educationLevel}
              </Typography>
            </Box>
            <Box>
              <MeshStatusChip statusName={status} />
            </Box>
          </Box>
        </Grid>
        <Grid item>
          <Box className={classes.cardBody}>
            <Grid container direction="column" spacing={2}>
              {/* Title */}
              <Grid item>
                <Typography variant="h4" className={classes.apDegree}>
                  {apDegree}
                </Typography>
              </Grid>

              {/* Details */}
              <Grid item container spacing={1}>
                <Grid item xs={4}>
                  <Typography variant="subtitle1" className={classes.detailText}>
                    Períodos
                  </Typography>
                  <Typography variant="subtitle2">{numPeriods}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="subtitle1" className={classes.detailText}>
                    Materias
                  </Typography>
                  <Typography variant="subtitle2">{numSubjects}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="subtitle1" className={classes.detailText}>
                    Modalidades
                  </Typography>
                  <Typography variant="subtitle2">{modality}</Typography>
                </Grid>
                <Grid item xs={4}>
                  {/* CAS CES */}
                  <Typography variant="subtitle1" className={classes.detailText}>
                    Resolución CES
                  </Typography>
                  <Typography variant="subtitle2">{casResolution}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="subtitle1" className={classes.detailText}>
                    Resolución OCAS
                  </Typography>
                  <Typography variant="subtitle2">{ocasResolution}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="subtitle1" className={classes.detailText}>
                    Fecha de Creación
                  </Typography>
                  <Typography variant="subtitle2">{creationDate}</Typography>
                </Grid>
              </Grid>

              {/* Buttons */}
              <Grid item container spacing={1} alignItems="center">
                {hasAccess('edit') && (
                  <Grid item>
                    <Button
                      variant="outlined"
                      size={matchUpLg ? 'medium' : 'small'}
                      startIcon={<IconEdit size={20} />}
                      onClick={() => history.push(`${path}/${apId}/editar`)}
                    >
                      Editar
                    </Button>
                  </Grid>
                )}

                {hasAccess('getOne') && (
                  <Grid item>
                    <Button
                      variant="outlined"
                      size={matchUpLg ? 'medium' : 'small'}
                      startIcon={<IconList size={20} />}
                      onClick={() => history.push(`${path}/${apId}/asignar-materias`)}
                    >
                      Materias
                    </Button>
                  </Grid>
                )}
                {hasAccessIntHom('read') && (
                  <Grid item>
                    <Button
                      variant="outlined"
                      size={matchUpLg ? 'medium' : 'small'}
                      startIcon={<IconArrowsRightLeft size={20} />}
                      onClick={() => history.push(`${path}/${apId}/materias-homologadas`)}
                    >
                      Materias Int. homologadas
                    </Button>
                  </Grid>
                )}

                {hasAccess('delete') && (
                  <Grid item>
                    <IconButton
                      size={matchUpLg ? 'medium' : 'small'}
                      sx={{ color: 'error.main' }}
                      onClick={() => handleDelete(apId)}
                    >
                      <IconTrash size={20} />
                    </IconButton>
                  </Grid>
                )}

                {linkCurriculum && (
                  <Grid item>
                    <Button
                      variant="outlined"
                      startIcon={<IconLink size={20} />}
                      onClick={() => window.open(linkCurriculum, '_blank')}
                    >
                      Link a malla
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AcademicProgramCard;
