import {
  Grid,
  TextField,
  Checkbox,
  Typography,
  FormControlLabel,
  Radio,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/useRedux';
import {
  useGetEvaluationStudentToQualifyDetailQuery,
  useQualifyEvaluationMutation,
} from '../../slices/evaluationApiSlice';
import MainCard from '../../../../../shared/components/cards/MainCard';
import { renderSkeletonTable } from '../../../../../shared/helpers/render';
import { EvaluationDetailQuestion } from '../../interfaces/evaluation-qualify.interfaces';
import { useGetCatalogByAcronymChildrenQuery } from '../../../../../shared/slices/catalogApi';
import { OptionAnswer } from '../../interfaces/evaluation-qualify.interfaces';
import { useState } from 'react';
import { selectQuestionQualified } from '../../slices/evaluationSlice';
import { useTranslation } from 'react-i18next';
import SuccessAlert from '../../../../../shared/components/alerts/SuccessAlert';
import ButtonBack from '../../../Components/ButtonBack';
import { IconDownload } from '@tabler/icons';
import downloadjs from 'downloadjs';

interface QuestionProps {
  question: EvaluationDetailQuestion;
  current: number;
}

const EvaluationQualify = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { evaluationId, studentId, userId } = useParams<{
    evaluationId: string;
    studentId: string;
    userId: string;
  }>();

  const { currentProfile } = useAppSelector((state) => state.access);

  const [questionQualified, setQuestionQualified] = useState<number>(0);

  const [qualifyQuestion, { isLoading: isLoadingQualifyQuestion, isSuccess }] =
    useQualifyEvaluationMutation();

  const { isLoading } = useGetEvaluationStudentToQualifyDetailQuery({
    evaluationId: Number(evaluationId),
    studentId: Number(studentId),
    userId: Number(userId),
    userProfileId: currentProfile?.profileId!,
  });

  /**
   * Obtener tipos de respuesta.
   */
  const { data: dataTypeQuestion, isLoading: isLoadingTypeQuestion } =
    useGetCatalogByAcronymChildrenQuery({
      acronym: 'tipo-respuesta',
      profileId: currentProfile?.profileId!,
    });

  const { evaluationToQualify } = useAppSelector((state) => state.evaluationTeacher.evaluation);

  /**
   * Calificar una pregunta seleccionada, el puntaje
   * debería ser limitado por el puntaje configurado.
   * Actualización del puntaje.
   */
  const handleQualifyQuestion = async (questionId: number, score: number) => {
    dispatch(selectQuestionQualified(questionId));

    setQuestionQualified(questionId);

    await qualifyQuestion({
      evaluationId: Number(evaluationId),
      questionId: questionId,
      studentId: Number(studentId),
      userId: Number(userId),
      userProfileId: currentProfile?.profileId!,
      payload: {
        csact_score_evaluate: score,
        csact_observations: '',
      },
    });
  };

  const Multiple = ({ description, isAnswer }: OptionAnswer, index: number) => {
    return (
      <Grid container item xs={12} key={`${index}-${description}`}>
        <Grid item xs={10} sx={{ mt: 2 }}>
          <TextField variant="standard" fullWidth disabled value={`${index + 1}. ${description}`} />
        </Grid>
        <Grid item sx={{ mt: 1 }}>
          <Checkbox disabled checked={Number(isAnswer) === 1 ? true : false} />
        </Grid>
      </Grid>
    );
  };

  const Single = ({ description, isAnswer }: OptionAnswer, index: number) => {
    return (
      <Grid container key={`${index}-${description}`}>
        <Grid item xs={10} sx={{ mt: 2 }}>
          <TextField variant="standard" fullWidth disabled value={`${index + 1}. ${description}`} />
        </Grid>
        <Grid item sx={{ mt: 1 }}>
          <FormControlLabel
            label=""
            disabled
            control={<Radio />}
            checked={Number(isAnswer) === 1 ? true : false}
          />
        </Grid>
      </Grid>
    );
  };

  const commentary = (comment: string | null) => {
    return (
      <Grid container>
        <Grid item xs={10} sx={{ mt: 2 }}>
          <TextField fullWidth rows={5} defaultValue={comment} multiline disabled />
        </Grid>
      </Grid>
    );
  };
  const FileQuestion = (urlFile: string) => {
    // const apiUrl = localStorage.getItem('apiUrl');
    const handleDownload = () => {
      try {
        downloadjs(urlFile);
      } catch (error) {
        console.log('errr', error);
      }
    };
    return (
      <Button onClick={handleDownload} endIcon={<IconDownload />}>
        Descargar Archivo
      </Button>
    );
  };
  const renderOptions = (
    typeAnswerId: number,
    options: OptionAnswer[],
    comment: string | null,
    urlFile: string
  ) => {
    const typeQuestion = dataTypeQuestion?.find((e) => e.id === typeAnswerId);

    if (typeQuestion?.cat_name! === 'Opcion Multiple') {
      return options?.map((e, index) => Multiple(e, index));
    } else if (typeQuestion?.cat_name! === 'Opcion Seleccion') {
      return options.map((e, index) => Single(e, index));
    } else if (typeQuestion?.cat_name! === 'Archivo - Herramientas') {
      return FileQuestion(urlFile);
      // return null;
    } else {
      return commentary(comment);
    }
  };

  const RenderQuestion = ({
    question: {
      qualified,
      question,
      score,
      comment,
      typeAnswerId,
      isManual,
      options,
      id,
      califcacion,
      urlFile,
    },
    current,
  }: QuestionProps) => {
    const [scoreSelected, setScoreSelected] = useState<number>(Number(score));

    return (
      <Grid item xs={12} key={`${current}-${question}`} sx={{ mb: 3 }}>
        {isSuccess && <SuccessAlert message="Calificado Correctamente" />}
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Typography variant="h4">{`${current + 1}. ${question}`} </Typography>
          <Typography variant="h4">Calificacion sobre : {score} </Typography>
          <Typography variant="h4">Puntaje: {califcacion} </Typography>
        </Grid>

        {renderOptions(Number(typeAnswerId), options, comment, urlFile)}

        {
          <Grid
            container
            item
            xs={12}
            sx={{ mt: 2 }}
            spacing={2}
            style={{ justifyContent: 'left', alignItems: 'center' }}
          >
            <Grid item xs={4}>
              <TextField
                disabled={isManual === '0'}
                fullWidth
                label="Puntaje"
                InputProps={{ inputProps: { min: 0, max: Number(score), step: 0.5 } }}
                type="number"
                placeholder={`El puntaje actual es : ${Number(califcacion)}`}
                onChange={(value) => setScoreSelected(Number(value.target.value))}
                variant="outlined"
              />
            </Grid>
            {isManual === '1' && (
              <Grid item>
                <Button
                  onClick={() => handleQualifyQuestion(id, scoreSelected)}
                  variant="contained"
                  endIcon={
                    isLoadingQualifyQuestion &&
                    questionQualified === id && <CircularProgress size={20} />
                  }
                  disabled={isLoadingQualifyQuestion}
                  size="small"
                >
                  {qualified
                    ? t('teacherVirtualClassroom.tests.calificate.update')
                    : t('teacherVirtualClassroom.tests.calificate.send')}
                </Button>
              </Grid>
            )}
          </Grid>
        }
      </Grid>
    );
  };

  const renderQuestions = () => {
    return (
      <>
        {evaluationToQualify &&
          evaluationToQualify?.map((e, index) =>
            e ? <RenderQuestion key={index} question={e} current={index} /> : 'Sin respuesta'
          )}
      </>
    );
  };

  const EvaluationToQualify = () => {
    return (
      <Grid container spacing={1} alignItems="flex-start">
        <Grid item xs={12} md={6} margin=" 0 auto " container spacing={1}>
          <Grid item xs={12}>
            <MainCard>
              <Grid container spacing={4}>
                <Grid item xs={12} md={12} container spacing={1} justifyContent="space-between">
                  <Grid container sx={{ mb: 1 }} justifyContent="space-between">
                    {evaluationToQualify && evaluationToQualify.length && renderQuestions()}
                  </Grid>
                </Grid>
              </Grid>
            </MainCard>
          </Grid>
        </Grid>
        <ButtonBack />
      </Grid>
    );
  };

  return (
    <>{isLoading || isLoadingTypeQuestion ? renderSkeletonTable(5, 5) : <EvaluationToQualify />}</>
  );
};

export default EvaluationQualify;
