import React, { useEffect, useMemo, useState } from 'react';

// material-ui
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  TextField,
  InputAdornment,
  Button,
  CircularProgress,
  Autocomplete,
  FormControl,
  FormGroup,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import { useParams } from 'react-router-dom';

import InputLabel from '../../../../shared/components/forms/InputLabel';

import { renderErrorOrEmptyRow, renderSkeletonRows } from '../../../../shared/helpers/render';

// helpers
import { isPrimitive, objectKeys } from '../../../../shared/helpers';

// types

import {
  CustomCellProperties,
  CustomRenderers,
  TableHeaders,
} from '../../../../shared/interfaces/material-ui.interfaces';

import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { SerializedError } from '@reduxjs/toolkit';

import { DataSearchHistoryAcademic } from '../../interfaces/data-search.interfaces';

import { useAppSelector } from '../../../../../hooks/useRedux';
import { useGetHistoryCalificationStatusMatterQuery } from '../../slices/academicCounselingApi';
import MainCard from '../../../../shared/components/cards/MainCard';
import CustomPaginationAndItems from '../../../../shared/components/tables/CustomPaginationAndItems';
import { useTranslation } from 'react-i18next';
import { useGetStudentYearsPeriodsQuery } from '../../../../maintenance/students/slices/studentsApiSlice';
import {
  StudentPeriod,
  YearsStudentPeriod,
} from '../../../../maintenance/students/interfaces/students.interface';

interface Props<T extends {}> {
  headers: TableHeaders<T>;
  data: T[];
  keyExtractor: (item: T) => string;
  customRenderers?: CustomRenderers<T>;
  customDataCellsProperties?: CustomCellProperties<T>;
  customHeadersCellsProperties?: CustomCellProperties<T>;
  isLoading: boolean;
  isFetching: boolean;
  error: FetchBaseQueryError | SerializedError | undefined;
  perPage: number;
  switchChecked: boolean;
  setSwitchChecked: React.Dispatch<React.SetStateAction<boolean>>;
  setPerPage: React.Dispatch<React.SetStateAction<number>>;
  page: number;
  numHeader: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  ActionButtons?: JSX.Element;
  setDataSearch: React.Dispatch<React.SetStateAction<DataSearchHistoryAcademic>>;
  dataSearch: DataSearchHistoryAcademic;
}

const HistoryTable = <T extends {}>({
  data,
  headers,
  customRenderers,
  keyExtractor,
  customDataCellsProperties,
  customHeadersCellsProperties,
  numHeader,
  isLoading,
  isFetching,
  error,
  perPage,
  setPerPage,
  page,
  setPage,
  setDataSearch,
  dataSearch,
  switchChecked,
  setSwitchChecked,
}: Props<T>) => {
  const { studentId } = useParams<{ studentId: string }>();
  const { t } = useTranslation();

  const [year, setYear] = useState<string>(dataSearch.year || '');

  const [period, setPeriod] = useState<number>(dataSearch.period || 0);

  const [periodReverse, setPeriodReverse] = useState<YearsStudentPeriod[]>([]);

  const [status, setStatus] = useState<number>(dataSearch.status || 0);

  const { currentProfile, currentStudent } = useAppSelector((state) => state.access);

  const {
    data: dataYearList,
    isLoading: isLoadingYearList,
    isFetching: isFetchingYearList,
  } = useGetStudentYearsPeriodsQuery({
    studentId: currentStudent?.id?.toString() || studentId!,
  });

  const {
    data: MatterStatusList,
    isLoading: isLoadingMatterStatus,
    isFetching: isFetchinMatterStatus,
  } = useGetHistoryCalificationStatusMatterQuery({
    profileId: currentProfile?.profileId!,
  });

  const yearList = useMemo(
    () => periodReverse?.map((dt, i) => ({ id: i + 1, value: dt.year })) || [],
    [periodReverse]
  );

  const periodsList: { [year: string]: StudentPeriod[] } | undefined = useMemo(
    () => periodReverse?.reduce((object, item) => ({ ...object, [item.year]: item.periods }), {}),
    [periodReverse]
  );

  const dataSearchCalification: DataSearchHistoryAcademic = {
    year: year,
    period: period,
    status: status,
  };

  const dataSearchBlank: DataSearchHistoryAcademic = {
    year: '',
    period: 0,
    status: 0,
  };

  const obtainInitialSlice = function (_page: number, _perPage: number): number {
    if (_page > 1) {
      return _perPage * _page - _perPage;
    }
    return 0;
  };

  const renderRows = () => {
    const dataSlice = data?.slice(obtainInitialSlice(page, perPage), perPage * page);
    return <>{dataSlice?.map(renderRow)}</>;
  };

  const renderTitle = () => {
    return (
      <>
        {switchChecked
          ? t('students.academic_counseling.all_scores')
          : t('students.academic_counseling.score_history')}
      </>
    );
  };

  const renderRow = (row: T) => {
    return (
      <TableRow className="table-row" key={keyExtractor(row)}>
        {objectKeys(row).map((cell) => {
          const customRenderer = customRenderers?.[cell];

          if (customRenderer) {
            return (
              <TableCell
                key={`${keyExtractor(row)}-${cell.toString()}`}
                {...customDataCellsProperties?.[cell]}
              >
                {customRenderer(row)}
              </TableCell>
            );
          }

          return (
            <TableCell
              key={`${keyExtractor(row)}-${cell.toString()}`}
              {...customDataCellsProperties?.[cell]}
            >
              {isPrimitive(row[cell]) ? row[cell] : ''}
            </TableCell>
          );
        })}
      </TableRow>
    );
  };

  useEffect(() => {
    if (dataYearList && dataYearList.length > 0) {
      setPeriodReverse([...dataYearList].reverse());
    }
  }, [dataYearList]);

  return (
    <>
      <Grid
        container
        style={{ marginBottom: 20 }}
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Grid item container spacing={2} xs={12}>
          <Grid item container spacing={2} xs={12} sm={6}>
            <Grid item xs={4}>
              <InputLabel>{t('students.academic_counseling.year')}</InputLabel>
              <Autocomplete
                options={yearList || []}
                loading={isLoadingYearList}
                loadingText={t('students.academic_counseling.year.loading')}
                value={yearList.find((e) => e.value === year) || null}
                onChange={(_, value) => setYear(value?.value || '')}
                getOptionLabel={(option) => option.value}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={t('students.academic_counseling.year.placeholder')}
                  />
                )}
                fullWidth
                clearIcon={null}
              />
            </Grid>
            <Grid item xs={8}>
              <InputLabel>{t('students.academic_counseling.period')}</InputLabel>
              {isLoadingYearList || isFetchingYearList ? (
                <>
                  <TextField
                    disabled
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <CircularProgress size={25} />
                        </InputAdornment>
                      ),
                    }}
                    placeholder={t('students.academic_counseling.period.loading')}
                  />
                </>
              ) : (
                <Autocomplete
                  options={periodsList && year ? periodsList[year] : []}
                  loading={isLoadingYearList}
                  loadingText={t('students.academic_counseling.period.loading')}
                  value={
                    (periodsList && year && periodsList[year]?.find((e) => e.id === period)) || null
                  }
                  getOptionLabel={(option) => option.per_name}
                  onChange={(_, value) => setPeriod(value?.id || 0)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={t('students.academic_counseling.period.placeholder')}
                    />
                  )}
                  fullWidth
                  clearIcon={null}
                />
              )}
            </Grid>
          </Grid>
          <Grid item container spacing={2} xs={6} sm={3}>
            <Grid item xs={12}>
              <InputLabel>{t('students.academic_counseling.status')}</InputLabel>
              {isLoadingMatterStatus || isFetchinMatterStatus ? (
                <>
                  <TextField
                    disabled
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <CircularProgress size={25} />
                        </InputAdornment>
                      ),
                    }}
                    placeholder={t('students.academic_counseling.status.loading')}
                  />
                </>
              ) : (
                <Autocomplete
                  options={MatterStatusList?.status || []}
                  loading={isLoadingMatterStatus}
                  loadingText={t('students.academic_counseling.status.loading')}
                  value={
                    (MatterStatusList && MatterStatusList?.status?.find((e) => e.id === status)) ||
                    null
                  }
                  getOptionLabel={(option) => option.st_name}
                  onChange={(_, value) => setStatus(value?.id || 0)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={t('students.academic_counseling.status.placeholder')}
                    />
                  )}
                  fullWidth
                  clearIcon={null}
                />
              )}
            </Grid>
          </Grid>
          <Grid
            item
            container
            spacing={1}
            direction="row"
            justifyContent="center"
            alignItems="flex-end"
            xs={12}
            sm={3}
          >
            <Grid item xs={4} md={4}>
              <Button
                onClick={() => {
                  setYear('');
                  setPeriod(0);
                  setStatus(0);
                  setDataSearch(dataSearchBlank);
                }}
                fullWidth
                variant="outlined"
              >
                {t('students.academic_counseling.reset')}
              </Button>
            </Grid>
            <Grid item xs={6} md={6}>
              <Button
                fullWidth
                onClick={() => {
                  setDataSearch(dataSearchCalification);
                }}
                variant="contained"
              >
                {t('students.academic_counseling.filter')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* Switch history calification */}
      <Grid
        container
        style={{ marginBottom: 20 }}
        justifyContent="flex-end"
        alignItems="center"
        spacing={2}
      >
        <Grid item container spacing={2} xs={12}>
          <Grid item container spacing={2} xs={12} sm={6}>
            <Grid item xs={4}>
              <FormControl>
                <FormGroup row>
                  <FormControlLabel
                    onChange={(e: any) => {
                      setSwitchChecked(e.target.checked);
                      //if(e.target.checked === true) dispatch(setAllHistory(e.target.checked));
                    }}
                    control={<Switch checked={switchChecked} />}
                    label={t('students.academic_counseling.all_scores')}
                  />
                </FormGroup>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* Table container */}
      <MainCard title={renderTitle()}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {Object.keys(headers).map((key) => (
                  <TableCell key={key} {...customHeadersCellsProperties?.[key as keyof T]}>
                    {headers[key as keyof T]}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {isFetching ? renderSkeletonRows(perPage, numHeader) : renderRows()}

              {error && renderErrorOrEmptyRow(numHeader, error as string)}
              {(data?.length || 0) === 0 && renderErrorOrEmptyRow(numHeader)}
            </TableBody>
          </Table>
        </TableContainer>
      </MainCard>

      <CustomPaginationAndItems
        error={error}
        isLoading={isLoading}
        total={data?.length || 0}
        perPage={perPage}
        page={page}
        setPage={setPage}
        setPerPage={setPerPage}
        perPageBox
        paginationBox
      />
    </>
  );
};

export default HistoryTable;
