export const activityTypes: {
  [key: string]: {
    path: string;
    exactPath?: (id: number | string) => string;
    icon: string;
    name: string;
  };
} = {
  TPA1: { path: 'tareas', icon: 'IconBook2', name: 'tareas' }, // taller individual
  TPA2: { path: 'tareas', icon: 'IconBook2', name: 'tareas' }, // taller grupal
  TPA3: {
    path: 'foros',
    exactPath: (id) => `foros/${id}/view`,
    icon: 'IconMessage',
    name: 'foros',
  }, // foros
  TPA4: { path: 'test', icon: 'IconClipboardText', name: 'Evaluaciones' }, // evaluaciones
  TPA5: {
    path: 'foros',
    exactPath: (id) => `foros/${id}/view`,
    icon: 'IconMessage',
    name: 'foros',
  }, // Materiales
  TPA6: {
    path: 'foros',
    exactPath: (id) => `foros/${id}/view`,
    icon: 'IconMessage',
    name: 'foros',
  }, // anuncios
  TPA7: {
    path: 'foros',
    exactPath: (id) => `foros/${id}/view`,
    icon: 'IconMessage',
    name: 'foros',
  }, // mensajeria
  TPA8: { path: 'reuniones', icon: 'IconBrandZoom', name: 'Reuniones' }, // clase virtual
  TPA9: { path: 'tareas', icon: 'IconBook2', name: 'tareas' }, // Tarea individual
  TPA10: { path: 'tareas', icon: 'IconBook2', name: 'tareas' }, // Tarea grupal
};
