import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISearchTable } from '../../../shared/interfaces/paginatedTable.interface';
import { Area } from '../interfaces/area.interfaces';

interface AreasSliceState {
  areasList: Area[];

  areasTable: ISearchTable;
}

const initialState: AreasSliceState = {
  areasList: [],

  areasTable: {
    page: 1,
    perPage: 5,
    search: '',
  },
};

const areasSlice = createSlice({
  name: 'area',
  initialState,
  reducers: {
    setAreasList: (state, action: PayloadAction<Area[]>) => {
      state.areasList = action.payload;
    },

    updateAreasTableStates: (state, action: PayloadAction<ISearchTable>) => {
      state.areasTable = action.payload;
    },
  },
});

export const { setAreasList, updateAreasTableStates } = areasSlice.actions;

export default areasSlice.reducer;
