import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import CustomSideDialog from '../../../shared/components/dialogs/CustomSideDialog';
import { setIsOpenChangeStatusModal, setSyllabusIdSelected } from '../slices/SyllabusSlice';
import ChangeStatusSyllabusForm from './ChangeStatusSyllabusForm';

const ChangeStatusSyllabusDialog = () => {
  const dispatch = useAppDispatch();

  const { isOpenChangeStatusModal } = useAppSelector((state) => state.syllabus);

  const handleClose = () => {
    dispatch(setIsOpenChangeStatusModal(false));
    dispatch(setSyllabusIdSelected(null));
  };

  return (
    <CustomSideDialog
      open={isOpenChangeStatusModal}
      handleCloseDialog={handleClose}
      title="Cambio de estado"
    >
      <ChangeStatusSyllabusForm />
    </CustomSideDialog>
  );
};

export default ChangeStatusSyllabusDialog;
