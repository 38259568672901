import { Box, Typography, useTheme, Checkbox, Chip } from '@material-ui/core';
import { Attendance } from '../interfaces/attendance.interfaces';
import IconsItem from './IconsItem';
import { useAppSelector, useAppDispatch } from '../../../../../hooks/useRedux';
import { setAttendancesToJustify } from '../../slices/virtualClassroomSlice';

interface Props {
  attendance: Attendance;
}

const TableDetailsItemAttendance = ({ attendance }: Props) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { attendancesToJustify } = useAppSelector((state) => state.virtualClassroomStudent);

  const handleChange = (justifyId: number) => {
    if (attendancesToJustify.some((justify) => justify === justifyId)) {
      dispatch(
        setAttendancesToJustify(attendancesToJustify.filter((justify) => justify !== justifyId))
      );
    } else {
      dispatch(setAttendancesToJustify([...attendancesToJustify, justifyId]));
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        p: 2,
        borderTop: `1px solid ${theme.palette.secondary.main}`,
      }}
    >
      <Box
        sx={{
          width: '50%',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Checkbox
          checked={attendancesToJustify.some((justify) => justify === attendance.id)}
          onChange={() => handleChange(attendance.id)}
          inputProps={{ 'aria-label': 'controlled' }}
          disabled={attendance.justified}
        />

        <IconsItem type={attendance.type_fault} />

        <Typography
          sx={{
            color: theme.palette.primary.dark,
            fontWeight: 'bold',
            fontSize: theme.typography.body2,
            ml: 1,
          }}
        >
          {attendance.type_fault}
        </Typography>
      </Box>
      <Box
        sx={{
          width: '20%',
        }}
      >
        <Typography
          sx={{
            color: theme.palette.primary.dark,
            fontWeight: 'bold',
            fontSize: theme.typography.body2,
            ml: 3,
          }}
        >
          {attendance.justified ? (
            <Chip label="Justificado" color={'primary'} />
          ) : (
            <Chip label="Sin justificar" />
          )}
        </Typography>
      </Box>
      <Box
        sx={{
          width: '30%',
        }}
      >
        <Typography
          sx={{
            color: theme.palette.primary.dark,
            fontWeight: 'bold',
            fontSize: theme.typography.body2,
            ml: 3,
          }}
        >
          {attendance.assistant_date}
        </Typography>
      </Box>
    </Box>
  );
};

export default TableDetailsItemAttendance;
