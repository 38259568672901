import DeleteAlert from '../../../../shared/components/dialogs/DeleteAlert';

import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';

import { useDeleteInternalHomologateSubjectMutation } from '../../slices/meshsApiSlice';
import {
  fireDeleteSubjectInternalHomologateDialog,
  resetSubjectCurriculumSelected,
} from '../../slices/meshSlice';

const HomologationInternalSubjectDialog = () => {
  const dispatch = useAppDispatch();

  const { deleteSubjectInternalHomologateDialogOpened, subjectInternalHomologateSelected } =
    useAppSelector((state) => state.meshs);
  const { currentProfile } = useAppSelector((state) => state.access);
  const profileId = currentProfile!.profileId;

  const [deleteHomologateSubject, { isLoading, isSuccess, isError, error }] =
    useDeleteInternalHomologateSubjectMutation();

  const handleDelete = () => {
    deleteHomologateSubject({
      profileId,
      internalHomologateSubjectId: subjectInternalHomologateSelected!,
    });
  };

  const handleClose = () => {
    dispatch(fireDeleteSubjectInternalHomologateDialog(false));
    dispatch(resetSubjectCurriculumSelected());
  };

  return (
    <DeleteAlert
      open={deleteSubjectInternalHomologateDialogOpened}
      onClose={handleClose}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      handleDelete={handleDelete}
      error={error as string}
    />
  );
};

export default HomologationInternalSubjectDialog;
