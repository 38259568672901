// import { useTranslation } from 'react-i18next';
import DeleteAlert from '../../../../shared/components/dialogs/DeleteAlert';
import { EducationLevelContacts } from '../../interfaces/educationLevels.interfaces';
import {
  useDeleteEduLevelContactMutation,
  useDeleteEduLevelNetworkMutation,
} from '../../slices/educationLevelsApiSlice';
import { Contact, Network } from './ContactDeanForm';
// import { resetRoleIdToDelete, setDeleteModalOpen } from '../slices/rolesSlice';

interface Props {
  openModal: boolean;
  educationLevelId: number;
  index: number;
  id: number;
  formType: Contact | Network | null;
  dataList: EducationLevelContacts;
  handleCloseModal: () => void;
}

const DeleteContactDialog = ({
  openModal,
  formType,
  index,
  id,
  dataList,
  educationLevelId,
  handleCloseModal,
}: Props) => {
  // const { t } = useTranslation();

  const [
    deleteCTrigger,
    {
      isLoading: isLoadingC,
      isSuccess: isSuccessC,
      isError: isErrorC,
      error: errorC,
      reset: resetC,
    },
  ] = useDeleteEduLevelContactMutation();
  const [
    deleteNTrigger,
    {
      isLoading: isLoadingN,
      isSuccess: isSuccessN,
      isError: isErrorN,
      error: errorN,
      reset: resetN,
    },
  ] = useDeleteEduLevelNetworkMutation();

  const handleDelete = () => {
    if (formType === 'contact')
      return deleteCTrigger({
        contactId: id,
      }).unwrap();

    return deleteNTrigger({
      newtworkId: id,
    }).unwrap();
    // deleteTrigger({
    //   payload: {
    //     education_level_id: +educationLevelId,
    //     education_levels_contact:
    //       formType === 'contact'
    //         ? [...dataList.education_levels_contact.filter((_, i) => i !== index)]
    //         : [...dataList.education_levels_contact],
    //     education_levels_network:
    //       formType === 'network'
    //         ? [...dataList.education_levels_network.filter((_, i) => i !== index)]
    //         : [...dataList.education_levels_network],
    //   },
    // }).unwrap();
  };

  const handleClose = () => {
    handleCloseModal();

    setTimeout(() => {
      if (formType === 'contact') return resetC();
      return resetN();
    }, 300);
  };
  //

  return (
    <DeleteAlert
      open={openModal}
      onClose={handleClose}
      isLoading={isLoadingC || isLoadingN}
      isSuccess={isSuccessC || isSuccessN}
      isError={isErrorC || isErrorN}
      handleDelete={handleDelete}
      // deleteTitle={t('academic.areas.delete.title')}
      error={(errorC || errorN) as string}
    />
  );
};

export default DeleteContactDialog;
