import { Redirect, Switch } from 'react-router-dom';
import { useAppSelector } from '../../../../hooks/useRedux';
import GuardRoute from '../../../../router/guards/GuardRoute';
import { permissionKey, subPermissionKeys } from '../constants/permissions';
import AddEconomicGroupScreen from '../screens/AddEconomicGroupScreen';
import EconomicGroupsScreens from '../screens/EconomicGroupsScreens';
import EditEconomicGroupScreen from '../screens/EditEconomicGroupScreen';

const EconomicGroupsRouter = () => {
  const { currentModule } = useAppSelector((state) => state.access);

  const hasPermission = 'economicGroup' in currentModule?.permissions!;

  if (!hasPermission) return <Redirect to="/" />;
  return (
    <>
      <Switch>
        <GuardRoute
          exact
          path="/administrar-grupos-economicos"
          component={EconomicGroupsScreens}
          subPermissionKey={subPermissionKeys.read}
          permissionKey={permissionKey}
        />
        <GuardRoute
          exact
          path="/administrar-grupos-economicos/agregar"
          component={AddEconomicGroupScreen}
          subPermissionKey={subPermissionKeys.create}
          permissionKey={permissionKey}
        />
        <GuardRoute
          exact
          path="/administrar-grupos-economicos/:economicGroupId/editar"
          component={EditEconomicGroupScreen}
          subPermissionKey={subPermissionKeys.edit}
          permissionKey={permissionKey}
        />
      </Switch>
    </>
  );
};

export default EconomicGroupsRouter;
