import { useState } from 'react';

import { Box, Button, Card, Grid, makeStyles } from '@material-ui/core';
// import Transitions from '../extended/Transitions';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  // cardMedia: {
  //   '& .MuiCardMedia-img': {
  //     // '& img': {
  //     transition: 'transform .5s, filter 1.5s ease-in-out',
  //     filter: ' grayscale(100%)',
  //     width: '200px',
  //     '&:hover': {
  //       filter: 'grayscale(0)',
  //       transform: 'scale(1.1)',
  //     },
  //   },
  // },
  cardMediaIcon: {
    // '& img': {
    // position: 'absolute',
    transition: 'transform .5s, filter 1.5s ease-in-out',
    margin: 'auto',
    width: '50%',
  },
  cardMediaIconZoom: {
    transition: 'transform .5s, filter 1.5s ease-in-out',
    transform: 'scale(1.2)',
  },

  overlayCard: {
    minHeight: 270,
    color: theme.palette.common.white,
  },

  card: {
    background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[50],
    // border: `4px solid ${theme.palette.primary[800]}`,
    // borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[100],
    transition: 'transform .25s, filter 2.5s ease-in-out',

    // '&:hover': {
    //   backgroundSize: '60%',
    // },
  },
  cardActive: {
    // background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[50],
    border: `4px solid ${theme.palette.primary[800]}`,
    // borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[100],
    transition: 'transform .25s, filter 2.5s ease-in-out',

    // '&:hover': {
    //   backgroundSize: '60%',
    // },
  },
}));

interface Props {
  image: string;
  moduleKeyword: string;
  moduleName: string;
  onClick: () => void;
}

const ModuleCard = ({ image, moduleKeyword: key, moduleName, onClick }: Props) => {
  const { t } = useTranslation();

  const [isMouseOver, setIsMouseOver] = useState<boolean>();

  const classes = useStyles();

  const handleSetModule = (e: any) => {
    e.preventDefault();
    onClick();
  };

  return (
    <>
      <Card
        className={`${isMouseOver ? classes.cardActive : classes.card} ${classes.overlayCard}`}
        onMouseEnter={() => {
          setIsMouseOver(true);
        }}
        onMouseLeave={() => {
          setIsMouseOver(false);
        }}
        sx={{ position: 'relative', cursor: 'pointer' }}
        onClick={handleSetModule}
      >
        <Box
          className={`${isMouseOver ? classes.cardMediaIconZoom : ''}`}
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',

            background: `url(${image})`,

            // backgroundSize: '50%',
            // backgroundSize: 'containe',
            transform: 'scale(1.1)',
            backgroundPosition: '50% 35%',
            backgroundRepeat: 'no-repeat',
            // transition: 'transform 3s ease-out',
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            bottom: 15,
            left: 0,
            width: '100%',
          }}
        >
          <Grid container justifyContent="center">
            {/* <Grid item xs={4}> */}
            <Button
              variant={isMouseOver ? 'contained' : 'outlined'}
              onClick={handleSetModule}
              size="small"
              sx={{ minWidth: '40%' }} /* fullWidth */
            >
              {t(`shared.modules.${key}`).toUpperCase()}
            </Button>
            {/* </Grid> */}
          </Grid>
        </Box>
      </Card>
    </>
  );
};

export default ModuleCard;
