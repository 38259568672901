// import {
//   useTheme,
// } from '@material-ui/core';

// import { ExpandMoreOutlined } from '@material-ui/icons';
// import { useMaterialStyles } from '../constants/styles';
import { Box, Typography, useTheme } from '@material-ui/core';
import {
  Material,
  MaterialChildrenContent,
  ToolsMaterial,
} from '../interfaces/materialsStudent.interfaces';
import Accordion from './Accordion';
import SubAccordion from './SubAccordion';
import SubAccordionItem from './SubAccordionItem';

interface Props {
  parcial: Material;
  modalOptions: {
    handleOpen: () => void;
    setDataModal: React.Dispatch<React.SetStateAction<ToolsMaterial | undefined>>;
  };
}

const MaterialItem = ({ parcial, modalOptions }: Props) => {
  const { act_description, children_content, tools_materials } = parcial;
  const { handleOpen, setDataModal } = modalOptions;
  const theme = useTheme();

  const amount = children_content.length + tools_materials.length;

  const setSubAccordionAndTools = (
    children_content: MaterialChildrenContent[] | object,
    tools_materials: ToolsMaterial[]
  ) => {
    if (
      (Array.isArray(children_content) && children_content.length > 0) ||
      (tools_materials.length > 0 && Array.isArray(children_content))
    ) {
      return (
        <>
          {tools_materials.map((tool) => (
            <SubAccordionItem
              key={tool.id}
              tool={tool}
              modalOptions={{ handleOpen, setDataModal }}
            />
          ))}
          {children_content.map((children) => {
            const { tools_materials, children_content, act_description } = children;
            return (
              <SubAccordion
                key={children.id}
                title={act_description}
                folders={children_content}
                tools={tools_materials}
              >
                {setSubAccordionAndTools(children_content, tools_materials)}
              </SubAccordion>
            );
          })}
        </>
      );
    } else {
      return (
        <Box
          sx={{
            padding: 1,
            borderRadius: '8px',
            border: `1px solid ${theme.palette.primary.light}`,
            mb: 2,
          }}
        >
          <Typography
            sx={{
              fontWeight: theme.typography.fontWeightBold,
              color: theme.palette.primary.dark,
              textAlign: 'center',
            }}
          >
            No hay información por el momento
          </Typography>
        </Box>
      );
    }
  };

  return (
    <Accordion title={act_description} amount={amount}>
      {setSubAccordionAndTools(children_content, tools_materials)}
    </Accordion>
  );
};

export default MaterialItem;
