import { useAppSelector } from '../../../../hooks/useRedux';

import { useGetAvailablesCampusQuery } from '../../../maintenance/campus/slices/campusApiSlice';
import { useGetCatalogByAcronymQuery } from '../../../shared/slices/catalogApi';

const useRegisterSubjectByCurriculumOptions = () => {
  const { currentProfile } = useAppSelector((state) => state.access);
  const profileId = currentProfile!.profileId;

  const { data: campus = [], isLoading: isLoadingCampus } = useGetAvailablesCampusQuery({
    profileId,
  });

  const { data: jornadas = [], isLoading: isLoadingJornadas } = useGetCatalogByAcronymQuery({
    profileId,
    acronym: 'J',
  });

  const isFetchingOptions = isLoadingCampus || isLoadingJornadas;

  return {
    isFetchingOptions,
    campus,
    jornadas,
    isLoadingCampus,
    isLoadingJornadas,
  };
};

export default useRegisterSubjectByCurriculumOptions;
