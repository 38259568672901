import { Grid } from '@material-ui/core';
import { useAppSelector } from '../../../../../../hooks/useRedux';
import EvaluationTypeSideDialog from '../../evaluation_type/components/EvaluationTypeSideDialog';
import IntegralEvaluationSideDialog from '../../integral_evaluation/components/IntegralEvaluationSideDialog';
import ScopeSideDialog from '../../scope/components/ScopeSideDialog';
import EvaluationStepper from './stepper';

const EvaluationForm = () => {
  const { evaluationTypeSideDialogOpen } = useAppSelector((state) => state.evaluationType);
  const { scopesSideDialogOpen } = useAppSelector((state) => state.scopes);
  const { integralEvaluationsSideDialogOpen } = useAppSelector((state) => state.integralEvaluation);

  return (
    <Grid container>
      <Grid item xs={12}>
        <EvaluationStepper />
      </Grid>

      {evaluationTypeSideDialogOpen && <EvaluationTypeSideDialog />}
      {integralEvaluationsSideDialogOpen && <IntegralEvaluationSideDialog />}
      {scopesSideDialogOpen && <ScopeSideDialog />}
    </Grid>
  );
};

export default EvaluationForm;
