import DeleteAlert from '../../../shared/components/dialogs/DeleteAlert';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { useDeleteParallelMutation } from '../slices/parallelsApiSlice';
import { resetParallelIdToDelete, setDeleteModalOpen } from '../slices/parallesSlice';

const DeletePerallelDialog = () => {
  const dispatch = useAppDispatch();

  const { deleteModalOpen, parallelIdToDelete } = useAppSelector((state) => state.parallels);
  const { currentProfile } = useAppSelector((state) => state.access);

  const [deleteParalell, { isLoading, isSuccess, isError, error }] = useDeleteParallelMutation();

  const handleDelete = () => {
    deleteParalell({
      profileId: currentProfile?.profileId!,
      parallelId: parallelIdToDelete!,
    });
  };

  const handleClose = () => {
    dispatch(setDeleteModalOpen(false));
    dispatch(resetParallelIdToDelete());
  };
  return (
    <DeleteAlert
      open={deleteModalOpen}
      onClose={handleClose}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      handleDelete={handleDelete}
      error={error as string}
    />
  );
};

export default DeletePerallelDialog;
