import * as yup from 'yup';

/* Student record certificate*/
export const studenRecordCertificateSchema = yup.object().shape({
  lang: yup.string().required('El lenguaje es requerido.').min(1, 'El lenguaje es requerido.'),
});

/* Study certificate*/
export const studyCertificateSchema = yup.object().shape({
  period: yup.string().required('El tipo es requerido.').min(1, 'El tipo es requerido.'),
});

/* Credit certificate*/
export const creditCertificateSchema = yup.object().shape({
  to: yup
    .string()
    .required('El destinatario es requerido.')
    .min(1, 'El destinatario es requerido.'),
  period_id: yup.number().required('El período es requerido.').min(1, 'El período es requerido.'),
});

/* Consulate certificate*/
export const consulateCertificateSchema = yup.object().shape({
  consul: yup
    .string()
    .required('El nombre del consulado es requerido.')
    .min(1, 'El nombre del consulado es requerido.'),
});

/* Preprofesional certificate*/
export const professionalPracticeCertificateSchema = yup.object().shape({
  degree: yup
    .string()
    .required('El título del destinatario es requerido.')
    .min(1, 'El título del destinatario es requerido.'),
  name: yup.string().required('El nombre es requerido.'),
  position: yup.string().required('El cargo es requerido.'),
  work_location: yup.string().required('El lugar de trabajo es requerido.'),
  city: yup.string().required('La ciudad donde trabaja es requerido.'),
});
