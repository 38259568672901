import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormHelperText,
  Grid,
  TextField,
} from '@material-ui/core';

import { useFormik } from 'formik';

import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import InputLabel from '../../../shared/components/forms/InputLabel';

import { useAppSelector } from '../../../../hooks/useRedux';
import { useAddCampusMutation } from '../slices/campusApiSlice';

import { CampusInfoSchema } from '../constants/validation-schemas';
import { statusAvailables } from '../../../shared/constants/resourceStatus';
import Map from '../../institution-info/components/Map';

const AddCampusForm = () => {
  const history = useHistory();

  const { currentProfile } = useAppSelector((state) => state.access);

  const [showAlerts, setShowAlerts] = useState(true);

  const [addCampus, { isLoading, isError, error, isSuccess }] = useAddCampusMutation();

  const { getFieldProps, errors, touched, handleSubmit, setFieldValue, values } = useFormik({
    initialValues: {
      name: '',
      description: '',
      direction: '',
      isVirtual: false,
      latitude: '',
      phone: '',
      cellphone: '',
      longitude: '',
      initials: '',
      statusId: 1,
    },
    validationSchema: CampusInfoSchema,
    onSubmit: async (values) => {
      await addCampus({
        profileId: currentProfile?.profileId!,
        campusPayload: {
          cam_name: values.name,
          cam_description: values.description,
          cam_direction: values.direction,
          cam_latitude: values.latitude,
          cam_longitude: values.longitude,
          cam_initials: values.initials,
          cam_phone: values.phone,
          can_cellphone: values.cellphone,
          status_id: values.statusId,
          cam_virtual: values.isVirtual ? '1' : '0',
        },
      });

      setShowAlerts(true);
    },
  });

  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction="column" gap={2}>
        <Grid item>
          {isError && showAlerts && (
            <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
          )}

          {isSuccess && showAlerts && (
            <SuccessAlert
              message="La sede ha sido creado correctamente."
              handleDismiss={() => setShowAlerts(false)}
            />
          )}
        </Grid>
        <Grid item container spacing={2} alignItems="center">
          <Grid item xs={12} md={8}>
            <InputLabel>Nombre</InputLabel>
            <TextField
              fullWidth
              error={Boolean(errors.name && touched.name)}
              placeholder="Ingrese el nombre de la sede"
              {...getFieldProps('name')}
            />
            {errors.name && touched.name && <FormHelperText error>{errors.name}</FormHelperText>}
          </Grid>

          <Grid item xs={12} md={4}>
            <InputLabel>Siglas</InputLabel>
            <TextField
              fullWidth
              error={Boolean(errors.initials && touched.initials)}
              placeholder="Ingrese las siglas de la sede"
              {...getFieldProps('initials')}
            />
            {errors.initials && touched.initials && (
              <FormHelperText error>{errors.initials}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12}>
            <InputLabel>Descripción</InputLabel>
            <TextField
              multiline
              rows={2}
              fullWidth
              error={Boolean(errors.description && touched.description)}
              placeholder="Ingrese una descripción para la sede"
              {...getFieldProps('description')}
            />
            {errors.description && touched.description && (
              <FormHelperText error>{errors.description}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12} container spacing={2}>
            <Grid item xs={6}>
              <InputLabel>Teléfono</InputLabel>
              <TextField
                fullWidth
                error={Boolean(errors.phone && touched.phone)}
                placeholder="Ingrese el número de teléfono de la sede"
                {...getFieldProps('phone')}
              />
              {errors.phone && touched.phone && (
                <FormHelperText error>{errors.phone}</FormHelperText>
              )}
            </Grid>
            <Grid item xs={6}>
              <InputLabel>Celular</InputLabel>
              <TextField
                fullWidth
                error={Boolean(errors.cellphone && touched.cellphone)}
                placeholder="Ingrese el número de celular de la sede"
                {...getFieldProps('cellphone')}
              />
              {errors.cellphone && touched.cellphone && (
                <FormHelperText error>{errors.cellphone}</FormHelperText>
              )}
            </Grid>
          </Grid>

          <Grid item xs={12}>
            {/* Checkbox para saber si es virtual */}
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.isVirtual}
                  onChange={(e) => setFieldValue('isVirtual', e.target.checked)}
                />
              }
              label="Tipo virtual"
            />
            <FormHelperText>No se solicitará información de ubicación.</FormHelperText>
          </Grid>

          <Grid item xs={12} container spacing={2}>
            {!values.isVirtual && (
              <Grid item xs={12}>
                <InputLabel>Dirección</InputLabel>
                <TextField
                  fullWidth
                  error={Boolean(errors.direction && touched.direction)}
                  placeholder="Ingrese la ubicación de la sede"
                  {...getFieldProps('direction')}
                />
                {errors.direction && touched.direction && (
                  <FormHelperText error>{errors.direction}</FormHelperText>
                )}
              </Grid>
            )}
          </Grid>

          {!values.isVirtual && (
            <>
              <Grid item xs={12} container spacing={2}>
                <Grid item xs={6}>
                  <InputLabel>Latitud</InputLabel>
                  <TextField
                    fullWidth
                    error={Boolean(errors.latitude && touched.latitude)}
                    placeholder="Ingrese la latitud de la sede"
                    {...getFieldProps('latitude')}
                  />
                  {errors.latitude && touched.latitude && (
                    <FormHelperText error>{errors.latitude}</FormHelperText>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <InputLabel>Longitud</InputLabel>
                  <TextField
                    fullWidth
                    error={Boolean(errors.longitude && touched.longitude)}
                    placeholder="Ingrese la longitud de la sede"
                    {...getFieldProps('longitude')}
                  />
                  {errors.longitude && touched.longitude && (
                    <FormHelperText error>{errors.longitude}</FormHelperText>
                  )}
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Map
                  googleMapURL={`${
                    process.env.REACT_APP_GOOGLE_MAPS_API_URL ||
                    'https://maps.googleapis.com/maps/api/js?v=3.exp&key='
                  }${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`}
                  containerElement={<div style={{ height: '400px' }} />}
                  mapElement={<div style={{ height: '100%' }} />}
                  loadingElement={<p>Cargando...</p>}
                  latitude={values.latitude || '-2.0'}
                  longitude={values.longitude || '-79.0'}
                  handleChangePosition={(lat, lng) => {
                    setFieldValue('latitude', `${lat}`);
                    setFieldValue('longitude', `${lng}`);
                  }}
                />
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <InputLabel>Estado</InputLabel>
            <Autocomplete
              options={statusAvailables}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField {...params} error={Boolean(errors.statusId && touched.statusId)} />
              )}
              defaultValue={statusAvailables[0]}
              onChange={(e, value) => setFieldValue('statusId', value?.id || '')}
              fullWidth
              clearIcon={null}
            />
            {errors.statusId && touched.statusId && (
              <FormHelperText error>{errors.statusId}</FormHelperText>
            )}
          </Grid>
        </Grid>

        <Grid
          item
          container
          alignItems="center"
          justifyContent="flex-end"
          spacing={2}
          sx={{ mt: 1 }}
        >
          <Grid item>
            <Button
              variant="outlined"
              size="large"
              color="primary"
              onClick={() => history.goBack()}
            >
              Regresar
            </Button>
          </Grid>
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              disabled={isLoading}
              endIcon={isLoading && <CircularProgress size={20} />}
            >
              Agregar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default AddCampusForm;
