import { TableHead, Tooltip } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { getColHeader } from './../helpers/dataGridHelper';
import {
  Distribution,
  DistributionCalification,
} from '../../interfaces/modelCalification.interfaces';
import { StyledTableCell, StyledTableRow } from './CalificationstTable';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import { setModalSupply } from '../../slices/virtualClassroomTeacherSlice';

interface Props {
  distribution?: DistributionCalification[];
  childs?: Distribution[];
}

const HeaderTable = ({ distribution }: Props) => {
  const dispatch = useAppDispatch();

  const [viewRows, setViewRows] = useState(true);
  const { course } = useAppSelector((state) => state.virtualClassroomTeacherShared);

  const { col } = getColHeader(distribution);

  const distributionList = distribution?.map((d, index) => (
    <Tooltip
      style={{ cursor: 'pointer' }}
      title={d.name as string}
      onClick={() => {
        if (course?.vcType !== 'native') return;
        dispatch(
          setModalSupply({
            modal: true,
            idDistribution: d.id!,
            idInsumo: Number(d?.distributions?.[0]?.ref2_supply_id!),
            distributionName: d.name!,
            supplyName: d?.distributions?.[0]?.ref2_supply.name!,
          })
        );
      }}
      key={index}
    >
      <StyledTableCell
        key={d.id}
        align="center"
        colSpan={
          d.distributions && d.distributions?.length > 1
            ? d.distributions?.length + 1
            : d.distributions?.length
        }
        rowSpan={d.distributions ? 1 : 2}
      >
        {d.name}
      </StyledTableCell>
    </Tooltip>
  ));

  const subRows = col?.map((d, index) => (
    <StyledTableCell align="center" key={index.toString()}>
      {d}
    </StyledTableCell>
  ));

  useEffect(() => {
    if (distributionList && distributionList?.length - 1 === subRows.length) setViewRows(false);
  }, [distributionList, subRows]);

  return (
    <TableHead>
      <StyledTableRow>
        <StyledTableCell align="center" rowSpan={2}>
          Apellidos y Nombres
        </StyledTableCell>
        {distributionList}
      </StyledTableRow>
      {viewRows && <StyledTableRow>{subRows}</StyledTableRow>}
    </TableHead>
  );
};

export default HeaderTable;
