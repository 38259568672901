import { mainApi } from '../../../../api/mainApi';
import { middlewareApi } from '../../../middleware/middlewareApi';
import { AuthorsResponse, Author } from '../interfaces/authors.interface';
import { NacionalidadesResponse } from '../interfaces/nacionalidades.interface';

const authorsApi = middlewareApi.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * @GET  autores
     */
    getAutores: builder.query<
      AuthorsResponse,
      {
        page: number;
        size: number;
        search?: string;
        type_sort?: string;
        sort?: string;
      }
    >({
      queryFn: async ({ page, size, search, sort, type_sort }) => {
        try {
          const { data } = await mainApi.get<AuthorsResponse>('/books-tags?type_tag=Autor', {
            params: {
              page,
              size,
              search,
              sort,
              type_sort,
            },
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),
    /**
     * @GET all authors
     */
    getAllAuthors: builder.query<Author[], void>({
      queryFn: async () => {
        try {
          const { data } = await mainApi.get<Author[]>('/books-tags?type_tag=Autor&data=all');
          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
    }),
    /**
     * @GET nacionalidades
     */
    getNacionalidades: builder.query<
      NacionalidadesResponse,
      {
        acronym: string;
      }
    >({
      queryFn: async ({ acronym }) => {
        try {
          const { data } = await mainApi.get(`/catalogs/${acronym}/children?user_profile_id=1`);
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),
    /**
     * @POST add autor
     */
    addAutor: builder.mutation<
      any,
      {
        payload: {
          name: string;
          born_date?: string;
          death_date?: string;
          nationality_id?: string;
          country_id?: string;
          description?: string;
          business_name?: string;
          type_tag: string;
          status_id: string;
        };
      }
    >({
      queryFn: async ({ payload }) => {
        try {
          const { data } = await mainApi.post<any>('/books-tags', {
            ...payload,
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),
    /**
     * @PUT edit author
     */
    editAuthor: builder.mutation<
      any,
      {
        id: string | number;

        payload: {
          name: string;
          born_date?: string;
          death_date?: string;
          nationality_id?: string;
          country_id?: string;
          description?: string;
          business_name?: string;
          type_tag: string;
          status_id: string;
        };
      }
    >({
      queryFn: async ({ id, payload }) => {
        try {
          const { data } = await mainApi.put<any>(`/books-tags/${id}`, {
            ...payload,
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),
    /**
     * @DELETE delete author
     */
    deleteAuthor: builder.mutation<any, { id: number | string }>({
      queryFn: async ({ id }) => {
        try {
          const { data } = await mainApi.delete<any>(`/books-tags/${id}`);
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),
  }),
});

export const {
  useGetAutoresQuery,
  useGetAllAuthorsQuery,
  useGetNacionalidadesQuery,
  useAddAutorMutation,
  useEditAuthorMutation,
  useDeleteAuthorMutation,
} = authorsApi;
