import { useFormikContext } from 'formik';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Autocomplete, Button, FormHelperText, Grid, TextField } from '@material-ui/core';
import TextFieldFetching from '../../../../../../shared/components/TextFieldFetching';
import InputLabel from '../../../../../../shared/components/forms/InputLabel';
import typeResearch from '../../../../constants/lists/typeResearch';
import { useGetEducationLevelsParentsVMQuery } from '../../../../../education-levels/slices/educationLevelsApiSlice';
import { useGetGroupResearchByLinesResearchQuery } from '../../../../slices/InvestigationLinesApiSlice';

import { useGetCatalogByAcronymChildrenQuery } from '../../../../../../shared/slices/catalogApi';
import { Project } from '../../../../interfaces/projectResearch.interfaces';

import {
  LinesGroupResearch,
  EducationLevels,
} from '../../../../interfaces/projectResearch.interfaces';
import LinesInvestigation from './LinesInvestigation';
import LinesInvestigationSecundary from './LinesInvestigationSecundary';

interface Props {
  handleReturn: () => void;
  dataEdit: Project | undefined;
  isEdit: boolean;
  isReset: boolean;
}

const SpecificDetailsWraperForm = ({ handleReturn, dataEdit, isEdit, isReset }: Props) => {
  const { values, errors, touched, handleSubmit, setFieldValue, resetForm } = useFormikContext<{
    cityId: number;
    provinceId: number;
    educationLevels: number[];
    educationLevelsPrincipal: string | undefined;
    linesGroupResearch: string[];
    linesGroupResearchPrincipal: string | undefined;
    linesGroupResearchSend: {
      id: number | null;
      group_research_id: number;
      is_principal: boolean;
      line_research_id: number;
    }[];
    investigationLines: string[] | undefined;
    investigationLinesPrincipal: string | undefined;
    typeResearch: string;
  }>();

  const { t } = useTranslation();

  const { data: facultadAvailables, isLoading: isLoadingFacultad } =
    useGetEducationLevelsParentsVMQuery({});

  const { data: provinceAvailables, isLoading: isLoadingProvince } =
    useGetCatalogByAcronymChildrenQuery({ acronym: 'pais-ec' });

  const { data: groupsByLinesAvailables, isLoading: isLoadingGroupsByLines } =
    useGetGroupResearchByLinesResearchQuery({
      linesResearch: values.investigationLines?.map((e) => e) || [],
    });

  const province = useMemo(
    () =>
      provinceAvailables?.find(({ id }) => id === values.provinceId) ||
      provinceAvailables?.find(({ children }) =>
        children.some(({ id }) => id === values.provinceId)
      ),
    [provinceAvailables, values.provinceId]
  );

  const city = useMemo(
    () =>
      provinceAvailables
        ?.find((province) => province.id === values.provinceId)
        ?.children.find(({ id }) => id === values.cityId),
    [provinceAvailables, values.cityId, values.provinceId]
  );

  useEffect(() => {
    setFieldValue(
      'linesGroupResearch',
      groupsByLinesAvailables
        ?.filter((e) => values.linesGroupResearch.some((i) => i === e.id))
        .map(
          ({ id, line_research_id }): LinesGroupResearch => ({
            id: isEdit
              ? dataEdit?.line_group_research_project?.find((e) => id === e.group_research_id)
                  ?.id || null
              : null,
            group_research_id: id,
            is_principal: id.toString() === values.linesGroupResearchPrincipal ? true : false,
            line_research_id,
          })
        ) || []
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupsByLinesAvailables]);

  useEffect(() => {
    setFieldValue(
      'linesGroupResearchSend',
      groupsByLinesAvailables
        ?.filter(
          (e) =>
            values.linesGroupResearch.some((i) => i === e.id) ||
            e.id === values.linesGroupResearchPrincipal
        )
        .map(
          ({ id, line_research_id }): LinesGroupResearch => ({
            id: isEdit
              ? dataEdit?.line_group_research_project?.find((e) => id === e.group_research_id)
                  ?.id || null
              : null,
            group_research_id: id,
            is_principal: id.toString() === values.linesGroupResearchPrincipal ? true : false,
            line_research_id,
          })
        ) || []
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.linesGroupResearch, values.linesGroupResearchPrincipal, dataEdit]);

  useEffect(() => {
    setFieldValue(
      'educationLevels',
      facultadAvailables
        ?.filter((e) => values.educationLevels.some((i) => i === e.id))
        .map(
          ({ id }): EducationLevels => ({
            id: null,
            education_level_id: id,
            is_principal:
              id.toString() === values.educationLevelsPrincipal?.toString() ? true : false,
          })
        ) || []
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facultadAvailables]);

  useEffect(() => {
    if (facultadAvailables) {
    }
  }, [facultadAvailables]);

  useEffect(() => {
    dataEdit?.project_education_level.find(({ id }) =>
      values.educationLevels?.some((e) => e.toString() === id)
    );
  }, [values.educationLevels, dataEdit]);

  useEffect(() => {
    if (isReset) {
      resetForm();
    }
  }, [isReset, resetForm]);

  return (
    <form onSubmit={handleSubmit} style={{ width: '100%' }}>
      <Grid container direction="column">
        <Grid item container xs={12} md={6} spacing={2}>
          <Grid item xs={12} md={isEdit ? 12 : 6}>
            <InputLabel> {t('project.specificDetail.provinceId.title')} </InputLabel>

            {isLoadingProvince ? (
              <TextFieldFetching />
            ) : (
              <Autocomplete
                options={provinceAvailables || []}
                loading={isLoadingProvince}
                loadingText={t('academic.subjects.add.edu_level.loading')}
                noOptionsText={t('project.specificDetail.provinceId.no_option')}
                getOptionLabel={(option) => option.cat_name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(errors.provinceId && touched.provinceId)}
                    placeholder={t('project.specificDetail.provinceId.placeholder')}
                  />
                )}
                onChange={(_, value) => {
                  setFieldValue('provinceId', value?.id);
                }}
                value={province || null}
                fullWidth
                clearIcon={null}
              />
            )}
            {errors.provinceId && touched.provinceId && (
              <FormHelperText error>{errors.provinceId}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12} md={isEdit ? 12 : 6}>
            <InputLabel> {t('project.specificDetail.cityId.title')} </InputLabel>

            {isLoadingProvince ? (
              <TextFieldFetching />
            ) : (
              <Autocomplete
                options={
                  provinceAvailables?.find((province) => province.id === values.provinceId)
                    ?.children || []
                }
                loading={isLoadingProvince}
                value={city || null}
                loadingText={t('academic.subjects.add.edu_level.loading')}
                noOptionsText={t('project.specificDetail.cityId.no_option')}
                getOptionLabel={(option) => option.cat_name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(errors.cityId && touched.cityId)}
                    placeholder={t('project.specificDetail.cityId.placeholder')}
                  />
                )}
                onChange={(_, value) => {
                  setFieldValue('cityId', value?.id);
                }}
                fullWidth
                clearIcon={null}
              />
            )}
            {errors.cityId && touched.cityId && (
              <FormHelperText error>{errors.cityId}</FormHelperText>
            )}
          </Grid>
          {
            //Linea de investigacion principal
          }
          <LinesInvestigation
            facultadAvailables={facultadAvailables}
            isLoadingFacultad={isLoadingFacultad}
          />

          {
            //Linea de investigacion secundaria
          }
          <LinesInvestigationSecundary
            facultadAvailables={facultadAvailables}
            isLoadingFacultad={isLoadingFacultad}
          />

          <Grid item xs={12} md={isEdit ? 12 : 6}>
            <InputLabel> {t('Grupo Principal')} </InputLabel>
            {isLoadingGroupsByLines ? (
              <TextFieldFetching />
            ) : (
              <Autocomplete
                options={groupsByLinesAvailables || []}
                loading={isLoadingGroupsByLines}
                value={
                  groupsByLinesAvailables?.find(
                    ({ id }) => id.toString() === values.linesGroupResearchPrincipal?.toString()
                  ) || null
                }
                loadingText={t('academic.subjects.add.edu_level.loading')}
                noOptionsText={t('project.linesGroupResearch.group.no_option')}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(errors.linesGroupResearchSend && touched.linesGroupResearchSend)}
                    placeholder={t('project.linesGroupResearch.group.placeholder')}
                  />
                )}
                onChange={(_, value) => {
                  setFieldValue('linesGroupResearchPrincipal', value?.id);
                }}
                fullWidth
                clearIcon={null}
              />
            )}
            {errors.linesGroupResearchPrincipal && touched.linesGroupResearchPrincipal && (
              <FormHelperText error>{errors.linesGroupResearchPrincipal}</FormHelperText>
            )}

            {errors.linesGroupResearchSend &&
              touched.linesGroupResearchSend &&
              Array.isArray(errors.linesGroupResearchSend) &&
              errors.linesGroupResearchSend?.map((error, index) => (
                <FormHelperText error key={index}>
                  {error}
                </FormHelperText>
              ))}
          </Grid>

          <Grid item xs={12} md={isEdit ? 12 : 6}>
            <InputLabel>Grupos secundarios</InputLabel>
            {isLoadingGroupsByLines ? (
              <TextFieldFetching />
            ) : (
              <Autocomplete
                options={
                  groupsByLinesAvailables?.filter(
                    ({ id }) => id !== values.linesGroupResearchPrincipal?.toString()
                  ) || []
                }
                multiple
                loadingText={t('academic.subjects.add.edu_level.loading')}
                value={
                  groupsByLinesAvailables?.filter(
                    ({ id }) =>
                      values.linesGroupResearch.some((e) => e === id) &&
                      id !== values.linesGroupResearchPrincipal?.toString()
                  ) || []
                }
                noOptionsText={'No hay lineas de investigacion disponibles'}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(
                      errors.linesGroupResearchPrincipal && touched.linesGroupResearchPrincipal
                    )}
                    placeholder={t('academic.subjects.add.edu_level.placeholder')}
                  />
                )}
                onChange={(_, value) => {
                  setFieldValue(
                    'linesGroupResearch',
                    value.map(({ id }) => id)
                  );
                }}
                fullWidth
                clearIcon={null}
              />
            )}
            {errors.linesGroupResearchPrincipal && touched.linesGroupResearchPrincipal && (
              <FormHelperText error>{errors.linesGroupResearchPrincipal}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12} md={isEdit ? 12 : 6}>
            <InputLabel> {t('project.AddCollaboratorProject.researchType.title')} </InputLabel>
            <Autocomplete
              options={typeResearch}
              value={typeResearch.find(({ keyword }) => keyword === values.typeResearch) || null}
              loadingText={t('project.AddCollaboratorProject.researchType.title.loading')}
              noOptionsText={t('project.AddCollaboratorProject.researchType.no_option')}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(errors.typeResearch && touched.typeResearch)}
                  placeholder={t('project.AddCollaboratorProject.researchType.placeholder')}
                />
              )}
              onChange={(_, value) => {
                setFieldValue('typeResearch', value?.keyword);
              }}
              fullWidth
              clearIcon={null}
            />
            {errors.typeResearch && touched.typeResearch && (
              <FormHelperText error>{errors.typeResearch}</FormHelperText>
            )}
          </Grid>

          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            spacing={2}
            sx={{ mt: 6 }}
          >
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="outlined"
                size="large"
                color="primary"
                onClick={handleReturn}
              >
                {t('academic.subjects.add.back')}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                type="submit"
                variant="contained"
                size="large"
                color="primary"
                // disabled={isLoading}
                // endIcon={isLoading && <CircularProgress size={20} />}
              >
                Siguiente
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default SpecificDetailsWraperForm;
