import { Skeleton, Timeline } from '@material-ui/lab';

import { useAppSelector } from '../../../../../hooks/useRedux';

import { useUseGetActivitiesbyCourseTeacherQuery } from '../../slices/virtualClassroomTeacherApiSlice';
import TimelineOnce from './TimelineOnce';

export default function TimeLineActivities() {
  const {
    userInfo: { collaboratorInfo },
  } = useAppSelector((state) => state.auth);
  const {
    course: { courseId },
  } = useAppSelector((state) => state.virtualClassroomTeacherShared);
  const { data, isLoading } = useUseGetActivitiesbyCourseTeacherQuery(
    {
      courseId: courseId!,
      collaboratorID: collaboratorInfo?.id!,
      page: 1,
      size: 100,
    },
    { skip: courseId === 0 || courseId === undefined }
  );

  return (
    <>
      <>
        {isLoading ? (
          <Skeleton variant="rectangular" sx={{ width: '100%', borderRadius: 3, height: '100%' }} />
        ) : (
          <Timeline position="right">
            {data?.data?.map((item) => (
              <TimelineOnce
                Category={item?.type_activity?.cat_description!}
                Descripcion={item?.act_description}
                FechaInicio={item?.course_activity_one.cact_start_date}
                FechaEnd={item?.course_activity_one.cact_end_date}
              />
            ))}
          </Timeline>
        )}
      </>
    </>
  );
}
