//import { useEffect, useState } from 'react';
/* import {
    useHistory, 
    Link,
    useRouteMatch
} from 'react-router-dom'; */
import {
  Autocomplete,
  //Checkbox,
  //Button,
  Grid,
  Typography,
  TextField,
  /* TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Table,
    CircularProgress,
    FormHelperText,
    Collapse,
    Box,
    useTheme, */
  // FormControlLabel,
  Paper,
  Box,
  Tab,
  Tabs,
} from '@material-ui/core';
import React from 'react';
import InputLabel from '../../../shared/components/forms/InputLabel';
import Leads from './LeadsCharts/FuentesContacto/Leads';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const CustomPaper = (prop: { title: string; subtitle: string }) => {
  return (
    <Paper
      elevation={5}
      sx={{
        background:
          'radial-gradient( circle 465px at -15.1% -25%,  rgba(17,130,193,1) 0%, rgba(67,166,238,1) 49%, rgba(126,203,244,1) 90.2% )',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        <Typography
          variant="h4"
          fontWeight={400}
          color="white"
          sx={{ fontSize: '1.3vw', width: 'fit-content' }}
        >
          {prop.title || ''}
        </Typography>
        <Typography variant="h2" color="white" sx={{ fontSize: '3vw', width: 'fit-content' }}>
          {prop.subtitle || ''}
        </Typography>
      </div>
    </Paper>
  );
};

const DashboardSocialtray = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <>
      <Grid item xs={12} hidden={false} sx={{ mt: 3 }}>
        <Grid container direction="column" gap={2}>
          <Grid item container spacing={2} alignItems="center">
            <Grid item xs={12} md={1}>
              <InputLabel>Año</InputLabel>
              <Autocomplete
                options={[]}
                placeholder="Seleccione Titulo..."
                //getOptionLabel={option => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    //error={Boolean(errors.statusId && touched.statusId)}
                    placeholder="Seleccione Titulo"
                  />
                )}
                //defaultValue={}
                onChange={
                  (e, value) => {}
                  //setFieldValue('statusId', value?.id || '')
                }
                fullWidth
                clearIcon={null}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <InputLabel>Mes</InputLabel>
              <Autocomplete
                options={[]}
                placeholder="Seleccione Editorial..."
                //getOptionLabel={option => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    //error={Boolean(errors.statusId && touched.statusId)}
                    placeholder="Seleccione Editorial"
                  />
                )}
                //defaultValue={}
                onChange={
                  (e, value) => {}
                  //setFieldValue('statusId', value?.id || '')
                }
                fullWidth
                clearIcon={null}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <InputLabel>Sede</InputLabel>
              <Autocomplete
                options={[]}
                placeholder="Seleccione Autor..."
                //getOptionLabel={option => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    //error={Boolean(errors.statusId && touched.statusId)}
                    placeholder="Seleccione Autor"
                  />
                )}
                //defaultValue={}
                onChange={
                  (e, value) => {}
                  //setFieldValue('statusId', value?.id || '')
                }
                fullWidth
                clearIcon={null}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <InputLabel>Campaña</InputLabel>
              <Autocomplete
                options={[]}
                placeholder="Seleccione Código..."
                //getOptionLabel={option => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    //error={Boolean(errors.statusId && touched.statusId)}
                    placeholder="Seleccione Código"
                  />
                )}
                //defaultValue={}
                onChange={
                  (e, value) => {}
                  //setFieldValue('statusId', value?.id || '')
                }
                fullWidth
                clearIcon={null}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <InputLabel>Oferta Académica</InputLabel>
              <Autocomplete
                options={[]}
                placeholder="Seleccione Notas del Contenido..."
                //getOptionLabel={option => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    //error={Boolean(errors.statusId && touched.statusId)}
                    placeholder="Seleccione Notas del Contenido"
                  />
                )}
                //defaultValue={}
                onChange={
                  (e, value) => {}
                  //setFieldValue('statusId', value?.id || '')
                }
                fullWidth
                clearIcon={null}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} hidden={false} sx={{ mt: 4 }}>
        <Grid container direction="column" gap={2}>
          <Grid item container spacing={3} alignItems="center">
            <Grid item xs={12} md={3}>
              <Box
                sx={{
                  '& > :not(style)': {
                    m: 1,
                    width: '100%',
                    height: 128,
                  },
                }}
              >
                <CustomPaper title="Leads" subtitle="1000" />
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box
                sx={{
                  '& > :not(style)': {
                    m: 1,
                    width: '100%',
                    height: 128,
                  },
                }}
              >
                <CustomPaper title="Inscritos" subtitle="1000" />
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box
                sx={{
                  '& > :not(style)': {
                    m: 1,
                    width: '100%',
                    height: 128,
                  },
                }}
              >
                <CustomPaper title="Campañas" subtitle="1000" />
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box
                sx={{
                  '& > :not(style)': {
                    m: 1,
                    width: '100%',
                    height: 128,
                  },
                }}
              >
                <CustomPaper title="Fuentes de Contacto" subtitle="1000" />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ mt: 4 }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Leads por fuentes de contacto" {...a11yProps(0)} />
            <Tab label="Leads por productos" {...a11yProps(1)} />
            <Tab label="Leads por estado comercial" {...a11yProps(2)} />
            <Tab label="métricas" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Leads />
        </TabPanel>
        <TabPanel value={value} index={1}>
          Item Two
        </TabPanel>
        <TabPanel value={value} index={2}>
          Item Three
        </TabPanel>
      </Grid>
    </>
  );
};

export default DashboardSocialtray;
