import { useHistory } from 'react-router-dom';
import {
  Autocomplete,
  Button,
  Typography,
  Grid,
  TextField,
  CircularProgress,
} from '@material-ui/core';
import { DatePicker, LocalizationProvider } from '@material-ui/lab';
import esLocale from 'date-fns/locale/es';
import { format, isBefore } from 'date-fns';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import InputLabel from '../../../shared/components/forms/InputLabel';
import { useState, useEffect } from 'react';
import { useAddLendingMutation, useLazyGetPersonsQuery } from '../slices/lendingApiSlice';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useLazyGetBooksQuery } from '../../books/slices/booskApiSlice';
import { useAppSelector } from '../../../../hooks/useRedux';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import { isAfter, add } from 'date-fns';
import CustomAsyncPaginatedSelect from '../../../shared/components/extended/Form/CustomAsyncPaginatedSelect';
import { UserItem } from '../interfaces/users.interface';

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

const AddLend = () => {
  const history = useHistory();
  const [updateAutocomplete, setUpdateAutocomplete] = useState(false);
  const { currentProfile } = useAppSelector((state) => state.access);

  const typesLendings = [
    { value: 'Interno', label: 'Interno' },
    { value: 'Externo', label: 'Externo' },
  ];

  //
  const [bookValue, setBookValue] = useState<any | null>(null);

  const [
    getBooksTrigger,
    // { data: institutesAvailables, isLoading: isLoadingInstitutesAvailables },
  ] = useLazyGetBooksQuery();

  const getBooks = async (search: string, page: number, perPage: number) => {
    const response = await getBooksTrigger({
      page,
      size: perPage,
      search,
      type_book: 'Libro',
    }).unwrap();

    const hasMore = response.current_page < response.last_page;

    return {
      options: response.data.map((dt) => {
        return {
          value: dt.id,
          label: dt.title,
          ...dt,
        };
      }),
      hasMore,
    };
  };
  //
  const [userValue, setUserValue] = useState<any | null>(null);

  const [getUsersTrigger, { isLoading: isLoadingUsers, isFetching: isFetchingUsers }] =
    useLazyGetPersonsQuery();

  const getUsers = async (search: string, page: number, perPage: number) => {
    const response = await getUsersTrigger({
      page,
      perPage,
      search,
      user_profile_id: currentProfile?.profileId!,
    }).unwrap();

    const hasMore = response.current_page < response.last_page;

    return {
      options: response.data.map((dt) => {
        return {
          value: dt.id,
          label: dt.pers_full_lastname || '',
          ...dt,
        };
      }),
      hasMore,
    };
  };

  const loading = isLoadingUsers || isFetchingUsers ? true : false;

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      await sleep(1e3);

      if (active) {
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  const [
    addLending,
    {
      isLoading: isLoadingAdd,
      isError: isErrorAdd,
      error: errorAdd,
      isSuccess: isSuccessAdd,
      reset,
    },
  ] = useAddLendingMutation();

  const schema = yup.object().shape({
    user: yup.string().required('Usario es requerido'),
    type_user: yup.string().required('Tipo de usuario es requerido'),
    type_lending: yup.string().required('Tipo de préstamo es requerido'),
    books: yup.array().min(1, 'Debe seleccionar un libro').required('Es requerido'),
    date: yup.string().required('Fecha de salida es requerida'),
    borrow_return_date: yup.string().required('Fecha de entrega es requerida'),
    detail: yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      user: '',
      type_user: '',
      type_lending: '',
      books: [],
      date: '',
      borrow_return_date: '',
      detail: '',
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      try {
        await addLending({
          payload: {
            user_profile_id: currentProfile?.profileId!,
            borrows: values.books.map((bk) => ({
              model_type: 'book',
              model_id: bk,
              borrow_date: format(new Date(values.date), 'yyyy-MM-dd'),
              borrow_return_date: format(new Date(values.borrow_return_date), 'yyyy-MM-dd'),
              copies_amount: 1,
              type: values.type_lending,
              selected_user_profile_id: Number(values.type_user),
              details: values.detail,
            })),
          },
        });
      } catch (error) {}
    },
  });

  useEffect(() => {
    setUpdateAutocomplete(true);
    setTimeout(() => {
      setUpdateAutocomplete(false);
    }, 50);
  }, [formik.values.user]);

  // useEffect(() => {
  //   if (isSuccessAdd) {
  //     formik.resetForm();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isSuccessAdd]);

  return (
    <Grid item container spacing={2}>
      {/* <Grid container spacing={2} alignItems="center"> */}

      <Grid item container xs={12} md={6} spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4">Datos Personales</Typography>
        </Grid>
        <Grid item xs={12}>
          <InputLabel>Buscar Usuario</InputLabel>
          <CustomAsyncPaginatedSelect
            key={'id'}
            value={userValue}
            isError={false}
            placeholder="Ingrese (Nombre, Apellido o Cédula)"
            setValue={(value) => {
              formik.setFieldValue('user', (value as any).id);
              setUserValue(value);
            }}
            fetchFunc={getUsers}
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel>Tipo de Usuario</InputLabel>
          {updateAutocomplete ? (
            <TextField placeholder="Cargando..." fullWidth />
          ) : (
            <Autocomplete
              options={
                isFetchingUsers
                  ? []
                  : (userValue as UserItem)?.user?.user_profiles.map((pr) => ({
                      value: pr.id,
                      label: pr.profile.pro_name,
                    })) || []
              }
              placeholder="Seleccione Tipo..."
              renderInput={(params) => (
                <TextField
                  {...params}
                  //error={Boolean(errors.statusId && touched.statusId)}
                  placeholder="Seleccione Tipo..."
                  error={Boolean(formik.errors.type_user && formik.touched.type_user)}
                  helperText={
                    Boolean(formik.errors.type_user && formik.touched.type_user) &&
                    formik.errors.type_user
                  }
                />
              )}
              //defaultValue={}
              onChange={
                (e, value) => {
                  if (value) {
                    formik.setFieldValue('type_user', value.value);
                  }
                }
                //setFieldValue('statusId', value?.id || '')
              }
              fullWidth
              clearIcon={null}
            />
          )}
        </Grid>

        <Grid item xs={12}>
          <InputLabel>Código</InputLabel>
          <TextField
            fullWidth
            placeholder="Código"
            value={(userValue as UserItem)?.user?.student?.stud_code || ''}
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel>Nombres</InputLabel>
          <TextField
            fullWidth
            placeholder="Nombres"
            value={`${(userValue as UserItem)?.pers_firstname || ''} ${
              (userValue as UserItem)?.pers_secondname || ''
            }`}
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel>Apellidos</InputLabel>
          <TextField
            fullWidth
            placeholder="Apellidos"
            value={`${(userValue as UserItem)?.pers_first_lastname || ''} ${
              (userValue as UserItem)?.pers_second_lastname || ''
            }`}
            disabled
          />
        </Grid>
        {/* <Grid item xs={12}>
          <InputLabel>Facultad</InputLabel>
          <TextField
            fullWidth
                       placeholder='Facultad'

            value={
              users?.data.find((us) => us.id === Number(formik.values.user))?.user?.student
                ?.stud_code
            }
          />
        </Grid> */}
      </Grid>
      <Grid item container xs={12} md={6} spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4">Datos de Libro</Typography>
        </Grid>
        <Grid item xs={12}>
          <InputLabel>Tipo de Préstamo</InputLabel>
          <Autocomplete
            options={typesLendings}
            placeholder="Seleccione Tipo..."
            //getOptionLabel={option => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                //error={Boolean(errors.statusId && touched.statusId)}
                placeholder="Seleccione Tipo..."
                error={Boolean(formik.errors.type_lending && formik.touched.type_lending)}
                helperText={
                  Boolean(formik.errors.type_lending && formik.touched.type_lending) &&
                  formik.errors.type_lending
                }
              />
            )}
            //defaultValue={}
            onChange={
              (e, value) => {
                if (value) {
                  formik.setFieldValue('type_lending', value.value);
                }
              }
              //setFieldValue('statusId', value?.id || '')
            }
            fullWidth
            clearIcon={null}
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel>Búsqueda de Libros</InputLabel>
          <CustomAsyncPaginatedSelect
            key={'id'}
            value={bookValue}
            isError={false}
            placeholder={'Seleccione un libro'}
            setValue={(value) => {
              console.log('value', value);
              formik.setFieldValue('books', [(value as any).id]);
              setBookValue(value);
            }}
            fetchFunc={getBooks}
          />
          {/* {isLoadingBooks ? (
            <TextField placeholder="Cargando..." fullWidth />
          ) : (
            <Autocomplete
              multiple
              options={
                books?.map((bk) => ({
                  value: bk.id,
                  label: bk.title,
                })) || []
              }
              placeholder="Seleccione Libros..."
              //getOptionLabel={option => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  //error={Boolean(errors.statusId && touched.statusId)}
                  placeholder="Seleccione Libros..."
                  error={Boolean(formik.errors.books && formik.touched.books)}
                  helperText={
                    Boolean(formik.errors.books && formik.touched.books) && formik.errors.books
                  }
                />
              )}
              //defaultValue={}
              onChange={
                (e, value) => {
                  if (value.length > 0) {
                    formik.setFieldValue(
                      'books',
                      value.map((vl) => vl.value)
                    );
                  } else {
                    formik.setFieldValue('books', []);
                  }
                }
                //setFieldValue('statusId', value?.id || '')
              }
              fullWidth
              clearIcon={null}
            />
          )} */}
        </Grid>
        <Grid item xs={12}>
          <InputLabel>Detalle</InputLabel>
          <TextField
            fullWidth
            multiline
            rows={3}
            placeholder="Ingrese detalle"
            onChange={({ target }) => formik.setFieldValue('detail', target.value)}
            //{...getFieldProps('direction')}
          />
        </Grid>
        <Grid item container xs={12} md={12} spacing={2}>
          <Grid item xs={6}>
            <InputLabel>Fecha de Salida</InputLabel>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
              <DatePicker
                value={formik.values.date}
                shouldDisableDate={(date) =>
                  !isBefore(
                    new Date(),
                    add(new Date(date), {
                      days: 1,
                    })
                  )
                }
                onChange={(newValue) => {
                  if (newValue) {
                    formik.setFieldValue('date', newValue);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(formik.errors.date && formik.touched.date)}
                    helperText={
                      Boolean(formik.errors.date && formik.touched.date) && formik.errors.date
                    }
                    //error={Boolean(errors.pers_date_birth && touched.pers_date_birth)}
                    placeholder="Fecha de salida"
                    fullWidth
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6}>
            <InputLabel>Fecha de Entrega</InputLabel>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
              <DatePicker
                value={formik.values.borrow_return_date}
                onChange={(val) => {
                  if (val) {
                    formik.setFieldValue('borrow_return_date', val);
                  }
                }}
                shouldDisableDate={(date) =>
                  isAfter(
                    add(new Date(), {
                      days: 2,
                    }),
                    new Date(date)
                  )
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(
                      formik.errors.borrow_return_date && formik.touched.borrow_return_date
                    )}
                    //error={Boolean(errors.pers_date_birth && touched.pers_date_birth)}
                    helperText={
                      Boolean(
                        formik.errors.borrow_return_date && formik.touched.borrow_return_date
                      ) && formik.errors.borrow_return_date
                    }
                    placeholder="Fecha de Entrega"
                    fullWidth
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
        <Grid item xs={12} />
        <Grid item xs={12} />
        <Grid item xs={12} />
      </Grid>

      <Grid item xs={12} md={12}>
        {isErrorAdd && <ErrorAlert message={errorAdd as string} handleDismiss={reset} />}

        {isSuccessAdd && (
          <SuccessAlert message="Préstamo creado correctamente." handleDismiss={reset} />
        )}
      </Grid>

      <Grid
        item
        container
        alignItems="center"
        justifyContent="center"
        spacing={2}
        sx={{ mt: 1 }}
        xs={12}
        md={12}
      >
        <Grid item>
          <Button variant="outlined" size="large" color="primary" onClick={() => history.goBack()}>
            Regresar
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            size="large"
            color="primary"
            onClick={() => {
              formik.handleSubmit();
            }}
            disabled={isLoadingAdd || isSuccessAdd}
            endIcon={isLoadingAdd && <CircularProgress size={20} />}
          >
            Agregar
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AddLend;
