import {
  Grid,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Table,
  CircularProgress,
  Typography,
  Button,
  InputLabel,
  Autocomplete,
  TextField,
} from '@material-ui/core';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../hooks/useRedux';
import { getFixedTime } from '../../../auth/helpers';
import TextFieldFetching from '../../../shared/components/TextFieldFetching';
import { Schedule } from '../../../students/academic-hourhands/interfaces/academic-hourhand-interfaces';
import useGoToVirtualClassroom from '../../../students/academic-portfolio/hooks/useGoToVirtualClassroom';
import { useGetTeacherPeriodsQuery, useGetTeacherScheduleQuery } from '../slices/HourhandApiSlice';
import IconTelework from '../../../shared/components/illustrations/IconTelework';

const days = [
  {
    label: '',
    keyword: 'mat_name',
  },
  {
    label: 'Lunes',
    keyword: 'monday',
  },
  {
    label: 'Martes',
    keyword: 'tuesday',
  },
  {
    label: 'Miércoles',
    keyword: 'wednesday',
  },
  {
    label: 'Jueves',
    keyword: 'thursday',
  },
  {
    label: 'Viernes',
    keyword: 'friday',
  },
  {
    label: 'Sábado',
    keyword: 'saturday',
  },
  {
    label: 'Domingo',
    keyword: 'sunday',
  },
];

const ScheduleTable = () => {
  const { t } = useTranslation();

  const { currentProfile } = useAppSelector((state) => state.access);
  const { userInfo } = useAppSelector((state) => state.auth);

  const [periodId, setPeriodId] = useState<number | null>(null);

  const [valueCourseId, setValueCourseId] = useState<number>(0);

  const { data: currentPeriods = [], isLoading: isLoadingP } = useGetTeacherPeriodsQuery(
    {
      user_profile_id: currentProfile?.profileId!,
      collaboratorId: userInfo?.collaboratorInfo?.id!,
    },
    {
      skip: !currentProfile?.profileId || !userInfo?.collaboratorInfo?.id,
    }
  );

  const {
    data: subjects = [],
    isLoading: isLoadingS,
    isFetching: isFetchingS,
  } = useGetTeacherScheduleQuery(
    {
      user_profile_id: currentProfile?.profileId!,
      collaboratorId: userInfo?.collaboratorInfo?.id!,
      periodId: periodId!,
    },
    {
      skip: !currentProfile?.profileId || !userInfo?.collaboratorInfo?.id || !periodId,
    }
  );

  useEffect(() => {
    if (currentPeriods.length > 0) {
      setPeriodId(currentPeriods[0]?.id || null);
    }
  }, [currentPeriods]);

  const { isFetching } = useGoToVirtualClassroom(valueCourseId, 'inicio', true);

  return (
    <Grid container>
      <Grid item mb={3} lg={4} md={6} xs={12}>
        <InputLabel>{t('shared.schedules.period')}</InputLabel>
        {isLoadingP && <TextFieldFetching />}
        {!isLoadingP && (
          <Autocomplete
            options={
              Array.isArray(currentPeriods)
                ? currentPeriods
                    .slice()
                    .sort((a, b) => Number(b?.per_current_year) - Number(a?.per_current_year))
                    .filter((item) => item)
                : []
            }
            loading={isLoadingP}
            noOptionsText={t('shared.schedules.period.empty')}
            loadingText={t('shared.schedules.period.loading')}
            getOptionLabel={(option) => `${option?.per_name}`}
            groupBy={(option) => option?.per_current_year}
            value={
              Array.isArray(currentPeriods)
                ? currentPeriods?.find((item) => item?.id === periodId)
                : null
            }
            renderInput={(params) => (
              <TextField {...params} placeholder={t('shared.schedules.period.placeholder')} />
            )}
            onChange={(_, value) => {
              setPeriodId(value?.id || currentPeriods?.[0]?.id || null);
            }}
          />
        )}
      </Grid>
      {periodId && !isLoadingS && !isFetchingS && subjects ? (
        <>
          {subjects.length > 0 ? (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {days.map((day) => (
                      <TableCell align="center" key={day.keyword}>
                        {t(`utils.${day.keyword}`)}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {subjects.map((subject) => (
                    <TableRow>
                      {days.map((day) => (
                        <TableCell key={day.keyword} align="center">
                          {day.keyword === 'mat_name' && (
                            <Grid
                              container
                              sx={{
                                width: '320px',
                                textAlign: 'left',
                              }}
                            >
                              <Grid item xs={12}>
                                <Typography variant="h5" color="primary">
                                  {subject.mat_name}
                                </Typography>
                              </Grid>
                              {subject.tm_name && (
                                <Grid item xs={12}>
                                  <Typography variant="h6">
                                    <strong>{t('shared.schedules.type_matter')}:</strong>{' '}
                                    {subject.tm_name}
                                  </Typography>
                                </Grid>
                              )}
                              <Grid item xs={12}>
                                <Typography variant="h6">
                                  <strong>{t('shared.schedules.campus')}:</strong> {subject.campus}
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Typography variant="h6">
                                  <strong>{t('shared.schedules.classroom')}:</strong>{' '}
                                  {subject.classroom}
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Typography variant="h6">
                                  <strong>{t('shared.schedules.parallel')}:</strong>{' '}
                                  {subject.parallel}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} mt={1}>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={() => setValueCourseId(Number(subject.course_id))}
                                  size="small"
                                  disabled={isFetching}
                                  endIcon={
                                    Boolean(
                                      isFetching && valueCourseId === Number(subject.course_id)
                                    ) && <CircularProgress size={12} />
                                  }
                                >
                                  {t('shared.schedules.virtual_classroom')}
                                </Button>
                              </Grid>
                            </Grid>
                          )}
                          {day.keyword !== 'mat_name' &&
                            Boolean(Number(subject[`hour_${day.keyword}` as keyof Schedule])) && (
                              <Typography variant="body1" color="black">
                                {/* {subject[`hour_start_time_${day.keyword}` as keyof Schedule]} -{' '}
                                {subject[`hour_end_time_${day.keyword}` as keyof Schedule]} */}
                                {getFixedTime(
                                  subject[
                                    `hour_start_time_${day.keyword}` as keyof Schedule
                                  ]! as string
                                )}{' '}
                                -{' '}
                                {getFixedTime(
                                  subject[
                                    `hour_end_time_${day.keyword}` as keyof Schedule
                                  ]! as string
                                )}
                              </Typography>
                            )}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Grid item container justifyContent="center" mt={2}>
              <Typography variant="h5">{t('shared.schedules.empty')}</Typography>
            </Grid>
          )}
        </>
      ) : (
        <Grid item container xs={12} justifyContent="center" mt={2}>
          {isLoadingS && isFetchingS ? (
            <CircularProgress />
          ) : (
            <Grid container justifyContent={'center'} item xs={8} md={6}>
              <Grid container item xs={12} justifyContent={'center'}>
                <IconTelework size={300} />
              </Grid>
              <Typography variant={'body1'} textAlign={'center'} lineHeight={2} component={'div'}>
                <Typography
                  variant={'h4'}
                  //sx={{ color: theme.palette.error.main }}
                  component={'p'}
                  align={'center'}
                  lineHeight={2}
                ></Typography>
                <Typography
                  variant={'h5'}
                  //sx={{ color: theme.palette.primary.main }}
                >
                  No hay horarios disponibles
                </Typography>
              </Typography>
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default ScheduleTable;
