import { useHistory, Link, useRouteMatch } from 'react-router-dom';
import EditButton from '../../../shared/components/tables/buttons/EditButton';
import DeleteButton from '../../../shared/components/tables/buttons/DeleteButton';
import AddButton from '../../../shared/components/tables/buttons/AddButton';

import { openDeleteModalWith, setShelfToEdit } from '../slices/shelfsSlice';
import useGuard from '../hooks/useGuard';

import StatusChip from '../../../shared/components/badges/StatusChip';
import SearchPaginatedTable, {
  FilterArrow,
} from '../../../shared/components/tables/SearchPaginatedTable';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { useGetShelfsQuery } from '../slices/shelfsApiSlice';
import { useEffect, useState } from 'react';
import { useGetAvailablesCampusQuery } from '../../../maintenance/campus/slices/campusApiSlice';

const ShelfsTable = () => {
  const dispatch = useAppDispatch();
  const { shelftToDelete } = useAppSelector((state) => state.shelfts);
  const { currentProfile } = useAppSelector((state) => state.access);

  const { hasAccess } = useGuard();
  const { path } = useRouteMatch();
  const history = useHistory();

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(5);
  const [search, setSearch] = useState('');
  const [filterArrow, setFilterArrow] = useState<FilterArrow>(null!);

  const {
    data: shelfs,
    isLoading: isLoadingShelfs,
    isFetching: isFetchingShelfs,
    isError: isErrorShelfs,
    error: errorShelfs,
    refetch,
  } = useGetShelfsQuery(
    {
      page,
      size,
      search,
      ...(filterArrow && { sort: filterArrow.name, type_sort: filterArrow.direction }),
    },
    { refetchOnMountOrArgChange: true }
  );

  const {
    data: campuses,
    //  isLoading: isLoadingCampuses
  } = useGetAvailablesCampusQuery({
    profileId: currentProfile?.profileId!,
  });

  const handleDelete = async (shelftId: number) => {
    dispatch(openDeleteModalWith(shelftId));
  };

  const goToAdd = async () => {
    await dispatch(setShelfToEdit(undefined));
    history.push(`${path}/agregar`);
  };

  useEffect(() => {
    if (shelftToDelete === undefined) {
      refetch();
    }
  }, [shelftToDelete, refetch]);

  return (
    <SearchPaginatedTable
      data={
        shelfs?.data.map((sh, index) => ({
          id: Number(shelfs?.from) + index,
          name: sh.description,
          type: sh.location_type,
          capacity: sh.capacity,
          sede: campuses?.find((c) => c.id === Number(sh.campus_id))?.cam_name || '',
          statusId: Number(sh.status_id),
          options: '',
        })) || []
      }
      headers={{
        id: 'Nº',
        name: 'Nombre',
        type: 'Tipo',
        capacity: 'Capacidad',
        sede: 'Sede',
        statusId: 'Estado',
        options: 'Opciones',
      }}
      keyExtractor={(item) => item.id.toString()}
      searchPlacehoder="Buscar Estante"
      listFilterArrows={{
        name: 'description',
        capacity: 'capacity',
        sede: 'campus_id',
        type: 'location_type',
        statusId: 'status_id',
      }}
      filterArrow={filterArrow}
      setFilterArrow={setFilterArrow}
      isLoading={isLoadingShelfs}
      isFetching={isFetchingShelfs}
      error={isErrorShelfs ? errorShelfs : undefined}
      perPage={size}
      setPerPage={setSize}
      total={shelfs?.total!}
      page={page}
      setPage={setPage}
      numHeader={7}
      search={search}
      setSearch={setSearch}
      customRenderers={{
        // id: (row) => row.id.id,

        statusId: (row) => <StatusChip statusId={row.statusId} />,

        options: (row) => (
          <>
            {hasAccess('edit') && (
              <Link to={`${path}/editar`}>
                <EditButton
                  title="Editar"
                  handleEdit={() => {
                    dispatch(
                      setShelfToEdit(
                        shelfs?.data.find((sh, index) => index === row.id - Number(shelfs?.from))
                      )
                    );
                  }}
                />
              </Link>
            )}
            {hasAccess('delete') && (
              <DeleteButton
                handleDelete={() =>
                  handleDelete(
                    shelfs?.data.find((sh, index) => index === row.id - Number(shelfs?.from))?.id!
                  )
                }
              />
            )}
            {!hasAccess('edit') && !hasAccess('delete') && '-'}
          </>
        ),
      }}
      ActionButtons={<>{hasAccess('create') && <AddButton size="large" onClick={goToAdd} />}</>}
      customDataCellsProperties={{
        id: { align: 'center' },
        type: { align: 'center' },
        capacity: { align: 'center' },
        sede: { align: 'center' },
        statusId: { align: 'center' },

        options: { align: 'center' },
      }}
    />
  );
};

export default ShelfsTable;
