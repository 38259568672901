import { Switch, Redirect } from 'react-router-dom';

import { useAppSelector } from '../../../../hooks/useRedux';

import RequestsStatusScreen from '../screens/RequestsStatusScreen';

import GuardRoute from '../../../../router/guards/GuardRoute';

import { subPermissionKeys } from '../constants/permissions';
import RequestsStatusDetailsScreen from '../screens/RequestsStatusDetailsScreen';
import RequestsStatusEditScreen from '../screens/RequestStatusEdit';
import RequestsStatusAddScreen from '../screens/RequestStatusAddScreen';
import RequestStatusEditRolesScreen from '../screens/RequestStatusEditRolesScreen';

const { groupKey: permissionKey } = subPermissionKeys;

const RequestsStatusRouter = () => {
  const { currentModule } = useAppSelector((state) => state.access);

  const hasPermission = 'applications' in currentModule?.permissions!;

  if (!hasPermission) return <Redirect to="/dashboard" />;

  return (
    <Switch>
      <GuardRoute
        exact
        path="/administrar-estados-solicitudes"
        component={RequestsStatusScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.read}
      />
      <GuardRoute
        exact
        path="/administrar-estados-solicitudes/agregar"
        component={RequestsStatusAddScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.read}
      />
      <GuardRoute
        exact
        path="/administrar-estados-solicitudes/detalle/:id"
        component={RequestsStatusDetailsScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.read}
      />
      <GuardRoute
        exact
        path="/administrar-estados-solicitudes/editar/:id"
        component={RequestsStatusEditScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.edit}
      />
      <GuardRoute
        exact
        path="/administrar-estados-solicitudes/detalle/editar/:id"
        component={RequestStatusEditRolesScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.edit}
      />
    </Switch>
  );
};

export default RequestsStatusRouter;
