import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import MainCard from '../../../../shared/components/cards/MainCard';
import EvaluationQualify from '../components/qualify/EvaluationQualify';

const EvaluationQualifyScreen = function () {
  return (
    <React.Fragment>
      <Grid
        component={MainCard}
        sx={{
          borderTopLeftRadius: '0px',
          borderTopRightRadius: '0px',
        }}
      >
        <Grid sx={{ mb: 3 }}>
          <Typography variant="h2">Calificar Test</Typography>
        </Grid>
        <EvaluationQualify />
      </Grid>
    </React.Fragment>
  );
};

export default EvaluationQualifyScreen;
