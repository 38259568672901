import {
    Grid, Typography
} from '@material-ui/core';
import MainCard from '../../../shared/components/cards/MainCard';
import ListStudents from '../components/ListStudents';

const TrackingNivelationScreen = () => {
    return (
        <Grid component={MainCard} sx={{ padding: 2 }}>
            <Grid sx={{ mb: 3 }}>
                <Typography variant="h2">Seguimiento de Nivelación</Typography>
            </Grid>
            
            <ListStudents/>
        </Grid>
    )
}

export default TrackingNivelationScreen;