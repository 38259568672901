import { Grid, Typography } from '@material-ui/core';
//import { useAppSelector } from '../../../../hooks/useRedux';
import MainCard from '../../../shared/components/cards/MainCard';
import LendingsTable from '../components/LendingsTable';

const LendingsScreen = () => {
  //const { authorToDelete } = useAppSelector((state) => state.authors);
  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">Préstamos</Typography>
      </Grid>

      <LendingsTable />

      {/* {authorToDelete && <DeleteAuthorDialog />} */}
    </Grid>
  );
};

export default LendingsScreen;
