import { Switch, Redirect } from 'react-router-dom';
import { useAppSelector } from '../../../../hooks/useRedux';

import MaintenanceProduct from '../screens/MaintenanceProduct';
import MaintenanceProductAdd from '../screens/MaintenanceProductAdd';
import MaintenanceProductEdit from '../screens/MaintenanceProductEdit';

import GuardRoute from '../../../../router/guards/GuardRoute';
import { permissionKey, subPermissionKeys } from '../constants/permissions';

const MaintenanceProductRouter = () => {
    const { currentModule } = useAppSelector((state) => state.access);

    const hasPermission = 'economicGroup' in currentModule?.permissions!;

    if (!hasPermission) return <Redirect to="/dashboard" />;

    return (
        <Switch>
            <GuardRoute
                exact
                path="/administrar-mantenimiento-producto"
                component={MaintenanceProduct}
                permissionKey={permissionKey}
                subPermissionKey={subPermissionKeys.read}
            />
            <GuardRoute
                exact
                path="/administrar-mantenimiento-producto/agregar"
                component={MaintenanceProductAdd}
                permissionKey={permissionKey}
                subPermissionKey={subPermissionKeys.create}
            />
            <GuardRoute
                exact
                path="/administrar-mantenimiento-producto/:productId/editar"
                component={MaintenanceProductEdit}
                permissionKey={permissionKey}
                subPermissionKey={subPermissionKeys.edit}
            />
        </Switch>
    )
}

export default MaintenanceProductRouter;