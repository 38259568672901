import { Switch, Redirect } from 'react-router-dom';
import { useAppSelector } from '../../../../hooks/useRedux';
import GuardRoute from '../../../../router/guards/GuardRoute';
import {
  permissionKey,
  //subPermissionKeys,
  permissionKeyAdmin,
} from '../constants/permissions';
import AdvanceScreen from '../screens/AdvanceScreen';
import ApplicationsStatusScreen from '../screens/ApplicationsStatusScreen';
import BirthdayHourScreen from '../screens/BirthdayHourScreen';
import PersonalDayScreen from '../screens/PersonalDayScreen';
import VacationsScreen from '../screens/VacationsScreen';

const RrHhApplicationsRouter = () => {
  const { currentModule } = useAppSelector((state) => state.access);
  const hasPermission =
    permissionKey in currentModule?.permissions! ||
    permissionKeyAdmin in currentModule?.permissions!;

  if (!hasPermission) <Redirect to="/dashboard" />;

  return (
    <Switch>
      <GuardRoute
        exact
        path="/solicitud/anticipo"
        component={AdvanceScreen}
        permissionKey={permissionKey || permissionKeyAdmin}
        //subPermissionKey={subPermissionKeys.read}
      />
      <GuardRoute
        exact
        path="/solicitud/vacaciones"
        component={VacationsScreen}
        permissionKey={permissionKey || permissionKeyAdmin}
        //subPermissionKey={subPermissionKeys.read}
      />
      <GuardRoute
        exact
        path="/solicitud/dia-personal"
        component={PersonalDayScreen}
        permissionKey={permissionKey || permissionKeyAdmin}
        //subPermissionKey={subPermissionKeys.read}
      />
      <GuardRoute
        exact
        path="/solicitud/happy-birthday-hour"
        component={BirthdayHourScreen}
        permissionKey={permissionKey || permissionKeyAdmin}
        //
      />
      <GuardRoute
        exact
        path="/solicitud/estados"
        component={ApplicationsStatusScreen}
        permissionKey={permissionKey || permissionKeyAdmin}
        //subPermissionKey={subPermissionKeys.read}
      />
    </Switch>
  );
};

export default RrHhApplicationsRouter;
