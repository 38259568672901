import {
  CircularProgress,
  IconButton,
  IconButtonProps,
  Tooltip,
  TooltipProps,
} from '@material-ui/core';
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone';
import type { TablerIcon } from '@tabler/icons';

export interface Props extends IconButtonProps {
  handleClick?: () => void;
  title?: string;
  // color?: 'inherit' | 'secondary' | 'default' | 'primary' | 'info' | undefined;

  Icon?: TablerIcon;
  isLoading?: boolean;

  tooltipProps?: Omit<Partial<TooltipProps>, 'title'>;
}

const CustomIconButton = ({
  handleClick,
  title,
  color,
  Icon,
  isLoading,
  tooltipProps,
  ...iconBtnProps
}: Props) => {
  const icon = Icon ? <Icon size={20} /> : <EditTwoToneIcon sx={{ fontSize: '1.3rem' }} />;

  return (
    <>
      {title ? (
        <Tooltip title={title ? title : 'Editar'} {...tooltipProps}>
          <IconButton
            aria-label={title ? title : 'Editar'}
            color={color ? color : 'secondary'}
            onClick={handleClick}
            disabled={isLoading}
            {...iconBtnProps}
          >
            {isLoading ? <CircularProgress size={20} /> : icon}
          </IconButton>
        </Tooltip>
      ) : (
        <IconButton
          color={color ? color : 'secondary'}
          onClick={handleClick}
          disabled={isLoading}
          {...iconBtnProps}
        >
          {isLoading ? <CircularProgress size={20} /> : icon}
        </IconButton>
      )}
    </>
  );
};

export default CustomIconButton;
