import {
  Button,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
  CircularProgress,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useState, useEffect } from 'react';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import { useTranslation } from 'react-i18next';
import {
  useAddServicesCompanyMutation,
  useLazyGetServiceCompanyByIdQuery,
  useUpdateServicesCompanyMutation,
} from '../slices/companiesApiSlice';
import { useHistory, useParams } from 'react-router-dom';
import { useAppSelector } from '../../../../hooks/useRedux';
import { ServiceCompanyFormSchema } from '../constants/serviceCompanySchema';

const ServiceCompanyForm = () => {
  const { t } = useTranslation();

  const [showAlerts, setShowAlerts] = useState(true);

  const history = useHistory();

  const { serviceId } = useParams<{
    serviceId: string;
  }>();

  const { company } = useAppSelector((state) => state.companies);

  const [addServicesCompany, { isLoading, isError, error, isSuccess }] =
    useAddServicesCompanyMutation();

  const [updateServicesCompany, { isLoading: islU, isError: isE, error: err, isSuccess: isS }] =
    useUpdateServicesCompanyMutation();

  const [getServiceCompanyById, { isLoading: isLoadingServiceCompany, error: eSCI }] =
    useLazyGetServiceCompanyByIdQuery();

  const { getFieldProps, errors, touched, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      service: '',
      description: '',
      url: '',
      company_id: '',
    },
    validationSchema: ServiceCompanyFormSchema,
    onSubmit: async (values) => {
      try {
        if (serviceId) {
          await updateServicesCompany({
            serviceCompanyId: serviceId,
            payload: {
              company_id: values.company_id,
              service: values.service,
              description: values.description,
              url: values.url,
            },
          });
        } else {
          await addServicesCompany({
            payload: {
              company_id: company.id,
              service: values.service,
              description: values.description,
              url: values.url,
            },
          });
        }
        setShowAlerts(true);
      } catch (error) {
        setShowAlerts(true);
      }
    },
  });

  useEffect(() => {
    if (serviceId) {
      const executeGetServiceCompanyById = async () => {
        try {
          const { data: serviceCompany } = await getServiceCompanyById({
            serviceCompany: serviceId,
          });
          if (serviceCompany) {
            setFieldValue('company_id', serviceCompany?.company?.id);
            setFieldValue('service', serviceCompany?.service);
            setFieldValue('description', serviceCompany?.description);
            setFieldValue('url', serviceCompany?.url);
          }
        } catch (error) {
          setShowAlerts(true);
        }
      };
      executeGetServiceCompanyById();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceId]);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction="column" gap={2}>
        <Grid item>
          {(isError || isE || eSCI) && showAlerts && (
            <ErrorAlert
              message={(error || err || eSCI) as string}
              handleDismiss={() => setShowAlerts(false)}
            />
          )}

          {(isSuccess || isS) && showAlerts && (
            <SuccessAlert
              message={t('financial.economic_group.add.success_alert')}
              handleDismiss={() => setShowAlerts(false)}
            />
          )}
        </Grid>

        <Grid item container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <InputLabel>{t('Nombre del servicio')}</InputLabel>
            <TextField
              disabled={isLoadingServiceCompany}
              fullWidth
              error={Boolean(errors.service && touched.service)}
              placeholder={
                isLoadingServiceCompany ? 'Cargando data' : t('Inserte el nombre del servicio')
              }
              {...getFieldProps('service')}
            />
            {errors.service && touched.service && (
              <FormHelperText error>{t(errors.service)}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={12}>
            <InputLabel>{t('Descripción del servicio')}</InputLabel>
            <TextField
              disabled={isLoadingServiceCompany}
              multiline
              rows={2}
              fullWidth
              placeholder={
                isLoadingServiceCompany ? 'Cargando data' : t('Inserte la descripción del servicio')
              }
              {...getFieldProps('description')}
            />
          </Grid>

          <Grid item xs={12} container spacing={2}>
            <Grid item xs={12}>
              <InputLabel>{t('Url')}</InputLabel>
              <TextField
                disabled={isLoadingServiceCompany}
                fullWidth
                placeholder={
                  isLoadingServiceCompany ? 'Cargando data' : t('Inserte la url del servicio')
                }
                {...getFieldProps('url')}
              />
              {errors.url && touched.url && <FormHelperText error>{t(errors.url)}</FormHelperText>}
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          container
          alignItems="center"
          justifyContent="flex-end"
          spacing={2}
          sx={{ mt: 1 }}
        >
          <Grid item>
            <Button
              variant="outlined"
              size="large"
              color="primary"
              onClick={() => history.goBack()}
            >
              {t('shared.go_back_button.label')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              disabled={isLoading || islU}
              endIcon={isLoading && <CircularProgress size={20} />}
            >
              {serviceId ? t('Editar') : t('shared.add_button.label')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default ServiceCompanyForm;
