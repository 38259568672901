import { Redirect, Switch } from 'react-router-dom';
import { useAppSelector } from '../../../../../../hooks/useRedux';
import GuardRoute from '../../../../../../router/guards/GuardRoute';
import FormEditScreen from '../screen/FormEditScreen';
import FormsAddScreen from '../screen/FormsAddScreen';
import FormsPreviewScreen from '../screen/FormsPreviewScreen';
import FormsUserScreen from '../screen/FormsUserScreen';
import ResultsScreen from '../screen/ResultsScreen';
// eslint-disable-next-line import/no-cycle

export const FormRoutes = () => {
  const { currentModule } = useAppSelector((state) => state.access);

  const hasPermission = 'evaluation' in currentModule?.permissions!;

  if (!hasPermission) return <Redirect to="/dashboard" />;

  return (
    <Switch>
      <GuardRoute
        exact
        path="/formularios/agregar-formulario"
        component={FormsAddScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.create}
      />
      <GuardRoute
        exact
        path="/formularios/editar-formulario/:id"
        component={FormEditScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.create}
      />
      <GuardRoute
        exact
        path="/formularios/previsualizar/:id"
        component={FormsPreviewScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.create}
      />
      <GuardRoute
        exact
        path="/formularios/formularios-usuario"
        component={FormsUserScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.create}
      />
      <GuardRoute
        exact
        path="/formularios/resultados"
        component={ResultsScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.create}
      />
    </Switch>
  );
};
