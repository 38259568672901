import { useMemo, useState } from 'react';

import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

import { useUpdateStudentRelativePersonMutation } from '../../../slices/studentsApiSlice';
import LoadingButton from '../../../../../shared/components/buttons/LoadingButton';
import { useGetCatalogByAcronymQuery } from '../../../../../shared/slices/catalogApi';
import ErrorAlert from '../../../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../../../shared/components/alerts/SuccessAlert';
import { personfilters } from '../../../../../shared/constants/globals';
import TextFieldFetching from '../../../../../shared/components/TextFieldFetching';
import CustomAsyncPaginatedSelect from '../../../../../shared/components/extended/Form/CustomAsyncPaginatedSelect';
// import AddButton from '../../../../../shared/components/tables/buttons/AddButton';
import { CustomChilds } from '../../../../../shared/interfaces/material-ui.interfaces';
import { SearchPersonParam } from '../../../../../shared/interfaces/searchPerson.interface';
import { useLazyGetPersonsByParamQuery } from '../../../../../shared/slices/searchPersonApi';
import {
  NewRelativesTable,
  PersonRelative,
} from '../../../interfaces/studentRecordAcademic.interfaces';
import { updateItemFromKeyArr } from '../../../../../shared/helpers';
import { AssociatedPersonData } from '../../../constants/validationSchemas';
import { useGetAvailablesTypeEducationsQuery } from '../../../../requisition-teacher/slices/requisitionsApiSlice';

export interface Props {
  studentId: number;
  data: {
    newRelative?: Partial<NewRelativesTable>;
    existingPerson?: PersonRelative;
  } | null;
  cases?: 'newRelative' | 'existingRelative';
  handleCloseDialog: (e: React.SyntheticEvent) => void;
  setNewRelatives?: React.Dispatch<React.SetStateAction<Partial<NewRelativesTable>[]>>;
}

const EditRelativeDialogform = ({
  studentId,
  data,
  cases,
  handleCloseDialog,
  setNewRelatives,
}: Props) => {
  const { t } = useTranslation();

  const [showAlerts, setShowAlerts] = useState(false);
  const [isSuccesLocalUpd, setIsSuccesLocalUpd] = useState(false);

  const [filter, setFilter] = useState('ci');
  const [personSelect, setPersonSelect] = useState<SearchPersonParam | null>(null);

  const [updateRelative, { isError, isSuccess, isLoading, error: errorUpdate }] =
    useUpdateStudentRelativePersonMutation();

  const [getPersonsTrigger] = useLazyGetPersonsByParamQuery();

  // parentesco
  const { data: kinship, isLoading: isLoadingParentesco } = useGetCatalogByAcronymQuery({
    acronym: 'P',
  });

  const { data: citiesAvailables = [], isLoading: isLoadingCA } = useGetCatalogByAcronymQuery({
    acronym: 'C',
  });

  const { data: availablesTypeEducations = [], isLoading: isLoadingTypeEducations } =
    useGetAvailablesTypeEducationsQuery({});

  //TODO: crear hook en el componente del select
  const getPersons = async (search: string, page: number, perPage: number) => {
    if (!filter || !search)
      return {
        options: [],
        hasMore: false,
      };

    const response = await getPersonsTrigger({
      page,
      perPage,
      [filter]: search,
      // statusId: 1,
    }).unwrap();

    const hasMore = response.current_page < response.last_page;

    return {
      options: response.data.map((dt) => {
        return {
          value: dt.id,
          label: `${dt.pers_full_name} - ${dt.pers_identification}`,
          ...dt,
        };
      }),
      hasMore,
    };
  };

  console.log('dataEdit', data);

  const initialValues = useMemo(() => {
    switch (cases) {
      case 'newRelative':
        return {
          id: data?.newRelative!.id,
          person_id_relative: data?.newRelative!.person_id_relative || '',
          rel_description: data?.newRelative!.rel_description || '',
          status_id: data?.newRelative!.status_id || '',
          type_kinship_id: data?.newRelative!.type_kinship_id || '',
          pers_full_name_relative: data?.newRelative!.pers_full_name_relative || '',
          pers_identification_relative: data?.newRelative!.pers_identification_relative || '',
          person_id: '',
          emergency_contacts:
            data?.newRelative!?.emergency_contacts ||
            Boolean(data?.newRelative!?.emergency_contacts_id) ||
            false,
          emergency_contacts_id: data?.newRelative!?.emergency_contacts_id || '',
          pers_phone_home: data?.newRelative!?.pers_phone_home || '',
          pers_cell: data?.newRelative!?.pers_cell || '',
          city_id: data?.newRelative!?.city_id || 0,
          type_education_id: data?.newRelative!?.type_education_id || 0,
        };

      case 'existingRelative':
        return {
          id: data?.existingPerson!.id,
          person_id_relative: data?.existingPerson!.person_id_relative || '',
          rel_description: data?.existingPerson!.rel_description || '',
          status_id: data?.existingPerson!.status_id || '',
          type_kinship_id: data?.existingPerson!.type_kinship_id || '',
          pers_full_name_relative: data?.existingPerson!.pers_full_name_relative || '',
          pers_identification_relative: data?.existingPerson!.pers_identification_relative || '',
          person_id: data?.existingPerson!.person_id,
          emergency_contacts:
            data?.existingPerson!?.emergency_contacts ||
            Boolean(data?.existingPerson!?.emergency_contacts_id) ||
            false,
          emergency_contacts_id: data?.existingPerson!?.emergency_contacts_id || '',
          pers_phone_home: data?.existingPerson!?.pers_phone_home || '',
          pers_cell: data?.existingPerson!?.pers_cell || '',
          city_id: data?.existingPerson!?.city_id || 0,
          type_education_id: data?.existingPerson!?.type_education_id || 0,
        };

      default:
        return {
          id: '',
          person_id_relative: '',
          rel_description: '',
          status_id: '1',
          type_kinship_id: '',
          pers_full_name_relative: '',
          pers_identification_relative: '',
          person_id: '',
        };
    }
  }, [cases, data?.existingPerson, data?.newRelative]);

  const { errors, touched, values, getFieldProps, handleSubmit, setFieldValue } = useFormik({
    initialValues: { ...initialValues },
    validationSchema: AssociatedPersonData,
    onSubmit: async (values) => {
      try {
        // const { faculty, ...value } = values;

        // setNewRelatives((prev) => [
        //   { ...values, id: `t${new Date().getTime()}${values.id}` },
        //   ...prev,
        // ]);

        switch (cases) {
          case 'newRelative':
            setNewRelatives!((prev) => {
              const updated = updateItemFromKeyArr(prev, 'id', values.id!, values);
              return [...updated];
            });
            break;

          case 'existingRelative':
            await updateRelative({
              studentId,
              payload: {
                id: Number(values.id),
                person_id: Number(values.person_id),
                person_id_relative: values.person_id_relative,
                rel_description: values.rel_description,
                type_kinship_id: Number(values.type_kinship_id),
                emergency_contacts: values.emergency_contacts,
                emergency_contacts_id: values.emergency_contacts_id,
                pers_phone_home: values.pers_phone_home,
                pers_cell: values.pers_cell,
              },
            }).unwrap();
            break;

          default:
            break;
        }

        setIsSuccesLocalUpd(true);
      } catch (error) {
        setIsSuccesLocalUpd(false);
      } finally {
        setShowAlerts(true);
      }
    },
  });

  const changePersonHandler = (
    newValue: CustomChilds<SearchPersonParam, { id: number; label: string }> | null
  ) => {
    setPersonSelect(newValue);

    setFieldValue('person_id_relative', newValue?.id || '');
    setFieldValue('pers_full_name_relative', newValue?.pers_full_name || '');
    setFieldValue('pers_identification_relative', newValue?.pers_identification || '');
  };

  return (
    <form onSubmit={handleSubmit}>
      {isError && showAlerts && (
        <ErrorAlert message={errorUpdate as string} handleDismiss={() => setShowAlerts(false)} />
      )}

      {(isSuccess || isSuccesLocalUpd) && showAlerts && (
        <SuccessAlert
          message={t('Contactos del familiar actualizado exitosamente')}
          handleDismiss={() => setShowAlerts(false)}
        />
      )}

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <InputLabel>{t('Tipo de búsqueda')}</InputLabel>
          <Autocomplete
            options={personfilters}
            getOptionLabel={(option) => option.value}
            renderInput={(params) => (
              <TextField {...params} placeholder={t('Seleccione el tipo de filtro')} />
            )}
            value={personfilters.find((item) => item.name === filter) || null}
            onChange={(_, value) => {
              setFilter((prev) => value?.name || '');
              setPersonSelect(null);
            }}
            fullWidth
            clearIcon={null}
          />
        </Grid>

        <Grid item xs={12}>
          <Grid container item xs={12} justifyContent="space-between" alignItems="flex-end" mb={1}>
            <InputLabel>{t('Persona')}</InputLabel>
            {/* <AddButton variant="outlined" onClick={() => handleOpenModalWithNewPerson()} /> */}
          </Grid>
          <CustomAsyncPaginatedSelect
            key={`async-select-${filter}`}
            value={personSelect}
            isError={false}
            placeholder={values.pers_identification_relative}
            // noOptionsMessage={''}
            setValue={(newValue) =>
              changePersonHandler(
                newValue as CustomChilds<SearchPersonParam, { id: number; label: string }> | null
              )
            }
            fetchFunc={getPersons}
          />
          {errors.person_id_relative && touched.person_id_relative && (
            <FormHelperText error>{t(errors.person_id_relative)}</FormHelperText>
          )}
        </Grid>

        <Grid item xs={12}>
          <InputLabel>{t('Parentesco')}</InputLabel>
          {isLoadingParentesco ? (
            <TextFieldFetching />
          ) : (
            <Autocomplete
              options={kinship || []}
              loadingText="Cargando parentescos..."
              getOptionLabel={(option) => (option ? option.cat_name! : '')}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(errors.type_kinship_id && touched.type_kinship_id)}
                  placeholder="Cargando parentescos"
                />
              )}
              value={kinship?.find((dt) => dt.id === Number(values.type_kinship_id)) || null}
              onChange={(_, value) => {
                setFieldValue('type_kinship_id', value?.id || '');
              }}
              fullWidth
              clearIcon={null}
            />
          )}
          {errors.type_kinship_id && touched.type_kinship_id && (
            <FormHelperText error>{errors.type_kinship_id}</FormHelperText>
          )}
        </Grid>

        <Grid item xs={12}>
          <InputLabel>Nombres</InputLabel>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Nombres del familiar"
            disabled
            value={personSelect?.pers_full_name || values.pers_full_name_relative || ''}
          />
        </Grid>

        <Grid item xs={12}>
          <InputLabel required>{t('Ciudad')}</InputLabel>
          {isLoadingCA ? (
            <TextFieldFetching />
          ) : (
            <Autocomplete
              options={citiesAvailables || []}
              loading={isLoadingCA}
              loadingText={t('Cargando ciudades...')}
              noOptionsText={t('No hay ciudades disponibles')}
              getOptionLabel={(option) => option.cat_name}
              value={citiesAvailables.find(({ id }) => values.city_id + '' === id + '')}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(errors.city_id && touched.city_id)}
                  placeholder={t('Seleccione una ciudad')}
                />
              )}
              onChange={(_, value) => {
                setFieldValue('city_id', value?.id || 0);
              }}
              fullWidth
              clearIcon={null}
            />
          )}
          {errors.city_id && touched.city_id && (
            <FormHelperText error>{t(errors.city_id)}</FormHelperText>
          )}
        </Grid>

        <Grid item xs={12}>
          <InputLabel>{t('Nivel educativo')}</InputLabel>
          <Autocomplete
            options={availablesTypeEducations}
            loading={isLoadingTypeEducations}
            loadingText={t('Cargando tipo de instituciones')}
            noOptionsText={t('No hay tipo de instituciones disponibles')}
            getOptionLabel={(option) => option.typ_edu_name}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={t('Seleccione un tipo de institución disponible')}
              />
            )}
            value={
              availablesTypeEducations?.find(({ id }) => id === Number(values.type_education_id)) ||
              null
            }
            onChange={(_, value) => {
              setFieldValue('type_education_id', value?.id || 0);
            }}
            fullWidth
            clearIcon={null}
          />
        </Grid>

        <Grid item xs={12} alignItems="center">
          <FormControlLabel
            sx={{
              display: 'flex',
              alignItems: 'center',
              height: '100%',
            }}
            control={
              <Checkbox
                checked={values.emergency_contacts}
                onChange={(e) => {
                  setFieldValue('emergency_contacts', e.target.checked);
                }}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label="¿Es contacto de emergencia?"
          />
        </Grid>

        {values.emergency_contacts && (
          <>
            <Grid item xs={12}>
              <InputLabel>{t('Telefono')}</InputLabel>
              <TextField
                {...getFieldProps('pers_phone_home')}
                error={Boolean(errors.pers_cell && touched.pers_cell)}
                placeholder={t('Ingrese el telefono')}
                fullWidth
              />
              {errors.pers_phone_home && touched.pers_phone_home && (
                <FormHelperText error>{errors.pers_phone_home}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12}>
              <InputLabel>{t('Celular')}</InputLabel>
              <TextField
                {...getFieldProps('pers_cell')}
                error={Boolean(errors.pers_cell && touched.pers_cell)}
                placeholder={t('Ingrese el celular')}
                fullWidth
              />
              {errors.pers_cell && touched.pers_cell && (
                <FormHelperText error>{errors.pers_cell}</FormHelperText>
              )}
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <InputLabel>{t('Descripción de relación')}</InputLabel>
          <TextField
            fullWidth
            multiline
            rows={2}
            variant="outlined"
            placeholder="Descripción de relación"
            error={Boolean(errors.rel_description && touched.rel_description)}
            {...getFieldProps('rel_description')}
            // value={
            //   dataPersons?.data?.find((e) => e.id === formikValues.person_id_relative)
            //     ?.pers_full_name
            // }
          />
          {errors.rel_description && touched.rel_description && (
            <FormHelperText error>{errors.rel_description}</FormHelperText>
          )}
        </Grid>

        <Grid item xs={12}>
          <Grid container alignItems="center" justifyContent="flex-end" mt={1} spacing={2}>
            <LoadingButton
              type="submit"
              isLoading={isLoading}
              variant="outlined"
              size="large"
              color="primary"
            >
              Guardar
            </LoadingButton>
          </Grid>
        </Grid>

        {/* / */}
      </Grid>
    </form>
  );
};

export default EditRelativeDialogform;
