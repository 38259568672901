import Grid from '@material-ui/core/Grid';

import DocumentsCard from './DocumentsCard';
import Typography from '@material-ui/core/Typography';
import { useGetDocumentsByProjectsResearchQuery } from '../../../slices/InvestigationLinesApiSlice';

interface Props {
  projectId: string;
}

const ListAssignPersonDelete = ({ projectId }: Props) => {
  const { data: documentsAvailables, isLoading: isLoadingDocuments } =
    useGetDocumentsByProjectsResearchQuery({ projectId: projectId || '' });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {!documentsAvailables?.length && !isLoadingDocuments && (
          <Typography variant="subtitle1" color="initial">
            No hay documentos disponibles
          </Typography>
        )}

        {isLoadingDocuments && (
          <Typography variant="subtitle1" color="initial">
            Cargando información
          </Typography>
        )}

        {documentsAvailables?.map((data) => (
          <DocumentsCard
            name={data?.doc_name_file || 'N/A'}
            key={data.id}
            id={data.id}
            url={data?.doc_url || ''}
          />
        ))}
      </Grid>
    </Grid>
  );
};

export default ListAssignPersonDelete;
