import { useFormik } from 'formik';
import {
  Autocomplete,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
} from '@material-ui/core';
import {
  useAddParameterCompanyMutation,
  useEditParameterCompanyMutation,
} from '../slices/parameterCompanyApiSlice';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import { useAppSelector } from '../../../../hooks/useRedux';
import { ParametersCompanyFormSchema } from '../validations';
import { useGetModulesQuery } from '../../permissions/slices/permissionsApiSlice';
import TextFieldFetching from '../../../shared/components/TextFieldFetching';

const AddParametersCompanyForm = () => {
  const { t } = useTranslation();

  const [showAlerts, setShowAlerts] = useState(false);

  const { parametersCompanyData, pcIdSelected } = useAppSelector((state) => state.parameterCompany);
  const { currentProfile } = useAppSelector((state) => state.access);

  const [addPM, { isLoading, isSuccess, isError, error }] = useAddParameterCompanyMutation();
  const [
    editPM,
    { isLoading: isLoadingU, isSuccess: isSuccessU, isError: isErrorU, error: errorU },
  ] = useEditParameterCompanyMutation();

  const { data: modules, isLoading: isLoadingModules } = useGetModulesQuery({
    profileId: currentProfile?.profileId!,
    aditional: true,
  });

  const { errors, getFieldProps, values, setFieldValue, handleSubmit, touched, resetForm } =
    useFormik({
      initialValues: {
        cp_keyword: '',
        value: '',
        descripcion: '',
        cp_module: '',
        cp_name: '',
        url: '',
      },
      validationSchema: ParametersCompanyFormSchema,
      onSubmit: async (currentValues) => {
        if (pcIdSelected) {
          await editPM({
            parameterId: pcIdSelected,
            body: {
              cp_keyword: currentValues.cp_keyword,
              value: currentValues.value,
              descripcion: currentValues.descripcion,
              cp_module: currentValues.cp_module,
              cp_name: currentValues.cp_name,
            },
          });
        } else {
          await addPM({
            cp_keyword: currentValues.cp_keyword,
            value: currentValues.value,
            descripcion: currentValues.descripcion,
            cp_module: currentValues.cp_module,
            cp_name: currentValues.cp_name,
          });
        }
        setShowAlerts(true);
      },
    });

  useEffect(() => {
    if (pcIdSelected) {
      const parameter = parametersCompanyData.find((p) => p.id === pcIdSelected);
      if (parameter) {
        setFieldValue('cp_keyword', parameter.cp_keyword);
        setFieldValue('value', +parameter.value);
        setFieldValue('descripcion', parameter.descripcion);
        setFieldValue('cp_module', parameter.cp_module);
        setFieldValue('cp_name', parameter.cp_name);
        setFieldValue('url', parameter.url);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pcIdSelected]);

  useEffect(() => {
    if (isSuccess) {
      resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  return (
    <Grid container spacing={2}>
      {(isSuccess || isSuccessU) && showAlerts && (
        <Grid item xs={12}>
          <SuccessAlert
            message={pcIdSelected ? t('Actualizado correctamente') : t('Agregado correctamente')}
            handleDismiss={() => setShowAlerts(false)}
          />
        </Grid>
      )}
      {(isError || isErrorU) && showAlerts && (
        <Grid item xs={12}>
          <ErrorAlert
            message={(error || errorU) as any}
            handleDismiss={() => setShowAlerts(false)}
          />
        </Grid>
      )}

      <Grid item xs={12}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputLabel>{t('Nombre')}</InputLabel>
              <TextField
                fullWidth
                error={Boolean(errors.cp_name && touched.cp_name)}
                placeholder={t('Ingrese el nombre del parámetro')}
                {...getFieldProps('cp_name')}
              />
              {errors.cp_name && touched.cp_name && (
                <FormHelperText error>{t(errors.cp_name)}</FormHelperText>
              )}
            </Grid>
            <Grid item xs={12}>
              <InputLabel>{t('Descripción')}</InputLabel>
              <TextField
                fullWidth
                error={Boolean(errors.descripcion && touched.descripcion)}
                placeholder={t('Ingrese la descripción del parámetro')}
                {...getFieldProps('descripcion')}
              />
              {errors.descripcion && touched.descripcion && (
                <FormHelperText error>{t(errors.descripcion)}</FormHelperText>
              )}
            </Grid>
            <Grid item xs={12}>
              <InputLabel>{t('Módulo')}</InputLabel>
              {isLoadingModules && <TextFieldFetching />}
              {!isLoadingModules && (
                <Autocomplete
                  options={modules || []}
                  getOptionLabel={(option) => option.mod_name_translated}
                  onChange={(_, value) => {
                    setFieldValue('cp_module', value?.mod_keyword);
                  }}
                  value={modules?.find((module) => module.mod_keyword === values.cp_module) || null}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Seleccione un módulo"
                      error={Boolean(touched.cp_module && errors.cp_module)}
                    />
                  )}
                />
              )}
              {errors.cp_module && touched.cp_module && (
                <FormHelperText error>{t(errors.cp_module)}</FormHelperText>
              )}
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Valor</InputLabel>
              <TextField
                fullWidth
                error={Boolean(errors.value && touched.value)}
                placeholder={t('Ingrese el valor del parámetro')}
                {...getFieldProps('value')}
              />
              {errors.value && touched.value && (
                <FormHelperText error>{t(errors.value)}</FormHelperText>
              )}
            </Grid>
            <Grid item xs={12}>
              <InputLabel>{t('Palabra clave')}</InputLabel>
              <TextField
                fullWidth
                error={Boolean(errors.cp_keyword && touched.cp_keyword)}
                placeholder={t('Ingrese la palabra clave del parámetro')}
                {...getFieldProps('cp_keyword')}
              />
              {errors.cp_keyword && touched.cp_keyword && (
                <FormHelperText error>{t(errors.cp_keyword)}</FormHelperText>
              )}
            </Grid>

            <Grid item container xs={12} justifyContent="flex-end" spacing={2}>
              <Grid item>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  disabled={isLoading || isLoadingU}
                  endIcon={(isLoading || isLoadingU) && <CircularProgress size={12} />}
                >
                  {pcIdSelected ? t('Actualizar') : t('Agregar')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default AddParametersCompanyForm;
