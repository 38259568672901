import { Switch, Redirect } from 'react-router-dom';
import { useAppSelector } from '../../../../hooks/useRedux';
import GuardRoute from '../../../../router/guards/GuardRoute';
import { permissionKey, subPermissionKeys } from '../constants/permissions';

import AssignHoursScreen from '../screens/AssignHoursScreen';
import EditHoursScreen from '../screens/EditHoursScreen';
import TeachersScreen from '../screens/TeachersScreen';

const TeachersRouter = () => {
  const { currentModule } = useAppSelector((state) => state.access);
  const hasPermission = permissionKey in currentModule?.permissions!;

  if (!hasPermission) <Redirect to="/dashboard" />;

  return (
    <Switch>
      <GuardRoute
        exact
        path="/administrar-horas-docentes"
        component={TeachersScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.read}
      />
      <GuardRoute
        exact
        path="/administrar-horas-docentes/agregar"
        component={AssignHoursScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.create}
      />
      <GuardRoute
        exact
        path="/administrar-horas-docentes/:hourSummaryId/editar"
        component={EditHoursScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.edit}
      />
    </Switch>
  );
};

export default TeachersRouter;
