import { format, isValid } from 'date-fns';
import { ThunkDispatch } from 'redux-thunk';
import { Profile, Role, Module, Permission as PermissionLite } from '../../../types';
import {
  resetProfile,
  saveProfiles,
  setCurrentModule,
  setCurrentProfile,
  setCurrentRol,
  setCurrentPermissions,
  setPermissions,
  resetCurrentModule,
  setPermissionsArr,
} from '../../shared/reducers/accessReducer';
import { User, UserProfile } from '../interfaces/login.interfaces';
import { VerifyResponse, Permission } from '../interfaces/verify.interfaces';
import { logoutSync, setFirsPasswordChange } from '../slices/authSlice';

/**
 * Esta funcion valida la existencia de perfiles y roles
 * @param dispatch
 * @param data
 */
export const validationProfilesRol = (
  dispatch: ThunkDispatch<any, any, any>,
  data: VerifyResponse | User
) => {
  if (data.user_profiles.length === 0) {
    throw new Error(
      'No existen roles o perfiles vinculados, por favor comuníquese con el administrador.'
    ).message;
  }

  data.user_profiles.forEach((p) => {
    if (p.roles.length === 0) {
      dispatch(logoutSync());
      dispatch(resetProfile());

      localStorage.removeItem('x-token');
      localStorage.removeItem('currentRol');
      localStorage.removeItem('currentProfile');

      throw new Error(
        'No existen roles o perfiles vinculados, por favor comuníquese con el administrador.'
      ).message;
    }
  });
};

/**
 * Esta funcion construye el objeto profiles que esta dentro de access
 * y lo asigna al store
 * @param dispatch
 * @param user_profiles
 * @returns retorna profile
 */
export const mapUserProfiles = (
  dispatch: ThunkDispatch<any, any, any>,
  user_profiles: UserProfile[]
): { [key: string]: Profile } => {
  const profilesMap = {} as { [key: string]: Profile };

  user_profiles.forEach(({ profile, roles, id, profile_id, url_file }) => {
    const { pro_name, pro_description, keyword, id: idKeyword } = profile;
    const profileId = id;

    const rolesMap = {} as { [key: string]: Role };

    roles.forEach((rol) => {
      const name = rol.name;
      const rolId = rol.id;
      const description = rol.description;
      const keyword = rol.keyword;
      const canExtendPeriod = rol.can_extend_period;
      const showFilteredRequests = rol.show_app_education_level;

      const modules = {} as { [key: string]: Module };

      rol.modules.forEach((module) => {
        const moduleGroup = module.module_group;
        const moduleGroupTranslated = module.module_group_translated;
        const externalUrl = module.icon_path;

        modules[moduleGroup] = {
          name: moduleGroupTranslated,
          key: moduleGroup,
          url: externalUrl,
          permissions: {},
          permissionsArr: [],
        };
      });

      // const modulesGroupAll = rol.permissions.map((p) => p.module_group);
      // const modulesGroup = [...new Set(modulesGroupAll)];

      // const modulesGroupObj = rol.permissions.map((p) => ({
      //   key: p.module_group,
      //   name: p.module_group_translated,
      // }));

      // modulesGroup.forEach((group) => {
      //   const groupedModulesPermissions = rol.permissions.filter((p) => p.module_group === group);

      //   const result = modulesGroupObj.find((obj) => obj.key === group);

      //   const name = result?.name;

      //   const permissions = {} as { [key: string]: Permission[] };

      //   groupedModulesPermissions.forEach((gm) => {
      //     permissions[gm.parent_name] = groupedModulesPermissions
      //       .filter((gmp) => gmp.parent_name === gm.parent_name)
      //       .map((p) => ({ id: p.id, key: p.name }));
      //   });

      //   modules[group] = {
      //     permissions,
      //     name: name || '',
      //     key: group,
      //   };
      // });

      rolesMap[keyword] = {
        rolId,
        name,
        keyword,
        description,
        modules,
        canExtendPeriod,
        showFilteredRequests,
      };
    });

    profilesMap[keyword] = {
      profileId,
      keywordId: Number(idKeyword),
      keyword,
      name: pro_name,
      description: pro_description,
      roles: rolesMap,
      imgProfile: url_file,
    };
  });

  dispatch(saveProfiles(profilesMap));
  return profilesMap;
};

export const mapPermissions = (
  dispatch: ThunkDispatch<any, any, any>,
  permissionsList: Permission[]
): { [key: string]: PermissionLite[] } => {
  const modulesGroupAll = permissionsList.map((p) => p.module_group);

  const permissionsArr = permissionsList.map((p) => ({
    id: p.id,
    key: p.name,
  }));

  const modulesGroup = [...new Set(modulesGroupAll)];

  // const currentProfile = localStorage.getItem('currentProfile');
  // const currentRol = localStorage.getItem('currentRol');
  // const currentModule = localStorage.getItem('currentModule');

  // const modulesGroupObj = permissionsList.map((p) => ({
  //   key: p.module_group,
  //   name: p.module_group_translated,
  // }));

  const permissions = {} as { [key: string]: PermissionLite[] };
  // console.log('test permissions');
  modulesGroup.forEach((group) => {
    const groupedModulesPermissions = permissionsList.filter((p) => p.module_group === group);

    // const result = modulesGroupObj.find((obj) => obj.key === group);

    // const name = result?.name;

    groupedModulesPermissions.forEach((gm) => {
      permissions[gm.parent_name] = groupedModulesPermissions
        .filter((gmp) => gmp.parent_name === gm.parent_name)
        .map((p) => ({ id: p.id, key: p.name }));
    });
  });

  // const permissionsModule =
  //   profiles[`${currentProfile}`].roles[`${currentRol}`].modules[`${currentModule}`].permissions;

  // console.log('modules', permissions, permissionsModule);
  dispatch(setPermissionsArr(permissionsArr!));
  dispatch(setCurrentPermissions(permissions!));
  dispatch(setPermissions(permissions!));

  return permissions;
};

/**
 * Asigna valores al local storage y al store
 * @param dispatch asigna valor al currentProfile y currentRol en el store
 * @param profileKey este valor se asigna al currentProfile del localStorage
 * @param profiles tiene modulos y roles
 * @returns retorna los modulos
 */
export const setValuesProfileLocalStorage = (
  dispatch: ThunkDispatch<any, any, any>,
  profileKey: string,
  profiles: { [key: string]: Profile }
) => {
  const [defaultRolKey] = Object.keys(profiles[profileKey].roles);
  const modules = profiles[profileKey].roles[defaultRolKey].modules;
  const rolId = profiles[profileKey].roles[defaultRolKey].rolId;

  dispatch(setCurrentProfile(profiles[profileKey]));
  dispatch(setCurrentRol(profiles[profileKey].roles[defaultRolKey]));

  localStorage.setItem('currentProfile', profileKey);
  localStorage.setItem('currentRol', JSON.stringify({ id: rolId, keyword: defaultRolKey }));
  return { modules };
};

/**
 * Asigna un solo profile al store y localStorage antes de ser validado
 * @param dispatch dispatch para enviar en la funcion que va asignar valores
 * al localStorage y al store
 * @param profiles se debe enviar un solo profile
 * @returns retorna los modulos de ese profile
 */
export const checkProfileQuantity = (
  dispatch: ThunkDispatch<any, any, any>,
  profiles: { [key: string]: Profile }
) => {
  let modules = {} as { [key: string]: Module };
  if (Object.keys(profiles).length === 1) {
    dispatch(resetCurrentModule());
    localStorage.removeItem('currentModule');

    const [uniqueProfileKey] = Object.keys(profiles); //uniqueProfileKey= 'estudiante'
    const { modules: modulo } = setValuesProfileLocalStorage(dispatch, uniqueProfileKey, profiles);
    modules = modulo;
  }
  return { modules };
};

/**
 * Asigna un modulo a currentModule en el store
 * @param dispatch
 * @param modules enviar un solo modulo para ser asignados al store
 */
export const checkModuleQuantity = (
  dispatch: ThunkDispatch<any, any, any>,
  modules: { [key: string]: Module }
) => {
  if (Object.keys(modules).length === 1) {
    const [defaultModule] = Object.keys(modules);
    dispatch(setCurrentModule(modules[defaultModule]));
    localStorage.setItem('currentModule', defaultModule);
  }
};

/**
 * Construye la sesion obteniendo los valores del localStorage
 * @param dispatch
 * @param profiles
 */
export const buildSessionStorage = (
  dispatch: ThunkDispatch<any, any, any>,
  profiles: { [key: string]: Profile }
) => {
  const currentProfile = localStorage.getItem('currentProfile');
  const currentRolStorage = localStorage.getItem('currentRol');
  const currentModule = localStorage.getItem('currentModule');

  if (currentProfile && currentRolStorage) {
    const currentRol = JSON.parse(currentRolStorage).keyword;

    dispatch(setCurrentProfile(profiles[currentProfile]));
    dispatch(setCurrentRol(profiles[currentProfile].roles[currentRol]));

    if (currentModule) {
      dispatch(setCurrentModule(profiles[currentProfile].roles[currentRol].modules[currentModule]));
    }
  }
};

/**
 * Cambia el valor del remember_token para el primer cambio de clave
 */
export const changeRememberTokenStorage = (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(setFirsPasswordChange());
};

export const getFixedTime = (time: string) => {
  try {
    const hours = time.split(':')[0];

    let date = '';

    date = `2020-01-01T${hours.length === 1 ? `0${time}` : time}`;

    const dateFixed = new Date(date);

    return isValid(dateFixed) ? format(dateFixed, 'HH:mm') : 'TIME FORMAT ERROR';
  } catch (error) {
    return 'TIME FORMAT ERROR';
  }
};
