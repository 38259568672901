import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import { makeStyles } from '@material-ui/core/styles';
import { Chip, IconButton, Tooltip } from '@material-ui/core';
import { IconArrowDown, IconDots, IconEdit, IconTrash } from '@tabler/icons';

import StatusChip from '../../../../shared/components/badges/StatusChip';
import SubjectBadge from '../builder/SubjectBadge';

import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';

import {
  fireDeleteSubjectCurriculumDialog,
  setSubjectCurriculumSelected,
} from '../../slices/meshSlice';

import { MatterMesh } from '../../interfaces/curriculum.interfaces';

import {
  setSubjectHoverId,
  resetSubjectHoverId,
  setCurrentGroup,
  fireAssignSubjectCurriculumDialog,
} from '../../slices/meshSlice';
import { useGetMeshQuery } from '../../slices/meshsApiSlice';
import useSharedGuard from '../../../../../hooks/useSharedGuard';
import { subPermissionKeys } from '../../constants/permissions';

const useStyles = makeStyles((theme) => ({
  root: {
    // border: '1px solid red',
    padding: '20px 20px 20px 30px',
    boxShadow: '0px 0px 20px #BFDAF9',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',

    position: 'relative',

    '&::after': {
      content: `""`,
      position: 'absolute',
      top: 0,
      left: 0,
      borderTopLeftRadius: '10px',
      borderBottomLeftRadius: '10px',
      width: '10px',
      height: '100%',
    },
  },
  cardRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  cardTitle: {
    color: theme.palette.primary.dark,
    fontSize: '14px',
    fontWeight: 600,
  },
  cardSubTitle: {
    color: theme.palette.grey[400],
    fontSize: '14px',
    fontWeight: 400,
  },

  subjectType: {
    color: theme.palette.secondary.main,
    fontSize: '12px',
    fontWeight: 400,
  },
  subjectTypeText: {
    color: theme.palette.grey[600],
    fontSize: '12px',
    fontWeight: 400,
  },
  preRequisitesText: {
    color: '#31355C',
    fontSize: '12px',
    fontWeight: 600,
  },
  cardBadges: {
    display: 'flex',
    gap: '4px',
    alignItems: 'center',
    flexWrap: 'wrap',
    // justifyContent: 'flex-start',
  },
  subjectPreRequisites: {
    display: 'flex',
    gap: '4px',
    flexWrap: 'wrap',
    marginTop: '0.5rem',
    background: '#fff',
  },
  buutonPreRequisites: {
    borderRadius: 5,
    border: '2px solid #1951a0',
  },
}));

interface Props {
  subject: MatterMesh;
  isOlder?: boolean;
}

const SubjectCard = ({ subject, isOlder }: Props) => {
  const classes = useStyles();

  const dispatch = useAppDispatch();
  const { subjectHoverId } = useAppSelector((state) => state.meshs);

  const { meshId: meshIdParam } = useParams<{ meshId: string }>();
  const meshId = Number(meshIdParam);

  const { currentProfile } = useAppSelector((state) => state.access);

  const profileId = currentProfile!.profileId;

  const { data } = useGetMeshQuery({ profileId, meshId });

  const [prerequisitos, setPreRequisitos] = useState(false);

  // Dropdown hooks
  const [anchorEl, setAnchorEl] = useState<
    Element | ((element: Element) => Element) | null | undefined
  >(null);
  const handleClick = (event: React.MouseEvent<SVGSVGElement | HTMLButtonElement> | undefined) => {
    setAnchorEl(event?.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const HandlePrereq = () => {
    if (prerequisitos === false) {
      setPreRequisitos(true);
    } else {
      setPreRequisitos(false);
    }
  };

  const handleDelete = () => {
    dispatch(setSubjectCurriculumSelected(subject.id));
    dispatch(fireDeleteSubjectCurriculumDialog(true));

    setAnchorEl(null);
  };

  const getPreRequisite = () => {
    let bgcolor = '#fff';

    if (subjectHoverId && subjectHoverId === Number(subject.id)) {
      bgcolor = '#1951a01f';
    }
    return bgcolor;
  };

  const editSubjectCurriculumHandler = () => {
    dispatch(setCurrentGroup(subject.group));
    dispatch(setSubjectCurriculumSelected(subject.id));

    dispatch(fireAssignSubjectCurriculumDialog(true));

    // close dropdown
    setAnchorEl(null);
  };

  const { hasAccess } = useSharedGuard(subPermissionKeys);

  const filterComponents = subject.detail_matter_mesh.filter((lc) => +lc.dem_workload !== 0);

  return (
    <Box
      className={classes.root}
      sx={{
        '&::after': {
          bgcolor: subject.simbology?.sim_color || '',
        },
        background: getPreRequisite,
      }}
    >
      <Box>
        <Box className={classes.cardRow}>
          <Typography variant="subtitle1" className={classes.cardTitle}>
            {subject?.matter?.cod_matter_migration
              ? `${subject?.matter?.cod_matter_migration} - `
              : ''}
            {subject?.matter?.mat_name}
          </Typography>
          {!isOlder &&
            data?.status.id !== 7 &&
            (hasAccess('updateAssign') || hasAccess('removeAssign')) && (
              <>
                <IconButton onClick={handleClick}>
                  <IconDots size={20} />
                </IconButton>

                {/* Options Dropdown */}
                <Menu
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  variant="selectedMenu"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  {hasAccess('updateAssign') && (
                    <MenuItem onClick={editSubjectCurriculumHandler}>
                      <ListItemIcon>
                        <IconEdit fontSize="small" />
                      </ListItemIcon>
                      Editar
                    </MenuItem>
                  )}

                  {hasAccess('removeAssign') && (
                    <MenuItem onClick={handleDelete}>
                      <ListItemIcon>
                        <IconTrash fontSize="small" />
                      </ListItemIcon>
                      Eliminar
                    </MenuItem>
                  )}
                </Menu>
              </>
            )}
        </Box>
        <Box className={classes.cardRow}>
          <Typography variant="subtitle2" className={classes.cardSubTitle}>
            {subject.matter_rename}
          </Typography>
          <StatusChip statusId={subject.status_id} />
        </Box>
      </Box>
      <Box className={classes.cardBadges}>
        {filterComponents.map((lc) => (
          <SubjectBadge key={lc.id} label={lc.component.com_acronym} number={+lc.dem_workload} />
        ))}
        <SubjectBadge
          label="Total"
          number={filterComponents.reduce((acc, lc) => acc + +lc.dem_workload, 0)}
        />
      </Box>
      <Box className={classes.cardRow}>
        <Typography variant="subtitle2" className={classes.subjectType}>
          Tipo de Materia
        </Typography>
        <Typography variant="subtitle2" className={classes.subjectTypeText}>
          {subject.matter.type_matter?.tm_name}
        </Typography>
      </Box>

      {subject.matter_mesh_prerequisites.length > 0 && (
        <Box>
          <Box>
            <Typography className={classes.preRequisitesText}>Prerrequisitos</Typography>
            <IconButton onClick={HandlePrereq}>
              <IconArrowDown></IconArrowDown>
            </IconButton>
          </Box>
          {prerequisitos && (
            <Box className={classes.subjectPreRequisites}>
              {subject.matter_mesh_prerequisites.map((pr) => (
                <Box
                  key={pr.id}
                  onMouseOver={() => dispatch(setSubjectHoverId(pr.id))}
                  onMouseLeave={() => dispatch(resetSubjectHoverId())}
                  sx={{
                    width: '100%',
                    background: '#fff',
                  }}
                >
                  <Tooltip title={pr.matter.mat_name} placement="top">
                    <Chip
                      label={pr.matter_rename}
                      variant="outlined"
                      color="primary"
                      size="small"
                      sx={{
                        width: '100%',
                        background: '#fff',
                      }}
                    />
                  </Tooltip>
                </Box>
              ))}
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default SubjectCard;
