import { Grid, Skeleton } from '@material-ui/core';

const skeletonTable = () => {
  let rows = [];
  for (let i = 0; i <= 6; i++) {
    rows.push(
      <Grid container key={i}>
        <Grid item xs={12} sx={{ p: 1 }}>
          <Skeleton variant="text"></Skeleton>
        </Grid>
      </Grid>
    );
  }

  return <Grid container>{rows}</Grid>;
};

export default skeletonTable;
