import * as Yup from 'yup';

export const ELSSchema = Yup.object().shape({
  groupNivelationId: Yup.number().required('El grupo de área es requerido.'),
  subjectId: Yup.number().required('La materia es requerida.'),
  statusId: Yup.number().required('El estado es requerido'),
});

export const ELSSchemaEdit = Yup.object().shape({
  groupNivelationId: Yup.number().required('El grupo de área es requerido.'),
  statusId: Yup.number().required('El estado es requerido'),
});

//groupSubject
export const ELSGroupSchema = Yup.object().shape({
  subjectId: Yup.number().required('La materia es requerida.'),
  statusId: Yup.number().required('El estado es requerido'),
});

export const ELSSGroupchemaEdit = Yup.object().shape({
  groupNivelationId: Yup.number().required('El grupo de área es requerido.'),
  statusId: Yup.number().required('El estado es requerido'),
});

export const EducationLevelUpdateSchema = Yup.object().shape({
  groupAreaId: Yup.string().required('Es requerido ingresar un grupo de area'),
});
