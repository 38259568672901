import GoogleLogin, { GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';
// import { Box, LinearProgress } from '@material-ui/core';
import { useParams, Redirect } from 'react-router-dom';
import { useLoginGoogleMutation } from '../slices/authApiSlice';
import ProfileCardSkeleton from '../../shared/screens/LoadingScreen';

function LoginGoogleScreen() {
  const { googleSSO } = useParams<{ googleSSO: string }>();

  const [loginGoogleSSO, { isError }] = useLoginGoogleMutation();

  const handleGoogleLogin = async (gmail: string) => {
    await loginGoogleSSO({ gmail });
  };

  function responseGoogle(res: GoogleLoginResponseOffline | GoogleLoginResponse) {
    if ('profileObj' in res) {
      handleGoogleLogin(res.profileObj.email);
    }
  }

  return (
    <>
      <ProfileCardSkeleton />
      {isError && <Redirect to="/login/googleSSO" />}
      <GoogleLogin
        uxMode={'redirect'}
        autoLoad={googleSSO === 'googleSSO' ? false : true}
        redirectUri={`${window.location.origin}/saml/googleSSO`}
        isSignedIn={true}
        clientId={process.env.REACT_APP_CLIENT_GOOGLE_ID!}
        render={(renderProps) => <></>}
        onSuccess={responseGoogle}
        onFailure={responseGoogle}
        cookiePolicy={'single_host_origin'}
      />
    </>
  );
}

export default LoginGoogleScreen;
