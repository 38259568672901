import { useHistory, Link, useRouteMatch } from 'react-router-dom';
import {
  Autocomplete,
  Button,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  Stack,
  Switch,
  TextField,
  Typography,
  Collapse,
  CircularProgress,
  IconButton,
} from '@material-ui/core';
import EditButton from '../../../shared/components/tables/buttons/EditButton';
import DeleteButton from '../../../shared/components/tables/buttons/DeleteButton';
import AddButton from '../../../shared/components/tables/buttons/AddButton';

import useGuard from '../hooks/useGuard';
import SearchPaginatedTable, {
  FilterArrow,
} from '../../../shared/components/tables/SearchPaginatedTable';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { useEffect, useState } from 'react';
import StatusChip from '../../../shared/components/badges/StatusChip';
import { useTranslation } from 'react-i18next';
import { IconBook, IconBarcode, IconSearch } from '@tabler/icons';
import { useGetInvestigationLinesAllAvailablesQuery } from '../../../maintenance/lines-of-investigation/slices/InvestigationLinesApiSlice';
import { useGetAllTagsByTypeQuery } from '../../tags/slices/tagsApiSlice';
import {
  useGetBooksQuery,
  useGetSubjectsClassificationsQuery,
} from '../../books/slices/booskApiSlice';
import { openDeleteModalWith, setBookToEdit } from '../../books/slices/booksSlice';
import BarCodeDialog from '../../shared/components/BarCodeDialog';

const OthersList = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { bookToDelete } = useAppSelector((state) => state.books);
  const { currentProfile } = useAppSelector((state) => state.access);

  const { hasAccess } = useGuard();
  const { path } = useRouteMatch();
  const history = useHistory();

  const [barCodeDialog, setBarCodeDialog] = useState({
    open: false,
    title: '',
    value: '',
    classification: '',
    author: '',
  });

  const [showFilters, setShowFilters] = useState(false);
  const [line_research, setLineResearch] = useState<string | undefined>();
  const [author, setAuthor] = useState<number | undefined>();
  const [editorial, setEditorial] = useState<number | undefined>();
  const [idiom, setIdiom] = useState<number | undefined>();
  const [city, setCity] = useState<number | undefined>();
  const [barcode, setBarcode] = useState<string | undefined>();

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(5);
  const [search, setSearch] = useState('');
  const [filterArrow, setFilterArrow] = useState<FilterArrow>(null!);

  const handleTagsParams = () => {
    let arr: number[] = [];

    if (author) {
      arr.push(author);
    }

    if (editorial) {
      arr.push(editorial);
    }

    if (idiom) {
      arr.push(idiom);
    }

    if (city) {
      arr.push(city);
    }

    return arr;
  };

  const {
    data: books,
    isLoading,
    isFetching,
    isError,
    error,
    refetch,
  } = useGetBooksQuery(
    {
      page,
      size,
      search,
      // type_book: 'Libro',
      type_all: 0,
      line_research,
      tags: handleTagsParams(),
      institution_barcode: barcode,
      ...(filterArrow && { sort: filterArrow.name, type_sort: filterArrow.direction }),
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  //Data Filters
  const { data: lines, isLoading: isLoadingLines } = useGetInvestigationLinesAllAvailablesQuery({
    profileId: currentProfile?.profileId!,
  });

  const { data: authors, isLoading: isLoadingAuthors } = useGetAllTagsByTypeQuery({
    type_tag: 'Autor',
  });

  const { data: cities, isLoading: isLoadingCities } = useGetAllTagsByTypeQuery({
    type_tag: 'Ciudad',
  });

  const { data: editorials, isLoading: isLoadingEditorials } = useGetAllTagsByTypeQuery({
    type_tag: 'Editorial',
  });

  const { data: idioms, isLoading: isLoadingIdioms } = useGetAllTagsByTypeQuery({
    type_tag: 'Idioma',
  });

  const { data: classifications, isLoading: isLoadingClassifications } =
    useGetSubjectsClassificationsQuery({
      user_profile_id: currentProfile?.profileId!,
    });

  const handleDelete = async (bookId: number) => {
    dispatch(openDeleteModalWith(bookId));
  };

  const goToAdd = () => {
    dispatch(setBookToEdit(undefined));
    history.push(`${path}/agregar`);
  };

  useEffect(() => {
    if (bookToDelete === undefined) {
      refetch();
    }
  }, [bookToDelete, refetch]);

  const handleCloseBarCodeDialog = () => {
    setBarCodeDialog({
      open: false,
      title: '',
      value: '',
      classification: '',
      author: '',
    });
  };

  return (
    <>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={4} md={2} lg={2}>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch value={showFilters} onChange={(e) => setShowFilters(e.target.checked)} />
              }
              label="Búsqueda avanzada"
            />
          </FormGroup>
        </Grid>
        <Grid item container xs={12} md={12} lg={12}>
          <Collapse in={showFilters} sx={{ flex: 1 }}>
            <Grid item container xs={12} md={12} lg={12} spacing={2}>
              <Grid item xs={12} md={3}>
                <InputLabel>Línea de Investigación</InputLabel>
                <Autocomplete
                  options={
                    lines?.map((dt) => ({
                      value: dt.id,
                      label: dt.name,
                    })) || []
                  }
                  loading={isLoadingLines}
                  onChange={(_, val) => {
                    val ? setLineResearch(val.value) : setLineResearch(undefined);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Seleccione Línea"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {isLoadingLines ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <InputLabel>Autor</InputLabel>
                <Autocomplete
                  options={
                    authors?.map((dt) => ({
                      value: dt.id,
                      label: dt.name,
                    })) || []
                  }
                  loading={isLoadingAuthors}
                  onChange={(_, val) => {
                    val ? setAuthor(val.value) : setAuthor(undefined);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Seleccione Autor"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {isLoadingAuthors ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <InputLabel>Idioma</InputLabel>
                <Autocomplete
                  options={
                    idioms?.map((dt) => ({
                      value: dt.id,
                      label: dt.name,
                    })) || []
                  }
                  loading={isLoadingIdioms}
                  onChange={(_, val) => {
                    val ? setIdiom(val.value) : setIdiom(undefined);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Seleccione Idioma"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {isLoadingIdioms ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <InputLabel>Editorial</InputLabel>
                <Autocomplete
                  options={
                    editorials?.map((dt) => ({
                      value: dt.id,
                      label: dt.name,
                    })) || []
                  }
                  onChange={(_, val) => {
                    val ? setEditorial(val.value) : setEditorial(undefined);
                  }}
                  loading={isLoadingEditorials}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Seleccione Editorial"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {isLoadingEditorials ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <InputLabel>Ciudad</InputLabel>
                <Autocomplete
                  options={
                    cities?.map((dt) => ({
                      value: dt.id,
                      label: dt.name,
                    })) || []
                  }
                  onChange={(_, val) => {
                    val ? setCity(val.value) : setCity(undefined);
                  }}
                  loading={isLoadingCities}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Seleccione Ciudad"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {isLoadingCities ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <InputLabel>Código de Barras</InputLabel>
                <TextField
                  fullWidth
                  placeholder="Seleccione Código de Barras"
                  onChange={({ target }) => {
                    setBarcode(target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <InputLabel>Materia</InputLabel>
                <Autocomplete
                  options={
                    classifications
                      ?.filter((dt) => !Boolean(dt.parent_id))
                      .map((dt) => ({
                        label: dt.name,
                        value: dt.id,
                      })) || []
                  }
                  loading={isLoadingClassifications}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Seleccione Materia"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {isLoadingClassifications ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <InputLabel>Clasificación</InputLabel>
                <Autocomplete
                  options={
                    classifications
                      ?.filter((dt) => Boolean(dt.parent_id))
                      .map((dt) => ({
                        label: dt.name,
                        value: dt.id,
                      })) || []
                  }
                  loading={isLoadingClassifications}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Seleccione Clasificación"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {isLoadingClassifications ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item container xs={12} md={12} lg={12} justifyContent="flex-end" mb={3}>
                <Button onClick={refetch} variant="contained" endIcon={<IconSearch />}>
                  Buscar
                </Button>
              </Grid>
            </Grid>
          </Collapse>
        </Grid>
        <Grid item md={12}>
          <SearchPaginatedTable
            data={
              books?.data.map((bk, index) => ({
                id: index + Number(books?.from),
                cover: bk.book_cover,
                //barcode: bk.institution_barcode,
                type_book: bk.type_book,
                title: {
                  title: bk.title,
                  authors: bk.tags
                    .filter((t) => t.type_tag === 'Autor')
                    .map((aut) => aut.name)
                    .join(', '),
                },
                editorial: bk.tags.find((t) => t.type_tag === 'Editorial')?.name || '',
                city: bk.tags.find((t) => t.type_tag === 'Ciudad')?.name || '',
                //subjects: bk.subjects,
                publish_date: bk.publish_date,
                origin: bk.origin,
                initial_copies: `${bk.initial_copies_number}`,
                available_copies: String(Number(bk.initial_copies_number) - Number(bk.reserved)),
                status: bk.status.id,
                options: bk,
              })) || []
            }
            headers={{
              id: 'Nº',
              cover: t('books.table.cover'),
              type_book: 'Tipo de Recurso',
              //barcode: 'Código de Libro',
              title: t('books.table.title'),
              editorial: 'Editorial',
              city: 'Ciudad',
              publish_date: t('books.table.publish_date'),
              origin: t('books.table.origin'),
              initial_copies: 'Ejemplares',
              available_copies: 'Copias Disponibles',
              status: t('shared.table.status'),
              options: t('shared.search_paginated_table.table.options'),
            }}
            keyExtractor={(item) => item.id.toString()}
            searchPlacehoder={t('books.search')}
            listFilterArrows={{
              title: 'title',
              publish_date: 'publish_date',
              origin: 'origin',
              initial_copies: 'initial_copies_number',
              available_copies: 'available_copies_number',
              status: 'status_id',
            }}
            filterArrow={filterArrow}
            setFilterArrow={setFilterArrow}
            isLoading={isLoading}
            isFetching={isFetching}
            error={isError ? error : undefined}
            perPage={size}
            setPerPage={setSize}
            total={books?.total!}
            page={page}
            setPage={setPage}
            numHeader={11}
            search={search}
            setSearch={setSearch}
            customRenderers={{
              // id: (row) => row.id.id,
              cover: (row) => (
                <>
                  {row.cover ? (
                    <img
                      src={`https://${localStorage.getItem('apiUrl')}/api/show?name=${row.cover}`}
                      style={{ height: 90, width: 60, objectFit: 'cover' }}
                      alt="cover-book"
                    />
                  ) : (
                    <div
                      style={{
                        height: 90,
                        width: 60,
                        backgroundColor: 'whitesmoke',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <IconBook />
                    </div>
                  )}
                </>
              ),
              available_copies: (row) =>
                Number(row.available_copies) < 0 ? 0 : row.available_copies,
              title: (row) => (
                <Stack direction="column" sx={{ minWidth: 200 }}>
                  <Typography variant="h4">{row.title.title.toUpperCase()}</Typography>
                  <Typography variant="body1">{row.title.authors}</Typography>
                </Stack>
              ),
              status: (row) => <StatusChip statusId={Number(row.status)} />,
              // subjects: (row) => (
              //   <>
              //     <div style={{ maxWidth: 200 }}>
              //       {row.subjects.map((sj) => (
              //         <Chip
              //           label={sj.mat_name.substring(0, 30) + '...'}
              //           size="small"
              //           sx={{ m: '3px', textTransform: 'capitalize', fontSize: '10px' }}
              //         />
              //       ))}
              //     </div>
              //   </>
              // ),
              options: (row) => (
                <>
                  {hasAccess('edit') && (
                    <Link to={`${path}/editar`}>
                      <EditButton
                        handleEdit={() => {
                          dispatch(
                            setBookToEdit(
                              books?.data.find(
                                (bk, index) => index === row.id - Number(books?.from)
                              )
                            )
                          );
                        }}
                      />
                    </Link>
                  )}
                  {hasAccess('delete') && (
                    <DeleteButton
                      handleDelete={() =>
                        handleDelete(
                          Number(
                            books?.data.find((bk, index) => index === row.id - Number(books?.from))
                              ?.id!
                          )
                        )
                      }
                    />
                  )}
                  {row?.options.institution_barcode && (
                    <IconButton
                      onClick={() => {
                        setBarCodeDialog({
                          open: true,
                          value: row?.options.institution_barcode,
                          title: `${row?.options.title}`,
                          classification: row?.options.clasification?.code,
                          author:
                            row?.options?.tags?.find((tag) => tag.type_tag === 'Autor')?.name || '',
                        });
                      }}
                    >
                      <IconBarcode />
                    </IconButton>
                  )}
                  {!hasAccess('edit') && !hasAccess('delete') && 'Ninguna'}
                </>
              ),
            }}
            ActionButtons={
              <>{hasAccess('create') && <AddButton size="large" onClick={goToAdd} />}</>
            }
            customHeadersCellsProperties={{
              type_book: { align: 'center' },
              publish_date: { align: 'center' },
              available_copies: { align: 'center' },
            }}
            customDataCellsProperties={{
              id: { align: 'center' },
              type_book: { align: 'center' },
              cover: { align: 'center' },
              publish_date: { align: 'center' },
              origin: { align: 'center' },
              initial_copies: { align: 'center' },
              available_copies: { align: 'center' },
              status: { align: 'center' },
              options: { align: 'center' },
            }}
          />
        </Grid>
        {barCodeDialog?.open && (
          <BarCodeDialog
            title={barCodeDialog?.title}
            value={barCodeDialog?.value}
            handleClose={handleCloseBarCodeDialog}
            classification={barCodeDialog?.classification}
            author={barCodeDialog?.author}
          />
        )}
      </Grid>
    </>
  );
};

export default OthersList;
