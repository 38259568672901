export const permissionKey = 'area';

export interface SubPermissionKeys {
  read: string;
  readOne: string;
  create: string;
  edit: string;
  delete: string;
}

export const subPermissionKeys: SubPermissionKeys = {
  read: 'areas-listar-areas',
  readOne: 'areas-obtener-area',
  create: 'areas-crear-area',
  edit: 'areas-actualizar-area',
  delete: 'areas-borrar-area',
};
