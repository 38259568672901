import { Button, CircularProgress, Grid, TextField, Typography, useTheme } from '@material-ui/core';
import { DatePicker, LocalizationProvider } from '@material-ui/lab';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import { es } from 'date-fns/locale';
import { isAfter, format } from 'date-fns';
import { useFormik } from 'formik';
import InputLabel from '../../../shared/components/forms/InputLabel';
import * as yup from 'yup';
import { useRenewalLendingMutation } from '../slices/lendingApiSlice';
import { IconCircleCheck, IconFileAlert } from '@tabler/icons';

interface props {
  handleClose: () => void;
  idLending: string;
}

const FormRenewal = ({ handleClose, idLending }: props) => {
  const theme = useTheme();

  const schema = yup.object().shape({
    date: yup.string().required('La fecha es requerida'),
  });

  const [renewal, { isLoading, isSuccess, isError, error }] = useRenewalLendingMutation();

  const formik = useFormik({
    initialValues: {
      date: '',
    },
    validationSchema: schema,
    onSubmit: (values) => {
      renewal({
        id: idLending,
        new_date: format(new Date(values.date), 'yyyy-MM-dd'),
      });
    },
  });

  if (isSuccess) {
    return (
      <Grid container gap={4} sx={{ p: 2 }}>
        <Grid item container justifyContent="center" xs={12} gap={1}>
          <IconCircleCheck
            size={60}
            color={theme.palette.success.main}
            style={{
              padding: 12,
              backgroundColor: theme.palette.success.light,
              borderRadius: '50%',
            }}
          />
        </Grid>
        <Grid item container justifyContent="center" xs={12} gap={1}>
          <Typography variant="h4" textAlign="center">
            La Renovación se realizo correctamente
          </Typography>
        </Grid>
        <Grid item justifyContent="center" container xs={12} gap={1}>
          <Button variant="contained" onClick={handleClose}>
            Aceptar
          </Button>
        </Grid>
      </Grid>
    );
  }

  if (isError) {
    return (
      <Grid container gap={4} sx={{ p: 2 }}>
        <Grid item container justifyContent="center" xs={12} gap={1}>
          <IconFileAlert
            size={60}
            color={theme.palette.error.main}
            style={{
              padding: 12,
              backgroundColor: theme.palette.orange.light,
              borderRadius: '50%',
            }}
          />
        </Grid>
        <Grid item container justifyContent="center" xs={12} gap={1}>
          <Typography variant="h4" textAlign="center">
            {String(error)}
          </Typography>
        </Grid>
        <Grid item justifyContent="center" container xs={12} gap={1}>
          <Button variant="contained" onClick={handleClose}>
            Aceptar
          </Button>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container gap={4} sx={{ p: 2 }}>
      <Grid item container xs={12} gap={1}>
        <InputLabel>Seleccione Fecha</InputLabel>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
          <DatePicker
            shouldDisableDate={(date) => isAfter(new Date(), new Date(date))}
            value={formik.values.date}
            onChange={(val) => {
              if (val) {
                formik.setFieldValue('date', val);
              }
            }}
            renderInput={(props) => (
              <TextField
                {...props}
                error={Boolean(formik.errors.date && formik.touched.date)}
                helperText={
                  Boolean(formik.errors.date && formik.touched.date) && formik.errors.date
                }
                placeholder={undefined}
                fullWidth
              />
            )}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item container xs={12} justifyContent="center" gap={1}>
        <Button
          variant="text"
          size="large"
          color="primary"
          onClick={handleClose}
          disabled={isLoading}
        >
          No, Cancelar
        </Button>
        <Button
          variant="contained"
          size="large"
          onClick={() => {
            formik.handleSubmit();
          }}
          color="primary"
          autoFocus
          disabled={isLoading}
          endIcon={isLoading && <CircularProgress size={20} />}
        >
          Sí, Renovar
        </Button>
        {/* {!isSuccess && (
          )} */}

        {/* {isSuccess && (
            <Button variant="contained" size="large" color="primary" onClick={onClose}>
              Continuar
            </Button>
          )} */}

        {/* {!isSuccess && (
            <Button
              variant="contained"
              size="large"
              onClick={actionHandler}
              color="primary"
              autoFocus
              disabled={isLoading || disabledConfirm}
              endIcon={isLoading && <CircularProgress size={20} />}
            >
              {confirmBtn}
            </Button>
          )} */}
      </Grid>
    </Grid>
  );
};

export default FormRenewal;
