import { Redirect, Switch } from 'react-router-dom';
import { useAppSelector } from '../../../../../../hooks/useRedux';
import GuardRoute from '../../../../../../router/guards/GuardRoute';
// eslint-disable-next-line import/no-cycle
import AddQuestionScreen from '../screen/AddQuestionScreen';
import EditQuestionScreen from '../screen/EditQuestionScreen';
import QuestionsScreen from '../screen/QuestionsScreen';

export const QuestionRoutes = () => {
  const { currentModule } = useAppSelector((state) => state.access);

  const hasPermission = 'evaluation' in currentModule?.permissions!;

  if (!hasPermission) return <Redirect to="/dashboard" />;

  return (
    <Switch>
      <GuardRoute
        exact
        path="/preguntas"
        component={QuestionsScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.list}
      />
      <GuardRoute
        exact
        path="/preguntas/agregar"
        component={AddQuestionScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.create}
      />
      <GuardRoute
        exact
        path="/preguntas/:id/editar"
        component={EditQuestionScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.edit}
      />
    </Switch>
  );
};
