import { Switch } from 'react-router-dom';
import GuardRoute from '../../../../router/guards/GuardRoute';
import AcademicHourhandScreen from '../screens/AcademicHourhandScreen';

const AcademicHourhandsRouter = () => {
  return (
    <Switch>
      <GuardRoute exact path="/asignaturas-horarios" component={AcademicHourhandScreen} />
    </Switch>
  );
};

export default AcademicHourhandsRouter;
