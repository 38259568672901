import { Grid, Typography } from '@material-ui/core';
import MainCard from '../../../shared/components/cards/MainCard';
import SlackTable from '../components/SlackTable';

const AppSlackScreen = () => {
   return (
      <Grid component={MainCard} sx={{ padding: 2 }}>
         <Grid sx={{ mb: 3 }}>
            <Typography variant="h2">Aplicaciones</Typography>
            <Typography variant="h2" color="gray" fontWeight={400}>Módulo Slack</Typography>
            <br />
            <SlackTable />
         </Grid>
      </Grid>
   )
}

export default AppSlackScreen;