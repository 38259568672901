import { Switch, Redirect } from 'react-router-dom';
import { useAppSelector } from '../../../../hooks/useRedux';
import GuardRoute from '../../../../router/guards/GuardRoute';
import { subPermissionKeys } from '../constants/permissions';
import BirthdayScreen from '../screens/BirthdayScreen';

const BirthdayRouter = () => {
  const { currentModule } = useAppSelector((state) => state.access);

  const hasPermission = subPermissionKeys.groupKey in currentModule?.permissions!;
  if (!hasPermission) return <Redirect to="/dashboard" />;

  return (
    <Switch>
      <GuardRoute
        exact
        path="/cumpleanios"
        component={BirthdayScreen}
        permissionKey={subPermissionKeys.groupKey}
        subPermissionKey={subPermissionKeys.read}
      />
    </Switch>
  );
};

export default BirthdayRouter;
