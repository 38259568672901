import { mainApi } from '../../../../api/mainApi';
import { AccessState, AuthState } from '../../../../types';
import { middlewareApi } from '../../../middleware/middlewareApi';
import { ResponseSMS } from '../califications/interfaces/sms.interface';
import { studentsAvailables } from '../forum/interfaces/students.interfaces';
import { AssistancePayload } from '../interfaces/assistants.interfaces';
import { Distribution } from '../interfaces/distributions.interfaces';
import {
  AnswersActivity,
  CommentPayload,
  Forum,
  ForumPayload,
  ForumResponse,
} from '../interfaces/forums.interfaces';
import {
  ActivityMaterials,
  ListMaterials,
  PayloadMaterials,
} from '../interfaces/materials.intefaces';
import { generateScorePass } from '../interfaces/generateScorePass.interfaces';

import { MaterialsGroup } from '../interfaces/materialsGroup.interfaces';
import { DistributionCalification, GradePayload } from '../interfaces/modelCalification.interfaces';
import { StudentCalifications } from '../interfaces/studentCalification.interfaces';
import { TaskList } from '../tasks/interfaces/task-list.interfaces';
import { TaskPayload, visible_task_Payload } from '../tasks/interfaces/task-payload.interfaces';

import { setForumList } from '../slices/virtualClassroomTeacherSlice';
import { DatasActivities } from '../interfaces/activities.interfaces';
import { ActivitiesRecents } from '../interfaces/TaskRecents.interface';
import { ContentMaterials, payloadCreateFolder } from '../interfaces/contentMaterials.interfaces';
import { ContentMaterialsOld, PayloadImportMaterials } from '../interfaces/ContentMaterialsOld';
import { RecentTasks } from '../interfaces/RecentTasks';
import { PayloadUpdate } from '../interfaces/payloadUpdate.interfaces';
import { TaskDetail } from '../interfaces/taskDetail.interfaces';
import { DetailActivityStudent } from '../interfaces/DetailActivityStudent';
import { DetailStudentActivity } from '../evaluations/interfaces/DetailActivityStudent';
import { QualifyTaskPayload } from '../interfaces/PayloadQualifyTask';
import { UpdateDate } from '../interfaces/DatePayload';
import { ConfigSupply, SupplyOfDistributions } from '../interfaces/SupplyOfDistributions';
import { SupplyActivitiesList } from '../interfaces/supplyActivitiesList.interfaces';
import { SupplyConfigByCourse } from '../interfaces/supplyConfigByCourse.interfaces';

export const virtualClassroomTags = middlewareApi.enhanceEndpoints({
  addTagTypes: [
    'DistributionCalification',
    'CourseStudentCalifications',
    'Forum',
    'ListMaterials',
    'StudentsAvailables',
    'ListActivities',
    'CourseStudents',
    'SupplysOfDistribution',
  ],
});

export const virtualClassroomTeacherApi = virtualClassroomTags.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * @POST asigna la asistencia de un curso
     * @param array de envio
     */
    addFaultAssistants: builder.mutation<AssistancePayload, AssistancePayload>({
      queryFn: async (AssistancePayload, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          AssistancePayload.user_profile_id = currentProfile?.profileId;

          const { data } = await mainApi.post<AssistancePayload>(`/fault-assistants`, {
            ...AssistancePayload,
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'CourseStudents', id: 'LIST' }],
    }),

    getAssistantsListCreatedDate: builder.query<
      { date: string },
      {
        courseId: number;
      }
    >({
      queryFn: async ({ courseId }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const { data } = await mainApi.get<{ date: string }>(
            `/fault-assistants/course/${courseId}/date`,
            {
              params: {
                user_profile_id: currentProfile?.profileId,
              },
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: [{ type: 'CourseStudents', id: 'LIST' }],
    }),
    /**
     *
     * @Post update configuration supply
     *
     */
    postConfigurationSupplies: builder.mutation<
      ConfigSupply,
      { courseId: string; payloadConfiguration: Partial<ConfigSupply> }
    >({
      queryFn: async ({ payloadConfiguration, courseId }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = currentProfile?.profileId;

          const { data } = await mainApi.post<ConfigSupply>(
            `courses/${courseId}/activities/configuration`,
            {
              user_profile_id: user_profile_id,
              ...payloadConfiguration,
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [
        { type: 'SupplysOfDistribution', id: 'LIST' },
        { type: 'SupplysOfDistribution', id: 'activitiesList' },
      ],
    }),

    /**
     *@POST 
   Import materiales
     */
    importMaterialsOld: builder.mutation<
      PayloadImportMaterials,
      { collaboratorID: number; PayloadImportMaterials: Partial<PayloadImportMaterials> }
    >({
      queryFn: async ({ PayloadImportMaterials, collaboratorID }, { getState }) => {
        try {
          //contents/collaborator/2/import/courses/29096?user_profile_id=1
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          //colaborator info
          //

          const user_profile_id = currentProfile?.profileId;
          const { data } = await mainApi.post(
            `/contents/collaborator/${collaboratorID}/import/courses/${PayloadImportMaterials.courseId}/`,
            {
              user_profile_id: user_profile_id,
              ...PayloadImportMaterials,
            }
          );

          return data;
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [
        { type: 'ListMaterials', id: 'LIST' },
        { type: 'SupplysOfDistribution', id: 'LIST' },
      ],
    }),

    /**
     *@POST 
    CREATE FOLDERS*  */

    AddFolderMaterials: builder.mutation<payloadCreateFolder, payloadCreateFolder>({
      queryFn: async (payloadCreateFolder, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          payloadCreateFolder.user_profile_id = currentProfile?.profileId;

          const { data } = await mainApi.post<payloadCreateFolder>(
            `/contents/courses/${payloadCreateFolder.CursoId}`,
            {
              ...payloadCreateFolder,
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'ListMaterials', id: 'LIST' }],
    }),

    /**
     * @GET obtiene la distribucion de notas de un curso
     */
    getDistributionCalification: builder.query<DistributionCalification[], { courseId?: number }>({
      queryFn: async ({ courseId }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = currentProfile?.profileId;

          const { data } = await mainApi.get<DistributionCalification[]>(
            `/courses/${courseId}/distributions`,
            {
              params: {
                user_profile_id,
              },
            }
          );
          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result, error, arg) => [
        { type: 'DistributionCalification', id: arg.courseId },
      ],
    }),
    //  FORO - FORUM REQUESTS

    /**
     * @GET get list Forum by course
     */
    getListForumByCourse: builder.query<
      ForumResponse,
      {
        dataAll?: string;
        profileId: number;
        page?: number;
        perPage?: number;
        search?: string;
        courseId: string;
        supply?: number;
        estado?: string;
      }
    >({
      queryFn: async (
        { dataAll, profileId, page, search, perPage, courseId, supply, estado },
        { dispatch }
      ) => {
        try {
          const { data } = await mainApi.get<ForumResponse>(`/courses/${courseId}/forums`, {
            params: {
              user_profile_id: profileId,
              page,
              size: perPage,
              type_sort: 'desc',
              search,
              data: dataAll,
              ...(supply && { supply }),
              ...(estado && { estado }),
            },
          });

          dispatch(setForumList(data.data));

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'Forum', id } as const)),
              { type: 'Forum', id: 'LIST' },
            ]
          : [{ type: 'Forum', id: 'LIST' }],
    }),

    /**
     * @GET get Forum by course
     */
    getForumByCourse: builder.query<Forum, { profileId: number; forumId: string }>({
      queryFn: async ({ profileId, forumId }) => {
        try {
          const { data } = await mainApi.get<Forum>(`/forums/${forumId}`, {
            params: {
              user_profile_id: profileId,
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    /**
     * @GET get de detalle de una activiidad por Estudiante
    
      
     */
    getActityForStudent: builder.query<
      DetailStudentActivity,
      { studentId: number; activityId: number; courseId: number }
    >({
      queryFn: async ({ studentId, activityId, courseId }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = currentProfile?.profileId;
          const { data } = await mainApi.get<DetailStudentActivity>(
            `courses/${courseId}/activities/${activityId}/students/${studentId}`,
            {
              params: {
                user_profile_id,
              },
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) => [{ type: 'ListActivities', id: 'LIST' }],
    }),

    /**@GET IMPORTS ANTIGUOS */
    ///contents/collaborator/2/subject/2234?user_profile_id=1
    getContentMaterialsOld: builder.query<
      ContentMaterialsOld[],
      { subject: number; collaboratorID: number }
    >({
      queryFn: async ({ collaboratorID, subject }, { getState }) => {
        const {
          access: { currentProfile },
        } = getState() as { access: AccessState };

        const user_profile_id = currentProfile?.profileId;
        try {
          const { data } = await mainApi.get<ContentMaterialsOld[]>(
            `/contents/collaborator/${collaboratorID}/subject/${subject}`,
            {
              params: {
                user_profile_id: user_profile_id,
              },
            }
          );

          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: [{ type: 'ListMaterials', id: 'LIST' }],
    }),

    /**GET ACTIVITIIES RECENTS */

    getTaskRecent: builder.query<
      ActivitiesRecents[],
      { cursoId: number; profileId: number; año: number; month: number }
    >({
      queryFn: async ({ cursoId, profileId, año, month }) => {
        try {
          const data = await mainApi.get<ActivitiesRecents[]>(
            `/courses/${cursoId}/activities/schedule?month=${month}&year=${año}`,
            {
              params: {
                user_profile_id: profileId,
                data: 'all',
              },
            }
          );

          return { data: Array.isArray(data.data) ? data.data : [] };
        } catch (error: any) {
          return { error };
        }
      },
    }),
    getStudentsActivities: builder.query<
      DetailActivityStudent,
      { cursoId: number; activityId: number }
    >({
      queryFn: async ({ activityId, cursoId }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = currentProfile?.profileId;

          const { data } = await mainApi.get(
            `/courses/${cursoId}/activities/${activityId}/students`,
            {
              params: {
                user_profile_id,
              },
            }
          );

          return { data: data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: [{ type: 'ListActivities', id: 'LIST' }],
    }),

    getTaskDetail: builder.query<TaskDetail, { cursoId: number; taskId: number }>({
      queryFn: async ({ cursoId, taskId }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = currentProfile?.profileId;
          const { data } = await mainApi.get<TaskDetail>(`/courses/${cursoId}/task/${taskId}`, {
            params: {
              user_profile_id,
            },
          });

          return { data: Array.isArray(data) ? data[0] : [] };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: [{ type: 'ListActivities', id: 'LIST' }],
    }),

    /**
     * @GET Get info forum and comments
     */
    getComments: builder.query<Forum, { profileId: number; forumId: number; courseId: number }>({
      queryFn: async ({ profileId, forumId, courseId }) => {
        try {
          const { data } = await mainApi.get<Forum>(`/courses/${courseId}/forums/${forumId}`, {
            params: {
              user_profile_id: profileId,
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) => [{ type: 'Forum', id: 'FORUMVIEW' }],
    }),

    /**@GET TASK RECENTS */

    getRecentsTasks: builder.query<RecentTasks[], { profileId: number; courseId: number }>({
      queryFn: async ({ profileId, courseId }) => {
        try {
          const { data } = await mainApi.get<RecentTasks[]>(
            `/courses/${courseId}/activities/recent?task`,
            {
              params: {
                user_profile_id: profileId,
              },
            }
          );

          console.log(data);

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    /**
     * @PUT  actualizar fecha Tarea
     */
    updateTaskDate: builder.mutation<
      ForumPayload,
      {
        courseId: number;
        taskId: number;
        DatePayload: Partial<UpdateDate>;
      }
    >({
      queryFn: async ({ courseId, taskId, DatePayload }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = currentProfile?.profileId;
          const { data } = await mainApi.put(`/courses/${courseId}/activities/${taskId}`, {
            user_profile_id: user_profile_id,
            ...DatePayload,
          });

          return data;
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'ListActivities', id: 'LIST' }],
    }),

    /**
     * @POST create comment
     */
    addComment: builder.mutation<
      CommentPayload,
      {
        forumId: number;
        CommentPayload: Partial<CommentPayload>;
      }
    >({
      queryFn: async ({ forumId, CommentPayload }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };
          CommentPayload.user_profile_id = currentProfile?.profileId;

          const form = new FormData();
          form.append('files[]', CommentPayload.files || '');
          form.append('parent_id', CommentPayload.parent_id || '');
          form.append('csact_comments', CommentPayload.csact_comments!);
          form.append('type_document', CommentPayload.type_document!.toString());
          form.append('user_id', CommentPayload.user_id!.toString());
          form.append('user_profile_id', CommentPayload.user_profile_id!.toString());

          const { data } = await mainApi.post(`/forums/${forumId}/comments`, form, {
            headers: { 'Content-Type': 'multipart/form-data' },
          });

          return data;
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [
        { type: 'Forum', id: 'FORUMVIEW' },
        { type: 'Forum', id: 'LIST' },
      ],
    }),

    /**
     * @GET obtiene todas las notas de los estudiantes de un curso con un parametro de filtro con el recordStudent
     */
    getCourseStudentCalifications: builder.query<
      StudentCalifications[],
      { courseId?: number; studentRecordId?: number }
    >({
      queryFn: async ({ courseId, studentRecordId }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = currentProfile?.profileId;

          const { data } = await mainApi.get<StudentCalifications[]>(
            `/courses/${courseId}/students`,
            {
              params: {
                user_profile_id,
                ...(studentRecordId && { studentRecordId }),
              },
            }
          );

          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'CourseStudentCalifications', id } as const)),
              { type: 'CourseStudentCalifications', id: 'LIST' },
            ]
          : [{ type: 'CourseStudentCalifications', id: 'LIST' }],
    }),

    /**
     * @POST create forum
     */
    addForum: builder.mutation<
      ForumPayload,
      {
        profileId: number;
        courseId: number;
        ForumPayload: Partial<ForumPayload>;
      }
    >({
      queryFn: async ({ profileId, courseId, ForumPayload }) => {
        try {
          const { data } = await mainApi.post(`/courses/${courseId}/forums`, {
            user_profile_id: profileId,
            ...ForumPayload,
          });

          return data;
        } catch (error: any) {
          return { error };
        }
      },
    }),

    /**
     * @PUT update forum
     */
    updateForum: builder.mutation<
      ForumPayload,
      {
        profileId: number;
        courseId: number;
        forumId: number;
        ForumPayload: Partial<ForumPayload>;
      }
    >({
      queryFn: async ({ profileId, courseId, forumId, ForumPayload }) => {
        try {
          const { data } = await mainApi.put(`/courses/${courseId}/forums/${forumId}`, {
            user_profile_id: profileId,
            ...ForumPayload,
          });

          return data;
        } catch (error: any) {
          return { error };
        }
      },
    }),

    /**
     * @PUT update visiblidad de la tarea
     */
    visibilityTask: builder.mutation<
      visible_task_Payload,
      {
        taskId: number;
        visiblePayload: Partial<visible_task_Payload>;
      }
    >({
      queryFn: async ({ taskId, visiblePayload }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = currentProfile?.profileId;
          const { data } = await mainApi.put(`/courses/activities/${taskId}`, {
            user_profile_id: user_profile_id,
            ...visiblePayload,
          });

          return data;
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'ListActivities', id: 'LIST' }],
    }),

    /**
     *
     * @PUT update Task
     */

    upodateTask: builder.mutation<
      PayloadUpdate,
      {
        courseId: number;
        taskId: number;
        updateTaskPayload: Partial<PayloadUpdate>;
      }
    >({
      queryFn: async ({ courseId, taskId, updateTaskPayload }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };
          const profileId = currentProfile?.profileId;
          const { data } = await mainApi.put(`/courses/${courseId}/task/${taskId}`, {
            ///courses/28819/task/10171
            user_profile_id: profileId,
            ...updateTaskPayload,
          });

          return data;
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'ListActivities', id: 'LIST' }],
    }),
    /**
     *
     * @PUT CALIFICAR UNA TAREA - con su nota y comentario
     */
    QualifyTask: builder.mutation<
      PayloadUpdate,
      {
        taskId: number;
        studentId: number;
        detailId: number;
        qualifyPayload: Partial<QualifyTaskPayload>;
      }
    >({
      queryFn: async ({ studentId, detailId, taskId, qualifyPayload }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };
          const profileId = currentProfile?.profileId;
          const { data } = await mainApi.put(
            `/tasks/${taskId}/students/${studentId}/details/${detailId}`,
            {
              ///courses/28819/task/10171
              user_profile_id: profileId,
              ...qualifyPayload,
            }
          );

          return data;
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'ListActivities', id: 'LIST' }],
    }),

    /**
     * @POST asigna la nota de un estudiante en un curso
     */
    addGradeStudent: builder.mutation<
      GradePayload,
      { GradePayload: GradePayload; course_id?: number; student_id: number }
    >({
      queryFn: async ({ GradePayload, course_id, student_id }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          GradePayload.user_profile_id = currentProfile?.profileId;

          const { data } = await mainApi.post<GradePayload>(
            `/courses/${course_id}/students/${student_id}/scores `,
            {
              ...GradePayload,
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'CourseStudentCalifications', id: 'LIST' }],
    }),

    /**
     * @GET get list distribution by course
     */
    getDistributionsByCourse: builder.query<
      Distribution[],
      { profileId: number; courseId: string }
    >({
      queryFn: async ({ profileId, courseId }) => {
        try {
          const { data } = await mainApi.get<Distribution[]>(
            `/distributions/courses/${courseId}/supplies`,
            {
              params: {
                user_profile_id: profileId,
              },
            }
          );

          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    /**
     * @GET get suply to distributions
     */
    getSupplysToDistributions: builder.query<
      SupplyOfDistributions[],
      { profileId: number; courseId: string; distributionsId: string }
    >({
      queryFn: async ({ profileId, courseId, distributionsId }) => {
        try {
          const { data } = await mainApi.get<SupplyOfDistributions[]>(
            `/courses/${courseId}/distributions/${distributionsId}/supplies`,
            {
              params: {
                user_profile_id: profileId,
              },
            }
          );

          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: [{ type: 'SupplysOfDistribution', id: 'LIST' }],
    }),

    /**
     * @GET get activitys of supply
     */
    getSupplyActivitiesList: builder.query<
      SupplyActivitiesList[],
      { profileId?: number; courseId: number; distributionsId: number; supplyId: number }
    >({
      queryFn: async ({ profileId, courseId, distributionsId, supplyId }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = profileId || currentProfile?.profileId;
          const { data } = await mainApi.get<SupplyActivitiesList[]>(
            `/courses/${courseId}/distributions/${distributionsId}/supplies/${supplyId}/activities`,
            {
              params: {
                user_profile_id,
              },
            }
          );

          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: [{ type: 'SupplysOfDistribution', id: 'activitiesList' }],
    }),

    /**
     * @GET get course supply config
     */
    getSupplyConfigByCourse: builder.query<
      SupplyConfigByCourse,
      { profileId?: number; courseId: number; distributionsId: number; supplyId: number }
    >({
      queryFn: async ({ profileId, courseId, distributionsId, supplyId }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = profileId || currentProfile?.profileId;
          const { data } = await mainApi.get<SupplyConfigByCourse>(
            `/courses/${courseId}/distributions/${distributionsId}/supplies/${supplyId}`,
            {
              params: {
                user_profile_id,
              },
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: [{ type: 'SupplysOfDistribution', id: 'activitiesList' }],
    }),

    /**
     * @POST asigna nota pass
     */
    addGenerateScorePass: builder.mutation<
      generateScorePass,
      {
        generateScorePassPayload: generateScorePass;
        courseId: number;
        distributionsId: number;
        supplyId: number;
      }
    >({
      queryFn: async (
        { generateScorePassPayload, courseId, distributionsId, supplyId },
        { getState }
      ) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = currentProfile?.profileId;

          const { data } = await mainApi.post<generateScorePass>(
            `/courses/${courseId}/distributions/${distributionsId}/supplies/${supplyId}`,
            {
              ...generateScorePassPayload,
              user_profile_id,
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'CourseStudentCalifications', id: 'LIST' }],
      // invalidatesTags: [{ type: 'CourseStudentCalifications', id: 'LIST' }],
    }),

    /**
     * @DELETE remove Carpeta
     */
    DeleteFolderMaterials: builder.mutation<ContentMaterials, { id: number }>({
      queryFn: async ({ id }, { getState }) => {
        const {
          access: { currentProfile },
        } = getState() as { access: AccessState };
        const user_profile_id = currentProfile?.profileId;
        try {
          const { data } = await mainApi.delete(`/activities/${id}/materials`, {
            data: {
              user_profile_id: user_profile_id,
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'ListMaterials', id: 'LIST' }],
    }),

    /**
     * @DELETE remove task
     *
     */
    deleteTask: builder.mutation<TaskList, { courseId: number; taskId: number }>({
      queryFn: async ({ courseId, taskId }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };
          const user_profile_id = currentProfile?.profileId;
          const { data } = await mainApi.delete(`/courses/${courseId}/task/${taskId}`, {
            data: {
              user_profile_id: user_profile_id,
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'ListActivities', id: 'LIST' }],
    }),

    /**
     * @DELETE remove forum
     */
    deleteForum: builder.mutation<Forum, { profileId: number; courseId: number; forumId: number }>({
      queryFn: async ({ profileId, courseId, forumId }) => {
        try {
          const { data } = await mainApi.delete(`courses/${courseId}/forums/${forumId}`, {
            data: {
              user_profile_id: profileId,
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'Forum', id: 'LIST' }],
    }),

    // Announcement - Anuncios Requests

    /**
     * @GET get list Forum by course
     */
    getAnnouncementsByCourse: builder.query<
      ForumResponse,
      { profileId: number; page: number; perPage: number; search: string; courseId: string }
    >({
      queryFn: async ({ profileId, page, search, perPage, courseId }, { dispatch }) => {
        try {
          const { data } = await mainApi.get<ForumResponse>(`/courses/${courseId}/forums`, {
            params: {
              user_profile_id: profileId,
              page,
              size: perPage,
              type_sort: 'desc',
              search,
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'Forum', id } as const)),
              { type: 'Forum', id: 'LIST' },
            ]
          : [{ type: 'Forum', id: 'LIST' }],
    }),

    /**
     * @GET get list Materials by course
     */
    getMaterialsByCourse: builder.query<ListMaterials[], { courseId: string }>({
      queryFn: async ({ courseId }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };
          const user_profile_id = currentProfile?.profileId;
          const { data } = await mainApi.get<ListMaterials[]>(`/courses/${courseId}/materials`, {
            params: {
              user_profile_id,
            },
          });

          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'ListMaterials', id } as const)),
              { type: 'ListMaterials', id: 'LIST' },
              { type: 'ListMaterials', id: 'LISTDELETE' },
            ]
          : [{ type: 'ListMaterials', id: 'LIST' }],
    }),

    /**
     *
     * @GET contenido MATERIALES
     */
    GetContentMaterials: builder.query<ContentMaterials[], { courseId: number }>({
      queryFn: async ({ courseId }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };
          const user_profile_id = currentProfile?.profileId;
          const { data } = await mainApi.get<ContentMaterials[]>(`/contents/courses/${courseId}`, {
            params: {
              user_profile_id,
            },
          });

          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: [{ type: 'ListMaterials', id: 'LIST' }],
    }),

    /**
     * @GET GET actividades maestro
     */
    useGetActivitiesbyCourseTeacher: builder.query<
      DatasActivities,
      { page: number; size: number; collaboratorID: number; courseId: number }
    >({
      queryFn: async ({ page, size, courseId, collaboratorID }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };
          const user_profile_id = currentProfile?.profileId;
          console.log(currentProfile);
          const { data } = await mainApi.get(
            `/courses/${courseId}/collaborators/${collaboratorID}/activities`,
            {
              params: {
                user_profile_id: user_profile_id,
                page,
                size,
                type_sort: 'desc',
                act_is_score: '1',
              },
            }
          );
          console.log(data);

          return { data: data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: [{ type: 'ListActivities', id: 'LIST' }],
    }),

    /**
     * @GET get list group Materials by course
     */
    getMaterialsGroupByCourse: builder.query<MaterialsGroup[], { courseId: string }>({
      queryFn: async ({ courseId }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };
          const user_profile_id = currentProfile?.profileId;
          const { data } = await mainApi.get<MaterialsGroup[]>(
            `/courses/${courseId}/activities/materials`,
            {
              params: {
                user_profile_id,
              },
            }
          );

          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'ListMaterials', id } as const)),
              { type: 'ListMaterials', id: 'LIST' },
            ]
          : [{ type: 'ListMaterials', id: 'LIST' }],
    }),

    /**
     * @POST agrega materiales al curso
     */
    addMaterials: builder.mutation<
      PayloadMaterials,
      { courseId: number; PayloadMaterials?: PayloadMaterials }
    >({
      queryFn: async ({ PayloadMaterials, courseId }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = currentProfile?.profileId;

          const form = new FormData();
          if (PayloadMaterials?.file) {
            form.append('files[]', PayloadMaterials?.file);
            form.append('type_document', '2');
          }
          form.append('user_profile_id', `${user_profile_id!}`);
          form.append('description', `${PayloadMaterials?.description}`);
          if (PayloadMaterials?.title) form.append('title', `${PayloadMaterials?.title}`);
          if (PayloadMaterials?.link_external)
            form.append('link_external', `${PayloadMaterials?.link_external}`);
          if (PayloadMaterials?.course_id)
            form.append('course_id', `${PayloadMaterials?.course_id}`);

          //activities/10180/materials?user_profile_id=1
          ///courses/${courseId}/materials
          const { data } = await mainApi.post<PayloadMaterials>(
            `/activities/${courseId}/materials`,
            form,
            {
              headers: { 'Content-Type': 'multipart/form-data' },
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'ListMaterials', id: 'LIST' }],
    }),

    /**
     * @POST update forum comment
     */
    updateComment: builder.mutation<
      ForumPayload,
      {
        commentId: number;
        CommentPayload: Partial<CommentPayload>;
      }
    >({
      queryFn: async ({ commentId, CommentPayload /*ForumPayload*/ }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };
          console.log(CommentPayload);
          /*const { data } = await mainApi.put(`/forums/comments/${commentId}`, {
            user_profile_id: currentProfile,
            ...CommentPayload,
            //...ForumPayload,
          });*/

          const user_profile_id = currentProfile?.profileId;
          const form = new FormData();
          form.append('files[]', CommentPayload.files || '');
          form.append('csact_comments', CommentPayload.csact_comments!);
          form.append('type_document', CommentPayload.type_document!.toString());
          form.append('user_profile_id', `${user_profile_id!}`);

          const { data } = await mainApi.post(`/forums/comments/${commentId}`, form, {
            headers: { 'Content-Type': 'multipart/form-data' },
          });

          return data;
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'Forum', id: 'FORUMVIEW' }],
    }),

    /**
     * @DELETE remove comment
     */
    deleteComment: builder.mutation<AnswersActivity, { commentId: number }>({
      queryFn: async ({ commentId }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const { data } = await mainApi.delete(`forums/comments/${commentId}`, {
            data: {
              user_profile_id: currentProfile?.profileId,
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'Forum', id: 'FORUMVIEW' }],
    }),

    /**
     * @DELETE remove materials
     */
    deleteMaterials: builder.mutation<ListMaterials, { materialId: number }>({
      queryFn: async ({ materialId }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = currentProfile?.profileId;
          const { data } = await mainApi.delete(`/materials/${materialId}`, {
            data: {
              user_profile_id,
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'ListMaterials', id: 'LIST' }],
    }),

    /**
     * @GET students availables
     */
    getStudentsAvailables: builder.query<
      studentsAvailables[],
      { course: number; profileId: number }
    >({
      queryFn: async ({ course, profileId }) => {
        try {
          const { data } = await mainApi.get<studentsAvailables[]>(`/course/${course}/students`, {
            params: {
              statusApproval: 'Cursando',
              user_profile_id: profileId,
              data: 'all',
            },
          });

          //dispatch(setHistoryCalification(data!));
          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'StudentsAvailables', id } as const)),
              { type: 'StudentsAvailables', id: 'LIST' },
            ]
          : [{ type: 'StudentsAvailables', id: 'LIST' }],
    }),
    /**
     * @GET all tasks teacher
     */
    getTaskTeacher: builder.query<
      TaskList,
      {
        userProfileId: number;
        page: number;
        search?: string;
        perPage: number;
        courseId: number;
        supply?: number;
        estado?: string;
        sort?: string;
        type_sort?: string;
      }
    >({
      queryFn: async (
        { page, search, perPage, userProfileId, courseId, supply, estado, sort, type_sort },
        { dispatch }
      ) => {
        try {
          const { data } = await mainApi.get(`/courses/${courseId}/task`, {
            //
            params: {
              user_profile_id: userProfileId,
              page,
              size: perPage,
              sort: 'id',
              search: search,
              type_sort: 'desc',
              course_id: courseId,
              ...(supply && { supply }),
              ...(estado && { estado }),
              ...(sort && { sort }),
              ...(type_sort && { type_sort }),
            },
          });

          return { data: data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: [{ type: 'ListActivities', id: 'LIST' }],
    }),

    /**
     * @GET generate validations sms for califications
     */
    getSmsScores: builder.query<ResponseSMS, void>({
      queryFn: async (_, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const { data } = await mainApi.get<ResponseSMS>(`/sms/scores`, {
            params: {
              user_profile_id: currentProfile?.profileId,
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },

      // providesTags: (result) =>
      //   result
      //     ? [
      //         ...result.data?.map(({ id }) => ({ type: 'Tasks', id: id } as const)),
      //         { type: 'Tasks', id: 'LIST' },
      //       ]
      //     : [{ type: 'Tasks', id: 'LIST' }],
    }),
    /**
     * @POST create task
     */
    addSmsCode: builder.mutation<ResponseSMS, { token?: number }>({
      queryFn: async ({ token }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = currentProfile?.profileId;

          const { data } = await mainApi.post<ResponseSMS>(`/sms/scores`, {
            user_profile_id,
            token,
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    /**
     * @GET get list Materials by course
     */
    getActivitiesWithMaterialsByCourse: builder.query<ActivityMaterials[], { courseId: string }>({
      queryFn: async ({ courseId }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };
          const user_profile_id = currentProfile?.profileId;
          const { data } = await mainApi.get<ActivityMaterials[]>(
            `/courses/${courseId}/activities/materials`,
            {
              params: {
                user_profile_id,
              },
            }
          );

          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'ListMaterials', id } as const)),
              { type: 'ListMaterials', id: 'LIST' },
            ]
          : [{ type: 'ListMaterials', id: 'LIST' }],
    }),

    createTaskTeacher: builder.mutation<
      AnswersActivity,
      {
        courseId: number;
        userId: number;
        payload: TaskPayload;
      }
    >({
      queryFn: async ({ userId, courseId, payload }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const {
            auth: { userInfo },
          } = getState() as { auth: AuthState };

          const { data } = await mainApi.post(`/courses/${courseId}/tasks`, {
            user_profile_id: currentProfile?.profileId,

            user_id: userInfo.id,

            ...payload,
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'ListActivities', id: 'LIST' }],
    }),

    importCalificationsFromMoodle: builder.mutation<
      any,
      {
        profileId: number;
        courseId: number;
      }
    >({
      queryFn: async ({ profileId, courseId }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = profileId || currentProfile?.profileId;

          const { data } = await mainApi.get(`/moodle/courses/${courseId}/scores/register`, {
            params: {
              user_profile_id,
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'CourseStudentCalifications', id: 'LIST' }],
    }),
  }),
});

export const {
  useAddFaultAssistantsMutation,
  useGetDistributionCalificationQuery,
  useGetCourseStudentCalificationsQuery,
  useAddGradeStudentMutation,
  useGetListForumByCourseQuery,
  useAddForumMutation,
  useDeleteForumMutation,
  useUpdateForumMutation,
  useAddMaterialsMutation,
  useGetMaterialsByCourseQuery,
  useDeleteMaterialsMutation,
  useGetCommentsQuery,
  useAddCommentMutation,
  useUpdateCommentMutation,
  useDeleteCommentMutation,
  useGetDistributionsByCourseQuery,
  useGetStudentsAvailablesQuery,
  useCreateTaskTeacherMutation,
  useGetTaskTeacherQuery,
  useGetActivitiesWithMaterialsByCourseQuery,
  useLazyGetSmsScoresQuery,
  useAddSmsCodeMutation,
  useGetMaterialsGroupByCourseQuery,
  useUseGetActivitiesbyCourseTeacherQuery,
  useGetTaskRecentQuery,
  useGetContentMaterialsQuery,
  useDeleteFolderMaterialsMutation,
  useAddFolderMaterialsMutation,
  useGetContentMaterialsOldQuery,
  useImportMaterialsOldMutation,
  useGetRecentsTasksQuery,
  useDeleteTaskMutation,
  useUpodateTaskMutation,
  useGetTaskDetailQuery,
  useVisibilityTaskMutation,
  useGetStudentsActivitiesQuery,
  useGetActityForStudentQuery,
  useQualifyTaskMutation,
  useUpdateTaskDateMutation,

  useGetAssistantsListCreatedDateQuery,
  useGetSupplysToDistributionsQuery,
  usePostConfigurationSuppliesMutation,
  useGetSupplyActivitiesListQuery,
  useGetSupplyConfigByCourseQuery,
  useAddGenerateScorePassMutation,
  useLazyGetTaskDetailQuery,

  useImportCalificationsFromMoodleMutation,
} = virtualClassroomTeacherApi;
