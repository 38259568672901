// import { useEffect } from 'react';
import { useAppSelector } from '../../../../../hooks/useRedux';
// import { setIsValidateCalification } from '../../slices/virtualClassroomTeacherSlice';
import CalificationstTable from '../components/CalificationstTable';
import ImportCalificationsMoodleDialog from '../components/ImportCalificationsMoodleDialog';
import ValidateSms from '../components/ValidateSms';

const CalificationsScreens = () => {
  const { isValidateCalifications, importCalificationsMoodleDialogOpen } = useAppSelector(
    (state) => state.virtualClassroomTeacher
  );
  // const dispatch = useAppDispatch();

  // useEffect(() => {
  //   return () => {
  //     dispatch(setIsValidateCalification(undefined));
  //   };
  // }, [dispatch]);

  return isValidateCalifications ? (
    <>
      <CalificationstTable />
      {importCalificationsMoodleDialogOpen && <ImportCalificationsMoodleDialog />}
    </>
  ) : (
    <ValidateSms />
  );

  // return <CalificationstTable />;
};

export default CalificationsScreens;
