// import { PostDataType /*Profile, Reply*/ } from '../../../../shared/interfaces/post.interfaces';
import moment from 'moment';
import { Forum } from '../interfaces/forums.interfaces';
import CardInfoPosts from './CardInfoPosts';

interface Props {
  forum: Forum;
}

const PostsForum = ({ forum }: Props) => {
  const {
    id,
    act_description,
    detail_forum: { dta_question },
    answers_activity,
    course_activity_one: { cact_start_date },
    author: {
      id: authorId,
      person: { pers_firstname, pers_first_lastname, pers_second_lastname },
    },
  } = forum;
  const data = {
    id: id.toString() || 'N/A',
    content: act_description || 'N/A',
    details: dta_question || 'N/A',
    images:
      [
        {
          img: '',
        },
      ] || [],
    comments: answers_activity,
  };

  const profile = {
    id: authorId.toString() || 'N/A',
    avatar: 'user-1.png',
    name: `${pers_first_lastname} ${pers_second_lastname} ${pers_firstname}` || 'N/A',
    time:
      moment(cact_start_date.toLocaleString()).format('DD MMM - hh:mm').replace('.', '') || 'N/A',
  };

  return <CardInfoPosts id={id.toString()} data={data} profile={profile} />;
};

export default PostsForum;
