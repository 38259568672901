import React, { FC, useRef, useMemo, useState } from 'react';

import Badge from '@material-ui/core/Badge';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Stack from '@material-ui/core/Stack';
// import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import Divider from '@material-ui/core/Divider';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import FormHelperText from '@material-ui/core/FormHelperText';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { PickersDayProps } from '@material-ui/lab/PickersDay';

import { parseISO, isSameDay, format } from 'date-fns';
import { IconBook2, IconMessage, IconClipboardText, IconBrandZoom } from '@tabler/icons';
import PerfectScrollbar from 'react-perfect-scrollbar';

import Transitions from '../../../../shared/components/extended/Transitions';
import { Activity } from '../../interfaces/dashboard.interface';
import useGoToVirtualClassroom from '../../../academic-portfolio/hooks/useGoToVirtualClassroom';
import { ButtonBase, CircularProgress, Typography } from '@material-ui/core';
import { activityTypes } from '../../constants';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import { setInfoModalEvaluation } from '../../../virtual-classroom/slices/virtualClassroomSlice';
import { useGoActivityMoodleMutation } from '../../../../shared/slices/moodle';

// style constant
const useStyles = makeStyles((theme) => ({
  avatar: {
    ...theme.typography.commonAvatar,
    ...theme.typography.largeAvatar,
    // margin: '8px',
    height: 'auto',
    width: 'auto',
    // backgroundColor: 'transparent',
    backgroundColor: theme.palette.grey[200],
    padding: '5px',
  },

  box: {
    marginLeft: '16px',
    marginRight: '24px',
    [theme.breakpoints.down('sm')]: {
      marginRight: '16px',
    },
  },

  eventsCounter: {
    // backgroundColor: 'black',
    '& >span': {
      //   backgroundColor: 'black',
    },
  },

  dayPicker: {
    // backgroundColor: 'black',

    '&:hover': {
      '& $eventsCounter': {
        // top: '-10px',
        // transitionDuration: 2,
        '& >span': {
          //   transform: 'translate(0px,30px)',
        },
      },
    },
  },
}));

interface Props {
  /**
   * data activities of student
   */
  data: Activity[];

  /** if is on teacher component */
  isTeacher?: boolean;

  /**
   * Day in miliseconds for validating popper display
   */
  day: number;
  /**
   * date of day component
   */
  dayDate: Date;

  /**
   * Current day where popper is displayed
   */
  openDay: number;
  /**
   * Setter for openDay
   */
  setOpenDay: React.Dispatch<React.SetStateAction<number>>;

  /**
   * day component
   */
  PickerDayComponent: <TDate>(
    props: PickersDayProps<TDate> & React.RefAttributes<HTMLButtonElement>
  ) => JSX.Element;
  dayComponentProps: PickersDayProps<Date>;
}

/**
 * Interface of course object for redirect to virtual classroom
 */
interface CourseDataRoute {
  /**
   * course id
   */
  id: number;
  /**
   * course path
   */
  path: string;
  /**
   * course activity id
   */
  activityId: number;
}

const IconMap = {
  IconBook2: IconBook2,
  IconMessage: IconMessage,
  IconClipboardText: IconClipboardText,
  IconBrandZoom: IconBrandZoom,
};

const CalendarPopper: FC<Props> = ({
  data,
  isTeacher = false,
  day,
  dayDate,
  openDay,
  setOpenDay,

  PickerDayComponent,
  dayComponentProps,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  /**
   * anchorRef is used on different componets and specifying one type leads to other components throwing an error
   * */
  const popperRef = useRef<any>(null);

  const dispatch = useAppDispatch();

  const popperComponentRef = useRef<any>(null);

  const [courseDataRoute, setCourseDataRoute] = useState<CourseDataRoute | undefined>();

  const { userInfo } = useAppSelector((state) => state.auth);

  const { isFetching: isFetchingGoToAct } = useGoToVirtualClassroom(
    courseDataRoute?.id!,
    isTeacher ? `actividades/${courseDataRoute?.activityId}/detail` : `${courseDataRoute?.path}`,
    isTeacher
  );

  const [getMoodleActivity, { isLoading: isLoadingMoodle }] = useGoActivityMoodleMutation();

  const activities = useMemo(
    () =>
      data.filter(
        ({ cact_start_date, cact_end_date }) =>
          !dayComponentProps.outsideCurrentMonth &&
          (isSameDay(parseISO(cact_start_date), dayDate) ||
            (cact_end_date ? isSameDay(parseISO(cact_end_date), dayDate) : false))
      ),
    [data, dayComponentProps.outsideCurrentMonth, dayDate]
  );

  const handleToggle = () => {
    setOpenDay((prevState) => (prevState === day ? -1 : day));
  };

  const handleClose = () => {
    if (openDay === day) {
      setOpenDay(-1);
    }
  };

  const RenderActivity = (activity: (typeof activities)[0], index: number) => {
    const {
      act_description,
      cact_start_date,
      cact_end_date,
      course_id,
      activity_id,
      type_activity: { cat_acronym },
      mat_name,
      //moodle
      instance,
      modulename,
    } = activity;

    const startDate = format(parseISO(cact_start_date), '	dd MMM - HH:mm');
    const endDate = cact_end_date
      ? format(parseISO(cact_end_date), '	dd MMM - HH:mm')
      : format(parseISO(cact_start_date), '	dd MMM');

    const setActivityDataRoute = async () => {
      if (instance && modulename) {
        try {
          const moodleActivity = await getMoodleActivity({
            instance,
            modulename,
            userId: userInfo?.id!,
          }).unwrap();

          window.open(moodleActivity);
        } finally {
          // catch (error) {
          // console.log('F');
          // }
          return;
        }
      }

      const activityType = activityTypes[cat_acronym as keyof typeof activityTypes];

      setCourseDataRoute({
        id: +course_id!,
        path: activityType?.exactPath?.(activity_id!) || activityType?.path || '',
        activityId: +activity_id!,
      });

      dispatch(
        setInfoModalEvaluation({
          idEvaluation: Number(activity_id!),
          modal: true,
          endDate: new Date(cact_end_date!),
        })
      );

      return undefined;
    };

    const ActivityIcon =
      IconMap[
        activityTypes[cat_acronym as keyof typeof activityTypes]?.icon as keyof typeof IconMap
      ];

    return (
      <React.Fragment key={`calendar-popper-teacher-${index.toString()}`}>
        <ButtonBase
          onClick={setActivityDataRoute}
          disabled={isFetchingGoToAct || isLoadingMoodle}
          sx={{ width: '100%' }}
        >
          <Stack direction="row" alignItems="center" width="100%" p={1} spacing={1}>
            <Box>
              {(isFetchingGoToAct && +courseDataRoute?.activityId! === +activity_id!) ||
              isLoadingMoodle ? (
                <CircularProgress />
              ) : (
                <Avatar
                  variant="rounded"
                  className={`${classes.avatar}`}
                  // sx={{ mb: isNormal ? 0 : 1 }}
                >
                  {activityTypes[cat_acronym as keyof typeof activityTypes] ? (
                    <Grid>
                      <ActivityIcon size={18} />
                      <Typography fontSize={'8px'}>
                        {activityTypes[cat_acronym as keyof typeof activityTypes].name}
                      </Typography>
                    </Grid>
                  ) : (
                    <IconBook2 />
                  )}
                </Avatar>
              )}
            </Box>

            <Box>
              <Divider orientation="vertical" flexItem />
            </Box>

            <Box width="100%">
              <Stack justifyContent="center">
                <Box>{act_description}</Box>
                {mat_name && (
                  <Typography variant="h6" fontSize={10}>
                    {mat_name}
                  </Typography>
                )}
                <FormHelperText>{`${startDate} - ${endDate}`}</FormHelperText>
              </Stack>
            </Box>
          </Stack>
        </ButtonBase>
        {index !== activities.length - 1 && (
          <Box width="100%">
            <Divider />
          </Box>
        )}
      </React.Fragment>
    );
  };

  const renderActivities = () => activities.map(RenderActivity);

  return (
    <Box className={classes.dayPicker}>
      <Badge
        // key={key}
        overlap="circular"
        badgeContent={
          <>
            {!!activities.length ? (
              <>
                <Box component="span" className={classes.box}>
                  <Box color="inherit">
                    <Badge
                      //   overlap="circular"
                      badgeContent={activities.length}
                      color="secondary"
                      className={classes.eventsCounter}
                    ></Badge>
                  </Box>
                </Box>
                <Popper
                  // placement={matchesXs ? 'bottom' : 'bottom-end'}
                  placement={'bottom-end'}
                  open={openDay === day}
                  ref={popperComponentRef}
                  anchorEl={popperRef.current}
                  role={undefined}
                  transition
                  disablePortal={false}
                  style={{ zIndex: 999999 }}
                >
                  {({ TransitionProps }) => (
                    <Transitions in={openDay + 1} {...TransitionProps}>
                      <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                          <Box
                            sx={{
                              boxShadow: theme.customShadows.primary,
                              borderRadius: '12px',
                              // p: 1,
                            }}
                          >
                            <Box sx={{ width: '250px', py: 1 }}>
                              <PerfectScrollbar component={'div'}>
                                <Grid
                                  container
                                  sx={{ height: '100%' }}
                                  justifyContent="flex-start"
                                  alignItems="center"
                                  maxHeight="400px"
                                >
                                  {renderActivities()}
                                </Grid>
                              </PerfectScrollbar>
                            </Box>
                          </Box>
                        </ClickAwayListener>
                      </Paper>
                    </Transitions>
                  )}
                </Popper>
              </>
            ) : undefined}
          </>
        }
      >
        <PickerDayComponent {...dayComponentProps} ref={popperRef} onClick={() => handleToggle()} />
      </Badge>
    </Box>
  );
};

export default CalendarPopper;
