import { Redirect, Switch } from 'react-router-dom';
import { useAppSelector } from '../../../../hooks/useRedux';
import GuardRoute from '../../../../router/guards/GuardRoute';
import { permissionKey, subPermissionKeys } from '../constants/permissions';
import AddPosgraduatePlanningCalendarScreen from '../screens/AddPosgraduatePlanningCalendarsScreen';
import EditPosgraduatePlanningCalendarScreen from '../screens/EditPosgraduatePlanningCalendarcreen';
import PosgraduatePlanningCalendarScreen from '../screens/PosgraduatePlanningCalendarScreen';

const PlanningPosgraduateCalendarRouter = () => {
  const { currentModule } = useAppSelector((state) => state.access);

  const hasPermission = permissionKey in currentModule!.permissions;

  if (!hasPermission) return <Redirect to="/dashboard" />;

  return (
    <Switch>
      <GuardRoute
        exact
        path="/planeacion-calendario-posgrado"
        component={PosgraduatePlanningCalendarScreen}
        subPermissionKey={subPermissionKeys.read}
        permissionKey={permissionKey}
      />
      <GuardRoute
        exact
        path="/planeacion-calendario-posgrado/agregar"
        component={AddPosgraduatePlanningCalendarScreen}
        subPermissionKey={subPermissionKeys.read}
        permissionKey={permissionKey}
      />
      <GuardRoute
        exact
        path="/planeacion-calendario-posgrado/:id/editar"
        component={EditPosgraduatePlanningCalendarScreen}
        subPermissionKey={subPermissionKeys.read}
        permissionKey={permissionKey}
      />
    </Switch>
  );
};

export default PlanningPosgraduateCalendarRouter;
