import { Grid, Typography } from '@material-ui/core'
import MainCard from '../../../shared/components/cards/MainCard'
import RequestsStatusTable from '../components/RequestsStatusTable';

const RequestsStatusScreen = () => {
  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant='h2'>Listado de Estados de Solicitudes</Typography>
      </Grid>
      <RequestsStatusTable />
    </Grid>
  )
}

export default RequestsStatusScreen;