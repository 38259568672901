import { Button, Grid, TextField, Typography } from '@material-ui/core';
import { IconFileImport, IconPlus } from '@tabler/icons';
import { useFormik } from 'formik';

import { useHistory } from 'react-router-dom';
import { useAppSelector } from '../../../../../hooks/useRedux';
import MainCard from '../../../../shared/components/cards/MainCard';
import { ContentMaterials } from '../../interfaces/contentMaterials.interfaces';
import {
  useAddFolderMaterialsMutation,
  useGetContentMaterialsQuery,
} from '../../slices/virtualClassroomTeacherApiSlice';
import ItemAcorrdeon from '../Components/ItemAccordeon';
import { DescripcionSchema } from '../constants/validationSchema';

import { useTranslation } from 'react-i18next';
import LoadingData from '../../../../shared/components/cards/Skeleton/LoadingData';
import SuccessAlert from '../../../../shared/components/alerts/SuccessAlert';
import ErrorAlert from '../../../../shared/components/alerts/ErrorAlert';
import { useState } from 'react';
import DeleteMaterialsDialog from '../DeleteMaterialsDialog';
import DeleteFoldersDialog from '../DeleteFolderDialog';

function ScreenMateriales() {
  console.log(window.location.href);
  const [NameFolderValue, setNameFolder] = useState('');
  const [ShowAlert, setShowAlert] = useState(false);

  const {
    values: { NameFolder },
    handleChange,
    errors,
    touched,

    submitForm,
    resetForm,
  } = useFormik({
    initialValues: {
      NameFolder: NameFolderValue,
    },
    validationSchema: DescripcionSchema,
    onSubmit: () => {
      SubmitFormFolder();
      resetForm();

      setNameFolder('');
      setShowAlert(true);
    },
  });

  const infoperfil = useAppSelector((state) => state.virtualClassroomTeacherShared);
  const { deleteModalOpen, materialIdToDelete, folderIdToDelete } = useAppSelector(
    (state) => state.virtualClassroomTeacher
  );
  const { currentProfile } = useAppSelector((state) => state.access);

  const [CreateFolder, { isSuccess, isError, error }] = useAddFolderMaterialsMutation();
  const History = useHistory();

  async function SubmitFormFolder() {
    await CreateFolder({
      name_content: NameFolder,
      CursoId: infoperfil.course.courseId,
      user_profile_id: currentProfile?.profileId,
    });
  }

  //  const handleOpen = () => setOpen(true);
  const handleTaskCreate = () => {
    History.push(`/administrar-aula-virtual/materiales/importar`);
  };

  const { t } = useTranslation();

  const { data, isLoading } = useGetContentMaterialsQuery(
    {
      courseId: infoperfil.course.courseId,
    },

    { refetchOnMountOrArgChange: 30 }
  );

  return (
    <MainCard
      sx={{
        borderTopLeftRadius: '0px',
        borderTopRightRadius: '0px',
      }}
    >
      {isLoading && <LoadingData />}
      {isSuccess && ShowAlert && (
        <SuccessAlert handleDismiss={() => setShowAlert(false)} message="Creada correctamente" />
      )}
      {isError && ShowAlert && (
        <ErrorAlert handleDismiss={() => setShowAlert(false)} message={error as string} />
      )}
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">{t('teacherVirtualClassroom.materials.title')}</Typography>
      </Grid>
      <Grid marginTop={'50px'} justifyContent={'space-around'} container>
        <Grid item>
          <Grid item container alignItems={'center'} justifyContent="space-around">
            <Typography marginRight={'15px'} color={'primary'}>
              {t('teacherVirtualClassroom.materials.create.msg.directories')}
            </Typography>

            <TextField
              helperText={errors.NameFolder}
              name={'NameFolder'}
              onChange={handleChange}
              value={NameFolder}
              error={Boolean(errors.NameFolder && touched.NameFolder)}
              type="text"
              sx={{ width: 300, mr: '15px' }}
            />
            <Button
              onClick={submitForm}
              sx={{ borderRadius: '50px' }}
              variant="contained"
              color="primary"
              startIcon={<IconPlus />}
            >
              {t('teacherVirtualClassroom.materials.create.save')}
            </Button>
          </Grid>
        </Grid>
        <Grid alignItems="center" display="flex">
          <Button
            onClick={handleTaskCreate}
            sx={{ mr: '15px' }}
            variant="outlined"
            endIcon={<IconFileImport />}
          >
            {t('teacherVirtualClassroom.materials.create.import')}
          </Button>
          {/* <Button variant="outlined">{t('teacherVirtualClassroom.materials.create.delete')}</Button>
           */}
        </Grid>
      </Grid>
      <Grid sm={9} margin="30px auto">
        {data && data?.length > 0
          ? data?.map((itemMap: ContentMaterials) => {
              return (
                <ItemAcorrdeon
                  nameFolder={itemMap.act_description}
                  childrenfiles={itemMap.tools_materials}
                  subCarpetas={itemMap.children_content}
                  id={itemMap.id}
                />
              );
            })
          : 'No hay Materiales'}
      </Grid>
      {deleteModalOpen && materialIdToDelete && <DeleteMaterialsDialog />}
      {deleteModalOpen && folderIdToDelete && <DeleteFoldersDialog />}
    </MainCard>
  );
}

export default ScreenMateriales;
