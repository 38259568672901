import DeleteAlert from '../../../shared/components/dialogs/DeleteAlert';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { useDeleteEconomicGroupMutation } from '../slices/economicGroupsApiSlice';
import { resetEconomicGroupIdToDelete, setDeleteModalOpen } from '../slices/economicGroupSlice';

const DeleteEconomicGroupsDialog = () => {
  const dispatch = useAppDispatch();

  const { deleteModalOpen, economicGroupIdToDelete } = useAppSelector(
    (state) => state.economicGroups
  );
  const { currentProfile } = useAppSelector((state) => state.access);

  const [deleteEconomicGroup, { isLoading, isSuccess, isError, error }] =
    useDeleteEconomicGroupMutation();

  const handleDelete = () => {
    deleteEconomicGroup({
      profileId: currentProfile?.profileId!,
      economicGroupId: economicGroupIdToDelete!,
    });
  };

  const handleClose = () => {
    dispatch(setDeleteModalOpen(false));
    dispatch(resetEconomicGroupIdToDelete());
  };
  return (
    <DeleteAlert
      open={deleteModalOpen}
      onClose={handleClose}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      handleDelete={handleDelete}
      error={error as string}
    />
  );
};

export default DeleteEconomicGroupsDialog;
