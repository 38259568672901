import { useHistory } from 'react-router-dom';
import { Grid, IconButton, Typography, useTheme } from '@material-ui/core';
import { IconArrowNarrowLeft } from '@tabler/icons';

import MainCard from '../../../shared/components/cards/MainCard';
import AddRoleForm from '../components/AddRoleForm';
import { useTranslation } from 'react-i18next';

const AddRoleScreen = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const history = useHistory();

  return (
    <Grid container component={MainCard} sx={{ padding: { xs: 0, md: 2 } }}>
      <Grid item xs={12}>
        <Grid item container alignItems="center" gap={1} sx={{ cursor: 'pointer' }}>
          <IconButton onClick={() => history.goBack()}>
            <IconArrowNarrowLeft size={30} color={theme.palette.text.dark} />
          </IconButton>
          <Typography variant="h2">{t('configuration.roles.add.title')}</Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} md={8} lg={6} sx={{ mt: 2 }}>
        <AddRoleForm />
      </Grid>
    </Grid>
  );
};

export default AddRoleScreen;
