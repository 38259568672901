import { Grid, Box, Typography, useTheme } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import MainCard from '../components/cards/MainCard';
import CustomIcon from '../components/illustrations/CustomIcon';
import imagenSeleccionMenu from '../../../assets/images/imagen-selecciona-una-opcion.png';

import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../hooks/useRedux';
import ShortsCups from '../components/ShortsCups';
//import { academic } from '../menu-items/superadmin/academic';

const DashboardScreen = () => {
  const { t } = useTranslation();

  const theme = useTheme();
  const { currentRol } = useAppSelector((state) => state.access);

  const [route, setRoute] = useState('dashboard');

  useEffect(() => {
    if (currentRol?.keyword === 'estudiante') setRoute('inicio-estudiante');
    if (currentRol?.keyword === 'docente') setRoute('inicio-docente');
  }, [currentRol?.keyword]);

  return (
    <>
      <Grid component={MainCard} sx={{ padding: 2 }}>
        <Box>
          <Grid
            container
            justifyContent={'center'}
            alignContent={'center'}
            sx={{ minHeight: '50vh' }}
          >
            <Grid container justifyContent={'center'} item xs={8} md={6}>
              <Grid container item xs={12} justifyContent={'center'}>
                <CustomIcon icon={imagenSeleccionMenu} customWidth={350} />
              </Grid>
              <Grid container item xs={12} justifyContent={'center'}>
                <Typography variant={'body1'} textAlign={'center'} lineHeight={2} component={'div'}>
                  <Typography
                    variant={'h4'}
                    sx={{ color: theme.palette.primary.main }}
                    component={'p'}
                    align={'center'}
                    lineHeight={2}
                  >
                    {t('shared.dashboard.title')}
                  </Typography>
                  {t('shared.dashboard.description')}
                </Typography>
              </Grid>
              <ShortsCups />
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Redirect to={route} />
    </>
  );
};

export default DashboardScreen;
