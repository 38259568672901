import { Switch } from 'react-router-dom';
import { useAppSelector } from '../../../../hooks/useRedux';
import GuardRoute from '../../../../router/guards/GuardRoute';
import ValidateGuardRoute from '../../../../router/guards/ValidateGuardRoute';
import RegisterSilabus from '../../../shared/screens/RegistwerSilabus';
import SyllabusScreen from '../screen/SyllabusScreen';
import { subPermissionKeys } from '../constants/permissions';

function SyllabusRouter() {
  const { syllabusIdSelected } = useAppSelector((state) => state.syllabus);

  return (
    <Switch>
      <GuardRoute
        exact
        path={`/syllabus`}
        component={SyllabusScreen}
        permissionKey={subPermissionKeys.groupKey}
        subPermissionKey={subPermissionKeys.readSyllabus}
      />
      <ValidateGuardRoute
        path="/silabus/:courseId/:id/:period/:caseKey"
        component={RegisterSilabus}
        isView={Boolean(syllabusIdSelected)}
        permissionKey={subPermissionKeys.groupKey}
        subPermissionKey={[subPermissionKeys.updateSyllabus]}
      />
    </Switch>
  );
}

export default SyllabusRouter;
