import { Grid, IconButton, Typography, useTheme } from '@material-ui/core';
import { IconArrowNarrowLeft } from '@tabler/icons';
import { useHistory, useParams } from 'react-router-dom';
import { useAppSelector } from '../../../../hooks/useRedux';
import MainCardForm from '../../../shared/components/cards/MainCardForm';
import AddCollaboratorWizard from '../components/AddCollaboratorWizard';
// import EditCollaboratorForm from '../components/EditCollaboratorWizard/EditCollaboratorForm';

const EditCollaboratorDataScreen = () => {
  const theme = useTheme();
  const history = useHistory();

  const { collaboratorId } = useParams<{ collaboratorId: string }>();

  const { collabList } = useAppSelector((state) => state.collaborators);

  const collaborator = collabList.find((collab) => collab.id === Number(collaboratorId));

  return (
    <MainCardForm>
      <Grid item xs={12}>
        <Grid item container alignItems="center" gap={1} sx={{ cursor: 'pointer' }}>
          <IconButton onClick={() => history.goBack()}>
            <IconArrowNarrowLeft size={30} color={theme.palette.text.dark} />
          </IconButton>
          <Typography variant="h2">
            Editar {collaborator && ` - ${collaborator.user?.person?.pers_full_name}`}
          </Typography>
        </Grid>
      </Grid>
      {/* <Grid item xs={12}>
        <EditCollaboratorForm />
      </Grid> */}

      <Grid item xs={12} container>
        <AddCollaboratorWizard
          collaboratorIdentification={collaborator?.user?.person?.pers_identification}
          collaboratorIdentificationType={collaborator?.user?.person?.type_identification}
          collaboratorType={collaborator?.coll_type}
        />
      </Grid>
    </MainCardForm>
  );
};

export default EditCollaboratorDataScreen;
