import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../../hooks/useRedux';
import { useGetRequestInformationByAppCodeQuery } from '../slices/academicRequestsApiSlice';
//import { renderSkeletonRows } from '../../../shared/helpers/render';
import { Box, Divider, Grid, Stack, Typography } from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@material-ui/lab';
import { renderTimeLineSkeleton } from '../../../shared/helpers/render';
import { useTranslation } from 'react-i18next';
import useFormatDate from '../../../shared/hooks/useFormatDate';
import Chip from '../../../shared/components/extended/Chip';
// const hmFormat = (date: string) => {
//   const dateFormat = new Date(date);
//   let hours = dateFormat.getHours();
//   let minutes = dateFormat.getMinutes();
//   let ampm = hours >= 12 ? 'pm' : 'am';
//   hours = hours % 12;
//   hours = hours ? hours : 12; // the hour '0' should be '12'
//   minutes = minutes < 10 ? 0 + minutes : minutes;
//   let strTime =
//     dateFormat.getDate() +
//     '-' +
//     (dateFormat.getMonth() + 1) +
//     '-' +
//     dateFormat.getFullYear() +
//     ' ' +
//     hours +
//     ':' +
//     minutes +
//     ' ' +
//     ampm;
//   return strTime;
// };
const InfoStatusRequest = () => {
  const { t } = useTranslation();
  const { currentProfile } = useAppSelector((state) => state.access);
  const { applicationCode } = useParams<{ applicationCode: string }>();

  const { formatDate } = useFormatDate();

  const { isLoading, /* error,*/ data } = useGetRequestInformationByAppCodeQuery(
    {
      profileId: currentProfile?.profileId!,
      appCode: applicationCode!,
    },
    { refetchOnMountOrArgChange: 30 }
  );

  return (
    <Grid container>
      {isLoading ? (
        <Grid item container xs={12} md={12} lg={12} justifyContent="center" sx={{ mt: 2 }}>
          <Grid item>{renderTimeLineSkeleton(5)}</Grid>
          {/* {renderSkeletonRows(5, 1)} */}
        </Grid>
      ) : (
        <>
          <Grid item xs={12} md={12} lg={12} sx={{ mt: 2 }}>
            <Box
              sx={{
                border: '1px solid',
                borderColor: 'secondary',
                borderRadius: '8px',
                padding: 2,
              }}
            >
              <Stack direction="row" spacing={2}>
                <Grid item>
                  <Typography>
                    <b>
                      {t('students.applications.academic_applications.applications_table.code')}:
                    </b>{' '}
                    {data?.data[0].secuencial}
                  </Typography>
                </Grid>
                <Divider orientation="vertical" flexItem />
                <Grid item>
                  <Typography>
                    <b>
                      {t('students.applications.academic_applications.applications_table.type')}:
                    </b>{' '}
                    {data?.data[0].type_application}
                  </Typography>
                </Grid>
                <Divider orientation="vertical" flexItem />
                <Grid item>
                  <Typography>
                    <b>
                      {t(
                        'students.applications.academic_applications.applications_table.register_date'
                      )}
                      :
                    </b>{' '}
                    {data?.data?.[0]?.register_date
                      ? formatDate(data?.data?.[0]?.register_date, {
                          withTime: true,
                        })
                      : ''}
                  </Typography>
                </Grid>
              </Stack>
            </Box>
          </Grid>

          <Grid item xs={12} md={12} lg={12} sx={{ mt: 2 }}>
            <Timeline position="alternate" sx={{ height: '45vh' }}>
              <PerfectScrollbar component="div">
                {!isLoading &&
                  data?.data.map((d) => (
                    <TimelineItem key={d.id}>
                      <TimelineOppositeContent color="text.secondary">
                        {d.register_date
                          ? formatDate(d.register_date, {
                              withTime: true,
                            })
                          : ''}
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineDot />
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>
                        <Chip
                          label={d.current_status}
                          size="small"
                          chipcolor={
                            d.current_status === 'Enviado'
                              ? 'info'
                              : d.current_status === 'Rechazado'
                              ? 'error'
                              : 'success'
                          }
                        />
                        <Typography>{d.comments}</Typography>
                        <Typography color="text.secondary">{d.user || ''}</Typography>
                      </TimelineContent>
                    </TimelineItem>
                  ))}
              </PerfectScrollbar>
            </Timeline>
          </Grid>
        </>
      )}
    </Grid>
  );
};
export default InfoStatusRequest;
