import DeleteAlert from '../../../../shared/components/dialogs/DeleteAlert';
// import { useTranslation } from 'react-i18next';
import { useDeleteScheduleDetailMutation } from '../../slices/busRouteApiSlice';
// import { resetRoleIdToDelete, setDeleteModalOpen } from '../slices/rolesSlice';

interface Props {
  openModal: boolean;
  scheduleDetailId: number;
  scheduleDetailIdx: number;
  handleCloseModal: () => void;
  removeHoursFromList: (index: number) => void;
}

const DeleteScheduleDetailDialog = ({
  openModal,
  scheduleDetailId,
  scheduleDetailIdx,
  handleCloseModal,
  removeHoursFromList,
}: Props) => {
  // const { t } = useTranslation();
  const [deleteScheduleDetail, { isLoading, isSuccess, isError, error, reset }] =
    useDeleteScheduleDetailMutation();

  const handleDelete = async () => {
    try {
      await deleteScheduleDetail({ scheduleDetailId }).unwrap();

      removeHoursFromList(scheduleDetailIdx);
    } catch (error) {
      console.log('error al eliminar');
    }
  };

  const handleClose = () => {
    handleCloseModal();
    setTimeout(() => {
      reset();
    }, 200);
  };

  return (
    <DeleteAlert
      open={openModal}
      onClose={handleClose}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      handleDelete={handleDelete}
      // deleteTitle={t('pediente')}
      error={error as string}
    />
  );
};

export default DeleteScheduleDetailDialog;
