import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { PropsFormEdit } from '.';

const useStyles = makeStyles((theme) => ({
  cardDisability: {
    background: `linear-gradient(90deg, ${theme.palette.secondary.light} 0%, transparent 7%)`,
    width: '100%',
  },
}));

const StudentRecordCard = ({ data: studentData }: PropsFormEdit) => {
  const { info_student_record = [] } = studentData!;

  const classes = useStyles();

  return (
    <Grid item xs={12}>
      {!!info_student_record.length ? (
        <Grid item container spacing={2} xs={12}>
          {info_student_record?.map(
            ({
              career_name,
              curriculum_name,
              curriculum_year,
              faculty_name,
              date_register,
              eco_gro_name,
              cohort,
              offer_name,
              eco_gro_description,
              offer_keyword,
              paralell,
              price,
              username_register,
            }) => (
              <Grid item container xs={12} md={6}>
                <Paper elevation={3} className={classes.cardDisability}>
                  <Grid item xs={12} p={2} pl={4}>
                    <Typography variant="h4" color="primary" gutterBottom>
                      {career_name}{' '}
                      {offer_name && offer_name !== '' && (
                        <Typography component="span" variant="h4" color="primary" gutterBottom>
                          ({offer_name || ' - '})
                        </Typography>
                      )}
                    </Typography>
                    <Typography variant="h5" color="primary" gutterBottom>
                      {faculty_name || ' - '}
                    </Typography>
                    {offer_keyword === 'oferta_postgrado' && (
                      <Typography variant="h5" gutterBottom>
                        Cohorte: {cohort || ' - '}
                      </Typography>
                    )}
                    <Typography variant="body1" gutterBottom>
                      Malla: {curriculum_name || ' - '}{' '}
                      {curriculum_year && curriculum_year !== '' && (
                        <Typography variant="body1" component="span" gutterBottom>
                          ({curriculum_year || ' - '})
                        </Typography>
                      )}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      Paralelo: {paralell || ' - '}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      Fecha de registro: {date_register || ' - '}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      Grupo económico:{' '}
                      {`${eco_gro_name || ''} - ${eco_gro_description || ''}` || ' - '}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      Precio: ${price || ' - '}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      Registrado por: {username_register || ' - '}
                    </Typography>
                  </Grid>
                </Paper>
              </Grid>
            )
          )}
        </Grid>
      ) : (
        <Grid item container xs={12} justifyContent="center" alignItems="center" minHeight="30vh">
          No existe record academico
        </Grid>
      )}
    </Grid>
  );
};

export default StudentRecordCard;
