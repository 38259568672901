import {
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
} from '@material-ui/core';
import { DatePicker, LocalizationProvider } from '@material-ui/lab';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useAppSelector } from '../../../../hooks/useRedux';
import { useGetLocaleDateLang } from '../../../../translation/hooks/useGetLocaleDateLang';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import { createCohortSchema } from '../constants/validations';
import {
  useAddPosgraduatePlanningCohortMutation,
  useUpdatePosgraduatePlanningCohortMutation,
} from '../slices/posgraduatePlanningCohortApiSlice';

const PosgraduatePlanningCohortsForm = () => {
  const { currentProfile } = useAppSelector((state) => state.access);

  const { posgraduatePlanningCohortsList, posgraduatePlanningCohortIdSelected } = useAppSelector(
    (state) => state.posgraduatePlanningCohorts
  );

  const posgraduateCohort = posgraduatePlanningCohortsList.find(
    (item) => item.id === posgraduatePlanningCohortIdSelected
  );

  const [showAlert, setShowAlert] = useState(false);

  const [
    addCohort,
    {
      isLoading: isLoadingAddCohort,
      isSuccess: isSuccessAdd,
      isError: isErrorAdd,
      error: errorAdd,
    },
  ] = useAddPosgraduatePlanningCohortMutation();
  const [
    updateCohort,
    {
      isLoading: isLoadingUpdateCohort,
      isSuccess: isSuccessUpdate,
      isError: isErrorUpdate,
      error: errorUpdate,
    },
  ] = useUpdatePosgraduatePlanningCohortMutation();

  const { errors, touched, handleSubmit, values, setFieldValue, getFieldProps } = useFormik({
    initialValues: {
      year: posgraduateCohort?.year ? new Date(Number(posgraduateCohort?.year), 1, 1) : new Date(),
      cantParallelCohort: posgraduateCohort?.cant_parallel_cohort || 0,
      cohortNumber: posgraduateCohort?.cohort_number || 0,
    },
    validationSchema: createCohortSchema,
    onSubmit: async (values) => {
      if (posgraduateCohort?.id) {
        await updateCohort({
          profileId: currentProfile?.profileId!,
          id: posgraduateCohort?.id,
          year: values.year.getFullYear(),
          cohortNumber: Number(values.cohortNumber),
          cantParallelCohort: Number(values.cantParallelCohort),
        });
      } else {
        await addCohort({
          profileId: currentProfile?.profileId!,
          year: values.year.getFullYear(),
          cohortNumber: Number(values.cohortNumber),
          cantParallelCohort: Number(values.cantParallelCohort),
        });
      }

      setShowAlert(true);
    },
  });

  const { lang } = useGetLocaleDateLang();

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        {showAlert && (isSuccessAdd || isSuccessUpdate) && (
          <Grid item xs={12}>
            <SuccessAlert
              handleDismiss={() => setShowAlert(false)}
              message={
                isSuccessAdd
                  ? 'Cohorte agregado correctamente'
                  : 'Cohorte actualizado correctamente'
              }
            />
          </Grid>
        )}
        {showAlert && (isErrorAdd || isErrorUpdate) && (
          <Grid item xs={12}>
            <ErrorAlert
              handleDismiss={() => setShowAlert(false)}
              message={isErrorAdd ? (errorAdd as string) : (errorUpdate as string)}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <InputLabel>Año</InputLabel>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={lang}>
            <DatePicker
              views={['year']}
              value={values.year}
              onChange={(newValue) => {
                setFieldValue('year', newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(touched.year && errors.year)}
                  fullWidth
                  helperText=""
                />
              )}
            />
          </LocalizationProvider>
          {errors.year && touched.year && <FormHelperText error>{errors.year}</FormHelperText>}
        </Grid>

        <Grid item xs={12}>
          <InputLabel>Número de cohorte</InputLabel>
          <TextField
            fullWidth
            type="number"
            {...getFieldProps('cohortNumber')}
            onChange={(e) => {
              if (Number(e.target.value) > 0) {
                setFieldValue('cohortNumber', e.target.value);
              }
            }}
            error={Boolean(touched.cohortNumber && errors.cohortNumber)}
          />
          {errors.cohortNumber && touched.cohortNumber && (
            <FormHelperText error>{errors.cohortNumber}</FormHelperText>
          )}
        </Grid>

        <Grid item xs={12}>
          <InputLabel>Cantidad de paralelos</InputLabel>
          <TextField
            fullWidth
            type="number"
            {...getFieldProps('cantParallelCohort')}
            error={Boolean(touched.cantParallelCohort && errors.cantParallelCohort)}
            onChange={(e) => {
              if (Number(e.target.value) > 0) {
                setFieldValue('cantParallelCohort', e.target.value);
              }
            }}
          />
          {errors.cantParallelCohort && touched.cantParallelCohort && (
            <FormHelperText error>{errors.cantParallelCohort}</FormHelperText>
          )}
        </Grid>

        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={isLoadingAddCohort || isLoadingUpdateCohort}
            endIcon={
              isLoadingAddCohort || isLoadingUpdateCohort ? <CircularProgress size={14} /> : null
            }
          >
            Guardar
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default PosgraduatePlanningCohortsForm;
