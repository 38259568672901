import DeleteAlert from '../../../shared/components/dialogs/DeleteAlert';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { useDeleteAcademicOfferingMutation } from '../slices/academicOfferingsApiSlice';
import {
  resetAcademicOfferingsIdToDelete,
  setDeleteModalOpen,
} from '../slices/academicOfferingsSlice';

const DeleteAcademicOfferingDialog = () => {
  const dispatch = useAppDispatch();

  const { deleteModalOpen, academicOfferingIdToDelete } = useAppSelector(
    (state) => state.academicOfferings
  );
  const { currentProfile } = useAppSelector((state) => state.access);

  const [deleteAcademicOffering, { isLoading, isSuccess, isError, error }] =
    useDeleteAcademicOfferingMutation();

  const handleDelete = () => {
    deleteAcademicOffering({
      profileId: currentProfile?.profileId!,
      academicOfferingId: academicOfferingIdToDelete!,
    });
  };

  const handleClose = () => {
    dispatch(setDeleteModalOpen(false));
    dispatch(resetAcademicOfferingsIdToDelete());
  };

  return (
    <DeleteAlert
      open={deleteModalOpen}
      onClose={handleClose}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      handleDelete={handleDelete}
      error={error as string}
    />
  );
};

export default DeleteAcademicOfferingDialog;
