export const subPermissionKeys = {
  groupKey: 'busTour',

  getRoutesCampus: 'bus-routes-listar-rutas-buses-campus',
  getRoutes: 'bus-routes-listar-rutas-buses',
  showRoute: 'permisoPendiente',

  createRoute: 'bus-routes-guardar-ruta-bus',
  updateRoute: 'bus-routes-actualizar-ruta-bus',
  destroyRoute: 'bus-routes-eliminar-ruta-bus',

  getScheduleList: 'bus-schedules-listar-horarios-buses',
  getSchedule: 'bus-schedules-obtener-horario-bus',
  deleteSchedule: 'bus-schedules-borrar-horario-bus',

  updateSchedulesDetailsGroudped: 'bus-schedule-details-guardar-paradas-grupo',

  getRouteTours: 'bus-routes-listar-rutas-buses-tours',
  createToursListGrouped: 'bus-tours-crear-recorridos-buses',
};
