
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/useRedux';
import { useStudentVCStartEvaluationMutation } from '../../../slices/virtualClassroomApiSlice';
import { setEvaluationConfirmStartDialog } from '../../../slices/virtualClassroomSlice';
import DialogConfirm from './Dialog';

const EvaluationConfirmDialog = () => {

    const history = useHistory();

    const dispatch = useAppDispatch();

    const [startEvaluation, { isLoading, error, isError, isSuccess }] = useStudentVCStartEvaluationMutation();

    const { currentProfile, currentStudent } = useAppSelector((state) => state.access);

    const { evaluation: { confirmStartDialog, evaluationId }} = useAppSelector((state) => state.virtualClassroomStudent);

    const { course : {courseId} } = useAppSelector((state) => state.virtualClassroomStudentShared);
    
    const handleClose = () => {
        dispatch(setEvaluationConfirmStartDialog(false));
    }

    const handleAction = async () => {
        const err = await startEvaluation({
            courseId: courseId!,
            evaluationId: evaluationId!,
            profileId: currentProfile?.profileId!,
            studentId: currentStudent?.id!,
        });

        if ('error' in err === false) {
            handleClose();
            history.push(`/aula-virtual-estudiante/test/${evaluationId}/realizar`);
        }
    }

    return (
        <DialogConfirm
            open={confirmStartDialog}
            onClose={handleClose}
            isLoading={isLoading}
            isSuccess={isSuccess}
            isError={isError}
            handleAction={handleAction}
            error={error as string}
        />
    )
}

export default EvaluationConfirmDialog;