import { Grid, TextField, Skeleton } from '@material-ui/core';
import { renderSkeletonTable } from '../../../helpers/render';
import InputLabel from '../../extended/Form/InputLabel';

const ComponentFormTable = () => {
    return (
        <form>
            <Grid container gap={2}>
                <Grid item container spacing={2} alignItems="center">
                    <Grid item xs={6}>
                        <Skeleton variant="text">
                            <InputLabel>Lorem ipsum dolor sit amet consectetur.</InputLabel>
                        </Skeleton>
                        <Skeleton variant="rectangular" width={'100%'}>
                            <TextField fullWidth />
                        </Skeleton>
                    </Grid>

                    <Grid item xs={6}>
                        <Skeleton variant="text">
                            <InputLabel>Lorem ipsum dolor sit amet consectetur.</InputLabel>
                        </Skeleton>
                        <Skeleton variant="rectangular" width={'100%'}>
                            <TextField fullWidth />
                        </Skeleton>
                    </Grid>

                    <Grid item xs={6}>
                        <Skeleton variant="text">
                            <InputLabel>Lorem ipsum dolor sit amet consectetur.</InputLabel>
                        </Skeleton>
                        <Skeleton variant="rectangular" width={'100%'}>
                            <TextField fullWidth />
                        </Skeleton>
                    </Grid>

                    <Grid item xs={6}>
                        <Skeleton variant="text">
                            <InputLabel>Lorem ipsum dolor sit amet consectetur.</InputLabel>
                        </Skeleton>
                        <Skeleton variant="rectangular" width={'100%'}>
                            <TextField fullWidth />
                        </Skeleton>
                    </Grid>

                    <Grid item xs={6}>
                        <Skeleton variant="text">
                            <InputLabel>Lorem ipsum dolor sit amet consectetur.</InputLabel>
                        </Skeleton>
                        <Skeleton variant="rectangular" width={'100%'}>
                            <TextField fullWidth />
                        </Skeleton>
                    </Grid>

                    <Grid item xs={12}></Grid>

                    <Grid item xs={12}>
                        <Skeleton variant="text">
                            <InputLabel>Lorem ipsum dolor sit amet consectetur.</InputLabel>
                        </Skeleton>
                        <Skeleton variant="rectangular" width={'100%'}>
                            <TextField fullWidth />
                        </Skeleton>
                    </Grid>
                </Grid>

                <Grid
                    item
                    container
                    alignItems="center"
                    justifyContent="flex-end"
                    spacing={2}
                    sx={{ mt: 1 }}
                >
                    {renderSkeletonTable(5, 1)}
                </Grid>
            </Grid>
        </form>
    );
};

export default ComponentFormTable;
