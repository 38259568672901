import { Switch, Redirect } from 'react-router-dom';

import { useAppSelector } from '../../../../hooks/useRedux';

import HomologationsScreen from '../screens/HomologationsScreen';
import PreviewIntHomologationScreen from '../screens/internal-homologation/PreviewIntHomologationScreen';
import IntHomologationFormScreen from '../screens/internal-homologation/IntHomologationFormScreen';
import EditExternalHomologationScreen from '../screens/external-homologation/EditExternalHomologationScreen';
import AddExternalHomologationScreen from '../screens/external-homologation/AddExternalHomologationScreen';

import GuardRoute from '../../../../router/guards/GuardRoute';

import {
  subPermissionKeys,
  subPermissionKeys2,
  subPermissionKeys3,
} from '../constants/permissions';

const PeriodsRouter = () => {
  const { currentModule } = useAppSelector((state) => state.access);

  const hasPermission = subPermissionKeys.groupKey in currentModule?.permissions!;

  if (!hasPermission) return <Redirect to="/dashboard" />;

  return (
    <Switch>
      {/* <GuardRoute
        exact
        path="/administrar-periodos/agregar"
        component={AddPeriodScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.create}
      /> */}

      {/* homologacion externa */}
      <GuardRoute
        exact
        path="/administrar-homologaciones/:hId/editar-he"
        component={EditExternalHomologationScreen}
        permissionKey={subPermissionKeys3.groupKey}
        subPermissionKey={subPermissionKeys3.edit}
      />
      <GuardRoute
        exact
        path="/administrar-homologaciones/homologacion-e/agregar"
        component={AddExternalHomologationScreen}
        permissionKey={subPermissionKeys3.groupKey}
        subPermissionKey={subPermissionKeys3.create}
      />

      {/* homologacion interna */}
      <GuardRoute
        exact
        path="/administrar-homologaciones/:hId/editar-hi"
        component={PreviewIntHomologationScreen}
        permissionKey={subPermissionKeys.groupKey}
        subPermissionKey={subPermissionKeys.read}
        // subPermissionKey={subPermissionKeys.editIntApp}
      />
      <GuardRoute
        exact
        path="/administrar-homologaciones/homologacion-i/:hId"
        component={IntHomologationFormScreen}
        permissionKey={subPermissionKeys2.groupKey}
        subPermissionKey={subPermissionKeys2.readMatters}
      />

      {/* vista de tablas */}
      <GuardRoute
        exact
        path="/administrar-homologaciones"
        component={HomologationsScreen}
        permissionKey={subPermissionKeys2.groupKey}
        subPermissionKey={subPermissionKeys2.read}
      />
    </Switch>
  );
};

export default PeriodsRouter;
