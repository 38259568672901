import {
  Autocomplete,
  Button,
  Checkbox,
  // Checkbox,
  CircularProgress,
  FormControlLabel,
  // FormControlLabel,
  FormHelperText,
  Grid,
  TextField,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppSelector } from '../../../../hooks/useRedux';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import InputLabel from '../../../shared/components/forms/InputLabel';
// import { statusAvailables } from '../../../shared/constants/resourceStatus';
import { RoleSchema } from '../constants/validationSchemas';
import { useAddRoleMutation } from '../slices/rolesApiSlice';
import { useTranslation } from 'react-i18next';
import { useGetAvailablesProfilesQuery } from '../../profiles/slices/profilesApiSlice';
import TextFieldFetching from '../../../shared/components/TextFieldFetching';

const AddRoleForm = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { currentProfile } = useAppSelector((state) => state.access);

  const [showAlerts, setShowAlerts] = useState(true);

  const [addRole, { isLoading, isError, error, isSuccess }] = useAddRoleMutation();

  const { getFieldProps, errors, touched, handleSubmit, setFieldValue, values } = useFormik({
    initialValues: {
      name: '',
      keyword: '',
      description: '',
      statusId: 1,
      accessUnique: false,
      showFilteredRequests: false,
      profile: 'administrativo',
    },
    validationSchema: RoleSchema,
    onSubmit: async (values) => {
      await addRole({
        profileId: currentProfile?.profileId!,
        rolePayload: {
          name: values.name,
          keyword: values.keyword,
          description: values.description,
          status_id: values.statusId.toString() || '1',
          access_unique_client: values.accessUnique,
          profile_keyword: values.profile,
          show_app_education_level: values.showFilteredRequests,
        },
      });

      setShowAlerts(true);
    },
  });

  const { data: profiles, isLoading: isLoadingProfiles } = useGetAvailablesProfilesQuery({
    profileId: currentProfile?.profileId!,
  });

  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction="column" gap={2}>
        <Grid item>
          {isError && showAlerts && (
            <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
          )}

          {isSuccess && showAlerts && (
            <SuccessAlert
              message={t('configuration.roles.add.success')}
              handleDismiss={() => setShowAlerts(false)}
            />
          )}
        </Grid>

        <Grid item container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <InputLabel>{t('configuration.roles.form.name')}</InputLabel>
            <TextField
              fullWidth
              error={Boolean(errors.name && touched.name)}
              placeholder={t('configuration.roles.form.name.placeholder')}
              {...getFieldProps('name')}
            />
            {errors.name && touched.name && <FormHelperText error>{t(errors.name)}</FormHelperText>}
          </Grid>

          <Grid item xs={12}>
            <InputLabel>{t('configuration.roles.form.keyword')}</InputLabel>
            <TextField
              fullWidth
              error={Boolean(errors.keyword && touched.keyword)}
              placeholder={t('configuration.roles.form.keyword.placeholder')}
              {...getFieldProps('keyword')}
            />
            {errors.keyword && touched.keyword && (
              <FormHelperText error>{t(errors.keyword)}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12}>
            <InputLabel>{t('configuration.roles.form.description')}</InputLabel>
            <TextField
              fullWidth
              error={Boolean(errors.description && touched.description)}
              placeholder={t('configuration.roles.form.description.placeholder')}
              {...getFieldProps('description')}
            />
            {errors.description && touched.description && (
              <FormHelperText error>{t(errors.description)}</FormHelperText>
            )}
          </Grid>

          {/* <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.accessUnique}
                  onChange={(e) => setFieldValue('accessUnique', e.target.checked)}
                />
              }
              labelPlacement="start"
              label={t('configuration.roles.form.access_unique')}
            />
          </Grid> */}

          {/* <Grid item xs={12}>
            <InputLabel>{t('configuration.roles.form.status')}</InputLabel>
            <Autocomplete
              options={statusAvailables}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField {...params} error={Boolean(errors.statusId && touched.statusId)} />
              )}
              defaultValue={statusAvailables[0]}
              onChange={(e, value) => setFieldValue('statusId', value?.id || '')}
              fullWidth
              clearIcon={null}
            />
            {errors.statusId && touched.statusId && (
              <FormHelperText error>{t(errors.statusId)}</FormHelperText>
            )}
          </Grid>
        </Grid> */}

          <Grid item xs={12}>
            <InputLabel>{t('configuration.roles.form.profile')}</InputLabel>
            {isLoadingProfiles ? (
              <TextFieldFetching />
            ) : (
              <Autocomplete
                options={profiles || []}
                getOptionLabel={(option) => t(option.pro_name)}
                renderInput={(params) => (
                  <TextField {...params} error={Boolean(errors.profile && touched.profile)} />
                )}
                value={profiles?.find((profile) => profile.keyword === values.profile)}
                onChange={(e, value) => setFieldValue('profile', value?.keyword || '')}
                fullWidth
                clearIcon={null}
              />
            )}
            {errors.profile && touched.profile && (
              <FormHelperText error>{t(errors.profile)}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.showFilteredRequests}
                  onChange={(e) => setFieldValue('showFilteredRequests', e.target.checked)}
                />
              }
              labelPlacement="end"
              label={t('configuration.roles.form.filtered_requests')}
            />
          </Grid>
        </Grid>

        <Grid
          item
          container
          alignItems="center"
          justifyContent="flex-end"
          spacing={2}
          sx={{ mt: 1 }}
        >
          <Grid item>
            <Button
              variant="outlined"
              size="large"
              color="primary"
              onClick={() => history.goBack()}
            >
              {t('configuration.roles.add.back')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              disabled={isLoading}
              endIcon={isLoading && <CircularProgress size={20} />}
            >
              {t('configuration.roles.add.submit')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default AddRoleForm;
