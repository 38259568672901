import { FC, useEffect } from 'react';

import { Button, Divider, FormHelperText, Grid, Skeleton, Typography } from '@material-ui/core';
import Alert from '@material-ui/core/Alert';
import { IconEdit, IconX } from '@tabler/icons';

import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import InputLabel from '../../../shared/components/extended/Form/InputLabel';
import { useGetDebtorStudentQuery } from '../slices/debtsPaymentApiSlice';
import { setDefaultTempBillingData, resetTemporalBillingData } from '../slices/debtsPaymentSlice';
import useSharedGuard from '../../../../hooks/useSharedGuard';

interface Props {
  handleOpen: () => void;
}

const TempBillingData: FC<Props> = (props) => {
  const { handleOpen } = props;

  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const { hasSharedPermissions } = useSharedGuard();

  const { currentProfile } = useAppSelector((state) => state.access);
  const { tempBillingData, selectedDebts, defaultTempBillingData, debtorStudentId } =
    useAppSelector((state) => state.debtsPayment);

  const { data: defaultBillData, isFetching } = useGetDebtorStudentQuery(
    {
      profileId: currentProfile?.profileId!,
      debtorStudentId: !!selectedDebts.length
        ? +selectedDebts?.[0].debtor_student_id
        : debtorStudentId!,
    },
    {
      skip:
        (!selectedDebts.length ||
          (!!selectedDebts.length && !selectedDebts?.[0].debtor_student_id)) &&
        !debtorStudentId,
    }
  );

  const resetTempBillingDataHanlder = () => {
    dispatch(resetTemporalBillingData());
  };

  useEffect(() => {
    if (defaultBillData)
      dispatch(
        setDefaultTempBillingData({
          tipoIdentificacion: defaultBillData.person.type_identification_id,
          identificacion: defaultBillData.person.pers_identification,
          direccion: defaultBillData.person.pers_direction,
          email: defaultBillData.person.pers_email,
          razonSocial: `${defaultBillData.person.pers_firstname || ''} ${
            defaultBillData.person.pers_secondname || ''
          } ${defaultBillData.person.pers_first_lastname || ''} ${
            defaultBillData.person.pers_second_lastname || ''
          }`,
          telefono: defaultBillData.person.pers_cell,
        })
      );
  }, [defaultBillData, dispatch]);

  return (
    <Grid container p={2} spacing={1}>
      <Grid container justifyContent={'space-between'} alignContent={'baseline'}>
        <Grid item>
          <Typography variant="h3">{t('financial.debts_payment.temp_billing.title')}</Typography>
        </Grid>
        <Grid item sx={{ textAlign: 'right' }}>
          {hasSharedPermissions('getChildrenCatalogByAcro') && (
            <Button variant="outlined" size="small" onClick={handleOpen} startIcon={<IconEdit />}>
              {t('financial.debts_payment.temp_billing.edit_btn')}
            </Button>
          )}
        </Grid>
      </Grid>

      <Grid item>
        <Alert
          severity="info"
          variant="outlined"
          sx={{ borderStyle: 'solid', borderColor: `primary.main`, borderRadius: '12px' }}
        >
          <FormHelperText sx={{ textAlign: 'justify' }}>
            {t('financial.debts_payment.temp_billing.alert')}
          </FormHelperText>
        </Alert>
      </Grid>

      {tempBillingData && (
        <Grid item xs={12}>
          <Button
            variant="outlined"
            size="small"
            onClick={resetTempBillingDataHanlder}
            endIcon={<IconX size={15} />}
          >
            {t('financial.debts_payment.temp_billing.initial_data_btn')}
          </Button>
        </Grid>
      )}

      <Grid item xs={12} my={1}>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        <InputLabel>{t('financial.debts_payment.temp_billing.name_input_label')}</InputLabel>
        {isFetching ? (
          <Skeleton variant="rectangular">
            <Typography variant="h5" component="p">
              Mera Solis Roberth Sebastían
            </Typography>
          </Skeleton>
        ) : (
          <Typography variant="h5" component="p">
            {tempBillingData ? tempBillingData.razonSocial : defaultTempBillingData?.razonSocial}
          </Typography>
        )}
      </Grid>

      <Grid item xs={12}>
        <InputLabel>
          {t('financial.debts_payment.temp_billing.identification_input_label')}
        </InputLabel>
        {isFetching ? (
          <Skeleton variant="rectangular">
            <Typography variant="h5" component="p">
              000000000000
            </Typography>
          </Skeleton>
        ) : (
          <Typography variant="h5" component="p">
            {tempBillingData
              ? tempBillingData.identificacion
              : defaultTempBillingData?.identificacion}
          </Typography>
        )}
      </Grid>

      <Grid item xs={12}>
        <InputLabel>{t('financial.debts_payment.temp_billing.phone_input_label')}</InputLabel>
        {isFetching ? (
          <Skeleton variant="rectangular">
            <Typography variant="h5" component="p">
              000000000000
            </Typography>
          </Skeleton>
        ) : (
          <Typography variant="h5" component="p">
            {tempBillingData ? tempBillingData.telefono : defaultTempBillingData?.telefono}
          </Typography>
        )}
      </Grid>

      <Grid item xs={12}>
        <InputLabel>{t('financial.debts_payment.temp_billing.mail_input_label')}</InputLabel>
        {isFetching ? (
          <Skeleton variant="rectangular">
            <Typography variant="h5" component="p">
              test@test.com
            </Typography>
          </Skeleton>
        ) : (
          <Typography variant="h5" component="p">
            {tempBillingData ? tempBillingData.email : defaultTempBillingData?.email}
          </Typography>
        )}
      </Grid>

      <Grid item xs={12}>
        <InputLabel>{t('financial.debts_payment.temp_billing.address_input_label')}</InputLabel>
        {isFetching ? (
          <Skeleton variant="rectangular">
            <Typography variant="h5" component="p">
              test@test.com
            </Typography>
          </Skeleton>
        ) : (
          <Typography variant="h5" component="p">
            {tempBillingData ? tempBillingData.direccion : defaultTempBillingData?.direccion}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default TempBillingData;
