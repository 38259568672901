import { useEffect } from 'react';
import { Grid, IconButton, Typography, useTheme } from '@material-ui/core';
import { IconArrowNarrowLeft } from '@tabler/icons';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import MainCardForm from '../../../shared/components/cards/MainCardForm';
import EditEconomicGroupForm from '../components/EditEconomicGroupForm';
import { setEconomicGroupList } from '../slices/economicGroupSlice';
import { useLazyGetEconomicGroupQuery } from '../slices/economicGroupsApiSlice';
import SimpleFormOneColumn from '../../../shared/components/forms/skeletons/SimpleFormOneColumn';

const EditEconomicGroupScreen = () => {
  const theme = useTheme();
  const history = useHistory();
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const location = useLocation();

  const { economicGroupId } = useParams<{ economicGroupId: string }>();

  const { economicGroupsList } = useAppSelector((state) => state.economicGroups);

  const [getEconomyGroup, { isFetching }] = useLazyGetEconomicGroupQuery();

  useEffect(() => {
    if (!economicGroupsList.length && !isFetching) {
      getEconomyGroup({
        economicGroupId: +economicGroupId,
      })
        .unwrap()
        .then((response) => {
          if (response) dispatch(setEconomicGroupList([response!]));
        })
        .catch((error) => console.log(error));
    }
  }, [getEconomyGroup, isFetching, economicGroupId, economicGroupsList.length, dispatch]);

  return (
    <MainCardForm>
      <Grid item xs={12}>
        <Grid item container alignItems="center" gap={1} sx={{ cursor: 'pointer' }}>
          <IconButton onClick={() => history.push(`/${location.pathname.split('/')[1]}`)}>
            <IconArrowNarrowLeft size={30} color={theme.palette.text.dark} />
          </IconButton>
          <Typography variant="h2">{t('financial.economic_group.edit.title')}</Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} md={8} lg={6} sx={{ mt: 2 }}>
        {!!economicGroupsList.length ? <EditEconomicGroupForm /> : <SimpleFormOneColumn />}
      </Grid>
    </MainCardForm>
  );
};

export default EditEconomicGroupScreen;
