import { useEffect, useMemo } from 'react';

import { useParams } from 'react-router-dom';
import { Grid, Skeleton, Typography } from '@material-ui/core';

import { useAppSelector } from '../../../../hooks/useRedux';
import MainCard from '../../../shared/components/cards/MainCard';
// import DeleteRoleDialog from '../components/DeleteRoleDialog';

import { useAppDispatch } from '../../../../hooks/useRedux';
import SimpleFormOneColumn from '../../../shared/components/forms/skeletons/SimpleFormOneColumn';
import { useTranslation } from 'react-i18next';
import { useLazyGetReportQuery } from '../slices/moduleReportsApiSlice';
import { setReportList } from '../slices/moduleReportsSlice';
import BackButton from '../../../shared/components/buttons/BackButton';
import ReportViewer from '../components/ReportViewer';
// import UserProfilesTable from '../components/UserProfilesTable';
// import { renderSkeletonTable } from '../../../shared/helpers/render';

const ViewReportScreen = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { reportId } = useParams<{ reportId: string }>();

  const { reportList } = useAppSelector((state) => state.moduleReports);

  const [getReport, { isFetching }] = useLazyGetReportQuery();

  useEffect(() => {
    if (!reportList.length && !isFetching) {
      getReport({
        reportId: +reportId,
      })
        .unwrap()
        .then((response) => {
          if (response) dispatch(setReportList([response!]));
        })
        .catch((error) => console.log(error));
    }
  }, [dispatch, getReport, isFetching, reportId, reportList.length]);

  const reportView = useMemo(() => {
    return reportList.find((dt) => dt.id === Number(reportId));
  }, [reportId, reportList]);

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid item xs={12} sx={{ mb: 3 }}>
        <Grid item container alignItems="center" gap={1}>
          <BackButton />
          <Typography variant="h2">
            {t('Reporte')} -{' '}
            {reportView ? (
              reportView.rep_description
            ) : (
              <Skeleton sx={{ display: 'inline-block' }} width={'150px'} />
            )}
          </Typography>
        </Grid>
      </Grid>

      <Grid item xs={12} sx={{ mt: 2 }}>
        {!!reportView ? <ReportViewer reportView={reportView} /> : <SimpleFormOneColumn />}
      </Grid>
      {/* {roleIdToDelete && <DeleteRoleDialog />} */}
    </Grid>
  );
};

export default ViewReportScreen;
