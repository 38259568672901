import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISearchTable } from '../../../shared/interfaces/paginatedTable.interface';
import { PosgraduatePlanningDayType } from '../interfaces/posgraduatePlanningDayTypes.interface';

interface PosgraduatePlanningDayTypesState {
  posgraduatePlanningDayTypesList: PosgraduatePlanningDayType[];
  posgraduatePlanningDayTypesTable: ISearchTable;
  posgraduatePlanningDayTypeIdSelected: string | null;
  posgraduatePlanningDayTypeDeleteDialogOpen: boolean;
  posgraduatePlanningDayTypeFormDialogOpen: boolean;
}

const initialState: PosgraduatePlanningDayTypesState = {
  posgraduatePlanningDayTypesList: [],
  posgraduatePlanningDayTypesTable: {
    page: 1,
    perPage: 5,
    search: '',
  },
  posgraduatePlanningDayTypeIdSelected: null,
  posgraduatePlanningDayTypeDeleteDialogOpen: false,
  posgraduatePlanningDayTypeFormDialogOpen: false,
};

const posgraduatePlanningDayTypesSlice = createSlice({
  name: 'posgraduatePlanningPosgraduate',
  initialState,
  reducers: {
    setPosgraduatePlanningDayTypesList: (state, action: PayloadAction<any[]>) => {
      state.posgraduatePlanningDayTypesList = action.payload;
    },
    setPosgraduatePlanningDayTypesTable: (state, action: PayloadAction<ISearchTable>) => {
      state.posgraduatePlanningDayTypesTable = action.payload;
    },
    setPosgraduatePlanningDayTypeIdSelected: (state, action: PayloadAction<string | null>) => {
      state.posgraduatePlanningDayTypeIdSelected = action.payload;
    },
    setPosgraduatePlanningDayTypeDeleteDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.posgraduatePlanningDayTypeDeleteDialogOpen = action.payload;
    },
    setPosgraduatePlanningDayTypeFormDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.posgraduatePlanningDayTypeFormDialogOpen = action.payload;
    },
  },
});

export const {
  setPosgraduatePlanningDayTypesList,
  setPosgraduatePlanningDayTypesTable,
  setPosgraduatePlanningDayTypeIdSelected,
  setPosgraduatePlanningDayTypeDeleteDialogOpen,
  setPosgraduatePlanningDayTypeFormDialogOpen,
} = posgraduatePlanningDayTypesSlice.actions;

export default posgraduatePlanningDayTypesSlice.reducer;
