import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Classification } from '../interfaces/classifications.interface';

interface ClassificationsState {
  classificationToEdit?: Classification;
  deleteModalOpen: boolean;
  classificationToDelete?: string;
}

const initialState: ClassificationsState = {
  deleteModalOpen: false,
};

const classificationSlice = createSlice({
  name: 'classifications',
  initialState,
  reducers: {
    setClassificationToEdit: (state, action: PayloadAction<Classification | undefined>) => {
      state.classificationToEdit = action.payload;
    },
    setDeleteModalOpen: (state, action: PayloadAction<boolean>) => {
      state.deleteModalOpen = action.payload;
    },
    openDeleteModalWith: (state, action: PayloadAction<string>) => {
      state.classificationToDelete = action.payload;
      state.deleteModalOpen = true;
    },
    resetClassificationToDelete: (state) => {
      state.classificationToDelete = undefined;
    },
  },
});

export const {
  setDeleteModalOpen,
  openDeleteModalWith,
  resetClassificationToDelete,
  setClassificationToEdit,
} = classificationSlice.actions;

export default classificationSlice.reducer;
