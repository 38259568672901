import {
  Autocomplete,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../../../hooks/useRedux';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import { recoveryExamSchema } from '../constants/formConstants';
import { RecoveryExamSchema } from '../constants/validationSchemas';
import {
  useAddRecoveryExamRequestMutation,
  useGetRecoveryExamByStudentRecordQuery,
} from '../slices/academicRequestsApiSlice';
import useGuard from '../hooks/useGuard';
import TextFieldFetching from '../../../shared/components/TextFieldFetching';

import { useTranslation } from 'react-i18next';
import PeriodStudentSelect from './Builder/PeriodStudentSelect';

const RecoveryExamForm = () => {
  const { t } = useTranslation();

  const { hasAccess } = useGuard();
  const { currentProfile, currentRecordStudent } = useAppSelector((state) => state.access);
  const { userInfo } = useAppSelector((state) => state.auth);
  const [showAlerts, setShowAlerts] = useState(true);

  const [studentPeriod, setStudentPeriod] = useState(0);

  const {
    data: materias = [],
    isLoading: isLoadingRE,
    isError: isErrorRE,
    error: errorRE,
  } = useGetRecoveryExamByStudentRecordQuery(
    {
      profileId: currentProfile?.profileId!,
      studentRecordId: currentRecordStudent?.id!,
      periodId: studentPeriod,
    },
    {
      skip: !studentPeriod,
      refetchOnMountOrArgChange: 30,
    }
  );

  const [addRecoveryExam, { isLoading, isSuccess, isError, error }] =
    useAddRecoveryExamRequestMutation();
  const { handleSubmit, errors, touched, setFieldValue, values } = useFormik({
    initialValues: recoveryExamSchema,
    validationSchema: RecoveryExamSchema,
    onSubmit: async (value) => {
      await addRecoveryExam({
        profileId: currentProfile?.profileId!,
        userId: String(userInfo?.id!),
        studentRecordId: currentRecordStudent?.id!,
        recoveryExamPayload: {
          app_description: '', //values.app_description,
          course_student_id: value.course_student_id, //values.course,
          period_id: studentPeriod,
        },
      });
      setShowAlerts(true);
    },
  });
  useEffect(() => {
    if (isErrorRE && errorRE) {
      setShowAlerts(true);
    }
  }, [isErrorRE, errorRE]);

  useEffect(() => {
    if (materias && materias.length === 1) {
      setFieldValue('course_student_id', materias[0].id);
    }
  }, [materias, setFieldValue]);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction="column" gap={2}>
        <Grid item>
          {isError && showAlerts && (
            <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
          )}
          {isErrorRE && showAlerts && (
            <ErrorAlert message={errorRE as string} handleDismiss={() => setShowAlerts(false)} />
          )}
          {isSuccess && showAlerts && (
            <SuccessAlert
              message={t('students.applications.success_message')}
              handleDismiss={() => setShowAlerts(false)}
            />
          )}
        </Grid>

        <Grid item xs={12}>
          <PeriodStudentSelect
            changeHandler={setStudentPeriod}
            sideFunc={() => setFieldValue('course_student_id', 0)}
          />
        </Grid>

        <Grid item xs={12}>
          <InputLabel>
            {t('students.applications.recovery_exam.subject')} -{' '}
            {t('students.applications.recovery_exam.final_calification')}
          </InputLabel>
          {!isLoadingRE ? (
            <Autocomplete
              options={Array.isArray(materias) && !isErrorRE ? materias : []}
              loading={isLoadingRE}
              loadingText="Cargando parcial..."
              noOptionsText={t('students.applications.no_period_selected')}
              getOptionLabel={(option) =>
                option.subject_curriculum.matter_rename + ' - ' + option.final_note
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(errors.course_student_id && touched.course_student_id)}
                  placeholder={t('students.applications.recovery_exam.subject_placeholder')}
                />
              )}
              onChange={(_, value) => setFieldValue('course_student_id', value?.id)}
              fullWidth
              clearIcon={null}
              value={
                materias.length === 1 && !isErrorRE
                  ? materias[0]
                  : materias.find((dt) => dt.id === values.course_student_id) || null
              }
            />
          ) : (
            <TextFieldFetching />
          )}
          {errors.course_student_id && touched.course_student_id && (
            <FormHelperText error>{errors.course_student_id}</FormHelperText>
          )}
        </Grid>

        <Grid
          item
          container
          alignItems="center"
          justifyContent="flex-end"
          spacing={2}
          sx={{ mt: 1 }}
        >
          {hasAccess('save_recovery_exam') && (
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                size="large"
                color="primary"
                disabled={isLoading}
                endIcon={isLoading && <CircularProgress size={20} />}
              >
                {t('students.applications.save')}
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </form>
  );
};
export default RecoveryExamForm;
