import Grid from '@material-ui/core/Grid';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Checkbox from '@material-ui/core/Checkbox';

import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../../../../hooks/useRedux';

import SubCard from '../../../../../shared/components/cards/SubCard';
import { Details } from '../../../../electronic-documents/interfaces/electronicDocuments.interfaces';
import { renderErrorOrEmptyRow } from '../../../../../shared/helpers/render';
import { setMotivo, setSelectedFees, setTotalFees } from '../../../slices/generateCreditNoteSlice';
import { controlSelectionArr } from '../../../../../shared/helpers';
import { useEffect } from 'react';
import Chip from '../../../../../shared/components/extended/Chip';

const CreditNoteDetail = () => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const defaultConfiguration = useAppSelector((state) => state.access.defaultConfiguration);

  const { selectedInvoice, selectedFees } = useAppSelector((state) => state.generateCreditNote);

  //check por fila
  const handleClick = (event: React.ChangeEvent<HTMLInputElement>, feeId: number) => {
    const newSelected = controlSelectionArr(selectedFees, feeId);

    dispatch(setSelectedFees(newSelected));
  };

  //check all
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelectedId =
        (selectedInvoice?.details as Details[])
          .filter((fee) => fee.isRevert === '0')
          .map((n) => +n.id) || [];
      dispatch(setSelectedFees(newSelectedId));
      return;
    }

    dispatch(setSelectedFees([]));
  };

  useEffect(() => {
    if (selectedFees.length) {
      const totalFees = (selectedInvoice?.details as Details[])
        .filter((fee) => selectedFees.some((selFee) => fee.id === selFee))
        .map((fee) => +fee.precioTotalSinImpuesto)
        .reduce((partialSum, a) => partialSum + a, 0);

      // console.log(totalFees);

      dispatch(setTotalFees(totalFees || 0));
    } else {
      dispatch(setMotivo(undefined));
      dispatch(setTotalFees(0));
    }
  }, [dispatch, selectedFees, selectedInvoice?.details]);

  const renderRow = (details: Details, i: number) => (
    <TableRow hover className="table-row" key={`${details.id.toString()}-${i}`}>
      <TableCell>{details.descripcion}</TableCell>
      <TableCell align="center">
        {details.isRevert === '0' ? (
          <Chip
            chipcolor="success"
            label={t(
              'financial.generate_creditnote.credit_note_detail.table.state_badge_available'
            )}
          />
        ) : (
          <Chip
            chipcolor="error"
            label={t('financial.generate_creditnote.credit_note_detail.table.state_badge_reversed')}
          />
        )}
      </TableCell>
      <TableCell align="center">
        {defaultConfiguration?.currency_symbol}
        {details.precioUnitario}
      </TableCell>
      <TableCell align="center">
        <Checkbox
          disabled={details.isRevert === '1'}
          checked={selectedFees.some((r) => r === details.id)}
          onChange={(e) => handleClick(e, details.id)}
        />
      </TableCell>
    </TableRow>
  );

  const renderRows = () => {
    return <>{selectedInvoice?.details?.map(renderRow)}</>;
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <SubCard title="" content={false}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        {t('financial.generate_creditnote.credit_note_detail.table.product')}
                      </TableCell>
                      <TableCell align="center">
                        {t('financial.generate_creditnote.credit_note_detail.table.state')}
                      </TableCell>
                      <TableCell align="center">
                        {t('financial.generate_creditnote.credit_note_detail.table.price')}
                        {`(${defaultConfiguration?.currency_symbol})`}
                      </TableCell>
                      <TableCell align="center">
                        <Checkbox
                          color="primary"
                          indeterminate={
                            selectedInvoice?.details &&
                            selectedFees.length > 0 &&
                            selectedFees.length < (selectedInvoice?.details.length || 0)
                          }
                          checked={
                            selectedInvoice?.details &&
                            (selectedInvoice?.details.length || 0) > 0 &&
                            selectedFees.length >= (selectedInvoice?.details.length || 0)
                          }
                          onChange={handleSelectAllClick}
                          inputProps={{
                            'aria-label': 'Seleccionar todas las cuotas',
                          }}
                        />
                      </TableCell>
                      {/* <TableCell align="center">Cantidad</TableCell>
                      <TableCell align="center">Descuento (%)</TableCell>
                      <TableCell align="center">Subtotal   {defaultConfiguration?.currency_symbol}
</TableCell> */}
                      {/* <TableCell align="center">Remover</TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedInvoice?.details && renderRows()}
                    {!selectedInvoice && renderErrorOrEmptyRow(5)}
                    {selectedInvoice?.details && selectedInvoice?.details?.length !== 0 && (
                      <>
                        {/* <TableRow>
                          <TableCell colSpan={6} align="right">
                            <b>Total:</b>
                          </TableCell>
                          <TableCell> {showWithDecimals(totalNetAmout)} </TableCell>
                          <TableCell>{showWithDecimals(totalPayAmount)} </TableCell>
                        </TableRow> */}
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </SubCard>
      </Grid>
    </Grid>
  );
};

export default CreditNoteDetail;
