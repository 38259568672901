import React, { FC, useState } from 'react';

import {
  Autocomplete,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  TextField,
} from '@material-ui/core';

import InputLabel from '../../../../shared/components/forms/InputLabel';
import { BillingData } from '../../interfaces/paymentsHistory.interface';
import { useAppSelector } from '../../../../../hooks/useRedux';
import { useFormik } from 'formik';
import { useUpdateStudentBillingDataMutation } from '../../slices/paymentsApiSlice';
import { useGetCatalogByAcronymQuery } from '../../../../shared/slices/catalogApi';
import TextFieldFetching from '../../../../shared/components/TextFieldFetching';
import ErrorAlert from '../../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../../shared/components/alerts/SuccessAlert';
import { FinancialInformationSchema } from '../../constants/validation-schemas';

interface Props {
  BillingData: BillingData | undefined;
  handleCloseDialog: (e: React.SyntheticEvent) => void;
  successAction?: (...args: any) => void;
  errorAction?: (...args: any) => void;
}

const EditBillingInfo: FC<Props> = ({
  BillingData,
  handleCloseDialog,
  successAction,
  errorAction,
}: Props) => {
  const {
    userInfo: { fullname },
  } = useAppSelector((state) => state.auth);

  const { currentProfile } = useAppSelector((state) => state.access);

  const [showAlerts, setShowAlerts] = useState(true);

  //identifications types
  const {
    data: identificationsAvailables = [],
    isLoading: isLoadingIA,
    isFetching: isFetchingIA,
  } = useGetCatalogByAcronymQuery({
    profileId: currentProfile?.profileId!,
    acronym: 'TI',
  });

  const [updateFinancialInformation, { isLoading, isError, error, isSuccess }] =
    useUpdateStudentBillingDataMutation();

  const { errors, touched, values, handleSubmit, getFieldProps, setFieldValue, resetForm } =
    useFormik({
      initialValues: {
        identificationType: BillingData?.type_identification?.cat_keyword,
        identification: BillingData?.identificacion,
        telf: BillingData?.telefono,
        email: BillingData?.email,
        address: BillingData?.direccion,
        socialReason: BillingData?.razonSocial,
      },
      validationSchema: FinancialInformationSchema,
      // validate: ({ start_process, end_process, startDate, endDate }) => {
      //   return validateDates(start_process, end_process, startDate, endDate);
      // },
      onSubmit: async (values) => {
        try {
          await updateFinancialInformation({
            profileId: currentProfile?.profileId!,
            financialInformationId: BillingData!.id,
            updatePayload: {
              type_identification_keyword: values.identificationType,
              identificacion: values.identification,
              telefono: values.telf,
              email: values.email,
              direccion: values.address,
              razonSocial: values.socialReason,
            },
          }).unwrap();

          if (successAction) successAction();
        } catch (error: any) {
          if (errorAction) errorAction();
        } finally {
          setShowAlerts(true);
          window.scrollTo(0, 0);
        }
      },
    });

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2} sx={{ pl: { lg: 3 } }}>
        <Grid item xs={12} lg={8}>
          {isError && showAlerts && (
            <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
          )}

          {isSuccess && showAlerts && (
            <SuccessAlert
              message="Los datos de facturación han sido actualizados."
              handleDismiss={() => setShowAlerts(false)}
            />
          )}
        </Grid>

        <Grid item xs={12} lg={8}>
          <InputLabel>Nombre y Apellido</InputLabel>
          <TextField fullWidth value={fullname} disabled />
        </Grid>

        <Grid item xs={12} lg={8}>
          <InputLabel>Razón Social</InputLabel>
          <TextField
            fullWidth
            {...getFieldProps('socialReason')}
            error={Boolean(errors.socialReason && touched.socialReason)}
            placeholder="Ingrese el número de identificación"
          />
          {errors.socialReason && touched.socialReason && (
            <FormHelperText error>{errors.socialReason}</FormHelperText>
          )}
        </Grid>

        <Grid item xs={12} lg={8}>
          <InputLabel>Tipo de identificación</InputLabel>
          {isFetchingIA ? (
            <TextFieldFetching />
          ) : (
            <Autocomplete
              options={identificationsAvailables}
              loading={isLoadingIA}
              loadingText="Cargando tipos de identificación..."
              getOptionLabel={(option) => option.cat_name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(errors.identificationType && touched.identificationType)}
                  placeholder="Seleccione un tipo identificación"
                />
              )}
              value={
                identificationsAvailables.find(
                  (identification) => identification.cat_keyword === values.identificationType
                ) || null
              }
              onChange={(_, value) => {
                setFieldValue('identificationType', value?.cat_keyword);
              }}
              fullWidth
              clearIcon={null}
            />
          )}
          {errors.identificationType && touched.identificationType && (
            <FormHelperText error>{errors.identificationType}</FormHelperText>
          )}
        </Grid>

        <Grid item xs={12} lg={8}>
          <InputLabel>Cédula o RUC</InputLabel>
          <TextField
            fullWidth
            {...getFieldProps('identification')}
            error={Boolean(errors.identification && touched.identification)}
            placeholder="Ingrese el número de identificación"
          />
          {errors.identification && touched.identification && (
            <FormHelperText error>{errors.identification}</FormHelperText>
          )}
        </Grid>

        <Grid item xs={12} lg={8}>
          <InputLabel>Teléfono</InputLabel>
          <TextField
            fullWidth
            {...getFieldProps('telf')}
            error={Boolean(errors.telf && touched.telf)}
            placeholder="Ingrese el número de teléfono"
          />
          {errors.telf && touched.telf && <FormHelperText error>{errors.telf}</FormHelperText>}
        </Grid>

        <Grid item xs={12} lg={8}>
          <InputLabel>E-mail</InputLabel>
          <TextField
            fullWidth
            {...getFieldProps('email')}
            error={Boolean(errors.email && touched.email)}
            placeholder="Ingrese el correo electrónico"
          />
          {errors.email && touched.email && <FormHelperText error>{errors.email}</FormHelperText>}
        </Grid>

        <Grid item xs={12} lg={8}>
          <InputLabel>Dirección</InputLabel>
          <TextField
            fullWidth
            multiline
            rows={2}
            {...getFieldProps('address')}
            error={Boolean(errors.address && touched.address)}
            placeholder="Ingrese la dirección"
          />
          {errors.address && touched.address && (
            <FormHelperText error>{errors.address}</FormHelperText>
          )}
        </Grid>

        <Grid container item xs={12} lg={8} spacing={2}>
          <Grid item>
            <Button
              variant="outlined"
              size="large"
              color="primary"
              onClick={(e) => {
                resetForm();
                handleCloseDialog(e);
              }}
            >
              Cancelar
            </Button>
          </Grid>
          <Grid item>
            {/* {hasAccess('create') && ( */}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              disabled={isLoading}
              endIcon={isLoading && <CircularProgress size={20} />}
            >
              Actualizar
            </Button>
            {/* )} */}
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default EditBillingInfo;
