import { Button, CircularProgress, IconButton, Tooltip } from '@material-ui/core';
import { IconReportMoney } from '@tabler/icons';
//import { useEffect,  useState } from 'react';
import { useGetAccountStateQuery } from '../slices/paymentsApiSlice';
interface Props {
  studentId: number;
  student_record?: number;
  isIcon: boolean;
}
const DownloadAccountState = ({ studentId, student_record, isIcon = true }: Props) => {
  //const [downloadPdf, setDownloadPdf] = useState<boolean>(false);

  const {
    data: pdfData,
    isLoading: isLoadingPDFA,
    isFetching: isFetchingPDFA,
    //refetch,
    // request,
  } = useGetAccountStateQuery({ studentId: studentId, student_record });

  /*useEffect(() => {
    if (!isLoadingPDFA && pdfData && downloadPdf) {
      console.log('entra');
      // const content = request.headers['content-type'];
      // downloader(pdfData, 'prueba.pdf', 'application/pdf');
      const file = new Blob([pdfData], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      // setDownloadPdf(false);
    }
  }, [isLoadingPDFA, pdfData, refetch, downloadPdf]);*/

  const handleDownload = () => {
    /*if (!Boolean(pdfData)) {
      setDownloadPdf(true);
      return;
    }*/
    // refetch();
    const file = new Blob([pdfData], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  };

  return (
    <>
      <Tooltip title={'descargar estado de cuenta en pdf'}>
        {isIcon && (isLoadingPDFA || isFetchingPDFA) ? (
          <CircularProgress size={20} />
        ) : isIcon && !(isLoadingPDFA || isFetchingPDFA) ? (
          <IconButton
            color="inherit"
            onClick={handleDownload}
            disabled={isLoadingPDFA || isFetchingPDFA}
            //endIcon={(isLoadingPDFA || isFetchingPDFA) && <CircularProgress size={20} />}
          >
            <IconReportMoney />
          </IconButton>
        ) : (
          <Button
            variant="contained"
            size="large"
            color="primary"
            onClick={handleDownload}
            sx={{
              textTransform: 'none',
            }}
            disabled={isLoadingPDFA || isFetchingPDFA}
            endIcon={(isLoadingPDFA || isFetchingPDFA) && <CircularProgress size={20} />}
          >
            Estado de cuenta
          </Button>
        )}
      </Tooltip>
    </>
  );
};
export default DownloadAccountState;
