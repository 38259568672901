export const ciudadesDisponibles = [
  {
    id: 1,
    cam_name: 'Guayaquil',
  },
  {
    id: 2,
    cam_name: 'Samborondon',
  },
  {
    id: 3,
    cam_name: 'Cuenca',
  },
];

export const tiposInstitutosDisponibles = [
  {
    id: 1,
    tp_name: 'Ecotec',
  },
  {
    id: 2,
    tp_name: 'Ecomundo',
  },
];

export const ordenesDisponibles = [
  {
    id: 1,
    name: 'Primero',
  },
  {
    id: 2,
    name: 'Segundo',
  },
  {
    id: 3,
    name: 'Tercero',
  },
  {
    id: 4,
    name: 'Cuarto',
  },
  {
    id: 5,
    name: 'Quinto',
  },
  {
    id: 6,
    name: 'Sexto',
  },
  {
    id: 7,
    name: 'Septimo',
  },
];

export const componentProcess = [
  {
    id: 1,
    name: 'carga-horaria-estudiante',
    label: 'Carga horaria estudiante',
  },
  {
    id: 2,
    name: 'carga-horaria-docente',
    label: 'Carga horaria docente',
  },
];
