import { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

// import AddStudentProjectForm from './forms/AddStudentProjectForm';
// import ListStudentsAssign from './lateral-dialogs/ListStudentsAssign';
import StudentListAndForm from './forms/StudentListAndForm';
import TeacherListAndForm from './forms/TeacherListAndForm';

interface Props {
  projectId: string;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Grid item xs={12}>
          {children}
        </Grid>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const ListAssignPersonDelete = ({ projectId }: Props) => {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Docentes" {...a11yProps(0)} />
          <Tab label="Estudiantes" {...a11yProps(1)} />
        </Tabs>
      </Grid>
      <Grid item xs={12}>
        <TabPanel value={value} index={0}>
          <TeacherListAndForm projectId={projectId} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          {/* <AddCollaboratorProjectForm projectId={projectId} /> */}
          <StudentListAndForm projectId={projectId} />
        </TabPanel>
      </Grid>
    </Grid>
  );
};

export default ListAssignPersonDelete;
