import { useHistory } from 'react-router-dom'
import { Grid, IconButton, Typography, useTheme } from "@material-ui/core"
import { IconArrowNarrowLeft } from "@tabler/icons"
import { useAppSelector } from "../../../../hooks/useRedux"
import MainCard from "../../../shared/components/cards/MainCard"
import EditCollective from "../components/EditCollective"

const EditCollectiveScreen = function () {
    const theme = useTheme()

    const history = useHistory()

    const { currentEducationLevelId,
        educationLevelList,
        educationLevelCollectives,
        currentColective } = useAppSelector((state) => state.educationLevels);

    const educationLevelToAdd = educationLevelList.find((value) => value.id === currentEducationLevelId);

    const collectiveToEdit = educationLevelCollectives.find((value) => value.id === currentColective);

    return <Grid component={MainCard} sx={{ padding: 2 }}>
        <Grid item container alignItems="center" gap={1} sx={{ cursor: 'pointer', marginBottom: 5 }}>
            <IconButton onClick={() => history.goBack()}>
                <IconArrowNarrowLeft size={30} color={theme.palette.text.dark} />
            </IconButton>
            <Typography variant="h2">Editar colectivo - {educationLevelToAdd && collectiveToEdit?.name}</Typography>
        </Grid>
        <EditCollective />
    </Grid>
}

export default EditCollectiveScreen