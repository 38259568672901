// import React from 'react';

import { CircularProgress, Grid } from '@material-ui/core';
// import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../../hooks/useRedux';

import SearchPaginatedTable from '../../../../shared/components/tables/SearchPaginatedTable';
import { BusToursSplited } from '../../interfaces/buses.interfaces';
import { useGetRoutesSchedulesQuery } from '../../slices/busRouteApiSlice';
import AccordionWithSelection from '../../../../shared/components/extended/AccordionWithSelection';
import AccordionTitleBusesSchedules from '../builder/AccordionTitleBusesSchedules';

interface Props {
  busTours: BusToursSplited;
}

const ScheduleList = ({ busTours }: Props) => {
  // const { t } = useTranslation();

  const { busRouteIdSelected } = useAppSelector((state) => state.busRoute);

  const {
    data: routeSchedules,
    isLoading,
    isFetching,
    error,
  } = useGetRoutesSchedulesQuery(
    {
      route: busRouteIdSelected || 0,
    },
    { skip: !busRouteIdSelected }
  );

  const data = routeSchedules?.map((item) => {
    const datafetch = item.bus_schedule_detail.map((schedule, i) => ({
      id: { id: schedule.id, index: i + 1 },
      hour_departure_go: schedule.hour_departure_go || '--',
      hour_arrival_go: schedule.hour_arrival_go || '--',
      hour_departure_return: schedule.hour_departure_return || '--',
      hour_arrival_return: schedule.hour_arrival_return || '--',
    }));

    return {
      id: 'form-info-evaluation',
      title: <AccordionTitleBusesSchedules title={item.sche_name} urlFile={item.sche_link} />,
      defaultExpand: true,
      content: (
        <SearchPaginatedTable
          data={datafetch}
          isLoading={isLoading}
          headers={{
            id: '#',
            hour_departure_go: busTours.ida?.at(0)?.tour_name || 'Salida base',
            hour_arrival_go: busTours.ida?.at(-1)?.tour_name || 'Llegada destino',
            hour_departure_return: busTours.retorno?.at(0)?.tour_name || 'Salida retorno',
            hour_arrival_return: busTours.retorno?.at(-1)?.tour_name || 'Llegada base retorno',
          }}
          isFetching={isFetching}
          error={error}
          keyExtractor={(item) => `bus-schedule-${item.id.id}`}
          customRenderers={{
            id: ({ id }) => <>{id.index}</>,
          }}
          setPage={() => {}}
          setPerPage={() => {}}
          setSearch={() => {}}
          searchPlacehoder={''}
          total={0}
          perPage={0}
          page={0}
          numHeader={5}
          search={''}
          paginationBox={false}
          perPageBox={false}
          showFilter={false}
        />
      ),
    };
  });

  return isLoading ? (
    <Grid container justifyContent="center">
      <CircularProgress />
    </Grid>
  ) : (
    <AccordionWithSelection accordionProps={{ defaultExpanded: true }} data={data!} />
  );
};

export default ScheduleList;
