import { useState } from 'react';

import { IconArrowNarrowLeft, IconBrandTelegram } from '@tabler/icons';

import SearchPaginatedTable from '../../../../shared/components/tables/SearchPaginatedTable';
import EditButton from '../../../../shared/components/tables/buttons/EditButton';
import { updateMigrationDialogTable } from '../../slices/contificoMigrationsSlice';
import { useTableSearch } from '../../../../../hooks/useTableSearch';
import { useAppSelector } from '../../../../../hooks/useRedux';
import SuccessAlert from '../../../../shared/components/alerts/SuccessAlert';
import { useTranslation } from 'react-i18next';
import {
  useGetContificoMonthDetailsQuery,
  useMigrateInvoicesMutation,
} from '../../slices/contificoMigrationsApiSlice';
import { Grid, IconButton, Typography, useTheme, Stack, Checkbox } from '@material-ui/core';
import LoadingButton from '../../../../shared/components/buttons/LoadingButton';
import ErrorAlert from '../../../../shared/components/alerts/ErrorAlert';

interface MigrateInvoicesTableProps {
  month: number;
  year: number;
  handleCloseDialog: (e: any) => void;
  //   data: ReturnType<typeof returnDummyData2>;
}

const MigrateInvoicesTable = ({ month, year, handleCloseDialog }: MigrateInvoicesTableProps) => {
  const theme = useTheme();

  const { t } = useTranslation();
  const [showAlerts, setShowAlerts] = useState(false);

  const { currentProfile } = useAppSelector((state) => state.access);
  const { page, perPage, search } = useAppSelector(
    (state) => state.contificoMigrations.migrationDialogTable
  );

  const [toMigrate, setToMigrate] = useState<number[]>([]);

  const { data, isLoading, isFetching } = useGetContificoMonthDetailsQuery({
    month,
    year,
    page,
    size: perPage,
    por_migrar: 'yes',
    user_profile_id: currentProfile?.profileId!,
  });

  const { setPage, setPerPage, setSearch } = useTableSearch({
    page,
    perPage,
    search,
    updateFunction: updateMigrationDialogTable,
  });

  const [
    triggerMigration,
    { isLoading: isMigrating, isSuccess: isMigrated, isError: isErrorMigrate, error: errorMigrate },
  ] = useMigrateInvoicesMutation({});

  const handleMigrateAll = async () => {
    await triggerMigration({
      user_profile_id: 1,
      documentsSriCabId: toMigrate,
    });
    setToMigrate([]);
    setShowAlerts(true);
  };

  const handleMigrateOne = async (id: number) => {
    setToMigrate([id]);
    await triggerMigration({
      user_profile_id: 1,
      documentsSriCabId: [id],
    });
    setToMigrate([]);
    setShowAlerts(true);
  };

  const datafetch =
    data?.data?.map((item, index) => ({
      codigo: (data?.from || 0) + index,
      cliente: item.razonSocialComprador,
      //   producto: '',
      amount: item.importeTotal,
      state: item.contifico_id ? 'Migrado' : 'Por migrar',
      options: item,
    })) || [];

  return (
    <>
      <Stack direction="row" width={'100%'} alignItems="center" justifyContent="space-between">
        <Grid item xs="auto" alignItems="center" gap={1}>
          <IconButton
            onClick={(e) => {
              handleCloseDialog(e);
            }}
          >
            <IconArrowNarrowLeft size={30} color={theme.palette.text.dark} />
          </IconButton>
          <Typography variant="h3" component="span">{`${t(
            'Migración de facturas a Contífico'
          )}`}</Typography>
        </Grid>

        {isMigrated && showAlerts && (
          <SuccessAlert
            message={t('Migración realizada con éxito')}
            handleDismiss={() => setShowAlerts(false)}
          />
        )}
        {isErrorMigrate && showAlerts && (
          <ErrorAlert
            message={(errorMigrate as string) || ''}
            handleDismiss={() => setShowAlerts(false)}
          />
        )}

        <Grid item gap={1}>
          <LoadingButton
            variant="contained"
            isLoading={isMigrating && toMigrate.length === perPage}
            onClick={async () => {
              await handleMigrateAll();
            }}
            disabled={toMigrate.length === 0}
          >
            Enviar por lote
          </LoadingButton>
        </Grid>
      </Stack>
      {isMigrated && showAlerts && (
        <SuccessAlert
          message={t('Migración realizada con éxito')}
          handleDismiss={() => setShowAlerts(false)}
        />
      )}

      <SearchPaginatedTable
        data={datafetch}
        error={undefined}
        headers={{
          codigo: 'N°',
          cliente: 'Cliente',
          //   producto: 'Producto',
          amount: 'Valor',
          state: 'Estado',
          options: 'Opciones',
        }}
        isFetching={isFetching}
        isLoading={isLoading}
        page={page}
        perPage={perPage}
        keyExtractor={(item) => item.codigo.toString()}
        search={search}
        searchPlacehoder=""
        setPage={setPage}
        setPerPage={setPerPage}
        setSearch={setSearch}
        total={data?.total || 0}
        showFilter={false}
        customRenderers={{
          //checkbox
          codigo: (item) => (
            <>
              <Checkbox
                checked={toMigrate.includes(item.options.id)}
                onChange={(e) => {
                  if (e.target.checked) {
                    setToMigrate([...toMigrate, item.options.id]);
                  } else {
                    setToMigrate(toMigrate.filter((id) => id !== item.options.id));
                  }
                }}
              />
            </>
          ),
          amount: (item) => `$${item.amount}`,
          options: (item) => (
            <>
              <EditButton
                Icon={IconBrandTelegram}
                title="Migrar facturas"
                isLoading={isMigrating && toMigrate.includes(item.options.id)}
                handleEdit={() => handleMigrateOne(item.options.id)}
              />
            </>
          ),
        }}
        customDataCellsProperties={{
          codigo: {
            align: 'center',
          },
          cliente: {
            align: 'center',
          },
          //   producto: {
          //     align: 'center',
          //   },
          amount: {
            align: 'center',
          },
          state: {
            align: 'center',
          },
          options: {
            align: 'center',
          },
        }}
      />
    </>
  );
};

export default MigrateInvoicesTable;
