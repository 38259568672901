import { Grid, Typography, useTheme } from '@material-ui/core';
import { Bookmark } from '@material-ui/icons';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@material-ui/lab';
import { format } from 'date-fns';
import { useAppSelector } from '../../../../hooks/useRedux';
import { useGetHistorialSyllabusQuery } from '../slices/SyllabusApiSlice';

const HistorialTimeline = () => {
  const { syllabusIdSelected } = useAppSelector((state) => state.syllabus);

  const theme = useTheme();

  const { data, isLoading } = useGetHistorialSyllabusQuery({
    syllabusId: syllabusIdSelected!,
  });

  console.log(data, isLoading);

  return (
    <Grid>
      {!isLoading && data && data.length > 0 && (
        <Grid container>
          <Grid item xs={12}>
            <Timeline>
              {data.map((item, index) => (
                <TimelineItem>
                  <TimelineOppositeContent style={{ flex: 0.1 }} />
                  <TimelineSeparator>
                    <TimelineDot
                      sx={{
                        backgroundColor: 'transparent',
                        borderColor: theme.palette.primary.main,
                        color: theme.palette.primary.main,
                      }}
                    >
                      <Bookmark width={32} height={32} />
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent sx={{ py: '12px', px: 2 }}>
                    <Typography variant="h4" component="span">
                      {item.observation}
                    </Typography>
                    <Typography
                      variant="h5"
                      style={{
                        color: theme.palette.info.main,
                      }}
                    >
                      {format(new Date(item.created_at), 'PPPpp')}
                    </Typography>
                  </TimelineContent>
                </TimelineItem>
              ))}
            </Timeline>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default HistorialTimeline;
