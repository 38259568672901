import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import useTheme from '@material-ui/core/styles/useTheme';
import LinearProgress from '@material-ui/core/LinearProgress';
import Grid from '@material-ui/core/Grid';

import MainCard from '../../../shared/components/cards/MainCard';
import DashboardCard from '../../../shared/components/cards/Skeleton/EarningCard';
import { useGetStudentCareerAdvanceQuery } from '../../academic-counseling/slices/academicCounselingApi';
import { useAppSelector } from '../../../../hooks/useRedux';

// style constant
const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.dark.dark : theme.palette.primary.main,
    color: '#fff',
    overflow: 'hidden',
    position: 'relative',
    '&>div': {
      position: 'relative',
      zIndex: 5,
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      width: '210px',
      height: '210px',
      background:
        theme.palette.mode === 'dark'
          ? 'linear-gradient(210.04deg, ' +
            theme.palette.primary.dark +
            ' -50.94%, rgba(144, 202, 249, 0) 95.49%)'
          : theme.palette.primary[200],
      borderRadius: '50%',
      zIndex: 1,
      top: '-85px',
      right: '-95px',
      [theme.breakpoints.down('xs')]: {
        top: '-105px',
        right: '-140px',
      },
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      zIndex: 1,
      width: '210px',
      height: '210px',
      background:
        theme.palette.mode === 'dark'
          ? 'linear-gradient(140.9deg, ' +
            theme.palette.primary.dark +
            ' -14.02%, rgba(144, 202, 249, 0) 82.50%)'
          : theme.palette.primary[200],
      borderRadius: '50%',
      top: '-125px',
      right: '-15px',
      opacity: 0.5,
      [theme.breakpoints.down('xs')]: {
        top: '-155px',
        right: '-70px',
      },
    },
  },
  content: {
    padding: '20px !important',
  },
  //   avatar: {
  //     ...theme.typography.commonAvatar,
  //     ...theme.typography.largeAvatar,
  //     backgroundColor:
  //       theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary[800],
  //     color: '#fff',
  //     marginTop: '8px',
  //   },
  //   cardHeading: {
  //     fontSize: '2.125rem',
  //     fontWeight: 500,
  //     marginRight: '8px',
  //     marginTop: '14px',
  //     marginBottom: '6px',
  //   },
  //   subHeading: {
  //     fontSize: '1rem',
  //     fontWeight: 500,
  //     color:
  //       theme.palette.mode === 'dark' ? theme.palette.text.secondary : theme.palette.primary[200],
  //   },
  //   avatarCircle: {
  //     ...theme.typography.smallAvatar,
  //     cursor: 'pointer',
  //     backgroundColor: theme.palette.primary[200],
  //     color: theme.palette.primary.dark,
  //   },
  //   circleIcon: {
  //     transform: 'rotate3d(1, 1, 1, 45deg)',
  //   },
}));

const CareerPercentage = () => {
  const theme = useTheme();
  const classes = useStyles();

  const history = useHistory();

  const { t } = useTranslation();
  const { currentRecordStudent } = useAppSelector((state) => state.access);

  const { isLoading, data } = useGetStudentCareerAdvanceQuery(
    {
      studentRecordId: currentRecordStudent?.id || 0,
    },
    { skip: !currentRecordStudent?.id }
  );

  const totalRequiredSubject: number = data?.curriculumNew
    ? data?.semesters!.reduce((prev, curr) => prev + parseInt(curr.required_subjects), 0) || 0
    : data?.areas!.reduce((prev, curr) => prev + parseInt(curr.required_subjects), 0) || 0;

  const totalApprovedSubject: number = data?.curriculumNew
    ? data?.semesters!.reduce((prev, curr) => prev + parseInt(curr.approved_subjects), 0) || 0
    : data?.areas!.reduce((prev, curr) => prev + parseInt(curr.approved_subjects), 0) || 0;

  const porcentageApprovedSubject = (totalApprovedSubject * 100) / (totalRequiredSubject || 1);

  return (
    <React.Fragment>
      {isLoading ? (
        <DashboardCard />
      ) : (
        <MainCard
          border={false}
          className={classes.card}
          contentClass={classes.content}
          onClick={() => {
            history.push('/consejeria-academica');
          }}
        >
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <Typography sx={{ fontWeight: 600, fontSize: '1.2rem' }}>
                {t('students.dashboard.career_percentage.title')}
                {porcentageApprovedSubject.toFixed(2)}%
              </Typography>
            </Grid>
            <Grid item>{/* <Typography>5to semestre 32%</Typography> */}</Grid>
            <Grid item xs={12}>
              <LinearProgress
                variant="determinate"
                value={porcentageApprovedSubject}
                color="inherit"
                sx={{
                  height: 8,
                  borderRadius: '15px',
                  bgcolor: theme.palette.grey[400],
                  '& .MuiLinearProgress-bar': { bgcolor: theme.palette.secondary.main },
                }}
              />
            </Grid>
          </Grid>
        </MainCard>
      )}
    </React.Fragment>
  );
};

export default CareerPercentage;
