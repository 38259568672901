import { Switch, Redirect } from 'react-router-dom';
import { useAppSelector } from '../../../../hooks/useRedux';
import GuardRoute from '../../../../router/guards/GuardRoute';
import { permissionKey } from '../constants/permissions';
import ReservationsScreen from '../screens/ReservationsScreen';

const TagsRouter = () => {
  const { currentModule } = useAppSelector((state) => state.access);

  const hasPermission = permissionKey in currentModule!.permissions;

  if (!hasPermission) return <Redirect to="/dashboard" />;

  return (
    <Switch>
      <GuardRoute exact path="/administrar-reservaciones" component={ReservationsScreen} />
    </Switch>
  );
};

export default TagsRouter;
