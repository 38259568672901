import { useClearTokenClientMutation } from '../slices/userConfigurationsApiSlice';
import { useTranslation } from 'react-i18next';
import ConfirmAlert from '../../../shared/components/dialogs/ConfirmAlert';
// import { resetRoleIdToDelete, setDeleteModalOpen } from '../slices/rolesSlice';

interface Props {
  openModal: boolean;
  userId: number;
  handleCloseModal: () => void;
}

const ClearClientTokenDialog = ({ openModal, userId, handleCloseModal }: Props) => {
  const { t } = useTranslation();
  const [deleteUserProfile, { isLoading, isSuccess, isError, error }] =
    useClearTokenClientMutation();

  const handleDelete = () => {
    deleteUserProfile({ userId });
  };

  const handleClose = () => {
    handleCloseModal();
  };

  return (
    <ConfirmAlert
      open={openModal}
      onClose={handleClose}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      handleDelete={handleDelete}
      title={t('configuration.users.dialog.delete_client_token.title')}
      confirmBtnMessage={t('configuration.users.dialog.delete_client_token.confirm_btn')}
      error={error as string}
    />
  );
};

export default ClearClientTokenDialog;
