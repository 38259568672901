import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../hooks/useRedux';
import MainCard from '../../../shared/components/cards/MainCard';
import DeleteTagModal from '../components/DeleteTagModal';
import TagsTable from '../components/TagsTable';

const TagsScreen = () => {
  const { t } = useTranslation();
  const { tagToDelete } = useAppSelector((state) => state.tags);
  return (
    <Grid component={MainCard} sx={{ p: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">{t('tags.title')}</Typography>
      </Grid>
      <TagsTable />
      {tagToDelete && <DeleteTagModal />}
    </Grid>
  );
};

export default TagsScreen;
