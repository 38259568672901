import { Switch } from 'react-router-dom';

import { useAppSelector } from '../../../../hooks/useRedux';
import InvestigationLinesScreenAndRoute from '../screens/InvestigationLinesScreen';
import ApplicationScreen from '../components/projects/applications/screens/ApplicationScreen';
import StagesScreen from '../components/projects/stages/screens/StagesScreen';
import GuardRoute from '../../../../router/guards/GuardRoute';
import { permissionKey } from '../constants/permissions';
import TasksScreen from '../components/projects/tasks/screens/TasksScreen';

function InvestigationLinesRouter() {
  const { currentModule } = useAppSelector((state) => state.access);
  const hasPermission = permissionKey in currentModule?.permissions!;
  console.log(hasPermission);

  return (
    <Switch>
      <GuardRoute
        exact
        path="/administrar-lineas-de-investigacion/proyecto/solicitudes/:projectId"
        component={ApplicationScreen}
      />
      <GuardRoute
        exact
        path="/administrar-lineas-de-investigacion/proyecto/:projectId/etapas"
        component={StagesScreen}
      />
      <GuardRoute
        exact
        path="/administrar-lineas-de-investigacion/proyecto/tareas/:projectId"
        component={TasksScreen}
      />
      <GuardRoute
        // exact
        path={`/administrar-lineas-de-investigacion`}
        component={InvestigationLinesScreenAndRoute}
      />
    </Switch>
  );
}

export default InvestigationLinesRouter;
