import {
  Grid,
  Step,
  StepButton,
  Stepper,
  useMediaQuery,
  useTheme,
  makeStyles,
  StepLabel,
  Typography,
} from '@material-ui/core';
import { TablerIcon } from '@tabler/icons';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { RegisterSyllabusData } from '../../interfaces/registerSyllabusData';
import { BibliographySchema, GeneralDetailsSchema, LessonPlanSchema } from './validation';

interface Props {
  steps: {
    label: string;
    Icon: TablerIcon;
    url: string;
  }[];
  activeStep: number;
  handleStep: (step: number) => () => void;
}

const useStyles = makeStyles((theme) => ({
  stepStyle: {
    '& >button>span>span>span>span.css-1kzwirt-MuiTypography-root-MuiStepLabel-label.Mui-active': {
      color: theme.palette.primary.main,
      fontWeight: '800',
    },
  },
  test: {
    color: 'GrayText',
  },
}));

const StepperPrimarySyllabus = ({ steps, activeStep, handleStep }: Props) => {
  const theme = useTheme();

  const classes = useStyles();

  const { isSubmitting, values } = useFormikContext<RegisterSyllabusData>();

  // Listado de steps invalidos
  const [invalidStep, setInvalidStep] = useState(() => new Set());
  // Función para determinar si existe algún step inválido
  const isStepFailed = (step: number) => invalidStep.has(step);

  // Se agrega un step inválido a la lista
  const handleSetInvalidStep = (index: number) => {
    setInvalidStep((prev) => new Set(prev).add(index));
  };
  // Se remueve un step inválido de la lista
  const handleRemoveInvalidStep = (index: number) => {
    setInvalidStep((prev) => {
      const unset = new Set(prev);
      unset.delete(index);
      return unset;
    });
  };

  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    if (isSubmitting) {
      const executeValitations = async () => {
        try {
          await GeneralDetailsSchema.validate(values)
            .then(() => {
              if (isStepFailed(0)) handleRemoveInvalidStep(0);
            })
            .catch((error) => {
              throw new Error('0');
            });
          await LessonPlanSchema.validate(values)
            .then(() => {
              if (isStepFailed(1)) handleRemoveInvalidStep(1);
            })
            .catch((error) => {
              throw new Error('1');
            });
          await BibliographySchema.validate(values)
            .then(() => {
              if (isStepFailed(2)) handleRemoveInvalidStep(2);
            })
            .catch((error) => {
              throw new Error('2');
            });
        } catch (error: any) {
          handleSetInvalidStep(Number(error.message));
        }
      };
      executeValitations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting]);

  return (
    <Grid item xs={10}>
      <Stepper
        nonLinear
        activeStep={activeStep}
        orientation={matchUpMd ? 'horizontal' : 'vertical'}
      >
        {steps.map(({ label, Icon }, index) => {
          const labelProps: {
            optional?: React.ReactNode;
            error?: boolean;
          } = {};
          if (isStepFailed(index)) {
            labelProps.optional = (
              <Typography variant="caption" color="error">
                Error
              </Typography>
            );
            labelProps.error = true;
          }

          return (
            <Step key={label} className={classes.test}>
              <StepButton
                color="inherit"
                onClick={handleStep(index)}
                icon={
                  <Icon
                    style={
                      index === activeStep
                        ? {
                            backgroundColor: theme.palette.primary.light,
                            borderRadius: '100%',
                            padding: '5px',
                            color: theme.palette.primary.main,
                          }
                        : {}
                    }
                  />
                }
              >
                <StepLabel {...labelProps}>{label}</StepLabel>
              </StepButton>
            </Step>
          );
        })}
      </Stepper>
    </Grid>
  );
};

export default StepperPrimarySyllabus;
