import {
    useHistory,
    Link,
    useRouteMatch
} from 'react-router-dom';
import {
    //Autocomplete,
    //Checkbox,
    //Button,
    Grid,
    //Typography,
    //TextField,
    /* TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Table,
    CircularProgress,
    FormHelperText,
    Collapse,
    Box,
    useTheme, */
    // FormControlLabel,
} from '@material-ui/core';
//import { useState } from 'react';
import EditButton from '../../../shared/components/tables/buttons/EditButton';
import DeleteButton from '../../../shared/components/tables/buttons/DeleteButton';
import AddButton from '../../../shared/components/tables/buttons/AddButton';

//import { openDeleteModalWith } from '../slices/booksSlice';
import useGuard from '../hooks/useGuard';

//import StatusChip from '../../../shared/components/badges/StatusChip';
import SearchPaginatedTable from '../../../shared/components/tables/SearchPaginatedTable';
//import { useAppDispatch } from '../../../../hooks/useRedux';

const FormsTable = () => {

    const { hasAccess } = useGuard();
    const { path } = useRouteMatch();
    const history = useHistory();

    const handleDelete = async (periodId: number) => {
        //dispatch(openDeleteModalWith(periodId));
    };

    const goToAdd = () => {
        history.push(`${path}/agregar`);
    };
    const data = [
        {
            id: '001',
            name: 'Admisiones Web',
            campaing: 'Extraordinario',
            status: 'Activo',
            options: ''
        },
    ]

    return (
        <>
            <Grid item xs={12}>
                <SearchPaginatedTable
                    data={data.flatMap(e => [e, e, e])}
                    headers={{
                        id: 'ID',
                        name: 'Nombres',
                        campaing: 'Campaña',
                        status: 'Estado',
                        options: 'Optiones'
                    }}
                    keyExtractor={(item) => item.id.toString()}
                    searchPlacehoder="Buscar lead"
                    isLoading={false}
                    isFetching={false}
                    error={undefined}
                    perPage={1}
                    setPerPage={() => { }}
                    total={0}
                    page={1}
                    setPage={() => { }}
                    numHeader={7}
                    search={""}
                    setSearch={() => { }}
                    customRenderers={{
                        // id: (row) => row.id.id,

                        //statusId: (row) => <StatusChip statusId={row.statusId} />,

                        options: (row) => (
                            <>
                                {hasAccess('edit') && (
                                    <Link to={`${path}/editar`}>
                                        <EditButton title="Editar" />
                                    </Link>
                                )}
                                {hasAccess('delete') && <DeleteButton handleDelete={() => handleDelete(1)} />}
                                {!hasAccess('edit') && !hasAccess('delete') && 'Ninguna'}
                            </>
                        ),
                    }}
                    ActionButtons={<>{hasAccess('create') && <AddButton size="large" onClick={goToAdd} />}</>}
                />
            </Grid>
        </>
    )
}

export default FormsTable;