import { FC, useEffect, useState } from 'react';

import { Button, CircularProgress, FormHelperText, Grid, TextField } from '@material-ui/core';
import { useFormik } from 'formik';

import InputLabel from '../../../../shared/components/forms/InputLabel';
import { EditSubjectScore } from '../../constants/validationSchemas';
import ErrorAlert from '../../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../../shared/components/alerts/SuccessAlert';
import { useAppSelector } from '../../../../../hooks/useRedux';
import { setExternalPreconfigSubjectScore } from '../../slices/homologationsSlice';
import { useDispatch } from 'react-redux';
import { Score } from '../../interfaces/payload.interface';
import { useTranslation } from 'react-i18next';

interface Props {
  onCancel: () => void;
}

const EditPreconfigSubject: FC<Props> = (props) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { onCancel } = props;

  const { externalPreconfigSubjectScore, preconfigSubjectTEdit } = useAppSelector(
    (state) => state.homologations
  );

  const preconfigId = preconfigSubjectTEdit ? (preconfigSubjectTEdit.id as unknown) : undefined;

  const filteredScore =
    preconfigId && externalPreconfigSubjectScore
      ? externalPreconfigSubjectScore[preconfigId as keyof Score]
      : undefined;

  const [showAlerts, setShowAlerts] = useState(true);
  const [status, setStatus] = useState<{ state: 'success' | 'error'; msg?: string }>();
  const [isLoading, setIsLoading] = useState(false);

  const { getFieldProps, errors, touched, handleSubmit } = useFormik({
    initialValues: {
      score: filteredScore ? filteredScore.score : 0.0,
      comment: filteredScore ? filteredScore.comment : '',
    },
    validationSchema: EditSubjectScore,
    onSubmit: async (values) => {
      setIsLoading(true);

      try {
        const scoreMapped: Score = externalPreconfigSubjectScore
          ? { ...externalPreconfigSubjectScore }
          : {};
        if (values.score !== 0 || values.comment !== '') {
          scoreMapped[preconfigId as keyof Score] = {
            id: preconfigId as number,
            score: values.score,
            comment: values.comment,
          };
        } else {
          throw new Error(t('academic.homologation.external.side.preconfig.error'));
        }

        dispatch(setExternalPreconfigSubjectScore(scoreMapped));

        setStatus({ state: 'success' });
        //   throw new Error('ha ocurridoo un error');
      } catch (error: unknown) {
        const { message } = error as Error;
        setStatus({ state: 'error', msg: message });
      }

      setShowAlerts(true);
    },
  });

  useEffect(() => {
    // if (status?.state === 'success') resetForm();
    setIsLoading(false);
  }, [status]);

  const handleClose = () => {
    // resetForm();
    onCancel();
  };

  return (
    <Grid container direction="column" gap={2}>
      <form onSubmit={handleSubmit}>
        <Grid item>
          {status?.state === 'error' && showAlerts && (
            <ErrorAlert
              message={status?.msg as string}
              handleDismiss={() => setShowAlerts(false)}
            />
          )}

          {status?.state === 'success' && showAlerts && (
            <SuccessAlert
              message={t('academic.homologation.external.side.preconfig.success')}
              handleDismiss={() => setShowAlerts(false)}
            />
          )}
        </Grid>

        <Grid item container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <InputLabel>{t('academic.homologation.external.side.preconfig.grade')}</InputLabel>
            <TextField
              fullWidth
              InputLabelProps={{ shrink: true }}
              type="number"
              InputProps={{ inputProps: { min: 0.0, max: 100.0, step: 0.1 } }}
              error={Boolean(errors.score && touched.score)}
              placeholder={t('academic.homologation.external.side.preconfig.grade.placeholder')}
              {...getFieldProps('score')}
            />
            {errors.score && touched.score && (
              <FormHelperText error>{t(errors.score)}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12}>
            <InputLabel>
              {t('academic.homologation.external.side.preconfig.observation')}
            </InputLabel>
            <TextField
              fullWidth
              multiline
              rows={3}
              error={Boolean(errors.comment && touched.comment)}
              placeholder={t('academic.homologation.external.side.preconfig.observation')}
              {...getFieldProps('comment')}
            />
            {errors.comment && touched.comment && (
              <FormHelperText error>{t(errors.comment)}</FormHelperText>
            )}
          </Grid>
        </Grid>

        <Grid
          item
          container
          alignItems="center"
          justifyContent="flex-end"
          spacing={2}
          sx={{ mt: 1 }}
        >
          <Grid item>
            <Button variant="outlined" size="large" color="primary" onClick={handleClose}>
              {t('academic.homologation.external.side.preconfig.cancel')}
            </Button>
          </Grid>

          <Grid item>
            <Button
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              disabled={isLoading}
              endIcon={isLoading && <CircularProgress size={20} />}
            >
              {t('academic.homologation.external.side.preconfig.add')}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

export default EditPreconfigSubject;
