import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import DeleteAlert from '../../../shared/components/dialogs/DeleteAlert';
import { useDeletePosgraduatePlanningDayTypeMutation } from '../slices/posgraduatePlanningDayTypesApiSlice';
import {
  setPosgraduatePlanningDayTypeDeleteDialogOpen,
  setPosgraduatePlanningDayTypeIdSelected,
} from '../slices/posgraduatePlanningDayTypesSlice';

const PostgraduatePlanningDayTypeDeleteDialog = () => {
  const dispatch = useAppDispatch();

  const { currentProfile } = useAppSelector((state) => state.access);

  const { posgraduatePlanningDayTypeIdSelected } = useAppSelector(
    (state) => state.posgraduatePlanningDayTypes
  );

  const [deleteDayType, { isLoading, isError, error, isSuccess }] =
    useDeletePosgraduatePlanningDayTypeMutation();

  const handleDelete = () => {
    deleteDayType({
      profileId: currentProfile?.profileId!,
      id: posgraduatePlanningDayTypeIdSelected!,
    });
  };

  const handleClose = () => {
    dispatch(setPosgraduatePlanningDayTypeDeleteDialogOpen(false));
    dispatch(setPosgraduatePlanningDayTypeIdSelected(null));
  };

  return (
    <DeleteAlert
      open
      handleDelete={handleDelete}
      onClose={handleClose}
      isLoading={isLoading}
      isError={isError}
      error={error as string}
      isSuccess={isSuccess}
    />
  );
};

export default PostgraduatePlanningDayTypeDeleteDialog;
