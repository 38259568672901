import { useRouteMatch, useHistory } from 'react-router-dom';

import {
  Autocomplete,
  TextField,
  Grid,
  Typography,
  // IconButton,
  // Tooltip,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  MenuList,
  MenuItem,
} from '@material-ui/core';

// import { IconList, IconArrowsRightLeft, IconLink } from '@tabler/icons';

// import EditButton from '../../../shared/components/tables/buttons/EditButton';
import DeleteButton from '../../../shared/components/tables/buttons/DeleteButton';
import AddButton from '../../../shared/components/tables/buttons/AddButton';
import MeshStatusChip from '../../../shared/components/badges/MeshStatusChip';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import {
  useGetCurriculumsQuery,
  useLazyGetAllCurriculumsFormatedQuery,
} from '../slices/meshsApiSlice';
import { useGetEducationLevelsParentsAndChildrenQuery } from '../../../maintenance/education-levels/slices/educationLevelsApiSlice';
import {
  fireDeleteCurriculumDialog,
  setCurriculumSelected,
  updateCurriculumTable,
} from '../slices/meshSlice';
import SearchPaginatedTable, {
  FilterArrow,
} from '../../../shared/components/tables/SearchPaginatedTable';

import { useTheme } from '@material-ui/core';

import useSharedGuard from '../../../../hooks/useSharedGuard';
import { subPermissionKeys } from '../constants/permissions';
import { subPermissionKeys as subPermissionKeysHomologation } from '../constants/permissionsIntHomologation';
import { useTableSearch } from '../../../../hooks/useTableSearch';
import { EducationLevel } from '../../education-levels/interfaces/educationLevels.interfaces';
import { useState } from 'react';
import { format } from 'date-fns';
import TextFieldFetching from '../../../shared/components/TextFieldFetching';
import { useGetAvaliblesMattersQuery } from '../../matters/slices/mattersApiSlice';
import SubCard from '../../../shared/components/cards/SubCard';
import LocalizedDatePicker from '../../../shared/components/controlled/LocalizedDatePicker';
import { useGetCatalogByAcronymQuery } from '../../../shared/slices/catalogApi';
import CustomPopper from '../../../shared/components/popper/CustomPopper';
import { MoreVert } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

const MeshsTable = () => {
  const history = useHistory();

  const { currentProfile } = useAppSelector((state) => state.access);

  const [parentEducationLevelId, setParentEducationLevelId] = useState('');

  const [childrenIdByParentId, setChildrenIdByParentId] = useState('');

  const [subjectIdByParentId, setSubjectIdByParentId] = useState('');

  const [mesYear, setMesYear] = useState<Date | null>(null);

  const [modality, setModality] = useState('');

  const [childrenByParent = [], setChildrenByParent] = useState<EducationLevel[]>([]);

  const dispatch = useAppDispatch();

  const { path } = useRouteMatch();

  const { t } = useTranslation();
  const theme = useTheme();

  const { page, perPage, search } = useAppSelector((state) => state.meshs.curriculumTable);

  const { setPage, setPerPage, setSearch, resetTable } = useTableSearch({
    page,
    perPage,
    search,
    updateFunction: updateCurriculumTable,
    otherFilters: {
      parentEducationLevelId,
      childrenIdByParentId,
      subjectIdByParentId,
      mesYear: mesYear ? format(mesYear, 'yyyy') : null,
      modality,
    },
  });

  const [filterArrow, setFilterArrow] = useState<FilterArrow>(null!);

  //Education Level Parent
  const {
    data: parentEducationLevel = [],
    isLoading: isLoadingPEL,
    isFetching: isFetchingPEL,
  } = useGetEducationLevelsParentsAndChildrenQuery({
    profileId: currentProfile?.profileId!,
    filter: true,
  });

  //Materias por facultad
  const {
    data: subjectsAvailables = [],
    isLoading: isLoadingSBA,
    isFetching: isFetchingSBA,
  } = useGetAvaliblesMattersQuery({
    profileId: currentProfile?.profileId!,
    educationLevelId: parentEducationLevelId,
  });

  const {
    isLoading,
    error,
    data: paginatedInfo,
    isFetching,
  } = useGetCurriculumsQuery(
    {
      page,
      perPage,
      search,
      ...(filterArrow && {
        typeSort: filterArrow?.direction,
        sort: filterArrow?.name,
      }),
      education_lvl_father_id: Number(parentEducationLevelId),
      education_lvl_id: Number(childrenIdByParentId),
      mes_year: mesYear ? Number(format(mesYear, 'yyyy') as unknown as number) : undefined,
      subject_id: Number(subjectIdByParentId),
      modalityId: modality,
    },
    { refetchOnMountOrArgChange: 30 }
  );

  const [getAllCurriculums] = useLazyGetAllCurriculumsFormatedQuery();

  const getAllDatafetch = async () => {
    try {
      const classroomsData = await getAllCurriculums(
        {
          education_lvl_father_id: Number(parentEducationLevelId),
          education_lvl_id: Number(childrenIdByParentId),
          mes_year: mesYear ? Number(format(mesYear, 'yyyy') as unknown as number) : undefined,
          subject_id: Number(subjectIdByParentId),
        },
        true
      ).unwrap();

      return classroomsData.map((c, index) => ({
        details: '',
        id: index + ((page - 1) * perPage + 1),
        name: c.mes_name,
        // educationLevel: c.education_level?.edu_name,
        CASRes: c.mes_res_cas,
        OCASRes: c.mes_res_ocas,
        numSubjects: c.mes_number_matter,
        numPeriods: c.mes_number_period,
        modality: c.cat_name,
        status: c.st_name,
        options: c,
      }));
    } catch (error) {
      return [];
    }
  };

  const handleDelete = async (meshId: number) => {
    dispatch(setCurriculumSelected(meshId));
    dispatch(fireDeleteCurriculumDialog(true));
  };

  const { isLoading: isLoadingModality, data: dataModality } = useGetCatalogByAcronymQuery({
    acronym: 'M',
  });

  const { hasAccess } = useSharedGuard(subPermissionKeys);
  const { hasAccess: hasAccessIntHom } = useSharedGuard(subPermissionKeysHomologation);

  const curriculumsData = paginatedInfo?.data || [];

  const curriculums = curriculumsData.map((c, index) => ({
    details: '',
    id: index + ((page - 1) * perPage + 1),
    name: c.mes_name,
    // educationLevel: c.education_level?.edu_name,
    CASRes: c.mes_res_cas,
    OCASRes: c.mes_res_ocas,
    numSubjects: c.mes_number_matter,
    numPeriods: c.mes_number_period,
    modality: c.modality?.cat_name,
    status: c.status,
    options: c,
  }));

  const renderCollapsibleItems = (index: number) => (
    <TableContainer>
      <SubCard
        sx={{
          mb: 2,
        }}
      >
        <Table>
          <TableHead>
            <TableRow hover sx={{ backgroundColor: theme.palette.grey[200] }}>
              <TableCell align="center">Descripción</TableCell>
              <TableCell align="center">Título</TableCell>
              <TableCell align="center">Itinerario</TableCell>
              <TableCell align="center">Fecha de creación</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow hover>
              <TableCell align="center">{curriculumsData?.[index]?.mes_description}</TableCell>
              <TableCell align="center">{curriculumsData?.[index]?.mes_title}</TableCell>
              <TableCell align="center">{curriculumsData?.[index]?.mes_itinerary}</TableCell>
              <TableCell align="center">{curriculumsData?.[index]?.mes_creation_date}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </SubCard>
    </TableContainer>
  );

  return (
    <SearchPaginatedTable
      data={curriculums}
      headers={{
        details: 'Detalles',
        id: 'N°',
        name: 'Pensum',
        // educationLevel: 'Unidad Académica',
        CASRes: 'Res. CES',
        OCASRes: 'Res. OCAS',
        numSubjects: 'N° Materias',
        numPeriods: 'N° Períodos',
        modality: 'Modalidad',
        status: 'Estado',
        options: 'Opciones',
      }}
      isLoading={isLoading}
      isFetching={isFetching}
      error={error}
      keyExtractor={(item) => item.id.toString()}
      numHeader={14}
      page={page}
      setPage={setPage}
      perPage={perPage}
      setPerPage={setPerPage}
      search={search}
      setSearch={setSearch}
      total={paginatedInfo?.total || 0}
      searchPlacehoder="Buscar malla curricular"
      listFilterArrows={{
        name: 'mes_name',
        // educationLevel: 'education_level',
        CASRes: 'mes_res_cas',
        OCASRes: 'mes_res_ocas',

        // educationLevel: 'edu_name',
        numSubjects: 'mes_number_matter',
        numPeriods: 'mes_number_period',
        // modality: 'modality',
        status: 'status_id',
      }}
      exportProps={{
        fileName: t('academic.classrooms.download_list'),
        files: { excel: true },
        // variant: 'buttons',
        // portalId: classroomExportPortalId,
        batch: true,
        allDataFetchHandler: getAllDatafetch as any,
      }}
      filterArrow={filterArrow}
      setFilterArrow={setFilterArrow}
      ActionButtons={<AddButton size="large" onClick={() => history.push(`${path}/agregar`)} />}
      customRenderers={{
        name: (item) => (
          <>
            <Typography>{item.name}</Typography>
            <Typography>
              <Typography component="span" sx={{ fontWeight: 500 }}>
                Programa:
              </Typography>{' '}
              {item.options.education_level?.edu_name}
            </Typography>
          </>
        ),

        options: ({ options: { id, link_curriculum }, status }) => (
          <>
            {/* Fix ID 7 == Vigente */}
            {/* {status.id !== 7 && hasAccess('edit') && (
              <EditButton handleEdit={() => history.push(`${path}/${id}/editar`)} />
            )}

            {hasAccess('getOne') && (
              <Tooltip title="Ver materias">
                <IconButton onClick={() => history.push(`${path}/${id}/asignar-materias`)}>
                  <IconList size={20} />
                </IconButton>
              </Tooltip>
            )}
            {hasAccessIntHom('read') && (
              <Tooltip title="Ver materias homologadas">
                <IconButton onClick={() => history.push(`${path}/${id}/materias-homologadas`)}>
                  <IconArrowsRightLeft size={20} style={{ color: theme.palette.primary.main }} />
                </IconButton>
              </Tooltip>
            )}

            {link_curriculum && (
              <Tooltip title="Url de la malla">
                <IconButton onClick={() => window.open(link_curriculum, '_blank')}>
                  <IconLink size={20} />
                </IconButton>
              </Tooltip>
            )} */}

            <CustomPopper iconButton={<MoreVert />} titleButton="Ver opciones">
              <MenuList id="split-button-menu" autoFocusItem>
                {hasAccess('getOne') && (
                  <MenuItem
                    key={'ver_materias'}
                    onClick={() => history.push(`${path}/${id}/asignar-materias`)}
                  >
                    Ver materias
                  </MenuItem>
                )}

                {hasAccessIntHom('read') && (
                  <MenuItem
                    key={'Ver materias homologadas'}
                    onClick={() => history.push(`${path}/${id}/materias-homologadas`)}
                  >
                    Ver materias homologadas
                  </MenuItem>
                )}

                {link_curriculum && (
                  <MenuItem
                    key={'ver_materias'}
                    onClick={() => window.open(link_curriculum, '_blank')}
                  >
                    Ver malla
                  </MenuItem>
                )}

                {status.id !== 7 && hasAccess('edit') && (
                  <MenuItem key={'editar'} onClick={() => history.push(`${path}/${id}/editar`)}>
                    Editar
                  </MenuItem>
                )}
              </MenuList>
            </CustomPopper>

            {hasAccess('delete') && <DeleteButton handleDelete={() => handleDelete(id)} />}
          </>
        ),
        status: ({ status }) => <MeshStatusChip statusName={status?.st_name || ''} />,
      }}
      customHeadersCellsProperties={{
        options: {
          align: 'center',
        },
      }}
      customDataCellsProperties={{
        CASRes: { align: 'center' },
        OCASRes: { align: 'center' },
        numSubjects: { align: 'center' },
        numPeriods: { align: 'center' },
        modality: { align: 'center' },
        status: { align: 'center' },
        options: {
          width: '15%',
          align: 'center',
        },
      }}
      collapsible={{
        collapsibleBtnColl: 0,
        Rows: renderCollapsibleItems,
      }}
      additionalFilters={
        <Grid container alignItems={'baseline'} spacing={1}>
          {/*  filter by parent of education level */}
          <Grid item xs={12} md={3}>
            {isFetchingPEL && <TextFieldFetching />}
            {!isFetchingPEL && (
              <Autocomplete
                options={parentEducationLevel}
                loading={isLoadingPEL}
                loadingText="Cargando unidades académicas y programas..."
                getOptionLabel={(option) => option.edu_name}
                onChange={(_, value) => {
                  resetTable();
                  setParentEducationLevelId(value ? value.id.toString() : '');
                  setChildrenByParent(value ? value.children : []);
                  setChildrenIdByParentId('');
                  setSubjectIdByParentId('');
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Unidades académicas y Programas"
                    placeholder="Seleccione una unidad académica o programa"
                    fullWidth
                  />
                )}
              />
            )}
          </Grid>

          {/*  filter by childreen of parent */}
          <Grid item xs={12} md={3}>
            {isLoadingPEL && <TextFieldFetching />}
            {!isLoadingPEL && (
              <Autocomplete
                options={childrenByParent}
                inputValue={
                  childrenByParent.find((dt) => dt.id === Number(childrenIdByParentId))?.edu_name ||
                  ''
                }
                loading={isLoadingPEL}
                loadingText="Cargando Carreras Académicas..."
                getOptionLabel={(option) => option.edu_name}
                onChange={(_, value) => {
                  resetTable();
                  setChildrenIdByParentId(value ? value.id.toString() : '');
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Carreras y programas"
                    placeholder="Seleccione una unidad académica o programa"
                    fullWidth
                  />
                )}
              />
            )}
          </Grid>

          {/* Filter by year */}
          <Grid item xs={12} md={3}>
            <LocalizedDatePicker
              views={['year']}
              label="Año"
              maxDate={new Date()}
              value={mesYear}
              onChange={(newValue) => {
                resetTable();
                setMesYear(newValue as Date);
              }}
              placeholder="Seleccione un año"
            />
          </Grid>

          {/* Filter by subject */}
          <Grid item xs={12} md={3}>
            {isFetchingSBA && <TextFieldFetching />}
            {!isFetchingSBA && (
              <Autocomplete
                options={subjectsAvailables}
                loading={isLoadingSBA}
                loadingText="Cargando materias/módulos..."
                getOptionLabel={(option) => option.mat_name}
                onChange={(_, value) => {
                  resetTable();
                  setSubjectIdByParentId(value ? value.id.toString() : '');
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Materia/Módulo"
                    placeholder="Seleccione una materia/módulo"
                    fullWidth
                  />
                )}
              />
            )}
          </Grid>

          {/* filter by modality class */}
          <Grid item xs={12} md={3}>
            {isLoadingModality && <TextFieldFetching />}
            {!isLoadingModality && (
              <Autocomplete
                options={dataModality || []}
                loading={isLoadingModality}
                loadingText="Cargando modalidades..."
                getOptionLabel={(option) => option.cat_name}
                onChange={(_, value) => {
                  resetTable();
                  setModality(value ? value.id.toString() : '');
                }}
                value={dataModality?.find(({ id }) => id.toString() === modality.toString())}
                renderInput={(params) => (
                  <TextField {...params} label="Modalidad" placeholder="modalidad" />
                )}
                fullWidth
              />
            )}
          </Grid>
        </Grid>
      }
    />
  );
};

export default MeshsTable;
