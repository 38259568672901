import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISearchTable } from '../../../shared/interfaces/paginatedTable.interface';
import { CalificationModel } from '../interfaces/calificationModels.interface';

interface CalificationModelsState {
  calificationModelsList: CalificationModel[];
  deleteModalOpen: boolean;
  calificationModelIdToDelete?: number;
  calificationModelTable: ISearchTable;
}

const initialState: CalificationModelsState = {
  calificationModelsList: [],
  deleteModalOpen: false,
  calificationModelTable: {
    page: 1,
    perPage: 5,
    search: '',
  },
};

const CalificationModelsSlice = createSlice({
  name: 'CalificationModels',
  initialState,
  reducers: {
    setCalificationModelList: (state, action: PayloadAction<CalificationModel[]>) => {
      state.calificationModelsList = action.payload;
    },
    setDeleteModalOpen: (state, action: PayloadAction<boolean>) => {
      state.deleteModalOpen = action.payload;
    },
    openDeleteModalWith: (state, action: PayloadAction<number>) => {
      state.calificationModelIdToDelete = action.payload;
      state.deleteModalOpen = true;
    },
    resetCalificationModelIdToDelete: (state) => {
      state.calificationModelIdToDelete = undefined;
    },
    updateCalificationModelTableStates: (state, action: PayloadAction<ISearchTable>) => {
      state.calificationModelTable = action.payload;
    },
  },
});

export const {
  setCalificationModelList,
  setDeleteModalOpen,
  openDeleteModalWith,
  resetCalificationModelIdToDelete,
  updateCalificationModelTableStates,
} = CalificationModelsSlice.actions;

export default CalificationModelsSlice.reducer;
