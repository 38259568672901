import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/core/Skeleton';
import { useParams } from 'react-router-dom';

import { useGetProjectQuery } from './../../../maintenance/lines-of-investigation/slices/InvestigationLinesApiSlice';
// import {} from

const InfoProjectTask = () => {
  const { projectId } = useParams<{ projectId: string }>();

  const { data, isLoading } = useGetProjectQuery({ projectId });
  return (
    <Grid container>
      <Typography variant="h3">
        {isLoading ? <Skeleton variant="text" width="50vw" height="50px" /> : data?.name}
      </Typography>
    </Grid>
  );
};

export default InfoProjectTask;
