import {
  Autocomplete,
  Button,
  CircularProgress,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  Switch,
  TextField,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useAppSelector } from '../../../../hooks/useRedux';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import { statusAvailables } from '../../../shared/constants/resourceStatus';
import { useFormStyles } from '../constants/styles';
import { MatterTypeInfoSchema } from '../constants/validationSchemas';
import { useUpdateMatterTypeMutation } from '../slices/mattersTypesApiSlice';
import { useGetCalificationTypesQuery } from '../../califications-types/slices/calificationsTypesApiSlice';
import TextFieldFetching from '../../../shared/components/TextFieldFetching';
import { useGetAvailablesOffersQuery } from '../../academic-offerings/slices/academicOfferingsApiSlice';

const EditMatterTypeForm = () => {
  const { currentProfile } = useAppSelector((state) => state.access);
  const { mattersTypesList } = useAppSelector((state) => state.mattersTypes);
  const classes = useFormStyles();
  const [showAlerts, setShowAlerts] = useState(false);

  const history = useHistory();

  const { matterTypeId } = useParams<{ matterTypeId: string }>();

  const [updateMatterType, { isLoading: isUpdating, isSuccess, isError, error }] =
    useUpdateMatterTypeMutation();

  const { data: calificationTypesAvailable = [], isLoading: isLoadingCalificationList } =
    useGetCalificationTypesQuery({});

  //niveles eduactivos
  const { data: offersAvailables, isFetching: isFetchingPID } = useGetAvailablesOffersQuery({});

  const matterTypeToEdit = mattersTypesList.find((mt) => mt.id === Number(matterTypeId));

  const { getFieldProps, errors, touched, handleSubmit, setFieldValue, values } = useFormik({
    initialValues: {
      name: matterTypeToEdit?.tm_name || '',
      acronym: matterTypeToEdit?.tm_acronym || '',
      description: matterTypeToEdit?.tm_description || '',
      offId: Number(matterTypeToEdit?.offer_id) || 0,
      order: matterTypeToEdit?.tm_order || 1,
      cobro: Number(matterTypeToEdit?.tm_cobro) || 0,
      matterCount: Number(matterTypeToEdit?.tm_matter_count) || 0,
      registerStudent: Number(matterTypeToEdit?.register_student) || 0,
      registerAdministrator: Number(matterTypeToEdit?.register_administrator) || 0,
      statusId: matterTypeToEdit?.status_id || 0,
      calificationTypeId: Number(matterTypeToEdit?.type_calification_id) || null!,
    },
    validationSchema: MatterTypeInfoSchema,
    onSubmit: async (values) => {
      values.cobro = state.checkedCobro ? 1 : 0;
      values.matterCount = state.checkedMatterCount ? 1 : 0;
      values.registerStudent = state.checkedRegisterStudent ? 1 : 0;
      values.registerAdministrator = state.checkedRegisterAdministrator ? 1 : 0;
      await updateMatterType({
        profileId: currentProfile?.profileId!,
        matterTypeId: matterTypeToEdit?.id!,
        matterTypePayload: {
          tm_name: values.name,
          tm_description: values.description,
          tm_acronym: values.acronym,
          tm_order: Number(values.order),
          tm_cobro: Number(values.cobro),
          tm_matter_count: Number(values.matterCount),
          register_student: Number(values.registerStudent),
          register_administrator: Number(values.registerAdministrator),
          status_id: Number(values.statusId),
          offer_id: values.offId.toString(),
          type_calification_id: values.calificationTypeId,
        },
      });

      setShowAlerts(true);
    },
  });

  const [state, setState] = useState({
    checkedCobro: matterTypeToEdit?.tm_cobro ? !!Number(matterTypeToEdit?.tm_cobro) : false,
    checkedMatterCount: matterTypeToEdit?.tm_matter_count
      ? !!Number(matterTypeToEdit?.tm_matter_count)
      : false,
    checkedState:
      matterTypeToEdit?.status_id && matterTypeToEdit?.status_id === '1'
        ? Boolean(matterTypeToEdit?.status_id)
        : false,
    checkedRegisterStudent:
      matterTypeToEdit?.register_student && matterTypeToEdit?.register_student === '1'
        ? true
        : false,
    checkedRegisterAdministrator:
      matterTypeToEdit?.register_administrator && matterTypeToEdit?.register_administrator === '1'
        ? true
        : false,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction="column" gap={2}>
        <Grid item>
          {isError && showAlerts && (
            <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
          )}

          {isSuccess && showAlerts && (
            <SuccessAlert
              message="El tipo de materia ha sido actualizado correctamente."
              handleDismiss={() => setShowAlerts(false)}
            />
          )}
        </Grid>

        <Grid item container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>Nombre</InputLabel>
            <TextField
              fullWidth
              error={Boolean(errors.name && touched.name)}
              placeholder="Ingrese el nombre del tipo de materia"
              {...getFieldProps('name')}
            />
            {errors.name && touched.name && <FormHelperText error>{errors.name}</FormHelperText>}
          </Grid>

          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>Acrónimo</InputLabel>
            <TextField
              fullWidth
              error={Boolean(errors.acronym && touched.acronym)}
              placeholder="Ingrese el acrónimo del tipo de materia"
              {...getFieldProps('acronym')}
            />
            {errors.acronym && touched.acronym && (
              <FormHelperText error>{errors.acronym}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>Descripción</InputLabel>
            <TextField
              placeholder="Ingrese la descripción del tipo de materia"
              multiline
              rows={2}
              fullWidth
              {...getFieldProps('description')}
            />
          </Grid>

          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>Oferta</InputLabel>
            {isFetchingPID ? (
              <TextFieldFetching />
            ) : (
              <Autocomplete
                options={offersAvailables || []}
                noOptionsText={'No hay resultados'}
                getOptionLabel={(option) => option.off_name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Seleccione la oferta"
                    error={Boolean(errors.offId && touched.offId)}
                  />
                )}
                value={offersAvailables?.find((dt) => dt.id === values.offId) || null}
                onChange={(e, value) => setFieldValue('offId', value?.id || '')}
                fullWidth
                clearIcon={null}
              />
            )}
            {errors.offId && touched.offId && <FormHelperText error>{errors.offId}</FormHelperText>}
          </Grid>

          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>Tipo de calificación</InputLabel>
            {isLoadingCalificationList ? (
              <TextFieldFetching />
            ) : (
              <Autocomplete
                options={calificationTypesAvailable}
                loading={isLoadingCalificationList}
                noOptionsText={'No hay resultados'}
                loadingText={'Cargando tipo de calificaciones...'}
                getOptionLabel={(option) => option.tc_name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Seleccione el tipo de calificación"
                    error={Boolean(errors.calificationTypeId && touched.calificationTypeId)}
                  />
                )}
                value={calificationTypesAvailable.find(
                  (calificationType) => calificationType.id === values.calificationTypeId
                )}
                onChange={(e, value) => setFieldValue('calificationTypeId', value?.id || '')}
                fullWidth
                clearIcon={null}
              />
            )}
            {errors.calificationTypeId && touched.calificationTypeId && (
              <FormHelperText error>{errors.calificationTypeId}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>Orden</InputLabel>
            <TextField
              {...getFieldProps('order')}
              error={Boolean(errors.order && touched.order)}
              placeholder="Ingrese el orden del tipo de materia"
              fullWidth
              type={'number'}
            />
            {errors.order && touched.order && <FormHelperText error>{errors.order}</FormHelperText>}
          </Grid>

          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>Estado</InputLabel>
            <Autocomplete
              options={statusAvailables}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField {...params} error={Boolean(errors.statusId && touched.statusId)} />
              )}
              defaultValue={statusAvailables[0]}
              onChange={(e, value) => setFieldValue('statusId', value?.id || '')}
              fullWidth
              clearIcon={null}
            />
            {errors.statusId && touched.statusId && (
              <FormHelperText error>{errors.statusId}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12}>
            <Grid container item xs={12}>
              <Grid item>
                <FormControlLabel
                  control={
                    <Switch
                      aria-label="cambiar si cobra"
                      checked={state.checkedCobro}
                      onChange={handleChange}
                      name="checkedCobro"
                      color="primary"
                    />
                  }
                  label={state.checkedCobro ? 'Cobra' : 'No Cobra'}
                />
              </Grid>

              <Grid item>
                <FormControlLabel
                  control={
                    <Switch
                      aria-label="cambiar si cuenta"
                      checked={state.checkedMatterCount}
                      onChange={handleChange}
                      name="checkedMatterCount"
                      color="primary"
                    />
                  }
                  label={state.checkedMatterCount ? 'Cuenta' : 'No cuenta'}
                />
              </Grid>

              <Grid item>
                <FormControlLabel
                  control={
                    <Switch
                      aria-label="cambiar si registra estudiante"
                      checked={state.checkedRegisterStudent}
                      onChange={handleChange}
                      name="checkedRegisterStudent"
                      color="primary"
                    />
                  }
                  label={
                    state.checkedRegisterStudent
                      ? 'Permite registrar estudiante'
                      : 'No permite registrar estudiante'
                  }
                />
              </Grid>

              <Grid item>
                <FormControlLabel
                  control={
                    <Switch
                      aria-label="cambiar si registra administrativo"
                      checked={state.checkedRegisterAdministrator}
                      onChange={handleChange}
                      name="checkedRegisterAdministrator"
                      color="primary"
                    />
                  }
                  label={
                    state.checkedRegisterAdministrator
                      ? 'Permite registrar administrativo'
                      : 'No permite registrar administrativo'
                  }
                />
              </Grid>
            </Grid>

            {errors.statusId && touched.statusId && (
              <FormHelperText error>{errors.statusId}</FormHelperText>
            )}
          </Grid>

          <Grid
            item
            container
            alignItems="center"
            justifyContent="flex-end"
            spacing={2}
            sx={{ mt: 1 }}
          >
            <Grid item>
              <Button
                variant="outlined"
                size="large"
                color="primary"
                onClick={() => history.goBack()}
              >
                Regresar
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                size="large"
                color="primary"
                disabled={isUpdating}
                endIcon={isUpdating && <CircularProgress size={20} />}
              >
                Actualizar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default EditMatterTypeForm;
