import { Grid } from '@material-ui/core';
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import DialogImg from '../../../../shared/components/dialogs/DialogImg';
import DeleteCommentDialog from '../../../../teachers/virtual-classroom/forum/screens/DeleteCommentDialog';
import Transitions from '../../../../shared/components/extended/Transitions';
import { setShowImageModalOpen } from '../../../../teachers/virtual-classroom/slices/virtualClassroomTeacherSlice';
import ForumContent from './Forum/ForumContent';
import ForumList from './Forum/ForumList';

const MatterDetailForum = () => {
  const dispatch = useAppDispatch();

  const [isForumView, setIsForumView] = useState<boolean>(false);
  const [forumId, setForumId] = useState(0);

  const { commentIdToDelete, imgModalOpen, imgNameToShow } = useAppSelector(
    (state) => state.virtualClassroomTeacher
  );

  const goToForumView = () => {
    isForumView ? setIsForumView(false) : setIsForumView(true);
  };

  return (
    <Grid container mt={2} spacing={2}>
      <Grid item xs={isForumView ? 3 : 12}>
        <ForumList goToForumView={goToForumView} setForumId={setForumId} />
      </Grid>
      {isForumView && forumId !== 0 && (
        <Grid item xs={9}>
          <Transitions type="slide" in={isForumView} position="top-left" direction={'left'}>
            <ForumContent goToForumView={goToForumView} forumId={forumId} />
          </Transitions>
        </Grid>
      )}
      {commentIdToDelete && <DeleteCommentDialog />}
      {imgModalOpen && (
        <DialogImg
          open={imgModalOpen}
          img={imgNameToShow!}
          onClose={() => dispatch(setShowImageModalOpen(false))}
        />
      )}
    </Grid>
  );
};
export default MatterDetailForum;
