import { Grid, Typography } from '@material-ui/core';
import MainCard from '../../../shared/components/cards/MainCard';
import RecoveryExamForm from '../components/RecoveryExamForm';

import { useTranslation } from 'react-i18next';

const RecoveryExamScreen = () => {
  const { t } = useTranslation();
  
  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">
          {t('students.menu.applications.academic.exam-recovery')}
        </Typography>
      </Grid>
      <Grid item xs={12} md={8} lg={6} sx={{ mt: 2, minHeight: '55vh' }}>
        <RecoveryExamForm />
      </Grid>
    </Grid>
  );
};
export default RecoveryExamScreen;
