import { Box, Button, Grid, Typography, useTheme } from '@material-ui/core';
import { useAppSelector } from '../../../../../hooks/useRedux';

interface Props {
  totalAmount?: number;
  alternativeText?: string;
  handleCLick?: () => void;
}
const BottomSticky = ({ totalAmount, alternativeText, handleCLick }: Props) => {
  const theme = useTheme();

  const defaultConfiguration = useAppSelector((state) => state.access.defaultConfiguration);

  return (
    <>
      {totalAmount ? (
        <Box
          sx={{
            position: 'sticky',
            bottom: '20px',
            width: '100%',
            paddingX: 3,
            zIndex: 100,
          }}
        >
          <Grid
            container
            color={'white'}
            // spacing={2}
            sx={{
              border: `1px solid ${theme.palette.primary.main}`,
              backgroundColor: theme.palette.primary.main,
              borderRadius: '12px',
              padding: 2,
            }}
          >
            <Grid container justifyContent={'flex-end'} alignItems={'center'} spacing={2}>
              <Grid item>
                <Typography>{alternativeText ? alternativeText : 'Total Seleccionado'}</Typography>
              </Grid>
              <Grid item>
                <Typography>
                  {totalAmount ? `${defaultConfiguration?.currency_symbol} ${totalAmount}` : 0}
                </Typography>
              </Grid>
              {!!handleCLick && (
                <Grid item>
                  <Button
                    style={{
                      background: 'white',
                      // background: theme.palette.dark.light,
                      borderColor: '#EDE7F6',
                      // color: 'white',
                    }}
                    onClick={handleCLick}
                  >
                    Continuar
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Grid></Grid>
      )}
    </>
  );
};

export default BottomSticky;
