import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../../../hooks/useRedux';
import MainCard from '../../../../../shared/components/cards/MainCard';
import OptionsTabScale from '../components/OptionsTabScale';
import OptionsTemplateDeleteDialog from '../components/OptionsTemplateDeleteDialog';
import RepresentativeScaleDeleteDialog from '../components/RepresentativeScaleDeleteDialog';

const OptionsTemplateScreen = () => {
  const { t } = useTranslation();
  const { optionsTemplatesDeleteModalOpen, representativeDeleteModalOpen } = useAppSelector(
    (state) => state.optionsTemplate
  );

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">{t('maintenance.options_template.title')}</Typography>
        <Typography variant="body1" color="textSecondary">
          {t('Las escalas permiten responder la rúbrica de forma cuantitativa y representativa ')}
        </Typography>
      </Grid>
      <OptionsTabScale />

      {optionsTemplatesDeleteModalOpen && <OptionsTemplateDeleteDialog />}
      {representativeDeleteModalOpen && <RepresentativeScaleDeleteDialog />}
    </Grid>
  );
};

export default OptionsTemplateScreen;
