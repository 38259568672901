export const studentsPermissions = {
  groupKey: 'students',

  getDebstByStudents: 'debts-student-listar-deudas-estudiante',
};

export const cashPointPermissions = {
  groupKey: 'cashPoint',

  openCashPoint: 'cash-point-store',
};

export const debtorsPermissions = {
  groupKey: 'debtors',

  getDebtorPerStudent: 'debtors-obtener-deudor-student',
};

export const cashCollectionPermissions = {
  groupKey: 'cashCollection',

  storeCashCollection: 'cash-collection-store',
  getPositiveBalance: 'cash-collection-forma-pago',
};

export const financialInstitutions = {
  groupKey: 'financialInstitutions',

  getFinancialInstituteCards:
    'financial-institutions-obtener-cuentas-asocidas-intitucion-financiera-agrupadas-cards',
};
