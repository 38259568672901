import { useEffect, useState } from 'react';

import {
  Grid,
  Autocomplete,
  //Stack,
  TextField,
  IconButton,
  Tooltip,
  //Checkbox,
  //FormControlLabel,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { IconPlus } from '@tabler/icons';
import { useRouteMatch } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
//import { useGetPeriodsQuery } from '../../periods/slices/periodsApiSlice';
import { useGetAvailablesCareersByOfferQuery } from '../../academic-offerings/slices/academicOfferingsApiSlice';
import {
  useGetStudentsNivelationQuery,
  //useGetPeriodsNivelationQuery,
} from '../slices/registerNivelationApiSlice';
import SearchPaginatedTable, {
  FilterArrow,
} from '../../../shared/components/tables/SearchPaginatedTable';
import { setPageRegNivelation, setSizeRegNivelation } from '../slices/registerNivelationSlice';
import Chip from '../../../shared/components/extended/Chip';
import { statusTable } from '../constants';
//import SkeletonList from './skeletons/SkeletonList';

const ListStudentsNivelation = () => {
  const history = useHistory();

  const { currentProfile } = useAppSelector((state) => state.access);
  const dispatch = useAppDispatch();
  const { page: pageReducer, size: sizeReducer } = useAppSelector(
    (state) => state.registerNivelation
  );

  const [page, setPage] = useState(pageReducer);
  const [search, setSearch] = useState('');
  const [perPage, setPerPage] = useState(sizeReducer);
  const [filterArrow, setFilterArrow] = useState<FilterArrow>(null!);

  //const [periodId, setPeriodId] = useState<number>();
  const [careerId, setCareerId] = useState<number>(0); //2 nivelacion

  useEffect(() => {
    dispatch(setPageRegNivelation(page));
    dispatch(setSizeRegNivelation(perPage));
    //eslint-disable-next-line
  }, [page, perPage, dispatch, setPageRegNivelation, setSizeRegNivelation]);

  const { path } = useRouteMatch();

  //periodos
  // const {
  //   data: periods,
  //   isLoading: loadingPeriods,
  //   isFetching: fetchingPeriods,
  // } = useGetPeriodsNivelationQuery(
  //   {
  //     dataParam: 'all',
  //     ...(!checked && { isVigent: 1 }),
  //   },
  //   { refetchOnMountOrArgChange: 30 }
  // );

  //carreras disponibles
  const { data: careersAvailables = [], isLoading: loadingCareers } =
    useGetAvailablesCareersByOfferQuery(
      {
        profileId: currentProfile?.profileId!,
        academicOfferingId: 2,
      },
      { refetchOnMountOrArgChange: 30 }
    );

  //Students Nivelation List
  const {
    data: studentsNivelation,
    isLoading: loadingSN,
    isFetching: fetchingSN,
    error,
  } = useGetStudentsNivelationQuery(
    {
      profileId: currentProfile?.profileId!,
      //educationLevel: Number(careerId!) || 2,
      educationLevel: careerId,
      search: search,
      size: perPage!,
      page: page,
      ...(filterArrow && { sort: filterArrow.name, type_sort: filterArrow.direction }),
    }
    //{ skip: !careerId }
  );
  const headers = {
    id: 'N°',
    codigo: 'Código',
    identification: 'Identificación',
    nombres: 'Nombres',
    apellidos: 'Apellidos',
    isPayment: 'Estado de pago',
    options: 'Opciones',
  };
  const datafetch = studentsNivelation?.data!.map((value, index) => ({
    //id: value.user.student.student_records.id,
    id: Number(studentsNivelation?.from) + index,
    codigo: value.user.student.stud_code,
    identification: value.pers_identification,
    nombres: value.pers_firstname + ' ' + (value?.pers_secondname! || ''),
    apellidos: value.pers_first_lastname + ' ' + (value?.pers_second_lastname! || ''),
    isPayment: value.status_payment,
    options: value,
  }));

  return (
    <>
      <Grid container>
        {/* <Grid item sm={12} md={6} sx={{ pr: 1, mb: 1 }}>
          <Stack direction="row" spacing={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked}
                  onChange={handleChange}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label="No Vigentes"
              // labelPlacement="start"
            />

            <Autocomplete
              // disabled={!checked}
              options={!fetchingPeriods ? periods! || [] : []}
              loading={fetchingPeriods}
              loadingText="Cargando periodos..."
              getOptionLabel={(option) => option.per_name}
              renderInput={(params) => <TextField {...params} label="Periodos" />}
              value={periods?.find((dt) => dt.id === Number(periodId)) || null}
              onChange={(_, value) => {
                setPeriodId(value?.id);
              }}
              // clearIcon={null}
              fullWidth
            />
          </Stack>
        </Grid> */}

        <Grid item sm={12} md={12} sx={{ pt: 1 }}>
          <SearchPaginatedTable
            data={datafetch || []}
            headers={headers}
            keyExtractor={(item) => item.id.toString()}
            searchPlacehoder="Buscar estudiante"
            listFilterArrows={{
              nombres: 'pers_firstname',
              apellidos: 'pers_first_lastname',
            }}
            filterArrow={filterArrow}
            setFilterArrow={setFilterArrow}
            isLoading={loadingSN}
            isFetching={fetchingSN}
            error={error}
            perPage={perPage}
            setPerPage={setPerPage}
            total={studentsNivelation?.total || 0}
            page={page}
            setPage={setPage}
            numHeader={7}
            search={search}
            setSearch={setSearch}
            customRenderers={{
              isPayment: (row) => (
                <Chip
                  label={row.isPayment}
                  chipcolor={
                    row.isPayment.toLowerCase() === statusTable.notPay
                      ? 'error'
                      : row.isPayment.toLowerCase() === statusTable.pay
                      ? 'success'
                      : 'info'
                  }
                />
              ),
              options: (row) => (
                <>
                  {/* <Link to={}> */}
                  <Tooltip title="Registar">
                    <IconButton
                      aria-label="Ver etapas"
                      color="primary"
                      disabled={row.isPayment.toLowerCase() === statusTable.notPay}
                      onClick={() => {
                        //setStudentId(row.options.user.student.student_records.id);
                        //setPeriodId(row.options.user.student.student_records.student_records_period_fees.period.id);
                        history.push(
                          `${path}/${studentsNivelation?.data.find(
                            (st, index) => index === row.id - Number(studentsNivelation?.from)
                          )?.user.student.student_records.id!}/registrar/${
                            row.options.user.student.student_records.student_records_period_fees
                              .period.id
                          }`
                        );
                      }}
                    >
                      <IconPlus size={20} />
                    </IconButton>
                  </Tooltip>
                  {/* </Link> */}
                </>
              ),
            }}
            customHeadersCellsProperties={{
              options: {
                align: 'center',
              },
              id: {
                align: 'center',
              },
            }}
            customDataCellsProperties={{
              id: {
                align: 'center',
              },
              codigo: {
                align: 'center',
              },
              identification: {
                align: 'center',
              },
              nombres: {
                align: 'center',
              },
              apellidos: {
                align: 'center',
              },
              isPayment: {
                align: 'center',
              },
              options: {
                align: 'center',
              },
            }}
            additionalFilters={
              <Grid container sm={12} md={12} spacing={2}>
                <Grid item sm={12} md={4} sx={{ mb: 1 }}>
                  <Autocomplete
                    options={careersAvailables!}
                    loading={loadingCareers}
                    loadingText="Cargando carreras..."
                    getOptionLabel={(option) => option.edu_name}
                    renderInput={(params) => (
                      <TextField {...params} label="Carrera" placeholder="Seleccione carrera..." />
                    )}
                    defaultValue={careersAvailables?.find((p) => p.id === Number(careerId) || '')}
                    onChange={(_, value) => {
                      if (value) {
                        setCareerId(Number(value.id));
                      } else {
                        setCareerId(0);
                      }
                    }}
                    fullWidth
                  />
                </Grid>
              </Grid>
            }
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ListStudentsNivelation;
