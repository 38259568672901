import { Checkbox, CircularProgress, Grid, Button, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../../../hooks/useRedux';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
//import SimpleTable from '../../../maintenance/periods/components/SimpleTable';
import SearchPaginatedTable from '../../../shared/components/tables/SearchPaginatedTable';
import { useGetLendingsDetailsQuery, useReturnLendingMutation } from '../slices/lendingApiSlice';
import { setLendingsSelected } from '../slices/lendingsSlice';

const LendingsDetails = () => {
  const { id } = useParams<{ id: string }>();
  const [checkAll, setCheckALl] = useState(false);
  const [update, setUpdate] = useState(false);
  const { lendingsSelecteds } = useAppSelector((state) => state.lendings);
  const dispatch = useAppDispatch();

  const { data, isLoading, isError, error, isSuccess } = useGetLendingsDetailsQuery({
    borrow_code: id!,
  });

  const [
    returnlendings,
    {
      isLoading: isLoadingReturn,
      isError: isErrorReturn,
      error: errorReturn,
      isSuccess: isSuccessReturn,
      reset,
    },
  ] = useReturnLendingMutation();

  const handleReturn = async () => {
    let selecteds: number[] = [];
    lendingsSelecteds.forEach((ld, index) => {
      if (ld === true) {
        selecteds.push(data!.data[index].id);
      }
    });

    returnlendings({
      borrows: selecteds,
      return_date: new Date().toISOString().split('T')[0],
    });
  };

  useEffect(() => {
    if (isSuccess && data && data.data.length > 0) {
      dispatch(setLendingsSelected(data.data.map((dt) => false)));
    }
  }, [isSuccess, data, dispatch]);

  useEffect(() => {
    setUpdate(true);
    setTimeout(() => {
      setUpdate(false);
    }, 50);
    if (lendingsSelecteds.every((el) => el === true)) {
      setCheckALl(true);
    }
  }, [lendingsSelecteds]);

  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <CircularProgress size={50} />
      </div>
    );
  }

  return (
    <div>
      <Grid container>
        <Grid item xs={12} md={12} sx={{ mb: 2 }}>
          {isErrorReturn && <ErrorAlert message={errorReturn as string} handleDismiss={reset} />}

          {isSuccessReturn && <SuccessAlert message="Devolución exitosa." handleDismiss={reset} />}
        </Grid>
        <Grid
          item
          container
          xs={12}
          md={12}
          justifyContent="space-between"
          alignItems="center"
          sx={{ border: 1, borderRadius: '1rem', p: 2 }}
        >
          <Grid item>
            <Typography>
              {[...lendingsSelecteds.filter((el) => el === true)].length}&nbsp;Seleccionados
            </Typography>
          </Grid>
          <Grid item>
            <Button
              disabled={
                [...lendingsSelecteds.filter((el) => el === true)].length === 0
                  ? true
                  : false || isLoadingReturn
              }
              endIcon={isLoadingReturn && <CircularProgress size={20} />}
              variant="contained"
              onClick={handleReturn}
            >
              Devolver
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12}>
          <SearchPaginatedTable
            showFilter={false}
            paginationBox={false}
            perPageBox={false}
            data={
              data?.data.map((dt, index) => ({
                chck: { data: dt, index: index },
                id: dt.id,
                code: dt.borrow_code,
                title: dt.book.title,
                available_copies: dt.book.available_copies_number,
                total_copies: dt.book.initial_copies_number,
                borrow_date: dt.borrow_date,
                return_date: dt.return_date,
              })) || []
            }
            headers={{
              chck: (
                <Checkbox
                  color="primary"
                  disabled={data?.data.some((dt) => dt.return_date !== null)}
                  indeterminate={lendingsSelecteds.length > data?.data.length! ? true : false}
                  checked={checkAll && lendingsSelecteds.every((el) => el === true)}
                  onChange={async (e) => {
                    setCheckALl(e.target.checked);
                    if (e.target.checked) {
                      await dispatch(setLendingsSelected(lendingsSelecteds.map((dt) => true)));
                    } else {
                      await dispatch(setLendingsSelected(lendingsSelecteds.map((dt) => false)));
                    }
                  }}
                  inputProps={{
                    'aria-label': 'select all desserts',
                  }}
                />
              ) as any,
              id: 'ID',
              code: 'Código',
              title: 'Título',
              available_copies: 'Copias Disponibles',
              total_copies: 'Copias Iniciales',
              borrow_date: 'Fecha de Prestamo',
              return_date: 'Fecha de Devolución',
            }}
            keyExtractor={(row) => row.id.toString()}
            error={isError ? error : undefined}
            isFetching={false}
            isLoading={false}
            numHeader={8}
            page={1}
            perPage={data?.total!}
            search={''}
            searchPlacehoder=""
            setPage={undefined}
            setPerPage={undefined}
            setSearch={undefined}
            total={data?.total!}
            customRenderers={{
              chck: ({ chck }) => (
                <>
                  {update ? null : (
                    <Checkbox
                      disabled={Boolean(chck.data.return_date)}
                      checked={lendingsSelecteds[chck.index]}
                      onChange={async (e) => {
                        const tempArray = [...lendingsSelecteds];
                        tempArray[chck.index] = e.target.checked;
                        await dispatch(setLendingsSelected(tempArray));
                      }}
                    />
                  )}
                </>
              ),
            }}
            customDataCellsProperties={{
              chck: { align: 'center' },
              id: { align: 'center' },
              code: { align: 'center' },
              available_copies: { align: 'center' },
              total_copies: { align: 'center' },
              borrow_date: { align: 'center' },
              return_date: { align: 'center' },
            }}
          />

          {/* <SimpleTable
            data={
              data?.data.map((dt) => ({
                id: dt.id,
                code: dt.borrow_code,
                title: dt.book.title,
                available_copies: dt.book.available_copies_number,
                total_copies: dt.book.initial_copies_number,
                borrow_date: dt.borrow_date,
                return_date: dt.return_date,
                options: '',
              })) || []
            }
            headers={{
              id: 'ID',
              code: 'Código',
              title: 'Título',
              available_copies: 'Copias Disponibles',
              total_copies: 'Copias Totales',
              borrow_date: 'Fecha de Prestamo',
              return_date: 'Fecha de Devolución',
              options: 'Opciones',
            }}
            keyExtractor={(row) => row.id.toString()}
            title=""
            customRenderers={{
              options: (row) => (
                <>
                  <Tooltip title="Retornar">
                    <IconButton color="primary">
                      <IconArrowBack />
                    </IconButton>
                  </Tooltip>
                </>
              ),
            }}
          /> */}
        </Grid>
      </Grid>
    </div>
  );
};

export default LendingsDetails;
