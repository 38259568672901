import { Switch, Redirect } from 'react-router-dom';

import { useAppSelector } from '../../../../hooks/useRedux';

import AssignCourseScreen from '../screens/AssignCoursesScreen';

// import EditRoleScreen from '../screens/EditRoleScreen';
// import AddRoleScreen from '../screens/AddRoleScreen';
import GuardRoute from '../../../../router/guards/GuardRoute';

import { permissionKey } from '../constants/permissions';

function AssignCoursePostgradeRouter() {
  // Verify permission
  const { currentModule } = useAppSelector((state) => state.access);
  const hasPermission = permissionKey in currentModule?.permissions!;

  if (!hasPermission) return <Redirect to="/" />;

  return (
    <Switch>
      <GuardRoute
        exact
        path={`/asignar-cursos-posgrado`}
        component={AssignCourseScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={usersSubPermissionKeys.read}
      />
    </Switch>
  );
}

export default AssignCoursePostgradeRouter;
