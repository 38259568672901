import { useEffect, useMemo } from 'react';

import { Grid, IconButton, Typography, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import { IconArrowNarrowLeft } from '@tabler/icons';

import MainCard from '../../../shared/components/cards/MainCard';
import StudentsAsignedList from '../../../teachers/academic-couseling/students-asigned/components/StudentsAsignedList';

import StudentAsignedCourseDetail from '../../../teachers/academic-couseling/students-asigned/components/StudentAsignedCourseDetail';
import StudentAsignedSendAnnouncement from '../../../teachers/academic-couseling/students-asigned/components/StudentAsignedSendAnnouncement';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import StudentAsignedSendAnnouncementAll from '../../../teachers/academic-couseling/students-asigned/components/StudentAsignedSendAnnouncementAll';
import UserHistoryAnnouncementsPreview from '../../../teachers/academic-couseling/students-asigned/components/UserHistoryAnnouncementsPreview';
import StudentHistoryAnnouncementsPreview from '../../../teachers/academic-couseling/students-asigned/components/StudentHistoryAnnouncementsPreview';
import { useLazyGetCollaboratorQuery } from '../../../hr/collaborators/slices/collaboratorsApiSlice';
import { setCounselorsList } from '../slices/academicCounselorSlice';
import SimpleFormOneColumn from '../../../shared/components/forms/skeletons/SimpleFormOneColumn';
// import AcademicCounselorsTable from '../components/AcademicCounselorsTable';

const CounselorsStudentsScreen = () => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { t } = useTranslation();
  const history = useHistory();
  const { collaboratorId } = useParams<{ collaboratorId: string }>();

  const {
    studentSelected,
    notifyAll,
    openModalStudentHistoryAnnouncements,
    openModalUserAnnouncements,
    openModalStudentAds,
    openModalStudentDetailCourse,
  } = useAppSelector((state) => state.studentsAsigned);

  const { academicCounselorsList } = useAppSelector((state) => state.academicCounselors);

  const [getCollaborator, { isFetching }] = useLazyGetCollaboratorQuery();

  useEffect(() => {
    if (!academicCounselorsList.length && !isFetching) {
      getCollaborator({
        collaboratorId: +collaboratorId,
      })
        .unwrap()
        .then((response) => {
          if (response) dispatch(setCounselorsList([response!]));
        })
        .catch((error) => console.log(error));
    }
  }, [getCollaborator, isFetching, collaboratorId, academicCounselorsList.length, dispatch]);

  const collaboratorToEdit = useMemo(() => {
    return academicCounselorsList.find((dt) => dt.id === Number(collaboratorId));
  }, [collaboratorId, academicCounselorsList]);


  return (
    <Grid component={MainCard} sx={{ padding: 2, minHeight: 'calc(100vh - 220px)' }}>
      <Grid sx={{ mb: 3 }}>
        <Grid item container alignItems="center" gap={1} sx={{ cursor: 'pointer' }}>
          <IconButton onClick={() => history.goBack()}>
            <IconArrowNarrowLeft size={30} color={theme.palette.text.dark} />
          </IconButton>
          <Typography variant="h2">
            {t('academic.academic_counselors.students.title')} -{' '}
            {collaboratorToEdit?.user.person.pers_full_name_lastname}
          </Typography>
        </Grid>
      </Grid>

      {!!academicCounselorsList.length ? (
        <StudentsAsignedList collaboratorId={+collaboratorId} />
      ) : (
        <SimpleFormOneColumn />
      )}

      {studentSelected && openModalStudentDetailCourse && <StudentAsignedCourseDetail />}
      {studentSelected && openModalStudentAds && <StudentAsignedSendAnnouncement />}
      {notifyAll && <StudentAsignedSendAnnouncementAll />}
      {openModalUserAnnouncements && <UserHistoryAnnouncementsPreview />}
      {openModalStudentHistoryAnnouncements && <StudentHistoryAnnouncementsPreview />}

      {/* <AcademicCounselorsTable /> */}
    </Grid>
  );
};

export default CounselorsStudentsScreen;
