import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import DialogAlert from '../../../shared/components/dialogs/DialogAlert';
import { useUpdateDepositTransferStateMutation } from '../../deposits-transfers/slices/depositsTransfersApiSlice';
import { resetDepositTransferId, setChangeStatusModalOpen } from '../slices/payChecksSlice';

const ChangeStatusDialog = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [comment, setComment] = useState('');

  const { payCheckId, changeStatusModalOpen, paymentStatus } = useAppSelector(
    (state) => state.payCheck
  );

  const [updateState, { isLoading, isSuccess, isError, error }] =
    useUpdateDepositTransferStateMutation();

  const handleAction = () => {
    const paymentActive = paymentStatus?.find((pE) => pE.cat_keyword === 'payment-active');
    updateState({
      transactionId: payCheckId!,
      depositsTransfersStatusPayload: {
        observation: comment,
        payments_status_id: paymentActive?.id!,
      },
    });
  };
  const handleRefuse = () => {
    const paymentRejected = paymentStatus?.find((pE) => pE.cat_keyword === 'payment-rejected');
    updateState({
      transactionId: payCheckId!,
      depositsTransfersStatusPayload: {
        observation: comment,
        payments_status_id: paymentRejected?.id!,
      },
    });
  };
  const handleClose = () => {
    dispatch(setChangeStatusModalOpen(false));
    dispatch(resetDepositTransferId());
  };
  return (
    <DialogAlert
      open={changeStatusModalOpen}
      onClose={handleClose}
      handleAction={handleAction}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      error={error as string}
      title={t('financial.pay_checks.change_status_dialog.title')}
      bodyText={t('financial.pay_checks.change_status_dialog.body')}
      customTitleSendButton={t('financial.pay_checks.change_status_dialog.approve')}
      customTitleCancelButton={t('financial.pay_checks.change_status_dialog.decline')}
      setObservation={setComment}
      handleRefuse={handleRefuse}
    />
  );
};
export default ChangeStatusDialog;
