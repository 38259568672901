import { Switch, Redirect } from 'react-router-dom';
import { useAppSelector } from '../../../../hooks/useRedux';

import TrackingNivelationScreen from '../screens/TrackingNivelationScreen';

import GuardRoute from '../../../../router/guards/GuardRoute';
import { permissionKey, subPermissionKeys } from '../constants/permissions';

const TrackingNivelationRouter = () => {

    const { currentModule } = useAppSelector((state) => state.access);

    const hasPermission = 'periods' in currentModule?.permissions!;

    if (!hasPermission) return <Redirect to="/dashboard" />;

    return (

        <Switch>
            <GuardRoute
                exact
                path="/administrar-seguimiento-nivelacion"
                component={TrackingNivelationScreen}
                permissionKey={permissionKey}
                subPermissionKey={subPermissionKeys.read}
            />
        </Switch>
    );
}

export default TrackingNivelationRouter;