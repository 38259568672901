import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import { useUpdateExtHomAppMutation } from '../../slices/homologationsApiSlice';
import { resetInternalAppIdToDelete, setConfirmModalOpen } from '../../slices/homologationsSlice';
import ConfirmAlert from '../../../../shared/components/dialogs/ConfirmAlert';
import { useEffect, useState } from 'react';
import { SubjectsHomologate } from '../../interfaces/payload.interface';
import { Score } from '../../interfaces/payload.interface';
import { useTranslation } from 'react-i18next';

const ConfirmExternalHomologation = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { confirmModalOpen, externalAppToConfirm, externalPreconfigSubjectScore } = useAppSelector(
    (state) => state.homologations
  );
  //
  const { dataList, selectedData, homId, curriculumId, agreementId } = externalAppToConfirm!;

  const { currentProfile } = useAppSelector((state) => state.access);

  const [initialized, setInitialized] = useState(false);

  const [updateExtHomApp, { isLoading, isError, error, isSuccess }] = useUpdateExtHomAppMutation();

  const handleConfirm = () => {
    setInitialized(true);

    const subjectsToHomolgate: SubjectsHomologate[] = [];

    const selectedMapped = selectedData.reduce((key, value) => ({ ...key, [value]: value }), {});

    const instituteId = Number(dataList[0].institution_subject.institute_id);

    dataList.forEach((dt) => {
      if (dt.id in selectedMapped) {
        if (!Boolean(externalPreconfigSubjectScore)) {
          subjectsToHomolgate.push({
            inst_subject_id: Number(dt.inst_subject_id),
            subject_id: Number(dt.subject_id),
            final_note: 0,
            observation: '',
          });
        } else {
          const preId = dt.id as unknown;
          const score = externalPreconfigSubjectScore![preId as keyof Score]
            ? externalPreconfigSubjectScore![preId as keyof Score]!.score
            : 0;
          const comment = externalPreconfigSubjectScore![preId as keyof Score]
            ? externalPreconfigSubjectScore![preId as keyof Score]!.comment
            : '';
          //
          subjectsToHomolgate.push({
            inst_subject_id: Number(dt.inst_subject_id),
            subject_id: Number(dt.subject_id),
            final_note: score,
            observation: comment,
          });
        }
      }
    });

    updateExtHomApp({
      profileId: currentProfile?.profileId!,
      extHomId: homId,
      // curriculumId: curriculumId!,
      subjectHomologationPayload: {
        curriculum_id: curriculumId!,
        institute_id: instituteId,
        convenio_id: agreementId,
        subjects_homologate: subjectsToHomolgate,
      },
    });
  };

  const handleClose = () => {
    dispatch(setConfirmModalOpen(false));
    dispatch(resetInternalAppIdToDelete());
    // setInitialized(false);
    // return <Redirect to="/administrar-homologaciones" />;
  };

  useEffect(() => {
    if (confirmModalOpen) setInitialized(false);
  }, [confirmModalOpen]);

  return (
    <ConfirmAlert
      open={confirmModalOpen}
      onClose={handleClose}
      isLoading={isLoading && initialized}
      isSuccess={isSuccess && initialized}
      isError={isError && initialized}
      handleDelete={handleConfirm}
      error={error as string}
      title={t('academic.homologation.external.edit.confirm.title')}
      subtitle={t('academic.homologation.external.edit.confirm.subtitle')}
      successMessage={t('academic.homologation.external.edit.confirm.success')}
    />
  );
};

export default ConfirmExternalHomologation;
