import { Box, Typography, useTheme } from '@material-ui/core';
import { ExpandLessOutlined, ExpandMoreOutlined } from '@material-ui/icons';
import { IconBulb } from '@tabler/icons';
import { useTranslation } from 'react-i18next';

interface Props {
  handleClickToggle: () => void;
  expanded: boolean;
}

const TaskItemHeaderToggle = ({ handleClickToggle, expanded }: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        mt: 1,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <IconBulb color={theme.palette.secondary.main} size="20px" style={{ marginRight: '5px' }} />
        <Typography
          sx={{
            fontSize: '0.7rem',
            ml: '5px',
          }}
        >
          {t('students.virtual_classroom.task.card.activity.calification')}
        </Typography>
      </Box>
      <Typography
        onClick={handleClickToggle}
        sx={{
          display: 'flex',
          color: theme.palette.secondary.main,
          cursor: 'pointer',
          ml: 1,
        }}
      >
        {expanded ? <ExpandLessOutlined /> : <ExpandMoreOutlined />}
      </Typography>
    </Box>
  );
};

export default TaskItemHeaderToggle;
