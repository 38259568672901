import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

import { useTranslation } from 'react-i18next';

import InputLabel from '../../../../shared/components/forms/InputLabel';
import { SearchPersonParam } from '../../../../shared/interfaces/searchPerson.interface';

interface Props {
  currentClient: SearchPersonParam;
}

const ClientLabels = ({ currentClient }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      {/* <Grid container spacing={1} justifyContent={'space-around'}> */}
      <Grid
        container
        alignContent={'center'}
        sx={{ justifyContent: { lg: 'space-around' } }}
        p={2}
        spacing={2}
      >
        <Grid item xs={12} sm={6} md={6} lg={'auto'}>
          <InputLabel sx={{ m: '0px!important' }}>
            {t('financial.generate_creditnote.client_label.code')}
            <Typography variant={'h5'} component={'span'}>
              {currentClient?.user.student?.stud_code || ''}
            </Typography>
          </InputLabel>
        </Grid>

        {/* <Grid item> */}
        {/* <InputLabel>Código</InputLabel>
            <TextField
              value={currentClient?.user.student?.stud_code || ''}
              placeholder="Código del estudiante"
              InputProps={{ readOnly: true }}
            /> */}
        {/* </Grid> */}

        <Grid item xs={12} sm={6} md={6} lg={'auto'}>
          <InputLabel sx={{ m: '0px!important' }}>
            {t('financial.generate_creditnote.client_label.identification')}
            <Typography variant={'h5'} component={'span'}>
              {currentClient?.pers_identification || ''}
            </Typography>
          </InputLabel>
        </Grid>

        {/* <Grid item>
            <InputLabel>CI / RUC</InputLabel>
            <TextField
              value={currentClient?.pers_identification || ''}
              placeholder="Número de identificación"
              InputProps={{ readOnly: true }}
            />
          </Grid> */}

        <Grid item xs={12} sm={6} md={6} lg={'auto'}>
          <InputLabel sx={{ m: '0px!important' }}>
            {t('financial.generate_creditnote.client_label.names')}
            <Tooltip
              title={`${currentClient?.pers_firstname || ''} ${
                currentClient?.pers_secondname || ''
              } ${currentClient?.pers_first_lastname || ''} ${
                currentClient?.pers_second_lastname || ''
              }`}
            >
              <Typography variant={'h5'} component={'span'} sx={{ pointerEvents: 'auto' }}>
                {`${currentClient?.pers_firstname || ''} ${currentClient?.pers_secondname || ''} ${
                  currentClient?.pers_first_lastname || ''
                } ${currentClient?.pers_second_lastname || ''}`}
              </Typography>
            </Tooltip>
          </InputLabel>
        </Grid>
        {/* <Grid item>
            <InputLabel>Nombres</InputLabel>
            <TextField
              value={`${currentClient?.pers_firstname ? currentClient?.pers_firstname + ' ' : ''}${
                currentClient?.pers_secondname ? currentClient?.pers_secondname + ' ' : ''
              }${
                currentClient?.pers_first_lastname ? currentClient?.pers_first_lastname + ' ' : ''
              }${
                currentClient?.pers_second_lastname ? currentClient?.pers_second_lastname + ' ' : ''
              }`}
              placeholder="Nombre del estudiante"
              InputProps={{ readOnly: true }}
            />
          </Grid> */}
      </Grid>
    </>
  );
};

export default ClientLabels;
