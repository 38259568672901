import { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import useTheme from '@material-ui/core/styles/useTheme';

import { renderErrorOrEmptyRow, renderSkeletonRows } from '../../../../shared/helpers/render';
import AddButton from '../../../../shared/components/tables/buttons/AddButton';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import AddProductSideDialog from '../AddProductSideDialog';
import EditButton from '../../../../shared/components/tables/buttons/EditButton';
import DeleteButton from '../../../../shared/components/tables/buttons/DeleteButton';
import { FormProduct } from '../../interfaces/payload.interface';
import { removeItemFromIndexArr, showWithDecimals } from '../../../../shared/helpers';
import { setProductsCollection } from '../../slices/generateDebtsSlice';
import { useTranslation } from 'react-i18next';

const AddProductsTable = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const { t } = useTranslation();
  //

  const { currentClient, productsCollection, totalNetAmout } = useAppSelector(
    (state) => state.generateDebts
  );

  const [open, setOpen] = useState(false);

  const handleOpenSideDialog = () => {
    setOpen(true);
  };
  const handleCloseSideDialog = () => {
    setOpen(false);
  };

  const handleEditPay = (index: number) => {};
  const handleDeletePay = (indextToDel: number) => {
    try {
      let newProductCollection = removeItemFromIndexArr(productsCollection, indextToDel);

      dispatch(setProductsCollection(newProductCollection));
    } catch (error) {
      console.log(error);
    }
  };

  const renderRow = (productColl: FormProduct, i: number) => (
    <TableRow hover className="table-row" key={`${productColl.product?.id.toString()}-${i}`}>
      <TableCell>{productColl.category?.cat_name}</TableCell>
      <TableCell align="center">{productColl.product?.pr_description}</TableCell>
      <TableCell align="center">
        {showWithDecimals(Number(productColl.productDetails?.precio_neto || 0))}
      </TableCell>
      <TableCell align="center">{productColl.quantity}</TableCell>
      <TableCell align="center">{productColl.productDetails?.porcentaje_desc || 0.0}</TableCell>
      <TableCell align="center">{productColl.productDetails?.iva || '0%'}</TableCell>
      <TableCell align="center">
        {showWithDecimals(
          Number(productColl.productDetails?.total || 0) * Number(productColl.quantity)
        )}
      </TableCell>
      <TableCell align="center">
        {false && <EditButton handleEdit={() => handleEditPay(i)} />}
        <DeleteButton handleDelete={() => handleDeletePay(i)} />
      </TableCell>
    </TableRow>
  );

  const renderRows = () => {
    return <>{productsCollection?.map(renderRow)}</>;
  };

  const defaultConfiguration = useAppSelector((state) => state.access.defaultConfiguration);

  return (
    <>
      <Grid container spacing={1}>
        {currentClient && (
          <Grid item xs={12} sx={{ textAlign: 'right' }}>
            <AddButton onClick={handleOpenSideDialog} />
          </Grid>
        )}

        <Grid item xs={12}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow sx={{ backgroundColor: theme.palette.grey[200] }}>
                  <TableCell>
                    {t('financial.generate_debts.products_component.table.category')}
                  </TableCell>
                  <TableCell>
                    {t('financial.generate_debts.products_component.table.product')}
                  </TableCell>
                  <TableCell>
                    {t('financial.generate_debts.products_component.table.price')}{' '}
                    {defaultConfiguration?.currency_symbol}
                  </TableCell>
                  <TableCell>
                    {t('financial.generate_debts.products_component.table.quantity')}
                  </TableCell>
                  <TableCell>
                    {t('financial.generate_debts.products_component.table.discount')}
                  </TableCell>
                  <TableCell>
                    {t('financial.generate_debts.products_component.table.taxes')}
                  </TableCell>
                  <TableCell>
                    {t('financial.generate_debts.products_component.table.subtotal')}{' '}
                    {defaultConfiguration?.currency_symbol}
                  </TableCell>
                  <TableCell align="center">
                    {t('financial.generate_debts.products_component.table.remove')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!productsCollection ? renderSkeletonRows(5, 8) : renderRows()}
                {productsCollection?.length === 0 &&
                  renderErrorOrEmptyRow(
                    8,
                    t('financial.generate_debts.products_component.table.empty_table')
                  )}
                {productsCollection && productsCollection?.length !== 0 && (
                  <TableRow>
                    <TableCell colSpan={6} align="right">
                      <b>{t('financial.generate_debts.products_component.table.total')}</b>
                    </TableCell>
                    <TableCell> {showWithDecimals(totalNetAmout)} </TableCell>
                    <TableCell>{/* {showWithDecimals(totalPayAmount)} */}</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      <AddProductSideDialog open={open} handleCloseDialog={handleCloseSideDialog} />
    </>
  );
};

export default AddProductsTable;
