import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AnnouncementCourse } from '../interfaces/announcement.interfaces';

interface DeleteAnnouncement {
  announcementId: number;
  courseId: number;
}

interface AnnouncementTeacherState {
  announcementTeacherList?: AnnouncementCourse[];
  announcementIdToDelete?: number;
  courseId?: number;
  deleteModalOpen: boolean;
}

const initialState: AnnouncementTeacherState = {
  deleteModalOpen: false,
};

const announcementTeacherSlice = createSlice({
  name: 'announcementTeacher',
  initialState,
  reducers: {
    setAnnouncementTeacherList: (state, action: PayloadAction<AnnouncementCourse[]>) => {
      state.announcementTeacherList = action.payload;
    },
    setDeleteModalOpen: (state, action: PayloadAction<boolean>) => {
      state.deleteModalOpen = action.payload;
    },
    openDeleteModalAnnouncement: (state, action: PayloadAction<DeleteAnnouncement>) => {
      state.announcementIdToDelete = action.payload.announcementId;
      state.courseId = action.payload.courseId;
      state.deleteModalOpen = true;
    },
    resetAnnouncementToDelete: (state) => {
      state.announcementIdToDelete = undefined;
    },
  },
});

export const {
  setAnnouncementTeacherList,
  openDeleteModalAnnouncement,
  setDeleteModalOpen,
  resetAnnouncementToDelete,
} = announcementTeacherSlice.actions;

export default announcementTeacherSlice.reducer;
