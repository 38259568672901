import { Box, Stack, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  boxContainer: {
    borderRadius: 5,
    backgroundColor: '#fff',
    paddingInline: 16,
    paddingBlock: 10,
    boxShadow: '0px 0px 20px #EAF0F7',
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    flexWrap: 'wrap',
    marginBottom: '1rem',
  },
  number: {
    fontWeight: 800,
    color: theme.palette.primary.main,
    fontSize: 16,
  },
  title: {
    color: theme.palette.grey[800],
    fontSize: 20,
  },
  simbologyText: {
    fontWeight: 600,
    fontSize: 14,
  },
  square: {
    width: 16,
    height: 16,
    borderRadius: '10px',
  },
}));

interface Props {
  simbologies: {
    color: string;
    label: string;
  }[];
}

const Simbologies = ({ simbologies }: Props) => {
  const classes = useStyles();

  return (
    <Box className={classes.boxContainer}>
      {simbologies.map((s) => (
        <Stack key={s.label} direction="row">
          <Box
            className={classes.square}
            sx={{ bgcolor: s.color, mr: '4px', boxShadow: s.color === '#FFF' ? 2 : '' }}
          ></Box>
          <Typography className={classes.simbologyText}>{s.label}</Typography>
        </Stack>
      ))}
    </Box>
  );
};

export default Simbologies;
