import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../../hooks/useRedux';
import { useTableSearch } from '../../../../hooks/useTableSearch';
import SearchPaginatedTable from '../../../shared/components/tables/SearchPaginatedTable';
import { useGetPaidCertificatesQuery } from '../slices/certificateApiSlice';
import { updatepaidCertificateTableStates } from '../slices/certificateSlice';
import DownloadPaidCertificate from './DownloadPaidCertificate';

const PaidCertificatesTable = () => {
  const { userInfo } = useAppSelector((state) => state.auth);
  const { page, perPage, search } = useAppSelector(
    (state) => state.certificate.paidCertificateTable
  );

  const { setPage, setPerPage, setSearch } = useTableSearch({
    page,
    perPage,
    search,
    updateFunction: updatepaidCertificateTableStates,
  });

  const { t } = useTranslation();

  const { data, isLoading, isFetching, error } = useGetPaidCertificatesQuery({
    userId: +userInfo.id,
    page,
    perPage,
    search,
  });

  const datafetch = data?.data.map((dt) => ({
    code: dt.app_code,
    description: dt.app_description,
    date: dt.app_register_date,
    typeApp: dt.type_application.typ_app_name,
    options: dt,
  }));

  return (
    <SearchPaginatedTable
      data={datafetch!}
      headers={{
        code: t('students.certificates.paid_certificates.table.code'),
        description: t('students.certificates.paid_certificates.table.description'),
        date: t('students.certificates.paid_certificates.table.date'),
        typeApp: t('students.certificates.paid_certificates.table.typeApp'),
        options: t('students.certificates.paid_certificates.table.options'),
      }}
      keyExtractor={(item) => item.options.id.toString()}
      searchPlacehoder={t('students.certificates.paid_certificates.table.search_placeholder')}
      isLoading={isLoading}
      isFetching={isFetching}
      error={error}
      perPage={perPage}
      setPerPage={setPerPage}
      total={data?.total || 0}
      page={page}
      setPage={setPage}
      numHeader={5}
      search={search}
      setSearch={setSearch}
      customRenderers={{
        //   status_id: (row) => <StatusChip statusId={Number(row.status_id)} />,
        //   detail: ({ detail }) => (
        //     <Grid container item direction="column" gap={1}>
        //       <Typography>Máximo materias: {detail.per_max_matter_enrollment}</Typography>
        //       <Typography>Mínimo materias: {detail.per_min_matter_enrollment}</Typography>
        //       <Typography>Cuota/matrícula : {detail.per_pay_enrollment}</Typography>
        //       <Typography>Número cuotas : {detail.per_num_fees}</Typography>
        //     </Grid>
        //   ),
        options: ({ options }) => (
          <>
            <DownloadPaidCertificate certificateId={options.id} />
            {/* {hasAccess('edit') && (
                <Link to={`${path}/etapas/${id}`}>
                  <Tooltip title="Ver etapas">
                    <IconButton aria-label="Ver etapas" color="default">
                      <IconClipboardList size={20} />
                    </IconButton>
                  </Tooltip>
                </Link>
              )}
              {hasAccess('edit') && (
                <Tooltip title="Editar periodo">
                  <span>
                    <IconButton
                      onClick={() => history.push(`${path}/${id}/editar`)}
                      disabled={new Date().getTime() > new Date(detail.start_date).getTime()}
                      aria-label="Editar"
                      color="secondary"
                    >
                      <EditTwoToneIcon sx={{ fontSize: '1.3rem' }} />
                    </IconButton>
                  </span>
                </Tooltip>
              )}
              {hasAccess('delete') && <DeleteButton handleDelete={() => handleDelete(id)} />}
              {!hasAccess('edit') && !hasAccess('delete') && 'Ninguna'} */}
          </>
        ),
      }}
      customHeadersCellsProperties={{
        code: {
          align: 'center',
        },
        description: {
          align: 'center',
        },
        date: {
          align: 'center',
        },
        typeApp: { align: 'center' },
        options: { align: 'center' },
      }}
      customDataCellsProperties={{
        code: {
          align: 'center',
        },
        description: {
          align: 'center',
        },
        date: {
          align: 'center',
        },
        typeApp: { align: 'center' },
        options: { align: 'center' },
      }}
      // ActionButtons={<>{hasAccess('create') && <AddButton size="large" onClick={goToAdd} />}</>}
    />
  );
};

export default PaidCertificatesTable;
