import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/core/Skeleton';

import { useGeStudentsAssignTaskQuery } from '../../../../../slices/InvestigationLinesApiSlice';
import StudentAssignCard from './StudentAssignCard';

interface Props {
  projectId: string;
}

const StudenstAssigns = ({ projectId }: Props) => {
  const { data: studentsAvailables, isLoading: isLoadingStudents } = useGeStudentsAssignTaskQuery({
    taskProjectResearch: projectId,
  });

  return (
    <Grid container spacing={2}>
      {isLoadingStudents
        ? Array.from(new Array(5)).map(() => <Skeleton variant="rectangular" height={'50px'} />)
        : studentsAvailables?.map((student) => <StudentAssignCard studentTask={student} />)}
    </Grid>
  );
};

export default StudenstAssigns;
