import { Grid } from '@material-ui/core';
import SkeletonCard from '../../../../students/academic-portfolio/components/skeleton/SkeletonCard';
import PartnerCard from '../../partners/components/PartnerCard';
import { useAppSelector } from '../../../../../hooks/useRedux';
import NoData from '../../../../shared/components/NoData';
import noContentImage from '../../../../../assets/images/no-classmates.png';
import { useTranslation } from 'react-i18next';
import CustomPaginationAndItems from '../../../../shared/components/tables/CustomPaginationAndItems';
import { useState } from 'react';
import { useGetStudentsAvailablesPartnersQuery } from '../../slices/virtualClassroomApiSlice';

const PartnersGrid = () => {
  const { currentProfile } = useAppSelector((state) => state.access);

  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(10);

  const {
    course: { courseId },
  } = useAppSelector((state) => state.virtualClassroomStudentShared);

  const {
    data,
    isLoading: isLoadingPartner,
    error,
  } = useGetStudentsAvailablesPartnersQuery(
    {
      course: courseId!,
      profileId: currentProfile?.profileId!,
      page,
      size: perPage,
    },
    { skip: courseId === 0 || courseId === undefined }
  );

  const { t } = useTranslation();

  return (
    <Grid container mt={2} spacing={3}>
      {isLoadingPartner ? (
        [...new Array(6)].map((_, index) => <SkeletonCard key={index.toString()} />)
      ) : data?.data?.length ? (
        <>
          {data?.data.map((cs) => (
            <PartnerCard student={cs} key={cs.id} />
          ))}

          <Grid
            container
            sx={{
              ml: 2,
            }}
          >
            <CustomPaginationAndItems
              error={error}
              isLoading={isLoadingPartner}
              page={page}
              perPage={perPage}
              setPage={setPage}
              setPerPage={setPerPage}
              total={data?.total || 0}
            />
          </Grid>
        </>
      ) : (
        <NoData
          image={noContentImage}
          title={t('studentVirtualClassroom.classmates.empty')}
          subtitle={t('studentVirtualClassroom.classmates.emptyDescription')}
        />
      )}
    </Grid>
  );
};

export default PartnersGrid;
