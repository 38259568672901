import { Box, Pagination, PaginationProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  pagination: {
    '& > *': {
      padding: '5px 0px',
    },
    '& .MuiPaginationItem-icon': {
      backgroundColor: 'white',
      borderRadius: '50%',
    },
  },
});

const CustomPagination = (props: PaginationProps) => {
  const classes = useStyles();

  return (
    <Box sx={{ bgcolor: 'contentBg.main', borderRadius: 4 }}>
      <Pagination {...props} className={classes.pagination} />
    </Box>
  );
};

export default CustomPagination;
