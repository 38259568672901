import { mainApi } from '../../../../api/mainApi';
import { AccessState } from '../../../../types';
import { middlewareApi } from '../../../middleware/middlewareApi';
import { GenerateDebt } from '../interfaces/payload.interface';
import {
  DataPostMassiveDebtsResponse,
  ProductStudentRecord,
} from '../interfaces/productStudentRecord.interface';

export const generateDebtsTags = middlewareApi.enhanceEndpoints({
  addTagTypes: [
    'PaymentsDebtsHistory', //
    'PaymentsHistory', // historial de pago del estudiante
    'StudentsDebts', //data all de deudas del estudiante
  ],
});
// Define a service using a base URL and expected endpoints
export const generateDebtsApi = generateDebtsTags.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * @POST generar deuda
     */
    generateDebtsOnCashPoint: builder.mutation<any, { generateDebtsPayload: GenerateDebt }>({
      queryFn: async ({ generateDebtsPayload }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = currentProfile?.profileId;

          const { data } = await mainApi.post<any>('/product/debts', {
            user_profile_id,
            ...generateDebtsPayload,
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [
        //considerar cambiar el id: LIST -> studentId del payload
        { type: 'PaymentsDebtsHistory', id: 'LIST' },
        { type: 'StudentsDebts', id: 'LIST' },
      ],
    }),

    /**
     * @GET cash point is available
     */
    getProductIdByStudentRecordId: builder.query<
      ProductStudentRecord,
      { productId: number; studentRecordId: number }
    >({
      queryFn: async ({ productId, studentRecordId }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = currentProfile?.profileId;

          const { data } = await mainApi.get<ProductStudentRecord>(
            `/product/${productId}/student-record/${studentRecordId}`,
            {
              params: {
                user_profile_id,
              },
            }
          );
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      // providesTags: (result) => [{ type: 'PaymentsHistory', id: 'LIST' }],
    }),

    /**
     * @POST generate massive debts by student
     */
    processMassiveDebtsByStudent: builder.mutation<
      DataPostMassiveDebtsResponse,
      { studentId: number; periodId: number; studentRecordId: number }
    >({
      queryFn: async ({ studentId, periodId, studentRecordId }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = currentProfile?.profileId;

          const { data } = await mainApi.post<DataPostMassiveDebtsResponse>(
            `/students/${studentId}/fees`,
            {
              user_profile_id,
              period_id: periodId,
              student_record_id: studentRecordId,
            }
          );
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [
        { type: 'PaymentsHistory', id: 'LIST' },
        { type: 'PaymentsDebtsHistory', id: 'LIST' },
        { type: 'StudentsDebts', id: 'LIST' }, //actualiza las deudas del estudiante
      ],
    }),

    /**
     * @POST generate massive debts
     */
    processMassiveDebts: builder.mutation<DataPostMassiveDebtsResponse, { periodId: number }>({
      queryFn: async ({ periodId }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = currentProfile?.profileId;

          const { data } = await mainApi.post<DataPostMassiveDebtsResponse>(
            `/periods/${periodId}/fees`,
            {
              user_profile_id,
            }
          );
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [
        { type: 'PaymentsHistory', id: 'LIST' },
        { type: 'PaymentsDebtsHistory', id: 'LIST' },
        { type: 'StudentsDebts', id: 'LIST' }, //actualiza las deudas del estudiante
      ],
    }),

    //
  }),
});

export const {
  useGenerateDebtsOnCashPointMutation,
  useLazyGetProductIdByStudentRecordIdQuery,
  useProcessMassiveDebtsByStudentMutation,
  useProcessMassiveDebtsMutation,
} = generateDebtsApi;
