import { useAppSelector } from '../../../../hooks/useRedux';
import { StudentInfo } from '../../../../types';

const useStudentInfo = (): StudentInfo | undefined => {
  const { userInfo } = useAppSelector((state) => state.auth);
  const { studentInfoAcademic } = useAppSelector((state) => state.subjectsRegister);

  let studentInfo: StudentInfo | undefined;

  if (userInfo?.studentInfo) studentInfo = userInfo.studentInfo;
  if (studentInfoAcademic) studentInfo = studentInfoAcademic;

  return studentInfo;
};

export default useStudentInfo;
