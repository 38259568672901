import React, { useState, ChangeEvent } from 'react';

// material-ui
import { makeStyles, useTheme, Theme } from '@material-ui/core/styles';
import {
  Button,
  ButtonBase,
  Card,
  CardMedia,
  Collapse,
  FormHelperText,
  Grid,
  InputAdornment,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from '@material-ui/core';

// third-party
import * as yup from 'yup';
import uniqueId from 'lodash/uniqueId';
import { Controller, FormProvider, useForm, useFormContext } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// project imports
import Avatar from '../../../../extended/Avatar';
import {
  CommentData,
  PostProps,
  Profile,
  UserProfile,
} from '../../../../../interfaces/post.interfaces';
import ImageList from '../../../../extended/ImageList';
import { useAppDispatch } from '../../../../../../../hooks/useRedux';
import {
  openDeleteModalComment,
  openShowModalImg,
} from '../../../../../../teachers/virtual-classroom/slices/virtualClassroomTeacherSlice';

// assets
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import MoreVertTwoToneIcon from '@material-ui/icons/MoreVertTwoTone';
import AttachmentRoundedIcon from '@material-ui/icons/AttachmentRounded';
import AnimateButton from '../../../../extended/AnimateButton';
import { FormInputProps } from '../../../../../interfaces/material-ui.interfaces';
// import ThumbUpAltTwoToneIcon from '@material-ui/icons/ThumbUpAltTwoTone';
// import ReplyTwoToneIcon from '@material-ui/icons/ReplyTwoTone';
import { useTranslation } from 'react-i18next';

// const avatarImage = require.context('../../../../../../../assets/images/profile', true);

// style constant
const useStyles = makeStyles((theme) => ({
  textSecondary: {
    color: theme.palette.secondary.main,
  },
  avatarRight: {
    ...theme.typography.commonAvatar,
    ...theme.typography.smallAvatar,
    background:
      theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
    color: theme.palette.mode === 'dark' ? theme.palette.dark.light : theme.palette.secondary.dark,
    zIndex: 1,
    transition: 'all .2s ease-in-out',
    '&[aria-controls="menu-list-grow"],&:hover': {
      background: theme.palette.secondary.main,
      color: theme.palette.secondary.light,
    },
  },
  profileComment: {
    background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[50],
    padding: '16px 16px 8px',
    marginTop: '10px',
  },
  commentLevel1: {
    marginLeft: '50px',
  },
}));

const validationSchema = yup.object().shape({
  name: yup.string().required('Reply Field is Required'),
});

//-----------------------|| COMMENT TEXTFIELD ||-----------------------//

const FormInput = ({ bug, label, name, required, defaultValue, ...others }: FormInputProps) => {
  const { control } = useFormContext();

  let isError = false;
  let errorMessage = '';
  if (bug && bug.hasOwnProperty(name)) {
    isError = true;
    errorMessage = bug[name].message;
  }

  return (
    <React.Fragment>
      <Controller
        as={TextField}
        name={name}
        control={control}
        defaultValue={defaultValue}
        label={label}
        fullWidth={true}
        InputLabelProps={{
          className: required ? 'required-label' : '',
          required: required || false,
        }}
        error={isError}
        {...others}
      />
      {errorMessage && (
        <Grid item xs={12}>
          <FormHelperText error>{errorMessage}</FormHelperText>
        </Grid>
      )}
    </React.Fragment>
  );
};

//-----------------------|| POST & COMMENT - REPLAY ||----------------------//

export interface ReplyProps {
  commentId: string;
  commentEdit: PostProps['commentEdit'];
  handleReplayLikes: (i: string, j: string, k: string) => void;
  onReply: () => void;
  postId: string;
  reply: { id: string; profile: UserProfile; data: CommentData };
  user: Profile;
  currentUser: Profile;
}

const Reply = ({
  commentId,
  commentEdit,
  handleReplayLikes,
  onReply,
  postId,
  reply,
  user,
  currentUser,
}: ReplyProps) => {
  const classes = useStyles();

  const { t } = useTranslation();

  const theme = useTheme();

  const matchesXS = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const dispatch = useAppDispatch();

  const handleDelete = function (commentId: number) {
    dispatch(openDeleteModalComment(commentId));
  };

  const [anchorEl, setAnchorEl] =
    React.useState<Element | ((element: Element) => Element) | null | undefined>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement> | undefined) => {
    setAnchorEl(event?.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [file, setFile] = useState('');
  const [target, setTarget] = useState<File>(null!);
  const [isEdit, setEdit] = useState(false);

  const obtainImageByDefault = (name: string | undefined) => {
    return `https://ui-avatars.com/api/?name=${name}&background=0D8ABC&color=fff`;
  };

  const onChangePicture = function (event: ChangeEvent<HTMLInputElement>) {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e: any) => {
        setFile(e.target.result);
      };
      reader.readAsDataURL(event.target.files[0]);
      setTarget(event.target.files[0]);
    }
  };

  const replies = reply;
  const handleShowImage = (nameImg: string) => {
    dispatch(openShowModalImg(nameImg));
  };

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: { name: '' },
  });

  const { handleSubmit, setValue, errors, reset } = methods;
  const onSubmitEdit = async (reply: CommentData) => {
    const replyId = uniqueId('#REPLY_');
    const reply_ = {
      id: replyId,
      profile: user,
      data: {
        comment: reply.name,
        imagesFile: target,
        likes: {
          like: false,
          value: 0,
        },
        replies: [],
      },
    };

    commentEdit(postId, replies.id, reply_);
    reset({ name: '' });
    setEdit(false);
  };

  const handleEdit = (comment: string) => {
    setValue('name', comment);
  };

  return (
    <React.Fragment>
      {Object.keys(replies).length > 0 && (
        <Grid item xs={12}>
          <div className={classes.commentLevel1}>
            <Card className={classes.profileComment}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Grid container wrap="nowrap" alignItems="center" spacing={1}>
                    <Grid item>
                      <Avatar
                        sx={{ width: 24, height: 24 }}
                        size="small"
                        alt="User 1"
                        src={
                          replies.profile &&
                          replies.profile.name &&
                          obtainImageByDefault(replies.profile.name)
                        }
                      />
                    </Grid>
                    <Grid item xs zeroMinWidth>
                      <Grid container alignItems="center" spacing={1}>
                        <Grid item>
                          <Typography align="left" variant="h5" component="div">
                            {replies.profile.name}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography align="left" variant="caption">
                            <FiberManualRecordIcon
                              sx={{ width: '10px', height: '10px', opacity: 0.5, m: '0 5px' }}
                            />
                            {replies.profile.time}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    {reply.profile.id === currentUser.id && (
                      <Grid item>
                        <ButtonBase sx={{ borderRadius: '12px' }} onClick={handleClick}>
                          <Avatar
                            variant="rounded"
                            className={classes.avatarRight}
                            aria-controls="menu-comment-reply"
                            aria-haspopup="true"
                          >
                            <MoreVertTwoToneIcon fontSize="inherit" />
                          </Avatar>
                        </ButtonBase>
                        <Menu
                          id="menu-comment-reply"
                          anchorEl={anchorEl}
                          keepMounted
                          open={Boolean(anchorEl)}
                          onClose={handleClose}
                          variant="selectedMenu"
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                        >
                          <MenuItem
                            onClick={() => {
                              handleClose();
                              setEdit(true);
                              handleEdit(reply?.data?.comment!);
                            }}
                          >
                            {t('studentVirtualClassroom.forum.edit')}
                          </MenuItem>
                          <MenuItem onClick={() => handleDelete(Number(replies.id))}>
                            {t('studentVirtualClassroom.forum.delete')}
                          </MenuItem>
                        </Menu>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                {!isEdit ? (
                  <Grid item xs={12} sx={{ '&.MuiGrid-root': { pt: 1.5 } }}>
                    <Typography align="left" variant="body2">
                      {replies.data.comment}
                    </Typography>
                    {replies.data && replies.data.images && replies.data.images.length > 0 && (
                      <Grid
                        display="inline"
                        width="fitContent"
                        bgcolor="red"
                        sx={{ cursor: 'pointer' }}
                        onClick={() => {
                          handleShowImage(replies!.data!.images![0].img!);
                        }}
                      >
                        <ImageList itemData={replies.data.images} />
                      </Grid>
                    )}
                  </Grid>
                ) : (
                  <Grid item xs={12} sx={{ '&.MuiGrid-root': { pt: 1.5 } }}>
                    <Collapse in={!!file} sx={{ width: '100%' }}>
                      <Grid item margin={2} marginLeft={6}>
                        <CardMedia
                          style={{ cursor: 'pointer', height: '10vh', width: '10vh' }}
                          component="img"
                          onClick={() => {
                            setFile('');
                            setTarget(null!);
                          }}
                          src={file ? file : ''}
                          title="image"
                        />
                      </Grid>
                    </Collapse>
                    <div className={classes.commentLevel1}>
                      <form onSubmit={handleSubmit(onSubmitEdit)}>
                        <Grid container spacing={2} alignItems="flex-start">
                          <Grid item xs zeroMinWidth sx={{ mt: 1 }}>
                            <FormProvider {...methods}>
                              <FormInput
                                fullWidth
                                name="name"
                                defaultValue={reply?.data?.comment!}
                                label={t('studentVirtualClassroom.forum.writeAnswer')}
                                size={matchesXS ? 'small' : 'medium'}
                                bug={errors}
                                InputProps={{
                                  label: t('studentVirtualClassroom.forum.writeAnswer'),
                                  startAdornment: (
                                    <label htmlFor={'contained-button-file' + reply.id}>
                                      <InputAdornment position="start" onChange={onChangePicture}>
                                        <input
                                          type="file"
                                          style={{ display: 'none' }}
                                          id={'contained-button-file' + reply.id}
                                        />

                                        <AttachmentRoundedIcon
                                          fontSize="small"
                                          sx={{
                                            color: `${
                                              !!file ? theme.palette.primary.main : 'inherit'
                                            }`,
                                          }}
                                        />
                                      </InputAdornment>
                                    </label>
                                  ),
                                }}
                              />
                            </FormProvider>
                          </Grid>
                          <Grid item>
                            <AnimateButton>
                              <Button
                                type="submit"
                                variant="contained"
                                // className={classes.btnSecondary}
                                size={matchesXS ? 'small' : 'large'}
                                color="primary"
                                sx={{
                                  mt: 1.5,
                                  background: theme.palette.error.main,
                                  borderColor: '#EDE7F6',
                                }}
                                onClick={() => setEdit(false)}
                              >
                                {t('studentVirtualClassroom.forum.cancel')}
                              </Button>
                            </AnimateButton>
                          </Grid>
                          <Grid item>
                            <AnimateButton>
                              <Button
                                type="submit"
                                variant="contained"
                                // className={classes.btnSecondary}
                                size={matchesXS ? 'small' : 'large'}
                                sx={{ mt: 1.5 }}
                              >
                                {t('studentVirtualClassroom.forum.edit')}
                              </Button>
                            </AnimateButton>
                          </Grid>
                        </Grid>
                      </form>
                    </div>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Stack
                    direction="row"
                    spacing={2}
                    sx={{ color: theme.palette.mode === 'dark' ? 'grey.700' : 'grey.800' }}
                  >
                    {/* <Button
                      onClick={() => handleReplayLikes(postId, commentId, id)}
                      variant="text"
                      color="inherit"
                      size="small"
                      startIcon={
                        <ThumbUpAltTwoToneIcon
                          className={
                            replies.data.likes && replies.data.likes.like
                              ? classes.textSecondary
                              : ''
                          }
                        />
                      }
                    >
                      {replies.data.likes && replies.data.likes.value
                        ? replies.data.likes.value
                        : 0}{' '}
                      likes
                    </Button> */}
                    {/* <Button
                      variant="text"
                      onClick={onReply}
                      size="small"
                      color="inherit"
                      startIcon={<ReplyTwoToneIcon color="primary" />}
                    >
                      responder
                    </Button> */}
                  </Stack>
                </Grid>
              </Grid>
            </Card>
          </div>
        </Grid>
      )}
    </React.Fragment>
  );
};

export default Reply;
