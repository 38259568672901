import { Switch, Redirect } from 'react-router-dom';
import { useAppSelector } from '../../../../hooks/useRedux';
import AddPermissionScreen from '../screens/AddPermissionScreen';
import EditPermissionScreen from '../screens/EditPermissionScreen';
import PermissionsScreen from '../screens/PermissionsScreen';

import GuardRoute from '../../../../router/guards/GuardRoute';

import { permissionKey, subPermissionKeys } from '../constants/permissions';

const PermissionsRouter = () => {
  const { currentModule } = useAppSelector((state) => state.access);

  const hasPermission = 'permissions' in currentModule?.permissions!;

  if (!hasPermission) return <Redirect to="/" />;
  return (
    <Switch>
      <GuardRoute
        exact
        path="/administrar-permisos"
        component={PermissionsScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.read}
      />
      <GuardRoute
        exact
        path="/administrar-permisos/agregar"
        component={AddPermissionScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.create}
      />
      <GuardRoute
        exact
        path="/administrar-permisos/:permissionId/editar"
        component={EditPermissionScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.edit}
      />
    </Switch>
  );
};

export default PermissionsRouter;
