import { Grid, Skeleton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  boxCard: {
    height: '30vh',
    width: '100%',
    borderRadius: 12,
  },
}));

const SkeletonCard = () => {
  const classes = useStyles();
  return (
    <Grid container item xs={12} sm={6} md={4} xl={3}>
      <Skeleton variant="rectangular" className={classes.boxCard} />
    </Grid>
  );
};

export default SkeletonCard;
