import * as yup from 'yup';

export const templateFormSchema = yup.object().shape({
  name: yup.string().required('El nombre es requerido'),
  description: yup.string(),
  eva_type_id: yup.string().required('La rúbrica es requerida'),
  eva_scale_evaluativa_uuid: yup.string().required('La escala de evaluación es requerida'),
  point_end: yup
    .string()
    .matches(/^[0-9]+([,.][0-9]+)?$/, 'El puntaje final debe ser un número')
    .required('El puntaje final es requerido'),
  questions: yup
    .array()
    .min(1, 'Debe agregar al menos una pregunta')
    .of(yup.string().notOneOf(['', null, undefined], 'Debe agregar al menos una pregunta'))
    .required('Debe agregar al menos una pregunta'),
});
