import { ReportUrlTypes } from './reportTypes.inteface';

const buildParameterString = (params: Pick<ReportUrlTypes, 'parameters'>) => {
  const { parameters } = params;

  if (!parameters) {
    return '';
  }

  const parameterString = Object.keys(parameters)
    .map((key) => {
      const value = parameters[key as unknown as keyof typeof parameters];

      if (Array.isArray(value)) {
        return value.map((item) => `${key}=${item}`).join('&');
      }

      return `${key}=${value}`;
    })
    .join('&');

  return `&${parameterString}`;
};

const buildReportUrl = (data: Omit<ReportUrlTypes, 'width' | 'height'>) => {
  const { reportServer, reportUrl, showParameters, parameters: params, language, toolbar } = data;

  if (!reportUrl) {
    return;
  }
  const parameters = buildParameterString({ parameters: params });

  return `${reportServer}?/${reportUrl}&rs:Embed=true&rc:Parameters=${showParameters}${parameters}&rs:ParameterLanguage=${language}&rc:Toolbar=${toolbar}`;
};

export default buildReportUrl;
