import { Box, Button } from '@material-ui/core';
import 'quill/dist/quill.snow.css';

import { useQuill } from 'react-quilljs';
import { ChatBubbleOutline } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { DeltaStatic } from 'quill';
import { useEffect } from 'react';

interface Props {
  /** Para el comportamiento del smothscroll */
  ref?: React.LegacyRef<HTMLDivElement> | undefined;
  commentAdd: (data: { comment: string; files: File | undefined }) => Promise<void>;
  commentEdit?: (data: { comment: string; files: File | undefined }) => Promise<void>;
  setViewEditor: () => void;
  type?: 'add' | 'edit';
  quillCurrent?: DeltaStatic;
}

const EditorText = ({
  ref,
  commentAdd,
  setViewEditor,
  type = 'add',
  quillCurrent,
  commentEdit,
}: Props) => {
  const theme = 'snow';
  const { t } = useTranslation();
  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ align: [] }],

      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ indent: '-1' }, { indent: '+1' }],

      [{ size: ['small', false, 'large', 'huge'] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ color: [] }, { background: [] }],
      ['image'],
      ['clean'],
    ],
    clipboard: {
      matchVisual: false,
    },
  };

  const placeholder = t('studentVirtualClassroom.forum.editor.placeholder');

  const formats = [
    'bold',
    'italic',
    'underline',
    'strike',
    'align',
    'list',
    'indent',
    'size',
    'header',
    'color',
    'background',
    'image',
    'clean',
  ];

  const { quill, quillRef } = useQuill({ theme, modules, formats, placeholder });

  useEffect(() => {
    if (type === 'edit' && quillCurrent) {
      quill?.setContents(quillCurrent);
    }
  }, [quillCurrent, quill, type]);
  return (
    <div
      ref={ref}
      style={{
        marginBottom: '30px',
      }}
    >
      <div
        style={{
          minHeight: '200px',
          color: '#000',
        }}
        ref={quillRef}
      />
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
          mt: 2,
        }}
      >
        <Button
          variant="outlined"
          sx={{
            mr: 2,
          }}
          onClick={setViewEditor}
        >
          {t('studentVirtualClassroom.forum.editor.cancel')}
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            const comment = quill?.getContents();
            if (comment) {
              const { ops } = comment;
              if (ops) {
                if (ops.length > 0 && ops[0]?.insert !== '\n') {
                  const data = {
                    comment: JSON.stringify(quill?.getContents()),
                    files: undefined,
                  };
                  if (type === 'add') {
                    commentAdd(data);
                  } else {
                    if (commentEdit) {
                      commentEdit(data);
                    }
                  }
                  quill?.setContents({} as DeltaStatic);
                }
              }
            }
          }}
        >
          <ChatBubbleOutline
            sx={{
              mr: 1,
            }}
          />
          {t('studentVirtualClassroom.forum.editor.send')}
        </Button>
      </Box>
    </div>
  );
};

export default EditorText;
