import { getProtocol } from '../helpers/urlHelpers';
const useGetBaseUrl = () => {
  const protocol = getProtocol();

  const baseUrl = `${protocol}://${localStorage.getItem('apiUrl')}/api`;

  return {
    baseUrl: baseUrl,
    urlDownload: `${baseUrl}/show?name=`,
  };
};

export default useGetBaseUrl;
