import { Avatar, Grid } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { useTableSearch } from '../../../../hooks/useTableSearch';
import EditButton from '../../../shared/components/tables/buttons/EditButton';
import SearchPaginatedTable, {
  FilterArrow,
} from '../../../shared/components/tables/SearchPaginatedTable';
import { useGetPosgraduatePlanningDayTypesQuery } from '../slices/posgraduatePlanningDayTypesApiSlice';
import {
  setPosgraduatePlanningDayTypeFormDialogOpen,
  setPosgraduatePlanningDayTypeIdSelected,
  setPosgraduatePlanningDayTypesList,
  setPosgraduatePlanningDayTypesTable,
} from '../slices/posgraduatePlanningDayTypesSlice';

const PosgraduatePlanningDayTypesTable = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const {
    posgraduatePlanningDayTypesTable: { page, perPage, search },
  } = useAppSelector((state) => state.posgraduatePlanningDayTypes);

  const [filterArrow, setFilterArrow] = useState<FilterArrow>(null!);

  const { setPage, setPerPage, setSearch } = useTableSearch({
    page,
    perPage,
    search,
    updateFunction: setPosgraduatePlanningDayTypesTable,
  });

  const { data, isLoading, error, isFetching } = useGetPosgraduatePlanningDayTypesQuery({
    page,
    perPage,
    search,
    ...(filterArrow && {
      sort: filterArrow.name,
      type_sort: filterArrow.direction,
    }),
  });

  const datafetch =
    data?.data?.map((item, index) => ({
      id: index + ((page - 1) * perPage + 1),
      description: item.pdt_description_day,
      color: item.pdt_colour,
      days: item.pdt_number_of_days,
      options: { id: item.id },
    })) || [];

  useEffect(() => {
    if (data?.data) {
      dispatch(setPosgraduatePlanningDayTypesList(data.data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleEdit = (id: string) => {
    dispatch(setPosgraduatePlanningDayTypeIdSelected(id));
    dispatch(setPosgraduatePlanningDayTypeFormDialogOpen(true));
  };

  return (
    <>
      <SearchPaginatedTable
        data={datafetch}
        error={error}
        isLoading={isLoading}
        isFetching={isFetching}
        headers={{
          id: 'N°',
          description: t('posgraduate.day_types.table.description'),
          color: t('posgraduate.day_types.table.color'),
          days: t('posgraduate.day_types.table.days'),
          options: t('posgraduate.day_types.table.options'),
        }}
        listFilterArrows={{
          description: 'pdt_description_day',
          color: 'pdt_colour',
          days: 'pdt_number_of_days',
        }}
        filterArrow={filterArrow}
        setFilterArrow={setFilterArrow}
        keyExtractor={(item) => item.id.toString()}
        numHeader={5}
        setPage={setPage}
        setPerPage={setPerPage}
        setSearch={setSearch}
        total={data?.total || 0}
        page={page}
        perPage={perPage}
        search={search}
        searchPlacehoder={t('posgraduate.day_types.table.search')}
        ActionButtons={<></>}
        customHeadersCellsProperties={{
          id: {
            align: 'center',
          },
          description: {
            align: 'center',
          },
          color: {
            align: 'center',
          },
          days: {
            align: 'center',
          },
          options: {
            align: 'center',
          },
        }}
        customDataCellsProperties={{
          id: {
            align: 'center',
          },
          description: {
            align: 'center',
          },
          color: {
            align: 'center',
          },
          days: {
            align: 'center',
          },
          options: {
            align: 'center',
          },
        }}
        customRenderers={{
          color: (row) => (
            <Grid container spacing={1} alignItems="center" justifyContent="center">
              <Avatar
                sx={{
                  width: 20,
                  height: 20,
                  backgroundColor: row.color,
                }}
              >
                {''}
              </Avatar>
              {row.color}
            </Grid>
          ),
          options: (row) => (
            <>
              <EditButton handleEdit={() => handleEdit(row.options.id)} />
            </>
          ),
        }}
      />
    </>
  );
};

export default PosgraduatePlanningDayTypesTable;
