import { FC, useMemo, useState, useEffect, useCallback } from 'react';

import {
  Alert,
  Checkbox,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from '@material-ui/core';

import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import { renderErrorOrEmptyRow, renderSkeletonRows } from '../../../../shared/helpers/render';
import { setSelectedDebts } from '../../slices/debtsPaymentSlice';
import AddPaymountMethod from '../AddPaymountMethod';
import AmountsPaysTable from './components/AmountsPaysTable';
// import AddButton from '../../../../shared/components/tables/buttons/AddButton';
import { showWithDecimals } from '../../../../shared/helpers';
import Chip from '../../../../shared/components/extended/Chip';
import AutoSelectDebtorStudent from './components/AutoSelectDebtorStudent';
import { PaymentsDebts } from '../../../../students/payments-history/interfaces/paymentsHistory.interface';

interface Props {
  //   handleOpenClientSideDialog: () => void;
  handleValidStep: (param: boolean) => void;
}

const ConfirmFees: FC<Props> = (props) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const defaultConfiguration = useAppSelector((state) => state.access.defaultConfiguration);

  const { handleValidStep } = props;

  const {
    selectedDebtsId,
    debtsList,
    selectedDebts,
    payCollection,
    totalDebtAmount,
    totalPayAmount,
    isPositiveBalanceTransac,
  } = useAppSelector((state) => state.debtsPayment);

  //el monto de los pagos debe ser mayor que el monto de las deudas
  // const [isTotalPaid, setIsTotalPaid] = useState(false);

  // useEffect(() => {
  //   if (totalPayAmount >= totalDebtAmount) setIsTotalPaid(true);
  //   else setIsTotalPaid(false);
  // }, [totalDebtAmount, totalPayAmount]);

  const selected = useMemo(() => selectedDebts, [selectedDebts]);

  //estado para controlar temporalmente los check seleccionados
  const [tempSelectedDebts, setTempSelectedDebts] = useState<PaymentsDebts[]>(selected);

  useEffect(() => {
    if (payCollection.length && (selected.length || isPositiveBalanceTransac))
      handleValidStep(true);
    else handleValidStep(false);
  }, [handleValidStep, isPositiveBalanceTransac, payCollection.length, selected.length]);

  //distribucion de los metodos y montos del pago a cada cuota
  const paysPerFee: { [key: number]: number } = useMemo(() => {
    let payAmount = totalPayAmount;
    let paysFees: { [key: number]: number } = {};
    // let totalPayed = 0;

    tempSelectedDebts.forEach((element, i) => {
      const feeBalance = element.apply_early_payment
        ? Number(element.net_value_early_payment)
        : Number(element.fees_balance);

      if (feeBalance <= payAmount) {
        paysFees[+element.fees_identifier] = feeBalance;
        payAmount -= Number(feeBalance.toFixed(2));
        // totalPayed += Number(feeBalance.toFixed(2));
      } else {
        //cuando es saldo a favor
        paysFees[+element.fees_identifier] = Math.round((payAmount + Number.EPSILON) * 100) / 100;
        payAmount = 0;
        // totalPayed += Number(feeBalance.toFixed(2));
      }
    });

    return paysFees;
  }, [tempSelectedDebts, totalPayAmount]);

  const filterDebtList = useMemo(() => {
    return debtsList?.data
      ?.filter((debt) =>
        selectedDebtsId.some((selected) => Number(debt.fees_identifier) === selected)
      )
      .map((filtered, i) => {
        //si se cambia algo aqui tambien debe cambiarse el map del dispatch setSelectedDebts
        return {
          ...filtered,
          fees_value_payment: selected.find(
            (sel) => sel.fees_identifier === filtered.fees_identifier
          )
            ? paysPerFee[Number(filtered.fees_identifier)]
            : 0,
        };
      });
  }, [debtsList?.data, selectedDebtsId, selected, paysPerFee]);

  const [outRangeState, setOutRangeState] = useState<number[]>();
  const [isOutRange, setIsOutRange] = useState<boolean>();

  // const [remainingPayment, setRemainingPayment] = useState<number>(totalPayAmount);

  //control open side dialog add payment
  const [open, setOpen] = useState(false);
  const [indexToEdit, setIndexToEdit] = useState<number>();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //funcion para editar el efectivo y no repetirlo
  const handleSetEditPayment = useCallback((indexToEdit: number | undefined) => {
    setIndexToEdit(indexToEdit);
  }, []);

  //select all checkbox
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setTempSelectedDebts(debtsList?.data!);
      return;
    }

    setTempSelectedDebts([]);
    // setSelected([]);
  };

  const handleClick = (
    // event: React.MouseEvent<HTMLTableHeaderCellElement, MouseEvent>,
    event: React.ChangeEvent<HTMLInputElement>,
    feeId: number
  ) => {
    const selectedIndex = tempSelectedDebts.map((sel) => +sel.fees_identifier).indexOf(feeId);
    let newSelected: number[] = [];

    let isUnSelected = false;

    const currentData = filterDebtList?.find((el) => +el.fees_identifier === feeId);

    if (selectedIndex === -1) {
      const outRange = filterDebtList!.filter(
        (dt) => Date.parse(dt.fees_expiration_date) < Date.parse(currentData?.fees_expiration_date!)
      );

      //comportamiento de bloqueo
      // hacer un filter de la data en base a los seleccionados y comparar el lenght
      if (outRange.length) {
        const dataSelected = outRange.filter((dt) =>
          tempSelectedDebts.some((sel) => +sel.fees_identifier === +dt.fees_identifier)
        );

        if (dataSelected.length !== outRange.length) {
          setOutRangeState(
            outRange
              .filter((dt) =>
                filterDebtList!.some((selDt) => selDt.fees_identifier === dt.fees_identifier)
              )
              .map((fil) => +fil.fees_identifier)
          );
          setIsOutRange(true);
          return;
        } else {
          setOutRangeState([]);
          setIsOutRange(false);
        }
      } else {
        setOutRangeState([]);
        setIsOutRange(false);
      }

      //comportamiento de autoselect
      outRange.forEach((element) => {
        const isStored = tempSelectedDebts.some(
          (dt) => +dt.fees_identifier === +element.fees_identifier
        );
        if (!isStored) newSelected = newSelected.concat(+element.fees_identifier);
      });

      newSelected = newSelected.concat(
        tempSelectedDebts.map((e) => +e.fees_identifier),
        feeId
      );
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(tempSelectedDebts.map((e) => +e.fees_identifier).slice(1));
      isUnSelected = true;
    } else if (selectedIndex === tempSelectedDebts.length - 1) {
      newSelected = newSelected.concat(
        tempSelectedDebts.map((e) => +e.fees_identifier).slice(0, -1)
      );
      isUnSelected = true;
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        tempSelectedDebts.map((e) => +e.fees_identifier).slice(0, selectedIndex),
        tempSelectedDebts.map((e) => +e.fees_identifier).slice(selectedIndex + 1)
      );
      isUnSelected = true;
    }

    //

    if (isUnSelected) {
      const stored = filterDebtList!.filter((dt) =>
        tempSelectedDebts.some(
          (sel) => +dt.fees_identifier === +sel.fees_identifier && +dt.fees_identifier !== feeId
        )
      );
      stored.forEach((element) => {
        const isOutDate =
          Date.parse(element.fees_expiration_date) > Date.parse(currentData?.fees_expiration_date!);
        if (isOutDate) {
          setIsOutRange(true);
          const outRangeIndex = newSelected.indexOf(+element.fees_identifier);
          newSelected.splice(outRangeIndex, 1);
        } else {
          setIsOutRange(false);
        }
      });
    }

    setTempSelectedDebts(
      filterDebtList!.filter((e) => newSelected.some((sel) => +e.fees_identifier === sel))
    );

    // dispatch(
    //   setSelectedDebts(
    //     filterDebtList!.filter((e) => newSelected.some((sel) => +e.fees_identifier === sel))
    //   )
    // );
  };

  useEffect(() => {
    dispatch(
      setSelectedDebts(
        tempSelectedDebts.map((mapped, i) => {
          return {
            ...mapped,
            fees_value_payment: paysPerFee[+mapped.fees_identifier],
          };
        })
      )
    );
  }, [dispatch, paysPerFee, tempSelectedDebts, totalDebtAmount, totalPayAmount]);

  const renderRow = (debt: PaymentsDebts, i: number, debtsArr: PaymentsDebts[]) => {
    return (
      <TableRow
        hover
        className="table-row"
        key={debt.fees_identifier.toString()}
        //   sx={{ cursor: 'pointer', backgroundColor: selected === debt.id ? '#eee' : '' }}
        onClick={() => {
          // setSelected(debt.id);
        }}
      >
        <TableCell>{debt.fees_name_identifier}</TableCell>
        <TableCell>
          {debt.per_current_year && debt.per_due_year
            ? `${debt.per_current_year}-${debt.per_due_year}`
            : ''}
        </TableCell>
        <TableCell>{debt.fees_expiration_date}</TableCell>
        <TableCell>
          {debt.apply_early_payment === null ? (
            <Chip
              size="small"
              label={t('financial.debts_payment.wizard.confirm_fees.apply_early_payment_badge_na')}
              chipcolor="primary"
            />
          ) : debt.apply_early_payment ? (
            <Chip
              size="small"
              label={t('financial.debts_payment.wizard.confirm_fees.apply_early_payment_badge_yes')}
              chipcolor="success"
            />
          ) : (
            <Chip
              size="small"
              label={t('financial.debts_payment.wizard.confirm_fees.apply_early_payment_badge_no')}
              chipcolor="error"
            />
          )}
        </TableCell>
        <TableCell>{debt.discount_early_payment}</TableCell>
        <TableCell>{debt.fees_balance}</TableCell>
        <TableCell>
          {debt.apply_early_payment ? debt.net_value_early_payment : debt.fees_balance}
        </TableCell>
        <TableCell>
          {defaultConfiguration?.currency_symbol}
          {showWithDecimals(debt.fees_value_payment || 0)}
        </TableCell>
        <TableCell>
          <Checkbox
            //   disabled={page > 1}
            {...(outRangeState?.some((dt) => dt === +debt.fees_identifier) && {
              sx: { color: 'red' },
            })}
            checked={tempSelectedDebts.some(
              (r) => Number(r.fees_identifier) === Number(debt.fees_identifier)
            )}
            onChange={(e) => handleClick(e, Number(debt.fees_identifier))}
          />
        </TableCell>
        {/* <TableCell
      // width="30%"
      // align="center"
      // sx={{ borderLeft: 1, borderColor: 'grey.200', paddingX: '10px' }}
      ></TableCell> */}
      </TableRow>
    );
  };

  const renderRows = () => {
    return <>{filterDebtList?.map(renderRow)}</>;
  };

  return (
    <>
      <Grid item xs={12}>
        <Grid container item xs={12} justifyContent={'flex-start'} p={1}>
          {/* <Button variant="outlined" onClick={handleOpen}>
            agregar pago
          </Button> */}
          {/* <AddButton
            variant="outlined"
            onClick={handleOpen}
            btnText={t('financial.debts_payment.wizard.confirm_fees.add_payment_btn')}
          /> */}
        </Grid>

        <AmountsPaysTable handleOpen={handleOpen} />

        {isPositiveBalanceTransac ? (
          <AutoSelectDebtorStudent />
        ) : (
          <>
            <Grid container my={1}>
              <Grid item xs={12}>
                {isOutRange && (
                  <Alert severity="warning">
                    {t('financial.debts_payment.wizard.invalid_debt_selection_alert')}
                  </Alert>
                )}
              </Grid>
            </Grid>

            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow sx={{ backgroundColor: theme.palette.grey[200] }}>
                    <TableCell>
                      {t('financial.debts_payment.wizard.confirm_fees.table.debt_description')}
                    </TableCell>
                    <TableCell>
                      {t('financial.debts_payment.wizard.confirm_fees.table.period_school')}
                    </TableCell>
                    <TableCell>
                      {t('financial.debts_payment.wizard.confirm_fees.table.due_date')}
                    </TableCell>
                    <TableCell>
                      {t(
                        'financial.debts_payment.wizard.confirm_fees.table.early_payment_percentage'
                      )}
                    </TableCell>
                    <TableCell>
                      {t('financial.debts_payment.wizard.confirm_fees.table.discount')}
                    </TableCell>
                    <TableCell>
                      {t('financial.debts_payment.wizard.confirm_fees.table.debt_value')}
                    </TableCell>
                    <TableCell>
                      {t('financial.debts_payment.wizard.confirm_fees.table.total_debt')}
                    </TableCell>
                    <TableCell>
                      {t('financial.debts_payment.wizard.confirm_fees.table.pay_amount')}
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        color="primary"
                        // disabled={!filterDebtList?.data}
                        indeterminate={
                          selected.length > 0 && selected.length < (filterDebtList?.length || 0)
                        }
                        checked={
                          (filterDebtList?.length || 0) > 0 &&
                          selected.length === (filterDebtList?.length || 0)
                        }
                        onChange={handleSelectAllClick}
                        inputProps={{
                          'aria-label': 'select all desserts',
                        }}
                      />
                    </TableCell>
                    {/* <TableCell>Identificación</TableCell>
                  <TableCell>Nombre</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!filterDebtList ? renderSkeletonRows(5, 5) : renderRows()}
                  {filterDebtList && (
                    <TableRow>
                      <TableCell colSpan={6} align="right">
                        <b>{t('financial.debts_payment.wizard.confirm_fees.table.total')}</b>
                      </TableCell>
                      <TableCell>
                        {defaultConfiguration?.currency_symbol}
                        {showWithDecimals(totalDebtAmount)}
                      </TableCell>
                      <TableCell colSpan={2}>
                        {defaultConfiguration?.currency_symbol}
                        {showWithDecimals(totalPayAmount)}
                      </TableCell>
                    </TableRow>
                  )}

                  {filterDebtList?.length === 0 && renderErrorOrEmptyRow(5)}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}

        {/* {!isTotalPaid && (
          <Grid container my={1}>
            <Grid item xs={12}>
              <Alert severity="warning">
                El monto total de los pagos debe ser igual o mayor al monto total de las deudas
                </Alert>
                </Grid>
          </Grid>
        )} */}
      </Grid>

      {/* dialogo para agregar formas de pagos */}
      {open && (
        <AddPaymountMethod
          open={open}
          handleCloseDialog={handleClose}
          //para cuando es efectivo y ya existe agregar el indice del array
          indexPayToEdit={indexToEdit}
          //para cuando es efectivo y ya existe fn agregar el indice del array
          handleSetEditPayment={handleSetEditPayment}
        />
      )}
    </>
  );
};

export default ConfirmFees;
