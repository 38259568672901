import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  ScrollHeight: {
    height: '100%',
    maxHeight: 'calc(100vh - 290px)',
    overflowX: 'hidden',
    // maxWidth: '400px',
  },
  headerAvatar: {
    ...theme.typography.commonAvatar,
    ...theme.typography.mediumAvatar,
    transition: 'all .2s ease-in-out',
    background:
      theme.palette.mode === 'dark' ? theme.palette.primary.main : theme.palette.primary.main,
    color: theme.palette.mode === 'dark' ? theme.palette.warning.dark : 'white',
    '&[aria-controls="menu-list-grow"],&:hover': {
      background:
        theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.secondary.dark,
      color:
        theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.secondary.light,
    },
  },
  cardContent: {
    padding: '0px !important',
    maxWidth: '400px',
  },
  notificationChip: {
    color: theme.palette.background.default,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.primary[100] : theme.palette.primary[100],
  },
  divider: {
    marginTop: 0,
    marginBottom: 0,
  },
  cardAction: {
    padding: '10px',
    justifyContent: 'center',
  },
  paddingBottom: {
    paddingBottom: '16px',
  },
  box: {
    marginLeft: '5px',
    marginRight: '25px',
    [theme.breakpoints.down('sm')]: {
      marginRight: '16px',
    },
  },
  bodyPPacing: {
    padding: '16px 16px 0',
  },
  textBoxSpacing: {
    padding: '0px 16px',
  },
}));
