import Autocomplete from '@material-ui/core/Autocomplete';
import TextField from '@material-ui/core/TextField';

import { useTranslation } from 'react-i18next';

import { useAppSelector, useAppDispatch } from '../../../../../hooks/useRedux';
import { StudentRecord } from '../../../../shared/interfaces/searchPerson.interface';
import {
  setCurrentStudentRecord,
  setCurrentStudentRecordMassiveDebts,
} from '../../slices/generateDebtsSlice';

const SelectStudentRecord = () => {
  const dispatch = useAppDispatch();

  const {t} = useTranslation()

  const {
    currentClient,
    currentClientMassiveDebts,
    currentStudentRecord,
    currentStudentRecordMassiveDebts,
    currentTab,
  } = useAppSelector((state) => state.generateDebts);

  const handleChangeCurrentStudentRecord = (studentRecord: StudentRecord) => {
    if (studentRecord)
      if (currentTab === 0) dispatch(setCurrentStudentRecord(studentRecord));
      else if (currentTab === 1) dispatch(setCurrentStudentRecordMassiveDebts(studentRecord));
  };
  return (
    <Autocomplete
      //   disabled={Boolean(indexPayToEdit?.toString()) && isEdit}
      options={
        currentTab === 0
          ? currentClient?.user.student?.student_records || []
          : currentClientMassiveDebts?.user.student?.student_records || []
      }
      getOptionLabel={(option) => option.education_level.edu_name}
      // size="medium"
      renderInput={(params) => (
        <TextField
          {...params}
          // error={Boolean(errors.campusIds && touched.campusIds)}
          placeholder={t('financial.generate_debts.select_stud_record_component.placeholder')}
          label={t("financial.generate_debts.select_stud_record_component.label")}
          variant="standard"
        />
      )}
      value={
        currentTab === 0
          ? currentClient?.user.student?.student_records.find(
              (dt) => dt.education_level_id === currentStudentRecord?.education_level_id
            ) || null
          : currentClientMassiveDebts?.user.student?.student_records.find(
              (dt) => dt.education_level_id === currentStudentRecordMassiveDebts?.education_level_id
            ) || null
      }
      fullWidth
      clearIcon={false}
      onChange={(_, values) => {
        if (values) handleChangeCurrentStudentRecord(values);
      }}
    />
  );
};

export default SelectStudentRecord;
