/* eslint-disable no-empty-pattern */

import { mainApi } from '../../../../../../api/mainApi';
import { middlewareApi } from '../../../../../middleware/middlewareApi';
import { IResponse } from '../../../../../shared/interfaces/response.interface';
import { IQuestion } from '../interfaces/questions.interface';
import { QuestionTypeData } from '../interfaces/questions_types.interfaces';

export const questionsTags = middlewareApi.enhanceEndpoints({
  addTagTypes: ['Questions'],
});

export const questionsApi = questionsTags.injectEndpoints({
  endpoints: (builder) => ({
    getQuestions: builder.query<
      IResponse<IQuestion>,
      {
        page: number;
        perPage: number;
        search: string;
        scopeId?: string;
        status?: string;
        sort?: string;
        typeSort?: string;
      }
    >({
      queryFn: async ({ page, perPage, search, scopeId, sort, typeSort, status }, { dispatch }) => {
        try {
          const { data } = await mainApi.get<IResponse<IQuestion>>('/eva/questions', {
            params: {
              page,
              size: perPage,
              search,
              ...(scopeId && { category_uuid: scopeId }),
              ...(status && { status }),
              ...(sort && { sort }),
              ...(typeSort && { type_sort: typeSort }),
            },
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) =>
        result && result.data
          ? [
              ...result.data.map(({ uuid: id }) => ({ type: 'Questions', id } as const)),
              { type: 'Questions', id: 'LIST' },
            ]
          : [{ type: 'Questions', id: 'LIST' }],
    }),
    getAllQuestions: builder.query<
      IQuestion[],
      {
        category_uuid?: string;
      }
    >({
      queryFn: async ({ category_uuid }, { dispatch }) => {
        try {
          const { data } = await mainApi.get<IQuestion[]>('/eva/questions', {
            params: {
              data: 'all',
              category_uuid,
            },
          });
          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) => [{ type: 'Questions', id: 'LIST' }],
    }),
    getQuestion: builder.query<
      IQuestion,
      {
        id: string;
      }
    >({
      queryFn: async ({ id }, { dispatch }) => {
        try {
          const { data } = await mainApi.get<IQuestion>(`/eva/questions/${id}`);
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: [{ type: 'Questions', id: 'LIST' }],
    }),
    addQuestion: builder.mutation<
      IQuestion,
      {
        name: string;
        description: string;
        category_uuid: string;
        status: string;
        que_type_uuid?: string;
      }
    >({
      queryFn: async (
        { description, category_uuid, status, name, que_type_uuid },
        { dispatch }
      ) => {
        try {
          const { data } = await mainApi.post<IQuestion>('/eva/questions', {
            description,
            category_uuid,
            status,
            name,
            que_type_uuid,
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'Questions', id: 'LIST' }],
    }),
    updateQuestion: builder.mutation<
      IQuestion,
      {
        id: string;
        description: string;
        category_uuid: string;
        status: string;
        name: string;
        que_type_uuid: string;
      }
    >({
      queryFn: async (
        { id, description, category_uuid, status, name, que_type_uuid },
        { dispatch }
      ) => {
        try {
          const { data } = await mainApi.put<IQuestion>(`/eva/questions/${id}`, {
            description,
            category_uuid,
            status,
            name,
            que_type_uuid,
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'Questions', id: 'LIST' }],
    }),
    deleteQuestion: builder.mutation<
      IQuestion,
      {
        id: string;
      }
    >({
      queryFn: async ({ id }, { dispatch }) => {
        try {
          const { data } = await mainApi.delete<IQuestion>(`/eva/questions/${id}`);
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'Questions', id: 'LIST' }],
    }),

    getQuestionsTypesAll: builder.query<QuestionTypeData[], {}>({
      queryFn: async ({}, { dispatch }) => {
        try {
          const { data } = await mainApi.get<QuestionTypeData[]>('/eva/que-types', {
            params: {
              data: 'all',
            },
          });
          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
    }),
  }),
});

export const {
  useGetQuestionsQuery,
  useLazyGetQuestionsQuery,
  useGetAllQuestionsQuery,
  useGetQuestionQuery,
  useLazyGetQuestionQuery,
  useAddQuestionMutation,
  useUpdateQuestionMutation,
  useDeleteQuestionMutation,
  useGetQuestionsTypesAllQuery,
} = questionsApi;
