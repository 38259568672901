import { Box, Grid, Tab } from '@material-ui/core';
import { gridSpacing } from '../../../shared/constants/uiConstants';
import { useState } from 'react';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { tabsInfoOptions } from '../constants/tabsInfoOptions';
import FinanceCard from './FinanceCard';
import MisionVision from '../components/MisionVision';
import ServicesCompanies from './ServicesCompanies';

interface TabsInformationProps {
  isLoading: boolean;
}

const TabsInformation = ({ isLoading }: TabsInformationProps) => {
  const [value, setValue] = useState('1');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <Grid item lg={8} xs={12}>
      <Grid container direction="column" spacing={gridSpacing}>
        <TabContext value={value}>
          <Box sx={{ width: '100%', px: 4 }}>
            <TabList onChange={handleChange}>
              {tabsInfoOptions.map((tabInfoOption) => (
                <Tab label={tabInfoOption.label} value={tabInfoOption.value} />
              ))}
            </TabList>
          </Box>
          <TabPanel value="1">
            <MisionVision isLoading={isLoading} />
          </TabPanel>
          <TabPanel value="2">
            <FinanceCard isLoading={isLoading} />
          </TabPanel>
          <TabPanel value="3">
            <ServicesCompanies />
          </TabPanel>
        </TabContext>
      </Grid>
    </Grid>
  );
};

export default TabsInformation;
