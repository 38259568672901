import { Button, CircularProgress, Dialog, Grid, Typography, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { IconFileInfo, IconCircleCheck, IconFileAlert } from '@tabler/icons';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { useDeleteClassroomEdLvlMutation } from '../slices/classroomEdLvlApiSlice';
import { resetClassroomEdLvlIdToDelete, setDeleteModalOpen } from '../slices/classroomEdLvlSlice';

const useStyles = makeStyles((theme) => ({
  paper: { maxWidth: '600px', width: '90%' },
}));

const DeleteClassroomEdLvlDialog = () => {
  const { t } = useTranslation();

  const theme = useTheme();
  const classes = useStyles();

  const dispatch = useAppDispatch();

  const { deleteModalOpen, classroomEdLvlIdToDelete } = useAppSelector(
    (state) => state.classroomEdLvl
  );
  const { currentProfile } = useAppSelector((state) => state.access);

  const [deletePeriod, { isLoading: isDeleting, isSuccess, isError, error }] =
    useDeleteClassroomEdLvlMutation();

  const handleDelete = () => {
    deletePeriod({
      profileId: currentProfile?.profileId!,
      classroomEdLvlId: classroomEdLvlIdToDelete!,
    });
  };

  const handleClose = () => {
    dispatch(setDeleteModalOpen(false));
    dispatch(resetClassroomEdLvlIdToDelete());
  };

  return (
    <Dialog
      classes={{ paper: classes.paper }}
      open={deleteModalOpen}
      onClose={handleClose}
      sx={{ p: 3 }}
    >
      <Grid container gap={4} sx={{ p: 2 }}>
        <Grid item container xs={12} alignItems="center" justifyContent="center">
          {!isError && !isSuccess && !isDeleting && (
            <IconFileInfo
              size={60}
              color={theme.palette.error.main}
              style={{
                padding: 12,
                backgroundColor: theme.palette.orange.light,
                borderRadius: '50%',
              }}
            />
          )}
          {isDeleting && <CircularProgress size={50} />}
          {isSuccess && (
            <IconCircleCheck
              size={60}
              color={theme.palette.success.main}
              style={{
                padding: 12,
                backgroundColor: theme.palette.success.light,
                borderRadius: '50%',
              }}
            />
          )}
          {isError && (
            <IconFileAlert
              size={60}
              color={theme.palette.error.main}
              style={{
                padding: 12,
                backgroundColor: theme.palette.orange.light,
                borderRadius: '50%',
              }}
            />
          )}
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Typography variant="h2" sx={{ color: theme.palette.primary.main, mb: 1 }}>
            {!isError && !isSuccess && t('academic.classrooms_distribution.alert.delete.confirm')}
            {isSuccess && t('academic.classrooms_distribution.alert.delete.success')}
            {isError && t('academic.classrooms_distribution.alert.delete.error')}
          </Typography>
          <Typography variant="body2">
            {!isError &&
              !isSuccess &&
              t('academic.classrooms_distribution.alert.delete.confirm.description')}
            {isError && error}
          </Typography>
        </Grid>
        <Grid item container xs={12} justifyContent="center" gap={1}>
          {!isSuccess && !isError && (
            <Button
              variant="text"
              size="large"
              color="primary"
              onClick={handleClose}
              disabled={isDeleting}
            >
              {t('academic.classrooms_distribution.alert.delete.cancel')}
            </Button>
          )}

          {(isSuccess || isError) && (
            <Button variant="contained" size="large" color="primary" onClick={handleClose}>
              {t('academic.classrooms_distribution.alert.delete.continue')}
            </Button>
          )}

          {!isSuccess && !isError && (
            <Button
              variant="contained"
              size="large"
              onClick={handleDelete}
              color="primary"
              autoFocus
              disabled={isDeleting}
            >
              {t('academic.classrooms_distribution.alert.delete.delete')}
            </Button>
          )}
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default DeleteClassroomEdLvlDialog;
