import * as Yup from 'yup';

export const ValidationSchema = Yup.object().shape({
  comName: Yup.string().required('El nombre es requerido.'),
  comAcronym: Yup.string().required('El acrónimo es requerido'),
  areaId: Yup.string().nullable(),

  statusId: Yup.number()
    .integer('Solo se permiten números enteros.')
    .min(1, 'La cantidad mínima de materias es 1.')
    .required('El  estado es requerido'),

  process: Yup.string().nullable().required('El proceso es requerido'),
});
