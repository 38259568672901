import { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';

// material-ui
import { ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline, StyledEngineProvider } from '@material-ui/core';

import { initDB } from 'react-indexed-db';

// routing
import AppRouter from './router/AppRouter';

// project imports
import NavigationScroll from './modules/shared/layout/NavigationScroll';

// redux hooks
import { useAppSelector } from './hooks/useRedux';

// default theme
import theme from './modules/shared/themes';

// i18next - translations
import './translation/settings';
import i18next from './translation/settings';

import { disableReactDevTools } from './utils/disableReactDevTools';
import { DBConfig } from './utils/DBConfig';

initDB(DBConfig);

const App = () => {
  const customization = useAppSelector((state) => state.customization);

  useEffect(() => {
    //i18next.changeLanguage(customization.language);
    i18next.changeLanguage('es');
  }, [customization.language]);

  if (process.env.NODE_ENV === 'production') {
    disableReactDevTools();
  }

  /**
   * BrowserRouter
   * this HOC wrap to entire app to provide routing service
   */
  return (
    <BrowserRouter>
      {/**
       * Material UI HOCs
       * this HOCs wrap to entire app to provide styles and animations
       */}
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme(customization)}>
          <CssBaseline />
          <NavigationScroll>
            {/**
             * AppRouter
             * this HOC declare all application routes
             * based on Auth status an user roles
             */}
            <AppRouter />
          </NavigationScroll>
        </ThemeProvider>
      </StyledEngineProvider>
    </BrowserRouter>
  );
};

export default App;
