import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IconDownload, IconNotes } from '@tabler/icons';
import { Grid, IconButton, Tooltip } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import download from 'downloadjs';

import {
  useGetRequisitionsQuery,
  useLazyGetRequisitionPDFQuery,
} from '../slices/requisitionsApiSlice';
import SearchPaginatedTableOptions from './SearchPaginatedTable';
// import StatusChip from '../../../shared/components/badges/StatusChip';
import AddButton from '../../../shared/components/tables/buttons/AddButton';
import { useAppSelector } from '../../../../hooks/useRedux';
import { statusList } from '../constants/statusRequisition';
import OmniChip from '../../../shared/components/badges/OmniChip';
import { DataSearch } from './SearchPaginatedTable';
import { setRequisitionList } from '../slices/requisitionsSlice';
import { useAppDispatch } from '../../../../hooks/useRedux';
import { FilterArrow } from '../../../shared/components/tables/SearchPaginatedTable';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import useSharedGuard from '../../../../hooks/useSharedGuard';
import { subPermissionKeys } from '../constants/permissions';

const RequisitionsTable = function () {
  const { t } = useTranslation();

  const history = useHistory();

  const dispatch = useAppDispatch();

  const { currentProfile } = useAppSelector((state) => state.access);

  const { requisitionList } = useAppSelector((state) => state.requisitionTeacher);

  const [isDownloadingPDF, setIsDownloadingPDF] = useState(false);

  const [downloadPdfError, setDownloadPdfError] = useState(null);

  // get first day of the week (Monday) based on the current date
  const startDate = new Date();
  startDate.setDate(startDate.getDate() - startDate.getDay() + 1);
  // get last day of the week (Sunday) based on the current date
  const endDate = new Date();
  endDate.setDate(endDate.getDate() - endDate.getDay() + 7);

  const DataSearchBlank: DataSearch = {
    startEndDate: [startDate, endDate],
    nivelId: 0,
    offerId: 0,
    statusRequisition: '',
  };

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [dataSearch, setDataSearch] = useState<DataSearch>(DataSearchBlank);
  const [filterArrow, setFilterArrow] = useState<FilterArrow>();
  const [perPage, setPerPage] = useState(5);

  const { hasAccess } = useSharedGuard(subPermissionKeys);

  const [getPDF] = useLazyGetRequisitionPDFQuery();

  const { data, isLoading, isFetching, error } = useGetRequisitionsQuery(
    {
      profileId: currentProfile?.profileId!,
      page,
      perPage,
      search,
      ...(dataSearch.offerId && { faculty_id: dataSearch.offerId }),
      ...(dataSearch.nivelId && { faculty_id: dataSearch.nivelId || dataSearch.offerId }),
      ...(dataSearch.startEndDate[0] && { start_date: dataSearch.startEndDate[0] }),
      ...(dataSearch.startEndDate[1] && { end_date: dataSearch.startEndDate[1] }),
      ...(dataSearch.statusRequisition && { status: dataSearch.statusRequisition }),
      ...(filterArrow && { sort: filterArrow.name, type_sort: filterArrow.direction }),
    },
    { refetchOnMountOrArgChange: 30 }
  );

  useEffect(() => {
    setPage(1);
  }, [perPage, search]);

  const datafetch = requisitionList?.map((value, index) => ({
    id: { id: value.id, index: index + ((page - 1) * perPage + 1) },
    code: value.app_code,
    user: value.user,
    description: value.app_description,
    register_date: value.app_register_date,
    estado_actual: value.current_status,
    // status_id: value.status_id,
    options: '',
  }));

  const goToAdd = () => {
    history.push('/administrar-requisiciones-docentes/agregar');
  };

  useEffect(() => {
    if (data) {
      dispatch(setRequisitionList(data.data));
    }
  }, [data, dispatch]);

  const handleDownloadPDF = async (appCode: string) => {
    try {
      setIsDownloadingPDF(true);
      const data = await getPDF({
        profileId: currentProfile?.profileId!,
        appCode,
      }).unwrap();

      if (!data) throw new Error('Ha ocurrido un error');

      const blob = new Blob([data], { type: 'application/pdf' });

      download(blob, `Requisition-${appCode}.pdf`, 'application/pdf');
      setDownloadPdfError(null);
    } catch (error: any) {
      if ('message' in error) setDownloadPdfError((error as any).message as any);
      else setDownloadPdfError(error as any);
    }

    setIsDownloadingPDF(false);
  };

  return (
    <>
      {downloadPdfError !== null && (
        <Grid item xs={12}>
          <ErrorAlert
            type="snackbar"
            message={downloadPdfError as unknown as string}
            handleDismiss={() => setDownloadPdfError(null)}
          />
        </Grid>
      )}

      <SearchPaginatedTableOptions
        data={datafetch!}
        headers={{
          id: 'N°',
          code: t('academic.requisition.table.req_code'),
          user: t('academic.requisition.table.user'),
          description: t('academic.requisition.table.description'),
          register_date: t('academic.requisition.table.reg_date'),
          estado_actual: t('academic.requisition.table.current_status'),
          // status_id: t('academic.requisition.table.status'),
          options: t('academic.requisition.table.options'),
        }}
        listFilterArrows={{
          user: 'p.pers_full_name',
          description: 'app_description',
          register_date: 'app_register_date',
          // estado_actual: 'current_status',
          // status_id: 'status_id',
        }}
        filterArrow={filterArrow}
        setFilterArrow={setFilterArrow}
        keyExtractor={(item) => item.id.id.toString()}
        searchPlacehoder={t('academic.requisition.table.search')}
        isLoading={isLoading}
        isFetching={isFetching}
        error={error}
        perPage={perPage}
        setPerPage={setPerPage}
        total={data?.total || 0}
        page={page}
        setPage={setPage}
        numHeader={8}
        search={search}
        setSearch={setSearch}
        lastPage={data?.last_page}
        setDataSearch={setDataSearch}
        dataSearch={dataSearch}
        customRenderers={{
          id: (row) => row.id.index,
          estado_actual: (row) => (
            <OmniChip
              id={statusList.find((e) => e.name === row.estado_actual)?.id ?? -1}
              statusArray={statusList}
            />
          ),
          options: (row) => (
            <>
              {hasAccess('edit') && (
                <Link to={`/administrar-requisiciones-docentes/${row.id.id}/revisar-solicitud`}>
                  <Tooltip title={t('academic.requisition.table.review')}>
                    <IconButton aria-label={'Cambiar estado'} color="default">
                      <IconNotes />
                    </IconButton>
                  </Tooltip>
                </Link>
              )}

              {hasAccess('pdfForTeacherRequisition') && (
                <Tooltip title={t('academic.requisition.table.download_pdf')}>
                  <IconButton
                    aria-label="Descargar PDF"
                    color="default"
                    onClick={() => handleDownloadPDF(row.code)}
                    disabled={isDownloadingPDF}
                  >
                    <IconDownload />
                  </IconButton>
                </Tooltip>
              )}
            </>
          ),
        }}
        customHeadersCellsProperties={{
          id: { align: 'center' },
          options: {
            align: 'center',
          },
          estado_actual: {
            align: 'center',
          },
          // status_id: {
          //   align: 'center',
          // },
        }}
        customDataCellsProperties={{
          id: {
            align: 'center',
          },
          estado_actual: {
            align: 'center',
          },
          // status_id: {
          //   align: 'center',
          // },
          options: {
            align: 'center',
          },
        }}
        ActionButtons={<> {hasAccess('create') && <AddButton size="large" onClick={goToAdd} />}</>}
      />
    </>
  );
};

export default RequisitionsTable;
