import { Box, Button, Grid, Skeleton, TextField, Typography } from '@material-ui/core';
import { DateRangePicker, LocalizationProvider } from '@material-ui/lab';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import { es } from 'date-fns/locale';
import { useFormik } from 'formik';
import InputLabel from '../../../../shared/components/forms/InputLabel';
import { useLazyMetricsDocentesQuery } from '../../slices/statisticsApiSlice';
import { format } from 'date-fns';
import { useAppSelector } from '../../../../../hooks/useRedux';
import Chart from 'react-apexcharts';
import MetricsMessage from '../MetricsMessage';

const Docentes = () => {
  const { currentProfile } = useAppSelector((state) => state.access);
  const [metrics, { isLoading, data, isUninitialized }] = useLazyMetricsDocentesQuery();

  const { values, errors, touched, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      ini: '',
      end: '',
    },
    onSubmit: (values) => {
      metrics({
        start_date: format(new Date(values.ini), 'yyyy-MM-dd'),
        end_date: format(new Date(values.end), 'yyyy-MM-dd'),
        user_profile_id: currentProfile?.profileId!,
      });
    },
  });

  return (
    <Grid container>
      <Grid item container spacing={2} direction="row-reverse">
        <Grid item xs={12} md={8} lg={8}>
          <InputLabel>Fechas</InputLabel>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
            <DateRangePicker
              value={[values.ini, values.end]}
              onChange={(val) => {
                if (val) {
                  setFieldValue('ini', val[0]);
                  setFieldValue('end', val[1]);
                }
              }}
              renderInput={(startProps, endProps) => (
                <>
                  <TextField
                    {...startProps}
                    label={null}
                    fullWidth
                    placeholder="Fecha"
                    error={Boolean(errors.ini && touched.ini)}
                    helperText={Boolean(errors.ini && touched.ini) && errors.ini}
                  />
                  <Box sx={{ mx: 2 }}> - </Box>
                  <TextField
                    {...endProps}
                    label={null}
                    fullWidth
                    placeholder="Fecha"
                    error={Boolean(errors.end && touched.end)}
                    helperText={Boolean(errors.end && touched.end) && errors.end}
                  />
                </>
              )}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={12} md={12} lg={12} textAlign="right">
          <Button
            variant="contained"
            size="large"
            color="primary"
            sx={{ mt: 2 }}
            onClick={() => handleSubmit()}
          >
            Buscar
          </Button>
        </Grid>
      </Grid>
      <br />
      <br />
      <Grid container spacing={6}>
        {isUninitialized ? (
          <Grid item xs={12}>
            <MetricsMessage />
          </Grid>
        ) : (
          <>
            <Grid item xs={12} md={12} lg={12}>
              <Typography variant="h3" sx={{ m: 2 }}>
                Visitas de Docentes por Facultad y Género
              </Typography>
              <Box sx={{ maxWidth: 600, margin: '0 auto' }}>
                {isLoading ? (
                  <Skeleton width={400} height={400} />
                ) : (
                  <>
                    {data && (
                      <Chart
                        options={{
                          xaxis: {
                            categories: data?.map((el) => el.edu_name) || [],
                            labels: {
                              rotate: 0,
                              style: {
                                fontSize: '8px',
                              },
                            },
                          },
                          dataLabels: {
                            enabled: true,
                            offsetX: -6,
                            style: {
                              fontSize: '12px',
                              colors: ['#fff'],
                            },
                          },
                        }}
                        series={[
                          {
                            name: 'Masculino',
                            data: data.flatMap((el) => el.total_estudiante_masculino),
                          },
                          {
                            name: 'Femenino',
                            data: data.flatMap((el) => el.total_estudiante_femenino),
                          },
                        ]}
                        type="bar"
                        width="100%"
                      />
                    )}
                  </>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h3" sx={{ m: 2 }}>
                Visitas de Docentes por Facultad
              </Typography>
              {data && (
                <Chart
                  options={{
                    labels: data.map((el) => el.edu_name) || [],
                  }}
                  series={data.map(
                    (el) =>
                      Number(el.porcentaje_estudiante_femenino) +
                      Number(el.porcentaje_estudiante_masculino)
                  )}
                  type="donut"
                  width="100%"
                />
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h3" sx={{ m: 2 }}>
                Servicios utilizados por Docentes
              </Typography>
              {data && (
                <Chart
                  options={{
                    labels: ['Libros', 'Computadoras', 'Cubículos', 'Mesas', 'Salones de Estudio'],
                  }}
                  series={[
                    Number(data.flatMap((el) => el.libros)),
                    Number(data.flatMap((el) => el.computadoras)),
                    Number(data.flatMap((el) => el.cubiculos)),
                    Number(data.flatMap((el) => el.mesas)),
                    Number(data.flatMap((el) => el.salones_estudio)),
                  ]}
                  type="donut"
                  width="100%"
                />
              )}
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default Docentes;
