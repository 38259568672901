import {
   Autocomplete,
   //Checkbox,
   Button,
   Grid,
   //Typography,
   TextField,
   /* TableBody,
   TableCell,
   TableContainer,
   TableHead,
   TableRow,
   Table,
   CircularProgress,
   FormHelperText,
   Collapse,
   Box,
   useTheme, */
   // FormControlLabel,
} from '@material-ui/core';
//import { useState } from 'react';
import InputLabel from '../../../shared/components/forms/InputLabel';

const GerentialReport = () => {
   return (<>
      <Grid item xs={12}>
         <Grid container direction="column" gap={2}>
            <Grid item container spacing={2} alignItems="center">
               <Grid item xs={12} md={3}>
                  <InputLabel>Fecha</InputLabel>
                  <Autocomplete
                     options={[]}
                     placeholder="Seleccione Fecha..."
                     //getOptionLabel={option => option.name}
                     renderInput={params => (
                        <TextField {...params}
                           //error={Boolean(errors.statusId && touched.statusId)} 
                           placeholder="Seleccione Fecha"
                        />
                     )}
                     //defaultValue={}
                     onChange={(e, value) => { }
                        //setFieldValue('statusId', value?.id || '')
                     }
                     fullWidth
                     clearIcon={null}
                  />
               </Grid>
               <Grid item xs={12} md={3}>
                  <InputLabel>Sede</InputLabel>
                  <Autocomplete
                     options={[]}
                     placeholder="Seleccione Sede..."
                     //getOptionLabel={option => option.name}
                     renderInput={params => (
                        <TextField {...params}
                           //error={Boolean(errors.statusId && touched.statusId)} 
                           placeholder="Seleccione Sede"
                        />
                     )}
                     //defaultValue={}
                     onChange={(e, value) => { }
                        //setFieldValue('statusId', value?.id || '')
                     }
                     fullWidth
                     clearIcon={null}
                  />
               </Grid>
               <Grid item xs={12} md={3}>
                  <InputLabel>Camapaña</InputLabel>
                  <Autocomplete
                     options={[]}
                     placeholder="Seleccione Camapaña..."
                     //getOptionLabel={option => option.name}
                     renderInput={params => (
                        <TextField {...params}
                           //error={Boolean(errors.statusId && touched.statusId)} 
                           placeholder="Seleccione Camapaña"
                        />
                     )}
                     //defaultValue={}
                     onChange={(e, value) => { }
                        //setFieldValue('statusId', value?.id || '')
                     }
                     fullWidth
                     clearIcon={null}
                  />
               </Grid>
               <Grid item xs={12} md={3}>
                  <InputLabel>Estado Comercial</InputLabel>
                  <Autocomplete
                     options={[]}
                     placeholder="Seleccione Editorial..."
                     //getOptionLabel={option => option.name}
                     renderInput={params => (
                        <TextField {...params}
                           //error={Boolean(errors.statusId && touched.statusId)} 
                           placeholder="Seleccione Editorial"
                        />
                     )}
                     //defaultValue={}
                     onChange={(e, value) => { }
                        //setFieldValue('statusId', value?.id || '')
                     }
                     fullWidth
                     clearIcon={null}
                  />
               </Grid>
               <Grid item xs={12} md={3}>
                  <InputLabel>Oferta Académica</InputLabel>
                  <Autocomplete
                     options={[]}
                     placeholder="Seleccione Oferta Académica..."
                     //getOptionLabel={option => option.name}
                     renderInput={params => (
                        <TextField {...params}
                           //error={Boolean(errors.statusId && touched.statusId)} 
                           placeholder="Seleccione Oferta Académica"
                        />
                     )}
                     //defaultValue={}
                     onChange={(e, value) => { }
                        //setFieldValue('statusId', value?.id || '')
                     }
                     fullWidth
                     clearIcon={null}
                  />
               </Grid>
               <Grid item xs={12} md={3}>
                  <InputLabel>Programas</InputLabel>
                  <Autocomplete
                     options={[]}
                     placeholder="Seleccione Programas..."
                     //getOptionLabel={option => option.name}
                     renderInput={params => (
                        <TextField {...params}
                           //error={Boolean(errors.statusId && touched.statusId)} 
                           placeholder="Seleccione Programas"
                        />
                     )}
                     //defaultValue={}
                     onChange={(e, value) => { }
                        //setFieldValue('statusId', value?.id || '')
                     }
                     fullWidth
                     clearIcon={null}
                  />
               </Grid>
               <Grid item xs={12} md={3}>
                  <InputLabel>Asesor</InputLabel>
                  <Autocomplete
                     options={[]}
                     placeholder="Seleccione Autor..."
                     //getOptionLabel={option => option.name}
                     renderInput={params => (
                        <TextField {...params}
                           //error={Boolean(errors.statusId && touched.statusId)} 
                           placeholder="Seleccione Autor"
                        />
                     )}
                     //defaultValue={}
                     onChange={(e, value) => { }
                        //setFieldValue('statusId', value?.id || '')
                     }
                     fullWidth
                     clearIcon={null}
                  />
               </Grid>
               <Grid item xs={12} md={3}>
                  <InputLabel>Fuente de Contacto</InputLabel>
                  <Autocomplete
                     options={[]}
                     placeholder="Seleccione Fuente de Contacto..."
                     //getOptionLabel={option => option.name}
                     renderInput={params => (
                        <TextField {...params}
                           //error={Boolean(errors.statusId && touched.statusId)} 
                           placeholder="Seleccione Fuente de Contacto"
                        />
                     )}
                     //defaultValue={}
                     onChange={(e, value) => { }
                        //setFieldValue('statusId', value?.id || '')
                     }
                     fullWidth
                     clearIcon={null}
                  />
               </Grid>

            </Grid>
         </Grid>
      </Grid>
      <Grid
         item
         container
         alignItems="center"
         justifyContent="flex-end"
         spacing={2}
         sx={{ mt: 1 }}
      >
         <Grid item>
            <Button
               variant="contained"
               size="large"
               color="primary"
               onClick={() => {
                  /* if (filtersSearch) {
                      refetchClassroomData();
                  } else {
                      setDisplayFilters(false);
                  }
                  setFiltersSearch(true); */
               }}
            //disabled={!searchBtn}
            >
               Generar PDF
            </Button>
         </Grid>
      </Grid>
   </>)
}

export default GerentialReport;