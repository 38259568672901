import { Fragment, useRef } from 'react';
import { createPortal } from 'react-dom';

import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { DownloadTableExcel } from 'react-export-table-to-excel';

import IconXLS from '../../../../../../../assets/icons/excel.svg';

import { WeightingReportTable } from '../../interfaces/integralsReports.interface';

const evaTypes = [
  {
    label: 'reports.integrals.weighting.activities.table.heteroevaluation',
    acronym: 'he',
  },
  {
    label: 'reports.integrals.weighting.activities.table.autoevaluation',
    acronym: 'ae',
  },
  {
    label: 'reports.integrals.weighting.activities.table.coevaluation_pairs',
    acronym: 'cp',
  },
  {
    label: 'reports.integrals.weighting.activities.table.coevaluation_directives',
    acronym: 'cd',
  },
];

interface Props {
  data: WeightingReportTable[];
}

const ActivitiesReport = ({ data }: Props) => {
  const { t } = useTranslation();

  const theme = useTheme();

  const tableRef = useRef<any>(null);

  return (
    <Grid container spacing={3}>
      {document.getElementById('report-export-excel') &&
        createPortal(
          <DownloadTableExcel
            filename="Reportes"
            sheet="reportes"
            currentTableRef={tableRef.current}
          >
            <Tooltip title="Descargar Excel">
              <IconButton>
                <img
                  style={{
                    width: '24px',
                    height: '24px',
                  }}
                  src={IconXLS}
                  alt=""
                />
              </IconButton>
            </Tooltip>
          </DownloadTableExcel>,
          document.getElementById('report-export-excel')!
        )}

      <Grid item xs={12} textAlign="center">
        <Typography variant="h3" color="primary">
          {t('reports.integrals.weighting.activities.title')}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <TableContainer>
          <Table id="table-report" ref={tableRef}>
            <TableHead>
              <TableRow>
                <TableCell
                  colSpan={evaTypes.length * 3 + 2}
                  align="center"
                  sx={{
                    borderTopLeftRadius: 22,
                    borderTopRightRadius: 22,
                    backgroundColor: `${theme.palette.secondary.main}99`,
                  }}
                >
                  <Typography variant="h4">
                    {t('reports.integrals.weighting.activities.table.title')}
                  </Typography>
                </TableCell>
              </TableRow>

              <TableRow
                sx={{
                  '& th:nth-of-type(2n)': {
                    background: theme.palette.grey[100],
                  },
                }}
              >
                <TableCell rowSpan={2} align="center">
                  {t('reports.integrals.weighting.activities.table.activities')}
                </TableCell>
                {evaTypes.map((evaType) => (
                  <Fragment key={evaType.label}>
                    <TableCell align="center" colSpan={3}>
                      <Typography variant="h4" component="p" color="InfoText">
                        {t(evaType.label)}
                      </Typography>
                    </TableCell>
                  </Fragment>
                ))}
                <TableCell
                  rowSpan={2}
                  align="center"
                  sx={{ background: `${theme.palette.primary.light}!important` }}
                >
                  <Typography variant="h6" align="center" fontWeight={600}>
                    {t('reports.integrals.weighting.activities.table.total')}
                  </Typography>
                </TableCell>
              </TableRow>

              <TableRow
                sx={{
                  '& th:nth-of-type(6n+1),th:nth-of-type(6n+2),th:nth-of-type(6n+3)': {
                    background: theme.palette.grey[100],
                  },
                }}
              >
                {evaTypes.map((evaType) => (
                  <Fragment key={evaType.label}>
                    <TableCell align="center">
                      <Typography variant="h6">
                        <strong>
                          {t('reports.integrals.weighting.activities.table.percentage')}
                        </strong>
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="h6">
                        <strong>{t('reports.integrals.weighting.activities.table.weight')}</strong>
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="h6">
                        <strong>
                          {t('reports.integrals.weighting.activities.table.weighting')}
                        </strong>
                      </Typography>
                    </TableCell>
                  </Fragment>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {data.map((category) => (
                <TableRow>
                  <TableCell>{category.description}</TableCell>
                  {evaTypes.map((evaType, i) => {
                    const catAcro = category[
                      evaType.acronym as unknown as keyof typeof category
                    ] as any;

                    const isOdd = i % 2 === 0;

                    return (
                      <Fragment key={`cells-${evaType.label}`}>
                        {/* porcentaje  */}
                        {(category[evaType.acronym as unknown as keyof typeof category] as any)
                          ?.weight ? (
                          <>
                            <TableCell
                              align="center"
                              {...(isOdd && { sx: { background: theme.palette.grey[100] } })}
                            >
                              <strong>{catAcro.weight}</strong>
                            </TableCell>
                            {/* peso */}
                            <TableCell
                              align="center"
                              {...(isOdd && { sx: { background: theme.palette.grey[100] } })}
                            >
                              <strong>{parseFloat(catAcro?.weighing).toFixed(2)}</strong>
                            </TableCell>
                            {/* calificacion */}
                            <TableCell
                              align="center"
                              {...(isOdd && { sx: { background: theme.palette.grey[100] } })}
                            >
                              <strong>{parseFloat(catAcro?.calification).toFixed(2)}</strong>
                            </TableCell>
                          </>
                        ) : (
                          <TableCell
                            colSpan={3}
                            align="center"
                            {...(isOdd && { sx: { background: theme.palette.grey[100] } })}
                          >
                            N/A
                          </TableCell>
                        )}
                      </Fragment>
                    );
                  })}
                  <TableCell
                    align="center"
                    sx={{
                      background: `${theme.palette.primary.light}`,
                    }}
                  >
                    {parseFloat(
                      category?.integral_evaluation_cab?.integral_evaluation_cab_category?.total
                    ).toFixed(2)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Grid item xs={12}>
        {/* <Button onClick={pdfHandler}>pdf</Button> */}
      </Grid>
    </Grid>
  );
};

export default ActivitiesReport;
