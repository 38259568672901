import {
    Button,
    CardActions,
    CardContent,
    Dialog,
    Divider,
    Grid,
    IconButton,
    makeStyles,
    Typography,
} from "@material-ui/core"
import { useTheme } from "@material-ui/core";
import { IconArrowNarrowLeft } from "@tabler/icons";
import MainCard from "../../../../../shared/components/cards/MainCard";
import { useAppDispatch } from "../../../../../../hooks/useRedux";
import { setEvaluationComments } from "../../../slices/virtualClassroomSlice";

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
        '&>*': {
            position: 'relative',
            // zIndex: '5'
        },
        '&:after': {
            position: 'absolute',
            top: '0',
            left: '110px',
            height: '100%',
            // background: '#ebebeb',
        },
    },
    paper: {
        minHeight: '100vh',
        width: '35%',
    },
}));

const EvaluationComments = function () {

    const classes = useStyles();

    const theme = useTheme();

    const dispatch = useAppDispatch();

    const handleClose = () => {

        dispatch(setEvaluationComments(false));

    }

    const handleHistory = () => {
        // history.push(`${path}/historial-anuncios/${studentSelected}`)
    }

    return (
        <Dialog
            open={true}
            keepMounted
            onClose={() => handleClose()}
            className={classes.container}
            classes={{
                paper: classes.paper,
            }}
        >
            <Grid item xs={12} style={{ marginLeft: 7 }}>
                <Grid item container alignItems="center" gap={1} sx={{ cursor: 'pointer' }}>
                    <IconButton onClick={() => handleClose()}>
                        <IconArrowNarrowLeft size={30} color={theme.palette.text.dark} />
                    </IconButton>
                    <Typography variant="h3">Regresar</Typography>
                </Grid>
            </Grid>
            <MainCard title="Comentario de evaluación" boxShadow={true}>
                <CardContent>
                </CardContent>
            </MainCard>
            <Divider />
            <CardActions sx={{ justifyContent: 'flex-end' }}>
                <Button style={{ textTransform: 'unset' }} onClick={handleHistory} variant="text" size="small" color="primary">
                    erjrjei
                </Button>
            </CardActions>
        </Dialog>
    )
}


export default EvaluationComments