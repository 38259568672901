import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  InputLabel,
  makeStyles,
  TextField,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { ChangeEvent, useEffect, useState } from 'react';
import { useAppSelector } from '../../../../hooks/useRedux';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import SubCard from '../../../shared/components/cards/SubCard';
import IconTelework from '../../../shared/components/illustrations/IconTelework';
import { SUPPORT_TYPES } from '../../../shared/constants/extensionRequestType';
import { titulationValues } from '../constants/formConstants';
import { TitulationSchema } from '../constants/validationSchemas';
import {
  useAddTitulationRequestMutation,
  useGetPeriodsTitulationQuery,
} from '../slices/academicRequestsApiSlice';

import { useTranslation } from 'react-i18next';
import DragArea from '../../../shared/components/files/DragArea';

const useStyles = makeStyles((theme) => ({
  uploadFile: {
    borderColor: theme.palette.secondary.light,
    borderWidth: '2px',
    borderStyle: 'solid',
    borderRadius: 20,
    textAlign: 'center',
    width: '100%',
    height: '10%',
    position: 'relative',
  },
  closeButton: {
    position: 'absolute',
    marginTop: 2,
    marginRight: 2,
    right: 0,
  },
  gridMTB: {
    marginTop: 1,
    marginBottom: 1,
  },
  bodyFileButton: {
    position: 'absolute',
  },
}));

const TitulationForm = () => {
  const { t } = useTranslation();

  const classes = useStyles();
  const { currentProfile, currentRecordStudent } = useAppSelector((state) => state.access);
  const { userInfo } = useAppSelector((state) => state.auth);
  const [showAlerts, setShowAlerts] = useState(true);

  const {
    data: periods = [],
    isLoading: isLoadingP,
    isError: isErrorP,
    error: errorP,
  } = useGetPeriodsTitulationQuery(
    { profileId: currentProfile?.profileId! },
    {
      refetchOnMountOrArgChange: 30,
    }
  );
  const [addTitulation, { isError, error, isSuccess, isLoading }] =
    useAddTitulationRequestMutation();
  const { handleSubmit, setFieldValue, values, /*getFieldProps,*/ errors, touched } = useFormik({
    initialValues: titulationValues,
    validationSchema: TitulationSchema,
    validate: () => {
      const fileNotAllowed = `${t('students.applications.title.validation1')}`;
      const errors: any = {};
      if (values.si) {
        let extension = values.si?.type;
        if (SUPPORT_TYPES.indexOf(extension) < 0) {
          errors.si = fileNotAllowed;
        }
      } else {
        const Nofile = `${t('students.applications.title.validation2')}`;
        errors.si = Nofile;
      }

      if (values.cc) {
        let extension = values.cc?.type;
        if (SUPPORT_TYPES.indexOf(extension) < 0) {
          errors.cc = fileNotAllowed;
        }
      } else {
        const Nofile = `${t('students.applications.title.validation3')}`;
        errors.cc = Nofile;
      }

      if (values.cp) {
        let extension = values.cp?.type;
        if (SUPPORT_TYPES.indexOf(extension) < 0) {
          errors.cc = fileNotAllowed;
        }
      } else {
        const Nofile = `${t('students.applications.title.validation4')}`;
        errors.cp = Nofile;
      }

      if (values.ca) {
        let extension = values.ca?.type;
        if (SUPPORT_TYPES.indexOf(extension) < 0) {
          errors.cc = fileNotAllowed;
        }
      } else {
        const Nofile = `${t('students.applications.title.validation5')}`;
        errors.ca = Nofile;
      }
      return errors;
    },
    onSubmit: async (value) => {
      await addTitulation({
        profileId: currentProfile?.profileId!,
        userId: String(userInfo?.id!),
        studentRecordId: currentRecordStudent?.id!,
        titulationPayload: {
          app_description: '',
          period_id: value.period_id,
          si: value.si,
          cc: value.cc,
          cp: value.cp,
          ca: value.ca,
        },
      });
      setShowAlerts(true);
    },
  });
  const onChangeFile = function (event: ChangeEvent<HTMLInputElement>, filename: string) {
    if (event.target.files && event.target.files[0]) {
      setFieldValue(filename, event.target.files[0]);
    }
  };
  const onRemoveFile = function (filename: string) {
    setFieldValue(filename, null!);
  };

  useEffect(() => {
    if (isErrorP && errorP) {
      setShowAlerts(true);
    }
  }, [isErrorP, errorP]);

  return userInfo?.studentInfo?.typeStudent !== 'NIV' ? (
    <form onSubmit={handleSubmit}>
      <Grid container direction="column" gap={2}>
        <Grid item>
          {isError && showAlerts && (
            <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
          )}
          {isErrorP && showAlerts && (
            <ErrorAlert message={errorP as string} handleDismiss={() => setShowAlerts(false)} />
          )}
          {isSuccess && showAlerts && (
            <SuccessAlert
              message={t('students.applications.success_message')}
              handleDismiss={() => setShowAlerts(false)}
            />
          )}
        </Grid>
        <Grid item xs={12} className={classes.gridMTB}>
          <InputLabel>{t('students.applications.title.period')}</InputLabel>
          <Autocomplete
            options={Array.isArray(periods) ? periods : []}
            loading={isLoadingP}
            loadingText="Cargando parcial..."
            getOptionLabel={(option) => option.per_name}
            renderInput={(params) => (
              <TextField
                {...params}
                error={Boolean(errors.period_id && touched.period_id)}
                placeholder={t('students.applications.title.period_placeholder')}
              />
            )}
            onChange={(_, value) => setFieldValue('period_id', value?.id)}
            fullWidth
            clearIcon={null}
          />
          <Grid item xs={12}>
            {errors.period_id && touched.period_id && (
              <FormHelperText error>{errors.period_id}</FormHelperText>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.gridMTB}>
          <InputLabel>{t('students.applications.title.admission_application')}</InputLabel>
          <Grid item xs={12} md={12} container>
            <DragArea
              handleChange={(event: ChangeEvent<HTMLInputElement>) => {
                onChangeFile(event, 'si');
              }}
              label={t('students.applications.upload_file')}
              typesAccepted={'application/pdf'}
              onRemoveFile={() => {
                onRemoveFile('si');
              }}
              // file={file}
            />
          </Grid>
          <Grid item xs={12}>
            {errors.si && touched.si && <FormHelperText error>{errors.si}</FormHelperText>}
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.gridMTB}>
          <InputLabel>{t('students.applications.title.letter_engagement')}</InputLabel>
          <Grid item xs={12} md={12} container>
            <DragArea
              handleChange={(event: ChangeEvent<HTMLInputElement>) => {
                onChangeFile(event, 'cc');
              }}
              label={t('students.applications.upload_file')}
              typesAccepted={'application/pdf'}
              onRemoveFile={() => {
                onRemoveFile('cc');
              }}
              // file={file}
            />
          </Grid>
          <Grid item xs={12}>
            {errors.cc && touched.cc && <FormHelperText error>{errors.cc}</FormHelperText>}
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.gridMTB}>
          <InputLabel>{t('students.applications.title.payment_proof')}</InputLabel>
          <Grid item xs={12} md={12} container>
            <DragArea
              handleChange={(event: ChangeEvent<HTMLInputElement>) => {
                onChangeFile(event, 'cp');
              }}
              label={t('students.applications.upload_file')}
              typesAccepted={'application/pdf'}
              onRemoveFile={() => {
                onRemoveFile('cp');
              }}
              // file={file}
            />
          </Grid>

          <Grid item xs={12}>
            {errors.cp && touched.cp && <FormHelperText error>{errors.cp}</FormHelperText>}
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.gridMTB}>
          <InputLabel>{t('students.applications.title.academic_counseling')}</InputLabel>
          <Grid item xs={12} md={12} container>
            <DragArea
              handleChange={(event: ChangeEvent<HTMLInputElement>) => {
                onChangeFile(event, 'ca');
              }}
              label={t('students.applications.upload_file')}
              typesAccepted={'application/pdf'}
              onRemoveFile={() => {
                onRemoveFile('ca');
              }}
              // file={file}
            />
          </Grid>
          <Grid item xs={12}>
            {errors.ca && touched.ca && <FormHelperText error>{errors.ca}</FormHelperText>}
          </Grid>
        </Grid>
        <Grid
          item
          container
          alignItems="center"
          justifyContent="flex-end"
          spacing={2}
          sx={{ mt: 1 }}
        >
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              disabled={isLoading}
              endIcon={isLoading && <CircularProgress size={20} />}
            >
              {t('students.applications.save')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  ) : (
    <Grid container item mt={3} justifyContent="center">
      <SubCard title="Aviso importante" darkTitle={true}>
        <Grid item>
          <ErrorAlert message={t('students.applications.error_message')} />
        </Grid>
        <Box>
          <Grid container justifyContent="center" alignContent="center">
            <Grid container justifyContent="center" item xs={8} md={6} spacing={2}>
              <Grid container item xs={12} justifyContent="center">
                <IconTelework size={300} />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </SubCard>
    </Grid>
  );
};
export default TitulationForm;
