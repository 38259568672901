import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/useRedux';
import { useTableSearch } from '../../../../../../hooks/useTableSearch';
import AddButton from '../../../../../shared/components/tables/buttons/AddButton';
import DeleteButton from '../../../../../shared/components/tables/buttons/DeleteButton';
import EditButton from '../../../../../shared/components/tables/buttons/EditButton';
import SearchPaginatedTable from '../../../../../shared/components/tables/SearchPaginatedTableAndCollapsibleItems';
import EvaluationsEvaTypes from '../../evaluations/components/EvaluationsEvaTypes';
import { useGetIntegralEvaluationsQuery } from '../slices/integralEvaluationApiSlice';
import {
  setDeleteIdSelected,
  setIntegralEvaluationsList,
  setIntegralEvaluationsTable,
  setIsOpenDeleteDialog,
} from '../slices/integralEvaluationSlice';

//Esto ahora es rubrica

const IntegralEvaluationsTable = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useHistory();
  const {
    integralEvaluationsList,
    integralEvaluationsTable: { page, perPage, search, filterArrow },
  } = useAppSelector((state) => state.integralEvaluation);

  const { setPage, setPerPage, setSearch } = useTableSearch({
    page,
    perPage,
    search,
    filterArrow,
    updateFunction: setIntegralEvaluationsTable,
  });

  const { data, isLoading, isFetching, error } = useGetIntegralEvaluationsQuery({
    page,
    perPage,
    search,
    ...(filterArrow && { sort: filterArrow.name, typeSort: filterArrow.direction }),
  });

  const handleEdit = (id: string | number) => {
    navigate.push(`/rubricas/${id}/editar`);
  };

  const handleDelete = (id: string | number) => {
    dispatch(setDeleteIdSelected(id));
    dispatch(setIsOpenDeleteDialog(true));
  };

  useEffect(() => {
    if (data?.data) {
      dispatch(setIntegralEvaluationsList(data?.data));
    }
  }, [data, dispatch]);

  const datafetch = integralEvaluationsList.map((item, idx) => ({
    id: { numberRow: (page - 1) * perPage + (idx + 1), ...item },
    name: item.name,
    description: item.description,
    options: item,
  }));

  const goToAdd = () => {
    navigate.push('/rubricas/agregar');
  };

  return (
    <SearchPaginatedTable
      data={datafetch!}
      headers={{
        id: 'N°',
        name: t('Nombre'),
        description: t('maintenance.integral_evaluation.description'),
        options: t('Opciones'),
      }}
      keyExtractor={(item) => item?.id?.id!.toString()!}
      searchPlacehoder={t('maintenance.integral_evaluation.search')}
      isLoading={isLoading}
      isFetching={isFetching}
      error={error as any}
      perPage={perPage}
      setPerPage={setPerPage}
      total={data?.total || 0}
      page={page}
      setPage={setPage}
      numHeader={5}
      search={search}
      setSearch={setSearch}
      customDataCellsProperties={{
        id: {
          align: 'center',
        },
        description: {
          align: 'center',
        },
        name: {
          align: 'center',
        },
        options: {
          align: 'center',
        },
      }}
      ActionButtons={<AddButton onClick={goToAdd} />}
      isCollapsible
      collapsibleItems={datafetch.map((item) => (
        <EvaluationsEvaTypes key={item.id!.id!.toString()} data={item.id} />
      ))}
      customRenderers={{
        id: (item) => <div>{item.id?.numberRow!}</div>,
        options: (row) => (
          <>
            <EditButton handleEdit={() => handleEdit(row.options.id!)} />
            <DeleteButton handleDelete={() => handleDelete(row.options.id!)} />
          </>
        ),
      }}
    />
  );
};

export default IntegralEvaluationsTable;
