import { useState } from 'react';
import { Link, useRouteMatch, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../../hooks/useRedux';
import { useGetProjectsTeacherTaskQuery } from '../slices/teacherProjectsApiSlice';
import StatusChip from '../../../shared/components/badges/StatusChip';
import SearchPaginatedTable, {
  FilterArrow,
} from '../../../shared/components/tables/SearchPaginatedTable';
import { statusAvailables } from '../../../shared/constants/resourceStatus';
import CustomIconButton from '../../../shared/components/tables/buttons/CustomIconButton';
import { IconCheckupList } from '@tabler/icons';

const TaskProjectTable = () => {
  const { t } = useTranslation();

  // const dispatch = useAppDispatch();

  // const history = useHistory();

  const { projectId } = useParams<{ projectId: string }>();

  const { path } = useRouteMatch();

  const studentId = useAppSelector((state) => state.access.currentStudent?.id);

  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(5);
  const [search, setSearch] = useState<string>('');
  const [filterArrow, setFilterArrow] = useState<FilterArrow>();

  const { isLoading, error, data, isFetching } = useGetProjectsTeacherTaskQuery({
    page,
    perPage,
    search,
    ...(filterArrow && {
      type_sort: filterArrow?.direction,
      sort: filterArrow?.name,
    }),
    studentId: studentId!,
    projectId,
  });

  //cargar en un estado la vista actual de la tabla
  // useEffect(() => {
  //   if (data) {
  //     dispatch(setProjectsList(data.data));
  //   }
  // }, [data, dispatch]);

  const datafetch = data?.data!.map((dt, i, arr) => ({
    id: i + ((page - 1) * perPage + 1),
    name: dt.name,
    description: dt.activity_description,
    startDate: dt.start_date,
    endDate: dt.end_date_real,
    statusId: dt.status,
    options: dt,
  }));

  // const goToAdd = () => {
  //   history.push(`${path}/agregar`);
  // };

  return (
    <>
      <SearchPaginatedTable
        data={datafetch!}
        headers={{
          id: 'N°',
          name: t('academic.areas.name'),
          description: t('academic.areas.description'),
          startDate: t('Fecha de inicio'),
          endDate: t('Fecha fin'),
          statusId: t('academic.areas.status'),
          options: t('academic.areas.options'),
        }}
        keyExtractor={(item) => item.options.id.toString()}
        searchPlacehoder={t('Buscar proyectos')}
        isLoading={isLoading}
        isFetching={isFetching}
        error={error}
        perPage={perPage!}
        setPerPage={setPerPage}
        total={data?.total || 0}
        page={page!}
        setPage={setPage}
        numHeader={4}
        search={search!}
        setSearch={setSearch}
        customRenderers={{
          statusId: (row) => (
            <StatusChip
              statusId={statusAvailables.find(({ acronym }) => acronym === row.statusId)?.id || 0}
            />
          ),

          options: (row) => (
            <>
              <Link to={`${path}/${row.options.id}/tareas`}>
                <CustomIconButton title="Ver tareas" Icon={IconCheckupList} />
              </Link>
            </>
          ),
        }}
        customDataCellsProperties={{
          id: { align: 'center' },
          name: {
            align: 'center',
          },
          description: {
            align: 'center',
          },
          statusId: {
            align: 'center',
          },

          options: {
            align: 'center',
          },
        }}
        customHeadersCellsProperties={{
          options: {
            align: 'center',
          },
        }}
        listFilterArrows={{
          name: 'name',
          description: 'activity_description',
          startDate: 'start_date',
          endDate: 'end_date_real',
          statusId: 'status',
        }}
        filterArrow={filterArrow}
        setFilterArrow={setFilterArrow}
      />
    </>
  );
};

export default TaskProjectTable;
