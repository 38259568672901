import { Grid, Typography } from '@material-ui/core';

import { useTranslation } from 'react-i18next';

import MainCard from '../../../shared/components/cards/MainCard';
import { useAppSelector } from '../../../../hooks/useRedux';
import ListProducts from '../components/ListProducts';
import DeleteModal from '../components/DeleteModal';

const MaintenanceProduct = () => {
  const { t } = useTranslation();
  const { productIdToDelete } = useAppSelector((state) => state.products);
  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">{t('financial.maintenance_prod.title')}</Typography>
      </Grid>

      <ListProducts />

      {productIdToDelete && <DeleteModal />}
    </Grid>
  );
};

export default MaintenanceProduct;
