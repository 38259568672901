import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DateRange } from '@material-ui/lab';
import { format } from 'date-fns';

import { HolidaysResponse, Holidays } from '../interfaces/holidays.interfaces';
import { DayType } from '../interfaces/posgraduateDaysTypes.interfaces';
import { MyEvents, InitialProgramsAndEvents } from '../interfaces/events.interfaces';
import { Mesh, EducationLevel } from '../../education-levels/interfaces/educationLevels.interfaces';
import { ISearchTable } from '../../../shared/interfaces/paginatedTable.interface';
import { PostgraduatePlanification } from '../interfaces/postgraduatePlanification.interface';

interface InitialInfoPlanning {
  date: Date;
  facultad: EducationLevel;
  career: EducationLevel[];
  curriculum: Mesh;
  campusId: number;
  hourhandId: number;
}
export interface AcademicPlanningPosgraduateState {
  holidaysResponse: HolidaysResponse;
  holidaysList: Holidays[];
  holidaysMove: boolean;
  colorEvents: DayType[];
  dragEvent: Partial<MyEvents>;
  initialEventsPlanning: MyEvents[];
  initialInfoPlanning: InitialInfoPlanning;
  initialPrograms: MyEvents[];
  templateAcademicEvents: MyEvents[];
  initialProgramsAndEvents: InitialProgramsAndEvents[];
  eduLevelsAvailables: EducationLevel[];
  postGraduatePlanificationList: PostgraduatePlanification[];
  postgraduatePlanificationTable: ISearchTable;
  postGraduatePlanificationIdSelected: string | null;
  postgraduatePlanificationApproveDialogOpen: boolean;
  postgraduateDeleteProgramDialogOpen: boolean;
  postgraduateDeleteProgramDialogIdSelected: string | null;
  currentDateSelected: string | null;
  showEventsDialog: {
    open: boolean;
    events: MyEvents[];
    date: Date | null;
  };

  calendarIdSelected: string | null;
  calendarNameSelected: string | null;

  hourhandIdSelected: number | null;

  datesArraySelected: DateRange<string | null> | null;
  campusIdSelected: number | null;
  statusIdSelected: string | null;
  modalityIdSelected: number | null;
}

const initialState: AcademicPlanningPosgraduateState = {
  holidaysResponse: {} as HolidaysResponse,
  holidaysList: [],
  holidaysMove: false,
  colorEvents: [],
  dragEvent: null!,
  initialEventsPlanning: [],
  initialInfoPlanning: {} as InitialInfoPlanning,

  initialPrograms: [],
  currentDateSelected: format(new Date(), 'dd-MMM-yyyy'),
  templateAcademicEvents: [],

  initialProgramsAndEvents: [],
  eduLevelsAvailables: [],
  postGraduatePlanificationList: [],
  postgraduatePlanificationTable: {
    page: 1,
    perPage: 5,
    search: '',
  },
  postGraduatePlanificationIdSelected: null,
  postgraduatePlanificationApproveDialogOpen: false,
  postgraduateDeleteProgramDialogOpen: false,
  postgraduateDeleteProgramDialogIdSelected: null,
  showEventsDialog: {
    open: false,
    events: [],
    date: null,
  },

  calendarIdSelected: null,
  calendarNameSelected: null,

  hourhandIdSelected: null,
  // list filters
  datesArraySelected: [null, null],
  campusIdSelected: null,
  statusIdSelected: null,
  modalityIdSelected: null,
};

const academicPlanningPosgraduateSlice = createSlice({
  name: 'academicPlanningPosgraduate',
  initialState,
  reducers: {
    setHolidaysResponse: (state, action: PayloadAction<HolidaysResponse>) => {
      state.holidaysResponse = action.payload;
    },
    setHolidaysList: (state, action: PayloadAction<Holidays[]>) => {
      state.holidaysList = action.payload;
    },
    setColorEvents: (state, action: PayloadAction<DayType[]>) => {
      state.colorEvents = action.payload;
    },
    setEventDrag: (state, action: PayloadAction<Partial<MyEvents>>) => {
      state.dragEvent = action.payload;
    },
    setInitialEventsPlanning: (state, action: PayloadAction<MyEvents[]>) => {
      state.initialEventsPlanning = action.payload;
    },
    setInitialInfoPlanning: (state, action: PayloadAction<InitialInfoPlanning>) => {
      state.initialInfoPlanning = action.payload;
    },
    setInitialPrograms: (state, action: PayloadAction<MyEvents[]>) => {
      state.initialPrograms = action.payload;
    },
    setTemplateAcademicEvents: (state, action: PayloadAction<MyEvents[]>) => {
      state.templateAcademicEvents = action.payload;
    },
    setInitialProgramsAndEvents: (state, action: PayloadAction<InitialProgramsAndEvents[]>) => {
      state.initialProgramsAndEvents = action.payload;
    },
    setEduLevelsAvailables: (state, action: PayloadAction<EducationLevel[]>) => {
      state.eduLevelsAvailables = action.payload;
    },
    setPostGraduatePlanificationList: (state, action: PayloadAction<any[]>) => {
      state.postGraduatePlanificationList = action.payload;
    },
    setPostgraduatePlanificationTable: (state, action: PayloadAction<ISearchTable>) => {
      state.postgraduatePlanificationTable = action.payload;
    },
    setPostGraduatePlanificationIdSelected: (state, action: PayloadAction<string | null>) => {
      state.postGraduatePlanificationIdSelected = action.payload;
    },
    setPostgraduatePlanificationApproveDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.postgraduatePlanificationApproveDialogOpen = action.payload;
    },
    setPostgraduateDeleteProgramDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.postgraduateDeleteProgramDialogOpen = action.payload;
    },
    setPostgraduateDeleteProgramDialogIdSelected: (state, action: PayloadAction<string | null>) => {
      state.postgraduateDeleteProgramDialogIdSelected = action.payload;
    },
    setHolidaysMove: (state, action: PayloadAction<boolean>) => {
      state.holidaysMove = action.payload;
    },
    setCurrentDateSelected: (state, action: PayloadAction<Date>) => {
      state.currentDateSelected = action.payload ? format(action.payload, 'dd-MMM-yyyy') : null;
    },
    setShowEventsDialog: (
      state,
      action: PayloadAction<{ open: boolean; events: MyEvents[]; date: Date | null }>
    ) => {
      state.showEventsDialog = action.payload;
    },
    setCalendarIdSelected: (state, action: PayloadAction<string | null>) => {
      state.calendarIdSelected = action.payload;
    },
    setHourhandIdSelected: (state, action: PayloadAction<number | null>) => {
      state.hourhandIdSelected = action.payload;
    },
    setDatesArraySelected: (state, action: PayloadAction<DateRange<Date | null>>) => {
      const startDate = action.payload[0] ? format(action.payload[0], 'dd-MMM-yyyy') : null;
      const endDate = action.payload[1] ? format(action.payload[1], 'dd-MMM-yyyy') : null;

      state.datesArraySelected = [startDate, endDate];
    },
    setCampusIdSelected: (state, action: PayloadAction<number | null>) => {
      state.campusIdSelected = action.payload;
    },
    setStatusIdSelected: (state, action: PayloadAction<string | null>) => {
      state.statusIdSelected = action.payload;
    },
    setModalityIdSelected: (state, action: PayloadAction<number | null>) => {
      state.modalityIdSelected = action.payload;
    },

    setCalendarNameSelected: (state, action: PayloadAction<string | null>) => {
      state.calendarNameSelected = action.payload;
    },
  },
});

export const {
  setColorEvents,
  setHolidaysList,
  setHolidaysResponse,
  setEventDrag,
  setInitialEventsPlanning,
  setInitialInfoPlanning,
  setInitialPrograms,
  setTemplateAcademicEvents,
  setInitialProgramsAndEvents,
  setEduLevelsAvailables,
  setPostGraduatePlanificationList,
  setPostgraduatePlanificationTable,
  setPostGraduatePlanificationIdSelected,
  setPostgraduatePlanificationApproveDialogOpen,
  setPostgraduateDeleteProgramDialogOpen,
  setPostgraduateDeleteProgramDialogIdSelected,
  setHolidaysMove,
  setCurrentDateSelected,
  setShowEventsDialog,
  setCalendarIdSelected,
  setHourhandIdSelected,
  setDatesArraySelected,
  setCampusIdSelected,
  setStatusIdSelected,
  setModalityIdSelected,
  setCalendarNameSelected,
} = academicPlanningPosgraduateSlice.actions;

export default academicPlanningPosgraduateSlice.reducer;
