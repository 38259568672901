import { useAppDispatch } from '../../../../hooks/useRedux';
import CustomSideDialog from '../../../shared/components/dialogs/CustomSideDialog';
import { AuthorsScientificPublication } from '../interfaces/publicationsPayload.interfaces';
import { setIsOpenAddPersonPublicationDialog, setKeywordAddPersonPublication } from '../slices/publicationsSlice';
import FormStudentAndCollaboratorPublication from './forms/FormStudentAndCollaboratorPublication';

interface AuthorName extends AuthorsScientificPublication {
  name: string;
}

interface Props {
  listAddAuthor: AuthorName[];
  setListAddAuthor: React.Dispatch<React.SetStateAction<AuthorName[]>>;
}

const AddStudentAndCollaboratorDialog = ({ listAddAuthor, setListAddAuthor }: Props) => {
  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(setIsOpenAddPersonPublicationDialog(false));
    dispatch(setKeywordAddPersonPublication(''));
  };

  return (
    <CustomSideDialog open handleCloseDialog={handleClose} title="Asignación de autores">
      <FormStudentAndCollaboratorPublication
        listAddAuthor={listAddAuthor}
        setListAddAuthor={setListAddAuthor}
      />
    </CustomSideDialog>
  );
};

export default AddStudentAndCollaboratorDialog;
