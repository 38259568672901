import { Box, Button, CircularProgress, Grid, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { IconClock, IconNotebook, IconWriting } from '@tabler/icons';
import { SubjectsByPeriod } from '../interfaces/subjectsByPeriods';
import { useAppDispatch } from '../../../../hooks/useRedux';
import { setCurrentCourse } from '../../virtual-classroom/shared/virtualClassroomSharedSlice';
import { useHistory } from 'react-router-dom';
import { getSubjectStatusColor } from '../helpers/colors';
import { useGetStatusSyllabusQuery } from '../slices/AcademicPortfolioApiSlice';
import download from 'downloadjs';
import { useState } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    // border: '1px solid red',
    padding: '20px 20px 20px 30px',
    boxShadow: '0px 0px 20px #BFDAF9',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: '20px',
    width: '100%',

    position: 'relative',

    '&::after': {
      content: `""`,
      position: 'absolute',
      top: 0,
      left: 0,
      borderTopLeftRadius: '10px',
      borderBottomLeftRadius: '10px',
      width: '10px',
      height: '100%',
    },
  },
  cardRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  cardCollapse: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  cardTitle: {
    color: theme.palette.primary.main,
    fontSize: '14px',
    fontWeight: 600,
    overflow: 'hidden',
    wordWrap: 'break-word',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    height: '3em',
  },
  cardSubTitle: {
    color: theme.palette.grey[500],
    fontSize: '14px',
    fontWeight: 400,
  },

  subjectType: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.main,
    fontSize: '12px',
    fontWeight: 400,
  },
  subjectTypeText: {
    color: theme.palette.grey[600],
    fontSize: '12px',
    fontWeight: 400,
  },
  preRequisitesText: {
    color: '#31355C',
    fontSize: '12px',
    fontWeight: 600,
  },
  cardBadges: {
    display: 'flex',
    gap: '4px',
    alignItems: 'center',
    flexWrap: 'wrap',
    // justifyContent: 'flex-start',
  },
  subjectPreRequisites: {
    display: 'flex',
    gap: '4px',
    flexWrap: 'wrap',
    marginTop: '0.5rem',
  },
  hourhandContainer: {
    backgroundColor: theme.palette.grey[200],
    padding: '10px',
    borderRadius: '8px',
  },
  gradeContainer: {
    backgroundColor: theme.palette.grey[200],
    padding: '10px',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
}));

interface Props {
  subject: SubjectsByPeriod;
  homologated?: boolean;
}

const SubjectCardNew = ({ subject, homologated }: Props) => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [isLoadingPdf, setIsLoadingPdf] = useState(false);

  const classes = useStyles();

  // const { serviceCredentials } = useAppSelector((state) => state.access);

  const goToVirtualClassroom = ({ course }: SubjectsByPeriod, courseId: number) => {
    // if (course.type_classroom_virtual !== 'native' && serviceCredentials?.sdk_secret)
    //   return window.open(serviceCredentials?.sdk_secret, '_blank');

    // if (course.type_classroom_virtual !== 'native' && tenant in tenantsVc)
    //   return window.open(tenantsVc[tenant].url);

    const data = {
      courseParalel: course?.parallel?.par_name!,
      courseHourhandDescription: course?.hourhand?.hour_description!,
      courseId: courseId,
      courseName: course?.matter?.mat_name!,
      collaborator: course?.collaborator?.user?.person?.pers_full_name!,
      vcType: course?.type_classroom_virtual || 'native',
      campusName: course?.classroom?.campus?.cam_name || 'N/A',
      collaboratorIdentification: course?.collaborator?.user?.person?.pers_identification!,
      collaboratorEmail: course?.collaborator?.user?.email || 'N/A',
    };

    dispatch(setCurrentCourse(data));

    localStorage.setItem('virtualClassroomStudent', JSON.stringify(data));

    history.push(`/aula-virtual-estudiante/inicio`);
  };

  const { data: status } = useGetStatusSyllabusQuery({
    course: subject?.course_id,
    matter: subject?.course.matter_id,
  });

  const handleDownloadPf = async () => {
    try {
      setIsLoadingPdf(true);
      const blob = new Blob([status], { type: 'application/pdf' });

      await download(blob, `syllabus.pdf`, 'application/pdf');
      setIsLoadingPdf(false);
    } catch (error) {
      setIsLoadingPdf(false);
      console.log(error);
    }
  };

  return (
    <Grid item xs={12} sm={6} md={4} xl={3}>
      <Box
        className={classes.root}
        sx={{
          '&::after': {
            //   bgcolor: subject.simbology.sim_color,
            bgcolor: getSubjectStatusColor(subject.approval_status?.st_name),
          },
          // minHeight: 250,
          opacity: 1,
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12} className={classes.cardRow}>
            <Tooltip
              title={
                homologated
                  ? subject.subject?.mat_name!
                  : subject?.subject_curriculum?.matter_rename || subject?.course?.matter?.mat_name
              }
            >
              <Typography variant="subtitle1" className={classes.cardTitle}>
                {homologated
                  ? subject?.subject?.mat_name
                  : subject?.subject_curriculum?.matter_rename || subject?.course?.matter?.mat_name}
              </Typography>
            </Tooltip>
          </Grid>
          {!homologated && (
            <>
              <Grid item className={classes.cardRow}>
                <Typography variant="subtitle2" className={classes.cardSubTitle}>
                  {subject?.course?.collaborator?.user?.person?.pers_full_name}
                </Typography>
              </Grid>
              {subject?.subject?.type_matter?.tm_name && (
                <Grid item xs={12} className={classes.cardRow}>
                  <Typography variant="subtitle2" className={classes.cardSubTitle}>
                    Tipo: {subject?.subject?.type_matter?.tm_name || ''}
                  </Typography>
                </Grid>
              )}
              <Grid item className={classes.cardRow}>
                <Typography variant="subtitle2" className={classes.cardSubTitle}>
                  Campus: {subject?.course?.classroom?.campus?.cam_name}
                </Typography>
              </Grid>
              <Grid item className={classes.cardRow}>
                <Typography variant="subtitle2" className={classes.cardSubTitle}>
                  Paralelo: {subject?.course?.parallel?.par_name}
                </Typography>
              </Grid>
              <Grid item className={classes.cardRow}>
                <Box className={classes.hourhandContainer}>
                  <Typography variant="subtitle2" className={classes.subjectType}>
                    <IconClock size={24} /> {subject?.course?.hourhand?.hour_description}
                  </Typography>
                </Box>
              </Grid>
            </>
          )}
          {homologated && (
            <Grid item xs={12} className={classes.cardRow}>
              <Box className={classes.gradeContainer}>
                <Typography variant="subtitle1">
                  Nota final:{' '}
                  <Typography variant="subtitle1" component="span">
                    {subject.final_note}
                  </Typography>
                </Typography>
              </Box>
            </Grid>
          )}
        </Grid>

        {!homologated && (
          <>
            <Grid container spacing={2} xs={12} justifyContent="center">
              <Grid item xs={12} md={12} lg={6}>
                <Button
                  startIcon={<IconNotebook />}
                  variant="contained"
                  size="small"
                  // fullWidth
                  onClick={() => goToVirtualClassroom(subject, Number(subject.course_id))}
                >
                  Aula virtual
                </Button>
              </Grid>
              {status && (
                <Grid item xs={12} md={12} lg={6}>
                  <Button
                    sx={{
                      width: '100%',
                    }}
                    startIcon={isLoadingPdf ? <CircularProgress size={14} /> : <IconWriting />}
                    variant="contained"
                    size="small"
                    // fullWidth
                    // onClick={() => goToVirtualClassroom(subject, Number(subject.course_id))}
                    onClick={handleDownloadPf}
                    disabled={isLoadingPdf}
                  >
                    Decargar PDF del Syllabus
                  </Button>
                </Grid>
              )}
            </Grid>
            <Box className={classes.cardRow}>
              {/* <IconButton onClick={() => setOpenCollapse((prev) => !prev)}>
          //         {openCollapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          //       </IconButton> */}
            </Box>
            <Grid item>
              <Typography variant="subtitle2">{subject?.course?.classroom?.cl_name}</Typography>
            </Grid>
          </>
        )}

        {/* <Collapse in={openCollapse} timeout="auto" unmountOnExit>
          <Grid container item spacing={2} flexDirection={'column'}>
            <Divider />
            <Grid item>
              <Typography variant="subtitle2" className={classes.cardTitle}>
                Aula:
              </Typography>
              <Typography variant="subtitle2">{subject?.course?.classroom?.cl_name}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle2" className={classes.cardTitle}>
                Estado:
              </Typography>
              <SubjectStatusChip keyStatus={subject?.approval_status?.st_name} />
            </Grid>
          </Grid>
        </Collapse> */}
      </Box>
    </Grid>
  );
};

export default SubjectCardNew;
