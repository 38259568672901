import { useEffect, useState, useRef, useMemo } from 'react';
import {
  Autocomplete,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
  // Typography,`
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

import SuccessAlert from '../../../../../shared/components/alerts/SuccessAlert';
import ErrorAlert from '../../../../../shared/components/alerts/ErrorAlert';
// import { LaunchingPayload } from '../interfaces/launchingList.interface';
import { useAddLaunchingMutation, useLazyGetEvaluatedQuery } from '../slices/launchingApiSlice';
import LocalizedDatePicker from '../../../../../shared/components/controlled/LocalizedDatePicker';
import {
  useGetListAllPeriodsQuery,
  useLazyGetListCoursesQuery,
} from '../../groups/slices/listGroupsApiSlice';
import { launchingFormSchema } from '../validation/launchingFormvalidation';
// import EvaluatorSelect from './EvaluatorSelect';
import { useGetTemplateEvaListAllQuery } from '../../templates/slices/evaTemplatesApiSlice';
import { TemplateEvaData } from '../../templates/interfaces/TemplateEva.interface';
import InfoAlert from '../../../../../shared/components/alerts/InfoAlert';
import { Courses } from '../../groups/interfaces/groupsListCourses.interfaces';
import { format } from 'date-fns';

interface ICourseDatafetch extends Partial<Courses> {
  label: string;
  value: number;
}

const LaunchingForm = () => {
  const { t } = useTranslation();
  const [showAlerts, setShowAlerts] = useState(false);

  const [templateSelected, setTemplateSelected] = useState<TemplateEvaData>();

  const { data: templateList, isLoading: isLoadingTemplateList } = useGetTemplateEvaListAllQuery(
    {}
  );

  const [
    getEvualuated,
    {
      data: evaluated = [],
      // isLoading: isLoadingEvaluated
    },
  ] = useLazyGetEvaluatedQuery();

  const [getCourses, { isLoading: isLoadingCourses, data: courses }] = useLazyGetListCoursesQuery();

  const courseDataFetch: ICourseDatafetch[] = useMemo(
    () =>
      courses && Array.isArray(courses)
        ? [
            {
              label: 'Todos',
              value: 0,
            },
            ...courses.map((course) => ({
              ...course,
              label: course.matter.mat_name,
              value: course.id,
            })),
          ]
        : [],
    [courses]
  );

  const { data: periods, isLoading: isLoadingPeriods } = useGetListAllPeriodsQuery({});

  const coursesInputRef = useRef<HTMLInputElement>();

  const [addEvaluationType, { isLoading, isError, error, isSuccess }] = useAddLaunchingMutation();

  const { errors, getFieldProps, handleSubmit, touched, resetForm, setFieldValue, values } =
    useFormik(
      // <LaunchingPayload>
      {
        initialValues: {
          name: '',
          description: '',
          start_date: new Date(),
          end_date: new Date(),
          eva_evaluation_id: '',
          profile_id: '',
          period_id: '',
          evaluated: [],
          evaluators: new Array<number>(),
        },
        validationSchema: launchingFormSchema,
        onSubmit: async (currentValues) => {
          console.log(currentValues.start_date, currentValues.end_date);
          await addEvaluationType({
            ...currentValues,
            start_date: format(currentValues.start_date, 'yyyy-MM-dd'),
            end_date: format(currentValues.end_date, 'yyyy-MM-dd'),
          });
          setShowAlerts(true);
        },
      }
    );

  useEffect(() => {
    if (values.period_id) {
      const executeGetCourses = async () => {
        try {
          await getCourses({
            periodId: values.period_id,
          });
        } catch (error) {
          console.log(error);
        }
      };
      executeGetCourses();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.period_id]);

  useEffect(() => {
    if (templateSelected && templateSelected.eva_types?.group_type?.keyword !== 'EGC') {
      const execute = async () => {
        try {
          await getEvualuated({ id: Number(values.eva_evaluation_id!) });
        } catch (error) {
          console.log(error);
        }
      };
      execute();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateSelected]);

  useEffect(() => {
    if (
      templateSelected &&
      templateSelected.eva_types?.group_type?.keyword !== 'EGC' &&
      evaluated.length > 0
    ) {
      const evaluatedIds = evaluated.map((item) => Number(item?.user_id)!);
      setFieldValue('evaluated', evaluatedIds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [evaluated, templateSelected]);

  useEffect(() => {
    if (isSuccess) {
      resetForm();
    }
  }, [isSuccess, resetForm]);

  useEffect(() => {
    if (templateSelected && templateSelected.eva_types?.group_type?.keyword === 'EGC') {
      const template = templateList?.find((item) => item.id === Number(values.eva_evaluation_id!));
      const period = periods?.find((item) => item.id === Number(values.period_id!));
      const evaluatorsNames =
        values.evaluators?.length === courseDataFetch.length - 1
          ? 'Todos'
          : values.evaluators?.reduce<string>((acc, item) => {
              const data = courseDataFetch.find((course) => course.value === item);
              return `${acc !== '' ? `${acc}, ` : acc}${data?.matter?.cod_matter_migration}`;
            }, '');

      setFieldValue(
        'name',
        `${template?.eva_types?.name ? template?.eva_types?.name : ''}${
          period?.per_name ? ` - ${period.per_name}` : ''
        }${evaluatorsNames ? ` - (${evaluatorsNames})` : ''}`
      );
    } else {
      const template = templateList?.find((item) => item.id === Number(values.eva_evaluation_id!));
      setFieldValue('name', `${template?.eva_types?.name ? template?.eva_types?.name : ''}`);
    }
  }, [
    values.period_id,
    values.eva_evaluation_id,
    periods,
    setFieldValue,
    templateList,
    courseDataFetch,
    values.evaluators,
    templateSelected,
  ]);

  return (
    <Grid
      container
      spacing={2}
      sx={{
        mt: 3,
      }}
    >
      {isSuccess && showAlerts && (
        <Grid item xs={12}>
          <SuccessAlert
            message={t('Evaluación lanzada con éxito')}
            handleDismiss={() => setShowAlerts(false)}
          />
        </Grid>
      )}
      {isError && showAlerts && (
        <Grid item xs={12}>
          <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
        </Grid>
      )}

      <Grid
        container
        sx={{
          display: 'flex',
          justifyContent: 'center',
          mt: 1,
        }}
      >
        <Grid item xs={6}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <InputLabel>{t('Formato de rúbrica')}</InputLabel>
                {isLoadingTemplateList ? (
                  <TextField fullWidth placeholder={t('shared.filters.template')} disabled />
                ) : (
                  <Autocomplete
                    options={Array.isArray(templateList) ? templateList : []}
                    getOptionLabel={(option) => option.name}
                    value={
                      Array.isArray(templateList)
                        ? templateList?.find(
                            (template) => Number(template.id) === Number(values.eva_evaluation_id)
                          )
                        : null
                    }
                    onChange={(event, newValue) => {
                      setFieldValue('eva_evaluation_id', newValue?.id!);
                      setTemplateSelected(newValue!);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={t('Seleccione un formato de rúbrica')}
                        error={Boolean(errors.eva_evaluation_id && touched.eva_evaluation_id)}
                        fullWidth
                      />
                    )}
                  />
                )}
                {errors.eva_evaluation_id && touched.eva_evaluation_id && (
                  <FormHelperText error>{t(errors.eva_evaluation_id)}</FormHelperText>
                )}
              </Grid>

              {templateSelected && templateSelected.eva_types?.group_type?.keyword === 'EGC' && (
                <Grid item xs={12}>
                  <InputLabel>{t('Periodos')}</InputLabel>
                  {isLoadingPeriods ? (
                    <TextField fullWidth placeholder={t('shared.filters.period')} disabled />
                  ) : (
                    <Autocomplete
                      options={periods || []}
                      getOptionLabel={(option) => option.per_name}
                      value={
                        periods?.find((period) => Number(period.id) === Number(values.period_id)) ||
                        null
                      }
                      onChange={(event, newValue) => {
                        setFieldValue('period_id', newValue?.id!);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="medium"
                          fullWidth
                          placeholder={t('shared.filters.period.placeholder')}
                        />
                      )}
                    />
                  )}
                </Grid>
              )}

              {templateSelected && templateSelected.eva_types?.group_type?.keyword === 'EGC' && (
                <Grid item xs={12}>
                  <InputLabel>{t('Cursos')}</InputLabel>
                  {isLoadingCourses && values.profile_id ? (
                    <TextField
                      fullWidth
                      placeholder={
                        values.profile_id
                          ? t('shared.filters.course.selected.period.placeholder')
                          : t('shared.filters.course.selected.period.placeholder')
                      }
                      disabled
                    />
                  ) : (
                    <Autocomplete
                      ref={coursesInputRef}
                      multiple
                      options={courseDataFetch}
                      value={courseDataFetch.filter((item) =>
                        values.evaluators.includes(item.value)
                      )}
                      onChange={(event, newValue) => {
                        const isDataAll = newValue.find((item) => item.value === 0);
                        if (isDataAll) {
                          setFieldValue(
                            'evaluators',
                            courseDataFetch.map((item) => item.value).filter((item) => item !== 0)
                          );
                        } else {
                          setFieldValue(
                            'evaluators',
                            newValue.map((item) => item.value)
                          );
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="medium"
                          fullWidth
                          placeholder={t('shared.filters.course.selected.period.placeholder')}
                        />
                      )}
                    />
                  )}
                </Grid>
              )}
              {templateSelected && templateSelected.eva_types?.group_type?.keyword !== 'EGC' && (
                <Grid item xs={12}>
                  <InfoAlert message={t('Esta opción no está disponible por el momento.')} />
                </Grid>
              )}
              {/* {templateSelected && templateSelected.eva_types?.group_type?.keyword !== 'EGC' && (
                <Grid
                  item
                  xs={12}
                  sx={{
                    mt: 2,
                  }}
                >
                  <Typography variant="h4">
                    {t('Escoja el evaluador para cada evaluado')}
                  </Typography>
                </Grid>
              )}

              {templateSelected &&
                templateSelected.eva_types?.group_type?.keyword !== 'EGC' &&
                isLoadingEvaluated && (
                  <Grid
                    container
                    item
                    xs={12}
                    sx={{
                      mb: 2,
                    }}
                  >
                    <Typography variant="h5">{t('Cargando Evaluados...')}</Typography>
                  </Grid>
                )}

              {templateSelected &&
                templateSelected.eva_types?.group_type?.keyword !== 'EGC' &&
                !isLoadingEvaluated &&
                evaluated.map((item, index) => (
                  <Grid
                    container
                    item
                    xs={12}
                    sx={{
                      mb: 2,
                    }}
                  >
                    <Grid item xs={12}>
                      <Typography variant="h5">{`${item.pers_full_name}`}</Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        mt: 1,
                      }}
                    >
                      <EvaluatorSelect
                        index={index}
                        setFieldValue={setFieldValue}
                        values={values}
                        isSuccess={isSuccess}
                      />
                    </Grid>
                  </Grid>
                ))}

              {errors.evaluators && touched.evaluators && (
                <Grid
                  item
                  xs={12}
                  sx={{
                    my: 1,
                  }}
                >
                  <FormHelperText error>{t(errors.evaluators)}</FormHelperText>
                </Grid>
              )} */}

              <Grid item xs={12}>
                <InputLabel>{t('maintenance.evaluation_type.form.name')}</InputLabel>
                <TextField
                  placeholder={t('maintenance.evaluation_type.form.name.placeholder')}
                  error={Boolean(errors.name && touched.name)}
                  {...getFieldProps('name')}
                  fullWidth
                />
                {errors.name && touched.name && (
                  <FormHelperText error>{t(errors.name)}</FormHelperText>
                )}
              </Grid>

              <Grid item xs={12}>
                <InputLabel>{t('maintenance.evaluation_type.form.description')}</InputLabel>
                <TextField
                  placeholder={t('maintenance.evaluation_type.form.description.placeholder')}
                  error={Boolean(errors.description && touched.description)}
                  {...getFieldProps('description')}
                  fullWidth
                />
                {errors.description && touched.description && (
                  <FormHelperText error>{t(errors.description)}</FormHelperText>
                )}
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={6}
                sx={{
                  mb: 2,
                }}
              >
                <Grid container spacing={1} alignItems="flex-start">
                  <Grid item xs zeroMinWidth>
                    <LocalizedDatePicker
                      value={values.start_date}
                      onChange={(newValue) => {
                        setFieldValue('start_date', newValue);
                      }}
                      label={t('financial.deposit_transfers.filters.dates.start_label')}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Grid container spacing={1} alignItems="flex-start">
                  <Grid item xs zeroMinWidth>
                    <LocalizedDatePicker
                      value={new Date(values.end_date)}
                      onChange={(newValue) => {
                        setFieldValue('end_date', newValue);
                      }}
                      label={t('financial.deposit_transfers.filters.dates.end_label')}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item container xs={12} justifyContent="flex-end" spacing={2}>
                <Grid item>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="large"
                    disabled={isLoading}
                    endIcon={isLoading && <CircularProgress size={12} color="primary" />}
                  >
                    {t('maintenance.evaluation_type.add.submit')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LaunchingForm;
