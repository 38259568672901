import Autocomplete from '@material-ui/core/Autocomplete';
import TextField from '@material-ui/core/TextField';

import { useTranslation } from 'react-i18next';

import { useAppSelector, useAppDispatch } from '../../../../../hooks/useRedux';
import { StudentRecord } from '../../../../shared/interfaces/searchPerson.interface';
import { setCurrentStudentRecord } from '../../slices/generateCreditNoteSlice';

const SelectStudentRecord = () => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const { currentClient, currentStudentRecord } = useAppSelector(
    (state) => state.generateCreditNote
  );

  const handleChangeCurrentStudentRecord = (studentRecord: StudentRecord) => {
    if (studentRecord) dispatch(setCurrentStudentRecord(studentRecord));
  };
  //
  return (
    <Autocomplete
      disabled
      options={currentClient?.user.student?.student_records || []}
      getOptionLabel={(option) => option.education_level.edu_name}
      // size="medium"
      renderInput={(params) => (
        <TextField
          {...params}
          // error={Boolean(errors.campusIds && touched.campusIds)}
          placeholder={t('financial.generate_creditnote.select_student_record.placeholder')}
          label={t('financial.generate_creditnote.select_student_record.label')}
          variant="standard"
        />
      )}
      value={
        currentClient?.user.student?.student_records.find(
          (dt) => dt.education_level_id === currentStudentRecord?.education_level_id
        ) || null
      }
      fullWidth
      clearIcon={false}
      onChange={(_, values) => {
        if (values) handleChangeCurrentStudentRecord(values);
      }}
    />
  );
};

export default SelectStudentRecord;
