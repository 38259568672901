import * as Yup from 'yup';

export const CreateRouteFormSchema = Yup.object().shape({
  description: Yup.string().required('academic.buses.route.dialog.form.description.required'),
  document: Yup.string().required('academic.buses.route.dialog.form.drag_file.required').nullable(),
  name: Yup.string().required('academic.buses.route.dialog.form.name.required'),
  campusId: Yup.number()
    .required('academic.buses.route.dialog.form.campus.required')
    .min(1, 'academic.buses.route.dialog.form.campus.required'),
});

export const UpdateRouteFormSchema = Yup.object().shape({
  description: Yup.string().required('academic.buses.route.dialog.form.description.required'),
  document: Yup.string().required('academic.buses.route.dialog.form.drag_file.required'),
  name: Yup.string().required('academic.buses.route.dialog.form.name.required'),
  campusId: Yup.number()
    .required('academic.buses.route.dialog.form.campus.required')
    .min(1, 'academic.buses.route.dialog.form.campus.required'),
  status_id: Yup.number().required('academic.areas.add.status.required'),
});

export const CreateScheduleFormSchema = Yup.object().shape({
  sche_name: Yup.string()
    .required('academic.buses.schedules.form.name.required')
    .min(1, 'academic.buses.schedules.form.name.required'),
});
