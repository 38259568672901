import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  boxContainer: {
    borderRadius: 12,
    backgroundColor: '#fff',
    paddingInline: 20,
    paddingBlock: 10,
  },
  title: {
    color: theme.palette.primary.main,
  },
}));

interface Props {
  title: string;
  career: string;
  educationLevel?: string;
  modality?: string ;
}

const BuilderTitle = ({ title, career, educationLevel, modality }: Props) => {
  const classes = useStyles();

  return (
    <Box className={classes.boxContainer}>
      <Typography variant="h3" className={classes.title}>
        {title} / {career} {!!educationLevel && `/ ${educationLevel} `}/ {modality && `(${modality})`}
      </Typography>
    </Box>
  );
};

export default BuilderTitle;
