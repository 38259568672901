import * as yup from 'yup';

export const changePaymentMethodSchema = yup.object().shape({
  paymentMethodKeyword: yup.string().required('Campo requerido'),
});

export const editDepositTransferSchema = yup.object().shape({
  paymentMethodKeyword: yup.string().required('Campo requerido'),
  financialInstituteTargetId: yup
    .number()
    .required('financial.deposit_transfers.edit.destined_institution.required'),
  financialInstituteAccountNumber: yup
    .string()
    .required('financial.deposit_transfers.edit.destined_account.required'),
  paymentDate: yup
    .date()
    .required('financial.deposit_transfers.edit.pay_date.required')

    .typeError('financial.deposit_transfers.edit.pay_date.invalid'),
  voucherNumber: yup.string().required('financial.deposit_transfers.edit.voucher_number.required'),
  financialInstituteOriginId: yup
    .number()
    .typeError('financial.deposit_transfers.edit.origin_institution.required')
    .nullable()
    .when('paymentMethodKeyword', {
      is: 'forma-pago-transferencia',
      then: yup
        .number()
        .typeError('financial.deposit_transfers.edit.origin_institution.required')
        .required('financial.deposit_transfers.edit.origin_institution.required'),
    }),
  nameHolderOriginAccount: yup
    .string()
    .typeError('financial.deposit_transfers.edit.name_holder_origin_account.required')
    .nullable()
    .when('paymentMethodKeyword', {
      is: 'forma-pago-transferencia',
      then: yup
        .string()
        .required('financial.deposit_transfers.edit.name_holder_origin_account.required')
        .typeError('financial.deposit_transfers.edit.name_holder_origin_account.required'),
    }),
});
