import { Avatar, Box, IconButton, Tooltip, Typography, useTheme } from '@material-ui/core';
import { Book } from 'phosphor-react';
import { Check, VisibilityOffOutlined } from '@material-ui/icons';
import { CabActivityStudent } from '../../interfaces/tasks.interface';
import { IconBulb } from '@tabler/icons';
import { useTranslation } from 'react-i18next';

interface Props {
  title: string;
  scoreActivity: string;
  calification: CabActivityStudent | null;
  isVisibleNote: boolean;
}

const TaskItemHeaderTitle = ({ title, scoreActivity, calification, isVisibleNote }: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        mb: 1,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ bgcolor: '#ddd', width: '30px', height: '30px' }}>
          <Book size={17} />
        </Avatar>
        <Typography
          variant="h6"
          sx={{
            marginLeft: 1,
            fontWeight: 'bold',
            lineHeight: '1.2',
          }}
        >
          {title}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          marginLeft: '5px',
        }}
      >
        {calification &&
        calification.answer_qualified !== '0' &&
        calification.answer_qualified !== null ? (
          isVisibleNote && (
            <Check
              sx={{
                color: theme.palette.secondary.main,
                fontSize: '20px',
                mr: '5px',
              }}
            />
          )
        ) : (
          <IconBulb
            color={theme.palette.secondary.main}
            size="20px"
            style={{ marginRight: '5px' }}
          />
        )}
        {isVisibleNote ? (
          calification &&
          calification.answer_qualified !== '0' &&
          calification.answer_qualified !== null ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontSize: '0.7rem',
                  fontWeight: 'bold',
                  color: theme.palette.primary.main,
                }}
              >
                {`${Number(calification?.act_score)}/${Number(scoreActivity)}`}
              </Typography>
            </Box>
          ) : (
            <Typography
              sx={{
                fontSize: '0.7rem',
                fontWeight: 'bold',
              }}
            >
              {Number(scoreActivity)}
            </Typography>
          )
        ) : (
          <Tooltip title={t('studentVirtualClassroom.test.card.description.hideNote')}>
            <span style={{ cursor: 'pointer' }}>
              <IconButton disabled={true} color="secondary">
                <VisibilityOffOutlined />
              </IconButton>
            </span>
          </Tooltip>
        )}
      </Box>
    </Box>
  );
};

export default TaskItemHeaderTitle;
