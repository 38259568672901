import {
  Autocomplete,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import TextFieldFetching from '../../../shared/components/TextFieldFetching';
import {
  useChangePaymentMethodMutation,
  useGetPaymentMethodsQuery,
} from '../slices/depositsTransfersApiSlice';
import {
  setChangePaymentMethodModalOpen,
  setDepositTransferId,
} from '../slices/depositsTransfersSlice';

const ChangePaymentMethodDialog = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { currentProfile } = useAppSelector((state) => state.access);
  const { changePaymentMethodModalOpen, depositTransferId, depositTransferList } = useAppSelector(
    (state) => state.depositsTransfers
  );

  const depositTransfer = depositTransferList.find(
    (depositTransfer) => depositTransfer.id === depositTransferId
  );

  const [showAlert, setShowAlert] = useState(false);

  const { values, setFieldValue, errors, touched } = useFormik({
    initialValues: {
      paymentMethodKeyword:
        depositTransfer?.other_methods_payment?.cat_keyword ||
        depositTransfer?.payment_way?.cat_keyword ||
        '',
    },
    onSubmit: async (values) => {
      setShowAlert(true);
    },
  });

  const [changePaymentMethod, { isLoading, isSuccess, isError, error }] =
    useChangePaymentMethodMutation();

  const { data: paymentMethods, isLoading: isLoadingPaymentMethods } = useGetPaymentMethodsQuery({
    profileId: currentProfile?.profileId!,
  });

  const handleAction = async () => {
    await changePaymentMethod({
      transactionId: depositTransferId!,
      paymentMethodKeyword: values.paymentMethodKeyword,
      profileId: currentProfile?.profileId!,
    });
    setShowAlert(true);
  };

  const handleClose = () => {
    dispatch(setChangePaymentMethodModalOpen(false));
    dispatch(setDepositTransferId(null!));
  };

  const paymentMethodsToShow = [depositTransfer!.payment_way, ...(paymentMethods || [])];

  return (
    <Dialog open={changePaymentMethodModalOpen} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Typography variant="h3" color="primary">
          {t('financial.deposit_transfers.change_payment_method')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          {showAlert && isSuccess && (
            <Grid item xs={12}>
              <SuccessAlert
                message={t('financial.deposit_transfers.change_payment_method.success')}
                handleDismiss={() => setShowAlert(false)}
              />
            </Grid>
          )}
          {showAlert && isError && (
            <Grid item xs={12}>
              <ErrorAlert message={error as string} handleDismiss={() => setShowAlert(false)} />
            </Grid>
          )}
          <Grid item xs={12}>
            {isLoadingPaymentMethods ? (
              <TextFieldFetching />
            ) : (
              <Autocomplete
                options={paymentMethodsToShow}
                loading={isLoadingPaymentMethods}
                getOptionLabel={(option) => option.cat_name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={t(
                      'financial.deposit_transfers.change_payment_method.payment_method.placeholder'
                    )}
                    error={Boolean(errors.paymentMethodKeyword && touched.paymentMethodKeyword)}
                  />
                )}
                value={
                  paymentMethodsToShow.find(
                    (paymentMethod) => paymentMethod?.cat_keyword === values.paymentMethodKeyword
                  ) || null
                }
                onChange={(event, value) => {
                  setFieldValue('paymentMethodKeyword', value?.cat_keyword || '');
                }}
              />
            )}
            {errors.paymentMethodKeyword && touched.paymentMethodKeyword && (
              <FormHelperText error>{t(errors.paymentMethodKeyword)}</FormHelperText>
            )}
          </Grid>
          <Grid item container justifyContent="center" xs={12}>
            <Button variant="outlined" color="secondary" onClick={handleClose} sx={{ mr: 2 }}>
              {t('financial.deposit_transfers.change_payment_method.cancel')}
            </Button>

            <Button
              variant="contained"
              color="primary"
              onClick={handleAction}
              disabled={isLoading}
              endIcon={isLoading && <CircularProgress size={14} color="inherit" />}
            >
              {t('financial.deposit_transfers.change_payment_method.save')}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
export default ChangePaymentMethodDialog;
