import { Grid, TextField, FormHelperText, Autocomplete, Button } from '@material-ui/core';
import { DateRange } from '@material-ui/lab';
import { useFormik } from 'formik';
import { IconSearch } from '@tabler/icons';

import InputLabel from '../../../shared/components/forms/InputLabel';
import statusProject from '../../lines-of-investigation/constants/lists/statusProject';
// import { format } from 'date-fns';
import LocalizedDateRangePicker from '../../../shared/components/controlled/LocalizedDateRangePicker';

interface Props {
  dispatchFilterData: React.Dispatch<React.SetStateAction<DataFilterProjectFollow | undefined>>;
}

export interface DataFilterProjectFollow {
  statusProject: string;
  rangeDate: DateRange<Date>;
}

const statusProjectList = statusProject;

const ProjectFollowFilter = ({ dispatchFilterData }: Props) => {
  const { setFieldValue, errors, touched, values } = useFormik({
    initialValues: {
      statusProject: '',
      rangeDate: [null, null] as DateRange<Date>,
    },
    onSubmit: async (value) => {
      // sendFilterData;
    },
  });

  const sendFilterData = () => {
    dispatchFilterData({
      rangeDate: values.rangeDate,
      statusProject: values.statusProject,
    });
  };
  console.log('values', values);

  return (
    <Grid container>
      <Grid item container xs={12} alignItems="center" spacing={2}>
        {false && (
          <Grid item xs={12} md={4}>
            <InputLabel>Estado de proyecto test</InputLabel>
            <Autocomplete
              options={statusProjectList!}
              getOptionLabel={(option) => option?.label!}
              renderInput={(params) => (
                <TextField
                  placeholder="Ingrese un estado de proyecto"
                  {...params}
                  error={Boolean(errors.statusProject && touched.statusProject)}
                />
              )}
              value={statusProjectList?.find((data) => data.keyword === values.statusProject)}
              onChange={(e, value) => setFieldValue('statusProject', value?.keyword || '')}
              fullWidth
              clearIcon={null}
            />
            {errors.statusProject && touched.statusProject && (
              <FormHelperText error>(errors.statusProject)</FormHelperText>
            )}
          </Grid>
        )}
        <Grid item xs={12} md={4}>
          <LocalizedDateRangePicker
            value={values.rangeDate}
            onChange={(newValue) => {
              setFieldValue('rangeDate', newValue);
            }}
            startText="Fecha de inicio"
            endText="Fecha fin"
          />
          {/* <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
            <DateRangePicker
              // startText={t('academic.requisition.table.start_date')}
              // endText={t('academic.requisition.table.end_date')}
              renderInput={(startProps, endProps) => (
                <>
                  <Grid item container xs={12} spacing={2}>
                    <Grid item xs={6}>
                      <InputLabel>Fecha de inicio</InputLabel>
                      <TextField {...startProps} label={false} helperText={false} />
                    </Grid>
                    <Grid item xs={6}>
                      <InputLabel>Fecha fin</InputLabel>
                      <TextField {...endProps} label={false} helperText={false} />
                    </Grid>
                  </Grid>
                </>
              )}
            />
          </LocalizationProvider> */}
        </Grid>
        {/* </Grid> */}

        {/* <Grid item container xs={12} md={12} spacing={2} alignItems="center"> */}
        <Grid item mt={2}>
          <Button
            onClick={sendFilterData}
            variant="outlined"
            size="large"
            startIcon={<IconSearch />}
          >
            Buscar
          </Button>
        </Grid>
        {/* </Grid> */}
        {/* </Grid> */}
      </Grid>
    </Grid>
  );
};

export default ProjectFollowFilter;
