import { useTranslation } from 'react-i18next';

import DeleteAlert from '../../../shared/components/dialogs/DeleteAlert';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { resetCashPointToProcess, setCloseCashPointModalOpen } from '../slices/cashPointsSlice';
import { useCloseCashPointMutation } from '../slices/cashPointsApiSlice';

const CloseCashPointDialog = () => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const { isOpenCloseCashPointModal, cashPointToProcess } = useAppSelector(
    (state) => state.cashPoints
  );

  const [closeCashPoint, { isLoading, isSuccess, isError, error }] = useCloseCashPointMutation();

  const handleDelete = () => {
    closeCashPoint({
      cashPointsPayload: [cashPointToProcess],
    });
  };

  const handleClose = () => {
    dispatch(setCloseCashPointModalOpen(false));
    dispatch(resetCashPointToProcess());
  };

  return (
    <DeleteAlert
      open={isOpenCloseCashPointModal}
      onClose={handleClose}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      handleDelete={handleDelete}
      error={error as string}
      deleteTitle={t('financial.close_cash_point.close_dialog.delete_title')}
      deleteSubTitle={t('financial.close_cash_point.close_dialog.delete_sub_title')}
      deleteBtnText={t('financial.close_cash_point.close_dialog.confirm')}
      deleteSuccess={t('financial.close_cash_point.close_dialog.delete_success')}
    />
  );
};

export default CloseCashPointDialog;
