import { useEffect, useState } from 'react';

import { Button, CircularProgress, Grid, IconButton, Stack, Tooltip } from '@material-ui/core';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PlayArrow from '@material-ui/icons/PlayArrow';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import SearchPaginatedTable from '../../../shared/components/tables/SearchPaginatedTable';
import {
  useGetNotificationsQuery,
  useReadNotificationMutation,
} from '../../../shared/slices/notificationsApi';
import useGoToVirtualClassroom from '../../../students/academic-portfolio/hooks/useGoToVirtualClassroom';
import { Notification } from '../../../shared/interfaces/notifications.interface';
import StatusChip from '../../../shared/components/badges/StatusChip';
import { returnNewUrlVc } from '../../../shared/layout/MainLayout/Header/NotificationSection/helpers';
import { useTableSearch } from '../../../../hooks/useTableSearch';
import { updateNotificationTable } from '../slices/notificationsSlice';
// import { useTableSearch } from '../../../../hooks/useTableSearch';

const NotificationsTable = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  if (false) console.log(dispatch);

  const history = useHistory();

  const { path } = useRouteMatch();
  if (false) console.log(path);

  const [onlyPending, setOnlyPending] = useState(false);

  const [valueCourseId, setValueCourseId] = useState(0);
  const [routePath, setRoutePath] = useState('');

  const { currentProfile } = useAppSelector((state) => state.access);

  const { page, perPage, search, filterArrow } = useAppSelector(
    (state) => state.notifications.notificationTable
  );

  const { setPage, setPerPage, setSearch, resetTable } = useTableSearch({
    page,
    perPage,
    search,
    updateFunction: updateNotificationTable,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => resetTable(), []);

  const { isFetching: isFetchingGoToAct } = useGoToVirtualClassroom(
    +valueCourseId,
    `${routePath}`,
    currentProfile?.keyword === 'docente' ? true : false
  );

  // const { setPage, setPerPage, setSearch, setFilterArrow } = useTableSearch({
  //   search,
  //   page,
  //   perPage,
  //   filterArrow,
  //   updateFunction: updateFaqsTable,
  // });

  const { isLoading, data, isFetching, error } = useGetNotificationsQuery({
    onlyPending,
    page,
    perPage,
    search,
  });

  const [readNotification, { isLoading: isLoadingRead }] = useReadNotificationMutation();

  const datafetch = data?.data?.map((item, index) => ({
    // id: index + 1, // + (page - 1) * perPage + 1,
    description: item?.announcement.ans_message,
    notificationDate: item.announcement.ans_date_send,
    state: item.rec_date_read,
    options: item,
  }));

  const handleGotoNotification = (notification: Notification) => {
    if (isLoadingRead || isFetchingGoToAct) return;

    if (!notification.rec_date_read)
      readNotification({ announcementId: +notification.announcement.id });

    if (notification.announcement.course_id && notification.announcement.url) {
      const newUrl = returnNewUrlVc(notification.announcement.url);

      setRoutePath(newUrl!);
      return setValueCourseId(+notification?.announcement?.course_id!);
    }

    return history.push(`/${notification.announcement.url}`);
  };

  // useEffect(() => {
  //   if (data) {
  //     if (Array.isArray(data?.data)) {
  //       dispatch(setFaqTable(data?.data));
  //     }
  //   }
  // }, [dispatch, data]);

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Stack pl={2} direction="row" justifyContent="flex-end" spacing={2}>
            <Grid item>
              <Button
                variant={!onlyPending ? 'contained' : 'outlined'}
                onClick={() => setOnlyPending(false)}
              >
                Todas
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant={onlyPending ? 'contained' : 'outlined'}
                onClick={() => setOnlyPending(true)}
              >
                No leídos
              </Button>
            </Grid>
          </Stack>
        </Grid>

        <SearchPaginatedTable
          data={datafetch!}
          headers={{
            // id: 'N°',
            description: 'Descripción',
            notificationDate: 'Fecha de envió',
            state: 'Estado',
            options: 'Opciones',
          }}
          // listFilterArrows={{
          //   description: 'announcement.ans_message',
          // }}
          // replaceHeader={true}
          newHeader={<></>}
          filterArrow={filterArrow}
          setFilterArrow={() => {}}
          // setFilterArrow={setFilterArrow}
          keyExtractor={(item) => item.options.id.toString()}
          searchPlacehoder={t('configuration.faqs.search')}
          showFilter={false}
          isLoading={isLoading}
          isFetching={isFetching}
          error={error}
          perPage={perPage}
          setPerPage={setPerPage}
          total={data?.total || 0}
          page={page}
          setPage={setPage}
          numHeader={7}
          search={search}
          setSearch={setSearch}
          customDataCellsProperties={{
            state: { align: 'center' },
            notificationDate: { align: 'center' },
            description: {
              align: 'left',
            },
          }}
          customHeadersCellsProperties={{
            state: {
              align: 'center',
            },
            notificationDate: {
              align: 'center',
            },
            description: {
              align: 'left',
            },
          }}
          customRenderers={{
            state: ({ state }) => (
              <Grid container>
                <Grid item>
                  {!state ? (
                    <StatusChip label="No leído" statusId={2} />
                  ) : (
                    <StatusChip label="Leído" statusId={1} />
                  )}
                </Grid>
              </Grid>
            ),
            options: ({ options }) => (
              <>
                <Tooltip title={t('Ir a notificación')}>
                  <span>
                    <IconButton
                      onClick={() => handleGotoNotification(options)}
                      aria-label={t('Ir a notificación')}
                      color="secondary"
                      disabled={isFetchingGoToAct}
                    >
                      {isFetchingGoToAct ? (
                        <CircularProgress size={20} />
                      ) : (
                        <PlayArrow sx={{ fontSize: '1.3rem' }} />
                      )}
                    </IconButton>
                  </span>
                </Tooltip>
              </>
            ),
          }}
        />
      </Grid>
    </>
  );
};

export default NotificationsTable;
