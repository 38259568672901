import projectPermissions from '../../../shared/constants/projectPermissions';

export const groupConfigurationPermissions = {
  groupKey: projectPermissions.groupConfiguration.groupKey,

  read: projectPermissions.groupConfiguration.read,
  store: projectPermissions.groupConfiguration.store,
};

export const groupConfigurationStudentsPermissions = {
  groupKey: projectPermissions.groupConfigurationStudents.groupKey,

  groupConfigurationByStudent:
    projectPermissions.groupConfigurationStudents.groupConfigurationByStudent,
  delete: projectPermissions.groupConfigurationStudents.delete,

  disabledGroup: projectPermissions.groupConfigurationStudents.disabledGroup,
};

export const studentPermissions = {
  groupKey: projectPermissions.student.groupKey,

  accountStatusPdf: projectPermissions.student.accountStatusPdf,
  getDebstByStudents: projectPermissions.student.getDebstByStudents,
};

export const feesPermissions = {
  groupKey: projectPermissions.fees.groupKey,

  setDateExtension: projectPermissions.fees.setDateExtension,
};

export const debtorsPermissions = { 
  groupKey: projectPermissions.debtors.groupKey,

  update: projectPermissions.debtors.update,
  getDebtorsByStudent: projectPermissions.debtors.getDebtorsByStudent,
  getDebtorPerPerson: projectPermissions.debtors.getDebtorPerPerson,
};

export const financialInformationPermissions = {
  groupKey: projectPermissions.financialInformation.groupKey,

  getIdDebtor: projectPermissions.financialInformation.getIdDebtor,
};
