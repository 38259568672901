import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import {
  Autocomplete,
  Button,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
  InputAdornment,
  CircularProgress,
} from '@material-ui/core';

import { useFormik } from 'formik';

import ErrorAlert from '../../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../../shared/components/alerts/SuccessAlert';

import { useAppSelector } from '../../../../../hooks/useRedux';

import { ELSSchemaEdit } from '../../constants/validation-schemas';
import { statusAvailables } from '../../../../shared/constants/resourceStatus';

import { useUpdateEducationLevelSubjectMutation } from '../../../education-level-subject/slices/ELSApiSlice';

import { useGetAvailablesSubjectsNivelationQuery } from '../../../matters/slices/mattersApiSlice';
import { useGetAvailablesGroupAreasQuery } from '../../../groups-area/slices/groupAreasApiSlice';

import { useFormStyles } from '../../constants/styles';

const EditELSForm = () => {
  const history = useHistory();
  const { ELSId } = useParams<{ ELSId: string }>();
  const { currentProfile } = useAppSelector((state) => state.access);

  const { data: availablesSubjectsNivelation = [], isLoading: isLoadingSubjectsNivelation } =
    useGetAvailablesSubjectsNivelationQuery({
      profileId: currentProfile?.profileId!,
    });

  const { data: availablesGroupAreasQuery = [], isLoading: isLoadingGroupsAreas } =
    useGetAvailablesGroupAreasQuery({
      profileId: currentProfile?.profileId!,
    });

  const { educationLevelSubjectGroupList } = useAppSelector((state) => state.educationLevelSubject);

  const classes = useFormStyles();

  const [showAlerts, setShowAlerts] = useState(false);

  const [updateELS, { isLoading: isUpdating, isSuccess, isError, error }] =
    useUpdateEducationLevelSubjectMutation();

  const ELSToEdit = educationLevelSubjectGroupList.find((p) => p.id === Number(ELSId));

  const { errors, touched, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      groupNivelationId: ELSToEdit?.group_area.id || 1,
      subjectId: ELSToEdit?.subject_id || 1,
      statusId: ELSToEdit?.status.id || 1,
    },
    validationSchema: ELSSchemaEdit,
    onSubmit: async (values) => {
      await updateELS({
        educationLevelSubjectId: Number(ELSId),
        profileId: currentProfile?.profileId!,
        educationLevelSubjectPayload: {
          group_nivelation_id: Number(values.groupNivelationId),
          subject_id: Number(values.subjectId),
          status_id: Number(values.statusId),
        },
      });

      setShowAlerts(true);
    },
  });

  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction="column" gap={2}>
        <Grid item>
          {isError && showAlerts && (
            <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
          )}

          {isSuccess && showAlerts && (
            <SuccessAlert
              message="La materia ha sido actualizada correctamente."
              handleDismiss={() => setShowAlerts(false)}
            />
          )}
        </Grid>

        <Grid item xs={12}>
          <InputLabel className={classes.inputLabel}>Grupo</InputLabel>

          {isLoadingGroupsAreas ? (
            <TextField
              disabled
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CircularProgress size={25} />
                  </InputAdornment>
                ),
              }}
              placeholder="Cargando datos ..."
            />
          ) : (
            <>
              <Autocomplete
                options={availablesGroupAreasQuery}
                loading={isLoadingGroupsAreas}
                loadingText="Cargando grupo..."
                getOptionLabel={(option) => option.arg_name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(errors.subjectId && touched.subjectId)}
                    placeholder="Seleccione un grupo disponible"
                  />
                )}
                defaultValue={availablesGroupAreasQuery.find(
                  (e) => e.id === ELSToEdit?.group_area.id!
                )}
                onChange={(_, value) => setFieldValue('subjectId', value?.id || '')}
                fullWidth
                clearIcon={null}
              />
              {errors.subjectId && touched.subjectId && (
                <FormHelperText error>{errors.subjectId}</FormHelperText>
              )}
            </>
          )}
        </Grid>

        <Grid item xs={12}>
          <InputLabel className={classes.inputLabel}>Materias</InputLabel>

          {isLoadingSubjectsNivelation ? (
            <TextField
              disabled
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CircularProgress size={25} />
                  </InputAdornment>
                ),
              }}
              placeholder="Cargando datos ..."
            />
          ) : (
            <>
              <Autocomplete
                options={availablesSubjectsNivelation}
                loading={isLoadingSubjectsNivelation}
                loadingText="Cargando materias..."
                defaultValue={availablesSubjectsNivelation.find(
                  (e) => e.id.toString() === ELSToEdit?.subject_id
                )}
                getOptionLabel={(option) => option.mat_name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(errors.subjectId && touched.subjectId)}
                    placeholder="Seleccione una materia disponible"
                  />
                )}
                // defaultValue={campusAvailables[0]}
                onChange={(_, value) => setFieldValue('subjectId', value?.id || '')}
                fullWidth
                clearIcon={null}
              />
              {errors.subjectId && touched.subjectId && (
                <FormHelperText error>{errors.subjectId}</FormHelperText>
              )}
            </>
          )}
        </Grid>

        <Grid item xs={12}>
          <InputLabel className={classes.inputLabel}>Estado</InputLabel>
          <Autocomplete
            options={statusAvailables}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField {...params} error={Boolean(errors.statusId && touched.statusId)} />
            )}
            defaultValue={statusAvailables.find((e) => e.id === ELSToEdit?.status.id!)}
            onChange={(e, value) => setFieldValue('statusId', value?.id || '')}
            fullWidth
            clearIcon={null}
          />
          {errors.statusId && touched.statusId && (
            <FormHelperText error>{errors.statusId}</FormHelperText>
          )}
        </Grid>

        <Grid
          item
          container
          alignItems="center"
          justifyContent="flex-end"
          spacing={2}
          sx={{ mt: 1 }}
        >
          <Grid item>
            <Button
              variant="outlined"
              size="large"
              color="primary"
              onClick={() => history.goBack()}
            >
              Regresar
            </Button>
          </Grid>
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              disabled={isUpdating}
              endIcon={isUpdating && <CircularProgress size={20} />}
            >
              Actualizar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default EditELSForm;
