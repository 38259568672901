import { Grid, Typography, IconButton } from '@material-ui/core';
import { IconArrowNarrowLeft } from '@tabler/icons';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import useTheme from '@material-ui/core/styles/useTheme';

import MainCard from '../../../../../../shared/components/cards/MainCard';
import StagesTable from '../components/StagesTable';

const StagesScreen = () => {
  const { t } = useTranslation();

  const theme = useTheme();

  const history = useHistory();

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid item container alignItems="center" gap={1} sx={{ cursor: 'pointer' }}>
        <IconButton onClick={() => history.push('/administrar-lineas-de-investigacion/proyectos')}>
          <IconArrowNarrowLeft size={30} color={theme.palette.text.dark} />
        </IconButton>
        <Typography variant="h2">{t('Etapas')}</Typography>
      </Grid>
      <Grid container>
        <StagesTable />
      </Grid>
    </Grid>
  );
};

export default StagesScreen;
