// import { PermissionsKeys } from '../../../shared/interfaces/permissions.interface';

export const institutesPermissions = {
  groupKey: 'institutes',

  read: 'institutes-listar-institutos',
  readTypes: 'institutetype-listar-tipos-de-institutos',
  create: 'institutes-crear-instituto',
  show: 'institutes-obtener-instituto',
  edit: 'institutes-actualizar-instituto',
  delete: 'institutes-eliminar-instituto',
}; // satisfies PermissionsKeys;

export const economicGroupPermissions = {
  groupKey: 'economicGroup',

  read: 'economic_group-listar-grupo-economico',
}; // satisfies PermissionsKeys;
