import { mainApi } from '../../../../api/mainApi';
import { AccessState } from '../../../../types';
import { middlewareApi } from '../../../middleware/middlewareApi';
import { StudentAnnouncement } from '../../../teachers/academic-couseling/students-asigned/interfaces/student-announcement.interfaces';
import { CareerAdvance, SemesterSubjectDetail } from '../interfaces/career-advance.interfaces';
import { StudentAnnouncementResponse } from '../interfaces/student-announcements.interfaces';
import {
  CalificationMatterStatus,
  StudentHistoryCalification,
} from '../interfaces/student-history-calification.interfaces';

import { setHistoryCalification, setMatterIdNew } from './AcademicCounselingSlice';

export const academicCounselingTags = middlewareApi.enhanceEndpoints({
  addTagTypes: ['academicsCounselings', 'StudentAnnouncements'],
});

// Define a service using a base URL and expected endpoints
export const academicCounselingApi = academicCounselingTags.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * @GET career advance
     */
    getStudentCareerAdvance: builder.query<
      CareerAdvance,
      { studentRecordId: number; profileId?: number }
    >({
      queryFn: async ({ studentRecordId, profileId }, { dispatch, getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = profileId || currentProfile?.profileId;

          const { data } = await mainApi.get<CareerAdvance>(
            `students/student-record/${studentRecordId}/career-advance`,
            {
              params: {
                user_profile_id,
              },
            }
          );

          dispatch(setMatterIdNew(data.curriculumNew));
          // const dataObject: CareerAdvance = {
          //   curriculumNew: data.curriculumNew,
          //   semesters:
          //     data.semesters?.map((semester) => ({
          //       semester: semester.semester,
          //       approved_subjects: semester.approved_subjects,
          //       required_subjects: semester.required_subjects,
          //       missing_subjects: `${
          //         parseInt(semester.required_subjects) - parseInt(semester.approved_subjects)
          //       }`,
          //       detail: '',
          //     })) ?? undefined,
          //   areas:
          //     data.areas?.map((area) => ({
          //       id: area.id,
          //       ar_name: area.ar_name,
          //       approved_subjects: parseFloat(area.approved_subjects).toFixed(2),
          //       required_subjects: parseFloat(area.required_subjects).toFixed(2),
          //       missing_subjects: `${
          //         parseFloat(area.required_subjects) - parseFloat(area.approved_subjects)
          //       }`,
          //       detail: '',
          //     })) ?? undefined,
          // };
          // console.log(dataObject);
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),
    /**
     * @GET history calification
     */
    getSemesterSubjectsDetail: builder.query<
      SemesterSubjectDetail[],
      { studentRecordId: number; profileId: number; semester: number }
    >({
      queryFn: async ({ studentRecordId, profileId, semester }, { dispatch }) => {
        try {
          const { data } = await mainApi.get<SemesterSubjectDetail[]>(
            `/curriculum/semesters/${semester}/detail-subjects?student_record_id=${studentRecordId}`,
            {
              params: {
                user_profile_id: profileId,
              },
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),
    /**
     * @GET history calification
     */
    getAreaSubjectsDetail: builder.query<
      SemesterSubjectDetail[],
      { studentRecordId: number; profileId: number; areaId: number }
    >({
      queryFn: async ({ studentRecordId, profileId, areaId }, { dispatch }) => {
        try {
          const { data } = await mainApi.get<SemesterSubjectDetail[]>(
            `/areas/${areaId}/detail-subjects?student_record_id=${studentRecordId}`,
            {
              params: {
                user_profile_id: profileId,
              },
            }
          );

          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
    }),
    /**
     * @GET history calification
     */
    getStudentHistoryCalification: builder.query<
      StudentHistoryCalification[],
      { student: number; educationLevel: number; profileId: number; courses?: string }
    >({
      queryFn: async ({ student, educationLevel, profileId, courses }, { dispatch }) => {
        try {
          const { data } = await mainApi.get<StudentHistoryCalification[]>(
            `/students/${student}/course-students`,
            {
              params: {
                user_profile_id: profileId,
                education_level_id: educationLevel,
                ...(courses === 'all' && { data: courses }),
                sort: 'year',
                type_sort: 'asc',
              },
            }
          );

          dispatch(setHistoryCalification(Array.isArray(data) ? data : []));
          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'academicsCounselings', id } as const)),
              { type: 'academicsCounselings', id: 'LIST' },
            ]
          : [{ type: 'academicsCounselings', id: 'LIST' }],
    }),

    getHistoryCalificationStatusMatter: builder.query<
      CalificationMatterStatus,
      { profileId: number }
    >({
      queryFn: async ({ profileId }, { dispatch }) => {
        try {
          const { data } = await mainApi.get<CalificationMatterStatus>(`/category-status/6`, {
            params: { user_profile_id: profileId },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    /**
     * @GET student all announcements
     */
    getStudentAllAnnouncements: builder.query<
      StudentAnnouncementResponse,
      { user_profile_id: number; studentId: number; perPage?: number; page?: number }
    >({
      queryFn: async ({ perPage, page }, { dispatch }) => {
        try {
          const { data } = await mainApi.get<StudentAnnouncementResponse>(
            '/users/announcements/receivers',
            {
              params: {
                size: perPage,
                page: page,
                acronym: 'CONS',
              },
            }
          );

          // if (page === 0) dispatch(resetStudentAnnouncements());

          // const dataNew = Array.isArray(data) ? data : [];

          // dispatch(setStudentAnnouncements(dataNew));

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: [{ type: 'StudentAnnouncements', id: 'LIST' }],
    }),

    /**
     * @POST Add students announcements
     */
    addAnnouncementByStudent: builder.mutation<
      StudentAnnouncement,
      { profileId: number; studentAdsPayload: StudentAnnouncement }
    >({
      queryFn: async ({ profileId, studentAdsPayload }) => {
        try {
          const { data } = await mainApi.post<StudentAnnouncement>('/users/ads', {
            ...studentAdsPayload,
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'StudentAnnouncements', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetStudentCareerAdvanceQuery,
  useGetSemesterSubjectsDetailQuery,
  useGetAreaSubjectsDetailQuery,
  useGetStudentHistoryCalificationQuery,
  useGetStudentAllAnnouncementsQuery,
  useGetHistoryCalificationStatusMatterQuery,
  useAddAnnouncementByStudentMutation,
} = academicCounselingApi;
