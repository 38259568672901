import {
  Alert,
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Grid,
  InputLabel,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { useTableSearch } from '../../../../hooks/useTableSearch';
import IconTelework from '../../../shared/components/illustrations/IconTelework';
import TextFieldFetching from '../../../shared/components/TextFieldFetching';
import { Period } from '../../academic-offerings/interfaces/periods.interface';
import {
  useGetAcademicOfferingQuery,
  // useGetAcademicOfferingQuery,
  useGetAvailablesOffersQuery,
  useGetPeriodAcademicOfferingQuery,
} from '../../academic-offerings/slices/academicOfferingsApiSlice';
import { useGetAvailablesCampusQuery } from '../../campus/slices/campusApiSlice';
import { useLazyGetCoursesByFiltersQuery } from '../slices/courseApiSlice';
import {
  setCoursesByFilterResponse,
  updateCoursesFilter,
  setCourseList,
  updateCoursesTable,
  CoursesFilter,
} from '../slices/courseSlice';
import CoursesTable from './CoursesTable';
import { useTranslation } from 'react-i18next';
import { isOutRangeDate } from '../helpers';
import useSharedGuard from '../../../../hooks/useSharedGuard';
import { subPermissionKeys } from '../constants/permisssion';

const FilterCourses = () => {
  const { t } = useTranslation();

  const theme = useTheme();
  const dispatch = useAppDispatch();

  const [isError] = useState(false);
  // const [filtersSearch, setFiltersSearch] = useState(false);
  // const [viewAll, setViewAll] = useState(false);

  const { hasAccess } = useSharedGuard(subPermissionKeys);

  const [displayFilters, setDisplayFilters] = useState(true);

  const [viewAll] = useState(false);

  const { currentProfile } = useAppSelector((state) => state.access);

  const { coursesFilter, coursesByFilterResponse, alertMessage } = useAppSelector(
    (state) => state.courses
  );

  const { page, perPage, filterArrow } = useAppSelector((state) => state.courses.coursesTable);

  const {
    offerDescription,
    campusId,
    educationLevelId,
    hourhandId,
    parallelId,
    modalityId,
    periodId,
    search,
    periodEndProcess,
  } = coursesFilter;

  const { resetTable } = useTableSearch({
    page,
    perPage,
    updateFunction: updateCoursesTable,
    search: search || '',
  });

  const handleFilterChange = (object: Partial<CoursesFilter>) => {
    dispatch(
      updateCoursesFilter({
        ...coursesFilter,
        ...object,
      })
    );
  };

  //sedes
  const {
    data: campusAvailables = [],
    isLoading: isLoadingCA,
    isFetching: isFetchingCA,
  } = useGetAvailablesCampusQuery({
    profileId: currentProfile?.profileId!,
  });

  //niveles eduactivos
  const {
    data: offersAvailables = [],
    isLoading: isLoadingPID,
    isFetching: isFetchingPID,
  } = useGetAvailablesOffersQuery(
    {
      profileId: currentProfile?.profileId!,
      off_description: '',
    },
    { refetchOnMountOrArgChange: 30 }
  );

  //periodos
  const {
    data: periodsByOffer = [],
    isLoading: isLoadingCID,
    isFetching: isFetchingCID,
  } = useGetPeriodAcademicOfferingQuery(
    {
      profileId: currentProfile?.profileId!,
      academicOfferingId:
        offersAvailables!
          .find((offer) => offer.off_description === offerDescription)
          ?.id?.toString() || '',
      campusId: campusId?.toString(),
      current: true,
    },
    { skip: !offerDescription, refetchOnMountOrArgChange: 30 }
  );

  const periods = useMemo(() => {
    return Array.isArray(periodsByOffer)
      ? (periodsByOffer as unknown as Period[]).filter(
          (dt) => new Date(dt.end_process).getTime() > new Date().getTime()
        )
      : [];
  }, [periodsByOffer]);

  const {
    data: offerById,
    isLoading: isLoadingOID,
    isFetching: isFetchingOID,
  } = useGetAcademicOfferingQuery(
    {
      profileId: currentProfile?.profileId!,
      academicOfferingId:
        offersAvailables!.find((offer) => offer.off_description === offerDescription)?.id! || 0,
    },
    { skip: !offerDescription, refetchOnMountOrArgChange: 30 }
  );

  const [
    getCoursesByFilters,
    {
      isLoading: isLoadingCourses,
      isFetching: isFetchingCourses,

      // data: courses,
    },
  ] = useLazyGetCoursesByFiltersQuery();

  const handleSearch = useCallback(async () => {
    const { data } = await getCoursesByFilters(
      {
        profileId: currentProfile?.profileId!,
        campusId: campusId!,
        periodId,
        educationLevelId: educationLevelId || 0,
        offerDescription,
        ...(hourhandId && { hourhandId }),
        ...(parallelId && { parallelId }),
        ...(search && { search }),
        ...(modalityId && { modalityId }),
        ...(filterArrow && { sort: filterArrow.name, type_sort: filterArrow.direction }),
        page,
        perPage,
      },
      true
    );

    dispatch(setCoursesByFilterResponse(data!));
    dispatch(setCourseList(data?.data || []));
    resetTable();
    setDisplayFilters(false);
  }, [
    campusId,
    currentProfile?.profileId,
    dispatch,
    educationLevelId,
    filterArrow,
    getCoursesByFilters,
    hourhandId,
    modalityId,
    offerDescription,
    page,
    parallelId,
    perPage,
    periodId,
    resetTable,
    search,
  ]);

  useEffect(() => {
    if (filterArrow) handleSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterArrow]);

  return (
    <>
      {isLoadingCourses ? (
        <Grid container>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <CircularProgress />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h3" color="primary" textAlign="center">
              {t('academic.courses.loading.title')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" textAlign="center">
              {t('academic.courses.loading.subtitle')}
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <>
          {!isError && !coursesByFilterResponse && (
            <Box>
              <Grid container justifyContent={'center'} alignContent={'center'} mb={2}>
                <Grid container justifyContent={'center'} item xs={12} md={10} lg={8}>
                  <Grid
                    container
                    item
                    xs={12}
                    md={5}
                    justifyContent={'center'}
                    sx={{
                      '& span, & img': {
                        width: '100%',
                      },
                    }}
                  >
                    <IconTelework size={300} />
                  </Grid>
                  <Grid container item xs={12} md={7} justifyContent={'center'} alignItems="center">
                    <Typography
                      variant={'body1'}
                      lineHeight={2}
                      component={'div'}
                      sx={{
                        textAlign: 'center',
                        [theme.breakpoints.up('md')]: {
                          textAlign: 'left',
                        },
                      }}
                    >
                      {t('academic.courses.no_data.title')}
                      <Typography
                        variant={'h4'}
                        sx={{ color: theme.palette.primary.main }}
                        component={'p'}
                        lineHeight={2}
                      >
                        {t('academic.courses.no_data.subtitle')}
                      </Typography>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          )}
          <Grid item xs={12} mb={2}>
            <Grid
              container
              direction="column"
              gap={2}
              sx={{
                border: displayFilters ? 'none' : `1px solid ${theme.palette.primary.main}`,
                borderRadius: 2,
                padding: displayFilters ? 0 : 2,
              }}
            >
              <Grid item container spacing={2} alignItems="center">
                {/* <Grid item container justifyContent="flex-end" xs={12}>
                  <Grid item xs={12} md={3} hidden={!displayFilters}>
                    <Typography variant="h6" color="primary">
                      Ver todos
                      <Switch
                        onChange={(event) => {
                          handleFilterChange({
                            educationLevelId: event.target.checked ? -1 : 0,
                          });
                          setDisplayFilters(!event.target.checked);
                          setViewAll(event.target.checked);
                        }}
                      />
                    </Typography>
                  </Grid>
                </Grid> */}
                {/* alert de fecha fuera de rango */}
                {isOutRangeDate(periodEndProcess) && (
                  <Grid item xs={12}>
                    <Alert severity="warning">{t('academic.courses.period_outrange')}</Alert>
                  </Grid>
                )}

                {hasAccess('readCampus') && (
                  <Grid item xs={displayFilters && 12} md={displayFilters && 3}>
                    {displayFilters ? (
                      <>
                        <InputLabel>{t('academic.courses.campus')}:</InputLabel>
                        {!isFetchingCA && (
                          <Autocomplete
                            options={campusAvailables}
                            noOptionsText={t('academic.courses.campus.no_data')}
                            loading={isLoadingCA}
                            loadingText={t('academic.courses.campus.loading')}
                            getOptionLabel={(option) => option.cam_name}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder={t('academic.courses.campus.placeholder')}
                              />
                            )}
                            value={campusAvailables.find((dt) => dt.id === campusId) || null}
                            onChange={(_, value) => {
                              // setFiltersSearch(false);
                              handleFilterChange({ campusId: value?.id || 0 });
                            }}
                            fullWidth
                            clearIcon={null}
                          />
                        )}
                        {isFetchingCA && <TextFieldFetching />}
                      </>
                    ) : (
                      <InputLabel sx={{ m: '0px!important' }}>
                        {t('academic.courses.campus')}:{' '}
                        <Typography variant={'h5'} component={'span'}>
                          {campusAvailables.find((dt) => dt.id === campusId)?.cam_name}
                        </Typography>
                      </InputLabel>
                    )}
                  </Grid>
                )}
                {hasAccess('readOffers') && (
                  <Grid item xs={displayFilters && 12} md={displayFilters && 3}>
                    <>
                      {displayFilters ? (
                        <>
                          <InputLabel>{t('academic.courses.edu_level')}:</InputLabel>
                          {!isFetchingPID && (
                            <Autocomplete
                              // key={autocompleteKey.offer}
                              options={offersAvailables}
                              noOptionsText={t('academic.courses.edu_level.no_data')}
                              loading={isLoadingPID}
                              loadingText={t('academic.courses.edu_level.loading')}
                              disabled={!campusId || viewAll}
                              getOptionLabel={(option) => option.off_name}
                              value={
                                offersAvailables.find(
                                  (dt) => dt.off_description === offerDescription
                                ) || null
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder={t('academic.courses.edu_level.placeholder')}
                                />
                              )}
                              onChange={(_, value) => {
                                // setFiltersSearch(false);
                                handleFilterChange({
                                  offerId: value?.id || 0,
                                  offerDescription: value?.off_description || '',
                                  educationLevelId: 0,
                                  periodId: 0,
                                  periodEndProcess: '',
                                });
                              }}
                              fullWidth
                              clearIcon={null}
                            />
                          )}
                          {isFetchingPID && <TextFieldFetching />}
                        </>
                      ) : (
                        <>
                          <InputLabel sx={{ m: '0px!important' }}>
                            {t('academic.courses.edu_level')}:{' '}
                            <Typography variant={'h5'} component={'span'}>
                              {viewAll
                                ? t('academic.courses.filters.all')
                                : offersAvailables.find(
                                    (dt) => dt.off_description === offerDescription
                                  )?.off_name}
                            </Typography>
                          </InputLabel>
                        </>
                      )}
                    </>
                  </Grid>
                )}
                {hasAccess('readOffersById') && (
                  <Grid item xs={displayFilters && 12} md={displayFilters && 3}>
                    {displayFilters ? (
                      <>
                        <InputLabel>{t('academic.courses.period')}:</InputLabel>
                        {!isFetchingCID && (
                          <Autocomplete
                            // key={autocompleteKey?.period}
                            options={periods}
                            noOptionsText={t('academic.courses.period.no_data')}
                            loading={isLoadingCID}
                            loadingText={t('academic.courses.period.loading')}
                            disabled={!offerDescription || viewAll}
                            getOptionLabel={(option) => option.per_name}
                            value={periods.find((dt) => dt.id === periodId) || null}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder={t('academic.courses.period.placeholder')}
                              />
                            )}
                            onChange={(_, value) => {
                              // setFiltersSearch(false);
                              handleFilterChange({
                                periodId: value?.id || 0,
                                periodEndProcess: (value?.end_process as any) || '',
                              });
                              if (value?.education_level_id) {
                                handleFilterChange({
                                  educationLevelId: Number(value?.education_level_id) || 0,
                                });
                              }
                            }}
                            fullWidth
                            clearIcon={null}
                          />
                        )}
                        {isFetchingCID && <TextFieldFetching />}
                      </>
                    ) : (
                      <InputLabel sx={{ m: '0px!important' }}>
                        {t('academic.courses.period')}:{' '}
                        <Typography variant={'h5'} component={'span'}>
                          {viewAll
                            ? t('academic.courses.filters.all')
                            : periods.find((period) => period.id === periodId)?.per_name}
                        </Typography>
                      </InputLabel>
                    )}
                  </Grid>
                )}
                {hasAccess('readOfferByPeriod') && (
                  <Grid item xs={displayFilters && 12} md={displayFilters && 3}>
                    {displayFilters ? (
                      <>
                        <Grid item xs={8}>
                          <InputLabel>{t('academic.courses.academic_unit')}:</InputLabel>
                        </Grid>

                        {!isFetchingOID && (
                          <Autocomplete
                            // key={autocompleteKey.educationLvl}
                            options={offerById ? offerById!.education_levels : []}
                            noOptionsText={t('academic.courses.academic_unit.no_data')}
                            loading={isLoadingOID}
                            disabled={!periodId || viewAll}
                            loadingText={t('academic.courses.academic_unit.loading')}
                            getOptionLabel={(option) => option.edu_name}
                            value={
                              offerById?.education_levels.find(
                                (dt) => dt.id === educationLevelId
                              ) || null
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder={t('academic.courses.academic_unit.placeholder')}
                              />
                            )}
                            onChange={(_, value) => {
                              // setFiltersSearch(false);
                              // setViewAll(false);

                              handleFilterChange({
                                educationLevelId: value?.id || 0,
                              });
                            }}
                            fullWidth
                            clearIcon={null}
                          />
                        )}
                        {isFetchingOID && <TextFieldFetching />}
                      </>
                    ) : (
                      <InputLabel sx={{ m: '0px!important' }}>
                        {t('academic.courses.academic_unit')}:{' '}
                        <Typography variant={'h5'} component={'span'}>
                          {viewAll
                            ? t('academic.courses.filters.all')
                            : offerById?.education_levels.find(
                                (offer) => offer.id === educationLevelId
                              )?.edu_name}
                        </Typography>
                      </InputLabel>
                    )}
                  </Grid>
                )}
                {!displayFilters && (
                  <Grid item>
                    <Button variant="text" onClick={() => setDisplayFilters(true)}>
                      {t('academic.courses.edit_distribution')}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
          {displayFilters && (
            <Grid
              item
              container
              alignItems="center"
              justifyContent="flex-end"
              spacing={2}
              sx={{ mt: 1 }}
            >
              <Grid item>
                <Button
                  size="large"
                  onClick={() => {
                    setDisplayFilters(false);
                  }}
                  // disabled={!searchBtn && !viewAll}
                >
                  {t('academic.courses.hide_filters')}
                </Button>
                {hasAccess('readCampus') &&
                  hasAccess('readOffers') &&
                  hasAccess('readOfferByPeriod') &&
                  hasAccess('readOffersById') && (
                    <Button
                      variant="contained"
                      size="large"
                      color="primary"
                      onClick={handleSearch}
                      disabled={
                        !campusId ||
                        !periodId ||
                        !offerDescription ||
                        isLoadingCourses ||
                        isFetchingCourses
                      }
                      endIcon={
                        (isLoadingCourses || isFetchingCourses) && <CircularProgress size={24} />
                      }
                    >
                      {t('academic.courses.search')}
                    </Button>
                  )}
              </Grid>
            </Grid>
          )}
          <Grid item xs={12} mt={1}>
            {alertMessage.open && (
              <Alert severity={alertMessage.variant}>{alertMessage.message}</Alert>
            )}
          </Grid>
          {coursesByFilterResponse && (
            <Grid item xs={12}>
              <CoursesTable />
            </Grid>
          )}
        </>
      )}
    </>
  );
};

export default FilterCourses;
