import { useRouteMatch, useHistory } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import {
  useGetEntryTypesAllQuery,
  useGetStudentsQuery,
  useGetStudentsTypesQuery,
  useLazyGetPdfByStudentIdQuery,
} from '../slices/studentsApiSlice';

import StatusChip from '../../../shared/components/badges/StatusChip';
import AddButton from '../../../shared/components/tables/buttons/AddButton';

import {
  openAddAvatarWith,
  openDeleteModalWith,
  setIsPaid,
  setOfferId,
  setStudentTableAdvancedFilters,
  updateStudentsTable,
} from '../slices/studentsSlice';
import SearchPaginatedTable from '../../../shared/components/tables/SearchPaginatedTable';
import {
  Switch,
  Grid,
  Autocomplete,
  TextField,
  FormControlLabel,
  MenuList,
  MenuItem,
  Stack,
  Typography,
  FormGroup,
} from '@material-ui/core';
import { useTableSearch } from '../../../../hooks/useTableSearch';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { DateRange } from '@material-ui/lab/DateRangePicker';
import { useControlModalStates } from '../../../shared/hooks/useControlModalStates';
import CertificateDialog from './CertificateDialog';
import { useGetAcademicOfferingsAvailablesQuery } from '../../periods/slices/periodsApiSlice';
import { MoreVert } from '@material-ui/icons';
import CustomPopper from '../../../shared/components/popper/CustomPopper';
// import StudentRecordSubTable from './StudentRecordSubTable';
import SetCounselorDialog from './SetCounselorDialog';
import { useEffect, useState } from 'react';
import {
  useGetAvailableInstitutesQuery,
  useGetAvailablesTypeInstituteQuery,
} from '../../institutes/slices/institutesApiSlice';
import TextFieldFetching from '../../../shared/components/TextFieldFetching';
import { useGetEducationLevelsParentsAndChildrenQuery } from '../../education-levels/slices/educationLevelsApiSlice';
import { statusAvailables } from '../../../shared/constants/resourceStatus';
import useSharedGuard from '../../../../hooks/useSharedGuard';
import { subPermissionKeys } from '../constants/permissions';
import LocalizedDateRangePicker from '../../../shared/components/controlled/LocalizedDateRangePicker';
import { ModalDocuments } from '../../../evaluations/modules/maintenance/form/components/ModalDocuments';
// import StudentRecordSubTable from './StudentRecordSubTable';

const StudentTable = () => {
  const { t } = useTranslation();

  //permissions

  const { hasAccess, hasAtLeastOne } = useSharedGuard(subPermissionKeys);

  const history = useHistory();

  const [showFilters, setShowFilters] = useState(false);

  const [openReceipt, setOpenReceipt] = useState(false);

  const [pdfUrl, setPdfUrl] = useState('');

  const [valuesDates, setValuesDates] = useState<DateRange<Date>>([new Date(), new Date()]);

  const [studentIdByPDF, setStudentIdByPDF] = useState('');

  const { currentProfile } = useAppSelector((state) => state.access);

  const dispatch = useAppDispatch();

  const { path } = useRouteMatch();

  const { offerId, isPaid } = useAppSelector((state) => state.students);
  const { instituteId, typeInstituteId, educationLevelId, typeStudentId, entryTypeId, statusId } =
    useAppSelector((state) => state.students.studentTableAdvancedFilters);

  const { page, perPage, search, filterArrow } = useAppSelector(
    (state) => state.students.studentsTable
  );
  const { setPage, setPerPage, setSearch, setFilterArrow } = useTableSearch({
    page,
    perPage,
    search,
    filterArrow,
    updateFunction: updateStudentsTable,
    otherFilters: {
      offerId,
      isPaid,
      instituteId,
      typeInstituteId,
      educationLevelId,
      typeStudentId,
      entryTypeId,
      statusId,
    },
  });

  const { isLoading, error, data, isFetching } = useGetStudentsQuery(
    {
      profileId: currentProfile?.profileId!,
      page,
      perPage,
      search,
      offerId,
      isPaid,
      ...(statusId && { statusId }),
      ...(filterArrow && { sort: filterArrow.name, type_sort: filterArrow.direction }),
      ...(showFilters && {
        ...(typeInstituteId && { type_institute: typeInstituteId }),
        ...(instituteId && { instituteId }),
        ...(typeStudentId && educationLevelId && { typeStudentId }),
        ...(entryTypeId && { entryTypeId }),
        ...(educationLevelId && typeStudentId && { educationLevelId }),
        ...(valuesDates[0] &&
          educationLevelId &&
          typeStudentId && { date_from: format(valuesDates[0], 'yyyy-MM-dd') }),
        ...(valuesDates[1] &&
          educationLevelId &&
          typeStudentId && { date_to: format(valuesDates[1], 'yyyy-MM-dd') }),
      }),
    },
    { refetchOnMountOrArgChange: 30 }
  );

  const [getPdf, { data: pdfArrayBuffer }] = useLazyGetPdfByStudentIdQuery();

  const { data: educationLevels, isFetching: isFetchingELA } =
    useGetEducationLevelsParentsAndChildrenQuery({ filter: true });
  const { data: typeStudents, isFetching: isFetchingTS } = useGetStudentsTypesQuery({});
  const { data: typeEntries, isFetching: isFetchingET } = useGetEntryTypesAllQuery({});

  const { data: typeInstitutes, isLoading: isLoadingTI } = useGetAvailablesTypeInstituteQuery({
    profileId: currentProfile?.profileId!,
  });

  const { data: institutes, isLoading: isLoadingI } = useGetAvailableInstitutesQuery(
    {
      profileId: currentProfile?.profileId!,
      typeInstituteId: typeInstituteId!,
    },
    {
      skip: !typeInstituteId,
    }
  );

  const { open, multipleIds, handleCloseModal, handleOpenModalWithMultipleIds } =
    useControlModalStates();

  console.log('pdf', pdfArrayBuffer);

  const {
    open: openCounselor,
    multipleIds: multipleIdsCou,
    handleCloseModal: handleCloseModalCou,
    handleOpenModalWithMultipleIds: handleOpenModalWithMultipleIdsCou,
  } = useControlModalStates();

  const handleDelete = async (studentId: number) => {
    dispatch(openDeleteModalWith(studentId));
  };

  const handleAddAvatar = async function (studentId: number, studentImg: string) {
    dispatch(
      openAddAvatarWith({ studentIdToAddAvatar: studentId, studentImgToAddAvatar: studentImg })
    );
  };

  const handleCloseFichaEstudiante = () => {
    setOpenReceipt(false);
    setPdfUrl('');
    setStudentIdByPDF('');
  };

  const goToAdd = () => {
    history.push(`${path}/agregar`);
  };

  const onChangeDates = (newDatesValues: DateRange<Date>) => {
    setValuesDates(newDatesValues);
  };

  const datafetch = data?.data!.map((d, index) => ({
    // collapse: index,
    id: {
      id: d.id,
      stud_code: d.stud_code,
      stud_photo: d.stud_photo,
      idx: index + ((page - 1) * perPage + 1),
    },
    studCod: d.stud_code,
    name: d.pers_first_lastname + ' ' + d.pers_second_lastname + ' ' + d.pers_firstname,
    identification: d.pers_identification,
    mail: d.email,
    statusId: d.status_id,
    options: d,
  }));

  const { data: academicOfferingsAvailables, isLoading: isLoadingAcademicOfferings } =
    useGetAcademicOfferingsAvailablesQuery({
      profileId: currentProfile?.profileId!,
    });

  useEffect(() => {
    if (studentIdByPDF) {
      const executeGetPdf = async () => {
        await getPdf({
          studentId: studentIdByPDF,
        });
      };
      executeGetPdf();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studentIdByPDF]);

  useEffect(() => {
    if (pdfArrayBuffer) {
      var file = new Blob([pdfArrayBuffer], { type: 'application/pdf' });
      var fileURL = URL.createObjectURL(file);
      setPdfUrl(fileURL);
    }
  }, [pdfArrayBuffer]);

  return (
    <>
      <SearchPaginatedTable
        data={datafetch!}
        headers={{
          // collapse: '',
          id: 'N°',
          studCod: t('academic.students_list.code'),
          name: t('academic.students_list.name'),
          identification: t('academic.students_list.identification'),
          mail: t('academic.students_list.email'),
          statusId: t('academic.students_list.status'),
          options: t('academic.students_list.options'),
        }}
        keyExtractor={(item) => item.id.id.toString()}
        searchPlacehoder={t('academic.students_list.search')}
        listFilterArrows={{
          name: 'p.pers_full_name',

          mail: 'u.email',
          statusId: 's.st_name',
        }}
        filterArrow={filterArrow}
        setFilterArrow={setFilterArrow}
        isLoading={isLoading}
        isFetching={isFetching}
        error={error}
        perPage={perPage}
        setPerPage={setPerPage}
        total={data?.total || 0}
        page={page}
        setPage={setPage}
        numHeader={8}
        search={search}
        setSearch={setSearch}
        customHeadersCellsProperties={{
          options: {
            align: 'center',
          },
        }}
        customDataCellsProperties={{
          id: { align: 'center' },
          // name: { align: 'center' },
          mail: { align: 'center' },
          identification: { align: 'center' },
          statusId: { align: 'center' },
          options: {
            align: 'center',
          },
        }}
        customRenderers={{
          id: (row) => row.id.idx,
          mail: ({ mail, options }) => (
            <Stack spacing={1}>
              <Grid item>
                <Typography align="center">{mail}</Typography>
              </Grid>
              <Grid item>
                <Typography align="center">{options.pers_cell}</Typography>
              </Grid>
            </Stack>
          ),
          statusId: (row) => <StatusChip statusId={Number(row.statusId)} />,
          options: (row) => (
            <>
              {(hasAtLeastOne(['show', 'getStudentRecords', 'update', 'getPeriods', 'delete']) ||
                hasAccess([
                  'getStudentRecords',
                  'studentPeriods',
                  'getPriceAndUrl',
                  'getPeriods',
                  'getTypeCertificates',
                ])) && (
                <CustomPopper iconButton={<MoreVert />} titleButton="Ver opciones">
                  <MenuList id="split-button-menu" autoFocusItem>
                    {/* <MenuItem
                    key={'assign_notes'}
                    onClick={() => history.push(`/asignar-notas/${row.id.id}`)}
                  >
                    {t('academic.students_list.student.assign_grades')}
                  </MenuItem> */}

                    {hasAccess('show') && (
                      <MenuItem
                        key={'academic_history'}
                        onClick={() =>
                          history.push(`/administrar-estudiantes/${row.id.id}/historial-academico`)
                        }
                      >
                        {t('academic.students_list.student.academic_history.title')}
                      </MenuItem>
                    )}
                    <MenuItem
                      key={'student_file'}
                      onClick={() => {
                        setStudentIdByPDF(row.id.id.toString());
                        setOpenReceipt(true);
                      }}
                    >
                      {t('Ficha estudiantil')}
                    </MenuItem>

                    {hasAccess('getPeriods') && (
                      <MenuItem
                        key={'register'}
                        onClick={() => history.push(`/registro-asignaturas/${row.id.id}/registro`)}
                      >
                        {t('academic.students_list.student.register')}
                      </MenuItem>
                    )}

                    {hasAccess('update') && (
                      <MenuItem
                        key={'counselor'}
                        onClick={() =>
                          handleOpenModalWithMultipleIdsCou({
                            userId: Number(row.options.user_id),
                            studentId: row.options.id,
                            data: row.options.student_records,
                          })
                        }
                      >
                        {t('academic.students_list.student.counselor')}
                      </MenuItem>
                    )}

                    {hasAccess([
                      'getStudentRecords',
                      'studentPeriods',
                      'getPriceAndUrl',
                      'getPeriods',
                      'getTypeCertificates',
                    ]) && (
                      <MenuItem
                        key={'certificate'}
                        onClick={() =>
                          handleOpenModalWithMultipleIds({
                            userId: Number(row.options.user_id),
                            studentId: row.options.id,
                          })
                        }
                      >
                        {t('academic.students_list.student.certificate')}
                      </MenuItem>
                    )}

                    {hasAccess('update') && (
                      <MenuItem
                        key={'edit'}
                        onClick={() => history.push(`${path}/${Number(row.id.id)}/editar`)}
                      >
                        {t('shared.edit_button.tooltip')}
                      </MenuItem>
                    )}

                    {hasAccess('delete') && (
                      <MenuItem key={'delete'} onClick={() => handleDelete(Number(row.id.id))}>
                        {t('shared.delete_button.tooltip')}
                      </MenuItem>
                    )}

                    {hasAccess('update') && (
                      <MenuItem
                        key={'change_photo'}
                        onClick={() => handleAddAvatar(Number(row.id.id), row.id.stud_photo || '')}
                      >
                        {t('shared.camera_button.tooltip')}
                      </MenuItem>
                    )}
                  </MenuList>
                </CustomPopper>
              )}
            </>
          ),
        }}
        ActionButtons={<>{hasAccess('store') && <AddButton size="large" onClick={goToAdd} />}</>}
        // collapsible={{
        //   collapsibleBtnColl: 0,
        //   Rows: (index, { options }, props) => (
        //     <StudentRecordSubTable data={options} numHeader={props.numHeader} />
        //   ),
        // }}
        additionalFilters={
          <Grid container spacing={2} mb={2} alignItems="center">
            {hasAccess('readOffers') && (
              <Grid item xs={12} md={4}>
                {isLoadingAcademicOfferings ? (
                  <TextField disabled placeholder="Cargando..." fullWidth />
                ) : (
                  <Autocomplete
                    options={academicOfferingsAvailables || []}
                    getOptionLabel={(option) => option.off_name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Oferta académica"
                        placeholder="Seleccione una oferta académica"
                        variant="outlined"
                        fullWidth
                      />
                    )}
                    multiple={false}
                    value={academicOfferingsAvailables?.find((o) => o.id === offerId) || null}
                    onChange={(e, value) => {
                      dispatch(setOfferId(value?.id || 0));
                    }}
                  />
                )}
              </Grid>
            )}

            <Grid item xs={12} md={4}>
              <Autocomplete
                options={statusAvailables}
                getOptionLabel={(option) => option.name}
                value={statusAvailables.find((option) => option.id === statusId)}
                onChange={(e, value) => {
                  dispatch(
                    setStudentTableAdvancedFilters({
                      statusId: value?.id || null,
                    })
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('utils.status')}
                    placeholder={t('utils.status.placeholder')}
                    variant="outlined"
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={2}>
              <FormControlLabel
                control={
                  <Switch
                    onChange={(e) => {
                      dispatch(setIsPaid(e.target.checked));
                    }}
                  />
                }
                label="Sin deudas"
                value={isPaid}
              />
            </Grid>

            {hasAtLeastOne([
              'readInstituteTypes',
              'readInstitutes',
              'onlyParentsEduLvls',
              'readTypeStudents',
              'listEntryTypes',
            ]) && (
              <Grid item xs={12} md={4} lg={2}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        value={showFilters}
                        onChange={(e) => setShowFilters(e.target.checked)}
                      />
                    }
                    label="Búsqueda avanzada"
                  />
                </FormGroup>
              </Grid>
            )}

            {showFilters && (
              <Grid item container xs={12} spacing={2}>
                {hasAccess('readInstituteTypes') && (
                  <>
                    <Grid item xs={12} md={4}>
                      {isLoadingTI ? (
                        <TextFieldFetching />
                      ) : (
                        <Autocomplete
                          options={typeInstitutes || []}
                          getOptionLabel={(option) => option.tin_name}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Tipo de institución"
                              placeholder="Seleccione un tipo de institución"
                              variant="outlined"
                              fullWidth
                            />
                          )}
                          multiple={false}
                          value={typeInstitutes?.find((o) => o.id === typeInstituteId) || null}
                          onChange={(e, value) => {
                            dispatch(
                              setStudentTableAdvancedFilters({
                                typeInstituteId: value?.id || null,
                                instituteId: null,
                              })
                            );
                          }}
                        />
                      )}
                    </Grid>
                  </>
                )}

                {hasAccess(['readInstituteTypes', 'readInstitutes']) && (
                  <Grid item xs={12} md={4}>
                    {!typeInstituteId && (
                      <TextField
                        placeholder="Selecciona un tipo de institución"
                        variant="outlined"
                        fullWidth
                        disabled
                      />
                    )}
                    {typeInstituteId && isLoadingI && <TextFieldFetching />}
                    {typeInstituteId && !isLoadingI && (
                      <Autocomplete
                        options={institutes || []}
                        getOptionLabel={(option) => option.inst_name}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Institución"
                            placeholder="Selecciona una institución"
                            variant="outlined"
                            fullWidth
                          />
                        )}
                        multiple={false}
                        value={institutes?.find((o) => o.id === instituteId) || null}
                        onChange={(e, value) => {
                          dispatch(
                            setStudentTableAdvancedFilters({
                              instituteId: value?.id || null,
                            })
                          );
                        }}
                      />
                    )}
                  </Grid>
                )}

                <Grid item xs={12} md={4} sx={{ display: { xs: 'none', md: 'block' } }}></Grid>

                {hasAccess('onlyParentsEduLvls') && (
                  <Grid item xs={12} md={4}>
                    {isFetchingELA ? (
                      <TextFieldFetching />
                    ) : (
                      <Autocomplete
                        options={educationLevels || []}
                        getOptionLabel={(option) => option.edu_name}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Facultad"
                            placeholder="Seleccione una facultad"
                            variant="outlined"
                            fullWidth
                          />
                        )}
                        value={educationLevels?.find((o) => o.id === educationLevelId) || null}
                        onChange={(e, value) => {
                          dispatch(
                            setStudentTableAdvancedFilters({
                              educationLevelId: value?.id || null,
                              typeStudentId: null,
                            })
                          );
                        }}
                      />
                    )}
                  </Grid>
                )}

                {hasAccess(['onlyParentsEduLvls', 'readTypeStudents']) && (
                  <Grid item xs={12} md={4}>
                    {!educationLevelId && (
                      <TextField
                        placeholder="Selecciona un tipo de estudiante"
                        variant="outlined"
                        fullWidth
                        disabled
                      />
                    )}
                    {educationLevelId && isFetchingTS && <TextFieldFetching />}
                    {educationLevelId && !isFetchingTS && (
                      <Autocomplete
                        options={typeStudents || []}
                        getOptionLabel={(option) => option.te_name}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Tipo de estudiante"
                            placeholder="Selecciona un tipo de estudiante"
                            variant="outlined"
                            fullWidth
                          />
                        )}
                        multiple={false}
                        value={typeStudents?.find((o) => o.id === typeStudentId) || null}
                        onChange={(e, value) => {
                          dispatch(
                            setStudentTableAdvancedFilters({
                              typeStudentId: value?.id || null,
                            })
                          );
                        }}
                      />
                    )}
                  </Grid>
                )}
                <Grid item xs={12} md={4} sx={{ display: { xs: 'none', md: 'block' } }}></Grid>

                {hasAccess('listEntryTypes') && (
                  <Grid item xs={12} md={4}>
                    {isFetchingET ? (
                      <TextFieldFetching />
                    ) : (
                      <Autocomplete
                        options={typeEntries || []}
                        getOptionLabel={(option) => option.ent_ty_name}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Tipo de ingreso"
                            placeholder="Seleccione un tipo de ingreso"
                            variant="outlined"
                            fullWidth
                          />
                        )}
                        value={typeEntries?.find((o) => o.id === entryTypeId) || null}
                        onChange={(e, value) => {
                          dispatch(
                            setStudentTableAdvancedFilters({
                              entryTypeId: value?.id || null,
                            })
                          );
                        }}
                      />
                    )}
                  </Grid>
                )}
                <Grid item xs={12} md={8}>
                  {educationLevelId && typeStudentId && (
                    <LocalizedDateRangePicker
                      value={valuesDates}
                      onChange={onChangeDates}
                      startText="Fecha inicial"
                      endText="Fecha fin"
                    />
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
        }
      />

      {open && (
        <CertificateDialog
          open={open}
          onClose={handleCloseModal}
          title="test"
          studentId={multipleIds?.studentId.toString() || null}
          userId={multipleIds?.userId || null}
        />
      )}

      {openCounselor && (
        <SetCounselorDialog
          open={openCounselor}
          onClose={handleCloseModalCou}
          title="test"
          studentId={multipleIdsCou?.studentId.toString() || null}
          userId={multipleIdsCou?.userId || null}
          data={multipleIdsCou?.data}
        />
      )}

      {openReceipt && (
        <ModalDocuments
          open={openReceipt}
          handleClose={handleCloseFichaEstudiante}
          title={'Ficha estudiantil'}
          urlFile={pdfUrl}
        />
      )}
    </>
  );
};

export default StudentTable;
