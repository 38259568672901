import { FC, useEffect, useState } from 'react';

// material-ui
import {
  Button,
  Step,
  Stepper,
  StepLabel,
  Stack,
  Typography,
  Grid,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';

// project imports
// import AddressForm from './AddressForm';
// import PaymentForm from './PaymentForm';
// import Review from './Review';
// import MainCard from './../../../../ui-component/cards/MainCard';
// import SecondaryAction from './../../../../ui-component/cards/CardSecondaryAction';
import AnimateButton from '../../../../shared/components/extended/AnimateButton';
import CartFees from './CartFees';
import PayMethods from './PayMethods';
import { methodTypes } from '../../interfaces/types.interface';
import DatafastPayment from './DatafastPayment';
import { useAppSelector } from '../../../../../hooks/useRedux';
import DepositPayment from './DepositPayment';
import TransferPayment from './TransferPayment';
import { checkoutSteps as steps } from '../../constants';
import TempBillingDataHoc from './TempBillingDataHoc';

function getStepContent(
  step: number,
  // pay method
  selectedMethod: methodTypes,
  // fees to pay
  selectedFees: number[],
  // total fees amount
  totalAmount: number | undefined,

  // select method func
  onSelectMethod: (method: methodTypes) => void,
  // selec fees func
  handleSelecteFees: (fees: number[]) => void,
  // valid continue de
  handleValidStep: (param: boolean) => void,
  //go step back
  handleBack: () => void,
  //go next step
  handleNext: () => void,
  // set index error
  setErrorIndex: (i: number | null) => void,
  // set total amount
  handleTotalAmount: (amount: number) => void
) {
  switch (step) {
    case 0:
      return (
        <CartFees
          selectedFees={selectedFees}
          totalAmount={totalAmount}
          handleSelectFees={handleSelecteFees}
          setValidStep={handleValidStep}
          handleTotalAmount={handleTotalAmount}
        />
      );
    case 1:
      return (
        <PayMethods
          totalAmount={totalAmount}
          selectedMethod={selectedMethod}
          onSelectMethod={onSelectMethod}
          setValidStep={handleValidStep}
        />
      );
    case 2:
      return <TempBillingDataHoc handleValidStep={handleValidStep} />;
    case 3:
      if (selectedMethod === 'credito')
        return <DatafastPayment totalAmount={totalAmount} handleBack={handleBack} />;
      if (selectedMethod === 'deposito')
        return (
          <DepositPayment
            handleBack={handleBack}
            handleNext={handleNext}
            setErrorIndex={setErrorIndex}
          />
        );
      if (selectedMethod === 'transferencia')
        return (
          <TransferPayment
            handleBack={handleBack}
            handleNext={handleNext}
            setErrorIndex={setErrorIndex}
          />
        );
      // return <Test />;
      break;
    default:
      throw new Error('Unknown step');
  }
}

//-----------------------|| FORMS WIZARD - BASIC ||-----------------------//
interface Props {
  activeStep: number;
  errorIndex: number | null;
  setErrorIndex: (i: number | null) => void;
  handleNext: () => void;
  handleBack: () => void;
  setActiveStep: (step: number) => void;
}

const CheckoutWizard: FC<Props> = (props) => {
  const { activeStep, errorIndex, setErrorIndex, handleNext, handleBack, setActiveStep } = props;

  const history = useHistory();
  const location = useLocation();

  const { selectedDebtsId, selectedDebts } = useAppSelector((state) => state.payments);
  const [validStep, setValidStep] = useState<boolean>();

  const [totalAmount, setTotalAmount] = useState<number>();

  const [selectedMethod, setSelectedMethod] = useState<methodTypes>();
  const [selectedFees, setSelectedFees] = useState<number[]>([...selectedDebtsId]); //selectedDebtsId cuando se usaba el cart de seleccionados previamente (step 1)

  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));

  const handleSelectMethod = (method: methodTypes) => {
    setSelectedMethod(method);
  };

  const handleSelecteFees = (fees: number[]) => {
    setSelectedFees([...fees]);
  };

  const handleValidStep = (param: boolean) => {
    setValidStep(param);
  };

  const handleTotalAmount = (amount: number) => {
    setTotalAmount(amount);
  };

  useEffect(() => {
    if (!selectedDebts.length) history.push(`/${location.pathname.split('/')[1]}`);
  }, [history, location.pathname, selectedDebts.length]);

  return (
    <Grid container>
      <Grid container justifyContent={'center'}>
        <Grid item xs={12} md={activeStep !== 1 ? 10 : 12} lg={activeStep !== 1 ? 7 : 12}>
          <Stepper
            activeStep={activeStep}
            sx={{ pt: 3, pb: 5 }}
            orientation={matchDownSM ? 'vertical' : 'horizontal'}
          >
            {steps.map((label, index) => {
              const labelProps: { error?: boolean; optional?: React.ReactNode } = {};

              if (index === errorIndex) {
                labelProps.optional = (
                  <Typography variant="caption" color="error">
                    Error
                  </Typography>
                );

                labelProps.error = true;
              }

              return (
                <Step key={label}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <>
          {activeStep === steps.length ? (
            <>
              <Typography variant="h5" gutterBottom>
                Thank you for your order.
              </Typography>
              <Typography variant="subtitle1">
                Your order number is #2001539. We have emailed your order confirmation, and will
                send you an update when your order has shipped.
              </Typography>
              <Stack direction="row" justifyContent="flex-end">
                <AnimateButton>
                  {/**
                   * wrong prop color="error"
                   * color?: "inherit" | "primary" | "secondary" | undefined
                   * placeholder style={{color: 'red', borderColor: '#EDE7F6'}}
                   */}
                  <Button
                    variant="contained"
                    style={{ color: 'red', borderColor: '#EDE7F6' }}
                    color="primary"
                    onClick={() => setActiveStep(0)}
                    sx={{ my: 3, ml: 1 }}
                  >
                    Reset
                  </Button>
                </AnimateButton>
              </Stack>
            </>
          ) : (
            <>
              {getStepContent(
                activeStep,
                selectedMethod,
                selectedFees,
                totalAmount,
                handleSelectMethod,
                handleSelecteFees,
                handleValidStep,
                handleBack,
                handleNext,
                setErrorIndex,
                handleTotalAmount
              )}
              {activeStep < steps.length - 1 && (
                <Stack
                  direction="row"
                  // justifyContent={activeStep !== 0 ? 'space-between' : 'flex-end'}
                  justifyContent={'center'}
                >
                  {activeStep !== 0 && (
                    <Button
                      onClick={() => {
                        if (activeStep > 1) handleBack();
                        else history.push(`/${location.pathname.split('/')[1]}`);
                      }}
                      sx={{ my: 3, ml: 1 }}
                    >
                      Regresar
                    </Button>
                  )}
                  <AnimateButton>
                    <Button
                      variant="contained"
                      onClick={handleNext}
                      sx={{ my: 3, ml: 1 }}
                      disabled={!validStep}
                    >
                      {activeStep === steps.length - 1 ? 'Pagar' : 'Siguiente'}
                    </Button>
                  </AnimateButton>
                </Stack>
              )}
            </>
          )}
        </>
      </Grid>

      {/* // */}
    </Grid>
  );
};

export default CheckoutWizard;
