import { Grid, TextField, FormHelperText, Autocomplete, Button } from '@material-ui/core';
// import { DatePicker, LocalizationProvider, DateRangePicker } from '@material-ui/lab';
// import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import { useFormik } from 'formik';
import { IconSearch } from '@tabler/icons';

import InputLabel from '../../../../shared/components/forms/InputLabel';
import { FilterPayloadsPrincipal } from './index';
import statusProject from '../../constants/lists/statusProject';
// import LocalizedDateRangePicker from '../../../../shared/components/controlled/LocalizedDateRangePicker';

interface Props {
  dispatchFilterData: React.Dispatch<React.SetStateAction<FilterPayloadsPrincipal | undefined>>;
}

const statusProjectList = statusProject;

const ProjectPrincipalFilter = ({ dispatchFilterData }: Props) => {
  const { setFieldValue, errors, touched, values } = useFormik({
    initialValues: {
      statusProject: '',
    },
    onSubmit: async (value) => {},
  });

  const sendFilterData = () => {
    dispatchFilterData({
      statusProject: values.statusProject,
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <InputLabel>Estado de proyecto</InputLabel>
        <Autocomplete
          options={statusProjectList!}
          getOptionLabel={(option) => option?.label!}
          renderInput={(params) => (
            <TextField
              placeholder="Ingrese un estado de proyecto"
              {...params}
              error={Boolean(errors.statusProject && touched.statusProject)}
            />
          )}
          value={statusProjectList?.find((data) => data.keyword === values.statusProject)}
          onChange={(e, value) => setFieldValue('statusProject', value?.keyword || '')}
          fullWidth
          clearIcon={null}
        />
        {errors.statusProject && touched.statusProject && (
          <FormHelperText error>(errors.statusProject)</FormHelperText>
        )}
      </Grid>
      <Grid item container xs={12} md={4} alignItems="flex-end">
        <Grid item>
          <Button
            onClick={sendFilterData}
            variant="outlined"
            size="large"
            startIcon={<IconSearch />}
          >
            Buscar
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProjectPrincipalFilter;
