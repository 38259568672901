import { Grid, Typography } from '@material-ui/core';
import { useAppSelector } from '../../../../hooks/useRedux';
import MainCard from '../../../shared/components/cards/MainCard';
import ThesisTable from '../components/ThesisTable';
import DeleteThesisDialog from '../components/DeleteThesisDialog';

const BooksScreen = () => {
  const { thesisToDelete } = useAppSelector((state) => state.thesis);
  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">Tesis</Typography>
      </Grid>

      <ThesisTable />

      {thesisToDelete && <DeleteThesisDialog />}
    </Grid>
  );
};

export default BooksScreen;
