import { Switch } from 'react-router-dom';

import { useAppSelector } from '../../../../hooks/useRedux';
import InvestigationLinesScreenAndRoute from '../screens/PublicationsScreen';
// import AddInvestigationLinesScreen from '../components/investigationProjectLines/forms/AddInvestigationLinesScreen';
import GuardRoute from '../../../../router/guards/GuardRoute';
import { permissionKey } from '../constants/permissions';
import AddPublicationStepper from '../components/forms/AddPublicationsStepper';

function PublicationRouter() {
  // Verify permission
  const { currentModule } = useAppSelector((state) => state.access);
  const hasPermission = permissionKey in currentModule?.permissions!;
  console.log(hasPermission);

  // if (!hasPermission) return <Redirect to="/" />;

  return (
    <Switch>
      <GuardRoute
        exact
        path={`/publicaciones`}
        component={InvestigationLinesScreenAndRoute}
        // permissionKey={permissionKey}
        // subPermissionKey={usersSubPermissionKeys.read}
      />
      <GuardRoute
        exact
        path={`/publicaciones/crear/:caseKey`}
        component={AddPublicationStepper}
        // permissionKey={permissionKey}
        // subPermissionKey={usersSubPermissionKeys.read}
      />
      <GuardRoute
        exact
        path={`/publicaciones/editar/:caseKey`}
        component={InvestigationLinesScreenAndRoute}
        // permissionKey={permissionKey}
        // subPermissionKey={usersSubPermissionKeys.read}
      />
    </Switch>
  );
}

export default PublicationRouter;
