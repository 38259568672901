import { Grid } from '@material-ui/core';
//import { useState } from 'react';
import SearchPaginatedTable from '../../../shared/components/tables/SearchPaginatedTable';
import {
  StudenstNivelationCoursesResponse,
  StudentRecord,
} from '../interfaces/studentNivelationCourses.interface';
import ChipStatus from './ChipStatus';

interface props {
  students: StudenstNivelationCoursesResponse | undefined;
  isLoading: boolean;
  isFetching: boolean;
  error?: any;
  page: number;
  perPage: number;
  setPage: any;
  setPerPage: any;
  setFilters: any;
  filters: any;
  search: string;
  setSearch: any;
  groupId?: number | undefined;
}

const DynamicTable = ({
  students,
  isLoading,
  isFetching,
  error,
  page,
  perPage,
  setPage,
  setPerPage,
  setFilters,
  filters,
  search,
  setSearch,
}: props) => {
  const courses_sort = students?.data.map((st) => st.courses).sort((a, b) => a.length - b.length);
  const lastIndex = courses_sort ? courses_sort.length - 1 : 0;
  //const courses = students?.data.map((st) => st.courses).sort((a, b) => a.length - b.length) || [];
  const courses =
    courses_sort && courses_sort.length > 0
      ? courses_sort[lastIndex].filter((e, i, a) => {
          if (e.course_id) {
            return !a.some((e2, i2) => e2.subject_id === e.subject_id && i2 > i);
          }
          return true;
        })
      : [];

  const headers =
    courses.length > 0
      ? {
          id: 'Nº',
          identification: 'Identificación',
          studCode: 'Cód. Est.',
          nombres: 'Nombres',
          ...Object.fromEntries(
            //courses[courses.length - 1].map((item, index) => [`materia${index}`, item.mat_name])
            courses.map((item, index) => [`materia${index}`, item.mat_name])
          ),
          estado: 'Estado',
        }
      : {
          id: 'Nº',
          identification: 'Identificación',
          studCode: 'Cód. Est.',
          nombres: 'Nombres',
          estado: 'Estado',
        };
  //Courses Headers
  const dinamicHeaders =
    courses.length > 0
      ? {
          ...Object.fromEntries(
            //courses[courses?.length - 1].map((item, index) => [`materia${index}`, item.subject_id])
            courses.map((item, index) => [`materia${index}`, item.subject_id])
          ),
        }
      : {};

  const generateCoursesRows = (student: StudentRecord) => {
    const studentCourses = student.courses;
    let obj: any = {};
    studentCourses.forEach((course) => {
      for (const [key, value] of Object.entries(dinamicHeaders)) {
        if (course.subject_id === value) {
          //eslint-disable-next-line
          obj[key] = course.status_matter;
        }
      }
    });
    return obj;
  };

  const rows =
    students?.data.map((st, index) => ({
      id: index + Number(students?.from || 0),
      identification: st?.pers_identification||'',
      studCode: st?.stud_code||'',
      nombres: `${st.pers_firstname} ${st.pers_secondname} ${st.pers_first_lastname} ${st.pers_second_lastname}`,
      ...generateCoursesRows(st),
      estado: `${st.courses.filter((cr) => cr.status_matter === 'Aprobado').length}/${
        Object.entries(dinamicHeaders).length
      }`,
    })) || [];

  return (
    <Grid item container>
      <SearchPaginatedTable
        data={rows}
        error={error}
        headers={headers}
        isLoading={isLoading}
        isFetching={isFetching}
        keyExtractor={(row) => String(row.id)}
        numHeader={9}
        page={page}
        perPage={perPage}
        search={search}
        searchPlacehoder="Buscar Estudiante"
        setPage={setPage}
        setPerPage={setPerPage}
        setSearch={setSearch}
        listFilterArrows={{
          identification: 'persons.pers_identification',
          studCode: 'persons.stud_code',
          nombres: 'persons.pers_full_name',
        }}
        filterArrow={filters}
        setFilterArrow={setFilters}
        total={students?.total || 5}
        customRenderers={{
          id: (row) => <>{row.id}</>,
          ...Object.fromEntries(
            Object.keys(dinamicHeaders).map((key) => [
              key,
              (row) => <ChipStatus status={row[key]} />,
            ])
          ),
        }}
        customDataCellsProperties={{
          estado: {
            align: 'center',
          },
          ...Object.fromEntries(
            Object.keys(dinamicHeaders).map((key) => [
              key,
              {
                align: 'center',
              },
            ])
          ),
        }}
        //perPageBox={false}
      />
    </Grid>
  );
};

export default DynamicTable;
