import { useHistory } from 'react-router-dom';
import { Grid, IconButton, Typography, useTheme } from '@material-ui/core';
import { IconArrowNarrowLeft } from '@tabler/icons';

import MainCardForm from '../../../../shared/components/cards/MainCardForm';
import SendAnnouncementForm from '../components/SendAnnouncementForm';

import { useTranslation } from 'react-i18next';

const SendAnnouncementScreen = () => {
  const theme = useTheme();
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <MainCardForm
      sx={{
        borderTopLeftRadius: '0px',
        borderTopRightRadius: '0px',
      }}
    >
      <Grid item xs={12}>
        <Grid item container alignItems="center" gap={1} sx={{ cursor: 'pointer' }}>
          <IconButton onClick={() => history.goBack()}>
            <IconArrowNarrowLeft size={30} color={theme.palette.text.dark} />
          </IconButton>
          <Typography variant="h2">
            {t('teacher_virtual_classroom.send_announcements.title')}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} md={8} lg={6} sx={{ mt: 2 }}>
        <SendAnnouncementForm />
      </Grid>
    </MainCardForm>
  );
};

export default SendAnnouncementScreen;
