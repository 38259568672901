export const Routes = [
  {
    id: 'autentication',
    action: 'Autenticación',
    children: [
      {
        id: 'autenticacion-login',
        action: 'Iniciar sesión',
        url: '/login',
      },
      {
        id: 'autenticacion-recuperar-contrasenia',
        action: 'Recuperar contraseña',
        url: '/recuperar-clave',
      },
    ],
  },
  {
    id: 'general',
    action: 'Preguntas frecuentes Generales',
    children: [
      {
        id: 'general-faqs',
        action: 'General',
        url: '/',
      },
    ],
  },
  {
    id: 'configuration',
    action: 'Configuración',
    children: [
      {
        id: 'configuracion-administrar-preguntas-frecuentes',
        action: 'Configuración Preguntas frecuentes',
        url: '/administrar-preguntas-frecuentes',
        permissionKey: 'permissions',
      },
      {
        id: 'configuracion-administrar-preguntas-frecuentes-agregar',
        action: 'Configuración Preguntas frecuentes agregar',
        url: '/administrar-preguntas-frecuentes/agregar',
      },
      {
        id: 'configuracion-administrar-preguntas-frecuentes-editar',
        action: 'Configuración Preguntas frecuentes editar',
        url: '/administrar-preguntas-frecuentes/editar/:id',
      },
      {
        id: 'configuracion-administrar-perfiles',
        action: 'Configuración Perfiles',
        url: '/administrar-perfiles',
      },
      {
        id: 'configuracion-administrar-perfiles-agregar',
        action: 'Configuración Perfiles agregar',
        url: '/administrar-perfiles/agregar',
      },
      {
        id: 'configuracion-administrar-perfiles-editar',
        action: 'Configuración Perfiles editar',
        url: '/administrar-perfiles/:id/editar',
      },
      {
        id: 'configuracion-administrar-roles',
        action: 'Configuración Roles',
        url: '/administrar-roles',
      },
      {
        id: 'configuracion-administrar-roles-agregar',
        action: 'Configuración Roles agregar',
        url: '/administrar-roles/agregar',
      },
      {
        id: 'configuracion-administrar-roles-editar',
        action: 'Configuración Roles editar',
        url: '/administrar-roles/:id/editar',
      },
      {
        id: 'configuracion-administrar-permisos',
        action: 'Configuración Permisos',
        url: '/administrar-permisos',
      },
      {
        id: 'configuracion-administrar-permisos',
        action: 'Configuración Permisos agregar',
        url: '/administrar-permisos/agregar',
      },
      {
        id: 'configuracion-administrar-permisos',
        action: 'Configuración Permisos editar',
        url: '/administrar-permisos/:id/editar',
      },
      {
        id: 'configuracion-administrar-usuarios',
        action: 'Configuración Usuarios',
        url: '/administrar-usuarios',
      },
      {
        id: 'configuracion-administrar-usuarios',
        action: 'Configuración Usuarios agregar',
        url: '/administrar-usuarios/agregar',
      },
      {
        id: 'configuracion-administrar-usuarios',
        action: 'Configuración Usuarios editar',
        url: '/administrar-usuarios/:id/editar',
      },
    ],
  },
  {
    id: 'financial',
    action: 'Financiero',
    children: [
      {
        id: 'financiero-administrar-caja-cobrar-deudas',
        action: 'Financiero Cobrar Deudas',
        url: '/administrar-caja/cobrar-deudas',
      },
      {
        id: 'financiero-administrar-caja-generar-deudas',
        action: 'Financiero Generar deudas',
        url: '/administrar-caja/generar-deudas',
      },
      {
        id: 'financiero-administrar-caja-nota-credito',
        action: 'Financiero Generar nota crédito',
        url: '/administrar-caja/generar-nota-credito',
      },
      {
        id: 'financiero-administrar-caja-cierre-cajas',
        action: 'Financiero Cierre caja',
        url: '/administrar-caja/cierre-cajas',
      },
      {
        id: 'financiero-administrar-caja-cierre-cajas-reporte',
        action: 'Financiero Administrar cierra caja reporte',
        url: '/administrar-caja/cierre-cajas/:id/reporte',
      },
      {
        id: 'financiero-administrar-clientes',
        action: 'Financiero Administrar clientes',
        url: '/administrar-clientes',
      },
      {
        id: 'financiero-administrar-clientes-asignar-descuento-asignacion',
        action: 'Financiero Administrar clientes asignar descuento',
        url: '/administrar-clientes/:id/asignar-descuento/asignacion',
      },
      {
        id: 'financiero-administrar-depositos-transferencias',
        action: 'Financiero Depósitos y Transferencias',
        url: '/administrar-depositos-transferencias',
      },
      {
        id: 'financiero-cheques',
        action: 'Financiero Cheques',
        url: '/administrar-cheques',
      },
      {
        id: 'financiero-administrar-documentos-electronicos',
        action: 'Financiero Documentos Electrónicos',
        url: '/administrar-documentos-electronicos',
      },
      {
        id: 'financiero-administrar-recibos',
        action: 'Financiero Recibos',
        url: '/administrar-recibos',
      },
      {
        id: 'financiero-administrar-mantenimiento-categoria',
        action: 'Financiero Categorías',
        url: '/administrar-mantenimiento-categoria',
      },
      {
        id: 'financiero-administrar-mantenimiento-producto',
        action: 'Financiero Productos',
        url: '/administrar-mantenimiento-producto',
      },
      {
        id: 'financiero-administrar-periodos-financieros',
        action: 'Financiero Periodos Financieros',
        url: '/administrar-periodos-financieros',
      },
      {
        id: 'financiero-administrar-periodos-financieros-agregar',
        action: 'Financiero Periodos Financieros agregar',
        url: '/administrar-periodos-financieros/agregar',
      },
      {
        id: 'financiero-administrar-periodos-financieros-editar',
        action: 'Financiero Periodos Financieros editar',
        url: '/administrar-periodos-financieros/:id/editar',
      },
      {
        id: 'financiero-administrar-grupos-economicos',
        action: 'Financiero Grupo Económico',
        url: '/administrar-grupos-economicos',
      },
      {
        id: 'financiero-administrar-grupos-economicos-agregar',
        action: 'Financiero Grupo Económico agregar',
        url: '/administrar-grupos-economicos/agregar',
      },
      {
        id: 'financiero-administrar-grupos-economicos-editar',
        action: 'Financiero Grupo Económico editar',
        url: '/administrar-grupos-economicos/:id/editar',
      },
      {
        id: 'financiero-administrar-puntos-cobro/punto-emision',
        action: 'Financiero Puntos de Emisión',
        url: '/administrar-puntos-cobro/punto-emision',
      },
      {
        id: 'financiero-administrar-puntos-cobro/punto-emision-agregar',
        action: 'Financiero Puntos de Emisión agregar',
        url: '/administrar-puntos-cobro/punto-emision/agregar',
      },
      {
        id: 'financiero-administrar-puntos-cobro/punto-emision-editar',
        action: 'Financiero Puntos de Emisión editar',
        url: '/administrar-puntos-cobro/punto-emision/:id/editar',
      },
      {
        id: 'financiero-administrar-configuracion-producto',
        action: 'Financiero Precio Producto',
        url: '/administrar-configuracion-productos',
      },
      {
        id: 'financiero-administrar-configuracion-producto-agregar',
        action: 'Financiero Precio Producto agregar',
        url: '/administrar-configuracion-productos-agregar',
      },
      {
        id: 'financiero-administrar-configuracion-producto-editar',
        action: 'Financiero Precio Producto editar',
        url: '/administrar-configuracion-productos/:id/editar',
      },
    ],
  },
  {
    id: 'rrhh',
    action: 'Recursos Humanos',
    children: [
      {
        id: 'recursos-humanos-administrar-colaboradores',
        action: 'Colaboradores',
        url: '/administrar-colaboradores',
      },
      {
        id: 'recursos-humanos-administrar-colaboradores-agregar',
        action: 'Colaboradores agregar',
        url: '/administrar-colaboradores-agregar',
      },
      {
        id: 'recursos-humanos-administrar-colaboradores-editar-colaborador',
        action: 'Colaboradores editar',
        url: '/administrar-colaboradores/:id/editar-colaborador',
      },
      {
        id: 'recursos-humanos-administrar-docentes',
        action: 'Asignar horas a docencia',
        url: '/administrar-docentes',
      },
      {
        id: 'recursos-humanos-administrar-cargos',
        action: 'Cargos',
        url: '/administrar-cargos',
      },
      {
        id: 'recursos-humanos-administrar-cargos',
        action: 'Cargos agregar',
        url: '/administrar-cargos/agregar',
      },
      {
        id: 'recursos-humanos-administrar-cargos-editar',
        action: 'Cargos editar',
        url: '/administrar-cargos/:id/editar',
      },
      {
        id: 'recursos-humanos-administrar-departamentos',
        action: 'Departamentos',
        url: '/administrar-departamentos',
      },
      {
        id: 'recursos-humanos-administrar-departamentos',
        action: 'Departamentos agregar',
        url: '/administrar-departamentos/agregar',
      },
      {
        id: 'recursos-humanos-administrar-departamentos',
        action: 'Departamentos editar',
        url: '/administrar-departamentos/:id/editar',
      },
    ],
  },
  {
    id: 'academic',
    action: 'Académico',
    children: [
      {
        id: 'academico-administrar-estudiantes',
        action: 'Académico Estudiantes',
        url: '/administrar-estudiantes',
      },
      {
        id: 'academico-administrar-estudiantes-agregar',
        action: 'Académico Estudiantes agregar',
        url: '/administrar-estudiantes/agregar',
      },
      {
        id: 'academico-administrar-estudiantes-editar',
        action: 'Académico Estudiantes editar',
        url: '/administrar-estudiantes/:id/editar',
      },
      {
        id: 'academico-asignar-notas',
        action: 'Académico Estudiantes asignar notas',
        url: '/asignar-notas/:id',
      },
      {
        id: 'academico-registro-asignaturas-registro',
        action: 'Académico Estudiante registro asignaturas',
        url: '/registro-asignaturas/:id/registro',
      },
      {
        id: 'academico-administrar-periodos',
        action: 'Académico Periodos',
        url: '/administrar-periodos',
      },
      {
        id: 'academico-administrar-periodos-agregar',
        action: 'Académico Periodos agregar',
        url: '/administrar-periodos/agregar',
      },
      {
        id: 'academico-administrar-periodos-etapas',
        action: 'Académico Periodos etapas',
        url: '/administrar-periodos/etapas/:id',
      },
      {
        id: 'academico-administrar-periodos-asignar-etapas',
        action: 'Académico Periodos etapas asignar',
        url: '/administrar-periodos/:id/asignar-etapas',
      },
      {
        id: 'academico-administrar-distribucion-aulas',
        action: 'Académico Distribución de aulas',
        url: '/administrar-distribucion-aulas',
      },
      {
        id: 'academico-administrar-cursos',
        action: 'Académico Cursos',
        url: '/administrar-cursos',
      },
      {
        id: 'academico-administrar-homologaciones',
        action: 'Académico Homologaciones',
        url: '/administrar-homologaciones',
      },
      {
        id: 'academico-administrar-solicitudes',
        action: 'Académico Listado de solicitudes',
        url: '/administrar-solicitudes',
      },
      {
        id: 'academico-administrar-solicitudes-revision',
        action: 'Académico Listado de solicitudes revisar',
        url: '/administrar-solicitudes/:id/revision',
      },
      {
        id: 'academico-administrar-requisiciones-docentes',
        action: 'Académico Requisiciones Docentes',
        url: '/administrar-requisiciones-docentes',
      },
      {
        id: 'academico-administrar-requisiciones-docentes-agregar',
        action: 'Académico Requisiciones Docentes agregar',
        url: '/administrar-requisiciones-docentes/agregar',
      },
      {
        id: 'academico-administrar-convenios',
        action: 'Académico Convenios',
        url: '/administrar-convenios',
      },
      {
        id: 'academico-administrar-convenios-agregar',
        action: 'Académico Convenios agregar',
        url: '/administrar-convenios/agregar',
      },
      {
        id: 'academico-administrar-convenios-editar',
        action: 'Académico Convenios editar',
        url: '/administrar-convenios/:id/editar',
      },
      {
        id: 'academico-administrar-grupo-de-areas',
        action: 'Académico Grupos',
        url: '/administrar-grupo-de-areas',
      },
      {
        id: 'academico-administrar-grupo-de-areas-agregar',
        action: 'Académico Grupos agregar',
        url: '/administrar-grupo-de-areas/agregar',
      },
      {
        id: 'academico-administrar-grupo-de-areas-editar',
        action: 'Académico Grupos editar',
        url: '/administrar-grupo-de-areas/:id/editar',
      },
      {
        id: 'academico-administrar-grupo-de-areas-carreras-nivelacion',
        action: 'Académico Grupos carreras de grupo nivelación',
        url: '/administrar-carreras-de-nivelacion/:id/carreras',
      },
      {
        id: 'academico-administrar-registro-nivelacion',
        action: 'Académico Registro Nivelación',
        url: '/administrar-registro-nivelacion',
      },
      {
        id: 'academico-administrar-seguimiento-nivelacion',
        action: 'Académico Seguimiento de Nivelación',
        url: '/administrar-seguimiento-nivelacion',
      },
      {
        id: 'academico-administrar-areas',
        action: 'Académico Áreas',
        url: '/administrar-areas',
      },
      {
        id: 'academico-administrar-areas-agregar',
        action: 'Académico Áreas agregar',
        url: '/administrar-areas/agregar',
      },
      {
        id: 'academico-administrar-areas-editar',
        action: 'Académico Áreas editar',
        url: '/administrar-areas/:id/editar',
      },
      {
        id: 'academico-administrar-companias',
        action: 'Académico Compañías',
        url: '/administrar-companias',
      },
      {
        id: 'academico-administrar-aulas',
        action: 'Académico Aulas',
        url: '/administrar-aulas',
      },
      {
        id: 'academico-administrar-campus',
        action: 'Académico Campus',
        url: '/administrar-campus',
      },
      {
        id: 'academico-administrar-campus-agregar',
        action: 'Académico Campus agregar',
        url: '/administrar-campus/agregar',
      },
      {
        id: 'academico-administrar-campus-editar',
        action: 'Académico Campus editar',
        url: '/administrar-campus/:id/editar',
      },
      {
        id: 'academico-administrar-paralelos',
        action: 'Académico Paralelos',
        url: '/administrar-paralelos',
      },
      {
        id: 'academico-administrar-paralelos-agregar',
        action: 'Académico Paralelos agregar',
        url: '/administrar-paralelos/agregar',
      },
      {
        id: 'academico-administrar-paralelos-editar',
        action: 'Académico Paralelos editar',
        url: '/administrar-paralelos/:id/editar',
      },
      {
        id: 'academico-administrar-horarios',
        action: 'Académico Horarios',
        url: '/administrar-horarios',
      },
      {
        id: 'academico-administrar-horarios-agregar',
        action: 'Académico Horarios agregar',
        url: '/administrar-horarios/agregar',
      },
      {
        id: 'academico-administrar-horarios-editar',
        action: 'Académico Horarios editar',
        url: '/administrar-horarios/:id/editar',
      },
      {
        id: 'academico-administrar-tipos-materias',
        action: 'Académico Tipos de Materias',
        url: '/administrar-tipos-materias',
      },
      {
        id: 'academico-administrar-tipos-materias-agregar',
        action: 'Académico Tipos de Materias agregar',
        url: '/administrar-tipos-materias/agregar',
      },
      {
        id: 'academico-administrar-tipos-materias-editar',
        action: 'Académico Tipos de Materias editar',
        url: '/administrar-tipos-materias/:id/editar',
      },
      {
        id: 'academico-administrar-materias',
        action: 'Académico Materias',
        url: '/administrar-materias',
      },
      {
        id: 'academico-administrar-materias-agregar',
        action: 'Académico Materias agregar',
        url: '/administrar-materias/agregar',
      },
      {
        id: 'academico-administrar-materias-editar',
        action: 'Académico Materias editar',
        url: '/administrar-materias/:id/editar',
      },
      {
        id: 'academico-administrar-niveles-educativos',
        action: 'Académico Niveles Educativos',
        url: '/administrar-niveles-educativos',
      },
      {
        id: 'academico-administrar-unidades-educativas',
        action: 'Académico Unidades Académicas',
        url: '/administrar-unidades-academicas',
      },
      {
        id: 'academico-administrar-unidades-educativas/agregar',
        action: 'Académico Unidades Académicas agregar',
        url: '/administrar-unidades-academicas/agregar',
      },
      {
        id: 'academico-administrar-unidades-educativas',
        action: 'Académico Unidades Académicas editar',
        url: '/administrar-unidades-academicas/:id/editar',
      },
      {
        id: 'academico-administrar-mallas',
        action: 'Académico Mallas',
        url: '/mallas-curriculares',
      },
      {
        id: 'academico-administrar-mallas-agregar',
        action: 'Académico Mallas agregar',
        url: '/mallas-curriculares/agregar',
      },
      {
        id: 'academico-administrar-mallas-editar',
        action: 'Académico Mallas editar',
        url: '/mallas-curriculares/:id/editar',
      },
      {
        id: 'academico-administrar-componentes-de-aprendizaje',
        action: 'Académico Componentes de aprendizaje',
        url: '/administrar-componentes-de-aprendizaje',
      },
      {
        id: 'academico-administrar-componentes-de-aprendizaje-agregar',
        action: 'Académico Componentes de aprendizaje agregar',
        url: '/administrar-componentes-de-aprendizaje/agregar',
      },
      {
        id: 'academico-administrar-componentes-de-aprendizaje-editar',
        action: 'Académico Componentes de aprendizaje editar',
        url: '/administrar-componentes-de-aprendizaje/:id/editar',
      },
      {
        id: 'academico-administrar-tipos-periodos',
        action: 'Académico Tipos de Periodos',
        url: '/administrar-tipos-periodos',
      },
      {
        id: 'academico-administrar-tipos-periodos-agregar',
        action: 'Académico Tipos de Periodos agregar',
        url: '/administrar-tipos-periodos/agregar',
      },
      {
        id: 'academico-administrar-tipos-periodos-editar',
        action: 'Académico Tipos de Periodos editar',
        url: '/administrar-tipos-periodos/:id/editar',
      },
      {
        id: 'academico-administrar-institutos',
        action: 'Académico Institutos',
        url: '/administrar-institutos',
      },
      {
        id: 'academico-administrar-institutos/agregar',
        action: 'Académico Institutos agregar',
        url: '/administrar-institutos/agregar',
      },
      {
        id: 'academico-administrar-institutos',
        action: 'Académico Institutos editar',
        url: '/administrar-institutos/:id/editar',
      },
      {
        id: 'academico-administrar-modelo-calificacion',
        action: 'Académico Modelo de Calificación',
        url: '/administrar-modelo-calificacion',
      },
      {
        id: 'academico-administrar-modelo-calificacion-agregar',
        action: 'Académico Modelo de Calificación agregar',
        url: '/administrar-modelo-calificacion/agregar',
      },
      {
        id: 'academico-administrar-modelo-calificacion-editar',
        action: 'Académico Modelo de Calificación editar',
        url: '/administrar-modelo-calificacion/:id/editar',
      },
      {
        id: 'academico-administrar-eventos',
        action: 'Académico Eventos y noticias',
        url: '/administrar-eventos',
      },
      {
        id: 'academico-administrar-eventos-agregar',
        action: 'Académico Eventos y noticias agregar',
        url: '/administrar-eventos',
      },
      {
        id: 'academico-administrar-eventos-editar',
        action: 'Académico Eventos y noticias editar',
        url: '/administrar-eventos/:id/editar',
      },
      {
        id: 'academico-administrar-eventos-visualizar',
        action: 'Académico Eventos visualizar',
        url: '/administrar-eventos/visualizar',
      },
      {
        id: 'academico-administrar-eventos-noticias/visualizar',
        action: 'Académico Noticias visualizar',
        url: '/administrar-eventos/noticias/visualizar',
      },
      {
        id: 'academico-cumpleanios',
        action: 'Académico Cumpleaños visualizar',
        url: '/cumpleanios',
      },
    ],
  },
  {
    id: 'virtual-classroom-student',
    action: 'Aula Virtual Estudiante',
    children: [
      {
        id: 'estudiante-aula-virtual-estudiante-inicio',
        action: 'Estudiante Contenido',
        url: '/aula-virtual-estudiante/inicio',
      },
      {
        id: 'estudiante-aula-virtual-estudiante-agenda',
        action: 'Estudiante Agenda',
        url: '/aula-virtual-estudiante/agenda',
      },
      {
        id: 'estudiante-aula-virtual-estudiante-tareas',
        action: 'Estudiante Tareas',
        url: '/aula-virtual-estudiante/tareas',
      },
      {
        id: 'estudiante-aula-virtual-estudiante-notas',
        action: 'Estudiante Calificaciones',
        url: '/aula-virtual-estudiante/notas',
      },
      {
        id: 'estudiante-aula-virtual-estudiante-foros',
        action: 'Estudiante Debate',
        url: '/aula-virtual-estudiante/foros',
      },
      {
        id: 'estudiante-aula-virtual-estudiante-test',
        action: 'Estudiante Test',
        url: '/aula-virtual-estudiante/test',
      },
      {
        id: 'estudiante-aula-virtual-estudiante-compañeros',
        action: 'Estudiante Compañeros',
        url: '/aula-virtual-estudiante/compañeros',
      },
      {
        id: 'vc-student-close',
        action: 'Estudiante Salir de aula virtual',
        url: '/',
      },
    ],
  },
  {
    id: 'virtual-classroom-teacher',
    action: 'Aula virtual Docente',
    children: [
      {
        id: 'docente-administrar-aula-virtual-test',
        action: 'Docente Test',
        url: '/administrar-aula-virtual/test',
      },
      {
        id: 'docente-administrar-aula-virtual-anuncios',
        action: 'Docente Calendario',
        url: '/administrar-aula-virtual/anuncios',
      },
      {
        id: 'docente-administrar-aula-virtual-estudiantes',
        action: 'Docente Estudiantes',
        url: '/administrar-aula-virtual/estudiantes',
      },
      {
        id: 'docente-administrar-aula-virtual-notas',
        action: 'Docente Calificaciones',
        url: '/administrar-aula-virtual/notas',
      },
      {
        id: 'docente-administrar-aula-virtual-materiales',
        action: 'Docente Materiales',
        url: '/administrar-aula-virtual/materiales',
      },
      {
        id: 'docente-administrar-aula-virtual-actividades',
        action: 'Docente Actividades',
        url: '/administrar-aula-virtual/actividades',
      },
      {
        id: 'docente-administrar-aula-virtual-asistencia',
        action: 'Docente Asistencia',
        url: '/administrar-aula-virtual/asistencia',
      },
      {
        id: 'docente-administrar-aula-virtual-sala',
        action: 'Docente Debate',
        url: '/administrar-aula-virtual/sala-debate',
      },
      {
        id: 'administrar-aula-virtual/reuniones',
        action: 'Docente reuniones virtuales',
        url: '/administrar-aula-virtual/reuniones',
      },
      {
        id: 'administrar-aula-virtual/reuniones/agregar',
        action: 'Docente agregar reuniones virtuales',
        url: '/administrar-aula-virtual/reuniones',
      },
      {
        id: 'docente-vc-salir',
        action: 'Docente Salir de aula virtual',
        url: '/administrar-cursos-profesor',
      },
    ],
  },
  {
    id: 'students',
    action: 'Estudiantes',
    children: [
      {
        id: 'estudiante-inicio-estudiante',
        action: 'Estudiante Inicio',
        url: '/inicio-estudiante',
      },
      {
        id: 'estudiante-portafolio-academico',
        action: 'Estudiante Cursos',
        url: '/portafolio-academico',
      },
      {
        id: 'estudiante-asignaturas-habilitadas',
        action: 'Estudiante Asignaturas Habilitadas',
        url: '/asignaturas-habilitadas',
      },
      {
        id: 'estudiante-registro-asignaturas',
        action: 'Estudiante Registro de Asignaturas',
        url: '/registro-asignaturas',
      },
      {
        id: 'estudiante-consejeria-academica',
        action: 'Estudiante Consejería Académica',
        url: '/consejeria-academica',
      },
      {
        id: 'estudiante-estado-de-cuenta',
        action: 'Estudiante Estado de cuenta',
        url: '/estado-de-cuenta',
      },
      {
        id: 'estudiante-solicitudes-examen-atrasado',
        action: 'Estudiante Examen atrasado',
        url: '/solicitudes/examen-atrasado',
      },
      {
        id: 'estudiante-solicitudes-examen-recuperacion',
        action: 'Estudiante Examen de recuperación',
        url: '/solicitudes/examen-recuperacion',
      },
      {
        id: 'estudiante-solicitudes-retiro-materia',
        action: 'Estudiante Retiro de materia',
        url: '/solicitudes/retiro-materia',
      },
      {
        id: 'estudiante-solicitudes-recalificacion',
        action: 'Estudiante Recalificación de notas',
        url: '/solicitudes/recalificacion',
      },
      {
        id: 'estudiante-solicitudes-cambio-facultad-carrera',
        action: 'Estudiante Cambio de facultad o carrera',
        url: '/solicitudes/cambio-facultad-carrera',
      },
      {
        id: 'estudiante-solicitudes-registro-tercera-matricula',
        action: 'Estudiante Solicitud de registro en tercera matrícula',
        url: '/solicitudes/registro-tercera-matricula',
      },
      {
        id: 'estudiante-solicitudes-otros-casos',
        action: 'Estudiante Otros casos especiales',
        url: '/solicitudes/otros-casos',
      },
      {
        id: 'estudiante-solicitudes-ingreso-practica-preprofesional',
        action: 'Estudiante Ingreso de practica preprofesional',
        url: '/solicitudes/ingreso-practica-preprofesional',
      },
      {
        id: 'estudiante-solicitudes-ingreso-vinculacion',
        action: 'Estudiante Ingreso vinculación',
        url: '/solicitudes/ingreso-vinculacion',
      },
      {
        id: 'estudiante-solicitudes-registro-anteproyecto',
        action: 'Estudiante Registro de anteproyecto',
        url: '/solicitudes/registro-anteproyecto',
      },
      {
        id: 'estudiante-solicitudes-convenio-pago',
        action: 'Estudiante Convenio de pago',
        url: '/solicitudes/convenio-pago',
      },
      {
        id: 'estudiante-solicitudes-consultar-solicitudes',
        action: 'Estudiante Consultar solicitudes',
        url: '/solicitudes/consultar-solicitudes',
      },
      {
        id: 'estudiante-certificados-record-academico',
        action: 'Estudiante Record académico',
        url: '/certificados/record-academico',
      },
      {
        id: 'estudiante-certificados-certificado-estudio',
        action: 'Estudiante Certificado de estudios',
        url: '/certificados/certificado-estudio',
      },
      {
        id: 'estudiante-certificados-disciplina-tercera-matricula',
        action: 'Estudiante Certificado de disciplina y tercera matrícula',
        url: '/certificados/disciplina-tercera-matricula',
      },
      {
        id: 'estudiante-certificados/materias-aprovadas',
        action: 'Estudiante Materias aprobadas',
        url: '/certificados/materias-aprovadas',
      },
      {
        id: 'estudiante-certificados/practicas-preprofesionales',
        action: 'Estudiante Pasantías PreProfesionales',
        url: '/certificados/practicas-preprofesionales',
      },
      {
        id: 'estudiante-certificados/credit',
        action: 'Estudiante Crédito educativo',
        url: '/certificados/credit',
      },
      {
        id: 'estudiante-certificados/consulado',
        action: 'Estudiante Consulado',
        url: '/certificados/consulado',
      },
      {
        id: 'estudiante-eventos-visualizar',
        action: 'Estudiante Eventos visualizar',
        url: '/eventos-visualizar',
      },
      {
        id: 'estudiante-noticias-visualizar',
        action: 'Estudiante Noticias visualizar',
        url: '/noticias-visualizar',
      },
    ],
  },
  {
    id: 'teacher',
    action: 'Docente',
    children: [
      {
        id: 'docente-inicio-docente',
        action: 'Docente Inicio',
        url: '/inicio-docente',
      },
      {
        id: 'docente-administrar-cursos-profesor',
        action: 'Docente Cursos',
        url: '/administrar-cursos-profesor',
      },
      {
        id: 'docente-eventos',
        action: 'Docente Eventos',
        url: '/eventos',
      },
      {
        id: 'docente-noticias',
        action: 'Docente Noticias',
        url: '/noticias',
      },
      {
        id: 'docente-administrar-horarios-profesor',
        action: 'Docente Horarios',
        url: '/administrar-horarios-profesor',
      },
      {
        id: 'docente-administrar-estudiantes-asignados',
        action: 'Docente Consejería Académica',
        url: '/administrar-estudiantes-asignados',
      },
      {
        id: 'docente-cumpleanios',
        action: 'Docente Cumpleaños',
        url: '/cumpleanios',
      },
      {
        id: 'docente-eventos-visualizar',
        action: 'Docente Eventos visualizar',
        url: '/eventos-visualizar',
      },
      {
        id: 'docente-noticias-visualizar',
        action: 'Docente Noticias visualizar',
        url: '/noticias-visualizar',
      },
    ],
  },
];
