import { Grid, IconButton, Typography, useTheme } from '@material-ui/core';
import { IconArrowNarrowLeft } from '@tabler/icons';
import { useHistory } from 'react-router-dom';
import MainCardForm from '../../../shared/components/cards/MainCardForm';
import EditCollaboratorWizard from '../components/EditCollaboratorWizard';

const EditPersonalDataScreen = () => {
  const theme = useTheme();
  const history = useHistory();

  return (
    <MainCardForm>
      <Grid item xs={12}>
        <Grid item container alignItems="center" gap={1} sx={{ cursor: 'pointer' }}>
          <IconButton onClick={() => history.goBack()}>
            <IconArrowNarrowLeft size={30} color={theme.palette.text.dark} />
          </IconButton>
          <Typography variant="h2">Editar</Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} container>
        <EditCollaboratorWizard />
      </Grid>
    </MainCardForm>
  );
};

export default EditPersonalDataScreen;
