import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISearchTable } from '../../../shared/interfaces/paginatedTable.interface';
import { Position } from '../interfaces/positions.interfaces';

interface PositionsState {
  positionList: Position[];
  deleteModalOpen: boolean;
  positionIdToDelete?: number;
  positionsTable: ISearchTable;
}

const initialState: PositionsState = {
  positionList: [],
  deleteModalOpen: false,
  positionsTable: {
    page: 1,
    perPage: 5,
    search: '',
  },
};

const positionsSlice = createSlice({
  name: 'positions',
  initialState,
  reducers: {
    setPositionList: (state, action: PayloadAction<Position[]>) => {
      state.positionList = action.payload;
    },
    setDeleteModalOpen: (state, action: PayloadAction<boolean>) => {
      state.deleteModalOpen = action.payload;
    },
    openDeleteModalWith: (state, action: PayloadAction<number>) => {
      state.positionIdToDelete = action.payload;
      state.deleteModalOpen = true;
    },
    resetPositionIdToDelete: (state) => {
      state.positionIdToDelete = undefined;
    },
    setPositionsTable: (state, action: PayloadAction<ISearchTable>) => {
      state.positionsTable = action.payload;
    },
  },
});

export const {
  setPositionList,
  setDeleteModalOpen,
  openDeleteModalWith,
  resetPositionIdToDelete,
  setPositionsTable,
} = positionsSlice.actions;

export default positionsSlice.reducer;
