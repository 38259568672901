import { Grid, Typography } from '@material-ui/core';
import { useAppSelector } from '../../../../hooks/useRedux';
import MainCard from '../../../shared/components/cards/MainCard';

import DeleteDepartmentDialog from '../components/DeleteDepartmentDialog';
// import DepartmentsTable from '../components/DepartmentsTable';
import DepartmentsTree from '../components/DepartmentsTree';

const DepartmentsScreen = () => {
  const { departmentIdToDelete } = useAppSelector((state) => state.departments);

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">Departamentos</Typography>
      </Grid>
      <DepartmentsTree />
      {/* <DepartmentsTable />*/}

      {departmentIdToDelete && <DeleteDepartmentDialog />}
    </Grid>
  );
};

export default DepartmentsScreen;
