import searchPaginatedTable from './searchPaginatedTable.json';
import buttons from './buttons.json';
import dashboard from './dashboard.json';
import modules from './modules.json';
import header from './header.json';
import events from './events.json';
import news from './news.json';
import sharedValidations from './sharedValidations.json';
import sharedInputs from './sharedInputs.json';
import profiles from './profiles.json';
import constants from './constants';
import menu from './menu.json';
import schedules from './schedules.json';
import sharedComponents from './sharedComponents.json';

const sharedTranslations = {
  ...searchPaginatedTable,
  ...dashboard,
  ...buttons,
  ...modules,
  ...header,
  ...events,
  ...news,
  ...sharedValidations,
  ...sharedInputs,
  ...profiles,
  ...constants,
  ...menu,
  ...schedules,
  ...sharedComponents,
};

export default sharedTranslations;
