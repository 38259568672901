import { Grid, Typography } from '@material-ui/core';
import MainCard from '../../../shared/components/cards/MainCard';
import BirthdayHourForm from '../forms/BirthdayHourForm';

const BirthdayHourScreen = () => {
  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid item sx={{ mb: 3 }} container justifyContent="space-between" alignItems="center">
        <Typography variant="h2">Solicitud de Happy Birthday Hour</Typography>
      </Grid>
      <Grid item container>
        <BirthdayHourForm />
      </Grid>
    </Grid>
  );
};

export default BirthdayHourScreen;
