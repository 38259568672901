import { Grid } from '@material-ui/core';
import { TasksData } from '../interfaces/tasks.interface';
import TaskItem from './taskItem/TaskItem';

interface Props {
  tasks: TasksData[];
  modalOptions: {
    viewModal: {
      open: boolean;
      type: 'comment' | 'file';
      detailId: number;
    };
    setViewModal: React.Dispatch<
      React.SetStateAction<{
        open: boolean;
        type: 'comment' | 'file';
        detailId: number;
      }>
    >;
    refetch: () => void;
  };
}

const TaskList = ({ tasks, modalOptions }: Props) => {
  return (
    <Grid
      container
      spacing={2}
      sx={{
        marginTop: 4,
      }}
    >
      {tasks.map((task) => (
        <TaskItem key={task.id} task={task} modalOptions={modalOptions} />
      ))}
    </Grid>
  );
};

export default TaskList;
