export const permissionKey = 'student';

export interface SubPermissionKeys {
  read: string;
  list: string;
  create: string;
  edit: string;
  delete: string;
}

export const subPermissionKeys: SubPermissionKeys = {
  read: 'student-read',
  list: 'student-list',
  create: 'student-crear-estudiante',
  edit: 'student-update-estudiante',
  delete: 'student-delete-estudiante',
};
