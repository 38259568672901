import { useFormikContext } from 'formik';
import { Grid, Typography } from '@material-ui/core';
import { FaqForm } from '../../interfaces/faq-form-interfaces';
import { ChangeEvent, useState } from 'react';
import { IconCloudUpload } from '@tabler/icons';
import { useTranslation } from 'react-i18next';

export const FaqEditFiles = () => {
  const { t } = useTranslation();
  const [target, setTarget] = useState<File>(null!);

  const { setFieldValue } = useFormikContext<FaqForm>();

  const onChangeFile = function (event: ChangeEvent<HTMLInputElement>) {
    if (event.target.files && event.target.files[0]) {
      setTarget(event.target.files[0]);
      setFieldValue('file', event.target.files[0]);
    }
  };

  return (
    <Grid container spacing={4} justifyContent="center" alignItems="center">
      <Grid item xs={6} md={6} container justifyContent="center" alignItems="center">
        <Grid
          item
          xs={6}
          container
          justifyContent="center"
          alignItems="center"
          flexDirection={'column'}
          textAlign={'center'}
          sx={{
            border: `5px solid #F2F3F4`,
            borderRadius: '10px',
            cursor: 'pointer',
            minWidth: '300px',
          }}
          p={4}
        >
          <input
            type="file"
            hidden
            id="selectFile"
            accept="application/pdf"
            onChange={onChangeFile}
          />
          <IconCloudUpload
            size={60}
            onClick={() => document.getElementById('selectFile')?.click()}
          />
          {target && (
            <Typography variant="h6" sx={{ mt: 2, wordWrap: 'break-word' }}>
              {target.name}
            </Typography>
          )}
          {!target && t('configuration.faqs.stepper.step3.select_document')}
        </Grid>
      </Grid>
    </Grid>
  );
};
