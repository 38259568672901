import { useState } from 'react';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Pagination,
  Radio,
  TextField,
  Typography,
} from '@material-ui/core';

import { useTranslation } from 'react-i18next';

import { IconTrash } from '@tabler/icons';
import { EvaluationQuestionSave, Options } from '../../interfaces/evaluations.interfaces';
import { removeQuestionSaved } from '../../slices/evaluationSlice';
import MainCard from '../../../../../shared/components/cards/MainCard';
import SuccessAlert from '../../../../../shared/components/alerts/SuccessAlert';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/useRedux';

const EvaluationQuestionAddedPreview = () => {
  const dispatch = useAppDispatch();
  /**
   * Estado de pregunta removida.
   */
  const [questionRemoved, setQuestionRemoved] = useState<boolean>(false);

  const { questions } = useAppSelector((state) => state.evaluationTeacher.evaluation);

  const { t } = useTranslation();
  const [Page, setPage] = useState(1);

  /**
   * Remover una pregunta del cuestionario.
   * Actualizar el estado de preguntas.
   * Se invalida el puntaje hasta volver a
   * completar puntos.
   */
  const removeQuestion = (index: number) => {
    dispatch(removeQuestionSaved(index));
    setQuestionRemoved(true);
  };

  const renderOptionsMultiple = ({ option, correct }: Options, index: number) => {
    return (
      <Grid container item xs={12} key={`${index}-${option}`}>
        <Grid item xs={10} sx={{ mt: 2 }}>
          <TextField variant="standard" fullWidth disabled value={`${index + 1}. ${option}`} />
        </Grid>
        <Grid item sx={{ mt: 1 }}>
          <Checkbox disabled checked={correct} />
        </Grid>
      </Grid>
    );
  };

  const renderOptionSingle = ({ option, correct }: Options, index: number) => {
    return (
      <Grid container key={`${index}-${option}`}>
        <Grid item xs={10} sx={{ mt: 2 }}>
          <TextField variant="standard" fullWidth disabled value={`${index + 1}. ${option}`} />
        </Grid>
        <Grid item sx={{ mt: 1 }}>
          <FormControlLabel label="" disabled control={<Radio />} checked={correct} />
        </Grid>
      </Grid>
    );
  };

  const renderQuestion = (
    { question, id, options, point, typeQuestion }: EvaluationQuestionSave,
    current: number
  ) => {
    return (
      <Grid item xs={12} key={`${current + 1}`} sx={{ mb: 3 }}>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Typography variant="h4">{`${current + 1}. ${question}`}</Typography>
        </Grid>

        {typeQuestion === 'multiple' && options.map((e, index) => renderOptionsMultiple(e, index))}

        {typeQuestion === 'single' && options.map((e, index) => renderOptionSingle(e, index))}

        <Grid container item xs={12} sx={{ mt: 2 }} style={{ justifyContent: 'left' }}>
          <Grid item xs={6}>
            <Typography variant="subtitle1">
              {point} {t('teacherVirtualClassroom.tests.chooseAnswerScored')}
            </Typography>
          </Grid>
          <Grid item>
            <Button
              onClick={() => removeQuestion(current)}
              variant="outlined"
              size="small"
              endIcon={<IconTrash style={{ cursor: 'pointer' }} />}
            >
              {t('teacherVirtualClassroom.tests.filterErase')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const RenderQuestionRemoved = () => {
    return (
      <Grid item xs={12} sx={{ mb: 2 }}>
        <SuccessAlert
          message="Pregunta removida del cuestionario"
          handleDismiss={() => setQuestionRemoved(false)}
        />
      </Grid>
    );
  };

  const renderQuestions = () => {
    return (
      <>
        {questions &&
          questions?.map((e, index) => (index + 1 === Page ? renderQuestion({ ...e }, index) : ''))}
      </>
    );
  };

  return (
    <Grid container spacing={1} alignItems="flex-start">
      <Grid item xs={12} md={12} container spacing={1}>
        <Grid item xs={12}>
          <MainCard>
            <Grid container spacing={4}>
              <Grid item xs={12} md={12} container spacing={1} justifyContent="center">
                <Grid container sx={{ mb: 1 }} justifyContent="space-between">
                  {questionRemoved && <RenderQuestionRemoved />}
                  {questions && questions.length === 0 ? null : renderQuestions()}
                </Grid>
              </Grid>
            </Grid>
            <Grid style={{ width: '100%' }} justifyContent="center" textAlign="center">
              <Pagination
                color="primary"
                count={questions?.length}
                page={Page}
                style={{ justifyContent: 'center', display: 'flex' }}
                onChange={(_, value) => setPage(value)}
              />
            </Grid>
          </MainCard>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EvaluationQuestionAddedPreview;
