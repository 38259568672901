import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISearchTable } from '../../../shared/interfaces/paginatedTable.interface';
import { EmissionPoint } from '../interfaces/financialConfiguration.interfaces';

interface FinancialConfigurationState {
  emissionPointList: EmissionPoint[];
  deleteModalOpen: boolean;
  emissionPointIdToDelete?: number;
  emissionPointTable: ISearchTable;

  userIdToUnlink?: number;
}

const initialState: FinancialConfigurationState = {
  emissionPointList: [],
  deleteModalOpen: false,
  emissionPointTable: {
    page: 1,
    perPage: 5,
    search: '',
  },
};

const financialConfigurationSlice = createSlice({
  name: 'financialConfigurations',
  initialState,
  reducers: {
    setEmissionPointList: (state, action: PayloadAction<EmissionPoint[]>) => {
      state.emissionPointList = action.payload;
    },
    setDeleteModalOpen: (state, action: PayloadAction<boolean>) => {
      state.deleteModalOpen = action.payload;
    },
    openDeleteModalWith: (state, action: PayloadAction<number>) => {
      state.emissionPointIdToDelete = action.payload;
      state.deleteModalOpen = true;
    },
    resetEmissionPointToDelete: (state) => {
      state.emissionPointIdToDelete = undefined;
    },

    openDeleteModalUnlinkUserWith: (state, action: PayloadAction<number>) => {
      state.userIdToUnlink = action.payload;
      state.deleteModalOpen = true;
    },
    resetUserIdToUnlink: (state) => {
      state.userIdToUnlink = undefined;
    },
    updateEmissionPointTable: (state, action: PayloadAction<ISearchTable>) => {
      state.emissionPointTable = action.payload;
    },
  },
});

export const {
  setEmissionPointList,
  setDeleteModalOpen,
  openDeleteModalWith,
  resetEmissionPointToDelete,
  updateEmissionPointTable,
  openDeleteModalUnlinkUserWith,
  resetUserIdToUnlink,
} = financialConfigurationSlice.actions;

export default financialConfigurationSlice.reducer;
