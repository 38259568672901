import { useAppSelector } from '../../../../hooks/useRedux';
import DeleteEducationLevelDialog from '../components/DeleteEducationLevelDialog';
import EducationLevelsTable from '../components/educationLevelsTable';
import { Grid, Typography } from '@material-ui/core';
import MainCard from '../../../shared/components/cards/MainCard';
// import { useGetPeriodsQuery } from '../slices/periodsSlice';

const EducationLevelsScreen = () => {
  const { educationLevelIdToDelete } = useAppSelector((state) => state.educationLevels);

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">Unidades Académicas</Typography>
      </Grid>

      <EducationLevelsTable />
      {educationLevelIdToDelete && <DeleteEducationLevelDialog />}
    </Grid>
  );
};

export default EducationLevelsScreen;
