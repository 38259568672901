import * as Yup from 'yup';

export const ProductSchema = Yup.object().shape({
  product: Yup.object()
    .nullable()
    .required('financial.generate_debts.product_dialog_form.item_required'),
  quantity: Yup.number()
    .nullable()
    .required('financial.generate_debts.product_dialog_form.quantity_required')
    .min(1, 'financial.generate_debts.product_dialog_form.quantity_min'),
});
