import { useState } from 'react';
import { Redirect } from 'react-router-dom';
import moment from 'moment';
import { useAppSelector } from '../../../../../hooks/useRedux';
import { Avatar, AvatarGroup, Chip, Grid, Paper, Tooltip, Typography } from '@material-ui/core';
import { ReceiverElement } from '../interfaces/student-announcement.interfaces';
import {
  Timeline,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
} from '@material-ui/lab';
import { obtainDomainStorageImage } from '../../../../shared/helpers';
import RenderAvatars from './tooltip/avatars';
import InfiniteScroll from 'react-infinite-scroll-component';
import { renderSkeletonTable } from '../../../../shared/helpers/render';
import { useStyles } from '../constants/styles';
import { useGetUserAnnouncementsQuery } from '../slices/studentsAsignedApiSlice';
import { useTranslation } from 'react-i18next';

interface Props {
  id: number;
  receiver: ReceiverElement[];
  ans_message: string;
  and_date_send: string;
}

const UserHistoryAnnouncements = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { currentProfile } = useAppSelector((state) => state.access);

  const { studentsList, userHistoryAnnouncements } = useAppSelector(
    (state) => state.studentsAsigned
  );

  const [limit, setLimit] = useState<number>(6);

  const [offset, setOffset] = useState<number>(0);

  const { data, isSuccess } = useGetUserAnnouncementsQuery({
    user_profile_id: currentProfile?.profileId!,
    offset: offset,
    limit: limit,
  });

  const dataFetch = userHistoryAnnouncements.map((value) => ({
    id: value.id,
    receiver: value?.receivers,
    ans_message: value.ans_message,
    and_date_send: value.ans_date_send,
  }));

  if (studentsList.length === 0) {
    return <Redirect to="/administrar-estudiantes-asignados" />;
  }

  const renderTime = ({ id, receiver, ans_message, and_date_send }: Props) => {
    return receiver !== undefined ? (
      <TimelineItem key={`${and_date_send}-${id}`}>
        <TimelineOppositeContent>
          {receiver.map((value) => (
            <Typography variant="body2" color="textPrimary">
              { value?.receiver?.person?.pers_firstname } { value?.receiver?.person?.pers_first_lastname } { value?.receiver?.person?.pers_second_lastname }
            </Typography>
          ))}
          <Typography variant="body2" color="textSecondary">
            {moment(and_date_send!).fromNow()}
          </Typography>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <AvatarGroup max={2} classes={{ avatar: classes.avatar }}>
            {receiver.map((value) => (
              <Tooltip
                key={`avatar-${value.id}-${value.rec_date_read}`}
                classes={{ tooltip: classes.tooltip }}
                title={RenderAvatars({ id, receiver, ans_message, and_date_send })}
              >
                <Avatar
                  style={{ cursor: 'pointer' }}
                  src={obtainDomainStorageImage(value?.receiver?.student?.stud_photo_path!)}
                ></Avatar>
              </Tooltip>
            ))}
          </AvatarGroup>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={3} className={classes.paper}>
            <Typography variant="h5" component="h1">
              {receiver?.length}{' '}
              {receiver?.length === 1
                ? t('teachers.academic_counseling.user_history.student')
                : t('teachers.academic_counseling.user_history.students')}
            </Typography>
            <Typography>{ans_message}</Typography>
            {moment(and_date_send).isSame(moment(), 'day') && (
              <Grid container justifyContent="flex-end">
                <Chip label="Reciente" color="primary" size="small" />
              </Grid>
            )}
          </Paper>
        </TimelineContent>
      </TimelineItem>
    ) : [];
  };

  const renderTimes = () => {
    return <>{dataFetch?.map(renderTime)}</>;
  };

  const fetchMoreData = () => {
    setOffset(userHistoryAnnouncements?.length!);
    setLimit(6);
  };

  const NotData = () => {
    return (
      <Typography variant="h5">{t('teachers.academic_counseling.user_history.no_data')}</Typography>
    );
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={12}>
        {isSuccess ? (
          <InfiniteScroll
            dataLength={userHistoryAnnouncements?.length!}
            next={fetchMoreData}
            hasMore={data?.length! > 5}
            loader={renderSkeletonTable(2, 6)}
            endMessage={<NotData />}
          >
            <Timeline position="alternate">{renderTimes()}</Timeline>
          </InfiniteScroll>
        ) : (
          renderSkeletonTable(2, 6)
        )}
      </Grid>
    </Grid>
  );
};

export default UserHistoryAnnouncements;
