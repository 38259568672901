import { useEffect, useState } from 'react';
// import { useHistory, useRouteMatch } from 'react-router-dom';
// import { Link } from 'react-router-dom';
import { useAppSelector } from '../../../../hooks/useRedux';
import StatusChip from '../../../shared/components/badges/StatusChip';
// import DeleteButton from '../../../shared/components/tables/buttons/DeleteButton';
// import EditButton from '../../../shared/components/tables/buttons/EditButton';
import { useGetProfilesQuery } from '../slices/profilesApiSlice';
// import { openDeleteModalWith } from '../slices/profilesSlice';
// import AddButton from '../../../shared/components/tables/buttons/AddButton';
// import useGuard from '../hooks/useGuard';
import SearchPaginatedTable, {
  FilterArrow,
} from '../../../shared/components/tables/SearchPaginatedTable';
import { useTranslation } from 'react-i18next';

const ProfilesTable = () => {
  const { t } = useTranslation();
  const { currentProfile } = useAppSelector((state) => state.access);

  // const dispatch = useAppDispatch();

  // const { path } = useRouteMatch();
  // const history = useHistory();

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const [search, setSearch] = useState('');
  const [filterArrow, setFilterArrow] = useState<FilterArrow>({
    name: 'pro_name',
    direction: 'asc',
  });

  useEffect(() => {
    setPage(1);
  }, [perPage, search]);

  // const { hasAccess } = useGuard();

  const { isLoading, error, data, isFetching } = useGetProfilesQuery(
    {
      profileId: currentProfile?.profileId!,
      page,
      perPage,
      search,
      ...(filterArrow && {
        sort: filterArrow.name,
        type_sort: filterArrow.direction,
      }),
    },
    { refetchOnMountOrArgChange: 30 }
  );

  // const handleDelete = async (profileId: number) => {
  //   dispatch(openDeleteModalWith(profileId));
  // };

  // const goToAdd = () => {
  //   history.push(`${path}/agregar`);
  // };

  const datafetch = data?.data!.map((d, index) => ({
    id: index + (page - 1) * perPage + 1,
    name: d.pro_name,
    statusId: d.status_id,
    // options: '',
  }));

  return (
    <SearchPaginatedTable
      data={datafetch!}
      headers={{
        id: 'N°',
        name: t('configuration.profiles.name'),
        statusId: t('configuration.profiles.status'),
        // options: t('configuration.profiles.options'),
      }}
      listFilterArrows={{
        name: 'pro_name',
      }}
      filterArrow={filterArrow}
      setFilterArrow={setFilterArrow}
      keyExtractor={(item) => item.id.toString()}
      searchPlacehoder={t('configuration.profiles.search')}
      isLoading={isLoading}
      isFetching={isFetching}
      error={error}
      perPage={perPage}
      setPerPage={setPerPage}
      total={data?.total || 0}
      page={page}
      setPage={setPage}
      numHeader={4}
      search={search}
      setSearch={setSearch}
      customRenderers={{
        statusId: (row) => <StatusChip statusId={Number(row.statusId)} />,
        // options: (row) => (
        //   <>
        //     {/* {hasAccess('edit') && (
        //       <Link to={`${path}/${row.id}/editar`}>
        //         <EditButton />
        //       </Link>
        //     )}
        //     {hasAccess('delete') && <DeleteButton handleDelete={() => handleDelete(row.id)} />}
        //     {!hasAccess('edit') && !hasAccess('delete') && t('configuration.profiles.none')} */}
        //   </>
        // ),
      }}
      customHeadersCellsProperties={{
        name: {
          width: '50%',
        },
      }}
      customDataCellsProperties={{
        // options: {
        //   align: 'center',
        // },
        statusId: {
          align: 'center',
        },
        id: {
          align: 'center',
        },
        name: {
          align: 'center',
        },
      }}
      // ActionButtons={<>{hasAccess('create') && <AddButton size="large" onClick={goToAdd} />}</>}
    />
  );
};

export default ProfilesTable;
