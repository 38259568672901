import { useHistory, Link, useRouteMatch } from 'react-router-dom';
import {
  Grid,
  IconButton,
  Tooltip,
  Autocomplete,
  TextField,
  Button,
  InputLabel,
  FormGroup,
  Switch,
  FormControlLabel,
  Collapse,
  CircularProgress,
  Stack,
  Typography,
} from '@material-ui/core';
import EditButton from '../../../shared/components/tables/buttons/EditButton';
import DeleteButton from '../../../shared/components/tables/buttons/DeleteButton';
import AddButton from '../../../shared/components/tables/buttons/AddButton';

import useGuard from '../hooks/useGuard';
import SearchPaginatedTable, {
  FilterArrow,
} from '../../../shared/components/tables/SearchPaginatedTable';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { useEffect, useState } from 'react';
import StatusChip from '../../../shared/components/badges/StatusChip';
import { useGetBooksQuery } from '../../books/slices/booskApiSlice';
import { openDeleteModalWith, setThesisToEdit } from '../slices/thesisSlice';
import { IconBarcode, IconEye, IconSearch } from '@tabler/icons';
//import { useGetPersonsQuery } from '../../lendings/slices/lendingApiSlice';
import { useGetAllCollaboratorsQuery } from '../../../hr/collaborators/slices/collaboratorsApiSlice';
import { useGetAvailablesCareersQuery } from '../../../maintenance/education-levels/slices/educationLevelsApiSlice';
import { useGetAcademicOfferingQuery } from '../../../maintenance/academic-offerings/slices/academicOfferingsApiSlice';
import { useGetStudentsQuery } from '../../../maintenance/students/slices/studentsApiSlice';
import BarCodeDialog from '../../shared/components/BarCodeDialog';
import { useGetInvestigationLinesAllAvailablesQuery } from '../../../maintenance/lines-of-investigation/slices/InvestigationLinesApiSlice';

const ThesisTable = () => {
  const dispatch = useAppDispatch();
  const { thesisToDelete } = useAppSelector((state) => state.thesis);

  const { currentProfile } = useAppSelector((state) => state.access);

  const { hasAccess } = useGuard();
  const { path } = useRouteMatch();
  const history = useHistory();

  const [showFilters, setShowFilters] = useState(false);
  const [open, setOpen] = useState(false);
  const [searchEst, setSearchEst] = useState<string>('');

  const [barCodeDialog, setBarCodeDialog] = useState({
    open: false,
    title: '',
    value: '',
    classification: '',
    author: ''
  });

  //Filtros
  const [line_research, setLineResearch] = useState<string | undefined>();
  const [studentId, setStudentId] = useState<number | undefined>();
  const [tutorId, setTutorId] = useState<number | undefined>();
  const [careerId, setCareerId] = useState<number | undefined>();
  const [facultyId, setFacultyId] = useState<number | undefined>();
  const [barcode, setBarcode] = useState<string | undefined>();

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(5);
  const [search, setSearch] = useState('');
  const [filterArrow, setFilterArrow] = useState<FilterArrow>(null!);

  const {
    data: books,
    isLoading,
    isFetching,
    isError,
    error,
    refetch,
  } = useGetBooksQuery(
    {
      page,
      size,
      search,
      type_book: 'Tesis',
      studentId,
      tutorId,
      careerId,
      facultyId,
      line_research,
      institution_barcode: barcode,
      ...(filterArrow && { sort: filterArrow.name, type_sort: filterArrow.direction }),
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const { data: lines, isLoading: isLoadingLines } = useGetInvestigationLinesAllAvailablesQuery({
    profileId: currentProfile?.profileId!,
  });

  const {
    data: users,
    isLoading: isLoadingUsers,
    isFetching: isFetchingUsers,
  } = useGetStudentsQuery(
    {
      page: 1,
      perPage: 100,
      search: searchEst,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const loadingUS = isLoadingUsers || isFetchingUsers ? true : false;

  const options =
    open && isFetchingUsers
      ? []
      : users?.data.map((us) => ({
          value: us.id,
          label: `${us.pers_firstname} ${us.pers_secondname} ${us.pers_first_lastname} ${us.pers_second_lastname} `,
        })) || [];

  const { data: collaborators, isLoading: isLoadingCollaborators } = useGetAllCollaboratorsQuery({
    user_profile_id: 1,
  });

  const { data: careers, isLoading: isLoadingCareers } = useGetAvailablesCareersQuery({
    profileId: 1,
  });

  const { data: faculties, isLoading: isLoadingFaculties } = useGetAcademicOfferingQuery({
    profileId: 1,
    academicOfferingId: 2,
  });

  const handleDelete = async (thesisId: number) => {
    dispatch(openDeleteModalWith(thesisId));
  };

  const goToAdd = () => {
    dispatch(setThesisToEdit(undefined));
    history.push(`${path}/agregar`);
  };

  useEffect(() => {
    if (thesisToDelete === undefined) {
      refetch();
    }
  }, [thesisToDelete, refetch]);

  const handleCloseBarCodeDialog = () => {
    setBarCodeDialog({
      open: false,
      title: '',
      value: '',
      classification: '',
      author: ''
    });
  };

  return (
    <>
      <Grid item xs={4} md={2} lg={2}>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch value={showFilters} onChange={(e) => setShowFilters(e.target.checked)} />
            }
            label="Búsqueda avanzada"
          />
        </FormGroup>
      </Grid>
      <Grid item container xs={12} md={12} lg={12}>
        <Collapse in={showFilters} sx={{ flex: 1 }}>
          <Grid item container xs={12} md={12} lg={12} spacing={2}>
            <Grid item xs={12} md={3}>
              <InputLabel>Línea de Investigación</InputLabel>
              <Autocomplete
                options={
                  lines?.map((dt) => ({
                    value: dt.id,
                    label: dt.name,
                  })) || []
                }
                loading={isLoadingLines}
                onChange={(_, val) => {
                  val ? setLineResearch(val.value) : setLineResearch(undefined);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Seleccione Línea"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {isLoadingLines ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <InputLabel>Autor</InputLabel>
              <Autocomplete
                options={options}
                filterOptions={(x) => x}
                open={open}
                onOpen={() => {
                  setOpen(true);
                }}
                onClose={() => {
                  setOpen(false);
                }}
                //isOptionEqualToValue={(option, value) => option.label === value.label}
                getOptionLabel={(option) => option.label}
                loading={loadingUS}
                onChange={(_, val) => {
                  setStudentId(val?.value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    onChange={({ target }) => setSearchEst(target.value)}
                    placeholder="Ingresa Nombre de Estudiante"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loadingUS ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <InputLabel>Tutor</InputLabel>
              <Autocomplete
                options={
                  collaborators?.data?.map((dt) => ({
                    value: dt.id,
                    label: dt.user.person.pers_full_name,
                  })) || []
                }
                loading={isLoadingCollaborators}
                onChange={(e, val) => {
                  setTutorId(val?.value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Ingresa Nombre de Tutor"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {isLoadingCollaborators ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <InputLabel>Carrera</InputLabel>
              <Autocomplete
                options={
                  careers
                    ?.filter((cr) => !Boolean(cr.edu_name === 'TODAS'))
                    .map((cr) => ({
                      value: cr.id,
                      label: cr.edu_name,
                    })) || []
                }
                loading={isLoadingCareers}
                onChange={(e, val) => {
                  setCareerId(val?.value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Seleccione Carrera"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {isLoadingCareers ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <InputLabel>Facultad</InputLabel>
              <Autocomplete
                options={
                  faculties?.education_levels?.map((fc) => ({
                    value: fc.id,
                    label: fc.edu_name,
                  })) || []
                }
                loading={isLoadingFaculties}
                onChange={(e, val) => {
                  setFacultyId(val?.value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Seleccione Facultad"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {isLoadingFaculties ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <InputLabel>Código de Barras</InputLabel>
              <TextField
                fullWidth
                placeholder="Seleccione Código de Barras"
                onChange={({ target }) => {
                  setBarcode(target.value);
                }}
              />
            </Grid>

            <Grid item container xs={12} md={12} lg={12} justifyContent="flex-end" mb={3}>
              <Button variant="contained" endIcon={<IconSearch />} onClick={refetch}>
                Buscar
              </Button>
            </Grid>
          </Grid>
        </Collapse>
      </Grid>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item md={12}>
          <SearchPaginatedTable
            data={
              books?.data.map((bk, index) => ({
                id: index + Number(books?.from),
                author: bk.student_record
                  ? bk.student_record[0].student.user.person.pers_full_name
                  : '',
                //tutor: bk.collaborator ? bk.collaborator[0].user.person.pers_full_name : '',
                title: bk.title.toUpperCase(),
                classification: bk.clasification.name,
                line: bk.line_research.name,
                publish_date: bk.publish_date,
                status: bk.status.id,
                options: bk,
              })) || []
            }
            headers={{
              id: 'Nº',
              author: 'Autor',
              //tutor: 'Tutor',
              title: 'Título',
              classification: 'Clasificación',
              line: 'Linea de Investigación',
              publish_date: 'Fecha de Publicación',
              status: 'Estado',
              options: 'Opciones',
            }}
            keyExtractor={(item) => item.id.toString()}
            searchPlacehoder="Buscar Tesis"
            listFilterArrows={{
              title: 'title',
              publish_date: 'publish_date',
              status: 'status_id',
            }}
            filterArrow={filterArrow}
            setFilterArrow={setFilterArrow}
            isLoading={isLoading}
            isFetching={isFetching}
            error={isError ? error : undefined}
            perPage={size}
            setPerPage={setSize}
            total={books?.total!}
            page={page}
            setPage={setPage}
            numHeader={8}
            search={search}
            setSearch={setSearch}
            customRenderers={{
              status: (row) => <StatusChip statusId={Number(row.status)} />,
              author: (row) => (
                <Stack sx={{ alignItems: 'center' }}>
                  <Typography variant="h5" textAlign="center">
                    {row.author}
                  </Typography>
                  <Typography variant="body2" textAlign="center">
                    Tutor:{' '}
                    {
                      books?.data?.find(
                        (bk, index) => index === Number(row.id) - Number(books.from)
                      )?.collaborator[0].user.person.pers_full_name
                    }
                  </Typography>
                </Stack>
              ),
              options: (row) => (
                <>
                  <Tooltip title="Ver Archivo">
                    <IconButton
                      disabled={
                        !Boolean(
                          books?.data.find(
                            (bk, index) => index === Number(row.id) - Number(books.from)
                          )?.book_url
                        )
                      }
                      onClick={() => {
                        const url = books?.data.find(
                          (bk, index) => index === Number(row.id) - Number(books.from)
                        )?.book_url;

                        if (url) {
                          window.open(
                            `https://${localStorage.getItem('apiUrl')}/api/show?name=${url}`,
                            ''
                          );
                        }
                      }}
                    >
                      <IconEye />
                    </IconButton>
                  </Tooltip>
                  {hasAccess('edit') && (
                    <Link to={`${path}/editar`}>
                      <EditButton
                        title="Editar"
                        handleEdit={() => {
                          dispatch(
                            setThesisToEdit(
                              books?.data.find(
                                (bk, index) => index === row.id - Number(books?.from)
                              )
                            )
                          );
                        }}
                      />
                    </Link>
                  )}
                  {hasAccess('delete') && (
                    <DeleteButton
                      handleDelete={() =>
                        handleDelete(
                          Number(
                            books?.data.find((bk, index) => index === row.id - Number(books?.from))
                              ?.id!
                          )
                        )
                      }
                    />
                  )}
                  {row?.options.institution_barcode && (
                    <IconButton
                      onClick={() => {
                        setBarCodeDialog({
                          open: true,
                          value: row?.options.institution_barcode,
                          title: `${row?.options.title}`,
                          classification: row.options.clasification?.code,
                          author: row?.options?.student_record?.[0]?.student?.user?.person?.pers_full_lastname || '',
                        });
                      }}
                    >
                      <IconBarcode />
                    </IconButton>
                  )}
                  {!hasAccess('edit') && !hasAccess('delete') && 'Ninguna'}
                </>
              ),
            }}
            ActionButtons={
              <>{hasAccess('create') && <AddButton size="large" onClick={goToAdd} />}</>
            }
            customDataCellsProperties={{
              id: { align: 'center' },
              publish_date: { align: 'center' },
              classification: { align: 'center' },
              line: { align: 'center' },
              status: { align: 'center' },
              options: { align: 'center' },
            }}
          />
        </Grid>
        {barCodeDialog?.open && (
          <BarCodeDialog
            title={barCodeDialog?.title}
            value={barCodeDialog?.value}
            classification={barCodeDialog?.classification}
            handleClose={handleCloseBarCodeDialog}
            author={barCodeDialog?.author}
          />
        )}
      </Grid>
    </>
  );
};

export default ThesisTable;
