import { Box, Button, Grid, TextField, Typography } from '@material-ui/core';
import { DateRangePicker, LocalizationProvider } from '@material-ui/lab';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import { IconSearch } from '@tabler/icons';
import { useFormik } from 'formik';
import { useAppSelector } from '../../../../hooks/useRedux';
import InputLabel from '../../../shared/components/forms/InputLabel';
import SearchPaginatedTable from '../../../shared/components/tables/SearchPaginatedTable';
import { useLazyGetEnterLeaveQuery } from '../../slices/rrhhApiSlice';
import * as yup from 'yup';
import imge from '../../../../assets/images/illustrations/telework.svg';
import { useEffect } from 'react';
import { es } from 'date-fns/locale';
import NoCollaboratorInfo from '../../messages/NoCollaboratorInfo';
import { format, add } from 'date-fns';

const EnterLeaveList = () => {
  const { currentProfile } = useAppSelector((state) => state.access);
  const { userInfo } = useAppSelector((state) => state.auth);
  const [getEnterLeave, { data, isLoading, isFetching, error }] = useLazyGetEnterLeaveQuery();
  //const [trigger, setrTrigger] = useState(false);

  const schema = yup.object().shape({
    ini: yup.string().nullable().required('Fecha de inicio es requerida'),
    end: yup.string().nullable().required('Fecha de fin es requerida'),
  });

  const formik = useFormik({
    initialValues: {
      ini: format(add(new Date(), { days: -7 }), 'yyyy-MM-dd'),
      end: format(new Date(), 'yyyy-MM-dd'),
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      getEnterLeave({
        collaborator_id: userInfo.collaboratorInfo?.id!,
        //collaborator_id: Number(userInfo.collaboratorInfo?.code_rrhh!),
        end_date: format(new Date(values.end), 'yyyy-MM-dd'),
        start_date: format(new Date(values.ini), 'yyyy-MM-dd'),
        user_profile_id: currentProfile?.profileId!,
      });
    },
  });

  const sendSubmit = () => {
    getEnterLeave({
      collaborator_id: userInfo.collaboratorInfo?.id!,
      //collaborator_id: Number(userInfo.collaboratorInfo?.code_rrhh!),
      end_date: format(new Date(formik.values.end), 'yyyy-MM-dd'),
      start_date: format(new Date(formik.values.ini), 'yyyy-MM-dd'),
      user_profile_id: currentProfile?.profileId!,
    });
  };

  useEffect(() => {
    sendSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!userInfo.collaboratorInfo) {
    return <NoCollaboratorInfo />;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6} lg={6}></Grid>
      <Grid item xs={12} md={6} lg={6}>
        <InputLabel>Fechas</InputLabel>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
          {/* <DatePicker
            //views={['year', 'month']}
            //label="Año y Mes"
            //minDate={dayjs('2012-03-01')}
            //maxDate={dayjs('2023-06-01')}
            value={formik.values.end}
            onChange={(newValue) => {
              formik.setFieldValue('end', newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                error={Boolean(formik.errors.end && formik.touched.end)}
                helperText={null}
                fullWidth
              />
            )}
          /> */}
          <DateRangePicker
            value={[formik.values.ini, formik.values.end]}
            onChange={(val) => {
              formik.setFieldValue('ini', val[0]);
              formik.setFieldValue('end', val[1]);
            }}
            renderInput={(startProps, endProps) => (
              <>
                <TextField
                  {...startProps}
                  //label="Fecha Inicio"
                  label={null}
                  helperText={null}
                  fullWidth
                  error={false}
                />
                <Box sx={{ mx: 2 }}> - </Box>
                <TextField
                  {...endProps}
                  //label="Fecha Fin"
                  label={null}
                  helperText={null}
                  fullWidth
                  error={false}
                />
              </>
            )}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} md={12} lg={12} sx={{ textAlign: 'right' }}>
        <Button
          onClick={() => {
            formik.handleSubmit();
          }}
          variant="contained"
          endIcon={<IconSearch />}
          disabled={Boolean(formik.values.ini === '' || formik.values.end === '')}
        >
          Buscar
        </Button>
      </Grid>
      <Grid item xs={12} md={12} lg={12} mt={2}>
        {formik.values.ini === '' || formik.values.end === '' ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img src={imge} style={{ width: 300 }} alt="select" />
            <Typography variant="h4">
              &nbsp;Seleccione fecha de inicio y fecha de fin para visualizar sus marcaciones
            </Typography>
          </div>
        ) : (
          <SearchPaginatedTable
            data={
              data?.map((el, index) => ({
                num: index + 1,
                date: el.fecha !== '' ? el.fecha : '-',
                enter: el.entrada !== '' ? el.entrada : '-',
                leave: el.salida !== '' ? el.salida : '-',
              })) || []
            }
            headers={{
              num: 'Nº',
              date: 'Fecha',
              enter: 'Entrada',
              leave: 'Salida',
            }}
            error={error}
            isFetching={isFetching}
            isLoading={isLoading}
            keyExtractor={(row) => String(row)}
            numHeader={3}
            page={1}
            perPage={data?.length || 10}
            search=""
            searchPlacehoder=""
            setPage={undefined}
            setPerPage={undefined}
            setSearch={undefined}
            total={data?.length || 10}
            showFilter={false}
            paginationBox={false}
            perPageBox={false}
            customDataCellsProperties={{
              enter: { align: 'center' },
              leave: { align: 'center' },
              num: { align: 'center' },
              date: { align: 'center' },
            }}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default EnterLeaveList;
