import { useEffect } from 'react';

import { useHistory, useParams } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { useAppSelector, useAppDispatch } from '../../../../hooks/useRedux';
import MainCard from '../../../shared/components/cards/MainCard';

import SimpleFormOneColumn from '../../../shared/components/forms/skeletons/SimpleFormOneColumn';
import { useLazyGetBusScheduleQuery } from '../slices/busRouteApiSlice';
import { setBuseSchedulesList } from '../slices/busRouteSlice';
import BusScheduleForm from '../components/BusScheduleForm';
import GoBackBtnTitle from '../../../shared/components/buttons/GoBackBtnTitle';

const BusSchedulesFormScreen = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  // const location = useLocation();
  const { scheduleId } = useParams<{ scheduleId: string }>();

  const { busSchedulesList } = useAppSelector((state) => state.busRoute);
  const { currentProfile } = useAppSelector((state) => state.access);

  if (currentProfile?.keyword !== 'administrativo') {
    history.goBack();
  }

  const [getSchedule, { isFetching }] = useLazyGetBusScheduleQuery();

  useEffect(() => {
    if (!busSchedulesList.length && !isFetching && scheduleId) {
      getSchedule({
        scheduleId: +scheduleId,
      })
        .unwrap()
        .then((response) => {
          if (response) dispatch(setBuseSchedulesList([response!]));
        })
        .catch((error) => console.log(error));
    }
  }, [busSchedulesList.length, dispatch, getSchedule, isFetching, scheduleId]);

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <GoBackBtnTitle title={t('Administrar Horario')} />

      <Grid item xs={12} sx={{ mt: 2 }}>
        {!isFetching /* && Boolean(busSchedulesList?.length) */ ? (
          <BusScheduleForm editId={Number(scheduleId) || undefined} />
        ) : (
          <SimpleFormOneColumn />
        )}
      </Grid>
      {/* {roleIdToDelete && <DeleteRoleDialog />} */}
    </Grid>
  );
};

export default BusSchedulesFormScreen;
