import { IconButton, Tooltip } from '@material-ui/core';
import { IconCalendarEvent } from '@tabler/icons';

const PeriodButton = ({ handlePeriod }: { handlePeriod?: () => void }) => {
  return (
    <Tooltip title="Ver períodos">
      <IconButton aria-label="Editar" color="primary" onClick={handlePeriod}>
        <IconCalendarEvent size={20} />
      </IconButton>
    </Tooltip>
  );
};

export default PeriodButton;
