import React from "react";
import { Box, Button, FormControlLabel, Grid, Radio, RadioGroup, Tab, Tabs, TextField, Typography } from "@material-ui/core";
import InputLabel from "../../../shared/components/forms/InputLabel";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const CompaniesForm = () => {
    const [valueTab, setValueTab] = React.useState(0);
    const [value, setValue] = React.useState('Leads');
    console.log(setValue);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue((event.target as HTMLInputElement).value);
    };

    //Tabs

    const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
        setValueTab(newValue);
    };

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <InputLabel>Nombre</InputLabel>
                    <TextField placeholder="Ingrese Nombre" fullWidth></TextField>
                </Grid>
                <Grid item xs={12} md={6}>
                    <InputLabel>Ruc</InputLabel>
                    <TextField placeholder="Ingrese Ruc" fullWidth></TextField>
                </Grid>
                <Grid item xs={12} md={6}>
                    <InputLabel>Telefono</InputLabel>
                    <TextField placeholder="Ingrese Telefono" fullWidth></TextField>
                </Grid>
                <Grid item xs={12} md={6}>
                    <InputLabel>Dirección</InputLabel>
                    <TextField placeholder="Ingrese Dirección" fullWidth></TextField>
                </Grid>
                <Grid item xs={12} md={6}>
                    <InputLabel>Email</InputLabel>
                    <TextField placeholder="Ingrese Email" fullWidth></TextField>
                </Grid>
                <Grid item xs={12} md={12}>
                    <InputLabel>Estado</InputLabel>
                    <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={value}
                        onChange={handleChange}
                    >
                        <FormControlLabel value="Leads" control={<Radio />} label="Activo" />
                        <FormControlLabel value="Clientes" control={<Radio />} label="Inactivo" />
                    </RadioGroup>
                </Grid>
            </Grid>

            <Grid item xs={12} sx={{ mt: 4 }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={valueTab} onChange={handleChangeTab} aria-label="basic tabs example">
                        <Tab label="Configuración Mail" {...a11yProps(0)} />
                        <Tab label="Configuración Socket" {...a11yProps(1)} />
                    </Tabs>
                </Box>
                <TabPanel value={valueTab} index={0}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <InputLabel>Usuario</InputLabel>
                            <TextField placeholder="Ingrese Usuario" fullWidth></TextField>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputLabel>Password</InputLabel>
                            <TextField placeholder="Ingrese Password" fullWidth></TextField>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputLabel>Host</InputLabel>
                            <TextField placeholder="Ingrese Host" fullWidth></TextField>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputLabel>Puerto</InputLabel>
                            <TextField placeholder="Ingrese Puerto" fullWidth></TextField>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputLabel>SMTP</InputLabel>
                            <TextField placeholder="Ingrese SMTP" fullWidth></TextField>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputLabel>Nombre Envio</InputLabel>
                            <TextField placeholder="Ingrese Nombre Envio" fullWidth></TextField>
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value={valueTab} index={1}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <InputLabel>URL</InputLabel>
                            <TextField placeholder="Ingrese URL" fullWidth></TextField>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputLabel>Token</InputLabel>
                            <TextField placeholder="Ingrese Token" fullWidth></TextField>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputLabel>Estado</InputLabel>
                            <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={value}
                                onChange={handleChange}
                            >
                                <FormControlLabel value="Leads" control={<Radio />} label="Activo" />
                                <FormControlLabel value="Clientes" control={<Radio />} label="Inactivo" />
                            </RadioGroup>
                        </Grid>

                    </Grid>
                </TabPanel>

            </Grid>
            <Grid
                item
                container
                alignItems="center"
                justifyContent="flex-end"
                spacing={2}
                sx={{ mt: 1 }}
            >
                <Grid item>
                    <Button
                        variant="contained"
                        size="large"
                        color="primary"
                        onClick={() => {
                            /* if (filtersSearch) {
                                refetchClassroomData();
                            } else {
                                setDisplayFilters(false);
                            }
                            setFiltersSearch(true); */
                        }}
                    //disabled={!searchBtn}
                    >
                        Guardar
                    </Button>
                </Grid>
            </Grid>
        </>
    )
}

export default CompaniesForm;