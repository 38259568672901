import { Autocomplete, FormHelperText, Grid, InputLabel, TextField } from '@material-ui/core';
import { DateRange } from '@material-ui/lab/DateRangePicker/RangeTypes';
import { parseISO } from 'date-fns';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ErrorAlert from '../../../../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../../../../shared/components/alerts/SuccessAlert';
import LoadingButton from '../../../../../../shared/components/buttons/LoadingButton';
import LocalizedDateRangePicker from '../../../../../../shared/components/controlled/LocalizedDateRangePicker';
import { statusAvailables } from '../../../../../../shared/constants/resourceStatus';
import useFormatDate from '../../../../../../shared/hooks/useFormatDate';
import { ProjectTask } from '../../../../interfaces/tasks.interfaces';
import {
  useAddTaskToProjectResearchMutation,
  useEditTaskToProjectResearchMutation,
} from '../../../../slices/InvestigationLinesApiSlice';
import { taskValidationSchema } from '../constants/validationSchemas';

interface TaskFormProps {
  taskToEdit?: ProjectTask;
}

const TaskForm = ({ taskToEdit }: TaskFormProps) => {
  const { projectId } = useParams<{ projectId: string }>();

  const [dates, setDates] = useState<DateRange<Date>>([null, null]);
  const [showAlert, setShowAlert] = useState(false);

  const { formatDate } = useFormatDate();

  const [
    addTask,
    { isLoading: isAddingTask, isError: isErrorAdd, isSuccess: isSuccessAdd, error: errorAdd },
  ] = useAddTaskToProjectResearchMutation();
  const [
    editTask,
    {
      isLoading: isUpdatingTask,
      isError: isErrorUpdate,
      isSuccess: isSuccessUpdate,
      error: errorUpdate,
    },
  ] = useEditTaskToProjectResearchMutation();

  const { getFieldProps, handleSubmit, errors, setFieldValue, touched, values } = useFormik({
    initialValues: {
      title: taskToEdit?.tas_title || '',
      observation: taskToEdit?.tas_observation || '',
      startDate: taskToEdit?.tas_start_date ? parseISO(taskToEdit.tas_start_date) : null!,
      endDate: taskToEdit?.tas_end_date ? parseISO(taskToEdit.tas_end_date) : null!,
      budget: taskToEdit?.tas_budget ? Number(taskToEdit?.tas_budget) : 0,
      numberHours: taskToEdit?.tas_number_hours ? Number(taskToEdit?.tas_number_hours) : 0,
      statusId: taskToEdit?.status_id ? Number(taskToEdit?.status_id) : 1,
    },
    validationSchema: taskValidationSchema,
    onSubmit: async (_values) => {
      if (taskToEdit) {
        await editTask({
          taskId: taskToEdit.id,
          title: _values.title,
          observation: _values.observation,
          startDate: formatDate(_values.startDate, { format: 'yyyy-MM-dd' }),
          endDate: formatDate(_values.endDate, { format: 'yyyy-MM-dd' }),
          budget: _values.budget,
          numberHours: _values.numberHours,
          statusId: _values.statusId,
          projectId,
        });
      } else {
        await addTask({
          title: _values.title,
          observation: _values.observation,
          startDate: formatDate(_values.startDate, { format: 'yyyy-MM-dd' }),
          endDate: formatDate(_values.endDate, { format: 'yyyy-MM-dd' }),
          budget: _values.budget,
          numberHours: _values.numberHours,
          statusId: _values.statusId,
          projectId,
        });
      }

      setShowAlert(true);
    },
  });

  const onChangeDates = (newDatesValues: DateRange<Date>) => {
    setDates(newDatesValues);
    setFieldValue('startDate', newDatesValues[0] || null);
    setFieldValue('endDate', newDatesValues[1] || null);
  };

  useEffect(() => {
    if (taskToEdit) {
      const range = [
        taskToEdit?.tas_start_date ? parseISO(taskToEdit?.tas_start_date) : null,
        taskToEdit?.tas_end_date ? parseISO(taskToEdit?.tas_end_date) : null,
      ];
      setDates(range as DateRange<Date>);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        {isErrorAdd && showAlert && (
          <Grid item xs={12}>
            <ErrorAlert message={errorAdd as string} handleDismiss={() => setShowAlert(false)} />
          </Grid>
        )}

        {isSuccessAdd && showAlert && (
          <Grid item xs={12}>
            <SuccessAlert
              message="Tarea agregada correctamente"
              handleDismiss={() => setShowAlert(false)}
            />
          </Grid>
        )}

        {isErrorUpdate && showAlert && (
          <Grid item xs={12}>
            <ErrorAlert message={errorUpdate as string} handleDismiss={() => setShowAlert(false)} />
          </Grid>
        )}

        {isSuccessUpdate && showAlert && (
          <Grid item xs={12}>
            <SuccessAlert
              message="Tarea actualizada correctamente"
              handleDismiss={() => setShowAlert(false)}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <InputLabel htmlFor="title">Título</InputLabel>
          <TextField
            {...getFieldProps('title')}
            placeholder="Ingrese el título"
            error={Boolean(errors.title && touched.title)}
            fullWidth
          />
          {errors.title && touched.title && <FormHelperText error>{errors.title}</FormHelperText>}
        </Grid>
        <Grid item xs={12}>
          <InputLabel htmlFor="observation">Observación</InputLabel>
          <TextField
            {...getFieldProps('observation')}
            error={Boolean(errors.observation && touched.observation)}
            placeholder="Ingrese la observación"
            fullWidth
          />
          {errors.observation && touched.observation && (
            <FormHelperText error>{errors.observation}</FormHelperText>
          )}
        </Grid>
        <Grid item xs={12}>
          <InputLabel htmlFor="budget">Presupuesto</InputLabel>
          <TextField
            {...getFieldProps('budget')}
            placeholder="Ingrese el presupuesto"
            error={Boolean(errors.budget && touched.budget)}
            fullWidth
            inputProps={{
              maxLength: 10,
            }}
          />
          {errors.budget && touched.budget && (
            <FormHelperText error>{errors.budget}</FormHelperText>
          )}
        </Grid>
        <Grid item xs={12}>
          <InputLabel htmlFor="numberHours">Número de horas</InputLabel>
          <TextField
            {...getFieldProps('numberHours')}
            error={Boolean(errors.numberHours && touched.numberHours)}
            placeholder="Ingrese el número de horas"
            fullWidth
            inputProps={{
              maxLength: 3,
            }}
          />
          {errors.numberHours && touched.numberHours && (
            <FormHelperText error>{errors.numberHours}</FormHelperText>
          )}
        </Grid>
        <Grid item xs={12}>
          <LocalizedDateRangePicker
            value={dates}
            onChange={onChangeDates}
            startError={
              Boolean(touched.startDate && errors.startDate)
                ? (errors.startDate as string)
                : undefined
            }
            endError={
              Boolean(touched.endDate && errors.endDate) ? (errors.endDate as string) : undefined
            }
            doubleRow
            showInputLabel
            hideLabel
            startFormikProps={getFieldProps('startDate')}
            endFormikProps={getFieldProps('endDate')}
          />
        </Grid>

        <Grid item xs={12}>
          <InputLabel>Estado</InputLabel>
          <Autocomplete
            options={statusAvailables}
            noOptionsText="No hay opciones"
            getOptionLabel={(option) => option.name || ''}
            value={
              statusAvailables?.find((statusAvailable) => statusAvailable.id === values.statusId) ||
              null
            }
            // defaultValue={statusAvailables[0]}
            onChange={(e, value) => setFieldValue('statusId', value?.id || null)}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Seleccione un estado"
                error={Boolean(errors.statusId && touched.statusId)}
              />
            )}
            fullWidth
            clearIcon={null}
          />
          {errors.statusId && touched.statusId && (
            <FormHelperText error>{errors.statusId}</FormHelperText>
          )}
        </Grid>
        <Grid item xs={12}>
          <LoadingButton
            type="submit"
            variant="contained"
            color="primary"
            isLoading={isAddingTask || isUpdatingTask}
            title="Guardar"
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default TaskForm;
