import { Grid, Typography } from '@material-ui/core';
import { useAppSelector } from '../../../../hooks/useRedux';
import MainCard from '../../../shared/components/cards/MainCard';

import DeletePeriodDialog from '../components/delete/DeletePeriodDialog';
import PeriodsTable from '../components/list/PeriodsTable';
import { useTranslation } from 'react-i18next';

const PeriodsScreen = () => {
  const { t } = useTranslation();

  const { periodIdToDelete } = useAppSelector((state) => state.periods);

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">{t('academic.periods_list.title')}</Typography>
      </Grid>

      <PeriodsTable />

      {periodIdToDelete && <DeletePeriodDialog />}
    </Grid>
  );
};

export default PeriodsScreen;
