import { FC, useEffect, useState } from 'react';

import { Button, CircularProgress, FormHelperText, Grid, TextField } from '@material-ui/core';
import { useFormik } from 'formik';

import { useAppSelector } from '../../../../../hooks/useRedux';
import ErrorAlert from '../../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../../shared/components/alerts/SuccessAlert';
import InputLabel from '../../../../shared/components/forms/InputLabel';
import { AddNewSubjectInstitute } from '../../constants/validationSchemas';
import { useAddInstituteSubjectMutation } from '../../../institutes/slices/institutesApiSlice';
import { useTranslation } from 'react-i18next';

interface Props {
  onCancel: () => void;
}

const AddInstituteSubject: FC<Props> = (props) => {
  const { t } = useTranslation();

  const { onCancel } = props;

  const { currentProfile } = useAppSelector((state) => state.access);
  const { instituteIdSubject } = useAppSelector((state) => state.homologations);

  const [showAlerts, setShowAlerts] = useState(true);

  const [addSubjectInstitute, { isLoading, isError, error, isSuccess }] =
    useAddInstituteSubjectMutation();

  const { getFieldProps, errors, touched, handleSubmit, resetForm } = useFormik({
    initialValues: {
      name: '',
      description: '',
      status_id: 1,
    },
    validationSchema: AddNewSubjectInstitute,
    onSubmit: async (values) => {
      await addSubjectInstitute({
        profileId: currentProfile?.profileId!,
        instituteSubjectPayload: {
          institute_id: instituteIdSubject!.toString(),
          name: values.name,
          description: values.description,
          status_id: values.status_id.toString(),
        },
      });

      setShowAlerts(true);
    },
  });

  useEffect(() => {
    if (isSuccess) resetForm();
  }, [resetForm, isSuccess]);

  const handleClose = () => {
    resetForm();
    onCancel();
  };

  return (
    <Grid container direction="column" gap={2}>
      <form onSubmit={handleSubmit}>
        <Grid item>
          {isError && showAlerts && (
            <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
          )}

          {isSuccess && showAlerts && (
            <SuccessAlert
              message={t('academic.homologation.external.side.add_subject.success')}
              handleDismiss={() => setShowAlerts(false)}
            />
          )}
        </Grid>

        <Grid item container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <InputLabel>{t('academic.homologation.external.side.add_subject.name')}</InputLabel>
            <TextField
              fullWidth
              error={Boolean(errors.name && touched.name)}
              placeholder={t('academic.homologation.external.side.add_subject.name.placeholder')}
              {...getFieldProps('name')}
            />
            {errors.name && touched.name && <FormHelperText error>{t(errors.name)}</FormHelperText>}
          </Grid>

          <Grid item xs={12}>
            <InputLabel>
              {t('academic.homologation.external.side.add_subject.description')}
            </InputLabel>
            <TextField
              fullWidth
              multiline
              rows={3}
              error={Boolean(errors.description && touched.description)}
              placeholder={t(
                'academic.homologation.external.side.add_subject.description.placeholder'
              )}
              {...getFieldProps('description')}
            />
            {errors.description && touched.description && (
              <FormHelperText error>{t(errors.description)}</FormHelperText>
            )}
          </Grid>
        </Grid>

        <Grid
          item
          container
          alignItems="center"
          justifyContent="flex-end"
          spacing={2}
          sx={{ mt: 1 }}
        >
          <Grid item>
            <Button variant="outlined" size="large" color="primary" onClick={handleClose}>
              {t('academic.homologation.external.side.add_subject.cancel')}
            </Button>
          </Grid>

          <Grid item>
            <Button
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              disabled={isLoading}
              endIcon={isLoading && <CircularProgress size={20} />}
            >
              {t('academic.homologation.external.side.add_subject.add')}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

export default AddInstituteSubject;
