import { Grid } from '@material-ui/core';
import SubCard from '../../../shared/components/cards/SubCard';
import SearchPaginatedTable from '../../../shared/components/tables/SearchPaginatedTable';
import { useGetServicesCompanyQuery } from '../slices/companiesApiSlice';
import { useTranslation } from 'react-i18next';
import { useTableSearch } from '../../../../hooks/useTableSearch';
import { useAppSelector, useAppDispatch } from '../../../../hooks/useRedux';
import {
  setServicesCompanyIdToDelete,
  setServicesDeleteModalOpen,
  updateServicesCompanyTableStates,
} from '../slices/companiesSlice';
import EditButton from '../../../shared/components/tables/buttons/EditButton';
import DeleteButton from '../../../shared/components/tables/buttons/DeleteButton';
import AddButton from '../../../shared/components/tables/buttons/AddButton';
import { useHistory } from 'react-router-dom';

const ServicesCompanies = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const history = useHistory();

  const {
    servicesCompanyTable: { page, perPage, search },
  } = useAppSelector((state) => state.companies);

  const { setPage, setPerPage, setSearch } = useTableSearch({
    page,
    perPage,
    search,
    updateFunction: updateServicesCompanyTableStates,
  });

  const { data, isLoading, isFetching, error } = useGetServicesCompanyQuery({
    page,
    perPage,
    search,
    type_sort: 'desc',
  });

  const dataFetch =
    data?.data.map((item, idx) => ({
      id: (page - 1) * perPage + (idx + 1),
      name: item.service,
      description: item.description,
      direction: item.company.co_direction,
      options: item,
    })) || [];

  const goToAdd = () => {
    history.push('/administrar-companias/agregar-servicio');
  };
  const handleEdit = (id: number | string) => {
    history.push(`/administrar-companias/${id}/editar`);
  };
  const handleDelete = (id: number | string) => {
    dispatch(setServicesDeleteModalOpen(true));
    dispatch(setServicesCompanyIdToDelete(id));
  };

  return (
    <Grid
      item
      xs={12}
      sx={{
        width: '100%',
      }}
    >
      <SubCard content={false} title="Servicios de Compañía">
        <Grid container p={2}>
          <Grid item xs={12}>
            <SearchPaginatedTable
              data={dataFetch!}
              headers={{
                id: 'N°',
                name: 'Nombre',
                description: 'Descripción',
                direction: 'Dirección',
                options: 'Opciones',
              }}
              total={data?.total! || 0}
              keyExtractor={(item) => item.id.toString()}
              isLoading={isLoading}
              isFetching={isFetching}
              page={page}
              setPage={setPage}
              perPage={perPage}
              setPerPage={setPerPage}
              search={search}
              setSearch={setSearch}
              searchPlacehoder={t('studentVirtualClassroom.test.search')}
              error={error}
              numHeader={7}
              ActionButtons={<AddButton size="large" onClick={goToAdd} />}
              customRenderers={{
                options: ({ options }) => (
                  <Grid container spacing={2} justifyContent="center">
                    <EditButton handleEdit={() => handleEdit(options.id!)} />
                    <DeleteButton handleDelete={() => handleDelete(options.id!)} />
                  </Grid>
                ),
              }}
            />
          </Grid>
        </Grid>
      </SubCard>
    </Grid>
  );
};

export default ServicesCompanies;
