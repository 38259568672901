import Accordion from '../../../../shared/components/extended/Accordion';
import { MatterMesh } from '../../../../maintenance/meshs/interfaces/curriculum.interfaces';
import { getCurrcilumPeriods, getCurriculumByArea } from '../../helpers';
import { SubjectCurriculumStatus } from '../../interfaces/subjects-approveds';

interface Props {
  subjectCurriculums?: MatterMesh[];
  subjectCurriculumsStatus: SubjectCurriculumStatus[];
  curriculum_new: boolean;
}

const AccordionSubjects = ({
  subjectCurriculums,
  subjectCurriculumsStatus,
  curriculum_new,
}: Props) => {
  return (
    <Accordion
      containerChild={{
        sx: {
          width: '100%',
        },
      }}
      data={
        subjectCurriculums && subjectCurriculumsStatus
          ? curriculum_new
            ? getCurrcilumPeriods(subjectCurriculums, subjectCurriculumsStatus!)
            : getCurriculumByArea(subjectCurriculums, subjectCurriculumsStatus!)
          : []
      }
    />
  );
};

export default AccordionSubjects;
