import * as Yup from 'yup';

export const AssignCoursesPostgrade = Yup.object().shape({
  studentId: Yup.string().required('El estudiante es requerido.'),
  subjectId: Yup.string().required('La materia es requerida.'),
  programId: Yup.string().required('El programa es requerido.'),
  assignedCourse: Yup.string().required('El curso ha asignar es requerido.'),

  date: Yup.date().required('La fecha es requerida.'),
});
