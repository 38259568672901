import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Student, StudentToPost } from '../interfaces/students.interface';
import { StudentToEdit } from '../interfaces/student-edit.interfaces';
import { ISearchTable } from '../../../shared/interfaces/paginatedTable.interface';
import { UpdateStudentRecordAcademicPayload } from '../interfaces/editStudentRecordAcademic.interfaces';
import { PersonJob, StudentRecordAcademic } from '../interfaces/studentRecordAcademic.interfaces';

interface AdvancedFilters {
  typeInstituteId: number | null;
  instituteId: number | null;

  educationLevelId: number | null;
  typeStudentId: number | null;

  statusId: number | null;

  entryTypeId: number | null;
}
interface StudentsState {
  studentsList: Student[];
  studentRecordAcademic?: StudentRecordAcademic;
  studentDataPost?: StudentToPost | undefined;
  studentDataEdit?: StudentToEdit | undefined;
  studentRecordAcademicDataEdit?: UpdateStudentRecordAcademicPayload;
  deleteModalOpen: boolean;
  studentCurrentCourse?: number;
  studentIdToDelete?: number;
  studentIdToAddAvatar?: number;
  studentAddUser: boolean;
  studentImgToAddAvatar?: string;
  avatarModalOpen: boolean;
  studentsTable: ISearchTable;
  offerId?: number;
  isPaid?: boolean;
  studentTableAdvancedFilters: AdvancedFilters;

  studentJobIdSelected: number | null;
  studentJobsList: PersonJob[];
}

interface StudentImgDialog {
  studentIdToAddAvatar?: number;
  studentImgToAddAvatar?: string;
}

const initialState: StudentsState = {
  studentsList: [],
  avatarModalOpen: false,
  studentAddUser: false,
  deleteModalOpen: false,
  studentsTable: {
    page: 1,
    perPage: 5,
    search: '',
    filterArrow: {
      name: 'p.pers_full_name',
      direction: 'asc',
    },
  },
  isPaid: false,
  studentTableAdvancedFilters: {
    typeInstituteId: null,
    instituteId: null,
    educationLevelId: null,
    typeStudentId: null,
    entryTypeId: null,
    statusId: null,
  },

  studentJobIdSelected: null,
  studentJobsList: [],
};

const studentsSlice = createSlice({
  name: 'students',
  initialState,
  reducers: {
    setStudentsList: (state, action: PayloadAction<Student[]>) => {
      state.studentsList = action.payload;
    },
    setStudentRecordAcademic: (state, action: PayloadAction<StudentRecordAcademic>) => {
      state.studentRecordAcademic = action.payload;
    },
    setStudentsDataPost: (state, action: PayloadAction<StudentToPost | undefined>) => {
      state.studentDataPost = action.payload;
    },
    setStudentDataEdit: (state, action: PayloadAction<StudentToEdit | undefined>) => {
      state.studentDataEdit = action.payload;
    },
    resetStudentsDataPost: (state) => {
      state.studentDataPost = undefined;
    },
    setStudentRecordAcademicDataToEdit: (
      state,
      action: PayloadAction<UpdateStudentRecordAcademicPayload | undefined>
    ) => {
      state.studentRecordAcademicDataEdit = action.payload;
    },
    setDeleteModalOpen: (state, action: PayloadAction<boolean>) => {
      state.deleteModalOpen = action.payload;
    },
    setSudentCurrentCourse: (state, action: PayloadAction<number>) => {
      state.studentCurrentCourse = action.payload;
    },
    openDeleteModalWith: (state, action: PayloadAction<number>) => {
      state.studentIdToDelete = action.payload;
      state.deleteModalOpen = true;
    },
    openModalAddUser: (state, action: PayloadAction<boolean>) => {
      state.studentAddUser = action.payload;
    },
    openAddAvatarWith: (state, action: PayloadAction<StudentImgDialog>) => {
      state.studentIdToAddAvatar = action.payload.studentIdToAddAvatar;
      state.studentImgToAddAvatar = action.payload.studentImgToAddAvatar;
      state.avatarModalOpen = true;
    },
    setModalAddAvatar: (state, action) => {
      state.avatarModalOpen = action.payload;
    },
    resetStudentIdToDelete: (state) => {
      state.studentIdToDelete = undefined;
    },
    resetStudenToAddAvatar: (state) => {
      state.studentIdToAddAvatar = undefined;
      state.studentImgToAddAvatar = undefined;
    },
    updateStudentsTable: (state, action: PayloadAction<ISearchTable>) => {
      state.studentsTable = action.payload;
    },
    setOfferId: (state, action: PayloadAction<number | undefined>) => {
      state.offerId = action.payload;
    },
    setIsPaid: (state, action: PayloadAction<boolean>) => {
      state.isPaid = action.payload;
    },
    setStudentTableAdvancedFilters: (state, action: PayloadAction<Partial<AdvancedFilters>>) => {
      state.studentTableAdvancedFilters = {
        ...state.studentTableAdvancedFilters,
        ...action.payload,
      };
    },

    setStudentJobIdSelected: (state, action: PayloadAction<number | null>) => {
      state.studentJobIdSelected = action.payload;
    },
    setStudentJobsList: (state, action: PayloadAction<PersonJob[]>) => {
      state.studentJobsList = action.payload;
    },
  },
});

export const {
  setStudentsList,
  setDeleteModalOpen,
  openDeleteModalWith,
  resetStudentIdToDelete,
  resetStudentsDataPost,
  openAddAvatarWith,
  setStudentDataEdit,
  openModalAddUser,
  setStudentsDataPost,
  setSudentCurrentCourse,
  resetStudenToAddAvatar,
  setModalAddAvatar,
  updateStudentsTable,
  setOfferId,
  setIsPaid,
  setStudentRecordAcademicDataToEdit,
  setStudentRecordAcademic,
  setStudentTableAdvancedFilters,
  setStudentJobIdSelected,
  setStudentJobsList,
} = studentsSlice.actions;

export default studentsSlice.reducer;
