import React from 'react';
import TableNoPaginated from '../../../../shared/components/tables/TableNoPaginated';
import { useGetPeriodCoursesQuery } from '../../../courses/slices/courseApiSlice';
import { Checkbox } from '@material-ui/core';
import { CoursesQualificationAssign } from '../../interfaces/calification-models.interfaces';

interface Props {
  periodId: number;
  controlCourses: {
    selectedCourses: CoursesQualificationAssign[];
    setSelectedCourses: React.Dispatch<React.SetStateAction<CoursesQualificationAssign[]>>;
  };
  onCancel: (e: React.SyntheticEvent) => void;
}

const PeriodCoursesList = ({
  periodId,
  controlCourses: { selectedCourses, setSelectedCourses },
  onCancel,
}: Props) => {
  const { data, error, isFetching, isLoading } = useGetPeriodCoursesQuery({
    periodId: periodId,
  });

  const datafetch = data?.map((item, idx) => {
    return {
      data: { ...item, idx: idx + 1 },
      name: item.course_description,
    };
  });

  return (
    <TableNoPaginated
      data={datafetch || []}
      headers={{
        data: (
          <Checkbox
            checked={datafetch?.length === selectedCourses.length}
            indeterminate={
              datafetch?.length !== selectedCourses.length && selectedCourses.length > 0
            }
            onChange={(e) => {
              if (e.target.checked)
                return setSelectedCourses(
                  datafetch?.map((item) => ({ course_id: item.data.id })) || []
                );

              return setSelectedCourses([]);
            }}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        ) as any,
        name: 'Nombre',
      }}
      keyExtractor={(item) => item.data.id.toString()}
      error={error}
      isFetching={isFetching}
      isLoading={isLoading}
      customRenderers={{
        data: ({ data }) => (
          <Checkbox
            checked={!!selectedCourses.find((item) => item.course_id === data.id)}
            onChange={() => {
              const selected = selectedCourses.find((item) => item.course_id === data.id);
              if (selected) {
                const newselectedCourses = selectedCourses.filter(
                  (item) => item.course_id !== data.id
                );
                setSelectedCourses(newselectedCourses);
              } else {
                setSelectedCourses([...selectedCourses, { course_id: data.id }]);
              }
            }}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        ),
      }}
      customDataCellsProperties={{
        data: {
          align: 'center',
        },
      }}
    />
  );
};

export default PeriodCoursesList;
