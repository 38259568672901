import {
  Grid,
  IconButton,
  makeStyles,
  Slide,
  SlideProps,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../hooks/useRedux';
import ExtendedSideDialog from '../../../shared/components/dialogs/ExtendedSideDialog';
import {
  setDocumentsBinnancleDialog,
  setBinnacleSelected,
} from '../slices/followUpOfResearchProjectSlice';
import DocumentsBinnancle from './DocumentsBinnancle';

const useStyles = makeStyles((theme) => ({
  userAddDialog: {
    '&>div:nth-child(3)': {
      justifyContent: 'flex-end',
      '&>div': {
        margin: '0px',
        borderRadius: '0px',
        maxWidth: '450px',
        maxHeight: '100%',
      },
    },
  },
  paper: {
    minHeight: '100vh',
    width: '100%',
  },

  card: {
    backgroundColor: theme.palette.primary.light,
    borderRadius: '20px',
  },
}));

const Transition = forwardRef(function Transition(props: SlideProps, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});
const DocumentsBinnancleDialog = () => {
  const { t } = useTranslation();

  const classes = useStyles();
  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(setBinnacleSelected(null));
    dispatch(setDocumentsBinnancleDialog(false));
  };

  return (
    <ExtendedSideDialog
      open
      keepMounted
      className={classes.userAddDialog}
      classes={{
        paper: classes.paper,
      }}
      TransitionComponent={Transition}
    >
      <Grid container p={2}>
        <Grid item container xs={12} alignItems="center">
          <Tooltip title={t('academic.students_graduate.graduate.back')}>
            <IconButton onClick={handleClose}>
              <ArrowBack />
            </IconButton>
          </Tooltip>
          <Typography variant="h3">{t('Documentos')}</Typography>
        </Grid>
        <Grid item xs={12}>
          <DocumentsBinnancle />
        </Grid>
      </Grid>
    </ExtendedSideDialog>
  );
};

export default DocumentsBinnancleDialog;
