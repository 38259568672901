import { useEffect, useState } from 'react';
import {
  Autocomplete,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
  /* Radio,
    RadioGroup,
    FormLabel,
    FormControl, */
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useHistory, useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../../hooks/useRedux';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import { useFormStyles } from '../constants/styles';
import { statusAvailables } from '../../../shared/constants/resourceStatus';
import { useGetProductByIdQuery } from '../slices/productApiSlice';
import { productEditSchema } from '../constants/validationSchemas';
import { useGetUseTypesQuery, useUpdateProductMutation } from '../slices/productApiSlice';
import { useGetAllCategoriesQuery } from '../../maintenance-category/slices/categoryApiSlice';
import SkeletonForm from '../components/skeletons/SkeletonForm';

const EditForm = () => {
  const { t } = useTranslation();

  const history = useHistory();
  const { productId } = useParams<{ productId: string }>();
  const { currentProfile } = useAppSelector((state) => state.access);
  // const [enablePorcentaje, setEnablePorcentaje] = useState(false);

  const [intgrConti, setIntgrConti] = useState(false);
  const [impuesto, setImpuesto] = useState(false);

  const handleChangeIntCont = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIntgrConti(event.target.checked);
  };

  const handleChangeImpuesto = (event: React.ChangeEvent<HTMLInputElement>) => {
    setImpuesto(event.target.checked);
  };

  //product data
  const { data: product, isLoading: loadingP } = useGetProductByIdQuery({
    productId: Number(productId),
    profileId: Number(currentProfile?.profileId),
  });

  //categories
  const { data: categories, isLoading: loadingCA } = useGetAllCategoriesQuery({
    profileId: currentProfile?.profileId!,
  });

  //use types
  const { data: useTypes, isLoading: loadingUT } = useGetUseTypesQuery({
    profileId: currentProfile?.profileId!,
    acronym: 'TUSO',
  });

  //Filter Tipos de Uso
  /* const useTypesData = useTypes?.children.filter(
        (cat) => {
            let keyword: string = cat.cat_acronym || '';
            if (keyword.includes("TU")) {
                return cat;
            }
        }
    ) */
  const useTypesData = useTypes?.children;

  const [updateProduct, { isLoading: isUpdating, isSuccess, isError, error }] =
    useUpdateProductMutation();

  const { getFieldProps, errors, touched, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      name: product?.pr_description || '',
      categoryId: Number(product?.category_id) || undefined,
      valor_inicial: Number(product?.amount) || 0,
      useTypeId: Number(product?.pr_use) || undefined,
      statusId: Number(product?.status_id) || undefined,
      dia_vencimiento: Number(product?.pr_expiration_day) || 0,
    },
    validationSchema: productEditSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      await updateProduct({
        productId: Number(productId),
        productPayload: {
          pr_description: values.name,
          category_id: values.categoryId,
          amount: values.valor_inicial,
          contifico: intgrConti,
          iva: impuesto,
          ice: impuesto,
          pr_end_date: null,
          pr_start_date: new Date().toISOString().split('T')[0],
          pr_type_formula: null,
          pr_type_frequency: null,
          pr_use: values.useTypeId,
          status_id: values.statusId,
          stock: 0,
          user_profile_id: currentProfile?.profileId!,
          pr_expiration_day: Number(values.dia_vencimiento),
        },
      });

      setShowAlerts(true);
    },
  });

  useEffect(() => {
    if (product) {
      setFieldValue('name', product?.pr_description);
      setFieldValue('valor_inicial', product?.amount);
      //setFieldValue("statusId", product.status_id)
      //setFieldValue("categoryId", product.category.id)
      //setFieldValue("useTypeId", product.type_use.id)
      setIntgrConti(Boolean(product?.contifico_id));
      setImpuesto(Boolean(Number(product?.iva) || Number(product?.ice)));
    }
  }, [product, setFieldValue]);

  const classes = useFormStyles();

  const [showAlerts, setShowAlerts] = useState(false);

  // const handleChangeTypeUse = (val: any) => {
  //     if (val === 396) {
  //         setEnablePorcentaje(true);
  //     } else {
  //         setEnablePorcentaje(false);
  //         setFieldValue('porcentaje', 1);
  //     }
  //     setFieldValue('useTypeId', val || '')
  // };

  // console.log(history, productId, currentProfile, classes, showAlerts, setShowAlerts);

  // console.log(enablePorcentaje, product)

  return (
    <>
      {loadingP || loadingUT || loadingCA ? (
        <SkeletonForm />
      ) : (
        <form onSubmit={handleSubmit}>
          <Grid container direction="column" gap={2}>
            <Grid item>
              {isError && showAlerts && (
                <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
              )}

              {isSuccess && showAlerts && (
                <SuccessAlert
                  message={t('financial.maintenance_prod.edit.success_alert')}
                  handleDismiss={() => setShowAlerts(false)}
                />
              )}
            </Grid>

            <Grid item container spacing={2} alignItems="center">
              <Grid item xs={12} md={6}>
                <InputLabel className={classes.inputLabel}>
                  {t('financial.maintenance_prod.add.name_label')}
                </InputLabel>
                <TextField
                  fullWidth
                  error={Boolean(errors.name && touched.name)}
                  placeholder={t('financial.maintenance_prod.add.name_placeholder')}
                  {...getFieldProps('name')}
                />
                {errors.name && touched.name && (
                  <FormHelperText error>{t(errors.name)}</FormHelperText>
                )}
              </Grid>
            </Grid>

            <Grid item container spacing={2} alignItems="center">
              <Grid item xs={12} md={6}>
                <InputLabel className={classes.inputLabel}>
                  {t('financial.maintenance_prod.add.category_label')}
                </InputLabel>
                {loadingCA ? (
                  <TextField
                    fullWidth
                    placeholder={t('financial.maintenance_prod.add.category_placeholder')}
                  />
                ) : (
                  <Autocomplete
                    options={categories || []}
                    loading={loadingCA}
                    loadingText={t('financial.maintenance_prod.edit.category_loading')}
                    getOptionLabel={(option) => option.cat_name}
                    //defaultValue={categories?.data.at(0)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={t('financial.maintenance_prod.add.category_placeholder')}
                        error={Boolean(errors.categoryId && touched.categoryId)}
                      />
                    )}
                    defaultValue={categories?.find((j) => j.id === product?.category?.id)}
                    onChange={(_, value) => {
                      setFieldValue('categoryId', value?.id || null);
                      console.log(value?.id);
                    }}
                    fullWidth
                    clearIcon={null}
                  />
                )}
                {errors.categoryId && touched.categoryId && (
                  <FormHelperText error>{t(errors.categoryId)}</FormHelperText>
                )}
              </Grid>
            </Grid>

            <Grid item container spacing={2} alignItems="center">
              <Grid item xs={12} md={6}>
                <InputLabel className={classes.inputLabel}>
                  {t('financial.maintenance_prod.add.initial_value_label')}
                </InputLabel>
                <TextField
                  fullWidth
                  error={Boolean(errors.valor_inicial && touched.valor_inicial)}
                  placeholder={t('financial.maintenance_prod.add.initial_value_placeholder')}
                  {...getFieldProps('valor_inicial')}
                />
                {errors.valor_inicial && touched.valor_inicial && (
                  <FormHelperText error>{t(errors.valor_inicial)}</FormHelperText>
                )}
              </Grid>
            </Grid>

            <Grid item xs={12} container spacing={2}>
              <Grid item xs={6}>
                <InputLabel>{t('financial.maintenance_prod.add.expiration_days_label')}</InputLabel>
                <TextField
                  fullWidth
                  // inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                  type="number"
                  error={Boolean(errors.dia_vencimiento && touched.dia_vencimiento)}
                  placeholder={t('financial.maintenance_prod.add.expiration_days_placeholder')}
                  {...getFieldProps('dia_vencimiento')}
                />
                {errors.dia_vencimiento && touched.dia_vencimiento && (
                  <FormHelperText error>{t(errors.dia_vencimiento)}</FormHelperText>
                )}
              </Grid>
            </Grid>

            <Grid item container spacing={2} alignItems="center">
              <Grid item xs={12} md={6}>
                <InputLabel className={classes.inputLabel}>
                  {t('financial.maintenance_prod.add.use_type_label')}
                </InputLabel>
                <Autocomplete
                  options={useTypesData!}
                  getOptionLabel={(option) => option.cat_name}
                  renderInput={(params) => (
                    <TextField
                      placeholder={t('financial.maintenance_prod.add.use_type_placeholder')}
                      {...params}
                      error={Boolean(errors.useTypeId && touched.useTypeId)}
                    />
                  )}
                  defaultValue={useTypesData?.find((j) => j.id === product?.type_use?.id)}
                  /* onChange={(e, value) => setFieldValue('useTypeId', value?.id || '')} */
                  onChange={(_, value) => setFieldValue('useTypeId', value?.id || '')}
                  fullWidth
                  clearIcon={null}
                  loading={loadingUT}
                />
                {errors.useTypeId && touched.useTypeId && (
                  <FormHelperText error>{t(errors.useTypeId)}</FormHelperText>
                )}
              </Grid>
            </Grid>

            <Grid item container spacing={2} alignItems="center">
              <Grid item xs={12} md={6}>
                <InputLabel className={classes.inputLabel}>
                  {t('shared.input.status.label')}
                </InputLabel>
                <Autocomplete
                  options={statusAvailables}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      placeholder={t('shared.input.status.placeholder')}
                      {...params}
                      error={Boolean(errors.statusId && touched.statusId)}
                    />
                  )}
                  //defaultValue={statusAvailables[0]}
                  onChange={(_, value) => setFieldValue('statusId', value?.id || '')}
                  defaultValue={statusAvailables?.find((j) => j.id === product?.status?.id)}
                  fullWidth
                  clearIcon={null}
                />
                {errors.statusId && touched.statusId && (
                  <FormHelperText error>{t(errors.statusId)}</FormHelperText>
                )}
              </Grid>
            </Grid>

            <Grid item xs={4}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={intgrConti}
                      disabled={product?.contifico_id}
                      onChange={handleChangeIntCont}
                    />
                  }
                  label={t('financial.maintenance_prod.add.contifico_integration_label')}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={impuesto}
                      disabled={Boolean(Number(product?.iva) || Number(product?.ice))}
                      onChange={handleChangeImpuesto}
                    />
                  }
                  label={t('financial.maintenance_prod.add.apply_taxe_label')}
                />
              </FormGroup>
            </Grid>

            <Grid
              item
              container
              alignItems="center"
              justifyContent="flex-end"
              spacing={2}
              sx={{ mt: 1 }}
            >
              <Grid item>
                <Button
                  variant="outlined"
                  size="large"
                  color="primary"
                  onClick={() => history.goBack()}
                >
                  {t('shared.go_back_button.label')}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  color="primary"
                  disabled={isUpdating}
                  endIcon={isUpdating && <CircularProgress size={20} />}
                >
                  {t('shared.update_button.label')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}
    </>
  );
};

export default EditForm;
