import { mainApi } from '../../../../api/mainApi';
import { middlewareApi } from '../../../middleware/middlewareApi';
import { TermsConditionsResponse } from '../interfaces/terms-conditions.interface';

export const institutionalInfoTags = middlewareApi.enhanceEndpoints({
  addTagTypes: [
    'institutionalInfo',
  ],
});
// Define a service using a base URL and expected endpoints
export const institutionalInfoApi = institutionalInfoTags.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * @GET terms and conditions
     */
    getTermsAndConditions: builder.query<TermsConditionsResponse, {
      user_profile_id: number;
    }>({
      queryFn: async ({ user_profile_id }) => {
        try {
          const { data } = await mainApi.get<TermsConditionsResponse>('legal-papers-list-files', {
            params: {
              user_profile_id
            }
          })

          return { data }
        } catch (error: any) {
          return { error }
        }
      }
    }),
    /**
     * @GET terms and conditionsd default
     */
     getTermsAndConditionsDefault: builder.query<any, {
        user_profile_id: number;
        name: string;
      }>({
        queryFn: async ({ user_profile_id, name }) => {
          try {
            const { data } = await mainApi.get<any>('legal-papers-list-files-defaults', {
              params: {
                user_profile_id,
                name
              }, 
              responseType: 'arraybuffer'
            })
  
            return { data }
          } catch (error: any) {
            return { error }
          }
        }
      }),
      /**
     * @GET terms and conditionsd default
     */
     getTermsAndConditionsFile: builder.query<any, {
        user_profile_id: number;
        name: string;
      }>({
        queryFn: async ({ user_profile_id, name }) => {
          try {
            const { data } = await mainApi.get<any>('show', {
              params: {
                user_profile_id,
                name
              }, 
              responseType: 'arraybuffer'
            })
  
            return { data }
          } catch (error: any) {
            return { error }
          }
        }
      }),
  }),
});

export const {
  useGetTermsAndConditionsQuery,
  useGetTermsAndConditionsDefaultQuery,
  useGetTermsAndConditionsFileQuery,
} = institutionalInfoApi;
