import { Alert, IconButton, Snackbar } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useState } from 'react';
import TimerAutoRedirect from '../TimerAutoRedirect';

const SuccessAlert = ({
  type = 'snackbar',
  message,
  messageComponent,
  handleDismiss,
  timeoutCallback,
}: {
  message?: string;
  messageComponent?: JSX.Element;
  handleDismiss?: () => void;
  type?: 'fixed' | 'snackbar';
  timeoutCallback?: {
    timer?: number;
    callbackFn: (...args: any) => void;
  };
}) => {
  const [open, setOpen] = useState(true);

  return (
    <>
      {type === 'snackbar' ? (
        <Snackbar
          open={open}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          autoHideDuration={5000}
          sx={{
            left: '60% !important',
          }}
          onClose={() => {
            setOpen(false);
            handleDismiss && handleDismiss();
          }}
        >
          <Alert
            icon={false}
            severity="success"
            action={
              <IconButton size="small" aria-label="close" color="inherit" onClick={handleDismiss}>
                <CloseIcon fontSize="small" />
              </IconButton>
            }
          >
            {message || messageComponent}
            {timeoutCallback && (
              <TimerAutoRedirect
                completeFunction={timeoutCallback.callbackFn}
                // customTimeInterval={timeoutCallback.timer}
                customAddTime={timeoutCallback.timer}
              />
            )}
          </Alert>
        </Snackbar>
      ) : (
        <Alert
          icon={false}
          severity="success"
          action={
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleDismiss}>
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        >
          {message || messageComponent}
          {timeoutCallback && (
            <TimerAutoRedirect
              completeFunction={timeoutCallback.callbackFn}
              // customTimeInterval={timeoutCallback.timer}
              customAddTime={timeoutCallback.timer}
            />
          )}
        </Alert>
      )}
    </>
  );
};

export default SuccessAlert;
