import { EvaluationLimitDate, EvaluationTypeCalc } from '../interfaces/evaluations.interfaces';

export const evaluationTypeCalc: EvaluationTypeCalc[] = [
  { id: 1, value: 'Promedio' },
  { id: 2, value: 'Mediana' },
  { id: 3, value: 'Mejor nota' },
];

export const evaluationLimit: EvaluationLimitDate[] = [
  { id: 0, name: 'Rango de fecha establecida', value: 0 },
  { id: 1, name: 'Fuera de fecha establecida', value: 1 },
];
