import { useMemo, useRef, useState } from 'react';

import {
  Grid,
  Autocomplete,
  FormHelperText,
  TextField,
  Divider,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { useFormik } from 'formik';

import { useAppSelector } from '../../../../../hooks/useRedux';
import ErrorAlert from '../../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../../shared/components/alerts/SuccessAlert';
import { useAddCredentialMutation } from '../../slices/collaboratorsApiSlice';
import InputLabel from '../../../../shared/components/forms/InputLabel';
import { useTranslation } from 'react-i18next';
import { meetingsCredentials } from '../../../../shared/constants/virtualClassroomDict';

interface Props {
  collId: number;
  handleCloseDialog: (e: React.SyntheticEvent) => void;
}

const AssignForm = ({ collId, handleCloseDialog }: Props) => {
  const { t } = useTranslation();

  //alert ref for scroll to view request alert
  const alertRef = useRef<HTMLDivElement>(null);

  const [currProvider, setCurrProvider] = useState(
    meetingsCredentials.length === 1 ? meetingsCredentials[0].keyword : ''
  );

  const [showAlerts, setShowAlerts] = useState(false);
  const { collabList } = useAppSelector((state) => state.collaborators);

  const currentUser = useMemo(
    () => collabList.find((dt) => dt.id === collId),
    [collabList, collId]
  );

  const initialValues = useMemo(() => {
    const currentCred = currentUser?.user.credentials_virtual_meet.find(
      (dt) => dt.provider_credential === currProvider
    );

    return {
      provider_credential: currProvider,
      client_email: currentCred?.client_email || '',
      api_key: currentCred?.api_key || '',
      api_secret: currentCred?.api_secret || '',
      sdk_key: currentCred?.sdk_key || '',
      sdk_secret: currentCred?.sdk_secret || '',
      url_base: currentCred?.url_base || '',
    };
  }, [currProvider, currentUser?.user.credentials_virtual_meet]);

  const [addCredential, { isError, isSuccess, error, isLoading }] = useAddCredentialMutation();

  const { getFieldProps, errors, touched, handleSubmit, values } = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    //   validationSchema: MatterInfoSchema,
    onSubmit: async (values) => {
      await addCredential({
        userId: currentUser?.user.id || 0,
        credPayload: {
          ...values,
        },
      });

      alertRef?.current?.scrollIntoView();
      setShowAlerts(true);
    },
  });

  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction="column" gap={2}>
        <Grid ref={alertRef} item xs={12}>
          {isError && showAlerts && (
            <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
          )}

          {isSuccess && showAlerts && (
            <SuccessAlert
              message={'Las credenciales han sido agregadas correctamente'}
              handleDismiss={() => setShowAlerts(false)}
            />
          )}
        </Grid>

        {/* provider credential */}
        <Grid item xs={12}>
          <InputLabel>Proveedor</InputLabel>
          <Autocomplete
            options={meetingsCredentials}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <TextField
                {...params}
                error={Boolean(errors.provider_credential && touched.provider_credential)}
                placeholder={'Proveedor'}
              />
            )}
            defaultValue={meetingsCredentials.length === 1 ? meetingsCredentials[0] : null}
            onChange={(_, value) => {
              setCurrProvider(value?.keyword || '');
            }}
            fullWidth
            clearIcon={null}
          />
          {errors.provider_credential && touched.provider_credential && (
            <FormHelperText error>{t(errors.provider_credential)}</FormHelperText>
          )}
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        {/* email */}
        <Grid item xs={12}>
          <InputLabel>Correo electrónico</InputLabel>
          <TextField
            type="email"
            {...getFieldProps('client_email')}
            error={Boolean(errors.client_email && touched.client_email)}
            value={values.client_email}
            fullWidth
            placeholder={'Ingrese el correo electrónico'}
          />
          {errors.client_email && touched.client_email && (
            <FormHelperText error>{t(errors.client_email)}</FormHelperText>
          )}
        </Grid>

        {/* api key */}
        <Grid item xs={12}>
          <InputLabel>Api key</InputLabel>
          <TextField
            {...getFieldProps('api_key')}
            error={Boolean(errors.api_key && touched.api_key)}
            value={values.api_key}
            fullWidth
            placeholder={'Ingrese el api key'}
          />
          {errors.api_key && touched.api_key && (
            <FormHelperText error>{t(errors.api_key)}</FormHelperText>
          )}
        </Grid>

        {/* api secret */}
        <Grid item xs={12}>
          <InputLabel>Api secret</InputLabel>
          <TextField
            {...getFieldProps('api_secret')}
            error={Boolean(errors.api_secret && touched.api_secret)}
            value={values.api_secret}
            fullWidth
            placeholder={'Api secret'}
          />
          {errors.api_secret && touched.api_secret && (
            <FormHelperText error>{t(errors.api_secret)}</FormHelperText>
          )}
        </Grid>

        {/* sdk_key */}
        <Grid item xs={12}>
          <InputLabel>Sdk key</InputLabel>
          <TextField
            {...getFieldProps('sdk_key')}
            error={Boolean(errors.sdk_key && touched.sdk_key)}
            value={values.sdk_key}
            fullWidth
            placeholder={'Ingrese el sdk key'}
          />
          {errors.sdk_key && touched.sdk_key && (
            <FormHelperText error>{t(errors.sdk_key)}</FormHelperText>
          )}
        </Grid>

        {/* sdk_secret */}
        <Grid item xs={12}>
          <InputLabel>Sdk secret</InputLabel>
          <TextField
            {...getFieldProps('sdk_secret')}
            error={Boolean(errors.sdk_secret && touched.sdk_secret)}
            value={values.sdk_secret}
            fullWidth
            placeholder={'Ingrese la sdk secret'}
          />
          {errors.sdk_secret && touched.sdk_secret && (
            <FormHelperText error>{t(errors.sdk_secret)}</FormHelperText>
          )}
        </Grid>

        {/* url_base */}
        <Grid item xs={12}>
          <InputLabel>Url base</InputLabel>
          <TextField
            {...getFieldProps('url_base')}
            error={Boolean(errors.url_base && touched.url_base)}
            value={values.url_base}
            fullWidth
            placeholder={'Ingrese la url base'}
          />
          {errors.url_base && touched.url_base && (
            <FormHelperText error>{t(errors.url_base)}</FormHelperText>
          )}
        </Grid>
      </Grid>

      <Grid item container alignItems="center" justifyContent="flex-end" spacing={2} sx={{ mt: 1 }}>
        <Grid item>
          <Button variant="outlined" size="large" color="primary" onClick={handleCloseDialog}>
            {t('shared.cancel_button.label')}
          </Button>
        </Grid>
        <Grid item>
          <Button
            type="submit"
            variant="contained"
            size="large"
            color="primary"
            disabled={isLoading}
            endIcon={isLoading && <CircularProgress size={20} />}
          >
            {t('shared.save_button.label')}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default AssignForm;
