import { Switch, Redirect } from 'react-router-dom';

import { useAppSelector } from '../../../../hooks/useRedux';

import { permissionKey, subPermissionKeys } from '../constants/permissions';
import GuardRoute from '../../../../router/guards/GuardRoute';

import CashPointsScreen from '../screens/CashPointsScreen';
import ReportScreen from '../screens/ReportScreen';
import MoveZendeskStyles from '../../../shared/components/helpers/MoveZendeskStyles';

const EducationLevelsRouter = () => {
  const { currentModule } = useAppSelector((state) => state.access);

  const hasPermission = permissionKey in currentModule!.permissions;

  if (!hasPermission) <Redirect to="/dashboard" />;

  return (
    <>
      <MoveZendeskStyles />
      <Switch>
        <GuardRoute
          exact
          path="/administrar-caja/listar-cajas"
          component={CashPointsScreen}
          permissionKey={permissionKey}
          subPermissionKey={subPermissionKeys.read}
        />
        <GuardRoute
          exact
          path="/administrar-caja/listar-cajas/:cashPointId/reporte"
          component={ReportScreen}
          permissionKey={permissionKey}
          subPermissionKey={subPermissionKeys.read}
        />
      </Switch>
    </>
  );
};

export default EducationLevelsRouter;
