import { useState, useEffect, memo } from 'react';

import { Typography } from '@material-ui/core';

interface Props {
  /** Value in milliseconds */
  initialValue: number;
  /** color text */
  colorText?: string;
}

// TODO
// - Parsear y dar Formato de tiempo para valores muy grandes
// -

/**
 *
 * @param param0 Valor inicial en milisegundos del cronometro
 * @returns texto del tiempo
 */
const Chronometer = ({ initialValue, colorText }: Props) => {
  const [timer, setTimer] = useState<number>(initialValue);

  useEffect(() => {
    const interval = 1000;
    const intervalId = setInterval(() => {
      setTimer((prev) => prev - interval);
      //   if (timer === 0) {
      //     finishEvaluation({
      //       evaluationId: Number(evaluationId),
      //       studentId: currentStudent?.id!,
      //       profileId: currentProfile?.profileId!,
      //     });
      //     clearTimeout(intervalId);
      //     navigateTopSmooth();
      //     setTimeFinished(true);
      //   }
    }, interval);
    return () => {
      clearTimeout(intervalId);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Typography color={colorText} component="span" variant="h5" id="time-evaluation">
      {timer / 1000}
    </Typography>
  );
};

export default memo(Chronometer);
