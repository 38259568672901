import { Alert, IconButton, Typography } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';

import ErrorAlert from '../../../../shared/components/alerts/ErrorAlert';

interface Props {
  isError: boolean;
  errorString: string[];
  setAlertFile: (value: React.SetStateAction<boolean | undefined>) => void;
}

const ErrorAlertStudentPays = ({ isError, errorString, setAlertFile }: Props) => {
  return (
    <>
      {isError && <ErrorAlert message={errorString as unknown as string} />}

      {/* si se quiere mantener el alert al inicio del formulario quitar el false y comentar el ErrorAlert */}
      {false && (
        <Alert
          severity={isError ? 'error' : 'success'}
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => {
                setAlertFile(false);
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        >
          <ul>
            {errorString && (
              <>
                {Array.isArray(errorString) ? (
                  errorString.map((e: string) => {
                    return (
                      <li>
                        <Typography component="p" align="left">
                          {e}
                        </Typography>
                      </li>
                    );
                  })
                ) : (
                  <li>
                    <Typography component="p" align="left">
                      {errorString}
                    </Typography>
                  </li>
                )}
              </>
            )}
          </ul>
        </Alert>
      )}
    </>
  );
};

export default ErrorAlertStudentPays;
