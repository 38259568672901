import { Grid, useTheme } from '@material-ui/core';
import { useEffect } from 'react';
import { useAppDispatch } from '../../../../hooks/useRedux';
import { setIsValidateCalification } from '../slices/virtualClassroomTeacherSlice';
import CourseDetailHeader from './CourseDetailHeader';

const CourseDetailScreen = () => {
  const theme = useTheme();

  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(setIsValidateCalification(undefined));
    };
  }, [dispatch]);

  return (
    <Grid
      sx={{
        bgcolor: theme.palette.secondary[200],
        mb: 0,
        borderEndEndRadius: 0,
        borderEndStartRadius: 0,
        p: '12px 0',
      }}
    >
      <CourseDetailHeader />
    </Grid>
  );
};

export default CourseDetailScreen;
