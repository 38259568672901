import { Switch } from 'react-router-dom';
import GuardRoute from '../../../../router/guards/GuardRoute';
import ConfigProductPriceCreateScreen from '../screens/configProductPriceCreateScreen';
import ConfigProductPriceEditScreen from '../screens/configProductPriceEditScreen';

import ConfigProductPriceListScreen from '../screens/configProductPriceListScreen';

const ConfigProductsRouter = () => {
  // const { currentModule } = useAppSelector((state) => state.access);

  // const hasPermission = 'periods' in currentModule?.permissions!;

  // if (!hasPermission) return <Redirect to="/dashboard" />;

  return (
    <Switch>
      {/* Stages */}
      <GuardRoute
        exact
        path="/administrar-configuracion-productos"
        component={ConfigProductPriceListScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.read}
      />
      <GuardRoute
        exact
        path="/administrar-configuracion-productos/editar/:configProduct"
        component={ConfigProductPriceEditScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.read}
      />
      <GuardRoute
        exact
        path="/administrar-configuracion-productos/agregar"
        component={ConfigProductPriceCreateScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.read}
      />
    </Switch>
  );
};

export default ConfigProductsRouter;
