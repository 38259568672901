import { Grid } from '@material-ui/core';
import { useAppSelector } from '../../../../hooks/useRedux';
import { gridSpacing } from '../../../shared/constants/uiConstants';
// import MisionVision from '../components/MisionVision';
// import FinanceCard from '../components/FinanceCard';
import PrincipalCard from '../components/PrincipalCard';
import SocialCard from '../components/SocialCard';
import { useGetCompanyQuery } from '../slices/companiesApiSlice';
import TabsInformation from '../components/TabsInformation';

const CardsScreen = () => {
  const { currentProfile } = useAppSelector((state) => state.access);

  const { isLoading } = useGetCompanyQuery(
    { profileId: currentProfile?.profileId! },
    { refetchOnMountOrArgChange: 30 }
  );

  return (
    <Grid container spacing={gridSpacing} ml={0} mt={0}>
      <Grid
        item
        lg={4}
        xs={12}
        sx={{
          marginBottom: 5,
        }}
      >
        <Grid container direction="column" spacing={gridSpacing}>
          <PrincipalCard isLoading={isLoading} />

          <SocialCard isLoading={isLoading} />
        </Grid>
      </Grid>
      <TabsInformation isLoading={isLoading} />
      {/* <Grid item lg={8} xs={12}>
        <Grid container direction="column" spacing={gridSpacing}>
          <FinanceCard isLoading={isLoading} />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container direction="column" spacing={gridSpacing}>
          <MisionVision isLoading={isLoading} />
        </Grid>
      </Grid> */}
    </Grid>
  );
};

export default CardsScreen;
