import {
  Autocomplete,
  Button,
  Grid,
  InputLabel,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

import { setIsEditFilters, resetFilters, setStageIdSelected } from '../slices/integralReportsSlice';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/useRedux';
import { useGetAllOpeningDatesByEvaluationQuery } from '../../../maintenance/opening_dates/slices/openingDateApiSlice';
import { locales } from '../../../../../shared/constants/locales';
import useFormatDate from '../../../../../shared/hooks/useFormatDate';

const IntegralFilters = ({ onSubmit }: { onSubmit: () => void }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { formatDate } = useFormatDate();

  const { isEditFilters, stageIdSelected } = useAppSelector((state) => state.integralReports);

  const { data: openingDates, isLoading: isLoadingOD } = useGetAllOpeningDatesByEvaluationQuery({});

  console.log(openingDates);

  const dispatch = useAppDispatch();

  const handleEdit = () => {
    dispatch(setIsEditFilters(true));
  };

  const handleReset = () => {
    dispatch(resetFilters());
  };

  return (
    <>
      {isEditFilters && (
        <>
          <Grid item container xs={12} spacing={2}>
            <Grid item xs={12} md={4}>
              <InputLabel>{t('reports.heteroevaluations.filters.stage')}</InputLabel>
              <Autocomplete
                options={Array.isArray(openingDates) ? openingDates : []}
                loading={isLoadingOD}
                getOptionLabel={(option) =>
                  `${formatDate(option.start_date)} - ${formatDate(option.end_date)}`
                }
                value={openingDates?.find((stage) => stage.uuid === stageIdSelected) || null}
                multiple={false}
                onChange={(event, value) => {
                  dispatch(setStageIdSelected(value?.uuid || ''));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder={t('reports.heteroevaluations.filters.stage.placeholder')}
                  />
                )}
              />
            </Grid>
            {/* <Grid item xs={12} md={4}>
              <InputLabel>{t('reports.heteroevaluations.filters.evaluated')}</InputLabel>
              <Autocomplete
                options={evaluateds}
                getOptionLabel={(option) => option.name}
                value={evaluateds.find((evaluated) => evaluated.uuid === evaluatedIdSelected)}
                multiple={false}
                onChange={(event, value) => {
                  dispatch(setEvaluatedIdSelected(value?.uuid || ''));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder={t('reports.heteroevaluations.filters.evaluated.placeholder')}
                  />
                )}
              />
            </Grid> */}
          </Grid>
          <Grid item container justifyContent="center" xs={12} spacing={2} mt={1}>
            <Grid item>
              <Button
                variant="outlined"
                color="secondary"
                disabled={!stageIdSelected}
                onClick={handleReset}
              >
                {t('reports.heteroevaluations.filters.reset')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                disabled={!stageIdSelected}
                onClick={onSubmit}
              >
                {t('reports.heteroevaluations.filters.search')}
              </Button>
            </Grid>
          </Grid>
        </>
      )}
      {!isEditFilters && (
        <Grid
          item
          container
          xs={12}
          alignItems="center"
          sx={{
            border: `1px solid ${theme.palette.primary.main}`,
            padding: 1,
            borderRadius: '8px',
          }}
        >
          <Grid item container xs={12} md={10}>
            <Grid item sx={{ mr: 2 }}>
              <Typography variant="h6">
                {t('reports.heteroevaluations.filters.stage')}:{' '}
                <strong>
                  {/* xddd */}
                  {`${format(
                    new Date(
                      openingDates?.find((stage) => stage.uuid === stageIdSelected)?.start_date ||
                        Date.now()
                    ),
                    'PPP',
                    {
                      locale: locales[i18next.language],
                    }
                  )} - ${format(
                    new Date(
                      openingDates?.find((stage) => stage.uuid === stageIdSelected)?.end_date ||
                        Date.now()
                    ),
                    'PPP',
                    {
                      locale: locales[i18next.language],
                    }
                  )}`}
                  {/* {openingDates?.find((stage) => stage.uuid === stageIdSelected)?.description} */}
                </strong>
              </Typography>
            </Grid>
            {/* <Grid item sx={{ mr: 2 }}>
              <Typography variant="h6">
                {t('reports.heteroevaluations.filters.evaluated')}:{' '}
                <strong>
                  {evaluateds.find((evaluated) => evaluated.uuid === evaluatedIdSelected)?.name}
                </strong>
              </Typography>
            </Grid> */}
          </Grid>
          <Grid item container xs={12} md={2} alignItems="center" justifyContent="flex-end">
            <Button variant="text" color="primary" onClick={handleEdit} size="small">
              {t('reports.heteroevaluations.filters.edit')}
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default IntegralFilters;
