import { Checkbox, FormControlLabel } from '@material-ui/core'
import { useCallback, useEffect, useState } from 'react';

interface props {
    label: string;
    id: string;
    states: string[];
    setStates: React.Dispatch<React.SetStateAction<string[]>>;
    isChecked: Boolean;
}

const CheckBoxControl = ({ label, setStates, id, states, isChecked }: props) => {
    const [checked, setChecked] = useState(label === 'Enviado' || label === 'Rechazado' || isChecked ? true : false);
    const [disable] = useState(label === 'Enviado' || label === 'Rechazado' ? true : false);

    const addStatus = useCallback(() => {
        if (!checked) {
            setStates(states => [...states, id]);
        } else {
            const arrayTemp = [...states];
            const index = arrayTemp.findIndex(el => el === id);
            arrayTemp.splice(index, 1);
            setStates(arrayTemp);
        }
    }, [checked, id, states, setStates])

    const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
        addStatus();
    }

    useEffect(()=>{
        if (checked) {
            setStates(states => [...states, id]);
        }
    },[]) // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <FormControlLabel control={<Checkbox checked={checked} onChange={handleChange} disabled={disable} />} label={label} />
    )
}

export default CheckBoxControl