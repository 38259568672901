import { useCallback, useEffect, useMemo, useState } from 'react';

import { CircularProgress, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import {
  useGetBusSchedulesWithDetailsQuery,
  useGetRoutesSchedulesQuery,
  useSaveSchedulesOnRoutesMutation,
} from '../../slices/busRouteApiSlice';
import TableNoPaginated from '../../../../shared/components/tables/TableNoPaginated';
import LoadingChildren from '../../../../shared/components/LoadingChildren';
import PortalAction from '../../../../shared/components/PortalAction';
import LoadingButton from '../../../../shared/components/buttons/LoadingButton';
import AccordionWithSelection from '../../../../shared/components/extended/AccordionWithSelection';
import AccordionTitleBusesSchedules from '../builder/AccordionTitleBusesSchedules';

interface Props {
  routeId: number;
}

const AssignSchedules = ({ routeId }: Props) => {
  const { t } = useTranslation();

  const [selection, setSelection] = useState<number[]>([]);

  const handleChange = useCallback((selection: number[]) => {
    setSelection(selection);
  }, []);

  const {
    data: schedulesWithDetails,
    isLoading,
    isFetching,
    error,
  } = useGetBusSchedulesWithDetailsQuery({});

  const [saveSchedules, { isLoading: isLoadingSaving }] = useSaveSchedulesOnRoutesMutation();

  const {
    data: routeSchedules,
    isLoading: isLoadingRouteSchedules,
    // isFetching: isFetchingRouteSchedules,
    // error: errorRouteSchedules,
  } = useGetRoutesSchedulesQuery(
    {
      route: routeId || 0,
    },
    { skip: !routeId }
  );

  useEffect(() => {
    if (routeSchedules) {
      setSelection(routeSchedules.map((item) => item.id));
    }
  }, [routeSchedules]);

  const saveSchedulesHandler = () => {
    try {
      saveSchedules({
        routeId: routeId,
        selection: [...selection],
      });
    } catch (error) {}
  };

  const data = useMemo(() => {
    return schedulesWithDetails?.map((item) => {
      const datafetch = item.bus_schedule_detail?.map((schedule, i) => ({
        id: { id: schedule.id, index: i + 1 },
        hour_departure_go: schedule.hour_departure_go || '--',
        hour_arrival_go: schedule.hour_arrival_go || '--',
        hour_departure_return: schedule.hour_departure_return || '--',
        hour_arrival_return: schedule.hour_arrival_return || '--',
      }));

      return {
        id: item.id.toString(),
        title: <AccordionTitleBusesSchedules title={item.sche_name} urlFile={item.sche_link} />,
        defaultExpand: true,
        content: (
          <TableNoPaginated
            data={datafetch}
            isLoading={isLoading}
            headers={{
              id: t('shared.table.index'),
              hour_departure_go: t('academic.buses.schedules_details.table_default.departure_go'),
              hour_arrival_go: t('academic.buses.schedules_details.table_default.arrival_go'),
              hour_departure_return: t(
                'academic.buses.schedules_details.table_default.departure_return'
              ),
              hour_arrival_return: t(
                'academic.buses.schedules_details.table_default.arrival_return'
              ),
            }}
            isFetching={isFetching}
            error={error}
            keyExtractor={(item) => `bus-schedule-${item.id.id}`}
            customRenderers={{
              id: ({ id }) => <>{id.index}</>,
            }}
            searchPlacehoder={''}
            search={''}
            showFilter={false}
          />
        ),
      };
    });
  }, [error, isFetching, isLoading, schedulesWithDetails, t]);

  return (
    <>
      <PortalAction containerId="save-schedules-buses-routes">
        <LoadingButton
          variant="contained"
          onClick={saveSchedulesHandler}
          isLoading={isLoadingSaving || isLoading || isLoadingRouteSchedules}
        >
          {t('shared.save_button.label')}
        </LoadingButton>
      </PortalAction>

      <LoadingChildren
        isLoading={isLoading || isLoadingRouteSchedules}
        loadingSkeleton={
          <Grid container justifyContent="center">
            <CircularProgress />
          </Grid>
        }
      >
        <AccordionWithSelection
          accordionProps={{ defaultExpanded: true }}
          data={data!}
          defaultSelectoin={selection}
          changeSelection={handleChange}
        />
      </LoadingChildren>
    </>
  );
};

export default AssignSchedules;
