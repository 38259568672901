import { Box } from '@material-ui/core';
import { useMemo, useState } from 'react';
import { SelectTypes } from './SelectTypes';
import {
  useGetAdTypesQuery,
  useGetAdvertisementsQuery,
} from '../../slices/virtualClassroomApiSlice';
import AdvertisementsList from './AdvertisementsList';
import { useAppSelector } from '../../../../../hooks/useRedux';
import CustomPaginationAndItems from '../../../../shared/components/tables/CustomPaginationAndItems';

import SkeletonTable from '../../dashboard/components/SkeletonTable';
import image from '../../../../../assets/images/no-message.png';
import { useTranslation } from 'react-i18next';
import NoData from '../../../../shared/components/NoData';

const AdvertisementsDetails = () => {
  const [adTypesSelected, setAdTypesSelected] = useState<string>('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(5);
  const { t } = useTranslation();

  const { currentStudent } = useAppSelector((state) => state.access);
  const {
    course: { courseId },
  } = useAppSelector((state) => state.virtualClassroomStudentShared);

  const { data: adTypes } = useGetAdTypesQuery({});
  const { data, error, isLoading } = useGetAdvertisementsQuery({
    page,
    size,
    studentId: Number(currentStudent?.id!),
    courseId: Number(courseId!),
    type_ads: adTypesSelected === '' ? undefined : adTypesSelected,
  });

  const items = useMemo(() => {
    if (!adTypes) return [];
    return adTypes.map((item) => {
      return {
        value: item.acronym,
        text: item.name,
      };
    });
  }, [adTypes]);

  return (
    <Box
      sx={{
        minHeight: '350px',
      }}
    >
      {data && data.data.length > 0 ? (
        <>
          <Box
            sx={{
              my: 4,
            }}
          >
            <SelectTypes
              items={items}
              handleChangeTask={setAdTypesSelected}
              valueState={adTypesSelected}
              label={t('studentVirtualClassroom.advertisements.label.select')}
            />
          </Box>
          {!isLoading && data ? (
            <AdvertisementsList advertisements={data.data} />
          ) : (
            <SkeletonTable />
          )}
          <Box
            sx={{
              mt: 4,
            }}
          >
            <CustomPaginationAndItems
              error={error}
              isLoading={isLoading}
              page={page}
              perPage={size}
              setPage={setPage}
              setPerPage={setSize}
              total={data?.total || 0}
              perPageBox={true}
              paginationBox={true}
            />
          </Box>
        </>
      ) : (
        <NoData
          title={t('studentVirtualClassroom.advertisements.nodata.title')}
          subtitle={t('studentVirtualClassroom.advertisements.nodata.description')}
          image={image}
        />
      )}
    </Box>
  );
};

export default AdvertisementsDetails;
