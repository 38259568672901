import React from 'react';

import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';

import MainCard from '../../../../shared/components/cards/MainCard';
import MeetingsForm from '../components/MeetingsForm';
import BackButton from '../../../../shared/components/buttons/BackButton';

const AddMeetingsScreen = function () {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Grid
        component={MainCard}
        sx={{
          borderTopLeftRadius: '0px',
          borderTopRightRadius: '0px',
        }}
      >
        <Grid sx={{ mb: 3 }} container spacing={1} alignItems="center">
          <BackButton />
          <Typography variant="h2">{t('teacher_virtual_classroom.meetings.add.title')}</Typography>
        </Grid>

        <MeetingsForm />
      </Grid>
    </React.Fragment>
  );
};

export default AddMeetingsScreen;
