export const permissionKey = 'permissions';

export const subPermissionKeys = {
  groupKey: 'permissions',

  read: 'permissions-listar-permisos',
  create: 'permissions-crear-permiso',
  edit: 'permissions-actualizar-permiso',
  delete: 'permissions-borrar-permiso',
  readPeriods: 'periods-listar-periodos',
  readModels: 'list-califications-model-period',
  //falta el permiso del filtro de courses
  readCourses: 'list-courses-model-period',
  readCollaborators: 'collaborators-listar-colaborador',
};
