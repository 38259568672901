import * as yup from 'yup';

export const questionFormSchema = yup.object().shape({
  name: yup.string().required('El nombre es requerido'),
  description: yup.string(),
  // scope
  category_uuid: yup
    .string()
    .notOneOf(['', null, undefined], 'maintenance.questions.form.scopes.required')
    .required('maintenance.questions.form.scopes.required'),
  // options template
  que_type_uuid: yup
    .string(),
  // options template
  status: yup
    .string()
    .notOneOf(['', null, undefined], 'maintenance.questions.form.options_template.required')
    .required('maintenance.questions.form.options_template.required'),
});
