import React, { useState } from 'react';
import { useAppSelector } from '../../../../../hooks/useRedux';
import { useGetAcademicPortfolioPartnertQuery } from '../../slices/AcademicPortfolioApiSlice';
import PartnerCards from '../cards-pagination/ParnertsCards';

const MatterDetailPartner = function () {
  const [page, setPage] = useState(1);

  const [perPage, setPerPage] = useState(5);

  const [search, setSearch] = useState<string>('');

  const { currentProfile } = useAppSelector((state) => state.access);

  const { studentCurrentCourse } = useAppSelector((state) => state.students);

  const { data, isLoading, isFetching, error } = useGetAcademicPortfolioPartnertQuery({
    course: studentCurrentCourse!,
    profileId: currentProfile?.profileId!,
  });

  const dataFetch = data?.map((value) => ({
    partner_id: value.student_record.student.id,
    partner_name: value.student_record.student.user.us_username,
    partner_email: value.student_record.student.user.email,
    partner_image: value.student_record.student.stud_photo_path,
  }));

  const sendDataOrFilter = function () {
    if (search !== '') {
      return dataFetch!
        .filter((value) => value.partner_name.toLocaleLowerCase().includes(search))
        .sort()!;
    }
    return dataFetch!;
  };

  return (
    <React.Fragment>
      <PartnerCards
        data={sendDataOrFilter()}
        isFetching={isFetching}
        error={error}
        isLoading={isLoading}
        page={page}
        search={search}
        setSearch={setSearch}
        perPage={perPage}
        setPage={setPage}
        setPerPage={setPerPage}
        keyExtractor={(item) => item.partner_id.toString()}
      />
    </React.Fragment>
  );
};

export default MatterDetailPartner;
