import enTranslations from './en';
import esTranslations from './es';

const resources = {
  en: {
    translation: {
      ...enTranslations,
    },
  },
  es: {
    translation: {
      ...esTranslations,
    },
  },
};

export default resources;
