import { Switch, Redirect } from 'react-router-dom';

import { useAppSelector } from '../../../../hooks/useRedux';

//import { permissionKey, subPermissionKeys } from '../constants/permissions';
import GuardRoute from '../../../../router/guards/GuardRoute';

import RefundsScreen from '../screens/RefunsScreen';
import AddRefundScreen from '../screens/AddRefundScreen';
import LendsDelayScreen from '../screens/LendsDelayScreen';

const RefundsRouter = () => {
  const { currentModule } = useAppSelector((state) => state.access);

  const hasPermission = 'borrows' in currentModule?.permissions!;

  if (!hasPermission) return <Redirect to="/dashboard" />;

  return (
    <Switch>
      <GuardRoute
        exact
        path="/administrar-devoluciones"
        component={RefundsScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.create}
      />
      <GuardRoute
        exact
        path="/administrar-devoluciones/agregar"
        component={AddRefundScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.create}
      />
      <GuardRoute
        exact
        path="/administrar-prestamos-atrasados"
        component={LendsDelayScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.create}
      />
      {/* 
            
            <GuardRoute
                exact
                path="/administrar-prestamos/agregar"
                component={AddLendScreen}
                permissionKey={permissionKey}
                subPermissionKey={subPermissionKeys.create}
            /> */}
      {/* 
            <GuardRoute
                exact
                path="/administrar-autores/editar"
                component={EditAuthorScreen}
                permissionKey={permissionKey}
                subPermissionKey={subPermissionKeys.create}
            /> */}
    </Switch>
  );
};

export default RefundsRouter;
