import { Grid, IconButton, Typography, useTheme } from '@material-ui/core';
import { IconArrowNarrowLeft } from '@tabler/icons';
import { useHistory } from 'react-router-dom';
import MainCard from '../../../shared/components/cards/MainCard';
import AddOtheForm from '../components/AddOtherForm';

const AddOtherScreen = () => {
  const theme = useTheme();
  const history = useHistory();
  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid item container alignItems="center" gap={1} sx={{ cursor: 'pointer' }}>
        <IconButton onClick={() => history.goBack()}>
          <IconArrowNarrowLeft size={30} color={theme.palette.text.dark} />
        </IconButton>
        <Typography variant="h2">Agregar</Typography>
      </Grid>
      <AddOtheForm />
    </Grid>
  );
};

export default AddOtherScreen;
