import { useEffect, useState } from 'react';

import {
  Alert,
  Box,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  Skeleton,
  Snackbar,
  Typography,
  useTheme,
} from '@material-ui/core';
// import { useDispatch } from 'react-redux';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';

import Chip from '../../../shared/components/extended/Chip';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
// import { useGetMeshQuery } from '../../../meshs/slices/meshsApiSlice';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import { useGetGeneralApplicationHomologationByIdQuery } from '../slices/GeneralApplicationsApiSlice';
import { ApplicationTypesComponent, updateStatus, DetailsProps } from '../constants/types';
import ThirdRegistrationComponent from './applicationDetails/ThirdRegistration';
import Practice from './applicationDetails/Practice';
import InternalHomologation from './applicationDetails/InternalHomologation';
import ExternalHomologation from './applicationDetails/ExternalHomologation';
import PracticePreNew from './applicationDetails/PracticePreNew';
import LateExam from './applicationDetails/LateExam';
import RecoveryExam from './applicationDetails/RecoveryExam';
import Requalification from './applicationDetails/Requalification';
import SubjectRetirement from './applicationDetails/SubjectRetirement';
import OthersApplications from './applicationDetails/OthersApplications';
import LinkIngress from './applicationDetails/LinkIngress';
import PaymentAgreement from './applicationDetails/PaymentAgreement';
import { appTypes } from '../constants/mockOptions';
import {
  setApplicationCommentsList,
  setNextStatus,
  setSelectedStudentRecordId,
} from '../slices/GeneralApplicationsSlice';
import FaultJustification from './applicationDetails/FaultJustification';
import BookReservation from './applicationDetails/BookReservation';
import HomologationComments from './applicationDetails/HomologationComments';
import useFormatDate from '../../../shared/hooks/useFormatDate';
import BirthdayTeacher from './applicationDetails/BirthdayTeacher';
import VacationsTeacher from './applicationDetails/VacationsTeacher';
import AnticipeTeacher from './applicationDetails/AnticipeTeacher';
import PersonalDayTeacher from './applicationDetails/PersonalDayTeacher';

const AppComponentDict: ApplicationTypesComponent = {
  CAMCAR: InternalHomologation,
  HOMEXT: ExternalHomologation,
  INGPRA: PracticePreNew, // nuevo practice
  REQDOC: ThirdRegistrationComponent, //no va aqui
  EXMATR: LateExam,
  EXMREC: RecoveryExam,
  RETMAT: SubjectRetirement,
  RECNOT: Requalification,
  REGTMC: ThirdRegistrationComponent,
  OTROS: OthersApplications,
  INGVIN: LinkIngress,
  INGTIT: ThirdRegistrationComponent, //Registro de anteproyecto
  CONPAG: PaymentAgreement,
  JUSFAL: FaultJustification,
  PREBIB: BookReservation,
  APPP: Practice,
  DOCHBH: BirthdayTeacher,
  DOCVAC: VacationsTeacher,
  DOCANT: AnticipeTeacher,
  DOCPDA: PersonalDayTeacher,
};

const spacing = 2;

//Quitar proceso de aprobar solicitudes
const PreviewIntHomologation = () => {
  // const dispatch = useDispatch();
  const theme = useTheme();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const { formatDate } = useFormatDate();

  const { currentProfile } = useAppSelector((state) => state.access);
  const [showAlerts, setShowAlerts] = useState(true);
  const [updateAlert, setUpdateAlert] = useState(true);
  const [updateStatus, setUpdateStatus] = useState<updateStatus>();

  //progress bar
  const [progress, setProgress] = useState(0);

  const updateStatusHandler = (data: updateStatus) => {
    setUpdateStatus(data);
  };
  const updateAlertHandler = (state: boolean) => {
    setUpdateAlert(state);
  };

  // const { internalHomologationsList } = useAppSelector((state) => state.homologations);
  const { app } = useParams<{ app: string }>();

  const {
    data,
    isLoading,
    error: getDataError,
  } = useGetGeneralApplicationHomologationByIdQuery(
    {
      profileId: currentProfile?.profileId!,
      ApplicationHomId: Number(app),
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    if (getDataError) setShowAlerts(true);
  }, [getDataError]);

  useEffect(() => {
    dispatch(setApplicationCommentsList(data?.app_comments!));
    dispatch(setNextStatus(data?.next_status));
    dispatch(setSelectedStudentRecordId(data?.applicationDetails.studentRecord?.id || undefined));
    return () => {
      setApplicationCommentsList([]);
      setNextStatus(undefined);
    };
  }, [data, dispatch]);

  // const currentApplication = internalHomologationsList.find((h) => h.id.toString() === app);
  // const primaryData = (currentApplication?.applicationDetails[0]?.value as StudentRecord) || [];

  //
  // const primaryData = data?.applicationDetails as ThirdRegistration;

  const appType = data?.app_code.split('-')[0];

  const Component = AppComponentDict[appType as keyof ApplicationTypesComponent];

  const titleAppType = appTypes.find((app) => app.acro === appType);

  useEffect(() => {
    if (
      (updateStatus?.state === 'success' || updateStatus?.state === 'success-practice') &&
      progress < 100
    ) {
      const timer = setInterval(() => {
        setProgress((oldProgress) => {
          if (oldProgress === 100) {
            return 0;
          }
          const diff = 20;
          return Math.min(oldProgress + diff, 100);
        });
      }, 400);

      return () => {
        clearInterval(timer);
      };
    }
  }, [location.pathname, progress, updateStatus?.state]);

  useEffect(() => {
    if (progress === 100) {
      const timer = setTimeout(() => {
        updateStatus?.state === 'success-practice'
          ? history.push(`/registro-asignaturas/${data?.student.id}/registro`)
          : history.push(`/${location.pathname.split('/')[1]}`);
      }, 300);

      return () => {
        clearTimeout(timer);
      };
    }

    // eslint-disable-next-line
  }, [history, location.pathname, progress, updateStatus?.state]);

  // const {
  //   data: destinedCurriculum,
  //   // isLoading: isLoadingDM,
  //   isFetching: isFetchingDM,
  // } = useGetMeshQuery(
  //   {
  //     profileId: currentProfile?.profileId!,
  //     meshId: data?.applicationDetails.curriculum.id || -1,
  //   },
  //   { skip: !data }
  // );

  // const [updateApplication, { isLoading: isUpdating }] = useUpdateApplicationMutation();

  // for (const el of currentApplication!.applicationDetails) {
  //   const aux = getApplicationDetailValue(el.value, 'collaborator.coll_advice', 'coll_advice');
  //   // const aux = getApplicationDetailValue(el.value, 'collaborator_id');
  //   if (Boolean(aux)) break;
  // }

  // const handleDelete = function (internalHomologation: { code: string; msg: string }) {
  //   dispatch(openInternalDeleteModalWith(internalHomologation));
  // };

  // if (true) return <></>;

  return (
    <Grid
      container
      spacing={4}
      //alignItems="flex-start"
    >
      {showAlerts && (
        <Grid item xs={12}>
          {getDataError && showAlerts && (
            <ErrorAlert
              message={getDataError as string}
              handleDismiss={() => setShowAlerts(false)}
              type="snackbar"
            />
          )}
        </Grid>
      )}

      {updateAlert && (
        <Grid item xs={12}>
          {updateStatus?.state === 'error' && updateAlert && (
            <ErrorAlert
              message={updateStatus.msg as string}
              handleDismiss={() => setUpdateAlert(false)}
              type="snackbar"
            />
          )}

          {(updateStatus?.state === 'success' || updateStatus?.state === 'success-practice') &&
            updateAlert && (
              <>
                <Snackbar
                  open
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                  sx={{
                    left: '60% !important',
                  }}
                >
                  <Alert
                    icon={false}
                    severity="success"
                    action={
                      <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={() => setUpdateAlert(false)}
                      >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    }
                  >
                    {(updateStatus?.msg as string) || 'Solicitud actualizada con exito'}
                    <Box mt={1} sx={{ width: '100%' }}>
                      <Typography variant="body2" component="span">
                        {updateStatus?.state === 'success-practice'
                          ? 'Redirigiendo a asignación de asignaturas del estudiante...'
                          : 'Redirigiendo...'}
                      </Typography>
                      <LinearProgress variant="determinate" value={progress} />
                    </Box>
                  </Alert>
                </Snackbar>
              </>
            )}
        </Grid>
      )}

      <Grid item container xs={12}>
        <Grid item container xs={12} md={6} spacing={1}>
          <Grid item xs={12} md={12}>
            <Typography variant={'h2'} component={'span'}>
              Datos de Solicitud
            </Typography>
          </Grid>

          <Grid container item xs={12} spacing={1} alignItems="center">
            <Grid item xs={'auto'}>
              <Typography variant={'h5'} component={'span'}>
                Nombre del solicitante:{' '}
              </Typography>
            </Grid>
            {isLoading ? (
              <Grid item xs={6}>
                <Skeleton
                  variant="text"
                  component={'span'}
                  sx={{ width: '100%', height: 30, ml: 1 }}
                />
              </Grid>
            ) : (
              <Grid item xs={'auto'}>
                <Typography variant={'body1'} component={'span'}>
                  {`${data?.person?.pers_firstname || ''} ${data?.person?.pers_secondname || ''} ${
                    data?.person?.pers_first_lastname || ''
                  } ${data?.person?.pers_second_lastname || ''}`}
                </Typography>
              </Grid>
            )}
          </Grid>

          {Boolean(data?.student?.stud_code) && (
            <Grid container item xs={12} spacing={1} alignItems="center">
              <Grid item xs={'auto'}>
                <Typography variant={'h5'} component={'span'}>
                  Código de Estudiante:{' '}
                </Typography>
              </Grid>
              {isLoading ? (
                <Grid item xs={6}>
                  <Skeleton
                    variant="text"
                    component={'span'}
                    sx={{ width: '100%', height: 30, ml: 1 }}
                  />
                </Grid>
              ) : (
                <Grid item xs={'auto'} lg={6}>
                  <Typography variant={'body1'} component={'span'}>
                    {' '}
                    {data?.student?.stud_code || ''}
                  </Typography>
                </Grid>
              )}
            </Grid>
          )}

          <Grid container item xs={12} spacing={1} alignItems="center">
            <Grid item xs={'auto'}>
              <Typography variant={'h5'} component={'span'}>
                Identificación:{' '}
              </Typography>
            </Grid>
            {isLoading ? (
              <Grid item xs={6}>
                <Skeleton
                  variant="text"
                  component={'span'}
                  sx={{ width: '100%', height: 30, ml: 1 }}
                />
              </Grid>
            ) : (
              <Grid item xs={'auto'} lg={6}>
                <Typography variant={'body1'} component={'span'}>
                  {data?.person?.pers_identification || ''}
                </Typography>
              </Grid>
            )}
          </Grid>

          <Grid container item xs={12} spacing={1} alignItems="center">
            <Grid item xs={'auto'}>
              <Typography variant={'h5'} component={'span'}>
                Código de Solicitud:{' '}
              </Typography>
            </Grid>
            {isLoading ? (
              <Grid item xs={6}>
                <Skeleton
                  variant="text"
                  component={'span'}
                  sx={{ width: '100%', height: 30, ml: 1 }}
                />
              </Grid>
            ) : (
              <Grid item xs={'auto'} lg={6}>
                <Typography variant={'body1'} component={'span'}>
                  {data?.app_code || ''}
                </Typography>
              </Grid>
            )}
          </Grid>

          <Grid container item xs={12} spacing={1} alignItems="center">
            <Grid item xs={'auto'}>
              <Typography variant={'h5'} component={'span'}>
                Fecha de solicitud:{'  '}
              </Typography>
            </Grid>
            {isLoading ? (
              <Grid item xs={6}>
                <Skeleton
                  variant="text"
                  component={'span'}
                  sx={{ width: '100%', height: 30, ml: 1 }}
                />
              </Grid>
            ) : (
              <Grid item xs={'auto'} lg={6}>
                <Typography variant={'body1'} component={'span'}>
                  {data?.app_register_date ? formatDate(data?.app_register_date) : 'N/A'}
                </Typography>
              </Grid>
            )}
          </Grid>

          <Grid container item xs={12} spacing={1} alignItems="center">
            <Grid item xs={'auto'}>
              <Typography variant={'h5'} component={'span'}>
                Estado:{' '}
              </Typography>
            </Grid>
            {isLoading ? (
              <Grid item xs={6}>
                <Skeleton
                  variant="text"
                  component={'span'}
                  sx={{ width: '100%', height: 30, ml: 1 }}
                />
              </Grid>
            ) : (
              <Grid item xs={'auto'} lg={6}>
                <Chip
                  label={data?.current_status}
                  size="small"
                  // chipcolor={label === 'En proceso' ? 'warning' : 'success'}
                  chipcolor={
                    data?.next_status
                      ? 'primary'
                      : data?.current_status === 'Rechazado'
                      ? 'error'
                      : 'success'
                  }
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <HomologationComments />
        </Grid>
      </Grid>

      {/* por si se quiere mostrar el tipo de solicitud si es posible que no haya descripcion de la solicitud */}
      {false && (
        <Grid item>
          <Typography variant="h4" component="span" sx={{ color: theme.palette.primary.main }}>
            Tipo de Solicitud:{' '}
          </Typography>
          <Typography variant="h4" component="span">
            {titleAppType?.value}
          </Typography>
        </Grid>
      )}

      <Grid item xs={12} md={12} lg={12} xl={12}>
        <Divider />
      </Grid>

      {/* {!isLoading && appType && appComponentDict[appType as keyof ApplicationTypesComponent]} */}
      {!isLoading && appType && Component && (
        <Grid item xs={12} md={12} lg={12} xl={12} justifyContent="center" alignItems="center">
          <Component
            appId={+app}
            details={data!.applicationDetails}
            appCode={data!.app_code}
            spacing={spacing}
            curriculumNew={data?.curriculum_new}
            practiceDetails={data?.practice_application}
            appDescription={data!.app_description}
            currentStatus={data!.current_status as DetailsProps['currentStatus']}
            updateStatusHandler={updateStatusHandler}
            updateAlertHandler={updateAlertHandler}
            nextStatus={data!.next_status}
          />
        </Grid>
      )}
      {/* {((currentRol?.keyword === 'decano' &&
          currentApplication!.current_status.toLowerCase() === 'enviado') ||
          (currentRol?.keyword === 'coordinador-facultad' &&
            (currentApplication!.current_status.toLowerCase() === 'revisado por decanato' ||
              currentApplication!.current_status.toLowerCase() ===
                'revisado por consejo facultad'))) &&
          !isSuccess && (

          <Grid item xs={12}>
            <InputLabel>Comentario</InputLabel>
            <TextField
              fullWidth
              multiline
              rows={4}
              error={Boolean(errors.comment && touched.comment)}
              placeholder="Ingrese el nombre del periodo"
              {...getFieldProps('comment')}
            />
            {errors.comment && touched.comment && (
              <FormHelperText error>{errors.comment}</FormHelperText>
            )}
          </Grid>

          )} */}

      {/* {((currentRol?.keyword === 'decano' &&
          currentApplication!.current_status.toLowerCase() === 'enviado') ||
          (currentRol?.keyword === 'coordinador-facultad' &&
            (currentApplication!.current_status.toLowerCase() === 'revisado por decanato' ||
              currentApplication!.current_status.toLowerCase() ===
                'revisado por consejo facultad'))) &&
          !isSuccess && (
          <Grid container justifyContent={'flex-end'} mt={2} spacing={2}>
            <Grid item>
              <Button
                variant="contained"
                size="large"
                // style={{ background: theme.palette.error.main, borderColor: '#EDE7F6' }}
                sx={
                  {
                    // boxShadow: theme.customShadows.error,
                    // ':hover': {
                    //   boxShadow: 'none',
                    // },
                  }
                }
                onClick={() => {
                  // touched.comment = true;
                  // validateField('comment').then(() => {
                  //   if (touched.comment && errors.comment) return;
                  //   handleDelete({ code: currentApplication!.app_code, msg: values.comment });
                  // });
                }}
              >
                Rechazar
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                size="large"
                color="primary"
                disabled={isUpdating}
                // endIcon={isUpdating && <CircularProgress size={20} />}
              >
                Aprobar
              </Button>
            </Grid>
          </Grid>
           )} */}
    </Grid>
  );
};

// const getApplicationDetailValue = (
//   data: any,
//   searchProp: any,
//   lastLvl?: string,
//   currentLvl?: any,
//   conditionId?: number
// ) => {
//   let value: string | number | null = null;
//   if (typeof data === 'object' && data !== null) {
//     //array
//     if (Array.isArray(data)) {
//       if (conditionId) {
//         for (const el of data) {
//           // extractValuedata(el, search);
//         }
//       } else {
//         // value = data;
//       }
//     } else {
//       //object
//       if (Boolean(lastLvl)) {
//         searchProp = searchProp.split('.');
//         const current = searchProp.shift();

//         if (lastLvl! in data) {
//           value = data[lastLvl!];
//         } else {
//           for (const key in data) {
//             if (key === current) {
//               getApplicationDetailValue(
//                 data[key],
//                 Boolean(searchProp.length) ? searchProp.join('.') : lastLvl,
//                 lastLvl,
//                 (currentLvl = current)
//               );
//               break;
//             }
//           }
//         }
//       } else {
//         if (searchProp in data) {
//           value = data[searchProp];
//         }
//       }
//       return value;
//     }
//   }

//   return value;
// };

export default PreviewIntHomologation;
