import { Grid } from '@material-ui/core';
import { useAppSelector } from '../../../../hooks/useRedux';
import SelectTeacherForm from './forms/SelectTeacherForm';
import TableProjects from './TableProjects';
import ProjectFollowTable from './ProjectFollowTable';

const FollowUpOfResearchProjectMain = () => {
  const { currentProfile } = useAppSelector((state) => state.access);
  const { userInfo } = useAppSelector((state) => state.auth);
  const { teacherId } = useAppSelector((state) => state.followUpOfResearch);

  return (
    <Grid container spacing={2}>
      {false && (
        <>
          {currentProfile?.keyword !== 'docente' && (
            <Grid item xs={12}>
              <SelectTeacherForm />
            </Grid>
          )}

          {(userInfo?.collaboratorInfo?.id || teacherId) && (
            <Grid item xs={12}>
              <TableProjects teacherId={userInfo?.collaboratorInfo?.id || teacherId!} />
            </Grid>
          )}
        </>
      )}
      {true && <ProjectFollowTable />}
    </Grid>
  );
};

export default FollowUpOfResearchProjectMain;
