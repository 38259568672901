import { Switch, Redirect } from 'react-router-dom';
import { useAppSelector } from '../../../../hooks/useRedux';
import GuardRoute from '../../../../router/guards/GuardRoute';
import { permissionKey, permissionKeyAdmin } from '../constants/permissions';
import EnterLeaveScreen from '../screens/EnterLeaveScreen';

const EntradasSalidasRouter = () => {
  const { currentModule } = useAppSelector((state) => state.access);
  const hasPermission =
    permissionKey in currentModule?.permissions! ||
    permissionKeyAdmin in currentModule?.permissions!;

  if (!hasPermission) <Redirect to="/dashboard" />;

  return (
    <Switch>
      <GuardRoute
        exact
        path="/entradas-salidas"
        component={EnterLeaveScreen}
        permissionKey={permissionKey || permissionKeyAdmin}
        //subPermissionKey={subPermissionKeys.read}
      />
      {/* <GuardRoute
        exact
        path="/administrar-horas-docentes/agregar"
        component={AssignHoursScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.read}
      />
      <GuardRoute
        exact
        path="/administrar-horas-docentes/:hourSummaryId/editar"
        component={EditHoursScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.read}
      /> */}
    </Switch>
  );
};

export default EntradasSalidasRouter;
