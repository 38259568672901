import { mainApi } from '../../../../api/mainApi';
import { middlewareApi } from '../../../middleware/middlewareApi';
import { ReservationsResponse } from '../interfaces/reservations.interface';

export const reservationsTags = middlewareApi.enhanceEndpoints({
  addTagTypes: ['Reservations', 'ReservedBooks'],
});

const reservationsApi = reservationsTags.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * @GET reservations
     */
    getReservations: builder.query<
      ReservationsResponse,
      {
        page: number;
        size: number;
        search?: string;
        sort?: string;
        type_sort?: string;
      }
    >({
      queryFn: async ({ page, size, search, sort, type_sort }) => {
        try {
          const { data } = await mainApi.get<ReservationsResponse>('reservations', {
            params: {
              page,
              size,
              search,
              sort,
              type_sort,
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'Reservations', id } as const)),
              { type: 'Reservations', id: 'LIST' },
            ]
          : [{ type: 'Reservations', id: 'LIST' }],
    }),
    /**
     * @PUT cancel reservation
     */
    cancelRerservation: builder.mutation<
      any,
      {
        id: string;
        status: string;
        type: string;
      }
    >({
      queryFn: async ({ id, status, type }) => {
        try {
          const { data } = await mainApi.put<any>(`reservations/${id}`, {
            status,
            type,
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [
        {
          type: 'Reservations',
          id: 'LIST',
        },
        {
          type: 'ReservedBooks',
          id: 'LIST',
        },
      ],
    }),
  }),
});

export const { useGetReservationsQuery, useCancelRerservationMutation } = reservationsApi;
