import { Switch, Redirect } from 'react-router-dom';

import { useAppSelector } from '../../../../hooks/useRedux';

import DebtsPaymentsScreen from '../screens/GenerateDebtsScreen';

import GuardRoute from '../../../../router/guards/GuardRoute';

// import { permissionKey, subPermissionKeys } from '../constants/permissions';

const DebtsPaymentRouter = () => {
  const { currentModule } = useAppSelector((state) => state.access);

  const hasPermission = 'economicGroup' in currentModule?.permissions!;

  if (!hasPermission) return <Redirect to="/dashboard" />;

  return (
    <Switch>
      {/* Stages */}
      <GuardRoute
        exact
        path="/administrar-caja/generar-deudas"
        component={DebtsPaymentsScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.read}
      />
    </Switch>
  );
};

export default DebtsPaymentRouter;
