import * as React from 'react';
import Stack from '@material-ui/core/Stack';
import TextField from '@material-ui/core/TextField';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import { DesktopDatePicker, LocalizationProvider } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
import { useGetLocaleDateLang } from '../../../../../translation/hooks/useGetLocaleDateLang';
const colorazul = '#2570B5';

const Styles = makeStyles({
  calendar: {
    '&': {
      textAlign: 'center',
      margin: '5px ',
      color: colorazul,
      marginRight: '10px',
    },
    '& .MuiOutlinedInput-root': {
      borderColor: 'white',
      backgroundColor: 'transparent',
    },
    '& .MuiOutlinedInput-input': {
      color: 'black',
      backgroundColor: '#F2F3F4',
      borderRadius: '8px',
      fontWeight: 'bolder',

      textAlign: 'center',
    },
    '& .MuiOutlinedInput-root .css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent',
    },
    '& .MuiSvgIcon-root': {
      color: colorazul,
    },
    '& .MuiFormControl-root .MuiInputLabel-root': {
      fontSize: 0,
    },
    '& .css-1rhr49-MuiFormHelperText-root': {
      display: 'none',
    },
    '& .css-tptuxb-MuiOutlinedInput-notchedOutline': {
      border: 'none !important',
    },

    '.MuiButtonBase-root': {
      backgroundColor: 'transparent',
    },
  },
});
const DateTimePickers: React.FC<{
  setFecha: React.Dispatch<React.SetStateAction<Date | null>>;
  Fecha: Date | null;
  disabled?: boolean;
}> = (setFecha, Fecha) => {
  const styles = Styles();
  const { lang } = useGetLocaleDateLang();

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={lang}>
        <Stack spacing={3} className={styles.calendar}>
          <DesktopDatePicker
            label="Date desktop"
            value={setFecha.Fecha || new Date()}
            onChange={(newvalue: Date | null) => setFecha.setFecha(newvalue)}
            renderInput={(params) => <TextField {...params} helperText="" />}
            disabled={setFecha.disabled}
          />
        </Stack>
      </LocalizationProvider>
    </>
  );
};

export default DateTimePickers;
