import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  Autocomplete,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  TextField,
  // Switch,
  // FormControlLabel,
} from '@material-ui/core';

import { useFormik } from 'formik';

import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import InputLabel from '../../../shared/components/forms/InputLabel';

import { useAppSelector } from '../../../../hooks/useRedux';
import { useAddEducationLevelSubjectMutation } from '../slices/ELSApiSlice';

import { useGetAvailablesGroupAreasQuery } from '../../groups-area/slices/groupAreasApiSlice';
import { useGetAvailablesSubjectsNivelationQuery } from '../../matters/slices/mattersApiSlice';
import { statusAvailables } from '../../../shared/constants/resourceStatus';

import { ELSSchema } from '../constants/validation-schemas';

const AddELSForm = () => {
  const history = useHistory();

  const { currentProfile } = useAppSelector((state) => state.access);

  const [showAlerts, setShowAlerts] = useState(true);

  const [addELS, { isLoading, isError, error, isSuccess }] = useAddEducationLevelSubjectMutation();

  const { data: availablesGroupAreas = [], isLoading: isLoadingGroupArea } =
    useGetAvailablesGroupAreasQuery({
      profileId: currentProfile?.profileId!,
    });

  const { data: availablesSubjectsNivelation = [], isLoading: isLoadingSubjectsNivelation } =
    useGetAvailablesSubjectsNivelationQuery({
      profileId: currentProfile?.profileId!,
    });

  const { errors, touched, handleSubmit, setFieldValue, getFieldProps } = useFormik({
    initialValues: {
      groupNivelationId: 0,
      subjects: [],
      subjectHours: 1,
      statusId: 1,
    },
    validationSchema: ELSSchema,
    onSubmit: async (values) => {
      await addELS({
        profileId: currentProfile?.profileId!,
        educationLevelSubjectPayload: {
          group_nivelation_id: values.groupNivelationId,
          subjects: values.subjects,
          subject_hours: values.subjectHours,
          status_id: values.statusId,
        },
      });

      setShowAlerts(true);
    },
  });

  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction="column" gap={2}>
        <Grid item>
          {isError && showAlerts && (
            <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
          )}

          {isSuccess && showAlerts && (
            <SuccessAlert
              message="La materia ha sido creada correctamente."
              handleDismiss={() => setShowAlerts(false)}
            />
          )}
        </Grid>

        <Grid item xs={12}>
          <InputLabel>Grupo</InputLabel>
          <Autocomplete
            options={availablesGroupAreas}
            loading={isLoadingGroupArea}
            loadingText="Cargando unidades académicas..."
            getOptionLabel={(option) => option.arg_name}
            renderInput={(params) => (
              <TextField
                {...params}
                error={Boolean(errors.groupNivelationId && touched.groupNivelationId)}
                placeholder="Seleccione una unidad académica disponible"
              />
            )}
            // defaultValue={campusAvailables[0]}
            onChange={(_, value) => setFieldValue('groupNivelationId', value?.id || '')}
            fullWidth
            clearIcon={null}
          />
          {errors.groupNivelationId && touched.groupNivelationId && (
            <FormHelperText error>{errors.groupNivelationId}</FormHelperText>
          )}
        </Grid>

        <Grid item xs={12}>
          <InputLabel>Materias</InputLabel>
          <Autocomplete
            multiple
            options={availablesSubjectsNivelation}
            loading={isLoadingSubjectsNivelation}
            loadingText="Cargando materias..."
            getOptionLabel={(option) => option.mat_name}
            renderInput={(params) => (
              <TextField
                {...params}
                error={Boolean(errors.subjects && touched.subjects)}
                placeholder="Seleccione una materia disponible"
              />
            )}
            // defaultValue={campusAvailables[0]}
            onChange={(_, values) => setFieldValue('subjects', [...values.map((s) => s.id)])}
            fullWidth
            clearIcon={null}
          />
          {errors.subjects && touched.subjects && (
            <FormHelperText error>{errors.subjects}</FormHelperText>
          )}
        </Grid>

        <Grid item xs={12}>
          <InputLabel>Horas</InputLabel>
          <TextField
            fullWidth
            InputLabelProps={{ shrink: true }}
            InputProps={{ inputProps: { min: 1, max: 20 } }}
            type="number"
            placeholder="Cantidad de horas"
            {...getFieldProps('subjectHours')}
            error={Boolean(errors.subjectHours && touched.subjectHours)}
          />
          {errors.subjectHours && touched.subjectHours && (
            <FormHelperText error>{errors.subjectHours}</FormHelperText>
          )}
        </Grid>

        <Grid item xs={12}>
          <InputLabel>Estado</InputLabel>
          <Autocomplete
            options={statusAvailables}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField {...params} error={Boolean(errors.statusId && touched.statusId)} />
            )}
            defaultValue={statusAvailables[0]}
            onChange={(e, value) => setFieldValue('statusId', value?.id || '')}
            fullWidth
            clearIcon={null}
          />
          {errors.statusId && touched.statusId && (
            <FormHelperText error>{errors.statusId}</FormHelperText>
          )}
        </Grid>

        <Grid
          item
          container
          alignItems="center"
          justifyContent="flex-end"
          spacing={2}
          sx={{ mt: 1 }}
        >
          <Grid item>
            <Button
              variant="outlined"
              size="large"
              color="primary"
              onClick={() => history.goBack()}
            >
              Regresar
            </Button>
          </Grid>
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              disabled={isLoading}
              endIcon={isLoading && <CircularProgress size={20} />}
            >
              Agregar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default AddELSForm;
