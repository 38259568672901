import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISearchTable } from '../../../shared/interfaces/paginatedTable.interface';
import { AcademicOfferConfig } from '../interfaces/academic-offerings.interfaces';
import { AcademicOffer } from '../interfaces/offers-academic.interfaces';
import { Period, PeriodToPost } from '../interfaces/periods.interfaces';
import { PeriodStage } from '../interfaces/periodStages.interfaces';
interface PeriodsState {
  periodList: Period[];
  periodToPost?: PeriodToPost;
  deleteModalOpen: boolean;
  academicOffers: AcademicOffer[];
  periodIdToDelete?: number;
  academicOfferConfig: AcademicOfferConfig[];
  periodStageIdToDelete?: number;
  periodStagesList: PeriodStage[];
  isErrorStages: boolean;

  periodsTable: ISearchTable;
  hourhandsTable: ISearchTable;
  hourhandsSelected: number[];
  statusIdSelected: number | null;
  typePeriodId: string | null;
}

const initialState: PeriodsState = {
  periodList: [],
  academicOfferConfig: [],
  academicOffers: [],
  periodStagesList: [],
  deleteModalOpen: false,
  isErrorStages: false,
  typePeriodId: '',

  periodsTable: {
    page: 1,
    perPage: 5,
    search: '',
  },
  hourhandsTable: {
    page: 1,
    perPage: 10,
    search: '',
  },

  statusIdSelected: null,
  hourhandsSelected: [],
};

const periodsSlice = createSlice({
  name: 'periods',
  initialState,
  reducers: {
    setPeriodList: (state, action: PayloadAction<Period[]>) => {
      state.periodList = action.payload;
    },
    setPeriodToPost: (state, action: PayloadAction<PeriodToPost>) => {
      state.periodToPost = action.payload;
    },
    setDeleteModalOpen: (state, action: PayloadAction<boolean>) => {
      state.deleteModalOpen = action.payload;
    },
    openDeleteModalWith: (state, action: PayloadAction<number>) => {
      state.periodIdToDelete = action.payload;
      state.deleteModalOpen = true;
    },
    resetPeriodIdToDelete: (state) => {
      state.periodIdToDelete = undefined;
    },
    setAcademicOffers: (state, action: PayloadAction<AcademicOffer[]>) => {
      state.academicOffers = action.payload;
    },
    setPeriodStages: (state, action: PayloadAction<PeriodStage[]>) => {
      state.periodStagesList = action.payload;
    },
    openDeleteModalPeriodStage: (state, action) => {
      state.periodStageIdToDelete = action.payload;
      state.deleteModalOpen = true;
    },
    resetPeriodSTageIdToDelete: (state) => {
      state.periodStageIdToDelete = undefined;
    },
    setOfferStagesConfig: (state, action: PayloadAction<AcademicOfferConfig[]>) => {
      state.academicOfferConfig = action.payload;
    },
    setIsErrorStages: (state, action: PayloadAction<boolean>) => {
      state.isErrorStages = action.payload;
    },
    setPeriodsTable: (state, action: PayloadAction<ISearchTable>) => {
      state.periodsTable = action.payload;
    },
    setHourhandsTable: (state, action: PayloadAction<ISearchTable>) => {
      state.hourhandsTable = action.payload;
    },
    setStatusIdSelected: (state, action: PayloadAction<number | null>) => {
      state.statusIdSelected = action.payload;
    },
    setTypePeriodId: (state, action: PayloadAction<string | null>) => {
      state.typePeriodId = action.payload;
    },
    sethourhandsSelected: (state, action: PayloadAction<number[]>) => {
      state.hourhandsSelected = action.payload;
    },
  },
});

export const {
  setPeriodList,
  setDeleteModalOpen,
  openDeleteModalWith,
  resetPeriodIdToDelete,
  setPeriodStages,
  setAcademicOffers,
  setOfferStagesConfig,
  setPeriodToPost,
  openDeleteModalPeriodStage,
  resetPeriodSTageIdToDelete,
  setIsErrorStages,
  setPeriodsTable,
  setStatusIdSelected,
  setTypePeriodId,
  setHourhandsTable,
  sethourhandsSelected,
} = periodsSlice.actions;

export default periodsSlice.reducer;
