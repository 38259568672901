import { Grid, IconButton, Typography } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useAppSelector } from '../../../../hooks/useRedux';
import MainCard from '../../../shared/components/cards/MainCard';
import PosgraduatePlanningDayTypesForm from '../components/PosgraduatePlanningDayTypesForm';
import { useLazyGetPosgraduatePlanningDayTypeQuery } from '../slices/posgraduatePlanningDayTypesApiSlice';
import { setPosgraduatePlanningDayTypesList } from '../slices/posgraduatePlanningDayTypesSlice';

const EditPosgraduatePlanningDayTypesScreen = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const { currentProfile } = useAppSelector((state) => state.access);
  const { id } = useParams<{ id: string }>();

  const { posgraduatePlanningDayTypesList, posgraduatePlanningDayTypeIdSelected } = useAppSelector(
    (state) => state.posgraduatePlanningDayTypes
  );

  const [getPosgraduatePlanningDayType, { isLoading }] =
    useLazyGetPosgraduatePlanningDayTypeQuery();

  useEffect(() => {
    if (posgraduatePlanningDayTypesList.length === 0) {
      getPosgraduatePlanningDayType({
        profileId: currentProfile?.profileId!,
        id: posgraduatePlanningDayTypeIdSelected || id,
      }).then((res) => {
        setPosgraduatePlanningDayTypesList([res!]);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid component={MainCard} sx={{ p: 2 }}>
      <Grid container alignItems="center" sx={{ mb: 3 }}>
        <IconButton
          onClick={() => {
            history.goBack();
          }}
        >
          <ArrowBack />
        </IconButton>
        <Typography variant="h2">{t('posgraduate.day_types.edit.title')}</Typography>
      </Grid>
      {!isLoading && posgraduatePlanningDayTypesList.length > 0 && (
        <PosgraduatePlanningDayTypesForm />
      )}
    </Grid>
  );
};

export default EditPosgraduatePlanningDayTypesScreen;
