import * as yup from 'yup';

export const ClassroomEducationLvlSchema = yup.object().shape({
  campus_id: yup.number().required('El Campus es requerido.').min(1, 'El Campus es requerido.'),
  period_id: yup.number().required('El período es requerido.').min(1, 'El período es requerido.'),
  academicOffer_id: yup
    .number()
    .required('La oferta académica es requerida.')
    .min(1, 'La oferta académica es requerida.'),
  educationLvl_id: yup
    .number()
    .required('La unidad académica es requerida.')
    .min(1, 'La unidad académica es requerida.'),
});

export const ClassroomLoansSchema = yup.object().shape({
  classroom_id: yup.number(),
  period_id: yup.number(),
  offer_id: yup.number(),
  education_level_id: yup.number(),
  campus_id: yup.number(),
  array_hourhand_id: yup.array().required('La fecha de prestamo es requerida.')
});