import SubCard from '../../../../shared/components/cards/SubCard';
import {
  Grid,
  CircularProgress,
  TextField,
  IconButton,
  useTheme,
  Typography,
  Button,
} from '@material-ui/core';
import InputLabel from '../../../../shared/components/forms/InputLabel';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { IconSend } from '@tabler/icons';
import { makeStyles } from '@material-ui/core/styles';
import {
  useAddSmsCodeMutation,
  useLazyGetSmsScoresQuery,
} from '../../slices/virtualClassroomTeacherApiSlice';
import { useEffect, useState } from 'react';
import ErrorAlert from '../../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../../shared/components/alerts/SuccessAlert';
import { useAppDispatch } from '../../../../../hooks/useRedux';
import { setIsValidateCalification } from '../../slices/virtualClassroomTeacherSlice';
import IconComponent from '../../../../shared/components/errors-boundary/IconComponent';
import CustomIcon from '../../../../shared/components/illustrations/CustomIcon';
import IconWarn from '../../../../../assets/images/illustrations/warn.svg';
import ErrorAlertSmsHOC from './ErrorAlertSmsHOC';

const useStyles = makeStyles((theme) => ({
  input: {
    height: 100,
    fontSize: 50,
    letterSpacing: 15,
  },
}));

const validationCodeSchema = yup.object({
  code: yup
    .string()
    .matches(/^\d+$/, 'Solo se aceptan números.')
    .required('El código es requerido.'),
});

const ValidateSms = () => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const [showAlerts, setShowAlerts] = useState(false);
  const [showAlertsToken, setShowAlertsToken] = useState(false);
  const [view, setView] = useState(false);

  const [trigger, { data, isLoading: isLoadingGet, isSuccess, isError, error }] =
    useLazyGetSmsScoresQuery();

  const handleSendSms = async () => {
    try {
      await trigger().unwrap();

      setView(true);
    } finally {
      setShowAlerts(true);
    }
  };

  const [
    addCode,
    {
      isError: isErrorToken,
      error: errorToken,
      isLoading: isLoadingToken,
      isSuccess: isSuccessToken,
    },
  ] = useAddSmsCodeMutation();

  const { errors, touched, handleChange, handleSubmit, setFieldValue, setFieldTouched, values } =
    useFormik({
      initialValues: {
        code: '',
      },
      validationSchema: validationCodeSchema,
      onSubmit: async (value) => {
        await addCode({
          token: Number(value.code),
        });
        setShowAlertsToken(true);
      },
    });

  const handleMaxAttempts = () => {
    setView(false);
    setShowAlertsToken(false);
    setFieldValue('code', '');
    setFieldTouched('code', false);
  };

  useEffect(() => {
    if (isSuccessToken) dispatch(setIsValidateCalification(true));
  }, [isSuccessToken, dispatch]);

  return (
    <SubCard title={'Validación por Sms'} darkTitle>
      {view ? (
        <>
          <Grid container justifyContent={'center'}>
            <Grid item container xs={4}>
              {isError && showAlerts && (
                <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
              )}

              {isSuccess && showAlerts && (
                <SuccessAlert message={data?.detail!} handleDismiss={() => setShowAlerts(false)} />
              )}
            </Grid>
          </Grid>

          {isErrorToken && showAlertsToken && (
            <Grid container justifyContent={'center'} mt={2}>
              <Grid item container xs={4}>
                <ErrorAlertSmsHOC
                  message={errorToken as string}
                  handleDismiss={() => setShowAlertsToken(false)}
                  maxAttemptsCallback={handleMaxAttempts}
                />
              </Grid>
            </Grid>
          )}

          <Grid container justifyContent={'center'} p={3} spacing={2}>
            <Grid item container xs={5}>
              <form onSubmit={handleSubmit}>
                <InputLabel>Código de verificación</InputLabel>
                <TextField
                  sx={{ textAlign: 'center' }}
                  fullWidth
                  id="code"
                  name="code"
                  value={values.code}
                  onChange={handleChange}
                  error={touched.code && Boolean(errors.code)}
                  helperText={touched.code && errors.code}
                  inputProps={{ style: { textAlign: 'center', fontWeight: 800 }, maxLength: 6 }}
                  InputProps={{
                    className: classes.input,
                    endAdornment: (
                      <IconButton type="submit" disabled={isLoadingToken || isLoadingGet}>
                        {isLoadingToken || isLoadingGet ? (
                          <CircularProgress />
                        ) : (
                          <IconSend color={theme.palette.primary.main} />
                        )}
                      </IconButton>
                    ),
                  }}
                />
              </form>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Grid container justifyContent={'center'}>
            <Grid item container xs={4}>
              {isError && showAlerts && (
                <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
              )}
            </Grid>
          </Grid>

          <IconComponent
            icon={<CustomIcon icon={IconWarn} size={250} />}
            title={'Validación por SMS'}
            subtitle={
              <Grid>
                {isError && showAlerts && (
                  <ErrorAlert
                    message={error as string}
                    handleDismiss={() => setShowAlerts(false)}
                  />
                )}
                <Typography>
                  Para acceder a las calificaciones debe solicitar un código de verificación.
                </Typography>
                <Grid item sx={{ mt: 2 }}>
                  <Button
                    onClick={handleSendSms}
                    variant="contained"
                    size="large"
                    color="primary"
                    disabled={isLoadingGet}
                    endIcon={isLoadingGet && <CircularProgress size={20} />}
                  >
                    Solicitar código
                  </Button>
                </Grid>
              </Grid>
            }
          />
        </>
      )}
    </SubCard>
  );
};

export default ValidateSms;
