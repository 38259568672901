import Skeleton from '@material-ui/core/Skeleton';
import Stack from '@material-ui/core/Stack';

const LaneSkeleton = () => {
  return (
    <Stack direction="row" spacing={2} sx={{ width: '100%', mt: '20px' }}>
      <Skeleton variant="rectangular" sx={{ borderRadius: 5, width: '100%', height: '70vh' }} />

      <Skeleton variant="rectangular" sx={{ borderRadius: 5, width: '100%', height: '70vh' }} />

      <Skeleton variant="rectangular" sx={{ borderRadius: 5, width: '100%', height: '70vh' }} />

      <Skeleton variant="rectangular" sx={{ borderRadius: 5, width: '100%', height: '70vh' }} />
    </Stack>
  );
};

export default LaneSkeleton;
