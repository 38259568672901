import { forwardRef } from 'react';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Slide,
  SlideProps,
  Typography,
  useTheme,
} from '@material-ui/core';

// third-party

// import AnimateButton from '../../../../shared/components/extended/AnimateButton';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { IconArrowNarrowLeft } from '@tabler/icons';
// import useGuard from '../../hooks/useGuard';
import AddPersonForm from './AddPersonForm';
import AddInstituteForm from './AddInstituteForm';
import AddInstituteSubject from './AddInstituteSubject';
import EditPreconfigSubject from './EditPreconfigSubject';
import ExtendedSideDialog from '../../../../shared/components/dialogs/ExtendedSideDialog';
import { useTranslation } from 'react-i18next';

// style constant
const useStyles = makeStyles((theme) => ({
  userAddDialog: {
    '&>div:nth-child(3)': {
      justifyContent: 'flex-end',
      '&>div': {
        margin: '0px',
        borderRadius: '0px',
        maxWidth: '650px',
        maxHeight: '100%',
        '&>div.MuiDialogContent-root>div.scrollbar-container': {
          paddingLeft: '20px',
          overflow: 'hidden',
        },
      },
    },
  },
  paper: {
    minHeight: '100vh',
    width: '100%',
  },
  paperTable: {
    minHeight: '100vh',
    // width: '40%!important',
  },
}));

// animation
const Transition = forwardRef(function Transition(props: SlideProps, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export interface Props {
  open: boolean;
  mode: 'person' | 'institute' | 'subjectInstitute' | 'editSubjectPreconfig';
  personId?: string;
  instituteId?: string;
  handleCloseDialog: () => void;
}

const EditHomologationSideDialog = ({ open, handleCloseDialog, mode }: Props) => {
  //
  const { t } = useTranslation();

  const classes = useStyles();
  const theme = useTheme();

  //   const { hasAccess } = useGuard();

  return (
    <ExtendedSideDialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCloseDialog}
      className={classes.userAddDialog}
      classes={
        mode !== 'person'
          ? {
              paper: classes.paper,
            }
          : {
              paper: classes.paperTable,
            }
      }
    >
      <DialogTitle disableTypography>
        <Grid item container alignItems="center" gap={1}>
          <IconButton onClick={handleCloseDialog}>
            <IconArrowNarrowLeft size={30} color={theme.palette.text.dark} />
          </IconButton>
          <Typography variant="h3">
            {/* viabilidad de cambiar estados a un objeto literal? */}
            {mode === 'institute' && (
              <>{t('academic.homologation.external.side.title.institute')}</>
            )}
            {mode === 'subjectInstitute' && (
              <>{t('academic.homologation.external.side.title.subject')}</>
            )}
            {mode === 'editSubjectPreconfig' && (
              <>{t('academic.homologation.external.side.title.grade')}</>
            )}
          </Typography>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <PerfectScrollbar component={'div'}>
          <Grid container spacing={2} pt={2}>
            {/* modal crear persona */}
            {mode === 'person' && <AddPersonForm />}
            {/* modal crear instituto */}
            {mode === 'institute' && <AddInstituteForm onCancel={handleCloseDialog} />}
            {/* modal agregar materia */}
            {mode === 'subjectInstitute' && <AddInstituteSubject onCancel={handleCloseDialog} />}
            {/* modal editar nota de las materias preconfiguradas */}
            {mode === 'editSubjectPreconfig' && (
              <EditPreconfigSubject onCancel={handleCloseDialog} />
            )}

            {/* formulario para editar un curso creado */}
            {/* {mode === 'edit' && <EditCourseForm onCancel={setModeView} />} */}
          </Grid>
        </PerfectScrollbar>
      </DialogContent>
      <DialogActions>
        {/* <AnimateButton>
          <Button variant="contained" color="primary">
            Create
          </Button>
        </AnimateButton>
        <Button variant="text" onClick={handleCloseDialog} color="primary">
          Close
        </Button> */}
      </DialogActions>
    </ExtendedSideDialog>
  );
};

export default EditHomologationSideDialog;
