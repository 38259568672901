import DeleteAlert from '../../../shared/components/dialogs/DeleteAlert';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { useDeletePeriodTypeMutation } from '../slices/periodTypesApiSlice';
import { resetPeriodTypeToDelete, setDeleteModalOpen } from '../slices/periodTypesSlice';

const DeletePeriodTypeDialog = () => {
  const dispatch = useAppDispatch();

  const { deleteModalOpen, periodTypeIdToDelete } = useAppSelector((state) => state.periodTypes);
  const { currentProfile } = useAppSelector((state) => state.access);

  const [deletePeriodType, { isLoading, isSuccess, isError, error }] =
    useDeletePeriodTypeMutation();

  const handleDelete = () => {
    deletePeriodType({
      profileId: currentProfile?.profileId!,
      periodTypeId: periodTypeIdToDelete!,
    });
  };

  const handleClose = () => {
    dispatch(setDeleteModalOpen(false));
    dispatch(resetPeriodTypeToDelete());
  };

  return (
    <DeleteAlert
      open={deleteModalOpen}
      onClose={handleClose}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      handleDelete={handleDelete}
      error={error as string}
    />
  );
};

export default DeletePeriodTypeDialog;
