import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Company } from '../interfaces/companies.interfaces';
import { ISearchTable } from '../../../shared/interfaces/paginatedTable.interface';

interface CompaniesState {
  company: Company;
  deleteModalOpen: boolean;
  servicesDeleteModalOpen: boolean;
  companyIdToDelete?: number | string | null;
  servicesCompanyIdToDelete?: number | string | null;
  servicesCompanyTable: ISearchTable;
}

const initialState: CompaniesState = {
  company: {} as Company,
  deleteModalOpen: false,
  servicesDeleteModalOpen: false,
  servicesCompanyIdToDelete: null,
  servicesCompanyTable: {
    page: 1,
    perPage: 5,
    search: '',
  },
};

const CompaniesSlice = createSlice({
  name: 'Companies',
  initialState,
  reducers: {
    setCompany: (state, action: PayloadAction<Company>) => {
      state.company = action.payload;
    },
    setDeleteModalOpen: (state, action: PayloadAction<boolean>) => {
      state.deleteModalOpen = action.payload;
    },
    openDeleteModalWith: (state, action: PayloadAction<number>) => {
      state.companyIdToDelete = action.payload;
      state.deleteModalOpen = true;
    },
    resetCompanyIdToDelete: (state) => {
      state.companyIdToDelete = undefined;
    },
    updateServicesCompanyTableStates: (state, action: PayloadAction<ISearchTable>) => {
      state.servicesCompanyTable = action.payload;
    },
    setServicesDeleteModalOpen: (state, action: PayloadAction<boolean>) => {
      state.servicesDeleteModalOpen = action.payload;
    },
    setServicesCompanyIdToDelete: (state, action: PayloadAction<number | string | null>) => {
      state.servicesCompanyIdToDelete = action.payload;
    },
  },
});

export const {
  setCompany,
  setDeleteModalOpen,
  openDeleteModalWith,
  resetCompanyIdToDelete,
  updateServicesCompanyTableStates,
  setServicesDeleteModalOpen,
  setServicesCompanyIdToDelete,
} = CompaniesSlice.actions;

export default CompaniesSlice.reducer;
