import { Grid, Typography, Autocomplete, TextField, Button, IconButton } from '@material-ui/core';
import MainCard from '../../../../shared/components/cards/MainCard';
import AssignmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import { useAppSelector } from '../../../../../hooks/useRedux';
import { useHistory, useParams } from 'react-router-dom';
import { useMemo, useState } from 'react';
import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
//import LoadingData from '../../../../shared/components/cards/Skeleton/LoadingData';
import SearchPaginatedTable from '../../../../shared/components/tables/SearchPaginatedTable';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import ButtonBack from '../../Components/ButtonBack';
import Chip from '../../../../shared/components/extended/Chip';
import { useGetStudentsActivitiesQuery } from '../../slices/virtualClassroomTeacherApiSlice';

function TaskDetail() {
  const { idtarea } = useParams<{ idtarea: string }>();
  const {
    course: { courseId },
  } = useAppSelector((state) => state.virtualClassroomTeacherShared);

  const History = useHistory();
  const path = window.location.pathname;

  const { t } = useTranslation();

  const [page, setPage] = useState<number>(1);

  const [perPage, setPerPage] = useState<number>(5);

  const [search, setSearch] = useState<string>('');

  // const { currentProfile } = useAppSelector((state) => state.access);

  function CalificarTask(studentId: number) {
    History.push(`${path}/student/${studentId}`);
  }

  const { data, isLoading } = useGetStudentsActivitiesQuery({
    cursoId: courseId,
    activityId: Number(idtarea),
  });

  const dataFetch = useMemo(() => {
    // TODO: Parche temporal
    return (Array.isArray(data?.participants!) ? data?.participants! : [])?.map(
      (e) =>
        ({
          id: e.id,
          nombre: `${e.student.user.person.pers_firstname}  ${e.student.user.person.pers_first_lastname}  ${e.student.user.person.pers_second_lastname} `,
          Grupo: e.csat_group,
          EntregaTarea:
            e?.cab_activity_student! !== null
              ? moment(e?.cab_activity_student?.act_start_date).format('LLL')
              : '-',

          Calificado:
            e?.cab_activity_student! !== null &&
            Number(e?.cab_activity_student?.answer_qualified!) > 0
              ? 'Calificado'
              : 'Sin Calificar',
          Calificacion:
            Number(e?.cab_activity_student?.answer_qualified!) > 0
              ? `${e?.cab_activity_student?.act_score!}/${data?.act_score_activity}`
              : null,
          Acciones: e?.student?.id,
        } || [])
    );
  }, [data]);

  return (
    <MainCard
      title="Tareas"
      sx={{
        borderTopLeftRadius: '0px',
        borderTopRightRadius: '0px',
      }}
    >
      <Grid display={'flex'} width="100%" justifyContent={'space-between'} alignItems="center">
        <Grid display={'inline-flex'} width="max-content" alignItems="center">
          <AssignmentOutlinedIcon fontSize="large" />
          <Typography color="black" fontWeight={'bold'} variant="h3">
            {data?.act_description}
          </Typography>
        </Grid>
        <Grid>
          <Grid display={'flex'} alignItems="center">
            <Typography variant="body1" style={{ marginRight: '5px' }}>
              Estado de la Tarea
            </Typography>
            <Autocomplete
              fullWidth
              style={{ width: '200px' }}
              disablePortal
              options={[]}
              renderInput={(params) => <TextField {...params} label="Estado de la Tarea" />}
            />
          </Grid>
        </Grid>
        <ButtonBack />
      </Grid>

      <SearchPaginatedTable
        data={dataFetch}
        page={page}
        setPage={setPage}
        perPage={perPage}
        setPerPage={setPerPage}
        isLoading={isLoading}
        search={search}
        isFetching={false}
        error={undefined}
        numHeader={5}
        setSearch={setSearch}
        keyExtractor={(item) => item?.id?.toString()}
        searchPlacehoder="Buscar tarea"
        ActionButtons={<></>}
        showFilter={false}
        perPageBox={false}
        total={5}
        headers={{
          id: t('teacherVirtualClassroom.tasks.table.cod'),
          nombre: t('teacherVirtualClassroom.tasks.grupo.table.estudiante'),
          Grupo: t('teacherVirtualClassroom.tasks.grupo.table.grupo'),
          EntregaTarea: 'Fecha Entrega',
          Calificado: 'Estado',
          Calificacion: 'Calificación',
          Acciones: t('teacherVirtualClassroom.tasks.grupo.table.options'),
        }}
        customRenderers={{
          Calificado: (row) =>
            row.Calificado === 'Calificado' ? (
              <Chip chipcolor="success" label="Calificado" />
            ) : data?.act_is_score! === '0' ? (
              'No es calificable'
            ) : (
              <Chip
                chipcolor="error"
                label={t('teacherVirtualClassroom.tasks.table.sincalficar')}
              />
            ),

          Acciones: (row) =>
            row.Calificado === 'Calificado' ? (
              <IconButton onClick={() => CalificarTask(row.Acciones)}>
                <AssignmentTurnedInOutlinedIcon style={{ fontSize: '30px' }} color="primary" />
              </IconButton>
            ) : (
              <Button
                onClick={() => CalificarTask(row.Acciones)}
                variant="contained"
                disabled={
                  //   new Date(TaskInfoDetail!?.course_activity_one?.cact_end_date).getTime() >
                  //     Date.now() ||
                  data?.act_is_score! === '0'
                }
                color="primary"
              >
                {t('teacherVirtualClassroom.tasks.table.calficar')}
              </Button>
            ),
        }}
        customDataCellsProperties={{
          id: {
            align: 'center',
          },
          nombre: {
            align: 'center',
          },
          EntregaTarea: {
            align: 'center',
          },
          Calificado: {
            align: 'center',
          },
          Acciones: {
            align: 'center',
          },
          Calificacion: {
            align: 'center',
          },
          Grupo: {
            align: 'center',
          },
        }}
      />

      {/*!isLoading ? <SearchPaginatedTable /> : <LoadingData />*/}
    </MainCard>
  );
}

export default TaskDetail;
