import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Slide,
  SlideProps,
  Stack,
  Typography,
  useTheme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { useAppSelector } from '../../../../../hooks/useRedux';
import IconTelework from '../../../../shared/components/illustrations/IconTelework';
import { useAddCourseStudentOnlineMutation } from '../../slices/subjectsRegisterApiSlice';
import SubjectOnlineCard from './SubjectOnlineCard';
import useStudentInfo from '../../hooks/useStudentInfo';
import { Course } from '../../interfaces/courseOnline.interfaces';
import ErrorAlert from '../../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../../shared/components/alerts/SuccessAlert';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
// import SubjectRegisteredCard from '../SubjectRegisteredCard';

const useStyles = makeStyles((theme) => ({
  userAddDialog: {
    '&>div:nth-child(3)': {
      justifyContent: 'flex-end',
      '&>div': {
        margin: '0px',
        borderRadius: '0px',
        maxWidth: '450px',
        maxHeight: '100%',
      },
    },
  },
  paper: {
    minHeight: '100vh',
    width: '33%',
  },
  learningComponents: {
    fontSize: '16px',
    fontWeight: 600,
    color: theme.palette.primary.main,
    marginBottom: '8px',
  },
  selectSubject: {
    fontSize: '16px',
    fontWeight: 600,
    color: theme.palette.primary[800],
    marginBottom: '8px',
  },
  selectSubjectType: {
    fontSize: '14px',
    fontWeight: 600,
    color: theme.palette.secondary.main,
    marginBottom: '8px',
  },
}));

const Transition = React.forwardRef(function Transition(props: SlideProps, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

interface Props {
  open: boolean;
  handleCloseDialog: () => void;
}
const SubjectsRegisterOnline = ({ open, handleCloseDialog }: Props) => {
  const { t } = useTranslation();

  const [showAlerts, setShowAlerts] = useState(true);
  const theme = useTheme();
  const { studentId } = useParams<{ studentId: string }>();
  const { carSubjects, currentPeriodId, subjectsCurriculum } = useAppSelector(
    (state) => state.subjectsRegister
  );
  // const { userInfo } = useAppSelector((state) => state.auth);
  const studentInfo = useStudentInfo();
  //   const dispatch = useAppDispatch();

  const classes = useStyles();

  const [addCourseOnline, { isError, error, isSuccess, isLoading }] =
    useAddCourseStudentOnlineMutation();

  const handleSave = async () => {
    const courses: Partial<Course>[] = carSubjects.map((cs) => {
      let subject = subjectsCurriculum?.find((sc) => sc.matter.id === cs.matter.id);
      return {
        course_id: cs.id,
        subject_id: cs.matter.id,
        course_description: cs.matter.mat_description,
        subject_curriculum_id: subject?.id,
        order: subject?.order,
      };
    });
    await addCourseOnline({
      courseOnlinePayload: {
        student_record_id: studentInfo?.studentRecordId,
        student_id: Number(studentId),
        period_id: currentPeriodId,
        curriculum_id: studentInfo?.curriculumId,
        status_id: 1,
        courses,
      },
    });

    setShowAlerts(true);
  };

  const subjectCoursing = carSubjects.map((c) => {
    return <SubjectOnlineCard course={c} key={c.id} />;
  });

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => {
        handleCloseDialog();
      }}
      className={classes.userAddDialog}
      classes={{
        paper: classes.paper,
      }}
    >
      {/* <PerfectScrollbar> */}
      <DialogContent>
        <Typography variant="h2">{t('students.subjects.registration.online.title')}</Typography>

        <Grid item xs={12} mb={2} mt={2}>
          {isError && showAlerts && (
            <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
          )}

          {isSuccess && showAlerts && (
            <SuccessAlert
              message={t('students.subjects.registration.online.success')}
              handleDismiss={() => setShowAlerts(false)}
            />
          )}
        </Grid>

        {carSubjects.length !== 0 ? (
          <Stack spacing={2} sx={{ mt: 4 }}>
            {subjectCoursing}
          </Stack>
        ) : (
          <Box>
            <Grid
              container
              justifyContent={'center'}
              alignContent={'center'}
              sx={{ height: '80vh' }}
            >
              <Grid container justifyContent={'center'} item xs={8} md={6}>
                <Grid container item xs={12} justifyContent={'center'}>
                  <IconTelework size={300} />
                </Grid>
                <Typography variant={'body1'} textAlign={'center'} lineHeight={2} component={'div'}>
                  <Typography
                    variant={'h4'}
                    sx={{ color: theme.palette.primary.main }}
                    component={'p'}
                    align={'center'}
                    lineHeight={2}
                  >
                    {t(
                      'students.subjects.registration.registered_subjects.modal.period.placeholder'
                    )}
                  </Typography>
                  {t('students.subjects.registration.registered_subjects.modal.period.subtitle')}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        )}
      </DialogContent>
      {/* </PerfectScrollbar> */}
      <DialogActions>
        <Stack direction="row" spacing={2}>
          <Button
            variant="contained"
            onClick={() => {
              handleSave();
            }}
            color="primary"
            disabled={isLoading}
            endIcon={isLoading && <CircularProgress size={20} />}
          >
            {t('students.subjects.registration.curriculum.dialog.register')}
          </Button>
          <Button
            variant="text"
            onClick={() => {
              handleCloseDialog();
            }}
            color="primary"
          >
            {t('students.subjects.registration.registered_subjects.modal.cancel')}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default SubjectsRegisterOnline;
