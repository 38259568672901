import { Button, Grid } from '@material-ui/core';
import { IconBook } from '@tabler/icons';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/useRedux';
import { useTableSearch } from '../../../../../../hooks/useTableSearch';
import LocalizedDatePicker from '../../../../../shared/components/controlled/LocalizedDatePicker';
import SearchPaginatedTableAndCollapsibleItems from '../../../../../shared/components/tables/SearchPaginatedTableAndCollapsibleItems';

import { useGetFormsUserListQuery } from '../slices/formsApiSlice';
import {
  setFormTable,
  setStart_date,
  setEnd_date,
  setFormUserSelected,
  setOpenDialogAcceptResult,
} from '../slices/formSlice';

const FormsTableUser = () => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const {
    formTable: { page, perPage: size, search },
    start_date,
    end_date,
  } = useAppSelector((state) => state.formsEva);

  const { setPage, setPerPage, setSearch } = useTableSearch({
    page,
    perPage: size,
    search,
    updateFunction: setFormTable,
  });

  const {
    data: formUserList,
    isFetching,
    isLoading,
    error,
  } = useGetFormsUserListQuery({
    page,
    perPage: size,
    search,
    start_date: start_date.split('T')[0],
    end_date: end_date.split('T')[0],
  });

  const dataFetch =
    formUserList?.data.map((item, index) => ({
      numberRow: (page - 1) * size + (index + 1),
      period: item.period?.per_name,
      education_level: item.education_level?.edu_name,
      eva_rubrica: item.eva_rubrica?.name,
      dateRealizated: item.date_accept_result || 'Sin aceptar',
      option: item,
    })) || [];

  return (
    <>
      <Grid container item xs={6} spacing={2}>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={6}
          sx={{
            mb: 2,
          }}
        >
          <Grid container spacing={1} alignItems="flex-start">
            <Grid item xs zeroMinWidth>
              <LocalizedDatePicker
                value={start_date}
                onChange={(newValue) => {
                  dispatch(setStart_date((newValue as Date).toISOString()));
                }}
                label={t('financial.deposit_transfers.filters.dates.start_label')}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Grid container spacing={1} alignItems="flex-start">
            <Grid item xs zeroMinWidth>
              <LocalizedDatePicker
                value={end_date}
                onChange={(newValue) => {
                  dispatch(setEnd_date((newValue as Date).toISOString()));
                }}
                label={t('financial.deposit_transfers.filters.dates.end_label')}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <SearchPaginatedTableAndCollapsibleItems
        data={dataFetch}
        headers={{
          numberRow: t('N°'),
          period: t('Periodo'),
          education_level: t('Unidad Académica'),
          eva_rubrica: t('Rúbrica'),
          dateRealizated: t('Fecha de aceptación de resultados'),
          option: t('Opciones'),
        }}
        keyExtractor={(item) => `${item.option.id}`}
        searchPlacehoder={t('Buscar...')}
        isLoading={isLoading}
        isFetching={isFetching}
        error={error}
        perPage={size}
        setPerPage={setPerPage}
        total={formUserList?.total || 0}
        page={page}
        setPage={setPage}
        numHeader={6}
        search={search}
        setSearch={setSearch}
        customRenderers={{
          option: (item) => (
            <Grid container justifyContent="center">
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    dispatch(setFormUserSelected(item.option));
                    dispatch(setOpenDialogAcceptResult(true));
                  }}
                >
                  <IconBook />
                </Button>
              </Grid>
            </Grid>
          ),
        }}
      />
    </>
  );
};

export default FormsTableUser;
