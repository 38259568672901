import { useEffect } from 'react';
import {
  Dialog,
  Grid,
  Button,
  Typography,
  Skeleton,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
  CircularProgress,
} from '@material-ui/core';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/useRedux';
import SimpleTable from '../../../../../shared/components/tables/SimpleTable';
import { closeModalSupply } from '../../../slices/virtualClassroomTeacherSlice';
import {
  useGetSupplyActivitiesListQuery,
  useGetSupplyConfigByCourseQuery,
  useAddGenerateScorePassMutation,
} from '../../../slices/virtualClassroomTeacherApiSlice';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useState } from 'react';
import SuccessAlert from '../../../../../shared/components/alerts/SuccessAlert';

interface sendData {
  activity_id: number;
  peso: number | null;
}

const ModalSettings: React.FC<{ open: boolean }> = ({ open }) => {
  const dispatch = useAppDispatch();
  const { configurateInsumo } = useAppSelector((state) => state.virtualClassroomTeacher);

  const [showAlerts, setShowAlerts] = useState(true);

  const {
    course: { courseId },
  } = useAppSelector((state) => state.virtualClassroomTeacherShared);

  const { data, isFetching, isError, error } = useGetSupplyActivitiesListQuery({
    courseId,
    distributionsId: configurateInsumo?.idDistribution!,
    supplyId: configurateInsumo?.idInsumo!,
  });

  const [
    addGenerateScore,
    {
      isLoading: isLoadingAddScore,
      isError: isErrorAddScore,
      error: errorAddGenerate,
      isSuccess: isSuccessAddGenerate,
    },
  ] = useAddGenerateScorePassMutation();

  const [sendValues, setSendValues] = useState<sendData[]>([] as sendData[]);

  const totalWeight = (): number => {
    let total = 0;
    sendValues.forEach((value) => {
      total += value.peso || 0;
    });
    return total;
  };

  const getInfoValueById = (id: number): sendData => {
    return sendValues.find((value) => value.activity_id === id) || ({} as sendData);
  };

  const handleSelectValues = (value: sendData) => {
    if (sendValues?.some((Currentvalue) => Currentvalue.activity_id === value.activity_id)) {
      setSendValues(sendValues?.filter((item) => !(item.activity_id === value.activity_id)));
    } else {
      setSendValues([...sendValues, value]);
    }
  };

  const handleTextfieldChange = (value: number, id: number) => {
    if (sendValues?.some((Currentvalue) => Currentvalue.activity_id === id)) {
      const dataCurrent = sendValues?.filter((item) => !(item.activity_id === id));
      setSendValues([...dataCurrent, { activity_id: id, peso: value }]);
    }
  };

  const submitData = () => {
    addGenerateScore({
      courseId,
      distributionsId: configurateInsumo?.idDistribution!,
      supplyId: configurateInsumo?.idInsumo!,
      generateScorePassPayload: {
        type_calculation: dataConfig?.type_calculation || '',
        activities: sendValues,
      },
    });
  };

  const {
    data: dataConfig,
    isFetching: isFetchingConfig,
    isError: isErrorConfig,
    error: errorConfig,
  } = useGetSupplyConfigByCourseQuery({
    courseId,
    distributionsId: configurateInsumo?.idDistribution!,
    supplyId: configurateInsumo?.idInsumo!,
  });

  const CloseModal = () => {
    dispatch(closeModalSupply());
  };

  const Data = data?.map((item) => ({
    check: item.id,
    name: item.act_description,
    score: item.act_score_activity,
    percent: item.promedio_activity,
    ...(dataConfig?.type_calculation.toLowerCase() === 'suma' && {
      weight: item.peso_sugerido || 0,
    }),
  }));

  const IsRequiredWeight = ['suma'].some(
    (value) => value === dataConfig?.type_calculation?.toLowerCase()
  );

  useEffect(() => {
    setSendValues(
      data?.map((item) => ({ activity_id: item.id, peso: item.peso_sugerido || null })) ||
        ([] as sendData[])
    );
  }, [data]);

  return (
    <Dialog open={open} onClose={CloseModal} fullWidth maxWidth="md">
      <Grid padding="20px" container justifyContent="space-between">
        <Typography variant="h4"> {configurateInsumo?.distributionName}</Typography>
        <Grid display="flex" gap={2} justifyContent={'center'}>
          <Typography color="primary" variant="h5">
            Comportamiento
          </Typography>
          <Typography variant="h5">
            {isFetchingConfig
              ? 'Cargando...'
              : isErrorConfig
              ? errorConfig
              : dataConfig?.type_calculation || 'Nota sin configuración'}
          </Typography>
        </Grid>
      </Grid>
      {isErrorAddScore && errorAddGenerate}
      {isSuccessAddGenerate && showAlerts && (
        <SuccessAlert message="Notas actualizadas" handleDismiss={() => setShowAlerts(false)} />
      )}
      <PerfectScrollbar>
        <Grid container padding="20px">
          <Grid item xs={12} height={'40vh'}>
            {!isFetching && !isFetchingConfig ? (
              <>
                <SimpleTable
                  data={Data || []}
                  headers={{
                    check: 'Seleccion',
                    name: 'Nombre',
                    score: 'Puntaje ',
                    percent: 'Porcentaje',
                    weight: IsRequiredWeight ? 'Peso' : '',
                  }}
                  customRenderers={{
                    percent: (item) => (
                      <Grid container justifyContent="center" alignItems="center" key={item.check}>
                        {item.percent}%
                      </Grid>
                    ),
                    weight: (item) => (
                      <Grid container justifyContent="center" alignItems="center" key={item.check}>
                        <TextField
                          id="standard-basic"
                          defaultValue={item.weight}
                          variant="standard"
                          disabled={!sendValues.some((value) => value.activity_id === item.check)}
                          InputLabelProps={{ shrink: true }}
                          InputProps={{
                            inputProps: {
                              min: 0,
                              max:
                                totalWeight() <= 100
                                  ? (getInfoValueById(item.check).peso || 0) + 100 - totalWeight()
                                  : 0,
                            },
                          }}
                          type="number"
                          onChange={(e) =>
                            handleTextfieldChange(Number(e.target.value), item.check)
                          }
                        />
                      </Grid>
                    ),
                    check: (item) => (
                      <Grid container justifyContent="center" alignItems="center" key={item.check}>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                defaultChecked={true}
                                onChange={() =>
                                  handleSelectValues({
                                    activity_id: item.check,
                                    peso:
                                      sendValues?.find((value) => value.activity_id === item.check)
                                        ?.peso ||
                                      item.weight ||
                                      0,
                                  })
                                }
                              />
                            }
                            label=""
                          />
                        </FormGroup>
                      </Grid>
                    ),
                  }}
                  keyExtractor={(item) => item.check + ''}
                />
                {!Data?.length && !isError && (
                  <Typography variant="body1" textAlign={'center'} mt={2}>
                    No existen actividades registradas
                  </Typography>
                )}
                {isError && (
                  <Typography variant="h5" textAlign={'center'} mt={2}>
                    {error}
                  </Typography>
                )}
              </>
            ) : (
              <Skeleton sx={{ height: '20vh', width: '300px' }} />
            )}
          </Grid>
        </Grid>
      </PerfectScrollbar>
      <Grid container display="flex" p={1} justifyContent="center">
        {IsRequiredWeight && (
          <Grid container display="flex" gap={3} my={2} justifyContent="right">
            {totalWeight() !== 100 && (
              <Typography variant="h6" textAlign={'end'} color={'red'}>
                El peso debe ser del 100%
              </Typography>
            )}
            <Typography variant="h6" textAlign={'end'}>
              Peso: {totalWeight()}%/100%
            </Typography>
          </Grid>
        )}

        <Grid container display="flex" gap={3} mt={1} justifyContent="right">
          <Button variant="outlined" onClick={CloseModal}>
            Salir
          </Button>
          <Button
            variant="contained"
            disabled={
              isLoadingAddScore ||
              (IsRequiredWeight && totalWeight() !== 100) ||
              !dataConfig?.type_calculation
            }
            endIcon={isLoadingAddScore && <CircularProgress size={20} />}
            onClick={submitData}
          >
            Guardar
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default ModalSettings;
