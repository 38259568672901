import { FC, useEffect, useRef, useState } from 'react';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  ClickAwayListener,
  IconButton,
  Paper,
  Popper,
  Tooltip,
  // useMediaQuery,
  useTheme,
} from '@material-ui/core';

// project imports
import Transitions from '../extended/Transitions';
import FilterListIcon from '@material-ui/icons/FilterListTwoTone';

// style constant
const useStyles = makeStyles((theme) => ({
  ScrollHeight: {
    height: '100%',
    maxHeight: 'calc(100vh - 205px)',
    overflowX: 'hidden',
  },
  headerAvatar: {
    ...theme.typography.commonAvatar,
    ...theme.typography.mediumAvatar,
    transition: 'all .2s ease-in-out',
    background:
      theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
    color:
      theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.secondary.dark,
    '&[aria-controls="menu-list-grow"],&:hover': {
      background:
        theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.secondary.dark,
      color:
        theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.secondary.light,
    },
  },
  cardContent: {
    padding: '0px !important',
  },
  notificationChip: {
    color: theme.palette.background.default,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.warning.dark,
  },
  divider: {
    marginTop: 0,
    marginBottom: 0,
  },
  cardAction: {
    padding: '10px',
    justifyContent: 'center',
  },
  paddingBottom: {
    paddingBottom: '16px',
  },
  box: {
    marginLeft: '16px',
    marginRight: '24px',
    [theme.breakpoints.down('sm')]: {
      marginRight: '16px',
    },
  },
  bodyPPacing: {
    padding: '16px 16px 0',
  },
  textBoxSpacing: {
    padding: '0px 16px',
  },
}));

//-----------------------|| POPPER ||-----------------------//

interface Props {
  /**
   * If true will not close onCLick outside
   */
  onClickAwayNotClose?: boolean;
  titleButton?: string;
  iconButton?: JSX.Element;
  //   open: boolean;
  //   handleToogle: () => void;
  //   handleClose: (event: React.MouseEvent<HTMLDivElement> | MouseEvent | TouchEvent) => void;
}

const CustomPopper: FC<Props> = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  // const matchesXs = useMediaQuery(theme.breakpoints.down('sm'));

  const { children, onClickAwayNotClose, iconButton, titleButton } = props;

  const [open, setOpen] = useState(false);
  /**
   * anchorRef is used on different componets and specifying one type leads to other components throwing an error
   * */
  const popperRef = useRef<any>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    if (onClickAwayNotClose) return;
    setOpen(false);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      popperRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <Box component="span" className={classes.box}>
        <Tooltip title={titleButton ? titleButton : `Filtros`}>
          <IconButton
            ref={popperRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            color="inherit"
          >
            {iconButton ? iconButton : <FilterListIcon />}
          </IconButton>
        </Tooltip>
      </Box>
      <Popper
        // placement={matchesXs ? 'bottom' : 'bottom-end'}
        placement={'bottom-end'}
        open={open}
        anchorEl={popperRef.current}
        role={undefined}
        transition
        // popperOptions={{
        //   modifiers: [
        //     {
        //       name: 'offset',
        //       options: {
        //         offset: [matchesXs ? 5 : 0, 20],
        //       },
        //     },
        //   ],
        // }}
      >
        {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <Box sx={{ boxShadow: theme.customShadows.primary, borderRadius: '12px' }}>
                  {children}
                </Box>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </>
  );
};

export default CustomPopper;
