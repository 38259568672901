import {
  Autocomplete,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { /*useEffect,*/ useEffect, useState } from 'react';
import { useAppSelector } from '../../../../hooks/useRedux';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import TextFieldFetching from '../../../shared/components/TextFieldFetching';
import { requalificationRequestValues } from '../constants/formConstants';
import { requalificationSchema } from '../constants/validationSchemas';
//import { Requalification, Supplies } from '../interfaces/requalification.interface';
import {
  useAddRequalificationRequestMutation,
  useGetPartialByCourseStudentQuery,
  useGetSubjectsCalificatedQuery,
} from '../slices/academicRequestsApiSlice';
import useGuard from '../hooks/useGuard';

import { useTranslation } from 'react-i18next';
import PeriodStudentSelect from './Builder/PeriodStudentSelect';

const RequalificationForm = () => {
  const { t } = useTranslation();

  const [studentPeriod, setStudentPeriod] = useState(0);

  const { hasAccess } = useGuard();
  const { currentProfile, currentRecordStudent } = useAppSelector((state) => state.access);
  const { userInfo } = useAppSelector((state) => state.auth);
  const {
    data: subjectsCalificated = [],
    isLoading: isLoadingSC,
    isError: isErrorSC,
    error: errorSC,
  } = useGetSubjectsCalificatedQuery(
    {
      profileId: currentProfile?.profileId!,
      studentRecordId: currentRecordStudent?.id!,
      periodId: studentPeriod,
    },
    { skip: !studentPeriod, refetchOnMountOrArgChange: 30 }
  );
  const [showAlerts, setShowAlerts] = useState(false);

  const [addRequalification, { isError, error, isSuccess, isLoading }] =
    useAddRequalificationRequestMutation();

  const { handleSubmit, setFieldValue, errors, touched, values, getFieldProps } = useFormik({
    initialValues: requalificationRequestValues,
    validationSchema: requalificationSchema,
    onSubmit: async (values) => {
      await addRequalification({
        profileId: currentProfile?.profileId!,
        userId: String(userInfo?.id!),
        requalificationRequestPayload: {
          app_description: values.app_description,
          supplie_id: values.supplie_id,
          course_student_id: values.course_student_id,
        },
      });
      setShowAlerts(true);
    },
  });
  const {
    data: partialsSC = [],
    isLoading: isLoadingPartialsSC,
    isFetching: isFetchingPartialsSC,
  } = useGetPartialByCourseStudentQuery(
    {
      profileId: currentProfile?.profileId!,
      courseStudentId: values.course_student_id!,
    },
    { refetchOnMountOrArgChange: 30, skip: !values.course_student_id }
  );

  useEffect(() => {
    if (isErrorSC && errorSC) {
      setShowAlerts(true);
    }
  }, [isErrorSC, errorSC]);

  useEffect(() => {
    if (subjectsCalificated && subjectsCalificated.length === 1) {
      setFieldValue('course_student_id', subjectsCalificated[0].id);
    }
  }, [setFieldValue, subjectsCalificated]);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction="column" gap={2}>
        <Grid container>
          <Grid item>
            {isError && showAlerts && (
              <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
            )}
            {isErrorSC && showAlerts && (
              <ErrorAlert message={errorSC as string} handleDismiss={() => setShowAlerts(false)} />
            )}
            {isSuccess && showAlerts && (
              <SuccessAlert
                message={t('students.applications.success_message')}
                handleDismiss={() => setShowAlerts(false)}
              />
            )}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <PeriodStudentSelect
            changeHandler={setStudentPeriod}
            sideFunc={() => setFieldValue('course_student_id', 0)}
          />
        </Grid>

        <Grid item xs={12}>
          <InputLabel>{t('students.applications.requalificarion.subject')}</InputLabel>
          {!isLoadingSC ? (
            <Autocomplete
              options={Array.isArray(subjectsCalificated) && !isErrorSC ? subjectsCalificated : []}
              loading={isLoadingSC}
              loadingText="Cargando materia..."
              getOptionLabel={(option) =>
                option.subject_curriculum?.matter_rename || option.subject?.mat_name
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(errors.course_student_id && touched.course_student_id)}
                  placeholder={t('students.applications.requalificarion.subject_placeholder')}
                />
              )}
              onChange={(_, value) => setFieldValue('course_student_id', value?.id)}
              fullWidth
              clearIcon={null}
              value={
                subjectsCalificated && subjectsCalificated.length === 1 && !isErrorSC
                  ? subjectsCalificated[0]
                  : subjectsCalificated.find((dt) => dt.id === values.course_student_id) || null
              }
            />
          ) : (
            <TextFieldFetching />
          )}
          {errors.course_student_id && touched.course_student_id && (
            <FormHelperText error>{errors.course_student_id}</FormHelperText>
          )}
        </Grid>
        <Grid item xs={12}>
          <InputLabel>{t('students.applications.requalificarion.supply')}</InputLabel>
          {!isFetchingPartialsSC && (
            <Autocomplete
              options={Array.isArray(partialsSC) ? partialsSC : []}
              loading={isLoadingPartialsSC}
              loadingText="Cargando parcial..."
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(errors.supplie_id && touched.supplie_id)}
                  placeholder={t('students.applications.requalificarion.supply_placeholder')}
                />
              )}
              onChange={(_, value) => setFieldValue('supplie_id', value?.id)}
              fullWidth
              clearIcon={null}
            />
          )}

          {isFetchingPartialsSC && <TextFieldFetching />}
          {errors.supplie_id && touched.supplie_id && (
            <FormHelperText error>{errors.supplie_id}</FormHelperText>
          )}
        </Grid>
        <Grid item xs={12}>
          <InputLabel>{t('students.applications.requalificarion.reason')}</InputLabel>
          <TextField
            multiline
            rows={2}
            fullWidth
            error={Boolean(errors.app_description && touched.app_description)}
            placeholder={t('students.applications.requalificarion.reason_placeholder')}
            {...getFieldProps('app_description')}
          />
          {errors.app_description && touched.app_description && (
            <FormHelperText error>{errors.app_description}</FormHelperText>
          )}
        </Grid>
        <Grid
          item
          container
          alignItems="center"
          justifyContent="flex-end"
          spacing={2}
          sx={{ mt: 1 }}
        >
          {hasAccess('save_requalification') && (
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                size="large"
                color="primary"
                disabled={isLoading}
                endIcon={isLoading && <CircularProgress size={20} />}
              >
                {t('students.applications.save')}
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </form>
  );
};
export default RequalificationForm;
