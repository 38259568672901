import * as Yup from 'yup';

//validaciones para agregar materias a las mallas
export const UpdateApplicationValidationSchema = Yup.object().shape({
  comment: Yup.string().required('academic.homologation.internal.form.comment.required'),
});

export const AddPersonValidationSchema = Yup.object().shape({});

export const EditSubjectScore = Yup.object().shape({
  score: Yup.number(),
  comment: Yup.string().nullable(),
});

export const AddNewSubjectHomologation = Yup.object().shape({
  instSubject: Yup.object()
    .typeError('academic.homologation.external.per_subject.inst_subject.required')
    .required('academic.homologation.external.per_subject.inst_subject.required'),
  subject: Yup.object()
    .typeError('academic.homologation.external.per_subject.subject.required')
    .required('academic.homologation.external.per_subject.subject.required'),
});
export const AddNewSubjectHomologationConfirm = Yup.object().shape({
  relationPct: Yup.number().min(
    1,
    'academic.homologation.external.edit.confirm_subject.relation_percentage.min'
  ),
});

export const AddNewSubjectInstitute = Yup.object().shape({
  name: Yup.string().required('academic.homologation.external.side.add_subject.name.required'),
  description: Yup.string().notRequired(),
});

export const AddInstituteInfoSchema = Yup.object().shape({
  name: Yup.string().required('academic.homologation.external.side.add_institute.name.required'),
  province_id: Yup.number()
    .integer('academic.homologation.external.side.add_institute.province.integer')
    .required('academic.homologation.external.side.add_institute.province.required')
    .min(1, 'academic.homologation.external.side.add_institute.province.min'),
  economic_group_id: Yup.number()
    .integer('academic.homologation.external.side.add_institute.economic_group.integer')
    .required('academic.homologation.external.side.add_institute.economic_group.required')
    .min(1, 'academic.homologation.external.side.add_institute.economic_group.min'),
  type_institute_id: Yup.number()
    .integer('academic.homologation.external.side.add_institute.type.integer')
    .required('academic.homologation.external.side.add_institute.type.required')
    .min(1, 'academic.homologation.external.side.add_institute.type.min'),
  has_agreement: Yup.boolean().required(
    'academic.homologation.external.side.add_institute.agreement.required'
  ),
  status_id: Yup.number()
    .integer('academic.homologation.external.side.add_institute.status.integer')
    .required('academic.homologation.external.side.add_institute.status.required')
    .min(1, 'academic.homologation.external.side.add_institute.status.min'),
});
