import { FC } from 'react';

interface Props {
  isLoading: boolean;
  loadingSkeleton?: JSX.Element;
  isEmpty?: boolean;
  emptyFallback?: JSX.Element;
}

const LoadingChildren: FC<Props> = ({
  children,
  isLoading,
  emptyFallback,
  loadingSkeleton,
  isEmpty,
}) => {
  const Loading = loadingSkeleton ? loadingSkeleton : <div>Loading...</div>;

  const NoDataComponent = emptyFallback ? emptyFallback : <></>;

  return <>{isLoading ? Loading : isEmpty ? NoDataComponent : children}</>;
};

export default LoadingChildren;
