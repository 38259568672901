import { Icon, Theme } from '@material-ui/core';
import { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  imageIcon: {
    display: 'flex',
    height: 'inherit',
    width: 'inherit',
  },
  iconRoot: {
    textAlign: 'center',
  },

  //
}));

interface Props {
  icon: string;
  alt?: string;
  //
  size?: number | undefined;

  //
  customHeight?: number;
  customWidth?: number;
}

/** Un textfield con un endIcon CircularProgress
 * ## Interface
 * ```jsx
 *  interface Props {
 *    // ruta de la imagen
 *    icon: string;
 *    alt?: string;
 *    // Si se manda el size la imagen se escala.
 *    // con size no se aplica el customHeight ni customWidth y viceversa.
 *    size?: number | undefined;
 *    customHeight?: number;
 *    customWidth?: number;
 *    }
 * ```
 */
const CustomIcon: FC<Props> = (props) => {
  const classes = useStyles();
  const { size, icon, alt, customHeight, customWidth } = props;
  return (
    <Icon
      classes={{ root: classes.iconRoot }}
      // sx={{ height: size || '100px', width: size || '100px' }}
      {...(size && !customHeight && !customWidth && { sx: { height: size, width: size } })}
      {...(!size &&
        (customHeight || customWidth) && {
          sx: { height: customHeight || 'auto', width: customWidth || 'auto' },
        })}
    >
      <img className={classes.imageIcon} alt={alt} src={icon} />
    </Icon>
  );
};

export default CustomIcon;
