import {
  Table,
  Button,
  Grid,
  Typography,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
  useTheme,
  Skeleton,
  Tooltip,
} from '@material-ui/core';
import { IconEdit } from '@tabler/icons';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useAppSelector } from '../../../../hooks/useRedux';
import SubCard from '../../../shared/components/cards/SubCard';
import { FC } from 'react';
import { baseUrl } from '../../../../api/mainApi';

interface Props {
  isLoading: boolean;
}

const SocialCard: FC<Props> = (props) => {
  const { company } = useAppSelector((state) => state.companies);
  const { path } = useRouteMatch();
  const history = useHistory();
  const theme = useTheme();

  const { isLoading } = props;

  return (
    <Grid
      item
      sx={{
        width: '100%',
      }}
    >
      <SubCard
        content={false}
        title="Misión y Visión"
        secondary={
          company.co_ruc && (
            <Tooltip title="Editar esta sección" placement="top-end">
              <Button onClick={() => history.push(`${path}/editar-mision-vision`)}>
                <IconEdit stroke={1.5} size="1.3rem" />
              </Button>
            </Tooltip>
          )
        }
      >
        <Grid container p={2}>
          <Grid item xs={12}>
            <TableContainer>
              {isLoading ? (
                <>
                  <Skeleton variant="text" />
                  <Skeleton variant="text" />
                  <Skeleton variant="text" />
                </>
              ) : (
                <Table
                  sx={{
                    '& td': {
                      borderBottom: 'none',
                    },
                  }}
                  size="small"
                >
                  <TableBody>
                    {company.co_mision ? (
                      <TableRow>
                        <TableCell variant="head">Misión</TableCell>
                        <TableCell>:</TableCell>
                        <TableCell>
                          <Typography sx={{ color: theme.palette.text.secondary }}>
                            {company.co_mision}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ) : null}
                    {company.co_vision ? (
                      <TableRow>
                        <TableCell variant="head">Visión</TableCell>
                        <TableCell>:</TableCell>
                        <TableCell>
                          <Typography sx={{ color: theme.palette.text.secondary }}>
                            {company.co_vision}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ) : null}
                    {company.co_director_message ? (
                      <TableRow>
                        <TableCell variant="head">
                          {company?.co_url_file_director_photo && (
                            <img
                              src={`${baseUrl}/show?name=${company?.co_url_file_director_photo}`}
                              alt="Director"
                              style={{
                                height: '100%',
                                width: '100%',
                                objectFit: 'contain',
                                borderRadius: '5px',
                                marginTop: '25px',
                              }}
                            />
                          )}
                          <div
                            style={{
                              textAlign: 'center',
                            }}
                          >
                            Mensaje del director
                          </div>
                        </TableCell>
                        <TableCell>:</TableCell>
                        <TableCell>
                          <Typography sx={{ color: theme.palette.text.secondary }}>
                            {company.co_director_message}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ) : null}
                  </TableBody>

                  {company.co_mision && company.co_vision ? null : (
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <p>No hay datos para mostrar</p>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              )}
            </TableContainer>
          </Grid>
        </Grid>
      </SubCard>
    </Grid>
  );
};

export default SocialCard;
