/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Formik, Field, Form, FieldArray } from 'formik';
import { TextField as FTextField } from 'formik-material-ui';
import { Autocomplete, AutocompleteRenderInputParams } from 'formik-material-ui-lab';

import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  Slide,
  SlideProps,
  Switch,
  TextField,
  Typography,
  Stack,
  FormHelperText,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import PerfectScrollbar from 'react-perfect-scrollbar';

import AnimateButton from '../../../shared/components/extended/AnimateButton';
import InputLabel from '../../../shared/components/forms/InputLabel';
import { MatterMeshValidationSchema } from '../constants/validationSchemas';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import {
  useAssignSubjectCurriculumMutation,
  // useGetCurriculumsSubjectsQuery,
  useGetMeshQuery,
  useUpdateMatterMeshMutation,
} from '../slices/meshsApiSlice';
import { statusAvailables } from '../../../shared/constants/resourceStatus';
import { useGetAvaliblesMattersQuery } from '../../matters/slices/mattersApiSlice';
import { Matter, MatterMesh } from '../interfaces/curriculum.interfaces';
import { Simbology } from '../interfaces/symbology.interfaces';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import { resetCurrentGroup, resetSubjectCurriculumSelected } from '../slices/meshSlice';
import { useGetCalificationModelsAvailablesQuery } from '../../calification-models/slices/calificationModelsApiSlice';
import { CalificationModelAvilable } from '../../calification-models/interfaces/calificationModels.interface';
import ExtendedSideDialog from '../../../shared/components/dialogs/ExtendedSideDialog';
import { useGetAvailablesMatterTypeQuery } from '../../matters-types/slices/mattersTypesApiSlice';

const useStyles = makeStyles((theme) => ({
  userAddDialog: {
    '&>div:nth-child(3)': {
      justifyContent: 'flex-end',
      '&>div': {
        margin: '0px',
        borderRadius: '0px',
        maxWidth: '450px',
        maxHeight: '100%',
      },
    },
  },
  paper: {
    minHeight: '100vh',
    width: '33%',
  },
  learningComponents: {
    fontSize: '16px',
    fontWeight: 600,
    color: theme.palette.primary.main,
    marginBottom: '8px',
  },
  selectSubject: {
    fontSize: '16px',
    fontWeight: 600,
    color: theme.palette.primary[800],
    marginBottom: '8px',
  },
  selectSubjectType: {
    fontSize: '14px',
    fontWeight: 600,
    color: theme.palette.secondary.main,
    marginBottom: '8px',
  },
}));

const Transition = React.forwardRef(function Transition(props: SlideProps, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

interface Props {
  open: boolean;
  handleCloseDialog: () => void;
}

const AddSubjectDialog = ({ open, handleCloseDialog }: Props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [showAlerts, setShowAlerts] = useState(true);

  const { meshId: meshIdParam } = useParams<{ meshId: string }>();
  const meshId = Number(meshIdParam);

  const { currentGroup, subjectCurriculumSelected } = useAppSelector((state) => state.meshs);
  const { currentProfile } = useAppSelector((state) => state.access);
  const [hasPP, setHasPP] = useState(false);
  const profileId = currentProfile!.profileId;

  const { data, isLoading } = useGetMeshQuery({
    profileId,
    meshId,
  });

  const { isLoading: isLoadingTSA } = useGetAvailablesMatterTypeQuery({
    profileId,
    offerId: Number(data?.education_level.offer_id),
  });

  const subjectCurriculumToEdit = data?.matter_mesh.find(
    (sc) => sc.id === subjectCurriculumSelected
  );

  const { data: mattersAvailables, isLoading: isLoadingMA } = useGetAvaliblesMattersQuery(
    {
      profileId,
      // typeCalification: data?.type_calification_id,
    },
    { skip: data?.education_level.offer.off_description === 'oferta_postgrado' }
  );

  const { data: mattersCurrAvailables, isLoading: isLoadingMAC } = useGetAvaliblesMattersQuery(
    {
      profileId,
    },
    { skip: data?.education_level.offer.off_description !== 'oferta_postgrado' }
  );

  const { data: calificationModelsAvailables = [], isLoading: isLoadingCM } =
    useGetCalificationModelsAvailablesQuery({
      profileId,
    });

  const [assignSubjectCurriculum, { isLoading: isAssigning, isError, error, isSuccess }] =
    useAssignSubjectCurriculumMutation();
  const [
    updateMatterMesh,
    {
      isLoading: isUpdating,
      isError: isErrorUpdating,
      error: errorUpdating,
      isSuccess: isSuccessUpdating,
    },
  ] = useUpdateMatterMeshMutation();

  const simbologiesAvailables = data?.education_level.offer.simbologies;

  // const [typeSubjectId, setTypeSubjectId] = useState(
  //   subjectCurriculumToEdit?.matter?.type_matter?.id.toString() || ''
  // );

  const subjectsAvailables =
    (mattersAvailables || mattersCurrAvailables)
      ?.filter(({ id: maId }) => !data?.matter_mesh.some(({ matter }) => maId === matter.id))
      .sort((a, b) =>
        `${a.cod_matter_migration} - ${a.mat_name}`.toLowerCase() >
        `${b.cod_matter_migration} - ${b.mat_name}`.toLowerCase()
          ? 1
          : -1
      ) || [];

  // console.log(setHasPP);
  useEffect(() => {
    if (data)
      for (const learningComponent of data!.learningComponent) {
        if (learningComponent.component.com_acronym === 'PP') {
          setHasPP(true);
          break;
        }
      }
  }, [data]);

  const getLearningComponents = () => {
    let initial =
      data?.learningComponent.map((lc) => ({
        label: lc.component.com_acronym,
        components_id: lc.component.id,
        lea_workload: 0,
        com_name: lc.component.com_name,
      })) || [];

    if (subjectCurriculumToEdit) {
      initial = initial.map((lc) => {
        const existLC = subjectCurriculumToEdit.detail_matter_mesh.find(
          (c) => c.component.id === lc.components_id
        );

        return {
          ...lc,
          lea_workload: Number(existLC?.dem_workload) || 0,
        };
      });
    }

    return initial;
  };

  useEffect(() => {}, []);

  return (
    <ExtendedSideDialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => {
        dispatch(resetCurrentGroup());
        dispatch(resetSubjectCurriculumSelected());
        handleCloseDialog();
      }}
      className={classes.userAddDialog}
      classes={{
        paper: classes.paper,
      }}
    >
      {isLoadingMA || isLoadingMAC || isLoadingCM ? (
        <DialogContent>
          <Grid container justifyContent="center">
            <CircularProgress size={30} />
          </Grid>
        </DialogContent>
      ) : (
        <Formik
          initialValues={{
            isNewCurriculum: data?.curriculum_new || false,
            subject: subjectCurriculumToEdit?.matter || null,
            subjectType: null, // no lo guarda backend
            renameSubject: subjectCurriculumToEdit?.matter_rename || '',
            gradeType:
              calificationModelsAvailables.find(
                (cm) => cm.id === subjectCurriculumToEdit?.calification_models_id
              ) || null,
            minGrade: subjectCurriculumToEdit?.min_calification || 0,
            maxGrade: subjectCurriculumToEdit?.max_calification || 10,
            minGradeAp: subjectCurriculumToEdit?.min_note || 7,
            numFouls: subjectCurriculumToEdit?.num_fouls || 0,
            symbology: subjectCurriculumToEdit?.simbology,
            prerequisites:
              data?.matter_mesh.filter((sa) =>
                subjectCurriculumToEdit?.matter_mesh_prerequisites.some((pr) => pr.id === sa.id)
              ) || [],
            status:
              statusAvailables.find((sa) => sa.id === subjectCurriculumToEdit?.id) ||
              statusAvailables[0],
            learningComponents: getLearningComponents(),
            canHomologateIntern: subjectCurriculumToEdit?.can_internal_homologations || false,
            canHomologateExternal: subjectCurriculumToEdit?.can_external_homologations || false,
            subjectIntegrated: subjectCurriculumToEdit?.sub_cur_integrative_type || false,
            amount_credits: subjectCurriculumToEdit?.amount_credits || 0,
          }}
          validationSchema={MatterMeshValidationSchema}
          onSubmit={async (values) => {
            const simbology = values.symbology! as Simbology;
            const gradeType = values.gradeType! as CalificationModelAvilable;
            const prerequisites = values.prerequisites as MatterMesh[];

            // const components =
            //   values.learningComponents?.map(({ components_id, lea_workload, label }) => ({
            //     components_id,
            //     lea_workload,
            //   })) || [];

            const components =
              values.learningComponents?.map(({ components_id, lea_workload, label }) => ({
                components_id,
                ...(label === 'PP' && !values.subjectIntegrated
                  ? { lea_workload: 0 }
                  : { lea_workload }),
              })) || [];

            if (subjectCurriculumToEdit) {
              updateMatterMesh({
                profileId,
                matterMeshId: subjectCurriculumToEdit.id,
                subjectCurriculumAssginPayload: {
                  matter_id: values.subject?.id,
                  mesh_id: meshId,
                  simbology_id: simbology.id,
                  is_prerequisite: false,
                  min_note: values.minGradeAp,
                  min_calification: values.minGrade,
                  max_calification: values.maxGrade,
                  num_fouls: values.numFouls,
                  matter_rename: values.renameSubject,
                  group: currentGroup!,
                  calification_models_id: gradeType.id,
                  prerequisites: prerequisites.map((pr) => pr.id),
                  components,
                  status_id: 1,
                  can_external_homologations: values.canHomologateExternal,
                  can_internal_homologations: values.canHomologateIntern,
                  sub_cur_integrative_type: values.subjectIntegrated,
                  amount_credits: values.amount_credits,
                },
              });
            } else {
              assignSubjectCurriculum({
                profileId,
                subjectCurriculumAssginPayload: {
                  matter_id: values.subject?.id,
                  mesh_id: meshId,
                  simbology_id: simbology.id,
                  is_prerequisite: false,
                  // min_note: values.minGradeAp,
                  // min_calification: values.minGrade,
                  // max_calification: values.maxGrade,
                  // num_fouls: values.numFouls,
                  matter_rename: values.renameSubject,
                  group: currentGroup!,
                  calification_models_id: gradeType.id,
                  prerequisites: prerequisites.map((pr) => pr.id),
                  components,
                  status_id: values.status.id,
                  can_external_homologations: values.canHomologateExternal,
                  can_internal_homologations: values.canHomologateIntern,
                  sub_cur_integrative_type: values.subjectIntegrated,
                  amount_credits: values.amount_credits,
                },
              });
            }
          }}
        >
          {({ errors, touched, values, getFieldProps, setFieldValue }) => (
            <Form>
              <PerfectScrollbar>
                <DialogContent>
                  <Grid container spacing={2}>
                    {!subjectCurriculumSelected ? (
                      <Grid item xs={12}>
                        {isError && showAlerts && (
                          <ErrorAlert
                            message={error as string}
                            handleDismiss={() => setShowAlerts(false)}
                          />
                        )}

                        {isSuccess && showAlerts && (
                          <SuccessAlert
                            message="La materia se ha asignado correctamente a la malla."
                            handleDismiss={() => setShowAlerts(false)}
                          />
                        )}
                      </Grid>
                    ) : (
                      <Grid item xs={12}>
                        {isErrorUpdating && showAlerts && (
                          <ErrorAlert
                            message={errorUpdating as string}
                            handleDismiss={() => setShowAlerts(false)}
                          />
                        )}

                        {isSuccessUpdating && showAlerts && (
                          <SuccessAlert
                            message="La materia se ha actualizado correctamente a la malla."
                            handleDismiss={() => setShowAlerts(false)}
                          />
                        )}
                      </Grid>
                    )}

                    {/* Seleccionar tipo de materia */}
                    {/* <Grid
                      item
                      xs={12}
                      hidden={data?.education_level?.offer?.off_description !== 'oferta_postgrado'}
                    >
                      <Typography>Seleccionar tipo de materia</Typography>
                      <MAutocomplete
                        options={typeSubjectAvailables}
                        getOptionLabel={(option) => option.tm_name}
                        loading={isLoadingMAC || isLoadingTSA}
                        renderInput={(params) => (
                          <TextField {...params} placeholder="Seleccione un tipo de materia" />
                        )}
                        defaultValue={subjectCurriculumToEdit?.matter.type_matter || null}
                        onChange={(_, value) => {
                          setTypeSubjectId(value ? value?.id.toString() : '');
                        }}
                        fullWidth
                        clearIcon={null}
                      />
                    </Grid> */}

                    {/* Seleccionar materia */}
                    <Grid item xs={12}>
                      <Typography className={classes.selectSubject}>Seleccionar materia</Typography>
                      <Field
                        name="subject"
                        component={Autocomplete}
                        options={subjectsAvailables}
                        loading={isLoadingMA || isLoadingMAC || isLoadingTSA}
                        noOptionsText="No quedan materias por asignar"
                        getOptionLabel={(option: any) =>
                          `${
                            option.cod_matter_migration ? `${option.cod_matter_migration} - ` : ''
                          }${option.mat_name}`
                        }
                        // groupBy={(option: any) => option.mat_payment_type}
                        fullWidth
                        {...(subjectCurriculumToEdit && {
                          onChange: (_: any, value: Matter) => {
                            setFieldValue('subject', value || null);
                            setFieldValue('renameSubject', value?.mat_name || '');
                          },
                        })}
                        renderInput={(params: AutocompleteRenderInputParams) => (
                          <TextField
                            {...params}
                            name="subject"
                            placeholder="Seleccione una materia"
                            error={touched.subject && !!errors.subject}
                            helperText={touched.subject && errors.subject}
                          />
                        )}
                      />
                    </Grid>

                    {/* Renombre de materia */}
                    <Grid item xs={12}>
                      <InputLabel>Renombre de Materia (Opcional)</InputLabel>
                      <Field
                        name="renameSubject"
                        component={FTextField}
                        placeholder="Nuevo nombre para la materia"
                        fullWidth
                      />
                    </Grid>
                    {/* Tipo de calificacion */}
                    <Grid item xs={12}>
                      <InputLabel>Modelo de Calificación</InputLabel>
                      <Field
                        name="gradeType"
                        component={Autocomplete}
                        options={calificationModelsAvailables}
                        loading={isLoadingCM}
                        getOptionLabel={(option: any) => option.cal_mod_name}
                        renderInput={(params: AutocompleteRenderInputParams) => (
                          <TextField
                            {...params}
                            name="gradeType"
                            placeholder="Seleccione un modelo de calificación"
                            error={touched.gradeType && !!errors.gradeType}
                            helperText={touched.gradeType && errors.gradeType}
                          />
                        )}
                      />
                    </Grid>
                    {/* Calif minima y maxima */}
                    {/* <Grid item xs={12} container spacing={2}>
                      <Grid item xs={6}>
                        <InputLabel>Min. Calif.</InputLabel>
                        <Field name="minGrade" type="number" component={FTextField} />
                      </Grid>
                      <Grid item xs={6}>
                        <InputLabel>Max. Calif.</InputLabel>
                        <Field name="maxGrade" type="number" component={FTextField} />
                      </Grid>
                    </Grid> */}
                    {/* Nota minima y faltas */}
                    {/* <Grid item xs={12} container spacing={2}>
                      <Grid item xs={6}>
                        <InputLabel>Nota Mínima (Aprobación)</InputLabel>
                        <Field name="minGradeAp" type="number" component={FTextField} />
                      </Grid>
                      <Grid item xs={6}>
                        <InputLabel>Faltas</InputLabel>
                        <Field name="numFouls" type="number" component={FTextField} />
                      </Grid>
                    </Grid> */}
                    {/* Simbologia */}
                    <Grid item xs={12}>
                      <InputLabel>Simbología</InputLabel>
                      <Field
                        name="symbology"
                        component={Autocomplete}
                        options={simbologiesAvailables}
                        loading={isLoading}
                        loadingText="Cargando simbologías..."
                        noOptionsText="No hay simbologías disponibles"
                        getOptionLabel={(option: any) => option.sim_description}
                        renderInput={(params: AutocompleteRenderInputParams) => (
                          <TextField
                            {...params}
                            name="symbology"
                            placeholder="Seleccione la simbología de la materia"
                            error={touched.symbology && !!errors.symbology}
                            helperText={touched.symbology && errors.symbology}
                          />
                        )}
                      />
                    </Grid>

                    {/* Numero de creditos por materia  */}
                    <Grid item xs={12}>
                      <InputLabel>
                        Número {values?.subject?.mat_payment_type || 'créditos'} por materia
                      </InputLabel>
                      <Field
                        fullWidth
                        name="amount_credits"
                        type="number"
                        placeholder={`Número ${
                          values?.subject?.mat_payment_type || 'créditos'
                        } por materia`}
                        component={FTextField}
                      />
                    </Grid>
                    {/* Componentes de aprendizaje */}
                    <Grid item xs={12}>
                      <Typography className={classes.learningComponents}>
                        Componentes de aprendizaje
                      </Typography>

                      <Grid container spacing={2}>
                        {!isLoading && (
                          <FieldArray
                            name="learningComponents"
                            render={(arrayHelpers) => (
                              <>
                                {values.learningComponents &&
                                  values.learningComponents.length > 0 &&
                                  values.learningComponents
                                    // .filter(
                                    //   (dt) => !(!values.subjectIntegrated && dt.label === 'PP')
                                    // )
                                    .map((lc, index) => {
                                      // console.log(
                                      //   `learningComponents.${lc.label.toLowerCase()}.lea_workload`
                                      // );
                                      if (!values.subjectIntegrated && lc.label === 'PP') {
                                        return <div key={lc.components_id}></div>;
                                      }

                                      return (
                                        <Grid item xs={3} key={lc.components_id}>
                                          <InputLabel info={lc.com_name}>{lc.label}</InputLabel>
                                          <Field
                                            name={`learningComponents.${index}.lea_workload`}
                                            component={FTextField}
                                            type="number"
                                          />
                                        </Grid>
                                      );
                                    })}
                              </>
                            )}
                          />
                        )}

                        {errors.learningComponents &&
                          touched.learningComponents &&
                          typeof errors?.learningComponents === 'string' && (
                            <Grid item xs={12}>
                              <FormHelperText error>
                                {errors.learningComponents as string}
                              </FormHelperText>
                            </Grid>
                          )}

                        {hasPP && (
                          <Grid item xs={12} container>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={values.subjectIntegrated}
                                  {...getFieldProps('subjectIntegrated')}
                                />
                              }
                              label="Materia Integradora"
                              labelPlacement="start"
                            />
                          </Grid>
                        )}

                        <Grid item xs={12} container>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={values.canHomologateIntern}
                                {...getFieldProps('canHomologateIntern')}
                              />
                            }
                            label="¿Se puede homologar internamente?"
                            labelPlacement="start"
                          />
                        </Grid>
                        <Grid item xs={12} container>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={values.canHomologateExternal}
                                {...getFieldProps('canHomologateExternal')}
                              />
                            }
                            label="¿Se puede homologar de otra institución?"
                            labelPlacement="start"
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    {/* Prerrequisitos */}
                    <Grid item xs={12}>
                      <InputLabel>Prerrequisitos</InputLabel>
                      <Field
                        name="prerequisites"
                        component={Autocomplete}
                        multiple
                        options={
                          values.subject !== null
                            ? data?.matter_mesh
                                .filter(
                                  (sc) =>
                                    sc.matter.id !== values.subject?.id &&
                                    sc.group < (currentGroup || 0)
                                )
                                .sort((a, b) =>
                                  `${a.matter?.cod_matter_migration} - ${a.matter_rename}`.toLowerCase() >
                                  `${b.matter?.cod_matter_migration} - ${b.matter_rename}`.toLowerCase()
                                    ? 1
                                    : -1
                                )
                            : []
                        }
                        loading={isLoading}
                        loadingText="Cargando pre requisitos..."
                        getOptionLabel={(option: any) =>
                          `${
                            option.matter?.cod_matter_migration
                              ? `${option.matter?.cod_matter_migration} - `
                              : ''
                          } ${option.matter_rename}`
                        }
                        noOptionsText="No hay prerequisitos disponibles, asegúrate de primero seleccionar una materia"
                        renderInput={(params: AutocompleteRenderInputParams) => (
                          <TextField
                            {...params}
                            name="prerequisites"
                            placeholder="Seleccione los prerequisitos de la materia"
                            error={touched.prerequisites && !!errors.prerequisites}
                            helperText={touched.prerequisites && errors.prerequisites}
                          />
                        )}
                      />
                    </Grid>

                    {/* Estado */}
                    <Grid item xs={12} hidden={!subjectCurriculumToEdit}>
                      <InputLabel>Estado</InputLabel>
                      <Field
                        name="status"
                        component={Autocomplete}
                        options={statusAvailables}
                        getOptionLabel={(option: any) => option.name}
                        renderInput={(params: AutocompleteRenderInputParams) => (
                          <TextField
                            {...params}
                            name="status"
                            placeholder="Seleccione un estado"
                            error={touched.status && !!errors.status}
                            helperText={touched.status && errors.status}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
              </PerfectScrollbar>
              <DialogActions>
                <Stack direction="row" spacing={2}>
                  <Button
                    variant="text"
                    onClick={() => {
                      dispatch(resetCurrentGroup());
                      dispatch(resetSubjectCurriculumSelected());
                      handleCloseDialog();
                    }}
                    color="primary"
                  >
                    {isSuccess || isSuccessUpdating ? 'Continuar' : 'Cancelar'}
                  </Button>
                  {!isSuccess && (
                    <AnimateButton>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={isAssigning || isUpdating}
                        endIcon={(isAssigning || isUpdating) && <CircularProgress size={20} />}
                      >
                        {subjectCurriculumSelected ? 'Actualizar' : 'Asignar'}
                      </Button>
                    </AnimateButton>
                  )}
                </Stack>
              </DialogActions>
            </Form>
          )}
        </Formik>
      )}
    </ExtendedSideDialog>
  );
};

export default AddSubjectDialog;
