import { Grid } from '@material-ui/core';
import { ActivityProjectResearch } from '../../../../interfaces/tasks.interfaces';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ActivityCard from './TaskActivities/ActivityCard';

interface Props {
  activities: ActivityProjectResearch[];
  handleEdit: (id: number) => void;
}

const ActivityList = ({ activities, handleEdit }: Props) => {
  return (
    <Grid>
      <PerfectScrollbar
        style={{
          width: '100%',
          height: '700px',
        }}
      >
        <Grid container>
          {activities.map((activity) => (
            <ActivityCard activity={activity} handleEdit={handleEdit} />
          ))}
        </Grid>
      </PerfectScrollbar>
    </Grid>
  );
};

export default ActivityList;
