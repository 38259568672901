const statusProjectsAvailables = [
  // { label: 'En Espera', keyword: 'EN ESPERA', color: 'info' },
  // { label: 'Finalizado', keyword: 'FINALIZADO', color: 'success' },
  // { label: 'En cierre', keyword: 'EN CIERRE', color: 'warning' },
  { label: 'En ejecución', keyword: 'EN EJECUCION', color: 'success' },
  // { label: 'Detenido', keyword: 'DETENIDO', color: 'warning' },
  // { label: 'Cancelado', keyword: 'CANCELADO', color: 'error' },
  { label: 'Planificado', keyword: 'PLANIFICADO', color: 'warning' },
  { label: 'Reservado', keyword: 'RESERVADO', color: 'info' },
];

export default statusProjectsAvailables;
