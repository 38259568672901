import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Role } from '../interfaces/roles.interfaces';

interface RolesState {
  rolesList: Role[];
  deleteModalOpen: boolean;
  roleIdToDelete?: number;
}

const initialState: RolesState = {
  rolesList: [],
  deleteModalOpen: false,
};

const rolesSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    setRolesList: (state, action: PayloadAction<Role[]>) => {
      state.rolesList = action.payload;
    },
    setDeleteModalOpen: (state, action: PayloadAction<boolean>) => {
      state.deleteModalOpen = action.payload;
    },
    openDeleteModalWith: (state, action: PayloadAction<number>) => {
      state.roleIdToDelete = action.payload;
      state.deleteModalOpen = true;
    },
    resetRoleIdToDelete: (state) => {
      state.roleIdToDelete = undefined;
    },
  },
});

export const { setRolesList, setDeleteModalOpen, openDeleteModalWith, resetRoleIdToDelete } =
  rolesSlice.actions;

export default rolesSlice.reducer;
