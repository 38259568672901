import { Switch, Redirect } from 'react-router-dom';

import { useAppSelector } from '../../../../hooks/useRedux';

//import { permissionKey, subPermissionKeys } from '../constants/permissions';
import GuardRoute from '../../../../router/guards/GuardRoute';

import ClasificationsScreen from '../screens/ClasificationsScreen';
import AddClassificationScreen from '../screens/AddClasificationScreen';
import EditClassificationsScreen from '../screens/EditClassificationsScreen';

const ClassificationsRouter = () => {
  const { currentModule } = useAppSelector((state) => state.access);

  const hasPermission = 'borrows' in currentModule?.permissions!;

  if (!hasPermission) return <Redirect to="/dashboard" />;

  return (
    <Switch>
      <GuardRoute
        exact
        path="/administrar-clasificaciones"
        component={ClasificationsScreen}
        /* permissionKey={permissionKey}
                subPermissionKey={subPermissionKeys.create} */
      />
      <GuardRoute
        exact
        path="/administrar-clasificaciones/agregar"
        component={AddClassificationScreen}
        /* permissionKey={permissionKey}
                subPermissionKey={subPermissionKeys.create} */
      />
      <GuardRoute
        exact
        path="/administrar-clasificaciones/editar"
        component={EditClassificationsScreen}
        /* permissionKey={permissionKey}
                subPermissionKey={subPermissionKeys.edit} */
      />
    </Switch>
  );
};

export default ClassificationsRouter;
