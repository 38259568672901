import { useHistory, useParams } from 'react-router-dom';
import { Grid, IconButton, Typography, useTheme } from '@material-ui/core';
import { IconArrowNarrowLeft } from '@tabler/icons';
import { useTranslation } from 'react-i18next';

import MainCardForm from '../../../shared/components/cards/MainCardForm';
import EditEmissionPointForm from '../components/EditEmissionPointForm';
import { useAppSelector, useAppDispatch } from '../../../../hooks/useRedux';
import { useLazyGetEmisionPointQuery } from '../slices/financialConfigurationApiSlice';
import { setEmissionPointList } from '../slices/financialConfigurationSlice';
import { useEffect } from 'react';
import SimpleFormOneColumn from '../../../shared/components/forms/skeletons/SimpleFormOneColumn';

const EditEmissionPointScreen = () => {
  const theme = useTheme();
  const history = useHistory();
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { emissionPointList } = useAppSelector((state) => state.financialConfigurations);
  const { emissionPointId } = useParams<{ emissionPointId: string }>();

  const [getEmissionPoint, { isFetching }] = useLazyGetEmisionPointQuery();

  useEffect(() => {
    if (!emissionPointList.length && !isFetching) {
      getEmissionPoint({
        emissionPointId: +emissionPointId,
      })
        .unwrap()
        .then((response) => {
          if (response) dispatch(setEmissionPointList([response!]));
        })
        .catch((error) => console.log(error));
    }
  }, [getEmissionPoint, isFetching, emissionPointId, emissionPointList.length, dispatch]);

  return (
    <MainCardForm>
      <Grid item xs={12}>
        <Grid item container alignItems="center" gap={1} sx={{ cursor: 'pointer' }}>
          <IconButton onClick={() => history.goBack()}>
            <IconArrowNarrowLeft size={30} color={theme.palette.text.dark} />
          </IconButton>
          <Typography variant="h2">{t('financial.emission_point.edit.title')}</Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} md={8} lg={6} sx={{ mt: 2 }}>
        {Boolean(emissionPointList.length) ? <EditEmissionPointForm /> : <SimpleFormOneColumn />}
      </Grid>
    </MainCardForm>
  );
};
export default EditEmissionPointScreen;
