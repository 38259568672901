import { useState, useEffect } from 'react';

import {
  Autocomplete,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  InputLabel,
  Skeleton,
  TextField,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useHistory, useParams } from 'react-router-dom';

import { useAppSelector } from '../../../../hooks/useRedux';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import { statusAvailables } from '../../../shared/constants/resourceStatus';
import {
  useGetAvailablesOffersQuery,
  useGetInfoOfferQuery,
} from '../../academic-offerings/slices/academicOfferingsApiSlice';
import { typePaymentsAvailables } from '../constants/optionsTypePayments';
import { useFormStyles } from '../constants/styles';
import { MatterInfoSchema } from '../constants/validationSchemas';
import {
  useGetAvailablesAreaQuery,
  useGetAllMattersTypesQuery,
  useUpdateMatterMutation,
  useGetJointAcademicsQuery,
} from '../slices/mattersApiSlice';
import TextFieldFetching from '../../../shared/components/TextFieldFetching';
import { useTranslation } from 'react-i18next';
// import { useGetCatalogByAcronymQuery } from '../../../shared/slices/catalogApi';
import InputSecondaryLabel from '../../../shared/components/forms/InputSecondaryLabel';
import { subPermissionKeys } from '../constants/permissions';
import useSharedGuard from '../../../../hooks/useSharedGuard';
import { subPermissionKeys as subPermissionKeysArea } from '../../areas/constants/permissions';

const EditMatterForm = () => {
  const { t } = useTranslation();

  const { hasAccess: hasAccessMatterType } = useSharedGuard(subPermissionKeys);

  const { hasAccess: hasAccessArea } = useSharedGuard(subPermissionKeysArea);

  const { currentProfile } = useAppSelector((state) => state.access);
  const { mattersList } = useAppSelector((state) => state.matters);

  const [showAlerts, setShowAlerts] = useState(false);

  const classes = useFormStyles();

  const history = useHistory();

  const { matterId } = useParams<{ matterId: string }>();
  // const { hasAccess: hasAccessArea } = useGuardArea();

  const [updateMatter, { isLoading: isUpdating, isSuccess, isError, error }] =
    useUpdateMatterMutation();

  const { data: areasAvailable = [], isLoading: isLoadingA } = useGetAvailablesAreaQuery({
    profileId: currentProfile?.profileId!,
  });

  const { data: offersAvailable = [], isLoading: isLoadingOF } = useGetAvailablesOffersQuery({
    profileId: currentProfile?.profileId!,
  });
  // console.log(offersAvailable);

  const matterToEdit = mattersList.find((m) => m.id === Number(matterId));

  const [payment, setPayment] = useState<{ isPay: string; type: string }>();

  // const getMatPaymentType = (mat_payment_type: string) => {
  //   const first_letter = mat_payment_type?.substring(0, 1) || 'n';
  //   return first_letter.toLocaleLowerCase() === 'c'
  //     ? 'creditos'
  //     : first_letter.toLocaleLowerCase() === 'h'
  //     ? 'horas'
  //     : 'Ninguno';
  // };

  // const { data: catalogModality, isLoading: isLoadingModality } = useGetCatalogByAcronymQuery({
  //   acronym: 'M',
  // });

  const { data: jointAcademics = [], isLoading: isLoadingJointAcademics } =
    useGetJointAcademicsQuery({
      profileId: currentProfile?.profileId!,
    });

  const {
    getFieldProps,
    errors,
    touched,
    handleSubmit,
    setFieldValue,
    setValues,
    values,
    // initialValues,
  } = useFormik({
    initialValues: {
      name: matterToEdit?.mat_name || '',
      acronym: matterToEdit?.mat_acronym || '',
      description: matterToEdit?.mat_description || '',
      codMatterMigration: matterToEdit?.cod_matter_migration || '',
      codOldMigration: matterToEdit?.cod_old_migration || '',
      typeMatter: Number(matterToEdit?.type_matter_id) || 0,
      educationLevel: Number(matterToEdit?.education_level_id) || 0,
      traslate: matterToEdit?.mat_translate || '',
      area: Number(matterToEdit?.area_id) || 0,
      offerId: Number(matterToEdit?.education_level?.offer_id) || 1,
      offerDescription: matterToEdit?.education_level?.offer?.off_description,
      statusId: matterToEdit?.status_id || 0,
      typePayment: matterToEdit?.mat_payment_type || '',
      jointAcademicId: Number(matterToEdit?.joint_academic_id) || 0,
      // typeModalityId: matterToEdit?.type_modality_id || null,
    },
    validationSchema: MatterInfoSchema,
    onSubmit: async (values) => {
      // const mat_payment_type = getMatPaymentType(values.typePayment);
      const mat_payment_type = values.typePayment;

      await updateMatter({
        profileId: currentProfile?.profileId!,
        matterId: matterToEdit?.id!,
        matterPayload: {
          mat_name: values.name,
          mat_description: values.description,
          mat_acronym: values.acronym,
          cod_matter_migration: values.codMatterMigration,
          cod_old_migration: values.codOldMigration,
          mat_translate: values.traslate,
          type_matter_id: values.typeMatter.toString(),
          education_level_id: values.educationLevel.toString(),
          area_id: values.area.toString(),
          status_id: values.statusId.toString(),
          mat_payment_type,
          joint_academic_id: values.jointAcademicId.toString(),
          // ...(values.offerDescription === 'oferta_postgrado' && {
          //   type_modality_id: values.typeModalityId,
          // }),
        },
      });

      window.scrollTo(0, 0);

      setShowAlerts(true);
    },
  });

  const { data: mattersTypesAvailable = [], isLoading: isLoadingMT } = useGetAllMattersTypesQuery({
    profileId: currentProfile?.profileId!,
    offerId: values.offerId.toString(),
  });

  const matterType = mattersTypesAvailable.find(
    (mt) => mt.id === Number(matterToEdit?.type_matter_id)
  );

  useEffect(() => {
    if (matterType)
      setPayment({
        isPay: matterType?.tm_cobro || '',
        type: matterType?.tm_acronym || '',
      });
  }, [matterType]);

  const {
    data: offersInfoAvailable,
    isLoading: isLoadingOI,
    isFetching: isFetchingOI,
    isSuccess: isSuccesLoadingInfo,
  } = useGetInfoOfferQuery({
    profileId: currentProfile?.profileId!,
    offerId: values.offerId.toString(),
    filter: true,
  });
  // console.log(offersInfoAvailable);
  // console.log(values);

  // const { data: generatedCode, isLoading: isLoadingCode } = useGetGenerateCodeSubjectQuery(
  //   {
  //     areaId: Number(values.area!),
  //   },
  //   {
  //     skip: !(values.area !== initialValues.area),
  //   }
  // );

  // useEffect(() => {
  //   if (generatedCode) {
  //     setFieldValue('codMatterMigration', generatedCode.codNewMigration);
  //     setFieldValue('codOldMigration', generatedCode.codOldMigration);
  //   }
  // }, [generatedCode, setFieldValue]);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction="column" gap={2}>
        <Grid item>
          {isError && showAlerts && (
            <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
          )}

          {isSuccess && showAlerts && (
            <SuccessAlert
              message={t('academic.subjects.edit.success')}
              handleDismiss={() => setShowAlerts(false)}
            />
          )}
          <Grid container direction="column" spacing={2}>
            <Grid item xs={12}>
              <InputLabel className={classes.inputLabel}>
                {t('academic.subjects.add.name')}
              </InputLabel>
              <TextField
                fullWidth
                placeholder={t('academic.subjects.add.name.placeholder')}
                error={Boolean(errors.name && touched.name)}
                {...getFieldProps('name')}
              />
              {errors.name && touched.name && (
                <FormHelperText error>{t(errors.name)}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12}>
              <InputLabel className={classes.inputLabel}>
                {t('academic.subjects.add.translation')}
              </InputLabel>
              <TextField
                {...getFieldProps('traslate')}
                error={Boolean(errors.traslate && touched.traslate)}
                fullWidth
                placeholder={t('academic.subjects.add.translation.placeholder')}
              />
              {errors.traslate && touched.traslate && (
                <FormHelperText error>{t(errors.traslate)}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12}>
              <InputLabel className={classes.inputLabel}>
                {t('academic.subjects.add.acronym')}
              </InputLabel>
              <TextField
                {...getFieldProps('acronym')}
                error={Boolean(errors.acronym && touched.acronym)}
                fullWidth
                placeholder={t('academic.subjects.add.acronym.placeholder')}
              />
              {errors.acronym && touched.acronym && (
                <FormHelperText error>{t(errors.acronym)}</FormHelperText>
              )}
            </Grid>

            <Grid item container>
              <InputLabel className={classes.inputLabel}>
                {t('academic.subjects.add.description')}
              </InputLabel>
              <TextField
                placeholder={t('academic.subjects.add.description.placeholder')}
                multiline
                rows={2}
                fullWidth
                {...getFieldProps('description')}
              />
            </Grid>

            <Grid item xs={12}>
              <InputLabel className={classes.inputLabel}>
                {t('academic.subjects.add.edu_level')}
              </InputLabel>

              {isLoadingOF ? (
                <Skeleton
                  variant="rectangular"
                  sx={{ width: '100%', borderRadius: 3, height: 50 }}
                />
              ) : (
                <Autocomplete
                  options={offersAvailable}
                  loading={isLoadingOF}
                  loadingText={t('academic.subjects.add.edu_level.loading')}
                  noOptionsText={t('academic.subjects.add.edu_level.no_data')}
                  getOptionLabel={(option) => option.off_name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(errors.offerId && touched.offerId)}
                      placeholder={t('academic.subjects.add.edu_level.placeholder')}
                    />
                  )}
                  value={offersAvailable.find((p) => p.id === values.offerId) || null}
                  // inputValue={offersAvailable.find((p) => p.id === values.offerId)?.off_name || ''}
                  onChange={(_, value) => {
                    setValues({
                      ...values,
                      typeMatter: 0,
                      typePayment: '',
                    });
                    setPayment(undefined);
                    setFieldValue('educationLevel', '');

                    setFieldValue('offerId', value?.id || '');
                    setFieldValue('offerDescription', value?.off_description || '');
                    if (value?.off_description === 'oferta_postgrado') {
                      setFieldValue('jointAcademicId', null);
                    }
                  }}
                  fullWidth
                  clearIcon={null}
                />
              )}

              {errors.offerId && touched.offerId && (
                <FormHelperText error>{t(errors.offerId)}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12}>
              {isSuccesLoadingInfo && offersInfoAvailable && offersInfoAvailable!.length > 0 ? (
                <>
                  <InputLabel className={classes.inputLabel}>
                    {t('academic.subjects.add.academic_unit')}
                  </InputLabel>
                  {isFetchingOI ? (
                    <TextFieldFetching />
                  ) : (
                    <Autocomplete
                      options={offersInfoAvailable!}
                      loading={isLoadingOI || isFetchingOI}
                      getOptionSelected={(option, value) => option.id === value.id}
                      loadingText={t('academic.subjects.add.academic_unit.loading')}
                      noOptionsText={t('academic.subjects.add.academic_unit.no_data')}
                      getOptionLabel={(option) => option.edu_name}
                      value={
                        offersInfoAvailable!.find((p) => p.id === Number(values.educationLevel)) ||
                        null
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={t('academic.subjects.add.academic_unit.placeholder')}
                          error={Boolean(errors.educationLevel && touched.educationLevel)}
                        />
                      )}
                      onChange={(e, value) => setFieldValue('educationLevel', value?.id || 0)}
                      fullWidth
                      clearIcon={null}
                    />
                  )}
                  {errors.educationLevel && touched.educationLevel && (
                    <FormHelperText error>{t(errors.educationLevel)}</FormHelperText>
                  )}
                </>
              ) : (
                <>
                  <InputLabel className={classes.inputLabel}>
                    {t('academic.subjects.add.academic_unit')}
                  </InputLabel>
                  <TextField
                    disabled
                    fullWidth
                    error={Boolean(errors.educationLevel && touched.educationLevel)}
                    placeholder={t('academic.subjects.add.academic_unit.no_data')}
                  />
                  {errors.educationLevel && touched.educationLevel && (
                    <FormHelperText error>{t(errors.educationLevel)}</FormHelperText>
                  )}
                </>
              )}
            </Grid>

            {/* {values.offerDescription === 'oferta_postgrado' && (
              <Grid item xs={12}>
                <InputLabel className={classes.inputLabel}>
                  {t('academic.subjects.add.modality.label')}
                </InputLabel>
                {isLoadingModality ? (
                  <TextFieldFetching />
                ) : (
                  <>
                    <Autocomplete
                      loadingText={t('academic.subjects.add.modality.loading')}
                      noOptionsText={t('academic.subjects.add.modality.noOptions')}
                      options={catalogModality || []}
                      loading={isLoadingModality}
                      getOptionLabel={(option) => option?.cat_name}
                      value={
                        catalogModality?.find((p) => p.id === Number(values.typeModalityId)) || null
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={Boolean(errors.typeModalityId && touched.typeModalityId)}
                          placeholder={t('academic.subjects.add.modality.placeholder')}
                        />
                      )}
                      onChange={(e, value) => setFieldValue('typeModalityId', value?.id || '')}
                      fullWidth
                    />
                    {errors.typeModalityId && touched.typeModalityId && (
                      <FormHelperText error>{errors.typeModalityId}</FormHelperText>
                    )}
                  </>
                )}
              </Grid>
            )} */}

            <Grid item xs={12}>
              <InputSecondaryLabel
                label={t('academic.subjects.add.area')}
                title={t('academic.subjects.add.area.add')}
                link={'/administrar-areas/agregar'}
                hasAccess={hasAccessArea('create')}
              />
              {isLoadingA ? (
                <Skeleton
                  variant="rectangular"
                  sx={{ width: '100%', borderRadius: 3, height: 50 }}
                />
              ) : (
                <Autocomplete
                  options={areasAvailable}
                  loading={isLoadingA}
                  loadingText={t('academic.subjects.add.area.loading')}
                  noOptionsText={t('academic.subjects.add.area.no_data')}
                  getOptionLabel={(option) => option.ar_name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(errors.area && touched.area)}
                      placeholder={t('academic.subjects.add.area.placeholder')}
                    />
                  )}
                  value={areasAvailable?.find((p) => p.id === Number(values.area))}
                  onChange={(_, value) => setFieldValue('area', value?.id || '')}
                  fullWidth
                  clearIcon={null}
                />
              )}

              {errors.area && touched.area && (
                <FormHelperText error>{t(errors.area)}</FormHelperText>
              )}
            </Grid>

            <Grid container item xs={12} spacing={2}>
              <Grid item xs={12} md={6}>
                {/* {isLoadingCode ? (
                  <TextFieldFetching />
                ) : (
                  <> */}
                <InputLabel className={classes.inputLabel}>
                  {t('academic.subjects.add.current_code')}
                </InputLabel>
                <TextField
                  {...getFieldProps('codMatterMigration')}
                  // disabled
                  // loading={isLoadingCode}
                  error={Boolean(errors.codMatterMigration && touched.codMatterMigration)}
                  value={values.codMatterMigration}
                  fullWidth
                  placeholder={t('academic.subjects.add.current_code.placeholder')}
                />
                {errors.codMatterMigration && touched.codMatterMigration && (
                  <FormHelperText error>{t(errors.codMatterMigration)}</FormHelperText>
                )}
                {/* </>
                )} */}
              </Grid>

              <Grid item xs={12} md={6}>
                <InputLabel className={classes.inputLabel}>
                  {t('academic.subjects.add.prev_code')}
                </InputLabel>
                {/* {isLoadingCode ? (
                  <TextFieldFetching />
                ) : (
                  <> */}
                <TextField
                  {...getFieldProps('codOldMigration')}
                  disabled
                  error={Boolean(errors.codOldMigration && touched.codOldMigration)}
                  value={values.codOldMigration}
                  fullWidth
                  placeholder={t('academic.subjects.add.prev_code.placeholder')}
                />
                {errors.codOldMigration && touched.codOldMigration && (
                  <FormHelperText error>{t(errors.codOldMigration)}</FormHelperText>
                )}
                {/* </>
                )} */}
              </Grid>
            </Grid>

            {values.offerDescription !== 'oferta_postgrado' && (
              <Grid item xs={12}>
                <InputLabel className={classes.inputLabel}>
                  {t('academic.subjects.add.joint_academic')}
                </InputLabel>
                <Autocomplete
                  options={jointAcademics}
                  loading={isLoadingJointAcademics}
                  loadingText={t('academic.subjects.add.joint_academic.loading')}
                  noOptionsText={t('academic.subjects.add.joint_academic.no_data')}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(errors.jointAcademicId && touched.jointAcademicId)}
                      placeholder={t('academic.subjects.add.joint_academic.placeholder')}
                    />
                  )}
                  onChange={(_, value) => setFieldValue('jointAcademicId', value?.id || '')}
                  multiple={false}
                  value={jointAcademics.find((e) => e.id === values.jointAcademicId)}
                  fullWidth
                  clearIcon={null}
                />
                {errors.jointAcademicId && touched.jointAcademicId && (
                  <FormHelperText error>{t(errors.jointAcademicId)}</FormHelperText>
                )}
              </Grid>
            )}

            <Grid item xs={12}>
              <InputSecondaryLabel
                label={t('academic.subjects.add.subject_type')}
                title={t('academic.subjects.add.subject_type.add')}
                link={'/administrar-tipos-materias/agregar'}
                hasAccess={hasAccessMatterType('create')}
              />

              {isLoadingMT ? (
                <Skeleton
                  variant="rectangular"
                  sx={{ width: '100%', borderRadius: 3, height: 50 }}
                />
              ) : (
                <Autocomplete
                  // options={
                  //   values.offerDescription === 'oferta_postgrado'
                  //     ? mattersTypesAvailable.filter((e) => !Number(e.tm_cobro))
                  //     : mattersTypesAvailable
                  // }
                  // !Martin dijo que agregue un campo pero nv no hay tiempo.
                  // TODO: agregar el campo que dijo martin para relacionar oferta y tipo de materia.
                  options={mattersTypesAvailable}
                  loading={isLoadingMT}
                  loadingText={t('academic.subjects.add.subject_type.loading')}
                  noOptionsText={t('academic.subjects.add.subject_type.no_data')}
                  getOptionLabel={(option) => option.tm_name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={t('academic.subjects.add.subject_type.placeholder')}
                      error={Boolean(errors.typeMatter && touched.typeMatter)}
                    />
                  )}
                  value={mattersTypesAvailable.find((p) => p.id === Number(values.typeMatter))}
                  onChange={(_, value) => {
                    setValues({
                      ...values,
                      typeMatter: value?.id || 0,
                      typePayment:
                        value?.tm_acronym === 'nv' || values.offerDescription === 'oferta_postgrado'
                          ? typePaymentsAvailables[2]?.value
                          : typePaymentsAvailables[1]?.value,
                    });
                    // setFieldValue('typeMatter', value?.id || '');

                    // setPayment(value?.tm_acronym || '');
                    setPayment({ isPay: value?.tm_cobro!, type: value?.tm_acronym! } || undefined);
                  }}
                  fullWidth
                  clearIcon={null}
                />
              )}

              {errors.typeMatter && touched.typeMatter && (
                <FormHelperText error>{t(errors.typeMatter)}</FormHelperText>
              )}
            </Grid>

            {payment?.isPay ? (
              <Grid item xs={12}>
                <InputLabel className={classes.inputLabel}>
                  {values.offerDescription !== 'oferta_postgrado'
                    ? t('academic.subjects.add.payment_type')
                    : t('academic.subjects.add.payment_type.postgrade')}
                </InputLabel>
                <Autocomplete
                  options={typePaymentsAvailables}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(errors.typePayment && touched.typePayment)}
                    />
                  )}
                  // defaultValue={typePaymentsAvailables[0]}
                  // defaultValue={typePaymentsAvailables.find(
                  //   (p) => p.value === matterToEdit?.mat_payment_type
                  // )}
                  value={typePaymentsAvailables.find((dt) => dt.value === values.typePayment)}
                  onChange={(e, value) => setFieldValue('typePayment', value?.value || '')}
                  fullWidth
                  clearIcon={null}
                />
                {errors.typePayment && touched.typePayment && (
                  <FormHelperText error>{t(errors.typePayment)}</FormHelperText>
                )}
              </Grid>
            ) : null}

            <Grid item xs={12}>
              <InputLabel className={classes.inputLabel}>
                {t('academic.subjects.add.status')}
              </InputLabel>
              <Autocomplete
                options={statusAvailables}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField {...params} error={Boolean(errors.statusId && touched.statusId)} />
                )}
                value={statusAvailables.find((st) => Number(matterToEdit?.status_id) === st.id)}
                onChange={(e, value) => setFieldValue('statusId', value?.id || '')}
                fullWidth
                clearIcon={null}
              />
              {errors.statusId && touched.statusId && (
                <FormHelperText error>{t(errors.statusId)}</FormHelperText>
              )}
            </Grid>

            <Grid
              item
              container
              alignItems="center"
              justifyContent="flex-end"
              spacing={2}
              sx={{ mt: 1 }}
            >
              <Grid item>
                <Button
                  variant="outlined"
                  size="large"
                  color="primary"
                  onClick={() => history.goBack()}
                >
                  {t('academic.subjects.edit.back')}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  color="primary"
                  disabled={isUpdating}
                  endIcon={isUpdating && <CircularProgress size={20} />}
                >
                  {t('academic.subjects.edit.submit')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default EditMatterForm;
