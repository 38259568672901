import {
  IconUsers,
  IconSchool,
  IconHierarchy,
  IconBook,
  IconBookmark,
  IconClipboard,
  IconArrowForward,
  IconArrowBackUp,
  IconFileAnalytics,
  IconChartArea,
  IconChartDonut,
  IconDashboard,
  IconAdjustments,
  IconRocket,
  IconTools,
  IconSettings,
  IconApps,
  IconChartPie3,
} from '@tabler/icons';
//import { shared } from '../shared';

// Icon Group
const icons = {
  IconUsers,
  IconSchool,
  IconHierarchy,
  IconBook,
  IconBookmark,
  IconClipboard,
  IconArrowForward,
  IconArrowBackUp,
  IconFileAnalytics,
  IconChartArea,
  IconChartDonut,
  IconDashboard,
  IconAdjustments,
  IconRocket,
  IconTools,
  IconSettings,
  IconApps,
  IconChartPie3,
};

export const crm_socialtray = {
  id: 'crm',
  title: 'CRM',
  type: 'group',
  url: '/dashboard',
  children: [
    {
      id: 'dashboard-socialtray',
      title: 'Dashboard',
      type: 'item',
      url: '/dashboard-socialtray',
      permissionKey: 'crm',
      icon: icons['IconDashboard'],
    },
    {
      id: 'gestionar',
      title: 'Gestionar',
      type: 'collapse',
      icon: icons['IconAdjustments'],
      children: [
        {
          id: 'gestionar-leads',
          title: 'Leads',
          type: 'item',
          url: '/gestionar-leads',
          // icon: icons['Stack'],
          permissionKey: 'crm',
        },
        {
          id: 'gestionar-clientes',
          title: 'Clientes',
          type: 'item',
          url: '/gestionar-clientes',
          // icon: icons['Stack'],
          permissionKey: 'crm',
        },
      ],
    },
    {
      id: 'marketing',
      title: 'Marketing',
      type: 'collapse',
      icon: icons['IconRocket'],
      children: [
        {
          id: 'marketing-importar-datos',
          title: 'Importar Datos',
          type: 'item',
          url: '/marketing-importar-datos',
          // icon: icons['Stack'],
          permissionKey: 'crm',
        },
        {
          id: 'marketing-campanias',
          title: 'Campañas',
          type: 'item',
          url: '/marketing-campanias',
          // icon: icons['Files'],
          permissionKey: 'crm',
        },
        {
          id: 'marketing-formularios',
          title: 'Formularios',
          type: 'item',
          url: '/marketing-formularios',
          // icon: icons['Files'],
          permissionKey: 'crm',
        },
        {
          id: 'marketing-mailing',
          title: 'Mailing',
          type: 'item',
          url: '/marketing-mailing',
          // icon: icons['Files'],
          permissionKey: 'crm',
        },
        {
          id: 'marketing-plantillas',
          title: 'Plantillas',
          type: 'item',
          url: '/marketing-plantillas',
          // icon: icons['Files'],
          permissionKey: 'crm',
        },
        /* {
            id: 'marketing-respuestas-automaticas',
            title: 'Respuestas Automáticas',
            type: 'item',
            url: '/marketing-respuestas-automaticas',
            // icon: icons['Files'],
            permissionKey: 'crm',
          }, */
      ],
    },
    {
      id: 'reporte',
      title: 'Reporte',
      type: 'collapse',
      icon: icons['IconChartPie3'],
      children: [
        {
          id: 'reporte-gestion',
          title: 'Gestión',
          type: 'item',
          url: '/reporte-gestion',
          // icon: icons['Stack'],
          permissionKey: 'crm',
        },
      ],
    },
    {
      id: 'administracion',
      title: 'Administración',
      type: 'collapse',
      icon: icons['IconTools'],
      children: [
        {
          id: 'administracion-permisos',
          title: 'Permisos',
          type: 'item',
          url: '/administracion-permisos',
          // icon: icons['Stack'],
          permissionKey: 'crm',
        },
        {
          id: 'administracion-roles',
          title: 'Roles',
          type: 'item',
          url: '/administracion-roles',
          // icon: icons['Stack'],
          permissionKey: 'crm',
        },
        {
          id: 'administracion-permisos-rol',
          title: 'Permisos por Rol',
          type: 'item',
          url: '/administracion-permisos-rol',
          // icon: icons['Stack'],
          permissionKey: 'crm',
        },
        {
          id: 'administracion-usuarios',
          title: 'Usuarios',
          type: 'item',
          url: '/administracion-usuarios',
          // icon: icons['Stack'],
          permissionKey: 'crm',
        },
        {
          id: 'administracion-empresas',
          title: 'Empresas',
          type: 'item',
          url: '/administracion-empresas',
          // icon: icons['Stack'],
          permissionKey: 'crm',
        },
        {
          id: 'administracion-sedes',
          title: 'Sedes',
          type: 'item',
          url: '/administracion-sedes',
          // icon: icons['Stack'],
          permissionKey: 'crm',
        },
      ],
    },
    {
      id: 'configuracion',
      title: 'Configuración',
      type: 'collapse',
      icon: icons['IconSettings'],
      children: [
        {
          id: 'configuracion-fuentes-contacto',
          title: 'Fuentes de Contacto',
          type: 'item',
          url: '/configuracion-fuentes-contacto',
          // icon: icons['Stack'],
          permissionKey: 'crm',
        },
        {
          id: 'configuracion-medios-gestion',
          title: 'Medios de Gestión',
          type: 'item',
          url: '/configuracion-medios-gestion',
          // icon: icons['Stack'],
          permissionKey: 'crm',
        },
        {
          id: 'configuracion-productos',
          title: 'Productos',
          type: 'item',
          url: '/configuracion-productos',
          // icon: icons['Stack'],
          permissionKey: 'crm',
        },
        {
          id: 'configuracion-servicios',
          title: 'Servicios',
          type: 'item',
          url: '/configuracion-servicios',
          // icon: icons['Stack'],
          permissionKey: 'crm',
        },
        {
          id: 'configuracion-preguntas-encuestas',
          title: 'Preguntas Encuestas',
          type: 'item',
          url: '/configuracion-preguntas-encuestas',
          // icon: icons['Stack'],
          permissionKey: 'crm',
        },
      ],
    },
    {
      id: 'aplicaciones',
      title: 'Aplicaciones',
      type: 'collapse',
      icon: icons['IconApps'],
      children: [
        {
          id: 'aplicaciones-zoom',
          title: 'Zoom',
          type: 'item',
          url: '/aplicaciones-zoom',
          // icon: icons['Stack'],
          permissionKey: 'crm',
        },
        {
          id: 'aplicaciones-slack',
          title: 'Slack',
          type: 'item',
          url: '/aplicaciones-slack',
          // icon: icons['Stack'],
          permissionKey: 'crm',
        },
      ],
    },
  ],
};
