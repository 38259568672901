import { useEffect, useState } from 'react';

import {
  Button,
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  makeStyles,
  TableCell,
  TableRow,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TextField,
  Tooltip,
  Typography,
  useTheme,
  Autocomplete,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import {
  IconEraser,
  IconFileSearch,
  IconSearch,
  IconFileX,
  IconCashBanknoteOff,
} from '@tabler/icons';

import SubCard from '../../../shared/components/cards/SubCard';
import CustomCollapsibleTable from '../../../shared/components/tables/CustomCollapsibleTable';
import { ReceiptPayload, Receipt /*,ReceiptsResponse*/ } from '../interfaces/receipts.interfaces';
import {
  useGetAvailablePaymentMethodsQuery,
  useGetReceiptsToCancelQuery,
} from '../slices/receiptApiSlice';
import { receiptValues } from '../constants/formConstants';
import { renderErrorOrEmptyRow } from '../../../shared/helpers/render';
import DialogIframe from '../../../shared/components/dialogs/DialogIframe';
import { useGetReceiptsQuery } from '../../../students/payments-history/slices/paymentsApiSlice';
import ReverseDialog from './ReverseDialog';
import { useAppSelector } from '../../../../hooks/useRedux';
import LocalizedDatePicker from '../../../shared/components/controlled/LocalizedDatePicker';
import TextFieldFetching from '../../../shared/components/TextFieldFetching';
import { useControlModalStates } from '../../../shared/hooks/useControlModalStates';
import CancelReceiptDialog from './CancelReceiptDialog';
import ReverseDialogCheck from './ReverseDialogCheck';

const useStyles = makeStyles((theme) => ({
  searchBlock: {
    border: '3px solid',
    borderColor: theme.palette.primary.light,
    width: '100%',
  },
  cardHeader: {
    backgroundColor: theme.palette.primary.light,
    paddingBottom: 2,
    paddingTop: 2,
  },
  labelTypo: {
    fontWeight: 'bold',
    textAlign: 'right',
    width: '100%',
    marginRight: 5,
  },
}));
const ReceiptsTable = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();

  const [receiptPayload, setReceiptPayload] = useState<Partial<ReceiptPayload> | null>(null);

  const {
    multipleIds: cancelReceiptIds,
    handleCloseModal: handleCloseCancel,
    open: openCancelReceipt,
    handleOpenModalWithMultipleIds: handleOpenCancelMultiple,
  } = useControlModalStates();

  const {
    dataId: checkReceipt,
    open: openCheckReceiopt,
    handleOpenModalWith: handleOpenCheckReceipt,
    handleCloseModal: handleCloseCeckReceipt,
  } = useControlModalStates();

  //handle open receipt popup
  const [transactionId, setTransactionId] = useState('');
  const [receipt, setReceipt] = useState('');
  const [openReceipt, setOpenReceipt] = useState(false);

  //handle reverse of payment popup
  const [openDeleteReceipt, setOpenDeleteReceipt] = useState(false);

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const [search, setSearch] = useState('');

  const {
    data: pdfHTML,
    error: errorPDF,
    isFetching: isFetchingPDF,
  } = useGetReceiptsQuery(
    { transactionId: transactionId },
    {
      skip: transactionId === '',
      refetchOnMountOrArgChange: 30,
    }
  );

  const { data: paymentMethods, isLoading: isLoadingPaymentMethods } =
    useGetAvailablePaymentMethodsQuery({});

  const {
    isLoading,
    isFetching,
    refetch: receiptRefetch,
    data: receiptsResp,
    error,
  } = useGetReceiptsToCancelQuery(
    {
      page,
      perPage,
      receiptPayload: {
        ...receiptPayload,
        payment_method_id:
          receiptPayload?.payment_method_id !== 0 ? receiptPayload?.payment_method_id : undefined,
      },
    },
    { refetchOnMountOrArgChange: 30, skip: receiptPayload === null }
  );

  const datafetch = receiptsResp?.data.map((d: Receipt) => ({
    id: d.id,
    ref: d.id_transaction,
    fecha_emision: d.fecha_emision,
    identificacion: d.student?.student_ci!,
    estudiante: d.student?.student_name!,
    cajero: d.cajero,
    total_amounth:
      !Number(d.total_amounth) && Number(d.saldo_favor)
        ? Number(d.saldo_favor).toFixed(2)
        : d.total_amounth,
    options: d,
  }));

  /**
   * show receipt methods
   */
  const handleOpen2 = (transactionId: string) => {
    setOpenReceipt(true);
    setTransactionId('');
    setTransactionId(transactionId);
  };

  const handleClose2 = () => {
    setOpenReceipt(false);
  };

  useEffect(() => {
    if (!isFetchingPDF && pdfHTML) {
      setReceipt(pdfHTML);
    }
  }, [isFetchingPDF, pdfHTML]);

  /**
   * show debts cancellation methods
   */
  const handleOpenDeleteReceipt = (transactionId: string) => {
    setOpenDeleteReceipt(true);
    setTransactionId('');
    setTransactionId(transactionId);
  };
  const handleCloseDeleteReceipt = () => {
    setOpenDeleteReceipt(false);
  };

  const CollapsibleContentRow = (id: number) => {
    return (
      <>
        {/*  payment products */}
        <Box margin={1}>
          <TableContainer>
            <SubCard content={false} sx={{ mb: 2 }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow hover sx={{ backgroundColor: theme.palette.grey[200] }}>
                    <TableCell>{t('financial.receipts.collapsible_table.debt')}</TableCell>
                    <TableCell>
                      {defaultConfiguration?.currency_symbol}
                      {t('financial.receipts.collapsible_table.amount')}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/*<TableRow>
                          <TableCell>cuota 1</TableCell>
                          <TableCell>100.00</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>certificado</TableCell>
                          <TableCell>2.00</TableCell>
                        </TableRow>*/}
                  {datafetch &&
                    id !== -1 &&
                    datafetch[id]?.options?.producto!.map((p) => (
                      <TableRow>
                        <TableCell>{p.name}</TableCell>
                        <TableCell>{p.valor}</TableCell>
                      </TableRow>
                    ))}
                  {datafetch &&
                    id !== -1 &&
                    datafetch[id] === undefined &&
                    renderErrorOrEmptyRow(2)}
                </TableBody>
              </Table>
            </SubCard>
          </TableContainer>
        </Box>
        {/* payment ways  */}
        <Box margin={1}>
          <TableContainer>
            <SubCard content={false} sx={{ mb: 2 }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow hover sx={{ backgroundColor: theme.palette.grey[200] }}>
                    <TableCell>
                      {t('financial.receipts.collapsible_table.payment_method')}
                    </TableCell>
                    <TableCell>
                      {defaultConfiguration?.currency_symbol}
                      {t('financial.receipts.collapsible_table.amount')}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/*<TableRow>
                          <TableCell>cheque</TableCell>
                          <TableCell>100.00</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>efectivo</TableCell>
                          <TableCell>2.00</TableCell>
                  </TableRow>*/}
                  {datafetch &&
                    id !== -1 &&
                    datafetch[id]?.options?.forma_pagos!.map((fp) => (
                      <TableRow>
                        <TableCell>{fp.forma_pago}</TableCell>
                        <TableCell>{fp.monto}</TableCell>
                      </TableRow>
                    ))}
                  {datafetch &&
                    id !== -1 &&
                    datafetch[id] === undefined &&
                    renderErrorOrEmptyRow(2)}
                </TableBody>
              </Table>
            </SubCard>
          </TableContainer>
        </Box>
      </>
    );
  };

  const { handleSubmit, values, getFieldProps, setFieldValue } = useFormik({
    initialValues: receiptValues,
    //validationSchema: electronicDocumentSchema,
    onSubmit: async (value) => {
      const receipt: Partial<ReceiptPayload> = {
        date_start: values.date_start,
        date_end: values.date_end,
        cajero: values.cajero,
        student_ci: values.student_ci,
        payment_method_id: values.payment_method_id,
        filter: true,
      };
      if (JSON.stringify(receipt) === JSON.stringify(receiptPayload)) {
        receiptRefetch();
      } else {
        setReceiptPayload(receipt);
      }
    },
  });

  const defaultConfiguration = useAppSelector((state) => state.access.defaultConfiguration);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3} alignContent="flex-start" mt={1}>
          <Grid item xs={12} container spacing={2}>
            <Card className={classes.searchBlock}>
              <CardHeader
                avatar={<IconSearch />}
                title={t('financial.receipts.filters.title')}
                className={classes.cardHeader}
              />
              <CardContent>
                <Grid container spacing={2} mb={2}>
                  <Grid item xs={12} md={12} lg={6} container spacing={2}>
                    {/* container */}
                    <Grid item xs={12} container>
                      <Grid item xs={12} md={3} xl={4} container sx={{ alignContent: 'center' }}>
                        <Typography variant="h5" className={classes.labelTypo}>
                          {t('financial.receipts.filters.dates.emission_label')}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={9} xl={8} container>
                        <Grid item xs={12}>
                          <Grid container spacing={1}>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                              <Grid
                                container
                                spacing={1}
                                alignItems="flex-start"
                                alignContent="center"
                              >
                                <Grid item xs zeroMinWidth>
                                  <LocalizedDatePicker
                                    value={values.date_start}
                                    onChange={(newValue) => {
                                      setFieldValue('date_start', newValue);
                                    }}
                                    label={t('financial.receipts.filters.dates.start_label')}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                              <Grid container spacing={1} alignItems="flex-start">
                                <Grid item xs zeroMinWidth>
                                  <LocalizedDatePicker
                                    value={values.date_end}
                                    onChange={(newValue) => {
                                      setFieldValue('date_end', newValue);
                                    }}
                                    label={t('financial.receipts.filters.dates.end_label')}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={12} lg={6} container spacing={2}>
                    {/* container */}
                    <Grid item xs={12} container>
                      <Grid item xs={12} md={3} xl={4} container sx={{ alignContent: 'center' }}>
                        <Typography variant="h5" className={classes.labelTypo}>
                          {t('financial.receipts.filters.dates.identification_label')}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={9} xl={8} container>
                        <TextField
                          fullWidth
                          //error={Boolean(errors.to && touched.to)}
                          placeholder={t(
                            'financial.receipts.filters.dates.identification_placeholder'
                          )}
                          {...getFieldProps('student_ci')}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} lg={6} container spacing={2}>
                    {/*container*/}
                    <Grid item xs={12} container>
                      <Grid item xs={12} md={3} xl={4} container sx={{ alignContent: 'center' }}>
                        <Typography variant="h5" className={classes.labelTypo}>
                          {t('financial.receipts.filters.dates.payment_method')}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={9} xl={8} container>
                        {isLoadingPaymentMethods ? (
                          <TextFieldFetching />
                        ) : (
                          <Autocomplete
                            options={paymentMethods || []}
                            getOptionLabel={(option) => option.cat_name}
                            loadingText={t(
                              'financial.receipts.filters.dates.payment_method.loading'
                            )}
                            loading={isLoadingPaymentMethods}
                            onChange={(event, newValue) => {
                              setFieldValue('payment_method_id', newValue?.id);
                            }}
                            value={paymentMethods?.find(
                              (item) => item.id === values.payment_method_id
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                                placeholder={t(
                                  'financial.receipts.filters.dates.payment_method.placeholder'
                                )}
                              />
                            )}
                            fullWidth
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={12} lg={6} container spacing={2}>
                    {/*container*/}
                    <Grid item xs={12} container>
                      <Grid item xs={12} md={3} xl={4} container sx={{ alignContent: 'center' }}>
                        <Typography variant="h5" className={classes.labelTypo}>
                          {t('financial.receipts.filters.dates.cashier_label')}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={9} xl={8} container>
                        <TextField
                          fullWidth
                          //error={Boolean(errors.to && touched.to)}
                          placeholder={t('financial.receipts.filters.dates.cashier_placeholder')}
                          {...getFieldProps('cajero')}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid item xs={12} textAlign="right" mt={2}>
                    <Button
                      type="submit"
                      variant="contained"
                      //disabled={isLoading}
                      //endIcon={isLoading && <CircularProgress size={20} />}
                      //fullWidth
                    >
                      <IconSearch />
                    </Button>
                    {/* <Grid item xs={12} md={6} xl={6} container sx={{ alignContent: 'center' }}>
                      
                    </Grid>
                    <Grid item xs={12} md={6} xl={6} container>
                      
                    </Grid> */}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} container spacing={2}>
            {
              /*isLoading ? (
              renderSkeletonTable(5, 7)
            ) : (*/
              <CustomCollapsibleTable
                data={datafetch ? datafetch : []}
                headers={{
                  id: t('financial.receipts.table.detail'),
                  ref: t('financial.receipts.table.ref'),
                  fecha_emision: t('financial.receipts.table.emission_date'),
                  identificacion: t('financial.receipts.table.identification'),
                  estudiante: t('financial.receipts.table.debtor'),
                  cajero: t('financial.receipts.table.cashier'),
                  total_amounth: `${defaultConfiguration?.currency_symbol}${t(
                    'financial.receipts.table.amount'
                  )}`,
                  options: t('shared.search_paginated_table.table.options'),
                }}
                keyExtractor={(item) => item.options.id.toString()}
                searchPlacehoder={t('financial.receipts.table.search_placeholder')}
                isLoading={isLoading}
                isFetching={isFetching}
                error={error}
                perPage={perPage}
                setPerPage={setPerPage}
                total={receiptsResp?.total || 0}
                page={page}
                setPage={setPage}
                numHeader={7}
                search={search}
                setSearch={setSearch}
                //apply collapsible function, in specific row (optional)
                isCollapsible={true}
                overwriteRowC={0}
                CollapsibleItems={CollapsibleContentRow}
                showFilter={false}
                customRenderers={{
                  options: (row) => (
                    <>
                      <Tooltip title={t('financial.receipts.table.options.view_voucher')}>
                        <IconButton onClick={() => handleOpen2(`${row.options.id_transaction}`)}>
                          <IconFileSearch />
                        </IconButton>
                      </Tooltip>

                      {row?.options?.payment_reverse_check && (
                        <Tooltip title={t('financial.receipts.table.options.revert_check')}>
                          <IconButton onClick={() => handleOpenCheckReceipt(row.id)}>
                            <IconCashBanknoteOff />
                          </IconButton>
                        </Tooltip>
                      )}

                      {row?.options?.product_reverse === 1 && (
                        <Tooltip title={t('financial.receipts.table.options.revert_payment')}>
                          <IconButton
                            onClick={() => {
                              handleOpenDeleteReceipt(`${row.id}`);
                            }}
                          >
                            <IconEraser />
                          </IconButton>
                        </Tooltip>
                      )}
                      {row?.options?.return_value_receipt === 1 && (
                        <Tooltip title={t('financial.receipts.table.options.cancellation_payment')}>
                          <IconButton
                            onClick={() => {
                              handleOpenCancelMultiple({
                                idFact: row.options.fact_id,
                                receiptId: row.id,
                              });
                            }}
                            color="warning"
                          >
                            <IconFileX />
                          </IconButton>
                        </Tooltip>
                      )}
                    </>
                  ),
                }}
                customHeadersCellsProperties={{
                  options: {
                    align: 'center',
                  },
                  id: {
                    align: 'center',
                  },
                }}
                customDataCellsProperties={{
                  id: {
                    align: 'center',
                  },
                  ref: {
                    align: 'center',
                  },
                  options: {
                    align: 'center',
                  },
                }}
              />
              /*)*/
            }
          </Grid>
        </Grid>
      </form>
      {openReceipt && (
        <DialogIframe
          open={openReceipt}
          onClose={handleClose2}
          title={'receipt'}
          id={'receipt'}
          isLoadingPDF={isFetchingPDF}
          htmlC={receipt}
          error={errorPDF as string}
          avaliableDownload={receipt === '' ? false : true}
        />
      )}

      {openDeleteReceipt && (
        <ReverseDialog
          open={openDeleteReceipt}
          transactionId={Number(transactionId)}
          handleClose={handleCloseDeleteReceipt}
        />
      )}

      {openCancelReceipt && (
        <CancelReceiptDialog
          open={openCancelReceipt}
          idFact={cancelReceiptIds.idFact}
          receiptId={cancelReceiptIds.receiptId}
          handleClose={handleCloseCancel}
        />
      )}

      {openCheckReceiopt && (
        <ReverseDialogCheck
          open={openCheckReceiopt}
          receiptId={checkReceipt}
          handleClose={handleCloseCeckReceipt}
        />
      )}
    </>
  );
};
export default ReceiptsTable;
