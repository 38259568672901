import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  FormHelperText,
  Grid,
  TextField,
  Stack,
  Typography,
  FormControlLabel,
  Switch,
  useTheme,
  useMediaQuery,
  Snackbar,
  Alert,
  CircularProgress,
  IconButton,
  Skeleton,
  LinearProgress,
  ToggleButtonGroup,
  ToggleButton,
} from '@material-ui/core';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import { makeStyles } from '@material-ui/core/styles';
import { IconTrash, IconCornerDownRight, IconChevronRight, IconX, IconEdit } from '@tabler/icons';
import { useFormik } from 'formik';
import 'moment/locale/es';
import { Calendar, momentLocalizer, CalendarProps, EventProps, Event } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import moment from 'moment';

import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import { useAppSelector } from '../../../../hooks/useRedux';
import {
  setCurrentDateSelected,
  setHolidaysList,
  setHolidaysMove,
  setHolidaysResponse,
  setInitialPrograms,
} from '../slices/acPlanPostgraduateSlice';
import { useGetLocaleDateLang } from '../../../../translation/hooks/useGetLocaleDateLang';
import { drawerWidth } from '../../../shared/constants/uiConstants';
import Simbologies from './symbologies/Symbologies';
import Transitions from '../../../shared/components/extended/Transitions';
import { useAppDispatch } from '../../../../hooks/useRedux';
import { MyEvents } from '../interfaces/events.interfaces';
import {
  useAddHolidayMutation,
  useAddOrEditPlanningMutation,
  useAddPlanningTemporaryMutation,
  useDeleteHolidayMutation,
  useDeleteTemporaryPlanningMutation,
  useGetHolidaysAvailablesQuery,
  useGetPlanningTemporaryQuery,
  useUpdateHolidayMutation,
} from '../slices/acPlanPostgraduateApiSlice';
import { Save } from '@material-ui/icons';
import { useInView } from 'react-intersection-observer';
import { DatePicker, LocalizationProvider } from '@material-ui/lab';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import { useTranslation } from 'react-i18next';
import useGetDayTypes from '../hooks/useGetDayTypes';
import useGetHolidays from '../hooks/useGetHolidays';
import useGeneratePIPsWithHolidays from '../hooks/useGeneratePIPsWithHolidays';
import { getUnavailableDatesStyle } from '../helpers/helpers';
import AddPlanningPointDialog from './dialogs/AddPlanningPointDialog';
import useSharedGuard from '../../../../hooks/useSharedGuard';
import { subPermissionKeys } from '../constants/permissions';
import { parse } from 'date-fns';

const useStyles = makeStyles((theme) => ({
  calendar: {
    '& .rbc-btn-group': { display: 'none' },
  },
}));

const MINIMUM_PLANNING_DAYS = 1;

const DnDCalendar = withDragAndDrop(Calendar as React.ComponentType<CalendarProps>);

const CalendarForm = () => {
  const { t } = useTranslation();

  const history = useHistory();

  const dispatch = useAppDispatch();

  const { hasAccess } = useSharedGuard(subPermissionKeys);

  const [, { isSuccess, isLoading }] = useAddPlanningTemporaryMutation();

  const [addPlannignDialogOpen, setAddPlannignDialogOpen] = useState(false);
  const [newPlanningEvent, setNewPlanningEvent] = useState<MyEvents | undefined>(undefined);
  const [planningPayload, setPlanningPayload] = useState<any>(undefined);
  const [autoHandle, setAutoHandle] = useState<boolean>(false);

  const { opened } = useAppSelector((state) => state.customization);
  const { defaultConfiguration } = useAppSelector((state) => state.access);

  const { dragEvent, currentDateSelected } = useAppSelector(
    (state) => state.academicPlanningPosgraduate
  );

  const { lang, langKey } = useGetLocaleDateLang();

  const classes = useStyles();

  const theme = useTheme();

  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

  const { colorEvents, holidaysResponse, holidaysMove, calendarIdSelected } = useAppSelector(
    (state) => state.academicPlanningPosgraduate
  );

  const { ref, inView: isVisible } = useInView();

  const localizer = momentLocalizer(moment);

  const [showAlerts, setShowAlerts] = useState(false);

  const [selectedtEventid, setSelectedEventId] = useState<string>('');

  const [firstRender, setFirstRender] = useState(true);

  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [openAlertTemporaryEvents, setOpenAlertTemporaryEvents] = useState(true);

  const [isRecoverEvents, setIsRecoverEvents] = useState(false);

  const [isEditEvent, setIsEditEvent] = useState(false);

  const [calendarView, setCalendarView] = useState<'month' | 'year'>('year');

  const handleChangeCalendarView = (
    event: React.MouseEvent<HTMLElement>,
    newCalendarView: string
  ) => {
    if (newCalendarView !== null) {
      setCalendarView(newCalendarView as 'month' | 'year');
    } else {
      setCalendarView('year');
    }
  };

  useEffect(() => {
    setSelectedEventId('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarIdSelected]);

  // La libreria  me obliga a tener este estado pese a que no se lo utiliza :'C

  const [addOrEditPlanning, { isLoading: isAddingOrEditting }] = useAddOrEditPlanningMutation();
  const [deletePlanning] = useDeleteTemporaryPlanningMutation();
  const [addHoliday, { isLoading: isAddingHoliday }] = useAddHolidayMutation();
  const [updateHoliday, { isLoading: isUpdatingHoliday }] = useUpdateHolidayMutation();
  const [deleteHoliday] = useDeleteHolidayMutation();

  const { holiday, initialPlanningPoint, startProgram } = useGetDayTypes();

  useEffect(() => {
    if (holidaysMove) {
      dispatch(setHolidaysList(holidaysResponse?.['move-holidays']));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const DaysEvents: MyEvents[] = useGetHolidays();

  const [myEvents, setMyEvents] = useState<MyEvents[]>(DaysEvents);

  const selectedtEvent = useMemo(
    () => myEvents.find((event) => event.id === selectedtEventid),
    [selectedtEventid, myEvents]
  );

  const pipWithHolidays = useGeneratePIPsWithHolidays(myEvents);

  const moveEvent = useCallback(
    ({ event, start, end }) => {
      if (!hasAccess('updateHolidays', false)) return;

      if (event.typeId === holiday?.pdt_keywork) {
        updateHoliday({
          id: event.id,
          payload: {
            day: start.getDate(),
            month: start.getMonth() + 1,
            year: start.getFullYear(),
            current_country: defaultConfiguration?.current_country,
            description: event.title,
            postgraduate_day_type_id: holiday?.id!,
            status_id: 1,
            // addExtraWeek: event.addExtraWeek,
          },
          ...(calendarIdSelected && { calendarId: calendarIdSelected }),
        });

        return;
      }

      const daysBeforeSunday = moment(start).day() === 0 ? 0 : 6 - moment(start).day() + 1;

      const haveEventInTheSameTime = myEvents
        .filter((myEvent) => myEvent.typeId === initialPlanningPoint?.pdt_keywork)
        .filter((myEvent) => myEvent.id !== event.id)
        .some(
          (e) =>
            e.start <= start &&
            start <
              moment(e.start)
                .add(
                  e.addExtraWeek
                    ? Number(holidaysResponse?.trabajo_autonomo?.pdt_number_of_days) +
                        7 -
                        daysBeforeSunday
                    : Number(holidaysResponse?.trabajo_autonomo?.pdt_number_of_days) -
                        daysBeforeSunday,
                  'days'
                )
                .toDate()
        );

      const endsAfterOtherInitialPointStart = myEvents
        .filter((myEvent) => myEvent.typeId === initialPlanningPoint?.pdt_keywork)
        .filter((myEvent) => myEvent.id !== event.id)
        .some((e) =>
          moment(start)
            .add(
              e.addExtraWeek
                ? Number(holidaysResponse?.trabajo_autonomo?.pdt_number_of_days) +
                    7 -
                    daysBeforeSunday
                : Number(holidaysResponse?.trabajo_autonomo?.pdt_number_of_days) - daysBeforeSunday,
              'days'
            )
            .isBetween(
              e.start,
              moment(e.start)
                .add(
                  e.addExtraWeek
                    ? Number(holidaysResponse?.trabajo_autonomo?.pdt_number_of_days) +
                        7 -
                        daysBeforeSunday
                    : Number(holidaysResponse?.trabajo_autonomo?.pdt_number_of_days) -
                        daysBeforeSunday,
                  'days'
                )
                .toDate()
            )
        );

      if (
        (haveEventInTheSameTime || endsAfterOtherInitialPointStart) &&
        event.typeId === initialPlanningPoint?.pdt_keywork
      ) {
        return;
      }

      const eventFound = myEvents.find((myEvent) => myEvent.id === event.id);

      addOrEditPlanning({
        datePayload: {
          ...eventFound,
          postgraduate_day_type_id: colorEvents?.find(
            (colorEvent) => colorEvent.pdt_keywork === event.typeId
          )?.id,
          colour: event.color,
          start_date: start,
          end_date: moment(start)
            .add(
              Number(holidaysResponse?.trabajo_autonomo?.pdt_number_of_days) - daysBeforeSunday,
              'days'
            )
            .toDate(),
          check_day_start_point_planning: eventFound?.addExtraWeek || false,
        },
        calendarId: calendarIdSelected!,
      });

      setMyEvents((prev: any) => {
        const existing = prev.find((ev: any) => ev.id === event.id) ?? {};
        const filtered = prev.filter((ev: any) => ev.id !== event.id);

        return [...filtered, { ...existing, start, end }];
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [myEvents]
  );

  const handleDeleteEvent = (id: string) => {
    if (myEvents.find((event) => event.id === id)?.typeId === holiday?.pdt_keywork) {
      deleteHoliday({ id });
    } else {
      deletePlanning({ id });
    }
    const eventFilter = myEvents.filter((event) => !(event.id === id));
    setMyEvents(eventFilter);
    setSelectedEventId('');
  };

  const { errors, handleSubmit, setFieldValue, values } = useFormik({
    initialValues: {
      year: currentDateSelected
        ? parse(currentDateSelected, 'dd-MMM-yyyy', new Date())
        : new Date(),
    },
    onSubmit: async (values) => {
      setShowAlerts(true);

      if ((dataTemporary?.data?.[0]?.total_event_save || 0) < MINIMUM_PLANNING_DAYS) {
        setOpenSnackbar(true);
      } else {
        history.push('/planeacion-academica-posgrado/fechas-carrera');
      }
    },
  });

  const { data, isLoading: isLoadingHolidays } = useGetHolidaysAvailablesQuery({
    currentCountry: 'pais-ec',
    year: values.year.getFullYear(),
    yearPlusOne: true,
    generateHoliday: true,
    ...(calendarIdSelected && { calendarId: calendarIdSelected }),
  });

  useEffect(() => {
    if (data) {
      dispatch(setHolidaysList(data?.holidays || []));
      dispatch(setHolidaysResponse(data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, dispatch]);

  const {
    data: dataTemporary,
    isLoading: isLoadingTemporary,
    isFetching: isFetchingTemporary,
  } = useGetPlanningTemporaryQuery(
    {
      page: 1,
      size: 50,
      sort: 'id',
      typeSort: 'desc',
      year: values.year.getFullYear(),
      month: values.year.getMonth() + 1,
      year_plus_one: true,
      generate_holiday: true,
      ...(calendarIdSelected && { calendarId: calendarIdSelected }),
    },
    { skip: !data }
  );

  useEffect(() => {
    if (dataTemporary) {
      handleRecover();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataTemporary]);

  const handleRecover = () => {
    const EventsDaysRecover: MyEvents[] = dataTemporary!
      .data!.filter((event) =>
        [initialPlanningPoint?.id, holiday?.id].some(
          (eventId) => eventId === event.postgraduate_day_type_id
        )
      )!
      .map((eventPIP) => ({
        start: new Date(eventPIP.start_date + 'T00:00:00'),
        end: new Date(eventPIP.start_date + 'T00:00:00'),
        title:
          eventPIP?.posgraduate_day_type?.pdt_description_day ||
          eventPIP?.holiday.pdt_description_day ||
          '-1',
        color:
          eventPIP?.posgraduate_day_type?.pdt_colour || eventPIP.holiday.pdt_colour || '#ff0000',
        id: eventPIP.id || '-1',
        typeId:
          eventPIP?.posgraduate_day_type?.pdt_keywork || eventPIP?.holiday.pdt_keywork || '-1',
        droppedOnAllDaySlot: false,
        addExtraWeek: Boolean(Number(eventPIP?.check_day_start_point_planning)),
      }));

    const EventInitialProgramRecover: MyEvents[] = dataTemporary!
      .data!.filter((event) => event.postgraduate_day_type_id === startProgram?.id)!
      .map((eventPIP) => {
        return {
          start: new Date(eventPIP.start_date + 'T00:00:00'),
          end: new Date(eventPIP.start_date + 'T00:00:00'),

          title: eventPIP?.posgraduate_day_type?.pdt_description_day || '-1',
          color: eventPIP?.posgraduate_day_type?.pdt_colour || '#ff0000',
          id: eventPIP.id || '-1',
          typeId: eventPIP?.posgraduate_day_type?.pdt_keywork || '-1',
          droppedOnAllDaySlot: false,
          eduLevelId: eventPIP.education_level_carrer_id || 0,
          eduLevelFatherId: eventPIP.education_level_id || 0,
          curriculumId: eventPIP.curriculum_id || 0,
          idTransactionPlanning: eventPIP.id_transaction_planning,
          status: eventPIP?.postgraduate_planning_status?.status_planning || '',
          namePeriod: eventPIP?.postgraduate_planning_additional_information?.name_period,
          hourhandsMove: Boolean(
            Number(eventPIP?.postgraduate_planning_additional_information?.hourhands_move)
          ),
          addExtraWeek: Boolean(Number(eventPIP?.check_day_start_point_planning)),
        };
      });

    setMyEvents([...DaysEvents, ...EventsDaysRecover]);

    dispatch(setInitialPrograms(EventInitialProgramRecover));

    setIsRecoverEvents(true);
  };

  const handleHolidaysGenerator = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setHolidaysMove(event.target.checked));
  };

  useEffect(() => {
    if (isSuccess) {
      history.push('/planeacion-academica-posgrado/fechas-carrera');
    }
    //eslint-disable-next-line
  }, [isSuccess]);

  useEffect(() => {
    if (!holidaysResponse['move-holidays'] && !holidaysResponse.holidays) return;

    if (holidaysMove) {
      dispatch(setHolidaysList(holidaysResponse['move-holidays']));
    } else {
      dispatch(setHolidaysList(holidaysResponse.holidays));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [holidaysMove, holidaysResponse]);

  useEffect(() => {
    if (firstRender) {
      setFirstRender(false);
      return;
    }

    const filterFDS = myEvents.filter((event) => !(event.typeId === holiday?.pdt_keywork));
    setMyEvents([...filterFDS, ...DaysEvents]);
    //eslint-disable-next-line
  }, [DaysEvents]);

  const handleChangeTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMyEvents((prev) =>
      prev.map((event) => {
        if (event.id === selectedtEvent?.id) {
          return {
            ...event,
            title: e.target.value,
          };
        }
        return event;
      })
    );
  };

  const handleSaveTitle = () => {
    if (selectedtEvent?.typeId === holiday?.pdt_keywork) {
      updateHoliday({
        id: selectedtEvent?.id!,
        payload: {
          current_country: defaultConfiguration?.current_country,
          day: selectedtEvent?.start.getDate(),
          month: (selectedtEvent?.start.getMonth() || 1) + 1,
          year: selectedtEvent?.start.getFullYear(),
          description: selectedtEvent?.title,
          postgraduate_day_type_id: holiday?.id!,
          status_id: 1,
        },
        ...(calendarIdSelected && { calendarId: calendarIdSelected }),
      });
    }
    setIsEditEvent(false);
  };

  const customEvent = ({ event: e }: EventProps<Event>) => (
    <Grid container>
      <Grid
        item
        container
        xs={12}
        alignItems="center"
        sx={{
          flexWrap: 'nowrap',
          height: '12px',
        }}
      >
        <Typography
          sx={{
            color: 'transparent',
          }}
        >
          {(e as unknown as MyEvents).title}
        </Typography>
      </Grid>
    </Grid>
  );

  const resetAddPlanningDialog = () => {
    setPlanningPayload(undefined);
    setAutoHandle(false);
    setNewPlanningEvent(undefined);
  };

  const successAddFn = (newPlanningEvent: MyEvents, addExtraWeek: boolean) => {
    setMyEvents([
      ...myEvents,
      {
        ...newPlanningEvent!,
        addExtraWeek: addExtraWeek,
      },
    ]);
    resetAddPlanningDialog();
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        {(isLoadingTemporary || isLoadingHolidays) && (
          <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
            <Grid item xs={8} md={3}>
              <Skeleton variant="rectangular" width="100%" height="10vh" />
            </Grid>

            <Grid item xs={12} mt={2}>
              <Skeleton variant="rectangular" width="100%" height="20vh" />
            </Grid>

            {Array.from(new Array(6)).map((item, index) => (
              <Grid item xs={12} md={4} key={index}>
                <Skeleton variant="rectangular" width="100%" height="400px" />
              </Grid>
            ))}
          </Grid>
        )}
        {!isLoadingTemporary && !isLoadingHolidays && (
          <Grid container direction="row" justifyContent="center" alignItems="center" gap={2}>
            {
              <Grid item xs={10}>
                {!!dataTemporary?.data.length && openAlertTemporaryEvents && (
                  <Alert
                    icon={false}
                    severity={isRecoverEvents ? 'success' : 'info'}
                    action={
                      <Grid container gap={2}>
                        <Button variant="text" onClick={handleRecover} disabled={isRecoverEvents}>
                          {isRecoverEvents ? t('posgraduate.recovered') : t('posgraduate.recover')}
                        </Button>
                        <IconButton
                          size="small"
                          aria-label="close"
                          color="inherit"
                          onClick={() => setOpenAlertTemporaryEvents(false)}
                        >
                          <IconX fontSize="small" />
                        </IconButton>
                      </Grid>
                    }
                  >
                    {isRecoverEvents
                      ? t('posgraduate.recover.success')
                      : 'Has estado trabajando en algunos eventos anteriormente. ¿Deseas recuperarlos?'}
                  </Alert>
                )}
              </Grid>
            }
            <Grid item>
              {showAlerts && isSuccess && (
                <SuccessAlert
                  message={t('posgraduate.success_added')}
                  handleDismiss={() => setShowAlerts(false)}
                />
              )}
            </Grid>

            <Grid
              item
              container
              xs={12}
              spacing={2}
              justifyContent="center"
              alignItems="center"
              ref={ref}
              mt={2}
              // sx={{ border: '1px solid blue' }}
            >
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={lang}>
                <DatePicker
                  views={['month', 'year']}
                  inputFormat="MMMM yyyy"
                  label={t('posgraduate.month_year')}
                  openTo="month"
                  onChange={(newValue) => {
                    setFieldValue('year', newValue);
                    dispatch(setCurrentDateSelected(newValue!));
                  }}
                  renderInput={(params) => <TextField {...params} helperText={null} />}
                  value={values.year}
                />
              </LocalizationProvider>
              {/* <Typography align="center" variant="h1" color={'GrayText'}>
              {format(values.year, 'MMMM', {
                locale: lang,
              })}
            </Typography> */}
            </Grid>

            <Grid
              item
              container
              md={10}
              spacing={2}
              justifyContent="left"
              alignItems="center"
              mt={2}
            >
              <Grid item sx={{ width: '100%' }}>
                <Simbologies
                  disabled={isAddingOrEditting || isAddingHoliday || isUpdatingHoliday}
                  simbologies={colorEvents
                    .filter((infoEvent) =>
                      [initialPlanningPoint?.pdt_keywork, holiday?.pdt_keywork].some(
                        (acronym) => acronym === infoEvent.pdt_keywork
                      )
                    )
                    .map((infoEvent) => ({
                      label: infoEvent.pdt_description_day,
                      color: infoEvent.pdt_colour,
                      index: myEvents.length,
                      typeId: infoEvent.pdt_keywork,
                    }))}
                />
              </Grid>
            </Grid>

            <Grid
              item
              container
              xs={10}
              spacing={2}
              justifyContent={matchDownMd ? 'right' : 'space-between'}
              alignItems="center"
            >
              <Grid item>
                <FormControlLabel
                  control={<Switch checked={holidaysMove} onChange={handleHolidaysGenerator} />}
                  label="Feriados por normativa"
                  labelPlacement={matchDownMd ? 'start' : 'top'}
                />
              </Grid>

              <Grid item>
                {isFetchingTemporary && (
                  <Grid item mb={1}>
                    <Typography variant="body1" color="initial" component={'span'}>
                      {t('posgraduate.loading')}
                    </Typography>
                    <LinearProgress />
                  </Grid>
                )}
                <Typography
                  variant="body2"
                  color="initial"
                  align="center"
                  component={'span'}
                  mx={2}
                >
                  {t('posgraduate.pip')}: {dataTemporary?.data?.[0]?.total_event_save || 0}
                </Typography>
                {/* <Button variant="contained" color={'primary'} onClick={() => setMyEvents(DaysEvents)}>
                Limpiar
              </Button> */}
              </Grid>
            </Grid>

            {!isVisible && (
              <Grid
                item
                container
                xs={12}
                md={6}
                spacing={2}
                justifyContent="center"
                alignItems="center"
                mt={2}
                sx={{
                  position: 'fixed',
                  left: !opened ? '70px' : `calc(${drawerWidth}px + 40px)`,
                  bottom: '20px',
                  zIndex: '10',
                }}
              >
                {isFetchingTemporary && (
                  <Grid item mb={1}>
                    <Typography variant="body1" color="initial" component={'span'}>
                      {t('posgraduate.loading')}
                    </Typography>
                    <LinearProgress />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Typography
                    variant="body2"
                    color="initial"
                    align="center"
                    component={'span'}
                    mx={2}
                  >
                    {t('posgraduate.pip')}: {dataTemporary?.data?.[0]?.total_event_save || 0}
                  </Typography>
                  <Simbologies
                    disabled={isAddingOrEditting || isAddingHoliday || isUpdatingHoliday}
                    simbologies={colorEvents
                      .filter((infoEvent) =>
                        [initialPlanningPoint?.pdt_keywork, holiday?.pdt_keywork].some(
                          (acronym) => acronym === infoEvent.pdt_keywork
                        )
                      )
                      .map((infoEvent) => ({
                        label: infoEvent.pdt_description_day,
                        color: infoEvent.pdt_colour,
                        index: myEvents.length,
                        typeId: infoEvent.pdt_keywork,
                      }))}
                  />
                </Grid>
              </Grid>
            )}

            <Transitions type="fade" in={!!selectedtEvent} position="bottom" direction={'left'}>
              <Stack
                direction="column"
                spacing={2}
                sx={{
                  position: 'fixed',
                  right: '50px',
                  bottom: '10vh',
                  zIndex: '10',
                }}
              >
                <Grid
                  container
                  sx={{
                    boxShadow: '0px 0px 20px #CADDF2',
                    backgroundColor: '#fff',
                    padding: 1,
                    borderRadius: '10px 0 0 10px',
                    borderStyle: `solid`,
                    borderColor: `${selectedtEvent ? selectedtEvent?.color : '#fff'}`,
                    borderWidth: '0 8px 0 0',
                  }}
                >
                  {!isEditEvent && (
                    <Typography align="right" color="inherit" variant="h5">
                      {selectedtEvent && selectedtEvent.title}
                    </Typography>
                  )}

                  {isEditEvent && (
                    <TextField
                      label={t('posgraduate.edit_title.label')}
                      defaultValue={selectedtEvent?.title}
                      onChange={handleChangeTitle}
                    />
                  )}
                </Grid>
                <Grid container justifyContent="flex-end" alignItems="center">
                  {!isEditEvent && (
                    <IconButton
                      aria-label="delete"
                      color="primary"
                      size="medium"
                      sx={{ boxShadow: '0px 0px 20px #CADDF2', backgroundColor: '#fff' }}
                      onClick={() => setIsEditEvent(true)}
                    >
                      <IconEdit />
                    </IconButton>
                  )}
                  {isEditEvent && (
                    <IconButton
                      aria-label="delete"
                      color="primary"
                      size="medium"
                      sx={{ boxShadow: '0px 0px 20px #CADDF2', backgroundColor: '#fff' }}
                      onClick={() => handleSaveTitle()}
                    >
                      <Save />
                    </IconButton>
                  )}
                </Grid>
                {hasAccess(['destroyHolidays', 'destroyCompletely']) && (
                  <Grid container justifyContent="flex-end" alignItems="center">
                    <IconButton
                      aria-label="delete"
                      color="primary"
                      size="medium"
                      sx={{ boxShadow: '0px 0px 20px #CADDF2', backgroundColor: '#fff' }}
                      onClick={() => handleDeleteEvent(selectedtEvent!.id)}
                    >
                      <IconTrash />
                    </IconButton>
                  </Grid>
                )}

                <Grid container justifyContent="flex-end" alignItems="center">
                  <IconButton
                    aria-label="delete"
                    color="primary"
                    size="medium"
                    sx={{ boxShadow: '0px 0px 20px #CADDF2', backgroundColor: '#fff' }}
                  >
                    <IconCornerDownRight />
                  </IconButton>
                </Grid>
                <Grid container justifyContent="flex-end" alignItems="center">
                  <IconButton
                    aria-label="delete"
                    color="primary"
                    size="medium"
                    sx={{ boxShadow: '0px 0px 20px #CADDF2', backgroundColor: '#fff' }}
                    onClick={() => setSelectedEventId(null!)}
                  >
                    <IconX />
                  </IconButton>
                </Grid>
              </Stack>
            </Transitions>

            {errors && (
              <FormHelperText error>
                {Object.keys(errors).map((error) => errors?.[error as keyof typeof errors])}
              </FormHelperText>
            )}

            <Grid
              item
              container
              md={10}
              spacing={4}
              justifyContent="flex-end"
              alignItems="center"
              mt={2}
            >
              <ToggleButtonGroup value={calendarView} onChange={handleChangeCalendarView} exclusive>
                <ToggleButton value="month">{t('posgraduate.month')}</ToggleButton>
                <ToggleButton value="year">{t('posgraduate.year')}</ToggleButton>
              </ToggleButtonGroup>
            </Grid>

            <Grid
              item
              container
              md={10}
              spacing={4}
              justifyContent="center"
              alignItems="center"
              mt={2}
            >
              {Array.from(new Array(calendarView === 'month' ? 1 : 12)).map((e, index) => (
                <Grid
                  item
                  xs={12}
                  md={calendarView === 'month' ? 12 : 6}
                  lg={calendarView === 'month' ? 12 : 4}
                  key={index}
                >
                  <DnDCalendar
                    views={calendarView === 'month' ? ['month', 'week'] : ['month']}
                    events={myEvents}
                    selectable
                    dayPropGetter={(day) => {
                      var style = {
                        backgroundColor: '#AECDC3',
                        opacity: 0.3,
                        display: 'block',
                      };
                      return {
                        style: getUnavailableDatesStyle({
                          day,
                          style,
                          myEvents,
                          IPAKewyword: initialPlanningPoint?.pdt_keywork,
                          numDays: holidaysResponse?.trabajo_autonomo?.pdt_number_of_days,
                          pipWithHolidays,
                        }),
                      };
                    }}
                    className={calendarView === 'year' ? classes.calendar : ''}
                    date={moment(new Date(values.year)).add(index, 'month').toDate()}
                    localizer={localizer}
                    onNavigate={(date) => setFieldValue('year', date)}
                    startAccessor="start"
                    endAccessor="end"
                    style={{ height: calendarView === 'month' ? 500 : 400 }}
                    onSelectEvent={(event) => setSelectedEventId((event as unknown as MyEvents).id)}
                    onSelectSlot={() => setSelectedEventId('')}
                    onDropFromOutside={async (event) => {
                      const daysBeforeSunday =
                        moment(event.start).day() === 0 ? 0 : 6 - moment(event.start).day() + 1;

                      const haveEventInTheSameTime = myEvents
                        .filter((myEvent) => myEvent.typeId === initialPlanningPoint?.pdt_keywork)
                        .some(
                          (e) =>
                            e.start <= event.start &&
                            event.start <
                              moment(e.start)
                                .add(
                                  e.addExtraWeek
                                    ? Number(
                                        holidaysResponse?.trabajo_autonomo?.pdt_number_of_days
                                      ) +
                                        7 -
                                        daysBeforeSunday
                                    : Number(
                                        holidaysResponse?.trabajo_autonomo?.pdt_number_of_days
                                      ) - daysBeforeSunday,
                                  'days'
                                )
                                .toDate()
                        );

                      const endsAfterOtherInitialPointStart = myEvents
                        .filter((myEvent) => myEvent.typeId === initialPlanningPoint?.pdt_keywork)
                        .some((e) =>
                          moment(event.start).isBetween(
                            e.start,
                            moment(e.start)
                              .add(
                                e.addExtraWeek
                                  ? Number(holidaysResponse?.trabajo_autonomo?.pdt_number_of_days) +
                                      7 -
                                      daysBeforeSunday
                                  : Number(holidaysResponse?.trabajo_autonomo?.pdt_number_of_days) -
                                      daysBeforeSunday,
                                'days'
                              )
                              .toDate()
                          )
                        );

                      if (
                        (haveEventInTheSameTime || endsAfterOtherInitialPointStart) &&
                        dragEvent.typeId === initialPlanningPoint?.pdt_keywork
                      ) {
                        return;
                      }

                      const newEvent = {
                        start: new Date(event.start),
                        end: new Date(event.start),
                        color: dragEvent.color!,
                        id: `generate|${Date.now()}`,
                        title: dragEvent.title!,
                        droppedOnAllDaySlot: true,
                        typeId: dragEvent.typeId!,
                      };

                      // eslint-disable-next-line
                      if (dragEvent.typeId === holiday?.pdt_keywork) {
                        addHoliday({
                          current_country: defaultConfiguration?.current_country,
                          day: new Date(event.start).getDate(),
                          month: new Date(event.start).getMonth() + 1,
                          year: new Date(event.start).getFullYear(),
                          description: 'Feriado',
                          postgraduate_day_type_id: holiday?.id!,
                          status_id: 1,
                          type_holiday: 'loc',
                          ...(calendarIdSelected && { calendarId: calendarIdSelected }),
                        });

                        setMyEvents([...myEvents, newEvent]);
                      } else {
                        const hasAHolidayBetween = myEvents
                          .filter((myEvent) => myEvent.typeId === holiday?.pdt_keywork)
                          .some((e) =>
                            moment(event.start)
                              .add(
                                Number(holidaysResponse?.trabajo_autonomo?.pdt_number_of_days) -
                                  daysBeforeSunday,
                                'days'
                              )
                              .isBetween(
                                e.start,
                                moment(e.start)
                                  .add(
                                    Number(holidaysResponse?.trabajo_autonomo?.pdt_number_of_days) -
                                      daysBeforeSunday,
                                    'days'
                                  )
                                  .toDate()
                              )
                          );

                        const payload = {
                          datePayload: {
                            start_date: new Date(event.start),
                            end_date: moment(event.start)
                              .add(
                                Number(holidaysResponse?.trabajo_autonomo?.pdt_number_of_days) -
                                  daysBeforeSunday,
                                'days'
                              )
                              .toDate(),
                            postgraduate_day_type_id: colorEvents?.find(
                              (colorEvent) => dragEvent.typeId === colorEvent.pdt_keywork
                            )?.id,
                            colour: dragEvent.color,
                          },
                          calendarId: calendarIdSelected!,
                        };

                        setNewPlanningEvent(newEvent);
                        setAutoHandle(!hasAHolidayBetween);
                        setPlanningPayload(payload);
                        setAddPlannignDialogOpen(true);
                      }

                      return;
                    }}
                    eventPropGetter={(event) => {
                      var backgroundColor = (event as unknown as MyEvents)?.color;
                      var style = {
                        backgroundColor: backgroundColor,
                        borderRadius: '0px',
                        opacity: 0.8,
                        color: 'black',
                        border: '0px',
                        display: 'block',
                      };
                      return {
                        style,
                      };
                    }}
                    onEventDrop={moveEvent}
                    messages={{
                      next: t('posgraduate.next'),
                      previous: t('posgraduate.prev'),
                      today: t('posgraduate.today'),
                      month: t('posgraduate.month'),
                      week: t('posgraduate.week'),
                      day: t('posgraduate.day'),
                    }}
                    culture={langKey as string}
                    components={{
                      event: customEvent,
                    }}
                  />
                </Grid>
              ))}
            </Grid>

            <Grid
              item
              container
              alignItems="center"
              justifyContent="flex-end"
              spacing={2}
              sx={{ mt: 1 }}
            >
              <Grid item>
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  color="primary"
                  endIcon={isLoading ? <CircularProgress /> : <IconChevronRight />}
                  disabled={isLoading}
                >
                  {t('posgraduate.start')}
                </Button>
              </Grid>
            </Grid>
            <Snackbar
              open={openSnackbar}
              autoHideDuration={6000}
              onClose={() => setOpenSnackbar(false)}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              sx={{ position: 'fixed', top: '100px' }}
              // message="Debes tener minimo 12 puntos de inicio"
              action={
                <>
                  <Button color="secondary" size="small" onClick={() => setOpenSnackbar(false)}>
                    {t('posgraduate.catched')}
                  </Button>
                  <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={() => setOpenSnackbar(false)}
                  >
                    <IconX fontSize="small" />
                  </IconButton>
                </>
              }
            >
              <Alert onClose={() => setOpenSnackbar(false)} severity="error" sx={{ width: '100%' }}>
                {t('posgraduate.min_pip', { min: MINIMUM_PLANNING_DAYS })}
              </Alert>
            </Snackbar>
          </Grid>
        )}
      </form>
      {addPlannignDialogOpen && (
        <AddPlanningPointDialog
          open={addPlannignDialogOpen}
          planningPayload={planningPayload}
          newPlanningEvent={newPlanningEvent}
          onClose={() => {
            resetAddPlanningDialog();
            setAddPlannignDialogOpen(false);
          }}
          autoHandle={autoHandle}
          successEvent={successAddFn}
        />
      )}
    </>
  );
};

export default CalendarForm;
