import { Grid, IconButton, Typography, useTheme } from '@material-ui/core';
import { IconArrowNarrowLeft } from '@tabler/icons';
import { useHistory, useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import MainCardForm from '../../../shared/components/cards/MainCardForm';
import { useAppSelector } from '../../../../hooks/useRedux';
import { useEffect } from 'react';
import ClientAccountStatus from '../components/account-status';

const ClientAccountStatusScreen = () => {
  const theme = useTheme();
  const history = useHistory();

  const { t } = useTranslation();

  const { id } = useParams<{ id: string }>();

  const { clientList } = useAppSelector((state) => state.client);
  const client = clientList.find((client) => client.id === Number(id));

  useEffect(() => {
    if (!client) {
      return;
    }
  }, [client]);

  return (
    <MainCardForm>
      <Grid item xs={12}>
        <Grid item container alignItems="center" gap={1} sx={{ cursor: 'pointer' }}>
          <IconButton onClick={() => history.goBack()}>
            <IconArrowNarrowLeft size={30} color={theme.palette.text.dark} />
          </IconButton>
          <Typography variant="h2">
            {t('financial.client_account_status.title')} {client && ` - ${client.pers_full_name}`}{' '}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <ClientAccountStatus />
      </Grid>
    </MainCardForm>
  );
};

export default ClientAccountStatusScreen;
