import { mainApi } from '../../../../api/mainApi';
import { middlewareApi } from '../../../middleware/middlewareApi';
import { IResponse } from '../../../shared/interfaces/response.interface';
import { ReservedBook } from '../interfaces/resrved-books.interfaces';

export const userReservedBooksTags = middlewareApi.enhanceEndpoints({
  addTagTypes: ['ReservedBooks'],
});

export const userReservedBooksApi = userReservedBooksTags.injectEndpoints({
  endpoints: (builder) => ({
    getReservedBooksByUser: builder.query<
      IResponse<ReservedBook>,
      {
        search: string;
        perPage: number;
        page: number;
        userId: number;
        sort?: string;
        typeSort?: string;
      }
    >({
      queryFn: async ({ search, perPage, page, userId, sort, typeSort }) => {
        try {
          const { data } = await mainApi.get<IResponse<ReservedBook>>(
            `/reservations/user/${userId}`,
            {
              params: {
                search,
                size: perPage,
                page,
                reservation_status: 'Pendiente',
                ...(sort && { sort }),
                ...(typeSort && { type_sort: typeSort }),
              },
            }
          );
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'ReservedBooks', id } as const)),
              { type: 'ReservedBooks', id: 'LIST' },
            ]
          : [{ type: 'ReservedBooks', id: 'LIST' }],
    }),
  }),
});

export const { useGetReservedBooksByUserQuery } = userReservedBooksApi;
