import { useEffect, useMemo, useState } from 'react';
import SearchPaginatedTable, {
  FilterArrow,
} from '../../../shared/components/tables/SearchPaginatedTable';
import {
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  MenuList,
  MenuItem,
} from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import IconTelework from '../../../shared/components/illustrations/IconTelework';
import InputLabel from '../../../shared/components/forms/InputLabel';
import { useAppSelector, useAppDispatch } from '../../../../hooks/useRedux';
import { useGetClientByFiltersQuery } from '../slices/clientApiSlice';
import { useTableSearch } from '../../../../hooks/useTableSearch';
import { updateClientTable, setClientList } from '../slices/clientSlice';
import CustomPopper from '../../../shared/components/popper/CustomPopper';
import AddButton from '../../../shared/components/tables/buttons/AddButton';
import SwitchEarlyPayment from './SwitchEarlyPayment';
import { setCurrentClient } from '../../generate-debts/slices/generateDebtsSlice';
import useSharedGuard from '../../../../hooks/useSharedGuard';
import {
  debtorsPermissions,
  financialInformationPermissions,
  groupConfigurationPermissions,
  groupConfigurationStudentsPermissions,
  studentPermissions,
} from '../constants/permissions';

interface FilterOptions {
  student: number;
  teacher: number;
  administrative: number;
}

const ClientsList = () => {
  const dispatch = useAppDispatch();

  const { currentProfile } = useAppSelector((state) => state.access);

  const { t } = useTranslation();

  // const dispatch = useAppDispatch();
  const history = useHistory();

  const { page, perPage, search } = useAppSelector((state) => state.client.clientTable);

  const { setPage, setPerPage, setSearch } = useTableSearch({
    search,
    page,
    perPage,
    updateFunction: updateClientTable,
  });

  const { hasAccess: hasAccessGroupConfig } = useSharedGuard(groupConfigurationPermissions);
  const { hasAtLeastOne: hasGroupConfigStudent } = useSharedGuard(
    groupConfigurationStudentsPermissions
  );
  const { hasAccess: hasDebtors } = useSharedGuard(debtorsPermissions);
  const { hasAccess: hasFinancialInst } = useSharedGuard(financialInformationPermissions);
  const { hasAccess } = useSharedGuard(studentPermissions);
  const { hasSharedPermissions } = useSharedGuard();

  const [filter, setFilter] = useState<FilterOptions>({
    student: 1,
    teacher: 0,
    administrative: 0,
  });

  const [filterDebounce, setFilterDebounce] = useState<FilterOptions>({
    student: 1,
    teacher: 0,
    administrative: 0,
  });

  const [filterArrow, setFilterArrow] = useState<FilterArrow>(null!);

  const {
    data: Person,
    isLoading: isLoadingP,
    isFetching: isFetchingP,
    error,
  } = useGetClientByFiltersQuery(
    {
      user_profile_id: currentProfile?.profileId!,
      is_student: filterDebounce.student,
      is_teacher: filterDebounce.teacher,
      is_administrative: filterDebounce.administrative,
      size: perPage,
      page,
      search,
      ...(filterArrow && { sort: filterArrow.name, type_sort: filterArrow.direction }),
    },
    { refetchOnMountOrArgChange: true }
  );

  const datafetch2 = useMemo(
    () =>
      Person?.data!.map((item, index) => ({
        id: index + (page - 1) * perPage + 1,
        code: item.pers_identification || '',
        name: item.pers_full_name || '',
        applyEarlyPayment: item?.user?.student?.student_records?.[0]?.stud_apply_early_payment
          ? Boolean(Number(item?.user?.student?.student_records?.[0]?.stud_apply_early_payment))
          : false,
        options: {
          studentId: item?.user?.student?.id,
          id: item.id,
          studentRecord: item?.user?.student?.student_records,
          financialRepresentative: item,
        },
      })) || [],
    [Person?.data, page, perPage]
  );

  useEffect(() => {
    dispatch(setClientList(Person?.data!));
    //eslint-disable-next-line
  }, [Person]);

  useEffect(() => {
    let debounce = setTimeout(() => setFilterDebounce(filter), 2000);
    return () => {
      clearTimeout(debounce);
    };
  }, [filter]);

  return (
    <Grid>
      <Grid
        container
        item
        xs={12}
        sx={{
          mb: 2,
          border: `1px solid`,
          borderColor: 'primary.main',
          borderRadius: '5px',
          py: '10px',
          px: '14px',
        }}
        justifyContent="space-between"
      >
        <Grid
          container
          item
          md={12}
          lg={10}
          alignContent={'center'}
          alignItems={'center'}
          spacing={2}
        >
          <Grid item xs={12} sm={6} md={6} lg={'auto'}>
            <InputLabel sx={{ m: '0px!important' }}>
              {t('financial.client_list.filter_by')}
            </InputLabel>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={'auto'}>
            <InputLabel sx={{ m: '0px!important' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={!!filter.student}
                    onChange={(e) =>
                      setFilter((prev) => ({ ...prev, student: e.target.checked ? 1 : 0 }))
                    }
                  />
                }
                label={
                  <Typography variant="subtitle1">
                    {t('financial.client_list.student_check')}
                  </Typography>
                }
              />
            </InputLabel>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={'auto'}>
            <InputLabel sx={{ m: '0px!important' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={!!filter.teacher}
                    onChange={(e) =>
                      setFilter((prev) => ({ ...prev, teacher: e.target.checked ? 1 : 0 }))
                    }
                  />
                }
                label={
                  <Typography variant="subtitle1">
                    {t('financial.client_list.teacher_check')}
                  </Typography>
                }
              />
            </InputLabel>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={'auto'}>
            <InputLabel sx={{ m: '0px!important' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={!!filter.administrative}
                    onChange={(e) =>
                      setFilter((prev) => ({ ...prev, administrative: e.target.checked ? 1 : 0 }))
                    }
                  />
                }
                label={
                  <Typography variant="subtitle1">
                    {t('financial.client_list.admin_check')}
                  </Typography>
                }
              />
            </InputLabel>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} justifyContent="space-between">
        <Grid>
          {datafetch2 ? (
            <>
              <SearchPaginatedTable
                data={datafetch2 || []}
                headers={{
                  id: 'N°',
                  code: t('financial.client_list.table.identification'),
                  name: t('financial.client_list.table.names'),
                  applyEarlyPayment: t('academic.students_list.apply_early_payment'),
                  options: t('shared.search_paginated_table.table.options'),
                }}
                keyExtractor={(item) => item.id.toString()}
                listFilterArrows={{
                  code: 'pers_identification',
                  name: 'pers_full_name',
                }}
                filterArrow={filterArrow}
                setFilterArrow={setFilterArrow}
                searchPlacehoder={t('financial.client_list.table.search_placeholder')}
                isLoading={isLoadingP}
                isFetching={isFetchingP}
                error={error}
                perPage={perPage}
                setPerPage={setPerPage}
                total={Person?.total || 0}
                page={page}
                setPage={setPage}
                numHeader={5}
                search={search}
                setSearch={setSearch}
                customRenderers={{
                  applyEarlyPayment: (row) => (
                    <>
                      {!!row.options?.studentRecord?.length ? (
                        <SwitchEarlyPayment
                          defaultValue={row.applyEarlyPayment}
                          id={row.options.studentId}
                        />
                      ) : (
                        'N/A'
                      )}
                    </>
                  ),
                  options: (row) => (
                    <>
                      {(hasAccessGroupConfig(['read', 'store']) ||
                        hasGroupConfigStudent(['groupConfigurationByStudent', 'disabledGroup']) ||
                        hasAccess('getDebstByStudents') ||
                        (hasDebtors(['update', 'getDebtorPerPerson', 'getDebtorsByStudent']) &&
                          hasFinancialInst('getIdDebtor'))) && (
                        //
                        <CustomPopper iconButton={<MoreVert />} titleButton="Ver opciones">
                          <MenuList id="split-button-menu" autoFocusItem>
                            {(hasAccessGroupConfig(['read', 'store']) ||
                              hasGroupConfigStudent([
                                'groupConfigurationByStudent',
                                'disabledGroup',
                              ])) && (
                              <MenuItem
                                key={'academic_history'}
                                onClick={() =>
                                  history.push(
                                    `/administrar-clientes/${row.options.id}/asignar-descuento/asignacion`
                                  )
                                }
                              >
                                {t('financial.client_list.table_options.discount_btn_tooltip')}
                              </MenuItem>
                            )}

                            {row.options.studentId && hasAccess('getDebstByStudents') && (
                              <MenuItem
                                key={'account_state'}
                                onClick={() =>
                                  history.push(
                                    `/administrar-clientes/${row.options.studentId}/${row.options.id}/estado-cuenta`
                                  )
                                }
                              >
                                {t('financial.client_list.table_options.account_state')}
                              </MenuItem>
                            )}

                            {row.options.financialRepresentative &&
                            filter.student &&
                            !filter.teacher &&
                            !filter.administrative &&
                            hasDebtors(['update', 'getDebtorPerPerson', 'getDebtorsByStudent']) &&
                            hasFinancialInst('getIdDebtor') ? (
                              <MenuItem
                                key={'account_representation'}
                                onClick={() => {
                                  console.log(row.options.financialRepresentative);
                                  dispatch(setCurrentClient(row.options.financialRepresentative));
                                  history.push(
                                    `/administrar-clientes/${row.options.financialRepresentative.pers_identification}/${row.options.financialRepresentative.user.person_id}/${row.options.financialRepresentative.user?.student?.id}/representate-financiero`
                                  );
                                }}
                              >
                                {t('Escoja un representante financiero')}
                              </MenuItem>
                            ) : null}
                          </MenuList>
                        </CustomPopper>
                      )}

                      {/* {hasAccess('edit') && (
                          <Link to={`${path}/etapas/${row.id}`}>`
                            <Tooltip title="Registro de Saldos">
                              <IconButton aria-label="Registro de Saldos" color="default">
                                <Add />
                              </IconButton>
                            </Tooltip>
                          </Link>
                        )} */}
                      {/* {hasAccess('edit') && (
                              <Link to={`${path}/etapas/${row.id}`}>
                                <Tooltip title="Reverso de Pago">
                                  <IconButton aria-label="Reverso de Pago">
                                    <SettingsBackupRestore sx={{ color: 'red' }} />
                                  </IconButton>
                                </Tooltip>
                              </Link>
                            )} */}
                    </>
                  ),
                }}
                customHeadersCellsProperties={{
                  options: {
                    align: 'center',
                  },
                }}
                customDataCellsProperties={{
                  id: {
                    align: 'center',
                  },
                  code: {
                    align: 'center',
                  },
                  name: {
                    align: 'center',
                  },
                  applyEarlyPayment: { align: 'center' },
                  options: {
                    align: 'center',
                  },
                }}
                // ActionButtons={
                //   <>
                //     {hasAccess('create') && (
                //       <AddButton
                //         size="large"
                //         onClick={() => history.push('/administrar-clientes/agregar')}
                //       />
                //     )}
                //   </>
                // }
                ActionButtons={
                  <>
                    {hasSharedPermissions('createPerson') && (
                      <AddButton
                        size="large"
                        onClick={() => history.push('/administrar-clientes/agregar')}
                      />
                    )}
                  </>
                }
              />
            </>
          ) : (
            <Grid
              container
              justifyContent={'center'}
              alignContent={'center'}
              sx={{ height: '50vh' }}
            >
              <Grid container justifyContent={'center'} item xs={8} md={6}>
                <Grid container item xs={12} justifyContent={'center'}>
                  <IconTelework size={300} />
                </Grid>
                <Typography variant={'body1'} textAlign={'center'} lineHeight={2} component={'div'}>
                  <Typography variant={'h4'} component={'p'} align={'center'} lineHeight={2}>
                    {t('financial.client_list.no_data.title')}
                  </Typography>
                  {t('financial.client_list.no_data.info')}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ClientsList;
