import { CardActions, Button, Grid } from '@material-ui/core';
import { IconFolders, IconPoint } from '@tabler/icons';
import { useAppSelector } from '../../../../../../hooks/useRedux';
import { useTranslation } from 'react-i18next';

const EvaluationHeaderInfoCreate = () => {
  const { infoEvaluation, questions } = useAppSelector(
    (state) => state.evaluationTeacher.evaluation
  );

  const { t } = useTranslation();

  /**
   * Suma de los puntos de la evalución, se sumará solo
   * si la pregunta ha sido guardada.
   */
  const sumPointsEvaluation = (): number => {
    return questions.reduce((acc, value) => acc + value.point, 0);
  };

  return (
    <Grid item xs={12}>
      <CardActions sx={{ justifyContent: 'space-between' }}>
        <Button
          startIcon={<IconPoint fontSize="0.875rem" />}
          style={{ textTransform: 'unset' }}
          variant="text"
          size="small"
        >
          {t('teacherVirtualClassroom.tests.testHeaderInfoCreateTotalScore')}:{' '}
          {infoEvaluation?.actScoreActivity!}
        </Button>
        <Button
          disableRipple
          startIcon={<IconPoint fontSize="0.875rem" />}
          style={{ textTransform: 'unset' }}
          variant="text"
          size="small"
        >
          {sumPointsEvaluation()} / {infoEvaluation?.actScoreActivity!}{' '}
          {t('teacherVirtualClassroom.tests.testHeaderInfoAccumulatedScore')}
        </Button>
        <Button
          disableRipple
          startIcon={<IconFolders fontSize="0.875rem" />}
          style={{ textTransform: 'unset' }}
          variant="text"
          size="small"
        >
          {questions?.length!}{' '}
          {questions?.length! > 1
            ? t('teacherVirtualClassroom.tests.testHeaderQuestion') + 's'
            : t('teacherVirtualClassroom.tests.testHeaderQuestion')}
        </Button>
      </CardActions>
    </Grid>
  );
};
export default EvaluationHeaderInfoCreate;
