export const permissionKey = 'position';

export interface SubPermissionKeys {
  read: string;
  create: string;
  edit: string;
  delete: string;
  getOne: string;
}

export const subPermissionKeys: SubPermissionKeys = {
  read: 'positions-listar-cargos',
  create: 'positions-crear-cargo',
  edit: 'positions-actualizar-cargo',
  delete: 'positions-eliminar-cargo',
  getOne: 'positions-obtener-cargo',
};
