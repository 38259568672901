import * as Yup from 'yup';

export const CalendarSchema = Yup.object().shape({
  year: Yup.number().required('El año es requerido'),
  curriculum: Yup.string().required('La malla es requerida'),
});

export const CalendarInitialSchema = Yup.object().shape({
  year: Yup.date().required('El año es requerido'),
  curriculum: Yup.object().required('La malla es requerida'),
  career: Yup.array().required('La carrear es requerida'),
  facultad: Yup.object().required('La unidad académica es requerida'),
});

export const StageGenerationQuote = Yup.object().shape({
  numFees: Yup.number()
    .nullable()
    .required('Número de cuotas requerido')
    .min(1, 'Número de cuotas debe ser mínimo uno')
    .when('feesEnrollmentUnique', {
      is: (feesEnrollmentUnique: string) => feesEnrollmentUnique === '1',
      then: Yup.number().min(2, 'Número de cuotas debe ser mínimo 2'),
    }),
  generation_quote: Yup.array().of(
    Yup.object().shape({
      start_date: Yup.date()
        .typeError('Ingrese una fecha válida.')
        .required('La fecha de inicio es requerida.'),
      end_date: Yup.date()
        .typeError('Ingrese una fecha válida.')
        .required('La fecha de fin es requerida.')
        .min(Yup.ref('start_date'), 'La fecha fin debe ser mayor a la fecha de inicio'),
    })
  ),
  entry_teacher: Yup.array().of(
    Yup.object().shape({
      start_date: Yup.date()
        .typeError('Ingrese una fecha válida.')
        .required('La fecha de inicio es requerida.'),
      end_date: Yup.date()
        .typeError('Ingrese una fecha válida.')
        .required('La fecha de fin es requerida.')
        .min(Yup.ref('start_date'), 'La fecha fin debe ser mayor a la fecha de inicio'),
    })
  ),
});
