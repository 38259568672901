import { useTranslation } from 'react-i18next';

import ConfirmAlert from '../../../shared/components/dialogs/ConfirmAlert';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { setIsSuccessRefund, setOpenRefundModal } from '../slices/cashPointsSlice';
import { useRefundCashPointMutation } from '../slices/cashPointsApiSlice';

const ModalRefund = () => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const { isOpenRefundModal, cashPointToRefund } = useAppSelector((state) => state.cashPoints);
  const { currentProfile } = useAppSelector((state) => state.access);

  const [refund, { isLoading, isSuccess, isError, error, reset }] = useRefundCashPointMutation();

  const handleConfirm = () => {
    refund({
      cash_id: cashPointToRefund!,
      user_profile_id: currentProfile?.profileId!,
    });
  };

  const handleClose = () => {
    dispatch(setOpenRefundModal(false));
  };

  const handleSuccess = () => {
    reset();
    dispatch(setIsSuccessRefund(true));
    dispatch(setOpenRefundModal(false));
  };

  return (
    <ConfirmAlert
      open={isOpenRefundModal}
      onClose={handleClose}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      handleDelete={handleConfirm}
      error={error as string}
      title={t('financial.refund.open_dialog.open_title')}
      subtitle={t('financial.refund.open_dialog.open_sub_title')}
      confirmBtnMessage={t('financial.close_refund.open_dialog.confirm')}
      successMessage={t('financial.success_refund.open_dialog.open_success')}
      successEvent={isSuccess ? handleSuccess : () => {}}
    />
  );
};

export default ModalRefund;
