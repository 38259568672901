import { memo, useEffect, useState } from 'react';

import { Dialog, DialogProps, Grid, Skeleton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LoadingChildren from '../LoadingChildren';

const useStyles = makeStyles((theme) => ({
  paper: { maxWidth: '90%', width: 'auto', padding: 0 },
  image: {
    display: 'flex',
    height: 'auto',
    width: 'auto',
    maxWidth: '1000px',
    maxHeight: '80vh',
  },
}));

interface Props extends DialogProps {
  onClose: () => void;
  img: string;
  alt?: string;
  maxW?: string;
  maxH?: string;

  onClick?: () => void;
}

const DialogImg = ({ onClose, onClick, img, alt, ...props }: Props) => {
  const classes = useStyles();

  const [imgUrl, setImgUrl] = useState('');

  useEffect(() => {
    if (imgUrl !== img && img !== '') setImgUrl(img);
  }, [img, imgUrl]);

  // const theme = useTheme();
  return (
    <Dialog
      {...props}
      classes={{ paper: classes.paper }}
      sx={{ backgroundColor: 'transparent' }}
      onClose={onClose}
    >
      <Grid container gap={4} sx={{ background: 'transparent' }}>
        <Grid
          item
          container
          xs={12}
          alignItems="center"
          justifyContent="center"
          sx={{ background: 'transparent' }}
        >
          <Image onClick={onClick} /* classes={classes.image} alt={alt} */ src={imgUrl} />
        </Grid>
      </Grid>
    </Dialog>
  );
};

interface ImgProps {
  src: string;
  alt?: string;
  classes?: string;
  onClick?: () => void;
}
const Image = memo(
  ({ src, alt, classes, onClick }: ImgProps) => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      setLoading(true);
      const image = document.createElement('img');
      image.src = src;

      image.onload = () => {
        setTimeout(() => {
          setLoading(false);
        }, 500); // Espera 500ms antes de mostrar la imagen
      };
      image.onerror = () => {
        setLoading(true);
      };
    }, [src]);

    return (
      <LoadingChildren
        isLoading={loading}
        loadingSkeleton={<Skeleton height={'30vh'} width={'30vw'} />}
      >
        <img
          className={classes}
          alt={alt}
          src={src}
          onError={() => console.log('sadas')}
          style={{ cursor: onClick ? 'pointer' : 'default' }}
          onClick={onClick}
        />
      </LoadingChildren>
    );
  }
  // (prevProps, nextProps) => {
  //   return prevProps.src === nextProps.src;
  // }
);

export default DialogImg;
