import { mainApi } from '../../../../api/mainApi';
import { AccessState } from '../../../../types';
import { middlewareApi } from '../../../middleware/middlewareApi';
import {
  CheckoutDatafast,
  updateFinancialInformation,
  UploadDepositVoucher,
  //DepositPayment,
  uploadFile,
} from '../interfaces/payload.interface';
import {
  PaymentsHistoryResponse,
  PaymentsDebtsHistoryResponse,
  PaymentsDebts,
  BillingData,
  FinancialInstitutionAccount,
  FinancialInstitution,
  PaymentsByStatusResponse,
} from '../interfaces/paymentsHistory.interface';
import { Params, TypePaymentsSri } from '../interfaces/typePaymentSri.interface';
import { InfocompanyBystudent } from '../interfaces/types.interface';
//import { setSelectedDebts, setSelectedDebtsId } from './paymentsSlice';

export const paymentsTags = middlewareApi.enhanceEndpoints({
  addTagTypes: [
    'PaymentsDebtsHistory',
    'PaymentsHistory',
    'FinancialInformation',
    'TypePaymentsSri',
    'StudentsDebts',
    'PaymentsDebtsByStatus',
  ],
});
// Define a service using a base URL and expected endpoints
export const paymentsApi = paymentsTags.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * @GET all payments debts
     * @Permission debts-student-listar-deudas-estudiante
     */
    getStudentDebts: builder.query<
      PaymentsDebtsHistoryResponse,
      {
        profileId?: number;
        page: number;
        perPage: number;
        search: string;
        studentId: number;
        history_fees?: boolean;
        student_record?: number;
      }
    >({
      queryFn: async (
        { profileId, page, search, perPage, studentId, student_record, history_fees },
        { getState }
      ) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = profileId || currentProfile?.profileId;

          const { data } = await mainApi.get<PaymentsDebtsHistoryResponse>(
            `/students/${studentId}/debts`,
            {
              params: {
                user_profile_id,
                page,
                size: perPage,
                type_sort: 'asc',
                sort: 'fees_expiration_date',
                history_fees,
                ...(student_record && { student_record }),
                search,
              },
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) =>
        result?.data
          ? [
              ...result.data.map(
                ({ fees_identifier }) =>
                  ({ type: 'PaymentsDebtsHistory', fees_identifier } as const)
              ),
              { type: 'PaymentsDebtsHistory', id: 'LIST' },
            ]
          : [{ type: 'PaymentsDebtsHistory', id: 'LIST' }],
    }),

    /**
     * @GET all available debts
     */
    getAvailableStudentDebts: builder.query<
      PaymentsDebts[],
      { profileId?: number; student_record?: number; studentId: number }
    >({
      queryFn: async ({ profileId, studentId, student_record }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = currentProfile?.profileId;
          const { data } = await mainApi.get<PaymentsDebts[]>(`/students/${studentId}/debts`, {
            params: {
              ...(profileId ? { user_profile_id: profileId } : { user_profile_id }),
              ...(student_record && { student_record }),
              data: 'all',
            },
          });

          return {
            data: Array.isArray(data)
              ? data.sort(
                  (a, b) => Date.parse(a.fees_expiration_date) - Date.parse(b.fees_expiration_date)
                )
              : [],
          };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) => [{ type: 'StudentsDebts', id: 'LIST' }],
    }),

    /**
     * @GET all payments debts
     */
    getStudentPaymentsHis: builder.query<
      PaymentsHistoryResponse,
      {
        profileId: number;
        page: number;
        perPage: number;
        search: string;
        studentId: number;
      }
    >({
      queryFn: async ({ profileId, page, search, perPage, studentId }, { dispatch }) => {
        try {
          const { data } = await mainApi.get<PaymentsHistoryResponse>(
            `/students/${studentId}/payments`,
            {
              params: {
                user_profile_id: profileId,
                page,
                size: perPage,
                type_sort: 'desc',
                search,
              },
            }
          );

          //   dispatch(setRolesList(data.data));

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'PaymentsHistory', id } as const)),
              { type: 'PaymentsHistory', id: 'LIST' },
            ]
          : [{ type: 'PaymentsHistory', id: 'LIST' }],
    }),

    /**
     * @POST send checkout datafast
     */
    addPaymentDatafast: builder.mutation<
      any,
      { profileId: number; studentId: number; datafastPayload: Partial<CheckoutDatafast> }
    >({
      queryFn: async ({ profileId, studentId, datafastPayload }) => {
        try {
          const { data } = await mainApi.post<any>('/data-fast/paymentWidgets', {
            user_profile_id: profileId,
            student: studentId,
            ...datafastPayload,
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'PaymentsHistory', id: 'LIST' }],
    }),

    /**
     * @GET student billing data
     */
    getStudentBillingData: builder.query<
      BillingData,
      { profileId?: number; debtorId: number; personId?: number | string }
    >({
      queryFn: async ({ profileId, debtorId, personId }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = profileId || currentProfile?.profileId;

          const { data } = await mainApi.get<BillingData>(
            `/financial-informations/debtor/${debtorId}`,
            {
              params: {
                user_profile_id,
                person_id: personId,
              },
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result, error, { profileId }) => [{ type: 'FinancialInformation', profileId }],
    }),

    /**
     * @PUT update student billing data
     */
    updateStudentBillingData: builder.mutation<
      any,
      {
        profileId: number;
        financialInformationId: number;
        updatePayload: Partial<updateFinancialInformation>;
      }
    >({
      queryFn: async ({ profileId, financialInformationId, updatePayload }) => {
        try {
          const { data } = await mainApi.put<any>(
            `/financial-informations/${financialInformationId}`,
            {
              user_profile_id: profileId,
              ...updatePayload,
            }
          );
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: (result, error, { profileId }) => [
        { type: 'FinancialInformation', profileId },
      ],
    }),

    /**
     * @POST upload deposit voucher
     */
    addDepositVoucher: builder.mutation<
      string,
      {
        profileId: number;
        depositPayload: UploadDepositVoucher;
      }
    >({
      queryFn: async ({ profileId, depositPayload }, { dispatch }) => {
        try {
          const form = new FormData();
          form.append('files[]', depositPayload.files);
          form.append('type_document', depositPayload.type_document.toString());
          // form.append('period', '1');
          form.append('user_profile_id', String(depositPayload.user_profile_id));

          const { data: filePath } = await mainApi.post<uploadFile>(`/collection-files`, form, {
            headers: { 'Content-Type': 'multipart/form-data' },
          });
          const { data } = await mainApi.post<string>('/collection-web', {
            user_profile_id: profileId,
            type_document: 'fact',
            url_file: filePath.route_file,
            name_file: filePath.name_file,
            collections_keyword: depositPayload?.collections[0]?.financial_institution_origin_id
              ? 'forma-pago-transferencia'
              : 'forma-pago-deposito',
            fees: depositPayload.fees,
            collections: depositPayload.collections,
            temporal: depositPayload.temporal,
          });

          //When fees are paids we have to clean selectedDebts and SelectedDebtsId(bottomsticky nasty)
          //dispatch(setSelectedDebts([]));
          //dispatch(setSelectedDebtsId([]));

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [
        { type: 'PaymentsDebtsHistory', id: 'LIST' },
        {
          type: 'StudentsDebts',
          id: 'LIST',
        },
      ],
    }),

    /**
     * @GET tipo de pago SRi
     */
    getTypePaymentSri: builder.query<TypePaymentsSri[], Params>({
      queryFn: async (Params, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = currentProfile?.profileId;

          const { data } = await mainApi.get<TypePaymentsSri[]>(`/payment-sri`, {
            params: {
              user_profile_id,
              ...Params,
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },

      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'TypePaymentsSri', id } as const)),
              { type: 'TypePaymentsSri', id: 'LIST' },
            ]
          : [{ type: 'TypePaymentsSri', id: 'LIST' }],
    }),

    /**
     * @GET financial institution accounts
     * @Permission financial-institutions-accounts-listar
     */
    getFinancialInstitutionAccount: builder.query<
      FinancialInstitutionAccount[],
      { profileId: number; seeInactives?: boolean }
    >({
      queryFn: async ({ profileId, seeInactives }) => {
        try {
          const { data } = await mainApi.get<FinancialInstitutionAccount[]>(
            `/financial-institutions-accounts`,
            {
              params: {
                user_profile_id: profileId,
                ...(!seeInactives && { status_id: 1 }),
                data: 'all',
              },
            }
          );

          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    /**
     * @GET financial institutions
     * @Permission financial-institutions-obtener-intituciones-financieras-hijas-del-keyword-solicitado
     */
    getFinancialInstitutions: builder.query<FinancialInstitution[], { profileId: number }>({
      queryFn: async ({ profileId }) => {
        try {
          const { data } = await mainApi.get<FinancialInstitution[]>(
            `/financial-institutions/children`,
            {
              params: {
                user_profile_id: profileId,
                fin_int_keyword_parent: 'entidades-financieras',
              },
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    /**
     * @GET all payments debts by payment_status
     */
    getStudentPaymentsByState: builder.query<
      PaymentsByStatusResponse,
      {
        profileId: number;
        page: number;
        perPage: number;
        search: string;
        studentId: number;
        payment_status: string;
        student_record?: number;
      }
    >({
      queryFn: async (
        {
          profileId,
          page,
          search,
          perPage,
          studentId,
          payment_status = 'active-payments',
          student_record,
        },
        { dispatch }
      ) => {
        try {
          const { data } = await mainApi.get<PaymentsByStatusResponse>(
            `/students/${studentId}/${payment_status}`,
            {
              params: {
                user_profile_id: profileId,
                page,
                size: perPage,
                type_sort: 'desc',
                ...(student_record && { student_record }),
                search,
              },
            }
          );

          //   dispatch(setRolesList(data.data));
          console.log(data);
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(
                ({ fees_identifier }) =>
                  ({ type: 'PaymentsDebtsByStatus', fees_identifier } as const)
              ),
              { type: 'PaymentsDebtsByStatus', fees_identifier: 'LIST' },
            ]
          : [{ type: 'PaymentsDebtsByStatus', fees_identifier: 'LIST' }],
    }),

    /**
     * @GET recover receipt
     */
    getReceipts: builder.query<string, { transactionId: string }>({
      queryFn: async ({ transactionId }) => {
        try {
          const { data } = await mainApi.get<string>(
            `cash-collection/collection-transaction/${transactionId}/recover-receipt`
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    /**
     * @GET account state
     */
    getAccountState: builder.query<any, { studentId: number; student_record?: number }>({
      queryFn: async ({ studentId, student_record }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = currentProfile?.profileId;

          const { data } = await mainApi.get<any>(`students/${studentId}/account-status-pdf`, {
            responseType: 'arraybuffer',
            headers: {
              Accept: 'application/pdf',
              'Content-Type': 'application/pdf',
            },
            params: {
              user_profile_id,
              ...(student_record && { student_record }),
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),
    /**
     * @GET finantial institution cards
     * @Permission financial-institutions-obtener-cuentas-asocidas-intitucion-financiera-agrupadas-cards
     */
    getFinancialInstitutionCards: builder.query<any[], { finantialInstitutionId: number }>({
      queryFn: async ({ finantialInstitutionId }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = currentProfile?.profileId;

          const { data } = await mainApi.get<any[]>( //actualizar interfaz
            `financial-institutions/${finantialInstitutionId}/cards`,
            {
              params: {
                user_profile_id,
              },
            }
          );

          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    getInfoCompany: builder.query<InfocompanyBystudent, { profileId?: number }>({
      queryFn: async ({ profileId }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = currentProfile?.profileId || profileId;

          const { data } = await mainApi.get<InfocompanyBystudent>(`/information-companies`, {
            params: {
              user_profile_id,
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),
  }),
});

export const {
  useGetStudentDebtsQuery,
  useGetStudentPaymentsHisQuery,
  useGetAvailableStudentDebtsQuery,
  useLazyGetAvailableStudentDebtsQuery,
  useAddPaymentDatafastMutation,
  useGetStudentBillingDataQuery,
  useUpdateStudentBillingDataMutation,
  useAddDepositVoucherMutation,
  useGetTypePaymentSriQuery,
  useGetFinancialInstitutionAccountQuery,
  useGetFinancialInstitutionsQuery,
  useGetFinancialInstitutionCardsQuery,
  useGetStudentPaymentsByStateQuery,
  useGetReceiptsQuery,
  useGetAccountStateQuery,
  useGetInfoCompanyQuery,
} = paymentsApi;
