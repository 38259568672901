import { IconButton, Tooltip } from '@material-ui/core';
import { useEffect } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import SearchPaginatedTable from '../../../shared/components/tables/SearchPaginatedTable';
import { useGetFaqsQuery } from '../slices/faqApiSlice';
import { setFaqList, updateFaqsTable } from '../slices/faqSlice';
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone';
import DeleteButton from '../../../shared/components/tables/buttons/DeleteButton';
import AddButton from '../../../shared/components/tables/buttons/AddButton';
import { setFaqDelete } from '../slices/faqSlice';
import { useTranslation } from 'react-i18next';
import { useTableSearch } from '../../../../hooks/useTableSearch';

export const FaqList = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const history = useHistory();

  const { path } = useRouteMatch();

  const { page, perPage, search, filterArrow } = useAppSelector((state) => state.faqs.faqsTable);

  const { setPage, setPerPage, setSearch, setFilterArrow } = useTableSearch({
    search,
    page,
    perPage,
    filterArrow,
    updateFunction: updateFaqsTable,
  });

  const { currentProfile } = useAppSelector((state) => state.access);

  const { isLoading, data, isFetching, error } = useGetFaqsQuery({
    profileId: currentProfile?.profileId!,
    page,
    perPage,
    search: search,
    ...(filterArrow && { sort: filterArrow.name, type_sort: filterArrow.direction }),
  });

  const datafetch = data?.data?.map((e, index) => ({
    id: index + (page - 1) * perPage + 1,
    question: e?.faq_question,
    numVisit: e?.faq_num_visit,
    tags: e?.faq_tags?.replace(/,/g, ', '),
    options: e,
  }));

  const goToDelete = (faqId: number) => {
    dispatch(setFaqDelete(faqId));
  };

  const goToAdd = () => {
    history.push(`${path}/agregar`);
  };

  const goToEdit = (handleId: number) => {
    history.push(`${path}/editar/${handleId}`);
  };

  useEffect(() => {
    if (data) {
      if (Array.isArray(data?.data)) {
        dispatch(setFaqList(data?.data));
      }
    }
  }, [dispatch, data]);

  return (
    <SearchPaginatedTable
      data={datafetch!}
      headers={{
        id: 'N°',
        question: t('configuration.faqs.question'),
        numVisit: t('configuration.faqs.visits'),
        tags: t('configuration.faqs.tags'),
        options: t('configuration.faqs.options'),
      }}
      listFilterArrows={{
        question: 'faq_question',
        numVisit: 'faq_num_visit',
      }}
      filterArrow={filterArrow}
      setFilterArrow={setFilterArrow}
      keyExtractor={(item) => item.id.toString()}
      searchPlacehoder={t('configuration.faqs.search')}
      isLoading={isLoading}
      isFetching={isFetching}
      error={error}
      perPage={perPage}
      setPerPage={setPerPage}
      total={data?.total || 0}
      page={page}
      setPage={setPage}
      numHeader={7}
      search={search}
      setSearch={setSearch}
      customDataCellsProperties={{
        id: { align: 'center' },
        question: {
          align: 'left',
        },
        numVisit: {
          align: 'center',
        },
        options: {
          align: 'center',
        },
        tags: {
          align: 'center',
        },
      }}
      customHeadersCellsProperties={{
        question: {
          align: 'left',
        },
        options: {
          align: 'center',
        },
        numVisit: {
          align: 'center',
        },
        tags: {
          align: 'center',
        },
      }}
      customRenderers={{
        options: ({ options: { id } }) => (
          <>
            <Tooltip title={t('configuration.faqs.btn_edit')}>
              <span>
                <IconButton
                  onClick={() => goToEdit(id)}
                  aria-label={t('configuration.faqs.btn_edit')}
                  color="secondary"
                >
                  <EditTwoToneIcon sx={{ fontSize: '1.3rem' }} />
                </IconButton>
              </span>
            </Tooltip>
            <DeleteButton handleDelete={() => goToDelete(id)} />
          </>
        ),
      }}
      ActionButtons={<AddButton size="large" onClick={goToAdd} />}
    />
  );
};
