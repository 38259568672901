import DeleteAlert from '../../../shared/components/dialogs/DeleteAlert';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { useDeleteCalificationModelMutation } from '../slices/calificationModelsApiSlice';
import {
  resetCalificationModelIdToDelete,
  setDeleteModalOpen,
} from '../slices/calificationModelsSlice';

const DeleteCalificationModelDialog = () => {
  const dispatch = useAppDispatch();

  const { deleteModalOpen, calificationModelIdToDelete } = useAppSelector(
    (state) => state.calificationModel
  );
  const { currentProfile } = useAppSelector((state) => state.access);

  const [deleteCalificationModel, { isLoading, isSuccess, isError, error }] =
    useDeleteCalificationModelMutation();

  const handleDelete = () => {
    deleteCalificationModel({
      profileId: currentProfile?.profileId!,
      calificationModelId: calificationModelIdToDelete!,
    });
  };

  const handleClose = () => {
    dispatch(setDeleteModalOpen(false));
    dispatch(resetCalificationModelIdToDelete());
  };

  return (
    <DeleteAlert
      open={deleteModalOpen}
      onClose={handleClose}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      handleDelete={handleDelete}
      error={error as string}
    />
  );
};

export default DeleteCalificationModelDialog;
