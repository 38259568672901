import { Box, Button, Grid, TextField, Typography } from '@material-ui/core';
import { DateRangePicker, LocalizationProvider } from '@material-ui/lab';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import { es } from 'date-fns/locale';
import { useFormik } from 'formik';
import InputLabel from '../../../../shared/components/forms/InputLabel';
import { useLazyMetricsMostViewedQuery } from '../../slices/statisticsApiSlice';
import { format } from 'date-fns';
import { useAppSelector } from '../../../../../hooks/useRedux';
import SearchPaginatedTable from '../../../../shared/components/tables/SearchPaginatedTable';
import { useMemo, useState } from 'react';
import MetricsMessage from '../MetricsMessage';

const MostViewed = () => {
  const { currentProfile } = useAppSelector((state) => state.access);

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const [search, setSearch] = useState('');

  const [metrics, { isLoading, data, isUninitialized }] = useLazyMetricsMostViewedQuery();

  const { values, errors, touched, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      ini: '',
      end: '',
    },
    onSubmit: (values) => {
      metrics({
        start_date: format(new Date(values.ini), 'yyyy-MM-dd'),
        end_date: format(new Date(values.end), 'yyyy-MM-dd'),
        user_profile_id: currentProfile?.profileId!,
      });
    },
  });

  const dataFetch = useMemo(
    () =>
      data?.slice((page - 1) * perPage, (page - 1) * perPage + perPage)?.map((item, index) => ({
        code: index + (page - 1) * perPage + 1,
        title: {
          value: item.titulo,
          author: item.autor,
        },
        edition: item.edicion,
        editorial: item.editorial,
        borrows: item.prestamos,
      })),
    [data, page, perPage]
  );

  return (
    <Grid container>
      <Grid item container spacing={2} direction="row-reverse">
        <Grid item xs={12} md={8} lg={8}>
          <InputLabel>Fechas</InputLabel>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
            <DateRangePicker
              value={[values.ini, values.end]}
              onChange={(val) => {
                if (val) {
                  setFieldValue('ini', val[0]);
                  setFieldValue('end', val[1]);
                }
              }}
              renderInput={(startProps, endProps) => (
                <>
                  <TextField
                    {...startProps}
                    label={null}
                    fullWidth
                    placeholder="Fecha"
                    error={Boolean(errors.ini && touched.ini)}
                    helperText={Boolean(errors.ini && touched.ini) && errors.ini}
                  />
                  <Box sx={{ mx: 2 }}> - </Box>
                  <TextField
                    {...endProps}
                    label={null}
                    fullWidth
                    placeholder="Fecha"
                    error={Boolean(errors.end && touched.end)}
                    helperText={Boolean(errors.end && touched.end) && errors.end}
                  />
                </>
              )}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={12} md={12} lg={12} textAlign="right">
          <Button
            variant="contained"
            size="large"
            color="primary"
            sx={{ mt: 2 }}
            onClick={() => handleSubmit()}
          >
            Buscar
          </Button>
        </Grid>
      </Grid>
      <br />
      <br />
      {isUninitialized ? (
        <Grid item xs={12}>
          <MetricsMessage />
        </Grid>
      ) : (
        <Grid item xs={12}>
          <SearchPaginatedTable
            data={dataFetch || []}
            page={page}
            setPage={setPage}
            perPage={perPage}
            setPerPage={setPerPage}
            search={search}
            setSearch={setSearch}
            total={data?.length || 0}
            headers={{
              code: 'N°',
              title: 'Titulo / Autor',
              edition: 'Edición',
              editorial: 'Editorial',
              borrows: 'Prestamos',
            }}
            error={undefined}
            isLoading={isLoading}
            isFetching={isLoading}
            keyExtractor={(item) => item.code.toString()}
            numHeader={8}
            searchPlacehoder={''}
            customRenderers={{
              title: (row) => (
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="h6">{row.title.value}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2">{row.title.author}</Typography>
                  </Grid>
                </Grid>
              ),
            }}
            customDataCellsProperties={{
              code: { align: 'center' },
              edition: { align: 'center' },
              editorial: { align: 'center' },
              borrows: { align: 'center' },
            }}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default MostViewed;
