import { IconBrandTelegram } from '@tabler/icons';

import SearchPaginatedTable from '../../../../shared/components/tables/SearchPaginatedTable';
import EditButton from '../../../../shared/components/tables/buttons/EditButton';
import { updateSendPaysTable } from '../../slices/contificoMigrationsSlice';
import { useTableSearch } from '../../../../../hooks/useTableSearch';
import { useAppSelector } from '../../../../../hooks/useRedux';
import { likeAfterRegexMatch, returnDummyData } from '../../constants/helpers';
// import MigrationsDialog from '../MigrationsDialog';
import { useControlModalStates } from '../../../../shared/hooks/useControlModalStates';

const data = returnDummyData();
// const dialogData = returnDummyData2();

const SendPays = () => {
  const { handleOpenModalWith } = useControlModalStates();

  const { page, perPage, search } = useAppSelector(
    (state) => state.contificoMigrations.sendPaysTable
  );

  const { setPage, setPerPage, setSearch } = useTableSearch({
    page,
    perPage,
    search,
    updateFunction: updateSendPaysTable,
  });

  const handler = (id: number) => {
    handleOpenModalWith(id);
  };

  return (
    <>
      <SearchPaginatedTable
        data={data
          .filter((item) => item.endMonth.toLowerCase().match(likeAfterRegexMatch(search)))
          .slice((page - 1) * perPage, page * perPage)}
        error={undefined}
        headers={{
          month: 'Mes',
          endMonth: 'Mes final',
          debts: 'Deudas',
          amount: 'Monto',
          options: 'Opciones',
        }}
        isFetching={false}
        isLoading={false}
        page={page}
        perPage={perPage}
        keyExtractor={(item) => item.month.toString()}
        search={search}
        searchPlacehoder="Buscar por mes"
        setPage={setPage}
        setPerPage={setPerPage}
        setSearch={setSearch}
        total={data.length}
        customRenderers={{
          options: (item) => (
            <>
              <EditButton
                Icon={IconBrandTelegram}
                title="Migrar"
                handleEdit={() => handler(item.month)}
              />
            </>
          ),
        }}
        customDataCellsProperties={{
          month: {
            align: 'center',
          },
          options: {
            align: 'center',
          },
        }}
      />

      {/* <MigrationsDialog open={open} handleCloseDialog={handleCloseModal} /> */}
    </>
  );
};

export default SendPays;
