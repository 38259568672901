import { forwardRef } from 'react';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Slide,
  SlideProps,
  Typography,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';

import { useTranslation } from 'react-i18next';

// third-party
// import clsx from 'clsx';

import PerfectScrollbar from 'react-perfect-scrollbar';
import { IconArrowNarrowLeft } from '@tabler/icons';
import EditTempBillingInfo from './EditTempBillingInfo';
import ExtendedSideDialog from '../../../../shared/components/dialogs/ExtendedSideDialog';

// style constant
const useStyles = makeStyles((theme) => ({
  userAddDialog: {
    '&>div:nth-child(3)': {
      justifyContent: 'flex-end',
      '&>div': {
        margin: '0px',
        borderRadius: '0px',
        maxWidth: '650px',
        maxHeight: '100%',
        '&>div.MuiDialogContent-root>div.scrollbar-container': {
          paddingLeft: '20px',
          overflow: 'hidden',
        },
      },
    },
  },
  paper: {
    minHeight: '100vh',
    width: '37%',
  },
  paperTable: {
    minHeight: '100vh',
    width: '50%!important',
  },
  movilPaper: {
    minHeight: '100vh',
    width: '100%',
  },
}));

// animation
const Transition = forwardRef(function Transition(props: SlideProps, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export interface Props {
  open: boolean;
  //   contentStatus: boolean;
  // BillingData: { data: BillingData | undefined; isFetching: boolean };
  handleCloseDialog: (e: React.SyntheticEvent) => void;
}

const TempBillingSideDialog = ({ open, handleCloseDialog }: Props) => {
  const classes = useStyles();
  const theme = useTheme();

  const { t } = useTranslation();

  const isSm = useMediaQuery(theme.breakpoints.down('md'));

  //   const { hasAccess } = useGuard();

  return (
    <ExtendedSideDialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCloseDialog}
      className={classes.userAddDialog}
      {...(!isSm && { classes: { paper: classes.paper } })}
      {...(isSm && { classes: { paper: classes.movilPaper } })}
      // {...classes={
      //   courseSideModalMode !== 'view'
      //     ? {
      //         paper: classes.paper,
      //       }
      //     : {
      //         paper: classes.paperTable,
      //       }
      // }}
    >
      <DialogTitle disableTypography>
        <Grid item container alignItems="center" gap={1}>
          <IconButton
            onClick={(e) => {
              handleCloseDialog(e);
            }}
          >
            <IconArrowNarrowLeft size={30} color={theme.palette.text.dark} />
          </IconButton>
          <Typography variant="h3">
            {t('financial.debts_payment.temp_billing_dialog.title')}
          </Typography>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <PerfectScrollbar component={'div'}>
          <EditTempBillingInfo handleCloseDialog={handleCloseDialog} />
        </PerfectScrollbar>
      </DialogContent>
      <DialogActions>
        {/* <AnimateButton>
          <Button variant="contained" color="primary">
            Create
          </Button>
        </AnimateButton>
        <Button variant="text" onClick={handleCloseDialog} color="primary">
          Close
        </Button> */}
      </DialogActions>
    </ExtendedSideDialog>
  );
};

export default TempBillingSideDialog;
