import DeleteAlert from '../../../shared/components/dialogs/DeleteAlert';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { useDeleteDepartmentMutation } from '../slices/departmentsApiSlice';
import { resetDepartmentIdToDelete, setDeleteModalOpen } from '../slices/departmentsSlice';

const DeleteDepartmentDialog = () => {
  const dispatch = useAppDispatch();

  const { deleteModalOpen, departmentIdToDelete } = useAppSelector((state) => state.departments);
  const { currentProfile } = useAppSelector((state) => state.access);

  const [deleteDepartment, { isLoading, isSuccess, isError, error }] =
    useDeleteDepartmentMutation();

  const handleDelete = () => {
    deleteDepartment({
      profileId: currentProfile?.profileId!,
      departmentId: departmentIdToDelete!,
    });
  };

  const handleClose = () => {
    dispatch(setDeleteModalOpen(false));
    dispatch(resetDepartmentIdToDelete());
  };

  return (
    <DeleteAlert
      open={deleteModalOpen}
      onClose={handleClose}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      handleDelete={handleDelete}
      error={error as string}
    />
  );
};

export default DeleteDepartmentDialog;
