import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import BinnacleForm from './forms/BinnacleForm';

const Binnacle = () => {
  const { t } = useTranslation();
  return (
    <Grid container justifyContent="center" alignItems="center" mt={4}>
      <Grid item container justifyContent="center" alignItems="center">
        <Typography fontWeight={2} variant="h3" color="initial">
          {t('Datos para la bitácora')}
        </Typography>
      </Grid>
      <Grid item container justifyContent="center" alignItems="center" mt={4}>
        <BinnacleForm />
      </Grid>
    </Grid>
  );
};

export default Binnacle;
