import React from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, IconButton, Typography, useTheme } from '@material-ui/core';
import { IconArrowNarrowLeft } from '@tabler/icons';
import UserHistoryAnnouncements from '../components/UserHistoryAnnouncements';
import MainCard from '../../../../shared/components/cards/MainCard';
import { useTranslation } from 'react-i18next';

const UserHistoryAnnouncementsScreen = () => {
  const { t } = useTranslation();

  const theme = useTheme();

  const history = useHistory();

  return (
    <React.Fragment>
      <Grid component={MainCard}>
        <Grid item xs={12}>
          <Grid item container alignItems="center" gap={1} sx={{ cursor: 'pointer' }}>
            <IconButton onClick={() => history.goBack()}>
              <IconArrowNarrowLeft size={30} color={theme.palette.text.dark} />
            </IconButton>
            <Typography variant="h2">
              {t('teachers.academic_counseling.user_history.title')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid component={MainCard} style={{ marginTop: 20 }}>
        <UserHistoryAnnouncements />
      </Grid>
    </React.Fragment>
  );
};

export default UserHistoryAnnouncementsScreen;
