import { useEffect, useState } from 'react';
import { getProtocol } from '../../../helpers/urlHelpers';

const Logo = () => {
  const [logo, setLogo] = useState('');

  useEffect(() => {
    const apiUrl = localStorage.getItem('apiUrl');
    const logoPath = localStorage.getItem('logoPath');
    setLogo(`${getProtocol()}://${apiUrl}/api/show?name=${logoPath}`);
  }, []);

  return (
    <img
      src={logo}
      onError={(e) => {
        if (logo !== '') {
          localStorage.removeItem('logoPath');
        }
      }}
      alt="Links S.A"
      width="100%"
      height="auto"
      style={{ maxHeight: '60px' }}
    />
  );
};

export default Logo;
