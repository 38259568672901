import DeleteAlert from '../../../../shared/components/dialogs/DeleteAlert';

import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import { useDeleteProjectMutation } from '../../slices/InvestigationLinesApiSlice';
import {
  setDeleteModalOpenProject,
  resetProjectIdToDelete,
} from '../../slices/investigationLinesSlice';

const DeleteProjectDialog = () => {
  const dispatch = useAppDispatch();

  const { deleteModalOpenProject, projectIdToDelete } = useAppSelector(
    (state) => state.investigationLines
  );

  const [deleteProject, { isLoading, isSuccess, isError, error }] = useDeleteProjectMutation();

  const handleDelete = () => {
    deleteProject({
      projectId: projectIdToDelete!,
    });
  };

  const handleClose = () => {
    dispatch(setDeleteModalOpenProject(false));
    dispatch(resetProjectIdToDelete());
  };

  return (
    <DeleteAlert
      open={deleteModalOpenProject}
      onClose={handleClose}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      handleDelete={handleDelete}
      error={error as string}
    />
  );
};

export default DeleteProjectDialog;
