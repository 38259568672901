import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/es';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useState } from 'react';
import useBirthdayOptions from '../../hooks/useBirthdayOptions';
import { BirthdayEvent } from '../../interfaces/birthday.interfaces';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

const BirthdayCalendar = () => {
  const { t } = useTranslation();
  const localizer = momentLocalizer(moment);
  const [currentDate, setCurrentDate] = useState<Date>(new Date());
  const { collaboratorBirthdays, isLoadingCB, isFetchingCB } = useBirthdayOptions({
    month: Number(currentDate.getMonth()) + 1,
  });
  const birthdayFormat = (collaboratorBirthdays: BirthdayEvent[]) => {
    return collaboratorBirthdays.map((cb) => {
      const day = moment(cb.pers_date_birth).format('DD');
      const month = moment(cb.pers_date_birth).format('MM');
      const year = moment(currentDate).format('YYYY');
      const date = year + month + day + ' 0:00';
      const birthday = moment(date, 'YYYYMMDD h:mm').toDate();
      const start = birthday;
      const end = birthday;
      const primerNombre = cb.pers_firstname ? cb.pers_firstname : '';
      const segundoNombre = cb.pers_secondname ? ' ' + cb.pers_secondname : '';
      const primerApellido = cb.pers_first_lastname ? ' ' + cb.pers_first_lastname : '';
      const SegundoApellido = cb.pers_second_lastname ? ' ' + cb.pers_second_lastname : '';
      const nombreCompleto = primerNombre + segundoNombre + primerApellido + SegundoApellido;
      const title = nombreCompleto;
      return { start, end, title };
    });
  };
  return (
    <div>
      <Calendar
        culture={i18n.language}
        localizer={localizer}
        onNavigate={(date) => setCurrentDate(date)}
        events={
          !isLoadingCB && Array.isArray(collaboratorBirthdays) && !isFetchingCB
            ? birthdayFormat(collaboratorBirthdays)
            : []
        }
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500 }}
        messages={{
          next: t('birthdays.next'),
          previous: t('birthdays.prev'),
          today: t('birthdays.today'),
          month: t('birthdays.month'),
          week: t('birthdays.week'),
          day: t('birthdays.day'),
          date: t('birthdays.date'),
          time: t('birthdays.time'),
          event: t('birthdays.event'),
          noEventsInRange: t('birthdays.no_data'),
        }}
      />
    </div>
  );
};
export default BirthdayCalendar;
