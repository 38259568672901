import { mainApi } from '../../../../api/mainApi';
import { middlewareApi } from '../../../middleware/middlewareApi';

import { AccessState, AuthState } from '../../../../types';
import { SubjectCurriculumStatus } from '../interfaces/subjects-approveds';
import { CourseStudentPayload } from '../interfaces/courseStudent.interfaces';
import { CourseStudentStatus } from '../interfaces/courseStudentStatus';
import { CourseAvailable } from '../interfaces/coursesAvailables.interfaces';
import { CourseHourhand, CourseHourhandPayload } from '../interfaces/coursesByHourhand.interface';
import { ValidatePaymentResponse } from '../interfaces/validatePayment.interface';
import { SubjectsByPeriod } from '../../academic-portfolio/interfaces/subjectsByPeriods';
import { CourseOnlinePayload } from '../interfaces/courseOnline.interfaces';
import { PreviewFees } from '../interfaces/previewFees.interface';
import { Debtors } from '../interfaces/debtors.interfaces';
import { Parallel } from '../../../maintenance/courses/interfaces/course.interface';

export const subjectsRegisterTags = middlewareApi.enhanceEndpoints({
  addTagTypes: [
    'CoursesAvaliables',
    'CourseStudentStatus',
    'CoursesHourhand',
    'StudentDebts',
    'StudentDebtsQuota',
  ],
});

export const subjectsRegisterApi = subjectsRegisterTags.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * @GET el estado de los materias en su malla actual
     */
    getSubjectsCurriculumStatus: builder.query<
      SubjectCurriculumStatus[],
      {
        studentRecordId: number;
        assign_grades?: boolean;
        period_id?: number;
        status_subject?: string;
      }
    >({
      queryFn: async (
        { studentRecordId, assign_grades, period_id, status_subject },
        { getState }
      ) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = currentProfile?.profileId;

          const { data } = await mainApi.get<SubjectCurriculumStatus[]>(
            `/subjects-approveds/student-record/${studentRecordId}`,
            {
              params: {
                user_profile_id,
                assign_grades,
                period_id,
                ...(status_subject && { status_subject }),
              },
            }
          );

          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ matter_id }) => ({ type: 'StatusSubjects', matter_id } as const)),
              { type: 'StatusSubjects', id: 'LIST' },
            ]
          : [{ type: 'StatusSubjects', id: 'LIST' }],
    }),

    /**
     * @GET los cursos disponibles según la materia, campus y jornada (opcional)
     */
    getAvailablesCourses: builder.query<
      CourseAvailable[],
      {
        subjectId: number | null;
        campusId?: number | null;
        jornadaId?: number | null;
        parallelId?: number | null;
        student_record_id: number;
        type_offer?: string;
        period_id?: number;
      }
    >({
      queryFn: async (
        { subjectId, campusId, jornadaId, student_record_id, period_id, type_offer, parallelId },
        { getState }
      ) => {
        // if (!subjectId || !campusId) {
        //   return { error: 'subjectId and campusId are required' };
        // }

        const {
          access: { currentProfile },
        } = getState() as { access: AccessState };

        const user_profile_id = currentProfile?.profileId;

        try {
          const { data } = await mainApi.get<CourseAvailable[]>(`/courses/${subjectId}/subjects`, {
            params: {
              user_profile_id,
              campus_id: campusId,
              student_record_id,
              type_offer,
              period_id,
              ...(jornadaId && { workday_id: jornadaId }), // opcional
              ...(parallelId && { parallel_id: parallelId }), // opcional
            },
          });

          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'CoursesAvaliables', id } as const)),
              { type: 'CoursesAvaliables', id: 'LIST' },
            ]
          : [{ type: 'CoursesAvaliables', id: 'LIST' }],
    }),

    /**
     * @GET obtiene las asignaturas por status
     */
    getCourseAprovalStatus: builder.query<
      CourseStudentStatus[],
      { studentRecordId: number; period_id: number; approval_status: string }
    >({
      queryFn: async ({ studentRecordId, period_id, approval_status }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = currentProfile?.profileId;

          const { data } = await mainApi.get<CourseStudentStatus[]>(
            `/course-students/${studentRecordId}/approval-status`,
            {
              params: {
                user_profile_id,
                period_id,
                approval_status,
              },
            }
          );

          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'CourseStudentStatus', id } as const)),
              { type: 'CourseStudentStatus', id: 'LIST' },
            ]
          : [{ type: 'CourseStudentStatus', id: 'LIST' }],
    }),

    /**
     * @GET obtiene cursos por hararios
     */
    getCoursesByHourhand: builder.query<CourseHourhand[], CourseHourhandPayload>({
      queryFn: async (CourseHourhandPayload, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState; auth: AuthState };

          const user_profile_id = currentProfile?.profileId;

          const { data } = await mainApi.get<CourseHourhand[]>(
            `/courses/${CourseHourhandPayload.studentRecordId}/hourhands`,
            {
              params: {
                user_profile_id,
                ...CourseHourhandPayload,
              },
            }
          );

          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'CoursesHourhand', id } as const)),
              { type: 'CoursesHourhand', id: 'LIST' },
            ]
          : [{ type: 'CoursesHourhand', id: 'LIST' }],
    }),

    /**
     * @GET pdf register subject
     */
    getRegisterSubjectPDF: builder.query<any, { studentId: string; periodId: string }>({
      queryFn: async ({ periodId, studentId }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const { data } = await mainApi.get<any>(
            `/course-students/${studentId}/periods/${periodId}/subjects-report`,
            {
              responseType: 'arraybuffer',
              headers: {
                Accept: 'application/pdf',
                'Content-Type': 'application/json',
              },
              params: {
                user_profile_id: currentProfile?.profileId,
              },
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    /**
     * @GET obtiene validacion de deuda por cuotas
     */
    getValidatePaymentQuota: builder.query<
      ValidatePaymentResponse,
      { period_id: number; student_record_id: number }
    >({
      queryFn: async ({ period_id, student_record_id }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = currentProfile?.profileId;
          const { data } = await mainApi.get<ValidatePaymentResponse>(
            `/students/student-record-fees/periods/validate/payment/quotas`,
            {
              params: {
                user_profile_id,
                student_record_id,
                period_id,
              },
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: [{ type: 'StudentDebtsQuota', id: 'DEBTS' }],
    }),

    /**
     * @GET obtiene validacion de deuda en el periodo
     */
    getValidateDebts: builder.query<
      ValidatePaymentResponse,
      {
        period_id: number;
        user_profile_id: number;
        student_record_id: number;
        person_id: number;
        can_extend_period: string;
      }
    >({
      queryFn: async ({
        period_id,
        user_profile_id,
        student_record_id,
        person_id,
        can_extend_period,
      }) => {
        try {
          const { data } = await mainApi.get<ValidatePaymentResponse>(`/students/validate/debts`, {
            params: {
              user_profile_id,
              student_record_id,
              period_id,
              person_id,
              can_extend_period,
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: [{ type: 'StudentDebts', id: 'DEBTS' }],
    }),

    /**
     * @GET obtiene lista de review de cuotas
     */
    getPreviewFees: builder.query<
      PreviewFees[],
      { student_record: number; period_id: number; student_id: number }
    >({
      queryFn: async ({ student_record, period_id, student_id }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = currentProfile?.profileId;

          const { data } = await mainApi.get<PreviewFees[]>(
            `/students/${student_id}/preview-fees`,
            {
              params: {
                user_profile_id,
                type_offer: 'grade',
                student_record,
                period_id,
              },
            }
          );

          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: [{ type: 'StudentDebts', id: 'DEBTS' }],
    }),

    /**
     * @GET obtiene las asignaturas por status
     */
    getCourseStudentById: builder.query<SubjectsByPeriod, { courseStudentId: number }>({
      queryFn: async ({ courseStudentId }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = currentProfile?.profileId;

          const { data } = await mainApi.get<SubjectsByPeriod>(
            `/course-students/${courseStudentId}`,
            {
              params: {
                user_profile_id,
              },
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    /**
     * @POST create course student online
     */
    addCourseStudentOnline: builder.mutation<
      Partial<CourseOnlinePayload>,
      { courseOnlinePayload: Partial<CourseOnlinePayload> }
    >({
      queryFn: async ({ courseOnlinePayload }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const { data } = await mainApi.post<Partial<CourseOnlinePayload>>(
            '/course-students/online',
            {
              user_profile_id: currentProfile?.profileId,
              ...courseOnlinePayload,
            }
          );

          if (!data.success) throw data.errors;

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'StatusSubjects', id: 'LIST' }],
    }),

    /**
     * @POST create course student
     */
    addCourseStudent: builder.mutation<
      CourseStudentPayload,
      { profileId: number; courseStudentPayload: Partial<CourseStudentPayload> }
    >({
      queryFn: async ({ profileId, courseStudentPayload }) => {
        try {
          const { data } = await mainApi.post<CourseStudentPayload>('/course-students', {
            user_profile_id: profileId,
            ...courseStudentPayload,
          });

          if (!data.success) throw data.errors;

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [
        { type: 'CourseStudentStatus', id: 'LIST' },
        { type: 'StatusSubjects', id: 'LIST' },
      ],
    }),

    /**
     * @DELETE eliminar un registro de un estudiante en curso
     */
    deleteCourseStudent: builder.mutation<CourseStudentStatus, { courseStudentId: number }>({
      queryFn: async ({ courseStudentId }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = currentProfile?.profileId;

          const { data } = await mainApi.delete(`/course-students/${courseStudentId}`, {
            data: {
              user_profile_id,
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [
        { type: 'StatusSubjects', id: 'LIST' },
        { type: 'CourseStudentStatus', id: 'LIST' },
      ],
    }),

    /**
     * @POST genera deuda en el periodo que se registra el estudiante
     */
    generateDebts: builder.mutation<
      CourseStudentStatus,
      {
        user_profile_id: number;
        period_id: number;
        student_record_id: number;
        person_id: number;
        id_debtor?: number;
      }
    >({
      queryFn: async (
        { user_profile_id, period_id, student_record_id, person_id, id_debtor },
        { dispatch }
      ) => {
        try {
          const { data } = await mainApi.post(`/students/student-record/periods`, {
            user_profile_id,
            student_record_id,
            period_id,
            person_id,
            id_debtor,
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: (_, error) => (!error ? [{ type: 'StudentDebts', id: 'DEBTS' }] : []),
    }),

    /**
     * @GET obtiene los deudores de un estudiante
     * @Permission debtors-obtener-deudores-por-estudiante
     */
    getDebtorsStudent: builder.query<Debtors[], { studentId: number }>({
      queryFn: async ({ studentId }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = currentProfile?.profileId;

          const { data } = await mainApi.get<Debtors[]>(`/debtors/student/${studentId}`, {
            params: {
              user_profile_id,
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    getStudentPeriodInfoRegisterSubjects: builder.query<
      {
        num_course_student: number;
        num_max_period: number;
        num_min_period: number;
      },
      { studentId: number; periodId: number }
    >({
      queryFn: async ({ studentId, periodId }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = currentProfile?.profileId;

          const { data } = await mainApi.get<{
            num_max_period: number;
            num_course_student: number;
            num_min_period: number;
          }>(`/course-students/${studentId}/periods/${periodId}`, {
            params: {
              user_profile_id,
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: [{ type: 'StatusSubjects', id: 'LIST' }],
    }),

    getSubjectPeriodParallels: builder.query<
      Parallel[],
      { subjectId: number; periodId: number; profileId: number }
    >({
      queryFn: async ({ subjectId, periodId, profileId }, { getState }) => {
        try {
          const { data } = await mainApi.get<Parallel[]>(
            `/courses/subjects/${subjectId}/periods/${periodId}/parallels`,
            {
              params: {
                user_profile_id: profileId,
                profile_id: profileId,
              },
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    getIsRegisterCheckMatter: builder.query<
      {
        value_exists: boolean;
      },
      {
        profileId?: number;
        period_id: number;
        approval_status?: string;
        student_record_id: number;
        course_id: number;
      }
    >({
      queryFn: async (
        { profileId, period_id, approval_status = 'CU', course_id, student_record_id },
        { getState }
      ) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = profileId || currentProfile?.profileId;

          const { data } = await mainApi.get<{
            value_exists: boolean;
          }>(`/course-students/register-check`, {
            params: {
              user_profile_id,
              period_id,
              approval_status,
              course_id,
              student_record_id,
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),
  }),
});

export const {
  useGetSubjectsCurriculumStatusQuery,
  useLazyGetAvailablesCoursesQuery,
  useGetAvailablesCoursesQuery,
  useAddCourseStudentMutation,
  useGetCourseAprovalStatusQuery,
  useDeleteCourseStudentMutation,
  useGetCoursesByHourhandQuery,
  useGetValidatePaymentQuotaQuery,
  useGenerateDebtsMutation,
  useGetValidateDebtsQuery,
  useGetCourseStudentByIdQuery,
  useAddCourseStudentOnlineMutation,
  useGetPreviewFeesQuery,
  useGetDebtorsStudentQuery,
  useGetStudentPeriodInfoRegisterSubjectsQuery,
  useGetSubjectPeriodParallelsQuery,
  useLazyGetRegisterSubjectPDFQuery,
  useGetIsRegisterCheckMatterQuery,
} = subjectsRegisterApi;
