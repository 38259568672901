import { RegisterSyllabusData } from '../../../interfaces/registerSyllabusData';
import { IconWriting, IconBooks, IconListCheck } from '@tabler/icons';

export const initialValues: RegisterSyllabusData = {
  syllabus_entries: {
    id: null,
    description_justify: '',
    object_study: '',
    object: '',
    result_learning: '',
    contents: '',
    metho_mean_evaluation: '',
    lesson_plan: '',
  },
  syllabus: {
    id: null,
    sy_anio: '',
    period_id: '',
    status_id: '',
    education_level_id: '',
    matter_id: '',
    date_send: null,
    course_id: '',
  },
  syllabus_bibliogra_basic: [],
  syllabus_bibliogra_comple: [],
  syllabus_data_collaborator: {
    collaborator_id: null,
    college_education: '',
    email_collaborator: '',
    id: null,
    experience_area: '',
    phone_collaborator: '',
    postgrado: '',
    specialty_area: '',
  },
  temp: {
    fileLessonPlan: null,
  },
};

export const generalDetails = {
  label: 'Contenido General',
  Icon: IconWriting,
  url: 'contenido-general',
};

export const lessonPlan = {
  label: 'Plan de clases',
  Icon: IconBooks,
  url: 'plan-clases',
};
export const bibliography = {
  label: 'Bibliografía',
  Icon: IconListCheck,
  url: 'bibliografia',
};

export const basicBibliographyExample = [
  {
    book_id: 1,
    book_name: 'Título de la bibliografía',
    book_autor: 'Autor de la bibliografía',
    book_editor: 'Editorial de la bibliografía',
  },
  {
    book_id: 2,
    book_name: 'Título de la bibliografía 2',
    book_autor: 'Autor de la bibliografía 2',
    book_editor: 'Editorial de la bibliografía 2',
  },
];
