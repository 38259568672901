// material-ui
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';

// project imports

// helpers
import { isPrimitive, objectKeys } from '../../../shared/helpers';
import { renderErrorOrEmptyRow, renderSkeletonRows } from '../../../shared/helpers/render';

// types
import {
  TableHeaders,
  CustomRenderers,
  CustomCellProperties,
} from '../../../shared/interfaces/material-ui.interfaces';

import { useTranslation } from 'react-i18next';

interface Props<T extends {}> {
  headers: TableHeaders<T>;
  data: T[];
  keyExtractor: (item: T) => string;
  customRenderers?: CustomRenderers<T>;
  isFetching: boolean;
  error: FetchBaseQueryError | SerializedError | undefined;
  customDataCellsProperties?: CustomCellProperties<T>;
  customHeadersCellsProperties?: CustomCellProperties<T>;
  sx?: {};
}

const SimpleTable = <T extends {}>({
  data,
  headers,
  keyExtractor,
  customRenderers,
  isFetching,
  error,
  customDataCellsProperties,
  customHeadersCellsProperties,
  sx,
}: Props<T>) => {
  const { t } = useTranslation();

  const renderRow = (row: T) => {
    return (
      <TableRow key={keyExtractor(row)}>
        {objectKeys(row).map((cell) => {
          const customRenderer = customRenderers?.[cell];

          if (customRenderer) {
            return (
              <TableCell
                key={`${keyExtractor(row)}-${String(cell)}`}
                {...customDataCellsProperties?.[cell]}
              >
                {customRenderer(row)}
              </TableCell>
            );
          }

          return (
            <TableCell
              key={`${keyExtractor(row)}-${String(cell)}`}
              {...customDataCellsProperties?.[cell]}
            >
              {isPrimitive(row[cell]) ? row[cell] : ''}
            </TableCell>
          );
        })}
      </TableRow>
    );
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {Object.keys(headers).map((headerValue) => (
              <TableCell
                sx={sx}
                key={headerValue}
                {...customHeadersCellsProperties?.[headerValue as keyof T]}
              >
                {headers[headerValue as keyof T]}
                {/* {headerValue} */}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {isFetching ? renderSkeletonRows(7, Object.values(headers).length) : data.map(renderRow)}

          {error && renderErrorOrEmptyRow(Object.values(headers).length, error as string)}
          {data.length === 0 &&
            !isFetching &&
            renderErrorOrEmptyRow(
              Object.values(headers).length,
              t('teacher.assign_grades.simple_table.no_data')
            )}
          {/* {data.map(renderRow)} */}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SimpleTable;
