import { Grid, Paper } from '@material-ui/core';
import { format } from 'date-fns';
import { useEffect } from 'react';
import { useAppSelector } from '../../../../hooks/useRedux';
import BooksTabs from '../components/BooksTabs.tsx';
import CategoriesList from '../components/Categories/CategoriesList';
import DatabasesList from '../components/Databases/DatabasesList';
import LibraryInfo from '../components/LibraryInfo';
// import LibraryInfoV2 from '../components/LibraryInfoV2';
import ReserveConfirmationDialog from '../components/ReserveConfirmationDialog';
import Search from '../components/Search/';
import { useRegisterVisitStudentMutation } from '../slices/libraryDashboardApiSlice';

const LibrayDashboardScreen = () => {
  const { showBooksSearch, bookIdToReserve, reserveBookModalOpen } = useAppSelector(
    (state) => state.libraryDashboard
  );

  const { userInfo, studentRecordReference } = useAppSelector((state) => state.auth);

  const [registerVisit] = useRegisterVisitStudentMutation();

  const handleVisit = () => {
    registerVisit({
      date: format(new Date(), 'yyyy-MM-dd'),
      educationLevelId: studentRecordReference?.[0]?.education_level_id
        ? Number(studentRecordReference?.[0]?.education_level_id)
        : 0,
      personId: Number(userInfo?.personId!),
      studentRecordId: userInfo?.studentInfo?.studentRecordId
        ? Number(userInfo?.studentInfo?.studentRecordId)
        : 0,
      type: 'library',
    });
  };

  useEffect(
    () => {
      handleVisit();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <Grid
      component={Paper}
      sx={{
        padding: 0,
        overflow: 'hidden',
      }}
      container
      justifyContent="center"
    >
      <Grid item xs={12}>
        <Search />
      </Grid>
      {!showBooksSearch && (
        <>
          <CategoriesList />

          <Grid item xs={12}>
            <BooksTabs />
          </Grid>
          <Grid item xs={12}>
            <LibraryInfo />
            {/* <LibraryInfoV2 /> */}
          </Grid>
          <Grid item xs={12}>
            <DatabasesList />
          </Grid>
        </>
      )}
      {reserveBookModalOpen && bookIdToReserve !== null && <ReserveConfirmationDialog />}
    </Grid>
  );
};

export default LibrayDashboardScreen;
