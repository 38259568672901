import { useState } from 'react';

import { Alert, Box, Button, Grid, useTheme, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import { useTranslation } from 'react-i18next';

import ClientsSideDialog from '../ClientsSideDialog';

import { useAppSelector } from '../../../../../hooks/useRedux';
import SelectStudentRecord from './SelectStudentRecord';
import ClientLabels from './ClientLabels';

const SelectClient = () => {
  const theme = useTheme();

  const { t } = useTranslation();

  const { currentClient } = useAppSelector((state) => state.generateCreditNote);

  const [open, setOpen] = useState(false);

  const handleCloseDialog = () => {
    setOpen(false);
  };
  const handleOpenClientSideDialog = () => {
    setOpen(true);
  };

  return (
    <>
      {/* <Grid container> */}
      <Box
        sx={{ width: '100%' }}
        border={`1px solid ${theme.palette.primary.main}`}
        borderRadius={'12px'}
        p={2}
      >
        <Grid container justifyContent={'space-between'} alignItems={'center'} mb={1}>
          {/* revisar si es necesario el studentRecord  */}
          <Grid item xs={6} md={6}>
            {!!currentClient?.user.student?.student_records &&
            currentClient?.user.student?.student_records.length > 1 ? (
              <SelectStudentRecord />
            ) : (
              !!currentClient?.user.student?.student_records.length && (
                <TextField
                  fullWidth
                  label={t(
                    'financial.generate_creditnote.select_client.select_student_record.label'
                  )}
                  disabled
                  variant="standard"
                  value={currentClient?.user.student?.student_records[0].education_level.edu_name}
                />
              )
            )}
          </Grid>
          <Grid item xs="auto">
            <Button
              startIcon={<SearchIcon fontSize="small" />}
              variant="outlined"
              onClick={handleOpenClientSideDialog}
            >
              buscar
            </Button>
          </Grid>

          {currentClient ? (
            <ClientLabels currentClient={currentClient} />
          ) : (
            <Grid container justifyContent={'center'} p={1}>
              <Grid item md={8}>
                <Alert severity="info">
                  {t('financial.generate_creditnote.select_client.select_client_alert')}
                </Alert>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Box>
      {/* </Grid> */}

      <ClientsSideDialog open={open} handleCloseDialog={handleCloseDialog} />
    </>
  );
};

export default SelectClient;
