import { Grid, IconButton, Tooltip } from '@material-ui/core';
import InputLabel from './InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { PlusCircle } from 'phosphor-react';

export const useStyles = makeStyles((theme) => ({
   inputLabel: {
      marginBlockEnd: 6,
      fontWeight: 500,
      color: theme.palette.text.primary,
   },
   icon: {
      color: theme.palette.primary.main,
      fontSize: '24px',
   },
}));

const InputSecondaryLabelAdd = (props: {
   label: string;
   title: string;
   link?: string;
   hasAccess?: boolean;
}) => {
   const history = useHistory();
   const classes = useStyles();
   const { label, title, link, hasAccess } = props;

   return (
      <Grid container justifyContent="space-between" alignItems="end" direction="row">
         <InputLabel>{label}</InputLabel>
         {hasAccess && (
            <Tooltip title={title}>
               <IconButton
                  onClick={() => (link ? history.push(link) : null)}>
                  <PlusCircle className={classes.icon} />
               </IconButton>
            </Tooltip>
         )}
      </Grid>
   );
};

export default InputSecondaryLabelAdd;
