const scopeAvailables = [
  { label: 'Nacional', keyword: 'NACIONAL' },
  { label: 'Provincial', keyword: 'PROVINCIAL' },
  { label: 'Cantonal', keyword: 'CANTONAL' },
  { label: 'Parroquial', keyword: 'PARROQUIAL' },
  { label: 'Institucional', keyword: 'INSTITUCIONAL' },
  { label: 'Internacional', keyword: 'INTERNACIONAL' },
];

export default scopeAvailables;
