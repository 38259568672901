import { Avatar, CircularProgress, Grid, Typography } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import { obtainDomainStorageImage } from '../../../../shared/helpers';
import { IconMail } from '@tabler/icons';
import { CourseStudents } from '../../../courses/interfaces/courseStudents.interfaces';
import { useGetRedlinksAvatarQueryQuery } from '../../../../shared/slices/redlinksApi';
import { useEffect, useState } from 'react';
import defaultPhoto from '../../../../../assets/images/users/noloadedavatar.png';

const useStyles = makeStyles((theme) => ({
  boxCard: {
    boxShadow: '0px 4px 20px 15px rgba(234, 240, 246, 0.5);',
    borderRadius: 12,
    padding: '20px',
  },
  satckContainer: {
    backgroundColor: theme.palette.grey[50],
    padding: '10px',
    borderRadius: '10px',
  },
  stackTitle: {
    color: theme.grey500,
  },
  stackSubtitle: {
    fontWeight: 600,
    color: theme.palette.grey[800],
  },
  title: {
    fontWeight: 700,
    color: theme.palette.primary.main,
  },
  divider: { borderColor: theme.palette.grey[300] },
}));

interface Props {
  student: CourseStudents;
}

const CardStudent = ({ student }: Props) => {
  const classes = useStyles();

  const { data, isLoading } = useGetRedlinksAvatarQueryQuery({
    identification: student?.us_username,
  });

  const [photo, setPhoto] = useState('');

  useEffect(() => {
    if (data?.result?.foto) {
      setPhoto(data?.result?.foto);
    } else {
      setPhoto(obtainDomainStorageImage(`${student?.stud_photo_path!}`));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Grid container item xs={12} sm={6} md={4} lg={3}>
      <Grid
        container
        item
        className={classes.boxCard}
        alignItems="center"
        direction="column"
        spacing={1}
      >
        <Grid item>
          {isLoading ? (
            <Avatar>
              <CircularProgress />
            </Avatar>
          ) : (
            <Avatar
              sx={{
                width: '72px',
                height: '72px',
                backgroundColor: '#ccc',
              }}
            >
              <img
                src={photo}
                onError={(e) => setPhoto(defaultPhoto)}
                width="100%"
                height="100%"
                alt="avatar"
              />
            </Avatar>
          )}
        </Grid>
        <Grid item>
          <Typography variant="h4" align="center">{`${
            student?.pers_full_lastname || student?.pers_full_name
          } `}</Typography>
        </Grid>
        <Grid item container justifyContent="center" alignItems="center" gap={1}>
          <Typography variant="subtitle2">{student?.faculty_name}</Typography>
        </Grid>
        <Grid item container justifyContent="center" alignItems="center" gap={1}>
          <Typography variant="subtitle2">{`${student?.career_name} - ${student?.curriculum_name}`}</Typography>
        </Grid>
        <Grid item container justifyContent="center" alignItems="center" gap={1}>
          <IconMail color={'#008DE1'} />
          <Typography
            variant="subtitle2"
            sx={{
              wordBreak: 'break-all',
            }}
          >
            {student?.email}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CardStudent;
