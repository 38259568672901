import { Grid, IconButton, Typography, useTheme } from '@material-ui/core';
import MainCard from '../../../../shared/components/cards/MainCard';
import { useHistory /*, useParams*/ } from 'react-router-dom';
import { IconArrowNarrowLeft } from '@tabler/icons';
import AddMaterials from '../../materials/AddMaterials';

const AddContentScreen = () => {
  //const { foroId } = useParams<{ foroId: string }>();
  const theme = useTheme();
  const history = useHistory();
  return (
    <Grid component={MainCard} style={{ marginTop: 10 }}>
      <Grid item xs={12}>
        <Grid item container alignItems="center" gap={1} sx={{ cursor: 'pointer' }}>
          <IconButton onClick={() => history.goBack()}>
            <IconArrowNarrowLeft size={30} color={theme.palette.text.dark} />
          </IconButton>
          <Typography variant="h2">Agregar</Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} md={8} lg={6} sx={{ mt: 2 }}>
        {/*Adding Materials*/}
        <AddMaterials />
      </Grid>
    </Grid>
  );
};
export default AddContentScreen;
