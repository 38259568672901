import { useCallback, useEffect, useState } from 'react';

import { Button, Grid, Stack, Typography } from '@material-ui/core';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IconFileText } from '@tabler/icons';

import { useControlModalStates } from '../../../shared/hooks/useControlModalStates';
import { droppableKeys, moveItems, removeItems, reorderItems, splitBusTours } from '../helpers';
import { BusRouteWIthCampus, BusTour, HandleEditTourI } from '../interfaces/buses.interfaces';

import TourList from './builder/TourList';
import {
  useGetRouteToursQuery,
  useUpdateBusToursGroupedMutation,
} from '../slices/busRouteApiSlice';
import BusNewTourDialog from './BusNewTourDialog';
import { updateItemFromIndexArr } from '../../../shared/helpers';
import ComponentFetch from '../../../shared/components/controlled/ComponentFetch';
import BottomFixedActions from '../../../shared/components/dialogs/BottomFixedActions';
import LoadingButton from '../../../shared/components/buttons/LoadingButton';
import AddButton from '../../../shared/components/tables/buttons/AddButton';
import { openPdf } from '../../../shared/helpers/files';

const getListHoriStyle = (isDraggingOver: any) => ({
  background: isDraggingOver ? 'lightblue' : 'transparent', // 'lightgrey',
  border: '4px dashed #d0d7de',
  // borderRadius: '12px',

  display: 'flex',
  flexWrap: 'wrap' as any,
  // gridTemplateColumns: 'repeat(auto-fill, 208px)',
  gap: '10px',
  padding: 8,
  // width: 250,
  minHeight: 100,
  // borderRadius: '8px',
  // border: '3px dashed black',
});

const getListStyle = (isDraggingOver: any) => ({
  background: isDraggingOver ? 'lightblue' : 'transparent', //'lightgrey',
  border: '4px dashed #d0d7de',
  // borderRadius: '12px',
  padding: 8,
  width: '100%',
  minHeight: 100,
});

interface Props {
  routeInfo: BusRouteWIthCampus;
}

const BusToursList = ({ routeInfo }: Props) => {
  const { t } = useTranslation();
  const { route } = useParams<{ route: string }>();

  const {
    open,
    multipleIds,
    handleOpenModalWithMultipleIds,
    handleOpenModalWith,
    handleCloseModal,
  } = useControlModalStates();

  const [submitTours, { isLoading: isLoadingSave }] = useUpdateBusToursGroupedMutation();

  /**
   * 0: array reserva
   * 1: array ida
   * 2: array retorno
   * 3: array eliminados (data original)
   */
  const [state, setState] = useState<BusTour[][]>([[], [], [], []]);
  // estado para determinar
  const [isEdited, setIsEdited] = useState(false);

  const multipleAction = () => {
    setIsEdited(true);
  };

  const [editData, setEditData] = useState({ droppableId: '', index: 0 });

  const { data, isSuccess, isError, error, isLoading, refetch } = useGetRouteToursQuery(
    {
      route: Number(route),
    },
    { skip: !route }
  );

  const resetTours = useCallback(() => {
    const tours = splitBusTours(data);
    setState((prev) => [
      [...prev[0]],
      [...(tours.ida || Array.from([]))],
      [...(tours.retorno || Array.from([]))],
      [],
    ]);

    setIsEdited(false);
  }, [data]);

  useEffect(() => {
    if (data) {
      resetTours();
    }
  }, [data, resetTours]);

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }
    const sInd = droppableKeys[source.droppableId as keyof typeof droppableKeys];
    const dInd = droppableKeys[destination.droppableId as keyof typeof droppableKeys];

    if (sInd === dInd) {
      const items = reorderItems(state[sInd], source.index, destination.index);

      const newState = [...state];
      newState[sInd] = items;
      setState(newState);
    } else {
      const result = moveItems(state[sInd], state[dInd], source, destination);

      const newState = [...state];
      newState[sInd] = result[sInd];
      newState[dInd] = result[dInd];

      setState(newState);
    }

    multipleAction();
  };

  /**
   * Abrira el modal en modo edicion (con los datos necesarios para editar el array)
   *
   * @param busTour BusTour
   * @param droppableId
   * @param index
   */
  const handleOpenEdit: HandleEditTourI = (busTour, droppableId, index) => {
    handleOpenModalWithMultipleIds({ busTour: busTour });
    setEditData({ droppableId, index });

    multipleAction();
  };

  /**
   * Creara un nuevo objeto en el array escogido
   *
   * @param busTour
   */
  const handleNewTour = (busTour: BusTour) => {
    const newState = [...state];
    newState[0] = [...state[0], busTour];

    setState(newState);

    multipleAction();
  };

  /**
   * Editara e; array escogido en base a la funcion para abrir el modal con estado edicion ```handleOpenEdit```
   *
   * @param busTour
   * @param droppableId
   * @param index
   */
  const handleEditTour: HandleEditTourI = (busTour, droppableId, index) => {
    const newState = [...state];

    const result = updateItemFromIndexArr(
      newState[droppableKeys[droppableId as keyof typeof droppableKeys]],
      index,
      busTour
    );
    newState[droppableKeys[droppableId as keyof typeof droppableKeys]] = [...result];

    setState(newState);
    multipleAction();
  };

  /**
   * Eliminara del array escogido el objeto
   *
   * @param droppableId
   * @param index
   */
  const handleDeleteTour = (droppableId: string, index: number) => {
    const newState = [...state];
    const { sourceClone: result, deleted } = removeItems(
      newState[droppableKeys[droppableId as keyof typeof droppableKeys]],
      droppableId,
      index
    );
    newState[droppableKeys[droppableId as keyof typeof droppableKeys]] = [...result];

    // index 3 siempre sera de los objetos eliminados
    newState[3] = [...deleted];

    setState(newState);
    multipleAction();
  };

  const handleSendData = async () => {
    const newData = [
      ...state[1].map((item, index) => ({
        ...item,
        tour_order: (index + 1).toString(),
        tour_type: 'ida',
      })),
      ...state[2].map((item, index) => ({
        ...item,
        tour_order: (index + 1).toString(),
        tour_type: 'retorno',
      })),
    ];

    const newTours: Omit<BusTour, 'id'>[] = [];
    const updatedTours: BusTour[] = [];

    newData.forEach((item) => {
      switch (typeof item.id) {
        case 'string':
          const { id, ...rest } = item;
          newTours.push(rest);
          break;
        case 'number':
          updatedTours.push(item);
          break;

        default:
          break;
      }
    });

    // console.log({
    //   new: newTours,
    //   update: updatedTours,
    //   delete: state[3].map((item) => item.id),
    // });

    submitTours({
      dataPayload: {
        new: newTours,
        update: updatedTours,
        delete: state[3].map((item) => item.id),
      },
    })
      .unwrap()
      .then(() => {
        refetch();
      })
      .catch((error) => console.log(error));

    setIsEdited(false);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-end">
            <Button
              variant="outlined"
              startIcon={<IconFileText size={15} />}
              onClick={() => openPdf(routeInfo.bus_document)}
            >
              Ver PDF
            </Button>

            <AddButton /* variant="outlined" */ onClick={() => handleOpenModalWith()}>
              {t('academic.buses.tours.view.new_tour.btn')}
            </AddButton>
          </Stack>
        </Grid>

        <ComponentFetch
          isLoading={isLoading}
          isSuccess={isSuccess}
          isError={isError}
          error={error as any}
        >
          <Grid item xs={12}>
            <DragDropContext onDragEnd={onDragEnd}>
              <Grid container spacing={2}>
                <Grid item xs={12} mb={2}>
                  {/* <Grid container justifyContent="center"> */}
                  <Typography variant="h3" component="h4">
                    {t('academic.buses.tours.view.reserve.title')}
                  </Typography>
                  <Droppable droppableId="reserve" direction="horizontal">
                    {(provided, snapshot) => {
                      return (
                        <div
                          ref={provided.innerRef}
                          style={getListHoriStyle(snapshot.isDraggingOver)}
                          {...provided.droppableProps}
                        >
                          <TourList
                            droppableId="reserve"
                            tours={state[0] || []}
                            position="up"
                            isLoading={isLoadingSave}
                            handleOpenEdit={handleOpenEdit}
                            handleDeleteTour={handleDeleteTour}
                          />
                          {provided.placeholder}
                        </div>
                      );
                    }}
                  </Droppable>
                  {/* </Grid> */}
                </Grid>

                <Grid item xs={6}>
                  <Typography variant="h3" component="h4">
                    {t('academic.buses.tours.view.go.title')}
                  </Typography>
                  <Grid container justifyContent="center">
                    <Droppable droppableId="ida">
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          style={getListStyle(snapshot.isDraggingOver)}
                          {...provided.droppableProps}
                        >
                          <TourList
                            droppableId="ida"
                            tours={state[1] || []}
                            position="left"
                            isLoading={isLoadingSave}
                            handleOpenEdit={handleOpenEdit}
                            handleDeleteTour={handleDeleteTour}
                          />
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </Grid>
                </Grid>

                <Grid item xs={6}>
                  <Typography variant="h3" component="h4">
                    {t('academic.buses.tours.view.return.title')}
                  </Typography>
                  <Grid container justifyContent="center">
                    <Droppable droppableId="retorno">
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          style={getListStyle(snapshot.isDraggingOver)}
                          {...provided.droppableProps}
                        >
                          <TourList
                            droppableId="retorno"
                            tours={state[2] || []}
                            position="right"
                            isLoading={isLoadingSave}
                            handleOpenEdit={handleOpenEdit}
                            handleDeleteTour={handleDeleteTour}
                          />
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </Grid>
                </Grid>
              </Grid>
            </DragDropContext>
          </Grid>
        </ComponentFetch>
      </Grid>

      <BottomFixedActions containerProps={{ justifyContent: 'center' }}>
        <LoadingButton
          variant="contained"
          isLoading={isLoadingSave}
          onClick={() => handleSendData()}
        >
          {t('shared.save_button.label')}
        </LoadingButton>

        {!isEdited ? null : (
          <Button
            variant="contained"
            color="error"
            disabled={isLoadingSave}
            onClick={() => resetTours()}
          >
            {t('shared.reset_button.label')}
          </Button>
        )}
      </BottomFixedActions>

      <BusNewTourDialog
        open={open}
        editTour={multipleIds?.busTour}
        editData={editData}
        routeId={route}
        handleCloseDialog={handleCloseModal}
        handleCreateTour={handleNewTour}
        handleEditTour={handleEditTour}
      />
    </>
  );
};

export default BusToursList;
