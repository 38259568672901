import { useEffect, useState } from 'react';
import { HighlightOffOutlined, CheckCircleOutlineOutlined } from '@material-ui/icons';

import { useTranslation } from 'react-i18next';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';

import { useGetCategoriesQuery } from '../slices/categoryApiSlice';
import useGuard from '../hooks/useGuard';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import SearchPaginatedTable, {
  FilterArrow,
} from '../../../shared/components/tables/SearchPaginatedTable';
import AddButton from '../../../shared/components/tables/buttons/AddButton';
import StatusChip from '../../../shared/components/badges/StatusChip';
import DeleteButton from '../../../shared/components/tables/buttons/DeleteButton';
import EditButton from '../../../shared/components/tables/buttons/EditButton';
import { openDeleteModalWith, setCategories, updateCategoriesTable } from '../slices/catgorySlice';
import { useTableSearch } from '../../../../hooks/useTableSearch';

const ListCategories = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { hasAccess } = useGuard();

  const history = useHistory();

  const { currentProfile } = useAppSelector((state) => state.access);

  const [filterArrow, setFilterArrow] = useState<FilterArrow>(null!);

  const { path } = useRouteMatch();

  const { page, perPage, search } = useAppSelector((state) => state.categories.categoriesTable);

  const { setPage, setPerPage, setSearch } = useTableSearch({
    page,
    perPage,
    search,
    updateFunction: updateCategoriesTable,
  });

  //Categories
  const { data, isLoading, isFetching, error } = useGetCategoriesQuery({
    profileId: currentProfile?.profileId!,
    page: page,
    size: perPage,
    sort: filterArrow?.name || 'id',
    type_sort: filterArrow?.direction || 'asc',
    search: search,
  });

  const datafetch = data?.data.map((value, index) => ({
    id: index + (page - 1) * perPage + 1,
    nombre: value.cat_name,
    //cate_prin: value.cat_parent || '-',
    cate_prin: value.parent?.cat_name || '-',
    integ_conti: value.contifico_id || '0',
    estado: value.status.id,
    options: value,
  }));

  const checkIntegracion = (val: string) => {
    let check: any;
    if (val === '0') {
      check = <HighlightOffOutlined style={{ color: 'red' }} />;
    } else {
      check = <CheckCircleOutlineOutlined style={{ color: 'green' }} />;
    }
    return check;
  };

  const goToAdd = () => {
    history.push(`${path}/agregar`);
  };

  const handleDelete = async (categoryId: number) => {
    dispatch(openDeleteModalWith(categoryId));
  };

  useEffect(() => {
    if (data) {
      dispatch(setCategories(data?.data!));
    }
  }, [data, dispatch]);

  return (
    <SearchPaginatedTable
      data={datafetch!}
      headers={{
        id: 'N°',
        nombre: t('financial.maintenance_cat.table.name'),
        cate_prin: t('financial.maintenance_cat.table.principal_category'),
        integ_conti: t('financial.maintenance_cat.table.contifico_integration'),
        estado: t('financial.maintenance_cat.table.status'),
        options: t('financial.maintenance_cat.table.options'),
      }}
      listFilterArrows={{
        nombre: 'cat_name',
        integ_conti: 'contifico_id',
      }}
      filterArrow={filterArrow}
      setFilterArrow={setFilterArrow}
      keyExtractor={(item) => item.id.toString()}
      searchPlacehoder={t('financial.maintenance_cat.table.search_placeholder')}
      isLoading={isLoading}
      isFetching={isFetching}
      error={error}
      perPage={perPage}
      setPerPage={setPerPage}
      total={data?.total || 0}
      page={page}
      setPage={setPage}
      numHeader={7}
      search={search}
      setSearch={setSearch}
      customRenderers={{
        integ_conti: (row) => checkIntegracion(row.integ_conti),
        estado: (row) => <StatusChip statusId={Number(row.estado)} />,
        options: (row) => (
          <>
            {hasAccess('edit') && (
              <Link to={`${path}/${row.options.id}/editar`}>
                <EditButton />
              </Link>
            )}
            {hasAccess('delete') && (
              <DeleteButton handleDelete={() => handleDelete(row.options.id)} />
            )}
            {!hasAccess('edit') && !hasAccess('delete') && 'Ninguna'}
          </>
        ),
      }}
      customHeadersCellsProperties={{
        options: {
          align: 'center',
        },
        estado: {
          align: 'center',
        },
        cate_prin: {
          align: 'center',
        },
        integ_conti: {
          align: 'center',
        },
      }}
      customDataCellsProperties={{
        id: { align: 'center' },
        nombre: { align: 'center' },
        estado: {
          align: 'center',
        },
        options: {
          align: 'center',
        },
        cate_prin: {
          align: 'center',
        },
        integ_conti: {
          align: 'center',
        },
      }}
      ActionButtons={<>{hasAccess('create') && <AddButton size="large" onClick={goToAdd} />}</>}
    />
  );
};

export default ListCategories;
