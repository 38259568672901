import { Grid, Typography, useTheme, useMediaQuery, Button, Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import {
  // IconArrowNarrowLeft,
  IconCalendarEvent,
  IconHome,
  IconNotebook,
  IconPictureInPicture,
  IconSchool,
  IconSettings,
} from '@tabler/icons';
import Avatar from '../../../shared/components/extended/Avatar';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { resetVirtualClassroomTeacherState } from '../slices/virtualClassroomTeacherSlice';
import {
  setCurrentCourse,
  resetCurrentCourse,
  setIsFinishedCourse,
} from '../shared/virtualClassroomSharedSlice';
import { Redirect, useHistory } from 'react-router-dom';
import { setCurrentVirtualClassroom } from '../../../shared/reducers/accessReducer';
import { useGetCourseStudentsQuery } from '../../courses/slices/coursesTeacherApiSlice';
import { useTranslation } from 'react-i18next';
import { useGetCourseVCQuery } from '../../../students/virtual-classroom/slices/virtualClassroomApiSlice';
import MoodleButton from '../../../shared/VCButtons/MoodleButton';
import BlackboardButton from '../../../shared/VCButtons/BlackboardButton';

export const useStyles = makeStyles((theme) => ({
  avatar: {
    width: '62px',
    height: '62px',
  },
  title: {
    marginLeft: '5px',
  },
  banner: {
    padding: '5px',
    '& .MuiCardContent-root': {
      padding: '5px !important',
    },
  },
}));

export interface Course {
  courseParalel: string;
  courseName: string;
  courseHourhandDescription: string;
  courseId: number;
  MatterId: number;
  vcType?: string;
  campusName: string;
}

const CourseDetailHeader = () => {
  const dispatch = useAppDispatch();

  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

  const { t } = useTranslation();

  const { currentVirtualClassroom, currentProfile } = useAppSelector((state) => state.access);

  const { isFinishedCourse: IsFinishedCourse } = useAppSelector(
    (state) => state.virtualClassroomTeacherShared
  );

  const classes = useStyles();

  const history = useHistory();

  const [course, setCourse] = useState<Course | null>(
    JSON.parse(localStorage.getItem('virtualClassroomTeacher')!) || null
  );
  // console.log(
  //   currentVirtualClassroom,
  //   course?.courseId!,
  //   localStorage.getItem('virtualClassroomTeacher')!
  // );

  useGetCourseStudentsQuery(
    {
      courseId: course?.courseId!,
      payload: { data: 'all' },
    },
    { skip: !course?.courseId! }
  );

  const { data } = useGetCourseVCQuery({
    courseId: course?.courseId!,
    profileId: currentProfile?.profileId!,
  });

  useEffect(() => {
    setCourse(JSON.parse(localStorage.getItem('virtualClassroomTeacher')!) || null);
  }, []);

  useEffect(() => {
    if (course) {
      const dataCourse = {
        courseHourhandDescription: course?.courseHourhandDescription || '',
        courseId: course?.courseId!,
        courseName: course?.courseName || '',
        courseParalel: course.courseParalel || '',
        MatterId: course?.MatterId,
        vcType: course?.vcType || 'native',
      };
      dispatch(setCurrentCourse(dataCourse));
    }
  }, [course, dispatch]);

  useEffect(() => {
    if (!!data?.id) {
      const isFinishedCourse = new Date() > new Date(data?.end_date || '');

      dispatch(setIsFinishedCourse(isFinishedCourse));
    }
  }, [data, dispatch]);

  /*useEffect(() => {
    if (course) dispatch(setCourseId(course.courseId));
  }, [course, dispatch]);*/

  useEffect(() => {
    if (!currentVirtualClassroom) dispatch(setCurrentVirtualClassroom(true));
  }, [currentVirtualClassroom, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(resetVirtualClassroomTeacherState());
      dispatch(resetCurrentCourse());
      dispatch(setCurrentVirtualClassroom(false));
      localStorage.removeItem('virtualClassroomTeacher');
    };
  }, [dispatch]);

  return course ? (
    <Grid container justifyContent="space-between" className={classes.banner} flexDirection="row">
      <Grid xs={12} md={8} item sx={{ ml: 3 }}>
        <Grid item container alignItems="center">
          <Grid item>
            <Avatar sx={{ bgcolor: theme.palette.grey[200] }} className={classes.avatar}>
              <IconNotebook size={!matchDownMd ? 40 : 35} />
            </Avatar>
          </Grid>
          <Grid item xs style={{ marginLeft: 7 }} zeroMinWidth>
            <Typography
              variant={!matchDownMd ? 'h4' : 'h5'}
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                display: 'block',
              }}
            >
              {t('studentVirtualClassroom.header.course')}
            </Typography>
            <Typography
              variant={!matchDownMd ? 'h3' : 'h4'}
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',

                display: 'block',
              }}
            >
              {course?.courseName}
            </Typography>

            <Grid
              container
              alignItems="center"
              spacing={1}
              sx={{
                mt: 0.5,
              }}
            >
              <Grid item container direction="row" alignItems="center">
                <IconCalendarEvent color={theme.palette.primary.main} />
                <Typography variant="h5" className={classes.title}>
                  {`Fecha de inicio: ${data?.start_date}`}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              spacing={1}
              sx={{
                mt: 0.5,
              }}
            >
              <Grid item container direction="row" alignItems="center">
                <IconCalendarEvent color={theme.palette.primary.main} />
                <Typography variant="h5" className={classes.title}>
                  {`Fecha de finalización: ${data?.end_date}`}
                </Typography>
              </Grid>
            </Grid>
            {IsFinishedCourse && (
              <Grid item container direction="row" alignItems="center" mt={1}>
                <Chip
                  label={t('teacherVirtualClassroom.header.isFinished')}
                  color={'error' as any}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={12} md={3} item sx={{ mb: 3 }} style={{ marginTop: 10 }}>
        <Grid item container alignItems="center" spacing={0}>
          {/* Fin: {data?.end_date} */}
          <Grid item container direction="row" alignItems="center">
            <IconSchool color={theme.palette.primary.dark} />
            <Typography variant={!matchDownMd ? 'h5' : 'h6'} className={classes.title}>
              {t('studentVirtualClassroom.header.campus')}: {course?.campusName}
            </Typography>
          </Grid>
          <Grid item container direction="row" alignItems="center">
            <IconPictureInPicture color={theme.palette.primary.dark} />
            <Typography variant="h5" className={classes.title}>
              {t('teacherVirtualClassroom.header.parallel')}: {course?.courseParalel}
            </Typography>
          </Grid>
          <Grid item container direction="row" alignItems="center">
            <IconHome color={theme.palette.primary.main} />
            <Typography variant="h5" className={classes.title}>
              {t('teacherVirtualClassroom.header.aula')}: {data?.classroom.cl_name}
            </Typography>
          </Grid>
          <Grid item container direction="row" alignItems="center">
            <IconCalendarEvent color={theme.palette.primary.main} />
            <Typography variant="h5" className={classes.title}>
              {course?.courseHourhandDescription}
            </Typography>
          </Grid>
          {course.vcType === 'moodle' && (
            <Grid item container direction="row" mt={2} alignItems="center">
              <MoodleButton />
            </Grid>
          )}
          {course.vcType === 'native' && (
            <Grid item container direction="row" mt={2} alignItems="center">
              <Button
                variant="contained"
                color="primary"
                aria-label="add"
                onClick={() => history.push(`/administrar-aula-virtual/notas/configurations`)}
              >
                {t('teacherVirtualClassroom.header.configurations')}{' '}
                <IconSettings style={{ margin: '0 5px' }} />
              </Button>
            </Grid>
          )}
          {course.vcType === 'blackboard' && (
            <Grid item container direction="row" mt={2} alignItems="center">
              <BlackboardButton />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  ) : (
    <Redirect to="/administrar-cursos-profesor" />
  );
};

export default CourseDetailHeader;
