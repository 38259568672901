import {
  Table,
  Grid,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../../hooks/useRedux';
import StatusChip from '../../../shared/components/badges/StatusChip';
import { renderErrorOrEmptyRow, renderSkeletonRows } from '../../../shared/helpers/render';
import { Period } from '../interfaces/periods.interface';
import { useGetPeriodAcademicOfferingQuery } from '../slices/academicOfferingsApiSlice';
import SkeletonLista from './SkeletonList';

const PeriodsTable = () => {
  const { currentProfile } = useAppSelector((state) => state.access);

  const { academicOfferingId } = useParams<{ academicOfferingId: string }>();

  const { data, isSuccess, isFetching, isLoading, error } = useGetPeriodAcademicOfferingQuery(
    {
      profileId: currentProfile?.profileId!,
      academicOfferingId: academicOfferingId,
    },
    { refetchOnMountOrArgChange: 30 }
  );

  const renderRow = (p: Period, index: number) => (
    <TableRow className="table-row" key={p.id}>
      <TableCell align="center" width="5%">
        {index + 1}
      </TableCell>
      <TableCell width="10%">{p.per_name}</TableCell>
      <TableCell width="5%">{p.type_period.tp_name}</TableCell>
      <TableCell align="center" width="5%">
        {p.per_min_matter_enrollment}
      </TableCell>
      <TableCell align="center" width="5%">
        {p.per_max_matter_enrollment}
      </TableCell>
      <TableCell align="center" width="5%">
        {p.per_num_fees}
      </TableCell>
      <TableCell align="center" width="5%">
        {p.per_pay_enrollment === '1' ? 'Sí' : 'No'}
      </TableCell>
      <TableCell align="center" width="5%">
        {p.per_fees_enrollment}
      </TableCell>
      <TableCell width="5%">
        <StatusChip statusId={Number(p.status_id)} />
      </TableCell>
    </TableRow>
  );

  const renderRows = () => {
    return <>{data?.data.map(renderRow)}</>;
  };

  return (
    <>
      <Grid container justifyContent="center">
        <Grid item sm={12} overflow={'hidden'}>
          {isLoading && <SkeletonLista />}
          {isSuccess && (
            <>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">N°</TableCell>
                      <TableCell>Nombre</TableCell>
                      <TableCell>Tipo</TableCell>
                      <TableCell align="center">Min. Materias</TableCell>
                      <TableCell align="center">Max. Materias</TableCell>
                      <TableCell align="center">Num. Cuotas</TableCell>
                      <TableCell align="center">¿Se cobra matrícula?</TableCell>
                      <TableCell align="center">Cuota/Matrícula</TableCell>
                      <TableCell>Estado</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isFetching ? renderSkeletonRows(2, 11) : renderRows()}

                    {error && renderErrorOrEmptyRow(11, error as string)}
                    {data?.data.length === 0 && renderErrorOrEmptyRow(11)}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default PeriodsTable;
