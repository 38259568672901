import { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useHistory, Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import StatusChip from '../../../shared/components/badges/StatusChip';
import AddButton from '../../../shared/components/tables/buttons/AddButton';
import DeleteButton from '../../../shared/components/tables/buttons/DeleteButton';
import EditButton from '../../../shared/components/tables/buttons/EditButton';
import { format, isValid } from 'date-fns';
import { useGetagreementsQuery } from '../slices/agreementsApiSlices';
import CustomAsyncPaginatedSelect from '../../../shared/components/extended/Form/CustomAsyncPaginatedSelect';
import {
  openDeleteModalWith,
  setAgreementList,
  setStatusIdSelected,
  updateAgreementTableStates,
  setAgreementTypeIdSelected,
} from '../slices/agreementsSlices';
import SearchPaginatedTable from '../../../shared/components/tables/SearchPaginatedTable';
import { useTableSearch } from '../../../../hooks/useTableSearch';
import { Autocomplete, Grid, TextField } from '@material-ui/core';
import { statusAvailables } from '../../../shared/constants/resourceStatus';
import { useLazyGetInstitutesQuery } from '../../institutes/slices/institutesApiSlice';
import { DateRange } from '@material-ui/lab';
import LocalizedDateRangePicker from '../../../shared/components/controlled/LocalizedDateRangePicker';
import useSharedGuard from '../../../../hooks/useSharedGuard';
import { subPermissionKeys } from '../constants/permissions';

const agreementType = [
  { id: 1, value: 'N', label: 'Nivelación' },
  { id: 2, value: 'H', label: 'Homologación' },
];

const AgreementsTable = () => {
  const history = useHistory();
  const { currentProfile } = useAppSelector((state) => state.access);

  const { hasAccess } = useSharedGuard(subPermissionKeys);

  const dispatch = useAppDispatch();
  // const { hasAccess: hasAccessInstitute } = useGuardInstitute();

  const [instituteValue, setInstituteValue] = useState<any | null>(null);

  const [startEndDate, setStartEndDate] = useState<DateRange<Date>>([null, null]);

  // const [agreementName, setAgreementName] = useState();

  const [
    getInstitutesTrigger,
    // { data: institutesAvailables, isLoading: isLoadingInstitutesAvailables },
  ] = useLazyGetInstitutesQuery();

  const getInstitutes = async (search: string, page: number, perPage: number) => {
    const response = await getInstitutesTrigger({
      page,
      perPage,
      search,
      sort: `inst_name`,
      type_sort: 'asc',
      profileId: currentProfile?.profileId!,
    }).unwrap();

    const hasMore = response.current_page < response.last_page;

    return {
      options: response.data.map((dt) => {
        return {
          value: dt.id,
          label: dt?.inst_name,
          ...dt,
        };
      }),
      hasMore,
    };
  };

  const { path } = useRouteMatch();

  const { page, perPage, search, filterArrow } = useAppSelector(
    (state) => state.agreements.agreementTable
  );

  const { statusIdSelected, agreementTypeIdSelected: agreementIdSelected } = useAppSelector(
    (state) => state.agreements
  );

  const { setPage, setPerPage, setSearch, setFilterArrow, resetTable } = useTableSearch({
    page,
    perPage,
    search,
    filterArrow,
    updateFunction: updateAgreementTableStates,
  });

  const { isLoading, error, data, isFetching } = useGetagreementsQuery(
    {
      profileId: currentProfile?.profileId!,
      page,
      perPage,
      search,
      ...(filterArrow && { sort: filterArrow.name, type_sort: filterArrow.direction }),
      ...(statusIdSelected && { statusId: statusIdSelected }),
      ...(instituteValue && { instituteId: instituteValue?.id }),
      ...(startEndDate?.[0] &&
        isValid(startEndDate?.[0]) && { startDate: format(startEndDate[0], 'yyyy-MM-dd') }),
      ...(startEndDate?.[1] &&
        isValid(startEndDate?.[1]) && { endDate: format(startEndDate[1], 'yyyy-MM-dd') }),
      ...(agreementIdSelected && { typeAgreement: agreementIdSelected }),
    },
    { refetchOnMountOrArgChange: 30 }
  );

  useEffect(() => {
    if (data) {
      dispatch(setAgreementList(data.data));
    }
  }, [data, dispatch]);

  const handleDelete = async (agreementsId: number) => {
    dispatch(openDeleteModalWith(agreementsId));
  };

  const goToAdd = () => {
    history.push(`${path}/agregar`);
  };

  useEffect(() => {
    if (data?.data) {
      dispatch(setAgreementList(data.data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, dispatch]);

  useEffect(() => {
    setPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusIdSelected]);

  const datafetch = data?.data!.map((d, index) => ({
    id: index + ((page - 1) * perPage + 1),
    name: d.agr_name,
    agreementType: d.type_agreement === 'N' ? 'Nivelación' : 'Homologación',
    initDate: d.agr_start_date,
    dueDate: d.agr_end_date,
    instName: d.institute?.inst_name,
    agreementNumMatterHomologate: d.type_agreement === 'H' ? d.agr_num_matter_homologate : '-',
    statusId: d.status_id,
    options: d,
  }));

  return (
    <SearchPaginatedTable
      data={datafetch!}
      headers={{
        id: 'N°',
        name: 'Nombre',
        agreementType: 'Tipo',
        initDate: 'Fecha inicio',
        dueDate: 'Fecha fin',
        instName: 'Instituto',
        agreementNumMatterHomologate: 'N° materias homologación',
        statusId: 'Estado',
        options: 'Opciones',
      }}
      listFilterArrows={{
        name: 'agr_name',
        agreementType: 'type_agreement',
        initDate: 'agr_start_date',
        dueDate: 'agr_end_date',
        statusId: 'status_id',
      }}
      filterArrow={filterArrow}
      setFilterArrow={setFilterArrow}
      keyExtractor={(item) => item.id.toString()}
      searchPlacehoder="Buscar convenio"
      isLoading={isLoading}
      isFetching={isFetching}
      error={error}
      perPage={perPage}
      setPerPage={setPerPage}
      total={data?.total || 0}
      page={page}
      setPage={setPage}
      numHeader={9}
      search={search}
      setSearch={setSearch}
      customRenderers={{
        statusId: (row) => <StatusChip statusId={Number(row.statusId)} />,
        options: (row) => (
          <>
            {hasAccess('edit') && (
              <Link to={`${path}/${row.options?.id}/editar`}>
                <EditButton />
              </Link>
            )}
            {hasAccess('delete') && (
              <DeleteButton handleDelete={() => handleDelete(row.options?.id)} />
            )}
            {!hasAccess('edit') && !hasAccess('delete') && 'Ninguna'}
          </>
        ),
      }}
      customDataCellsProperties={{
        id: { align: 'center' },
        agreementType: {
          align: 'center',
        },
        agreementNumMatterHomologate: {
          align: 'center',
        },
        initDate: {
          align: 'center',
        },
        dueDate: {
          align: 'center',
        },
        statusId: {
          align: 'center',
        },
        options: {
          align: 'center',
        },
      }}
      customHeadersCellsProperties={{
        id: { align: 'center' },
        agreementType: {
          align: 'center',
        },
        agreementNumMatterHomologate: {
          align: 'center',
        },
        initDate: {
          align: 'center',
        },
        dueDate: {
          align: 'center',
        },
        statusId: {
          align: 'center',
        },
        options: {
          align: 'center',
        },
      }}
      ActionButtons={<>{hasAccess('create') && <AddButton size="large" onClick={goToAdd} />}</>}
      additionalFilters={
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Autocomplete
              options={agreementType}
              getOptionLabel={(option) => option?.label}
              loadingText="Cargando Tipos de Convenio..."
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Seleccione el Tipo de Convenio"
                  label="Tipo de convenio"
                />
              )}
              value={agreementType.find((dt) => dt.value === agreementIdSelected) || null}
              onChange={(_, value) => {
                dispatch(setAgreementTypeIdSelected(value?.value || null));
              }}
            />
          </Grid>

          <Grid item xs={3}>
            <CustomAsyncPaginatedSelect
              key={'instituteId'}
              value={instituteValue}
              isError={false}
              placeholder=" seleccione el instituto"
              label="Institutos"
              setValue={setInstituteValue}
              fetchFunc={getInstitutes}
            />
          </Grid>

          <Grid item xs={6}>
            <LocalizedDateRangePicker
              startText="Fecha de Inicio"
              endText="Fecha de Fin"
              value={startEndDate}
              onChange={(newValue) => {
                resetTable();
                setStartEndDate(newValue);
              }}
            />
          </Grid>

          <Grid item xs={3}>
            <Autocomplete
              options={statusAvailables || []}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Estado"
                  placeholder="Selecciona un estado"
                  variant="outlined"
                />
              )}
              onChange={(event, newValue) => {
                dispatch(setStatusIdSelected(newValue?.id || null));
              }}
              value={statusAvailables.find((status) => status.id === statusIdSelected)}
            />
          </Grid>
        </Grid>
      }
    />
  );
};

export default AgreementsTable;
