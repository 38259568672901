import * as yup from 'yup';

export const SchemaEvaluations = yup.object().shape({
    description: yup.string().trim().required('Descripción es requerida.'),
    actNumTries: yup.number().required().min(1, 'Número de intentos es requerido.'),
    actScoreActivity: yup.number().min(1, 'Puntaje de actividad es requerido.'),
    distributionId: yup.number().nullable().notRequired(), //yup.number().min(1, 'Seleccione una distribución.'),
    startDate: yup.date().nullable().min(new Date(), 'Fecha de inicio no permitida.').required(),
    endDate: yup.date().nullable().min(new Date(), 'Fecha fin no permitida.').required(),
  
});

export const SchemaEvaluationsEdit = yup.object().shape({
  description: yup.string().trim().required('Descripción es requerida.'),
  actNumTries: yup.number().required().min(1, 'Número de intentos es requerido.'),
  actScoreActivity: yup.number().min(1, 'Puntaje de actividad es requerido.'),
  categoryActivityId: yup.number().min(1, 'Categoría de actividad es requerido.'),
  distributionId: yup.number().nullable().notRequired(), //yup.number().min(1, 'Seleccione una distribución.'),
  startDate: yup.date().nullable().required(),
  endDate: yup.date().nullable().required(),

});


export const TaskQuestionAnswerData = yup.object().shape({
  point: yup.number().min(0.5, 'Pregunta debería estar asociado a un puntaje.'),
  question: yup.string().trim().required('Descripción de pregunta es requerida.'),
});
