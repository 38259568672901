import { useMemo } from 'react';

import { Grid } from '@material-ui/core';
import { useParams } from 'react-router-dom';

import { useAppSelector } from '../../../../hooks/useRedux';
import PersonalDataT from './PersonalDataT';
import CoursesList from './CoursesList';

const AsignCoursesTeacher = () => {
  const { teacherId } = useParams<{ teacherId: string }>();
  const collabList = useAppSelector((state) => state.teacher.collabList);

  const currentTeacher = useMemo(
    () => collabList.find((dt) => dt.id === +teacherId),
    [collabList, teacherId]
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {!!currentTeacher && <PersonalDataT teacher={currentTeacher} />}
      </Grid>
      <Grid item xs={12}>
        <CoursesList />
      </Grid>
    </Grid>
  );
};

export default AsignCoursesTeacher;
