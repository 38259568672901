import { middlewareApi } from '../../../middleware/middlewareApi';

import { mainApi } from '../../../../api/mainApi';
import { StudentsNivelationsResponse } from '../interfaces/studentsNivelation.interface';
import {
  StudentCoursesResponse,
  StudentPayload,
  StudentDebtPayload,
  RegisterStudent,
} from '../interfaces/studentCourses.interface';
import { Period } from '../interfaces/periodsNivelation.interface';
import { ApprobedFailedNivelationResponse } from '../interfaces/approves.failed.inferface';

export const registerNivelationTags = middlewareApi.enhanceEndpoints({
  addTagTypes: ['StudentsNivelation'],
});

export const registerNivelationApi = registerNivelationTags.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * @GET all nivelation periods
     */
    getPeriodsNivelation: builder.query<Period[], { dataParam: string; isVigent?: number }>({
      queryFn: async ({ dataParam, isVigent }) => {
        try {
          const { data } = await mainApi.get<Period[]>(`/nivelations/periods`, {
            params: {
              data: dataParam,
              ...(isVigent && { isVigent }),
            },
          });

          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
      /* providesTags: (result) =>
                result
                    ?
                    [...result.data.map(({ student_record_id }) => ({ type: 'StudentsNivelation', student_record_id } as const)), { type: 'StudentsNivelation', id: 'LIST' }]
                    :
                    { type: 'StudentsNivelation', id: 'LIST' } */
    }),
    /**
     * @GET all students nivelation
     */
    getStudentsNivelation: builder.query<
      StudentsNivelationsResponse,
      {
        profileId: number;
        search: string;
        page?: number;
        size?: number;
        educationLevel: number;
        period_id?: number | undefined;
        sort?: string;
        type_sort?: string;
      }
    >({
      queryFn: async (
        { profileId, search, size, educationLevel, period_id, page, sort, type_sort },
        { dispatch }
      ) => {
        try {
          const { data } = await mainApi.get<StudentsNivelationsResponse>(
            //`/nivelations/education-level/${educationLevel}/students`,
            `/nivelations/education-level/${educationLevel}/students`,
            {
              params: {
                user_profile_id: profileId,
                search: search,
                page: page,
                size: size,
                educationLevel: educationLevel,
                period_id,
                ...(type_sort && { type_sort }),
                ...(sort && { sort }),
              },
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      /* providesTags: (result) =>
                result
                    ?
                    [...result.data.map(({ student_record_id }) => ({ type: 'StudentsNivelation', student_record_id } as const)), { type: 'StudentsNivelation', id: 'LIST' }]
                    :
                    { type: 'StudentsNivelation', id: 'LIST' } */
    }),

    /**
     * @GET student nivelation and courses
     */
    getStudentNivelationCourses: builder.query<
      StudentCoursesResponse,
      {
        profileId: number;
        modality?: number;
        hourhand?: number;
        studentRecord: number;
        period_id?: number;
        campus?: number;
        subject?: number;
        parallel?: number;
      }
    >({
      queryFn: async (
        { profileId, modality, hourhand, studentRecord, period_id, campus, subject, parallel },
        { dispatch }
      ) => {
        try {
          const { data } = await mainApi.get<StudentCoursesResponse>(
            `/nivelations/student-record/${studentRecord}/periods/${period_id}/courses-nivelation`,
            {
              params: {
                user_profile_id: profileId,
                modality: modality,
                hourhand: hourhand,
                studentRecord: studentRecord,
                // period_id: period_id,
                campus: campus,
                matter_id: subject,
                parallel_id: parallel,
              },
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    /**
     * @POST assing subject to student
     */
    addCourseNivelation: builder.mutation<
      StudentPayload,
      { studentPayload: Partial<StudentPayload> }
    >({
      queryFn: async ({ studentPayload }) => {
        try {
          const { data } = await mainApi.post<StudentPayload>(
            '/nivelations/student-record/courses-nivelation',
            {
              ...studentPayload,
            }
          );
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    /**
     * @POST assing subject to student v2
     */
    addRegisterNivelation: builder.mutation<
      RegisterStudent,
      { RegisterStudent: Partial<RegisterStudent> }
    >({
      queryFn: async ({ RegisterStudent }) => {
        try {
          const { data } = await mainApi.post<RegisterStudent>(
            '/nivelations/student-record/courses-nivelation-v2',
            {
              ...RegisterStudent,
            }
          );
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    /**
     * @POST assing debt to student
     */
    addDebtNivelation: builder.mutation<
      StudentDebtPayload,
      { studentDebtPayload: Partial<StudentDebtPayload> }
    >({
      queryFn: async ({ studentDebtPayload }) => {
        try {
          const { data } = await mainApi.post<StudentDebtPayload>(
            '/nivelations/student-record/debts/courses-nivelation',
            {
              ...studentDebtPayload,
            }
          );
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),
    /**
     * @GET aprobes and reprobes sujjects
     */
    getApprovesAndReprovesSubjectsNivelation: builder.query<
      ApprobedFailedNivelationResponse,
      {
        user_profile_id: number;
        student_id: number;
        student_record_id: number;
      }
    >({
      queryFn: async ({ user_profile_id, student_id, student_record_id }) => {
        try {
          const { data } = await mainApi.get<ApprobedFailedNivelationResponse>(
            'nivelations/student-subject-approved-failed',
            {
              params: {
                user_profile_id,
                student_id,
                student_record_id,
              },
            }
          );
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),
  }),
});

export const {
  useGetPeriodsNivelationQuery,
  useGetStudentsNivelationQuery,
  useGetStudentNivelationCoursesQuery,
  useAddCourseNivelationMutation,
  useAddRegisterNivelationMutation,
  useAddDebtNivelationMutation,
  useGetApprovesAndReprovesSubjectsNivelationQuery,
} = registerNivelationApi;
