import { Chip } from '@material-ui/core';

interface props {
  text: string;
  nuevoIngreso: boolean;
}

const ChipStatus = ({ text, nuevoIngreso }: props) => {
  return (
    <Chip
      size="small"
      label={text}
      sx={{
        color: nuevoIngreso ? '#4F98CA' : '#FF896B',
        backgroundColor: nuevoIngreso ? '#D3F4FF' : '#FFE8D6',
      }}
    />
  );
};

export default ChipStatus;
