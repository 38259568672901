import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import ConfirmAlert from '../../../components/dialogs/ConfirmAlert';
import { useUploadEvaluationMutation } from '../../slices/userEvaluationsApiSlice';
import {
  resetEvaluationQuestionsAnswered,
  setEvaluationQuestionToAnswer,
  setEvaluationUploadDialogOpen,
} from '../../slices/userEvaluationsSlice';

const EvaluationUploadDIalog = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useHistory();

  //infoEvaluationToDo

  const { evaluationQuestionsAnswered, infoEvaluationToDo, observationAnswer } = useAppSelector(
    (state) => state.userEvaluations
  );

  const [uploadEvaluation, { isLoading, isSuccess, error, isError }] =
    useUploadEvaluationMutation();

  const handleConfirm = () => {
    uploadEvaluation({
      eva_release_id: infoEvaluationToDo?.eva_release.id! || '',
      eva_release_details_id: infoEvaluationToDo?.id! || '',
      evaluated_id: infoEvaluationToDo?.evaluated_id! || '',
      evaluator_id: infoEvaluationToDo?.evaluator_id! || '',
      category_uuid:
        infoEvaluationToDo?.eva_release?.eva_evaluation?.eva_evaluations_questions[0]
          ?.evaluation_question?.category_uuid! || '',
      questions: evaluationQuestionsAnswered.map((ans) => ({
        option_uuid: ans.option_uuid!,
        question_uuid: ans.questionId,
        eva_value: ans.answerId,
      }))!,
      observation: observationAnswer,
    });
  };
  const handleClose = () => {
    dispatch(setEvaluationUploadDialogOpen(false));
    if (isSuccess) {
      dispatch(resetEvaluationQuestionsAnswered());
      dispatch(setEvaluationQuestionToAnswer(0));
      navigate.push(`/evaluaciones-usuario/`, {
        replace: true,
      });
    }
  };

  return (
    <ConfirmAlert
      open
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      error={error as any}
      handleDelete={handleConfirm}
      onClose={handleClose}
      confirmBtnMessage={t('user_evaluation.evaluation.upload.submit')}
      title={t('user_evaluation.evaluation.upload.title')}
      subtitle={t('user_evaluation.evaluation.upload.subtitle')}
      successMessage={t('user_evaluation.evaluation.upload.success')}
    />
  );
};

export default EvaluationUploadDIalog;
