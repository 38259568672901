import { Redirect, Switch } from 'react-router-dom';
import { useAppSelector } from '../../../../../../hooks/useRedux';
import GuardRoute from '../../../../../../router/guards/GuardRoute';
import ListGroupsScreen from '../screen/ListGroupsScreen';
import UsuariosGruposScreen from '../screen/UsuariosGruposScreen';
// eslint-disable-next-line import/no-cycle

export const GroupsRoutes = () => {
  const { currentModule } = useAppSelector((state) => state.access);

  const hasPermission = 'evaluation' in currentModule?.permissions!;

  if (!hasPermission) return <Redirect to="/dashboard" />;

  return (
    <Switch>
      <GuardRoute
        exact
        path="/grupos/usuarios-por-grupo"
        component={UsuariosGruposScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.create}
      />
      <GuardRoute
        exact
        path="/grupos/listar-grupos"
        component={ListGroupsScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.list}
      />
    </Switch>
  );
};
