interface StatusCollectives {
  id: number;
  name: string;
  color: string;
}

export const StatusCollectivesList: StatusCollectives[] = [
  { id: 1, name: 'Activo', color: 'success' },
  { id: 2, name: 'Inhabilitado', color: 'warning' },
];
