import { useHistory, useLocation } from 'react-router-dom';

import DeleteAlert from '../../../shared/components/dialogs/DeleteAlert';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import {
  useUpdateThirdRegistrationApplicationMutation,
  useUpdateSubjectRetirementApplicationMutation,
  useUpdateOthersApplicationMutation,
  useUpdatePracticeApplicationMutation,
  useUpdatePaymentAgreementApplicationMutation,
  useUpdateInternalHomApplicationMutation,
  useUpdateRecoveryExamApplicationMutation,
  useUpdateLinkApplicationMutation,
  useUpdateRequalificationApplicationMutation,
  useUpdateLateExamApplicationMutation,
  useUpdateFaultJustificationApplicationMutation,
  useUpdateBookReservationApplicationMutation,
  useUpdateProfessionalPracticeApplicationMutation,
} from '../slices/GeneralApplicationsApiSlice';
import { resetAppIdToReject, setDeleteModalOpen } from '../slices/GeneralApplicationsSlice';
import { ApplicationTypesRequest } from '../constants/types';
// import { Redirect } from 'react-router-dom';

const apiRequest: ApplicationTypesRequest = {
  CAMCAR: useUpdateInternalHomApplicationMutation,
  HOMEXT: useUpdateThirdRegistrationApplicationMutation, // no se actualiza aqui
  INGPRA: useUpdatePracticeApplicationMutation,
  REQDOC: useUpdateThirdRegistrationApplicationMutation, // no va aqui
  EXMATR: useUpdateLateExamApplicationMutation,
  EXMREC: useUpdateRecoveryExamApplicationMutation,
  RETMAT: useUpdateSubjectRetirementApplicationMutation, //Retiro de materia
  RECNOT: useUpdateRequalificationApplicationMutation, //Recalificación de nota
  REGTMC: useUpdateThirdRegistrationApplicationMutation, //Registro de tercera matrícula
  OTROS: useUpdateOthersApplicationMutation,
  INGVIN: useUpdateLinkApplicationMutation, //Ingreso a vinculación
  INGTIT: useUpdateThirdRegistrationApplicationMutation, //ingreso titulacion
  CONPAG: useUpdatePaymentAgreementApplicationMutation, //Convenio de pago
  JUSFAL: useUpdateFaultJustificationApplicationMutation, //Justificación de falta
  PREBIB: useUpdateBookReservationApplicationMutation, //Pre-biblioteca
  APPP: useUpdateProfessionalPracticeApplicationMutation,
  DOCHBH: useUpdateLateExamApplicationMutation, // no va aqui
  DOCVAC: useUpdateLateExamApplicationMutation, // no va aqui
  DOCANT: useUpdateLateExamApplicationMutation, // no va aqui
  DOCPDA: useUpdateLateExamApplicationMutation, // no va aqui
};

const RejectAppDialog = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();

  const { deleteModalOpen, gAppToReject } = useAppSelector((state) => state.applications);
  const appType = gAppToReject!.code.split('-')[0];

  const todoFunc = apiRequest[appType as keyof ApplicationTypesRequest] as any;

  const { currentProfile } = useAppSelector((state) => state.access);

  const [rejectApplication, { isLoading, isSuccess, isError, error }] = todoFunc();

  const handleDelete = () => {
    rejectApplication({
      profileId: currentProfile?.profileId!,
      ApplicationId: gAppToReject!.appId,
      applicationPayload: {
        app_code: gAppToReject!.code,
        comment: gAppToReject!.msg,
        refused: gAppToReject?.refuseId ? gAppToReject.refuseId : 1,
      },
    });
  };

  const handleClose = () => {
    dispatch(setDeleteModalOpen(false));
    dispatch(resetAppIdToReject());
    // return <Redirect to="/administrar-homologaciones" />;
  };

  const completeAutoCloseDialog = () => {
    handleClose();
    history.push(`/${location.pathname.split('/')[1]}`);
  };

  return (
    <DeleteAlert
      open={deleteModalOpen}
      onClose={handleClose}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      handleDelete={handleDelete}
      error={error as string}
      deleteTitle={`¿Estás seguro de ${
        gAppToReject?.refuseId === 2 ? 'anular' : 'rechazar'
      } la solicitud?`}
      deleteSubTitle={'No se podrá revertir esta acción ¿Estás seguro ?'}
      deleteSuccess={`Se ha ${
        gAppToReject?.refuseId === 2 ? 'anulado' : 'rechazado'
      }  la solicitud con éxito`}
      isAutoClose
      onCompleteAutoCloseFn={completeAutoCloseDialog}
    />
  );
};

export default RejectAppDialog;
