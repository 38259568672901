import {
   //Autocomplete,
   //Checkbox,
   //Button,
   Grid,
   Typography,
   //TextField,
   /* TableBody,
   TableCell,
   TableContainer,
   TableHead,
   TableRow,
   Table,
   CircularProgress,
   FormHelperText,
   Collapse,
   Box,
   useTheme, */
   // FormControlLabel,
   //Paper,
   Box,
   Tab, Tabs
} from '@material-ui/core';
import React from 'react';
import ContactsList from './ContactsList';
import GerentialReport from './GerentialReport';

interface TabPanelProps {
   children?: React.ReactNode;
   index: number;
   value: number;
}

function TabPanel(props: TabPanelProps) {
   const { children, value, index, ...other } = props;

   return (
      <div
         role="tabpanel"
         hidden={value !== index}
         id={`simple-tabpanel-${index}`}
         aria-labelledby={`simple-tab-${index}`}
         {...other}
      >
         {value === index && (
            <Box sx={{ p: 3 }}>
               <Typography>{children}</Typography>
            </Box>
         )}
      </div>
   );
}

function a11yProps(index: number) {
   return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
   };
}

const ReportManagement = () => {

   const [value, setValue] = React.useState(0);

   const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
   };

   return (
      <>
         <Grid item xs={12} sx={{ mt: 4 }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
               <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                  <Tab label="Reporte Gerencial" {...a11yProps(0)} />
                  <Tab label="Listado de Contactos" {...a11yProps(1)} />
               </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
               <GerentialReport />
            </TabPanel>
            <TabPanel value={value} index={1}>
               <ContactsList />
            </TabPanel>

         </Grid>
      </>
   )
}

export default ReportManagement;