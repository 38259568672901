import { Button, Grid, Typography } from '@material-ui/core';
import { baseUrl } from '../../../../../api/mainApi';
import { useAppDispatch } from '../../../../../hooks/useRedux';
import { IBook } from '../../../books/interfaces/books.interfaces';
import { setBookIdToReserve, setReserveBookModalOpen } from '../../slices/libraryDashboardSlice';

import bookCover from '../../../../../assets/images/nbs-book-empty-cover.jpg';

interface BookCardProps {
  book: IBook;
}

const BookCard = ({ book }: BookCardProps) => {
  const dispatch = useAppDispatch();

  const handleReserveBook = () => {
    dispatch(setBookIdToReserve(book.id));
    dispatch(setReserveBookModalOpen(true));
  };

  return (
    <Grid
      container
      sx={{
        height: '100%',
      }}
    >
      <Grid
        item
        xs={12}
        sm={5}
        md={5}
        px={1}
        sx={{
          height: '-webkit-fill-available',
        }}
      >
        <img
          src={book.book_cover ? `${baseUrl}/show?name=${book.book_cover}` : bookCover}
          alt=""
          style={{
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
            height: '100%',
            width: '100%',
          }}
        />
      </Grid>
      <Grid item xs={12} md={7} pl={2} pr={1}>
        <Typography variant="h4" color="primary">
          {book.title}
        </Typography>
        <Typography
          variant="h5"
          sx={{
            mt: 1,
          }}
        >
          {book.subtitle}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            mt: 1,
          }}
        >
          {book.tags?.reduce((acc, curr) => {
            if (curr.type_tag === 'Autor') {
              return acc + (acc.length > 0 ? ', ' : '') + curr.name;
            }
            return acc;
          }, '')}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            mt: 1,
            fontWeight: 'bold',
          }}
        >
          {Number(book.initial_copies_number || 0) - Number(book.reserved || 0)} disponibles
        </Typography>

        <Button
          fullWidth
          variant="outlined"
          color="primary"
          sx={{
            mt: 1,
            fontWeight: 'bold',
          }}
          onClick={handleReserveBook}
          disabled={Number(book.initial_copies_number || 0) - Number(book.reserved || 0) === 0}
        >
          Reservar
        </Button>
      </Grid>
    </Grid>
  );
};

export default BookCard;
