import { Box } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BasicSelect } from '../../components/Select';

interface Props {
  handleChangeSelect: React.Dispatch<React.SetStateAction<number>>;
  valueStateSelect: number;
  setTask: {};
}

const TaskFilter = ({ handleChangeSelect, setTask, valueStateSelect }: Props) => {
  const { t } = useTranslation();
  const items = [
    {
      value: 0,
      text: t('students.virtual_classroom.task.select.all'),
    },
    {
      value: 1,
      text: t('students.virtual_classroom.task.select.recent'),
    },
    {
      value: 2,
      text: t('students.virtual_classroom.task.select.expired'),
    },
  ];

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        mt: 4,
      }}
    >
      <BasicSelect
        handleChangeTask={handleChangeSelect}
        items={items}
        label={t('students.virtual_classroom.task.select.label')}
        valueState={valueStateSelect}
      />
    </Box>
  );
};

export default TaskFilter;
