import {
  Autocomplete,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  FormHelperText,
  Grid,
  Stack,
  TextField,
  Typography,
  //   useTheme,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import ExtendedSideDialog from '../../../shared/components/dialogs/ExtendedSideDialog';
import InputLabel from '../../../shared/components/forms/InputLabel';
import { useGetApprovalReasonsQuery } from '../../../shared/slices/catalogApi';
import {
  Transition,
  useStyles,
} from '../../../students/subjects-register/components/SubjectsRegisteredDialog';
import { AssignGradeSchema } from '../constants/validationSchema';
import { useUpdateCourseStudentNoteMutation } from '../slices/AssignGradeApiSlice';
import { setSubjectAssignGrade } from '../slices/AssignGradeSlice';
import { useTranslation } from 'react-i18next';

interface Props {
  open: boolean;
  handleCloseDialog: () => void;
}
interface Errors {
  final_note?: string;
}

const AssignGradeDialog = ({ open, handleCloseDialog }: Props) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const { currentProfile } = useAppSelector((state) => state.access);
  const { showAssignGradeDialog, subjectAssignGrade, studentRecord } = useAppSelector(
    (state) => state.assignGrade
  );

  const classes = useStyles();
  const [showAlerts, setShowAlerts] = useState(true);

  const { data: reasons, isLoading: isLoadingM } = useGetApprovalReasonsQuery({
    profileId: currentProfile?.profileId!,
    // acronym: 'motivo-aprobacion',
    type: 'APROBAR',
  });

  useEffect(() => {
    if (!showAssignGradeDialog) dispatch(setSubjectAssignGrade(undefined));
  }, [dispatch, showAssignGradeDialog]);

  const [updateFinalNote, { isError, isSuccess, error, isLoading }] =
    useUpdateCourseStudentNoteMutation();

  const { getFieldProps, errors, touched, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      final_note: 0,
      approval_reason_id: 0,
    },
    validationSchema: AssignGradeSchema,
    validate: (values) => {
      const errors = {} as Errors;
      if (values.final_note > Number(subjectAssignGrade?.equivalences[0]?.max_value)) {
        errors.final_note = `${t('teacher.assign_grades.dialog.final_grade.max')} ${Number(
          subjectAssignGrade?.equivalences[0]?.max_value
        )}`;
      }
      if (!values.final_note) {
        errors.final_note = t('teacher.assign_grades.dialog.final_grade.required');
      }
      return errors;
    },
    onSubmit: async ({ final_note, approval_reason_id }) => {
      await updateFinalNote({
        courseStudentId: subjectAssignGrade?.course_student_id,
        payloadCourse: {
          final_note,
          approval_reason_id,
          subject_curriculum_id: subjectAssignGrade?.subject_curriculum_id || '',
          student_id: studentRecord?.student_id || '',
          subject_id: subjectAssignGrade?.matter_id || '',
        },
      });
      setShowAlerts(true);
    },
  });

  return (
    <ExtendedSideDialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => {
        handleCloseDialog();
      }}
      className={classes.userAddDialog}
      classes={{
        paper: classes.paper,
      }}
    >
      {/* <PerfectScrollbar> */}
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid container item>
              <Typography variant="h2">{t('teacher.assign_grades.dialog.title')}</Typography>
            </Grid>

            <Grid container item>
              {isError && showAlerts && (
                <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
              )}

              {isSuccess && showAlerts && (
                <SuccessAlert
                  message={t('teacher.assign_grades.dialog.success')}
                  handleDismiss={() => setShowAlerts(false)}
                />
              )}
            </Grid>
            <Grid container item>
              <InputLabel>{t('teacher.assign_grades.dialog.approve_reason')}</InputLabel>
              <Autocomplete
                loadingText={t('teacher.assign_grades.dialog.approve_reason.loading')}
                noOptionsText={t('teacher.assign_grades.dialog.approve_reason.no_data')}
                options={reasons || []}
                loading={isLoadingM}
                getOptionLabel={(option) => option?.cat_name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(errors.approval_reason_id && touched.approval_reason_id)}
                    placeholder={t('teacher.assign_grades.dialog.approve_reason.placeholder')}
                  />
                )}
                onChange={(e, value) => setFieldValue('approval_reason_id', value?.id)}
                fullWidth
              />
              {errors.approval_reason_id && touched.approval_reason_id && (
                <FormHelperText error>{t(errors.approval_reason_id)}</FormHelperText>
              )}
            </Grid>
            <Grid container item>
              <InputLabel>{t('teacher.assign_grades.dialog.final_grade')}</InputLabel>
              <TextField
                fullWidth
                type="number"
                error={Boolean(errors.final_note && touched.final_note)}
                placeholder={t('teacher.assign_grades.dialog.final_grade.placeholder')}
                {...getFieldProps('final_note')}
              />
              {errors.final_note && touched.final_note && (
                <FormHelperText error>{t(errors.final_note)}</FormHelperText>
              )}
              {!errors.final_note && !touched.final_note && (
                <FormHelperText>
                  {t('teacher.assign_grades.dialog.final_grade.min_max')}{' '}
                  {Number(subjectAssignGrade?.equivalences[0]?.max_value) || null}
                </FormHelperText>
              )}
            </Grid>

            <Grid
              item
              container
              alignItems="center"
              justifyContent="flex-end"
              spacing={2}
              sx={{ mt: 1 }}
            >
              <Grid item>
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  color="primary"
                  disabled={isLoading}
                  endIcon={isLoading && <CircularProgress size={20} />}
                >
                  {t('teacher.assign_grades.dialog.add')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      {/* </PerfectScrollbar> */}
      <DialogActions>
        <Stack direction="row" spacing={2}>
          <Button
            variant="text"
            onClick={() => {
              handleCloseDialog();
            }}
            color="primary"
          >
            {t('teacher.assign_grades.dialog.cancel')}
          </Button>
        </Stack>
      </DialogActions>
    </ExtendedSideDialog>
  );
};

export default AssignGradeDialog;
