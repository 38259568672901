import { useState, useMemo } from 'react';
// import { useParams } from 'react-router-dom';

import {
  Autocomplete,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  TextField,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Typography,
} from '@material-ui/core';

import { useFormik } from 'formik';

import ErrorAlert from '../../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../../shared/components/alerts/SuccessAlert';
import MainCard from '../../../../shared/components/cards/MainCard';

import esLocale from 'date-fns/locale/es';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';

import { forumSchema } from './../validation-schemas';
import { statusAvailables } from '../../../../shared/constants/resourceStatus';
import InputLabel from '../../../../shared/components/forms/InputLabel';

import DateRangePicker, { DateRange } from '@material-ui/lab/DateRangePicker';
import { LocalizationProvider } from '@material-ui/lab';

import { format } from 'date-fns';
// import ReactQuill from 'react-quill';

import 'react-quill/dist/quill.snow.css';
import { useAppSelector } from '../../../../../hooks/useRedux';
import {
  useGetStudentsAvailablesQuery,
  useUpdateForumMutation,
  useGetDistributionsByCourseQuery,
} from '../../slices/virtualClassroomTeacherApiSlice';

import Transitions from '../../../../shared/components/extended/Transitions';

import { useHistory, useParams } from 'react-router-dom';
import TextFieldFetching from '../../../../shared/components/TextFieldFetching';
import { useTranslation } from 'react-i18next';
import { useGetActivityByAcronymQuery } from '../../../../shared/slices/catalogApi';

const EditForum = () => {
  const { currentProfile } = useAppSelector((state) => state.access);
  const {
    userInfo: { id: userId },
  } = useAppSelector((state) => state.auth);

  const { forumList } = useAppSelector((state) => state.virtualClassroomTeacher);
  const history = useHistory();
  const { forumId } = useParams<{ forumId: string }>();
  const { t } = useTranslation();

  const dataForum = useMemo(
    () => forumList?.find((forum) => forum.id === Number(forumId)),
    //eslint-disable-next-line
    []
  );

  // const dataForum = forumList![0];

  const { data: catalogChildren, isFetching: isFetchingCata } = useGetActivityByAcronymQuery({
    acronym: 'TPA3',
  });

  const {
    course: { courseId },
  } = useAppSelector((state) => state.virtualClassroomTeacherShared);

  const [showAlerts, setShowAlerts] = useState(true);

  const [isAllStudents, setIsAllStudents] = useState(true);

  const handleChangeAllStudents = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsAllStudents(event.target.checked);
  };

  const [updateForum, { isLoading, isError, error, isSuccess }] = useUpdateForumMutation();

  const { data: studentsAvailables = [], isLoading: isLoadingStudents } =
    useGetStudentsAvailablesQuery(
      {
        profileId: currentProfile?.profileId!,
        course: courseId!,
      },
      { skip: !!courseId }
    );

  const { data: distributionAvailables, isLoading: isLoadingDistribution } =
    useGetDistributionsByCourseQuery({
      profileId: currentProfile?.profileId!,
      courseId: courseId!.toString(),
    });

  const [forumDate, setForumDate] = useState<DateRange<Date>>([
    // new Date(dataForum.course_activity_one?.cact_start_date?) || new Date(),
    // new Date(dataForum.course_activity_one?.cact_end_date?) || new Date(),
    new Date(),
    new Date(),
  ]);

  const onChangesForumDate = (newDatesValues: DateRange<Date>) => {
    const [start, end] = newDatesValues;
    //Update formik values
    setFieldValue('cactStartDate', start);
    setFieldValue('cactEndDate', end);

    setForumDate(newDatesValues);
  };

  const validateDates = (cactStartDate: string, cactEndDate: string) => {
    const errors: any = {};

    if (new Date(cactStartDate)! >= new Date(cactEndDate)!) {
      errors.cactStartDate = t('teacher_virtual_classroom.EditForum.lower_date');
    }
    return errors;
  };

  const handleIsScore = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue('actIsScore', event.target.checked);
  };

  const { getFieldProps, errors, touched, handleSubmit, setFieldValue, values } = useFormik({
    initialValues: {
      actDescription: dataForum?.act_description,
      // typeActivityId: 0,
      // catalogoId: 0,
      dtaQuestion: dataForum?.detail_forum.dta_question,
      cactStartDate: new Date(dataForum?.course_activity_one.cact_start_date || ''),
      cactEndDate: new Date(dataForum?.course_activity_one.cact_end_date || ''),
      studentId: [],
      statusId: Number(dataForum?.status_id),
      cactIsExtemporaneous: false,
      actIsScore: dataForum?.act_is_score === '1' ? true : false,
      distributionId: dataForum?.calification_models_distributions_id,
      supplyId: dataForum?.supply_id,
      actScoreActivity: Number(dataForum?.act_score_activity) || 1,
    },
    validationSchema: forumSchema,
    validate: ({ cactStartDate, cactEndDate }) => {
      return validateDates(
        format(new Date(cactStartDate), 'yyyy-MM-dd'),
        format(new Date(cactEndDate), 'yyyy-MM-dd')
      );
    },
    onSubmit: async (values) => {
      await updateForum({
        profileId: currentProfile?.profileId!,
        courseId: courseId!,
        forumId: Number(dataForum?.id),

        ForumPayload: {
          act_description: values.actDescription,
          type_activity_id: catalogChildren?.id,
          user_id: Number(userId),
          act_is_score: values.actIsScore === true ? 1 : 0,
          course_activity: {
            cact_start_date: format(new Date(values.cactStartDate), 'yyyy-MM-dd'),
            cact_end_date: format(new Date(values.cactEndDate), 'yyyy-MM-dd'),
            cact_is_extemporaneous: Number(values.cactIsExtemporaneous),
          },
          status_id: Number(values.statusId),
          detail: { type_answer_id: 612, dta_question: values.dtaQuestion },
          // course_student: values.studentId.map((e) => ...e),
          course_student: values.studentId.flat(1),

          ...(values.actIsScore && {
            calification_models_distributions_id: Number(values.distributionId),
            supply_id: Number(values.supplyId),
            act_score_activity: Number(values.actScoreActivity),
          }),
        },
      });

      setShowAlerts(true);
      window.scrollTo(0, 0);
    },
  });

  console.log(values);

  const supplyInfo = distributionAvailables
    ?.find((e) => e.distribution_id === values.distributionId)
    ?.ref2.find((i) => i.ref2_supply_id === values.supplyId);

  // useEffect(() => {
  //   if (isAllStudents && !isLoadingStudents) {
  //     setFieldValue('studentId', [...(studentsAvailables?.map((v) => v.id) || '-1')]);
  //   } else setFieldValue('studentId', []);
  // }, [isAllStudents, setFieldValue, studentsAvailables, isLoadingStudents]);

  return (
    <MainCard
      sx={{
        borderTopLeftRadius: '0px',
        borderTopRightRadius: '0px',
      }}
    >
      <Grid item container alignItems="center" gap={1} marginY={2}>
        <Typography variant="h3">{t('teacher_virtual_classroom.EditForum.title')}</Typography>
      </Grid>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={4} alignItems="flex-start">
          <Grid item xs={12} md={6} container spacing={2}>
            <Grid item xs={12}>
              {isError && showAlerts && (
                <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
              )}

              {isSuccess && showAlerts && (
                <SuccessAlert
                  message={t('teacher_virtual_classroom.EditForum.success_message')}
                  handleDismiss={() => setShowAlerts(false)}
                />
              )}
            </Grid>
            {/* <Grid item xs={12}>
            <InputLabel>Tema</InputLabel>
            <ReactQuill
              theme="snow"
              placeholder="Ingrese un tema..."
              onChange={(e) => setFieldValue('actDescription', e)}
              value={values.actDescription}
            />
            {errors.actDescription && touched.actDescription && (
              <FormHelperText error>{errors.actDescription}</FormHelperText>
            )}
          </Grid> */}

            <Grid item xs={12}>
              <InputLabel>{t('teacher_virtual_classroom.EditForum.subject_label')}</InputLabel>
              <TextField
                multiline
                minRows={2}
                fullWidth
                error={Boolean(errors.actDescription && touched.actDescription)}
                placeholder={t('teacher_virtual_classroom.EditForum.subject_placeholder')}
                {...getFieldProps('actDescription')}
              />
              <FormHelperText>
                {t('teacher_virtual_classroom.EditForum.subject_helper')}
              </FormHelperText>
              {errors.actDescription && touched.actDescription && (
                <FormHelperText error>{errors.actDescription}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12}>
              <InputLabel>{t('teacher_virtual_classroom.EditForum.detail_label')}</InputLabel>
              {/* <ReactQuill
                theme="snow"
                placeholder="Ingrese detalles..."
                onChange={(e) => setFieldValue('dtaQuestion', e)}
                value={values.dtaQuestion}
              /> */}
              <TextField
                multiline
                minRows={2}
                fullWidth
                error={Boolean(errors.dtaQuestion && touched.dtaQuestion)}
                placeholder={t('teacher_virtual_classroom.EditForum.detail_placeholder')}
                {...getFieldProps('dtaQuestion')}
              />
              {errors.dtaQuestion && touched.dtaQuestion && (
                <FormHelperText error>{errors.dtaQuestion}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12} marginTop={2}>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                <DateRangePicker
                  startText={null}
                  endText={null}
                  value={forumDate}
                  onChange={onChangesForumDate}
                  renderInput={(startProps, endProps) => (
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12} sm={3} lg={4} sx={{ pt: { xs: 2, sm: '0 !important' } }}>
                        <InputLabel>
                          {t('teacher_virtual_classroom.EditForum.start_date_label')}:
                        </InputLabel>
                      </Grid>
                      <Grid item xs={12} sm={9} lg={8}>
                        <TextField
                          fullWidth
                          {...startProps}
                          helperText=""
                          error={Boolean(errors.cactStartDate && touched.cactStartDate)}
                          {...getFieldProps('cactStartDate')}
                        />
                        <FormHelperText>
                          {t('teacher_virtual_classroom.EditForum.start_date_helper')}
                        </FormHelperText>

                        {errors.cactStartDate && touched.cactStartDate && (
                          <FormHelperText error>{errors.cactStartDate}</FormHelperText>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={3} lg={4} sx={{ pt: { xs: 2, sm: '0 !important' } }}>
                        <InputLabel>
                          {t('teacher_virtual_classroom.EditForum.end_date_label')}:
                        </InputLabel>
                      </Grid>

                      <Grid item xs={12} sm={9} lg={8}>
                        <TextField
                          fullWidth
                          {...endProps}
                          helperText=""
                          error={Boolean(errors.cactEndDate && touched.cactEndDate)}
                          {...getFieldProps('cactEndDate')}
                        />
                        <FormHelperText>
                          {t('teacher_virtual_classroom.EditForum.end_date_helper')}
                        </FormHelperText>
                        {errors.cactEndDate && touched.cactEndDate && (
                          <FormHelperText error>{errors.cactEndDate}</FormHelperText>
                        )}
                      </Grid>
                    </Grid>
                  )}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12}>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox checked={isAllStudents} onChange={handleChangeAllStudents} />}
                  label={t('teacher_virtual_classroom.EditForum.all_students_can_watch_forum')}
                />
              </FormGroup>
            </Grid>

            {!isAllStudents && (
              <Grid item xs={12}>
                <InputLabel>
                  {t('teacher_virtual_classroom.EditForum.students_who_can_view_this_forum_label')}
                </InputLabel>

                <Autocomplete
                  multiple
                  options={studentsAvailables || []}
                  loading={isLoadingStudents}
                  loadingText={t(
                    'teacher_virtual_classroom.EditForum.students_who_can_view_this_forum_loading'
                  )}
                  getOptionLabel={(option) => `${option.pers_full_name} `}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(errors.studentId && touched.studentId)}
                      placeholder={t(
                        'teacher_virtual_classroom.EditForum.students_who_can_view_this_forum_placeholder'
                      )}
                    />
                  )}
                  onChange={(_, values) =>
                    setFieldValue('studentId', [...values.map((v) => v.id)] || '')
                  }
                  fullWidth
                />
                {errors.studentId && touched.studentId && (
                  <FormHelperText error>{errors.studentId}</FormHelperText>
                )}
              </Grid>
            )}

            <Grid item xs={12}>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox checked={values.actIsScore} onChange={handleIsScore} />}
                  label={t('teacher_virtual_classroom.EditForum.forum_evaluated_label')}
                />
              </FormGroup>
              <Transitions type="collapse" in={values.actIsScore}>
                <Grid item xs={4} marginTop={2}>
                  <InputLabel>
                    {t('teacher_virtual_classroom.EditForum.distribution_label')}
                  </InputLabel>
                  {isLoadingDistribution ? (
                    <TextFieldFetching />
                  ) : (
                    <Autocomplete
                      options={distributionAvailables || []}
                      getOptionLabel={(option) => option.distribution}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={Boolean(errors.distributionId && touched.distributionId)}
                        />
                      )}
                      loading={isLoadingDistribution}
                      defaultValue={distributionAvailables?.find(
                        (e) => e.distribution_id === values.distributionId
                      )}
                      onChange={(e, value) =>
                        setFieldValue('distributionId', value?.distribution_id || '')
                      }
                      fullWidth
                      clearIcon={null}
                    />
                  )}
                  <FormHelperText>
                    {t('teacher_virtual_classroom.EditForum.distribution_helper')}
                  </FormHelperText>
                  {errors.distributionId && touched.distributionId && (
                    <FormHelperText error>{errors.distributionId}</FormHelperText>
                  )}
                </Grid>

                <Grid item xs={4} marginTop={2}>
                  <InputLabel>{t('teacher_virtual_classroom.EditForum.supplies_label')}</InputLabel>
                  {isLoadingDistribution ? (
                    <TextFieldFetching />
                  ) : (
                    <Autocomplete
                      options={
                        distributionAvailables?.find(
                          (e) => e.distribution_id === values.distributionId
                        )?.ref2 || []
                      }
                      getOptionLabel={(option) => option.ref2}
                      defaultValue={distributionAvailables
                        ?.find((e) => e.distribution_id === values.distributionId)
                        ?.ref2.find((e) => e.ref2_supply_id === values.supplyId)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={Boolean(errors.supplyId && touched.supplyId)}
                        />
                      )}
                      loading={isLoadingDistribution}
                      onChange={(e, value) =>
                        setFieldValue('supplyId', value?.ref2_supply_id || '')
                      }
                      fullWidth
                      clearIcon={null}
                    />
                  )}
                  <FormHelperText>
                    {t('teacher_virtual_classroom.EditForum.supplies_helper')}
                  </FormHelperText>
                  {errors.supplyId && touched.supplyId && (
                    <FormHelperText error>{errors.supplyId}</FormHelperText>
                  )}
                </Grid>
                <Grid item container marginY={2} spacing={1}>
                  <Grid item>
                    <InputLabel>
                      {t('teacher_virtual_classroom.EditForum.total_grade')}:{' '}
                      {Number(supplyInfo?.composition_value) * 100 || '0'}
                    </InputLabel>
                  </Grid>
                  <Grid item>
                    <InputLabel>
                      {t('teacher_virtual_classroom.EditForum.garde_used')}:{' '}
                      {Number(supplyInfo?.suma_score_activity) || '0'}
                    </InputLabel>
                  </Grid>
                  <Grid item>
                    <InputLabel>
                      {t('teacher_virtual_classroom.EditForum.grade_available')}:
                      {Number(supplyInfo?.composition_value) * 100 -
                        Number(supplyInfo?.suma_score_activity) || '0'}
                    </InputLabel>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={4}>
                  <InputLabel>
                    {t('teacher_virtual_classroom.EditForum.grade_forum_label')}
                  </InputLabel>
                  <TextField
                    type="number"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      inputProps: {
                        min: 1,
                        max:
                          Number(supplyInfo?.composition_value) * 100 -
                            Number(supplyInfo?.suma_score_activity) || 10,
                      },
                    }}
                    fullWidth
                    error={Boolean(errors.actScoreActivity && touched.actScoreActivity)}
                    placeholder={t('teacher_virtual_classroom.EditForum.grade_forum_placeholder')}
                    {...getFieldProps('actScoreActivity')}
                  />
                  {errors.actScoreActivity && touched.actScoreActivity && (
                    <FormHelperText error>{errors.actScoreActivity}</FormHelperText>
                  )}
                </Grid>
              </Transitions>
            </Grid>

            <Grid item xs={12} container spacing={2}>
              <Grid item xs={12} marginTop={2}>
                <InputLabel>
                  {t('teacher_virtual_classroom.EditForum.status_forum_label')}
                </InputLabel>
                <Autocomplete
                  options={statusAvailables}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField {...params} error={Boolean(errors.statusId && touched.statusId)} />
                  )}
                  defaultValue={
                    statusAvailables.find((e) => e.id === Number(dataForum?.status_id)) ||
                    statusAvailables[0]
                  }
                  onChange={(e, value) => setFieldValue('statusId', value?.id || '')}
                  fullWidth
                  clearIcon={null}
                />
                <FormHelperText>
                  {t('teacher_virtual_classroom.EditForum.status_forum_placeholder')}
                </FormHelperText>
                {errors.statusId && touched.statusId && (
                  <FormHelperText error>{errors.statusId}</FormHelperText>
                )}
              </Grid>
            </Grid>

            <Grid
              item
              container
              alignItems="center"
              justifyContent="flex-end"
              spacing={2}
              sx={{ mt: 1 }}
            >
              <Grid item>
                <Button
                  variant="outlined"
                  size="large"
                  color="primary"
                  onClick={() => history.goBack()}
                >
                  {t('teacher_virtual_classroom.EditForum.return')}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  color="primary"
                  disabled={isFetchingCata || isLoading}
                  endIcon={isLoading && <CircularProgress size={20} />}
                >
                  {t('teacher_virtual_classroom.EditForum.save')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </MainCard>
  );
};

export default EditForum;
