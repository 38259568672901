import { useEffect, useState } from 'react';
import SearchPaginatedTable, {
  FilterArrow,
} from '../../../shared/components/tables/SearchPaginatedTable';
import {
  Grid,
  Typography,
  Checkbox,
  // MenuList,
  // MenuItem,
  Autocomplete,
  TextField,
} from '@material-ui/core';
// import { MoreVert } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
// import { useRouteMatch } from 'react-router-dom';

// import useGuard from '../hooks/useGuard';
import IconTelework from '../../../shared/components/illustrations/IconTelework';
import InputLabel from '../../../shared/components/forms/InputLabel';
import { useAppSelector, useAppDispatch } from '../../../../hooks/useRedux';
import {
  useGetClientByFiltersQuery,
  useGetDiscountGroupConfigurationsQuery,
} from '../../clients/slices/clientApiSlice';
import { useAssignDiscountListMutation } from '../slices/clientDiscountApiSlice';

import { useTableSearch } from '../../../../hooks/useTableSearch';
import { updateClientTable, setClientList } from '../slices/clientDiscountSlice';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
// import CustomPopper from '../../../shared/components/popper/CustomPopper';
import { Discount } from '../interfaces/clientsDiscountPayload.interface';
import LoadingButton from '../../../shared/components/buttons/LoadingButton';

const ClientsDiscountList = () => {
  const dispatch = useAppDispatch();

  const { currentProfile } = useAppSelector((state) => state.access);

  const { t } = useTranslation();

  const [showAlerts, setShowAlerts] = useState(false);

  const [discountSelect, setDiscountSelect] = useState<number>();

  const [selected, setSelected] = useState<Discount[]>([]);

  // const dispatch = useAppDispatch();
  // const history = useHistory();

  const { page, perPage, search } = useAppSelector((state) => state.clientsDiscount.clientTable);

  const { setPage, setPerPage, setSearch } = useTableSearch({
    search,
    page,
    perPage,
    updateFunction: updateClientTable,
  });

  // const { hasAccess } = useGuard();
  // const { path } = useRouteMatch();

  const [filterArrow, setFilterArrow] = useState<FilterArrow>(null!);

  const {
    data: person,
    isLoading: isLoadingP,
    isFetching: isFetchingP,
    error,
  } = useGetClientByFiltersQuery(
    {
      user_profile_id: currentProfile?.profileId!,
      is_student: 1,

      size: perPage,
      page,
      search,
      ...(filterArrow && { sort: filterArrow.name, type_sort: filterArrow.direction }),
    }
    // { refetchOnMountOrArgChange: 30 }
  );

  const { data: discountConfiguration = [], isLoading: isLoadingDC } =
    useGetDiscountGroupConfigurationsQuery({
      data: 'all',
      type_discount_keyword: 'TIPDES_G',
    });

  const datafetch2 =
    person?.data!.map((item, index) => ({
      check: item,
      id: index + (page - 1) * perPage + 1,
      code: item.pers_identification || '',
      name: item.pers_full_name || '',
      discountProgram: item?.user?.student?.student_records,
      options: {
        studentId: item?.user?.student?.id,
        id: item.id,
        studentRecord: item?.user?.student?.student_records,
      },
    })) || [];

  const controlSelectionArrString = (selected: Discount[], itemId: Discount): Discount[] => {
    const selectedIndex = selected.map((e) => e.student_id).indexOf(itemId.student_id);
    let newSelected: Discount[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, itemId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    return newSelected;
  };

  const handleClick = (event: React.ChangeEvent<HTMLInputElement>, discountId: Discount) => {
    const newSelected = controlSelectionArrString(selected, discountId);

    setSelected(newSelected);
  };

  //check all
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected: Discount[] = person?.data
        .filter(({ user }) => !!user?.student?.student_records[0]?.id)
        .filter((person) => !selected.find((s) => s?.student_id === person?.user?.student?.id))
        .map((dt) => ({
          student_id: dt.user?.student?.id!,
          group_configuration_id: discountSelect!,
          is_apply: Boolean(Number(dt.user?.student?.student_records[0]?.stud_apply_early_payment)),
          student_record_id: dt.user?.student?.student_records[0].id!,
        }))!;
      setSelected((data) => [...data, ...newSelected]);
      return;
    }

    setSelected((data) =>
      data.filter(
        ({ student_id }) => !person?.data.some(({ user: { student } }) => student.id === student_id)
      )
    );
  };

  const [
    addClientsList,
    {
      isLoading: isLoadingClients,
      isError: isErrorClients,
      isSuccess: isSuccessClients,
      error: errorClients,
    },
  ] = useAssignDiscountListMutation();

  const handleSubmit = async () => {
    await addClientsList({
      list_discount: selected,
    });
    setShowAlerts(true);
  };

  useEffect(() => {
    dispatch(setClientList(person?.data!));
    //eslint-disable-next-line
  }, [person]);

  console.log('discountSelect', discountSelect, isLoadingClients, addClientsList, selected);

  return (
    <Grid>
      <Grid container item xs={12}>
        {isSuccessClients && showAlerts && (
          <Grid item xs={12}>
            <SuccessAlert
              message="Se ha aplicado descuento correctamente"
              handleDismiss={() => setShowAlerts(false)}
            />
          </Grid>
        )}
        {isErrorClients && showAlerts && (
          <Grid item xs={12}>
            <ErrorAlert
              message={errorClients as string}
              handleDismiss={() => setShowAlerts(false)}
            />
          </Grid>
        )}
      </Grid>
      <Grid item md={12}>
        {datafetch2 ? (
          <>
            <SearchPaginatedTable
              data={datafetch2 || []}
              headers={{
                check: (
                  <Checkbox
                    color="primary"
                    disabled={!person?.data?.length || isFetchingP}
                    indeterminate={
                      person?.data &&
                      !!selected.length &&
                      !!person?.data?.filter(({ user: { student } }) =>
                        selected.some((e) => e.student_id === student.id)
                      ).length &&
                      person?.data?.filter(({ user: { student } }) =>
                        selected.some((e) => e.student_id === student.id)
                      ).length !==
                        person?.data?.filter(({ user }) => !!user?.student?.student_records[0]?.id)
                          .length
                    }
                    checked={
                      !isLoadingP &&
                      !!selected.length &&
                      person?.data?.filter(({ user: { student } }) =>
                        selected.some((e) => e.student_id === student.id)
                      ).length ===
                        person?.data?.filter(({ user }) => !!user?.student?.student_records[0]?.id)
                          .length
                    }
                    onChange={handleSelectAllClick}
                    inputProps={{
                      'aria-label': 'select all cash points',
                    }}
                  />
                ) as any,
                id: 'N°',
                code: t('financial.client_list.table.identification'),
                name: t('financial.client_list.table.names'),
                discountProgram: t('Programa'),
                options: '',
              }}
              keyExtractor={(item) => item.id.toString()}
              listFilterArrows={{
                code: 'pers_identification',
                name: 'pers_full_name',
              }}
              filterArrow={filterArrow}
              setFilterArrow={setFilterArrow}
              searchPlacehoder={t('financial.client_list.table.search_placeholder')}
              isLoading={isLoadingP}
              isFetching={isFetchingP}
              error={error}
              perPage={perPage}
              setPerPage={setPerPage}
              total={person?.total || 0}
              page={page}
              setPage={setPage}
              numHeader={5}
              search={search}
              setSearch={setSearch}
              customRenderers={{
                check: (row) => (
                  <>
                    {!!row?.options?.studentRecord[0]?.id && (
                      <Checkbox
                        checked={selected.some((r) => r.student_id === row.options.studentId)}
                        disabled={!row?.options?.studentRecord[0]?.id}
                        onChange={(e) =>
                          handleClick(e, {
                            student_id: row.options.studentId!,
                            group_configuration_id: discountSelect!,
                            // is_apply: false,
                            is_apply: true,
                            student_record_id: row.check.user?.student?.student_records[0].id!,
                          })
                        }
                        // disabled={Number(row.) === 1}
                      />
                    )}
                    {!row?.options?.studentRecord[0]?.id && '-'}
                  </>
                ),
                discountProgram: (row) => (
                  <>
                    {!!row?.options?.studentRecord[0]?.id && row.options?.studentId ? (
                      <Autocomplete
                        options={row.discountProgram || []}
                        noOptionsText={t(
                          'financial.client_list.asign_discount.form.student_record_no_options'
                        )}
                        getOptionLabel={(option) => option.education_level.edu_name || ''}
                        onChange={(_, value) => {
                          console.log('student_record', value);
                        }}
                        defaultValue={row.discountProgram[0]}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder={t(
                              'financial.client_list.asign_discount.form.student_record_placeholder'
                            )}
                          />
                        )}
                        fullWidth
                        clearIcon={null}
                      />
                    ) : (
                      'N/A'
                    )}
                  </>
                ),
                // options: (row) => (
                //   <CustomPopper iconButton={<MoreVert />} titleButton="Ver opciones">
                //     <MenuList id="split-button-menu" autoFocusItem>
                //       {hasAccess('edit') && (
                //         <MenuItem
                //           key={'academic_history'}
                //           onClick={() =>
                //             history.push(`${path}/${row.options.id}/asignar-descuento/asignacion`)
                //           }
                //         >
                //           {t('financial.client_list.table_options.discount_btn_tooltip')}
                //         </MenuItem>
                //       )}
                //       {row.options.studentId && (
                //         <MenuItem
                //           key={'account_state'}
                //           onClick={() => history.push(`${path}/${row.options.id}/estado-cuenta`)}
                //         >
                //           {t('financial.client_list.table_options.account_state')}
                //         </MenuItem>
                //       )}
                //     </MenuList>
                //   </CustomPopper>
                // ),
              }}
              additionalFilters={
                <Grid container spacing={2} mb={2}>
                  <Grid item xs={4}>
                    <InputLabel>
                      {t('financial.client_list.asign_discount.form.config_label')}
                    </InputLabel>
                    <Autocomplete
                      options={discountConfiguration || []}
                      loading={isLoadingDC}
                      loadingText={t('financial.client_list.asign_discount.form.config_loading')}
                      noOptionsText={t(
                        'financial.client_list.asign_discount.form.config_no_options'
                      )}
                      getOptionLabel={(option) => option.gc_description || ''}
                      onChange={(_, value) => {
                        setDiscountSelect(value?.id || 0);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={t(
                            'financial.client_list.asign_discount.form.config_placeholder'
                          )}
                        />
                      )}
                      fullWidth
                      clearIcon={null}
                    />
                  </Grid>
                  <Grid
                    container
                    item
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    xs={8}
                  >
                    <LoadingButton
                      isLoading={isLoadingClients}
                      children={'Asignar descuento ' + selected.length}
                      onClick={() => {
                        if (selected.length >= 1 && discountSelect !== undefined) {
                          handleSubmit();
                        }
                      }}
                    />
                  </Grid>
                </Grid>
              }
              filtersPos={'top'}
              customHeadersCellsProperties={{
                options: {
                  align: 'center',
                },
              }}
              customDataCellsProperties={{
                id: {
                  align: 'center',
                },
                code: {
                  align: 'center',
                },
                name: {
                  align: 'center',
                },
                discountProgram: { align: 'center' },
                check: {
                  align: 'center',
                },
                options: {
                  style: {
                    display: 'none',
                  },
                },
              }}
              // ActionButtons={
              //   <>
              //     {hasAccess('create') && (
              //       <AddButton
              //         size="large"
              //         onClick={() => history.push('/administrar-clientes/agregar')}
              //       />
              //     )}
              //   </>
              // }
            />
          </>
        ) : (
          <Grid container justifyContent={'center'} alignContent={'center'} sx={{ height: '50vh' }}>
            <Grid container justifyContent={'center'} item xs={8} md={6}>
              <Grid container item xs={12} justifyContent={'center'}>
                <IconTelework size={300} />
              </Grid>
              <Typography variant={'body1'} textAlign={'center'} lineHeight={2} component={'div'}>
                <Typography variant={'h4'} component={'p'} align={'center'} lineHeight={2}>
                  {t('financial.client_list.no_data.title')}
                </Typography>
                {t('financial.client_list.no_data.info')}
              </Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default ClientsDiscountList;
