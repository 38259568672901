import Autocomplete from '@material-ui/core/Autocomplete';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { useFormik } from 'formik';
import { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import ErrorAlert from '../../../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../../../shared/components/alerts/SuccessAlert';
import { useAppSelector } from '../../../../../../hooks/useRedux';
import { statusAvailables } from '../../../../../shared/constants/resourceStatus';
import { useGetEducationLevelsParentsVMQuery } from '../../../../education-levels/slices/educationLevelsApiSlice';
import { useUpdateInvestigationLinesMutation } from '../../../slices/InvestigationLinesApiSlice';
import InputLabel from '../../../../../shared/components/forms/InputLabel';
import { InvestigationLinesSchema } from '../../../constants/validationSchemas.investigationLines';
import TextFieldFetching from '../../../../../shared/components/TextFieldFetching';

interface Props {
  researchLineId: string;
}

const EditInvestigationLineForm = ({ researchLineId }: Props) => {
  const { t } = useTranslation();

  const { investigationLineList } = useAppSelector((state) => state.investigationLines);

  const investigationLineInfo = useMemo(
    () => investigationLineList.find((e) => e.id === researchLineId),
    [investigationLineList, researchLineId]
  );

  const [showAlerts, setShowAlerts] = useState(true);

  const [updateInvestigationLine, { isLoading, isError, error, isSuccess }] =
    useUpdateInvestigationLinesMutation();

  const { data: facultadAvailables, isLoading: isLoadingFalcultad } =
    useGetEducationLevelsParentsVMQuery({});

  const { getFieldProps, errors, touched, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      name: investigationLineInfo?.name || '',
      description: investigationLineInfo?.description || '',
      educationLevel: investigationLineInfo?.education_level.map(({ id }) => id) || [],
      status: statusAvailables.find((e) => e.acronym === investigationLineInfo?.status)?.id || 1,
    },
    validationSchema: InvestigationLinesSchema,
    onSubmit: async ({ description, educationLevel, name }) => {
      await updateInvestigationLine({
        researchLineId,
        investigationLinePayload: {
          name,
          description,
          education_levels: educationLevel,
          // status,
        },
      });
      window.scrollTo(0, 0);
      setShowAlerts(true);
    },
  });

  return (
    <Grid container spacing={2}>
      {isSuccess && showAlerts && (
        <SuccessAlert
          message={t('lines_research.EditInvestigationLineForm.edit.success')}
          handleDismiss={() => setShowAlerts(false)}
        />
      )}
      {isError && showAlerts && (
        <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
      )}

      <Grid item xs={12}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={9}>
              <InputLabel>{t('lines_research.AddInvestigationLineForm.name.title')}</InputLabel>
              <TextField
                {...getFieldProps('name')}
                error={Boolean(errors.name && touched.name)}
                placeholder={t('lines_research.AddInvestigationLineForm.name.placeholder')}
                fullWidth
              />
              {errors.name && touched.name && (
                <FormHelperText error>{t(errors.name)}</FormHelperText>
              )}
            </Grid>
            <Grid item xs={12} md={9}>
              <InputLabel>
                {t('lines_research.AddInvestigationLineForm.description.title')}
              </InputLabel>
              <TextField
                {...getFieldProps('description')}
                error={Boolean(errors.description && touched.description)}
                placeholder={t('lines_research.AddInvestigationLineForm.description.placeholder')}
                fullWidth
              />
              {errors.description && touched.description && (
                <FormHelperText error>{errors.description}</FormHelperText>
              )}
            </Grid>
            <Grid item xs={12}>
              <InputLabel>{t('academic.subjects.add.academic_unit')}</InputLabel>

              {isLoadingFalcultad ? (
                <TextFieldFetching />
              ) : (
                <Autocomplete
                  multiple
                  options={facultadAvailables || []}
                  loading={isLoadingFalcultad}
                  loadingText={t('academic.subjects.add.edu_level.loading')}
                  noOptionsText={t('academic.subjects.add.edu_level.no_data')}
                  getOptionLabel={(option) => option.edu_name}
                  defaultValue={facultadAvailables?.filter(({ id }) =>
                    investigationLineInfo?.education_level.some((e) => e.id === id)
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(errors.educationLevel && touched.educationLevel)}
                      placeholder={t('academic.subjects.add.edu_level.placeholder')}
                    />
                  )}
                  onChange={(_, value) => {
                    setFieldValue(
                      'educationLevel',
                      value.map((item) => item.id)
                    );
                  }}
                  fullWidth
                  clearIcon={null}
                />
              )}
              {errors.educationLevel && touched.educationLevel && (
                <FormHelperText error>{t(errors.educationLevel)}</FormHelperText>
              )}
            </Grid>
            {false && (
              <Grid item xs={12}>
                <InputLabel> {t('lines_research.statusAvailables.title')} </InputLabel>
                <Autocomplete
                  options={statusAvailables}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(errors.status && touched.status)}
                      placeholder={t('lines_research.statusAvailables.placeholder')}
                    />
                  )}
                  defaultValue={
                    statusAvailables.find((e) => e.acronym === investigationLineInfo?.status) ||
                    statusAvailables[0]
                  }
                  onChange={(e, value) => setFieldValue('status', value?.id || '')}
                  fullWidth
                  clearIcon={null}
                />
                {errors.status && touched.status && (
                  <FormHelperText error>{errors.status}</FormHelperText>
                )}
              </Grid>
            )}
            <Grid
              item
              container
              alignItems="center"
              justifyContent="right"
              spacing={2}
              sx={{ mt: 6 }}
            >
              <Grid item xs={6}>
                <Button
                  fullWidth
                  type="submit"
                  variant="contained"
                  size="large"
                  color="primary"
                  disabled={isLoading}
                  endIcon={isLoading && <CircularProgress size={20} />}
                >
                  {t('academic.subjects.add.submit')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default EditInvestigationLineForm;
