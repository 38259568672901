import { Grid, Typography } from '@material-ui/core';
import { useAppSelector } from '../../../../hooks/useRedux';
import MainCard from '../../../shared/components/cards/MainCard';
import CollaboratorsTable from '../components/CollaboratorsTable';
import DeleteCollaboratorDialog from '../components/DeleteCollaboratorDialog';
// import UsersTable from '../components/UsersTable';
// import PeriodsTypesTable from '../components/PeriodsTypesTable';
// import DeletePeriodTypeDialog from '../components/DeletePeriodTypeDialog';

const UsersScreen = () => {
  const { collaboratorToDelete } = useAppSelector((state) => state.collaborators);

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">Colaboradores</Typography>
      </Grid>

      <CollaboratorsTable />

      {collaboratorToDelete && <DeleteCollaboratorDialog />}
    </Grid>
  );
};

export default UsersScreen;
