import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import { setObservationDialog } from '../../slices/requisitionsSlice';

const ObservationDialog = () => {
  const { observationDialog } = useAppSelector((state) => state.requisitionTeacher);

  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(
      setObservationDialog({
        open: false,
        message: '',
        title: '',
      })
    );
  };

  return (
    <Dialog open={observationDialog.open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Typography variant="h2">{observationDialog.title}</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography>{observationDialog.message}</Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ObservationDialog;
