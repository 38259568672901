import { Grid, makeStyles, Typography } from '@material-ui/core';
import MainCard from '../../../../shared/components/cards/MainCard';
import WeekActivities from '../../../dashboard/components/WeekActivities';
import StudentCalendar from '../components/CalentarStudent';
import TimeLineActivities from '../components/TimeLineActivities';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  scrollRoot: {
    '& >div.ps__rail-y': {
      '& :hover': {
        backgroundColor: 'black',
      },
    },

    '& >div.ps__rail-y > div.ps__thumb-y': {
      backgroundColor: theme.palette.primary[200],
      '& :hover': {
        backgroundColor: 'black',
      },
    },
    // '& .scrollbar-container .ps__rail-y:hover > .ps__thumb-y, .scrollbar-container .ps__rail-y:focus > .ps__thumb-y, .scrollbar-container .ps__rail-y.ps--clicking .ps__thumb-y':
    //   { backgroundColor: 'red' },
  },
}));

const VCStudentDashboardScreen = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Grid
        style={{
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          minHeight: '500px',
          overflowY: 'hidden',
        }}
      >
        <Grid container>
          <Grid
            component={MainCard}
            item
            sx={{ boxShadow: 2, borderTopLeftRadius: '0px', borderTopRightRadius: '0px' }}
            style={{ backgroundColor: '' }}
            xs={7}
          >
            <Grid item alignItems="center">
              <Typography variant="h2">{t('studentVirtualClassroom.content.content')}</Typography>
            </Grid>

            <Grid container item justifyContent="center" md={12}>
              <StudentCalendar />
            </Grid>
            <WeekActivities />
          </Grid>

          <Grid
            sx={{
              bgcolor: 'lightgray[200]-',
              p: '24px',
              height: '75vh',
            }}
            item
            xs={5}
          >
            <PerfectScrollbar className={classes.scrollRoot} component={'div'}>
              <Grid item alignItems="center" sx={{ mb: 3 }}>
                <Typography variant="h3">
                  {t('studentVirtualClassroom.content.activities')}
                </Typography>
              </Grid>
              <TimeLineActivities />
            </PerfectScrollbar>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default VCStudentDashboardScreen;
