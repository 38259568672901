import { useState } from 'react';

import { Link, useRouteMatch } from 'react-router-dom';
import { IconUsers } from '@tabler/icons';
import { Autocomplete, Grid, IconButton, TextField, Tooltip, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../../hooks/useRedux';

import StatusChip from '../../../shared/components/badges/StatusChip';
import SearchPaginatedTable from '../../../shared/components/tables/SearchPaginatedTable';
import { renderSkeletonTable } from '../../../shared/helpers/render';
import { useTableSearch } from '../../../../hooks/useTableSearch';
import { updateCopunselorsTableStates } from '../slices/academicCounselorSlice';
import TextFieldFetching from '../../../shared/components/TextFieldFetching';
import { useGetAvailablesOffersQuery } from '../../academic-offerings/slices/academicOfferingsApiSlice';
import { useGetEducationLevelsParentsVMQuery } from '../../education-levels/slices/educationLevelsApiSlice';
import { useGetCounselorsQuery } from '../slices/academicCounselorApiSlice';
import useSharedGuard from '../../../../hooks/useSharedGuard';
import { subPermissionKeys } from '../constants/permissions';

const AcademicCounselorsTable = () => {
  const { path } = useRouteMatch();
  const { t } = useTranslation();
  const { hasAccess } = useSharedGuard(subPermissionKeys);

  const [offerId, setOfferId] = useState<number>(0);
  const [academic, setAcademic] = useState<number>(0);

  const { page, perPage, search, filterArrow } = useAppSelector(
    (state) => state.academicCounselors.academicCounselorsTable
  );

  const { setPage, setPerPage, setSearch, setFilterArrow } = useTableSearch({
    page,
    perPage,
    search,
    filterArrow,
    updateFunction: updateCopunselorsTableStates,
  });

  const { isLoading, error, data, isFetching } = useGetCounselorsQuery(
    {
      page,
      perPage,
      search,
      offer: offerId,
      educationLevel: academic,
      sort: filterArrow,
    },
    { refetchOnMountOrArgChange: 30 }
  );

  const {
    data: dataOffers,
    isLoading: isLoadingOffers,
    isFetching: isFetchingOffers,
  } = useGetAvailablesOffersQuery({});

  const {
    data: dataSubjects,
    isLoading: isLoadingSubjects,
    isFetching: isFetchingSubjects,
  } = useGetEducationLevelsParentsVMQuery(
    {
      offerId: offerId,
    },
    { skip: offerId === 0 }
  );

  const datafetch = data?.data!.map((d, index) => ({
    id: index + ((page - 1) * perPage + 1),
    name: d.user?.person?.pers_full_lastname,
    // mail: d.coll_email,
    // gender: d.user.person?.pers_gender,
    phone_home: d.user?.person?.pers_phone_home,
    identification: d.user?.person?.pers_identification,
    address: d.user?.person?.pers_direction,
    // birthday: d.user.person.pers_date_birth,
    asignedStudents: d.students_control_count,
    statusId: d.status_id,
    options: {
      id: d.id,
      idPerson: d.user?.person?.id,
      email: d.coll_email,
      gender: d.user?.person?.pers_gender,
      user: d.user,
    },
  }));

  return isLoading ? (
    renderSkeletonTable(perPage, 7)
  ) : (
    <>
      <SearchPaginatedTable
        data={datafetch!}
        headers={{
          id: 'N°',
          name: 'Nombre',
          // mail: 'Email',
          // gender: 'Género',
          phone_home: 'Teléfonio convencional',
          identification: 'Cédula',
          address: 'Dirección',
          // birthday: 'Cumpleaños',
          asignedStudents: 'Estudiantes asignados',
          statusId: 'Estado',
          options: 'Opciones',
        }}
        listFilterArrows={{
          name: 'p.pers_full_lastname',
          identification: 'p.pers_identification',
          address: 'p.pers_direction',
          statusId: 'status_id',
        }}
        filterArrow={filterArrow}
        setFilterArrow={setFilterArrow}
        keyExtractor={(item) => item.id.toString()}
        searchPlacehoder="Buscar consejero"
        isLoading={isLoading}
        isFetching={isFetching}
        error={error}
        perPage={perPage}
        setPerPage={setPerPage}
        total={data?.total || 0}
        page={page}
        setPage={setPage}
        numHeader={7}
        search={search}
        setSearch={setSearch}
        customRenderers={{
          // id: (row) => row.id.id,

          statusId: (row) => <StatusChip statusId={Number(row.statusId)} />,
          name: (row) => (
            <>
              {row.name}
              <Typography variant="subtitle2" color="grey">
                {row.options.email}
              </Typography>
              <Typography variant="subtitle2" color="grey">
                {row.options.gender}
              </Typography>
            </>
          ),

          options: (row) => (
            <>
              {hasAccess(['showCollaborator', 'getStudentsOfCollaborator']) &&
                !!Number(row.asignedStudents) && (
                  <Link to={`${path}/${row.options.id}/estudiantes`}>
                    <Tooltip title="Ver estudiantes asignados">
                      <IconButton aria-label="Editar" color="secondary">
                        <IconUsers size={20} />
                      </IconButton>
                    </Tooltip>
                  </Link>
                )}
              {/* {hasAccess('edit') && (
                <Tooltip title="Asignar credenciales de meets">
                  <IconButton color="inherit" onClick={() => handleOpenModalWith(row.options.id)}>
                    <KeyIcon sx={{ fontSize: '1.3rem' }} />
                  </IconButton>
                </Tooltip>
              )} */}
              {/* {hasAccess('delete') && (
                <DeleteButton handleDelete={() => handleDelete(row.options.id)} />
              )} */}
              {/* {!hasAccess('edit') && !hasAccess('delete') && 'Ninguna'} */}
            </>
          ),
        }}
        additionalFilters={
          <Grid container alignItems="center" spacing={2}>
            {/* Academic level */}
            {hasAccess('showOffers') && (
              <Grid item xs={6}>
                {isLoadingOffers || isFetchingOffers ? (
                  <TextFieldFetching
                    placeholder={t(
                      'teachers.academic_counseling.students_assigned.academic_level.loading'
                    )}
                  />
                ) : (
                  <Autocomplete
                    options={dataOffers || []}
                    loading={isLoadingOffers}
                    noOptionsText={t(
                      'teachers.academic_counseling.students_assigned.academic_level.no_data'
                    )}
                    value={(dataOffers && dataOffers?.find((e) => e.id === offerId)) || null}
                    getOptionLabel={(option) => option.off_name}
                    onChange={(_, value) => {
                      setOfferId(value?.id || 0);
                      setAcademic(0);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t('teachers.academic_counseling.students_assigned.academic_level')}
                        placeholder={t(
                          'teachers.academic_counseling.students_assigned.academic_level.placeholder'
                        )}
                      />
                    )}
                    fullWidth
                  />
                )}
              </Grid>
            )}

            {/* Academic */}
            {hasAccess('getOnlyParentsWithMeshVigents') && (
              <Grid item xs={6}>
                {isLoadingSubjects || isFetchingSubjects ? (
                  <>
                    <TextFieldFetching
                      placeholder={t(
                        'teachers.academic_counseling.students_assigned.academic_unit.loading'
                      )}
                    />
                  </>
                ) : (
                  <Autocomplete
                    options={dataSubjects! || []}
                    loading={isLoadingSubjects}
                    loadingText={t(
                      'teachers.academic_counseling.students_assigned.academic_unit.loading'
                    )}
                    noOptionsText={t(
                      'teachers.academic_counseling.students_assigned.academic_unit.no_data'
                    )}
                    value={(dataSubjects && dataSubjects?.find((e) => e.id === academic)) || null}
                    getOptionLabel={(option) => option.edu_name}
                    onChange={(_, value) => setAcademic(value?.id || 0)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t('teachers.academic_counseling.students_assigned.academic_unit')}
                        placeholder={t(
                          'teachers.academic_counseling.students_assigned.academic_unit.placeholder'
                        )}
                      />
                    )}
                    fullWidth
                  />
                )}
              </Grid>
            )}
          </Grid>
        }
        // ActionButtons={<>{hasAccess('create') && <AddButton size="large" onClick={goToAdd} />}</>}
      />
    </>
  );
};

export default AcademicCounselorsTable;
