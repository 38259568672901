import Grid from '@material-ui/core/Grid';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import useTheme from '@material-ui/core/styles/useTheme';

import { PreviewFees } from '../../interfaces/previewFees.interface';

import { renderErrorOrEmptyRow, renderSkeletonRows } from '../../../../shared/helpers/render';
import { useTranslation } from 'react-i18next';
import { Box, Collapse, IconButton } from '@material-ui/core';
import { useState } from 'react';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import SubCard from '../../../../shared/components/cards/SubCard';
import { IconCheck, IconX } from '@tabler/icons';
// import { renderErrorOrEmptyRow, renderSkeletonRows } from '../../../../shared/helpers/render';
// import { showWithDecimals } from '../../../../shared/helpers';

export interface FormProduct {
  category: string;
  name: string;
  price: string;
  id: string;
}

interface Props {
  data: PreviewFees[];
  error?: string;
  isLoading: boolean;
}

const ReviewQuotasTable = ({ data, error, isLoading }: Props) => {
  const { t } = useTranslation();

  const theme = useTheme();

  const [openDescription, setOpenDescription] = useState('');
  //

  const handleToggleOpenDescription = (id: string) => {
    setOpenDescription(openDescription === id ? '' : id);
  };

  var dataTotal = 0;

  const renderRow = (quotaData: PreviewFees, i: number) => {
    const key = `row-${i}`;

    return (
      <>
        <TableRow hover className="table-row" key={`{i}`}>
          <TableCell align="center" width="5%">
            {quotaData?.discounts_product?.length > 0 && (
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => handleToggleOpenDescription(key)}
              >
                {openDescription === key ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              </IconButton>
            )}
          </TableCell>
          <TableCell>{quotaData.product_name}</TableCell>
          <TableCell align="center">${Number(quotaData.pc_gross_value).toFixed(2)}</TableCell>
          <TableCell align="center">
            {quotaData.discounts_product?.length > 0 ? (
              <IconCheck style={{ color: theme.palette.success.main }} />
            ) : (
              <IconX style={{ color: theme.palette.error.main }} />
            )}
          </TableCell>
          <TableCell align="center">${Number(quotaData.pc_net_value).toFixed(2)}</TableCell>
          {/* <TableCell align="left">
          {showWithDecimals(
            Number(quotaData.pc_discount_percent || 0) * Number(quotaData.pc_discount_percent)
          )}
        </TableCell> */}
        </TableRow>
        <TableRow className="table-row">
          <TableCell
            style={{ paddingBottom: 0, paddingTop: 0 }}
            colSpan={5}
            sx={{ backgroundColor: theme.palette.primary.light }}
          >
            <Collapse in={openDescription === key} timeout="auto" unmountOnExit>
              <Box p={4}>
                <SubCard>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow sx={{ backgroundColor: theme.palette.grey[200] }}>
                          <TableCell>
                            {t('students.subjects.registration.debts.detail.name_discount')}
                          </TableCell>
                          <TableCell align="center">
                            {t('students.subjects.registration.debts.detail.base')}
                          </TableCell>
                          <TableCell align="center">
                            {t('students.subjects.registration.debts.detail.percent')}
                          </TableCell>
                          <TableCell align="center">
                            {t('students.subjects.registration.debts.detail.discount')}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {quotaData?.discounts_product?.map((item) => (
                          <TableRow>
                            <TableCell>{item?.name_discount}</TableCell>
                            <TableCell align="center">${Number(item?.base).toFixed(2)}</TableCell>
                            <TableCell align="center">
                              {Number(item?.percent).toFixed(2)}%
                            </TableCell>
                            <TableCell align="center">
                              ${Number(item?.discount).toFixed(2)}
                            </TableCell>
                          </TableRow>
                        ))}
                        {quotaData?.discounts_product?.length > 0 && (
                          <TableRow>
                            <TableCell colSpan={3} align="right">
                              <strong>{t('Total')}:</strong>
                            </TableCell>
                            <TableCell align="center">
                              $
                              {quotaData?.discounts_product
                                ?.reduce((acc, item) => {
                                  return acc + Number(item?.discount);
                                }, 0)
                                .toFixed(2)}
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </SubCard>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  };

  const renderRows = () => {
    return <>{data?.map(renderRow)}</>;
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow sx={{ backgroundColor: theme.palette.grey[200] }}>
                  <TableCell></TableCell>
                  <TableCell>{t('students.subjects.registration.product')}</TableCell>
                  <TableCell align="center">{t('students.subjects.registration.value')}</TableCell>
                  <TableCell align="center">
                    {t('students.subjects.registration.discount')}
                  </TableCell>
                  <TableCell align="center">
                    {t('students.subjects.registration.discount_value')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading && renderSkeletonRows(5, 4)}
                {!isLoading && data && renderRows()}
                {!isLoading &&
                  !data &&
                  error &&
                  renderErrorOrEmptyRow(
                    4,
                    'No se ha encontrado la configuración del producto requerida'
                  )}
                {!isLoading &&
                  data?.length === 0 &&
                  renderErrorOrEmptyRow(3, 'No hay cuotas asignadas')}
                {!isLoading && data && data?.length !== 0 && (
                  <TableRow>
                    <TableCell colSpan={4} align="right">
                      {' '}
                      <b>Total:</b>
                    </TableCell>
                    {/* <TableCell> {showWithDecimals(100)} </TableCell> */}
                    <TableCell align="center">
                      {data?.forEach((item) => {
                        dataTotal += Number(item?.pc_net_value);
                      })}
                      ${dataTotal.toFixed(2)}
                    </TableCell>

                    <TableCell> </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
};

export default ReviewQuotasTable;
