import { Grid } from '@material-ui/core';
import { useAppSelector } from '../../../../../hooks/useRedux';
import { useGetResearchGroupQuery } from '../../slices/InvestigationLinesApiSlice';
import ResearchGroupTable from './ResearchGroupTable';
import { useState } from 'react';
import { FilterArrow } from '../../../../shared/components/tables/SearchPaginatedTable';

export interface FilterData {
  group: FilterArrow;
  lines: FilterArrow;
}

const GroupsTable = () => {
  const [sort, setSort] = useState<FilterData>({
    group: { name: 'name', direction: 'asc' },
    lines: { name: 'name', direction: 'asc' },
  });

  const {
    page: pageGroup,
    perPage: perPageGroup,
    search: searchGroup,
  } = useAppSelector((state) => state.investigationLines.groupResearchTable);

  const {
    data: dataGroup,
    error: errorGroup,
    isLoading: isLoadingGroup,
    isFetching: isFetchingGroup,
  } = useGetResearchGroupQuery({
    search: searchGroup,
    type_sort: 'asc',
    sort: 'name',
    page: pageGroup,
    size: perPageGroup,
  });

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <ResearchGroupTable
          data={dataGroup!}
          isLoading={isLoadingGroup}
          error={errorGroup}
          isFetching={isFetchingGroup}
          setSort={setSort}
          sort={sort}
        />
      </Grid>
    </Grid>
  );
};

export default GroupsTable;
