import {
  Autocomplete,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppSelector } from '../../../../hooks/useRedux';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import InputSecondaryLabel from '../../../shared/components/forms/InputSecondaryLabel';
import { statusAvailables } from '../../../shared/constants/resourceStatus';
import { useGetAvailablesCampusQuery } from '../../campus/slices/campusApiSlice';
import { useFormStyles } from '../constants/styles';
import { ClassroomInfoSchema } from '../constants/validationSchemas';
import useSharedGuard from '../../../../hooks/useSharedGuard';

import { subPermissionKeys } from '../../campus/constants/permissions';
import {
  useAddClassroomMutation,
  useGetAvailableClassroomTypesQuery,
} from '../slices/classroomsApiSlice';
import { useTranslation } from 'react-i18next';

const AddClassroomForm = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useFormStyles();
  const { hasAccess } = useSharedGuard(subPermissionKeys);

  const { currentProfile } = useAppSelector((state) => state.access);

  const [showAlerts, setShowAlerts] = useState(true);

  const [addClassroom, { isLoading, isError, error, isSuccess }] = useAddClassroomMutation();

  const { data: classroomTypesAvailables = [], isLoading: isLoadingCT } =
    useGetAvailableClassroomTypesQuery({ profileId: currentProfile?.profileId! });

  const { data: campusAvailables = [], isLoading: isLoadingCAMP } = useGetAvailablesCampusQuery({
    profileId: currentProfile?.profileId!,
  });

  const { getFieldProps, errors, touched, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      block: '',
      name: '',
      description: '',
      capacity: 0,
      statusId: 1,
      campusId: -1,
      classroomTypeId: -1,
    },
    validationSchema: ClassroomInfoSchema,
    onSubmit: async (values) => {
      await addClassroom({
        profileId: currentProfile?.profileId!,
        classroomPayload: {
          cl_block: values.block,
          cl_name: values.name,
          cl_cap_max: values.capacity,
          cl_acronym: null,
          cl_description: values.description,
          campus_id: values.campusId.toString(),
          classroom_type_id: values.classroomTypeId.toString(),
          status_id: values.statusId.toString(),
        },
      });
      setShowAlerts(true);
    },
  });
  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction="column" gap={2}>
        <Grid item>
          {isError && showAlerts && (
            <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
          )}

          {isSuccess && showAlerts && (
            <SuccessAlert
              message={t('academic.classrooms.add.success')}
              handleDismiss={() => setShowAlerts(false)}
            />
          )}
        </Grid>

        <Grid item container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <InputSecondaryLabel
              label={t('academic.classrooms.add.campus')}
              title={t('academic.classrooms.add.campus.add')}
              link={'/administrar-campus/agregar'}
              hasAccess={hasAccess('create')}
            />
            <Autocomplete
              options={campusAvailables}
              loading={isLoadingCAMP}
              loadingText={t('academic.classrooms.add.campus.loading')}
              noOptionsText={t('academic.classrooms.add.campus.no_data')}
              getOptionLabel={(option) => option.cam_name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(errors.campusId && touched.campusId)}
                  placeholder={t('academic.classrooms.add.campus.placeholder')}
                />
              )}
              onChange={(_, value) => setFieldValue('campusId', value?.id || '')}
              fullWidth
              clearIcon={null}
            />
            {errors.campusId && touched.campusId && (
              <FormHelperText error>{t(errors.campusId)}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>
              {t('academic.classrooms.add.block')}
            </InputLabel>
            <TextField
              fullWidth
              error={Boolean(errors.block && touched.block)}
              placeholder={t('academic.classrooms.add.block.placeholder')}
              {...getFieldProps('block')}
            />
            {errors.block && touched.block && (
              <FormHelperText error>{t(errors.block)}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>
              {t('academic.classrooms.add.classroom_type')}
            </InputLabel>
            <Autocomplete
              options={classroomTypesAvailables}
              loading={isLoadingCT}
              loadingText={t('academic.classrooms.add.classroom_type.loading')}
              noOptionsText={t('academic.classrooms.add.classroom_type.no_data')}
              getOptionLabel={(option) => option.clt_name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(errors.classroomTypeId && touched.classroomTypeId)}
                  placeholder={t('academic.classrooms.add.classroom_type.placeholder')}
                />
              )}
              onChange={(_, value) => setFieldValue('classroomTypeId', value?.id || '')}
              fullWidth
              clearIcon={null}
            />
            {errors.classroomTypeId && touched.classroomTypeId && (
              <FormHelperText error>{t(errors.classroomTypeId)}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>
              {t('academic.classrooms.add.name')}
            </InputLabel>
            <TextField
              fullWidth
              error={Boolean(errors.name && touched.name)}
              placeholder={t('academic.classrooms.add.name.placeholder')}
              {...getFieldProps('name')}
            />
            {errors.name && touched.name && <FormHelperText error>{t(errors.name)}</FormHelperText>}
          </Grid>

          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>
              {t('academic.classrooms.add.description')}
            </InputLabel>
            <TextField
              multiline
              rows={2}
              fullWidth
              placeholder={t('academic.classrooms.add.description.placeholder')}
              {...getFieldProps('description')}
            />
          </Grid>

          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>
              {t('academic.classrooms.add.capacity')}
            </InputLabel>
            <TextField
              fullWidth
              type="number"
              error={Boolean(errors.capacity && touched.capacity)}
              placeholder={t('academic.classrooms.add.capacity.placeholder')}
              {...getFieldProps('capacity')}
            />
            {errors.capacity && touched.capacity && (
              <FormHelperText error>{t(errors.capacity)}</FormHelperText>
            )}
          </Grid>

          {/* <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>Acrónimo</InputLabel>
            <TextField
              fullWidth
              error={Boolean(errors.acronym && touched.acronym)}
              placeholder="Ingrese el acrónimo del aula"
              {...getFieldProps('acronym')}
            />
            {errors.acronym && touched.acronym && (
              <FormHelperText error>{errors.acronym}</FormHelperText>
            )}
          </Grid> */}

          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>
              {t('academic.classrooms.add.status')}
            </InputLabel>
            <Autocomplete
              options={statusAvailables}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField {...params} error={Boolean(errors.statusId && touched.statusId)} />
              )}
              defaultValue={statusAvailables[0]}
              onChange={(e, value) => setFieldValue('statusId', value?.id || '')}
              fullWidth
              clearIcon={null}
            />
            {errors.statusId && touched.statusId && (
              <FormHelperText error>{t(errors.statusId)}</FormHelperText>
            )}
          </Grid>
        </Grid>

        <Grid
          item
          container
          alignItems="center"
          justifyContent="flex-end"
          spacing={2}
          sx={{ mt: 1 }}
        >
          <Grid item>
            <Button
              variant="outlined"
              size="large"
              color="primary"
              onClick={() => history.goBack()}
            >
              {t('academic.classrooms.add.back')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              disabled={isLoading}
              endIcon={isLoading && <CircularProgress size={20} />}
            >
              {t('academic.classrooms.add.submit')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default AddClassroomForm;
