import { Route, Switch } from 'react-router-dom';

import InvestigationProjectLines from '../components/investigation-project-lines';
import AddInvestigationLinesStepper from '../components/investigation-project-lines/forms/InvestigationLinesStepper';
import AddInvestigationLinesWStepper from '../components/projects/forms/ProjectStepper';
import Proyectos from '../components/projects';
import GroupsTable from '../components/investigation-project-lines/GroupsTable';

const path = 'administrar-lineas-de-investigacion';

const researchLineasPath = 'lineas-de-proyecto-de-investigacion';
const researchGroupsPath = 'grupos-de-proyecto-de-investigacion';

const InvestigationProjectsLinesRoute = () => {
  return (
    <Switch>
      <Route exact path={`/${path}/${researchLineasPath}`} component={InvestigationProjectLines} />
      <Route exact path={`/${path}/${researchGroupsPath}`} component={GroupsTable} />
      <Route
        exact
        path={`/${path}/${researchLineasPath}/crear/:caseKey`}
        component={AddInvestigationLinesStepper}
      />
      <Route
        exact
        path={`/${path}/${researchGroupsPath}/crear/:caseKey`}
        component={AddInvestigationLinesStepper}
      />

      {/* <Route
        exact
        path={`/${path}/${researchLineasPath}/crear/:caseKey`}
        component={AddInvestigationLinesStepper}
      /> */}

      <Route
        exact
        path={`/${path}/${researchLineasPath}/:id/editar`}
        component={AddInvestigationLinesStepper}
      />
      <Route
        exact
        path={`/${path}/${researchGroupsPath}/:id/editar`}
        component={AddInvestigationLinesStepper}
      />

      <Route exact path={`/${path}/proyectos`} component={Proyectos} />
      <Route
        exact
        path={`/${path}/proyectos/crear/:caseKey`}
        component={AddInvestigationLinesWStepper}
      />

      <Route
        exact
        path={`/${path}/proyectos/crear/:caseKey/proyecto/:projectId`}
        component={AddInvestigationLinesWStepper}
      />
      {/* <Route exact path={'/proyectos/solicitudes/:id'} component={Applications} /> */}
    </Switch>
  );
};

export default InvestigationProjectsLinesRoute;
