import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EvaluationDetailQuestion } from '../interfaces/evaluation-qualify.interfaces';
import { EvaluationData } from '../interfaces/evaluation-response.interfaces';
import {
  EvaluationQuestion,
  EvaluationQuestionSave,
  InfoEvaluation,
} from '../interfaces/evaluations.interfaces';
import { EvaluationDetail } from '../interfaces/evaluation-detail.interfaces';

export interface EvaluationState {
  evaluation: {
    evaluationList?: EvaluationData[];
    evaluationDetail?: EvaluationDetail;
    evaluationQuestionCurrent?: EvaluationQuestion;
    evaluationToQualify: EvaluationDetailQuestion[];
    choose: boolean;
    typeQuestion: 'comentary' | 'single' | 'multiple' | 'imagen' | 'archivo';
    chooseAnswerEdit: boolean;
    questions: EvaluationQuestionSave[];
    infoEvaluation?: InfoEvaluation;
    questionAdded: boolean;
    deleteModalOpen: boolean;
    evaluationIdToDelete: number | null;
  };
}

const initialState: EvaluationState = {
  evaluation: {
    chooseAnswerEdit: false,
    choose: false,
    evaluationToQualify: [],
    questionAdded: false,
    typeQuestion: 'multiple',
    questions: [],
    deleteModalOpen: false,
    evaluationIdToDelete: null,
  },
};

const evaluationSlice = createSlice({
  name: 'evaluationTeacher',
  initialState,
  reducers: {
    /**
     * Actions evalaution
     */
    setEvaluations: (state, action: PayloadAction<EvaluationData[]>) => {
      state.evaluation.evaluationList = action.payload;
    },
    setChoose: (state, action: PayloadAction<boolean>) => {
      state.evaluation.choose = action.payload;
    },
    setInfoEvaluation: (state, action: PayloadAction<InfoEvaluation>) => {
      state.evaluation.infoEvaluation = action.payload;
    },
    setEvaluationQuestionCurrent: (state, action: PayloadAction<EvaluationQuestion>) => {
      state.evaluation.evaluationQuestionCurrent = action.payload;
    },
    setQuestions: (state, action: PayloadAction<EvaluationQuestionSave>) => {
      state.evaluation.questions = state.evaluation.questions.concat(action.payload);
    },
    updateQuestion: (state, action: PayloadAction<EvaluationQuestionSave>) => {
      const questions = state.evaluation.questions.map((e) =>
        e.id === action.payload.id ? action.payload : e
      );
      state.evaluation.questions = questions;
    },
    setQuestionsPreloaded: (state, action: PayloadAction<EvaluationQuestionSave[]>) => {
      state.evaluation.questions = state.evaluation.questions = action.payload;
    },
    setChooseAnswerEdit: (state, action: PayloadAction<boolean>) => {
      state.evaluation.chooseAnswerEdit = action.payload;
    },
    setQuestionAdded: (state, action: PayloadAction<boolean>) => {
      state.evaluation.questionAdded = action.payload;
    },
    removeQuestionSaved: (state, action: PayloadAction<number>) => {
      state.evaluation.questions = state.evaluation.questions.filter(
        (e, idx) => idx !== action.payload
      );
    },
    resetQuestions: (state) => {
      state.evaluation.questions = [];
    },
    resetEvaluationQuestionCurrent: (state) => {
      state.evaluation.evaluationQuestionCurrent = undefined;
    },
    resetInfoEvaluation: (state) => {
      state.evaluation.infoEvaluation = undefined;
    },
    setTypeQuestion: (
      state,
      action: PayloadAction<'comentary' | 'multiple' | 'single' | 'imagen' | 'archivo'>
    ) => {
      state.evaluation.typeQuestion = action.payload;
    },
    setEvaluationDetail: (state, action: PayloadAction<EvaluationDetail>) => {
      state.evaluation.evaluationDetail = action.payload;
    },
    setEvaluationToQualify: (state, action: PayloadAction<EvaluationDetailQuestion[]>) => {
      state.evaluation.evaluationToQualify = action.payload;
    },
    selectQuestionQualified: (state, action: PayloadAction<number>) => {
      state.evaluation.evaluationToQualify = state.evaluation.evaluationToQualify.map((e) =>
        e.id === action.payload ? { ...e, qualified: true } : e
      );
    },
    setEvaluationDeleteModalOpen: (state, action: PayloadAction<boolean>) => {
      state.evaluation.deleteModalOpen = action.payload;
    },
    setEvaluationIdToDelete: (state, action: PayloadAction<number | null>) => {
      state.evaluation.evaluationIdToDelete = action.payload;
    },
  },
});

export const {
  setEvaluations,
  setQuestions,
  setChoose,
  setQuestionAdded,
  setInfoEvaluation,
  selectQuestionQualified,
  setChooseAnswerEdit,
  setTypeQuestion,
  removeQuestionSaved,
  resetQuestions,
  resetInfoEvaluation,
  setEvaluationDetail,
  setQuestionsPreloaded,
  updateQuestion,
  setEvaluationToQualify,
  setEvaluationQuestionCurrent,
  resetEvaluationQuestionCurrent,
  setEvaluationDeleteModalOpen,
  setEvaluationIdToDelete,
} = evaluationSlice.actions;

export default evaluationSlice.reducer;
