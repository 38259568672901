export const subPermissionKeys = {
  groupKey: 'educationLevel',

  read: 'education-levels-listar-niveles-educativos',
  create: 'education-levels-crear-nivel-educativo',
  edit: 'education-levels-actualizar-nivel-educativo',
  delete: 'education-levels-borrar-nivel-educativo',

  getEducationLevel: 'offers-listar-ofertas',
  getCareer: 'education-levels-obtener-nivel-educativo',

  //
}; // satisfies PermissionsKeys;
