import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Author } from '../../authors/interfaces/authors.interface';

interface TagsState {
  tagToEdit?: Author;
  deleteModalOpen: boolean;
  tagToDelete?: number;
}

const initialState: TagsState = {
  deleteModalOpen: false,
};

const tagsSlice = createSlice({
  name: 'tags',
  initialState,
  reducers: {
    setTagToEdit: (state, action: PayloadAction<Author | undefined>) => {
      state.tagToEdit = action.payload;
    },
    setDeleteModalOpen: (state, action: PayloadAction<boolean>) => {
      state.deleteModalOpen = action.payload;
    },
    openDeleteModalWith: (state, action: PayloadAction<number>) => {
      state.tagToDelete = action.payload;
      state.deleteModalOpen = true;
    },
    resetTagToDelete: (state) => {
      state.tagToDelete = undefined;
    },
  },
});

export const { setTagToEdit, setDeleteModalOpen, openDeleteModalWith, resetTagToDelete } =
  tagsSlice.actions;

export default tagsSlice.reducer;
