import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISearchTable } from '../../../../shared/interfaces/paginatedTable.interface';

export interface StudentMeetingsState {
  meetingsTable: ISearchTable;
}

const initialState: StudentMeetingsState = {
  meetingsTable: { page: 1, perPage: 5, search: '' },
};

const meetingStudentSlice = createSlice({
  name: 'meetingStudent',
  initialState,
  reducers: {
    updateMeetingsTable: (state, action: PayloadAction<ISearchTable>) => {
      state.meetingsTable = action.payload;
    },
  },
});

export const { updateMeetingsTable } = meetingStudentSlice.actions;

export default meetingStudentSlice.reducer;
