import { Box, Grid, IconButton, Tooltip, Typography } from '@material-ui/core';

import { IconLayoutGrid, IconList } from '@tabler/icons';

import MainCard from '../../../shared/components/cards/MainCard';
import DeleteCurriculumDialog from '../components/DeleteCurriculumDialog';
import MeshGridView from '../components/MeshGridView';
import CurriculumsTable from '../components/CurriculumsTable';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { setCurriculumViewMode } from '../slices/meshSlice';

const CurriculumsScreen = () => {
  const dispatch = useAppDispatch();

  const { curriculumSelected, curriculumViewMode: viewMode } = useAppSelector(
    (state) => state.meshs
  );

  const setViewMode = (mode: 'table' | 'grid') => {
    dispatch(setCurriculumViewMode(mode));
  };

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid item sx={{ mb: 3 }} container justifyContent="space-between" alignItems="center">
        <Typography variant="h2">Mallas Curriculares</Typography>

        {/* Switch view */}
        <Box>
          <Tooltip title="Vista de tabla">
            <IconButton onClick={() => setViewMode('table')}>
              <IconList size={20} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Vista de tarjetas">
            <IconButton onClick={() => setViewMode('grid')}>
              <IconLayoutGrid size={20} />
            </IconButton>
          </Tooltip>
        </Box>
      </Grid>

      {viewMode === 'table' && <CurriculumsTable />}
      {viewMode === 'grid' && <MeshGridView />}

      {curriculumSelected && <DeleteCurriculumDialog />}
    </Grid>
  );
};

export default CurriculumsScreen;
