import {
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  useTheme,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { useTableSearch } from '../../../../hooks/useTableSearch';
import SubCard from '../../../shared/components/cards/SubCard';
import DeleteButton from '../../../shared/components/tables/buttons/DeleteButton';
import EditButton from '../../../shared/components/tables/buttons/EditButton';
import CustomCollapsibleTable from '../../../shared/components/tables/CustomCollapsibleTable';
import { FilterArrow } from '../../../shared/components/tables/SearchPaginatedTable';
import { renderErrorOrEmptyRow } from '../../../shared/helpers/render';
import useFormatDate from '../../../shared/hooks/useFormatDate';
import { useGetPosgraduatePlanningCalendarsQuery } from '../slices/posgraduatePlanningCalendarApiSlice';
import {
  setPosgraduatePlanningCalendarDeleteDialogOpen,
  setPosgraduatePlanningCalendarFormDialogOpen,
  setPosgraduatePlanningCalendarIdSelected,
  setPosgraduatePlanningCalendarsList,
  setPosgraduatePlanningCalendarsTable,
} from '../slices/posgraduatePlanningCalendarSlice';
import { useHistory } from 'react-router-dom';
import { IconEye } from '@tabler/icons';
import { setCalendarIdSelected } from '../../academic-planning-postgraduate/slices/acPlanPostgraduateSlice';

const PosgraduatePlanningCalendarsTable = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const {
    posgraduatePlanningCalendarsTable: { page, perPage, search },
  } = useAppSelector((state) => state.posgraduatePlanningCalendars);

  const [filterArrow, setFilterArrow] = useState<FilterArrow>(null!);

  const { formatDate } = useFormatDate();

  const { setPage, setPerPage, setSearch } = useTableSearch({
    page,
    perPage,
    search,
    updateFunction: setPosgraduatePlanningCalendarsTable,
  });

  const { data, isLoading, error, isFetching } = useGetPosgraduatePlanningCalendarsQuery({
    page,
    perPage,
    search,
    filter: true,
    ...(filterArrow && {
      sort: filterArrow.name,
      type_sort: filterArrow.direction,
    }),
  });

  const datafetch =
    data?.data?.map((item, index) => ({
      code: '',
      id: index + ((page - 1) * perPage + 1),
      description: item.pc_description,
      starting_point_planning: item.starting_point_planning,
      pips: item.postgraduate_temporary_plans || [],
      options: { id: item.id },
    })) || [];

  useEffect(() => {
    if (data?.data) {
      dispatch(setPosgraduatePlanningCalendarsList(data.data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleAdd = () => {
    dispatch(setPosgraduatePlanningCalendarFormDialogOpen(true));
  };

  const handleEdit = (id: string) => {
    dispatch(setPosgraduatePlanningCalendarIdSelected(id));
    dispatch(setPosgraduatePlanningCalendarFormDialogOpen(true));
  };

  const handleDelete = (id: string) => {
    dispatch(setPosgraduatePlanningCalendarIdSelected(id));
    dispatch(setPosgraduatePlanningCalendarDeleteDialogOpen(true));
  };

  const handleReview = (id: string, month: number, year: number, calendarId: string) => {
    dispatch(setCalendarIdSelected(calendarId));
    history.push(
      `/planeacion-academica-posgrado/fechas-carrera/${id}/planeacion-carrera?month=${month}&year=${year}`
    );
  };

  const CollapsibleContentRow = (id: number) => {
    return (
      <Box margin={1}>
        <TableContainer>
          <SubCard content={false} sx={{ mb: 2 }}>
            <Table size="small" aria-label="purchases">
              <TableHead>
                <TableRow>
                  <TableCell>Nombre</TableCell>
                  <TableCell align="center">Inicio</TableCell>
                  <TableCell align="center">Fin</TableCell>
                  <TableCell align="center">Estado</TableCell>
                  <TableCell align="center">Opciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {datafetch &&
                  id !== -1 &&
                  datafetch[id].pips.length > 0 &&
                  datafetch?.[id]?.pips?.map((temp) => (
                    <TableRow>
                      <TableCell>{temp?.name_period_temporary}</TableCell>
                      <TableCell align="center">{formatDate(temp?.start_date)}</TableCell>
                      <TableCell align="center">{formatDate(temp?.end_date)}</TableCell>
                      <TableCell align="center">
                        <Chip
                          label={
                            temp?.postgraduate_planning_status?.status_planning ||
                            'Punto de inicio de programa'
                          }
                          style={{
                            backgroundColor:
                              temp?.postgraduate_planning_status?.status_planning === 'pendiente'
                                ? theme.palette.warning.main
                                : temp?.postgraduate_planning_status?.status_planning ===
                                  'ejecutada'
                                ? theme.palette.success.main
                                : theme.palette.info.main,
                            borderColor:
                              temp?.postgraduate_planning_status?.status_planning === 'pendiente'
                                ? theme.palette.warning.main
                                : temp?.postgraduate_planning_status?.status_planning ===
                                  'ejecutada'
                                ? theme.palette.success.main
                                : theme.palette.info.main,
                            color:
                              temp?.postgraduate_planning_status?.status_planning === 'pendiente'
                                ? '#000'
                                : '#fff',
                          }}
                          variant="outlined"
                        />
                      </TableCell>
                      <TableCell align="center">
                        {temp?.postgraduate_planning_status?.status_planning === 'pendiente' ||
                        temp?.postgraduate_planning_status?.status_planning === 'ejecutada' ? (
                          <Tooltip title="Revisar">
                            <IconButton
                              onClick={() =>
                                handleReview(
                                  temp.id,
                                  new Date(temp.start_date).getMonth() + 1,
                                  new Date(temp.start_date).getFullYear(),
                                  temp.planning_calendar_id
                                )
                              }
                            >
                              <IconEye />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          'No disponible'
                        )}
                      </TableCell>
                    </TableRow>
                  ))}

                {
                  datafetch &&
                    id !== -1 &&
                    datafetch?.[id]?.pips?.length === 0 &&
                    renderErrorOrEmptyRow(4)
                  // eslint-disable-next-line react/jsx-curly-newline
                }
                {datafetch && id !== -1 && datafetch[id] === undefined && renderErrorOrEmptyRow(4)}
              </TableBody>
            </Table>
          </SubCard>
        </TableContainer>
      </Box>
    );
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CustomCollapsibleTable
            data={datafetch}
            error={error}
            isLoading={isLoading}
            isFetching={isFetching}
            headers={{
              code: '',
              id: 'N°',
              description: 'Descripción',
              starting_point_planning: 'Punto de inicio de planificación',
              pips: 'Puntos de inicio de programa',
              options: 'Opciones',
            }}
            listFilterArrows={{
              description: 'year',
            }}
            filterArrow={filterArrow}
            setFilterArrow={setFilterArrow}
            keyExtractor={(item) => item.id.toString()}
            numHeader={6}
            setPage={setPage}
            setPerPage={setPerPage}
            setSearch={setSearch}
            total={data?.total || 0}
            page={page}
            perPage={perPage}
            search={search}
            searchPlacehoder="Buscar..."
            ActionButtons={
              <>
                <Tooltip title="Agregar">
                  <Button onClick={handleAdd} variant="contained" color="primary">
                    Agregar
                  </Button>
                </Tooltip>
              </>
            }
            customHeadersCellsProperties={{
              id: {
                align: 'center',
              },
              description: {
                align: 'center',
              },
              starting_point_planning: {
                align: 'center',
              },
              pips: {
                align: 'center',
              },
              options: {
                align: 'center',
              },
            }}
            customDataCellsProperties={{
              id: {
                align: 'center',
              },
              description: {
                align: 'center',
              },
              starting_point_planning: {
                align: 'center',
              },
              pips: {
                align: 'center',
              },
              options: {
                align: 'center',
              },
            }}
            customRenderers={{
              pips: (value) => {
                return value.pips?.length || 0;
              },
              options: (row) => (
                <>
                  <EditButton handleEdit={() => handleEdit(row.options.id)} />
                  <DeleteButton handleDelete={() => handleDelete(row.options.id)} />
                </>
              ),
            }}
            //apply collapsible function, in specific row (optional)
            isCollapsible={true}
            overwriteRowC={0}
            CollapsibleItems={CollapsibleContentRow}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default PosgraduatePlanningCalendarsTable;
