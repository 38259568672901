import {
  FormHelperText,
  ButtonProps,
  TableCell,
  TableRow,
  useTheme,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import { IconTrash } from '@tabler/icons';
import { StudentCourseDetail } from '../../../interfaces/evaluations.interfaces';

interface Props extends ButtonProps {
  student: StudentCourseDetail;
}

const EvaluationStudentsList = ({ student: { student }, onClick }: Props) => {
  const theme = useTheme();

  return (
    <TableRow
      hover
      className="table-row"
      key={`${student?.student_id?.toString()}-${student?.student_full_name!}`}
    >
      <TableCell width="70%" sx={{ cursor: 'pointer' }}>
        {student?.student_full_name!}
        <FormHelperText>{student?.student_email!}</FormHelperText>
      </TableCell>
      <TableCell width="30%" align="center">
        <Tooltip title="Remover del listado">
          <IconButton aria-label="Eliminar" onClick={onClick}>
            <IconTrash size={20} color={theme.palette.error.main} />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

export default EvaluationStudentsList;
