import { useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { IconButton, Grid, useTheme, Typography } from '@material-ui/core';
import { IconArrowNarrowLeft } from '@tabler/icons';
import { useTranslation } from 'react-i18next';

import EditFinancialPeriodForm from '../components/EditFinancialPeriodForm';
import MainCardForm from '../../../shared/components/cards/MainCardForm';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { useLazyGetFinancialPeriodQuery } from '../slices/financialPeriodsApiSlice';
import { setFinancialPeriodList } from '../slices/financialPeriodsSlice';
import SimpleFormOneColumn from '../../../shared/components/forms/skeletons/SimpleFormOneColumn';

const EditPeriodScreen = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();

  const { financialPeriodId } = useParams<{ financialPeriodId: string }>();

  const { currentProfile } = useAppSelector((state) => state.access);
  const { financialPeriodList } = useAppSelector((state) => state.financialPeriods);

  const [getFinancialPeriod, { data, isFetching }] = useLazyGetFinancialPeriodQuery();

  useEffect(() => {
    if (!financialPeriodList.length && !isFetching) {
      getFinancialPeriod({
        profileId: currentProfile?.profileId!,
        fperiodId: +financialPeriodId,
      });
    }
  }, [
    currentProfile?.profileId,
    financialPeriodId,
    financialPeriodList.length,
    getFinancialPeriod,
    isFetching,
  ]);

  useEffect(() => {
    if (data) dispatch(setFinancialPeriodList([data!]));
  }, [data, dispatch]);

  return (
    <MainCardForm>
      <Grid item xs={12}>
        <Grid item container alignItems="center" gap={1} sx={{ cursor: 'pointer' }}>
          <IconButton onClick={() => history.push(`/${location.pathname.split('/')[1]}`)}>
            <IconArrowNarrowLeft size={30} color={theme.palette.text.dark} />
          </IconButton>
          <Typography variant="h2">{t('financial.periods.edit.title')}</Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} md={8} lg={6} sx={{ mt: 2 }}>
        {Boolean(financialPeriodList.length) ? (
          <EditFinancialPeriodForm />
        ) : (
          <SimpleFormOneColumn />
        )}
      </Grid>
    </MainCardForm>
  );
};

export default EditPeriodScreen;
