import { Grid, Typography } from '@material-ui/core';
import { useAppSelector } from '../../../../hooks/useRedux';
import MainCard from '../../../shared/components/cards/MainCard';
import PeriodsTypesTable from '../components/PeriodsTypesTable';
import DeletePeriodTypeDialog from '../components/DeletePeriodTypeDialog';

const PeriodTypesScreen = () => {
  // Verify permission
  const { periodTypeIdToDelete } = useAppSelector((state) => state.periodTypes);

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">Tipos de Períodos</Typography>
      </Grid>

      <PeriodsTypesTable />

      {periodTypeIdToDelete && <DeletePeriodTypeDialog />}
    </Grid>
  );
};

export default PeriodTypesScreen;
