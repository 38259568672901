import * as React from 'react';
import { ChangeEvent, useState } from 'react';
// import { useSelector } from 'react-redux';

// material-ui
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Button,
  // ButtonBase,
  CardMedia,
  Collapse,
  FormHelperText,
  Grid,
  // IconButton,
  // Menu,
  // MenuItem,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  Theme,
  Skeleton,
  InputAdornment,
} from '@material-ui/core';

// third-party
import * as yup from 'yup';
import uniqueId from 'lodash/uniqueId';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';

// project imports
import Comment from './Comment';
import MainCard from './../MainCard';
import AnimateButton from '../../extended/AnimateButton';
import ImageList from '../../extended/ImageList';
import Avatar from '../../extended/Avatar';
// import { DefaultRootStateProps, FormInputProps } from '../../../types';
import { FormInputProps } from '../../../interfaces/material-ui.interfaces';

import { CommentData, PostProps, Reply } from '../../../interfaces/post.interfaces';

// assets
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
// import MoreVertTwoToneIcon from '@material-ui/icons/MoreVertTwoTone';
// import ThumbUpAltTwoToneIcon from '@material-ui/icons/ThumbUpAltTwoTone';
import ChatBubbleTwoToneIcon from '@material-ui/icons/ChatBubbleTwoTone';
import AttachmentRoundedIcon from '@material-ui/icons/AttachmentRounded';
import i18next from 'i18next';
// const avatarImage = requ ire.context('../../.././../assets/images/profile', true);

import { useAppSelector } from '../../../../../hooks/useRedux';
import { useTranslation } from 'react-i18next';
import useFileFormatValidation from '../../../../../hooks/useFileFormatValidation';

const obtainImageByDefault = (name: string | undefined) => {
  return `https://ui-avatars.com/api/?name=${name}&background=0D8ABC&color=fff`;
};

// style constant
const useStyles = makeStyles((theme) => ({
  textSecondary: {
    color: theme.palette.secondary.main,
  },
  avatarRight: {
    ...theme.typography.commonAvatar,
    ...theme.typography.smallAvatar,
    background:
      theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
    color: theme.palette.mode === 'dark' ? theme.palette.dark.light : theme.palette.secondary.dark,
    zIndex: 1,
    transition: 'all .2s ease-in-out',
    '&[aria-controls="menu-list-grow"],&:hover': {
      background: theme.palette.secondary.main,
      color: theme.palette.secondary.light,
    },
  },
  menuItem: {
    marginRight: '14px',
    fontSize: '1.25rem',
  },
  videoMedia: {
    height: '330px',
    [theme.breakpoints.down('lg')]: {
      height: '220px',
    },
  },
  btnSecondary: {
    background: theme.palette.secondary.main,
    '&:hover,&:focus,&:active': {
      background: theme.palette.secondary.dark,
    },
  },
  message: {
    '& > p': {
      ...theme.typography.body1,
      marginBottom: 0,
    },
  },
}));

const validationSchema = yup.object().shape({
  name: yup.string().required(i18next.t('studentVirtualClassroom.forum.requiredComment')),
});

//-----------------------|| COMMENT TEXTFIELD ||-----------------------//

const FormInput = ({
  bug,
  label,
  size,
  fullWidth = true,
  name,
  required,
  ...others
}: FormInputProps) => {
  let isError = false;
  let errorMessage = '';
  if (bug && bug.hasOwnProperty(name)) {
    isError = true;
    errorMessage = bug[name].message;
  }

  return (
    <React.Fragment>
      {/**
       * Controller controp prop error
       * control' is specified more than once, so this usage will be overwritten.
       */}
      <Controller
        as={TextField}
        name={name}
        defaultValue=""
        label={label}
        size={size}
        fullWidth={fullWidth}
        InputLabelProps={{
          className: required ? 'required-label' : '',
          required: required || false,
        }}
        error={isError}
        {...others}
      />
      {errorMessage && (
        <Grid item xs={12}>
          <FormHelperText error>{errorMessage}</FormHelperText>
        </Grid>
      )}
    </React.Fragment>
  );
};

//-----------------------|| SOCIAL PROFILE - POST ||-----------------------//

const Post = ({
  commentAdd,
  commentEdit,
  handleCommentLikes,
  handlePostLikes,
  handleReplayLikes,
  post,
  replyAdd,
  author,
  isLoading,
}: PostProps) => {
  const classes = useStyles();

  const theme = useTheme();

  const { id, data, profile } = post;

  const { userInfo } = useAppSelector((state) => state.auth);

  const { t } = useTranslation();

  // const customization = useSelector((state: DefaultRootStateProps) => state.customization);
  const matchesXS = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const [fileFormatError, setFileFormatError] = React.useState('');

  const { validateFormatFile } = useFileFormatValidation();
  // const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);
  // const handleClick = (event: React.SyntheticEvent) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  const [file, setFile] = useState('');

  const [target, setTarget] = useState<File>(null!);

  const onChangePicture = function (event: ChangeEvent<HTMLInputElement>) {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      const error = validateFormatFile(file, 'image/*');

      if (error !== '') {
        setFileFormatError(error);
        return;
      }

      let reader = new FileReader();
      reader.onload = (e: any) => {
        setFile(e.target.result);
      };
      reader.readAsDataURL(event.target.files[0]);
      setTarget(event.target.files[0]);
    }
  };

  const [openComment, setOpenComment] = React.useState(
    data.comments && data.comments.length > 0 ? true : true
  );
  const handleChangeComment = () => {
    setOpenComment((prev) => !prev);
  };

  let commentsResult:
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>[] = <></>;

  if (data && data.comments) {
    commentsResult = data.comments.map((comment, index) => {
      return (
        <Comment
          postId={id}
          comment={comment}
          commentEdit={commentEdit}
          key={comment.id}
          user={comment.profile}
          replyAdd={replyAdd}
          handleCommentLikes={handleCommentLikes}
          handleReplayLikes={handleReplayLikes}
          currentUser={author ? author : profile}
        />
      );
    });
  }

  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });

  const { handleSubmit, errors, reset } = methods;
  const onSubmit = async (comment: CommentData) => {
    handleChangeComment();
    const commentId = uniqueId('#COMMENT_');
    const comment_: Reply = {
      id: commentId,
      profile: profile,
      data: {
        comment: comment.name,
        imagesFile: target,
        likes: {
          like: false,
          value: 0,
        },
        replies: [],
      },
    };
    commentAdd(id, comment_);
    reset({ name: '' });
  };

  return (
    <MainCard>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Grid container wrap="nowrap" alignItems="center" spacing={1}>
            <Grid item>
              <Avatar alt="User 1" src={obtainImageByDefault(profile.name)}></Avatar>
            </Grid>
            <Grid item xs zeroMinWidth>
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <Typography align="left" variant="h5" component="div">
                    {!isLoading ? profile.name : <Skeleton width={'10vw'} />}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography align="left" variant="caption">
                    <FiberManualRecordIcon
                      sx={{ width: '10px', height: '10px', opacity: 0.5, m: '0 5px' }}
                    />
                    {!isLoading && profile.time}

                    {/* {profile.time} */}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item></Grid>
          </Grid>
        </Grid>

        {/* post - content */}
        <Grid item xs={12}>
          {!isLoading ? (
            <ReactMarkdown
              remarkPlugins={[gfm]}
              children={`## ${data.content} \n  ${data.details}`}
              className={classes.message}
            />
          ) : (
            <Skeleton width={'30vh'} />
          )}
        </Grid>

        {/* post - photo grid */}
        {data && data.images && data.images.length > 0 && (
          <Grid item xs={12}>
            <ImageList itemData={data.images} />
          </Grid>
        )}

        {/* post - video */}
        {data.video && (
          <Grid item xs={12} sx={{ '&.MuiGrid-root': { pt: 2 } }}>
            <CardMedia
              sx={{ borderRadius: '10px' }}
              className={classes.videoMedia}
              component="iframe"
              src={'https://www.youtube.com/embed/' + data.video}
            />
          </Grid>
        )}

        {/* post - comment, likes and replay history */}
        <Grid item xs={12}>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            spacing={2}
            sx={{ mt: 0, color: theme.palette.mode === 'dark' ? 'grey.700' : 'grey.800' }}
          >
            <Grid item>
              <Stack direction="row" spacing={2}>
                {/* <Button
                  variant="text"
                  onClick={() => handlePostLikes(id)}
                  color="inherit"
                  size="small"
                  startIcon={
                    <ThumbUpAltTwoToneIcon
                      color={data && data.likes && data.likes.like ? 'primary' : 'inherit'}
                    />
                  }
                >
                  {data && data.likes && data.likes.value ? data.likes.value : 0}
                  <Typography
                    color="inherit"
                    sx={{ fontWeight: 500, ml: 0.5, display: { xs: 'none', sm: 'block' } }}
                  >
                    likes
                  </Typography>
                </Button> */}
                <Button
                  onClick={handleChangeComment}
                  size="small"
                  variant="text"
                  color="inherit"
                  startIcon={<ChatBubbleTwoToneIcon className={classes.textSecondary} />}
                >
                  {data.comments ? data.comments.length : 0}{' '}
                  {t('studentVirtualClassroom.forum.comments')}
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        {/* add new comment */}
        <Collapse in={openComment} sx={{ width: '100%' }}>
          <Grid item xs={12} sx={{ pt: 2 }}>
            <Collapse in={!!file} sx={{ width: '100%' }}>
              <Grid item margin={2} marginLeft={6}>
                <CardMedia
                  style={{ cursor: 'pointer', height: '10vh', width: '10vh' }}
                  component="img"
                  onClick={() => {
                    setFile('');
                    setTarget(null!);
                  }}
                  src={file ? file : ''}
                  title="image"
                />
              </Grid>
            </Collapse>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2} alignItems="flex-start">
                <Grid item sx={{ display: { xs: 'none', sm: 'block' } }}>
                  <Avatar
                    sx={{ mt: 0.75 }}
                    alt="User 1"
                    src={obtainImageByDefault(
                      `${userInfo.name} ${userInfo?.fullname!.split(' ')[0]}`
                    )}
                    // src={profile.avatar && avatarImage(`./${profile.avatar}`).default}
                    size="xs"
                  ></Avatar>
                </Grid>
                <Grid item xs zeroMinWidth>
                  <FormProvider {...methods}>
                    <FormInput
                      fullWidth
                      name="name"
                      label={t('studentVirtualClassroom.forum.writeAnswer')}
                      size={matchesXS ? 'small' : 'medium'}
                      bug={errors}
                      InputProps={{
                        label: t('studentVirtualClassroom.forum.writeAnswer'),
                        startAdornment: (
                          <label htmlFor={'contained-button-file' + data.id}>
                            <InputAdornment position="start" onChange={onChangePicture}>
                              <input
                                type="file"
                                style={{ display: 'none' }}
                                id={'contained-button-file' + data.id}
                              />

                              <AttachmentRoundedIcon
                                fontSize="small"
                                sx={{ color: `${!!file ? theme.palette.primary.main : 'inherit'}` }}
                              />
                            </InputAdornment>
                          </label>
                        ),
                      }}
                    />
                  </FormProvider>
                </Grid>
                <Grid item>
                  {/* comentar foro padre */}
                  <AnimateButton>
                    <Button
                      type="submit"
                      variant="contained"
                      // className={classes.btnSecondary}
                      size={matchesXS ? 'small' : 'large'}
                      color={'primary'}
                      sx={{ mt: 0.5 }}
                    >
                      {t('studentVirtualClassroom.forum.comment')}
                    </Button>
                  </AnimateButton>
                </Grid>
                {fileFormatError !== '' && (
                  <Grid item xs={12}>
                    <FormHelperText error>{fileFormatError}</FormHelperText>
                  </Grid>
                )}
              </Grid>
            </form>
          </Grid>
        </Collapse>
        {commentsResult}
      </Grid>
    </MainCard>
  );
};

export default Post;
