import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISearchTable } from '../../../../shared/interfaces/paginatedTable.interface';
import { Meeting } from '../interfaces/meetings.interface';

export interface TeacherMeetingsState {
  meetingsTable: ISearchTable;
  meetingList: Meeting[];
}

const initialState: TeacherMeetingsState = {
  meetingsTable: { page: 1, perPage: 5, search: '' },
  meetingList: [],
};

const meetingTeacherSlice = createSlice({
  name: 'meetingTeacher',
  initialState,
  reducers: {
    updateMeetingsTable: (state, action: PayloadAction<ISearchTable>) => {
      state.meetingsTable = action.payload;
    },
    setMeetingsList: (state, action: PayloadAction<Meeting[]>) => {
      state.meetingList = action.payload;
    },
  },
});

export const { updateMeetingsTable, setMeetingsList } = meetingTeacherSlice.actions;

export default meetingTeacherSlice.reducer;
