import { mainApi } from '../../../api/mainApi';
import { middlewareApi } from '../../middleware/middlewareApi';
import { BirthdayEvent } from '../interfaces/birthday.interfaces';

export const birthdayApi = middlewareApi.injectEndpoints({
  //tagTypes: ['','',''],
  endpoints: (builder) => ({
    /**
     * @GET all collaborator birthdays
     */
    getCollaboratorBirthdays: builder.query<BirthdayEvent[], { profileId: number; month?: number }>(
      {
        queryFn: async ({ profileId, month }) => {
          try {
            const { data } = await mainApi.get<BirthdayEvent[]>(`/collaborators/birth/month`, {
              params: {
                user_profile_id: profileId,
                month: month,
              },
            });
            return { data };
          } catch (error: any) {
            return { error };
          }
        },
      }
    ),
  }),
});
export const { useGetCollaboratorBirthdaysQuery } = birthdayApi;
