import { Box, Grid, Tab, Tabs, Typography } from '@material-ui/core';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useAppSelector } from '../../../../hooks/useRedux';
import MainCard from '../../../shared/components/cards/MainCard';
import { useStyles } from '../constants/styles';
import { obtainCareerDefault } from '../helpers';
import AcademicCareerTable from './AcademicCareerTable';
// import AccompanimentAcademic from './AccompanimentAcademic';
import HistoryCalificationTable from './HistoryCalificationTable';
import { useTranslation } from 'react-i18next';
import SendAnnouncementAccompanimentAcademic from './SendAnnouncementAccompanimentAcademic';
import Accompainment from './Accompainment';

const HistoryCalificationIndex = () => {
  const { t } = useTranslation();

  const [value, setValue] = useState(0);

  const { currentRecordStudent } = useAppSelector((state) => state.access);
  const { studentRecordReference } = useAppSelector((state) => state.auth);
  const { openSendAnnouncementModal } = useAppSelector((state) => state.academicCounseling);
  const studentCareer =
    studentRecordReference &&
    studentRecordReference.find((value) => value.id === currentRecordStudent?.id);

  const classes = useStyles();

  const handleChange = (event: React.SyntheticEvent | null, newValue: number) => {
    setValue(newValue);
  };

  const TabPanel = (props: { children: React.ReactElement; value: number; index: number }) => {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={0}>{children}</Box>}
      </div>
    );
  };

  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  return (
    <>
      <Grid component={MainCard}>
        <Grid>
          <Typography variant="h2">{t('students.academic_counseling.title')}</Typography>
          <Grid style={{ marginTop: 10 }}>
            <Typography>{t('students.academic_counseling.description')}</Typography>
          </Grid>
          <Grid item xs={12} sx={{ mt: 2, width: 1 }}>
            <Box width="100%" sx={{ maxWidth: 2000, width: 1 }} style={{ marginTop: 30 }}>
              <Tabs
                value={value}
                indicatorColor="primary"
                className={classes.accountTab}
                aria-label="Consejería Académica"
                variant="fullWidth"
                onChange={handleChange}
              >
                <Tab
                  component={RouterLink}
                  to="#"
                  label={
                    studentCareer ? studentCareer.education_level.edu_name : obtainCareerDefault()
                  }
                  {...a11yProps(0)}
                />
                <Tab
                  component={RouterLink}
                  to="#"
                  label={t('students.academic_counseling.student_score_history')}
                  {...a11yProps(1)}
                />
                <Tab
                  component={RouterLink}
                  to="#"
                  label={t('students.academic_counseling.academic_monitoring')}
                  {...a11yProps(2)}
                />
              </Tabs>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid component={MainCard} sx={{ padding: 2 }} style={{ marginTop: 20 }}>
        <TabPanel value={value} index={0}>
          <AcademicCareerTable />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <HistoryCalificationTable />
        </TabPanel>
        <TabPanel value={value} index={2}>
          {/* <AccompanimentAcademic /> */}
          {/*  */}
          <Accompainment />
        </TabPanel>
      </Grid>

      {openSendAnnouncementModal && <SendAnnouncementAccompanimentAcademic />}
    </>
  );
};

export default HistoryCalificationIndex;
