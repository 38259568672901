import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import MainCard from '../../../shared/components/cards/MainCard';
import ProjectInfo from '../components/ProjectInfo';
import BackButton from '../../../shared/components/buttons/BackButton';

const ProjectInfoScreen = () => {
  const { t } = useTranslation();

  return (
    <Grid component={MainCard} sx={{ padding: 2, minHeight: '500px' }}>
      <Grid sx={{ mb: 3, display: 'flex', alignItems: 'center' }}>
        <BackButton />
        <Typography variant="h2">{t('Información de proyecto')}</Typography>
      </Grid>
      <ProjectInfo />
    </Grid>
  );
};

export default ProjectInfoScreen;
