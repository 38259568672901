import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
  useTheme,
} from '@material-ui/core';
import { IconClipboardList, IconFileSearch } from '@tabler/icons';
import { Link, useRouteMatch } from 'react-router-dom';
import { FC, useEffect, useState } from 'react';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import DateRangePicker, { DateRange } from '@material-ui/lab/DateRangePicker';
import { format } from 'date-fns';
import esLocale from 'date-fns/locale/es';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';

import { useAppSelector } from '../../../../hooks/useRedux';
import SearchPaginatedTable, {
  FilterArrow,
} from '../../../shared/components/tables/SearchPaginatedTable';
import {
  useGetApplicationsHomologationsQuery,
  useGetExtAppPdfByIdQuery,
} from '../slices/homologationsApiSlice';
import CustomPopper from '../../../shared/components/popper/CustomPopper';
import InputLabel from '../../../shared/components/forms/InputLabel';
import TextFieldFetching from '../../../shared/components/TextFieldFetching';
import {
  useGetAcademicOfferingQuery,
  useGetAvailablesOffersQuery,
} from '../../academic-offerings/slices/academicOfferingsApiSlice';
import {
  AcademicOffering,
  EducationLevel,
} from '../../academic-offerings/interfaces/academicOfferings.interfaces';
import { ExternalHomologation } from '../interfaces/homologations.interface';
import Chip from '../../../shared/components/extended/Chip';
import { useTranslation } from 'react-i18next';
import useSharedGuard from '../../../../hooks/useSharedGuard';
import { subPermissionKeys3 } from '../constants/permissions';
// const downloader = require('downloadjs');

const ExternalHomologationsTable = () => {
  const { t } = useTranslation();

  const theme = useTheme();
  //   const dispatch = useAppDispatch();

  const { path } = useRouteMatch();

  const { currentProfile } = useAppSelector((state) => state.access);

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [perPage, setPerPage] = useState(5);
  const [value, setValue] = useState<DateRange<Date>>([null, null]);
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();
  const [filterStatus, setFilterStatus] = useState<string | undefined | null>(null);
  const [status, setStatus] = useState<string>('');

  const [offer, setOffer] = useState<AcademicOffering | null>();
  const [eduLvl, setEduLvl] = useState<EducationLevel | null>();

  const [filterArrow, setFilterArrow] = useState<FilterArrow>(null!);

  const { hasAccess } = useSharedGuard(subPermissionKeys3);
  const { data, isLoading, isFetching, error } = useGetApplicationsHomologationsQuery(
    {
      profileId: currentProfile?.profileId!,
      acronym: 'HOMEXT',
      page,
      perPage,
      search,
      ...(filterArrow && { sort: filterArrow.name, type_sort: filterArrow.direction }),
      ...(startDate && endDate && { startDate: startDate }),
      ...(endDate && startDate && { endDate: endDate }),
      ...(status && { status }),
    },
    { refetchOnMountOrArgChange: 30 }
  );

  const { data: offersAvailables = [], isFetching: isFetchingOA } = useGetAvailablesOffersQuery({
    profileId: currentProfile?.profileId!,
  });
  const { data: offersById, isFetching: isFetchingOBYA } = useGetAcademicOfferingQuery(
    {
      profileId: currentProfile?.profileId!,
      academicOfferingId: offer ? offer.id : -1,
    },
    { skip: !Boolean(offer) }
  );

  useEffect(() => {
    setPage(1);
  }, [perPage, search]);

  const datafetch = data?.data!.map((value, index) => {
    const newValue = value as ExternalHomologation;
    // const person = newValue.applicationDetails['person' as any] as unknown;
    return {
      id: index + ((page - 1) * perPage + 1),
      app_code: newValue.app_code,
      name: newValue.user,
      // stud_name: newValue.cam_name,
      // detail: {
      //   per_max_matter_enrollment: newValue?.per_max_matter_enrollment,
      //   per_min_matter_enrollment: newValue?.per_min_matter_enrollment,
      //   per_num_fees: newValue?.per_num_fees,
      //   per_pay_enrollment: newValue?.per_pay_enrollment,
      // },
      status: newValue.current_status,
      options: newValue,
    };
  });

  // const handleDelete = function (internalId: number) {
  //   // dispatch(openDeleteModalWith(periodId));
  // };

  //date filter handlers
  const handleFilter = () => {
    value[0] && !isNaN(value[0].getTime()) && setStartDate(format(value[0], 'yyyy-MM-dd'));
    value[1] && !isNaN(value[1].getTime()) && setEndDate(format(value[1], 'yyyy-MM-dd'));
    if (filterStatus) setStatus(filterStatus);
  };
  const handleClearFilter = () => {
    setValue([null, null]);
    setStartDate('');
    setEndDate('');

    setFilterStatus(null);
    setStatus('');
  };
  const onChangeDates = (newDatesValues: DateRange<Date>) => {
    setValue(newDatesValues);
  };

  //filter

  const [anchorElClassStatus, setAnchorElClassStatus] = useState<null | HTMLElement>(null);

  const openClassStatus = Boolean(anchorElClassStatus);

  const handleClickFilterClassStatus = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElClassStatus(event.currentTarget);
  };

  const handleCloseFilterClassStatus = (filterStatus: string | undefined | null) => {
    setAnchorElClassStatus(null);
    setFilterStatus(filterStatus);
  };

  return (
    <SearchPaginatedTable
      data={datafetch!}
      headers={{
        id: 'N°',
        app_code: t('academic.homologation.external.code'),
        name: t('academic.homologation.external.student'),
        // detail: 'Detalle',
        status: t('academic.homologation.external.status'),
        options: t('academic.homologation.external.options'),
      }}
      keyExtractor={(item) => item.options.id.toString()}
      searchPlacehoder={t('academic.homologation.external.search')}
      listFilterArrows={{
        app_code: 'app_code',
        name: 'p.pers_full_name',
        status: 'status.st_name',
      }}
      filterArrow={filterArrow}
      setFilterArrow={setFilterArrow}
      isLoading={isLoading}
      isFetching={isFetching}
      error={error}
      perPage={perPage}
      setPerPage={setPerPage}
      total={data?.total || 0}
      page={page}
      setPage={setPage}
      numHeader={5}
      search={search}
      setSearch={setSearch}
      customRenderers={{
        // name: (row) => <>{row.name}</>,
        status: (row) => {
          const externalApp = row.options as ExternalHomologation;
          // const externalAppDetails = externalApp.applicationDetails[3]?.value as ValueElement[];

          let label = row.status;
          if (row.status === 'Enviado' && Boolean(externalApp && externalApp.matters)) {
            label = t('academic.homologation.external.in_process');
          }
          return (
            <Chip
              label={label}
              size="small"
              chipcolor={
                label === t('academic.homologation.external.in_process') ? 'warning' : 'success'
              }
            />
          );
        },
        // detail: ({ detail }) => (
        //   <Grid container item direction="column" gap={1}>
        //     <Typography>Máximo materias: {detail.per_max_matter_enrollment}</Typography>
        //     <Typography>Mínimo materias: {detail.per_min_matter_enrollment}</Typography>
        //     <Typography>Cuota/matrícula : {detail.per_pay_enrollment}</Typography>
        //     <Typography>Número cuotas : {detail.per_num_fees}</Typography>
        //   </Grid>
        // ),
        options: (row) => {
          const externalApp = row.options as ExternalHomologation;
          // const externalAppDetails =
          //   (externalApp.applicationDetails[3]?.value as ValueElement[]) || [];
          return (
            <>
              {hasAccess('read') && externalApp.hasFile && (
                <Link to={`${path}/${row.options.id}/editar-he`}>
                  <Tooltip title={t('academic.homologation.external.view_app')}>
                    <IconButton
                      aria-label={t('academic.homologation.external.view_app')}
                      color="default"
                    >
                      <IconClipboardList size={20} color={theme.palette.primary.main} />
                    </IconButton>
                  </Tooltip>
                </Link>
              )}
              {hasAccess('read') && Boolean(externalApp && externalApp.matters) && (
                <DownloadPdf appId={row.options.id} profile={currentProfile?.profileId!} />
              )}

              {!hasAccess('read') && t('academic.homologation.external.none')}
            </>
          );
        },
      }}
      customHeadersCellsProperties={{
        options: {
          align: 'center',
        },
      }}
      customDataCellsProperties={{
        id: {
          align: 'center',
        },
        status: {
          align: 'center',
        },
        options: {
          align: 'center',
        },
      }}
      ActionButtons={
        <>
          <CustomPopper onClickAwayNotClose>
            <Grid
              container
              p={1}
              pt={2}
              sx={{ maxWidth: { xs: '65vw', sm: '50vw', md: '40vw', lg: '25vw' } }}
            >
              <Grid item xs={12}>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                  <DateRangePicker
                    calendars={1}
                    startText={null}
                    endText={null}
                    value={value}
                    onChange={onChangeDates}
                    PopperProps={{ style: { ...{ left: '-400px' } } }}
                    renderInput={(startProps, endProps) => (
                      <Grid container spacing={1} alignItems="center">
                        <Grid item xs={12}>
                          <Grid item xs={12} sx={{ pt: { xs: 2, sm: '0 !important' } }}>
                            <InputLabel sx={{ textAlign: { xs: 'left', sm: 'right' } }}>
                              {t('academic.homologation.external.start_date')}
                            </InputLabel>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField fullWidth {...startProps} helperText="" value={value[0]} />
                          </Grid>
                        </Grid>

                        <Grid item xs={12}>
                          <Grid item xs={12} sx={{ pt: { xs: 2, sm: '0 !important' } }}>
                            <InputLabel sx={{ textAlign: { xs: 'left', sm: 'right' } }}>
                              {t('academic.homologation.external.end_date')}
                            </InputLabel>
                          </Grid>

                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              {...endProps}
                              helperText=""
                              value={value[1]}
                              // error={Boolean(errors.endDate && touched.endDate)}
                              // {...getFieldProps('endDate')}
                            />
                            {/* <FormHelperText>En esta fecha la etapa finalizará</FormHelperText>
                        {errors.endDate && touched.endDate && (
                          <FormHelperText error>{errors.endDate}</FormHelperText>
                        )} */}
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12} mt={2} sx={{ textAlign: 'right' }}>
                <InputLabel>{t('academic.homologation.external.filters')}</InputLabel>
              </Grid>
              <Grid item xs={12} border="1px solid #eee" borderRadius="12px" p={1}>
                <Grid item xs={12}>
                  <InputLabel>{t('academic.homologation.external.filters.edu_level')}</InputLabel>
                  {isFetchingOA && <TextFieldFetching />}
                  {!isFetchingOA && (
                    <Autocomplete
                      options={offersAvailables}
                      noOptionsText={t(
                        'academic.homologation.external.filters.academic_unit.no_data'
                      )}
                      loadingText={t(
                        'academic.homologation.external.filters.academic_unit.loading'
                      )}
                      getOptionLabel={(option) => option.off_name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={t(
                            'academic.homologation.external.filters.academic_unit.placeholder'
                          )}
                        />
                      )}
                      value={offersAvailables.find((dt) => dt.id === offer?.id) || null}
                      onChange={(_, value) => {
                        setOffer(value || null);
                      }}
                      fullWidth
                      clearIcon={null}
                    />
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    mt: 2,
                  }}
                >
                  <InputLabel>
                    {t('academic.homologation.external.filters.academic_unit')}
                  </InputLabel>
                  {isFetchingOBYA && <TextFieldFetching />}
                  {!isFetchingOBYA && (
                    <Autocomplete
                      options={offersById ? offersById.education_levels : []}
                      noOptionsText={t('academic.homologation.external.filters.edu_level.no_data')}
                      loadingText={t('academic.homologation.external.filters.edu_level.loading')}
                      getOptionLabel={(option) => option.edu_name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={t(
                            'academic.homologation.external.filters.edu_level.placeholder'
                          )}
                        />
                      )}
                      value={
                        offersById
                          ? offersById.education_levels.find((dt) => dt.id === eduLvl?.id) || null
                          : null
                      }
                      onChange={(_, value) => {
                        setEduLvl(value || null);
                      }}
                      fullWidth
                      clearIcon={null}
                    />
                  )}
                </Grid>
              </Grid>

              <Grid container justifyContent="flex-end" mt={1}>
                <Grid item xs={12}>
                  <InputLabel
                    // className={classes.inputLabel}
                    sx={{ textAlign: { xs: 'left', sm: 'right' } }}
                  >
                    {t('academic.homologation.external.app_status')}
                  </InputLabel>
                </Grid>
                {/* {(isFetching || !Boolean(Object.entries(statusMemorized).length)) && (
                      <Skeleton variant="rectangular" />
                    )} */}
                {/* {!isFetching && Boolean(typeof statusMemorized === 'object') && ( */}
                <Grid item xs="auto">
                  <Box
                    component={Button}
                    size="large"
                    sx={{
                      color: theme.palette.grey[900],
                      backgroundColor: theme.palette.primary.light,
                      ':hover': { bgcolor: theme.palette.primary.light },
                      px: 2,
                      borderRadius: 4,
                      mx: 1,
                    }}
                    endIcon={<ExpandMoreRoundedIcon />}
                    onClick={handleClickFilterClassStatus}
                  >
                    {/* {filterStatus && Boolean(dataMemorized.length)
                          ? dataMemorized.find((dt) => dt.current_status === filterStatus)!
                              .current_status
                          : 'General'} */}
                    {filterStatus || 'General'}
                  </Box>
                  <Menu
                    anchorEl={anchorElClassStatus}
                    keepMounted
                    open={openClassStatus}
                    onClose={() => handleCloseFilterClassStatus(filterStatus?.toString())}
                    variant="selectedMenu"
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                  >
                    <MenuItem onClick={() => handleCloseFilterClassStatus(null)}>General</MenuItem>
                    <MenuItem onClick={() => handleCloseFilterClassStatus('Enviado')}>
                      {t('academic.homologation.external.sent')}
                    </MenuItem>
                    {/* {Object.keys(statusMemorized).map((key, index) => {
                          return (
                            <MenuItem
                              key={`search-by-type-${key}`}
                              onClick={() => handleCloseFilterClassStatus(`${key}`)}
                            >
                              {key}
                            </MenuItem>
                          );
                        })} */}
                    {/* {classroomTypes.map((classroomType) => {
                        return (
                          <MenuItem
                            key={`search-by-type-${classroomType.id}`}
                            onClick={() => handleCloseFilterClassStatus(`${classroomType.id}`)}
                          >
                            {classroomType.clt_name}
                          </MenuItem>
                        );
                      })} */}
                  </Menu>
                </Grid>
                {/* )} */}
              </Grid>

              <Grid container mt={1} justifyContent={'flex-end'} spacing={1}>
                <Grid item xs={'auto'}>
                  <Button variant={'outlined'} onClick={handleClearFilter}>
                    {t('academic.homologation.external.reset_filter')}
                  </Button>
                </Grid>
                <Grid item xs={'auto'}>
                  <Button variant={'outlined'} onClick={handleFilter}>
                    {t('academic.homologation.external.filter')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </CustomPopper>
        </>
      }
    />
  );
};

export default ExternalHomologationsTable;

interface DownloadProps {
  appId: number;
  profile: number;
}
export const DownloadPdf: FC<DownloadProps> = (props) => {
  const { appId, profile } = props;
  const [downloadPdf, setDownloadPdf] = useState<boolean>(false);

  const {
    data: pdfData,
    isLoading: isLoadingPDFA,
    isFetching: isFetchingPDFA,
    refetch,
    // request,
  } = useGetExtAppPdfByIdQuery(
    {
      profileId: profile,
      ApplicationHomId: appId,
    },
    { skip: !downloadPdf }
  );

  useEffect(() => {
    if (!isLoadingPDFA && pdfData) {
      // const content = request.headers['content-type'];
      // downloader(pdfData, 'prueba.pdf', 'application/pdf');
      const file = new Blob([pdfData], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      // setDownloadPdf(false);
    }
  }, [isLoadingPDFA, pdfData, refetch]);

  const handleDownload = () => {
    if (!Boolean(pdfData)) {
      setDownloadPdf(true);
      return;
    }
    // refetch();
    const file = new Blob([pdfData], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  };

  return (
    <>
      <Tooltip title={'ver Solicitud pdf'}>
        <IconButton
          aria-label="ver Solicitud pdf"
          color="default"
          onClick={handleDownload}
          disabled={isLoadingPDFA || isFetchingPDFA}
        >
          {!isLoadingPDFA && !isFetchingPDFA && <IconFileSearch size={20} />}

          {(isLoadingPDFA || isFetchingPDFA) && <CircularProgress size={20} />}
        </IconButton>
      </Tooltip>
    </>
  );
};
