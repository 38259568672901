import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';

import Typography from '@material-ui/core/Typography';
import { AssingStudentTask } from '../../interfaces/studentsAssigns.interfaces';
import { useDeleteStudentByTaskMutation } from '../../../../../slices/InvestigationLinesApiSlice';
import LoadingButton from '../../../../../../../shared/components/buttons/LoadingButton';

interface Props {
  studentTask: AssingStudentTask;
  // handleEdit: (id: number) => void;
}

const StudentAssignCard = ({ studentTask }: Props) => {
  const handleDelete = (id: string) => {
    deleteStudent({ studentProjectResearchId: id });
  };

  const [deleteStudent, { isLoading }] = useDeleteStudentByTaskMutation();

  return (
    <Grid item xs={12} mt={2} px={1}>
      <Paper elevation={2} sx={{ width: '100%', padding: '5px' }}>
        <Grid container justifyContent="space-between" alignItems="center" p={1} spacing={1}>
          <Grid item>
            <Typography variant="subtitle1" color="initial">
              {studentTask.student_name}
            </Typography>
            <Typography variant="subtitle2" color="initial">
              {studentTask.start_date} - {studentTask.end_date}
            </Typography>
            <Typography variant="subtitle2" color="initial">
              Horas: {studentTask.number_hours}
            </Typography>
          </Grid>
          <Grid item>
            <Tooltip title={'Eliminar estudiante'}>
              <LoadingButton
                isLoading={isLoading}
                onClick={() => handleDelete(studentTask.id)}
                color={'error'}
                variant="text"
              >
                Eliminar
              </LoadingButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default StudentAssignCard;
