import { TextField } from '@material-ui/core';
import {
  StyledTableCell,
  StyledTableRow,
} from '../../../../teachers/virtual-classroom/califications/components/CalificationstTable';
import { Distribution } from '../../../../teachers/virtual-classroom/interfaces/modelCalification.interfaces';
import { StudentCalifications } from '../../../../teachers/virtual-classroom/interfaces/studentCalification.interfaces';
import { useTranslation } from 'react-i18next';

interface Props {
  columns: string[];
  row: any[];
  distributions: { [key: string]: Distribution };
  setShowModal: React.Dispatch<
    React.SetStateAction<{
      open: boolean;
      id: string | null;
      name: string | null;
    }>
  >;
}

//
const CustomRow = ({ columns, row, distributions, setShowModal }: Props) => {
  const { t } = useTranslation();

  const rowsRender = row.map((r, index) => {
    return (
      <StyledTableRow key={index.toString()}>
        {columns.map((k) => {
          //@ts-ignore
          return k === 'name' ? (
            <StyledTableCell
              key={`${k}`}
              sx={{
                cursor: 'pointer',
              }}
            >
              {t(r[`${k}`].fullname)}
            </StyledTableCell>
          ) : k.split('-')[0] === 'res' ? (
            <StyledTableCell
              align="center"
              key={`${k}`}
              sx={{
                cursor: 'pointer',
              }}
            >
              {r[`${k}`] ? Number(r[`${k}`].value).toFixed(2) : '--'}
            </StyledTableCell>
          ) : k.split('-')[0] === 'nfin' ? (
            <StyledTableCell align="center" key={`${k}`}>
              {r[`${k}`] ? Number(r[`${k}`].value).toFixed(2) : '--'}
            </StyledTableCell>
          ) : (
            <StyledTableCell key={k}>
              <TextField
                variant="standard"
                inputProps={{ style: { textAlign: 'center' } }}
                InputProps={{ readOnly: true }}
                value={(r[`${k}`] as StudentCalifications)?.value || '--'}
                onClick={() => {
                  if ((r[`${k}`] as StudentCalifications).value) {
                    setShowModal({
                      open: true,
                      id: r[`${k}`]?.supply.type_supply_id || null,
                      name: r[`${k}`]?.supply.name || null,
                    });
                  }
                }}
              />
            </StyledTableCell>
          );
        })}
      </StyledTableRow>
    );
  });

  return <>{rowsRender}</>;
};

export default CustomRow;
