import React, { useEffect } from 'react';
import { useAppDispatch } from '../../../../../hooks/useRedux';
import MainCard from '../../../../shared/components/cards/MainCard';
import { setResetStep } from '../../slices/virtualClassroomTeacherSlice';
import CreateQuestionOptions from '../components/options-answer/create/CreateQuestionOptions';
// import CourseDetailScreen from "../../header/CourseDetailScreen"
//import EvaluationCreateIndex from '../components/tabs/EvaluationsCreateIndex';

const EvaluationCreateScreen = function () {
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(setResetStep());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      {/* <CourseDetailScreen/> */}
      <MainCard
        sx={{
          borderTopLeftRadius: '0px',
          borderTopRightRadius: '0px',
        }}
      >
        <CreateQuestionOptions />
      </MainCard>
    </React.Fragment>
  );
};

export default EvaluationCreateScreen;
