import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import GoBackBtnTitle from '../../../../shared/components/buttons/GoBackBtnTitle';
import MainCard from '../../../../shared/components/cards/MainCard';
import ForumContent from '../../../../students/virtual-classroom/forum/components/ForumContent';

function DetailForo() {
  const { foroId } = useParams<{ foroId: string }>();
  const { t } = useTranslation();

  console.log(foroId);

  return (
    <Grid
      component={MainCard}
      sx={{
        borderTopLeftRadius: '0px',
        borderTopRightRadius: '0px',
      }}
    >
      <Grid item xs={12}>
        <Grid sx={{ mb: 3 }}>
          <GoBackBtnTitle title={t('studentVirtualClassroom.forum.forum')} />
        </Grid>
      </Grid>
      {/*<ForumCollapseList />*/}
      <ForumContent goToForumView={() => {}} forumId={Number(foroId!)} />
    </Grid>
  );
}

export default DetailForo;
