import MainCard from '../../../../shared/components/cards/MainCard';
import img from '../../../../../assets/images_optimized/av-student-no-content_optimized.png';
import { Button, Grid, Typography } from '@material-ui/core';
import { IconTableExport, IconTableImport } from '@tabler/icons';
import { useTranslation } from 'react-i18next';

import { Link, useRouteMatch } from 'react-router-dom';
function BannerMaterials() {
  const { path } = useRouteMatch();
  const { t } = useTranslation();

  return (
    <MainCard
      sx={{
        borderTopLeftRadius: '0px',
        borderTopRightRadius: '0px',
      }}
    >
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">{t('teacherVirtualClassroom.materials.title')}</Typography>
      </Grid>
      <Grid
        container
        alignItems={'center'}
        component={MainCard}
        justifyContent="center"
        minHeight="50vh"
        sx={{
          borderTopLeftRadius: '0px',
          borderTopRightRadius: '0px',
        }}
      >
        <Grid container alignItems={'center'} justifyContent={'center'}>
          <Grid item md={4}>
            <img width={'100%'} src={img} alt="" />
          </Grid>
          <Grid mb={'40px'} item xs={12}>
            <Typography variant="h3" color="primary" textAlign={'center'}>
              {t('teacherVirtualClassroom.materials.mes_banner')}
            </Typography>
          </Grid>

          <Link style={{ textDecoration: 'none', color: 'blue' }} to={`${path}/crear`}>
            <Button sx={{ mr: '15px' }} variant="outlined" startIcon={<IconTableExport />}>
              {t('teacherVirtualClassroom.materials.create')}
            </Button>
          </Link>

          <Link style={{ textDecoration: 'none', color: 'white' }} to={`${path}/importar`}>
            <Button variant="contained" startIcon={<IconTableImport />}>
              {t('teacherVirtualClassroom.materials.import')}
            </Button>
          </Link>
        </Grid>
      </Grid>
    </MainCard>
  );
}

export default BannerMaterials;
