import { statusHistoryAcademic } from '../constants/';
import { useState } from 'react';
import { DataSearchHistoryAcademic } from '../interfaces/data-search.interfaces';
import OmniChip from '../../../shared/components/badges/OmniChip';
import { useGetStudentHistoryCalificationQuery } from '../slices/academicCounselingApi';
import HistoryTable from './tables/HistoryTable';
import { renderSkeletonTable } from '../../../shared/helpers/render';
import { useAppSelector } from '../../../../hooks/useRedux';
import { useTranslation } from 'react-i18next';

const HistoryCalificationTable = () => {
  const { t } = useTranslation();

  const [page, setPage] = useState(1);

  const [perPage, setPerPage] = useState(5);

  const [switchAllCourses, setSwitchAllCourses] = useState<boolean>(false);

  const DataSearchBlank: DataSearchHistoryAcademic = {
    period: 0,
    status: 0,
    year: '',
  };

  const { currentProfile, currentStudent, currentRecordStudent } = useAppSelector(
    (state) => state.access
  );

  const { data, isLoading, error, isFetching } = useGetStudentHistoryCalificationQuery({
    profileId: currentProfile?.profileId!,
    educationLevel: Number(currentRecordStudent?.education_level_id!),
    student: currentStudent?.id!,
    courses: switchAllCourses ? 'all' : '',
  });

  const dataFetch = data?.map((value, index) => ({
    id: index + (page - 1) * perPage + 1,
    year: value.year,
    period: value.per_name,
    matter: value.matter_rename || value.mat_name,
    teacher: value.collaborator_name || '',
    user: value.user_full_name || '',
    final_note: value.final_note,
    num_fouls: value.num_fouls,
    status_matter: {
      period_id: value.period_id,
      approval_status: value.approval_status,
      status_matter: value.status_matter,
    },
  }));

  const [dataSearch, setDataSearch] = useState<DataSearchHistoryAcademic>(DataSearchBlank);

  const sendDataOrFilter = function () {
    if (dataSearch.year !== '' || dataSearch.status !== 0 || dataSearch.period !== 0) {
      return dataFetch
        ?.filter(
          (value) =>
            (dataSearch.year ? value.year === dataSearch.year : true) &&
            (dataSearch.status
              ? value.status_matter.approval_status === dataSearch.status.toString()
              : true) &&
            (dataSearch.period
              ? value.status_matter.period_id === dataSearch.period.toString()
              : true)
        )!
        .sort((a, b) => Number(b.year) - Number(a.year))!;
    }
    return dataFetch!;
  };

  return (
    <>
      {isLoading ? (
        renderSkeletonTable(4, 6)
      ) : (
        <HistoryTable
          data={sendDataOrFilter()}
          headers={{
            id: 'N°',
            year: t('students.academic_counseling.year'),
            period: t('students.academic_counseling.period'),
            matter: t('students.academic_counseling.subject'),
            teacher: t('students.academic_counseling.teacher'),
            user: t('students.academic_counseling.registered_by'),
            final_note: t('students.academic_counseling.grade'),
            num_fouls: t('students.academic_counseling.absences'),
            status_matter: t('students.academic_counseling.status'),
          }}
          keyExtractor={(item) => item.id.toString()}
          isLoading={isLoading}
          isFetching={isFetching}
          error={error}
          perPage={perPage}
          setPerPage={setPerPage}
          page={page}
          setPage={setPage}
          numHeader={7}
          dataSearch={dataSearch}
          setDataSearch={setDataSearch}
          switchChecked={switchAllCourses}
          setSwitchChecked={setSwitchAllCourses}
          customRenderers={{
            status_matter: (row) => (
              <OmniChip
                variant={0}
                id={
                  statusHistoryAcademic.find(
                    (e) => e.id === Number(row.status_matter.approval_status)
                  )?.id || 0
                }
                statusArray={statusHistoryAcademic}
              />
            ),
          }}
          customHeadersCellsProperties={{
            status_matter: {
              align: 'center',
            },
            num_fouls: {
              align: 'center',
            },
          }}
          customDataCellsProperties={{
            status_matter: {
              align: 'center',
            },
            num_fouls: {
              align: 'center',
            },
          }}
        />
      )}
    </>
  );
};

export default HistoryCalificationTable;
