import {
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
  Autocomplete,
  Button,
  CircularProgress,
  Alert,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../hooks/useRedux';
// import DragArea from '../../../students/virtual-classroom/components/DragArea';
import DragArea from '../../../shared/components/files/DragArea';
import { useGetAvailablesCitiesQuery } from '../../institutes/slices/institutesApiSlice';
import { graduateValidationSchema } from '../validation/graduateValidations';
import { useGetAvailablesTypeEducationsQuery } from '../../requisition-teacher/slices/requisitionsApiSlice';
import { useState } from 'react';
import { useUploadFilesMutation } from '../../../shared/slices/uploadFilesApi';
import { useGraduateStudentMutation } from '../slices/studentsGraduateApiSlice';
import { format } from 'date-fns';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import { useGetCatalogByAcronymChildrenQuery } from '../../../shared/slices/catalogApi';
import LocalizedDatePicker from '../../../shared/components/controlled/LocalizedDatePicker';

const StudentGraduateForm = () => {
  const { t } = useTranslation();

  const [file, setFile] = useState<File | null>(null);
  const [showAlerts, setShowAlerts] = useState(true);

  const { currentProfile } = useAppSelector((state) => state.access);
  const { studentIdSelected, studentsList } = useAppSelector((state) => state.studentsGraduate);
  const { data: cities = [], isLoading: isLoadingC } = useGetAvailablesCitiesQuery(
    {
      profileId: currentProfile?.profileId!,
    },
    {
      skip: !currentProfile?.profileId,
    }
  );

  const { data: eduTypes = [], isLoading: isLoadingT } = useGetAvailablesTypeEducationsQuery(
    {
      profileId: currentProfile?.profileId!,
    },
    {
      skip: !currentProfile?.profileId,
    }
  );

  const { data: observations, isLoading: isLoadingO } = useGetCatalogByAcronymChildrenQuery({
    acronym: 'razon-graduacion',
    profileId: currentProfile?.profileId!,
  });

  const [uploadFile, { isLoading: isUploadingFile }] = useUploadFilesMutation();

  const [graduateStudent, { isLoading, isSuccess, isError, error }] = useGraduateStudentMutation();

  const studentRecord = studentsList.find((e) => e.id === studentIdSelected);

  const { values, handleSubmit, getFieldProps, errors, touched, setFieldValue, isSubmitting } =
    useFormik({
      initialValues: {
        certificateTitle: studentRecord?.mesh?.mes_title || '',
        graduationDate: new Date(),
        educationCalification: 0,
        educationObservation: '',
        documentUrl: '',
        educationTypeId: 5,
        cityId: 0,
      },
      validationSchema: graduateValidationSchema,
      onSubmit: async (values) => {
        uploadFile({
          profileId: currentProfile?.profileId!,
          files: file!,
        })
          .unwrap()
          .then(async (response) => {
            if (response) {
              await graduateStudent({
                studentId: studentIdSelected!,
                certificateTitle: values.certificateTitle,
                graduationDate: format(values.graduationDate, 'yyyy-MM-dd'),
                educationCalification: values.educationCalification,
                educationObservation: values.educationObservation,
                documentUrl: response,
                educationTypeId: values.educationTypeId,
                cityId: values.cityId,
              });
              setShowAlerts(true);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      },
    });

  const handleChangeFile = (e: any) => {
    setFieldValue('documentUrl', e.target.files[0]);
    setFile(e.target.files[0]);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={1}>
        {showAlerts && isSuccess && (
          <Grid item xs={12}>
            <SuccessAlert
              message={t('academic.students_graduate.graduate.success')}
              handleDismiss={() => setShowAlerts(false)}
            />
          </Grid>
        )}
        {showAlerts && isError && (
          <Grid item xs={12}>
            <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
          </Grid>
        )}
        {!!Number(studentRecord?.ds_balance) && (
          <Grid item xs={12}>
            <Alert severity="error">{t('academic.students_graduate.graduate.has_debts')}</Alert>
          </Grid>
        )}
        <Grid item xs={12}>
          <InputLabel>{t('academic.students_graduate.certificate_title')}</InputLabel>
          <TextField
            {...getFieldProps('certificateTitle')}
            placeholder={t('academic.students_graduate.certificate_title.placeholder')}
            error={Boolean(touched.certificateTitle && errors.certificateTitle)}
            disabled
            fullWidth
          />
          {touched.certificateTitle && errors.certificateTitle && (
            <FormHelperText error>{t(errors.certificateTitle)}</FormHelperText>
          )}
        </Grid>
        <Grid item xs={12}>
          <InputLabel>{t('academic.students_graduate.graduation_date')}</InputLabel>
          <LocalizedDatePicker
            {...getFieldProps('graduationDate')}
            value={values.graduationDate}
            onChange={(date) => setFieldValue('graduationDate', date || null)}
            placeholder={t('academic.students_graduate.graduation_date.placeholder')}
            error={Boolean(touched.graduationDate && errors.graduationDate)}
          />

          {touched.graduationDate && errors.graduationDate && (
            <FormHelperText error>{t(errors.graduationDate as string)}</FormHelperText>
          )}
        </Grid>
        <Grid item xs={12}>
          <InputLabel>{t('academic.students_graduate.education_calification')}</InputLabel>
          <TextField
            {...getFieldProps('educationCalification')}
            placeholder={t('academic.students_graduate.education_calification.placeholder')}
            error={Boolean(touched.educationCalification && errors.educationCalification)}
            fullWidth
          />
          {touched.educationCalification && errors.educationCalification && (
            <FormHelperText error>{t(errors.educationCalification)}</FormHelperText>
          )}
        </Grid>
        <Grid item xs={12}>
          <InputLabel>{t('academic.students_graduate.education_observation')}</InputLabel>
          {isLoadingO ? (
            <TextField
              fullWidth
              disabled
              placeholder={t('academic.students_graduate.education_observation.loading')}
            />
          ) : (
            <Autocomplete
              options={observations || []}
              getOptionLabel={(option) => option.cat_name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  {...getFieldProps('educationObservation')}
                  placeholder={t('academic.students_graduate.education_observation.placeholder')}
                  error={Boolean(touched.educationObservation && errors.educationObservation)}
                  fullWidth
                />
              )}
              value={observations?.find((e) => e.cat_name === values.educationObservation)}
              onChange={(event, value) => {
                setFieldValue('educationObservation', value?.cat_name);
              }}
            />
          )}
          {/* <TextField
            {...getFieldProps('educationObservation')}
            placeholder={t('academic.students_graduate.education_observation.placeholder')}
            error={Boolean(touched.educationObservation && errors.educationObservation)}
            fullWidth
          /> */}
          {touched.educationObservation && errors.educationObservation && (
            <FormHelperText error>{t(errors.educationObservation)}</FormHelperText>
          )}
        </Grid>
        <Grid item xs={12}>
          <InputLabel>{t('academic.students_graduate.city')}</InputLabel>
          {isLoadingC ? (
            <TextField
              fullWidth
              disabled
              placeholder={t('academic.students_graduate.city.loading')}
            />
          ) : (
            <Autocomplete
              options={cities}
              getOptionLabel={(option) => option.cat_name}
              {...getFieldProps('cityId')}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={t('academic.students_graduate.city.placeholder')}
                  fullWidth
                />
              )}
              value={cities.find((e) => e.id === values.cityId)}
              multiple={false}
              onChange={(event, value) => {
                setFieldValue('cityId', value?.id || null);
              }}
            />
          )}
          {touched.cityId && errors.cityId && (
            <FormHelperText error>{t(errors.cityId)}</FormHelperText>
          )}
        </Grid>
        <Grid item xs={12}>
          <InputLabel>{t('academic.students_graduate.edu_type')}</InputLabel>
          {isLoadingT ? (
            <TextField
              fullWidth
              disabled
              placeholder={t('academic.students_graduate.edu_type.loading')}
            />
          ) : (
            <Autocomplete
              options={eduTypes}
              getOptionLabel={(option) => option.typ_edu_name}
              {...getFieldProps('educationTypeId')}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={t('academic.students_graduate.edu_type.placeholder')}
                  fullWidth
                />
              )}
              value={eduTypes.find((e) => e.id === values.educationTypeId)}
              multiple={false}
              onChange={(event, value) => {
                setFieldValue('educationTypeId', value?.id || null);
              }}
            />
          )}
          {touched.educationTypeId && errors.educationTypeId && (
            <FormHelperText error>{t(errors.educationTypeId)}</FormHelperText>
          )}
        </Grid>
        <Grid item xs={12}>
          {/* <InputLabel>{t('academic.students_graduate.document_url')}</InputLabel> */}
          <DragArea
            description={t('academic.students_graduate.document_url.description')}
            label={t('academic.students_graduate.document_url.label')}
            typesAccepted="application/pdf"
            handleChange={(e) => handleChangeFile(e)}
          />
          {touched.documentUrl && errors.documentUrl && (
            <FormHelperText error>{t(errors.documentUrl)}</FormHelperText>
          )}
        </Grid>
        <Grid item container xs={12} justifyContent="flex-end">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            disabled={isLoading || isSuccess || isSubmitting || isUploadingFile}
            endIcon={(isLoading || isSubmitting) && <CircularProgress size={14} />}
          >
            {t('academic.students_graduate.graduate.submit')}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default StudentGraduateForm;
