import DeleteAlert from '../../../shared/components/dialogs/DeleteAlert';
import { useDeleteUserProfileMutation } from '../slices/userConfigurationsApiSlice';
import { useTranslation } from 'react-i18next';
// import { resetRoleIdToDelete, setDeleteModalOpen } from '../slices/rolesSlice';

interface Props {
  openModal: boolean;
  userId: number;
  profileId: number;
  handleCloseModal: () => void;
  setProfileId: React.Dispatch<React.SetStateAction<number>>;
}

const DeleteUserProfileDialog = ({
  openModal,
  userId,
  profileId,
  handleCloseModal,
  setProfileId,
}: Props) => {
  const { t } = useTranslation();
  const [deleteUserProfile, { isLoading, isSuccess, isError, error }] =
    useDeleteUserProfileMutation();

  const handleDelete = () => {
    deleteUserProfile({ userId, profileId });
  };

  const handleClose = () => {
    handleCloseModal();
    setProfileId(0);
  };

  return (
    <DeleteAlert
      open={openModal}
      onClose={handleClose}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      handleDelete={handleDelete}
      deleteTitle={t('configuration.users.dialog.delete.title')}
      error={error as string}
    />
  );
};

export default DeleteUserProfileDialog;
