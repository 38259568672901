import { DateRange } from '@material-ui/lab/DateRangePicker';

import { useTranslation } from 'react-i18next';

import { useAppSelector, useAppDispatch } from '../../../../../hooks/useRedux';
import { setDate } from '../../slices/generateDebtsSlice';
import LocalizedDateRangePicker from '../../../../shared/components/controlled/LocalizedDateRangePicker';

const SelectStudentRecord = () => {
  const dispatch = useAppDispatch();

  const { dates } = useAppSelector((state) => state.generateDebts);

  const { t } = useTranslation();

  const onChangeDates = (newDatesValues: DateRange<Date>) => {
    dispatch(setDate(newDatesValues));
  };
  return (
    <LocalizedDateRangePicker
      startText={t('financial.generate_debts.unitary.start_date')}
      endText={t('financial.generate_debts.unitary.end_date')}
      value={dates}
      onChange={onChangeDates}
    />
  );
};

export default SelectStudentRecord;
