import { FormHelperText, Grid, InputLabel, TextField } from '@material-ui/core';
import { useFormikContext } from 'formik';

import { useTranslation } from 'react-i18next';

interface EnterpriseDataForm {
  enterpriseName: string;
  ruc: string;
  address: string;
  reason: string;
  activity: string;
}

const EnterpriseStep = () => {
  const { t } = useTranslation();

  const { getFieldProps, errors, touched } = useFormikContext<EnterpriseDataForm>();
  return (
    <Grid container spacing={4} alignContent="flex-start">
      <Grid item xs={12} md={6} container spacing={2}>
        <Grid item xs={12} container spacing={2}>
          <Grid item xs={12}>
            <InputLabel>{t('students.applications.professional_practices.company.name')}</InputLabel>
            <TextField
              fullWidth
              placeholder={t('students.applications.professional_practices.company.name')}
              error={Boolean(errors.enterpriseName && touched.enterpriseName)}
              {...getFieldProps('enterpriseName')}
            />
            {errors.enterpriseName && touched.enterpriseName && (
              <FormHelperText error>{errors.enterpriseName}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={12}>
            <InputLabel>{t('students.applications.professional_practices.company.ruc')}</InputLabel>
            <TextField
              fullWidth
              placeholder={t('students.applications.professional_practices.company.ruc')}
              error={Boolean(errors.ruc && touched.ruc)}
              {...getFieldProps('ruc')}
            />
            {errors.ruc && touched.ruc && <FormHelperText error>{errors.ruc}</FormHelperText>}
          </Grid>
          <Grid item xs={12}>
            <InputLabel>{t('students.applications.professional_practices.company.address')}</InputLabel>
            <TextField
              fullWidth
              placeholder={t('students.applications.professional_practices.company.address')}
              error={Boolean(errors.address && touched.address)}
              {...getFieldProps('address')}
            />
            {errors.address && touched.address && (
              <FormHelperText error>{errors.address}</FormHelperText>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6} container spacing={2}>
        <Grid item xs={12} height={'27vh'} container spacing={2}>
          <Grid item xs={12}>
            <InputLabel>{t('students.applications.professional_practices.company.reason')}</InputLabel>
            <TextField
              fullWidth
              placeholder={t('students.applications.professional_practices.company.reason')}
              error={Boolean(errors.reason && touched.reason)}
              {...getFieldProps('reason')}
            />
            {errors.reason && touched.reason && (
              <FormHelperText error>{errors.reason}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={12}>
            <InputLabel>{t('students.applications.professional_practices.company.activity')}</InputLabel>
            <TextField
              fullWidth
              placeholder={t('students.applications.professional_practices.company.activity')}
              error={Boolean(errors.activity && touched.activity)}
              {...getFieldProps('activity')}
            />
            {errors.activity && touched.activity && (
              <FormHelperText error>{errors.activity}</FormHelperText>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default EnterpriseStep;
