import { Grid } from '@material-ui/core';
import MainCard from '../../../shared/components/cards/MainCard';

export const FaqHelpCenterScreen = () => {

   return (
      <Grid component={MainCard} sx={{ padding: 2 }}>

      </Grid>
   );
};
