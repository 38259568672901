import { Redirect, Switch } from 'react-router-dom';
import { useAppSelector } from '../../../../../../hooks/useRedux';
import GuardRoute from '../../../../../../router/guards/GuardRoute';
// eslint-disable-next-line import/no-cycle
import EvaluationGroupsScreen from '../screen/EvaluationGroupsScreen';
import PreviewEvaluationScreen from '../screen/PreviewEvaluationScreen';
import AddEvaluationScreen from '../screen/AddEvaluationScreen';
import EditEvaluationScreen from '../screen/EditEvaluationScreen';
import EvaluationsScreen from '../screen/EvaluationsScreen';

export const EvaluationsRoutes = () => {
  const { currentModule } = useAppSelector((state) => state.access);

  const hasPermission = 'evaluation' in currentModule?.permissions!;

  if (!hasPermission) return <Redirect to="/dashboard" />;

  return (
    <Switch>
      <GuardRoute
        exact
        path="/evaluaciones"
        component={EvaluationsScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.list}
      />
      <GuardRoute
        exact
        path="/evaluaciones/agregar"
        component={AddEvaluationScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.create}
      />
      <GuardRoute
        exact
        path="/evaluaciones/:id/editar"
        component={EditEvaluationScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.edit}
      />
      <GuardRoute
        exact
        path="/:id/grupos"
        component={EvaluationGroupsScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.edit}
      />
      <GuardRoute
        exact
        path="/:id/previsualizar"
        component={PreviewEvaluationScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.edit}
      />
    </Switch>
  );
};
