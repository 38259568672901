import { Grid, IconButton, Tooltip } from '@material-ui/core';
import InputLabel from './InputLabel';

import { makeStyles } from '@material-ui/core/styles';
import { IconLink } from '@tabler/icons';
import { useHistory } from 'react-router-dom';

export const useStyles = makeStyles((theme) => ({
  inputLabel: {
    marginBlockEnd: 6,
    fontWeight: 500,
    color: theme.palette.text.primary,
  },
  icon: {
    color: theme.palette.primary.main,
  },
}));

const InputSecondaryLabel = (props: {
  label: string;
  title: string;
  link?: string;
  hasAccess?: boolean;
}) => {
  const history = useHistory();
  const classes = useStyles();
  const { label, title, link, hasAccess } = props;

  return (
    <Grid container justifyContent="space-between" alignItems="end" direction="row">
      <InputLabel>{label}</InputLabel>
      {hasAccess && (
        <Tooltip title={title}>
          <IconButton onClick={() => (link ? history.push(link) : null)}>
            <IconLink className={classes.icon} />
          </IconButton>
        </Tooltip>
      )}
    </Grid>
  );
};

export default InputSecondaryLabel;
