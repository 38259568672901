import { Button, Grid } from '@material-ui/core';
import { IconEyeTable, IconStar } from '@tabler/icons';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Img from '../../../../../assets/images/no-classmates.png';
import MainCard from '../../../../shared/components/cards/MainCard';

function CalificateMain() {
  const History = useHistory();
  const path = window.location.pathname;
  console.log(path);

  const { t } = useTranslation();

  function RedirectPage(url: string) {
    History.push(`${path}/${url}`);
  }
  return (
    <Grid
      component={MainCard}
      height={'60vh'}
      sx={{ borderTopLeftRadius: '0px', borderTopRightRadius: '0px', placeContent: 'center' }}
    >
      <Grid textAlign={'center'}>
        <Grid>
          <img src={Img} alt="" />
        </Grid>
        <Grid spacing={2} marginTop="15px">
          <Button
            onClick={() => RedirectPage('calificar')}
            style={{ marginRight: '15px' }}
            variant="contained"
            startIcon={<IconStar />}
          >
            {t('teacher_virtual_classroom.califications.main.header.califications')}
          </Button>
          <Button
            onClick={() => RedirectPage('view')}
            variant="outlined"
            startIcon={<IconEyeTable />}
          >
            {t('teacher_virtual_classroom.califications.main.header.classroom')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default CalificateMain;
