import { TextField, TextFieldProps } from '@material-ui/core';
import { LocalizationProvider, StaticTimePicker, StaticTimePickerProps } from '@material-ui/lab';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import { JSXElementConstructor, ReactElement } from 'react';
import { useGetLocaleDateLang } from '../../../../translation/hooks/useGetLocaleDateLang';

export interface LocalizedDatePickerProps extends Omit<StaticTimePickerProps, 'renderInput'> {
  error?: boolean;
  placeholder?: string;
  helperText?: string;
  renderInput?: (props: TextFieldProps) => ReactElement<any, string | JSXElementConstructor<any>>;
}

/**
 * @param props - props pertenecientes a MUI DatePicker
 * @param props.renderInput - input personalizado (si no se pasa se usa el input por defecto)
 * @param props.placeholder - placeholder del input (si no se pasa se usa el placeholder por defecto)
 * @param props.helperText - helperText del input (si no se pasa se usa el helperText por defecto)
 * @param props.error - error del input
 * @param props.value - valor del input
 * @param props.onChange - onChange del input
 * @returns
 * DateTimePicker con localización de idioma y formato de fecha
 * @example
 *
 *
 * @see https://material-ui.com/es/components/text-fields/
 * @see https://material-ui.com/es/components/pickers/
 * @see https://material-ui.com/es/guides/localization/
 * @see https://material-ui.com/es/guides/localization/#date-fns
 */
const LocalizedStaticTimePicker = (props: LocalizedDatePickerProps) => {
  const { lang } = useGetLocaleDateLang();

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={lang}>
      <StaticTimePicker
        // ampm={false}
        label="Hora de inicio"
        orientation="landscape"
        openTo="hours"
      {...props}
        {...(props.renderInput
          ? { renderInput: props.renderInput }
          : {
              renderInput: (params) => (
                <TextField
                  {...params}
                  error={params.error || props.error}
                  placeholder={props.placeholder || ''}
                  helperText={props.helperText || null}
                  fullWidth
                  inputProps={{
                    ...params.inputProps,
                    placeholder: props.placeholder || '',
                  }}
                />
              ),
            })}
      />
    </LocalizationProvider>
  );
};

export default LocalizedStaticTimePicker;
