import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Skeleton,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core';
import { IconX } from '@tabler/icons';
import { useState } from 'react';
import { DebounceInput } from 'react-debounce-input';
import SearchIcon from '@material-ui/icons/Search';
import CustomPagination from '../../../shared/components/tables/CustomPagination';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import TeacherCard from './Cards/TeacherCard';
import IconTelework from '../../../shared/components/illustrations/IconTelework';
import { HoursSummariesResponse } from '../interfaces/collaborator.interfaces';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { SerializedError } from '@reduxjs/toolkit';

interface Props {
  setPage: (setPage: number) => void;
  setPerPage: (setPerPage: number) => void;
  setSearch: (setSearch: string) => void;
  periodId?: string;
  educationLevelId?: string;
  isLoading?: boolean;
  data?: HoursSummariesResponse;
  isFetching?: boolean;
  search?: string;
  perPage: number;
  page: number;
  error?: FetchBaseQueryError | SerializedError;
}

const TeachersGridView = ({
  setPage,
  setPerPage,
  setSearch,
  error,
  page,
  perPage,
  periodId,
  educationLevelId,
  isLoading,
  data,
  isFetching,
  search,
}: Props) => {
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (newPerPage: number) => {
    setAnchorEl(null);
    setPerPage(newPerPage);
  };

  return (
    <Grid container spacing={2} mt={3}>
      {periodId && educationLevelId ? (
        <>
          <Grid item xs={12} container>
            <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
              <DebounceInput
                autoFocus={Boolean(search)}
                minLength={2}
                debounceTimeout={300}
                onChange={({ target }) => setSearch(target.value)}
                value={search}
                element={(props) => (
                  <TextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon fontSize="small" />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            size="small"
                            onClick={() => setSearch('')}
                            {...(!search && { sx: { cursor: 'initial', opacity: 0 } })}
                          >
                            <IconX size={20} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    placeholder="Buscar docente"
                    variant="outlined"
                    size="small"
                    {...props}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} container spacing={4}>
            {isLoading ? (
              <>
                {Array.from(new Array(perPage)).map((n, index) => (
                  <Grid key={index} item xs={12} md={6} lg={4}>
                    <Skeleton
                      variant="rectangular"
                      sx={{ borderRadius: 2 }}
                      height={250}
                      width="100%"
                    />
                  </Grid>
                ))}
              </>
            ) : (
              <>
                {data?.data.map((c) => (
                  <Grid key={c?.id} item xs={12} md={6} lg={4}>
                    <TeacherCard
                      apId={c?.id}
                      apName={`${c.collaborator?.user?.person?.pers_first_lastname} ${c.collaborator?.user?.person?.pers_second_lastname} ${c.collaborator?.user?.person?.pers_firstname} ${c.collaborator?.user?.person?.pers_secondname}`}
                      status={c.status?.st_name}
                      educationLevel={c.education_level?.edu_name}
                      apDegree={'Horas asignadas'}
                      hClasses={Number(c.hs_classes)}
                      hPreparation={Number(c.hs_classes_examns_preparation)}
                      hTutoring={Number(c.hs_tutoring)}
                      hBonding={Number(c.hs_bonding)}
                      hAcademicManagement={Number(c.hs_academic_management)}
                      hResearch={Number(c.hs_research)}
                      identification={c.collaborator?.user?.person?.pers_identification}
                      period={c.period?.per_name}
                      hTotal={Number(c.hs_total)}
                      journey={c.collaborator?.coll_journey_description}
                    />
                  </Grid>
                ))}
              </>
            )}

            {data?.data.length === 0 && !isFetching && (
              <Grid item xs={12} container justifyContent="center">
                <Typography variant="subtitle1">¡No hay elementos que mostrar!</Typography>
              </Grid>
            )}
          </Grid>

          {!isLoading && !error && (
            <Grid
              item
              xs={12}
              container
              justifyContent="space-between"
              alignItems="flex-end"
              sx={{ mt: 4 }}
            >
              <Grid item>
                <CustomPagination
                  shape="rounded"
                  color="primary"
                  count={Math.ceil((data?.total || 0) / perPage || 1)}
                  page={page}
                  onChange={(_, newPage) => {
                    setPage(newPage);
                  }}
                />
              </Grid>
              <Grid>
                <Box
                  component={Button}
                  size="large"
                  sx={{
                    color: 'grey.900',
                    backgroundColor: 'primary.light',
                    ':hover': { bgcolor: 'primary.light' },
                    px: 2,
                    borderRadius: 4,
                  }}
                  endIcon={<ExpandMoreRoundedIcon />}
                  onClick={handleClick}
                >
                  {perPage} Registros
                </Box>

                <Menu
                  anchorEl={anchorEl}
                  keepMounted
                  open={open}
                  onClose={() => handleClose(perPage)}
                  variant="selectedMenu"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <MenuItem onClick={() => handleClose(6)}>6 Registros</MenuItem>
                  <MenuItem onClick={() => handleClose(8)}>8 Registros</MenuItem>
                  <MenuItem onClick={() => handleClose(12)}>12 Registros</MenuItem>
                </Menu>
              </Grid>
            </Grid>
          )}
        </>
      ) : (
        <Grid container justifyContent={'center'} alignContent={'center'} sx={{ height: '50vh' }}>
          <Grid container justifyContent={'center'} item xs={8} md={6}>
            <Grid container item xs={12} justifyContent={'center'}>
              <IconTelework size={300} />
            </Grid>
            <Typography variant={'body1'} textAlign={'center'} lineHeight={2} component={'div'}>
              <Typography
                variant={'h4'}
                sx={{ color: theme.palette.primary.main }}
                component={'p'}
                align={'center'}
                lineHeight={2}
              >
                Selecciona Unidad académica y Período
              </Typography>
              Con los siguientes filtros puede listar en cuadrícula <strong>los docentes</strong> y
              consultar sus <strong>horas asignadas</strong>.
            </Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default TeachersGridView;
