import { Grid, Typography } from '@material-ui/core';
import { useAppSelector } from '../../../../hooks/useRedux';
import MainCard from '../../../shared/components/cards/MainCard';

import DeleteCampusDialog from '../components/DeleteCampusDialog';
import CampusTable from '../components/CampusTable';

const CampusScreen = () => {
  const { campusIdToDelete } = useAppSelector((state) => state.campus);
  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">Listado de campus</Typography>
      </Grid>

      <CampusTable />

      {campusIdToDelete && <DeleteCampusDialog />}
    </Grid>
  );
};

export default CampusScreen;
