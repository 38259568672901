import { Box, Stack, TextField } from '@material-ui/core';
import { FacebookRounded, Instagram, LinkedIn, Twitter } from '@material-ui/icons';
import { IconBrandTiktok } from '@tabler/icons';
import { SocialNetwork } from '../../interfaces/studentRecordAcademic.interfaces';
import TextFieldFetching from '../../../../shared/components/TextFieldFetching';

interface Props {
  handleAddSocialNetwork: (social_keywork: string, value: string) => void;
  socialNetworks: SocialNetwork[];
  isLoadingSocialNetworkData?: boolean;
}

const SocialNetworks = ({
  handleAddSocialNetwork,
  socialNetworks,
  isLoadingSocialNetworkData,
}: Props) => {
  return (
    <Stack spacing={2}>
      <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
        <FacebookRounded sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
        {isLoadingSocialNetworkData ? (
          <TextFieldFetching variant="standard" />
        ) : (
          <TextField
            fullWidth
            variant="standard"
            value={socialNetworks.find((sn) => sn.social_keywork === 'sn-facebook')?.url || ''}
            onChange={(e) => handleAddSocialNetwork('sn-facebook', e.target.value)}
          />
        )}
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
        <Instagram sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
        {isLoadingSocialNetworkData ? (
          <TextFieldFetching variant="standard" />
        ) : (
          <TextField
            fullWidth
            variant="standard"
            value={socialNetworks.find((sn) => sn.social_keywork === 'sn-instagram')?.url || ''}
            onChange={(e) => handleAddSocialNetwork('sn-instagram', e.target.value)}
          />
        )}
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
        <LinkedIn sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
        {isLoadingSocialNetworkData ? (
          <TextFieldFetching variant="standard" />
        ) : (
          <TextField
            fullWidth
            variant="standard"
            value={socialNetworks.find((sn) => sn.social_keywork === 'sn-linkedin')?.url || ''}
            onChange={(e) => handleAddSocialNetwork('sn-linkedin', e.target.value)}
          />
        )}
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
        <Twitter sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
        {isLoadingSocialNetworkData ? (
          <TextFieldFetching variant="standard" />
        ) : (
          <TextField
            fullWidth
            variant="standard"
            value={socialNetworks.find((sn) => sn.social_keywork === 'sn-twitter')?.url || ''}
            onChange={(e) => handleAddSocialNetwork('sn-twitter', e.target.value)}
          />
        )}
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'flex-end', mb: 2 }}>
        <IconBrandTiktok /* sx={{ color: 'action.active', mr: 1, my: 0.5 }} */ />
        {isLoadingSocialNetworkData ? (
          <TextFieldFetching variant="standard" />
        ) : (
          <TextField
            fullWidth
            variant="standard"
            value={socialNetworks.find((sn) => sn.social_keywork === 'sn-tiktok')?.url || ''}
            onChange={(e) => handleAddSocialNetwork('sn-tiktok', e.target.value)}
          />
        )}
      </Box>
    </Stack>
  );
};

export default SocialNetworks;
