/* eslint-disable react/no-unstable-nested-components */
import { Autocomplete, Grid, TextField } from '@material-ui/core';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetEvaluationsQuery } from '../slices/evaluationsApiSlice';
import {
  setEvaluationsDeleteModalOpen,
  setEvaluationsIdToDelete,
  setEvaluationsList,
  setEvaluationsTable,
  setEvaTypeIdSelected,
} from '../slices/evaluationsSlice';
import { useGetAllIntegralEvaluationsQuery } from '../../integral_evaluation/slices/integralEvaluationApiSlice';
import { useGetAllEvaluationTypesQuery } from '../../evaluation_type/slices/evaluationTypeApiSlice';
import { useHistory } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../../../../../hooks/useRedux';
import { useTableSearch } from '../../../../../../hooks/useTableSearch';
import { renderSkeletonTable } from '../../../../../shared/helpers/render';
import SearchPaginatedTable from '../../../../../shared/components/tables/SearchPaginatedTable';
import PreviewButton from '../../../../../shared/components/tables/buttons/PreviewButton';
import AssignGroupButton from '../../../../../shared/components/tables/buttons/AssignGroupsButton';
import DeleteButton from '../../../../../shared/components/tables/buttons/DeleteButton';
import EditButton from '../../../../../shared/components/tables/buttons/EditButton';
import AddButton from '../../../../../shared/components/buttons/AddIconButton';

const EvaluationsTable = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useHistory();

  const {
    evaluationsList,
    evaluationsTable: { page, perPage, search, filterArrow },
    evaTypeIdSelected,
    integralEvaluationIdSelected,
  } = useAppSelector((state) => state.evaluations);

  const { setPage, setPerPage, setSearch, setFilterArrow } = useTableSearch({
    page,
    perPage,
    search,
    filterArrow,
    updateFunction: setEvaluationsTable,
  });

  const { data, isLoading, isFetching, error } = useGetEvaluationsQuery({
    page,
    perPage,
    search,
    ...(evaTypeIdSelected && { evaTypeId: evaTypeIdSelected }),
    ...(integralEvaluationIdSelected && { integralEvaluationId: integralEvaluationIdSelected }),
    ...(filterArrow && { sort: filterArrow.name, typeSort: filterArrow.direction }),
  });

  const { data: integralEvaluations, isLoading: isLoadingIE } = useGetAllIntegralEvaluationsQuery(
    {}
  );

  const { data: evaluationTypes, isLoading: isLoadingET } = useGetAllEvaluationTypesQuery({});

  useEffect(() => {
    if (data?.data) {
      dispatch(setEvaluationsList(data?.data));
    }
  }, [data, dispatch]);

  const datafetch = evaluationsList.map((item, idx) => ({
    id: (page - 1) * perPage + (idx + 1),
    evaluationType: item.eva_type?.description,
    integralEvaluation: item.integral_evaluation?.name,
    description: item.description,
    created_at: item.created_at?.split('T')[0],
    options: { id: item.uuid },
  }));

  const goToAdd = () => {
    navigate.push('/evaluaciones/agregar');
  };
  const goToGroups = (id: string) => {
    navigate.push(`/evaluaciones/${id}/grupos`);
  };

  const goToPreview = (id: string) => {
    navigate.push(`/evaluaciones/${id}/previsualizar`);
  };

  const handleEdit = (id: string) => {
    navigate.push(`/evaluaciones/${id}/editar`);
  };

  const handleDelete = (id: string) => {
    dispatch(setEvaluationsIdToDelete(id));
    dispatch(setEvaluationsDeleteModalOpen(true));
  };

  return isLoading ? (
    renderSkeletonTable(10, 5)
  ) : (
    <SearchPaginatedTable
      data={datafetch!}
      headers={{
        id: 'N°',
        evaluationType: t('maintenance.evaluations.evaluation_type'),
        integralEvaluation: t('maintenance.evaluations.integral_evaluation'),
        description: t('maintenance.evaluations.description'),
        created_at: t('maintenance.evaluations.created_at'),
        options: t('maintenance.evaluations.options'),
      }}
      listFilterArrows={{
        // evaluationType: 'eva_type.description',
        description: 'description',
        created_at: 'created_at',
      }}
      filterArrow={filterArrow}
      setFilterArrow={setFilterArrow}
      keyExtractor={(item) => item.id.toString()}
      searchPlacehoder={t('maintenance.evaluations.search')}
      isLoading={isLoading}
      isFetching={isFetching}
      error={error as any}
      perPage={perPage}
      setPerPage={setPerPage}
      total={data?.total || 0}
      page={page}
      setPage={setPage}
      numHeader={5}
      search={search}
      setSearch={setSearch}
      customDataCellsProperties={{
        id: {
          align: 'center',
        },
        evaluationType: {
          align: 'center',
        },
        integralEvaluation: {
          align: 'center',
        },
        description: {
          align: 'center',
        },
        created_at: {
          align: 'center',
        },
        options: {
          align: 'center',
        },
      }}
      customHeadersCellsProperties={{
        id: {
          align: 'center',
        },
        evaluationType: {
          align: 'center',
        },
        integralEvaluation: {
          align: 'center',
        },
        description: {
          align: 'center',
        },
        created_at: {
          align: 'center',
        },
        options: {
          align: 'center',
        },
      }}
      customRenderers={{
        options: (item) => (
          <>
            <PreviewButton handlePreview={() => goToPreview(item.options.id!)} />
            <AssignGroupButton handleClick={() => goToGroups(item.options.id!)} />
            {false && <EditButton handleEdit={() => handleEdit(item.options.id!)} />}
            <DeleteButton handleDelete={() => handleDelete(item.options.id!)} />
          </>
        ),
      }}
      ActionButtons={<AddButton onClick={goToAdd} />}
      additionalFilters={
        <>
          <Grid container spacing={1}>
            <Grid item xs={12} md={4}>
              <Autocomplete
                options={evaluationTypes || []}
                getOptionLabel={(option) => option.description}
                loading={isLoadingET}
                onChange={(_, value) => {
                  dispatch(setEvaTypeIdSelected(value?.uuid || ''));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('maintenance.evaluations.evaluation_type')}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Autocomplete
                options={integralEvaluations || []}
                getOptionLabel={(option) => option.name}
                loading={isLoadingIE}
                onChange={(_, value) => {
                  console.log(value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('maintenance.evaluations.integral_evaluation')}
                    fullWidth
                  />
                )}
              />
            </Grid>
          </Grid>
        </>
      }
    />
  );
};

export default EvaluationsTable;
