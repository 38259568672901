import { useState, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { IconUserPlus, IconUsers } from '@tabler/icons';

import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import StatusChip from '../../../../shared/components/badges/StatusChip';
import DeleteButton from '../../../../shared/components/tables/buttons/DeleteButton';
import AddButton from '../../../../shared/components/tables/buttons/AddButton';
import {
  updateGroupResearchTableStates,
  openDeleteModalWithResearchGroup,
  setGroupResearchList,
} from '../../slices/investigationLinesSlice';
import SearchPaginatedTable, {
  FilterArrow,
} from '../../../../shared/components/tables/SearchPaginatedTable';
import { useTableSearch } from '../../../../../hooks/useTableSearch';
import { GroupResearchResponse } from '../../interfaces/groupResearch.interfaces';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { SerializedError } from '@reduxjs/toolkit';
import { statusAvailables } from '../../../../shared/constants/resourceStatus';
import DeleteResearchGroupDialog from './DeleteResearchGroupDialog';
import ExtendDialog from '../ExtendDialog';
import AddCollaboratorGroupResearchForm from './forms/CollaboratorGroupResearchForm';
import ListCollaboratorsDelete from './forms/ListCollaboratorsDelete';
import { FilterData } from './index';

interface Props {
  data: GroupResearchResponse;
  isLoading: boolean;
  isFetching: boolean;
  error: FetchBaseQueryError | SerializedError | undefined;
  setSort: React.Dispatch<React.SetStateAction<FilterData>>;
  sort: FilterData;
}

const ResearchGroupTable = ({ data, isLoading, isFetching, error, setSort, sort }: Props) => {
  const { t } = useTranslation();
  // const { hasAccess } = useGuard();

  const dispatch = useAppDispatch();

  const { path } = useRouteMatch();

  const history = useHistory();

  const [filterArrow, setFilterArrow] = useState<FilterArrow>(sort.group);

  const [openDialog, setOpenDialog] = useState(false);

  const [openDialogListColl, setOpenDialogListColl] = useState(false);

  const [researchGroupId, setResearchGroupId] = useState('');

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleOpen = (id: string) => {
    setOpenDialog(true);
    setResearchGroupId(id);
  };

  const handleCloseListColl = () => {
    setOpenDialogListColl(false);
  };

  const handleOpenListColl = (id: string) => {
    setOpenDialogListColl(true);
    setResearchGroupId(id);
  };

  const { deleteModalOpenGroupResearch } = useAppSelector((state) => state.investigationLines);

  const { page, perPage, search } = useAppSelector(
    (state) => state.investigationLines.groupResearchTable
  );

  const { setPage, setPerPage, setSearch } = useTableSearch({
    page,
    perPage,
    search,
    updateFunction: updateGroupResearchTableStates,
  });

  const handleDelete = async (researchLine: string) => {
    dispatch(openDeleteModalWithResearchGroup(researchLine));
  };

  const goToAdd = () => {
    history.push(`${path}/crear/grupo-de-investigacion`);
  };

  const datafetch = data?.data?.map((d, index) => ({
    id: { index: index + 1 + (page - 1) * perPage, id: d.id },
    name: d.name,
    lineResearchName: d.line_research?.name,
    collaborator: d.collaborator_group_research.length,
    project: d.line_group_research_project.length,
    statusAcronym: d.status,
    options: '',
  }));

  useEffect(() => {
    if (data) {
      dispatch(setGroupResearchList(data.data));
    }
  }, [data, dispatch]);

  useEffect(() => {
    setSort((state) => ({ ...state, group: filterArrow }));
    //eslint-disable-next-line
  }, [filterArrow]);

  return (
    <>
      <SearchPaginatedTable
        data={datafetch!}
        headers={{
          id: 'N°',
          name: t('lines_research.researchGroupTable.lineResearchName'),
          lineResearchName: t('lines_research.FilterTables.investigationLine.title'),
          collaborator: '# Colaborador',
          project: '# ' + t('lines_research.researchGroupTable.project'),
          statusAcronym: t('academic.subjects.status'),
          options: t('academic.subjects.options'),
        }}
        keyExtractor={(item) => item.id.id.toString()}
        searchPlacehoder={t(
          'lines_research.index.investigationProjectFilter.search.researchLinesGroup'
        )}
        isLoading={isLoading}
        isFetching={isFetching}
        listFilterArrows={{
          name: 'name',
          statusAcronym: 'status',
        }}
        filterArrow={filterArrow}
        setFilterArrow={setFilterArrow}
        error={error}
        perPage={perPage}
        setPerPage={setPerPage}
        total={data?.total || 0}
        page={page}
        setPage={setPage}
        numHeader={7}
        search={search}
        setSearch={setSearch}
        customRenderers={{
          id: (row) => row.id.index,

          statusAcronym: (row) => (
            <StatusChip
              statusId={
                statusAvailables.find(
                  (status) => status.acronym?.toLowerCase() === row.statusAcronym
                )?.id || 0
              }
            />
          ),
          options: (row) => (
            <>
              <Tooltip title={t('Agregar Docente')}>
                <IconButton
                  aria-label="Editar"
                  color={'secondary'}
                  onClick={() => handleOpen(row.id.id)}
                >
                  <IconUserPlus />
                </IconButton>
              </Tooltip>
              <Tooltip title={t('Ver docentes asignados')}>
                <IconButton
                  aria-label="Editar"
                  color="primary"
                  onClick={() => handleOpenListColl(row.id.id)}
                >
                  <IconUsers />
                </IconButton>
              </Tooltip>

              <DeleteButton handleDelete={() => handleDelete(row.id.id)} />
            </>
          ),
        }}
        ActionButtons={
          <>
            <AddButton
              btnText={t('lines_research.researchGroupTable.AddButton')}
              size="large"
              onClick={goToAdd}
            />
          </>
        }
        customDataCellsProperties={{
          id: {
            align: 'center',
          },
          collaborator: {
            align: 'center',
          },
          name: {
            align: 'center',
          },
          lineResearchName: {
            align: 'center',
          },
          project: {
            align: 'center',
          },
          statusAcronym: {
            align: 'center',
          },
          options: {
            align: 'center',
          },
        }}
      />

      {deleteModalOpenGroupResearch && <DeleteResearchGroupDialog />}

      {
        <ExtendDialog
          title={t('Agregar docente')}
          open={openDialog}
          handleCloseDialog={handleClose}
        >
          {openDialog ? (
            <AddCollaboratorGroupResearchForm isEdit={true} researchGroupId={researchGroupId} />
          ) : (
            <></>
          )}
        </ExtendDialog>
      }

      {
        <ExtendDialog
          title={t('Docentes Asignados')}
          open={openDialogListColl}
          handleCloseDialog={handleCloseListColl}
        >
          {openDialogListColl ? (
            <ListCollaboratorsDelete groupResearchId={researchGroupId} />
          ) : (
            <></>
          )}
        </ExtendDialog>
      }
    </>
  );
};

export default ResearchGroupTable;
