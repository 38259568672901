import { useParams } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import { useGetStudentAnnouncementsHistoryQuery } from '../slices/studentsAsignedApiSlice';
import { useAppSelector } from '../../../../../hooks/useRedux';
import { Chip, Grid, Paper, Typography } from '@material-ui/core';
import { useState } from 'react';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@material-ui/lab';
import moment from 'moment';
import { renderSkeletonTable } from '../../../../shared/helpers/render';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useStyles } from '../constants/styles';
import { IconMessage } from '@tabler/icons';
import { useTranslation } from 'react-i18next';

interface Props {
  id: number;
  ans_date_send: string;
  ans_message: string;
}

const StudentAsignedHistoryAnnouncement = () => {
  const { t } = useTranslation();

  const classes = useStyles();

  const [limit, setLimit] = useState<number>(6);

  const [offset, setOffset] = useState<number>(0);

  const { currentProfile } = useAppSelector((state) => state.access);

  const { studentsList, studentHistoryAnnouncements } = useAppSelector(
    (state) => state.studentsAsigned
  );

  const { studentId } = useParams<{ studentId: string }>();

  const { data, isSuccess } = useGetStudentAnnouncementsHistoryQuery({
    studentId: Number(studentId),
    user_profile_id: currentProfile?.profileId!,
    limit: limit,
    offset: offset,
  });

  const dataFetch = studentHistoryAnnouncements?.map((value) => ({
    id: value.id,
    ans_date_send: value.ans_date_send,
    ans_message: value.ans_message,
  }));

  const renderTime = ({ id, ans_date_send, ans_message }: Props) => {
    return (
      <TimelineItem key={`${ans_date_send}-${id}`}>
        <TimelineOppositeContent>
          <Typography variant="body2" color="textSecondary">
            {moment(ans_date_send!).fromNow()}
          </Typography>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot color="primary">
            <IconMessage />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={3} className={classes.paper}>
            <Typography>{ans_message}</Typography>
            {moment(ans_date_send).isSame(moment(), 'day') && (
              <Grid container justifyContent="flex-end">
                <Chip
                  label={t('teachers.academic_counseling.students_assigned.history.recent')}
                  color="primary"
                  size="small"
                />
              </Grid>
            )}
          </Paper>
        </TimelineContent>
      </TimelineItem>
    );
  };

  const renderTimes = () => {
    return <>{dataFetch?.map(renderTime)}</>;
  };

  const fetchMoreData = () => {
    setOffset(studentHistoryAnnouncements?.length!);
    setLimit(6);
  };

  if (studentsList.length === 0) {
    return <Redirect to="/administrar-estudiantes-asignados" />;
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={12}>
        {isSuccess ? (
          <InfiniteScroll
            dataLength={studentHistoryAnnouncements?.length!}
            next={fetchMoreData}
            hasMore={data?.length! > 5}
            loader={renderSkeletonTable(2, 6)}
            endMessage={
              <Typography variant="h5">
                {t('teachers.academic_counseling.students_assigned.history.no_data')}
              </Typography>
            }
          >
            <Timeline position="alternate">{renderTimes()}</Timeline>
          </InfiniteScroll>
        ) : (
          renderSkeletonTable(2, 6)
        )}
      </Grid>
    </Grid>
  );
};

export default StudentAsignedHistoryAnnouncement;
