import { Grid, Typography, IconButton, Tooltip } from '@material-ui/core';
import { RemoveRedEye } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import MainCard from '../../components/cards/MainCard';
import CardViewButton from '../../components/tables/buttons/CardViewButton';
import TableViewButton from '../../components/tables/buttons/TableViewButton';
import UserEvaluationList from '../components/UserEvaluationList';
import { setUserEvaluationListView } from '../slices/userEvaluationsSlice';

const UserEvaluationsScreen = () => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const navigate = useHistory();

  const { userEvaluationListView } = useAppSelector((state) => state.userEvaluations);

  const handleViewChange = (view: 'list' | 'table') => {
    dispatch(setUserEvaluationListView(view));
  };

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid container alignItems="center" justifyContent="space-between" sx={{ mb: 3 }}>
        <Typography variant="h2">Evaluaciones</Typography>
        <Grid item>
          <Tooltip title={t('Ver Resultados')}>
            <IconButton
              onClick={() => {
                navigate.push('/evaluaciones-usuario/formularios/formularios-usuario');
              }}
            >
              <RemoveRedEye />
            </IconButton>
          </Tooltip>

          <TableViewButton
            selected={userEvaluationListView === 'table'}
            onClick={() => handleViewChange('table')}
          />
          <CardViewButton
            selected={userEvaluationListView === 'list'}
            onClick={() => handleViewChange('list')}
          />
        </Grid>
      </Grid>
      <UserEvaluationList />
    </Grid>
  );
};

export default UserEvaluationsScreen;
