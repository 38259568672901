import { useEffect, useState } from 'react';

import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

import { newOption, newOptionEdit } from '../constants/optionsTemplate.constants';
import { IOptions, IOptionsTemplate } from '../interfaces/optionsTemplate.interface';
import { optionsTemplateFormSchema } from '../validation/optionsTemplate.validation';
import {
  useAddOptionsTemplateMutation,
  useUpdateOptionsTemplateMutation,
} from '../slices/optionsTemplateApiSlice';
import { useAppSelector } from '../../../../../../hooks/useRedux';
import {
  Alert,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from '@material-ui/core';
import SuccessAlert from '../../../../../shared/components/alerts/SuccessAlert';
import ErrorAlert from '../../../../../shared/components/alerts/ErrorAlert';
import AddIconButton from '../../../../../shared/components/buttons/AddIconButton';
import DeleteButton from '../../../../../shared/components/tables/buttons/DeleteButton';

const OptionsTemplateForm = () => {
  const { t } = useTranslation();
  const [showAlerts, setShowAlerts] = useState(false);

  const [addOptionsTemplate, { isLoading, isError, error, isSuccess }] =
    useAddOptionsTemplateMutation();

  const [
    updateOptionsTemplate,
    { isLoading: isLoadingU, isError: isErrorU, error: errorU, isSuccess: isSuccessU },
  ] = useUpdateOptionsTemplateMutation();

  const { optionsTemplateList, optionsTemplateIdSelected, optionsTemplateSideDialogOpen } =
    useAppSelector((state) => state.optionsTemplate);

  const optionsTemplateSelected = optionsTemplateList.find(
    (option) => option.uuid === optionsTemplateIdSelected
  );

  console.log(optionsTemplateSelected);

  const { errors, getFieldProps, handleSubmit, touched, values, setFieldValue, resetForm } =
    useFormik<IOptionsTemplate>({
      initialValues: {
        description: optionsTemplateSelected?.description || '',
        options:
          optionsTemplateSelected?.options ||
          Array.from({ length: 2 }, () => {
            return newOption;
          }),
      },
      validationSchema: optionsTemplateFormSchema,
      onSubmit: async (currentValues) => {
        if (optionsTemplateIdSelected) {
          await updateOptionsTemplate({
            id: optionsTemplateIdSelected,
            description: currentValues.description,
            options: currentValues.options,
          });
        } else {
          await addOptionsTemplate({
            description: currentValues.description,
            options: currentValues.options,
          });
        }
        setShowAlerts(true);
      },
    });

  useEffect(() => {
    if (isSuccess) {
      resetForm();
    }
  }, [isSuccess, resetForm]);

  const handleAddOption = () => {
    if (optionsTemplateIdSelected) {
      setFieldValue('options', [...values.options, newOptionEdit]);
    } else {
      setFieldValue('options', [...values.options, newOption]);
    }
  };

  const handleDeleteOption = (index: number) => {
    setFieldValue(
      'options',
      values.options.filter((_, i) => i !== index)
    );
  };

  return (
    <Grid container spacing={2}>
      {(isSuccess || isSuccessU) && showAlerts && (
        <Grid item xs={12}>
          <SuccessAlert
            message={
              isSuccess
                ? t('maintenance.options_template.add.success')
                : t('maintenance.options_template.edit.success')
            }
            handleDismiss={() => setShowAlerts(false)}
          />
        </Grid>
      )}
      {(isError || isErrorU) && showAlerts && (
        <Grid item xs={12}>
          <ErrorAlert
            message={(error as string) || (errorU as string)}
            handleDismiss={() => setShowAlerts(false)}
          />
        </Grid>
      )}

      <Grid item xs={12}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={optionsTemplateSideDialogOpen ? 12 : 8}>
              <InputLabel>{t('maintenance.options_template.form.name')}</InputLabel>
              <TextField
                placeholder={t('maintenance.options_template.form.name.placeholder')}
                error={Boolean(errors.description && touched.description)}
                {...getFieldProps('description')}
                fullWidth
              />
              {errors.description && touched.description && (
                <FormHelperText error>{t(errors.description)}</FormHelperText>
              )}
            </Grid>
            <Grid
              item
              container
              xs={12}
              md={optionsTemplateSideDialogOpen ? 12 : 8}
              justifyContent="space-between"
              alignItems="center"
              mt={2}
            >
              <Typography variant="h4">{t('maintenance.options_template.form.options')}</Typography>
              <AddIconButton btnText="Agregar opción" onClick={handleAddOption} />
            </Grid>

            <Grid item xs={optionsTemplateSideDialogOpen ? 12 : 8} justifyContent="center">
              <Alert severity="info">{t('maintenance.options_template.form.options.min')}</Alert>
            </Grid>
            {/* {values.options.length < 2 && (
              <Grid
                item
                container
                xs={12}
                md={optionsTemplateSideDialogOpen ? 12 : 8}
                justifyContent="center"
              >
                <FormHelperText error>
                  {t('maintenance.options_template.form.options.min')}
                </FormHelperText>
              </Grid>
            )} */}
            <Grid item xs={12} justifyContent="center">
              {values.options?.length > 0 ? (
                <>
                  {values.options.map((option, index) => (
                    <Grid
                      item
                      container
                      xs={12}
                      md={optionsTemplateSideDialogOpen ? 12 : 8}
                      spacing={1}
                      key={`option-${index + 1}`}
                      sx={{
                        my: 1,
                      }}
                    >
                      <Grid item xs={5}>
                        <InputLabel>
                          {t('maintenance.options_template.form.options.value')}
                        </InputLabel>
                        <TextField
                          placeholder={t(
                            'maintenance.options_template.form.options.value.placeholder'
                          )}
                          error={Boolean(
                            (errors.options?.[index] as IOptions)?.value &&
                              touched.options?.[index]?.value
                          )}
                          {...getFieldProps(`options[${index}].value`)}
                          fullWidth
                        />
                        {errors.options?.[index] && touched.options?.[index]?.value && (
                          <FormHelperText error>
                            {t((errors.options?.[index] as IOptions).value)}
                          </FormHelperText>
                        )}
                      </Grid>
                      <Grid item xs={5}>
                        <InputLabel>
                          {t('maintenance.options_template.form.options.name')}
                        </InputLabel>
                        <TextField
                          placeholder={t(
                            'maintenance.options_template.form.options.name.placeholder'
                          )}
                          error={Boolean(
                            (errors.options?.[index] as IOptions)?.description &&
                              touched.options?.[index]?.description
                          )}
                          {...getFieldProps(`options[${index}].description`)}
                          fullWidth
                        />
                        {errors.options?.[index] && touched.options?.[index]?.description && (
                          <FormHelperText error>
                            {t((errors.options?.[index] as IOptions).description)}
                          </FormHelperText>
                        )}
                      </Grid>
                      <Grid item container xs={2} justifyContent="center" alignItems="center">
                        <DeleteButton handleDelete={() => handleDeleteOption(index)} />
                      </Grid>
                    </Grid>
                  ))}
                </>
              ) : (
                <Grid item xs={12} md={optionsTemplateSideDialogOpen ? 12 : 8}>
                  <Typography variant="h5" textAlign="center">
                    {t('maintenance.options_template.form.options.no_data')}
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Grid
              item
              container
              xs={12}
              md={optionsTemplateSideDialogOpen ? 12 : 8}
              justifyContent="flex-end"
              spacing={2}
            >
              <Grid item>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  disabled={isLoading || isLoadingU}
                  endIcon={isLoading || (isLoadingU && <CircularProgress size={12} />)}
                >
                  {!optionsTemplateIdSelected
                    ? t('maintenance.options_template.add.submit')
                    : t('maintenance.options_template.edit.submit')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default OptionsTemplateForm;
