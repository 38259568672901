import { Switch } from 'react-router-dom';

import GuardRoute from '../../../../router/guards/GuardRoute';
import UserBorrowedBooks from '../screens/UserBorrowedBooks';

const BorrowedBooksRouter = () => {
  return (
    <Switch>
      <GuardRoute exact path="/biblioteca-prestamos" component={UserBorrowedBooks} />
    </Switch>
  );
};

export default BorrowedBooksRouter;
