import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISearchTable } from '../../../shared/interfaces/paginatedTable.interface';
import { ClassroomEdLvl } from '../interfaces/classroomEdLvl.interface';

interface ClassroomEdLvlState {
  classroomEdLvlList: ClassroomEdLvl[];
  deleteModalOpen: boolean;
  classroomEdLvlIdToDelete?: number;
  classroomEdLvlTable: ISearchTable;
}

const initialState: ClassroomEdLvlState = {
  classroomEdLvlList: [],
  deleteModalOpen: false,
  classroomEdLvlTable: {
    page: 1,
    perPage: 8,
    search: '',
  },
};

const classroomEdLvlSlice = createSlice({
  name: 'classroomEdLvl',
  initialState,
  reducers: {
    setClassroomEdLvlList: (state, action: PayloadAction<ClassroomEdLvl[]>) => {
      state.classroomEdLvlList = action.payload;
    },

    setDeleteModalOpen: (state, action: PayloadAction<boolean>) => {
      state.deleteModalOpen = action.payload;
    },
    openDeleteModalWith: (state, action: PayloadAction<number>) => {
      state.classroomEdLvlIdToDelete = action.payload;
      state.deleteModalOpen = true;
    },
    resetClassroomEdLvlIdToDelete: (state) => {
      state.classroomEdLvlIdToDelete = undefined;
    },
    updateClassroomEdLvlTable: (state, action: PayloadAction<ISearchTable>) => {
      state.classroomEdLvlTable = action.payload;
    },
  },
});

export const {
  setClassroomEdLvlList,
  setDeleteModalOpen,
  openDeleteModalWith,
  resetClassroomEdLvlIdToDelete,
  updateClassroomEdLvlTable,
} = classroomEdLvlSlice.actions;

export default classroomEdLvlSlice.reducer;
