import { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import CalendarPicker from '@material-ui/lab/CalendarPicker';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import PickersDay from '@material-ui/lab/PickersDay';
import esLocale from 'date-fns/locale/es';
import { getTime, getMonth, getYear, setMonth } from 'date-fns';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';

import { useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import CalendarPopper from './builder/CalendarPopper';
import { useAppSelector } from '../../../../hooks/useRedux';
import { useGetStudentActivitiesNotPagedQuery } from '../slices/dashboardApiSlice';
import { CalendarPickerSkeleton } from '@material-ui/lab';

// style constant
const useStyles = makeStyles((theme) => ({
  monthPicker: {
    // [theme.breakpoints.down('sm')]: {
    //   transform: 'scale(0.8)',
    // },
    // [theme.breakpoints.down('md')]: {
    //   transform: 'scale(1)',
    // },
    [theme.breakpoints.between(960, 1400)]: {
      transform: 'scale(0.8)',
    },

    '& [class^="PrivatePickersSlideTransition-root"]': {
      // backgroundColor: 'blue',
      minHeight: '230px',
    },
    '& [class^="MuiButtonBase-root MuiPickersDay-root MuiPickersDay-dayWithMargin css-umg0an-MuiButtonBase-root"]':
      {
        fontWeight: 500,
        // backgroundColor: theme.palette.secondary.light,
        backgroundColor: 'transparent',
      },
  },
}));

const EventsCalendar = () => {
  const theme = useTheme();
  const classes = useStyles();

  const history = useHistory();

  const { t } = useTranslation();

  const [date, setDate] = useState<Date | null>(new Date());

  const changeMonthHandler = (month: Date) => {
    if (date) return setDate(setMonth(date, getMonth(month)));

    return setDate(new Date());
  };

  // const changeYearHandler = (year: Date) => {
  //   if (date) return setDate(setYear(date, getYear(date)));

  //   return setDate(new Date());
  // };
  // console.log(changeYearHandler);

  // const [highlightedDays] = useState([1, 2, 15]);

  const { currentStudent: { id: studentId } = { id: undefined } } = useAppSelector(
    (state) => state.access
  );

  const { data, isFetching } = useGetStudentActivitiesNotPagedQuery(
    {
      studentId: studentId!,
      isCalendar: true,
      month: getMonth(date!) + 1,
      year: getYear(date!),
    },
    { skip: !studentId || !date }
  );

  console.log(data);

  const [openDay, setOpenDay] = useState(-1);

  return (
    <Box
      sx={{
        // border: `1px solid ${theme.palette.primary[200]}`,
        backgroundColor: `${theme.palette.secondary.light}`,
        padding: 2,
        height: '100%',
        borderRadius: '12px',
      }}
    >
      <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
        <Grid item>{t('students.dashboard.events_calendar.title')}</Grid>
        <Grid item>
          <Button
            size="small"
            variant="text"
            color="primary"
            onClick={() => history.push('/mis-actividades')}
          >
            {t('students.dashboard.recent_applications.title.button')}
          </Button>
        </Grid>
      </Grid>
      <Grid container justifyContent="center" spacing={2}>
        <Grid item>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                {isFetching ? (
                  <CalendarPickerSkeleton
                    style={{
                      width: 'max-content',
                    }}
                  />
                ) : (
                  <CalendarPicker
                    date={date}
                    onChange={setDate}
                    allowSameDateSelection
                    className={`${classes.monthPicker}`}
                    onMonthChange={changeMonthHandler}
                    // onYearChange={changeYearHandler}
                    renderDay={(day, _value, DayComponentProps) => {
                      const dayNumber = getTime(day);

                      return (
                        <CalendarPopper
                          key={day.toString()}
                          data={data || []}
                          day={dayNumber}
                          dayDate={day}
                          openDay={openDay}
                          setOpenDay={setOpenDay}
                          PickerDayComponent={PickersDay}
                          dayComponentProps={DayComponentProps}
                        />
                      );
                    }}
                  />
                )}
              </Grid>
            </Grid>
          </LocalizationProvider>
        </Grid>

        {false && (
          <Grid item>
            <Button
              variant="contained"
              color="info"
              sx={{
                border: 'none',
                borderRadius: `12px`,
                px: 4,
                // backgroundColor: `${theme.palette.primary.light}`,
                // color: `${theme.palette.primary.main}`,
                fontWeight: 800,
                fontSize: '1.2rem',
              }}
              onClick={() => history.push('/eventos-visualizar')}
            >
              {t('students.dashboard.events_calendar.view_more_events_btn')}
            </Button>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default EventsCalendar;
