import React, { useEffect, useState } from 'react';

// material-ui
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  TextField,
  InputAdornment,
  Button,
  IconButton,
  CircularProgress,
  Autocomplete,
  useTheme,
} from '@material-ui/core';

import SearchIcon from '@material-ui/icons/Search';
import { renderErrorOrEmptyRow, renderSkeletonRows } from '../../../shared/helpers/render';

// access

import { useAppSelector } from '../../../../hooks/useRedux';

// helpers
import { objectKeys } from '../../../shared/helpers';

// types

import {
  CustomCellProperties,
  CustomRenderers,
  TableHeaders,
} from '../../../shared/interfaces/material-ui.interfaces';

import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { SerializedError } from '@reduxjs/toolkit';

import { DebounceInput } from 'react-debounce-input';
import { IconX } from '@tabler/icons';

// Date
import { DateRange } from '@material-ui/lab';
import { IconArrowNarrowDown, IconArrowNarrowUp } from '@tabler/icons';

// Data available
import {
  useGetAvailablesOffersQuery,
  useGetInfoOfferQuery,
} from '../../academic-offerings/slices/academicOfferingsApiSlice';

import { statusList } from '../constants/statusRequisition';
import { useTranslation } from 'react-i18next';
import CustomPaginationAndItems from '../../../shared/components/tables/CustomPaginationAndItems';
import { FilterArrow } from '../../../shared/components/tables/SearchPaginatedTable';
import useFormatDate from '../../../shared/hooks/useFormatDate';
import LocalizedDateRangePicker from '../../../shared/components/controlled/LocalizedDateRangePicker';
import useSharedGuard from '../../../../hooks/useSharedGuard';
import { subPermissionKeys } from '../constants/permissions';

// interfaces
// import { EducationLevel } from '../../education-levels/interfaces/educationLevels.interfaces';

export interface DataSearch {
  startEndDate: DateRange<Date>;
  offerId: number;
  nivelId: number;
  statusRequisition: string;
}

interface ListFilterArrow {}
interface Props<T extends {}> {
  headers: TableHeaders<T>;
  data: T[];
  keyExtractor: (item: T) => string;
  customRenderers?: CustomRenderers<T>;
  searchPlacehoder: string;
  customDataCellsProperties?: CustomCellProperties<T>;
  customHeadersCellsProperties?: CustomCellProperties<T>;
  isLoading: boolean;
  isFetching: boolean;
  error: FetchBaseQueryError | SerializedError | undefined;
  total: number;
  perPage: number;
  setPerPage: React.Dispatch<React.SetStateAction<number>>;
  page: number;
  numHeader: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  ActionButtons?: JSX.Element;
  lastPage?: number;
  setDataSearch: React.Dispatch<React.SetStateAction<DataSearch>>;
  dataSearch: DataSearch;
  showFilter?: boolean;
  listFilterArrows?: ListFilterArrow;
  setFilterArrow?:
    | React.Dispatch<React.SetStateAction<FilterArrow>>
    | (() => void)
    | ((filterArrow: FilterArrow) => void);
  filtersPos?: 'top' | 'bottom';
  filterArrow?: FilterArrow;
}

const SearchPaginatedTable = <T extends {}>({
  data,
  headers,
  customRenderers,
  keyExtractor,
  searchPlacehoder,
  customDataCellsProperties,
  customHeadersCellsProperties,
  numHeader,
  isLoading,
  isFetching,
  error,
  perPage,
  setPerPage,
  total,
  page,
  setPage,
  search,
  setSearch,
  ActionButtons,
  lastPage,
  setDataSearch,
  dataSearch,
  showFilter = true,
  listFilterArrows = {},
  setFilterArrow: setFilterArrowDispatch,
  filtersPos = 'bottom',
  filterArrow: customFilterArrow,
}: Props<T>) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const renderRows = () => {
    try {
      return <>{data!.map(renderRow)}</>;
    } catch (error) {
      console.log(error);
    }
  };

  const { hasAccess } = useSharedGuard(subPermissionKeys);

  const { currentProfile } = useAppSelector((state) => state.access);

  const profileId = currentProfile?.profileId!;

  const { formatTableDate } = useFormatDate();

  const [filterArrow, setFilterArrow] = useState<FilterArrow>(customFilterArrow || null!);

  const handleFilterArrowDown = (name: string, direction: string, nameHeader: string) => {
    setFilterArrow({ name: nameHeader, direction });
    if (setFilterArrowDispatch) setFilterArrowDispatch({ name, direction });
  };

  const handleFilterArrowUp = (name: string, direction: string, nameHeader: string) => {
    setFilterArrow({ name: nameHeader, direction });
    if (setFilterArrowDispatch) setFilterArrowDispatch({ name, direction });
  };

  /// Take data
  const [offerId, setOfferId] = useState<number>(dataSearch.offerId || 0);
  const [nivelId, setNivelId] = useState<number>(dataSearch.nivelId || 0);
  const [statusRequisition, setStatusRequisition] = useState<string>(
    dataSearch.statusRequisition || ''
  );
  const [startEndDate, setStartEndDate] = useState<DateRange<Date>>(
    dataSearch.startEndDate || [null, null]
  );

  const dataSearchRequisition: DataSearch = {
    startEndDate,
    offerId: offerId,
    nivelId: nivelId,
    statusRequisition,
  };

  const dataSearchBlank: DataSearch = {
    startEndDate: [null, null],
    offerId: 0,
    nivelId: 0,
    statusRequisition: '',
  };

  const { data: availablesOffers = [], isLoading: isLoadingOffers } = useGetAvailablesOffersQuery({
    profileId,
  });

  const [skipValue, setSkipValue] = useState<boolean>(true);

  const {
    data: infoOffer = [],
    isLoading: isLoadingInfoOffer,
    isFetching: isFetchingInfoOffer,
  } = useGetInfoOfferQuery(
    {
      profileId,
      offerId: offerId.toString(),
    },
    { skip: skipValue }
  );

  useEffect(() => {
    if (offerId) {
      setSkipValue(false);
    }
  }, [offerId]);

  const renderRow = (row: T) => {
    return (
      <TableRow className="table-row" key={keyExtractor(row)}>
        {objectKeys(row).map((cell) => {
          const customRenderer = customRenderers?.[cell];

          if (customRenderer) {
            return (
              <TableCell
                key={`${keyExtractor(row)}-${cell.toString()}`}
                {...customDataCellsProperties?.[cell]}
              >
                {customRenderer(row)}
              </TableCell>
            );
          }

          return (
            <TableCell
              key={`${keyExtractor(row)}-${cell.toString()}`}
              {...customDataCellsProperties?.[cell]}
            >
              {formatTableDate(row[cell])}
              {/* {isPrimitive(row[cell]) ? row[cell] : ''} */}
            </TableCell>
          );
        })}
      </TableRow>
    );
  };

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
        <Grid item xs={12} md={4}>
          <DebounceInput
            autoFocus={Boolean(search)}
            minLength={2}
            debounceTimeout={300}
            onChange={({ target }) => setSearch(target.value)}
            value={search}
            element={(props) => (
              <TextField
                style={{
                  width: '100%',
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon fontSize="small" />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        size="small"
                        onClick={() => setSearch('')}
                        {...(!search && { sx: { cursor: 'initial', opacity: 0 } })}
                      >
                        <IconX size={20} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                placeholder={searchPlacehoder}
                variant="outlined"
                size="small"
                {...props}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6} sx={{ textAlign: 'right' }}>
          {ActionButtons}
        </Grid>
        <Grid item container spacing={2} xs={12}>
          {/* Take Date */}

          <Grid item xs={12} md={4}>
            <LocalizedDateRangePicker
              startText={t('academic.requisition.table.start_date')}
              endText={t('academic.requisition.table.end_date')}
              value={startEndDate}
              onChange={(newValue) => {
                setStartEndDate(newValue);
              }}
            />
          </Grid>
          {/* end Take Date */}

          {/* Take  education Lvl */}
          <Grid item container spacing={2} xs={12} md={4}>
            {hasAccess('showOffers') && (
              <Grid item xs={6}>
                {isLoadingOffers ? (
                  <>
                    <TextField
                      disabled
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <CircularProgress size={25} />
                          </InputAdornment>
                        ),
                      }}
                      placeholder={t('academic.requisition.table.edu_level.loading')}
                    />
                  </>
                ) : (
                  <Autocomplete
                    options={availablesOffers}
                    loading={isLoadingOffers}
                    loadingText={t('academic.requisition.table.edu_level.loading')}
                    noOptionsText={t('academic.requisition.table.edu_level.no_data')}
                    getOptionLabel={(option) => option.off_name}
                    value={availablesOffers.find((e) => e.id === offerId) || null}
                    onChange={(_, value) => setOfferId(value?.id || 0)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t('academic.requisition.table.edu_level')}
                        placeholder={t('academic.requisition.table.edu_level.placeholder')}
                      />
                    )}
                    fullWidth
                  />
                )}
              </Grid>
            )}

            {hasAccess('showEduLvls') && (
              <Grid item xs={6}>
                {isLoadingInfoOffer || isFetchingInfoOffer ? (
                  <>
                    <TextField
                      disabled
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <CircularProgress size={25} />
                          </InputAdornment>
                        ),
                      }}
                      placeholder={t('academic.requisition.table.academic_unit.loading')}
                    />
                  </>
                ) : (
                  <Autocomplete
                    options={infoOffer}
                    loading={isLoadingInfoOffer}
                    loadingText={t('academic.requisition.table.academic_unit.loading')}
                    noOptionsText={t('academic.requisition.table.academic_unit.no_data')}
                    value={infoOffer.find((e) => e.id === nivelId) || null}
                    getOptionLabel={(option) => option.edu_name}
                    onChange={(_, value) => setNivelId(value?.id || 0)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t('academic.requisition.table.academic_unit')}
                        placeholder={t('academic.requisition.table.academic_unit.placeholder')}
                      />
                    )}
                    fullWidth
                  />
                )}
              </Grid>
            )}
          </Grid>
          {/* Take status */}
          <Grid item container spacing={2} xs={12} md={2}>
            <Grid item xs={12}>
              <Autocomplete
                options={statusList || []}
                loadingText={t('academic.requisition.table.req_status.loading')}
                noOptionsText={t('academic.requisition.table.req_status.no_data')}
                value={statusList.find((e) => e.name === statusRequisition) || null}
                onChange={(_, value) => setStatusRequisition(value?.name || '')}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('academic.requisition.table.req_status')}
                    placeholder={t('academic.requisition.table.req_status.placeholder')}
                  />
                )}
                fullWidth
              />
            </Grid>
          </Grid>
          {/* End status */}
          <Grid
            item
            container
            spacing={2}
            direction="row"
            justifyContent="end"
            alignItems="flex-start"
            xs={12}
          >
            <Grid item xs={6} sm={3} md={2}>
              <Button
                onClick={() => {
                  setStartEndDate([null, null]);
                  setOfferId(0);
                  setNivelId(0);
                  setStatusRequisition('');
                  setDataSearch(dataSearchBlank);
                }}
                fullWidth
                variant="outlined"
              >
                {t('academic.requisition.table.reset')}
              </Button>
            </Grid>
            <Grid item xs={6} sm={3} md={2}>
              <Button
                fullWidth
                onClick={() => {
                  console.log('dataSearchRequisition', dataSearchRequisition);
                  setDataSearch(dataSearchRequisition);
                }}
                variant="contained"
              >
                {t('academic.requisition.table.filter')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {Object.keys(headers).map((key) => (
                <TableCell key={key} {...customHeadersCellsProperties?.[key as keyof T]}>
                  <Grid container justifyContent="center" alignItems="center">
                    <Grid item>{headers[key as keyof T]}</Grid>
                    {Object.keys(listFilterArrows).some((e) => e === key) && (
                      <Grid item>
                        <IconArrowNarrowDown
                          style={{
                            color:
                              filterArrow?.direction === 'asc' &&
                              filterArrow?.name === listFilterArrows[key as keyof ListFilterArrow]
                                ? theme.palette.primary.main
                                : 'inherit',
                            cursor: 'pointer',
                          }}
                          stroke={
                            filterArrow?.direction === 'asc' &&
                            filterArrow?.name === listFilterArrows[key as keyof ListFilterArrow]
                              ? 2
                              : 1
                          }
                          size={18}
                          onClick={() =>
                            handleFilterArrowUp(
                              listFilterArrows[key as keyof ListFilterArrow],
                              'asc',
                              listFilterArrows[key as keyof ListFilterArrow]
                            )
                          }
                        />
                        <IconArrowNarrowUp
                          style={{
                            color:
                              filterArrow?.direction === 'desc' &&
                              filterArrow?.name === listFilterArrows[key as keyof ListFilterArrow]
                                ? theme.palette.primary.main
                                : 'inherit',
                            cursor: 'pointer',
                          }}
                          stroke={
                            filterArrow?.direction === 'desc' &&
                            filterArrow?.name === listFilterArrows[key as keyof ListFilterArrow]
                              ? 2
                              : 1
                          }
                          size={18}
                          onClick={() =>
                            handleFilterArrowDown(
                              listFilterArrows[key as keyof ListFilterArrow],
                              'desc',
                              listFilterArrows[key as keyof ListFilterArrow]
                            )
                          }
                        />
                      </Grid>
                    )}
                  </Grid>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isFetching ? renderSkeletonRows(perPage, numHeader) : renderRows()}

            {error && renderErrorOrEmptyRow(numHeader, error as string)}
            {data?.length === 0 && !isFetching && renderErrorOrEmptyRow(numHeader)}
          </TableBody>
        </Table>
      </TableContainer>

      <CustomPaginationAndItems
        error={error}
        isLoading={isLoading}
        total={total}
        perPage={perPage}
        page={page}
        setPage={setPage}
        setPerPage={setPerPage}
        perPageBox
        paginationBox
      />
    </>
  );
};

export default SearchPaginatedTable;
