import {
  IconGizmo,
  IconCheckupList,
  IconFileInfo,
  IconBuilding,
  IconList,
  IconZoomQuestion,
  IconSettings,
  IconUsers,
  IconUser,
  IconRocket,
  IconChecklist,
  IconFile,
} from '@tabler/icons';
import { shared } from '../../shared';

// Icon Group
const icons = {
  IconGizmo,
  IconCheckupList,
  IconFileInfo,
  IconBuilding,
  IconList,
  IconZoomQuestion,
  IconSettings,
  IconUsers,
  IconUser,
  IconRocket,
  IconChecklist,
  IconFile,
};

export const evaluations = {
  id: 'evaluation',
  type: 'group',
  title: 'Evaluaciones',
  url: '/dashboard',
  children: [
    // {
    //   id: 'evaluation',
    //   title: 'Evaluación',
    //   menuId: 'evaluation.menu.evaluation',
    //   type: 'item',
    //   icon: icons['IconFileInfo'],
    //   permissionKey: 'evaluation',
    //   url: '/evaluaciones',
    // },
    // {
    //   id: 'scope',
    //   title: 'Ámbitos de Evaluación',
    //   menuId: 'evaluation.menu.scope',
    //   type: 'collapse',
    //   icon: icons['IconCheckupList'],
    //   permissionKey: 'evaluation',
    //   url: '/ambitos',
    // },
    // {
    //   id: 'integrales',
    //   title: 'Evaluaciones Integrales',
    //   menuId: 'evaluation.menu.integrales',
    //   type: 'item',
    //   icon: icons['IconCheckupList'],
    //   permissionKey: 'evaluation',
    //   url: '/evaluacion-integrales',
    // },
    // {
    //   id: 'evaluation-user',
    //   title: 'Evaluaciones de Usuario',
    //   menuId: 'evaluation-user.menu.evaluation-user',
    //   type: 'item',
    //   icon: icons['IconCheckupList'],
    //   permissionKey: 'evaluation',
    //   url: '/evaluacion-usuario',
    // },
    // {
    //   id: 'groups-evaluation',
    //   title: 'Grupos de Evaluación',
    //   menuId: 'evaluation-user.menu.groups.evaluations',
    //   type: 'item',
    //   icon: icons['IconUsers'],
    //   permissionKey: 'evaluation',
    //   url: '/grupos/listar-grupos',
    // },
    // {
    //   id: 'evaluation-user',
    //   title: 'Evaluaciones de Usuario',
    //   menuId: 'evaluation-user.menu.evaluation-user',
    //   type: 'item',
    //   icon: icons['IconCheckupList'],
    //   permissionKey: 'evaluation',
    //   url: '/evaluaciones-usuario',
    // },
    // {
    //   id: 'forms-eva-for-user',
    //   title: 'Resultados',
    //   type: 'item',
    //   icon: icons['IconFileInfo'],
    //   permissionKey: 'evaluation',
    //   url: '/formularios/formularios-usuario',
    // },

    {
      id: 'administration-evaluation',
      title: 'Administración',
      menuId: 'evaluation.menu.management-evaluation',
      type: 'collapse',
      icon: icons['IconChecklist'],
      permissionKey: 'evaluation',
      children: [
        {
          id: 'launching_integral_evaluation',
          title: 'Lanzamiento',
          menuId: 'evaluation.menu.launching.integral_evaluation',
          type: 'item',
          // icon: icons['IconRocket'],
          permissionKey: 'evaluation',
          url: '/lanzamiento',
        },
        {
          id: 'launching_integral_evaluation_results',
          title: 'Resultados de la evaluación individual',
          menuId: 'Resultados individuales',
          type: 'item',
          // icon: icons['IconRocket'],
          permissionKey: 'evaluation',
          url: '/lanzamiento/lanzamiento-resultados',
        },
        {
          id: 'forms-eva-results',
          title: 'Resultados Integrales',
          type: 'item',
          // icon: icons['IconFileInfo'],
          permissionKey: 'evaluation',
          url: '/formularios/resultados',
        },
      ],
    },
    {
      id: 'configuration-evaluation',
      title: 'Configuración',
      menuId: 'evaluation.menu.configuration-evaluation',
      type: 'collapse',
      icon: icons['IconSettings'],
      permissionKey: 'evaluation',
      children: [
        {
          id: 'evaluation-type',
          title: 'Rúbrica',
          menuId: 'evaluation.menu.evaluation.type',
          type: 'item',
          // icon: icons['IconCheckupList'],
          permissionKey: 'evaluation',
          url: '/tipos-de-evaluacion',
        },
        {
          id: 'ambitos',
          title: 'Secciones',
          type: 'item',
          // icon: icons['IconFileInfo'],
          permissionKey: 'evaluation',
          url: '/ambitos',
        },
        {
          id: 'preguntas',
          title: 'Preguntas',
          type: 'item',
          url: '/preguntas',
          permissionKey: 'evaluation',
          // icon: icons['IconZoomQuestion'],
        },
        {
          id: 'integral_evaluation',
          title: 'Evaluación Integral',
          menuId: 'evaluation.menu.integral_evaluation',
          type: 'item',
          // icon: icons['IconBuilding'],
          permissionKey: 'evaluation',
          url: '/rubricas',
        },
        {
          url: '/escalas',
          id: 'options_template',
          title: 'Plantillas de Opciones',
          menuId: 'evaluation.menu.options_template',
          type: 'item',
          // icon: icons['IconList'],
          permissionKey: 'evaluation',
        },

        {
          id: 'template-eva',
          title: 'Formatos',
          menuId: 'Formatos',
          type: 'item',
          // icon: icons['IconFile'],
          permissionKey: 'evaluation',
          url: '/plantilla',
        },
      ],
    },

    ...shared,
  ],
};
