import { Autocomplete, Button, CircularProgress, Grid, TextField } from '@material-ui/core';
import { useFormik } from 'formik';
import { useAppSelector } from '../../../../hooks/useRedux';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import { useGetCollaboratorsQuery } from '../../collaborators/slices/collaboratorsApiSlice';
import { useSendApplicationRrHhMutation } from '../../slices/rrhhApiSlice';
import * as yup from 'yup';
import { DatePicker, LocalizationProvider } from '@material-ui/lab';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import { es } from 'date-fns/locale';
import InputLabel from '../../../shared/components/forms/InputLabel';
import NoCollaboratorInfo from '../../messages/NoCollaboratorInfo';
import { getRRHHPeriods } from '../../helpers/rrhhhelper';

const PersonalDayForm = () => {
  const { userInfo } = useAppSelector((state) => state.auth);
  const { currentProfile } = useAppSelector((state) => state.access);

  const { data: collaborators, isLoading: isLoadingCollaborators } = useGetCollaboratorsQuery({
    page: 1,
    perPage: 100,
    profileId: currentProfile?.profileId!,
    search: '',
  });

  const schema = yup.object().shape({
    period: yup.string().required('Periodo es requerido'),
    start: yup.string().nullable().required('Fecha es requerida'),
    replace: yup.string().required('Reemplazo/Responsable es requerido'),
    //director: yup.string().required('Director es requerido'),
    boss: yup.string().required('Jefe Inmediato es requerido'),
  });

  const [
    sendApp,
    {
      isLoading: isLoadingApp,
      isSuccess: isSuccessApp,
      isError: isErrorApp,
      error: errorApp,
      reset,
    },
  ] = useSendApplicationRrHhMutation();

  const formik = useFormik({
    initialValues: {
      period: '',
      start: '',
      replace: '',
      boss: '',
    },
    validationSchema: schema,
    onSubmit: (values) => {
      sendApp({
        type_application_acronym: 'DOCPDA',
        type_application_id: 31,
        collaborator_id: userInfo.collaboratorInfo?.id!,
        immediate_boss_id: Number(values.boss),
        period: Number(values.period),
        start_date: values.start,
        replacement_collaborator_id: Number(values.replace),
        user_profile_id: currentProfile?.profileId!,
      });
    },
  });

  if (!userInfo.collaboratorInfo) {
    return <NoCollaboratorInfo />;
  }

  return (
    <Grid
      container
      direction="column"
      spacing={2}
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <Grid item container>
        <Grid item xs={12} md={4}>
          <InputLabel>Periodo</InputLabel>

          <Autocomplete
            options={getRRHHPeriods()}
            clearIcon={null}
            onChange={(_, val) => {
              if (val) {
                formik.setFieldValue('period', val.value);
              }
            }}
            renderInput={(props) => (
              <TextField
                {...props}
                placeholder="Seleccione Periodo"
                error={Boolean(formik.errors.period && formik.touched.period)}
                helperText={
                  Boolean(formik.errors.period && formik.touched.period) && formik.errors.period
                }
              />
            )}
          />
        </Grid>
      </Grid>

      <Grid item container>
        <Grid item xs={12} md={4}>
          <InputLabel>Fecha</InputLabel>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
            <DatePicker
              value={formik.values.start}
              onChange={(val) => {
                if (val) {
                  formik.setFieldValue('start', val);
                }
              }}
              renderInput={(props) => (
                <TextField
                  {...props}
                  fullWidth
                  error={Boolean(formik.errors.start && formik.touched.start)}
                  helperText={
                    Boolean(formik.errors.start && formik.touched.start) && formik.errors.start
                  }
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
      <Grid item container>
        <Grid item xs={12} md={4}>
          <InputLabel>Reemplazo/Responsable de los requerimientos del área</InputLabel>
          {isLoadingCollaborators ? (
            <TextField placeholder="Cargando..." fullWidth />
          ) : (
            <Autocomplete
              options={
                collaborators?.data.map((cl) => ({
                  value: cl.id,
                  label: cl.user.person.pers_full_name || '',
                })) || []
              }
              clearIcon={null}
              onChange={(_, val) => {
                if (val) {
                  formik.setFieldValue('replace', val.value);
                }
              }}
              renderInput={(props) => (
                <TextField
                  {...props}
                  placeholder="Seleccione una opción"
                  error={Boolean(formik.errors.replace && formik.touched.replace)}
                  helperText={
                    Boolean(formik.errors.replace && formik.touched.replace) &&
                    formik.errors.replace
                  }
                />
              )}
            />
          )}
        </Grid>
      </Grid>
      {/* <Grid item container>
        <Grid item xs={12} md={4}>
          <InputLabel>Director</InputLabel>
          {isLoadingCollaborators ? (
            <TextField placeholder="Cargando..." fullWidth />
          ) : (
            <Autocomplete
              options={
                collaborators?.data.map((cl) => ({
                  value: cl.id,
                  label: cl.user.person.pers_full_name || '',
                })) || []
              }
              clearIcon={null}
              onChange={(_, val) => {
                if (val) {
                  formik.setFieldValue('director', val.value);
                }
              }}
              renderInput={(props) => (
                <TextField
                  {...props}
                  placeholder="Seleccione una opción"
                  error={Boolean(formik.errors.director && formik.touched.director)}
                  helperText={
                    Boolean(formik.errors.director && formik.touched.director) &&
                    formik.errors.director
                  }
                />
              )}
            />
          )}
        </Grid>
      </Grid> */}
      <Grid item container>
        <Grid item xs={12} md={4}>
          <InputLabel>Jefe Inmediato</InputLabel>
          {isLoadingCollaborators ? (
            <TextField placeholder="Cargando..." fullWidth />
          ) : (
            <Autocomplete
              options={
                collaborators?.data.map((cl) => ({
                  value: cl.id,
                  label: cl.user.person.pers_full_name || '',
                })) || []
              }
              clearIcon={null}
              onChange={(_, val) => {
                if (val) {
                  formik.setFieldValue('boss', val.value);
                }
              }}
              renderInput={(props) => (
                <TextField
                  {...props}
                  placeholder="Seleccione una opción"
                  error={Boolean(formik.errors.boss && formik.touched.boss)}
                  helperText={
                    Boolean(formik.errors.boss && formik.touched.boss) && formik.errors.boss
                  }
                />
              )}
            />
          )}
        </Grid>
      </Grid>
      <Grid item container>
        <Grid item xs={12} md={4}>
          {isSuccessApp && (
            <SuccessAlert message="Solicitud enviada correctamente" handleDismiss={reset} />
          )}
          {isErrorApp && <ErrorAlert message={String(errorApp)} handleDismiss={reset} />}
        </Grid>
      </Grid>
      <Grid item container>
        <Grid item xs={12} md={4} textAlign="center">
          <Button
            variant="contained"
            onClick={() => formik.handleSubmit()}
            disabled={isLoadingApp}
            endIcon={isLoadingApp && <CircularProgress size={20} />}
          >
            Solicitar
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PersonalDayForm;
