import JsPDF from 'jspdf';
// import 'jspdf-autotable';
import autoTable from 'jspdf-autotable';

import { WeightingReportTable } from '../interfaces/integralsReports.interface';

interface Props {
  data: WeightingReportTable[];
}
export const createPdfReport = ({ data }: Props) => {
  const doc = new JsPDF({ putOnlyUsedFonts: true, orientation: 'l' });
  // eslint-disable-next-line new-cap
  // const doc = new jsPDF();

  autoTable(doc, { html: '#table-report' });

  // autoTable(tableReport, {
  //   html: '#table-report-total',
  //   startY: doc.las,
  // });

  doc.save('report');
};
