import { useAppDispatch, useAppSelector } from '../../../../../../hooks/useRedux';
import DialogAlert from '../../../../../shared/components/dialogs/DialogAlert';
import { useAcceptEvaluationMutation } from '../slices/formsApiSlice';
import { setOpenDialogAcceptResult } from '../slices/formSlice';
import HistoricalCalifications from './HistoricalCalifications';

const AcceptResultDialog = () => {
  const dispatch = useAppDispatch();

  const { openDialogAcceptResult, formUserSelected } = useAppSelector((state) => state.formsEva);

  const [accept, { isLoading, isSuccess, isError, error }] = useAcceptEvaluationMutation();

  const handleClose = () => {
    dispatch(setOpenDialogAcceptResult(false));
  };

  const handleConfirm = async () => {
    if (formUserSelected) {
      await accept({
        id: formUserSelected.id,
      });
    }
  };

  return (
    <DialogAlert
      open={openDialogAcceptResult}
      onClose={handleClose}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      children={<HistoricalCalifications />}
      handleAction={handleConfirm}
      customTitleSendButton={'Si, aceptar resultados'}
      title={`Detalle de evaluación`}
      error={error as string}
      isHideComfirmButton={
        formUserSelected?.date_accept_result
          ? true
          : formUserSelected?.date_accept_result === null
          ? false
          : true
      }
      customTitleCancelButton={'Volver'}
    />
  );
};

export default AcceptResultDialog;
