import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import MainCard from '../../../../../shared/components/cards/MainCard';
import BackButton from '../../../../../shared/components/buttons/BackButton';
import FormPreview from '../components/FormPreview';
import { useGetFormByIdQuery } from '../slices/formsApiSlice';
import LoadingScreen from '../../../../../shared/screens/LoadingScreen';

const FormsPreviewScreen = () => {
  const { t } = useTranslation();

  const { id } = useParams<{
    id: string;
  }>();

  const { data: formById } = useGetFormByIdQuery({
    id,
  });

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid alignItems="center" sx={{ mb: 3, display: 'flex' }}>
        <BackButton />
        <Typography variant="h2">{t('Detalle de la evaluación')}</Typography>
      </Grid>
      {formById ? <FormPreview /> : <LoadingScreen />}
    </Grid>
  );
};

export default FormsPreviewScreen;
