import {
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppSelector } from '../../../../hooks/useRedux';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import { useFormStyles } from '../constants/styles';
import { CompanyInfoSchema } from '../constants/validarionSchemas';
import { useUpdateCompanyMutation } from '../slices/companiesApiSlice';

const EditSocialForm = () => {
  const { currentProfile } = useAppSelector((state) => state.access);
  const { company } = useAppSelector((state) => state.companies);
  const classes = useFormStyles();
  const [showAlerts, setShowAlerts] = useState(false);

  const history = useHistory();

  const [updateCompany, { isLoading: isUpdating, isSuccess, isError, error }] =
    useUpdateCompanyMutation();

  const { getFieldProps, errors, touched, handleSubmit } = useFormik({
    initialValues: {
      name: company?.co_name || '',
      ruc: company?.co_ruc || '',
      website: company?.co_website || '',
      email: company?.co_email || '',
      statusId: 1,
      assignedSite: company?.co_assigned_site || '',
      facebook: company?.co_facebook || '',
      instagram: company?.co_instagram || '',
      linkedin: company?.co_linkedin || '',
      youtube: company?.co_youtube || '',
      tiktok: company?.co_tiktok || '',
      co_mision: company?.co_mision || '',
      co_vision: company?.co_vision || '',
      co_director_message: company?.co_director_message || '',
      co_url_file_director_photo: company?.co_url_file_director_photo || '',
    },
    validationSchema: CompanyInfoSchema,
    onSubmit: async (values) => {
      await updateCompany({
        profileId: currentProfile?.profileId!,
        companyId: company?.id!,
        companyPayload: {
          co_name: values.name,
          co_ruc: values.ruc,
          co_website: values.website,
          co_email: values.email,
          status_id: values.statusId.toString(),
          co_assigned_site: values.assignedSite,
          co_facebook: values.facebook,
          co_instagram: values.instagram,
          co_linkedin: values.linkedin,
          co_youtube: values.youtube,
          co_tiktok: values.tiktok,
          co_mision: values.co_mision,
          co_vision: values.co_vision,
          co_director_message: values?.co_director_message || '',
          co_url_file_director_photo: values?.co_url_file_director_photo || '',
        },
      });

      setShowAlerts(true);
    },
  });
  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction="column" gap={2}>
        <Grid item>
          {isError && showAlerts && (
            <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
          )}

          {isSuccess && showAlerts && (
            <SuccessAlert
              message="Los datos financieros de la compañía se actualizaron."
              handleDismiss={() => setShowAlerts(false)}
            />
          )}
        </Grid>

        <Grid item container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>Sitio Asignado</InputLabel>
            <TextField
              fullWidth
              error={Boolean(errors.assignedSite && touched.assignedSite)}
              placeholder="Ingrese el sitio web asignado a la compañía"
              {...getFieldProps('assignedSite')}
            />
            {errors.assignedSite && touched.assignedSite && (
              <FormHelperText error>{errors.assignedSite}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>Perfil de Facebook</InputLabel>
            <TextField
              fullWidth
              error={Boolean(errors.facebook && touched.facebook)}
              placeholder="Ingrese el perfil de facebook de la compañía"
              {...getFieldProps('facebook')}
            />
            {errors.facebook && touched.facebook && (
              <FormHelperText error>{errors.facebook}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>Perfil de Instagram</InputLabel>
            <TextField
              fullWidth
              error={Boolean(errors.instagram && touched.instagram)}
              placeholder="Ingrese el perfil de instagram de la compañía"
              {...getFieldProps('instagram')}
            />
            {errors.instagram && touched.instagram && (
              <FormHelperText error>{errors.instagram}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>Perfil de Linkedin</InputLabel>
            <TextField
              fullWidth
              error={Boolean(errors.linkedin && touched.linkedin)}
              placeholder="Ingrese el perfil de likedin de la compañía"
              {...getFieldProps('linkedin')}
            />
            {errors.linkedin && touched.linkedin && (
              <FormHelperText error>{errors.linkedin}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>Perfil de Youtube</InputLabel>
            <TextField
              fullWidth
              error={Boolean(errors.youtube && touched.youtube)}
              placeholder="Ingrese el perfil de youtube de la compañía"
              {...getFieldProps('youtube')}
            />
            {errors.youtube && touched.youtube && (
              <FormHelperText error>{errors.youtube}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>Perfil de TikTok</InputLabel>
            <TextField
              fullWidth
              error={Boolean(errors.tiktok && touched.tiktok)}
              placeholder="Ingrese el perfil de TikTok de la compañía"
              {...getFieldProps('tiktok')}
            />
            {errors.tiktok && touched.tiktok && (
              <FormHelperText error>{errors.tiktok}</FormHelperText>
            )}
          </Grid>

          <Grid
            item
            container
            alignItems="center"
            justifyContent="flex-end"
            spacing={2}
            sx={{ mt: 1 }}
          >
            <Grid item>
              <Button
                variant="outlined"
                size="large"
                color="primary"
                onClick={() => history.goBack()}
              >
                Regresar
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                size="large"
                color="primary"
                disabled={isUpdating}
                endIcon={isUpdating && <CircularProgress size={20} />}
              >
                Actualizar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default EditSocialForm;
