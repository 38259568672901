import {
  Autocomplete,
  Button,
  Chip,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  useTheme,
} from '@material-ui/core';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { useTableSearch } from '../../../../hooks/useTableSearch';
import SubCard from '../../../shared/components/cards/SubCard';
import DeleteButton from '../../../shared/components/tables/buttons/DeleteButton';
import EditButton from '../../../shared/components/tables/buttons/EditButton';
import CustomCollapsibleTable from '../../../shared/components/tables/CustomCollapsibleTable';
import TextFieldFetching from '../../../shared/components/TextFieldFetching';
import { useGetStudyModalitiesQuery } from '../../meshs/slices/meshsApiSlice';
import { Hourhand } from '../interfaces/posgraduatePlanningHourhands.interface';
import { useGetPosgraduatePlanningHourhandsQuery } from '../slices/posgraduatePlanningHourhandsApiSlice';
import {
  setModalityIdSelected,
  setPosgraduatePlanningHourhandDeleteDialogOpen,
  setPosgraduatePlanningHourhandIdSelected,
  setPosgraduatePlanningHourhandsList,
  setPosgraduatePlanningHourhandsTable,
} from '../slices/posgraduatePlanningHourhandsSlice';

const days = [
  {
    keyword: 'monday',
    label: 'Lunes',
  },
  {
    keyword: 'tuesday',
    label: 'Martes',
  },
  {
    keyword: 'wednesday',
    label: 'Miércoles',
  },
  {
    keyword: 'thursday',
    label: 'Jueves',
  },
  {
    keyword: 'friday',
    label: 'Viernes',
  },
  {
    keyword: 'saturday',
    label: 'Sabado',
  },
  {
    keyword: 'sunday',
    label: 'Domingo',
  },
];

const PosgraduatePlanningHourhandsTable = () => {
  const { t } = useTranslation();

  const { currentProfile } = useAppSelector((state) => state.access);

  const theme = useTheme();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const {
    posgraduatePlanningHourhandsTable: { page, perPage, search, filterArrow },
    modalityIdSelected,
  } = useAppSelector((state) => state.posgraduatePlanningHourhands);

  const { setPage, setPerPage, setSearch, setFilterArrow } = useTableSearch({
    page,
    perPage,
    search,
    filterArrow,
    updateFunction: setPosgraduatePlanningHourhandsTable,
  });

  const { data: modalities, isLoading: isLoadingModalities } = useGetStudyModalitiesQuery({
    profileId: currentProfile?.profileId!,
  });

  const { data, isLoading, error, isFetching } = useGetPosgraduatePlanningHourhandsQuery({
    page,
    perPage,
    search,
    ...(filterArrow && { sort: filterArrow.name, type_sort: filterArrow.direction }),
    ...(modalityIdSelected && { modalityId: modalityIdSelected }),
  });

  const datafetch =
    data?.data?.map((item, index) => ({
      id: index + ((page - 1) * perPage + 1),
      hourhand: item.hourhand?.hour_description || 'Sin descripción',
      days: {
        hourMonday: item.hourhand?.hour_monday,
        hourTuesday: item.hourhand?.hour_tuesday,
        hourWednesday: item.hourhand?.hour_wednesday,
        hourThursday: item.hourhand?.hour_thursday,
        hourFriday: item.hourhand?.hour_friday,
        hourSaturday: item.hourhand?.hour_saturday,
        hourSunday: item.hourhand?.hour_sunday,
      },
      modality: item.modality?.cat_name,
      options: { id: item.id, hourhandId: item.hourhand?.id },
    })) || [];

  useEffect(() => {
    if (data?.data) {
      dispatch(setPosgraduatePlanningHourhandsList(data.data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleAdd = () => {
    history.push('/administrar-horarios/agregar?pg=1');
  };

  const handleEdit = (id: number) => {
    // dispatch(setPosgraduatePlanningHourhandIdSelected(id));
    history.push(`/administrar-horarios/${id}/editar`);
  };

  const handleDelete = (id: string) => {
    dispatch(setPosgraduatePlanningHourhandIdSelected(id));
    dispatch(setPosgraduatePlanningHourhandDeleteDialogOpen(true));
  };

  const renderItemCollapse = (idx: number) => {
    return (
      <TableContainer>
        <SubCard>
          <Table>
            <TableHead>
              <TableRow hover sx={{ backgroundColor: theme.palette.grey[200] }}>
                {days.map((day, index) => (
                  <TableCell align="center" key={index}>
                    {t(`utils.${day.keyword}`)}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow key={idx}>
                {days.map((day, index) => (
                  <TableCell align="center" key={index}>
                    {data?.data?.[idx]?.hourhand?.[`hour_${day.keyword}` as keyof Hourhand] ===
                    '1' ? (
                      <>
                        {t('posgraduate.table.from')}:{' '}
                        {
                          data?.data?.[idx]?.hourhand?.[
                            `hour_start_time_${day.keyword}` as keyof Hourhand
                          ]
                        }{' '}
                        <br />
                        {t('posgraduate.table.to')}:{' '}
                        {
                          data?.data?.[idx]?.hourhand?.[
                            `hour_end_time_${day.keyword}` as keyof Hourhand
                          ]
                        }
                      </>
                    ) : (
                      <> - </>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>
        </SubCard>
      </TableContainer>
    );
  };

  return (
    <>
      <CustomCollapsibleTable
        data={datafetch}
        error={error}
        isLoading={isLoading}
        isFetching={isFetching}
        headers={{
          id: 'N°',
          hourhand: t('posgraduate.hourhands.table.detail'),
          days: t('posgraduate.hourhands.table.days'),
          modality: t('posgraduate.hourhands.table.modality'),
          options: t('posgraduate.hourhands.table.options'),
        }}
        keyExtractor={(item) => item.id.toString()}
        listFilterArrows={{
          hourhand: 'hourhand.hour_description',
          modality: 'modality.cat_name',
        }}
        filterArrow={filterArrow}
        setFilterArrow={setFilterArrow}
        numHeader={6}
        setPage={setPage}
        setPerPage={setPerPage}
        setSearch={setSearch}
        total={data?.total || 0}
        page={page}
        perPage={perPage}
        search={search}
        searchPlacehoder={t('posgraduate.hourhands.table.search')}
        ActionButtons={
          <>
            <Button variant="contained" color="primary" onClick={handleAdd}>
              {t('posgraduate.hourhands.table.add')}
            </Button>
          </>
        }
        customHeadersCellsProperties={{
          id: {
            align: 'center',
          },
          hourhand: {
            align: 'center',
          },
          days: {
            align: 'center',
          },
          modality: {
            align: 'center',
          },
          options: {
            align: 'center',
          },
        }}
        customDataCellsProperties={{
          id: {
            align: 'center',
          },
          hourhand: {
            align: 'center',
          },
          days: {
            align: 'center',
          },
          modality: {
            align: 'center',
          },
          options: {
            align: 'center',
          },
        }}
        customRenderers={{
          days: (row) => (
            <Grid container spacing={1} justifyContent="center">
              {row.days.hourSunday === '1' && (
                <Grid item>
                  <Chip label={t('utils.sunday')} size="small" variant="outlined" />
                </Grid>
              )}
              {row.days.hourMonday === '1' && (
                <Grid item>
                  <Chip label={t('utils.monday')} size="small" variant="outlined" />
                </Grid>
              )}

              {row.days.hourTuesday === '1' && (
                <Grid item>
                  <Chip label={t('utils.tuesday')} size="small" variant="outlined" />
                </Grid>
              )}
              {row.days.hourWednesday === '1' && (
                <Grid item>
                  <Chip label={t('utils.wednesday')} size="small" variant="outlined" />
                </Grid>
              )}
              {row.days.hourThursday === '1' && (
                <Grid item>
                  <Chip label={t('utils.thursday')} size="small" variant="outlined" />
                </Grid>
              )}
              {row.days.hourFriday === '1' && (
                <Grid item>
                  <Chip label={t('utils.friday')} size="small" variant="outlined" />
                </Grid>
              )}
              {row.days.hourSaturday === '1' && (
                <Grid item>
                  <Chip label={t('utils.saturday')} size="small" variant="outlined" />
                </Grid>
              )}
            </Grid>
          ),

          options: (row) => (
            <>
              <EditButton handleEdit={() => handleEdit(row.options.hourhandId)} />
              <DeleteButton handleDelete={() => handleDelete(row.options.id)} />
            </>
          ),
        }}
        isCollapsible
        CollapsibleItems={renderItemCollapse}
        additionalFilters={
          <Grid container spacing={2}>
            <Grid item xs={4}>
              {isLoadingModalities ? (
                <TextFieldFetching />
              ) : (
                <Autocomplete
                  options={modalities || []}
                  getOptionLabel={(option) => option.cat_name}
                  onChange={(event, value) => {
                    dispatch(setModalityIdSelected(value?.id || null));
                  }}
                  value={modalities?.find((item) => item.id === modalityIdSelected) || null}
                  renderInput={(params) => (
                    <TextField {...params} label="Modalidad" variant="outlined" />
                  )}
                />
              )}
            </Grid>
          </Grid>
        }
      />
    </>
  );
};

export default PosgraduatePlanningHourhandsTable;
