import { useEffect } from 'react';

import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Grid, Typography, IconButton, useTheme } from '@material-ui/core';
import { IconArrowNarrowLeft } from '@tabler/icons';

import { useAppSelector } from '../../../../hooks/useRedux';
import MainCard from '../../../shared/components/cards/MainCard';
// import DeleteRoleDialog from '../components/DeleteRoleDialog';

import { useAppDispatch } from '../../../../hooks/useRedux';
import { useLazyGetUserQuery } from '../slices/userConfigurationsApiSlice';
import { setUsersList } from '../slices/userConfigurationsSlice';
import UserProfilesTable from '../components/UserProfilesTable';
import { renderSkeletonTable } from '../../../shared/helpers/render';
import { useTranslation } from 'react-i18next';

const RolesScreen = () => {
  // const { roleIdToDelete } = useAppSelector((state) => state.roles);
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  const { userId } = useParams<{ userId: string }>();

  const { usersList } = useAppSelector((state) => state.usersConfiguration);

  const [getUser, { data, isFetching }] = useLazyGetUserQuery();

  useEffect(() => {
    if (!usersList.length && !isFetching) {
      getUser({
        userId: +userId,
      });
    }
  }, [getUser, isFetching, userId, usersList.length]);

  useEffect(() => {
    if (data) dispatch(setUsersList([data!]));
  }, [data, dispatch]);

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid item xs={12} sx={{ mb: 3 }}>
        <Grid item container alignItems="center" gap={1}>
          <IconButton onClick={() => history.push(`/${location.pathname.split('/')[1]}`)}>
            <IconArrowNarrowLeft size={30} color={theme.palette.text.dark} />
          </IconButton>
          <Typography variant="h2">{t('configuration.users.profiles')}</Typography>
        </Grid>
      </Grid>

      {Boolean(usersList.length) ? <UserProfilesTable /> : renderSkeletonTable(5, 5)}
      {/* {roleIdToDelete && <DeleteRoleDialog />} */}
    </Grid>
  );
};

export default RolesScreen;
