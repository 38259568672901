import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import {
  Autocomplete,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  TextField,
  Skeleton,
  // Switch,
  // FormControlLabel,
} from '@material-ui/core';

import { useFormik } from 'formik';

import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import InputLabel from '../../../shared/components/forms/InputLabel';
import { LocalizationProvider, DateTimePicker } from '@material-ui/lab';
import esLocale from 'date-fns/locale/es';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import SubjectCardNew from './SubjectCardNew';

import { useAppSelector } from '../../../../hooks/useRedux';
// import {} from '../slices/';
import {
  useGetCoursesByPeriodQuery,
  useLazyGetStudentAvailableQuery,
} from '../slices/AssignCoursesPostgradeApiSlice';
import { useAddCourseStudentMutation } from '../../../students/subjects-register/slices/subjectsRegisterApiSlice';

import { AssignCoursesPostgrade } from '../constants/validationSchemas';
// import { statusAvailables } from '../../../shared/constants/resourceStatus';
import TextFieldFetching from '../../../shared/components/TextFieldFetching';

const AssignCoursePostgrade = () => {
  const history = useHistory();

  const { currentProfile } = useAppSelector((state) => state.access);

  const [showAlerts, setShowAlerts] = useState(true);

  const [courseListId, setCourseListId] = useState<number>(null!);

  const [AssignCourseStudent, { isLoading, isError, error, isSuccess }] =
    useAddCourseStudentMutation();

  const [
    getStudentAvailable,
    {
      data: studentInfo,
      isLoading: isLoadingStudent,
      isError: isErrorStudent,
      isSuccess: isSuccessStudent,
    },
  ] = useLazyGetStudentAvailableQuery();

  const { getFieldProps, errors, touched, handleSubmit, setFieldValue, values } = useFormik({
    initialValues: {
      studentId: '',
      subjectId: '',
      programId: '',
      assignedCourse: '',
      date: '',
      statusId: '1',
    },
    validationSchema: AssignCoursesPostgrade,
    onSubmit: async (values) => {
      await AssignCourseStudent({
        profileId: currentProfile?.profileId!,
        courseStudentPayload: {
          person_id: Number(studentInfo?.id),
          course_id: Number(values.assignedCourse),
          student_record_id: studentInfo?.user?.student?.student_records?.find(
            (e) => e.mesh_id === values.programId
          )?.id,
          final_note: 0,
          observation: 'Register postgraduate',
          num_fouls: 0,
          subject_id: Number(
            studentInfo?.user?.student?.student_records
              ?.find((e) => e.mesh_id === values.programId)
              ?.course_student.find((x) => x.course_id)?.subject_id
          ),
          period_id: periodsAvailables?.find((e) =>
            e.courses.some((x) => x.id === Number(values.assignedCourse))
          )?.id,
          curriculum_id: Number(values.programId),
          status_id: 1,
          generation_fees: true,
          expiration_date: values.date,
        },
      });

      setShowAlerts(true);
    },
  });

  const {
    data: periodsAvailables,
    isLoading: isLoadingPeriods,
    isUninitialized,
    error: errorPeriod,
    isError: isErrorPeriod,
  } = useGetCoursesByPeriodQuery(
    {
      curriculumId: Number(values.programId),
      offDescription: 'oferta_postgrado',
      subjectId: Number(values.subjectId),
    },
    { skip: !values.programId || !values.subjectId }
  );

  useEffect(() => {
    setCourseListId(null!);
  }, [values.subjectId]);

  useEffect(() => {
    setFieldValue('assignedCourse', courseListId);
  }, [setFieldValue, courseListId]);

  useEffect(() => {
    if (values.studentId.length === 10) {
      getStudentAvailable({
        identification: values.studentId,
        offerDescription: 'oferta_postgrado',
      });
      setShowAlerts(true);
    }
  }, [values.studentId, getStudentAvailable]);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction="column" gap={2}>
        <Grid item>
          {isError && showAlerts && (
            <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
          )}

          {isSuccess && showAlerts && (
            <SuccessAlert
              message="El estudiante ha sido agregado correctamente."
              handleDismiss={() => setShowAlerts(false)}
            />
          )}

          {isErrorStudent && showAlerts && (
            <ErrorAlert
              message={(error as string) || 'El estudiante no cumple con los parametros requeridos'}
              handleDismiss={() => setShowAlerts(false)}
            />
          )}
        </Grid>

        <Grid item container spacing={2} alignItems="center">
          <Grid item xs={12} md={6}>
            <InputLabel>Identificación del estudiante</InputLabel>
            {isLoadingStudent ? (
              <TextFieldFetching placeholderValue={values.studentId} />
            ) : (
              <TextField
                fullWidth
                error={Boolean(errors.studentId && touched.studentId)}
                placeholder="Ingrese la identifición del estudiante"
                {...getFieldProps('studentId')}
              />
            )}
            {errors.studentId && touched.studentId && (
              <FormHelperText error>{errors.studentId}</FormHelperText>
            )}
          </Grid>
          {isSuccessStudent && (
            <>
              <Grid item xs={12} md={6}>
                <InputLabel>Programa</InputLabel>
                <Autocomplete
                  options={studentInfo?.user.student?.student_records || []}
                  loading={isLoadingPeriods}
                  loadingText="Cargando programas disponibles..."
                  disabled={!values.studentId}
                  getOptionLabel={(option) => option.id + ' - ' + option.education_level.edu_name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(errors.programId && touched.programId)}
                      placeholder="Seleccione las programas disponibles"
                    />
                  )}
                  onChange={(e, values) => setFieldValue('programId', values?.mesh_id)}
                  fullWidth
                />
                {errors.programId && touched.programId && (
                  <FormHelperText error>{errors.programId}</FormHelperText>
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <InputLabel>Materia reprobada</InputLabel>
                <Autocomplete
                  options={
                    studentInfo?.user.student?.student_records.find(
                      (item) => item.mesh_id === values.programId
                    )?.course_student || []
                  }
                  loading={isLoadingPeriods}
                  loadingText="Cargando materias disponibles..."
                  disabled={!values.studentId}
                  getOptionLabel={(option) => option.subject.mat_name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(errors.subjectId && touched.subjectId)}
                      placeholder="Seleccione las materias disponibles"
                    />
                  )}
                  onChange={(e, values) => setFieldValue('subjectId', values?.subject.id)}
                  fullWidth
                />
                {errors.subjectId && touched.subjectId && (
                  <FormHelperText error>{errors.subjectId}</FormHelperText>
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <InputLabel>Fecha fin</InputLabel>

                <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                  <DateTimePicker
                    renderInput={(props) => <TextField fullWidth {...props} helperText="" />}
                    // label="Fecha de inicio"
                    value={values.date}
                    onChange={(newValue: Date | null) => setFieldValue('date', newValue)}
                  />
                </LocalizationProvider>
                {errors.date && touched.date && (
                  <FormHelperText error>{errors.date}</FormHelperText>
                )}
              </Grid>
              {false && (
                <Grid item xs={12} md={6}>
                  <InputLabel>Curso</InputLabel>
                  <Autocomplete
                    options={
                      studentInfo?.user.student?.student_records.find(
                        (item) => item.id === Number(values.programId)
                      )?.course_student || []
                    }
                    loading={isLoadingPeriods}
                    loadingText="Cargando materias disponibles..."
                    disabled={!values.studentId}
                    getOptionLabel={(option) => option.subject.mat_name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={Boolean(errors.subjectId && touched.subjectId)}
                        placeholder="Seleccione las materias disponibles"
                      />
                    )}
                    onChange={(e, values) => setFieldValue('subjectId', values?.subject.id)}
                    fullWidth
                  />
                  {errors.subjectId && touched.subjectId && (
                    <FormHelperText error>{errors.subjectId}</FormHelperText>
                  )}
                </Grid>
              )}
              <Grid item xs={12} md={6}>
                <InputLabel>Selecciona un curso</InputLabel>
              </Grid>
              {isLoadingPeriods &&
                Array.from(new Array(4)).map((e, index) => (
                  <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                    <Skeleton
                      key={index}
                      variant="rectangular"
                      sx={{ borderRadius: 2 }}
                      height={250}
                      width="100%"
                    />
                  </Grid>
                ))}

              {isUninitialized ? (
                <Grid item container xs={12} justifyContent="center" alignItems="center">
                  Selecciona una materia reprobada para cargar los cursos
                </Grid>
              ) : isErrorPeriod ? (
                <Grid item container xs={12} justifyContent="center" alignItems="center">
                  {errorPeriod}
                </Grid>
              ) : (
                <Grid item container spacing={2}>
                  {periodsAvailables?.map((e) =>
                    e.courses.map((course) => (
                      <SubjectCardNew
                        courseId={courseListId}
                        courseIdDispatch={setCourseListId}
                        subject={course}
                        period={e}
                      />
                    ))
                  )}
                </Grid>
              )}
            </>
          )}
          <Grid
            item
            container
            alignItems="center"
            justifyContent="flex-end"
            spacing={2}
            sx={{ mt: 1 }}
          >
            <Grid item>
              <Button
                variant="outlined"
                size="large"
                color="primary"
                onClick={() => history.goBack()}
              >
                Regresar
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                size="large"
                color="primary"
                disabled={isLoading || !!Object.values(errors).length}
                endIcon={isLoading && <CircularProgress size={20} />}
              >
                Asignar curso
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default AssignCoursePostgrade;
