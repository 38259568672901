import { FormControlLabel, Grid, Radio, RadioGroup, Typography, useTheme } from '@material-ui/core';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import { Option } from '../../interfaces/evaluationToDo.interface';
import { setEvaluationQuestionsAnswered } from '../../slices/userEvaluationsSlice';
import { EvaluationQuestion } from '../../interfaces/evaluationQuestions.interface';
// import { setEvaluationQuestionsAnswered } from '../../slices/userEvaluationsSlice';

interface QuestionAnswerProps {
  question: EvaluationQuestion;
  template: Option[];
  idxFather?: string | number;
  idxSon?: string | number;
}

const QuestionAnswer = ({ question, template, idxFather, idxSon }: QuestionAnswerProps) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { evaluationQuestionsAnswered } = useAppSelector((state) => state.userEvaluations);

  const answer = evaluationQuestionsAnswered?.find((item) => item.questionId === question.uuid);

  const handleSelectOption = (e: any) => {
    dispatch(
      setEvaluationQuestionsAnswered({
        option_uuid:
          template?.find((item) => item.value.toString() === e.target.value.toString())?.uuid! ||
          '',
        questionId: question?.uuid || '',
        answerId: e.target.value,
      })
    );
  };

  const templateOrderMaxValue = [...template].sort((a, b) => Number(b.value) - Number(a.value));

  return (
    <Grid
      item
      container
      xs={12}
      mt={2}
      sx={{
        padding: 2,
        borderRadius: 2,
        backgroundColor: theme.palette.background.paper,
      }}
    >
      <Grid item xs={12} md={4}>
        <Typography variant="h5">{`${idxFather}.${idxSon}.${'  '}${question.name}`}</Typography>
      </Grid>
      <Grid item xs={12} md={8}>
        <RadioGroup
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: 1,
            justifyContent: 'flex-end',
            flexWrap: 'wrap',
          }}
          value={answer?.answerId || ''}
          onChange={handleSelectOption}
        >
          {templateOrderMaxValue?.map((option) => (
            <FormControlLabel
              control={<Radio />}
              label={Number(option.value).toFixed(2)}
              value={Number(option.value).toFixed(2)}
            />
          ))}
        </RadioGroup>
      </Grid>
    </Grid>
  );
};

export default QuestionAnswer;
