import { Button, CircularProgress, Grid, TextField } from "@material-ui/core";
import { useFormik } from "formik"
import { useState } from "react";
import * as Yup from 'yup';
import { useAppSelector } from "../../../../hooks/useRedux";
import ErrorAlert from "../../../shared/components/alerts/ErrorAlert";
import SuccessAlert from "../../../shared/components/alerts/SuccessAlert";
import InputLabel from "../../../shared/components/forms/InputLabel";
import { useAddStatusApplicationMutation } from "../slices/requestsStatusApiSlice";



const AddStatusForm = () => {

    const stateSchema = Yup.object().shape({
        st_name: Yup.string().required('Nombre es requerido'),
    })

    const { currentProfile } = useAppSelector(state => state.access);

    const [addStatus, { isSuccess, isLoading, isError, error }] = useAddStatusApplicationMutation();

    const [showAlerts, setShowAlerts] = useState(true);

    const formik = useFormik({
        validationSchema: stateSchema,
        initialValues: {
            st_name: ''
        },
        onSubmit: (values) => {
            // alert(JSON.stringify(values))
            addStatus({
                user_profile_id: currentProfile?.profileId || '',
                ...values,
            })
        }
    })

    return (
        <div>
            <form onSubmit={formik.handleSubmit}>
                <Grid container item xs={12} md={6} direction="column" spacing={2}>
                    <Grid item xs={12} md={6}>
                        {isError && showAlerts && (
                            <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
                        )}
                        {
                            isSuccess && showAlerts &&
                            <SuccessAlert
                                message="El estado se ha creado correctamente"
                                handleDismiss={() => setShowAlerts(false)}
                            />
                        }
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <InputLabel>Nombre de Estado</InputLabel>
                        <TextField
                            placeholder="Nombre"
                            value={formik.values.st_name}
                            error={Boolean(formik.errors.st_name) && Boolean(formik.touched.st_name)}
                            helperText={Boolean(formik.errors.st_name) && Boolean(formik.touched.st_name) ? formik.errors.st_name : undefined}
                            id="st_name"
                            name="st_name"
                            onChange={formik.handleChange}
                            fullWidth
                        />

                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Button
                            variant="contained"
                            type="submit"
                            disabled={isLoading}
                            endIcon={isLoading && <CircularProgress size={20} />}
                        >
                            Agregar
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </div>
    )
}

export default AddStatusForm