import { lazy } from 'react';
import { Route, Switch } from 'react-router-dom';

// project imports
import MinimalLayout from '../modules/shared/layout/MinimalLayout';
import NavMotion from '../modules/shared/layout/NavMotion';
import Loadable from '../modules/shared/components/Loadable';
import LoginGoogleScreen from '../modules/auth/screens/LoginGoogleScreen';
import AuthGoogleScreen from '../modules/auth/screens/AuthGoogleScreen';

// screens
const LoginScreen = Loadable(lazy(() => import('../modules/auth/screens/LoginScreen')));
// const LoginGoogleScreen = Loadable(lazy(() => import('../modules/auth/screens/LoginGoogleScreen')));

const RecoverPasswordScreen = Loadable(
  lazy(() => import('../modules/auth/screens/RecoverPasswordScreen'))
);
const ResetPasswordScreen = Loadable(
  lazy(() => import('../modules/auth/screens/ResetPasswordScreen'))
);

const AuthRouter = () => {
  return (
    <MinimalLayout>
      <Switch>
        <NavMotion>
          <Route path={['/login/:googleSSO', '/login']} component={LoginScreen} />
          <Route exact path={['/saml/:googleSSO', '/saml']} component={LoginGoogleScreen} />
          <Route exact path="/auth/google" component={AuthGoogleScreen} />
          <Route exact path="/recuperar-clave" component={RecoverPasswordScreen} />
          <Route exact path="/restablecer-clave/:token" component={ResetPasswordScreen} />
        </NavMotion>
      </Switch>
    </MinimalLayout>
  );
};

export default AuthRouter;
