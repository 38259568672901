import { useEffect, useState, useMemo } from 'react';
import {
  TableRow,
  TableCell,
  IconButton,
  Box,
  TableContainer,
  useTheme,
  Table,
  TableHead,
  TableBody,
  Grid,
  Tooltip,
} from '@material-ui/core';
import { useAppSelector } from '../../../../../hooks/useRedux';
import {
  useGetReceiptsQuery,
  useGetStudentPaymentsByStateQuery,
} from '../../slices/paymentsApiSlice';
import SubCard from '../../../../shared/components/cards/SubCard';
import { renderErrorOrEmptyRow } from '../../../../shared/helpers/render';
import { IconFileSearch } from '@tabler/icons';
import DialogIframe from '../../../../shared/components/dialogs/DialogIframe';
import CustomCollapsibleTable from '../../../../shared/components/tables/CustomCollapsibleTable';
import { CashCollection } from '../../interfaces/paymentsHistory.interface';
import { traverseObject } from '../../../../shared/helpers';

const RejectedTransc = () => {
  const { currentProfile, currentStudent, currentRecordStudent } = useAppSelector(
    (state) => state.access
  );
  const theme = useTheme();

  const [transactionId, setTransactionId] = useState('');
  const [receipt, setReceipt] = useState('');
  const [openReceipt, setOpenReceipt] = useState(false);

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [perPage, setPerPage] = useState(5);

  const {
    data,
    isFetching,
    isLoading,
    error,
    // refetch,
  } = useGetStudentPaymentsByStateQuery({
    profileId: currentProfile?.profileId!,
    studentId: currentStudent?.id!,
    page,
    perPage,
    search,
    payment_status: 'rejected-payments',
    ...(currentRecordStudent && { student_record: currentRecordStudent?.id }),
  });

  const {
    data: pdfHTML,
    error: errorPDF,
    isFetching: isLoadingPDF,
  } = useGetReceiptsQuery(
    { transactionId: transactionId },
    {
      skip: transactionId === '',
      refetchOnMountOrArgChange: 30,
    }
  );

  const handleOpen = (transactionId: string) => {
    setOpenReceipt(true);
    setTransactionId(transactionId);
  };
  const handleClose = () => {
    setOpenReceipt(false);
  };
  // console.log(receipt, transactionId);

  useEffect(() => {
    if (!isLoadingPDF && pdfHTML) {
      //Actualizando valor inmediato a la generacion del certificado
      //este es valido para el primero como el n-esimo
      setReceipt(pdfHTML);
    }
  }, [isLoadingPDF, pdfHTML]);

  const datafetch = data?.data!.map((value) => {
    return {
      detalle: value,
      debts: value.fees_name_identifier!,
      period:
        value.per_current_year && value.per_due_year
          ? `${value.per_current_year}-${value.per_due_year}`
          : '',
      start_date: value.fees_start_date!,
      due_date: value.fees_expiration_date!,
      value: value.fees_net_amount!,
      discount: value.apply_early_payment
        ? value.discount_early_payment_is_percent
          ? value.discount_value_early_payment //value.discount_early_payment
          : value.discount_early_payment //discount_percent_early_payment
        : '0.00',
      final_value: value.apply_early_payment
        ? value.net_value_early_payment
        : value.fees_net_amount!,
      reason: value.cash_collections?.[0]?.observation || '',
    };
  });

  //se tomara el ultimo indice de cada grupo de transacciones del datafetch (id_transaction)
  const groupedTransactions: { [key: string]: { [key2: string]: number } } = useMemo(() => {
    let existingItems: { [key: string]: { [key2: string]: number } } = {};

    datafetch?.forEach((value, index) => {
      value.detalle.cash_collections?.forEach((element) => {
        // {fee_identifier:{id_transaction:fee id}}
        existingItems[value.detalle.fees_identifier] = {
          ...existingItems[value.detalle.fees_identifier],
          [element.id_transaction as keyof CashCollection]: element.id,
        };
      });
    });

    return existingItems;
  }, [datafetch]);

  const CollapsibleContentRow = (id: number) => {
    return (
      <Box margin={1}>
        <TableContainer>
          <SubCard content={false} sx={{ mb: 2 }}>
            <Table size="small" aria-label="purchases">
              <TableHead>
                <TableRow hover sx={{ backgroundColor: theme.palette.grey[200] }}>
                  <TableCell>Método de Pago</TableCell>
                  <TableCell>Fecha de pago</TableCell>
                  <TableCell>Fecha de rechazo</TableCell>
                  <TableCell>
                    {' '}
                    {defaultConfiguration?.currency_symbol}
                    Valor
                  </TableCell>
                  <TableCell>Opciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {datafetch &&
                  id !== -1 &&
                  datafetch[id]?.detalle?.cash_collections?.map((typePayment) => (
                    <TableRow>
                      <TableCell>{typePayment.payment_way.cat_name}</TableCell>
                      <TableCell>{typePayment.payment_date}</TableCell>
                      <TableCell>{typePayment.approval_rejection_date}</TableCell>
                      <TableCell>({typePayment.o_payment_amount})</TableCell>
                      <TableCell>
                        {/* {datafetch[id]?.detalle?.cash_collections!.at(-1) === typePayment && ( */}
                        {traverseObject(
                          groupedTransactions,
                          `${datafetch[id]?.detalle?.fees_identifier}.${typePayment.id_transaction}`
                        ) === typePayment.id && (
                          <Tooltip title="Ver comprobante">
                            <IconButton onClick={() => handleOpen(typePayment.id_transaction)}>
                              <IconFileSearch />
                            </IconButton>
                          </Tooltip>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                {datafetch && id !== -1 && datafetch[id] === undefined && renderErrorOrEmptyRow(2)}
              </TableBody>
            </Table>
          </SubCard>
        </TableContainer>
      </Box>
    );
  };

  const defaultConfiguration = useAppSelector((state) => state.access.defaultConfiguration);

  return (
    <>
      <Grid container alignItems="flex-start" spacing={2}>
        <Grid item xs={12} container spacing={2}>
          <CustomCollapsibleTable
            data={datafetch ? datafetch : []}
            headers={{
              detalle: 'Detalle',
              debts: 'Deuda',
              period: 'Período lectivo',
              start_date: 'Fecha de inicio cobro',
              due_date: 'Fecha de vencimiento',
              value: `${defaultConfiguration?.currency_symbol} Valor`,
              discount: `${defaultConfiguration?.currency_symbol} Descuento`,
              final_value: `${defaultConfiguration?.currency_symbol} Valor final`,
              reason: 'Motivo',
            }}
            keyExtractor={(item) => item.detalle.fees_identifier.toString()}
            searchPlacehoder="Buscar por nombre"
            isLoading={isLoading}
            isFetching={isFetching}
            error={error}
            perPage={perPage}
            setPerPage={setPerPage}
            total={data?.total || 0}
            page={page}
            setPage={setPage}
            numHeader={8}
            search={search}
            setSearch={setSearch}
            //apply collapsible function, in specific row (optional)
            isCollapsible={true}
            overwriteRowC={0}
            CollapsibleItems={CollapsibleContentRow}
            showFilter={true}
            customHeadersCellsProperties={{
              detalle: {
                align: 'center',
              },
              debts: {
                align: 'center',
              },
              period: {
                align: 'center',
              },
              start_date: {
                align: 'center',
              },
              due_date: {
                align: 'center',
              },
              value: {
                align: 'center',
              },
              discount: {
                align: 'center',
              },
              final_value: {
                align: 'center',
              },
            }}
            customDataCellsProperties={{
              detalle: {
                align: 'center',
              },
              debts: {
                align: 'center',
              },
              period: {
                align: 'center',
              },
              start_date: {
                align: 'center',
              },
              due_date: {
                align: 'center',
              },
              value: {
                align: 'center',
              },
              discount: {
                align: 'center',
              },
              final_value: {
                align: 'center',
              },
            }}
          />
        </Grid>
      </Grid>
      <DialogIframe
        open={openReceipt}
        onClose={handleClose}
        title={'receipt'}
        id={'receipt'}
        isLoadingPDF={isLoadingPDF}
        htmlC={receipt}
        error={errorPDF as string}
        avaliableDownload={receipt === '' ? false : true}
      />
    </>
  );
};

export default RejectedTransc;
