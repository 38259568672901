import { Grid, Typography } from '@material-ui/core';
import MainCard from '../../../shared/components/cards/MainCard';
import AssignCoursePostgrade from '../components/ChangePeriod';

const ChangePeriodScreen = () => {
  return (
    <Grid component={MainCard} sx={{ padding: 2, minHeight: '60vh' }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">Cambiar Período</Typography>
      </Grid>
      <AssignCoursePostgrade />
    </Grid>
  );
};

export default ChangePeriodScreen;
