import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISearchTable } from '../../../shared/interfaces/paginatedTable.interface';
import { Client } from '../../clients/interfaces/clientsFilter.interface';

interface ClientState {
  clientList: Client[];
  clientTable: ISearchTable;
}

const initialState: ClientState = {
  clientList: [],
  clientTable: {
    page: 1,
    perPage: 5,
    search: '',
  },
};

export const clientDiscountSlice = createSlice({
  name: 'client-discount',
  initialState,
  reducers: {
    setClientList: (state, action: PayloadAction<Client[]>) => {
      state.clientList = action.payload;
    },
    updateClientTable: (state, action: PayloadAction<ISearchTable>) => {
      state.clientTable = action.payload;
    },
  },
});

export const { setClientList, updateClientTable } = clientDiscountSlice.actions;

export default clientDiscountSlice.reducer;
