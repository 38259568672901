import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Child } from '../../maintenance-product/interfaces/usetypes.interface';
import { DepositTransfer } from '../interfaces/depositsTransfers.interface';

interface DepositsTransfersState {
  paymentStatus: Child[];
  changeStatusModalOpen: boolean;
  depositTransferId?: number;
  depositTransferList: DepositTransfer[];
  viewDepositTransferModalOpen: boolean;
  editDepositTransferModalOpen: boolean;
  changePaymentMethodModalOpen: boolean;
  observationDialogOpen: boolean;
}
const initialState: DepositsTransfersState = {
  paymentStatus: [],
  changeStatusModalOpen: false,
  depositTransferList: [],
  viewDepositTransferModalOpen: false,
  editDepositTransferModalOpen: false,
  changePaymentMethodModalOpen: false,
  observationDialogOpen: false,
};
const depositsTransfersSlice = createSlice({
  name: 'depositsTransfers',
  initialState,
  reducers: {
    resetDepositTransferId: (state) => {
      state.depositTransferId = undefined;
    },
    setChangeStatusModalOpen: (state, action: PayloadAction<boolean>) => {
      state.changeStatusModalOpen = action.payload;
    },
    setPaymentStatus: (state, action: PayloadAction<Child[]>) => {
      state.paymentStatus = action.payload;
    },
    openChangeStatusModalWith: (state, action: PayloadAction<number>) => {
      state.depositTransferId = action.payload;
      state.changeStatusModalOpen = true;
      state.viewDepositTransferModalOpen = true;
    },
    setDepositTransferList: (state, action: PayloadAction<DepositTransfer[]>) => {
      state.depositTransferList = action.payload;
    },
    setViewDepositTransferModalOpen: (state, action: PayloadAction<boolean>) => {
      state.viewDepositTransferModalOpen = action.payload;
    },
    setDepositTransferId: (state, action: PayloadAction<number>) => {
      state.depositTransferId = action.payload;
    },
    setEditDepositTransferModalOpen: (state, action: PayloadAction<boolean>) => {
      state.editDepositTransferModalOpen = action.payload;
    },
    setChangePaymentMethodModalOpen: (state, action: PayloadAction<boolean>) => {
      state.changePaymentMethodModalOpen = action.payload;
    },
    setObservationDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.observationDialogOpen = action.payload;
    },
  },
});
export const {
  resetDepositTransferId,
  setChangeStatusModalOpen,
  setPaymentStatus,
  openChangeStatusModalWith,
  setDepositTransferList,
  setViewDepositTransferModalOpen,
  setDepositTransferId,
  setEditDepositTransferModalOpen,
  setChangePaymentMethodModalOpen,
  setObservationDialogOpen,
} = depositsTransfersSlice.actions;
export default depositsTransfersSlice.reducer;
