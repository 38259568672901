import {
  Autocomplete,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useAppSelector } from '../../../../hooks/useRedux';
import { SUBJECTWITHDRAWAL_TYPES } from '../../../shared/constants/subjectWithDrawalTypes';
import { SubjectWithDrawalPayload } from '../interfaces/subjectWithDrawal.interfaces';
import {
  useAddRetirementSubjectsMutation,
  useGetRetirementSubjectsByStudentIdQuery,
} from '../slices/academicRequestsApiSlice';
import { subjectWithDrawalValues } from '../constants/formConstants';
import { subjectWithDrawalSchema } from '../constants/validationSchemas';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import { ChangeEvent, useEffect, useState } from 'react';
import { SUPPORT_TYPES_RM } from '../../../shared/constants/extensionRequestType';
import useGuard from '../hooks/useGuard';
import TextFieldFetching from '../../../shared/components/TextFieldFetching';

import { useTranslation } from 'react-i18next';
import PeriodStudentSelect from './Builder/PeriodStudentSelect';
import DragArea from '../../../shared/components/files/DragArea';

const SubjectWithDrawalForm = () => {
  const { t } = useTranslation();

  const { hasAccess } = useGuard();
  const { currentProfile, /* currentStudent,*/ currentRecordStudent } = useAppSelector(
    (state) => state.access
  );

  const [studentPeriod, setStudentPeriod] = useState(0);

  const [reset, setReset] = useState(false);

  const [, setFile] = useState('');
  const [target, setTarget] = useState<File>(null!);
  const onChangeFile = function (event: ChangeEvent<HTMLInputElement>) {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e: any) => {
        setFile(e.target.result);
      };
      reader.readAsDataURL(event.target.files[0]);
      setTarget(event.target.files[0]);
    }
  };
  const onRemoveFile = function () {
    setFile('');
    setTarget(null!);
  };
  const { userInfo } = useAppSelector((state) => state.auth);
  const [showAlerts, setShowAlerts] = useState(false);
  const [addRetirementSubjects, { isError, error, isSuccess, isLoading }] =
    useAddRetirementSubjectsMutation();

  const {
    data: retirementSubjects = [],
    isLoading: isLoadingRS,
    isError: isErrorRS,
    error: errorRS,
  } = useGetRetirementSubjectsByStudentIdQuery(
    {
      profileId: currentProfile?.profileId!,
      studentId: currentRecordStudent?.id!,
      periodId: studentPeriod,
    },
    { skip: !studentPeriod, refetchOnMountOrArgChange: 30 }
  );

  const { handleSubmit, setFieldValue, errors, touched, getFieldProps, values, resetForm } =
    useFormik<SubjectWithDrawalPayload>({
      initialValues: subjectWithDrawalValues,
      validationSchema: subjectWithDrawalSchema,
      validate: (values) => {
        const fileNotAllowed = `${t('students.applications.subject_retire.validation1')}`;
        const fileNotFound = `${t('students.applications.subject_retire.validation2')}`;
        const errors: any = {};
        if (target) {
          let extension = target?.type;
          if (SUPPORT_TYPES_RM.indexOf(extension) < 0) {
            errors.files = fileNotAllowed;
          }
        } else {
          errors.files = fileNotFound;
        }
        return errors;
      },
      onSubmit: async (values) => {
        await addRetirementSubjects({
          profileId: currentProfile?.profileId!,
          userId: String(userInfo?.id!),
          studentRecordId: currentRecordStudent?.id!,
          subjectWithDrawalPayload: {
            files: target,
            app_description: values.app_description,
            tipo_retiro: values.tipo_retiro,
            course_student_id: values.course_student_id,
          },
        });
        setShowAlerts(true);
      },
    });

  useEffect(() => {
    if (isErrorRS && errorRS) {
      setShowAlerts(true);
    }
  }, [isErrorRS, errorRS]);

  useEffect(() => {
    if (isSuccess) {
      resetForm();
      setFile(null!);
      setReset(true);
    }
  }, [isSuccess, resetForm]);

  useEffect(() => {
    if (retirementSubjects && retirementSubjects.length === 1) {
      setFieldValue('course_student_id', retirementSubjects[0].id);
    }
  }, [retirementSubjects, setFieldValue]);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction="column" gap={2}>
        <Grid container>
          <Grid item>
            {isError && showAlerts && (
              <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
            )}
            {isErrorRS && showAlerts && (
              <ErrorAlert message={errorRS as string} handleDismiss={() => setShowAlerts(false)} />
            )}
            {isSuccess && showAlerts && (
              <SuccessAlert
                message={t('students.applications.success_message')}
                handleDismiss={() => setShowAlerts(false)}
              />
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <InputLabel>{t('students.applications.subject_retire.retirement_type')}</InputLabel>
          <Autocomplete
            options={SUBJECTWITHDRAWAL_TYPES}
            loadingText="Cargando tipos de retiro..."
            getOptionLabel={(option) => `${t(option.label)}`}
            renderInput={(params) => (
              <TextField
                {...params}
                error={Boolean(errors.tipo_retiro && touched.tipo_retiro)}
                placeholder={t('students.applications.subject_retire.retirement_type_placeholder')}
              />
            )}
            onChange={(_, value) => {
              setFieldValue('tipo_retiro', value?.id);
            }}
            value={SUBJECTWITHDRAWAL_TYPES.find((type) => type.id === values.tipo_retiro) || null}
            fullWidth
            clearIcon={null}
          />
          {errors.tipo_retiro && touched.tipo_retiro && (
            <FormHelperText error>{errors.tipo_retiro}</FormHelperText>
          )}
        </Grid>

        <Grid item xs={12}>
          <PeriodStudentSelect
            changeHandler={setStudentPeriod}
            sideFunc={() => setFieldValue('course_student_id', 0)}
            reset={reset}
          />
        </Grid>

        <Grid item xs={12}>
          <InputLabel>{t('students.applications.subject_retire.subject')}</InputLabel>
          {!isLoadingRS ? (
            <Autocomplete
              options={Array.isArray(retirementSubjects) && !isErrorRS ? retirementSubjects : []}
              loading={isLoadingRS}
              loadingText="Cargando materias..."
              getOptionLabel={(option) => option.subject_curriculum.matter_rename}
              noOptionsText={t('students.applications.no_period_selected')}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(errors.course_student_id && touched.course_student_id)}
                  placeholder={t('students.applications.subject_retire.subject_placeholder')}
                />
              )}
              onChange={(_, value) => setFieldValue('course_student_id', value?.id)}
              fullWidth
              clearIcon={null}
              value={retirementSubjects.find((dt) => dt.id === values.course_student_id) || null}
            />
          ) : (
            <TextFieldFetching />
          )}
          {errors.course_student_id && touched.course_student_id && (
            <FormHelperText error>{errors.course_student_id}</FormHelperText>
          )}
        </Grid>
        <Grid item xs={12}>
          <InputLabel>{t('students.applications.subject_retire.reason')}</InputLabel>
          <TextField
            multiline
            rows={2}
            fullWidth
            error={Boolean(errors.app_description && touched.app_description)}
            placeholder={t('students.applications.subject_retire.reason_placeholder')}
            {...getFieldProps('app_description')}
          />
          {errors.app_description && touched.app_description && (
            <FormHelperText error>{errors.app_description}</FormHelperText>
          )}
        </Grid>
        <Grid item xs={12}>
          {/* <InputLabel>{t('students.applications.upload_file')}</InputLabel> */}
          <DragArea
            handleChange={onChangeFile}
            label={t('students.applications.upload_file')}
            onRemoveFile={onRemoveFile}
            typesAccepted={'application/pdf'}
            file={target}
            reset={reset}
          />
          {/* <Grid item xs={12} md={12} container>
            <label className={classes.uploadFile}>
              {file && <IconCircleX className={classes.closeButton} onClick={onRemoveFile} />}
              <br />
              <input name="files[]" type="file" hidden onChange={onChangeFile} />

              <IconFile size={32} />
              {<p>{!file ? `${t('students.applications.upload_file')}` : target.name}</p>}
            </label>
          </Grid> */}
          <Grid item xs={12}>
            {errors.files && touched.files && <FormHelperText error>{errors.files}</FormHelperText>}
          </Grid>
        </Grid>
        <Grid
          item
          container
          alignItems="center"
          justifyContent="flex-end"
          spacing={2}
          sx={{ mt: 1 }}
        >
          {hasAccess('save_subject_retirement') && (
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                size="large"
                color="primary"
                disabled={isLoading}
                endIcon={isLoading && <CircularProgress size={20} />}
              >
                {t('students.applications.save')}
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </form>
  );
};
export default SubjectWithDrawalForm;
