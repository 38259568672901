import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../hooks/useRedux';
import MainCard from '../../../shared/components/cards/MainCard'
import ConfigProductList from '../components/ConfigProductPriceList'
import ConfigProductDeleteDialog from '../components/ConfigProductPriceDeleteDialog'

const ConfigProductPriceListScreen = function () {
    const {t} = useTranslation();
    const { configProductDeleteModal } = useAppSelector((state) => state.configProducts);

    return (
        <Grid component={MainCard} sx={{ padding: 2 }}>
            <Grid sx={{ mb: 3 }}>
                <Typography variant="h2">{t('financial.product_config.title')}</Typography>
            </Grid>

            <ConfigProductList />

            {configProductDeleteModal && <ConfigProductDeleteDialog />}

        </Grid>
    )
}


export default ConfigProductPriceListScreen