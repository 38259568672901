import { Grid } from '@material-ui/core';
import { useAppSelector } from '../../../../../hooks/useRedux';
import MainCard from '../../../../shared/components/cards/MainCard';
import { useGetCourseStudentsQuery } from '../../../courses/slices/coursesTeacherApiSlice';
import CourseStudentsGrid from '../components/CourseStudentsGrid';

const StudentsScreen = () => {
  const {
    course: { courseId },
  } = useAppSelector((state) => state.virtualClassroomTeacherShared);

  const { data, isLoading } = useGetCourseStudentsQuery(
    {
      courseId: courseId!,
      payload: { data: 'all' },
    },
    { skip: courseId === 0 || courseId === undefined }
  );

  return (
    <Grid
      component={MainCard}
      sx={{
        borderTopLeftRadius: '0px',
        borderTopRightRadius: '0px',
      }}
    >
      <CourseStudentsGrid data={data} isLoading={isLoading} />
    </Grid>
  );
};

export default StudentsScreen;
