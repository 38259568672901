import { useEffect, useState } from 'react';
import useTheme from '@material-ui/core/styles/useTheme';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import FormHelperText from '@material-ui/core/FormHelperText';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { IconAlertCircle, IconCheck, IconDeviceFloppy } from '@tabler/icons';
import { useParams } from 'react-router-dom';

import {
  useAddStudentStoreMutation,
  useGetStudentRecordAcademicQuery,
  useUpdateStudentMutation,
} from '../../slices/studentsApiSlice';
import ErrorAlert from '../../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../../shared/components/alerts/SuccessAlert';
import {
  PersonalForm as PersonalInfoInterface,
  LivingPlace as LivingPlaceInterface,
  EducationForm as EducationInterface,
} from '../../interfaces/studentsStore.interface';
import { StudentRecordAcademic } from '../../interfaces/studentRecordAcademic.interfaces';
import PersonalInfo from './PersonalInfoForm';
import LivingPlace from './LivingPlaceForm';
import StudentRecordForm from './StudentRecordForm';
import SimpleFormOneColumn from '../../../../shared/components/forms/skeletons/SimpleFormOneColumn';
import StudentRecordCard from './StudentRecordCard';
import DocumentDataForm from './DocumentDataForm';
import RelativesForm from './RelativesForm';
import EmergencyContactsForm from './EmergencyContactsForm';
import Education from './Education';
import Jobs from './Jobs';

export type NamesTabs =
  | 'personal-info'
  | 'living-place'
  | 'student-record'
  | 'education'
  | 'documents';

export type NamesTabsEdit = 'emergency-contact' | 'relatives' | 'jobs';

export interface ObjectTabs {
  name: NamesTabs;
  label: string;
}

export interface ObjectTabsEdit {
  name: NamesTabsEdit;
  label: string;
}

export interface ErrorValidation {
  formName: NamesTabs;
  error: boolean;
}
export interface PropsForm {
  setData: React.Dispatch<React.SetStateAction<DataPayload>>;
  data: StudentRecordAcademic;
  isEdit: boolean;
  errorValidation?: React.Dispatch<React.SetStateAction<ErrorValidation[]>>;
  errorObject?: ErrorValidation[];
  currentTab?: NamesTabs;
  setCurrentTab?: React.Dispatch<React.SetStateAction<number>>;
  submit?: { setSubmit: () => void; isLoading: boolean };
}

export interface PropsFormEdit {
  data?: StudentRecordAcademic;
}

interface TabPanelProps {
  children?: React.ReactNode;
  name: NamesTabs | NamesTabsEdit;
  value: NamesTabs | NamesTabsEdit;
}

export interface DataPayload {
  personalInfo: PersonalInfoInterface;
  livingPlace: LivingPlaceInterface;
  education: EducationInterface;
}

const personalInfo: ObjectTabs = {
  name: 'personal-info',
  label: 'Información personal',
};

const livingPlace: ObjectTabs = {
  name: 'living-place',
  label: 'Vivienda',
};

const studentRecord: ObjectTabs = {
  name: 'student-record',
  label: 'Record académico',
};

// Edit tabs

const relatives: ObjectTabsEdit = {
  name: 'relatives',
  label: 'Familiares',
};

const emergencyContacts: ObjectTabsEdit = {
  name: 'emergency-contact',
  label: 'Contactos',
};

const jobs: ObjectTabsEdit = {
  name: 'jobs',
  label: 'Trabajos',
};

const documents: ObjectTabs = {
  name: 'documents',
  label: 'Documentos',
};

const education: ObjectTabs = {
  name: 'education',
  label: 'Educación',
};

export const listTabs = [personalInfo, livingPlace, studentRecord];

export const listTabsEdit = [education, documents, relatives, emergencyContacts, jobs];

export const listTabsAll = [...listTabs, ...listTabsEdit];

export function TabPanel(props: TabPanelProps) {
  const { children, value, name, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== name}
      id={`simple-tabpanel-${name}`}
      aria-labelledby={`simple-tab-${name}`}
      {...other}
    >
      {children}
    </div>
  );
}

function a11yProps(name: NamesTabs | NamesTabsEdit) {
  return {
    id: `simple-tab-${name}`,
    'aria-controls': `simple-tabpanel-${name}`,
  };
}

const FormTabs = () => {
  const { t } = useTranslation();

  const theme = useTheme();

  const { studentId } = useParams<{ studentId: string }>();

  const [dataPayload, setDataPayload] = useState<DataPayload>({} as DataPayload);

  const [value, setValue] = useState(0);

  const isEdit = !!studentId;

  const [errorValidation, setErrorValidation] = useState<ErrorValidation[]>(
    [] as ErrorValidation[]
  );

  const [showAlerts, setShowAlerts] = useState(true);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [addStudent, { isLoading, isError, error, isSuccess }] = useAddStudentStoreMutation();

  const [
    updateStudent,
    { isLoading: isLoadingEdit, isError: isErrorEdit, error: errorEdit, isSuccess: isSuccessEdit },
  ] = useUpdateStudentMutation();

  const {
    data: dataStudent,
    isLoading: isLoadingStudent,
    isFetching: isFetchingStudent,
  } = useGetStudentRecordAcademicQuery({
    studentId,
  });

  const { errors, setFieldValue, submitForm } = useFormik({
    initialValues: {
      personalData: {} as PersonalInfoInterface,
      livingPlace: {} as LivingPlaceInterface,
      education: {} as EducationInterface,
    },
    // validationSchema: StudentStoreSchema,
    onSubmit: async ({ personalData, livingPlace, education }) => {
      if (isEdit) {
        await updateStudent({
          // studentPayload: { ...personalInfo, ...livingPlace, ...education },
          studentPayload: {
            person: {
              ...personalData.personalInfo,
              ...livingPlace,
              type_entry_id: education.type_entry_id,
              id_period: education.id_period,
              person_id: dataStudent?.id?.toString() || '',
              user_profile_id: 1,
              email_user_profile: personalData?.personalInfo.pers_email,
            },
            pers_disabilities: personalData.persDisability,
            bus_routes: personalData.bus_routes,
            social_networks: personalData.social_networks,

            // offer_id: education.offer_id,
            can_generate_debt: education.can_generate_debt,
            // education_level_id: education.education_level_id,
          },
          studentId,
        });
      } else {
        console.log('education', education);
        await addStudent({
          studentPayload: {
            person: {
              ...personalData.personalInfo,
              ...livingPlace,
              type_entry_id: education.type_entry_id,
              id_period: education.id_period,
              person_id: dataStudent?.id?.toString() || '',
              user_profile_id: 1,
              email_user_profile: personalData.personalInfo.pers_email,
            },
            pers_disabilities: personalData.persDisability,
            bus_routes: personalData.bus_routes,
            social_networks: personalData.social_networks,
            offer_id: education.offer_id,
            can_generate_debt: education.can_generate_debt,
            education_level_id: education.education_level_id,
          },
        });
      }
      window.scrollTo(0, 0);
      setShowAlerts(true);
    },
  });

  const submitFormik = () => {
    try {
      // if (false) {
      dataPayload?.personalInfo && setFieldValue('personalData', dataPayload?.personalInfo);
      dataPayload?.livingPlace && setFieldValue('livingPlace', dataPayload?.livingPlace);
      dataPayload?.education && setFieldValue('education', dataPayload?.education);
      // }
      submitForm();
    } catch (error) {
      console.log('error');
    }
  };

  useEffect(() => {
    if (
      !isEdit &&
      !!dataPayload.education?.offer_id &&
      !!dataPayload?.livingPlace?.city_id &&
      !!dataPayload?.personalInfo?.personalInfo?.pers_firstname
    ) {
      submitFormik();
    } else if (
      isEdit &&
      !!dataPayload?.livingPlace?.city_id &&
      !!dataPayload?.personalInfo?.personalInfo?.pers_firstname
    ) {
      submitFormik();
    }
    console.log('cambios', dataPayload);
    //eslint-disable-next-line
  }, [dataPayload]);

  return (
    <Grid container spacing={2}>
      {(isError || isErrorEdit) && showAlerts && (
        <ErrorAlert
          message={(error || errorEdit) as string}
          handleDismiss={() => setShowAlerts(false)}
        />
      )}

      {(isSuccess || isSuccessEdit) && showAlerts && (
        <SuccessAlert
          message={
            isEdit
              ? t('Estudiante actualizado exitosamente')
              : t('Estudiante agregado exitosamente')
          }
          handleDismiss={() => setShowAlerts(false)}
        />
      )}
      <Grid item xs={12} sx={{ borderBottom: 1, borderColor: 'divider' }}>
        {errors &&
          Object.keys(errors).map((key) => (
            <FormHelperText error>{errors[key as keyof typeof errors]}</FormHelperText>
          ))}

        <Tabs value={value} onChange={handleChange} variant="scrollable">
          {listTabs.map(({ label, name }, index) => (
            <Tab
              label={label}
              {...a11yProps(name)}
              sx={{
                // borderLeft: `2px solid ${theme.palette.grey[600]}`,

                ...(isEdit &&
                  index + 1 === listTabs.length && {
                    borderLeft: `2px solid ${theme.palette.grey[200]}`,
                  }),
                '& .MuiTab-wrapper': { display: 'contents' },
                '& .MuiTab-wrapper > svg': { marginRight: 1 },
                color: errorValidation.some(({ formName }) => formName === name)
                  ? errorValidation.find(({ formName }) => formName === name)?.error
                    ? theme.palette.error.main
                    : theme.palette.success.dark
                  : 'inherit',
              }}
              icon={
                errorValidation.some(({ formName }) => formName === name) &&
                name !== listTabs[value]?.name ? (
                  errorValidation.find(({ formName }) => formName === name)?.error ? (
                    <IconAlertCircle />
                  ) : (
                    <IconCheck />
                  )
                ) : (
                  <></>
                )
              }
            />
          ))}

          {(isLoading || isLoadingEdit) && (
            <Tab
              label={'Guardando...'}
              sx={{
                '& .MuiTab-wrapper': { display: 'contents' },
                '& .MuiTab-wrapper > svg': { marginRight: 1 },
              }}
              icon={<IconDeviceFloppy />}
              disabled
            />
          )}

          {isEdit &&
            listTabsEdit.map(({ label, name }) => <Tab label={label} {...a11yProps(name)} />)}
        </Tabs>
      </Grid>
      <Grid item xs={12}>
        {isEdit && isLoadingStudent ? (
          <SimpleFormOneColumn />
        ) : (
          <>
            <TabPanel value={listTabs[value]?.name} name={personalInfo.name}>
              <PersonalInfo
                data={dataStudent!}
                setData={setDataPayload}
                isEdit={isEdit}
                currentTab={listTabs[value]?.name}
                errorValidation={setErrorValidation}
                setCurrentTab={setValue}
                errorObject={errorValidation}
                submit={{ setSubmit: submitFormik, isLoading: isLoading || isLoadingEdit }}
              />
            </TabPanel>
            <TabPanel value={listTabs[value]?.name} name={livingPlace.name}>
              <LivingPlace
                data={dataStudent!}
                setData={setDataPayload}
                isEdit={isEdit}
                currentTab={listTabs[value]?.name}
                errorValidation={setErrorValidation}
                setCurrentTab={setValue}
                errorObject={errorValidation}
                submit={{ setSubmit: submitFormik, isLoading: isLoading || isLoadingEdit }}
              />
            </TabPanel>
            <TabPanel value={listTabs[value]?.name} name={studentRecord.name}>
              {isEdit ? (
                <StudentRecordCard data={dataStudent!} />
              ) : (
                <StudentRecordForm
                  data={dataStudent!}
                  setData={setDataPayload}
                  isEdit={isEdit}
                  currentTab={listTabs[value]?.name}
                  errorValidation={setErrorValidation}
                  setCurrentTab={setValue}
                  errorObject={errorValidation}
                  submit={{ setSubmit: submitFormik, isLoading: isLoading || isLoadingEdit }}
                />
              )}
            </TabPanel>
            <TabPanel value={listTabsAll[value]?.name} name={documents.name}>
              <DocumentDataForm data={dataStudent!} />
            </TabPanel>
            <TabPanel value={listTabsAll[value]?.name} name={relatives.name}>
              <RelativesForm data={dataStudent!} isFetchingTable={isFetchingStudent} />
            </TabPanel>
            <TabPanel value={listTabsAll[value]?.name} name={emergencyContacts.name}>
              <EmergencyContactsForm data={dataStudent!} />
            </TabPanel>
            <TabPanel value={listTabsAll[value]?.name} name={education.name}>
              <Education data={dataStudent!} />
            </TabPanel>
            <TabPanel value={listTabsAll[value]?.name} name={jobs.name}>
              <Jobs data={dataStudent!} />
            </TabPanel>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default FormTabs;
