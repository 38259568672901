import { mainApi } from '../../../../api/mainApi';
import { Course } from '../../../maintenance/courses/interfaces/course.interface';
import { middlewareApi } from '../../../middleware/middlewareApi';
import { setCurrentVirtualClassroom } from '../../../shared/reducers/accessReducer';
import { EvaluationStudentDetail } from '../evaluations/interfaces/evaluationDetail.interfaces';
import { VCStudentSaveQuestion } from '../evaluations/interfaces/evaluationPayload.interfaces';
import { EvaluationStudentData } from '../evaluations/interfaces/evaluationStudent.interfaces';
import {
  setEvaluationActive,
  setEvaluationQuestionAnswer,
  setEvaluations,
  setEvalutionFinishedData,
  setMaterialsStudent,
  setScheduleActivities,
} from './virtualClassroomSlice';
import { setCurrentCourse } from '../shared/virtualClassroomSharedSlice';
import { ActivityDetails } from '../schedule/interfaces/scheduleActivitiesInterfaces';
import { Distribution } from '../../../teachers/virtual-classroom/interfaces/distributions.interfaces';
import { ActivityMaterials } from '../../../teachers/virtual-classroom/interfaces/materials.intefaces';
import { AccessState } from '../../../../types';
import { CalificationsStudentResponse } from '../notes/interfaces/notes.interfaces';
import { Activity } from '../dashboard/interfaces/dashboard.interfaces';
import { TaskResponse } from '../tasks/interfaces/task.interface';
import { Material } from '../materials/interfaces/materialsStudent.interfaces';
import {
  AnswersActivity,
  CommentPayload,
  Forum,
  ForumPayload,
  ForumResponse,
} from '../forum/interfaces/forums.interfaces';
import { EvaluationStudentDataFinished } from '../evaluations/interfaces/evaluationFinished.interfaces';
import { TaskPayload, Tasks } from '../tasks/interfaces/tasks.interface';
import { Students } from '../partners/interfaces/student.interfaces';
import { AdTypes, Advertisements } from '../advertisements/interfaces/as.interfaces';
import { Attendance, AttendancePagination } from '../attendance/interfaces/attendance.interfaces';
import { Activities } from '../notes/interfaces/qualifications.interfaces';
import { DataFile, UploadFile } from '../evaluations/interfaces/evaluation.file.interface';
import { PayloadJustifyAttendace } from '../attendance/interfaces/PayloadJustifyAttendace';

export const virtualClassroomStudentTags = middlewareApi.enhanceEndpoints({
  addTagTypes: ['EvaluationStudentList', ''],
});

export const virtualClassroomStudent = virtualClassroomStudentTags.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * @GET Obtener evaluaciones de un estudiante.
     */
    getEvaluationsStudent: builder.query<
      EvaluationStudentData,
      {
        courseId: number;
        studentId: number;
        categoryActivityId?: number;
        userProfileId?: number;
        page?: number;
        dataAll?: string;
        size?: number;
        search?: string;
        estado?: string;
        fdesde?: string;
        fhasta?: string;
      }
    >({
      queryFn: async (
        {
          userProfileId,
          dataAll,
          page,
          size,
          categoryActivityId,
          courseId,
          studentId,
          search,
          estado,
          fdesde,
          fhasta,
        },
        { dispatch, getState }
      ) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const profileId = currentProfile?.profileId;
          const { data } = await mainApi.get<EvaluationStudentData>(
            `courses/${courseId}/students/${studentId}/evaluations`,
            {
              params: {
                user_profile_id: userProfileId || profileId,
                category_activity_id: categoryActivityId,
                page,
                size,
                data: dataAll,
                search,
                estado,
                fdesde,
                fhasta,
              },
            }
          );

          dispatch(setEvaluations(data?.data!));

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result?.data?.map(({ id }) => ({ type: 'EvaluationStudentList', id } as const)),
              { type: 'EvaluationStudentList', id: 'LIST' },
            ]
          : [{ type: 'EvaluationStudentList', id: 'LIST' }],
    }),

    /**
     * Hecho por Anthony Lozano
     * @GET Obtener materiales de un estudiante.
     *
     */
    getMaterialsStudent: builder.query<
      Material[],
      {
        courseId: number;
        userProfileId: number;
      }
    >({
      queryFn: async ({ courseId, userProfileId }, { dispatch, getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };
          const profileId = currentProfile?.profileId;
          const { data } = await mainApi.get<Material[]>(`/contents/courses/${courseId}`, {
            params: {
              user_profile_id: userProfileId || profileId,
            },
          });
          dispatch(setMaterialsStudent(data));
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    /**
     * @GET students availables
     */
    getStudentsAvailablesPartners: builder.query<
      Students,
      { course: number; profileId?: number; page: number; size: number; dataAll?: string }
    >({
      queryFn: async ({ course, profileId, page, size, dataAll }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };
          const userProfileId = currentProfile?.profileId;

          const { data } = await mainApi.get<Students>(`/course/${course}/students`, {
            params: {
              statusApproval: 'Cursando',
              user_profile_id: profileId || userProfileId,
              sort: 'pers_full_lastname',
              type_sort: 'asc',
              page,
              size,
            },
          });

          //dispatch(setHistoryCalification(data!));
          console.log(data);
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    /**
     * @GET Obtener evaluación detalle a realizar
     */
    getEvaluationStudentDetail: builder.query<
      EvaluationStudentDetail,
      {
        evaluationId: number;
        studentId: number;
        userProfileId: number;
        userId: number;
      }
    >({
      queryFn: async ({ userProfileId, userId, evaluationId, studentId }, { dispatch }) => {
        try {
          const { data } = await mainApi.get<EvaluationStudentDetail>(
            `/students/${studentId}/evaluations/${evaluationId}`,
            {
              params: {
                user_profile_id: userProfileId,
                user_id: userId,
              },
            }
          );

          dispatch(setEvaluationActive(data));

          const questionsAnswer = data?.details_activity?.map((e) => ({
            id: e.id,
            resolved: false,
            activity_id: e.activity_id,
            type_answer_id: e.type_answer_id,
            dta_question: e.dta_question,
            dta_score_evaluated: e.dta_score_evaluated,
            is_manual: e.is_manual,
            option_answers: e.option_answers.map((i) => ({
              id: i.id,
              detail_activity_id: i.detail_activity_id,
              op_ans_description: i.op_ans_description,
              op_ans_comment: i.op_ans_comment,
              op_ans_is_answer: false,
            })),
          }))!;

          dispatch(setEvaluationQuestionAnswer(questionsAnswer));

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),
    /**
     * @GET Curso por identificador y establecer aula virtual
     */
    getCourseVC: builder.query<Course, { profileId: number; courseId: number }>({
      queryFn: async ({ profileId, courseId }, { dispatch }) => {
        try {
          dispatch(setCurrentVirtualClassroom(true));

          const { data } = await mainApi.get<Course>(`/courses/${courseId}`, {
            params: {
              user_profile_id: profileId,
            },
          });

          const dataCourse = {
            courseParalel: data?.parallel?.par_name,
            courseHourhandDescription: data?.hourhand?.hour_description,
            courseId: courseId,
            courseName: data?.matter?.mat_name,
            collaborator: data.collaborator?.user?.person?.pers_full_name,
            vcType: data?.type_classroom_virtual,
            collaboratorIdentification: data?.collaborator?.user?.person?.pers_identification,
            collaboratorEmail: data?.collaborator?.user?.email,
          };

          dispatch(setCurrentCourse(dataCourse));

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    /**
     * @GET Iniciar una evaluación de estudiante
     */
    studentVCStartEvaluation: builder.mutation<
      Course,
      { profileId: number; courseId: number; studentId: number; evaluationId: number }
    >({
      queryFn: async ({ profileId, courseId, studentId, evaluationId }, { dispatch }) => {
        try {
          const { data } = await mainApi.post(
            `/courses/${courseId}/students/${studentId}/evaluations/${evaluationId}`,
            {
              params: {
                user_profile_id: profileId,
              },
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'EvaluationStudentList', id: 'LIST' }],
    }),
    /**
     * @GET get list distribution by course
     */
    getDistributionsByCourse: builder.query<
      Distribution[],
      { profileId: number; courseId: string }
    >({
      queryFn: async ({ profileId, courseId }) => {
        try {
          const { data } = await mainApi.get<Distribution[]>(
            `/distributions/courses/${courseId}/supplies`,
            {
              params: {
                user_profile_id: profileId,
              },
            }
          );

          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
    }),
    getActivitiesWithMaterialsByCourse: builder.query<ActivityMaterials[], { courseId: string }>({
      queryFn: async ({ courseId }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };
          const user_profile_id = currentProfile?.profileId;
          const { data } = await mainApi.get<ActivityMaterials[]>(
            `/courses/${courseId}/activities/materials`,
            {
              params: {
                user_profile_id,
              },
            }
          );

          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
    }),
    /**
     * @GET get list of recent activities by course and
     */
    getActivitiesByCourseAndStudent: builder.query<
      Activity[],
      { courseId: string; studentId: number; profileId?: number }
    >({
      queryFn: async ({ profileId, courseId, studentId }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };
          const { data } = await mainApi.get<Activity[]>(`/courses/${courseId}/activities/recent`, {
            params: {
              user_profile_id: profileId || currentProfile?.profileId,
              student_id: studentId,
            },
          });

          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
    }),
    /**
     * @POST Registrar la respuesta de una pregunta seleccionada.
     */
    studentVCEvaluationSaveQuestion: builder.mutation<
      EvaluationStudentData,
      {
        profileId: number;
        questionId: number;
        studentId: number;
        evaluationId: number;
        payload: VCStudentSaveQuestion;
      }
    >({
      queryFn: async (
        { profileId, questionId, studentId, evaluationId, payload },
        { dispatch }
      ) => {
        try {
          const { data } = await mainApi.post(
            `/students/${studentId}/evaluations/${evaluationId}/questions/${questionId}`,
            {
              user_profile_id: profileId,
              ...payload,
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'EvaluationStudentList', id: 'LIST' }],
    }),

    /**
     * @PUT Actualizar la respuesta de una pregunta seleccionada.
     */
    studentVCEvaluationUpdateQuestion: builder.mutation<
      EvaluationStudentData,
      {
        profileId: number;
        questionId: number;
        studentId: number;
        evaluationId: number;
        payload: VCStudentSaveQuestion;
      }
    >({
      queryFn: async (
        { profileId, questionId, studentId, evaluationId, payload },
        { dispatch }
      ) => {
        try {
          const { data } = await mainApi.put(
            `/students/${studentId}/evaluations/${evaluationId}/questions/${questionId}`,
            {
              user_profile_id: profileId,
              ...payload,
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'EvaluationStudentList', id: 'LIST' }],
    }),

    /**
     * @PUT Finalizar una evaluación de un estudiante.
     */
    studentVCEvaluationFinish: builder.mutation<
      EvaluationStudentData,
      {
        profileId: number;
        studentId: number;
        evaluationId: number;
      }
    >({
      queryFn: async ({ profileId, studentId, evaluationId }, { dispatch }) => {
        try {
          const { data } = await mainApi.put(`/students/${studentId}/evaluations/${evaluationId}`, {
            params: {
              user_profile_id: profileId,
            },
          });
          dispatch(setEvalutionFinishedData(data as EvaluationStudentDataFinished));

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'EvaluationStudentList', id: 'LIST' }],
    }),
    /**
     * @GET Curso por identificador y establecer aula virtual
     */
    getScheduleActivities: builder.query<
      any,
      { profileId: number; courseId: number; month: number; year: number }
    >({
      queryFn: async ({ profileId, courseId, month, year }, { dispatch }) => {
        try {
          const { data } = await mainApi.get<ActivityDetails[]>(`/courses/${courseId}/activities`, {
            params: {
              user_profile_id: profileId,
              month,
              year,
            },
          });
          dispatch(setScheduleActivities(data));

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    /**
     * @GET Curso por identificador y establecer aula virtual
     */
    getStudentCalifications: builder.query<
      CalificationsStudentResponse[],
      { stundentId: number; courseId: number }
    >({
      queryFn: async ({ stundentId, courseId }, { dispatch, getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = currentProfile?.profileId;

          const { data } = await mainApi.get<CalificationsStudentResponse[]>(
            `/courses/${courseId}/students/${stundentId}/scores`,
            {
              params: {
                user_profile_id,
              },
            }
          );

          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    /**
     * @GET get list Forum by course
     */
    getListForumByCourse: builder.query<
      ForumResponse,
      {
        dataAll?: string;
        profileId: number;
        page?: number;
        perPage?: number;
        search?: string;
        courseId: string;
      }
    >({
      queryFn: async ({ dataAll, profileId, page, search, perPage, courseId }, { dispatch }) => {
        try {
          const { data } = await mainApi.get<ForumResponse>(`/courses/${courseId}/forums`, {
            params: {
              user_profile_id: profileId,
              page,
              size: perPage,
              type_sort: 'desc',
              search,
              data: dataAll,
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    /**
     * @GET task by student
     */
    getStudentTask: builder.query<TaskResponse[], { studentId: number; courseId: number }>({
      queryFn: async ({ studentId, courseId }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = currentProfile?.profileId;

          const { data } = await mainApi.get<TaskResponse[]>(
            `/courses/${courseId}/students/${studentId}/tasks`,
            {
              params: {
                user_profile_id,
              },
            }
          );

          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    /**
     * @GET Get info forum and comments
     */
    getComments: builder.query<Forum, { forumId: number; courseId: number; profileId?: number }>({
      queryFn: async ({ profileId, forumId, courseId }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = currentProfile?.profileId;
          const { data } = await mainApi.get<Forum>(`/courses/${courseId}/forums/${forumId}`, {
            params: {
              user_profile_id: profileId || user_profile_id,
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    /**
     *
     * @POST ADD FILE POST
     */
    addFileBucket: builder.mutation<
      DataFile[],
      {
        PayloadFile: Partial<UploadFile>;
      }
    >({
      queryFn: async ({ PayloadFile }) => {
        try {
          const form = new FormData();
          form.append('files[]', PayloadFile.files || '');
          form.append('type_document', '18');

          const { data: result } = await mainApi.post(`/upload/`, form, {
            headers: { 'Content-Type': 'multipart/form-data' },
          });

          return { data: result };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    /**
     * @POST create comment
     */
    addComments: builder.mutation<
      CommentPayload,
      {
        forumId: number;
        CommentPayload: Partial<CommentPayload>;
      }
    >({
      queryFn: async ({ forumId, CommentPayload }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };
          CommentPayload.user_profile_id = currentProfile?.profileId;

          const form = new FormData();
          form.append('files[]', CommentPayload.files || '');
          form.append('parent_id', CommentPayload.parent_id || '');
          form.append('csact_comments', CommentPayload.csact_comments!);
          form.append('type_document', CommentPayload.type_document!.toString());
          form.append('user_id', CommentPayload.user_id!.toString());
          form.append('student_id', CommentPayload.student_id!.toString());
          form.append('user_profile_id', CommentPayload.user_profile_id!.toString());

          const { data: result } = await mainApi.post(`/forums/${forumId}/comments`, form, {
            headers: { 'Content-Type': 'multipart/form-data' },
          });

          return { data: result };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    /**
     * @POST update forum comment
     */
    updateComment: builder.mutation<
      ForumPayload,
      {
        commentId: number;
        CommentPayload: Partial<CommentPayload>;
      }
    >({
      queryFn: async ({ commentId, CommentPayload /*ForumPayload*/ }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };
          /*const { data } = await mainApi.put(`/forums/comments/${commentId}`, {
            user_profile_id: currentProfile,
            ...CommentPayload,
            //...ForumPayload,
          });*/

          const user_profile_id = currentProfile?.profileId;
          const form = new FormData();
          form.append('files[]', CommentPayload.files || '');
          form.append('csact_comments', CommentPayload.csact_comments!);
          form.append('type_document', CommentPayload.type_document!.toString());
          form.append('user_profile_id', `${user_profile_id!}`);

          const { data } = await mainApi.post(`/forums/comments/${commentId}`, form, {
            headers: { 'Content-Type': 'multipart/form-data' },
          });

          return data;
        } catch (error: any) {
          return { error };
        }
      },
    }),

    /**
     * @DELETE remove comment
     */
    deleteComment: builder.mutation<AnswersActivity, { commentId: number }>({
      queryFn: async ({ commentId }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const { data } = await mainApi.delete(`forums/comments/${commentId}`, {
            data: {
              user_profile_id: currentProfile?.profileId,
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    // task

    getAllTasksStudent: builder.query<
      Tasks,
      {
        courseId: number;
        studentId: number;
        page: number;
        size: number;
        profileId?: number;
        tasks_recent?: boolean;
        tasks_expire?: boolean;
      }
    >({
      queryFn: async (
        { profileId, courseId, studentId, page, size, tasks_recent, tasks_expire },
        { getState }
      ) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const { data } = await mainApi.get<Tasks>(
            `/courses/${courseId}/students/${studentId}/tasks${
              tasks_recent ? '?tasks_recent=1' : ''
            }${tasks_expire ? '?tasks_expire=1' : ''}`,
            {
              params: {
                user_profile_id: profileId || currentProfile?.profileId,
                page,
                size,
              },
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    /**
     * @POST Registrar la respuesta de una pregunta seleccionada.
     */
    saveTaskFile: builder.mutation<
      Tasks,
      {
        profileId?: number;
        taskId: number;
        courseId: number;
        studentId: number;
        detailId: number;
        payload: Partial<TaskPayload>;
      }
    >({
      queryFn: async (
        { profileId, studentId, courseId, taskId, detailId, payload },
        { getState }
      ) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };
          const user_profile_id = currentProfile?.profileId;
          const form = new FormData();
          form.append('files[]', payload.files || '');
          form.append('description', payload.description!);
          form.append('title', payload.title!);
          form.append('type_document', payload.type_document!.toString());

          const { data } = await mainApi.post(
            `/courses/${courseId}/tasks/${taskId}/students/${studentId}/details/${detailId}?user_profile_id=${user_profile_id}`,
            form,
            {
              headers: { 'Content-Type': 'multipart/form-data' },
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    /**
     * @POST Registrar la respuesta de una pregunta seleccionada.
     */
    saveTaskComment: builder.mutation<
      Tasks,
      {
        profileId?: number;
        taskId: number;
        studentId: number;
        detailId: number;
        payload: {
          csact_comments: string;
        };
      }
    >({
      queryFn: async ({ profileId, studentId, taskId, detailId, payload }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };
          const user_profile_id = currentProfile?.profileId;
          const form = new FormData();
          form.append('csact_comments', payload.csact_comments!);

          const { data } = await mainApi.post(
            `/students/${studentId}/tasks/${taskId}/questions/${detailId}?user_profile_id=${user_profile_id}`,
            form,
            {
              headers: { 'Content-Type': 'multipart/form-data' },
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    /**Ad */
    getAdTypes: builder.query<
      AdTypes[],
      {
        profileId?: number;
      }
    >({
      queryFn: async ({ profileId }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const { data } = await mainApi.get<AdTypes[]>(`/announcements/types`, {
            params: {
              user_profile_id: profileId || currentProfile?.profileId,
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),
    getAdvertisements: builder.query<
      Advertisements,
      {
        profileId?: number;
        courseId: number;
        studentId: number;
        page: number;
        size: number;
        type_ads?: string;
      }
    >({
      queryFn: async ({ profileId, courseId, studentId, page, size, type_ads }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const params = type_ads
            ? {
                user_profile_id: profileId || currentProfile?.profileId,
                page,
                size,
                type_ads,
                sort: 'id',
                type_sort: 'desc',
              }
            : {
                user_profile_id: profileId || currentProfile?.profileId,
                page,
                size,
                sort: 'id',
                type_sort: 'desc',
              };

          const { data } = await mainApi.get<Advertisements>(
            `/course/${courseId}/students/${studentId}/announcements`,
            {
              params,
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    getAttendance: builder.query<
      Attendance[],
      {
        courseId: string;
        studentId: string;
        month: string;
      }
    >({
      queryFn: async ({ courseId, studentId, month }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const { data } = await mainApi.get<Attendance[]>(
            `/fault-assistants/student/absences?user_profile_id=${currentProfile?.profileId!}&data=all`,
            {
              params: {
                course_id: courseId,
                student_id: studentId,
                month: month,
              },
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    /**
     * @GET Attendance
     */
    getAttendancePagination: builder.query<
      AttendancePagination,
      {
        courseId: string;
        studentId: string;
        month: string;
        page: number;
        size: number;
      }
    >({
      queryFn: async ({ courseId, studentId, month, page, size }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const { data } = await mainApi.get<AttendancePagination>(
            `/fault-assistants/student/absences`,
            {
              params: {
                user_profile_id: currentProfile?.profileId!,
                page,
                size,
                course_id: courseId,
                student_id: studentId,
                month: month,
              },
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    /**
     * @GET Attendance
     */
    getQualifications: builder.query<
      Activities,
      {
        courseId: string;
        studentId: string;
        page: number;
        size: number;
        search: string;
        supplyId?: string;
        activityType?: number;
      }
    >({
      queryFn: async (
        { courseId, studentId, page, size, search, supplyId, activityType },
        { getState }
      ) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const params = supplyId
            ? {
                user_profile_id: currentProfile?.profileId!,
                page,
                size,
                supply_id: supplyId,
                search,
                ...(activityType && { type_activity_id: activityType }),
              }
            : {
                user_profile_id: currentProfile?.profileId!,
                page,
                size,
                search,
                ...(activityType && { type_activity_id: activityType }),
              };

          const { data } = await mainApi.get<Activities>(
            `/courses/${courseId}/students/${studentId}/activities`,
            {
              params,
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    /**
     * @POST agrega materiales al curso
     */
    addJustifyAttendance: builder.mutation<
      PayloadJustifyAttendace,
      { PayloadJustify: PayloadJustifyAttendace }
    >({
      queryFn: async ({ PayloadJustify }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = currentProfile?.profileId;

          let photoPath = '';
          if (PayloadJustify.file_url) {
            const form = new FormData();
            form.append('files[]', PayloadJustify.file_url);
            // form.append('type_document', String(PayloadJustify.));
            //form.append('period', '1')
            form.append('user_profile_id', String(user_profile_id));

            const { data: dataPhoto } = await mainApi.post<string>(
              '/applications/others/upload/files',
              form,
              {
                headers: { 'Content-Type': 'multipart/form-data' },
              }
            );
            photoPath = dataPhoto;
          }

          //activities/10180/materials?user_profile_id=1
          ///courses/${courseId}/materials
          const { data } = await mainApi.post<PayloadJustifyAttendace>(
            `/applications/justify-fault`,
            {
              user_profile_id,
              ...{ ...PayloadJustify, file_url: photoPath },
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'EvaluationStudentList', id: 'justifyAttendance' }],
    }),

    //
  }),
});

export const {
  useGetEvaluationsStudentQuery,
  useGetCourseVCQuery,
  useStudentVCEvaluationSaveQuestionMutation,
  useStudentVCEvaluationUpdateQuestionMutation,
  useStudentVCEvaluationFinishMutation,
  useStudentVCStartEvaluationMutation,
  useGetEvaluationStudentDetailQuery,
  useGetScheduleActivitiesQuery,
  useGetDistributionsByCourseQuery,
  useGetActivitiesWithMaterialsByCourseQuery,

  useGetStudentCalificationsQuery,
  useGetActivitiesByCourseAndStudentQuery,
  useGetStudentTaskQuery,
  useGetMaterialsStudentQuery,
  useGetStudentsAvailablesPartnersQuery,
  useGetListForumByCourseQuery,
  useGetCommentsQuery,
  useAddCommentsMutation,
  useUpdateCommentMutation,
  useDeleteCommentMutation,
  useGetAllTasksStudentQuery,
  useSaveTaskFileMutation,
  useSaveTaskCommentMutation,
  useGetAdTypesQuery,
  useGetAdvertisementsQuery,
  useGetAttendanceQuery,
  useGetAttendancePaginationQuery,
  useGetQualificationsQuery,
  useAddFileBucketMutation,
  useAddJustifyAttendanceMutation,
} = virtualClassroomStudent;
