import { useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
  Autocomplete,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SuccessAlert from '../../../../../shared/components/alerts/SuccessAlert';
import ErrorAlert from '../../../../../shared/components/alerts/ErrorAlert';
import {
  useAddTemplateEvaluationMutation,
  useEditTemplateEvaluationMutation,
  useLazyGetTemplateEvaByIdQuery,
} from '../slices/evaTemplatesApiSlice';
import { useGetAllEvaluationTypesQuery } from '../../evaluation_type/slices/evaluationTypeApiSlice';
import QuestionTableForm from './QuestionTableForm';
import { templateFormSchema } from '../validation/templateSchema.validation';
import { useGetAllOptionsTemplatesQuery } from '../../options_template/slices/optionsTemplateApiSlice';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/useRedux';
import TextFieldFetching from '../../../../../shared/components/TextFieldFetching';
import { setQuestionsSelected } from '../slices/evaTemplateSlice';

const TemplateForm = () => {
  const { t } = useTranslation();

  const { id } = useParams<{ id: string }>();

  const dispatch = useAppDispatch();

  const { questionsSelected } = useAppSelector((state) => state.templateEva);

  const [showAlerts, setShowAlerts] = useState(false);

  // const [scaleType, setScaleType] = useState('C');

  // const scaleOptions = [
  //   { value: 'C', label: t('Calificativa') },
  //   { value: 'R', label: t('Representativa') },
  // ];

  const [getDataTemplateById, { data: templateData, isLoading: isLoadingTemplateById }] =
    useLazyGetTemplateEvaByIdQuery();

  const { data: evaluationTypes, isLoading: isLoadingEvaluationTypes } =
    useGetAllEvaluationTypesQuery({});

  const { data: optionsTemplates, isLoading: isLoadingOptionsTemplates } =
    useGetAllOptionsTemplatesQuery({});

  // const { data: representativeScale, isLoading: isLoadingRepresentativeScale } =
  //   useGetRepresentativeScaleAllQuery({});

  const [addTemplateEva, { isLoading, isError, error, isSuccess }] =
    useAddTemplateEvaluationMutation();

  const [
    editTemplateEva,
    { isLoading: isLoadingEdit, isError: isErrorEdit, error: errorEdit, isSuccess: isSuccessEdit },
  ] = useEditTemplateEvaluationMutation();

  const { errors, getFieldProps, handleSubmit, touched, resetForm, setFieldValue, values } =
    useFormik({
      initialValues: {
        name: '',
        description: '',
        eva_type_id: '',
        point_end: '',
        questions: [],
        eva_scale_evaluativa_uuid: '',
      },
      validationSchema: templateFormSchema,
      onSubmit: async (currentValues) => {
        if (id) {
          await editTemplateEva({
            id: id,
            payload: {
              name: currentValues.name,
              description: currentValues.description,
              eva_type_id: currentValues.eva_type_id,
              point_end: Number(currentValues.point_end),
              questions: currentValues.questions,
              eva_scale_evaluativa_uuid: currentValues.eva_scale_evaluativa_uuid,
            },
          });
        } else {
          await addTemplateEva({
            name: currentValues.name,
            description: currentValues.description,
            eva_type_id: currentValues.eva_type_id,
            point_end: Number(currentValues.point_end),
            questions: currentValues.questions,
            eva_scale_evaluativa_uuid: currentValues.eva_scale_evaluativa_uuid,
          });
        }
        setShowAlerts(true);
      },
    });

  useEffect(() => {
    if (id) {
      const executeGetTemplateById = async () => {
        await getDataTemplateById({
          id: id,
        });
      };
      executeGetTemplateById();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (templateData) {
      setFieldValue('name', templateData.name);
      setFieldValue('description', templateData.description);
      setFieldValue('eva_type_id', templateData.eva_type_id);
      setFieldValue('point_end', Number(templateData.point_end).toFixed(2));
      setFieldValue('eva_scale_evaluativa_uuid', templateData.eva_scale_evaluativa_uuid);
      setFieldValue(
        'questions',
        templateData.eva_evaluations_questions.map((item) => item.question_uuid)
      );
      dispatch(
        setQuestionsSelected(
          templateData.eva_evaluations_questions.map((item) => ({
            uuid: item?.question_uuid,
            name: item?.evaluation_question.name,
            description: item?.evaluation_question.description,
            category_uuid: item?.evaluation_question.category_uuid,
          }))
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateData]);

  useEffect(() => {
    if (questionsSelected.length > 0) {
      setFieldValue(
        'questions',
        questionsSelected.map((item) => item.uuid)
      );
    } else {
      setFieldValue('questions', []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionsSelected]);

  useEffect(() => {
    if (isSuccess) {
      resetForm();
    }
  }, [isSuccess, resetForm]);

  return (
    <Grid container spacing={2}>
      {(isSuccess || isSuccessEdit) && showAlerts && (
        <Grid item xs={12}>
          <SuccessAlert
            message={
              isSuccess
                ? t('maintenance.integral_evaluation.add.success')
                : t('maintenance.integral_evaluation.edit.success')
            }
            handleDismiss={() => setShowAlerts(false)}
          />
        </Grid>
      )}
      {(isError || isErrorEdit) && showAlerts && (
        <Grid item xs={12}>
          <ErrorAlert
            message={(error || errorEdit) as string}
            handleDismiss={() => setShowAlerts(false)}
          />
        </Grid>
      )}

      <Grid item xs={12}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <InputLabel>{t('maintenance.integral_evaluation.form.name')}</InputLabel>

              {isLoadingTemplateById ? (
                <TextFieldFetching fullWidth placeholder={t('Cargando puntos...')} disabled />
              ) : (
                <>
                  <TextField
                    placeholder={t('maintenance.integral_evaluation.form.name.placeholder')}
                    error={Boolean(errors.name && touched.name)}
                    {...getFieldProps('name')}
                    fullWidth
                  />
                  {errors.name && touched.name && (
                    <FormHelperText error>{t(errors.name)}</FormHelperText>
                  )}
                </>
              )}
            </Grid>

            <Grid item xs={6}>
              <InputLabel>{t('maintenance.integral_evaluation.description')}</InputLabel>
              {isLoadingTemplateById ? (
                <TextFieldFetching fullWidth placeholder={t('Cargando puntos...')} disabled />
              ) : (
                <>
                  <TextField
                    placeholder={t('maintenance.integral_evaluation.description.placeholder')}
                    error={Boolean(errors.description && touched.description)}
                    {...getFieldProps('description')}
                    fullWidth
                  />
                  {errors.description && touched.description && (
                    <FormHelperText error>{t(errors.description)}</FormHelperText>
                  )}
                </>
              )}
            </Grid>

            <Grid item xs={6}>
              <InputLabel>{t('maintenance.integral_evaluation.evaTypes')}</InputLabel>
              {isLoadingEvaluationTypes || isLoadingTemplateById ? (
                <TextFieldFetching
                  fullWidth
                  placeholder={t('shared.filters.eva_type.loading')}
                  disabled
                />
              ) : (
                <Autocomplete
                  options={evaluationTypes || []}
                  getOptionLabel={(option) => option.name}
                  value={
                    evaluationTypes?.find(
                      (item) => item.uuid?.toString() === values.eva_type_id?.toString()
                    ) || null
                  }
                  onChange={(event, newValue) => {
                    setFieldValue('eva_type_id', newValue?.uuid);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="medium"
                      fullWidth
                      placeholder={t('shared.filters.eva_type.placeholder')}
                      error={Boolean(errors.eva_type_id && touched.eva_type_id)}
                    />
                  )}
                />
              )}
              {errors.eva_type_id && touched.eva_type_id && (
                <FormHelperText error>{t(errors.eva_type_id)}</FormHelperText>
              )}
            </Grid>
            <Grid item xs={6}>
              <InputLabel>{t('Puntos')}</InputLabel>
              {isLoadingTemplateById ? (
                <TextFieldFetching fullWidth placeholder={t('Cargando puntos...')} disabled />
              ) : (
                <>
                  <TextField
                    placeholder={t('Ingrese los puntos')}
                    error={Boolean(errors.point_end && touched.point_end)}
                    {...getFieldProps('point_end')}
                    fullWidth
                  />
                  {errors.point_end && touched.point_end && (
                    <FormHelperText error>{t(errors.point_end)}</FormHelperText>
                  )}
                </>
              )}
            </Grid>

            {/* <Grid item xs={6}>
              <InputLabel>{t('Tipo de escala')}</InputLabel>
              <Autocomplete
                value={scaleOptions.find((option) => option.value === scaleType) || null}
                options={scaleOptions}
                getOptionLabel={(option) => option.label}
                onChange={(event, newValue) => {
                  setScaleType(newValue?.value!);
                  setFieldValue('eva_scale_evaluativa_uuid', '');
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="medium"
                    fullWidth
                    placeholder={t('Seleccione el tipo de escala')}
                    error={Boolean(
                      errors.eva_scale_evaluativa_uuid && touched.eva_scale_evaluativa_uuid
                    )}
                  />
                )}
              />
            </Grid> */}

            <Grid item xs={6}>
              <InputLabel>{t('Escala')}</InputLabel>
              {isLoadingOptionsTemplates || isLoadingTemplateById ? (
                <TextFieldFetching fullWidth placeholder={t('Cargando escalas...')} disabled />
              ) : (
                <Autocomplete
                  options={optionsTemplates || []}
                  getOptionLabel={(option) => option.description}
                  value={
                    optionsTemplates?.find(
                      (option) => option.uuid === values.eva_scale_evaluativa_uuid
                    ) || null
                  }
                  onChange={(event, newValue) => {
                    setFieldValue('eva_scale_evaluativa_uuid', newValue?.uuid);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="medium"
                      fullWidth
                      placeholder={t('Seleccione la escala')}
                      error={Boolean(
                        errors.eva_scale_evaluativa_uuid && touched.eva_scale_evaluativa_uuid
                      )}
                    />
                  )}
                />
              )}
              {errors.eva_scale_evaluativa_uuid && touched.eva_scale_evaluativa_uuid && (
                <FormHelperText error>{t(errors.eva_scale_evaluativa_uuid)}</FormHelperText>
              )}
            </Grid>

            {/* {scaleType === 'R' && (
              <Grid item xs={6}>
                <InputLabel>{t('Escala')}</InputLabel>
                {isLoadingRepresentativeScale ? (
                  <TextField fullWidth placeholder={t('Cargando escalas...')} disabled />
                ) : (
                  <Autocomplete
                    options={representativeScale || []}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, newValue) => {
                      setFieldValue('eva_type_id', newValue?.id);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="medium"
                        fullWidth
                        placeholder={t('shared.filters.eva_type.placeholder')}
                        error={Boolean(
                          errors.eva_scale_evaluativa_uuid && touched.eva_scale_evaluativa_uuid
                        )}
                      />
                    )}
                  />
                )}
                {errors.eva_scale_evaluativa_uuid && touched.eva_scale_evaluativa_uuid && (
                  <FormHelperText error>{t(errors.eva_scale_evaluativa_uuid)}</FormHelperText>
                )}
              </Grid>
            )} */}

            <Grid item xs={12}>
              <InputLabel
                sx={{
                  mb: 2,
                }}
              >
                {t('Preguntas')}:{' '}
                <b>
                  {questionsSelected.length} {t('seleccionadas')}
                </b>
              </InputLabel>
              <QuestionTableForm />

              {errors.questions && touched.questions && (
                <FormHelperText
                  error
                  sx={{
                    mt: 2,
                  }}
                >
                  {t(errors.questions)}
                </FormHelperText>
              )}
            </Grid>

            <Grid
              item
              container
              xs={12}
              justifyContent="flex-end"
              spacing={2}
              sx={{
                mt: 3,
              }}
            >
              <Grid item>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  disabled={isLoading || isLoadingEdit}
                  endIcon={(isLoading || isLoadingEdit) && <CircularProgress size={12} />}
                >
                  {id ? t('Editar') : t('Crear')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default TemplateForm;
