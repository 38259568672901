import { useEffect, useLayoutEffect, useRef, useState } from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import Grid from '@material-ui/core/Grid';
import Stack from '@material-ui/core/Stack';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';

import MailIcon from '@material-ui/icons/Mail';

import ProfileSkeletonCard from '../../../shared/components/cards/Skeleton/ProfileSkeletonCard';
import MainCard from '../../../shared/components/cards/MainCard';
import { useAppSelector } from '../../../../hooks/useRedux';

import Noloadedavatar from './../../../../../src/assets/images/users/noloadedavatar.png';
import { useTranslation } from 'react-i18next';
import { useGetRedlinksAvatarQueryQuery } from '../../../shared/slices/redlinksApi';
import AnimateButton from '../../../shared/components/extended/AnimateButton';

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.dark.dark : theme.palette.primary.main,
    color: '#fff',
    overflow: 'hidden',
    position: 'relative',
    '&>div': {
      position: 'relative',
      zIndex: 5,
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      width: '210px',
      height: '210px',
      background:
        theme.palette.mode === 'dark'
          ? 'linear-gradient(210.04deg, ' +
            theme.palette.primary.dark +
            ' -50.94%, rgba(144, 202, 249, 0) 95.49%)'
          : theme.palette.primary[200],
      borderRadius: '50%',
      zIndex: 1,
      top: '-85px',
      right: '-95px',
      [theme.breakpoints.down('xs')]: {
        top: '-105px',
        right: '-140px',
      },
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      zIndex: 1,
      width: '210px',
      height: '210px',
      background:
        theme.palette.mode === 'dark'
          ? 'linear-gradient(140.9deg, ' +
            theme.palette.primary.dark +
            ' -14.02%, rgba(144, 202, 249, 0) 82.50%)'
          : theme.palette.primary[200],
      borderRadius: '50%',
      top: '-125px',
      right: '-15px',
      opacity: 0.5,
      [theme.breakpoints.down('xs')]: {
        top: '-155px',
        right: '-70px',
      },
    },
  },
  content: {
    padding: '20px !important',
  },
  avatar: {
    ...theme.typography.commonAvatar,
    ...theme.typography.largeAvatar,
    cursor: 'default',
    // margin: '8px',
    height: 'auto',
    width: 'auto',
    backgroundColor: 'transparent',
    // backgroundColor: theme.palette.secondary[200],
    // padding: '0px 15px',
  },
}));

const AcademicAdvisor = () => {
  const theme = useTheme();
  const classes = useStyles();

  const { t } = useTranslation();

  const containerRef = useRef<HTMLDivElement | null>(null);

  //
  const [isNormal, setIsNormal] = useState(true);

  const { userInfo } = useAppSelector((state) => state.auth);
  const { currentRecordStudent } = useAppSelector((state) => state.access);

  const [photo, setPhoto] = useState(Noloadedavatar);

  const { data: redlinksInfo } = useGetRedlinksAvatarQueryQuery(
    {
      identification: currentRecordStudent?.collaborator?.pers_identification || '0',
    },
    { skip: !currentRecordStudent?.collaborator?.pers_identification }
  );

  const handleResetPhoto = () => {
    setPhoto(Noloadedavatar);
  };

  useEffect(() => {
    if (redlinksInfo?.result?.foto) setPhoto(redlinksInfo?.result?.foto);
  }, [redlinksInfo]);

  useLayoutEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      if (entries[0].contentRect.width > 380) {
        setIsNormal(true);
      } else {
        setIsNormal(false);
      }
    });

    resizeObserver.observe(containerRef.current as any);
  }, []);

  return (
    <>
      {false ? (
        <ProfileSkeletonCard className={classes.card} />
      ) : (
        <MainCard
          border={false}
          className={classes.card}
          contentClass={classes.content}
          ref={containerRef}
        >
          <Stack spacing={2} pt={1}>
            <Grid item>
              <Typography fontSize="1.4rem" fontWeight="bold">
                {t('students.dashboard.academic_advisor.title')}
              </Typography>
            </Grid>

            <Grid item>
              <Grid container>
                <Grid item xs={isNormal ? 4 : 12}>
                  <Avatar
                    variant="rounded"
                    className={`${classes.avatar}`}
                    sx={{ mb: isNormal ? 0 : 1 }}
                  >
                    {/* <object
                      data={
                        redlinksInfo?.result?.foto ? redlinksInfo?.result?.foto : Noloadedavatar
                      }
                      style={{
                        height: '80px',
                        borderRadius: '50%',
                      }}
                      type="image/jpg"
                    > */}
                    <img
                      style={{
                        height: '80px',
                        borderRadius: '50%',
                      }}
                      src={photo}
                      onError={handleResetPhoto}
                      alt={userInfo?.fullname ? userInfo.fullname : 'Estudiante'}
                    />
                    {/* </object> */}
                  </Avatar>
                </Grid>
                <Grid item xs={isNormal ? 8 : 12}>
                  <Stack spacing={1}>
                    <Typography
                      component="p"
                      width="100%"
                      fontSize="1.1rem"
                      fontWeight="bold"
                      align="left"
                    >
                      {currentRecordStudent?.collaborator?.pers_full_name ||
                        'No Tiene asignado un consejero/a'}
                    </Typography>

                    {currentRecordStudent?.collaborator && (
                      <Grid item xs={12}>
                        <Grid container spacing={1} alignItems="center">
                          <Grid item>
                            <MailIcon fontSize="small" />
                          </Grid>
                          <Grid item>
                            <Typography component="span" align="left">
                              {currentRecordStudent?.collaborator?.pers_email}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </Grid>

            {currentRecordStudent?.collaborator && (
              <Grid container item xs={12} justifyContent="center">
                <Grid item py={1}>
                  {/* <Button variant="contained" sx={{ borderRadius: `${theme.shape.borderRadius}` }}> */}
                  <AnimateButton>
                    <Button
                      variant="contained"
                      color="inherit"
                      size="small"
                      sx={{
                        borderRadius: `12px`,
                        py: 0,
                        px: 4,
                        backgroundColor: `${theme.palette.primary.light}`,
                        color: `${theme.palette.primary.main}`,
                        fontWeight: 800,
                        fontSize: '1.2rem',
                      }}
                      onClick={() =>
                        (window.location =
                          `mailto:${currentRecordStudent?.collaborator?.pers_email}` as any)
                      }
                    >
                      {t('students.dashboard.academic_advisor.button')}
                    </Button>
                  </AnimateButton>
                </Grid>
              </Grid>
            )}
          </Stack>
        </MainCard>
      )}
    </>
  );
};

export default AcademicAdvisor;
