import { Grid, Typography } from '@material-ui/core';
import MainCard from '../../../shared/components/cards/MainCard';
import TitulationForm from '../components/TitulationForm';

import { useTranslation } from 'react-i18next';

const TitulationScreen = () => {
  const { t } = useTranslation();

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">{t('students.menu.applications.title')}</Typography>
      </Grid>
      <TitulationForm />
    </Grid>
  );
};
export default TitulationScreen;
