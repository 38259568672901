import { useEffect, useMemo, useState } from 'react';

import {
  Autocomplete,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  TextField,
  useTheme,
  Stack,
  IconButton,
  Box,
  Alert,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { useAppSelector } from '../../../../hooks/useRedux';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import InputLabel from '../../../shared/components/forms/InputLabel';
import { statusAvailables } from '../../../shared/constants/resourceStatus';
import { DaysWeeks } from '../../hourhands/constants/mockOptions';
// import Transitions from '../../../shared/components/extended/Transitions';
// import LocalizedStaticTimePicker from '../../../shared/components/controlled/LocalizedStaticTimePicker';
import LoadingButton from '../../../shared/components/buttons/LoadingButton';
import {
  BusSchedule,
  BusScheduleDetail,
  BusScheduleDetailNew,
  BusScheduleNew,
} from '../interfaces/buses.interfaces';
import DragArea from '../../../shared/components/files/DragArea';
import { IconPlus } from '@tabler/icons';
import ScheduleTimeComponent from './builder/ScheduleTimeComponent';
import Transitions from '../../../shared/components/extended/Transitions';
import { removeItemFromIndexArr } from '../../../shared/helpers';
import {
  useGetScheduleDetailsQuery,
  useUpdateScheduleDetailsGroupedMutation,
} from '../slices/busRouteApiSlice';
import SkeletonGrids from '../../../shared/components/forms/skeletons/SkeletonGrids';
import { format, parse, parseISO } from 'date-fns';
import { formatDatesLangs } from '../../../../translation/hooks/useGetLocaleDateLang';
import DeleteScheduleDetailDialog from './builder/DeleteScheduleDetailDialog';
import { useControlModalStates } from '../../../shared/hooks/useControlModalStates';
import { useUploadFilesMutation } from '../../../shared/slices/uploadFilesApi';
import { CreateScheduleFormSchema } from '../constants/validationSchemas';

interface Props {
  editId?: number;
}

const BusScheduleForm = ({ editId }: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const history = useHistory();

  const [file, setFile] = useState<File | null>(null);

  const { busSchedulesList } = useAppSelector((state) => state.busRoute);

  const [showAlerts, setShowAlerts] = useState(true);

  const { open, multipleIds, handleCloseModal, handleOpenModalWithMultipleIds } =
    useControlModalStates<{
      id: number;
      idx: number;
    }>();

  const [uploadFile, { isLoading: isUploading }] = useUploadFilesMutation();
  const [saveScheduleDetails, { isLoading, error, isError, isSuccess }] =
    useUpdateScheduleDetailsGroupedMutation();

  const scheduleToEdit = useMemo(() => {
    if (editId) return busSchedulesList.find((fp) => fp.id === editId);
  }, [editId, busSchedulesList]);

  const [hoursList, setHoursList] = useState<(BusScheduleDetailNew | BusScheduleDetail)[]>([
    {
      tempId: `temp-${new Date().getTime()}-${Math.random()}`,
      hour_departure_go: null, // ida salida
      hour_arrival_go: null, // ida llegada
      hour_departure_return: null, // vuelta salida
      hour_arrival_return: null, // vuelta llegada
    } as BusScheduleDetailNew,
  ]);

  const { data: scheduleDetails, isLoading: isLoadingDetails } = useGetScheduleDetailsQuery(
    {
      scheduleId: editId || 0,
    },
    { skip: !editId }
  );

  useEffect(() => {
    if (scheduleDetails) {
      setHoursList([
        ...scheduleDetails.map((sd) => ({
          ...sd,
          hour_departure_go: !sd.hour_departure_go
            ? null
            : format(
                parse(sd.hour_departure_go, formatDatesLangs.normalizadTime, new Date()),
                formatDatesLangs.normalizadDateTime
              ),
          hour_arrival_go: !sd.hour_arrival_go
            ? null
            : format(
                parse(sd.hour_arrival_go, formatDatesLangs.normalizadTime, new Date()),
                formatDatesLangs.normalizadDateTime
              ),
          hour_departure_return: !sd.hour_departure_return
            ? null
            : format(
                parse(sd.hour_departure_return, formatDatesLangs.normalizadTime, new Date()),
                formatDatesLangs.normalizadDateTime
              ),
          hour_arrival_return: !sd.hour_arrival_return
            ? null
            : format(
                parse(sd.hour_arrival_return, formatDatesLangs.normalizadTime, new Date()),
                formatDatesLangs.normalizadDateTime
              ),
        })),
      ]);
    }
  }, [scheduleDetails]);

  const addNewHoursToList = (newHours?: BusScheduleDetailNew | BusScheduleDetail) => {
    setHoursList((prev) => [
      ...prev,
      {
        tempId: `temp-${new Date().getTime()}-${Math.random()}`,
        hour_departure_go: null,
        hour_arrival_go: null,
        hour_departure_return: null,
        hour_arrival_return: null,
      } as BusScheduleDetailNew,
    ]);
  };

  const updateHoursFromList = (
    index: number,
    newHours: BusScheduleDetailNew | BusScheduleDetail
  ) => {
    setHoursList((prev) => {
      const newArr = [...prev];
      newArr[index] = newHours;
      return newArr;
    });
  };

  const removeHoursFromList = (index: number) => {
    setHoursList((prev) => removeItemFromIndexArr(prev, index));
  };

  const { getFieldProps, errors, touched, handleSubmit, handleChange, setFieldValue, values } =
    useFormik<Partial<BusSchedule | BusScheduleNew>>({
      initialValues: {
        id: scheduleToEdit?.id || null,
        sche_name: scheduleToEdit?.sche_name || '',
        status_id: scheduleToEdit?.status_id ? scheduleToEdit?.status_id : '1',

        sche_monday: scheduleToEdit?.sche_monday || 0,
        sche_tuesday: scheduleToEdit?.sche_tuesday || 0,
        sche_wednesday: scheduleToEdit?.sche_wednesday || 0,
        sche_thursday: scheduleToEdit?.sche_thursday || 0,
        sche_friday: scheduleToEdit?.sche_friday || 0,
        sche_saturday: scheduleToEdit?.sche_saturday || 0,
        sche_sunday: scheduleToEdit?.sche_sunday || 0,
        sche_link: '',
      },
      validationSchema: CreateScheduleFormSchema,
      validate: () => {
        const minOneDay = t('academic.buses.schedules.form.min_required');
        const errors: any = {};

        let hasOneHourhad = false;

        DaysWeeks.forEach((day) => {
          if (values[`sche_${day.day.toLowerCase()}` as keyof typeof values]) {
            // minimo un horario activado
            hasOneHourhad = true;
          }
        });

        if (!hasOneHourhad) errors[`hours`] = minOneDay;

        return errors;
      },
      onSubmit: async ({ id, ..._values }) => {
        try {
          let urlDoc = _values.sche_link;

          if (file) {
            urlDoc = await uploadFile({
              files: file,
            }).unwrap();
          }

          const details = () => {
            const newSchedules: Omit<BusScheduleDetail, 'id'>[] = [];
            const updateSchedules: BusScheduleDetail[] = [];

            hoursList.forEach((h) => {
              const formatedHours = {
                hour_arrival_go: !h.hour_arrival_go
                  ? null
                  : format(parseISO(h.hour_arrival_go), formatDatesLangs.normalizadTime),
                hour_departure_go: !h.hour_departure_go
                  ? null
                  : format(parseISO(h.hour_departure_go), formatDatesLangs.normalizadTime),
                hour_arrival_return: !h.hour_arrival_return
                  ? null
                  : format(parseISO(h.hour_arrival_return), formatDatesLangs.normalizadTime),
                hour_departure_return: !h.hour_departure_return
                  ? null
                  : format(parseISO(h.hour_departure_return), formatDatesLangs.normalizadTime),
              };

              if ('tempId' in h) {
                const { isEdited, tempId, ...rest } = h;
                newSchedules.push({
                  ...rest,
                  ...formatedHours,
                });
              } else {
                updateSchedules.push({
                  ...h,
                  bus_schedule_id: h.bus_schedule_id,
                  ...formatedHours,
                });
              }
            }); //end forEach

            return {
              new: newSchedules,
              update: updateSchedules,
            };
          };

          //  console.log({
          //   ...(editId && { id }),
          //   sche_name: _values.sche_name,
          //   sche_monday: _values?.sche_monday ? 1 : 0,
          //   sche_tuesday: _values?.sche_tuesday ? 1 : 0,
          //   sche_wednesday: _values?.sche_wednesday ? 1 : 0,
          //   sche_thursday: _values?.sche_thursday ? 1 : 0,
          //   sche_friday: _values?.sche_friday ? 1 : 0,
          //   sche_saturday: _values?.sche_saturday ? 1 : 0,
          //   sche_sunday: _values?.sche_sunday ? 1 : 0,
          //   status_id: _values.status_id,
          //   sche_link: '',
          //   details: {
          //     ...details(),
          //   },
          // });

          // if (false)
          saveScheduleDetails({
            dataPayload: {
              ...(editId && { id }),
              sche_name: _values.sche_name || '',
              sche_monday: _values?.sche_monday ? 1 : 0,
              sche_tuesday: _values?.sche_tuesday ? 1 : 0,
              sche_wednesday: _values?.sche_wednesday ? 1 : 0,
              sche_thursday: _values?.sche_thursday ? 1 : 0,
              sche_friday: _values?.sche_friday ? 1 : 0,
              sche_saturday: _values?.sche_saturday ? 1 : 0,
              sche_sunday: _values?.sche_sunday ? 1 : 0,
              status_id: _values.status_id || '1',
              sche_link: urlDoc || '',
              details: {
                ...details(),
              },
            },
          });
        } catch (error) {
          console.log(error);
        }

        setShowAlerts(true);
      },
    });

  const setSuggestedDescription = (isVerify?: boolean) => {
    const selectedDays: {
      name: string;
      id: string;
      day: string;
    }[] = [];
    let suggest = '';

    for (const day of DaysWeeks) {
      if (values[`hour${day.day}` as keyof typeof values]) {
        if (isVerify) {
          return true;
        } else {
          selectedDays.push(day);
        }
      }
    }

    if (selectedDays.length) {
      suggest = selectedDays.reduce<string>((acc, day, i) => {
        const start = format(
          values[`hour_start_time_${day.day.toLowerCase()}` as keyof typeof values] as any,
          'HH:mm'
        );

        const end = format(
          values[`hour_end_time_${day.day.toLowerCase()}` as keyof typeof values] as any,
          'HH:mm'
        );

        if (i === 0) {
          return `${day.name} (${start} - ${end})`;
        }

        return `${acc}, ${day.name} (${start} - ${end})`;
      }, '');
    }

    return suggest;
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid container gap={2}>
          {isError && showAlerts && (
            <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
          )}

          {isSuccess && showAlerts && (
            <SuccessAlert
              message={
                !editId
                  ? t('academic.buses.schedules.form.success_create')
                  : t('academic.buses.schedules.form.success_update')
              }
              handleDismiss={() => setShowAlerts(false)}
            />
          )}
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {setSuggestedDescription(true) && false && (
                      <Alert severity="info">
                        <Grid item container justifyContent="space-between" alignItems="center">
                          <Grid item>{`${t(
                            'academic.buses.schedules.form.suggest_description'
                          )}${setSuggestedDescription()}`}</Grid>
                          <Grid item>
                            <Button
                              onClick={() =>
                                setFieldValue('hourDescription', setSuggestedDescription())
                              }
                            >
                              Aceptar
                            </Button>
                          </Grid>
                        </Grid>
                      </Alert>
                    )}

                    <InputLabel>{t('academic.buses.schedules.form.name.label')}</InputLabel>
                    <TextField
                      // type="number"
                      // disabled={paymethod.keyword === 'forma-pago-saldo-favor'}
                      fullWidth
                      // InputProps={{
                      //   defaultValue: '',
                      //   inputComponent: CurrencyInputMasked as any,
                      //   startAdornment: defaultConfiguration?.currency_symbol,
                      // }}
                      // placeholder={`${defaultConfiguration?.currency_symbol}0.00`}
                      {...getFieldProps('sche_name')}
                      error={Boolean(errors.sche_name && touched.sche_name)}
                    />
                    {errors.sche_name && touched.sche_name && (
                      <FormHelperText error>{t(errors.sche_name)}</FormHelperText>
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    <Box
                      p={2}
                      sx={{
                        border: `1px solid ${theme.palette.primary.main}`,
                        borderRadius: '10px',
                        backgroundColor: theme.palette.primary.light,
                      }}
                    >
                      <InputLabel>{t('academic.buses.schedules.form.day.label')}</InputLabel>
                      {DaysWeeks.map((day) => (
                        <FormControlLabel
                          key={`sche-key-${day.day}`}
                          control={
                            <Checkbox
                              key={day.day}
                              onChange={handleChange}
                              checked={Boolean(
                                values[`sche_${day.day.toLowerCase()}` as keyof typeof values]
                              )}
                              id={`sche_${day.day.toLowerCase()}`}
                            />
                          }
                          label={day.name}
                          labelPlacement="end"
                        />
                      ))}
                    </Box>
                  </Grid>
                  <Grid item xs={12} ml={2}>
                    {(errors as any).hours && (
                      <FormHelperText error>{t((errors as any).hours)}</FormHelperText>
                    )}
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} md={6}>
                <InputLabel>{t('academic.buses.schedules.form.file.label')}</InputLabel>
                <DragArea
                  handleChange={(e) => {
                    setFieldValue('sche_link', 'd');
                    setFile(e.target.files![0]);
                  }}
                  label={t('academic.buses.schedules.form.file.drag_label')}
                  // description="La foto del director debe ser una imagen de no más de 2MB"
                  typesAccepted=".pdf"
                />

                {errors.sche_link && touched.sche_link && (
                  <FormHelperText error>{t(errors.sche_link)}</FormHelperText>
                )}
              </Grid>

              <Grid item xs={12}>
                <Stack spacing={2} justifyContent="center" width="100%">
                  {isLoadingDetails ? (
                    <Grid item xs={12}>
                      <SkeletonGrids columns={2} customHeight={200} />
                    </Grid>
                  ) : (
                    <>
                      <Grid item xs={12}>
                        {hoursList.map((schDetails, idx) => {
                          const isNew = !!(schDetails as any).tempId;

                          const newSch = schDetails as BusScheduleDetailNew;
                          const updateSch = schDetails as BusScheduleDetail;

                          const id = isNew ? newSch.tempId : updateSch.id;

                          return (
                            <Transitions
                              type="collapse"
                              in={Boolean(hoursList[idx])}
                              position="top-left"
                              direction={'left'}
                            >
                              <ScheduleTimeComponent
                                key={`schedule-time-comp-${idx}`}
                                id={id}
                                idx={idx}
                                data={isNew ? newSch : updateSch}
                                removeItemHandler={removeHoursFromList}
                                changeHoursHandler={updateHoursFromList}
                                deleteHandler={handleOpenModalWithMultipleIds}
                              />
                            </Transitions>
                          );
                        })}
                      </Grid>

                      <Grid item xs={12}>
                        <Divider>
                          <IconButton onClick={() => addNewHoursToList()}>
                            <IconPlus />
                          </IconButton>
                        </Divider>
                      </Grid>
                    </>
                  )}

                  {/* <Grid item xs={12}>
                <Grid container justifyItems="center">
                  <Grid item xs={6} p={1}>
                    <Box sx={{ width: '100%', p: 1, border: '1px dashed black' }}>b</Box>
                  </Grid>
                  <Grid item xs={6} p={1}>
                    <Box sx={{ width: '100%', p: 1, border: '1px dashed black' }}>b</Box>
                  </Grid>
                </Grid>
              </Grid> */}
                </Stack>
              </Grid>

              {/* <Grid item xs={12}>
            <Divider />
          </Grid> */}

              {!!editId && (
                <Grid item xs={12}>
                  <InputLabel>{t('academic.buses.schedules.form.state.label')}</InputLabel>
                  <Autocomplete
                    options={statusAvailables}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={Boolean(errors.status_id && touched.status_id)}
                      />
                    )}
                    defaultValue={statusAvailables[0]}
                    onChange={(e, value) => setFieldValue('status_id', value?.id || '')}
                    fullWidth
                    clearIcon={null}
                  />
                  {errors.status_id && touched.status_id && (
                    <FormHelperText error>{t(errors.status_id)}</FormHelperText>
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          container
          alignItems="center"
          justifyContent="flex-end"
          spacing={2}
          sx={{ mt: 1 }}
        >
          <Grid item>
            <Button
              variant="outlined"
              size="large"
              color="primary"
              onClick={() => history.goBack()}
            >
              {t('shared.go_back_button.label')}
            </Button>
          </Grid>
          <Grid item>
            <LoadingButton isLoading={isLoading || isUploading} type="submit"></LoadingButton>
          </Grid>
        </Grid>
      </form>

      <DeleteScheduleDetailDialog
        openModal={open}
        scheduleDetailId={multipleIds?.id || 0}
        scheduleDetailIdx={multipleIds?.idx || 0}
        removeHoursFromList={removeHoursFromList}
        handleCloseModal={handleCloseModal}
      />
    </>
  );
};

export default BusScheduleForm;
