import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getClientNameFromUrl } from '../../../helpers/urlHelpers';

import { AuthState, AuthUser, CollaboratorInfo, StudentInfo } from '../../../types';
import { StudentRecord } from '../interfaces/student-record.interfaces';
// import { StudentRecord } from '../interfaces/login.interfaces';

const initialState: AuthState = {
  apiUrl: undefined,
  isAuth: false,
  loading: true,
  loadingUrl: true,
  userInfo: {} as AuthUser,
  tenant: getClientNameFromUrl(window.location.hostname),
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginSync: (state, action: PayloadAction<AuthUser>) => {
      state.isAuth = true;
      state.userInfo = action.payload;
    },
    setFirsPasswordChange: (state) => {
      if (state.userInfo) state.userInfo.remember_token = 'changed';
    },
    logoutSync: () => initialState,
    setStudentInfo: (state, action: PayloadAction<StudentInfo>) => {
      state.userInfo.studentInfo = action.payload;
    },
    setStudentRecordReference: (state, action: PayloadAction<StudentRecord[]>) => {
      state.studentRecordReference = action.payload;
    },
    resetStudentRecordReference: (state) => {
      state.studentRecordReference = undefined;
    },
    setCollaboratorInfo: (state, action: PayloadAction<CollaboratorInfo>) => {
      state.userInfo.collaboratorInfo = action.payload;
    },
    setGoogleLoginError: (state, action: PayloadAction<string | undefined>) => {
      state.googleLoginError = action.payload;
    },
    setTenant: (state, action: PayloadAction<string>) => {
      state.tenant = action.payload;
    },
  },
});

export const {
  loginSync,
  setFirsPasswordChange,
  logoutSync,
  setStudentInfo,
  setStudentRecordReference,
  resetStudentRecordReference,
  setCollaboratorInfo,
  setGoogleLoginError,
  setTenant,
} = authSlice.actions;

export default authSlice.reducer;
