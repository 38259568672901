import { makeStyles } from "@material-ui/core";

export const dialogStyles = makeStyles((theme) => ({
    container: {
      '&>div:nth-child(3)': {
        justifyContent: 'flex-end',
        '&>div': {
          margin: '0px',
          borderRadius: '0px',
          // maxWidth: '450px',
          maxWidth: '80%',
          maxHeight: '100%',
        },
      },
    },
    paper: {
      minHeight: '100vh',
      width: '45%',
    },
  }));