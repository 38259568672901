import { Switch } from 'react-router-dom';
import GuardRoute from '../../../../router/guards/GuardRoute';

import ElectronicDocumentScreen from '../screens/ElectronicDocumentScreen';

const ElectronicDocumentRouter = () => {
  return (
    <Switch>
      <GuardRoute
        exact
        path="/administrar-documentos-electronicos"
        component={ElectronicDocumentScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.read}
      />
    </Switch>
  );
};
export default ElectronicDocumentRouter;
