import { baseUrl } from '../../../api/mainApi';
import { PrimitiveType } from '../interfaces/material-ui.interfaces';
import { Year } from '../interfaces/years..interfaces';
import { DefaultConfiguration } from '../interfaces/tenants.interfaces';
import { isValid } from 'date-fns';

export const objectValues = <T extends {}>(obj: T) => {
  return Object.keys(obj).map((objKey) => obj[objKey as keyof T]);
};

export const objectKeys = <T extends {}>(obj: T) => {
  return Object.keys(obj).map((objKey) => objKey as keyof T);
};

export const isEmptyObject = <T extends {}>(obj: T) => {
  return obj && Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype;
};

export const isPrimitive = (value: any): value is PrimitiveType => {
  return (
    typeof value === 'string' ||
    typeof value === 'number' ||
    typeof value === 'boolean' ||
    typeof value === 'symbol'
  );
};

export const obtainDomainStorageImage = (url_image: string) => {
  return `${baseUrl}/show?name=${url_image}`;
};

//Set first letter to Uppercase
export const firstLetterUppercase = (value?: string) => {
  if (!value) return;
  return value.charAt(0).toUpperCase() + value.slice(1);
};

//return array without repeated items
export const arrayUnique = (array: any[]) => {
  var a = array.concat();
  for (var i = 0; i < a.length; ++i) {
    for (var j = i + 1; j < a.length; ++j) {
      if (a[i] === a[j]) a.splice(j--, 1);
    }
  }

  return a;
};

export const controlSelectionArr = (selected: number[], itemId: number): number[] => {
  const selectedIndex = selected.indexOf(itemId);
  let newSelected: number[] = [];

  if (selectedIndex === -1) {
    newSelected = newSelected.concat(selected, itemId);
  } else if (selectedIndex === 0) {
    newSelected = newSelected.concat(selected.slice(1));
  } else if (selectedIndex === selected.length - 1) {
    newSelected = newSelected.concat(selected.slice(0, -1));
  } else if (selectedIndex > 0) {
    newSelected = newSelected.concat(
      selected.slice(0, selectedIndex),
      selected.slice(selectedIndex + 1)
    );
  }

  return newSelected;
};

//return new array without param index item
export const removeItemFromIndexArr = <TypeArr,>(arr: TypeArr[], indexToDel: number): TypeArr[] => {
  let newArr: TypeArr[] = [];
  try {
    if (indexToDel === 0) {
      newArr = newArr.concat(arr.slice(1));
    } else if (indexToDel === arr.length - 1) {
      newArr = newArr.concat(arr.slice(0, -1));
    } else if (indexToDel > 0) {
      newArr = newArr.concat(arr.slice(0, indexToDel), arr.slice(indexToDel + 1));
    }

    console.log(arr);
  } catch (error) {
    console.log(error);
  }

  return newArr;
};

//return updated array item from index
export const updateItemFromIndexArr = <TypeArr,>(
  arr: TypeArr[],
  indexToUpdate: number,
  newDataToInsert: TypeArr
): TypeArr[] => {
  const updatedItem = { ...arr[indexToUpdate], ...newDataToInsert };

  const updatedArr = [...arr.slice(0, indexToUpdate), updatedItem, ...arr.slice(indexToUpdate + 1)];

  return updatedArr;
};

//return updated array item from index
export const updateItemFromKeyArr = <TypeArr extends { [key: string]: any }>(
  arr: TypeArr[],
  key: string,
  keyValue: number | string,
  newDataToInsert: TypeArr
): TypeArr[] => {
  // eslint-disable-next-line eqeqeq
  const indexToUpdate = arr.findIndex((item) => item[key] == keyValue);

  const updatedItem = { ...arr[indexToUpdate], ...newDataToInsert };

  const updatedArr = [...arr.slice(0, indexToUpdate), updatedItem, ...arr.slice(indexToUpdate + 1)];

  return updatedArr;
};

//return number with 2 decimals
export const showWithDecimals = (value: number, decimalPlaces?: number) => {
  // value.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 });

  const defaultConfiguration = JSON.parse(
    localStorage.getItem('defaultConfiguration') || '{}'
  ) as DefaultConfiguration;

  let decimals = decimalPlaces || defaultConfiguration.number_decimal;

  return +Number(Math.round(parseFloat(value + 'e' + decimals)) + 'e-' + decimals).toFixed(
    decimals
  );
};

//Search deep prop on object
export const traverseObject = (obj: {}, path: string) => {
  const newPath = path.split('.');
  let current = { ...obj };
  while (newPath.length) {
    if (typeof current !== 'object') return undefined;
    current = current[newPath.shift()! as keyof typeof current];
  }
  console.log(current);
  return current;
};

export const arrayOfYears = (): Year[] => {
  const maxDate = new Date().getFullYear();
  const minDate = maxDate - 9;
  const dataYears: Year[] = [];
  for (let i = maxDate; i >= minDate; i--) {
    dataYears.push({ id: i, value: i });
  }
  return dataYears;
};

export const navigateTopSmooth = () => {
  window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
};

export const epsilonRound = (num: number) => {
  return showWithDecimals(num);
};

export const customSort = <T,>(list: T[], key: keyof T, typeSort: 'asc' | 'desc' = 'asc') => {
  const listToSort = [...list];

  try {
    const listSorted = listToSort.sort((a, b) => {
      const firstKey = typeSort === 'asc' ? 1 : -1;
      const secondKey = typeSort === 'asc' ? -1 : 1;

      if (
        (typeof a[key] === 'string' && typeof b[key] === 'string') ||
        (typeof a[key] === 'number' && typeof b[key] === 'number')
      ) {
        if (a[key] > b[key]) {
          return firstKey;
        }
        if (a[key] < b[key]) {
          return secondKey;
        }
        return 0;
      }

      if (isValid(a[key]) && isValid(b[key])) {
        var c = new Date((a as any).start);
        var d = new Date((b as any).start);
        if (c > d) {
          return firstKey;
        }
        if (c < d) {
          return secondKey;
        }
        return 0;
      }

      return 0;
    });

    return listSorted;
  } catch (error) {
    return listToSort;
  }
};

// export const splitArrByCon = <TypeArr,>(
//   arr: TypeArr[],
//   keyToSplit: string
// ): { [key: string]: TypeArr[] } => {
//   let obj = input.reduce((res, curr) => {
//     if (res[curr.index]) res[curr.index].push(curr);
//     else Object.assign(res, { [curr.index]: [curr] });

//     return res;
//   }, {});

//   return obj;
// };

export const showNumberStringWithDecimals = (value: number, decimalPlaces?: number) => {
  const defaultConfiguration = JSON.parse(
    localStorage.getItem('defaultConfiguration') || '{}'
  ) as DefaultConfiguration;

  let decimals = decimalPlaces || defaultConfiguration.number_decimal;

  const number = showWithDecimals(value);

  if (Number(number) % 1 === 0) return number + `.${'0'.repeat(decimals)}`;

  return number?.toString();
};

/**
 *
 * @param obj   Object to map   {a: 1, b: 2}
 * @param func  Function to map (key, value) => v + 1
 * @returns     Object mapped   {a: 2, b: 3}
 */
export const objMap = <T extends {}, Z>(
  obj: T,
  func: (k: keyof typeof obj, v: (typeof obj)[keyof typeof obj]) => Z
): Record<keyof T, Z> => {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [key, func(key as any, value as any)])
  ) as Record<keyof T, Z>;
};

/**
 * Devuelve un codigo unico (crypto o random)
 * @returns string with 64 characters
 */
export const genUniqueCode = async (): Promise<string> => {
  try {
    const buffer = new Uint32Array(8);
    const crypto = window.crypto || (window as any).msCrypto;
    crypto.getRandomValues(buffer);

    const hashBuffer = await crypto.subtle.digest('SHA-256', buffer);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');

    return hashHex;
  } catch (error) {
    const randomCode = Math.random().toString(36).substring(2, 8);
    const now = new Date().getTime();

    return `${randomCode}${now}`;
  }
};
