import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, Tab, Tabs } from '@material-ui/core';
import PersonalData from './PersonalData';
import ContactData from './ContactData';
import JobData from './JobData';
import AcademicData from './AcademicData';

// style constant
const useStyles = makeStyles((theme) => ({
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    minWidth: 160,
  },
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 'auto',
  },
}));

// tabs
function TabPanel(props: { children: React.ReactElement | string; value: number; index: number }) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={0}>{children}</Box>}
    </div>
  );
}

const a11yProps = (index: number) => {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
};

const TeachersFile = () => {
  const classes = useStyles();

  const [value, setValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent | null, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} /*  sm={4} md={3} */>
          <Tabs
            value={value}
            indicatorColor="primary"
            onChange={handleChange}
            // orientation="vertical"
            aria-label="Formularios de datos del docente"
            variant="scrollable"
          >
            <Tab
              component={RouterLink}
              to="#"
              label="Información personal"
              {...a11yProps(0)}
              replace
            />
            <Tab component={RouterLink} to="#" label="Contacto" {...a11yProps(1)} replace />
            <Tab component={RouterLink} to="#" label="Estudios" {...a11yProps(2)} replace />
            <Tab component={RouterLink} to="#" label="Laboral" {...a11yProps(3)} replace />
            {/* <Tab component={RouterLink} to="#" label="Menciones" {.d..a11yProps(4)} replace />

            <Tab component={RouterLink} to="#" label="D. Familiares" {...a11yProps(5)} replace />

            <Tab component={RouterLink} to="#" label="Datos Ingreso" {...a11yProps(6)} replace /> */}
          </Tabs>
        </Grid>
        <Grid item xs={12} /* sm={8} md={9} */>
          <TabPanel value={value} index={0}>
            <PersonalData />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ContactData />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <AcademicData />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <JobData />
          </TabPanel>
          {/* <TabPanel value={value} index={3}>
            <PersonalData />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <PersonalData />
          </TabPanel>
          <TabPanel value={value} index={5}>
            <PersonalData />
          </TabPanel>
          <TabPanel value={value} index={6}>
            <PersonalData />
          </TabPanel> */}
          {/* <TabPanel value={value} index={10}>
          <PosgradoFormIndex onHandleChange={handleChangeBind} />
        </TabPanel> */}
        </Grid>
      </Grid>
    </div>
  );
};

export default TeachersFile;
