import { makeStyles } from '@material-ui/core/styles';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import { resetCurrentInfoSubject } from '../../slices/subjectsRegisterSlice';
import { useTheme } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Stack from '@material-ui/core/Stack';

import { IconBuilding, IconSchool, IconCalendarStats } from '@tabler/icons';
import HourshandCourse from '../HourshandCourse';
import { Hourhand } from '../../interfaces/coursesAvailables.interfaces';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  boxContainer: {
    boxShadow: '0px 4px 20px 15px rgba(234, 240, 246, 0.5);',
    borderRadius: 10,
    padding: '20px',
  },
  paper: {
    width: '90%',
    // height: '32%',
    maxWidth: 480,
    minHeight: '32%',
    maxHeight: '50%',
  },
});

interface Props {
  open: boolean;
  handleCloseDialog: () => void;
}

const InfoSubjectDialog = ({ open, handleCloseDialog }: Props) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const classes = useStyles();
  const { currentInfoSubject } = useAppSelector((state) => state.subjectsRegister);
  const theme = useTheme();

  const onClose = () => {
    handleCloseDialog();
    dispatch(resetCurrentInfoSubject());
  };

  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.paper }}>
      <DialogTitle>
        <Grid container direction="row">
          <Typography variant="h2" sx={{ color: 'primary.main', mb: 1 }}>
            {currentInfoSubject?.subject_curriculum.matter_rename}
          </Typography>
          <Typography color="primary" variant="h2"></Typography>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid item container xs={12} spacing={2}>
          <Grid item xs={6}>
            <Stack direction="row" spacing={1.5} alignItems="center">
              <IconSchool color={theme.palette.grey[700]} size={20} />
              <Stack spacing={0.5}>
                <Typography sx={{ fontWeight: 600 }}>
                  {t('students.subjects.available.teacher')}
                </Typography>
                <Typography>{`${
                  currentInfoSubject?.course.collaborator?.user.person.pers_first_lastname || ''
                } ${
                  currentInfoSubject?.course.collaborator?.user.person.pers_second_lastname || ''
                } ${
                  currentInfoSubject?.course.collaborator?.user.person.pers_firstname || ''
                }`}</Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack direction="row" spacing={1.5} alignItems="center">
              <IconBuilding color={theme.palette.grey[700]} size={20} />
              <Stack spacing={0.5}>
                <Typography sx={{ fontWeight: 600 }}>
                  {t('students.subjects.available.building')}
                </Typography>
                <Typography>{currentInfoSubject?.course.classroom.campus.cam_name}</Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" spacing={1.5} alignItems="center">
              <IconCalendarStats color={theme.palette.grey[700]} size={20} />
              <Stack>
                <Typography sx={{ fontWeight: 600 }}>
                  {t('students.subjects.available.schedule')}
                </Typography>
              </Stack>
            </Stack>
            <Grid item mt={2}>
              {currentInfoSubject?.course.hourhand ? (
                <HourshandCourse hourhand={currentInfoSubject?.course.hourhand as Hourhand} />
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default InfoSubjectDialog;
