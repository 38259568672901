import { FC, useEffect, useMemo } from 'react';

import { Autocomplete, Button, FormHelperText, Grid, TextField } from '@material-ui/core';
import { useFormik } from 'formik';

import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../../../../hooks/useRedux';
import InputLabel from '../../../../../shared/components/forms/InputLabel';

import { FormProps } from '../../../interfaces/components.interface';
import { setPayCollection } from '../../../slices/debtsPaymentSlice';
import { epsilonRound, updateItemFromIndexArr } from '../../../../../shared/helpers';
import TextFieldFetching from '../../../../../shared/components/TextFieldFetching';
import { useGetCatalogByAcronymQuery } from '../../../../../shared/slices/catalogApi';
import CurrencyInputMasked from '../../shared/CurrencyInputMasked';
import { CreditDebitMethodSchema } from '../../../constants/validationForms-schemas';
import {
  useGetFinancialInstitutionsQuery,
  useGetFinancialInstitutionCardsQuery,
} from '../../../../../students/payments-history/slices/paymentsApiSlice';

const CreditDebitCardMethod: FC<FormProps> = (props) => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const defaultConfiguration = useAppSelector((state) => state.access.defaultConfiguration);

  const { paymethod, indexPayToEdit, setIsPay, onCancel } = props;

  const { currentProfile } = useAppSelector((state) => state.access);
  const { payCollection, totalDebtAmount, totalPayAmount } = useAppSelector(
    (state) => state.debtsPayment
  );

  const { data, isFetching } = useGetCatalogByAcronymQuery({
    profileId: currentProfile?.profileId!,
    acronym: 'RP',
  });

  const { data: financialInstitutions, isFetching: isFetchingFI } =
    useGetFinancialInstitutionsQuery({
      profileId: currentProfile?.profileId!,
    });

  const initialValues = useMemo(() => {
    return {
      FinancialInstitucionOriginId: indexPayToEdit?.toString()
        ? payCollection[indexPayToEdit]?.tempOriginBanckCard
        : null,
      FinancialInstitucionCardId: indexPayToEdit?.toString()
        ? payCollection[indexPayToEdit]?.financial_institution_origin_id
        : null,
      observation: indexPayToEdit?.toString() ? payCollection[indexPayToEdit]?.observation : '',
      payAmount: indexPayToEdit?.toString()
        ? payCollection[indexPayToEdit]?.payment_amount
        : epsilonRound(
            totalDebtAmount - totalPayAmount > 0 ? totalDebtAmount - totalPayAmount : 0
          ) || null,

      cardCode: indexPayToEdit?.toString() ? payCollection[indexPayToEdit]?.card_code : '',
      cardNumber: indexPayToEdit?.toString()
        ? payCollection[indexPayToEdit]?.card_number
        : undefined,
      reference: indexPayToEdit?.toString() ? payCollection[indexPayToEdit]?.reference : '',
      redPago: indexPayToEdit?.toString() ? payCollection[indexPayToEdit]?.red_pago : undefined,

      issuerName: indexPayToEdit?.toString()
        ? payCollection[indexPayToEdit]?.issuer_name
        : undefined,
      paymethod: paymethod.keyword,
      lote: indexPayToEdit?.toString() ? payCollection[indexPayToEdit]?.lote : undefined,
      // ...(paymethod.keyword === 'forma-pago-tarjeta-cred' && {
      //   lote: indexPayToEdit?.toString() ? payCollection[indexPayToEdit]?.lote : undefined,
      // }),
    };
  }, [indexPayToEdit, payCollection, totalDebtAmount, totalPayAmount, paymethod]);

  //usar un solo formik e importar los initial state
  const { getFieldProps, errors, touched, handleSubmit, setValues, setFieldValue, values } =
    useFormik({
      initialValues: initialValues,
      validationSchema: CreditDebitMethodSchema,
      onSubmit: async (values) => {
        try {
          if (!indexPayToEdit?.toString()) {
            const newPayCollection = [
              {
                payment_amount: values.payAmount!,
                observation: values.observation,
                payment_way_id: paymethod.id,
                payment_way: paymethod.id,
                payment_way_name: paymethod.name,
                payment_way_keyword: paymethod.keyword,

                tempOriginBanckCard: values.FinancialInstitucionOriginId!,
                financial_institution_origin_id: values.FinancialInstitucionCardId!,

                card_code: values.cardCode,
                card_number: values.cardNumber,
                reference: values.reference,
                red_pago: values.redPago,

                issuer_name: values.issuerName,

                // ...(paymethod.keyword === 'forma-pago-tarjeta-cred' && { lote: values.lote }),

                lote: values.lote,

                issues_invoice: 1,
              },
              ...payCollection,
            ];

            dispatch(setPayCollection(newPayCollection));
          } else {
            const updatedItem = {
              payment_amount: values.payAmount!,
              observation: values.observation,
              payment_way_id: paymethod.id,
              payment_way: paymethod.id,
              payment_way_name: paymethod.name,
              payment_way_keyword: paymethod.keyword,

              tempOriginBanckCard: values.FinancialInstitucionOriginId!,
              financial_institution_origin_id: values.FinancialInstitucionCardId!,

              lote: values.lote,
              // ...(paymethod.keyword === 'forma-pago-tarjeta-cred' && { lote: values.lote }),
              issuer_name: values.issuerName,
              issues_invoice: 1,
            };

            const newPayCollection = updateItemFromIndexArr(
              payCollection,
              indexPayToEdit,
              updatedItem
            );

            dispatch(setPayCollection(newPayCollection));
          }

          setIsPay();
        } catch (error) {
          console.log(error);
        }

        //   setShowAlerts(true);
        //   window.scrollTo(0, 0);
      },
    });

  const { data: financialInstitutionCards, isFetching: isFetchingFIC } =
    useGetFinancialInstitutionCardsQuery(
      {
        finantialInstitutionId: values.FinancialInstitucionOriginId!,
      },
      {
        skip: values.FinancialInstitucionOriginId === 0 || !values.FinancialInstitucionOriginId,
      }
    );

  useEffect(() => {
    if (indexPayToEdit?.toString()) {
      setValues({ ...initialValues });
    }
  }, [indexPayToEdit, initialValues, setValues]);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={1} alignItems="flex-start">
        <Grid item xs={12}>
          <InputLabel>
            {t('financial.debts_payment.pay_dialog.form.emission_bank_label')}
          </InputLabel>
          {isFetchingFI ? (
            <TextFieldFetching />
          ) : (
            <Autocomplete
              options={financialInstitutions || []}
              getOptionLabel={(option) => option.fin_int_name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(
                    errors.FinancialInstitucionOriginId && touched.FinancialInstitucionOriginId
                  )}
                  placeholder={t(
                    'financial.debts_payment.pay_dialog.form.emission_bank_placeholder'
                  )}
                />
              )}
              value={
                financialInstitutions?.find(
                  (fn) => fn.id === values.FinancialInstitucionOriginId
                ) || null
              }
              onChange={(_, values) =>
                setFieldValue('FinancialInstitucionOriginId', values?.id || null)
              }
              fullWidth
            />
          )}
          {errors.FinancialInstitucionOriginId && touched.FinancialInstitucionOriginId && (
            <FormHelperText error>{t(errors.FinancialInstitucionOriginId)}</FormHelperText>
          )}
        </Grid>

        {values.FinancialInstitucionOriginId !== 0 && (
          <Grid item xs={12}>
            <InputLabel>
              {t('financial.debts_payment.pay_dialog.form.cards_method.card_label')}
            </InputLabel>
            {isFetchingFIC ? (
              <TextFieldFetching />
            ) : (
              <Autocomplete
                options={financialInstitutionCards || []}
                getOptionLabel={(option) => option.fin_int_name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(
                      errors.FinancialInstitucionCardId && touched.FinancialInstitucionCardId
                    )}
                    placeholder={t(
                      'financial.debts_payment.pay_dialog.form.cards_method.card_placeholder'
                    )}
                  />
                )}
                value={
                  financialInstitutionCards?.find(
                    (fn: any) => fn.id === values.FinancialInstitucionCardId
                  ) || null
                }
                onChange={(_, values) =>
                  setFieldValue('FinancialInstitucionCardId', values?.id || null)
                }
                fullWidth
              />
            )}
            {errors.FinancialInstitucionCardId && touched.FinancialInstitucionCardId && (
              <FormHelperText error>{t(errors.FinancialInstitucionCardId)}</FormHelperText>
            )}
          </Grid>
        )}

        <Grid item xs={12}>
          <InputLabel>
            {t('financial.debts_payment.pay_dialog.form.cards_method.payment_network_label')}
          </InputLabel>
          {isFetching ? (
            <TextFieldFetching />
          ) : (
            <Autocomplete
              options={data || []}
              getOptionLabel={(option) => option.cat_name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(errors.redPago && touched.redPago)}
                  placeholder={t(
                    'financial.debts_payment.pay_dialog.form.cards_method.payment_network_placeholder'
                  )}
                />
              )}
              value={data?.find((fn) => fn.id === values.redPago) || null}
              onChange={(_, values) => setFieldValue('redPago', values?.id || null)}
              fullWidth
            />
          )}
          {errors.redPago && touched.redPago && (
            <FormHelperText error>{t(errors.redPago)}</FormHelperText>
          )}
        </Grid>

        <Grid item xs={12}>
          <InputLabel>{t('financial.debts_payment.pay_dialog.form.amount_label')}</InputLabel>
          <TextField
            // type="number"
            fullWidth
            InputProps={{
              defaultValue: '',
              inputComponent: CurrencyInputMasked as any,
              startAdornment: defaultConfiguration?.currency_symbol,
            }}
            placeholder={`${defaultConfiguration?.currency_symbol}0.00`}
            {...getFieldProps('payAmount')}
            error={Boolean(errors.payAmount && touched.payAmount)}
          />
          {errors.payAmount && touched.payAmount && (
            <FormHelperText error>{t(errors.payAmount)}</FormHelperText>
          )}
        </Grid>

        {/* <Grid item xs={12}>
          <InputLabel>Código de Tarjeta</InputLabel>
          <TextField
            fullWidth
            error={Boolean(errors.cardCode && touched.cardCode)}
            placeholder="Código de Tarjeta"
            {...getFieldProps('cardCode')}
          />
          {errors.cardCode && touched.cardCode && (
            <FormHelperText error>{errors.cardCode}</FormHelperText>
          )}
        </Grid> */}

        <Grid item xs={12}>
          <InputLabel>
            {t('financial.debts_payment.pay_dialog.form.cheque_method.issuer_name_label')}
          </InputLabel>
          <TextField
            fullWidth
            error={Boolean(errors.issuerName && touched.issuerName)}
            placeholder={t(
              'financial.debts_payment.pay_dialog.form.cheque_method.issuer_name_placeholder'
            )}
            {...getFieldProps('issuerName')}
          />
          {errors.issuerName && touched.issuerName && (
            <FormHelperText error>{t(errors.issuerName)}</FormHelperText>
          )}
        </Grid>

        <Grid item xs={12}>
          <InputLabel>
            {t('financial.debts_payment.pay_dialog.form.cards_method.card_number_label')}
          </InputLabel>
          <TextField
            fullWidth
            error={Boolean(errors.cardNumber && touched.cardNumber)}
            placeholder={t(
              'financial.debts_payment.pay_dialog.form.cards_method.card_number_placeholder'
            )}
            {...getFieldProps('cardNumber')}
          />
          {errors.cardNumber && touched.cardNumber && (
            <FormHelperText error>{t(errors.cardNumber)}</FormHelperText>
          )}
        </Grid>

        {/* {paymethod.keyword === 'forma-pago-tarjeta-cred' && ( */}
        <Grid item xs={12}>
          <InputLabel>
            {t('financial.debts_payment.pay_dialog.form.cards_method.lote_label')}
          </InputLabel>
          <TextField
            fullWidth
            error={Boolean(errors.lote && touched.lote)}
            placeholder={t('financial.debts_payment.pay_dialog.form.cards_method.lote_placeholder')}
            {...getFieldProps('lote')}
          />
          {errors.lote && touched.lote && <FormHelperText error>{t(errors.lote)}</FormHelperText>}
        </Grid>
        {/* )} */}

        <Grid item xs={12}>
          <InputLabel>{t('financial.debts_payment.pay_dialog.form.reference_label')}</InputLabel>
          <TextField
            fullWidth
            error={Boolean(errors.reference && touched.reference)}
            placeholder={t('financial.debts_payment.pay_dialog.form.reference_placeholder')}
            {...getFieldProps('reference')}
          />
          {errors.reference && touched.reference && (
            <FormHelperText error>{t(errors.reference)}</FormHelperText>
          )}
        </Grid>

        <Grid item xs={12}>
          <InputLabel>{t('financial.debts_payment.pay_dialog.form.observation_label')}</InputLabel>
          <TextField
            fullWidth
            multiline
            rows={2}
            error={Boolean(errors.observation && touched.observation)}
            placeholder={t('financial.debts_payment.pay_dialog.form.observation_placeholder')}
            {...getFieldProps('observation')}
          />
          {errors.observation && touched.observation && (
            <FormHelperText error>{t(errors.observation)}</FormHelperText>
          )}
        </Grid>

        {/* <Grid item xs={12} marginTop={2}>
              <Typography variant="h5" component="div" sx={{ mb: 3 }}>
                3. Establezca el inicio y fin del periodo
              </Typography>

              <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                <DateRangePicker
                  startText={null}
                  endText={null}
                  value={periodDate}
                  onChange={onChangesPeriodProcess}
                  renderInput={(startProps, endProps) => (
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12} sm={3} lg={4} sx={{ pt: { xs: 2, sm: '0 !important' } }}>
                        <InputLabel
                          className={classes.inputLabel}
                          sx={{ textAlign: { xs: 'left', sm: 'right' } }}
                        >
                          Fecha de Inicio:
                        </InputLabel>
                      </Grid>
                      <Grid item xs={12} sm={9} lg={8}>
                        <TextField
                          fullWidth
                          {...startProps}
                          helperText=""
                          error={Boolean(errors.start_process && touched.start_process)}
                          {...getFieldProps('start_process')}
                        />
                        <FormHelperText>A partir de esta fecha comenzará el periodo</FormHelperText>

                        {errors.start_process && touched.start_process && (
                          <FormHelperText error>{errors.start_process}</FormHelperText>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={3} lg={4} sx={{ pt: { xs: 2, sm: '0 !important' } }}>
                        <InputLabel
                          className={classes.inputLabel}
                          sx={{ textAlign: { xs: 'left', sm: 'right' } }}
                        >
                          Fecha de Fin:
                        </InputLabel>
                      </Grid>

                      <Grid item xs={12} sm={9} lg={8}>
                        <TextField
                          fullWidth
                          {...endProps}
                          helperText=""
                          error={Boolean(errors.end_process && touched.end_process)}
                          {...getFieldProps('end_process')}
                        />
                        <FormHelperText>En esta fecha el periodo finalizará</FormHelperText>
                        {errors.end_process && touched.end_process && (
                          <FormHelperText error>{errors.end_process}</FormHelperText>
                        )}
                      </Grid>
                    </Grid>
                  )}
                />
              </LocalizationProvider>
            </Grid> */}

        {/* <Grid item xs={12}>
              <InputLabel>Campus</InputLabel>
              <Autocomplete
                multiple
                options={campusAvailables}
                loading={isLoadingCAMP}
                loadingText="Cargando campus disponibles..."
                getOptionLabel={(option) => option.cam_name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(errors.campusIds && touched.campusIds)}
                    placeholder="Seleccione los campus disponibles"
                  />
                )}
                onChange={(_, values) =>
                  setFieldValue('campusIds', [...values.map((v) => v.id)] || '')
                }
                fullWidth
              />
              {errors.campusIds && touched.campusIds && (
                <FormHelperText error>{errors.campusIds}</FormHelperText>
              )}
            </Grid> */}

        <Grid item xs={12}>
          <Grid container justifyContent={'flex-end'} spacing={1}>
            <Grid item>
              <Button variant="outlined" onClick={onCancel}>
                {t('financial.debts_payment.pay_dialog.form.cancel_btn')}
              </Button>
            </Grid>
            <Grid item>
              <Button type="submit" variant="contained">
                {indexPayToEdit?.toString()
                  ? t('financial.debts_payment.pay_dialog.form.update_btn')
                  : t('financial.debts_payment.pay_dialog.form.save_btn')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default CreditDebitCardMethod;
