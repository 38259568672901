import menu from './menu.json';
import faqs from './faqs.json';
import profiles from './profiles.json';
import roles from './roles.json';
import permissions from './permissions.json';
import users from './users.json';

const configurationTranslations = {
  ...menu,
  ...faqs,
  ...profiles,
  ...roles,
  ...permissions,
  ...users,
};

export default configurationTranslations;
