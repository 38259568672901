import { useEffect } from 'react';

import { IconButton } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { IconBus } from '@tabler/icons';

import { useGetBusesSchedulesQuery } from '../slices/busRouteApiSlice';
import SearchPaginatedTable from '../../../shared/components/tables/SearchPaginatedTable';
import AddButton from '../../../shared/components/tables/buttons/AddButton';
import EditButton from '../../../shared/components/tables/buttons/EditButton';
import DeleteButton from '../../../shared/components/tables/buttons/DeleteButton';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { useTableSearch } from '../../../../hooks/useTableSearch';
import { setBuseSchedulesList, updateScheduleTableStates } from '../slices/busRouteSlice';
import { useControlModalStates } from '../../../shared/hooks/useControlModalStates';
import DeleteScheduleDialog from './builder/DeleteScheduleDialog';
import useSharedGuard from '../../../../hooks/useSharedGuard';
import { subPermissionKeys } from '../constants/permissions';

const BusSchedulesList = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const { hasAccess, hasSharedPermissions } = useSharedGuard(subPermissionKeys);

  const { page, perPage, search, filterArrow } = useAppSelector(
    (state) => state.busRoute.busSchedulesTable
  );

  const { dataId, open, handleCloseModal, handleOpenModalWith } = useControlModalStates();

  const { setPage, setPerPage, setSearch, setFilterArrow } = useTableSearch({
    page,
    perPage,
    search,
    filterArrow,
    updateFunction: updateScheduleTableStates,
  });

  const { data, isFetching, isLoading, error } = useGetBusesSchedulesQuery({
    search,
    perPage,
    page,
    ...(filterArrow && { sort: filterArrow.name, typeSort: filterArrow.direction }),
  });

  useEffect(() => {
    if (data) dispatch(setBuseSchedulesList(data.data));
  }, [data, dispatch]);

  const datafetch = data?.data.map((item, idx) => ({
    id: (page - 1) * perPage + (idx + 1),
    name: item.sche_name,
    // campus: item.campus.cam_name,
    options: item,
  }));

  //   const datafetch = campus.

  const goToAdd = () => {
    history.push(`/bus-route/horarios/crear`);
  };
  const goToEdit = (id: number) => {
    history.push(`/bus-route/horarios/${id}`);
  };

  return (
    <>
      <SearchPaginatedTable
        data={datafetch || []}
        isFetching={isFetching}
        isLoading={isLoading}
        error={error}
        headers={{
          id: t('shared.table.index'),
          name: t('academic.buses.schedules.table.name'),
          options: t('shared.search_paginated_table.table.options'),
        }}
        keyExtractor={(row) => `table-busschedules-${row.options.id}`}
        // numHeader={1}
        page={page}
        perPage={perPage}
        search={search}
        searchPlacehoder={t('academic.buses.schedules.table.search_placeholder')}
        filterArrow={filterArrow}
        setFilterArrow={setFilterArrow}
        listFilterArrows={{
          name: 'sche_name',
        }}
        setPage={setPage}
        setPerPage={setPerPage}
        setSearch={setSearch}
        total={data?.total || 0}
        // additionalFilters={
        //   <Grid item xs={12} sm={6} md={4}>
        //     {isFetchingCampus ? (
        //       <TextFieldFetching />
        //     ) : (
        //       <Autocomplete
        //         options={dataCampus || []}
        //         getOptionLabel={(option) => option.cam_name}
        //         renderInput={(params) => (
        //           <TextField label={t('academic.buses.campus')} {...params} />
        //         )}
        //         value={campus}
        //         // defaultValue={campusDefault}
        //         onChange={(_, value) => setCampus(value)}
        //         fullWidth
        //       />
        //     )}
        //   </Grid>
        // }
        // paginationBox={false}
        // perPageBox={false}
        customDataCellsProperties={{
          options: {
            align: 'center',
          },
        }}
        customRenderers={{
          options: (row) => (
            <>
              {hasAccess(['getSchedule', 'updateSchedulesDetailsGroudped']) &&
                hasSharedPermissions('uploadFile') && (
                  <EditButton handleEdit={() => goToEdit(row.options.id)} />
                )}

              {hasAccess('createToursListGrouped') && false && (
                <Link to={`/bus-route/ruta/${row.options.id}/paradas`}>
                  <IconButton onClick={() => {}}>
                    <IconBus />
                  </IconButton>
                </Link>
              )}

              {hasAccess('deleteSchedule') && (
                <DeleteButton handleDelete={() => handleOpenModalWith(row.options.id)} />
              )}
            </>
          ),
        }}
        ActionButtons={
          <>
            {hasAccess('updateSchedulesDetailsGroudped') && (
              <AddButton size="large" onClick={goToAdd} />
            )}
          </>
        }
      />

      <DeleteScheduleDialog
        openModal={open}
        scheduleId={dataId}
        handleCloseModal={handleCloseModal}
      />
    </>
  );
};

export default BusSchedulesList;
