import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISearchTable } from '../../../shared/interfaces/paginatedTable.interface';

interface LendsState {
  lendingsSelecteds: boolean[];
  deleteModalOpen: boolean;
  lendToDelete?: number;
  currentDateSelected?: Date;

  lendingsTable: ISearchTable;
}

const initialState: LendsState = {
  lendingsSelecteds: [],
  deleteModalOpen: false,

  lendingsTable: {
    page: 1,
    perPage: 5,
    search: '',
  },
};

const lendingsSlice = createSlice({
  name: 'lendings',
  initialState,
  reducers: {
    setCurrentDateSelected: (state, action: PayloadAction<Date | undefined>) => {
      state.currentDateSelected = action.payload;
    },
    setLendingsSelected: (state, action: PayloadAction<boolean[]>) => {
      state.lendingsSelecteds = action.payload;
    },
    setDeleteModalOpen: (state, action: PayloadAction<boolean>) => {
      state.deleteModalOpen = action.payload;
    },
    openDeleteModalWith: (state, action: PayloadAction<number>) => {
      state.lendToDelete = action.payload;
      state.deleteModalOpen = true;
    },
    resetLendToDelete: (state) => {
      state.lendToDelete = undefined;
    },

    updateLendingsTable: (state, action: PayloadAction<ISearchTable>) => {
      state.lendingsTable = action.payload;
    },
  },
});

export const {
  setLendingsSelected,
  setDeleteModalOpen,
  openDeleteModalWith,
  resetLendToDelete,
  setCurrentDateSelected,
  updateLendingsTable,
} = lendingsSlice.actions;

export default lendingsSlice.reducer;
