import { useEffect, useState } from 'react';

import { Autocomplete, Grid, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { IconBus, IconClock, IconFileDownload } from '@tabler/icons';

import TextFieldFetching from '../../../shared/components/TextFieldFetching';
import { useGetRoutesQuery } from '../slices/busRouteApiSlice';
import SearchPaginatedTable from '../../../shared/components/tables/SearchPaginatedTable';
import { useGetAvailablesCampusQuery } from '../../campus/slices/campusApiSlice';
import { Campus } from '../../campus/interfaces/campus.interfaces';
import AddButton from '../../../shared/components/tables/buttons/AddButton';
import EditButton from '../../../shared/components/tables/buttons/EditButton';
import { useControlModalStates } from '../../../shared/hooks/useControlModalStates';
import BusNewRouteDialog from './BusNewRouteDialog';
import DeleteButton from '../../../shared/components/tables/buttons/DeleteButton';
import DeleteBusRouteDialog from './DeleteRouteDialog';
import AsignBusScheduleDialog from './AsignBusScheduleDialog';
import useGetUrlDownload from '../../../../hooks/useGetUrlDownload';
import CustomIconButton from '../../../shared/components/tables/buttons/CustomIconButton';
import useSharedGuard from '../../../../hooks/useSharedGuard';
import { subPermissionKeys } from '../constants/permissions';
import { useAppDispatch } from '../../../../hooks/useRedux';
import { setBusRoutesList } from '../slices/busRouteSlice';

const BusRouteList = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [campus, setCampus] = useState<Campus | null>(null);
  const [search, setSearch] = useState('');

  const { hasAccess } = useSharedGuard(subPermissionKeys);

  const { urlDownload } = useGetUrlDownload();

  const {
    open,
    handleCloseModal,
    multipleIds,
    handleOpenModalWithMultipleIds,
    handleOpenModalWith,
  } = useControlModalStates();

  const {
    open: openDeleteModal,
    dataId: deleteRouteId,
    handleOpenModalWith: handleOpenDeleteModalWith,
    handleCloseModal: handleCloseModalDelete,
  } = useControlModalStates();

  const {
    open: openAssignSchedules,
    multipleIds: assignScheRoutes,
    handleOpenModalWithMultipleIds: handleOpenAssignSchedules,
    handleCloseModal: handleCloseAssignSchedules,
  } = useControlModalStates<{ routeId: number; routeName: string }>();

  const { data, isFetching, isLoading, error } = useGetRoutesQuery({
    campusId: campus?.id.toString() || undefined,
    search,
  });

  const { data: dataCampus, isFetching: isFetchingCampus } = useGetAvailablesCampusQuery({});

  const datafetch = data?.data.map((item, i) => ({
    id: i + 1,
    name: item.bus_name,
    description: item.bus_description,
    campus: item.campus.cam_name,
    options: item,
  }));

  useEffect(() => {
    if (data) dispatch(setBusRoutesList(data.data));
  });

  const clickHandler = (file: string) => {
    window.open(`${urlDownload}${file}`, '_blank');
  };

  return (
    <>
      <SearchPaginatedTable
        data={datafetch || []}
        isFetching={isFetching}
        isLoading={isLoading}
        error={error}
        headers={{
          id: t('shared.table.index'),
          name: t('academic.buses.routes.table.route'),
          description: t('academic.buses.routes.table.description'),
          campus: t('academic.buses.routes.table.campus'),
          options: t('shared.search_paginated_table.table.options'),
        }}
        keyExtractor={(row) => `table-busroutes-${row.options.id}`}
        numHeader={1}
        page={1}
        perPage={100}
        search={search}
        searchPlacehoder={t('academic.buses.routes.table.search_placeholder')}
        setPage={() => {}}
        setPerPage={() => {}}
        setSearch={setSearch}
        total={data?.total || 0}
        additionalFilters={
          <Grid item xs={12} sm={6} md={4}>
            {isFetchingCampus ? (
              <TextFieldFetching />
            ) : (
              <Autocomplete
                options={dataCampus || []}
                getOptionLabel={(option) => option.cam_name}
                renderInput={(params) => (
                  <TextField label={t('academic.buses.campus')} {...params} />
                )}
                value={campus}
                // defaultValue={campusDefault}
                onChange={(_, value) => setCampus(value)}
                fullWidth
              />
            )}
          </Grid>
        }
        paginationBox={false}
        perPageBox={false}
        customDataCellsProperties={{
          options: {
            align: 'center',
          },
        }}
        customRenderers={{
          options: (row) => (
            <>
              {hasAccess(['getRouteTours', 'createToursListGrouped']) && (
                <Link to={`/bus-route/ruta/${row.options.id}/paradas`}>
                  <CustomIconButton
                    title={t('academic.buses.routes.table.options.tours')}
                    Icon={IconBus}
                    onClick={() => {}}
                    color="default"
                  />
                </Link>
              )}

              {hasAccess('getScheduleList') && (
                <CustomIconButton
                  title={t('academic.buses.routes.table.options.schedules')}
                  Icon={IconClock}
                  onClick={() =>
                    handleOpenAssignSchedules({ routeId: row.options.id, routeName: row.name })
                  }
                  color="default"
                />
              )}

              {hasAccess('updateRoute') && (
                <EditButton
                  handleEdit={() => handleOpenModalWithMultipleIds({ edit: row.options })}
                />
              )}

              {false && (
                <CustomIconButton
                  title={t('academic.buses.shared.view_doc_pdf.icon')}
                  Icon={IconFileDownload}
                  onClick={() => clickHandler(row.options.bus_document)}
                />
              )}

              {hasAccess('destroyRoute') && (
                <DeleteButton handleDelete={() => handleOpenDeleteModalWith(row.options.id)} />
              )}
            </>
          ),
        }}
        ActionButtons={
          <>
            {hasAccess('createRoute') && (
              <AddButton size="large" onClick={() => handleOpenModalWith()} />
            )}
          </>
        }
      />

      {openDeleteModal && (
        <DeleteBusRouteDialog
          openModal={openDeleteModal}
          routeId={deleteRouteId}
          handleCloseModal={handleCloseModalDelete}
        />
      )}

      <BusNewRouteDialog
        open={open}
        handleCloseDialog={handleCloseModal}
        editRoute={multipleIds?.edit}
      />

      <AsignBusScheduleDialog
        open={openAssignSchedules}
        routeId={assignScheRoutes?.routeId}
        routeName={assignScheRoutes?.routeName}
        handleCloseDialog={handleCloseAssignSchedules}
      />
    </>
  );
};

export default BusRouteList;
