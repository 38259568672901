import { Box, Typography, useTheme } from '@material-ui/core';

interface Props {
  Icon: JSX.Element;
  info: string;
}

const TableHeaderInfo = ({ Icon, info }: Props) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        mx: 2,
        fontSize: theme.typography.body2,
      }}
    >
      {Icon}
      <Typography
        sx={{
          fontWeight: 'bold',
          marginLeft: '0.5rem',
        }}
      >
        {info}
      </Typography>
    </Box>
  );
};

export default TableHeaderInfo;
