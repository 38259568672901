import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Course {
  courseHourhandDescription?: string;
  courseId?: number;
  courseName?: string;
  courseParalel?: string;
  collaborator?: string;
  vcType?: string;
  collaboratorIdentification: string;
  collaboratorEmail: string;
}

interface state {
  course: Course;
  IsFinishedCourse: boolean;
}

const initialCourse = {
  courseHourhandDescription: '',
  courseId: 0,
  collaborator: '',
  courseName: '',
  courseParalel: '',
  vcType: '',
  collaboratorIdentification: '',
  collaboratorEmail: '',
};

const initialState: state = {
  course: initialCourse,
  IsFinishedCourse: false,
};

const virtualClassroomStudentShared = createSlice({
  name: 'virtualClassroomStudentSharedSlice',
  initialState,
  reducers: {
    setCurrentCourse: (state, action: PayloadAction<Course>) => {
      state.course = action.payload;
    },
    resetCurrentCourse: (state) => {
      state.course = initialCourse;
    },
    setIsFinishedCourse: (state, action: PayloadAction<boolean>) => {
      state.IsFinishedCourse = action.payload;
    },
  },
});

export const { setCurrentCourse, resetCurrentCourse, setIsFinishedCourse } =
  virtualClassroomStudentShared.actions;

export default virtualClassroomStudentShared.reducer;
