import { Avatar, Grid } from '@material-ui/core';
import { Days } from '../constants/daysAvalaibles';
import { Hourhand } from '../interfaces/coursesAvailables.interfaces';
import Chip from '../../../shared/components/extended/Chip';

interface Props {
  hourhand: Hourhand;
}

const HourshandCourse = ({ hourhand }: Props) => {
  const hours = Days.map((d) => {
    return hourhand[`hour_${d.day}`] === '1'
      ? {
          day: d.name,
          start_hour: hourhand[`hour_start_time_${d.day}`] as string,
          end_hour: hourhand[`hour_end_time_${d.day}`] as string,
        }
      : null;
  });

  const filterHours = hours.filter((h) => h);

  const listHourhand = filterHours.map((h) => {
    return (
      <Grid item key={h?.day}>
        <Chip
          avatar={<Avatar>{h?.day.slice(0, 2)}</Avatar>}
          label={`${h?.start_hour?.slice(0, 5) || 'NaN'} - ${h?.end_hour?.slice(0, 5) || 'NaN'}`}
          color="primary"
          variant="outlined"
        />
      </Grid>
    );
  });

  return (
    <Grid container spacing={1}>
      {listHourhand}
    </Grid>
  );
};

export default HourshandCourse;
