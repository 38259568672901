import { mainApi } from '../../../../api/mainApi';
import { middlewareApi } from '../../../middleware/middlewareApi';
import { Report } from '../interfaces/reports.interface';
import { IResponse } from '../../../shared/interfaces/response.interface';
import { AccessState } from '../../../../types';

export const moduleReportsTags = middlewareApi.enhanceEndpoints({
  addTagTypes: ['ModuleReports'],
});

export const moduleReportsApi = moduleReportsTags.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * @GET reports table
     */
    getReportList: builder.query<
      IResponse<Report>,
      {
        profileId?: number;
        page: number;
        size: number;
        sort?: string;
        type_sort?: string;
        system_module_id?: number;
        mod_name?: string;
        search: string;
      }
    >({
      queryFn: async (
        { profileId, page, size, sort, type_sort, search, system_module_id, mod_name },
        { getState }
      ) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = profileId || currentProfile?.profileId;

          const { data } = await mainApi.get<IResponse<Report>>('/report-dashboard', {
            params: {
              user_profile_id,
              page,
              size,
              sort,
              type_sort,
              search,
              system_module_id,
              mod_name,
            },
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    /**
     * @GET report by id
     */
    getReport: builder.query<
      Report,
      {
        profileId?: number;
        reportId: number;
      }
    >({
      queryFn: async ({ profileId, reportId }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = profileId || currentProfile?.profileId;

          const { data } = await mainApi.get<Report>(`/report-dashboard/${reportId}`, {
            params: {
              user_profile_id,
            },
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    // /**
    //  * @POST assing roles to status
    //  */
    // assingRolesToStatus: builder.mutation<
    //   any,
    //   {
    //     user_profile_id: string | number;
    //     type_application_status_id: string | number;
    //     roles: number[];
    //   }
    // >({
    //   queryFn: async ({ user_profile_id, type_application_status_id, roles }) => {
    //     try {
    //       const { data } = await mainApi.post<any>('type-application-status-roles', {
    //         user_profile_id,
    //         type_application_status_id,
    //         roles,
    //       });

    //       return { data };
    //     } catch (error: any) {
    //       return { error };
    //     }
    //   },
    // }),

    //
  }),
});

export const { useGetReportListQuery, useGetReportQuery, useLazyGetReportQuery } = moduleReportsApi;
