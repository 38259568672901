import { InputLabel, MenuItem } from '@material-ui/core';
import { FormControl } from '@material-ui/core';
import { Select } from '@material-ui/core';
import React from 'react';

interface Props {
  handleChangeTask: React.Dispatch<React.SetStateAction<string>>;
  label: string;
  valueState: string;
  items: {
    value: string;
    text: string;
    info?: any;
  }[];
}

export function SelectTypes({ handleChangeTask, items, label, valueState }: Props) {
  const handleChangeSelect = (event: React.ChangeEvent<{ value: unknown }>) => {
    handleChangeTask(event.target.value as string);
  };

  return (
    <FormControl
      sx={{
        width: '230px',
        mr: 4,
      }}
    >
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={valueState}
        label={label}
        onChange={handleChangeSelect}
      >
        {items.map((menu) => (
          <MenuItem value={menu.value} key={`${menu.value} - ${menu.text}`}>
            {menu.text}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
