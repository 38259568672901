import { ReactElement } from 'react';
import { withAsyncPaginate } from 'react-select-async-paginate';
import type { UseAsyncPaginateParams, ComponentProps } from 'react-select-async-paginate';
import type {
  ActionMeta,
  GroupBase,
  MultiValue,
  OptionsOrGroups,
  SingleValue,
  DropdownIndicatorProps,
} from 'react-select';
import Select, { components } from 'react-select';
// import Creatable from 'react-select/creatable';
// import NativeSelect from '@material-ui/core/NativeSelect';
// import Autocomplete from '@material-ui/core/Autocomplete';
// import TextField from '@material-ui/core/TextField';
import type { CreatableProps } from 'react-select/creatable';
import type { Theme } from '@material-ui/core/styles/createTheme';
import useTheme from '@material-ui/core/styles/useTheme';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDownOutlined';
import { CustomChilds } from '../../../interfaces/material-ui.interfaces';

//tipado del componente
type AsyncPaginateCreatableProps<
  OptionType,
  Group extends GroupBase<OptionType>,
  Additional,
  IsMulti extends boolean
> = CreatableProps<OptionType, IsMulti, Group> &
  UseAsyncPaginateParams<OptionType, Group, Additional> &
  ComponentProps<OptionType, Group, IsMulti>;

type AsyncPaginateCreatableType = <
  OptionType,
  Group extends GroupBase<OptionType>,
  Additional,
  IsMulti extends boolean = false
>(
  props: AsyncPaginateCreatableProps<OptionType, Group, Additional, IsMulti>
) => ReactElement;

const AsyncPaginateCreatable = withAsyncPaginate(Select) as AsyncPaginateCreatableType;

//parametros de la funcion loadOptions
interface IReturnLoadOptions<T> {
  options: T[];
  hasMore: boolean;
  additional: any | undefined;
}

//interfaz del return de la prop del fetch
interface IReturnedFetch<ResponsePaginated extends {}> {
  options: ResponsePaginated[];
  hasMore: boolean;
}

const customStyles = (theme: Theme, isError?: boolean, multiple?: boolean) => {
  return {
    option: (provided: any, state: any) => ({
      ...provided,

      // borderBottom: '1px dotted pink',
      // color: state.isSelected ? 'red' : 'blue',
      // color: theme.palette.primary[100],
      // padding: 20,
      cursor: 'pointer',
      backgroundColor: state.isSelected && 'rgba(163, 196, 227,0.3)',
      color: state.isSelected && '#657f98',
      '&:hover': {
        backgroundColor: state.isSelected
          ? 'rgba(163, 196, 227,0.3)'
          : theme.palette.mode === 'dark'
          ? theme.palette.dark[800]
          : theme.palette.grey[100],
      },
    }),
    // dropdownIndicator: () => ArrowDropDownIcon,

    control: (provided: any) => ({
      ...provided,
      padding: '0.545em 0.4em',
      borderRadius: '12px',
      color: '#212121',
      fontWeight: 500,
      backgroundColor:
        theme.palette.mode === 'dark' ? theme.palette.dark[800] : theme.palette.grey[100], //'rgb(26, 34, 63)', //'#f5f5f5', //theme.palette.grey[100],
      border: isError ? '1px solid hsl(4deg 90% 58%)' : '1px solid hsl(210deg 1% 73%)',
      // none of react-select's styles are passed to <Control />
      // width: 'auto',
      display: 'flex',
      alignItems: 'center',
      // minHeight: '54px',
      height: multiple ? 'auto' : '54px',
      cursor: 'text',
      // background: 'transparent',
      '&:hover': {
        border: isError ? '1px solid hsl(4deg 90% 58%)' : '1px solid black',
        boxShadow: 'none',
      },
      '&:focus-within': {
        border: isError ? '1px solid hsl(4deg 90% 58%)' : `2px solid ${theme.palette.primary.main}`,
      },
      boxShadow: 'none',
      // '&:focus': {
      //   backgroundColor: 'black',
      //   border: isError && '2px solid hsl(4deg 90% 58%)',
      // },
    }),
    menu: (provided: any, state: any) => ({
      // ...provided,
      backgroundColor: 'white',
      boxShadow: theme.shadows[10],
      position: 'absolute' as any,
      left: 0,
      top: `calc(100% + 1px)`,
      width: '100%',
      zIndex: 2,
      maxHeight: 48 * 4.5,
      borderRadius: '12px',
      '&:hover': {
        // border: isError ? '1px solid hsl(4deg 90% 58%)' : '1px solid black',
        // boxShadow: 'none',
      },
    }),
    menuList: (provided: any, state: any) => ({
      padding: '10px 0px',
      // ...provided,
      maxHeight: 48 * 4.5,
      // zIndex: 99999,
      // padding: 2,
      overflowY: 'auto' as any,
    }),

    singleValue: (provided: any, state: any) => ({
      ...provided,
      // opacity: state.isDisabled ? 0.5 : 1,
      // transition: 'opacity 300ms',
      color: theme.palette.text.dark,
    }),

    // input: (provided: any, state: any) => ({
    //   ...provided,
    // }),
  };
};

const DropdownIndicator = <OptionType extends {}>(
  props: DropdownIndicatorProps<OptionType, true>
) => {
  return (
    <components.DropdownIndicator {...props}>
      <ArrowDropDownIcon />
    </components.DropdownIndicator>
  );
};

//props del componente
interface Props<T extends {}> extends Partial<AsyncPaginateCreatableType> {
  // Determina si el select acepta multiples datos
  multiple?: boolean;
  value: T | T[] | null; // string | readonly string[] | null;
  defaultValue?: T | null; // string | readonly string[] | undefined;
  perPage?: number;
  isError?: boolean;
  placeholder?: string;
  noOptionsMessage?: string;
  label?: string;
  loadingMessage?: string;
  /**
   * Si el select esta al final del dialog, la caja del menu se corta
   */
  inDialog?: boolean;
  fetchFunc: (
    search: string,
    page: number,
    perPage: number
    //TODO: fix con el conflicto del tipado de la respuesta y el generico (cuando se tipa el estado fuera, exige instanciar el value y label, revisar si es necesario)
  ) => Promise<IReturnedFetch<CustomChilds<T, { value: number; label: any }>>>;
  setValue: React.Dispatch<React.SetStateAction<T | readonly T[] | null>>;
  clearable?: boolean;
}

const CustomAsyncPaginatedSelect = <T extends {}>({
  perPage = 20,
  multiple,
  value,
  isError,
  placeholder,
  label,
  noOptionsMessage,
  loadingMessage,
  inDialog,
  fetchFunc,
  setValue,
  ...props
}: Props<T>) => {
  const theme = useTheme();
  // const [value, setValue] = useState<string | readonly string[] | null>(null);

  const onCHange = (newValue: SingleValue<T> | MultiValue<T>, actionMeta: ActionMeta<T>) => {
    // console.log('newValue: ', newValue);
    setValue(newValue);
  };

  const loadOptions = async (
    search: string,
    loadedOptions: OptionsOrGroups<T, any>,
    { page }: any | undefined
  ): Promise<IReturnLoadOptions<T>> => {
    const { options, hasMore } = await fetchFunc(search, page, perPage);

    return {
      options,
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  return (
    <AsyncPaginateCreatable
      value={value}
      className={'paginated-select'}
      classNamePrefix={'paginated-select'}
      // {...(defaultValue && { defaultValue })}
      styles={customStyles(theme, isError, multiple)}
      components={{
        DropdownIndicator,
      }}
      noOptionsMessage={() => noOptionsMessage || 'Sin registros disponibles'}
      loadingMessage={() => loadingMessage || 'Cargando...'}
      placeholder={placeholder ? placeholder : 'Escoger item'}
      debounceTimeout={300}
      // onCreateOption={() => {}}
      {...(multiple && { isMulti: true })}
      loadOptions={loadOptions}
      onChange={onCHange}
      additional={{
        page: 1,
      }}
      {...(inDialog && {
        menuPortalTarget: document.body,
        styles: { menuPortal: (base) => ({ ...base, zIndex: 9999 }) },
      })}
      {...props}
    />
  );
};

// interface IUseGetLoadOptions {
//   loadOptionsFunc: (...params: any) => void;
// }
// export const useGetLoadOptions = () => {
//   const response = await getInstitutesTrigger({
//     page,
//     perPage,
//     search,
//     profileId: currentProfile?.profileId!,
//   }).unwrap();

//   const hasMore = response.current_page < response.last_page;

//   return {
//     options: response.data.map((dt) => {
//       return {
//         value: dt.id,
//         label: dt.inst_name,
//         ...dt,
//       };
//     }),
//     hasMore,
//   };
// };

export default CustomAsyncPaginatedSelect;
