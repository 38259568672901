import { Autocomplete, Button, Grid, InputLabel, TextField } from '@material-ui/core';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useGetAllShelfsQuery } from '../../../shelfs/slices/shelfsApiSlice';
import { useGetAvailablesCampusQuery } from '../../../../maintenance/campus/slices/campusApiSlice';
import { useAppSelector, useAppDispatch } from '../../../../../hooks/useRedux';
import { useGetInvestigationLinesAllAvailablesQuery } from '../../../../maintenance/lines-of-investigation/slices/InvestigationLinesApiSlice';
import { useEffect, useState } from 'react';
import { useGetSubjectsClassificationsQuery } from '../../../books/slices/booskApiSlice';
import { setBodyForm } from '../../../books/slices/booksSlice';
import TextFieldFetching from '../../../../shared/components/TextFieldFetching';
interface props {
  isActive: boolean;
  handleNext: () => void;
  handleBack: () => void;
}

const Step2 = ({ handleNext, handleBack, isActive }: props) => {
  const { data: shelfs, isLoading: isLoadingShelfs } = useGetAllShelfsQuery();
  const { bookToEdit } = useAppSelector((state) => state.books);
  const { currentProfile } = useAppSelector((state) => state.access);
  const dispatch = useAppDispatch();
  const [updatAutocomplete, setUpdateAutocomplete] = useState(false);

  const { data: classifications, isLoading: isLoadingClassifications } =
    useGetSubjectsClassificationsQuery({
      user_profile_id: currentProfile?.profileId!,
    });

  const classificationsOptions =
    classifications
      ?.filter((cl) => !Boolean(cl.parent_id))
      .map((cl) => ({
        label: `${cl.code} - ${cl.name}`,
        value: String(cl.id),
        code: cl.code,
      })) || [];

  const { data: lines, isLoading: isLoadingLines } = useGetInvestigationLinesAllAvailablesQuery({
    profileId: currentProfile?.profileId!,
  });

  const {
    data: campuses,
    //isLoading: isLoadingCampuses
  } = useGetAvailablesCampusQuery({
    profileId: currentProfile?.profileId!,
  });

  const schema = yup.object().shape({
    isEdit: yup.boolean(),
    location: yup.string().required('Ubicación es requerida'),
    edification: yup.string(),
    inventory: yup.string(),
    collection: yup.string(),
    codebar: yup.string(),
    classification: yup.string().required('Materia es requerida'),
    classification_child: yup.string().required('Clasificación es requerida'),
    line: yup.string().required('Linea de Investigación es requerida'),
  });

  const { handleSubmit, values, errors, touched, setFieldValue } = useFormik({
    initialValues: {
      isEdit: bookToEdit ? true : false,
      //location: bookToEdit?.locations.map((lc) => lc.location_type).join(',') || '',
      location: bookToEdit?.locations[0]?.id?.toString() || '',
      edification: '',
      inventory: bookToEdit?.book_inventory || '',
      collection: bookToEdit?.book_collection || '',
      campus: bookToEdit?.locations[0]?.campus_id?.toString() || '',
      classification: bookToEdit?.clasification?.parent_id?.toString() || '',
      classification_child: bookToEdit?.clasification?.id?.toString() || '',
      line: bookToEdit?.line_research_id || '',
      codebar: bookToEdit?.institution_barcode || '',
      subject_code: '',
      classification_code: '',
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      await dispatch(setBodyForm(values));
      await handleNext();
    },
  });

  const handleFormSubmit = () => {
    handleSubmit();
  };

  useEffect(() => {
    setUpdateAutocomplete(true);
    setTimeout(() => {
      setUpdateAutocomplete(false);
    }, 50);
  }, [values.classification_child]);

  if (!isActive) {
    return <div />;
  }

  const changeBarcode = (subjectCode: string, classificationCode: string) => {
    const codebar = `${subjectCode || ''}${classificationCode || ''}`;
    setFieldValue('codebar', codebar);
  };

  return (
    <>
      <Grid item xs={12} md={6}>
        <InputLabel>Ubicación</InputLabel>
        {isLoadingShelfs ? (
          <TextField placeholder="Cargando..." fullWidth />
        ) : (
          <Autocomplete
            options={
              shelfs?.map((sh) => ({
                label: sh.description,
                value: sh.id,
                campus: sh.campus_id,
              })) || []
            }
            getOptionLabel={(option) => option.label}
            getOptionSelected={(opt) => Number(values.location) === opt.value}
            renderInput={(params) => (
              <TextField
                {...params}
                //error={Boolean(errors.statusId && touched.statusId)}
                placeholder="Seleccione Ubicación..."
                error={Boolean(errors.location && touched.location)}
                helperText={Boolean(errors.location && touched.location) ? errors.location : ''}
              />
            )}
            // defaultValue={shelfs
            //   ?.filter((sh) => values.location.split(',').includes(sh.location_type))
            //   .map((sh) => ({ value: sh.id, label: sh.description }))}
            value={shelfs
              ?.map((sh) => ({
                label: sh.description,
                value: sh.id,
                campus: sh.campus_id,
              }))
              .find((sh) => sh.value === Number(values.location))}
            onChange={async (e, value) => {
              // setFieldValue('location', value.map((vl) => String(vl.value)).join(','));
              if (value) {
                await setFieldValue('location', value.value);
                await setFieldValue('campus', value.campus);
              }
            }}
            fullWidth
            clearIcon={null}
          />
        )}
      </Grid>
      {/* <Grid item xs={12} md={6}>
        <InputLabel>Edificación</InputLabel>
        {isLoadingCampuses ? (
          <TextField placeholder="Cargando..." fullWidth />
        ) : (
          <Autocomplete
            options={
              campuses?.map((sh) => ({
                label: sh.cam_name,
                value: sh.id,
              })) || []
            }
            getOptionLabel={(option) => option.label}
            value={campuses
              ?.map((sh) => ({
                label: sh.cam_name,
                value: sh.id,
              }))
              .find((cp) => cp.value === Number(values.edification))}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Seleccione Edificación..."
                error={Boolean(errors.edification && touched.edification)}
                helperText={
                  Boolean(errors.edification && touched.edification)
                    ? errors.edification
                    : ''
                }
              />
            )}
            onChange={(e, value) => {
              if (value) {
                setFieldValue('edification', value.value);
              }
            }}
            fullWidth
            clearIcon={null}
          />
        )}
      </Grid> */}
      <Grid item xs={12} md={6}>
        <InputLabel>Edificación</InputLabel>
        {campuses ? (
          <TextField
            placeholder=""
            value={campuses?.find((cp) => cp.id === Number(values.campus))?.cam_name}
            fullWidth
            disabled
          />
        ) : (
          <TextFieldFetching />
        )}
      </Grid>
      <Grid item xs={12} md={6}>
        <InputLabel>Inventario</InputLabel>
        <TextField
          placeholder="Ingrese Inventario..."
          value={values.inventory}
          fullWidth
          onChange={({ target }) => {
            setFieldValue('inventory', target.value);
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <InputLabel>Colección</InputLabel>
        <TextField
          placeholder="Ingrese Colección..."
          value={values.collection}
          fullWidth
          onChange={({ target }) => {
            setFieldValue('collection', target.value);
          }}
        />
      </Grid>
      <Grid item container xs={12} md={6}>
        <InputLabel>Materia</InputLabel>

        {isLoadingClassifications ? (
          <TextField placeholder="Cargando..." fullWidth />
        ) : (
          <Autocomplete
            options={classificationsOptions}
            getOptionLabel={(option) => option.label}
            value={classificationsOptions.find((el) => el.value === values.classification)}
            getOptionSelected={(option) => values.classification === option.value}
            //defaultValue={[]}
            fullWidth
            clearIcon={null}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Seleccione Materia..."
                error={Boolean(errors.classification && touched.classification)}
                helperText={
                  Boolean(errors.classification && touched.classification)
                    ? errors.classification
                    : ''
                }
              />
            )}
            onChange={async (_, value) => {
              if (value) {
                await setFieldValue('classification', value.value);
                await setFieldValue('subject_code', value.code);
              }
              await setFieldValue('classification_child', '');
              await setFieldValue('classification_code', '');
              changeBarcode(value?.code || '', values.classification_code);
            }}
          />
        )}
      </Grid>

      <Grid item container xs={12} md={6}>
        <InputLabel>Clasificación</InputLabel>

        {isLoadingClassifications || updatAutocomplete ? (
          <TextField placeholder="Cargando..." fullWidth />
        ) : (
          <Autocomplete
            options={
              values.classification !== ''
                ? classifications
                    ?.find((cl) => cl.id === values.classification)
                    ?.child?.map((ch) => ({
                      value: ch.id,
                      label: ch.name,
                      code: ch.code,
                    })) || []
                : []
            }
            getOptionLabel={(option) => `${option.code} - ${option.label}`}
            getOptionSelected={(option) => values.classification_child === option.value}
            value={
              values.classification !== ''
                ? classifications
                    ?.find((cl) => cl.id === values.classification)
                    ?.child?.map((ch) => ({
                      value: ch.id,
                      label: ch.name,
                      code: ch.code,
                    }))
                    .find((ch) => ch.value === values.classification_child)
                : undefined
            }
            fullWidth
            clearIcon={null}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Seleccione Clasificación..."
                error={Boolean(errors.classification_child && touched.classification_child)}
                helperText={
                  Boolean(errors.classification_child && touched.classification_child)
                    ? errors.classification_child
                    : ''
                }
              />
            )}
            onChange={async (_, value) => {
              if (value) {
                await setFieldValue('classification_child', value.value);
                await setFieldValue('classification_code', value.code);
              }

              changeBarcode(values.subject_code, value?.code || '');
            }}
          />
        )}
      </Grid>

      <Grid item container xs={12} md={6}>
        <InputLabel>Lineas de Investigación</InputLabel>

        {isLoadingLines ? (
          <TextField placeholder="Cargando..." fullWidth />
        ) : (
          <Autocomplete
            options={
              lines?.map((ln) => ({
                value: ln.id,
                label: ln.name,
              })) || []
            }
            getOptionLabel={(option) => option.label}
            getOptionSelected={(option) => values.line === String(option.value)}
            value={
              lines
                ?.map((ln) => ({
                  value: ln.id,
                  label: ln.name,
                }))
                .find((ln) => String(ln.value) === values.line) || undefined
            }
            fullWidth
            clearIcon={null}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Seleccione Linea de Investigación..."
                error={Boolean(errors.line && touched.line)}
                helperText={Boolean(errors.line && touched.line) ? errors.line : ''}
              />
            )}
            onChange={(_, value) => {
              if (value) {
                setFieldValue('line', value.value);
              }
            }}
          />
        )}
      </Grid>

      <Grid item xs={12} md={6}>
        <InputLabel>Código de Barra de Institución</InputLabel>
        <TextField
          fullWidth
          placeholder="Ingrese Código de Barra"
          value={values.codebar}
          error={Boolean(errors.codebar && touched.codebar)}
          helperText={Boolean(errors.codebar && touched.codebar) ? errors.codebar : ''}
          onChange={({ target }) => setFieldValue('codebar', target.value)}
          disabled
        />
      </Grid>

      <Grid item container xs={12} md={12} justifyContent="flex-end">
        <Button variant="outlined" onClick={handleBack} size="large">
          Anterior
        </Button>{' '}
        &nbsp;&nbsp;
        <Button variant="contained" onClick={handleFormSubmit} size="large">
          Siguiente
        </Button>
      </Grid>
    </>
  );
};

export default Step2;
