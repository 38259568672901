import { Alert } from '@material-ui/core';
import { LinksValidation } from '../../interfaces/linksValidation.interfaces';

interface Props {
  data: LinksValidation[];
}

const Validation = ({ data }: Props) => {
  return (
    <>
      {data?.map(({ message, status }) => (
        <Alert sx={{ mb: 1 }} severity={status ? 'success' : 'error'}>
          {message}
        </Alert>
      ))}
    </>
  );
};

export default Validation;
