import { useEffect, useState } from 'react';

import { LinearProgress } from '@material-ui/core';

interface Props {
  customTimeInterval?: number;
  customAddTime?: number;
  completeFunction?: () => void;
}
const TimerAutoRedirect = ({
  customAddTime,
  customTimeInterval,
  completeFunction,
  ...props
}: Props) => {
  //progress bar
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (progress < 100) {
      const timer = setInterval(() => {
        setProgress((oldProgress) => {
          if (oldProgress === 100) {
            return 0;
          }

          const customIntervalTime = customTimeInterval ? (2 * customTimeInterval) / 500 : 2.001;

          const diff = customAddTime ? 100 / customAddTime / customIntervalTime : 20;
          return Math.min(oldProgress + diff, 100);
        });
      }, customTimeInterval || 400);

      return () => {
        clearInterval(timer);
      };
    }
  }, [customAddTime, customTimeInterval, progress]);

  useEffect(() => {
    const customTime =
      customTimeInterval && customTimeInterval > 100 ? customTimeInterval - 100 : undefined;
    if (progress === 100) {
      const timer = setTimeout(() => {
        if (completeFunction) completeFunction();
      }, customTime || 300);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [completeFunction, customTimeInterval, progress]);

  return <LinearProgress variant="determinate" value={progress} />;
};

export default TimerAutoRedirect;
