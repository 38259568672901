import { useEffect } from 'react';

import { Avatar, Divider, Grid, Paper, Typography, useTheme } from '@material-ui/core';

import { IconBook2 } from '@tabler/icons';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import Weighting from '../components/weighting';
import { useLazyGetIntegralsReportsByIdsQuery } from '../slices/integralReportsApiSlice';
import { setIntegralsReportsList } from '../slices/integralReportsSlice';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/useRedux';
import BackButton from '../../../../../shared/components/buttons/BackButton';
import Loader from '../../../../../shared/components/Loader';

const IntegralWeightingScreen = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const dispatch = useAppDispatch();
  const { evaId, userId } = useParams<{ evaId: string; userId: string }>();

  const { integralsReportsList } = useAppSelector((state) => state.integralReports);

  const [getIntegralEvaluations, { isFetching }] = useLazyGetIntegralsReportsByIdsQuery();

  useEffect(() => {
    if (!integralsReportsList.length && !isFetching) {
      getIntegralEvaluations({
        integral_evaluation_id: evaId!,
        user_id: userId!,
      })
        .unwrap()
        .then((response) => {
          if (response) dispatch(setIntegralsReportsList([response!]));
        })
        .catch((error) => console.log(error));
    }
  }, [dispatch, evaId, getIntegralEvaluations, integralsReportsList.length, isFetching, userId]);

  return (
    <Grid
      container
      component={Paper}
      sx={{
        p: 2,
      }}
    >
      <Grid item xs={12} container alignItems="center" spacing={1}>
        <BackButton />
        <Typography variant="h2">{t('reports.integrals.weighting.title')}</Typography>
      </Grid>
      <Grid item container xs={12} mt={2} alignItems="center" spacing={1}>
        <Grid item>
          <Avatar
            sx={{
              backgroundColor: 'grey.200',
            }}
          >
            <IconBook2 color={theme.palette.grey[700]} />
          </Avatar>
        </Grid>
        <Grid item>
          <Typography variant="h4">{t('reports.integrals.weighting.description')}</Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} mt={2}>
        <Divider />
      </Grid>
      <Grid item xs={12} mt={2}>
        {!integralsReportsList.length ? <Loader /> : <Weighting />}
      </Grid>
    </Grid>
  );
};

export default IntegralWeightingScreen;
