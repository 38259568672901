import {
  Autocomplete,
  Button,
  Grid,
  InputLabel,
  TextField,
  CircularProgress,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useState, useEffect } from 'react';
import * as yup from 'yup';
import { useAppDispatch } from '../../../../../hooks/useRedux';
import { useGetPersonsQuery } from '../../../lendings/slices/lendingApiSlice';
import { setStudentId } from '../../slices/thesisSlice';

interface props {
  isActive: boolean;
  handleNext: () => void;
}

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

const Step1 = ({ handleNext, isActive }: props) => {
  const [search, setSearch] = useState('');
  const [open, setOpen] = useState(false);

  const dispatch = useAppDispatch();

  const {
    data: users,
    isLoading: isLoadingUsers,
    isFetching: isFetchingUsers,
  } = useGetPersonsQuery(
    {
      user_profile_id: 1,
      search,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const loading = isLoadingUsers || isFetchingUsers ? true : false;

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      await sleep(1e3);

      if (active) {
      }
    })();

    return () => {
      active = false;
    };
  }, [loading, users]);

  const schema = yup.object().shape({
    user: yup.string().required('Estudiante es requerido'),
    stu_record: yup.string().required('Carrera es requerida'),
  });

  const formik = useFormik({
    initialValues: {
      user: '',
      faculty: '',
      code: '',
      name: '',
      lastname: '',
      stu_record: '',
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      await handleNext();
    },
  });

  const handleSubmit = () => formik.handleSubmit();

  const options =
    open && isFetchingUsers
      ? []
      : users?.data
          .filter((us) => us.user && us.user.student)
          .map((us) => ({
            value: us.id,
            label: us.pers_full_name,
            code: us.user?.student?.stud_code || '',
            name: us.pers_firstname + ' ' + us.pers_secondname,
            lastname: us.pers_first_lastname + ' ' + us.pers_second_lastname,
            students_records: us.user?.student?.student_records,
            fac:
              us.user?.student?.student_records && us.user?.student?.student_records.length > 0
                ? us.user?.student?.student_records[0].education_level?.father?.edu_name
                : '',
          })) || [];

  if (isActive === false) {
    return <div />;
  }

  return (
    <>
      <Grid item xs={12} md={6} lg={6}>
        <InputLabel>Buscar Estudiante</InputLabel>
        <Autocomplete
          fullWidth
          filterOptions={(x) => x}
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          //isOptionEqualToValue={(option, value) => option.label === value.label}
          getOptionLabel={(option) => option.label}
          getOptionSelected={(option) => option.value === Number(formik.values.user)}
          onChange={async (_, value) => {
            if (value) {
              await formik.setFieldValue('user', value.value);
              await formik.setFieldValue('code', value.code);
              await formik.setFieldValue('name', value.name);
              await formik.setFieldValue('lastname', value.lastname);
            } else {
              formik.setFieldValue('user', '');
              formik.setFieldValue('code', '');
              formik.setFieldValue('name', '');
              formik.setFieldValue('lastname', '');
              await dispatch(setStudentId(undefined));
            }
          }}
          options={options}
          value={options.find((opt) => opt.value === Number(formik.values.user))}
          loading={isLoadingUsers || isFetchingUsers}
          renderInput={(params) => (
            <TextField
              onChange={({ target }) => setSearch(target.value)}
              placeholder="Ingrese (Nombre, Apellido o Cédula)"
              defaultValue={search}
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
              error={Boolean(formik.errors.user && formik.touched.user)}
              helperText={Boolean(formik.errors.user && formik.touched.user) && formik.errors.user}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} md={6} lg={6}>
        <InputLabel>Código*</InputLabel>
        <TextField fullWidth value={formik.values.code} placeholder="Código" disabled />
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        <InputLabel>Nombres*</InputLabel>
        <TextField fullWidth value={formik.values.name} placeholder="Nombres" disabled />
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        <InputLabel>Apellidos*</InputLabel>
        <TextField fullWidth value={formik.values.lastname} placeholder="Apellidos" disabled />
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        <InputLabel>Carrera</InputLabel>
        <Autocomplete
          options={
            Boolean(formik.values.user !== '')
              ? options
                  .find((opt) => opt.value === Number(formik.values.user))
                  ?.students_records?.map((st) => ({
                    label: st.education_level.edu_name,
                    value: st.id,
                    fac: st.education_level.father.edu_name,
                  })) || []
              : []
          }
          value={options
            .find((opt) => opt.value === Number(formik.values.user))
            ?.students_records?.map((st) => ({
              label: st.education_level.edu_name,
              value: st.id,
              fac: st.education_level.father.edu_name,
            }))
            .find((st) => st.value === Number(formik.values.stu_record))}
          onChange={async (_, value) => {
            if (value) {
              await formik.setFieldValue('stu_record', value.value);
              await formik.setFieldValue('faculty', value.fac);
              await dispatch(setStudentId(value.value));
            } else {
              await formik.setFieldValue('stu_record', '');
              await formik.setFieldValue('faculty', '');
              await dispatch(setStudentId(undefined));
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Seleccione carrera"
              error={Boolean(formik.errors.stu_record && formik.touched.stu_record)}
              helperText={
                Boolean(formik.errors.stu_record && formik.touched.stu_record) &&
                formik.errors.stu_record
              }
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        <InputLabel>Facultad*</InputLabel>
        <TextField
          fullWidth
          value={formik.values.faculty}
          onChange={({ target }) => setSearch(target.value)}
          placeholder="Facultad"
          disabled
        />
      </Grid>
      {/* <Grid item xs={12} md={6}>
        <InputLabel>Título</InputLabel>
        <TextField
          fullWidth
          placeholder="Ingrese Título"
          value={formik.values.title}
          error={Boolean(formik.errors.title && formik.touched.title)}
          helperText={
            Boolean(formik.errors.title && formik.touched.title) ? formik.errors.title : ''
          }
          onChange={({ target }) => formik.setFieldValue('title', target.value)}
        />
      </Grid> */}

      <Grid item xs={12} md={6} lg={6} xl={6} />

      <Grid item container xs={12} md={12} justifyContent="flex-end">
        <Button variant="contained" onClick={handleSubmit} size="large">
          Siguiente
        </Button>
      </Grid>
    </>
  );
};

export default Step1;
