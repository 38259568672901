import { mainApi } from '../../../../api/mainApi';
import { middlewareApi } from '../../../middleware/middlewareApi';

import { setCurriculumList } from './meshSlice';

import { StudyModalityAvailable } from '../interfaces/studyModalities.interfaces';
import { Curriculum, CurriculumAll, MatterMesh } from '../interfaces/curriculum.interfaces';
import { Curriculum as Curriculum2 } from '../interfaces/curriculums.interfaces';
import { Simbology } from '../interfaces/symbology.interfaces';
import { LearningComponent } from '../interfaces/components.interfaces';
import {
  CurriculumPostPayload,
  SubjectCurriculumAssignPayload,
} from '../interfaces/payloads.interfaces';
import { CurriculumsPaginatedResponse } from '../interfaces/curriculums.interfaces';
import { CurriculumStatus } from '../interfaces/status.interfaces';
import { HomologateInternalSubjectsResponse } from '../interfaces/homologateInternalSubjects.interfaces';
import { infoInternalHomologateSubject } from '../../homologations/interfaces/homologations.interface';
import { Matter } from '../../matters/interfaces/matters.interface';
import { AccessState } from '../../../../types';

export const meshsTags = middlewareApi.enhanceEndpoints({
  addTagTypes: [
    'Meshs',
    'MatterMesh',
    'Dependencies',
    'Curriculum',
    'HomologateInternalSubjects',
    'MattersAvailables',
  ],
});

export const meshsApi = meshsTags.injectEndpoints({
  endpoints: (build) => ({
    /**
     * @GET all curriculums to table or grid view
     */
    getCurriculums: build.query<
      CurriculumsPaginatedResponse,
      {
        page: number;
        perPage: number;
        search: string;
        sort?: string;
        typeSort?: string;
        education_lvl_father_id?: number;
        education_lvl_id?: number;
        mes_year?: number;
        subject_id?: number;
        modalityId?: string;
      }
    >({
      queryFn: async (
        {
          page,
          perPage,
          search,
          sort = 'id',
          typeSort = 'desc',
          education_lvl_father_id,
          education_lvl_id,
          mes_year,
          subject_id,
          modalityId,
        },
        { dispatch, getState }
      ) => {
        try {
          const {
            access: {
              currentProfile: { profileId },
            },
          } = getState() as { access: { currentProfile: { profileId: number } } };

          const { data: paginatedInfo } = await mainApi.get<CurriculumsPaginatedResponse>(
            '/curriculums',
            {
              params: {
                user_profile_id: profileId,
                search,
                page,
                size: perPage,
                sort,
                type_sort: typeSort,
                ...(education_lvl_father_id && { education_lvl_father_id }),
                ...(education_lvl_id && { education_lvl_id }),
                ...(mes_year && { mes_year }),
                ...(subject_id && { subject_id }),
                ...(modalityId && { modality_id: modalityId }),
              },
            }
          );

          dispatch(setCurriculumList(paginatedInfo.data));

          return { data: paginatedInfo };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'Meshs', id } as const)),
              { type: 'Meshs', id: 'LIST' },
            ]
          : [{ type: 'Meshs', id: 'LIST' }],
    }),

    getAllCurriculums: build.query<
      Curriculum[],
      {
        education_lvl_father_id?: number;
        education_lvl_id?: number;
        mes_year?: number;
        subject_id?: number;
        modalityId?: string;
      }
    >({
      queryFn: async (
        { education_lvl_father_id, education_lvl_id, mes_year, subject_id, modalityId },
        { dispatch, getState }
      ) => {
        try {
          const {
            access: {
              currentProfile: { profileId },
            },
          } = getState() as { access: { currentProfile: { profileId: number } } };

          const { data: paginatedInfo } = await mainApi.get<Curriculum[]>('/curriculums', {
            params: {
              user_profile_id: profileId,
              data: 'all',
              ...(education_lvl_father_id && { education_lvl_father_id }),
              ...(education_lvl_id && { education_lvl_id }),
              ...(mes_year && { mes_year }),
              ...(subject_id && { subject_id }),
              ...(modalityId && { modality_id: modalityId }),
            },
          });

          return { data: Array.isArray(paginatedInfo) ? paginatedInfo : [] };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'Meshs', id } as const)),
              { type: 'Meshs', id: 'LIST' },
            ]
          : [{ type: 'Meshs', id: 'LIST' }],
    }),

    getAllCurriculumsFormated: build.query<
      CurriculumAll[],
      {
        education_lvl_father_id?: number;
        education_lvl_id?: number;
        mes_year?: number;
        subject_id?: number;
        modalityId?: string;
      }
    >({
      queryFn: async (
        { education_lvl_father_id, education_lvl_id, mes_year, subject_id, modalityId },
        { dispatch, getState }
      ) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = currentProfile?.profileId;

          const { data: paginatedInfo } = await mainApi.get<CurriculumAll[]>('/curriculums/all', {
            params: {
              user_profile_id,
              ...(education_lvl_father_id && { education_lvl_father_id }),
              ...(education_lvl_id && { education_lvl_id }),
              ...(mes_year && { mes_year }),
              ...(subject_id && { subject_id }),
              ...(modalityId && { modality_id: modalityId }),
            },
          });

          return { data: paginatedInfo };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) => [{ type: 'Meshs', id: 'LIST' }],
    }),

    getCurriculum: build.query<
      Curriculum2,
      {
        meshId: number;
      }
    >({
      queryFn: async ({ meshId }, { dispatch, getState }) => {
        try {
          const {
            access: {
              currentProfile: { profileId },
            },
          } = getState() as { access: { currentProfile: { profileId: number } } };

          const { data } = await mainApi.get<Curriculum2>('/curriculums/' + meshId, {
            params: {
              user_profile_id: profileId,
            },
          });

          dispatch(setCurriculumList([data]));

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) =>
        result ? [{ type: 'Meshs', id: '' }] : [{ type: 'Meshs', id: 'LIST' }],
    }),

    /**
     * @GET available curriculumns
     */
    getAvailablesCurriculums: build.query<Curriculum[], { profileId: number }>({
      queryFn: async ({ profileId }, { dispatch }) => {
        try {
          const { data } = await mainApi.get<Curriculum[]>('/curriculums', {
            params: {
              user_profile_id: profileId,
              data: 'all',
            },
          });

          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    /**
     * @GET curriculum by id, bring all info including subjects-curriculums
     */
    getMesh: build.query<Curriculum, { profileId: number; meshId: number }>({
      queryFn: async ({ profileId, meshId }) => {
        try {
          const { data } = await mainApi.get<Curriculum>(`/curriculums/${meshId}`, {
            params: {
              user_profile_id: profileId,
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: [
        { type: 'Curriculum', id: 'LIST' },
        { type: 'Meshs', id: 'LIST' },
      ],
    }),

    /**
     * @POST add new mesh
     */
    addMesh: build.mutation<
      CurriculumPostPayload,
      { profileId: number; meshPayload: Partial<CurriculumPostPayload> }
    >({
      queryFn: async ({ profileId, meshPayload }) => {
        try {
          const { data } = await mainApi.post('/curriculums', {
            user_profile_id: profileId,
            ...meshPayload,
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'Meshs', id: 'LIST' }],
    }),

    /**
     * @PUT update mesh
     */
    updateMesh: build.mutation<
      CurriculumPostPayload,
      { profileId: number; meshId: number; meshPayload: Partial<CurriculumPostPayload> }
    >({
      queryFn: async ({ profileId, meshId, meshPayload }) => {
        try {
          const { data } = await mainApi.put(`/curriculums/${meshId}`, {
            user_profile_id: profileId,
            ...meshPayload,
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'Meshs', id: 'LIST' }],
    }),

    /**
     * @DELETE delete mesh
     */
    deleteMesh: build.mutation<Curriculum, { profileId: number; meshId: number }>({
      queryFn: async ({ profileId, meshId }) => {
        try {
          const { data } = await mainApi.delete(`/curriculums/${meshId}`, {
            data: {
              user_profile_id: profileId,
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [
        { type: 'Curriculum', id: 'LIST' },
        { type: 'Meshs', id: 'LIST' },
      ],
    }),

    /**
     * @DELETE delete matter by mesh
     */
    deleteMatterMesh: build.mutation<MatterMesh, { profileId: number; matterMeshId: number }>({
      queryFn: async ({ profileId, matterMeshId }) => {
        try {
          const { data } = await mainApi.delete(`/subject-curriculum/${matterMeshId}`, {
            data: {
              user_profile_id: profileId,
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'Curriculum', id: 'LIST' }],
    }),

    /**
     * @POST create matter mesh
     */
    addMatterMesh: build.mutation<
      MatterMesh,
      { profileId: number; matterMeshPayload: Partial<MatterMesh> }
    >({
      queryFn: async ({ profileId, matterMeshPayload }) => {
        try {
          const { data } = await mainApi.post<MatterMesh>('/subject-curriculum', {
            user_profile_id: profileId,
            ...matterMeshPayload,
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'MatterMesh', id: 'MATTER_MESH_LIST' }],
    }),

    /**
     * @POST create matter mesh
     */
    assignSubjectCurriculum: build.mutation<
      MatterMesh,
      { profileId: number; subjectCurriculumAssginPayload: Partial<SubjectCurriculumAssignPayload> }
    >({
      queryFn: async ({ profileId, subjectCurriculumAssginPayload }) => {
        try {
          const { data } = await mainApi.post<MatterMesh>('/subject-curriculum', {
            user_profile_id: profileId,
            ...subjectCurriculumAssginPayload,
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'Curriculum', id: 'LIST' }],
    }),

    /**
     * @PUT update matter mesh
     */
    updateMatterMesh: build.mutation<
      MatterMesh,
      {
        profileId: number;
        matterMeshId: number;
        subjectCurriculumAssginPayload: Partial<SubjectCurriculumAssignPayload>;
      }
    >({
      queryFn: async ({ profileId, matterMeshId, subjectCurriculumAssginPayload }) => {
        try {
          const { data } = await mainApi.put(`/subject-curriculum/${matterMeshId}`, {
            user_profile_id: profileId,
            ...subjectCurriculumAssginPayload,
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'Curriculum', id: 'LIST' }],
    }),

    /**
     * @PUT update matter mesh
     */
    publishCurrciculum: build.mutation<
      MatterMesh,
      {
        profileId: number;
        meshId: number;
      }
    >({
      queryFn: async ({ profileId, meshId }) => {
        try {
          const { data } = await mainApi.put(`/curriculums/${meshId}/status`, {
            user_profile_id: profileId,
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'Curriculum', id: 'LIST' }],
    }),

    /**
     * @GET all symbology
     */
    getSymbologies: build.query<Simbology[], { profileId: number }>({
      queryFn: async ({ profileId }) => {
        try {
          const { data } = await mainApi.get<Simbology[]>('/curriculums/332', {
            params: {
              user_profile_id: profileId,
              data: 'all',
            },
          });

          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    /**
     * @GET all learning components
     */
    getLearningComponents: build.query<LearningComponent[], { profileId: number }>({
      queryFn: async ({ profileId }) => {
        try {
          const { data } = await mainApi.get<LearningComponent[]>('/components', {
            params: {
              user_profile_id: profileId,
              data: 'all',
            },
          });

          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    /**
     * @GET all curriculum status
     */
    getCurriculumStatus: build.query<CurriculumStatus[], { profileId: number }>({
      queryFn: async ({ profileId }) => {
        try {
          const { data } = await mainApi.get<CurriculumStatus[]>('/status', {
            params: {
              user_profile_id: profileId,
              search: 'mallas',
            },
          });

          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    /**
     * @GET all study modality
     */
    getStudyModalities: build.query<StudyModalityAvailable[], { profileId: number }>({
      queryFn: async ({ profileId }) => {
        try {
          const { data } = await mainApi.get<StudyModalityAvailable>('/catalogs/M/children', {
            params: {
              user_profile_id: profileId,
            },
          });

          // get first search result
          return { data: Array.isArray(data.children) ? data.children : [] };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    /**
     * @GET all availables homologations subjects
     */
    getExternalHomologationSubjects: build.query<
      MatterMesh[],
      { profileId: number; educationLevel: number }
    >({
      queryFn: async ({ profileId, educationLevel }) => {
        try {
          const { data } = await mainApi.get<MatterMesh[]>(
            `/education-levels/${educationLevel}/external-homologation-subject-curriculum`,
            {
              params: {
                user_profile_id: profileId,
                data: 'all',
              },
            }
          );

          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    /**
     * @GET list of homologate subjects
     */
    getInternalHomologateSubjects: build.query<
      HomologateInternalSubjectsResponse,
      {
        page: number;
        perPage: number;
        search: string;
        meshId: string;
        meshDestinyId?: number;
        careerDestinyId?: number;
        subjectDestinyId?: number;
      }
    >({
      queryFn: async (
        { page, perPage, search, meshId, meshDestinyId, careerDestinyId, subjectDestinyId },
        { getState }
      ) => {
        try {
          const {
            access: {
              currentProfile: { profileId },
            },
          } = getState() as { access: { currentProfile: { profileId: number } } };

          const { data: paginatedInfo } = await mainApi.get<HomologateInternalSubjectsResponse>(
            `/curriculums/internal-homologation`,
            {
              params: {
                user_profile_id: profileId,
                curriculumCurrent: meshId,
                search,
                page,
                size: perPage,
                sort: 'id',
                type_sort: 'asc',
                ...(meshDestinyId && { mesh_id: meshDestinyId }),
                ...(careerDestinyId && { education_level_id: careerDestinyId }),
                ...(subjectDestinyId && { subject_id: subjectDestinyId }),
              },
            }
          );

          // dispatch(setCurriculumList(paginatedInfo.data));

          return { data: paginatedInfo };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'HomologateInternalSubjects', id } as const)),
              { type: 'HomologateInternalSubjects', id: 'LIST' },
            ]
          : [{ type: 'HomologateInternalSubjects', id: 'LIST' }],
    }),

    /**
     * @GET all availables Internal homologations subjects by mesh
     */
    getInternalHomologationSubjectsbyMesh: build.query<
      MatterMesh[],
      { profileId: number; educationLevel: number; curriculumId: number }
    >({
      queryFn: async ({ profileId, educationLevel, curriculumId }) => {
        try {
          const { data } = await mainApi.get<MatterMesh[]>(
            `/education-levels/${educationLevel}/internal-homologation-subject-curriculum`,
            {
              params: {
                user_profile_id: profileId,
                curriculum_id: curriculumId,
                data: 'all',
              },
            }
          );

          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    /**
     * @DELETE delete internal homologate subject by mesh
     */
    deleteInternalHomologateSubject: build.mutation<
      MatterMesh,
      { profileId: number; internalHomologateSubjectId: number }
    >({
      queryFn: async ({ profileId, internalHomologateSubjectId }) => {
        try {
          const { data } = await mainApi.delete(
            `/internal-homologation/${internalHomologateSubjectId}`,
            {
              data: {
                user_profile_id: profileId,
              },
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'HomologateInternalSubjects', id: 'LIST' }],
    }),

    /**
     * @GET info from homologate subject
     */
    getInfoInternalHomologateSubject: build.query<
      infoInternalHomologateSubject,
      { profileId: number; subjectHomologateId: number }
    >({
      queryFn: async ({ profileId, subjectHomologateId }) => {
        try {
          const { data } = await mainApi.get<infoInternalHomologateSubject>(
            `/internal-homologation/${subjectHomologateId}`,
            {
              params: {
                user_profile_id: profileId,
              },
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    /**
     * @GET
     */
    getIfEducationLevelIsAlreadyVigent: build.query<any, { educationLevelId: number }>({
      queryFn: async ({ educationLevelId }, { getState }) => {
        try {
          const {
            access: {
              currentProfile: { profileId },
            },
          } = getState() as { access: { currentProfile: { profileId: number } } };

          const { data } = await mainApi.get<any>(
            `/curriculums/education-level/${educationLevelId}/vigent`,
            {
              params: {
                user_profile_id: profileId,
              },
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    /**
     * @GET all curriculums to table or grid view
     */
    getCurriculumsSubjects: build.query<
      Matter[],
      {
        curriculumId: number;
        tmAcronym?: string;
      }
    >({
      queryFn: async ({ curriculumId, tmAcronym }, { getState }) => {
        try {
          const {
            access: {
              currentProfile: { profileId },
            },
          } = getState() as { access: { currentProfile: { profileId: number } } };

          const { data } = await mainApi.get<Matter[]>(`/curriculums/${curriculumId}/subjects`, {
            params: {
              user_profile_id: profileId,
              data: 'all',
              ...(tmAcronym && { tm_acronym: tmAcronym }),
            },
          });

          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) => [
        { type: 'Meshs', id: 'LIST' },
        { type: 'MattersAvailables', id: 'COMBO_MATTERS_LIST' },
      ],
    }),

    //
  }),
});

export const {
  useGetMeshQuery,
  useLazyGetCurriculumQuery,
  // useGetMattersQuery,
  useGetSymbologiesQuery,
  useAddMeshMutation,
  useAddMatterMeshMutation,
  useUpdateMeshMutation,
  useUpdateMatterMeshMutation,
  useDeleteMeshMutation,
  useDeleteMatterMeshMutation,
  useGetStudyModalitiesQuery,
  useGetLearningComponentsQuery,
  useGetAvailablesCurriculumsQuery,
  useAssignSubjectCurriculumMutation,
  useGetCurriculumStatusQuery,
  useGetCurriculumsQuery,
  useLazyGetAllCurriculumsQuery,
  usePublishCurrciculumMutation,
  useGetExternalHomologationSubjectsQuery,
  useGetInternalHomologateSubjectsQuery,
  useGetInternalHomologationSubjectsbyMeshQuery,
  useDeleteInternalHomologateSubjectMutation,
  useGetInfoInternalHomologateSubjectQuery,
  useGetIfEducationLevelIsAlreadyVigentQuery,
  useGetCurriculumsSubjectsQuery,
  useLazyGetAllCurriculumsFormatedQuery,
} = meshsApi;
