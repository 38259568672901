import { mainApi } from '../../../../../../api/mainApi';
import { middlewareApi } from '../../../../../middleware/middlewareApi';
import { IResponse } from '../../../../../shared/interfaces/response.interface';
import { TemplateEvaData, TemplateEvaPayload } from '../interfaces/TemplateEva.interface';

export const TemplateEvaTags = middlewareApi.enhanceEndpoints({
  addTagTypes: ['TemplateEva'],
});

export const templateEvaApi = TemplateEvaTags.injectEndpoints({
  endpoints: (builder) => ({
    getTemplateEvaList: builder.query<
      IResponse<TemplateEvaData>,
      {
        page: number;
        perPage: number;
        search: string;
        sort?: string;
        typeSort?: string;
        start_date?: string;
        end_date?: string;
        eva_type_id?: string;
      }
    >({
      queryFn: async (
        { page, perPage, search, sort, typeSort, end_date, start_date, eva_type_id },
        { dispatch }
      ) => {
        try {
          const { data } = await mainApi.get<IResponse<TemplateEvaData>>('/eva/evaluations', {
            params: {
              page,
              size: perPage,
              search,
              start_date,
              end_date,
              ...(sort && { sort }),
              ...(typeSort && { type_sort: typeSort }),
              ...(eva_type_id && { eva_type_id }),
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: [{ type: 'TemplateEva', id: 'LIST' }],
    }),

    getTemplateEvaListAll: builder.query<TemplateEvaData[], {}>({
      queryFn: async () => {
        try {
          const { data } = await mainApi.get<TemplateEvaData[]>('/eva/evaluations?data=all');

          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    getTemplateEva: builder.query<TemplateEvaData, { id: string }>({
      queryFn: async ({ id }, { dispatch }) => {
        try {
          const { data } = await mainApi.get<TemplateEvaData>(`/eva/evaluations/${id}`);

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) =>
        result ? [{ type: 'TemplateEva', id: result.id }] : [{ type: 'TemplateEva', id: 'LIST' }],
    }),

    getTemplateEvaById: builder.query<TemplateEvaData, { id: string }>({
      queryFn: async ({ id }, { dispatch }) => {
        try {
          const { data } = await mainApi.get<TemplateEvaData>(`/eva/evaluations/get-evaluation`, {
            params: {
              id,
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    addTemplateEvaluation: builder.mutation<any, TemplateEvaPayload>({
      queryFn: async (body, { dispatch }) => {
        try {
          const { data } = await mainApi.post<any>('/eva/evaluations', body);
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: ['TemplateEva'],
    }),
    editTemplateEvaluation: builder.mutation<
      any,
      {
        payload: TemplateEvaPayload;
        id: string | number;
      }
    >({
      queryFn: async ({ payload, id }, { dispatch }) => {
        try {
          const { data } = await mainApi.put<any>(`/eva/evaluations/${id}`, payload);
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: ['TemplateEva'],
    }),
  }),
});

export const {
  useGetTemplateEvaListQuery,
  useAddTemplateEvaluationMutation,
  useGetTemplateEvaListAllQuery,
  useGetTemplateEvaQuery,
  useLazyGetTemplateEvaQuery,
  useLazyGetTemplateEvaByIdQuery,
  useEditTemplateEvaluationMutation,
} = templateEvaApi;
