export enum ApplicationsType {
  CAMCAR = 'CAMCAR',
  HOMEXT = 'HOMEXT',
  INGPRA = 'INGPRA',
  REQDOC = 'REQDOC',
  EXMATR = 'EXMATR',
  EXMREC = 'EXMREC',
  RETMAT = 'RETMAT',
  RECNOT = 'RECNOT',
  REGTMC = 'REGTMC',
  OTROS = 'OTROS',
  INGVIN = 'INGVIN',
  INGTIT = 'INGTIT',
  CONPAG = 'CONPAG',
}
