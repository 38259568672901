import PersonCreateModal from '../components/PersonCreateModal';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import { openModalAddUser } from '../../../../maintenance/students/slices/studentsSlice';

const PersonCreateScreen = function () {

    const dispatch = useAppDispatch();

    const { studentAddUser } = useAppSelector((state) => state.students);

    const handleClose = async () => {
        await dispatch(openModalAddUser(false));
    };

    return (
        <PersonCreateModal
            open={studentAddUser}
            onClose={handleClose}
        />
    );
};

export default PersonCreateScreen;
