import {
  IconGizmo,
  IconSchool,
  IconHierarchy,
  IconArticle,
  IconFileSpreadsheet,
  IconFileSearch,
} from '@tabler/icons';

import { shared } from '../shared';

// Icon Group
const icons = {
  IconGizmo,
  IconSchool,
  IconHierarchy,
  IconArticle,
  IconFileSpreadsheet,
  IconFileSearch,
};

export const communityOutreach = {
  id: 'vinculacion',
  type: 'group',
  title: 'communityOutreach.menu.title',
  children: [
    // {
    //   id: 'administrar-lineas-de-investigacion',
    //   title: 'communityOutreach.menu.resaerch-lines',
    //   menuId: 'communityOutreach.menu.resaerch-lines',
    //   type: 'item',
    //   url: '/administrar-lineas-de-investigacion/lineas-de-proyecto-de-investigacion',
    //   permissionKey: 'vinculacion',
    //   icon: icons['IconGizmo'],
    // },
    // {
    //   id: 'publicaciones',
    //   title: 'communityOutreach.menu.publications',
    //   menuId: 'communityOutreach.menu.publications',
    //   type: 'item',
    //   url: '/publicaciones',
    //   permissionKey: 'vinculacion',
    //   icon: icons['IconArticle'],
    // },

    {
      id: 'administration-vinculacion',
      title: 'Administración',
      menuId: 'Administración',
      type: 'collapse',
      url: '/administrar-lineas-de-investigacion/proyectos',
      permissionKey: 'vinculacion',
      icon: icons['IconFileSpreadsheet'],
      children: [
        {
          id: 'proyectos',
          title: 'Proyectos',
          menuId: 'Proyectos',
          type: 'item',
          url: '/administrar-lineas-de-investigacion/proyectos',
          permissionKey: 'vinculacion',
        },
        {
          id: 'seguimiento-proyecto-investigacion',
          title: 'communityOutreach.menu.research-project-follow-up',
          menuId: 'communityOutreach.menu.research-project-follow-up',
          type: 'item',
          url: '/seguimiento-proyecto-investigacion',
          permissionKey: 'vinculacion',
        },
      ],
    },
    // shared menu
    ...shared,
  ],
};
