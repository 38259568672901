import { FormHelperText, Grid, TextField, Typography, Divider } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { useState } from 'react';
import { PeriodForm } from '../../../interfaces/period-form.interfaces';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import esLocale from 'date-fns/locale/es';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import DateRangePicker, { DateRange } from '@material-ui/lab/DateRangePicker';
import { REGISTRO_MATERIAS_PORTAL_ESTUDIANTIL } from '../../../constants/stages';
import { useTranslation } from 'react-i18next';
import InfoAlert from '../../../../../shared/components/alerts/InfoAlert';

const RegisterMatterStudentPortal = () => {
  const { t } = useTranslation();

  const { errors, touched, values, setFieldValue } = useFormikContext<PeriodForm>();

  const [date, setDate] = useState<DateRange<Date>>([
    values.register_matter_student_portal.start_date!,
    values.register_matter_student_portal.end_date!,
  ]);

  const onChangeDates = (newDatesValues: DateRange<Date>) => {
    if (values.register_matter_student_portal.stg_acronym === '') {
      const object = {
        course_id: null,
        education_level_id: null,
        parallel_id: null,
        start_date: null,
        end_date: null,
        status_id: 1,
        stg_acronym: REGISTRO_MATERIAS_PORTAL_ESTUDIANTIL,
      };
      setFieldValue('register_matter_student_portal', object);
    }

    const [start, end] = newDatesValues;
    if (start !== null) {
      setFieldValue('register_matter_student_portal.start_date', start);
    }

    if (end !== null) {
      setFieldValue('register_matter_student_portal.end_date', end);
    }
    setDate(newDatesValues);
  };

  return (
    <Grid container spacing={4} justifyContent="center" alignItems="flex-start">
      <Grid item xs={12} md={12} container spacing={2}>
        <Grid item xs={12} marginTop={2}>
          <InfoAlert message={t('academic.periods_list.add.stage_3.tab_3.date.requerided')} />
          <Divider
            light
            style={{ width: '100%', marginTop: 20, marginBottom: 20, borderRadius: 2 }}
          />
          <Typography variant="h3" component="div">
            {t('academic.periods_list.add.stage_3.tab_3.title')}
          </Typography>
          <Grid item xs={12} sx={{ mt: 5 }}>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
              <DateRangePicker
                startText={null}
                endText={null}
                value={date}
                onChange={onChangeDates}
                renderInput={(startProps, endProps) => (
                  <Grid container spacing={10} alignItems="center">
                    <Grid item xs={12} md={6} lg={6}>
                      <TextField
                        fullWidth
                        {...startProps}
                        helperText=""
                        error={Boolean(
                          errors.register_matter_student_portal?.start_date &&
                            touched.register_matter_student_portal?.start_date
                        )}
                      />
                      <FormHelperText>
                        {t('academic.periods_list.add.stage_3.tab_3.start.helper')}
                      </FormHelperText>

                      {errors.register_matter_student_portal?.start_date &&
                        touched.register_matter_student_portal?.start_date && (
                          <FormHelperText error>
                            {errors.register_matter_student_portal?.start_date}
                          </FormHelperText>
                        )}
                    </Grid>

                    <Grid item xs={12} md={6} lg={6}>
                      <TextField
                        fullWidth
                        {...endProps}
                        helperText=""
                        error={Boolean(
                          errors.register_matter_student_portal?.end_date &&
                            touched.register_matter_student_portal?.end_date
                        )}
                      />
                      <FormHelperText>
                        {t('academic.periods_list.add.stage_3.tab_3.end.helper')}
                      </FormHelperText>
                      {errors.register_matter_student_portal?.end_date &&
                        touched.register_matter_student_portal?.end_date && (
                          <FormHelperText error>
                            {errors.register_matter_student_portal?.end_date}
                          </FormHelperText>
                        )}
                    </Grid>
                  </Grid>
                )}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RegisterMatterStudentPortal;
