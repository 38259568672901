import { useState } from 'react';
import { useAppSelector } from '../../../../../hooks/useRedux';
import StudentAsignedCouselingTable from './tables/StudentAsignedCouselingTable';
import { useGetStudentsAsignedQuery } from '../slices/studentsAsignedApiSlice';
import { StudentAsignedDataSearch } from '../interfaces/students-asigned.interfaces';
import { useTranslation } from 'react-i18next';
import { useTableSearch } from '../../../../../hooks/useTableSearch';
import { updateStudentAsignedTable } from '../slices/studentsAsignedSlice';

interface Props {
  // id del colaborador por si no se quiere usar el colaborador autenticado
  collaboratorId?: number;
}

const StudentsAsignedList = function ({ collaboratorId }: Props) {
  const { t } = useTranslation();

  const { page, perPage, search, filterArrow } = useAppSelector(
    (state) => state.studentsAsigned.studentsAssignedTable
  );

  const DataSearchBlank: StudentAsignedDataSearch = {
    periodId: 0,
    educationLevelId: 0,
    academic: 0,
  };

  const { setSearch, setPage, setPerPage } = useTableSearch({
    search,
    filterArrow,
    page,
    perPage,
    updateFunction: updateStudentAsignedTable,
  });

  const [dataSearch, setDataSearch] = useState<StudentAsignedDataSearch>(DataSearchBlank);

  const { currentProfile } = useAppSelector((state) => state.access);

  const { data, isLoading, isFetching, error } = useGetStudentsAsignedQuery(
    {
      page: page,
      perPage: perPage,
      profileId: currentProfile?.profileId!,
      search: search,
      offer_id: dataSearch.educationLevelId === 0 ? undefined : dataSearch.educationLevelId,
      education_level_id: dataSearch.academic === 0 ? undefined : dataSearch.academic,
      period_id: dataSearch.periodId === 0 ? undefined : dataSearch.periodId,
      collaborator_id: collaboratorId,
    }
    // { refetchOnMountOrArgChange: true }
  );

  return (
    <StudentAsignedCouselingTable
      data={data!}
      searchPlacehoder={t('teachers.academic_counseling.students_assigned.search')}
      isLoading={isLoading}
      isFetching={isFetching}
      error={error}
      perPage={perPage}
      setPerPage={setPerPage}
      total={data?.total! || 0}
      page={page}
      setPage={setPage}
      search={search}
      setSearch={setSearch}
      dataSearch={dataSearch}
      setDataSearch={setDataSearch}
    />
  );
};

export default StudentsAsignedList;
