import { useState, useMemo } from 'react';
import {
  Grid,
  IconButton,
  Tooltip,
  Dialog,
  Typography,
  Paper,
  Checkbox,
  LinearProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { renderSkeletonTable } from '../../../shared/helpers/render';
import PerfectScrollbar from 'react-perfect-scrollbar';
//import SimpleTableCollapsible from '../../../shared/components/tables/SimpleTableCollapsible';
import IconViewList from '@material-ui/icons/RemoveRedEye';

import { IconTableImport } from '@tabler/icons';
import ItemAccordeon from './Components/ItemAccordeon';
import SubCard from '../../../shared/components/cards/SubCard';
import { useAppSelector } from '../../../../hooks/useRedux';
import {
  useGetContentMaterialsOldQuery,
  useImportMaterialsOldMutation,
} from '../slices/virtualClassroomTeacherApiSlice';
import { useTranslation } from 'react-i18next';

import { LoadingButton } from '@material-ui/lab';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import { ContentMaterialsOld } from '../interfaces/ContentMaterialsOld';
import { useHistory } from 'react-router-dom';
import SearchPaginatedTable from '../../../shared/components/tables/SearchPaginatedTable';
import { controlSelectionArr } from '../../../shared/helpers';

const useStyles = makeStyles((theme) => ({
  paper: { maxWidth: '900px', width: '100%' },
}));

const HeaderDialog: React.FC<{ nameCampo: string | undefined; valuecampo: string | undefined }> = ({
  nameCampo,
  valuecampo,
}) => {
  return (
    <Grid item xs={4} justifyContent="center" textAlign={'center'}>
      <Grid justifyContent="center" item textAlign={'center'} container>
        <Typography variant="body1" fontWeight={'bold'}>
          {nameCampo}:
        </Typography>{' '}
        <Typography variant="body1">{valuecampo}</Typography>
      </Grid>
    </Grid>
  );
};

const DialogComponent: React.FC<{
  open: { data: ContentMaterialsOld | null; modal: boolean };
  setOpen: React.Dispatch<
    React.SetStateAction<{
      data: ContentMaterialsOld | null;
      modal: boolean;
    }>
  >;
}> = ({ open, setOpen }) => {
  const History = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();

  const [selected, setSelected] = useState<number[]>(
    open.data?.course_activities?.map((dt) => dt.activity.id) || []
  );

  const {
    course: { courseId },
  } = useAppSelector((state) => state.virtualClassroomTeacherShared);
  const {
    userInfo: { collaboratorInfo },
  } = useAppSelector((state) => state.auth);

  const [showAlerts, setShowAlerts] = useState(false);

  const [importMaterial, { isLoading, isSuccess, isError, error }] =
    useImportMaterialsOldMutation();

  async function importar() {
    try {
      const directorios = open.data?.course_activities
        ?.map((item) => item.activity.id)
        .filter((dt) => selected.some((sel) => sel === dt));

      await importMaterial({
        collaboratorID: collaboratorInfo?.id!,
        PayloadImportMaterials: {
          courseId: courseId!,
          directories: directorios!,
        },
      });

      setTimeout(() => {
        History.goBack();
      }, 3000);
    } catch (error) {}

    setShowAlerts(true);
  }

  const handleClick = (event: React.ChangeEvent<HTMLInputElement>, actId: number) => {
    const newSelected = controlSelectionArr(selected, actId);

    setSelected(newSelected);
  };

  return (
    <>
      <Dialog
        classes={{ paper: classes.paper }}
        open={open.modal}
        onClose={() => setOpen({ ...open, modal: false })}
        sx={{ p: 8 }}
      >
        <Grid container justifyContent="center" textAlign={'center'}>
          <Grid item xs={12}>
            <Typography variant="h3" color="primary">
              {t('teacherVirtualClassroom.materials.modal.file.input.detail')}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ p: 2 }}>
            <Grid container justifyContent={'space-between'} mt={2} xs={12}>
              <HeaderDialog
                nameCampo={t('teacherVirtualClassroom.materials.imports.matter')}
                valuecampo={open.data?.matter.mat_description}
              />
              <HeaderDialog
                nameCampo={t('teacherVirtualClassroom.materials.imports.paralelo')}
                valuecampo={`${open?.data?.classroom?.cl_name} - ${open.data?.parallel?.par_description}`}
              />
              <HeaderDialog
                nameCampo={t('teacherVirtualClassroom.materials.imports.periodo')}
                valuecampo={open.data?.period?.per_name}
              />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Paper elevation={1} sx={{ m: '10px 15px', p: '5px 12px' }}>
              <Grid container alignItems="center">
                {open.data?.course_activities?.map((item) => {
                  return (
                    <>
                      <Grid item xs={1}>
                        <Checkbox
                          checked={selected.some((r) => r === Number(item.activity.id))}
                          onChange={(e) => handleClick(e, item.activity.id)}
                        />
                      </Grid>

                      <Grid item xs={11}>
                        <ItemAccordeon
                          nameFolder={item.activity.act_description}
                          childrenfiles={item.activity.tools_materials}
                          subCarpetas={item.activity.children_content} //tools_materials
                          id={item.activity.id}
                          actions={false}
                        />
                      </Grid>
                    </>
                  );
                })}
              </Grid>
            </Paper>
          </Grid>

          {showAlerts && (
            <Grid item xs={12} p={2}>
              {isSuccess && (
                <>
                  <SuccessAlert
                    message="Importado correctamente, será redirigido a los materiales."
                    handleDismiss={() => setShowAlerts(false)}
                  />
                  <LinearProgress />
                </>
              )}
              {isError && <ErrorAlert message={error as string} />}
            </Grid>
          )}
          <Grid item xs={12} m={'40px 0 '}>
            <LoadingButton
              style={{ margin: '0 5px' }}
              loading={isLoading}
              loadingIndicator="regresar"
              variant="outlined"
              onClick={() => setOpen({ ...open, modal: false })}
            >
              {t('teacherVirtualClassroom.materials.modal.button.regresar')}
            </LoadingButton>
            <LoadingButton
              endIcon={<IconTableImport />}
              onClick={importar}
              loading={isLoading}
              disabled={!selected.length}
              variant="contained"
              loadingIndicator="Importando ..."
            >
              {t('teacherVirtualClassroom.materials.create.import')}
            </LoadingButton>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

const ListMaterials = () => {
  const { t } = useTranslation();

  const {
    course: { courseId },
  } = useAppSelector((state) => state.virtualClassroomTeacherShared);

  const { course } = useAppSelector((state) => state.virtualClassroomTeacherShared);

  const {
    userInfo: { collaboratorInfo },
  } = useAppSelector((state) => state.auth);

  //const apiUrl = localStorage.getItem('apiUrl');
  const { data, isLoading, isFetching, error } = useGetContentMaterialsOldQuery(
    {
      collaboratorID: collaboratorInfo?.id!,
      subject: course.MatterId,
    },
    {
      skip: courseId === 0 || courseId === undefined,
      refetchOnMountOrArgChange: 30,
    }
  );
  /*
  if (isFetching === false && data && data?.length < 1) {
    return <Typography>No hay Importaciones</Typography>;
  }*/

  const datafetch = useMemo(
    () =>
      data?.map((value) => ({
        id: value.id,
        act_description: value.matter.mat_name,
        // tool_ms_url_file: value.tool_ms_url_file,
        options: '',
        data: value,
      })) || [],
    [data]
  );

  const [open, setOpen] = useState<{ data: ContentMaterialsOld | null; modal: boolean }>({
    data: null,
    modal: false,
  });

  const [Page, setPage] = useState(5);
  const [Search, setSearch] = useState('');

  return (
    <Grid style={{ marginLeft: 40, marginTop: 30, marginRight: 40 }}>
      <PerfectScrollbar>
        {isLoading ? (
          renderSkeletonTable(3, 3)
        ) : (
          <SubCard title={t('teacherVirtualClassroom.materials.imports.listofmaterials')} darkTitle>
            <SearchPaginatedTable
              data={datafetch || []}
              headers={{
                id: t('teacherVirtualClassroom.materials.imports.listofmaterials.codigo'),
                act_description: t(
                  'teacherVirtualClassroom.materials.imports.listofmaterials.descripcion'
                ),
                options: t('teacherVirtualClassroom.materials.imports.listofmaterials.opciones'),
                data: '',
              }}
              numHeader={4}
              page={1}
              perPage={Page || 10}
              setPage={setPage}
              setPerPage={setPage}
              isLoading={true}
              isFetching={isFetching}
              ActionButtons={<></>}
              error={error}
              searchPlacehoder={'flase'}
              total={10}
              showFilter={false}
              search={Search}
              setSearch={setSearch}
              keyExtractor={(item) => `${item.id}`}
              customRenderers={{
                options: (row) => (
                  <>
                    {console.log(row)}
                    <Tooltip title={t('teacherVirtualClassroom.materials.imports.tooltips.ver')}>
                      <IconButton
                        aria-label="Ver"
                        color="secondary"
                        onClick={
                          () => setOpen({ data: row.data, modal: true })
                          /**
                *            window.open(
                            `${getProtocol()}://${apiUrl}/api/show?name=${row.options}`,
                            '_blank'
                          )
                */
                        }
                      >
                        <IconViewList sx={{ fontSize: '1.3rem' }} />
                      </IconButton>
                    </Tooltip>
                    {/*
                    <Tooltip title={t('teacherVirtualClassroom.materials.imports.tooltips.import')}>
                      <IconButton
                      aria-label="Ver"
                      color="secondary"
                      onClick={() => setOpen({ data: row.data, modal: true })}
                      >
                        <IconTableImport />
                      </IconButton>
                    </Tooltip>
                      */}
                  </>
                ),
                data: (row) => null,
              }}
              customDataCellsProperties={{
                id: {
                  align: 'center',
                },
                options: {
                  align: 'center',
                },

                act_description: {
                  align: 'center',
                },
                data: {
                  align: 'center',
                },
              }}
              customHeadersCellsProperties={{
                id: {
                  align: 'center',
                },
                options: {
                  align: 'center',
                },
                act_description: {
                  align: 'center',
                  width: '30%',
                },
              }}
            />
          </SubCard>
        )}
      </PerfectScrollbar>

      <DialogComponent open={open} setOpen={setOpen} />
    </Grid>
  );
};

export default ListMaterials;
