import { memo, useState, useEffect } from 'react';
// material-ui
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  ListItem,
  ListItemAvatar,
  // ListItemSecondaryAction,
  // ListItemText,
  Stack,
  Typography,
} from '@material-ui/core';
import { formatRelative, parseISO } from 'date-fns';
import { useHistory } from 'react-router-dom';

import { useGetLocaleDateLang } from '../../../../../../translation/hooks/useGetLocaleDateLang';
import { Notification } from '../../../../interfaces/notifications.interface';
import { useReadNotificationMutation } from '../../../../slices/notificationsApi';
import useGoToVirtualClassroom from '../../../../../students/academic-portfolio/hooks/useGoToVirtualClassroom';
import Loader from '../../../../components/Loader';
import { useAppSelector } from '../../../../../../hooks/useRedux';
import { returnNewUrlVc } from './helpers';
import Avatar from '../../../../components/extended/Avatar';
// import { setCurrentVirtualClassroom } from '../../../../reducers/accessReducer';
// import { resetVirtualClassroomTeacherState } from '../../../../../teachers/virtual-classroom/slices/virtualClassroomTeacherSlice';
// import { resetVirtualClassroomStudent } from '../../../../../students/virtual-classroom/slices/virtualClassroomSlice';

// style constant
const useStyles = makeStyles((theme) => ({
  listAction: {
    top: '22px',
  },
  actionColor: {
    color: theme.palette.grey[500],
  },

  listItem: {
    padding: 0,
  },
  sendIcon: {
    marginLeft: '8px',
    marginTop: '-3px',
  },
  listDivider: {
    marginTop: 0,
    marginBottom: 0,
  },
  listChipError: {
    color: theme.palette.orange.dark,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.orange.light,
    height: '24px',
    padding: '0 6px',
    marginRight: '5px',
  },
  listChipWarning: {
    color: theme.palette.warning.dark,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.warning.light,
    height: '24px',
    padding: '0 6px',
  },
  listChipSuccess: {
    color: theme.palette.success.dark,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.success.light,
    height: '24px',
    padding: '0 6px',
  },
  listAvatarSuccess: {
    color: theme.palette.success.dark,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.success.light,
    border: theme.palette.mode === 'dark' ? '1px solid' : 'none',
    borderColor: theme.palette.success.main,
  },
  listAvatarPrimary: {
    color: theme.palette.primary.dark,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
    border: theme.palette.mode === 'dark' ? '1px solid' : 'none',
    borderColor: theme.palette.primary.main,
  },
  listContainer: {
    paddingLeft: '56px',
  },
  uploadCard: {
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
  },
  paddingBottom: {
    paddingBottom: '16px',
  },
  itemAction: {
    cursor: 'pointer',
    padding: '16px',
    '&:hover': {
      background:
        theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
    },
  },
  itemActionUnread: {
    cursor: 'pointer',
    padding: '16px',
    '&:hover': {
      background:
        theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
    },
    backgroundColor: theme.palette.warning.light,
  },
}));

interface Props {
  notification: Notification;

  handleClose: () => void;
}

const NotificationItem = ({ notification, handleClose }: Props) => {
  const classes = useStyles();
  const history = useHistory();

  const { lang } = useGetLocaleDateLang();

  const [valueCourseId, setValueCourseId] = useState(0);
  const [routePath, setRoutePath] = useState('');

  const { currentProfile } = useAppSelector((state) => state.access);

  const { isFetching: isFetchingGoToAct } = useGoToVirtualClassroom(
    +valueCourseId,
    `${routePath}`,
    currentProfile?.keyword === 'docente' ? true : false
  );

  useEffect(() => {
    if (!isFetchingGoToAct && valueCourseId) {
      setRoutePath('');
      setValueCourseId(0);
      handleClose();
    }
  }, [handleClose, isFetchingGoToAct, valueCourseId]);

  //   const isFetchingGoToAct = false;

  const [readNotification, { isLoading }] = useReadNotificationMutation();

  const handleAction = async (item: Notification) => {
    const isInVirtualClass =
      currentProfile?.keyword === 'docente'
        ? localStorage.getItem('virtualClassroomTeacher')
        : localStorage.getItem('virtualClassroomStudent');

    if (isLoading || isFetchingGoToAct) return;

    if (!item.rec_date_read) readNotification({ announcementId: +item.announcement.id });

    if (item.announcement.course_id && item.announcement.url) {
      const newUrl = returnNewUrlVc(item.announcement.url);

      setRoutePath(newUrl!);

      if (isInVirtualClass) {
        history.push(`${newUrl}`);
        return handleClose();
      }

      setRoutePath(newUrl);
      return setValueCourseId(+item?.announcement?.course_id!);
    }

    // dispatch(resetVirtualClassroomTeacherState());
    // dispatch(resetVirtualClassroomStudent());
    // dispatch(setCurrentVirtualClassroom(false));

    // localStorage.removeItem('virtualClassroomTeacher');
    // localStorage.removeItem('virtualClassroomStudent');

    // console.log(`${path}/${item.announcement.url}`);

    // console.log(history.push(`${path}/${item.announcement.url}`));

    history.push(`/${item.announcement.url}`);
    return handleClose();
  };

  return (
    <>
      {(isLoading || isFetchingGoToAct) && <Loader />}
      <div
        className={notification.rec_date_read ? classes.itemAction : classes.itemActionUnread}
        onClick={() => handleAction(notification)}
      >
        <ListItem alignItems="center" className={classes.listItem}>
          <ListItemAvatar
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              alignSelf: 'center',
            }}
          >
            {notification.rec_date_read && (
              <Avatar sx={{ bgcolor: 'primary', width: '0.7em', height: '0.7em' }}>&nbsp;</Avatar>
            )}
          </ListItemAvatar>
          <Stack direction="row" justifyContent="space-between" spacing={2} sx={{ width: '100%' }}>
            <Grid item>
              <Typography variant="subtitle1">
                {notification.announcement.user.person.pers_full_name}
              </Typography>
            </Grid>

            <Grid item>
              <Typography
                variant="caption"
                display="block"
                gutterBottom
                className={classes.actionColor}
              >
                {formatRelative(parseISO(notification.announcement.ans_date_send), new Date(), {
                  locale: lang,
                })}
              </Typography>
            </Grid>
          </Stack>
        </ListItem>
        <Grid container direction="column" className={classes.listContainer}>
          <Grid item xs={12} className={classes.paddingBottom}>
            <Typography variant="subtitle2" /* maxWidth={'240px'} */>
              {notification.announcement.ans_message}
            </Typography>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default memo(NotificationItem);
