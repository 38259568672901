import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISearchTable } from '../../../shared/interfaces/paginatedTable.interface';
import { PosgraduatePlanningHourhand } from '../interfaces/posgraduatePlanningHourhands.interface';

interface PosgraduatePlanningHourhandsState {
  posgraduatePlanningHourhandsList: PosgraduatePlanningHourhand[];
  posgraduatePlanningHourhandsTable: ISearchTable;
  posgraduatePlanningHourhandIdSelected: string | null;
  posgraduatePlanningHourhandDeleteDialogOpen: boolean;

  modalityIdSelected: number | null;
}

const initialState: PosgraduatePlanningHourhandsState = {
  posgraduatePlanningHourhandsList: [],
  posgraduatePlanningHourhandsTable: {
    page: 1,
    perPage: 5,
    search: '',
  },
  posgraduatePlanningHourhandIdSelected: null,
  posgraduatePlanningHourhandDeleteDialogOpen: false,

  modalityIdSelected: null,
};

const posgraduatePlanningHourhandsSlice = createSlice({
  name: 'posgraduatePlanningPosgraduate',
  initialState,
  reducers: {
    setPosgraduatePlanningHourhandsList: (state, action: PayloadAction<any[]>) => {
      state.posgraduatePlanningHourhandsList = action.payload;
    },
    setPosgraduatePlanningHourhandsTable: (state, action: PayloadAction<ISearchTable>) => {
      state.posgraduatePlanningHourhandsTable = action.payload;
    },
    setPosgraduatePlanningHourhandIdSelected: (state, action: PayloadAction<string | null>) => {
      state.posgraduatePlanningHourhandIdSelected = action.payload;
    },
    setPosgraduatePlanningHourhandDeleteDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.posgraduatePlanningHourhandDeleteDialogOpen = action.payload;
    },

    setModalityIdSelected: (state, action: PayloadAction<number | null>) => {
      state.modalityIdSelected = action.payload;
    },
  },
});

export const {
  setPosgraduatePlanningHourhandsList,
  setPosgraduatePlanningHourhandsTable,
  setPosgraduatePlanningHourhandIdSelected,
  setPosgraduatePlanningHourhandDeleteDialogOpen,
  setModalityIdSelected,
} = posgraduatePlanningHourhandsSlice.actions;

export default posgraduatePlanningHourhandsSlice.reducer;
