import { Grid, Autocomplete, TextField } from '@material-ui/core';
import { useState } from 'react';
import { useAppSelector } from '../../../../hooks/useRedux';
import { useGetAvailablesGroupAreasQuery } from '../../groups-area/slices/groupAreasApiSlice';
import {
  useGetStudentsNivelationCoursesQuery,
  useGetCareersByGroupIdQuery,
} from '../slices/TrackingNivelationApiSlice';
import { FilterArrow } from '../../../shared/components/tables/SearchPaginatedTable';
import SkeletonList from './skeletons/SkeletonList';
import DynamicTable from './DynamicTable';
import SelectionMessage from './SelectionMessage';

const ListStudents = () => {
  const { currentProfile } = useAppSelector((state) => state.access);

  const [grupoId, setGrupoId] = useState<number | undefined>(undefined); //1
  const [carreraId, setCarreraId] = useState<number>();

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [perPage, setPerPage] = useState(5);
  const [filterArrow, setFilterArrow] = useState<FilterArrow>();

  //Grupos
  const { isLoading: loadingGrupos, data: grupos } = useGetAvailablesGroupAreasQuery(
    {
      profileId: currentProfile?.profileId!,
    },
    { refetchOnMountOrArgChange: true }
  );

  //carreras
  const {
    isLoading: loadingCA,
    data: carreras,
    isFetching: fetchingCA,
  } = useGetCareersByGroupIdQuery(
    {
      profileId: currentProfile?.profileId!,
      groupAreaSubject: grupoId,
    },
    { refetchOnMountOrArgChange: 30, skip: !grupoId }
  );

  //lista estudiantes
  const {
    data: students,
    isLoading: loadingST,
    isFetching: fetchingST,
    isError,
    error,
  } = useGetStudentsNivelationCoursesQuery(
    {
      groupAreaSubject: grupoId,
      educationLevel: carreraId,
      page,
      size: perPage,
      profileId: currentProfile?.profileId!,
      search,
      ...(filterArrow && { sort: filterArrow.name, type_sort: filterArrow.direction }),
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !grupoId,
    }
  );

  return (
    <>
      <Grid container>
        <Grid item sm={12} md={6} sx={{ p: 1 }}>
          <Autocomplete
            options={grupos! || []}
            loading={loadingGrupos}
            loadingText="Cargando grupos..."
            getOptionLabel={(option) => option.arg_name}
            renderInput={(params) => <TextField {...params} label="Grupo de Nivelación" />}
            defaultValue={grupos?.find((dt) => dt.id === grupoId)}
            //value={grupos?.find((dt) => dt.id === grupoId) || null}
            onChange={(_, value) => {
              if (value) {
                setGrupoId(value.id);
              } else {
                setGrupoId(0);
              }
            }}
            fullWidth
            disableClearable={true}
          />
        </Grid>
        <Grid item sm={12} md={6} sx={{ p: 1, mb: 1 }}>
          <Autocomplete
            options={carreras?.data || []}
            loading={loadingCA}
            disabled={fetchingCA}
            loadingText="Cargando carreras..."
            getOptionLabel={(option) => option.edu_name}
            renderInput={(params) => <TextField {...params} label="Carrera" />}
            value={carreras?.data!.find((dt) => dt.id === carreraId) || null}
            onChange={(_, value) => {
              if (value) {
                setCarreraId(value.id);
              } else {
                setCarreraId(undefined);
              }
            }}
            fullWidth
          />
        </Grid>

        {loadingCA || loadingGrupos ? (
          <SkeletonList />
        ) : (
          <Grid
            container
            justifyContent={'center'}
            sx={{ pt: 2, borderTop: '1px solid gainsboro' }}
          >
            {grupoId === undefined ? (
              <SelectionMessage />
            ) : (
              <>
                <DynamicTable
                  students={students}
                  isFetching={fetchingST}
                  isLoading={loadingST}
                  error={isError ? error : undefined}
                  page={page}
                  perPage={perPage}
                  setPage={setPage}
                  setPerPage={setPerPage}
                  setFilters={setFilterArrow}
                  filters={filterArrow}
                  search={search}
                  setSearch={setSearch}
                />
              </>
            )}
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default ListStudents;
