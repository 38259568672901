export const permissionKey = 'department';

export interface SubPermissionKeys {
  read: string;
  create: string;
  edit: string;
  delete: string;
}

export const subPermissionKeys: SubPermissionKeys = {
  read: 'departments-listar-departamentos',
  create: 'departments-crear-departamento',
  edit: 'departments-actualizar-departamento',
  delete: 'departments-actualizar-estado-departamento',
};
