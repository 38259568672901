import DeleteAlert from '../../../shared/components/dialogs/DeleteAlert';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { useDeleteRoleMutation } from '../slices/rolesApiSlice';
import { resetRoleIdToDelete, setDeleteModalOpen } from '../slices/rolesSlice';

const DeleteRoleDialog = () => {
  const dispatch = useAppDispatch();

  const { deleteModalOpen, roleIdToDelete } = useAppSelector((state) => state.roles);
  const { currentProfile } = useAppSelector((state) => state.access);

  const [deleteRole, { isLoading, isSuccess, isError, error }] = useDeleteRoleMutation();

  const handleDelete = () => {
    deleteRole({ profileId: currentProfile?.profileId!, roleId: roleIdToDelete! });
  };

  const handleClose = () => {
    dispatch(setDeleteModalOpen(false));
    dispatch(resetRoleIdToDelete());
  };

  return (
    <DeleteAlert
      open={deleteModalOpen}
      onClose={handleClose}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      handleDelete={handleDelete}
      error={error as string}
    />
  );
};

export default DeleteRoleDialog;
