import * as Yup from 'yup';

export const InvestigationLinesSchema = Yup.object().shape({
  name: Yup.string().required('El nombre es requerido'),
  description: Yup.string().required('La descripción es requerida'),
  educationLevel: Yup.array().of(Yup.number()),
  status: Yup.number()
    .integer('Solo se permiten números enteros.')
    .required('Debe seleccionar el estado del instituto.')
    .min(1, 'Debe seleccionar el estado del instituto.'),
});

export const GroupResearchSchema = Yup.object().shape({
  name: Yup.string().required('El nombre es requerido'),
  lineResearchId: Yup.string(),
  status: Yup.number()
    .integer('Solo se permiten números enteros.')
    .required('Debe seleccionar el estado del instituto.')
    .min(1, 'Debe seleccionar el estado del instituto.'),
});

export const CollaboratorGroupResearchSchema = Yup.object().shape({
  collaboratorId: Yup.string().required('El colaborador es requerido'),
  groupResearchId: Yup.string(),
  status: Yup.number()
    .integer('Solo se permiten números enteros.')
    .required('Debe seleccionar el estado del instituto.')
    .min(1, 'Debe seleccionar el estado del instituto.'),
});
