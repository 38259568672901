import { useGetAnnouncementsByCourseTeacherQuery } from '../slices/announcementTeacherApiSlice';
import { Chip, Grid, Paper, Typography, Button } from '@material-ui/core';
import { IconSend } from '@tabler/icons';
import { useState } from 'react';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@material-ui/lab';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { renderSkeletonTable } from '../../../../shared/helpers/render';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useStyles } from '../constants/styles';
import { IconMessage } from '@tabler/icons';
import { useAppSelector } from '../../../../../hooks/useRedux';
import { useTranslation } from 'react-i18next';

interface Props {
  id: number;
  ans_date_send: string;
  ans_message: string;
}

const CourseHistoryAnnouncement = () => {
  const classes = useStyles();

  const [limit, setLimit] = useState<number>(2);

  const [offset, setOffset] = useState<number>(0);

  const {
    course: { courseId },
  } = useAppSelector((state) => state.virtualClassroomTeacherShared);

  // const { data, isSuccess } = useGetStudentAnnouncementsHistoryQuery({
  //   studentId: Number(studentId),
  //   user_profile_id: currentProfile?.profileId!,
  //   limit: limit,
  //   offset: offset,
  // });

  const history = useHistory();

  const { t } = useTranslation();

  const goToAdd = () => {
    history.push(`anuncios/crear`);
  };

  console.log(offset, limit);
  const { data: courseHistoryAnnouncement, isSuccess } = useGetAnnouncementsByCourseTeacherQuery(
    {
      courseId: courseId!,
    },
    { skip: courseId === 0 || courseId === undefined }
  );

  const dataFetch = courseHistoryAnnouncement?.map((value) => ({
    id: value.id,
    ans_date_send: value.ans_date_send.toString(),
    ans_message: value.ans_message,
  }));

  const renderTime = ({ id, ans_date_send, ans_message }: Props) => {
    return (
      <TimelineItem key={`${ans_date_send}-${id}`}>
        <TimelineOppositeContent>
          <Typography variant="body2" color="textSecondary">
            {moment(ans_date_send!).fromNow()}
          </Typography>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot color="primary">
            <IconMessage />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={3} className={classes.paper}>
            <Typography>{ans_message}</Typography>
            {moment(ans_date_send).isSame(moment(), 'day') && (
              <Grid container justifyContent="flex-end">
                <Chip label="Reciente" color="primary" size="small" />
              </Grid>
            )}
          </Paper>
        </TimelineContent>
      </TimelineItem>
    );
  };

  const renderTimes = () => {
    return <>{dataFetch?.slice(0, limit + 1).map(renderTime)}</>;
  };

  const fetchMoreData = () => {
    setOffset(courseHistoryAnnouncement?.length!);
    setLimit(limit + 3);
  };

  return (
    <Grid container spacing={3}>
      <Grid item container xs={12} sm={12} md={12} justifyContent="flex-end">
        <Button variant="contained" startIcon={<IconSend size={15} />} onClick={goToAdd}>
          {t('teacher_virtual_classroom.course_history_announcements.send_announcement')}
        </Button>
      </Grid>

      <Grid item xs={12} sm={12} md={12} sx={{ minHeight: '70vh' }}>
        {isSuccess ? (
          <InfiniteScroll
            dataLength={courseHistoryAnnouncement?.slice(0, limit + 1).length!}
            next={fetchMoreData}
            hasMore={courseHistoryAnnouncement?.length! > limit + 1}
            loader={renderSkeletonTable(2, 6)}
            endMessage={
              <Typography variant="h5">
                {t('teacher_virtual_classroom.course_history_announcements.data_to_show')}
              </Typography>
            }
          >
            <Timeline position="alternate">{renderTimes()}</Timeline>
          </InfiniteScroll>
        ) : (
          renderSkeletonTable(2, 6)
        )}
      </Grid>
    </Grid>
  );
};

export default CourseHistoryAnnouncement;
