import { FC, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import { TreeItem, TreeView } from '@material-ui/lab';
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  Fab,
  Tooltip,
} from '@material-ui/core';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { checkboxTreeData, Role } from '../interfaces/roles.interfaces';

//
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import {
  IconChevronRight,
  IconSquareCheck,
  IconSquare,
  IconSquareMinus,
  IconChevronDown,
  IconX,
} from '@tabler/icons';
import { useUpdateRoleTreeMutation } from '../slices/rolesApiSlice';
import { useAppSelector } from '../../../../hooks/useRedux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { DebounceInput } from 'react-debounce-input';
import SearchIcon from '@material-ui/icons/Search';
import EditIcon from '@material-ui/icons/Edit';
import { useTranslation } from 'react-i18next';

interface Props {
  role: Role;
  data: checkboxTreeData[];
  permissions: string[];
  handleStatus: (value: string) => void;
  handleMessage: (value: string) => void;
  refetch: () => void;
}

const useStyles = makeStyles((theme) => ({
  icons: {
    display: 'none',
  },
  checkbox_tree: {
    '& div.react-checkbox-tree': {
      // '& span.rct-node-icon': {
      // },
      '& ol': {
        '& li': {
          '& span.rct-text': {
            '& label': {
              display: 'flex',
              alignItems: 'center',
              '& span.rct-checkbox': {
                width: 'auto',
                paddingLeft: '2px',
                '& svg.rct-icon': {
                  width: 'auto',
                  // color: 'black',
                },
              },
              '& span.rct-node-icon': {
                display: 'none',
              },
              '& span.rct-title': {},
            },
          },
        },
      },
    },
    '& .rct-icon-check': {
      '& path': {
        color: '#00AD47',
      },
    },
    '& .rct-icon-half-check': {
      '& line': {
        color: '#8E2FFF',
      },
    },
  },
}));

const AddPermissionsForm: FC<Props> = (props) => {
  const { t } = useTranslation();
  const { data, permissions, role, refetch, handleStatus, handleMessage } = props;
  const classes = useStyles();

  const { currentProfile } = useAppSelector((state) => state.access);

  const [selected, setSelected] = useState(permissions);
  // const [newSelected, setNewSelected] = useState(permissions);
  const [expanded, setExpanded] = useState<string[]>([...permissions]);

  const [isDisableAssign, setIsDisableAssign] = useState(true);

  const [search, setSearch] = useState<string>();
  const [permissionsFiltered, setPermissionsFiltered] = useState<checkboxTreeData[]>([]);

  useEffect(() => {}, []);

  //dt.children?.filter((ch) =>  ch.children?.find((chleaf) => chleaf.label.includes(search))  )

  useEffect(() => {
    if (search) {
      let filteredData: any[] = [];

      data.forEach((dt) => {
        console.log(dt);
        const data =
          dt.children?.filter(
            (ch) =>
              ch.value.includes(search) ||
              ch.label.includes(search) ||
              ch.children?.find((chleaf) => chleaf.value.includes(search.toLowerCase())) || // por si se quiere filtrar por las ramas y no solo los nodos padre de permisos
              ch.children?.find((chleaf) => chleaf.label.includes(search.toLowerCase()))
          ) || [];
        if (Boolean(data?.length)) filteredData.push([...data!]);
      });

      setPermissionsFiltered(filteredData.flat(1));

      // general
      // setPermissionsFiltered(
      //   data.filter(
      //     (dt) => dt.label.includes(search) || dt.children?.find((ch) => ch.label.includes(search))
      //   )
      // );
    } else {
      setPermissionsFiltered([]);
    }
  }, [search, data]);

  const [updateRole, { isLoading: isUpdating, isSuccess, isError, error }] =
    useUpdateRoleTreeMutation();

  const update = async () => {
    //array de nuevos
    const newPerm: string[] = [];
    //array de elminar
    const delPerm: string[] = [];

    //thash selected
    const thashSelected = selected.reduce((el, v) => ({ ...el, [v]: v }), {});

    //thash permissions
    const thashPermissions = permissions.reduce((el, v) => ({ ...el, [v]: v }), {});

    //filtro de array para agregar -> si selected tiene items que permissions no
    selected.forEach((alreadySelect) => {
      if (!(alreadySelect in thashPermissions)) {
        newPerm.push(alreadySelect);
      }
    });

    //filtro de array para eliminar -> si permissions tiene items que selected no
    permissions.forEach((basePerm) => {
      if (!(basePerm in thashSelected)) {
        delPerm.push(basePerm);
      }
    });

    await updateRole({
      profileId: currentProfile?.profileId!,
      roleId: Number(role.id),
      rolePayload: {
        name: role.name,
        keyword: role.keyword,
        description: role.description,
        status_id: role.status_id,
        add_permissions: [...newPerm],
        del_permissions: [...delPerm],
      },
    });
  };

  useEffect(() => {
    if (isSuccess) {
      // const timeout = setTimeout(() => {
      // refetch();
      handleStatus('success');
      handleMessage(t('configuration.roles.add_permissions.success'));
      // }, 1000);
      // return () => clearTimeout(timeout);
    } else if (isError) {
      handleStatus('error');
      handleMessage(error as string);
    }
  }, [refetch, isSuccess, isError, error, handleMessage, handleStatus, t]);

  return (
    <>
      <Grid container>
        <Grid container item xs={12} sx={{ mt: { xs: '10px' } }} justifyContent={'space-between'}>
          <Grid item xs={12} mb={1}>
            <DebounceInput
              autoFocus={Boolean(search)}
              minLength={0}
              debounceTimeout={200}
              onChange={({ target }) => setSearch(target.value)}
              value={search}
              element={(props) => (
                <TextField
                  style={{
                    width: '100%',
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon fontSize="small" />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          onClick={() => setSearch('')}
                          {...(!search && { sx: { cursor: 'initial', opacity: 0 } })}
                        >
                          <IconX size={20} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  placeholder={t('configuration.roles.add_permissions.search')}
                  variant="outlined"
                  size="small"
                  {...props}
                />
              )}
            />
          </Grid>
          <Typography variant={'h4'} component={'span'} py={'10px'}>
            {t('configuration.roles.add_permissions.permissions')}: {role.name}{' '}
            {isSuccess && (
              <span>{t('configuration.roles.add_permissions.permissions.success')}</span>
            )}
          </Typography>

          {!isDisableAssign ? (
            <Button
              type="button"
              variant="contained"
              size="large"
              color="primary"
              onClick={update}
              disabled={isUpdating}
              endIcon={isUpdating && <CircularProgress size={20} />}
            >
              {t('configuration.roles.add_permissions.save')}
            </Button>
          ) : (
            <Tooltip title={t('configuration.roles.add_permissions.edit')}>
              <Fab
                size="medium"
                color="primary"
                onClick={() => setIsDisableAssign(!isDisableAssign)}
                disabled={isUpdating}
              >
                <EditIcon fontSize={'small'} />
              </Fab>
            </Tooltip>
          )}
        </Grid>

        <Grid
          item
          xs={12}
          className={`${classes.checkbox_tree}`}
          sx={{ maxHeight: 550, overflow: 'hidden', mt: { xs: '10px' } }}
        >
          <PerfectScrollbar component="div">
            <CheckboxTree
              nodes={Boolean(permissionsFiltered.length) || search ? permissionsFiltered : data}
              checked={selected}
              expanded={expanded}
              disabled={isDisableAssign}
              // onClick={(t) => {
              //   console.log(selected);
              //   const selectedIndex = selected.indexOf(t.value);
              //   let newSelected: string[] = [];

              //   if (selectedIndex === -1) {
              //     newSelected = newSelected.concat(selected, t.value);
              //   } else if (selectedIndex === 0) {
              //     newSelected = newSelected.concat(selected.slice(1));
              //   } else if (selectedIndex === selected.length - 1) {
              //     newSelected = newSelected.concat(selected.slice(0, -1));
              //   } else if (selectedIndex > 0) {
              //     newSelected = newSelected.concat(
              //       selected.slice(0, selectedIndex),
              //       selected.slice(selectedIndex + 1)
              //     );
              //   }

              //   setSelected(newSelected);
              // }}
              onCheck={(checked, t) => {
                const target = t as any;
                const newChecks = [...selected];

                //si el elemento es una rama
                if (target.isLeaf) {
                  // no es necesaria ya que la libreria agregar una propiedad de is parent por defecto
                  let exist = false;

                  //encontrar el abuelo
                  const grParent = data.find((grp) => grp.label === target.parent.parent_name);

                  //filtrar el padre para extraer el primer permiso (visualizar)
                  // const parent = data.filter((parent) => parent.value === target.parent.value)[0];
                  const parent = grParent?.children?.find(
                    //
                    (parent) => parent.value === target.parent.value
                  );

                  //acciones de seleccionar y deseleccionar modificadas de la libreria
                  if (target.checked) {
                    newChecks.push(target.value);
                  } else {
                    newChecks.splice(newChecks.indexOf(target.value), 1);
                  }

                  //si el escogido no es el de visualizar
                  if (target.value !== parent?.children![0].value) {
                    /*ciclo por todos los hijos del padre del seleccionado para saber si el primer hijo
                    (el de visualizar) esta o no seleccionado si esta seleccionado retornar caso
                    contrario pushearlo al array de los items seleccionados*/
                    parent?.children?.forEach((item, i) => {
                      if (newChecks.some((el) => item.value === el && i === 0)) {
                        exist = true;
                        return;
                      }
                    });
                    /*si el primer hijo no existe, marcarlo (requisito de que el permiso default 
                      debe ser el de visualizar)*/
                    if (!exist && parent) newChecks.push(parent?.children![0].value);
                  } else {
                    //si es el de visualizar, condicionar si se esta marcando o desmarcando
                    if (!target.checked) {
                      //si se esta desmarcando recorrer todo el padre y desmarcar todos los hijos
                      parent?.children!.forEach((item, i) => {
                        const index = newChecks.indexOf(item.value);
                        if (index > -1) newChecks.splice(newChecks.indexOf(item.value), 1);
                      });
                    }
                  }
                } else {
                  //parche de seleccion de padres y abuelos
                  if (target.checked) {
                    target.children!.forEach((item: checkboxTreeData) => {
                      const index = newChecks.indexOf(item.value);
                      if (index === -1 && item.leaf) newChecks.push(item.value);

                      if (!item.leaf) {
                        item.children!.forEach((child) => {
                          const indexChild = newChecks.indexOf(child.value);
                          if (indexChild === -1 && child.leaf) newChecks.push(child.value);
                        });
                      }
                    });
                  } else {
                    target.children!.forEach((item: checkboxTreeData) => {
                      const index = newChecks.indexOf(item.value);
                      if (index > -1 && item.leaf)
                        newChecks.splice(newChecks.indexOf(item.value), 1);

                      if (!item.leaf) {
                        item.children!.forEach((child) => {
                          const indexChild = newChecks.indexOf(child.value);
                          if (indexChild > -1 && child.leaf)
                            newChecks.splice(newChecks.indexOf(child.value), 1);
                        });
                      }
                    });
                  }
                }

                setSelected(newChecks);
              }}
              onExpand={(expanded) => setExpanded(expanded)}
              icons={{
                check: <IconSquareCheck className="rct-icon rct-icon-check" />,
                uncheck: <IconSquare className="rct-icon rct-icon-uncheck" />,
                halfCheck: <IconSquareMinus className="rct-icon rct-icon-half-check" />,
                expandClose: <IconChevronRight className="rct-icon rct-icon-expand-close" />,
                expandOpen: <IconChevronDown className="rct-icon rct-icon-expand-open" />,
                expandAll: <span className={`rct-icon ${classes.icons}`} />,
                collapseAll: <span className={`rct-icon ${classes.icons}`} />,
                parentClose: <span className={`rct-icon ${classes.icons}`} />,
                parentOpen: <span className={`rct-icon ${classes.icons}`} />,
                leaf: <span className={`rct-icon ${classes.icons}`} />,
              }}
            />
          </PerfectScrollbar>
        </Grid>
      </Grid>
    </>
  );
};

export default AddPermissionsForm;
