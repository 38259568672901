import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EvaluationStudent } from '../evaluations/interfaces/evaluationStudent.interfaces';
import {
  EvaluationDetailQuestion,
  EvaluationStudentDetail,
} from '../evaluations/interfaces/evaluationDetail.interfaces';
import {
  VCStudentCourse,
  VCStudentQuestionAnswers,
} from '../evaluations/interfaces/evaluationPayload.interfaces';
import { VCStudentForum } from '../forum/interfaces/forum.interface';
import { VCStudentForumValues } from '../forum/constants/formConstants';
import { SubjectsByPeriod } from '../../academic-portfolio/interfaces/subjectsByPeriods';
import { ActivityDetails } from '../schedule/interfaces/scheduleActivitiesInterfaces';
import { Material } from '../materials/interfaces/materialsStudent.interfaces';
import { Forum } from '../forum/interfaces/forums.interfaces';
import { EvaluationStudentDataFinished } from '../evaluations/interfaces/evaluationFinished.interfaces';
import { modalInfoEvaluation } from '../evaluations/interfaces/evalutaionModal.interface';
interface evaluationStudentState {
  content: any;
  evaluation: {
    evaluationActive?: EvaluationStudentDetail;
    evaluationQuestionAnswers: EvaluationDetailQuestion[];
    evaluationId?: number;
    evaluations: EvaluationStudent[];
    confirmStartDialog: boolean;
    questionsAnswer: VCStudentQuestionAnswers[];
    evaluationComments: boolean;
    evaluationAnswersCompleted: number;
    evalutionFinishedData: EvaluationStudentDataFinished;
  };
  forum: VCStudentForum;
  course: VCStudentCourse;
  schedule: ActivityDetails[] | null;
  materials: Material[];
  modalInfoEvaluation?: modalInfoEvaluation;
  attendancesToJustify: number[];
  showJustifyDialog: boolean;
}

const initialState: evaluationStudentState = {
  content: undefined,
  evaluation: {
    evaluations: [],
    evaluationComments: false,
    confirmStartDialog: false,
    questionsAnswer: [],
    evaluationQuestionAnswers: [],
    evaluationAnswersCompleted: 0,
    evalutionFinishedData: {
      id: 0,
    } as EvaluationStudentDataFinished,
  },
  forum: VCStudentForumValues,
  course: {
    courseHourhandDescription: '',
    courseId: 0,
    courseName: '',
    courseParalel: '',
    collaborator: '',
    collaboratorEmail: '',
    collaboratorIdentification: '',
  },
  schedule: null,
  materials: [],
  attendancesToJustify: []!,
  showJustifyDialog: false,
};

const virtualClassroomStudent = createSlice({
  name: 'virtualClassroomSlice',
  initialState,
  reducers: {
    /**
     * Acciones de evaluación
     */
    setEvaluations: (state, action: PayloadAction<EvaluationStudent[]>) => {
      state.evaluation.evaluations = action.payload;
    },
    setEvalutionFinishedData: (state, action: PayloadAction<EvaluationStudentDataFinished>) => {
      state.evaluation.evalutionFinishedData = action.payload;
    },
    setEvaluationAnswersCompleted: (state) => {
      state.evaluation.evaluationAnswersCompleted++;
    },
    setEvaluationComments: (state, action: PayloadAction<boolean>) => {
      state.evaluation.evaluationComments = action.payload;
    },
    setCurrentCourse: (state, action: PayloadAction<VCStudentCourse>) => {
      state.course = action.payload;
    },
    resetVirtualClassroomStudent: (state) => {
      state = initialState;
    },
    setEvaluationActive: (state, action: PayloadAction<EvaluationStudentDetail>) => {
      state.evaluation.evaluationActive = action.payload;
    },
    setEvaluationConfirmStartDialog: (state, action: PayloadAction<boolean>) => {
      state.evaluation.confirmStartDialog = action.payload;
    },
    setCurrentEvaluation: (state, action: PayloadAction<number>) => {
      state.evaluation.evaluationId = action.payload;
    },
    setEvaluationQuestionAnswer: (state, action: PayloadAction<EvaluationDetailQuestion[]>) => {
      state.evaluation.evaluationQuestionAnswers = action.payload;
    },
    resolvedEvaluationQuestionAnswer: (state, action: PayloadAction<number>) => {
      const questionUpdated = state.evaluation.evaluationQuestionAnswers?.map((e) =>
        e.id === action.payload ? { ...e, resolved: true } : e
      );
      state.evaluation.evaluationQuestionAnswers = questionUpdated;
    },
    setAnswerQuestionMultiple: (
      state,
      action: PayloadAction<{ questionId: number; answerId: number; selected: boolean }>
    ) => {
      const question = state.evaluation.evaluationQuestionAnswers.find(
        (i) => i.id === action.payload.questionId
      )!;

      const answers = question?.option_answers.map((e) =>
        e.id === action.payload.answerId
          ? { ...e, op_ans_is_answer: action.payload.selected }
          : { ...e }
      )!;

      state.evaluation.evaluationQuestionAnswers = state.evaluation.evaluationQuestionAnswers.map(
        (e) => (e.id === action.payload.questionId ? { ...e, option_answers: answers } : { ...e })
      );
    },
    setAnswerQuestionSingle: (
      state,
      action: PayloadAction<{ questionId: number; answerId: number; selected: boolean }>
    ) => {
      const question = state.evaluation.evaluationQuestionAnswers.find(
        (i) => i.id === action.payload.questionId
      )!;

      const answers = question?.option_answers.map((e) =>
        e.id === action.payload.answerId
          ? { ...e, op_ans_is_answer: action.payload.selected }
          : { ...e, op_ans_is_answer: false }
      )!;

      state.evaluation.evaluationQuestionAnswers = state.evaluation.evaluationQuestionAnswers.map(
        (e) => (e.id === action.payload.questionId ? { ...e, option_answers: answers } : { ...e })
      );
    },
    setAnswerQuestionCommentary: (
      state,
      action: PayloadAction<{ questionId: number; commentary: string }>
    ) => {
      state.evaluation.evaluationQuestionAnswers = state.evaluation.evaluationQuestionAnswers.map(
        (e) =>
          e.id === action.payload.questionId
            ? { ...e, op_ans_answer_comment: action.payload.commentary }
            : { ...e }
      );
    },
    /**
     * Forum actions
     */
    setPortfolioAcademicMatters: (state, action: PayloadAction<SubjectsByPeriod[]>) => {
      state.forum.portfolioMatters = action.payload;
    },
    setForums: (state, action: PayloadAction<Forum[]>) => {
      state.forum.forums = action.payload;
    },
    setForumCurrent: (state, action: PayloadAction<Forum>) => {
      state.forum.forumCurrent = action.payload;
    },
    setDeleteForumModalOpen: (state, action: PayloadAction<boolean>) => {
      state.forum.deleteModalOpen = action.payload;
    },
    openDeleteForumModalWith: (state) => {
      state.forum.deleteModalOpen = true;
    },
    resetForumId: (state) => {
      state.forum.forumIdToManage = undefined;
    },
    setForumToManage: (state, action: PayloadAction<Number>) => {
      state.forum.forumIdToManage = action.payload;
    },
    setScheduleActivities: (state, action: PayloadAction<ActivityDetails[]>) => {
      state.schedule = action.payload;
    },
    setMaterialsStudent: (state, action: PayloadAction<Material[]>) => {
      state.materials = action.payload;
    },
    setInfoModalEvaluation: (state, action: PayloadAction<modalInfoEvaluation>) => {
      state.modalInfoEvaluation = action.payload;
    },
    setAttendancesToJustify: (state, action: PayloadAction<number[]>) => {
      state.attendancesToJustify = action.payload;
    },
    fireAttendancesToJustifyDialog: (state, action: PayloadAction<boolean>) => {
      state.showJustifyDialog = action.payload;
    },
    resetEvaluationState: (state) => {
      state.evaluation = {
        ...state.evaluation,
      };
    },
  },
});

export const {
  setEvaluationActive,
  setInfoModalEvaluation,
  setCurrentEvaluation,
  setCurrentCourse,
  setEvaluationComments,
  setEvaluations,
  setAnswerQuestionMultiple,
  setAnswerQuestionCommentary,
  setAnswerQuestionSingle,
  setEvaluationQuestionAnswer,
  resolvedEvaluationQuestionAnswer,
  resetVirtualClassroomStudent,
  setEvaluationConfirmStartDialog,
  setDeleteForumModalOpen,
  openDeleteForumModalWith,
  setPortfolioAcademicMatters,
  setForums,
  resetForumId,
  setForumToManage,
  setScheduleActivities,
  setMaterialsStudent,
  setForumCurrent,
  setEvaluationAnswersCompleted,
  setEvalutionFinishedData,
  setAttendancesToJustify,
  resetEvaluationState,
} = virtualClassroomStudent.actions;

export default virtualClassroomStudent.reducer;
