import { FC, useEffect } from 'react';

import { useDispatch } from 'react-redux';
import {
  Grid,
  // Typography,
  // Skeleton,
  TextField,
  Button,
  FormHelperText,
  useTheme,
  CircularProgress,
  Typography,
  Divider,
  List,
  ListItem,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useHistory, useLocation } from 'react-router-dom';
import downloadjs from 'downloadjs';

import { DetailsProps } from '../../constants/types';
import InputLabel from '../../../../shared/components/forms/InputLabel';
import { useAppSelector } from '../../../../../hooks/useRedux';
// import { ThirdRegistration as ThirdRegistrationInt } from '../../interfaces/generalApplications.interfaces';
import { ChangeStatusApp } from '../../constants/validation-schemas';
import { useUpdateFaultJustificationApplicationMutation } from '../../slices/GeneralApplicationsApiSlice';
import { openRejectAppModalWith } from '../../slices/GeneralApplicationsSlice';
import { JustifyFaultInterface } from '../../interfaces/generalApplications.interfaces';
import { useGetCourseStudentByIdQuery } from '../../../../students/subjects-register/slices/subjectsRegisterApiSlice';
import useGetUrlDownload from '../../../../../hooks/useGetUrlDownload';
import { isAppChangeStatusAvailable } from '../../helpers';
import { subPermissionKeys } from '../../constants/permissions';
import useSharedGuard from '../../../../../hooks/useSharedGuard';

const FaultJustification: FC<DetailsProps> = (props) => {
  const {
    details: detailsProp,
    // spacing,
    appCode,
    appId,
    // appDescription,
    currentStatus,
    nextStatus,
    updateAlertHandler,
    updateStatusHandler,
  } = props;
  const details = detailsProp as JustifyFaultInterface;

  const { currentProfile } = useAppSelector((state) => state.access);

  const { data: courseStudent } = useGetCourseStudentByIdQuery(
    {
      courseStudentId: +details.collections[0][0].course_student_id,
    },
    {
      skip: !currentProfile?.profileId,
    }
  );
  const isNotAvailable = !isAppChangeStatusAvailable(currentStatus);

  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const { hasAccess } = useSharedGuard(subPermissionKeys);

  const [
    updateApplication,
    {
      isLoading: isUpdating,
      error: updateError,
      isError: isErrorUpdate,
      isSuccess: isSuccessUpdate,
    },
  ] = useUpdateFaultJustificationApplicationMutation();

  const { handleSubmit, getFieldProps, errors, touched, validateField, values } = useFormik({
    initialValues: {
      comment: '',
    },
    validationSchema: ChangeStatusApp,
    onSubmit: async (values) => {
      await updateApplication({
        profileId: currentProfile?.profileId!,
        ApplicationId: appId,
        applicationPayload: {
          app_code: appCode,
          comment: values.comment,
        },
      });
      updateAlertHandler(true);
    },
  });

  useEffect(() => {
    if (isErrorUpdate) updateStatusHandler({ state: 'error', msg: updateError as string });
    if (isSuccessUpdate)
      updateStatusHandler({ state: 'success', msg: 'La solicitud se ha aprobado' });
  }, [isErrorUpdate, updateStatusHandler, updateError, isSuccessUpdate]);

  const handleDelete = (data: { appId: number; code: string; msg: string }) => {
    dispatch(openRejectAppModalWith(data));
  };
  const { urlDownload } = useGetUrlDownload();

  const handleDownload = () => {
    if (details[0].value) {
      const url = `${urlDownload}/${details[0].value}`;
      try {
        downloadjs(url);
      } catch (error) {
        console.log(Error);
      }
    }
  };

  return (
    <Grid container item xs={12}>
      <Grid item container xs={12} alignItems="center">
        <Typography
          sx={{
            mr: 2,
          }}
        >
          Documento
        </Typography>
        <Button variant="contained" onClick={handleDownload}>
          Descargar
        </Button>
      </Grid>
      <Grid item xs={12} mt={4}>
        <Divider />
      </Grid>
      <Grid item xs={12} mt={2}>
        <Typography variant="h4">{courseStudent?.subject?.mat_name}</Typography>
        <Typography variant="h4">Faltas a justificar</Typography>
        <List>
          {details.collections[0]!.map((item) => (
            <ListItem>
              <strong>Fecha</strong>: {item.assistant_date}
            </ListItem>
          ))}
        </List>
      </Grid>
      <Grid item xs={12} my={2}>
        <Divider />
      </Grid>

      <form onSubmit={handleSubmit}>
        {/* )} */}

        {hasAccess('changeApplicationStatus') && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputLabel>Comentario</InputLabel>
              <TextField
                fullWidth
                multiline
                rows={4}
                error={Boolean(errors.comment && touched.comment)}
                placeholder="Ingrese una observación para el proceso de la solicitud"
                {...getFieldProps('comment')}
                disabled={isNotAvailable}
              />
              {errors.comment && touched.comment && (
                <FormHelperText error>{errors.comment}</FormHelperText>
              )}
            </Grid>

            {/* {((currentRol?.keyword === 'decano' &&
            currentApplication!.current_status.toLowerCase() === 'enviado') ||
            (currentRol?.keyword === 'coordinador-facultad' &&
              (currentApplication!.current_status.toLowerCase() === 'revisado por decanato' ||
                currentApplication!.current_status.toLowerCase() ===
                  'revisado por consejo facultad'))) &&
            !isSuccess && ( */}
            <Grid container justifyContent={'flex-end'} mt={2} spacing={2}>
              <Grid item>
                <Button
                  variant="outlined"
                  size="large"
                  onClick={() => history.push(`/${location.pathname.split('/')[1]}`)}
                >
                  Cancelar
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  size="large"
                  {...(!isNotAvailable && {
                    sx: { background: theme.palette.error.main, borderColor: '#EDE7F6' },
                  })}
                  onClick={() => {
                    touched.comment = true;
                    validateField('comment').then(() => {
                      if ((touched.comment && errors.comment) || !values.comment) return;
                      handleDelete({ appId, code: appCode, msg: values.comment });
                    });
                  }}
                  disabled={isUpdating || isNotAvailable || isSuccessUpdate || nextStatus === null}
                >
                  Rechazar
                </Button>
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  color="primary"
                  disabled={isUpdating || isNotAvailable || isSuccessUpdate || nextStatus === null}
                  endIcon={isUpdating && <CircularProgress size={20} />}
                >
                  Aprobar
                </Button>
              </Grid>
            </Grid>
            {/* )} */}
          </Grid>
        )}
      </form>
    </Grid>
  );
};

export default FaultJustification;
