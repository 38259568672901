import { CircularProgress, Divider, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import { IconDownload, IconEye, IconX, IconEyeOff, IconEdit } from '@tabler/icons';
import download from 'downloadjs';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { useGetStudentDocumentsQuery } from '../../slices/studentsApiSlice';
import ErrorAlert from '../../../../shared/components/alerts/ErrorAlert';
import CustomIconButton from '../../../../shared/components/tables/buttons/CustomIconButton';
import MainCard from '../../../../shared/components/cards/MainCard';
import { useControlModalStates } from '../../../../shared/hooks/useControlModalStates';
import CustomSimpleDialog from '../../../../shared/components/dialogs/CustomSimpleDialog';
import AddButton from '../../../../shared/components/tables/buttons/AddButton';
import UploadDocDialog from './UploadDocDialog';
import { StudentDocuments } from '../../interfaces/students.interface';
import DeleteButton from '../../../../shared/components/tables/buttons/DeleteButton';
import DeleteDocDialog from './DeleteDocDialog';
import useGetUrlDownload from '../../../../../hooks/useGetUrlDownload';
import { PropsFormEdit } from '.';

const DocumentDataForm = ({ data: studentData }: PropsFormEdit) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const { studentId } = useParams<{ studentId: string }>();

  const { dataId, open, handleCloseModal, handleOpenModalWith } = useControlModalStates();
  const {
    dataId: dataIdDelete,
    open: openDelete,
    handleCloseModal: handleCloseModalDelete,
    handleOpenModalWith: handleOpenModalWithDelete,
  } = useControlModalStates();

  const { urlDownload } = useGetUrlDownload();

  const { data, isLoading, error } = useGetStudentDocumentsQuery({
    studentId: studentId!,
  });

  const [previewDocument, setPreviewDocument] = useState(0);
  const [previewDocumentUrl, setPreviewDocumentUrl] = useState<string | null>(null);
  // const [showAlerts, setShowAlerts] = useState(false);

  const isSm = useMediaQuery(theme.breakpoints.down('md'));

  const [studentDocument, setStudentDocument] = useState<StudentDocuments | null>(null);

  const handleClose = () => {
    handleCloseModal();
    setStudentDocument(null);
  };

  const handleOpen = (dataId: number, studentDocument: StudentDocuments) => {
    handleOpenModalWith(dataId);
    setStudentDocument(studentDocument);
  };

  const handlePreviewDocument = (url: string, docId: number, isExternal: boolean) => {
    let document = url;

    if (!isExternal) {
      document = urlDownload + url;
      // document = URL.createObjectURL(new Blob([url], { type: 'application/pdf' }));
    }

    setPreviewDocument(docId);
    setPreviewDocumentUrl(document);
  };

  const handleDownloadFile = async (url: string, isExternal: boolean) => {
    let document = url;

    if (!isExternal) {
      document = urlDownload + url;
      // document = URL.createObjectURL(new Blob([url], { type: 'application/pdf' }));
    }

    // setShowAlerts(false);
    download(document);

    // setShowAlerts(true);
  };

  return (
    <>
      {!isLoading && error && (
        <Grid container>
          <Grid item xs={12}>
            <ErrorAlert message={`Error: ${error as string}`} />
          </Grid>
        </Grid>
      )}

      <MainCard
        title="Documentos del estudiante"
        secondary={
          <AddButton
            // btnText={t('')}
            // title="Previsualizar"
            // color="info"
            btnText="Agregar Documento"
            onClick={() => handleOpenModalWith()}
          />
        }
      >
        {isLoading && (
          <Grid container item justifyContent="center" alignItems="center">
            <CircularProgress size={50} />
          </Grid>
        )}
        {!isLoading && data && data.length > 0 && (
          <Grid container spacing={2}>
            <Grid item xs={!isSm && previewDocument ? 5 : 12}>
              <PerfectScrollbar
                style={{ height: '100%', maxHeight: 'calc(100vh - 305px)', overflowX: 'hidden' }}
              >
                <Grid>
                  {data.map((doc) => (
                    <>
                      <Divider
                        style={{
                          width: '100%',
                          marginBottom: '1em',
                          borderColor: theme.palette.grey[400],
                        }}
                      />
                      <Grid item container xs={12} alignItems="center">
                        <Grid item xs={8}>
                          {doc.stu_doc_name_file}
                        </Grid>
                        <Grid item xs={4}>
                          <CustomIconButton
                            // btnText={t('')}
                            title="Previsualizar"
                            color="info"
                            Icon={previewDocument === doc.id ? IconEyeOff : IconEye}
                            handleClick={() =>
                              previewDocument === doc.id
                                ? setPreviewDocument(0)
                                : handlePreviewDocument(
                                    doc.stu_doc_url,
                                    doc.id,
                                    !!Number(doc.stu_doc_is_external_url)
                                  )
                            }
                          />
                          <CustomIconButton
                            // btnText={t('')}
                            title="Editar"
                            Icon={IconEdit}
                            handleClick={() => handleOpen(doc.id, doc)}
                          />
                          <CustomIconButton
                            // btnText={t('')}
                            title="Descargar"
                            color="primary"
                            Icon={IconDownload}
                            handleClick={() =>
                              handleDownloadFile(
                                doc.stu_doc_url,
                                !!Number(doc.stu_doc_is_external_url)
                              )
                            }
                          />
                          <DeleteButton handleDelete={() => handleOpenModalWithDelete(doc.id)} />
                          {/* <CustomIconButton
                          // btnText={t('')}
                          title="Descargar"
                          color="primary"
                          Icon={IconDownload}
                          handleClick={() => handleDownloadFile(doc.stu_doc_url)}
                        /> */}
                        </Grid>
                      </Grid>
                      <Divider
                        style={{
                          width: '100%',
                          marginTop: '1em',
                          borderColor: theme.palette.grey[400],
                        }}
                      />
                    </>
                  ))}
                </Grid>
              </PerfectScrollbar>
            </Grid>

            {!!previewDocument && (
              <Grid item xs={isSm ? 12 : 7}>
                <Grid item container spacing={1}>
                  <Grid container justifyContent="flex-end">
                    <Grid item>
                      <CustomIconButton
                        // btnText={t('')}
                        title="Cerrar"
                        Icon={IconX}
                        handleClick={() => setPreviewDocument(0)}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <iframe
                      title={previewDocumentUrl!}
                      src={previewDocumentUrl!}
                      // data-iframely-url={previewDocumentUrl}
                      style={{
                        width: '100%',
                        height: '600px',
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        )}
      </MainCard>

      <CustomSimpleDialog open={open} onClose={handleClose}>
        {open && (
          <UploadDocDialog
            dataId={dataId}
            studentIdentification={studentData?.pers_identification}
            dataEdit={studentDocument}
            closeModal={handleCloseModal}
          />
        )}
      </CustomSimpleDialog>

      {openDelete && (
        <DeleteDocDialog
          openModal={openDelete}
          dataId={dataIdDelete}
          handleCloseModal={handleCloseModalDelete}
        />
      )}

      {!isLoading && data && !data.length && (
        <Grid container>
          <Grid item xs={12}>
            <ErrorAlert message={t('Sin documentos')} />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default DocumentDataForm;
