import { useEffect } from 'react';

import { Autocomplete, Grid, TextField, Switch, FormHelperText } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { useAppSelector } from '../../../../../hooks/useRedux';
import InputLabel from '../../../../shared/components/forms/InputLabel';

import { useGetDisabilityTypesQuery } from '../../slices/collaboratorsApiSlice';
import { DisabilityType } from '../../interfaces/disabilityType.interfaces';

interface DisabilitiesForm {
  hasDisability: boolean;
  numDisabilityCertificate: string;
  disabilityPercentage: string;
  disabilities: DisabilityType[];
}

const DisabilitiesStep = () => {
  const { currentProfile } = useAppSelector((state) => state.access);
  const { personData } = useAppSelector((state) => state.collaborators);

  const { values, setFieldValue, getFieldProps, errors, touched, setTouched } =
    useFormikContext<DisabilitiesForm>();

  const profileId = currentProfile!.profileId;

  const { data: disabilityTypes = [], isLoading: isLoadingTD } = useGetDisabilityTypesQuery({
    profileId,
  });

  useEffect(() => {
    setTouched({});
  }, [setTouched]);

  useEffect(() => {
    if (personData && !values?.numDisabilityCertificate) {
      setFieldValue('hasDisability', Boolean(Number(personData.pers_has_disability)));
      setFieldValue('numDisabilityCertificate', personData.pers_disability_identification || '');
      setFieldValue('disabilityPercentage', Number(personData.pers_disability_percent) || 0);
      setFieldValue('disabilities', personData.disabilities || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personData, setFieldValue]);

  return (
    <Grid container spacing={4} alignItems="flex-start">
      <Grid item xs={12} md={6} container spacing={2}>
        <Grid item xs={12}>
          <InputLabel>¿Posee discapacidad?</InputLabel>
          <Switch checked={values.hasDisability} {...getFieldProps('hasDisability')} />
        </Grid>

        {values.hasDisability && (
          <>
            <Grid item xs={12}>
              <InputLabel>N° Certificado de discapacidad</InputLabel>
              <TextField
                fullWidth
                placeholder="Ej: N° CONADIS"
                {...getFieldProps('numDisabilityCertificate')}
                error={Boolean(errors.numDisabilityCertificate && touched.numDisabilityCertificate)}
              />
              {errors.numDisabilityCertificate && touched.numDisabilityCertificate && (
                <FormHelperText error>{errors.numDisabilityCertificate}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12}>
              <InputLabel>Porcentaje de discapacidad</InputLabel>
              <TextField
                fullWidth
                type="number"
                placeholder="Porcentaje indicado en el cetificado"
                InputProps={{
                  endAdornment: '%',
                }}
                inputProps={{
                  min: 1,
                }}
                {...getFieldProps('disabilityPercentage')}
                error={Boolean(errors.disabilityPercentage && touched.disabilityPercentage)}
              />
              {errors.disabilityPercentage && touched.disabilityPercentage && (
                <FormHelperText error>{errors.disabilityPercentage}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12}>
              <InputLabel>Tipos de discapacidad</InputLabel>
              <Autocomplete
                options={disabilityTypes}
                multiple
                loading={isLoadingTD}
                loadingText="Cargando tipos de discapacidad..."
                noOptionsText="No hay opciones"
                getOptionLabel={(option) => option.typ_dis_name}
                value={values.disabilities || []}
                onChange={(_, values) => {
                  setFieldValue('disabilities', [...values.map((v) => v)]);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(errors.disabilities && touched.disabilities)}
                    placeholder="Seleccione al menos un tipo de discapacidad"
                  />
                )}
                fullWidth
                clearIcon={null}
              />
              {errors.disabilities && touched.disabilities && (
                <FormHelperText error>{errors.disabilities}</FormHelperText>
              )}
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default DisabilitiesStep;
