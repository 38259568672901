import {
  Autocomplete,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ErrorAlert from '../../../../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../../../../shared/components/alerts/SuccessAlert';
import { useGetAllEvaluationTypesQuery } from '../../../evaluation_type/slices/evaluationTypeApiSlice';
import {
  useAddCourseGroupMutation,
  useGetListAllPeriodsQuery,
  useLazyGetListCoursesQuery,
} from '../../slices/listGroupsApiSlice';
import { groupsCourseSchema } from '../../validation/GroupsCourse.validation';

const AddGroupsCourseForm = () => {
  const { t } = useTranslation();
  const [showAlerts, setShowAlerts] = useState(false);

  const coursesInputRef = useRef<HTMLInputElement>();

  const [addGroupCourse, { isLoading, isError, error, isSuccess }] = useAddCourseGroupMutation();

  const { data: periods, isLoading: isLoadingPeriods } = useGetListAllPeriodsQuery({});

  const { data: evaluationTypes, isLoading: isLoadingEvaluationTypes } =
    useGetAllEvaluationTypesQuery({});

  const [getCourses, { isLoading: isLoadingCourses, data: courses }] = useLazyGetListCoursesQuery();

  const { errors, getFieldProps, setFieldValue, handleSubmit, touched, resetForm, values } =
    useFormik({
      initialValues: {
        name: '',
        keyword: 'EGC',
        eva_type_uuid: '',
        period_id: '',
        course: [],
      },
      validationSchema: groupsCourseSchema,
      onSubmit: async (currentValues) => {
        await addGroupCourse({
          name: currentValues.name,
          keyword: currentValues.keyword,
          eva_type_uuid: currentValues.eva_type_uuid,
          course: [1, 2, 3],
          period_id: 1,
        });

        setShowAlerts(true);
      },
    });

  useEffect(() => {
    if (values.period_id) {
      const executeGetCourses = async () => {
        try {
          await getCourses({
            periodId: values.period_id,
          });
        } catch (error) {
          console.log(error);
        }
      };
      executeGetCourses();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.period_id]);

  useEffect(() => {
    if (isSuccess) {
      const inputCourses = coursesInputRef.current?.getElementsByClassName(
        'MuiAutocomplete-clearIndicator'
      )[0] as HTMLButtonElement | undefined;
      if (inputCourses) {
        inputCourses.click();
      }
      resetForm();
    }
  }, [isSuccess, resetForm]);

  return (
    <Grid container spacing={2}>
      {isSuccess && showAlerts && (
        <Grid item xs={12}>
          <SuccessAlert
            message={t('maintenance.groups.add.success')}
            handleDismiss={() => setShowAlerts(false)}
          />
        </Grid>
      )}
      {isError && showAlerts && (
        <Grid item xs={12}>
          <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
        </Grid>
      )}

      <Grid item xs={12}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputLabel>{t('maintenance.scopes.form.name')}</InputLabel>
              <TextField
                placeholder={t('maintenance.scopes.form.name.placeholder')}
                error={Boolean(errors.name && touched.name)}
                {...getFieldProps('name')}
                fullWidth
              />
              {errors.name && touched.name && (
                <FormHelperText error>{t(errors.name)}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12}>
              <InputLabel>{t('Palabra clave (keyword)')}</InputLabel>
              <TextField
                disabled
                placeholder={t('Palabra clave (keyword)')}
                error={Boolean(errors.keyword && touched.keyword)}
                {...getFieldProps('keyword')}
                fullWidth
              />
              {errors.keyword && touched.keyword && (
                <FormHelperText error>{t(errors.keyword)}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12}>
              {isLoadingEvaluationTypes ? (
                <TextField fullWidth placeholder={t('shared.filters.eva_type.loading')} disabled />
              ) : (
                <Autocomplete
                  options={evaluationTypes || []}
                  getOptionLabel={(option) => option.description}
                  value={
                    evaluationTypes?.find(
                      (evaluationType) =>
                        evaluationType.uuid === getFieldProps('eva_type_uuid').value
                    ) || null
                  }
                  onChange={(event, newValue) => {
                    setFieldValue('eva_type_uuid', newValue?.uuid);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="medium"
                      fullWidth
                      error={Boolean(errors.eva_type_uuid && touched.eva_type_uuid)}
                      placeholder={t('shared.filters.eva_type.placeholder')}
                    />
                  )}
                />
              )}
              {errors.eva_type_uuid && touched.eva_type_uuid && (
                <FormHelperText error>{t(errors.eva_type_uuid)}</FormHelperText>
              )}
            </Grid>
            <Grid item xs={12}>
              {isLoadingPeriods ? (
                <TextField fullWidth placeholder={t('shared.filters.period')} disabled />
              ) : (
                <Autocomplete
                  options={periods || []}
                  getOptionLabel={(option) => option.per_name}
                  value={
                    periods?.find((period) => period.id === getFieldProps('period_id').value) ||
                    null
                  }
                  onChange={(event, newValue) => {
                    setFieldValue('period_id', newValue?.id);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="medium"
                      fullWidth
                      error={Boolean(errors.period_id && touched.period_id)}
                      placeholder={t('shared.filters.period.placeholder')}
                    />
                  )}
                />
              )}
              {errors.period_id && touched.period_id && (
                <FormHelperText error>{t(errors.period_id)}</FormHelperText>
              )}
            </Grid>
            <Grid item xs={12}>
              {isLoadingCourses && values.period_id ? (
                <TextField
                  fullWidth
                  placeholder={
                    values.period_id
                      ? t('shared.filters.course.selected.period.placeholder')
                      : t('shared.filters.course.selected.period.placeholder')
                  }
                  disabled
                />
              ) : (
                <Autocomplete
                  ref={coursesInputRef}
                  multiple
                  options={courses || []}
                  getOptionLabel={(option) => option.matter.mat_name}
                  onChange={(event, newValue) => {
                    console.log(newValue);
                    setFieldValue(
                      'course',
                      newValue.map((item) => item.id)
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="medium"
                      fullWidth
                      error={Boolean(errors.course && touched.course)}
                      placeholder={t('shared.filters.course.selected.period.placeholder')}
                    />
                  )}
                />
              )}
              {errors.course && touched.course && (
                <FormHelperText error>{t(errors.course)}</FormHelperText>
              )}
            </Grid>

            <Grid item container xs={12} justifyContent="flex-end" spacing={2}>
              <Grid item>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  disabled={isLoading}
                  endIcon={isLoading && <CircularProgress size={12} />}
                >
                  {t('maintenance.scopes.add.submit')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default AddGroupsCourseForm;
