import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import ConfirmAlert from '../../../../shared/components/dialogs/ConfirmAlert';
import { useImportCalificationsFromMoodleMutation } from '../../slices/virtualClassroomTeacherApiSlice';
import { setImportCalificationsMoodleDialogOpen } from '../../slices/virtualClassroomTeacherSlice';

const ImportCalificationsMoodleDialog = () => {
  const dispatch = useAppDispatch();

  const { currentProfile } = useAppSelector((state) => state.access);
  const { course } = useAppSelector((state) => state.virtualClassroomTeacherShared);

  const [importCalifications, { isLoading, isError, isSuccess, error }] =
    useImportCalificationsFromMoodleMutation();

  const handleConfirm = () => {
    importCalifications({
      profileId: currentProfile?.profileId!,
      courseId: course.courseId,
    });
  };

  const handleClose = () => {
    dispatch(setImportCalificationsMoodleDialogOpen(false));
  };

  return (
    <ConfirmAlert
      open={true}
      error={error as string}
      handleDelete={handleConfirm}
      isError={isError}
      isLoading={isLoading}
      isSuccess={isSuccess}
      onClose={handleClose}
      key={1}
      confirmBtnMessage="Sí, importar"
      title="¿Desea importar las calificaciones desde la plataforma Moodle?"
      subtitle="Esta acción importa y reemplaza las calificaciones actuales."
      successMessage="Calificaciones importadas correctamente"
    />
  );
};

export default ImportCalificationsMoodleDialog;
