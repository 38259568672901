import {
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Table,
  Tooltip,
  IconButton,
  Box,
  useTheme,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { DeleteOutlineOutlined, DescriptionOutlined, NoteAdd } from '@material-ui/icons';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { useGetStudentsByProjectsDetailsQuery } from '../slices/followUpOfResearchProjectApiSlice';
import {
  setAddDocumentDialog,
  setBinnacleSelected,
  setDeleteBinnancleDialog,
  setDocumentsBinnancleDialog,
} from '../slices/followUpOfResearchProjectSlice';
import { useAppDispatch } from '../../../../hooks/useRedux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Student } from '../interfaces/studentsByProjects.interfaces';

interface Props {
  studentId: number | string;
  studentSearchProjectId: number | string;
  projectId: number | string;
  student: Student;
}

const HistoricalBinnancleStudent = ({ projectId, studentId, student }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { path } = useRouteMatch();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { data } = useGetStudentsByProjectsDetailsQuery({
    projectId,
    studentId,
  });

  const redirectForm = () => {
    const url = path.replace(':projectId', projectId.toString());
    history.push(`${url}/${studentId}/bitacora`);
  };

  return (
    <TableContainer component={Paper} sx={{ border: 1 }}>
      <Table
        sx={{
          minWidth: '650px',
        }}
        aria-label="simple table"
      >
        <TableHead sx={{ backgroundColor: theme.palette.primary[300] }}>
          <TableRow>
            <TableCell align="center">{t('Observaciones')}</TableCell>
            <TableCell align="center">{t('Fecha')}</TableCell>
            <TableCell align="center">{t('Hora de inicio')}</TableCell>
            <TableCell align="center">{t('Hora de finalización')}</TableCell>
            <TableCell align="center">{t('Número de horas')}</TableCell>
            <TableCell align="center">{t('Opciones')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((binnancle) => (
            <TableRow key={binnancle.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell align="center">{binnancle.observation || 'Sin Observaciones'}</TableCell>
              <TableCell align="center">{binnancle.date}</TableCell>
              <TableCell align="center">{binnancle.start_hour}</TableCell>
              <TableCell align="center">{binnancle.end_hour}</TableCell>
              <TableCell align="center">{Number(binnancle.number_hours).toFixed(2)}</TableCell>
              <TableCell align="center">
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Tooltip title={t('Editar')}>
                    <IconButton
                      onClick={() => {
                        dispatch(setBinnacleSelected(binnancle));
                        redirectForm();
                      }}
                    >
                      <EditOutlinedIcon
                        sx={{
                          color: theme.palette.secondary.main,
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t('Eliminar')}>
                    <IconButton
                      onClick={() => {
                        dispatch(setBinnacleSelected(binnancle));
                        dispatch(setDeleteBinnancleDialog(true));
                      }}
                    >
                      <DeleteOutlineOutlined
                        sx={{
                          color: theme.palette.error.main,
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t('Ver Documentos')}>
                    <IconButton
                      onClick={() => {
                        dispatch(setBinnacleSelected(binnancle));
                        dispatch(setDocumentsBinnancleDialog(true));
                      }}
                    >
                      <DescriptionOutlined
                        sx={{
                          color: theme.palette.primary.main,
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t('Agregar Documentos')}>
                    <IconButton
                      onClick={() => {
                        dispatch(setBinnacleSelected(binnancle));
                        dispatch(setAddDocumentDialog(true));
                      }}
                    >
                      <NoteAdd
                        sx={{
                          color: theme.palette.primary.main,
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </Box>
              </TableCell>
            </TableRow>
          ))}
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell
              align="right"
              colSpan={5}
              sx={{
                fontWeight: 'bold',
              }}
            >{`${t('Total de horas')}: ${data?.reduce(
              (acc, binnancle) => acc + Number(binnancle?.number_hours || 0),
              0
            )}`}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default HistoricalBinnancleStudent;
