import { Switch } from 'react-router-dom';
import GuardRoute from '../../../../router/guards/GuardRoute';
import FormsUserScreen from '../../../evaluations/modules/maintenance/form/screen/FormsUserScreen';
import EvaluationScreen from '../screens/EvaluationScreen';
import UserEvaluationsScreen from '../screens/UserEvaluationsScreen';

const UserEvaluationsRouter = () => {
  return (
    <Switch>
      <GuardRoute exact path="/evaluaciones-usuario" component={UserEvaluationsScreen} />
      <GuardRoute
        exact
        path="/evaluaciones-usuario/formularios/formularios-usuario"
        component={FormsUserScreen}
      />
      <GuardRoute exact path="/evaluaciones-usuario/:id/evaluar" component={EvaluationScreen} />
    </Switch>
  );
};

export default UserEvaluationsRouter;
