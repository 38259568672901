import { Grid, Tab, Tabs } from '@material-ui/core';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../../../hooks/useRedux';
import DownloadAccountState from '../../../../students/payments-history/components/DownloadAccountStatus';
import DebtsTab from './DebtsTab';
import ProrrogueTab from './ProrrogueTab';
import useSharedGuard from '../../../../../hooks/useSharedGuard';
import { studentPermissions } from '../../constants/permissions';
import PermissionsCheck from '../../../../shared/components/PermissionCheck';

const ClientAccountStatus = () => {
  const { hasAccess } = useSharedGuard(studentPermissions);

  const { id } = useParams<{ id: string }>();

  const { clientList } = useAppSelector((state) => state.client);
  const client = clientList.find((client) => client.id === Number(id));

  const [tabSelected, setTabSelected] = useState(0);

  const getAccountStatusTab = () => {
    switch (tabSelected) {
      case 0:
        return (
          <PermissionsCheck permissions={[studentPermissions.getDebstByStudents]}>
            <DebtsTab />
          </PermissionsCheck>
        );
      case 1:
        return (
          <PermissionsCheck permissions={[studentPermissions.getDebstByStudents]}>
            <ProrrogueTab />
          </PermissionsCheck>
        );
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {hasAccess('accountStatusPdf') && client?.user?.student?.id && (
          <DownloadAccountState studentId={client?.user?.student?.id} isIcon={false} />
        )}
      </Grid>
      <Grid item xs={12}>
        <Tabs
          value={tabSelected}
          onChange={(_, newValue) => {
            setTabSelected(newValue);
          }}
        >
          <Tab value={0} label="Deudas" />
          <Tab value={1} label="Prórroga" />
        </Tabs>
      </Grid>

      <Grid item xs={12}>
        {getAccountStatusTab()}
      </Grid>
    </Grid>
  );
};

export default ClientAccountStatus;
