import * as Yup from 'yup';

export const contacFormSchema = Yup.object().shape({
  id: Yup.number().nullable(),
  name: Yup.string().required('El nombre es requerido.'),
  description: Yup.string().required('La descripción es requerida.').nullable(),
  email: Yup.string()
    .email()
    //  .required('El correo es requerido.')
    .nullable(),
  phone: Yup.string()
    // .required('El teléfono es requerido')
    .matches(/^\d+$/, 'Solo se aceptan números.')
    .nullable(),
});

export const networkFormSchema = Yup.object().shape({
  name: Yup.string().required('El nombre es requerido.'),
  description: Yup.string().required('La descripción es requerida.').nullable(),
});
