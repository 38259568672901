import { Button, CardActions, Divider, FormHelperText, Grid, TextField } from '@material-ui/core';
import { IconCopy, IconPlus, IconTrash } from '@tabler/icons';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

import { useAppDispatch, useAppSelector } from '../../../../../../../hooks/useRedux';
import SuccessAlert from '../../../../../../shared/components/alerts/SuccessAlert';
import MainCard from '../../../../../../shared/components/cards/MainCard';
import { EvaluationQuestionSave } from '../../../interfaces/evaluations.interfaces';
import {
  setEvaluationQuestionCurrent,
  setChooseAnswerEdit,
  setChoose,
} from '../../../slices/evaluationSlice';

const EvaluationEditQuestion = () => {
  const dispatch = useAppDispatch();

  const [copy, setCopy] = useState<boolean>(false);

  const [questionRemoved, setQuestionRemoved] = useState<boolean>(false);

  const { evaluationQuestionCurrent, typeQuestion } = useAppSelector(
    (state) => state.evaluationTeacher.evaluation
  );

  const { values, getFieldProps, setFieldValue, handleSubmit, errors, touched } = useFormik({
    initialValues: {
      question: evaluationQuestionCurrent?.question!,
      options: evaluationQuestionCurrent?.options!,
      point: evaluationQuestionCurrent?.point!,
      catalogId: evaluationQuestionCurrent?.catalogId!,
    },
    onSubmit: () => {
      handleContinue();
    },
  });

  /* Remover una pregunta mientras se está editando. */
  const handleRemoveQuestion = async () => {
    await dispatch(setChooseAnswerEdit(false));
    await dispatch(setChoose(false));
  };
  /* Agregar opciones mientras se está editando. */
  const handleAdd = () => {
    const data = [...values.options, { option: '' }];
    setFieldValue('options', data);
  };
  /**
   * Manejo de actualización cuando se escribe en un campo
   * de texto dinamicamente.
   */
  const handleInputChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined,
    index: number
  ) => {
    const value = e?.target?.value!;
    const list = [...values.options];
    list[index] = { option: value, correct: false };
    setFieldValue('options', list);
  };
  /*
   * Actualizar el estado y continuar a guardar preguntas.
   */
  const handleContinue = async () => {
    const data: EvaluationQuestionSave = {
      id: evaluationQuestionCurrent?.id!,
      point: values.point,
      catalogId: values.catalogId,
      options: values?.options!,
      question: values?.question!,
      typeQuestion: typeQuestion,
    };
    await dispatch(setEvaluationQuestionCurrent(data));
    await dispatch(setChooseAnswerEdit(false));
  };
  /**
   * Eliminar un campo de texto dinámico.
   */
  const handleRemove = (index: number) => {
    const list = [...values.options];
    list.splice(index, 1);
    setFieldValue('options', list);
  };

  const ActionsButton = () => {
    return (
      <React.Fragment>
        <Button
          onClick={() => handleSubmit()}
          disabled={!values.question}
          style={{ textTransform: 'unset' }}
          variant="outlined"
          size="small"
          color="primary"
        >
          Continuar y guardar
        </Button>
        {typeQuestion !== 'comentary' && (
          <Grid item>
            <Button style={{ textTransform: 'unset' }} variant="text" size="small" color="primary">
              {`${values.options?.length!} ${values?.options?.length > 1 ? 'opciones' : 'opción'}`}
            </Button>
          </Grid>
        )}
        <Grid item>
          <Button style={{ textTransform: 'unset' }} variant="text" size="small" color="primary">
            {values.point} puntos
          </Button>
        </Grid>
        {false && (
          <Button disabled={!values.question}>
            <CopyToClipboard onCopy={() => setCopy(true)} text={values.question}>
              <IconCopy style={{ cursor: 'pointer' }} />
            </CopyToClipboard>
          </Button>
        )}
        <Button>
          <IconTrash onClick={handleRemoveQuestion} style={{ cursor: 'pointer' }} />
        </Button>
      </React.Fragment>
    );
  };

  return (
    <Grid container spacing={4} alignItems="flex-start">
      <Grid item xs={12} md={6} margin=" 0 auto" container spacing={1}>
        <Grid item xs={12}>
          <MainCard title="Editar pregunta" xs={12}>
            {copy && (
              <Grid item xs={12} sx={{ mb: 2 }}>
                <SuccessAlert message="Texto copiado" handleDismiss={() => setCopy(false)} />
              </Grid>
            )}

            {questionRemoved && (
              <Grid item xs={12} sx={{ mb: 2 }}>
                <SuccessAlert
                  message="Pregunta removida"
                  handleDismiss={() => setQuestionRemoved(false)}
                />
              </Grid>
            )}

            <Grid item xs={12} sx={{ mb: 1 }}>
              {values.question && (
                <TextField
                  fullWidth
                  error={Boolean(errors.question && touched.question)}
                  placeholder="Ingrese pregunta..."
                  {...getFieldProps('question')}
                  value={values.question || ''}
                />
              )}
              {errors.question && touched.question && (
                <FormHelperText error>{errors.question}</FormHelperText>
              )}
            </Grid>

            {typeQuestion !== 'comentary' && (
              <Grid container spacing={4}>
                <Grid
                  item
                  xs={12}
                  md={12}
                  container
                  spacing={1}
                  justifyContent="space-between"
                  alignItems="flex-end"
                >
                  {values.options &&
                    values.options.map((v, i) => (
                      <React.Fragment key={i}>
                        <Grid item xs={6} sx={{ mt: 2 }}>
                          <TextField
                            name={`question-${i.toString()}`}
                            variant="standard"
                            fullWidth
                            onChange={(e) => handleInputChange(e, i)}
                            placeholder="Opción..."
                            value={v.option || ''}
                          />
                        </Grid>
                        <Grid item>
                          <IconTrash
                            size={20}
                            onClick={() => handleRemove(i)}
                            style={{ cursor: 'pointer' }}
                          />
                        </Grid>
                      </React.Fragment>
                    ))}
                </Grid>
                <Grid item xs={12}>
                  <Button
                    onClick={handleAdd}
                    startIcon={<IconPlus fontSize="0.875rem" />}
                    style={{ textTransform: 'unset' }}
                    variant="text"
                    size="small"
                    color="primary"
                  >
                    Agregar opción
                  </Button>
                </Grid>
              </Grid>
            )}
          </MainCard>
          <Divider />
          <CardActions sx={{ justifyContent: 'space-between' }}>
            <ActionsButton />
          </CardActions>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EvaluationEditQuestion;
