import * as React from 'react';
import { useEffect } from 'react';

// material-ui
import {
  Button,
  Grid,
  Stack,
  TextField,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl,
  Autocomplete,
  FormHelperText,
  InputAdornment,
  CircularProgress,
  Typography,
  Tooltip,
  // Divider,
} from '@material-ui/core';

import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import {
  fireDialogSubjectsRegistered,
  fireDialogAcademicFormation,
  fireDialogLaboralExperience,
  setPeriodId,
} from '../../slices/requisitionsSlice';

// project imports
// import AnimateButton from './../../../../ui-component/extended/AnimateButton';
import AnimateButton from '../../../../shared/components/extended/AnimateButton';
import InputLabel from '../../../../shared/components/forms/InputLabel';

// simple Tables
import SubjectsSimpleTable from '../../components/simpleTables/SubjectsSimpleTable';
import AcademicFormationSimpleTable from '../simpleTables/AcademicFormationSimpleTable';
import LaboralExperienceSimpleTable from '../../components/simpleTables/LaboralExperienceSimpleTable';

import { Plus } from 'phosphor-react';

//data available
import {
  useGetAvailablesOffersQuery,
  useGetAcademicOfferingQuery,
  useGetPeriodAcademicOfferingQuery,
} from '../../../academic-offerings/slices/academicOfferingsApiSlice';
import { useGetHourhandsByPeriodQuery } from '../../../periods/slices/periodsApiSlice';
// import { useGetPersonInformationQuery } from '../../slices/requisitionsApiSlice';

// Dialogs
import SubjectsRegisteredDialog from '../Dialogs/SubjectsRegisteredDialog';
import AcademicFormationDialog from '../Dialogs/AcademicFormationDialog';
import LaboralExperienceDialog from '../Dialogs/LaboralExperienceDialog';

// third-party
import { useFormik } from 'formik';
import * as yup from 'yup';

//interface

import { Period } from '../../../academic-offerings/interfaces/periods.interface';

// slice

import { setEducationLvlId } from '../../slices/requisitionsSlice';
import { isValid, parseISO } from 'date-fns';
import LocalizedDatePicker from '../../../../shared/components/controlled/LocalizedDatePicker';
import { useTranslation } from 'react-i18next';

const validationSchema = yup.object({
  offerId: yup.string(),
  hourhandId: yup.string().when('offerId', {
    is: '3',
    then: yup.string().required('El campo horario es requerido cuando la oferta es posgrado'),
  }),

  ////
  beforePeriodId: yup.string().when('typeContract', {
    is: 'ri',
    then: yup.string().required('El campo es requerido cuando es reingreso'),
  }),
  newPeriodId: yup.string().required('El período nuevo es requerido'),
  educationLevelId: yup.string(),

  ///
  tipoVinculacion: yup.string(),
  tipoDedicacion: yup.string(),
  typePayment: yup.string(),
  salary: yup.string().required('El salario es requerido'),
  typeContract: yup.string(),
  startDate: yup.date(),

  email: yup.string().email('Debe ser un email').required('El email es requerido'),
  cardName: yup.string(),
  cardNumber: yup.string(),

  subjectsNumber: yup
    .number()
    .min(1, 'Se debe ingresar al menos una materia')
    .required('Al menos una materia es requerida'),
});

//-----------------------|| FORM WIZARD - VALIDATION  ||-----------------------//

export type PaymentData = {
  hourhandId?: string;
  offerId?: number;
  beforePeriodId?: number;
  newPeriodId?: number;
  educationLevelId?: number;
  lastPeriod?: Period;
  newPeriod?: Period;
  email?: string;
  //
  tipoVinculacion?: string;
  tipoDedicacion?: string;
  typePayment?: string;
  salary?: number;
  typeContract?: string;
  startDate?: Date;
  endDate?: Date;
  ///
  userId?: number;
  //
  cardName?: string;
  cardNumber?: number;
};
export interface PaymentFormProps {
  paymentData: PaymentData;
  setPaymentData: (d: PaymentData) => void;
  handleNext: () => void;
  handleBack: () => void;
  setErrorIndex: (i: number | null) => void;
}

export default function PaymentForm({
  paymentData,
  setPaymentData,
  handleNext,
  handleBack,
  setErrorIndex,
}: PaymentFormProps) {
  const { t } = useTranslation();

  const { collaboratorRequisition } = useAppSelector((state) => state.requisitionTeacher);
  const { currentProfile } = useAppSelector((state) => state.access);

  const { listSubjects } = useAppSelector((state) => state.requisitionTeacher);
  const { listAcademicFormation } = useAppSelector((state) => state.requisitionTeacher);
  const { listLaboralExperience } = useAppSelector((state) => state.requisitionTeacher);

  const [subjectRegisteredId, setSubjectRegisteredId] = React.useState<number | null>(null);
  const [academicFormationId, setAcademicFormationId] = React.useState<number | null>(null);
  const [laboralExperienceId, setLaboralExperienceId] = React.useState<number | null>(null);

  const selectSubjectRegistered = (id: number) => {
    setSubjectRegisteredId(id);
    dispatch(fireDialogSubjectsRegistered(true));
  };

  const selectAcademicFormation = (id: number) => {
    setAcademicFormationId(id);
    dispatch(fireDialogAcademicFormation(true));
  };

  const selectLaboralExperience = (id: number) => {
    setLaboralExperienceId(id);
    dispatch(fireDialogLaboralExperience(true));
  };

  const { userPersonRequisition, personRequisition } = useAppSelector(
    (state) => state.requisitionTeacher
  );
  const { setFieldValue, errors, touched, values, handleSubmit, handleChange, getFieldProps } =
    useFormik({
      initialValues: {
        offerId:
          paymentData.offerId ||
          Number(collaboratorRequisition?.education_level_principal?.offer_id) ||
          0,
        hourhandId: paymentData.hourhandId,

        beforePeriodId: collaboratorRequisition?.last_period?.id || paymentData.beforePeriodId,
        newPeriodId: paymentData.newPeriodId,
        educationLevelId:
          paymentData.educationLevelId ||
          Number(collaboratorRequisition?.education_level_principal?.id),
        ///
        tipoVinculacion: paymentData.tipoVinculacion || '0',
        tipoDedicacion: paymentData.tipoDedicacion || 'TC',
        typePayment: paymentData.typePayment || 's',
        salary: paymentData.salary,
        typeContract: collaboratorRequisition?.last_period?.id ? 'ri' : 'pv',
        startDate: new Date(),
        endDate: new Date(),

        cardName: paymentData.cardName,
        cardNumber: paymentData.cardNumber,
        email: paymentData.email || collaboratorRequisition?.coll_email || '',
        subjectsNumber: 0,

        beforePeriod: {
          id: 0,
          name: collaboratorRequisition?.last_period?.per_name,
          init: collaboratorRequisition?.last_period?.id,
        },
        newPeriod: paymentData.newPeriod || undefined,
        lastPeriod: paymentData.lastPeriod || undefined,
      },
      validationSchema: validationSchema,
      onSubmit: (values) => {
        setPaymentData({
          offerId: values.offerId,
          hourhandId: values.hourhandId,

          ///
          email: values.email,

          ///
          beforePeriodId: values.beforePeriodId,
          newPeriodId: values.newPeriodId,
          educationLevelId: values.educationLevelId,

          ///
          tipoVinculacion: values.tipoVinculacion,
          tipoDedicacion: values.tipoDedicacion,
          typePayment: values.typePayment,
          salary: values.salary,
          typeContract: values.typeContract,
          startDate: values.startDate,
          endDate: values.endDate ? values.endDate : undefined,

          //
          userId:
            Number(userPersonRequisition?.id) ||
            Number(personRequisition?.user_id) ||
            Number(collaboratorRequisition?.user_id),

          cardName: values.cardName,
          cardNumber: values.cardNumber,

          lastPeriod: values.lastPeriod,
          newPeriod: values.newPeriod,
        });
        handleNext();
      },
    });

  const profileId = currentProfile?.profileId!;

  const { data: availablesOffers = [], isLoading: isLoadingOffers } = useGetAvailablesOffersQuery({
    profileId,
  });

  const { data: availablesPeriodsByOffer = [], isLoading: isLoadingPeriodsByOffer } =
    useGetPeriodAcademicOfferingQuery(
      {
        profileId,
        academicOfferingId: values.offerId?.toString(),
        current: true,
      },
      { skip: !values.offerId }
    );

  const { isLoading: isLoadingInfoOffer, data: availableInfoOffer } = useGetAcademicOfferingQuery(
    {
      profileId,
      academicOfferingId:
        values.offerId || Number(collaboratorRequisition?.education_level_principal?.offer_id),
    },
    {
      skip:
        !values.offerId && !Number(collaboratorRequisition?.education_level_principal?.offer_id),
    }
  );

  const { isFetching: isLoadingHourhands, data: availableHourhands } = useGetHourhandsByPeriodQuery(
    {
      profileId,
      periodId: values.newPeriodId || 0,
    },
    { skip: !values.newPeriodId }
  );

  const { showSubjectsRegistered, showAcademicFormation, showLaboralExperience } = useAppSelector(
    (state) => state.requisitionTeacher
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setEducationLvlId(values.educationLevelId || values.offerId));
  }, [dispatch, values.educationLevelId, values.offerId]);

  useEffect(() => {
    if (values.newPeriodId) {
      dispatch(setPeriodId(values.newPeriodId));
    }
  }, [dispatch, values.newPeriodId]);

  useEffect(() => {
    setFieldValue('subjectsNumber', listSubjects.length);
  }, [listSubjects, setFieldValue]);

  useEffect(() => {
    if (collaboratorRequisition?.last_period) {
      setFieldValue('lastPeriod', collaboratorRequisition?.last_period);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collaboratorRequisition?.id, setFieldValue]);

  useEffect(() => {
    const period = (availablesPeriodsByOffer as unknown as Period[])?.find(
      (period) => period.id === values.newPeriodId
    );

    if (!period) return;

    setFieldValue(
      'startDate',
      period?.start_process ? parseISO(period?.start_process as unknown as string) : null
    );
    setFieldValue(
      'endDate',
      period?.end_process ? parseISO(period?.end_process as unknown as string) : null
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.newPeriodId, availablesPeriodsByOffer]);

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit}>
        <Grid container item xs={12} spacing={1} alignItems="flex-start">
          <Grid item container xs={12} marginY={2}>
            <InputLabel>{t('academic.requisition.edit.wizard.step2.user')}</InputLabel>
            <Grid item xs={12}>
              <Typography variant="h3" color="inherit">
                {`${
                  userPersonRequisition?.person?.pers_full_name || personRequisition?.pers_full_name
                }`}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container xs={12} sm={6} spacing={1}>
            <Grid item xs={12}>
              <InputLabel>{t('academic.requisition.edit.wizard.step2.edu_level')}</InputLabel>
              {isLoadingOffers ? (
                <>
                  <TextField
                    disabled
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <CircularProgress size={25} />
                        </InputAdornment>
                      ),
                    }}
                    placeholder={t('academic.requisition.edit.wizard.step2.edu_level.loading')}
                  />
                </>
              ) : (
                <Autocomplete
                  options={availablesOffers}
                  loading={isLoadingOffers}
                  loadingText={t('academic.requisition.edit.wizard.step2.edu_level.loading')}
                  noOptionsText={t('academic.requisition.edit.wizard.step2.edu_level.no_data')}
                  // disabled={
                  //   collaboratorRequisition?.education_level_principal?.offer_id ? true : false
                  // }
                  getOptionLabel={(option) => option.off_name}
                  value={
                    availablesOffers.find((e) => e.id === values.offerId) ||
                    availablesOffers.find(
                      (e) =>
                        e.id ===
                        Number(collaboratorRequisition?.education_level_principal?.offer_id)
                    )
                  }
                  onChange={(_, value) => {
                    setFieldValue('offerId', value?.id);
                    setFieldValue('hourhandId', '');
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={t(
                        'academic.requisition.edit.wizard.step2.edu_level.placeholder'
                      )}
                      error={Boolean(errors.offerId && touched.offerId)}
                    />
                  )}
                  fullWidth
                  clearIcon={null}
                />
              )}
              {errors.offerId && touched.offerId && (
                <FormHelperText error>{errors.offerId}</FormHelperText>
              )}
            </Grid>
            <Grid item xs={12}>
              <InputLabel>{t('academic.requisition.edit.wizard.step2.academic_unit')}</InputLabel>

              {isLoadingInfoOffer ? (
                <>
                  <TextField
                    disabled
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <CircularProgress size={25} />
                        </InputAdornment>
                      ),
                    }}
                    placeholder={t('academic.requisition.edit.wizard.step2.academic_unit.loading')}
                  />
                </>
              ) : (
                <Autocomplete
                  options={availableInfoOffer?.education_levels || []}
                  loading={isLoadingInfoOffer}
                  // disabled={collaboratorRequisition?.education_level_principal?.id ? true : false}
                  loadingText={t('academic.requisition.edit.wizard.step2.academic_unit.loading')}
                  noOptionsText={t('academic.requisition.edit.wizard.step2.academic_unit.no_data')}
                  value={
                    availableInfoOffer?.education_levels.find(
                      (e) => e.id === values.educationLevelId
                    ) ||
                    availableInfoOffer?.education_levels.find(
                      (e) => e.id === Number(collaboratorRequisition?.education_level_principal?.id)
                    )
                  }
                  getOptionLabel={(option) => option.edu_name}
                  onChange={(_, value) => {
                    setFieldValue('educationLevelId', value?.id);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={t(
                        'academic.requisition.edit.wizard.step2.academic_unit.placeholder'
                      )}
                      error={Boolean(errors.educationLevelId && touched.educationLevelId)}
                    />
                  )}
                  fullWidth
                  clearIcon={null}
                />
              )}
              {errors.educationLevelId && touched.educationLevelId && (
                <FormHelperText error>{errors.educationLevelId}</FormHelperText>
              )}
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Correo institucional</InputLabel>
              <TextField
                {...getFieldProps('email')}
                error={Boolean(errors.email && touched.email)}
                placeholder={'Ingrese el correo institucional'}
                fullWidth
              />
              {errors.email && touched.email && (
                <FormHelperText error>{t(errors.email)}</FormHelperText>
              )}
            </Grid>
          </Grid>

          <Grid item container xs={12} sm={6} spacing={1}>
            <Grid item xs={12}>
              <InputLabel>{t('academic.requisition.edit.wizard.step2.period')}</InputLabel>
              {isLoadingPeriodsByOffer ? (
                <>
                  <TextField
                    disabled
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <CircularProgress size={25} />
                        </InputAdornment>
                      ),
                    }}
                    placeholder={t('academic.requisition.edit.wizard.step2.period.loading')}
                  />
                </>
              ) : (
                <Autocomplete
                  options={availablesPeriodsByOffer as unknown as Period[]}
                  loading={isLoadingPeriodsByOffer}
                  loadingText={t('academic.requisition.edit.wizard.step2.period.loading')}
                  noOptionsText={t('academic.requisition.edit.wizard.step2.period.no_data')}
                  value={(availablesPeriodsByOffer as unknown as Period[])?.find(
                    (e) => e.id === values.newPeriodId
                  )}
                  getOptionLabel={(option) => option.per_name}
                  onChange={(_, value) => {
                    setFieldValue('newPeriodId', value?.id);
                    setFieldValue('hourhandId', '');
                    setFieldValue('newPeriod', value);

                    setFieldValue(
                      'startDate',
                      isValid(parseISO(value?.start_process as unknown as string))
                        ? parseISO(value?.start_process! as unknown as string)
                        : null
                    );
                    setFieldValue(
                      'endDate',
                      isValid(parseISO(value?.end_process as unknown as string))
                        ? parseISO(value?.end_process! as unknown as string)
                        : null
                    );

                    if (!values.beforePeriod.init && values.typeContract === 'ri') {
                      setFieldValue('beforePeriodId', value?.id);
                      setFieldValue('beforePeriod', {
                        ...values.beforePeriod,
                        id: value?.id,
                        name: value?.per_name,
                      });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={t('academic.requisition.edit.wizard.step2.period.placeholder')}
                      error={Boolean(errors.newPeriodId && touched.newPeriodId)}
                    />
                  )}
                  fullWidth
                  clearIcon={null}
                />
              )}
              {errors.newPeriodId && touched.newPeriodId && (
                <FormHelperText error>{errors.newPeriodId}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12} md={6}>
              <InputLabel>{t('academic.requisition.edit.wizard.step2.start_date')}</InputLabel>
              <LocalizedDatePicker
                placeholder={t('academic.requisition.edit.wizard.step2.start_date.placeholder')}
                value={values.startDate || null}
                // disabled={true}
                onChange={(newValue) => {
                  setFieldValue('startDate', newValue);
                }}
              />

              {errors.startDate && touched.startDate && (
                <FormHelperText error>{errors.startDate}</FormHelperText>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel>{t('academic.requisition.edit.wizard.step2.end_date')}</InputLabel>
              <LocalizedDatePicker
                placeholder={t('academic.requisition.edit.wizard.step2.end_date.placeholder')}
                value={values.endDate || null}
                // disabled={true}
                onChange={(newValue) => {
                  setFieldValue('endDate', newValue);
                }}
              />

              {errors.endDate && touched.endDate && (
                <FormHelperText error>{errors.endDate}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12}>
              <InputLabel>{t('academic.requisition.edit.wizard.step2.schedule')}</InputLabel>
              {isLoadingHourhands ? (
                <>
                  <TextField
                    disabled
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <CircularProgress size={25} />
                        </InputAdornment>
                      ),
                    }}
                    placeholder={t('academic.requisition.edit.wizard.step2.schedule.loading')}
                  />
                </>
              ) : (
                <Autocomplete
                  disabled={values.offerId !== 3}
                  options={availableHourhands || []}
                  loading={isLoadingHourhands}
                  value={
                    availableHourhands?.find((e) => e.id.toString() === values.hourhandId) || null
                  }
                  loadingText={t('academic.requisition.edit.wizard.step2.schedule.loading')}
                  getOptionLabel={(option) => option.hour_description}
                  onChange={(_, value) => {
                    setFieldValue('hourhandId', value?.id.toString());
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={
                        values.offerId === 3
                          ? t('academic.requisition.edit.wizard.step2.schedule.placeholder')
                          : t('academic.requisition.edit.wizard.step2.schedule.available')
                      }
                      error={Boolean(errors.hourhandId && touched.hourhandId)}
                    />
                  )}
                  fullWidth
                  clearIcon={null}
                />
              )}
              {errors.hourhandId && touched.hourhandId && (
                <FormHelperText error>{errors.hourhandId}</FormHelperText>
              )}
            </Grid>
            <Grid item container spacing={2} xs={12}>
              <Grid item xs={12} md={6}>
                <InputLabel>{t('academic.requisition.edit.wizard.step2.date')}</InputLabel>
                <LocalizedDatePicker
                  disabled={true}
                  value={values.cardName}
                  onChange={(newValue) => {
                    setFieldValue('cardName', newValue);
                  }}
                  placeholder={t('academic.requisition.edit.wizard.step2.date.placeholder')}
                />
                {errors.cardName && touched.cardName && (
                  <FormHelperText error>{errors.cardName}</FormHelperText>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item container spacing={1} xs={12} sm={6}>
            <Grid item xs={12}>
              <InputLabel>
                {t('academic.requisition.edit.wizard.step2.vinculation_type')}
              </InputLabel>
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  aria-label="tipoVinculacion"
                  name="radio-buttons-group"
                  value={values.tipoVinculacion || '0'}
                  onChange={(_, value) => {
                    setFieldValue('tipoVinculacion', value);
                  }}
                >
                  <FormControlLabel
                    value="0"
                    control={<Radio />}
                    label={t(
                      'academic.requisition.edit.wizard.step2.vinculation_type.prof_service'
                    )}
                  />
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label={t('academic.requisition.edit.wizard.step2.vinculation_type.dep_service')}
                  />
                </RadioGroup>
              </FormControl>
              {errors.tipoVinculacion && touched.tipoVinculacion && (
                <FormHelperText error>{errors.tipoVinculacion}</FormHelperText>
              )}
            </Grid>
          </Grid>
          <Grid item container spacing={1} xs={12} sm={6}>
            <Grid item xs={12}>
              <InputLabel>{t('academic.requisition.edit.wizard.step2.contract_type')} </InputLabel>
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  aria-label="tipoDedicacion"
                  value={values.tipoDedicacion || 'TC'}
                  name="radio-buttons-group"
                  onChange={(_, value) => {
                    setFieldValue('tipoDedicacion', value);
                  }}
                >
                  <FormControlLabel
                    value="TC"
                    control={<Radio />}
                    label={t('academic.requisition.edit.wizard.step2.contract_type.full_time')}
                  />
                  <FormControlLabel
                    value="MT"
                    control={<Radio />}
                    label={t('academic.requisition.edit.wizard.step2.contract_type.half_time')}
                  />
                  <FormControlLabel
                    value="PA"
                    control={<Radio />}
                    label={t('academic.requisition.edit.wizard.step2.contract_type.partial')}
                  />
                </RadioGroup>
              </FormControl>
              {errors.tipoDedicacion && touched.tipoDedicacion && (
                <FormHelperText error>{errors.tipoDedicacion}</FormHelperText>
              )}
            </Grid>
          </Grid>
          <Grid item container spacing={1} xs={12} sm={6}>
            <Grid item xs={6}>
              <InputLabel>{t('academic.requisition.edit.wizard.step2.payment_type')}</InputLabel>
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  aria-label="typePayment"
                  value={values.typePayment || 's'}
                  name="radio-buttons-group"
                  onChange={(_, value) => {
                    setFieldValue('typePayment', value);
                  }}
                >
                  <FormControlLabel
                    value="s"
                    control={<Radio />}
                    label={t('academic.requisition.edit.wizard.step2.payment_type.salary')}
                  />
                  <FormControlLabel
                    value="h"
                    control={<Radio />}
                    label={t('academic.requisition.edit.wizard.step2.payment_type.hour')}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={3} justifyItems="self-start">
              <InputLabel>{t('academic.requisition.edit.wizard.step2.salary')}</InputLabel>

              <TextField
                id="salary"
                name="salary"
                defaultValue={values.salary}
                onChange={handleChange}
                error={touched.salary && Boolean(errors.salary)}
                helperText={touched.salary && errors.salary}
                placeholder={t('academic.requisition.edit.wizard.step2.salary.placeholder')}
                fullWidth
                autoComplete="cc-name"
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <InputLabel>{t('academic.requisition.edit.wizard.step2.contract_time')}</InputLabel>
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  aria-label="typeContract"
                  value={values.typeContract || 'pv'}
                  name="radio-buttons-group"
                  onChange={(_, value) => {
                    setFieldValue('typeContract', value);
                  }}
                >
                  <FormControlLabel
                    disabled
                    value="pv"
                    control={<Radio />}
                    label={t('academic.requisition.edit.wizard.step2.contract_time.first_time')}
                  />
                  <FormControlLabel
                    disabled
                    value="ri"
                    control={<Radio />}
                    label={t('academic.requisition.edit.wizard.step2.contract_time.re_entry')}
                  />
                </RadioGroup>
              </FormControl>
              {errors.typeContract && touched.typeContract && (
                <FormHelperText error>{errors.typeContract}</FormHelperText>
              )}
            </Grid>
          </Grid>
          <Grid item container xs={12} sm={6}>
            <InputLabel>{t('academic.requisition.edit.wizard.step2.last_period')}</InputLabel>
            <TextField
              disabled
              fullWidth
              value={collaboratorRequisition?.last_period?.per_name || 'Sin asignar'}
            />
            {/* {isLoadingPeriodsByOffer ? (
              <>
                <TextField
                  disabled
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <CircularProgress size={25} />
                      </InputAdornment>
                    ),
                  }}
                  placeholder={t('academic.requisition.edit.wizard.step2.last_period.loading')}
                />
              </>
            ) : (
              <Autocomplete
                options={availablesPeriodsByOffer as unknown as Period[]}
                loading={isLoadingPeriodsByOffer}
                disabled={values.typeContract === 'pv'}
                loadingText={t('academic.requisition.edit.wizard.step2.last_period.loading')}
                noOptionsText={t('academic.requisition.edit.wizard.step2.last_period.no_data')}
                getOptionLabel={(option) => option.per_name}
                value={(availablesPeriodsByOffer as unknown as Period[]).find(
                  (e) => e.id === values.beforePeriodId
                )}
                onChange={(_, value) => {
                  setFieldValue('beforePeriodId', value?.id);
                }}
                disab
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={t(
                      'academic.requisition.edit.wizard.step2.last_period.placeholder'
                    )}
                    error={Boolean(errors.beforePeriodId && touched.beforePeriodId)}
                  />
                )}
                fullWidth
                clearIcon={null}
              />
            )} */}
            {errors.beforePeriodId && touched.beforePeriodId && (
              <FormHelperText error>{errors.beforePeriodId}</FormHelperText>
            )}
          </Grid>
          {/* 
          <Grid item xs={12}>
            <Divider>Asignaturas</Divider>
          </Grid> */}
          <Grid
            item
            container
            spacing={1}
            xs={12}
            sm={12}
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            mt={1}
          >
            <Tooltip title={t('academic.requisition.edit.wizard.step2.add_subject')}>
              <Button
                variant="contained"
                startIcon={<Plus size={15} />}
                onClick={() => dispatch(fireDialogSubjectsRegistered(true))}
              >
                {t('shared.add_button.label')}
              </Button>
            </Tooltip>
          </Grid>
          <Grid item container spacing={1} xs={12} sm={12}>
            {errors.subjectsNumber && touched.subjectsNumber && (
              <FormHelperText error>{errors.subjectsNumber}</FormHelperText>
            )}
            <SubjectsSimpleTable
              data={listSubjects}
              selectSubjectRegistered={selectSubjectRegistered}
            />
          </Grid>
          <Grid
            item
            container
            spacing={1}
            xs={12}
            sm={12}
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            mt={1}
          >
            <Tooltip title={t('academic.requisition.edit.wizard.step2.add_academic_form')}>
              <Button
                onClick={() => dispatch(fireDialogAcademicFormation(true))}
                variant="contained"
                startIcon={<Plus size={15} />}
              >
                {t('shared.add_button.label')}
              </Button>
            </Tooltip>
          </Grid>
          <Grid item container spacing={1} xs={12} sm={12}>
            <InputLabel>{t('academic.requisition.edit.wizard.step2.academic_form')}</InputLabel>
            <AcademicFormationSimpleTable
              data={listAcademicFormation}
              selectAcademicFormation={selectAcademicFormation}
            />
          </Grid>
          <Grid
            item
            container
            spacing={1}
            xs={12}
            sm={12}
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            mt={1}
          >
            <Tooltip title={t('academic.requisition.edit.wizard.step2.add_laboral_experience')}>
              <Button
                onClick={() => dispatch(fireDialogLaboralExperience(true))}
                variant="contained"
                startIcon={<Plus size={15} />}
              >
                {t('shared.add_button.label')}
              </Button>
            </Tooltip>
          </Grid>
          <Grid item container spacing={1} xs={12} sm={12}>
            <InputLabel>
              {t('academic.requisition.edit.wizard.step2.laboral_experience')}
            </InputLabel>
            <LaboralExperienceSimpleTable
              data={listLaboralExperience}
              selectLaboralExperience={selectLaboralExperience}
            />
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="right">
              <Button variant="contained" onClick={handleBack} sx={{ my: 3, ml: 1 }}>
                {t('academic.requisition.edit.wizard.back')}
              </Button>
              <AnimateButton>
                <Button
                  variant="contained"
                  type="submit"
                  sx={{ my: 3, ml: 1 }}
                  onClick={() => setErrorIndex(1)}
                >
                  {t('academic.requisition.edit.wizard.next')}
                </Button>
              </AnimateButton>
            </Stack>
          </Grid>
        </Grid>
        {showSubjectsRegistered && (
          <SubjectsRegisteredDialog
            open={showSubjectsRegistered}
            handleCloseDialog={() => {
              dispatch(fireDialogSubjectsRegistered(false));
              setSubjectRegisteredId(null);
            }}
            subject={subjectRegisteredId !== null ? listSubjects?.[subjectRegisteredId] : undefined}
          />
        )}
        {showAcademicFormation && (
          <AcademicFormationDialog
            open={showAcademicFormation}
            handleCloseDialog={() => {
              dispatch(fireDialogAcademicFormation(false));
              setAcademicFormationId(null);
            }}
            academicFormation={
              academicFormationId !== null
                ? listAcademicFormation?.find((e) => e.id === academicFormationId)
                : undefined
            }
          />
        )}
        {showLaboralExperience && (
          <LaboralExperienceDialog
            open={showLaboralExperience}
            handleCloseDialog={() => {
              dispatch(fireDialogLaboralExperience(false));
              setLaboralExperienceId(null);
            }}
            laboralExperience={
              laboralExperienceId !== null
                ? listLaboralExperience?.find((e) => e.id === laboralExperienceId)
                : undefined
            }
          />
        )}
      </form>
    </React.Fragment>
  );
}
