import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../../hooks/useRedux';
import ConfirmAlert from '../../../../shared/components/dialogs/ConfirmAlert';
import { useApprovePlanningMutation } from '../../slices/acPlanPostgraduateApiSlice';
import {
  setPostgraduatePlanificationApproveDialogOpen,
  setPostGraduatePlanificationIdSelected,
} from '../../slices/acPlanPostgraduateSlice';

const ApprovePlanificationDialog = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  // const { postGraduatePlanificationIdSelected } = useAppSelector(
  //   (state) => state.academicPlanningPosgraduate
  // );

  const [, { isLoading, isError, isSuccess, error }] = useApprovePlanningMutation();

  const handleSubmit = () => {
    // approvePlanning({
    //   id: postGraduatePlanificationIdSelected!,
    // });
  };

  const handleClose = () => {
    dispatch(setPostgraduatePlanificationApproveDialogOpen(false));
    dispatch(setPostGraduatePlanificationIdSelected(null));
  };

  return (
    <ConfirmAlert
      error={error as string}
      isError={isError}
      isLoading={isLoading}
      isSuccess={isSuccess}
      handleDelete={handleSubmit}
      onClose={handleClose}
      open={true}
      confirmBtnMessage={t('posgraduate.approve.confirm')}
      title={t('posgraduate.approve.title')}
      subtitle={t('posgraduate.approve.subtitle')}
      successMessage={t('posgraduate.approve.success')}
    />
  );
};

export default ApprovePlanificationDialog;
