import { Grid, Typography } from '@material-ui/core';

import MainCard from '../../../shared/components/cards/MainCard';
import ContificoMigrationsWizard from '../components/ContificoMigrationsWizard';
import { useParams, useRouteMatch } from 'react-router-dom';
import { WizardKeywords } from '../constants/helpers';

const ContificoMigrationsScreen = () => {
  const { step } = useParams<{ step?: WizardKeywords }>();
  const path = useRouteMatch();

  console.log({ path, step });

  // if (!step) return <Redirect to={`/migraciones-contifico/${steps[0]}`} />;

  return (
    <div>
      <Grid component={MainCard} sx={{ padding: 2 }}>
        <Grid sx={{ mb: 3 }}>
          <Typography variant="h2">Envío a Contífico</Typography>
        </Grid>
        <ContificoMigrationsWizard keyword={'actualizar-facturas'} />
      </Grid>
    </div>
  );
};

export default ContificoMigrationsScreen;
