import {
    Autocomplete,
    Button,
    CircularProgress,
    FormHelperText,
    Grid,
    InputLabel,
    TextField,
    Checkbox,
    FormGroup,
    FormControlLabel,
} from '@material-ui/core';

import { useTranslation } from 'react-i18next';

import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';

import { useAddCategoryMutation, useGetAllCategoriesQuery } from '../slices/categoryApiSlice';
import {/*  useAppDispatch, */ useAppSelector } from '../../../../hooks/useRedux';
import { CategoryValidationSchema } from '../constants/CategoryValidationSchema';
import { statusAvailables } from '../../../shared/constants/resourceStatus';
import { useState } from 'react';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import { renderSkeletonTable } from '../../../shared/helpers/render';

const AddForm = () => {
    const { t } = useTranslation();

    const { currentProfile } = useAppSelector((state) => state.access);
    const history = useHistory();
    const [intgrConti, setIntgrConti] = useState(false);

    const handleChangeIntCont = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIntgrConti(event.target.checked);
    };

    const [showAlerts, setShowAlerts] = useState(true);

    //Request
    const [addCategory, { isLoading, isError, error, isSuccess }] = useAddCategoryMutation();

    //Categories
    const { data: categories, isLoading: loadingCA } = useGetAllCategoriesQuery({
        profileId: currentProfile?.profileId!,
    })

    const typesUse = [
        { id: 1, label: 'PROD' },
        { id: 2, label: 'SERV' }
    ];

    const { getFieldProps, errors, touched, handleSubmit, setFieldValue } = useFormik({
        initialValues: {
            name: '',
            categoryId: '',
            description: '',
            statusId: statusAvailables[0].id || '',
            contifico: intgrConti,
            typesUse: typesUse.at(0)?.label || '',
        },
        validationSchema: CategoryValidationSchema,
        onSubmit: async (values) => {
            await addCategory({
                categoryPayload: {
                    user_profile_id: currentProfile?.profileId!,
                    cat_group: false,
                    cat_name: values.name,
                    cat_parent: values.categoryId === '' ? null : Number(values.categoryId),
                    cat_type_product: values.typesUse,
                    status_id: Number(values.statusId),
                    contifico: intgrConti,
                    cat_description : values.description
                }
            })
            setShowAlerts(true);
        },
    });

    return (
        <>
            {loadingCA ? renderSkeletonTable(5,5 ) :
                <form onSubmit={handleSubmit}>
                    <Grid container>
                        <Grid item xs={6}>
                            {isError && showAlerts && (
                                <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
                            )}

                            {isSuccess && showAlerts && (
                                <SuccessAlert
                                    message={t('financial.maintenance_cat.add.success_alert')}
                                    handleDismiss={() => setShowAlerts(false)}
                                />
                            )}
                        </Grid>
                        <Grid item container spacing={2} alignItems="center">
                            <Grid item xs={12} md={6} sx={{ mb: 1 }}>
                                <InputLabel>{t('financial.maintenance_cat.add.name_label')}</InputLabel>
                                <TextField
                                    fullWidth
                                    error={Boolean(errors.name && touched.name)}
                                    placeholder={t('financial.maintenance_cat.add.name_placeholder')}
                                    {...getFieldProps('name')}
                                />
                                {errors.name && touched.name && <FormHelperText error>{t(errors.name)}</FormHelperText>}
                            </Grid>
                        </Grid>
                        <Grid item container spacing={2} alignItems="center">
                            <Grid item xs={12} md={6} sx={{ mb: 1 }}>
                                <InputLabel>{t('financial.maintenance_cat.add.category_label')}</InputLabel>
                                <Autocomplete
                                    options={categories || []}
                                    getOptionLabel={(option) => option.cat_name}
                                    renderInput={(params) => (
                                        <TextField placeholder={t('financial.maintenance_cat.add.category_placeholder')} {...params} />
                                    )}
                                    onChange={(e, value) => setFieldValue('categoryId', value?.id || null)}
                                    fullWidth
                                    loading={loadingCA}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container spacing={2} alignItems="center">
                            <Grid item xs={12} md={6} sx={{ mb: 1 }}>
                                <InputLabel>{t('financial.maintenance_cat.add.type_use_label')}</InputLabel>
                                <Autocomplete
                                    options={typesUse}
                                    getOptionLabel={(option) => option.label}
                                    renderInput={(params) => (
                                        <TextField placeholder={t('financial.maintenance_cat.add.type_use_placeholder')} {...params} />
                                    )}
                                    onChange={(e, value) => setFieldValue('typeUse', value?.label)}
                                    fullWidth
                                    clearIcon={null}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container spacing={2} alignItems="center">
                            <Grid item xs={12} md={6} sx={{ mb: 1 }}>
                                <InputLabel>{t('financial.maintenance_cat.add.description_label')}</InputLabel>
                                <TextField
                                    fullWidth
                                    error={Boolean(errors.description && touched.description)}
                                    placeholder={t('financial.maintenance_cat.add.description_placeholder')}
                                    {...getFieldProps('description')}
                                />
                                {errors.description && touched.description && <FormHelperText error>{t(errors.description)}</FormHelperText>}
                            </Grid>
                        </Grid>
                        <Grid item xs={12} container spacing={2}>
                            <Grid item xs={12} md={6} sx={{ mb: 1 }}>
                                <InputLabel>{t('financial.maintenance_cat.add.status_label')}</InputLabel>
                                <Autocomplete
                                    options={statusAvailables}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => (
                                        <TextField {...params} />
                                    )}
                                    defaultValue={statusAvailables[0]}
                                    onChange={(e, value) => setFieldValue('statusId', value?.id || '')}
                                    fullWidth
                                    clearIcon={null}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} container spacing={2}>
                            <Grid item xs={4}>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox checked={intgrConti} onChange={handleChangeIntCont} />} label={t('financial.maintenance_cat.add.contifico_switch_label')} />
                                </FormGroup>
                            </Grid>
                        </Grid>


                        <Grid
                            item
                            container
                            alignItems="center"
                            justifyContent="flex-end"
                            spacing={2}
                            sx={{ mt: 1 }}
                        >
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    size="large"
                                    color="primary"
                                    onClick={() => history.goBack()}
                                >
                                    {t('financial.maintenance_cat.add.go_back_btn')}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    size="large"
                                    color="primary"
                                    disabled={isLoading}
                                    endIcon={isLoading && <CircularProgress size={20} />}
                                >
                                    {t('financial.maintenance_cat.add.add_btn')}
                                </Button>
                            </Grid>
                        </Grid>

                    </Grid>
                </form >
            }
        </>
    )
}

export default AddForm;