import { Grid, Typography } from '@material-ui/core';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import MainCard from '../../../shared/components/cards/MainCard';
import PosgraduatePlanningCohortDialog from '../components/PosgraduatePlanningCohortDialog';
import PosgraduatePlanningCohortsTable from '../components/PosgraduatePlanningCohortsTable';
import PostgraduatePlanningCohortDeleteDialog from '../components/PostgraduatePlanningCohortDeleteDialog';
import {
  setPosgraduatePlanningCohortFormDialogOpen,
  setPosgraduatePlanningCohortIdSelected,
} from '../slices/posgraduatePlanningCohortSlice';

const PosgraduatePlanningCohortsScreen = () => {
  const dispatch = useAppDispatch();

  const {
    posgraduatePlanningCohortIdSelected,
    posgraduatePlanningCohortDeleteDialogOpen,
    posgraduatePlanningCohortFormDialogOpen,
  } = useAppSelector((state) => state.posgraduatePlanningCohorts);

  const handleCloseFormDialog = () => {
    dispatch(setPosgraduatePlanningCohortFormDialogOpen(false));
    dispatch(setPosgraduatePlanningCohortIdSelected(null));
  };

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2" mb={2}>
          Planificación secuencial de cohortes
        </Typography>
      </Grid>
      <PosgraduatePlanningCohortsTable />
      {posgraduatePlanningCohortIdSelected && posgraduatePlanningCohortDeleteDialogOpen && (
        <PostgraduatePlanningCohortDeleteDialog />
      )}
      {posgraduatePlanningCohortFormDialogOpen && (
        <PosgraduatePlanningCohortDialog open handleCloseDialog={handleCloseFormDialog} />
      )}
    </Grid>
  );
};

export default PosgraduatePlanningCohortsScreen;
