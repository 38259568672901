import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import DeleteAlert from '../../../shared/components/dialogs/DeleteAlert';
import { useDeleteFolderMaterialsMutation } from '../slices/virtualClassroomTeacherApiSlice';
import { setDeleteModalOpen, resetFolderIdToDelete } from '../slices/virtualClassroomTeacherSlice';

const DeleteFoldersDialog = () => {
  const dispatch = useAppDispatch();

  const { deleteModalOpen, folderIdToDelete } = useAppSelector(
    (state) => state.virtualClassroomTeacher
  );

  const [deleteMaterials, { isLoading, isSuccess, isError, error }] =
    useDeleteFolderMaterialsMutation();

  const handleDelete = () => {
    deleteMaterials({ id: folderIdToDelete! });
  };

  const handleClose = () => {
    dispatch(setDeleteModalOpen(false));
    dispatch(resetFolderIdToDelete());
  };

  return (
    <DeleteAlert
      open={deleteModalOpen}
      onClose={handleClose}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      handleDelete={handleDelete}
      error={error as string}
    />
  );
};

export default DeleteFoldersDialog;
