import { useEffect, useState } from 'react';
import { useHistory, Link, useRouteMatch } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from '../../../../hooks/useRedux';
import { useGetAcademicOfferingsQuery } from '../slices/academicOfferingsApiSlice';

import StatusChip from '../../../shared/components/badges/StatusChip';
import EditButton from '../../../shared/components/tables/buttons/EditButton';
import PeriodButton from '../../../shared/components/tables/buttons/PeriodButton';

import DeleteButton from '../../../shared/components/tables/buttons/DeleteButton';
import AddButton from '../../../shared/components/tables/buttons/AddButton';

import {
  openDeleteModalWith,
  setAcademicOfferingsList,
  updateAcademicOfferingTable,
} from '../slices/academicOfferingsSlice';

import useSharedGuard from '../../../../hooks/useSharedGuard';
import { subPermissionKeys } from '../constants/permissions';
import SearchPaginatedTable, {
  FilterArrow,
} from '../../../shared/components/tables/SearchPaginatedTable';
import { useTableSearch } from '../../../../hooks/useTableSearch';

const AcademicOfferingTable = () => {
  const { currentProfile } = useAppSelector((state) => state.access);

  const dispatch = useAppDispatch();

  const { hasAccess } = useSharedGuard(subPermissionKeys);

  const { path } = useRouteMatch();
  const history = useHistory();

  const { page, perPage, search } = useAppSelector(
    (state) => state.academicOfferings.academicOfferingTable
  );

  const [filterArrow, setFilterArrow] = useState<FilterArrow>({
    name: 'off_name',
    direction: 'asc',
  });

  const { setPage, setPerPage, setSearch } = useTableSearch({
    page,
    perPage,
    search,

    updateFunction: updateAcademicOfferingTable,
  });

  const { isLoading, error, data, isFetching } = useGetAcademicOfferingsQuery(
    {
      profileId: currentProfile?.profileId!,
      page,
      perPage,
      search,
      typeSort: filterArrow?.direction || 'asc',
      sort: filterArrow?.name || 'off_name',
    },
    { refetchOnMountOrArgChange: 30 }
  );

  useEffect(() => {
    if (data) {
      dispatch(setAcademicOfferingsList(data.data));
    }
  }, [data, dispatch]);

  const handleDelete = async (periodId: number) => {
    dispatch(openDeleteModalWith(periodId));
  };

  const goToAdd = () => {
    history.push(`${path}/agregar`);
  };

  const datafetch = data?.data!.map((d, index) => ({
    id: index + ((page - 1) * perPage + 1),
    name: d.off_name,
    description: d.off_description,
    statusId: d.status_id,
    options: {
      eduLevelId: d.id,
    },
  }));

  return (
    <SearchPaginatedTable
      data={datafetch!}
      headers={{
        id: 'N°',
        name: 'Nombre',
        description: 'Descripción',
        statusId: 'Estado',
        options: 'Opciones',
      }}
      keyExtractor={(item) => item.id.toString()}
      searchPlacehoder="Buscar nivel educativo"
      listFilterArrows={{
        name: 'off_name',
        description: 'off_description',
        statusId: 'status_id',
      }}
      filterArrow={filterArrow}
      setFilterArrow={setFilterArrow}
      isLoading={isLoading}
      isFetching={isFetching}
      error={error}
      perPage={perPage}
      setPerPage={setPerPage}
      total={data?.total || 0}
      page={page}
      setPage={setPage}
      numHeader={5}
      search={search}
      setSearch={setSearch}
      customRenderers={{
        statusId: (row) => <StatusChip statusId={Number(row.statusId)} />,
        options: (row) => (
          <>
            {hasAccess('edit') && (
              <Link to={`${path}/${row.options.eduLevelId}/editar`}>
                <EditButton />
              </Link>
            )}
            <Link to={`${path}/${row.options.eduLevelId}/periodos`}>
              <PeriodButton />
            </Link>
            {hasAccess('delete') && (
              <DeleteButton handleDelete={() => handleDelete(row.options.eduLevelId)} />
            )}
            {!hasAccess('edit') && !hasAccess('delete')}
          </>
        ),
      }}
      ActionButtons={<>{hasAccess('create') && <AddButton size="large" onClick={goToAdd} />}</>}
      customHeadersCellsProperties={{
        id: { align: 'center' },
        name: { align: 'center' },
        description: { align: 'center' },
        statusId: { align: 'center' },
        options: { align: 'center' },
      }}
      customDataCellsProperties={{
        id: { align: 'center' },
        name: { align: 'center' },
        description: { align: 'center' },
        statusId: { align: 'center' },
        options: { align: 'center' },
      }}
    />
  );
};

export default AcademicOfferingTable;
