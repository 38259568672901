import { Grid } from '@material-ui/core';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PropsFormEdit } from '..';
import { useAppDispatch } from '../../../../../../hooks/useRedux';
import DeleteButton from '../../../../../shared/components/tables/buttons/DeleteButton';
import EditButton from '../../../../../shared/components/tables/buttons/EditButton';
import SearchPaginatedTable, {
  FilterArrow,
} from '../../../../../shared/components/tables/SearchPaginatedTable';
import { customSort } from '../../../../../shared/helpers';
import { setStudentJobIdSelected, setStudentJobsList } from '../../../slices/studentsSlice';
import DeleteJobDialog from './DeleteJobDialog';

const JobsTable = ({ data }: PropsFormEdit) => {
  const { studentId } = useParams<{ studentId: string }>();

  const dispatch = useAppDispatch();

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const [search, setSearch] = useState('');
  const [filterArrow, setFilterArrow] = useState<FilterArrow>(null!);

  const [idSelected, setIdSelected] = useState<number | null>(null);

  useEffect(() => {
    dispatch(setStudentJobsList(data?.person_job || []));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.person_job]);

  const datafetch = useMemo(() => {
    const dataFiltered =
      data?.person_job?.map((item, idx) => ({
        organization: item.per_job_organization || '-',
        position: item.per_job_position || '-',
        start_date: item.per_job_start_date || '-',
        end_date: item.per_job_end_date || '-',
        options: item,
      })) || [];

    if (!filterArrow) return dataFiltered;

    const firstElement = dataFiltered?.[0];

    const sorted = customSort(
      dataFiltered,
      filterArrow.name! as keyof typeof firstElement,
      filterArrow.direction! as 'asc' | 'desc'
    );

    return sorted;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, page, perPage, search, filterArrow]);

  const handleCloseDeleteDialog = () => {
    setIdSelected(null);
  };

  const handleEditJob = (id: number) => {
    dispatch(setStudentJobIdSelected(id));
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <SearchPaginatedTable
          data={
            datafetch
              ?.map((item, idx) => ({
                id: (page - 1) * perPage + idx + 1,
                ...item,
              }))
              ?.slice((page - 1) * perPage, page * perPage)
              ?.filter(
                (item) =>
                  item.organization?.toLowerCase().includes(search.toLowerCase()) ||
                  item.position?.toLowerCase().includes(search.toLowerCase())
              ) || []
          }
          headers={{
            id: 'N°',
            organization: 'Organización',
            position: 'Cargo',
            start_date: 'Fecha de inicio',
            end_date: 'Fecha de fin',
            options: 'Opciones',
          }}
          listFilterArrows={{
            organization: 'organization',
            position: 'position',
            start_date: 'start_date',
            end_date: 'end_date',
            options: 'options',
          }}
          filterArrow={filterArrow}
          setFilterArrow={setFilterArrow}
          page={page}
          perPage={perPage}
          search={search}
          setPage={setPage}
          setPerPage={setPerPage}
          setSearch={setSearch}
          error={undefined}
          isLoading={false}
          isFetching={false}
          keyExtractor={(item) => item.id.toString()}
          searchPlacehoder="Buscar"
          total={data?.person_job?.length || 0}
          customDataCellsProperties={{
            id: { align: 'center' },
            organization: { align: 'center' },
            position: { align: 'center' },
            start_date: { align: 'center' },
            end_date: { align: 'center' },
            options: { align: 'center' },
          }}
          customRenderers={{
            options: (item) => (
              <>
                <EditButton handleEdit={() => handleEditJob(item.options.id!)} />
                <DeleteButton handleDelete={() => setIdSelected(item.options.id)} />
              </>
            ),
          }}
        />
      </Grid>
      {idSelected && (
        <DeleteJobDialog
          idSelected={idSelected}
          studentId={studentId!}
          handleClose={handleCloseDeleteDialog}
        />
      )}
    </Grid>
  );
};

export default JobsTable;
