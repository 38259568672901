import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import Stack from '@material-ui/core/Stack';
import useTheme from '@material-ui/core/styles/useTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { useTranslation } from 'react-i18next';

import PerfectScrollbar from 'react-perfect-scrollbar';
import { renderSkeletonTable } from '../../../shared/helpers/render';
import { useAppSelector } from '../../../../hooks/useRedux';
import { Button } from '@material-ui/core';
import { useGetPaidCertificatesQuery } from '../../certificates/slices/certificateApiSlice';
import { PaidCertificate } from '../../certificates/interfaces/paidCertificates.interface';
import DownloadPaidCertificate from '../../certificates/components/DownloadPaidCertificate';
import useFormatDate from '../../../shared/hooks/useFormatDate';

// style constant
const useStyles = makeStyles((theme) => ({
  scrollRoot: {
    '& >div.ps__rail-y': {
      '& :hover': {
        backgroundColor: 'black',
      },
    },

    '& >div.ps__rail-y > div.ps__thumb-y': {
      backgroundColor: theme.palette.primary[200],
      '& :hover': {
        backgroundColor: 'black',
      },
    },
    // '& .scrollbar-container .ps__rail-y:hover > .ps__thumb-y, .scrollbar-container .ps__rail-y:focus > .ps__thumb-y, .scrollbar-container .ps__rail-y.ps--clicking .ps__thumb-y':
    //   { backgroundColor: 'red' },
  },
}));

// const DUMMY_DATA = [
//   {
//     id: 1,
//     quota: 'Tercera matrícula',
//     deadline: 'vencío 30 de marzo',
//     amount: '1300.00',
//     detail1: 'Detalle 1',
//     detail2: 'Detalle 2',
//   },
// ];

// const generateDummyData = () => {
//   const data: typeof DUMMY_DATA = [];

//   for (let index = 0; index < 10; index++) {
//     data.push({
//       id: index,
//       quota: 'Tercera matrícula',
//       deadline: `Materia ${index}`,
//       amount: '1300.00',
//       detail1: 'Detalle 1',
//       detail2: 'Detalle 2',
//     });
//   }

//   return data;
// };

interface IItemList {
  certificate: PaidCertificate;
}

const ItemList = ({ certificate }: IItemList) => {
  const theme = useTheme();

  const { formatDate } = useFormatDate();

  return (
    <Grid
      item
      key={certificate.id.toString()}
      borderBottom={`1px solid ${theme.palette.primary[200]}`}
      py={1}
    >
      <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="flex-start">
        <Box
        //   sx={{
        //     border: `1px solid ${theme.palette.primary[800]}`,
        //     backgroundColor: `${theme.palette.primary[800]}`,
        //     padding: 1,
        //     borderRadius: theme.shape.borderRadius,
        //   }}
        >
          <Typography>{certificate.type_application?.typ_app_description}</Typography>
          <FormHelperText>{formatDate(certificate.app_register_date)}</FormHelperText>
        </Box>

        <Box>
          <Stack spacing={1} direction="row">
            <DownloadPaidCertificate certificateId={certificate.id} />
          </Stack>
        </Box>
      </Stack>
    </Grid>
  );
};

const RecentCertificates = () => {
  const theme = useTheme();
  const classes = useStyles();

  const history = useHistory();

  const { userInfo } = useAppSelector((state) => state.auth);

  const { t } = useTranslation();

  const { data, isLoading } = useGetPaidCertificatesQuery({
    userId: +userInfo.id,
    page: 1,
    perPage: 3,
    search: '',
  });

  const datafetch: PaidCertificate[] | undefined = useMemo(() => {
    return data?.data || undefined;
  }, [data]);

  const itemList = (certificate: PaidCertificate) => {
    return <ItemList certificate={certificate} key={certificate!.id.toString()} />;
  };

  const rendercertificateList = () => {
    return <>{datafetch?.map(itemList)}</>;
  };

  const handleGoToCertificatesHistory = () => history.push('/certificados/certificados-pagados');

  return (
    <Box
      sx={{
        border: `1px solid ${theme.palette.primary[200]}`,
        padding: 2,
        height: '100%',
        borderRadius: '12px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography component="h3" variant="h3">
          {t('students.dashboard.recent_certificates.title')}
        </Typography>
        <Button onClick={handleGoToCertificatesHistory}>
          {t('students.dashboard.recent_certificates.title.button')}
        </Button>
      </Box>

      <>
        {isLoading ? (
          renderSkeletonTable(1, 2)
        ) : (
          <Grid container pt={2} pb={1}>
            <Grid
              item
              xs={12}
              sx={{
                maxHeight: 140,
                overflow: 'hidden',
                [theme.breakpoints.down('md')]: {
                  maxHeight: 280,
                },
              }}
            >
              <PerfectScrollbar component={'div'} className={classes.scrollRoot}>
                <Stack spacing={2}>
                  {(datafetch?.length || 0) > 0 ? (
                    rendercertificateList()
                  ) : (
                    <Typography component={'p'} align="center">
                      {t('students.dashboard.recent_certificates.no_data')}
                    </Typography>
                  )}
                </Stack>
              </PerfectScrollbar>
            </Grid>
          </Grid>
        )}
      </>
    </Box>
  );
};

export default RecentCertificates;
