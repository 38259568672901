import { Autocomplete, FormHelperText, Grid, InputLabel, TextField } from '@material-ui/core';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../../../../hooks/useRedux';
import ErrorAlert from '../../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../../shared/components/alerts/SuccessAlert';
import LoadingButton from '../../../../shared/components/buttons/LoadingButton';
import TextFieldFetching from '../../../../shared/components/TextFieldFetching';
import { ModulePermissionSchema } from '../../constants/validationSchemas';
import {
  useAddPermissionAdditionalModuleMutation,
  useGetModulesQuery,
} from '../../slices/permissionsApiSlice';

const ModuleForm = () => {
  const { currentProfile } = useAppSelector((state) => state.access);
  const { permissionIdSelected } = useAppSelector((state) => state.permissions);

  const [showAlert, setShowAlert] = useState(false);

  const [addModule, { isLoading: isAddingModule, isSuccess, isError, error }] =
    useAddPermissionAdditionalModuleMutation();

  const { handleSubmit, setFieldValue, errors, touched, values } = useFormik({
    initialValues: {
      moduleId: null as number | null,
    },
    validationSchema: ModulePermissionSchema,
    onSubmit: async (values) => {
      await addModule({
        profileId: currentProfile?.profileId!,
        permissionId: permissionIdSelected!,
        moduleId: values.moduleId!,
      });
    },
  });

  const { data: modules, isLoading: isLoadingModules } = useGetModulesQuery({
    profileId: currentProfile?.profileId!,
  });

  useEffect(() => {
    setShowAlert(true);
  }, [isSuccess, isError]);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2} mt={2}>
        {isSuccess && showAlert && (
          <SuccessAlert
            message="Módulo agregado correctamente"
            handleDismiss={() => setShowAlert(false)}
          />
        )}
        {isError && showAlert && (
          <ErrorAlert message={error as string} handleDismiss={() => setShowAlert(false)} />
        )}
        <Grid item xs={12}>
          <InputLabel>Módulo</InputLabel>
          {isLoadingModules && <TextFieldFetching />}
          {!isLoadingModules && (
            <Autocomplete
              options={modules || []}
              getOptionLabel={(option) => option.mod_name_translated}
              onChange={(_, value) => {
                setFieldValue('moduleId', value?.id);
              }}
              value={modules?.find((module) => module.mod_id === values.moduleId) || null}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Seleccione un módulo"
                  error={Boolean(touched.moduleId && errors.moduleId)}
                />
              )}
            />
          )}
          {errors.moduleId && touched.moduleId && (
            <FormHelperText error>{errors.moduleId}</FormHelperText>
          )}
        </Grid>

        <Grid item xs={12}>
          <LoadingButton
            type="submit"
            variant="contained"
            color="primary"
            isLoading={isAddingModule}
          >
            Guardar
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default ModuleForm;
