import { useMemo, useState } from 'react';

import { useFormik } from 'formik';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';
import Autocomplete from '@material-ui/core/Autocomplete';
import Skeleton from '@material-ui/core/Skeleton';

import { useTranslation } from 'react-i18next';

import InputLabel from '../../../../shared/components/forms/InputLabel';
import TextFieldFetching from '../../../../shared/components/TextFieldFetching';
// import { updateItemFromIndexArr } from '../../../../shared/helpers';
import { useGetAllCategoriesQuery } from '../../../maintenance-category/slices/categoryApiSlice';
import { FormProduct } from '../../interfaces/payload.interface';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import { useGetAllProductsQuery } from '../../../maintenance-product/slices/productApiSlice';
import { ProductSchema } from '../../constants/validationForms-schemas';
import { showWithDecimals } from '../../../../shared/helpers/index';
import { setProductsCollection } from '../../slices/generateDebtsSlice';
import { useLazyGetProductIdByStudentRecordIdQuery } from '../../slices/generateDebtsApiSlice';

interface Props {
  handleCloseDialog: () => void;
}
const AddProductForm = ({ handleCloseDialog }: Props) => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const defaultConfiguration = useAppSelector((state) => state.access.defaultConfiguration);

  const [productKey, setProductKey] = useState(Math.random());

  const { productsCollection, currentStudentRecord } = useAppSelector(
    (state) => state.generateDebts
  );

  const { data: productCategories, isFetching: isFetchingPRC } = useGetAllCategoriesQuery({});

  const [getProduct, { isFetching: isFetchingPRD }] = useLazyGetProductIdByStudentRecordIdQuery();

  const handleSearchProduct = async (productId: number) => {
    try {
      const triggerData = await getProduct(
        {
          productId,
          studentRecordId: Number(currentStudentRecord?.id),
        },
        true
      ).unwrap();
      setFieldValue('productDetails', triggerData);
    } catch (error) {
      console.log(error);
    }
  };

  const initialStates: FormProduct = useMemo(() => {
    return {
      category: null,
      product: null,
      productDetails: null,
      quantity: 1,
    };
  }, []);

  const { getFieldProps, errors, touched, handleSubmit, setFieldValue, values } = useFormik({
    initialValues: initialStates,
    validationSchema: ProductSchema,
    onSubmit: async (values) => {
      try {
        if (true) {
          const newProductsCollection = [values, ...productsCollection];

          dispatch(setProductsCollection(newProductsCollection));

          handleCloseDialog();
        } else {
          //   const updatedItem = {
          //     payment_amount: values.payAmount!,
          //     observation: values.observation,
          //     payment_way_id: paymethod.id,
          //     payment_way: paymethod.id,
          //     payment_way_name: paymethod.name,
          //     payment_way_keyword: paymethod.keyword,
          //     issues_invoice: 1,
          //   };
          //   const newDebtCollection = updateItemFromIndexArr(
          //     productsCollection,
          //     indexPayToEdit,
          //     updatedItem
          //   );
          //   dispatch(setproductsCollection(newproductsCollection));
        }

        // setIsPay();
      } catch (error) {
        console.log(error);
      }

      //   setShowAlerts(true);
      //   window.scrollTo(0, 0);
    },
  });

  const { data: products, isFetching: isFetchingPR } = useGetAllProductsQuery({
    ...(values.category && { category_id: values.category.id }),
    isDebts: true,
  });

  const onChangeCategoryHandler = () => {
    setFieldValue('product', null);
    setFieldValue('productDetails', null);
    setProductKey(Math.random());
  };

  return (
    <form onSubmit={handleSubmit}>
      {/* <Button onClick={() => setValues({ ...initialValues });}>asd</Button> */}
      <Grid container spacing={1} alignItems="flex-start">
        {/* <Grid item>
        {isError && showAlerts && (
          <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
        )}

        {isSuccess && showAlerts && (
          <SuccessAlert
            message="El periodo ha sido creado correctamente."
            handleDismiss={() => setShowAlerts(false)}
          />
        )}
      </Grid> */}

        <Grid item xs={12}>
          <InputLabel>
            {t('financial.generate_debts.product_dialog_form.category_label')}
          </InputLabel>
          {isFetchingPRC ? (
            <TextFieldFetching />
          ) : (
            <Autocomplete
              //   disabled={Boolean(indexPayToEdit?.toString()) && isEdit}
              options={productCategories || []}
              loading={isFetchingPRC}
              loadingText={t('financial.generate_debts.product_dialog_form.category_loading')}
              getOptionLabel={(option) => option.cat_name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  // error={Boolean(errors.campusIds && touched.campusIds)}
                  placeholder={t(
                    'financial.generate_debts.product_dialog_form.category_placeholder'
                  )}
                />
              )}
              value={productCategories?.find((pr) => pr.id === values.category?.id) || null}
              //   inputValue={inputValue}
              onChange={(_, values) => {
                setFieldValue('category', values || null);
                onChangeCategoryHandler();
                // setIsAddPay(false);
              }}
              onInputChange={(event, newInputValue) => {
                // setInputValue(newInputValue);
              }}
              fullWidth
            />
          )}
        </Grid>

        <Grid item xs={12}>
          <InputLabel>{t('financial.generate_debts.product_dialog_form.item_label')}</InputLabel>
          {isFetchingPR ? (
            <TextFieldFetching />
          ) : (
            <Autocomplete
              //   disabled={Boolean(indexPayToEdit?.toString()) && isEdit}
              key={productKey}
              options={products || []}
              loading={isFetchingPR}
              loadingText={t('financial.generate_debts.product_dialog_form.item_loading')}
              getOptionLabel={(option) => option.pr_description}
              renderInput={(params) => (
                <TextField
                  {...params}
                  // error={Boolean(errors.campusIds && touched.campusIds)}
                  placeholder={t('financial.generate_debts.product_dialog_form.item_placeholder')}
                />
              )}
              value={products?.find((pr) => pr.id === values.product?.id) || null}
              //   inputValue={inputValue}
              onChange={(_, values) => {
                setFieldValue('product', values || null);
                // ? meh
                setFieldValue(
                  'category',
                  productCategories?.find((pr) => pr.id === Number(values?.category_id)) || null
                );
                if (values) handleSearchProduct(values?.id);
                // setIsAddPay(false);
              }}
              onInputChange={(event, newInputValue) => {
                // setInputValue(newInputValue);
              }}
              fullWidth
              //   error={Boolean(errors.product && touched.product)}
            />
          )}
          {errors.product && touched.product && (
            <FormHelperText error>{t(errors.product)}</FormHelperText>
          )}
        </Grid>

        <Grid item xs={12}>
          <InputLabel>{t('financial.generate_debts.product_dialog_form.price_label')}</InputLabel>
          {isFetchingPRD ? (
            <Skeleton variant="rectangular" width={'100%'}>
              <TextField
                disabled
                fullWidth
                value={`${showWithDecimals(Number(values.product?.amount || 0))}`}
                InputProps={{
                  startAdornment: `${defaultConfiguration?.currency_symbol}`,
                }}
                placeholder="0.00"
              />
            </Skeleton>
          ) : (
            <TextField
              disabled
              fullWidth
              value={`${showWithDecimals(Number(values.productDetails?.precio || 0))}`}
              InputProps={{
                startAdornment: `${defaultConfiguration?.currency_symbol}`,
              }}
              placeholder="0.00"
            />
          )}
        </Grid>

        <Grid item xs={12}>
          <InputLabel>
            {t('financial.generate_debts.product_dialog_form.quantity_label')}
          </InputLabel>
          <TextField
            type="number"
            fullWidth
            InputProps={{
              defaultValue: 1,
              inputProps: { min: 1 },
              //   inputComponent: CurrencyInputMasked as any,
            }}
            placeholder="0"
            {...getFieldProps('quantity')}
            error={Boolean(errors.quantity && touched.quantity)}
          />
          {errors.quantity && touched.quantity && (
            <FormHelperText error>{t(errors.quantity)}</FormHelperText>
          )}
        </Grid>

        {/* <Grid item xs={12}>
          <InputLabel>Total Descuento</InputLabel>
          {isFetchingPRD ? (
            <Skeleton variant="rectangular" width={'100%'}>
              <TextField
                disabled
                fullWidth
                InputProps={{
                  startAdornment: '$',
                }}
                value={`${showWithDecimals(
                  Number(values.product?.iva)
                    ? (Number(values.product?.amount || 0) * Number(values.quantity || 0) * 100) /
                        Number(values.product?.iva)
                    : 0
                )}`}
              />
            </Skeleton>
          ) : (
            <TextField
              disabled
              fullWidth
              InputProps={{
                startAdornment: '$',
              }}
              value={`${showWithDecimals(
                Number(values.product?.iva)
                  ? (Number(values.product?.amount || 0) * Number(values.quantity || 0) * 100) /
                      Number(values.product?.iva)
                  : 0
              )}`}
            />
          )}
        </Grid>

        <Grid item xs={12}>
          <InputLabel>IVA</InputLabel>
          {isFetchingPRD ? (
            <Skeleton variant="rectangular" width={'100%'}>
              <TextField
                disabled
                fullWidth
                InputProps={{
                  startAdornment: '$',
                }}
                value={`${showWithDecimals(
                  Number(values.product?.iva)
                    ? (Number(values.product?.amount || 0) * Number(values.quantity || 0) * 100) /
                        Number(values.product?.iva)
                    : 0
                )}`}
              />
            </Skeleton>
          ) : (
            <TextField
              disabled
              fullWidth
              InputProps={{
                startAdornment: '$',
              }}
              value={`${showWithDecimals(
                Number(values.product?.iva)
                  ? (Number(values.product?.amount || 0) * Number(values.quantity || 0) * 100) /
                      Number(values.product?.iva)
                  : 0
              )}`}
            />
          )}
        </Grid> */}

        <Grid item xs={12}>
          <InputLabel>{t('financial.generate_debts.product_dialog_form.total_label')}</InputLabel>
          {isFetchingPRD ? (
            <Skeleton variant="rectangular" width={'100%'}>
              <TextField
                disabled
                fullWidth
                InputProps={{
                  startAdornment: `${defaultConfiguration?.currency_symbol}`,
                }}
                value={`${showWithDecimals(
                  Number(values.product?.amount || 0) * Number(values.quantity || 0)
                )}`}
              />
            </Skeleton>
          ) : (
            <TextField
              disabled
              fullWidth
              InputProps={{
                startAdornment: `${defaultConfiguration?.currency_symbol}`,
              }}
              value={`${showWithDecimals(
                Number(values.productDetails?.total || 0) * Number(values.quantity || 0)
              )}`}
            />
          )}
        </Grid>

        <Grid item xs={12}>
          <Grid container justifyContent={'flex-end'} spacing={1}>
            <Grid item>
              <Button variant="outlined" onClick={handleCloseDialog}>
                {t('financial.generate_debts.product_dialog_form.cancel_btn')}
              </Button>
            </Grid>
            <Grid item>
              <Button type="submit" variant="contained" disabled={isFetchingPRD}>
                {t('financial.generate_debts.product_dialog_form.save_btn')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default AddProductForm;
