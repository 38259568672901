import { ChangeEvent, useState } from 'react';
import {
    Button,
    CardActions,
    CircularProgress,
    Dialog,
    DialogProps,
    Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/useRedux';
import MainCard from '../../../../../shared/components/cards/MainCard';
import { useSendFileMutation } from '../../../../../shared/slices/catalogApi';
import ErrorAlert from '../../../../../shared/components/alerts/ErrorAlert';
import { setTaskChooseFiles } from '../../../slices/virtualClassroomTeacherSlice';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { SerializedError } from '@reduxjs/toolkit';
import { IconFile, IconUpload } from '@tabler/icons';
import { setTaskFile } from '../../../slices/virtualClassroomTeacherSlice';

const useStyles = makeStyles((theme) => ({
    paper: { maxWidth: '600px', width: '90%' },
    uploadFile: {
        borderColor: theme.palette.secondary.light,
        borderWidth: '2px',
        borderStyle: 'solid',
        borderRadius: 20,
        textAlign: 'center',
        width: '100%',
        height: '12vh',
        position: 'relative',
    },
}));

interface Props extends DialogProps {
}

const TaskChooseFilesModal = ({
    ...props
}: Props) => {

    const classes = useStyles();

    const dispatch = useAppDispatch();

    const { currentProfile } = useAppSelector((state) => state.access);

    const { taskToCreate } = useAppSelector((state) => state.virtualClassroomTeacher);

    const [showAlerts, setShowAlerts] = useState<boolean>(false);

    const [target, setTarget] = useState<File>(null!);

    const [sendFile, { isLoading, error: errorFetch, isError }] = useSendFileMutation();

    const onChangeFile = function (event: ChangeEvent<HTMLInputElement>) {
        if (event.target.files && event.target.files[0]) {
            setTarget(event.target.files[0]);
        }
    };

    const uploadFile = async () => {
        const resultSendFile: { data: any; } | { error: FetchBaseQueryError | SerializedError; } = await sendFile({
            profileId: currentProfile?.profileId!,
            file: target,
            typeDocument: 2
        });

        if ('error' in resultSendFile === false) {
            const documentURL = 'data' in resultSendFile ? resultSendFile.data : '';
            const data = {
                name: target.name,
                name_url: documentURL
            }
            await dispatch(setTaskFile(data));
        }

        setShowAlerts(true);

    }


    const onClose = () => {
        dispatch(setTaskChooseFiles(false));
    }

    return (
        <Dialog {...props} classes={{ paper: classes.paper }} sx={{ p: 3 }} onClose={onClose}>
            <Grid container gap={4} sx={{ p: 2 }}>
                <Grid item sm={12} md={12}>
                    <MainCard title="Subir material">
                        {isError && showAlerts && (
                            <Grid container spacing={2} sx={{ mt: 2, mb: 2 }}>
                                <Grid item md={12} xs={12}>
                                    <ErrorAlert message={errorFetch as string} handleDismiss={() => setShowAlerts(false)} />
                                </Grid>
                            </Grid>
                        )}

                        <input type="file" hidden accept="application/pdf" onChange={onChangeFile} />

                        <Grid container item justifyContent="center">
                            <Grid container item xs={10} md={10}>
                                <label className={classes.uploadFile} style={{ cursor: 'pointer' }}>
                                    <input type="file" hidden accept="application/pdf" onChange={onChangeFile} />

                                    <IconFile size={32} />
                                    {<p>{!target ? 'Seleccionar archivo' : target.name}</p>}
                                    {<p>{target && 'Seleccionar otro archivo'}</p>}
                                </label>
                            </Grid>
                        </Grid>

                    </MainCard>
                    <CardActions>
                        <Grid container item sx={{ justifyContent: 'space-between', mt: 5 }}>
                            <Grid item >
                                <Button
                                    variant="text" color="primary">
                                    Archivos subidos : {taskToCreate?.files ? taskToCreate?.files?.length! : 0}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    onClick={uploadFile}
                                    disabled={!target || isLoading}
                                    startIcon={<IconUpload />}
                                    endIcon={isLoading && <CircularProgress size={20} />}
                                    variant="outlined" color="primary">
                                    Subir archivo
                                </Button>
                            </Grid>
                            <Grid item >
                                <Button
                                    onClick={onClose}
                                    disabled={isLoading}
                                    variant="contained" color="primary">
                                    Continuar
                                </Button>
                            </Grid>
                        </Grid>
                    </CardActions>
                </Grid>
            </Grid >
        </Dialog >
    );
};

export default TaskChooseFilesModal;
