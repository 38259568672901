import { Switch } from 'react-router-dom';

// import { useAppSelector } from '../../../../hooks/useRedux';

import GuardRoute from '../../../../router/guards/GuardRoute';
import NotificationsScreen from '../screens/NotificationsScreen';

const NotificationRouter = () => {
  // const { currentModule } = useAppSelector((state) => state.access);

  // const hasPermission = 'periods' in currentModule?.permissions!;

  // if (!hasPermission) return <Redirect to="/dashboard" />;

  return (
    <Switch>
      <GuardRoute
        exact
        path="/notificaciones"
        component={NotificationsScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.read}
      />
    </Switch>
  );
};

export default NotificationRouter;
