import { useEffect, useMemo } from 'react';

import { useHistory, useParams } from 'react-router-dom';
import { Grid, Typography, IconButton, useTheme, Skeleton } from '@material-ui/core';
import { IconArrowNarrowLeft } from '@tabler/icons';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import MainCard from '../../../shared/components/cards/MainCard';

// import { useAppDispatch } from '../../../../hooks/useRedux';
import SimpleFormOneColumn from '../../../shared/components/forms/skeletons/SimpleFormOneColumn';
import BusToursList from '../components/BusToursList';
import { useLazyGetRouteQuery } from '../slices/busRouteApiSlice';
import { setBusRoutesList } from '../slices/busRouteSlice';
import LoadingChildren from '../../../shared/components/LoadingChildren';

const EditBusRoutesScreen = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const history = useHistory();
  // const location = useLocation();
  const { route } = useParams<{ route: string }>();

  const { busRoutesList } = useAppSelector((state) => state.busRoute);

  const [getRoute, { isFetching }] = useLazyGetRouteQuery();

  useEffect(() => {
    if (!busRoutesList.length && !isFetching) {
      getRoute({
        routeId: +route,
      })
        .unwrap()
        .then((response) => {
          if (response) dispatch(setBusRoutesList([response!]));
        })
        .catch((error) => console.log(error));
    }
  }, [busRoutesList.length, dispatch, getRoute, isFetching, route]);

  const currentRoute = useMemo(() => {
    return busRoutesList.find((dt) => dt.id === Number(route));
  }, [route, busRoutesList]);

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid item xs={12} sx={{ mb: 3 }}>
        <Grid item container alignItems="center" gap={1}>
          <IconButton onClick={() => history.goBack()}>
            <IconArrowNarrowLeft size={30} color={theme.palette.text.dark} />
          </IconButton>
          <Typography variant="h2">
            {!isFetching ? (
              `${t('academic.buses.tours.view.title')} - ${currentRoute?.bus_name}`
            ) : (
              <Skeleton variant="rectangular" width={300} height={30} sx={{ maxWidth: '50vw' }} />
            )}
          </Typography>
        </Grid>
      </Grid>

      <Grid item xs={12} sx={{ mt: 2 }}>
        <LoadingChildren
          isLoading={isFetching}
          isEmpty={!currentRoute && !isFetching}
          loadingSkeleton={<SimpleFormOneColumn />}
        >
          <BusToursList routeInfo={currentRoute!} />
        </LoadingChildren>
      </Grid>
    </Grid>
  );
};

export default EditBusRoutesScreen;
