import { Grid, Typography } from '@material-ui/core';
import MainCard from '../../../shared/components/cards/MainCard';
import ZoomTable from '../components/ZoomTable';

const AppZoomScreen = () => {
   return (
      <Grid component={MainCard} sx={{ padding: 2 }}>
         <Grid sx={{ mb: 3 }}>
            <Typography variant="h2">Aplicaciones</Typography>
            <Typography variant="h2" color="gray" fontWeight={400}>Módulo Zoom</Typography>
            <br />
            <ZoomTable />
         </Grid>
      </Grid>
   )
}

export default AppZoomScreen;