export const GENERACION_CUOTAS = 'RC';
export const INGRESO_DOCENTES_CURSO = 'D';
export const REGISTRO_MATERIAS_ADMIN = 'M';
export const REGISTRO_MATERIAS_PORTAL_ESTUDIANTIL = 'R';
export const INICIO_PROGRAMA_MAESTRIA = 'S';
export const SOLICITUD_APERTURA_MATERIAS_GRADO = 'Y';
export const INGRESO_CALIFICACIONES = 'B';
export const REGISTRO_ASIGNATURAS = 'E';
export const INICIO_CLASES = 'I';

export const STAGES = [
  {
    use_case: GENERACION_CUOTAS,
    field: 'generation_quote',
    description: 'Generación de cuotas',
    descriptionId: 'academic.periods_list.add.stage_3.tab_1',
  },
  {
    use_case: INGRESO_DOCENTES_CURSO,
    field: 'entry_teacher',
    description: 'Ingreso de Docentes al Curso',
    descriptionId: 'academic.periods_list.add.stage_3.tab_6',
  },
  {
    use_case: REGISTRO_MATERIAS_ADMIN,
    field: 'register_matter_admin',
    description: 'Registro de Materias desde Administración',
    descriptionId: 'academic.periods_list.add.stage_3.tab_2',
  },
  {
    use_case: REGISTRO_MATERIAS_PORTAL_ESTUDIANTIL,
    field: 'register_matter_student_portal',
    description: 'Registro de Materias desde Portal Estudiantil',
    descriptionId: 'academic.periods_list.add.stage_3.tab_3',
  },
  {
    use_case: INICIO_PROGRAMA_MAESTRIA,
    field: 'start_program_maestry',
    description: 'Inicio Programa Maestría',
    descriptionId: 'academic.periods_list.add.stage_3.tab_4',
  },
  {
    use_case: SOLICITUD_APERTURA_MATERIAS_GRADO,
    field: 'request_matter_grade',
    description: 'Solicitud de Apertura Materias Grado',
    descriptionId: 'academic.periods_list.add.stage_3.tab_5',
  },
  // {
  //   use_case: INGRESO_CALIFICACIONES,
  //   field: 'entry_',
  //   description: 'Ingreso de calificaciones',
  // },
  {
    use_case: REGISTRO_ASIGNATURAS,
    field: 'register_matter',
    description: 'Registro de Materias',
    descriptionId: 'academic.periods_list.add.stage_3.tab_7',
  },
  {
    use_case: INICIO_CLASES,
    field: 'start_class',
    description: 'Inicio Clases',
    descriptionId: 'academic.periods_list.add.stage_3.tab_8',
  },
];
