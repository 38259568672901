import { Button, Tooltip } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { useTableSearch } from '../../../../hooks/useTableSearch';
import DeleteButton from '../../../shared/components/tables/buttons/DeleteButton';
import EditButton from '../../../shared/components/tables/buttons/EditButton';
import SearchPaginatedTable, {
  FilterArrow,
} from '../../../shared/components/tables/SearchPaginatedTable';
import { useGetPosgraduatePlanningCohortsQuery } from '../slices/posgraduatePlanningCohortApiSlice';
import {
  setPosgraduatePlanningCohortDeleteDialogOpen,
  setPosgraduatePlanningCohortFormDialogOpen,
  setPosgraduatePlanningCohortIdSelected,
  setPosgraduatePlanningCohortsList,
  setPosgraduatePlanningCohortsTable,
} from '../slices/posgraduatePlanningCohortSlice';

const PosgraduatePlanningCohortsTable = () => {
  const dispatch = useAppDispatch();

  const {
    posgraduatePlanningCohortsTable: { page, perPage, search },
  } = useAppSelector((state) => state.posgraduatePlanningCohorts);

  const [filterArrow, setFilterArrow] = useState<FilterArrow>(null!);

  const { setPage, setPerPage, setSearch } = useTableSearch({
    page,
    perPage,
    search,
    updateFunction: setPosgraduatePlanningCohortsTable,
  });

  const { data, isLoading, error, isFetching } = useGetPosgraduatePlanningCohortsQuery({
    page,
    perPage,
    search,
    ...(filterArrow && {
      sort: filterArrow.name,
      type_sort: filterArrow.direction,
    }),
  });

  const datafetch =
    data?.data?.map((item, index) => ({
      id: index + ((page - 1) * perPage + 1),
      description: `${item.year} Cohorte ${item.cohort_number}`,
      year: item.year,
      cohort_number: item.cohort_number,
      cant_parallel_cohort: item.cant_parallel_cohort || 'Sin asignar',
      options: { id: item.id },
    })) || [];

  useEffect(() => {
    if (data?.data) {
      dispatch(setPosgraduatePlanningCohortsList(data.data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleAdd = () => {
    dispatch(setPosgraduatePlanningCohortFormDialogOpen(true));
  };

  const handleEdit = (id: string) => {
    dispatch(setPosgraduatePlanningCohortIdSelected(id));
    dispatch(setPosgraduatePlanningCohortFormDialogOpen(true));
  };

  const handleDelete = (id: string) => {
    dispatch(setPosgraduatePlanningCohortIdSelected(id));
    dispatch(setPosgraduatePlanningCohortDeleteDialogOpen(true));
  };

  return (
    <>
      <SearchPaginatedTable
        data={datafetch}
        error={error}
        isLoading={isLoading}
        isFetching={isFetching}
        headers={{
          id: 'N°',
          description: 'Descripción',
          year: 'Año',
          cohort_number: 'Cohorte',
          cant_parallel_cohort: 'Cant. Paralelos',
          options: 'Opciones',
        }}
        listFilterArrows={{
          description: 'year',
          year: 'year',
          cohort_number: 'cohort_number',
          cant_parallel_cohort: 'cant_parallel_cohort',
        }}
        filterArrow={filterArrow}
        setFilterArrow={setFilterArrow}
        keyExtractor={(item) => item.id.toString()}
        numHeader={5}
        setPage={setPage}
        setPerPage={setPerPage}
        setSearch={setSearch}
        total={data?.total || 0}
        page={page}
        perPage={perPage}
        search={search}
        searchPlacehoder="Buscar..."
        ActionButtons={
          <>
            <Tooltip title="Agregar">
              <Button onClick={handleAdd} variant="contained" color="primary">
                Agregar
              </Button>
            </Tooltip>
          </>
        }
        customHeadersCellsProperties={{
          id: {
            align: 'center',
          },
          description: {
            align: 'center',
          },
          year: {
            align: 'center',
          },
          cohort_number: {
            align: 'center',
          },
          cant_parallel_cohort: {
            align: 'center',
          },

          options: {
            align: 'center',
          },
        }}
        customDataCellsProperties={{
          id: {
            align: 'center',
          },
          description: {
            align: 'center',
          },
          year: {
            align: 'center',
          },
          cohort_number: {
            align: 'center',
          },
          cant_parallel_cohort: {
            align: 'center',
          },

          options: {
            align: 'center',
          },
        }}
        customRenderers={{
          options: (row) => (
            <>
              <EditButton handleEdit={() => handleEdit(row.options.id)} />
              <DeleteButton handleDelete={() => handleDelete(row.options.id)} />
            </>
          ),
        }}
      />
    </>
  );
};

export default PosgraduatePlanningCohortsTable;
