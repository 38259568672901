export const SUBJECTWITHDRAWAL_TYPES = [
  {
    id: 'Voluntario',
    label: 'students.applications.subject_retire.retirement_type.volunteer',
  },
  {
    id: 'Fuerza Mayor',
    label: 'students.applications.subject_retire.reason_placeholder.force_majeure',
  },
];
