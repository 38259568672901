import { Grid, IconButton, Typography, useTheme } from '@material-ui/core';
import { IconArrowNarrowLeft } from '@tabler/icons';
import { useHistory } from 'react-router-dom';
//import { useAppSelector } from '../../../../hooks/useRedux';
import MainCard from '../../../shared/components/cards/MainCard';
import LendingsDetails from '../components/LendingsDetails';

const LendingsDetailsScreen = () => {
  const theme = useTheme();
  const history = useHistory();
  //const { authorToDelete } = useAppSelector((state) => state.authors);
  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid item container direction="row" alignItems="center" sx={{ mb: 3 }}>
        <IconButton onClick={() => history.goBack()}>
          <IconArrowNarrowLeft size={30} color={theme.palette.text.dark} />
        </IconButton>
        <Typography variant="h2">Detalle de Prestamo</Typography>
      </Grid>

      <LendingsDetails />

      {/* {authorToDelete && <DeleteAuthorDialog />} */}
    </Grid>
  );
};

export default LendingsDetailsScreen;
