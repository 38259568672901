import DeleteAlert from '../../../shared/components/dialogs/DeleteAlert';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { resetProductId, setDeleteModalOpen } from '../slices/productSlice';
import { useDeleteProductMutation } from '../slices/productApiSlice';

const DeleteModal = () => {

    const dispatch = useAppDispatch();

    const { deleteModalOpen , productIdToDelete } = useAppSelector(
        (state) => state.products
    )

    const { currentProfile } = useAppSelector((state) => state.access);

    const [deleteProduct , { isLoading , isSuccess, isError , error }] = useDeleteProductMutation();

    const handleClose = () => {
        dispatch(setDeleteModalOpen(false));
        dispatch(resetProductId());
    }

    const handleDelete = () => {
        deleteProduct({
            profileId: currentProfile?.profileId!,
            productId: productIdToDelete!,
        });
    }
    
    console.log(productIdToDelete)

    return (
        <DeleteAlert
            open={deleteModalOpen}
            onClose={handleClose}
            isLoading={isLoading}
            isSuccess={isSuccess}
            isError={isError}
            handleDelete={handleDelete}
            error={error as string}
        />
    )
}

export default DeleteModal;