import { createSlice } from '@reduxjs/toolkit';
import { Schedule } from '../interfaces/academic-hourhand-interfaces';

interface AcademicHourhandState {
  academicHourhandData: [];
  courses: Schedule[];
}

const initialState: AcademicHourhandState = {
  academicHourhandData: [],
  courses: [],
};

export const academicHourhandSlice = createSlice({
  name: 'hourhands',
  initialState,
  reducers: {
    setAcademicHourhand: (state, action) => {
      state.academicHourhandData = action.payload;
    },
    setCourses: (state, action) => {
      state.courses = action.payload;
    },
  },
});

export const { setAcademicHourhand, setCourses } = academicHourhandSlice.actions;

export default academicHourhandSlice.reducer;
