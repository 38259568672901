import { useEffect, useState } from 'react';
import { useHistory, Link, useRouteMatch } from 'react-router-dom';

import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { useGetMattersTypesQuery } from '../slices/mattersTypesApiSlice';

import StatusChip from '../../../shared/components/badges/StatusChip';
import EditButton from '../../../shared/components/tables/buttons/EditButton';
import DeleteButton from '../../../shared/components/tables/buttons/DeleteButton';
import AddButton from '../../../shared/components/tables/buttons/AddButton';

import {
  openDeleteModalWith,
  setMattersTypesList,
  updateMatterTypeTable,
} from '../slices/mattersTypesSlice';
import useSharedGuard from '../../../../hooks/useSharedGuard';
import { subPermissionKeys } from '../constants/permissions';

import SearchPaginatedTable, {
  FilterArrow,
} from '../../../shared/components/tables/SearchPaginatedTable';
import { useTableSearch } from '../../../../hooks/useTableSearch';

// style constant
const useStyles = makeStyles((theme) => ({
  active: {
    background:
      theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.success.light + 60,
    color: theme.palette.success.dark,
  },
  inactive: {
    background:
      theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.orange.light + 80,
    color: theme.palette.orange.dark,
  },
  warning: {
    background:
      theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.warning.light,
    color: theme.palette.warning.dark,
  },
}));

const MattersTypesTable = () => {
  const classes = useStyles();

  const { currentProfile } = useAppSelector((state) => state.access);

  const dispatch = useAppDispatch();

  const { hasAccess } = useSharedGuard(subPermissionKeys);

  const { path } = useRouteMatch();
  const history = useHistory();

  const { page, perPage, search } = useAppSelector((state) => state.mattersTypes.matterTypeTable);

  const { setPage, setPerPage, setSearch } = useTableSearch({
    page,
    perPage,
    search,
    updateFunction: updateMatterTypeTable,
  });

  const [filterArrow, setFilterArrow] = useState<FilterArrow>({
    name: 'tm_name',
    direction: 'asc',
  });

  const { isLoading, error, data, isFetching } = useGetMattersTypesQuery(
    {
      profileId: currentProfile?.profileId!,
      page,
      perPage,
      search,
      ...(filterArrow && { sort: filterArrow.name, type_sort: filterArrow.direction }),
    },
    { refetchOnMountOrArgChange: 60 }
  );

  useEffect(() => {
    if (data) {
      dispatch(setMattersTypesList(data.data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleDelete = async (matterTypeId: number) => {
    dispatch(openDeleteModalWith(matterTypeId));
  };

  const goToAdd = () => {
    history.push(`${path}/agregar`);
  };

  const datafetch = data?.data!.map((d, index) => ({
    id: index + ((page - 1) * perPage + 1),
    name: d.tm_name,
    description: d.tm_description,
    offer: d.offer?.off_name,
    order: d.tm_order,
    cobro: d.tm_cobro,
    matterCount: d.tm_matter_count,
    statusId: d.status_id,
    options: d,
  }));

  return (
    <SearchPaginatedTable
      data={datafetch!}
      headers={{
        id: 'N°',
        name: 'Nombre',
        description: 'Descripción',
        offer: 'Oferta',
        order: 'Orden',
        cobro: 'Cobro',
        matterCount: 'Cuenta',
        statusId: 'Estado',
        options: 'Opciones',
      }}
      keyExtractor={(item) => item.options.id.toString()}
      listFilterArrows={{
        name: 'tm_name',
        description: 'tm_description',
        // offer: 'offer.off_name',
        order: 'tm_order',
        cobro: 'tm_cobro',
        matterCount: 'tm_matter_count',
        statusId: 'status_id',
      }}
      filterArrow={filterArrow}
      setFilterArrow={setFilterArrow}
      searchPlacehoder="Buscar tipo de materia"
      isLoading={isLoading}
      isFetching={isFetching}
      error={error}
      perPage={perPage}
      setPerPage={setPerPage}
      total={data?.total || 0}
      page={page}
      setPage={setPage}
      numHeader={9}
      search={search}
      setSearch={setSearch}
      customRenderers={{
        cobro: (row) => (
          <>
            {Number(row.cobro) === 1 && (
              <Chip size="small" label="Cobra" color="primary" className={classes.active} />
            )}
            {Number(row.cobro) === 0 && (
              <Chip size="small" label="No Cobra" color={'secondary'} className={classes.warning} />
            )}
          </>
        ),
        matterCount: (row) => (
          <>
            {Number(row.matterCount) === 1 && (
              <Chip size="small" label="Cuenta" color="primary" className={classes.active} />
            )}
            {Number(row.matterCount) === 0 && (
              <Chip
                size="small"
                label="No Cuenta"
                color={'secondary'}
                className={classes.warning}
              />
            )}
          </>
        ),
        statusId: (row) => <StatusChip statusId={Number(row.statusId)} />,
        options: (row) => (
          <>
            {hasAccess('edit') && (
              <Link to={`${path}/${row.options.id}/editar`}>
                <EditButton />
              </Link>
            )}
            {hasAccess('delete') && (
              <DeleteButton handleDelete={() => handleDelete(row.options.id)} />
            )}
            {!hasAccess('edit') && !hasAccess('delete') && 'Ninguna'}
          </>
        ),
      }}
      ActionButtons={<>{hasAccess('create') && <AddButton size="large" onClick={goToAdd} />}</>}
    />
  );
};

export default MattersTypesTable;
