import { Box, Typography, useTheme } from '@material-ui/core';
import { ExpandLessOutlined, ExpandMoreOutlined } from '@material-ui/icons';
import useExpandAccordion from '../../hooks/useExpandAccordion';
import { Book } from 'phosphor-react';
interface Props {
  children: JSX.Element | JSX.Element[] | undefined;
  title: string;
  folders: any[] | any;
  tools: any[];
}

const SubAccordion = ({ children, title, folders, tools }: Props) => {
  const { expanded, handleClickToogle } = useExpandAccordion();
  const theme = useTheme();
  return (
    <Box
      sx={{
        marginBottom: '1.5rem',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderRadius: '8px',
          cursor: 'pointer',
          paddingLeft: '0.5rem',
          marginBottom: '0.5rem',
        }}
        onClick={folders.length > 0 || tools.length > 0 ? handleClickToogle : undefined}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Book size={20} color={theme.palette.secondary.main} />
          <Typography
            color="primary"
            sx={{
              fontWeight: theme.typography.fontWeightBold,
              fontSize: '1rem',
              ml: 1,
            }}
          >
            {title}
          </Typography>
        </Box>
        {(folders.length > 0 || tools.length > 0) && (
          <Typography
            sx={{
              color: theme.palette.secondary[500],
              paddingX: '1rem',
            }}
          >
            {expanded ? <ExpandLessOutlined /> : <ExpandMoreOutlined />}
          </Typography>
        )}
      </Box>
      {expanded && (
        <Box
          sx={{
            paddingLeft: '1.5rem',
            marginTop: '1rem',
          }}
        >
          {children}
        </Box>
      )}
    </Box>
  );
};

export default SubAccordion;
