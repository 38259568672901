import { Tooltip } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    border: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '0.5rem',
    backgroundColor: '#F5F9FF',
    borderRadius: '5px',
    padding: '4px 8px',
  },
  label: {
    color: theme.palette.grey[600],
  },
  number: {
    color: theme.palette.grey[800],
    fontWeight: 600,
  },
}));

interface Props {
  label: string;
  number: number;
  tooltip?: string;
}

const PeriodBadge = ({ label, number, tooltip, ...props }: Props) => {
  const classes = useStyles();

  return (
    <Tooltip title={tooltip || label} placement="top">
      <Box className={classes.root}>
        <Typography className={classes.label}>{label}</Typography>
        <Typography className={classes.number}>{number}</Typography>
      </Box>
    </Tooltip>
  );
};

export default PeriodBadge;
