import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../../../hooks/useRedux';
import BackButton from '../../../../../shared/components/buttons/BackButton';
import MainCard from '../../../../../shared/components/cards/MainCard';
import IntegralEvaluationForm from '../components/IntegralEvaluationForm';
import {
  setEvaTypesAutocomplete,
  setIntegralEvaluationsFormStepActive,
} from '../slices/integralEvaluationSlice';
// import { useAppDispatch} from '../../../../../../../../hooks/useRedux';

const AddIntegralInformationScreen = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid container alignItems="center" sx={{ mb: 3 }}>
        <BackButton
          adicionalFunction={() => {
            dispatch(setIntegralEvaluationsFormStepActive(0));
            dispatch(setEvaTypesAutocomplete([]));
          }}
        />
        <Typography variant="h2">{t('Agregar escenario de evaluación')}</Typography>
      </Grid>
      <IntegralEvaluationForm />
    </Grid>
  );
};

export default AddIntegralInformationScreen;
