import { useAppSelector } from '../../../../hooks/useRedux';
import { matterDetailList, matterStatus } from '../constants';
import MatterDetailTable from './tables/MatterDetailTable';
import OmniChip from '../../../shared/components/badges/OmniChip';
import { useGetSemesterSubjectsDetailQuery } from '../slices/academicCounselingApi';
import SkeletonTable from '../../virtual-classroom/dashboard/components/SkeletonTable';
import { useTranslation } from 'react-i18next';

const SemesterMatterDetail = () => {
  const { t } = useTranslation();

  const { matterIdDetail } = useAppSelector((state) => state.academicCounseling);
  const { currentRecordStudent, currentProfile } = useAppSelector((state) => state.access);

  const { isLoading, data } = useGetSemesterSubjectsDetailQuery(
    {
      semester: matterIdDetail!,
      studentRecordId: currentRecordStudent?.id || 0,
      profileId: currentProfile?.profileId || 0,
    },
    {
      skip: !matterIdDetail,
    }
  );

  const dataFetch =
    data?.map((e, index) => ({
      index: index + 1,

      ...e,
    })) || [];

  return (
    <>
      {isLoading ? (
        <SkeletonTable />
      ) : (
        <MatterDetailTable
          data={dataFetch!}
          headers={{
            index: 'N°',
            cod_matter_migration: t('students.academic_counseling.code'),
            matter_rename: t('students.academic_counseling.subject'),
            final_note: t('students.academic_counseling.grade'),
            status_matter: t('students.academic_counseling.status'),
            amount_credits: t('students.academic_counseling.credit'),
          }}
          totalRowKeys={['amount_credits']}
          keyExtractor={(item) => item.cod_matter_migration.toString()}
          isLoading={false}
          isFetching={false}
          error={undefined}
          numHeader={5}
          page={5}
          perPage={5}
          total={matterDetailList.length}
          customRenderers={{
            status_matter: (row) => (
              <OmniChip
                variant={0}
                id={matterStatus.find((e) => e.name === row.status_matter)?.id || 0}
                statusArray={matterStatus}
              />
            ),
          }}
        />
      )}
    </>
  );
};

export default SemesterMatterDetail;
