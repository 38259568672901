import { useEffect, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Skeleton,
  Stack,
  TextField,
  Tooltip,
  Typography,
  //Theme,
  useTheme,
} from '@material-ui/core';
//import { makeStyles } from '@material-ui/core/styles';

import { useFormik } from 'formik';

// import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
// import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import InputLabel from '../../../shared/components/forms/InputLabel';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';

import { ClassroomEducationLvlSchema } from '../constants/validationSchemas';
import TextFieldFetching from '../../../shared/components/TextFieldFetching';
import { useGetAvailablesCampusQuery } from '../../campus/slices/campusApiSlice';
import CustomPagination from '../../../shared/components/tables/CustomPagination';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import SearchIcon from '@material-ui/icons/Search';
import {
  IconSquare,
  IconSquareCheck,
  IconLayoutGrid,
  IconList,
  IconSquareX,
  IconX,
  IconSquareMinus,
} from '@tabler/icons';
import { DebounceInput } from 'react-debounce-input';
import { useGetAcademicOfferingsAvailablesQuery } from '../../periods/slices/periodsApiSlice';
import {
  useGetAcademicOfferingQuery,
  useGetPeriodAcademicOfferingQuery,
} from '../../academic-offerings/slices/academicOfferingsApiSlice';
import {
  useAddClassroomEdLvlMutation,
  useGetClassroomsFilteredQuery,
} from '../slices/classroomEdLvlApiSlice';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import { openDeleteModalWith, updateClassroomEdLvlTable } from '../slices/classroomEdLvlSlice';
import CardView from './CardView';
import { useGetAvailableClassroomTypesQuery } from '../../classrooms/slices/classroomsApiSlice';
import IconTelework from '../../../shared/components/illustrations/IconTelework';
import { Period } from '../../academic-offerings/interfaces/periods.interface';
import SearchPaginatedTable, {
  FilterArrow,
} from '../../../shared/components/tables/SearchPaginatedTable';
import ClassroomStatusChip from '../../../shared/components/badges/ClassroomStatusChip';
import { useTableSearch } from '../../../../hooks/useTableSearch';
import { useTranslation } from 'react-i18next';
import useSharedGuard from '../../../../hooks/useSharedGuard';
import { subPermissionKeys } from '../constants/permission';
import { MeetingRoom } from '@material-ui/icons';
import ClassroomLoans from './ClassroomLoans';

/*const useStyles = makeStyles((theme: Theme) => ({
  customHeader: {
    textAlign:'center'
  },
}));*/

const AddClassroomEdLvlForm = () => {
  const { t } = useTranslation();

  const theme = useTheme();
  //const classes = useStyles();

  //permissions
  const { currentProfile } = useAppSelector((state) => state.access);

  // const hasCreateClassroomEdLvlPermission = currentModule?.permissions![
  //   'classroomEducationLevel'
  // ].filter((p) => p.key === 'classroom_education_levels-crear-aula-niveleconomico');
  // const hasDeleteClassroomEdLvlPermission = currentModule?.permissions![
  //   'classroomEducationLevel'
  // ].filter((p) => p.key === 'classroom_education_levels-borrar-aula-niveleconomico');

  //sedes
  const {
    data: campusAvailables = [],
    isLoading: isLoadingCA,
    isFetching: isFetchingCA,
  } = useGetAvailablesCampusQuery({
    profileId: currentProfile?.profileId!,
    statusId: 1,
  });

  const dispatch = useAppDispatch();

  //filtros
  const [filtersSearch, setFiltersSearch] = useState<boolean>();
  //active search btn
  const [searchBtn, setSearchBtn] = useState<boolean>();
  //filtros labels
  const [displayFilters, setDisplayFilters] = useState<boolean>(true);
  // const [viewMode] = useState<'Card' | 'Table'>('Card');
  //const [selectAll, setSelectAll] = useState<boolean>(true);
  const [selection, setSelection] = useState<number[]>([]);
  const [viewMode, setViewMode] = useState<'table' | 'grid'>('grid');

  const [filterArrow, setFilterArrow] = useState<FilterArrow>(null!);

  const { page, perPage, search } = useAppSelector(
    (state) => state.classroomEdLvl.classroomEdLvlTable
  );

  const { setPage, setPerPage, setSearch, resetTable } = useTableSearch({
    page,
    perPage,
    search,
    updateFunction: updateClassroomEdLvlTable,
  });

  const [showAlerts, setShowAlerts] = useState(true);
  // const [sort, setSort] = useState('id');

  const [filterType, setFilterType] = useState<number | null>(null);
  // const [optionsTypes, setOptionsTypes] = useState<{ [key: string]: number }>({});
  const [filterStatus, setFilterStatus] = useState<string | null>(null);
  const [optionsStatus] = useState({
    available: t('utils.classroom_status.available'),
    disabled: t('utils.classroom_status.unavailable'),
    asigned: t('utils.classroom_status.assigned'),
  });
  //state de errores
  const [isFilterError, setIsError] = useState<boolean>();

  // const [ranId, setRanId] = useState(0);

  const [autocompleteKey, setAutocompleteKey] = useState<{
    campus: number;
    period: number;
    offer: number;
    educationLvl: number;
  }>({ campus: 0, period: 0, offer: 0, educationLvl: 0 });
  const [labelsText, setLabelsText] = useState<{
    campus: string | undefined;
    period: string | undefined;
    offer: string | undefined;
    educationLvl: string | undefined;
  }>({ campus: '', period: '', offer: '', educationLvl: '' });

  //   const { data: campusAvailables = [], isLoading: isLoadingCAMP } = useGetAvailablesCampusQuery({
  //     profileId: currentProfile?.profileId!,
  //   });

  //   const { data: acdOfferingsAvailables = [], isLoading: isLoadingACD } =
  //     useGetAcademicOfferingsAvailablesQuery({
  //       profileId: currentProfile?.profileId!,
  //     });

  const [addClassroomEdLvl, { isLoading, isError, error, isSuccess }] =
    useAddClassroomEdLvlMutation();

  //replace formik with states?
  const { errors, touched, handleSubmit, values, setFieldValue, setValues } = useFormik({
    initialValues: {
      campus_id: 0,
      period_id: 0,
      academicOffer_id: 0,
      educationLvl_id: 0,
      status_id: 1,
      classrooms: [] as number[],
      // alreadyAsigned: [] as number[],
    },
    validationSchema: ClassroomEducationLvlSchema,
    onSubmit: async (values) => {
      await addClassroomEdLvl({
        profileId: currentProfile?.profileId!,
        classroomEdLvlPayload: {
          period_id: values.period_id.toString(),
          education_level_id: values.educationLvl_id.toString(),
          classrooms: values.classrooms,
          status_id: values.status_id.toString(),
        },
      });
      setShowAlerts(true);
    },
  });

  //reset filters values
  const resetFiltersHandler = () => {
    setFiltersSearch(false);
    setValues({ ...values, campus_id: 0, period_id: 0, academicOffer_id: 0, educationLvl_id: 0 });
    setAutocompleteKey({
      campus: Math.random(),
      period: Math.random(),
      offer: Math.random(),
      educationLvl: Math.random(),
    });
  };

  //ofertas
  const {
    data: offers = [],
    isLoading: isLoadingOFF,
    isFetching: isFetchingOFF,
  } = useGetAcademicOfferingsAvailablesQuery({
    profileId: currentProfile?.profileId!,
    statusId: 1,
  });

  //periodos por la oferta
  const {
    data: periodsByOffer,
    isLoading: isLoadingCID,
    isFetching: isFetchingCID,
  } = useGetPeriodAcademicOfferingQuery(
    {
      profileId: currentProfile?.profileId!,
      academicOfferingId: values.academicOffer_id.toString(),
      current: true,
      isAdmin: false,
    },
    { skip: !values.academicOffer_id, refetchOnMountOrArgChange: true }
  );

  //aulas asignadas
  // const {
  //   data: classroomsEdLvls = [],
  //   // isLoading: isLoadingCAED,
  //   isFetching: isFetchingCAED,
  //   error: errorClassroomEdLvls,
  //   refetch: refetchClassEdLvls,
  // } = useGetClassroomEdLvlsQuery(
  //   {
  //     profileId: currentProfile?.profileId!,
  //     periodId: values.period_id!,
  //   },
  //   { skip: !values.period_id, refetchOnMountOrArgChange: true }
  // );

  //facultades
  const {
    data: offerById,
    isLoading: isLoadingOID,
    isFetching: isFetchingOID,
  } = useGetAcademicOfferingQuery(
    {
      profileId: currentProfile?.profileId!,
      academicOfferingId: values.academicOffer_id,
    },
    { skip: !values.academicOffer_id, refetchOnMountOrArgChange: true }
  );

  //tipos de aulas
  const { data: classroomTypes = [], isFetching: isFetchingCLT } =
    useGetAvailableClassroomTypesQuery(
      {
        profileId: currentProfile?.profileId!,
      },
      { skip: !values.educationLvl_id, refetchOnMountOrArgChange: true }
    );

  //aulas del campus
  // const {
  //   isLoading: isLoadingClassrooms,
  //   error: errorClassrooms,
  //   data: classroomData,
  //   isFetching: isFetchingClassrooms,
  //   refetch: refetchClassroomData,
  // } = useGetClassroomsByCampusQuery(
  //   {
  //     profileId: currentProfile?.profileId!,
  //     campusId: values.campus_id!,
  //     page,
  //     sort,
  //     order: order,
  //     perPage,
  //     search,
  //   },
  //   { refetchOnMountOrArgChange: 30, skip: !values.educationLvl_id }
  // );

  //aulas por campus, education level, period
  const {
    isLoading: isLoadingClassrooms,
    error: errorClassrooms,
    data: classroomData,
    isFetching: isFetchingClassrooms,
    refetch: refetchClassroomData,
  } = useGetClassroomsFilteredQuery(
    {
      profileId: currentProfile?.profileId!,
      campusId: values.campus_id!,
      periodId: values.period_id!,
      educationLvl: values.educationLvl_id!,
      page,
      perPage,
      search,
      classroomStatus: filterStatus,
      classroomTypeId: filterType,
      ...(filterArrow && { sort: filterArrow.name, type_sort: filterArrow.direction }),
      statusId: 1,
    },
    { refetchOnMountOrArgChange: 30, skip: !values.period_id }
  );

  // const [classroomEdLvlSelected, setClassroomEdLvlSelected] = useState<number[]>();

  //Si hay errores en los request activar un state
  useEffect(() => {
    // if (errorClassrooms || errorClassroomEdLvls) {
    if (errorClassrooms) {
      setIsError(true);
    } else {
      setIsError(false);
    }
  }, [errorClassrooms]);

  //filtrar por facultad
  // useEffect(() => {
  //   if (classroomsEdLvls?.length) {
  //     const filtered = classroomsEdLvls.filter(
  //       (dt) => dt.education_level_id === values.educationLvl_id.toString()
  //     );
  //     setClassroomEdLvlSelected(filtered.map((dt) => Number(dt.classroom_id)));
  //   }
  // }, [classroomsEdLvls, values.educationLvl_id]);

  //asignar al formik
  // useEffect(() => {
  //   if (classroomEdLvlSelected) {
  //     // if (!values.alreadyAsigned.length)
  //     values.alreadyAsigned = [...classroomEdLvlSelected];
  //     // if (!values.classrooms.length)
  //     values.classrooms = [...classroomEdLvlSelected];
  //   }
  // }, [values, classroomEdLvlSelected]);

  //habilitar boton de busqueda
  useEffect(() => {
    if (values.campus_id && values.period_id && values.academicOffer_id) {
      setSearchBtn(true);
    } else {
      setSearchBtn(false);
    }
  }, [values.campus_id, values.educationLvl_id, values.period_id, values.academicOffer_id]);

  const handleDelete = async (classroomEdLvlId: number) => {
    dispatch(openDeleteModalWith(classroomEdLvlId));
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorElClassType, setAnchorElClassType] = useState<null | HTMLElement>(null);
  const [anchorElClassStatus, setAnchorElClassStatus] = useState<null | HTMLElement>(null);
  const [opened, setOpened] = useState(false);
  const [classId, setClassId] = useState<number>();

  const handleCloseDialog = () => {
    setOpened(false);
  };

  const handleOpenClientSideDialog = (idClass: number) => {
    // selection.map((ids)=>{
    //   const idClass = Number(ids);
    // event?.stopPropagation();/
    setClassId(idClass);
    //   return idClass;
    // })
    setOpened(true);
  };

  const open = Boolean(anchorEl);
  const openClassType = Boolean(anchorElClassType);
  const openClassStatus = Boolean(anchorElClassStatus);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickFilterClassType = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElClassType(event.currentTarget);
  };

  const handleClickFilterClassStatus = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElClassStatus(event.currentTarget);
  };

  const handleClose = (newPerPage: number) => {
    setAnchorEl(null);
    setPerPage(newPerPage);
  };

  const handleCloseFilterClassType = (filterType: string | undefined | null) => {
    setAnchorElClassType(null);
    setFilterType(Number(filterType));
    resetTable();
  };

  const handleCloseFilterClassStatus = (filterStatus: string) => {
    setAnchorElClassStatus(null);
    setFilterStatus(filterStatus);
    resetTable();
  };

  //tipos de aulas
  // if (classroomTypes.length) {
  //   let aux: { [key: string]: number } = {};
  //   for (let classType of classroomTypes) {
  //     if (classType.clt_name in aux) {
  //       ++aux[classType.clt_name];
  //     } else {
  //       aux[classType.clt_name] = 1;
  //     }
  //   }
  //   if (!Object.entries(optionsTypes).length) {
  //     setOptionsTypes({ ...aux });
  //   }
  // }

  //Permissions
  const { hasAccess } = useSharedGuard(subPermissionKeys);

  //Classroom Card Click
  const handleClickClassroom = (
    status: 'disponible' | 'no disponible' | 'asignada',
    id: number,
    idAsigned: string | undefined,
    selected: boolean
  ) => {
    if (status === 'no disponible') return;

    if (hasAccess('create')) {
      if (selected || status === 'asignada') {
        //si esta seleccionado y si esta en base de datos
        // const isRegistered = classroomsEdLvls.filter((dt) => dt.classroom_id === id.toString());

        if (!selected) {
          if (hasAccess('delete')) {
            handleDelete(Number(idAsigned!));
          }
        }
        values.classrooms.splice(values.classrooms.indexOf(id), 1);
        setSelection((prevState) => {
          return prevState.filter((state) => state !== id);
        });
      } else {
        values.classrooms.push(id);
        setSelection((prevState) => {
          return [...prevState, id];
        });
      }
    }
  };

  //reset selection on success
  useEffect(() => {
    if (isSuccess) {
      setSelection([]);
      setFieldValue('classrooms', []);
    }
  }, [isSuccess, setFieldValue]);

  const datafetch = classroomData?.data!.map((d) => {
    //validar estado seleccionado
    let selected = false;
    if (selection.some((selected) => selected === d.id)) {
      selected = true;
    }

    return {
      selected: selected,
      name: d.cl_name,
      capacity: d.cl_cap_max,
      statusId: d.EstadoAula,
      type: d.clt_name,
      options: d,
    };
  });

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      /*const dataFilter =*/ classroomData
        ?.data!.filter((d) => d.EstadoAula.toLowerCase() !== 'asignada')
        .map((d) => {
          if (hasAccess('create')) {
            if (!values.classrooms.includes(d.id)) values.classrooms.push(d.id);
            if (!selection.includes(d.id)) {
              setSelection((prevState) => {
                return [...prevState, d.id];
              });
            }
          }
          return [d.EstadoAula.toLowerCase(), d.id!, d.cl_edu_lev_id!, !event.target.checked];
        });
      return;
    }
    values.classrooms = [];
    setSelection([]);
  };

  return (
    <form onSubmit={handleSubmit}>
      {/* Vista default vacia */}
      {!isFilterError && !filtersSearch && (
        <Box>
          <Stack
            component="div"
            direction={{ xs: 'column', sm: 'row' }}
            justifyContent="center"
            alignItems="center"
            spacing={3}
            pb={3}
            sx={{ px: { sm: 2, lg: 18, xl: 24 } }}
          >
            <IconTelework size={300} />
            <Typography variant={'body1'} textAlign={'left'} lineHeight={2} component={'span'}>
              <Typography
                variant={'h4'}
                sx={{ color: theme.palette.primary.main }}
                component={'p'}
                lineHeight={2}
              >
                {t('academic.classrooms_distribution.subtitle')}
              </Typography>
              {t('academic.classrooms_distribution.description')}
            </Typography>
          </Stack>
        </Box>
      )}

      {/* filtros retraidos refactorizar en un componente de filtros unicamente */}
      {!displayFilters && (
        <Grid
          container
          item
          xs={12}
          sx={{ border: `1px solid #008DE1`, borderRadius: '5px', py: '10px', px: '14px' }}
        >
          <Grid container item md={12} lg={10} alignContent={'center'} spacing={2}>
            <Grid item xs={12} sm={6} md={6} lg={'auto'}>
              <InputLabel sx={{ m: '0px!important' }}>
                {t('academic.classrooms_distribution.campus')}:{' '}
                <Typography variant={'h5'} component={'span'}>
                  {labelsText.campus}
                </Typography>
              </InputLabel>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={'auto'}>
              <InputLabel sx={{ m: '0px!important' }}>
                {t('academic.classrooms_distribution.edu_level')}:{' '}
                <Typography variant={'h5'} component={'span'}>
                  {labelsText.offer}
                </Typography>
              </InputLabel>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={'auto'}>
              <InputLabel sx={{ m: '0px!important' }}>
                {t('academic.classrooms_distribution.period')}:{' '}
                <Typography variant={'h5'} component={'span'}>
                  {labelsText.period}
                </Typography>
              </InputLabel>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={'auto'}>
              <InputLabel sx={{ m: '0px!important' }}>
                {t('academic.classrooms_distribution.academic_unit')}:{' '}
                <Typography variant={'h5'} component={'span'}>
                  {labelsText.educationLvl || 'Todos'}
                </Typography>
              </InputLabel>
            </Grid>
          </Grid>
          <Grid container item xs={12} lg={2} justifyContent={'flex-end'}>
            <Button onClick={() => setDisplayFilters(true)}>
              {t('academic.classrooms_distribution.edit')}
            </Button>
          </Grid>
        </Grid>
      )}

      {/* filtros desplegados refactorizar en un componente de filtros unicamente */}
      <Grid item xs={12} hidden={!displayFilters}>
        <Grid container direction="column" gap={2}>
          <Grid item container spacing={2} alignItems="center">
            {hasAccess('readCampus') && (
              <Grid item xs={12} md={3}>
                <InputLabel>{t('academic.classrooms_distribution.campus')}:</InputLabel>
                {!isFetchingCA && (
                  <Autocomplete
                    options={campusAvailables}
                    loading={isLoadingCA}
                    loadingText={t('academic.classrooms_distribution.campus.loading')}
                    noOptionsText={t('academic.classrooms_distribution.campus.no_data')}
                    getOptionLabel={(option) => option.cam_name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={Boolean(errors.campus_id && touched.campus_id)}
                        placeholder={t('academic.classrooms_distribution.campus.placeholder')}
                      />
                    )}
                    value={campusAvailables.find((dt) => dt.id === values.campus_id) || null}
                    onChange={(_, value) => {
                      resetTable();
                      setFiltersSearch(false);
                      setAutocompleteKey((prevState) => {
                        return {
                          ...prevState,
                          period: Math.random(),
                          offer: Math.random(),
                          educationLvl: Math.random(),
                        };
                      });
                      setFieldValue('campus_id', value?.id || null);
                      setFieldValue('academicOffer_id', 0);
                      setFieldValue('period_id', 0);
                      setFieldValue('educationLvl_id', 0);

                      setLabelsText((prevState) => {
                        return { ...prevState, campus: value?.cam_name };
                      });
                    }}
                    fullWidth
                    clearIcon={null}
                  />
                )}
                {isFetchingCA && <TextFieldFetching />}
                {errors.campus_id && touched.campus_id && (
                  <FormHelperText error>{errors.campus_id}</FormHelperText>
                )}
              </Grid>
            )}

            {hasAccess('readOffers') && (
              <Grid item xs={12} md={3}>
                <InputLabel>{t('academic.classrooms_distribution.edu_level')}:</InputLabel>
                {!isFetchingOFF && (
                  <Autocomplete
                    key={autocompleteKey.offer}
                    options={offers}
                    loading={isLoadingOFF}
                    loadingText={t('academic.classrooms_distribution.edu_level.loading')}
                    noOptionsText={t('academic.classrooms_distribution.edu_level.no_data')}
                    getOptionLabel={(option) => option.off_name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={Boolean(errors.academicOffer_id && touched.academicOffer_id)}
                        placeholder={t('academic.classrooms_distribution.edu_level.placeholder')}
                      />
                    )}
                    onChange={(_, value) => {
                      setFiltersSearch(false);
                      setAutocompleteKey({
                        ...autocompleteKey,
                        educationLvl: Math.random(),
                        period: Math.random(),
                      });
                      setFieldValue('academicOffer_id', value?.id || null);
                      setFieldValue('period_id', 0);
                      setFieldValue('educationLvl_id', 0);

                      setLabelsText((prevState) => {
                        return { ...prevState, offer: value?.off_name };
                      });
                    }}
                    fullWidth
                    clearIcon={null}
                  />
                )}
                {isFetchingOFF && <TextFieldFetching />}
                {errors.academicOffer_id && touched.academicOffer_id && (
                  <FormHelperText error>{errors.academicOffer_id}</FormHelperText>
                )}
              </Grid>
            )}

            {hasAccess('readOfferByPeriod') && (
              <Grid item xs={12} md={3}>
                <InputLabel>{t('academic.classrooms_distribution.period')}:</InputLabel>
                {!isFetchingCID && (
                  <Autocomplete
                    key={autocompleteKey?.period}
                    options={
                      //se excluyen periodos finalizados
                      (Array.isArray(periodsByOffer)
                        ? periodsByOffer
                        : ([] as unknown as Period[])) || []
                      // periodsByOffer
                      //   ? periodsByOffer.data.filter(
                      //       (dt) => new Date(dt.end_process).getTime() > new Date().getTime()
                      //     )
                      //   : []
                    }
                    loading={isLoadingCID}
                    loadingText={t('academic.classrooms_distribution.period.loading')}
                    noOptionsText={t('academic.classrooms_distribution.period.no_data')}
                    getOptionLabel={(option) => option.per_name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={Boolean(errors.period_id && touched.period_id)}
                        placeholder={t('academic.classrooms_distribution.period.placeholder')}
                      />
                    )}
                    onChange={(_, value) => {
                      setFiltersSearch(false);
                      setAutocompleteKey({
                        ...autocompleteKey,
                        // educationLvl: Math.random(),
                      });
                      setFieldValue('period_id', value?.id || null);
                      // setFieldValue('educationLvl_id', 0);

                      setLabelsText((prevState) => {
                        return { ...prevState, period: value?.per_name };
                      });
                    }}
                    fullWidth
                    clearIcon={null}
                  />
                )}
                {isFetchingCID && <TextFieldFetching />}
                {errors.period_id && touched.period_id && (
                  <FormHelperText error>{errors.period_id}</FormHelperText>
                )}
              </Grid>
            )}

            {hasAccess('readOffersById') && (
              <Grid item xs={12} md={3}>
                <InputLabel>{t('academic.classrooms_distribution.academic_unit')}:</InputLabel>
                {!isFetchingOID && (
                  <Autocomplete
                    key={autocompleteKey.educationLvl}
                    options={offerById ? offerById!.education_levels : []}
                    loading={isLoadingOID}
                    loadingText={t('academic.classrooms_distribution.academic_unit.loading')}
                    noOptionsText={t('academic.classrooms_distribution.academic_unit.no_data')}
                    getOptionLabel={(option) => option.edu_name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={Boolean(errors.educationLvl_id && touched.educationLvl_id)}
                        placeholder={t(
                          'academic.classrooms_distribution.academic_unit.placeholder'
                        )}
                      />
                    )}
                    onChange={(_, value) => {
                      setFiltersSearch(false);
                      setFieldValue('educationLvl_id', value?.id || null);

                      setLabelsText((prevState) => {
                        return { ...prevState, educationLvl: value?.edu_name };
                      });
                    }}
                    fullWidth
                    clearIcon={null}
                  />
                )}
                {isFetchingOID && <TextFieldFetching />}
                {errors.educationLvl_id && touched.educationLvl_id && (
                  <FormHelperText error>{errors.educationLvl_id}</FormHelperText>
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      {displayFilters && (
        <Grid container alignItems="center" justifyContent="center" spacing={2} sx={{ mt: 1 }}>
          <Grid item>
            {/* setDisplayFilters(false); */}
            <Button size="large" onClick={resetFiltersHandler}>
              {t('academic.classrooms_distribution.reset')}
            </Button>
          </Grid>
          {hasAccess('readCampus') &&
            hasAccess('readOffers') &&
            hasAccess('readOfferByPeriod') &&
            hasAccess('readOffersById') && (
              <Grid item>
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  onClick={() => {
                    if (filtersSearch) {
                      // refetchClassEdLvls();
                      refetchClassroomData();
                    } else {
                      // setDisplayFilters(false);
                    }
                    setDisplayFilters(false);
                    setFiltersSearch(true);
                  }}
                  disabled={!searchBtn}
                >
                  {t('academic.classrooms_distribution.search')}
                </Button>
              </Grid>
            )}
        </Grid>
      )}

      <Grid container my={1}>
        <Grid item xs={12}>
          {isError && showAlerts && (
            <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
          )}

          {isSuccess && showAlerts && (
            <SuccessAlert
              message={t('academic.classrooms_distribution.success')}
              handleDismiss={() => setShowAlerts(false)}
            />
          )}
        </Grid>
      </Grid>

      {/* error en las request para mostrar la aulas */}
      {isFilterError && filtersSearch && !isFetchingClassrooms && (
        <>
          <Divider sx={{ my: 4 }} />
          <Typography variant={'body2'} align={'center'} sx={{ mt: 5 }}>
            {t('academic.classrooms_distribution.error')}
          </Typography>
        </>
      )}

      {/* {!isFilterError && filtersSearch && (
        <Grid item container xs={12} md={8} sx={{ my: '5px' }}>
          <Grid item xs={3} md={3}>
            <InputLabel>Total de aulas: {classroomData?.total || 0}</InputLabel>
          </Grid>

          {Object.keys(types).map((key, i) => {
            return (
              <Grid item xs={3} md={3} key={key + i}>
                <InputLabel>
                  {key}: {types[key]}
                </InputLabel>
              </Grid>
            );
          })}
        </Grid>
      )} */}

      {filtersSearch && !isLoadingClassrooms && !isFilterError && classroomData && (
        <>
          {/* header */}
          <Grid container justifyContent="space-between" alignItems="center" spacing={2} mt={2}>
            {/* title  */}
            <Grid item xs={12}>
              <Typography variant="h3">
                {t('academic.classrooms_distribution.classrooms')}
              </Typography>
            </Grid>

            {/* filtros */}
            <Grid container item xs={12} lg={8}>
              <Grid container alignItems={'baseline'} spacing={2}>
                {!Boolean(selection.length) && (
                  <>
                    <Grid item xs={'auto'}>
                      <Typography
                        variant="h4"
                        component={'span'}
                        sx={{ color: `${theme.palette.primary.main}` }}
                      >
                        {t('academic.classrooms_distribution.classrooms.filter')}
                      </Typography>
                    </Grid>
                    {/*  filter by type */}
                    <Grid item xs={'auto'}>
                      <Typography
                        component={'span'}
                        sx={{ fontWeight: 500, color: theme.palette.text.primary }}
                      >
                        {t('academic.classrooms_distribution.classrooms.type')}
                      </Typography>
                      {isFetchingCLT && (
                        <>{t('academic.classrooms_distribution.classrooms.loading')}</>
                      )}
                      {!isFetchingCLT && (
                        <>
                          <Box
                            component={Button}
                            size="large"
                            sx={{
                              color: theme.palette.grey[900],
                              backgroundColor: theme.palette.primary.light,
                              ':hover': { bgcolor: theme.palette.primary.light },
                              px: 2,
                              borderRadius: 4,
                              mx: 1,
                            }}
                            endIcon={<ExpandMoreRoundedIcon />}
                            onClick={handleClickFilterClassType}
                          >
                            {filterType
                              ? classroomTypes!.find((dt) => dt.id === filterType)!.clt_name
                              : t('academic.classrooms_distribution.classrooms.general')}
                          </Box>
                          <Menu
                            anchorEl={anchorElClassType}
                            keepMounted
                            open={openClassType}
                            onClose={() => handleCloseFilterClassType(filterType?.toString())}
                            variant="selectedMenu"
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'right',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                            }}
                          >
                            <MenuItem onClick={() => handleCloseFilterClassType(null)}>
                              General
                            </MenuItem>
                            {classroomTypes.map((classroomType) => {
                              return (
                                <MenuItem
                                  key={`search-by-type-${classroomType.id}`}
                                  onClick={() => handleCloseFilterClassType(`${classroomType.id}`)}
                                >
                                  {classroomType.clt_name}
                                </MenuItem>
                              );
                            })}
                          </Menu>
                        </>
                      )}
                    </Grid>
                    {/* filter class state */}
                    <Grid item xs={'auto'}>
                      <Typography
                        component={'span'}
                        sx={{ fontWeight: 500, color: theme.palette.text.primary }}
                      >
                        {t('academic.classrooms_distribution.classrooms.status')}
                      </Typography>
                      <Box
                        component={Button}
                        size="large"
                        sx={{
                          color: theme.palette.grey[900],
                          backgroundColor: theme.palette.primary.light,
                          ':hover': { bgcolor: theme.palette.primary.light },
                          px: 2,
                          borderRadius: 4,
                          mx: 1,
                        }}
                        endIcon={<ExpandMoreRoundedIcon />}
                        onClick={handleClickFilterClassStatus}
                      >
                        {filterStatus === 'D'
                          ? t('utils.classroom_status.available')
                          : filterStatus === 'N'
                          ? t('utils.classroom_status.unavailable')
                          : filterStatus === 'A'
                          ? t('utils.classroom_status.assigned')
                          : t('utils.classroom_status.all')}
                      </Box>
                      <Menu
                        anchorEl={anchorElClassStatus}
                        keepMounted
                        open={openClassStatus}
                        onClose={() => handleCloseFilterClassStatus(search)}
                        variant="selectedMenu"
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                      >
                        <MenuItem onClick={() => handleCloseFilterClassStatus('')}>
                          {t('utils.classroom_status.all')}
                        </MenuItem>
                        {Object.keys(optionsStatus).map((key) => {
                          return (
                            <MenuItem
                              key={`search-by-${key}`}
                              onClick={() =>
                                handleCloseFilterClassStatus(
                                  `${key === 'available' ? 'D' : key === 'disabled' ? 'N' : 'A'}`
                                )
                              }
                            >
                              {optionsStatus[key as 'available' | 'disabled' | 'asigned']}
                            </MenuItem>
                          );
                        })}
                      </Menu>
                    </Grid>
                  </>
                )}
                {Boolean(selection.length) && (
                  <>
                    <Grid item xs={'auto'}>
                      {selection.length} {t('academic.classrooms_distribution.classrooms.selected')}
                    </Grid>
                    <Grid item xs={'auto'}>
                      <Button
                        variant="outlined"
                        size="large"
                        color="primary"
                        onClick={() => {
                          values.classrooms = [];
                          setSelection([]);
                        }}
                      >
                        {t('academic.classrooms_distribution.classrooms.delete')}
                      </Button>
                    </Grid>
                    {hasAccess('create') && (
                      <Grid item xs={'auto'}>
                        <Button
                          type="submit"
                          variant="contained"
                          size="large"
                          color="primary"
                          disabled={isLoading || !hasAccess('create')}
                          endIcon={isLoading && <CircularProgress size={20} />}
                        >
                          {t('academic.classrooms_distribution.classrooms.assign')}
                        </Button>
                      </Grid>
                    )}
                  </>
                )}
              </Grid>
            </Grid>

            {/* search */}
            <Grid item xs={12} lg={4} sx={{ textAlign: 'right' }}>
              {/* sort by type */}
              {/* <Tooltip title="Ordenar por tipo de aula">
                  <IconButton
                    aria-label="Ordenar por tipo de aula"
                    color="default"
                    onClick={() => {
                      setSort('classroom_type_id');
                      order === 'asc' ? setOrder('desc') : setOrder('asc');
                    }}
                  >
                    {order === 'asc' && <IconSortDescending size={20} />}
                    {order === 'desc' && <IconSortAscending size={20} />}
                  </IconButton>
                </Tooltip> */}
              {/* debounce search */}
              <DebounceInput
                autoFocus={Boolean(search)}
                minLength={1}
                debounceTimeout={300}
                onChange={({ target }) => {
                  setSearch(target.value);
                }}
                value={search}
                element={(props) => (
                  <TextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon fontSize="small" />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            size="small"
                            onClick={() => setSearch('')}
                            {...(!search && { sx: { cursor: 'initial', opacity: 0 } })}
                          >
                            <IconX size={20} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    placeholder={t('academic.classrooms_distribution.classrooms.search')}
                    variant="outlined"
                    size="small"
                    {...props}
                  />
                )}
              />
            </Grid>
          </Grid>

          {/* divider */}
          <Divider sx={{ my: 4 }} />
          {/* Switch view */}
          <Grid container justifyContent="space-between" alignItems="center" spacing={2} mt={2}>
            <Grid item xs={12} sx={{ textAlign: 'right' }}>
              <Box>
                <Tooltip title={t('academic.classrooms_distribution.classrooms.table_view')}>
                  <IconButton onClick={() => setViewMode('table')}>
                    <IconList
                      size={20}
                      {...(viewMode === 'table' && { color: theme.palette.primary.main })}
                    />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t('academic.classrooms_distribution.classrooms.card_view')}>
                  <IconButton onClick={() => setViewMode('grid')}>
                    <IconLayoutGrid
                      size={20}
                      {...(viewMode === 'grid' && { color: theme.palette.primary.main })}
                    />
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>
          </Grid>

          {viewMode === 'table' && (
            <SearchPaginatedTable
              data={datafetch!}
              headers={{
                selected: (
                  <Checkbox
                    disabled={isLoadingClassrooms || isFetchingClassrooms}
                    color="primary"
                    indeterminate={
                      classroomData?.data &&
                      selection.length > 0 &&
                      selection.length <
                        (classroomData?.data.filter(
                          (d) => d.EstadoAula.toLowerCase() !== 'asignada'
                        ).length || 0)
                    }
                    checked={
                      classroomData?.data &&
                      (classroomData?.data.length || 0) > 0 &&
                      (selection.length || 0) > 0 &&
                      selection.length >=
                        (classroomData?.data.filter(
                          (d) => d.EstadoAula.toLowerCase() !== 'asignada'
                        ).length || 0) /* * page */
                    }
                    icon={<IconSquare />}
                    checkedIcon={<IconSquareCheck />}
                    indeterminateIcon={<IconSquareMinus />}
                    onChange={handleSelectAll}
                    inputProps={{
                      'aria-label': 'select all cash points',
                    }}
                  />
                ) as any,
                name: t('academic.classrooms_distribution.classrooms.table_view.classrooms'),
                capacity: t('academic.classrooms_distribution.capacity'),
                type: t('academic.classrooms_distribution.classrooms.table_view.type'),
                statusId: t('academic.classrooms_distribution.classrooms.table_view.status'),
                options: t('academic.classrooms_distribution.classrooms.table_view.options'),
              }}
              keyExtractor={(item) => item.options.id.toString()}
              searchPlacehoder={t('academic.classrooms_distribution.classrooms.search')}
              listFilterArrows={{
                name: 'cl_name',
                capacity: 'cl_cap_max',
              }}
              filterArrow={filterArrow}
              setFilterArrow={setFilterArrow}
              isLoading={isLoadingClassrooms}
              isFetching={isFetchingClassrooms}
              error={errorClassrooms}
              perPage={perPage}
              setPerPage={setPerPage}
              total={classroomData?.total || 0}
              page={page}
              setPage={setPage}
              numHeader={4}
              search={search}
              setSearch={setSearch}
              showFilter={false}
              customDataCellsProperties={{
                selected: {
                  align: 'center',
                },
                name: {
                  align: 'center',
                },
                capacity: {
                  align: 'center',
                },
                type: {
                  align: 'center',
                },
                statusId: {
                  align: 'center',
                },
                options: {
                  align: 'center',
                },
              }}
              customRenderers={{
                selected: (row) => (
                  <Checkbox
                    color="primary"
                    //className={`${classes.circleStatus} ${row.selected ? classes.circleStatusSelected : ''}`}
                    icon={
                      row.statusId.toLowerCase() === 'asignada' ? (
                        <IconSquareX color={theme.palette.error.main} />
                      ) : (
                        <IconSquare />
                      )
                    }
                    checkedIcon={<IconSquareCheck />}
                    checked={row.selected}
                    onClick={(e) =>
                      handleClickClassroom(
                        row.statusId.toLowerCase() as 'disponible' | 'no disponible' | 'asignada',
                        row.options?.id!,
                        row.options?.cl_edu_lev_id!,
                        row.selected!
                      )
                    }
                    //{...getFieldProps('rememberMe')}
                  />
                ),
                name: (row) => row.name,
                type: (row) => row.options.clt_name,
                statusId: (row) => <ClassroomStatusChip statusName={row.statusId} />,
                options: (row) => (
                  <>
                    <Button onClick={() => handleOpenClientSideDialog(row.options.id)}>
                      <MeetingRoom />
                    </Button>

                    <ClassroomLoans
                      open={opened && classId === row.options.id}
                      periodId={values?.period_id!}
                      handleCloseDialog={handleCloseDialog}
                      id={classId!}
                      offerId={values?.academicOffer_id}
                    />
                  </>
                ),
              }}
              /*replaceHeader={true}
              newHeader={<>
                <TableCell >
                  <Checkbox
                    color="primary"
                    //className={`${classes.circleStatus} ${row.selected ? classes.circleStatusSelected : ''}`}
                    icon={< IconSquare />}
                    checkedIcon={< IconSquareCheck />}
                    //checked={row.selected}
                    onChange={handleSelectAll}
                    //{...getFieldProps('rememberMe')}
                  />
                </TableCell>
                <TableCell >
                  Materia
                </TableCell>
                <TableCell >
                  Tipo
                </TableCell>
                <TableCell >
                  Estado
                </TableCell>
              </>}*/
            />
          )}

          {viewMode === 'grid' && (
            <>
              {/* cards */}
              {!isFetchingClassrooms && (
                <Box sx={{ mt: '1rem' }}>
                  <Grid container>
                    {classroomData?.data.map((data) => {
                      // let status: 'selected' | 'asigned' | 'disabled' | 'available' = 'available';

                      // if (values.classrooms.some((classroom) => classroom === data.id)) {
                      //   if (values.alreadyAsigned.some((classroom) => classroom === data.id)) {
                      //     status = 'asigned';
                      //   } else {
                      //     status = 'selected';
                      //   }
                      // } else {
                      //   status = 'available';
                      // }

                      // if (
                      //   classroomsEdLvls?.some(
                      //     (classroom) =>
                      //       Number(classroom.classroom_id) === data.id &&
                      //       classroom.education_level_id !== values.educationLvl_id.toString()
                      //   )
                      // ) {
                      //   status = 'disabled';
                      // }

                      //validar estado seleccionado
                      let selected = false;
                      if (selection.some((selected) => selected === data.id)) selected = true;

                      //views
                      return (
                        <CardView
                          data={data}
                          onClick={handleClickClassroom}
                          selected={selected}
                          periodId={values?.period_id!}
                          offerId={values?.academicOffer_id!}
                          key={`cardview-card-${data.id}`}
                        />
                      );
                    })}
                    {!classroomData.data.length && (
                      <Grid item xs={12}>
                        <Typography align="center">
                          {t('academic.classrooms_distribution.no_data')}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Box>
              )}
              {isFetchingClassrooms && renderSkeletonGrids(perPage)}

              {/* footer */}
              <Grid container justifyContent="space-between" alignItems="flex-end" sx={{ mt: 4 }}>
                <Grid item>
                  <CustomPagination
                    shape="rounded"
                    color="primary"
                    count={Math.ceil(classroomData!.total / perPage) || 1}
                    page={page}
                    onChange={(_, newPage) => {
                      setPage(newPage);
                    }}
                  />
                </Grid>
                <Grid>
                  <Box
                    component={Button}
                    size="large"
                    sx={{
                      color: theme.palette.grey[900],
                      backgroundColor: theme.palette.primary.light,
                      ':hover': { bgcolor: theme.palette.primary.light },
                      px: 2,
                      borderRadius: 4,
                    }}
                    endIcon={<ExpandMoreRoundedIcon />}
                    onClick={handleClick}
                  >
                    {perPage} {t('academic.classrooms_distribution.records')}
                  </Box>

                  <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={() => handleClose(perPage)}
                    variant="selectedMenu"
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                  >
                    <MenuItem onClick={() => handleClose(8)}>
                      {' '}
                      8 {t('academic.classrooms_distribution.records')}
                    </MenuItem>
                    <MenuItem onClick={() => handleClose(16)}>
                      {' '}
                      16 {t('academic.classrooms_distribution.records')}
                    </MenuItem>
                    <MenuItem onClick={() => handleClose(24)}>
                      {' '}
                      24 {t('academic.classrooms_distribution.records')}
                    </MenuItem>
                  </Menu>
                </Grid>
              </Grid>
            </>
          )}
        </>
      )}
      {filtersSearch && isLoadingClassrooms && !errorClassrooms && !classroomData && (
        <>
          <Grid
            container
            justifyContent={'center'}
            alignItems={'center'}
            mt={5}
            spacing={2}
            direction={'column'}
            minHeight={'45vh'}
          >
            <Grid item>
              <CircularProgress size={40} />
            </Grid>
            <Grid item>
              <Typography variant={'h3'} sx={{ color: theme.palette.primary.main }}>
                {t('academic.classrooms_distribution.searching')}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant={'body2'}>
                {t('academic.classrooms_distribution.searching.description')}
              </Typography>
            </Grid>
          </Grid>
          {/* skeleton not used  */}
          {/* skeleton header */}
          {/* <Grid container justifyContent="space-between" alignItems="center" spacing={2} mt={2}>
            <Grid item xs={12} sm={6}>
              <Skeleton variant={'rectangular'} width="70%" height={'50px'} />
            </Grid>
            <Grid container item xs={12} sm={6} justifyContent={'flex-end'}>
              <Skeleton variant={'rectangular'} width="20%" height={'50px'} />
            </Grid>
          </Grid> */}

          {/* skeleton cards */}
          {/* {renderSkeletonGrids(perPage)} */}
          {/* skeleton footer */}
          {/* <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
            <Grid item xs={12} sm={6}>
              <Skeleton variant={'rectangular'} width="70%" height={'50px'} />
            </Grid>
            <Grid container item xs={12} sm={6} justifyContent={'flex-end'}>
              <Skeleton variant={'rectangular'} width="30%" height={'50px'} sx={{ mr: '10px' }} />
            </Grid>
          </Grid> */}
        </>
      )}
    </form>
  );
};

const renderSkeletonGrids = (grids: number) => {
  return (
    <Box sx={{ mt: '1rem' }}>
      <Grid container>
        {[...new Array(grids)].map((_, index) => {
          return (
            <Grid container item xs={12} sm={6} md={4} lg={3} justifyContent={'center'} key={index}>
              <Skeleton variant={'rectangular'} height="100px" width="100%" sx={{ m: '10px' }} />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default AddClassroomEdLvlForm;
