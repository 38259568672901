import { Student } from '../../../../auth/interfaces/student-record.interfaces';
import { StudentCalifications } from '../../../../teachers/virtual-classroom/interfaces/studentCalification.interfaces';

//TODO: refactorizar (clon de calificaciones docente donde es ligeramnete distinta la data)
export const getStudentVirtualClassroomGrades = (
  fullName?: string,
  students?: Student[],
  grades?: StudentCalifications[]
) => {
  return students?.map((s) => {
    const grade = grades?.filter(
      (g) => g.course_student.student_record.student.id === s.id
      // && (g.calification_model_distribution || g.formula_id)
    );

    return {
      name: {
        id: s.id,
        fullname: `${fullName}`,
      },
      grade,
    };
  });
};
