export const financialPeriodsTypes = [
  {
    id: 1,
    label: 'financial.periods.const.period_types.yearly_label',
    keyword: 'A',
  },
  {
    id: 2,
    label: 'financial.periods.const.period_types.semestral_label',
    keyword: 'S',
  },
  {
    id: 3,
    label: 'financial.periods.const.period_types.trimestral_label',
    keyword: 'T',
  },
  {
    id: 4,
    label: 'financial.periods.const.period_types.monthly_label',
    keyword: 'M',
  },
];
