import React, { useState, ChangeEvent } from 'react';

// material-ui
import { makeStyles, useTheme, Theme } from '@material-ui/core/styles';
import {
  Button,
  ButtonBase,
  Card,
  Collapse,
  FormHelperText,
  Grid,
  InputAdornment,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Typography,
  CardMedia,
  useMediaQuery,
} from '@material-ui/core';

// third-party
import * as yup from 'yup';
import uniqueId from 'lodash/uniqueId';
import { Controller, FormProvider, useForm, useFormContext } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// project imports
import Reply from './Reply';
import Avatar from '../../../extended/Avatar';
import AnimateButton from '../../../extended/AnimateButton';
import {
  Comment as CommentProps,
  CommentData,
  PostProps,
  Profile,
} from '../../../../interfaces/post.interfaces';
import ImageList from '../../../extended/ImageList';

// assets
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import MoreVertTwoToneIcon from '@material-ui/icons/MoreVertTwoTone';
// import ThumbUpAltTwoToneIcon from '@material-ui/icons/ThumbUpAltTwoTone';
import ReplyTwoToneIcon from '@material-ui/icons/ReplyTwoTone';
import AttachmentRoundedIcon from '@material-ui/icons/AttachmentRounded';
import { FormInputProps } from '../../../../interfaces/material-ui.interfaces';
import { useAppDispatch } from '../../../../../../hooks/useRedux';
import { useTranslation } from 'react-i18next';
import {
  openDeleteModalComment,
  openShowModalImg,
} from '../../../../../teachers/virtual-classroom/slices/virtualClassroomTeacherSlice';

// style component
const useStyles = makeStyles((theme) => ({
  textSecondary: {
    color: theme.palette.secondary.main,
  },
  avatarRight: {
    ...theme.typography.commonAvatar,
    ...theme.typography.smallAvatar,
    background:
      theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
    color: theme.palette.mode === 'dark' ? theme.palette.dark.light : theme.palette.secondary.dark,
    zIndex: 1,
    transition: 'all .2s ease-in-out',
    '&[aria-controls="menu-list-grow"],&:hover': {
      background: theme.palette.secondary.main,
      color: theme.palette.secondary.light,
    },
  },
  profileComment: {
    background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[50],
    padding: '16px 16px 8px',
    marginTop: '10px',
  },
  commentLevel1: {
    marginLeft: '34px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
  btnSecondary: {
    background: theme.palette.secondary.main,
    '&:hover,&:focus,&:active': {
      background: theme.palette.secondary.dark,
    },
  },
}));

const validationSchema = yup.object().shape({
  name: yup.string().required('Reply Field is Required'),
});

//-----------------------|| COMMENT TEXTFIELD ||-----------------------//

const FormInput = ({ bug, label, name, required, defaultValue, ...others }: FormInputProps) => {
  const { control } = useFormContext();

  let isError = false;
  let errorMessage = '';
  if (bug && bug.hasOwnProperty(name)) {
    isError = true;
    errorMessage = bug[name].message;
  }

  return (
    <React.Fragment>
      <Controller
        as={TextField}
        name={name}
        control={control}
        defaultValue={defaultValue}
        label={label}
        fullWidth={true}
        InputLabelProps={{
          className: required ? 'required-label' : '',
          required: required || false,
        }}
        error={isError}
        {...others}
      />
      {errorMessage && (
        <Grid item xs={12}>
          <FormHelperText error>{errorMessage}</FormHelperText>
        </Grid>
      )}
    </React.Fragment>
  );
};

//-----------------------|| SOCIAL PROFILE - COMMENT ||-----------------------//

export interface CommentComponentProps {
  comment: CommentProps;
  commentEdit: PostProps['commentEdit'];
  postId: string;
  handleReplayLikes: PostProps['handleReplayLikes'];
  handleCommentLikes: PostProps['handleCommentLikes'];
  replyAdd: PostProps['replyAdd'];
  user: Profile;
  currentUser: Profile;
}

const Comment = ({
  comment,
  commentEdit,
  handleCommentLikes,
  handleReplayLikes,
  postId,
  replyAdd,
  user,
  currentUser,
}: CommentComponentProps) => {
  const classes = useStyles();

  const { t } = useTranslation();

  const theme = useTheme();
  const matchesXS = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const dispatch = useAppDispatch();
  const handleDelete = function (commentId: number) {
    dispatch(openDeleteModalComment(commentId));
  };

  const [anchorEl, setAnchorEl] = React.useState<
    Element | ((element: Element) => Element) | null | undefined
  >(null);
  const handleClick = (event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [file, setFile] = useState('');
  const [target, setTarget] = useState<File>(null!);
  const [isEdit, setEdit] = useState(false);

  const onChangePicture = function (event: ChangeEvent<HTMLInputElement>) {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e: any) => {
        setFile(e.target.result);
      };
      reader.readAsDataURL(event.target.files[0]);
      setTarget(event.target.files[0]);
    }
  };

  const [openReply, setOpenReply] = React.useState(false);

  const handleChangeReply = () => {
    setOpenReply((prev) => !prev);
    setFile('');
    setTarget(null!);
  };

  const handleShowImage = (nameImg: string) => {
    dispatch(openShowModalImg(nameImg));
  };

  let repliesResult: React.ReactElement[] | React.ReactElement = <></>;
  if (Object.keys(comment).length > 0 && comment.data?.replies && comment.data?.replies.length) {
    repliesResult = comment.data?.replies.map((reply, index) => {
      return (
        <Reply
          postId={postId}
          commentId={comment.id}
          commentEdit={commentEdit}
          key={index}
          onReply={handleChangeReply}
          reply={reply}
          handleReplayLikes={handleReplayLikes}
          user={user}
          currentUser={currentUser}
        />
      );
    });
  }

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: { name: '' },
  });

  const { handleSubmit, setValue, errors, reset } = methods;
  const onSubmit = async (reply: CommentData) => {
    handleChangeReply();
    const replyId = uniqueId('#REPLY_');
    const reply_ = {
      id: replyId,
      profile: user,
      data: {
        comment: reply.name,
        imagesFile: target,
        likes: {
          like: false,
          value: 0,
        },
        replies: [],
      },
    };

    replyAdd(postId, comment.id, reply_);
    reset({ name: '' });
  };

  const onSubmitEdit = async (reply: CommentData) => {
    const replyId = uniqueId('#REPLY_');
    const reply_ = {
      id: replyId,
      profile: user,
      data: {
        comment: reply.name,
        imagesFile: target,
        likes: {
          like: false,
          value: 0,
        },
        replies: [],
      },
    };

    commentEdit(postId, comment.id, reply_);
    reset({ name: '' });
    setEdit(false);
  };

  const obtainImageByDefault = (name: string | undefined) => {
    return `https://ui-avatars.com/api/?name=${name}&background=0D8ABC&color=fff`;
  };

  const handleEdit = (comment: string) => {
    setValue('name', comment);
  };

  return (
    <React.Fragment>
      {Object.keys(comment).length > 0 && (
        <Grid item xs={12}>
          <Card className={classes.profileComment}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Grid container wrap="nowrap" alignItems="center" spacing={1}>
                  <Grid item>
                    <Avatar alt="User 1" src={obtainImageByDefault(user.name)}></Avatar>
                    {/*
                    <Avatar
                      sx={{ width: 24, height: 24 }}
                      size="small"
                      alt="User 1"
                      src={
                        comment.profile &&
                        comment.profile.name &&
                        obtainImageByDefault(comment.profile.name)
                      }
                    />
                    */}
                  </Grid>
                  <Grid item xs zeroMinWidth>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item>
                        <Typography align="left" variant="h5" component="div">
                          {comment.profile.name}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography align="left" variant="caption">
                          <FiberManualRecordIcon
                            sx={{ width: '10px', height: '10px', opacity: 0.5, m: '0 5px' }}
                          />
                          {comment.profile.time}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  {comment.profile.id === currentUser.id && (
                    <Grid item>
                      <ButtonBase sx={{ borderRadius: '12px' }}>
                        <Avatar
                          variant="rounded"
                          className={classes.avatarRight}
                          aria-controls="menu-comment"
                          aria-haspopup="true"
                          onClick={handleClick}
                        >
                          <MoreVertTwoToneIcon fontSize="inherit" />
                        </Avatar>
                      </ButtonBase>
                      <Menu
                        id="menu-comment"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        variant="selectedMenu"
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                      >
                        <MenuItem
                          onClick={() => {
                            handleClose();
                            setEdit(true);
                            handleEdit(comment?.data?.comment!);
                          }}
                        >
                          {t('studentVirtualClassroom.forum.edit')}
                        </MenuItem>
                        <MenuItem onClick={() => handleDelete(Number(comment.id))}>
                          {t('studentVirtualClassroom.forum.delete')}
                        </MenuItem>
                      </Menu>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              {!isEdit ? (
                <Grid item xs={12} sx={{ '&.MuiGrid-root': { pt: 1.5 } }}>
                  <Typography align="left" variant="body2">
                    {comment.data?.comment}
                  </Typography>
                  {comment.data && comment.data.images && comment.data.images.length > 0 && (
                    <Grid
                      display="inline"
                      width="fitContent"
                      bgcolor="red"
                      sx={{ cursor: 'pointer' }}
                      onClick={() => {
                        handleShowImage(comment!.data!.images![0].img!);
                      }}
                    >
                      <ImageList itemData={comment.data.images} />
                    </Grid>
                  )}
                </Grid>
              ) : (
                <Grid item xs={12} sx={{ '&.MuiGrid-root': { pt: 1.5 } }}>
                  <Collapse in={!!file} sx={{ width: '100%' }}>
                    <Grid item margin={2} marginLeft={6}>
                      <CardMedia
                        style={{ cursor: 'pointer', height: '10vh', width: '10vh' }}
                        component="img"
                        onClick={() => {
                          setFile('');
                          setTarget(null!);
                        }}
                        src={file ? file : ''}
                        title="image"
                      />
                    </Grid>
                  </Collapse>
                  <div className={classes.commentLevel1}>
                    <form onSubmit={handleSubmit(onSubmitEdit)}>
                      <Grid container spacing={2} alignItems="flex-start">
                        <Grid item xs zeroMinWidth sx={{ mt: 1 }}>
                          <FormProvider {...methods}>
                            <FormInput
                              fullWidth
                              name="name"
                              defaultValue={comment.data?.comment}
                              label={t('studentVirtualClassroom.forum.writeAnswer')}
                              size={matchesXS ? 'small' : 'medium'}
                              bug={errors}
                              InputProps={{
                                label: t('studentVirtualClassroom.forum.writeAnswer'),
                                startAdornment: (
                                  <label htmlFor={'contained-button-file' + comment.id}>
                                    <InputAdornment position="start" onChange={onChangePicture}>
                                      <input
                                        type="file"
                                        style={{ display: 'none' }}
                                        id={'contained-button-file' + comment.id}
                                      />

                                      <AttachmentRoundedIcon
                                        fontSize="small"
                                        sx={{
                                          color: `${
                                            !!file ? theme.palette.primary.main : 'inherit'
                                          }`,
                                        }}
                                      />
                                    </InputAdornment>
                                  </label>
                                ),
                              }}
                            />
                          </FormProvider>
                        </Grid>
                        <Grid item>
                          <AnimateButton>
                            <Button
                              type="submit"
                              variant="contained"
                              // className={classes.btnSecondary}
                              size={matchesXS ? 'small' : 'large'}
                              color="primary"
                              sx={{
                                mt: 1.5,
                                background: theme.palette.error.main,
                                borderColor: '#EDE7F6',
                              }}
                              onClick={() => setEdit(false)}
                            >
                              {t('studentVirtualClassroom.forum.delete')}
                            </Button>
                          </AnimateButton>
                        </Grid>
                        <Grid item>
                          <AnimateButton>
                            <Button
                              type="submit"
                              variant="contained"
                              // className={classes.btnSecondary}
                              size={matchesXS ? 'small' : 'large'}
                              sx={{ mt: 1.5 }}
                            >
                              {t('studentVirtualClassroom.forum.edit')}
                            </Button>
                          </AnimateButton>
                        </Grid>
                      </Grid>
                    </form>
                  </div>
                </Grid>
              )}
              <Grid item xs={12}>
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{ color: theme.palette.mode === 'dark' ? 'grey.700' : 'grey.800' }}
                >
                  {/* <Button
                    onClick={() => handleCommentLikes(postId, comment.id)}
                    variant="text"
                    color="inherit"
                    size="small"
                    startIcon={
                      <ThumbUpAltTwoToneIcon
                        className={
                          comment.data?.likes && comment.data?.likes.like
                            ? classes.textSecondary
                            : undefined
                        }
                      />
                    }
                  >
                    {comment.data?.likes && comment.data?.likes.value
                      ? comment.data?.likes.value
                      : 0}{' '}
                    likes
                  </Button> */}
                  <Button
                    variant="text"
                    onClick={handleChangeReply}
                    color="inherit"
                    size="small"
                    startIcon={<ReplyTwoToneIcon color="primary" />}
                  >
                    {comment.data?.replies ? comment.data?.replies.length : 0}{' '}
                    {t('studentVirtualClassroom.forum.comments')}
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      )}
      <Collapse in={openReply} sx={{ width: '100%' }}>
        {repliesResult}
      </Collapse>
      {/* comment - add new replay */}
      <Collapse in={openReply} sx={{ width: '100%' }}>
        <Grid item xs={12} sx={{ pl: { xs: 1, sm: 3 }, pt: 3 }}>
          <Collapse in={!!file} sx={{ width: '100%' }}>
            <Grid item margin={2} marginLeft={6}>
              <CardMedia
                style={{ cursor: 'pointer', height: '10vh', width: '10vh' }}
                component="img"
                onClick={() => {
                  setFile('');
                  setTarget(null!);
                }}
                src={file ? file : ''}
                title="image"
              />
            </Grid>
          </Collapse>
          {true && (
            <div className={classes.commentLevel1}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2} alignItems="flex-start">
                  <Grid item sx={{ display: { xs: 'none', sm: 'block' } }}>
                    <Avatar alt="User 1" src={obtainImageByDefault(currentUser.name)}></Avatar>
                    {/*
                  <Avatar
                    sx={{ mt: 1.5 }}
                    alt="User 1"
                    src={obtainImageByDefault(
                      `${userInfo.name} ${userInfo?.fullname!.split(' ')[0]}`
                    )}
                  />
                  */}
                  </Grid>
                  <Grid item xs zeroMinWidth sx={{ mt: 1 }}>
                    <FormProvider {...methods}>
                      <FormInput
                        fullWidth
                        name="name"
                        label={t('studentVirtualClassroom.forum.writeAnswer')}
                        size={matchesXS ? 'small' : 'medium'}
                        bug={errors}
                        InputProps={{
                          label: t('studentVirtualClassroom.forum.writeAnswer'),
                          startAdornment: (
                            <label htmlFor={'contained-button-file' + comment.id}>
                              <InputAdornment position="start" onChange={onChangePicture}>
                                <input
                                  type="file"
                                  style={{ display: 'none' }}
                                  id={'contained-button-file' + comment.id}
                                />

                                <AttachmentRoundedIcon
                                  fontSize="small"
                                  sx={{
                                    color: `${!!file ? theme.palette.primary.main : 'inherit'}`,
                                  }}
                                />
                              </InputAdornment>
                            </label>
                          ),
                        }}
                      />
                    </FormProvider>
                  </Grid>
                  <Grid item>
                    <AnimateButton>
                      <Button
                        type="submit"
                        variant="contained"
                        // className={classes.btnSecondary}
                        size={matchesXS ? 'small' : 'large'}
                        color="primary"
                        sx={{ mt: 1.5 }}
                      >
                        {t('studentVirtualClassroom.forum.answer')}
                      </Button>
                    </AnimateButton>
                  </Grid>
                </Grid>
              </form>
            </div>
          )}
        </Grid>
      </Collapse>
    </React.Fragment>
  );
};

export default Comment;
