import { Grid } from '@material-ui/core';
import { useAppSelector } from '../../../../../hooks/useRedux';
import { useGetRecentsTasksQuery } from '../../slices/virtualClassroomTeacherApiSlice';
import ItemTask from './ItemTask';
import PerfectScrollbar from 'react-perfect-scrollbar';
import LoadingData from '../../../../shared/components/cards/Skeleton/LoadingData';
import img from '../../../../../assets/images/no-tests.png';
import NoData from '../../../../shared/components/NoData';
import { useTranslation } from 'react-i18next';
function TaskRecentIndividual() {
  const { t } = useTranslation();

  const {
    course: { courseId },
  } = useAppSelector((state) => state.virtualClassroomTeacherShared);
  const { currentProfile } = useAppSelector((state) => state.access);

  const { data, isLoading } = useGetRecentsTasksQuery({
    profileId: currentProfile?.profileId!,
    courseId: courseId!,
  });

  if (isLoading) return <LoadingData />;

  return (
    <PerfectScrollbar>
      <Grid style={{ overflowY: 'scroll' }} height={'200px'} sm={12} container>
        {data && data?.length! > 0 ? (
          data?.map((item) => {
            return (
              <ItemTask
                type={item.type_activity.cat_name}
                activityId={item.id}
                descripcion={item.act_description}
                additionals={{
                  instance: item.instance,
                  modulename: item.modulename,
                  date: (item.course_activity_one?.cact_start_date as unknown as string) || '',
                }}
              />
            );
          })
        ) : (
          <NoData
            imgWitdh={150}
            image={img}
            title={t('students.dashboard.activitiesByStart.no-list')}
            subtitle={t('students.dashboard.activitiesByStart.no-list.description')}
          />
        )}
      </Grid>
    </PerfectScrollbar>
  );
}

export default TaskRecentIndividual;
