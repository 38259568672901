import Chart from "react-apexcharts";

const Leads = () => {

    const data = {
          
        series: [{
            name: "Correo",
            data: [0, 0, 0, 60, 55, 62, 69, 91, 148]
        },
        {
            name: "Facebook",
            data: [0, 0, 0, 55, 30, 45, 69, 91, 148]
        },
        {
            name: "Instagram",
            data: [0, 0, 0, 45, 66, 50, 69, 91, 148]
        }],
        options: {
          chart: {
            height: 350,
            type: 'line',
            zoom: {
              enabled: false
            }
          },
          dataLabels: {
            enabled: true
          },
          stroke: {
            curve: 'straight'
          },
          title: {
            text: '',
            align: 'left'
          },
          grid: {
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          xaxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
          }
        },
      
      }

    return (
        <Chart
            options={data}
            series={data.series}
            type="line"
            width="100%"
            height={400}
        />
    )
}

export default Leads;