import { Switch } from 'react-router-dom';

// import { ErrorBoundary } from 'react-error-boundary';
// import ErrorFallbackComponentScreen from '../../shared/components/errors-boundary/ErrorFallbackComponentScreen';

import CoursesRouter from '../courses/router';
import AcademicHourhand from '../academic-hourhands/router';
import BirthdayRouter from '../birthday/router';
import StudentAsignedRouter from '../academic-couseling/students-asigned/router';
import VirtualClassroomRouter from '../virtual-classroom/router';
import AssignGradeRouter from '../assign-grades/router/AssignGradeRouter';
import DashboardRouter from '../dashboard/router';
import GuardRoute from '../../../router/guards/GuardRoute';
import EntradasSalidasRouter from '../../hr/enter-leave/router';
import RolesRouter from '../../hr/roles/router';
import RrHhApplicationsRouter from '../../hr/applications/router';
import MealTicketRouter from '../../hr/meal-ticket/router';
import TeacherProjectRouter from '../projects/router';
import RegisterSilabus from '../../shared/screens/RegistwerSilabus';

const TeachersRouter = () => {
  return (
    // <ErrorBoundary FallbackComponent={ErrorFallbackComponentScreen}>
    <Switch>
      {/* <Route path="/administrar-colaboradores" /> */}
      <GuardRoute path="/administrar-cursos-profesor" component={CoursesRouter} />
      <GuardRoute path="/administrar-horarios-profesor" component={AcademicHourhand} />
      <GuardRoute path="/cumpleanios" component={BirthdayRouter} />
      <GuardRoute path="/administrar-estudiantes-asignados" component={StudentAsignedRouter} />
      <GuardRoute path="/administrar-aula-virtual" component={VirtualClassroomRouter} />
      <GuardRoute path="/asignar-notas" component={AssignGradeRouter} />
      <GuardRoute path="/inicio-docente" component={DashboardRouter} />
      <GuardRoute path="/entradas-salidas" component={EntradasSalidasRouter} />
      <GuardRoute path="/roles-pagos" component={RolesRouter} />
      <GuardRoute path="/ticket-comida" component={MealTicketRouter} />
      <GuardRoute path="/solicitud" component={RrHhApplicationsRouter} />
      <GuardRoute path="/solicitudes" component={RolesRouter} />
      <GuardRoute path="/proyectos-docente" component={TeacherProjectRouter} />
      <GuardRoute path="/silabus/:courseId/:id/:period/:caseKey" component={RegisterSilabus} />
    </Switch>
    // </ErrorBoundary>
  );
};

export default TeachersRouter;
