export const subPermissionKeys = {
  groupKey: 'classrooms',

  read: 'classrooms-listar-aulas',
  create: 'classrooms-crear-aula',
  edit: 'classrooms-actualizar-aula',
  delete: 'classrooms-borrar-aula',

  getCampus: 'campus-listar-sedes',
  getClassroomType: 'classroomType-listar-tipos-de-aulas',
  getCapacity: 'classrooms-list-capacity-classroom', // este permiso esta comentado en el back, asumo que no se mostrara hasta que lo descomenten

  //
}; // satisfies PermissionsKeys;
