import { useState } from 'react'
import { useFormik } from 'formik';
import { useHistory, useParams, Redirect } from 'react-router-dom'
import {
    Grid,
    InputLabel,
    Button,
    FormHelperText,
    TableBody,
    TextField,
    Checkbox,
    Autocomplete,
    TableRow,
    TableCell,
    TableContainer,
    Table,
    Tooltip,
    useTheme,
    IconButton,
    CircularProgress
} from '@material-ui/core';
import { useGetAvailablesCollaboratorsByEdLvlQuery } from '../../../hr/collaborators/slices/collaboratorsApiSlice'
import { useAddCollectiveEducationLevelMutation } from '../slices/educationLevelsApiSlice'
import { useAppSelector } from '../../../../hooks/useRedux';
import { CollectiveInfoSchema } from '../constants/validationSchemas';
import { Collaborator } from '../../../hr/collaborators/interfaces/collaborators.interfaces';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { checkCollaboratorPincipal } from '../helpers';
import { CollectiveForm } from '../interfaces/educationLevelsForms.interfaces';
import { IconTrash } from '@tabler/icons';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';

const AddCollective = function () {

    const theme = useTheme();

    const history = useHistory();

    const { educationLevelId } = useParams<{ educationLevelId: string }>();

    const { currentProfile } = useAppSelector((state) => state.access);

    const { educationLevelList } = useAppSelector((state) => state.educationLevels)

    const [collPrincipal, setCollPrincipal] = useState<number>(0);

    const educLevelAddToColl = educationLevelList.find((value) => value.id === Number(educationLevelId));

    const [showAlerts, setShowAlerts] = useState<boolean>(false);

    const [removed, setRemoved] = useState<boolean>(false);

    const { isLoading: isLoadingCollaborators, data: dataCollaborator, } = useGetAvailablesCollaboratorsByEdLvlQuery(
        {
            edLvlId: Number(educationLevelId!),
            profileId: currentProfile?.profileId!,
        },
        { refetchOnMountOrArgChange: 30 }
    );

    const [addCollectiveCollaborator, { isLoading: isLoadingAdd, isError: isErrorAdd, error: errorAdd, isSuccess: isSuccessAdd }] = useAddCollectiveEducationLevelMutation();

    const handleDelete = (collaboratorId: number) => {
        setRemoved(true)

        const currentValues = values.collaborators.filter((value) => value.id !== collaboratorId)

        setFieldValue('collaborators', currentValues);

        if (collaboratorId === collPrincipal) {
            setCollPrincipal(0)
        }
    }

    const { values, handleSubmit, getFieldProps, errors, touched, setFieldValue } = useFormik<CollectiveForm>({
        initialValues: {
            title: '',
            collaborators: []
        },
        validate: function (values) {
            return checkCollaboratorPincipal(collPrincipal)
        },
        validationSchema: CollectiveInfoSchema,
        onSubmit: async (values) => {
            const collaborators = values.collaborators.map((e) => e.id);

            await addCollectiveCollaborator({
                jointAcademic: {
                    collaborator_id: collPrincipal,
                    collaborators,
                    education_level_id: Number(educationLevelId),
                    name: values.title,
                    status_id: 1
                }
            })

            setShowAlerts(true);
            window.scrollTo(0, 0);
        },
    });

    const uniqueSelected = (collaborator: number): boolean => {
        return collPrincipal === collaborator
    }

    const RenderRow = (collaborator: Collaborator) => (
        <TableRow hover selected={collaborator.id === collPrincipal} className="table-row" key={collaborator.id.toString()}>
            <TableCell
                width="70%"
                sx={{ cursor: 'pointer' }}
            >
                {collaborator.user.person.pers_firstname} {collaborator.user.person.pers_first_lastname} {collaborator.user.person.pers_second_lastname}
                <FormHelperText>{collaborator.user.email}</FormHelperText>
            </TableCell>
            <TableCell
                width="30%"
                align="center"
            >
                <Tooltip title="Remover del listado">
                    <IconButton aria-label="Eliminar" onClick={() => handleDelete(collaborator.id)}>
                        <IconTrash size={20} color={collaborator.id === collPrincipal ? theme.palette.success.main : theme.palette.error.main} />
                    </IconButton>
                </Tooltip>

                <Tooltip title="Asignar colaborador principal">
                    <Checkbox checked={uniqueSelected(collaborator.id)} onChange={() => setCollPrincipal(collaborator.id)} value={collaborator.id} />
                </Tooltip>
            </TableCell>
        </TableRow >
    );

    const RenderRows = () => {
        return <>{values.collaborators?.map(RenderRow)}</>;
    };

    if (!educLevelAddToColl) {
        return <Redirect to="/administrar-niveles-educativos" />;
    }

    return <form onSubmit={handleSubmit}>
        <Grid container spacing={4} alignItems="flex-start">
            <Grid item xs={12} md={6} sm={12} container spacing={2}>

                {/* Show messages */}
                <Grid item xs={12}>
                    {isErrorAdd && showAlerts && (
                        <ErrorAlert message={errorAdd as string} handleDismiss={() => setShowAlerts(false)} />
                    )}

                    {isSuccessAdd && showAlerts && (
                        <SuccessAlert
                            message="El grupo colectivo se ha creado correctamente."
                            handleDismiss={() => setShowAlerts(false)}
                        />
                    )}
                </Grid>

                {/* Collective name */}
                <Grid item xs={12}>
                    <InputLabel>Nombre del colectivo</InputLabel>
                    <TextField
                        style={{ marginTop: 5 }}
                        fullWidth
                        error={Boolean(errors.title && touched.title)}
                        placeholder="Ingrese el nombre del colectivo"
                        {...getFieldProps('title')}
                    />
                    {errors.title && touched.title && <FormHelperText error>{errors.title}</FormHelperText>}
                </Grid>

                {/* Collective collaborators */}
                <Grid item xs={12}>
                    <InputLabel>Colaboradores</InputLabel>
                    <Autocomplete
                        multiple
                        options={dataCollaborator! || []}
                        loading={isLoadingCollaborators}
                        onReset={() => setCollPrincipal(0)}
                        loadingText="Cargando colaboradores disponibles..."
                        getOptionLabel={(option) => `${option.user.person.pers_firstname} ${option.user.person.pers_first_lastname}`}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                error={Boolean(errors.collaborators && touched.collaborators)}
                                placeholder="Seleccione los colaboradores disponibles"
                            />
                        )}
                        value={values.collaborators && values.collaborators}
                        onChange={(_, values) =>
                            setFieldValue('collaborators', [...values.map((col) => col)] || '')
                        }
                        fullWidth
                        style={{ marginTop: 5 }}
                    />
                    {errors.collaborators && touched.collaborators && (
                        <FormHelperText error>{errors.collaborators}</FormHelperText>
                    )}
                </Grid>

                {removed &&
                    <Grid item xs={12}><SuccessAlert
                        message="Colaborador removido de la lista."
                        handleDismiss={() => setRemoved(false)}
                    />
                    </Grid>}

                {/* Presentational collaborators */}
                {values.collaborators && values.collaborators.length > 0 && (
                    <Grid item xs={12} md={12} sx={{ maxHeight: 600, overflow: 'hidden', marginTop: 3 }}>
                        <PerfectScrollbar component="div">
                            <TableContainer>
                                <InputLabel>Asignar un representante del colectivo.</InputLabel>
                                <Table style={{ marginTop: 10 }}>
                                    <TableBody>
                                        {values.collaborators.length === 0 ? null : RenderRows()}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </PerfectScrollbar>
                    </Grid>
                )}

                {/* Action buttons */}
                <Grid item
                    container
                    alignItems="center"
                    justifyContent="flex-end"
                    spacing={2}
                    sx={{ mt: 3 }}>
                    <Grid item>
                        <Button
                            variant="outlined"
                            size="large"
                            color="primary"
                            onClick={() => history.goBack()}
                        >
                            Regresar
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            type="submit"
                            variant="contained"
                            size="large"
                            color="primary"
                            disabled={isLoadingAdd}
                            endIcon={isLoadingAdd && <CircularProgress size={20} />}
                        >
                            Agregar
                        </Button>
                    </Grid>
                </Grid>
            </Grid>

        </Grid>
    </form>
}

export default AddCollective