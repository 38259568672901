import { useState } from 'react';
// import { useHistory } from 'react-router-dom';
import {
  Grid,
  Typography,
  useTheme,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
} from '@material-ui/core';
import { IconCheck } from '@tabler/icons';

import ChangePasswordForm from '../components/ChangePasswordForm';
import MainCardForm from '../../../shared/components/cards/MainCardForm';
import { useAppSelector } from '../../../../hooks/useRedux';

const ChangePasswordScreen = () => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));
  const [passwordValue, setPasswordValue] = useState('');

  const {
    userInfo: { remember_token },
  } = useAppSelector((state) => state.auth);

  const handlePassword = (data: string) => {
    setPasswordValue(data);
  };

  return (
    <MainCardForm>
      <Grid item xs={12}>
        <Grid item container alignItems="center" gap={1}>
          <Typography variant="h2">Cambiar contraseña</Typography>
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={12}
        md={remember_token ? 8 : 12}
        lg={remember_token ? 6 : 12}
        sx={{ mt: 2 }}
        justifyContent="center"
        direction={'row-reverse'}
      >
        <Grid item xs={12} sm={6} md={8} lg={6}>
          <ChangePasswordForm setPass={handlePassword} />
        </Grid>
        <Grid item xs={12} sm={6} md={8} lg={6}>
          <Grid container spacing={2} justifyContent="center" marginTop={2}>
            <Grid item xs={10}>
              <Grid
                container
                direction={matchDownSM ? 'column-reverse' : 'column-reverse'}
                alignItems={matchDownSM ? 'flex-start' : 'flex-start'}
                justifyContent={matchDownSM ? 'flex-start' : 'flex-start'}
                gap={1}
              >
                <Typography color="textPrimary" gutterBottom variant="h5">
                  Tu contraseña debe tener
                </Typography>
              </Grid>
            </Grid>
            <Grid container justifyContent="center">
              <Grid item xs={9}>
                <List disablePadding dense>
                  <ListItem>
                    <ListItemIcon>
                      <IconCheck
                        size={20}
                        stroke={2.5}
                        color={
                          /^(?=.{6,})/.test(passwordValue)
                            ? theme.palette.success.dark
                            : theme.palette.text.primary
                        }
                      />
                    </ListItemIcon>
                    <ListItemText id="switch-list-label-wifi" disableTypography>
                      Mínimo 6 caracteres
                    </ListItemText>
                  </ListItem>

                  <ListItem>
                    <ListItemIcon>
                      <IconCheck
                        size={20}
                        stroke={2.5}
                        color={
                          /^[\s\S]{6,15}$/.test(passwordValue)
                            ? theme.palette.success.dark
                            : theme.palette.text.primary
                        }
                      />
                    </ListItemIcon>
                    <ListItemText id="switch-list-label-wifi" disableTypography>
                      Máximo 15 caracteres
                    </ListItemText>
                  </ListItem>

                  <ListItem>
                    <ListItemIcon>
                      <IconCheck
                        size={20}
                        stroke={2.5}
                        color={
                          /^(?=.*[A-Z])(?=.*[a-z])/.test(passwordValue)
                            ? theme.palette.success.dark
                            : theme.palette.text.primary
                        }
                      />
                    </ListItemIcon>
                    <ListItemText id="switch-list-label-wifi" disableTypography>
                      Minúsculas y mayúsculas
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <IconCheck
                        size={20}
                        stroke={2.5}
                        color={
                          /^(?=.*[0-9])/.test(passwordValue)
                            ? theme.palette.success.dark
                            : theme.palette.text.primary
                        }
                      />
                    </ListItemIcon>
                    <ListItemText id="switch-list-label-wifi" disableTypography>
                      Un número
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <IconCheck
                        size={20}
                        stroke={2.5}
                        color={
                          /^(?=.*[#?!@$%^&*-.])/.test(passwordValue)
                            ? theme.palette.success.dark
                            : theme.palette.text.primary
                        }
                      />
                    </ListItemIcon>
                    <ListItemText id="switch-list-label-wifi" disableTypography>
                      Un carácter especial
                    </ListItemText>
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </MainCardForm>
  );
};

export default ChangePasswordScreen;
