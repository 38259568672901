import { mainApi } from '../../../api/mainApi';
import { middlewareApi } from '../../middleware/middlewareApi';

export const birthdayApi = middlewareApi.injectEndpoints({
  //tagTypes: ['','',''],
  endpoints: (builder) => ({
    /**
     * @GET all collaborator birthdays
     */
    addElectronicSignature: builder.mutation<
      any,
      { file: File | null; password: string; userProfileId: number; userId: number | string }
    >({
      queryFn: async ({ file, password, userProfileId, userId }) => {
        try {
          const form = new FormData();
          form.append('files[]', file || '');
          form.append('password', password!);
          form.append('type_document', '19');

          const { data } = await mainApi.post<any>(
            `/users/${userId}/register/cert?user_profile_id=${userProfileId}`,
            form,
            {
              headers: { 'Content-Type': 'multipart/form-data' },
            }
          );
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),
  }),
});
export const { useAddElectronicSignatureMutation } = birthdayApi;
