import {
  Autocomplete,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  Typography,
  TextField,
  IconButton,
  FormControl,
  FormGroup,
  FormControlLabel,
  Switch,
  Stack,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import { useGetAllAuthorsQuery } from '../../../authors/slices/authorsApiSlice';
import { useGetAllTagsByTypeQuery } from '../../../tags/slices/tagsApiSlice';
import * as yup from 'yup';
import FormAddCity from '../mini-forms/FormAddCity';
import AddAuthor from '../../../authors/components/AddAuthor';
import FormAddEditorial from '../mini-forms/FormAddEditorial';
import FormAddIdiom from '../mini-forms/FormAddIdiom';
//import { typesDocuments } from '../../constants/typesDocuments';
import { IconPlus } from '@tabler/icons';
import { DatePicker, LocalizationProvider } from '@material-ui/lab';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import esLocale from 'date-fns/locale/es';
import { FileUpload, PhotoCamera } from '@material-ui/icons';
import { statusAvailables } from '../../../../shared/constants/resourceStatus';
import { setBodyForm } from '../../slices/booksSlice';
import { isBefore } from 'date-fns';
interface props {
  isActive: boolean;
  handleNext: () => void;
}

const Step1 = ({ handleNext, isActive }: props) => {
  const dispatch = useAppDispatch();
  const { bookToEdit } = useAppSelector((state) => state.books);
  const [showFormAuthor, setShowFormAuthor] = useState(false);
  const [showFormCity, setShowFormCity] = useState(false);
  const [showFormEditorial, setShowFormEditorial] = useState(false);
  const [showFormIdiom, setShowFormIdiom] = useState(false);

  const {
    data: authors,
    isLoading: isLoadingAuthors,
    isFetching: isFetchingAuthors,
    refetch: refetchAuthors,
  } = useGetAllAuthorsQuery();

  const {
    data: cities,
    isLoading: isLoadingCities,
    isFetching: isFetchingCities,
    refetch: refetchCities,
  } = useGetAllTagsByTypeQuery({
    type_tag: 'Ciudad',
  });

  const {
    data: editorials,
    isLoading: isLoadingEditorials,
    isFetching: isFetchingEditorials,
    refetch: refetchEditorials,
  } = useGetAllTagsByTypeQuery({
    type_tag: 'Editorial',
  });

  const {
    data: idioms,
    isLoading: isLoadingIdioms,
    isFetching: isFetchingIdioms,
    refetch: refetchIdioms,
  } = useGetAllTagsByTypeQuery({
    type_tag: 'Idioma',
  });

  const origins = [
    { value: 'Fisico', label: 'Físico' },
    { value: 'Digital', label: 'Digital' },
    // { value: 'Fisico y Digital', label: 'Físico y Digital' },
  ];

  const schema = yup.object().shape({
    isEdit: yup.boolean(),
    title: yup.string().required('Título es requerido'),
    subtitle: yup.string(),
    authors: yup.array().required().min(1, 'Autor es requerido'),
    city: yup.string().required('Ciudad es requerida'),
    editorial: yup.string().required('Editorial es requerida'),
    idiom: yup.string().required('Idioma es requerida'),
    type_document: yup.string(),
    isbn: yup.string().required('ISBN es requerido'),
    version: yup.string().required('Edición es requerida'),
    origin: yup.string().required('Origen es requerido'),
    // codebar: yup.string().required('Código de Barras es requerido'),
    publish_date: yup.string().required('Fecha de publicación es requerida'),
    status: yup.string().required('Estado es requerido'),

    cover: yup.array(),
    isUrlFile: yup.boolean(),
    file: yup.array().when(['origin', 'isEdit', 'isUrlFile'], {
      is: (val: string, isEdit: boolean, isUrlFile: boolean) =>
        isEdit === false && isUrlFile === false && val === 'Digital',
      //|| (isEdit === false && isUrlFile === false && val === 'Fisico y Digital'),
      then: (schema) => schema.min(1, 'Archivo es requerido').required(),
      otherwise: (schema) => schema.notRequired(),
    }),
    file_url: yup.string().when('isUrlFile', {
      is: (val: boolean) => val === true,
      then: (schema) => schema.required('Url es requerido'),
      otherwise: (schema) => schema.notRequired(),
    }),

    num_pag: yup
      .number()
      .moreThan(0, 'Debe ser mayor a 0')
      .required('Número de paginas es requerido'),
    serie: yup.string().required('Serie es requerido'),
    content_notes: yup.string().required('Notas de contenido es requerido'),
    description: yup.string(),
  });

  const { values, errors, touched, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      isEdit: false,
      title: bookToEdit?.title || '',
      subtitle: bookToEdit?.subtitle || '',
      authors:
        bookToEdit?.tags?.filter((tag) => tag.type_tag === 'Autor')?.map((tag) => tag.id) ||
        new Array<number>(),
      city: bookToEdit?.tags?.find((tag) => tag.type_tag === 'Ciudad')?.id.toString() || '',
      editorial: bookToEdit?.tags?.find((tag) => tag.type_tag === 'Editorial')?.id.toString() || '',
      idiom: bookToEdit?.tags?.find((tag) => tag.type_tag === 'Idioma')?.id.toString() || '',
      type_document: '',
      isbn: bookToEdit?.isbn || '',
      version: bookToEdit?.version || '',
      origin: bookToEdit?.origin || '',
      // codebar: bookToEdit?.institution_barcode || '',
      publish_date: bookToEdit?.publish_date || '',
      status: bookToEdit?.status_id || '1',
      cover: new Array<File>(),
      file: new Array<File>(),
      file_url: bookToEdit?.book_url || '',
      symbol: bookToEdit?.symbol || '',

      isUrlFile: false,
      num_pag: Number(bookToEdit?.page_count) || 0,
      serie: bookToEdit?.serie || '',
      content_notes: bookToEdit?.content_notes || '',
      description: bookToEdit?.description || '',
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      await dispatch(setBodyForm(values));
      await handleNext();
    },
  });

  const handleFormSubmit = () => {
    handleSubmit();
  };

  const authorsOptions =
    authors && authors.length > 0
      ? authors.map((at) => ({
          title: at.name,
          value: at.id,
        }))
      : [];

  const citiesOptions =
    cities && cities.length > 0
      ? cities.map((ct) => ({
          label: ct.name,
          value: ct.id,
        }))
      : [];

  const editorialsOptions =
    editorials && editorials.length > 0
      ? editorials.map((ct) => ({
          label: ct.name,
          value: ct.id,
        }))
      : [];

  const idiomsOptions =
    idioms && idioms.length > 0
      ? idioms.map((ct) => ({
          label: ct.name,
          value: ct.id,
        }))
      : [];

  if (isActive === false) {
    return <div />;
  }

  return (
    <>
      <Grid item xs={12} md={6}>
        <InputLabel>Título</InputLabel>
        <TextField
          fullWidth
          placeholder="Ingrese Título"
          value={values.title}
          error={Boolean(errors.title && touched.title)}
          helperText={Boolean(errors.title && touched.title) ? errors.title : ''}
          onChange={({ target }) => setFieldValue('title', String(target.value).toUpperCase())}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <InputLabel>Subtítulo</InputLabel>
        <TextField
          fullWidth
          placeholder="Ingrese Subtítulo"
          value={values.subtitle}
          error={Boolean(errors.subtitle && touched.subtitle)}
          helperText={Boolean(errors.subtitle && touched.subtitle) ? errors.subtitle : ''}
          onChange={({ target }) => setFieldValue('subtitle', target.value.toUpperCase())}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <InputLabel>Descripción</InputLabel>
        <TextField
          fullWidth
          multiline
          rows={2}
          placeholder="Ingrese Descripción"
          value={values.description}
          error={Boolean(errors.description && touched.description)}
          helperText={Boolean(errors.description && touched.description) ? errors.description : ''}
          onChange={({ target }) => setFieldValue('description', target.value.toUpperCase())}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <InputLabel>Notas de contenido</InputLabel>
        <TextField
          multiline
          rows={2}
          fullWidth
          placeholder="Ingrese Notas de contenido"
          value={values.content_notes}
          error={Boolean(errors.content_notes && touched.content_notes)}
          helperText={
            Boolean(errors.content_notes && touched.content_notes) ? errors.content_notes : ''
          }
          onChange={({ target }) => setFieldValue('content_notes', target.value.toUpperCase())}
        />
      </Grid>

      <Grid item container xs={12} md={6}>
        <Grid item xs={6} md={6} lg={6}>
          <InputLabel>Autor(es)</InputLabel>
        </Grid>
        <Grid item xs={6} md={6} lg={6} sx={{ textAlign: 'right' }}>
          <Button
            size="small"
            endIcon={<IconPlus size={14} />}
            onClick={() => {
              setShowFormAuthor(true);
            }}
          >
            Agregar
          </Button>
        </Grid>
        {isLoadingAuthors || isFetchingAuthors ? (
          <TextField placeholder="Cargando..." fullWidth />
        ) : (
          <Autocomplete
            multiple
            options={authorsOptions}
            getOptionLabel={(option) => option.title}
            getOptionSelected={(option) => values.authors.includes(option.value)}
            defaultValue={[]}
            value={authorsOptions.filter((at) => values.authors.includes(at.value))}
            fullWidth
            clearIcon={null}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Seleccione Autor..."
                error={Boolean(errors.authors && touched.authors)}
                helperText={Boolean(errors.authors && touched.authors) ? errors.authors : ''}
              />
            )}
            onChange={(_, values) => {
              setFieldValue(
                'authors',
                values.map((vl) => vl.value)
              );
            }}
          />
        )}
      </Grid>

      <Grid item container xs={12} md={3}>
        <Grid item xs={6} md={6} lg={6}>
          <InputLabel>Ciudad</InputLabel>
        </Grid>
        <Grid item xs={6} md={6} lg={6} sx={{ textAlign: 'right' }}>
          <Button
            size="small"
            endIcon={<IconPlus size={14} />}
            onClick={() => {
              setShowFormCity(true);
            }}
          >
            Agregar
          </Button>
        </Grid>
        {isLoadingCities || isFetchingCities ? (
          <TextField placeholder="Cargando..." fullWidth />
        ) : (
          <Autocomplete
            options={citiesOptions}
            getOptionLabel={(option) => option.label}
            getOptionSelected={(option) => Number(values.city) === option.value}
            value={citiesOptions.find((ct) => ct.value === Number(values.city))}
            fullWidth
            clearIcon={null}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Seleccione Ciudad..."
                error={Boolean(errors.city && touched.city)}
                helperText={Boolean(errors.city && touched.city) ? errors.city : ''}
              />
            )}
            onChange={(_, value) => {
              if (value) {
                setFieldValue('city', value.value);
              }
            }}
          />
        )}
      </Grid>

      <Grid item container xs={12} md={3}>
        <Grid item xs={6} md={6} lg={6}>
          <InputLabel>Editorial</InputLabel>
        </Grid>
        <Grid item xs={6} md={6} lg={6} sx={{ textAlign: 'right' }}>
          <Button
            size="small"
            endIcon={<IconPlus size={14} />}
            onClick={() => {
              setShowFormEditorial(true);
            }}
          >
            Agregar
          </Button>
        </Grid>
        {isLoadingEditorials || isFetchingEditorials ? (
          <TextField placeholder="Cargando..." fullWidth />
        ) : (
          <Autocomplete
            options={editorialsOptions}
            getOptionLabel={(option) => option.label}
            getOptionSelected={(option) => Number(values.editorial) === option.value}
            value={editorialsOptions.find((ed) => ed.value === Number(values.editorial))}
            fullWidth
            clearIcon={null}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Seleccione Editorial..."
                error={Boolean(errors.editorial && touched.editorial)}
                helperText={Boolean(errors.editorial && touched.editorial) ? errors.editorial : ''}
              />
            )}
            onChange={(_, value) => {
              if (value) {
                setFieldValue('editorial', value.value);
              }
            }}
          />
        )}
      </Grid>

      <Grid item container xs={12} md={3}>
        <Grid item xs={6} md={6} lg={6}>
          <InputLabel>Idioma</InputLabel>
        </Grid>
        <Grid item xs={6} md={6} lg={6} sx={{ textAlign: 'right' }}>
          <Button
            size="small"
            endIcon={<IconPlus size={14} />}
            onClick={() => {
              setShowFormIdiom(true);
            }}
          >
            Agregar
          </Button>
        </Grid>
        {isLoadingIdioms || isFetchingIdioms ? (
          <TextField placeholder="Cargando..." fullWidth />
        ) : (
          <Autocomplete
            options={idiomsOptions}
            getOptionLabel={(option) => option.label}
            getOptionSelected={(option) => Number(values.idiom) === option.value}
            value={idiomsOptions.find((id) => id.value === Number(values.idiom))}
            fullWidth
            clearIcon={null}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Seleccione Idioma..."
                error={Boolean(errors.idiom && touched.idiom)}
                helperText={Boolean(errors.idiom && touched.idiom) ? errors.idiom : ''}
              />
            )}
            onChange={(_, value) => {
              if (value) {
                setFieldValue('idiom', value.value);
              }
            }}
          />
        )}
      </Grid>

      {/* <Grid item xs={12} md={6}>
        <InputLabel>Tipo de Documento</InputLabel>
        <Autocomplete
          options={typesDocuments}
          getOptionLabel={(option) => option.label}
          getOptionSelected={(option) => values.type_document === option.value}
          value={typesDocuments.find((doc) => doc.value === values.type_document)}
          fullWidth
          clearIcon={null}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Seleccione Tipo..."
              error={Boolean(errors.type_document && touched.type_document)}
              helperText={
                Boolean(errors.type_document && touched.type_document)
                  ? errors.type_document
                  : ''
              }
            />
          )}
          onChange={(_, value) => {
            if (value) {
              setFieldValue('type_document', value.value);
            }
          }}
        />
      </Grid> */}

      <Grid item xs={12} md={3}>
        <InputLabel>ISBN</InputLabel>
        <TextField
          fullWidth
          placeholder="Ingrese ISBN"
          value={values.isbn}
          error={Boolean(errors.isbn && touched.isbn)}
          helperText={Boolean(errors.isbn && touched.isbn) ? errors.isbn : ''}
          onChange={({ target }) => setFieldValue('isbn', target.value)}
        />
      </Grid>

      <Grid item xs={12} md={3}>
        <InputLabel>Edición</InputLabel>
        <TextField
          fullWidth
          placeholder="Ingrese Edición"
          value={values.version}
          error={Boolean(errors.version && touched.version)}
          helperText={Boolean(errors.version && touched.version) ? errors.version : ''}
          onChange={({ target }) => setFieldValue('version', target.value)}
        />
      </Grid>

      <Grid item xs={12} md={3}>
        <InputLabel>Origen</InputLabel>
        <Autocomplete
          options={origins}
          getOptionLabel={(opt) => opt.label}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Seleccione Origen..."
              error={Boolean(errors.origin && touched.origin)}
              helperText={Boolean(errors.origin && touched.origin) ? errors.origin : ''}
            />
          )}
          getOptionSelected={(opt) => values.origin === opt.value}
          value={origins.find((or) => or.value === values.origin)}
          onChange={(e, value) => {
            if (value) {
              setFieldValue('origin', value.value);
            }
          }}
          fullWidth
          clearIcon={null}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <InputLabel>Simbolo</InputLabel>
        <TextField
          fullWidth
          placeholder="Ingrese Simbolo"
          value={values.symbol}
          error={Boolean(errors.symbol && touched.symbol)}
          helperText={Boolean(errors.symbol && touched.symbol) ? errors.symbol : ''}
          onChange={({ target }) => setFieldValue('symbol', target.value)}
        />
      </Grid>

      {/* <Grid item xs={12} md={3}>
        <InputLabel>Código de Barra de Institución</InputLabel>
        <TextField
          fullWidth
          placeholder="Ingrese Código de Barra"
          value={values.codebar}
          error={Boolean(errors.codebar && touched.codebar)}
          helperText={
            Boolean(errors.codebar && touched.codebar) ? errors.codebar : ''
          }
          onChange={({ target }) => setFieldValue('codebar', target.value)}
        />
      </Grid> */}

      <Grid item xs={12} md={3}>
        <InputLabel>Fecha de Publicación</InputLabel>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
          <DatePicker
            value={values.publish_date}
            onChange={(newValue) => {
              setFieldValue('publish_date', newValue);
            }}
            shouldDisableDate={(date) => isBefore(new Date(), new Date(date))}
            renderInput={(params) => (
              <TextField
                {...params}
                error={Boolean(errors.publish_date && touched.publish_date)}
                helperText={
                  Boolean(errors.publish_date && touched.publish_date) ? errors.publish_date : ''
                }
                placeholder="Ingrese fecha de publicación"
                fullWidth
              />
            )}
          />
        </LocalizationProvider>
      </Grid>

      <Grid item xs={12} md={3}>
        <InputLabel>Número de Paginas</InputLabel>
        <TextField
          type="number"
          fullWidth
          placeholder="Ingrese Número de paginas"
          value={values.num_pag}
          error={Boolean(errors.num_pag && touched.num_pag)}
          helperText={Boolean(errors.num_pag && touched.num_pag) ? errors.num_pag : ''}
          onChange={({ target }) => setFieldValue('num_pag', target.value)}
        />
      </Grid>

      <Grid item xs={12} md={3}>
        <InputLabel>Serie</InputLabel>
        <TextField
          fullWidth
          placeholder="Ingrese Serie"
          value={values.serie}
          error={Boolean(errors.serie && touched.serie)}
          helperText={Boolean(errors.serie && touched.serie) ? errors.serie : ''}
          onChange={({ target }) => setFieldValue('serie', target.value)}
        />
      </Grid>

      <Grid item xs={12} md={3}>
        <InputLabel>Estado</InputLabel>
        <Autocomplete
          options={statusAvailables.map((st) => ({ value: st.id, label: st.name }))}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Seleccione Estado..."
              error={Boolean(errors.status && touched.status)}
              helperText={Boolean(errors.status && touched.status) ? errors.status : ''}
            />
          )}
          value={statusAvailables
            .map((st) => ({ value: st.id, label: st.name }))
            .find((st) => st.value === Number(values.status))}
          onChange={(e, value) => {
            setFieldValue('status', value?.value);
          }}
          fullWidth
          clearIcon={null}
        />
      </Grid>

      <Grid item xs={12} md={12} lg={12}></Grid>

      <Grid item xs={12} md={12} lg={12}></Grid>

      <Grid item xs={12} md={6}>
        <InputLabel>Subir ilustración (carátula)</InputLabel>
        <IconButton
          color="primary"
          aria-label="upload picture"
          component="label"
          size="medium"
          sx={{
            borderRadius: '10px',
            border: 1,
            borderColor: 'cyan',
            width: '100%',
            mt: '18px',
            height: 150,
          }}
        >
          <input
            hidden
            accept="image/jpeg, image/png"
            type="file"
            onChange={(e) => {
              if (e && e.target && e.target.files && e.target.files.length > 0) {
                setFieldValue('cover', [e.target.files[0]]);
              }
            }}
          />
          {values.cover && values.cover.length > 0 ? (
            <Stack direction="column" alignItems="center">
              <img
                style={{ width: 60, height: 80, objectFit: 'cover' }}
                src={URL.createObjectURL(values.cover[0])}
                alt="cover"
              />
              <br />
              <Typography>&nbsp;{values.cover[0].name}</Typography>
            </Stack>
          ) : (
            <>
              <PhotoCamera />
              <Typography>&nbsp;Subir Imagen...</Typography>
            </>
          )}
        </IconButton>
      </Grid>

      <Grid item xs={12} md={6} sx={{ alignSelf: 'baseline' }}>
        {values.origin === 'Digital' ? (
          // || values.origin === 'Fisico y Digital'
          <>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <InputLabel>Subir archivo digital</InputLabel>
              <FormControl>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={values.isUrlFile}
                        onChange={() => setFieldValue('isUrlFile', !values.isUrlFile)}
                        name="gilad"
                      />
                    }
                    label="Usar URL"
                  />
                </FormGroup>
              </FormControl>
            </Stack>
            {values.isUrlFile ? (
              <TextField
                value={values.file}
                placeholder="Ingrese url de archivo"
                error={Boolean(errors.file_url && touched.file_url)}
                helperText={Boolean(errors.file_url && touched.file_url) && errors.file_url}
                fullWidth
              />
            ) : (
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="label"
                size="medium"
                sx={{ borderRadius: '10px', border: 1, borderColor: 'cyan', width: '100%' }}
              >
                <input
                  hidden
                  accept="application/pdf,application/msword"
                  type="file"
                  onChange={(e) => {
                    setFieldValue('file', [e!.target!.files![0]]);
                  }}
                />
                <>
                  {values.file.length > 0 ? (
                    <Typography>&nbsp;{values!.file![0].name}</Typography>
                  ) : (
                    <>
                      <FileUpload />
                      <Typography>&nbsp;Subir Archivo...</Typography>
                    </>
                  )}
                </>
              </IconButton>
            )}
            {Boolean(errors.file && touched.file) && (
              <Typography variant="caption" color="red">
                &nbsp;&nbsp; {errors.file}
              </Typography>
            )}
          </>
        ) : null}
      </Grid>
      <Grid item container xs={12} md={12} justifyContent="flex-end">
        <Button variant="contained" onClick={handleFormSubmit} size="large">
          Siguiente
        </Button>
      </Grid>

      {/* 
      /**
       * DIALOGS
       * FORMS AUTHOR, CITY, IDIOM
       **
      */}

      <Dialog
        onClose={() => {
          setShowFormAuthor(false);
        }}
        open={showFormAuthor}
      >
        <DialogTitle>
          <Typography variant="h3" sx={{ textAlign: 'center' }}>
            Agregar Autor
          </Typography>
        </DialogTitle>
        <DialogContent>
          <AddAuthor
            isModal={true}
            onUpdate={refetchAuthors}
            handleCloseModal={() => setShowFormAuthor(false)}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        onClose={() => {
          setShowFormCity(false);
        }}
        open={showFormCity}
      >
        <DialogTitle>
          <Typography variant="h3" sx={{ textAlign: 'center' }}>
            Agregar Ciudad
          </Typography>
        </DialogTitle>
        <DialogContent>
          <FormAddCity onUpdate={refetchCities} handleClose={() => setShowFormCity(false)} />
        </DialogContent>
      </Dialog>

      <Dialog
        onClose={() => {
          setShowFormEditorial(false);
        }}
        open={showFormEditorial}
      >
        <DialogTitle>
          <Typography variant="h3" sx={{ textAlign: 'center' }}>
            Agregar Editorial
          </Typography>
        </DialogTitle>
        <DialogContent>
          <FormAddEditorial
            onUpdate={refetchEditorials}
            handleClose={() => setShowFormEditorial(false)}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        onClose={() => {
          setShowFormIdiom(false);
        }}
        open={showFormIdiom}
      >
        <DialogTitle>
          <Typography variant="h3" sx={{ textAlign: 'center' }}>
            Agregar Idioma
          </Typography>
        </DialogTitle>
        <DialogContent>
          <FormAddIdiom onUpdate={refetchIdioms} handleClose={() => setShowFormIdiom(false)} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Step1;
