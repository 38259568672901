
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/useRedux';
import CustomSideDialog from '../../../../../shared/components/dialogs/CustomSideDialog';
import {
  setEvaluationTypeIdSelected,
  setEvaluationTypeSideDialogOpen,
} from '../slices/evaluationTypeSlice';
import EvaluationTypeForm from './EvaluationTypeForm';

const EvaluationTypeSideDialog = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { evaluationTypeIdSelected } = useAppSelector((state) => state.evaluationType);

  const handleClose = () => {
    dispatch(setEvaluationTypeSideDialogOpen(false));
    dispatch(setEvaluationTypeIdSelected(null));
  };

  return (
    <CustomSideDialog
      open
      handleCloseDialog={handleClose}
      title={
        evaluationTypeIdSelected
          ? t('maintenance.evaluation_type.edit.title')
          : t('maintenance.evaluation_type.add.title')
      }
    >
      <EvaluationTypeForm />
    </CustomSideDialog>
  );
};

export default EvaluationTypeSideDialog;
