import { Avatar, Button, Grid, Typography } from '@material-ui/core';
import { Send } from '@material-ui/icons';
import { IconCalendarStats, IconUserCheck } from '@tabler/icons';
import { useHistory } from 'react-router-dom';
import useFormatDate from '../../hooks/useFormatDate';
import { EvaluationByEvaluated } from '../interfaces/evaluationByEvaluated.interface';

interface EvaluationCardProps {
  evaluation: EvaluationByEvaluated;
}

const EvaluationCard = ({ evaluation }: EvaluationCardProps) => {
  const { formatDate } = useFormatDate();

  const history = useHistory();

  const handleEvaluate = () => {
    history.push(`/evaluaciones-usuario/${evaluation.id}/evaluar`);
  };

  return (
    <Grid
      p={2}
      container
      spacing={1}
      sx={{
        border: '1px solid #e0e0e0',
        borderRadius: 3,
      }}
    >
      <Grid item xs={12}>
        <Typography variant="h3" color="primary">
          {evaluation?.course_name || ''}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h3">{evaluation.eva_release?.name}</Typography>
      </Grid>
      {/* <Grid item xs={4}></Grid> */}
      <Grid item container xs={12} alignItems="center">
        <Grid item xs={2}>
          <Avatar
            sx={{
              backgroundColor: 'primary.light',
            }}
          >
            <IconUserCheck />
          </Avatar>
        </Grid>
        <Grid item xs={10}>
          <Typography variant="h4">
            {evaluation.user?.person?.pers_full_lastname || 'N/A'}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container xs={12} alignItems="center">
        <Grid item xs={2}>
          <Avatar
            sx={{
              backgroundColor: 'primary.light',
            }}
          >
            <IconCalendarStats />
          </Avatar>
        </Grid>
        <Grid item xs={10}>
          <Typography variant="h5">
            {formatDate(evaluation.eva_release?.start_date)} /{' '}
            {formatDate(evaluation.eva_release?.end_date)}
          </Typography>
        </Grid>
      </Grid>

      <Grid item container xs={12}>
        <Button variant="contained" color="primary" endIcon={<Send />} onClick={handleEvaluate}>
          Ir
        </Button>
      </Grid>
    </Grid>
  );
};

export default EvaluationCard;
