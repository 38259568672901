import {
  Alert,
  Autocomplete,
  Button,
  CircularProgress,
  DialogContent,
  DialogTitle,
  Divider,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  Skeleton,
  Snackbar,
  TextField,
  Typography,
} from '@material-ui/core';
import { IconX } from '@tabler/icons';
import { FormikErrors, useFormik } from 'formik';
import moment from 'moment';
import { useEffect, useMemo, useRef, useState } from 'react';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import {
  // useAppDispatch,
  useAppSelector,
} from '../../../../hooks/useRedux';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import ExtendedSideDialog from '../../../shared/components/dialogs/ExtendedSideDialog';
// import CustomAsyncPaginatedSelect from '../../../shared/components/extended/Form/CustomAsyncPaginatedSelect';
import { useGetAvailablesCampusQuery } from '../../campus/slices/campusApiSlice';
import { reorder } from '../helpers/helpers';
import {
  CareerPlanningForm,
  CourseData,
  DatesPlaning,
} from '../interfaces/programPlanningPayload.interfaces';
import { PostgraduatePlanningAdditionalInformation } from '../interfaces/programPlanningResponse.interface';
import {
  useCreateProgramPlanningMutation,
  useGetCurriculumHourhandsQuery,
  useGetPosgraduateClassroomsByCampusQuery,
  useGetPosgraduateCollaboratorsByCampusQuery,
  useGetPosgraduateSubjectsByCurriculumQuery,
  // useLazyGetPosgraduateParallelsQuery,'
} from '../slices/acPlanPostgraduateApiSlice';
// import { setInitialInfoPlanning } from '../slices/acPlanPostgraduateSlice';
import { careePlanningJsonValidationSchema } from '../validations/careerPlanning';
import { DayType } from '../interfaces/posgraduateDaysTypes.interfaces';
import { EducationLevel } from '../../education-levels/interfaces/educationLevels.interfaces';
import useFormatDate from '../../../shared/hooks/useFormatDate';
import TextFieldFetching from '../../../shared/components/TextFieldFetching';
import { virtualClassrooms } from '../../../shared/constants/virtualClassroomDict';

interface Props {
  open: boolean;
  initialInfoPlanning: any;
  eduLevel: EducationLevel;
  myEvents: any[];
  initialEventsPlanning: any[];
  colorEvents: DayType[];
  handleClose: () => void;
  courseData: CourseData[];
  setCourseData: (courseData: CourseData[]) => void;
  curriculumId: number;
  year: number;
  additionalInformation?: PostgraduatePlanningAdditionalInformation;
  idTransactionPlanning?: string;
  status: string;
  feeCollectionDay?: number;
  namePeriod: string;
  refetchPlanning: any;
}

const CareerPlanningSideDialog = ({
  open,
  initialInfoPlanning,
  eduLevel,
  myEvents,
  initialEventsPlanning,
  colorEvents,
  courseData,
  setCourseData,
  curriculumId,
  handleClose,
  year,
  additionalInformation,
  idTransactionPlanning,
  status,
  feeCollectionDay = 1,
  namePeriod,
  refetchPlanning,
}: Props) => {
  const { t } = useTranslation();
  // const dispatch = useAppDispatch();
  const [showAlerts, setShowAlerts] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [dependenceFaultIds, setDependenceFaultIds] = useState<string[]>([]);

  const { hourhandIdSelected } = useAppSelector((state) => state.academicPlanningPosgraduate);

  const history = useHistory();

  const { search } = useLocation();

  const params = new URLSearchParams(search);

  const completePlanning = useMemo(
    () => Boolean(params.get('complete')),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const alertRef = useRef<any>(null);

  const [addPlanning, { isLoading: isLoadingPlanning, isSuccess, isError, error }] =
    useCreateProgramPlanningMutation();

  const { currentProfile, serviceCredentials } = useAppSelector((state) => state.access);

  const { firstDay } = useMemo(
    () => ({
      firstDay: colorEvents.find((event) => event.pdt_keywork === 'IDA'),
    }),
    //eslint-disable-next-line
    [colorEvents]
  );

  const { formatDate } = useFormatDate();

  const startDate = myEvents[0]?.start;
  const endDate = myEvents[myEvents.length - 1]?.end;

  const { errors, handleSubmit, getFieldProps, touched, setFieldValue, values } =
    useFormik<CareerPlanningForm>({
      initialValues: {
        campusId: Number(additionalInformation?.campus_id) || 0, // al form
        capacity: Number(additionalInformation?.capacity) || 0, // al form
        curriculumId: curriculumId, // de la carrera
        hourhandsId:
          additionalInformation?.postgraduate_planning_hourhands.map((hourhand) =>
            Number(hourhand.hourhand_id)
          ) || hourhandIdSelected
            ? [Number(hourhandIdSelected)!]
            : [], // al form
        namePeriod: additionalInformation?.name_period || '', // de la peticion
        parallelId: Number(additionalInformation?.parallel_id) || 0, // de la peticion
        classroomId: Number(additionalInformation?.classroom_id), // al form
        numFees: Number(additionalInformation?.number_fees_period_stages) || 0, // al form
        courseData, // al form
        feeCollectionDay,
        typeVirtualClassroom: courseData?.[0]?.typeClassrooomVirtual || 'blackboard',
      },
      validationSchema: careePlanningJsonValidationSchema,
      onSubmit: async (values) => {
        const curriculumId = eduLevel?.meshs?.[0]?.id || 0;

        await addPlanning({
          academicPlanningPayload: {
            campus_id: values.campusId,
            capacity: values.capacity,
            curriculum_id: curriculumId,
            hourhand_id: values.hourhandsId,
            //hourhand_id: values.hourhandsId,
            namePeriod: namePeriod || additionalInformation?.name_period || '',
            classroom_id: values.classroomId,
            per_current_year: year || new Date().getFullYear(),
            parallel_id: values.parallelId,
            number_fees_period_stages: values.numFees,
            id_transaction_planning: idTransactionPlanning || '0',
            datesPlaning: generateAcademicEventsByCourse().map((dates) =>
              dates.map((date) => ({
                ...date,
                start_date: moment(date.start_date, 'D/M/yyyy').format('YYYY-MM-DD HH:mm:ss'),
                end_date: moment(date.end_date, 'D/M/yyyy').format('YYYY-MM-DD HH:mm:ss'),
                start: date.start_date,
                type_classroom_virtual: values.typeVirtualClassroom,
              }))
            ),
            datesHoliday: initialEventsPlanning
              .filter((event) => event.typeId === 'FDS')
              .map((event) => ({
                start_date: moment(event.start, 'D/M/yyyy').isValid()
                  ? moment(event.start, 'D/M/yyyy').format('YYYY-MM-DD HH:mm:ss')
                  : moment(event.start, 'YYYY-MM-DD').format('YYYY-MM-DD HH:mm:ss'),
                end_date: moment(event.end, 'D/M/yyyy').isValid()
                  ? moment(event.end, 'D/M/yyyy').format('YYYY-MM-DD HH:mm:ss')
                  : moment(event.end, 'YYYY-MM-DD').format('YYYY-MM-DD HH:mm:ss'),
                holiday_id: event.id,
                postgraduate_day_type_id:
                  colorEvents.find((colorEvent) => colorEvent.pdt_keywork === event.typeId)?.id ||
                  '-1',
                color: event.color,
                start: event.start,
              })),
            off_description: eduLevel?.offer?.off_description || '',
            method: additionalInformation ? 'PUT' : undefined,
            fee_collection_day_max: values.feeCollectionDay,
          },
        });

        setShowAlerts(true);
      },
    });

  useEffect(() => {
    if (completePlanning && isSuccess) {
      history.push('/listado-planeacion-academica-posgrado');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  useEffect(() => {
    setFieldValue('courseData', courseData);
    // eslint-disable-next-line
  }, [courseData]);

  const { data: campus = [], isLoading: isLoadingCampus } = useGetAvailablesCampusQuery({
    profileId: currentProfile?.profileId!,
  });

  const { data: subjects, isLoading: isLoadingSubjects } =
    useGetPosgraduateSubjectsByCurriculumQuery(
      {
        profileId: currentProfile?.profileId!,
        curriculumId: Number(eduLevel?.meshs?.[0]?.id!),
      },
      {
        skip: !eduLevel?.meshs?.[0]?.id || !currentProfile?.profileId,
      }
    );

  const { data: teachers, isLoading: isLoadingTeachers } =
    useGetPosgraduateCollaboratorsByCampusQuery(
      {
        profileId: currentProfile?.profileId,
        campusId: values.campusId,
      },
      {
        skip: !values.campusId || !currentProfile?.profileId,
      }
    );

  const {
    data: classrooms,
    isLoading: isLoadingClassrooms,
    isFetching: isFetchingClassrooms,
  } = useGetPosgraduateClassroomsByCampusQuery(
    {
      campusId: values.campusId,
      profileId: currentProfile?.profileId,
      hourhandId: values.hourhandsId[0],
      startDate: formatDate(startDate, {
        format: 'yyyy-MM-dd',
      }),
      endDate: formatDate(endDate, {
        format: 'yyyy-MM-dd',
      }),
      method: additionalInformation ? 'PUT' : undefined,
      idTransactionPlanning: additionalInformation
        ? additionalInformation.id_transaction_planning
        : undefined,
    },
    {
      skip: !values.campusId || values.hourhandsId.length === 0 || !currentProfile?.profileId,
    }
  );

  const { data: curriculumHourhands = [], isLoading: isLoadingHourhands } =
    useGetCurriculumHourhandsQuery(
      {
        profileId: currentProfile?.profileId!,
        curriculumId: curriculumId!,
      },
      {
        skip: !curriculumId || !currentProfile?.profileId,
      }
    );

  const generateAcademicEventsByCourse = (): Array<DatesPlaning[]> => {
    // recorrer los programas iniciales y validar desde el valor minimo hasta el maxima

    // filter events firts day
    const eventsIDA = myEvents.filter((event) => event.typeId === firstDay?.pdt_keywork);

    const sortEventsIDA = eventsIDA.sort((a, b) => {
      var c = new Date(a.start);
      var d = new Date(b.start);
      if (c > d) {
        return 1;
      }
      if (c < d) {
        return -1;
      }
      return 0;
    });

    let datesPlanning: Array<DatesPlaning[]> = [];

    sortEventsIDA.forEach((program, index) => {
      const filterEventsInThisDateRange = myEvents.filter(
        (event) =>
          event.start >= program.start &&
          (sortEventsIDA.length - 1 === index ? true : sortEventsIDA[index + 1].start > event.start)
        // sortEventsIDA[sortEventsIDA.length - 1 === index ? index : index + 1].start > event.start
        // event.start < moment(program.start).add(28, 'days').toDate()
      );

      const idaEvent = courseData.find((course) => course.eventId === program.id);

      datesPlanning.push(
        filterEventsInThisDateRange.map((event) => {
          return {
            start_date: event.start.toLocaleString(),
            end_date: event.end.toLocaleString(),
            postgraduate_day_type_id:
              colorEvents.find((colorEvent) => colorEvent.pdt_colour === event.color)?.id || '-1',
            color: event.color,
            subject_curriculum_id: idaEvent?.subjectId,
            collaborator_id: idaEvent?.teacherId !== 0 ? idaEvent?.teacherId : undefined,
          };
        })
      );
    });

    return datesPlanning;
  };

  useEffect(() => {
    alertRef?.current?.scrollIntoView({ behavior: 'smooth' });
    if (isSuccess) {
      refetchPlanning();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  const onDragEnd = ({ destination, source }: DropResult) => {
    if (!destination) {
      return;
    }

    if (destination.index === source.index && destination.droppableId === source.droppableId) {
      return;
    }

    const subjectId = courseData[source.index].subjectId;

    const subject = subjects?.find((subject) => subject.id === subjectId);

    const dependencesFault: string[] = [];

    subject?.dependence_subject?.forEach((dependenceSubjectId) => {
      const dependenceSubjectIndex = courseData.findIndex(
        (course) => course.subjectId === Number(dependenceSubjectId)
      );
      if (dependenceSubjectIndex >= destination.index) {
        dependencesFault.push(dependenceSubjectId);
      }
    });

    if (dependencesFault.length > 0) {
      setDependenceFaultIds(dependencesFault);
      setOpenSnackbar(true);
      return;
    }

    const programs = reorder(courseData, source.index, destination.index);

    const idaEvents = myEvents!
      .filter((event) => event.typeId === 'IDA')
      .sort((a, b) => {
        var c = new Date(a.start);
        var d = new Date(b.start);
        if (c > d) {
          return 1;
        }
        if (c < d) {
          return -1;
        }
        return 0;
      });

    const newPrograms = programs.map((program, index) => ({
      ...program,
      eventId: idaEvents[index].id,
    }));

    setFieldValue('courseData', newPrograms);
    setCourseData(newPrograms);
  };

  return (
    <>
      <ExtendedSideDialog open={open} onClose={handleClose}>
        <DialogTitle>
          <Typography variant="h3">
            {t('posgraduate.complete.title')} - {namePeriod}
          </Typography>
        </DialogTitle>
        <DialogContent>
          {!isLoadingSubjects && (
            <form onSubmit={handleSubmit}>
              <Grid container direction="row" justifyContent="center" alignItems="center" gap={2}>
                <Grid ref={alertRef} item xs={12}>
                  {showAlerts && isSuccess && (
                    <SuccessAlert
                      message={t('posgraduate.success_added')}
                      handleDismiss={() => setShowAlerts(false)}
                    />
                  )}
                  {showAlerts && isError && (
                    <ErrorAlert
                      message={error as string}
                      handleDismiss={() => setShowAlerts(false)}
                      // handleRetry={handleRetry}/
                    />
                  )}
                </Grid>

                <Grid item xs={12}>
                  <Divider />
                </Grid>

                <Grid item container xs={12} spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h4" color="primary">
                      Información de aula
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputLabel id="select-campus-label">{t('posgraduate.campus')}</InputLabel>
                    {(isLoadingCampus || !campus) && (
                      <TextField fullWidth placeholder={t('posgraduate.campus.loading')} disabled />
                    )}
                    {!isLoadingCampus && campus && (
                      <Autocomplete
                        options={campus}
                        getOptionLabel={(option) => option.cam_name}
                        loading={isLoadingCampus}
                        loadingText={t('posgraduate.campus.loading')}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            placeholder={t('posgraduate.campus.placeholder')}
                            error={Boolean(touched.campusId && errors.campusId)}
                          />
                        )}
                        {...getFieldProps('campusId')}
                        multiple={false}
                        value={campus.find((campus) => campus.id === values.campusId)}
                        onChange={(event, newValue) => {
                          setFieldValue('campusId', newValue?.id);
                          setFieldValue('classRoomId', 0);
                          setFieldValue('capacity', 0);
                        }}
                        fullWidth
                        disableClearable
                        disabled={status === 'ejecutada'}
                      />
                    )}
                    {errors.campusId && touched.campusId && (
                      <FormHelperText error>{t(errors.campusId)}</FormHelperText>
                    )}
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <InputLabel>{t('posgraduate.schedule')}</InputLabel>
                    {status === 'ejecutada' ? (
                      <TextField
                        fullWidth
                        value={
                          additionalInformation?.postgraduate_planning_hourhands?.reduce(
                            (acc, ph) => {
                              return (
                                acc + `${acc !== '' ? ', ' : ''}${ph.hourhand.hour_description}`
                              );
                            },
                            ''
                          ) || ''
                        }
                        disabled
                      />
                    ) : (
                      <>
                        {isLoadingHourhands || curriculumHourhands.length === 0 ? (
                          <TextField
                            disabled
                            placeholder={t('posgraduate.schedule.loading')}
                            fullWidth
                          />
                        ) : (
                          <Autocomplete
                            options={curriculumHourhands}
                            getOptionLabel={(option) => `${option.hour_description}`}
                            loading={isLoadingHourhands}
                            loadingText={t('posgraduate.schedule.loading')}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                                placeholder={t('posgraduate.schedule.placeholder')}
                                error={Boolean(touched.hourhandsId && errors.hourhandsId)}
                              />
                            )}
                            {...getFieldProps('hourhandsId')}
                            multiple={false}
                            value={
                              curriculumHourhands.filter((hourhand) =>
                                values.hourhandsId.includes(Number(hourhand.id))
                              )?.[0] || null
                              // gaurdar un solo horario
                            }
                            onChange={(event, newValue) => {
                              // dispatch(
                              //   setInitialInfoPlanning({
                              //     ...initialInfoPlanning,
                              //     hourhandId: newValue?!,
                              //   })
                              // );
                              setFieldValue('hourhandsId', [Number(newValue.id)]);
                            }}
                            fullWidth
                            disableClearable
                            disabled={
                              status === 'ejecutada' ||
                              eduLevel?.meshs?.[0]?.modality?.cat_keyword === 'modalidad-hibrida'
                            }
                          />
                        )}
                      </>
                    )}
                    {errors.hourhandsId && touched.hourhandsId && (
                      <FormHelperText error>{t(errors.hourhandsId)}</FormHelperText>
                    )}
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <InputLabel>{t('posgraduate.classroom')}</InputLabel>
                    {status === 'ejecutada' ? (
                      <TextField
                        fullWidth
                        value={additionalInformation?.class_room?.cl_name}
                        disabled
                        // placeholder="Seleccione un aula"
                      />
                    ) : (
                      <>
                        {!isLoadingClassrooms &&
                          !isFetchingClassrooms &&
                          values.hourhandsId?.length !== 0 && (
                            <Autocomplete
                              options={classrooms || []}
                              loading={isLoadingClassrooms}
                              loadingText={t('posgraduate.classroom.loading')}
                              getOptionLabel={(option) => option.cl_name}
                              noOptionsText={t('posgraduate.classroom.no_data')}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  fullWidth
                                  placeholder={t('posgraduate.classroom.placeholder')}
                                  error={Boolean(touched.classroomId && errors.classroomId)}
                                />
                              )}
                              {...getFieldProps('classroomId')}
                              value={
                                classrooms?.find(
                                  (classroom) => classroom.id === values.classroomId
                                ) || null
                              }
                              multiple={false}
                              onChange={(event, newValue) => {
                                setFieldValue('classroomId', newValue?.id);
                                setFieldValue('capacity', Number(newValue?.cl_cap_max));
                              }}
                              fullWidth
                              disabled={status === 'ejecutada'}
                            />
                          )}
                        {(isLoadingClassrooms ||
                          isFetchingClassrooms ||
                          values.hourhandsId?.length === 0) && (
                          <TextField
                            fullWidth
                            disabled
                            placeholder={
                              isLoadingClassrooms || isFetchingClassrooms
                                ? t('posgraduate.classroom.loading')
                                : t('posgraduate.classroom.select_hourhand')
                            }
                          />
                        )}
                      </>
                    )}
                  </Grid>

                  <Grid item xs={6}>
                    <InputLabel>{t('academic.courses.side.virtual_classroom_type')}</InputLabel>
                    <Autocomplete
                      options={
                        serviceCredentials.moodle
                          ? virtualClassrooms
                          : virtualClassrooms.filter((dt) => dt.keyword !== 'moodle')
                      }
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          placeholder={t(
                            'academic.courses.side.virtual_classroom_type.placeholder'
                          )}
                          error={Boolean(
                            errors.typeVirtualClassroom && touched.typeVirtualClassroom
                          )}
                          {...getFieldProps('typeVirtualClassroom')}
                        />
                      )}
                      value={
                        virtualClassrooms.find(
                          (dt) => dt.keyword === values.typeVirtualClassroom
                        ) || null
                      }
                      onChange={(event, newValue) => {
                        setFieldValue('typeVirtualClassroom', newValue?.keyword || null);
                      }}
                    />
                    {errors.typeVirtualClassroom && touched.typeVirtualClassroom && (
                      <FormHelperText error>
                        {t(errors.typeVirtualClassroom as string)}
                      </FormHelperText>
                    )}
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <InputLabel>{t('posgraduate.capacity')}</InputLabel>
                    <TextField
                      {...getFieldProps('capacity')}
                      placeholder={t('posgraduate.capacity.placeholder')}
                      fullWidth
                      error={Boolean(touched.capacity && errors.capacity)}
                      type="number"
                      inputProps={{
                        min: 0,
                        max: Number(
                          classrooms?.find((cl) => cl.id === values.classroomId)?.cl_cap_max || 0
                        ),
                      }}
                    />
                    {errors.capacity && touched.capacity && (
                      <FormHelperText error>{errors.capacity}</FormHelperText>
                    )}
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Divider />
                </Grid>

                <Grid item container xs={12} spacing={2} justifyContent="center">
                  <Grid item xs={12}>
                    <Typography variant="h4" color="primary">
                      Datos de cuotas
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputLabel>{t('posgraduate.num_fees')}</InputLabel>
                    <TextField
                      {...getFieldProps('numFees')}
                      fullWidth
                      placeholder={t('posgraduate.num_fees.placeholder')}
                      error={Boolean(touched.numFees && errors.numFees)}
                    />
                    {errors.numFees && touched.numFees && (
                      <FormHelperText error>{t(errors.numFees)}</FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputLabel>{t('posgraduate.day_max_payment')}</InputLabel>
                    <TextField
                      {...getFieldProps('feeCollectionDay')}
                      fullWidth
                      placeholder={t('posgraduate.day_max_payment.placeholder')}
                      error={Boolean(touched.feeCollectionDay && errors.feeCollectionDay)}
                    />
                    {errors.feeCollectionDay && touched.feeCollectionDay && (
                      <FormHelperText error>{errors.feeCollectionDay}</FormHelperText>
                    )}
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Divider />
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="h4" color="primary">
                    {t('posgraduate.date_start_subject')}
                  </Typography>
                  {values.courseData?.length === 0 && (
                    <Grid container spacing={2}>
                      {Array.from(new Array(5)).map((_, index) => (
                        <Grid item xs={12} key={index}>
                          <Skeleton variant="rectangular" width="100%" height={150} />
                        </Grid>
                      ))}
                    </Grid>
                  )}
                  {values.courseData?.length > 0 && (
                    <List>
                      <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable">
                          {(provided, snapshot) => (
                            <div {...provided.droppableProps} ref={provided.innerRef}>
                              {values.courseData?.map((course, index) => (
                                <Draggable
                                  isDragDisabled={status === 'ejecutada'}
                                  key={course.eventId}
                                  draggableId={course.eventId.toString()}
                                  index={index}
                                >
                                  {(provided, snapshot) => (
                                    <ListItem
                                      key={course.eventId}
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      sx={{
                                        p: 2,
                                        pr: 0,
                                      }}
                                    >
                                      <Grid
                                        container
                                        spacing={2}
                                        justifyContent="flex-end"
                                        sx={{
                                          boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.75)',
                                          borderRadius: '12px',
                                          pr: 2,
                                          pb: 2,
                                        }}
                                      >
                                        <Grid item container xs={6}>
                                          <Grid item xs={12}>
                                            <Typography variant="h4" color="primary">
                                              {moment(
                                                myEvents.find((e) => e.id === course.eventId)
                                                  ?.start || new Date()
                                              ).format('yyyy-MM-DD')}
                                            </Typography>
                                            <Typography variant="h5" color="info">
                                              {
                                                subjects?.find(
                                                  (subject) => subject.id === course.subjectId
                                                )?.matter?.mat_name
                                              }
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                        <Grid item xs={6}>
                                          <InputLabel>{t('posgraduate.teacher')}</InputLabel>
                                          {!isLoadingTeachers && teachers && (
                                            <Autocomplete
                                              key={`${course.eventId}-${course.teacherId}`}
                                              options={teachers}
                                              getOptionLabel={(option) =>
                                                option.user?.person?.pers_full_name
                                              }
                                              {...getFieldProps(`courseData[${index}].teacherId`)}
                                              renderInput={(params) => (
                                                <TextField
                                                  {...params}
                                                  fullWidth
                                                  error={Boolean(
                                                    touched.courseData?.[index]?.teacherId &&
                                                      (
                                                        errors.courseData?.[
                                                          index
                                                        ] as FormikErrors<CourseData>
                                                      )?.teacherId
                                                  )}
                                                  placeholder={
                                                    !values.campusId
                                                      ? t('posgraduate.teacher.select_campus')
                                                      : t('posgraduate.teacher.placeholder')
                                                  }
                                                  size="small"
                                                />
                                              )}
                                              multiple={false}
                                              onChange={(event, newValue) => {
                                                setCourseData(
                                                  courseData.map((c) => {
                                                    if (c.eventId === course.eventId) {
                                                      return {
                                                        ...c,
                                                        teacherId: newValue?.id!,
                                                      };
                                                    }
                                                    return c;
                                                  })
                                                );
                                                setFieldValue(
                                                  `courseData[${index}].teacherId`,
                                                  newValue?.id!
                                                );
                                              }}
                                              value={
                                                teachers.find(
                                                  (teacher) => teacher.id === course.teacherId
                                                ) || null
                                              }
                                              fullWidth
                                              disabled={status === 'ejecutada'}
                                            />
                                          )}
                                          {(isLoadingTeachers || !teachers) && (
                                            <TextFieldFetching />
                                          )}
                                          {touched.courseData?.[index]?.teacherId &&
                                            (errors.courseData?.[index] as FormikErrors<CourseData>)
                                              ?.teacherId && (
                                              <FormHelperText error>
                                                {
                                                  (
                                                    errors.courseData?.[
                                                      index
                                                    ] as FormikErrors<CourseData>
                                                  )?.teacherId
                                                }
                                              </FormHelperText>
                                            )}
                                        </Grid>
                                      </Grid>
                                    </ListItem>
                                  )}
                                </Draggable>
                              ))}

                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </List>
                  )}
                </Grid>

                <Grid
                  item
                  container
                  alignItems="center"
                  justifyContent="flex-end"
                  spacing={2}
                  sx={{ mt: 1 }}
                >
                  <Grid item>
                    {status !== 'ejecutada' && (
                      <Button
                        type="submit"
                        variant="contained"
                        size="large"
                        color="primary"
                        disabled={
                          isLoadingPlanning ||
                          isSuccess ||
                          (!namePeriod && !additionalInformation?.name_period)
                        }
                        endIcon={isLoadingPlanning && <CircularProgress />}
                      >
                        {t('posgraduate.create_planning')}
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </form>
          )}
        </DialogContent>
      </ExtendedSideDialog>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        sx={{ position: 'fixed', top: '100px' }}
        // message="Debes tener minimo 12 puntos de inicio"
        action={
          <>
            <Button color="secondary" size="small" onClick={() => setOpenSnackbar(false)}>
              Entendido
            </Button>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setOpenSnackbar(false)}
            >
              <IconX fontSize="small" />
            </IconButton>
          </>
        }
      >
        <Alert onClose={() => setOpenSnackbar(false)} severity="error" sx={{ width: '100%' }}>
          La materia tiene las siguientes dependencias:
          {dependenceFaultIds.map((id) => {
            return (
              <Typography variant="body2" color="black" key={id}>
                {subjects?.find((subject) => subject.id === Number(id))?.matter?.mat_name}
              </Typography>
            );
          })}
        </Alert>
      </Snackbar>
    </>
  );
};

export default CareerPlanningSideDialog;
