import { Button, CircularProgress, FormHelperText, Grid, Typography } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { useFormik } from 'formik';

import React, { useEffect, useState } from 'react';
import ErrorAlert from '../../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../../shared/components/alerts/SuccessAlert';
import { useAddMaterialsMutation } from '../../slices/virtualClassroomTeacherApiSlice';
import { MaterialSchema } from '../constants/validationSchema';
import InputModals from './InputModalsInputModals';
import { useTranslation } from 'react-i18next';

import ModalImport from './ModalImport';
import { useAppSelector } from '../../../../../hooks/useRedux';
import DragArea from '../../../../shared/components/files/DragArea';
import useFileFormatValidation from '../../../../../hooks/useFileFormatValidation';
//import theme from '../../../../shared/themes';
// import DragArea from '../../../../students/virtual-classroom/components/DragArea';

const ModalArchivos: React.FC<{
  Open: any;
  setOpen: React.Dispatch<React.SetStateAction<any>>;
}> = ({ Open, setOpen }) => {
  /*States */

  const { course } = useAppSelector((state) => state.virtualClassroomTeacherShared);

  const [showAlerts, setShowAlerts] = useState(false);
  const { t } = useTranslation();

  const { validateFormatFile } = useFileFormatValidation();

  const [errorFile, setErrorFile] = useState<string>('');

  const [addMaterials, { isLoading, isError, isSuccess, error }] = useAddMaterialsMutation();

  const {
    values: { description, file, title },
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm,

    errors,
    touched,
  } = useFormik({
    initialValues: {
      title: '',
      description: '',
      file: null,
      nameFolder: '',
    },

    validationSchema: MaterialSchema,

    onSubmit: () => {
      AddMaterial();
      resetForm({ values: { description: '', file: null, title: '', nameFolder: '' } });
    },
  });
  console.log(file!);
  console.log(Open);

  async function AddMaterial() {
    await addMaterials({
      courseId: Open.id,
      PayloadMaterials: {
        file: file!,
        description: description,
        title: title,
        course_id: course.courseId,
      },
    });
    setShowAlerts(true);
  }

  useEffect(() => {
    if (isSuccess) {
      resetForm();
      setOpen(false);
      setFieldValue('title', '');
      setFieldValue('description', '');
      setFieldValue('file', null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  /*Formik */

  const onChangeFile = function (event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      const error = validateFormatFile(file, '*');

      if (error !== '') {
        setErrorFile(error);
        return;
      }

      setFieldValue('file', event.target.files[0]);
    }
  };

  console.log(file!);

  return (
    <ModalImport Open={Open.modal} title={t('teacherVirtualClassroom.materials.modal.file.title')}>
      <>
        <Grid item container>
          <Grid item>
            {isError && showAlerts && (
              <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
            )}

            {errorFile !== '' && (
              <ErrorAlert message={errorFile} handleDismiss={() => setErrorFile('')} />
            )}

            {isSuccess && showAlerts && (
              <SuccessAlert
                message={t('teacherVirtualClassroom.materials.success')}
                handleDismiss={() => setShowAlerts(false)}
              />
            )}
          </Grid>
        </Grid>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} justifyContent={'space-between'}>
            <Grid item xs={5}>
              <div style={{ marginBottom: '10px ' }}>
                <Typography fontWeight={'bold'} mb={'10px'} color="#657F98">
                  {t('teacherVirtualClassroom.materials.modal.file.input.folder')}
                </Typography>
                <Typography fontWeight={'bold'} mb={'5px'} color="black">
                  {'>'}
                  {Open.nameFolder}
                </Typography>
              </div>

              <InputModals
                placeholder="Ingrese nombre"
                Title={t('teacherVirtualClassroom.materials.modal.file.input.material')}
                setValue={handleChange}
                name={'title'}
                error={Boolean(errors.title && touched.title)}
                messageError={errors.title}
              />

              {/* // Todo: Multilenguaje en labels */}
              <DragArea
                handleChange={onChangeFile}
                label="Subir archivo"
                description="Depositar su archivo listo en esta sección"
                showFileName
              />

              {errors.file && touched.file && <FormHelperText error>{errors.file}</FormHelperText>}
            </Grid>

            <Grid item xs={7}>
              <InputModals
                placeholder="Ingrese nombre"
                Title={t('teacherVirtualClassroom.materials.modal.file.input.detail')}
                name={'description'}
                setValue={handleChange}
                error={Boolean(errors.description && touched.description)}
                messageError={errors.description}
              />
              <Grid
                container
                style={{
                  backgroundColor: '#E6F8F4',
                  padding: '5px',
                  borderRadius: '20px',
                }}
              >
                <Grid item mt={'5px'} style={{ padding: '5px 12px' }} textOverflow={'ellipsis'}>
                  <Typography color={'primary'} variant="h5">
                    {t('teacherVirtualClassroom.materials.modal.file.info.format.title')}
                  </Typography>
                  <Typography mt={2} color={'primary'} variant="body2" noWrap={false}>
                    {t('teacherVirtualClassroom.materials.modal.file.info.format.descripcion')}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                style={{
                  backgroundColor: '#FCEBEA',
                  padding: '5px',
                  marginTop: '15px',
                  borderRadius: '20px',
                }}
              >
                <Grid style={{ padding: '5px 12px' }} item mt={'5px'} textOverflow={'ellipsis'}>
                  <Typography color={'primary'} variant="h5">
                    {t('teacherVirtualClassroom.materials.modal.file.info.recomendate.title')}
                  </Typography>
                  <Typography mt={2} color={'primary'} variant="body2" noWrap={false}>
                    {t('teacherVirtualClassroom.materials.modal.file.info.recomendate.description')}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid justifyContent={'center'} mt={'20px'} textAlign="center">
            <Button
              onClick={() => setOpen({ ...Open, modal: false })}
              style={{ marginRight: '30px', borderRadius: '30px' }}
              variant="outlined"
            >
              {t('teacherVirtualClassroom.materials.modal.button.regresar')}
            </Button>
            <LoadingButton
              loading={isLoading}
              type="submit"
              style={{ borderRadius: '30px' }}
              variant="contained"
              loadingPosition="end"
              endIcon={isLoading && <CircularProgress size={20} />}
            >
              {t('teacherVirtualClassroom.materials.modal.button.subir')}
            </LoadingButton>
          </Grid>
        </form>
      </>
    </ModalImport>
  );
};

export default ModalArchivos;
