import { FC } from 'react';
import useSharedGuard from '../../../../hooks/useSharedGuard';
import NotPermissionAlert from './NotPermissionAlert';

interface Props {
  permissions: string | string[];
  mode?: 'all' | 'one';

  notPermission?: () => JSX.Element;

  testForceValidation?: boolean;
}

const PermissionListCheck: FC<Props> = ({
  permissions,
  children,
  mode = 'all',
  notPermission,
  testForceValidation,
}) => {
  const { hasSharedPermissions } = useSharedGuard();

  const notPermissionComponent = notPermission ? notPermission : () => <NotPermissionAlert />;

  return (
    <>
      {hasSharedPermissions([...permissions], mode, testForceValidation)
        ? children
        : notPermissionComponent()}
    </>
  );
};

export default PermissionListCheck;
