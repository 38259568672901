import { Route, Switch } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallbackComponentScreen from '../../shared/components/errors-boundary/ErrorFallbackComponentScreen';

import CollaboratorsRouter from '../collaborators/router';
import TeachersRouter from '../hours-to-teachers/router';
import MaintenanceApplicationsRRHHRouter from '../maintenance-applications/router';

const HRRouter = () => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallbackComponentScreen}>
      <Switch>
        <Route path="/administrar-colaboradores" component={CollaboratorsRouter} />
        <Route path="/administrar-horas-docentes" component={TeachersRouter} />
        <Route path="/administrar-solicitudes-rrhh" component={MaintenanceApplicationsRRHHRouter} />
      </Switch>
    </ErrorBoundary>
  );
};

export default HRRouter;
