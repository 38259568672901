import {
  Checkbox,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DatePicker, LocalizationProvider, StaticTimePicker } from '@material-ui/lab';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import { useFormikContext } from 'formik';
import esLocale from 'date-fns/locale/es';

import { useTranslation } from 'react-i18next';

interface PracticeDataForm {
  practicePosition: string;
  practiceStartDate: Date;
  practiceEndDate: Date;
  practiceHourSunday: boolean;
  practiceHourStart: Date;
  practiceHourEnd: Date;
  practiceHourMonday: boolean;
  practiceHourTuesday: boolean;
  practiceHourWednesday: boolean;
  practiceHourThursday: boolean;
  practiceHourFriday: boolean;
  practiceHourSaturday: boolean;
}
export const useFormStyles = makeStyles((theme) => ({
  inputLabel: {
    marginBlockEnd: 6,
    fontWeight: 500,
    color: theme.palette.text.primary,
  },

  timePicker: {
    '& .MuiInternalClockNumber-inner': { color: 'inherit' },
    '& .Mui-disabled': { color: theme.palette.error.main },
  },
}));

const PracticeStep = () => {
  const { t } = useTranslation();

  const classes = useFormStyles();
  const { handleChange, getFieldProps, setFieldValue, errors, touched, values } =
    useFormikContext<PracticeDataForm>();

  return (
    <Grid container spacing={4} alignContent="flex-start">
      <Grid item xs={12} md={6} container spacing={2}>
        <Grid item xs={12} height={'38vh'} container spacing={2}>
          <Grid item xs={12}>
            <InputLabel>{t('students.applications.professional_practices.additional.role')}</InputLabel>
            <TextField
              fullWidth
              placeholder={t('students.applications.professional_practices.additional.role')}
              error={Boolean(errors.practicePosition && touched.practicePosition)}
              {...getFieldProps('practicePosition')}
            />
            {errors.practicePosition && touched.practicePosition && (
              <FormHelperText error>{errors.practicePosition}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={12}>
            <InputLabel>{t('students.applications.professional_practices.additional.start_date')}</InputLabel>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
              <DatePicker
                value={values.practiceStartDate}
                onChange={(newValue) => {
                  setFieldValue('practiceStartDate', newValue);
                  setFieldValue('practiceEndDate', newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    helperText=""
                    placeholder={t('students.applications.professional_practices.additional.start_date')}
                    error={Boolean(errors.practiceStartDate && touched.practiceStartDate)}
                    fullWidth
                  />
                )}
                minDate={new Date()}
              />
            </LocalizationProvider>
            {errors.practiceStartDate && touched.practiceStartDate && (
              <FormHelperText error>{errors.practiceStartDate}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={12}>
            <InputLabel>{t('students.applications.professional_practices.additional.end_date')}</InputLabel>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
              <DatePicker
                value={values.practiceEndDate}
                onChange={(newValue) => {
                  setFieldValue('practiceEndDate', newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    helperText=""
                    placeholder={t('students.applications.professional_practices.additional.end_date')}
                    error={Boolean(errors.practiceEndDate && touched.practiceEndDate)}
                    fullWidth
                  />
                )}
                minDate={values.practiceStartDate}
              />
            </LocalizationProvider>
            {errors.practiceEndDate && touched.practiceEndDate && (
              <FormHelperText error>{errors.practiceEndDate}</FormHelperText>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6} container spacing={2}>
        <Grid item xs={12} container spacing={2}>
          <Grid item xs={12}>
            <InputLabel>{t('students.applications.professional_practices.additional.days')}</InputLabel>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={handleChange}
                  defaultChecked={values.practiceHourSunday}
                  id="practiceHourSunday"
                />
              }
              label={t('shared.constants.datetime.day.sunday')}
              labelPlacement="end"
            />
            <FormControlLabel
              control={
                <Checkbox
                  onChange={handleChange}
                  defaultChecked={values.practiceHourMonday}
                  id="practiceHourMonday"
                />
              }
              label={t('shared.constants.datetime.day.monday')}
              labelPlacement="end"
            />
            <FormControlLabel
              control={
                <Checkbox
                  onChange={handleChange}
                  defaultChecked={values.practiceHourTuesday}
                  id="practiceHourTuesday"
                />
              }
              label={t('shared.constants.datetime.day.tuesday')}
              labelPlacement="end"
            />

            <FormControlLabel
              control={
                <Checkbox
                  onChange={handleChange}
                  defaultChecked={values.practiceHourWednesday}
                  id="practiceHourWednesday"
                />
              }
              label={t('shared.constants.datetime.day.wednesday')}
              labelPlacement="end"
            />
            <FormControlLabel
              control={
                <Checkbox
                  onChange={handleChange}
                  defaultChecked={values.practiceHourThursday}
                  id="practiceHourThursday"
                />
              }
              label={t('shared.constants.datetime.day.thursday')}
              labelPlacement="end"
            />
            <FormControlLabel
              control={
                <Checkbox
                  onChange={handleChange}
                  defaultChecked={values.practiceHourFriday}
                  id="practiceHourFriday"
                />
              }
              label={t('shared.constants.datetime.day.friday')}
              labelPlacement="end"
            />
            <FormControlLabel
              control={
                <Checkbox
                  onChange={handleChange}
                  defaultChecked={values.practiceHourSaturday}
                  id="practiceHourSaturday"
                />
              }
              label={t('shared.constants.datetime.day.saturday')}
              labelPlacement="end"
            />
          </Grid>
          {/* Hourhand */}
          {(values.practiceHourSunday ||
            values.practiceHourMonday ||
            values.practiceHourTuesday ||
            values.practiceHourWednesday ||
            values.practiceHourThursday ||
            values.practiceHourFriday ||
            values.practiceHourSaturday) && (
            <>
              <Grid item xs={12}>
                <Divider>{t('students.applications.professional_practices.additional.hourhand')}</Divider>
              </Grid>
              {/* <Grid item xs={12}>
                {errors.practiceHourSunday && touched.practiceHourSunday && (
                  <FormHelperText error>{errors.practiceHourSunday}</FormHelperText>
                )}
              </Grid> */}

              <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <StaticTimePicker
                      ampm={false}
                      className={classes.timePicker}
                      label={t('students.applications.professional_practices.additional.start_hour')}
                      orientation="landscape"
                      openTo="hours"
                      value={values.practiceHourStart}
                      onChange={(newValue) => {
                        setFieldValue(`practiceHourStart`, newValue);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <StaticTimePicker
                      ampm={false}
                      label={t('students.applications.professional_practices.additional.end_hour')}
                      orientation="landscape"
                      openTo="hours"
                      value={values.practiceHourEnd}
                      className={classes.timePicker}
                      minTime={
                        values.practiceHourStart &&
                        new Date(
                          0,
                          0,
                          0,
                          (values.practiceHourStart as unknown as Date).getHours() + 1,
                          (values.practiceHourStart as unknown as Date).getHours() + 1 ===
                          (values.practiceHourEnd as unknown as Date).getHours()
                            ? (values.practiceHourStart as unknown as Date).getMinutes()
                            : 59
                        )
                      }
                      maxTime={
                        values.practiceHourStart &&
                        // A la hora de inicio se le suma 6 horas como hora maxima y si pasa de las 18h se le asigna como hora maxima las 23h
                        new Date(
                          0,
                          0,
                          0,
                          (values.practiceHourStart as unknown as Date).getHours() < 18
                            ? (values.practiceHourStart as unknown as Date).getHours() + 9
                            : 23,
                          // Como la hora maxima no puede ser las 24h se le agrega 59 min a las 23h
                          (values.practiceHourStart as unknown as Date).getHours() >= 19
                            ? 59
                            : (values.practiceHourStart as unknown as Date).getHours() + 9 ===
                              (values.practiceHourEnd as unknown as Date).getHours()
                            ? (values.practiceHourStart as unknown as Date).getMinutes()
                            : 59
                        )
                      }
                      onChange={(newValue) => {
                        setFieldValue(`practiceHourEnd`, newValue);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </LocalizationProvider>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
export default PracticeStep;
