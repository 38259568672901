import * as Yup from 'yup';

export const ClassroomInfoSchema = Yup.object().shape({
  name: Yup.string().required('academic.classrooms.add.name.required'),
  description: Yup.string(),
  capacity: Yup.number()
    .moreThan(-1, 'academic.classrooms.add.capacity.more_than')
    .typeError('academic.classrooms.add.capacity.type')
    .integer('academic.classrooms.add.capacity.integer')
    .required('academic.classrooms.add.capacity.required')
    .min(1, 'academic.classrooms.add.capacity.min')
    .max(1000, 'academic.classrooms.add.capacity.max'),
  statusId: Yup.number().required('academic.classrooms.add.status.required'),
  campusId: Yup.number()
    .required('academic.classrooms.add.campus.required')
    .min(1, 'academic.classrooms.add.campus.required'),
  // block: Yup.string().required('academic.classrooms.add.block.required'),
  classroomTypeId: Yup.number()
    .required('academic.classrooms.add.classroom_type.required')
    .min(1, 'academic.classrooms.add.classroom_type.required'),
});
