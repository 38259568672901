import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../../../hooks/useRedux';
import MainCard from '../../../../../shared/components/cards/MainCard';
import EvaluationTypeDeleteDialog from '../components/EvaluationTypeDeleteDialog';
import EvaluationTypeSideDialog from '../components/EvaluationTypeSideDialog';
import EvaluationTypeTable from '../components/EvaluationTypeTable';

const EvaluationTypeScreen = () => {
  const { t } = useTranslation();

  const { evaluationTypeSideDialogOpen, evaluationTypeDeleteModalOpen } = useAppSelector(
    (state) => state.evaluationType
  );

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">{t('maintenance.evaluation_type.title')}</Typography>
        <Typography variant="body1" color="textSecondary">
          {t('Las rúbricas permiten definir el formato de la evaluación.')}
        </Typography>
      </Grid>
      <EvaluationTypeTable />

      {evaluationTypeSideDialogOpen && <EvaluationTypeSideDialog />}
      {evaluationTypeDeleteModalOpen && <EvaluationTypeDeleteDialog />}
    </Grid>
  );
};

export default EvaluationTypeScreen;
