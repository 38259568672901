import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/useRedux';
import MainCard from '../../../../../shared/components/cards/MainCard';
import CardViewButton from '../../../../../shared/components/tables/buttons/CardViewButton';
import TableViewButton from '../../../../../shared/components/tables/buttons/TableViewButton';
import EvaluationDeleteDialog from '../components/EvaluationDeleteDialog';
import EvaluationsList from '../components/EvaluationsList';
import EvaluationsTable from '../components/EvaluationsTable';
import { setEvaluationsListView } from '../slices/evaluationsSlice';

const EvaluationsScreen = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { evaluationsListView, evaluationsDeleteModalOpen } = useAppSelector(
    (state) => state.evaluations
  );

  const handleViewChange = (view: 'cards' | 'table') => {
    dispatch(setEvaluationsListView(view));
  };

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid
        item
        container
        alignItems="center"
        justifyContent="space-between"
        xs={12}
        sx={{ mb: 3 }}
      >
        <Grid item>
          <Typography variant="h2">{t('maintenance.evaluations.title')}</Typography>
        </Grid>
        <Grid item>
          <TableViewButton onClick={() => handleViewChange('table')} />
          <CardViewButton onClick={() => handleViewChange('cards')} />
        </Grid>
      </Grid>
      {evaluationsListView === 'table' && <EvaluationsTable />}
      {evaluationsListView === 'cards' && <EvaluationsList />}

      {evaluationsDeleteModalOpen && <EvaluationDeleteDialog />}
    </Grid>
  );
};

export default EvaluationsScreen;
