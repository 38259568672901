import { Grid, Typography } from '@material-ui/core';
import MainCard from '../../../shared/components/cards/MainCard';
import StudentCurriculum from '../components/Curriculum';

const MyCurriulumScreen = () => {
  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">{'Malla'}</Typography>
        {/* <Typography variant="body1">{t('students.courses.description')}</Typography> */}
      </Grid>
      <StudentCurriculum />
    </Grid>
  );
};

export default MyCurriulumScreen;
