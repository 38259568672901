import {
  Autocomplete,
  FormControlLabel,
  Grid,
  InputLabel,
  Switch,
  TextField,
} from '@material-ui/core';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/useRedux';
import { useTableSearch } from '../../../../../../hooks/useTableSearch';
import {
  useGetAcademicOfferingQuery,
  useGetAvailablesOffersQuery,
  useGetPeriodAcademicOfferingQuery,
} from '../../../../../maintenance/academic-offerings/slices/academicOfferingsApiSlice';
import LocalizedDatePicker from '../../../../../shared/components/controlled/LocalizedDatePicker';
import SearchPaginatedTableAndCollapsibleItems from '../../../../../shared/components/tables/SearchPaginatedTableAndCollapsibleItems';
import TextFieldFetching from '../../../../../shared/components/TextFieldFetching';
import { useGetAllEvaluationTypesQuery } from '../../evaluation_type/slices/evaluationTypeApiSlice';
import { CourseTeacher } from '../../form/interfaces/coursesListAll.interface';
import { useGetLaunchingResultsListQuery } from '../slices/launchingApiSlice';
import { setEnd_date, setLaunchingTable, setStart_date } from '../slices/launchingSlice';
import { Period } from '../../../../../maintenance/academic-offerings/interfaces/periods.interface';
import { useLazyGetCoursesListAllQuery } from '../../form/slices/formsApiSlice';
import CustomAsyncPaginatedSelect from '../../../../../shared/components/extended/Form/CustomAsyncPaginatedSelect';
import { useLazyGetCollaboratorsQuery } from '../../../../../hr/collaborators/slices/collaboratorsApiSlice';

const LaunchingResultsTable = () => {
  const dispatch = useAppDispatch();

  const [evaType, setEvaType] = useState<string | null>(null);

  const [collaboratorValue, setCollaboratorValue] = useState<any | null>(null);

  const [offerDescription, setOff_description] = useState('');

  const [evaluated, setEvaluated] = useState<CourseTeacher[]>([]);

  const [periodId, setPeriodId] = useState<number | null>(null);
  const [education_level_id, setEducation_level_id] = useState<number | null>(null);

  const [course_id, setCourse_id] = useState<number | null>(null);

  const [evaluated_id, setEvaluated_id] = useState<number | null>(null);

  const [evaCalificationOption, setEvaCalificationOption] = useState(false);

  const { currentProfile } = useAppSelector((state) => state.access);

  const { data: evaluationTypes, isLoading: isLoadingEvaluationTypes } =
    useGetAllEvaluationTypesQuery({});

  const [getCoursesListAll, { data: coursesListAll, isLoading: isLoadingCoursesListAll }] =
    useLazyGetCoursesListAllQuery();

  //filters

  //niveles eduactivos
  const {
    data: offersAvailables = [],
    isLoading: isLoadingPID,
    isFetching: isFetchingPID,
  } = useGetAvailablesOffersQuery(
    {
      profileId: currentProfile?.profileId!,
      off_description: '',
    },
    { refetchOnMountOrArgChange: 30 }
  );

  //periodos
  const {
    data: periodsByOffer = [],
    isLoading: isLoadingCID,
    isFetching: isFetchingCID,
  } = useGetPeriodAcademicOfferingQuery(
    {
      profileId: currentProfile?.profileId!,
      academicOfferingId:
        offersAvailables!
          .find((offer) => offer.off_description === offerDescription)
          ?.id?.toString() || '',
      current: true,
    },
    { skip: !offerDescription, refetchOnMountOrArgChange: 30 }
  );

  const periods = useMemo(() => {
    return Array.isArray(periodsByOffer)
      ? (periodsByOffer as unknown as Period[]).filter(
          (dt) => new Date(dt.end_process).getTime() > new Date().getTime()
        )
      : [];
  }, [periodsByOffer]);

  const {
    data: offerById,
    isLoading: isLoadingOID,
    isFetching: isFetchingOID,
  } = useGetAcademicOfferingQuery(
    {
      profileId: currentProfile?.profileId!,
      academicOfferingId:
        offersAvailables!.find((offer) => offer.off_description === offerDescription)?.id! || 0,
    },
    { skip: !offerDescription, refetchOnMountOrArgChange: 30 }
  );

  //filters

  const [
    getCollaboratorTrigger,
    // { data: institutesAvailables, isLoading: isLoadingInstitutesAvailables },
  ] = useLazyGetCollaboratorsQuery();

  //TODO: crear hook en el componente del select
  const getCollaborators = async (search: string, page: number, perPage: number) => {
    const response = await getCollaboratorTrigger({
      page,
      perPage,
      search,
      statusId: 1,
    }).unwrap();

    const hasMore = response.current_page < response.last_page;

    return {
      options: response.data.map((dt) => {
        return {
          value: dt.id,
          label: dt.user.person.pers_full_name || dt.user.person.pers_full_lastname,
          ...dt,
        };
      }),
      hasMore,
    };
  };

  const {
    launchingTable: { page, perPage: size, search },
    end_date,
    start_date,
  } = useAppSelector((state) => state.launching);

  const { t } = useTranslation();

  const { setPage, setPerPage, setSearch } = useTableSearch({
    page,
    perPage: size,
    search,
    updateFunction: setLaunchingTable,
  });

  const {
    data: launchingList,
    isFetching,
    isLoading,
    error,
  } = useGetLaunchingResultsListQuery({
    page,
    perPage: size,
    search,
    start_date: start_date.split('T')[0],
    end_date: end_date.split('T')[0],
    ...(evaType && { eva_type_id: evaType }),
    ...(evaluated_id && { evaluated_id: evaluated_id }),
    ...(collaboratorValue && { evaluated_id: collaboratorValue.user_id }),
    ...(evaCalificationOption && { eva_value: evaCalificationOption }),
    ...(periodId && { period_id: periodId }),
    ...(education_level_id && { education_level_id: education_level_id }),
  });

  const dataFetch =
    launchingList?.data.map(
      (
        { user, eva_release, course, total_estudiantes, total_evaluados, total_no_evaluados },
        index
      ) => ({
        numberRow: (page - 1) * size + (index + 1),
        name: user?.person?.pers_full_name || 'Sin nombre',
        materia: `${course?.period?.per_name} - ${course?.matter?.mat_name} - ${course?.parallel?.par_name}`,
        start_date: eva_release?.start_date,
        end_date: eva_release?.end_date,
        totalStudent: total_estudiantes,
        totalEvaluated: total_evaluados,
        totalNoEvaluated: total_no_evaluados,
      })
    ) || [];

  useEffect(() => {
    if (periodId && education_level_id) {
      getCoursesListAll({
        periodId: periodId,
        educationLevelId: education_level_id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periodId, education_level_id]);

  return (
    <>
      <Grid
        container
        item
        xs={12}
        spacing={2}
        sx={{
          mb: 2,
        }}
      >
        <Grid item xs={12} sm={4} md={4} lg={4}>
          <Grid container spacing={1} alignItems="flex-start">
            <Grid item xs zeroMinWidth>
              <LocalizedDatePicker
                value={start_date}
                onChange={(newValue) => {
                  dispatch(setStart_date((newValue as Date).toISOString()));
                }}
                label={t('financial.deposit_transfers.filters.dates.start_label')}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4}>
          <Grid container spacing={1} alignItems="flex-start">
            <Grid item xs zeroMinWidth>
              <LocalizedDatePicker
                value={end_date}
                onChange={(newValue) => {
                  dispatch(setEnd_date((newValue as Date).toISOString()));
                }}
                label={t('financial.deposit_transfers.filters.dates.end_label')}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        item
        xs={12}
        spacing={2}
        sx={{
          mb: 2,
        }}
      >
        <Grid item xs={12} sm={4} md={4} lg={4}>
          <InputLabel>{t('Rúbrica')}</InputLabel>
          {isLoadingEvaluationTypes ? (
            <TextField fullWidth placeholder={t('shared.filters.eva_type.loading')} disabled />
          ) : (
            <Autocomplete
              options={evaluationTypes || []}
              getOptionLabel={(option) => option.description}
              value={evaluationTypes?.find((evaT) => evaT.uuid === evaType) || null}
              onChange={(event, newValue) => {
                setEvaType(newValue?.uuid || null);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="medium"
                  fullWidth
                  placeholder={t('shared.filters.eva_type.placeholder')}
                />
              )}
            />
          )}
        </Grid>

        {!offerDescription && (
          <>
            <Grid item xs={6}>
              <InputLabel>Colaborador</InputLabel>
              <CustomAsyncPaginatedSelect
                key={'id'}
                value={collaboratorValue}
                isError={false}
                placeholder={'Escoger colaborador'}
                setValue={setCollaboratorValue}
                fetchFunc={getCollaborators}
              />
            </Grid>
          </>
        )}

        <Grid item xs={12} sm={4} md={4} lg={4}>
          <InputLabel>{t('academic.courses.edu_level')}:</InputLabel>
          {!isFetchingPID && (
            <Autocomplete
              // key={autocompleteKey.offer}
              options={offersAvailables}
              noOptionsText={t('academic.courses.edu_level.no_data')}
              loading={isLoadingPID}
              loadingText={t('academic.courses.edu_level.loading')}
              getOptionLabel={(option) => option.off_name}
              value={offersAvailables.find((dt) => dt.off_description === offerDescription) || null}
              renderInput={(params) => (
                <TextField {...params} placeholder={t('academic.courses.edu_level.placeholder')} />
              )}
              onChange={(_, value) => {
                if (value) {
                  setOff_description(value.off_description);
                } else {
                  setOff_description('');
                  setPeriodId(0);
                  setEducation_level_id(0);
                  setEvaluated([]);
                  setCourse_id(null);
                  setEvaluated_id(null);
                  setCollaboratorValue(null);
                }
              }}
              fullWidth
            />
          )}
          {isFetchingPID && <TextFieldFetching />}
        </Grid>

        {offerDescription && (
          <>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <InputLabel>{t('academic.courses.period')}:</InputLabel>
              {!isFetchingCID && (
                <Autocomplete
                  options={periods}
                  noOptionsText={t('academic.courses.period.no_data')}
                  loading={isLoadingCID}
                  loadingText={t('academic.courses.period.loading')}
                  disabled={!offerDescription}
                  getOptionLabel={(option) => option.per_name}
                  value={periods.find((dt) => dt.id === Number(periodId)) || null}
                  renderInput={(params) => (
                    <TextField {...params} placeholder={t('academic.courses.period.placeholder')} />
                  )}
                  onChange={(_, value) => {
                    if (value) {
                      setPeriodId(value?.id);
                    } else {
                      setPeriodId(0);
                    }
                  }}
                  fullWidth
                />
              )}
              {isFetchingCID && <TextFieldFetching />}
            </Grid>

            <Grid item xs={12} sm={4} md={4} lg={4}>
              <Grid item xs={8}>
                <InputLabel>{t('academic.courses.academic_unit')}:</InputLabel>
              </Grid>

              {!isFetchingOID && (
                <Autocomplete
                  // key={autocompleteKey.educationLvl}
                  options={offerById ? offerById!.education_levels : []}
                  noOptionsText={t('academic.courses.academic_unit.no_data')}
                  loading={isLoadingOID}
                  disabled={!periodId}
                  loadingText={t('academic.courses.academic_unit.loading')}
                  getOptionLabel={(option) => option.edu_name}
                  value={
                    offerById?.education_levels.find(
                      (dt) => dt.id === Number(education_level_id)
                    ) || null
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={t('academic.courses.academic_unit.placeholder')}
                    />
                  )}
                  onChange={(_, value) => {
                    if (value) {
                      setEducation_level_id(value?.id);
                    } else {
                      setEducation_level_id(0);
                    }
                  }}
                  fullWidth
                />
              )}
              {isFetchingOID && <TextFieldFetching />}
            </Grid>

            <Grid item xs={12} sm={4} md={4} lg={4}>
              <InputLabel>{t('Cursos')}</InputLabel>
              {isLoadingCoursesListAll || !periodId || !education_level_id ? (
                <TextField
                  fullWidth
                  placeholder={t('Seleccione un periodo y la unidad académica ')}
                  disabled
                />
              ) : (
                <Autocomplete
                  options={Array.isArray(coursesListAll) ? coursesListAll : []}
                  value={
                    Array.isArray(coursesListAll)
                      ? coursesListAll?.find((dt) => dt.id === Number(course_id))
                      : null
                  }
                  renderInput={(params) => (
                    <TextField {...params} placeholder={t('Selecciona un curso')} />
                  )}
                  getOptionLabel={(option) => option?.mat_name}
                  onChange={(_, value) => {
                    if (value) {
                      setEvaluated(value?.course_teacher!);
                      setCourse_id(value?.id!);
                    }
                  }}
                />
              )}
            </Grid>

            <Grid item xs={12} sm={4} md={4} lg={4}>
              <InputLabel>{t('Evaluado')}</InputLabel>
              {evaluated.length === 0 ? (
                <TextField
                  fullWidth
                  placeholder={t('Seleccione un curso para ver los evaluados')}
                  disabled
                />
              ) : (
                <Autocomplete
                  options={evaluated}
                  value={
                    evaluated.find(
                      (dt) => Number(dt.collaborator.user_id) === Number(evaluated_id)
                    ) || null
                  }
                  renderInput={(params) => (
                    <TextField {...params} placeholder={t('Seleccione una evaluado')} />
                  )}
                  getOptionLabel={(option) => option?.collaborator.user.person.pers_full_name!}
                  onChange={(_, value) => {
                    if (value) {
                      setEvaluated_id(Number(value?.collaborator.user_id!));
                    } else {
                      setEvaluated_id(null);
                    }
                  }}
                />
              )}
            </Grid>
          </>
        )}

        <Grid item xs={6}>
          <InputLabel>{t('Calificado')}</InputLabel>
          <FormControlLabel
            control={<Switch onChange={(e) => setEvaCalificationOption(e.target.checked)} />}
            value={evaCalificationOption}
            label={evaCalificationOption ? t('Si') : t('No')}
          />
        </Grid>
      </Grid>

      {evaType && (
        <SearchPaginatedTableAndCollapsibleItems
          data={dataFetch}
          headers={{
            numberRow: t('N°'),
            name: t('Nombre'),
            materia: t('Curso'),
            start_date: t('Fecha de inicio'),
            end_date: t('Fecha de fin'),
            totalStudent: t('Total de estudiantes'),
            totalEvaluated: t('Total de estudiantes evaluados'),
            totalNoEvaluated: t('Total de estudiantes no evaluados'),
          }}
          keyExtractor={(item) => `${item.name} - ${item.numberRow}`}
          searchPlacehoder={t('Buscar grupo...')}
          isLoading={isLoading}
          isFetching={isFetching}
          error={error}
          perPage={size}
          setPerPage={setPerPage}
          total={launchingList?.total || 0}
          page={page}
          setPage={setPage}
          numHeader={10}
          search={search}
          setSearch={setSearch}
          customDataCellsProperties={{
            totalStudent: {
              align: 'center',
            },
            totalEvaluated: {
              align: 'center',
            },
            totalNoEvaluated: {
              align: 'center',
            },
          }}
        />
      )}
    </>
  );
};

export default LaunchingResultsTable;
