import { mainApi } from '../../../../api/mainApi';
import { middlewareApi } from '../../../middleware/middlewareApi';
import {
  AcademicRequestsResponse,
  StudentsPeriods,
} from '../interfaces/academicRequests.interfaces';
import { InternalHomologationsPayload } from '../interfaces/changeCareer.interfaces';
import { StatusRequestResponse } from '../interfaces/statusRequests.interfaces';
import { InternalHomologationResponse } from '../interfaces/internalHomologations.interfaces';
import {
  CoursesAvaliable,
  SubjectCurriculum,
  ThirdRegistrationPayload,
} from '../interfaces/thirdTimeRegistration.interfaces';
import { StudentRecordsResponse } from '../interfaces/studentRecords.interfaces';
import { Subject } from '../interfaces/subject.interfaces';
import { setAcademicRequestsList } from '../slices/academicRequestsSlice';
import { OtherAcademicRequestPayload } from '../interfaces/otherAcademicRequest.interfaces';
import {
  Requalification,
  RequalificationRequestPayload,
  Supplies,
} from '../interfaces/requalification.interface';
import { PracticeRequestPayload } from '../interfaces/practiceRequest.interfaces';
import {
  SubjectWithDrawal,
  SubjectWithDrawalPayload,
} from '../interfaces/subjectWithDrawal.interfaces';
import { BondingPayload, BondingProgramsListResponse } from '../interfaces/bonding.interfaces';
import { MissedExamPayload, SubjectsAvailable } from '../interfaces/missedExam.interfaces';
import { PaymentAgreementPayload } from '../interfaces/paymentAgreement.interface';
import { RecoveryExam, RecoveryExamPayload } from '../interfaces/recoveryExam.interfaces';
import {
  ArrayfileUploadResponse,
  Period,
  TitulationPayload,
} from '../interfaces/titulation.interfaces';
import { PracticesValidation } from '../interfaces/practicesValidation.interfaces';
import { ApprovePracticesPayload } from '../interfaces/approvePractices.interfaces';
import { LinksValidation } from '../interfaces/linksValidation.interfaces';
import { AccessState } from '../../../../types';

export const academicRequestsTags = middlewareApi.enhanceEndpoints({
  addTagTypes: ['AcademicRequests', 'SubjectAproved', 'SubjectNews', 'Applications'],
});
export const academicRequestsApi = academicRequestsTags.injectEndpoints({
  endpoints: (builder) => ({
    /* consulta general de solicitudes*/
    /**
     * @GET all academic-requests
     */
    getAcademicRequests: builder.query<
      AcademicRequestsResponse,
      {
        profileId: number;
        userId: string;
        page: number;
        perPage: number;
        search: string;
        acronym?: string;
        startDate?: string;
        endDate?: string;
        status?: string;
        sort?: string;
        type_sort?: string;
      }
    >({
      queryFn: async (
        {
          profileId,
          userId,
          page,
          perPage,
          search,
          acronym,
          startDate,
          endDate,
          status,
          sort,
          type_sort,
        },
        { dispatch }
      ) => {
        try {
          const { data } = await mainApi.get<AcademicRequestsResponse>(
            `/application/${userId}/get-user-applications`,
            {
              params: {
                user_profile_id: profileId,
                page,
                size: perPage,
                search,
                acronym: acronym || '',
                start_date: startDate || undefined,
                end_date: endDate || undefined,
                status: status || undefined,
                ...(type_sort && { type_sort }),
                ...(sort && { sort }),
              },
            }
          );
          dispatch(setAcademicRequestsList(data.data));
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'AcademicRequests', id } as const)),
              { type: 'AcademicRequests', id: 'LIST' },
            ]
          : [{ type: 'AcademicRequests', id: 'LIST' }],
    }),

    /*Solicitudes cambio de carrera*/
    /**
     * @GET all student records by student-code
     */
    getStudentRecords: builder.query<
      StudentRecordsResponse,
      { profileId: number; stud_code: string }
    >({
      queryFn: async ({ profileId, stud_code }) => {
        try {
          const { data } = await mainApi.get<StudentRecordsResponse>(
            `/students/show-student-records/${stud_code}`,
            {
              params: {
                user_profile_id: profileId,
              },
            }
          );
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      //invalidatesTags: [{ type: 'SubjectAproved', id: 'LIST' }],
    }),

    /**
     * @POST save internalHomologation
     */
    addInternalHomologation: builder.mutation<
      string,
      {
        profileId: number;
        studCode: string;
        userId: string;
        roleId: number;
        internalHomologationPayload: Partial<InternalHomologationsPayload>;
      }
    >({
      queryFn: async ({ profileId, userId, studCode, roleId, internalHomologationPayload }) => {
        try {
          const { data } = await mainApi.post<string>('/applications/internal-homologations', {
            user_profile_id: profileId,
            user_id: userId,
            stud_code: studCode,
            role_id: roleId,
            ...internalHomologationPayload,
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'AcademicRequests', id: 'LIST' }],
    }),

    /**
     * @POST save practice approve
     */
    addApprovePractice: builder.mutation<
      string,
      {
        profileId?: number;
        userId: string;
        approvePracticesPayload: ApprovePracticesPayload;
      }
    >({
      queryFn: async ({ profileId, userId, approvePracticesPayload }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = profileId || currentProfile?.profileId;

          const { data } = await mainApi.post<string>('/applications/approve-practices', {
            user_profile_id,
            user_id: userId,
            ...approvePracticesPayload,
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'AcademicRequests', id: 'LIST' }],
    }),

    /**
     * @GET all subjects in the current career by studentRecord
     */
    getSubjectByStudentRecord: builder.query<
      Subject[],
      { profileId: number; studentRecords: number }
    >({
      queryFn: async ({ profileId, studentRecords }) => {
        try {
          const { data } = await mainApi.get<Subject[]>(
            `/students/student-record/${studentRecords}/subject-approved`,
            {
              params: {
                user_profile_id: profileId,
              },
            }
          );
          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ matter_id }) => ({ type: 'SubjectAproved', matter_id } as const)),
              { type: 'SubjectAproved', id: 'LIST' },
            ]
          : [{ type: 'SubjectAproved', id: 'LIST' }],
    }),
    /**
     * @GET all subjects in the new career by education-level-id
     */
    getSubjectByEducationLevel: builder.query<
      InternalHomologationResponse,
      { profileId: number; educationLevel: number; studentRecordId: number }
    >({
      queryFn: async ({ profileId, educationLevel, studentRecordId }) => {
        try {
          const { data } = await mainApi.get<InternalHomologationResponse>(
            `education-levels/${educationLevel}/internal-homologation-subject-curriculum`,
            {
              params: {
                user_profile_id: profileId,
                student_record_id: studentRecordId,
              },
            }
          );
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'SubjectNews', id } as const)),
              { type: 'SubjectNews', id: 'LIST' },
            ]
          : [{ type: 'SubjectNews', id: 'LIST' }],
    }),

    /**
     * @DELETE delete internal homologation
     */
    deleteInternalHomologation: builder.mutation<
      string,
      { profileId: number; applicationbId: number }
    >({
      queryFn: async ({ profileId, applicationbId }) => {
        try {
          const { data } = await mainApi.delete(`application/${applicationbId}`, {
            data: {
              user_profile_id: profileId,
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'AcademicRequests', id: 'LIST' }],
    }),

    /**
     * @GET request information by application_code
     */
    getRequestInformationByAppCode: builder.query<
      StatusRequestResponse,
      { profileId: number; appCode: string }
    >({
      queryFn: async ({ profileId, appCode }) => {
        try {
          const { data } = await mainApi.get<StatusRequestResponse>(
            `/application/show-application-status/${appCode}`,
            {
              params: {
                user_profile_id: profileId,
                type_sort: 'asc',
              },
            }
          );
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    /**
     * @GET practice validation
     */
    getPracticesValidation: builder.query<
      PracticesValidation,
      { profileId?: number; studentRecordId: number }
    >({
      queryFn: async ({ profileId, studentRecordId }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = profileId || currentProfile?.profileId;

          const { data } = await mainApi.get<PracticesValidation>(
            `/applications/practices/student-record/${studentRecordId}/validations`,
            {
              params: {
                user_profile_id,
              },
            }
          );
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    /**
     * @GET vinculacion validation
     */
    getLinksValidation: builder.query<
      LinksValidation[],
      { profileId?: number; studentRecordId: number }
    >({
      queryFn: async ({ profileId, studentRecordId }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = profileId || currentProfile?.profileId;

          const { data } = await mainApi.get<LinksValidation[]>(
            `/applications/links/check-requisites`,
            {
              params: {
                student_record_id: studentRecordId,
                user_profile_id,
              },
            }
          );
          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    /**
     * @GET all subject curriculums by studentId
     */
    getSubjectCurriculumsByStudentId: builder.query<
      SubjectCurriculum[],
      { profileId: number; studentId: number }
    >({
      queryFn: async ({ profileId, studentId }) => {
        try {
          const { data } = await mainApi.get<SubjectCurriculum[]>(
            `/applications/student/${studentId}/get-failed-matters`,
            {
              params: {
                user_profile_id: profileId,
              },
            }
          );
          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    /*Solicitudes 3ra matricula*/
    /**
     * @POST save third registration
     */
    addThirdRegistration: builder.mutation<
      string,
      {
        profileId: number;
        userId: string;
        roleId: number;
        thirdRegistrationPayload: Partial<ThirdRegistrationPayload>;
      }
    >({
      queryFn: async ({ profileId, userId, roleId, thirdRegistrationPayload }) => {
        try {
          const { data } = await mainApi.post<string>('/applications/third-registration', {
            user_profile_id: profileId,
            user_id: userId,
            role_id: roleId,
            ...thirdRegistrationPayload,
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'AcademicRequests', id: 'LIST' }],
    }),
    /**
     * @GET all Course availablsz by MatterId
     */
    getCoursesAvaliableByMatterId: builder.query<
      CoursesAvaliable[],
      { profileId: number; studentId: number; matterId: string }
    >({
      queryFn: async ({ profileId, studentId, matterId }) => {
        try {
          const { data } = await mainApi.get<CoursesAvaliable[]>(
            `applications/subject/${matterId}/student/${studentId}/get-available-courses`,
            {
              params: {
                user_profile_id: profileId,
              },
            }
          );
          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    /*Solicitudes especiales*/
    /**
     * @POST save especial request
     */
    addOtherRequest: builder.mutation<
      string,
      {
        profileId: number;
        userId: string;
        otherAcademicRequestPayload: OtherAcademicRequestPayload;
      }
    >({
      queryFn: async ({ profileId, userId, otherAcademicRequestPayload }) => {
        try {
          let photoPath = '';
          if (otherAcademicRequestPayload.files) {
            const form = new FormData();
            form.append('files[]', otherAcademicRequestPayload.files);
            form.append('type_document', String(otherAcademicRequestPayload.type_document));
            //form.append('period', '1')
            form.append('user_profile_id', String(profileId));

            const { data: dataPhoto } = await mainApi.post<string>(
              '/applications/others/upload/files',
              form,
              {
                headers: { 'Content-Type': 'multipart/form-data' },
              }
            );
            photoPath = dataPhoto;
          }
          const { data } = await mainApi.post<string>('/applications/others', {
            user_profile_id: profileId,
            user_id: userId,
            certificate_url: photoPath,
            ...otherAcademicRequestPayload,
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'AcademicRequests', id: 'LIST' }],
    }),

    /*Practicas preprofesionales*/
    /**
     * @POST save especial request
     */
    addPractice: builder.mutation<
      string,
      {
        profileId: number;
        userId: string;
        practiceRequestPayload: PracticeRequestPayload;
      }
    >({
      queryFn: async ({ profileId, userId, practiceRequestPayload }) => {
        try {
          let filePath = '';
          if (practiceRequestPayload.files) {
            const form = new FormData();
            form.append('files[]', practiceRequestPayload.files);
            form.append('type_document', String(practiceRequestPayload.type_document));
            //form.append('period', '1');
            form.append('user_profile_id', String(profileId));

            const { data: dataPath } = await mainApi.post<string>(
              '/applications/others/upload/files',
              form,
              {
                headers: { 'Content-Type': 'multipart/form-data' },
              }
            );
            filePath = dataPath;
          }

          const { data } = await mainApi.post<string>('/applications/practices', {
            user_profile_id: profileId,
            user_id: userId,
            file_url: filePath,
            ...practiceRequestPayload,
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'AcademicRequests', id: 'LIST' }],
    }),

    /*Practicas preprofesionales*/
    /**
     * @PUT save especial request
     */
    updatePractice: builder.mutation<
      string,
      {
        profileId: number;
        appId: number;
        practiceRequestPayload: Partial<PracticeRequestPayload>;
      }
    >({
      queryFn: async ({ profileId, practiceRequestPayload, appId }) => {
        try {
          const { data } = await mainApi.put<string>(`/applications/practices/${appId}`, {
            user_profile_id: profileId,
            ...practiceRequestPayload,
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: (_, __, { appId }) => [
        { type: 'AcademicRequests', id: 'LIST' },
        {
          type: 'Applications',
          id: appId,
        },
        {
          type: 'Applications',
          id: 'LIST',
        },
      ],
    }),

    /*retiro de materia*/
    /**
     * @GET all subject curriculums by studentId
     */
    getRetirementSubjectsByStudentId: builder.query<
      SubjectWithDrawal[],
      { profileId: number; studentId: number; periodId: number }
    >({
      queryFn: async ({ profileId, studentId, periodId }) => {
        try {
          const { data } = await mainApi.get<SubjectWithDrawal[]>(
            `applications/student-record/${studentId}/retirement-subjects`,
            {
              params: {
                user_profile_id: profileId,
                period_id: periodId,
              },
            }
          );
          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
    }),
    /**
     * @POST save retirement subjects
     */
    addRetirementSubjects: builder.mutation<
      string,
      {
        profileId: number;
        userId: string;
        studentRecordId: number;
        subjectWithDrawalPayload: Partial<SubjectWithDrawalPayload>;
      }
    >({
      queryFn: async ({ profileId, userId, studentRecordId, subjectWithDrawalPayload }) => {
        try {
          let filePath = '';
          if (subjectWithDrawalPayload.files) {
            const form = new FormData();
            form.append('files[]', subjectWithDrawalPayload.files);
            //form.append('type_document', String(practiceRequestPayload.type_document));
            //form.append('period', '1');
            form.append('user_profile_id', String(profileId));

            const { data: dataPath } = await mainApi.post<string>(
              '/applications/others/upload/files',
              form,
              {
                headers: { 'Content-Type': 'multipart/form-data' },
              }
            );
            filePath = dataPath;
          }
          const { data } = await mainApi.post<string>('/applications/subject-retirement', {
            user_profile_id: profileId,
            user_id: userId,
            url_document: filePath,
            student_record_id: studentRecordId,
            ...subjectWithDrawalPayload,
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'AcademicRequests', id: 'LIST' }],
    }),

    /* Recalificacion */
    /**
     * @GET all subject calificated
     */
    getSubjectsCalificated: builder.query<
      Requalification[],
      { profileId: number; studentRecordId: number; periodId: number }
    >({
      queryFn: async ({ profileId, studentRecordId, periodId }) => {
        try {
          const { data } = await mainApi.get<Requalification[]>(
            `applications/student-record/${studentRecordId}/calificated-subjects`,
            {
              params: {
                user_profile_id: profileId,
                period_id: periodId,
              },
            }
          );
          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    /* External homologation */
    /**
     * @Post update record student file external homologation
     */
    updateRecordStudentFile: builder.mutation<
      any,
      { profileId?: number; applicationId: number; filePath: string }
    >({
      queryFn: async ({ profileId, applicationId, filePath }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = profileId || currentProfile?.profileId;

          const { data } = await mainApi.post<any>(
            `applications/external/homologations/${applicationId}/uploads/files`,
            {
              user_profile_id,
              url_file: filePath,
            }
          );
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    /**
     * @GET all supplies(partial) by courseStudent
     */
    getPartialByCourseStudent: builder.query<
      Supplies[],
      { profileId: number; courseStudentId: number }
    >({
      queryFn: async ({ profileId, courseStudentId }) => {
        try {
          const { data } = await mainApi.get<Supplies[]>(
            `applications/course-student/${courseStudentId}/supplies`,
            {
              params: {
                user_profile_id: profileId,
              },
            }
          );
          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    /**
     * @POST save requalification request
     */
    addRequalificationRequest: builder.mutation<
      string,
      {
        profileId: number;
        userId: string;
        requalificationRequestPayload: RequalificationRequestPayload;
      }
    >({
      queryFn: async ({ profileId, userId, requalificationRequestPayload }) => {
        try {
          const { data } = await mainApi.post<string>('/applications/requalification', {
            user_profile_id: profileId,
            user_id: userId,
            ...requalificationRequestPayload,
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'AcademicRequests', id: 'LIST' }],
    }),

    /* Vinculacion */
    /**
     * @POST save bonding request
     */
    addBondingRequest: builder.mutation<
      string,
      {
        profileId: number;
        userId: string;
        studentRecordId: number;
        bondingPayload: BondingPayload;
      }
    >({
      queryFn: async ({ profileId, userId, studentRecordId, bondingPayload }) => {
        try {
          let filePath = '';
          if (bondingPayload.files) {
            const form = new FormData();
            form.append('files[]', bondingPayload.files);
            form.append('type_document', String(bondingPayload.type_document));
            //form.append('period', '1')
            form.append('user_profile_id', String(profileId));

            const { data: dataFile } = await mainApi.post<string>(
              '/applications/others/upload/files',
              form,
              {
                headers: { 'Content-Type': 'multipart/form-data' },
              }
            );
            filePath = dataFile;
          }
          const { data } = await mainApi.post<string>('/applications/links', {
            user_profile_id: profileId,
            user_id: userId,
            student_record_id: studentRecordId,
            project_pdf_url: filePath,
            ...bondingPayload,
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'AcademicRequests', id: 'LIST' }],
    }),

    /* Missed examn */
    /**
     * @GET all missed exam subjects  by studentRecord
     */
    getMissedExamByStudentRecord: builder.query<
      SubjectsAvailable[],
      { profileId: number; studentRecordId: number; periodId: number }
    >({
      queryFn: async ({ profileId, studentRecordId, periodId }) => {
        try {
          const { data } = await mainApi.get<SubjectsAvailable[]>(
            `applications/late-exam/student-record/${studentRecordId}`,
            {
              params: {
                user_profile_id: profileId,
                period_id: periodId,
              },
            }
          );
          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    /**
     * @POST save missed exam request
     */
    addMissedExamRequest: builder.mutation<
      string,
      {
        profileId: number;
        userId: string;
        missedExamPayload: MissedExamPayload;
      }
    >({
      queryFn: async ({ profileId, userId, missedExamPayload }) => {
        try {
          let filePath = '';
          if (missedExamPayload.files) {
            const form = new FormData();
            form.append('files[]', missedExamPayload.files);
            form.append('user_profile_id', String(profileId));

            const { data: dataFile } = await mainApi.post<string>(
              '/applications/others/upload/files',
              form,
              {
                headers: { 'Content-Type': 'multipart/form-data' },
              }
            );
            filePath = dataFile;
          }
          const { data } = await mainApi.post<string>('/applications/late-exam', {
            user_profile_id: profileId,
            user_id: userId,
            url_document: filePath,
            ...missedExamPayload,
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'AcademicRequests', id: 'LIST' }],
    }),

    /* Payment aggrement */
    /**
     * @POST save payment aggrement request
     */
    addPaymentAggrementRequest: builder.mutation<
      string,
      {
        profileId: number;
        userId: string;
        studentRecordId: number;
        paymentAgreementPayload: PaymentAgreementPayload;
      }
    >({
      queryFn: async ({ profileId, userId, studentRecordId, paymentAgreementPayload }) => {
        try {
          const { data } = await mainApi.post<string>('/applications/payment-agreement', {
            user_profile_id: profileId,
            user_id: userId,
            student_record_id: studentRecordId,
            ...paymentAgreementPayload,
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'AcademicRequests', id: 'LIST' }],
    }),

    /* Recovery examn */
    /**
     * @GET all recovery exams  by studentRecord
     */
    getRecoveryExamByStudentRecord: builder.query<
      RecoveryExam[],
      { profileId: number; studentRecordId: number; periodId: number }
    >({
      queryFn: async ({ profileId, studentRecordId, periodId }) => {
        try {
          const { data } = await mainApi.get<RecoveryExam[]>(
            `applications/recovery-exam/student-record/${studentRecordId}`,
            {
              params: {
                user_profile_id: profileId,
                period_id: periodId,
              },
            }
          );
          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    /**
     * @POST save recovery exam request
     */
    addRecoveryExamRequest: builder.mutation<
      string,
      {
        profileId: number;
        userId: string;
        studentRecordId: number;
        recoveryExamPayload: RecoveryExamPayload;
      }
    >({
      queryFn: async ({ profileId, userId, studentRecordId, recoveryExamPayload }) => {
        try {
          const { data } = await mainApi.post<string>('/applications/recovery-exam', {
            user_profile_id: profileId,
            user_id: userId,
            student_record_id: studentRecordId,
            ...recoveryExamPayload,
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'AcademicRequests', id: 'LIST' }],
    }),

    /* Titulation*/
    /**
     * @GET periods
     */
    getPeriodsTitulation: builder.query<Period[], { profileId: number }>({
      queryFn: async ({ profileId }) => {
        try {
          const { data } = await mainApi.get<Period[]>(`/applications/academic-degree/periods`, {
            params: {
              user_profile_id: profileId,
            },
          });
          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    /**
     * @POST save titulation request
     */
    addTitulationRequest: builder.mutation<
      string,
      {
        profileId: number;
        userId: string;
        studentRecordId: number;
        titulationPayload: TitulationPayload;
      }
    >({
      queryFn: async ({ profileId, userId, studentRecordId, titulationPayload }) => {
        try {
          let response: ArrayfileUploadResponse[] = null!;
          if (
            titulationPayload.si &&
            titulationPayload.cc &&
            titulationPayload.cp &&
            titulationPayload.ca
          ) {
            const form = new FormData();
            form.append('files[]', titulationPayload.si);
            form.append('files[]', titulationPayload.cc);
            form.append('files[]', titulationPayload.cp);
            form.append('files[]', titulationPayload.ca);
            form.append('user_profile_id', String(profileId));
            form.append('type_document', '9');

            const { data: dataFile } = await mainApi.post<ArrayfileUploadResponse[]>(
              '/upload',
              form,
              {
                headers: { 'Content-Type': 'multipart/form-data' },
              }
            );
            response = dataFile;
          }

          const { data } = await mainApi.post<string>('/applications/academic-degree', {
            user_profile_id: profileId,
            user_id: userId,
            student_record_id: studentRecordId,
            app_description: titulationPayload.app_description,
            period_id: titulationPayload.period_id,
            url_document: response.map((item) => item.route),
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'AcademicRequests', id: 'LIST' }],
    }),

    /**
     * @GET all academic-requests
     */
    getBondingList: builder.query<
      BondingProgramsListResponse,
      {
        profileId?: number;
        studentRecordId: number;
        page: number;
        perPage: number;
        search: string;
      }
    >({
      queryFn: async ({ profileId, studentRecordId, page, perPage, search }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = profileId || currentProfile?.profileId;

          const { data } = await mainApi.get<BondingProgramsListResponse>(
            `/applications/links/list-projects`,
            {
              params: {
                student_record_id: studentRecordId,
                user_profile_id,
                page,
                size: perPage,
                type_sort: 'desc',
                search,
              },
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      // providesTags: (result) =>
      //   result
      //     ? [
      //         ...result.data.map(({ id }) => ({ type: 'AcademicRequests', id } as const)),
      //         { type: 'AcademicRequests', id: 'LIST' },
      //       ]
      //     : [{ type: 'AcademicRequests', id: 'LIST' }],
    }),

    /**
     * @GET all academic-requests
     */
    getStudentPeriods: builder.query<
      StudentsPeriods[],
      {
        profileId?: number;
        studentRecordId: number;
      }
    >({
      queryFn: async ({ profileId, studentRecordId }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = profileId || currentProfile?.profileId;

          const { data } = await mainApi.get<StudentsPeriods[]>(
            `student-record/${studentRecordId}/periods`,
            {
              params: {
                user_profile_id,
              },
            }
          );

          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
      // providesTags: (result) =>
      //   result
      //     ? [
      //         ...result.data.map(({ id }) => ({ type: 'AcademicRequests', id } as const)),
      //         { type: 'AcademicRequests', id: 'LIST' },
      //       ]
      //     : [{ type: 'AcademicRequests', id: 'LIST' }],
    }),

    //
  }),
});
export const {
  useGetAcademicRequestsQuery,
  useGetStudentRecordsQuery,
  useAddInternalHomologationMutation,
  useGetSubjectByStudentRecordQuery,
  useGetSubjectByEducationLevelQuery,
  useDeleteInternalHomologationMutation,
  useGetRequestInformationByAppCodeQuery,
  useAddOtherRequestMutation,
  useGetSubjectCurriculumsByStudentIdQuery,
  useAddThirdRegistrationMutation,
  useGetCoursesAvaliableByMatterIdQuery,
  useAddPracticeMutation,
  useGetRetirementSubjectsByStudentIdQuery,
  useAddRetirementSubjectsMutation,
  useGetSubjectsCalificatedQuery,
  useGetPartialByCourseStudentQuery,
  useAddRequalificationRequestMutation,
  useAddBondingRequestMutation,
  useGetMissedExamByStudentRecordQuery,
  useAddMissedExamRequestMutation,
  useAddPaymentAggrementRequestMutation,
  useGetRecoveryExamByStudentRecordQuery,
  useAddRecoveryExamRequestMutation,
  useGetPeriodsTitulationQuery,
  useAddTitulationRequestMutation,

  useUpdateRecordStudentFileMutation,
  useGetBondingListQuery,
  useGetStudentPeriodsQuery,

  useGetPracticesValidationQuery,
  useAddApprovePracticeMutation,

  useUpdatePracticeMutation,
  useGetLinksValidationQuery,
} = academicRequestsApi;
