import downloadjs from 'downloadjs';
import { Box, Grid, IconButton, Tooltip, Typography, useTheme } from '@material-ui/core';
import { CloseOutlined, InsertDriveFileOutlined, FileDownload } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import useGetUrlDownload from '../../../../hooks/useGetUrlDownload';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { useGetDocuementsByBinnancleQuery } from '../slices/followUpOfResearchProjectApiSlice';
import {
  setDeleteDocumentDialog,
  setDocumentIdByBinnacle,
} from '../slices/followUpOfResearchProjectSlice';

const DocumentsBinnancle = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { urlDownload } = useGetUrlDownload();

  const { binnacleSelected } = useAppSelector((state) => state.followUpOfResearch);
  const { data: documents, isError } = useGetDocuementsByBinnancleQuery({
    studentProjectResearchDetails: binnacleSelected?.id!,
  });

  const handleDownload = (urlFile: string) => {
    const url = `${urlDownload}/${urlFile}`;
    try {
      downloadjs(url);
    } catch (error) {
      console.log(Error);
    }
  };

  return (
    <Grid>
      {!isError && documents && Array.isArray(documents) && documents.length > 0 ? (
        documents?.map((document) => (
          <Grid
            sx={{
              mb: 2,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <IconButton
                sx={{
                  position: 'relative',
                  top: 0,
                  left: '40%',
                }}
                onClick={() => {
                  dispatch(setDeleteDocumentDialog(true));
                  dispatch(setDocumentIdByBinnacle(document.id));
                }}
              >
                <CloseOutlined
                  sx={{
                    color: theme.palette.error.main,
                    fontSize: '1.2rem',
                  }}
                />
              </IconButton>
              <InsertDriveFileOutlined
                sx={{
                  fontSize: '25px',
                  mb: 1,
                }}
              />

              <Typography
                sx={{
                  fontSize: '0.85rem',
                  ml: '3px',
                  fontWeight: 'bold',
                }}
              >
                {document.doc_name_file}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
              }}
            >
              <Tooltip title={t('Descargar')}>
                <IconButton
                  onClick={() => {
                    handleDownload(document.doc_url);
                  }}
                >
                  <FileDownload />
                </IconButton>
              </Tooltip>
            </Box>
          </Grid>
        ))
      ) : (
        <Grid mt={3}>
          <Typography
            sx={{
              fontSize: '0.85rem',
              ml: '3px',
              fontWeight: 'bold',
              textAlign: 'center',
            }}
          >
            {t('No hay documentos asociados a esta bitácora')}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default DocumentsBinnancle;
