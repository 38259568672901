import { Grid /*, Typography*/ } from '@material-ui/core';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import MainCardForm from '../../../shared/components/cards/MainCardForm';
import { setSidebarOpened } from '../../../shared/reducers/customizationReducer';
import { useEffect } from 'react';
import ConductThirdRegistrationForm from '../components/ConductThirdRegistrationForm';
import DebitCertificateDialog from '../components/DebitCertificateDialog';

const ConductThirdRegistrationScreen = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(setSidebarOpened(false));
  }, [dispatch]);
  const { certificateToDebit } = useAppSelector((state) => state.certificate);
  return (
    <MainCardForm>
      <Grid item xs={12} md={12} lg={12} sx={{ mt: 2 }}>
        <ConductThirdRegistrationForm />
      </Grid>
      {certificateToDebit && <DebitCertificateDialog />}
    </MainCardForm>
  );
};
export default ConductThirdRegistrationScreen;
