import {
  Autocomplete,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useAppSelector } from '../../../../hooks/useRedux';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import { useGetStudyModalitiesQuery } from '../../meshs/slices/meshsApiSlice';
import { useGetVigentsHourhandsQuery } from '../../periods/slices/periodsApiSlice';
import {
  useAddPosgraduatePlanningHourhandMutation,
  useUpdatePosgraduatePlanningHourhandMutation,
} from '../slices/posgraduatePlanningHourhandsApiSlice';

const PosgraduatePlanningHourhandsForm = () => {
  const { currentProfile } = useAppSelector((state) => state.access);

  const { posgraduatePlanningHourhandsList, posgraduatePlanningHourhandIdSelected } =
    useAppSelector((state) => state.posgraduatePlanningHourhands);

  const posgraduateHourhand = posgraduatePlanningHourhandsList.find(
    (item) => item.id === posgraduatePlanningHourhandIdSelected
  );

  const [showAlert, setShowAlert] = useState(false);

  const { data: modalities = [], isLoading: isLoadingModalities } = useGetStudyModalitiesQuery(
    {
      profileId: currentProfile?.profileId!,
    },
    {
      skip: !currentProfile?.profileId,
    }
  );

  const { data: hourhands = [], isLoading: isLoadingHourhands } = useGetVigentsHourhandsQuery(
    {
      profileId: currentProfile?.profileId!,
    },
    {
      skip: !currentProfile?.profileId,
    }
  );

  const [
    addHourhand,
    {
      isLoading: isLoadingAddHourhand,
      isSuccess: isSuccessAdd,
      isError: isErrorAdd,
      error: errorAdd,
    },
  ] = useAddPosgraduatePlanningHourhandMutation();
  const [
    updateHourhand,
    {
      isLoading: isLoadingUpdateHourhand,
      isSuccess: isSuccessUpdate,
      isError: isErrorUpdate,
      error: errorUpdate,
    },
  ] = useUpdatePosgraduatePlanningHourhandMutation();

  const { values, errors, touched, getFieldProps, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      offerDescription: 'oferta_postgrado',
      modalityId: posgraduateHourhand?.modality_id ? Number(posgraduateHourhand?.modality_id) : 0,
      hourhandId: posgraduateHourhand?.hourhand_id ? Number(posgraduateHourhand?.hourhand_id) : 0,
    },
    onSubmit: async (values) => {
      if (posgraduateHourhand?.id) {
        await updateHourhand({
          profileId: currentProfile?.profileId!,
          id: posgraduateHourhand?.id,
          modalityId: values.modalityId,
          hourhandId: values.hourhandId,
        });
      } else {
        await addHourhand({
          profileId: currentProfile?.profileId!,
          modalityId: values.modalityId,
          hourhandId: values.hourhandId,
        });
      }

      setShowAlert(true);
    },
  });

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        {showAlert && (isSuccessAdd || isSuccessUpdate) && (
          <Grid item xs={12}>
            <SuccessAlert
              handleDismiss={() => setShowAlert(false)}
              message={
                isSuccessAdd
                  ? 'Horario agregado correctamente'
                  : 'Horario actualizado correctamente'
              }
            />
          </Grid>
        )}
        {showAlert && (isErrorAdd || isErrorUpdate) && (
          <ErrorAlert
            handleDismiss={() => setShowAlert(false)}
            message={isErrorAdd ? (errorAdd as string) : (errorUpdate as string)}
          />
        )}
        <Grid item xs={12}>
          <InputLabel>Modalidad</InputLabel>
          <Autocomplete
            options={modalities}
            getOptionLabel={(option) => option.cat_name}
            loading={isLoadingModalities}
            loadingText="Cargando..."
            noOptionsText="No hay modalidades"
            {...getFieldProps('modalityId')}
            value={modalities.find((modality) => modality.id === values.modalityId) || null}
            multiple={false}
            onChange={(event, value) => {
              setFieldValue('modalityId', value?.id || 0);
            }}
            placeholder="Seleccione una modalidad"
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                placeholder="Seleccione una modalidad"
                error={Boolean(touched.modalityId && errors.modalityId)}
              />
            )}
          />
          {errors.modalityId && touched.modalityId && (
            <FormHelperText error>{errors.modalityId}</FormHelperText>
          )}
        </Grid>
        <Grid item xs={12}>
          <InputLabel>Horario</InputLabel>
          <Autocomplete
            {...getFieldProps('hourhandId')}
            options={hourhands || []}
            getOptionLabel={(option) => option.hour_description}
            loading={isLoadingHourhands}
            loadingText="Cargando..."
            noOptionsText="No hay horarios"
            value={hourhands.find((hourhand) => hourhand.id === values.hourhandId) || null}
            multiple={false}
            onChange={(event, value) => {
              setFieldValue('hourhandId', value?.id || 0);
            }}
            placeholder="Seleccione un horario"
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                placeholder="Seleccione un horario"
                error={Boolean(touched.hourhandId && errors.hourhandId)}
              />
            )}
          />
          {errors.hourhandId && touched.hourhandId && (
            <FormHelperText error>{errors.hourhandId}</FormHelperText>
          )}
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={isLoadingAddHourhand || isLoadingUpdateHourhand}
            endIcon={
              isLoadingAddHourhand || isLoadingUpdateHourhand ? (
                <CircularProgress size={14} />
              ) : null
            }
          >
            Guardar
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default PosgraduatePlanningHourhandsForm;
