import {
  IconUser,
  IconUsers,
  IconLock,
  IconBuilding,
  IconSchool,
  IconCalendarEvent,
  IconNotebook,
  IconBook,
  IconBuildingBank,
  IconStairs,
  IconNotes,
  IconFileCertificate,
  IconCalendarTime,
  IconId,
  IconSettings,
  IconNotification,
  IconGift,
  IconReportAnalytics,
  IconRoute,
  IconLayoutGridAdd,
  IconBus,
  IconChecklist,
  IconFileText,
} from '@tabler/icons';
import MeetingRoomOutlinedIcon from '@material-ui/icons/MeetingRoomOutlined';
import { UserCircle, ClockAfternoon, UsersThree, ChalkboardSimple, BookOpen } from 'phosphor-react';
import { shared } from '../shared';
import { NavItemType } from '../../interfaces/material-ui.interfaces';

// Icon Group
const icons = {
  IconUser,
  IconUsers,
  IconLock,
  IconBuilding,
  UserCircle,
  IconSchool,
  ClockAfternoon,
  IconCalendarEvent,
  IconNotebook,
  MeetingRoomOutlinedIcon,
  UsersThree,
  IconBook,
  IconBuildingBank,
  IconStairs,
  IconNotes,
  ChalkboardSimple,
  BookOpen,
  IconFileCertificate,
  IconCalendarTime,
  IconId,
  IconNotification,
  IconSettings,
  IconGift,
  IconReportAnalytics,
  IconRoute,
  IconLayoutGridAdd,
  IconBus,
  IconChecklist,
  IconFileText,
};

export const academic: NavItemType = {
  id: 'academic',
  title: 'Académico',
  type: 'group',
  url: '/dashboard',
  children: [
    {
      id: 'administrar-estudiantes',
      title: 'Estudiantes',
      menuId: 'academic.menu.students',
      type: 'collapse',
      icon: icons['IconUsers'],
      permissionKey: 'student',
      isImportant: true,
      children: [
        {
          id: 'administrar-estudiantes',
          title: 'Estudiantes',
          menuId: 'academic.menu.students.list',
          type: 'item',
          url: '/administrar-estudiantes',
          isImportant: true,
          permissionKey: 'student',
        },
        {
          id: 'graduar-estudiantes',
          title: 'Graduar Estudiantes',
          menuId: 'academic.menu.students.graduate',
          type: 'item',
          url: '/graduar-estudiantes',
          permissionKey: 'student',
        },
      ],
    },
    {
      id: 'administrar-docentes',
      title: 'Docentes',
      menuId: 'academic.menu.teachers',
      type: 'collapse',
      icon: icons['IconSchool'],
      permissionKey: 'course',
      isImportant: true,
      children: [
        {
          id: 'administrar-docentes',
          title: 'Docentes',
          menuId: 'academic.menu.teachers.list',
          type: 'item',
          url: '/administrar-docentes',
          isImportant: true,
          permissionKey: 'course',
        },
        {
          id: 'administrar-consejeros',
          title: 'Consejeros',
          menuId: 'academic.menu.teachers.counselors',
          type: 'item',
          url: '/administrar-consejeros',
          permissionKey: 'course',
        },
        {
          id: 'administrar-requisiciones-docentes',
          title: 'Requisiciones Docentes',
          menuId: 'academic.menu.academic.requisitions',
          type: 'item',
          url: '/administrar-requisiciones-docentes',
          permissionKey: 'applications',
        },
      ],
    },
    {
      id: 'administrar-administracion',
      title: 'Administracion',
      menuId: 'academic.menu.admin',
      type: 'collapse',
      icon: icons['IconChecklist'],
      children: [
        {
          id: 'administrar-homologaciones',
          title: 'Homologaciones',
          menuId: 'academic.menu.academic.homologations',
          type: 'item',
          url: '/administrar-homologaciones',
          permissionKey: 'applications',
        },
        {
          id: 'administrar-solicitudes',
          title: 'Listado de solicitudes',
          menuId: 'academic.menu.academic.requests',
          type: 'item',
          url: '/administrar-solicitudes',
          permissionKey: 'applications',
        },
        {
          id: 'syllabus',
          title: 'Syllabus',
          menuId: 'Syllabus',
          type: 'item',
          url: '/syllabus',
          permissionKey: 'collaborator',
        },
        {
          id: 'administrar-acta-solicitud',
          title: 'Acta de Consejo',
          menuId: 'academic.menu.academic.conseling_act',
          type: 'item',
          url: '/administrar-acta-solicitud',
          permissionKey: 'applications',
        },

        {
          id: 'administrar-convenios',
          title: 'Convenios',
          menuId: 'academic.menu.academic.agreements',
          type: 'item',
          url: '/administrar-convenios',
          permissionKey: 'agreement',
        },
      ],
    },
    {
      id: 'administrar-academico',
      title: 'Académico',
      menuId: 'academic.menu.academic',
      type: 'collapse',
      icon: icons['ChalkboardSimple'],
      isImportant: true,
      children: [
        {
          id: 'administrar-periodos',
          title: 'Listado de Periodos',
          menuId: 'academic.menu.academic.periods_list',
          type: 'item',
          url: '/administrar-periodos',
          permissionKey: 'periods',
        },
        {
          id: 'permisos-notas',
          title: 'Permisos de notas',
          menuId: 'academic.menu.academic.permissions_grades',
          type: 'item',
          url: '/permisos-notas',
          permissionKey: 'periods',
        },
        {
          id: 'administrar-distribucion-aulas',
          title: 'Distribución de aulas',
          menuId: 'academic.menu.academic.classrooms',
          type: 'item',
          url: '/administrar-distribucion-aulas',
          permissionKey: 'classroomEducationLevel',
        },
        {
          id: 'administrar-cursos',
          title: 'Cursos',
          menuId: 'academic.menu.academic.courses',
          type: 'item',
          url: '/administrar-cursos',
          isImportant: true,
          permissionKey: 'course',
        },
      ],
    },
    {
      id: 'administrar-posgrado',
      title: 'Posgrado',
      menuId: 'academic.menu.posgraduate',
      type: 'collapse',
      icon: icons['IconCalendarEvent'],
      children: [
        {
          id: 'planeacion-academica-posgrado',
          title: 'Planeación academica posgrado',
          menuId: 'academic.menu.planning_postgraduate',
          type: 'item',
          url: '/planeacion-academica-posgrado',
          permissionKey: 'courseStudent',
        },
        {
          id: 'planeacion-academica-posgrado/listado',
          title: 'Planeación academica posgrado',
          menuId: 'academic.menu.planning_postgraduate.list',
          type: 'item',
          url: '/listado-planeacion-academica-posgrado',
          permissionKey: 'courseStudent',
        },
        {
          id: 'planeacion-horarios-posgrado',
          title: '',
          menuId: 'academic.menu.planning_postgraduate.hourhands',
          type: 'item',
          url: '/planeacion-horarios-posgrado',
          permissionKey: 'courseStudent',
        },
        {
          id: 'planeacion-tipos-dia-posgrado',
          title: 'Tipos de días',
          menuId: 'academic.menu.planning_postgraduate.type_days',
          type: 'item',
          url: '/planeacion-tipos-dia-posgrado',
          permissionKey: 'courseStudent',
        },
        {
          id: 'planeacion-cohorte-posgrado',
          title: 'Planificación de cohorte',
          menuId: 'academic.menu.planning_postgraduate.cohort',
          type: 'item',
          url: '/planeacion-cohorte-posgrado',
          permissionKey: 'courseStudent',
        },
        {
          id: 'planeacion-calendario-posgrado',
          title: 'Planificación de calendarios',
          menuId: 'academic.menu.planning_postgraduate.calendar',
          type: 'item',
          url: '/planeacion-calendario-posgrado',
          permissionKey: 'courseStudent',
        },
        {
          id: 'estudiantes',
          title: 'Estudiantes',
          // menuId: 'academic.menu.posgraduate.students',
          type: 'collapse',
          permissionKey: 'courseStudent',
          children: [
            {
              id: 'asignar-cursos-posgrado',
              title: 'Registrar cursos posgrado',
              menuId: 'academic.menu.academic.postgraduate',
              type: 'item',
              url: '/asignar-cursos-posgrado',
              permissionKey: 'courseStudent',
            },
            {
              id: 'cambiar-periodo',
              title: 'Cambiar periodo',
              menuId: 'academic.menu.academic.change_period',
              type: 'item',
              url: '/cambiar-periodo',
              permissionKey: 'courseStudent',
            },
          ],
        },
      ],
    },
    {
      id: 'administrar-nivelacion',
      title: 'Nivelación',
      menuId: 'academic.menu.nivelation',
      type: 'collapse',
      icon: icons['IconLayoutGridAdd'],
      children: [
        {
          id: 'administrar-grupo-de-areas',
          title: 'Grupos',
          menuId: 'academic.menu.nivelation.groups',
          type: 'item',
          url: '/administrar-grupo-de-areas',
          permissionKey: 'areaGroup',
        },
        {
          id: 'administrar-registro-nivelacion',
          title: 'Registro Nivelación',
          menuId: 'academic.menu.nivelation.register',
          type: 'item',
          url: '/administrar-registro-nivelacion',
          permissionKey: 'periods',
        },
        {
          id: 'administrar-seguimiento-nivelacion',
          title: 'Seguimiento de Nivelación',
          menuId: 'academic.menu.nivelation.tracking',
          type: 'item',
          url: '/administrar-seguimiento-nivelacion',
          permissionKey: 'periods',
        },
      ],
    },
    {
      id: 'administrar-configuracion',
      title: 'Configuración',
      menuId: 'academic.menu.configuration',
      type: 'collapse',
      icon: icons['IconSettings'],
      children: [
        {
          id: 'administrar-areas',
          title: 'Areas',
          menuId: 'academic.menu.configuration.areas',
          type: 'item',
          url: '/administrar-areas',
          permissionKey: 'area',
        },
        {
          id: 'administrar-aulas',
          title: 'Aulas',
          menuId: 'academic.menu.configuration.classrooms',
          type: 'item',
          url: '/administrar-aulas',
          permissionKey: 'classrooms',
        },
        {
          id: 'administrar-campus',
          title: 'Campus',
          menuId: 'academic.menu.configuration.campus',
          type: 'item',
          url: '/administrar-campus',
          permissionKey: 'campus',
        },
        {
          id: 'administrar-paralelos',
          title: 'Paralelos',
          menuId: 'academic.menu.configuration.parallels',
          type: 'item',
          url: '/administrar-paralelos',
          permissionKey: 'parallels',
        },
        {
          id: 'administrar-horarios',
          title: 'Horarios',
          menuId: 'academic.menu.configuration.schedules',
          type: 'item',
          url: '/administrar-horarios',
          permissionKey: 'hourhand',
        },
        {
          id: 'administrar-tipos-materias',
          title: 'Tipos de Materias',
          menuId: 'academic.menu.configuration.subject_types',
          type: 'item',
          url: '/administrar-tipos-materias',
          permissionKey: 'typeMatters',
        },
        {
          id: 'administrar-materias',
          title: 'Materias',
          menuId: 'academic.menu.configuration.subjects',
          type: 'item',
          url: '/administrar-materias',
          permissionKey: 'matters',
        },
        {
          id: 'administrar-niveles-educativos',
          title: 'Niveles Educativos',
          menuId: 'academic.menu.configuration.edu_levels',
          type: 'item',
          url: '/administrar-niveles-educativos',
          permissionKey: 'offers',
        },
        {
          id: 'administrar-unidades-educativas',
          title: 'Unidades Académicas',
          menuId: 'academic.menu.configuration.academic_unit',
          type: 'item',
          url: '/administrar-unidades-academicas',
          permissionKey: 'educationLevel',
        },
        {
          id: 'administrar-mallas',
          title: 'Mallas',
          menuId: 'academic.menu.configuration.curriculums',
          type: 'item',
          url: '/mallas-curriculares',
          permissionKey: 'meshs',
          breadcrumbs: false,
        },
        {
          id: 'administrar-componentes-de-aprendizaje',
          title: 'Componentes de aprendizaje',
          menuId: 'academic.menu.configuration.learning_components',
          type: 'item',
          url: '/administrar-componentes-de-aprendizaje',
          permissionKey: 'component',
        },
        {
          id: 'administrar-tipos-periodos',
          title: 'Tipos de Periodos',
          menuId: 'academic.menu.configuration.period_types',
          type: 'item',
          url: '/administrar-tipos-periodos',
          permissionKey: 'typePeriods',
        },
        {
          id: 'administrar-institutos',
          title: 'Institutos',
          menuId: 'academic.menu.configuration.institutes',
          type: 'item',
          url: '/administrar-institutos',
          permissionKey: 'institutes',
        },
        {
          id: 'administrar-modelo-calificacion',
          title: 'Modelo de Calificación',
          menuId: 'academic.menu.configuration.calification_model',
          type: 'item',
          url: '/administrar-modelo-calificacion',
          permissionKey: 'calificationModel',
        },
        {
          id: 'administrar-estados-solicitudes',
          title: 'Solicitudes',
          menuId: 'academic.menu.configuration.requests_status',
          type: 'item',
          url: '/administrar-estados-solicitudes',
          permissionKey: 'calificationModel',
        },
      ],
    },
    {
      id: 'externos',
      title: 'Externos',
      menuId: 'academic.menu.externals',
      type: 'collapse',
      icon: icons['BookOpen'],
      children: [
        {
          id: 'blackboard/archivos',
          title: 'Blackboard',
          menuId: 'academic.menu.externals.blackboard',
          type: 'item',
          url: '/blackboard/archivos',
          permissionKey: 'calificationModel',
        },
      ],
    },
    {
      id: 'events-news-admin',
      title: 'Noticias y eventos',
      menuId: 'academic.menu.news_events',
      type: 'collapse',
      icon: icons['IconNotification'],
      url: '/administrar-eventos',
      isImportant: true,
      children: [
        {
          id: 'administrar-eventos',
          title: 'Eventos y noticias',
          menuId: 'academic.menu.news_events.events_news',
          type: 'item',
          url: '/administrar-eventos',
          isImportant: true,
          permissionKey: 'agreement',
        },
        {
          id: 'administrar-eventos/visualizar',
          title: 'Eventos',
          menuId: 'academic.menu.news_events.events',
          type: 'item',
          url: '/administrar-eventos/visualizar',
          permissionKey: 'course',
        },
        {
          id: 'administrar-eventos/noticias/visualizar',
          title: 'Noticias',
          menuId: 'academic.menu.news_events.news',
          type: 'item',
          url: '/administrar-eventos/noticias/visualizar',
          permissionKey: 'course',
        },
      ],
    },
    {
      id: 'cumpleanios',
      title: 'Cumpleaños',
      menuId: 'academic.menu.birthday',
      type: 'item',
      url: '/cumpleanios',
      permissionKey: 'collaborator',
      icon: icons['IconGift'],
    },
    {
      id: 'bus-route',
      title: 'Ruta de buses',
      menuId: 'academic.menu.bus_route',
      type: 'item',
      url: '/bus-route',
      permissionKey: 'collaborator',
      icon: icons['IconBus'],
    },

    // {
    //   id: 'reportes',t
    //   title: 'Reportes',
    //   menuId: 'shared.menu.reports',
    //   type: 'collapse',
    //   permissionKey: 'course',
    //   icon: icons['IconReportAnalytics'],
    //   children: [
    //     {
    //       id: 'reportes',
    //       title: 'Reportes',
    //       menuId: 'shared.menu.reports.scolarship',
    //       type: 'item',
    //       url: 'http://192.168.137.60:8081/Reports/report/Reporte_de_Becas',
    //       external: 'http://192.168.137.60:8081/Reports/report/Reporte_de_Becas',
    //       target: true,
    //       permissionKey: 'course',
    //     },
    //   ],
    // },
    // shared menu
    ...shared,
  ],
};
