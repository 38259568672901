import { useState, FC, useEffect } from 'react';

import { Grid } from '@material-ui/core';

import SubCard from '../../../../shared/components/cards/SubCard';
import BillingData from '../BillingData';
import BillingSideDialog from '../BillingSideDialog';
import { useGetStudentBillingDataQuery } from '../../slices/paymentsApiSlice';
import { useAppSelector } from '../../../../../hooks/useRedux';

interface Props {
  //   handleOpenClientSideDialog: () => void;
  handleValidStep: (param: boolean) => void;
}

const TempBillingDataHoc: FC<Props> = (props) => {
  const { handleValidStep } = props;

  const { debtorId } = useAppSelector((state) => state.payments);
  const { userInfo } = useAppSelector((state) => state.auth);

  //Billing data side dialog
  const [openBilling, setOpenBilling] = useState(false);
  // si no hay data y se agrega nueva
  const [isNewInfo, setIsNewInfo] = useState(false);

  //billing Data
  const { data: billingData, isFetching: isFetcingBD } = useGetStudentBillingDataQuery({
    debtorId,
    personId: userInfo.personId || '',
  });

  useEffect(() => {
    if (
      (!billingData?.id ||
        !billingData?.direccion ||
        !billingData?.email ||
        !billingData?.identificacion) &&
      !isNewInfo
    )
      handleValidStep(false);
    else handleValidStep(true);
  }, [billingData, handleValidStep, isNewInfo]);

  const handleOpenBilling = () => {
    setOpenBilling(true);
  };

  const handleCloseBilling = () => {
    setOpenBilling(false);
  };
  return (
    <>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={6}>
          <SubCard>
            <BillingData
              BillingData={{ data: billingData, isFetching: isFetcingBD }}
              handleOpen={handleOpenBilling}
            />
          </SubCard>
        </Grid>
      </Grid>

      <BillingSideDialog
        BillingData={{ data: billingData, isFetching: isFetcingBD }}
        open={openBilling}
        handleCloseDialog={handleCloseBilling}
        successAction={() => setIsNewInfo(true)}
        errorAction={() => setIsNewInfo(false)}
      />
    </>
  );
};

export default TempBillingDataHoc;
