import { memo, useEffect, useState } from 'react';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, List, Grid, Typography } from '@material-ui/core';

import { useGetNotificationsQuery } from '../../../../slices/notificationsApi';
import { useAppSelector } from '../../../../../../hooks/useRedux';

import NotificationItem from './NotificationItem';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTableSearch } from '../../../../../../hooks/useTableSearch';
import { updateNotificationTable } from '../../../../../maintenance/notifications/slices/notificationsSlice';
import { renderSkeletonTable } from '../../../../helpers/render';
import { Notification } from '../../../../interfaces/notifications.interface';

// style constant
const useStyles = makeStyles((theme) => ({
  navContainer: {
    width: '100%',
    // maxWidth: '330px',
    paddingTop: 0,
    paddingBottom: 0,
    borderRadius: '10px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '300px',
    },
  },
}));

//-----------------------|| NOTIFICATION LIST ITEM ||-----------------------//
interface Props {
  notificationsViewState: boolean;

  handleClose: () => void;
}
const NotificationList = ({ notificationsViewState = true, handleClose }: Props) => {
  const classes = useStyles();

  // const [localCounter, setLocalCounter] = useState(0);

  const [notifications, setNotifications] = useState<Notification[]>([]);

  // const { counter } = useAppSelector((state) => state.access.notifications);
  const { page, perPage, search } = useAppSelector(
    (state) => state.notifications.notificationTable
  );

  const { setPage, resetTable } = useTableSearch({
    page,
    perPage,
    search,
    updateFunction: updateNotificationTable,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => resetTable(), []);

  const { data, isLoading } = useGetNotificationsQuery({
    onlyPending: notificationsViewState,
    page,
    perPage,
    search,
  });

  // useEffect(() => {
  //   if (localCounter > 0 && localCounter !== counter)
  //     getNotifications(
  //       { onlyPending: notificationsViewState, page, perPage, search },
  //       true
  //     ).refetch();
  //   else getNotifications({ onlyPending: notificationsViewState, page, perPage, search }, true);

  //   setLocalCounter(counter);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [notificationsViewState, page, counter]);

  //actualiza estado de notificaciones
  useEffect(() => {
    if (data) setNotifications((nots) => [...nots, ...data.data]);
  }, [data]);

  //Aumentar el page o usar un trigger lazy query para hacer fetch a un estado directamente desde la respuesta de la peticion
  const fetchMoreData = () => {
    setPage(page + 1);
  };

  const renderView = () =>
    notifications.length ? (
      notifications.map((item, index) => (
        <NotificationItem
          handleClose={handleClose}
          key={`notificationitem-${item.announcement.id}-${index.toString()}`}
          notification={item}
        />
      ))
    ) : (
      <Grid container p={2} justifyContent="center">
        <Typography align="center">Sin notificaciones</Typography>
      </Grid>
    );

  console.log(data ? data.total > page * perPage : true);
  console.log(data?.total, '-', notifications);

  return (
    <List id="testest" className={classes.navContainer}>
      {isLoading ? (
        <Grid container p={2} justifyContent="center">
          <CircularProgress />
        </Grid>
      ) : (
        <InfiniteScroll
          dataLength={notifications.length!}
          next={fetchMoreData}
          hasMore={data ? data.total > notifications.length : true}
          loader={renderSkeletonTable(1, 1)}
          scrollableTarget="notificationScrollable"
          // scrollThreshold={0.3}
          // endMessage={
          //   <Typography variant="h5" textAlign={'center'} mt={2}>
          //     Sin notificaciones
          //   </Typography>
          // }
        >
          {renderView()}
        </InfiniteScroll>
      )}
    </List>
  );
};

export default memo(NotificationList);
