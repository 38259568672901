import { useEffect, useState } from 'react';
import { ReportUrlTypes } from './reportTypes.inteface';
import buildReportUrl from './processUrl';

const defaultProps = {
  height: '100%',
  language: 'en-us',
  //   parameters: {},
  reportServer:
    'http://reportes.nbserp.com/ReportNBS/Pages/ReportViewer.aspx?rs:Command=Render&amp;rs:Format=HTML5&%2fReporte_Prueba&id_base=10013',
  reportUrl: '',
  showParameters: true,
  toolbar: true,
  width: '100%',
};

const ReportComponent = (props: ReportUrlTypes = defaultProps) => {
  const [urlProcessed, setUrlProcessed] = useState<string | undefined>('');

  useEffect(() => {
    setUrlProcessed(
      buildReportUrl({
        ...props,
      })
    );
  }, [props]);

  return (
    <iframe title="report-iframe" src={urlProcessed} width={props.width} height={props.height} />
  );
};

export default ReportComponent;
