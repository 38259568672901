import { useAppSelector } from '../../../../hooks/useRedux';
import DeleteLearningComponentDialog from '../components/DeleteLearningComponentDialog';
import LearningComponentsTable from '../components/LearningComponentsTable';
import { Grid, Typography, useTheme } from '@material-ui/core';
import MainCard from '../../../shared/components/cards/MainCard';
// import { useGetPeriodsQuery } from '../slices/periodsSlice';

const LearningComponentsScreen = () => {
  const { learningComponentIdToDelete } = useAppSelector((state) => state.learningComponents);

  const theme = useTheme();

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">Componentes de aprendizaje</Typography>
      </Grid>
      <Grid
        item
        xs={12}
        lg={12}
        sx={{ border: `solid 1px ${theme.palette.grey}`, borderRadius: '20px' }}
      >
        <LearningComponentsTable />
      </Grid>
      {learningComponentIdToDelete && <DeleteLearningComponentDialog />}
    </Grid>
  );
};

export default LearningComponentsScreen;
