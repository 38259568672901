import { useState, useMemo, useRef, useEffect } from 'react';
import { Grid, MobileStepper, useTheme, Typography } from '@material-ui/core';
import { useParams } from 'react-router-dom';

import GeneralDetailsForm from './GeneralDetailsForm';
import SpecificDetailsForm from './SpecificDetailsForm';
import ProjectPlanningForm from './ProjectPlanningForm';
import MoreDetailsForm from './MoreDetailsForm';
import { ProjectStepsPayload } from '../../../../interfaces/projectResearch.interfaces';
import {
  useAddprojectsResearchMutation,
  useUpdateProjectsResearchMutation,
} from '../../../../slices/InvestigationLinesApiSlice';
import SuccessAlert from '../../../../../../shared/components/alerts/SuccessAlert';
import ErrorAlert from '../../../../../../shared/components/alerts/ErrorAlert';
import { useAppSelector } from '../../../../../../../hooks/useRedux';
import { investigationType, vinculationType } from '../../../../constants/lists/typeProject';

export interface Reseteable {
  isReset: boolean;
  handleReset?: () => void;
}

const stepsArray = [
  { id: 1, name: 'Detalles Generales' },
  { id: 2, name: 'Detalles Específicos' },
  { id: 3, name: 'Planificación del Proyecto' },
  { id: 4, name: 'Detalles especiales' },
];

interface Props {
  isEdit?: boolean;
  projectId?: string;
  isPrincipal?: boolean;
  projectType?: typeof investigationType | typeof vinculationType;
}

const WizardProject = ({ isEdit, projectId = '', isPrincipal, projectType }: Props) => {
  const theme = useTheme();

  const { caseKey } = useParams<{ caseKey: string }>();

  const [activeStep, setActiveStep] = useState(1);

  const [projectData, setProjectData] = useState({} as ProjectStepsPayload);

  const [reset, setReset] = useState(false);

  const [showAlerts, setShowAlerts] = useState(true);

  const { projectList } = useAppSelector((state) => state.investigationLines);

  const inputElement = useRef<HTMLDivElement>(null);

  const projectInfo = useMemo(
    () => projectList?.find((e) => e.id === projectId),
    [projectList, projectId]
  );

  const [createProject, { isLoading: isLoadingProject, isError, isSuccess, error }] =
    useAddprojectsResearchMutation({});

  const [
    editProject,
    {
      isLoading: isLoadingEditProject,
      isError: isErrorEdit,
      isSuccess: isSuccessEdit,
      error: errorEdit,
    },
  ] = useUpdateProjectsResearchMutation({});

  const sendCreateProject = async ({
    generalDetails,
    moreDetailsProject,
    projectPlanning,
    specificDetails,
  }: ProjectStepsPayload) => {
    await createProject({
      projectResearchPayload: {
        ...generalDetails,
        ...moreDetailsProject,
        ...projectPlanning,
        ...specificDetails,
      },
    });
    setShowAlerts(true);
    // inputElement?.current?.scrollIntoView(true);
  };

  const sendEditProject = async ({
    generalDetails,
    moreDetailsProject,
    projectPlanning,
    specificDetails,
  }: ProjectStepsPayload) => {
    await editProject({
      projectId,
      projectResearchPayload: {
        ...generalDetails,
        ...moreDetailsProject,
        ...projectPlanning,
        ...specificDetails,
      },
    });
    setShowAlerts(true);
    inputElement?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const handleReset = () => {
    setReset(true);
  };

  useEffect(() => {
    if (isSuccess) {
      handleReset();
    }
  }, [isSuccess]);

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      direction="column"
      ref={inputElement}
    >
      <Grid item xs={12}>
        {(isError || isErrorEdit) && showAlerts && (
          <ErrorAlert
            message={(error as string) || (errorEdit as string)}
            handleDismiss={() => setShowAlerts(false)}
          />
        )}

        {(isSuccess || isSuccessEdit) && showAlerts && (
          <SuccessAlert
            message={isEdit ? 'Proyecto actualizado exitosamente' : 'Proyecto creado exitosamente'}
            handleDismiss={() => setShowAlerts(false)}
          />
        )}
      </Grid>
      <Grid container justifyContent="center" alignItems="center" my={2}>
        <Typography variant="h4" color="primary">
          Paso {activeStep} de {stepsArray.length}
        </Typography>
        <Grid item xs={12}>
          <MobileStepper
            variant="progress"
            steps={4}
            position="static"
            activeStep={activeStep - 1}
            LinearProgressProps={{
              sx: {
                width: '100%',
                height: 10,
                borderRadius: 5,
                backgroundColor: `${theme.palette.grey[300]} !important`,
              },
            }}
            backButton
            nextButton
          />
        </Grid>
        <Grid container>
          <Typography variant="h4" color="inherit" my={2}>
            {stepsArray[activeStep - 1]?.name}
          </Typography>
        </Grid>
      </Grid>
      <Grid container direction="column">
        <div hidden={activeStep !== 1}>
          <GeneralDetailsForm
            isPrincipal={isPrincipal || caseKey === 'crear-proyecto'}
            projectType={projectType}
            setData={setProjectData}
            setNext={setActiveStep}
            dataEdit={projectInfo}
            isEdit={isEdit}
            isReset={reset}
          />
        </div>
        <div hidden={activeStep !== 2}>
          <SpecificDetailsForm
            setData={setProjectData}
            setNext={setActiveStep}
            dataEdit={projectInfo}
            isEdit={isEdit}
            isReset={reset}
          />
        </div>
        <div hidden={activeStep !== 3}>
          <ProjectPlanningForm
            setData={setProjectData}
            setNext={setActiveStep}
            dataEdit={projectInfo}
            isEdit={isEdit}
            isReset={reset}
          />
        </div>
        <div hidden={activeStep !== 4}>
          <MoreDetailsForm
            setData={setProjectData}
            setNext={setActiveStep}
            getData={projectData}
            sendSubmit={isEdit ? sendEditProject : sendCreateProject}
            isLoading={isLoadingProject || isLoadingEditProject}
            dataEdit={projectInfo}
            isEdit={isEdit}
            isReset={reset}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default WizardProject;
