// import { useState } from 'react';

import { Divider, Grid, Skeleton, Typography } from '@material-ui/core';
// import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import // useUpdateApplicationMutation,
// useGetApplicationHomologationQuery,
'../../slices/homologationsApiSlice';
import { useAppSelector } from '../../../../../hooks/useRedux';
import { StudentRecord } from '../../interfaces/homologations.interface';
import { UpdateApplicationValidationSchema } from '../../constants/validationSchemas';
import { useFormik } from 'formik';
import { useGetMeshQuery } from '../../../meshs/slices/meshsApiSlice';
import { useGetApplicationHomologationQuery } from '../../slices/homologationsApiSlice';
import ErrorAlert from '../../../../shared/components/alerts/ErrorAlert';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import ErrorAlert from '../../../../shared/components/alerts/ErrorAlert';
// import SuccessAlert from '../../../../shared/components/alerts/SuccessAlert';
// import { openInternalDeleteModalWith } from '../../slices/homologationsSlice';

const spacing = 2;

//Quitar proceso de aprobar solicitudes
const PreviewIntHomologation = () => {
  const { t } = useTranslation();
  // const dispatch = useDispatch();
  // const theme = useTheme();
  const { currentProfile } = useAppSelector((state) => state.access);
  const [showAlerts, setShowAlerts] = useState(true);

  // const { internalHomologationsList } = useAppSelector((state) => state.homologations);
  const { hId } = useParams<{ hId: string }>();

  const {
    data,
    isFetching,
    error: getDataError,
  } = useGetApplicationHomologationQuery({
    profileId: currentProfile?.profileId!,
    ApplicationHomId: Number(hId),
  });

  useEffect(() => {
    if (getDataError) setShowAlerts(true);
  }, [getDataError]);

  // const currentApplication = internalHomologationsList.find((h) => h.id.toString() === hId);
  // const primaryData = (currentApplication?.applicationDetails[0]?.value as StudentRecord) || [];

  const primaryData = data?.applicationDetails[0]?.value as StudentRecord;

  const {
    data: destinedCurriculum,
    // isLoading: isLoadingDM,
    isFetching: isFetchingDM,
  } = useGetMeshQuery(
    {
      profileId: currentProfile?.profileId!,
      meshId: data?.applicationDetails.curriculum.id || -1,
    },
    { skip: !data }
  );

  // const [updateApplication, { isLoading: isUpdating }] = useUpdateApplicationMutation();

  // for (const el of currentApplication!.applicationDetails) {
  //   const aux = getApplicationDetailValue(el.value, 'collaborator.coll_advice', 'coll_advice');
  //   // const aux = getApplicationDetailValue(el.value, 'collaborator_id');
  //   if (Boolean(aux)) break;
  // }

  const { handleSubmit } = useFormik({
    initialValues: {
      comment: '',
    },
    validationSchema: UpdateApplicationValidationSchema,
    onSubmit: async (values) => {
      // await updateApplication({
      //   profileId: currentProfile?.profileId!,
      //   applicationPayload: {
      //     app_code: data?.app_code,
      //     comment: values.comment,
      //     role_id: currentRol!.rolId,
      //   },
      // });
      // setShowAlerts(true);
    },
  });

  // const handleDelete = function (internalHomologation: { code: string; msg: string }) {
  //   dispatch(openInternalDeleteModalWith(internalHomologation));
  // };

  // if (true) return <></>;

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={4} alignItems="flex-start">
        <Grid item xs={12}>
          {getDataError && showAlerts && (
            <ErrorAlert
              message={getDataError as string}
              handleDismiss={() => setShowAlerts(false)}
            />
          )}
        </Grid>

        <>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid container item xs={12} md={6} alignItems={'center'}>
                <Grid item xs={'auto'}>
                  <Typography variant={'body2'} component={'span'}>
                    {t('academic.homologation.internal.preview.student_code')}
                  </Typography>
                </Grid>
                {isFetching ? (
                  <Grid item xs={6}>
                    <Skeleton
                      variant="text"
                      component={'span'}
                      sx={{ width: '100%', height: 30, ml: 1 }}
                    />
                  </Grid>
                ) : (
                  <Grid item xs={'auto'} lg={6} sx={{ pl: spacing }}>
                    <Typography variant={'h4'} component={'span'}>
                      {primaryData?.student.stud_code}
                    </Typography>
                  </Grid>
                )}
              </Grid>

              <Grid container item xs={12} md={6} alignItems={'center'}>
                <Grid item xs={'auto'}>
                  <Typography variant={'body2'} component={'span'}>
                    {t('academic.homologation.internal.preview.dni')}
                  </Typography>
                </Grid>
                {isFetching ? (
                  <Grid item xs={6}>
                    <Skeleton
                      variant="text"
                      component={'span'}
                      sx={{ width: '100%', height: 30, ml: 1 }}
                    />
                  </Grid>
                ) : (
                  <Grid item xs={'auto'} lg={6} sx={{ pl: spacing }}>
                    <Typography variant={'h4'} component={'span'}>
                      {primaryData?.student.user.person.pers_identification}
                    </Typography>
                  </Grid>
                )}
              </Grid>

              <Grid container item xs={12} md={6} alignItems={'center'}>
                <Grid item xs={'auto'}>
                  <Typography variant={'body2'} component={'span'}>
                    {t('academic.homologation.internal.preview.student_name')}
                  </Typography>
                </Grid>
                {isFetching ? (
                  <Grid item xs={6}>
                    <Skeleton
                      variant="text"
                      component={'span'}
                      sx={{ width: '100%', height: 30, ml: 1 }}
                    />
                  </Grid>
                ) : (
                  <Grid item xs={'auto'} lg={6} sx={{ pl: spacing }}>
                    <Typography variant={'h4'} component={'span'}>
                      {`${primaryData?.student.user.person.pers_firstname || ''} ${
                        primaryData?.student.user.person.pers_secondname || ''
                      } ${primaryData?.student.user.person.pers_first_lastname || ''} ${
                        primaryData?.student.user.person.pers_second_lastname || ''
                      }`}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid container item xs={12} alignItems={'center'}>
                <Grid item xs={'auto'}>
                  <Typography variant={'body2'} component={'span'}>
                    {t('academic.homologation.internal.preview.academic_unit')}
                  </Typography>
                </Grid>
                {isFetching ? (
                  <Grid item xs={6}>
                    <Skeleton
                      variant="text"
                      component={'span'}
                      sx={{ width: '100%', height: 30, ml: 1 }}
                    />
                  </Grid>
                ) : (
                  <Grid item xs={'auto'} lg={6} sx={{ pl: spacing }}>
                    <Typography variant={'h4'} component={'span'}>
                      {primaryData?.education_level.offer.off_name}
                    </Typography>
                  </Grid>
                )}
              </Grid>

              <Grid container item xs={12} md={6} alignItems={'center'}>
                <Grid item xs={'auto'}>
                  <Typography variant={'body2'} component={'span'} alignItems={'center'}>
                    {t('academic.homologation.internal.preview.actual_program')}
                  </Typography>
                </Grid>
                {isFetching ? (
                  <Grid item xs={6}>
                    <Skeleton
                      variant="text"
                      component={'span'}
                      sx={{ width: '100%', height: 30, ml: 1 }}
                    />
                  </Grid>
                ) : (
                  <Grid item xs={'auto'} lg={6} sx={{ pl: spacing }}>
                    <Typography variant={'h4'} component={'span'}>
                      {primaryData?.education_level.edu_name}
                    </Typography>
                  </Grid>
                )}
              </Grid>

              <Grid container item xs={12} md={6} alignItems={'center'}>
                <Grid item xs={'auto'}>
                  <Typography variant={'body2'} component={'span'} alignItems={'center'}>
                    {t('academic.homologation.internal.preview.destinate_program')}
                  </Typography>
                </Grid>
                {isFetchingDM ? (
                  <Grid item xs={6}>
                    <Skeleton
                      variant="text"
                      component={'span'}
                      sx={{ width: '100%', height: 30, ml: 1 }}
                    />
                  </Grid>
                ) : (
                  <Grid item xs={'auto'} lg={6} sx={{ pl: spacing }}>
                    <Typography variant={'h4'} component={'span'}>
                      {destinedCurriculum?.education_level.edu_name}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>

          {/* {((currentRol?.keyword === 'decano' &&
          currentApplication!.current_status.toLowerCase() === 'enviado') ||
          (currentRol?.keyword === 'coordinador-facultad' &&
            (currentApplication!.current_status.toLowerCase() === 'revisado por decanato' ||
              currentApplication!.current_status.toLowerCase() ===
                'revisado por consejo facultad'))) &&
          !isSuccess && (

          <Grid item xs={12}>
            <InputLabel>Comentario</InputLabel>
            <TextField
              fullWidth
              multiline
              rows={4}
              error={Boolean(errors.comment && touched.comment)}
              placeholder="Ingrese el nombre del periodo"
              {...getFieldProps('comment')}
            />
            {errors.comment && touched.comment && (
              <FormHelperText error>{errors.comment}</FormHelperText>
            )}
          </Grid>

          )} */}

          {/* {((currentRol?.keyword === 'decano' &&
          currentApplication!.current_status.toLowerCase() === 'enviado') ||
          (currentRol?.keyword === 'coordinador-facultad' &&
            (currentApplication!.current_status.toLowerCase() === 'revisado por decanato' ||
              currentApplication!.current_status.toLowerCase() ===
                'revisado por consejo facultad'))) &&
          !isSuccess && (
          <Grid container justifyContent={'flex-end'} mt={2} spacing={2}>
            <Grid item>
              <Button
                variant="contained"
                size="large"
                // style={{ background: theme.palette.error.main, borderColor: '#EDE7F6' }}
                sx={
                  {
                    // boxShadow: theme.customShadows.error,
                    // ':hover': {
                    //   boxShadow: 'none',
                    // },
                  }
                }
                onClick={() => {
                  // touched.comment = true;
                  // validateField('comment').then(() => {
                  //   if (touched.comment && errors.comment) return;
                  //   handleDelete({ code: currentApplication!.app_code, msg: values.comment });
                  // });
                }}
              >
                Rechazar
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                size="large"
                color="primary"
                disabled={isUpdating}
                // endIcon={isUpdating && <CircularProgress size={20} />}
              >
                Aprobar
              </Button>
            </Grid>
          </Grid>
           )} */}
        </>
      </Grid>
    </form>
  );
};

// const getApplicationDetailValue = (
//   data: any,
//   searchProp: any,
//   lastLvl?: string,
//   currentLvl?: any,
//   conditionId?: number
// ) => {
//   let value: string | number | null = null;
//   if (typeof data === 'object' && data !== null) {
//     //array
//     if (Array.isArray(data)) {
//       if (conditionId) {
//         for (const el of data) {
//           // extractValuedata(el, search);
//         }
//       } else {
//         // value = data;
//       }
//     } else {
//       //object
//       if (Boolean(lastLvl)) {
//         searchProp = searchProp.split('.');
//         const current = searchProp.shift();

//         if (lastLvl! in data) {
//           value = data[lastLvl!];
//         } else {
//           for (const key in data) {
//             if (key === current) {
//               getApplicationDetailValue(
//                 data[key],
//                 Boolean(searchProp.length) ? searchProp.join('.') : lastLvl,
//                 lastLvl,
//                 (currentLvl = current)
//               );
//               break;
//             }
//           }
//         }
//       } else {
//         if (searchProp in data) {
//           value = data[searchProp];
//         }
//       }
//       return value;
//     }
//   }

//   return value;
// };

export default PreviewIntHomologation;
