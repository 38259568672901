import { useAppDispatch, useAppSelector } from '../../../../../../hooks/useRedux';
//import TaskChooseGroup from './TaskChooseGroup';
//import TaskChooseQuestions from './TaskChooseQuestion';
import { useTranslation } from 'react-i18next';

import { Stepper, StepLabel, Step, Box, Button, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import CompleteinfoEdit from './Components/CompleteInfoEdit';
import MainCard from '../../../../../shared/components/cards/MainCard';
import CompleteGroup from './Components/CompleteGroup';
import CompleteQuestionsEdit from './Components/CompleteQuestionsEdit';
import { useGetTaskDetailQuery } from '../../../slices/virtualClassroomTeacherApiSlice';
import { useParams } from 'react-router-dom';
import { setDataDetail } from '../../../slices/virtualClassroomTeacherSlice';
import LoadingData from '../../../../../shared/components/cards/Skeleton/LoadingData';
import LoadingScreen from '../../../../../shared/screens/LoadingScreen';

const ScreenEditTask = () => {
  const { t } = useTranslation();
  const { taskChooseGroup, StepEdit, taskToEdit } = useAppSelector(
    (state) => state.virtualClassroomTeacher
  );
  const dispatch = useAppDispatch();

  const {
    course: { courseId },
  } = useAppSelector((state) => state.virtualClassroomTeacherShared);

  const { idtarea } = useParams<{ idtarea: string }>();

  const { isSuccess, isLoading, isError, data, error, isFetching } = useGetTaskDetailQuery({
    cursoId: courseId,
    taskId: Number(idtarea)!,
  });
  console.log(isSuccess, isError, data, error);

  const steps = [
    t('teacherVirtualClassroom.tasks.steps.datos'),
    t('teacherVirtualClassroom.tasks.steps.estudiante'),
    t('teacherVirtualClassroom.tasks.steps.preguntas'),
    t('teacherVirtualClassroom.tasks.steps.Confirmacion'),
  ];
  /*taskCompleteInfo, taskChooseQuestions */

  const [activeStep, setActiveStep] = React.useState(0);

  console.log(activeStep, taskChooseGroup);
  /*const handleNext = () => {
    if (
      (activeStep === 1 && taskCompleteInfo) ||
      (activeStep === 2 && taskChooseGroup) ||
      (activeStep === 3 && taskChooseQuestions)
    ) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };*/
  /*

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  

*/

  useEffect(() => {
    dispatch(
      setDataDetail({
        act_description: data?.act_description!,
        supply_id: data?.supply_id!,
        act_is_score: data?.act_is_score!,
        act_score_activity: data?.act_score_activity!,
        act_num_tries: data?.act_num_tries!,
        calification_models_distributions_id: Number(data?.calification_models_distributions_id!),
        course_activity: data?.course_activity_one!,
        course_student_add: [],
        course_student_remove: [],
        detail_add: [],
        detail_remove: [],
        detail_edit: [],
        Detail_activity: data?.details_activity!,
      })
    );
  }, [dispatch, data, isLoading, isFetching]);

  const handleReset = () => {
    setActiveStep(1);
  };
  if (isLoading) {
    return <LoadingScreen />;
  }
  if (taskToEdit?.act_description === undefined) {
    return <Typography>Sin Informacion </Typography>;
  }

  return (
    <MainCard title="Editar Tarea">
      {!isLoading && isSuccess ? (
        <Box sx={{ width: '100%' }}>
          <Stepper activeStep={StepEdit}>
            {steps.map((label) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: {
                optional?: React.ReactNode;
              } = {};

              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          {activeStep === steps.length ? (
            <React.Fragment>
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button onClick={handleReset}>si</Button>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {StepEdit === 0 && <CompleteinfoEdit />}
              {StepEdit === 1 && <CompleteGroup />}
              {StepEdit === 2 && <CompleteQuestionsEdit />}
              {console.log(StepEdit)}
            </React.Fragment>
          )}
        </Box>
      ) : (
        <LoadingData />
      )}
    </MainCard>
  );
};

export default ScreenEditTask;
