import * as Yup from 'yup';

export const ValidationSchema = Yup.object().shape({
  act_description: Yup.string().required(
    'teacher_virtual_classroom.meetings.validation_schema.act_description'
  ),
  duration: Yup.number()
    .required()
    .min(1, 'teacher_virtual_classroom.meetings.validation_schema.duration'),
  start_time: Yup.date()
    .nullable()
    .required('teacher_virtual_classroom.meetings.validation_schema.start_time.required')
    .typeError('teacher_virtual_classroom.meetings.validation_schema.start_time.typeError'),
  approval_type: Yup.object()
    .nullable()
    .required('teacher_virtual_classroom.meetings.validation_schema.approval_type'),
  jbh_time: Yup.object()
    .nullable()
    .when('join_before_host', {
      is: true,
      then: Yup.object()
        .nullable()
        .required('teacher_virtual_classroom.meetings.validation_schema.jbh_time'),
    }),
  participants: Yup.array()
    .nullable()
    .when('participants_check', {
      is: false,
      then: Yup.array().min(1, 'teacher_virtual_classroom.meetings.validation_schema.participants'),
    }),
  // fperiod_type: Yup.string().required('financial.periods.add.type_required'),
  // fp_startDate: Yup.date().nullable().required('financial.periods.add.start_period_required'),
  // fp_endDate: Yup.date().nullable().required('financial.periods.add.end_period_required'),
  // // .integer('Solo se permiten números enteros.'),

  // status_id: Yup.number()
  //   .integer('financial.periods.edit.status_only_numbers')
  //   .min(1, 'financial.periods.edit.status_min')
  //   .notRequired(),
});
