import { useEffect } from 'react';
import { Grid, IconButton, Typography, useTheme } from '@material-ui/core';
import { IconArrowNarrowLeft } from '@tabler/icons';
import { useHistory, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import MainCardForm from '../../../shared/components/cards/MainCardForm';
import EditMatterForm from '../components/EditMatterForm';
import { useLazyGetMatterQuery } from '../slices/mattersApiSlice';
import { setMattersList } from '../slices/mattersSlice';
import SimpleFormOneColumn from '../../../shared/components/forms/skeletons/SimpleFormOneColumn';
import { useTranslation } from 'react-i18next';

const EditMatterScreen = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const history = useHistory();

  const dispatch = useAppDispatch();

  const { matterId } = useParams<{ matterId: string }>();
  const mattersList = useAppSelector((state) => state.matters.mattersList);

  const [getMatter, { isFetching }] = useLazyGetMatterQuery();

  useEffect(() => {
    if (!mattersList.length && !isFetching) {
      getMatter({
        matterId: +matterId,
      })
        .unwrap()
        .then((response) => {
          if (response) dispatch(setMattersList([response!]));
        })
        .catch((error) => console.log(error));
    }
  }, [getMatter, isFetching, matterId, mattersList.length, dispatch]);

  return (
    <MainCardForm>
      <Grid item xs={12}>
        <Grid item container alignItems="center" gap={1} sx={{ cursor: 'pointer' }}>
          <IconButton onClick={() => history.goBack()}>
            <IconArrowNarrowLeft size={30} color={theme.palette.text.dark} />
          </IconButton>
          <Typography variant="h2">{t('academic.subjects.edit.title')}</Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} md={8} lg={6} sx={{ mt: 2 }}>
        {!!mattersList.length ? <EditMatterForm /> : <SimpleFormOneColumn />}
      </Grid>
    </MainCardForm>
  );
};

export default EditMatterScreen;
