import { FormControlLabel, Switch } from '@material-ui/core';

import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../../../../hooks/useRedux';
import { setIsPositiveBalanceTransac } from '../../../slices/debtsPaymentSlice';

const IsPositiveBalanceSwitch = () => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const { isPositiveBalanceTransac } = useAppSelector((state) => state.debtsPayment);

  const changeIsPositiveBalanceHandler = () => {
    dispatch(setIsPositiveBalanceTransac(!isPositiveBalanceTransac));
  };

  return (
    // <Box sx={{ pr: 1 }}>
    <FormControlLabel
      labelPlacement="start"
      control={
        <Switch
          checked={isPositiveBalanceTransac}
          onChange={changeIsPositiveBalanceHandler}
          inputProps={{ 'aria-label': 'is positive balance control' }}
        />
      }
      label={t('financial.debts_payment.wizard.pay_review.is_positive_balance_switch.label')}
    />
    // </Box>
  );
};

export default IsPositiveBalanceSwitch;
