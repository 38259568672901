import {
  Autocomplete,
  FormHelperText,
  Grid,
  InputLabel,
  Skeleton,
  Switch,
  TextField,
} from '@material-ui/core';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { useAppSelector } from '../../../../../hooks/useRedux';
import { useGetCatalogByAcronymQuery } from '../../../../shared/slices/catalogApi';
import { useFormStyles } from '../../constants/styles';

interface ComplementaryDataForm {
  // Datos complementarios
  proffesion: string;
  studyReason: string;
  hasVehicle: boolean;
  taxPayersHousehold: number;
  numChild: number;
  viviendaKeyword: number;
  numBedrooms: number;
  religionKeyword: number;
  etnicKeyword: number;
  civilStatusKeyword: number;
}

const ComplementaryDataEdit = () => {
  const { currentProfile } = useAppSelector((state) => state.access);

  const profileId = currentProfile!.profileId;

  const classes = useFormStyles();

  const { getFieldProps, setFieldValue, errors, touched, setTouched, values } =
    useFormikContext<ComplementaryDataForm>();

  const { data: typesVivienda = [], isLoading: isLoadingViviendas } = useGetCatalogByAcronymQuery({
    profileId,
    acronym: 'VV',
  });

  const { data: religionTypes = [], isLoading: isLoadingReligion } = useGetCatalogByAcronymQuery({
    profileId,
    acronym: 'TR',
  });

  const { data: etnics = [], isLoading: isLoadingEtnic } = useGetCatalogByAcronymQuery({
    profileId,
    acronym: 'ET',
  });

  const { data: statusMaritalTypes = [], isLoading: isLoadingStatusMarital } =
    useGetCatalogByAcronymQuery({
      profileId,
      acronym: 'SM',
    });

  useEffect(() => {
    setTouched({});
  }, [setTouched]);

  return (
    <Grid container spacing={4} alignItems="flex-start">
      <Grid item xs={12} md={6} container spacing={2}>
        {/* Profesion */}
        <Grid item xs={12}>
          <InputLabel className={classes.inputLabel}>Profesión</InputLabel>
          <TextField
            fullWidth
            placeholder="Ej: Analista financiero"
            error={Boolean(errors.proffesion && touched.proffesion)}
            {...getFieldProps('proffesion')}
          />
          {errors.proffesion && touched.proffesion && (
            <FormHelperText error>{errors.proffesion}</FormHelperText>
          )}
        </Grid>

        {/* Razon de estudio */}
        <Grid item xs={12}>
          <InputLabel className={classes.inputLabel}>Motivación de estudio</InputLabel>
          <TextField
            fullWidth
            placeholder="Describa la motivación de estudio"
            error={Boolean(errors.studyReason && touched.studyReason)}
            {...getFieldProps('studyReason')}
          />
          {errors.studyReason && touched.studyReason && (
            <FormHelperText error>{errors.studyReason}</FormHelperText>
          )}
        </Grid>

        {/* Tiene vehiculo */}
        <Grid item xs={12}>
          <InputLabel className={classes.inputLabel}>¿Tiene vehículo?</InputLabel>
          <Switch checked={values.hasVehicle} {...getFieldProps('hasVehicle')} />
        </Grid>

        {/* Contribuyentes hogar */}
        <Grid item xs={12}>
          <InputLabel className={classes.inputLabel}>Contribuyentes del hogar</InputLabel>
          <TextField
            type="number"
            fullWidth
            placeholder="Cantidad de personas que aportan económicamente"
            error={Boolean(errors.taxPayersHousehold && touched.taxPayersHousehold)}
            {...getFieldProps('taxPayersHousehold')}
          />
          {errors.taxPayersHousehold && touched.taxPayersHousehold && (
            <FormHelperText error>{errors.taxPayersHousehold}</FormHelperText>
          )}
        </Grid>

        {/* Num hijos */}
        <Grid item xs={12}>
          <InputLabel className={classes.inputLabel}>Número de hijos</InputLabel>
          <TextField
            type="number"
            fullWidth
            placeholder="Número de hijos"
            error={Boolean(errors.numChild && touched.numChild)}
            {...getFieldProps('numChild')}
          />
          {errors.numChild && touched.numChild && (
            <FormHelperText error>{errors.numChild}</FormHelperText>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12} md={6} container spacing={2}>
        {/* Vivienda */}
        <Grid item xs={12}>
          <InputLabel className={classes.inputLabel}>Tipo de vivienda</InputLabel>
          {isLoadingViviendas ? (
            <Skeleton variant="rectangular" sx={{ width: '100%', borderRadius: 3, height: 50 }} />
          ) : (
            <Autocomplete
              options={typesVivienda}
              loading={isLoadingViviendas}
              loadingText="Cargando tipos de vivienda..."
              defaultValue={typesVivienda.find((tv) => tv.id === values.viviendaKeyword)}
              getOptionLabel={(option) => option.cat_name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Seleccione un tipo de vivienda"
                  error={Boolean(errors.viviendaKeyword && touched.viviendaKeyword)}
                />
              )}
              onChange={(_, value) => setFieldValue('viviendaKeyword', value?.id)}
              fullWidth
              clearIcon={null}
            />
          )}
          {errors.viviendaKeyword && touched.viviendaKeyword && (
            <FormHelperText error>{errors.viviendaKeyword}</FormHelperText>
          )}
        </Grid>

        {/* Num cuartos */}
        <Grid item xs={12}>
          <InputLabel className={classes.inputLabel}>Cant. cuartos en la vivienda</InputLabel>
          <TextField
            type="number"
            fullWidth
            placeholder="Cant. cuartos en la vivienda"
            error={Boolean(errors.numBedrooms && touched.numBedrooms)}
            {...getFieldProps('numBedrooms')}
          />
          {errors.numBedrooms && touched.numBedrooms && (
            <FormHelperText error>{errors.numBedrooms}</FormHelperText>
          )}
        </Grid>

        {/* Religion */}
        <Grid item xs={12}>
          <InputLabel className={classes.inputLabel}>Religión</InputLabel>
          {isLoadingReligion ? (
            <Skeleton variant="rectangular" sx={{ width: '100%', borderRadius: 3, height: 50 }} />
          ) : (
            <Autocomplete
              options={religionTypes}
              loading={isLoadingReligion}
              loadingText="Cargando religiones..."
              getOptionLabel={(option) => option.cat_name}
              defaultValue={religionTypes.find((tr) => tr.id === values.religionKeyword)!}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Seleccione una religión"
                  error={Boolean(errors.religionKeyword && touched.religionKeyword)}
                />
              )}
              onChange={(_, value) => setFieldValue('religionKeyword', value?.id)}
              fullWidth
              clearIcon={null}
            />
          )}
          {errors.religionKeyword && touched.religionKeyword && (
            <FormHelperText error>{errors.religionKeyword}</FormHelperText>
          )}
        </Grid>

        {/* Etnia */}
        <Grid item xs={12}>
          <InputLabel className={classes.inputLabel}>Etnia</InputLabel>
          {isLoadingEtnic ? (
            <Skeleton variant="rectangular" sx={{ width: '100%', borderRadius: 3, height: 50 }} />
          ) : (
            <Autocomplete
              options={etnics}
              loading={isLoadingEtnic}
              loadingText="Cargando etnias..."
              getOptionLabel={(option) => option.cat_name}
              defaultValue={etnics.find((e) => e.id === values.etnicKeyword)!}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(errors.etnicKeyword && touched.etnicKeyword)}
                  placeholder="Seleccionar etnia correspondiente"
                />
              )}
              onChange={(_, value) => setFieldValue('etnicKeyword', value?.id)}
              fullWidth
              clearIcon={null}
            />
          )}
          {errors.etnicKeyword && touched.etnicKeyword && (
            <FormHelperText error>{errors.etnicKeyword}</FormHelperText>
          )}
        </Grid>

        {/* Status Marital */}
        <Grid item xs={12}>
          <InputLabel className={classes.inputLabel}>Estado Civil</InputLabel>
          {isLoadingEtnic ? (
            <Skeleton variant="rectangular" sx={{ width: '100%', borderRadius: 3, height: 50 }} />
          ) : (
            <Autocomplete
              options={statusMaritalTypes}
              loading={isLoadingStatusMarital}
              loadingText="Cargando estado civil..."
              getOptionLabel={(option) => option.cat_name}
              defaultValue={statusMaritalTypes.find((e) => e.id === values.civilStatusKeyword)!}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(errors.civilStatusKeyword && touched.civilStatusKeyword)}
                  placeholder="Seleccionar estado civil correspondiente"
                />
              )}
              onChange={(_, value) => setFieldValue('civilStatusKeyword', value?.id)}
              fullWidth
            />
          )}
          {errors.civilStatusKeyword && touched.civilStatusKeyword && (
            <FormHelperText error>{errors.civilStatusKeyword}</FormHelperText>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ComplementaryDataEdit;
