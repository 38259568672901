// material-ui
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  makeStyles,
} from '@material-ui/core';

// project imports
import MainCard from '../../../shared/components/cards/MainCard';

// helpers
import { isPrimitive, objectKeys, objectValues } from '../../../shared/helpers';

// types
import {
  TableHeaders,
  CustomRenderers,
  CustomCellProperties,
} from '../../../shared/interfaces/material-ui.interfaces';

const useStyle = makeStyles((theme) => ({
  tableRow: {
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  },
}));

interface Props<T extends {}> {
  title: string;
  headers: TableHeaders<T | JSX.Element>;
  data: T[];
  keyExtractor: (item: T) => string;
  customRenderers?: CustomRenderers<T>;
  customDataCellsProperties?: CustomCellProperties<T>;
}

const SimpleTable = <T extends {}>({
  title,
  data,
  headers,
  keyExtractor,
  customRenderers,
  customDataCellsProperties,
}: Props<T>) => {
  const classes = useStyle();

  const renderRow = (row: T) => {
    return (
      <TableRow key={keyExtractor(row)} className={classes.tableRow}>
        {objectKeys(row).map((cell) => {
          const customRenderer = customRenderers?.[cell];

          if (customRenderer) {
            return (
              <TableCell
                key={`${keyExtractor(row)}-${String(cell)}`}
                {...customDataCellsProperties?.[cell]}
              >
                {customRenderer(row)}
              </TableCell>
            );
          }

          return (
            <TableCell
              align="center"
              key={`${keyExtractor(row)}-${String(cell)}`}
              {...customDataCellsProperties?.[cell]}
            >
              {isPrimitive(row[cell]) ? row[cell] : ''}
            </TableCell>
          );
        })}
      </TableRow>
    );
  };

  return (
    <MainCard title={title} content={false}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {objectValues(headers).map((headerValue) => (
                <TableCell align="center" key={headerValue}>
                  {headerValue}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>{data.map(renderRow)}</TableBody>
        </Table>
      </TableContainer>
    </MainCard>
  );
};

export default SimpleTable;
