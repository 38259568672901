import { useEffect } from 'react';

import { Switch, Redirect, Route } from 'react-router-dom';

import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

import MainLayout from '../modules/shared/layout/MainLayout';

import MaintenanceRouter from '../modules/maintenance/router/MaintenanceRouter';
import HRRouter from '../modules/hr/router/HRRouter';
import StudentsRouter from '../modules/students/router/StudentsRouter';
import TeachersRouter from '../modules/teachers/router/TeachersRouter';

import LibraryRouter from '../modules/library/router/LibraryRouter';
import SocialtrayRouter from '../modules/socialtray/router/SocialtrayRouter';

import DashboardScreen from '../modules/shared/screens/DashboardScreen';
import { useZendesk } from '../hooks/useZendesk';
import { useAppDispatch, useAppSelector } from '../hooks/useRedux';
import ProfileCardSkeleton from '../modules/shared/screens/LoadingScreen';
import { isEmptyObject } from '../modules/shared/helpers';
import {
  addNotificationCounter,
  setNotificationCounter,
} from '../modules/shared/reducers/accessReducer';
import { getProtocol } from '../helpers/urlHelpers';
import { useLazyGetNotificationsCountQuery } from '../modules/shared/slices/notificationsApi';

import notificationSound from '../assets/sounds/notification.mp3';
import EvaluationRouter from '../modules/evaluations/router';
// import LibrayDashboardScreen from '../modules/library/dashboard/screens/LibraryDashboardScreen';

const PrivateRouter = () => {
  //zendesk wdget
  useZendesk();

  const dispatch = useAppDispatch();

  const { currentModule, serviceCredentials } = useAppSelector((state) => state.access);
  const { userInfo } = useAppSelector((state) => state.auth);

  const [getNotificationsCounts] = useLazyGetNotificationsCountQuery();

  // TODO: need custom hook
  useEffect(() => {
    // if (serviceCredentials.pusher?.api_key) {
    const protocol = getProtocol();
    const baseUrl = `${protocol}://${localStorage.getItem('apiUrl')}/api`;
    const token = localStorage.getItem('x-token');

    getNotificationsCounts({ onlyPending: true })
      .unwrap()
      .then((data) => {
        dispatch(setNotificationCounter(data));
      })
      .catch((error) => console.log('notifications error'));

    if (serviceCredentials.pusher?.api_key && serviceCredentials.pusher?.cluster) {
      // console.log(baseUrl);
      const options = {
        broadcaster: 'pusher',
        key: serviceCredentials.pusher?.api_key,
        cluster: serviceCredentials.pusher?.cluster,
        forceTLS: process.env.NODE_ENV === 'production' ? true : false,
        // forceTLS: false,
        //authEndpoint is your apiUrl + /broadcasting/auth
        // authEndpoint: config.pusher.authEndpoint,
        // As I'm using JWT tokens, I need to manually set up the headers.
        authEndpoint: `${baseUrl}/broadcasting/auth`,
        auth: {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
          },
        },
      };

      console.log(Pusher);

      console.log(options);

      const echo = new Echo({
        ...options,
      });

      const channel = echo.private(`notificate-user.${userInfo.id}`);

      channel.listen('.notificate.push', (data: any) => {
        dispatch(addNotificationCounter());

        // console.log(notificationSound);
        var sonido = new Audio(notificationSound);
        // console.log(sonido);
        sonido.play();

        // console.log(notifications.counter);
        // console.log(data);
      });
    }
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    // notifications.counter,
    // serviceCredentials.pusher?.api_key,
    // serviceCredentials.pusher?.cluster,
    // userInfo,
  ]);

  if (!currentModule || isEmptyObject(currentModule.permissions)) return <ProfileCardSkeleton />;
  // if (!currentModule || isEmptyObject(currentModule.permissions)) return <Redirect to="/modulos" />;

  return (
    <MainLayout>
      <Switch>
        <Redirect from="/" to="/dashboard" exact />
        <Route exact path="/dashboard" component={DashboardScreen} />
        {/* <Route exact path="/biblioteca/inicio" component={LibrayDashboardScreen} /> */}

        <>
          <MaintenanceRouter />
          <HRRouter />
          <StudentsRouter />
          <TeachersRouter />
          <LibraryRouter />
          <SocialtrayRouter />
          <EvaluationRouter />
        </>
      </Switch>
    </MainLayout>
  );
};

export default PrivateRouter;
