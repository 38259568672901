import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import DeleteAlert from '../../../shared/components/dialogs/DeleteAlert';
import { useDeleteEventMutation } from '../slices/eventNewApiSlice';
import { resetEventNewToId, setModalOpenEventNew} from '../slices/eventNewSlice';

const EventNewDelete = function () {
  const dispatch = useAppDispatch();

  const { deleteModalOpen, eventIdToDelete } = useAppSelector((state) => state.eventNew);

  const { currentProfile } = useAppSelector((state) => state.access);

  const [deleteEventNew, { isLoading, isSuccess, isError, error }] =
  useDeleteEventMutation();

  const handleDelete = () => {
    deleteEventNew({
      eventId: eventIdToDelete!,
      profileId: currentProfile?.profileId!,
    });
  };

  const handleClose = () => {
    dispatch(setModalOpenEventNew(false));
    dispatch(resetEventNewToId());
  };

  return (
    <DeleteAlert
      open={deleteModalOpen}
      onClose={handleClose}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      handleDelete={handleDelete}
      error={error as string}
    />
  );
};

export default EventNewDelete;
