import { mainApi } from '../../../../api/mainApi';
import { middlewareApi } from '../../../middleware/middlewareApi';
import {
  CatalogVisitResponse,
  OpenAccessVisitResponse,
} from '../interfaces/metrics.virtual.response.interface';
import { MetricGradoResponse } from '../interfaces/metrics.grado.response.interface';
import { MetricsResponse } from '../interfaces/metrics.response.interface';
import { BookBorrowStatistic } from '../interfaces/metrics.borrows.response.interface';
import {
  DatabaseByEducationLevelResponse,
  SearchByDatabaseResponse,
} from '../interfaces/metrics.databases.response.interface';

const statisticsApi = middlewareApi.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * @GET
     */
    getMetrics: builder.query<
      MetricsResponse,
      {
        type: string;
        user_profile_id: number;
        start_date: string;
        end_date: string;
      }
    >({
      queryFn: async ({ end_date, start_date, user_profile_id, type }) => {
        try {
          const { data } = await mainApi.get<MetricsResponse>(`library-metrics/${type}`, {
            params: {
              end_date,
              start_date,
              user_profile_id,
            },
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),
    /**
     * @GET
     */
    metricsGenre: builder.query<
      MetricsResponse,
      {
        user_profile_id: number;
        start_date: string;
        end_date: string;
      }
    >({
      queryFn: async ({ end_date, start_date, user_profile_id }) => {
        try {
          const { data } = await mainApi.get<MetricsResponse>('library-metrics/todos-por-genero', {
            params: {
              end_date,
              start_date,
              user_profile_id,
            },
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),
    /**
     * @GET
     */
    metricsGrado: builder.query<
      MetricGradoResponse,
      {
        user_profile_id: number;
        start_date: string;
        end_date: string;
      }
    >({
      queryFn: async ({ end_date, start_date, user_profile_id }) => {
        try {
          const { data } = await mainApi.get<MetricGradoResponse>(
            'library-metrics/estudiante-grado-presencial',
            {
              params: {
                end_date,
                start_date,
                user_profile_id,
              },
            }
          );
          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
    }),
    /**
     * @GET
     */
    metricsPosgrado: builder.query<
      MetricGradoResponse,
      {
        user_profile_id: number;
        start_date: string;
        end_date: string;
      }
    >({
      queryFn: async ({ end_date, start_date, user_profile_id }) => {
        try {
          const { data } = await mainApi.get<MetricGradoResponse>(
            'library-metrics/estudiante-posgrado-presencial',
            {
              params: {
                end_date,
                start_date,
                user_profile_id,
              },
            }
          );

          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
    }),
    /**
     * @GET
     */
    metricsDocentes: builder.query<
      MetricGradoResponse,
      {
        user_profile_id: number;
        start_date: string;
        end_date: string;
      }
    >({
      queryFn: async ({ end_date, start_date, user_profile_id }) => {
        try {
          const { data } = await mainApi.get<MetricGradoResponse>(
            'library-metrics/colaborador-genero-facultad-presencial',
            {
              params: {
                end_date,
                start_date,
                user_profile_id,
              },
            }
          );

          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    /**
     * @GET
     *
     */
    metricsCatalog: builder.query<
      CatalogVisitResponse,
      {
        user_profile_id: number;
        start_date: string;
        end_date: string;
      }
    >({
      queryFn: async ({ end_date, start_date, user_profile_id }) => {
        try {
          const { data } = await mainApi.get<CatalogVisitResponse>(
            'library-metrics/visita-catalogos',
            {
              params: {
                end_date,
                start_date,
                user_profile_id,
              },
            }
          );
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),
    /**
     * @GET
     *
     */
    metricsOpenAccess: builder.query<
      OpenAccessVisitResponse,
      {
        user_profile_id: number;
        start_date: string;
        end_date: string;
      }
    >({
      queryFn: async ({ end_date, start_date, user_profile_id }) => {
        try {
          const { data } = await mainApi.get<OpenAccessVisitResponse>(
            'library-metrics/open-access-genero',
            {
              params: {
                end_date,
                start_date,
                user_profile_id,
              },
            }
          );
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),
    /**
     * @GET
     *
     */
    metricsEntriesLibrary: builder.query<
      OpenAccessVisitResponse,
      {
        user_profile_id: number;
        start_date: string;
        end_date: string;
      }
    >({
      queryFn: async ({ end_date, start_date, user_profile_id }) => {
        try {
          const { data } = await mainApi.get<OpenAccessVisitResponse>(
            'library-metrics/visita-biblioteca-virtual',
            {
              params: {
                end_date,
                start_date,
                user_profile_id,
              },
            }
          );
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),
    /**
     * @GET
     *
     */
    metricsTotalVirtualLibrary: builder.query<
      any,
      {
        user_profile_id: number;
        start_date: string;
        end_date: string;
      }
    >({
      queryFn: async ({ end_date, start_date, user_profile_id }) => {
        try {
          const { data } = await mainApi.get<any>(
            'library-metrics/visita-totales-biblioteca-virtual',
            {
              params: {
                end_date,
                start_date,
                user_profile_id,
              },
            }
          );
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),
    /**
     * @GET
     *
     */
    metricsVisitsDatabase: builder.query<
      SearchByDatabaseResponse,
      {
        user_profile_id: number;
        start_date: string;
        end_date: string;
      }
    >({
      queryFn: async ({ end_date, start_date, user_profile_id }) => {
        try {
          const { data } = await mainApi.get<SearchByDatabaseResponse>(
            'library-metrics/busquedas-base',
            {
              params: {
                end_date,
                start_date,
                user_profile_id,
              },
            }
          );
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),
    /**
     * @GET
     *
     */
    metricsVisitsDatabaseByEducationLevel: builder.query<
      DatabaseByEducationLevelResponse,
      {
        user_profile_id: number;
        start_date: string;
        end_date: string;
      }
    >({
      queryFn: async ({ end_date, start_date, user_profile_id }) => {
        try {
          const { data } = await mainApi.get<DatabaseByEducationLevelResponse>(
            'library-metrics/base-facultad',
            {
              params: {
                end_date,
                start_date,
                user_profile_id,
              },
            }
          );
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),
    /**
     * @GET
     *
     */
    metricsMostViewed: builder.query<
      BookBorrowStatistic[],
      {
        user_profile_id: number;
        start_date: string;
        end_date: string;
      }
    >({
      queryFn: async ({ end_date, start_date, user_profile_id }) => {
        try {
          const { data } = await mainApi.get<BookBorrowStatistic[]>(
            'library-metrics/libros-mas-solicitados',
            {
              params: {
                end_date,
                start_date,
                user_profile_id,
              },
            }
          );
          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
    }),
  }),
});

export const {
  useLazyGetMetricsQuery,
  useLazyMetricsGenreQuery,
  useLazyMetricsGradoQuery,
  useLazyMetricsPosgradoQuery,
  useLazyMetricsDocentesQuery,
  useLazyMetricsCatalogQuery,
  useLazyMetricsOpenAccessQuery,
  useLazyMetricsEntriesLibraryQuery,
  useLazyMetricsTotalVirtualLibraryQuery,
  useLazyMetricsMostViewedQuery,
  useLazyMetricsVisitsDatabaseQuery,
  useLazyMetricsVisitsDatabaseByEducationLevelQuery,
} = statisticsApi;
