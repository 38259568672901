import { useTranslation } from 'react-i18next';
import Chip from '../../../shared/components/extended/Chip';
import { statusAvailables } from '../../../shared/constants/resourceStatus';

interface Props {
  label?: string;
  statusId: number;
}

const StatusChip = ({ label, statusId }: Props) => {
  const { t } = useTranslation();

  const status = statusAvailables.find((status) => status.id === statusId);

  return (
    <Chip
      label={label || t(`utils.status.${status?.id}`)}
      size="small"
      chipcolor={status?.color || 'grey'}
    />
  );
};

export default StatusChip;
