// import { useEffect, useMemo } from 'react';

import { useHistory, useParams } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../../hooks/useRedux';
import MainCard from '../../../shared/components/cards/MainCard';

// import { useAppDispatch } from '../../../../hooks/useRedux';
import SimpleFormOneColumn from '../../../shared/components/forms/skeletons/SimpleFormOneColumn';
import BusRouteList from '../components/BusRouteList';
import GoBackBtnTitle from '../../../shared/components/buttons/GoBackBtnTitle';

const EditBusRoutesScreen = () => {
  const { t } = useTranslation();
  // const dispatch = useAppDispatch();
  const history = useHistory();
  // const location = useLocation();
  const { campusId } = useParams<{ campusId: string }>();

  const { currentProfile } = useAppSelector((state) => state.access);

  if (currentProfile?.keyword !== 'administrativo' && currentProfile?.keyword !== 'docente') {
    history.goBack();
  }

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <GoBackBtnTitle
        title={t('academic.buses.routes.title')}
        itemContainerProps={{ sx: { mb: 3 } }}
      />

      <Grid item xs={12}>
        {!campusId || true ? <BusRouteList /> : <SimpleFormOneColumn />}
      </Grid>

      {/* {roleIdToDelete && <DeleteRoleDialog />} */}
    </Grid>
  );
};

export default EditBusRoutesScreen;
