import { Autocomplete, FormHelperText, Grid, InputLabel, TextField } from '@material-ui/core';
import TextFieldFetching from '../../../../../shared/components/TextFieldFetching';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { useGetGroupResearchByLinesResearchQuery } from '../../../../lines-of-investigation/slices/InvestigationLinesApiSlice';
import { useEffect } from 'react';
import { LinesGroupResearch } from '../../../interfaces/publicationsPayload.interfaces';
import { IInitialValuesAssigns } from '../../../interfaces/initialValuesAssigns.interface';

interface Props {
  isEdit?: boolean;
}

const GoupsByAssingForm = ({ isEdit }: Props) => {
  const { values, errors, touched, setFieldValue } = useFormikContext<IInitialValuesAssigns>();

  const { data: groupsByLinesAvailables, isLoading: isLoadingGroupsByLines } =
    useGetGroupResearchByLinesResearchQuery({
      linesResearch: values.investigationLines?.map((eId) => eId) || [],
    });

  const { t } = useTranslation();

  useEffect(() => {
    if (groupsByLinesAvailables) {
      setFieldValue(
        'lines_group_research',
        groupsByLinesAvailables
          ?.filter(({ id }) => values.linesGroupResearch?.some((e) => e === id))
          ?.map(
            ({ id, line_research_id }): LinesGroupResearch => ({
              group_research_id: id,
              is_principal: id.toString() === values.linesGroupResearchPrincipal ? 1 : 0,
              line_research_id,
            })
          )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupsByLinesAvailables]);

  return (
    <>
      <Grid item xs={12} md={isEdit ? 12 : 6}>
        <InputLabel> {t('Grupo Principal')} </InputLabel>
        {isLoadingGroupsByLines ? (
          <TextFieldFetching />
        ) : (
          <Autocomplete
            options={groupsByLinesAvailables || []}
            loading={isLoadingGroupsByLines}
            value={
              groupsByLinesAvailables?.find(
                ({ id }) => id.toString() === values.linesGroupResearchPrincipal?.toString()
              ) || null
            }
            loadingText={t('academic.subjects.add.edu_level.loading')}
            noOptionsText={t('project.linesGroupResearch.group.no_option')}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                error={Boolean(errors.linesGroupResearch && touched.linesGroupResearch)}
                placeholder={t('project.linesGroupResearch.group.placeholder')}
              />
            )}
            onChange={(_, value) => {
              setFieldValue('linesGroupResearchPrincipal', value?.id);
            }}
            fullWidth
            clearIcon={null}
          />
        )}
        {errors.linesGroupResearchPrincipal && touched.linesGroupResearchPrincipal && (
          <FormHelperText error>{errors.linesGroupResearchPrincipal}</FormHelperText>
        )}
      </Grid>

      <Grid item xs={12} md={isEdit ? 12 : 6}>
        <InputLabel>Grupos secundarios</InputLabel>
        {isLoadingGroupsByLines ? (
          <TextFieldFetching />
        ) : (
          <Autocomplete
            options={
              groupsByLinesAvailables?.filter(
                ({ id }) => id !== values.linesGroupResearchPrincipal?.toString()
              ) || []
            }
            multiple
            loadingText={t('academic.subjects.add.edu_level.loading')}
            value={
              groupsByLinesAvailables?.filter(({ id }) =>
                values?.linesGroupResearch?.some((e) => e === id)
              ) || []
            }
            noOptionsText={'No hay lineas de investigacion disponibles'}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                error={Boolean(
                  errors.linesGroupResearchPrincipal && touched.linesGroupResearchPrincipal
                )}
                placeholder={t('academic.subjects.add.edu_level.placeholder')}
              />
            )}
            onChange={(_, value) => {
              setFieldValue(
                'linesGroupResearch',
                value.map(({ id }) => id)
              );
            }}
            fullWidth
            clearIcon={null}
          />
        )}
        {errors.linesGroupResearch && touched.linesGroupResearch && (
          <FormHelperText error>{errors.linesGroupResearch}</FormHelperText>
        )}
      </Grid>
    </>
  );
};

export default GoupsByAssingForm;
