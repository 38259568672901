import { mainApi } from '../../../../api/mainApi';
import { AccessState } from '../../../../types';
import { middlewareApi } from '../../../middleware/middlewareApi';
import { ClassroomTypes } from '../interfaces/classroom-types.interface';
import { Classroom, ClassroomsLoans, ClassroomsResponse } from '../interfaces/classrooms.interface';
import { setClassroomList } from './classroomsSlices';

export const classroomTags = middlewareApi.enhanceEndpoints({
  addTagTypes: ['Classrooms'],
});

export const classroomsApi = classroomTags.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * @GET all classrooms
     */
    getClassrooms: builder.query<
      ClassroomsResponse,
      {
        profileId: number;
        page: number;
        perPage: number;
        search: string;
        sort?: string;
        type_sort?: string;
        campusId?: number;
        classroomTypeId?: number;
        capacity?: number;
      }
    >({
      queryFn: async (
        { profileId, page, search, perPage, type_sort, sort, campusId, classroomTypeId, capacity },
        { dispatch }
      ) => {
        try {
          const { data } = await mainApi.get<ClassroomsResponse>('/classrooms', {
            params: {
              user_profile_id: profileId,
              page,
              size: perPage,
              type_sort: type_sort || 'desc',
              sort: sort || 'id',
              ...(campusId && { campus_id: campusId }),
              ...(classroomTypeId && { classroom_type_id: classroomTypeId }),
              ...(capacity && { cl_cap_max: capacity }),
              search,
            },
          });

          dispatch(setClassroomList(data.data));

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'Classrooms', id } as const)),
              { type: 'Classrooms', id: 'LIST' },
            ]
          : [{ type: 'Classrooms', id: 'LIST' }],
    }),
    /**
     * @GET all classrooms data all
     */
    getAllClassrooms: builder.query<
      Classroom[],
      {
        profileId?: number;
        search: string;
        sort?: string;
        type_sort?: string;
        campusId?: number;
        classroomTypeId?: number;
        capacity?: number;
      }
    >({
      queryFn: async (
        { profileId, search, type_sort, sort, campusId, classroomTypeId, capacity },
        { getState }
      ) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };
          const user_profile_id = profileId || currentProfile?.profileId;

          const { data } = await mainApi.get<Classroom[]>('/classrooms', {
            params: {
              user_profile_id,
              type_sort: type_sort || 'desc',
              sort: sort || 'id',
              ...(campusId && { campus_id: campusId }),
              ...(classroomTypeId && { classroom_type_id: classroomTypeId }),
              ...(capacity && { cl_cap_max: capacity }),
              search,
              data: 'all',
            },
          });

          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) => [{ type: 'Classrooms', id: 'LIST' }],
    }),

    /**
     * @GET all classrooms by campus id
     */
    getClassroomsByCampus: builder.query<
      ClassroomsResponse,
      {
        profileId: number;
        page: number;
        perPage: number;
        search: string;
        sort?: string;
        order: string;
        campusId: number;
      }
    >({
      queryFn: async ({ profileId, page, search, perPage, sort, order, campusId }) => {
        try {
          const { data } = await mainApi.get<ClassroomsResponse>(`/campus/${campusId}/classrooms`, {
            params: {
              user_profile_id: profileId,
              page,
              size: perPage,
              sort: sort,
              type_sort: order,
              search,
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'Classrooms', id } as const)),
              { type: 'Classrooms', id: 'LIST' },
            ]
          : [{ type: 'Classrooms', id: 'LIST' }],
    }),

    /**
     * @GET classroom by id
     */
    getClassroom: builder.query<Classroom, { profileId?: number; classroomId: number }>({
      queryFn: async ({ profileId, classroomId }, { dispatch, getState }) => {
        const {
          access: { currentProfile },
        } = getState() as { access: AccessState };
        const user_profile_id = profileId || currentProfile?.profileId;

        try {
          const { data } = await mainApi.get<Classroom>(`/classrooms/${classroomId}`, {
            params: {
              user_profile_id,
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result, error, { profileId }) => [{ type: 'Classrooms', profileId }],
    }),

    /**
     * @GET classroom loans
     */
    getClassroomsLoans: builder.query<
      ClassroomsLoans[],
      {
        profileId: number;
        classroomId: number;
        periodId: number;
        offerId: number;
        educationLevelId: string;
        campusId: number;
      }
    >({
      queryFn: async ({
        profileId,
        classroomId,
        offerId,
        campusId,
        educationLevelId,
        periodId,
      }) => {
        try {
          const { data } = await mainApi.get<ClassroomsLoans[]>(`/classrooms/get-loans`, {
            params: {
              user_profile_id: profileId,
              classroom_id: classroomId,
              period_id: periodId,
              offer_id: offerId,
              education_level_id: educationLevelId,
              campus_id: campusId,
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    /**
     * @POST create classroom
     */
    addClassroom: builder.mutation<
      Classroom,
      { profileId: number; classroomPayload: Partial<Classroom> }
    >({
      queryFn: async ({ profileId, classroomPayload }) => {
        try {
          const { data } = await mainApi.post<Classroom>('/classrooms', {
            user_profile_id: profileId,
            ...classroomPayload,
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'Classrooms', id: 'LIST' }],
    }),

    /**
     * @POST add classroomLoans
     */
    classroomLoans: builder.mutation<
      ClassroomsLoans,
      { profileId: number; classroomPayload: Partial<ClassroomsLoans> }
    >({
      queryFn: async ({ profileId, classroomPayload }) => {
        try {
          const { data } = await mainApi.post<ClassroomsLoans>('/classrooms/loans', {
            user_profile_id: profileId,
            ...classroomPayload,
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    /**
     * @PUT update classroom
     */
    updateClassroom: builder.mutation<
      Classroom,
      { profileId: number; classroomId: number; classroomPayload: Partial<Classroom> }
    >({
      queryFn: async ({ profileId, classroomId, classroomPayload }) => {
        try {
          const { data } = await mainApi.put<Classroom>(`/classrooms/${classroomId}`, {
            user_profile_id: profileId,
            ...classroomPayload,
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'Classrooms', id: 'LIST' }],
    }),

    /**
     * @DELETE remove classroom
     */
    deleteClassroom: builder.mutation<Classroom, { profileId: number; classroomId: number }>({
      queryFn: async ({ profileId, classroomId }) => {
        try {
          const { data } = await mainApi.delete(`/classrooms/${classroomId}`, {
            data: {
              user_profile_id: profileId,
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'Classrooms', id: 'LIST' }],
    }),

    /**
     * @DELETE remove classroom loans
     */
    deleteClassroomLoans: builder.mutation<
      Classroom,
      { profileId: number; lendingHourhand: number }
    >({
      queryFn: async ({ profileId, lendingHourhand }) => {
        try {
          const { data } = await mainApi.delete(`/classrooms/lendingHourhand/${lendingHourhand}`, {
            data: {
              user_profile_id: profileId,
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    /**
     * @GET classroms types to combo box
     */
    getAvailableClassroomTypes: builder.query<ClassroomTypes[], { profileId: number }>({
      queryFn: async ({ profileId }) => {
        try {
          const { data } = await mainApi.get('/classroom-types', {
            params: {
              user_profile_id: profileId,
              data: 'all',
            },
          });

          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    getAllClassroomCapacities: builder.query<
      number[],
      { profileId: number; classroomTypeId?: number; campusId?: number }
    >({
      queryFn: async ({ profileId, classroomTypeId, campusId }) => {
        try {
          const { data } = await mainApi.get('/classrooms/enabled/capacity', {
            params: {
              user_profile_id: profileId,
              ...(classroomTypeId && { classroom_type_id: classroomTypeId }),
              ...(campusId && { campus_id: campusId }),
            },
          });

          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
    }),
  }),
});

export const {
  useGetClassroomsQuery,
  useAddClassroomMutation,
  useDeleteClassroomMutation,
  useGetClassroomQuery,
  useUpdateClassroomMutation,
  useGetAvailableClassroomTypesQuery,
  useGetClassroomsByCampusQuery,
  useLazyGetClassroomQuery,
  useGetAllClassroomCapacitiesQuery,
  useLazyGetAllClassroomsQuery,
  useClassroomLoansMutation,
  useDeleteClassroomLoansMutation,
  useGetClassroomsLoansQuery,
} = classroomsApi;
