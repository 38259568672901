import { ChangeEvent, useState, useEffect } from 'react';

import {
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  InputLabel,
  Skeleton,
  TextField,
} from '@material-ui/core';
import { IconChevronRight } from '@tabler/icons';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../../hooks/useRedux';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import { SUPPORT_TYPES } from '../../../shared/constants/extensionRequestType';
import { bondingValues } from '../constants/formConstants';
import { bondingSchema } from '../constants/validationSchemas';
import {
  useAddBondingRequestMutation,
  useGetLinksValidationQuery,
} from '../slices/academicRequestsApiSlice';
import useGuard from '../hooks/useGuard';
import BondingProgramsDialog from './BondingProgramsDialog';
import { useControlModalStates } from '../../../shared/hooks/useControlModalStates';

import DragArea from '../../../shared/components/files/DragArea';
import ValidationList from '../components/Builder/Validation';
import ImportantAnnouncement from '../../../shared/components/messages-to-show/ImportantAnnouncement';

const BondingForm = () => {
  const { t } = useTranslation();

  const { hasAccess } = useGuard();
  const { currentProfile, currentRecordStudent } = useAppSelector((state) => state.access);
  const { userInfo } = useAppSelector((state) => state.auth);
  const { bondingProgram } = useAppSelector((state) => state.academicRequests);
  const [showAlerts, setShowAlerts] = useState(false);
  const [, setFile] = useState('');
  const [target, setTarget] = useState<File>(null!);

  const { open, handleCloseModal, handleOpenModalWith } = useControlModalStates();

  const onChangeFile = function (event: ChangeEvent<HTMLInputElement>) {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e: any) => {
        setFile(e.target.result);
      };
      reader.readAsDataURL(event.target.files[0]);
      setTarget(event.target.files[0]);
    }
  };
  const onRemoveFile = function () {
    setFile('');
    setTarget(null!);
  };

  const [addBonding, { isError, error, isSuccess, isLoading }] = useAddBondingRequestMutation();

  const {
    data: dataValidation,
    error: errorValidation,
    isLoading: isLoadingValidation,
  } = useGetLinksValidationQuery({ studentRecordId: currentRecordStudent?.id! });

  const { handleSubmit, getFieldProps, setFieldValue, errors, touched } = useFormik({
    initialValues: bondingValues,
    validationSchema: bondingSchema,
    validate: () => {
      const fileNotAllowed = `${t('students.applications.bonding.validation1')}`;
      const Nofile = `${t('students.applications.bonding.validation2')}`;
      const errors: any = {};
      if (target) {
        let extension = target?.type;
        if (SUPPORT_TYPES.indexOf(extension) < 0) {
          errors.files = fileNotAllowed;
        }
      } else {
        errors.files = Nofile;
      }
      return errors;
    },
    onSubmit: async (values) => {
      await addBonding({
        profileId: currentProfile?.profileId!,
        userId: String(userInfo?.id!),
        studentRecordId: currentRecordStudent?.id!,
        bondingPayload: {
          files: target,
          type_document: 2,
          app_description: '',
          project_name: values.project_name,
          project_research_id: bondingProgram?.value!,
        },
      });
      setShowAlerts(true);
    },
  });

  console.log(dataValidation);
  console.log(errorValidation);

  useEffect(() => {
    if (bondingProgram) setFieldValue('project_name', bondingProgram.name);
  }, [bondingProgram, setFieldValue]);

  return (
    <>
      {userInfo?.studentInfo?.typeStudent !== 'NIV' ? (
        <form onSubmit={handleSubmit}>
          <Grid container direction="column">
            <Grid item xs={12}>
              {isError && showAlerts && (
                <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
              )}
              {isSuccess && showAlerts && (
                <SuccessAlert
                  message={t('students.applications.success_message')}
                  handleDismiss={() => setShowAlerts(false)}
                />
              )}
            </Grid>
            {isLoadingValidation ? (
              <>
                <Skeleton height="30vh" sx={{ mb: 2 }} variant="rectangular" />
                <Skeleton height="10vh" sx={{ mb: 2 }} variant="rectangular" />
                <Skeleton height="25vh" sx={{ mb: 2 }} variant="rectangular" />
              </>
            ) : (
              <>
                <Grid item xs={12} mb={2}>
                  <ValidationList data={dataValidation!} />
                </Grid>

                {!dataValidation?.some(({ status }) => !status) ? (
                  <>
                    <Grid item xs={12}>
                      <Grid container justifyContent="space-between">
                        <InputLabel>{t('students.applications.bonding.project_name')}</InputLabel>
                        <Button
                          variant="outlined"
                          // size="large"
                          color="primary"
                          endIcon={<IconChevronRight size={20} />}
                          onClick={() => handleOpenModalWith()}
                        >
                          {t('students.applications.bonding.projects')}
                        </Button>
                      </Grid>
                      <TextField
                        fullWidth
                        error={Boolean(errors.project_name && touched.project_name)}
                        placeholder={t('students.applications.bonding.project_placeholder')}
                        variant="standard"
                        InputProps={{ inputProps: { readOnly: true } }}
                        {...getFieldProps('project_name')}
                        value={bondingProgram?.name || ''}
                      />
                      {errors.project_name && touched.project_name && (
                        <FormHelperText error>{errors.project_name}</FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      {/* <InputLabel>{t('students.applications.upload_file')}</InputLabel> */}
                      <DragArea
                        handleChange={onChangeFile}
                        label={t('students.applications.upload_file')}
                        onRemoveFile={onRemoveFile}
                        typesAccepted={'application/pdf'}
                        // file={file}
                      />
                      {/* <Grid item xs={12} md={12} container>
                <label className={classes.uploadFile}>
                  {file && <IconCircleX className={classes.closeButton} onClick={onRemoveFile} />}
                  <br />
                  <input name="files[]" type="file" hidden onChange={onChangeFile} />

                  <IconFile size={32} />
                  {<p>{!file ? `${t('students.applications.upload_file')}` : target.name}</p>}
                </label>
              </Grid> */}
                      <Grid item xs={12}>
                        {errors.files && touched.files && (
                          <FormHelperText error>{errors.files}</FormHelperText>
                        )}
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      alignItems="center"
                      justifyContent="flex-end"
                      spacing={2}
                      sx={{ mt: 1 }}
                    >
                      {hasAccess('save_bonding') && (
                        <Grid item>
                          <Button
                            type="submit"
                            variant="contained"
                            size="large"
                            color="primary"
                            disabled={isLoading}
                            endIcon={isLoading && <CircularProgress size={20} />}
                          >
                            {t('students.applications.save')}
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  </>
                ) : (
                  <ImportantAnnouncement />
                )}
              </>
            )}
          </Grid>
        </form>
      ) : (
        <ImportantAnnouncement />
      )}

      <BondingProgramsDialog open={open} handleCloseDialog={handleCloseModal} />
    </>
  );
};
export default BondingForm;
