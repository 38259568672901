import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Profile } from "../interfaces/profiles.interfaces";

interface ProfilesState {
  profilesList: Profile[];
  deleteModalOpen: boolean;
  profileIdToDelete?: number;
}

const initialState: ProfilesState = {
  profilesList: [],
  deleteModalOpen: false,
};

const profilesSlice = createSlice({
  name: 'Profiles',
  initialState,
  reducers: {
    setProfilesList: (state, action: PayloadAction<Profile[]>) => {
      state.profilesList = action.payload;
    },
    setDeleteModalOpen: (state, action: PayloadAction<boolean>) => {
      state.deleteModalOpen = action.payload;
    },
    openDeleteModalWith: (state, action: PayloadAction<number>) => {
      state.profileIdToDelete = action.payload;
      state.deleteModalOpen = true;
    },
    resetProfilesIdToDelete: (state) => {
      state.profileIdToDelete = undefined;
    },
  },
});

export const {
  setProfilesList,
  setDeleteModalOpen,
  openDeleteModalWith,
  resetProfilesIdToDelete,
} = profilesSlice.actions;

export default profilesSlice.reducer;