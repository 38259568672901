import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../hooks/useRedux';
import MainCard from '../../../shared/components/cards/MainCard';
import AddClassroomEdLvlForm from '../components/AddClassroomEdLvlForm';

import DeleteClassroomEdLvlDialog from '../components/DeleteClassroomEdLvlDialog';

const ClassroomsEducationLevelsScreen = () => {
  const { t } = useTranslation();

  const { classroomEdLvlIdToDelete } = useAppSelector((state) => state.classroomEdLvl);

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid container justifyContent={'space-between'} sx={{ mb: 3 }}>
        <Typography variant="h2">{t('academic.classrooms_distribution.title')}</Typography>
      </Grid>

      <AddClassroomEdLvlForm />
      {classroomEdLvlIdToDelete && <DeleteClassroomEdLvlDialog />}
    </Grid>
  );
};

export default ClassroomsEducationLevelsScreen;
