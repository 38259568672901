import { useState } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import Autocomplete from '@material-ui/core/Autocomplete';
// import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
// import { DateRange } from '@material-ui/lab/DateRangePicker';

import InputLabel from '../../../../shared/components/forms/InputLabel';
import { EducationSchema } from '../../constants/validationSchemas';
import { PropsFormEdit } from './';
import {
  useGetCatalogByAcronymChildrenQuery,
  useGetCatalogByAcronymQuery,
} from '../../../../shared/slices/catalogApi';
import { useGetAvailableInstitutesQuery } from '../../../institutes/slices/institutesApiSlice';
import { useGetAvailablesTypeEducationsQuery } from '../../../requisition-teacher/slices/requisitionsApiSlice';
import { studyStatusList } from '../../../requisition-teacher/constants/statusRequisition';
import LocalizedDateRangePicker from '../../../../shared/components/controlled/LocalizedDateRangePicker';
import { Catalog } from '../../../../shared/interfaces/catalogs.interfaces';
import { useAddEducationStudentMutation } from '../../slices/studentsApiSlice';
import LoadingButton from '../../../../shared/components/buttons/LoadingButton';
import { useGetAvaliblesEducationLevelsQuery } from '../../../education-levels/slices/educationLevelsApiSlice';
import ErrorAlert from '../../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../../shared/components/alerts/SuccessAlert';

const EducationCardForm = ({ data }: PropsFormEdit) => {
  const { t } = useTranslation();

  const [showAlerts, setShowAlerts] = useState(false);

  const { errors, touched, handleSubmit, setFieldValue, values, resetForm, getFieldProps } =
    useFormik({
      initialValues: {
        institutionTypeId: 0,
        institutionId: 0,
        provinceId: 0,
        cityId: 0,
        educationLevel: 0,
        studyStatus: '',
        senescytNumber: '',
        countryId: {} as Catalog,
        startDate: new Date(),
        endDate: new Date(),
        // journeyId: 0,
      },
      validationSchema: EducationSchema,
      onSubmit: async (values) => {
        await addEducation({
          educationPayload: {
            personEducations: [
              {
                city_id: values.cityId,
                country_id: values.countryId.id,
                education_level_id: values.educationLevel,
                education_status: values.studyStatus,
                end_date: values.endDate,
                institute_id: values.institutionId,
                person_id: data?.id!,
                senescyt_register_number: values.senescytNumber,
                start_date: values.startDate,
                type_education_id: values.institutionTypeId,
              },
            ],
          },

          studentId: data?.user.student.id! + '',
        });
        setShowAlerts(true);
        resetForm();
      },
    });

  const { data: countries = [], isLoading: isLoadingCountries } = useGetCatalogByAcronymQuery({
    acronym: 'PS',
  });

  const { data: provinces = [], isLoading: isLoadingProvince } =
    useGetCatalogByAcronymChildrenQuery(
      {
        acronym: values.countryId?.cat_keyword || '',
      },
      { skip: !values.countryId?.cat_keyword }
    );

  const { data: availablesTypeEducations = [], isLoading: isLoadingTypeEducations } =
    useGetAvailablesTypeEducationsQuery({});

  const { data: availablesInstitutes, isLoading: isLoadingInstitutes } =
    useGetAvailableInstitutesQuery(
      {
        typeInstituteId: values?.institutionTypeId,
      },
      { skip: !values?.institutionTypeId }
    );

  const { data: educationLevelAvailables = [], isLoading: isLoadingEdLv } =
    useGetAvaliblesEducationLevelsQuery({});

  const [addEducation, { isLoading, isError, error, isSuccess }] = useAddEducationStudentMutation();

  return (
    <form onSubmit={handleSubmit}>
      <Grid item xs={12}>
        {isError && showAlerts && (
          <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
        )}

        {isSuccess && showAlerts && (
          <SuccessAlert
            message={t('Educación de estudiante actualizado exitosamente')}
            handleDismiss={() => setShowAlerts(false)}
          />
        )}
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <InputLabel>{t('País')}</InputLabel>
            <Autocomplete
              options={countries || []}
              loading={isLoadingCountries}
              loadingText={t('Cargardo información')}
              noOptionsText={t('No hay paises que mostrar')}
              getOptionLabel={(option) => option.cat_name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(errors.countryId && touched.countryId)}
                  placeholder={t('Seleccione un país')}
                />
              )}
              value={countries?.find(({ id }) => id === values.countryId.id) || null}
              onChange={(_, value) => {
                setFieldValue('countryId', value);
              }}
              fullWidth
              clearIcon={null}
            />
            {errors.countryId && touched.countryId && (
              <FormHelperText error>{t(errors.countryId?.id || '')}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            <InputLabel>{t('Provincia')}</InputLabel>
            <Autocomplete
              options={provinces}
              loading={isLoadingProvince}
              loadingText={t('Cargando información')}
              noOptionsText={t('No hay provincias disponibles')}
              getOptionLabel={(option) => option.cat_name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(errors.provinceId && touched.provinceId)}
                  placeholder={t('Seleccione una provincia disponible')}
                />
              )}
              value={provinces?.find(({ id }) => id === values.provinceId) || null}
              onChange={(_, value) => {
                setFieldValue('provinceId', value?.id || '');
              }}
              fullWidth
              clearIcon={null}
            />
            {errors.provinceId && touched.provinceId && (
              <FormHelperText error>{t(errors.provinceId)}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            <InputLabel>{t('Ciudad')}</InputLabel>
            <Autocomplete
              options={provinces.find(({ id }) => id === values.provinceId)?.children || []}
              loading={isLoadingProvince}
              loadingText={t('Cargando infromación')}
              noOptionsText={t('No hay ciudades disponibles')}
              getOptionLabel={(option) => option.cat_name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(errors.cityId && touched.cityId)}
                  placeholder={t('Seleccione una ciudad disponible')}
                />
              )}
              value={
                provinces
                  ?.find(({ id }) => id === values.provinceId)
                  ?.children.find(({ id }) => id === values.cityId) || null
              }
              onChange={(_, value) => {
                setFieldValue('cityId', value?.id || 0);
              }}
              fullWidth
              clearIcon={null}
            />
            {errors.cityId && touched.cityId && (
              <FormHelperText error>{t(errors.cityId)}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            <InputLabel>{t('Carrera')}</InputLabel>
            <Autocomplete
              options={educationLevelAvailables}
              loading={isLoadingEdLv}
              loadingText={t('Cargando carreras')}
              noOptionsText={t('No hay carreraras disponibles')}
              getOptionLabel={(option) => option.edu_name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(errors.educationLevel && touched.educationLevel)}
                  placeholder={t('Seleccione una carrera')}
                />
              )}
              value={
                educationLevelAvailables?.find(({ id }) => id === values.educationLevel) || null
              }
              onChange={(_, value) => {
                setFieldValue('educationLevel', value?.id || '');
              }}
              fullWidth
              clearIcon={null}
            />
            {errors.educationLevel && touched.educationLevel && (
              <FormHelperText error>{t(errors.educationLevel)}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            <InputLabel>{t('Estado de los estudios')}</InputLabel>
            <Autocomplete
              options={studyStatusList}
              loading={isLoadingProvince}
              loadingText={t('Cargando estados')}
              noOptionsText={t('No hay estados disponibles')}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(errors.studyStatus && touched.studyStatus)}
                  placeholder={t('Seleccion un estado')}
                />
              )}
              value={studyStatusList?.find(({ value }) => value === values.studyStatus) || null}
              onChange={(_, value) => {
                setFieldValue('studyStatus', value?.value || '');
              }}
              fullWidth
              clearIcon={null}
            />
            {errors.provinceId && touched.provinceId && (
              <FormHelperText error>{t(errors.provinceId)}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            <LocalizedDateRangePicker
              value={[values.startDate, values.endDate]}
              onChange={(value) => {
                setFieldValue('startDate', value[0]);
                setFieldValue('endDate', value[1]);
              }}
              startError={
                Boolean(touched.startDate && errors.startDate)
                  ? (errors.startDate as string)
                  : undefined
              }
              endError={
                Boolean(touched.endDate && errors.endDate) ? (errors.endDate as string) : undefined
              }
              // doubleRow
              showInputLabel
              hideLabel
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <InputLabel>{t('Tipo de institución')}</InputLabel>
            <Autocomplete
              options={availablesTypeEducations}
              loading={isLoadingTypeEducations}
              loadingText={t('Cargando tipo de instituciones')}
              noOptionsText={t('No hay tipo de instituciones disponibles')}
              getOptionLabel={(option) => option.typ_edu_name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(errors.institutionTypeId && touched.institutionTypeId)}
                  placeholder={t('Seleccione un tipo de institución disponible')}
                />
              )}
              value={
                availablesTypeEducations?.find(({ id }) => id === values.institutionTypeId) || null
              }
              onChange={(_, value) => {
                setFieldValue('institutionTypeId', value?.id || 0);
              }}
              fullWidth
              clearIcon={null}
            />
            {errors.institutionTypeId && touched.institutionTypeId && (
              <FormHelperText error>{t(errors.institutionTypeId)}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            <InputLabel>{t('Instituto')}</InputLabel>
            <Autocomplete
              options={availablesInstitutes || []}
              loading={isLoadingInstitutes}
              loadingText={t('Cargando institutos')}
              noOptionsText={t('No hay institutos disponibles')}
              getOptionLabel={(option) => option.inst_name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(errors.institutionId && touched.institutionId)}
                  placeholder={t('Seleccionar un instituto')}
                />
              )}
              value={availablesInstitutes?.find(({ id }) => id === values.institutionId) || null}
              onChange={(_, value) => {
                setFieldValue('institutionId', value?.id || 0);
              }}
              fullWidth
              clearIcon={null}
            />
            {errors.institutionId && touched.institutionId && (
              <FormHelperText error>{t(errors.institutionId)}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            <InputLabel>{t('Registro senescyt')}</InputLabel>
            <TextField
              {...getFieldProps('senescytNumber')}
              error={Boolean(errors.senescytNumber && touched.senescytNumber)}
              placeholder={t('Ingrese el número de registro senescyt')}
              fullWidth
            />
            {errors.senescytNumber && touched.senescytNumber && (
              <FormHelperText error>{t(errors.senescytNumber)}</FormHelperText>
            )}
          </Grid>
        </Grid>

        <Grid
          item
          container
          alignItems="center"
          justifyContent="flex-end"
          spacing={2}
          xs={12}
          mt={2}
        >
          {/* {JSON.stringify(errors)} */}
          <LoadingButton type="submit" isLoading={isLoading} children="Agregar" />
        </Grid>
      </Grid>
    </form>
  );
};

export default EducationCardForm;
