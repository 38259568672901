import { Catalog } from '../../../shared/interfaces/catalogs.interfaces';

export interface DepositsTransfersStatusPayload {
  payments_status_id: number;
  observation: string;
}

export interface DepositsTransfersPayload {
  start_date: Date;
  end_date: Date;
  approved_dates: boolean;
  approved_start_date: Date | null;
  approved_end_date: Date | null;
  transaction_dates: boolean;
  transaction_start_date: Date | null;
  transaction_end_date: Date | null;
  user_id: number | undefined; //cajero
  financial_institution_target_id: number | undefined;
  payment_way_id: number | undefined;
  start_date_collection?: Date;
  end_date_collection?: Date;
  payment_status_id: number | null;
  search: string;
  keyword_payment_way?: string;
  payment_dep_transf?: string;
  status_check?: string;
}

export interface DepositsTransfersResponse {
  current_page: number;
  data: DepositTransfer[];
  first_page_url: string;
  from: number;
  last_page: number;
  last_page_url: string;
  links: Link[];
  next_page_url: string;
  path: string;
  per_page: string;
  prev_page_url: null;
  to: number;
  total: number;
  debug: Debug;
}

export interface DepositTransfer {
  id: number;
  cash_point_id: string;
  payment_way_id: string;
  debtor_id: string;
  financial_institution_origin_id: null | string;
  financial_institution_target_id: null | string;
  payment_amount: string;
  o_payment_amount: string;
  payment_date: string;
  card_code: null;
  card_number: null;
  voucher_number: null | string;
  lote: null;
  red_pago: null;
  observation: string;
  reference?: string;
  name_holder_origin_account: null | string;
  cuenta_origen: null;
  cuenta_destino: null | string;
  start_date_collection: Date | null;
  end_date_collection: null;
  issues_invoice: string;
  url_file: null | string;
  name_file: null | string;
  status_id: string;
  id_transaction: null | string;
  details?: Detail[];
  pay: Pay;
  payment_way: Catalog;
  financial_institution_origin: FinancialInstitution | null;
  financial_institution_target: FinancialInstitution | null;
  debtor: Debtor;
  o_detaill_collect: ODetaillCollect;
  check?: Check;
  other_methods_payment: Catalog;
  stud_code: string;
  cash_point_identification: string;
  cash_point_full_name: string;
  student_id: string;
  student_identification: string;
  student_full_name: string;
  num_doc_fact: string;
  sri_document_cab_id: string;
  transaction_date: string;
  approval_rejection_date: string;
  /*payment_description?: string;
  fees?: Fees;*/
}

export interface Check {
  id: number;
  issuer_name: string;
  cheque_number: string;
  account_number: string;
  account_type: null;
  cheque_date: Date;
  cheque_mount: string;
  observation: string;
  cash_collection_id: string;
  bank_id: string;
  status_id: string;
  status: PaymentWay;
  bank: FinancialInstitutionOrigin;
}

export interface FinancialInstitutionOrigin {
  id: number;
  fin_int_name: string;
  fin_int_description: null;
  fin_int_acronym: string;
  fin_int_keyword: string;
  parent_id: string;
  status_id: number;
}

export interface ODetaillCollect {
  fees_amount_sin_desc: string;
  sum_descu: string;
}
export interface Detail {
  fees_description: FeesDescription;
  fees_name_identifier: FeesNameIdentifier;
  fees_identifier: string;
  payment_id: string;
  cat_name: DetailCatName;
  cat_keyword: DetailCatKeyword;
  fees_start_date: Date;
  fees_expiration_date: Date;
  amount: string;
  o_amount: string;
}
export enum FeesDescription {
  CuotaMatriculaFase1 = 'Cuota Matricula Fase 1',
}

export enum FeesNameIdentifier {
  Cuota11 = 'Cuota 1/1',
  InscripcionGradoOnlineCuota12 = 'Inscripción Grado Online - Cuota 1/2',
  InscripcionGradoOnlineCuota13 = 'Inscripción Grado Online - Cuota 1/3',
  InscripcionGradoOnlineCuota15 = 'Inscripción Grado Online - Cuota 1/5',
  InscripcionGradoOnlineCuota22 = 'Inscripción Grado Online - Cuota 2/2',
  InscripcionGradoOnlineCuota25 = 'Inscripción Grado Online - Cuota 2/5',
  InscripcionGradoOnlineCuota35 = 'Inscripción Grado Online - Cuota 3/5',
  InscripcionGradoOnlineCuota45 = 'Inscripción Grado Online - Cuota 4/5',
  InscripcionGradoOnlineCuota55 = 'Inscripción Grado Online - Cuota 5/5',
  RegistroDeCreditoDeEstudiantePorPeriodoCuota14 = 'Registro De Crédito De Estudiante Por Período - Cuota 1/4',
  RegistroDeCreditoDeEstudiantePorPeriodoCuota24 = 'Registro De Crédito De Estudiante Por Período - Cuota 2/4',
  RegistroDeCreditoDeEstudiantePorPeriodoCuota34 = 'Registro De Crédito De Estudiante Por Período - Cuota 3/4',
  RegistroDeCreditoDeEstudiantePorPeriodoCuota44 = 'Registro De Crédito De Estudiante Por Período - Cuota 4/4',
}
export enum DetailCatKeyword {
  FormaPagoCheque = 'forma-pago-cheque',
  FormaPagoDeposito = 'forma-pago-deposito',
  FormaPagoSaldoFavor = 'forma-pago-saldo-favor',
  FormaPagoTarjetaDeb = 'forma-pago-tarjeta-deb',
  FormaPagoTransferencia = 'forma-pago-transferencia',
  FormaProntoPago = 'forma-pronto-pago',
}

export enum DetailCatName {
  Cheque = 'Cheque',
  Depósito = 'Depósito',
  ProntoPago = 'Pronto pago',
  SaldoAFavor = 'Saldo a favor',
  TarjetaDeDébito = 'Tarjeta de Débito',
  Transferencia = 'Transferencia',
}
export interface Debtor {
  id: number;
  person_id: string;
  deb_debtor_balance: string;
  deb_total_debt: string;
  deb_total_payment: string;
  deb_total_expired: string;
  deb_total_debt_due: string;
  status_id: string;
  person: Person;
}

export interface Person {
  id: number;
  pers_identification: string;
  pers_firstname: string;
  pers_secondname: string;
  pers_first_lastname: string;
  pers_second_lastname: string;
  pers_gender: string;
  pers_email: string;
  pers_date_birth: Date;
  pers_direction: string;
  pers_phone_home: string;
  pers_cell: string;
  pers_num_child: string;
  pers_profession: null | string;
  pers_num_bedrooms: null;
  pers_study_reason: string;
  pers_num_taxpayers_household: string;
  pers_has_vehicle: null;
  pers_has_disability: string;
  pers_disability_identification: string;
  pers_disability_percent: null;
  pers_is_provider: null;
  isupdate_required: string;
  nationality_id: string;
  type_religion_id: string;
  status_marital_id: string;
  city_id: string;
  current_city_id: string;
  sector_id: null;
  type_identification_id: string;
  ethnic_id: string;
  vivienda_id: null;
  type_person: null;
  pers_full_name: string;
  pers_full_lastname: string;
  pers_full_name_lastname: string;
  pers_full_lastname_secondname: string;
  pers_full_lastname_fullname: string;
  pers_full_secondname_secondlastname: string;
  pers_full_secondlastname_secondname: string;
  disease_id: null;
}

export interface FinancialInstitution {
  id: number;
  fin_int_name: string;
  fin_int_description: null;
  fin_int_acronym: string;
  fin_int_keyword: string;
  parent_id: string;
  status_id: number;
}

export interface Pay {
  id: number;
  cash_collection_id: string;
  debtor_student_id: string;
  pay_applied_amount: string;
  pay_amount: string;
  pay_balance: string;
  o_pay_balance: string;
  payments_status_id: string;
  status_payments: PaymentWay;
}

export interface PaymentWay {
  id: number;
  cat_name: CatName;
  cat_description: string;
  cat_acronym: string;
  cat_keyword: string;
  status_id: string;
}

export enum CatKeyword {
  FormaPagoDeposito = 'forma-pago-deposito',
  FormaPagoTransferencia = 'forma-pago-transferencia',
  PaymentPending = 'payment-pending',
}

export enum CatName {
  Depósito = 'Depósito',
  Pendiente = 'Pendiente',
  Transferencia = 'Transferencia',
  Aprobado = 'Aprobado',
  Rechazado = 'Rechazado',
}
export interface Fees {
  fees_name_identifier: string;
  fees_description: string;
}

export interface Debug {
  database: Database;
  cache: Cache;
  profiling: Profiling[];
  memory: Memory;
}

export interface Cache {
  hit: Forget;
  miss: Forget;
  write: Forget;
  forget: Forget;
}

export interface Forget {
  keys: string[];
  total: number;
}

export interface Database {
  total: number;
  items: Item[];
}

export interface Item {
  connection: Connection;
  query: string;
  time: number;
}

export enum Connection {
  Tenant = 'tenant',
}

export interface Memory {
  usage: number;
  peak: number;
}

export interface Profiling {
  event: string;
  time: number;
}

export interface Link {
  url: null | string;
  label: string;
  active: boolean;
}
