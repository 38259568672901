import { Grid, IconButton, Typography, useTheme } from '@material-ui/core';
import { IconArrowNarrowLeft } from '@tabler/icons';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import MainCard from '../../../shared/components/cards/MainCard';
import AddTagForm from '../components/AddTagForm';

const AddTagScreen = () => {
  const theme = useTheme();
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <Grid component={MainCard} sx={{ p: 2 }}>
      <Grid item container direction="row" alignItems="center" sx={{ mb: 3 }}>
        <IconButton onClick={() => history.goBack()}>
          <IconArrowNarrowLeft size={30} color={theme.palette.text.dark} />
        </IconButton>
        <Typography variant="h2">{t('shared.add_button.tooltip')}</Typography>
      </Grid>
      <AddTagForm />
    </Grid>
  );
};

export default AddTagScreen;
