import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  SearchPersonParam,
  StudentRecord,
} from '../../../shared/interfaces/searchPerson.interface';
import { FormProduct } from '../interfaces/payload.interface';
import { parseISO } from 'date-fns';

interface generateDebtsState {
  currentTab: number; // 0 = deudas unitarias -- 1 = deudas masivas

  currentClient?: SearchPersonParam;
  currentStudentRecord?: StudentRecord;

  currentClientMassiveDebts?: SearchPersonParam;
  currentStudentRecordMassiveDebts?: StudentRecord;
  currentPeriodIdMassiveDebts?: number;

  productsCollection: FormProduct[];
  totalNetAmout: number;

  dates: [Date | null, Date | null];

  debtor?: string;
}

const initialState: generateDebtsState = {
  currentTab: 0,
  productsCollection: [],
  totalNetAmout: 0,

  currentClient: undefined,
  currentStudentRecord: undefined,

  currentClientMassiveDebts: undefined,
  currentStudentRecordMassiveDebts: undefined,
  currentPeriodIdMassiveDebts: undefined,

  dates: [null, null],

  debtor: undefined,
};

const generateDebtsSlice = createSlice({
  name: 'generateDebts',
  initialState,
  reducers: {
    setCurrentTab: (state, action: PayloadAction<number>) => {
      state.currentTab = action.payload;
    },

    //student info generate normal debts
    setCurrentClient: (state, action: PayloadAction<SearchPersonParam>) => {
      state.currentClient = action.payload;

      const studentRecords = action.payload.user.student?.student_records;
      if (studentRecords?.length) state.currentStudentRecord = studentRecords[0];

      generateDebtsSlice.caseReducers.resetProductsCollection(state);
      generateDebtsSlice.caseReducers.resetDate(state);
    },
    setCurrentStudentRecord: (state, action: PayloadAction<StudentRecord>) => {
      state.currentStudentRecord = action.payload;

      generateDebtsSlice.caseReducers.resetProductsCollection(state);
      generateDebtsSlice.caseReducers.resetDate(state);
    },
    resetClient: (state) => {
      state.currentClient = undefined;
      state.currentStudentRecord = undefined;
    },

    //student info generate massive debts
    setCurrentClientMassiveDebts: (state, action: PayloadAction<SearchPersonParam>) => {
      state.currentClientMassiveDebts = action.payload;

      const studentRecords = action.payload.user.student?.student_records;
      if (studentRecords?.length) state.currentStudentRecordMassiveDebts = studentRecords[0];
    },
    setCurrentStudentRecordMassiveDebts: (state, action: PayloadAction<StudentRecord>) => {
      state.currentStudentRecordMassiveDebts = action.payload;
    },
    setCurrentPeriodIdMassiveDebts: (state, action: PayloadAction<number>) => {
      state.currentPeriodIdMassiveDebts = action.payload;
    },
    resetClientMassiveDebts: (state) => {
      state.currentClientMassiveDebts = undefined;
      state.currentStudentRecordMassiveDebts = undefined;
      state.currentPeriodIdMassiveDebts = undefined;
    },

    setProductsCollection: (state, action: PayloadAction<FormProduct[]>) => {
      state.productsCollection = action.payload;

      //add initial dates
      if (
        action.payload.length === 1 &&
        action.payload[0].product?.pr_start_date &&
        state.dates[0] === null &&
        state.dates[1] === null
      ) {
        state.dates = [
          parseISO(action.payload[0].product?.pr_start_date),
          parseISO(
            action.payload[0].product?.pr_end_date || action.payload[0].product?.pr_start_date
          ),
        ];
      }

      let totalNetAmount = 0;
      action.payload.forEach((pr) => {
        totalNetAmount += Number(pr.productDetails?.total || 0) * (pr.quantity || 0);
      });
      state.totalNetAmout = totalNetAmount;
    },
    resetProductsCollection: (state) => {
      state.productsCollection = [];
    },

    setDate: (state, action: PayloadAction<[Date | null, Date | null]>) => {
      state.dates = action.payload;
    },
    resetDate: (state) => {
      state.dates = [null, null];
    },

    setDebtor: (state, action: PayloadAction<string | undefined>) => {
      state.debtor = action.payload;
    },
  },
});

export const {
  setCurrentTab,

  //for normal debts
  setCurrentClient,
  setCurrentStudentRecord,
  resetClient,

  //for massive debts
  setCurrentClientMassiveDebts,
  setCurrentStudentRecordMassiveDebts,
  setCurrentPeriodIdMassiveDebts,
  resetClientMassiveDebts,

  setProductsCollection,
  resetProductsCollection,
  setDate,
  resetDate,
  setDebtor,
} = generateDebtsSlice.actions;

export default generateDebtsSlice.reducer;
