import { IconButton, Stack, Typography, Grid } from '@material-ui/core';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import {
  IconBuilding,
  IconBuildingWarehouse,
  IconCalendar,
  IconInfoCircle,
  IconSchool,
  IconTrash,
} from '@tabler/icons';
import { useAppDispatch } from '../../../../hooks/useRedux';
import { CourseStudentStatus } from '../interfaces/courseStudentStatus';
import { openDeleteModalWith } from '../slices/subjectsRegisterSlice';

const useStyles = makeStyles((theme) => ({
  boxContainer: {
    boxShadow: '0px 4px 20px 15px rgba(234, 240, 246, 0.5);',
    borderRadius: 10,
    padding: '20px',
  },
}));

interface props {
  course: CourseStudentStatus;
}

const SubjectRegisteredCard = ({ course }: props) => {
  const dispatch = useAppDispatch();

  const classes = useStyles();
  const theme = useTheme();

  const teacher = course?.course?.collaborator;

  const handleDelete = async (courseStudentId: number) => {
    dispatch(openDeleteModalWith(courseStudentId));
  };

  return (
    <Grid container className={classes.boxContainer}>
      <Grid item xs={12} sx={{ mb: 2 }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h3" sx={{ color: 'primary.main', mb: 1 }}>
              {course?.subject_curriculum?.matter_rename}
            </Typography>
          </Grid>
          <IconButton onClick={() => handleDelete(course.id)}>
            <IconTrash size={20} color={theme.palette.error.main} />
          </IconButton>
        </Stack>
      </Grid>
      <Grid item xs={12} container>
        <Grid item xs={6}>
          <Stack direction="row" spacing={1.5} alignItems="center">
            <IconInfoCircle color={theme.palette.grey[700]} size={20} />
            <Stack spacing={0.5}>
              <Typography sx={{ fontWeight: 600 }}>Paralelo</Typography>
              <Typography>{course?.course?.parallel?.par_name}</Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack direction="row" spacing={1.5} alignItems="center">
            <IconBuildingWarehouse color={theme.palette.grey[700]} size={20} />
            <Stack spacing={0.5}>
              <Typography sx={{ fontWeight: 600 }}>Aula</Typography>
              <Typography>{course?.course?.classroom?.cl_name}</Typography>
            </Stack>
          </Stack>
        </Grid>
        {/* <Grid item xs={6}>
          <Stack direction="row" spacing={1.5} alignItems="center">
            <IconBuilding color={theme.palette.grey[700]} size={20} />
            <Stack spacing={0.5}>
              <Typography sx={{ fontWeight: 600 }}>Campus</Typography>
              <Typography>{course?.course?.classroom?.campus?.cam_name}</Typography>
            </Stack>
          </Stack>
        </Grid> */}
      </Grid>
      <Grid item xs={12} container>
        {teacher && (
          <Grid item xs={6}>
            <Stack direction="row" spacing={1.5} alignItems="center">
              <IconSchool color={theme.palette.grey[700]} size={20} />
              <Stack spacing={0.5}>
                <Typography sx={{ fontWeight: 600 }}>Docente</Typography>
                <Typography>
                  {teacher
                    ? `${teacher?.user?.person?.pers_first_lastname} ${teacher?.user?.person?.pers_second_lastname} ${teacher?.user?.person?.pers_firstname}`
                    : null}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
        )}
        <Grid item xs={6}>
          <Stack direction="row" spacing={1.5} alignItems="center">
            <IconBuilding color={theme.palette.grey[700]} size={20} />
            <Stack spacing={0.5}>
              <Typography sx={{ fontWeight: 600 }}>Campus</Typography>
              <Typography>{course?.course?.classroom?.campus?.cam_name}</Typography>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
      <Grid item xs={12} container>
        <Grid item xs={12}>
          <Stack direction="row" spacing={1.5} alignItems="center">
            <IconCalendar color={theme.palette.grey[700]} size={20} />
            <Stack spacing={0.5}>
              <Typography sx={{ fontWeight: 600 }}>Horario</Typography>
              <Typography>{course?.course?.hourhand?.hour_description}</Typography>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SubjectRegisteredCard;
