
import { mainApi } from '../../../../../../api/mainApi';
import { middlewareApi } from '../../../../../middleware/middlewareApi';
import { IResponse } from '../../../../../shared/interfaces/response.interface';
import { IntegralReport, WeightingReport } from '../interfaces/integralsReports.interface';

const integralReportsTags = middlewareApi.enhanceEndpoints({
  addTagTypes: ['IntegralReports'],
});

export const integralReportsApi = integralReportsTags.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * @GET integral evaluations report
     */
    getIntegralsReports: builder.query<
      IResponse<IntegralReport>,
      { page: number; perPage: number; search: string; sort?: string; typeSort?: string }
    >({
      queryFn: async ({ page, perPage, search = '', sort, typeSort }) => {
        try {
          const { data } = await mainApi.get<IResponse<IntegralReport>>(
            `/eva/integral-evaluations/completes`,
            {
              params: {
                page,
                size: perPage,
                search,
                ...(sort && { sort }),
                ...(typeSort && { type_sort: typeSort }),
              },
            },
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      // providesTags: (result) =>
      //   result
      //     ? [
      //         ...result.map(({ id }) => ({ type: 'Course', id } as const)),
      //         { type: 'Course', id: 'LIST' },
      //       ]
      //     : [{ type: 'Course', id: 'LIST' }],
    }),

    /**
     * @GET integral evaluations report
     */
    getIntegralsReportsByIds: builder.query<
      IntegralReport,
      { integral_evaluation_id: string; user_id: string }
    >({
      // eslint-disable-next-line camelcase
      queryFn: async ({ integral_evaluation_id, user_id }) => {
        try {
          const { data } = await mainApi.get<IntegralReport>(`/eva/integral-evaluations/completes`, {
            // eslint-disable-next-line camelcase
            params: { integral_evaluation_id, user_id },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      // providesTags: (result) =>
      //   result
      //     ? [
      //         ...result.map(({ id }) => ({ type: 'Course', id } as const)),
      //         { type: 'Course', id: 'LIST' },
      //       ]
      //     : [{ type: 'Course', id: 'LIST' }],
    }),

    /**
     * @GET integral evaluations weighting report
     */
    getIntegralWeightingReport: builder.query<
      IResponse<WeightingReport>,
      { evaId: string; userId: string }
    >({
      queryFn: async ({ evaId, userId }) => {
        try {
          const { data } = await mainApi.get<IResponse<WeightingReport>>(
            `/eva/integral-evaluations/${evaId}/user/${userId}`,
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      // providesTags: (result) =>
      //   result
      //     ? [
      //         ...result.map(({ id }) => ({ type: 'Course', id } as const)),
      //         { type: 'Course', id: 'LIST' },
      //       ]
      //     : [{ type: 'Course', id: 'LIST' }],
    }),

    //
  }),
});

export const {
  useGetIntegralsReportsQuery,
  useLazyGetIntegralsReportsQuery,
  useGetIntegralsReportsByIdsQuery,
  useLazyGetIntegralsReportsByIdsQuery,
  useGetIntegralWeightingReportQuery,
  useLazyGetIntegralWeightingReportQuery,
} = integralReportsApi;
