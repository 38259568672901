import { Grid, IconButton, Typography } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import MainCard from '../../../shared/components/cards/MainCard';
import PosgraduatePlanningHourhandsForm from '../components/PosgraduatePlanningHourhandsForm';

const AddPosgraduatePlanningHourhandsScreen = () => {
  const history = useHistory();

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid container alignItems="center" sx={{ mb: 3 }}>
        <IconButton
          onClick={() => {
            history.goBack();
          }}
        >
          <ArrowBack />
        </IconButton>
        <Typography variant="h2">Agregar horarios de posgrado</Typography>
      </Grid>
      <PosgraduatePlanningHourhandsForm />
    </Grid>
  );
};

export default AddPosgraduatePlanningHourhandsScreen;
