import { Box, useTheme } from '@material-ui/core';
import CustomIcon from '../../../../../shared/components/illustrations/CustomIcon';
import { EvaluationDetailQuestion } from '../../interfaces/evaluationDetail.interfaces';
import { DetailsActivity } from '../../interfaces/evaluationFinished.interfaces';
import CardNote from './CardNote';
import image from '../../../../../../assets/images/no-tests.png';
import FaceIcon from './FaceIcon';

interface Props {
  score: string;
  califications: DetailsActivity[];
  questions: EvaluationDetailQuestion[];
  hideNote?: boolean;
  actualScore?: string;
}

const ViewVisivleNote = ({
  score,
  // califications,
  questions,
  hideNote = false,
  actualScore,
}: Props) => {
  const isQuestionCommentary = questions.find((question) => question.type_answer_id === '625');
  const questionsHideCommentary = questions.filter((question) => question.type_answer_id !== '625');
  const getCalification = () => {
    let calificationTotal: number = 0;
    // califications.forEach((calification) => {
    //   calification.answered_activities.forEach((score) => {
    //     calificationTotal += Number(score.csact_score_evaluate);
    //   });
    // });
    calificationTotal = parseFloat(actualScore || '0');
    return calificationTotal;
  };

  const scoreNumber = Number(score);
  const calificationTotal = getCalification();
  console.log('califications', calificationTotal);
  const setTypeCardNote = () => {
    if (calificationTotal >= 0 && calificationTotal < scoreNumber / 2) {
      return 'bad';
    } else if (calificationTotal >= scoreNumber / 2 && calificationTotal < scoreNumber) {
      return 'regular';
    }
    return 'good';
  };
  const type = isQuestionCommentary || hideNote ? 'procesing' : setTypeCardNote();

  const theme = useTheme();

  const setColor = (type: string) => {
    switch (type) {
      case 'good':
        return '#00C853';

      case 'regular':
        return '#FFC107';

      case 'bad':
        return '#D84315';
    }

    return theme.palette.secondary.main;
  };

  return (
    <Box
      sx={{
        display: 'flex',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '75%',
          right: '95%',
          mb: 4,
        }}
      >
        <CustomIcon icon={image} customWidth={200} />
      </Box>
      <CardNote
        calificationBase={!isQuestionCommentary ? score : questions.length.toString()}
        type={type}
        calification={
          !isQuestionCommentary
            ? calificationTotal.toString()
            : questionsHideCommentary.length.toString()
        }
        setColor={setColor}
        hideNote={hideNote}
      />
      <FaceIcon
        type={type}
        setColor={() => {
          return setColor(type);
        }}
      />
    </Box>
  );
};

export default ViewVisivleNote;
