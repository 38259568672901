import {
  Grid,
  Autocomplete,
  InputLabel,
  TextField,
  Tooltip,
  IconButton,
  Typography,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import { Cancel, CheckCircle, Description, Preview } from '@material-ui/icons';
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/useRedux';
import { useTableSearch } from '../../../../../../hooks/useTableSearch';
import LocalizedDatePicker from '../../../../../shared/components/controlled/LocalizedDatePicker';
import SearchPaginatedTableAndCollapsibleItems from '../../../../../shared/components/tables/SearchPaginatedTableAndCollapsibleItems';
import { useGetAllEvaluationTypesQuery } from '../../evaluation_type/slices/evaluationTypeApiSlice';

import { useGetResultsEvaQuery, useLazyGetCoursesListAllQuery } from '../slices/formsApiSlice';
import {
  setFormTable,
  setStart_date,
  setEnd_date,
  setIsOpenDocumentDialog,
  setFormIdSelected,
  setIsOpenDialogOpen,
} from '../slices/formSlice';

import {
  useGetAcademicOfferingQuery,
  useGetAvailablesOffersQuery,
  useGetPeriodAcademicOfferingQuery,
} from '../../../../../maintenance/academic-offerings/slices/academicOfferingsApiSlice';

import { CourseTeacher } from '../../form/interfaces/coursesListAll.interface';
import { Period } from '../../../../../maintenance/academic-offerings/interfaces/periods.interface';
import TextFieldFetching from '../../../../../shared/components/TextFieldFetching';
import { useGetAllIntegralEvaluationsQuery } from '../../integral_evaluation/slices/integralEvaluationApiSlice';
import AddButton from '../../../../../shared/components/tables/buttons/AddButton';
import { useLazyGetCollaboratorsQuery } from '../../../../../hr/collaborators/slices/collaboratorsApiSlice';
import CustomAsyncPaginatedSelect from '../../../../../shared/components/extended/Form/CustomAsyncPaginatedSelect';
import DeleteButton from '../../../../../shared/components/tables/buttons/DeleteButton';
import EditButton from '../../../../../shared/components/tables/buttons/EditButton';
import { useGetRepresentativeScaleAllQuery } from '../../options_template/slices/optionsTemplateApiSlice';

const ResultadosTable = () => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const navigate = useHistory();

  const [dataFetch, setDataFetch] = useState<any[]>([]);

  const [evaTypes, setEvaTypes] = useState<
    {
      id: string;
      label: string;
    }[]
  >([]);

  const [collaboratorValue, setCollaboratorValue] = useState<any | null>(null);

  const [evaCalificationOption, setEvaCalificationOption] = useState(false);

  const [periodId, setPeriodId] = useState<number | string>();

  const [evaType, setEvaType] = useState<string | null>(null);

  const [offerDescription, setOff_description] = useState('');

  const [evaluated, setEvaluated] = useState<CourseTeacher[]>([]);

  const [eva_scale_representative_id, setEva_scale_representative_id] = useState<
    string | number | null
  >(null);

  const [education_level_id, setEducation_level_id] = useState<number | null>(null);

  const [course_id, setCourse_id] = useState<number | null>(null);

  const [evaluated_id, setEvaluated_id] = useState<number | null>(null);

  const { currentProfile } = useAppSelector((state) => state.access);

  const { data: dataEvaluationType } = useGetAllEvaluationTypesQuery({});

  const { data: rubricsAll, isLoading: isLoadingRuibrics } = useGetAllIntegralEvaluationsQuery({});

  const [getCoursesListAll, { data: coursesListAll, isLoading: isLoadingCoursesListAll }] =
    useLazyGetCoursesListAllQuery();

  const { data: representativeScaleAll, isLoading: isLoadingRepresentativeScaleAll } =
    useGetRepresentativeScaleAllQuery({});

  //filters

  //niveles eduactivos
  const {
    data: offersAvailables = [],
    isLoading: isLoadingPID,
    isFetching: isFetchingPID,
  } = useGetAvailablesOffersQuery(
    {
      profileId: currentProfile?.profileId!,
      off_description: '',
    },
    { refetchOnMountOrArgChange: 30 }
  );

  //periodos
  const {
    data: periodsByOffer = [],
    isLoading: isLoadingCID,
    isFetching: isFetchingCID,
  } = useGetPeriodAcademicOfferingQuery(
    {
      profileId: currentProfile?.profileId!,
      academicOfferingId:
        offersAvailables!
          .find((offer) => offer.off_description === offerDescription)
          ?.id?.toString() || '',
      current: true,
    },
    { skip: !offerDescription, refetchOnMountOrArgChange: 30 }
  );

  const periods = useMemo(() => {
    return Array.isArray(periodsByOffer)
      ? (periodsByOffer as unknown as Period[]).filter(
          (dt) => new Date(dt.end_process).getTime() > new Date().getTime()
        )
      : [];
  }, [periodsByOffer]);

  const {
    data: offerById,
    isLoading: isLoadingOID,
    isFetching: isFetchingOID,
  } = useGetAcademicOfferingQuery(
    {
      profileId: currentProfile?.profileId!,
      academicOfferingId:
        offersAvailables!.find((offer) => offer.off_description === offerDescription)?.id! || 0,
    },
    { skip: !offerDescription, refetchOnMountOrArgChange: 30 }
  );

  //filters

  const {
    formTable: { page, perPage: size, search },
    start_date,
    end_date,
  } = useAppSelector((state) => state.formsEva);

  const { setPage, setPerPage, setSearch } = useTableSearch({
    page,
    perPage: size,
    search,
    updateFunction: setFormTable,
  });

  const {
    data: results,
    isFetching,
    isLoading,
    error,
  } = useGetResultsEvaQuery({
    page,
    perPage: size,
    search,
    start_date: start_date.split('T')[0],
    end_date: end_date.split('T')[0],
    ...(education_level_id && { education_level_id: education_level_id }),
    ...(periodId && { period_id: periodId }),
    ...(evaType && { rubrica_id: evaType }),
    ...(evaluated_id && { evaluated_id: evaluated_id }),
    ...(collaboratorValue && { evaluated_id: collaboratorValue.user_id }),
    ...(evaCalificationOption && { date_accept_result: evaCalificationOption }),
    ...(eva_scale_representative_id && {
      eva_scale_representative_id: eva_scale_representative_id,
    }),
  });

  const merged = () => {
    const newHeader: {
      [key: string]: string;
    } = {
      name: t('Nombre'),
      facultad: t('Unidad Académica'),
      periodo: t('Periodo'),
      anio: t('Año'),
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    newHeader['course'] = t('Curso');
    newHeader['escalaRepresentatriva'] = t('Escala Representativa');
    newHeader['promedio'] = t('Promedio');
    newHeader['acceptResult'] = t('¿Aceptó Resultado?');
    newHeader['options'] = t('Opciones');

    return newHeader;
  };

  const goToAdd = () => {
    navigate.push('/formularios/agregar-formulario');
  };

  const [
    getCollaboratorTrigger,
    // { data: institutesAvailables, isLoading: isLoadingInstitutesAvailables },
  ] = useLazyGetCollaboratorsQuery();

  //TODO: crear hook en el componente del select
  const getCollaborators = async (search: string, page: number, perPage: number) => {
    const response = await getCollaboratorTrigger({
      page,
      perPage,
      search,
      statusId: 1,
    }).unwrap();

    const hasMore = response.current_page < response.last_page;

    return {
      options: response.data.map((dt) => {
        return {
          value: dt.id,
          label: dt.user.person.pers_full_name || dt.user.person.pers_full_lastname,
          ...dt,
        };
      }),
      hasMore,
    };
  };

  useEffect(() => {
    if (results && evaTypes.length > 0) {
      const newArrayResults = Object.entries(results.collaboratores).map(([name, dataInfo]) => {
        // const newObject: {
        //   [key: string]: string;
        // } = {};

        // console.log(dataInfo);

        // evaTypes.forEach((item) => {
        //   const result = dataInfo.find((info: any) => info.eva_type_id === item.id);
        //   console.log(result);

        //   newObject[item.label as unknown as string] = result?.form_value
        //     ? Number(result?.form_value).toFixed(2)
        //     : '0.00';
        // });
        // resultado: dataInfo.reduce(
        //   (acc: number, item: any) => acc + parseFloat(item.form_value),
        //   0
        // ),

        return {
          name: name,
          facultad: dataInfo[0].education_level_name,
          periodo: dataInfo[0].periodo,
          // ...newObject,
          anio: dataInfo[0].period_anio,
          course: dataInfo[0].course,
          escalaRepresentatriva: dataInfo[0].eva_scale_representative_name || '-',
          promedio: dataInfo[0].prom_value,
          acceptResult: dataInfo[0].date_accept_result,
          options: dataInfo,
        };
      });
      setDataFetch(newArrayResults);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results, evaTypes]);

  useEffect(() => {
    if (dataEvaluationType) {
      const newArray = dataEvaluationType
        .map((item) => {
          return {
            id: item.uuid!,
            label: item.name,
          };
        })
        .filter((item) => item.label);
      setEvaTypes(newArray);
    }
  }, [dataEvaluationType]);

  useEffect(() => {
    if (periodId && education_level_id) {
      getCoursesListAll({
        periodId: periodId,
        educationLevelId: education_level_id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periodId, education_level_id]);

  return (
    <>
      <Grid container item xs={12} spacing={2}>
        <Grid
          item
          xs={12}
          sm={4}
          md={4}
          lg={4}
          sx={{
            mb: 2,
          }}
        >
          <Grid container spacing={1} alignItems="flex-start">
            <Grid item xs zeroMinWidth>
              <LocalizedDatePicker
                value={start_date}
                onChange={(newValue) => {
                  dispatch(setStart_date((newValue as Date).toISOString()));
                }}
                label={t('financial.deposit_transfers.filters.dates.start_label')}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4}>
          <Grid container spacing={1} alignItems="flex-start">
            <Grid item xs zeroMinWidth>
              <LocalizedDatePicker
                value={end_date}
                onChange={(newValue) => {
                  dispatch(setEnd_date((newValue as Date).toISOString()));
                }}
                label={t('financial.deposit_transfers.filters.dates.end_label')}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={4} sm={4} md={4} lg={4}>
          <Grid container item xs={12} spacing={1}>
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <AddButton size="large" onClick={goToAdd} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={12}
        spacing={2}
        sx={{
          mb: 2,
        }}
      >
        <Grid item xs={12} sm={4} md={4} lg={4}>
          <InputLabel>{t('Escenarios')}</InputLabel>
          {isLoadingRuibrics ? (
            <TextField fullWidth placeholder={t('Cargando escenarios...')} disabled />
          ) : (
            <Autocomplete
              options={Array.isArray(rubricsAll) ? rubricsAll : []}
              getOptionLabel={(option) => option.name}
              value={rubricsAll?.find((evaT) => evaT.id === Number(evaType)) || null}
              onChange={(event, newValue) => {
                if (newValue) {
                  setEvaType(newValue?.id.toString() || null);
                } else {
                  setEvaType(null);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="medium"
                  fullWidth
                  placeholder={t('Seleccione un escenario')}
                />
              )}
            />
          )}
        </Grid>
        {!offerDescription && (
          <>
            <Grid item xs={6}>
              <InputLabel>Colaborador</InputLabel>
              <CustomAsyncPaginatedSelect
                key={'id'}
                value={collaboratorValue}
                isError={false}
                placeholder={'Escoger colaborador'}
                setValue={setCollaboratorValue}
                fetchFunc={getCollaborators}
              />
            </Grid>
          </>
        )}

        <Grid item xs={12} sm={4} md={4} lg={4}>
          <InputLabel>{t('academic.courses.edu_level')}:</InputLabel>
          {!isFetchingPID && (
            <Autocomplete
              // key={autocompleteKey.offer}
              options={offersAvailables}
              noOptionsText={t('academic.courses.edu_level.no_data')}
              loading={isLoadingPID}
              loadingText={t('academic.courses.edu_level.loading')}
              getOptionLabel={(option) => option.off_name}
              value={offersAvailables.find((dt) => dt.off_description === offerDescription) || null}
              renderInput={(params) => (
                <TextField {...params} placeholder={t('academic.courses.edu_level.placeholder')} />
              )}
              onChange={(_, value) => {
                if (value) {
                  setOff_description(value.off_description);
                } else {
                  setOff_description('');
                  setPeriodId(0);
                  setEducation_level_id(0);
                  setEvaluated([]);
                  setCourse_id(null);
                  setEvaluated_id(null);
                  setCollaboratorValue(null);
                }
              }}
              fullWidth
            />
          )}
          {isFetchingPID && <TextFieldFetching />}
        </Grid>

        {offerDescription && (
          <>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <InputLabel>{t('academic.courses.period')}:</InputLabel>
              {!isFetchingCID && (
                <Autocomplete
                  options={periods}
                  noOptionsText={t('academic.courses.period.no_data')}
                  loading={isLoadingCID}
                  loadingText={t('academic.courses.period.loading')}
                  disabled={!offerDescription}
                  getOptionLabel={(option) => option.per_name}
                  value={periods.find((dt) => dt.id === Number(periodId)) || null}
                  renderInput={(params) => (
                    <TextField {...params} placeholder={t('academic.courses.period.placeholder')} />
                  )}
                  onChange={(_, value) => {
                    if (value) {
                      setPeriodId(value?.id);
                    } else {
                      setPeriodId(0);
                    }
                  }}
                  fullWidth
                />
              )}
              {isFetchingCID && <TextFieldFetching />}
            </Grid>

            <Grid item xs={12} sm={4} md={4} lg={4}>
              <Grid item xs={8}>
                <InputLabel>{t('academic.courses.academic_unit')}:</InputLabel>
              </Grid>

              {!isFetchingOID && (
                <Autocomplete
                  // key={autocompleteKey.educationLvl}
                  options={offerById ? offerById!.education_levels : []}
                  noOptionsText={t('academic.courses.academic_unit.no_data')}
                  loading={isLoadingOID}
                  disabled={!periodId}
                  loadingText={t('academic.courses.academic_unit.loading')}
                  getOptionLabel={(option) => option.edu_name}
                  value={
                    offerById?.education_levels.find(
                      (dt) => dt.id === Number(education_level_id)
                    ) || null
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={t('academic.courses.academic_unit.placeholder')}
                    />
                  )}
                  onChange={(_, value) => {
                    if (value) {
                      setEducation_level_id(value?.id);
                    } else {
                      setEducation_level_id(0);
                    }
                  }}
                  fullWidth
                />
              )}
              {isFetchingOID && <TextFieldFetching />}
            </Grid>

            <Grid item xs={12} sm={4} md={4} lg={4}>
              <InputLabel>{t('Cursos')}</InputLabel>
              {isLoadingCoursesListAll || !periodId || !education_level_id ? (
                <TextField
                  fullWidth
                  placeholder={t('Seleccione un periodo y la unidad académica ')}
                  disabled
                />
              ) : (
                <Autocomplete
                  options={Array.isArray(coursesListAll) ? coursesListAll : []}
                  value={
                    Array.isArray(coursesListAll)
                      ? coursesListAll?.find((dt) => dt.id === Number(course_id))
                      : null
                  }
                  renderInput={(params) => (
                    <TextField {...params} placeholder={t('Selecciona un curso')} />
                  )}
                  getOptionLabel={(option) => option?.mat_name}
                  onChange={(_, value) => {
                    if (value) {
                      setEvaluated(value?.course_teacher!);
                      setCourse_id(value?.id!);
                    }
                  }}
                />
              )}
            </Grid>

            <Grid item xs={12} sm={4} md={4} lg={4}>
              <InputLabel>{t('Evaluado')}</InputLabel>
              {evaluated.length === 0 ? (
                <TextField
                  fullWidth
                  placeholder={t('Seleccione un curso para ver los evaluados')}
                  disabled
                />
              ) : (
                <Autocomplete
                  options={evaluated}
                  value={
                    evaluated.find(
                      (dt) => Number(dt.collaborator.user_id) === Number(evaluated_id)
                    ) || null
                  }
                  renderInput={(params) => (
                    <TextField {...params} placeholder={t('Seleccione una evaluado')} />
                  )}
                  getOptionLabel={(option) => option?.collaborator.user.person.pers_full_name!}
                  onChange={(_, value) => {
                    if (value) {
                      setEvaluated_id(Number(value?.collaborator.user_id!));
                    } else {
                      setEvaluated_id(null);
                    }
                  }}
                />
              )}
            </Grid>
          </>
        )}

        <Grid item xs={12} sm={4} md={4} lg={4}>
          <InputLabel>{t('Escala Representativa')}</InputLabel>
          {isLoadingRepresentativeScaleAll ? (
            <TextField fullWidth placeholder={t('Cargando escala representativa...')} disabled />
          ) : (
            <Autocomplete
              options={representativeScaleAll! || []}
              value={
                representativeScaleAll?.find(
                  (dt) => dt.id === Number(eva_scale_representative_id)
                ) || null
              }
              renderInput={(params) => (
                <TextField {...params} placeholder={t('Seleccione una escala representativa')} />
              )}
              getOptionLabel={(option) => option?.name}
              onChange={(_, value) => {
                if (value) {
                  setEva_scale_representative_id(value.id);
                } else {
                  setEva_scale_representative_id(null);
                }
              }}
            />
          )}
        </Grid>

        <Grid item xs={6}>
          <InputLabel>{t('¿Resultados Aceptados?')}</InputLabel>
          <FormControlLabel
            control={<Switch onChange={(e) => setEvaCalificationOption(e.target.checked)} />}
            value={evaCalificationOption}
            label={evaCalificationOption ? t('Si') : t('No')}
          />
        </Grid>
      </Grid>

      <SearchPaginatedTableAndCollapsibleItems
        data={dataFetch}
        headers={{
          ...merged(),
        }}
        keyExtractor={(item) => `${item.name} - ${item.facultad} - ${item.periodo}`}
        searchPlacehoder={t('Buscar grupo...')}
        isLoading={isLoading}
        isFetching={isFetching}
        error={error}
        perPage={size}
        setPerPage={setPerPage}
        total={0}
        page={page}
        setPage={setPage}
        numHeader={9}
        search={search}
        setSearch={setSearch}
        perPageBox={false}
        paginationBox={false}
        customRenderers={{
          acceptResult: (item) => {
            const isAcceptResult = item.acceptResult;
            return (
              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {isAcceptResult ? (
                  <Grid
                    item
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <CheckCircle sx={{ fontSize: '1.5rem', color: 'green' }} />
                    <Typography variant="caption" sx={{ fontSize: '0.8rem', ml: 1 }}>
                      {isAcceptResult}
                    </Typography>
                  </Grid>
                ) : (
                  <Tooltip title={t('No aceptado')}>
                    <Cancel sx={{ fontSize: '1.5rem', color: 'red', cursor: 'pointer' }} />
                  </Tooltip>
                )}
              </Grid>
            );
          },
          options: (item) => (
            <>
              <Tooltip title={t('Ver Detalle')}>
                <IconButton
                  aria-label="preview"
                  color="default"
                  disabled={Boolean(item.acceptResult)}
                  onClick={() => {
                    navigate.push(`/formularios/previsualizar/${item.options[0].eva_formulary_id}`);
                  }}
                >
                  <Preview sx={{ fontSize: '1.3rem' }} />
                </IconButton>
              </Tooltip>
              <Tooltip title={t('Ver Documentos')}>
                <IconButton
                  aria-label="preview"
                  color="primary"
                  disabled={Boolean(item.acceptResult)}
                  onClick={() => {
                    dispatch(setIsOpenDocumentDialog(true));
                    dispatch(setFormIdSelected(item.options[0].eva_formulary_id));
                  }}
                >
                  <Description sx={{ fontSize: '1.3rem' }} />
                </IconButton>
              </Tooltip>
              <EditButton
                handleEdit={() => {
                  navigate.push(
                    `/formularios/editar-formulario/${item.options[0].eva_formulary_id}`
                  );
                }}
                disabled={Boolean(item.acceptResult)}
              />
              <DeleteButton
                handleDelete={() => {
                  dispatch(setFormIdSelected(item.options[0].eva_formulary_id));
                  dispatch(setIsOpenDialogOpen(true));
                }}
                disabled={Boolean(item.acceptResult)}
              />
            </>
          ),
        }}
      />
    </>
  );
};

export default ResultadosTable;
