import { Grid, IconButton, Typography, useTheme } from '@material-ui/core';
import { IconChevronLeft } from '@tabler/icons';
import { useParams, useHistory } from 'react-router-dom';
import { useAppSelector } from '../../../../hooks/useRedux';
import MainCard from '../../../shared/components/cards/MainCard';
import PeriodStagesDelete from '../components/delete/PeriodStagesDelete';
import PeriodStagesTable from '../components/list/PeriodStagesTable';
import { useTranslation } from 'react-i18next';

const PeriodStageScreen = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const history = useHistory();

  const { periodId } = useParams<{ periodId: string }>();

  const { periodList, periodStageIdToDelete } = useAppSelector((state) => state.periods);

  const period = periodList.find((value) => value.id === Number(periodId));

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid item xs={12} sx={{ mb: 3 }}>
        <Grid item container alignItems="center" gap={1} sx={{ cursor: 'pointer' }}>
          <IconButton onClick={() => history.push('/administrar-periodos')}>
            <IconChevronLeft size={30} color={theme.palette.text.dark} />
          </IconButton>
          {period && (
            <Typography variant="h2">
              {period?.per_name} - {t('academic.periods_list.stages.title')}
            </Typography>
          )}
        </Grid>
      </Grid>

      <PeriodStagesTable />

      {periodStageIdToDelete && <PeriodStagesDelete />}
    </Grid>
  );
};

export default PeriodStageScreen;
