import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import MainCard from '../../../shared/components/cards/MainCard';
import FollowUpOfResearchProjectMain from '../components';

const FollowUpOfResearchProjectScreen = () => {
  const { t } = useTranslation();

  return (
    <Grid component={MainCard} sx={{ padding: 2, minHeight: '500px' }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">{t('Seguimiento de proyectos')}</Typography>
      </Grid>
      <FollowUpOfResearchProjectMain />
    </Grid>
  );
};

export default FollowUpOfResearchProjectScreen;
