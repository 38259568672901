//import { /*useEffect,*/ useState } from 'react';

import { useAppSelector } from '../../../../../../hooks/useRedux';
import { useParams } from 'react-router-dom';
import { PostDataType, Reply, Profile } from '../../../../../shared/interfaces/post.interfaces';
import Posts from '../../../../../shared/components/cards/Post';
import {
  CircularProgress,
  Grid,
  IconButton,
  /*makeStyles,*/ Skeleton,
  Tooltip,
} from '@material-ui/core';
import { IconRefresh, IconX } from '@tabler/icons';
import { useState, useEffect, useMemo } from 'react';
import ErrorAlert from '../../../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../../../shared/components/alerts/SuccessAlert';

// style constant
import {
  useAddCommentMutation,
  useGetCommentsQuery,
  useUpdateCommentMutation,
} from '../../../../../teachers/virtual-classroom/slices/virtualClassroomTeacherApiSlice';
/*const useStyles = makeStyles((theme) => ({
  avatarRight: {
    ...theme.typography.commonAvatar,
    ...theme.typography.smallAvatar,
    background:
      theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
    color: theme.palette.mode === 'dark' ? theme.palette.dark.light : theme.palette.secondary.dark,
    zIndex: 1,
    transition: 'all .2s ease-in-out',
    '&[aria-controls="menu-list-grow"],&:hover': {
      background: theme.palette.secondary.main,
      color: theme.palette.secondary.light,
    },
  },
  message: {
    '& > p': {
      ...theme.typography.body1,
      marginBottom: 0,
    },
  },
  btnSecondary: {
    background: theme.palette.secondary.main,
    '&:hover,&:focus,&:active': {
      background: theme.palette.secondary.dark,
    },
  },
  containerCard: {
    border: theme.palette.mode === 'dark' ? 'none' : '2px solid',
    borderColor: theme.palette.grey[100],
  },
}));*/

interface Props {
  goToForumView: () => void;
  forumId: number;
}
const ForumContent = (props: Props) => {
  const { goToForumView, forumId } = props;

  const [showAlerts, setShowAlerts] = useState(true);

  const { currentProfile, currentStudent } = useAppSelector((state) => state.access);
  const { courseId } = useParams<{ courseId: string }>();
  //const classes = useStyles();
  const { userInfo } = useAppSelector((state) => state.auth);

  const currentAuthor: Profile = {
    id: String(userInfo?.id!),
    avatar: 'user-1.png',
    name: userInfo?.fullname!,
    time: 'publicado el 09/09/21 hasta el 09/12/21',
  };

  //const [comments, setComments] = useState<Comment[]>([]);

  const {
    data,
    refetch,
    isFetching: isFetchingFR,
    isLoading: isLoadingFR,
  } = useGetCommentsQuery({
    profileId: currentProfile?.profileId!,
    courseId: Number(courseId)!,
    forumId: forumId!,
  });

  const [createComment, { isSuccess: isSuccessComment, isError: isErrorComment, error }] =
    useAddCommentMutation();

  const [
    editComment,
    { isSuccess: isSuccessEditComment, isError: isErrorEditComment, error: errorEdit },
  ] = useUpdateCommentMutation();

  const dataPost: PostDataType = useMemo(
    () => ({
      id: data?.id.toString() || 'N/A',
      data: {
        id: data?.id.toString() || 'N/A',
        content: data?.act_description || 'N/A',
        details: data?.detail_forum.dta_question || 'N/A',
        images:
          [
            {
              img: '',
            },
          ] || [],
        likes: { like: false, value: 0 },
        comments: data?.answers_activity!.map((item) => ({
          id: item.id.toString(),
          profile: {
            id: item.user_id.toString(),
            avatar: 'N/A',
            name: `${item?.user.person.pers_firstname} ${item?.user.person.pers_first_lastname}`,
            time: item.created_at.toLocaleString(),
          },
          data: {
            images: [
              {
                img: item.csact_name_file
                  ? `http://api.web.educalinks.com.ec/api/show?name=Web/IMAGEN_FORO/${item.csact_name_file}`
                  : '',
              },
            ],
            name: `${item?.user.person.pers_firstname} ${item?.user.person.pers_first_lastname}`,
            comment: item.csact_comments,
            replies: item?.children!.map((itemChild) => ({
              id: itemChild.id.toString(),
              profile: {
                id: itemChild.user_id.toString(),
                avatar: 'N/A',
                name: `${itemChild?.user.person.pers_firstname} ${itemChild?.user.person.pers_first_lastname}`,
                time: itemChild.created_at.toLocaleString(),
              },
              data: {
                name: `${itemChild?.user.person.pers_firstname} ${itemChild?.user.person.pers_first_lastname}`,
                comment: itemChild.csact_comments,
                images: [
                  {
                    img: itemChild?.csact_name_file
                      ? `http://api.web.educalinks.com.ec/api/show?name=Web/IMAGEN_FORO/${itemChild?.csact_name_file}`
                      : '',
                  },
                ],
              },
            })),
          },
        })),
      },
      profile: {
        id: data?.author.id.toString() || 'N/A',
        avatar: 'user-1.png',
        name:
          `${data?.author.person.pers_firstname} ${data?.author.person.pers_first_lastname}` ||
          'N/A',
        time: data?.course_activity_one.cact_start_date.toLocaleString() || 'N/A',
      },
    }),
    [data]
  );

  const [posts, setPosts] = useState<PostDataType>(dataPost);

  const getPost = async () => {
    console.log('getPost');
  };

  const editPost = async (id: string, commentId: string) => {
    console.log(id, commentId);
  };

  const commentAdd = async (id: string, comment: Reply) => {
    setPosts(dataPost);
    await createComment({
      forumId,
      CommentPayload: {
        csact_comments: comment.data.comment,
        files: comment.data.imagesFile,
        user_id: Number(userInfo?.id!),
        student_id: Number(currentStudent?.id!),
        type_document: 12,
        parent_id: null,
      },
    });
    setShowAlerts(true);
  };

  const commentEdit = async (postId: string, commentId: string, reply: Reply) => {
    await editComment({
      commentId: Number(commentId!),
      CommentPayload: {
        csact_comments: reply.data.comment,
        files: reply.data.imagesFile,
        type_document: 12,
      },
    });
    setShowAlerts(true);
  };

  const replyAdd = async (postId: string, commentId: string, reply: Reply) => {
    await createComment({
      forumId,
      CommentPayload: {
        csact_comments: reply.data.comment,
        files: reply.data.imagesFile,
        user_id: Number(userInfo?.id!),
        student_id: Number(currentStudent?.id!),
        type_document: 12,
        parent_id: commentId,
      },
    });
    setShowAlerts(true);
  };

  const handlePostLikes = async (postId: string) => {
    console.log(postId);
  };

  const handleCommentLikes = async (postId: string, commentId: string) => {
    console.log(postId, commentId);
  };

  const handleReplayLikes = async (postId: string, commentId: string, replayId: string) => {
    console.log(postId, commentId, replayId);
  };

  const handleRefetch = () => {
    refetch();
  };

  useEffect(() => {
    setPosts(dataPost);
  }, [dataPost]);

  return !isLoadingFR && data ? (
    <>
      <Grid container justifyContent="flex-end" alignItems="center">
        <Tooltip title="Refrescar" onClick={handleRefetch}>
          <IconButton>
            {isFetchingFR && !isLoadingFR ? (
              <CircularProgress size={18} />
            ) : (
              <IconRefresh size={18} />
            )}
          </IconButton>
        </Tooltip>
        <Tooltip title="Cerrar foro">
          <IconButton onClick={goToForumView}>
            <IconX size={18} />
          </IconButton>
        </Tooltip>
      </Grid>
      {/*Adding comments*/}
      <Grid item>
        {isErrorComment && showAlerts && (
          <ErrorAlert
            message={error as string}
            handleDismiss={() => {
              setShowAlerts(false);
              window.scrollTo(0, 0);
            }}
          />
        )}

        {isSuccessComment && showAlerts && (
          <SuccessAlert
            message="El comentarios ha sido creado correctamente."
            handleDismiss={() => setShowAlerts(false)}
          />
        )}
      </Grid>
      {/*Edit comments*/}
      <Grid item>
        {isErrorEditComment && showAlerts && (
          <ErrorAlert
            message={errorEdit as string}
            handleDismiss={() => {
              setShowAlerts(false);
              window.scrollTo(0, 0);
            }}
          />
        )}

        {isSuccessEditComment && showAlerts && (
          <SuccessAlert
            message="El comentarios ha sido actualizado correctamente."
            handleDismiss={() => setShowAlerts(false)}
          />
        )}
      </Grid>

      {posts && (
        <Grid key={posts.id} item xs={12}>
          <Posts
            key={posts.id}
            post={posts}
            editPost={editPost}
            renderPost={getPost}
            setPosts={setPosts}
            // showMenu={false}
            commentAdd={commentAdd}
            commentEdit={commentEdit}
            replyAdd={replyAdd}
            handlePostLikes={handlePostLikes}
            handleCommentLikes={handleCommentLikes}
            handleReplayLikes={handleReplayLikes}
            author={currentAuthor}
          />
        </Grid>
      )}
    </>
  ) : (
    <Skeleton
      variant="rectangular"
      sx={{ width: '100%', borderRadius: 3, height: '40vh', marginTop: 2 }}
    />
  );
};
export default ForumContent;
