import { useState, useEffect, useMemo } from 'react';

import {
  Autocomplete,
  Button,
  CircularProgress,
  // createFilterOptions,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from '@material-ui/core';
import { DateRange, DateRangePicker, LocalizationProvider } from '@material-ui/lab';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import { useFormik } from 'formik';
// import { useFormik } from 'formik';\
import { useAppSelector, useAppDispatch } from '../../../../../hooks/useRedux';
import { useGetCollaboratorByEducationLevelQuery } from '../../../../hr/collaborators/slices/collaboratorsApiSlice';
import { useGetAllClassroomsByPeriodEdlevelQuery } from '../../../classrooms-educational-levels/slices/classroomEdLvlApiSlice';
import { useGetAvaliblesMattersQuery } from '../../../matters/slices/mattersApiSlice';
import { useGetStudyModalitiesQuery } from '../../../meshs/slices/meshsApiSlice';
import {
  useGetHourhandsByPeriodQuery,
  useGetPeriodQuery,
} from '../../../periods/slices/periodsApiSlice';
import { CourseScheme } from '../../constants/validationSchemas';
import esLocale from 'date-fns/locale/es';
import {
  useAddCourseMutation,
  useGetCareerByEduLevelQuery,
  useGetCurrentAvailableParallelQuery,
  useUpdateCourseMutation,
} from '../../slices/courseApiSlice';
import { setAlertMessage, setCourseSideModalOpen } from '../../slices/courseSlice';
import { parseISO, format } from 'date-fns';
import { useTranslation } from 'react-i18next';
// import { MattersByEducationLevel } from '../../../matters/interfaces/matters.interface';
import { virtualClassrooms } from '../../../../shared/constants/virtualClassroomDict';
import ErrorAlert from '../../../../shared/components/alerts/ErrorAlert';

// const filterOptionsSubjects = createFilterOptions({
//   matchFrom: 'any',
//   stringify: (option: MattersByEducationLevel) =>
//     `${option.mat_name} ${option.cod_matter_migration}`,
// });

const CourseForm = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const { serviceCredentials } = useAppSelector((state) => state.access);
  const currentProfile = useAppSelector((state) => state.access.currentProfile);
  const { coursesFilter, coursesList, courseIdToEdit } = useAppSelector((state) => state.courses);

  const { educationLevelId, offerDescription, periodId, campusId } = coursesFilter;

  const [typeSubject, setTypeSubject] = useState<number | undefined>();
  // const [subjectCurriculumId, setSubjectCurriculumId] = useState<string | undefined>();

  const courseToEdit = courseIdToEdit
    ? coursesList.find((course) => course.id === courseIdToEdit)
    : null;

  const {
    data: faculty,
    isLoading: isLoadingF,
    isFetching: isFetchingF,
  } = useGetCareerByEduLevelQuery({
    profileId: currentProfile?.profileId!,
    eduLevelId: educationLevelId!,
  });

  const {
    data: modalitiesAvailables,
    isLoading: isLoadingMOA,
    isFetching: isFetchingMOA,
  } = useGetStudyModalitiesQuery({
    profileId: currentProfile?.profileId!,
  });

  const {
    data: hourhands,
    isLoading: isLoadingH,
    isFetching: isFetchingH,
  } = useGetHourhandsByPeriodQuery(
    {
      profileId: currentProfile?.profileId!,
      periodId: periodId!,
    },
    {
      skip: !periodId,
    }
  );

  const {
    data: collaborators,
    isLoading: isLoadingC,
    isFetching: isFetchingC,
  } = useGetCollaboratorByEducationLevelQuery(
    {
      profileId: currentProfile?.profileId!,
      educationlevel: educationLevelId!,
      type_collaborator: 'D',
    },
    {
      skip: !educationLevelId,
    }
  );

  const [createCourse, { isError: isErrorC, error: errorC, isLoading: isLoadingCreate }] =
    useAddCourseMutation();
  const [updateCourse, { isError: isErrorU, error: errorU, isLoading: isLoadingUpdate }] =
    useUpdateCourseMutation();

  const initialValues = useMemo(
    () => ({
      careerId: null,
      meshId: null,
      subjectId: courseToEdit ? courseToEdit.subject_id : null,
      parallelId: courseToEdit ? courseToEdit.parallel_id : null,
      modalityId: courseToEdit ? courseToEdit.modality_id : null,
      hourhandId: courseToEdit ? courseToEdit.hourhand_id : null,
      classroomId: courseToEdit ? Number(courseToEdit.classroom_id) : null,
      collaboratorId: courseToEdit ? courseToEdit.collaborator_id : -1,
      collaborators_support: courseToEdit
        ? courseToEdit.course_teacher
            ?.filter((collaborator) => collaborator.type_teacher === 'O')
            ?.map((collaborator) => collaborator.collaborator)
        : null,
      // max capacity es la capacidad del aula (get course)
      max_capacity: courseToEdit?.cl_cap_max ? Number(courseToEdit.cl_cap_max) : 0,
      // max register es el max capacity del payload (post)
      max_registers: courseToEdit?.max_capacity ? Number(courseToEdit.max_capacity) : 0,
      expected_registers: courseToEdit?.expected_registers
        ? Number(courseToEdit.expected_registers)
        : 0,
      num_fouls: courseToEdit ? courseToEdit.num_fouls : null,
      status: courseToEdit ? courseToEdit.status : null,
      period_id: courseToEdit ? courseToEdit.period_id : null,
      startDate: courseToEdit ? parseISO(courseToEdit.start_date) : null,
      endDate: courseToEdit ? parseISO(courseToEdit.end_date) : null,
      typeVirtualClassroom: courseToEdit ? courseToEdit.type_classroom_virtual : null,
      ID_LEVEL_EDU: null,
    }),
    [courseToEdit]
  );

  const { errors, getFieldProps, handleSubmit, setFieldValue, setValues, touched, values } =
    useFormik({
      initialValues,
      validationSchema: CourseScheme,
      enableReinitialize: true,
      onSubmit: async (values) => {
        if (!courseIdToEdit) {
          createCourse({
            profileId: currentProfile?.profileId!,
            coursePayload: {
              max_capacity: values.max_registers!,
              matter_id: Number(values.subjectId!),
              parallel_id: Number(values.parallelId),
              modality_id: Number(values.modalityId!),
              hourhand_id: Number(values.hourhandId!),
              collaborator_id:
                values.collaboratorId && Number(values.collaboratorId) >= 0
                  ? Number(values.collaboratorId)
                  : undefined,
              collaborators_support: values.collaborators_support?.map((coll) => coll.id) || [],
              start_date: values.startDate ? format(values.startDate, 'yyyy-MM-dd') : null,
              end_date: values.endDate ? format(values.endDate, 'yyyy-MM-dd') : null,
              period_id: periodId!,
              status_id: values.status || 1,
              classroom_id: Number(values.classroomId),
              education_level_id: educationLevelId!,
              // subjectCurriculumId,
              type_classroom_virtual: values.typeVirtualClassroom!,
              expected_registers: values.expected_registers!,
            },
          })
            .unwrap()
            .then((response) => {
              dispatch(
                setAlertMessage({
                  message: t('academic.courses.side.success.add'),
                  variant: 'success',
                  open: true,
                })
              );
              dispatch(setCourseSideModalOpen(false));
            });
        } else {
          await updateCourse({
            profileId: currentProfile?.profileId!,
            courseId: courseIdToEdit,
            coursePayload: {
              max_capacity: values.max_registers!,
              matter_id: Number(values.subjectId!),
              parallel_id: Number(values.parallelId),
              modality_id: Number(values.modalityId!),
              hourhand_id: Number(values.hourhandId!),
              collaborator_id:
                Number(values.collaboratorId) >= 0 ? Number(values.collaboratorId) : undefined,
              collaborators_support:
                values.collaborators_support?.map((coll) => coll.id) || undefined,
              start_date: values.startDate ? format(values.startDate, 'yyyy-MM-dd') : null,
              end_date: values.endDate ? format(values.endDate, 'yyyy-MM-dd') : null,
              period_id: periodId!,
              status_id: values.status || 1,
              classroom_id: Number(values.classroomId),
              education_level_id: educationLevelId!,
              expected_registers: values.expected_registers!,
              // values.ID_LEVEL_EDU ||
              // subjectsEdAvailables?.find((s) => s.ID_Matter === values.subjectId)?.ID_LEVEL_EDU,
              // subjectCurriculumId: 0,
              // subjectCurriculumId ||
              // subjectsEdAvailables?.find((s) => s.subjectCurriculumId === subjectCurriculumId)
              //   ?.subjectCurriculumId,
              type_classroom_virtual: values.typeVirtualClassroom!,
            },
          })
            .unwrap()
            .then(async (response) => {
              dispatch(
                setAlertMessage({
                  message: t('academic.courses.side.success.edit'),
                  variant: 'success',
                  open: true,
                })
              );
              dispatch(setCourseSideModalOpen(false));
            });
        }
      },
    });

  const {
    data: subjectsEdAvailables,
    isLoading: isLoadingESBA,
    isFetching: isFetchingESBA,
  } = useGetAvaliblesMattersQuery(
    {
      profileId: currentProfile?.profileId!,
      educationLevelId: educationLevelId.toString(),
      // tm_acronym: offerDescription === 'Nivelación' ? 'nv' : undefined,
      ...(offerDescription !== 'Nivelación' && { off_description: offerDescription }),
      // careerId: values.careerId!,
      // meshId: values.meshId!,
    },
    {
      skip: !educationLevelId || !offerDescription,
      // || !values.careerId || !values.meshId,
    }
  );

  // inicializar demas estados de la materia
  // TODO: considerar refactorizar el value subject del form al objeto completo (para tomar directamente de ahi sus demas propiedades)
  useEffect(() => {
    if (subjectsEdAvailables) {
      const subject = subjectsEdAvailables?.find(
        (subject) => subject.id === Number(courseToEdit?.subject_id)
      );

      setTypeSubject(Number(subject?.type_matter_id));
      // setSubjectCurriculumId(subject?.subjectCurriculumId);
    }
  }, [courseToEdit?.subject_id, subjectsEdAvailables]);

  const {
    data: parallels,
    isLoading: isLoadingP,
    isFetching: isFetchingP,
  } = useGetCurrentAvailableParallelQuery(
    {
      profileId: currentProfile?.profileId!,
      // classroomId: values.classroomId!,
      periodId: periodId!,
      // eduLevelId: educationLevelId!,
      subjectId: values.subjectId!,
    },
    {
      skip: !periodId || !values.subjectId,
    }
  );

  const {
    data: classrooms,
    isLoading: isLoadingCO,
    isFetching: isFetchingCO,
  } = useGetAllClassroomsByPeriodEdlevelQuery(
    {
      profileId: currentProfile?.profileId!,
      periodId: periodId!,
      edLvlId: educationLevelId!,
      campusId: campusId!,
      filterCampus: true,
      typeSubjectId: typeSubject!,
      loans: true,
      // offer_id: offerId,
    },
    {
      skip: !periodId || !educationLevelId || !campusId || !typeSubject,
    }
  );

  const { data: period } = useGetPeriodQuery(
    {
      profileId: currentProfile?.profileId!,
      periodId: periodId!,
    },
    {
      skip: !periodId,
    }
  );

  const [value, setValue] = useState<DateRange<Date>>([null, null]);

  useEffect(() => {
    //set dates on useState
    setValue([
      courseToEdit
        ? parseISO(courseToEdit.start_date)
        : period?.start_process
        ? parseISO(period.start_process)
        : new Date(),
      courseToEdit
        ? parseISO(courseToEdit.end_date)
        : period?.end_process
        ? parseISO(period.end_process)
        : new Date(),
    ]);

    // set dates on formik
    setFieldValue(
      'startDate',
      courseToEdit
        ? parseISO(courseToEdit.start_date)
        : period?.start_process
        ? parseISO(period.start_process)
        : new Date()
    );
    setFieldValue(
      'endDate',
      courseToEdit
        ? parseISO(courseToEdit.end_date)
        : period?.end_process
        ? parseISO(period.end_process)
        : new Date()
    );
  }, [courseToEdit, period, setFieldValue]);

  const onChangeDates = (newDatesValues: DateRange<Date>) => {
    const [start, end] = newDatesValues;
    // update formik values
    setFieldValue('startDate', start);
    setFieldValue('endDate', end);
    setValue(newDatesValues);
  };

  const handleResetForm = () => {
    setValues({ ...initialValues, endDate: value[1], startDate: value[0] });
  };

  useEffect(() => {
    if (parallels && parallels?.length > 0) {
      setFieldValue('parallelId', parallels?.[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parallels]);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          {isErrorU && <ErrorAlert type="snackbar" message={errorU as string} />}
          {isErrorC && <ErrorAlert type="snackbar" message={errorC as string} />}
        </Grid>

        {false && (
          <>
            {' '}
            <Grid item xs={6}>
              <InputLabel>Carrera</InputLabel>

              <Autocomplete
                options={faculty?.children || []}
                getOptionLabel={(option) => option.edu_name}
                loading={isLoadingF || isFetchingF}
                value={faculty?.children?.find((c) => c.id === values.careerId) || null}
                onChange={(event, newValue) => {
                  setFieldValue('careerId', newValue?.id);
                  setFieldValue('subjectId', '');
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Seleccione una carrera"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {isLoadingC ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <InputLabel>Malla</InputLabel>
              <Autocomplete
                options={faculty?.children?.find((c) => c.id === values.careerId)?.meshs || []}
                getOptionLabel={(option) => option.mes_name}
                loading={isLoadingF || isFetchingF}
                value={
                  faculty?.children
                    ?.find((c) => c.id === values.careerId)
                    ?.meshs?.find((m) => m.id === values.meshId) || null
                }
                onChange={(event, newValue) => {
                  setFieldValue('meshId', newValue?.id);
                  setFieldValue('subjectId', '');
                }}
                disabled={!values.careerId}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder={
                      values.careerId ? 'Seleccione una malla' : 'Seleccione una carrera primero'
                    }
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {isLoadingC ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          {/* <Typography variant="h3" color="primary">
            {t('academic.courses.side.subject')}
          </Typography> */}
          <InputLabel>{t('academic.courses.side.subject')}</InputLabel>
          <Autocomplete
            options={
              subjectsEdAvailables
                // ?.filter((s) => Number(s.ID_LEVEL_EDU) === values.careerId)
                ?.slice()
                .sort((a, b) => Number(a.cod_matter_migration) - Number(b.cod_matter_migration)) ||
              []
            }
            noOptionsText={t('academic.courses.side.subject.no_data')}
            // filterOptions={filterOptionsSubjects}
            loading={isFetchingESBA || isLoadingESBA}
            loadingText={t('academic.courses.side.subject.loading')}
            getOptionLabel={(option) => `${option?.cod_matter_migration} - ${option.mat_name}`}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder={t('academic.courses.side.subject.placeholder')}
                error={Boolean(touched.subjectId && errors.subjectId)}
                {...getFieldProps('subjectId')}
                onChange={() => {}}
              />
            )}
            // disabled={!values.meshId}
            value={
              subjectsEdAvailables?.find((subject) => subject.id === Number(values.subjectId)) ||
              null
            }
            onChange={(_, newValue) => {
              setFieldValue('subjectId', newValue?.id || null);
              setFieldValue('ID_LEVEL_EDU', newValue?.education_level?.id || null);
              setTypeSubject(Number(newValue?.type_matter_id));
              // setSubjectCurriculumId(newValue?.subjectCurriculumId);
            }}
            // groupBy={(option) => option.edu_name}
          />
          {errors.subjectId && touched.subjectId && (
            <FormHelperText error>{t(errors.subjectId)}</FormHelperText>
          )}
        </Grid>
        <Grid item xs={12}>
          <InputLabel>Modalidad</InputLabel>
          <Autocomplete
            options={modalitiesAvailables || []}
            noOptionsText="No hay modalidades disponibles"
            loading={isLoadingMOA || isFetchingMOA}
            loadingText="Cargando modalidades..."
            getOptionLabel={(option) => option.cat_name}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder="Seleccione una modalidad"
                {...getFieldProps('modalityId')}
                error={Boolean(touched.modalityId && errors.modalityId)}
              />
            )}
            value={
              modalitiesAvailables?.find((modality) => modality.id === Number(values.modalityId)) ||
              null
            }
            onChange={(event, newValue) => {
              setFieldValue('modalityId', newValue?.id || null);
            }}
          />
          {errors.modalityId && touched.modalityId && (
            <FormHelperText error>{t(errors.modalityId)}</FormHelperText>
          )}
        </Grid>
        <Grid item xs={12}>
          <InputLabel>{t('academic.courses.side.schedule')}</InputLabel>
          <Autocomplete
            options={hourhands || []}
            noOptionsText={t('academic.courses.side.schedule.no_data')}
            loading={isFetchingH || isLoadingH}
            loadingText={t('academic.courses.side.schedule.loading')}
            getOptionLabel={(option) => option.hour_description}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder={t('academic.courses.side.schedule.placeholder')}
                {...getFieldProps('hourhandId')}
                error={Boolean(touched.hourhandId && errors.hourhandId)}
              />
            )}
            value={hourhands?.find((hourhand) => hourhand.id === Number(values.hourhandId)) || null}
            onChange={(event, newValue) => {
              setFieldValue('hourhandId', newValue?.id || null);
            }}
          />
          {errors.hourhandId && touched.hourhandId && (
            <FormHelperText error>{t(errors.hourhandId)}</FormHelperText>
          )}
        </Grid>
        <Grid item xs={12}>
          <InputLabel>{t('academic.courses.side.principal_teacher')}</InputLabel>
          <Autocomplete
            options={
              collaborators?.filter(
                (collaborator) =>
                  !values.collaborators_support?.find(
                    (collaboratorSupport) => collaboratorSupport.id === collaborator.id
                  )
              ) || []
            }
            noOptionsText={t('academic.courses.side.principal_teacher.no_data')}
            loading={isFetchingC || isLoadingC}
            loadingText={t('academic.courses.side.principal_teacher.loading')}
            getOptionLabel={(option) => option.user.person.pers_full_name || ''}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder={t('academic.courses.side.principal_teacher.placeholder')}
              />
            )}
            value={
              collaborators?.find(
                (collaborator) => collaborator.id === Number(values.collaboratorId)
              ) || null
            }
            onChange={(event, newValue) => {
              setFieldValue('collaboratorId', newValue?.id || null);
            }}
          />
        </Grid>
        <Grid item xs={12} mb={2}>
          <InputLabel>{t('academic.courses.side.secondary_teacher')}</InputLabel>
          <Autocomplete
            options={
              collaborators?.filter((coll) => coll.id !== Number(values.collaboratorId)) || []
            }
            noOptionsText={t('academic.courses.side.secondary_teacher.no_data')}
            loading={isFetchingC || isLoadingC}
            loadingText={t('academic.courses.side.secondary_teacher.loading')}
            getOptionLabel={(option) => option.user.person.pers_full_name || ''}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder={t('academic.courses.side.secondary_teacher.placeholder')}
              />
            )}
            value={
              collaborators?.filter((collaborator) =>
                values.collaborators_support?.some((coll) => coll.id === collaborator.id)
              ) || []
            }
            onChange={(event, newValue) => {
              setFieldValue('collaborators_support', newValue || null);
            }}
            multiple
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h3" color="primary">
            {t('academic.courses.side.classroom')}
          </Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <InputLabel>{t('academic.courses.side.classroom.input_label')}</InputLabel>
          <Autocomplete
            options={classrooms || []}
            noOptionsText={t('academic.courses.side.classroom.no_data')}
            loading={isFetchingCO || isLoadingCO}
            loadingText={t('academic.courses.side.classroom.loading')}
            getOptionLabel={(option) => option.class_room.cl_name}
            disabled={!values.subjectId}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder={
                  !values?.subjectId
                    ? t('academic.courses.side.classroom.no_subject')
                    : t('academic.courses.side.classroom.placeholder')
                }
                {...getFieldProps('classroomId')}
                error={Boolean(touched.classroomId && errors.classroomId)}
              />
            )}
            value={
              classrooms?.find(
                (classroom) => classroom.class_room.id === Number(values.classroomId)
              ) || null
            }
            onChange={(event, newValue) => {
              setFieldValue('classroomId', newValue?.classroom_id || null);
              setFieldValue('max_capacity', Number(newValue?.class_room?.cl_cap_max) || null);
              setFieldValue('max_registers', Number(newValue?.class_room?.cl_cap_max) || null);
              // setFieldValue('expected_registers', Number(newValue?.class_room?.cl_cap_max) || null);
            }}
          />
          {errors.classroomId && touched.classroomId && (
            <FormHelperText error>{t(errors.classroomId)}</FormHelperText>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <InputLabel>{t('academic.courses.side.classroom_capacity')}</InputLabel>
          <TextField
            type="number"
            variant="outlined"
            placeholder="0"
            fullWidth
            disabled
            value={values.max_capacity || null}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputLabel>{t('academic.courses.side.max_register')}</InputLabel>
          <TextField
            type="number"
            variant="outlined"
            placeholder="0"
            inputProps={{
              min: 0,
              max: values.max_capacity || 0,
            }}
            fullWidth
            error={Boolean(errors.max_registers && touched.max_registers)}
            {...getFieldProps('max_registers')}
          />
          {errors.max_registers && touched.max_registers && (
            <FormHelperText error>{t(errors.max_registers)}</FormHelperText>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <InputLabel>{t('academic.courses.side.expected_register')}</InputLabel>
          <TextField
            type="number"
            variant="outlined"
            placeholder="0"
            fullWidth
            inputProps={{
              min: 0,
              max: values.max_registers || 0,
            }}
            error={Boolean(errors.expected_registers && touched.expected_registers)}
            {...getFieldProps('expected_registers')}
          />
          {errors.expected_registers && touched.expected_registers && (
            <FormHelperText error>{t(errors.expected_registers)}</FormHelperText>
          )}
        </Grid>

        <Grid item xs={6}>
          <InputLabel>{t('academic.courses.side.parallel')}</InputLabel>
          <Autocomplete
            options={parallels || []}
            noOptionsText={t('academic.courses.side.parallel.no_data')}
            loading={isFetchingP || isLoadingP}
            loadingText={t('academic.courses.side.parallel.loading')}
            getOptionLabel={(option) => option.par_name}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder={t('academic.courses.side.parallel.placeholder')}
                error={Boolean(errors.parallelId && touched.parallelId)}
                {...getFieldProps('parallelId')}
              />
            )}
            value={parallels?.find((parallel) => parallel.id === Number(values.parallelId)) || null}
            onChange={(event, newValue) => {
              setFieldValue('parallelId', newValue?.id || null);
            }}
          />
          {errors.parallelId && touched.parallelId && (
            <FormHelperText error>{t(errors.parallelId)}</FormHelperText>
          )}
        </Grid>

        <Grid item xs={6}>
          <InputLabel>{t('academic.courses.side.virtual_classroom_type')}</InputLabel>
          <Autocomplete
            options={
              serviceCredentials.moodle
                ? virtualClassrooms
                : virtualClassrooms.filter((dt) => dt.keyword !== 'moodle')
            }
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder={t('academic.courses.side.virtual_classroom_type.placeholder')}
                error={Boolean(errors.typeVirtualClassroom && touched.typeVirtualClassroom)}
                {...getFieldProps('typeVirtualClassroom')}
              />
            )}
            value={
              virtualClassrooms.find((dt) => dt.keyword === values.typeVirtualClassroom) || null
            }
            onChange={(event, newValue) => {
              setFieldValue('typeVirtualClassroom', newValue?.keyword || null);
            }}
          />
          {errors.typeVirtualClassroom && touched.typeVirtualClassroom && (
            <FormHelperText error>{t(errors.typeVirtualClassroom)}</FormHelperText>
          )}
        </Grid>

        <Grid item xs={12} marginTop={2}>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
            <DateRangePicker
              startText={null}
              endText={null}
              value={value}
              minDate={new Date(period?.start_process!)}
              maxDate={new Date(period?.end_process!)}
              onChange={onChangeDates}
              renderInput={(startProps, endProps) => (
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={6}>
                    <InputLabel>{t('academic.courses.side.start_date')}:</InputLabel>
                    <TextField
                      fullWidth
                      {...startProps}
                      helperText=""
                      error={Boolean(errors.startDate && touched.startDate)}
                      {...getFieldProps('startDate')}
                    />
                    <FormHelperText>{t('academic.courses.side.start_date.helper')}</FormHelperText>

                    {errors.startDate && touched.startDate && (
                      <FormHelperText error>{t(errors.startDate)}</FormHelperText>
                    )}
                  </Grid>

                  <Grid item xs={6}>
                    <InputLabel>{t('academic.courses.side.end_date')}:</InputLabel>
                    <TextField
                      fullWidth
                      {...endProps}
                      helperText=""
                      error={Boolean(errors.endDate && touched.endDate)}
                      {...getFieldProps('endDate')}
                    />
                    <FormHelperText>{t('academic.courses.side.end_date.helper')}</FormHelperText>
                    {errors.endDate && touched.endDate && (
                      <FormHelperText error>{t(errors.endDate)}</FormHelperText>
                    )}
                  </Grid>
                </Grid>
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item container justifyContent="center" spacing={1} xs={12} mt={2}>
          <Grid item>
            <Button variant="outlined" onClick={handleResetForm}>
              {t('academic.courses.side.reset')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              disabled={isLoadingCreate || isLoadingUpdate}
              endIcon={(isLoadingCreate || isLoadingUpdate) && <CircularProgress size={20} />}
            >
              {t('academic.courses.side.save')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default CourseForm;
