import { useEffect } from 'react';

import Grid from '@material-ui/core/Grid';
import { Autocomplete, TextField, FormHelperText, Switch } from '@material-ui/core';

import InputLabel from '../../../../shared/components/forms/InputLabel';

import { useAppSelector } from '../../../../../hooks/useRedux';

import { useGetCatalogByAcronymQuery } from '../../../../shared/slices/catalogApi';
import { useFormikContext } from 'formik';
import { Catalog } from '../../interfaces/person.interface';

interface ComplementaryDataForm {
  // Datos complementarios
  proffesion: string;
  studyReason: string;
  hasVehicle: boolean;
  taxPayersHousehold: number;
  numChild: number;
  vivienda: Catalog;
  numBedrooms: number;
  religion: Catalog;
  etnic: Catalog;
}

const ComplementaryDataStep = () => {
  const { currentProfile } = useAppSelector((state) => state.access);
  const { personData } = useAppSelector((state) => state.collaborators);

  const profileId = currentProfile!.profileId;

  const { getFieldProps, setFieldValue, errors, touched, setTouched, values } =
    useFormikContext<ComplementaryDataForm>();
  console.log(values);

  const { data: typesVivienda = [], isLoading: isLoadingViviendas } = useGetCatalogByAcronymQuery({
    profileId,
    acronym: 'VV',
  });

  const { data: religionTypes = [], isLoading: isLoadingReligion } = useGetCatalogByAcronymQuery({
    profileId,
    acronym: 'TR',
  });

  const { data: etnics = [], isLoading: isLoadingEtnic } = useGetCatalogByAcronymQuery({
    profileId,
    acronym: 'ET',
  });

  useEffect(() => {
    setTouched({});
  }, [setTouched]);

  useEffect(() => {
    if (personData && !values.vivienda?.id) {
      setFieldValue('proffesion', personData.pers_profession);
      setFieldValue('studyReason', personData.pers_study_reason);
      setFieldValue('hasVehicle', Boolean(Number(personData.pers_has_vehicle)));
      setFieldValue(
        'taxPayersHousehold',
        personData.pers_num_taxpayers_household
          ? Number(personData.pers_num_taxpayers_household)
          : 0
      );
      setFieldValue('numChild', personData?.pers_num_child ? Number(personData.pers_num_child) : 0);
      setFieldValue('vivienda', personData.living_place || null);
      setFieldValue(
        'numBedrooms',
        personData.pers_num_bedrooms ? Number(personData.pers_num_bedrooms) : 0
      );
      setFieldValue('religion', personData.religion || null);
      setFieldValue('etnic', personData.ethnic || null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personData]);

  return (
    <Grid container spacing={4} alignItems="flex-start">
      <Grid item xs={12} md={6} container spacing={2}>
        {/* Profesion */}
        <Grid item xs={12}>
          <InputLabel>Profesión</InputLabel>
          <TextField
            fullWidth
            placeholder="Ej: Analista financiero"
            error={Boolean(errors.proffesion && touched.proffesion)}
            {...getFieldProps('proffesion')}
          />
          {errors.proffesion && touched.proffesion && (
            <FormHelperText error>{errors.proffesion}</FormHelperText>
          )}
        </Grid>

        {/* Razon de estudio */}
        <Grid item xs={12}>
          <InputLabel>Motivación de estudio</InputLabel>
          <TextField
            fullWidth
            placeholder="Describa la motivación de estudio"
            error={Boolean(errors.studyReason && touched.studyReason)}
            {...getFieldProps('studyReason')}
          />
          {errors.studyReason && touched.studyReason && (
            <FormHelperText error>{errors.studyReason}</FormHelperText>
          )}
        </Grid>

        {/* Tiene vehiculo */}
        <Grid item xs={12}>
          <InputLabel>¿Tiene vehículo?</InputLabel>
          <Switch checked={values.hasVehicle} {...getFieldProps('hasVehicle')} />
        </Grid>

        {/* Contribuyentes hogar */}
        <Grid item xs={12}>
          <InputLabel>Contribuyentes del hogar</InputLabel>
          <TextField
            type="number"
            fullWidth
            placeholder="Cantidad de personas que aportan económicamente"
            error={Boolean(errors.taxPayersHousehold && touched.taxPayersHousehold)}
            {...getFieldProps('taxPayersHousehold')}
          />
          {errors.taxPayersHousehold && touched.taxPayersHousehold && (
            <FormHelperText error>{errors.taxPayersHousehold}</FormHelperText>
          )}
        </Grid>

        {/* Num hijos */}
        <Grid item xs={12}>
          <InputLabel>Número de hijos</InputLabel>
          <TextField
            type="number"
            fullWidth
            placeholder="Número de hijos"
            error={Boolean(errors.numChild && touched.numChild)}
            {...getFieldProps('numChild')}
          />
          {errors.numChild && touched.numChild && (
            <FormHelperText error>{errors.numChild}</FormHelperText>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12} md={6} container spacing={2}>
        {/* Vivienda */}
        <Grid item xs={12}>
          <InputLabel>Tipo de vivienda</InputLabel>
          <Autocomplete
            options={typesVivienda}
            loading={isLoadingViviendas}
            loadingText="Cargando tipos de vivienda..."
            noOptionsText="No hay tipos de vivienda disponibles"
            getOptionLabel={(option) => option.cat_name}
            value={values.vivienda || null}
            onChange={(_, value) => setFieldValue('vivienda', value)}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Seleccione un tipo de vivienda"
                error={Boolean(errors.vivienda && touched.vivienda)}
              />
            )}
            fullWidth
            clearIcon={null}
          />
          {errors.vivienda && touched.vivienda && (
            <FormHelperText error>{errors.vivienda}</FormHelperText>
          )}
        </Grid>

        {/* Num cuartos */}
        <Grid item xs={12}>
          <InputLabel>Cant. cuartos en la vivienda</InputLabel>
          <TextField
            type="number"
            fullWidth
            placeholder="Cant. cuartos en la vivienda"
            error={Boolean(errors.numBedrooms && touched.numBedrooms)}
            {...getFieldProps('numBedrooms')}
          />
          {errors.numBedrooms && touched.numBedrooms && (
            <FormHelperText error>{errors.numBedrooms}</FormHelperText>
          )}
        </Grid>

        {/* Religion */}
        <Grid item xs={12}>
          <InputLabel>Religión</InputLabel>
          <Autocomplete
            options={religionTypes}
            loading={isLoadingReligion}
            loadingText="Cargando religiones..."
            noOptionsText="No hay religiones disponibles"
            getOptionLabel={(option) => option.cat_name}
            value={values.religion || null}
            onChange={(_, value) => setFieldValue('religion', value)}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Seleccione una religión"
                error={Boolean(errors.religion && touched.religion)}
              />
            )}
            fullWidth
            clearIcon={null}
          />
          {errors.religion && touched.religion && (
            <FormHelperText error>{errors.religion}</FormHelperText>
          )}
        </Grid>

        {/* Etnia */}
        <Grid item xs={12}>
          <InputLabel>Etnia</InputLabel>
          <Autocomplete
            options={etnics}
            loading={isLoadingEtnic}
            loadingText="Cargando etnias..."
            noOptionsText="No hay etnias disponibles"
            getOptionLabel={(option) => option.cat_name}
            value={values.etnic || null}
            onChange={(_, value) => setFieldValue('etnic', value)}
            renderInput={(params) => (
              <TextField
                {...params}
                error={Boolean(errors.etnic && touched.etnic)}
                placeholder="Seleccionar etnia correspondiente"
              />
            )}
            fullWidth
            clearIcon={null}
          />
          {errors.etnic && touched.etnic && <FormHelperText error>{errors.etnic}</FormHelperText>}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ComplementaryDataStep;
