import { FC, useEffect, useMemo } from 'react';

import { Autocomplete, Button, FormHelperText, Grid, TextField } from '@material-ui/core';
import { useFormik } from 'formik';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import DatePicker from '@material-ui/lab/DatePicker';

import { format, parseISO } from 'date-fns';

import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../../../../hooks/useRedux';
import InputLabel from '../../../../../shared/components/forms/InputLabel';

import { FormProps } from '../../../interfaces/components.interface';
import { setPayCollection } from '../../../slices/debtsPaymentSlice';
import { epsilonRound, updateItemFromIndexArr } from '../../../../../shared/helpers';
// import { useGetFinancialInstitutionsCatalogQuery } from '../../../../../shared/slices/catalogApi';
import TextFieldFetching from '../../../../../shared/components/TextFieldFetching';
import CurrencyInputMasked from '../../shared/CurrencyInputMasked';
import { TransferDepositMethodSchema } from '../../../constants/validationForms-schemas';
import { useGetFinancialInstitutionAccountQuery } from '../../../../../students/payments-history/slices/paymentsApiSlice';
import { useGetLocaleDateLang } from '../../../../../../translation/hooks/useGetLocaleDateLang';
import { paymentMethods } from '../../../../../shared/constants/globals';
import { useGetFinancialInstitutionsCatalogQuery } from '../../../../../shared/slices/catalogApi';

const TransferDepositMethod: FC<FormProps> = (props) => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();
  const { lang } = useGetLocaleDateLang();

  const defaultConfiguration = useAppSelector((state) => state.access.defaultConfiguration);

  const { paymethod, indexPayToEdit, setIsPay, onCancel } = props;

  const { currentProfile } = useAppSelector((state) => state.access);

  const { payCollection, totalDebtAmount, totalPayAmount } = useAppSelector(
    (state) => state.debtsPayment
  );

  const {
    data: fIA,
    // isLoading: isLoadingFIA,
    isFetching: isFetchingFIA,
  } = useGetFinancialInstitutionAccountQuery(
    {
      profileId: currentProfile?.profileId!,
    }
    /*{ skip: paymethod.keyword !== 'forma-pago-deposito' }*/
  );

  const { data: financialInstitutions, isFetching: isFetchingFI } =
    useGetFinancialInstitutionsCatalogQuery({
      profileId: currentProfile?.profileId!,
    });

  const initialValues = useMemo(() => {
    return {
      observation: indexPayToEdit?.toString() ? payCollection[indexPayToEdit]?.observation : '',
      payAmount: indexPayToEdit?.toString()
        ? payCollection[indexPayToEdit]?.payment_amount
        : epsilonRound(
            totalDebtAmount - totalPayAmount > 0 ? totalDebtAmount - totalPayAmount : 0
          ) || null,

      //si es deposito
      FinancialInstitucionOriginId:
        indexPayToEdit?.toString() && paymethod.keyword === paymentMethods.deposit
          ? payCollection[indexPayToEdit]?.financial_institution_origin_id
          : undefined,
      //si es transferencia
      originAccount:
        indexPayToEdit?.toString() && paymethod.keyword === paymentMethods.transfer
          ? payCollection[indexPayToEdit]?.cuenta_origen
          : undefined,
      isDeposit: paymethod.keyword === paymentMethods.deposit ? true : false,

      // global
      FinancialInstitucionTargetId: indexPayToEdit?.toString()
        ? payCollection[indexPayToEdit]?.financial_institution_target_id
        : undefined,
      destinedAccount: indexPayToEdit?.toString()
        ? payCollection[indexPayToEdit]?.cuenta_destino
        : undefined,
      reference: indexPayToEdit?.toString() ? payCollection[indexPayToEdit]?.reference : undefined,
      depositDate: indexPayToEdit?.toString()
        ? payCollection[indexPayToEdit]?.start_date_collection
          ? parseISO(payCollection[indexPayToEdit]?.start_date_collection!)
          : new Date()
        : new Date(),
      voucherNumber: indexPayToEdit?.toString()
        ? payCollection[indexPayToEdit]?.voucher_number
        : undefined,
    };
  }, [indexPayToEdit, payCollection, paymethod.keyword, totalDebtAmount, totalPayAmount]);

  //usar un solo formik e importar los initial state
  const { getFieldProps, errors, touched, handleSubmit, setValues, setFieldValue, values } =
    useFormik({
      initialValues: initialValues,
      validationSchema: TransferDepositMethodSchema,
      onSubmit: async (values) => {
        try {
          if (!indexPayToEdit?.toString()) {
            const newPayCollection = [
              {
                payment_amount: values.payAmount!,
                observation: values.observation,
                payment_way_id: paymethod.id,
                payment_way: paymethod.id,
                payment_way_name: paymethod.name,
                payment_way_keyword: paymethod.keyword,

                ...(paymethod.keyword === paymentMethods.deposit
                  ? { financial_institution_origin_id: values.FinancialInstitucionOriginId }
                  : paymethod.keyword === paymentMethods.transfer && {
                      cuenta_origen: values.originAccount,
                    }),

                financial_institution_target_id: values.FinancialInstitucionTargetId,
                cuenta_destino: values.destinedAccount,
                reference: values.reference,
                voucher_number: values.voucherNumber,
                start_date_collection: format(values.depositDate!, 'yyyy-MM-dd'),

                issues_invoice: 1,
              },
              ...payCollection,
            ];

            dispatch(setPayCollection(newPayCollection));
          } else {
            const updatedItem = {
              payment_amount: values.payAmount!,
              observation: values.observation,
              payment_way_id: paymethod.id,
              payment_way: paymethod.id,
              payment_way_name: paymethod.name,
              payment_way_keyword: paymethod.keyword,

              ...(paymethod.keyword === paymentMethods.deposit
                ? { financial_institution_origin_id: values.FinancialInstitucionOriginId }
                : paymethod.keyword === paymentMethods.transfer && {
                    cuenta_origen: values.originAccount,
                  }),

              financial_institution_target_id: values.FinancialInstitucionTargetId,
              cuenta_destino: values.destinedAccount,
              reference: values.reference,
              voucher_number: values.voucherNumber,
              start_date_collection: format(values.depositDate!, 'yyyy-MM-dd'),

              issues_invoice: 1,
            };

            const newPayCollection = updateItemFromIndexArr(
              payCollection,
              indexPayToEdit,
              updatedItem
            );

            dispatch(setPayCollection(newPayCollection));
          }

          setIsPay();
        } catch (error) {
          console.log(error);
        }

        //   setShowAlerts(true);
        //   window.scrollTo(0, 0);
      },
    });

  useEffect(() => {
    if (indexPayToEdit?.toString()) {
      setValues({ ...initialValues });
    }
  }, [indexPayToEdit, initialValues, setValues]);

  //reset initial values on change beetween deposit and transfer
  useEffect(() => {
    setValues({ ...initialValues });
  }, [initialValues, setValues]);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={1} alignItems="flex-start">
        {paymethod.keyword === paymentMethods.deposit ? (
          <Grid item xs={12}></Grid>
        ) : (
          paymethod.keyword === paymentMethods.transfer && (
            <>
              <Grid item xs={12}>
                <InputLabel>Banco origen</InputLabel>
                {isFetchingFI ? (
                  <TextFieldFetching />
                ) : (
                  <Autocomplete
                    options={financialInstitutions || []}
                    getOptionLabel={(option) => option.fin_int_name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={Boolean(
                          errors.FinancialInstitucionOriginId &&
                            touched.FinancialInstitucionOriginId
                        )}
                        placeholder="Seleccione los campus disponibles"
                      />
                    )}
                    value={
                      financialInstitutions?.find(
                        (fn) => fn.id === values.FinancialInstitucionOriginId
                      ) || null
                    }
                    onChange={(_, values) =>
                      setFieldValue('FinancialInstitucionOriginId', values?.id || null)
                    }
                    fullWidth
                  />
                )}
                {errors.FinancialInstitucionOriginId && touched.FinancialInstitucionOriginId && (
                  <FormHelperText error>{errors.FinancialInstitucionOriginId}</FormHelperText>
                )}
              </Grid>

              <Grid item xs={12}>
                <InputLabel>
                  {t(
                    'financial.debts_payment.pay_dialog.form.deposit_transfer_method.origin_account_label'
                  )}
                </InputLabel>
                <TextField
                  fullWidth
                  error={Boolean(errors.originAccount && touched.originAccount)}
                  placeholder={t(
                    'financial.debts_payment.pay_dialog.form.deposit_transfer_method.origin_account_placeholder'
                  )}
                  {...getFieldProps('originAccount')}
                />
                {errors.originAccount && touched.originAccount && (
                  <FormHelperText error>{t(errors.originAccount)}</FormHelperText>
                )}
              </Grid>
            </>
          )
        )}

        <Grid item xs={12}>
          <InputLabel>
            {t(
              'financial.debts_payment.pay_dialog.form.deposit_transfer_method.destined_account_label'
            )}
          </InputLabel>
          {isFetchingFIA ? (
            <TextFieldFetching />
          ) : (
            <Autocomplete
              options={fIA || []}
              getOptionLabel={(option) =>
                option.financial_institution.fin_int_name + ' - ' + option.fia_account_number
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(errors.destinedAccount && touched.destinedAccount)}
                  placeholder={t(
                    'financial.debts_payment.pay_dialog.form.deposit_transfer_method.destined_account_placeholder'
                  )}
                />
              )}
              value={fIA?.find((fn) => fn.fia_account_number === values.destinedAccount) || null}
              onChange={(_, value) => {
                setFieldValue('destinedAccount', value?.fia_account_number!);
                setFieldValue(
                  'FinancialInstitucionTargetId',
                  value?.financial_institution_id! || null
                );
              }}
              fullWidth
              clearIcon={null}
            />
          )}
          {/* <TextField
            fullWidth
            error={Boolean(errors.destinedAccount && touched.destinedAccount)}
            placeholder="Cuenta Origen"
            {...getFieldProps('destinedAccount')}
          /> */}
          {errors.destinedAccount && touched.destinedAccount && (
            <FormHelperText error>{t(errors.destinedAccount)}</FormHelperText>
          )}
        </Grid>

        <Grid item xs={12}>
          <InputLabel>{t('financial.debts_payment.pay_dialog.form.amount_label')}</InputLabel>
          <TextField
            // type="number"
            fullWidth
            InputProps={{
              defaultValue: '',
              inputComponent: CurrencyInputMasked as any,
              startAdornment: defaultConfiguration?.currency_symbol,
            }}
            placeholder={`${defaultConfiguration?.currency_symbol}0.00`}
            {...getFieldProps('payAmount')}
            error={Boolean(errors.payAmount && touched.payAmount)}
          />
          {errors.payAmount && touched.payAmount && (
            <FormHelperText error>{t(errors.payAmount)}</FormHelperText>
          )}
        </Grid>

        <Grid item xs={12}>
          <InputLabel>
            {t('financial.debts_payment.pay_dialog.form.deposit_transfer_method.voucher_label')}
          </InputLabel>
          <TextField
            fullWidth
            placeholder={t(
              'financial.debts_payment.pay_dialog.form.deposit_transfer_method.voucher_placeholder'
            )}
            {...getFieldProps('voucherNumber')}
            error={Boolean(errors.voucherNumber && touched.voucherNumber)}
          />
          {errors.voucherNumber && touched.voucherNumber && (
            <FormHelperText error>{t(errors.voucherNumber)}</FormHelperText>
          )}
        </Grid>

        <Grid item xs={12}>
          <InputLabel>{t('financial.debts_payment.pay_dialog.form.reference_label')}</InputLabel>
          <TextField
            fullWidth
            error={Boolean(errors.reference && touched.reference)}
            placeholder={t('financial.debts_payment.pay_dialog.form.reference_placeholder')}
            {...getFieldProps('reference')}
          />
          {errors.reference && touched.reference && (
            <FormHelperText error>{t(errors.reference)}</FormHelperText>
          )}
        </Grid>

        {/* Fecha de deposito */}
        <Grid item xs={12}>
          <InputLabel>
            {t(
              'financial.debts_payment.pay_dialog.form.deposit_transfer_method.deposit_date_label'
            )}
          </InputLabel>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={lang}>
            <DatePicker
              value={values.depositDate}
              onChange={(newValue) => {
                setFieldValue('depositDate', newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(errors.depositDate && touched.depositDate)}
                  placeholder={t(
                    'financial.debts_payment.pay_dialog.form.deposit_transfer_method.deposit_date_placeholder'
                  )}
                  fullWidth
                />
              )}
            />
          </LocalizationProvider>
          {errors.depositDate && touched.depositDate && (
            <FormHelperText error>{t(errors.depositDate as string)}</FormHelperText>
          )}
        </Grid>

        <Grid item xs={12}>
          <InputLabel>{t('financial.debts_payment.pay_dialog.form.observation_label')}</InputLabel>
          <TextField
            fullWidth
            multiline
            rows={2}
            error={Boolean(errors.observation && touched.observation)}
            placeholder={t('financial.debts_payment.pay_dialog.form.observation_placeholder')}
            {...getFieldProps('observation')}
          />
          {errors.observation && touched.observation && (
            <FormHelperText error>{t(errors.observation)}</FormHelperText>
          )}
        </Grid>

        <Grid item xs={12}>
          <Grid container justifyContent={'flex-end'} spacing={1}>
            <Grid item>
              <Button variant="outlined" onClick={onCancel}>
                {t('financial.debts_payment.pay_dialog.form.cancel_btn')}
              </Button>
            </Grid>
            <Grid item>
              <Button type="submit" variant="contained">
                {indexPayToEdit?.toString()
                  ? t('financial.debts_payment.pay_dialog.form.update_btn')
                  : t('financial.debts_payment.pay_dialog.form.save_btn')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default TransferDepositMethod;
