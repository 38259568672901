import { Chip } from '@material-ui/core';

interface props {
  status: string | null;
}

const ChipStatus = ({ status }: props) => {
  const handleLabel = () => {
    let label = '';

    switch (status) {
      case 'Cursando':
        label = 'Cursando';
        break;
      case 'Aprobado':
        label = 'Aprobado';
        break;
      case 'Reprobado':
        label = 'Reprobado';
        break;
      case null:
        label = 'No Cursada';
        break;

      default:
        break;
    }
    return label;
  };
  const handleColor = () => {
    let label = '';

    switch (status) {
      case 'Cursando':
        label = '#4F98CA';
        break;
      case 'Aprobado':
        label = '';
        break;
      case 'Reprobado':
        label = '';
        break;
      case null:
        label = 'No Cursada';
        break;

      default:
        break;
    }
    return label;
  };
  const handleBGColor = () => {
    let label = '';

    switch (status) {
      case 'Cursando':
        label = '#EFF8FF';
        break;
      case 'Aprobado':
        label = '';
        break;
      case 'Reprobado':
        label = '';
        break;
      case null:
        label = 'No Cursada';
        break;

      default:
        break;
    }
    return label;
  };

  return (
    <Chip
      label={handleLabel()}
      sx={{
        color: handleColor(),
        backgroundColor: handleBGColor(),
      }}
    />
  );
};

export default ChipStatus;
