import {
  Autocomplete,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  Skeleton,
  Switch,
  TextField,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppSelector } from '../../../../hooks/useRedux';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import InputLabel from '../../../shared/components/forms/InputLabel';
import { useGetCatalogByAcronymQuery } from '../../../shared/slices/catalogApi';
import { useGetTypePaymentSriQuery } from '../../../students/payments-history/slices/paymentsApiSlice';
import { ambienteAvailables } from '../../departments/constants/ambienteAvailables';
import { CompanyInfoSchema } from '../constants/validarionSchemas';
import { useUpdateCompanyMutation } from '../slices/companiesApiSlice';

const EditFinanceForm = () => {
  const { currentProfile } = useAppSelector((state) => state.access);
  const { company } = useAppSelector((state) => state.companies);
  const [showAlerts, setShowAlerts] = useState(false);
  const checkedContabilidad = !!company.co_obligadoContabilidad;

  const profileId = currentProfile!.profileId;

  const history = useHistory();

  const [updateCompany, { isLoading: isUpdating, isSuccess, isError, error }] =
    useUpdateCompanyMutation();

  const { data: typePayment = [], isLoading: isLoadingTP } = useGetTypePaymentSriQuery({
    data: 'all',
  });
  const { data: currency = [], isLoading: isLoadingC } = useGetCatalogByAcronymQuery({
    profileId,
    acronym: 'mon',
  });

  const { getFieldProps, errors, touched, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      name: company?.co_name || '',
      ruc: company?.co_ruc || '',
      website: company?.co_website || '',
      email: company?.co_email || '',
      statusId: 1,
      description: company?.co_description || '',
      businessName: company?.co_business_name || '',
      comercialName: company?.co_comercial_name || '',
      agentLegal: company?.co_agent_legal || '',
      legalIdentification: company?.co_legal_identification || '',
      typePerson: company?.co_person_type || '',
      address: company?.co_direction || '',
      mailInfo: company?.co_info_mail || '',
      phone: company?.co_phone || '',
      co_ambiente: company?.co_ambiente || 1,
      co_obligadoContabilidad: company?.co_obligadoContabilidad || false,
      co_contribuyenteEspecial: company?.co_contribuyenteEspecial || '',
      co_regimenMicroempresa: company?.co_regimenMicroempresa || '',
      co_pago_sri: company?.co_pago_sri || 1,
      co_moneda_id: company?.co_moneda_id,
      co_mision: company?.co_mision || '',
      co_vision: company?.co_vision || '',
      co_director_message: company?.co_director_message || '',
      co_url_file_director_photo: company?.co_url_file_director_photo || '',
    },
    validationSchema: CompanyInfoSchema,
    onSubmit: async (values) => {
      await updateCompany({
        profileId: currentProfile?.profileId!,
        companyId: company?.id!,
        companyPayload: {
          co_name: values.name,
          co_ruc: values.ruc,
          co_website: values.website,
          co_email: values.email,
          status_id: values.statusId.toString(),
          co_description: values.description,
          co_business_name: values.businessName,
          co_comercial_name: values.comercialName,
          co_agent_legal: values.agentLegal,
          co_legal_identification: values.legalIdentification,
          co_person_type: values.typePerson,
          co_direction: values.address,
          co_info_mail: values.mailInfo,
          co_phone: values.phone,
          co_ambiente: values.co_ambiente,
          co_obligadoContabilidad: values.co_obligadoContabilidad,
          co_contribuyenteEspecial: values.co_contribuyenteEspecial,
          co_regimenMicroempresa: values.co_regimenMicroempresa,
          co_pago_sri: values.co_pago_sri,
          co_moneda_id: values.co_moneda_id,
          co_mision: values.co_mision,
          co_vision: values.co_vision,
          co_director_message: values?.co_director_message || '',
          co_url_file_director_photo: values?.co_url_file_director_photo || '',
        },
      });

      setShowAlerts(true);
    },
  });

  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction="column" gap={2}>
        <Grid item>
          {isError && showAlerts && (
            <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
          )}

          {isSuccess && showAlerts && (
            <SuccessAlert
              message="Los datos financieros de la compañía se actualizaron."
              handleDismiss={() => setShowAlerts(false)}
            />
          )}
        </Grid>

        <Grid item container spacing={2} alignItems="start">
          <Grid container item xs={12} md={6} spacing={2}>
            <Grid item xs={12}>
              <InputLabel>Descripción</InputLabel>
              <TextField
                fullWidth
                rows={2}
                multiline
                error={Boolean(errors.description && touched.description)}
                placeholder="Ingrese la descripción de la compañía"
                {...getFieldProps('description')}
              />
              {errors.description && touched.description && (
                <FormHelperText error>{errors.description}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12}>
              <InputLabel>Razón Social</InputLabel>
              <TextField
                fullWidth
                error={Boolean(errors.businessName && touched.businessName)}
                placeholder="Ingrese la razón social de la compañía"
                {...getFieldProps('businessName')}
              />
              {errors.businessName && touched.businessName && (
                <FormHelperText error>{errors.businessName}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12}>
              <InputLabel>Nombre Comercial</InputLabel>
              <TextField
                fullWidth
                error={Boolean(errors.comercialName && touched.comercialName)}
                placeholder="Ingrese el nombre comercial de la compañía"
                {...getFieldProps('comercialName')}
              />
              {errors.comercialName && touched.comercialName && (
                <FormHelperText error>{errors.comercialName}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12}>
              <InputLabel>Representante Legal</InputLabel>
              <TextField
                fullWidth
                error={Boolean(errors.agentLegal && touched.agentLegal)}
                placeholder="Ingrese el representante legal de la compañía"
                {...getFieldProps('agentLegal')}
              />
              {errors.agentLegal && touched.agentLegal && (
                <FormHelperText error>{errors.agentLegal}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12}>
              <InputLabel>ID del Representante Legal</InputLabel>
              <TextField
                fullWidth
                error={Boolean(errors.legalIdentification && touched.legalIdentification)}
                placeholder="Ingrese el ID del representante legal de la compañía"
                {...getFieldProps('legalIdentification')}
              />
              {errors.legalIdentification && touched.legalIdentification && (
                <FormHelperText error>{errors.legalIdentification}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12}>
              <InputLabel>Tipo de persona</InputLabel>
              <TextField
                fullWidth
                error={Boolean(errors.typePerson && touched.typePerson)}
                placeholder="Ingrese el tipo de persona de la compañía"
                {...getFieldProps('typePerson')}
              />
              {errors.typePerson && touched.typePerson && (
                <FormHelperText error>{errors.typePerson}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12}>
              <InputLabel>Dirección</InputLabel>
              <TextField
                fullWidth
                rows={2}
                multiline
                error={Boolean(errors.address && touched.address)}
                placeholder="Ingrese la dirección de la compañía"
                {...getFieldProps('address')}
              />
              {errors.address && touched.address && (
                <FormHelperText error>{errors.address}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12}>
              <InputLabel>Email Informativo</InputLabel>
              <TextField
                fullWidth
                error={Boolean(errors.mailInfo && touched.mailInfo)}
                placeholder="Ingrese el email informativo de la compañía"
                {...getFieldProps('mailInfo')}
              />
              {errors.mailInfo && touched.mailInfo && (
                <FormHelperText error>{errors.mailInfo}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12}>
              <InputLabel>Teléfono</InputLabel>
              <TextField
                fullWidth
                error={Boolean(errors.phone && touched.phone)}
                placeholder="Ingrese el teléfono de la compañía"
                {...getFieldProps('phone')}
              />
              {errors.phone && touched.phone && (
                <FormHelperText error>{errors.phone}</FormHelperText>
              )}
            </Grid>
          </Grid>

          <Grid container item xs={12} md={6} spacing={2}>
            <Grid item xs={12}>
              <InputLabel>Ambiente</InputLabel>
              <Autocomplete
                options={ambienteAvailables}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(errors.co_ambiente && touched.co_ambiente)}
                  />
                )}
                defaultValue={ambienteAvailables.find((a) => a.id === Number(company?.co_ambiente))}
                onChange={(e, value) => setFieldValue('co_ambiente', value?.id || '')}
                fullWidth
                clearIcon={null}
              />
              {errors.statusId && touched.statusId && (
                <FormHelperText error>{errors.statusId}</FormHelperText>
              )}
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Contribuyente Especial</InputLabel>
              <TextField
                fullWidth
                error={Boolean(errors.co_contribuyenteEspecial && touched.co_contribuyenteEspecial)}
                placeholder="Contribuyente especial"
                {...getFieldProps('co_contribuyenteEspecial')}
              />
              {errors.co_contribuyenteEspecial && touched.co_contribuyenteEspecial && (
                <FormHelperText error>{errors.co_contribuyenteEspecial}</FormHelperText>
              )}
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Régimen de Microempresa</InputLabel>
              <TextField
                fullWidth
                error={Boolean(errors.co_regimenMicroempresa && touched.co_regimenMicroempresa)}
                placeholder="Régimen de microempresa"
                {...getFieldProps('co_regimenMicroempresa')}
              />
              {errors.co_regimenMicroempresa && touched.co_regimenMicroempresa && (
                <FormHelperText error>{errors.co_regimenMicroempresa}</FormHelperText>
              )}
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Tipo de pago SRI</InputLabel>
              {isLoadingTP ? (
                <Skeleton
                  variant="rectangular"
                  sx={{ width: '100%', borderRadius: 3, height: 50 }}
                />
              ) : (
                <Autocomplete
                  options={typePayment}
                  loading={isLoadingTP}
                  getOptionLabel={(option) => option.payment_sri}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Seleccione un tipo de pago"
                      error={Boolean(errors.co_pago_sri && touched.co_pago_sri)}
                    />
                  )}
                  defaultValue={typePayment.find((tp) => tp.id === Number(company?.co_pago_sri))}
                  onChange={(_, value) => {
                    setFieldValue('co_pago_sri', value?.id);
                  }}
                  fullWidth
                  clearIcon={null}
                />
              )}
              {errors.co_pago_sri && touched.co_pago_sri && (
                <FormHelperText error>{errors.co_pago_sri}</FormHelperText>
              )}
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Moneda</InputLabel>
              {isLoadingC ? (
                <Skeleton
                  variant="rectangular"
                  sx={{ width: '100%', borderRadius: 3, height: 50 }}
                />
              ) : (
                <Autocomplete
                  options={currency}
                  loading={isLoadingC}
                  getOptionLabel={(option) => option.cat_name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Seleccione un tipo de pago"
                      error={Boolean(errors.co_pago_sri && touched.co_pago_sri)}
                    />
                  )}
                  defaultValue={currency.find((c) => c.id === Number(company?.co_moneda_id))}
                  onChange={(_, value) => {
                    setFieldValue('co_moneda_id', value?.id);
                  }}
                  fullWidth
                  clearIcon={null}
                />
              )}
              {errors.co_pago_sri && touched.co_pago_sri && (
                <FormHelperText error>{errors.co_pago_sri}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12}>
              <InputLabel>¿Obligado a llevar contabilidad?</InputLabel>
              <Switch
                defaultChecked={checkedContabilidad}
                onChange={(e) => {
                  setFieldValue('co_obligadoContabilidad', e.target.checked);
                }}
              />
            </Grid>
          </Grid>

          <Grid
            item
            container
            alignItems="center"
            justifyContent="flex-end"
            spacing={2}
            sx={{ mt: 1 }}
          >
            <Grid item>
              <Button
                variant="outlined"
                size="large"
                color="primary"
                onClick={() => history.goBack()}
              >
                Regresar
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                size="large"
                color="primary"
                disabled={isUpdating}
                endIcon={isUpdating && <CircularProgress size={20} />}
              >
                Actualizar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default EditFinanceForm;
