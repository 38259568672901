import { Grid } from '@material-ui/core';
import MainCard from '../../../shared/components/cards/MainCard';
import { HelpCenter } from '../components/HelpCenter';

export const HelpCenterScreen = () => {

   return (
      <Grid component={MainCard} sx={{ padding: 2, height: '100%' }}>
         <HelpCenter />
      </Grid>
   );
};

