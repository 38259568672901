import { useState } from 'react';

import { Grid, IconButton } from '@material-ui/core';
import { Download } from '@material-ui/icons';
import { DateRange } from '@material-ui/lab/DateRangePicker';
import downloadjs from 'downloadjs';
import { format } from 'date-fns';

import { useAppSelector } from '../../../../hooks/useRedux';
import { useTableSearch } from '../../../../hooks/useTableSearch';
import SearchPaginatedTable from '../../../shared/components/tables/SearchPaginatedTable';
import { useGetBlackboardFilesQuery } from '../slices/blackboardApiSlice';
import { updateBlackboardFilesTable } from '../slices/blackboardSlice';
import LocalizedDateRangePicker from '../../../shared/components/controlled/LocalizedDateRangePicker';

const BlackboardFilesTable = () => {
  const [valuesDates, setValuesDates] = useState<DateRange<Date>>([new Date(), new Date()]);

  const {
    blackboardFilesTable: { page, perPage, search, filterArrow },
  } = useAppSelector((state) => state.blackboard);

  const { data, isLoading, isFetching, error } = useGetBlackboardFilesQuery({
    page,
    perPage,
    search,
    ...(valuesDates[0] && { start_date: format(valuesDates[0], 'yyyy-MM-dd') }),
    ...(valuesDates[1] && { end_date: format(valuesDates[1], 'yyyy-MM-dd') }),
    ...(filterArrow && { sort: filterArrow.name, type_sort: filterArrow.direction }),
  });

  const { setPage, setPerPage, setSearch, setFilterArrow } = useTableSearch({
    page,
    perPage,
    search,
    filterArrow,
    updateFunction: updateBlackboardFilesTable,
  });

  const handleGetFile = (fileUrl: string) => {
    try {
      downloadjs(fileUrl);
    } catch (error) {
      console.log(Error);
    }
  };

  const onChangeDates = (newDatesValues: DateRange<Date>) => {
    setValuesDates(newDatesValues);
  };

  const datafetch =
    data?.data?.map((file, index) => ({
      id: index + ((page - 1) * perPage + 1),
      name: file.file_name,
      date: file.created_at,
      options: {
        url: file.file_url,
        isDownloadable: Boolean(Number(file.download)),
      },
    })) || [];

  return (
    <SearchPaginatedTable
      data={datafetch}
      isLoading={isLoading}
      isFetching={isFetching}
      error={error}
      numHeader={4}
      keyExtractor={(item) => `${item.id}`}
      customDateFormat="dd-MMM-yyyy HH:mm:ss"
      headers={{
        id: 'N°',
        name: 'Nombre',
        date: 'Fecha de creación',
        options: 'Opciones',
      }}
      listFilterArrows={{
        name: 'file_name',
        date: 'created_at',
      }}
      filterArrow={filterArrow}
      setFilterArrow={setFilterArrow}
      total={data?.total || 0}
      page={page}
      perPage={perPage}
      search={search}
      setPage={setPage}
      setPerPage={setPerPage}
      setSearch={setSearch}
      searchPlacehoder="Buscar por ID"
      customDataCellsProperties={{
        id: {
          align: 'center',
        },
        name: {
          align: 'center',
        },
        date: {
          align: 'center',
        },
        options: {
          align: 'center',
        },
      }}
      customRenderers={{
        options: (row) => (
          <IconButton
            onClick={() => handleGetFile(row.options.url)}
            disabled={!row.options.isDownloadable}
          >
            <Download />
          </IconButton>
        ),
      }}
      additionalFilters={
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={8}>
            <LocalizedDateRangePicker
              value={valuesDates}
              onChange={onChangeDates}
              startText="Fecha inicial"
              endText="Fecha fin"
            />
          </Grid>
        </Grid>
      }
    />
  );
};

export default BlackboardFilesTable;
