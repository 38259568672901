import { Switch } from 'react-router-dom';
import GuardRoute from '../../../../router/guards/GuardRoute';

import CoursesScreen from '../screens/CoursesScreen';
import { subPermissionKeys } from '../constants/permisssion';

const ClassroomEdLvlRouter = () => {
  return (
    <Switch>
      <GuardRoute
        exact
        path="/administrar-cursos"
        component={CoursesScreen}
        permissionKey={subPermissionKeys.groupKey}
        subPermissionKey={[
          subPermissionKeys.readCampus,
          subPermissionKeys.readOffers,
          subPermissionKeys.readOffersById,
          subPermissionKeys.readOfferByPeriod,
          subPermissionKeys.readCourses,
        ]}
      />
    </Switch>
  );
};

export default ClassroomEdLvlRouter;
