import { Switch, Redirect } from 'react-router-dom';
import { useAppSelector } from '../../../../hooks/useRedux';
import GuardRoute from '../../../../router/guards/GuardRoute';
import { subPermissionKeys } from '../constants/permissions';
import AddClassroomScreen from '../screens/AddClassroomScreen';
import ClassroomsScreen from '../screens/ClassroomsScreen';
import EditClassroomScreen from '../screens/EditClassroomScreen';

const { groupKey: permissionKey } = subPermissionKeys;

const ClassroomsRouter = () => {
  const { currentModule } = useAppSelector((state) => state.access);

  const hasPermission = subPermissionKeys.groupKey in currentModule?.permissions!;

  if (!hasPermission) return <Redirect to="/" />;

  return (
    <>
      <Switch>
        <GuardRoute
          exact
          path="/administrar-aulas"
          component={ClassroomsScreen}
          subPermissionKey={subPermissionKeys.read}
          permissionKey={permissionKey}
        />
        <GuardRoute
          exact
          path="/administrar-aulas/agregar"
          component={AddClassroomScreen}
          subPermissionKey={subPermissionKeys.create}
          permissionKey={permissionKey}
        />
        <GuardRoute
          exact
          path="/administrar-aulas/:classroomId/editar"
          component={EditClassroomScreen}
          subPermissionKey={subPermissionKeys.edit}
          permissionKey={permissionKey}
        />
      </Switch>
    </>
  );
};

export default ClassroomsRouter;
