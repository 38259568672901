import { ThunkDispatch } from 'redux-thunk';
import { setCurrentRecordStudent, setCurrentStudent } from '../../shared/reducers/accessReducer';
import { User } from '../interfaces/login.interfaces';
import { StudentRecord } from '../interfaces/student-record.interfaces';
import { VerifyResponse } from '../interfaces/verify.interfaces';
import { setStudentInfo, setStudentRecordReference } from '../slices/authSlice';

/**
 * Asigna el estudiante al store
 * @param dispatch
 * @param data
 */
export const setCurrentStudentIfExist = (
  dispatch: ThunkDispatch<any, any, any>,
  data: VerifyResponse | User
) => {
  const { student } = data;
  if (student) {
    dispatch(setCurrentStudent(student));
    if (student.student_records.length !== 0)
      checkStudentRecordQuantity(dispatch, student.student_records);
  }
};

export const checkStudentRecordQuantity = (
  dispatch: ThunkDispatch<any, any, any>,
  studentRecord: StudentRecord[]
) => {
  if (studentRecord.length === 0) {
    throw new Error('Usuario no contiene record estudiantil');
  }

  dispatch(setStudentRecordReference(studentRecord));
  const idStudentRecord = localStorage.getItem('currentStudentRecord');
  const sr = studentRecord.find((sr) => sr.id.toString() === idStudentRecord);
  if (idStudentRecord && sr) {
    setStudentRecordInfo(dispatch, sr);
  } else {
    setStudentRecordInfo(dispatch, studentRecord[0]);
  }
};

export const setStudentRecordInfo = (
  dispatch: ThunkDispatch<any, any, any>,
  studentRecord: StudentRecord
) => {
  const { id, mesh_id, education_level, type_student } = studentRecord;

  dispatch(
    setStudentInfo({
      studentRecordId: id,
      curriculumId: Number(mesh_id),
      offerId: Number(education_level?.offer_id),
      typeStudent: type_student?.te_acronym,
      offerName: education_level?.offer?.off_name,
      offerDescription: education_level?.offer?.off_description,
    })
  );
  dispatch(setCurrentRecordStudent(studentRecord));
  localStorage.setItem('currentStudentRecord', id.toString());
};
