import { useState } from 'react';
import { Box, CircularProgress, Grid, Tab, Tabs } from '@material-ui/core';
import { useStyles } from '../../../constants/styles';
import { Link as RouterLink } from 'react-router-dom';
import MainCard from '../../../../../shared/components/cards/MainCard';
import { FormikErrors, useFormikContext } from 'formik';
import { useAppSelector } from '../../../../../../hooks/useRedux';
import GenerationQuote from '../tabs/GenerationQuote';
import RegisterMatterAdmin from './RegisterMatterAdmin';
// import RegisterMatterStudentPortal from './RegisterMatterStudentPortal';
import RequestMatterGrade from './RequestMatterGrade';
import EntryTeachersCourse from './EntryTeachersCourse';
import RegisterMatter from './RegisterMatter';
import StartProgramMaestry from './StartProgramMaestry';
import StartClass from './StartClass';
import { PeriodForm } from '../../../interfaces/period-form.interfaces';
// import InfoAlert from '../../../../../shared/components/alerts/InfoAlert';
import {
  STAGES,
  GENERACION_CUOTAS,
  INGRESO_DOCENTES_CURSO,
  INICIO_PROGRAMA_MAESTRIA,
  REGISTRO_ASIGNATURAS,
  REGISTRO_MATERIAS_ADMIN,
  INICIO_CLASES,
  // REGISTRO_MATERIAS_PORTAL_ESTUDIANTIL,
  SOLICITUD_APERTURA_MATERIAS_GRADO,
} from '../../../constants/stages';
import { useTranslation } from 'react-i18next';
import { useGetStagesByOfferAcademicConfigQuery } from '../../../../periods/slices/periodsApiSlice';

export const StagesTabs = function () {
  const { t } = useTranslation();

  const classes = useStyles();

  const [tabValue, setTabValue] = useState(0);

  const { errors } = useFormikContext<PeriodForm>();

  const { currentProfile } = useAppSelector((state) => state.access);

  const { academicOfferConfig } = useAppSelector((state) => state.periods);

  const config = academicOfferConfig?.map((e) => e.stg_acronym);

  // const checkStageValid = (fieldName: string, iterator: number): boolean => {
  //   const field = values[fieldName as unknown as keyof PeriodForm] as Stage;
  //   const { start_date, end_date } = field;

  //   if (start_date === null) {
  //     setFieldError(
  //       `${STAGES[iterator].field}`,
  //       `${t(STAGES[iterator].descriptionId)},  ${t(
  //         'academic.periods_list.add.start_date.required'
  //       )}`
  //     );
  //     return false;
  //   }
  //   if (end_date === null) {
  //     setFieldError(
  //       `${STAGES[iterator].field}`,
  //       `${STAGES[iterator].description}, ${t('academic.periods_list.add.end_date.required')}`
  //     );
  //     return false;
  //   }
  //   return true;
  // };

  const checkAvailablesDates = () => {
    for (let i = 0; i < STAGES.length; i++) {
      if (config.includes(STAGES[i].use_case)) {
        // checkStageValid(STAGES[i].field, i);
      }
    }
  };

  const handleChange = (event: React.SyntheticEvent | null, newValue: number) => {
    checkAvailablesDates();
    setTabValue(newValue);
  };

  const { isLoading, isFetching } = useGetStagesByOfferAcademicConfigQuery({
    offerId: 3,
    profileId: currentProfile?.profileId!,
  });

  const TabPanel = (props: { children: React.ReactElement; value: number; index: number }) => {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={0}>{children}</Box>}
      </div>
    );
  };

  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  const RenderTabs = () => {
    return (
      <Tabs
        value={tabValue}
        indicatorColor="primary"
        className={classes.accountTab}
        aria-label="StagesPeriods"
        variant="fullWidth"
        onChange={handleChange}
        centered
      >
        <Tab
          sx={{
            display: config?.includes(GENERACION_CUOTAS) ? 'block' : 'none',
            ...(errors[
              STAGES.find((s) => s.use_case === GENERACION_CUOTAS)
                ?.field! as keyof FormikErrors<PeriodForm>
            ] && {
              color: 'red !important',
            }),
          }}
          component={RouterLink}
          to="#"
          label={t('academic.periods_list.add.stage_3.tab_1')}
          {...a11yProps(0)}
        />
        <Tab
          sx={{
            display: config?.includes(REGISTRO_MATERIAS_ADMIN) ? 'block' : 'none',
            ...(errors[
              STAGES.find((s) => s.use_case === REGISTRO_MATERIAS_ADMIN)
                ?.field! as keyof FormikErrors<PeriodForm>
            ] && {
              color: 'red !important',
            }),
          }}
          component={RouterLink}
          to="#"
          label={t('academic.periods_list.add.stage_3.tab_2')}
          {...a11yProps(1)}
        />
        {/* <Tab
          sx={{
            display: config?.includes(REGISTRO_MATERIAS_PORTAL_ESTUDIANTIL) ? 'block' : 'none',
            ...(errors[
              STAGES.find((s) => s.use_case === REGISTRO_MATERIAS_PORTAL_ESTUDIANTIL)
                ?.field! as keyof FormikErrors<PeriodForm>
            ] && { color: 'red !important' }),
          }}
          component={RouterLink}
          to="#"
          label={t('academic.periods_list.add.stage_3.tab_3')}
          {...a11yProps(2)}
        /> */}
        <Tab
          sx={{
            display: config?.includes(INICIO_PROGRAMA_MAESTRIA) ? 'block' : 'none',
            ...(errors[
              STAGES.find((s) => s.use_case === INICIO_PROGRAMA_MAESTRIA)
                ?.field! as keyof FormikErrors<PeriodForm>
            ] && {
              color: 'red !important',
            }),
          }}
          component={RouterLink}
          to="#"
          label={t('academic.periods_list.add.stage_3.tab_4')}
          {...a11yProps(2)}
        />
        <Tab
          sx={{
            display: config?.includes(SOLICITUD_APERTURA_MATERIAS_GRADO) ? 'block' : 'none',
            ...(errors[
              STAGES.find((s) => s.use_case === SOLICITUD_APERTURA_MATERIAS_GRADO)
                ?.field! as keyof FormikErrors<PeriodForm>
            ] && { color: 'red !important' }),
          }}
          component={RouterLink}
          to="#"
          label={t('academic.periods_list.add.stage_3.tab_5')}
          {...a11yProps(3)}
        />
        <Tab
          sx={{
            display: config?.includes(INGRESO_DOCENTES_CURSO) ? 'block' : 'none',
            ...(errors[
              STAGES.find((s) => s.use_case === INGRESO_DOCENTES_CURSO)
                ?.field! as keyof FormikErrors<PeriodForm>
            ] && {
              color: 'red !important',
            }),
          }}
          component={RouterLink}
          to="#"
          label={t('academic.periods_list.add.stage_3.tab_6')}
          {...a11yProps(4)}
        />
        <Tab
          sx={{
            display: config?.includes(REGISTRO_ASIGNATURAS) ? 'block' : 'none',
            ...(errors[
              STAGES.find((s) => s.use_case === REGISTRO_ASIGNATURAS)
                ?.field! as keyof FormikErrors<PeriodForm>
            ] && {
              color: 'red !important',
            }),
          }}
          component={RouterLink}
          to="#"
          label={t('academic.periods_list.add.stage_3.tab_7')}
          {...a11yProps(5)}
        />
        <Tab
          sx={{
            display: config?.includes(INICIO_CLASES) ? 'block' : 'none',
            ...(errors[
              STAGES.find((s) => s.use_case === INICIO_CLASES)
                ?.field! as keyof FormikErrors<PeriodForm>
            ] && {
              color: 'red !important',
            }),
          }}
          component={RouterLink}
          to="#"
          label={t('academic.periods_list.add.stage_3.tab_8')}
          {...a11yProps(6)}
        />
      </Tabs>
    );
  };

  // const RenderErrors = () => {
  //   return (
  //     <>
  //       <Grid item xs={12} sx={{ mt: 2, width: 1 }}>
  //         <InfoAlert
  //           message={
  //             Object.keys(errors).map((e) => {
  //               if (e === 'generation_quote' && errors[e]) {
  //                 return `${STAGES.find((s) => s.field === e)?.description}, ${(
  //                   errors[e] as FormikErrors<Stage>[]
  //                 )?.map(
  //                   (error, index) => `Cuota ${index + 1}: ${Object.values(error).map((e) => e)}`
  //                 )}`;
  //               }
  //               return `${
  //                 STAGES.find((s) => s.field === e.toString())?.description
  //               }: ${Object.values(errors[e as unknown as keyof PeriodForm] as Stage).map(
  //                 (v) => v
  //               )}`;
  //             })!
  //           }
  //         />
  //       </Grid>
  //     </>
  //   );
  // };

  const Loader = () => {
    return (
      <Grid container item justifyContent="center" alignItems="center">
        <CircularProgress size={50} />
      </Grid>
    );
  };

  const ErrorConfig = () => {
    return (
      <Grid container item justifyContent="center" alignItems="center">
        <h1>{t('academic.periods_list.add.stage_3.no_config')}</h1>
      </Grid>
    );
  };

  return (
    <>
      {isLoading || isFetching ? (
        <Loader />
      ) : (
        <Grid
          p={2}
          sx={{
            mt: 1,
            border: `3px solid #F2F3F4`,
            borderRadius: '10px',
          }}
          component={MainCard}
        >
          {/* {Object.values(errors)?.length !== 0 && <RenderErrors />} */}
          <Grid item xs={12} sx={{ mt: 2, width: 1 }}>
            <Box width="100%" sx={{ maxWidth: 1500, mt: 1, margin: 'auto' }}>
              {config?.length === 0 ? <ErrorConfig /> : <RenderTabs />}
            </Box>
          </Grid>
          {config?.length !== 0 && (
            <>
              <TabPanel value={tabValue} index={0}>
                <GenerationQuote />
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <RegisterMatterAdmin />
              </TabPanel>
              {/* <TabPanel value={tabValue} index={2}>
                <RegisterMatterStudentPortal />
              </TabPanel> */}
              <TabPanel value={tabValue} index={2}>
                <StartProgramMaestry />
              </TabPanel>
              <TabPanel value={tabValue} index={3}>
                <RequestMatterGrade />
              </TabPanel>
              <TabPanel value={tabValue} index={4}>
                <EntryTeachersCourse />
              </TabPanel>
              <TabPanel value={tabValue} index={5}>
                <RegisterMatter />
              </TabPanel>
              <TabPanel value={tabValue} index={6}>
                <StartClass />
              </TabPanel>
            </>
          )}
        </Grid>
      )}
    </>
  );
};
