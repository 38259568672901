import { Grid } from '@material-ui/core';
import { useState } from 'react';
import MainCard from '../../../../shared/components/cards/MainCard';
import { renderSkeletonTable } from '../../../../shared/helpers/render';
import MatterDetailTable from '../../components/tables/MatterDetailTable';
import { useGetAcademicPortfolioMatterDetailNoteQuery } from '../../slices/AcademicPortfolioApiSlice';

const MatterDetailNote = function () {
  const { data, isLoading, isFetching, error } = useGetAcademicPortfolioMatterDetailNoteQuery();

  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(5);
  const [search, setSearch] = useState<string>('');

  return (
    <Grid component={MainCard}>
      {isLoading ? (
        renderSkeletonTable(4, 6)
      ) : (
        <MatterDetailTable
          data={data!}
          headers={{
            id: 'N°',
            file_detail: 'Ficha detalle',
            type: 'Tipo',
            archive: 'Archivo',
          }}
          page={page}
          setPage={setPage}
          perPage={perPage}
          error={error}
          numHeader={7}
          isFetching={isFetching}
          isLoading={isLoading}
          keyExtractor={(value) => value.id.toString()}
          setPerPage={setPerPage}
          searchPlacehoder="Buscar"
          searchField={false}
          search={search}
          setSearch={setSearch}
        />
      )}
    </Grid>
  );
};

export default MatterDetailNote;
