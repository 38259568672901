import {
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import useGetBaseUrl from '../../../../hooks/useGetBaseUrl';
import { useAppSelector } from '../../../../hooks/useRedux';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import { useUploadFilesMutation } from '../../../shared/slices/uploadFilesApi';
import DragArea from '../../../shared/components/files/DragArea';
import { useFormStyles } from '../constants/styles';
import { useUpdateCompanyMutation } from '../slices/companiesApiSlice';
// import DragArea from '../../../students/virtual-classroom/components/DragArea';
// import { CompanyInfoSchema } from '../constants/validarionSchemas';

const EditMisionVisionForm = () => {
  const { currentProfile } = useAppSelector((state) => state.access);
  const { company } = useAppSelector((state) => state.companies);
  const classes = useFormStyles();
  const [showAlerts, setShowAlerts] = useState(false);
  const [directorPhoto, setDirectorPhoto] = useState<File | null>(null);

  const { baseUrl } = useGetBaseUrl();

  const history = useHistory();

  const [updateCompany, { isLoading: isUpdating, isSuccess, isError, error }] =
    useUpdateCompanyMutation();

  const [uploadFile, { isLoading: isUploading }] = useUploadFilesMutation();

  const { getFieldProps, errors, touched, handleSubmit } = useFormik({
    initialValues: {
      name: company?.co_name || '',
      ruc: company?.co_ruc || '',
      website: company?.co_website || '',
      email: company?.co_email || '',
      statusId: 1,
      co_mision: company?.co_mision || '',
      co_vision: company?.co_vision || '',
      co_director_message: company?.co_director_message || '',
      co_url_file_director_photo: company?.co_url_file_director_photo || '',
    },
    // validationSchema: CompanyInfoSchema,
    onSubmit: async (values) => {
      let urlPhoto = values.co_url_file_director_photo;

      if (directorPhoto) {
        urlPhoto = await uploadFile({
          profileId: currentProfile?.profileId!,
          files: directorPhoto,
        }).unwrap();
      }

      await updateCompany({
        profileId: currentProfile?.profileId!,
        companyId: company?.id!,
        companyPayload: {
          co_name: values.name,
          co_ruc: values.ruc,
          co_website: values.website,
          co_email: values.email,
          status_id: values.statusId.toString(),
          co_mision: values.co_mision,
          co_vision: values.co_vision,
          co_director_message: values.co_director_message,
          co_url_file_director_photo: urlPhoto,
        },
      });

      setShowAlerts(true);
    },
  });
  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction="column" gap={2}>
        <Grid item>
          {isError && showAlerts && (
            <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
          )}

          {isSuccess && showAlerts && (
            <SuccessAlert
              message="Los datos se actualizaron correctamente."
              handleDismiss={() => setShowAlerts(false)}
            />
          )}
        </Grid>

        <Grid item container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>Misión</InputLabel>
            <TextField
              fullWidth
              multiline
              rows={3}
              error={Boolean(errors.co_mision && touched.co_mision)}
              placeholder="Ingrese la misión de la compañía"
              {...getFieldProps('co_mision')}
            />
            {errors.co_mision && touched.co_mision && (
              <FormHelperText error>{errors.co_mision}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>Visión</InputLabel>
            <TextField
              fullWidth
              multiline
              rows={3}
              error={Boolean(errors.co_vision && touched.co_vision)}
              placeholder="Ingrese la visión de la compañía"
              {...getFieldProps('co_vision')}
            />
            {errors.co_vision && touched.co_vision && (
              <FormHelperText error>{errors.co_vision}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>Mensaje de la autoridad</InputLabel>
            <TextField
              fullWidth
              multiline
              rows={3}
              error={Boolean(errors.co_director_message && touched.co_director_message)}
              placeholder="Ingrese el mensaje de la autoridad"
              {...getFieldProps('co_director_message')}
            />
            {errors.co_director_message && touched.co_director_message && (
              <FormHelperText error>{errors.co_director_message}</FormHelperText>
            )}
          </Grid>

          {company?.co_url_file_director_photo && (
            <Grid item xs={12} md={3} sx={{ height: 230 }}>
              <InputLabel className={classes.inputLabel}>Foto actual</InputLabel>
              <img
                src={`${baseUrl}/show?name=${company?.co_url_file_director_photo}`}
                alt="Director"
                style={{ height: '100%', width: '100%', objectFit: 'contain' }}
              />
            </Grid>
          )}

          <Grid item xs={12} md={company?.co_url_file_director_photo ? 9 : 12}>
            <DragArea
              handleChange={(e) => setDirectorPhoto(e.target.files![0])}
              label="Foto del director"
              description="La foto del director debe ser una imagen de no más de 2MB"
              typesAccepted="image/*"
            />
          </Grid>

          <Grid
            item
            container
            alignItems="center"
            justifyContent="flex-end"
            spacing={2}
            sx={{ mt: 1 }}
          >
            <Grid item>
              <Button
                variant="outlined"
                size="large"
                color="primary"
                onClick={() => history.goBack()}
              >
                Regresar
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                size="large"
                color="primary"
                disabled={isUpdating || isUploading}
                endIcon={(isUpdating || isUploading) && <CircularProgress size={20} />}
              >
                Actualizar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default EditMisionVisionForm;
