import { useAppDispatch } from '../../../../hooks/useRedux';
import CustomSideDialog from '../../../shared/components/dialogs/CustomSideDialog';
import { setIsOpenHistorialSyllabusModal, setSyllabusIdSelected } from '../slices/SyllabusSlice';
import HistorialTimeline from './HistorialTimeline';

const HistorialSyllabusDialog = () => {
  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(setIsOpenHistorialSyllabusModal(false));
    dispatch(setSyllabusIdSelected(null));
  };

  return (
    <CustomSideDialog open handleCloseDialog={handleClose} title="Historial">
      <HistorialTimeline />
    </CustomSideDialog>
  );
};

export default HistorialSyllabusDialog;
