import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../hooks/useRedux';
import MainCard from '../../../shared/components/cards/MainCard';
import PosgraduatePlanningHourhandsTable from '../components/PosgraduatePlanningHourhandsTable';
import PostgraduatePlanningHourhandDeleteDialog from '../components/PostgraduatePlanningHourhandDeleteDialog';

const PosgraduatePlanningHourhandsScreen = () => {
  const { t } = useTranslation();

  const { posgraduatePlanningHourhandIdSelected, posgraduatePlanningHourhandDeleteDialogOpen } =
    useAppSelector((state) => state.posgraduatePlanningHourhands);

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2" mb={2}>
          {t('posgraduate.hourhands.title')}
        </Typography>
      </Grid>
      <PosgraduatePlanningHourhandsTable />
      {posgraduatePlanningHourhandIdSelected && posgraduatePlanningHourhandDeleteDialogOpen && (
        <PostgraduatePlanningHourhandDeleteDialog />
      )}
    </Grid>
  );
};

export default PosgraduatePlanningHourhandsScreen;
