import { Backdrop, Box, Button, Fade, Modal, Typography, useTheme } from '@material-ui/core';
import { FileDownloadOutlined } from '@material-ui/icons';
import moment from 'moment';
import { ToolsMaterial } from '../interfaces/materialsStudent.interfaces';
import downloadjs from 'downloadjs';
import { useTranslation } from 'react-i18next';
import useGetUrlDownload from '../../../../../hooks/useGetUrlDownload';

interface Props {
  open: boolean;
  handleClose: () => void;
  dataModal: ToolsMaterial | undefined;
}

const ModalDownload = ({ open, handleClose, dataModal }: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: '8px',
    boxShadow: 24,
    p: 3,
  };

  const { urlDownload } = useGetUrlDownload();

  const handleDownload = () => {
    if (dataModal) {
      const url = `${urlDownload}/${dataModal.tool_ms_url_file}`;
      // console.log(url);
      try {
        downloadjs(url.replace(' ', '%20'));
      } catch (error) {
        console.log(Error);
      }
      handleClose();
    }
  };
  return (
    <Modal
      aria-labelledby="spring-modal-title"
      aria-describedby="spring-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <Typography
            sx={{
              fontWeight: 'fontWeightBold',
              mb: 2,
            }}
            color={theme.palette.primary.dark}
          >
            {dataModal?.tool_ms_name_file}
          </Typography>
          <Box
            sx={{
              display: 'flex',
            }}
          >
            <Typography
              sx={{
                fontWeight: 'fontWeightBold',
              }}
              color={theme.palette.primary.dark}
            >
              {t('studentVirtualClassroom.materials.content.uploaded')}:
            </Typography>
            <Typography
              sx={{
                fontWeight: 'fontWeightBold',
                px: '0.5rem',
              }}
            >
              {`${
                dataModal?.upload_date
                  ? moment(dataModal?.upload_date).format('DD MMM - hh:mm')
                  : ''
              }`}
            </Typography>
          </Box>
          <Typography sx={{ mt: 2, fontWeight: 'fontWeightBold' }}>
            {dataModal?.tool_ms_description}
          </Typography>
          <hr />
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Button
              startIcon={<FileDownloadOutlined />}
              variant="contained"
              color="primary"
              onClick={handleDownload}
              sx={{
                marginTop: '0.5rem',
              }}
            >
              {t('studentVirtualClassroom.materials.content.download')}
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default ModalDownload;
