import { mainApi } from '../../../../../../api/mainApi';
import { middlewareApi } from '../../../../../middleware/middlewareApi';
import { ISingleResponse } from '../../../../../shared/interfaces/response.interface';
import { IEvaluation, IEvaluationsResponse } from '../interfaces/evaluations.interface';

export const evaluationsTags = middlewareApi.enhanceEndpoints({
  addTagTypes: ['Evaluations'],
});

export const evaluationsApi = evaluationsTags.injectEndpoints({
  endpoints: (builder) => ({
    getEvaluations: builder.query<
      IEvaluationsResponse,
      {
        page: number;
        perPage: number;
        search: string;
        sort?: string;
        typeSort?: string;
        evaTypeId?: string;
        integralEvaluationId?: string;
      }
    >({
      queryFn: async (
        { page, perPage, search, sort, typeSort, evaTypeId, integralEvaluationId },
        { dispatch }
      ) => {
        try {
          const { data } = await mainApi.get<IEvaluationsResponse>('/eva/evaluations', {
            params: {
              page,
              size: perPage,
              search,
              ...(evaTypeId && { eva_type_id: evaTypeId }),
              ...(integralEvaluationId && { integral_evaluation_cab_uuid: integralEvaluationId }),
              ...(sort && { sort }),
              ...(typeSort && { type_sort: typeSort }),
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) =>
        result && result.data
          ? [
              ...result.data.map(({ uuid: id }) => ({ type: 'Evaluations', id } as const)),
              { type: 'Evaluations', id: 'LIST' },
            ]
          : [{ type: 'Evaluations', id: 'LIST' }],
    }),
    getEvaluation: builder.query<
      IEvaluation,
      {
        id: string;
      }
    >({
      queryFn: async ({ id }, { dispatch }) => {
        try {
          const { data } = await mainApi.get<IEvaluation>(`/eva/evaluations/${id}`);
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),
    addEvaluation: builder.mutation<
      IEvaluation,
      {
        description: string;
        evaluationTypeId: string;
        questions: string[];
        scopes: {
          categorie_id: string;
          weight: number;
        }[];
        integralEvaluationId: string;
      }
    >({
      queryFn: async ({
        description,
        evaluationTypeId,
        questions,
        scopes,
        integralEvaluationId,
      }) => {
        try {
          const { data } = await mainApi.post<IEvaluation>('/eva/evaluations', {
            description,
            eva_type_id: evaluationTypeId,
            questions,
            categories: scopes,
            integral_evaluation_cab_uuid: integralEvaluationId,
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'Evaluations', id: 'LIST' }],
    }),

    updateEvaluation: builder.mutation<
      IEvaluation,
      {
        id: string;
        description: string;
        evaluationTypeId: string;
        questions: string[];
        addedQuestions: string[];
        deletedQuestions: string[];
        scopes: {
          categorie_id: string;
          weight: number;
        }[];
        integralEvaluationId: string;
      }
    >({
      queryFn: async ({
        id,
        description,
        evaluationTypeId,
        questions,
        addedQuestions,
        deletedQuestions,
        scopes,
        integralEvaluationId,
      }) => {
        try {
          const { data } = await mainApi.put<ISingleResponse<IEvaluation>>(
            `/eva/evaluations/${id}`,
            {
              description,
              eva_type_id: evaluationTypeId,
              questions,
              attach_questions: addedQuestions,
              detach_questions: deletedQuestions,
              categories: scopes,
              integral_evaluation_cab_uuid: integralEvaluationId,
            }
          );
          return { data: data.data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'Evaluations', id: 'LIST' }],
    }),
    deleteEvaluation: builder.mutation<
      IEvaluation,
      {
        id: string;
      }
    >({
      queryFn: async ({ id }, { dispatch }) => {
        try {
          const { data } = await mainApi.delete<IEvaluation>(`/eva/evaluations/${id}`);
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'Evaluations', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetEvaluationsQuery,
  useLazyGetEvaluationsQuery,
  useGetEvaluationQuery,
  useLazyGetEvaluationQuery,
  useAddEvaluationMutation,
  useUpdateEvaluationMutation,
  useDeleteEvaluationMutation,
} = evaluationsApi;
