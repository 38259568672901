import { useState, useRef } from 'react';
import { Grid, MobileStepper, useTheme, Typography, FormHelperText } from '@material-ui/core';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import EditorTextSylabus from '../EditorTextSylabus';
import { RegisterSyllabusData } from '../../../interfaces/registerSyllabusData';
import { useFormikContext } from 'formik';

const stepsArray = [
  { id: 1, name: 'Descripción y Justificación' },
  { id: 2, name: 'Objeto de estudio' },
  { id: 3, name: 'Objetivo' },
  { id: 4, name: 'Resultados de aprendizaje' },
  { id: 5, name: 'Contenidos' },
  { id: 6, name: 'Métodos, medios, forma y evaluación' },
];

interface Props {
  isEdit?: boolean;
}

const WizardSyllabus = ({ isEdit }: Props) => {
  const theme = useTheme();

  const history = useHistory();
  const { id, courseId, period } = useParams<{ id?: string; courseId?: string; period?: string }>();
  const { path } = useRouteMatch();

  const { values, setFieldValue, errors } = useFormikContext<RegisterSyllabusData>();

  const [activeStep, setActiveStep] = useState(1);

  const inputElement = useRef<HTMLDivElement>(null);

  const isValidtedStringEditor = (dt: string) => {
    const regex = /^<p>(?!(<\/p>|<br\s*\/?>|&nbsp;|\s)*$)[^<]*<\/p>$/;
    return regex.test(dt);
  };

  const setValueDescriptionAndJustification = (value: string) => {
    if (!isValidtedStringEditor(value)) {
      setFieldValue('syllabus_entries.description_justify', '');
    } else {
      setFieldValue('syllabus_entries.description_justify', value);
    }
  };
  const setValueObjectStudy = (value: string) => {
    if (!isValidtedStringEditor(value)) {
      setFieldValue('syllabus_entries.object_study', '');
    } else {
      setFieldValue('syllabus_entries.object_study', value);
    }
  };
  const setValueObjec = (value: string) => {
    if (!isValidtedStringEditor(value)) {
      setFieldValue('syllabus_entries.object', '');
    } else {
      setFieldValue('syllabus_entries.object', value);
    }
  };
  const setValueResultLearning = (value: string) => {
    if (!isValidtedStringEditor(value)) {
      setFieldValue('syllabus_entries.result_learnin', '');
    } else {
      setFieldValue('syllabus_entries.result_learning', value);
    }
  };
  const setValueContents = (value: string) => {
    setFieldValue('syllabus_entries.contents', value);
  };
  const setValueMethoMeanEvaluation = (value: string) => {
    setFieldValue('syllabus_entries.metho_mean_evaluation', value);
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      direction="column"
      ref={inputElement}
    >
      <Grid container justifyContent="center" alignItems="center" my={2}>
        <Typography variant="h4" color="primary">
          Paso {activeStep} de {stepsArray.length}
        </Typography>
        <Grid item xs={12}>
          <MobileStepper
            variant="progress"
            steps={6}
            position="static"
            activeStep={activeStep - 1}
            LinearProgressProps={{
              sx: {
                width: '100%',
                height: 10,
                borderRadius: 5,
                backgroundColor: `${theme.palette.grey[300]} !important`,
              },
            }}
            backButton
            nextButton
          />
        </Grid>
        <Grid container>
          <Typography variant="h4" color="inherit" my={2}>
            {stepsArray[activeStep - 1]?.name}
          </Typography>
        </Grid>
      </Grid>
      <Grid container direction="column">
        <div
          hidden={activeStep !== 1}
          style={{
            width: 'inherit',
            maxWidth: '100vw',
          }}
        >
          <EditorTextSylabus
            setData={setValueDescriptionAndJustification}
            setNext={setActiveStep}
            data={values.syllabus_entries.description_justify}
            errorComponent={
              errors.syllabus_entries?.description_justify ? (
                <FormHelperText error>
                  {errors.syllabus_entries?.description_justify}
                </FormHelperText>
              ) : (
                <></>
              )
            }
          />
        </div>
        <div
          hidden={activeStep !== 2}
          style={{
            width: 'inherit',
            maxWidth: '100vw',
          }}
        >
          <EditorTextSylabus
            setData={setValueObjectStudy}
            setNext={setActiveStep}
            isBack
            data={values.syllabus_entries.object_study}
            errorComponent={
              errors.syllabus_entries?.object_study ? (
                <FormHelperText error>{errors.syllabus_entries?.object_study}</FormHelperText>
              ) : (
                <></>
              )
            }
          />
        </div>
        <div
          hidden={activeStep !== 3}
          style={{
            width: 'inherit',
            maxWidth: '100vw',
          }}
        >
          <EditorTextSylabus
            setData={setValueObjec}
            setNext={setActiveStep}
            isBack
            data={values.syllabus_entries.object}
            errorComponent={
              errors.syllabus_entries?.object ? (
                <FormHelperText error>{errors.syllabus_entries?.object}</FormHelperText>
              ) : (
                <></>
              )
            }
          />
        </div>
        <div
          hidden={activeStep !== 4}
          style={{
            width: 'inherit',
            maxWidth: '100vw',
          }}
        >
          <EditorTextSylabus
            setData={setValueResultLearning}
            setNext={setActiveStep}
            isBack
            data={values.syllabus_entries.result_learning}
            errorComponent={
              errors.syllabus_entries?.result_learning ? (
                <FormHelperText error>{errors.syllabus_entries?.result_learning}</FormHelperText>
              ) : (
                <></>
              )
            }
          />
        </div>
        <div
          hidden={activeStep !== 5}
          style={{
            width: 'inherit',
            maxWidth: '100vw',
          }}
        >
          <EditorTextSylabus
            setData={setValueContents}
            setNext={setActiveStep}
            isBack
            data={values.syllabus_entries.contents}
            errorComponent={
              errors.syllabus_entries?.contents ? (
                <FormHelperText error>{errors.syllabus_entries?.contents}</FormHelperText>
              ) : (
                <></>
              )
            }
          />
        </div>
        <div
          hidden={activeStep !== 6}
          style={{
            width: 'inherit',
            maxWidth: '100vw',
          }}
        >
          <EditorTextSylabus
            setData={setValueMethoMeanEvaluation}
            setNext={setActiveStep}
            isBack
            data={values.syllabus_entries.metho_mean_evaluation}
            aditionalFunctionInNext={() => {
              history.push(
                path
                  .replace(':caseKey', 'plan-clases')
                  .replace(':id', id ? id : '')
                  .replace(':courseId', courseId ? courseId : '')
                  .replace(':period', period ? period : '')
              );
            }}
            errorComponent={
              errors.syllabus_entries?.metho_mean_evaluation ? (
                <FormHelperText error>
                  {errors.syllabus_entries?.metho_mean_evaluation}
                </FormHelperText>
              ) : (
                <></>
              )
            }
            finalStep
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default WizardSyllabus;
