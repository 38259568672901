import * as Yup from 'yup';

export const GeneralDetailsSchemaPrincipal = Yup.object().shape({
  name: Yup.string().required('El nombre es requerido'),
  typeProject: Yup.string().required('El tipo de proyecto es requerido'),
  // programName: Yup.string().required('El nombre del programa es requerido'),
  programGoal: Yup.string().required('El objetivo del proyecto es requerido'),
  startDatePrincipal: Yup.date().required('Fecha es requerida'),
  endDatePrincipal: Yup.date().required('Fecha es requerida'),
});

export const GeneralDetailsSchema = Yup.object().shape({
  name: Yup.string().required('El nombre es requerido'),
  typeProject: Yup.string().required('El tipo de proyecto es requerido'),
  // programName: Yup.string().required('El nombre del programa es requerido'),
  programGoal: Yup.string().required('El objetivo del proyecto es requerido'),
});

export const SpecificDetailsSchema = Yup.object().shape({
  cityId: Yup.number().min(1, 'La ciudad es requerida').required('La ciudad es requerida'),
  provinceId: Yup.number()
    .min(1, 'La provincia es requerida')
    .required('La provincia es requerida'),
  educationLevels: Yup.array().required('La unidad académica es requerida'),
  linesGroupResearchSend: Yup.array()
    .min(1, 'El grupo de linea de investigacion es requerido')
    .required('El grupo de linea de investigacion es requerido'),
  typeResearch: Yup.string().required('El tipo de investugacion es requerido'),
  educationLevelsPrincipal: Yup.string().required('La unidad académica principal es requerida'),
  linesGroupResearchPrincipal: Yup.string().required('El grupo principal es requerido'),
  investigationLinesPrincipal: Yup.string().required(
    'La linea de investigacion principal es requerido'
  ),
});

export const ProjectPlanningSchema = Yup.object().shape({
  startDate: Yup.date().required('La fecha es requerida'),
  endDateReal: Yup.date().required('La fecha es requerida'),
  endDatePlanned: Yup.date().required('La fecha es requerida'),
  financialSourceId: Yup.string().required('La fuente de financiamiento es necesario'),
  scope: Yup.string().required('El alcance es requerida'),
});

export const MoreDetailsProjectSchema = Yup.object().shape({
  activityDescription: Yup.string().required('La descripcion requerida'),
  fieldDetailed: Yup.string().nullable(), //.required('Los detalles son requeridos'),
  plannedBudget: Yup.number()
    .typeError('El campo debe ser numérico')
    .required('La planeacion es requerida'),
  executedBudget: Yup.number()
    .typeError('El campo debe ser numérico')
    .required('La ejecucion es requerida'),
  statusProject: Yup.string().required('El estatus del proyecto es requerido'),
  complianceParameterId: Yup.string().required('El cumplimiento de los parametros es requerido'),
  status: Yup.string().required('El estatus es requerido'),
  projectDocuments: Yup.array().required('El documento es necesario'),
});
