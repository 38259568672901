import { Box, Typography, useTheme } from '@material-ui/core';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { CabActivityStudent } from '../../interfaces/tasks.interface';

interface Props {
  startDate: Date;
  endDate: Date;
  numTries: string;
  numTriesRealized: string | number;
  calification: CabActivityStudent | null;
  scoreActivity: string;
}

const TaskItemHeaderDate = ({
  startDate,
  endDate,
  numTries,
  numTriesRealized,
  calification,
  scoreActivity,
}: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <>
      <Typography
        variant="h6"
        sx={{
          fontSize: '0.7rem',
          fontWeight: 'bold',
        }}
      >
        {t('students.virtual_classroom.task.card.start')}:{' '}
        {moment(startDate).format('DD MMM - hh:mm a').replace('.', '')}
      </Typography>
      <Typography
        variant="h6"
        sx={{
          fontSize: '0.7rem',
          fontWeight: 'bold',
        }}
      >
        {t('students.virtual_classroom.task.card.end')}:{' '}
        {moment(endDate).format('DD MMM - hh:mm a').replace('.', '')}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontSize: '0.7rem',
            fontWeight: 'bold',
            mr: 0.5,
          }}
        >
          {t('students.virtual_classroom.task..card.numberOfAttemps')}:
        </Typography>
        <Typography
          variant="h6"
          sx={{
            fontSize: '0.7rem',
            fontWeight: 'bold',
            color: theme.palette.primary.main,
          }}
        >
          {`${numTriesRealized}/${numTries}`}
        </Typography>
      </Box>
      {/* {calification &&
        calification.answer_qualified !== '0' &&
        calification.answer_qualified !== null && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontSize: '0.7rem',
                fontWeight: 'bold',
                mr: 0.5,
              }}
            >
              {t('students.virtual_classroom.task..card.score')}:
            </Typography>
            <Typography
              variant="h6"
              sx={{
                fontSize: '0.7rem',
                fontWeight: 'bold',
                color: theme.palette.primary.main,
              }}
            >
              {`${Number(calification?.act_score)}/${Number(scoreActivity)}`}
            </Typography>
          </Box>
        )} */}
    </>
  );
};

export default TaskItemHeaderDate;
