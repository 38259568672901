import { useState } from 'react';

import { makeStyles } from '@material-ui/styles';
import { Button, CircularProgress, Grid } from '@material-ui/core';

import downloadjs from 'downloadjs';

import { useAppSelector } from '../../../../../hooks/useRedux';
import { useLazyGetReceiptByTransactionIdQuery } from '../../../../shared/slices/collectionTransactions';
// import ElementToPdfButton from '../../../../shared/components/buttons/ElementToPdfButton';

const useStyles = makeStyles((theme) => ({
  voucher: {
    display: 'flex',
    justifyContent: 'center',
  },

  //   [`@media print`]: {
  //     ['html body *']: {
  //       display: 'none',
  //     },
  //     voucher: {
  //       display: 'visible',
  //       position: 'absolute',
  //       left: 0,
  //       top: 0,
  //     },
  //   },

  //   [`@media print`]: {
  //     ['@page']: {
  //       size: 'landscape',
  //     },
  //     body: {
  //       visibility: 'hidden',
  //     },
  //     voucher: {
  //       display: 'visible',
  //       position: 'absolute',
  //       left: 0,
  //       top: 0,
  //     },
  //   },
}));

const VoucherView = () => {
  const classes = useStyles();

  const { voucher } = useAppSelector((state) => state.debtsPayment);

  const [getPdf] = useLazyGetReceiptByTransactionIdQuery();

  const [pdfState, setPdfState] = useState(false);

  const printAsPDF = async () => {
    try {
      setPdfState(true);

      const auxInput = document.getElementById('aux_id_transaction');

      const pdfBlob = await getPdf({
        idTransaction: (auxInput as any).value || 0,
      }).unwrap();

      const file = new Blob([pdfBlob], { type: 'application/pdf' });
      downloadjs(file);
    } catch (error) {}

    setPdfState(false);
  };

  return (
    <Grid container justifyContent={'center'}>
      <Grid item xs={6}>
        {/* <ElementToPdfButton
          downloadedName="voucherPago"
          customId="voucher-print"
          // dataToConvert={HTMLDATA}
        /> */}
        <Button
          variant="outlined"
          onClick={printAsPDF}
          disabled={pdfState}
          endIcon={pdfState && <CircularProgress size={20} />}
        >
          PDF
        </Button>
      </Grid>

      <Grid item xs={7}>
        <div
          id="voucher-print"
          className={classes.voucher}
          dangerouslySetInnerHTML={{ __html: voucher as string }}
        ></div>
        {/* <iframe title="voucher" id="voucher-print"></iframe> */}
      </Grid>

      {/* {voucher && <button onClick={print}>print</button>} */}
    </Grid>
  );
};

export default VoucherView;
