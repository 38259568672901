import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import BackButton from '../../../../../shared/components/buttons/BackButton';
import MainCard from '../../../../../shared/components/cards/MainCard';
import OptionsTemplateForm from '../components/OptionsTemplateForm';

const AddOptionsTemplateScreen = () => {
  const { t } = useTranslation();

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid container alignItems="center" sx={{ mb: 3 }}>
        <BackButton />
        <Typography variant="h2">{t('maintenance.options_template.add.title')}</Typography>
      </Grid>
      <OptionsTemplateForm />
    </Grid>
  );
};

export default AddOptionsTemplateScreen;
