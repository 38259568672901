import { useMemo, useState } from 'react';

import { Autocomplete, FormHelperText, Grid, InputLabel, TextField } from '@material-ui/core';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

import { useUpdateStudentEmergencyContactMutation } from '../../../slices/studentsApiSlice';
import LoadingButton from '../../../../../shared/components/buttons/LoadingButton';
import { useGetCatalogByAcronymQuery } from '../../../../../shared/slices/catalogApi';
import ErrorAlert from '../../../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../../../shared/components/alerts/SuccessAlert';
import { personfilters } from '../../../../../shared/constants/globals';
import TextFieldFetching from '../../../../../shared/components/TextFieldFetching';
import CustomAsyncPaginatedSelect from '../../../../../shared/components/extended/Form/CustomAsyncPaginatedSelect';
// import AddButton from '../../../../../shared/components/tables/buttons/AddButton';
import { CustomChilds } from '../../../../../shared/interfaces/material-ui.interfaces';
import { SearchPersonParam } from '../../../../../shared/interfaces/searchPerson.interface';
import { useLazyGetPersonsByParamQuery } from '../../../../../shared/slices/searchPersonApi';
import { updateItemFromKeyArr } from '../../../../../shared/helpers';
import { EmergencyContactPersonData } from '../../../constants/validationSchemas';
import {
  EmergencyContact,
  EmergencyContactNews,
} from '../../../interfaces/studentRecordAcademic.interfaces';

export interface Props {
  studentId: string;
  data: {
    newContact?: EmergencyContactNews;
    existingContact?: EmergencyContact;
  } | null;
  cases?: 'newContact' | 'existentingContact';
  handleCloseDialog: (e: React.SyntheticEvent) => void;
  setNewContacts?: React.Dispatch<React.SetStateAction<EmergencyContactNews[]>>;
}

const EditEmergencyContactForm = ({
  studentId,
  data,
  cases,
  handleCloseDialog,
  setNewContacts,
}: Props) => {
  const { t } = useTranslation();

  const [showAlerts, setShowAlerts] = useState(false);
  const [isSuccesLocalUpd, setIsSuccesLocalUpd] = useState(false);

  const [filter, setFilter] = useState('ci');
  const [personSelect, setPersonSelect] = useState<CustomChilds<
    SearchPersonParam,
    { id: number; label: string }
  > | null>(null);

  const [updateContact, { isError, isSuccess, isLoading, error: errorUpdate }] =
    useUpdateStudentEmergencyContactMutation();

  const [getPersonsTrigger] = useLazyGetPersonsByParamQuery();

  // parentesco
  const { data: kinship, isLoading: isLoadingParentesco } = useGetCatalogByAcronymQuery({
    acronym: 'P',
  });

  //TODO: crear hook en el componente del select
  const getPersons = async (search: string, page: number, perPage: number) => {
    if (!filter || !search)
      return {
        options: [],
        hasMore: false,
      };

    const response = await getPersonsTrigger({
      page,
      perPage,
      [filter]: search,
      // statusId: 1,
    }).unwrap();

    const hasMore = response.current_page < response.last_page;

    return {
      options: response.data.map((dt) => {
        return {
          value: dt.id,
          label: `${dt.pers_full_name} - ${dt.pers_identification}`,
          ...dt,
        };
      }),
      hasMore,
    };
  };

  const initialValues = useMemo(() => {
    switch (cases) {
      case 'newContact':
        return {
          id: data?.newContact?.id || 0,
          em_ct_name: data?.newContact?.em_ct_name || '',
          em_ct_first_phone: data?.newContact?.em_ct_first_phone || '',
          em_ct_second_phone: data?.newContact?.em_ct_second_phone || '',
          person_id: data?.newContact?.person_id || '',
          status_id: data?.newContact?.status_id || '1',
          type_kinship_id: data?.newContact?.type_kinship_id || '',
          type_kinship_name: data?.newContact?.cat_name || '',
          person_name_relative: data?.newContact?.person_name_relative || '',
        };

      case 'existentingContact':
        return {
          id: data?.existingContact?.id || 0,
          em_ct_name: data?.existingContact?.em_ct_name || '',
          em_ct_first_phone: data?.existingContact?.em_ct_first_phone || '',
          em_ct_second_phone: data?.existingContact?.em_ct_second_phone || '',
          person_id: data?.existingContact?.person_id || '',
          status_id: data?.existingContact?.status_id || '1',
          type_kinship_id: data?.existingContact?.type_kinship_id || '',
          type_kinship_name: data?.existingContact?.cat_name || '',
          person_name_relative: data?.existingContact?.em_ct_name || '',
        };

      default:
        return {
          id: 0,
          em_ct_name: '',
          em_ct_first_phone: '',
          em_ct_second_phone: '',
          person_id: '',
          status_id: '1',
          type_kinship_id: '',
          type_kinship_name: '',
          person_name_relative: '',
        };
    }
  }, [cases, data?.existingContact, data?.newContact]);

  const { errors, touched, values, getFieldProps, handleSubmit, setFieldValue } = useFormik({
    initialValues: { ...initialValues },
    validationSchema: EmergencyContactPersonData,
    onSubmit: async (values) => {
      try {
        // const { faculty, ...value } = values;

        // setNewContacts((prev) => [
        //   { ...values, id: `t${new Date().getTime()}${values.id}` },
        //   ...prev,
        // ]);

        console.log(values);

        switch (cases) {
          case 'newContact':
            setNewContacts!((prev) => {
              const updated = updateItemFromKeyArr(prev, 'id', values.id!, values);
              return [...updated];
            });
            break;

          case 'existentingContact':
            updateContact({
              studentId,
              emergencyContactId: values.id as number,
              payload: {
                em_ct_name: values.em_ct_name,
                em_ct_first_phone: values.em_ct_first_phone,
                em_ct_second_phone: values.em_ct_second_phone,
                person_id: values.person_id,
                status_id: values.status_id,
                type_kinship_id: values.type_kinship_id,
              },
            });
            break;

          default:
            break;
        }

        setIsSuccesLocalUpd(true);
      } catch (error) {
        setIsSuccesLocalUpd(false);
      } finally {
        setShowAlerts(true);
      }
    },
  });

  const changePersonHandler = (
    newValue: CustomChilds<SearchPersonParam, { id: number; label: string }> | null
  ) => {
    setPersonSelect(newValue);

    setFieldValue('person_id_relative', newValue?.id || '');
    setFieldValue('pers_full_name_relative', newValue?.pers_full_name || '');
    setFieldValue('pers_identification_relative', newValue?.pers_identification || '');
  };

  return (
    <form onSubmit={handleSubmit}>
      {isError && showAlerts && (
        <ErrorAlert message={errorUpdate as string} handleDismiss={() => setShowAlerts(false)} />
      )}

      {(isSuccess || isSuccesLocalUpd) && showAlerts && (
        <SuccessAlert
          message={t('Contactos de emergencia actualizado exitosamente')}
          handleDismiss={() => setShowAlerts(false)}
        />
      )}

      <Grid container spacing={2}>
        {false && (
          <>
            <Grid item xs={12}>
              <InputLabel>{t('Tipo de búsqueda')}</InputLabel>
              <Autocomplete
                options={personfilters}
                getOptionLabel={(option) => option.value}
                renderInput={(params) => (
                  <TextField {...params} placeholder={t('Seleccione el tipo de filtro')} />
                )}
                value={personfilters.find((item) => item.name === filter) || null}
                onChange={(_, value) => {
                  setFilter((prev) => value?.name || '');
                  setPersonSelect(null);
                }}
                fullWidth
                clearIcon={null}
              />
            </Grid>

            <Grid item xs={12}>
              <InputLabel>{t('Persona')}</InputLabel>
              <CustomAsyncPaginatedSelect
                key={`async-select-${filter}`}
                value={personSelect}
                isError={false}
                placeholder={`Buscar persona por ${
                  personfilters.find((dt) => dt.name === filter)?.value
                }`}
                // noOptionsMessage={''}
                setValue={(newValue) =>
                  changePersonHandler(
                    newValue as CustomChilds<
                      SearchPersonParam,
                      { id: number; label: string }
                    > | null
                  )
                }
                fetchFunc={getPersons}
              />
              {/* {errors.person_id && touched.person_id && (
              <FormHelperText error>{t(errors.person_id)}</FormHelperText>
            )} */}
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <InputLabel>{t('Parentesco')}</InputLabel>
          {isLoadingParentesco ? (
            <TextFieldFetching />
          ) : (
            <Autocomplete
              options={kinship || []}
              loadingText="Cargando parentescos..."
              getOptionLabel={(option) => (option ? option.cat_name! : '')}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(errors.type_kinship_id && touched.type_kinship_id)}
                  placeholder={`${values.type_kinship_name || 'Cargando parentescos'}`}
                />
              )}
              value={kinship?.find((dt) => dt.id === Number(values.type_kinship_id)) || null}
              onChange={(_, value) => {
                setFieldValue('type_kinship_id', value?.id || '');
              }}
              fullWidth
              clearIcon={null}
            />
          )}
          {errors.type_kinship_id && touched.type_kinship_id && (
            <FormHelperText error>{errors.type_kinship_id}</FormHelperText>
          )}
        </Grid>

        {false && (
          <Grid item xs={12}>
            <InputLabel>Nombres</InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Nombres del familiar"
              disabled
              value={personSelect?.pers_full_name || ''}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <InputLabel>{t('Nombre')}</InputLabel>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Nombre del contacto"
            error={Boolean(errors.em_ct_name && touched.em_ct_name)}
            {...getFieldProps('em_ct_name')}
          />
          {errors.em_ct_name && touched.em_ct_name && (
            <FormHelperText error>{errors.em_ct_name}</FormHelperText>
          )}
        </Grid>

        <Grid item xs={12}>
          <InputLabel>{t('Teléfono principal')}</InputLabel>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Agregue teléfono principal"
            error={Boolean(errors.em_ct_first_phone && touched.em_ct_first_phone)}
            {...getFieldProps('em_ct_first_phone')}
          />
          {errors.em_ct_first_phone && touched.em_ct_first_phone && (
            <FormHelperText error>{errors.em_ct_first_phone}</FormHelperText>
          )}
        </Grid>
        <Grid item xs={12}>
          <InputLabel>{t('Teléfono secundario')}</InputLabel>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Agregue teléfono secundario"
            error={Boolean(errors.em_ct_second_phone && touched.em_ct_second_phone)}
            {...getFieldProps('em_ct_second_phone')}
          />
          {errors.em_ct_second_phone && touched.em_ct_second_phone && (
            <FormHelperText error>{errors.em_ct_second_phone}</FormHelperText>
          )}
        </Grid>

        <Grid item xs={12}>
          <Grid container alignItems="center" justifyContent="flex-end" spacing={2} mt={1}>
            <LoadingButton
              type="submit"
              isLoading={isLoading}
              variant="contained"
              size="large"
              color="primary"
            >
              Guardar
            </LoadingButton>
          </Grid>
        </Grid>

        {/* / */}
      </Grid>
    </form>
  );
};

export default EditEmergencyContactForm;
