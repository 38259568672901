import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// types
import { Property } from 'csstype';
import { PaletteMode } from '@material-ui/core';
import { CustomizationState, Languages } from '../../../types';

// helpers
import { getThemeFromStorage } from '../../../helpers/storageHelpers';
import { DefaultConfiguration } from '../interfaces/tenants.interfaces';
import { initialDefaultConfiguration } from '../constants/defaultConfiguration';

const localConfig = localStorage.getItem('defaultConfiguration');

const defaultConfiguration = localConfig
  ? (JSON.parse(localConfig) as DefaultConfiguration)
  : initialDefaultConfiguration;

const initialState: CustomizationState = {
  isOpen: undefined, //for active default menu
  fontFamily: `'Poppins', sans-serif`,
  borderRadius: 12,
  outlinedFilled: true,
  themeMode: getThemeFromStorage(),
  presetColor: 'web',
  locale: 'es',
  opened: true,
  breadcrumb: true,
  language: defaultConfiguration.language as Languages,
  loadedFile: 0,
};

const customizationSlice = createSlice({
  name: 'customization',
  initialState,
  reducers: {
    setMenuOpen: (state, action: PayloadAction<string>) => {
      state.isOpen = action.payload;
    },
    setThemeMode: (state, action: PayloadAction<PaletteMode>) => {
      state.themeMode = action.payload;
    },
    setPresetColor: (state, action: PayloadAction<string>) => {
      state.presetColor = action.payload;
    },
    setLocale: (state, action: PayloadAction<string>) => {
      state.locale = action.payload;
    },
    setSidebarOpened: (state, action: PayloadAction<boolean>) => {
      state.opened = action.payload;
    },
    setFontFamily: (state, action: PayloadAction<Property.FontFamily>) => {
      state.fontFamily = action.payload;
    },
    setBorderRadius: (state, action: PayloadAction<number>) => {
      state.borderRadius = action.payload;
    },
    setOutlinedFilled: (state, action: PayloadAction<boolean>) => {
      state.outlinedFilled = action.payload;
    },
    setBreadcrumbState: (state, action: PayloadAction<boolean>) => {
      state.breadcrumb = action.payload;
    },
    resetCustomization: (state) => {},
    setLanguage: (state, action: PayloadAction<Languages>) => {
      state.language = action.payload;
    },
    setLoadedFile: (state, action: PayloadAction<number>) => {
      state.loadedFile = action.payload;
    },
  },
});

export const {
  setMenuOpen,
  setThemeMode,
  setPresetColor,
  setLocale,
  setSidebarOpened,
  setFontFamily,
  setBorderRadius,
  setOutlinedFilled,
  resetCustomization,
  setBreadcrumbState,
  setLanguage,
  setLoadedFile,
} = customizationSlice.actions;

export default customizationSlice.reducer;
