import { Button, Dialog, Grid, Typography } from '@material-ui/core';
import React from 'react';
// import useGetBaseUrl from '../../../../../hooks/useGetBaseUrl';
import useGetUrlDownload from '../../../../../hooks/useGetUrlDownload';
const ViewFileModal: React.FC<{ url: string; Open: boolean; onClose: () => void }> = ({
  url,
  Open,
  onClose,
}) => {
  //'http://www.pdf995.com/samples/pdf.pdf

  // const baseUrl = useGetBaseUrl();
  // const docs = [
  //   {
  //     uri: `${baseUrl}/${url}`,
  //   },
  // ];

  const { urlDownload } = useGetUrlDownload();

  console.log(url);
  return (
    <Dialog open={Open} onClose={onClose}>
      <Grid container spacing={2} justifyContent="center">
        {/* <DocViewer
          pluginRenderers={DocViewerRenderers}
          documents={docs}
          config={{
            header: {
              disableHeader: false,
              disableFileName: false,
              retainURLParams: false,
            },
          }}
          style={{ height: 500 }}
        />
        ;{' '} */}
        <Grid item xs={12}>
          <Typography variant="h3" color="primary" align="center">
            Vista previa
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <iframe
            src={`${urlDownload}/${url}`}
            style={{ width: 600, height: 500, border: 'none' }}
            title="preview"
          />
        </Grid>
        <Grid item xs={3}>
          <Button variant="outlined" color="primary" onClick={onClose} fullWidth>
            Cerrar
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default ViewFileModal;
