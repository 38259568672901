import { useState } from 'react';

import {
  Alert,
  FormHelperText,
  Grid,
  InputLabel,
  Radio,
  Skeleton,
  TextField,
  TextFieldProps,
  Typography,
  useTheme,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Field, FieldArray, FormikProvider, useFormikContext } from 'formik';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import { DateRange, DateRangePicker, LocalizationProvider } from '@material-ui/lab';

import LoadingChildren from '../../../../shared/components/LoadingChildren';
import { AssignQualiCoursesFormValues } from '../AssignQualiCoursesForm';
import SimpleTable from '../../../periods/components/SimpleTable';
import { useGetLocaleDateLang } from '../../../../../translation/hooks/useGetLocaleDateLang';
import { Supply, Distribution } from '../../../periods/components/AssignStagesForm';
import { useGetCoursesCollaboratorQuery } from '../../../courses/slices/courseApiSlice';
import LoadingButton from '../../../../shared/components/buttons/LoadingButton';

interface Props {
  isLoadingPost: boolean;
  singleCourseSel: number;
}

const DateStageSelection = ({ isLoadingPost, singleCourseSel }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { lang } = useGetLocaleDateLang();

  // solo visual
  const [editedDates, setEditedDates] = useState<number[]>([]);

  const formik = useFormikContext<AssignQualiCoursesFormValues>();

  const { values, errors, setFieldValue, getFieldProps, submitForm } = formik;

  const { isLoading } = useGetCoursesCollaboratorQuery(
    {
      periodId: values.period_id,
      collId: values.collaborator_id,
    },
    { skip: !values.period_id || !values.collaborator_id }
  );

  const showRadioBtn = values.courses.length === 1 && singleCourseSel;

  return (
    <Grid container>
      <LoadingChildren
        isLoading={isLoading}
        loadingSkeleton={<Skeleton sx={{ width: '100%', height: '20vh' }} />}
        isEmpty={!values.calificationModelId}
        // errorFallback={<>componente de no hay nada compa</>}
      >
        <>
          <Grid item xs={12}>
            {values.courses.length === 1 && (
              <Alert severity="info">
                <Typography component={'p'}>Se asignará las fechas ingresadas al curso:</Typography>
                <b>{values.courses[0].course_description}</b>
              </Alert>
            )}

            {values.courses.length > 1 && (
              <Alert severity="warning">
                <Typography component={'p'}>
                  Se asignará las fechas ingresadas a todos los cursos seleccionados.
                </Typography>
                <b>total cursos: {values.courses.length}</b>
              </Alert>
            )}
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={2} alignItems="center" justifyContent="space-between">
              <Grid item>
                <Typography variant="h5" sx={{ my: 3 }}>
                  {t('academic.periods_list.stages.add.stages_dates')}
                </Typography>
              </Grid>

              <Grid item>
                <LoadingButton
                  // type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => {
                    submitForm();
                  }}
                  isLoading={isLoadingPost}
                >
                  {t('shared.save_button.label')}
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>

          {values.modelInfo?.distribution?.map((dist, distrIndex) => (
            <>
              <Grid
                item
                xs={12}
                key={`stage-container-edit-perms-${distrIndex}`}
                sx={{
                  border: `1px solid ${theme.palette.primary.main}`,
                  borderRadius: '10px',
                  mb: 2,
                }}
                p={2}
              >
                <Typography variant="h4">{dist?.distribution}</Typography>
                <SimpleTable
                  title=""
                  data={
                    dist?.ref2?.map((ref2, index: number) => ({
                      data: {
                        supplyName: ref2?.ref2,
                        id: ref2?.id,
                        index: distrIndex + 1,
                        ref2_supply_id: ref2?.ref2_supply_id,
                      },
                      dates: '',
                      ...(showRadioBtn && { edit: '' }),
                    })) || []
                  }
                  headers={{
                    // data: <>#</>,
                    periodStageName: t('academic.periods_list.stages.add.supply'),
                    dates: t('academic.periods_list.stages.add.date'),
                    ...(showRadioBtn && { edit: 'Editar' }),
                  }}
                  keyExtractor={(e) => e.data.index.toString()}
                  customRenderers={{
                    // data: ({ data }) => <>{data.index} </>,

                    //custom renderer conditional

                    data: ({ data }) => <Typography variant="h6">{data.supplyName}</Typography>,

                    ...(showRadioBtn && {
                      edit: () => (
                        <Radio
                          checked={editedDates.includes(distrIndex)}
                          onClick={() => {
                            if (editedDates.includes(distrIndex))
                              return setEditedDates((prev) => prev.filter((e) => e !== distrIndex));

                            return setEditedDates((prev) => [...prev, distrIndex]);
                          }}
                          value={distrIndex}
                          name="radio-buttons"
                          inputProps={{ 'aria-label': 'A' }}
                        />
                      ),
                    }),

                    dates: (date) => (
                      <FormikProvider value={formik}>
                        <FieldArray
                          name="periodStages"
                          render={(arrayHelpers) => (
                            <LocalizationProvider dateAdapter={AdapterDateFns} locale={lang}>
                              <Field
                                disabled={showRadioBtn && !editedDates.includes(distrIndex)}
                                name={`periodStages[${date.data.id}]`}
                                component={DateRangePicker}
                                startText={null}
                                endText={null}
                                value={[
                                  values?.calificationModelDates?.distributions
                                    ?.find((e) => e?.ref1_supply_id === dist?.ref1_supply_id)
                                    ?.supplies?.find(
                                      (e) => e?.ref2_supply_id === date?.data?.ref2_supply_id
                                    )?.startDate || null,
                                  values?.calificationModelDates?.distributions
                                    ?.find((e) => e?.ref1_supply_id === dist?.ref1_supply_id)
                                    ?.supplies?.find(
                                      (e) => e?.ref2_supply_id === date?.data?.ref2_supply_id
                                    )?.endDate || null,
                                ]}
                                onChange={(e: DateRange<Date>) => {
                                  setFieldValue('calificationModelDates', {
                                    ...values.calificationModelDates,
                                    distributions:
                                      values?.calificationModelDates?.distributions?.find(
                                        (_distribution) =>
                                          _distribution?.ref1_supply_id === dist?.ref1_supply_id
                                      )
                                        ? values.calificationModelDates.distributions.map(
                                            (_distribution) => {
                                              if (
                                                _distribution?.ref1_supply_id ===
                                                dist?.ref1_supply_id
                                              ) {
                                                return {
                                                  ..._distribution,
                                                  supplies: _distribution.supplies.find?.(
                                                    (supply) =>
                                                      supply?.ref2_supply_id ===
                                                      date?.data?.ref2_supply_id
                                                  )
                                                    ? _distribution.supplies.map((supply) => {
                                                        if (
                                                          supply?.ref2_supply_id ===
                                                          date?.data?.ref2_supply_id
                                                        ) {
                                                          return {
                                                            ...supply,
                                                            startDate: e[0],
                                                            endDate: e[1],
                                                          };
                                                        }
                                                        return supply;
                                                      })
                                                    : _distribution.supplies.concat({
                                                        ref2_supply_id: date?.data?.ref2_supply_id,
                                                        startDate: e[0],
                                                        endDate: e[1],
                                                      }),
                                                };
                                              }
                                              return _distribution;
                                            }
                                          )
                                        : values.calificationModelDates.distributions.concat({
                                            ref1_supply_id: dist?.ref1_supply_id,
                                            supplies: [
                                              {
                                                ref2_supply_id: date?.data?.ref2_supply_id,
                                                startDate: e[0],
                                                endDate: e[1],
                                              },
                                            ],
                                          }),
                                  });
                                }}
                                renderInput={(
                                  startProps: TextFieldProps,
                                  endProps: TextFieldProps
                                ) => (
                                  <Grid
                                    container
                                    spacing={2}
                                    justifyContent="center"
                                    alignItems="center"
                                  >
                                    <Grid item xs={12} md={6} lg={4}>
                                      <InputLabel>
                                        {t('academic.periods_list.stages.add.stages_dates.start')}:
                                      </InputLabel>
                                      <TextField
                                        fullWidth
                                        {...startProps}
                                        helperText=""
                                        {...getFieldProps(
                                          `periodStages[${date.data.id}].startDate`
                                        )}
                                      />
                                      <FormHelperText>
                                        {t(
                                          'academic.periods_list.stages.add.stages_dates.start.helper'
                                        )}
                                      </FormHelperText>
                                      <FormHelperText error>
                                        {t(
                                          (
                                            (
                                              errors?.calificationModelDates?.distributions?.[
                                                distrIndex
                                              ] as unknown as Distribution
                                            )?.supplies?.[0] as unknown as Supply
                                          )?.startDate as unknown as string
                                        )}
                                      </FormHelperText>
                                      {/* <ErrorMessage
                                    name={`periodStages[${date.ids}].startDate`}
                                  /> */}
                                    </Grid>

                                    <Grid item xs={12} md={6} lg={4}>
                                      <InputLabel>
                                        {t('academic.periods_list.stages.add.stages_dates.end')}:
                                      </InputLabel>
                                      <TextField
                                        fullWidth
                                        {...endProps}
                                        helperText=""
                                        {...getFieldProps(`periodStages[${date.data.id}].endDate`)}
                                      />
                                      <FormHelperText>
                                        {t(
                                          'academic.periods_list.stages.add.stages_dates.end.helper'
                                        )}
                                      </FormHelperText>
                                      <FormHelperText error>
                                        {t(
                                          (
                                            (
                                              errors?.calificationModelDates?.distributions?.[
                                                distrIndex
                                              ] as unknown as Distribution
                                            )?.supplies?.[0] as unknown as Supply
                                          )?.endDate as unknown as string
                                        )}
                                      </FormHelperText>
                                      {/* <ErrorMessage
                                    name={`periodStages[${date.ids}].endDate`}
                                  /> */}
                                    </Grid>
                                  </Grid>
                                )}
                              />
                            </LocalizationProvider>
                          )}
                        />
                      </FormikProvider>
                    ),
                  }}
                />
              </Grid>
            </>
          ))}
        </>
      </LoadingChildren>
    </Grid>
  );
};

export default DateStageSelection;
