import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISearchTable } from '../../../shared/interfaces/paginatedTable.interface';
import { EventNew } from '../interfaces/event-new.interfaces';

interface EventNewState {
  eventIdToDelete?: number;
  eventNewList: EventNew[];
  deleteModalOpen: boolean;
  eventNewIdToAddImage?: number;
  showContainerImage?: boolean;
  eventNewToDelete?: number;
  eventNewTable: ISearchTable;
}

const initialState: EventNewState = {
  eventNewList: [],
  deleteModalOpen: false,
  eventNewTable: {
    page: 1,
    perPage: 5,
    search: '',
  },
};

const eventNewSlice = createSlice({
  name: 'eventNew',
  initialState,
  reducers: {
    setEventNewList: (state, action: PayloadAction<EventNew[]>) => {
      state.eventNewList = action.payload;
    },
    setModalOpenEventNew: (state, action: PayloadAction<boolean>) => {
      state.deleteModalOpen = action.payload;
    },
    openDeleteModalWith: (state, action: PayloadAction<number>) => {
      state.eventIdToDelete = action.payload;
      state.deleteModalOpen = true;
    },
    resetEventNewToId: (state) => {
      state.eventIdToDelete = undefined;
    },
    openDeleteModalEventNew: (state, action) => {
      state.eventIdToDelete = action.payload;
      state.deleteModalOpen = true;
    },
    resetEventNewToDelete: (state) => {
      state.eventIdToDelete = undefined;
    },
    setShowContainerImage: (state, action: PayloadAction<boolean>) => {
      state.showContainerImage = action.payload;
    },
    setEventNewsToAddImage: (state, action: PayloadAction<number>) => {
      state.eventNewIdToAddImage = action.payload;
    },
    resetEventNewsToAddImage: (state) => {
      state.eventNewIdToAddImage = undefined;
    },
    updateEventNewTable: (state, action: PayloadAction<ISearchTable>) => {
      state.eventNewTable = action.payload;
    },
  },
});

export const {
  setEventNewList,
  openDeleteModalEventNew,
  openDeleteModalWith,
  setShowContainerImage,
  setEventNewsToAddImage,
  resetEventNewsToAddImage,
  resetEventNewToDelete,
  resetEventNewToId,
  setModalOpenEventNew,
  updateEventNewTable,
} = eventNewSlice.actions;

export default eventNewSlice.reducer;
