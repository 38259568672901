import React, { useEffect, useState } from 'react';

import { Alert, Snackbar } from '@material-ui/core';

import { useIdleTimer } from 'react-idle-timer';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../hooks/useRedux';
import { useLogoutMutation } from '../modules/auth/slices/authApiSlice';
import DeleteAlert from '../modules/shared/components/dialogs/DeleteAlert';
import { TransitionToRight } from '../modules/shared/helpers/Transitions';
import { useControlModalStates } from '../modules/shared/hooks/useControlModalStates';
import { logoutSync } from '../modules/auth/slices/authSlice';
import { resetProfile, setCurrentVirtualClassroom } from '../modules/shared/reducers/accessReducer';
import Chronometer from '../modules/shared/components/Chronometer';

interface Props {
  children: React.ReactElement | React.ReactElement[];
}

const promptTime = 10000;

const CustomIdleTimer = ({ children }: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { open, handleCloseModal, handleOpenModalWith } = useControlModalStates();

  const { open: openLogout, handleOpenModalWith: handleOpenModalWithLogout } =
    useControlModalStates();

  const [isIdle, setIsIdle] = useState(false);

  const { defaultConfiguration } = useAppSelector((state) => state.access);

  const timeoutTime = (Number(defaultConfiguration.time_inactive_client) || 60) * 1000 * 60;

  // console.log(timeoutTime);

  const [logout] = useLogoutMutation();

  const onPrompt = () => {
    // console.log('onPrompt');
    setIsIdle(true);
    handleOpenModalWith();
  };

  const onIdle = () => {
    // console.log('onIdle');
    if (isIdle) {
      logout({ noSync: true });
      handleOpenModalWithLogout();
    }
  };

  const onActive = (event: any) => {
    // console.log('onActive');
    if (isIdle) {
      setIsIdle(false);
      handleCloseModal();
    }
  };

  const onAction = (event: any) => {
    // console.log('onAction');
    if (isIdle) {
      setIsIdle(false);
      handleCloseModal();
    }
  };

  const syncLogout = () => {
    dispatch(logoutSync());
    dispatch(resetProfile());
    dispatch(setCurrentVirtualClassroom(false));

    localStorage.removeItem('x-token');
    localStorage.removeItem('currentRol');
    localStorage.removeItem('currentProfile');
    localStorage.removeItem('currentStudentRecord');
    localStorage.removeItem('currentModule');
    localStorage.removeItem('currentCourseStudent');
  };

  const { reset } = useIdleTimer({
    onPrompt,
    onIdle,
    onActive,
    onAction,
    timeout: timeoutTime, //defaultConfiguration.time_inactive_client * 1000,
    promptTimeout: promptTime,
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      'visibilitychange',
    ],
    immediateEvents: [],
    debounce: 0,
    throttle: 0,
    eventsThrottle: 200,
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: false,
    // si se quiere activar la sicronizacion en tre pestanas
    crossTab: false,
    name: 'idle-timer',
    syncTimers: 0,
    leaderElection: false,
  });

  useEffect(() => {
    if (!isIdle) reset();
  }, [isIdle, reset]);

  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={promptTime}
        onClose={handleCloseModal}
        // action={action}
        TransitionComponent={TransitionToRight}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert severity="warning">
          {t('alerts.inactivity.toast.logout')} <Chronometer initialValue={promptTime} />s
        </Alert>
      </Snackbar>

      <DeleteAlert
        open={openLogout}
        onClose={syncLogout}
        handleDelete={syncLogout}
        isLoading={false}
        isSuccess={true}
        isError={true}
        error={t('alerts.inactivity.alert.logout')}
        noSubtitle
        deleteSuccess=""
        // deleteTitle="Su sesion ha caducado"
        deleteSubTitle=""
        deleteBtnText={t('shared.accept_button.label')}
        isAutoClose
        onCompleteAutoCloseFn={syncLogout}
        customIntervalTime={5}
      />

      {children}
    </>
  );
};

export default CustomIdleTimer;
