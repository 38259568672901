import { CircularProgress, IconButton, Tooltip } from '@material-ui/core';
import { IconFileSearch } from '@tabler/icons';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetXMLQuery } from '../slices/electronicDocumentsApiSlice';
interface DownloadXMLProps {
  docId: number;
  docName: string;
}
export const DownloadXMLDocument: FC<DownloadXMLProps> = (props) => {
  const { docId, docName } = props;
  const [downloadPdf, setDownloadPdf] = useState<boolean>(false);

  const { t } = useTranslation();

  const {
    data: pdfData,
    isLoading: isLoadingPDFA,
    isFetching: isFetchingPDFA,
    refetch,
    // request,
  } = useGetXMLQuery({ sriCabecera: docId }, { skip: !downloadPdf });

  useEffect(() => {
    if (!isLoadingPDFA && pdfData) {
      // const content = request.headers['content-type'];
      // downloader(pdfData, 'prueba.pdf', 'application/pdf');
      const file = new Blob([pdfData], { type: 'text/xml' });
      // Others
      let a = document.createElement('a');
      const fileURL = URL.createObjectURL(file);
      a.href = fileURL;
      a.download = `${docName}.xml`;
      document.body.appendChild(a);
      a.click();
      setTimeout(function () {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(fileURL);
      }, 0);
      //window.open(fileURL);
      // setDownloadPdf(false);
    }
  }, [isLoadingPDFA, pdfData, refetch, docName]);

  const handleDownload = () => {
    if (!Boolean(pdfData)) {
      setDownloadPdf(true);
      return;
    }
    // refetch();
    const file = new Blob([pdfData], { type: 'octet-stream' });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  };

  return (
    <>
      <Tooltip title={t('financial.electronic_docs.xml_doc')}>
        <IconButton
          aria-label={t('financial.electronic_docs.xml_doc')}
          color="default"
          onClick={handleDownload}
          disabled={isLoadingPDFA || isFetchingPDFA}
        >
          {!isLoadingPDFA && !isFetchingPDFA && <IconFileSearch size={20} />}

          {(isLoadingPDFA || isFetchingPDFA) && <CircularProgress size={20} />}
        </IconButton>
      </Tooltip>
    </>
  );
};
