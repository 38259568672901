import { Grid, Typography } from '@material-ui/core';
import MainCard from '../../../shared/components/cards/MainCard';

import ClientsTable from '../components/ClientsTable';

const ManageLeadsScreen = () => {
    return (
        <Grid component={MainCard} sx={{ padding: 2 }}>
            <Grid sx={{ mb: 3 }}>
                <Typography variant="h2">Gestión</Typography>
                <Typography variant="h2" color="gray" fontWeight={400}>Clientes</Typography>
                <br />
                <ClientsTable />

            </Grid>

        </Grid>
    )
}

export default ManageLeadsScreen;