import {
  Button,
  Grid,
  InputLabel,
  TextField,
  Autocomplete,
  FormHelperText,
  Typography,
} from '@material-ui/core';
import { ArrowBack, ArrowForward } from '@material-ui/icons';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch, useParams } from 'react-router-dom';
import { RegisterSyllabusData, SyllabusBibliogra } from '../../../interfaces/registerSyllabusData';
import DeleteButton from '../../tables/buttons/DeleteButton';
import TextFieldFetching from '../../TextFieldFetching';
import { useGetAllBooksQuery } from '../../../../library/dashboard/slices/libraryDashboardApiSlice';

interface Props {
  setNext: React.Dispatch<React.SetStateAction<number>>;
  isBack?: boolean;
  aditionalFunctionInNext?: () => void;
  finalStep?: boolean;
}

const BasicBibliography = ({ setNext, finalStep, isBack, aditionalFunctionInNext }: Props) => {
  const { t } = useTranslation();
  const { id, courseId, period } = useParams<{ id?: string; courseId?: string; period?: string }>();
  const history = useHistory();
  const { path } = useRouteMatch();

  const { data: basicBibliography = [], isLoading: isLoadingBooks } = useGetAllBooksQuery({});

  const { values, setFieldValue, getFieldProps, errors, touched } =
    useFormikContext<RegisterSyllabusData>();

  const handleBack = () => {
    setNext((step) => {
      if (step === 1) {
        history.push(
          path
            .replace(':caseKey', 'plan-clases')
            .replace(':id', id ? id : '')
            .replace(':courseId', courseId ? courseId : '')
            .replace(':period', period ? period : '')
        );
        return step;
      }
      return step - 1;
    });
  };

  const handleNext = () => {
    setNext((step) => {
      if (finalStep) {
        return step;
      }
      return step + 1;
    });
    aditionalFunctionInNext && aditionalFunctionInNext();
  };

  const handleDeleteOption = (index: number) => {
    setFieldValue(
      'syllabus_bibliogra_basic',
      values.syllabus_bibliogra_basic.filter((_, i) => i !== index)
    );
  };

  return (
    <Grid container direction="column">
      <Grid item container xs={12} md={6} spacing={2}>
        <Grid item xs={12}>
          <InputLabel>{t('Título')}</InputLabel>
          {isLoadingBooks ? (
            <TextFieldFetching />
          ) : (
            <Autocomplete
              options={basicBibliography}
              getOptionLabel={(option) => option.title}
              loadingText={t('Cargando datos...')}
              noOptionsText={t('No hay datos')}
              renderInput={(params) => (
                <TextField {...params} placeholder={t('Seleccionar bibliografía básica')} />
              )}
              onChange={(_, value) => {
                if (value) {
                  console.log(value);
                  const basicBlibliography = [...values.syllabus_bibliogra_basic];
                  const isSaveBookBasic = basicBlibliography.find(
                    (option) => option.book_id === value?.id
                  );
                  const editorial = value.tags.find((tag) => tag.type_tag === 'Editorial');
                  const autor = value.tags.find((tag) => tag.type_tag === 'Autor');

                  if (!isSaveBookBasic) {
                    basicBlibliography.push({
                      id: null,
                      syllabu_id: basicBlibliography[0]?.syllabu_id || null,
                      book_id: value.id,
                      book_name: value.title,
                      book_autor: autor?.name || 'Autor de la bibliografía no disponible',
                      book_editor: editorial?.name || 'Editorial de la bibliografía no disponible',
                    });
                    setFieldValue('syllabus_bibliogra_basic', basicBlibliography);
                  }
                }
              }}
              fullWidth
              clearIcon={null}
            />
          )}
        </Grid>

        <Grid item xs={12}>
          {values.syllabus_bibliogra_basic?.length > 0 ? (
            <>
              {values.syllabus_bibliogra_basic.map((option, index) => (
                <Grid
                  item
                  container
                  xs={12}
                  spacing={1}
                  key={`bibliografy-basic-${index + 1}`}
                  sx={{
                    my: 1,
                  }}
                >
                  <Grid item xs={4}>
                    <InputLabel>{t('Texto')}</InputLabel>
                    <TextField
                      {...getFieldProps(`syllabus_bibliogra_basic[${index}].book_name`)}
                      fullWidth
                      error={Boolean(
                        (errors.syllabus_bibliogra_basic?.[index] as SyllabusBibliogra)
                          ?.book_name && touched.syllabus_bibliogra_basic?.[index]?.book_name
                      )}
                      disabled
                    />
                    {errors.syllabus_bibliogra_basic?.[index] &&
                      touched.syllabus_bibliogra_basic?.[index]?.book_name && (
                        <FormHelperText error>
                          {t(
                            (errors.syllabus_bibliogra_basic?.[index] as SyllabusBibliogra)
                              .book_name
                          )}
                        </FormHelperText>
                      )}
                  </Grid>
                  <Grid item xs={3}>
                    <InputLabel>{t('Autor')}</InputLabel>
                    <TextField
                      error={Boolean(
                        (errors.syllabus_bibliogra_basic?.[index] as SyllabusBibliogra)
                          ?.book_autor && touched.syllabus_bibliogra_basic?.[index]?.book_autor
                      )}
                      {...getFieldProps(`syllabus_bibliogra_basic[${index}].book_autor`)}
                      fullWidth
                      disabled
                    />
                    {errors.syllabus_bibliogra_basic?.[index] &&
                      touched.syllabus_bibliogra_basic?.[index]?.book_name && (
                        <FormHelperText error>
                          {t(
                            (errors.syllabus_bibliogra_basic?.[index] as SyllabusBibliogra)
                              .book_autor
                          )}
                        </FormHelperText>
                      )}
                  </Grid>
                  <Grid item xs={3}>
                    <InputLabel>{t('Editorial')}</InputLabel>
                    <TextField
                      error={Boolean(
                        (errors.syllabus_bibliogra_basic?.[index] as SyllabusBibliogra)
                          ?.book_editor && touched.syllabus_bibliogra_basic?.[index]?.book_editor
                      )}
                      {...getFieldProps(`syllabus_bibliogra_basic[${index}].book_editor`)}
                      fullWidth
                      disabled
                    />
                    {errors.syllabus_bibliogra_basic?.[index] &&
                      touched.syllabus_bibliogra_basic?.[index]?.book_editor && (
                        <FormHelperText error>
                          {t(
                            (errors.syllabus_bibliogra_basic?.[index] as SyllabusBibliogra)
                              .book_editor
                          )}
                        </FormHelperText>
                      )}
                  </Grid>
                  <Grid item container xs={2} justifyContent="center" alignItems="center">
                    <DeleteButton handleDelete={() => handleDeleteOption(index)} />
                  </Grid>
                </Grid>
              ))}
            </>
          ) : (
            <Grid item xs={12}>
              <Typography variant="h5" textAlign="center">
                {t('No ha seleccionado ninguna bibliografía básica')}
              </Typography>
            </Grid>
          )}
        </Grid>

        {errors.syllabus_bibliogra_basic && !Array.isArray(errors.syllabus_bibliogra_basic) && (
          <FormHelperText sx={{ mt: 6 }} error>
            {errors.syllabus_bibliogra_basic}
          </FormHelperText>
        )}

        <Grid
          item
          xs={12}
          container
          alignItems="center"
          justifyContent="center"
          spacing={2}
          sx={{ mt: 6 }}
        >
          {isBack && (
            <Grid item>
              <Button
                fullWidth
                variant="outlined"
                size="large"
                color="primary"
                onClick={handleBack}
              >
                <ArrowBack />
              </Button>
            </Grid>
          )}
          <Grid item>
            <Button
              fullWidth
              variant="outlined"
              size="large"
              color="primary"
              onClick={handleNext}
              // disabled={isLoading}
              // endIcon={isLoading && <CircularProgress size={20} />}
            >
              <ArrowForward />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BasicBibliography;
