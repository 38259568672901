import { Box, Grid } from '@material-ui/core';
import { useState } from 'react';
import { TasksData } from '../../interfaces/tasks.interface';
import TaskItemBody from './TaskItemBody';
import TaskItemHeader from './TaskItemHeader';

interface Props {
  task: TasksData;
  modalOptions: {
    viewModal: {
      open: boolean;
      type: 'comment' | 'file';
      detailId: number;
    };
    setViewModal: React.Dispatch<
      React.SetStateAction<{
        open: boolean;
        type: 'comment' | 'file';
        detailId: number;
      }>
    >;
    refetch: () => void;
  };
}

const TaskItem = ({ task, modalOptions }: Props) => {
  const [viewDownload, setViewDownload] = useState<boolean>(false);
  const handleClickToggle = () => {
    setViewDownload(!viewDownload);
  };
  const infoHeader = {
    title: task.act_description,
    score: task.act_score_activity,
    startDate: task.course_activity_one.cact_start_date,
    endDate: task.course_activity_one.cact_end_date,
    groups: task.participants_group,
    numTries: task.act_num_tries,
    numTriesRealized: task.num_tries_realized,
    calification: task.cab_activity_student,
    isVisibleNote: Boolean(Number(task.visible_note)),
    groupId: task.group_student,
  };

  const infoBody = {
    detailsActivity: task.details_activity,
    toolsMaterials: task.tools_materials,
    calification: task.cab_activity_student,
    taskId: task.id,
    numTries: task.act_num_tries,
    numTriesRealized: task.num_tries_realized,
  };

  const isDateEnterRange = new Date() <= new Date(task.course_activity_one.cact_end_date);
  const isNumTriesCompleted = Number(infoHeader.numTriesRealized) === Number(infoHeader.numTries);

  return (
    <Grid item xs={6}>
      <Box
        sx={{
          borderRadius: '12px',
          boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
        }}
      >
        <TaskItemHeader
          infoHeader={infoHeader}
          expanded={viewDownload}
          handleClickToggle={handleClickToggle}
        />

        {viewDownload && (
          <TaskItemBody
            modalOptions={modalOptions}
            infoBody={infoBody}
            isUploadTask={isNumTriesCompleted}
            isDateEnterRange={isDateEnterRange}
          />
        )}
      </Box>
    </Grid>
  );
};

export default TaskItem;
