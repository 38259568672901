import { useAppDispatch } from '../../../../hooks/useRedux';
import CustomSideDialog from '../../../shared/components/dialogs/CustomSideDialog';
import { setIsOpenCollaboratorTableModal } from '../slices/investigationLinesSlice';
import CollaboratorTableSelected from './CollaboratorTableSelected';

const CollaboratorTableDialog = () => {
  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(setIsOpenCollaboratorTableModal(false));
  };

  return (
    <CustomSideDialog open handleCloseDialog={handleClose} title="Colaboradores">
      <CollaboratorTableSelected />
    </CustomSideDialog>
  );
};

export default CollaboratorTableDialog;
