import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import {
  Alert,
  Autocomplete,
  Button,
  CircularProgress,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  Skeleton,
  Switch,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@material-ui/core';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import DatePicker from '@material-ui/lab/DatePicker';
import esLocale from 'date-fns/locale/es';

import { format, parseISO } from 'date-fns';

import { useFormik } from 'formik';

import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import InputLabel from '../../../shared/components/forms/InputLabel';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';

import {
  useUpdateMeshMutation,
  // useGetCurriculumStatusQuery,
  useGetStudyModalitiesQuery,
  useGetLearningComponentsQuery,
  useGetIfEducationLevelIsAlreadyVigentQuery,
} from '../slices/meshsApiSlice';

import { MeshValidationSchema } from '../constants/validationSchemas';
import { useGetEducationLevelsParentsAndChildrenQuery } from '../../education-levels/slices/educationLevelsApiSlice';

import { MeshFormFields } from '../interfaces/payloads.interfaces';
import { useGetCalificationTypesQuery } from '../../califications-types/slices/calificationsTypesApiSlice';
import { setAddEducationLevelDialogOpen } from '../slices/meshSlice';
import { IconLink } from '@tabler/icons';
import AddEducationLevelDialog from './AddEducationLevelDialog';

const EditCurriculumForm = () => {
  const { meshId } = useParams<{ meshId: string }>();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const history = useHistory();

  const { currentProfile } = useAppSelector((state) => state.access);
  const { curriculumList, addEducationLevelDialogOpen } = useAppSelector((state) => state.meshs);

  const meshToEdit = curriculumList.find((m) => m.id === Number(meshId));

  const [offDescription, setOffDescription] = useState(
    meshToEdit?.education_level.offer?.off_description
  );

  const [showAlerts, setShowAlerts] = useState(true);

  const [updateMesh, { isLoading, isError, isSuccess, error }] = useUpdateMeshMutation();

  // const { data: curriculumStatusAvailables = [], isLoading: isLoadingMS } =
  //   useGetCurriculumStatusQuery({
  //     profileId: currentProfile?.profileId!,
  //   });

  const { data: calificationTypesAvailables = [], isLoading: isLoadingCT } =
    useGetCalificationTypesQuery({
      profileId: currentProfile?.profileId!,
    });

  const { data: studyModalitiesAvailables = [], isLoading: isLoadingSM } =
    useGetStudyModalitiesQuery({
      profileId: currentProfile?.profileId!,
    });

  const { data: learningComponents = [], isLoading: isLoadingLC } = useGetLearningComponentsQuery({
    profileId: currentProfile?.profileId!,
  });

  const [isNew, setIsNew] = useState<boolean>(Boolean(Number(meshToEdit?.curriculum_new)));

  const handleChangeIsNew = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsNew(event.target.checked);
  };

  const {
    data: edLvls,
    isLoading: isLoadingEDL,
    isFetching: isFetchingEDL,
  } = useGetEducationLevelsParentsAndChildrenQuery({
    profileId: currentProfile?.profileId!,
    filter: true,
  });

  const { getFieldProps, errors, touched, setFieldValue, handleSubmit, values } =
    useFormik<MeshFormFields>({
      initialValues: {
        name: meshToEdit?.mes_name! || '',
        description: meshToEdit?.mes_description || '',
        casRes: meshToEdit?.mes_res_cas || '',
        ocasRes: meshToEdit?.mes_res_ocas || '',
        creationDate: parseISO(meshToEdit?.mes_creation_date!) || null,
        year: parseISO(meshToEdit?.anio.toString()!),
        title: meshToEdit?.mes_title || '',
        itinerary: meshToEdit?.mes_itinerary || '',
        numPeriods: Number(meshToEdit?.mes_number_period) || null,
        levelEduId: meshToEdit?.education_level.id || null,
        statusId: Number(meshToEdit?.status_id) || null,
        modalityId: meshToEdit?.modality?.id || null,
        calificationTypeId: meshToEdit?.type_calification_keywork || null, // now is a string
        internalHomologationSubjects:
          Number(meshToEdit?.mes_quantity_internal_matter_homologate) || null,
        externalHomologationSubjects:
          Number(meshToEdit?.mes_quantity_external_matter_homologate) || null,
        codCareer: meshToEdit?.mes_cod_career || '',
        learningComponents: meshToEdit?.learning_component || [],
        subjectsToPP: meshToEdit?.cur_num_prof_pract || null,
        maxFailedSubject: Number(meshToEdit?.max_number_failed_subject) || null,
        percentage_titulation1: Number(meshToEdit?.percentage_titulation1) || 0,
        percentage_titulation2: Number(meshToEdit?.percentage_titulation2) || 0,
        isRequiredCalificationType: false,
        offDescription: meshToEdit?.education_level.offer?.off_description || null,
        facultyId: meshToEdit?.education_level?.father?.id || null,
        vigencyDate: meshToEdit?.mes_expired_date ? parseISO(meshToEdit?.mes_expired_date) : null,
        cacesResolutionDate: meshToEdit?.mes_approval_date_caces
          ? parseISO(meshToEdit?.mes_approval_date_caces)
          : null,
        cacesResolutionNumber: meshToEdit?.mes_resolution_approval_caces || '',
        offerId: null,
        offer_id: meshToEdit?.education_level.offer?.id?.toString() || null,
        link_curriculum: meshToEdit?.link_curriculum,
      },
      validationSchema: MeshValidationSchema,
      onSubmit: async (values) => {
        const internalHomologation = values.internalHomologationSubjects;
        const externalHomologation = values.externalHomologationSubjects;

        await updateMesh({
          profileId: currentProfile?.profileId!,
          meshId: meshToEdit?.id!,
          meshPayload: {
            mes_name: values.name,
            mes_res_cas: values.casRes,
            mes_res_ocas: values.ocasRes,
            mes_title: values.title,
            mes_itinerary: values.itinerary,
            mes_number_period: values.numPeriods!,
            mes_creation_date: format(values.creationDate!, 'yyyy-MM-dd'),
            anio: Number(format(values.year!, 'yyyy')),
            // anio: Number(values.year),
            mes_description: values.description,
            mes_modality_id: values.modalityId!,
            level_edu_id: values.levelEduId!,
            status_id: values.statusId!,
            type_calification_id: values.calificationTypeId!,
            ...(internalHomologation && {
              mes_quantity_internal_matter_homologate: internalHomologation,
            }),
            ...(externalHomologation && {
              mes_quantity_external_matter_homologate: externalHomologation,
            }),
            mes_cod_career: values.codCareer,
            components: values.learningComponents,
            cur_num_prof_pract: values.subjectsToPP!,
            max_number_failed_subject: values.maxFailedSubject!,
            percentage_titulation1: parseFloat(values.percentage_titulation1.toString()),
            percentage_titulation2: parseFloat(values.percentage_titulation1.toString()),
            curriculum_new: isNew,
            mes_expired_date: values.vigencyDate
              ? format(values.vigencyDate, 'yyyy-MM-dd')
              : undefined,
            mes_approval_date_caces: values.cacesResolutionDate
              ? format(values.cacesResolutionDate, 'yyyy-MM-dd')
              : undefined,
            mes_resolution_approval_caces: values.cacesResolutionNumber,
            link_curriculum: values.link_curriculum,
          },
        });

        setShowAlerts(true);
        window.scrollTo(0, 0);
      },
    });

  const { data: ifVigentMsg, isFetching: isFetchingVED } =
    useGetIfEducationLevelIsAlreadyVigentQuery(
      {
        educationLevelId: values.levelEduId!,
      },
      {
        skip: !values.levelEduId || values.levelEduId === meshToEdit?.education_level?.id,
      }
    );

  if (!meshToEdit) {
    history.replace('/mallas-curriculares');
  }

  return (
    <>
      <form onSubmit={handleSubmit} style={{ width: '100%' }}>
        <Grid item container sx={{ mb: 2 }}>
          <Grid item xs={12}>
            {isError && showAlerts && (
              <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
            )}

            {isSuccess && showAlerts && (
              <SuccessAlert
                message="La malla se ha actualizado correctamente"
                handleDismiss={() => setShowAlerts(false)}
              />
            )}
          </Grid>
        </Grid>

        <Grid item container alignItems="flex-start" spacing={4}>
          <Grid item xs={12} md={6} container spacing={2}>
            <Grid item xs={12}>
              {ifVigentMsg &&
                !isFetchingVED &&
                values.levelEduId !== meshToEdit?.education_level.id && (
                  <Alert severity="info">{ifVigentMsg?.detail}</Alert>
                )}
              <Grid>
                <InputLabel>Unidad Académica</InputLabel>
                <Autocomplete
                  options={edLvls || []}
                  loading={isLoadingEDL || isFetchingEDL}
                  loadingText="Cargando unidades académicas..."
                  getOptionLabel={(option) => option.edu_name}
                  {...(isFetchingEDL && { popupIcon: <CircularProgress size={25} /> })}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(errors.facultyId && touched.facultyId)}
                      placeholder="Seleccione la unidad académica"
                    />
                  )}
                  value={edLvls?.find((ed) => ed.id === values.facultyId) || null}
                  onChange={(_, value) => {
                    setFieldValue('facultyId', value?.id || '');
                    setFieldValue('eduLevelId', '');
                    setFieldValue('offer_id', value?.offer_id || 0);
                  }}
                  fullWidth
                  clearIcon={null}
                />
              </Grid>

              <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                  <InputLabel>Carrera</InputLabel>
                </Grid>

                <Grid item>
                  <Tooltip title="Agregar unidad académica">
                    <IconButton
                      onClick={() => {
                        dispatch(setAddEducationLevelDialogOpen(true));
                      }}
                    >
                      <IconLink
                        style={{
                          color: theme.palette.primary.main,
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
              {/* <InputSecondaryLabel
                label="Unidad Académica"
                title="Agregar unidad académica"
                link={'/administrar-unidades-academicas/agregar'}
                hasAccess={hasAccess('create')}
              /> */}
              <Autocomplete
                options={edLvls?.find((ed) => ed.id === values.facultyId)?.children || []}
                loading={isLoadingEDL || isFetchingEDL}
                loadingText="Cargando carreras disponibles..."
                getOptionLabel={(option) => option.edu_name}
                {...(isFetchingVED && { popupIcon: <CircularProgress size={25} /> })}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(errors.levelEduId && touched.levelEduId)}
                    placeholder={
                      values.facultyId
                        ? 'Seleccione una carrera'
                        : 'Seleccione primero una unidad académica '
                    }
                  />
                )}
                disabled={!values.facultyId}
                value={
                  edLvls
                    ?.find((ed) => ed.id === values.facultyId)
                    ?.children.find((ed) => ed.id === values.levelEduId) || null
                }
                onChange={(_, value) => {
                  setFieldValue('levelEduId', value?.id || '');
                  setFieldValue('learningComponents', []);
                  setOffDescription(value?.offer?.off_description || '');
                  if (
                    value?.offer?.off_description === 'oferta_ed_continua' ||
                    value?.offer?.off_description === 'oferta_postgrado'
                  ) {
                    setFieldValue('isRequiredCalificationType', false);
                    setFieldValue('calificationTypeId', 'hrs');
                  } else {
                    setFieldValue('isRequiredCalificationType', true);
                    setFieldValue('numPeriods', null);
                  }
                }}
                fullWidth
                clearIcon={null}
              />

              {errors.levelEduId && touched.levelEduId && (
                <FormHelperText error>{errors.levelEduId}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12}>
              <InputLabel>Itinerario</InputLabel>
              <TextField
                fullWidth
                placeholder="Itinerario de la malla"
                error={Boolean(errors.itinerary && touched.itinerary)}
                {...getFieldProps('itinerary')}
              />
              {errors.itinerary && touched.itinerary && (
                <FormHelperText error>{errors.itinerary}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12}>
              <InputLabel>Título</InputLabel>
              <TextField
                fullWidth
                placeholder="Título a obtener"
                error={Boolean(errors.title && touched.title)}
                {...getFieldProps('title')}
              />
              {errors.title && touched.title && (
                <FormHelperText error>{errors.title}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12}>
              <InputLabel>Código de Carrera (Alfanumérico)</InputLabel>
              <TextField
                fullWidth
                placeholder="Ingrese Código de Carrera (Alfanumérico)"
                error={Boolean(errors.codCareer && touched.codCareer)}
                {...getFieldProps('codCareer')}
              />
              {errors.codCareer && touched.codCareer && (
                <FormHelperText error>{errors.codCareer}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12}>
              <InputLabel>Url de malla</InputLabel>
              <TextField
                fullWidth
                placeholder="Ingrese enlace de malla"
                error={Boolean(errors.link_curriculum && touched.link_curriculum)}
                {...getFieldProps('link_curriculum')}
              />
              {errors.link_curriculum && touched.link_curriculum && (
                <FormHelperText error>{errors.link_curriculum}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12}>
              <InputLabel>Número de períodos</InputLabel>
              <TextField
                fullWidth
                type="number"
                placeholder="Número de períodos del programa"
                error={Boolean(errors.numPeriods && touched.numPeriods)}
                {...getFieldProps('numPeriods')}
              />
              {errors.numPeriods && touched.numPeriods && (
                <FormHelperText error>{errors.numPeriods}</FormHelperText>
              )}
            </Grid>
            {/* Materias reprobadas */}
            <Grid item xs={12}>
              <InputLabel>Máximo de Materias Reprobadas</InputLabel>
              <TextField
                fullWidth
                type="number"
                placeholder="Máximo de Materias Reprobadas"
                error={Boolean(errors.maxFailedSubject && touched.maxFailedSubject)}
                {...getFieldProps('maxFailedSubject')}
              />
              {errors.maxFailedSubject && touched.maxFailedSubject && (
                <FormHelperText error>{errors.maxFailedSubject}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12}>
              <InputLabel>Máximo de Materias Homologación Interna</InputLabel>
              <TextField
                fullWidth
                type="number"
                placeholder="Máximo de Materias Homologación Interna"
                error={Boolean(
                  errors.internalHomologationSubjects && touched.internalHomologationSubjects
                )}
                {...getFieldProps('internalHomologationSubjects')}
              />
              {errors.internalHomologationSubjects && touched.internalHomologationSubjects && (
                <FormHelperText error>{errors.internalHomologationSubjects}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12}>
              <InputLabel>Máximo de Materias Homologación Externa</InputLabel>
              <TextField
                fullWidth
                type="number"
                placeholder="Máximo de Materias Homologación Externa"
                error={Boolean(
                  errors.externalHomologationSubjects && touched.externalHomologationSubjects
                )}
                {...getFieldProps('externalHomologationSubjects')}
              />
              {errors.externalHomologationSubjects && touched.externalHomologationSubjects && (
                <FormHelperText error>{errors.externalHomologationSubjects}</FormHelperText>
              )}
            </Grid>

            {/* <Grid item xs={12}>
            <InputLabel>Número créditos port materia</InputLabel>
            <TextField
              fullWidth
              type="number"
              placeholder="Número créditos por materia"
              error={Boolean(
                errors.amount_credits && touched.amount_credits
              )}
              {...getFieldProps('amount_credits')}
            />
            {errors.amount_credits && touched.amount_credits && (
              <FormHelperText error>{errors.amount_credits}</FormHelperText>
            )}
          </Grid> */}

            {/* Estado */}
            {/* <Grid item xs={12}>
            <InputLabel>Estado</InputLabel>
            {isLoadingMS ? (
              <Skeleton variant="rectangular" sx={{ width: '100%', borderRadius: 3, height: 50 }} />
            ) : (
              <Autocomplete
                options={curriculumStatusAvailables}
                getOptionLabel={(option) => option.st_name}
                loading={isLoadingMS}
                loadingText="Cargando estados de la malla"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(errors.modalityId && touched.modalityId)}
                    placeholder="Seleccione el estado de la malla"
                  />
                )}
                defaultValue={
                  curriculumStatusAvailables.find(
                    (ms) => ms.id === Number(meshToEdit?.status_id)
                  ) || curriculumStatusAvailables[0]
                }
                onChange={(_, value) => setFieldValue('statusId', value?.id || '')}
                fullWidth
                clearIcon={null}
              />
            )}
            {errors.statusId && touched.statusId && (
              <FormHelperText error>{errors.statusId}</FormHelperText>
            )}
          </Grid> */}

            <Grid item xs={12}>
              <InputLabel>Porcentaje Titulación 1</InputLabel>
              <TextField
                fullWidth
                type="number"
                placeholder="Porcentaje Titulación 1"
                error={Boolean(errors.percentage_titulation1 && touched.percentage_titulation1)}
                {...getFieldProps('percentage_titulation1')}
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
              />
              {errors.percentage_titulation1 && touched.percentage_titulation1 && (
                <FormHelperText error>{errors.percentage_titulation1}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12}>
              <InputLabel>Porcentaje Titulación 2</InputLabel>
              <TextField
                fullWidth
                type="number"
                placeholder="Porcentaje Titulación 2"
                error={Boolean(errors.percentage_titulation2 && touched.percentage_titulation2)}
                {...getFieldProps('percentage_titulation2')}
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
              />
              {errors.percentage_titulation2 && touched.percentage_titulation2 && (
                <FormHelperText error>{errors.percentage_titulation2}</FormHelperText>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} container spacing={2}>
            {/* Nombre */}
            <Grid item xs={12}>
              <Grid container justifyContent={'space-between'}>
                <Grid item xs={6}>
                  <InputLabel>Pensum</InputLabel>
                </Grid>

                <Grid item xs={4} sx={{ position: 'relative' }}>
                  {/* <Tooltip title="Mostrar todas las aulas"> */}
                  {!isNew && (
                    <Typography
                      sx={{ position: 'absolute', right: 0, top: -10, color: 'error.main' }}
                    >
                      Malla antigua
                    </Typography>
                  )}

                  {false && (
                    <FormControlLabel
                      sx={{ position: 'absolute', right: 0, top: -10 }}
                      control={
                        <Switch
                          checked={isNew}
                          disabled
                          onChange={handleChangeIsNew}
                          inputProps={{ 'aria-label': '¿La malla es nueva?' }}
                        />
                      }
                      label="¿Es nueva?"
                      labelPlacement="start"
                    />
                  )}

                  {/* </Tooltip> */}
                </Grid>
              </Grid>

              <TextField
                fullWidth
                error={Boolean(errors.name && touched.name)}
                placeholder="Ingrese el pensum"
                disabled
                {...getFieldProps('name')}
              />
              {errors.name && touched.name && <FormHelperText error>{errors.name}</FormHelperText>}
            </Grid>

            {/* Descripcion */}
            <Grid item xs={12}>
              <InputLabel>Descripción</InputLabel>
              <TextField
                multiline
                rows={2}
                fullWidth
                error={Boolean(errors.description && touched.description)}
                placeholder="Ingrese una descripción para la malla"
                {...getFieldProps('description')}
              />
              {errors.description && touched.description && (
                <FormHelperText error>{errors.description}</FormHelperText>
              )}
            </Grid>

            {/* Fecha de creacion */}
            <Grid item xs={12}>
              <InputLabel>Fecha de creación</InputLabel>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                <DatePicker
                  value={values.creationDate}
                  onChange={(newValue) => {
                    setFieldValue('creationDate', newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(errors.creationDate && touched.creationDate)}
                      helperText=""
                      placeholder="Fecha de creación"
                      fullWidth
                    />
                  )}
                />
              </LocalizationProvider>
              {errors.creationDate && touched.creationDate && (
                <FormHelperText error>{errors.creationDate}</FormHelperText>
              )}
            </Grid>

            {/* Año */}
            <Grid item xs={12}>
              <InputLabel>Año</InputLabel>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                <DatePicker
                  views={['year']}
                  value={values.year}
                  onChange={(newValue) => {
                    setFieldValue('year', newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(errors.year && touched.year)}
                      helperText=""
                      placeholder="Fecha de creación"
                      fullWidth
                    />
                  )}
                />
              </LocalizationProvider>
              {/* <TextField
              fullWidth
              type="number"
              placeholder="Ingrese el año de la malla"
              error={Boolean(errors.year && touched.year)}
              {...getFieldProps('year')}
            /> */}
              {errors.year && touched.year && <FormHelperText error>{errors.year}</FormHelperText>}
            </Grid>

            {values.offer_id !== null && values.offer_id === '3' && (
              <>
                {/* Fecha  de vigencia */}
                <Grid item xs={12}>
                  <InputLabel>Fecha de vigencia</InputLabel>
                  <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                    <DatePicker
                      value={values.vigencyDate}
                      onChange={(newValue) => {
                        setFieldValue('vigencyDate', newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={Boolean(errors.vigencyDate && touched.vigencyDate)}
                          helperText=""
                          placeholder="Fecha de vigencia"
                          fullWidth
                        />
                      )}
                    />
                  </LocalizationProvider>
                  {errors.vigencyDate && touched.vigencyDate && (
                    <FormHelperText error>{errors.vigencyDate}</FormHelperText>
                  )}
                </Grid>

                {/* {resolucion de aprobacion (CACES) } */}
                <Grid item xs={12}>
                  <InputLabel>Resolución de aprobación (CACES)</InputLabel>
                  <TextField
                    fullWidth
                    error={Boolean(errors.cacesResolutionNumber && touched.cacesResolutionNumber)}
                    placeholder="Ingrese la resolución de aprobación"
                    {...getFieldProps('cacesResolutionNumber')}
                  />
                  {errors.cacesResolutionNumber && touched.cacesResolutionNumber && (
                    <FormHelperText error>{errors.cacesResolutionNumber}</FormHelperText>
                  )}
                </Grid>

                {/* Fecha de aprobacion del CACES  */}
                <Grid item xs={12}>
                  <InputLabel>Fecha de aprobación del CACES</InputLabel>
                  <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                    <DatePicker
                      value={values.cacesResolutionDate}
                      onChange={(newValue) => {
                        setFieldValue('cacesResolutionDate', newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={Boolean(errors.cacesResolutionDate && touched.cacesResolutionDate)}
                          helperText=""
                          placeholder="Fecha de aprobación del CACES"
                          fullWidth
                        />
                      )}
                    />
                  </LocalizationProvider>
                  {errors.cacesResolutionDate && touched.cacesResolutionDate && (
                    <FormHelperText error>{errors.cacesResolutionDate}</FormHelperText>
                  )}
                </Grid>
              </>
            )}

            {/* Resolucion CAS CES */}
            <Grid item xs={12}>
              <InputLabel>Resolución CES</InputLabel>
              <TextField
                fullWidth
                error={Boolean(errors.casRes && touched.casRes)}
                placeholder="Ingrese la resolución CES"
                {...getFieldProps('casRes')}
              />
              {errors.casRes && touched.casRes && (
                <FormHelperText error>{errors.casRes}</FormHelperText>
              )}
            </Grid>

            {/* Resolucion OCAS */}
            <Grid item xs={12}>
              <InputLabel>Resolución OCAS</InputLabel>
              <TextField
                fullWidth
                error={Boolean(errors.ocasRes && touched.ocasRes)}
                placeholder="Ingrese la resolución OCAS"
                {...getFieldProps('ocasRes')}
              />
              {errors.ocasRes && touched.ocasRes && (
                <FormHelperText error>{errors.ocasRes}</FormHelperText>
              )}
            </Grid>

            {/* Modalidad */}
            <Grid item xs={12}>
              <InputLabel>Modalidad</InputLabel>
              {isLoadingSM ? (
                <Skeleton
                  variant="rectangular"
                  sx={{ width: '100%', borderRadius: 3, height: 50 }}
                />
              ) : (
                <Autocomplete
                  options={studyModalitiesAvailables}
                  getOptionLabel={(option) => option.cat_name}
                  loading={isLoadingSM}
                  loadingText="Cargando modalidades..."
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(errors.modalityId && touched.modalityId)}
                      placeholder="Seleccione la modalidad"
                    />
                  )}
                  defaultValue={
                    studyModalitiesAvailables.find(
                      (sm) => sm.id === Number(meshToEdit?.mes_modality_id)
                    ) || studyModalitiesAvailables[0]
                  }
                  onChange={(_, value) => setFieldValue('modalityId', value?.id || '')}
                  fullWidth
                  clearIcon={null}
                />
              )}
              {errors.modalityId && touched.modalityId && (
                <FormHelperText error>{errors.modalityId}</FormHelperText>
              )}
            </Grid>

            {/* Tipo de calificación */}
            <Grid item xs={12}>
              <InputLabel>Tipo de calificación</InputLabel>
              {isLoadingCT ? (
                <Skeleton
                  variant="rectangular"
                  sx={{ width: '100%', borderRadius: 3, height: 50 }}
                />
              ) : (
                <Autocomplete
                  options={calificationTypesAvailables}
                  loading={isLoadingCT}
                  disabled={!values.isRequiredCalificationType}
                  loadingText="Cargando tipos de calificación disponibles..."
                  getOptionLabel={(option) => option.tc_name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(errors.calificationTypeId && touched.calificationTypeId)}
                      placeholder="Seleccione un tipo de calificación"
                    />
                  )}
                  value={
                    calificationTypesAvailables.find(
                      (ct) => ct.tc_keywork === meshToEdit?.type_calification_keywork
                    ) || calificationTypesAvailables[0]
                  }
                  onChange={(_, value) =>
                    setFieldValue('calificationTypeId', value?.tc_keywork || '')
                  }
                  fullWidth
                  clearIcon={null}
                />
              )}
              {errors.calificationTypeId && touched.calificationTypeId && (
                <FormHelperText error>{errors.calificationTypeId}</FormHelperText>
              )}
            </Grid>

            {/* Componentes de aprendizaje */}
            <Grid item xs={12}>
              <InputLabel>Componentes de aprendizaje</InputLabel>
              {isLoadingLC ? (
                <Skeleton
                  variant="rectangular"
                  sx={{ width: '100%', borderRadius: 3, height: 50 }}
                />
              ) : (
                <Autocomplete
                  options={learningComponents.filter(
                    (e) => e?.offer?.off_description === offDescription
                  )}
                  loading={isLoadingLC}
                  loadingText="Cargando los componentes de aprendizaje..."
                  multiple
                  getOptionLabel={(option) => option.com_name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(errors.learningComponents && touched.learningComponents)}
                      placeholder="Seleccione al menos un componente de aprendizaje"
                    />
                  )}
                  // defaultValue={learningComponents.filter((lc) =>
                  //   meshToEdit?.learning_component?.some((c) => +c.component_id === lc.id)
                  // )}
                  defaultValue={learningComponents.filter((lc) =>
                    meshToEdit?.learning_component?.some((c) => +c.component_id === lc.id)
                  )}
                  // value={
                  //   learningComponents.filter((lc) =>
                  //     values.learningComponents.find((lc2) => lc2.component_id === lc.id)
                  //   ) || []
                  // }
                  onChange={(_, values) =>
                    setFieldValue('learningComponents', [
                      ...values.map((v) => ({
                        component_id: v.id,
                      })),
                    ])
                  }
                  fullWidth
                  clearIcon={null}
                />
              )}
              {errors.learningComponents && touched.learningComponents && (
                <FormHelperText error>{errors.learningComponents}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12}>
              <InputLabel>Cantidad de Materias para Prácticas Preprofesionales</InputLabel>
              <TextField
                fullWidth
                type="number"
                placeholder="Cantidad de Materias para Prácticas Preprofesionales"
                error={Boolean(errors.subjectsToPP && touched.subjectsToPP)}
                {...getFieldProps('subjectsToPP')}
              />
              {errors.subjectsToPP && touched.subjectsToPP && (
                <FormHelperText error>{errors.subjectsToPP}</FormHelperText>
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid item container>
          <Grid
            item
            container
            alignItems="center"
            justifyContent="flex-end"
            spacing={2}
            sx={{ mt: 1 }}
          >
            <Grid item>
              <Button
                variant="outlined"
                size="large"
                color="primary"
                onClick={() => history.goBack()}
              >
                Regresar
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                size="large"
                color="primary"
                disabled={isLoading}
                endIcon={isLoading && <CircularProgress size={20} />}
              >
                Actualizar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
      {addEducationLevelDialogOpen && <AddEducationLevelDialog />}
    </>
  );
};

export default EditCurriculumForm;
