import { Avatar, Box, ButtonBase, useTheme } from '@material-ui/core';
import { IconListCheck } from '@tabler/icons';
import { useHistory } from 'react-router-dom';
import { useStyles } from '../../../maintenance/faqs/constants/styles';

export const EvaluationButton = () => {
  const classes = useStyles();

  const theme = useTheme();

  const history = useHistory();

  return (
    <Box component="span" className={classes.box}>
      <ButtonBase sx={{ borderRadius: '12px' }}>
        <Avatar
          variant="rounded"
          className={classes.headerAvatar}
          color={theme.palette.primary.main}
          onClick={() => history.push('/evaluaciones-usuario')}
        >
          <IconListCheck stroke={1.5} size="1.3rem" />
        </Avatar>
      </ButtonBase>
    </Box>
  );
};
