import { Formik } from 'formik';
import {
  ProjectStepsPayload,
  Project,
  LinesGroupResearch,
  EducationLevels,
} from '../../../../interfaces/projectResearch.interfaces';
import { SpecificDetailsSchema } from '../../../../constants/wizard.validationSchemas';

import { Reseteable } from '.';

import SpecificDetailsWraperForm from './SpecificDetailsWraperForm';

interface Props extends Reseteable {
  setData: React.Dispatch<React.SetStateAction<ProjectStepsPayload>>;
  setNext: React.Dispatch<React.SetStateAction<number>>;
  isEdit?: boolean;
  dataEdit?: Project;
}

const GeneralDetailsForm = ({
  setData,
  setNext,
  isEdit = false,
  dataEdit,
  isReset,
}: // dataEdit = [] as Project,
Props) => {
  const handleNext = () => {
    setNext((step) => step + 1);
  };

  const handleReturn = () => {
    setNext((step) => step - 1);
  };

  return (
    <Formik<{
      cityId: number;
      provinceId: number;
      educationLevels: number[] | EducationLevels[];
      educationLevelsPrincipal: string | undefined;
      linesGroupResearch: string[] | LinesGroupResearch[];
      linesGroupResearchSend: LinesGroupResearch[];
      linesGroupResearchPrincipal: string | undefined;
      investigationLines: string[] | undefined;
      investigationLinesPrincipal: string | undefined;
      typeResearch: string;
    }>
      initialValues={{
        cityId: isEdit ? Number(dataEdit?.city_id) || 0 : 0,
        provinceId: isEdit ? Number(dataEdit?.province_id) || 0 : 0,
        // educationLevels: isEdit
        //   ? facultadAvailables?.filter((e) =>
        //       dataEdit?.project_education_level
        //         ?.map((item) => item?.education_level)
        //         .flat()
        //         .some((item) => item.id === e.id)
        //     ) ,
        educationLevels: isEdit
          ? dataEdit?.project_education_level
              ?.map((item) => item?.education_level.map((i) => i.id))
              .flat() || []
          : [],
        educationLevelsPrincipal: isEdit
          ? dataEdit?.project_education_level?.find(({ is_principal }) => is_principal === '1')
              ?.education_level_id
          : '',
        // linesGroupResearch: [] as LineGroupResearchAllAvailables[],
        linesGroupResearch: isEdit
          ? dataEdit?.line_group_research_project.map(
              ({ group_research_id }) => group_research_id
            ) || []
          : [],
        linesGroupResearchSend: [],
        linesGroupResearchPrincipal: isEdit
          ? dataEdit?.line_group_research_project.find(({ is_principal }) => is_principal === '1')
              ?.group_research_id
          : '',
        // investigationLines: [] as InvestigationLine[],
        investigationLines: isEdit
          ? dataEdit?.line_group_research_project.map(({ line_research_id }) => line_research_id)
          : [],
        investigationLinesPrincipal: isEdit
          ? dataEdit?.line_group_research_project.find(({ is_principal }) => is_principal === '1')
              ?.line_research_id
          : '',
        typeResearch: isEdit ? dataEdit?.type_research || '' : '',
      }}
      validationSchema={SpecificDetailsSchema}
      onSubmit={async ({
        cityId,
        provinceId,
        educationLevels,
        linesGroupResearch,
        linesGroupResearchSend,
        typeResearch,
        linesGroupResearchPrincipal,
        educationLevelsPrincipal,
        investigationLines,
      }) => {
        setData((data) => ({
          ...data,
          specificDetails: {
            city_id: cityId!,
            education_levels: educationLevels,
            lines_group_research: linesGroupResearchSend,
            province_id: provinceId!,
            type_research: typeResearch,
          },
        }));

        handleNext();
        // window.scrollTo(0, 0);
      }}
    >
      {() => (
        <SpecificDetailsWraperForm
          handleReturn={handleReturn}
          dataEdit={dataEdit}
          isEdit={isEdit}
          isReset={isReset}
        />
      )}
    </Formik>
  );
};

export default GeneralDetailsForm;
