import { FC, useState } from 'react';

import { Alert, Box, Button, Grid, TextField, useTheme } from '@material-ui/core';
import { IconX, IconSearch } from '@tabler/icons';

import { useTranslation } from 'react-i18next';

import ClientsSideDialog from '../ClientsSideDialog';

import { useAppSelector, useAppDispatch } from '../../../../../hooks/useRedux';
import SelectStudentRecord from './SelectStudentRecord';
import ClientLabels from './ClientLabels';
import { SearchPersonParam } from '../../../../shared/interfaces/searchPerson.interface';
import { resetClientMassiveDebts } from '../../slices/generateDebtsSlice';

interface Props {
  currentClient?: SearchPersonParam;
  isOptional?: boolean;
}

const SelectClient: FC<Props> = ({ currentClient, isOptional }) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const { currentTab, currentClientMassiveDebts } = useAppSelector((state) => state.generateDebts);

  const handleClearCurrentCLient = () => {
    dispatch(resetClientMassiveDebts());
  };

  const [open, setOpen] = useState(false);

  const handleCloseDialog = () => {
    setOpen(false);
  };
  const handleOpenClientSideDialog = () => {
    setOpen(true);
  };

  return (
    <>
      {/* <Grid container> */}
      <Box
        sx={{ width: '100%' }}
        border={`1px solid ${theme.palette.primary.main}`}
        borderRadius={'12px'}
        p={2}
      >
        <Grid container justifyContent={'space-between'} alignItems={'center'} mb={1}>
          <Grid item xs={6} md={6}>
            {!!currentClient?.user.student?.student_records &&
            currentClient?.user.student?.student_records.length > 1 ? (
              <SelectStudentRecord />
            ) : (
              currentClient && (
                <TextField
                  fullWidth
                  label={t('financial.generate_debts.select_stud_record_component.label')}
                  disabled
                  variant="standard"
                  value={currentClient?.user.student?.student_records[0]?.education_level.edu_name}
                />
              )
            )}
          </Grid>

          <Grid item xs="auto">
            {currentTab === 1 && currentClientMassiveDebts && (
              <Button
                startIcon={<IconX size={20} />}
                variant="outlined"
                onClick={handleClearCurrentCLient}
                sx={{ marginRight: 1 }}
              >
                {t('financial.generate_debts.client_component.remove_btn')}
              </Button>
            )}
            <Button
              startIcon={<IconSearch size={20} />}
              variant="outlined"
              onClick={handleOpenClientSideDialog}
            >
              {t('financial.generate_debts.client_component.search_btn')}
            </Button>
          </Grid>

          {currentClient ? (
            <ClientLabels currentClient={currentClient} />
          ) : (
            <Grid container justifyContent={'center'} p={1}>
              <Grid item md={8}>
                <Alert severity="info">
                  {isOptional
                    ? t('financial.generate_debts.unitary.select_client_alert_optional')
                    : t('financial.generate_debts.unitary.select_client_alert')}
                </Alert>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Box>
      {/* </Grid> */}

      <ClientsSideDialog open={open} handleCloseDialog={handleCloseDialog} />
    </>
  );
};

export default SelectClient;
