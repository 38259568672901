import {
  Autocomplete,
  Button,
  CircularProgress,
  Grid,
  InputLabel,
  TextField,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { statusAvailables } from '../../../shared/constants/resourceStatus';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { DatePicker, LocalizationProvider } from '@material-ui/lab';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import {
  useAddAutorMutation,
  useEditAuthorMutation,
  useGetNacionalidadesQuery,
} from '../../authors/slices/authorsApiSlice';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import { useAppSelector } from '../../../../hooks/useRedux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

const AddTagForm = () => {
  const { t } = useTranslation();

  const history = useHistory();
  const { tagToEdit } = useAppSelector((state) => state.tags);

  const tagsTypes = [
    { value: 'Editorial', lable: t('tags.editorial') },
    { value: 'Personaje', lable: t('tags.character') },
    { value: 'Contenido', lable: t('tags.content') },
    { value: 'Ciudad', lable: t('tags.city') },
    { value: 'Idioma', lable: t('tags.idiom') },
    { value: 'Facultad', lable: t('tags.faculty') },
    { value: 'Carrera', lable: t('tags.career') },
  ];

  const { data: nacionalidades } = useGetNacionalidadesQuery({
    acronym: 'NA',
  });

  const { data: paises } = useGetNacionalidadesQuery({
    acronym: 'PS',
  });

  const [tagTypeSelected, setTagTypeSelected] = useState<string | undefined>(
    tagToEdit ? tagToEdit.type_tag : undefined
  );

  const schema = yup.object().shape({
    status: yup.string().required('tags.status.validation'),
    typeTag: yup.string().required('tags.typetag.validation'),
    name: yup.string().required('tags.name.validation'),
    business_name: yup.string().when('typeTag', {
      is: (val: string) => val === 'Editorial',
      then: (schema) => schema.required('tags.editorial.validation'),
      otherwise: (schema) => schema.notRequired(),
    }),
    birthdate: yup.string(),
    deceasedate: yup.string(),
    // nacionality: yup.string().when('typeTag', {
    //   is: (val: string) => val === 'Personaje',
    //   then: (schema) => schema.required('tags.nacionality.validation'),
    //   otherwise: (schema) => schema.notRequired(),
    // }),
    // country: yup.string().when('typeTag', {
    //   is: (val: string) => val === 'Personaje',
    //   then: (schema) => schema.required('tags.country.validation'),
    //   otherwise: (schema) => schema.notRequired(),
    // }),
    nacionality: yup.string(),
    country: yup.string(),
    description: yup.string().when('typeTag', {
      is: (val: string) => val === 'Contenido',
      then: (schema) => schema.required('tags.description.validation'),
      otherwise: (schema) => schema.notRequired(),
    }),
  });

  const [
    addAuthor,
    {
      isLoading: isLoadingAdd,
      isSuccess: isSuccessAdd,
      isError: isErrorAdd,
      error: errorAdd,
      reset,
    },
  ] = useAddAutorMutation();

  const [
    editAuthor,
    {
      isLoading: isLoadingEdit,
      isSuccess: isSuccessEdit,
      isError: isErrorEdit,
      error: errorEdit,
      reset: resetEdit,
    },
  ] = useEditAuthorMutation();

  const formik = useFormik({
    initialValues: {
      status: tagToEdit?.status_id || '',
      typeTag: tagToEdit?.type_tag || '',
      name: tagToEdit?.name || '',
      business_name: tagToEdit?.business_name || '',
      birthdate: tagToEdit?.born_date || '',
      deceasedate: tagToEdit?.death_date || '',
      nacionality: tagToEdit?.nationality_id || '',
      country: tagToEdit?.country_id || '',
      description: tagToEdit?.description || '',
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      if (tagToEdit) {
        editAuthor({
          id: tagToEdit.id,
          payload: {
            name: values.name,
            status_id: values.status,
            type_tag: values.typeTag,
            born_date: values.birthdate,
            country_id: values.country,
            death_date: values.deceasedate,
            description: values.description,
            nationality_id: values.nacionality,
            business_name: values.business_name,
          },
        });
        return;
      }

      addAuthor({
        payload: {
          name: values.name,
          status_id: values.status,
          type_tag: values.typeTag,
          born_date: values.birthdate,
          country_id: values.country,
          death_date: values.deceasedate,
          description: values.description,
          nationality_id: values.nacionality,
          business_name: values.business_name,
        },
      });
    },
  });

  const handleSubmit = () => {
    formik.handleSubmit();
  };

  useEffect(() => {
    if (isSuccessAdd) {
      formik.resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessAdd]);

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Grid container item xs={12} md={4} direction="column" gap={2}>
          <Grid item xs={4} md={4}>
            <InputLabel>{t('tags.typetag')}</InputLabel>
            <Autocomplete
              options={tagsTypes}
              value={tagsTypes.find((tg) => tg.value === formik.values.typeTag)}
              disableClearable
              getOptionLabel={(row) => row.lable}
              renderInput={(props) => (
                <TextField
                  {...props}
                  placeholder={`${t('tags.select')} ${t('tags.typetag')}`}
                  error={Boolean(formik.errors.typeTag && formik.touched.typeTag)}
                  helperText={
                    Boolean(formik.errors.typeTag && formik.touched.typeTag)
                      ? t(String(formik.errors.typeTag))
                      : ''
                  }
                  {...formik.getFieldProps('typeTag')}
                />
              )}
              onChange={(e, val) => {
                setTagTypeSelected(val.value);
                formik.setFieldValue('typeTag', val.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <InputLabel>{t('tags.name')}</InputLabel>
            <TextField
              placeholder={`${t('tags.enter')} ${t('tags.name')}`}
              fullWidth
              //value={formik.values.name}
              error={Boolean(formik.errors.name && formik.touched.name)}
              helperText={
                Boolean(formik.errors.name && formik.touched.name)
                  ? t(String(formik.errors.name))
                  : ''
              }
              //onChange={({ target }) => formik.setFieldValue('name', target.value)}
              {...formik.getFieldProps('name')}
            />
          </Grid>
          {tagTypeSelected === 'Categoria' ||
            (tagTypeSelected === 'Contenido' && (
              <>
                <Grid item xs={12} md={4}>
                  <InputLabel>{t('tags.description')}</InputLabel>
                  <TextField
                    placeholder={`${t('tags.enter')} ${t('tags.description')}`}
                    fullWidth
                    //value={formik.values.description}
                    error={Boolean(formik.errors.description && formik.touched.description)}
                    helperText={
                      Boolean(formik.errors.description && formik.touched.description)
                        ? t(String(formik.errors.description))
                        : ''
                    }
                    //onChange={({ target }) => formik.setFieldValue('description', target.value)}
                    {...formik.getFieldProps('description')}
                  />
                </Grid>
              </>
            ))}
          {tagTypeSelected === 'Personaje' && (
            <>
              <Grid item xs={12} md={4}>
                <InputLabel>{t('tags.birthdate')}</InputLabel>
                <DatePicker
                  value={formik.values.birthdate || null}
                  onChange={(val) => {
                    formik.setFieldValue('birthdate', val);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      // placeholder={`${t('authors.form.placeholder.enter')} ${t(
                      //   'authors.form.birthdate'
                      // )}`}
                      error={Boolean(formik.errors.birthdate && formik.touched.birthdate)}
                      helperText={
                        Boolean(formik.errors.birthdate && formik.touched.birthdate)
                          ? t(String(formik.errors.birthdate))
                          : null
                      }
                      {...formik.getFieldProps('birthdate')}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <InputLabel>{t('tags.deceasedate')}</InputLabel>
                <DatePicker
                  value={formik.values.deceasedate || null}
                  onChange={(val) => {
                    formik.setFieldValue('deceasedate', val);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      // placeholder={`${t('authors.form.placeholder.enter')} ${t(
                      //   'authors.form.birthdate'
                      // )}`}
                      error={Boolean(formik.errors.deceasedate && formik.touched.deceasedate)}
                      helperText={
                        Boolean(formik.errors.deceasedate && formik.touched.deceasedate)
                          ? t(String(formik.errors.deceasedate))
                          : null
                      }
                      {...formik.getFieldProps('deceasedate')}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <InputLabel>{t('tags.nacionality')}</InputLabel>
                <Autocomplete
                  options={
                    nacionalidades?.children.map((na) => ({ value: na.id, label: na.cat_name })) ||
                    []
                  }
                  value={
                    nacionalidades?.children
                      .map((na) => ({ value: na.id, label: na.cat_name }))
                      .find((nc) => nc.value === Number(formik.values.nacionality)) || null
                  }
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={`${t('tags.select')} ${t('tags.nacionality')}`}
                      error={Boolean(formik.errors.nacionality && formik.touched.nacionality)}
                      helperText={
                        Boolean(formik.errors.nacionality && formik.touched.nacionality)
                          ? t(String(formik.errors.nacionality))
                          : null
                      }
                      {...formik.getFieldProps('nacionality')}
                    />
                  )}
                  //defaultValue={}
                  onChange={(_, value) => {
                    formik.setFieldValue('nacionality', value?.value);
                  }}
                  fullWidth
                  clearIcon={null}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <InputLabel>{t('tags.country')}</InputLabel>
                <Autocomplete
                  options={
                    paises?.children.map((na) => ({ value: na.id, label: na.cat_name })) || []
                  }
                  value={
                    paises?.children
                      .map((na) => ({ value: na.id, label: na.cat_name }))
                      .find((nc) => nc.value === Number(formik.values.country)) || null
                  }
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={`${t('tags.select')} ${t('tags.country')}`}
                      error={Boolean(formik.errors.country && formik.touched.country)}
                      helperText={
                        Boolean(formik.errors.country && formik.touched.country)
                          ? t(String(formik.errors.country))
                          : null
                      }
                      {...formik.getFieldProps('country')}
                    />
                  )}
                  //defaultValue={}
                  onChange={(_, value) => {
                    formik.setFieldValue('country', value?.value);
                  }}
                  fullWidth
                  clearIcon={null}
                />
              </Grid>
            </>
          )}
          {tagTypeSelected === 'Editorial' && (
            <>
              <Grid item xs={12} md={4}>
                <InputLabel>{t('tags.editorial')}</InputLabel>
                <TextField
                  placeholder={`${t('tags.enter')} ${t('tags.editorial')}`}
                  fullWidth
                  //value={formik.values.business_name}
                  error={Boolean(formik.errors.business_name && formik.touched.business_name)}
                  helperText={
                    Boolean(formik.errors.business_name && formik.touched.business_name)
                      ? t(String(formik.errors.business_name))
                      : ''
                  }
                  //onChange={({ target }) => formik.setFieldValue('business_name', target.value)}
                  {...formik.getFieldProps('business_name')}
                />
              </Grid>
            </>
          )}
          <Grid item xs={4} md={4}>
            <InputLabel>{t('tags.status')}</InputLabel>
            <Autocomplete
              options={statusAvailables.map((st) => ({
                value: st.id,
                label: st.name,
              }))}
              value={statusAvailables
                .map((st) => ({
                  value: st.id,
                  label: st.name,
                }))
                .find((st) => st.value === Number(formik.values.status))}
              disableClearable
              getOptionLabel={(row) => row.label}
              renderInput={(props) => (
                <TextField
                  {...props}
                  placeholder={`${t('tags.select')} ${t('tags.status')}`}
                  error={Boolean(formik.errors.status && formik.touched.status)}
                  helperText={
                    Boolean(formik.errors.status && formik.touched.status)
                      ? t(String(formik.errors.status))
                      : ''
                  }
                  {...formik.getFieldProps('status')}
                />
              )}
              onChange={(e, val) => {
                formik.setFieldValue('status', val.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            {isErrorAdd && <ErrorAlert message={errorAdd as string} handleDismiss={reset} />}

            {isErrorEdit && <ErrorAlert message={errorEdit as string} handleDismiss={resetEdit} />}

            {isSuccessAdd && (
              <SuccessAlert
                //message={t('tags.add.success.message')}
                message="Etiqueta creada correctamente"
                handleDismiss={reset}
              />
            )}

            {isSuccessEdit && (
              <SuccessAlert message="Etiqueta editada correctamente" handleDismiss={resetEdit} />
            )}
          </Grid>
          <Grid item container gap={1} xs={12} md={4} justifyContent="center">
            <Button
              variant="outlined"
              onClick={() => {
                history.goBack();
              }}
            >
              {t('shared.go_back_button.label')}
            </Button>
            <Button
              variant="contained"
              onClick={handleSubmit}
              disabled={isLoadingAdd || isLoadingEdit}
              endIcon={isLoadingAdd || (isLoadingEdit && <CircularProgress size={20} />)}
            >
              {tagToEdit ? t('shared.edit_button.tooltip') : t('shared.add_button.tooltip')}
            </Button>
          </Grid>
        </Grid>
      </LocalizationProvider>
    </>
  );
};

export default AddTagForm;
