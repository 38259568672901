import { Grid } from '@material-ui/core';
import { useMemo } from 'react';
import { useAppSelector } from '../../../../hooks/useRedux';
import { useGetSubjectStatusQuery } from '../../../shared/slices/catalogApi';
import { getSubjectStatusColor } from '../../academic-portfolio/helpers/colors';

const StatusCard = () => {
  const { currentProfile } = useAppSelector((state) => state.access);

  const { data: estados = [] } = useGetSubjectStatusQuery({
    profileId: currentProfile!.profileId,
    search: 'Materia_curso',
  });

  const estadosToMutate = useMemo(() => [...estados] || [], [estados]);

  return (
    <Grid item container xs={12} spacing={1} justifyContent="flex-end">
      {estadosToMutate.map((estado) => (
        <Grid item>
          <Grid container alignItems="center">
            <span
              style={{
                backgroundColor: getSubjectStatusColor(estado.st_name),
                display: 'inline-flex',
                width: '20px',
                height: '20px',
                borderRadius: '50%',
                marginRight: '5px',
              }}
            ></span>
            {estado.st_name}
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default StatusCard;
