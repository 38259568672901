import { Grid, Typography } from '@material-ui/core';
import { IconClock, IconMessage } from '@tabler/icons';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../../../../../hooks/useRedux';
import { useParams } from 'react-router-dom';
import SearchPaginatedTable from '../../../../../shared/components/tables/SearchPaginatedTable';
import { format } from 'date-fns';
import { useGetListForumByCourseQuery } from '../../../../../teachers/virtual-classroom/slices/virtualClassroomTeacherApiSlice';

interface Props {
  goToForumView: () => void;
  setForumId: React.Dispatch<React.SetStateAction<number>>;
}
const ForumList = (props: Props) => {
  const { goToForumView, setForumId } = props;
  const { currentProfile } = useAppSelector((state) => state.access);
  const { courseId } = useParams<{ courseId: string }>();

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [perPage, setPerPage] = useState(5);

  const { data, isLoading, isFetching, error } = useGetListForumByCourseQuery(
    {
      profileId: currentProfile?.profileId!,
      courseId: courseId!,
      page,
      perPage,
      search,
    },
    { refetchOnMountOrArgChange: 30 }
  );

  useEffect(() => {
    setPage(1);
  }, [perPage, search]);

  const datafetch = data?.data!.map((value) => ({
    data: {
      id: value.id,
      act_description: value.act_description,
      simbology_id: value.simbology_id,
      act_is_score: value.act_is_score,
      supply_id: value.supply_id,
      calification_model_id: value.calification_model_id,
      type_activity_id: value.type_activity_id,
      act_score_activity: value.act_score_activity,
      user_id: value.user_id,
      status_id: value.status_id,
      act_num_max_questions: value.act_num_max_questions,
      act_num_tries: value.act_num_tries,
      type_calc: value.type_calc,
      course_activity_one: value.course_activity_one,
      detail_forum: value.detail_forum,
      author: value.author,
      answers_activity_count: value.answers_activity_count,
      calification_models_distributions_id: value.calification_models_distributions_id,
    },
    options: '',
  }));
  const onClick = (id: number) => {
    goToForumView();
    setForumId(id);
  };
  return (
    <SearchPaginatedTable
      data={datafetch || []}
      headers={{
        data: 'Listado de foros',
        options: '',
      }}
      keyExtractor={(item) => item.data.id.toString()}
      searchPlacehoder="Buscar foro"
      isLoading={isLoading}
      isFetching={isFetching}
      error={error}
      perPage={perPage}
      setPerPage={setPerPage}
      total={data?.total || 0}
      page={page}
      setPage={setPage}
      numHeader={2}
      search={search}
      setSearch={setSearch}
      customRenderers={{
        data: ({ data }) => (
          <Grid container>
            <Grid container item direction="column">
              <Grid item>
                <Typography
                  variant="h4"
                  color="primary"
                  // onClick={goToForumView}
                >
                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      onClick(data.id);
                    }}
                  >
                    {data.act_description.charAt(0).toUpperCase() + data.act_description.slice(1)}
                  </span>
                </Typography>
              </Grid>
            </Grid>
            <Grid container item direction="column">
              <Typography>{'Descripcion'}</Typography>
            </Grid>

            <Grid container marginTop={1} alignItems="center" justifyContent="flex-start">
              <Grid
                item
                container
                alignItems="center"
                xl={2}
                xs={12}
                lg={3}
                sx={{ minWidth: '125px' }}
                gap={1}
              >
                <IconClock size={15} />

                <Typography>
                  {`Publicado el ` +
                    format(new Date(data.course_activity_one.cact_start_date), 'yyyy-MM-dd').split(
                      ' '
                    )[0]}
                </Typography>
              </Grid>

              <Grid
                item
                container
                alignItems="center"
                xs={12}
                xl={2}
                lg={3}
                sx={{ minWidth: '125px' }}
                gap={1}
              >
                <IconClock size={15} />

                <Typography>
                  {`Participar hasta ` +
                    format(new Date(data.course_activity_one.cact_end_date), 'yyyy-MM-dd').split(
                      ' '
                    )[0]}
                </Typography>
              </Grid>

              <Grid
                item
                container
                alignItems="center"
                xs={12}
                xl={2}
                lg={3}
                sx={{ minWidth: '50px' }}
                gap={1}
              >
                <IconMessage size={15} alignmentBaseline="central" colorRendering="black" />

                <Typography>{data.answers_activity_count}</Typography>
              </Grid>
            </Grid>
          </Grid>
        ),
      }}
      customHeadersCellsProperties={{
        options: {
          align: 'center',
          width: '10%',
        },
        data: {
          align: 'left',
        },
      }}
      customDataCellsProperties={{
        data: {
          align: 'left',
        },
        options: {
          align: 'center',
          width: '10%',
        },
      }}
    />
  );
};
export default ForumList;
