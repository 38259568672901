import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISearchTable } from '../../../shared/interfaces/paginatedTable.interface';
import { ExternalLink } from '../../../students/dashboard/interfaces/dashboard.interface';

interface EventNewState {
  externalLinksList: ExternalLink[];
  externalLinksTable: ISearchTable;
}

const initialState: EventNewState = {
  externalLinksList: [],
  externalLinksTable: {
    page: 1,
    perPage: 5,
    search: '',
  },
};

const eventNewSlice = createSlice({
  name: 'eventNew',
  initialState,
  reducers: {
    setExternalLinksList: (state, action: PayloadAction<ExternalLink[]>) => {
      state.externalLinksList = action.payload;
    },

    updateExternalLinksTable: (state, action: PayloadAction<ISearchTable>) => {
      state.externalLinksTable = action.payload;
    },
  },
});

export const { setExternalLinksList, updateExternalLinksTable } = eventNewSlice.actions;

export default eventNewSlice.reducer;
