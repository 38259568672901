export const SUPPORT_TYPES = ['application/PDF', 'application/pdf'];
//incluir imagenes de todos los tipos posibles
export const SUPPORT_TYPES_RM = [
  'application/PDF',
  'application/pdf',
  'application/jpg',
  'application/JPG',
  'application/jpeg',
  'application/JPEG',
  'application/gif',
  'application/GIF',
  'application/png',
  'application/PNG',
  'application/tiff',
  'application/TIFF',
];
