import { MenuItem, Typography } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import { MoreVertOutlined } from '@material-ui/icons';
import { useState } from 'react';

interface Props {
  dataMenu: {
    name: string;
    handleClick: () => void;
  }[];
}

const MenuMoreVert = ({ dataMenu }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Typography
        onClick={handleClick}
        sx={{
          cursor: 'pointer',
        }}
      >
        <MoreVertOutlined />
      </Typography>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {dataMenu.map((item) => (
          <MenuItem
            key={item.name}
            onClick={() => {
              item.handleClick();
              handleClose();
            }}
          >
            {item.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default MenuMoreVert;
