import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';
import QuestionForm from '../components/QuestionForm';
import { useLazyGetQuestionQuery } from '../slices/questionApiSlice';
import { setQuestionsIdSelected, setQuestionsList } from '../slices/questionSlice';
import BackButton from '../../../../../shared/components/buttons/BackButton';
import SimpleFormOneColumn from '../../../../../shared/components/forms/skeletons/SimpleFormOneColumn';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/useRedux';
import MainCard from '../../../../../shared/components/cards/MainCard';

const EditQuestionScreen = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  const { questionsIdSelected, questionsList } = useAppSelector((state) => state.questions);

  const [getQuestion, { isLoading }] = useLazyGetQuestionQuery();

  useEffect(() => {
    if (!questionsIdSelected && id) {
      dispatch(setQuestionsIdSelected(id!));
    }

    if (!questionsList?.length && id) {
      getQuestion({ id }).then((questions) => {
        dispatch(setQuestionsList([questions.data!]));
      });
    }
    return () => {
      dispatch(setQuestionsIdSelected(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid container alignItems="center" sx={{ mb: 3 }}>
        <BackButton />
        <Typography variant="h2">{t('maintenance.questions.edit.title')}</Typography>
      </Grid>
      {questionsIdSelected && id && questionsList.length && !isLoading ? (
        <QuestionForm />
      ) : (
        <SimpleFormOneColumn />
      )}
    </Grid>
  );
};

export default EditQuestionScreen;
