import * as yup from 'yup';

export const TaskInfoData = yup.object().shape({
  description: yup.string().trim().required('Descripción es requerida.'),
  numTries: yup.number().min(1, 'Número de intentos es requerido.'),
  actScoreActivity: yup
    .number()
    .nullable()
    .notRequired()
    .min(0, 'Puntaje de actividad es requerido.'),
  category_activity_id: yup.number().min(1, 'Categoría de actividad es requerido.'),
  distributionId: yup.number().nullable().notRequired(), //yup.number().min(1, 'Seleccione una distribución.'),
  startDate: yup
    .date()
    .min(new Date(), 'Fecha de inicio no permitida.')
    .required('Fecha de inicio es requerida.')
    .typeError('Fecha de inicio es requerida.'),
  endDate: yup
    .date()
    .min(new Date(), 'Fecha fin no permitida.')
    .required('Fecha fin es requerida.')
    .typeError('Fecha fin es requerida.'),
});

export const taskInfoEditSchema = yup.object().shape({
  description: yup.string().trim().required('Descripción es requerida.'),
  numTries: yup.number().min(1, 'Número de intentos es requerido.'),
  actScoreActivity: yup
    .number()
    .nullable()
    .notRequired()
    .min(0, 'Puntaje de actividad es requerido.'),
  type_activity_id: yup.number().min(1, 'Categoría de actividad es requerido.'),
  distributionId: yup.number().nullable().notRequired(), //yup.number().min(1, 'Seleccione una distribución.'),
  startDate: yup
    .date()
    .required('Fecha de inicio es requerida.')
    .typeError('Fecha de inicio es requerida.'),
  endDate: yup
    .date()
    .min(yup.ref('startDate'), 'Fecha fin no permitida.')
    .required('Fecha fin es requerida.')
    .typeError('Fecha fin es requerida.'),
});

export const TaskQuestionAnswerData = yup.object().shape({
  point: yup.number().min(0.25, 'Pregunta debería estar asociado a un puntaje.'),
  question: yup.string().trim().required('Descripción de pregunta es requerida.'),
});
