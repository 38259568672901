import { Grid } from '@material-ui/core';
import MainCard from '../../../shared/components/cards/MainCard';

import InstitutionInfoWizard from '../components/InstitutionInfoWizard';

// import InstitutionInfoList from '../components/InstitutionInfoList';

const InstitutionInfoScreen = () => {
  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <InstitutionInfoWizard />
      {/* <InstitutionInfoList /> */}
    </Grid>
  );
};

export default InstitutionInfoScreen;
