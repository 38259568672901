import DeleteAlert from '../../../shared/components/dialogs/DeleteAlert';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { useDeleteInstituteMutation } from '../slices/institutesApiSlice';
import { resetinstituteIdToDelete, setDeleteModalOpen } from '../slices/institutesSlice';

const DeletePeriodDialog = () => {
  const dispatch = useAppDispatch();

  const { deleteModalOpen, instituteIdToDelete } = useAppSelector((state) => state.institutes);
  const { currentProfile } = useAppSelector((state) => state.access);

  const [deletePeriod, { isLoading, isSuccess, isError, error }] = useDeleteInstituteMutation();

  const handleDelete = () => {
    deletePeriod({ profileId: currentProfile?.profileId!, instituteId: instituteIdToDelete! });
  };

  const handleClose = () => {
    dispatch(setDeleteModalOpen(false));
    dispatch(resetinstituteIdToDelete());
  };

  return (
    <DeleteAlert
      open={deleteModalOpen}
      onClose={handleClose}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      handleDelete={handleDelete}
      error={error as string}
    />
  );
};

export default DeletePeriodDialog;
