export const tabsInfoOptions = [
  {
    label: 'Misión y Visión',
    value: '1',
  },
  {
    label: 'Información Financiera',
    value: '2',
  },
  {
    label: 'Servicios',
    value: '3',
  },
];
