import { Grid, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../../hooks/useRedux';
import MainCard from '../../../../shared/components/cards/MainCard';
import { resetEvaluationState } from '../../slices/virtualClassroomSlice';
import FinishedDetail from '../components/FinishedDetail';

const EvaluationFinished = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  useEffect(
    () => () => {
      dispatch(resetEvaluationState());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  return (
    <React.Fragment>
      <Grid
        component={MainCard}
        sx={{
          borderTopLeftRadius: '0px',
          borderTopRightRadius: '0px',
        }}
      >
        <Grid sx={{ mb: 3 }}>
          <Typography variant="h2">{t('studentVirtualClassroom.menu.tests')}</Typography>
        </Grid>
        <FinishedDetail />
      </Grid>
    </React.Fragment>
  );
};

export default EvaluationFinished;
