import * as yup from 'yup';

export const GeneralDetailsSchema = yup.object().shape({
  syllabus_entries: yup.object().shape({
    description_justify: yup.string().required('Descripción y justificación requerida'),
    object_study: yup.string().required('Objetivo de estudio requerido'),
    object: yup.string().required('Objetivo requerido'),
    result_learning: yup.string().required('Resultado de aprendizaje requerido'),
    contents: yup.string().required('Contenido requerido'),
    metho_mean_evaluation: yup.string().required('Método y medio de evaluación requerido'),
  }),
});

export const LessonPlanSchema = yup.object().shape({
  syllabus_entries: yup.object().shape({
    lesson_plan: yup.string().required('Plan de lección requerido'),
  }),
});

export const BibliographySchema = yup.object().shape({
  syllabus_bibliogra_basic: yup
    .array()
    .of(
      yup.object().shape({
        book_name: yup.string().required('Nombre del libro requerido'),
        book_autor: yup.string().required('Autor del libro requerido'),
        book_editor: yup.string().required('Editorial del libro requerido'),
        book_id: yup.string().required('ID del libro requerido'),
      })
    )
    .min(1, 'Debe agregar al menos un libro'),
  syllabus_bibliogra_comple: yup.array().of(
    yup.object().shape({
      book_name: yup.string().required('Nombre del libro requerido'),
      book_autor: yup.string().required('Autor del libro requerido'),
      book_editor: yup.string().required('Editorial del libro requerido'),
    })
  ),
  syllabus_data_collaborator: yup.object().shape({
    collaborator_id: yup.string().required('ID del colaborador requerido'),
    college_education: yup.string().required('Educación requerida'),
    email_collaborator: yup.string().required('Correo electrónico requerido'),
    experience_area: yup.string().required('Experiencia requerida'),
    phone_collaborator: yup.string().required('Teléfono requerido'),
    postgrado: yup.string().required('Postgrado requerido'),
    specialty_area: yup.string().required('Especialidad requerida'),
  }),
});

export const SyllabusSchema = yup.object().shape({
  syllabus_entries: yup.object().shape({
    description_justify: yup.string().required('Descripción y justificación requerida'),
    object_study: yup.string().required('Objetivo de estudio requerido'),
    object: yup.string().required('Objetivo requerido'),
    result_learning: yup.string().required('Resultado de aprendizaje requerido'),
    contents: yup.string().required('Contenido requerido'),
    metho_mean_evaluation: yup.string().required('Método y medio de evaluación requerido'),
    lesson_plan: yup.string().required('Plan de lección requerido'),
  }),
  syllabus_bibliogra_basic: yup
    .array()
    .of(
      yup.object().shape({
        book_name: yup.string().required('Nombre del libro requerido'),
        book_autor: yup.string().required('Autor del libro requerido'),
        book_editor: yup.string().required('Editorial del libro requerido'),
        book_id: yup.string().required('ID del libro requerido'),
      })
    )
    .min(1, 'Debe agregar al menos un libro'),
  syllabus_bibliogra_comple: yup.array().of(
    yup.object().shape({
      book_name: yup.string().required('Nombre del libro requerido'),
      book_autor: yup.string().required('Autor del libro requerido'),
      book_editor: yup.string().required('Editorial del libro requerido'),
    })
  ),
  syllabus_data_collaborator: yup.object().shape({
    collaborator_id: yup.string().required('ID del colaborador requerido'),
    college_education: yup.string().required('Educación requerida'),
    email_collaborator: yup.string().required('Correo electrónico requerido'),
    experience_area: yup.string().required('Experiencia requerida'),
    phone_collaborator: yup.string().required('Teléfono requerido'),
    postgrado: yup.string().required('Postgrado requerido'),
    specialty_area: yup.string().required('Especialidad requerida'),
  }),
});
