import { forwardRef } from 'react';

import { Slide, SlideProps } from '@material-ui/core';

// animation to left
export const TransitionToLeft = forwardRef(function Transition(props: SlideProps, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

// animation to right
export const TransitionToRight = forwardRef((props: SlideProps) => (
  <Slide direction="right" {...props} />
));

// animation to top
export const TransitionToUp = forwardRef((props: SlideProps) => (
  <Slide direction="up" {...props} />
));

// animation to down
export const TransitionToDown = forwardRef((props: SlideProps) => (
  <Slide direction="down" {...props} />
));
