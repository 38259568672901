import * as Yup from 'yup';

export const UserProfileSchema = Yup.object().shape({
  profileId: Yup.number()
    .nullable()
    .min(1, 'configuration.users.profiles.form.profile.required')
    .required('configuration.users.profiles.form.profile.required'),
  email: Yup.string()
    .email('configuration.users.profiles.form.email.valid')
    .required('configuration.users.profiles.form.email.required'),
  statusId: Yup.number().required('configuration.users.profiles.form.status.required'),
});
