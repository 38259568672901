import * as Yup from 'yup';

export const PeriodFormConfigData = Yup.object().shape({
  periodTypeId: Yup.number()
    .min(1, 'academic.periods_list.add.stage_2.period_type.required')
    .required('academic.periods_list.add.stage_2.period_type.required'),
  name: Yup.string().required('academic.periods_list.add.stage_2.period_name.required'),
  minMatter: Yup.number()
    .integer('academic.periods_list.add.stage_2.subject_max.min.integer')
    .required('academic.periods_list.add.stage_2.subject_max.min.required')
    .min(1, 'academic.periods_list.add.stage_2.subject_max.min.min'),
  maxMatter: Yup.number()
    .integer('academic.periods_list.add.stage_2.subject_max.max.integer')
    .min(Yup.ref('minMatter'), 'academic.periods_list.add.stage_2.subject_max.max.min'),
  numFouls: Yup.number()
    .min(1, 'academic.periods_list.add.stage_2.num_faults.min')
    .required('academic.periods_list.add.stage_2.num_faults.required'),
  feesEnrollmentUnique: Yup.string(),
});

export const PeriodOfferAcademicData = Yup.object().shape({
  campusIds: Yup.array().of(Yup.number()).min(1, 'academic.periods_list.add.stage_1.campus.min'),
  academicOfferId: Yup.number()
    .min(1, 'academic.periods_list.add.stage_1.academic_offer.required')
    .required('academic.periods_list.add.stage_1.academic_offer.required'),
  hourhandsIds: Yup.array().min(1, 'Seleccione al menos un horario').required('Horario requerido'),
});

export const PeriodFormDatesData = Yup.object().shape({
  start_process: Yup.date()
    .typeError('Ingrese una fecha válida.')
    .required('La fecha de inicio es requerida.'),
  end_process: Yup.date()
    .typeError('Ingrese una fecha válida.')
    .required('La fecha de fin es requerida.')
    // .when('startDate', (startDate, schema) => startDate && schema.min(startDate)),
    .min(Yup.ref('start_process'), 'La fecha fin debe ser mayor a la fecha de inicio'),
});

export const StageGenerationQuote = Yup.object().shape({
  feesEnrollmentUnique: Yup.string(),
  additional_fees: Yup.array(),
  tp_pay_enrollment: Yup.string(),
  tp_num_fees: Yup.number(),
  numFees: Yup.number()
    .nullable()
    .required('Número de cuotas requerido')
    .min(0, 'Número de cuotas debe ser mínimo 0')
    // .when('feesEnrollmentUnique', {
    //   is: (feesEnrollmentUnique: string) => feesEnrollmentUnique === '1',
    //   then: Yup.number().min(1, 'Número de cuotas debe ser mínimo 1'),
    // }),
    .test({
      name: 'numFees',
      test: function (value) {
        const { parent, createError } = this;

        const tp_num_fees = parent.tp_num_fees;
        const tp_pay_enrollment = parent.tp_pay_enrollment;
        const feesEnrollmentUnique = parent.feesEnrollmentUnique;
        const numFees = Number(value || 0);

        console.log('tp_num_fees', tp_num_fees);
        console.log('tp_pay_enrollment', tp_pay_enrollment);
        console.log('feesEnrollmentUnique', feesEnrollmentUnique);

        if (
          tp_pay_enrollment === '1' &&
          feesEnrollmentUnique === '1' &&
          numFees < Number(tp_num_fees || 0) + 1
        ) {
          return createError({
            path: 'numFees',
            message: `Número de cuotas debe ser mínimo ${Number(tp_num_fees || 0) + 1}`,
          });
        }

        if (
          ((tp_pay_enrollment === '1' && feesEnrollmentUnique === '0') ||
            tp_pay_enrollment === '0') &&
          numFees < Number(tp_num_fees || 0)
        ) {
          return createError({
            path: 'numFees',
            message: `Número de cuotas debe ser mínimo ${Number(tp_num_fees || 0)}`,
          });
        }

        return true;
      },
    }),
  generation_quote: Yup.array().of(
    Yup.object().shape({
      start_date: Yup.date()
        .typeError('Ingrese una fecha válida.')
        .required('La fecha de inicio es requerida.'),
      end_date: Yup.date()
        .typeError('Ingrese una fecha válida.')
        .required('La fecha de fin es requerida.')
        .min(Yup.ref('start_date'), 'La fecha fin debe ser mayor a la fecha de inicio'),
    })
  ),
});

export const PeriodInfoSchema = Yup.object().shape({
  name: Yup.string().required('El nombre del periodo es requeridos.'),
  minMatter: Yup.number()
    .integer('Solo se permiten números enteros.')
    .required('La cantidad mínima de materias es requerida.')
    .min(1, 'La cantidad mínima de materias es 1.'),
  maxMatter: Yup.number()
    .integer('Solo se permiten números enteros.')
    .min(Yup.ref('minMatter'), 'Max. Materias debe ser mayor o igual al Min. Materias.'),
  statusId: Yup.number().required('El estado del periodo es requerido.'),
  // campusId: Yup.number().min(1, 'El campus es requerido.').required('El campus es requerido.'),
  campusIds: Yup.array().of(Yup.number()).min(1, 'Debe seleccionar al menos un campus.'),
  periodTypeId: Yup.number()
    .min(1, 'El tipo de periodo es requerido.')
    .required('El tipo de periodo es requerido.'),
  academicOfferId: Yup.number()
    .min(1, 'La oferta académica del periodo es requerida.')
    .required('La oferta académica del periodo es requerida.'),
  numFouls: Yup.number()
    .min(1, 'La cantidad de faltas no puede ser menor a cero.')
    .required('La cantidad de faltas es requerida.'),
  numFees: Yup.number()
    .min(1, 'La cantidad de cuotas debe ser un valor mayor a cero.')
    .required('La cantidad de cuotas es requerida.'),
  isPayEnrollment: Yup.boolean().required('Confirmar el pago de matrícula es requerido.'),
  feesPerEnrollement: Yup.number()
    .min(0, 'Debes ser un valor mayor o igual a cero')
    .max(Yup.ref('numFees'), 'Debe ser un valor menor al número de cuotas.'),
  start_process: Yup.date()
    .typeError('Ingrese una fecha válida.')
    .required('La fecha de inicio es requerida.'),
  end_process: Yup.date()
    .typeError('Ingrese una fecha válida.')
    .required('La fecha de fin es requerida.')
    // .when('startDate', (startDate, schema) => startDate && schema.min(startDate)),
    .min(Yup.ref('start_process'), 'La fecha fin debe ser mayor a la fecha de inicio'),
});

export const PeriodUpdateShema = Yup.object().shape({
  name: Yup.string().required('academic.periods_list.edit.name.required'),
  minMatter: Yup.number()
    .integer('academic.periods_list.edit.min_subject.integer')
    .required('academic.periods_list.edit.min_subject.required')
    .min(1, 'academic.periods_list.edit.min_subject.min'),
  maxMatter: Yup.number()
    .integer('academic.periods_list.edit.max_subject.integer')
    .min(Yup.ref('minMatter'), 'academic.periods_list.edit.max_subject.min'),
  statusId: Yup.number().required('academic.periods_list.edit.status.required'),
  // campusId: Yup.number().min(1, 'El campus es requerido.').required('El campus es requerido.'),
  campus: Yup.array().of(Yup.number()).min(1, 'academic.periods_list.edit.campus.min'),
  periodTypeId: Yup.number()
    .min(1, 'academic.periods_list.edit.period_type.required')
    .required('academic.periods_list.edit.period_type.required'),
  offers: Yup.array().of(Yup.number()).min(1, 'academic.periods_list.edit.edu_levels.min'),
  numFees: Yup.number()
    .min(0, 'academic.periods_list.edit.num_fees.min')
    .required('academic.periods_list.edit.num_fees.required'),
  isPayEnrollment: Yup.boolean().required('academic.periods_list.edit.enrollment.required'),
  feesPerEnrollement: Yup.number()
    .min(0, 'academic.periods_list.edit.quote_enrollment.min')
    .max(Yup.ref('numFees'), 'academic.periods_list.edit.quote_enrollment.max'),
  start_process: Yup.date()
    .typeError('academic.periods_list.edit.start_date.valid')
    .required('academic.periods_list.edit.start_date.required'),
  end_process: Yup.date()
    .typeError('academic.periods_list.edit.end_date.valid')
    .required('academic.periods_list.edit.end_date.required')
    // .when('startDate', (startDate, schema) => startDate && schema.min(startDate)),
    .min(Yup.ref('start_process'), 'academic.periods_list.edit.end_date.min'),
});

export const stageDates = Yup.object().shape({
  // stageAcronym: Yup.string().required('academic.periods_list.stages.add.stage.required'),
  // statusId: Yup.number().required('El estado es requerido.').min(1, 'El estado es requerido.'),
  // days_late: Yup.number().min(0, 'El numero de días debe ser mayor o igual a 0'),
  periodStages: Yup.array().of(
    Yup.object().shape({
      courseId: Yup.number().nullable(),
      startDate: Yup.date()
        .typeError('academic.periods_list.stages.add.date.valid')
        .required('academic.periods_list.stages.add.start.required'),
      endDate: Yup.date()
        .typeError('academic.periods_list.stages.add.date.valid')
        .required('academic.periods_list.stages.add.end.required')
        // .when('startDate', (startDate, schema) => startDate && schema.min(startDate)),
        .min(Yup.ref('startDate'), 'academic.periods_list.stages.add.end.min'),
    })
  ),

  calificationModelDates: Yup.object().shape({
    calification_model_id: Yup.number(),
    distributions: Yup.array().of(
      Yup.object().shape({
        ref1_supply_id: Yup.number().required('El tipo de calificación es requerido.'),
        supplies: Yup.array().of(
          Yup.object().shape({
            ref2_supply_id: Yup.number().required('El tipo de calificación es requerido.'),
            startDate: Yup.date()
              .typeError('academic.periods_list.stages.add.date.valid')
              .required('academic.periods_list.stages.add.start.required'),
            endDate: Yup.date()
              .typeError('academic.periods_list.stages.add.date.valid')
              .required('academic.periods_list.stages.add.end.required')
              // .when('startDate', (startDate, schema) => startDate && schema.min(startDate)),
              .min(Yup.ref('startDate'), 'academic.periods_list.stages.add.end.min'),
          })
        ),
      })
    ),
  }),
});

export const StagePeriodAssignmentSchema = Yup.object().shape({
  // stageAcronym: Yup.string().required('academic.periods_list.stages.add.stage.required'),
  // statusId: Yup.number().required('El estado es requerido.').min(1, 'El estado es requerido.'),
  // days_late: Yup.number().min(0, 'El numero de días debe ser mayor o igual a 0'),
  periodStages: Yup.array().of(
    Yup.object().shape({
      courseId: Yup.number().nullable(),
      startDate: Yup.date()
        .typeError('academic.periods_list.stages.add.date.valid')
        .required('academic.periods_list.stages.add.start.required'),
      endDate: Yup.date()
        .typeError('academic.periods_list.stages.add.date.valid')
        .required('academic.periods_list.stages.add.end.required')
        // .when('startDate', (startDate, schema) => startDate && schema.min(startDate)),
        .min(Yup.ref('startDate'), 'academic.periods_list.stages.add.end.min'),
    })
  ),

  calificationModelDates: Yup.object().shape({
    calification_model_id: Yup.number(),
    distributions: Yup.array().of(
      Yup.object().shape({
        ref1_supply_id: Yup.number().required('El tipo de calificación es requerido.'),
        supplies: Yup.array().of(
          Yup.object().shape({
            ref2_supply_id: Yup.number().required('El tipo de calificación es requerido.'),
            startDate: Yup.date()
              .typeError('academic.periods_list.stages.add.date.valid')
              .required('academic.periods_list.stages.add.start.required'),
            endDate: Yup.date()
              .typeError('academic.periods_list.stages.add.date.valid')
              .required('academic.periods_list.stages.add.end.required')
              // .when('startDate', (startDate, schema) => startDate && schema.min(startDate)),
              .min(Yup.ref('startDate'), 'academic.periods_list.stages.add.end.min'),
          })
        ),
      })
    ),
  }),
});

export const StagePeriodAssignmentSchemaOld = Yup.object().shape({
  stageId: Yup.number().required('La etapa es requerida.').min(1, 'La etapa es requerida.'),
  statusId: Yup.number().required('El estado es requerido.').min(1, 'El estado es requerido.'),
  startDate: Yup.date()
    .typeError('Ingrese una fecha válida.')
    .required('La fecha de inicio es requerida.'),
  endDate: Yup.date()
    .typeError('Ingrese una fecha válida.')
    .required('La fecha de fin es requerida.')
    // .when('startDate', (startDate, schema) => startDate && schema.min(startDate)),
    .min(Yup.ref('startDate'), 'La fecha fin debe ser mayor a la fecha de inicio'),
});

export const ImportHourhandsSchema = Yup.object().shape({
  hourhands: Yup.array()
    .of(Yup.number())
    .min(1, 'Escoger un periodo que tenga al menos un horario.'),
});
