import Grid from '@material-ui/core/Grid';
import PaymentIcon from '@material-ui/icons/Payment';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import { IconButton, Tooltip } from '@material-ui/core';
import SubCard from '../../../../shared/components/cards/SubCard';
import ErrorAlert from '../../../../shared/components/alerts/ErrorAlert';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface Props {
  isError: boolean;
  error: any;
}

const NoticeRequirements = ({ isError, error }: Props) => {
  const { t } = useTranslation();

  const { studentId } = useParams<{ studentId: string }>();
  const history = useHistory();
  const errorArray = (error + '')?.split('|');

  return (
    <Grid container item justifyContent="center" mt={3} mb={3} spacing={2}>
      <Grid item>
        <SubCard title="Requisitos:" darkTitle={true}>
          <Grid item>
            {isError && (
              <>
                <ErrorAlert
                  message={errorArray[0]}
                  type="fixed"
                  action={
                    !studentId && (
                      <>
                        {/* <IconButton
                          size="small"
                          color="inherit"
                          onClick={() => history.push('/estado-de-cuenta')}
                        >
                          <PaymentIcon fontSize="large" />
                        </IconButton> */}
                      </>
                    )
                  }
                />
                {!studentId && errorArray.length === 1 && (
                  <Grid container justifyContent={'center'} alignItems={'center'}>
                    {t('students.subjects.registration.pay_to_continue')}
                    <ArrowForwardIcon fontSize="small" sx={{ mx: 2 }} />
                    <Tooltip title={t('students.subjects.registration.pay_to_continue.tooltip')}>
                      <IconButton
                        size="medium"
                        color="primary"
                        onClick={() => history.push('/estado-de-cuenta')}
                      >
                        <PaymentIcon
                          fontSize="large"
                          sx={{
                            '@keyframes float ': {
                              '0%': {
                                // boxShadow: '0 5px 15px 0px rgba(0,0,0,0.6)',
                                transform: 'translatey(0px)',
                              },
                              '50%': {
                                // boxShadow: '0 25px 15px 0px rgba(0,0,0,0.2)',
                                transform: 'translatey(-5px)',
                              },
                              '100%': {
                                // boxShadow: '0 5px 15px 0px rgba(0,0,0,0.6)',
                                transform: 'translatey(0px)',
                              },
                            },
                            animation: 'float 1.5s ease-in-out infinite',
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </SubCard>
      </Grid>
    </Grid>
  );
};

export default NoticeRequirements;
