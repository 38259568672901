// import { useState } from 'react';
import { Grid, Button } from '@material-ui/core';
import { IconArrowNarrowLeft, IconArticle } from '@tabler/icons';
import { useHistory, useParams } from 'react-router-dom';
import { useTheme, Typography } from '@material-ui/core';

import MainCard from '../../../../shared/components/cards/MainCard';
import WizardPublications from './wizard-publications';

const addPublication = {
  label: 'Crear Publicación',
  Icon: IconArticle,
  url: 'publicacion',
};

const steps = [addPublication];

const AddInvestigationLinesScreen = () => {
  const history = useHistory();

  // const { path } = useRouteMatch();

  const { caseKey } = useParams<{ caseKey: string }>();

  const theme = useTheme();

  // const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

  // const activeStep = steps.findIndex((step) => step.url === caseKey) || 0;

  // const [completed, setCompleted] = useState<{
  //   [k: number]: boolean;
  // }>({});

  // const handleStep = (step: number) => () => {
  //   history.push(path.replace(':caseKey', steps[step].url || addPublication.url));
  //   // setActiveStep(step);
  // };

  // console.log(path);

  // console.log(setCompleted);

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Button
          variant="text"
          onClick={() => history.push('/publicaciones')}
          startIcon={<IconArrowNarrowLeft />}
        >
          Ir a la página principal
        </Button>
      </Grid>
      {/* <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={10}>
          <Stepper
            nonLinear
            activeStep={activeStep}
            orientation={matchUpMd ? 'horizontal' : 'vertical'}
          >
            {steps.map(({ label, Icon }, index) => (
              <Step key={label} completed={completed[index]}>
                <StepButton
                  color="inherit"
                  onClick={handleStep(index)}
                  icon={
                    <Icon
                      style={
                        index === activeStep
                          ? {
                              backgroundColor: theme.palette.primary.light,
                              borderRadius: '100%',
                              padding: '5px',
                              color: theme.palette.primary.main,
                            }
                          : {}
                      }
                    />
                  }
                >
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </Grid>
      </Grid> */}
      <Grid container justifyContent="center" alignItems="center" mt={4}>
        <Grid item container m={2} justifyContent="center" alignItems="center">
          {steps.map(({ url, Icon }) => {
            if (url === caseKey) return <Icon size={40} color={theme.palette.primary.main} />;
            else return '';
          })}
        </Grid>

        <Grid item container justifyContent="center" alignItems="center">
          <Typography fontWeight={2} variant="h3" color="initial">
            {steps.map(({ url, label }) => {
              if (url === caseKey) return label;
              else return '';
            })}
          </Typography>
        </Grid>

        <Grid item xs={6} mt={4}>
          {addPublication.url === caseKey && <WizardPublications />}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AddInvestigationLinesScreen;
