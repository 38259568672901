import { useTranslation } from 'react-i18next';
import { Button, CircularProgress, Grid, Typography } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { useFormik } from 'formik';

import React, { useEffect, useState } from 'react';
import ErrorAlert from '../../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../../shared/components/alerts/SuccessAlert';
import { useAddMaterialsMutation } from '../../slices/virtualClassroomTeacherApiSlice';
import { MaterialLinkSchema } from '../constants/validationSchema';
import InputModals from './InputModalsInputModals';

import ModalImport from './ModalImport';
import { useAppSelector } from '../../../../../hooks/useRedux';
//import theme from '../../../../shared/themes';

const ModalClassroomVideo: React.FC<{
  Open: any;
  setOpen: React.Dispatch<React.SetStateAction<any>>;
}> = ({ Open, setOpen }) => {
  /*States */
  const [showAlerts, setShowAlerts] = useState(false);
  const { t } = useTranslation();

  const { course } = useAppSelector((state) => state.virtualClassroomTeacherShared);

  const [addMaterials, { isLoading, isError, isSuccess, error }] = useAddMaterialsMutation();

  const {
    values: { description, url },
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm,
    errors,
    touched,
  } = useFormik({
    initialValues: {
      description: '',
      url: '',
    },

    validationSchema: MaterialLinkSchema,

    onSubmit: () => {
      AddMaterial();
      resetForm();
    },
  });

  async function AddMaterial() {
    await addMaterials({
      courseId: Open.id,
      PayloadMaterials: {
        description: description,
        link_external: url,
        course_id: course.courseId,
      },
    });
    setShowAlerts(true);
  }

  useEffect(() => {
    if (isSuccess) {
      resetForm();
      setOpen({ id: 0, modal: false, nameFolder: '' });
      setFieldValue('description', '');
      setFieldValue('url', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  /*Formik */

  return (
    <ModalImport Open={Open.modal} title={t('teacherVirtualClassroom.materials.modal.link.title')}>
      <>
        <Grid container mb={2}>
          <Grid item xs={12}>
            {isError && showAlerts && (
              <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
            )}
          </Grid>
          {isSuccess && showAlerts && (
            <SuccessAlert
              type="snackbar"
              message={t('teacherVirtualClassroom.materials.success')}
              handleDismiss={() => setShowAlerts(false)}
            />
          )}
        </Grid>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} justifyContent={'space-between'}>
            <Grid item xs={12} md={5}>
              <Grid mb={1.2}>
                <Typography fontWeight={'bold'} mb={'10px'} color="#657F98">
                  {t('teacherVirtualClassroom.materials.modal.file.input.folder')}
                </Typography>
                <Typography fontWeight={'bold'} mb={'5px'} color="black">
                  {'>'}
                  {Open.nameFolder}
                </Typography>
              </Grid>

              <InputModals
                placeholder={t(
                  'teacherVirtualClassroom.materials.modal.file.input.material.placeholder'
                )}
                Title={t('teacherVirtualClassroom.materials.modal.file.input.material')}
                setValue={handleChange}
                name={'description'}
                error={Boolean(errors.description && touched.description)}
                messageError={errors.description}
              />
            </Grid>

            <Grid container item xs={12} md={7} justifyContent="center" alignItems="flex-end">
              <InputModals
                placeholder={t(
                  'teacherVirtualClassroom.materials.modal.file.input.link.placeholder'
                )}
                Title={'Url'}
                setValue={handleChange}
                name={'url'}
                error={Boolean(errors.url && touched.url)}
                messageError={errors.url}
              />
            </Grid>
          </Grid>
          <Grid justifyContent={'center'} mt={'20px'} textAlign="center">
            <Button
              onClick={() => setOpen({ ...Open, modal: false })}
              style={{ marginRight: '30px', borderRadius: '30px' }}
              variant="outlined"
            >
              {t('teacherVirtualClassroom.materials.modal.button.regresar')}
            </Button>
            <LoadingButton
              loading={isLoading}
              type="submit"
              style={{ borderRadius: '30px' }}
              variant="contained"
              loadingPosition="end"
              endIcon={isLoading && <CircularProgress size={20} />}
            >
              {t('teacherVirtualClassroom.materials.tooltips.crear.link')}
            </LoadingButton>
          </Grid>
        </form>
      </>
    </ModalImport>
  );
};

export default ModalClassroomVideo;
