import {
  Button,
  TextField,
  Grid,
  Typography,
  FormHelperText,
  CircularProgress,
  InputLabel,
  Pagination,
  IconButton,
  Stack,
} from '@material-ui/core';
import { useAppDispatch, useAppSelector } from '../../../../../../../hooks/useRedux';
import {
  setTaskChooseGroup,
  setTaskQuestions,
  setTaskTypeQuestion,
  setBackStep,
  setEditTask,
  setRemoveTask,
  ResetTaskEdit,
} from '../../../../slices/virtualClassroomTeacherSlice';
import { useFormik } from 'formik';
import { useState } from 'react';
import { IconCheckbox, IconTrash } from '@tabler/icons';
import { useUpodateTaskMutation } from '../../../../slices/virtualClassroomTeacherApiSlice';
import SubCard from '../../../../../../shared/components/cards/SubCard';
import ErrorAlert from '../../../../../../shared/components/alerts/ErrorAlert';
//import moment from 'moment';
import { useGetCatalogByAcronymChildrenQuery } from '../../../../../../shared/slices/catalogApi';
import { TaskQuestionAnswerData } from '../../../constants/validationSchemas';
import SuccessAlert from '../../../../../../shared/components/alerts/SuccessAlert';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

const CompleteQuestionsEdit = () => {
  const dispatch = useAppDispatch();

  const History = useHistory();

  console.log(History);

  const { t } = useTranslation();

  const [showAlert, setShowAlert] = useState<boolean>(false);

  const { userInfo } = useAppSelector((state) => state.auth);
  console.log(userInfo);

  const [Page, setPage] = useState(1);

  const [pointInvalid, setPointInvalid] = useState(false);

  const [optionNotSelected, setOptionNotSelected] = useState(false);

  const [typeQuestionNoSelected, setTypeQuestionNoSelected] = useState(false);

  const { currentProfile } = useAppSelector((state) => state.access);

  const [AñadirPregunta, setAñadirPregunta] = useState(false);

  interface ArrayTasks {
    id?: string;
    dta_question: string;
    dta_score_evaluated: string;
    catalogo_id?: string;
    is_manual?: number;
  }

  const {
    course: { courseId },
  } = useAppSelector((state) => state.virtualClassroomTeacherShared);

  // Obtener data almacenada.
  const { taskToCreate, taskToEdit, TaskEdit } = useAppSelector(
    (state) => state.virtualClassroomTeacher
  );

  /**
   * Manejo de la lista de opciones dinámicas.
   * La propiedad correct se puede utilizar más luego para poder seleccionar la opción correcta.
   */
  const [optionsList, setOptionsList] = useState([{ option: 'Escribe opción...', correct: false }]);

  const { values, errors, touched, handleChange, resetForm } = useFormik({
    initialValues: {
      question: '',
      point: 0,
    },
    validationSchema: TaskQuestionAnswerData,
    onSubmit: () => {
      handleSubmitForm();
      resetForm();
    },
  });

  const [EstadoGlobalTasks] = useState<ArrayTasks[]>();

  const [UpdatTask, { isLoading, isError, error, isSuccess }] = useUpodateTaskMutation();

  /**
   * Obtener tipos de respuesta.
   */
  const { data: dataTypeQuestion } = useGetCatalogByAcronymChildrenQuery({
    acronym: 'tipo-respuesta',
    profileId: currentProfile?.profileId!,
  });
  console.log(courseId);

  const { idtarea } = useParams<{ idtarea: string }>();

  const handleSubmitForm = async () => {
    await UpdatTask({
      taskId: Number(idtarea),
      courseId: courseId,
      updateTaskPayload: {
        act_description: taskToCreate?.act_description!,
        supply_id:
          String(taskToCreate?.supply_id!) === '0' ? null : String(taskToCreate?.supply_id!),
        act_is_score: String(taskToCreate?.act_is_score!),
        act_score_activity: String(taskToCreate?.act_score_activity),
        act_num_tries: String(taskToCreate?.act_num_tries!),
        calification_models_distributions_id:
          String(taskToCreate?.calification_models_distributions_id!) === '0'
            ? null
            : String(taskToCreate?.calification_models_distributions_id!),
        course_activity: {
          cact_start_date: taskToEdit?.course_activity?.cact_start_date!,
          cact_end_date: taskToEdit?.course_activity?.cact_end_date!,
          cact_is_extemporaneous: Number(taskToEdit?.course_activity?.cact_is_extemporaneous),
        },
        course_student_add: [],
        course_student_remove: [],
        detail_add: taskToCreate?.detail!,
        detail_remove: taskToEdit?.detail_remove!,
        detail_edit: TaskEdit,
        visible_note: '1',
        visible: '1',
      },
    })
      /*await createTask({
      userId: userInfo.collaboratorInfo?.id!,
      courseId: courseId,
      payload: {
        act_description: taskToCreate?.act_description!,
        supply_id: taskToCreate?.supply_id!,
        act_is_score: taskToCreate?.act_score_activity!,
        type_activity_id: taskToCreate?.type_activity_id!,
        act_score_activity: taskToCreate?.act_score_activity!,
        act_num_tries: taskToCreate?.act_num_tries!,
        calification_models_distributions_id: taskToCreate?.supply_id!,
        status_id: taskToCreate?.status_id!,
        category_activity_id: taskToCreate?.category_activity_id!,
        course_activity: {
          cact_start_date: moment(new Date(taskToCreate?.course_activity?.cact_start_date!)).format(
            'YYYY-MM-DD HH:mm:ss'
          ),
          cact_end_date: moment(new Date(taskToCreate?.course_activity?.cact_end_date!)).format(
            'YYYY-MM-DD HH:mm:ss'
          ),
          cact_is_extemporaneous: taskToCreate?.course_activity?.cact_is_extemporaneous!,
        },
        course_student: taskToCreate?.course_student?.map((e) => ({
          grupo: e.grupo + 1,
          student_id: e.student_id,
        }))!,

        detail: taskToCreate?.detail!,
        materials: [],
        visible_note: taskToCreate?.visible_note,
        visible: taskToCreate?.visible,
      },
    })**/
      .then((data) => console.log('payload', data))
      .catch((err) => console.log(err));

    if (isSuccess) {
      //History.goBack();
      resetForm();
      dispatch(ResetTaskEdit());
    }

    if (!isError) {
      resetForm();
      dispatch(ResetTaskEdit());

      //History.goBack();

      //History.goBack();
    }
    setShowAlert(true);
  };

  /**
   * Seleccionar estudiantes y agruparlos.
   * Desactivar las pantallas de completado de información
   * y selección de preguntas.
   */
  const handleChooseGroup = () => {
    dispatch(setBackStep(1));
    dispatch(setTaskChooseGroup(true));
  };

  /*const handleSelectCatalog = (): number => {
    const optionsSelectedPrevious = taskToCreate?.typeQuestion!;
    return dataTypeQuestion?.find((e) => e.cat_name === optionsSelectedPrevious)?.id!;
  };*/

  /**
   * Suma de los puntos de la actividades de todas las preguntas almacenadas.
   */
  const sumPointsActivity = (): number => {
    return taskToCreate?.detail?.reduce((acc, e) => acc + e?.dta_score_evaluated!, 0)!;
  };

  const sumaEdiciones = (): number => {
    let sumaAcc = 0;
    TaskEdit.forEach((item) => {
      sumaAcc += Number(item.dta_score_evaluated);
    });
    return sumaAcc;
  };

  const SUMPOINT = (): number => {
    let acum = 0;

    taskToEdit?.Detail_activity?.forEach((item) => {
      const fue_editado = TaskEdit.find(
        (tareaeditada) => Number(tareaeditada.id) === Number(item.id)
      );
      if (!fue_editado) {
        acum += Number(item.dta_score_evaluated);
        //item.dta_score_evaluated = '0';
      }
    });
    return acum;
  };

  const checkIfPointIsAvailable = (): boolean => {
    const totalPoint = sumPointsActivity() + SUMPOINT() + sumaEdiciones() + values?.point!;

    if (totalPoint > Number(taskToCreate?.act_score_activity)) {
      setPointInvalid(true);
      return false;
    }

    return true;
  };

  //  function CrearTarea() {}

  function EditarTarea(id: string, descripcion: string, score: string) {
    const actividad = Number(score);

    if (TaskEdit?.length > 0) {
      const finEdit = TaskEdit.find((item) => item.id === id);
      const totalPoint =
        sumPointsActivity() +
        SUMPOINT() +
        sumaEdiciones() +
        values?.point! -
        Number(finEdit?.dta_score_evaluated);

      if (totalPoint > Number(taskToCreate?.act_score_activity)!) {
        return setPointInvalid(true);
      }
      dispatch(setEditTask({ id: id, dta_question: descripcion, dta_score_evaluated: score }));
    }
    const pointWhitEdit =
      sumPointsActivity() + SUMPOINT() + sumaEdiciones() + values?.point! - actividad;

    if (pointWhitEdit < taskToCreate?.act_score_activity!) {
      dispatch(setEditTask({ id: id, dta_question: descripcion, dta_score_evaluated: score }));
    } else {
      return setPointInvalid(true);
    }

    EstadoGlobalTasks?.push({
      dta_question: descripcion,
      dta_score_evaluated: score,
      id: id,
    });

    /*   if (checkOfExistEdit(id)) {
      const restante =
        sumPointsActivity() + SUMPOINT() + sumaEdiciones() - taskToCreate?.act_score_activity!;
      console.log('restante', restante);
      const ValorUsar: boolean = restante + values?.point! >= taskToCreate?.act_score_activity!;
      if (!ValorUsar) {
        dispatch(setEditTask({ id: id, dta_question: descripcion, dta_score_evaluated: score }));
      } else {
        setPointInvalid(true);
      }
    }
    if (checkIfPointIsAvailable()) {
      dispatch(setEditTask({ id: id, dta_question: descripcion, dta_score_evaluated: score }));
    }*/
  }

  function SumaTotal(): number {
    const totalPoint = sumPointsActivity() + SUMPOINT() + sumaEdiciones();
    return totalPoint;
  }
  const checkIfTypeQuestionIsSelected = (): boolean => {
    if (taskToCreate?.typeQuestion === '' || taskToCreate?.typeQuestion === undefined) {
      setTypeQuestionNoSelected(true);
      return false;
    }
    return true;
  };

  const checkIfAnswerSelected = (): boolean => {
    if (taskToCreate?.typeQuestion === 'commentary' || taskToCreate?.typeQuestion === 'Imagen')
      return true;

    const isSelected = optionsList.reduce<number>(
      (acc, ele) => (ele.correct === true ? acc + 1 : acc),
      0
    );
    if (isSelected === 0) {
      setOptionNotSelected(true);
      return false;
    }

    return true;
  };

  const checkTypeQuestionId = (): number => {
    const typeQuestion = taskToCreate?.typeQuestion!;
    if (typeQuestion === 'commentary') {
      return dataTypeQuestion?.find((e) => e.cat_name === 'Comentario')?.id!;
    } else if (typeQuestion === 'Imagen') {
      return dataTypeQuestion?.find((e) => e.cat_name === 'Imagen')?.id!;
    }
    return dataTypeQuestion?.find((e) => e.cat_name === 'Comentario')?.id!;
  };

  const handleSaveQuestion = () => {
    const typeAnswerId = checkTypeQuestionId();

    const someAnswerSelected = checkIfAnswerSelected();
    if (!someAnswerSelected) return false;

    const typeQuestionSelected = checkIfTypeQuestionIsSelected();
    if (!typeQuestionSelected) return false;

    const sumAllpointValid = checkIfPointIsAvailable();
    if (!sumAllpointValid) return false;

    const typeQuestion = taskToCreate?.typeQuestion!;
    const data = {
      dta_question: values.question,
      dta_score_evaluated: values.point,
      catalogo_id: typeAnswerId,
      is_manual: typeQuestion === 'commentary' ? 1 : 0,
    };

    //setValue((itemprev) => itemprev + 1);

    dispatch(setTaskQuestions(data));
    // resetForm();
    setAñadirPregunta(false);
    setOptionsList([]);
    EstadoGlobalTasks?.push({
      dta_question: String(values.question),
      dta_score_evaluated: String(values.point),
      catalogo_id: String(typeAnswerId),
      is_manual: typeQuestion === 'commentary' ? 1 : 0,
    });
    resetForm();
  };

  function RemoveTask(id: number, index: number) {
    dispatch(setRemoveTask(id));
    EstadoGlobalTasks?.splice(index, 1);
  }

  console.log(values.point);

  const handleSetTyQuestion = (typeQuestion: string) => {
    dispatch(setTaskTypeQuestion(typeQuestion));
  };

  return (
    <Grid margin={'15px 0'}>
      <Grid container justifyContent={'Center'}>
        <Typography variant="h2" pr={'15px'}>
          {' '}
          {t('teacherVirtualClassroom.tasks.questions.puntaje.total')}:{' '}
          {taskToCreate?.act_score_activity}
        </Typography>
        <Typography variant="h2">
          {' '}
          {t('teacherVirtualClassroom.tasks.questions.puntaje.restante')}: {SumaTotal()}
        </Typography>
      </Grid>
      <Grid
        container
        item
        xs={12}
        mt={'12px'}
        spacing={2}
        justifyContent="center"
        alignContent="center"
      >
        {taskToEdit?.Detail_activity?.map((itemTask, index) => {
          return index + 1! === Page ? (
            <>
              <Grid item xs={8} md={8}>
                <Grid component={SubCard} title="Complete preguntas y opciones">
                  {isSuccess && showAlert && (
                    <Grid container justifyContent="space-between" item sx={{ mb: 3 }}>
                      <Grid item xs={12}>
                        <SuccessAlert
                          message={t('teacherVirtualClassroom.tasks.questions.success')}
                          handleDismiss={() => setShowAlert(false)}
                        />
                      </Grid>
                    </Grid>
                  )}

                  {isError && showAlert && (
                    <Grid container justifyContent="space-between" item sx={{ mb: 3 }}>
                      <Grid item xs={12}>
                        <ErrorAlert
                          message={error as string}
                          handleDismiss={() => setShowAlert(false)}
                        />
                      </Grid>
                    </Grid>
                  )}

                  {pointInvalid && (
                    <Grid container justifyContent="space-between" item sx={{ mb: 3 }}>
                      <Grid item xs={12}>
                        <ErrorAlert
                          message={`Total de puntos de actividad no puede ser mayor a ${taskToCreate?.act_score_activity} puntos`}
                          handleDismiss={() => setPointInvalid(false)}
                        />
                      </Grid>
                    </Grid>
                  )}

                  {typeQuestionNoSelected && (
                    <Grid container justifyContent="space-between" item sx={{ mb: 3 }}>
                      <Grid item xs={12}>
                        <ErrorAlert
                          message={`Tipo de pregunta no seleccionada.`}
                          handleDismiss={() => setTypeQuestionNoSelected(false)}
                        />
                      </Grid>
                    </Grid>
                  )}

                  {optionNotSelected && (
                    <Grid container justifyContent="space-between" item sx={{ mb: 3 }}>
                      <Grid item xs={12}>
                        <ErrorAlert
                          message={`Seleccione al menos una respuesta.`}
                          handleDismiss={() => setOptionNotSelected(false)}
                        />
                      </Grid>
                    </Grid>
                  )}

                  <Grid container justifyContent={'center'}>
                    <Grid item>
                      <Typography variant="h5" component="div" sx={{ mb: 1 }}>
                        {t('teacherVirtualClassroom.tasks.questions.puntaje.question')}
                      </Typography>
                      <TextField
                        id="button-action-search"
                        fullWidth
                        placeholder={'8'}
                        label="Puntaje"
                        onChange={handleChange}
                        InputProps={{ inputProps: { min: 0.5, step: 0.5 } }}
                        type="number"
                        name="point"
                        defaultValue={Number(itemTask.dta_score_evaluated)}
                        //{...getFieldProps('point')}
                        variant="outlined"
                      />
                      {errors.point && touched.point && (
                        <FormHelperText error>{errors.point}</FormHelperText>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container justifyContent="center" item>
                    <IconButton onClick={() => RemoveTask(Number(itemTask.id), index)}>
                      <IconTrash />
                    </IconButton>
                    {/* Container left */}
                    <Grid item xs={12}>
                      <Grid
                        style={{
                          borderTop: '4px solid #065CAB"',
                          borderRadius: '15px',
                          paddingBottom: '15px',
                        }}
                      >
                        <Grid item xs={12} sx={{ mt: 3 }}>
                          <InputLabel>
                            {t('teacherVirtualClassroom.tasks.questions.input.title')}
                          </InputLabel>
                          <TextField
                            multiline
                            rows={4}
                            style={{ height: '120px' }}
                            fullWidth
                            defaultValue={itemTask.dta_question}
                            error={Boolean(errors.question && touched.question)}
                            placeholder="Ingrese pregunta..."
                            name="question"
                            onChange={handleChange}
                            // {...getFieldProps('question')}
                          />
                          {errors.question && touched.question && (
                            <FormHelperText error>{errors.question}</FormHelperText>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* Container rigth */}
                    <Grid justifyContent={'center'} textAlign="center" item>
                      <Stack textAlign={'center'} spacing={2}>
                        <Pagination
                          count={taskToEdit?.Detail_activity?.length!}
                          page={Page}
                          onChange={(_, value) => setPage(value)}
                          color="primary"
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {AñadirPregunta && (
                <Grid item xs={12}>
                  <Grid
                    style={{
                      borderTop: '4px solid #065CAB"',
                      borderRadius: '15px',
                      paddingBottom: '15px',
                    }}
                  >
                    <Grid item xs={12} sx={{ mt: 3 }}>
                      <InputLabel>
                        {t('teacherVirtualClassroom.tasks.questions.input.title')}
                      </InputLabel>
                      <TextField
                        multiline
                        rows={4}
                        style={{ height: '120px' }}
                        fullWidth
                        defaultValue={itemTask.dta_question}
                        error={Boolean(errors.question && touched.question)}
                        placeholder="Ingrese pregunta..."
                        name="question"
                        onChange={handleChange}
                        // {...getFieldProps('question')}
                      />
                      {errors.question && touched.question && (
                        <FormHelperText error>{errors.question}</FormHelperText>
                      )}
                    </Grid>
                    <Button
                      style={{ textTransform: 'unset', borderRadius: 0 }}
                      variant="contained"
                      onClick={handleSaveQuestion}
                      size="small"
                      color="primary"
                    >
                      {t('teacherVirtualClassroom.tasks.questions.button.add')}
                    </Button>
                  </Grid>
                </Grid>
              )}

              <Grid item xs={8} md={8}>
                <Grid spacing={2} item xs={12}>
                  <Grid container item justifyContent="center">
                    <Button
                      style={{ textTransform: 'unset', borderRadius: 0 }}
                      variant="contained"
                      onClick={() => {
                        resetForm();
                        setAñadirPregunta(true);
                      }}
                      size="small"
                      color="primary"
                    >
                      {t('teacherVirtualClassroom.tasks.questions.button.add')}
                    </Button>

                    <Button
                      style={{ textTransform: 'unset', borderRadius: 0 }}
                      variant="contained"
                      onClick={() =>
                        EditarTarea(
                          String(itemTask.id),
                          values.question || itemTask.dta_question,
                          (values.point !== 0 && String(values.point)) ||
                            itemTask.dta_score_evaluated
                        )
                      }
                      size="small"
                      color="primary"
                    >
                      Editar Tarea
                      {/*t('teacherVirtualClassroom.tasks.questions.button.add')*/}
                    </Button>
                    {/*
               onClick={() => handleSetTyQuestion('single')}
               startIcon={<IconCircle />}
               style={{ textTransform: 'unset', borderRadius: 0 }}
                variant={taskToCreate?.typeQuestion === 'single' ? 'contained' : 'outlined'}
                size="small"
                color="primary"
              >
                Una respuesta
              </Button>
              <Button
              <Button
              onClick={() => handleSetTyQuestion('multiple')}
              startIcon={<IconCheckbox />}
              style={{ textTransform: 'unset', borderRadius: 0 }}
              color="primary"
              variant={taskToCreate?.typeQuestion === 'multiple' ? 'contained' : 'outlined'}
              >
              Varias respuesta
              </Button>
              */}
                    <Button
                      onClick={() => handleSetTyQuestion('commentary')}
                      startIcon={<IconCheckbox />}
                      style={{ textTransform: 'unset', borderRadius: 0 }}
                      color="primary"
                      variant={
                        taskToCreate?.typeQuestion === 'commentary' ? 'contained' : 'outlined'
                      }
                      size="small"
                    >
                      {t('teacherVirtualClassroom.tasks.questions.button.text')}
                    </Button>
                    <Button
                      startIcon={<IconCheckbox />}
                      onClick={() => handleSetTyQuestion('Imagen')}
                      style={{ textTransform: 'unset', borderRadius: 0 }}
                      color="primary"
                      size="small"
                      variant={taskToCreate?.typeQuestion === 'Imagen' ? 'contained' : 'outlined'}
                    >
                      {t('teacherVirtualClassroom.tasks.questions.button.upload')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sx={{ mt: 5 }}>
                <Typography color="red">
                  La tarea debe ser igual a {taskToCreate?.act_score_activity!} (*)
                </Typography>
                <Grid container item justifyContent="center" spacing={1}>
                  <Grid item>
                    <Button
                      onClick={() => handleChooseGroup()}
                      style={{ textTransform: 'unset' }}
                      variant="outlined"
                      size="large"
                      color="primary"
                    >
                      {t('teacherVirtualClassroom.tasks.questions.button.cancel')}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      onClick={() => handleSubmitForm()}
                      disabled={SumaTotal() !== taskToCreate?.act_score_activity || isLoading}
                      endIcon={isLoading && <CircularProgress size={20} />}
                      style={{ textTransform: 'unset' }}
                      variant="contained"
                      size="large"
                      color="primary"
                    >
                      {t('teacherVirtualClassroom.tasks.questions.button.finish')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </>
          ) : null;
        })}
      </Grid>
    </Grid>
  );
};

export default CompleteQuestionsEdit;
