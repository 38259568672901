import {
  Autocomplete,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  InputLabel,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
//import { IconBell } from '@tabler/icons';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import { degreeName } from '../../../shared/constants/certificateType';
import { professionalPracticeCertificateValues } from '../constants/formConstants';
import { professionalPracticeCertificateSchema } from '../constants/validationSchemas';
import {
  useAddCertificateMutation,
  useGetCertificateTypesQuery,
  useGetPdfArrayBufferQuery,
  useGetProfessionalPracticeCertificateQuery,
} from '../slices/certificateApiSlice';
import { openDebitModalWithObject } from '../slices/certificateSlice';

import { useTranslation } from 'react-i18next';

//import { useGetCertificateTypesQuery } from '../slices/certificateApiSlice';
const useStyles = makeStyles((theme) => ({
  alert: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.primary.light,
    fontSize: '14px',
    fontWeight: 600,
    borderRadius: 20,
    paddingLeft: 40,
    paddingRight: 40,
  },
  pdfGrid: {
    padding: theme.spacing(1),
    borderRadius: 20,
    paddingTop: '6vh',
    paddingBottom: '6vh',
    backgroundColor: theme.palette.primary.light,
  },
  pdfFrame: {
    overflow: 'hidden',
  },
}));

const ProfessionalPracticesForm = () => {
  const { t } = useTranslation();

  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [showAlerts, setShowAlerts] = useState(false);
  const { currentProfile, currentRecordStudent } = useAppSelector((state) => state.access);
  const { userInfo } = useAppSelector((state) => state.auth);
  const { data: certificateTypes = [], isLoading: isLoadingCT } = useGetCertificateTypesQuery(
    { profileId: currentProfile?.profileId! },
    { refetchOnMountOrArgChange: 30 }
  );

  /* Obtenemos el id del tipo de certificado, en este caso usamos el acronimo para encontrarlo */
  const [certificateTypeId, setCertificateTypeId] = useState(0);
  const [deuda, setDeuda] = useState(0);
  const [pdfUrl, setPdfUrl] = useState('');
  const [pdf, setPdf] = useState('');
  const [notSkip, setNotSkip] = useState(false);
  const [errorPDf, setErrorPdf] = useState(false);

  const [addcertificate, { isLoading, isSuccess, data: certificadoSavedResponse, isError, error }] =
    useAddCertificateMutation();
  const { handleSubmit, errors, touched, getFieldProps, setFieldValue, values } = useFormik({
    initialValues: professionalPracticeCertificateValues,
    validationSchema: professionalPracticeCertificateSchema,
    onSubmit: async (value) => {
      if (deuda !== 0) {
        dispatch(
          openDebitModalWithObject({ id: certificateTypeId, pdfUrl: pdfUrl, appAcronym: 'CERPPP' })
        );
      } else {
        await addcertificate({
          profileId: currentProfile?.profileId!,
          appId: certificateTypeId,
          studentRecordId: currentRecordStudent?.id!,
          pdfUrl: pdfUrl,
          certificatePayload: {
            lang: '',
            consul: '',
            practice: [
              values.degree,
              values.name,
              values.position,
              values.work_location,
              values.city,
            ],
            period_id: 0,
            to: '',
            app_acronym: 'CERPPP',
          },
        });
      }
      setShowAlerts(true);
    },
  });

  //control de peticiones al escripbir
  useEffect(() => {
    if (
      values.degree ||
      values.name ||
      values.position ||
      values.work_location ||
      values.city ||
      certificateTypeId !== 0
    ) {
      const timerDebounce = setTimeout(() => {
        setNotSkip(true);
      }, 300);

      return () => {
        setNotSkip(false);
        clearTimeout(timerDebounce);
      };
    }
  }, [
    certificateTypeId,
    values.city,
    values.degree,
    values.name,
    values.position,
    values.work_location,
  ]);

  const {
    data,
    isLoading: isLoadingPPC,
    isFetching: isFetchingPPC,
    isError: isErrorSRC,
    error: errorSRC,
  } = useGetProfessionalPracticeCertificateQuery(
    {
      profileId: currentProfile?.profileId!,
      userId: String(userInfo?.id!),
      studentRecordId: currentRecordStudent?.id!,
      appId: certificateTypeId,
      degree: values.degree,
      name: values.name,
      position: values.position,
      work_location: values.work_location,
      city: values.city,
    },
    {
      refetchOnMountOrArgChange: 30,
      skip:
        !values.degree ||
        !values.name ||
        !values.position ||
        !values.work_location ||
        !values.city ||
        !notSkip ||
        certificateTypeId === 0,
    }
  );
  const {
    data: pdfBlob,
    isFetching: isFetchingPDF,
    isLoading: isLoadingPDF,
    error: isErrorPDF,
  } = useGetPdfArrayBufferQuery(
    { profileId: currentProfile?.profileId!, url: data?.pdf_url! },
    {
      refetchOnMountOrArgChange: 30,
      skip:
        !values.degree ||
        !values.name ||
        !values.position ||
        !values.work_location ||
        !values.city ||
        certificateTypeId === 0 ||
        data === undefined,
    }
  );

  useEffect(() => {
    if (!isFetchingPDF && pdfBlob) {
      let blob = new Blob([pdfBlob], { type: 'application/pdf' });
      let url = window.URL.createObjectURL(blob);
      setPdf(url);
    }
  }, [isFetchingPDF, pdfBlob]);

  useEffect(() => {
    if (!isLoadingCT && certificateTypeId === 0) {
      const certificateType = certificateTypes.filter(
        (certificateType) => certificateType.typ_app_acronym === 'CERPPP'
      );
      if (certificateType.length > 0) setCertificateTypeId(certificateType[0].id);
    }
  }, [isLoadingCT, certificateTypeId, certificateTypes]);

  useEffect(() => {
    if (!isLoadingPPC && data) {
      setDeuda(Number(data?.deuda!));
      setPdfUrl(data?.pdf_url!);
    }
  }, [isLoadingPPC, data]);

  useEffect(() => {
    if (isErrorSRC && errorSRC && isErrorPDF) {
      setShowAlerts(true);
    }if(isErrorPDF){
      setErrorPdf(true);
    }
  }, [isErrorSRC, errorSRC,isErrorPDF]);

  useEffect(() => {
    if (isSuccess && certificadoSavedResponse) {
      //Actualizando valor inmediato a la generacion del certificado
      //este es valido para el primero como el n-esimo
      setDeuda(Number(certificadoSavedResponse));
    }
  }, [isSuccess, certificadoSavedResponse]);

  const defaultConfiguration = useAppSelector((state) => state.access.defaultConfiguration);

  return (
    <form onSubmit={handleSubmit}>
      <Grid item>
        {isError && showAlerts && (
          <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
        )}
        {isErrorSRC && showAlerts && (
          <ErrorAlert message={errorSRC as string} handleDismiss={() => setShowAlerts(false)} />
        )}

        {isSuccess && showAlerts && (
          <SuccessAlert
            message={t('students.certificates.success_message')}
            handleDismiss={() => setShowAlerts(false)}
          />
        )}
      </Grid>
      <Grid container spacing={4} alignContent="flex-start">
        <Grid item xs={12} md={4} container spacing={2}>
          <Grid item xs={12} container spacing={2} margin={2}>
            <Grid item xs={12}>
              <Typography variant="h1" sx={{ mb: 2 }}>
                {t('students.certificates.practical_training.title')}
              </Typography>
              <Grid item xs={12} justifyContent="center" container sx={{ mb: 2 }}>
                <Grid item textAlign="center" className={classes.alert}>
                  <Typography variant="h5">
                    {t('students.certificates.info.complete_info')}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} sx={{ mb: 1 }}>
                <InputLabel>
                  {t('students.certificates.practical_training.addressee_grade')}
                </InputLabel>
                <Autocomplete
                  options={degreeName}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(errors.degree && touched.degree)}
                      placeholder={t('students.certificates.practical_training.addressee_grade')}
                    />
                  )}
                  onChange={(_, value) => {
                    setFieldValue('degree', value?.id || '');
                  }}
                  fullWidth
                  clearIcon={null}
                />
                {errors.degree && touched.degree && (
                  <FormHelperText error>{errors.degree}</FormHelperText>
                )}
              </Grid>
              <Grid item xs={12} sx={{ mb: 1 }}>
                <InputLabel>
                  {t('students.certificates.practical_training.addressee_name')}
                </InputLabel>
                <TextField
                  fullWidth
                  error={Boolean(errors.name && touched.name)}
                  placeholder={t('students.certificates.practical_training.addressee_name')}
                  {...getFieldProps('name')}
                />
                {errors.name && touched.name && (
                  <FormHelperText error>{errors.name}</FormHelperText>
                )}
              </Grid>
              <Grid item xs={12} sx={{ mb: 1 }}>
                <InputLabel>
                  {t('students.certificates.practical_training.addressee_role')}
                </InputLabel>
                <TextField
                  fullWidth
                  error={Boolean(errors.position && touched.position)}
                  placeholder={t('students.certificates.practical_training.addressee_role')}
                  {...getFieldProps('position')}
                />
                {errors.position && touched.position && (
                  <FormHelperText error>{errors.position}</FormHelperText>
                )}
              </Grid>
              <Grid item xs={12} sx={{ mb: 1 }}>
                <InputLabel>
                  {t('students.certificates.practical_training.company_name')}
                </InputLabel>
                <TextField
                  fullWidth
                  error={Boolean(errors.work_location && touched.work_location)}
                  placeholder={t('students.certificates.practical_training.company_name')}
                  {...getFieldProps('work_location')}
                />
                {errors.work_location && touched.work_location && (
                  <FormHelperText error>{errors.work_location}</FormHelperText>
                )}
              </Grid>
              <Grid item xs={12} sx={{ mb: 1 }}>
                <InputLabel>{t('students.certificates.practical_training.city')}</InputLabel>
                <TextField
                  fullWidth
                  error={Boolean(errors.city && touched.city)}
                  placeholder={t('students.certificates.practical_training.city')}
                  {...getFieldProps('city')}
                />
                {errors.city && touched.city && (
                  <FormHelperText error>{errors.city}</FormHelperText>
                )}
              </Grid>
              <Grid item xs={12} justifyContent="center" container sx={{ mt: 2 }}>
                <Grid item xs={8} textAlign="center">
                  <Typography variant="h4">{t('students.certificates.info.include')} </Typography>
                </Grid>
                <Grid item xs={12} textAlign="center" sx={{ mt: 2 }}>
                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    color="primary"
                    disabled={isLoading || errorPDf}
                    endIcon={isLoading && <CircularProgress size={20} />}
                  >
                    {t('shared.get_button.label')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={8} container spacing={2}>
          <Grid item xs={12} container spacing={2} margin={2}>
            <Grid item xs={12} container>
              <Grid item xs={12} textAlign="center">
                <Typography variant="h1" sx={{ mb: 2 }}>
                  {t('students.certificates.info.preview')}
                </Typography>
              </Grid>

              <Grid item xs={12} container sx={{ mt: 2, pl: 4, pr: 4 }}>
                <Grid item xs={6} textAlign="left">
                  <Typography variant="h3" sx={{ mb: 2 }}>
                    {t('students.certificates.info.to_pay')}
                  </Typography>
                </Grid>
                <Grid item xs={6} textAlign="right">
                  <Typography variant="h3" sx={{ mb: 2 }}>
                    <strong>
                      {defaultConfiguration?.currency_symbol} {deuda > 0 ? deuda : 0}
                    </strong>
                  </Typography>
                </Grid>
              </Grid>
              {isErrorPDF ? (
                <Grid
                item
                xs={12}
                container
                justifyContent="center"
                className={classes.pdfGrid}
                sx={{ height: '60vh' }}
              >
                <Typography 
                color='primary.dark'
                variant='h3'
                >
                {isErrorPDF}
                </Typography>
                </Grid>
              ) : (
                <Grid
                  item
                  xs={12}
                  container
                  justifyContent="center"
                  className={classes.pdfGrid}
                  sx={{ height: '60vh' }}
                >
                  {!isLoadingPPC && pdf && !isFetchingPPC && !isFetchingPDF && (
                    <div style={{ position: 'relative', width: '85%' }}>
                      <div
                        onContextMenu={(e) => e.preventDefault()}
                        style={{
                          position: 'absolute',
                          width: '96%',
                          height: '96%',
                        }}
                      ></div>
                      <iframe
                        title="VistaPrevia"
                        style={{ width: '100%', height: '100%' /*, pointerEvents: 'none'*/ }}
                        src={pdf + '#toolbar=0'}
                      />
                    </div>
                  )}
                  {(isLoadingPPC || isLoadingPDF || isFetchingPPC || isFetchingPDF) && (
                    <Grid item xs={12} container justifyContent="center" alignItems="center">
                      <CircularProgress size={20} />
                    </Grid>
                  )}
                  {/* To render pdf*/}
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};
export default ProfessionalPracticesForm;
