export const permissionKey = 'applications';

export interface SubPermissionKeys {
  read: string;
  get: string;
  create: string;
  delete: string;
  save_late_exam: string;
  save_recovery_exam: string;
  save_subject_retirement: string;
  save_requalification: string;
  save_change_career: string;
  save_third_registration: string;
  save_other: string;
  save_practice: string;
  save_bonding: string;
  save_academic_degree: string;
  save_payment_agreement: string;
}

export const subPermissionKeys: SubPermissionKeys = {
  read: 'application-listar-solicitudes',
  create: 'application-crear-solicitudes',
  get: 'application-obtener-solicitudes',
  delete: 'application-borrar-solicitudes',
  save_late_exam: 'applications-late-exam-store',
  save_recovery_exam: 'applications-recovery-exam-store',
  save_subject_retirement: 'applications-subject-retirement-store',
  save_requalification: 'applications-requalification-store',
  save_change_career:
    'applications-internal-homologations-registrar-configuracion-homologacion-interna',
  save_third_registration: 'applications-third-registration-store',
  save_other: 'applications-others-store',
  save_practice: 'applications-practices-store',
  save_bonding: 'applications-links-store',
  save_academic_degree: 'applications-academic-degree-store',
  save_payment_agreement: 'applications-payment-agreement-store',
};
