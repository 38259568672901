import { useHistory, useParams } from 'react-router-dom';
import { useAppSelector } from '../../../../../hooks/useRedux';
import MainCard from '../../../../shared/components/cards/MainCard';
import { useGetStudentsActivitiesQuery } from '../../slices/virtualClassroomTeacherApiSlice';
import AssignmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import { Grid, Typography, Autocomplete, TextField, IconButton, Tooltip } from '@material-ui/core';
import LoadingData from '../../../../shared/components/cards/Skeleton/LoadingData';
import SearchPaginatedTable from '../../../../shared/components/tables/SearchPaginatedTable';
import { useMemo, useState } from 'react';
import moment from 'moment';
import FeedOutlinedIcon from '@material-ui/icons/FeedOutlined';
import ButtonBack from '../../Components/ButtonBack';
import { useTranslation } from 'react-i18next';

function ParticipantsActivities() {
  const { activityId } = useParams<{ activityId: string }>();

  const history = useHistory();

  const [page, setPage] = useState<number>(1);

  const [perPage, setPerPage] = useState<number>(5);

  const [search, setSearch] = useState<string>('');

  const Path = window.location.pathname;

  const {
    course: { courseId },
  } = useAppSelector((state) => state.virtualClassroomTeacherShared);

  const { data, isLoading, isFetching, error } = useGetStudentsActivitiesQuery({
    cursoId: courseId!,
    activityId: Number(activityId),
  });

  const {t} = useTranslation()

  function NavigateToDetail(studentId: number) {
    history.replace(`${Path}/student/${studentId}`);
  }
  /*function NavigateEvaluation(evaluationId: number, studentId: string, userId: string) {
    history.replace(
      `/administrar-aula-virtual/test/calificar/${evaluationId}/estudiante/${studentId}/${userId}`
    );
  }
  */

  const dataFetch = useMemo(() => {
    return (Array.isArray(data?.participants) ? data?.participants! : [])?.map(
      (e) =>
        ({
          Codigo: e?.id!,
          Nombre: `${e?.student.user.person.pers_firstname} ${e?.student.user.person.pers_first_lastname} ${e?.student.user.person.pers_second_lastname} `,
          FechaEntrega:
            e?.cab_activity_student! !== null
              ? moment(e?.cab_activity_student?.act_start_date).format('LLL')
              : '',
          Calificacion:
            e?.cab_activity_student! !== null
              ? `${e?.cab_activity_student?.act_score}/${data?.act_score_activity}`
              : null,
          accion: {
            student: e,
            id: e?.student?.id!,
          },
        } || [])
    );
  }, [data]);

  if (isLoading) {
    return <LoadingData />;
  }

  return (
    <MainCard title={t("teacher_virtual_classroom.califications.main.header.califications")}>
      <Grid display={'flex'} width="100%" justifyContent={'space-between'} alignItems="center">
        <Grid display={'inline-flex'} width="max-content" alignItems="center">
          <AssignmentOutlinedIcon fontSize="large" />
          <Typography color="black" fontWeight={'bold'} variant="h3">
            {/*data?.*/}
            {data?.act_description!}
          </Typography>
        </Grid>
        <Grid>
          <Grid display={'flex'} alignItems="center">
            <Typography variant="body1" style={{ marginRight: '5px' }}>
              {t("teacher_virtual_classroom.califications.filters.state")}
            </Typography>
            <Autocomplete
              
              style={{ width: '200px' }}
              disablePortal
              options={[]}
              renderInput={(params) => <TextField {...params} label={t("teacher_virtual_classroom.califications.filters.state")} />}
            />
          </Grid>
        </Grid>
        <ButtonBack />
      </Grid>

      <SearchPaginatedTable
        headers={{
          Codigo: t("teacher_virtual_classroom.califications.table.participants.item.codigo"),
          Nombre: t("teacher_virtual_classroom.califications.table.participants.item.nombre"),
          FechaEntrega: t("teacher_virtual_classroom.califications.table.participants.item.fechaEntrega"),
          Calificacion: t("teacher_virtual_classroom.califications.table.participants.item.Calificacion"),
          accion: '',
        }}
        searchPlacehoder="Buscar tarea"
        keyExtractor={(item) => item.Codigo.toString()}
        data={dataFetch}
        page={page}
        setPage={setPage}
        perPage={perPage}
        setPerPage={setPerPage}
        search={search}
        total={Math.ceil(Number(data?.participants_count)) || 0}
        setSearch={setSearch}
        isFetching={isFetching}
        numHeader={5}
        isLoading={isLoading}
        error={error}
        ActionButtons={<></>}
        showFilter={false}
        perPageBox={true}
        paginationBox={true}
        customDataCellsProperties={{
          Codigo: {
            align: 'center',
          },
          Nombre: {
            align: 'center',
          },

          FechaEntrega: {
            align: 'center',
          },
          Calificacion: {
            align: 'center',
          },
          accion: {
            align: 'center',
          },
        }}
        customRenderers={{
          accion: (row) => (
            <Tooltip title={t("teacher_virtual_classroom.califications.table.tooltip")}>
              <IconButton onClick={() => NavigateToDetail(row.accion.id)}>
                <FeedOutlinedIcon />
              </IconButton>
            </Tooltip>
          ),
        }}
      />
    </MainCard>
  );
}

export default ParticipantsActivities;
