import DeleteAlert from '../../../../../../shared/components/dialogs/DeleteAlert';
import { useDeleteTaskToProjectResearchMutation } from '../../../../slices/InvestigationLinesApiSlice';

interface DeleteTaskDialogProps {
  taskId: number;
  handleClose: () => void;
}

const DeleteTaskDialog = ({ taskId, handleClose }: DeleteTaskDialogProps) => {
  const [deleteTask, { isLoading, isSuccess, isError, error }] =
    useDeleteTaskToProjectResearchMutation();

  const handleDelete = () => {
    deleteTask({
      taskId,
    });
  };

  return (
    <DeleteAlert
      open
      onClose={handleClose}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      handleDelete={handleDelete}
      error={String(error)}
      deleteTitle="¿Estás seguro de eliminar la tarea?"
    />
  );
};

export default DeleteTaskDialog;
