import { useEffect } from 'react';

import { Grid } from '@material-ui/core';

import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';

import MainCard from '../../../shared/components/cards/MainCard';
import GoBackBtnTitle from '../../../shared/components/buttons/GoBackBtnTitle';
import EditForm from '../components/EditForm';
import { useLazyGetCategoryByIdQuery } from '../slices/categoryApiSlice';
import { setCategories } from '../slices/catgorySlice';
import SimpleFormOneColumn from '../../../shared/components/forms/skeletons/SimpleFormOneColumn';

const MaintenanceCategoryEdit = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { categoryId } = useParams<{ categoryId: string }>();

  const { categories: categoriesStore } = useAppSelector((state) => state.categories);

  const [getCategory, { isFetching }] = useLazyGetCategoryByIdQuery();

  useEffect(() => {
    if (!categoriesStore.length && !isFetching) {
      getCategory({
        categoryId: categoryId,
      })
        .unwrap()
        .then((response) => {
          if (response) dispatch(setCategories([response!]));
        })
        .catch((error) => console.log(error));
    }
  }, [getCategory, isFetching, categoryId, categoriesStore.length, dispatch]);

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <GoBackBtnTitle title={t('financial.maintenance_cat.edit.title')} />
      </Grid>
      {!!categoriesStore.length ? <EditForm /> : <SimpleFormOneColumn />}
    </Grid>
  );
};

export default MaintenanceCategoryEdit;
