import { useState } from 'react';

import { Formik } from 'formik';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

import { navigateTopSmooth } from '../../../shared/helpers';
import { CalificationModelDates } from '../../periods/components/AssignStagesForm';
import { Stage } from '../../periods/interfaces/periodStages.interfaces';
import {
  CalificationModel,
  CalificationModelCourses,
} from '../../periods/interfaces/calification-models.interfaces';
import { usePeriodStageQualificationsMutation } from '../../periods/slices/periodsApiSlice';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import FiltersQualificationCourses from './builder/FiltersQualificationCourses';
import { CoursesQualificationAssign } from '../../periods/interfaces/calification-models.interfaces';
import CoursesTable from './builder/CoursesTable';
import DateStageSelection from './builder/DateStageSelection';
import LoadingChildren from '../../../shared/components/LoadingChildren';
import NoData from '../../../shared/components/NoData';
import image from '../../../../assets/images/illustrations/telework.svg';
import { CoursesStage } from '../../periods/interfaces/calification-models.interfaces';
import { AssignStagesSchema } from '../../periods/constants/AssignQualiCoursesSchema';

const modelAcronym = 'B';

export interface AssignQualiCoursesFormValues {
  stageInfo: Stage | null;
  modelInfo: CalificationModel | null;
  modelCourses: CalificationModelCourses[];

  collaborator_id: number;
  course_id: number;
  period_id: number;
  stg_acronym: string;
  all_courses: boolean;
  current_period_stage: boolean;
  qualification_model_id: number;
  courses: (CoursesQualificationAssign & Partial<CoursesStage>)[]; // array courses to assign

  calificationModelId: number;
  calificationModelDates: CalificationModelDates;
}

const AssignQualiCoursesForm = () => {
  const { t } = useTranslation();

  const [showAlerts, setShowAlerts] = useState(false);
  const [singleCourseSel, setSingleCourseSel] = useState(0);

  const [addPeriodQualification, { isLoading, isError, isSuccess, error }] =
    usePeriodStageQualificationsMutation();

  return (
    <Formik
      initialValues={
        {
          stageInfo: null as Stage | null,
          modelInfo: null as CalificationModel | null,
          modelCourses: [] as CalificationModelCourses[],

          // filters
          collaborator_id: 0,
          course_id: 0,
          period_id: 0,

          stg_acronym: modelAcronym,
          all_courses: false,
          current_period_stage: false,
          qualification_model_id: 0,
          courses: [],

          calificationModelId: 0,
          calificationModelDates: {} as CalificationModelDates,
        } as AssignQualiCoursesFormValues
      }
      validationSchema={AssignStagesSchema}
      onSubmit={async (values, actions) => {
        await addPeriodQualification({
          periodStageQuaPayload: {
            period_id: values.period_id,
            stg_acronym: modelAcronym,
            all_courses: values.all_courses,
            courses: values.courses,
            current_period_stage: true,
            qualification_model_id: values.calificationModelId,
            dates: values.calificationModelDates?.distributions?.reduce<any[]>(
              (acc, curr) => [
                ...acc,
                ...curr.supplies?.map((s) => ({
                  supply_id: s.ref2_supply_id,
                  start_date: format(s.startDate!, 'yyyy-MM-dd'),
                  end_date: format(s.endDate!, 'yyyy-MM-dd'),
                })),
              ],
              []
            ) as unknown as any[],
          },
        });

        setShowAlerts(true);
        navigateTopSmooth();
      }}
    >
      {({ values, errors, touched, setFieldValue }) => (
        <form>
          <Grid container spacing={2}>
            {showAlerts && (
              <Grid item xs={12}>
                {isError && (
                  <ErrorAlert
                    message={error as string}
                    handleDismiss={() => setShowAlerts(false)}
                  />
                )}

                {isSuccess && (
                  <SuccessAlert
                    message={t('Datos guardados exitosamente')}
                    handleDismiss={() => setShowAlerts(false)}
                  />
                )}
              </Grid>
            )}

            <Grid item xs={12}>
              <FiltersQualificationCourses modelAcronym={modelAcronym} />
            </Grid>

            <LoadingChildren
              isLoading={false}
              // loadingSkeleton={<Skeleton sx={{ width: '100%', height: '20vh' }} />}
              isEmpty={!values.period_id || !values.calificationModelId}
              emptyFallback={
                <>
                  <Grid container alignItems="center" mt={10}>
                    <NoData
                      // imgWitdh={100}
                      image={image}
                      title={t('Permisos de notas')}
                      subtitle={t(
                        'Con los siguientes filtros de selección, escoge la oferta académica donde quieras visualizar los cursos y asignarle fechas a los insumos.'
                      )}
                    />
                  </Grid>
                </>
              }
            >
              <Grid item xs={!!values.courses.length ? 6 : 12} lg={!!values.courses.length ? 6 : 8}>
                <CoursesTable
                  singleCourseSel={singleCourseSel}
                  setSingleCourseSel={setSingleCourseSel}
                />
              </Grid>

              {!!values.courses.length && (
                <Grid item xs={6}>
                  {/* <Slide direction="up" in={false} mountOnEnter unmountOnExit>
                </Slide> */}
                  <DateStageSelection isLoadingPost={isLoading} singleCourseSel={singleCourseSel} />
                </Grid>
              )}

              <Grid item xs={12}>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="right"
                  spacing={2}
                  sx={{ mt: 2 }}
                ></Grid>
              </Grid>
            </LoadingChildren>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default AssignQualiCoursesForm;
