import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import MainCard from '../../../../../shared/components/cards/MainCard';
import LaunchingForm from '../components/LaunchingForm';
import BackButton from '../../../../../shared/components/buttons/BackButton';

const LauchingAddScreen = () => {
  const { t } = useTranslation();

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid alignItems="center" sx={{ mb: 3, display:'flex' }}>
        <BackButton />
        <Typography variant="h2">{t('maintenance.launching.title.form')}</Typography>
      </Grid>
      <LaunchingForm />
    </Grid>
  );
};

export default LauchingAddScreen;
