import { useTranslation } from 'react-i18next';
import Chip from '../../../shared/components/extended/Chip';

interface Props {
  statusName: string;
}

const ClassroomStatusChip = ({ statusName }: Props) => {
  const { t } = useTranslation();

  let chipcolor = '';

  switch (statusName) {
    case 'DISPONIBLE':
      chipcolor = 'success';
      break;
    case 'ASIGNADA':
      chipcolor = 'info';
      break;
    default:
      chipcolor = 'orange';
  }

  return (
    <Chip
      label={t(`utils.classroom_status.${statusName.toLowerCase()}`)}
      size="small"
      chipcolor={chipcolor}
    />
  );
};

export default ClassroomStatusChip;
