import { useState } from 'react';
import {
  Button,
  CircularProgress,
  Divider,
  FormHelperText,
  Grid,
  Skeleton,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';

import { useAppSelector } from '../../../../../hooks/useRedux';
import { StudentRecord } from '../../interfaces/homologations.interface';
import {
  useGetApplicationHomologationQuery,
  useGetEducationLevelMattersQuery,
  useGetStudentRecordSubjectsQuery,
  useUpdateApplicationMutation,
  // useGetApplicationHomologationQuery,
} from '../../slices/homologationsApiSlice';
import { UpdateApplicationValidationSchema } from '../../constants/validationSchemas';
import ErrorAlert from '../../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../../shared/components/alerts/SuccessAlert';
import {
  openInternalConfirmModalWith,
  openInternalDeleteModalWith,
} from '../../slices/homologationsSlice';
import InputLabel from '../../../../shared/components/forms/InputLabel';
import { useGetMeshQuery } from '../../../meshs/slices/meshsApiSlice';
import SubjectsList from './SubjectsList';
import { useTranslation } from 'react-i18next';

const spacing = 2;

const PreviewIntHomologation = () => {
  const { t } = useTranslation();

  const history = useHistory();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { currentProfile, currentRol } = useAppSelector((state) => state.access);
  const location = useLocation();

  const [showAlerts, setShowAlerts] = useState(true);

  //otra lista de seleccion para las materias a homologar (solo es para visualizar)
  const [selectedApprovedSubjects, setSelectedApprovedSubjects] = useState<number[]>([]);
  const [selectedHomologationSubjects, setSelectedHomologationSubjects] = useState<number[]>([]);

  const handleSelectApprovedSubjects = (selected: number[]) => {
    setSelectedApprovedSubjects(selected);
  };

  const handleSelectHomologationSubjects = (selected: number[]) => {
    setSelectedHomologationSubjects(selected);
  };

  // const { internalHomologationsList } = useAppSelector((state) => state.homologations);
  const { hId } = useParams<{ hId: string }>();

  const { data, isFetching } = useGetApplicationHomologationQuery({
    profileId: currentProfile?.profileId!,
    ApplicationHomId: Number(hId),
  });

  // const currentApplication = internalHomologationsList.find((h) => h.id.toString() === hId);

  // const primaryData = currentApplication!.applicationDetails[0].value as StudentRecord;
  const primaryData = data?.applicationDetails[0]?.value as StudentRecord;

  const [updateApplication, { isLoading: isUpdating, isSuccess, isError, error }] =
    useUpdateApplicationMutation();

  // for (const el of currentApplication!.applicationDetails) {
  //   const aux = getApplicationDetailValue(el.value, 'collaborator.coll_advice', 'coll_advice');
  //   // const aux = getApplicationDetailValue(el.value, 'collaborator_id');
  //   if (Boolean(aux)) break;
  // }

  const { errors, touched, getFieldProps, handleSubmit, values, validateField } = useFormik({
    initialValues: {
      comment: '',
    },
    validationSchema: UpdateApplicationValidationSchema,
    onSubmit: async (values) => {
      await updateApplication({
        profileId: currentProfile?.profileId!,
        applicationPayload: {
          app_code: data!.app_code,
          comment: values.comment,
          role_id: currentRol!.rolId,
        },
      });

      setShowAlerts(true);
    },
  });

  const {
    data: destinedCurriculum,
    isLoading: isLoadingDM,
    isFetching: isFetchingDM,
  } = useGetMeshQuery(
    {
      profileId: currentProfile?.profileId!,
      meshId: data?.applicationDetails.curriculum.id || -1,
    },
    { skip: !data }
  );

  const {
    data: educationLevelMatters = [],
    isFetching: isFetchingEDM,
    error: errorEDM,
  } = useGetEducationLevelMattersQuery(
    {
      profileId: currentProfile?.profileId!,
      edLvlId: destinedCurriculum! ? destinedCurriculum.education_level.id : 0,
    },
    { skip: isLoadingDM, refetchOnMountOrArgChange: 30 }
  );

  const {
    data: ApprovedSubjects = [],
    isFetching: isFetchingAPS,
    error: errorAPS,
  } = useGetStudentRecordSubjectsQuery(
    {
      profileId: currentProfile?.profileId!,
      stdRecord: primaryData?.id || -1,
    },
    { skip: !primaryData, refetchOnMountOrArgChange: 30 }
  );

  const handleReject = function (internalHomologation: { code: string; msg: string }) {
    touched.comment = true;
    validateField('comment').then(() => {
      if (values.comment === '') return;
      if (touched.comment && errors.comment) return;
      dispatch(openInternalDeleteModalWith(internalHomologation));
    });
  };

  const handleConfirm = function (internalHomologation: {
    code: string;
    msg: string;
    subjects: number[];
  }) {
    touched.comment = true;
    validateField('comment').then(() => {
      if (values.comment === '') return;
      if (touched.comment && errors.comment) return;
      dispatch(openInternalConfirmModalWith(internalHomologation));
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={4} alignItems="flex-start">
        <Grid item xs={12}>
          {isError && showAlerts && (
            <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
          )}

          {isSuccess && showAlerts && (
            <SuccessAlert
              message={t('academic.homologation.internal.form.success')}
              handleDismiss={() => setShowAlerts(false)}
            />
          )}
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid container item xs={12} md={6} alignItems={'center'}>
              <Grid item xs={'auto'}>
                <Typography variant={'body2'} component={'span'}>
                  {t('academic.homologation.internal.form.student_code')}
                </Typography>
              </Grid>
              {isFetching ? (
                <Grid item xs={6}>
                  <Skeleton
                    variant="text"
                    component={'span'}
                    sx={{ width: '100%', height: 30, ml: 1 }}
                  />
                </Grid>
              ) : (
                <Grid item xs={'auto'} lg={6} sx={{ pl: spacing }}>
                  <Typography variant={'h4'} component={'span'}>
                    {primaryData?.student.stud_code}
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Grid container item xs={12} md={6} alignItems={'center'}>
              <Grid item xs={'auto'}>
                <Typography variant={'body2'} component={'span'}>
                  {t('academic.homologation.internal.form.dni')}
                </Typography>
              </Grid>
              {isFetching ? (
                <Grid item xs={6}>
                  <Skeleton
                    variant="text"
                    component={'span'}
                    sx={{ width: '100%', height: 30, ml: 1 }}
                  />
                </Grid>
              ) : (
                <Grid item xs={'auto'} lg={6} sx={{ pl: spacing }}>
                  <Typography variant={'h4'} component={'span'}>
                    {primaryData?.student.user.person.pers_identification}
                  </Typography>
                </Grid>
              )}
            </Grid>

            <Grid container item xs={12} md={6} alignItems={'center'}>
              <Grid item xs={'auto'}>
                <Typography variant={'body2'} component={'span'}>
                  {t('academic.homologation.internal.form.student_name')}
                </Typography>
              </Grid>
              {isFetching ? (
                <Grid item xs={6}>
                  <Skeleton
                    variant="text"
                    component={'span'}
                    sx={{ width: '100%', height: 30, ml: 1 }}
                  />
                </Grid>
              ) : (
                <Grid item xs={'auto'} lg={6} sx={{ pl: spacing }}>
                  <Typography variant={'h4'} component={'span'}>
                    {`${primaryData?.student?.user?.person?.pers_firstname || ''} ${
                      primaryData?.student?.user?.person?.pers_secondname || ''
                    } ${primaryData?.student?.user?.person?.pers_first_lastname || ''} ${
                      primaryData?.student?.user?.person?.pers_second_lastname || ''
                    }`}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid container item xs={12} md={6}>
              <Grid item xs={'auto'}>
                <Typography variant={'body2'} component={'span'}>
                  {t('academic.homologation.internal.form.edu_level')}
                </Typography>
              </Grid>
              {isFetching ? (
                <Grid item xs={6}>
                  <Skeleton
                    variant="text"
                    component={'span'}
                    sx={{ width: '100%', height: 30, ml: 1 }}
                  />
                </Grid>
              ) : (
                <Grid item xs={'auto'} lg={6} sx={{ pl: spacing }}>
                  <Typography variant={'h4'} component={'span'}>
                    {primaryData?.education_level.offer.off_name}
                  </Typography>
                </Grid>
              )}
            </Grid>

            <Grid container item xs={12} md={6}>
              <Grid item xs={'auto'}>
                <Typography variant={'body2'} component={'span'}>
                  {t('academic.homologation.internal.form.academic_counsel')}
                </Typography>
              </Grid>
              {isFetching ? (
                <Grid item xs={6}>
                  <Skeleton
                    variant="text"
                    component={'span'}
                    sx={{ width: '100%', height: 30, ml: 1 }}
                  />
                </Grid>
              ) : (
                <Grid item xs={'auto'} lg={6} sx={{ pl: spacing }}>
                  <Typography variant={'h4'} component={'span'}>
                    {`${primaryData?.collaborator?.user?.person?.pers_firstname || ''} ${
                      primaryData?.collaborator?.user?.person?.pers_secondname || ''
                    } ${primaryData?.collaborator?.user?.person?.pers_first_lastname || ''} ${
                      primaryData?.collaborator?.user?.person?.pers_second_lastname || ''
                    }`}
                  </Typography>
                </Grid>
              )}
            </Grid>

            <Grid container item xs={12} md={6}>
              <Grid item xs={'auto'}>
                <Typography variant={'body2'} component={'span'}>
                  {t('academic.homologation.internal.form.actual_program')}
                </Typography>
              </Grid>
              {isFetching ? (
                <Grid item xs={6}>
                  <Skeleton
                    variant="text"
                    component={'span'}
                    sx={{ width: '100%', height: 30, ml: 1 }}
                  />
                </Grid>
              ) : (
                <Grid item xs={'auto'} lg={6} sx={{ pl: spacing }}>
                  <Typography variant={'h4'} component={'span'}>
                    {primaryData?.education_level.edu_name}
                  </Typography>
                </Grid>
              )}
            </Grid>

            <Grid container item xs={12} md={6} alignItems={'center'}>
              <Grid item xs={'auto'}>
                <Typography variant={'body2'} component={'span'} alignItems={'center'}>
                  {t('academic.homologation.internal.form.destinate_program')}
                </Typography>
              </Grid>
              {isFetchingDM ? (
                <Grid item xs={6}>
                  <Skeleton
                    variant="text"
                    component={'span'}
                    sx={{ width: '100%', height: 30, ml: 1 }}
                  />
                </Grid>
              ) : (
                <Grid item xs={'auto'} lg={6} sx={{ pl: spacing }}>
                  <Typography variant={'h4'} component={'span'}>
                    {destinedCurriculum?.education_level.edu_name}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid container item xs={12} justifyContent={'space-between'}>
          <SubjectsList
            approvedSubjects={ApprovedSubjects}
            isFetchingApprovedSubjects={
              isFetchingAPS || isFetchingEDM || isFetchingDM || isFetching
            }
            errorApprovedSubjects={errorAPS as string}
            selectedApprovedSubjs={selectedApprovedSubjects}
            homologationSubjects={educationLevelMatters}
            selectedHomologationSubjs={selectedHomologationSubjects}
            isFetchingHomologationSubjs={isFetchingEDM || isFetchingDM || isFetching}
            errorHomologationSubjs={errorEDM as string}
            handleApprovedSubjects={handleSelectApprovedSubjects}
            handleHomologationSubjects={handleSelectHomologationSubjects}
          />
        </Grid>

        <Grid item xs={12}>
          <InputLabel>{t('academic.homologation.internal.form.comment')}</InputLabel>
          <TextField
            fullWidth
            multiline
            rows={4}
            error={Boolean(errors.comment && touched.comment)}
            placeholder={t('academic.homologation.internal.form.comment.placeholder')}
            {...getFieldProps('comment')}
          />
          {errors.comment && touched.comment && (
            <FormHelperText error>{t(errors.comment)}</FormHelperText>
          )}
        </Grid>

        <Grid container justifyContent={'flex-end'} mt={2} spacing={2}>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              size="large"
              // style={{ background: theme.palette.error.main, borderColor: '#EDE7F6' }}
              sx={
                {
                  // boxShadow: theme.customShadows.error,
                  // ':hover': {
                  //   boxShadow: 'none',
                  // },
                }
              }
              onClick={() => {
                history.push(`/${location.pathname.split('/')[1]}`);
              }}
            >
              {t('academic.homologation.internal.form.cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              size="large"
              style={{ background: theme.palette.error.main, borderColor: '#EDE7F6' }}
              onClick={() => {
                handleReject({ code: data!.app_code, msg: values.comment });
              }}
            >
              {t('academic.homologation.internal.form.reject')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              size="large"
              color="primary"
              disabled={isUpdating || !Boolean(selectedApprovedSubjects.length)}
              endIcon={isUpdating && <CircularProgress size={20} />}
              onClick={() =>
                handleConfirm({
                  code: data!.app_code,
                  msg: values.comment,
                  subjects: selectedApprovedSubjects,
                })
              }
            >
              {t('academic.homologation.internal.form.homologate')}
            </Button>
          </Grid>
        </Grid>

        {/* // */}
      </Grid>
    </form>
  );
};

export default PreviewIntHomologation;
