import React from 'react';
import { Grid } from '@material-ui/core';

import DialogAlert from '../../../shared/components/dialogs/DialogAlert';
import InputLabel from '../../../shared/components/forms/InputLabel';

interface Props extends PropsChildren {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  error: any;
  event: () => void;
}

interface PropsChildren {
  subjectsLength: number;
  isError?: boolean;
}

const Children = ({ subjectsLength, isError }: PropsChildren) => {
  return (
    <>
      {!isError && (
        <>
          {/* <Grid item xs={12}>
            <InputLabel>Migrar cursos a Moodle</InputLabel>
            {periodName}
          </Grid> */}
          <Grid item xs={12} mt={2}>
            <InputLabel>
              {' '}
              {!!subjectsLength ? subjectsLength + ' cursos' : 'Todos los cursos de la tabla'}
            </InputLabel>
          </Grid>
        </>
      )}
    </>
  );
};

const ConfirmAlertDialog = ({
  error,
  event,
  isError,
  isLoading,
  isSuccess,
  open,
  setOpen,
  subjectsLength,
}: Props) => {
  const handleConfirm = () => {
    event();
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <DialogAlert
      open={open}
      onClose={handleClose}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      children={<Children isError={isError} subjectsLength={subjectsLength} />}
      handleAction={handleConfirm}
      customTitleSendButton={'Si, migrar cursos'}
      title={`Se migrarán cursos a moodle`}
      error={error as string}
    />
  );
};

export default ConfirmAlertDialog;
