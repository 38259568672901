import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import BackButton from '../../../../../shared/components/buttons/BackButton';
import MainCard from '../../../../../shared/components/cards/MainCard';
import QuestionForm from '../components/QuestionForm';

const AddQuestionScreen = () => {
  const { t } = useTranslation();

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid container alignItems="center" sx={{ mb: 3 }}>
        <BackButton />
        <Typography variant="h2">{t('maintenance.questions.add.title')}</Typography>
      </Grid>
      <QuestionForm />
    </Grid>
  );
};

export default AddQuestionScreen;
