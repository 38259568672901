import { Grid, Typography } from '@material-ui/core';
import MainCard from '../../../shared/components/cards/MainCard';
import ParametersCompanyTable from '../components/ParametersCompanyTable';
import { useAppSelector } from '../../../../hooks/useRedux';
import AddParametersCompanyDialog from '../components/AddParametersCompanyDialog';
import ConfirmChangeValueDialog from '../components/ConfirmChangeValueDialog';

const CompanyParametersScreen = () => {
  const { isOpenModalAdd, isOpenModalEdit } = useAppSelector((state) => state.parameterCompany);

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">Parámetros de Compañías</Typography>
      </Grid>
      <ParametersCompanyTable />
      {isOpenModalAdd && <AddParametersCompanyDialog />}
      {isOpenModalEdit && <ConfirmChangeValueDialog />}
    </Grid>
  );
};

export default CompanyParametersScreen;
