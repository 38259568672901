import moment from 'moment';
import { useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { useAppSelector } from '../../../../../hooks/useRedux';
import { useGetScheduleActivitiesQuery } from '../../slices/virtualClassroomApiSlice';
import { ActivityDetails } from '../interfaces/scheduleActivitiesInterfaces';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

const Schedule = () => {
  const localizer = momentLocalizer(moment);

  const [currentDate, setCurrentDate] = useState<Date>(new Date());

  const { currentProfile } = useAppSelector((state) => state.access);

  //const { language } = useAppSelector((state) => state.customization);

  const { course } = useAppSelector((state) => state.virtualClassroomStudent);

  const { t } = useTranslation();

  const { data } = useGetScheduleActivitiesQuery(
    {
      profileId: currentProfile?.profileId!,
      courseId: course?.courseId!,
      month: currentDate.getMonth() + 1,
      year: currentDate.getFullYear(),
    },
    {
      skip: !course.courseId,
    }
  );

  const activityFormat = (activities: ActivityDetails[]) => {
    return activities.map((activity: ActivityDetails) => {
      const start = moment(activity.cact_start_date, 'YYYYMMDD h:mm').toDate();
      const end = moment(activity.cact_end_date, 'YYYYMMDD h:mm').toDate();
      const title = activity.act_description;
      return { start, end, title };
    });
  };

  return (
    <>
      <Calendar
        localizer={localizer}
        culture={i18next.language}
        onNavigate={(date) => setCurrentDate(date)}
        onDoubleClickEvent={(event) => console.log(event)}
        onSelectSlot={(slotInfo) => console.log(slotInfo)}
        onSelectEvent={(event) => console.log(event)}
        events={data?.data ? activityFormat(data?.data) : []}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500 }}
        messages={{
          agenda: t('studentVirtualClassroom.schedule.schedule'),
          next: t('studentVirtualClassroom.schedule.next'),
          previous: t('studentVirtualClassroom.schedule.back'),
          today: t('studentVirtualClassroom.schedule.today'),
          month: t('studentVirtualClassroom.schedule.month'),
          week: t('studentVirtualClassroom.schedule.week'),
          day: t('studentVirtualClassroom.schedule.day'),
          date: t('studentVirtualClassroom.schedule.date'),
          time: t('studentVirtualClassroom.schedule.time'),
          event: t('studentVirtualClassroom.schedule.activities'),
          noEventsInRange: t('studentVirtualClassroom.schedule.notActivities'),
        }}
      />
    </>
  );
};

export default Schedule;
