import { Grid, Typography } from '@material-ui/core';
import Chip from '../../../shared/components/extended/Chip';
import useFormatDate from '../../../shared/hooks/useFormatDate';

const CommentsList = ({
  applicationCommentsList,
  nextStatus,
}: {
  applicationCommentsList: any[];
  nextStatus: any;
}) => {
  const { formatDate } = useFormatDate();

  return (
    <>
      {applicationCommentsList?.length > 0 && (
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h2">Comentarios</Typography>
          </Grid>
          <Grid item container xs={12} mt={2}>
            {applicationCommentsList.map((comment, i) => (
              <Grid
                container
                border="1px solid #eee"
                key={`app-comment-${i}`}
                borderRadius="12px"
                p={2}
                mb={1}
              >
                <Grid item container justifyContent="space-between" xs={12}>
                  <Typography variant="body2">{comment?.user?.person?.pers_full_name}</Typography>
                  <Chip
                    label={comment?.type_application_status?.status?.st_name}
                    size="small"
                    chipcolor={
                      comment?.type_application_status?.status?.st_name === 'Enviado'
                        ? 'info'
                        : comment?.type_application_status?.status?.st_name === 'Rechazado'
                        ? 'error'
                        : 'success'
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2">{comment.transac_comments}</Typography>
                </Grid>
                <Grid item xs={12} mt={1}>
                  <Typography variant="body2">
                    {formatDate(comment.transac_register_date, { withTime: true })}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default CommentsList;
