import { useAppDispatch, useAppSelector } from '../../../../../../hooks/useRedux';
import DeleteAlert from '../../../../../shared/components/dialogs/DeleteAlert';
import { useDeleteEvaluationTypeMutation } from '../slices/evaluationTypeApiSlice';
import {
  setEvaluationTypeDeleteModalOpen,
  setEvaluationTypeIdToDelete,
} from '../slices/evaluationTypeSlice';

const EvaluationTypeDeleteDialog = () => {
  const dispatch = useAppDispatch();
  const { evaluationTypeIdToDelete, evaluationTypeDeleteModalOpen } = useAppSelector(
    (state) => state.evaluationType
  );

  const [deleteEvaluationType, { isLoading, isSuccess, error, isError }] =
    useDeleteEvaluationTypeMutation();

  const handleDelete = () => {
    deleteEvaluationType({
      id: evaluationTypeIdToDelete!,
    });
  };
  const handleClose = () => {
    dispatch(setEvaluationTypeIdToDelete(null));
    dispatch(setEvaluationTypeDeleteModalOpen(false));
  };

  return (
    <DeleteAlert
      open={evaluationTypeDeleteModalOpen}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      error={error as any}
      handleDelete={handleDelete}
      onClose={handleClose}
    />
  );
};

export default EvaluationTypeDeleteDialog;
