import { Grid, Skeleton, Typography } from '@material-ui/core';
// import { useAppSelector } from '../../../../hooks/useRedux';
import MainCard from '../../../shared/components/cards/MainCard';
import CalendarFormChooseInitCareer from '../components/CalendarFormChooseInitCareer';
import Breadcrumb, { Data } from '../components/Breadcrumbs';
import { useAppSelector } from '../../../../hooks/useRedux';
import { useLazyGetDaysTypesAvailablesQuery } from '../slices/acPlanPostgraduateApiSlice';
import { useEffect } from 'react';
import DeleteProgramDialog from '../components/dialogs/DeleteProgramDialog';
import { useTranslation } from 'react-i18next';
import CalendarSelection from './CalendarSelection';
import BackButton from '../../../shared/components/buttons/BackButton';

const ChooseInitCareerScreen = () => {
  const { t } = useTranslation();

  const data: Data[] = [{ name: t('posgraduate.title'), url: '/planeacion-academica-posgrado' }];

  const { colorEvents, postgraduateDeleteProgramDialogOpen, calendarIdSelected } = useAppSelector(
    (state) => state.academicPlanningPosgraduate
  );

  const [getDaysTypesAvailables, { isLoading: isLoadingDaysTypes }] =
    useLazyGetDaysTypesAvailablesQuery();

  useEffect(() => {
    if (colorEvents.length === 0) {
      getDaysTypesAvailables({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Grid component={MainCard} sx={{ padding: 2 }}>
        <Grid sx={{ mb: 3 }} container alignItems="center">
          <BackButton />
          <Typography variant="h2">{t('posgraduate.select_career')}</Typography>
        </Grid>
        <Grid sx={{ mb: 3 }}>
          <Breadcrumb data={data} currentName={t('posgraduate.select_career')} />
        </Grid>
        {isLoadingDaysTypes || colorEvents.length === 0 ? (
          <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
            <Grid item xs={8} md={4}>
              <Skeleton variant="rectangular" width="100%" height="10vh" />
            </Grid>

            <Grid item xs={8} md={9} mt={2}>
              <Skeleton variant="rectangular" width="100%" height="40vh" />
            </Grid>
          </Grid>
        ) : (
          <>
            <CalendarSelection />
            {calendarIdSelected && <CalendarFormChooseInitCareer />}

            {postgraduateDeleteProgramDialogOpen && <DeleteProgramDialog />}
          </>
        )}
      </Grid>
    </div>
  );
};

export default ChooseInitCareerScreen;
