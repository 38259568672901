import { useHistory, Link, useRouteMatch } from 'react-router-dom';
import EditButton from '../../../shared/components/tables/buttons/EditButton';
import DeleteButton from '../../../shared/components/tables/buttons/DeleteButton';
import AddButton from '../../../shared/components/tables/buttons/AddButton';

import { openDeleteModalWith, setAuthorToEdit } from '../slices/authorsSlice';
import useGuard from '../hooks/useGuard';

import StatusChip from '../../../shared/components/badges/StatusChip';
import SearchPaginatedTable, {
  FilterArrow,
} from '../../../shared/components/tables/SearchPaginatedTable';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { useGetAutoresQuery } from '../slices/authorsApiSlice';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const AuthorsTable = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(5);
  const [search, setSearch] = useState('');
  const [filterArrow, setFilterArrow] = useState<FilterArrow>(null!);

  const dispatch = useAppDispatch();
  const { authorToDelete } = useAppSelector((state) => state.authors);

  const { hasAccess } = useGuard();
  const { path } = useRouteMatch();
  const history = useHistory();

  const handleDelete = async (authorId: number) => {
    dispatch(openDeleteModalWith(authorId));
  };

  const goToAdd = async () => {
    await dispatch(setAuthorToEdit(undefined));
    history.push(`${path}/agregar`);
  };

  const {
    data: authors,
    isLoading: isLoadingAuthors,
    isFetching: isFetchingAuthors,
    isError: isErrorAuthors,
    error: errorAuthors,
    refetch,
  } = useGetAutoresQuery(
    {
      page,
      size,
      search,
      ...(filterArrow && { sort: filterArrow.name, type_sort: filterArrow.direction }),
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    if (authorToDelete === undefined) {
      refetch();
    }
  }, [authorToDelete, refetch]);

  return (
    <SearchPaginatedTable
      data={
        authors?.data.map((auth, index) => ({
          id: Number(authors?.from) + index,
          name: auth.name,
          statusId: auth.status_id,
          options: '',
        })) || []
      }
      headers={{
        id: 'Nº',
        name: t('shared.table.name'),
        statusId: t('shared.table.status'),
        options: t('shared.search_paginated_table.table.options'),
      }}
      keyExtractor={(item) => item.id.toString()}
      searchPlacehoder={t('authors.search')}
      listFilterArrows={{
        name: 'name',
        statusId: 'status_id',
      }}
      filterArrow={filterArrow}
      setFilterArrow={setFilterArrow}
      isLoading={isLoadingAuthors}
      isFetching={isFetchingAuthors}
      error={isErrorAuthors ? errorAuthors : undefined}
      perPage={size}
      setPerPage={setSize}
      total={authors?.total!}
      page={page}
      setPage={setPage}
      numHeader={4}
      search={search}
      setSearch={setSearch}
      customRenderers={{
        // id: (row) => row.id.id,

        statusId: (row) => <StatusChip statusId={Number(row.statusId)} />,

        options: (row) => (
          <>
            {hasAccess('edit') && (
              <Link to={`${path}/editar`}>
                <EditButton
                  title="Editar"
                  handleEdit={() => {
                    dispatch(
                      setAuthorToEdit(
                        authors?.data.find((at, index) => index === row.id - Number(authors?.from))
                      )
                    );
                  }}
                />
              </Link>
            )}
            {hasAccess('delete') && (
              <DeleteButton
                handleDelete={() => {
                  handleDelete(
                    authors?.data.find((at, index) => index === row.id - Number(authors?.from))?.id!
                  );
                }}
              />
            )}
            {!hasAccess('edit') && !hasAccess('delete') && 'Ninguna'}
          </>
        ),
      }}
      ActionButtons={<>{hasAccess('create') && <AddButton size="large" onClick={goToAdd} />}</>}
      customDataCellsProperties={{
        id: { align: 'center' },
        statusId: { align: 'center' },
        options: { align: 'center' },
      }}
    />
  );
};

export default AuthorsTable;
