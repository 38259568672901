import { Stack, StackProps } from '@material-ui/core';
import { FC } from 'react';

import { useAppSelector } from '../../../../hooks/useRedux';
import { drawerWidth } from '../../../shared/constants/uiConstants';

interface Props {
  containerProps?: StackProps;
}

const BottomFixedActions: FC<Props> = ({ containerProps, children }) => {
  const { opened } = useAppSelector((state) => state.customization);

  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{
        position: 'fixed',
        left: !opened ? '40px' : `calc(${drawerWidth}px + 20px)`,
        bottom: '80px',
      }}
      width={!opened ? `calc(100% - 60px)` : `calc(100% - (${drawerWidth}px + 30px))`}
      justifyContent="space-between"
      {...containerProps}
    >
      {children}
    </Stack>
  );
};

export default BottomFixedActions;
