import axios from 'axios';
import { getProtocol } from '../helpers/urlHelpers';
import { TenantsResponse } from '../modules/shared/interfaces/tenants.interfaces';

const protocol = getProtocol();

const baseUrl = `${protocol}://${localStorage.getItem('apiUrl')}/api`;

/**
 * Instancia de Axios para la API principal
 * @return {AxiosInstance}
 */
const mainApi = axios.create();

mainApi.interceptors.request.use(
  async function (config) {
    const apiUrl = localStorage.getItem('apiUrl');
    const logoPath = localStorage.getItem('logoPath');
    const banners = localStorage.getItem('banners');
    const defaultConfiguration = localStorage.getItem('defaultConfiguration');

    if (!apiUrl || !logoPath || !banners || !defaultConfiguration) {
      const { data: dataUrl } = await axios.get<TenantsResponse>(
        `${protocol}://${
          process.env.REACT_APP_LANDLORD_API_URL
        }/api/tenants?search=${window.location.origin.replace(':3000', '')}`
      );

      localStorage.setItem('apiUrl', dataUrl.domain);
      localStorage.setItem('logoPath', dataUrl.logo_path);
      localStorage.setItem('banners', JSON.stringify(dataUrl.banners));
      localStorage.setItem('defaultConfiguration', JSON.stringify(dataUrl.default_configuration));
      localStorage.setItem('msjBusinessHours', dataUrl.msj_business_hours || '');
    }

    config.baseURL = `${protocol}://${localStorage.getItem('apiUrl')}/api`;

    const token = localStorage.getItem('x-token');

    config.headers = {
      ...config.headers,
      ...(token && { Authorization: `Bearer ${token}` }),
    };

    return config;
  },
  function (error: any) {
    return Promise.reject(error);
  }
);

mainApi.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger

    return response;
  },
  function (error: any) {
    let errorMessage = '';
    let auxErrorMsg = '';

    if (!error.response) {
      return Promise.reject(error.mesage || 'Error inesperado, intente nuevamente');
    }

    if ('detail' in error.response.data) {
      auxErrorMsg = error.response.data.detail;
    } else {
      const enc = new TextDecoder('utf-8');
      const dataText = enc.decode(error.response.data);
      const dataJson = JSON.parse(dataText);

      auxErrorMsg = dataJson.detail;
    }

    if (Array.isArray(auxErrorMsg)) {
      if (auxErrorMsg.every((item: any) => typeof item === 'string')) {
        errorMessage = auxErrorMsg.join(' ');
      } else if (auxErrorMsg.every((item: any) => 'warning' in item || 'message' in item)) {
        errorMessage = auxErrorMsg.map((item: any) =>
          'warning' in item ? item.warning : item.message
        ) as any;
      }
    } else {
      errorMessage = auxErrorMsg;
    }

    return Promise.reject(errorMessage);
  }
);

export { mainApi, baseUrl };
