// import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import { makeStyles, useTheme } from '@material-ui/core/styles';

import StatusChip from '../../../../shared/components/badges/StatusChip';
import DeleteButton from '../../../../shared/components/tables/buttons/DeleteButton';
import EditButton from '../../../../shared/components/tables/buttons/EditButton';

import { openDeleteModalWith } from '../../slices/ELSSlice';
import { useAppDispatch } from '../../../../../hooks/useRedux';

import { Link, useRouteMatch } from 'react-router-dom';

import { ELS } from '../../interfaces/ELS.interfaces';

const useStyles = makeStyles((theme) => ({
  root: {
    // border: '1px solid red',
    padding: '20px 20px 20px 30px',
    boxShadow: '0px 0px 20px #BFDAF9',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',

    position: 'relative',

    '&::after': {
      content: `""`,
      position: 'absolute',
      top: 0,
      left: 0,
      borderTopLeftRadius: '10px',
      borderBottomLeftRadius: '10px',
      width: '10px',
      height: '100%',
    },
  },
  cardRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  cardTitle: {
    color: theme.palette.primary.dark,
    fontSize: '14px',
    fontWeight: 600,
  },
  cardSubTitle: {
    color: theme.palette.grey[400],
    fontSize: '14px',
    fontWeight: 400,
  },

  subjectType: {
    color: theme.palette.secondary.main,
    fontSize: '12px',
    fontWeight: 400,
  },
  subjectTypeText: {
    color: theme.palette.grey[600],
    fontSize: '12px',
    fontWeight: 400,
  },
  preRequisitesText: {
    color: '#31355C',
    fontSize: '12px',
    fontWeight: 600,
  },
  cardBadges: {
    display: 'flex',
    gap: '4px',
    alignItems: 'center',
    flexWrap: 'wrap',
    // justifyContent: 'flex-start',
  },
  subjectPreRequisites: {
    display: 'flex',
    gap: '4px',
    flexWrap: 'wrap',
    marginTop: '0.5rem',
  },
}));

interface Props {
  subject: ELS;
}

const SubjectCard = ({ subject }: Props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const handleDelete = async (subjectId: number) => {
    dispatch(openDeleteModalWith(subjectId));
  };

  const { path } = useRouteMatch();

  // const dispatch = useAppDispatch();
  // const { subjectHoverId } = useAppSelector((state) => state.meshs);

  const getOpcityValue = () => {
    let opacity = 1;

    // if (subjectHoverId && subjectHoverId === Number(subject?.matter_id)) {
    //   opacity = 0.5;
    // }

    return opacity;
  };

  const theme = useTheme();

  return (
    <Grid
      container
      className={classes.root}
      sx={{
        '&::after': {
          // bgcolor: subject?.simbology.sim_color,
          bgcolor: theme.palette.primary.main,
        },
        opacity: getOpcityValue,
      }}
    >
      <Grid container item xs={12}>
        <Grid className={classes.cardRow} item container spacing={4}>
          <Grid item xs={6} md={9} lg={8}>
            <Typography variant="subtitle1" className={classes.cardTitle}>
              {subject?.subject?.mat_name}
            </Typography>
          </Grid>
          <Grid
            item
            container
            xs={6}
            md={7}
            lg={4}
            direction="row-reverse"
            justifyContent="center"
            alignItems="center"
          >
            <Link
              to={`${path.replace(':groupId', subject?.group_nivelation_id)}/${subject?.id}/editar`}
            >
              <EditButton />
            </Link>
            <DeleteButton handleDelete={() => handleDelete(Number(subject?.id))} />
          </Grid>
        </Grid>

        <Grid item container className={classes.cardRow}>
          <Typography variant="subtitle2" className={classes.cardSubTitle}>
            {subject?.subject?.mat_description}
          </Typography>
          <StatusChip statusId={Number(subject?.status.id)} />
        </Grid>
      </Grid>
      <Grid className={classes.cardBadges}>GRUPO: {subject?.group_area?.arg_name}</Grid>
      <Grid className={classes.cardRow}>
        <Typography variant="subtitle2" className={classes.subjectTypeText}>
          {`${subject?.subject_hours} Horas`}
        </Typography>
        <Typography variant="subtitle2" className={classes.subjectTypeText}>
          cód {`${subject?.id}`}
        </Typography>
      </Grid>
      <Grid className={classes.cardRow}>
        <Typography variant="subtitle2" className={classes.subjectType}>
          Tipo de Materia
        </Typography>
        <Typography variant="subtitle2" className={classes.subjectTypeText}>
          Materia de Nivelación
        </Typography>
      </Grid>
    </Grid>
  );
};

export default SubjectCard;
