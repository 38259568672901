import { useReactToPrint } from 'react-to-print';
import {
  Dialog,
  DialogProps,
  Grid,
  Button,
  CircularProgress,
  Skeleton,
  Typography,
  useTheme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// import { jsPDF } from 'jspdf';
import { forwardRef, useRef, useState } from 'react';
import { IconFileAlert } from '@tabler/icons';
import { useLazyGetReceiptByTransactionIdQuery } from '../../slices/collectionTransactions';
import downloadjs from 'downloadjs';

const useStyles = makeStyles((theme) => ({
  paper: { maxWidth: '600px', width: 'auto', padding: 0 },
  iframe: {
    display: 'flex',
    height: 'auto',
    width: 'auto',
    //maxWidth: '600px',
    //maxHeight: '600px',
  },
  pdfButton: {
    width: '100px',
    boxShadow: '0',
  },
  pdf: {
    width: '78mm',
    paddingLeft: '7mm',
    '& *': {
      fontSize: '12px',
      fontFamily: 'Arial',
      fontWeight: 'normal',
      color: 'black',
    },
    '& th': {
      color: 'black !important',
      backgroundColor: 'white !important',
      borderTop: '1px solid black',
      borderBottom: '1px solid black',
    },
    '& td:not(.description, .price)': {
      backgroundColor: 'white !important',
      borderTop: '1px solid black',
      borderBottom: '1px solid black',
    },
    '& #logo_nbs_table': {
      '& td': {
        borderTop: 'none !important',
        borderBottom: 'none !important',
      },
    },
  },
}));

interface Props extends DialogProps {
  onClose: () => void;
  title: string;
  id: string;
  isLoadingPDF?: boolean;
  htmlC?: string;
  error?: string;
  avaliableDownload: boolean;
}

const DialogIframe = ({
  onClose,
  title,
  id,
  isLoadingPDF,
  htmlC,
  error,
  avaliableDownload = false,
  ...props
}: Props) => {
  const classes = useStyles();
  const theme = useTheme();

  const [pdfState, setPdfState] = useState(false);

  const [getPdf] = useLazyGetReceiptByTransactionIdQuery();

  const printAsPDF = async () => {
    try {
      setPdfState(true);

      const auxInput = document.getElementById('aux_id_transaction');

      const pdfBlob = await getPdf({
        idTransaction: (auxInput as any).value || 0,
      }).unwrap();

      const file = new Blob([pdfBlob], { type: 'application/pdf' });
      downloadjs(file);
      // const fileURL = URL.createObjectURL(file);
      // window.open(fileURL);
    } catch (error) {}

    setPdfState(false);

    //need to be visible on DOM
    // const input = document.getElementById(id);
    // let doc = new jsPDF('p', 'pt', 'a4', true);
    // doc.html(input!, {
    //   callback: function (doc) {
    //     setPdfState(false);
    //     doc.save(`${title ? title : 'comprobante'}.pdf`);
    //   },
    //   x: 15,
    //   y: 15,
    // });
  };

  const ComponentToPrint = forwardRef((props, ref) => {
    return (
      <div ref={ref as any} {...props} className={classes.pdf}>
        <div id={id} dangerouslySetInnerHTML={{ __html: htmlC as string }}></div>
      </div>
    );
  });

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef?.current || null,
  });

  // const theme = useTheme();
  return (
    <Dialog
      {...props}
      classes={{ paper: classes.paper }}
      sx={{ backgroundColor: 'transparent' }}
      onClose={onClose}
    >
      <Grid container gap={4}>
        <Grid item container xs={12} alignItems="center" justifyContent="center" mt={2}>
          {!isLoadingPDF && error ? (
            <>
              <Grid item container xs={12} alignItems="center" justifyContent="center">
                <IconFileAlert
                  size={60}
                  color={theme.palette.error.main}
                  style={{
                    padding: 12,
                    backgroundColor: theme.palette.orange.light,
                    borderRadius: '50%',
                  }}
                />
              </Grid>
              <Grid item xs={12} textAlign="center" mb={2}>
                <Typography variant="body2">
                  Ha ocurrido un error, vuelve a intentarlo más tarde{/*error*/}
                </Typography>
              </Grid>
            </>
          ) : (
            !isLoadingPDF && (
              <>
                <div
                  className={classes.iframe}
                  title={title}
                  id={id}
                  dangerouslySetInnerHTML={{ __html: htmlC as string }}
                ></div>
                <div hidden>
                  <ComponentToPrint ref={componentRef} />
                </div>
              </>
            )
          )}

          {isLoadingPDF && !document.getElementById(`${id}`)?.hasChildNodes() && (
            <div className={classes.iframe}>
              <Skeleton variant="rectangular" width={250} height={500} />
            </div>
          )}
        </Grid>
        {avaliableDownload && !isLoadingPDF && (
          <Grid
            item
            container
            xs={12}
            alignItems="center"
            justifyContent="center"
            paddingBottom={2}
          >
            <Button
              variant="outlined"
              onClick={printAsPDF}
              disabled={pdfState}
              endIcon={pdfState && <CircularProgress size={20} />}
            >
              PDF
            </Button>
            <Button
              variant="outlined"
              onClick={handlePrint}
              disabled={pdfState}
              endIcon={pdfState && <CircularProgress size={20} />}
              sx={{
                ml: 1,
              }}
            >
              Imprimir
            </Button>
          </Grid>
        )}
      </Grid>
    </Dialog>
  );
};

export default DialogIframe;
