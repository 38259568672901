import CustomSideDialog from '../../../../shared/components/dialogs/CustomSideDialog';
import ImportHourhandsForm from './ImportHourhandsForm';

interface Props {
  handleCloseDialog: () => void;
  orderImports: () => void;
}

const ImportHourhandsDialog = ({ handleCloseDialog, orderImports }: Props) => {
  return (
    <CustomSideDialog
      open
      handleCloseDialog={handleCloseDialog}
      title="Importar horarios de un periodo existente"
    >
      <ImportHourhandsForm handleClose={handleCloseDialog} orderImports={orderImports} />
    </CustomSideDialog>
  );
};

export default ImportHourhandsDialog;
