import { useEffect } from 'react';

import { Alert, Box, Grid } from '@material-ui/core';

import { useTranslation } from 'react-i18next';

import { useAppSelector, useAppDispatch } from '../../../../../../hooks/useRedux';
import { useGetDebtorsStudentQuery } from '../../../../../students/subjects-register/slices/subjectsRegisterApiSlice';
import { setDebtorStudentId } from '../../../slices/debtsPaymentSlice';

const AutoSelectDebtorStudent = () => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const { currentClient } = useAppSelector((state) => state.debtsPayment);

  const { data, isFetching } = useGetDebtorsStudentQuery({
    studentId: currentClient?.user.student?.id!,
  });

  useEffect(() => {
    if (data && !isFetching) {
      dispatch(setDebtorStudentId(data[0]?.debtor_student[0]?.id));
    }
  }, [data, dispatch, isFetching]);

  return (
    <Box sx={{ p: 3 }}>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={8}>
          <Alert severity="info" sx={{ p: 3 }}>
            {t('financial.debts_payment.wizard.amounts_pays_table.no_data')}
          </Alert>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AutoSelectDebtorStudent;
