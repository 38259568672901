import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Stack from '@material-ui/core/Stack';
import { IconPhone, IconMail } from '@tabler/icons';
import { Link } from 'react-router-dom';
import { WhatsApp } from '@material-ui/icons';
import { useGetInfoCompanyQuery } from '../../slices/paymentsApiSlice';

const ContactFoot = () => {
  const { data } = useGetInfoCompanyQuery({});
  return (
    <Grid container mt={2} spacing={1}>
      <Grid item xs={12} mb={2}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Box
          sx={{ border: '1px solid', borderColor: 'secondary', borderRadius: '8px', padding: 2 }}
        >
          <Stack spacing={2} direction="row" alignItems="center" justifyContent="center">
            <Grid item xs="auto">
              <Stack direction="row" spacing={1}>
                <Typography component="span" sx={{ fontWeight: 800 }}>
                  Comuníquese:
                </Typography>
                <IconMail size={20} />
                <Typography component="span">{data?.co_email}</Typography>
              </Stack>
            </Grid>
            {(data?.co_phone || data?.co_pbx) && (
              <Grid item xs="auto">
                <Stack direction="row" spacing={1}>
                  <IconPhone size={20} />
                  <Typography
                    alignItems={'center'}
                    sx={{
                      display: 'flex',
                      ...(data?.co_phone && data?.co_pbx ? { fontSize: '12px' } : {}),
                    }}
                  >
                    {`${data?.co_phone ? data?.co_phone : ''} ${
                      data?.co_phone && data?.co_pbx ? '-' : ''
                    } ${data?.co_pbx ? data?.co_pbx : ''}`}
                  </Typography>
                </Stack>
              </Grid>
            )}

            {data?.co_whatsapp && (
              <Grid item xs="auto">
                <Stack direction="row" spacing={1}>
                  <WhatsApp />
                  <Typography alignItems={'center'} sx={{ display: 'flex' }}>
                    {data?.co_whatsapp}
                  </Typography>
                </Stack>
              </Grid>
            )}

            <Grid item>
              <Typography component="span">
                <Link
                  to="/informacion-institucional"
                  target={'_blank'}
                  style={{ textDecorationLine: 'none', color: '#657F98', fontWeight: 800 }}
                >
                  Términos y Condiciones
                </Link>
              </Typography>
            </Grid>
          </Stack>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ContactFoot;
