import * as Yup from 'yup';

export const CashMethodSchema = Yup.object().shape({
  payAmount: Yup.string()
    .trim()
    .nullable()
    .required('financial.debts_payment.pay_dialog.form.amount_required')
    .matches(/^\d{1,100}(\.\d{1,2})?$/, 'shared.validation.only_numbers')
    .test('isValidCurrency', 'shared.validation.greater_than_zero', (val) => Number(val) > 0),
});

export const ChequeMethodSchema = Yup.object().shape({
  payAmount: Yup.string()
    .trim()
    .nullable()
    .required('financial.debts_payment.pay_dialog.form.amount_required')
    .matches(/^\d{1,100}(\.\d{1,2})?$/, 'shared.validation.only_numbers')
    .test('isValidCurrency', 'shared.validation.greater_than_zero', (val) => Number(val) > 0),
  FinancialInstitucionOriginId: Yup.number()
    .nullable()
    .required('financial.debts_payment.pay_dialog.form.emission_bank_required'),
  accountType: Yup.string().required(
    'financial.debts_payment.pay_dialog.form.cheque_method.account_type_required'
  ),
  chequeNum: Yup.string()
    .trim()
    .required('financial.debts_payment.pay_dialog.form.cheque_method.cheque_number_required')
    .matches(/^\d+$/, 'shared.validation.only_numbers'),
  issuedAccount: Yup.string()
    .trim()
    .required('financial.debts_payment.pay_dialog.form.cheque_method.account_number_required')
    .matches(/^\d+$/, 'shared.validation.only_numbers'),
  issuerName: Yup.string().required(
    'financial.debts_payment.pay_dialog.form.cheque_method.issuer_name_required'
  ),
  depositDate: Yup.date()
    .required('financial.debts_payment.pay_dialog.form.cheque_method.deposit_date_required')
    .typeError('La fecha es inválida, click para seleccionar.'),
  chequeAmount: Yup.string().trim().nullable(),
  // .required('financial.debts_payment.pay_dialog.form.cheque_method.cheque_amount_required')
  // .matches(/^\d{1,100}(\.\d{1,2})?$/, 'shared.validation.only_numbers')
  // .test('isValidCurrency', 'shared.validation.greater_than_zero', (val) => Number(val) > 0),
});

export const CreditDebitMethodSchema = Yup.object().shape({
  FinancialInstitucionOriginId: Yup.number()
    .nullable()
    .required('financial.debts_payment.pay_dialog.form.emission_bank_required'),
  FinancialInstitucionCardId: Yup.number()
    .nullable()
    .required('financial.debts_payment.pay_dialog.form.cards_method.card_required'),
  payAmount: Yup.string()
    .trim()
    .nullable()
    .required('financial.debts_payment.pay_dialog.form.amount_required')
    .matches(/^\d{1,100}(\.\d{1,2})?$/, 'shared.validation.only_numbers')
    .test('isValidCurrency', 'shared.validation.greater_than_zero', (val) => Number(val) > 0),
  issuerName: Yup.string().required(
    'financial.debts_payment.pay_dialog.form.cards_method.issuer_name_required'
  ),
  // cardCode: Yup.string()
  //   .trim()
  //   .nullable()
  //   .required('El código de la tarjeta es requerido.')
  //   .matches(/^\d+$/, 'shared.validation.only_numbers'),
  cardNumber: Yup.string()
    .trim()
    .nullable()
    .required('financial.debts_payment.pay_dialog.form.cards_method.card_number_required')
    .matches(/^\d+$/, 'shared.validation.only_numbers'),
  reference: Yup.string()
    .nullable()
    .required('financial.debts_payment.pay_dialog.form.reference_required'),
  redPago: Yup.number().nullable(),
  // .required('financial.debts_payment.pay_dialog.form.cards_method.payment_network_required'),
  // Se comenta para que sea opcional
  //
  // lote: Yup.string()
  //   .trim()
  //   .nullable()
  //   .required('financial.debts_payment.pay_dialog.form.cards_method.lote_required')
  //   .matches(/^\d{1,100}(\.\d{1,2})?$/, 'shared.validation.only_numbers')
  //   .test('isValidCurrency', 'shared.validation.greater_than_zero', (val) => Number(val) > 0),

  paymethod: Yup.string(),

  lote: Yup.string()
    .trim()
    .nullable()
    .when('paymethod', {
      is: 'forma-pago-tarjeta-cred',
      then: (schema) =>
        schema
          .required('financial.debts_payment.pay_dialog.form.cards_method.lote_required')
          .matches(/^\d{1,100}(\.\d{1,2})?$/, 'shared.validation.only_numbers')
          .test('isValidCurrency', 'shared.validation.greater_than_zero', (val) => Number(val) > 0),
      otherwise: (schema) => schema.notRequired(),
    }),

  // ca
});

export const TransferDepositMethodSchema = Yup.object().shape({
  payAmount: Yup.string()
    .trim()
    .nullable()
    .required('financial.debts_payment.pay_dialog.form.amount_required')
    .matches(/^\d{1,100}(\.\d{1,2})?$/, 'shared.validation.only_numbers')
    .test('isValidCurrency', 'shared.validation.greater_than_zero', (val) => Number(val) > 0),
  // FinancialInstitucionOriginId: Yup.number()
  //   .nullable()
  //   .when('isDeposit', {
  //     is: true,
  //     then: (schema) => schema.required('El banco emisor es requerido'),
  //     otherwise: (schema) => schema.notRequired(),
  //   }),
  originAccount: Yup.string()
    .nullable()
    .when('isDeposit', {
      is: false,
      then: (schema) =>
        schema
          // .required('La cuenta de origen es requerida')
          .matches(/^\d+$/, 'shared.validation.only_numbers'),
      otherwise: (schema) => schema.notRequired(),
    }),
  destinedAccount: Yup.string()
    .trim()
    .required(
      'financial.debts_payment.pay_dialog.form.deposit_transfer_method.destined_account_required'
    )
    .matches(/^\d+$/, 'shared.validation.only_numbers'),
  reference: Yup.string()
    .nullable()
    .required('financial.debts_payment.pay_dialog.form.reference_required'),
  depositDate: Yup.date()
    .required(
      'financial.debts_payment.pay_dialog.form.deposit_transfer_method.deposit_date_required'
    )
    .typeError('shared.validation.date_type'),
  voucherNumber: Yup.string()
    .trim()
    .required('financial.debts_payment.pay_dialog.form.deposit_transfer_method.voucher_required')
    .matches(/^\d+$/, 'shared.validation.only_numbers'),
});
