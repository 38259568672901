import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StudentRecordNiv } from '../interfaces/studentsNivelation.interface';

interface RegisterNivelationState {
  studentNivelationList: StudentRecordNiv[];
  page: number;
  size: number;
}

const initialState: RegisterNivelationState = {
  studentNivelationList: [],
  page: 1,
  size: 5,
};

const registerNivelationSlice = createSlice({
  name: 'registerNivelation',
  initialState,
  reducers: {
    setStudentsNivelationList: (state, action: PayloadAction<StudentRecordNiv[]>) => {
      state.studentNivelationList = action.payload;
    },
    setPageRegNivelation: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setSizeRegNivelation: (state, action: PayloadAction<number>) => {
      state.size = action.payload;
    },
  },
});

export const { setStudentsNivelationList, setPageRegNivelation, setSizeRegNivelation } =
  registerNivelationSlice.actions;

export default registerNivelationSlice.reducer;
