import { Switch /* Redirect */ } from 'react-router-dom';
// import { useAppSelector } from '../../../../hooks/useRedux';

import GuardRoute from '../../../../router/guards/GuardRoute';

// import { permissionKey, subPermissionKeys } from '../constants/permissions';
import PermissionsGradesScreen from '../screens/PermissionsGradesScreen';

const PermissionsGradesRouter = () => {
  // const { currentModule } = useAppSelector((state) => state.access);

  // const hasPermission = 'permissions' in currentModule?.permissions!;

  // if (!hasPermission) return <Redirect to="/" />;
  return (
    <Switch>
      <GuardRoute
        exact
        path="/permisos-notas"
        component={PermissionsGradesScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.read}
      />
    </Switch>
  );
};

export default PermissionsGradesRouter;
