import { useState } from 'react';

import {
  Autocomplete,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';

import AnimateButton from '../../../../shared/components/extended/AnimateButton';
import DragArea from '../../../../shared/components/files/DragArea';
import InputLabel from '../../../../shared/components/forms/InputLabel';
import TextFieldFetching from '../../../../shared/components/TextFieldFetching';
import {
  useGetTypeDocumentsByStudentQuery,
  useSaveStudentDocumentMutation,
  useUpdateStudentDocumentMutation,
} from '../../slices/studentsApiSlice';
import { useUploadFilesMutation } from '../../../../shared/slices/uploadFilesApi';
import ErrorAlert from '../../../../shared/components/alerts/ErrorAlert';
import { StudentDocumentPayload } from '../../interfaces/students.interface';

interface Props {
  dataId: number;
  studentIdentification?: string;
  dataEdit?: StudentDocumentPayload | null;
  closeModal: () => void;
}

const UploadDocDialog = ({ dataId, dataEdit, studentIdentification, closeModal }: Props) => {
  const { studentId } = useParams<{ studentId: string }>();

  const identification = dataEdit ? '' : `-${studentIdentification || ''}`;

  const [document, setDocument] = useState<File | null>(null);
  const [showAlerts, setShowAlerts] = useState(true);
  const [error, setError] = useState<string[] | string>('');

  const [uploadFile, { isLoading: isUploading, isError: isErrorUpload }] = useUploadFilesMutation();
  const [saveDocument, { isLoading, isError }] = useSaveStudentDocumentMutation();
  const [updateDocument, { isLoading: isLoadingUpdate, isError: isErrorUpdate }] =
    useUpdateStudentDocumentMutation();

  const { data: typeDocuments, isFetching } = useGetTypeDocumentsByStudentQuery({
    studentId: studentId,
  });

  const { errors, touched, values, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      docUrl: dataEdit?.stu_doc_url || '',
      docType: dataEdit?.type_document_id || '',
      docName: dataEdit?.stu_doc_name_file || '',
    },
    // validationSchema: DepositPaymentSchema,
    // validate: ({ start_process, end_process, startDate, endDate }) => {
    //   return validateDates(start_process, end_process, startDate, endDate);
    // },
    onSubmit: async (values) => {
      try {
        let urlPhoto = '';

        if (document) {
          urlPhoto = await uploadFile({
            files: document,
          }).unwrap();
        }

        const payload = {
          stu_doc_name_file: `${values.docName}${identification}`,
          type_document_id: values.docType,
          stu_doc_url: urlPhoto,
          student_id: studentId!,
        };

        if (dataId) {
          await updateDocument({
            studentDocumentId: dataId,
            payload,
          });
        } else
          await saveDocument({
            studentId: studentId!,
            payload,
          }).unwrap();

        closeModal();
      } catch (error: any) {
        setError(error);
        setShowAlerts(true);
      }
    },
  });

  return (
    <form onSubmit={handleSubmit}>
      <Grid container>
        {/* <Grid container p={2}> */}
        <Grid item xs={12}>
          <Typography variant="h3" component="h4">
            Cargar documento
          </Typography>
        </Grid>

        {(isError || isErrorUpdate || isErrorUpload) && showAlerts && (
          <Grid item xs={12}>
            <ErrorAlert
              message={error as string}
              handleDismiss={() => {
                setShowAlerts(false);
                setError('');
              }}
            />
          </Grid>
        )}

        {!dataId && (
          <Grid item xs={12}>
            <InputLabel>Seleccione el tipo de documento</InputLabel>
            {isFetching ? (
              <TextFieldFetching />
            ) : (
              <Autocomplete
                options={typeDocuments || []}
                getOptionLabel={(option) => option.typ_doc_name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(errors.docType && touched.docType)}
                    placeholder="Seleccione un tipo de documento"
                  />
                )}
                onChange={(_, value) => {
                  setFieldValue('docType', value?.id || '');
                  setFieldValue('docName', value?.typ_doc_name || '');
                }}
                fullWidth
                clearIcon={null}
                value={typeDocuments?.find((dt) => dt.id === +values.docType) || null}
                //   defaultValue={fIA.length === 1 ? fIA[0] : null}
              />
            )}
            {errors.docType && touched.docType && (
              <FormHelperText error>{errors.docType}</FormHelperText>
            )}
          </Grid>
        )}

        <Grid item xs={12}>
          <InputLabel>Nombre del documento</InputLabel>
          <TextField
            fullWidth
            placeholder="Nombre del documento"
            disabled
            value={values.docName ? `${values.docName}${identification}` : null}
          />
        </Grid>

        <Grid item xs={12}>
          <DragArea
            handleChange={(e) => setDocument(e.target.files![0])}
            label="Documento"
            // description="La foto del director debe ser una imagen de no más de 2MB"
            typesAccepted="application/pdf"
          />
        </Grid>

        <Grid item xs={12}>
          <Stack direction="row" justifyContent="center">
            <Button sx={{ my: 3, ml: 1 }} /* disabled={isLoading} */ onClick={closeModal}>
              Regresar
            </Button>
            <AnimateButton>
              <Button
                variant="contained"
                type="submit"
                sx={{ my: 3, ml: 1 }}
                disabled={isLoading || isUploading || isLoadingUpdate}
                endIcon={
                  (isUploading || isLoadingUpdate || isLoading) && <CircularProgress size={20} />
                }
              >
                Guardar
              </Button>
            </AnimateButton>
          </Stack>
        </Grid>
        {/* </Grid> */}
      </Grid>
    </form>
  );
};

export default UploadDocDialog;
