import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../../../hooks/useRedux';
import MainCard from '../../../../../shared/components/cards/MainCard';
import QuestionDeleteDialog from '../components/QuestionDeleteDialog';
import QuestionSideDialog from '../components/QuestionSideDialog';
import QuestionsTable from '../components/QuestionsTable';

const QuestionsScreen = () => {
  const { t } = useTranslation();

  const { questionsSideDialogOpen, questionsDeleteModalOpen } = useAppSelector(
    (state) => state.questions
  );

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">{t('maintenance.questions.title')}</Typography>
        <Typography variant="body1" color="textSecondary">
          {t('Las preguntas permiten realizar el formato de la rubrica.')}
        </Typography>
      </Grid>
      <QuestionsTable />

      {questionsSideDialogOpen && <QuestionSideDialog />}
      {questionsDeleteModalOpen && <QuestionDeleteDialog />}
    </Grid>
  );
};

export default QuestionsScreen;
