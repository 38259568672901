import {
  useHistory,
  //Link,
  useRouteMatch,
} from 'react-router-dom';
import { Button, Grid, IconButton, Tooltip } from '@material-ui/core';
import useGuard from '../hooks/useGuard';
import { DateRange } from '@material-ui/lab';
import SearchPaginatedTable from '../../../shared/components/tables/SearchPaginatedTable';
import { useEffect, useState } from 'react';
import { useLazyGetLendingsQuery } from '../slices/lendingApiSlice';
import { formatDistance, format } from 'date-fns';
import { es } from 'date-fns/locale';
import AddButton from '../../../shared/components/tables/buttons/AddButton';
import { IconRotateClockwise } from '@tabler/icons';
import CustomDialog from '../../../shared/components/dialogs/CustomDialog';
import FormRenewal from './FormRenewal';
import LocalizedDateRangePicker from '../../../shared/components/controlled/LocalizedDateRangePicker';
import { useAppSelector } from '../../../../hooks/useRedux';
import { useTableSearch } from '../../../../hooks/useTableSearch';
import { updateLendingsTable } from '../slices/lendingsSlice';

const LendingsTable = () => {
  const { hasAccess } = useGuard();
  const { path } = useRouteMatch();
  const history = useHistory();

  const { filterArrow, page, perPage, search } = useAppSelector(
    (state) => state.lendings.lendingsTable
  );

  const { setFilterArrow, setPage, setPerPage, setSearch } = useTableSearch({
    filterArrow,
    page,
    perPage,
    search,
    updateFunction: updateLendingsTable,
  });

  const [startEndDate, setStartEndDate] = useState<DateRange<Date>>([new Date(), null]);

  const [lendingId, setLendingId] = useState<string | undefined>();

  const [getLendings, { data: lendings, isLoading, isFetching, isError, error }] =
    useLazyGetLendingsQuery();

  const goToAdd = () => {
    history.push(`${path}/agregar`);
  };

  const handleGetLendings = () => {
    getLendings({
      borrow_start_date: format(
        new Date(startEndDate[0] ? startEndDate[0] : new Date()),
        'yyyy-MM-dd'
      ),
      borrow_end_date: startEndDate[1]
        ? format(new Date(startEndDate[1]), 'yyyy-MM-dd')
        : undefined,
      not_return: 1,
      page,
      size: perPage,
      search,
      ...(filterArrow && { sort: filterArrow.name, type_sort: filterArrow.direction }),
    });
  };

  // useEffect(() => {
  //   getLendings({
  //     borrow_start_date: format(
  //       new Date(startEndDate[0] ? startEndDate[0] : new Date()),
  //       'yyyy-MM-dd'
  //     ),
  //     borrow_end_date: startEndDate[1]
  //       ? format(new Date(startEndDate[1]), 'yyyy-MM-dd')
  //       : undefined,
  //     not_return: 1,
  //     page,
  //     size,
  //     search,
  //     ...(filterArrow && { sort: filterArrow.name, type_sort: filterArrow.direction }),
  //   });
  //   // eslint-disable-next-line
  // }, [filterArrow, getLendings]);

  useEffect(() => {
    getLendings({
      borrow_start_date: format(
        new Date(startEndDate[0] ? startEndDate[0] : new Date()),
        'yyyy-MM-dd'
      ),
      borrow_end_date: startEndDate[1]
        ? format(new Date(startEndDate[1]), 'yyyy-MM-dd')
        : undefined,
      not_return: 1,
      page,
      size: perPage,
      search,
      ...(filterArrow && { sort: filterArrow.name, type_sort: filterArrow.direction }),
    });
    // eslint-disable-next-line
  }, [filterArrow, page, perPage, search, getLendings]);

  const handleRenewal = async (id: string) => {
    await setLendingId(id);
  };

  return (
    <>
      <Grid container justifyContent="flex-end" spacing={2}>
        <Grid item xs={6} md={6}>
          <Grid item xs={12}>
            <LocalizedDateRangePicker
              startText="Fecha de Inicio"
              endText="Fecha de Fin"
              value={startEndDate}
              onChange={(newValue) => {
                setStartEndDate(newValue);
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} lg={12} textAlign="right">
          <Button variant="contained" size="medium" color="primary" onClick={handleGetLendings}>
            Buscar
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item md={12}>
          <SearchPaginatedTable
            data={
              lendings?.data.map((ld, index) => ({
                id: index + Number(lendings?.from),
                code: ld.model_data?.institution_barcode ? ld.model_data?.institution_barcode : '',
                books: ld.model_data?.title,
                user:
                  ld.user_profile.user?.person.pers_firstname +
                  ' ' +
                  ld.user_profile.user?.person.pers_first_lastname,
                type: ld.type,
                type_user: ld.user_profile.profile.pro_name,
                borrow_date: ld.borrow_date,
                return_date: ld.estimated_return_date,
                days_lefts: ld.estimated_return_date
                  ? formatDistance(new Date(ld.estimated_return_date), new Date(), {
                      addSuffix: true,
                      locale: es,
                    })
                  : '-',
                options: '',
                //ammount: ld.borrow_books_amount || '-',
              })) || []
            }
            headers={{
              id: 'Nº',
              code: 'Código de Libro',
              books: 'Libro',
              user: 'Usuario',
              type: 'Tipo de Préstamo',
              type_user: 'Tipo de Usuario',
              borrow_date: 'Fecha de Préstamo',
              return_date: 'Fecha de devolución',
              days_lefts: 'Préstamo Termina',
              options: 'Opciones',
              //ammount: 'Cantidad',
            }}
            // keyExtractor={(item) => item.code.toString()}
            keyExtractor={(item) => item.id.toString()}
            searchPlacehoder="Buscar préstamo"
            listFilterArrows={{
              books: 'books',
              borrow_date: 'borrow_date',
              return_date: 'return_date',
              type: 'type',
              type_user: 'user_profile',
              //ammount: 'borrow_books_amount',
              user: 'user',
            }}
            filterArrow={filterArrow}
            setFilterArrow={setFilterArrow}
            isLoading={isLoading}
            isFetching={isFetching}
            error={isError ? error : undefined}
            perPage={perPage}
            setPerPage={setPerPage}
            total={lendings?.total!}
            page={page}
            setPage={setPage}
            numHeader={9}
            search={search}
            setSearch={setSearch}
            customRenderers={{
              options: (row) => (
                <>
                  <Tooltip title="Renovar Préstamo">
                    <IconButton
                      onClick={() =>
                        handleRenewal(
                          lendings?.data.find(
                            (el, index) => index === Number(row.id) - Number(lendings.from)
                          )?.borrow_code!
                        )
                      }
                    >
                      <IconRotateClockwise />
                    </IconButton>
                  </Tooltip>
                </>
              ),
            }}
            ActionButtons={
              <>
                {hasAccess('create') && (
                  <AddButton btnText="Nuevo Préstamo" size="large" onClick={goToAdd} />
                )}
              </>
            }
            customDataCellsProperties={{
              id: { align: 'center' },
              code: { align: 'center' },
              type: { align: 'center' },
              type_user: { align: 'center' },
              borrow_date: { align: 'center' },
              return_date: { align: 'center' },
              days_lefts: { align: 'center' },
              //ammount: { align: 'center' },
              user: { align: 'center' },
            }}
          />
        </Grid>
      </Grid>

      {lendingId && (
        <CustomDialog
          open={Boolean(lendingId)}
          onClose={() => setLendingId(undefined)}
          actionHandler={() => {}}
          isError={false}
          isLoading={false}
          isSuccess={false}
          title="Renovar Préstamo"
          confirmBtnMessage="Sí, Renovar"
          hideFooter={true}
        >
          <FormRenewal handleClose={() => setLendingId(undefined)} idLending={lendingId} />
        </CustomDialog>
      )}
    </>
  );
};

export default LendingsTable;
