import { useState, useEffect } from 'react';
import { CardActions, CardContent, Divider, Grid, Typography, useTheme } from '@material-ui/core';
import { IconCalendarEvent, IconClock, IconUser } from '@tabler/icons';
import { useAppSelector } from '../../../../../../hooks/useRedux';
// import MainCard from "../../../../../shared/components/cards/MainCard";
import moment from 'moment';
import { useStudentVCEvaluationFinishMutation } from '../../../slices/virtualClassroomApiSlice';
import { useHistory, useParams } from 'react-router-dom';
import ErrorAlert from '../../../../../shared/components/alerts/ErrorAlert';
import { navigateTopSmooth } from '../../../../../shared/helpers';
import SubCard from '../../../../../shared/components/cards/SubCard';
import { useTranslation } from 'react-i18next';

const EvaluationHeaderInfo = () => {
  const theme = useTheme();

  const [timer, setTimer] = useState<string>();

  const [timeFinished, setTimeFinished] = useState<boolean>(false);

  const { evaluationId } = useParams<{ evaluationId: string }>();

  const { currentProfile, currentStudent } = useAppSelector((state) => state.access);

  const { language } = useAppSelector((state) => state.customization);

  const [finishEvaluation, { isSuccess }] = useStudentVCEvaluationFinishMutation();

  const history = useHistory();

  const { evaluationActive: evaluation } = useAppSelector(
    (state) => state.virtualClassroomStudent.evaluation
  );

  const { t } = useTranslation();

  useEffect(() => {
    const currentTime = evaluation
      ? moment(evaluation?.cab_course_student_activity?.act_start_date!).valueOf()
      : 0;
    const eventTime = evaluation
      ? moment(evaluation?.course_activity_one?.cact_end_date!).valueOf()
      : 0;
    // const eventTime = 1366549900;
    // const currentTime = 1366549800;
    const diffTime = eventTime - currentTime;
    let duration: any = moment.duration(diffTime, 'milliseconds');
    let durationDays: any = moment.duration(diffTime).days();
    const interval = 1000;
    const intervalId = setInterval(() => {
      duration = moment.duration(duration - interval, 'milliseconds');
      durationDays = moment.duration(eventTime - currentTime).days();
      if (durationDays === 0) {
        const currentTime = duration.hours() + ':' + duration.minutes() + ':' + duration.seconds();
        setTimer(currentTime);
      } else {
        const currentTime =
          durationDays +
          ` ${t('studentVirtualClassroom.test.days')} -- ` +
          duration.hours() +
          ':' +
          duration.minutes() +
          ':' +
          duration.seconds();
        setTimer(currentTime);
      }

      if (duration.minutes() === 0 && duration.seconds() === 0) {
        finishEvaluation({
          evaluationId: Number(evaluationId),
          studentId: currentStudent?.id!,
          profileId: currentProfile?.profileId!,
        });
        clearTimeout(intervalId);
        navigateTopSmooth();
        setTimeFinished(true);
      }
    }, interval);
    return () => {
      clearTimeout(intervalId);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (timeFinished) {
      history.push(`/aula-virtual-estudiante/test`);
    }
  }, [timeFinished, evaluationId, history]);

  return (
    <Grid container spacing={1} alignItems="flex-start">
      <Grid item xs={12}>
        <Grid item xs={12} sx={{ mb: 2 }}>
          {isSuccess && timeFinished && (
            <ErrorAlert
              message="Tiempo de evaluación ha sido finalizado."
              handleDismiss={() => setTimeFinished(false)}
            />
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <SubCard content={false}>
          <CardContent>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Grid container item direction="column" justifyContent="space-between" spacing={2}>
                  <Grid item>
                    <Typography variant="h4">{evaluation?.act_description}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="h5">
                      {Number(evaluation?.act_score_activity)}{' '}
                      {t('studentVirtualClassroom.test.points')}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid
                  container
                  item
                  justifyContent="space-between"
                  alignContent={'center'}
                  spacing={2}
                >
                  <Grid item>
                    <Typography variant="h4">
                      -- / {Number(evaluation?.act_score_activity!)}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <IconUser />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions sx={{ justifyContent: 'space-between' }}>
            <Grid container direction="row" spacing={1}>
              <Grid item>
                <IconCalendarEvent />
              </Grid>
              <Grid item>
                <Typography variant="h4">{t('studentVirtualClassroom.test.startDate')}:</Typography>
              </Grid>
              <Grid item>
                <Typography variant="h5">
                  {moment(evaluation?.course_activity_one?.cact_start_date!)
                    .locale(language)
                    .format('dddd, D MMMM YYYY')}
                </Typography>
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={1}>
              <Grid item>
                <IconClock />
              </Grid>
              <Grid item>
                <Typography variant="h4">{t('studentVirtualClassroom.test.startHour')}:</Typography>
              </Grid>
              <Grid item>
                <Typography variant="h5">
                  {moment(evaluation?.cab_course_student_activity?.act_start_date!).format('h:mm')}
                </Typography>
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={1}>
              <Grid item>
                <Typography variant="h4" color={theme.palette.error.dark}>
                  {t('studentVirtualClassroom.test.testTime')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography color="#c62828" variant="h5" id="time-evaluation">
                  {timer}
                </Typography>
              </Grid>
            </Grid>
          </CardActions>
        </SubCard>
      </Grid>
    </Grid>
  );
};

export default EvaluationHeaderInfo;
