import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISearchTable } from '../../../shared/interfaces/paginatedTable.interface';
import { Agreement } from '../interfaces/agreements.interfaces';

interface AgreementsState {
  agreementsList: Agreement[];
  deleteModalOpen: boolean;
  agreementIdToDelete?: number;
  agreementTable: ISearchTable;

  statusIdSelected: number | null;
  agreementTypeIdSelected: string | null;
}

const initialState: AgreementsState = {
  agreementsList: [],
  deleteModalOpen: false,
  agreementTable: {
    page: 1,
    perPage: 5,
    search: '',
  },
  statusIdSelected: null,
  agreementTypeIdSelected: null,
};

const AgreementsSlice = createSlice({
  name: 'Agreements',
  initialState,
  reducers: {
    setAgreementList: (state, action: PayloadAction<Agreement[]>) => {
      state.agreementsList = action.payload;
    },
    setDeleteModalOpen: (state, action: PayloadAction<boolean>) => {
      state.deleteModalOpen = action.payload;
    },
    openDeleteModalWith: (state, action: PayloadAction<number>) => {
      state.agreementIdToDelete = action.payload;
      state.deleteModalOpen = true;
    },
    resetAgreementIdToDelete: (state) => {
      state.agreementIdToDelete = undefined;
    },
    updateAgreementTableStates: (state, action: PayloadAction<ISearchTable>) => {
      state.agreementTable = action.payload;
    },
    setStatusIdSelected: (state, action: PayloadAction<number | null>) => {
      state.statusIdSelected = action.payload;
    },
    setAgreementTypeIdSelected: (state, action: PayloadAction<string | null>) => {
      state.agreementTypeIdSelected = action.payload;
    },
  },
});

export const {
  setAgreementList,
  setDeleteModalOpen,
  openDeleteModalWith,
  resetAgreementIdToDelete,
  updateAgreementTableStates,
  setStatusIdSelected,
  setAgreementTypeIdSelected,
} = AgreementsSlice.actions;

export default AgreementsSlice.reducer;
