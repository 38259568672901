import React, { useEffect, useRef, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import Grid from '@material-ui/core/Grid';
import Stack from '@material-ui/core/Stack';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Avatar } from '@material-ui/core';

import ProfileSkeletonCard from '../../../shared/components/cards/Skeleton/ProfileSkeletonCard';
import MainCard from '../../../shared/components/cards/MainCard';
import { useAppSelector } from '../../../../hooks/useRedux';

import Noloadedavatar from './../../../../../src/assets/images/users/noloadedavatar.png';
import {
  useGetAuthorityEducationLevelQuery,
  useGetContactByEducationLevelQuery,
} from '../../../maintenance/education-levels/slices/educationLevelsApiSlice';
import FlipCard from '../../../shared/components/cards/FlipCard';
import useSizeObserver from '../../../shared/hooks/useSizeObserver';
import AcademicContactNetworkTable from './builder/AcademicContactNetworkTable';
import LoadingChildren from '../../../shared/components/LoadingChildren';
import { useGetRedlinksAvatarQueryQuery } from '../../../shared/slices/redlinksApi';

const cardHeight = 500;

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.dark.dark : theme.palette.primary.main,
    color: '#fff',
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
    height: 'auto',
    '&>div': {
      position: 'relative',
      zIndex: 5,
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      width: '150px',
      height: '150px',
      background:
        theme.palette.mode === 'dark'
          ? 'linear-gradient(210.04deg, ' +
            theme.palette.primary.dark +
            ' -50.94%, rgba(144, 202, 249, 0) 95.49%)'
          : 'linear-gradient(210.04deg, ' +
            theme.palette.primary[200] +
            ' -50.94%, rgba(144, 202, 249, 0) 95.49%)',
      borderRadius: '50%',
      zIndex: 1,
      // top: '-40px',
      // right: '-15px',
      bottom: '-30px',
      left: '-50px',
      [theme.breakpoints.down('xs')]: {
        top: '-105px',
        right: '-140px',
      },
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      zIndex: 1,
      width: '75px',
      height: '75px',
      background:
        theme.palette.mode === 'dark'
          ? 'linear-gradient(140.9deg, ' +
            theme.palette.primary.dark +
            ' -14.02%, rgba(144, 202, 249, 0) 82.50%)'
          : theme.palette.primary[200],
      borderRadius: '50%',
      top: '-40px',
      right: '-15px',
      opacity: 0.5,
      [theme.breakpoints.down('xs')]: {
        top: '-155px',
        right: '-70px',
      },
    },
  },

  cardFlipped: {
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.dark.dark : theme.palette.primary.main,
    color: '#fff',
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
    height: 'auto',
    '&>div': {
      position: 'relative',
      zIndex: 5,
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      width: '150px',
      height: '150px',
      background:
        theme.palette.mode === 'dark'
          ? 'linear-gradient(210.04deg, ' +
            theme.palette.primary.dark +
            ' -50.94%, rgba(144, 202, 249, 0) 95.49%)'
          : 'linear-gradient(210.04deg, ' +
            theme.palette.primary[200] +
            ' -50.94%, rgba(144, 202, 249, 0) 95.49%)',
      borderRadius: '50%',
      zIndex: 1,
      // top: '-40px',
      // right: '-15px',
      bottom: '-30px',
      right: '-50px',
      [theme.breakpoints.down('xs')]: {
        top: '-105px',
        right: '-140px',
      },
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      zIndex: 1,
      width: '75px',
      height: '75px',
      background:
        theme.palette.mode === 'dark'
          ? 'linear-gradient(140.9deg, ' +
            theme.palette.primary.dark +
            ' -14.02%, rgba(144, 202, 249, 0) 82.50%)'
          : theme.palette.primary[200],
      borderRadius: '50%',
      top: '-40px',
      left: '-15px',
      opacity: 0.5,
      [theme.breakpoints.down('xs')]: {
        top: '-155px',
        right: '-70px',
      },
    },
  },
  content: {
    padding: '20px !important',
  },
  avatar: {
    ...theme.typography.commonAvatar,
    ...theme.typography.largeAvatar,
    cursor: 'default',
    margin: '8px',
    height: 'auto',
    width: 'auto',
    backgroundColor: 'transparent',
    // backgroundColor: theme.palette.secondary[200],
    padding: '0px 15px',
  },
}));

const AcademicDeanContacts = () => {
  const theme = useTheme();
  const classes = useStyles();

  const containerRef1 = useRef<HTMLDivElement | null>(null);

  const { height } = useSizeObserver({ ref: containerRef1 });
  console.log('sadsadasadass: ', { height });

  const { userInfo } = useAppSelector((state) => state.auth);
  const { currentRecordStudent } = useAppSelector((state) => state.access);

  const [typeTable, setTypeTable] = useState<'contact' | 'network' | null>(null);

  const [photo, setPhoto] = useState(Noloadedavatar);

  const { data: redlinksInfo } = useGetRedlinksAvatarQueryQuery(
    {
      identification: currentRecordStudent?.collaborator?.pers_identification || '0',
    },
    { skip: !currentRecordStudent?.collaborator?.pers_identification }
  );

  const handleResetPhoto = () => {
    setPhoto(Noloadedavatar);
  };

  useEffect(() => {
    if (redlinksInfo?.result?.foto) setPhoto(redlinksInfo?.result?.foto);
  }, [redlinksInfo]);

  const { data: currentAuthority, isLoading: isLoadingCurrentAuthoity } =
    useGetAuthorityEducationLevelQuery({
      educationLevelId: Number(currentRecordStudent?.education_level?.father?.id) || 0,
    });

  const {
    data: tableData,
    isLoading: isLoadingTable,
    isFetching: isFetchingTable,
    error: errorTable,
  } = useGetContactByEducationLevelQuery({
    educationLevelId: Number(currentRecordStudent?.education_level?.father?.id) || 0,
  });

  return (
    <React.Fragment>
      <FlipCard
        isFlipped={!!typeTable}
        height={`${height || cardHeight}px`}
        width={`100%`}
        noTap={true}
        frontContent={
          <MainCard
            ref={containerRef1}
            border={false}
            className={classes.card}
            contentClass={classes.content}
          >
            <LoadingChildren
              isLoading={isLoadingCurrentAuthoity}
              loadingSkeleton={
                <ProfileSkeletonCard
                  sx={{
                    // height: '100%',
                    background: 'transparent',
                  }}
                  /* className={classes.card} */
                />
              }
            >
              <Stack spacing={4} py={2}>
                <Grid item>
                  <Typography
                    // variant="h3"
                    fontSize="1.2rem"
                    fontWeight="bold" /* color={theme.palette.primary[800]} */
                  >
                    Autoridad académica
                  </Typography>
                </Grid>

                <Grid item>
                  <Avatar
                    variant="rounded"
                    className={`${classes.avatar}`}
                    // sx={{ mb: isNormal ? 0 : 1 }}
                  >
                    <img
                      style={{
                        height: '150px',
                        borderRadius: '50%',
                      }}
                      src={photo}
                      onError={handleResetPhoto}
                      alt={userInfo?.fullname ? userInfo.fullname : 'Estudiante'}
                    />
                    {/* </object> */}
                  </Avatar>
                </Grid>

                <Stack spacing={1}>
                  <Typography
                    component="p"
                    width="100%"
                    fontSize="1rem"
                    /* variant="h4" */
                    align="center"
                  >
                    {currentAuthority?.pers_full_name}
                  </Typography>

                  <Typography
                    component="p"
                    width="100%"
                    fontSize="0.9rem"
                    // variant="subtitle1"
                    align="center"
                  >
                    {currentAuthority?.pers_cell}
                  </Typography>

                  {/* <Typography
                    component="p"
                    // variant="subtitle1"
                    fontSize="0.9rem"
                    width="100%"
                    align="right"
                    // color={theme.palette.primary.main}
                  >
                    Facultad de Turismo
                  </Typography> */}
                </Stack>

                <Grid item xs={12}>
                  <Grid container justifyContent="center" spacing={2}>
                    <Grid item py={1}>
                      {/* <Button variant="contained" sx={{ borderRadius: `${theme.shape.borderRadius}` }}> */}
                      <Button
                        variant="contained"
                        color="inherit"
                        size="small"
                        sx={{
                          borderRadius: `12px`,
                          py: 0,
                          px: 4,
                          backgroundColor: `${theme.palette.primary.light}`,
                          color: `${theme.palette.primary.main}`,
                          fontWeight: 700,
                          fontSize: '1.1rem',
                        }}
                        onClick={() => setTypeTable('contact')}
                      >
                        Contactos
                      </Button>
                    </Grid>
                    <Grid item py={1}>
                      {/* <Button variant="contained" sx={{ borderRadius: `${theme.shape.borderRadius}` }}> */}
                      <Button
                        variant="contained"
                        color="inherit"
                        size="small"
                        sx={{
                          borderRadius: `12px`,
                          py: 0,
                          px: 4,
                          backgroundColor: `${theme.palette.primary.light}`,
                          color: `${theme.palette.primary.main}`,
                          fontWeight: 700,
                          fontSize: '1.1rem',
                        }}
                        onClick={() => setTypeTable('network')}
                      >
                        Redes
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Stack>
            </LoadingChildren>
          </MainCard>
        }
        backContent={
          <>
            <div className={classes.cardFlipped} style={{ height, borderRadius: 14 }}>
              <Stack spacing={2} py={2} className={classes.content}>
                <Grid item xs={12}>
                  <AcademicContactNetworkTable
                    formType={typeTable}
                    data={tableData}
                    isLoading={isLoadingTable}
                    isFetching={isFetchingTable}
                    error={errorTable}
                    setTypeTable={setTypeTable}
                    height={height || cardHeight}
                  />
                </Grid>
              </Stack>
            </div>
          </>
        }
      />
    </React.Fragment>
  );
};

export default AcademicDeanContacts;
