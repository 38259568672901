import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../hooks/useRedux';
import MainCard from '../../../shared/components/cards/MainCard';
import CoursesList from '../components/courses/CoursesList';

const CoursesScreen = () => {
  const { t } = useTranslation();

  const { userInfo } = useAppSelector((state) => state.auth);

  return userInfo?.collaboratorInfo !== undefined ? (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">{t('teachers.courses.title')}</Typography>
        <Typography variant="body1">{t('teachers.courses.subtitle')}</Typography>
      </Grid>
      <CoursesList />
    </Grid>
  ) : (<Grid component={MainCard} sx={{ padding: 2 }}>
  <Grid sx={{ mb: 3 }}>
    <Typography variant="h2">{t('teachers.courses.title')}</Typography>
  </Grid>
  <CoursesList />
</Grid>);
};

export default CoursesScreen;
