import { useHistory } from 'react-router-dom';
import {
  Autocomplete,
  //Checkbox,
  Button,
  Typography,
  Grid,
  TextField,
  /* TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Table,
    CircularProgress,
    FormHelperText,
    Collapse,
    Box,
    useTheme, */
  // FormControlLabel,
  //  makeStyles,
} from '@material-ui/core';
/* import { DatePicker, LocalizationProvider } from '@material-ui/lab';
import esLocale from 'date-fns/locale/es';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns'; */
import InputLabel from '../../../shared/components/forms/InputLabel';
//import { IconCircleX, IconFile } from '@tabler/icons';
//import { useState } from 'react';

/* const useStyles = makeStyles((theme) => ({
    uploadFile: {
        borderColor: theme.palette.secondary.light,
        borderWidth: '2px',
        borderStyle: 'solid',
        borderRadius: 20,
        textAlign: 'center',
        width: '100%',
        height: 'auto',
        position: 'relative',
    },
    fileIcon: {
        mt: 3,
    },
    closeButton: {
        fposition: 'absolute',
        marginTop: 2,
        marginRight: 2,
        right: 0,
    },
})); */

const AddRefund = () => {
  const history = useHistory();
  /* const [file, setFile] = useState('');
    const classes = useStyles(); */
  return (
    <Grid container spacing={3}>
      {/* <Grid item>
                {isError && showAlerts && (
                    <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
                )}

                {isSuccess && showAlerts && (
                    <SuccessAlert
                        message="La sede ha sido creado correctamente."
                        handleDismiss={() => setShowAlerts(false)}
                    />
                )}
            </Grid> */}

      {/* <Grid container spacing={2} alignItems="center"> */}

      <Grid item container spacing={2} md={6} lg={6}>
        <Typography variant="h3" sx={{ m: 2 }}>
          Datos Personales
        </Typography>

        <Grid item xs={12}>
          <InputLabel>Tipo de Usuario</InputLabel>
          <Autocomplete
            options={[]}
            placeholder="Seleccione Tipo..."
            //getOptionLabel={option => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                //error={Boolean(errors.statusId && touched.statusId)}
                placeholder="Seleccione Tipo..."
              />
            )}
            //defaultValue={}
            onChange={
              (e, value) => {}
              //setFieldValue('statusId', value?.id || '')
            }
            fullWidth
            clearIcon={null}
          />
        </Grid>

        <Grid item xs={12}>
          <InputLabel>Código</InputLabel>
          <TextField
            fullWidth
            //error={Boolean(errors.direction && touched.direction)}
            placeholder="Ingrese Código"
            //{...getFieldProps('direction')}
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel>Nombres</InputLabel>
          <TextField
            fullWidth
            //error={Boolean(errors.direction && touched.direction)}
            placeholder="Ingrese Nombres"
            //{...getFieldProps('direction')}
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel>Apellidos</InputLabel>
          <TextField
            fullWidth
            //error={Boolean(errors.direction && touched.direction)}
            placeholder="Ingrese Apellidos"
            //{...getFieldProps('direction')}
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel>Facultad</InputLabel>
          <TextField
            fullWidth
            //error={Boolean(errors.direction && touched.direction)}
            placeholder="Ingrese Facultad"
            //{...getFieldProps('direction')}
          />
        </Grid>
      </Grid>

      {/* </Grid> */}

      <Grid
        item
        container
        alignItems="center"
        justifyContent="flex-start"
        spacing={2}
        sx={{ mt: 1 }}
        xs={12}
        md={12}
      >
        <Grid item>
          <Button variant="outlined" size="large" color="primary" onClick={() => history.goBack()}>
            Regresar
          </Button>
        </Grid>
        <Grid item>
          <Button
            type="submit"
            variant="contained"
            size="large"
            color="primary"
            //disabled={isLoading}
            //endIcon={isLoading && <CircularProgress size={20} />}
          >
            Agregar
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AddRefund;
