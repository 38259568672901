import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISearchTable } from '../../../shared/interfaces/paginatedTable.interface';
import { PosgraduatePlanningCohort } from '../interfaces/posgraduatePlanningCohorts.interface';

interface PosgraduatePlanningCohortsState {
  posgraduatePlanningCohortsList: PosgraduatePlanningCohort[];
  posgraduatePlanningCohortsTable: ISearchTable;
  posgraduatePlanningCohortIdSelected: string | null;
  posgraduatePlanningCohortDeleteDialogOpen: boolean;
  posgraduatePlanningCohortFormDialogOpen: boolean;
}

const initialState: PosgraduatePlanningCohortsState = {
  posgraduatePlanningCohortsList: [],
  posgraduatePlanningCohortsTable: {
    page: 1,
    perPage: 5,
    search: '',
  },
  posgraduatePlanningCohortIdSelected: null,
  posgraduatePlanningCohortDeleteDialogOpen: false,
  posgraduatePlanningCohortFormDialogOpen: false,
};

const posgraduatePlanningCohortsSlice = createSlice({
  name: 'posgraduatePlanningPosgraduateCohort',
  initialState,
  reducers: {
    setPosgraduatePlanningCohortsList: (state, action: PayloadAction<any[]>) => {
      state.posgraduatePlanningCohortsList = action.payload;
    },
    setPosgraduatePlanningCohortsTable: (state, action: PayloadAction<ISearchTable>) => {
      state.posgraduatePlanningCohortsTable = action.payload;
    },
    setPosgraduatePlanningCohortIdSelected: (state, action: PayloadAction<string | null>) => {
      state.posgraduatePlanningCohortIdSelected = action.payload;
    },
    setPosgraduatePlanningCohortDeleteDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.posgraduatePlanningCohortDeleteDialogOpen = action.payload;
    },
    setPosgraduatePlanningCohortFormDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.posgraduatePlanningCohortFormDialogOpen = action.payload;
    },
  },
});

export const {
  setPosgraduatePlanningCohortsList,
  setPosgraduatePlanningCohortsTable,
  setPosgraduatePlanningCohortIdSelected,
  setPosgraduatePlanningCohortDeleteDialogOpen,
  setPosgraduatePlanningCohortFormDialogOpen,
} = posgraduatePlanningCohortsSlice.actions;

export default posgraduatePlanningCohortsSlice.reducer;
