import { Switch, Redirect } from 'react-router-dom';


import { useAppSelector } from '../../../../hooks/useRedux';

//import { permissionKey, subPermissionKeys } from '../constants/permissions';
import GuardRoute from '../../../../router/guards/GuardRoute';
import ManageLeadsScreen from '../screens/ManageLeadsScreen';

const ManageLeadsRouter = () => {
    const { currentModule } = useAppSelector((state) => state.access);

    const hasPermission = 'crm' in currentModule?.permissions!;

    if (!hasPermission) return <Redirect to="/dashboard" />;

    return (
        <Switch>
            <GuardRoute
                exact
                path="/gestionar-leads"
                component={ManageLeadsScreen}
                /* permissionKey={permissionKey}
                subPermissionKey={subPermissionKeys.create} */
            />
            
        </Switch>
    )
}

export default ManageLeadsRouter;