import { useHistory } from 'react-router-dom';
import {
  Autocomplete,
  //Checkbox,
  Button,
  CircularProgress,
  Grid,
  InputLabel,
  TextField,
} from '@material-ui/core';
import {
  useAddShelfMutation,
  useEditShelftMutation,
  // useGetAllShelfsQuery,
} from '../slices/shelfsApiSlice';
import { statusAvailables } from '../../../shared/constants/resourceStatus';
import { useFormik } from 'formik';
import * as yup from 'yup';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import { useAppSelector } from '../../../../hooks/useRedux';
import { useGetAvailablesCampusQuery } from '../../../maintenance/campus/slices/campusApiSlice';
// import //useEffect,
// // useState,
// 'react';

const AddShelft = () => {
  const history = useHistory();
  const { shelfToEdit } = useAppSelector((state) => state.shelfts);
  //const [updateAutocomplete, setUpdateAutocomplete] = useState(false);
  const { currentProfile } = useAppSelector((state) => state.access);

  const { data: campuses, isLoading: isLoadingCampuses } = useGetAvailablesCampusQuery({
    profileId: currentProfile?.profileId!,
  });

  const location_type = [
    { value: 'Bodega', label: 'Bodega' },
    { value: 'Estanteria', label: 'Estanteria' },
    { value: 'Armario', label: 'Armario' },
    { value: 'Gabinete', label: 'Gabinete' },
    { value: 'Cajon', label: 'Cajon' },
  ];

  //const { data: shelfs, isLoading: isLoadingShelfs } = useGetAllShelfsQuery();

  const schema = yup.object().shape({
    name: yup.string().required('Nombre es un campo requerido'),
    type: yup.string().required('Tipo es un campo requerido'),
    acronym: yup.string().required('Acrónimo es un campo requerido'),
    capacity: yup
      .number()
      .moreThan(0, 'El valor debe ser mayor a 0')
      .required('Capacidad es un campo requerido'),
    code: yup.string().required('Código es un campo requerido'),
    parent: yup.string(),
    sede: yup.string().required('Sede es un campo requerido'),
    status: yup.string().required('Estado es un campo requerido'),
  });

  const [addShelft, { isLoading, isError, error, isSuccess, reset }] = useAddShelfMutation();
  const [
    editShelft,
    {
      isLoading: isLoadingEdit,
      isError: isErrorEdit,
      error: errorEdit,
      isSuccess: isSuccessEdit,
      reset: resetEdit,
    },
  ] = useEditShelftMutation();

  const formik = useFormik({
    initialValues: {
      name: shelfToEdit?.description || '',
      type: shelfToEdit?.location_type || '',
      acronym: shelfToEdit?.acronym || '',
      code: shelfToEdit?.code || '',
      parent: String(shelfToEdit?.parent_id) || '',
      status: shelfToEdit?.status_id || '',
      capacity: Number(shelfToEdit?.capacity) || 0,
      sede: '',
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      if (shelfToEdit) {
        editShelft({
          id: shelfToEdit.id,
          payload: {
            description: values.name,
            location_type: values.type,
            acronym: values.acronym,
            capacity: values.capacity,
            code: values.code,
            status_id: Number(values.status),
            parent_id: values.parent !== '' ? Number(values.parent) : undefined,
          },
        });
      } else {
        addShelft({
          payload: {
            description: values.name,
            location_type: values.type,
            acronym: values.acronym,
            capacity: values.capacity,
            code: values.code,
            status_id: Number(values.status),
            parent_id: values.parent !== '' ? Number(values.parent) : undefined,
            campus_id: values.sede !== '' ? Number(values.sede) : undefined,
          },
        });
      }
    },
  });

  // useEffect(() => {
  //   setUpdateAutocomplete(true);
  //   setTimeout(() => {
  //     setUpdateAutocomplete(false);
  //   }, 50);
  // }, [formik.values.parent, formik.values.sede]);

  return (
    <Grid container direction="column" gap={2}>
      <Grid item container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <InputLabel>Nombre</InputLabel>
          <TextField
            fullWidth
            placeholder="Ingrese nombre"
            value={formik.values.name}
            error={Boolean(formik.errors.name) && formik.touched.name}
            helperText={
              Boolean(formik.errors.name) && formik.touched.name ? formik.errors.name : ''
            }
            onChange={({ target }) => formik.setFieldValue('name', target.value)}
          />
        </Grid>

        <Grid item xs={12}>
          <InputLabel>Tipo</InputLabel>
          <Autocomplete
            options={location_type}
            value={location_type.find((ty) => ty.value === formik.values.type)}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Seleccione tipo"
                error={Boolean(formik.errors.type && formik.touched.type)}
                helperText={
                  Boolean(formik.errors.type && formik.touched.type) ? formik.errors.type : null
                }
              />
            )}
            //defaultValue={}
            onChange={(_, value) => {
              formik.setFieldValue('type', value?.value);
            }}
            fullWidth
            clearIcon={null}
          />
        </Grid>

        <Grid item xs={12}>
          <InputLabel>Acrónimo</InputLabel>
          <TextField
            fullWidth
            placeholder="Ingrese acrónimo"
            error={Boolean(formik.errors.acronym) && formik.touched.acronym}
            value={formik.values.acronym}
            helperText={
              Boolean(formik.errors.acronym) && formik.touched.acronym ? formik.errors.acronym : ''
            }
            onChange={({ target }) => formik.setFieldValue('acronym', target.value)}
          />
        </Grid>

        <Grid item xs={12}>
          <InputLabel>Código</InputLabel>
          <TextField
            fullWidth
            placeholder="Ingrese código"
            value={formik.values.code}
            error={Boolean(formik.errors.code) && formik.touched.code}
            helperText={
              Boolean(formik.errors.code) && formik.touched.code ? formik.errors.code : ''
            }
            onChange={({ target }) => formik.setFieldValue('code', target.value)}
          />
        </Grid>

        <Grid item xs={12}>
          <InputLabel>Capacidad</InputLabel>
          <TextField
            type="number"
            fullWidth
            placeholder="Ingrese capacidad"
            value={formik.values.capacity}
            error={Boolean(formik.errors.capacity) && formik.touched.capacity}
            helperText={
              Boolean(formik.errors.capacity) && formik.touched.capacity
                ? formik.errors.capacity
                : ''
            }
            onChange={({ target }) => formik.setFieldValue('capacity', target.value)}
          />
        </Grid>

        {/* <Grid item xs={12}>
          <InputLabel>Padre</InputLabel>
          {isLoadingShelfs || updateAutocomplete ? (
            <TextField placeholder="Cargando" fullWidth />
          ) : (
            <Autocomplete
              options={shelfs?.map((sh) => ({ value: sh.id, label: sh.description })) || []}
              value={shelfs
                ?.map((sh) => ({ value: sh.id, label: sh.description }))
                .find((sh) => sh.value === Number(formik.values.parent))}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Seleccione Padre..."
                  error={Boolean(formik.errors.parent && formik.touched.parent)}
                  helperText={
                    Boolean(formik.errors.parent && formik.touched.parent)
                      ? formik.errors.parent
                      : null
                  }
                />
              )}
              //defaultValue={}
              onChange={async (e, value) => {
                if (value) {
                  await formik.setFieldValue('parent', value.value);
                  await formik.setFieldValue('sede', '');
                } else {
                  formik.setFieldValue('parent', '');
                }
              }}
              fullWidth
            />
          )}
        </Grid> */}

        <Grid item xs={12}>
          <InputLabel>Sede</InputLabel>
          {isLoadingCampuses ? (
            <TextField placeholder="Cargando" fullWidth />
          ) : (
            <Autocomplete
              options={campuses?.map((sh) => ({ value: sh.id, label: sh.cam_name })) || []}
              value={campuses
                ?.map((sh) => ({ value: sh.id, label: sh.cam_name }))
                .find((sh) => sh.value === Number(formik.values.sede))}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Seleccione Sede..."
                  error={Boolean(formik.errors.sede && formik.touched.sede)}
                  helperText={
                    Boolean(formik.errors.sede && formik.touched.sede) ? formik.errors.sede : null
                  }
                />
              )}
              //defaultValue={}
              onChange={async (e, value) => {
                if (value) {
                  await formik.setFieldValue('sede', value.value);
                  await formik.setFieldValue('parent', '');
                } else {
                  formik.setFieldValue('sede', '');
                }
              }}
              fullWidth
            />
          )}
        </Grid>

        <Grid item xs={12}>
          <InputLabel>Estado</InputLabel>
          <Autocomplete
            options={statusAvailables.map((st) => ({ value: st.id, label: st.name }))}
            value={statusAvailables
              .map((st) => ({ value: st.id, label: st.name }))
              .find((st) => st.value === Number(formik.values.status))}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Seleccione Estado..."
                error={Boolean(formik.errors.status && formik.touched.status)}
                helperText={
                  Boolean(formik.errors.status && formik.touched.status)
                    ? formik.errors.status
                    : null
                }
              />
            )}
            //defaultValue={}
            onChange={(e, value) => {
              formik.setFieldValue('status', value?.value);
            }}
            fullWidth
            clearIcon={null}
          />
        </Grid>
      </Grid>

      <Grid item xs={12} md={12}>
        {isError && <ErrorAlert message={error as string} handleDismiss={reset} />}
        {isErrorEdit && <ErrorAlert message={errorEdit as string} handleDismiss={resetEdit} />}

        {isSuccess && (
          <SuccessAlert message="Estante creado correctamente." handleDismiss={reset} />
        )}
        {isSuccessEdit && (
          <SuccessAlert message="Estante editado correctamente." handleDismiss={resetEdit} />
        )}
      </Grid>

      <Grid
        item
        container
        alignItems="center"
        justifyContent="center"
        spacing={2}
        sx={{ mt: 1 }}
        xs={12}
        md={6}
      >
        <Grid item>
          <Button variant="outlined" size="large" color="primary" onClick={() => history.goBack()}>
            Regresar
          </Button>
        </Grid>
        <Grid item>
          <Button
            type="submit"
            variant="contained"
            size="large"
            color="primary"
            onClick={() => formik.handleSubmit()}
            disabled={isLoading || isLoadingEdit}
            endIcon={isLoading || (isLoadingEdit && <CircularProgress size={20} />)}
          >
            {shelfToEdit ? 'Editar' : 'Agregar'}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AddShelft;
