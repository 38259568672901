import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CoursesCollaborator } from '../../../maintenance/courses/interfaces/coursesCollaborator.interface';
import { TaskList } from '../tasks/interfaces/task-list.interfaces';
import { Question, TaskToCreatePayload } from '../tasks/interfaces/task-to-create.interfaces';
import { StudentCourse } from '../../../maintenance/courses/interfaces/course-students.interfaces';
import { TaskQuestion } from '../../virtual-classroom/tasks/interfaces/task-to-create.interfaces';
//import { DetailActivity } from '../../virtual-classroom/tasks/interfaces/task-to-create.interfaces';
import { Forum } from '../interfaces/forums.interfaces';
import { DetailEdit, TaskEditPayload } from '../tasks/interfaces/task-to-edit.interfaces';
import { TaskDetail } from '../interfaces/taskDetail.interfaces';
import { SettingsInsumos } from '../califications/interfaces/ConfigurationInsumo';
import { removeItemFromIndexArr } from '../../../shared/helpers';
interface VirtualClassroomTeacherState {
  coursesTeacherList?: CoursesCollaborator[];
  forumIdToDelete: number | null;
  forumList?: Forum[];
  materialIdToDelete?: number;
  folderIdToDelete?: number;
  deleteModalOpen: boolean;
  commentIdToDelete?: number;
  imgModalOpen: boolean;
  imgNameToShow?: string;
  isValidateCalifications?: boolean;
  TaskCompleteInfoEdit: boolean;
  TaskCompleteGroupEdit: boolean;
  TaskCompleteQuestionEdit: boolean;
  StepEdit: number;

  TaskInfoDetail?: TaskDetail;

  /*State task*/
  tasks: TaskList[];
  taskChooseGroup: boolean;
  taskCompleteInfo: boolean;
  taskChooseQuestions: boolean;
  taskChooseFiles: boolean;
  taskToCreate?: TaskToCreatePayload;
  studentsToAsignedGroup: StudentCourse[];
  taskToEdit?: TaskEditPayload;
  TaskEdit: DetailEdit[];
  deleteTasks: number[];

  configurateInsumo?: SettingsInsumos;

  calificationsError: string;
  calificationsAlertOpen: boolean;

  // Forum
  forumDeleteDialogOpen: boolean;

  importCalificationsMoodleDialogOpen: boolean;
}

interface CourseForum {
  courseId: number;
  forumId: number;
}

const initialState: VirtualClassroomTeacherState = {
  deleteModalOpen: false,
  imgModalOpen: false,
  TaskCompleteInfoEdit: true,
  TaskCompleteGroupEdit: false,
  TaskCompleteQuestionEdit: false,
  StepEdit: 0,

  isValidateCalifications: false,

  /* Tasks initial state */
  tasks: [],
  taskCompleteInfo: true,
  taskChooseGroup: false,
  taskChooseQuestions: false,
  studentsToAsignedGroup: [],
  taskChooseFiles: false,
  deleteTasks: [],

  /*Task Edits */

  TaskEdit: [],

  calificationsError: '',
  calificationsAlertOpen: false,

  /*Forum */
  forumIdToDelete: null,
  forumDeleteDialogOpen: false,

  importCalificationsMoodleDialogOpen: false,
};

const virtualClassroomTeacherSlice = createSlice({
  name: 'virtualClassroomTeacher',
  initialState,
  reducers: {
    resetVirtualClassroomTeacherState: () => initialState,
    setDeleteModalOpen: (state, action: PayloadAction<boolean>) => {
      state.deleteModalOpen = action.payload;
    },

    openDeleteModalForum: (state, action: PayloadAction<CourseForum>) => {
      state.forumIdToDelete = action.payload.forumId;
      state.deleteModalOpen = true;
    },
    resetForumIdToDelete: (state) => {
      state.forumIdToDelete = null;
    },
    openDeleteModalMaterials: (state, action: PayloadAction<number>) => {
      state.materialIdToDelete = action.payload;
      state.deleteModalOpen = true;
    },
    openDeleteModalFolders: (state, action: PayloadAction<number>) => {
      state.folderIdToDelete = action.payload;
      state.deleteModalOpen = true;
    },
    resetFolderIdToDelete: (state) => {
      state.folderIdToDelete = undefined;
      state.deleteModalOpen = false;
    },
    resetMaterialIdToDelete: (state) => {
      state.materialIdToDelete = undefined;
      state.deleteModalOpen = false;
    },
    openDeleteModalComment: (state, action: PayloadAction<number>) => {
      state.commentIdToDelete = action.payload;
      state.deleteModalOpen = true;
    },
    resetCommentIdToDelete: (state) => {
      state.commentIdToDelete = undefined;
    },
    setShowImageModalOpen: (state, action: PayloadAction<boolean>) => {
      state.imgModalOpen = action.payload;
    },
    openShowModalImg: (state, action: PayloadAction<string>) => {
      state.imgNameToShow = action.payload;
      state.imgModalOpen = true;
    },
    resetImgNameToShow: (state) => {
      state.imgNameToShow = undefined;
    },
    setIsValidateCalification: (state, action: PayloadAction<boolean | undefined>) => {
      state.isValidateCalifications = action.payload;
    },
    setForumList: (state, action: PayloadAction<Forum[]>) => {
      state.forumList = action.payload;
    },
    /* Tasks actions */
    setTasks: (state, action: PayloadAction<TaskList[]>) => {
      state.tasks = action.payload;
    },
    setTaskChooseGroup: (state, action: PayloadAction<boolean>) => {
      state.taskChooseGroup = action.payload;
      state.taskChooseQuestions = false;
      state.taskCompleteInfo = false;
    },
    setNextStep: (state, action: PayloadAction<number>) => {
      state.StepEdit += action.payload;
    },
    setResetStep: (state) => {
      state.StepEdit = 0;
    },

    setBackStep: (state, action: PayloadAction<number>) => {
      state.StepEdit -= action.payload;
    },
    ResetTask: (state) => {
      state.taskToCreate = undefined;
      state.StepEdit = 0;
    },
    ResetTaskEdit: (state) => {
      state.taskToEdit = undefined;
    },

    setTaskChooseQuestionEdit: (state, action: PayloadAction<boolean>) => {
      state.TaskCompleteInfoEdit = false;
      state.taskChooseQuestions = action.payload;
      state.taskCompleteInfo = false;
      state.StepEdit = 3;
    },

    setStudentToAsignedGroup: (state, action: PayloadAction<StudentCourse[]>) => {
      state.studentsToAsignedGroup = action.payload;
    },

    setDataDetail: (state, action: PayloadAction<TaskEditPayload>) => {
      state.taskToEdit = action.payload!;
    },

    setTaskDetail: (state, action: PayloadAction<TaskDetail>) => {
      state.TaskInfoDetail = action.payload;
    },

    setGroup: (state) => {
      const isNew = state.taskToCreate?.course_student;
      if (isNew === undefined || isNew?.length! === 0) {
        const group = [{ grupo: 0 }];
        state.taskToCreate = {
          ...state.taskToCreate!,
          course_student: state.taskToCreate?.course_student.concat(group)!,
        };
      } else {
        const groupLength = state.taskToCreate?.course_student.length!;
        const group = [{ grupo: groupLength + 1 }];
        state.taskToCreate = {
          ...state.taskToCreate!,
          course_student: state.taskToCreate?.course_student.concat(group)!,
        };
      }
    },
    setGroupEdit: (state) => {
      const isNew = state.taskToEdit?.course_student_add;
      if (isNew === undefined || isNew?.length! === 0) {
        const group = [{ grupo: 0 }];
        state.taskToEdit = {
          ...state.taskToEdit!,
          course_student_add: state?.taskToEdit?.course_student_add.concat(group)!,
        };
      } else {
        const groupLength = state.taskToEdit?.course_student_add.length!;
        const group = [{ grupo: groupLength + 1 }];
        state.taskToEdit = {
          ...state.taskToEdit!,
          course_student_add: state.taskToEdit?.course_student_add.concat(group)!,
        };
      }
    },
    removeGroup: (state, action: PayloadAction<number>) => {
      state.taskToCreate = {
        ...state.taskToCreate!,
        course_student: state.taskToCreate?.course_student.filter(
          (e, index) => e.grupo !== action.payload
        )!,
      };
    },
    setTaskQuestions: (state, action: PayloadAction<Question>) => {
      const detail = state?.taskToCreate?.detail!;
      if (detail !== undefined) {
        state.taskToCreate = {
          ...state.taskToCreate!,
          detail: state.taskToCreate?.detail.concat(action.payload)!,
        };
      } else {
        state.taskToCreate = { ...state.taskToCreate!, detail: [action.payload] };
      }
    },
    unsetIndexQuestion: (state, action: PayloadAction<number>) => {
      let newQuestions;

      if (state.taskToCreate?.detail)
        newQuestions = removeItemFromIndexArr(state.taskToCreate.detail, action.payload);

      if (newQuestions)
        state.taskToCreate = {
          ...state.taskToCreate!,
          detail: [...newQuestions],
        };
    },
    setGroupStudents: (
      state,
      action: PayloadAction<{ group: number; student: number; add: boolean }>
    ) => {
      const { group, student, add } = action.payload;
      if (add) {
        state.taskToCreate = {
          ...state.taskToCreate!,
          course_student: state.taskToCreate?.course_student
            .filter((a) => a.student_id !== undefined)
            .concat({
              grupo: group,
              student_id: student,
            })!,
        };
      } else {
        state.taskToCreate = {
          ...state.taskToCreate!,
          course_student: state.taskToCreate?.course_student.filter(
            (e) => e.student_id !== student
          )!,
        };
      }
    },
    setTaskChooseQuestion: (state, action: PayloadAction<boolean>) => {
      state.taskChooseQuestions = action.payload;

      state.taskChooseGroup = false;
      state.taskCompleteInfo = false;
      state.taskChooseFiles = false;
    },
    setTaskQuestionCurrent: (state, action: PayloadAction<TaskQuestion>) => {
      state.taskToCreate = { ...state.taskToCreate!, questionCurrent: action.payload };
    },
    setTaskCompleteInfo: (state, action: PayloadAction<boolean>) => {
      state.taskCompleteInfo = action.payload;
      state.taskChooseGroup = false;
      state.taskChooseQuestions = false;
      state.taskChooseFiles = false;
    },
    setTaskChooseFiles: (state, action: PayloadAction<boolean>) => {
      state.taskChooseFiles = action.payload;
    },
    setTaskToCreate: (state, action: PayloadAction<TaskToCreatePayload>) => {
      state.taskToCreate = action.payload;
    },

    setTaskToEdit: (state, action: PayloadAction<TaskEditPayload>) => {
      state.taskToEdit = action.payload;
    },

    setTaskEditCreate: (state, action: PayloadAction<TaskEditPayload>) => {
      state.taskToEdit = action.payload;
    },

    setTaskTypeQuestion: (state, action: PayloadAction<string>) => {
      state.taskToCreate = { ...state.taskToCreate!, typeQuestion: action.payload };
    },
    setTaskFile: (state, action: PayloadAction<{ name: string; name_url: string }>) => {
      const { name, name_url } = action.payload;
      const files = state?.taskToCreate?.files!;
      const file = { tool_ms_name_file: name, tool_ms_url_file: name_url };
      if (files !== undefined) {
        state.taskToCreate = {
          ...state.taskToCreate!,
          files: state.taskToCreate?.files?.concat(file),
        };
      } else {
        state.taskToCreate = {
          ...state.taskToCreate!,
          files: [file],
        };
      }
    },

    setEditTask: (state, action: PayloadAction<DetailEdit>) => {
      let busqueda = state.TaskEdit.findIndex((busqueda) => busqueda.id === action.payload.id);
      if (busqueda !== -1) {
        state.TaskEdit[busqueda] = action.payload;
      } else {
        state.TaskEdit?.push(action.payload);
      }
    },
    setDeleteTasksEdit: (state, action: PayloadAction<number>) => {
      state.deleteTasks.push(action.payload);
    },
    setRemoveTask: (state, action: PayloadAction<number>) => {
      state.taskToEdit = {
        ...state.taskToEdit!,
        detail_remove: state.taskToEdit?.detail_remove?.concat(action.payload)!,
      };
    },
    setModalSupply: (state, action: PayloadAction<SettingsInsumos>) => {
      state.configurateInsumo = action.payload;
    },
    closeModalSupply: (state) => {
      state.configurateInsumo = {
        ...state.configurateInsumo!,
        modal: false,
      };
    },
    setCalificationsError: (state, action: PayloadAction<string>) => {
      state.calificationsError = action.payload;
    },
    setCalificationsAlertOpen: (state, action: PayloadAction<boolean>) => {
      state.calificationsAlertOpen = action.payload;
    },

    setForumIdToDelete: (state, action: PayloadAction<number | null>) => {
      state.forumIdToDelete = action.payload;
    },
    setForumDeleteDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.forumDeleteDialogOpen = action.payload;
    },

    setImportCalificationsMoodleDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.importCalificationsMoodleDialogOpen = action.payload;
    },
  },
});

export const {
  //edit tas
  setEditTask,
  setDeleteTasksEdit,
  setRemoveTask,

  setTaskDetail,
  //reset task,
  ResetTask,
  //config Modal Supply
  setModalSupply,
  closeModalSupply,
  //
  resetVirtualClassroomTeacherState,
  openDeleteModalForum,
  setDeleteModalOpen,
  resetForumIdToDelete,
  openDeleteModalMaterials,
  resetMaterialIdToDelete,
  openDeleteModalComment,
  resetCommentIdToDelete,
  setIsValidateCalification,
  setResetStep,
  // img show modal
  setShowImageModalOpen,
  openShowModalImg,
  resetImgNameToShow,
  removeGroup,
  setGroup,
  setGroupStudents,
  setStudentToAsignedGroup,
  setTaskChooseGroup,
  setTaskTypeQuestion,
  setTaskChooseQuestion,
  setTaskQuestions,
  unsetIndexQuestion,
  setTaskFile,
  setTaskCompleteInfo,
  setTaskQuestionCurrent,
  setTaskChooseFiles,
  setTaskToCreate,
  setTasks,
  setForumList,
  setNextStep,
  setTaskEditCreate,
  setTaskChooseQuestionEdit,
  setGroupEdit,
  setBackStep,
  setDataDetail,
  ResetTaskEdit,
  openDeleteModalFolders,
  resetFolderIdToDelete,
  setCalificationsError,
  setCalificationsAlertOpen,
  setForumIdToDelete,
  setForumDeleteDialogOpen,
  setImportCalificationsMoodleDialogOpen,
} = virtualClassroomTeacherSlice.actions;

export default virtualClassroomTeacherSlice.reducer;
