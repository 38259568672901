import { useFormik } from 'formik';
//import { useState } from 'react';
import { useAppSelector } from '../../../../hooks/useRedux';
import {
  useGetHourhandsByPeriodQuery,
  usePeriodByStudentRecordIdQuery,
} from '../../../maintenance/periods/slices/periodsApiSlice';
import { useGetCatalogByAcronymQuery } from '../../../shared/slices/catalogApi';
import { enableSubjectsValues } from '../constants/formConstants';
import { EnabledSubjectsSchema } from '../constants/validationSchemas';
import { useFilterEnabledSubjectsMutation } from '../slices/enabledSubjectsApiSlice';
import { useTranslation } from 'react-i18next';
import { useGetAvailablesCampusQuery } from '../../../maintenance/campus/slices/campusApiSlice';

const useEnabledSubjectsByFilterOptions = () => {
  const { t } = useTranslation();

  const { currentProfile, currentRecordStudent } = useAppSelector((state) => state.access);

  const { userInfo } = useAppSelector((state) => state.auth);

  /* search current and future periods */
  const { data: periods = [], isLoading: isLoadingPeriods } = usePeriodByStudentRecordIdQuery(
    {
      userProfileId: currentProfile?.profileId!,
      studentRecordId: currentRecordStudent?.id!,
    },
    { refetchOnMountOrArgChange: 30 }
  );

  /* search current and future periods */
  const { data: campus, isLoading: isLoadingCampus } = useGetAvailablesCampusQuery({});

  /* search current journally */
  const { data: jornadas = [], isLoading: isLoadingJornadas } = useGetCatalogByAcronymQuery(
    {
      profileId: currentProfile?.profileId!,
      acronym: 'M',
    },
    { refetchOnMountOrArgChange: 30 }
  );

  const [
    filterEnabledSubjects,
    { isError, isLoading: isLoadingFilter, data: enabledSubjectsResponse },
  ] = useFilterEnabledSubjectsMutation();

  const { handleSubmit, setFieldValue, errors, touched, values, resetForm } = useFormik({
    initialValues: enableSubjectsValues,
    validationSchema: EnabledSubjectsSchema,
    validate: () => {
      const periodoIsRequired = t('students.subjects.available.period.required');
      const errors: any = {};
      if (!values.period_id) {
        errors.period_id = periodoIsRequired;
      }
      return errors;
    },
    onSubmit: async (value) => {
      await filterEnabledSubjects({
        profileId: currentProfile?.profileId!,
        studentRecordId: currentRecordStudent?.id!,
        enabledSubjectsPayload: {
          period_id: value.period_id,
          campus_id: value.campus_id,
          workday: value.workday,
          hourhands: value.hourhands,
        },
      });
    },
  });

  const handleResetForm = () => {
    resetForm();
  };

  /* search current hourhands by periodId */
  const {
    data: hourhands = [],
    isLoading: isLoadingHourhands,
    isFetching: isFetchingHourhands,
  } = useGetHourhandsByPeriodQuery(
    {
      profileId: currentProfile?.profileId!,
      periodId: values.period_id,
      course_filter: true,
      student_curriculum: userInfo?.studentInfo?.curriculumId,
    },
    { skip: !values.period_id, refetchOnMountOrArgChange: 30 }
  );

  return {
    periods,
    isLoadingPeriods,
    campus,
    isLoadingCampus,
    jornadas,
    isLoadingJornadas,
    hourhands,
    isLoadingHourhands,
    isFetchingHourhands,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
    values,
    handleResetForm,
    isLoadingFilter,
    enabledSubjectsResponse,
    isError,
  };
};
export default useEnabledSubjectsByFilterOptions;
