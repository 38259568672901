import { Alert, Grid, Skeleton, Typography } from '@material-ui/core';
import { IconCircleCheck, IconCircleX } from '@tabler/icons';
import { Cursando } from '../interfaces/approves.failed.inferface';

interface props {
  approbed?: Cursando[];
  failed?: Cursando[];
  isLoading?: boolean;
}

const ListApprobesFaileds = ({ approbed, failed, isLoading }: props) => {
  if (isLoading) {
    return (
      <Grid item container spacing={2}>
        <Grid item container xs={6} md={6}>
          <Skeleton variant="text" width={200} sx={{ mb: 1 }} />
          <Skeleton variant="rectangular" width={400} height={140} />
        </Grid>
        <Grid item container xs={6} md={6}>
          <Skeleton variant="text" width={200} sx={{ mb: 1 }} />
          <Skeleton variant="rectangular" width={400} height={140} />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid item container spacing={2}>
      <Grid item container xs={6} md={6}>
        <Grid item container>
          <Typography variant="h5">Materias Aprobadas &nbsp;</Typography>
          <IconCircleCheck color="#00FFAB" />
        </Grid>
        <Grid item>
          {approbed && approbed.length > 0 ? (
            approbed.map((ap) => <li>{ap.mat_name}</li>)
          ) : (
            <Alert severity="info">No se encontraron materias aprobadas</Alert>
          )}
        </Grid>
      </Grid>
      <Grid item container xs={6} md={6}>
        <Grid item container>
          <Typography variant="h5">Materias Reprobadas &nbsp;</Typography>
          <IconCircleX color="#FF6464" />
        </Grid>
        <Grid item>
          {failed && failed.length > 0 ? (
            failed.map((ap) => <li>{ap.mat_name}</li>)
          ) : (
            <Alert severity="info">No se encontraron materias reprobadas</Alert>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ListApprobesFaileds;
