import { useState } from 'react';

import { Grid, Switch } from '@material-ui/core';
import { useSetEarlyPaymentMutation } from '../../students/slices/studentsApiSlice';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';

interface Props {
  id: number;
  defaultValue: boolean;
}

const SwitchEarlyPayment = ({ defaultValue = false, id }: Props) => {
  const [active, setActive] = useState(defaultValue);
  const [showAlerts, setShowAlerts] = useState(false);

  const [
    setApplyEarlyPayment,
    { isLoading: isApplying, isSuccess: isSuccessApply, isError: isErrorApply, error: errorApply },
  ] = useSetEarlyPaymentMutation();

  const handleChangeEarlyPayment = async (studentId: number, applyEarlyPayment: boolean) => {
    setApplyEarlyPayment({ studentId, applyEarlyPayment });
  };

  return (
    <>
      {isSuccessApply && showAlerts && (
        <Grid item xs={12}>
          <SuccessAlert
            message="Se ha aplicado prontopago correctamente"
            handleDismiss={() => setShowAlerts(false)}
          />
        </Grid>
      )}
      {isErrorApply && showAlerts && (
        <Grid item xs={12}>
          <ErrorAlert message={errorApply as string} handleDismiss={() => setShowAlerts(false)} />
        </Grid>
      )}
      <Switch
        checked={active}
        onChange={(e) => {
          setActive(e.target.checked);
          handleChangeEarlyPayment(id, e.target.checked);
        }}
        disabled={isApplying}
      />
    </>
  );
};

export default SwitchEarlyPayment;
