import { Grid, IconButton, Typography } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
// import MainCard from '../../../shared/components/cards/MainCard';
import MainCardForm from '../../../shared/components/cards/MainCardForm';
import FaqsList from '../components/LibraryFaqs';

const LibraryFaqsScreen = () => {
  const history = useHistory();

  return (
    <MainCardForm>
      <Grid container alignItems="center" sx={{ mb: 3 }}>
        <IconButton
          onClick={() => {
            history.goBack();
          }}
        >
          <ArrowBack />
        </IconButton>
        <Typography variant="h2">Preguntas frecuentes</Typography>
      </Grid>

      <Grid item xs={12}>
        <FaqsList />
      </Grid>
    </MainCardForm>
  );
};

export default LibraryFaqsScreen;
