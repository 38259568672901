import { useState } from 'react';
import {
  Grid,
  Typography,
  IconButton,
  Tooltip,
  // Box,
  // ImageList,
  // useTheme,
  // useMediaQuery,
  // ImageListItem,
} from '@material-ui/core';
import ProfileTeacherCard from '../components/ProfileTeacherCard';
import HourhandCard from '../components/HourhandCard';
import { IconChevronDown, IconChevronUp } from '@tabler/icons';

import MainCard from '../../../shared/components/cards/MainCard';
import Transitions from '../../../shared/components/extended/Transitions';
import CoursesList from '../components/CoursesList';
import { useTranslation } from 'react-i18next';
import ActivitiesCalendarTeacher from '../components/ActivitiesCalendarTeacher';
import InstitutionBanner from '../../../students/dashboard/components/InstitutionBanner';
import WeekActivities from '../components/WeekActivities';
import TimeLineActivities from '../components/TimeLineActivities';

export interface hourhandData {
  start: string;
  end: string;
  name: string;
}

const DashboardScreen = () => {
  const { t } = useTranslation();
  // const theme = useTheme();
  // const isLoadingTest = false;

  // const matchDownLG = useMediaQuery(theme.breakpoints.down('lg'));
  // const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));

  const isLoadingTest = false;
  const namesHorario = ['Lunes ', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];

  const [showSubjects, setShowSubjects] = useState(true);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ProfileTeacherCard isLoading={isLoadingTest} />
      </Grid>
      <Grid item xs={12}>
        <MainCard>
          {/* <Box sx={{ width: '100%', boxSizing: 'border-box' }}>
            <ImageList variant="masonry" cols={matchDownLG ? (matchDownSM ? 1 : 2) : 3} gap={16}>
              <ImageListItem sx={{ width: '100%' }}>
                <InstitutionBanner />
              </ImageListItem>
              <ImageListItem sx={{ width: '100%' }}>
                <ActivitiesCalendarTeacher />
              </ImageListItem>
            </ImageList>
          </Box> */}

          <Grid container spacing={2} mb={2}>
            <Grid item xs={12} md={5}>
              <TimeLineActivities />
            </Grid>

            <Grid item xs={12} md={7}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={12} lg={6}>
                  <InstitutionBanner />
                </Grid>
                <Grid item xs={12} sm={6} md={12} lg={6}>
                  <ActivitiesCalendarTeacher />
                </Grid>
                <Grid item xs={12} sx={{ display: { xs: 'block', md: 'none', lg: 'block' } }}>
                  <WeekActivities />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sx={{ display: { xs: 'none', md: 'block', lg: 'none' } }}>
              <WeekActivities />
            </Grid>
          </Grid>

          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item sx={{ mb: 3 }}>
              <Typography variant="h3" color={'inherit'}>
                {t('teachers.dashboard.title')}
              </Typography>
              <Typography variant="body1">{t('teachers.dashboard.subtitle')}</Typography>
            </Grid>
            <Grid item sx={{ mb: 3 }}>
              <Tooltip
                title={showSubjects ? t('teachers.dashboard.close') : t('teachers.dashboard.open')}
                onClick={() => setShowSubjects(!showSubjects)}
              >
                <IconButton>{showSubjects ? <IconChevronUp /> : <IconChevronDown />}</IconButton>
              </Tooltip>
            </Grid>
          </Grid>

          <Transitions type="collapse" in={showSubjects} position="top-left" direction={'left'}>
            <Grid container spacing={2}>
              <CoursesList />
            </Grid>
          </Transitions>
        </MainCard>
      </Grid>
      {false && (
        <Grid item xs={12}>
          <MainCard>
            <Grid sx={{ mb: 3 }}>
              <Typography variant="h3" color={'inherit'}>
                Horario
              </Typography>
              <Typography variant="body1">
                Listado de tu <strong>horario</strong>.
              </Typography>
            </Grid>
            <Grid container item xs={12} spacing={2}>
              {namesHorario.map((e) => (
                <Grid item xs={12} md={6} lg={4} xl={3} key={e}>
                  <HourhandCard name={e} isLoading={isLoadingTest} />
                </Grid>
              ))}
            </Grid>
          </MainCard>
        </Grid>
      )}
    </Grid>
  );
};

export default DashboardScreen;
