import React, { FC, useState, useMemo } from 'react';

import {
  Grid,
  TextField,
  Button,
  CircularProgress,
  Autocomplete,
  FormHelperText,
} from '@material-ui/core';
import { useFormik } from 'formik';

import { useAppSelector } from '../../../../../hooks/useRedux';
import { useGetAvailablesProfilesQuery } from '../../../profiles/slices/profilesApiSlice';
import {
  useAddUserProfileMutation,
  useUpdateUserProfileMutation,
} from '../../slices/userConfigurationsApiSlice';
import ErrorAlert from '../../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../../shared/components/alerts/SuccessAlert';
import { UserProfileSchema } from '../../constants/validationSchemas';
import InputLabel from '../../../../shared/components/forms/InputLabel';
import { statusAvailables } from '../../../../shared/constants/resourceStatus';
import TextFieldFetching from '../../../../shared/components/TextFieldFetching';
// import { setCurrentClient, setCurrentClientMassiveDebts } from '../../slices/generateDebtsSlice';
import { useTranslation } from 'react-i18next';

interface Props {
  userId: number;
  profileId: number;
  handleCloseDialog: (e: React.SyntheticEvent) => void;
  setProfileId: React.Dispatch<React.SetStateAction<number>>;
}

const AddUserProfileForm: FC<Props> = (props) => {
  const { t } = useTranslation();
  const { handleCloseDialog, setProfileId, userId, profileId } = props;

  const [showAlerts, setShowAlerts] = useState(true);

  const { userProfileList } = useAppSelector((state) => state.usersConfiguration);

  //client data
  const { data: profilesList, isFetching: isFetchingPL } = useGetAvailablesProfilesQuery({});

  const profileToEdit = useMemo(() => {
    return userProfileList?.find((dt) => dt.profile.id === profileId);
  }, [profileId, userProfileList]);

  const [addUserProfile, { isLoading, isError, isSuccess, error }] = useAddUserProfileMutation();
  const [
    updateUserProfile,
    {
      isLoading: isLoadingUpdate,
      isError: isErrorUpdate,
      isSuccess: isSuccessUpdate,
      error: errorUpdate,
    },
  ] = useUpdateUserProfileMutation();

  const { errors, touched, handleSubmit, setFieldValue, values, getFieldProps } = useFormik({
    initialValues: {
      profileId: profileId || null,
      email: profileToEdit?.email || '',
      statusId: profileToEdit?.status_id ? Number(profileToEdit?.status_id) : 1,
    },
    validationSchema: UserProfileSchema,
    onSubmit: async (values) => {
      if (!profileId) {
        //agregar
        await addUserProfile({
          userId,
          userProfilePayload: {
            profile_id: values.profileId!,
            email: values.email,
            status_id: values.statusId,
          },
        });
      } else {
        // editar
        await updateUserProfile({
          userId,
          profileId,
          userProfilePayload: {
            profile_id: values.profileId!,
            email: values.email,
            status_id: values.statusId,
          },
        })
          .unwrap()
          .then((response) => {
            setProfileId(values.profileId!);
          });
      }

      setShowAlerts(true);
    },
  });

  //
  // useEffect(() => {
  //   if (profileToEdit) {
  //     setFieldValue('email', profileToEdit.email);
  //   }
  // }, [profileToEdit]);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid container direction="column" gap={2}>
          <Grid item xs={12}>
            {(isError || isErrorUpdate) && showAlerts && (
              <ErrorAlert
                message={(error || errorUpdate) as string}
                handleDismiss={() => setShowAlerts(false)}
              />
            )}

            {(isSuccess || isSuccessUpdate) && showAlerts && (
              <SuccessAlert
                message={
                  !profileId
                    ? t('configuration.users.profiles.add.success')
                    : t('configuration.users.profiles.edit.success')
                }
                handleDismiss={() => setShowAlerts(false)}
              />
            )}
          </Grid>

          <Grid item container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <InputLabel>{t('configuration.users.profiles.form.profile')}</InputLabel>
              {isFetchingPL ? (
                <TextFieldFetching />
              ) : (
                <Autocomplete
                  options={profilesList || []}
                  loadingText={t('configuration.users.profiles.form.loading')}
                  noOptionsText={t('configuration.users.profiles.form.no_data')}
                  getOptionLabel={(option) => option.pro_name}
                  getOptionDisabled={(option) =>
                    userProfileList.some(
                      (dt) => dt.profile.keyword === option.keyword && dt.profile.id !== profileId
                    )
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(errors.profileId && touched.profileId)}
                      placeholder={t('configuration.users.profiles.form.profile.placeholder')}
                    />
                  )}
                  value={profilesList?.find((pr) => pr.id === values.profileId) || null}
                  onChange={(e, value) => setFieldValue('profileId', value?.id || '')}
                  fullWidth
                  clearIcon={null}
                />
              )}
              {errors.profileId && touched.profileId && (
                <FormHelperText error>{t(errors.profileId)}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12}>
              <InputLabel>{t('configuration.users.profiles.form.email')}</InputLabel>
              <TextField
                fullWidth
                placeholder={t('configuration.users.profiles.form.email.placeholder')}
                error={Boolean(errors.email && touched.email)}
                {...getFieldProps('email')}
              />
              {errors.email && touched.email && (
                <FormHelperText error>{t(errors.email)}</FormHelperText>
              )}
            </Grid>

            {!!profileId && (
              <Grid item xs={12}>
                <InputLabel>{t('configuration.users.profiles.form.status')}</InputLabel>
                <Autocomplete
                  options={statusAvailables}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField {...params} error={Boolean(errors.statusId && touched.statusId)} />
                  )}
                  value={statusAvailables.find((st) => st.id === values.statusId) || null}
                  onChange={(e, value) => setFieldValue('statusId', value?.id || '')}
                  fullWidth
                  clearIcon={null}
                />
                {errors.statusId && touched.statusId && (
                  <FormHelperText error>{t(errors.statusId)}</FormHelperText>
                )}
              </Grid>
            )}
          </Grid>

          <Grid
            item
            container
            alignItems="center"
            justifyContent="flex-end"
            spacing={2}
            sx={{ mt: 1 }}
          >
            <Grid item>
              <Button variant="outlined" size="large" color="primary" onClick={handleCloseDialog}>
                {t('configuration.users.profiles.add.back')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                size="large"
                color="primary"
                disabled={isLoading || isLoadingUpdate}
                endIcon={(isLoading || isLoadingUpdate) && <CircularProgress size={20} />}
              >
                {!profileId
                  ? t('configuration.users.profiles.add.submit')
                  : t('configuration.users.profiles.edit.submit')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default AddUserProfileForm;
