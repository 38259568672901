import * as yup from 'yup';

export const graduateValidationSchema = yup.object().shape({
  graduationDate: yup
    .date()
    .typeError('academic.students_graduate.graduation_date.required')
    .required('academic.students_graduate.graduation_date.required'),
  certificateTitle: yup.string().required('academic.students_graduate.certificate_title.required'),
  educationCalification: yup
    .number()
    .typeError('academic.students_graduate.education_calification.invalid')
    .required('academic.students_graduate.education_calification.required'),
  educationObservation: yup.string(),
  documentUrl: yup.string().required('academic.students_graduate.document_url.required'),
  cityId: yup
    .number()
    .typeError('academic.students_graduate.city.required')
    .required('academic.students_graduate.city.required'),
  educationTypeId: yup
    .number()
    .min(1, 'academic.students_graduate.education_type.required')
    .typeError('academic.students_graduate.edu_type.required')
    .required('academic.students_graduate.edu_type.required'),
});
