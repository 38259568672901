import { Grid, Button } from '@material-ui/core';
import { ExitToApp } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { useAppSelector } from '../../../hooks/useRedux';
import { dashboardDict } from '../constants/shortsCups';

const ShortsCups = () => {
  const { currentModule } = useAppSelector((state) => state.access);

  const navigate = useHistory();

  const shortCups =
    dashboardDict[currentModule?.key!]
      // ?para mas de dos niveles tocara hacer un map recursivo
      ?.filter((item) => 'isImportant' in item && item.isImportant)
      ?.map((item) => ({
        title: item.title,
        children: item.children?.filter(
          (child) => child.isImportant && currentModule?.permissions![child.permissionKey!]
        ),
      })) || [];
  if (!shortCups.length) return <></>;

  console.log(shortCups);

  return (
    <Grid
      container
      item
      xs={12}
      lg={8}
      justifyContent={'center'}
      sx={{
        mt: 3,
      }}
    >
      {shortCups.map((item, idx) => (
        <Grid
          key={`container-shortcut-${idx}`}
          container
          item
          xs={12}
          sm={12}
          md={6}
          p={1}
          justifyContent={'center'}
        >
          {item?.children?.map((child, i) => (
            <Button
              key={`shortcut-${child.title}-${i}}`}
              variant={'outlined'}
              color={'primary'}
              sx={{ width: '100%', maxWidth: '250px' }}
              onClick={() => {
                navigate.push(child.url!);
              }}
              startIcon={<ExitToApp />}
            >
              {`Ir a ${child.title}`}
            </Button>
          ))}
        </Grid>
      ))}
    </Grid>
  );
};

export default ShortsCups;
