import { Grid, Typography } from '@material-ui/core';
import MainCard from '../../../../shared/components/cards/MainCard';
import TotalVisits from '../../components/virtuales/TotalVisits';

const TotalVisitsScreen = () => {
  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">Total de visitas</Typography>
      </Grid>
      <TotalVisits />
    </Grid>
  );
};

export default TotalVisitsScreen;
