import //useHistory,
//Link,
//useRouteMatch
'react-router-dom';
import {
  //Autocomplete,
  //Checkbox,
  //Button,
  Grid,
  IconButton,
  //Typography,
  //TextField,
  /* TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Table,
    CircularProgress,
    FormHelperText,
    Collapse,
    Box,
    useTheme, */
  // FormControlLabel,
} from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';
//import EditButton from '../../../shared/components/tables/buttons/EditButton';
import DeleteButton from '../../../../shared/components/tables/buttons/DeleteButton';
//import AddButton from '../../../../shared/components/tables/buttons/AddButton';

import { openDeleteModalWith } from '../../slices/lendingsSlice';
import useGuard from '../../hooks/useGuard';
//import { DatePicker, LocalizationProvider } from '@material-ui/lab';
//import esLocale from 'date-fns/locale/es';
//import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
//import StatusChip from '../../../shared/components/badges/StatusChip';
import SearchPaginatedTable from '../../../../shared/components/tables/SearchPaginatedTable';
import { useAppDispatch } from '../../../../../hooks/useRedux';
//import InputLabel from '../../../shared/components/forms/InputLabel';
//import { useState } from 'react';

const BookingTable = () => {
  const dispatch = useAppDispatch();

  const { hasAccess } = useGuard();
  //const { path } = useRouteMatch();
  //const history = useHistory();

  const handleDelete = async (periodId: number) => {
    dispatch(openDeleteModalWith(periodId));
  };

  /* const goToAdd = () => {
        history.push(`${path}/agregar`);
    }; */

  const data = [
    {
      id: 56846,
      user: 'Prueba',
      code: '202215156',
      author: 'Autor',
      ejemplares: '2',
      cantidad: '1',
      options: '',
    },
    {
      id: 56845,
      user: 'Prueba',
      code: '202215156',
      author: 'Autor',
      ejemplares: '2',
      cantidad: '1',
      options: '',
    },
    {
      id: 56847,
      user: 'Prueba',
      code: '202215156',
      author: 'Autor',
      ejemplares: '2',
      cantidad: '1',
      options: '',
    },
  ];

  return (
    <>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid
          item
          md={12}
          //sx={{ borderTop: '1px solid gainsboro' }}
        >
          <SearchPaginatedTable
            data={data}
            headers={{
              id: 'Código',
              user: 'Usuario',
              code: 'Código',
              author: 'Autor',
              ejemplares: 'Ejemplares',
              cantidad: 'Cantidad',
              options: 'Opciones',
            }}
            keyExtractor={(item) => item.id.toString()}
            searchPlacehoder="Buscar prestamo"
            isLoading={false}
            isFetching={false}
            error={undefined}
            perPage={1}
            setPerPage={() => {}}
            total={0}
            page={1}
            setPage={() => {}}
            numHeader={7}
            search={''}
            setSearch={() => {}}
            customRenderers={{
              // id: (row) => row.id.id,

              options: (row) => (
                <>
                  {hasAccess('delete') && <DeleteButton handleDelete={() => handleDelete(1)} />}
                  {hasAccess('edit') && (
                    <IconButton aria-label="delete">
                      <CheckCircle color="secondary" />
                    </IconButton>
                  )}
                  {!hasAccess('edit') && !hasAccess('delete') && 'Ninguna'}
                </>
              ),
            }}
            //ActionButtons={<>{hasAccess('create') && <AddButton size="large" onClick={goToAdd} />}</>}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default BookingTable;
