import { Grid, makeStyles, Typography } from '@material-ui/core';
import Carousel from 'react-material-ui-carousel';
import { IBook } from '../../../books/interfaces/books.interfaces';
import BookCard from './BookCard';

interface BooksListProps {
  books: IBook[];
}

const useStyles = makeStyles({
  carouselRoot: {
    width: '100%',
  },
});

const BooksList = ({ books }: BooksListProps) => {
  const classes = useStyles();

  const booksGroup = books?.reduce<IBook[][]>((acc, curr) => {
    if (acc.length > 0) {
      if (acc[acc.length - 1].length < 3) {
        acc[acc.length - 1].push(curr);
      } else {
        acc.push([curr]);
      }
    } else {
      acc.push([curr]);
    }
    return acc;
  }, []);

  return (
    <Grid container px={2} py={4}>
      {booksGroup && booksGroup.length > 0 ? (
        <Carousel autoPlay={false} className={classes.carouselRoot}>
          {booksGroup?.map((bookGroup) => (
            <Grid item container xs={12}>
              {bookGroup?.map((book) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  p={2}
                  sx={{
                    height: 'auto',
                  }}
                >
                  <BookCard book={book as IBook} />
                </Grid>
              ))}
            </Grid>
          ))}
        </Carousel>
      ) : (
        <Grid item xs={12}>
          <Typography textAlign="center" variant="h5">
            No hay libros disponibles
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default BooksList;
