export const getRRHHPeriods = () => {
  const year = new Date().getFullYear();
  const yearsPeriods = [];

  for (let i = 0; i <= 5; i++) {
    yearsPeriods.push({
      label: `${Number(year) - i} - ${Number(year) - i + 1}`,
      value: `${Number(year) - i} - ${Number(year) - i + 1}`,
    });
  }

  return yearsPeriods;
};
