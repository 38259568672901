import { Grid, makeStyles, Skeleton } from '@material-ui/core';
import { useEffect, useState } from 'react';
import Carousel from 'react-material-ui-carousel';
import { Classification } from '../../../classifications/interfaces/classifications.interface';
import { useGetAvailableClassificationsQuery } from '../../../classifications/slices/classificationsApiSlice';
import BooksList from '../Books/BooksList';
import CategoryCard from './CategoryCard';

const useStyles = makeStyles({
  carouselRoot: {
    width: '100%',
    overflow: 'visible',
  },
});

const CategoriesList = () => {
  const classes = useStyles();

  const [categoryIdSelected, setCategoryIdSelected] = useState('');

  const { data: categories = [], isLoading: isLoadingC } = useGetAvailableClassificationsQuery({
    parent_id: 1,
  });

  useEffect(() => {
    if (categories.length > 0) {
      setCategoryIdSelected(categories[0].id);
    }
  }, [categories]);

  const categoriesGroup = categories?.reduce<Classification[][]>((acc, curr) => {
    if (acc.length > 0) {
      if (acc[acc.length - 1].length < 5) {
        acc[acc.length - 1].push(curr);
      } else {
        acc.push([curr]);
      }
    } else {
      acc.push([curr]);
    }
    return acc;
  }, []);

  return (
    <Grid item xs={12} container px={2} py={4}>
      {isLoadingC && (
        <Grid item container xs={12} justifyContent="center">
          {Array.from(new Array(3)).map(() => (
            <Grid item container xs={12} sm={6} md={4}>
              <Grid item xs={12}>
                <Skeleton variant="circular" width="100px" height="100px" />
              </Grid>
              <Grid item xs={12}>
                <Skeleton variant="rectangular" width="100%" height="20px" />
              </Grid>
            </Grid>
          ))}
        </Grid>
      )}

      {!isLoadingC && categories.length > 0 && (
        <Carousel autoPlay={false} className={classes.carouselRoot}>
          {categoriesGroup?.map((categoryGroup) => (
            <Grid container spacing={1} justifyContent="center">
              {categoryGroup.map((category) => (
                <CategoryCard
                  onClick={() => setCategoryIdSelected(category.id)}
                  key={category.id}
                  selected={category.id === categoryIdSelected}
                  imageUrl={null!}
                  text={category.name}
                />
              ))}
            </Grid>
          ))}
        </Carousel>
      )}

      {/* {!isLoadingC && categories.length > 0 && (
        <Grid item container xs={12}>
          {categories?.map((category) => (
            <CategoryCard
              onClick={() => setCategoryIdSelected(category.id)}
              key={category.id}
              selected={category.id === categoryIdSelected}
              imageUrl={null!}
              text={category.name}
            />
          ))}
        </Grid>
      )} */}
      {/* 
      {!isLoadingC && categories.length === 0 && (
        <Grid item container xs={12} justifyContent="center">
          <Typography textAlign="center" variant="h5">
            No hay categorías
          </Typography>
        </Grid>
      )} */}

      {(isLoadingC || (categoryIdSelected === '' && categories.length > 0)) && (
        <Grid item container xs={12} justifyContent="center" mt={2}>
          {Array.from(new Array(3)).map(() => (
            <Grid item container xs={12} sm={6} md={4}>
              <Grid item xs={6}>
                <Skeleton variant="rectangular" width="100px" height="200px" />
              </Grid>
              <Grid item xs={6}>
                <Skeleton variant="rectangular" width="100px" height="30px" />
                <Skeleton
                  variant="rectangular"
                  width="100px"
                  height="30px"
                  sx={{
                    mt: 1,
                  }}
                />
                <Skeleton
                  variant="rectangular"
                  width="100px"
                  height="30px"
                  sx={{
                    mt: 1,
                  }}
                />
              </Grid>
            </Grid>
          ))}
        </Grid>
      )}

      {!isLoadingC && categoryIdSelected !== '' && (
        <BooksList books={categories?.find((c) => c.id === categoryIdSelected)?.book || []} />
      )}
    </Grid>
  );
};

export default CategoriesList;
