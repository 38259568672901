import {
  Table,
  Button,
  Grid,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
  useTheme,
  Link,
  Skeleton,
  Tooltip,
} from '@material-ui/core';
import { TiktokLogo } from 'phosphor-react';
import { IconEdit } from '@tabler/icons';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useAppSelector } from '../../../../hooks/useRedux';
import SubCard from '../../../shared/components/cards/SubCard';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import InstagramInIcon from '@material-ui/icons/Instagram';
import YoutubeInIcon from '@material-ui/icons/YouTube';
import InternetInIcon from '@material-ui/icons/Web';
import { FC } from 'react';

interface Props {
  isLoading: boolean;
}

const SocialCard: FC<Props> = (props) => {
  const { company } = useAppSelector((state) => state.companies);
  const { path } = useRouteMatch();
  const history = useHistory();
  const theme = useTheme();

  const { isLoading } = props;

  return (
    <Grid
      item
      sx={{
        width: '100%',
      }}
    >
      <SubCard
        content={false}
        title="Redes Sociales"
        secondary={
          company.co_ruc && (
            <Tooltip title="Editar esta sección" placement="top-end">
              <Button onClick={() => history.push(`${path}/editar-social`)}>
                <IconEdit stroke={1.5} size="1.3rem" />
              </Button>
            </Tooltip>
          )
        }
      >
        <Grid container p={2}>
          <Grid item xs={12}>
            <TableContainer>
              {isLoading ? (
                <>
                  <Skeleton variant="text" />
                  <Skeleton variant="text" />
                  <Skeleton variant="text" />
                </>
              ) : (
                <Table
                  sx={{
                    '& td': {
                      borderBottom: 'none',
                    },
                  }}
                  size="small"
                >
                  <TableBody>
                    {company.co_assigned_site ? (
                      <TableRow>
                        <TableCell width="5%" variant="head">
                          <Grid item container alignItems="center">
                            <InternetInIcon sx={{ color: theme.palette.grey[700] }} />
                          </Grid>
                        </TableCell>
                        <TableCell>
                          <Link
                            sx={{ color: theme.palette.text.secondary }}
                            href={company.co_assigned_site}
                            target="_blank"
                          >
                            {company.co_assigned_site}
                          </Link>
                        </TableCell>
                      </TableRow>
                    ) : null}

                    {company.co_facebook ? (
                      <TableRow>
                        <TableCell width="5%" variant="head">
                          <Grid item container alignItems="center">
                            <FacebookIcon sx={{ color: theme.palette.grey[700] }} />
                          </Grid>
                        </TableCell>
                        <TableCell>
                          <Link
                            sx={{ color: theme.palette.text.secondary }}
                            href={company.co_facebook}
                            target="_blank"
                          >
                            {company.co_facebook}
                          </Link>
                        </TableCell>
                      </TableRow>
                    ) : null}
                    {company.co_instagram ? (
                      <TableRow>
                        <TableCell width="5%" variant="head">
                          <Grid item container alignItems="center">
                            <InstagramInIcon sx={{ color: theme.palette.grey[700] }} />
                          </Grid>
                        </TableCell>
                        <TableCell>
                          <Link
                            sx={{ color: theme.palette.text.secondary }}
                            href={company.co_instagram}
                            target="_blank"
                          >
                            {company.co_instagram}
                          </Link>
                        </TableCell>
                      </TableRow>
                    ) : null}
                    {company.co_linkedin ? (
                      <TableRow>
                        <TableCell width="5%" variant="head">
                          <Grid item container alignItems="center">
                            <LinkedInIcon sx={{ color: theme.palette.grey[700] }} />
                          </Grid>
                        </TableCell>
                        <TableCell>
                          <Link
                            sx={{ color: theme.palette.text.secondary }}
                            href={company.co_linkedin}
                            target="_blank"
                          >
                            {company.co_linkedin}
                          </Link>
                        </TableCell>
                      </TableRow>
                    ) : null}
                    {company.co_youtube ? (
                      <TableRow>
                        <TableCell width="5%" variant="head">
                          <Grid item container alignItems="center">
                            <YoutubeInIcon sx={{ color: theme.palette.grey[700] }} />
                          </Grid>
                        </TableCell>
                        <TableCell>
                          <Link
                            sx={{ color: theme.palette.text.secondary }}
                            href={company.co_youtube}
                            target="_blank"
                          >
                            {company.co_youtube}
                          </Link>
                        </TableCell>
                      </TableRow>
                    ) : null}
                    {company.co_tiktok ? (
                      <TableRow>
                        <TableCell width="5%" variant="head">
                          <Grid item container alignItems="center">
                            <TiktokLogo
                              size="24px"
                              color={theme.palette.grey[700]}
                              fill={theme.palette.grey[700]}
                              weight="duotone"
                            />
                          </Grid>
                        </TableCell>
                        <TableCell>
                          <Link
                            sx={{ color: theme.palette.text.secondary }}
                            href={company.co_tiktok}
                            target="_blank"
                          >
                            {company.co_tiktok}
                          </Link>
                        </TableCell>
                      </TableRow>
                    ) : null}
                  </TableBody>

                  {company.co_assigned_site &&
                  company.co_facebook &&
                  company.co_instagram &&
                  company.co_linkedin &&
                  company.co_youtube ? null : (
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <p>No hay datos para mostrar</p>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              )}
            </TableContainer>
          </Grid>
        </Grid>
      </SubCard>
    </Grid>
  );
};

export default SocialCard;
