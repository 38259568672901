import { useState, useEffect } from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { useTranslation } from 'react-i18next';
import { isAfter, parseISO } from 'date-fns';
import { IconReceipt2 } from '@tabler/icons';

import { useAppSelector } from '../../../../../hooks/useRedux';
import { useGetPeriodsQuery } from '../../../periods/slices/periodsApiSlice';
import SearchPaginatedTable from '../../../../shared/components/tables/SearchPaginatedTable';
import CustomIconButton from '../../../../shared/components/tables/buttons/CustomIconButton';

interface Props {
  isLoading: boolean;
  massiveDebtsByStudentHandler: (customPeriodId?: number) => Promise<void>;
}

const SelectPeriodTable = ({ isLoading, massiveDebtsByStudentHandler }: Props) => {
  // const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const { currentPeriodIdMassiveDebts } = useAppSelector((state) => state.generateDebts);

  const { currentProfile } = useAppSelector((state) => state.access);
  // const { currentPeriodIdMassiveDebts } = useAppSelector((state) => state.generateDebts);

  //   const [selected, setSelected] = useState<SearchPersonParam | null>(null);

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const [search, setSearch] = useState('');

  //client data
  const {
    data,
    isFetching,
    isLoading: isLoadingTable,
    error,
  } = useGetPeriodsQuery({
    profileId: currentProfile?.profileId!,
    page,
    perPage,
    search,
    current: true,
  });

  // const handleSelect = (periodId: string | number) => {
  //   dispatch(setCurrentPeriodIdMassiveDebts(+periodId));
  //   // if (selected) {
  //   //   dispatch(setCurrentClient(selected));
  //   //   handleCloseDialog(e);
  //   // }
  // };

  useEffect(() => {
    setPage(1);
  }, [search]);

  const datafetch = data?.data?.map((value, idx) => ({
    id: (page - 1) * perPage + (idx + 1),
    per_name: value.per_name,
    // period_stage: {
    //   ...objMap(periodStagesAvailables, (key, _value) => ({
    //     name: value.period_stages?.find((item) => item.stage.stg_acronym === key)?.stage.stg_name,
    //     date: value.period_stages?.find((item) => item.stage.stg_acronym === key)?.end_date,
    //   })),
    // },

    period_m: value.period_stages?.find((item) => item.stage.stg_acronym === 'M')?.end_date || '',
    period_r: value.period_stages?.find((item) => item.stage.stg_acronym === 'R')?.end_date || '',

    detail: {
      // per_max_matter_enrollment: value?.per_max_matter_enrollment,
      // per_min_matter_enrollment: value?.per_min_matter_enrollment,
      per_num_fees: value?.per_num_fees,
      per_pay_enrollment: value?.per_pay_enrollment,
    },
    // status_id: value.status_id,
    options: value,
  }));

  const enableButtonHandler = (endDates: string[]) => {
    if (endDates.some((date) => isAfter(new Date().setHours(0, 0, 0, 0), parseISO(date)))) {
      return true;
    }
    return false;
  };

  return (
    <SearchPaginatedTable
      data={datafetch || []}
      headers={{
        id: t('#'),
        per_name: t('financial.generate_debts.massive.table.name'),
        // period_stage: t('financial.generate_debts.massive.table.period_stage'),

        period_m: t('financial.generate_debts.massive.table.period_stage.period_m'),
        period_r: t('financial.generate_debts.massive.table.period_stage.period_r'),

        detail: t('financial.generate_debts.massive.table.detail'),

        // status_id: 'Estado',
        options: t('financial.generate_debts.massive.generate_debts_btn'),
      }}
      keyExtractor={(item) => item.options.id.toString()}
      searchPlacehoder={t('financial.generate_debts.massive.table.placeholder')}
      isLoading={isLoadingTable}
      isFetching={isFetching}
      error={error}
      perPage={perPage}
      setPerPage={setPerPage}
      total={data?.total || 0}
      page={page}
      setPage={setPage}
      numHeader={3}
      search={search}
      setSearch={setSearch}
      perPageBox={false}
      // onClickObj={{
      //   onClickFunc: handleSelect,
      //   funcKey: 'id',
      //   isSelection: true,
      //   selected: currentPeriodIdMassiveDebts,
      // }}
      customRenderers={{
        // status_id: (row) => <StatusChip statusId={Number(row.status_id)} />,
        detail: ({ detail }) => (
          <Grid container item direction="column" gap={1}>
            {/* <Typography>Máximo materias: {detail.per_max_matter_enrollment}</Typography>
                <Typography>Mínimo materias: {detail.per_min_matter_enrollment}</Typography> */}
            <Typography>
              {t('financial.generate_debts.massive.table.detail.fee')}
              {detail.per_pay_enrollment}
            </Typography>
            <Typography>
              {t('financial.generate_debts.massive.table.detail.num_fee')}
              {detail.per_num_fees}
            </Typography>
          </Grid>
        ),
        // period_stage: ({ period_stage }) => (
        //   <Grid container item direction="column" gap={1}>
        //     {Object.entries(period_stage).map(
        //       ([key, value]) =>
        //         value.date && (
        //           <Grid item>
        //             {/* <Typography component="span" fontWeight="bold">
        //               {value.name}:{' '}
        //             </Typography> */}
        //             <Typography component="span">{value.date}</Typography>
        //           </Grid>
        //         )
        //     )}
        //   </Grid>
        // ),

        options: (row) => (
          <>
            {/* {hasAccess('edit') && ( */}
            <CustomIconButton
              title={t('financial.generate_debts.massive.generate_debts_btn')}
              onClick={() => massiveDebtsByStudentHandler(row.options.id)}
              color="primary"
              disabled={
                (isLoading && currentPeriodIdMassiveDebts === row.options.id) ||
                !enableButtonHandler([row.period_m, row.period_r].filter((item) => item))
                // !enableButtonHandler(
                //   Object.values(row.period_stage)
                //     .filter((item) => item.date)
                //     .map((item) => item.date!) || []
                // )
              }
              Icon={IconReceipt2}
            />
            {/* )} */}

            {/* <LoadingButton
              variant="contained"
              size="small"
              onClick={() => massiveDebtsByStudentHandler(row.options.id)}
              isLoading={isLoading}
              disabled={
                isLoading ||
                !enableButtonHandler(
                  Object.values(row.period_stage)
                    .filter((item) => item.date)
                    .map((item) => item.date!) || []
                )
              }
            >
              {t('financial.generate_debts.massive.generate_debts_btn')}
            </LoadingButton> */}
          </>
        ),
      }}
      customHeadersCellsProperties={{
        // options: {
        //   align: 'center',
        // },
        id: {
          align: 'center',
        },
      }}
      customDataCellsProperties={{
        id: {
          align: 'center',
        },
        // options: {
        //   align: 'center',
        // },
      }}
      //   ActionButtons={<>{hasAccess('create') && <AddButton size="large" onClick={goToAdd} />}</>}
    />
  );
};

export default SelectPeriodTable;
