import { useFormikContext } from 'formik';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';

import { ChalkboardTeacher, Folders } from 'phosphor-react';

const useStyles = makeStyles((theme) => ({
  container: {
    border: '1px solid red',
  },
  card: {
    border: `2px solid ${theme.palette.grey[50]}`,
    minWidth: 200,
    textAlign: 'center',
    cursor: 'pointer',
  },
}));

const CollaboratorTypeStep = () => {
  const classes = useStyles();

  const theme = useTheme();

  const { setFieldValue, values, errors } = useFormikContext<{ collaboratorType: string }>();

  const { collaboratorType } = values;

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item xs={12} sx={{ mb: 4 }}>
        <Typography variant="h3" textAlign="center" sx={{ color: 'grey.700' }}>
          Por favor, selecciona el tipo de colaborador
        </Typography>
      </Grid>

      <Grid item xs={12} container justifyContent="center" gap={3}>
        <Card
          className={classes.card}
          onClick={() => setFieldValue('collaboratorType', 'D')}
          sx={{ bgcolor: collaboratorType === 'D' ? 'primary.light' : 'none' }}
        >
          <CardHeader title="Docente" />
          <CardContent>
            <Grid container justifyContent="center">
              <ChalkboardTeacher size={48} color={theme.palette.secondary.main} />
            </Grid>
          </CardContent>
        </Card>
        <Card
          className={classes.card}
          onClick={() => setFieldValue('collaboratorType', 'A')}
          sx={{
            bgcolor: collaboratorType === 'A' ? 'primary.light' : 'none',
          }}
        >
          <CardHeader title="Administrativo" />
          <CardContent>
            <Grid container justifyContent="center">
              <Folders size={48} color={theme.palette.secondary.main} />
            </Grid>
          </CardContent>
        </Card>

        <Card
          className={classes.card}
          onClick={() => setFieldValue('collaboratorType', 'DA')}
          sx={{
            bgcolor: collaboratorType === 'DA' ? 'primary.light' : 'none',
          }}
        >
          <CardHeader title="Docente / Administrativo" />
          <CardContent>
            <Grid container justifyContent="center">
              <ChalkboardTeacher size={48} color={theme.palette.secondary.main} />

              <Folders size={48} color={theme.palette.secondary.main} />
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      {errors.collaboratorType && (
        <Typography variant="h5" textAlign="center" sx={{ color: 'grey.700', mt: 4 }}>
          Debes seleccionar un tipo de colaborador para continuar
        </Typography>
      )}
    </Grid>
  );
};

export default CollaboratorTypeStep;
