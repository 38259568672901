import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../hooks/useRedux';
import BackButton from '../../../shared/components/buttons/BackButton';

import MainCard from '../../../shared/components/cards/MainCard';
import AddDocumentByBinnancleDialog from '../components/AddDocumentByBinnancleDialog';
import ApproveBinnancleDialog from '../components/ApproveBinnancleDialog';
import DeleteBinnacleDialog from '../components/DeleteBinnacleDialog';
import DeleteDocumentByBinnancleDialog from '../components/DeleteDocumentByBinnancleDialog';
import DocumentsBinnancleDialog from '../components/DocumentsBinnancleDialog';
import TableStudentsByProjects from '../components/TableStudentsByProjects';

const StudentsByProjectScreen = () => {
  const { t } = useTranslation();

  const {
    approveBinnacleDialog,
    deleteBinnancleDialog,
    documentsBinnancleDialog,
    deleteDocumentDialog,
    addDocumentDialog,
  } = useAppSelector((state) => state.followUpOfResearch);

  return (
    <>
      <Grid component={MainCard} sx={{ padding: 2, minHeight: '500px' }}>
        <Grid sx={{ mb: 3, display: 'flex', alignItems: 'center' }}>
          <BackButton />
          <Typography variant="h2">{t('Estudiantes')}</Typography>
        </Grid>
        <TableStudentsByProjects />
      </Grid>
      {approveBinnacleDialog && <ApproveBinnancleDialog />}
      {deleteBinnancleDialog && <DeleteBinnacleDialog />}
      {documentsBinnancleDialog && <DocumentsBinnancleDialog />}
      {deleteDocumentDialog && <DeleteDocumentByBinnancleDialog />}
      {addDocumentDialog && <AddDocumentByBinnancleDialog />}
    </>
  );
};

export default StudentsByProjectScreen;
