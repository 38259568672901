import { useState, useMemo } from 'react';

import Grid from '@material-ui/core/Grid';
// import useTheme from '@material-ui/core/styles/useTheme';
import Button from '@material-ui/core/Button';

import { useTranslation } from 'react-i18next';

import { updateBondingProgramsTable, setBondingProgram } from '../../slices/academicRequestsSlice';

import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import { useTableSearch } from '../../../../../hooks/useTableSearch';
import { useGetBondingListQuery } from '../../slices/academicRequestsApiSlice';
import SearchPaginatedTable from '../../../../shared/components/tables/SearchPaginatedTable';

interface Props {
  handleCloseDialog: (e: React.SyntheticEvent) => void;
}

const BondingProgramsTable = ({ handleCloseDialog }: Props) => {
  // const theme = useTheme();
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const { currentRecordStudent } = useAppSelector((state) => state.access);
  const {
    bondingProgramsTable: { page, perPage, search },
    bondingProgram,
  } = useAppSelector((state) => state.academicRequests);

  const [selected, setSelected] = useState(bondingProgram || null);

  const { setPage, setPerPage, setSearch } = useTableSearch({
    page,
    perPage,
    search,
    updateFunction: updateBondingProgramsTable,
  });

  const { data, isLoading, isFetching, error } = useGetBondingListQuery(
    {
      page,
      perPage,
      search: search,
      studentRecordId: currentRecordStudent?.id!,
    },
    { skip: !currentRecordStudent }
  );

  const datafetch = useMemo(
    () =>
      data?.data.map((dt) => {
        return {
          name: dt,
          description: dt.activity_description,
          dates: `${dt.start_date} / ${dt.end_date_planned}`,
        };
      }),
    [data?.data]
  );

  const handleSelect = (programId: string | number) => {
    console.log(programId);
    setSelected(
      data?.data
        .map((dt) => ({ name: dt.name, value: dt.id }))
        .find((dt) => dt.value === (programId as string)) || null
    );
  };

  const handleDispatchSelection = (e: React.SyntheticEvent) => {
    if (selected) {
      dispatch(setBondingProgram(selected));
      handleCloseDialog(e);
    }
  };

  console.log({ data });

  return (
    <>
      <Grid container alignContent={'flex-end'} spacing={1}>
        <Grid item xs={12}>
          <SearchPaginatedTable
            data={datafetch || []}
            headers={{
              name: `${t('students.applications.bonding.projects_table.name')}`,
              description: `${t('students.applications.bonding.projects_table.description')}`,
              dates: `${t('students.applications.bonding.projects_table.dates')}`,
            }}
            keyExtractor={(item) => item.name.id}
            searchPlacehoder={t('students.applications.bonding.projects_table.search')}
            isLoading={isLoading}
            isFetching={isFetching}
            error={error}
            perPage={perPage}
            setPerPage={setPerPage}
            total={data?.total || 0}
            page={page}
            setPage={setPage}
            numHeader={3}
            search={search}
            setSearch={setSearch}
            perPageBox={false}
            onClickObj={{
              onClickFunc: handleSelect,
              funcKey: 'name.id',
              isSelection: true,
              selected: selected?.value,
            }}
            customRenderers={{
              name: (row) => <>{row.name.name}</>,
              // statusId: (row) => <StatusChip statusId={Number(row.statusId)} />,
              // name: (row) => (
              //   <>
              //     {row.name}
              //     <Typography variant="subtitle2" color="grey">
              //       {row.options.email}
              //     </Typography>
              //     <Typography variant="subtitle2" color="grey">
              //       {row.options.gender}
              //     </Typography>
              //   </>
              // ),
            }}
          />
        </Grid>

        <Grid container justifyContent={'flex-end'} spacing={1} mt={1}>
          <Grid item>
            <Button variant="outlined" size="large" onClick={(e) => handleCloseDialog(e)}>
              {t('students.applications.cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              size="large"
              disabled={!selected}
              onClick={handleDispatchSelection}
            >
              {t('students.applications.save')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default BondingProgramsTable;
