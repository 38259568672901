import { mainApi } from '../../../../api/mainApi';
import { middlewareApi } from '../../../middleware/middlewareApi';
import { IResponse } from '../../../shared/interfaces/response.interface';
import { Classification, ClassificationResponse } from '../interfaces/classifications.interface';

const classificationsApi = middlewareApi
  .enhanceEndpoints({ addTagTypes: ['classifications'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      /**
       * @GET classifications
       */
      getClassifications: builder.query<
        ClassificationResponse,
        {
          page?: number;
          size?: number;
          search?: string;
          sort?: string;
          type_sort?: string;
          parent_id?: number;
        }
      >({
        queryFn: async ({ page, size, search, sort, type_sort, parent_id }) => {
          try {
            const { data } = await mainApi.get<ClassificationResponse>('/classifications', {
              params: {
                page,
                size,
                search,
                sort,
                type_sort,
                parent_id,
              },
            });
            return { data };
          } catch (error: any) {
            return { error };
          }
        },
      }),

      getAvailableClassifications: builder.query<
        Classification[],
        {
          parent_id: number;
        }
      >({
        queryFn: async ({ parent_id }) => {
          try {
            const { data } = await mainApi.get<IResponse<Classification>>('/classifications', {
              params: {
                parent_id,
                data: 'all',
              },
            });
            return {
              data: Array.isArray(data?.data)
                ? data?.data.filter((classification) => classification.book.length > 0)
                : [],
            };
          } catch (error: any) {
            return { error };
          }
        },
      }),

      /**
       * @POST
       */
      addClassfication: builder.mutation<
        any,
        {
          payload: {
            user_profile_id: number;
            code: string;
            name: string;
            parent_id: string;
            status_id?: string;
          };
        }
      >({
        queryFn: async ({ payload }) => {
          try {
            const { data } = await mainApi.post<any>('/classifications', {
              ...payload,
            });
            return { data };
          } catch (error: any) {
            return { error };
          }
        },
        invalidatesTags: ['classifications'],
      }),
      /**
       * @PUT
       */
      editClassfication: builder.mutation<
        any,
        {
          id: string;
          payload: {
            user_profile_id: number;
            code: string;
            name: string;
            parent_id: string;
            status_id?: string;
          };
        }
      >({
        queryFn: async ({ id, payload }) => {
          try {
            const { data } = await mainApi.put<any>(`/classifications/${id}`, {
              ...payload,
            });
            return { data };
          } catch (error: any) {
            return { error };
          }
        },
        invalidatesTags: ['classifications'],
      }),
      /**
       * @DELETE delete classification
       */
      deleteClassification: builder.mutation<
        any,
        {
          id: string;
        }
      >({
        queryFn: async ({ id }) => {
          try {
            const { data } = await mainApi.delete<any>(`/classifications/${id}`);
            return { data };
          } catch (error: any) {
            return { error };
          }
        },
      }),
    }),
  });

export const {
  useGetClassificationsQuery,
  useGetAvailableClassificationsQuery,
  useLazyGetAvailableClassificationsQuery,
  useAddClassficationMutation,
  useEditClassficationMutation,
  useDeleteClassificationMutation,
} = classificationsApi;
