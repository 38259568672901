import { FormHelperText, Grid, TextField, TextFieldProps, InputLabel } from '@material-ui/core';
import { DateRangePickerProps, DateRangePicker, LocalizationProvider } from '@material-ui/lab';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import { JSXElementConstructor, ReactElement } from 'react';
import { useGetLocaleDateLang } from '../../../../translation/hooks/useGetLocaleDateLang';

export interface LocalizedDateRangePickerProps<TDate>
  extends Omit<DateRangePickerProps<TDate>, 'renderInput'> {
  error?: boolean;
  placeholder?: string;
  helperText?: string;
  hideLabel?: boolean;
  renderInput?: (props: TextFieldProps) => ReactElement<any, string | JSXElementConstructor<any>>;
  startError?: string;
  endError?: string;
  doubleRow?: boolean;
  showInputLabel?: boolean;
  startFormikProps?: any;
  endFormikProps?: any;
}

/**
 *
 * @param props - props pertenecientes a MUI DateRangePicker
 * @param props.calendars 1 or 2
 * @param props.startText - texto del label del input de fecha de inicio
 * @param props.endText - texto del label del input de fecha de fin
 * @param props.withLabel - si se muestra el label del input
 * @param props.renderInput - input personalizado (si no se pasa se usa el input por defecto)
 * @param props.placeholder - placeholder del input (si no se pasa se usa el placeholder por defecto)
 * @param props.helperText - helperText del input (si no se pasa se usa el helperText por defecto)
 * @param props.error - error del input
 * @param props.value - valor del input
 * @param props.onChange - onChange del input
 *
 *
 * @returns
 * DateRangePicker con localización de idioma y formato de fecha
 * @example
 * Componente simple de ejemplo:
 * <LocalizedDateRangePicker
 *    value={myValue}
 *    onChange={handleChange}
 * />
 * Componente con input personalizado:
 * <LocalizedDateRangePicker
 *     value={myValue}
 *     onChange={handleChange}
 *     renderInput={(startProps, endProps) => (
 *        <Grid container spacing={1} alignItems="center">
 *          <Grid item xs={6}>
 *            <TextField
 *              fullWidth
 *              {...startProps}
 *             />
 *          </Grid>
 *          <Grid item xs={6}>
 *            <TextField
 *              fullWidth
 *              {...endProps}
 *            />
 *          </Grid>
 *        </Grid>
 *     )}
 * />
 * @see https://material-ui.com/api/date-range-picker/
 * @see https://material-ui.com/components/date-picker/
 * @see https://material-ui.com/components/pickers/
 * @see https://material-ui.com/components/text-fields/
 * @see https://material-ui.com/components/grid/
 * @see https://material-ui.com/components/localization/
 * @see https://material-ui.com/components/pickers/#localized-formatting
 * @see https://material-ui.com/components/pickers/#customized-inputs
 */
const LocalizedDateRangePicker = (props: LocalizedDateRangePickerProps<Date>) => {
  const { lang } = useGetLocaleDateLang();

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={lang}>
      <DateRangePicker
        {...props}
        calendars={props.calendars || 1}
        startText={props.hideLabel ? null : props.startText || 'Fecha Inicio'}
        endText={props.hideLabel ? null : props.endText || 'Fecha Fin'}
        PopperProps={{ style: { ...((props.calendars || 1) === 1 && { left: '-400px' }) } }}
        {...(props.renderInput
          ? { renderInput: props.renderInput }
          : {
              renderInput: (startProps, endProps) => (
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={props.doubleRow ? 12 : 6}>
                    {props.showInputLabel && (
                      <InputLabel>{props.startText || 'Fecha Inicio'}</InputLabel>
                    )}
                    <TextField
                      fullWidth
                      {...startProps}
                      {...(!props.hideLabel ? { label: props.startText || 'Fecha Inicio' } : {})}
                      inputProps={{
                        ...startProps.inputProps,
                        placeholder: props.placeholder || 'dd/mm/a',
                      }}
                      helperText={props.helperText || null}
                      {...(props.startFormikProps ? props.startFormikProps : {})}
                      value={props.value?.[0] || null}
                      error={Boolean(props.startError)}
                    />
                    {Boolean(props.startError) && (
                      <FormHelperText error>{props.startError}</FormHelperText>
                    )}
                  </Grid>

                  <Grid item xs={props.doubleRow ? 12 : 6}>
                    {props.showInputLabel && (
                      <InputLabel>{props.endText || 'Fecha Fin'}</InputLabel>
                    )}
                    <TextField
                      fullWidth
                      {...endProps}
                      {...(!props.hideLabel ? { label: props.endText || 'Fecha Fin' } : {})}
                      inputProps={{
                        ...endProps.inputProps,
                        placeholder: props.placeholder || 'dd/mm/a',
                      }}
                      helperText={props.helperText || null}
                      {...(props.endFormikProps ? props.endFormikProps : {})}
                      value={props.value?.[0] || null}
                      error={Boolean(props.endError)}
                    />
                    {Boolean(props.endError) && (
                      <FormHelperText error>{props.endError}</FormHelperText>
                    )}
                  </Grid>
                </Grid>
              ),
            })}
      />
    </LocalizationProvider>
  );
};

export default LocalizedDateRangePicker;
