import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISearchTable } from '../../../../../shared/interfaces/paginatedTable.interface';
import { IEvaluationType } from '../../evaluation_type/interfaces/evaluationType.interface';
import {
  EvaTypeSelected,
  IntegralEvaluationsState,
} from '../interfaces/integralEvaluations.interface';
import { RubricData } from '../interfaces/rubrics.interface';

const initialState: IntegralEvaluationsState = {
  integralEvaluationsList: [],
  integralEvaluationsIdSelected: null,
  integralEvaluationsIdToDelete: null,
  integralEvaluationsSideDialogOpen: false,
  integralEvaluationsDeleteModalOpen: false,
  integralEvaluationsFormStepActive: 0,
  integralEvaluationsTable: {
    page: 1,
    perPage: 5,
    search: '',
  },
  integralEvaluationsOpenConfirmDialog: false,
  evaTypeSelected: null,
  evaTypesAutocomplete: [],
  deleteIdSelected: null,
  isOpenDeleteDialog: false,
};

export const integralEvaluationsSlice = createSlice({
  name: 'integralEvaluations',
  initialState,
  reducers: {
    setIntegralEvaluationsList: (state, action: PayloadAction<RubricData[]>) => {
      state.integralEvaluationsList = action.payload;
    },
    setIntegralEvaluationsIdSelected: (state, action: PayloadAction<string | null>) => {
      state.integralEvaluationsIdSelected = action.payload;
    },
    setIntegralEvaluationsIdToDelete: (state, action: PayloadAction<string | null>) => {
      state.integralEvaluationsIdToDelete = action.payload;
    },
    setIntegralEvaluationsDeleteModalOpen: (state, action: PayloadAction<boolean>) => {
      state.integralEvaluationsDeleteModalOpen = action.payload;
    },
    setIntegralEvaluationsTable: (state, action: PayloadAction<ISearchTable>) => {
      state.integralEvaluationsTable = {
        ...state.integralEvaluationsTable,
        ...action.payload,
      };
    },
    setIntegralEvaluationsSideDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.integralEvaluationsSideDialogOpen = action.payload;
    },
    setIntegralEvaluationsFormStepActive: (state, action: PayloadAction<number>) => {
      state.integralEvaluationsFormStepActive = action.payload;
    },
    setIntegralEvaluationsOpenConfirmDialog: (state, action: PayloadAction<boolean>) => {
      state.integralEvaluationsOpenConfirmDialog = action.payload;
    },
    setEvaTypeSelected: (state, action: PayloadAction<EvaTypeSelected | null>) => {
      state.evaTypeSelected = action.payload;
    },
    setEvaTypesAutocomplete: (state, action: PayloadAction<IEvaluationType[]>) => {
      state.evaTypesAutocomplete = action.payload;
    },
    setDeleteIdSelected: (state, action: PayloadAction<string | number | null>) => {
      state.deleteIdSelected = action.payload;
    },
    setIsOpenDeleteDialog: (state, action: PayloadAction<boolean>) => {
      state.isOpenDeleteDialog = action.payload;
    },
  },
});

export const {
  setIntegralEvaluationsList,
  setIntegralEvaluationsIdSelected,
  setIntegralEvaluationsIdToDelete,
  setIntegralEvaluationsDeleteModalOpen,
  setIntegralEvaluationsTable,
  setIntegralEvaluationsSideDialogOpen,
  setIntegralEvaluationsFormStepActive,
  setIntegralEvaluationsOpenConfirmDialog,
  setEvaTypeSelected,
  setEvaTypesAutocomplete,
  setDeleteIdSelected,
  setIsOpenDeleteDialog,
} = integralEvaluationsSlice.actions;
