import {
  Autocomplete,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
} from '@material-ui/core';
import { DateTimePicker, LocalizationProvider } from '@material-ui/lab';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import { format, isValid, parseISO } from 'date-fns';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../hooks/useRedux';
import { useGetLocaleDateLang } from '../../../../translation/hooks/useGetLocaleDateLang';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import TextFieldFetching from '../../../shared/components/TextFieldFetching';
import { useGetCatalogByAcronymQuery } from '../../../shared/slices/catalogApi';
import {
  useGetFinancialInstitutionAccountQuery,
  useGetFinancialInstitutionsQuery,
} from '../../../students/payments-history/slices/paymentsApiSlice';
import { editDepositTransferSchema } from '../constants/validations';
import { useUpdateDepositTransferMutation } from '../slices/depositsTransfersApiSlice';

const EditDepositTransferForm = () => {
  const { t } = useTranslation();

  const { currentProfile } = useAppSelector((state) => state.access);

  const [showAlert, setShowAlert] = useState(false);

  const { lang } = useGetLocaleDateLang();

  const { depositTransferId, depositTransferList } = useAppSelector(
    (state) => state.depositsTransfers
  );

  const depositTransfer = depositTransferList?.find((item) => item.id === depositTransferId);

  const { data: financialInstitutesAccounts, isLoading: isLoadingFinancialInstitutesAccounts } =
    useGetFinancialInstitutionAccountQuery({
      profileId: currentProfile?.profileId!,
    });

  const { data: financialInstitutions, isLoading: isLoadingFinancialInstitutions } =
    useGetFinancialInstitutionsQuery({
      profileId: currentProfile?.profileId!,
    });
  const { data: paymentMethods, isLoading: isLoadingPaymentMethods } = useGetCatalogByAcronymQuery({
    profileId: currentProfile?.profileId!,
    acronym: 'FP',
  });

  const [updateDepositTransfer, { isLoading, isSuccess, isError, error }] =
    useUpdateDepositTransferMutation();

  const { values, setFieldValue, handleSubmit, getFieldProps, touched, errors } = useFormik({
    initialValues: {
      paymentMethodKeyword: depositTransfer?.payment_way?.cat_keyword || '',
      financialInstituteTargetId: Number(depositTransfer?.financial_institution_target_id),
      financialInstituteAccountNumber: depositTransfer?.cuenta_destino,
      paymentDate: parseISO(depositTransfer?.payment_date!),
      voucherNumber: depositTransfer?.voucher_number,
      financialInstituteOriginId: depositTransfer?.financial_institution_origin_id
        ? Number(depositTransfer?.financial_institution_origin_id)
        : null, // SOLO PARA TRANSFERENCIAS
      nameHolderOriginAccount: depositTransfer?.name_holder_origin_account, // SOLO PARA TRANSFERENCIAS
    },
    validationSchema: editDepositTransferSchema,
    onSubmit: async (_values) => {
      await updateDepositTransfer({
        paymentMethodKeyword: _values.paymentMethodKeyword,
        transactionId: depositTransferId!,
        paymentDate: format(_values.paymentDate, "yyyy-MM-dd'T'H:mm:ss"),
        targetAccountNumber: _values.financialInstituteAccountNumber!,
        targetFinancialInstituteId: _values.financialInstituteTargetId,
        voucherNumber: _values.voucherNumber!,
        financialInstituteOriginId: _values.financialInstituteOriginId!,
        nameHolderOriginAccount: _values.nameHolderOriginAccount!,
        profileId: currentProfile?.profileId!,
      });
      setShowAlert(true);
    },
  });
  console.log(depositTransfer);
  console.log(values);
  const paymentMethodsToShow =
    paymentMethods?.filter((item) =>
      ['forma-pago-deposito', 'forma-pago-transferencia'].includes(item.cat_keyword)
    ) || [];

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        {isSuccess && showAlert && (
          <Grid item xs={12}>
            <SuccessAlert
              message={t('financial.deposit_transfers.edit.success')}
              handleDismiss={() => setShowAlert(false)}
            />
          </Grid>
        )}
        {isError && showAlert && (
          <Grid item xs={12}>
            <ErrorAlert message={error as string} handleDismiss={() => setShowAlert(false)} />
          </Grid>
        )}

        <Grid item xs={12}>
          <InputLabel>{t('financial.debts_payment.pay_dialog.payment_method_label')}</InputLabel>
          {isLoadingPaymentMethods ? (
            <TextFieldFetching />
          ) : (
            <Autocomplete
              options={paymentMethodsToShow}
              loading={isLoadingPaymentMethods}
              getOptionLabel={(option) => option.cat_name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={t(
                    'financial.deposit_transfers.change_payment_method.payment_method.placeholder'
                  )}
                  error={Boolean(errors.paymentMethodKeyword && touched.paymentMethodKeyword)}
                />
              )}
              value={
                paymentMethodsToShow.find(
                  (paymentMethod) => paymentMethod?.cat_keyword === values.paymentMethodKeyword
                ) || null
              }
              onChange={(event, value) => {
                setFieldValue('paymentMethodKeyword', value?.cat_keyword || '');
              }}
            />
          )}
          {errors.paymentMethodKeyword && touched.paymentMethodKeyword && (
            <FormHelperText error>{t(errors.paymentMethodKeyword)}</FormHelperText>
          )}
        </Grid>

        <Grid item xs={12}>
          <InputLabel>{t('financial.deposit_transfers.edit.destined_institution')}</InputLabel>

          {isLoadingFinancialInstitutesAccounts ? (
            <TextField
              fullWidth
              value={`${depositTransfer?.financial_institution_target?.fin_int_name} - ${depositTransfer?.cuenta_destino}`}
              disabled
              InputProps={{
                endAdornment: <CircularProgress size={14} />,
              }}
            />
          ) : (
            <Autocomplete
              fullWidth
              options={financialInstitutesAccounts || []}
              getOptionLabel={(option) =>
                `${option.financial_institution.fin_int_name} - ${option.fia_account_number}`
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={t(
                    'financial.deposit_transfers.edit.destined_institution.placeholder'
                  )}
                  error={Boolean(
                    errors.financialInstituteTargetId && touched.financialInstituteTargetId
                  )}
                />
              )}
              value={financialInstitutesAccounts?.find(
                (item) => item.fia_account_number === values.financialInstituteAccountNumber
              )}
              onChange={(event, newValue) => {
                setFieldValue('financialInstituteTargetId', newValue?.financial_institution?.id);
                setFieldValue('financialInstituteAccountNumber', newValue?.fia_account_number);
              }}
              disableClearable
            />
          )}
          {errors.financialInstituteTargetId && touched.financialInstituteTargetId && (
            <FormHelperText error>{t(errors.financialInstituteTargetId)}</FormHelperText>
          )}
        </Grid>
        <Grid item xs={12}>
          <InputLabel>{t('financial.deposit_transfers.edit.pay_date')}</InputLabel>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={lang}>
            <DateTimePicker
              value={values.paymentDate}
              onChange={(newValue) => isValid(newValue) && setFieldValue('paymentDate', newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  helperText={null}
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                  sx={{
                    '& .MuiInputBase-input': {
                      caretColor: 'transparent',
                    },
                  }}
                  error={Boolean(errors.paymentDate && touched.paymentDate)}
                />
              )}
            />
          </LocalizationProvider>
          {touched.paymentDate && errors.paymentDate && (
            <FormHelperText error>{t(errors.paymentDate as string)}</FormHelperText>
          )}
        </Grid>

        <Grid item xs={12}>
          <InputLabel>{t('financial.deposit_transfers.edit.voucher_number')}</InputLabel>
          <TextField
            fullWidth
            {...getFieldProps('voucherNumber')}
            error={Boolean(touched.voucherNumber && errors.voucherNumber)}
            placeholder={t('financial.deposit_transfers.edit.voucher_number.placeholder')}
          />
          {touched.voucherNumber && errors.voucherNumber && (
            <FormHelperText error>{t(errors.voucherNumber)}</FormHelperText>
          )}
        </Grid>

        {values.paymentMethodKeyword === 'forma-pago-transferencia' && (
          <>
            <Grid item xs={12}>
              <InputLabel>
                {t('financial.deposit_transfers.edit.name_holder_origin_account')}
              </InputLabel>
              <TextField
                fullWidth
                {...getFieldProps('nameHolderOriginAccount')}
                placeholder={t('financial.deposit_transfers.edit.name_holder_origin_account')}
                error={Boolean(touched.nameHolderOriginAccount && errors.nameHolderOriginAccount)}
              />
              {touched.nameHolderOriginAccount && errors.nameHolderOriginAccount && (
                <FormHelperText error>{t(errors.nameHolderOriginAccount)}</FormHelperText>
              )}
            </Grid>
            <Grid item xs={12}>
              <InputLabel>{t('financial.deposit_transfers.edit.origin_institution')}</InputLabel>
              {isLoadingFinancialInstitutions ? (
                <TextField
                  fullWidth
                  value={`${depositTransfer?.financial_institution_origin?.fin_int_name}`}
                  disabled
                  InputProps={{
                    endAdornment: <CircularProgress size={14} />,
                  }}
                />
              ) : (
                <Autocomplete
                  fullWidth
                  options={financialInstitutions || []}
                  getOptionLabel={(option) => option.fin_int_name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(
                        errors.financialInstituteOriginId && touched.financialInstituteOriginId
                      )}
                      placeholder={t(
                        'financial.deposit_transfers.edit.origin_institution.placeholder'
                      )}
                    />
                  )}
                  value={financialInstitutions?.find(
                    (item) => item.id === values.financialInstituteOriginId
                  )}
                  onChange={(event, newValue) => {
                    setFieldValue('financialInstituteOriginId', newValue?.id);
                  }}
                  disableClearable
                />
              )}
              {touched.financialInstituteOriginId && errors.financialInstituteOriginId && (
                <FormHelperText error>{t(errors.financialInstituteOriginId)}</FormHelperText>
              )}
            </Grid>
          </>
        )}

        <Grid item>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isLoading}
            endIcon={isLoading && <CircularProgress size={14} />}
          >
            {t('financial.deposit_transfers.edit.save')}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default EditDepositTransferForm;
