import { hasSubPermission } from '../../../../helpers/prmissionsHelpers';

import { useAppSelector } from '../../../../hooks/useRedux';

import {
  permissionKey,
  subPermissionKeys,
  SubPermissionKeys,
} from '../interfaces/permissions.interface';

const useGuard = () => {
  const { currentModule } = useAppSelector((state) => state.access);

  const { permissions } = currentModule!;

  const hasAccess = (spKey: keyof SubPermissionKeys) =>
    hasSubPermission(permissions!, permissionKey, subPermissionKeys[spKey]);

  return {
    hasAccess,
  };
  
};

export default useGuard;
