import { Button, CircularProgress, Dialog, Grid, Typography, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { IconFileInfo, IconCircleCheck, IconFileAlert } from '@tabler/icons';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { useDeleteCourseMutation } from '../slices/courseApiSlice';
import { resetCourseIdToDelete, setDeleteModalOpen } from '../slices/courseSlice';

const useStyles = makeStyles((theme) => ({
  paper: { maxWidth: '600px', width: '90%' },
}));

const DeleteCourseDialog = () => {
  const theme = useTheme();
  const classes = useStyles();

  const dispatch = useAppDispatch();

  const { deleteModalOpen, courseIdToDelete } = useAppSelector(
    (state) => state.courses
  );
  const { currentProfile } = useAppSelector((state) => state.access);

  const [deleteCourse, { isLoading: isDeleting, isSuccess, isError, error }] =
  useDeleteCourseMutation();

  const handleDelete = () => {
    deleteCourse({
      profileId: currentProfile?.profileId!,
      courseId: courseIdToDelete!,
    });
  };

  const handleClose = () => {
    dispatch(setDeleteModalOpen(false));
    dispatch(resetCourseIdToDelete());
  };

  return (
    <Dialog
      classes={{ paper: classes.paper }}
      open={deleteModalOpen}
      onClose={handleClose}
      sx={{ p: 3 }}
    >
      <Grid container gap={4} sx={{ p: 2 }}>
        <Grid item container xs={12} alignItems="center" justifyContent="center">
          {!isError && !isSuccess && !isDeleting && (
            <IconFileInfo
              size={60}
              color={theme.palette.error.main}
              style={{
                padding: 12,
                backgroundColor: theme.palette.orange.light,
                borderRadius: '50%',
              }}
            />
          )}
          {isDeleting && <CircularProgress size={50} />}
          {isSuccess && (
            <IconCircleCheck
              size={60}
              color={theme.palette.success.main}
              style={{
                padding: 12,
                backgroundColor: theme.palette.success.light,
                borderRadius: '50%',
              }}
            />
          )}
          {isError && (
            <IconFileAlert
              size={60}
              color={theme.palette.error.main}
              style={{
                padding: 12,
                backgroundColor: theme.palette.orange.light,
                borderRadius: '50%',
              }}
            />
          )}
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Typography variant="h2" sx={{ color: theme.palette.primary.main, mb: 1 }}>
            {!isError && !isSuccess && '¿Estás seguro de eliminar el registro?'}
            {isSuccess && 'Hemos Eliminado el registro con éxito'}
            {isError && 'Ha ocurrido un error'}
          </Typography>
          <Typography variant="body2">
            {!isError &&
              !isSuccess &&
              'Eliminaremos el registro de forma permanente, ¿Estás seguro de realizar esta acción?'}
            {isError && error}
          </Typography>
        </Grid>
        <Grid item container xs={12} justifyContent="center" gap={1}>
          {!isSuccess && !isError && (
            <Button
              variant="text"
              size="large"
              color="primary"
              onClick={handleClose}
              disabled={isDeleting}
            >
              No, Cancelar
            </Button>
          )}

          {(isSuccess || isError) && (
            <Button variant="contained" size="large" color="primary" onClick={handleClose}>
              Continuar
            </Button>
          )}

          {!isSuccess && !isError && (
            <Button
              variant="contained"
              size="large"
              onClick={handleDelete}
              color="primary"
              autoFocus
              disabled={isDeleting}
            >
              Sí, Eliminar
            </Button>
          )}
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default DeleteCourseDialog;
