import DeleteAlert from '../../../../../shared/components/dialogs/DeleteAlert';
import { useDeleteStudentJobInformationMutation } from '../../../slices/studentsApiSlice';

interface Props {
  idSelected: number | null;
  studentId: string;
  handleClose: () => void;
}

const DeleteJobDialog = ({ idSelected, studentId, handleClose }: Props) => {
  const [deleteJob, { isLoading, isSuccess, isError, error }] =
    useDeleteStudentJobInformationMutation();

  const handleDelete = () => {
    deleteJob({
      jobId: idSelected!,
      studentId,
    });
  };

  return (
    <DeleteAlert
      open={Boolean(idSelected)}
      onClose={handleClose}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      handleDelete={handleDelete}
      error={error as string}
    />
  );
};

export default DeleteJobDialog;
