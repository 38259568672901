import { useEffect, useMemo } from 'react';

import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Grid, Typography, IconButton, useTheme } from '@material-ui/core';
import { IconArrowNarrowLeft } from '@tabler/icons';

import { useAppSelector } from '../../../../hooks/useRedux';
import MainCard from '../../../shared/components/cards/MainCard';
// import DeleteRoleDialog from '../components/DeleteRoleDialog';

import { useAppDispatch } from '../../../../hooks/useRedux';
import { useLazyGetAreaQuery, useUpdateAreaMutation } from '../slices/areasApiSlice';
import { setAreasList } from '../slices/areasSlice';
import AreaForm from '../components/AreaForm';
import SimpleFormOneColumn from '../../../shared/components/forms/skeletons/SimpleFormOneColumn';
import { AreaPayload } from '../interfaces/payload.interface';
import { useTranslation } from 'react-i18next';
// import UserProfilesTable from '../components/UserProfilesTable';
// import { renderSkeletonTable } from '../../../shared/helpers/render';

const EditAreaScreen = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  const { areaId } = useParams<{ areaId: string }>();

  const { areasList } = useAppSelector((state) => state.areas);

  const [getArea, { isFetching }] = useLazyGetAreaQuery();

  const [editArea, { isLoading, isError, error, isSuccess }] = useUpdateAreaMutation();

  const editAreaHandler = async (values: AreaPayload) => {
    await editArea({ areaId: +areaId, areaPayload: values });
  };

  useEffect(() => {
    if (!areasList.length && !isFetching) {
      getArea({
        areaId: +areaId,
      })
        .unwrap()
        .then((response) => {
          if (response) dispatch(setAreasList([response!]));
        })
        .catch((error) => console.log(error));
    }
  }, [getArea, isFetching, areaId, areasList.length, dispatch]);

  const areaToEdit = useMemo(() => {
    return areasList.find((dt) => dt.id === Number(areaId));
  }, [areaId, areasList]);

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid item xs={12} sx={{ mb: 3 }}>
        <Grid item container alignItems="center" gap={1}>
          <IconButton onClick={() => history.push(`/${location.pathname.split('/')[1]}`)}>
            <IconArrowNarrowLeft size={30} color={theme.palette.text.dark} />
          </IconButton>
          <Typography variant="h2">
            {t('academic.areas.edit.title')} - {areaToEdit?.ar_name}
          </Typography>
        </Grid>
      </Grid>

      <Grid item xs={12} md={8} lg={6} sx={{ mt: 2 }}>
        {Boolean(areasList.length) ? (
          <AreaForm
            {...(areaId && { editId: +areaId })}
            states={{ isLoading, isError, error, isSuccess }}
            submitRequestHandler={editAreaHandler}
          />
        ) : (
          <SimpleFormOneColumn />
        )}
      </Grid>
      {/* {roleIdToDelete && <DeleteRoleDialog />} */}
    </Grid>
  );
};

export default EditAreaScreen;
