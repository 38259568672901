import React, { useState, useEffect } from 'react';
// material-ui
import {
  Button,
  Step,
  Stepper,
  StepLabel,
  Stack,
  Typography,
  Grid,
  CircularProgress,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

// project imports
import AddressForm, { ShippingData } from './Identification';
import PaymentForm, { PaymentData } from './information';
import Review from './Review';
// import MainCard from '../../../../shared/components/cards/MainCard';
import AnimateButton from '../../../../shared/components/extended/AnimateButton';
import { useAppSelector, useAppDispatch } from '../../../../../hooks/useRedux';
import { useSendRequisitionMutation } from '../../slices/requisitionsApiSlice';
import ErrorAlert from '../../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../../shared/components/alerts/SuccessAlert';
import {
  resetListAcademicFormations,
  resetListSubjects,
  resetListLaboralExperiences,
} from '../../slices/requisitionsSlice';

// step options
const steps = ['Identificación del Docente', 'Solicitud', 'Revisión de solicitud'];

export interface SubjectsArray {
  matter_id: number;
  matter_name: string;
  num_paralels: number;
  sede: number;
  sede_name: string;
  h_planificadas: string | number;
  hourhand: string;
}

export interface AcademicFormation {
  certificate_title: string;
  graduation_date: string | Date;
  education_calification?: number;
  education_observation: string;
  document_url: string | null;
  institute_id: number;
  city_id: number;
  city_name: string;
  type_education_id: number;
  status_id: number;
  ies_code: string;
  senescyt_register_number: string;
  unesco_code: string;
  start_date: Date | string;
  end_date: Date | string;
  education_status: string;
  country_id: number;
}

export interface LaboralExperience {
  per_job_organization: string;
  per_job_position: string;
  per_job_phone: string;
  per_job_start_date: string | Date;
  per_job_end_date: string | Date;
  per_job_current: number | boolean;
  per_job_iess_affiliated: number;
  document_url: string | null;
  city_id: number;
  country_id: number;
  city_name: string;
  status_id: number;
  name_institution: string;
  subjects_taught: string;
}

const getStepContent = (
  step: number,
  handleNext: () => void,
  handleBack: () => void,
  setErrorIndex: (i: number | null) => void,
  shippingData: ShippingData,
  setShippingData: (d: ShippingData) => void,
  paymentData: PaymentData,
  setPaymentData: (d: PaymentData) => void
) => {
  switch (step) {
    case 0:
      return (
        <AddressForm
          handleNext={handleNext}
          setErrorIndex={setErrorIndex}
          shippingData={shippingData}
          setShippingData={setShippingData}
        />
      );
    case 1:
      return (
        <PaymentForm
          handleNext={handleNext}
          handleBack={handleBack}
          setErrorIndex={setErrorIndex}
          paymentData={paymentData}
          setPaymentData={setPaymentData}
        />
      );
    case 2:
      return <Review information={paymentData} identification={shippingData} />;
    default:
      throw new Error('Unknown step');
  }
};

//-----------------------|| FORMS WIZARD - BASIC ||-----------------------//

const ValidationWizard = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const [activeStep, setActiveStep] = React.useState(0);
  const [shippingData, setShippingData] = React.useState<ShippingData>({});
  const [paymentData, setPaymentData] = React.useState<PaymentData>({});
  const [errorIndex, setErrorIndex] = React.useState<number | null>(null);

  const { currentProfile, currentRol } = useAppSelector((state) => state.access);
  // const { userInfo } = useAppSelector((state) => state.auth);

  // const { userInfo } = useAppSelector((state) => state.auth);

  const profileId = currentProfile?.profileId!;
  const rolId = currentRol?.rolId!;
  // const userId = userInfo?.id!;

  //tables data
  const { listSubjects, listAcademicFormation, listLaboralExperience } = useAppSelector(
    (state) => state.requisitionTeacher
  );

  const [sendRequisition, { isLoading, isError, error, isSuccess }] = useSendRequisitionMutation();

  const handleNext = () => {
    setActiveStep(activeStep + 1);
    setErrorIndex(null);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));

  const [showAlerts, setShowAlerts] = useState(true);

  const dataForm1 = {
    user_id_requisition: paymentData.userId,
    // user_id_requisition: userId,
    coll_email: paymentData.email,
    offer_id: paymentData.offerId,
    hourhand_id: '1',
    before_period_id: paymentData.beforePeriodId,
    new_period_id: paymentData.newPeriodId,
    education_level_id: paymentData.educationLevelId,
    before_period: paymentData.lastPeriod,
    new_period: paymentData.newPeriod,
  };

  const dataForm2 = {
    tipo_vinculacion: paymentData.tipoVinculacion,
    tipo_dedicacion: paymentData.tipoDedicacion,
    type_payment: paymentData.typePayment,
    salary: paymentData.salary,
    type_contract: paymentData.typeContract,
    start_date: paymentData?.startDate?.toISOString().split('T')[0],
    end_date: paymentData?.endDate?.toISOString().split('T')[0],
  };

  const subjectsArray: SubjectsArray[] = listSubjects.map((e) => {
    return {
      matter_id: e.subject.id,
      matter_name: e.subject.name,
      num_paralels: e.parallels,
      sede: e.campus.id,
      sede_name: e.campus.name,
      h_planificadas: e.hours,
      course_id: e.courseId,
      hourhand: e.hourhand,
    };
  });

  const academicFormationArray: AcademicFormation[] = listAcademicFormation.map((e) => {
    return {
      certificate_title: e.certificate_title,
      graduation_date: e.graduation_date,
      education_calification: e.education_calification,
      education_observation: e.education_observation,
      document_url: e.document_url,
      institute_id: e.institute_id.id,
      city_id: e.city_id.id,
      city_name: e.city_id.name,
      type_education_id: e.type_education_id.id,
      status_id: e.status_id,
      ies_code: e.ies_code,
      senescyt_register_number: e.senescyt_register_number,
      unesco_code: e.unesco_code,
      start_date: e.start_date,
      end_date: e.end_date,
      education_status: e.education_status,
      country_id: e.country_id,
    };
  });

  const laboralExperienceArray: LaboralExperience[] = listLaboralExperience.map((e) => {
    return {
      per_job_organization: e.per_job_organization,
      per_job_position: e.per_job_position,
      per_job_phone: e.per_job_phone,
      per_job_start_date: e.per_job_start_date,
      per_job_end_date: e.per_job_end_date,
      per_job_current: e.per_job_current,
      per_job_iess_affiliated: e.per_job_iess_affiliated,
      document_url: e.document_url,
      city_id: e.city_id.id,
      country_id: e.country_id,
      city_name: e.city_id.name,
      status_id: e.status_id,
      name_institution: e.name_institution,
      subjects_taught: e.subjects_taught,
    };
  });

  const sendData = async () => {
    // return {
    //   app_description: t('academic.requisition.edit.wizard.app_desc'),
    //   // user_id: userId,
    //   user_id: paymentData.userId,
    //   role_id: rolId,
    //   json: [dataForm1, dataForm2, subjectsArray, academicFormationArray, laboralExperienceArray],
    // };

    await sendRequisition({
      profileId,
      requisitionPayload: {
        app_description: t('academic.requisition.edit.wizard.app_desc'),
        // user_id: userId,
        user_id: paymentData.userId,
        role_id: rolId,
        json: [dataForm1, dataForm2, subjectsArray, academicFormationArray, laboralExperienceArray],
      },
    });

    setShowAlerts(true);
  };

  useEffect(() => {
    if (isSuccess) {
      setActiveStep(steps.length);
    }
  }, [isSuccess]);

  return (
    <Grid container>
      <Grid item xs={12}>
        {isError && showAlerts && (
          <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
        )}

        {isSuccess && showAlerts && (
          <SuccessAlert
            message={t('academic.requisition.edit.wizard.success')}
            handleDismiss={() => setShowAlerts(false)}
          />
        )}
        <Stepper
          activeStep={activeStep}
          sx={{ pt: 3, pb: 5 }}
          orientation={matchDownSM ? 'vertical' : 'horizontal'}
        >
          {steps.map((label, index) => {
            const labelProps: { error?: boolean; optional?: React.ReactNode } = {};

            if (index === errorIndex) {
              labelProps.optional = (
                <Typography variant="caption" color="error">
                  Error
                </Typography>
              );

              labelProps.error = true;
            }

            return (
              <Step key={label}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Grid>
      <Grid item xs={12}>
        {activeStep === steps.length ? (
          <React.Fragment>
            <Typography variant="h5" gutterBottom>
              {t('academic.requisition.edit.wizard.sent.title')}
            </Typography>
            <Typography variant="subtitle1">
              {t('academic.requisition.edit.wizard.sent.subtitle')}
            </Typography>
            <Stack direction="row" justifyContent="flex-end">
              <AnimateButton>
                {/**
                 * wrong prop color="error"
                 * color?: "inherit" | "primary" | "secondary" | undefined
                 * placeholder
                 */}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setShippingData({});
                    setPaymentData({});
                    dispatch(resetListAcademicFormations());
                    dispatch(resetListSubjects());
                    dispatch(resetListLaboralExperiences());
                    setActiveStep(0);
                  }}
                  sx={{ my: 3, ml: 1 }}
                >
                  {t('academic.requisition.edit.wizard.back')}
                </Button>
              </AnimateButton>
            </Stack>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {getStepContent(
              activeStep,
              handleNext,
              handleBack,
              setErrorIndex,
              shippingData,
              setShippingData,
              paymentData,
              setPaymentData
            )}
            {activeStep === steps.length - 1 && (
              <Stack direction="row" justifyContent={'right'}>
                {activeStep !== 0 && (
                  <Button variant="contained" onClick={handleBack} sx={{ my: 3, ml: 1 }}>
                    Regresar
                  </Button>
                )}
                <AnimateButton>
                  <Button
                    variant="contained"
                    disabled={isLoading}
                    endIcon={isLoading && <CircularProgress size={20} />}
                    onClick={async () => {
                      await sendData();
                      // isSuccess && handleNext();
                    }}
                    sx={{ my: 3, ml: 1 }}
                  >
                    {activeStep === steps.length - 1
                      ? t('academic.requisition.edit.wizard.submit')
                      : t('academic.requisition.edit.wizard.next')}
                  </Button>
                </AnimateButton>
              </Stack>
            )}
          </React.Fragment>
        )}
      </Grid>
    </Grid>
  );
};

export default ValidationWizard;
