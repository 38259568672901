import { useState, useEffect } from 'react';
import {
  Button,
  FormHelperText,
  Grid,
  IconButton,
  TableCell,
  TableHead,
  MenuItem,
  useTheme,
  TableBody,
  TextField,
  TableContainer,
  TableRow,
  Tooltip,
  Table,
  InputLabel,
  Typography,
} from '@material-ui/core';
import { IconArrowBarRight, IconPlus, IconTrash } from '@tabler/icons';
import { useAppDispatch, useAppSelector } from '../../../../../../../hooks/useRedux';
import { useGetAllStudentsCourseQuery } from '../../../../../../maintenance/courses/slices/courseApiSlice';
import MainCard from '../../../../../../shared/components/cards/MainCard';
import { renderSkeletonRows } from '../../../../../../shared/helpers/render';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  setStudentToAsignedGroup,
  setTaskChooseQuestion,
  setGroupStudents,
  setGroup,
  setNextStep,
  setTaskToCreate,
  setBackStep,
  removeGroup,
  //setTaskCompleteInfo,
} from '../../../../slices/virtualClassroomTeacherSlice';
import { StudentCourse } from '../../../../../../maintenance/courses/interfaces/course-students.interfaces';
import { Checkbox } from '@material-ui/core';
import ErrorAlert from '../../../../../../shared/components/alerts/ErrorAlert';
import { navigateTop } from '../../../../evaluations/helpers/index';
import { useTranslation } from 'react-i18next';
import EvaluationStudentsList from '../../../../evaluations/components/students/list/EvaluationStudentsList';

interface GroupProps {
  index: number;
  e?: any;
}

const CompleteGroup = () => {
  const dispatch = useAppDispatch();

  const theme = useTheme();

  const [studentsNotAsigned, setStudentsNotAsigned] = useState(false);

  const [selectStd, setSelectStd] = useState<{ selected: boolean; index: number }>({
    selected: false,
    index: 0,
  });

  const { t } = useTranslation();

  const {
    course: { courseId },
  } = useAppSelector((state) => state.virtualClassroomTeacherShared);

  const { studentsToAsignedGroup, taskToCreate } = useAppSelector(
    (state) => state.virtualClassroomTeacher
  );

  /**
   * Obtener los estudiantes de un curso
   */
  const { data: students, isLoading: isLoadingStudents } = useGetAllStudentsCourseQuery(
    {
      courseId: courseId!,
    },
    { skip: courseId === 0 || courseId === undefined }
  );

  const handleSelectStudents = (index: number) => {
    setSelectStd({ selected: true, index: index });
  };

  useEffect(() => {
    if (students) {
      dispatch(setStudentToAsignedGroup(students));
    }
    // eslint-disable-next-line
  }, [students]);

  /**
   * Revisar si un estudiante de a lista ya ha sido asignado
   * a un grupo en común.
   */
  const checkIfStudentIsAssigned = (student: number): boolean => {
    const students = taskToCreate?.course_student?.reduce<number[]>(
      (acc, ele) => acc.concat(ele?.student_id!),
      []
    );
    const isChecked = students?.find((a) => a === student);
    if (isChecked) {
      return true;
    }
    return false;
  };

  /**
   * Asignar un estudiante a un grupo, o posiblemente removerlo de un
   * grupo en el caso de quitar el checkbox.
   */
  const assigStudent = (student: number, checked: boolean) => {
    dispatch(
      setGroupStudents({
        group: selectStd.index,
        student,
        add: checked,
      })
    );
  };

  /* Obtener grupo al que el estudiante se encuentra agregado. En 
     el caso de que no exita en un grupo, por defecto emite cero.
   */
  const checkNameGroup = (id: number): number => {
    const groupCurrent = taskToCreate?.course_student?.find((a) => a.student_id === id)?.grupo!;
    return groupCurrent !== undefined ? groupCurrent + 1 : groupCurrent;
  };

  const RenderStudent = ({ pers_full_lastname, email, id }: StudentCourse) => {
    const studentIsSelected = checkIfStudentIsAssigned(id);

    return (
      <TableRow selected={studentIsSelected} hover className="table-row" key={`${email}-${id}`}>
        <TableCell align="center">
          <Checkbox
            color="primary"
            checked={studentIsSelected}
            onChange={(e) => assigStudent(id, e?.target.checked)}
          />
        </TableCell>
        <TableCell width="70%" sx={{ cursor: 'pointer' }}>
          {pers_full_lastname}
          <FormHelperText>{email}</FormHelperText>
        </TableCell>
        <TableCell width="70%" sx={{ cursor: 'pointer' }}>
          Grupo {checkNameGroup(id)}
        </TableCell>
      </TableRow>
    );
  };

  const RenderGroup = ({ e, index }: GroupProps) => {
    console.log(e);
    return (
      <TableRow hover className="table-row" key={`${index}-group-added`}>
        <TableCell width="50%" align="center">
          {`Grupo ${index + 1}`}
        </TableCell>
        <TableCell align="center">
          <TextField
            fullWidth
            id="standard-select-currency"
            select
            onChange={() => handleSelectStudents(index)}
            variant="outlined"
            label={
              'Integrantes ' +
              taskToCreate?.course_student.filter(
                (e, current) => e.grupo === index && e.student_id !== undefined
              )?.length
            }
          >
            <MenuItem
              value={
                taskToCreate?.course_student.filter((e, current) => current === index)?.length ||
                'no value'
              }
            >
              {`${
                taskToCreate?.course_student.filter(
                  (e, current) => e.grupo === index && e.student_id !== undefined
                )?.length
              } estudiantes`}
            </MenuItem>
          </TextField>
        </TableCell>
        <TableCell width="10%" align="center">
          {console.log(index)}
          <Tooltip title="Remover grupo" onClick={() => handleRemoveGroup(e)}>
            <IconButton aria-label="Eliminar">
              <IconTrash size={20} color={theme.palette.error.main} />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
    );
  };

  /**
   * Completar información del taller.
   * Desactivar las pantallas de completado de selección de
   * preguntas y escoger grupo.
   */
  const handleCompleteInfo = () => {
    dispatch(setBackStep(1));
    /// dispatch(setTaskCompleteInfo(true));
  };

  /**
   * Determinar si todos los estudiantes fueron asignados
   * a un grupo en particular.
   */
  const checkIfAllStudentsAreAsigned = (): Boolean => {
    const totalStudentsAsigned = taskToCreate?.course_student.map((a) => a.student_id)!;
    if (totalStudentsAsigned?.length! === studentsToAsignedGroup?.length!) {
      return true;
    }
    return false;
  };

  /**
   * Seleccionar preguntas y opciones.
   * Desactivar las pantallas de completado de información
   * y seleccionar grupo de estudiantes.
   * Asignar el grupo de estudiantes seleccionado por el docente.
   */
  const handleChooseQuestions = () => {
    const isAsignedAllStudents = checkIfAllStudentsAreAsigned();
    if (taskToCreate?.category_activity_id === 2087) {
      dispatch(setTaskChooseQuestion(true));
      dispatch(setNextStep(1));
      return true;
    }

    if (!isAsignedAllStudents) {
      setStudentsNotAsigned(true);
      navigateTop();
      return false;
    }

    const data = {
      ...taskToCreate!,
      course_student: taskToCreate?.course_student!,
    };
    dispatch(setTaskChooseQuestion(true));
    dispatch(setTaskToCreate(data));

    dispatch(setNextStep(1));
  };

  /**
   * Agregar un grupo.
   * Remover lista de estudiantes.
   */
  const handleAddGroup = () => {
    setSelectStd({ selected: false, index: 0 });

    dispatch(setGroup());
  };

  /**
   * Remover un grupo.
   */
  const handleRemoveGroup = (index: number) => {
    dispatch(removeGroup(index));
  };

  const renderGroups = () => {
    const groupsAsigned = taskToCreate?.course_student.map((e) => e.grupo);
    const groupsSelected = [...new Set(groupsAsigned)];

    console.log(groupsSelected, groupsAsigned);

    return (
      <>
        {groupsSelected?.map((e, index) => (
          <RenderGroup key={`${index}-group-table`} e={e} index={index} />
        ))}
      </>
    );
  };

  const renderStudents = () => {
    const groups = taskToCreate?.course_student?.filter(
      (e, current) => e.grupo !== selectStd.index
    );

    const studentsAsigned = groups?.reduce<number[]>(
      (acc, ele) => acc.concat(ele?.student_id!),
      []
    );

    const studentsAvailables = studentsToAsignedGroup.filter((a) => !(a.id in studentsAsigned!));

    return (
      <>
        {studentsAvailables?.map((e) => (
          <RenderStudent key={`${e.id}`} {...e} />
        ))}
      </>
    );
  };
  useEffect(() => {
    if (taskToCreate?.course_student?.length! < 1) {
      students?.forEach((student) => {
        dispatch(
          setGroupStudents({
            group: 0,
            student: student.id,
            add: true,
          })
        );
      });
    }
  }, [dispatch, students, taskToCreate]);
  const RenderStudentsTable = () => {
    function handleDeleteStudentList(id: number) {
      dispatch(
        setGroupStudents({
          group: 0,
          student: id,
          add: false,
        })
      );
    }

    return (
      <>
        {students && students?.length > 0 ? (
          <>
            {students?.map((e) =>
              taskToCreate?.course_student?.map((item) => {
                return (
                  e.id === item.student_id && (
                    <EvaluationStudentsList
                      student={{
                        student: {
                          student_id: e.id,
                          student_full_name: e.pers_full_lastname,
                          student_email: e.email,
                        },
                      }}
                      key={e?.id!}
                      onClick={() => handleDeleteStudentList(e?.id!)}
                    />
                  )
                );
              })
            )}
          </>
        ) : (
          <>
            <TableRow>
              <TableCell colSpan={3}>
                <Typography variant="body2" color="textSecondary" component="p" textAlign="center">
                  No hay estudiantes en este curso.
                </Typography>
              </TableCell>
            </TableRow>
          </>
        )}
      </>
    );
  };

  return (
    <Grid justifyContent="center">
      <MainCard title={t('teacherVirtualClassroom.tasks.grupo.title')}>
        {taskToCreate?.category_activity_id === 2087 ? (
          <Grid item xs={12} sm={12} container spacing={2}>
            <Grid item xs={12} md={12} sx={{ maxHeight: 600, overflow: 'hidden', marginTop: 3 }}>
              <PerfectScrollbar component="div">
                <TableContainer>
                  <InputLabel>
                    {t('teacherVirtualClassroom.tests.preCreatedStudentsListLabel')}
                  </InputLabel>
                  <Table style={{ marginTop: 10 }}>
                    <TableHead>
                      <TableRow>
                        <TableCell key={'headname'}>
                          {t('teacherVirtualClassroom.tests.testHeaderStudent')}
                        </TableCell>
                        <TableCell align="center" key={'headOptions'}>
                          {t('teacherVirtualClassroom.tests.listHeaderOptions')}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {isLoadingStudents ? renderSkeletonRows(3, 3) : <RenderStudentsTable />}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Grid
                  container
                  item
                  xs={12}
                  sm={12}
                  spacing={2}
                  justifyContent="center"
                  textAlign={'center'}
                  sx={{ mt: 2 }}
                >
                  <Grid item>
                    <Button
                      onClick={handleCompleteInfo}
                      style={{ textTransform: 'unset' }}
                      variant="outlined"
                      size="large"
                      color="primary"
                    >
                      {t('teacherVirtualClassroom.tasks.questions.button.regresar')}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      onClick={handleChooseQuestions}
                      endIcon={<IconArrowBarRight size={20} />}
                      style={{ textTransform: 'unset' }}
                      variant="contained"
                      size="large"
                      color="primary"
                    >
                      {t('teacherVirtualClassroom.tasks.compleinfo.button.title')}
                    </Button>
                  </Grid>
                </Grid>
              </PerfectScrollbar>
            </Grid>
          </Grid>
        ) : (
          <>
            <Grid justifyContent={'center'} container item xs={12} sm={12} spacing={3}>
              <Grid item xs={6} sx={{ mb: 2 }}>
                {studentsNotAsigned && (
                  <ErrorAlert
                    message={'Grupos asignados están incompletos.'}
                    handleDismiss={() => setStudentsNotAsigned(false)}
                  />
                )}
              </Grid>
            </Grid>
            <Grid container item xs={12} sm={12} spacing={3}>
              <Grid item xs={6}>
                <Button
                  onClick={handleAddGroup}
                  startIcon={<IconPlus fontSize="0.875rem" />}
                  style={{ textTransform: 'unset' }}
                  variant="text"
                  size="small"
                  color="primary"
                >
                  {t('teacherVirtualClassroom.tasks.grupo.button.title')}
                </Button>
                <Grid item xs={12}>
                  <PerfectScrollbar component="div">
                    <TableContainer>
                      <Table style={{ marginTop: 10 }}>
                        <TableHead>
                          <TableRow>
                            <TableCell align="center">
                              {t('teacherVirtualClassroom.tasks.grupo.table.grupo')}
                            </TableCell>
                            <TableCell align="center">
                              {t('teacherVirtualClassroom.tasks.grupo.table.estudiantes')}
                            </TableCell>
                            <TableCell align="center">
                              {t('teacherVirtualClassroom.tasks.grupo.table.options')}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>{renderGroups()}</TableBody>
                      </Table>
                    </TableContainer>
                  </PerfectScrollbar>
                </Grid>
              </Grid>
              {selectStd.selected && (
                <Grid item xs={6}>
                  <PerfectScrollbar component="div">
                    <TableContainer>
                      <Table sx={{ mt: 5 }}>
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              {t('teacherVirtualClassroom.tasks.grupo.table.seleccionar')}
                            </TableCell>
                            <TableCell>
                              {t('teacherVirtualClassroom.tasks.grupo.table.estudiante')}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {isLoadingStudents ? renderSkeletonRows(3, 3) : renderStudents()}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </PerfectScrollbar>
                </Grid>
              )}
            </Grid>
            <Grid
              container
              item
              xs={12}
              sm={12}
              spacing={2}
              justifyContent="center"
              textAlign={'center'}
              sx={{ mt: 2 }}
            >
              <Grid item>
                <Button
                  onClick={handleCompleteInfo}
                  style={{ textTransform: 'unset' }}
                  variant="outlined"
                  size="large"
                  color="primary"
                >
                  {t('teacherVirtualClassroom.tasks.questions.button.regresar')}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={handleChooseQuestions}
                  endIcon={<IconArrowBarRight size={20} />}
                  style={{ textTransform: 'unset' }}
                  variant="contained"
                  size="large"
                  color="primary"
                >
                  {t('teacherVirtualClassroom.tasks.compleinfo.button.title')}
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </MainCard>
    </Grid>
  );
};

export default CompleteGroup;
