import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISearchTable } from '../../../shared/interfaces/paginatedTable.interface';
import { bookFormBody } from '../interfaces/book.form.interface';
import { BookItem } from '../interfaces/books.response.interface';

interface BooksState {
  bookToEdit?: BookItem;
  deleteModalOpen: boolean;
  bookToDelete?: number;
  bookBodyForm?: Partial<bookFormBody>;

  booksTable: ISearchTable;
  resourcesTable: ISearchTable;
  thesisTable: ISearchTable;
}

const initialState: BooksState = {
  deleteModalOpen: false,

  booksTable: {
    page: 1,
    perPage: 5,
    search: '',
  },
  resourcesTable: {
    page: 1,
    perPage: 5,
    search: '',
  },
  thesisTable: {
    page: 1,
    perPage: 5,
    search: '',
  },
};

const booksState = createSlice({
  name: 'books',
  initialState,
  reducers: {
    setBookToEdit: (state, action: PayloadAction<BookItem | undefined>) => {
      state.bookToEdit = action.payload;
    },
    setDeleteModalOpen: (state, action: PayloadAction<boolean>) => {
      state.deleteModalOpen = action.payload;
    },
    openDeleteModalWith: (state, action: PayloadAction<number>) => {
      state.bookToDelete = action.payload;
      state.deleteModalOpen = true;
    },
    setBodyForm: (state, action: PayloadAction<Partial<bookFormBody>>) => {
      let temp = state.bookBodyForm || {};
      let obj = Object.assign(temp, action.payload);
      state.bookBodyForm = obj;
    },
    resetBodyForm: (state) => {
      state.bookBodyForm = undefined;
    },
    resetBookToDelete: (state) => {
      state.bookToDelete = undefined;
    },
  },
});

export const {
  setDeleteModalOpen,
  openDeleteModalWith,
  resetBookToDelete,
  setBookToEdit,
  setBodyForm,
  resetBodyForm,
} = booksState.actions;

export default booksState.reducer;
