import React from 'react';

import {
  DialogActions,
  DialogContent,
  Slide,
  SlideProps,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// import PerfectScrollbar from 'react-perfect-scrollbar';

import ExtendedSideDialog from '../../../shared/components/dialogs/ExtendedSideDialog';

// import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  userAddDialog: {
    '&>div:nth-child(3)': {
      justifyContent: 'flex-end',
      '&>div': {
        margin: '0px',
        borderRadius: '0px',
        maxWidth: '450px',
        maxHeight: '100%',
      },
    },
  },
  paper: {
    minHeight: '100vh',
    width: '100%',
  },
  learningComponents: {
    fontSize: '16px',
    fontWeight: 600,
    color: theme.palette.primary.main,
    marginBottom: '8px',
  },
  selectSubject: {
    fontSize: '16px',
    fontWeight: 600,
    color: theme.palette.primary[800],
    marginBottom: '8px',
  },
  selectSubjectType: {
    fontSize: '14px',
    fontWeight: 600,
    color: theme.palette.secondary.main,
    marginBottom: '8px',
  },
}));

const Transition = React.forwardRef(function Transition(props: SlideProps, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

interface Props {
  open: boolean;
  handleCloseDialog: () => void;
  children?: React.ReactElement | React.ReactElement[];
  action?: React.ReactElement | React.ReactElement[];
  title?: string;
}

const ResearchLinesDialog = ({ open, handleCloseDialog, children, title, action }: Props) => {
  // const { t } = useTranslation();

  const classes = useStyles();

  //              format

  return (
    <ExtendedSideDialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => {
        handleCloseDialog();
      }}
      className={classes.userAddDialog}
      classes={{
        paper: classes.paper,
      }}
    >
      {/* <PerfectScrollbar> */}
      <DialogTitle>
        <Typography variant="h3" color="initial">
          {title}
        </Typography>
      </DialogTitle>
      {/* <PerfectScrollbar options=> */}
      <DialogContent
        sx={{
          width: '100%',
          '& > div > div > div': {
            width: '100%',
          },
        }}
      >
        {children}
      </DialogContent>
      {/* </PerfectScrollbar> */}
      <DialogActions>{action}</DialogActions>
      {/* </PerfectScrollbar> */}
    </ExtendedSideDialog>
  );
};

export default ResearchLinesDialog;
