import projectPermissions from './projectPermissions';

export const sharedPermissions = {
  //!grupo: applications
  uploadFile: projectPermissions.applications.uploadFile,
  //!grupo: persons
  findPerson: projectPermissions.persons.findPerson,
  updatePerson: projectPermissions.persons.updatePerson,
  createPerson: projectPermissions.persons.createPerson,
  // !grupo: catalog
  getChildrenCatalogByAcro: projectPermissions.catalog.getChildrenCatalogByAcro,
};
