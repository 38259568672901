import DeleteAlert from '../../../shared/components/dialogs/DeleteAlert';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { useDeleteLearningComponentMutation } from '../slices/learningComponentsApiSlice';
import {
  resetLearningComponentIdToDelete,
  setDeleteModalOpen,
} from '../slices/learningComponentsSlice';

const DeleteLearningComponentsDialog = () => {
  const dispatch = useAppDispatch();

  const { deleteModalOpen, learningComponentIdToDelete } = useAppSelector(
    (state) => state.learningComponents
  );

  const [deleteLearningComponents, { isLoading, isSuccess, isError, error }] =
    useDeleteLearningComponentMutation();

  const handleDelete = () => {
    deleteLearningComponents({
      learningComponentId: learningComponentIdToDelete!,
    });
  };

  const handleClose = () => {
    dispatch(setDeleteModalOpen(false));
    dispatch(resetLearningComponentIdToDelete());
  };

  return (
    <DeleteAlert
      open={deleteModalOpen}
      onClose={handleClose}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      handleDelete={handleDelete}
      error={error as string}
    />
  );
};

export default DeleteLearningComponentsDialog;
