import {
  Grid,
  RadioGroup,
  Radio,
  Typography,
  FormControl,
  FormControlLabel,
} from '@material-ui/core';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import MainCard from '../../../shared/components/cards/MainCard';

import { useTranslation } from 'react-i18next';
import StudentsGraduateTable from '../components/StudentsGraduateTable';
import GraduateSideDialog from '../components/GraduateSideDialog';
import GraduatedStudentsTable from '../components/GraduatedStudentsTable';
import { setTableTypeSelected } from '../slices/studentsGraduateSlice';

const StudentsGraduateScreen = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  //   const { studentIdToDelete, studentIdToAddAvatar } = useAppSelector((state) => state.students);
  const { studentGraduateSideDialogOpen, tableTypeSelected } = useAppSelector(
    (state) => state.studentsGraduate
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      setTableTypeSelected((event.target as HTMLInputElement).value as 'to_graduate' | 'graduated')
    );
  };

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">{t('academic.students_graduate.title')}</Typography>
      </Grid>
      <Grid sx={{ textAlign: 'right' }}>
        <FormControl component="fieldset">
          {/* <FormLabel component="legend">Homlogación</FormLabel> */}
          <RadioGroup
            row
            aria-label="homologaciones"
            name="controlled-radio-buttons-group"
            value={tableTypeSelected}
            onChange={handleChange}
          >
            <FormControlLabel
              value="to_graduate"
              control={<Radio />}
              label={t('academic.students_graduate.to_graduate')}
            />
            <FormControlLabel
              value="graduated"
              control={<Radio />}
              label={t('academic.students_graduate.graduated')}
            />
          </RadioGroup>
        </FormControl>
      </Grid>

      {tableTypeSelected === 'to_graduate' && <StudentsGraduateTable />}
      {tableTypeSelected === 'graduated' && <GraduatedStudentsTable />}
      {/* <StudentsTable /> */}

      {studentGraduateSideDialogOpen && <GraduateSideDialog />}
    </Grid>
  );
};

export default StudentsGraduateScreen;
