import { Grid, useTheme } from '@material-ui/core';
import MainCard from '../../../../shared/components/cards/MainCard';
import VCStudentHeader from '../components/VCStudentHeader';

const VCStudentHeaderScreen = () => {
  const theme = useTheme();

  return (
    <Grid
      component={MainCard}
      style={{ padding: 2 }}
      sx={{
        bgcolor: theme.palette.secondary.light,
        borderBottomLeftRadius: '0px',
        borderBottomRightRadius: '0px',
      }}
    >
      <VCStudentHeader />
    </Grid>
  );
};

export default VCStudentHeaderScreen;
