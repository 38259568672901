import { Switch } from 'react-router-dom';
import GuardRoute from '../../../../router/guards/GuardRoute';
import MyCurriulumScreen from '../screens/MyCurriulumScreen';

const StudentCurriculumRouter = () => {
  return (
    <Switch>
      <GuardRoute exact path="/malla-curricular" component={MyCurriulumScreen} />
    </Switch>
  );
};

export default StudentCurriculumRouter;
