import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { WeightingReportTable } from '../../interfaces/integralsReports.interface';

interface Props {
  data: WeightingReportTable[];
}

const ActivitiesTotalReport = ({ data }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();

  let aux = 0;
  data.forEach((element) => {
    aux += Number(element.integral_evaluation_cab.integral_evaluation_cab_category.total);
  });

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} textAlign="center">
        <Typography variant="h3" color="primary">
          {t('reports.integrals.weighting_total.activities.title')}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <TableContainer>
          <Table id="table-report-total">
            <TableHead>
              {/* <TableRow>
                <TableCell
                  colSpan={2}
                  align="center"
                  sx={{
                    borderTopLeftRadius: 22,
                    borderTopRightRadius: 22,
                    backgroundColor: 'secondary.main',
                  }}
                >
                  <Typography variant="h4">
                    {t('reports.integrals.weighting.activities.table.title')}
                  </Typography>
                </TableCell>
              </TableRow> */}

              <TableRow>
                <TableCell
                  sx={{
                    borderTopLeftRadius: 22,
                    backgroundColor: `${theme.palette.secondary.main}99`,
                  }}
                >
                  {t('reports.integrals.weighting.activities.table.activities')}
                </TableCell>

                <TableCell
                  sx={{
                    borderTopRightRadius: 22,
                    backgroundColor: `${theme.palette.secondary.main}99`,
                  }}
                >
                  {t('reports.integrals.weighting.activities.table.weighting')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((category) => (
                <TableRow>
                  <TableCell>{category.description}</TableCell>

                  <TableCell>
                    {parseFloat(
                      category.integral_evaluation_cab.integral_evaluation_cab_category.total,
                    ).toFixed(2)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow
                sx={{
                  borderEndEndRadius: 22,
                  // borderBottomRightRadius: 22,
                  backgroundColor: `${theme.palette.secondary.main}99`,
                }}
              >
                <TableCell>
                  <Typography variant="h4">
                    {t('reports.integrals.weighting_total.activities.table.title')}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h4">{(aux / data.length || 1).toFixed(2)}</Typography>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default ActivitiesTotalReport;
