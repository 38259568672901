import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  useTheme,
} from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import { setFaqsExpandedId } from '../../slices/libraryDashboardSlice';

export interface IFaqItem {
  question: string;
  answer: string;
}

export interface FaqItemProps {
  faq: IFaqItem;
  index: number;
}

const FaqItem = ({ faq: { question, answer }, index }: FaqItemProps) => {
  const theme = useTheme();

  const dispatch = useAppDispatch();
  const { faqsExpandedId } = useAppSelector((state) => state.libraryDashboard);

  const handleExpand = () => {
    if (faqsExpandedId === index) {
      dispatch(setFaqsExpandedId(null));
      return;
    }
    dispatch(setFaqsExpandedId(index));
  };

  return (
    <Accordion onChange={handleExpand} expanded={faqsExpandedId === index}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        sx={{
          backgroundColor: theme.palette.grey[100],
          color: theme.palette.primary.main,
          borderRadius: 4,
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontWeight: 400,
          }}
          color="primary"
        >
          {question}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div dangerouslySetInnerHTML={{ __html: answer }} />
      </AccordionDetails>
    </Accordion>
  );
};

export default FaqItem;
