import { addWeeks, isValid, parseISO, isBefore, format } from 'date-fns';

import { DateRange } from '../interfaces/material-ui.interfaces';

export const getWeeksAfter = (date: Date | null, amount: number) => {
  return date ? addWeeks(date, amount) : undefined;
};

/**
 * return date or null is given param is a valid date
 *
 * @param date ```Date```
 * @returns ```Date | null```
 */
export const returnValidDate = (date: Date | string | null | undefined) => {
  let newDate = date;

  if (typeof newDate === 'string') {
    newDate = parseISO(newDate);
  }

  return isValid(newDate) ? newDate : null;
};

/**
 * Return dates conditionality with 2 dates (start and end) works with both properties at the same time when daterangepicker
 *
 *
 * ```
 * onSubmit(){
 *  startDate: getDatesInRange({startDate,endDate}, 'start')
 *  endDate: getDatesInRange({startDate,endDate}, 'end')
 * }
 * indeed
 *
 * onSubmit(){
 *  startDate: null,
 *  endDate: getDatesInRange({startDate,endDate}, 'end')
 * }
 * dont know xd
 * ```
 *
 * @param date
 * @param position
 * @param isFormated  **If true, the returned value will be string**
 * @returns
 */
export const getDatesInRange = (
  date: Omit<DateRange, 'start' | 'end'> & {
    start: Date | string | null;
    end: Date | string | null;
  },
  position: 'start' | 'end',
  isFormated?: boolean
): Date | undefined => {
  let startDate = returnValidDate(date.start);
  let endDate = returnValidDate(date.end);

  let returnedDate = undefined;

  if (startDate && endDate) returnedDate = position === 'start' ? startDate : endDate;
  if (!startDate && !endDate) returnedDate = undefined;

  if (position === 'start') {
    if (!endDate && startDate) returnedDate = isValid(startDate) ? startDate : undefined;

    if (endDate && isValid(endDate) && !startDate)
      returnedDate = isBefore(new Date(), endDate) ? new Date() : endDate;
  }

  if (position === 'end') {
    if (!endDate && startDate && isValid(startDate))
      returnedDate = isBefore(startDate, new Date()) ? new Date() : startDate;

    if (endDate && isValid(endDate) && !startDate) returnedDate = endDate;
  }

  if (isFormated && returnedDate) {
    return format(returnedDate, 'yyyy-MM-dd') as any;
  }

  return returnedDate;
};

// interface RangeDates {
//   start: Date;
//   end: Date;
// }

/* Validar que las fechas seleccionadas sean correctas.
 * 1) Fecha inicio
 * Fecha inicio no puede superar fecha inicio del periodo.
 * Fecha inicio no puede ser menor fecha inicio del periodo.
 * 2) Fecha fin
 * Fecha fin no puede superar fecha de fin del periodo.
 *
 */
// export const checkPeriodDatesRange = (dates: RangeDates[]) => {
//   const errors = {};

//   const startPeriod = parseISO(periodInfo?.start_process!);
//   const endPeriod = parseISO(periodInfo?.end_process!);

//   for (let i = 0; i < periodStages.length; i++) {
//     const start = periodStages[i]?.startDate!;
//     const end = periodStages[i]?.endDate!;
//     if (start > endPeriod) {
//       setDateInvalid({
//         show: true,
//         index: i,
//         message: `${t('academic.periods_list.stages.add.date.start.max')} ${
//           periodInfo?.end_process
//         }`,
//       });
//       return false;
//     }
//     if (start < startPeriod) {
//       setDateInvalid({
//         show: true,
//         index: i,
//         message: `${t('academic.periods_list.stages.add.date.start.min')} ${
//           periodInfo?.start_process
//         }`,
//       });
//       return false;
//     }
//     if (end > endPeriod) {
//       setDateInvalid({
//         show: true,
//         index: i,
//         message: `${t('academic.periods_list.stages.add.date.end.max')} ${periodInfo?.end_process}`,
//       });
//       return false;
//     }
//   }

//   return errors;
// };
