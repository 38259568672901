import {  Switch } from 'react-router-dom';
import GuardRoute from '../../../../router/guards/GuardRoute';
import ReceiptScreen from '../screens/ReceiptScreen';
//import { permissionKey, subPermissionKeys } from '../constants/permissions';

const ReceiptsRouter = () => {

  return (
    <Switch>
      <GuardRoute
        exact
        path="/administrar-recibos"
        component={ReceiptScreen}
        /*subPermissionKey={subPermissionKeys.read}
        permissionKey={permissionKey}*/
      />
    </Switch>
  );
};

export default ReceiptsRouter;