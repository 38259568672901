import { Grid, Typography } from '@material-ui/core';
import MainCard from '../../../shared/components/cards/MainCard';
import EventList from '../components/EventList';
import { useTranslation } from 'react-i18next';

const EventAdminListScreen = function () {
  const { t } = useTranslation();
  
  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">{t('shared.events.title')}</Typography>
      </Grid>
      <EventList />
    </Grid>
  );
};

export default EventAdminListScreen;
