import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISearchTable } from '../../../shared/interfaces/paginatedTable.interface';
import { Certificate } from '../interfaces/certificate.interfaces';
interface CertificateBody {
  id: number;
  pdfUrl: string;
  appAcronym: string;
}
interface CertificateState {
  certificateList: Certificate[];
  debitModalOpen: boolean;
  certificateToDebit?: CertificateBody;

  paidCertificateTable: ISearchTable;
}

const initialState: CertificateState = {
  certificateList: [],
  debitModalOpen: false,

  paidCertificateTable: {
    page: 1,
    perPage: 5,
    search: '',
  },
};

const certificateSlice = createSlice({
  name: 'certificate',
  initialState,
  reducers: {
    setCertificateList: (state, action: PayloadAction<Certificate[]>) => {
      state.certificateList = action.payload;
    },
    setDebitModalOpen: (state, action: PayloadAction<boolean>) => {
      state.debitModalOpen = action.payload;
    },
    openDebitModalWithObject: (state, action: PayloadAction<CertificateBody>) => {
      state.certificateToDebit = action.payload;
      state.debitModalOpen = true;
    },
    resetCertificateToDebit: (state) => {
      state.certificateToDebit = undefined;
    },

    updatepaidCertificateTableStates: (state, action: PayloadAction<ISearchTable>) => {
      state.paidCertificateTable = action.payload;
    },
  },
});
export const {
  setCertificateList,
  setDebitModalOpen,
  openDebitModalWithObject,
  resetCertificateToDebit,

  updatepaidCertificateTableStates,
} = certificateSlice.actions;
export default certificateSlice.reducer;
