import { Grid, Typography } from '@material-ui/core';
import { useAppSelector } from '../../../../hooks/useRedux';
import MainCard from '../../../shared/components/cards/MainCard';
import DeletePermissionDialog from '../components/DeletePermissionDialog';
import PermissionsTable from '../components/PermissionsTable';
import { useTranslation } from 'react-i18next';

const PermissionsScreen = () => {
  const { t } = useTranslation();
  const { permissionIdToDelete } = useAppSelector((state) => state.permissions);

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">{t('configuration.permissions.title')}</Typography>
      </Grid>

      <PermissionsTable />

      {permissionIdToDelete && <DeletePermissionDialog />}
    </Grid>
  );
};

export default PermissionsScreen;
