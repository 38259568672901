import { useState, useEffect } from 'react';

interface Props {
  ref: React.MutableRefObject<HTMLDivElement | null>;
}

const useSizeObserver = ({ ref }: Props) => {
  const [size, setSize] = useState({ width: 0, height: 0 });

  console.log('useSizeObserver', ref);

  useEffect(() => {
    const element = ref.current;
    const observer = new ResizeObserver((entries) => {
      const { width, height } = entries[0].contentRect;
      setSize({ width, height });
    });

    if (element) observer.observe(element);

    return () => observer.disconnect();
  }, [ref]);

  return size;
};

export default useSizeObserver;
