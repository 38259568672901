import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/useRedux';
import usePaginationAndSearch from '../../../../../maintenance/follow-up-of-research-project/hooks/usePaginationAndSearch';
import SearchPaginatedTableAndCollapsibleItems from '../../../../../shared/components/tables/SearchPaginatedTableAndCollapsibleItems';
import {
  useGetAllQuestionsQuery,
  useGetQuestionsQuery,
} from '../../question/slices/questionApiSlice';
import { useGetAllScopesQuery } from '../../scope/slices/scopeApiSlice';
import { setQuestionsSelected } from '../slices/evaTemplateSlice';

const QuestionTableForm = () => {
  const { t } = useTranslation();

  const [checkAll, setCheckALl] = useState(false);
  const [update, setUpdate] = useState(false);

  const dispatch = useAppDispatch();

  const { questionsSelected } = useAppSelector((state) => state.templateEva);

  const [scopeIdSelected, setScopeIdSelected] = useState<string | null>(null);

  const { page, perPage, search, setPage, setPerPage, setSearch } = usePaginationAndSearch(1, 10);

  const { data: scopes, isLoading: isLoadingS } = useGetAllScopesQuery({});

  const { data: questionsListAll } = useGetAllQuestionsQuery({
    ...(scopeIdSelected && { category_uuid: scopeIdSelected }),
  });

  const {
    data: questionsList,
    isLoading,
    isFetching,
    error,
  } = useGetQuestionsQuery({
    page,
    perPage,
    search,
    sort: 'name',
    typeSort: 'asc',
    ...(scopeIdSelected && { scopeId: scopeIdSelected }),
  });

  const datafetch =
    questionsList && questionsList.data && Array.isArray(questionsList.data)
      ? questionsList.data.map((item, idx) => ({
          numberItem: (page - 1) * perPage + (idx + 1),
          chck: item,
          section: item.ambitos?.name || '',
          name: item,
        }))
      : [];

  useEffect(() => {
    if (
      Array.isArray(questionsListAll) &&
      questionsListAll?.every((item) => item.category_uuid === scopeIdSelected)
    ) {
      setCheckALl(
        questionsSelected.filter((item) =>
          questionsListAll!.find((item2) => item2.uuid === item.uuid)
        ).length === questionsListAll.length
      );
    } else {
      setCheckALl(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scopeIdSelected, questionsListAll]);

  useEffect(() => {
    setUpdate(true);
    setTimeout(() => {
      setUpdate(false);
    }, 50);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionsSelected]);

  return (
    <SearchPaginatedTableAndCollapsibleItems
      data={datafetch!}
      headers={{
        numberItem: t('N°'),
        chck: (
          <FormControlLabel
            sx={{
              '& .MuiTypography-body1': {
                fontSize: '0.8rem',
              },
              mx: 0,
            }}
            control={
              <Checkbox
                checked={checkAll}
                onChange={(e) => {
                  setCheckALl(e.target.checked);
                  if (e.target.checked) {
                    const newQuestionsSelected = questionsSelected.filter(
                      (item) =>
                        !Boolean(
                          questionsListAll!.find((item2) => item2.uuid === item.uuid.toString())
                        )
                    );
                    dispatch(setQuestionsSelected([...newQuestionsSelected, ...questionsListAll!]));
                  } else {
                    const newQuestionsSelected = questionsSelected.filter(
                      (item) =>
                        !Boolean(
                          questionsListAll!.find((item2) => item2.uuid === item.uuid.toString())
                        )
                    );
                    dispatch(setQuestionsSelected(newQuestionsSelected));
                  }
                }}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label="Todos"
          />
        ) as any,
        section: t('maintenance.questions.scope'),
        name: t('maintenance.questions.name'),
      }}
      keyExtractor={(item) => item.name.uuid!.toString()}
      searchPlacehoder={t('maintenance.questions.search')}
      isLoading={isLoading}
      isFetching={isFetching}
      error={error as any}
      perPage={perPage}
      setPerPage={setPerPage}
      total={questionsList?.total || 0}
      page={page}
      setPage={setPage}
      numHeader={6}
      search={search}
      setSearch={setSearch}
      customHeadersCellsProperties={{
        name: {
          align: 'center',
        },
        chck: {
          align: 'center',
        },
        numberItem: {
          align: 'center',
        },
      }}
      customDataCellsProperties={{
        name: {
          align: 'center',
        },
        chck: {
          align: 'center',
        },
        numberItem: {
          align: 'center',
        },
      }}
      customRenderers={{
        name: (item) => (
          <Typography variant="body2" align="justify">
            {item.name.name}
          </Typography>
        ),
        chck: (question ) => (
          <>
            {update ? null : (
              <Checkbox
                checked={questionsSelected.find((item) => item.uuid === question.chck.uuid)}
                onChange={() => {
                  const isSelected = questionsSelected.find(
                    (item) => item.uuid === question.chck.uuid
                  );
                  if (isSelected) {
                    const newQuestionsSelected = questionsSelected.filter(
                      (item) => item.uuid !== question.chck.uuid
                    );
                    setCheckALl(false);
                    dispatch(setQuestionsSelected(newQuestionsSelected));
                  } else {
                    dispatch(setQuestionsSelected([...questionsSelected, question.chck]));
                  }
                }}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            )}
          </>
        ),
      }}
      filtersPos="top"
      additionalFilters={
        <>
          <Grid container spacing={1}>
            <Grid item xs={12} md={4}>
              <Autocomplete
                options={scopes || []}
                getOptionLabel={(option) => option.name || ''}
                loading={isLoadingS}
                onChange={async (_, value) => {
                  setScopeIdSelected(value?.uuid || '');
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('maintenance.questions.scope')}
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </Grid>
          </Grid>
        </>
      }
    />
  );
};

export default QuestionTableForm;
