import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
// import { useAppSelector } from '../../../../hooks/useRedux';
import MainCard from '../../../shared/components/cards/MainCard';
import ChangePaymentMethodDialog from '../components/ChangePaymentMethodDialog';
import ChangeStatusDialog from '../components/ChangeStatusDialog';

// import DeletePeriodDialog from '../components/DeletePeriodDialog';
import DepositsTransfersTable from '../components/DepositsTransfersTable';
import EditDepositTransferDialog from '../components/EditDepositTransferDialog';
import ObservationDialog from '../components/ObservationDialog';
import {
  setDepositTransferId,
  setEditDepositTransferModalOpen,
} from '../slices/depositsTransfersSlice';

const PeriodsScreen = () => {
  const { t } = useTranslation();

  const {
    depositTransferId,
    changeStatusModalOpen,
    editDepositTransferModalOpen,
    viewDepositTransferModalOpen,
    changePaymentMethodModalOpen,
    observationDialogOpen,
  } = useAppSelector((state) => state.depositsTransfers);
  const dispatch = useAppDispatch();

  const handleCloseEditDialog = () => {
    if (!viewDepositTransferModalOpen) {
      dispatch(setDepositTransferId(null!));
    }
    dispatch(setEditDepositTransferModalOpen(false));
  };

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">{t('financial.deposit_transfers.title')}</Typography>
      </Grid>

      <DepositsTransfersTable />

      {depositTransferId && changeStatusModalOpen && <ChangeStatusDialog />}
      {depositTransferId && editDepositTransferModalOpen && (
        <EditDepositTransferDialog
          open={editDepositTransferModalOpen}
          handleCloseDialog={handleCloseEditDialog}
        />
      )}
      {depositTransferId && changePaymentMethodModalOpen && <ChangePaymentMethodDialog />}
      {depositTransferId && observationDialogOpen && <ObservationDialog open />}
    </Grid>
  );
};

export default PeriodsScreen;
