import * as Yup from 'yup';

export const FaqCreateInfoSchema = Yup.object().shape({
  faq_question: Yup.string().required('configuration.faqs.stepper.step2.question.required'),
  faq_answer: Yup.string().required('configuration.faqs.stepper.step2.description.required'),
  // faq_link_video: Yup.string().required('Dirección del video requerida.'),
  faq_tags: Yup.array().min(1, 'configuration.faqs.stepper.step2.tags.min'),
});

export const FaqStepperModule = Yup.object().shape({
  faq_keyword: Yup.string().required('configuration.faqs.stepper.step1.actions.required'),
});
