import { useMemo } from 'react';
import Grid from '@material-ui/core/Grid';

import { useAppSelector } from '../../../../../../hooks/useRedux';
import CollaboratorResearchDelete from './CollaboratorResearchDelete';
import Typography from '@material-ui/core/Typography';

interface Props {
  groupResearchId: string;
}

const ListCollaboratorsDelete = ({ groupResearchId }: Props) => {
  const { groupResearchList } = useAppSelector((state) => state.investigationLines);

  const collaborators = useMemo(
    () => groupResearchList.find(({ id }) => id === groupResearchId)?.collaborator_group_research,

    [groupResearchList, groupResearchId]
  );

  return (
    <Grid container>
      {!collaborators?.length && (
        <Typography variant="subtitle1" color="initial">
          No hay docentes disponibles
        </Typography>
      )}
      {collaborators?.map((data) => (
        <CollaboratorResearchDelete collaboratorGR={data} key={data.id} />
      ))}
    </Grid>
  );
};

export default ListCollaboratorsDelete;
