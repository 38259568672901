import { useParams } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';
import PeriodBadge from './PeriodBadge';
import { Button, Chip, Grid } from '@material-ui/core';
import SubjectCard from '../cards/SubjectCard';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import { fireAssignSubjectCurriculumDialog, setCurrentGroup } from '../../slices/meshSlice';
import { MatterMesh } from '../../interfaces/curriculum.interfaces';
import { useGetMeshQuery } from '../../slices/meshsApiSlice';
import useSharedGuard from '../../../../../hooks/useSharedGuard';

import { subPermissionKeys } from '../../constants/permissions';

const useStyles = makeStyles({
  root: {
    backgroundColor: '#fff',
    boxShadow: '0px 0px 20px #EAF0F7',
    borderRadius: '5px',
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  headerInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerActions: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  badges: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '0.5rem',
  },
  subjects: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
});

interface Props {
  group: number;
  periodTitle: string;
  subjects: MatterMesh[];
  isOlder?: boolean;
}

const PeriodLane = ({ group, periodTitle, subjects, isOlder }: Props) => {
  const classes = useStyles();

  const dispatch = useAppDispatch();

  const { meshId: meshIdParam } = useParams<{ meshId: string }>();
  const meshId = Number(meshIdParam);

  const { currentProfile } = useAppSelector((state) => state.access);

  const profileId = currentProfile!.profileId;

  const { data } = useGetMeshQuery({ profileId, meshId });

  const learningComponents = subjects.reduce<{ [key: string]: number }>((acc, current) => {
    current.detail_matter_mesh.forEach((dmm) => {
      if (acc[dmm.component.com_acronym]) {
        acc[dmm.component.com_acronym] += Number(dmm.dem_workload);
      } else {
        acc[dmm.component.com_acronym] = Number(dmm.dem_workload);
      }
    });
    return acc;
  }, {});

  const { hasAccess } = useSharedGuard(subPermissionKeys);

  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        <Box className={classes.headerInfo}>
          <Typography variant="subtitle1">{periodTitle}</Typography>
          <Box sx={{ mt: 1 }} className={classes.badges}>
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <Typography color="black">N° de materias: </Typography>
              </Grid>
              <Grid item>
                <Chip variant="filled" color="primary" size="small" label={subjects.length} />
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box className={classes.headerInfo}>
          <Box sx={{ mt: 1 }} className={classes.badges}>
            {Object.keys(learningComponents).map((lcKey) => (
              <PeriodBadge key={lcKey} label={lcKey} number={learningComponents[lcKey]} />
            ))}
            <PeriodBadge
              label="Total"
              number={Object.values(learningComponents).reduce((acc, current) => acc + current, 0)}
            />
          </Box>
        </Box>
        <Box className={classes.headerActions}>
          {/* Fix ID 7 === Vigente :( */}
          {!isOlder && data?.status.id !== 7 && hasAccess('assign') && (
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              onClick={() => {
                dispatch(setCurrentGroup(group));
                dispatch(fireAssignSubjectCurriculumDialog(true));
              }}
            >
              Agregar Materia
            </Button>
          )}
        </Box>
      </Box>
      <Box className={classes.subjects}>
        {subjects.map((s, index) => (
          <SubjectCard key={s.id} subject={s} isOlder={isOlder} />
        ))}
      </Box>
    </Box>
  );
};

export default PeriodLane;
