import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import SearchPaginatedTable from '../../../../shared/components/tables/SearchPaginatedTable';
import HomologationInAddDialog from './HomologationInAddDialog';
import HomologationIntEditDialog from './HomologationIntEditDialog';
// import EditButton from '../../../../shared/components/tables/buttons/EditButton';
import DeleteButton from '../../../../shared/components/tables/buttons/DeleteButton';
import AddButton from '../../../../shared/components/tables/buttons/AddButton';
import EditButton from '../../../../shared/components/tables/buttons/EditButton';
// import MeshStatusChip from '../../../../shared/components/badges/MeshStatusChip';
import { HomologateInternalSubjects } from '../../interfaces/homologateInternalSubjects.interfaces';

import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import {
  useGetInternalHomologateSubjectsQuery,
  useGetInternalHomologationSubjectsbyMeshQuery,
} from '../../slices/meshsApiSlice';
import {
  fireDeleteSubjectInternalHomologateDialog,
  setSubjectInternalHomologateSelected,
} from '../../slices/meshSlice';

import { LinearProgress, Box, TextField, Grid, Autocomplete } from '@material-ui/core';

import useSharedGuard from '../../../../../hooks/useSharedGuard';
import { subPermissionKeys } from '../../constants/permissions';
import { useGetEducationLevelsParentsAndChildrenQuery } from '../../../education-levels/slices/educationLevelsApiSlice';
import TextFieldFetching from '../../../../shared/components/TextFieldFetching';

const HomologateSubjectInternalTable = () => {
  const { currentProfile } = useAppSelector((state) => state.access);

  const dispatch = useAppDispatch();

  const { meshId } = useParams<{ meshId: string }>();

  const [isOpenAddDialog, setIsOpenAddDialog] = useState<boolean>(false);
  const [isOpenEditDialog, setIsOpenEditDialog] = useState<boolean>(false);

  const [eduLevelParentIdSelected, setEduLevelParentIdSelected] = useState<number | null>(null);
  const [careerIdSelected, setCareerIdSelected] = useState<number | null>(null);
  const [meshIdSelected, setMeshIdSelected] = useState<number | null>(null);
  const [subjectIdSelected, setSubjectIdSelected] = useState<number | null>(null);

  const [dataSubject, setDataSubject] = useState<HomologateInternalSubjects>();

  const handlerAddOpenDialog = (status: boolean) => {
    setIsOpenAddDialog(status);
  };

  const handlerEditOpenDialog = (status: boolean) => {
    setIsOpenEditDialog(status);
  };

  const handlerCloseDialog = () => {
    setIsOpenAddDialog(false);
    setIsOpenEditDialog(false);
  };

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const [search, setSearch] = useState('');

  const {
    isLoading,
    error,
    data: paginatedInfo,
    isFetching,
  } = useGetInternalHomologateSubjectsQuery(
    {
      page,
      perPage,
      search,
      meshId,
      ...(careerIdSelected && { careerDestinyId: careerIdSelected }),
      ...(meshIdSelected && { meshDestinyId: meshIdSelected }),
      ...(subjectIdSelected && { subjectDestinyId: subjectIdSelected }),
    },
    { refetchOnMountOrArgChange: 30 }
  );

  const { data: educationLevels, isLoading: isLoadingEL } =
    useGetEducationLevelsParentsAndChildrenQuery({
      profileId: currentProfile?.profileId!,
    });

  const { data: subjects, isLoading: isLoadingSubjects } =
    useGetInternalHomologationSubjectsbyMeshQuery(
      {
        profileId: currentProfile?.profileId!,
        educationLevel: careerIdSelected!,
        curriculumId: meshIdSelected!,
      },
      { skip: !careerIdSelected || !meshIdSelected }
    );

  useEffect(() => {
    if (search) {
      setPage(1);
    }
  }, [search]);

  const handleDelete = async (homologateId: number) => {
    dispatch(setSubjectInternalHomologateSelected(homologateId));
    dispatch(fireDeleteSubjectInternalHomologateDialog(true));
  };

  const handleEdit = async (dataSubject: HomologateInternalSubjects) => {
    setDataSubject(dataSubject);
  };

  const { hasAccess } = useSharedGuard(subPermissionKeys);

  const curriculumsData = paginatedInfo?.data || [];

  const curriculums = curriculumsData.map((dataInfo, index: number) => ({
    id: {
      idx: index + (page - 1) * perPage + 1,
      id: dataInfo.id,
      curriculumCurrentId: dataInfo.curriculumCurrentId,
      curriculumCurrentName: dataInfo.curriculumCurrentName,
      educationLevelCurrentId: dataInfo.educationLevelCurrentId,
      educationLevelCurrent: dataInfo.educationLevelCurrent,
      curriculumDestinyId: dataInfo.curriculumDestinyId,
      educationLevelDestinyId: dataInfo.educationLevelDestinyId,
    },
    subjectOrigin: dataInfo.subjectOrigin,
    relation: dataInfo.relation,
    educationLevelDestiny: dataInfo.educationLevelDestiny,
    curriculumDestinyName: dataInfo.curriculumDestinyName,
    subjectDestiny: dataInfo.subjectDestiny,
    options: 'curriculums-options',
  }));

  return (
    <>
      <SearchPaginatedTable
        data={curriculums}
        headers={{
          id: 'N°',
          subjectOrigin: 'Materia malla',
          relation: 'Relación',
          educationLevelDestiny: 'Carrera',
          curriculumDestinyName: 'Malla',
          subjectDestiny: 'Materia',
          options: 'Opciones',
        }}
        isFetching={isFetching}
        isLoading={isLoading}
        error={error}
        keyExtractor={(item) => item.id.id.toString()}
        numHeader={7}
        page={page}
        setPage={setPage}
        perPage={perPage}
        setPerPage={setPerPage}
        search={search}
        setSearch={setSearch}
        total={paginatedInfo?.total || 0}
        searchPlacehoder="Buscar materia"
        ActionButtons={<AddButton size="large" onClick={() => handlerAddOpenDialog(true)} />}
        customRenderers={{
          relation: ({ relation }) => (
            <>
              {relation}%
              <Box sx={{ width: '100%' }}>
                <LinearProgress
                  variant="determinate"
                  value={Number(relation)}
                  color={
                    Number(relation) < 25
                      ? 'inherit'
                      : Number(relation) < 50
                      ? 'secondary'
                      : 'primary'
                  }
                />
              </Box>
            </>
          ),
          id: ({ id }) => <>{id.idx} </>,
          options: ({
            id,
            subjectOrigin,
            relation,
            subjectDestiny,
            curriculumDestinyName,
            educationLevelDestiny,
          }) => (
            <>
              {hasAccess('delete') && <DeleteButton handleDelete={() => handleDelete(id.id)} />}

              {hasAccess('edit') && (
                <EditButton
                  handleEdit={() => {
                    handleEdit({
                      id: id.id,
                      curriculumCurrentId: id.curriculumCurrentId,
                      curriculumCurrentName: id.curriculumCurrentName,
                      educationLevelCurrentId: id.educationLevelCurrentId,
                      educationLevelCurrent: id.educationLevelCurrent,
                      curriculumDestinyId: id.curriculumDestinyId,
                      educationLevelDestinyId: id.educationLevelDestinyId,
                      subjectOrigin,
                      relation,
                      subjectDestiny,
                      curriculumDestinyName,
                      educationLevelDestiny,
                    });
                    handlerEditOpenDialog(true);
                  }}
                />
              )}
            </>
          ),
        }}
        customHeadersCellsProperties={{
          options: {
            align: 'center',
          },
        }}
        customDataCellsProperties={{
          options: {
            width: '15%',
            align: 'center',
          },
        }}
        additionalFilters={
          <>
            <Grid container spacing={1}>
              <Grid item xs={12} md={3}>
                {isLoadingEL ? (
                  <TextFieldFetching />
                ) : (
                  <Autocomplete
                    options={educationLevels || []}
                    getOptionLabel={(option) => option.edu_name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Seleccione una facultad"
                        label="Facultad"
                        fullWidth
                      />
                    )}
                    value={educationLevels?.find((e) => e.id === eduLevelParentIdSelected) || null}
                    onChange={(e, value) => {
                      setEduLevelParentIdSelected(value?.id || null);
                      setCareerIdSelected(null);
                      setMeshIdSelected(null);
                    }}
                  />
                )}
              </Grid>
              <Grid item xs={12} md={3}>
                {!eduLevelParentIdSelected ? (
                  <TextField placeholder="Seleccione una facultad" disabled fullWidth />
                ) : (
                  <Autocomplete
                    options={
                      educationLevels?.find(
                        (educationLevel) => educationLevel.id === eduLevelParentIdSelected
                      )?.children || []
                    }
                    getOptionLabel={(option) => option.edu_name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Seleccione una carrera"
                        label="Carrera"
                        fullWidth
                      />
                    )}
                    value={
                      educationLevels
                        ?.find((educationLevel) => educationLevel.id === eduLevelParentIdSelected)
                        ?.children?.find((c) => c.id === careerIdSelected) || null
                    }
                    onChange={(e, value) => {
                      setCareerIdSelected(value?.id || null);
                      setMeshIdSelected(null);
                    }}
                  />
                )}
              </Grid>
              <Grid item xs={12} md={3}>
                {!careerIdSelected ? (
                  <TextField placeholder="Seleccione una carrera" disabled fullWidth />
                ) : (
                  <Autocomplete
                    options={
                      educationLevels
                        ?.find((educationLevel) => educationLevel.id === eduLevelParentIdSelected)
                        ?.children?.find((c) => c.id === careerIdSelected)?.meshs || []
                    }
                    getOptionLabel={(option) => `${option.mes_name} (${option.anio})`}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Seleccione una malla"
                        label="Malla"
                        fullWidth
                      />
                    )}
                    value={
                      educationLevels
                        ?.find((educationLevel) => educationLevel.id === eduLevelParentIdSelected)
                        ?.children?.find((c) => c.id === careerIdSelected)
                        ?.meshs?.find((m) => m.id === meshIdSelected) || null
                    }
                    onChange={(e, value) => {
                      setMeshIdSelected(value?.id || null);
                    }}
                  />
                )}
              </Grid>
              <Grid item xs={12} md={3}>
                {!meshIdSelected && (
                  <TextField placeholder="Seleccione una malla" disabled fullWidth />
                )}
                {meshIdSelected && isLoadingSubjects && <TextFieldFetching />}
                {meshIdSelected && !isLoadingSubjects && (
                  <Autocomplete
                    options={subjects || []}
                    getOptionLabel={(option) => option.matter_rename}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Seleccione una materia"
                        label="Materia"
                        fullWidth
                      />
                    )}
                    value={subjects?.find((s) => Number(s.matter_id) === subjectIdSelected) || null}
                    onChange={(e, value) => {
                      setSubjectIdSelected(value?.matter_id ? Number(value.matter_id) : null);
                    }}
                  />
                )}
              </Grid>
            </Grid>
          </>
        }
      />
      {isOpenAddDialog && (
        <HomologationInAddDialog open={isOpenAddDialog} handleCloseDialog={handlerCloseDialog} />
      )}
      {isOpenEditDialog && (
        <HomologationIntEditDialog
          dataSubject={dataSubject!}
          open={isOpenEditDialog}
          handleCloseDialog={handlerCloseDialog}
        />
      )}
    </>
  );
};

export default HomologateSubjectInternalTable;
