import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';

import { IconBuilding, IconBuildingWarehouse, IconCirclePlus, IconSchool } from '@tabler/icons';

import Stack from '@material-ui/core/Stack';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import { CircularProgress, IconButton } from '@material-ui/core';
import {
  useAddCourseStudentMutation,
  useGetIsRegisterCheckMatterQuery,
} from '../slices/subjectsRegisterApiSlice';
import { useAppSelector } from '../../../../hooks/useRedux';
import { useEffect, useState } from 'react';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import { CourseAvailable } from '../interfaces/coursesAvailables.interfaces';
import HourshandCourse from './HourshandCourse';
import useStudentInfo from '../hooks/useStudentInfo';
import { useAddCarOnline } from '../hooks/useAddCarOnline';

import { useTranslation } from 'react-i18next';

interface Props {
  course: CourseAvailable;
}

const useStyles = makeStyles((theme) => ({
  boxContainer: {
    boxShadow: '0px 4px 20px 15px rgba(234, 240, 246, 0.5);',
    borderRadius: 10,
    padding: '20px',
  },
}));

const CourseOptionCard = ({ course }: Props) => {
  const { t } = useTranslation();

  const classes = useStyles();
  const theme = useTheme();

  const [showAlerts, setShowAlerts] = useState<Boolean>(true);

  const { userInfo } = useAppSelector((state) => state.auth);
  const { currentProfile } = useAppSelector((state) => state.access);
  const { currentSubject, currentPeriodId, isOnline, studentInfoAcademic } = useAppSelector(
    (state) => state.subjectsRegister
  );

  const studentInfo = useStudentInfo();

  const [addCourseStudent, { isLoading, error, isSuccess, isError }] =
    useAddCourseStudentMutation();

  const {
    data: isRegister,
    isLoading: isLoadingIsRegister,
    refetch,
  } = useGetIsRegisterCheckMatterQuery(
    {
      course_id: course.id,
      student_record_id: studentInfo?.studentRecordId!,
      period_id: currentPeriodId!,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const {
    addCourseCar,
    isError: isErrorAddCar,
    isSuccess: isSuccessAddCar,
    error: errorAddCar,
  } = useAddCarOnline();

  const teacher = course.collaborator;

  useEffect(() => {
    if (isSuccess || isSuccessAddCar) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, isSuccessAddCar]);

  return (
    <Grid item className={classes.boxContainer}>
      <Grid item container>
        {(isError || isErrorAddCar) && showAlerts && (
          <ErrorAlert
            message={(error || errorAddCar) as string}
            handleDismiss={() => setShowAlerts(false)}
          />
        )}

        {(isSuccess || isSuccessAddCar) && showAlerts && (
          <SuccessAlert
            message={
              isSuccess
                ? t('students.subjects.registration.curriculum.dialog.succes_register')
                : isSuccessAddCar
                ? t('students.subjects.registration.curriculum.dialog.succes_add')
                : ''
            }
            handleDismiss={() => setShowAlerts(false)}
          />
        )}
      </Grid>
      <Grid item xs={12} sx={{ mb: 2 }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h3" sx={{ color: 'primary.main' }}>
            {course.classroom.cl_name}
          </Typography>
          <Grid item>
            {isLoading ? (
              <Grid container item direction="row" alignItems="center">
                <CircularProgress size={12} />
              </Grid>
            ) : (
              <Grid container item direction="row" alignItems="center">
                {!isLoadingIsRegister && (
                  <Typography>
                    {isRegister?.value_exists
                      ? t('Registrado')
                      : t('students.subjects.registration.curriculum.dialog.register')}
                  </Typography>
                )}
                {isLoadingIsRegister ? (
                  <CircularProgress size={12} />
                ) : (
                  !isRegister?.value_exists && (
                    <IconButton
                      disabled={isRegister?.value_exists}
                      onClick={async () => {
                        isOnline
                          ? addCourseCar(course)
                          : await addCourseStudent({
                              profileId: Number(currentProfile?.profileId),
                              courseStudentPayload: {
                                course_id: course.id,
                                student_record_id: studentInfo?.studentRecordId!,
                                final_note: 0,
                                observation: null,
                                num_fouls: 0,
                                subject_id: Number(course.matter_id),
                                person_id:
                                  studentInfoAcademic?.personId || Number(userInfo.personId),
                                period_id: currentPeriodId,
                                curriculum_id: Number(currentSubject?.mesh_id),
                                subject_curriculum_id: Number(
                                  currentSubject?.subject_curriculum_id
                                ),
                                approval_status: 13,
                                approval_reason_id: 380,
                                status_id: 1,
                                generation_fees: false,
                                expiration_date: null,
                              },
                            });
                        setShowAlerts(true);
                      }}
                    >
                      <IconCirclePlus size={20} color={theme.palette.primary.main} />
                    </IconButton>
                  )
                )}
              </Grid>
            )}
          </Grid>
        </Stack>
      </Grid>
      <Grid item xs={12} container>
        <Grid item xs={6}>
          <Stack direction="row" spacing={1.5} alignItems="center">
            {/* <IconUsers color={theme.palette.grey[700]} size={20} /> */}
            <Typography>
              <b>{t('students.subjects.registration.curriculum.dialog.available_quotas')}:</b>{' '}
              {/* {Number(course?.max_capacity || 0) - */}
              {Number(course?.available_quotas || 0)}
              {/* {course?.max_capacity} */}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack direction="row" spacing={1.5} alignItems="center">
            <Typography sx={{ fontWeight: 600 }}>
              <b>Paralelo:</b> {course?.parallel?.par_name}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
      <Grid item xs={12} container>
        {teacher && (
          <Grid item xs={6}>
            <Stack direction="row" spacing={1.5} alignItems="center">
              <IconSchool color={theme.palette.grey[700]} size={20} />
              <Typography>
                {teacher
                  ? `${teacher.user.person.pers_first_lastname} ${teacher.user.person.pers_second_lastname} ${teacher.user.person.pers_firstname}`
                  : 'N/A'}
              </Typography>
            </Stack>
          </Grid>
        )}
        <Grid item xs={6}>
          <Stack direction="row" spacing={1.5} alignItems="center">
            <IconBuilding color={theme.palette.grey[700]} size={20} />
            <Typography>{course.classroom.campus.cam_name}</Typography>
          </Stack>
        </Grid>
        {/* <Grid item xs={6}>
            <Stack direction="row" spacing={1.5} alignItems="center">
              <IconCalendar color={theme.palette.grey[700]} size={20} />
              <Stack spacing={0.5}>
                <Typography>{course?.hourhand?.hour_description}</Typography>
              </Stack>
            </Stack>
          </Grid> */}
        <Grid item xs={6}>
          <Stack direction="row" spacing={1.5} alignItems="center">
            <IconBuildingWarehouse color={theme.palette.grey[700]} size={20} />
            <Stack spacing={0.5}>
              <Typography>{course?.classroom?.cl_name}</Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid container mt={1}>
          {course.hourhand ? <HourshandCourse hourhand={course.hourhand} /> : null}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CourseOptionCard;
