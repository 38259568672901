import { useEffect, useState } from 'react';
import { useHistory, Link, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { useGetHourhandsQuery, useLazyGetAllHourhandsQuery } from '../slices/hourhandsApiSlice';
import { Hourhand } from '../interfaces/hourhands.interfaces';
import {
  openDeleteModalWith,
  setHourhandList,
  updateHourhandsTable,
} from '../slices/hourhandsSlice';
import SearchPaginatedTable from '../../../shared/components/tables/SearchPaginatedTable';
import useSharedGuard from '../../../../hooks/useSharedGuard';
import { useTableSearch } from '../../../../hooks/useTableSearch';
import { FilterArrow } from '../../../shared/components/tables/SearchPaginatedTableAndCollapsibleItems';
import AddButton from '../../../shared/components/tables/buttons/AddButton';
import StatusChip from '../../../shared/components/badges/StatusChip';
import EditButton from '../../../shared/components/tables/buttons/EditButton';
import DeleteButton from '../../../shared/components/tables/buttons/DeleteButton';
import Chip from '../../../shared/components/extended/Chip';
import RenderHourHandsCollapsible from './renderHourHandsCollapsible';
import { subPermissionKeys } from '../constants/permissions';

const HourhandTable = () => {
  const { currentProfile } = useAppSelector((state) => state.access);

  const { t } = useTranslation();

  const { hasAccess } = useSharedGuard(subPermissionKeys);

  const [filterArrow, setFilterArrow] = useState<FilterArrow>(null!);

  const dispatch = useAppDispatch();

  const { path } = useRouteMatch();
  const history = useHistory();

  const { page, perPage, search } = useAppSelector((state) => state.hourhands.hourhandsTable);
  const { setPage, setPerPage, setSearch } = useTableSearch({
    page,
    perPage,
    search,
    updateFunction: updateHourhandsTable,
  });

  const { isLoading, error, data, isFetching } = useGetHourhandsQuery(
    {
      profileId: currentProfile?.profileId!,
      page,
      perPage,
      ...(filterArrow && {
        type_sort: filterArrow.direction,
        sort: filterArrow.name,
      }),
      search,
    },
    { refetchOnMountOrArgChange: 30 }
  );

  const [getAllHourhands] = useLazyGetAllHourhandsQuery();

  const getAllDatafetch = async () => {
    try {
      const hourHandsData = await getAllHourhands({
        profileId: currentProfile?.profileId!,
      }).unwrap();

      return hourHandsData.map((p: Hourhand, idx) => ({
        viewHourHands: '',
        id: idx + ((page - 1) * perPage + 1),
        description: p.hour_description,
        days: p,
        state: p.status_id,
        options: p,
      }));
    } catch (error) {
      return [];
    }
  };

  useEffect(() => {
    if (data) {
      dispatch(setHourhandList(data.data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const hourhands =
    data?.data?.map((p: Hourhand, idx) => ({
      viewHourHands: '',
      id: idx + ((page - 1) * perPage + 1),
      description: p.hour_description,
      days: p,
      state: p.status_id,
      options: p,
    })) || [];

  const handleDelete = async (hourhandId: number) => {
    dispatch(openDeleteModalWith(hourhandId));
  };

  return (
    <SearchPaginatedTable
      data={hourhands}
      headers={{
        viewHourHands: 'Ver horario',
        id: 'N°',
        description: 'Descripción',
        days: 'Días',
        state: 'Estado',
        options: 'Opciones',
      }}
      isLoading={isLoading}
      isFetching={isFetching}
      error={error}
      keyExtractor={(item) => item.id.toString()}
      numHeader={14}
      page={page}
      setPage={setPage}
      perPage={perPage}
      setPerPage={setPerPage}
      search={search}
      setSearch={setSearch}
      total={data?.total || 0}
      searchPlacehoder="Buscar malla curricular"
      filterArrow={filterArrow}
      setFilterArrow={setFilterArrow}
      ActionButtons={<AddButton size="large" onClick={() => history.push(`${path}/agregar`)} />}
      exportProps={{
        fileName: t('academic.classrooms.download_list'),
        files: { excel: true },
        // variant: 'buttons',
        // portalId: classroomExportPortalId,
        batch: true,
        allDataFetchHandler: getAllDatafetch as any,
      }}
      customHeadersCellsProperties={{
        options: {
          align: 'center',
        },
      }}
      collapsible={{
        collapsibleBtnColl: 0,
        Rows: RenderHourHandsCollapsible,
      }}
      customRenderers={{
        options: ({ options }) => (
          <>
            {hasAccess('edit') && (
              <Link to={`${path}/${options.id}/editar`}>
                <EditButton />
              </Link>
            )}
            {hasAccess('delete') && <DeleteButton handleDelete={() => handleDelete(options.id)} />}
            {!hasAccess('edit') && !hasAccess('delete') && 'Ninguna'}
          </>
        ),
        days: ({ days: p }) => (
          <>
            {p.hour_sunday === '1' && (
              <Chip label="Domingo" size="small" variant="outlined" style={{ marginRight: 3 }} />
            )}
            {p.hour_monday === '1' && (
              <Chip label="Lunes" size="small" variant="outlined" style={{ marginRight: 3 }} />
            )}
            {p.hour_tuesday === '1' && (
              <Chip label="Martes" size="small" variant="outlined" style={{ marginRight: 3 }} />
            )}
            {p.hour_wednesday === '1' && (
              <Chip label="Miércoles" size="small" variant="outlined" style={{ marginRight: 3 }} />
            )}
            {p.hour_thursday === '1' && (
              <Chip label="Jueves" size="small" variant="outlined" style={{ marginRight: 3 }} />
            )}
            {p.hour_friday === '1' && (
              <Chip label="Viernes" size="small" variant="outlined" style={{ marginRight: 3 }} />
            )}
            {p.hour_saturday === '1' && (
              <Chip label="Sábado" size="small" variant="outlined" style={{ marginRight: 3 }} />
            )}
          </>
        ),

        state: ({ state }) => <StatusChip statusId={Number(state)} />,
      }}
    />
  );
};

export default HourhandTable;
