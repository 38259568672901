import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Typography, Box, Tooltip, IconButton } from '@material-ui/core';
import { IconLayoutGrid, IconList, IconArrowNarrowLeft } from '@tabler/icons';
import { useAppSelector } from '../../../../hooks/useRedux';
import MainCard from '../../../shared/components/cards/MainCard';

import DeleteELSDialog from '../components/DeleteELCDialog';
import ELSTable from '../components/ELCTable';
import ELSGridView from '../components/ELCGridView';
// import AddSubjects from './AddSubjects';

const ELSScreen = () => {
  const { educationLevelSubjectIdToDelete } = useAppSelector(
    (state) => state.educationLevelSubject
  );
  const [viewMode, setViewMode] = useState<'table' | 'grid'>('grid');

  const history = useHistory();

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid item xs={12} marginBottom={4}>
        <Grid item container alignItems="center" gap={1} sx={{ cursor: 'pointer' }}>
          <IconButton onClick={() => history.goBack()}>
            <IconArrowNarrowLeft size={30} />
          </IconButton>
          <Typography variant="h2">Regresar</Typography>
        </Grid>
      </Grid>
      <Grid item sx={{ mb: 3 }} container justifyContent="space-between" alignItems="center">
        <Typography variant="h2">Listado de materias de nivelación</Typography>
        <Box>
          <Tooltip title="Vista de tabla">
            <IconButton onClick={() => setViewMode('table')}>
              <IconList size={20} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Vista de tarjetas">
            <IconButton onClick={() => setViewMode('grid')}>
              <IconLayoutGrid size={20} />
            </IconButton>
          </Tooltip>
        </Box>
      </Grid>
      {viewMode === 'table' && <ELSTable />}
      {viewMode === 'grid' && <ELSGridView />}

      {educationLevelSubjectIdToDelete && <DeleteELSDialog />}
    </Grid>
  );
};

export default ELSScreen;
