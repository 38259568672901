import { makeStyles } from '@material-ui/core/styles';

// style constant
export const scrollStyles = makeStyles((theme) => ({
  scrollRoot: {
    '& >div.ps__rail-y': {
      '& :hover': {
        backgroundColor: 'black',
      },
    },

    '& >div.ps__rail-y > div.ps__thumb-y': {
      backgroundColor: theme.palette.secondary.dark,
      '& :hover': {
        backgroundColor: 'black',
      },
    },
    // '& .scrollbar-container .ps__rail-y:hover > .ps__thumb-y, .scrollbar-container .ps__rail-y:focus > .ps__thumb-y, .scrollbar-container .ps__rail-y.ps--clicking .ps__thumb-y':
    //   { backgroundColor: 'red' },
  },
}));
