import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  Autocomplete,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  TextField,
  InputAdornment,
} from '@material-ui/core';

import { useFormik } from 'formik';

import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import InputLabel from '../../../shared/components/forms/InputLabel';

import { useAppSelector } from '../../../../hooks/useRedux';
import { useAddDepartmentMutation, useGetDepartmentsAllQuery } from '../slices/departmentsApiSlice';

import { DepartmentSchema } from '../constants/validation-schemas';
import { statusAvailables } from '../../../shared/constants/resourceStatus';
// import { Department } from '../interfaces/departments.interface';

const AddDepartmentForm = () => {
  const history = useHistory();

  const { currentProfile } = useAppSelector((state) => state.access);

  const [showAlerts, setShowAlerts] = useState(true);

  const [addDepartment, { isLoading, isError, error, isSuccess }] = useAddDepartmentMutation();

  const { data: departmentsAvailables = [], isLoading: isLoadingDepartmentsAvailables } =
    useGetDepartmentsAllQuery({ profileId: currentProfile?.profileId! });

  const { getFieldProps, errors, touched, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      parentId: null,
      depName: '',
      depDescription: '',
      statusId: 1,
    },
    validationSchema: DepartmentSchema,
    onSubmit: async (values) => {
      await addDepartment({
        profileId: currentProfile?.profileId!,
        departmentPayload: {
          parent_id: values.parentId,
          dep_name: values.depName,
          dep_description: values.depDescription,
          status_id: values.statusId,
        },
      });

      setShowAlerts(true);
    },
  });

  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction="column" gap={2}>
        <Grid item>
          {isError && showAlerts && (
            <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
          )}

          {isSuccess && showAlerts && (
            <SuccessAlert
              message="El departamento ha sido creado correctamente."
              handleDismiss={() => setShowAlerts(false)}
            />
          )}
        </Grid>

        <Grid item container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <InputLabel>Departamento principal</InputLabel>
            {isLoadingDepartmentsAvailables ? (
              <>
                <TextField
                  disabled
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <CircularProgress size={25} />
                      </InputAdornment>
                    ),
                  }}
                  error={Boolean(errors.parentId && touched.parentId)}
                  placeholder="Cargando datos ..."
                />
                {errors.parentId && touched.parentId && (
                  <FormHelperText error>{errors.parentId}</FormHelperText>
                )}
              </>
            ) : (
              <>
                <Autocomplete
                  loadingText="Cargando Datos"
                  noOptionsText="No hay opciones"
                  options={departmentsAvailables}
                  loading={isLoadingDepartmentsAvailables}
                  getOptionLabel={(option) => option?.dep_name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(errors.parentId && touched.parentId)}
                      placeholder="Ningun departamento asignado"
                    />
                  )}
                  onChange={(e, value) => setFieldValue('parentId', value?.id || '')}
                  fullWidth
                />
                {errors.parentId && touched.parentId && (
                  <FormHelperText error>{errors.parentId}</FormHelperText>
                )}
              </>
            )}
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Nombre del departamento</InputLabel>
            <TextField
              fullWidth
              error={Boolean(errors.depName && touched.depName)}
              placeholder="Ingrese el nombre del departamento"
              {...getFieldProps('depName')}
            />
            {errors.depName && touched.depName && (
              <FormHelperText error>{errors.depName}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Descripción</InputLabel>
            <TextField
              multiline
              rows={2}
              fullWidth
              error={Boolean(errors.depDescription && touched.depDescription)}
              placeholder="Ingrese una descripción"
              {...getFieldProps('depDescription')}
            />
            {errors.depDescription && touched.depDescription && (
              <FormHelperText error>{errors.depDescription}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12}>
            <InputLabel>Estado</InputLabel>
            <Autocomplete
              options={statusAvailables}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField {...params} error={Boolean(errors.statusId && touched.statusId)} />
              )}
              defaultValue={statusAvailables[0]}
              onChange={(e, value) => setFieldValue('statusId', value?.id || '')}
              fullWidth
              clearIcon={null}
            />
            {errors.statusId && touched.statusId && (
              <FormHelperText error>{errors.statusId}</FormHelperText>
            )}
          </Grid>
        </Grid>

        <Grid
          item
          container
          alignItems="center"
          justifyContent="flex-end"
          spacing={2}
          sx={{ mt: 1 }}
        >
          <Grid item>
            <Button
              variant="outlined"
              size="large"
              color="primary"
              onClick={() => history.goBack()}
            >
              Regresar
            </Button>
          </Grid>
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              disabled={isLoading}
              endIcon={isLoading && <CircularProgress size={20} />}
            >
              Agregar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default AddDepartmentForm;
