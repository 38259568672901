import { Avatar, Grid, Typography, makeStyles, Box } from '@material-ui/core';
import moment from 'moment';
// import { DeltaStatic } from 'quill';
import { obtainDomainStorageImage } from '../../../../shared/helpers';
import { Profile } from '../../../../shared/interfaces/post.interfaces';
import MenuMoreVert from '../../components/MenuMoreVert';
import { AnswersActivity } from '../interfaces/forums.interfaces';
import EditorReadText from './EditorReadText';

interface Props {
  id: string;
  data: {
    name: string;
    comment: string;
    images: {
      img: string;
    }[];
    replies: AnswersActivity[];
  };
  profile: Profile;
  menuData: {
    isViewMenu: boolean;
    data: {
      name: string;
      handleClick: () => void;
    }[];
  };
}

const useStylesContent = makeStyles((theme) => ({
  card: {
    minWidth: 200,
    textAlign: 'left',
    marginTop: 10,
  },
  cardContent: {
    paddingTop: '0 !important',
  },
  cardHeader: {
    paddingBottom: '2 !important',
  },
}));

const CommentCard = ({ id, data, profile, menuData }: Props) => {
  const { isViewMenu, data: dataMenu } = menuData;
  const classesContent = useStylesContent();
  let text: any;
  if (!data.comment?.includes('{') || !data.comment?.includes('[')) {
    text = {
      ops: [
        {
          insert: data.comment,
        },
      ],
    };
  } else {
    text = JSON.parse(data.comment);
  }

  return (
    <Box key={id} className={classesContent.card}>
      <Box className={classesContent.cardHeader}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Avatar alt="User 1" src={obtainDomainStorageImage(profile.name)}></Avatar>
            <Box
              sx={{
                ml: 2,
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{
                  mb: 0.2,
                }}
              >
                {profile.name}
              </Typography>

              <Typography variant="subtitle2">
                {moment(profile.time.toLocaleString()).format('DD MMM - hh:mm').replace('.', '') ||
                  'N/A'}
              </Typography>
            </Box>
          </Box>
          {isViewMenu && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              <MenuMoreVert dataMenu={dataMenu} />
            </Box>
          )}
        </Box>
      </Box>
      <Box className={classesContent.cardContent}>
        <Grid>
          <Grid>
            <Typography
              variant="h5"
              sx={{
                my: 1,
              }}
            >
              <EditorReadText text={text} />
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default CommentCard;
