import { Grid, Typography } from '@material-ui/core';

import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import { fireDialogSubjectsRegistered } from '../../slices/subjectsRegisterSlice';
import SubjectsRegisteredDialog from '../SubjectsRegisteredDialog';
import HoraPlaceholder from './HoraPlaceholder';

import { useTranslation } from 'react-i18next';

const HourshandRegistered = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const { showSubjectsRegistered } = useAppSelector((state) => state.subjectsRegister);
  const date = new Date();

  const handleHoursHand = (): number[] => {
    const arrayHours: number[] = [];
    for (let i = 0; i < 16; i++) {
      arrayHours.push(date.setHours(7 + i, 0, 0, 0));
    }
    return arrayHours;
  };

  const convertTime = (datetime: number): number => {
    const hour = new Date(datetime);
    return hour.getHours();
  };

  const handleRows = handleHoursHand().map((h) => {
    return (
      <Grid item xs={12} container sx={{ mt: 2 }} alignItems="center" key={h}>
        <Grid item xs={1}>
          <Typography variant="h5">{`${convertTime(h)}:00 - ${convertTime(h) + 1}:00`}</Typography>
        </Grid>
        <Grid item xs={11} container spacing={1}>
          <HoraPlaceholder
            day={'mo'}
            start_hour={
              convertTime(h) < 10 ? `0${convertTime(h)}:00:00` : `${convertTime(h)}:00:00`
            }
            end_hour={
              convertTime(h) < 10 ? `0${convertTime(h) + 1}:00:00` : `${convertTime(h) + 1}:00:00`
            }
          />
          <HoraPlaceholder
            day={'tu'}
            start_hour={
              convertTime(h) < 10 ? `0${convertTime(h)}:00:00` : `${convertTime(h)}:00:00`
            }
            end_hour={
              convertTime(h) < 10 ? `0${convertTime(h) + 1}:00:00` : `${convertTime(h) + 1}:00:00`
            }
          />
          <HoraPlaceholder
            day={'we'}
            start_hour={
              convertTime(h) < 10 ? `0${convertTime(h)}:00:00` : `${convertTime(h)}:00:00`
            }
            end_hour={
              convertTime(h) < 10 ? `0${convertTime(h) + 1}:00:00` : `${convertTime(h) + 1}:00:00`
            }
          />
          <HoraPlaceholder
            day={'th'}
            start_hour={
              convertTime(h) < 10 ? `0${convertTime(h)}:00:00` : `${convertTime(h)}:00:00`
            }
            end_hour={
              convertTime(h) < 10 ? `0${convertTime(h) + 1}:00:00` : `${convertTime(h) + 1}:00:00`
            }
          />
          <HoraPlaceholder
            day={'fr'}
            start_hour={
              convertTime(h) < 10 ? `0${convertTime(h)}:00:00` : `${convertTime(h)}:00:00`
            }
            end_hour={
              convertTime(h) < 10 ? `0${convertTime(h) + 1}:00:00` : `${convertTime(h) + 1}:00:00`
            }
          />
          <HoraPlaceholder
            day={'sa'}
            start_hour={
              convertTime(h) < 10 ? `0${convertTime(h)}:00:00` : `${convertTime(h)}:00:00`
            }
            end_hour={
              convertTime(h) < 10 ? `0${convertTime(h) + 1}:00:00` : `${convertTime(h) + 1}:00:00`
            }
          />
        </Grid>
      </Grid>
    );
  });

  return (
    <>
      <Grid container>
        <Grid item xs={12} container>
          <Grid item xs={1}></Grid>
          <Grid item xs={11} container>
            <Grid item xs={2}>
              <Typography variant="h4" textAlign="center">
                {t('utils.monday')}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="h4" textAlign="center">
                {t('utils.tuesday')}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="h4" textAlign="center">
                {t('utils.wednesday')}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="h4" textAlign="center">
                {t('utils.thursday')}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="h4" textAlign="center">
                {t('utils.friday')}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="h4" textAlign="center">
                {t('utils.saturday')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        {/* Rows */}
        {handleRows}
      </Grid>

      <SubjectsRegisteredDialog
        open={showSubjectsRegistered}
        handleCloseDialog={() => dispatch(fireDialogSubjectsRegistered(false))}
      />
    </>
  );
};

export default HourshandRegistered;
