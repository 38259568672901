import { Grid, Typography } from "@material-ui/core"
import MainCard from '../../../shared/components/cards/MainCard';
import EventsNewsTable from "../components/EventsNewsTable";
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import EventNewDelete from "../components/EventNewsDelete";
import EventNewsImage from "../components/EventNewsImage";
import { setShowContainerImage, resetEventNewsToAddImage  } from "../slices/eventNewSlice";

const EventNewScreen = function () {

  const dispatch = useAppDispatch()
  const { eventIdToDelete, showContainerImage } = useAppSelector((state) => state.eventNew)

  const handleCloseContainerImage = function (){
    dispatch(setShowContainerImage(false));
    dispatch(resetEventNewsToAddImage());
  }

  return <Grid component={MainCard} sx={{ padding: 2 }}>
    <Grid sx={{ mb: 3 }}>
      <Typography variant="h2">Listado de comunicados</Typography>
    </Grid>

    <EventsNewsTable />

    {showContainerImage && <EventNewsImage open={showContainerImage} handleCloseDialog={() => handleCloseContainerImage()}/>}
    {eventIdToDelete && <EventNewDelete />}
  </Grid>
}

export default EventNewScreen