import { Button, CircularProgress, Dialog, DialogProps, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FC } from 'react';

const useStyles = makeStyles((theme) => ({
  paper: { maxWidth: '600px', width: '90%' },
  paperlg: { maxWidth: '800px', width: '90%' },
}));

interface Props extends DialogProps {
  onClose: () => void;
  actionHandler: () => void;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  open: boolean;
  confirmBtnMessage?: string;
  disabledConfirm?: boolean;
  title?: string;
  btnText?: string;
  hideFooter?: boolean;
  noTittle?: boolean;
  onlyChildren?: boolean;
  size?: 'lg' | 'md';
}

const CustomDialog: FC<Props> = ({
  onClose,
  actionHandler,
  isLoading,
  isSuccess,
  isError,
  open,
  confirmBtnMessage,
  children,
  disabledConfirm,
  title,
  hideFooter,
  noTittle = false,
  onlyChildren,
  size = 'md',
  ...props
}) => {
  const classes = useStyles();

  const confirmBtn = !!confirmBtnMessage ? confirmBtnMessage : 'Sí, Publicar';

  const paperSize = {
    md: classes.paper,
    lg: classes.paperlg,
  };

  return (
    <Dialog
      open={open}
      classes={{ paper: paperSize[size] }}
      sx={{ p: 3 }}
      onClose={onClose}
      {...props}
    >
      <Grid container gap={4} sx={{ p: 2 }}>
        {!noTittle && !onlyChildren && (
          <Grid item xs={12} px={2}>
            <Typography variant="h2" textAlign="center">
              {' '}
              {title || 'Cargar récord académico'}
            </Typography>
          </Grid>
        )}

        <Grid item xs={12}>
          {children}
        </Grid>
        {!onlyChildren && (
          <Grid
            item
            container
            xs={12}
            justifyContent="center"
            gap={1}
            sx={{
              display: hideFooter ? 'none' : '',
            }}
          >
            {!isSuccess && (
              <Button
                variant="text"
                size="large"
                color="primary"
                onClick={onClose}
                disabled={isLoading}
              >
                No, Cancelar
              </Button>
            )}

            {isSuccess && (
              <Button variant="contained" size="large" color="primary" onClick={onClose}>
                Continuar
              </Button>
            )}

            {!isSuccess && (
              <Button
                variant="contained"
                size="large"
                onClick={actionHandler}
                color="primary"
                autoFocus
                disabled={isLoading || disabledConfirm}
                endIcon={isLoading && <CircularProgress size={20} />}
              >
                {confirmBtn}
              </Button>
            )}
          </Grid>
        )}
      </Grid>
    </Dialog>
  );
};

export default CustomDialog;
