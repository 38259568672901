import { forwardRef } from 'react';

import { IconArrowNarrowLeft } from '@tabler/icons';
import {
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Slide,
  SlideProps,
  Typography,
  useTheme,
} from '@material-ui/core';
import ExtendedSideDialog from './ExtendedSideDialog';

// //styles
// const useStyles = makeStyles((theme) => ({
//   userAddDialog: {
//     '&>div:nth-child(3)': {
//       justifyContent: 'flex-end',
//       '&>div': {
//         margin: '0px',
//         borderRadius: '0px',
//         maxWidth: '650px',
//         maxHeight: '100%',
//         '&>div.MuiDialogContent-root>div.scrollbar-container': {
//           paddingLeft: '20px',
//           overflow: 'hidden',
//         },
//       },
//     },
//   },
//   paper: {
//     minHeight: '100vh',
//     width: '37%',
//   },
//   paperTable: {
//     minHeight: '100vh',
//     width: '50%!important',
//   },
//   movilPaper: {
//     minHeight: '100vh',
//     width: '100%',
//   },
// }));

// animation
const Transition = forwardRef((props: SlideProps, ref) => (
  <Slide direction="left" ref={ref} {...props} />
));

export interface Props {
  open: boolean;
  handleCloseDialog: (e: React.SyntheticEvent) => void;
  title: string;
  children: React.ReactNode;
}

const CustomSideDialog = ({ open, handleCloseDialog, title, children }: Props) => {
  //   const classes = useStyles();
  const theme = useTheme();
  // const dispatch = useDispatch();

  return (
    <ExtendedSideDialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCloseDialog}
      sx={{
        '.MuiDialog-container': {
          justifyContent: 'flex-end',
        },
        '&  .MuiDialog-paper': {
          width: '80%',
          maxWidth: '500px',
          height: '100vh',
          maxHeight: '100vh',
          margin: '0px',
          borderRadius: '0',
        },
      }}
    >
      <DialogTitle>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={2}>
            <IconButton onClick={handleCloseDialog}>
              <IconArrowNarrowLeft size={30} color={theme.palette.text.dark} />
            </IconButton>
          </Grid>
          <Grid item xs={10}>
            <Typography variant="h3">{title}</Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      {/* <DialogActions></DialogActions> */}
      {/* <DialogActions></DialogActions> */}
    </ExtendedSideDialog>
  );
};

export default CustomSideDialog;
