import { Grid, IconButton, Typography, useTheme } from '@material-ui/core';
import { IconArrowNarrowLeft } from '@tabler/icons';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import MainCardForm from '../../../shared/components/cards/MainCardForm';
import AddEconomicGroupForm from '../components/AddEconomicGroupForm';

const AddEconomicGroupScreen = () => {
  const theme = useTheme();
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <MainCardForm>
      <Grid item xs={12}>
        <Grid item container alignItems="center" gap={1} sx={{ cursor: 'pointer' }}>
          <IconButton onClick={() => history.goBack()}>
            <IconArrowNarrowLeft size={30} color={theme.palette.text.dark} />
          </IconButton>
          <Typography variant="h2">{t('financial.economic_group.add.title')}</Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} md={8} lg={6} sx={{ mt: 2 }}>
        <AddEconomicGroupForm />
      </Grid>
    </MainCardForm>
  );
};

export default AddEconomicGroupScreen;
