import { Button, CircularProgress, Dialog, Grid, Typography, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { IconCircleCheck, IconBook, IconFileAlert } from '@tabler/icons';

const useStyles = makeStyles((theme) => ({
  paper: { maxWidth: '600px', width: '90%' },
}));

interface Props {
  onClose: () => void;
  handleDelete: () => void;
  isLoading: boolean;
  loadingComponent?: JSX.Element | null;
  isSuccess: boolean;
  isError: boolean;
  error: string;
  title?: string;
  subtitle?: string;
  successMessage?: string;
  open: boolean;
  confirmBtnMessage?: string;
  successEvent?: () => void;
  /**
   * Custom action al ser exitosa la peticion
   */
  customSuccessAction?: () => void;
  customSuccessBtnText?: string;
}

const ConfirmAlert = ({
  onClose,
  handleDelete,
  isLoading,
  loadingComponent,
  isSuccess,
  isError,
  error,
  title,
  subtitle,
  successMessage,
  open,
  confirmBtnMessage,
  successEvent,
  customSuccessAction,
  customSuccessBtnText,
}: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const titleText = title !== undefined ? title : '¿Estás seguro de eliminar el registro?';
  const subtitleText =
    subtitle !== undefined
      ? subtitle
      : 'Eliminaremos el registro de forma permanente, ¿Estás seguro de realizar esta acción?';
  const success =
    successMessage !== undefined ? successMessage : 'Hemos Eliminado el registro con éxito';

  const Loading = loadingComponent ? loadingComponent : <CircularProgress size={50} />;

  return (
    <Dialog
      open={open}
      classes={{ paper: classes.paper }}
      sx={{ p: 3 }}
      onClose={() => {
        onClose();
        if (successEvent) {
          successEvent();
        }
      }}
    >
      <Grid container gap={4} sx={{ p: 2 }}>
        <Grid item container xs={12} alignItems="center" justifyContent="center">
          {!isError && !isSuccess && !isLoading && (
            <IconBook
              size={60}
              color={theme.palette.primary.main}
              style={{
                padding: 12,
                backgroundColor: theme.palette.primary.light,
                borderRadius: '50%',
              }}
            />
          )}
          {isLoading && Loading}
          {isSuccess && (
            <IconCircleCheck
              size={60}
              color={theme.palette.success.main}
              style={{
                padding: 12,
                backgroundColor: theme.palette.success.light,
                borderRadius: '50%',
              }}
            />
          )}
          {isError && (
            <IconFileAlert
              size={60}
              color={theme.palette.error.main}
              style={{
                padding: 12,
                backgroundColor: theme.palette.orange.light,
                borderRadius: '50%',
              }}
            />
          )}
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Typography variant="h2" sx={{ color: theme.palette.primary.main, mb: 1 }}>
            {!isError && !isSuccess && titleText}
            {isSuccess && success}
            {isError && '¡Ha ocurrido un error!'}
          </Typography>
          <Typography variant="body2">
            {!isError && !isSuccess && subtitleText}
            {isError && error}
          </Typography>
        </Grid>
        <Grid item container xs={12} justifyContent="center" gap={1}>
          {!isSuccess && !isError && (
            <Button
              variant="text"
              size="large"
              color="primary"
              onClick={onClose}
              disabled={isLoading}
            >
              No, Cancelar
            </Button>
          )}

          {(isSuccess || isError) && (
            <Button
              variant="contained"
              size="large"
              color="primary"
              onClick={() => {
                onClose();
                if (successEvent) {
                  successEvent();
                }
                if (customSuccessAction) {
                  customSuccessAction();
                }
              }}
            >
              {customSuccessBtnText || 'Continuar'}
            </Button>
          )}

          {!isSuccess && !isError && (
            <Button
              variant="contained"
              size="large"
              onClick={handleDelete}
              color="primary"
              autoFocus
              disabled={isLoading}
            >
              {!!confirmBtnMessage ? confirmBtnMessage : 'Sí, Publicar'}
            </Button>
          )}
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default ConfirmAlert;
