import { Grid, TextField, Skeleton } from '@material-ui/core';

interface Props {
  rows?: number;
  columns?: number;

  customHeight?: number;
}

const SkeletonGrids = ({ columns = 1, rows = 1, customHeight }: Props) => {
  return (
    <Grid container gap={2}>
      {Array.from({ length: rows }).map((_, i) => (
        <Grid item container spacing={2} alignItems="center">
          {Array.from({ length: columns }).map((_, j) => (
            <Grid item xs={6}>
              <Skeleton
                variant="rectangular"
                width={'100%'}
                {...(customHeight && { height: customHeight })}
              >
                <TextField fullWidth />
              </Skeleton>
            </Grid>
          ))}
        </Grid>
      ))}
    </Grid>
  );
};

export default SkeletonGrids;
