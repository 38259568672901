import { mainApi } from '../../../../api/mainApi';
import { AccessState } from '../../../../types';
import { middlewareApi } from '../../../middleware/middlewareApi';
import {
  RecentSubjectsResponse,
  ActivitiesResponse,
  Activity,
  ExternalLink,
} from '../interfaces/dashboard.interface';

export const dashboardTags = middlewareApi.enhanceEndpoints({
  addTagTypes: ['StatusSubjects', 'ExternalLinks'],
});
// Define a service using a base URL and expected endpoints
export const dashboardApi = dashboardTags.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * @GET get Recent subjects by student record
     */
    getRecentSubjects: builder.query<
      RecentSubjectsResponse,
      {
        profileId?: number;
        page: number;
        perPage: number;
        // search: string;
        student_record?: number;
      }
    >({
      queryFn: async ({ profileId, page, perPage, student_record }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = profileId || currentProfile?.profileId;

          const { data } = await mainApi.get<RecentSubjectsResponse>(
            `/students/records/${student_record}/courses`,
            {
              params: {
                user_profile_id,
                page,
                size: perPage,
                // type_sort: 'asc',
                // sort: 'id',
                // search,
              },
            }
          );

          //   dispatch(setRolesList(data.data));

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'StatusSubjects', id } as const)),
              { type: 'StatusSubjects', id: 'LIST' },
            ]
          : [{ type: 'StatusSubjects', id: 'LIST' }],
    }),

    getRecentApplications: builder.query<
      RecentSubjectsResponse,
      {
        profileId?: number;
        page: number;
        perPage: number;
        // search: string;
        student_record?: number;
      }
    >({
      queryFn: async ({ profileId, page, perPage, student_record }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = profileId || currentProfile?.profileId;

          const { data } = await mainApi.get<RecentSubjectsResponse>(
            `/students/records/${student_record}/courses`,
            {
              params: {
                user_profile_id,
                page,
                size: perPage,
                // type_sort: 'asc',
                // sort: 'id',
                // search,
              },
            }
          );

          //   dispatch(setRolesList(data.data));

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'StatusSubjects', id } as const)),
              { type: 'StatusSubjects', id: 'LIST' },
            ]
          : [{ type: 'StatusSubjects', id: 'LIST' }],
    }),

    /**
     * @GET student activities paged
     */
    getStudentActivities: builder.query<
      ActivitiesResponse,
      {
        studentId: number;
        profileId?: number;
        isCalendar?: boolean;
        month?: number;
        year?: number;
        page: number;
        perPage: number;
        // search: string;
        student_record?: number;
      }
    >({
      queryFn: async (
        { profileId, page, perPage, studentId, isCalendar, month, year },
        { getState }
      ) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = profileId || currentProfile?.profileId;

          const { data } = await mainApi.get<ActivitiesResponse>(
            `students/${studentId}/news/activities`,
            {
              params: {
                user_profile_id,
                ...(isCalendar && { isCalendar: isCalendar }),
                ...(month && { month }),
                ...(year && { year }),
                page,
                size: perPage,
                // search,
              },
            }
          );

          //   dispatch(setRolesList(data.data));

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      // TODO: add tags for activities (invalidate tags when activities change needed)
      // providesTags: (result) =>
      //   result
      //     ? [
      //         ...result.data.map(
      //           ({ activity_id }) => ({ type: 'Activities', activity_id } as const)
      //         ),
      //         { type: 'Activities', id: 'LIST' },
      //       ]
      //     : [{ type: 'Activities', id: 'LIST' }],
    }),

    /**
     * @GET student activities not paged
     */
    getStudentActivitiesNotPaged: builder.query<
      Activity[],
      {
        studentId: number;
        profileId?: number;
        isCalendar?: boolean;
        month?: number;
        year?: number;
        student_record?: number;
      }
    >({
      queryFn: async ({ profileId, studentId, isCalendar, month, year }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = profileId || currentProfile?.profileId;

          const { data } = await mainApi.get<Activity[]>(`students/${studentId}/news/activities`, {
            params: {
              user_profile_id,
              ...(isCalendar && { isCalendar: isCalendar }),
              ...(month && { month }),
              ...(year && { year }),
              data: 'all',
              // search,
            },
          });

          //   dispatch(setRolesList(data.data));

          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
      // TODO: add tags for activities (invalidate tags when activities change needed)
      // providesTags: (result) =>
      //   result
      //     ? [
      //         ...result.data.map(
      //           ({ activity_id }) => ({ type: 'Activities', activity_id } as const)
      //         ),
      //         { type: 'Activities', id: 'LIST' },
      //       ]
      //     : [{ type: 'Activities', id: 'LIST' }],
    }),

    /**
     * @GET student activities not paged
     */
    getExternalLinks: builder.query<ExternalLink[], { profileId?: number }>({
      queryFn: async ({ profileId }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = profileId || currentProfile?.profileId;

          const { data } = await mainApi.get<ExternalLink[]>(`/external-link-configurations`, {
            params: {
              user_profile_id,
              data: 'all',
              // search,
            },
          });

          //   dispatch(setRolesList(data.data));

          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result, error, { profileId }) => [{ type: 'ExternalLinks', id: 'LIST' }],
    }),

    //
  }),
});

export const {
  useGetRecentSubjectsQuery,
  useGetRecentApplicationsQuery,
  useGetStudentActivitiesQuery,
  useGetStudentActivitiesNotPagedQuery,
  useGetExternalLinksQuery,
} = dashboardApi;
