import React from 'react';

// material-ui
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  InputAdornment,
  TextField,
  IconButton,
} from '@material-ui/core';

import { renderErrorOrEmptyRow, renderSkeletonRows } from '../../../../shared/helpers/render';

// helpers
import { isPrimitive, objectKeys } from '../../../../shared/helpers';

// types

import {
  CustomCellProperties,
  CustomRenderers,
  TableHeaders,
} from '../../../../shared/interfaces/material-ui.interfaces';

import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { SerializedError } from '@reduxjs/toolkit';
import { DebounceInput } from 'react-debounce-input';
import SearchIcon from '@material-ui/icons/Search';
import { IconX } from '@tabler/icons';
import CustomPaginationAndItems from '../../../../shared/components/tables/CustomPaginationAndItems';

interface Props<T extends {}> {
  headers: TableHeaders<T>;
  data: T[];
  keyExtractor: (item: T) => string;
  customRenderers?: CustomRenderers<T>;
  customDataCellsProperties?: CustomCellProperties<T>;
  customHeadersCellsProperties?: CustomCellProperties<T>;
  isLoading: boolean;
  isFetching: boolean;
  error: FetchBaseQueryError | SerializedError | undefined;
  perPage: number;
  setPerPage: React.Dispatch<React.SetStateAction<number>>;
  page: number;
  numHeader: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  searchPlacehoder?: string;
  searchField?: boolean;
}

const MatterDetailNoteTable = <T extends {}>({
  data,
  headers,
  customRenderers,
  keyExtractor,
  customDataCellsProperties,
  customHeadersCellsProperties,
  numHeader,
  isLoading,
  isFetching,
  error,
  perPage,
  setPerPage,
  page,
  setPage,
  search,
  setSearch,
  searchPlacehoder,
  searchField,
}: Props<T>) => {
  const obtainInitialSlice = function (_page: number, _perPage: number): number {
    if (_page > 1) {
      return _perPage * _page - _perPage;
    }
    return 0;
  };

  const renderRows = () => {
    const dataSlice = data!.slice(obtainInitialSlice(page, perPage), perPage * page);
    return <>{dataSlice!.map(renderRow)}</>;
  };

  const renderRow = (row: T) => {
    return (
      <TableRow className="table-row" key={keyExtractor(row)}>
        {objectKeys(row).map((cell) => {
          const customRenderer = customRenderers?.[cell];

          if (customRenderer) {
            return (
              <TableCell
                key={`${keyExtractor(row)}-${cell.toString()}`}
                {...customDataCellsProperties?.[cell]}
              >
                {customRenderer(row)}
              </TableCell>
            );
          }

          return (
            <TableCell
              key={`${keyExtractor(row)}-${cell.toString()}`}
              {...customDataCellsProperties?.[cell]}
            >
              {isPrimitive(row[cell]) ? row[cell] : ''}
            </TableCell>
          );
        })}
      </TableRow>
    );
  };

  const RenderSearch = function () {
    return (
      <Grid
        container
        justifyContent="right"
        alignItems="center"
        sx={{ marginBottom: 4 }}
        spacing={2}
      >
        <Grid item xs={12} sm={4}>
          <DebounceInput
            autoFocus={Boolean(search)}
            minLength={2}
            debounceTimeout={300}
            onChange={({ target }) => setSearch(target.value)}
            value={search}
            element={(props) => (
              <TextField
                style={{
                  width: '100%',
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon fontSize="small" />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        size="small"
                        onClick={() => setSearch('')}
                        {...(!search && { sx: { cursor: 'initial', opacity: 0 } })}
                      >
                        <IconX size={20} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                placeholder={searchPlacehoder}
                variant="outlined"
                size="small"
                {...props}
              />
            )}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      {searchField && <RenderSearch />}
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {Object.keys(headers).map((key) => (
                <TableCell key={key} {...customHeadersCellsProperties?.[key as keyof T]}>
                  {headers[key as keyof T]}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isFetching ? renderSkeletonRows(perPage, numHeader) : renderRows()}

            {error && renderErrorOrEmptyRow(numHeader, error as string)}
            {data.length === 0 && renderErrorOrEmptyRow(numHeader)}
          </TableBody>
        </Table>
      </TableContainer>

      <CustomPaginationAndItems
        error={error}
        isLoading={isLoading}
        total={data?.length || 0}
        perPage={perPage}
        page={page}
        setPage={setPage}
        setPerPage={setPerPage}
        perPageBox
        paginationBox
      />
    </>
  );
};

export default MatterDetailNoteTable;
