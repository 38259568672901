import { useParams } from 'react-router-dom';
import { useGetDebtorsStudentQuery } from '../../../students/subjects-register/slices/subjectsRegisterApiSlice';
import { useTranslation } from 'react-i18next';
import {
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  CircularProgress,
} from '@material-ui/core';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { setFinancialRepresentativeSelected } from '../slices/clientSlice';
import { useChangeDebtorByPersonIdMutation } from '../../debts-payment/slices/debtsPaymentApiSlice';

const DebtorTable = () => {
  const { studentId, personId } = useParams<{
    studentId: string;
    dni: string;
    personId: string;
  }>();

  const { t } = useTranslation();

  const { financialRepresentativeSelected } = useAppSelector((state) => state.client);

  const dispatch = useAppDispatch();

  const { data: AvailablesDebtors } = useGetDebtorsStudentQuery({
    studentId: Number(studentId!),
  });

  const [updateDebtor, { isLoading: updateDebtorLoading }] = useChangeDebtorByPersonIdMutation();

  const datafetch =
    AvailablesDebtors?.map((item, idx) => {
      return {
        id: idx + 1,
        dni: item.person.pers_full_name,
        name: item.person.pers_full_name,
        options: item,
      };
    }) || [];

  const handleUpdateDebtor = async (debtorId: number) => {
    await updateDebtor({
      debtor: debtorId,
      personId: Number(personId!),
    });
  };

  useEffect(() => {
    if (AvailablesDebtors && AvailablesDebtors.length === 1) {
      handleUpdateDebtor(AvailablesDebtors?.[0]!?.id);
      dispatch(setFinancialRepresentativeSelected(AvailablesDebtors?.[0]!?.id.toString()));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AvailablesDebtors]);

  return (
    <TableContainer component={Paper} sx={{ border: 1 }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead sx={{ backgroundColor: '#EFF7FF' }}>
          <TableRow>
            <TableCell align="center">{t('N°')}</TableCell>
            <TableCell align="center">{t('Identificación')}</TableCell>
            <TableCell align="center">{t('Nombre')}</TableCell>
            <TableCell align="center">{t('Seleccionar')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {datafetch?.map((data) => (
            <TableRow key={data.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell align="center">{data.id}</TableCell>
              <TableCell align="center">{data.dni}</TableCell>
              <TableCell align="center">{data.name}</TableCell>
              <TableCell align="center">
                {updateDebtorLoading &&
                financialRepresentativeSelected === data.options.id.toString() ? (
                  <CircularProgress size={20} />
                ) : (
                  <Checkbox
                    inputProps={{ 'aria-label': 'controlled' }}
                    checked={financialRepresentativeSelected === data.options.id.toString()}
                    onChange={() => {
                      if (datafetch.length > 1) {
                        handleUpdateDebtor(data.options.id);
                        dispatch(setFinancialRepresentativeSelected(data.options.id.toString()));
                      }
                    }}
                  />
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DebtorTable;
