import { Grid, Typography } from '@material-ui/core';
import MainCard from '../../../shared/components/cards/MainCard';
import BlackboardFilesTable from '../components/BlackboardFilesTable';

const BlackBoardFilesScreen = () => {
  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">Listado de archivos - Blackboard</Typography>
      </Grid>

      <BlackboardFilesTable />
    </Grid>
  );
};

export default BlackBoardFilesScreen;
