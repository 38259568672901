import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import { Box, Tab, Tabs } from '@material-ui/core';

import DebtsTab from './DebtsTab';
import ApprovedTransc from './ApprovedTransc';
import PendingTransc from './PendingTransc';
import RejectedTransc from './RejectedTransc';

// style constant
const useStyles = makeStyles((theme) => ({
  accountTab: {
    marginBottom: '24px',
    '& a': {
      minHeight: 'auto',
      minWidth: '10px',
      padding: '12px 8px',
      marginRight: '18px',
      color: theme.palette.grey[600],
    },
    '& a.Mui-selected': {
      color: theme.palette.primary.main,
    },
    '& a > span': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '& a > span > svg': {
      marginBottom: '0px !important',
      marginRight: '10px',
    },
    '& a > span > span + svg': {
      margin: '0px 0px 0px auto !important',
      width: '14px',
      height: '14px',
    },
  },
}));

// tabs
const TabPanel = (props: { children: React.ReactElement; value: number; index: number }) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={0}>{children}</Box>}
    </div>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const PaymentsTabs = () => {
  const classes = useStyles();

  const [value, setValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent | null, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }} mt={2}>
      <Tabs
        value={value}
        indicatorColor="primary"
        onChange={handleChange}
        className={classes.accountTab}
        aria-label="Tablas de pagos y deudas"
        variant="fullWidth"
      >
        <Tab
          sx={{
            textTransform: 'none',
          }}
          component={RouterLink}
          to="#"
          label="Deudas por pagar"
          aria-label="Deudas por pagar"
          {...a11yProps(0)}
          replace
        />
        <Tab
          sx={{
            textTransform: 'none',
          }}
          component={RouterLink}
          to="#"
          label="Transacciones pendientes"
          aria-label="Transacciones pendientes"
          {...a11yProps(1)}
          replace
        />
        <Tab
          sx={{
            textTransform: 'none',
          }}
          component={RouterLink}
          to="#"
          label="Transacciones aprobadas"
          aria-label="Transacciones aprobadas"
          {...a11yProps(2)}
          replace
        />
        <Tab
          sx={{
            textTransform: 'none',
          }}
          component={RouterLink}
          to="#"
          label="Transacciones rechazadas"
          aria-label="Transacciones rechazadas"
          {...a11yProps(3)}
          replace
        />
      </Tabs>
      <TabPanel value={value} index={0}>
        <DebtsTab />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <PendingTransc />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ApprovedTransc />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <RejectedTransc />
      </TabPanel>
    </Box>
  );
};

export default PaymentsTabs;
