import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import MainCard from '../../../shared/components/cards/MainCard';
// import AcademicHourhand from '../components/AcademicHourhand';
import ScheduleTable from '../components/ScheduleTable';
const AcademicHourhandsScreen = () => {
  const { t } = useTranslation();

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">{t('teachers.schedules.title')}</Typography>
      </Grid>
      {/* <AcademicHourhand />
       */}
      <ScheduleTable />
    </Grid>
  );
};

export default AcademicHourhandsScreen;
