import { useEffect, useMemo, useState, useCallback } from 'react';

import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  Grid,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import InputLabel from '../../../shared/components/forms/InputLabel';
import TextFieldFetching from '../../../shared/components/TextFieldFetching';
import {
  useGetAvailablesOffersQuery,
  useGetPeriodAcademicOfferingQuery,
} from '../../academic-offerings/slices/academicOfferingsApiSlice';
import {
  AcademicOffering,
  Period,
} from '../../academic-offerings/interfaces/academicOfferings.interfaces';
import {
  useAddCoursesToTeacherMutation,
  useLazyGetCollCoursesAvailablesQuery,
} from '../slices/teacherApiSlice';
import { useTableSearch } from '../../../../hooks/useTableSearch';
import { useAppSelector } from '../../../../hooks/useRedux';
import { updateTeachersCoursesTableStates } from '../slices/teachersSlice';
import SearchPaginatedTable from '../../../shared/components/tables/SearchPaginatedTable';
import IconTelework from '../../../shared/components/illustrations/IconTelework';
import AddButton from '../../../shared/components/tables/buttons/AddButton';
import { controlSelectionArr } from '../../../shared/helpers';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';

const availableStatus = [
  {
    label: 'Asignadas',
    value: 'assigned',
  },
  {
    label: 'Disponibles',
    value: 'available',
  },
];

const CoursesList = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { teacherId } = useParams<{ teacherId: string }>();

  const [offer, setOffer] = useState<AcademicOffering | null>(null);
  const [period, setPeriod] = useState<Period | null>(null);

  const [showAlerts, setShowAlerts] = useState(false);

  const [selected, setSelected] = useState<number[]>([]);

  const [availability, setAvailability] = useState('');

  const { page, perPage, search } = useAppSelector((state) => state.teacher?.teacherCourseTable);

  const { setPage, setPerPage, setSearch } = useTableSearch({
    page,
    perPage,
    search,
    updateFunction: updateTeachersCoursesTableStates,
  });

  //niveles eduactivos
  const { data: offersAvailables, isFetching: isFetchingPID } = useGetAvailablesOffersQuery({});

  //periodos
  const { data: periodsByOffer, isFetching: isFetchingCID } = useGetPeriodAcademicOfferingQuery(
    {
      academicOfferingId: offer?.id?.toString() || '',
      current: true,
    },
    { skip: !offer }
  );

  useEffect(() => {
    if (offersAvailables) setOffer(offersAvailables?.length === 1 ? offersAvailables[0] : null);
  }, [offersAvailables]);
  useEffect(() => {
    if (periodsByOffer)
      setPeriod(
        (periodsByOffer as unknown as Period[])?.length === 1
          ? (periodsByOffer as unknown as Period[])[0]
          : null
      );
  }, [periodsByOffer]);

  const [getCourses, { data: courses, isLoading, isFetching, error, isUninitialized }] =
    useLazyGetCollCoursesAvailablesQuery();

  const [addCourses, { isLoading: isLoadingCo, error: errorCo, isError, isSuccess }] =
    useAddCoursesToTeacherMutation();

  const datafetch = useMemo(
    () =>
      courses?.data.map((dt) => ({
        id: dt.id,
        courseName: dt.mat_name,
        parallel: `${dt.cl_name} - ${dt.par_name}`,
        campus: dt.cam_name,
        hourhand: dt.hour_description,
      })),
    [courses?.data]
  );

  const handleGetCourses = useCallback(() => {
    try {
      getCourses(
        {
          collId: +teacherId,
          offer: offer?.id!,
          period: period?.id!,
          page,
          perPage,
          search,
          ...(availability && { availableStatus: availability }),
        },
        true
      );
    } catch (error) {
      console.log(error);
    }
  }, [getCourses, offer?.id, page, perPage, period?.id, search, teacherId, availability]);

  //control de checkboxs

  // resetea la seleccion al cambiar de estado de la data en la tabla
  useEffect(() => {
    setSelected([]);
  }, [perPage, search]);

  // Refetch de la peticion
  useEffect(() => {
    if (!isUninitialized && offer && period) handleGetCourses();
  }, [handleGetCourses, isUninitialized, offer, page, perPage, period, search]);

  //check por fila
  const handleClick = (event: React.ChangeEvent<HTMLInputElement>, cashPointId: number) => {
    const newSelected = controlSelectionArr(selected, cashPointId);

    setSelected(newSelected);
  };

  //check all
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelectedId =
        courses?.data
          .filter((dt) => !dt.collaborator && !selected.includes(dt.id))
          .map((n) => +n.id) || [];
      setSelected((prevState) => [...prevState, ...newSelectedId]);
      return;
    }

    // si ya esta el select all y solo se quiere quitar la seleccion de la pagina actual
    const mappedData = courses?.data.map((n) => +n.id) || [];
    const newSelection = selected.filter((sel) => !mappedData.some((mp) => mp === sel));

    setSelected(newSelection);
  };

  const availableCourses = useMemo(
    () => courses?.data.filter((dt) => !dt.collaborator),
    [courses?.data]
  );
  const alreadySelected = useMemo(
    () => courses?.data.filter((dt) => dt.collaborator?.id === +teacherId),
    [courses?.data, teacherId]
  );

  const handleAssignCourses = async () => {
    await addCourses({
      collaboratorId: +teacherId,
      courses: selected,
    });

    setShowAlerts(true);
    setSelected([]);
  };

  // console.log(availableCourses?.every((dt) => selected.some((sel) => sel === dt.id)));

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <InputLabel>{t('academic.courses.edu_level')}:</InputLabel>
                {isFetchingPID ? (
                  <TextFieldFetching />
                ) : (
                  <Autocomplete
                    options={offersAvailables || []}
                    noOptionsText={t('academic.courses.edu_level.no_data')}
                    getOptionLabel={(option) => option.off_name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={t('academic.courses.edu_level.placeholder')}
                      />
                    )}
                    value={offer}
                    onChange={(_, value) => {
                      setOffer(value || null);
                    }}
                    fullWidth
                    clearIcon={null}
                  />
                )}
              </Grid>

              <Grid item xs={4}>
                <InputLabel>{t('academic.courses.period')}:</InputLabel>
                {isFetchingCID ? (
                  <TextFieldFetching />
                ) : (
                  <Autocomplete
                    options={(periodsByOffer as unknown as Period[]) || []}
                    noOptionsText={t('academic.courses.period.no_data')}
                    disabled={!offer}
                    getOptionLabel={(option) => option.per_name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={t('academic.courses.period.placeholder')}
                      />
                    )}
                    value={period}
                    onChange={(_, value) => {
                      setPeriod(value || null);
                    }}
                    fullWidth
                    clearIcon={null}
                  />
                )}
              </Grid>

              <Grid item xs={12} md={4}>
                <InputLabel>Estado:</InputLabel>
                <Autocomplete
                  options={availableStatus || []}
                  getOptionLabel={(option) => option.label}
                  value={availableStatus?.find((st) => st.value === availability) || null}
                  onChange={(e, value) => setAvailability(value?.value || '')}
                  disabled={!offer || !period}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      fullWidth
                      placeholder="Selecciona un estado"
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container item justifyContent="flex-end">
              <Button
                variant={isUninitialized ? 'contained' : 'outlined'}
                disabled={!period}
                size="large"
                color="primary"
                onClick={handleGetCourses}
                endIcon={isLoading && <CircularProgress size={24} />}
              >
                {t('academic.teachers_list.courses.filters.search_btn')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        {isError && showAlerts && (
          <ErrorAlert message={errorCo as string} handleDismiss={() => setShowAlerts(false)} />
        )}

        {isSuccess && showAlerts && (
          <SuccessAlert
            message={t('academic.teachers_list.courses.success')}
            handleDismiss={() => setShowAlerts(false)}
          />
        )}
      </Grid>

      <Grid item xs={12}>
        {isUninitialized ? (
          <>
            <Grid container justifyContent={'center'} alignContent={'center'} mb={2}>
              <Grid container justifyContent={'center'} item xs={12} md={10} lg={8}>
                <Grid container item xs={12} md={5} justifyContent={'center'}>
                  <IconTelework size={300} />
                </Grid>
                <Grid container item xs={12} md={7} justifyContent={'center'} alignItems="center">
                  <Typography
                    variant={'body1'}
                    lineHeight={2}
                    component={'div'}
                    sx={{
                      textAlign: 'center',
                      [theme.breakpoints.up('md')]: {
                        textAlign: 'left',
                      },
                    }}
                  >
                    {t('academic.teachers_list.courses.filters.no_data.title')}
                    <Typography
                      variant={'h4'}
                      sx={{ color: theme.palette.primary.main }}
                      component={'p'}
                      lineHeight={2}
                    >
                      {t('academic.teachers_list.courses.filters.no_data.subtitle')}
                    </Typography>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </>
        ) : (
          <SearchPaginatedTable
            data={datafetch!}
            headers={{
              // index: '#',
              id: (
                <Checkbox
                  color="primary"
                  disabled={!availableCourses?.length || isLoadingCo}
                  indeterminate={
                    !!courses?.data &&
                    !!selected.length &&
                    selected.filter((sel) => availableCourses?.some((dt) => dt.id === sel)).length <
                      (availableCourses?.length || 0)
                  }
                  checked={
                    !!courses?.data &&
                    !!selected.length &&
                    !!availableCourses?.length &&
                    !!availableCourses?.every((dt) => selected.some((sel) => sel === dt.id)) &&
                    selected.length >= (availableCourses?.length || 0)
                  }
                  onChange={handleSelectAllClick}
                />
              ) as any,
              courseName: t('academic.teachers_list.courses.table.course_name'),
              parallel: t('academic.teachers_list.courses.table.parallel_name'),
              campus: t('academic.teachers_list.courses.table.campus'),
              hourhand: t('academic.teachers_list.courses.table.hourhand'),
            }}
            keyExtractor={(item) => item.id.toString()}
            searchPlacehoder={t('academic.areas.search')}
            isLoading={isLoading}
            isFetching={isFetching}
            error={error}
            perPage={perPage!}
            setPerPage={setPerPage}
            total={courses?.total || 0}
            page={page!}
            setPage={setPage}
            numHeader={4}
            search={search!}
            setSearch={setSearch}
            customRenderers={{
              id: (row) => (
                <>
                  <Checkbox
                    checked={
                      selected.some((r) => r === Number(row.id)) ||
                      alreadySelected?.some(
                        (dt) =>
                          dt.id === Number(row.id) && dt.collaborator?.id === Number(teacherId)
                      )
                    }
                    onChange={(e) => handleClick(e, Number(row.id))}
                    disabled={
                      !availableCourses?.length ||
                      alreadySelected?.some(
                        (dt) =>
                          dt.id === Number(row.id) && dt.collaborator?.id === Number(teacherId)
                      ) ||
                      isLoadingCo
                    }
                  />
                </>
              ),
              // statusId: (row) => <StatusChip statusId={Number(row.statusId)} />,
              // options: (row) => (
              //   <>
              //     {hasAccess('edit') && (
              //       <Link to={`${path}/${row.options.id}/editar`}>
              //         <EditButton />
              //       </Link>
              //     )}
              //     {hasAccess('delete') && (
              //       <DeleteButton handleDelete={() => handleOpenModalWith(row.options.id)} />
              //     )}
              //     {!hasAccess('edit') && !hasAccess('delete') && t('academic.areas.none')}
              //   </>
              // ),
            }}
            customDataCellsProperties={{
              id: {
                align: 'center',
              },

              hourhand: {
                align: 'center',
              },
              campus: {
                align: 'center',
              },
            }}
            customHeadersCellsProperties={{
              id: {
                align: 'center',
              },
            }}
            ActionButtons={
              <>
                {
                  <AddButton
                    btnText={t('academic.teachers_list.courses.assign_btn')}
                    size="large"
                    disabled={isLoadingCo || !selected.length}
                    endIcon={isLoadingCo && <CircularProgress size={20} />}
                    onClick={handleAssignCourses}
                  />
                }
              </>
            }
          />
        )}
      </Grid>
    </Grid>
  );
};

export default CoursesList;
