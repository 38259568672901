import { Grid, IconButton, Typography } from '@material-ui/core';
import { IconArrowNarrowLeft } from '@tabler/icons';
import { useHistory } from 'react-router-dom';
import MainCard from '../../../shared/components/cards/MainCard';
import RegisterStudent from '../components/RegisterStudent';

const AddRegisterNivelationScreen = () => {
  const history = useHistory();

  const handleBack = () => {
    history.goBack();
  };

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid item container direction="row" alignItems="center" sx={{ mb: 3 }}>
        <IconButton onClick={handleBack}>
          <IconArrowNarrowLeft size={30} />
        </IconButton>
        <Typography variant="h2">Registrar Estudiante</Typography>
      </Grid>
      <RegisterStudent />
    </Grid>
  );
};

export default AddRegisterNivelationScreen;
