import { middlewareApi } from '../../../middleware/middlewareApi';
import { mainApi } from '../../../../api/mainApi';
import { StudenstNivelationCoursesResponse } from '../interfaces/studentNivelationCourses.interface';
import { CarrerasResponse } from '../interfaces/careers.interface';
export const trackingNivelationTags = middlewareApi.enhanceEndpoints({
  addTagTypes: ['TrackingStudentsNivelation'],
});

export const trackingNivelationApi = trackingNivelationTags.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * @GET all students nivelation
     */
    getStudentsNivelationCourses: builder.query<
      StudenstNivelationCoursesResponse,
      {
        profileId: number;
        educationLevel?: number;
        periodId?: number;
        groupAreaSubject?: number;
        search?: string;
        sort?: string;
        type_sort?: string;
        page: number;
        size: number;
      }
    >({
      queryFn: async (
        {
          profileId,
          educationLevel,
          periodId,
          groupAreaSubject,
          search,
          sort,
          type_sort,
          page,
          size,
        },
        { dispatch }
      ) => {
        try {
          const { data } = await mainApi.get<StudenstNivelationCoursesResponse>(
            `/nivelations/group-area/${groupAreaSubject}/courses-students`,
            {
              params: {
                user_profile_id: profileId,
                education_level_id: educationLevel,
                period_id: periodId,
                groupAreaSubject: groupAreaSubject,
                search,
                page,
                size,
                ...(sort && { sort }),
                ...(type_sort && { type_sort }),
              },
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),
    /**
     * @GET all students nivelation
     */
    getCareersByGroupId: builder.query<
      CarrerasResponse,
      { profileId: number; groupAreaSubject?: number }
    >({
      queryFn: async ({ profileId, groupAreaSubject }, { dispatch }) => {
        try {
          const { data } = await mainApi.get<CarrerasResponse>(
            `/group-areas/${groupAreaSubject}/education-levels`,
            {
              params: {
                user_profile_id: profileId,
                id: groupAreaSubject,
              },
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),
  }),
});

export const { useGetStudentsNivelationCoursesQuery, useGetCareersByGroupIdQuery } =
  trackingNivelationApi;
