import { Switch, Redirect } from 'react-router-dom';

import { useAppSelector } from '../../../../hooks/useRedux';

import ELSScreen from '../screens/ELSScreen';
import AddELSScreen from '../screens/AddELSScreen';
import EditELSScreen from '../screens/EditELSScreen';

import ELSGroupScreen from '../screens/ELSGroupScreen';
import AddELSGroupScreen from '../screens/AddELSGroupScreen';
import EditELSGroupScreen from '../screens/EditELSGroupScreen';
import GuardRoute from '../../../../router/guards/GuardRoute';

const ELSRouter = () => {
  const { currentModule } = useAppSelector((state) => state.access);

  const hasPermission = 'matters' in currentModule?.permissions!;

  if (!hasPermission) return <Redirect to="/dashboard" />;

  return (
    <Switch>
      <GuardRoute
        exact
        path="/administrar-carreras-y-materias-de-nivelacion"
        component={ELSScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.read}
      />
      <GuardRoute
        exact
        path="/administrar-carreras-y-materias-de-nivelacion/agregar"
        component={AddELSScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.read}
      />
      <GuardRoute
        exact
        path="/administrar-carreras-y-materias-de-nivelacion/:ELSId/editar"
        component={EditELSScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.read}
      />

      {/* Group */}
      <GuardRoute
        exact
        path="/administrar-carreras-y-materias-de-nivelacion/:groupId/materias"
        component={ELSGroupScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.read}
      />
      <GuardRoute
        exact
        path="/administrar-carreras-y-materias-de-nivelacion/:groupId/materias/agregar"
        component={AddELSGroupScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.read}
      />
      <GuardRoute
        exact
        path="/administrar-carreras-y-materias-de-nivelacion/:groupId/materias/:ELSId/editar"
        component={EditELSGroupScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.read}
      />
    </Switch>
  );
};

export default ELSRouter;
