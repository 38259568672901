import { forwardRef } from 'react';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import {
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Slide,
  SlideProps,
  Typography,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import { IconArrowNarrowLeft } from '@tabler/icons';

import EditDepositTransferForm from './EditDepositTransferForm';
import ExtendedSideDialog from '../../../shared/components/dialogs/ExtendedSideDialog';
import ViewDepositTransfer from './ViewDepositTransfer';
import PermissionsCheck from '../../../shared/components/PermissionCheck';
import permissions from '../constants/permissions';

//styles
const useStyles = makeStyles((theme) => ({
  userAddDialog: {
    '&>div:nth-child(3)': {
      justifyContent: 'flex-end',
      '&>div': {
        margin: '0px',
        borderRadius: '0px',
        maxWidth: '900px',
        maxHeight: '100%',
        '&>div.MuiDialogContent-root>div.scrollbar-container': {
          paddingLeft: '20px',
          overflow: 'hidden',
        },
      },
    },
  },
  paper: {
    minHeight: '100vh',
    width: '37%',
  },
  paperTable: {
    minHeight: '100vh',
    // width: '50%!important',
  },
  movilPaper: {
    minHeight: '100vh',
    width: '100%',
  },
}));

// animation
const Transition = forwardRef(function Transition(props: SlideProps, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

interface Props {
  open: boolean;
  handleCloseDialog: (e: React.SyntheticEvent) => void;
}

const EditDepositTransferDialog = ({ open, handleCloseDialog }: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  // const dispatch = useDispatch();

  const isSm = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <ExtendedSideDialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCloseDialog}
      className={classes.userAddDialog}
      {...(!isSm && { classes: { paper: classes.paper } })}
      {...(!isSm && { classes: { paper: classes.paperTable } })}
      {...(isSm && { classes: { paper: classes.movilPaper } })}
    >
      <DialogTitle disableTypography>
        <Grid item container alignItems="center" gap={1}>
          <IconButton onClick={handleCloseDialog}>
            <IconArrowNarrowLeft size={30} color={theme.palette.text.dark} />
          </IconButton>
          <Typography variant="h3">Editar</Typography>
        </Grid>
      </DialogTitle>
      <DialogContent>
        {open && (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <ViewDepositTransfer />
            </Grid>
            <Grid item xs={6}>
              <PermissionsCheck
                permissions={[
                  permissions.cashCollection.updateInfoTransferOrDeposit,
                  permissions.financialInstitutions.read,
                  permissions.financialInstitutions.getFinancialInstituteChildren,
                  permissions.catalog.getChildrenCatalogByAcro,
                ]}
              >
                <EditDepositTransferForm />
              </PermissionsCheck>
            </Grid>
          </Grid>
        )}
      </DialogContent>
    </ExtendedSideDialog>
  );
};

export default EditDepositTransferDialog;
