import { useMemo } from 'react';
import { useAppSelector } from '../../../../hooks/useRedux';

const useGetDayTypes = () => {
  const { colorEvents } = useAppSelector((state) => state.academicPlanningPosgraduate);

  const dayTypes = useMemo(
    () => ({
      firstDay: colorEvents?.find((event) => event.pdt_keywork === 'IDA'),
      holiday: colorEvents?.find((event) => event.pdt_keywork === 'FDS'),
      firstWeek: colorEvents?.find((event) => event.pdt_keywork === 'PAU'),
      titleWorkshop: colorEvents?.find((event) => event.pdt_keywork === 'TST'),
      selfEmployment: colorEvents?.find((event) => event.pdt_keywork === 'TAU'),
      startProgram: colorEvents?.find((event) => event.pdt_keywork === 'IPA'),
      initialPlanningPoint: colorEvents?.find((event) => event.pdt_keywork === 'PIP'),
      presencialClasses: colorEvents?.find((event) => event.pdt_keywork === 'CPR'),
      onlineClasses: colorEvents?.find((event) => event.pdt_keywork === 'CEL'),
    }),
    [colorEvents]
  );

  return dayTypes;
};

export default useGetDayTypes;
