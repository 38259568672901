import { Button, Grid, Typography, useTheme } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import { getFixedTime } from '../../../../auth/helpers';
import { Schedule } from '../../interfaces/dashboard.interfaces';

const Schedules = ({ schedule }: { schedule: Schedule }) => {
  const theme = useTheme();

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      px={2}
      py={4}
      sx={{
        backgroundColor: theme.palette.primary.main,
        borderRadius: '12px',
        height: '100%',
      }}
      spacing={2}
      ml={0}
      mt={0}
    >
      <Grid item>
        <Info
          sx={{
            fontSize: '70px',
            color: 'white',
          }}
        />
      </Grid>
      <Grid item>
        <Typography variant="h4" color="white">
          Horarios de atención
        </Typography>
        <Typography variant="body1" color="white" mt={2}>
          Lunes a viernes
        </Typography>
        <Typography variant="h5" color="white">
          {getFixedTime(schedule?.hour_start_time_monday)} -{' '}
          {getFixedTime(schedule?.hour_end_time_monday)}
        </Typography>
        <Typography variant="body1" color="white" mt={2}>
          Sábados
        </Typography>
        <Typography variant="h5" color="white">
          {getFixedTime(schedule?.hour_start_time_saturday)} -{' '}
          {getFixedTime(schedule?.hour_end_time_saturday)}
        </Typography>
      </Grid>
      <Grid item xs={12} md={8} mt={2}>
        <Button
          fullWidth
          variant="contained"
          color="secondary"
          onClick={() => (window.location = `mailto:` as any)}
        >
          Contactar
        </Button>
      </Grid>
    </Grid>
  );
};

export default Schedules;
