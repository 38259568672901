import {
  Autocomplete,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppSelector } from '../../../../hooks/useRedux';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import { statusAvailables } from '../../../shared/constants/resourceStatus';
import { PensumInfoSchema } from '../constants/validationSchemas';
import { useAddPensumMutation } from '../slices/pensumsApiSlice';

const AddPensumForm = () => {
  const history = useHistory();

  const { currentProfile } = useAppSelector((state) => state.access);

  const [showAlerts, setShowAlerts] = useState(true);

  const [addPensum, { isLoading, isError, error, isSuccess }] = useAddPensumMutation();

  const { getFieldProps, errors, touched, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      name: '',
      description: '',
      acronym: '',
      anio: new Date().getFullYear(),
      statusId: 1,
    },
    validationSchema: PensumInfoSchema,
    onSubmit: async (values) => {
      await addPensum({
        profileId: currentProfile?.profileId!,
        pensumPayload: {
          pen_name: values.name,
          pen_description: values.description,
          pen_acronym: values.acronym,
          anio: values.anio.toString(),
          status_id: values.statusId.toString(),
        },
      });

      setShowAlerts(true);
    },
  });
  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction="column" gap={2}>
        <Grid item>
          {isError && showAlerts && (
            <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
          )}

          {isSuccess && showAlerts && (
            <SuccessAlert
              message="El pensum se ha agregado correctamente."
              handleDismiss={() => setShowAlerts(false)}
            />
          )}
        </Grid>

        <Grid item container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <InputLabel>Nombre</InputLabel>
            <TextField
              fullWidth
              error={Boolean(errors.name && touched.name)}
              placeholder="Ingrese el nombre del pensum"
              {...getFieldProps('name')}
            />
            {errors.name && touched.name && <FormHelperText error>{errors.name}</FormHelperText>}
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Descripción</InputLabel>
            <TextField
              multiline
              rows={2}
              fullWidth
              error={Boolean(errors.description && touched.description)}
              placeholder="Ingrese una descripción para el pensum"
              {...getFieldProps('description')}
            />
            {errors.description && touched.description && (
              <FormHelperText error>{errors.description}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} container spacing={2}>
            <Grid item xs={6}>
              <InputLabel>Acrónimo</InputLabel>
              <TextField
                type="text"
                fullWidth
                placeholder="Ingrese un acrónimo"
                {...getFieldProps('acronym')}
                error={Boolean(errors.acronym && touched.acronym)}
              />
              {errors.acronym && touched.acronym && (
                <FormHelperText error>{errors.acronym}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={6}>
              <InputLabel>Año</InputLabel>
              <TextField
                type="number"
                fullWidth
                placeholder="Ingrese el año"
                {...getFieldProps('anio')}
                error={Boolean(errors.anio && touched.anio)}
              />
              {errors.anio && touched.anio && <FormHelperText error>{errors.anio}</FormHelperText>}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Estado</InputLabel>
            <Autocomplete
              options={statusAvailables}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField {...params} error={Boolean(errors.statusId && touched.statusId)} />
              )}
              defaultValue={statusAvailables[0]}
              onChange={(e, value) => setFieldValue('statusId', value?.id || '')}
              fullWidth
              clearIcon={null}
            />
            {errors.statusId && touched.statusId && (
              <FormHelperText error>{errors.statusId}</FormHelperText>
            )}
          </Grid>
        </Grid>

        <Grid
          item
          container
          alignItems="center"
          justifyContent="flex-end"
          spacing={2}
          sx={{ mt: 1 }}
        >
          <Grid item>
            <Button
              variant="outlined"
              size="large"
              color="primary"
              onClick={() => history.goBack()}
            >
              Regresar
            </Button>
          </Grid>
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              disabled={isLoading}
              endIcon={isLoading && <CircularProgress size={20} />}
            >
              Agregar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default AddPensumForm;
