import login from './login.json';
import recoverPassword from './recoverPassword.json';
import errorPassword from './errorPassword.json';
import resetPassword from './resetPassword.json';

const authTranslations = {
  ...login,
  ...recoverPassword,
  ...errorPassword,
  ...resetPassword,
};

export default authTranslations;
