import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../../../../hooks/useRedux';
import CustomSideDialog from '../../../../../../shared/components/dialogs/CustomSideDialog';
import { setOpenAddGroupsUser } from '../../slices/groupsUsersSlice';
import AddGroupsUsersForm from './AddGroupsUsersForm';

const AddGroupsUsersSideDialog = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  // const { openAddGroupsUser } = useAppSelector((state) => state.groupsUser);

  const handleClose = () => {
    dispatch(setOpenAddGroupsUser(false));
  };

  return (
    <CustomSideDialog open handleCloseDialog={handleClose} title={t('Agregar grupo')}>
      <AddGroupsUsersForm />
    </CustomSideDialog>
  );
};

export default AddGroupsUsersSideDialog;
