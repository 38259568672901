import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISearchTable } from '../../../shared/interfaces/paginatedTable.interface';
import { Faq } from '../interfaces/faqs-list.interfaces';

interface FAQState {
  faqList: Faq[];
  faqDelete: boolean;
  faqCurrent?: number;
  faqs: boolean;
  faqsAdmin: boolean;
  faqDetail?: Faq;
  faqsTable: ISearchTable;
}

const initialState: FAQState = {
  faqList: [],
  faqsAdmin: false,
  faqDelete: false,
  faqs: false,
  faqsTable: {
    page: 1,
    perPage: 5,
    search: '',
    filterArrow: {
      name: 'id',
      direction: 'asc',
    },
  },
};

const faqSlice = createSlice({
  name: 'faq',
  initialState,
  reducers: {
    setFaqList: (state, action: PayloadAction<Faq[]>) => {
      state.faqList = action.payload;
    },
    setFaqs: (state, action: PayloadAction<boolean>) => {
      state.faqs = action.payload;
    },
    setFaqDetail: (state, action: PayloadAction<Faq>) => {
      state.faqDetail = action.payload;
    },
    setFaqsAdmin: (state, action: PayloadAction<boolean>) => {
      state.faqsAdmin = action.payload;
    },
    setFaqDelete: (state, action: PayloadAction<number>) => {
      state.faqDelete = true;
      state.faqCurrent = action.payload;
    },
    resetFaqDelete: (state) => {
      state.faqCurrent = undefined;
      state.faqDelete = false;
    },
    updateFaqsTable: (state, action: PayloadAction<ISearchTable>) => {
      state.faqsTable = action.payload;
    },
  },
});

export const {
  setFaqDetail,
  setFaqsAdmin,
  setFaqList,
  setFaqs,
  setFaqDelete,
  resetFaqDelete,
  updateFaqsTable,
} = faqSlice.actions;

export default faqSlice.reducer;
