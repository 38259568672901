import { FormHelperText, Grid, TextField, Typography } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { useState } from 'react';
import { PeriodForm } from '../../../interfaces/period-form.interfaces';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import esLocale from 'date-fns/locale/es';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import DateRangePicker, { DateRange } from '@material-ui/lab/DateRangePicker';
import { SOLICITUD_APERTURA_MATERIAS_GRADO } from '../../../constants/stages';
import { useTranslation } from 'react-i18next';

const RequestMatterGrade = () => {
  const { t } = useTranslation();

  const { values, errors, touched, setFieldValue } = useFormikContext<PeriodForm>();

  const [date, setDate] = useState<DateRange<Date>>([
    values.request_matter_grade.start_date!,
    values.request_matter_grade.end_date!,
  ]);

  const onChangeDates = (newDatesValues: DateRange<Date>) => {
    if (values.request_matter_grade.stg_acronym === '') {
      const object = {
        course_id: null,
        education_level_id: null,
        parallel_id: null,
        status_id: 1,
        start_date: null,
        end_date: null,
        stg_acronym: SOLICITUD_APERTURA_MATERIAS_GRADO,
      };
      setFieldValue('request_matter_grade', object);
    }

    const [start, end] = newDatesValues;

    if (start !== null) {
      setFieldValue('request_matter_grade.start_date', start);
    }
    if (end !== null) {
      setFieldValue('request_matter_grade.end_date', end);
    }
    setDate(newDatesValues);
  };

  return (
    <Grid container spacing={4} justifyContent="center" alignItems="flex-start">
      <Grid item xs={12} md={12} container spacing={2}>
        <Grid item xs={12} marginTop={2}>
          <Typography variant="h3" component="div">
            {t('academic.periods_list.add.stage_3.tab_5.title')}
          </Typography>
          <Grid item xs={12} sx={{ mt: 5 }}>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
              <DateRangePicker
                startText={null}
                endText={null}
                value={date}
                onChange={onChangeDates}
                renderInput={(startProps, endProps) => (
                  <Grid container spacing={10} alignItems="center">
                    <Grid item xs={12} md={6} lg={6}>
                      <TextField
                        fullWidth
                        {...startProps}
                        helperText=""
                        error={Boolean(
                          errors.request_matter_grade?.start_date &&
                            touched.request_matter_grade?.start_date
                        )}
                        disabled={values?.disabled}
                      />
                      <FormHelperText>
                        {t('academic.periods_list.add.stage_3.tab_5.start.helper')}
                      </FormHelperText>

                      {errors.request_matter_grade?.start_date &&
                        touched.request_matter_grade?.start_date && (
                          <FormHelperText error>
                            {errors.request_matter_grade?.start_date}
                          </FormHelperText>
                        )}
                    </Grid>

                    <Grid item xs={12} md={6} lg={6}>
                      <TextField
                        fullWidth
                        {...endProps}
                        helperText=""
                        error={Boolean(
                          errors.request_matter_grade?.end_date &&
                            touched.request_matter_grade?.end_date
                        )}
                        disabled={values?.disabled}
                      />
                      <FormHelperText>
                        {t('academic.periods_list.add.stage_3.tab_5.end.helper')}
                      </FormHelperText>
                      {errors.request_matter_grade?.end_date &&
                        touched.request_matter_grade?.end_date && (
                          <FormHelperText error>
                            {errors.request_matter_grade?.end_date}
                          </FormHelperText>
                        )}
                    </Grid>
                  </Grid>
                )}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RequestMatterGrade;
