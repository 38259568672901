import { useEffect, useMemo, useState } from 'react';

// import { useHistory, useLocation } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';
// import { IconArrowNarrowLeft } from '@tabler/icons';

import { useTranslation } from 'react-i18next';

// import MainCardForm from '../../../shared/components/cards/MainCardForm';
// import Checkout from '../components/Checkout';

// import CheckoutWizard from '../components/CheckoutWizard';
// import BillingData from '../components/BillingData';
// import BillingSideDialog from '../components/BillingSideDialog';
// import { useGetStudentBillingDataQuery } from '../slices/paymentsApiSlice';
import { useAppSelector } from '../../../../hooks/useRedux';
import { useLazyGetAvailableStudentDebtsQuery } from '../../../students/payments-history/slices/paymentsApiSlice';
import DebtsPaymentsWizard from '../components/DebtsPaymentsWizard';
import ClientsSideDialog from '../components/ClientsSideDialog';
import { useGetPersonsByParamQuery } from '../../../shared/slices/searchPersonApi';
import { setCurrentClient, setDebtsList } from '../slices/debtsPaymentSlice';
import { useAppDispatch } from '../../../../hooks/useRedux';

const DebtsPaymentsScreen = () => {
  //   const theme = useTheme();
  // const history = useHistory();
  // const location = useLocation();
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const { currentProfile } = useAppSelector((state) => state.access);
  const { currentClient, currentStudentRecord } = useAppSelector((state) => state.debtsPayment);

  const [activeStep, setActiveStep] = useState(0);
  const [errorIndex, setErrorIndex] = useState<number | null>(null);

  const handleSetError = (i: number | null) => {
    setErrorIndex(i);
  };

  const handleActiveStep = (step: number) => {
    setActiveStep(step);
  };

  const handleNext = () => {
    setActiveStep(activeStep + 1);
    setErrorIndex(null);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  //side dialog
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //student id for table
  const [clientId, setClientId] = useState<number>();
  const [clientInfoPage, setClientInfoPage] = useState(1);

  // search state
  const [searchClientCI, setSearchClientCI] = useState<string>();

  const handleSetClientCI = (ci: string) => {
    setSearchClientCI(ci);
  };
  //

  //client data
  // TODO: mover la lista de clientes al componente de la tabla de clientes
  const {
    data: clientInfo,
    // refetch: clientInfoRefetch,
    isFetching: isFetchingCI,
    error: errorCI,
    // refetch,
  } = useGetPersonsByParamQuery(
    {
      profileId: currentProfile?.profileId!,
      is_student: true,
      page: clientInfoPage,
      ...(searchClientCI && { ci: searchClientCI }),
    },
    { skip: !searchClientCI }
  );

  //student debts data
  const [
    triggerStudentDebts,
    {
      data: studentDebts,
      isFetching: isFetchingStudentDebts,
      error: errorStudentDebts,
      // refetch,
    },
  ] = useLazyGetAvailableStudentDebtsQuery();

  //selected client data
  const selectedClientData = useMemo(() => {
    const current = clientInfo?.data.find((client) => client.id === clientId);

    try {
      //trigger de la peticion en cada seleccin de usuario
      //TODO: refactorizar el metodo de peticion de las deudas para eviar el triple trigger #Ej: generacion de deudas
      if (current && currentStudentRecord) {
        triggerStudentDebts({
          studentId: current?.user.student?.id!,
          ...(currentStudentRecord && { student_record: currentStudentRecord.id }),
        });
      }
    } catch (error) {}
    //cambio a envio por redux

    return current;
  }, [clientId, clientInfo?.data, currentStudentRecord, triggerStudentDebts]);

  useEffect(() => {
    selectedClientData && dispatch(setCurrentClient(selectedClientData));
  }, [dispatch, selectedClientData]);

  // {
  //   studentId: currentClient?.user.student?.id!,
  // }

  // const [
  //   debtsTrigger,
  //   {
  //     data: studentDebtsLazy,
  //     isFetching: isFetchingStudentDebtsLazy,
  //     error: errorStudentDebtsLazy,
  //   },
  // ] = useLazyGetAvailableStudentDebtsQuery();

  //refetch list on client change
  // useEffect(() => {
  //   let mount = true;
  //   if (selectedClientData?.user.student?.id!) {
  //     if (mount) refetch();
  //   }
  //   return () => {
  //     mount = false;
  //   };
  // }, [refetch, selectedClientData?.user.student?.id]);

  //set debts list to redux
  useEffect(() => {
    if (currentClient) {
      dispatch(
        setDebtsList({
          data: studentDebts,
          isFetching: isFetchingStudentDebts,
          error: errorStudentDebts,
        })
      );
    }
  }, [dispatch, errorStudentDebts, isFetchingStudentDebts, studentDebts, currentClient]);
  // carga la peticion al abrir la pantalla (para mas comodidad si ya hay data en el currentClient luego de haberlo seleccionado y haber salido de la pantalla)
  useEffect(() => {
    let mount = true;
    if (currentClient && mount) {
      //TODO: refactorizar el metodo de peticion de las deudas para eviar el triple trigger #Ej: generacion de deudas
      triggerStudentDebts({
        studentId: currentClient?.user.student?.id!,
        ...(currentStudentRecord && { student_record: currentStudentRecord.id }),
      });
    }
    return () => {
      mount = false;
    };
  }, [currentClient, currentStudentRecord, triggerStudentDebts]);

  const handleSetClientId = async (clientId: number | undefined) => {
    try {
      setClientId(clientId);

      //trigger de la peticion en la primera carga de usuario
      //TODO: refactorizar el metodo de peticion de las deudas para eviar el triple trigger #Ej: generacion de deudas
      if (currentClient) {
        triggerStudentDebts({
          studentId: currentClient?.user.student?.id!,
          ...(currentStudentRecord && { student_record: currentStudentRecord.id }),
        });
      }
    } catch (error) {}
  };
  //   return <>asd</>;

  return (
    <Grid container>
      <Grid item xs={12} xl={8} sx={{ pr: { lg: 1 } }}>
        {/*md={'auto'} lg={activeStep === 1 ? 8 : 12} sx={{ pr: { lg: 1 } }}> */}
        <Grid item xs={12} sx={{ padding: 4, bgcolor: 'background.paper', borderRadius: 2 }}>
          <Grid item xs={12}>
            <Grid item container alignItems="center" gap={1} sx={{ cursor: 'pointer' }}>
              {/* <IconButton onClick={() => history.push(`/${location.pathname.split('/')[1]}`)}>
                <IconArrowNarrowLeft size={30} color={theme.palette.text.dark} />
              </IconButton> */}
              <Typography variant="h2">{t('financial.debts_payment.title')}</Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sx={{ mt: 2 }}>
              <DebtsPaymentsWizard
                clientInfo={clientInfo} //sin usar
                activeStep={activeStep}
                errorIndex={errorIndex}
                setActiveStep={handleActiveStep}
                handleNext={handleNext}
                handleBack={handleBack}
                setErrorIndex={handleSetError}
                handleOpenClientSideDialog={handleOpen}
              />
              {/* {false && <Checkout />} */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <ClientsSideDialog
        open={open}
        clientInfo={clientInfo}
        isFetchingClientInfo={isFetchingCI}
        clientInfoPage={clientInfoPage}
        errorClientInfo={errorCI}
        selectedClientId={clientId}
        handleCloseDialog={handleClose}
        handleSetClientId={handleSetClientId}
        setClientInfoPage={setClientInfoPage}
        handleSetClientCI={handleSetClientCI}
      />
    </Grid>
  );
};

export default DebtsPaymentsScreen;
