import * as yup from 'yup';

export const optionsTemplateFormSchema = yup.object().shape({
  description: yup.string().required('maintenance.options_template.form.name.required'),
  options: yup
    .array()
    .of(
      yup.object().shape({
        value: yup
          .number()
          .typeError('maintenance.options_template.form.options.value.number')
          .required('maintenance.options_template.form.options.value.required'),
        label: yup.string(),
      })
    )
    .min(2, 'maintenance.options_template.form.options.min')
    .required('maintenance.options_template.form.options.min'),
});

export const representativeScaleFormSchema = yup.object().shape({
  name: yup.string().required('maintenance.options_template.form.name.required'),
  description: yup.string(),
  keyword: yup.string().required('Keyword es requerido'),
  status: yup.string().required('Status es requerido'),
  evaluation_scale_detail: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string().required('maintenance.options_template.form.name.required'),
        scale_start: yup
          .number()
          .typeError('maintenance.options_template.form.options.value.number')
          .required('maintenance.options_template.form.options.value.required'),
        scale_end: yup
          .number()
          .min(yup.ref('scale_start'), 'El valor final debe ser mayor al valor inicial')
          .typeError('maintenance.options_template.form.options.value.number')
          .required('maintenance.options_template.form.options.value.required'),
      })
    )
    .min(2, 'maintenance.options_template.form.options.min')
    .required('maintenance.options_template.form.options.min'),
});
