import { useAppSelector } from '../../../../hooks/useRedux';
import DeleteFinancialPeriodDialog from '../components/DeleteFinancialPeriodDialog';
import FinancialPeriodTable from '../components/FinancialPeriodTable';
import { Grid, Typography } from '@material-ui/core';
import MainCard from '../../../shared/components/cards/MainCard';
// import { useGetPeriodsQuery } from '../slices/periodsSlice';
import { useTranslation } from 'react-i18next';

const FinancialPeriodsScreen = () => {
  const {t} = useTranslation();
  const { financialPeriodIdToDelete } = useAppSelector((state) => state.financialPeriods);

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">{t('financial.periods.title')}</Typography>
      </Grid>

      <FinancialPeriodTable />
      {financialPeriodIdToDelete && <DeleteFinancialPeriodDialog />}
    </Grid>
  );
};

export default FinancialPeriodsScreen;
