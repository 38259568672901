import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    '&>div:nth-child(3)': {
      justifyContent: 'flex-end',
      '&>div': {
        margin: '0px',
        padding: '10px',
        borderRadius: '0px',
        maxWidth: '535px',
        maxHeight: '100%',
      },
    },
  },
  message: {
    '& > p': {
      ...theme.typography.body1,
      marginBottom: 0,
    },
  },
  paper: {
    minHeight: '100vh',
    width: '620px',
  },
  faqsSearchContainerPaper: {
    minHeight: '300px',
    position: 'absolute',
    top: '60px',
    right: '60px',
    backgroundColor: theme.palette.primary.main,
    maxWidth: '400px',
    minWidth: '225px',
  },
  faqsSearchActions: {
    borderTopLeftRadius: '20px',
    backgroundColor: 'white',
    padding: '20px 18px 18px 18px',
  },
  faqsSearchContainer: {
    '&>div:nth-child(3)': {
      justifyContent: 'center',
      '&>div': {
        padding: '0px',
      },
    },
  },
  buttonFloat: {
    ...theme.typography.commonAvatar,
    ...theme.typography.mediumAvatar,
    transition: 'all .2s ease-in-out',
    background:
      theme.palette.mode === 'dark' ? theme.palette.primary.main : theme.palette.primary.main,
    color: theme.palette.mode === 'dark' ? theme.palette.warning.dark : 'white',
    '&[aria-controls="menu-list-grow"],&:hover': {
      background:
        theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.secondary.dark,
      color:
        theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.secondary.light,
      width: '200px',
      '& span': {
        display: 'flex',
        width: 'auto',
      },
    },
    position: 'fixed',
    width: '50px',
    borderRadius: '25px 0 0 25px',
    height: '50px',
    top: '110px',
    right: '0px',
    cursor: 'pointer',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1200,
  },
  headerAvatar: {
    ...theme.typography.commonAvatar,
    ...theme.typography.mediumAvatar,
    transition: 'all .2s ease-in-out',
    background:
      theme.palette.mode === 'dark' ? theme.palette.primary.main : theme.palette.primary.main,
    color: theme.palette.mode === 'dark' ? theme.palette.warning.dark : 'white',
    '&[aria-controls="menu-list-grow"],&:hover': {
      background:
        theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.secondary.dark,
      color:
        theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.secondary.light,
    },
  },
  box: {
    marginLeft: '17px',
    marginRight: '25px',
    [theme.breakpoints.down('sm')]: {
      marginRight: '16px',
    },
  },
  text: {
    color: 'white',
    width: '0',
    overflow: 'hidden',
    margin: '0',
    transition: 'width 2s ease-in-out',
  },
}));
