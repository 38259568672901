import { useState, FC, useEffect } from 'react';

import { Grid } from '@material-ui/core';

import TempBillingData from '../TempBillingData';
import TempBillingSideDialog from '../TempBillingSideDialog';
import SubCard from '../../../../shared/components/cards/SubCard';
import { useAppSelector } from '../../../../../hooks/useRedux';
import ErrorAlert from '../../../../shared/components/alerts/ErrorAlert';

interface Props {
  //   handleOpenClientSideDialog: () => void;
  handleValidStep: (param: boolean) => void;
}

const TempBillingDataHoc: FC<Props> = (props) => {
  const { handleValidStep } = props;

  const { tempBillingData, defaultTempBillingData } = useAppSelector((state) => state.debtsPayment);

  useEffect(() => {
    if (defaultTempBillingData || tempBillingData) {
      handleValidStep(true);
    } else {
      handleValidStep(false);
    }
  }, [defaultTempBillingData, tempBillingData, handleValidStep]);

  //Billing data side dialog
  const [openBilling, setOpenBilling] = useState(false);

  const handleOpenBilling = () => {
    setOpenBilling(true);
  };

  const handleCloseBilling = () => {
    setOpenBilling(false);
  };

  return (
    <>
      <Grid container justifyContent="center">
        {!tempBillingData && !defaultTempBillingData && (
          <Grid item xs={12}>
            <ErrorAlert message="No se ha encontrado información de facturación" />
          </Grid>
        )}

        <Grid item xs={12} md={6}>
          <SubCard>
            <TempBillingData handleOpen={handleOpenBilling} />
          </SubCard>
        </Grid>
      </Grid>

      <TempBillingSideDialog
        // BillingData={{ data: billingData, isFetching: isFetcingBD }}
        open={openBilling}
        handleCloseDialog={handleCloseBilling}
      />
    </>
  );
};

export default TempBillingDataHoc;
