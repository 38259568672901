import {
  Autocomplete,
  Button,
  FormHelperText,
  Grid,
  InputLabel,
  Switch,
  TextField,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { PropsFormEdit } from '..';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/useRedux';
import ErrorAlert from '../../../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../../../shared/components/alerts/SuccessAlert';
import LoadingButton from '../../../../../shared/components/buttons/LoadingButton';
import LocalizedDatePicker from '../../../../../shared/components/controlled/LocalizedDatePicker';
import TextFieldFetching from '../../../../../shared/components/TextFieldFetching';
import { Catalog } from '../../../../../shared/interfaces/catalogs.interfaces';
import {
  useGetCatalogByAcronymChildrenQuery,
  useGetCatalogByAcronymQuery,
} from '../../../../../shared/slices/catalogApi';
import { JobSchema } from '../../../constants/validationSchemas';
import {
  useAddStudentJobInformationMutation,
  useEditStudentJobInformationMutation,
} from '../../../slices/studentsApiSlice';
import { setStudentJobIdSelected } from '../../../slices/studentsSlice';

const JobsForm = ({ data }: PropsFormEdit) => {
  const { studentId } = useParams<{ studentId: string }>();

  const dispatch = useAppDispatch();

  const { currentProfile } = useAppSelector((state) => state.access);
  const { studentJobsList, studentJobIdSelected } = useAppSelector((state) => state.students);

  const { t } = useTranslation();

  const [cities, setCities] = useState<Catalog[]>([]);

  const { data: countries = [], isLoading: isLoadingCountries } = useGetCatalogByAcronymQuery(
    {
      profileId: currentProfile?.profileId!,
      acronym: 'PS',
    },
    {
      skip: !currentProfile?.profileId,
    }
  );

  const [
    addJob,
    {
      isLoading: isLoadingAddJob,
      isSuccess: isSuccessAddJob,
      isError: isErrorAddJob,
      error: errorAddJob,
      reset: resetAddJob,
    },
  ] = useAddStudentJobInformationMutation();
  const [
    editJob,
    {
      isLoading: isLoadingEditJob,
      isSuccess: isSuccessEditJob,
      isError: isErrorEditJob,
      error: errorEditJob,
      reset: resetEditJob,
    },
  ] = useEditStudentJobInformationMutation();

  const { errors, touched, handleSubmit, getFieldProps, setFieldValue, values, resetForm } =
    useFormik({
      initialValues: {
        jobOrganization: '',
        jobPosition: '',
        jobDirection: '',
        jobPhone: '',
        jobStartDate: new Date(),
        jobEndDate: new Date(),
        jobCurrent: false,
        jobAffilated: false,
        cityId: 0,
        countryId: 0,
        statusId: 1,
      },
      validationSchema: JobSchema,
      onSubmit: async (values) => {
        const item = {
          // id: laboralExperience?.id || Date.now(),
          person_id: data?.id!,
          per_job_organization: values.jobOrganization,
          per_job_position: values.jobPosition,
          per_job_direction: values.jobDirection,
          per_job_phone: values.jobPhone.toString(),
          per_job_start_date: values.jobStartDate.toISOString().split('T')[0],
          per_job_end_date: values.jobEndDate.toISOString().split('T')[0],
          per_job_current: values.jobCurrent === true,
          per_job_iess_affiliated: values.jobAffilated === true,
          city_id: values.cityId,
          country_id: values.countryId,
          status_id: values.statusId,
          per_job_document_url: null,
        };

        if (studentJobIdSelected) {
          await editJob({
            payload: {
              ...item,
            },
            studentId,
            jobId: studentJobIdSelected,
          });
        } else {
          await addJob({
            payload: item,
            studentId,
          });
        }
      },
    });

  useEffect(() => {
    if (isSuccessAddJob) {
      resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessAddJob]);

  useEffect(() => {
    if (isSuccessEditJob) {
      resetForm();
      dispatch(setStudentJobIdSelected(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessEditJob]);

  const {
    data: provinces,
    isLoading: isLoadingCities,
    isUninitialized: isUninitializedCities,
  } = useGetCatalogByAcronymChildrenQuery(
    {
      profileId: currentProfile?.profileId!,
      acronym: countries?.find((e) => e.id === Number(values.countryId))?.cat_keyword || '',
    },
    {
      skip: !currentProfile?.profileId || !values.countryId || values.countryId === 0,
    }
  );

  useEffect(() => {
    if (provinces) {
      const cities = provinces?.reduce((acc: Catalog[], province: Catalog) => {
        return [...acc, ...province.children];
      }, []);
      setCities(cities);
    }
  }, [provinces]);

  useEffect(() => {
    const job = studentJobsList.find((e) => e.id === studentJobIdSelected);

    if (job) {
      setFieldValue('jobOrganization', job.per_job_organization);
      setFieldValue('jobPosition', job.per_job_position);
      setFieldValue('jobDirection', job.per_job_direction);
      setFieldValue('jobPhone', job.per_job_phone);
      setFieldValue('jobStartDate', new Date(job.per_job_start_date));
      setFieldValue('jobEndDate', job?.per_job_end_date ? new Date(job.per_job_end_date) : null);
      setFieldValue('jobCurrent', Boolean(Number(job.per_job_current)));
      setFieldValue('jobAffilated', Boolean(Number(job.per_job_iess_affiliated)));
      setFieldValue('cityId', job.city_id);
      setFieldValue('countryId', job.country_id);
      setFieldValue('statusId', job.status_id);
    } else {
      resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studentJobIdSelected, studentJobsList, setFieldValue]);

  return (
    <Grid container>
      {isErrorAddJob && (
        <Grid item xs={12}>
          <ErrorAlert message={errorAddJob as string} handleDismiss={() => {}} />
        </Grid>
      )}
      {isSuccessAddJob && (
        <Grid item xs={12}>
          <SuccessAlert
            message="El registro se ha guardado correctamente"
            handleDismiss={() => {
              resetAddJob();
            }}
          />
        </Grid>
      )}
      {isErrorEditJob && (
        <Grid item xs={12}>
          <ErrorAlert message={errorEditJob as string} handleDismiss={() => {}} />
        </Grid>
      )}
      {isSuccessEditJob && (
        <Grid item xs={12}>
          <SuccessAlert
            message="El registro se ha actualizado correctamente"
            handleDismiss={() => {
              resetEditJob();
            }}
          />
        </Grid>
      )}

      <form onSubmit={handleSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <InputLabel>Organización</InputLabel>
            <TextField
              fullWidth
              placeholder={t(
                'academic.requisition.edit.wizard.step2.add_laboral_experience.dialog.org.placeholder'
              )}
              {...getFieldProps('jobOrganization')}
              error={Boolean(errors.jobOrganization && touched.jobOrganization)}
            />
            {errors.jobOrganization && touched.jobOrganization && (
              <FormHelperText error>{t(errors.jobOrganization)}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <InputLabel>
              {t('academic.requisition.edit.wizard.step2.add_laboral_experience.dialog.position')}
            </InputLabel>
            <TextField
              fullWidth
              placeholder={t(
                'academic.requisition.edit.wizard.step2.add_laboral_experience.dialog.position.placeholder'
              )}
              {...getFieldProps('jobPosition')}
              error={Boolean(errors.jobPosition && touched.jobPosition)}
            />
            {errors.jobPosition && touched.jobPosition && (
              <FormHelperText error>{t(errors.jobPosition)}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <InputLabel>
              {t('academic.requisition.edit.wizard.step2.add_academic_form.dialog.country')}
            </InputLabel>
            {isLoadingCountries ? (
              <TextFieldFetching />
            ) : (
              <Autocomplete
                options={countries}
                loading={isLoadingCountries}
                loadingText={t('Cargando paises...')}
                noOptionsText={t('No se encontraron paises')}
                getOptionLabel={(option) => option.cat_name}
                value={countries.find((e) => e.id === Number(values.countryId)) || null}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={t('Seleccione un pais')}
                    error={Boolean(errors.countryId && touched.countryId)}
                  />
                )}
                onChange={(e, value) => setFieldValue('countryId', value?.id)}
                fullWidth
                clearIcon={null}
              />
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            <InputLabel>{t('Ciudad')}</InputLabel>
            {isUninitializedCities && (
              <TextField fullWidth placeholder={t('Seleccione un pais')} disabled />
            )}
            {!isUninitializedCities && isLoadingCities && <TextFieldFetching />}
            {!isUninitializedCities && !isLoadingCities && (
              <>
                <Autocomplete
                  options={cities || []}
                  loading={isLoadingCities}
                  loadingText={t('Cargando ciudades...')}
                  noOptionsText={t('No se encontraron ciudades')}
                  getOptionLabel={(option) => option.cat_name}
                  value={cities?.find((e) => e.id === Number(values.cityId)) || null}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={t('Seleccione una ciudad')}
                      error={Boolean(errors.cityId && touched.cityId)}
                    />
                  )}
                  onChange={(_, value) => setFieldValue('cityId', value?.id || null)}
                  fullWidth
                />

                {errors && touched.cityId && (
                  <FormHelperText error>{t(errors.cityId as string)}</FormHelperText>
                )}
              </>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <InputLabel>{t('Dirección')}</InputLabel>
            <TextField
              fullWidth
              placeholder={t('Ingrese la dirección')}
              {...getFieldProps('jobDirection')}
              error={Boolean(errors.jobDirection && touched.jobDirection)}
            />
            {errors.jobDirection && touched.jobDirection && (
              <FormHelperText error>{t(errors.jobDirection)}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <InputLabel>{t('Teléfono')}</InputLabel>
            <TextField
              fullWidth
              placeholder={t('Ingrese el teléfono')}
              inputProps={{
                maxLength: 14,
              }}
              {...getFieldProps('jobPhone')}
              error={Boolean(errors.jobPhone && touched.jobPhone)}
            />
            {errors.jobPhone && touched.jobPhone && (
              <FormHelperText error>{t(errors.jobPhone)}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <InputLabel>{t('Fecha de inicio')}</InputLabel>
            <LocalizedDatePicker
              value={values.jobStartDate}
              onChange={(newValue) => {
                setFieldValue('jobStartDate', newValue);
              }}
              helperText=""
              placeholder={t('Seleccione la fecha de inicio')}
              error={Boolean(errors.jobStartDate && touched.jobStartDate)}
            />

            {errors.jobStartDate && touched.jobStartDate && (
              <FormHelperText error>{t(errors.jobStartDate as string)}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <InputLabel>{t('¿Trabaja en esta organización actualmente?')}</InputLabel>
            <Switch checked={values.jobCurrent} {...getFieldProps('jobCurrent')} />
          </Grid>
          {!values.jobCurrent && (
            <Grid item xs={12} md={6}>
              <InputLabel>{t('Fecha de finalización')}</InputLabel>
              <LocalizedDatePicker
                value={values.jobEndDate}
                onChange={(newValue) => {
                  setFieldValue('jobEndDate', newValue);
                }}
                helperText=""
                placeholder={t('Ingrese la fecha de finalización')}
                error={Boolean(errors.jobEndDate && touched.jobEndDate)}
              />

              {errors.jobEndDate && touched.jobEndDate && (
                <FormHelperText error>{t(errors.jobEndDate as string)}</FormHelperText>
              )}
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <InputLabel>{t('¿Estuvo afiliado?')}</InputLabel>
            <Switch checked={values.jobAffilated} {...getFieldProps('jobAffilated')} />
          </Grid>
        </Grid>
        <Grid container spacing={1} mt={2} justifyContent="flex-end">
          {studentJobIdSelected && (
            <Grid item>
              <Button
                variant="outlined"
                onClick={() => {
                  dispatch(setStudentJobIdSelected(null));
                }}
              >
                {t('Cancelar')}
              </Button>
            </Grid>
          )}
          <Grid item>
            <LoadingButton
              variant="contained"
              type="submit"
              isLoading={isLoadingAddJob || isLoadingEditJob}
            >
              {studentJobIdSelected ? t('Editar') : t('Guardar')}
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

export default JobsForm;
