import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';
import EvaluationForm from '../components/EvaluationForm';
import { useLazyGetEvaluationQuery } from '../slices/evaluationsApiSlice';
import {
  setEvaluationsEditFormQuestionsAdded,
  setEvaluationsEditFormQuestionsDeleted,
  setEvaluationsIdSelected,
  setEvaluationsList,
} from '../slices/evaluationsSlice';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/useRedux';
import MainCard from '../../../../../shared/components/cards/MainCard';
import SimpleFormOneColumn from '../../../../../shared/components/forms/skeletons/SimpleFormOneColumn';
import BackButton from '../../../../../shared/components/buttons/BackButton';

const EditEvaluationScreen = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();

  const { evaluationsIdSelected, evaluationsList } = useAppSelector((state) => state.evaluations);

  const [getEvaluation, { isLoading }] = useLazyGetEvaluationQuery();

  useEffect(() => {
    if (!evaluationsIdSelected && id) {
      dispatch(setEvaluationsIdSelected(id!));
    }

    if (!evaluationsList?.length && id) {
      getEvaluation({ id }).then((evaluation) => {
        dispatch(setEvaluationsList([evaluation.data!]));
      });
    }

    return () => {
      dispatch(setEvaluationsIdSelected(null));
      dispatch(setEvaluationsEditFormQuestionsAdded([]));
      dispatch(setEvaluationsEditFormQuestionsDeleted([]));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid container alignItems="center" sx={{ mb: 3 }}>
        <BackButton />
        <Typography variant="h2">{t('maintenance.evaluations.edit.title')}</Typography>
      </Grid>
      {evaluationsIdSelected && id && evaluationsList?.length && !isLoading ? (
        <EvaluationForm />
      ) : (
        <SimpleFormOneColumn />
      )}
    </Grid>
  );
};

export default EditEvaluationScreen;
