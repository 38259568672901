import Stack from '@material-ui/core/Stack';
import Skeleton from '@material-ui/core/Skeleton';

const SkeletonPeriod = () => {
  return (
    <Stack spacing={1}>
      <Skeleton variant="text" width={200} height={30} sx={{ borderRadius: 2 }} />
      <Skeleton variant="rectangular" width="100%" height={100} sx={{ borderRadius: 2 }} />
    </Stack>
  );
};

export default SkeletonPeriod;
