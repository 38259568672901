import { JSXElementConstructor } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

// redux hooks
import { useAppSelector } from '../../hooks/useRedux';
// idle timer wrapper
import CustomIdleTimer from '../CustomIdleTimer';

interface Props extends RouteProps {
  component: JSXElementConstructor<RouteProps>;
}

const PrivateRoute = ({ component: Component, ...rest }: Props) => {
  const { isAuth } = useAppSelector((state) => state.auth);

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuth ? (
          <>
            <CustomIdleTimer>
              <Component {...props} />
            </CustomIdleTimer>
          </>
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default PrivateRoute;
