import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import Autocomplete from '@material-ui/core/Autocomplete';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

import InputLabel from '../../../../shared/components/forms/InputLabel';
import { LivingPlace as LivingPlaceInterface } from '../../interfaces/studentsStore.interface';
import {
  useGetCatalogByAcronymQuery,
  useGetCatalogByKeywordQuery,
} from '../../../../shared/slices/catalogApi';
import { LivingPlaceFormSchema } from '../../constants/validationSchemas';
import { PropsForm, NamesTabs, listTabs } from './index';
import errorValidationFunction from '../../constants/errorValidation';
import { useGetAvailablesCampusQuery } from '../../../campus/slices/campusApiSlice';
import { handleNextStep, handleReturnStep } from '../../constants/navigateFunctionsForm';
import TextFieldFetching from '../../../../shared/components/TextFieldFetching';
import LoadingButton from '../../../../shared/components/buttons/LoadingButton';

const ThisTabName: NamesTabs = 'living-place';

const LivingPlaceForm = ({
  setData,
  data,
  currentTab,
  errorValidation,
  setCurrentTab,
  isEdit,
  errorObject,
  submit,
}: PropsForm) => {
  const { t } = useTranslation();

  const [firstShow, setFirstShow] = useState(false);

  const [isAFormChange, setIsAFormChange] = useState(false);

  //households
  const { data: householdsAvailables = [], isLoading: isLoadingHA } = useGetCatalogByAcronymQuery({
    acronym: 'VV',
  });

  // countries
  const { data: countriesAvailables = [], isLoading: isLoadingCountries } =
    useGetCatalogByAcronymQuery({
      acronym: 'PS',
    });

  //cities avalibles
  const { data: citiesAvailables = [], isLoading: isLoadingCA } = useGetCatalogByAcronymQuery({
    acronym: 'C',
  });

  //Ethnics
  const { data: ethnicsAvailables = [], isLoading: isLoadingEA } = useGetCatalogByAcronymQuery({
    acronym: 'ET',
  });

  //Campus
  const { data: campusAvailables = [], isLoading: isLoadingCAM } = useGetAvailablesCampusQuery({});

  //sectors
  const { data: sectorsAvailables = [], isLoading: isLoadingSA } = useGetCatalogByAcronymQuery({
    acronym: 'S',
  });

  //Daytrips
  const { data: daytripsAvailables = [], isLoading: isLoadingDA } = useGetCatalogByAcronymQuery({
    acronym: 'J',
  });

  //Modalities
  const { data: modalitiesAvailables = [], isLoading: isLoadingMA } = useGetCatalogByAcronymQuery({
    acronym: 'M',
  });

  //religions
  const { data: religionsAvailables = [], isLoading: isLoadingRA } = useGetCatalogByAcronymQuery({
    acronym: 'TR',
  });

  const { data: provinceCatalog, isFetching: isFetchingProvince } = useGetCatalogByKeywordQuery({
    keyword: 'pais-ec',
  });

  const notErrorForm = !errorObject?.some((e) => e.error);

  const isCompleteOthersTabs = isEdit
    ? errorObject?.length! === listTabs.length - 1
    : errorObject?.length! === listTabs.length;

  const { getFieldProps, errors, touched, handleSubmit, setFieldValue, submitForm, values } =
    useFormik<LivingPlaceInterface>({
      initialValues: {
        vivienda_id: isEdit ? Number(data.vivienda_id) : 0,
        country_id: isEdit ? Number(data.country_id) : 0,
        city_id: isEdit ? Number(data.city_id) : 0,
        current_city_id: isEdit ? Number(data.current_city_id) : 0,
        ethnic_id: isEdit ? Number(data.ethnic_id) : 0,
        campus_id: isEdit ? data?.user?.student?.campus_id || '' : '',
        sector_id: isEdit ? Number(data.sector_id) : 0,
        jornada_id: isEdit ? data.user.student.jornada_id : '',
        modalidad_id: isEdit ? data?.user?.student?.modalidad_id || '' : '',
        type_religion_id: isEdit ? data.type_religion_id : '',
        pers_direction: isEdit ? data.pers_direction : '',
        pers_num_bedrooms: isEdit ? Number(data.pers_num_bedrooms) || 0 : 0,
        pers_num_child: isEdit ? Number(data.pers_num_child) || 0 : 0,
        pers_num_taxpayers_household: isEdit ? Number(data.pers_num_taxpayers_household) || 0 : 0,
        photo_path: isEdit ? data.user.student.stud_photo_path || '' : '',
        province_id: isEdit ? Number(data.province_id) : 0,
      },
      validationSchema: LivingPlaceFormSchema,
      onSubmit: async (values) => {
        if (notErrorForm && isCompleteOthersTabs && isAFormChange) {
          setData((data) => ({ ...data, livingPlace: values }));
          // submit?.setSubmit();
          // window.scrollTo(0, 0);
        } else if (isAFormChange) {
          setData((data) => ({ ...data, livingPlace: values }));
          handleNextStep(setCurrentTab, currentTab, ThisTabName);
        }
        setIsAFormChange(false);
      },
    });

  useEffect(() => {
    if (currentTab === ThisTabName) {
      setFirstShow(true);
    }

    if (firstShow && currentTab !== ThisTabName) {
      submitForm();
    }

    //eslint-disable-next-line
  }, [currentTab]);

  // Despacha un objecto a la indextab si existe un error en el formulario
  useEffect(() => {
    errorValidationFunction(errors, ThisTabName, firstShow, errorValidation);
    //eslint-disable-next-line
  }, [errors]);

  useEffect(() => {
    setIsAFormChange(true);
    //eslint-disable-next-line
  }, [values]);

  return (
    <form onSubmit={handleSubmit}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <InputLabel>{t('Tipo de vivienda')}</InputLabel>

            {isLoadingHA ? (
              <TextFieldFetching />
            ) : (
              <Autocomplete
                options={householdsAvailables || []}
                loading={isLoadingHA}
                loadingText={t('Cargando tipos de vivienda...')}
                noOptionsText={t('No hay tipos de vivienda disponibles')}
                getOptionLabel={(option) => option.cat_name}
                value={householdsAvailables.find(({ id }) => values.vivienda_id + '' === id + '')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(errors.vivienda_id && touched.vivienda_id)}
                    placeholder={t('Seleccione un tipo de vivienda')}
                  />
                )}
                onChange={(_, value) => {
                  setFieldValue('vivienda_id', value?.id || '');
                }}
                fullWidth
                clearIcon={null}
              />
            )}
            {errors.vivienda_id && touched.vivienda_id && (
              <FormHelperText error>{t(errors.vivienda_id)}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            <InputLabel>{t('País de residencia')}</InputLabel>
            {isLoadingCountries ? (
              <TextFieldFetching />
            ) : (
              <Autocomplete
                options={countriesAvailables || []}
                loading={isLoadingCountries}
                value={countriesAvailables.find(({ id }) => values.country_id + '' === id + '')}
                loadingText={t('Cargando paises...')}
                noOptionsText={t('No hay paises disponibles')}
                getOptionLabel={(option) => option.cat_name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(errors.country_id && touched.country_id)}
                    placeholder={t('Seleccione un país')}
                  />
                )}
                onChange={(_, value) => {
                  setFieldValue('country_id', value?.id || '');
                }}
                fullWidth
                clearIcon={null}
              />
            )}
            {errors.country_id && touched.country_id && (
              <FormHelperText error>{t(errors.country_id)}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            <InputLabel>{t('Ciudad de nacimiento')}</InputLabel>
            {isLoadingCA ? (
              <TextFieldFetching />
            ) : (
              <Autocomplete
                options={citiesAvailables || []}
                loading={isLoadingCA}
                loadingText={t('Cargando ciudades...')}
                noOptionsText={t('No hay ciudades disponibles')}
                getOptionLabel={(option) => option.cat_name}
                value={citiesAvailables.find(({ id }) => values.current_city_id + '' === id + '')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(errors.current_city_id && touched.current_city_id)}
                    placeholder={t('Seleccione una ciudad')}
                  />
                )}
                onChange={(_, value) => {
                  setFieldValue('current_city_id', value?.id || '');
                }}
                fullWidth
                clearIcon={null}
              />
            )}
            {errors.current_city_id && touched.current_city_id && (
              <FormHelperText error>{t(errors.current_city_id)}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            <InputLabel>{t('Ciudad de residencia')}</InputLabel>
            {isLoadingCA ? (
              <TextFieldFetching />
            ) : (
              <Autocomplete
                options={citiesAvailables || []}
                loading={isLoadingCA}
                loadingText={t('Cargando ciudades...')}
                noOptionsText={t('No hay ciudades disponibles')}
                getOptionLabel={(option) => option.cat_name}
                value={citiesAvailables.find(({ id }) => values.city_id + '' === id + '')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(errors.city_id && touched.city_id)}
                    placeholder={t('Seleccione una ciudad')}
                  />
                )}
                onChange={(_, value) => {
                  setFieldValue('city_id', value?.id || '');
                }}
                fullWidth
                clearIcon={null}
              />
            )}
            {errors.city_id && touched.city_id && (
              <FormHelperText error>{t(errors.city_id)}</FormHelperText>
            )}
          </Grid>

          {/* Provincia */}
          <Grid item xs={12} md={6}>
            <InputLabel required>{t('Provincia')}</InputLabel>
            {isFetchingProvince ? (
              <TextFieldFetching />
            ) : (
              <Autocomplete
                options={provinceCatalog || []}
                getOptionLabel={(option) => option.cat_name}
                loadingText={t('Cargando provincias...')}
                noOptionsText={t('No hay provincias disponibles')}
                value={
                  provinceCatalog?.find(({ id }) => values.province_id + '' === id + '') || null
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(errors.province_id && touched.province_id)}
                    placeholder={t('Seleccione una provincia')}
                  />
                )}
                onChange={(_, value) => {
                  setFieldValue('province_id', value?.id || '');
                }}
                fullWidth
                clearIcon={null}
              />
            )}
            {errors.province_id && touched.province_id && (
              <FormHelperText error>{t(errors.province_id)}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            <InputLabel>{t('Etnia')}</InputLabel>
            {isLoadingEA ? (
              <TextFieldFetching />
            ) : (
              <Autocomplete
                options={ethnicsAvailables || []}
                loading={isLoadingEA}
                loadingText={t('Cargando etnias...')}
                noOptionsText={t('No hay etnias disponibles')}
                getOptionLabel={(option) => option.cat_name}
                value={ethnicsAvailables.find(({ id }) => values.ethnic_id + '' === id + '')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(errors.ethnic_id && touched.ethnic_id)}
                    placeholder={t('Seleccione una etnia')}
                  />
                )}
                onChange={(_, value) => {
                  setFieldValue('ethnic_id', value?.id || '');
                }}
                fullWidth
                clearIcon={null}
              />
            )}
            {errors.ethnic_id && touched.ethnic_id && (
              <FormHelperText error>{t(errors.ethnic_id)}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            <InputLabel>{t('Campus')}</InputLabel>
            {isLoadingCAM ? (
              <TextFieldFetching />
            ) : (
              <Autocomplete
                options={campusAvailables || []}
                loading={isLoadingCAM}
                loadingText={t('Cargando campus...')}
                noOptionsText={t('No hay campus disponibles')}
                getOptionLabel={(option) => option.cam_name}
                value={campusAvailables.find(({ id }) => values.campus_id + '' === id + '')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(errors.campus_id && touched.campus_id)}
                    placeholder={t('Seleccione un campus')}
                  />
                )}
                onChange={(_, value) => {
                  setFieldValue('campus_id', value?.id || '');
                }}
                fullWidth
                clearIcon={null}
              />
            )}
            {errors.campus_id && touched.campus_id && (
              <FormHelperText error>{t(errors.campus_id)}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            <InputLabel>{t('Sector')}</InputLabel>
            {isLoadingSA ? (
              <TextFieldFetching />
            ) : (
              <Autocomplete
                options={sectorsAvailables || []}
                loading={isLoadingSA}
                loadingText={t('Cargando sectores...')}
                noOptionsText={t('No hay sectores disponibles')}
                getOptionLabel={(option) => option.cat_name}
                value={sectorsAvailables.find(({ id }) => values.sector_id + '' === id + '')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(errors.sector_id && touched.sector_id)}
                    placeholder={t('Seleccione un sector')}
                  />
                )}
                onChange={(_, value) => {
                  setFieldValue('sector_id', value?.id || '');
                }}
                fullWidth
                clearIcon={null}
              />
            )}
            {errors.sector_id && touched.sector_id && (
              <FormHelperText error>{t(errors.sector_id)}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            <InputLabel>{t('Jornada')}</InputLabel>

            {isLoadingDA ? (
              <TextFieldFetching />
            ) : (
              <Autocomplete
                options={daytripsAvailables || []}
                loading={isLoadingDA}
                loadingText={t('Cargando jornadas...')}
                noOptionsText={t('No hay jornadas disponibles')}
                getOptionLabel={(option) => option.cat_name}
                value={daytripsAvailables.find(({ id }) => values.jornada_id + '' === id + '')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(errors.jornada_id && touched.jornada_id)}
                    placeholder={t('Seleccione una jornada')}
                  />
                )}
                onChange={(_, value) => {
                  setFieldValue('jornada_id', value?.id || '');
                }}
                fullWidth
                clearIcon={null}
              />
            )}
            {errors.jornada_id && touched.jornada_id && (
              <FormHelperText error>{t(errors.jornada_id)}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            <InputLabel>{t('Modalidad')}</InputLabel>
            {isLoadingMA ? (
              <TextFieldFetching />
            ) : (
              <Autocomplete
                options={modalitiesAvailables || []}
                loading={isLoadingMA}
                loadingText={t('Cargando modalidades...')}
                noOptionsText={t('No hay modalidades disponibles')}
                getOptionLabel={(option) => option.cat_name}
                value={modalitiesAvailables.find(({ id }) => values.modalidad_id + '' === id + '')}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(errors.modalidad_id && touched.modalidad_id)}
                    placeholder={t('Seleccione una modalidad')}
                  />
                )}
                onChange={(_, value) => {
                  setFieldValue('modalidad_id', value?.id || '');
                }}
                fullWidth
                clearIcon={null}
              />
            )}
            {errors.modalidad_id && touched.modalidad_id && (
              <FormHelperText error>{t(errors.modalidad_id)}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            <InputLabel>{t('Religión')}</InputLabel>
            {isLoadingRA ? (
              <TextFieldFetching />
            ) : (
              <Autocomplete
                options={religionsAvailables || []}
                loading={isLoadingRA}
                loadingText={t('Cargando religiones...')}
                noOptionsText={t('No hay religiones disponibles')}
                getOptionLabel={(option) => option.cat_name}
                value={religionsAvailables.find(
                  ({ id }) => values.type_religion_id + '' === id + ''
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(errors.type_religion_id && touched.type_religion_id)}
                    placeholder={t('Seleccione una religión')}
                  />
                )}
                onChange={(_, value) => {
                  setFieldValue('type_religion_id', value?.id || '');
                }}
                fullWidth
                clearIcon={null}
              />
            )}
            {errors.type_religion_id && touched.type_religion_id && (
              <FormHelperText error>{t(errors.type_religion_id)}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            <InputLabel>{t('Dirección')}</InputLabel>
            <TextField
              {...getFieldProps('pers_direction')}
              error={Boolean(errors.pers_direction && touched.pers_direction)}
              placeholder={t('Ingrese la dirección')}
              fullWidth
            />
            {errors.pers_direction && touched.pers_direction && (
              <FormHelperText error>{t(errors.pers_direction)}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            <InputLabel>{t('Número de habitaciones')}</InputLabel>
            <TextField
              {...getFieldProps('pers_num_bedrooms')}
              error={Boolean(errors.pers_num_bedrooms && touched.pers_num_bedrooms)}
              placeholder={t('Ingrese el número de habitaciones')}
              fullWidth
            />
            {errors.pers_num_bedrooms && touched.pers_num_bedrooms && (
              <FormHelperText error>{t(errors.pers_num_bedrooms)}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            <InputLabel>{t('Número de hijos')}</InputLabel>
            <TextField
              {...getFieldProps('pers_num_child')}
              error={Boolean(errors.pers_num_child && touched.pers_num_child)}
              placeholder={t('Ingrese el número de hijos')}
              fullWidth
            />
            {errors.pers_num_child && touched.pers_num_child && (
              <FormHelperText error>{t(errors.pers_num_child)}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            <InputLabel>{t('Número de contribuyentes')}</InputLabel>
            <TextField
              {...getFieldProps('pers_num_taxpayers_household')}
              error={Boolean(
                errors.pers_num_taxpayers_household && touched.pers_num_taxpayers_household
              )}
              placeholder={t('Ingrese el número de contribuyentes en el hogar')}
              fullWidth
            />
            {errors.pers_num_taxpayers_household && touched.pers_num_taxpayers_household && (
              <FormHelperText error>{t(errors.pers_num_taxpayers_household)}</FormHelperText>
            )}
          </Grid>
        </Grid>

        <Grid
          item
          container
          alignItems="center"
          justifyContent="start"
          spacing={2}
          xs={4}
          sx={{ mt: 6 }}
        >
          {/* {notErrorForm && isCompleteOthersTabs && ( */}
          <Grid item xs={6}>
            <Button
              fullWidth
              variant="outlined"
              size="large"
              color="primary"
              onClick={() => handleReturnStep(setCurrentTab)}
            >
              {t('academic.subjects.add.back')}
            </Button>
          </Grid>
          {/* )} */}

          <Grid item xs={6}>
            {/* <Button fullWidth type="submit" variant="contained" size="large" color="primary">
              Siguiente
            </Button> */}
            <LoadingButton
              fullWidth
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              // disabled={isAFormChange && !isCompleteOthersTabs}
              isLoading={!!submit?.isLoading}
              // disabled={!isAFormChange && notErrorForm && isCompleteOthersTabs}
              disabled={notErrorForm && isCompleteOthersTabs && !isAFormChange ? true : false}
            >
              {/* {t('academic.subjects.add.submit')} */}
              {notErrorForm && isCompleteOthersTabs ? 'Guardar' : 'Siguiente'}
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default LivingPlaceForm;
