import { hasSubPermission } from '../../../helpers/prmissionsHelpers';

import { useAppSelector } from '../../../hooks/useRedux';

import { subPermissionKeys, SubPermissionKeys } from '../constants/profile-section-permissions';

const useGuardProfileSection = () => {
  const { currentModule } = useAppSelector((state) => state.access);

  const { permissions } = currentModule!;

  const hasAccess = (spKey: keyof SubPermissionKeys, permissionKey = 'academico') =>
    hasSubPermission(permissions!, permissionKey, subPermissionKeys[spKey]);

  return {
    hasAccess,
  };
};

export default useGuardProfileSection;
