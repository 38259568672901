import {
  IconUser,
  IconUsers,
  IconLock,
  IconBuilding,
  IconSchool,
  IconCalendarEvent,
  IconNotebook,
  IconBook,
  IconBuildingBank,
  IconStairs,
  IconNotes,
  IconFileCertificate,
  IconCalendarTime,
  IconDashboard,
} from '@tabler/icons';
import MeetingRoomOutlinedIcon from '@material-ui/icons/MeetingRoomOutlined';
import { UserCircle, ClockAfternoon, UsersThree, ChalkboardSimple, BookOpen } from 'phosphor-react';

// Icon Group
const icons = {
  IconUser,
  IconUsers,
  IconLock,
  IconBuilding,
  UserCircle,
  IconSchool,
  ClockAfternoon,
  IconCalendarEvent,
  IconNotebook,
  MeetingRoomOutlinedIcon,
  UsersThree,
  IconBook,
  IconBuildingBank,
  IconStairs,
  IconNotes,
  ChalkboardSimple,
  BookOpen,
  IconFileCertificate,
  IconCalendarTime,
  IconDashboard,
};

export const dashboard = {
  id: 'dashboard',
  type: 'group',
  title: 'Dashboard',
  children: [
    {
      id: 'dashboard',
      title: 'Dashboard',
      type: 'item',
      url: '/dashboard',
      icon: icons['IconDashboard'],
      permissionKey: 'dashboard',
      breadcrumbs: false,
    },
  ],
};
