import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import MainCard from '../../../../../shared/components/cards/MainCard';
import BackButton from '../../../../../shared/components/buttons/BackButton';
import FormsAdd from '../components/FormAdd';

const FormEditScreen = () => {
  const { t } = useTranslation();

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid alignItems="center" sx={{ mb: 3, display: 'flex' }}>
        <BackButton />
        <Typography variant="h2">{t('Editar Evaluación')}</Typography>
      </Grid>
      <FormsAdd />
    </Grid>
  );
};

export default FormEditScreen;
