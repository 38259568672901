import { makeStyles } from '@material-ui/core/styles';

export const useFormStyles = makeStyles((theme) => ({
  inputLabel: {
    marginBlockEnd: 6,
    fontWeight: 500,
    color: theme.palette.text.primary,
  },
}));

export const stylesDialogEventNews = makeStyles((theme) => ({
  userAddDialog: {
    '&>div:nth-child(3)': {
      justifyContent: 'flex-end',
      '&>div': {
        margin: '0px',
        borderRadius: '0px',
        maxWidth: '600px',
        maxHeight: '100%',
      },
    },
  },
  paper: {
    minHeight: '100vh',
    width: '43%',
  },
  learningComponents: {
    fontSize: '16px',
    fontWeight: 600,
    color: theme.palette.primary.main,
    marginBottom: '8px',
  },
  selectSubject: {
    fontSize: '16px',
    fontWeight: 600,
    color: theme.palette.primary[800],
    marginBottom: '8px',
  },
  selectSubjectType: {
    fontSize: '14px',
    fontWeight: 600,
    color: theme.palette.secondary.main,
    marginBottom: '8px',
  },
}));