import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PersonSearch } from '../../../maintenance/requisition-teacher/interfaces/person.interfaces';
import { Collaborator } from '../interfaces/collaborators.interfaces';

interface UsersState {
  collabList: Collaborator[];
  deleteModalOpen: boolean;
  collaboratorToDelete?: number;

  personData: PersonSearch;
  collaboratorData: Collaborator;
}

const initialState: UsersState = {
  collabList: [],
  deleteModalOpen: false,
  personData: null!,
  collaboratorData: null!,
};

const collaboratorsSlice = createSlice({
  name: 'collaborators',
  initialState,
  reducers: {
    setCollaboratorsList: (state, action: PayloadAction<Collaborator[]>) => {
      state.collabList = action.payload;
    },
    setDeleteModalOpen: (state, action: PayloadAction<boolean>) => {
      state.deleteModalOpen = action.payload;
    },
    openDeleteModalWith: (state, action: PayloadAction<number>) => {
      state.collaboratorToDelete = action.payload;
      state.deleteModalOpen = true;
    },
    resetCollabIdToDelete: (state) => {
      state.collaboratorToDelete = undefined;
    },
    setPersonData: (state, action: PayloadAction<PersonSearch>) => {
      state.personData = action.payload;
    },
    setCollaboratorData: (state, action: PayloadAction<Collaborator>) => {
      state.collaboratorData = action.payload;
    },
  },
});

export const {
  setCollaboratorsList,
  setDeleteModalOpen,
  openDeleteModalWith,
  resetCollabIdToDelete,
  setPersonData,
  setCollaboratorData,
} = collaboratorsSlice.actions;

export default collaboratorsSlice.reducer;
