import { useState } from 'react';

import { useFormik } from 'formik';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';

import { useTranslation } from 'react-i18next';

import InputLabel from '../../../../shared/components/forms/InputLabel';
import { EducationLevelContacts } from '../../interfaces/educationLevels.interfaces';
import SuccessAlert from '../../../../shared/components/alerts/SuccessAlert';
import ErrorAlert from '../../../../shared/components/alerts/ErrorAlert';
import { contacFormSchema } from '../../constants/contactsValidationSchemas';
import {
  useCreateEduLevelContactsMutation,
  useUpdateEduLevelContactsMutation,
} from '../../slices/educationLevelsApiSlice';
import LoadingButton from '../../../../shared/components/buttons/LoadingButton';
import { Contact, Network } from './ContactDeanForm';
import { updateItemFromKeyArr } from '../../../../shared/helpers';

type Props<FormType = Contact | Network> = FormType extends Contact
  ? {
      formType: FormType;
      dataList: EducationLevelContacts;
      educationLevelId: number;
      edit?: EducationLevelContacts['education_levels_contact'][0];
      returnHandler: () => void;
      // changeContactHandler: (
      //   value: EducationLevelContactsTemp['education_levels_contact'][0],
      //   editIndex?: number | string | false
      // ) => void;
    }
  : {
      formType: FormType;
      dataList: EducationLevelContacts;
      educationLevelId: number;
      edit?: EducationLevelContacts['education_levels_network'][0];
      returnHandler: () => void;
      // changeContactHandler: (
      //   value: EducationLevelContactsTemp['education_levels_contact'][0],
      //   editIndex?: number | string | false
      // ) => void;
    };

const ContactsForm = ({ dataList, edit, educationLevelId, formType, returnHandler }: Props) => {
  const { t } = useTranslation();

  const [showAlerts, setShowAlerts] = useState(false);

  const [
    createContacts,
    {
      isLoading: isLoadingCreate,
      isError: isErrorCreate,
      isSuccess: isSuccessCreate,
      error: errorCreate,
    },
  ] = useCreateEduLevelContactsMutation();
  const [
    updateContacts,
    {
      isLoading: isLoadingUpdate,
      isError: isErrorUpdate,
      isSuccess: isSuccessUpdate,
      error: errorUpdate,
    },
  ] = useUpdateEduLevelContactsMutation();

  const { getFieldProps, errors, touched, handleSubmit, resetForm } = useFormik({
    initialValues: {
      id: edit?.id || null,
      name: edit?.name || '',
      description: edit?.description || '',
      ...(formType === 'contact' && {
        email: (edit as EducationLevelContacts['education_levels_contact'][0])?.email || '',
        phone: (edit as EducationLevelContacts['education_levels_contact'][0])?.phone || '',
      }),
    },
    // enableReinitialize: true,
    validationSchema: contacFormSchema,
    onSubmit: async (_values) => {
      try {
        if (!edit) {
          await createContacts({
            payload: {
              education_level_id: +educationLevelId,
              education_levels_contact:
                formType === 'contact'
                  ? [
                      {
                        name: _values?.name || '',
                        description: _values?.description || '',
                        email: _values?.email || '',
                        phone: _values?.phone || '',
                      },
                    ]
                  : [],
              education_levels_network:
                formType === 'network'
                  ? [{ name: _values?.name || '', description: _values?.description || '' }]
                  : [],
            },
          }).unwrap();

          // changeContactHandler({
          //   tempId: `t-${_values.name}-${Math.random()}`,
          //   education_level_id: educationLevelId.toString(),
          //   name: _values?.name || '',
          //   description: _values?.description || '',
          //   email: _values?.email || '',
          //   phone: _values?.phone || '',
          // });
        } else {
          await updateContacts({
            payload: {
              education_level_id: +educationLevelId,
              education_levels_contact:
                formType === 'contact'
                  ? [
                      ...updateItemFromKeyArr(
                        dataList.education_levels_contact as any,
                        'id',
                        Number(_values.id) || 0,
                        {
                          id: Number(_values.id) || 0,
                          education_level_id: educationLevelId.toString(),
                          name: _values?.name || '',
                          description: _values?.description || '',
                          email: _values?.email || '',
                          phone: _values?.phone || '',
                        }
                      ),
                    ]
                  : [...dataList.education_levels_contact],
              education_levels_network:
                formType === 'network'
                  ? [
                      ...updateItemFromKeyArr(
                        dataList.education_levels_network as any,
                        'id',
                        Number(_values.id) || 0,
                        {
                          id: Number(_values.id) || 0,
                          education_level_id: educationLevelId.toString(),
                          name: _values?.name || '',
                          description: _values?.description || '',
                        }
                      ),
                    ]
                  : [...dataList.education_levels_network],
            },
          }).unwrap();

          // changeContactHandler(
          //   {
          //     ...(edit && 'tempId' in edit ? { tempId: edit.tempId } : { id: edit.id }),
          //     education_level_id: educationLevelId.toString(),
          //     name: _values?.name || '',
          //     description: _values?.description || '',
          //     email: _values?.email || '',
          //     phone: _values?.phone || '',
          //     isEdited: true,
          //   },
          //   edit && 'tempId' in edit ? edit.tempId : edit.id
          // );
        }

        resetForm();
      } finally {
        setShowAlerts(true);
        // returnHandler();
      }
    },
  });

  return (
    <form onSubmit={handleSubmit}>
      <Grid container gap={2}>
        <Grid item>
          {showAlerts && (isSuccessCreate || isSuccessUpdate) && (
            <SuccessAlert
              message={`La parada ha sido ${!true ? 'creada' : 'actualizada'} correctamente.`}
              handleDismiss={() => setShowAlerts(false)}
            />
          )}
          {showAlerts && (isErrorCreate || isErrorUpdate) && (
            <ErrorAlert
              message={isErrorCreate ? (errorCreate as string) : (errorUpdate as string)}
              handleDismiss={() => setShowAlerts(false)}
            />
          )}
        </Grid>

        <Grid item container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <InputLabel>{t('Nombre')}</InputLabel>
            <TextField
              fullWidth
              {...getFieldProps('name')}
              error={Boolean(errors.name && touched.name)}
              placeholder={t('Nombre')}
            />
            {errors.name && touched.name && <FormHelperText error>{t(errors.name)}</FormHelperText>}
          </Grid>

          <Grid item xs={12}>
            <InputLabel>{t('Descripción')}</InputLabel>
            <TextField
              fullWidth
              {...getFieldProps('description')}
              error={Boolean(errors.description && touched.description)}
              placeholder={t('Descripción')}
            />
            {errors.description && touched.description && (
              <FormHelperText error>{t(errors.description)}</FormHelperText>
            )}
          </Grid>

          {formType === 'contact' && (
            <>
              <Grid item xs={12}>
                <InputLabel>{t('Telefóno')}</InputLabel>
                <TextField
                  fullWidth
                  {...getFieldProps('phone')}
                  error={Boolean(errors.phone && touched.phone)}
                  placeholder={t('Descripción')}
                />
                {errors.phone && touched.phone && (
                  <FormHelperText error>{t(errors.phone)}</FormHelperText>
                )}
              </Grid>

              <Grid item xs={12}>
                <InputLabel>{t('Correo')}</InputLabel>
                <TextField
                  fullWidth
                  {...getFieldProps('email')}
                  type="email"
                  error={Boolean(errors.email && touched.email)}
                  placeholder={t('Correo')}
                />
                {errors.email && touched.email && (
                  <FormHelperText error>{t(errors.email as string)}</FormHelperText>
                )}
              </Grid>
            </>
          )}
        </Grid>

        <Grid
          item
          container
          alignItems="center"
          justifyContent="flex-end"
          spacing={2}
          sx={{ mt: 1 }}
        >
          <Grid item>
            <Button variant="outlined" size="large" color="primary" onClick={returnHandler}>
              {t('academic.areas.add.back')}
            </Button>
          </Grid>

          <Grid item>
            <LoadingButton
              type="submit"
              variant="contained"
              isLoading={isLoadingCreate || isLoadingUpdate}
            >
              {edit ? t('Editar') : t('Agregar')}
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default ContactsForm;
