import * as Yup from 'yup';

export const ValidationSchema = Yup.object().shape({
  fperiod_name: Yup.string().required('financial.periods.add.name_required'),
  //fperiod_parent: Yup.number().optional().notRequired().nullable(),
  fperiod_type: Yup.string().required('financial.periods.add.type_required'),
  fp_startDate: Yup.date().nullable().required('financial.periods.add.start_period_required'),
  fp_endDate: Yup.date().nullable().required('financial.periods.add.end_period_required'),
  // .integer('Solo se permiten números enteros.'),

  status_id: Yup.number()
    .integer('financial.periods.edit.status_only_numbers')
    .min(1, 'financial.periods.edit.status_min')
    .notRequired(),
});
