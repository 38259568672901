import * as Yup from 'yup';
import { Collaborator } from '../../../hr/collaborators/interfaces/collaborators.interfaces';

export const ValidationSchema = Yup.object().shape({
  edu_name: Yup.string().required('El nombre es requerido.'),
  edu_alias: Yup.string().required('Campo requerido'),
  edu_order: Yup.number().integer('Solo se permiten números enteros.'),
  principal_id: Yup.number().when('checked', {
    is: true,
    then: Yup.number()
      .required('El orden es requerido')
      .integer('Solo se permiten números enteros.'),
  }),
  offer_id: Yup.number()
    .nullable()
    .required('El nivel educativo es requerido')
    .min(1, 'El nivel educativo es requerido'),
  status_id: Yup.number()
    .integer('Solo se permiten números enteros.')
    .min(1, 'La cantidad mínima de materias es 1.')
    .required('El  estado es requerido'),
  checked: Yup.boolean(),
});

export const CollectiveInfoSchema = Yup.object().shape({
  title: Yup.string().required('El título del colectivo es requerido.'),
  collaborators: Yup.array<Collaborator[]>()
    .of(Yup.object())
    .min(1, 'Debe seleccionar al menos un colaborador'),
  //collaborators:  Yup.array().of(Yup.number()).min(1, 'Debe seleccionar al menos un colaborador.'),
});
