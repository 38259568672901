import { Redirect, Switch } from 'react-router-dom';
import { useAppSelector } from '../../../../hooks/useRedux';
import TermsCoditions from '../screens/TermsCoditions';
import GuardRoute from '../../../../router/guards/GuardRoute';

function StudentsRouter() {
  // Verify permission
  const { currentModule } = useAppSelector((state) => state.access);
  const hasPermission = 'student' in currentModule?.permissions!;

  if (!hasPermission) return <Redirect to="/" />;

  return (
    <>
      <Switch>
        <GuardRoute path="/terminos-condiciones" exact component={TermsCoditions} />
      </Switch>
    </>
  );
}

export default StudentsRouter;
