import { Grid, IconButton, Typography } from '@material-ui/core'
import { IconArrowLeft } from '@tabler/icons';
import { useHistory } from 'react-router-dom';
import MainCard from '../../../shared/components/cards/MainCard'
import DetailsStatus from '../components/DetailsStatus';

const RequestsStatusDetailsScreen = () => {
  const history = useHistory();
  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid item container alignItems="center" gap={1} sx={{ mb: 3 }}>
        <IconButton onClick={() => history.goBack()}>
          <IconArrowLeft />
        </IconButton>
        <Typography variant='h2'>Estados de Solicitud</Typography>
      </Grid>
      <DetailsStatus />
    </Grid>
  )
}

export default RequestsStatusDetailsScreen;