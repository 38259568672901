import {
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useAppSelector } from '../../../../hooks/useRedux';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import { createCalendarSchema } from '../constants/validations';
import {
  useAddPosgraduatePlanningCalendarMutation,
  useUpdatePosgraduatePlanningCalendarMutation,
} from '../slices/posgraduatePlanningCalendarApiSlice';

const PosgraduatePlanningCalendarsForm = () => {
  const { currentProfile } = useAppSelector((state) => state.access);

  const { posgraduatePlanningCalendarsList, posgraduatePlanningCalendarIdSelected } =
    useAppSelector((state) => state.posgraduatePlanningCalendars);

  const posgraduateCalendar = posgraduatePlanningCalendarsList.find(
    (item) => item.id === posgraduatePlanningCalendarIdSelected
  );

  const [showAlert, setShowAlert] = useState(false);

  const [
    addCalendar,
    {
      isLoading: isLoadingAddCalendar,
      isSuccess: isSuccessAdd,
      isError: isErrorAdd,
      error: errorAdd,
    },
  ] = useAddPosgraduatePlanningCalendarMutation();
  const [
    updateCalendar,
    {
      isLoading: isLoadingUpdateCalendar,
      isSuccess: isSuccessUpdate,
      isError: isErrorUpdate,
      error: errorUpdate,
    },
  ] = useUpdatePosgraduatePlanningCalendarMutation();

  const { errors, touched, handleSubmit, getFieldProps } = useFormik({
    initialValues: {
      description: posgraduateCalendar?.pc_description || '',
    },
    validationSchema: createCalendarSchema,
    onSubmit: async (values) => {
      if (posgraduateCalendar?.id) {
        await updateCalendar({
          profileId: currentProfile?.profileId!,
          id: posgraduateCalendar?.id,
          description: values.description,
        });
      } else {
        await addCalendar({
          profileId: currentProfile?.profileId!,
          description: values.description,
        });
      }

      setShowAlert(true);
    },
  });

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        {showAlert && (isSuccessAdd || isSuccessUpdate) && (
          <Grid item xs={12}>
            <SuccessAlert
              handleDismiss={() => setShowAlert(false)}
              message={
                isSuccessAdd
                  ? 'Calendario agregado correctamente'
                  : 'Calendario actualizado correctamente'
              }
            />
          </Grid>
        )}
        {showAlert && (isErrorAdd || isErrorUpdate) && (
          <Grid item xs={12}>
            <ErrorAlert
              handleDismiss={() => setShowAlert(false)}
              message={isErrorAdd ? (errorAdd as string) : (errorUpdate as string)}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <InputLabel>Descripción</InputLabel>
          <TextField
            fullWidth
            variant="outlined"
            {...getFieldProps('description')}
            error={Boolean(touched.description && errors.description)}
          />
          {errors.description && touched.description && (
            <FormHelperText error>{errors.description}</FormHelperText>
          )}
        </Grid>

        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={isLoadingAddCalendar || isLoadingUpdateCalendar}
            endIcon={
              isLoadingAddCalendar || isLoadingUpdateCalendar ? (
                <CircularProgress size={14} />
              ) : null
            }
          >
            Guardar
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default PosgraduatePlanningCalendarsForm;
