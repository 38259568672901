import * as Yup from 'yup';

export const ProductsValidationSchema = Yup.object().shape({
  categoryId: Yup.number().required('financial.maintenance_prod.add.category_required'),
  name: Yup.string().required('financial.maintenance_prod.add.name_required'),
  valor_inicial: Yup.string()
    .matches(/^\d{1,100}(\.\d{1,2})?$/, 'shared.validation.only_numbers_max_decimals.two')
    .required('financial.maintenance_prod.add.initial_value_required'),
  dia_vencimiento: Yup.string()
    .matches(/^\d+$/, 'shared.validation.only_numbers')
    .required('financial.maintenance_prod.add.expiration_days_required'),
  useTypeId: Yup.number().required('financial.maintenance_prod.add.use_type_required'),
  /* formuleTypeId: Yup.number().required('El estado del permiso es requerido.'),
  frecuencyTypeId: Yup.number().required('El estado del permiso es requerido.'), */
  porcentaje: Yup.string()
    .matches(/^\d{1,100}(\.\d{1,4})?$/, 'shared.validation.only_numbers_max_decimals.four')
    .required('shared.validation.percentage_required')
    .test('isValid', 'shared.validation.percentage_limit_smaller_than_100', (val) => Number(val) <= 100),
  systemTypeId: Yup.number().required('financial.maintenance_prod.add.system_type_required'),

  /* description: Yup.string(),
  statusId: Yup.number().required('El estado del permiso es requerido.'),
  eco_gro_max_amount: Yup.number().required('Monto máximo es requerido.').positive('Monto máximo no válido.'),
  eco_gro_min_amount: Yup.number().required('Monto mínimo es requerido.').positive('Monto mínimo no válido.') */
});

export const productEditSchema = Yup.object().shape({
  name: Yup.string().required('financial.maintenance_prod.add.name_required'),
  categoryId: Yup.number().required('financial.maintenance_prod.add.category_required'),
  valor_inicial: Yup.number().required('financial.maintenance_prod.add.initial_value_required'),
  useTypeId: Yup.number().required('financial.maintenance_prod.add.use_type_required'),
  statusId: Yup.number().required('financial.maintenance_prod.edit.status_required'),
});
