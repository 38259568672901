import { useMemo } from 'react';

import { Grid, IconButton, LinearProgress, Tooltip, Typography } from '@material-ui/core';
import { IconClipboardList } from '@tabler/icons';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import MainCard from '../../../shared/components/cards/MainCard';
import { careerAcademicList } from '../constants';
import { obtainCareerDefault } from '../helpers';
import AreasTable from './tables/AreasTable';
import SemesterTable from './tables/SemesterTable';
import {
  setOpenModalMatterDetail,
  setMatterIdDetail,
  setMatterName,
} from '../slices/AcademicCounselingSlice';
import { useTheme } from '@material-ui/core/styles';
import { useStyles } from '../constants/styles';
import { useGetStudentCareerAdvanceQuery } from '../slices/academicCounselingApi';
import SkeletonTable from '../../virtual-classroom/dashboard/components/SkeletonTable';
import { Semester, Area } from '../interfaces/career-advance.interfaces';
import { useTranslation } from 'react-i18next';

const AcademicCareerTable = function () {
  const { t } = useTranslation();

  const classes = useStyles();

  const dispatch = useAppDispatch();

  const theme = useTheme();

  const { currentRecordStudent, currentProfile } = useAppSelector((state) => state.access);

  const { studentRecordReference } = useAppSelector((state) => state.auth);

  const { isLoading, data } = useGetStudentCareerAdvanceQuery({
    studentRecordId: currentRecordStudent?.id || 0,
    profileId: currentProfile?.profileId || 0,
  });

  const handleDetailMatter = (matterId: number, matterName: string = '') => {
    dispatch(setMatterIdDetail(matterId));
    dispatch(setMatterName(matterName));
    dispatch(setOpenModalMatterDetail(true));
  };

  const totalRequiredSubject: number = data?.curriculumNew
    ? data?.semesters!.reduce((prev, curr) => prev + parseInt(curr.required_subjects), 0) || 0
    : data?.areas!.reduce((prev, curr) => prev + parseInt(curr.required_subjects), 0) || 0;

  const totalApprovedSubject: number = data?.curriculumNew
    ? data?.semesters!.reduce((prev, curr) => prev + parseInt(curr.approved_subjects), 0) || 0
    : data?.areas!.reduce((prev, curr) => prev + parseInt(curr.approved_subjects), 0) || 0;

  const porcentageApprovedSubject = (totalApprovedSubject * 100) / (totalRequiredSubject || 1);

  const studentCareer =
    studentRecordReference &&
    studentRecordReference.find((value) => value.id === currentRecordStudent?.id);

  const datafetch = useMemo(() => {
    if (data) {
      const prop = data.curriculumNew ? 'semesters' : 'areas';

      return data[prop]?.map((dt) => {
        const isNew = data.curriculumNew;

        if (isNew) {
          return {
            semester: (dt as Semester).semester,
            required_subjects: dt.required_subjects,
            approved_subjects: dt.approved_subjects,
            missing_subjects: `${parseInt(dt.required_subjects) - parseInt(dt.approved_subjects)}`,
            detail: '',
          };
        } else {
          return {
            id: (dt as Area).id,
            ar_name: (dt as Area).ar_name,
            required_subjects: parseFloat(dt.required_subjects).toFixed(2),
            approved_subjects: parseFloat(dt.approved_subjects).toFixed(2),
            missing_subjects: `${
              parseFloat(dt.required_subjects) - parseFloat(dt.approved_subjects)
            }`,
            detail: '',
          };
        }
        // return {
        //   ...(isNew && { semester: (dt as Semester).semester }),
        //   ...(!isNew && { id: (dt as Area).id, ar_name: (dt as Area).ar_name }),

        //   required_subjects: isNew
        //     ? dt.required_subjects
        //     : parseFloat(dt.approved_subjects).toFixed(2),

        //   approved_subjects: isNew
        //     ? dt.approved_subjects
        //     : parseFloat(dt.required_subjects).toFixed(2),

        //   missing_subjects: isNew
        //     ? `${parseInt(dt.required_subjects) - parseInt(dt.approved_subjects)}`
        //     : `${parseFloat(dt.required_subjects) - parseFloat(dt.approved_subjects)}`,

        //   detail: '',
        // };
      });
    }
  }, [data]);

  return (
    <Grid component={MainCard}>
      <Grid>
        {studentCareer && (
          <Typography variant="h2">
            {studentCareer ? studentCareer.education_level.edu_name : obtainCareerDefault()}
          </Typography>
        )}
        {isLoading ? (
          <SkeletonTable />
        ) : (
          <>
            <Grid style={{ marginTop: 10 }}>
              <Typography>{t('students.academic_counseling.career_management')}</Typography>
            </Grid>
            <Grid item xs={12} className={classes.separate}>
              <Typography variant="h3" align="right" className={classes.separate}>
                {porcentageApprovedSubject.toFixed(2)}%
              </Typography>
              {/* Barra de progreso de materia */}
              <Grid item xs={12} className={classes.separate}>
                <LinearProgress
                  variant="determinate"
                  value={porcentageApprovedSubject}
                  sx={{
                    borderRadius: '15px',
                    bgcolor: theme.palette.success.light,
                    '& .MuiLinearProgress-bar': { bgcolor: theme.palette.success.dark },
                  }}
                />
              </Grid>
              {data?.curriculumNew ? (
                <SemesterTable
                  data={datafetch! as Semester[]}
                  headers={{
                    semester: t('students.academic_counseling.semester'),
                    required_subjects: t('students.academic_counseling.required'),
                    approved_subjects: t('students.academic_counseling.approved'),
                    missing_subjects: t('students.academic_counseling.not_attendance'),
                    detail: '',
                  }}
                  totalRowKeys={['required_subjects', 'approved_subjects', 'missing_subjects']}
                  keyExtractor={(item) => item.semester}
                  isLoading={false}
                  isFetching={false}
                  error={undefined}
                  numHeader={8}
                  page={5}
                  perPage={5}
                  total={careerAcademicList.length}
                  customRenderers={{
                    semester: (item) =>
                      `${t(`students.academic_counseling.semester`)} ${item.semester}`,
                    detail: (row) => (
                      <Tooltip title={t('students.academic_counseling.view_details')}>
                        <IconButton
                          onClick={() => handleDetailMatter(parseInt(row.semester))}
                          aria-label={t('students.academic_counseling.view_details')}
                          color="default"
                        >
                          <IconClipboardList size={20} />
                        </IconButton>
                      </Tooltip>
                    ),
                  }}
                />
              ) : (
                <AreasTable
                  data={datafetch! as Area[]}
                  headers={{
                    id: 'ID',
                    ar_name: t('students.academic_counseling.area'),
                    required_subjects: t('students.academic_counseling.required'),
                    approved_subjects: t('students.academic_counseling.approved'),
                    missing_subjects: t('students.academic_counseling.not_attendance'),
                    detail: '',
                  }}
                  totalRowKeys={['required_subjects', 'approved_subjects', 'missing_subjects']}
                  keyExtractor={(item) => item.ar_name}
                  isLoading={false}
                  isFetching={false}
                  error={undefined}
                  numHeader={8}
                  page={5}
                  perPage={5}
                  total={careerAcademicList.length}
                  customRenderers={{
                    detail: (row) => (
                      <Tooltip title={t('students.academic_counseling.view_details')}>
                        <IconButton
                          onClick={() => handleDetailMatter(row.id!, row.ar_name!)}
                          aria-label={t('students.academic_counseling.view_details')}
                          color="default"
                        >
                          <IconClipboardList size={20} />
                        </IconButton>
                      </Tooltip>
                    ),
                  }}
                />
              )}
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default AcademicCareerTable;
