import * as Yup from 'yup';

export const justifyFormSchema = Yup.object().shape({
  appDescription: Yup.string().required('La descripción es requerida'),
  userId: Yup.number().required('Usuario es requerido'),
  faultAssistans: Yup.array()
    .min(1, 'Ingresa al meno un dia para justificar')
    .required('asistencia requerida'),
  studentRecordId: Yup.number().required('Requerido record Id'),
  fileUrl: Yup.string().required('Archivo es requerido'),
});
