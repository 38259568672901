import { Switch, Redirect } from 'react-router-dom';

import { useAppSelector } from '../../../../hooks/useRedux';

import PositionsScreen from '../screens/PositionsScreen';
import AddPositionScreen from '../screens/AddPeriodScreen';
import EditPositionScreen from '../screens/EditPeriodScreen';

import { permissionKey, subPermissionKeys } from '../constants/permissions';

import GuardRoute from '../../../../router/guards/GuardRoute';

const PositionsRouter = () => {
  const { currentModule } = useAppSelector((state) => state.access);

  const hasPermission = permissionKey in currentModule?.permissions!;

  if (!hasPermission) return <Redirect to="/dashboard" />;

  return (
    <Switch>
      <GuardRoute
        exact
        path="/administrar-cargos"
        component={PositionsScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.read}
      />

      <GuardRoute
        exact
        path="/administrar-cargos/agregar"
        component={AddPositionScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.create}
      />

      <GuardRoute
        exact
        path="/administrar-cargos/:positionId/editar"
        component={EditPositionScreen}
        permissionKey={permissionKey}
        subPermissionKey={subPermissionKeys.edit}
      />
    </Switch>
  );
};

export default PositionsRouter;
