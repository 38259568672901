import {
  Table,
  Grid,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import { renderErrorOrEmptyRow } from '../../../../shared/helpers/render';
import { AcademicFormation } from '../../interfaces/academicFormation.interface';
import { IconX } from '@tabler/icons';
import { deleteListAcademicFormation } from '../../slices/requisitionsSlice';
import { useAppDispatch } from '../../../../../hooks/useRedux';
import { IconFile } from '@tabler/icons';
import { useTranslation } from 'react-i18next';
import EditButton from '../../../../shared/components/tables/buttons/EditButton';

interface props {
  data: AcademicFormation[];
  readOnly?: boolean;
  selectAcademicFormation?: (id: number) => void;
}

const AcademicFormationTable = (props: props) => {
  const { t } = useTranslation();

  const { data, readOnly, selectAcademicFormation } = props;
  const dispatch = useAppDispatch();

  const renderRow = (p: AcademicFormation) => (
    <TableRow className="table-row" key={data.indexOf(p)}>
      <TableCell align="center" width="5%">
        {data.indexOf(p) + 1}
      </TableCell>
      <TableCell width="10%">{p.certificate_title}</TableCell>
      <TableCell width="10%">{p.institute_id.name}</TableCell>
      <TableCell width="10%" align="center">
        {p.graduation_date}
      </TableCell>
      <TableCell width="10%" align="center">
        {p.city_id.name}
      </TableCell>
      <TableCell width="10%" align="center">
        {p.education_calification}
      </TableCell>
      <TableCell width="10%" align="center">
        {p.type_education_id.name}
      </TableCell>
      <TableCell width="10%" align="center">
        {p.document_url && (
          <a href={p.document_url} target="_blank" rel="noreferrer">
            <Tooltip title={t('academic.requisition.edit.wizard.step2.add_academic_form.see_doc')}>
              <IconButton>
                <IconFile />
              </IconButton>
            </Tooltip>
          </a>
        )}
        {/* {p.document_url} */}
      </TableCell>

      {!readOnly && (
        <TableCell align="center" width="5%">
          <Tooltip title={t('academic.requisition.edit.wizard.step2.add_academic_form.exit')}>
            <IconButton>
              <IconX
                onClick={() => {
                  dispatch(deleteListAcademicFormation(p));
                }}
                color="red"
              />
            </IconButton>
          </Tooltip>
          {selectAcademicFormation && (
            <EditButton
              handleEdit={() => {
                selectAcademicFormation(p.id!);
              }}
            />
          )}
        </TableCell>
      )}
    </TableRow>
  );

  const renderRows = () => {
    return <>{data.map(renderRow)}</>;
  };

  return (
    <>
      <Grid container justifyContent="center">
        <Grid item sm={12} overflow={'hidden'}>
          <>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">#</TableCell>
                    <TableCell align="center">
                      {t('academic.requisition.edit.wizard.step2.add_academic_form.title')}
                    </TableCell>
                    <TableCell align="center">{t('Instituto')}</TableCell>
                    <TableCell align="center">
                      {t('academic.requisition.edit.wizard.step2.add_academic_form.grad_date')}
                    </TableCell>
                    <TableCell align="center">
                      {t('academic.requisition.edit.wizard.step2.add_academic_form.city')}
                    </TableCell>
                    <TableCell align="center">
                      {t('academic.requisition.edit.wizard.step2.add_academic_form.grade')}
                    </TableCell>
                    <TableCell align="center">
                      {t('academic.requisition.edit.wizard.step2.add_academic_form.ed_type')}
                    </TableCell>
                    <TableCell align="center">
                      {t('academic.requisition.edit.wizard.step2.add_academic_form.document')}
                    </TableCell>
                    {selectAcademicFormation && (
                      <TableCell align="center">
                        {t('shared.search_paginated_table.table.options')}
                      </TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {renderRows()}
                  {data.length === 0 && renderErrorOrEmptyRow(11)}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        </Grid>
      </Grid>
    </>
  );
};

export default AcademicFormationTable;
