import {
  useState,
  //useMemo
} from 'react';
import {
  Autocomplete,
  Grid,
  TextField,
  Button,
  Stack,
  CircularProgress,
  Alert,
  Paper,
  Typography,
  Skeleton,
  InputLabel,
} from '@material-ui/core';
import { DataGrid, GridColDef, GridRowParams, GridSelectionModel } from '@material-ui/data-grid';
import {
  useHistory,
  useParams,
  //useLocation
} from 'react-router-dom';
import { useAppSelector } from '../../../../hooks/useRedux';
import {
  useGetStudentNivelationCoursesQuery,
  useAddDebtNivelationMutation,
  useGetApprovesAndReprovesSubjectsNivelationQuery,
  useAddRegisterNivelationMutation,
  //useGetPeriodsNivelationQuery,
} from '../slices/registerNivelationApiSlice';
import { useGetCatalogByAcronymQuery } from '../../../shared/slices/catalogApi';
import { useGetAvailablesCampusQuery } from '../../campus/slices/campusApiSlice';

import { useFormik } from 'formik';
// import SkeletonRegister from './skeletons/SkeletonRegister';
import { HourhandsByPeriod } from '../../periods/interfaces/periods.interfaces';
import { Catalog } from '../../../shared/interfaces/catalogs.interfaces';
import { useGetVigentsHourhandsQuery } from '../../periods/slices/periodsApiSlice';
import ListApprobesFaileds from './ListApprobesFaileds';
import ChipStatus from './ChipStatus';
//import TextFieldFetching from '../../../shared/components/TextFieldFetching';
import { getFormatHourhand } from '../helper/helper';
import WarningAlert from '../../../shared/components/alerts/WarningAlert';

const RegisterStudent = () => {
  const { currentProfile } = useAppSelector((state) => state.access);

  const history = useHistory();
  //const location = useLocation();

  //const newLocation = useMemo(() => location.pathname.split('/'), [location.pathname]);

  const {
    studentId,
    //periodId: periodIdParam
  } = useParams<{
    studentId: string;
    //periodId: string;
  }>();

  const [periodId] = useState<number | undefined>(0);

  // const [page, setPage] = useState(1);
  // const [perPage, setPerPage] = useState(5);
  // const [search, setSearch] = useState('');

  /* const [periodId, setPeriodId] = useState(2); */
  const [modality, setModality] = useState('');
  const [hourhand, setHourhand] = useState('');
  const [campusId, setCampusId] = useState('');

  //const [isRegistering, setIsRegistering] = useState(false);
  const [warning, setWarning] = useState(false);

  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);

  const [
    addRegisterNivelation,
    { isLoading: loadingPost, isError: errorAdd, error: errorMSGAdd, isSuccess: successAdd },
  ] = useAddRegisterNivelationMutation();
  const [
    addDebtNivelation,
    { isLoading: loadingDebt, isError: errorDebt, error: errorMSGDebt, isSuccess: successDebt },
  ] = useAddDebtNivelationMutation();

  //Modalidades
  const {
    data: modalitiesAvailables = [],
    isLoading: isLoadingMOA,
    // isFetching: isFetchingMOA,
  } = useGetCatalogByAcronymQuery({
    profileId: currentProfile?.profileId!,
    acronym: 'M',
  });

  //horarios asignados al periodo
  const {
    data: hourhands = [],
    // isLoading: isLoadingH,
    isFetching: isFetchingH,
    // error: hourhandsByPeriodError,
  } = useGetVigentsHourhandsQuery(
    {
      profileId: currentProfile?.profileId!,
      period_id: periodId,
    },
    {
      refetchOnMountOrArgChange: 30,
      skip: !periodId,
    }
  );

  const {
    data: studentData,
    // isLoading: loadingSD,
    isFetching: fetchingSD,
  } = useGetStudentNivelationCoursesQuery(
    {
      profileId: currentProfile?.profileId!,
      modality: Number(modality!) || undefined,
      hourhand: Number(hourhand!) || undefined,
      studentRecord: Number(studentId),
      period_id: periodId,
      campus: Number(campusId) || undefined,
    },
    {
      refetchOnMountOrArgChange: true,
      //skip: !periodId,
    }
  );

  //campus
  const {
    isLoading: loadingCampus,
    //error: errorCampus,
    data: campus = [],
  } = useGetAvailablesCampusQuery(
    {
      profileId: currentProfile?.profileId!,
    },
    { refetchOnMountOrArgChange: 30 }
  );

  const { data: approbesFailedCourses, isLoading: isLoadingApprobes } =
    useGetApprovesAndReprovesSubjectsNivelationQuery(
      {
        user_profile_id: currentProfile?.profileId!,
        student_id: Number(studentData?.studentRecord.student_id),
        student_record_id: Number(studentData?.studentRecord.id),
      },
      {
        skip: !studentData ? true : false,
      }
    );

  //periodos
  // const {
  //   data: periods,
  //   isLoading: loadingPeriods,
  //   //isFetching: fetchingPeriods,
  // } = useGetPeriodsNivelationQuery(
  //   {
  //     dataParam: 'all',
  //     isVigent: 1,
  //   },
  //   { refetchOnMountOrArgChange: 30 }
  // );

  const student = studentData?.studentRecord;

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      disableColumnMenu: true,
      disableReorder: true,
      hide: true,
    },
    {
      field: 'mat_name',
      headerName: 'Materia',
      disableColumnMenu: true,
      disableReorder: true,
      sortable: false,
      flex: 1.5,
    },
    {
      field: 'start_date',
      headerName: 'Fecha Inicio.',
      disableColumnMenu: true,
      disableReorder: true,
      sortable: false,
      flex: 1.5,
    },
    {
      field: 'end_date',
      headerName: 'Fecha Fin.',
      disableColumnMenu: true,
      disableReorder: true,
      sortable: false,
      flex: 1.5,
    },
    {
      field: 'periodo',
      headerName: 'Período',
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
      headerAlign: 'center',
    },
    {
      field: 'sede',
      headerName: 'Sede',
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'aula',
      headerName: 'Aula',
      disableColumnMenu: true,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'paralelo',
      headerName: 'Paralelo',
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'horario',
      headerName: 'Horario',
      flex: 2,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'occupied',
      headerName: 'Registrados',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'max_capacity',
      headerName: 'Capacidad Máxima',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
    },
    // {
    //   field: 'periodoAnterior',
    //   headerName: 'Periodo Anterior',
    //   width: 170,
    //   disableColumnMenu: true,
    // },
    // {
    //   field: 'periodo',
    //   headerName: 'Periodo Actual',
    //   width: 160,
    //   disableColumnMenu: true,
    // },
  ];

  const rows = studentData?.courses
    .filter(function (val) {
      return (
        val.course_id != null &&
        val.approval_status !== '0' &&
        val.approval_status !== '13' &&
        val.approval_status !== '10'
      );
    })
    .map((mat) => ({
      id: mat.course_id,
      mat_name: mat.mat_name,
      status_matter: mat.status_matter || 'Por Cursar',
      approval_status: mat.approval_status || 0,
      cupo: (mat.occupied || 0) + '/' + mat.max_capacity,
      subject_id: mat.subject_id || '',
      period_id: mat.period_id || Number(periodId),
      sede: mat.cam_name,
      aula: mat.cl_name,
      paralelo: mat.par_name,
      //periodoAnterior: mat.lastNamePeriod,
      periodo: mat.perCurrent,
      max_capacity: +mat.max_capacity,
      occupied: +mat.occupied,
      de_balance_value: mat.de_balance_value,
      debt_course: mat.debt_course,
      //horario: mat.hourhand.hour_description,
      horario: getFormatHourhand(mat.hourhand),
      start_date: mat.start_date,
      end_date: mat.end_date,
    }));

  const localtext = {
    footerTotalVisibleRows: (visibleCount: any, totalCount: any) =>
      `${visibleCount.toLocaleString()} de ${totalCount.toLocaleString()} Materias`,
    noRowsLabel: 'No existen materias disponibles',
    footerRowSelected: (count: any) =>
      count !== 1
        ? `${count.toLocaleString()} Materias Seleccionadas`
        : `${count.toLocaleString()} Materia Seleccionada`,
    MuiTablePagination: {
      labelDisplayedRows: ({ from, to, count }: { from: number; to: number; count: number }) =>
        `${from} - ${to} de ${count}`,
    },
  };

  const fullName =
    student?.student.user.person.pers_firstname +
    ' ' +
    (student?.student.user.person.pers_secondname || '') +
    ' ' +
    student?.student.user.person.pers_first_lastname +
    ' ' +
    (student?.student.user.person.pers_second_lastname || '');

  /* const handleRegister = async () => {
        //setIsRegistering(true);
        if (selectionModel.length === 0) {
            return setWarning(true);
        } else {
            setWarning(false);
            console.log(selectionModel)
            const cursos = selectionModel.map(function (val) { return parseInt(val.toString()) });
            await addCourseNivelation({
                studentPayload: {
                    courseStudent: studentData?.courseStudent!,
                    courses: cursos,
                    period_id: periodId,
                    student_record_id: studentData?.studentRecord.id!,
                    user_profile_id: currentProfile?.profileId!,
                }
            });
        }
    } */

  const { handleSubmit } = useFormik({
    initialValues: {
      registerNiv: [],
      courseStudent: studentData?.courseStudent!,
      courses: [],
      period_id: periodId,
      student_record_id: studentData?.studentRecord.id!,
      user_profile_id: currentProfile?.profileId!,
    },
    onSubmit: async (values) => {
      if (selectionModel.length === 0) {
        return setWarning(true);
      }
      const cursos: any[] = [];
      selectionModel.forEach((id) => {
        rows?.forEach((row) => {
          if (row.id === id) {
            cursos.push({
              course_id: row.id,
              subject_id: row.subject_id,
              approval_status: row.approval_status,
              de_balance_value: row.de_balance_value,
              debt_course: row.debt_course,
            });
          }
        });
      });
      await addRegisterNivelation({
        RegisterStudent: {
          user_profile_id: values.user_profile_id,
          registerNiv: cursos.map((list) => ({
            courseStudent: studentData?.courseStudent!,
            courses: [list],
            student_record_id: studentData?.studentRecord.id!,
            period_id: Number(rows![0].period_id) || Number(periodId),
          })),
        },
      });
    },
  });

  const { handleSubmit: handleSubmitDebt } = useFormik({
    initialValues: {
      courses: [],
      courseStudent: studentData?.courseStudent!,
      period_id: periodId,
      student_record_id: studentData?.studentRecord.id!,
      user_profile_id: currentProfile?.profileId!,
    },
    onSubmit: async () => {
      if (selectionModel.length === 0) {
        return setWarning(true);
      }
      const cursos: any[] = [];
      selectionModel.forEach((id) => {
        rows?.forEach((row) => {
          if (row.id === id) {
            cursos.push({
              course_id: row.id,
              subject_id: row.subject_id,
              approval_status: row.approval_status,
              de_balance_value: '0.0000',
              debt_course: 28800,
            });
          }
        });
      });
      await addDebtNivelation({
        studentDebtPayload: {
          user_profile_id: currentProfile?.profileId!,
          student_record_id: studentData?.studentRecord.id,
          person_id: Number(studentData?.studentRecord.student.user.person_id!),
          education_level_id: Number(studentData?.studentRecord.education_level_id!),
          period_id: Number(rows![0].period_id) || Number(periodId),
          courses: cursos,
        },
      });
      /* const payload = {
                user_profile_id: 0,
                student_record_id: 0,
                person_id: 0,
                education_level_id: 0,
                period_id: 0,
                courses: cursos
            }

            console.log('payload debt', payload) */
    },
  });
  return (
    <>
      {/* {loadingSD || isLoadingMOA || isLoadingH ? (
        <SkeletonRegister />
      ) : ( */}
      <Grid container>
        {errorAdd && (
          <Grid item xs={12} mb={2}>
            {/* <Alert severity="warning">{errorMSGAdd as string}</Alert> */}
            <WarningAlert message={errorMSGAdd as any} />
          </Grid>
        )}
        {errorDebt && (
          <Grid item xs={12} mb={2}>
            <WarningAlert message={errorMSGDebt as any} />
          </Grid>
        )}
        {successAdd ? (
          <Grid item md={12} sx={{ mt: 2 }}>
            <Alert severity="success">Materia de Nivelación Agregada.</Alert>
          </Grid>
        ) : (
          ''
        )}
        {successDebt ? (
          <Grid item md={12} sx={{ mt: 2 }}>
            <Alert severity="success">Deuda Generada.</Alert>
          </Grid>
        ) : (
          ''
        )}
        <Grid container spacing={2} sx={{ mb: 4 }}>
          <Grid item sm={12} md={4}>
            <Stack direction="row" spacing={2}>
              <Paper>
                <InputLabel>Estudiante</InputLabel>
                <Typography variant="h5">
                  {!fullName.includes('undefined') ? (
                    fullName
                  ) : (
                    <Skeleton variant="text" width={60} />
                  )}
                </Typography>
              </Paper>
              <Paper>
                {fullName.includes('undefined') ? (
                  <Skeleton variant="text" width={65} height={40} />
                ) : (
                  <ChipStatus
                    text={studentData?.courseStudent! === 0 ? 'Nuevo Ingreso' : 'Repetidor'}
                    nuevoIngreso={studentData?.courseStudent! === 0 ? true : false}
                  />
                )}
              </Paper>
            </Stack>
            <Stack direction="row" spacing={2}>
              <Paper>
                <InputLabel>Carrera</InputLabel>
                <Typography variant="h5">
                  {studentData?.studentRecord.education_level?.edu_name}
                </Typography>
              </Paper>
            </Stack>
          </Grid>
          <Grid item xs={12} md={8}>
            {approbesFailedCourses && !isLoadingApprobes ? (
              <ListApprobesFaileds
                approbed={approbesFailedCourses.aprobado}
                failed={approbesFailedCourses.reprobado}
              />
            ) : (
              <ListApprobesFaileds isLoading={true} />
            )}
          </Grid>
        </Grid>
        <Grid container sx={{ mb: 3 }}>
          <Grid item container spacing={2}>
            <Grid item container xs={12} md={4}>
              <Autocomplete
                options={modalitiesAvailables}
                loading={isLoadingMOA}
                loadingText="Cargando modalidades..."
                getOptionLabel={(option) => option.cat_name}
                renderInput={(params) => <TextField {...params} label="Modalidades" />}
                defaultValue={modalitiesAvailables.find((p) => p.id === +modality) || null}
                onChange={(_, value: unknown) => {
                  const newValue = value as Catalog; // auxiliar a causa de que en algun lado se convierte el value en string, solo para evitar que se marque como error
                  setModality(newValue?.id.toString() || '');
                  //   console.log(modality);
                  setSelectionModel([]);
                }}
                fullWidth
                // clearIcon={null}
              />
            </Grid>
            <Grid item container xs={12} md={4}>
              <Autocomplete
                options={campus}
                loading={loadingCampus}
                loadingText="Cargando Sedes..."
                getOptionLabel={(option) => option.cam_name}
                renderInput={(params) => <TextField {...params} label="Campus" />}
                //defaultValue={campus?.find((p) => p.id === +campusId) || null}
                onChange={(_, value: unknown) => {
                  const newValue = value as Catalog; // auxiliar a causa de que en algun lado se convierte el value en string, solo para evitar que se marque como error
                  setCampusId(newValue?.id.toString() || '');
                  //   console.log(modality);
                  setSelectionModel([]);
                }}
                fullWidth
                // clearIcon={null}
              />
            </Grid>
            <Grid item container xs={12} md={4}>
              <Autocomplete
                options={hourhands}
                loading={isFetchingH}
                loadingText="Cargando horarios..."
                getOptionLabel={(option) => option.hour_description}
                renderInput={(params) => <TextField {...params} label="Horarios" />}
                defaultValue={hourhands.find((p) => p.id === +hourhand) || null}
                onChange={(_, value: unknown) => {
                  const newValue = value as HourhandsByPeriod; // solo para evitar que se marque como error la ausencia de id en un string
                  setHourhand(newValue?.id.toString() || '');
                  setSelectionModel([]);
                }}
                fullWidth
                // clearIcon={null}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid container>
          {fetchingSD ? (
            <div style={{ width: '100%', textAlign: 'center' }}>
              <CircularProgress />
            </div>
          ) : (
            <div style={{ height: 'auto', width: '100%' }}>
              {studentData?.courseStudent! === 0 ? (
                <DataGrid
                  rows={rows!}
                  columns={columns}
                  pageSize={5}
                  rowsPerPageOptions={[5]}
                  checkboxSelection
                  isRowSelectable={(params: GridRowParams) =>
                    (Number(params.row?.approval_status) === 0 ||
                      (Number(params.row?.approval_status) !== 13 &&
                        Number(params.row?.approval_status) !== 10)) &&
                    params.row?.max_capacity > params.row?.occupied
                  }
                  localeText={localtext}
                  autoHeight={true}
                  onSelectionModelChange={(newSelectionModel) => {
                    setSelectionModel(newSelectionModel);
                  }}
                  selectionModel={selectionModel}
                  disableSelectionOnClick
                  style={{
                    border: 0,
                  }}
                  disableColumnSelector
                  disableDensitySelector
                  disableColumnFilter
                />
              ) : (
                <DataGrid
                  rows={rows!}
                  columns={columns}
                  pageSize={5}
                  rowsPerPageOptions={[5]}
                  checkboxSelection
                  isRowSelectable={(params: GridRowParams) =>
                    (Number(params.row?.approval_status) === 0 ||
                      (Number(params.row?.approval_status) !== 13 &&
                        Number(params.row?.approval_status) !== 10)) &&
                    params.row?.max_capacity > params.row?.occupied
                  }
                  localeText={localtext}
                  autoHeight={true}
                  onSelectionModelChange={(newSelectionModel) => {
                    setSelectionModel(newSelectionModel);
                  }}
                  selectionModel={selectionModel}
                  disableSelectionOnClick
                  style={{
                    border: 0,
                  }}
                  disableColumnSelector
                  disableDensitySelector
                  disableColumnFilter
                />
              )}
            </div>
          )}
        </Grid>
        <Grid container>
          {warning ? (
            <Grid item sm={12} md={12} sx={{ mt: 2 }}>
              <Alert severity="warning"> Debe seleccionar una o más materias.</Alert>
            </Grid>
          ) : (
            ''
          )}
          <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
            <Button variant="outlined" size="large" onClick={() => history.goBack()}>
              Cancelar
            </Button>
            {studentData?.courseStudent! === 0 ? (
              <form onSubmit={handleSubmit}>
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  color="primary"
                  disabled={
                    fetchingSD || loadingPost || rows?.length === 0 || selectionModel.length === 0
                  }
                  endIcon={loadingPost && <CircularProgress size={20} />}
                  /* onClick={handleSubmit} */
                >
                  Registrar
                </Button>
              </form>
            ) : (
              <form onSubmit={handleSubmitDebt}>
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  color="primary"
                  disabled={
                    fetchingSD || loadingDebt || rows?.length === 0 || selectionModel.length === 0
                  }
                  endIcon={loadingDebt && <CircularProgress size={20} />}
                  /*  onClick={handleSubmit} */
                >
                  Generar Deuda
                </Button>
              </form>
            )}
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default RegisterStudent;
