import { useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';

import { useGetEventsQuery } from '../slices/eventNewApiSlice';
import {
  openDeleteModalEventNew,
  setShowContainerImage,
  setEventNewsToAddImage,
  updateEventNewTable,
} from '../slices/eventNewSlice';
import { useAppDispatch } from '../../../../hooks/useRedux';
import SearchPaginatedTable, {
  FilterArrow,
} from '../../../shared/components/tables/SearchPaginatedTable';
import AddButton from '../../../shared/components/tables/buttons/AddButton';
import { useAppSelector } from '../../../../hooks/useRedux';
import StatusChip from '../../../shared/components/badges/StatusChip';
import DeleteButton from '../../../shared/components/tables/buttons/DeleteButton';
import EditButton from '../../../shared/components/tables/buttons/EditButton';
import CameraAddEventNew from '../../../shared/components/tables/buttons/CameraEventNewAdd';
import PreviewEventNewImage from '../../../shared/components/tables/buttons/PreviewEventNewImage';
import { useTableSearch } from '../../../../hooks/useTableSearch';
import useFormatDate from '../../../shared/hooks/useFormatDate';
import useSharedGuard from '../../../../hooks/useSharedGuard';
import { subPermissionKeys } from '../constants/permissions';

const EventsNewsTable = function () {
  const dispatch = useAppDispatch();

  const history = useHistory();

  const { formatTableDate } = useFormatDate();

  const { path } = useRouteMatch();
  const { currentProfile } = useAppSelector((state) => state.access);

  const [filterArrow, setFilterArrow] = useState<FilterArrow>(null!);

  const { page, perPage, search } = useAppSelector((state) => state.eventNew.eventNewTable);
  const { setPage, setPerPage, setSearch } = useTableSearch({
    page,
    perPage,
    search,
    updateFunction: updateEventNewTable,
  });

  const { hasAccess } = useSharedGuard(subPermissionKeys);

  const { data, isLoading, isFetching, error } = useGetEventsQuery({
    page: page,
    perPage: perPage,
    search: search,
    profileId: currentProfile?.profileId!,
    ...(filterArrow && { sort: filterArrow.name, type_sort: filterArrow.direction }),
  });

  const EVENT_EVENTS = 'events';

  const datafetch = data?.data!.map((value, index) => ({
    id: index + ((page - 1) * perPage + 1),
    type: value.type === EVENT_EVENTS ? 'Evento' : 'Noticias',
    title: value.title,
    dates: {
      start_date: value.start_date,
      end_data: value.end_date,
    },
    status: value.status_id,
    options: value,
  }));

  const handleDelete = function (eventNewId: number) {
    dispatch(openDeleteModalEventNew(eventNewId));
  };

  const goToAdd = () => {
    history.push(`${path}/agregar`);
  };

  const handleImageAddOrPreview = function (eventNewId: number) {
    dispatch(setShowContainerImage(true));
    dispatch(setEventNewsToAddImage(eventNewId));
  };

  return (
    <SearchPaginatedTable
      data={datafetch!}
      headers={{
        id: 'N°',
        type: 'Tipo',
        title: 'Título',
        dates: 'Fecha inicio/fin',
        status: 'Estado',
        options: 'Opciones',
      }}
      keyExtractor={(item) => item.id.toString()}
      listFilterArrows={{
        type: 'type',
        title: 'title',
        dates: 'start_date',
        status: 'status_i',
      }}
      filterArrow={filterArrow}
      setFilterArrow={setFilterArrow}
      searchPlacehoder="Buscar eventos"
      isLoading={isLoading}
      isFetching={isFetching}
      error={error}
      perPage={perPage}
      setPerPage={setPerPage}
      total={data?.total || 0}
      page={page}
      setPage={setPage}
      numHeader={7}
      search={search}
      setSearch={setSearch}
      customHeadersCellsProperties={{
        options: {
          align: 'center',
        },
      }}
      customDataCellsProperties={{
        id: { align: 'center' },
        type: { align: 'center' },
        dates: { align: 'center' },
        status: { align: 'center' },
        options: {
          align: 'center',
        },
      }}
      customRenderers={{
        dates: ({ dates }) => (
          <Grid container justifyContent="center" item gap={1}>
            <Typography> {formatTableDate(dates.start_date)} - </Typography>
            <Typography>{formatTableDate(dates.end_data)} </Typography>
          </Grid>
        ),
        status: ({ status }) => <StatusChip statusId={Number(status)} />,
        options: (row) => (
          <>
            {hasAccess('updateEvent') && (
              <Link to={`${path}/${row.options.id}/editar`}>
                <EditButton />
              </Link>
            )}

            {/* no se pueden borrar eventos y noticias luego de publicar */}
            {hasAccess('destroyEvent') && false && (
              <DeleteButton handleDelete={() => handleDelete(row.options.id)} />
            )}

            {!hasAccess(['destroyEvent', 'updateEvent']) && 'Ninguna'}

            {row.options.url_image === null ? (
              <>
                {hasAccess('updatePhotoByEvent') && (
                  <CameraAddEventNew
                    handleEventNew={() => handleImageAddOrPreview(row.options.id)}
                  />
                )}
              </>
            ) : (
              <>
                {hasAccess('updatePhotoByEvent') && (
                  <PreviewEventNewImage
                    handlePreview={() => handleImageAddOrPreview(row.options.id)}
                  />
                )}
              </>
            )}
          </>
        ),
      }}
      ActionButtons={<>{hasAccess('storeEvent') && <AddButton size="large" onClick={goToAdd} />}</>}
    />
  );
};

export default EventsNewsTable;
