import projectPermissions from '../../../shared/constants/projectPermissions';

export const groupConfigurationPermissions = {
  groupKey: projectPermissions.groupConfiguration.groupKey,

  read: projectPermissions.groupConfiguration.read,
};

export const groupConfigurationStudentsPermissions = {
  groupKey: projectPermissions.groupConfigurationStudents.groupKey,

  setMassiveDiscounts: projectPermissions.groupConfigurationStudents.setMassiveDiscounts,
};
