import { useState } from 'react';
import Grid from '@material-ui/core/Grid';

import ProjectPrincipalTable from './ProjectPrincipalTable';
import { useGetProjectResearchPrincipalQuery } from '../../slices/InvestigationLinesApiSlice';
import { useAppSelector } from '../../../../../hooks/useRedux';
import { FilterArrow } from '../../../../shared/components/tables/SearchPaginatedTable';
import ProjectPrincipalFilter from './ProjectPrincipalFilter';
// import typeProject from '../../constants/lists/typeProject';

export interface FilterData {
  project: FilterArrow;
}

export interface FilterPayloadsPrincipal {
  // typeProject: string;
  statusProject: string;
  // yearProject: string;
  // lineResearchId: string;
  // typeResearch: string;
}

export interface FilterPayloads {
  typeProject: string;
  statusProject: string;
  yearProject: string;
  lineResearchId: string;
  typeResearch: string;
}

const ProjectMain = () => {
  const { page, perPage, search, filterArrow } = useAppSelector(
    (state) => state.investigationLines.projectPrincipalTable
  );

  const [filterData, setFilterData] = useState<FilterPayloadsPrincipal>();

  // const [filterData, setFilterData] = useState<FilterData>(null!);
  const [sort, setSort] = useState<FilterData>({
    project: { name: 'id', direction: 'asc' },
  });

  // const { currentModule } = useAppSelector((state) => state.access);

  // console.log(currentModule);

  const { data, error, isLoading, isFetching } = useGetProjectResearchPrincipalQuery({
    search,
    sort: filterArrow?.name || '',
    type_sort: filterArrow?.direction || '',
    page,
    size: perPage,
    // ...(filterData?.lineResearchId && { lineResearchId: filterData?.lineResearchId }),
    ...(filterData?.statusProject && { statusProject: filterData?.statusProject }),
    // ...(filterData?.typeProject
    //   ? { typeProject: filterData?.typeProject }
    //   : {
    //       typeProject:
    //         typeProject.find(({ label }) => label === currentModule?.name)?.keyword ||
    //         'vinculacion',
    //     }),
    // ...(filterData?.typeProject && { typeProject: filterData?.typeProject }),
    // ...(filterData?.yearProject && { yearProject: filterData?.yearProject }),
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} my={1}>
        <ProjectPrincipalFilter dispatchFilterData={setFilterData} />
      </Grid>
      <Grid item xs={12}>
        <ProjectPrincipalTable
          data={data!}
          isLoading={isLoading}
          error={error}
          isFetching={isFetching}
          setSort={setSort}
          sort={sort}
        />
      </Grid>
    </Grid>
  );
};

export default ProjectMain;
