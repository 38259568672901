import { useEffect, useState, ChangeEvent } from 'react';
import Autocomplete from '@material-ui/core/Autocomplete';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import useTheme from '@material-ui/core/styles/useTheme';
import { useFormik } from 'formik';
import Divider from '@material-ui/core/Divider';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useTranslation } from 'react-i18next';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';
import { PhotoCamera } from '@material-ui/icons';
import { IconTrash } from '@tabler/icons';
import { SerializedError } from '@reduxjs/toolkit';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { handleNextStep } from '../../constants/navigateFunctionsForm';
import {
  useLazyGetSocialNetworksByPersonQuery,
  useSaveAvatarStudentMutation,
} from '../../slices/studentsApiSlice';
import InputLabel from '../../../../shared/components/forms/InputLabel';
import {
  PersonalInfo as PersonalInfoInterface,
  PersDisability,
} from '../../interfaces/studentsStore.interface';
import {
  useGetCatalogByAcronymQuery,
  useGetCatalogByKeywordQuery,
  useGetDisabilityAvailableQuery,
} from '../../../../shared/slices/catalogApi';
import LocalizedDatePicker from '../../../../shared/components/controlled/LocalizedDatePicker';
import { PersonalInfoFormSchema } from '../../constants/validationSchemas';
import { NamesTabs, PropsForm, listTabs } from './index';
import errorValidationFunction from '../../constants/errorValidation';
import useGetUrlDownload from '../../../../../hooks/useGetUrlDownload';
import { useGetRedlinksAvatarQueryQuery } from '../../../../shared/slices/redlinksApi';
import AddDisabilityForm from './AddDisabilityForm';
import TextFieldFetching from '../../../../shared/components/TextFieldFetching';
import LoadingButton from '../../../../shared/components/buttons/LoadingButton';
import noLoadedAvatar from '../../../../../assets/images/users/noloadedavatar.png';
import { useGetRoutesCampusQuery } from '../../../bus-route/slices/busRouteApiSlice';
import { BusRoute, BusRoutesCampus } from '../../../bus-route/interfaces/buses.interfaces';
import { BusRoutesStudent } from '../../interfaces/busRoute.interface';
import SocialNetworks from './SocialNetworks';
import { SocialNetwork } from '../../interfaces/studentRecordAcademic.interfaces';

const ThisTabName: NamesTabs = 'personal-info';

const useStyles = makeStyles((theme) => ({
  uploadFile: {
    borderRadius: 20,
    textAlign: 'center',
    width: '100%',
    height: '35vh',
    position: 'relative',
    background: `linear-gradient(180deg, ${theme.palette.secondary.light} 0%, transparent 100%)`,
  },
  avatarSelected: {
    width: '170px',
    height: '170px',
    margin: '0 auto',
  },
  cardDisability: {
    background: `linear-gradient(90deg, ${theme.palette.secondary.light} 0%, transparent 7%)`,
  },
}));

const PersonalInfo = ({
  setData,
  data,
  isEdit,
  currentTab,
  errorValidation,
  setCurrentTab,
  errorObject,
  submit,
}: PropsForm) => {
  const { t } = useTranslation();

  const classes = useStyles();

  const { urlDownload } = useGetUrlDownload();

  const theme = useTheme();

  const [firstShow, setFirstShow] = useState(isEdit ? true : false);

  const [, setFile] = useState<string>('');

  const [target, setTarget] = useState<File>(null!);

  const [isAFormChange, setIsAFormChange] = useState(false);
  const [photo, setPhoto] = useState<string>(null!);

  const [campus, setCampus] = useState<BusRoutesCampus | null>(null);

  const [busRouteData, setBusRouteData] = useState<BusRoute[] | null>(null);

  // const { data: socialNetworksByPerson } = useGetSocialNetworksByPersonQuery({
  //   personId: data?.user.person_id!,
  // });

  const [getSocialNetworksByPerson, { isLoading: isLoadingSocialNetworkData }] =
    useLazyGetSocialNetworksByPersonQuery();

  // identification
  const {
    data: identificationsAvailables = [],
    isLoading: isLoadingIA,
    isFetching: isFetchingIA,
  } = useGetCatalogByAcronymQuery({
    acronym: 'TI',
  });

  // countries
  const { data: nacionalities = [], isLoading: isLoadingNacionalities } =
    useGetCatalogByAcronymQuery({
      acronym: 'NA',
    });

  //genders
  const {
    data: gendersAvailables = [],
    isLoading: isLoadingGA,
    isFetching: isFetchingGA,
  } = useGetCatalogByKeywordQuery({
    keyword: 'GE',
  });

  //marital status
  const { data: civilStatusAvailables = [], isLoading: isLoadingCSA } = useGetCatalogByAcronymQuery(
    {
      acronym: 'SM',
    }
  );

  //campus
  const { data: campusEcobus, isFetching: isFetchingCampusEcobus } = useGetRoutesCampusQuery({});
  //disability
  const { data: disability = [] } = useGetDisabilityAvailableQuery({});

  const [addPhoto, { isLoading: isLoadingImage }] = useSaveAvatarStudentMutation();

  const { data: redlinksAvatar } = useGetRedlinksAvatarQueryQuery(
    {
      identification: data?.pers_identification || '',
    },
    { skip: !isEdit || !data?.pers_identification }
  );

  const onChangeFile = async function (event: ChangeEvent<HTMLInputElement>) {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e: any) => {
        setFile(e.target.result);
      };
      reader.readAsDataURL(event.target.files[0]);
      setTarget(event.target.files[0]);
      await handleAddPhoto(event.target.files[0]);
    }
  };

  const notErrorForm = !errorObject?.some((e) => e.error);

  const isCompleteOthersTabs = errorObject?.length! >= listTabs.length - 1;

  const { getFieldProps, errors, touched, handleSubmit, setFieldValue, submitForm, values } =
    useFormik<
      PersonalInfoInterface & { pers_disabilities: PersDisability[] } & {
        bus_routes: BusRoutesStudent[];
      } & { social_networks: SocialNetwork[] }
    >({
      initialValues: {
        type_identification_id: isEdit ? data?.type_identification_id : '',
        pers_identification: isEdit ? data?.pers_identification : '',
        pers_firstname: isEdit ? data?.pers_firstname : '',
        pers_secondname: isEdit ? data?.pers_secondname : '',
        pers_first_lastname: isEdit ? data?.pers_first_lastname : '',
        pers_second_lastname: isEdit ? data?.pers_second_lastname : '',
        pers_email: isEdit ? data?.pers_email : '',
        pers_date_birth: isEdit ? new Date(data?.pers_date_birth) : new Date(),
        pers_gender: isEdit ? data?.pers_gender : '',
        status_marital_id: isEdit ? Number(data?.status_marital_id) : 0,
        nationality_id: isEdit ? Number(data?.nationality_id) || 0 : 0,
        stud_photo: isEdit ? data?.user.student.stud_photo : '',
        stud_photo_path: isEdit ? data?.user.student.stud_photo_path : '',
        pers_has_disability: isEdit ? Boolean(Number(data?.pers_has_disability)) : false,
        pers_cell: isEdit ? data?.pers_cell : '',
        pers_phone_home: isEdit ? data?.pers_phone_home : '',
        pers_disabilities: [] as PersDisability[],

        bus_routes: isEdit ? data?.bus_route : ([] as BusRoutesStudent[]),

        social_networks: [
          {
            social_keywork: 'sn-facebook',
            url: '',
          },
          {
            social_keywork: 'sn-instagram',
            url: '',
          },
          {
            social_keywork: 'sn-linkedin',
            url: '',
          },
          {
            social_keywork: 'sn-twitter',
            url: '',
          },
          {
            social_keywork: 'sn-tiktok',
            url: '',
          },
        ] as SocialNetwork[],
      },
      validationSchema: PersonalInfoFormSchema,
      onSubmit: async (values) => {
        const { pers_disabilities, bus_routes, social_networks, ...PersonalInfoData } = values;

        if (notErrorForm && isCompleteOthersTabs && isAFormChange) {
          setData((dta) => ({
            ...dta,
            personalInfo: {
              personalInfo: {
                ...PersonalInfoData,
              },
              persDisability: pers_disabilities,
              bus_routes: bus_routes,
              social_networks: social_networks,
            },
          }));
          // submit?.setSubmit();
          // window.scrollTo(0, 0);
        } else if (isAFormChange) {
          setData((dt) => ({
            ...dt,
            personalInfo: {
              personalInfo: {
                ...PersonalInfoData,
              },
              persDisability: pers_disabilities,
              bus_routes: bus_routes,
              social_networks: social_networks,
            },
          }));
          handleNextStep(setCurrentTab, currentTab, ThisTabName);
        }
        setIsAFormChange(false);
      },
    });

  const handleAddSocialNetwork = (social_keywork: string, value: string) => {
    const socialNetwork = values.social_networks.filter(
      (sn) => sn.social_keywork !== social_keywork
    );

    setFieldValue('social_networks', [
      ...socialNetwork,
      {
        social_keywork,
        url: value,
      },
    ]);
  };

  const handlerAddDisability = (data: PersDisability) => {
    setFieldValue('pers_disabilities', [
      ...values.pers_disabilities,
      {
        type_disability: data.type_disability,
        percentage_disability: data.percentage_disability,
        observation: data.observation,
      },
    ]);
  };

  const handlerDeleteDisability = (itemIndex: number) => {
    const arrayData = values.pers_disabilities.filter((_, index) => !(index === itemIndex));
    setFieldValue('pers_disabilities', arrayData);
  };

  useEffect(() => {
    if (currentTab === ThisTabName) {
      setFirstShow(true);
    }

    if (firstShow && currentTab !== ThisTabName) {
      submitForm();
    }

    //eslint-disable-next-line
  }, [currentTab]);

  // Despacha un objecto a la tab si existe un error en el formulario
  useEffect(() => {
    errorValidationFunction(errors, ThisTabName, firstShow, errorValidation);

    //eslint-disable-next-line
  }, [errors]);

  useEffect(() => {
    if (isEdit) {
      const execute = async () => {
        try {
          const { data: dataSocialNetworks } = await getSocialNetworksByPerson({
            personId: data?.user.person_id!,
          });
          if (dataSocialNetworks && dataSocialNetworks.length > 0) {
            const socialNetworksNewArray = dataSocialNetworks.map((sn) => ({
              social_keywork: sn.social_network.cat_keyword,
              url: sn.url,
            }));
            setFieldValue('social_networks', socialNetworksNewArray);
          }
        } catch (er) {
          console.log(er);
        }
      };
      execute();
    }
    //eslint-disable-next-line
  }, [isEdit]);

  const handleAddPhoto = async (file: File) => {
    const result: { data: string } | { error: FetchBaseQueryError | SerializedError } =
      await addPhoto({
        file: file,
      });

    if ('error' in result === false) {
      const photo = 'data' in result ? result.data : '';
      setFieldValue('stud_photo_path', photo);
    }
  };

  useEffect(() => {
    if (isEdit) {
      data.disabilities?.map(({ id, pivot: { percentage_disability }, typ_dis_description }) =>
        handlerAddDisability({
          observation: typ_dis_description,
          percentage_disability: Number(percentage_disability),
          type_disability: id,
        })
      );
    }
    //eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    setIsAFormChange(true);
    //eslint-disable-next-line
  }, [values]);

  useEffect(() => {
    if (campusEcobus && !campus) {
      setCampus(campusEcobus[0]);
      setBusRouteData(
        campusEcobus.length && campusEcobus[0].bus_route && campusEcobus[0].bus_route.length
          ? campusEcobus[0].bus_route
          : null
      );
    }

    if (campus) {
      setBusRouteData(campus.bus_route && campus.bus_route.length ? campus.bus_route : null);
    }
    //eslint-disable-next-line
  }, [campus, campusEcobus]);

  useEffect(() => {
    if (isEdit && Array.isArray(campusEcobus) && data.bus_route.length > 0 && !campus) {
      const campusFind = campusEcobus.find(
        (campus) => Number(campus.id) === Number(data.bus_route?.[0]?.campus_id)
      );

      setCampus(campusFind || null);

      const routesFind = campusEcobus.find(
        (campus) => Number(campus.id) === Number(data.bus_route?.[0]?.campus_id)
      );

      setBusRouteData(routesFind ? routesFind.bus_route : null);
    }
    //eslint-disable-next-line
  }, [campus, campusEcobus, data]);

  const handleRemoveAvatar = () => {
    setTarget(null!);
    setFile('');
  };

  const handleClickAvatar = function () {
    document.getElementById('btnFile')?.click();
  };

  const handleSelectCampus = (value: BusRoutesCampus | null) => {
    setCampus(value || null);
  };

  useEffect(() => {
    if (redlinksAvatar?.result?.foto || values.stud_photo_path) {
      setPhoto(redlinksAvatar?.result?.foto || urlDownload + values.stud_photo_path);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.stud_photo_path, redlinksAvatar?.result?.foto]);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} xl={8}>
              <InputLabel>Subir foto *</InputLabel>
              <Grid item xs={12} md={12} container>
                <input id="btnFile" type="file" hidden accept="image/*" onChange={onChangeFile} />
                <label className={classes.uploadFile} style={{ cursor: 'pointer' }}>
                  <Grid item sx={{ mt: 2 }}>
                    <Avatar
                      className={classes.avatarSelected}
                      alt="foto de perfil"
                      sx={{ bgcolor: theme.palette.grey[200] }}
                      style={{ cursor: 'pointer' }}
                      src={photo}
                      onError={(e) => {
                        setPhoto(noLoadedAvatar);
                      }}
                    ></Avatar>
                  </Grid>
                  <Grid container item justifyContent="center">
                    <Grid item sx={{ mt: 4 }}>
                      <Button
                        variant="contained"
                        size="small"
                        disabled={isLoadingImage}
                        endIcon={isLoadingImage ? <CircularProgress size={20} /> : <PhotoCamera />}
                        onClick={handleClickAvatar}
                        color="primary"
                      >
                        {isLoadingImage && 'Subiendo imagen'}
                        {!target && !isLoadingImage && 'Seleccionar foto'}
                        {target && !isLoadingImage && 'Seleccionar otro'}
                      </Button>
                    </Grid>
                    <Grid item sx={{ mt: 4 }}>
                      <Button disabled={!!values?.stud_photo_path}>
                        <IconTrash onClick={handleRemoveAvatar} style={{ cursor: 'pointer' }} />
                      </Button>
                    </Grid>
                  </Grid>
                </label>
              </Grid>
              {errors?.stud_photo_path && touched?.stud_photo_path && (
                <FormHelperText error>{errors?.stud_photo_path}</FormHelperText>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <InputLabel>{t('Tipo de indentificacion')}</InputLabel>

              {isFetchingIA ? (
                <TextFieldFetching />
              ) : (
                <Autocomplete
                  options={identificationsAvailables || []}
                  loading={isLoadingIA || isFetchingIA}
                  loadingText={t('Cargando tipos de identificacion...')}
                  noOptionsText={t('No hay tipos de identificacion disponibles')}
                  getOptionLabel={(option) => option.cat_name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(
                        errors.type_identification_id && touched.type_identification_id
                      )}
                      placeholder={t('Seleccione un tipo de identificación')}
                    />
                  )}
                  value={
                    identificationsAvailables?.find(
                      ({ id }) => values.type_identification_id + '' === id + ''
                    ) || null
                  }
                  onChange={(_, value) => {
                    setFieldValue('type_identification_id', value?.id || '');
                  }}
                  fullWidth
                  clearIcon={null}
                />
              )}
              {errors.type_identification_id && touched.type_identification_id && (
                <FormHelperText error>{t(errors.type_identification_id)}</FormHelperText>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel>{t('Identificacion')}</InputLabel>
              <TextField
                {...getFieldProps('pers_identification')}
                error={Boolean(errors.pers_identification && touched.pers_identification)}
                placeholder={t('Ingrese la identificación')}
                fullWidth
              />
              {errors.pers_identification && touched.pers_identification && (
                <FormHelperText error>{t(errors.pers_identification)}</FormHelperText>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel>{t('Primer Nombre')}</InputLabel>
              <TextField
                {...getFieldProps('pers_firstname')}
                error={Boolean(errors.pers_firstname && touched.pers_firstname)}
                placeholder={t('Ingrese el primer nombre')}
                fullWidth
              />
              {errors.pers_firstname && touched.pers_firstname && (
                <FormHelperText error>{t(errors.pers_firstname)}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12} md={6}>
              <InputLabel>{t('Segundo Nombre')}</InputLabel>
              <TextField
                {...getFieldProps('pers_secondname')}
                error={Boolean(errors.pers_secondname && touched.pers_secondname)}
                placeholder={t('Ingrese el segundo nombre')}
                fullWidth
              />
              {errors.pers_secondname && touched.pers_secondname && (
                <FormHelperText error>{t(errors.pers_secondname)}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12} md={6}>
              <InputLabel>{t('Primer Apellido')}</InputLabel>
              <TextField
                {...getFieldProps('pers_first_lastname')}
                error={Boolean(errors.pers_first_lastname && touched.pers_first_lastname)}
                placeholder={t('Ingrese el primer apellido')}
                fullWidth
              />
              {errors.pers_first_lastname && touched.pers_first_lastname && (
                <FormHelperText error>{t(errors.pers_first_lastname)}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12} md={6}>
              <InputLabel>{t('Segundo Apellido')}</InputLabel>
              <TextField
                {...getFieldProps('pers_second_lastname')}
                error={Boolean(errors.pers_second_lastname && touched.pers_second_lastname)}
                placeholder={t('Ingrese el segundo apellido')}
                fullWidth
              />
              {errors.pers_second_lastname && touched.pers_second_lastname && (
                <FormHelperText error>{t(errors.pers_second_lastname)}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12} md={6}>
              <InputLabel>{t('Correo')}</InputLabel>
              <TextField
                {...getFieldProps('pers_email')}
                error={Boolean(errors.pers_email && touched.pers_email)}
                placeholder={t('Ingrese el correo')}
                fullWidth
              />
              {errors.pers_email && touched.pers_email && (
                <FormHelperText error>{t(errors.pers_email)}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12} md={6}>
              <InputLabel>{t('Fecha de nacimiento')}</InputLabel>
              <LocalizedDatePicker
                value={values.pers_date_birth || null}
                // disabled={true}
                onChange={(newValue) => {
                  setFieldValue('pers_date_birth', newValue);
                }}

                // {...getFieldProps('pers_date_birth')}
              />

              {errors.pers_date_birth && touched.pers_date_birth && (
                <FormHelperText error>{errors.pers_date_birth}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12} md={6}>
              <InputLabel>{t('Genero')}</InputLabel>
              {isFetchingGA ? (
                <TextFieldFetching />
              ) : (
                <Autocomplete
                  options={gendersAvailables || []}
                  loading={isLoadingGA || isFetchingGA}
                  loadingText={t('Cargando generos...')}
                  noOptionsText={t('No hay generos disponibles')}
                  getOptionLabel={(option) => option.cat_name}
                  value={gendersAvailables.find(({ cat_name }) => values.pers_gender === cat_name)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(errors.pers_gender && touched.pers_gender)}
                      placeholder={t('Seleccione un genero')}
                    />
                  )}
                  onChange={(_, value) => {
                    setFieldValue('pers_gender', value?.cat_name || '');
                  }}
                  fullWidth
                  clearIcon={null}
                />
              )}
              {errors.pers_gender && touched.pers_gender && (
                <FormHelperText error>{t(errors.pers_gender)}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12} md={6}>
              <InputLabel>{t('Estado civil')}</InputLabel>
              {isLoadingCSA ? (
                <TextFieldFetching />
              ) : (
                <Autocomplete
                  options={civilStatusAvailables || []}
                  loading={isLoadingCSA}
                  loadingText={t('Cargando estados civiles...')}
                  noOptionsText={t('No hay estados civiles disponibles')}
                  getOptionLabel={(option) => option.cat_name}
                  value={civilStatusAvailables.find(
                    ({ id }) => values.status_marital_id + '' === id + ''
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(errors.status_marital_id && touched.status_marital_id)}
                      placeholder={t('Seleccione un estado civil')}
                    />
                  )}
                  onChange={(_, value) => {
                    setFieldValue('status_marital_id', value?.id || '');
                  }}
                  fullWidth
                  clearIcon={null}
                />
              )}
              {errors.status_marital_id && touched.status_marital_id && (
                <FormHelperText error>{t(errors.status_marital_id)}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12} md={6}>
              <InputLabel>{t('Nacionalidad')}</InputLabel>
              {isLoadingNacionalities ? (
                <TextFieldFetching />
              ) : (
                <Autocomplete
                  options={nacionalities || []}
                  loading={isLoadingNacionalities}
                  loadingText={t('Cargando nacionalidades...')}
                  noOptionsText={t('No hay nacionalidades disponibles')}
                  getOptionLabel={(option) => option.cat_name}
                  value={nacionalities.find(({ id }) => values.nationality_id + '' === id + '')}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(errors.nationality_id && touched.nationality_id)}
                      placeholder={t('Seleccione una nacionalidad')}
                    />
                  )}
                  onChange={(_, value) => {
                    setFieldValue('nationality_id', value?.id || '');
                  }}
                  fullWidth
                  clearIcon={null}
                />
              )}
              {errors.nationality_id && touched.nationality_id && (
                <FormHelperText error>{t(errors.nationality_id)}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12} md={6}>
              <InputLabel>{t('Tiene discapacidad')}</InputLabel>

              <FormControlLabel
                control={
                  <Switch
                    checked={values.pers_has_disability}
                    {...getFieldProps('pers_has_disability')}
                  />
                }
                label={values.pers_has_disability ? 'Si' : 'No'}
              />
            </Grid>

            {/* Teléfono */}
            <Grid item xs={12} md={6}>
              <InputLabel>{t('Teléfono convencional')}</InputLabel>
              <TextField
                {...getFieldProps('pers_phone_home')}
                error={Boolean(errors.pers_phone_home && touched.pers_phone_home)}
                placeholder={t('Ingrese el teléfono convencional')}
                fullWidth
              />
              {errors.pers_phone_home && touched.pers_phone_home && (
                <FormHelperText error>{t(errors.pers_phone_home)}</FormHelperText>
              )}
            </Grid>

            {/* Celular */}
            <Grid item xs={12} md={6}>
              <InputLabel>{t('Celular')}</InputLabel>
              <TextField
                {...getFieldProps('pers_cell')}
                error={Boolean(errors.pers_cell && touched.pers_cell)}
                placeholder={t('Ingrese el celular')}
                fullWidth
              />
              {errors.pers_cell && touched.pers_cell && (
                <FormHelperText error>{t(errors.pers_cell)}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12} md={6}>
              <InputLabel>{t('academic.buses.campus')}</InputLabel>
              {isFetchingCampusEcobus ? (
                <TextFieldFetching />
              ) : (
                <Autocomplete
                  options={campusEcobus || []}
                  getOptionLabel={(option) => option.cam_name}
                  renderInput={(params) => <TextField {...params} />}
                  value={campus}
                  onChange={(_, value) => handleSelectCampus(value)}
                  fullWidth
                  clearIcon={null}
                />
              )}
            </Grid>

            <Grid item xs={12} md={6}>
              <InputLabel>{t('Ruta de Ecobus')}</InputLabel>
              {isLoadingNacionalities ? (
                <TextFieldFetching />
              ) : (
                <Autocomplete
                  options={busRouteData || []}
                  noOptionsText={t('No hay rutas disponibles')}
                  getOptionLabel={(option) => option.bus_name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(errors.nationality_id && touched.nationality_id)}
                    />
                  )}
                  value={
                    busRouteData?.find(
                      ({ id }) => Number(values.bus_routes[0]?.bus_route_id) === id
                    ) || null
                  }
                  onChange={(_, value) => {
                    setFieldValue('bus_routes', [
                      {
                        bus_route_id: value?.id,
                        campus_id: value?.campus_id,
                        person_id: data?.user?.person_id,
                        id: data?.bus_route[0]?.id ? data?.bus_route[0]?.id : null,
                      },
                    ]);
                  }}
                  fullWidth
                  clearIcon={null}
                />
              )}
              {errors.nationality_id && touched.nationality_id && (
                <FormHelperText error>{t(errors.nationality_id)}</FormHelperText>
              )}
            </Grid>

            {values?.pers_has_disability && (
              <Grid item xs={12} my={2}>
                <Divider sx={{ width: '100%' }} />
                <Typography variant="h3" my={2}>
                  Discapacidad
                </Typography>
                <Paper elevation={3} sx={{ padding: 3 }}>
                  <AddDisabilityForm setData={handlerAddDisability} />
                </Paper>
              </Grid>
            )}
            {values.pers_has_disability &&
              values?.pers_disabilities?.map(
                ({ observation, percentage_disability, type_disability }, index) => (
                  <Grid item xs={12} md={6}>
                    <Paper elevation={3} className={classes.cardDisability}>
                      {/* <Grid item xs={6}></Grid> */}
                      <Grid item xs={12} p={2} pl={4}>
                        <Typography variant="subtitle1" gutterBottom>
                          {disability.find(({ id }) => id === type_disability)?.typ_dis_name}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          Porcentaje de discapacidad: {percentage_disability} %
                        </Typography>
                        <LinearProgress
                          variant="determinate"
                          value={percentage_disability}
                          sx={{ mb: 2 }}
                        />
                        <Typography variant="body1" gutterBottom>
                          Observación: {observation}
                        </Typography>
                        <Button color="error" onClick={() => handlerDeleteDisability(index)}>
                          Eliminar
                        </Button>
                      </Grid>
                    </Paper>
                  </Grid>
                  // </Grid>
                )
              )}

            <Grid item xs={12} my={2}>
              <Divider sx={{ width: '100%' }} />
              <Typography variant="h3" my={2}>
                Perfiles sociales
              </Typography>
              <Paper elevation={3} sx={{ padding: 3 }}>
                <SocialNetworks
                  handleAddSocialNetwork={handleAddSocialNetwork}
                  socialNetworks={values.social_networks}
                  isLoadingSocialNetworkData={isLoadingSocialNetworkData}
                />
              </Paper>
            </Grid>
          </Grid>

          <Grid
            item
            container
            alignItems="center"
            justifyContent="start"
            spacing={2}
            xs={4}
            sx={{ mt: 6 }}
          >
            {/* {notErrorForm + 'Test '}
            {errorObject?.map((e) => e.)}
            {isCompleteOthersTabs + ''} */}
            <Grid item xs={6}>
              <LoadingButton
                fullWidth
                type="submit"
                variant="contained"
                size="large"
                color="primary"
                isLoading={!!submit?.isLoading}
                disabled={notErrorForm && isCompleteOthersTabs && !isAFormChange}
              >
                {/* {t('academic.subjects.add.submit')} */}
                {notErrorForm && isCompleteOthersTabs ? 'Guardar' : 'Siguiente'}
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default PersonalInfo;
