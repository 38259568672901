import { useAppDispatch, useAppSelector } from '../../../../../../hooks/useRedux';
import DeleteAlert from '../../../../../shared/components/dialogs/DeleteAlert';
import { useDeleteQuestionMutation } from '../slices/questionApiSlice';
import { setQuestionsDeleteModalOpen, setQuestionsIdToDelete } from '../slices/questionSlice';

const QuestionDeleteDialog = () => {
  const dispatch = useAppDispatch();
  const { questionsIdToDelete, questionsDeleteModalOpen } = useAppSelector(
    (state) => state.questions
  );

  const [deleteQuestion, { isLoading, isSuccess, error, isError }] = useDeleteQuestionMutation();

  const handleDelete = () => {
    deleteQuestion({
      id: questionsIdToDelete!,
    });
  };
  const handleClose = () => {
    dispatch(setQuestionsIdToDelete(null));
    dispatch(setQuestionsDeleteModalOpen(false));
  };

  return (
    <DeleteAlert
      open={questionsDeleteModalOpen}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      error={error as any}
      handleDelete={handleDelete}
      onClose={handleClose}
    />
  );
};

export default QuestionDeleteDialog;
