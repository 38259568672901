import { IconButton, Tooltip } from '@material-ui/core';
import { People } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

const GroupButton = ({ handleClick, title }: { handleClick?: () => void; title?: string }) => {
  const { t } = useTranslation();

  return (
    <Tooltip title={title || t('shared.group_button.tooltip')}>
      <IconButton aria-label="group" color="primary" onClick={handleClick}>
        <People sx={{ fontSize: '1.3rem' }} />
      </IconButton>
    </Tooltip>
  );
};

GroupButton.defaultProps = {
  handleClick: () => {},
  title: '',
};

export default GroupButton;
