import utils from './modules/utils.json';
import alerts from './modules/alerts.json';
import authTranslations from './modules/auth';
import studentTranslations from './modules/students';
import sharedTranslations from './modules/shared';
import academicTranslations from './modules/academic';
import teachersTraanslations from './modules/teachers';
import financialTranslations from './modules/financial';
import configurationTranslations from './modules/configuration';
import communityOutreachTranslations from './modules/communityOutreach';
import classifications from './modules/library';
import research from './modules/research';

const enTranslations = {
  // auth
  ...authTranslations,
  // students
  ...studentTranslations,

  //financial
  ...financialTranslations,

  // academic
  ...academicTranslations,

  // teachers
  ...teachersTraanslations,

  // configuration
  ...configurationTranslations,

  //shared
  ...sharedTranslations,

  // utils
  ...utils,

  // communityOutreach/vinculación
  ...communityOutreachTranslations,

  //research
  ...research,

  ...alerts,

  ...classifications,
};

export default enTranslations;
