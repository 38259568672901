export const subPermissionKeys = {
  groupKey: 'applications',

  read: 'applications-teacher-requisition-obtener-requisicion-docente',
  create: 'applications-teacher-requisition-crear-requisicion-docente',
  edit: 'applications-teacher-requisition-cambiar-estado-requisicion-docente',
  // delete: 'users-borrar-usuario',

  showOffers: 'offers-listar-ofertas',
  showEduLvls: 'offers-obtener-oferta',

  pdfForTeacherRequisition: 'applications-teacher-requisition-pdf-requisicion-docente',
};
