import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Stack,
  Typography,
} from '@material-ui/core';

import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import { Transition } from '../../../students/subjects-register/components/SubjectsRegisteredDialog';

export const useStyles = makeStyles((theme) => ({
  userAddDialog: {
    '&>div:nth-child(3)': {
      justifyContent: 'flex-end',
      '&>div': {
        margin: '0px',
        borderRadius: '0px',
        maxWidth: '450px',
        maxHeight: '100%',
      },
    },
  },
  paper: {
    minHeight: '100vh',
    width: '33%',
  },
  learningComponents: {
    fontSize: '16px',
    fontWeight: 600,
    color: theme.palette.primary.main,
    marginBottom: '8px',
  },
  selectSubject: {
    fontSize: '16px',
    fontWeight: 600,
    color: theme.palette.primary[800],
    marginBottom: '8px',
  },
  selectSubjectType: {
    fontSize: '14px',
    fontWeight: 600,
    color: theme.palette.secondary.main,
    marginBottom: '8px',
  },
}));

interface Props {
  open: boolean;
  handleCloseDialog: () => void;
}

const AccountStatusDialog = ({ open, handleCloseDialog }: Props) => {
  const classes = useStyles();

  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => {
        handleCloseDialog();
      }}
      className={classes.userAddDialog}
      classes={{
        paper: classes.paper,
      }}
    >
      {/* <PerfectScrollbar> */}
      <DialogContent>
        <Grid container spacing={2}>
          <Grid container item>
            <Typography variant="h2">
              {t('financial.client_list.account_status_dialog.title')}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      {/* </PerfectScrollbar> */}
      <DialogActions>
        <Stack direction="row" spacing={2}>
          <Button
            variant="text"
            onClick={() => {
              handleCloseDialog();
            }}
            color="primary"
          >
            {t('shared.cancel_button.label')}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default AccountStatusDialog;
