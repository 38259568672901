import { Switch, Redirect } from 'react-router-dom';

import { useAppSelector } from '../../../../hooks/useRedux';

//import { permissionKey, subPermissionKeys } from '../constants/permissions';
import GuardRoute from '../../../../router/guards/GuardRoute';

import ThesisScreen from '../screens/ThesisScreen';
import AddThesisScreen from '../screens/AddThesisScreen';
import EditThesisScreen from '../screens/EditThesisScreen';

const ThesisRouter = () => {
  const { currentModule } = useAppSelector((state) => state.access);

  const hasPermission = 'borrows' in currentModule?.permissions!;

  if (!hasPermission) return <Redirect to="/dashboard" />;

  return (
    <Switch>
      <GuardRoute
        exact
        path="/administrar-tesis"
        component={ThesisScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.create}
      />
      <GuardRoute
        exact
        path="/administrar-tesis/agregar"
        component={AddThesisScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.create}
      />

      <GuardRoute
        exact
        path="/administrar-tesis/editar"
        component={EditThesisScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.create}
      />
    </Switch>
  );
};

export default ThesisRouter;
