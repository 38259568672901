import { Button } from '@material-ui/core';
import { useAppSelector } from '../../../hooks/useRedux';
import { tenantsVc } from '../constants/virtualClassroomDict';

const BlackboardButton = () => {
  const { tenant } = useAppSelector((state) => state.auth);

  const handleAuthBlackboard = () => {
    window.open(tenantsVc[tenant].url);
  };

  return (
    <Button variant="contained" color="primary" onClick={handleAuthBlackboard}>
      Ir a Blackboard
    </Button>
  );
};

export default BlackboardButton;
