import { ReactElement, useState } from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  card: {
    position: 'relative',
    width: 'auto',
    height: 'auto',
    transformStyle: 'preserve-3d',
    transition: 'transform 0.8s',
  },

  flipped: {
    transform: 'rotateY(180deg)',
  },

  content: {
    position: 'absolute',
    // top: 0,
    // left: 0,
    width: '100%',
    height: '100%',
    backfaceVisibility: 'hidden',
  },

  front: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  back: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    transform: 'rotateY(180deg)',
    zIndex: 10,
  },
}));

interface Props {
  frontContent: ReactElement;
  backContent: ReactElement;
  width?: string;
  height?: string;

  noTap?: boolean;
  isFlipped?: boolean;
}

const FlipCard = ({ frontContent, backContent, noTap, isFlipped, width, height }: Props) => {
  const [flipped, setFlipped] = useState(isFlipped);
  console.log(isFlipped);

  const classes = useStyles();

  const handleClick = (event: MouseEvent) => {
    event.stopPropagation();
    setFlipped(!flipped);
  };

  return (
    <div
      className={`${classes.card} ${flipped || isFlipped ? classes.flipped : ''}`}
      onClick={(e) => {
        if (!noTap) handleClick(e as any);
      }}
      style={{ width: width, height: height }}
    >
      <div key={'front-flip-card'} className={`${classes.content} ${classes.front}`}>
        {frontContent}
      </div>
      <div
        key={'back-flip-card'}
        style={{ pointerEvents: flipped || isFlipped ? 'auto' : 'none' }}
        className={`${classes.content} ${classes.back}`}
      >
        {backContent}
      </div>
    </div>
  );
};

export default FlipCard;
