import { Switch } from 'react-router-dom';

import GuardRoute from '../../../../router/guards/GuardRoute';

import LibrayDashboardScreen from '../screens/LibraryDashboardScreen';
import LibraryFaqsScreen from '../screens/LibraryFaqsScreen';

const DashboardRouter = () => {
  return (
    <Switch>
      <GuardRoute exact path="/biblioteca/inicio" component={LibrayDashboardScreen} />
      <GuardRoute exact path="/biblioteca/preguntas-frecuentes" component={LibraryFaqsScreen} />
    </Switch>
  );
};

export default DashboardRouter;
