import { Grid, Skeleton, Stepper, Step, StepLabel, Divider } from '@material-ui/core';
// import { useTranslation } from 'react-i18next';

import ProfessionalPracticesForm from './ProfessionalPracticesForm';
import { useGetPracticesValidationQuery } from '../slices/academicRequestsApiSlice';
import { useAppSelector } from '../../../../hooks/useRedux';
import ApprovePracticesForm from './ApprovePracticesForm';
import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import ImportantAnnouncement from '../../../shared/components/messages-to-show/ImportantAnnouncement';
import DownloadButton from '../../../shared/components/tables/buttons/DownloadPdfButton';

const steps = ['Crear solicitud', 'Agregar documentos'];

const getIndex = [
  { id: 0, index: 1 },
  { id: 1, index: 0 },
  { id: 2, index: -1 },
];

const ProfessionalPractices = () => {
  // const { t } = useTranslation();

  const recordId = useAppSelector((state) => state.access?.currentRecordStudent?.id);

  const { data, isLoading, isError, error } = useGetPracticesValidationQuery({
    studentRecordId: recordId || 0,
  });

  return (
    <Grid container spacing={2}>
      {isError && <ErrorAlert message={error as any} />}
      <Grid item xs={12}>
        {!isLoading && (
          <Stepper
            activeStep={getIndex.find(({ id }) => data?.available_application === id)?.index ?? -1}
            alternativeLabel
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel sx={{ fontSize: '40px' }}>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        )}
      </Grid>
      <Grid item xs={12} mt={2}>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        {isLoading ? (
          <Skeleton variant="rectangular" sx={{ height: '50vh' }} />
        ) : (
          <>
            <Grid container justifyContent="flex-end" alignItems="center">
              {!!data?.url_download_template && (
                <DownloadButton
                  urlBase={false}
                  typeButton="button"
                  url={data?.url_download_template || ''}
                />
              )}
            </Grid>
            {data?.available_application === 0 && (
              <ApprovePracticesForm isCurriculumNew={!!Number(data.curriculum_new)} />
            )}
            {data?.available_application === 1 && <ProfessionalPracticesForm />}
            {data?.available_application === 2 && (
              <ImportantAnnouncement messageList={data.warnings} />
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
};
export default ProfessionalPractices;
