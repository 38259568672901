const StatusProjectsAvailables = [
  { label: 'Aprobado', keyword: 'Aprobado', color: 'success' },
  { label: 'Enviado', keyword: 'Enviado', color: 'info' },
  { label: 'En cierre', keyword: 'EN CIERRE', color: 'warning' },
  { label: 'En ejecución', keyword: 'EN EJECUCION', color: '' },
  { label: 'Detenido', keyword: 'DETENIDO', color: 'warning' },
  { label: 'Cancelado', keyword: 'CANCELADO', color: 'error' },
  { label: 'Rechazado', keyword: 'Rechazado', color: 'error' },
];

export default StatusProjectsAvailables;
