import {
  Button,
  CircularProgress,
  Dialog,
  DialogProps,
  Grid,
  Typography,
  useTheme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { IconCircleCheck, IconFileInfo, IconFileAlert } from '@tabler/icons';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  paper: { maxWidth: '600px', width: '90%' },
}));

interface Props extends DialogProps {
  onClose: () => void;
  handleAction: () => void;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  error: string;
  actionTitle?: string;
  actionSubtitle?: string;
  actionSucces?: string;
  buttonTitle?: string;
  successTitle?: string;
  successButtonTitle?: string;
}

const DialogConfirm = ({
  onClose,
  handleAction,
  isLoading,
  isSuccess,
  isError,
  error,
  actionSucces,
  actionTitle,
  actionSubtitle,
  buttonTitle,
  successTitle,
  successButtonTitle,
  ...props
}: Props) => {
  const { t } = useTranslation();

  const classes = useStyles();

  const theme = useTheme();

  const title =
    actionTitle !== undefined ? actionTitle : t('studentVirtualClassroom.test.confirmStartTest');

  const subtitle =
    actionSubtitle !== undefined
      ? actionSubtitle
      : t('studentVirtualClassroom.test.confirmStartTestDescription');

  const success = actionSucces !== undefined ? actionSucces : '';

  return (
    <Dialog {...props} classes={{ paper: classes.paper }} sx={{ p: 3 }} onClose={onClose}>
      <Grid container gap={4} sx={{ p: 2 }}>
        <Grid item container xs={12} alignItems="center" justifyContent="center">
          {!isError && !isSuccess && !isLoading && (
            <IconFileInfo
              size={60}
              color={theme.palette.info.dark}
              style={{
                padding: 12,
                backgroundColor: theme.palette.info[500],
                borderRadius: '50%',
              }}
            />
          )}
          {isLoading && <CircularProgress size={50} />}
          {isSuccess && (
            <IconCircleCheck
              size={60}
              color={theme.palette.success.main}
              style={{
                padding: 12,
                backgroundColor: theme.palette.success.light,
                borderRadius: '50%',
              }}
            />
          )}
          {isError && (
            <IconFileAlert
              size={60}
              color={theme.palette.error.main}
              style={{
                padding: 12,
                backgroundColor: theme.palette.orange.light,
                borderRadius: '50%',
              }}
            />
          )}
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Typography variant="h2" sx={{ color: theme.palette.primary.main, mb: 1 }}>
            {!isError && !isSuccess && title}
            {isSuccess && success}
            {isSuccess && successTitle}
            {isError && error}
          </Typography>
          <Typography variant="body2">
            {!isError && !isSuccess && subtitle}
            {isError && error}
          </Typography>
        </Grid>
        <Grid item container xs={12} justifyContent="center" gap={1}>
          {!isSuccess && !isError && (
            <Button
              variant="text"
              size="large"
              color="primary"
              onClick={onClose}
              disabled={isLoading}
            >
              {t('studentVirtualClassroom.test.confirmStartTestCancel')}
            </Button>
          )}
          {(isSuccess || isError) && (
            <Button variant="contained" size="large" color="primary" onClick={onClose}>
              {successButtonTitle
                ? successButtonTitle
                : t('studentVirtualClassroom.test.confirmStartTestAcept')}
            </Button>
          )}
          {!isSuccess && !isError && (
            <Button
              variant="contained"
              size="large"
              onClick={handleAction}
              color="primary"
              autoFocus
              disabled={isLoading}
            >
              {buttonTitle ? buttonTitle : t('studentVirtualClassroom.test.confirmStartTestAcept')}
            </Button>
          )}
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default DialogConfirm;
