import { Grid, Typography } from '@material-ui/core';
import MainCard from '../../components/cards/MainCard';
import RegisterVisits from '../components/RegisterVisits';

const RegistrerVisitsNoAuthScreen = () => {
  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">Registrar visita a biblioteca</Typography>
      </Grid>
      <Grid item xs={12}>
        <RegisterVisits />
      </Grid>
    </Grid>
  );
};

export default RegistrerVisitsNoAuthScreen;
