import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import MainCard from '../../../../../shared/components/cards/MainCard';
import FormsTableUser from '../components/FormsTableUser';
import AcceptResultDialog from '../components/AcceptResultDialog';
import { useAppSelector } from '../../../../../../hooks/useRedux';

const FormsUserScreen = () => {
  const { t } = useTranslation();
  const { openDialogAcceptResult } = useAppSelector((state) => state.formsEva);

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">{t('Evaluaciones por usuario')}</Typography>
      </Grid>
      <FormsTableUser />
      {openDialogAcceptResult && <AcceptResultDialog />}
    </Grid>
  );
};

export default FormsUserScreen;
