import { Chip, Grid, Typography, useTheme } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useAppDispatch } from '../../../../../../../hooks/useRedux';
import DeleteButton from '../../../../../../shared/components/tables/buttons/DeleteButton';
import EditButton from '../../../../../../shared/components/tables/buttons/EditButton';
import { IEvaluation } from '../../interfaces/evaluations.interface';
import {
  setEvaluationsDeleteModalOpen,
  setEvaluationsIdToDelete,
} from '../../slices/evaluationsSlice';

interface EvaluationsListItemProps {
  evaluation: IEvaluation;
}

const EvaluationsListItem = ({ evaluation }: EvaluationsListItemProps) => {
  const theme = useTheme();

  const navigate = useHistory();
  const dispatch = useAppDispatch();

  const handleEdit = (id: string) => {
    navigate.push(`/evaluaciones/${id}/editar`);
  };

  const handleDelete = (id: string) => {
    dispatch(setEvaluationsIdToDelete(id));
    dispatch(setEvaluationsDeleteModalOpen(true));
  };

  return (
    <Grid
      container
      sx={{
        padding: 2,
        borderRadius: '12px',
        border: '1px solid #e0e0e0',
        backgroundColor: '#fff',
        boxShadow: '0px 0px 20px 2px rgba(14, 36, 91, 0.15)',
      }}
    >
      <Grid item xs={12}>
        <Typography color="primary" variant="h4">
          {evaluation.description}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">{evaluation.eva_type?.description}</Typography>
      </Grid>
      <Grid item xs={12} mt={1}>
        <Typography
          variant="h5"
          sx={{
            fontWeight: 'bold',
            color: theme.palette.grey[500],
          }}
        >
          Creación
        </Typography>
        <Typography variant="h5" fontWeight={600}>
          {evaluation.created_at?.split('T')![0]}
        </Typography>
      </Grid>
      <Grid item container justifyContent="space-between" xs={12} mt={2}>
        <Grid item>
          <Chip
            label="Activo"
            sx={{
              backgroundColor: theme.palette.success[200],
            }}
          />
        </Grid>
        <Grid item>
          {false && <EditButton handleEdit={() => handleEdit(evaluation.uuid!)} />}
          <DeleteButton handleDelete={() => handleDelete(evaluation.uuid!)} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EvaluationsListItem;
