import { Grid, Typography, useTheme } from '@material-ui/core';
import { Option } from '../../interfaces/evaluationToDo.interface';
import { EvaluationQuestion } from '../../interfaces/evaluationQuestions.interface';

import QuestionAnswer from './QuestionAnswer';

interface Props {
  title: string;
  questions: EvaluationQuestion[];
  template: Option[];
  idx?: string | number;
}

const QuestionsGroup = ({ title, questions, template, idx }: Props) => {
  const theme = useTheme();

  const ordenateQuestions= questions.sort(
    ( a, b )=> {
      if ( a.name < b.name ){
        return -1;
      }
      if ( a.name > b.name ){
        return 1;
      }
      return 0;
    }
  )

  return (
    <Grid
      item
      container
      xs={12}
      mt={2}
      sx={{
        padding: 2,
        borderRadius: 2,
        backgroundColor: theme.palette.secondary.light,
      }}
    >
      <Grid item xs={12}>
        <Typography variant="h4">{title}</Typography>
      </Grid>
      <Grid item xs={12}>
        {ordenateQuestions?.map((question, indexSon) => (
          <QuestionAnswer key={question.uuid} question={question} template={template} idxFather={idx} idxSon={indexSon+1}/>
        ))}
      </Grid>
    </Grid>
  );
};

export default QuestionsGroup;
