import { Switch, Redirect } from 'react-router-dom';
import { useAppSelector } from '../../../../hooks/useRedux';

import GuardRoute from '../../../../router/guards/GuardRoute';

import PeriodsTypesScreen from '../screens/PeriodTypesScreen';
import AddPeriodsTypesScreen from '../screens/AddPeriodTypeScreen';
import EditPeriodTypeScreen from '../screens/EditPeriodTypeScreen';

import { subPermissionKeys } from '../constants/permissions';

const { groupKey: permissionKey } = subPermissionKeys;

const PeriodTypesRouter = () => {
  const { currentModule } = useAppSelector((state) => state.access);

  const hasPermission = permissionKey in currentModule?.permissions!;

  if (!hasPermission) <Redirect to="/dashboard" />;

  return (
    <Switch>
      <GuardRoute
        exact
        path="/administrar-tipos-periodos"
        component={PeriodsTypesScreen}
        subPermissionKey={subPermissionKeys.read}
        permissionKey={permissionKey}
      />

      <GuardRoute
        exact
        path="/administrar-tipos-periodos/agregar"
        component={AddPeriodsTypesScreen}
        subPermissionKey={subPermissionKeys.create}
        permissionKey={permissionKey}
      />

      <GuardRoute
        exact
        path="/administrar-tipos-periodos/:periodTypeId/editar"
        component={EditPeriodTypeScreen}
        subPermissionKey={subPermissionKeys.edit}
        permissionKey={permissionKey}
      />
    </Switch>
  );
};

export default PeriodTypesRouter;
