import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { questionFormSchema } from '../validation/questions.validation';
import {
  useAddQuestionMutation,
  useGetQuestionsTypesAllQuery,
  useUpdateQuestionMutation,
} from '../slices/questionApiSlice';
import { useGetAllScopesQuery } from '../../scope/slices/scopeApiSlice';
import OptionsTemplateSideDialog from '../../options_template/components/OptionsTemplateSideDialog';
import { useAppSelector } from '../../../../../../hooks/useRedux';
import {
  Autocomplete,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from '@material-ui/core';
import SuccessAlert from '../../../../../shared/components/alerts/SuccessAlert';
import ErrorAlert from '../../../../../shared/components/alerts/ErrorAlert';

const QuestionForm = () => {
  const { t } = useTranslation();
  const [showAlerts, setShowAlerts] = useState(false);
  const { optionsTemplateSideDialogOpen } = useAppSelector((state) => state.optionsTemplate);

  const [addQuestion, { isLoading, isError, error, isSuccess }] = useAddQuestionMutation();

  const [
    updateQuestion,
    { isLoading: isLoadingU, isError: isErrorU, error: errorU, isSuccess: isSuccessU },
  ] = useUpdateQuestionMutation();

  const { data: scopes = [], isLoading: isLoadingScopes } = useGetAllScopesQuery({});

  const { data: questionsTypes = [] } = useGetQuestionsTypesAllQuery({});

  const { questionsList, questionsIdSelected } = useAppSelector((state) => state.questions);

  const questionsSelected = questionsList.find((option) => option.uuid === questionsIdSelected);

  const statusOptions = [
    { value: 'A', label: t('maintenance.scopes.status.active') },
    { value: 'I', label: t('maintenance.scopes.status.inactive') },
  ];

  const { errors, getFieldProps, handleSubmit, setFieldValue, touched, values, resetForm } =
    useFormik({
      initialValues: {
        name: questionsSelected?.name || '',
        description: questionsSelected?.description || '',
        category_uuid: questionsSelected?.ambitos.uuid || '',
        que_type_uuid:
          questionsSelected?.que_type_uuid ||
          questionsTypes.find((option) => option.acronym === 'EU')?.uuid ||
          '',
        status: questionsSelected?.status || 'A',
      },
      validationSchema: questionFormSchema,
      onSubmit: async (currentValues) => {
        if (questionsIdSelected) {
          await updateQuestion({
            id: questionsIdSelected,
            name: currentValues.name,
            description: currentValues.description,
            category_uuid: currentValues.category_uuid,
            status: currentValues.status,
            que_type_uuid: currentValues.que_type_uuid,
          });
        } else {
          await addQuestion({
            name: currentValues.name,
            description: currentValues.description,
            category_uuid: currentValues.category_uuid,
            status: currentValues.status,
            que_type_uuid: currentValues.que_type_uuid,
          });
        }
        setShowAlerts(true);
      },
    });

  useEffect(() => {
    if (questionsTypes.length > 0) {
      setFieldValue(
        'que_type_uuid',
        questionsTypes.find((option) => option.acronym === 'EU')?.uuid || ''
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionsTypes]);

  useEffect(() => {
    if (isSuccess) {
      resetForm();
    }
  }, [isSuccess, resetForm]);

  const handleSelectScope = (uuid: string) => {
    setFieldValue('category_uuid', uuid);
  };

  return (
    <Grid container spacing={2}>
      {(isSuccess || isSuccessU) && showAlerts && (
        <Grid item xs={12}>
          <SuccessAlert
            message={
              isSuccess
                ? t('maintenance.questions.add.success')
                : t('maintenance.questions.edit.success')
            }
            handleDismiss={() => setShowAlerts(false)}
          />
        </Grid>
      )}
      {(isError || isErrorU) && showAlerts && (
        <Grid item xs={12}>
          <ErrorAlert
            message={(error as any) || (errorU as any)}
            handleDismiss={() => setShowAlerts(false)}
          />
        </Grid>
      )}

      <Grid item xs={12}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputLabel>{t('maintenance.questions.form.scopes')}</InputLabel>
              {isLoadingScopes && <CircularProgress />}

              {!isLoadingScopes && scopes.length > 0 && (
                <Autocomplete
                  options={scopes || []}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, value) => handleSelectScope(value?.uuid!)}
                  value={scopes.find((option) => option.uuid === values.category_uuid) || null}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={t('maintenance.questions.form.scopes.placeholder')}
                      fullWidth
                    />
                  )}
                />
              )}
              {!isLoadingScopes && scopes.length === 0 && (
                <Typography variant="body2">
                  {t('maintenance.questions.form.scopes.no_data')}
                </Typography>
              )}
              {errors.category_uuid && touched.category_uuid && (
                <FormHelperText error>{t(errors.category_uuid)}</FormHelperText>
              )}
            </Grid>

            <Grid item xs={12}>
              <InputLabel>{t('maintenance.scopes.form.name')}</InputLabel>
              <TextField
                placeholder={t('maintenance.scopes.form.name.placeholder')}
                error={Boolean(errors.name && touched.name)}
                {...getFieldProps('name')}
                multiline
                minRows={4}
                fullWidth
              />
              {errors.name && touched.name && (
                <FormHelperText error>{t(errors.name)}</FormHelperText>
              )}
            </Grid>
            <Grid item xs={12}>
              <InputLabel>{t('maintenance.questions.form.description')}</InputLabel>
              <TextField
                placeholder={t('maintenance.questions.form.description.placeholder')}
                error={Boolean(errors.description && touched.description)}
                {...getFieldProps('description')}
                fullWidth
              />
              {errors.description && touched.description && (
                <FormHelperText error>{t(errors.description)}</FormHelperText>
              )}
            </Grid>

            {/* <Grid item xs={12}>
              <InputLabel>{t('Tipo de pregunta')}</InputLabel>
              {!isLoadingQuestionsTypes && questionsTypes.length > 0 && (
                <Autocomplete
                  loading={isLoadingQuestionsTypes}
                  options={questionsTypes}
                  getOptionLabel={(option) => option.description}
                  onChange={(event, value) => {
                    setFieldValue('que_type_uuid', value?.uuid);
                  }}
                  value={
                    questionsTypes.find(
                      (option) => option.uuid === getFieldProps('que_type_uuid').value
                    ) || null
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={t('maintenance.scopes.form.status.placeholder')}
                      fullWidth
                    />
                  )}
                />
              )}
              {!isLoadingQuestionsTypes && questionsTypes.length === 0 && (
                <Typography variant="body2">{t('No existen tipos de preguntas')}</Typography>
              )}

              {errors.que_type_uuid && touched.que_type_uuid && (
                <FormHelperText error>{t(errors.que_type_uuid)}</FormHelperText>
              )}
            </Grid> */}

            <Grid item xs={12}>
              <InputLabel>{t('maintenance.scopes.form.status')}</InputLabel>
              <Autocomplete
                options={statusOptions}
                onChange={(event, value) => {
                  setFieldValue('status', value?.value);
                }}
                value={statusOptions.find(
                  (option) => option.value === getFieldProps('status').value
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={t('maintenance.scopes.form.status.placeholder')}
                    fullWidth
                  />
                )}
              />
              {errors.status && touched.status && (
                <FormHelperText error>{t(errors.status)}</FormHelperText>
              )}
            </Grid>

            <Grid item container xs={12} justifyContent="flex-end" spacing={2}>
              <Grid item>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  disabled={isLoading || isLoadingU}
                  endIcon={isLoading || (isLoadingU && <CircularProgress size={12} />)}
                >
                  {!questionsIdSelected
                    ? t('maintenance.questions.add.submit')
                    : t('maintenance.questions.edit.submit')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
      {optionsTemplateSideDialogOpen && <OptionsTemplateSideDialog />}
    </Grid>
  );
};

export default QuestionForm;
