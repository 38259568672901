import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../../../../hooks/useRedux';
import { showWithDecimals } from '../../../../../shared/helpers';
import { renderErrorOrEmptyRow, renderSkeletonRows } from '../../../../../shared/helpers/render';
import { PaymentsDebts } from '../../../../../students/payments-history/interfaces/paymentsHistory.interface';

const FeesTableReview = () => {
  const { t } = useTranslation();

  const defaultConfiguration = useAppSelector((state) => state.access.defaultConfiguration);

  const { selectedDebts } = useAppSelector((state) => state.debtsPayment);

  const renderRow = (debt: PaymentsDebts, i: number) => (
    <TableRow
      hover
      className="table-row"
      key={`${debt.fees_identifier.toString()}-${i}`}
      //   sx={{ cursor: 'pointer', backgroundColor: selected === debt.id ? '#eee' : '' }}
      onClick={() => {
        // setSelected(debt.id);
      }}
    >
      <TableCell>{debt.fees_name_identifier}</TableCell>
      <TableCell>
        {debt.per_current_year && debt.per_due_year
          ? `${debt.per_current_year}-${debt.per_due_year}`
          : ''}
      </TableCell>
      <TableCell>{debt.fees_expiration_date}</TableCell>
      <TableCell>
        {debt.apply_early_payment ? debt.net_value_early_payment : debt.fees_balance}
      </TableCell>
      <TableCell>
        {defaultConfiguration?.currency_symbol}
        {showWithDecimals(Number(debt.fees_value_payment))}
      </TableCell>
    </TableRow>
  );

  const renderRows = () => {
    return <>{selectedDebts?.map(renderRow)}</>;
  };

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                {t('financial.debts_payment.wizard.pay_review.fees_table_review.fee')}
              </TableCell>
              <TableCell>
                {t('financial.debts_payment.wizard.pay_review.fees_table_review.period')}
              </TableCell>
              <TableCell>
                {t('financial.debts_payment.wizard.pay_review.fees_table_review.endline')}
              </TableCell>
              <TableCell>
                {t('financial.debts_payment.wizard.pay_review.fees_table_review.amount')}
              </TableCell>
              <TableCell>
                {t('financial.debts_payment.wizard.pay_review.fees_table_review.amount_to_pay')}
              </TableCell>
              {/* <TableCell>Opciones</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {!selectedDebts ? renderSkeletonRows(5, 2) : renderRows()}

            {selectedDebts?.length === 0 &&
              renderErrorOrEmptyRow(
                2,
                t('financial.debts_payment.wizard.pay_review.fees_table_review.no_data')
              )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default FeesTableReview;
