import * as yup from 'yup';

const phoneRegExp = /^\d{10}$/;
const telephoneRegExp = /\d/;

export const PersonalInfoFormSchema = yup.object().shape({
  pers_firstname: yup.string().trim().required('El primer nombre es requerido.'),
  pers_secondname: yup.string().trim().required('El segundo nombre es requerido'),
  pers_first_lastname: yup.string().trim().required('El apellido es requerido'),
  pers_second_lastname: yup.string().trim().required('El segundo apellido es requerido'),
  pers_email: yup
    .string()
    .trim()
    .email('Ingrese un email valido')
    .required('El email es requerido'),
  type_identification_id: yup
    .string()
    .required('El tipo de identificación es requerido.')
    .min(1, 'El tipo de identificación es requerido.'),
  pers_identification: yup
    .string()
    .trim()
    .required('El número de identificación es requerido.')
    .matches(/^\d+$/, 'Solo se aceptan números.')
    .min(10, 'Ingrese al menos 10 dígitos.'),
  pers_gender: yup.string().required('El género es requerido.').min(1, 'El género es requerido.'),
  // avatar: yup.string().required('Selecciona una foto del estudiante.'),
  pers_date_birth: yup.date().required('La fecha de nacimiento es requerida'),
  // type_entry_id: yup.string().required('El tipo de ingreso es requerido'),
  status_marital_id: yup
    .number()
    .min(1, 'El estado civil es requerido')
    .required('El estado civil es requerido'),
  nationality_id: yup
    .number()
    .min(1, 'La nacionalidad es requerida.')
    .required('La nacionalidad es requerida'),
  pers_phone_home: yup.string().matches(telephoneRegExp, 'Ingrese un número de teléfono válido.'),
  pers_cell: yup.string().matches(phoneRegExp, 'Ingrese un número de celular válido.'),
});

export const LivingPlaceFormSchema = yup.object().shape({
  vivienda_id: yup
    .number()
    .min(1, 'El tipo de vivienda es requerido.')
    .required('El tipo de vivienda es requerido.'),
  country_id: yup.number().min(1, 'El país es requerido.').required('El país es requerido.'),
  city_id: yup.number().min(1, 'La ciudad es requerida.').required('La ciudad es requerida.'),
  province_id: yup
    .number()
    .min(1, 'La provincia es requerida.')
    .required('La provincia es requerida.'),
  current_city_id: yup
    .number()
    .min(1, 'La ciudad es requerida.')
    .required('La ciudad es requerida.'),
  ethnic_id: yup.number().min(1, 'La etnia es requerida.').required('La etnia es requerida.'),
  campus_id: yup.number().min(1, 'El campus es requerido.').required('El campus es requerido.'),
  sector_id: yup.number().min(1, 'El sector es requerido.').required('El sector es requerido.'),
  jornada_id: yup.string().trim().typeError('La jornada es requerida.'),
  modalidad_id: yup.string().trim().required('La modalidad es requerida.'),
  type_religion_id: yup.string().trim().required('La religión es requerida.'),
  pers_direction: yup.string().trim().required('La dirección es requerida.'),
  pers_num_bedrooms: yup
    .number()
    .min(1, 'El número de habitaciones es requerido.')
    .required('El número de habitaciones es requerido.'),

  pers_num_child: yup
    .number()
    .min(0, 'El número de hijos es requerido.')
    .required('El número de hijos es requerido.'),
  pers_num_taxpayers_household: yup
    .number()
    .min(1, 'El número de contribuyentes del hogar es requerido.')
    .required('El número de contribuyentes del hogar es requerido.'),
  // pers_has_disability: yup.boolean().required('El primer nombre es requerido.'),
});

export const certificateDownloadSchema = yup.object().shape({
  certificateTypeAcronym: yup
    .string()
    .nullable()
    .required('El tipo de certificado es requerido.')
    .notOneOf(['']),
  studentRecordId: yup
    .number()
    .nullable()
    .notOneOf([0], 'El récord estudiantil es requerido')
    .required('El récord estudiantil es requerido.'),
  periodId: yup
    .number()
    .nullable()
    .when('certificateTypeAcronym', (certificateTypeAcronym, schema) => {
      if (certificateTypeAcronym === 'CEREDU' || certificateTypeAcronym === 'CERREN') {
        return schema.min(1, 'El período es requerido').required('El período es requerido.');
      }
      return schema;
    }),
  practices: yup.object().shape({
    isCERPPP: yup.boolean(),
    name: yup.string().when('isCERPPP', {
      is: true,
      then: yup.string().required('El nombre de la práctica es requerido.'),
    }),
    title: yup.string().when('isCERPPP', {
      is: true,
      then: yup.string().required('El título es requerido.'),
    }),
    position: yup.string().when('isCERPPP', {
      is: true,
      then: yup.string().required('La posición es requerida.'),
    }),
    enterprise: yup.string().when('isCERPPP', {
      is: true,
      then: yup.string().required('La empresa es requerida.'),
    }),
    city: yup.string().when('isCERPPP', {
      is: true,
      then: yup.string().required('La ciudad es requerida.'),
    }),
  }),
  to: yup
    .string()
    .nullable()
    .when('certificateTypeAcronym', {
      is: 'CEREDU',
      then: yup.string().required('El destinatario es requerido.'),
    }),
  consul: yup
    .string()
    .nullable()
    .when('certificateTypeAcronym', {
      is: 'CERCON',
      then: yup.string().required('El consul es requerido.'),
    }),
});

export const EducationSchema = yup.object().shape({
  institutionTypeId: yup
    .number()
    .min(1, 'El tipo institución es requerida.')
    .required('El tipo institución es requerida.'),
  institutionId: yup
    .number()
    .min(1, 'La intitución es requerida.')
    .required('La intitución es requerida'),
  provinceId: yup
    .number()
    .min(1, 'La provincia es requerida.')
    .required('La provincia es requerida'),
  cityId: yup.number().min(1, 'La ciudad es requerida.').required('La ciudad es requerida'),
  educationLevel: yup
    .number()
    .min(1, 'La carrera es requerida.')
    .required('La carrera es requerida'),
  studyStatus: yup.string().required('Estado de estudio es requerido'),
  senescytNumber: yup.string().required('Senescyt nombre es requerido'),
  startDate: yup.date().required('Fecha de inico es requerida'),
  endDate: yup.date().required('Fecha de fin es requerida'),
  countryId: yup.object().shape({ id: yup.number().required('país es requerido') }),
});

export const RecordAcademicSchema = yup.object().shape({
  education_level_id: yup.string().required('La carrera es requerida.'),
  faculty: yup.number().min(1, 'La facultad es requerida.').required('La facultad es requerida.'),
  offer_id: yup.number().min(1, 'La oferta es requerida.').required('La oferta es requerida'),
  id_period: yup.number().min(1, 'El período es requerido.').required('El período es requerido.'),
  type_entry_id: yup
    .number()
    .min(1, 'El tipo de ingreso es requerido.')
    .required('El tipo de ingreso es requerido.'),
  can_generate_debt: yup.boolean().required('El campo es requerido.'),
});

export const EducationObjectsSchema = yup.object().shape({
  education_level_id: yup.object().shape({
    name: yup.string().required('El nivel educativo es requerido.'),
    id: yup.number().min(1, 'El id es requerido.').required('El período es requerido'),
  }),
  offer_id: yup.object().shape({
    name: yup.string().required('El nivel educativo es requerido.'),
    id: yup.number().min(1, 'El id es requerido.').required('La oferta es requerida.'),
  }),
  id_period: yup.object().shape({
    name: yup.string().required('El nivel educativo es requerido.'),
    id: yup.number().min(1, 'El id es requerido.').required('El período es requerido'),
  }),
  type_entry_id: yup.object().shape({
    name: yup.string().required('El nivel educativo es requerido.'),
    id: yup.number().min(1, 'El id es requerido.').required('El período es requerido'),
  }),
  can_generate_debt: yup.boolean().required('Generar deuda es requerido'),
});

export const DisabilitySchema = yup.object().shape({
  type_disability: yup
    .number()
    .min(1, 'El tipo de discapacidad es requerido.')
    .required('El tipo de discapacidad es requerido.'),
  percentage_disability: yup
    .number()
    .min(1, 'El porcentaje de discapacidad es requerido.')
    .required('El porcentaje de discapacidad es requerido.'),
  observation: yup.string().required('La observación es requerida.'),
});

export const StudentStoreSchema = yup.object().shape({
  test: yup.string().required('valor requerido'),
  // personalData: PersonalInfoFormSchema,
  livingPlace: LivingPlaceFormSchema,
  // education: EducationSchema,
});

export const AssociatedPersonData = yup.object().shape({
  person_id_relative: yup
    .number()
    .min(1, 'Familiar es requerido')
    .required('Familiar es requerido.'),
  type_kinship_id: yup
    .number()
    .min(1, 'Parentesco es requerido')
    .required('Parentesco es requerido.'),
  city_id: yup.number().min(1, 'Ciudad es requerida').required('Ciudad es requerida.'),
  type_education_id: yup
    .number()
    .min(1, 'Tipo de educación es requerido')
    .required('Tipo de educación es requerido.'),
  pers_phone_home: yup.string().matches(telephoneRegExp, 'Ingrese un número de teléfono válido.'),
  pers_cell: yup.string().matches(phoneRegExp, 'Ingrese un número de celular válido.'),
});

export const EmergencyContactPersonData = yup.object().shape({
  // person_id: yup.number().min(1, 'Familiar es requerido').required('Familiar es requerido.'),
  type_kinship_id: yup
    .number()
    .min(1, 'Parentesco es requerido')
    .required('Parentesco es requerido.'),
  em_ct_first_phone: yup
    .number()
    .required('El teléfono es requerido.')
    .typeError('Ingrese un número de teléfono válido.'),
  em_ct_second_phone: yup.number().nullable().typeError('Ingrese un número de teléfono válido.'),
});

export const JobSchema = yup.object({
  jobOrganization: yup.string().required('La organización es requerida.'),
  jobPosition: yup.string().required('La posición es requerida.'),
  jobDirection: yup.string().required('La dirección es requerida.'),
  jobPhone: yup
    .number()
    .required('El teléfono es requerido.')
    .typeError('Ingrese un número de teléfono válido.'),
  jobStartDate: yup.date().required('La fecha de inicio es requerida.'),
  jobEndDate: yup.date().required('La fecha de finalización es requerida.'),
  jobCurrent: yup.boolean().required('EL campo es requerido.'),
  jobAffilated: yup.boolean().required('El campo es requerido.'),
  cityId: yup.number().required('La ciudad es requerida.'),
  statusId: yup.number().required('El estado es requerido.'),
});
