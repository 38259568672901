import { useState, useMemo, useEffect } from 'react';

import {
  Autocomplete,
  Checkbox,
  Grid,
  Skeleton,
  Tab,
  Tabs,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core';

import InputLabel from '../../../shared/components/forms/InputLabel';
import {
  useGetSubjectByPeriodsQuery,
  useLazyGetSubjectByPeriodsQuery,
} from '../slices/AcademicPortfolioApiSlice';
import { useAppSelector } from '../../../../hooks/useRedux';
import { useGetSubjectStatusQuery } from '../../../shared/slices/catalogApi';
import IconTelework from '../../../shared/components/illustrations/IconTelework';
import CircleIcon from './CircleIcon';
import SubjectCardNew from './SubjectCardNew';
import { useTranslation } from 'react-i18next';
import { SubjectsByPeriod } from '../interfaces/subjectsByPeriods';
import { useGetStudentYearsPeriodsQuery } from '../../../maintenance/students/slices/studentsApiSlice';
import { YearsStudentPeriod } from '../../../maintenance/students/interfaces/students.interface';

const MatterList = () => {
  const { t } = useTranslation();

  const { currentProfile, currentStudent } = useAppSelector((state) => state.access);

  const [periodId, setPeriodId] = useState<number | null>(null);
  const [periodReverse, setPeriodReverse] = useState<YearsStudentPeriod[]>([]);
  const theme = useTheme();

  const { data: mattersHomologated = [] } = useGetSubjectByPeriodsQuery(
    {
      user_profile_id: currentProfile!.profileId,
      periodId: periodId!,
      type_sort: 'desc',
      matters_homologated: true,
      data: 'all',
    },
    { refetchOnMountOrArgChange: 30, skip: !periodId }
  );

  const { data: periods, isLoading: isLoadingPeriods } = useGetStudentYearsPeriodsQuery(
    {
      studentId: currentStudent?.id!.toString(),
    },
    {
      skip: !currentStudent?.id,
    }
  );

  const [showHomologated, setShowHomologated] = useState(false);

  const { data: estados = [] } = useGetSubjectStatusQuery({
    profileId: currentProfile!.profileId,
    search: 'Materia_curso',
  });

  const estadosToMutate = useMemo(() => [...estados] || [], [estados]);
  const getSortedArr = (value: string) => {
    if (value.toLowerCase().match('Cursando')) {
      return 1;
    }

    return 2;
  };

  const [getSubjectsByPeriod, { data, isFetching, isError }] = useLazyGetSubjectByPeriodsQuery();

  const handleSearch = (_periodId: number | null = null) => {
    getSubjectsByPeriod({
      user_profile_id: currentProfile!.profileId,
      periodId: _periodId || periodId!,
      type_sort: 'desc',
      search: '',
      data: 'all',
    });
  };

  const [tabValue, setTabValue] = useState('Cursando');

  const subjectsFiltered = useMemo(() => {
    return data?.filter((_) => _.approval_status?.st_name === tabValue) || [];
  }, [data, tabValue]);

  const allSubjects = useMemo(
    () =>
      (data?.length || 0) > 0
        ? ([
            ...data!.filter((subject) => subject?.approval_status?.st_name === 'Cursando'),
            ...data!
              .filter((subject) => subject?.approval_status?.st_name !== 'Cursando')
              .sort((a, b) =>
                a.approval_status?.st_name || '' > b.approval_status?.st_name || '' ? 1 : -1
              ),
          ] as SubjectsByPeriod[])
        : [],
    [data]
  );

  const handleShowHomologated = (checked: boolean) => {
    setShowHomologated(checked);
    setTabValue('Aprobado');
  };

  useEffect(() => {
    if (
      periods &&
      (periods?.length || 0) > 0 &&
      (periods?.[0].periods?.length || 0) > 0 &&
      !periodId
    ) {
      setPeriodId([...periods].reverse()?.[0]?.periods?.[0]?.id!);
      setPeriodReverse([...periods].reverse()!);
      handleSearch([...periods]?.reverse()?.[0]?.periods?.[0]?.id!);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periods, periodId]);

  return (
    <Grid container item xs={12} mt={3}>
      <Grid container item xs={12} alignItems="center">
        <Grid
          container
          item
          xs={12}
          md={12}
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item xs={12} md={8}>
            <Grid container alignItems="center" spacing={1}>
              <Grid item xs={12} md={7}>
                <InputLabel>{t('students.courses.semester')}</InputLabel>
                <Autocomplete
                  options={periodReverse?.map((period) => period?.periods).flat() || []}
                  loading={isLoadingPeriods}
                  // noOptionsText={t('students.courses.no_data')}
                  loadingText={t('students.courses.loading')}
                  groupBy={(option) => option?.per_current_year}
                  getOptionLabel={(option) => `${option?.per_name}`}
                  renderInput={(params) => (
                    <TextField {...params} placeholder={t('students.courses.semester_select')} />
                  )}
                  onChange={(_, value) => {
                    setPeriodId(value?.id || null);
                    handleSearch(value?.id || null);
                    // setPeriod(value);
                    // handleSearch(value);
                  }}
                  value={
                    periodReverse
                      ?.find((period) => period?.periods?.find((period) => period?.id === periodId))
                      ?.periods?.find((period) => period?.id === periodId) || null
                  }
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <Grid container alignItems="center">
                  <Checkbox
                    value={showHomologated}
                    onChange={(_) => handleShowHomologated(_.target.checked)}
                  />
                  <Typography variant="body2">{t('students.courses.approved')}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {isFetching ? (
        <Grid item container xs={12} spacing={2} mt={2}>
          <Grid item xs={12}>
            <Skeleton sx={{ borderRadius: '10px', height: '50px', width: '100%' }} />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Skeleton sx={{ borderRadius: '10px', height: '150px' }} />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Skeleton sx={{ borderRadius: '10px', height: '150px' }} />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Skeleton sx={{ borderRadius: '10px', height: '150px' }} />
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={2} mt={2}>
          <Grid item container xs={12}>
            {periodId && data && !isError && (
              <>
                <Grid item xs={12}>
                  <Tabs
                    value={tabValue}
                    onChange={(_, newValue) => {
                      setTabValue(newValue);
                    }}
                  >
                    {[
                      {
                        st_name: 'Todos',
                        id: 'all',
                      },
                      ...estadosToMutate.sort(
                        (a, b) => getSortedArr(a.st_name) - getSortedArr(b.st_name)
                      ),
                    ]?.map((e, i) => (
                      <Tab
                        key={`tab-matterlist-${i}`}
                        value={e?.st_name}
                        icon={
                          <CircleIcon
                            variant={e.st_name}
                            quantity={
                              e?.st_name === 'Todos'
                                ? data?.length
                                : data?.filter((_) => _.approval_status?.st_name === e?.st_name)
                                    .length || 0
                            }
                          />
                        }
                        label={t(`utils.subject_status.${e.id}`)}
                        sx={{
                          '& .MuiTab-wrapper': {
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '.8px',
                          },
                        }}
                      />
                    ))}
                    <Tab
                      value="homologado"
                      icon={
                        <CircleIcon variant={'homologado'} quantity={mattersHomologated?.length} />
                      }
                      label={t('utils.subject_status.homologado')}
                      sx={{
                        '& .MuiTab-wrapper': {
                          flexDirection: 'row',
                          justifyContent: 'center',
                          alignItems: 'center',
                          gap: '.8px',
                        },
                        display: showHomologated ? 'flex' : 'none',
                      }}
                    />
                  </Tabs>
                </Grid>

                <Grid container spacing={2} mt={2}>
                  {tabValue !== 'Todos' ? (
                    <>
                      {tabValue !== 'homologado' ? (
                        subjectsFiltered?.length > 0 ? (
                          subjectsFiltered?.map((subject) => <SubjectCardNew subject={subject} />)
                        ) : (
                          <Grid item xs={12} textAlign="center">
                            <Typography variant="h6">
                              {t('students.courses.no_subjects')}
                            </Typography>
                          </Grid>
                        )
                      ) : (
                        showHomologated &&
                        (mattersHomologated?.length > 0 ? (
                          mattersHomologated?.map((subject) => (
                            <SubjectCardNew subject={subject} homologated />
                          ))
                        ) : (
                          <Grid item xs={12} textAlign="center">
                            <Typography variant="h6">
                              {t('students.courses.no_subjects')}
                            </Typography>
                          </Grid>
                        ))
                      )}
                    </>
                  ) : (
                    <>
                      {data?.length > 0 ? (
                        allSubjects?.map((subject) => <SubjectCardNew subject={subject} />)
                      ) : (
                        <Grid item xs={12} textAlign="center">
                          <Typography variant="h6">{t('students.courses.no_subjects')}</Typography>
                        </Grid>
                      )}
                    </>
                  )}
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      )}

      {!periodId && !data && false && (
        <Grid container justifyContent={'center'} alignContent={'center'} sx={{ height: '50vh' }}>
          <Grid container justifyContent={'center'} item xs={8} md={6}>
            <Grid container item xs={12} justifyContent={'center'}>
              <IconTelework size={300} />
            </Grid>
            <Typography variant={'body1'} textAlign={'center'} lineHeight={2} component={'div'}>
              <Typography
                variant={'h4'}
                sx={{ color: theme.palette.primary.main }}
                component={'p'}
                align={'center'}
                lineHeight={2}
              >
                Selecciona el período
              </Typography>
              Con los siguientes filtros puede listar <strong>las materias</strong> en las que te
              has registrado.
            </Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default MatterList;
