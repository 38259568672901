import React from 'react';

import { Box, Step, StepButton, Stepper } from '@material-ui/core';
import { IconSchool, IconBuilding, IconFileSearch, IconCards } from '@tabler/icons';
import HomeInstInfoStep from './HomeInstInfoStep';
import CampusStep from './CampusStep';
import TermsStep from './TermsStep';
import ServicesStep from './ServicesStep';

interface IconCompI {
  icon: JSX.Element;
  isActive: boolean;
}
const IconCompoent = ({ icon, isActive }: IconCompI) => {
  return (
    <Box
      sx={{
        width: 50,
        height: 50,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        zIndex: 1,
        padding: 0,
        ...(isActive && { backgroundColor: 'primary.main', color: 'background.paper' }),
      }}
    >
      {icon}
    </Box>
  );
};

const steps = [
  {
    label: 'Inicio',
    component: <HomeInstInfoStep />,
    icon: <IconSchool />,
  },
  {
    label: 'Campus',
    component: <CampusStep />,
    icon: <IconBuilding />,
  },
  {
    label: 'Servicios',
    component: <ServicesStep />,
    icon: <IconCards />,
  },
  {
    label: 'Políticas',
    component: <TermsStep />,
    icon: <IconFileSearch />,
  },
];

const InstitutionInfoWizard = () => {
  const [activeStep, setActiveStep] = React.useState(0);

  //   const totalSteps = () => {
  //     return steps.length;
  //   };

  //   const isLastStep = () => {
  //     return activeStep === totalSteps() - 1;
  //   };

  //   const handleNext = () => {
  //     const newActiveStep = isLastStep() ? 0 : activeStep + 1;
  //     setActiveStep(newActiveStep);
  //   };

  //   const handleBack = () => {
  //     setActiveStep((prevActiveStep) => prevActiveStep - 1);
  //   };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper nonLinear activeStep={activeStep} alternativeLabel sx={{ mb: 2 }}>
        {steps.map((item, index) => (
          <Step
            key={`institution-info-wizard-${index}`}
            sx={{
              '& .MuiStepConnector-root': {
                top: '24px',
              },
            }}
          >
            <StepButton
              color="inherit"
              onClick={handleStep(index)}
              icon={<IconCompoent icon={item.icon} isActive={index === activeStep} />}
            >
              {item.label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <div>
        <React.Fragment>
          {steps[activeStep].component}
          {/* <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
              Back
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button onClick={handleNext} sx={{ mr: 1 }}>
              Next
            </Button>
          </Box> */}
        </React.Fragment>
      </div>
    </Box>
  );
};

export default InstitutionInfoWizard;
