import { Button, Dialog, DialogContent, Grid, Typography } from '@material-ui/core';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import { setEvaluationIncompleteQuestionsOpen } from '../../slices/userEvaluationsSlice';
import iconExams from '../../../../../assets/images/no-tests.png';

const IncompleteQuestionsDialog = () => {
  const { evaluationIncompleteQuestionsOpen } = useAppSelector((state) => state.userEvaluations);

  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(setEvaluationIncompleteQuestionsOpen(false));
  };

  return (
    <Dialog open={evaluationIncompleteQuestionsOpen}>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h2" color="primary" textAlign="center">
              Preguntas pendientes por responder
            </Typography>
          </Grid>
          <Grid item container xs={12} justifyContent="center">
            <img
              style={{
                width: 'auto',
                height: '200px',
              }}
              src={iconExams}
              alt="exams student"
            />
          </Grid>
          <Grid item xs={12}>
            <Typography fontSize="1rem" textAlign="center">
              Aun no ha terminado de completar la evaluación.
              <br />
              Por favor, complete todas las preguntas para poder enviar la evaluación.
            </Typography>
          </Grid>
          <Grid item container xs={12} justifyContent="center">
            <Button onClick={handleClose} variant="contained" color="primary">
              Volver
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default IncompleteQuestionsDialog;
