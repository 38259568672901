import { useDeleteOpeningDateMutation } from '../slices/openingDateApiSlice';
import {
  setOpeningDatesDeleteModalOpen,
  setOpeningDatesIdToDelete,
} from '../slices/openingDateSlice';
import { setEvaluationsOpeningDateSelected } from '../../evaluations/slices/evaluationsSlice';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/useRedux';
import DeleteAlert from '../../../../../shared/components/dialogs/DeleteAlert';

const OpeningDateDeleteDialog = () => {
  const dispatch = useAppDispatch();
  const { openingDatesIdToDelete, openingDatesDeleteModalOpen } = useAppSelector(
    (state) => state.openingDates
  );

  const [deleteOpeningDate, { isLoading, isSuccess, error, isError }] =
    useDeleteOpeningDateMutation();

  const handleDelete = async () => {
    try {
      await deleteOpeningDate({
        id: openingDatesIdToDelete!,
      }).unwrap();

      dispatch(setEvaluationsOpeningDateSelected(null));
    } catch (er) {
      console.log('cannot be deleted');
    }
  };
  const handleClose = () => {
    dispatch(setOpeningDatesIdToDelete(null));
    dispatch(setOpeningDatesDeleteModalOpen(false));
  };

  return (
    <DeleteAlert
      open={openingDatesDeleteModalOpen}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      error={error as any}
      handleDelete={handleDelete}
      onClose={handleClose}
    />
  );
};

export default OpeningDateDeleteDialog;
