import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import SubCard from '../../../shared/components/cards/SubCard';
import { SubjectCurriculumStatus } from '../../../students/subjects-register/interfaces/subjects-approveds';
import SimpleTable from './SimpleTable';
import { useTranslation } from 'react-i18next';

interface Props {
  courses?: SubjectCurriculumStatus[];
  isFetching: boolean;
  error: FetchBaseQueryError | SerializedError | undefined;
}

const ListSubjectsApproved = ({ courses, isFetching, error }: Props) => {
  const { t } = useTranslation();

  const filterApproved = courses?.filter((e) => e.keyword_status_matter === 'APR');

  const data =
    filterApproved?.map(({ matter_id, matter_rename, status_matter, note, cat_name }) => {
      return {
        matter_rename: {
          matter_id,
          matter_rename,
        },
        status_matter,
        cat_name,
        note,
      };
    }) || [];
  return (
    <SubCard
      title={t('teacher.assign_grades.list_subjects_approved.title')}
      content={false}
      darkTitle
    >
      <SimpleTable
        data={data}
        headers={{
          matter_rename: t('teacher.assign_grades.list_subjects_approved.subject'),
          status_matter: t('teacher.assign_grades.list_subjects_approved.status'),
          cat_name: t('teacher.assign_grades.list_subjects_approved.reason'),
          note: t('teacher.assign_grades.list_subjects_approved.grade'),
        }}
        keyExtractor={(item) => item.matter_rename.matter_id?.toString() || '1'}
        isFetching={isFetching}
        error={error}
        customHeadersCellsProperties={{
          matter_rename: {
            align: 'center',
          },
          status_matter: {
            align: 'center',
          },
          cat_name: {
            align: 'center',
          },
          note: {
            align: 'center',
          },
        }}
        customDataCellsProperties={{
          status_matter: {
            align: 'center',
          },
          cat_name: {
            align: 'center',
          },
          note: {
            align: 'center',
          },
        }}
        customRenderers={{
          matter_rename: (row) => <>{row.matter_rename.matter_rename}</>,
          note: (row) => <>{Number(row.note).toFixed(2) || '-'}</>,
        }}
      />
    </SubCard>
  );
};

export default ListSubjectsApproved;
