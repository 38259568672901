import { Button, Grid, Typography } from '@material-ui/core';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import MainCard from '../../../shared/components/cards/MainCard';
// import AddCourseForm from '../components/FilterClassroomForm';

import { setCourseSideModalOpen, setCourseToEdit } from '../slices/courseSlice';
import CourseSideDialog from '../components/CoursesSideDialog';
import DeleteCourseDialog from '../components/DeleteCourseDialog';
import FilterCourses from '../components/FilterCourses';
import { useTranslation } from 'react-i18next';
import { isOutRangeDate } from '../helpers';

const ClassroomsEducationLevelsScreen = () => {
  const { t } = useTranslation();

  const { courseIdToDelete, coursesFilter, courseSideModalOpen } = useAppSelector(
    (state) => state.courses
  );
  const dispatch = useAppDispatch();

  const { campusId, periodId, educationLevelId, offerDescription, periodEndProcess } =
    coursesFilter;

  const handleClick = () => {
    dispatch(setCourseSideModalOpen(true));
  };

  const handleClose = () => {
    dispatch(setCourseSideModalOpen(false));
    dispatch(setCourseToEdit(0));
  };

  // const { courseSideModalOpen } = useAppSelector((state) => state.courses);
  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid container justifyContent="space-between" alignItems="center" sx={{ mb: 3 }}>
        <Typography variant="h2">{t('academic.courses.title')}</Typography>
        <Button
          variant="contained"
          color="primary"
          disabled={isOutRangeDate(periodEndProcess)}
          sx={{
            display:
              campusId && periodId && educationLevelId && offerDescription ? 'initial' : 'none',
          }}
          onClick={handleClick}
        >
          {t('academic.courses.add')}
        </Button>
      </Grid>
      {/* <AddCourseForm /> */}
      <FilterCourses />
      {courseSideModalOpen && (
        <CourseSideDialog open={courseSideModalOpen} handleCloseDialog={handleClose} />
      )}

      {courseIdToDelete && <DeleteCourseDialog />}

      {/* Side dialog */}
      {/* {courseSideModalOpen && (
        <CourseSideDialog
          open={courseSideModalOpen}
          handleCloseDialog={() => dispatch(setCourseSideModalOpen(false))}
        />
      )} */}
    </Grid>
  );
};

export default ClassroomsEducationLevelsScreen;
