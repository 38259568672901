import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import DialogAlert from '../../../shared/components/dialogs/DialogAlert';
import { useResendElectronicDocumentQuery } from '../slices/electronicDocumentsApiSlice';
import {
  resetElectronicDocumentIdUpdate,
  setResendModalOpen,
} from '../slices/electronicDocumentsSlice';

const ResendElectronicDocumentDialog = () => {
  const dispatch = useAppDispatch();
  const [id, setId] = useState(0);
  const { t } = useTranslation();

  const { electronicDocumentIdUpdate, resendModalOpen } = useAppSelector(
    (state) => state.electronicDocuments
  );

  const { isLoading, isSuccess, isError, error } = useResendElectronicDocumentQuery(
    {
      electronicDocumentId: id,
    },
    { skip: id === 0 }
  );
  const handleAction = () => {
    setId(electronicDocumentIdUpdate!);
  };
  const handleClose = () => {
    dispatch(setResendModalOpen(false));
    dispatch(resetElectronicDocumentIdUpdate());
  };
  return (
    <DialogAlert
      open={resendModalOpen}
      onClose={handleClose}
      handleAction={handleAction}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      error={error as string}
      title={t('financial.electronic_docs.resend_dialog.title')}
      bodyText={t('financial.electronic_docs.resend_dialog.body')}
      updateSuccess={t('financial.electronic_docs.resend_dialog.success')}
      customTitleSendButton={t('financial.electronic_docs.resend_dialog.confirm_btn')}
    />
  );
};
export default ResendElectronicDocumentDialog;
