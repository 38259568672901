import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISearchTable } from '../../../shared/interfaces/paginatedTable.interface';
import { LearningComponents } from '../interfaces/learningComponents.interfaces';

interface learningComponentsState {
  learningComponentList: LearningComponents[];
  availablesLearningComponentList: LearningComponents[];
  deleteModalOpen: boolean;
  learningComponentIdToDelete?: number;
  currentLearningComponentId?: number;
  //Collective

  offerIdSelected: number | null;
  learningComponentsTable: ISearchTable;
}

const initialState: learningComponentsState = {
  learningComponentList: [],
  availablesLearningComponentList: [],
  deleteModalOpen: false,

  offerIdSelected: null,
  learningComponentsTable: {
    page: 1,
    perPage: 5,
    search: '',
  },
};

const learningComponentsSlice = createSlice({
  name: 'learningComponents',
  initialState,
  reducers: {
    setLearningComponentList: (state, action: PayloadAction<LearningComponents[]>) => {
      state.learningComponentList = action.payload;
    },
    setAvailablesLearningComponentsList: (state, action: PayloadAction<LearningComponents[]>) => {
      state.availablesLearningComponentList = action.payload;
    },
    setcurrentLearningComponentId: (state, action: PayloadAction<number>) => {
      state.currentLearningComponentId = action.payload;
    },

    setDeleteModalOpen: (state, action: PayloadAction<boolean>) => {
      state.deleteModalOpen = action.payload;
    },

    openDeleteModalWith: (state, action: PayloadAction<number>) => {
      state.learningComponentIdToDelete = action.payload;
      state.deleteModalOpen = true;
    },
    resetLearningComponentIdToDelete: (state) => {
      state.learningComponentIdToDelete = undefined;
    },

    setOfferIdSelected: (state, action: PayloadAction<number | null>) => {
      state.offerIdSelected = action.payload;
    },
    updateLearningComponentsTable: (state, action: PayloadAction<ISearchTable>) => {
      state.learningComponentsTable = action.payload;
    },
  },
});

export const {
  setLearningComponentList,
  setAvailablesLearningComponentsList,
  setDeleteModalOpen,
  openDeleteModalWith,
  setcurrentLearningComponentId,
  resetLearningComponentIdToDelete,
  setOfferIdSelected,
  updateLearningComponentsTable,
} = learningComponentsSlice.actions;

export default learningComponentsSlice.reducer;
