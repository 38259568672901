import { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

import { PublicationStepsPayload } from '../../../interfaces/publicationsPayload.interfaces';
import {
  SpecificDetailsMagazineSchema,
  SpecificDetailsBookSchema,
  SpecificDetailsPonenciaSchema,
} from '../../../constants/validationSchemas';
import { Publication } from '../../../interfaces/publications.interfaces';
import DetailsFormsSplitted from './DetailsFormsSplitted';
import { TypePublication } from '../../../constants/typePublication';

interface Props {
  setData: React.Dispatch<React.SetStateAction<PublicationStepsPayload>>;
  getData: PublicationStepsPayload;
  setNext: React.Dispatch<React.SetStateAction<number>>;
  isEdit?: boolean;
  dataEdit?: Publication;
}

const SpecificDetailsForm = ({ setData, setNext, isEdit = false, dataEdit, getData }: Props) => {
  const { t } = useTranslation();

  const handleNext = () => {
    setNext((step) => step + 1);
  };

  const handleReturn = () => {
    setNext((step) => step - 1);
  };

  const validateType = () => {
    const type = getData?.generalDetails?.type_publication;
    if (type === 'PAPERS' /*'ARTICULOS'*/) {
      return SpecificDetailsMagazineSchema;
    } else if (type === 'LIBROS') {
      return SpecificDetailsBookSchema;
    } else if (type === 'MEMORIAS') {
      return SpecificDetailsPonenciaSchema;
    } else return SpecificDetailsBookSchema;
  };

  const { errors, touched, handleSubmit, getFieldProps, values, setFieldValue } = useFormik({
    initialValues: {
      magazine: isEdit ? dataEdit?.magazine || '' : '',
      urlMagazine: isEdit ? dataEdit?.url_magazine || '' : '',
      numberMagazine: isEdit ? dataEdit?.number_magazine || '' : '',
      magazinesJri: isEdit ? dataEdit?.magazine_sjrq || '' : '',
      magazinesJrq: isEdit ? dataEdit?.magazine_sjrq || '' : '',
      nameCongress: isEdit ? dataEdit?.name_congress || '' : '',
      organizer: isEdit ? dataEdit?.organizer || '' : '',
      organizingCommittee: isEdit ? dataEdit?.organizing_committee || '' : '',
      book: isEdit ? dataEdit?.book || '' : '',
      bookISBN: isEdit ? dataEdit?.book_ISBN || '' : '',
      bookEdition: isEdit ? dataEdit?.book_edition || '' : '',
      // chapter: isEdit ? dataEdit?.chapter || '' : '',
      numberChapter: isEdit ? dataEdit?.number_chapter || '' : '',
      editor: isEdit ? dataEdit?.editor || '' : '',
      typePublication: (isEdit ? dataEdit?.type_publication || '' : '') as TypePublication,

      pubDOI: isEdit ? dataEdit?.sci_pub_DOI || '' : '',
      pubISSN: isEdit ? dataEdit?.sci_pub_ISSN || '' : '',
      pubEditorial: isEdit ? dataEdit?.sci_pub_editorial || '' : '',
      numberPage: isEdit ? dataEdit?.number_page || '' : '',
    },
    validationSchema: validateType(),
    onSubmit: async ({
      magazine,
      magazinesJri,
      magazinesJrq,
      nameCongress,
      numberMagazine,
      organizer,
      organizingCommittee,
      urlMagazine,
      book,
      // chapter,
      editor,
      numberChapter,
      bookISBN,
      bookEdition,
      numberPage,
      pubDOI,
      pubEditorial,
      pubISSN,
    }) => {
      setData((data) => ({
        ...data,
        specificDetails: {
          magazine,
          magazine_sjri: magazinesJri,
          magazine_sjrq: magazinesJrq,
          name_congress: nameCongress,
          number_magazine: numberMagazine,
          organizer,
          organizing_committee: organizingCommittee,
          url_magazine: urlMagazine,
          book: book,
          sci_pub_ISBN: bookISBN,
          sci_pub_edition: bookEdition,
          // chapter: chapter,
          editor: editor,
          number_chapter: numberChapter,

          sci_pub_DOI: pubDOI,
          sci_pub_ISSN: pubISSN,
          sci_pub_editorial: pubEditorial,
          number_page: numberPage,
        },
      }));
      handleNext();
    },
  });

  useEffect(() => {
    if (getData?.generalDetails?.type_publication) {
      setFieldValue('typePublication', getData?.generalDetails?.type_publication);
    }
  }, [getData, setFieldValue]);

  // console.log(validateType);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction="column">
        <Grid item container xs={12} md={6} spacing={2}>
          <DetailsFormsSplitted
            errors={errors}
            touched={touched}
            type={values.typePublication}
            getFieldProps={getFieldProps}
          />

          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            spacing={2}
            sx={{ mt: 6 }}
          >
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="outlined"
                size="large"
                color="primary"
                onClick={handleReturn}
              >
                {t('academic.subjects.add.back')}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                type="submit"
                variant="contained"
                size="large"
                color="primary"
                // disabled={isLoading}
                // endIcon={isLoading && <CircularProgress size={20} />}
              >
                {t('publication.AssignsForm.button.next')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default SpecificDetailsForm;
