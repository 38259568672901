import { Grid, Stack, Typography } from '@material-ui/core';

import { useEffect, useState } from 'react';
import { BooksInfo } from '../interfaces/dashboard.interfaces';

import booksIcon from '../../../../assets/images/icons/books.png';
import databasesIcon from '../../../../assets/images/icons/databases.png';
import digitalBooksIcon from '../../../../assets/images/icons/digital_books.png';
import newsIcon from '../../../../assets/images/icons/news.png';
import scientificPublicationsIcon from '../../../../assets/images/icons/scientific_publications.png';
import tesisIcon from '../../../../assets/images/icons/tesis.png';

const info = [
  {
    keyword: 'total_books',
    label: 'Libros impresos',
    icon: booksIcon,
  },
  {
    keyword: 'digital_books',
    label: 'Libros digitales',
    icon: digitalBooksIcon,
  },
  {
    keyword: 'news_papers',
    label: 'Periódicos y revistas',
    icon: newsIcon,
  },
  {
    keyword: 'scientific_publications',
    label: 'Publicaciones',
    icon: scientificPublicationsIcon,
  },
  {
    keyword: 'tesis',
    label: 'Tesis',
    icon: tesisIcon,
  },
  {
    keyword: 'dbs_external',
    label: 'Bases de datos',
    icon: databasesIcon,
  },
];

const InfoItem = ({ label, icon, value = 0 }: { label: string; icon: string; value: number }) => {
  const [numValue, setNumValue] = useState(0);

  const time = 1500;

  const sum = Math.ceil(value / time);

  useEffect(() => {
    const interval = setInterval(() => {
      if (numValue < value) {
        setNumValue(numValue + sum);
      }
    }, 100);

    setTimeout(() => {
      clearInterval(interval);
      setNumValue(value);
    }, time);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numValue, value]);

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{
        maxWidth: 160,
      }}
    >
      <img
        src={icon}
        alt={label}
        style={{
          height: '60px',
          width: 'auto',
        }}
      />
      <Typography variant="h2" textAlign="center">
        {numValue}
      </Typography>
      <Typography variant="body2" textAlign="center">
        {label}
      </Typography>
    </Stack>
  );
};

const LibraryInfoV2 = ({ booksInfo }: { booksInfo: BooksInfo }) => {
  return (
    <Grid container justifyContent="center" spacing={2} p={3}>
      {info.map((item) => (
        <Grid item>
          <InfoItem
            label={item.label}
            icon={item.icon}
            value={booksInfo[item.keyword as keyof BooksInfo]}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default LibraryInfoV2;
