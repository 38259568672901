import { Box, Button, Grid, Skeleton, TextField, Typography } from '@material-ui/core';
import { DateRangePicker, LocalizationProvider } from '@material-ui/lab';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import { es } from 'date-fns/locale';
import { useFormik } from 'formik';
import InputLabel from '../../../../shared/components/forms/InputLabel';
import { useLazyMetricsVisitsDatabaseByEducationLevelQuery } from '../../slices/statisticsApiSlice';
import { format } from 'date-fns';
import { useAppSelector } from '../../../../../hooks/useRedux';
import Chart from 'react-apexcharts';
import MetricsMessage from '../MetricsMessage';

const Docentes = () => {
  const { currentProfile } = useAppSelector((state) => state.access);
  const [metrics, { isLoading, data, isUninitialized }] =
    useLazyMetricsVisitsDatabaseByEducationLevelQuery();

  const { values, errors, touched, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      ini: '',
      end: '',
    },
    onSubmit: (values) => {
      metrics({
        start_date: format(new Date(values.ini), 'yyyy-MM-dd'),
        end_date: format(new Date(values.end), 'yyyy-MM-dd'),
        user_profile_id: currentProfile?.profileId!,
      });
    },
  });

  return (
    <Grid container>
      <Grid item container spacing={2} direction="row-reverse">
        <Grid item xs={12} md={8} lg={8}>
          <InputLabel>Fechas</InputLabel>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
            <DateRangePicker
              value={[values.ini, values.end]}
              onChange={(val) => {
                if (val) {
                  setFieldValue('ini', val[0]);
                  setFieldValue('end', val[1]);
                }
              }}
              renderInput={(startProps, endProps) => (
                <>
                  <TextField
                    {...startProps}
                    label={null}
                    fullWidth
                    placeholder="Fecha"
                    error={Boolean(errors.ini && touched.ini)}
                    helperText={Boolean(errors.ini && touched.ini) && errors.ini}
                  />
                  <Box sx={{ mx: 2 }}> - </Box>
                  <TextField
                    {...endProps}
                    label={null}
                    fullWidth
                    placeholder="Fecha"
                    error={Boolean(errors.end && touched.end)}
                    helperText={Boolean(errors.end && touched.end) && errors.end}
                  />
                </>
              )}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={12} md={12} lg={12} textAlign="right">
          <Button
            variant="contained"
            size="large"
            color="primary"
            sx={{ mt: 2 }}
            onClick={() => handleSubmit()}
          >
            Buscar
          </Button>
        </Grid>
      </Grid>
      <br />
      <br />
      <Grid container spacing={6}>
        {isUninitialized ? (
          <Grid item xs={12}>
            <MetricsMessage />
          </Grid>
        ) : (
          <>
            <Grid item xs={12} md={12} lg={12}>
              <Typography variant="h3" sx={{ m: 2 }}>
                Número de visitas por Bases de datos contratadas
              </Typography>
              <Box sx={{ maxWidth: 600, margin: '0 auto' }}>
                {isLoading ? (
                  <Skeleton width={400} height={400} />
                ) : (
                  <>
                    {data && (
                      <Chart
                        series={[
                          {
                            name: 'Docentes',
                            data: [
                              data?.ingenieriasCollaborator
                                ? Number(data?.ingenieriasCollaborator)
                                : 0,
                              data?.cienciasEconomicasCollaborator
                                ? Number(data?.cienciasEconomicasCollaborator)
                                : 0,
                              data?.marketingCollaborator ? Number(data?.marketingCollaborator) : 0,
                              data?.derechoCollaborator ? Number(data?.derechoCollaborator) : 0,
                              data?.estudiosGlobalesCollaborator
                                ? Number(data?.estudiosGlobalesCollaborator)
                                : 0,
                              data?.cienciasSaludCollaborator
                                ? Number(data?.cienciasSaludCollaborator)
                                : 0,
                              data?.posgradoCollaborator ? Number(data?.posgradoCollaborator) : 0,
                            ],
                          },
                          {
                            name: 'Estudiantes',
                            data: [
                              data?.ingenieriasStudents ? Number(data?.ingenieriasStudents) : 0,
                              data?.cienciasEconomicasStudents
                                ? Number(data?.cienciasEconomicasStudents)
                                : 0,
                              data?.marketingStudents ? Number(data?.marketingStudents) : 0,
                              data?.derechoStudents ? Number(data?.derechoStudents) : 0,
                              data?.estudiosGlobalesStudents
                                ? Number(data?.estudiosGlobalesStudents)
                                : 0,
                              data?.cienciasSaludStudents ? Number(data?.cienciasSaludStudents) : 0,
                              data?.posgradoStudents ? Number(data?.posgradoStudents) : 0,
                            ],
                          },
                        ]}
                        options={{
                          xaxis: {
                            categories: ['FING', 'FCEE', 'FMC', 'FDG', 'FEGH', 'FCS', 'POSGRADO'],

                            labels: {
                              rotate: 0,
                              style: {
                                fontSize: '8px',
                              },
                            },
                          },
                          dataLabels: {
                            enabled: true,
                            offsetX: -6,
                            style: {
                              fontSize: '12px',
                              colors: ['#fff'],
                            },
                          },
                          chart: {
                            type: 'bar',
                            stacked: true,
                          },
                        }}
                        type="bar"
                        width="100%"
                      />
                    )}
                  </>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h3" sx={{ m: 2 }}>
                Total de Búsquedas por Docentes
              </Typography>
              {data && (
                <Chart
                  series={[
                    {
                      name: 'Vlex',
                      data: [
                        data?.vlexIngenieriasCollaborators
                          ? Number(data?.vlexIngenieriasCollaborators)
                          : 0,
                        data?.vlexCienciasEconomicasCollaborators
                          ? Number(data?.vlexCienciasEconomicasCollaborators)
                          : 0,
                        data?.vlexCienciasEconomicasCollaborators
                          ? Number(data?.vlexCienciasEconomicasCollaborators)
                          : 0,

                        data?.vlexCienciasEconomicasCollaborators
                          ? Number(data?.vlexCienciasEconomicasCollaborators)
                          : 0,
                        data?.vlexCienciasEconomicasCollaborators
                          ? Number(data?.vlexCienciasEconomicasCollaborators)
                          : 0,
                        data?.vlexCienciasEconomicasCollaborators
                          ? Number(data?.vlexCienciasEconomicasCollaborators)
                          : 0,
                        data?.vlexCienciasEconomicasCollaborators
                          ? Number(data?.vlexCienciasEconomicasCollaborators)
                          : 0,
                      ],
                    },
                    {
                      name: 'FielWeb',
                      data: [
                        data?.fielwebIngenieriasCollaborators
                          ? Number(data?.fielwebIngenieriasCollaborators)
                          : 0,
                        data?.fielwebCienciasEconomicasCollaborators
                          ? Number(data?.fielwebCienciasEconomicasCollaborators)
                          : 0,
                        data?.fielwebCienciasEconomicasCollaborators
                          ? Number(data?.fielwebCienciasEconomicasCollaborators)
                          : 0,

                        data?.fielwebCienciasEconomicasCollaborators
                          ? Number(data?.fielwebCienciasEconomicasCollaborators)
                          : 0,
                        data?.fielwebCienciasEconomicasCollaborators
                          ? Number(data?.fielwebCienciasEconomicasCollaborators)
                          : 0,
                        data?.fielwebCienciasEconomicasCollaborators
                          ? Number(data?.fielwebCienciasEconomicasCollaborators)
                          : 0,
                        data?.fielwebCienciasEconomicasCollaborators
                          ? Number(data?.fielwebCienciasEconomicasCollaborators)
                          : 0,
                      ],
                    },
                    {
                      name: 'Web of Science',
                      data: [
                        data?.webOfScienceGroupIngenieriasCollaborators
                          ? Number(data?.webOfScienceGroupIngenieriasCollaborators)
                          : 0,
                        data?.webOfScienceGroupCienciasEconomicasCollaborators
                          ? Number(data?.webOfScienceGroupCienciasEconomicasCollaborators)
                          : 0,
                        data?.webOfScienceGroupCienciasEconomicasCollaborators
                          ? Number(data?.webOfScienceGroupCienciasEconomicasCollaborators)
                          : 0,

                        data?.webOfScienceGroupCienciasEconomicasCollaborators
                          ? Number(data?.webOfScienceGroupCienciasEconomicasCollaborators)
                          : 0,
                        data?.webOfScienceGroupCienciasEconomicasCollaborators
                          ? Number(data?.webOfScienceGroupCienciasEconomicasCollaborators)
                          : 0,
                        data?.webOfScienceGroupCienciasEconomicasCollaborators
                          ? Number(data?.webOfScienceGroupCienciasEconomicasCollaborators)
                          : 0,
                        data?.webOfScienceGroupCienciasEconomicasCollaborators
                          ? Number(data?.webOfScienceGroupCienciasEconomicasCollaborators)
                          : 0,
                      ],
                    },
                    {
                      name: 'Scopus',
                      data: [
                        data?.scopusIngenieriasCollaborators
                          ? Number(data?.scopusIngenieriasCollaborators)
                          : 0,
                        data?.scopusCienciasEconomicasCollaborators
                          ? Number(data?.scopusCienciasEconomicasCollaborators)
                          : 0,
                        data?.scopusCienciasEconomicasCollaborators
                          ? Number(data?.scopusCienciasEconomicasCollaborators)
                          : 0,

                        data?.scopusCienciasEconomicasCollaborators
                          ? Number(data?.scopusCienciasEconomicasCollaborators)
                          : 0,
                        data?.scopusCienciasEconomicasCollaborators
                          ? Number(data?.scopusCienciasEconomicasCollaborators)
                          : 0,
                        data?.scopusCienciasEconomicasCollaborators
                          ? Number(data?.scopusCienciasEconomicasCollaborators)
                          : 0,
                        data?.scopusCienciasEconomicasCollaborators
                          ? Number(data?.scopusCienciasEconomicasCollaborators)
                          : 0,
                      ],
                    },
                    {
                      name: 'Ebsco',
                      data: [
                        data?.ebscoIngenieriasCollaborators
                          ? Number(data?.ebscoIngenieriasCollaborators)
                          : 0,
                        data?.ebscoCienciasEconomicasCollaborators
                          ? Number(data?.ebscoCienciasEconomicasCollaborators)
                          : 0,
                        data?.ebscoCienciasEconomicasCollaborators
                          ? Number(data?.ebscoCienciasEconomicasCollaborators)
                          : 0,

                        data?.ebscoCienciasEconomicasCollaborators
                          ? Number(data?.ebscoCienciasEconomicasCollaborators)
                          : 0,
                        data?.ebscoCienciasEconomicasCollaborators
                          ? Number(data?.ebscoCienciasEconomicasCollaborators)
                          : 0,
                        data?.ebscoCienciasEconomicasCollaborators
                          ? Number(data?.ebscoCienciasEconomicasCollaborators)
                          : 0,
                        data?.ebscoCienciasEconomicasCollaborators
                          ? Number(data?.ebscoCienciasEconomicasCollaborators)
                          : 0,
                      ],
                    },
                    {
                      name: 'Eureka',
                      data: [
                        data?.eurekaIngenieriasCollaborators
                          ? Number(data?.eurekaIngenieriasCollaborators)
                          : 0,
                        data?.eurekaCienciasEconomicasCollaborators
                          ? Number(data?.eurekaCienciasEconomicasCollaborators)
                          : 0,
                        data?.eurekaCienciasEconomicasCollaborators
                          ? Number(data?.eurekaCienciasEconomicasCollaborators)
                          : 0,

                        data?.eurekaCienciasEconomicasCollaborators
                          ? Number(data?.eurekaCienciasEconomicasCollaborators)
                          : 0,
                        data?.eurekaCienciasEconomicasCollaborators
                          ? Number(data?.eurekaCienciasEconomicasCollaborators)
                          : 0,
                        data?.eurekaCienciasEconomicasCollaborators
                          ? Number(data?.eurekaCienciasEconomicasCollaborators)
                          : 0,
                        data?.eurekaCienciasEconomicasCollaborators
                          ? Number(data?.eurekaCienciasEconomicasCollaborators)
                          : 0,
                      ],
                    },
                    {
                      name: 'Bibliotechnia',
                      data: [
                        data?.bibliotechniaIngenieriasCollaborators
                          ? Number(data?.bibliotechniaIngenieriasCollaborators)
                          : 0,
                        data?.bibliotechniaCienciasEconomicasCollaborators
                          ? Number(data?.bibliotechniaCienciasEconomicasCollaborators)
                          : 0,
                        data?.bibliotechniaCienciasEconomicasCollaborators
                          ? Number(data?.bibliotechniaCienciasEconomicasCollaborators)
                          : 0,

                        data?.bibliotechniaCienciasEconomicasCollaborators
                          ? Number(data?.bibliotechniaCienciasEconomicasCollaborators)
                          : 0,
                        data?.bibliotechniaCienciasEconomicasCollaborators
                          ? Number(data?.bibliotechniaCienciasEconomicasCollaborators)
                          : 0,
                        data?.bibliotechniaCienciasEconomicasCollaborators
                          ? Number(data?.bibliotechniaCienciasEconomicasCollaborators)
                          : 0,
                        data?.bibliotechniaCienciasEconomicasCollaborators
                          ? Number(data?.bibliotechniaCienciasEconomicasCollaborators)
                          : 0,
                      ],
                    },
                  ]}
                  options={{
                    xaxis: {
                      categories: ['FING', 'FCEE', 'FMC', 'FDG', 'FEGH', 'FCS', 'POSGRADO'],

                      labels: {
                        rotate: 0,
                        style: {
                          fontSize: '8px',
                        },
                      },
                    },
                    dataLabels: {
                      enabled: true,
                      offsetX: -6,
                      style: {
                        fontSize: '12px',
                        colors: ['#fff'],
                      },
                    },
                    chart: {
                      type: 'line',
                      height: 400,
                      toolbar: {
                        show: true,
                      },
                    },
                  }}
                  type="line"
                  width="100%"
                />
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h3" sx={{ m: 2 }}>
                Total de Búsquedas por Alumnos
              </Typography>
              {data && (
                <Chart
                  series={[
                    {
                      name: 'Vlex',
                      data: [
                        data?.vlexIngenieriasStudents ? Number(data?.vlexIngenieriasStudents) : 0,
                        data?.vlexCienciasEconomicasStudents
                          ? Number(data?.vlexCienciasEconomicasStudents)
                          : 0,
                        data?.vlexCienciasEconomicasStudents
                          ? Number(data?.vlexCienciasEconomicasStudents)
                          : 0,

                        data?.vlexCienciasEconomicasStudents
                          ? Number(data?.vlexCienciasEconomicasStudents)
                          : 0,
                        data?.vlexCienciasEconomicasStudents
                          ? Number(data?.vlexCienciasEconomicasStudents)
                          : 0,
                        data?.vlexCienciasEconomicasStudents
                          ? Number(data?.vlexCienciasEconomicasStudents)
                          : 0,
                        data?.vlexCienciasEconomicasStudents
                          ? Number(data?.vlexCienciasEconomicasStudents)
                          : 0,
                      ],
                    },
                    {
                      name: 'FielWeb',
                      data: [
                        data?.fielwebIngenieriasStudents
                          ? Number(data?.fielwebIngenieriasStudents)
                          : 0,
                        data?.fielwebCienciasEconomicasStudents
                          ? Number(data?.fielwebCienciasEconomicasStudents)
                          : 0,
                        data?.fielwebCienciasEconomicasStudents
                          ? Number(data?.fielwebCienciasEconomicasStudents)
                          : 0,

                        data?.fielwebCienciasEconomicasStudents
                          ? Number(data?.fielwebCienciasEconomicasStudents)
                          : 0,
                        data?.fielwebCienciasEconomicasStudents
                          ? Number(data?.fielwebCienciasEconomicasStudents)
                          : 0,
                        data?.fielwebCienciasEconomicasStudents
                          ? Number(data?.fielwebCienciasEconomicasStudents)
                          : 0,
                        data?.fielwebCienciasEconomicasStudents
                          ? Number(data?.fielwebCienciasEconomicasStudents)
                          : 0,
                      ],
                    },
                    {
                      name: 'Web of Science',
                      data: [
                        data?.webOfScienceGroupIngenieriasStudents
                          ? Number(data?.webOfScienceGroupIngenieriasStudents)
                          : 0,
                        data?.webOfScienceGroupCienciasEconomicasStudents
                          ? Number(data?.webOfScienceGroupCienciasEconomicasStudents)
                          : 0,
                        data?.webOfScienceGroupCienciasEconomicasStudents
                          ? Number(data?.webOfScienceGroupCienciasEconomicasStudents)
                          : 0,

                        data?.webOfScienceGroupCienciasEconomicasStudents
                          ? Number(data?.webOfScienceGroupCienciasEconomicasStudents)
                          : 0,
                        data?.webOfScienceGroupCienciasEconomicasStudents
                          ? Number(data?.webOfScienceGroupCienciasEconomicasStudents)
                          : 0,
                        data?.webOfScienceGroupCienciasEconomicasStudents
                          ? Number(data?.webOfScienceGroupCienciasEconomicasStudents)
                          : 0,
                        data?.webOfScienceGroupCienciasEconomicasStudents
                          ? Number(data?.webOfScienceGroupCienciasEconomicasStudents)
                          : 0,
                      ],
                    },
                    {
                      name: 'Scopus',
                      data: [
                        data?.scopusIngenieriasStudents
                          ? Number(data?.scopusIngenieriasStudents)
                          : 0,
                        data?.scopusCienciasEconomicasStudents
                          ? Number(data?.scopusCienciasEconomicasStudents)
                          : 0,
                        data?.scopusCienciasEconomicasStudents
                          ? Number(data?.scopusCienciasEconomicasStudents)
                          : 0,

                        data?.scopusCienciasEconomicasStudents
                          ? Number(data?.scopusCienciasEconomicasStudents)
                          : 0,
                        data?.scopusCienciasEconomicasStudents
                          ? Number(data?.scopusCienciasEconomicasStudents)
                          : 0,
                        data?.scopusCienciasEconomicasStudents
                          ? Number(data?.scopusCienciasEconomicasStudents)
                          : 0,
                        data?.scopusCienciasEconomicasStudents
                          ? Number(data?.scopusCienciasEconomicasStudents)
                          : 0,
                      ],
                    },
                    {
                      name: 'Ebsco',
                      data: [
                        data?.ebscoIngenieriasStudents ? Number(data?.ebscoIngenieriasStudents) : 0,
                        data?.ebscoCienciasEconomicasStudents
                          ? Number(data?.ebscoCienciasEconomicasStudents)
                          : 0,
                        data?.ebscoCienciasEconomicasStudents
                          ? Number(data?.ebscoCienciasEconomicasStudents)
                          : 0,

                        data?.ebscoCienciasEconomicasStudents
                          ? Number(data?.ebscoCienciasEconomicasStudents)
                          : 0,
                        data?.ebscoCienciasEconomicasStudents
                          ? Number(data?.ebscoCienciasEconomicasStudents)
                          : 0,
                        data?.ebscoCienciasEconomicasStudents
                          ? Number(data?.ebscoCienciasEconomicasStudents)
                          : 0,
                        data?.ebscoCienciasEconomicasStudents
                          ? Number(data?.ebscoCienciasEconomicasStudents)
                          : 0,
                      ],
                    },
                    {
                      name: 'Eureka',
                      data: [
                        data?.eurekaIngenieriasStudents
                          ? Number(data?.eurekaIngenieriasStudents)
                          : 0,
                        data?.eurekaCienciasEconomicasStudents
                          ? Number(data?.eurekaCienciasEconomicasStudents)
                          : 0,
                        data?.eurekaCienciasEconomicasStudents
                          ? Number(data?.eurekaCienciasEconomicasStudents)
                          : 0,

                        data?.eurekaCienciasEconomicasStudents
                          ? Number(data?.eurekaCienciasEconomicasStudents)
                          : 0,
                        data?.eurekaCienciasEconomicasStudents
                          ? Number(data?.eurekaCienciasEconomicasStudents)
                          : 0,
                        data?.eurekaCienciasEconomicasStudents
                          ? Number(data?.eurekaCienciasEconomicasStudents)
                          : 0,
                        data?.eurekaCienciasEconomicasStudents
                          ? Number(data?.eurekaCienciasEconomicasStudents)
                          : 0,
                      ],
                    },
                    {
                      name: 'Bibliotechnia',
                      data: [
                        data?.bibliotechniaIngenieriasStudents
                          ? Number(data?.bibliotechniaIngenieriasStudents)
                          : 0,
                        data?.bibliotechniaCienciasEconomicasStudents
                          ? Number(data?.bibliotechniaCienciasEconomicasStudents)
                          : 0,
                        data?.bibliotechniaCienciasEconomicasStudents
                          ? Number(data?.bibliotechniaCienciasEconomicasStudents)
                          : 0,

                        data?.bibliotechniaCienciasEconomicasStudents
                          ? Number(data?.bibliotechniaCienciasEconomicasStudents)
                          : 0,
                        data?.bibliotechniaCienciasEconomicasStudents
                          ? Number(data?.bibliotechniaCienciasEconomicasStudents)
                          : 0,
                        data?.bibliotechniaCienciasEconomicasStudents
                          ? Number(data?.bibliotechniaCienciasEconomicasStudents)
                          : 0,
                        data?.bibliotechniaCienciasEconomicasStudents
                          ? Number(data?.bibliotechniaCienciasEconomicasStudents)
                          : 0,
                      ],
                    },
                  ]}
                  options={{
                    xaxis: {
                      categories: ['FING', 'FCEE', 'FMC', 'FDG', 'FEGH', 'FCS', 'POSGRADO'],

                      labels: {
                        rotate: 0,
                        style: {
                          fontSize: '8px',
                        },
                      },
                    },
                    dataLabels: {
                      enabled: true,
                      offsetX: -6,
                      style: {
                        fontSize: '12px',
                        colors: ['#fff'],
                      },
                    },
                    chart: {
                      type: 'line',
                      height: 400,
                      toolbar: {
                        show: true,
                      },
                    },
                  }}
                  type="line"
                  width="100%"
                />
              )}
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default Docentes;
