import { Grid, Typography, useTheme } from '@material-ui/core';
import { baseUrl } from '../../../../../api/mainApi';

import emptyCategoryCover from '../../../../../assets/images/nbs-circle-empty-cover.jpg';

interface CategoryCardProps {
  selected: boolean;
  imageUrl: string;
  text: string;
  onClick: () => void;
}

const CategoryCard = ({ selected, imageUrl, text, onClick }: CategoryCardProps) => {
  const theme = useTheme();

  return (
    <Grid
      container
      justifyContent="center"
      sx={{
        width: '200px',
      }}
    >
      <Grid item>
        <img
          onClick={onClick}
          style={{
            boxShadow: selected
              ? `0 0 0 7px white, 0 0 0 10px ${theme.palette.secondary.main}`
              : 'none',
            width: '120px',
            height: '120px',
            cursor: 'pointer',
            borderRadius: '50%',
          }}
          src={imageUrl ? `${baseUrl}/show?name=${imageUrl}` : emptyCategoryCover}
          alt={text}
        />
      </Grid>
      <Grid item xs={10} mt={2}>
        <Typography
          variant={selected ? 'h4' : 'h4'}
          fontWeight={selected ? 'bold' : 'normal'}
          color={selected ? 'primary' : 'grey[500]'}
          align="center"
          sx={{
            cursor: 'pointer',
            width: '100%',
            margin: '0 auto',
          }}
          onClick={onClick}
        >
          {text}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default CategoryCard;
