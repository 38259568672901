// reducers
import academicOfferingsSlice from '../modules/maintenance/academic-offerings/slices/academicOfferingsSlice';
import academicRequestsReducer from '../modules/students/academic-requests/slices/academicRequestsSlice';
import accessReducer from '../modules/shared/reducers/accessReducer';
import agreementsReducer from '../modules/maintenance/agreements/slices/agreementsSlices';
import authReducer from '../modules/auth/slices/authSlice';
import calificationModelReducer from '../modules/maintenance/calification-models/slices/calificationModelsSlice';
import campusReducer from '../modules/maintenance/campus/slices/campusSlice';
import classroomEdLvlReducer from '../modules/maintenance/classrooms-educational-levels/slices/classroomEdLvlSlice';
import classroomsReducer from '../modules/maintenance/classrooms/slices/classroomsSlices';
import collaboratorsReducer from '../modules/hr/collaborators/slices/collaboratorsSlice';
import companiesReducer from '../modules/maintenance/companies/slices/companiesSlice';
import configurationsReducer from '../modules/maintenance/configurations/slices/configurationsSlice';
import coursesReducer from '../modules/maintenance/courses/slices/courseSlice';
import customizationReducer from '../modules/shared/reducers/customizationReducer';
import departmentsReducer from '../modules/maintenance/departments/slices/departmentsSlice';
import economicGroupsReducer from '../modules/maintenance/economic-groups/slices/economicGroupSlice';
import educationLevelCareerReducer from '../modules/maintenance/education-level-career/slices/ELCSlice';
import educationLevelsReducer from '../modules/maintenance/education-levels/slices/educationLevelsSlice';
import educationLevelSubjectsReducer from '../modules/maintenance/education-level-subject/slices/ELSSlice';
import groupAreasReducer from '../modules/maintenance/groups-area/slices/groupAreasSlice';
import homologationsReducer from '../modules/maintenance/homologations/slices/homologationsSlice';
import hourhandsReducer from '../modules/maintenance/hourhands/slices/hourhandsSlice';
import hoursTeachersReducer from '../modules/hr/hours-to-teachers/slice/hoursTeacherSlice';
import institutesReducer from '../modules/maintenance/institutes/slices/institutesSlice';
import mattersReducer from '../modules/maintenance/matters/slices/mattersSlice';
import mattersTypesReducer from '../modules/maintenance/matters-types/slices/mattersTypesSlice';
import meshsReducer from '../modules/maintenance/meshs/slices/meshSlice';
import parallelsReducer from '../modules/maintenance/parallels/slices/parallesSlice';
import pensumsReducer from '../modules/maintenance/pensums/slices/pensumsSlice';
import periodsReducer from '../modules/maintenance/periods/slices/periodsSlice';
import periodTypesReducer from '../modules/maintenance/periods-types/slices/periodTypesSlice';
import permissionsReducer from '../modules/maintenance/permissions/slices/permissionsSlice';
import positionsReducer from '../modules/maintenance/positions/slices/PositionsSlice';
import profilesReducer from '../modules/maintenance/profiles/slices/profilesSlice';
import rolesReducer from '../modules/maintenance/roles/slices/rolesSlice';
import studentsReducer from '../modules/maintenance/students/slices/studentsSlice';
import studentsGraduateReducer from '../modules/maintenance/students_graduate/slices/studentsGraduateSlice';
import subjectsRegisterReducer from '../modules/students/subjects-register/slices/subjectsRegisterSlice';
import VirtualClassroomStudentReducer from '../modules/students/virtual-classroom/slices/virtualClassroomSlice';
import evaluationTeacherReducer from '../modules/teachers/virtual-classroom/evaluations/slices/evaluationSlice';
import studentsAsignedReducer from '../modules/teachers/academic-couseling/students-asigned/slices/studentsAsignedSlice';
import registerNivelationReducer from '../modules/maintenance/register-nivelation/slices/registerNivelationSlice';
import academicPortfolioReducer from '../modules/students/academic-portfolio/slices/academicPortfolioSlice';
import requisitionTeacherReducer from '../modules/maintenance/requisition-teacher/slices/requisitionsSlice';
import eventNewReducer from '../modules/maintenance/events-news/slices/eventNewSlice';
import academicCounselingReducer from '../modules/students/academic-counseling/slices/AcademicCounselingSlice';
import generalApplicationReducer from '../modules/maintenance/general-applications/slices/GeneralApplicationsSlice';
import hrApplicationsReducer from '../modules/hr/applications/slices/rrhhApplicationsSlice';
import paymentsReducer from '../modules/students/payments-history/slices/paymentsSlice';
import productReducer from '../modules/maintenance/maintenance-product/slices/productSlice';
import categoryReducer from '../modules/maintenance/maintenance-category/slices/catgorySlice';
// import hourhandsApiReducer from '../modules/students/academic-hourhands/slices/academicHourhandSlice'
import certificatesReducer from '../modules/students/certificates/slices/certificateSlice';
import coursesTeacherReducer from '../modules/teachers/courses/slices/coursesTeacherSlice';
import enabledSubjectsReducer from '../modules/students/subjects-enables/slices/enabledSubjectsSlice';
import teacherHourhandReducer from '../modules/teachers/academic-hourhands/slices/academicHourhandSlice';
import financialConfigurationReducer from '../modules/maintenance/financial-configurations/slices/financialConfigurationSlice';
import configProductReducer from '../modules/maintenance/configuration-product-price/slices/configProductPriceSlice';
import debtsPaymentSlice from '../modules/maintenance/debts-payment/slices/debtsPaymentSlice';
import virtualClassroomStudentSharedSlice from '../modules/students/virtual-classroom/shared/virtualClassroomSharedSlice';
import virtualClassroomTeacherSharedSlice from '../modules/teachers/virtual-classroom/shared/virtualClassroomSharedSlice';
import electronicDocumentsReducer from '../modules/maintenance/electronic-documents/slices/electronicDocumentsSlice';
import clientReducer from '../modules/maintenance/clients/slices/clientSlice';
import financialPeriodsReducer from '../modules/maintenance/financial-periods/slices/financialPeriodsSlice';
import generateDebtsReducer from '../modules/maintenance/generate-debts/slices/generateDebtsSlice';
import virtualClassroomTeacherReducer from '../modules/teachers/virtual-classroom/slices/virtualClassroomTeacherSlice';
import announcementTeacherReducer from '../modules/teachers/virtual-classroom/announcements/slices/announcementTeacherSlice';
import assignGradeReducer from '../modules/teachers/assign-grades/slices/AssignGradeSlice';
import generateCreditNoteReducer from '../modules/maintenance/generate-credit-note/slices/generateCreditNoteSlice';
import cashPointReducer from '../modules/maintenance/close-cash-point/slices/cashPointsSlice';
import depositsTransfersReducer from '../modules/maintenance/deposits-transfers/slices/depositsTransfersSlice';
import payChecksReducer from '../modules/maintenance/paychecks/slices/payChecksSlice';
import learningComponentsReducer from '../modules/maintenance/learning-components/slices/learningComponentsSlice';
import usersConfigurationReducer from '../modules/maintenance/users-configuration/slices/userConfigurationsSlice';
import areasReducer from '../modules/maintenance/areas/slices/areasSlice';
import faqsReducer from '../modules/maintenance/faqs/slices/faqSlice';
import authorsReducer from '../modules/library/authors/slices/authorsSlice';
import booksReducer from '../modules/library/books/slices/booksSlice';
import libraryDashboardReducer from '../modules/library/dashboard/slices/libraryDashboardSlice';
import userReservedBooksReducer from '../modules/library/user-reserved-books/slices/userReservedbooksSlice';
import userBorrowedBooksReducer from '../modules/library/user-borrowed-books/slices/userBorrowedbooksSlice';
import classificationsReducer from '../modules/library/classifications/slices/classificationsSlice';
import libraryReservationsReducer from '../modules/library/reservations/slices/reservationsSlice';
import shelftsReducer from '../modules/library/shelfs/slices/shelfsSlice';
import thesisReducer from '../modules/library/thesis/slices/thesisSlice';
import lendingsReducer from '../modules/library/lendings/slices/lendingsSlice';
import refundsReducer from '../modules/library/refunds/slices/refundsSlice';
import acPlanPostgraduateReducer from '../modules/maintenance/academic-planning-postgraduate/slices/acPlanPostgraduateSlice';
import posgraduatePlanningHourhandsReducer from '../modules/maintenance/academic-planning-postgraduate-hourhands/slices/posgraduatePlanningHourhandsSlice';
import posgraduatePlanningDayTypesReducer from '../modules/maintenance/academic-planning-postgraduate-day-types/slices/posgraduatePlanningDayTypesSlice';
import posgraduatePlanningCohortsReducer from '../modules/maintenance/academic-planning-postgraduate-cohorte/slices/posgraduatePlanningCohortSlice';
import posgraduatePlanningCalendarsReducer from '../modules/maintenance/academic-planning-postgraduate-calendars/slices/posgraduatePlanningCalendarSlice';
import requestsStatusReducer from '../modules/maintenance/requests-status/slices/requestsStatusSlice';
import meetingsTeacherReducer from '../modules/teachers/virtual-classroom/meetings/slices/teacherMeetingsSlice';
import meetingsStudentReducer from '../modules/students/virtual-classroom/meetings/slices/studentMeetingsSlice';
import tasksTeacherReducer from '../modules/teachers/virtual-classroom/tasks/slices/virtualClassroomsTeachersTasksSlice';
import investigationLinesReducer from '../modules/maintenance/lines-of-investigation/slices/investigationLinesSlice';
import followUpOfResearchReducer from '../modules/maintenance/follow-up-of-research-project/slices/followUpOfResearchProjectSlice';
import teacherReducer from '../modules/maintenance/teachers/slices/teachersSlice';
import publicationsReducer from '../modules/maintenance/publications/slices/publicationsSlice';
import tagsReducer from '../modules/library/tags/slices/tagsSlice';
import academicCounselorReducer from '../modules/maintenance/academic-counselors/slices/academicCounselorSlice';
import notificationsReducer from '../modules/maintenance/notifications/slices/notificationsSlice';
import busRouteReducer from '../modules/maintenance/bus-route/slices/busRouteSlice';
import projectsStudentReducer from '../modules/students/projects/slices/projectsSlice';
import { groupsUsersSlice } from '../modules/evaluations/modules/maintenance/groups/slices/groupsUsersSlice';
import { listGroupsSlice } from '../modules/evaluations/modules/maintenance/groups/slices/listGroupsSlice';
import { templateSlice } from '../modules/evaluations/modules/maintenance/templates/slices/evaTemplateSlice';
import { formsSlice } from '../modules/evaluations/modules/maintenance/form/slices/formSlice';
import { academicHourhandSlice } from '../modules/students/academic-hourhands/slices/academicHourhandSlice';
import { scopesSlice } from '../modules/evaluations/modules/maintenance/scope/slices/scopeSlice';
import { launchingSlice } from '../modules/evaluations/modules/maintenance/launching/slices/launchingSlice';
import { heteroevaluationReportsSlice } from '../modules/evaluations/modules/reports/heteroevaluation/slices/heteroevaluationReportsSlice';
import projectsTeacherReducer from '../modules/teachers/projects/slices/teacherProjectsSlice';
import { userEvaluationsSlice } from '../modules/shared/user-evaluations/slices/userEvaluationsSlice';
import { openingDatesSlice } from '../modules/evaluations/modules/maintenance/opening_dates/slices/openingDateSlice';
import clientDiscountReducer from '../modules/maintenance/clients-discount/slices/clientDiscountSlice';
import moduleReportsReducer from '../modules/maintenance/module-reports/slices/moduleReportsSlice';
import contificoMigrationsReducer from '../modules/maintenance/contifico-migrations/slices/contificoMigrationsSlice';
import externalLinkConfigReducer from '../modules/maintenance/external-link-config/slices/externalLinksSlice';

//

// RTK Query - Slices
import { academicOfferingsApi } from '../modules/maintenance/academic-offerings/slices/academicOfferingsApiSlice';
import { academicRequestsApi } from '../modules/students/academic-requests/slices/academicRequestsApiSlice';
import { agreementsApi } from '../modules/maintenance/agreements/slices/agreementsApiSlices';
import { authApi } from '../modules/auth/slices/authApiSlice';
import { calificationModelsApi } from '../modules/maintenance/calification-models/slices/calificationModelsApiSlice';
import { calificationTypesApi } from '../modules/maintenance/califications-types/slices/calificationsTypesApiSlice';
import { campusApi } from '../modules/maintenance/campus/slices/campusApiSlice';
import { catalogApi } from '../modules/shared/slices/catalogApi';
import { searchPersonApi } from '../modules/shared/slices/searchPersonApi';
import { classroomEdLvlApi } from '../modules/maintenance/classrooms-educational-levels/slices/classroomEdLvlApiSlice';
import { classroomsApi } from '../modules/maintenance/classrooms/slices/classroomsApiSlice';
import { collaboratorsApi } from '../modules/hr/collaborators/slices/collaboratorsApiSlice';
import { companiesApi } from '../modules/maintenance/companies/slices/companiesApiSlice';
import { configurationsApi } from '../modules/maintenance/configurations/slices/configurationsApiSlice';
import { courseApi } from '../modules/maintenance/courses/slices/courseApiSlice';
import { departmentsApi } from '../modules/maintenance/departments/slices/departmentsApiSlice';
import { economicGroupsApi } from '../modules/maintenance/economic-groups/slices/economicGroupsApiSlice';
import { educationLevelCareersApi } from '../modules/maintenance/education-level-career/slices/ELCApiSlice';
import { educationLevelsApi } from '../modules/maintenance/education-levels/slices/educationLevelsApiSlice';
import { educationLevelSubjectsApi } from '../modules/maintenance/education-level-subject/slices/ELSApiSlice';
import { groupAreasApi } from '../modules/maintenance/groups-area/slices/groupAreasApiSlice';
import { HomologationsApi } from '../modules/maintenance/homologations/slices/homologationsApiSlice';
import { hourhandsApi } from '../modules/maintenance/hourhands/slices/hourhandsApiSlice';
import { hoursTeachersApi } from '../modules/hr/hours-to-teachers/slice/hoursTeacherApiSlice';
import { institutesApi } from '../modules/maintenance/institutes/slices/institutesApiSlice';
import { mattersApi } from '../modules/maintenance/matters/slices/mattersApiSlice';
import { mattersTypesApi } from '../modules/maintenance/matters-types/slices/mattersTypesApiSlice';
import { meshsApi } from '../modules/maintenance/meshs/slices/meshsApiSlice';
import { parallelsApi } from '../modules/maintenance/parallels/slices/parallelsApiSlice';
import { pensumApi } from '../modules/maintenance/pensums/slices/pensumsApiSlice';
import { periodsApi } from '../modules/maintenance/periods/slices/periodsApiSlice';
import { periodTypesApi } from '../modules/maintenance/periods-types/slices/periodTypesApiSlice';
import { permissionsApi } from '../modules/maintenance/permissions/slices/permissionsApiSlice';
import { positionsApi } from '../modules/maintenance/positions/slices/PositionsApiSlice';
import { profilesApi } from '../modules/maintenance/profiles/slices/profilesApiSlice';
import { rolesApi } from '../modules/maintenance/roles/slices/rolesApiSlice';
import { studentsApi } from '../modules/maintenance/students/slices/studentsApiSlice';
import { subjectsRegisterApi } from '../modules/students/subjects-register/slices/subjectsRegisterApiSlice';
import { registerNivelationApi } from '../modules/maintenance/register-nivelation/slices/registerNivelationApiSlice';
import { academicPortfolioApi } from '../modules/students/academic-portfolio/slices/AcademicPortfolioApiSlice';
import { eventNewApi } from '../modules/maintenance/events-news/slices/eventNewApiSlice';
import { requisitionTeacherApi } from '../modules/maintenance/requisition-teacher/slices/requisitionsApiSlice';
import { trackingNivelationApi } from '../modules/maintenance/tracking-nivelation/slices/TrackingNivelationApiSlice';
import { certificateApi } from '../modules/students/certificates/slices/certificateApiSlice';
import { generalApplicationApi } from '../modules/maintenance/general-applications/slices/GeneralApplicationsApiSlice';
import { paymentsApi } from '../modules/students/payments-history/slices/paymentsApiSlice';
import { enabledSubjectsApi } from '../modules/students/subjects-enables/slices/enabledSubjectsApiSlice';
import { clientsApi } from '../modules/maintenance/clients/slices/clientApiSlice';
import { birthdayApi } from '../modules/shared/slices/birthdayApi';
import { financialConfigurationApi } from '../modules/maintenance/financial-configurations/slices/financialConfigurationApiSlice';
import { configProductsApi } from '../modules/maintenance/configuration-product-price/slices/configProductPriceApiSlice';
// import { EvaluationApi } from '../modules/teachers/virtual-classroom/evaluations/slices/evaluationApiSlice';
import { studentsAsignedApi } from '../modules/teachers/academic-couseling/students-asigned/slices/studentsAsignedApiSlice';
import { electronicDocumentApi } from '../modules/maintenance/electronic-documents/slices/electronicDocumentsApiSlice';
import { virtualClassroomStudent } from '../modules/students/virtual-classroom/slices/virtualClassroomApiSlice';
import { generateDebtsApi } from '../modules/maintenance/generate-debts/slices/generateDebtsApiSlice';
import { debtorPersonApi } from '../modules/shared/slices/debtorPersonApi';
import { financialPeriodsApi } from '../modules/maintenance/financial-periods/slices/financialPeriodsApiSlice';
import { evaluationTeacherApi } from '../modules/teachers/virtual-classroom/evaluations/slices/evaluationApiSlice';
import { virtualClassroomTeacherApi } from '../modules/teachers/virtual-classroom/slices/virtualClassroomTeacherApiSlice';
import { announcementTeacherApi } from '../modules/teachers/virtual-classroom/announcements/slices/announcementTeacherApiSlice';
import { assignGradeApi } from '../modules/teachers/assign-grades/slices/AssignGradeApiSlice';
import { generateCreditNoteApi } from '../modules/maintenance/generate-credit-note/slices/generateCreditNoteApiSlice';
import { cashPointsApi } from '../modules/maintenance/close-cash-point/slices/cashPointsApiSlice';
import { depositsTransfersApi } from '../modules/maintenance/deposits-transfers/slices/depositsTransfersApiSlice';
import { learningComponentsApi } from '../modules/maintenance/learning-components/slices/learningComponentsApiSlice';
import { usersConfigurationApi } from '../modules/maintenance/users-configuration/slices/userConfigurationsApiSlice';
import { receiptApi } from '../modules/maintenance/receipts/slices/receiptApiSlice';
import { areasApi } from '../modules/maintenance/areas/slices/areasApiSlice';
import { faqApi } from '../modules/maintenance/faqs/slices/faqApiSlice';
// import { hourhandApi } from '../modules/students/academic-hourhands/slices/academicHourhandApiSlice';
import { permissionsAccessApi } from '../modules/shared/slices/permissionsApi';
import { uploadFilesApi } from '../modules/shared/slices/uploadFilesApi';
import { acPlanPosgraduateApi } from '../modules/maintenance/academic-planning-postgraduate/slices/acPlanPostgraduateApiSlice';
import { teacherMeetingsApi } from '../modules/teachers/virtual-classroom/meetings/slices/teacherMeetingsApi';
import { studentMeetingsApi } from '../modules/students/virtual-classroom/meetings/slices/studentMeetingsApi';
import { studentsGraduateApi } from '../modules/maintenance/students_graduate/slices/studentsGraduateApiSlice';
import { libraryDashboardApi } from '../modules/library/dashboard/slices/libraryDashboardApiSlice';
import { InvestigationLinesApi } from '../modules/maintenance/lines-of-investigation/slices/InvestigationLinesApiSlice';
import { posgraduatePlanningHourhandsApi } from '../modules/maintenance/academic-planning-postgraduate-hourhands/slices/posgraduatePlanningHourhandsApiSlice';
import { posgraduatePlanningDayTypesApi } from '../modules/maintenance/academic-planning-postgraduate-day-types/slices/posgraduatePlanningDayTypesApiSlice';
import { publicationsApi } from '../modules/maintenance/publications/slices/publicationsApiSlice';
import { posgraduatePlanningCohortsApi } from '../modules/maintenance/academic-planning-postgraduate-cohorte/slices/posgraduatePlanningCohortApiSlice';
import { blackboardApi } from '../modules/maintenance/blackboard/slices/blackboardApiSlice';
import blackboardReducer from '../modules/maintenance/blackboard/slices/blackboardSlice';
import { posgraduatePlanningCalendarsApi } from '../modules/maintenance/academic-planning-postgraduate-calendars/slices/posgraduatePlanningCalendarApiSlice';
import { notificationApi } from '../modules/shared/slices/notificationsApi';
import { busRouteApi } from '../modules/maintenance/bus-route/slices/busRouteApiSlice';
import { academicCounselorApi } from '../modules/maintenance/academic-counselors/slices/academicCounselorApiSlice';
import { projectsStudentApi } from '../modules/students/projects/slices/projectsApiSlice';
import { projectsTeacherApi } from '../modules/teachers/projects/slices/teacherProjectsApiSlice';
import { optionsTemplateSlice } from '../modules/evaluations/modules/maintenance/options_template/slices/optionsTemplateSlice';
import { optionsTemplateApi } from '../modules/evaluations/modules/maintenance/options_template/slices/optionsTemplateApiSlice';
import { evaluationTypeSlice } from '../modules/evaluations/modules/maintenance/evaluation_type/slices/evaluationTypeSlice';
import { evaluationTypeApi } from '../modules/evaluations/modules/maintenance/evaluation_type/slices/evaluationTypeApiSlice';
import { integralEvaluationsSlice } from '../modules/evaluations/modules/maintenance/integral_evaluation/slices/integralEvaluationSlice';
import { integralEvaluationsApi } from '../modules/evaluations/modules/maintenance/integral_evaluation/slices/integralEvaluationApiSlice';
import { questionsSlice } from '../modules/evaluations/modules/maintenance/question/slices/questionSlice';
import { questionsApi } from '../modules/evaluations/modules/maintenance/question/slices/questionApiSlice';
import { evaluationsSlice } from '../modules/evaluations/modules/maintenance/evaluations/slices/evaluationsSlice';
import { evaluationsApi } from '../modules/evaluations/modules/maintenance/evaluations/slices/evaluationsApiSlice';
// import { groupsSlice } from '../modules/evaluations/modules/maintenance/groups/slices/groupsSlice';
// import { groupsApi } from '../modules/evaluations/modules/maintenance/groups/slices/groupsApiSlice';
import { openingDatesApi } from '../modules/evaluations/modules/maintenance/opening_dates/slices/openingDateApiSlice';
import { userEvaluationsApi } from '../modules/shared/user-evaluations/slices/userEvaluationsApiSlice';
import { heteroevaluationReportsApi } from '../modules/evaluations/modules/reports/heteroevaluation/slices/heteroevaluationReportsApiSlice';
import { integralReportsSlice } from '../modules/evaluations/modules/reports/integral/slices/integralReportsSlice';
import { integralReportsApi } from '../modules/evaluations/modules/reports/integral/slices/integralReportsApiSlice';
import { scopesApi } from '../modules/evaluations/modules/maintenance/scope/slices/scopeApiSlice';
import { clientsDiscountApi } from '../modules/maintenance/clients-discount/slices/clientDiscountApiSlice';
import { moduleReportsApi } from '../modules/maintenance/module-reports/slices/moduleReportsApiSlice';
import { syllabusApi } from '../modules/maintenance/syllabus/slices/SyllabusApiSlice';
import SyllabusSlice from '../modules/maintenance/syllabus/slices/SyllabusSlice';
import parameterCompanySlice from '../modules/maintenance/company-parameters/slices/parameterCompanySlice';
import { externalLinksApi } from '../modules/maintenance/external-link-config/slices/externalLinksApiSlice';
import { migrationContificoAPI } from '../modules/maintenance/contifico-migrations/slices/contificoMigrationsApiSlice';

// Root reducer for configureStore
const reducer = {
  // Auth
  [authApi.reducerPath]: authApi.reducer,
  auth: authReducer,

  // Catalog
  [catalogApi.reducerPath]: catalogApi.reducer,

  // Person? by param
  [searchPersonApi.reducerPath]: searchPersonApi.reducer,

  // upload files
  [uploadFilesApi.reducerPath]: uploadFilesApi.reducer,

  //debtor by personId
  [debtorPersonApi.reducerPath]: debtorPersonApi.reducer,

  //permissions by module
  [permissionsAccessApi.reducerPath]: permissionsAccessApi.reducer,

  customization: customizationReducer,
  access: accessReducer,

  // Profiles
  [profilesApi.reducerPath]: profilesApi.reducer,
  profiles: profilesReducer,

  // Paralelos
  [parallelsApi.reducerPath]: parallelsApi.reducer,
  parallels: parallelsReducer,

  //Permissions
  [permissionsApi.reducerPath]: permissionsApi.reducer,
  permissions: permissionsReducer,

  // Period types
  [periodTypesApi.reducerPath]: periodTypesApi.reducer,
  periodTypes: periodTypesReducer,

  // Periods
  periods: periodsReducer,
  [periodsApi.reducerPath]: periodsApi.reducer,

  //Economic Groups
  economicGroups: economicGroupsReducer,
  [economicGroupsApi.reducerPath]: economicGroupsApi.reducer,

  // Classrooms
  classrooms: classroomsReducer,
  [classroomsApi.reducerPath]: classroomsApi.reducer,

  // Institutes
  institutes: institutesReducer,
  [institutesApi.reducerPath]: institutesApi.reducer,

  // Matters Types
  mattersTypes: mattersTypesReducer,
  [mattersTypesApi.reducerPath]: mattersTypesApi.reducer,

  // Education Levels Types
  educationLevels: educationLevelsReducer,
  [educationLevelsApi.reducerPath]: educationLevelsApi.reducer,

  // Academic offerings
  academicOfferings: academicOfferingsSlice,
  [academicOfferingsApi.reducerPath]: academicOfferingsApi.reducer,

  // Matters
  matters: mattersReducer,
  [mattersApi.reducerPath]: mattersApi.reducer,

  // Meshs
  meshs: meshsReducer,
  [meshsApi.reducerPath]: meshsApi.reducer,

  //Pensums
  pensums: pensumsReducer,
  [pensumApi.reducerPath]: pensumApi.reducer,

  //Hourhands
  hourhands: hourhandsReducer,
  [hourhandsApi.reducerPath]: hourhandsApi.reducer,

  // Calification type
  [calificationTypesApi.reducerPath]: calificationTypesApi.reducer,

  // Collaborators
  collaborators: collaboratorsReducer,
  [collaboratorsApi.reducerPath]: collaboratorsApi.reducer,

  //Students
  students: studentsReducer,
  [studentsApi.reducerPath]: studentsApi.reducer,

  // Students Graduate
  studentsGraduate: studentsGraduateReducer,
  [studentsGraduateApi.reducerPath]: studentsGraduateApi.reducer,

  //Campus
  campus: campusReducer,
  [campusApi.reducerPath]: campusApi.reducer,

  //Positions
  positions: positionsReducer,
  [positionsApi.reducerPath]: positionsApi.reducer,

  //Agreements
  agreements: agreementsReducer,
  [agreementsApi.reducerPath]: agreementsApi.reducer,

  //Companies
  companies: companiesReducer,
  [companiesApi.reducerPath]: companiesApi.reducer,

  //Roles
  roles: rolesReducer,
  [rolesApi.reducerPath]: rolesApi.reducer,

  //Departments
  departments: departmentsReducer,
  [departmentsApi.reducerPath]: departmentsApi.reducer,

  //Calification Model
  calificationModel: calificationModelReducer,
  [calificationModelsApi.reducerPath]: calificationModelsApi.reducer,

  //Configurations
  configurations: configurationsReducer,
  [configurationsApi.reducerPath]: configurationsApi.reducer,

  //Education Level Subjects
  educationLevelSubject: educationLevelSubjectsReducer,
  [educationLevelSubjectsApi.reducerPath]: educationLevelSubjectsApi.reducer,

  //classrooms educational levels
  classroomEdLvl: classroomEdLvlReducer,
  [classroomEdLvlApi.reducerPath]: classroomEdLvlApi.reducer,

  //Hours Teacher
  hoursTeachers: hoursTeachersReducer,
  [hoursTeachersApi.reducerPath]: hoursTeachersApi.reducer,
  //Group Areas
  groupAreas: groupAreasReducer,
  [groupAreasApi.reducerPath]: groupAreasApi.reducer,

  // Subjects Register
  subjectsRegister: subjectsRegisterReducer,
  [subjectsRegisterApi.reducerPath]: subjectsRegisterApi.reducer,

  //courses
  courses: coursesReducer,
  [courseApi.reducerPath]: courseApi.reducer,

  //applications
  academicRequests: academicRequestsReducer,
  [academicRequestsApi.reducerPath]: academicRequestsApi.reducer,

  //Education Level Career
  educationLevelCareer: educationLevelCareerReducer,
  [educationLevelCareersApi.reducerPath]: educationLevelCareersApi.reducer,

  //Homologations (match/applications)
  homologations: homologationsReducer,
  [HomologationsApi.reducerPath]: HomologationsApi.reducer,

  //Register Nivelation
  registerNivelation: registerNivelationReducer,
  [registerNivelationApi.reducerPath]: registerNivelationApi.reducer,
  //Academic Portfolio
  [academicPortfolioApi.reducerPath]: academicPortfolioApi.reducer,
  academicPortfolio: academicPortfolioReducer,

  // academicHourhand: hourhandsApiReducer,
  // [hourhandApi.reducerPath] : hourhandApi.reducer,

  //requisition teacher
  requisitionTeacher: requisitionTeacherReducer,
  [requisitionTeacherApi.reducerPath]: requisitionTeacherApi.reducer,

  //certificate
  certificate: certificatesReducer,
  [certificateApi.reducerPath]: certificateApi.reducer,

  eventNew: eventNewReducer,
  [eventNewApi.reducerPath]: eventNewApi.reducer,

  //Tracking Nivelation
  [trackingNivelationApi.reducerPath]: trackingNivelationApi.reducer,

  // Student Academic counseling
  academicCounseling: academicCounselingReducer,

  //general applications
  applications: generalApplicationReducer,
  [generalApplicationApi.reducerPath]: generalApplicationApi.reducer,

  // rrhh applications
  hrApplications: hrApplicationsReducer,

  //student payments/debts
  payments: paymentsReducer,
  [paymentsApi.reducerPath]: paymentsApi.reducer,

  //Teachers Course
  courseTeacher: coursesTeacherReducer,

  //enabled subjects
  enabledSubjects: enabledSubjectsReducer,
  [enabledSubjectsApi.reducerPath]: enabledSubjectsApi.reducer,

  //Maintenance Product
  products: productReducer,

  //Maintenance Category
  categories: categoryReducer,

  //Academic Hourhand Teacher
  hourhandsTeacher: teacherHourhandReducer,

  //Clients
  [clientsApi.reducerPath]: clientsApi.reducer,
  client: clientReducer,

  //birthday
  [birthdayApi.reducerPath]: birthdayApi.reducer,

  //financialConfi
  financialConfigurations: financialConfigurationReducer,
  [financialConfigurationApi.reducerPath]: financialConfigurationApi.reducer,

  // configuration products
  configProducts: configProductReducer,
  [configProductsApi.reducerPath]: configProductsApi.reducer,

  // Asigned students
  studentsAsigned: studentsAsignedReducer,
  [studentsAsignedApi.reducerPath]: studentsAsignedApi.reducer,

  // Virtual classroom evaluation teacher
  evaluationTeacher: evaluationTeacherReducer,
  [evaluationTeacherApi.reducerPath]: evaluationTeacherApi.reducer,

  // Virtual classroom evaluation student
  virtualClassroomStudent: VirtualClassroomStudentReducer,
  [virtualClassroomStudent.reducerPath]: virtualClassroomStudent.reducer,

  // Virtual classroom student shared
  virtualClassroomStudentShared: virtualClassroomStudentSharedSlice,

  //Meetings student virtual classroom
  meetingsStudent: meetingsStudentReducer,
  [studentMeetingsApi.reducerPath]: studentMeetingsApi.reducer,

  // Virtual classroom teacher shared
  virtualClassroomTeacherShared: virtualClassroomTeacherSharedSlice,

  // debts Payment
  debtsPayment: debtsPaymentSlice,

  electronicDocuments: electronicDocumentsReducer,
  [electronicDocumentApi.reducerPath]: electronicDocumentApi.reducer,

  // financial periods
  financialPeriods: financialPeriodsReducer,
  [financialPeriodsApi.reducerPath]: financialPeriodsApi.reducer,

  // generate Debts
  generateDebts: generateDebtsReducer,
  [generateDebtsApi.reducerPath]: generateDebtsApi.reducer,

  //Virtual classroom
  virtualClassroomTeacher: virtualClassroomTeacherReducer,
  [virtualClassroomTeacherApi.reducerPath]: virtualClassroomTeacherApi.reducer,

  //Announcement teacher virtual classroom
  announcementTeacher: announcementTeacherReducer,
  [announcementTeacherApi.reducerPath]: announcementTeacherApi.reducer,

  //Meetings teacher virtual classroom
  meetingsTeacher: meetingsTeacherReducer,
  [teacherMeetingsApi.reducerPath]: teacherMeetingsApi.reducer,

  //Assign Grade
  assignGrade: assignGradeReducer,
  [assignGradeApi.reducerPath]: assignGradeApi.reducer,
  //Generate credit note
  generateCreditNote: generateCreditNoteReducer,
  [generateCreditNoteApi.reducerPath]: generateCreditNoteApi.reducer,

  //close cash points
  cashPoints: cashPointReducer,
  [cashPointsApi.reducerPath]: cashPointsApi.reducer,

  //close cash points
  depositsTransfers: depositsTransfersReducer,
  [depositsTransfersApi.reducerPath]: depositsTransfersApi.reducer,

  //payChecks
  payCheck: payChecksReducer,

  //Learnings components
  learningComponents: learningComponentsReducer,
  [learningComponentsApi.reducerPath]: learningComponentsApi.reducer,

  //User configuration
  usersConfiguration: usersConfigurationReducer,
  [usersConfigurationApi.reducerPath]: usersConfigurationApi.reducer,

  //Areas
  areas: areasReducer,
  [areasApi.reducerPath]: areasApi.reducer,

  faqs: faqsReducer,
  [faqApi.reducerPath]: faqApi.reducer,

  //receipts
  [receiptApi.reducerPath]: receiptApi.reducer,

  //Academic Planning Postgraduate Calendar
  academicPlanningPosgraduate: acPlanPostgraduateReducer,
  [acPlanPosgraduateApi.reducerPath]: acPlanPosgraduateApi.reducer,

  posgraduatePlanningHourhands: posgraduatePlanningHourhandsReducer,
  [posgraduatePlanningHourhandsApi.reducerPath]: posgraduatePlanningHourhandsApi.reducer,

  posgraduatePlanningDayTypes: posgraduatePlanningDayTypesReducer,
  [posgraduatePlanningDayTypesApi.reducerPath]: posgraduatePlanningDayTypesApi.reducer,

  posgraduatePlanningCohorts: posgraduatePlanningCohortsReducer,
  [posgraduatePlanningCohortsApi.reducerPath]: posgraduatePlanningCohortsApi.reducer,

  posgraduatePlanningCalendars: posgraduatePlanningCalendarsReducer,
  [posgraduatePlanningCalendarsApi.reducerPath]: posgraduatePlanningCalendarsApi.reducer,

  // Virtual classroom teacher tasks
  virtualClassroomTeacherTasks: tasksTeacherReducer,

  //Classifications
  classifications: classificationsReducer,

  //Authors
  authors: authorsReducer,

  //Shelfs
  shelfts: shelftsReducer,

  //books
  books: booksReducer,

  //thesis
  thesis: thesisReducer,

  //lends
  lendings: lendingsReducer,

  //returns books
  returns: refundsReducer,

  //request status
  requestsStatus: requestsStatusReducer,

  //request status
  teacher: teacherReducer,

  // library
  libraryDashboard: libraryDashboardReducer,
  [libraryDashboardApi.reducerPath]: libraryDashboardApi.reducer,
  userReservedBooks: userReservedBooksReducer,
  userBorrowedBooks: userBorrowedBooksReducer,

  // library admin
  libraryReservations: libraryReservationsReducer,

  // investigation lines
  investigationLines: investigationLinesReducer,
  followUpOfResearch: followUpOfResearchReducer,
  [InvestigationLinesApi.reducerPath]: InvestigationLinesApi.reducer,

  // publications
  publications: publicationsReducer,
  [publicationsApi.reducerPath]: publicationsApi.reducer,

  // blackboard
  blackboard: blackboardReducer,
  [blackboardApi.reducerPath]: blackboardApi.reducer,

  //tags
  tags: tagsReducer,

  //academic conselour
  academicCounselors: academicCounselorReducer,
  [academicCounselorApi.reducerPath]: academicCounselorApi.reducer,

  //notifications
  notifications: notificationsReducer,
  [notificationApi.reducerPath]: notificationApi.reducer,

  //bus route
  busRoute: busRouteReducer,
  [busRouteApi.reducerPath]: busRouteApi.reducer,

  // Projects student
  projectsStudent: projectsStudentReducer,
  [projectsStudentApi.reducerPath]: projectsStudentApi.reducer,

  // Projects teacher
  projectsTeacher: projectsTeacherReducer,
  [projectsTeacherApi.reducerPath]: projectsTeacherApi.reducer,

  //evaluation
  optionsTemplate: optionsTemplateSlice.reducer,
  [optionsTemplateApi.reducerPath]: optionsTemplateApi.reducer,
  scopes: scopesSlice.reducer,
  [scopesApi.reducerPath]: scopesApi.reducer,
  evaluationType: evaluationTypeSlice.reducer,
  launching: launchingSlice.reducer,

  templateEva: templateSlice.reducer,

  groupsUser: groupsUsersSlice.reducer,

  listGroups: listGroupsSlice.reducer,

  formsEva: formsSlice.reducer,

  [evaluationTypeApi.reducerPath]: evaluationTypeApi.reducer,
  integralEvaluation: integralEvaluationsSlice.reducer,
  [integralEvaluationsApi.reducerPath]: integralEvaluationsApi.reducer,
  questions: questionsSlice.reducer,
  [questionsApi.reducerPath]: questionsApi.reducer,
  evaluations: evaluationsSlice.reducer,
  [evaluationsApi.reducerPath]: evaluationsApi.reducer,
  // groups: groupsSlice.reducer,
  // [groupsApi.reducerPath]: groupsApi.reducer,
  // users: usersSlice.reducer,
  // [usersApi.reducerPath]: usersApi.reducer,
  openingDates: openingDatesSlice.reducer,
  [openingDatesApi.reducerPath]: openingDatesApi.reducer,
  userEvaluations: userEvaluationsSlice.reducer,
  [userEvaluationsApi.reducerPath]: userEvaluationsApi.reducer,
  heteroevaluations: heteroevaluationReportsSlice.reducer,
  [heteroevaluationReportsApi.reducerPath]: heteroevaluationReportsApi.reducer,
  integralReports: integralReportsSlice.reducer,
  [integralReportsApi.reducerPath]: integralReportsApi.reducer,

  //syllabus
  syllabus: SyllabusSlice,
  [syllabusApi.reducerPath]: syllabusApi.reducer,

  //Parameter
  parameterCompany: parameterCompanySlice,

  //
  academicHourhandStudents: academicHourhandSlice.reducer,

  // client discount
  clientsDiscount: clientDiscountReducer,
  [clientsDiscountApi.reducerPath]: clientsDiscountApi.reducer,

  // module Reports
  moduleReports: moduleReportsReducer,
  [moduleReportsApi.reducerPath]: moduleReportsApi.reducer,

  // contifico migrations
  contificoMigrations: contificoMigrationsReducer,

  // external links config
  externalLinks: externalLinkConfigReducer,
  [externalLinksApi.reducerPath]: externalLinksApi.reducer,

  [migrationContificoAPI.reducerPath]: migrationContificoAPI.reducer,
};

export default reducer;
