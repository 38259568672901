import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
  TimelineProps,
  timelineItemClasses,
} from '@material-ui/lab';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import {
  TablerIcon,
  // IconMapPin,
  // IconArrowBigDown,
} from '@tabler/icons';

interface LineRoute {
  tour_name: string;
  icon?: TablerIcon;
  description?: string;
}

interface Props extends TimelineProps {
  listLineRoute: LineRoute[];
}

const TimeLineRoute = ({ listLineRoute, ...props }: Props) => {
  return (
    <>
      <Grid item xs={12}>
        <Typography
          variant="h3"
          color={'secondary'}
          align={props?.position === 'left' ? 'right' : 'left'}
          marginX={3}
          // component="span"
        >
          {props?.position !== 'left' && <ArrowDownwardIcon />}
          {listLineRoute ? listLineRoute[0].tour_name : ''}
          {props?.position === 'left' && <ArrowDownwardIcon />}
        </Typography>
      </Grid>
      <Timeline
        {...(props as any)}
        sx={{
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0,
          },
        }}
      >
        {listLineRoute.map(({ tour_name }, index) => {
          if (index !== 0 && index !== listLineRoute.length - 1)
            return (
              <TimelineItem>
                <TimelineSeparator>
                  {/* {!(index === 0 || index === listLineRoute.length - 1) && <TimelineConnector />} */}
                  <TimelineConnector />
                  <TimelineDot color="primary">
                    <LocationOnIcon />
                  </TimelineDot>
                  {/* {!(index === 0 || index === listLineRoute.length - 1) && <TimelineConnector />} */}
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ py: '30px', px: 2 }}>
                  <Typography variant="h4" component="span">
                    {tour_name}
                  </Typography>
                  {/* <Typography>Because you need strength</Typography> */}
                </TimelineContent>
              </TimelineItem>
            );

          return undefined;
        })}
      </Timeline>
      <Grid item xs={12}>
        <Typography
          variant="h3"
          color={'secondary'}
          align={props?.position === 'left' ? 'right' : 'left'}
          marginX={3}
        >
          {listLineRoute ? listLineRoute.at(-1)?.tour_name : ''}
        </Typography>
      </Grid>
    </>
  );
};

export default TimeLineRoute;
