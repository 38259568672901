// material-ui
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  TextField,
  Button,
  Autocomplete,
  Tooltip,
  Badge,
  Typography,
  // IconButton,
} from '@material-ui/core';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { SerializedError } from '@reduxjs/toolkit';
import {
  IconFileCheck,
  IconFile,
  // IconFileExport
} from '@tabler/icons';
import { EvaluationDataSearch } from '../../interfaces/evaluation-search.interfaces';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { EvaluationData, StudentActivity } from '../../interfaces/evaluation-response.interfaces';
import moment from 'moment';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/useRedux';
import { renderErrorOrEmptyRow, renderSkeletonRows } from '../../../../../shared/helpers/render';
import {
  CustomCellProperties,
  CustomRenderers,
} from '../../../../../shared/interfaces/material-ui.interfaces';
import { useTranslation } from 'react-i18next';
import { ResetTask } from '../../../slices/virtualClassroomTeacherSlice';
import {
  resetInfoEvaluation,
  setEvaluationDeleteModalOpen,
  setEvaluationIdToDelete,
} from '../../slices/evaluationSlice';
import DeleteButton from '../../../../../shared/components/tables/buttons/DeleteButton';
import { virtualClassActivitiesStatus } from '../../../../../shared/constants/resourceStatus';
import { useGetSupplyCourseQuery } from '../../slices/evaluationApiSlice';
import CustomPaginationAndItems from '../../../../../shared/components/tables/CustomPaginationAndItems';

interface Props<T extends {}> {
  data: EvaluationData[];
  customRenderers?: CustomRenderers<T>;
  customDataCellsProperties?: CustomCellProperties<T>;
  customHeadersCellsProperties?: CustomCellProperties<T>;
  isLoading: boolean;
  isFetching: boolean;
  error: FetchBaseQueryError | SerializedError | undefined;
  total: number;
  perPage: number;
  setPerPage: React.Dispatch<React.SetStateAction<number>>;
  page: number;
  numHeader: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  replaceHeader?: boolean;
  selectedCount?: number;
  newHeader?: JSX.Element;
  dataSearch: EvaluationDataSearch;
  setDataSearch: React.Dispatch<React.SetStateAction<EvaluationDataSearch>>;

  setSupply: React.Dispatch<React.SetStateAction<number | null>>;
  setStatus: React.Dispatch<React.SetStateAction<string | null>>;
}

const EvaluationsListTable = <T extends {}>({
  data,
  customRenderers,
  numHeader,
  isLoading,
  isFetching,
  error,
  perPage,
  setPerPage,
  total,
  page,
  setPage,
  replaceHeader,
  dataSearch,
  setDataSearch,
  newHeader,

  setSupply,
  setStatus,
}: Props<T>) => {
  const { path } = useRouteMatch();

  const history = useHistory();

  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  // const [courseId, setCourseId] = useState<number>(dataSearch.courseId || 0);

  const {
    course: { courseId },
    isFinishedCourse,
  } = useAppSelector((state) => state.virtualClassroomTeacherShared);

  const handleCreateEvaluation = () => {
    dispatch(resetInfoEvaluation());
    dispatch(ResetTask());
    history.push(`${path}/crear`);
  };

  const handleDetailEvaluation = (evaluationId: number, courseId: number) => {
    history.push(`${path}/detalle/${evaluationId}/curso/${courseId}`);
  };

  const obtainRelativeTimeToFinish = (date: string): string => {
    const isAferDate = moment().isAfter(date);
    if (isAferDate) {
      return 'Finalizado';
    }
    return moment(date).fromNow();
  };

  /**
   * Obtener estudiantes a calificar por cada evaluación.
   * Si la cabecera del estudiante es nula, éste no ha iniciado
   * evaluación.
   * En el caso de que un estudiante tenga al menos una pregunta por
   * calificar es considerado como un estudiante por calificar.
   */
  const obtainStudentsToQualify = (students: StudentActivity[], max_questions: string): number => {
    const quantity = students.reduce<number>(
      (acc, el) =>
        el?.cab_activity_student?.answer_qualified === max_questions ||
        el?.cab_activity_student === null
          ? acc
          : acc + 1,
      0
    );
    return quantity;
  };

  const { data: supplies, isLoading: isLoadingSupp } = useGetSupplyCourseQuery({
    course: courseId!,
  });

  const handleDelete = (evaluationId: number) => {
    dispatch(setEvaluationIdToDelete(evaluationId));
    dispatch(setEvaluationDeleteModalOpen(true));
  };

  const RenderRow = (props: { p: EvaluationData; index: number }) => {
    const { p, index } = props;

    return (
      <TableRow className="table-row">
        <TableCell align="center" width="10%">
          {index + 1 + (page - 1) * perPage}
        </TableCell>
        <TableCell align="center" width="30%">
          {p?.act_description!}
        </TableCell>
        <TableCell align="center" width="10%">
          {p?.act_num_tries}
        </TableCell>
        <TableCell align="center" width="30%">
          {moment(p?.course_activity_one?.cact_start_date!).format('YYYY-MM-DD HH:mm')}
        </TableCell>
        <TableCell align="center" width="30%">
          {obtainRelativeTimeToFinish(p?.course_activity_one?.cact_end_date!)}
        </TableCell>
        <TableCell align="center" width="30%">
          {isFinishedCourse && (
            <Tooltip title={t('teacherVirtualClassroom.tasks.table.calficar')}>
              <Button
                // disabled={new Date(p.course_activity_one.cact_end_date).getTime() > Date.now()}
                onClick={() =>
                  handleDetailEvaluation(p?.id!, Number(p?.course_activity_one?.course_id!))
                }
                startIcon={
                  <>
                    <Badge
                      badgeContent={obtainStudentsToQualify(
                        p?.course_activity_one?.student_activities!,
                        p?.act_num_max_questions
                      )}
                      color="primary"
                    >
                      <IconFile />
                    </Badge>
                  </>
                }
              ></Button>
            </Tooltip>
          )}
        </TableCell>
        <TableCell align="center" width="30%">
          {/* <Tooltip
            onClick={() => handleCopyEvaluation(p?.id!, Number(p?.course_activity_one?.course_id!))}
            title={t('teacherVirtualClassroom.tests.listItemTooltip')}
          >
            <IconButton color="primary">
              <IconFileExport fontSize="0.875rem" />
            </IconButton>
          </Tooltip> */}
          <DeleteButton handleDelete={() => handleDelete(p?.id!)} />
        </TableCell>
      </TableRow>
    );
  };

  return (
    <>
      <Grid
        container
        style={{ marginBottom: 20, marginTop: 10 }}
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
      >
        <Grid item container spacing={1}>
          <Grid item xs={4} md={3}>
            <Typography fontWeight="bold" color={'primary'} variant="body1">
              {t('teacherVirtualClassroom.tasks.table.input.title.insumo')}
            </Typography>
            <Autocomplete
              // style={{ width: '60%' }}
              options={supplies || []}
              loading={isLoadingSupp}
              loadingText={t('teacherVirtualClassroom.tests.filterparcialLoading')}
              getOptionLabel={(option) => option.name}
              onChange={(_, value) => {
                setSupply(value?.id || null);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('teacherVirtualClassroom.tasks.table.input.title.insumo.select')}
                  fullWidth
                />
              )}
              fullWidth
            />
          </Grid>

          <Grid item xs={4} md={3}>
            <Typography fontWeight="bold" color={'primary'} variant="body1">
              {t('teacherVirtualClassroom.tasks.table.input.title.status')}
            </Typography>
            <Autocomplete
              // style={{ width: '60%' }}
              options={virtualClassActivitiesStatus}
              loadingText={t('teacherVirtualClassroom.tests.filterparcialLoading')}
              getOptionLabel={(option) => t(option.label)}
              onChange={(_, value) => {
                setStatus(value?.name || null);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('teacherVirtualClassroom.tasks.table.input.title.status.select')}
                  fullWidth
                />
              )}
              fullWidth
            />
          </Grid>

          {/* Action button filter*/}
          <Grid
            item
            style={{ display: 'flex' }}
            spacing={3}
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            xs={12}
            // sm={3}
          >
            <Button
              onClick={handleCreateEvaluation}
              style={{ textTransform: 'unset', marginLeft: '15px', padding: '8px 15px' }}
              size="small"
              variant="contained"
              disabled={isFinishedCourse}
              startIcon={<IconFileCheck fontSize="0.875rem" />}
            >
              {t('teacherVirtualClassroom.tests.createTest')}
            </Button>
          </Grid>
        </Grid>
      </Grid>

      {/* Container Search */}

      {/* Table primitive */}
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">N°</TableCell>
              <TableCell align="center">
                {t('teacherVirtualClassroom.tests.listHeaderDescription')}
              </TableCell>
              <TableCell align="center">
                {t('teacherVirtualClassroom.tests.listHeaderAttempts')}
              </TableCell>
              <TableCell align="center">
                {t('teacherVirtualClassroom.tests.listHeaderCreationDate')}
              </TableCell>
              <TableCell align="center">
                {t('teacherVirtualClassroom.tests.listHeaderEndDate')}
              </TableCell>
              <TableCell align="center">
                {t('teacherVirtualClassroom.tests.listHeaderQualify')}
              </TableCell>
              <TableCell align="center">
                {t('teacherVirtualClassroom.tests.listHeaderOptions')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isFetching
              ? renderSkeletonRows(perPage, 7)
              : data?.map((row, index) => <RenderRow key={row?.id} p={row} index={index} />)}

            {error && renderErrorOrEmptyRow(7, error as string)}
            {data?.length === 0 && renderErrorOrEmptyRow(7, t('shared.table.empty'))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* End table primitive */}

      <CustomPaginationAndItems
        error={error}
        isLoading={isLoading}
        total={total}
        perPage={perPage}
        page={page}
        setPage={setPage}
        setPerPage={setPerPage}
        perPageBox
        paginationBox
      />
    </>
  );
};

export default EvaluationsListTable;
