export const permissionKey = 'permissions';

export interface SubPermissionKeys {
  read: string;
  create: string;
  edit: string;
  delete: string;
}

export const subPermissionKeys: SubPermissionKeys = {
  read: 'permissions-listar-permisos',
  create: 'permissions-crear-permiso',
  edit: 'permissions-actualizar-permiso',
  delete: 'permissions-borrar-permiso',
};
