import { Switch, Redirect } from 'react-router-dom';

import { useAppSelector } from '../../../../hooks/useRedux';

import GroupAreasScreen from '../screens/GroupAreasScreen';
import AddGroupAreaScreen from '../screens/AddGroupAreaScreen';
import EditGroupAreaScreen from '../screens/EditGroupAreaScreen';
import GuardRoute from '../../../../router/guards/GuardRoute';

const GroupAreasRouter = () => {
  const { currentModule } = useAppSelector((state) => state.access);

  const hasPermission = 'areaGroup' in currentModule?.permissions!;

  if (!hasPermission) return <Redirect to="/dashboard" />;

  return (
    <Switch>
      <GuardRoute exact path="/administrar-grupo-de-areas" component={GroupAreasScreen} />
      <GuardRoute exact path="/administrar-grupo-de-areas/agregar" component={AddGroupAreaScreen} />
      <GuardRoute
        exact
        path="/administrar-grupo-de-areas/:groupAreaId/editar"
        component={EditGroupAreaScreen}
      />
    </Switch>
  );
};

export default GroupAreasRouter;
