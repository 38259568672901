import React from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const CustomTabPanel = ({
  children,
  value,
  index,
  keepmounted = false,
  ...other
}: {
  children: React.ReactElement | string;
  value: number;
  index: number;
  keepmounted?: boolean;
}) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {(value === index || keepmounted) && (
        <Box p={3} hidden={keepmounted && value !== index }>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

export default CustomTabPanel;
