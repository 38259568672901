import { useState } from 'react';
import {
  TableBody,
  TableContainer,
  Table,
  CircularProgress,
  Grid,
  TableCell,
  TableRow,
  Typography,
  Button,
} from '@material-ui/core';
import { StudentCalifications } from '../../interfaces/studentCalification.interfaces';
import HeaderTable from './HeaderTable';
import CustomRow from './CustomRow';
import { getCol, getRows, getStudentGrades, useColumns } from '../helpers/dataGridHelper';
import { withStyles } from '@material-ui/core/styles';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import SubCard from '../../../../shared/components/cards/SubCard';
import {
  useGetCourseStudentCalificationsQuery,
  useGetDistributionCalificationQuery,
} from '../../slices/virtualClassroomTeacherApiSlice';

import { useGetCourseStudentsQuery } from '../../../courses/slices/coursesTeacherApiSlice';

import ModalSettings from './ModalSettings/ModalSettings';
import ErrorAlert from '../../../../shared/components/alerts/ErrorAlert';
import {
  setCalificationsAlertOpen,
  setCalificationsError,
  setImportCalificationsMoodleDialogOpen,
} from '../../slices/virtualClassroomTeacherSlice';
import InfoDialog from './ModalSettings/InfoDialog';

export interface DataStudent {
  id: number;
  name: string;
  grade?: StudentCalifications[];
}

export const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: 'transparent',
    color: theme.palette.dark + ' !important',
    border: `1px solid ${theme.palette.common.white}`,
    '& .css-b1hioo-MuiTableCell-root': {
      color: theme.palette.dark + ' !important',
    },
    '& :hover': {
      backgroundColor: 'blue',
    },
  },
  body: {
    fontSize: 14,
    color: theme.palette.dark + ' !important',
    '& .css-b1hioo-MuiTableCell-root': {
      color: theme.palette.dark + ' !important',
    },

    // border: `1px solid ${theme.palette.common.black}`,
  },
}))(TableCell);

export const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      color: theme.palette.dark + ' !important',
      backgroundColor: 'white',
    },
    '& .css-b1hioo-MuiTableCell-root': {
      color: theme.palette.dark + ' !important',
    },
  },
}))(TableRow);

const CalificationstTable = () => {
  const dispatch = useAppDispatch();
  const {
    course: { courseId, vcType },
  } = useAppSelector((state) => state.virtualClassroomTeacherShared);

  const { data: distribution, isLoading: isLoadingDistributions } =
    useGetDistributionCalificationQuery(
      {
        courseId,
      },
      { refetchOnMountOrArgChange: 30, skip: !courseId }
    );

  const { data: grades, isLoading: isLoadingGrades } = useGetCourseStudentCalificationsQuery(
    {
      courseId,
    },
    { refetchOnMountOrArgChange: 30, skip: !courseId }
  );

  const { data: students, isLoading: isLoadingStudents } = useGetCourseStudentsQuery(
    {
      courseId,
      payload: { data: 'all' },
    },
    { refetchOnMountOrArgChange: 30, skip: !courseId }
  );

  const { columns, distributions } = useColumns(distribution);
  const listGrades = getStudentGrades(students, grades);

  const { configurateInsumo, calificationsError, calificationsAlertOpen } = useAppSelector(
    (state) => state.virtualClassroomTeacher
  );

  const rows = getRows(columns, listGrades);

  const r = getCol(rows, columns);

  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const handleSetOpenDialog = () => {
    setOpenDialog(!openDialog);
  };

  const handleImportMoodle = () => {
    dispatch(setImportCalificationsMoodleDialogOpen(true));
  };

  return (
    <>
      <SubCard
        title={
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography variant="h2">Ingresar calificaciones</Typography>
            </Grid>
            {vcType !== 'native' && (
              <Grid item>
                <Button variant="contained" color="primary" onClick={handleImportMoodle}>
                  Importar calificaciones
                </Button>
              </Grid>
            )}
          </Grid>
        }
        sx={{
          borderTopLeftRadius: '0px',
          borderTopRightRadius: '0px',
        }}
        darkTitle
      >
        {configurateInsumo?.modal! && <ModalSettings open={configurateInsumo?.modal!} />}
        {calificationsError && calificationsAlertOpen && (
          <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12}>
              <ErrorAlert
                message={calificationsError}
                handleDismiss={() => {
                  dispatch(setCalificationsError(''));
                  dispatch(setCalificationsAlertOpen(false));
                }}
              />
            </Grid>
          </Grid>
        )}
        {!isLoadingDistributions && !isLoadingGrades && !isLoadingStudents ? (
          <TableContainer>
            <Table>
              <HeaderTable distribution={distribution} />
              <TableBody>
                <CustomRow columns={columns} row={r} distributions={distributions} />
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Grid container item justifyContent="center" alignItems="center">
            <CircularProgress />
          </Grid>
        )}
      </SubCard>
      <InfoDialog open={openDialog} handleCloseDialog={handleSetOpenDialog} />
    </>
  );
};

export default CalificationstTable;
