import { Switch } from 'react-router-dom';
import GuardRoute from '../../../../router/guards/GuardRoute';
import SubjectsEnablesScreen from '../screens/SubjectsEnablesScreen';

const SubjectsEnablesRouter = () => {
  return (
    <Switch>
      <GuardRoute exact path="/asignaturas-habilitadas" component={SubjectsEnablesScreen} />
    </Switch>
  );
};

export default SubjectsEnablesRouter;
