import React, { useEffect, useState } from 'react';

import { Autocomplete, FormHelperText, InputLabel, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../../../hooks/useRedux';
import { useGetStudentPeriodsQuery } from '../../slices/academicRequestsApiSlice';
import TextFieldFetching from '../../../../shared/components/TextFieldFetching';
import { StudentsPeriods } from '../../interfaces/academicRequests.interfaces';

interface Props {
  changeHandler: React.Dispatch<React.SetStateAction<number>>;
  sideFunc?: () => void;
  isError?: boolean;
  error?: string;
  reset?: boolean;
}

const PeriodStudentSelect = ({
  isError = false,
  error = '',
  changeHandler,
  sideFunc,
  reset = false,
}: Props) => {
  const { currentRecordStudent } = useAppSelector((state) => state.access);

  const [selected, setSelected] = useState<StudentsPeriods | null>(null);

  const [periodReverse, setPeriodReverse] = useState<StudentsPeriods[]>([]);

  const { t } = useTranslation();

  const { data, isLoading } = useGetStudentPeriodsQuery(
    {
      studentRecordId: currentRecordStudent?.id || 0,
    },
    {
      skip: !currentRecordStudent,
    }
  );

  useEffect(() => {
    if (data && data.length > 0) {
      setPeriodReverse([...data].reverse());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (reset) {
      setSelected(null);
    }
  }, [reset]);

  return (
    <>
      <InputLabel>{t('students.applications.student_period_label')}</InputLabel>
      {isLoading ? (
        <TextFieldFetching />
      ) : (
        <>
          <Autocomplete
            options={periodReverse || []}
            loading={isLoading}
            loadingText="Cargando parcial..."
            noOptionsText="No hay períodos disponibles"
            groupBy={(option) => option.per_current_year}
            getOptionLabel={(option) => option.per_name}
            renderInput={(params) => (
              <TextField
                {...params}
                error={isError}
                placeholder={t('students.applications.student_period_placeholder')}
              />
            )}
            onChange={(_, value) => {
              changeHandler(value?.id || 0);
              setSelected(value || null);
              if (sideFunc) sideFunc();
            }}
            value={periodReverse?.find((period) => period.id === (selected?.id || 0)) || null}
            fullWidth
            clearIcon={null}
            defaultValue={data && data.length === 1 ? data[0] : null}
          />
          {selected && selected?.start_process && selected?.end_process && (
            <FormHelperText>{`Inicio: ${selected?.start_process} - Fin: ${selected?.end_process}`}</FormHelperText>
          )}
        </>
      )}

      {isError && <FormHelperText error>{error}</FormHelperText>}
    </>
  );
};

export default PeriodStudentSelect;
