import { Dialog, DialogContent, DialogTitle, Typography, Grid, Button } from '@material-ui/core';
import { RemoveRedEye } from '@material-ui/icons';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useGetUrlDownload from '../../../../../../hooks/useGetUrlDownload';
import { useAppSelector, useAppDispatch } from '../../../../../../hooks/useRedux';
import { useGetFormByIdQuery } from '../slices/formsApiSlice';
import { setFormIdSelected, setIsOpenDocumentDialog } from '../slices/formSlice';
import { ModalDocuments } from './ModalDocuments';

const ViewDocumentsDialog = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { formIdSelected } = useAppSelector((state) => state.formsEva);

  const [showDocument, setShowDocument] = useState(false);

  const [documentData, setDocumentData] = useState({
    url: '',
    name: '',
  });

  const { urlDownload } = useGetUrlDownload();

  const { data: formById } = useGetFormByIdQuery({
    id: formIdSelected!,
  });

  return (
    <>
      <Dialog
        open
        maxWidth="sm"
        fullWidth
        onClose={() => {
          dispatch(setIsOpenDocumentDialog(false));
          dispatch(setFormIdSelected(null));
        }}
      >
        <DialogTitle>
          <Typography variant="h3">{t('Documentos')} </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid
            item
            xs={12}
            sx={{
              mx: 'auto',
            }}
          >
            {Array.isArray(formById?.eva_formulary_documents) && (
              <Grid
                item
                container
                xs={12}
                sx={{
                  mb: 4,
                }}
              >
                {formById?.eva_formulary_documents.map((item) => (
                  <Grid item xs={6} key={item.id}>
                    <Button
                      startIcon={<RemoveRedEye />}
                      onClick={() => {
                        setShowDocument(true);
                        setDocumentData({
                          url: `${urlDownload}/${item?.eva_formulary_document_url}#toolbar=0`,
                          name: item?.eva_formulary_type_document.eva_form_tip_doc_name,
                        });
                      }}
                    >
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{
                          wordWrap: 'break-word',
                          width: '150px',
                        }}
                      >
                        {item?.eva_formulary_type_document?.eva_form_tip_doc_name}
                      </Typography>
                    </Button>
                  </Grid>
                ))}
              </Grid>
            )}
          </Grid>
        </DialogContent>
      </Dialog>
      {showDocument && (
        <ModalDocuments
          open={showDocument}
          handleClose={() => setShowDocument(false)}
          urlFile={documentData.url}
          title={documentData.name}
        />
      )}
    </>
  );
};

export default ViewDocumentsDialog;
