import { Switch } from 'react-router-dom';
import GuardRoute from '../../../../router/guards/GuardRoute';
import ProjectScreen from '../screens/ProjectScreen';
import TasksScreen from '../screens/TasksScreen';

const ProjectsTeachertRouter = () => {
  return (
    <Switch>
      <GuardRoute exact path="/proyectos-docente" component={ProjectScreen} />
      <GuardRoute exact path="/proyectos-docente/:projectId/tareas" component={TasksScreen} />
    </Switch>
  );
};

export default ProjectsTeachertRouter;
