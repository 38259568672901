import { Grid, makeStyles, Slide, SlideProps, Typography } from '@material-ui/core';
import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import ExtendedSideDialog from '../../../shared/components/dialogs/ExtendedSideDialog';
import AddEducationLevelForm from '../../education-levels/components/AddEducationLevelForm';
import { setAddEducationLevelDialogOpen } from '../slices/meshSlice';

const Transition = React.forwardRef(function Transition(props: SlideProps, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  userAddDialog: {
    '&>div:nth-child(3)': {
      justifyContent: 'flex-end',
      '&>div': {
        margin: '0px',
        borderRadius: '0px',
        maxWidth: '450px',
        maxHeight: '100%',
      },
    },
  },
  paper: {
    minHeight: '100vh',
    width: '100%',
  },
  learningComponents: {
    fontSize: '16px',
    fontWeight: 600,
    color: theme.palette.primary.main,
    marginBottom: '8px',
  },
  selectSubject: {
    fontSize: '16px',
    fontWeight: 600,
    color: theme.palette.primary[800],
    marginBottom: '8px',
  },
  selectSubjectType: {
    fontSize: '14px',
    fontWeight: 600,
    color: theme.palette.secondary.main,
    marginBottom: '8px',
  },
}));

const AddEducationLevelDialog = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const { addEducationLevelDialogOpen } = useAppSelector((state) => state.meshs);

  const handleCloseDialog = () => {
    dispatch(setAddEducationLevelDialogOpen(false));
  };

  return (
    <ExtendedSideDialog
      open={addEducationLevelDialogOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCloseDialog}
      className={classes.userAddDialog}
      classes={{
        paper: classes.paper,
      }}
    >
      <Grid container p={2}>
        <Grid item xs={12}>
          <Typography variant="h4">Agregar unidad académica</Typography>
        </Grid>
        <Grid item xs={12}>
          <AddEducationLevelForm handleClose={handleCloseDialog} />
        </Grid>
      </Grid>
    </ExtendedSideDialog>
  );
};

export default AddEducationLevelDialog;
