import { Switch, Redirect } from 'react-router-dom';
import { useAppSelector } from '../../../../hooks/useRedux';

import GuardRoute from '../../../../router/guards/GuardRoute';
import CompaniesScreen from '../screens/CompaniesScreen';
import EditCompaniesScreen from '../screens/EditCompaniesScreen';
import EditFinanceScreen from '../screens/EditFinanceScreen';
import EditSocialScreen from '../screens/EditSocialScreen';

import { permissionKey, subPermissionKeys } from '../constants/permissions';
import EditMisionVisionScreen from '../screens/EditMisionVisionScreen';
import AddServiceCompanyScreen from '../screens/AddServiceCompanyScreen';
import EditServiceCompanyScreen from '../screens/EditServiceCompanyScreen';

const CompaniesRouter = () => {
  const { currentModule } = useAppSelector((state) => state.access);

  const hasPermission = permissionKey in currentModule?.permissions!;

  if (!hasPermission) return <Redirect to="/dashboard" />;
  return (
    <Switch>
      <GuardRoute
        exact
        path="/administrar-companias"
        component={CompaniesScreen}
        subPermissionKey={subPermissionKeys.read}
        permissionKey={permissionKey}
      />
      <GuardRoute
        exact
        path="/administrar-companias/editar-principal"
        component={EditCompaniesScreen}
        subPermissionKey={subPermissionKeys.edit}
        permissionKey={permissionKey}
      />
      <GuardRoute
        exact
        path="/administrar-companias/editar-financiero"
        component={EditFinanceScreen}
        subPermissionKey={subPermissionKeys.edit}
        permissionKey={permissionKey}
      />
      <GuardRoute
        exact
        path="/administrar-companias/editar-social"
        component={EditSocialScreen}
        subPermissionKey={subPermissionKeys.edit}
        permissionKey={permissionKey}
      />
      <GuardRoute
        exact
        path="/administrar-companias/editar-mision-vision"
        component={EditMisionVisionScreen}
        subPermissionKey={subPermissionKeys.edit}
        permissionKey={permissionKey}
      />
      <GuardRoute
        exact
        path="/administrar-companias/agregar-servicio"
        component={AddServiceCompanyScreen}
      />
      <GuardRoute
        exact
        path="/administrar-companias/:serviceId/editar"
        component={EditServiceCompanyScreen}
      />
    </Switch>
  );
};

export default CompaniesRouter;
