import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISearchTable } from '../../../shared/interfaces/paginatedTable.interface';

interface HRApllicationSliceState {
  hrTable: ISearchTable;
}

const initialState: HRApllicationSliceState = {
  hrTable: {
    page: 1,
    perPage: 5,
    search: '',
  },
};

const busRouteSlice = createSlice({
  name: 'hrApplicationsTable',
  initialState,
  reducers: {
    setHRTable: (state, action: PayloadAction<ISearchTable>) => {
      state.hrTable = action.payload;
    },
  },
});

export const { setHRTable } = busRouteSlice.actions;

export default busRouteSlice.reducer;
