import { FC, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import {
  Grid,
  Typography,
  TextField,
  Button,
  FormHelperText,
  useTheme,
  CircularProgress,
  TableRow,
  TableCell,
  TableContainer,
  Table,
  TableBody,
  Tooltip,
  Link,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { IconEye } from '@tabler/icons';

import { DetailsProps } from '../../constants/types';
import InputLabel from '../../../../shared/components/forms/InputLabel';
import { openRejectAppModalWith } from '../../slices/GeneralApplicationsSlice';
import { ChangeStatusApp } from '../../constants/validation-schemas';
import { useAppSelector } from '../../../../../hooks/useRedux';
import {
  OthersApplications as OthersApplicationsInt,
  OthersDescription,
} from '../../interfaces/generalApplications.interfaces';
import { useUpdateOthersApplicationMutation } from '../../slices/GeneralApplicationsApiSlice';
import { renderErrorOrEmptyRow } from '../../../../shared/helpers/render';
import { firstLetterUppercase } from '../../../../shared/helpers';
import { baseUrl } from '../../../../../api/mainApi';
import { isAppChangeStatusAvailable } from '../../helpers';
import { subPermissionKeys } from '../../constants/permissions';
import useSharedGuard from '../../../../../hooks/useSharedGuard';

const OthersApplications: FC<DetailsProps> = (props) => {
  const {
    details: detailsProp,
    spacing,
    appCode,
    appId,
    appDescription,
    currentStatus,
    nextStatus,
    updateAlertHandler,
    updateStatusHandler,
  } = props;
  const details = detailsProp as OthersApplicationsInt;

  const isNotAvailable = !isAppChangeStatusAvailable(currentStatus);

  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const { hasAccess } = useSharedGuard(subPermissionKeys);

  const { currentProfile } = useAppSelector((state) => state.access);

  const [
    updateApplication,
    {
      isLoading: isUpdating,
      error: updateError,
      isError: isErrorUpdate,
      isSuccess: isSuccessUpdate,
    },
  ] = useUpdateOthersApplicationMutation();

  const { handleSubmit, getFieldProps, errors, touched, validateField, values } = useFormik({
    initialValues: {
      comment: '',
    },
    validationSchema: ChangeStatusApp,
    onSubmit: async (values) => {
      await updateApplication({
        profileId: currentProfile?.profileId!,
        ApplicationId: appId,
        applicationPayload: {
          app_code: appCode,
          comment: values.comment,
        },
      });
      updateAlertHandler(true);
    },
  });

  useEffect(() => {
    if (isErrorUpdate) updateStatusHandler({ state: 'error', msg: updateError as string });
    if (isSuccessUpdate)
      updateStatusHandler({ state: 'success', msg: 'La solicitud se ha aprobado' });
  }, [isErrorUpdate, updateStatusHandler, updateError, isSuccessUpdate]);

  const handleDelete = (data: { appId: number; code: string; msg: string }) => {
    dispatch(openRejectAppModalWith(data));
  };

  const renderRow = (evidence: OthersDescription, i: number) => (
    <TableRow hover className="table-row" key={`${evidence.description}-${i}`}>
      <TableCell
        width="70%"
        // sx={{ cursor: 'pointer' }}
        onClick={() => {
          // handleSelectRole(rol.id);
        }}
      >
        {evidence.description}
        {/* <FormHelperText>{evidence.description}</FormHelperText> */}
      </TableCell>
      <TableCell
        width="30%"
        align="center"
        // sx={{ borderLeft: 1, borderColor: 'grey.200', paddingX: '10px' }}
      >
        <Tooltip title="Ver evidencia">
          <Link
            href={`${baseUrl}/show?name=${evidence.evidence_url}`}
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Ver evidencia"
            color="primary"
          >
            <IconEye size={20} />
          </Link>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
  const renderRows = () => {
    return <>{details[3].value?.map(renderRow)}</>;
  };

  return (
    <Grid container item xs={12}>
      <form onSubmit={handleSubmit}>
        <Grid item mb={1}>
          <InputLabel>{firstLetterUppercase(appDescription)}</InputLabel>
        </Grid>
        <Grid container border="1px solid #eee" borderRadius="12px" p={2} mb={1}>
          <Grid container item xs={12} alignItems={'center'} mb={1}>
            <Grid item xs={'auto'}>
              <Typography variant={'body2'} component={'span'}>
                Descripción:
              </Typography>
            </Grid>

            <Grid item xs={'auto'} lg={6} sx={{ pl: spacing }}>
              <Typography variant={'h5'} component={'span'}>
                {details[1].value}
              </Typography>
            </Grid>
          </Grid>

          <Grid container item xs={6} alignItems={'center'} mb={1}>
            <Grid item xs={'auto'}>
              <Typography variant={'body2'} component={'span'}>
                Tipo de solicitud:
              </Typography>
            </Grid>

            <Grid item xs={'auto'} lg={6} sx={{ pl: spacing }}>
              <Typography variant={'h5'} component={'span'}>
                {details[0].value}
              </Typography>
            </Grid>
          </Grid>

          {details[2].value && (
            <Grid container item xs={6} alignItems={'center'} mb={1}>
              <Grid item xs={'auto'}>
                <Typography variant={'body2'} component={'span'}>
                  Certificado:
                </Typography>
              </Grid>

              <Grid item xs={'auto'} lg={6} sx={{ pl: spacing }}>
                <Typography variant={'h5'} component={'span'}>
                  <Link
                    href={`${baseUrl}/show?name=${details[2].value as string}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Ver
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          )}

          {/* {((currentRol?.keyword === 'decano' &&
          currentApplication!.current_status.toLowerCase() === 'enviado') ||
          (currentRol?.keyword === 'coordinador-facultad' &&
            (currentApplication!.current_status.toLowerCase() === 'revisado por decanato' ||
              currentApplication!.current_status.toLowerCase() ===
                'revisado por consejo facultad'))) &&
          !isSuccess && ( */}

          <Grid container mt={'1rem'}>
            <Typography variant={'h4'} component={'span'} color="primary">
              Lista de evidencias
            </Typography>
            <Grid item xs={12} sx={{ maxHeight: 600, overflow: 'hidden' }}>
              <PerfectScrollbar component="div">
                <TableContainer>
                  <Table>
                    <TableBody>
                      {!details[3].value?.length ? renderErrorOrEmptyRow(5) : renderRows()}

                      {/* {!details[3].value.length && renderErrorOrEmptyRow(5)} */}
                    </TableBody>
                  </Table>
                </TableContainer>
              </PerfectScrollbar>
            </Grid>
          </Grid>
        </Grid>
        {/* )} */}

        {hasAccess('changeApplicationStatus') && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputLabel>Comentario</InputLabel>
              <TextField
                fullWidth
                multiline
                rows={4}
                error={Boolean(errors.comment && touched.comment)}
                placeholder="Ingrese el comentario"
                {...getFieldProps('comment')}
                disabled={isNotAvailable}
              />
              {errors.comment && touched.comment && (
                <FormHelperText error>{errors.comment}</FormHelperText>
              )}
            </Grid>

            {/* {((currentRol?.keyword === 'decano' &&
            currentApplication!.current_status.toLowerCase() === 'enviado') ||
            (currentRol?.keyword === 'coordinador-facultad' &&
              (currentApplication!.current_status.toLowerCase() === 'revisado por decanato' ||
                currentApplication!.current_status.toLowerCase() ===
                  'revisado por consejo facultad'))) &&
            !isSuccess && ( */}
            <Grid container justifyContent={'flex-end'} mt={2} spacing={2}>
              <Grid item>
                <Button
                  variant="outlined"
                  size="large"
                  onClick={() => history.push(`/${location.pathname.split('/')[1]}`)}
                >
                  Cancelar
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  size="large"
                  {...(!isNotAvailable && {
                    sx: { background: theme.palette.error.main, borderColor: '#EDE7F6' },
                  })}
                  onClick={() => {
                    touched.comment = true;
                    validateField('comment').then(() => {
                      if ((touched.comment && errors.comment) || !values.comment) return;
                      handleDelete({ appId, code: appCode, msg: values.comment });
                    });
                  }}
                  disabled={isUpdating || isNotAvailable || isSuccessUpdate || nextStatus === null}
                >
                  Rechazar
                </Button>
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  color="primary"
                  disabled={isUpdating || isNotAvailable || isSuccessUpdate || nextStatus === null}
                  endIcon={isUpdating && <CircularProgress size={20} />}
                >
                  Aprobar
                </Button>
              </Grid>
            </Grid>
            {/* )} */}
          </Grid>
        )}
      </form>
    </Grid>
  );
};

export default OthersApplications;
