import { Autocomplete, Grid, TextField } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../../../hooks/useRedux';
import { useTableSearch } from '../../../../../../../hooks/useTableSearch';
import AddButton from '../../../../../../shared/components/tables/buttons/AddButton';
import SearchPaginatedTableAndCollapsibleItems from '../../../../../../shared/components/tables/SearchPaginatedTableAndCollapsibleItems';
import {
  useGetListUserProfileQuery,
  useLazyGetUsersByProfileIdQuery,
} from '../../slices/groupsUsersApiSlice';
import {
  setGroupsUserTable,
  setGroupsUsersList,
  setOpenAddGroupsUser,
} from '../../slices/groupsUsersSlice';

const GroupsUsersTable = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const {
    groupsUserTable: { page, size, search },
    groupsUserList,
  } = useAppSelector((state) => state.groupsUser);

  const [profileIdSelected, setProfileIdSelected] = useState(0);

  const [rowsSelected, setRowsSelected] = useState<any[]>([]);

  const { setPage, setPerPage, setSearch } = useTableSearch({
    page,
    perPage: size,
    search,
    updateFunction: setGroupsUserTable,
  });

  const { data: userProfile = [], isLoading: isLoadingUserProfile } = useGetListUserProfileQuery(
    {}
  );

  const [
    getUsers,
    { data: users, isLoading: isLoadingUsers, isFetching: isFetchingUsers, error: errorUsers },
  ] = useLazyGetUsersByProfileIdQuery();

  const dataFetch =
    users?.data?.map((item, index) => ({
      id: index + ((page - 1) * size + 1),
      user_id: item.user_id,
      cedula: item.pers_identification,
      nombre: `${item.pers_full_name}`,
      email: item.pers_email,
      direccion: item.pers_direction,
    })) || [];

  useEffect(() => {
    if (profileIdSelected) {
      const executeGetUsersByProfileId = async () => {
        await getUsers({
          page,
          size,
          search,
          sort: 'user_id',
          type_sort: 'DESC',
          profile_id: profileIdSelected,
        });
      };
      executeGetUsersByProfileId();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, size, search, profileIdSelected]);

  useEffect(() => {
    if (rowsSelected.length > 0) {
      dispatch(setGroupsUsersList(rowsSelected.map((item) => Number(item?.user_id!))));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsSelected]);

  return (
    <Grid>
      <Grid
        xs={4}
        sx={{
          mb: 3,
        }}
      >
        <Grid item xs={12}>
          <Autocomplete
            options={userProfile}
            loading={isLoadingUserProfile}
            getOptionLabel={(option) => option.pro_name}
            renderInput={(params) => (
              <TextField
                {...params}
                //error={Boolean(errors.tipo_comprobante && touched.tipo_comprobante)}
                placeholder={t('maintenance.groups.form.users_select.placeholder')}
              />
            )}
            onChange={(_, value) => {
              setProfileIdSelected(value?.id!);
            }}
          />
        </Grid>
      </Grid>

      <SearchPaginatedTableAndCollapsibleItems
        data={dataFetch}
        headers={{
          id: t('N°'),
          user_id: 'Id',
          cedula: 'Cédula',
          nombre: 'Nombre',
          email: 'Email',
          direccion: 'Dirección',
        }}
        keyExtractor={(item) => `${item.cedula}`}
        searchPlacehoder={t('Buscar usuario')}
        isLoading={isLoadingUsers}
        isFetching={isFetchingUsers}
        error={errorUsers}
        perPage={size}
        setPerPage={setPerPage}
        total={users?.total || 0}
        page={page}
        setPage={setPage}
        numHeader={6}
        search={search}
        setSearch={setSearch}
        checkboxSelection
        onChangeSelections={(rows) => setRowsSelected(rows)}
        ActionButtons={
          <AddButton
            size="large"
            disabled={groupsUserList.length === 0}
            onClick={() => {
              dispatch(setOpenAddGroupsUser(true));
            }}
          />
        }
      />
    </Grid>
  );
};

export default GroupsUsersTable;
