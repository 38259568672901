import { Box, Fab, Typography } from '@material-ui/core';
import { IconLeaf } from '@tabler/icons';
import { setFaqs } from '../../slices/faqSlice';
import { useStyles } from '../../constants/styles';
import { useAppDispatch } from '../../../../../hooks/useRedux';

export const FaqsClient = () => {
  // const [show, setShow] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  // const { faqs } = useAppSelector((state) => state.faqs);

  const classes = useStyles();

  const handleToggleFaq = () => {
    dispatch(setFaqs(true));
  };

  // useEffect(() => {
  //    let interval = setTimeout(function () {
  //       setShow(!show);
  //    }, 6000);
  //    return () => {
  //       clearTimeout(interval);
  //    }
  //    // eslint-disable-next-line
  // }, []);

  return (
    <Box component="span">
      {/* <Tooltip open={!faqs && show} id="message-help" title="¿Necesitas ayuda?" placement="right"> */}
      <Fab color={'primary'} className={classes.buttonFloat} onClick={handleToggleFaq}>
        <IconLeaf stroke={1.5} size="1.5rem" />
        <Typography variant="caption" component="span" className={classes.text} sx={{ ml: 1 }}>
          ¿Necesitas ayuda?
        </Typography>
      </Fab>
      {/* </Tooltip> */}
    </Box>
  );
};
