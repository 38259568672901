import * as yup from 'yup';

export const careePlanningJsonValidationSchema = yup.object().shape({
  campusId: yup.number().required('El campus es requerido').min(1, 'El campus es requerido'),
  capacity: yup
    .number()
    .min(1, 'La capacidad debe ser mayor a 0')
    .typeError('La capacidad debe ser un número')
    .required('La capacidad es requerida'),
  hourhandsId: yup
    .array()
    .of(yup.number())
    .required('El horario es requerido')
    .min(1, 'El horario es requerido'),
  classroomId: yup.number().required('El aula es requerida').min(1, 'El aula es requerida'),
  numFees: yup
    .number()
    .typeError('El número de cuotas debe ser un número')
    .required('El número de cuotas es requerido')
    .min(1, 'El número de cuotas es requerido'),
  courseData: yup.array().of(
    yup.object().shape({
      eventId: yup.string().required('El evento es requerido'),
      eventTitle: yup.string().required('El evento es requerido'),
      subjectId: yup.string().required('El evento es requerido'),
      teacherId: yup.number().nullable(),
    })
  ),
  feeCollectionDay: yup
    .number()
    .required('El dia de cobro es requerido')
    .min(1, 'El dia de debe estar entre 1 y 31')
    .max(31, 'El dia de debe estar entre 1 y 31'),
});
