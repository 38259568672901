import DeleteAlert from '../../../shared/components/dialogs/DeleteAlert';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { useDeleteStudentMutation } from '../slices/studentsApiSlice';
import { resetStudentIdToDelete, setDeleteModalOpen } from '../slices/studentsSlice';

const DeleteStudentDialog = () => {
  const dispatch = useAppDispatch();

  const { deleteModalOpen, studentIdToDelete, studentsList } = useAppSelector(
    (state) => state.students
  );

  const student = studentsList.find((student) => student.id === studentIdToDelete);

  const { currentProfile } = useAppSelector((state) => state.access);

  const [deleteStudent, { isLoading, isSuccess, isError, error }] = useDeleteStudentMutation();

  const handleDelete = () => {
    deleteStudent({ profileId: currentProfile?.profileId!, studentId: studentIdToDelete! });
  };

  const handleClose = () => {
    dispatch(setDeleteModalOpen(false));
    dispatch(resetStudentIdToDelete());
  };

  return (
    <DeleteAlert
      open={deleteModalOpen}
      onClose={handleClose}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      handleDelete={handleDelete}
      error={error as string}
      deleteTitle={`¿Está seguro que desea eliminar el registro de ${student?.pers_full_name}?`}
    />
  );
};

export default DeleteStudentDialog;
