import { FC, useState, useMemo } from 'react';

import { useHistory, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { SerializedError } from '@reduxjs/toolkit';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Autocomplete from '@material-ui/core/Autocomplete';

import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import InputLabel from '../../../shared/components/forms/InputLabel';
import { statusAvailables } from '../../../shared/constants/resourceStatus';
import { AreaPayload } from '../interfaces/payload.interface';
import { useAppSelector } from '../../../../hooks/useRedux';
import { AreaFormSchema } from '../constants/validationSchemas';
import { useTranslation } from 'react-i18next';

interface IFormStates {
  isLoading: boolean;
  isError: boolean;
  error: FetchBaseQueryError | SerializedError | undefined;
  isSuccess: boolean;
}
interface Props {
  editId?: number;
  states: IFormStates;
  submitRequestHandler: (values: AreaPayload) => void;
}

const AreaForm: FC<Props> = ({
  editId,
  states: { error, isError, isLoading, isSuccess },
  submitRequestHandler,
}) => {
  const { t } = useTranslation();

  const history = useHistory();
  const location = useLocation();

  const { areasList } = useAppSelector((state) => state.areas);

  const [showAlerts, setShowAlerts] = useState(true);

  const areaToEdit = useMemo(() => {
    if (editId) return areasList.find((fp) => fp.id === Number(editId));
  }, [editId, areasList]);

  const { getFieldProps, errors, touched, handleSubmit, setFieldValue, values } = useFormik({
    initialValues: {
      ar_name: areaToEdit?.ar_name || '',
      ar_description: areaToEdit?.ar_description || '',
      ar_keywords: areaToEdit?.ar_keywords || '',
      status_id: areaToEdit?.status_id ? +areaToEdit?.status_id : 1,
    },
    validationSchema: AreaFormSchema,
    onSubmit: async (values) => {
      await submitRequestHandler(values);

      setShowAlerts(true);
    },
  });

  return (
    <form onSubmit={handleSubmit}>
      <Grid container gap={2}>
        <Grid item xs={12}>
          {isError && showAlerts && (
            <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
          )}

          {isSuccess && showAlerts && (
            <SuccessAlert
              message={
                !!editId ? t('academic.areas.edit.success') : t('academic.areas.add.success')
              }
              handleDismiss={() => setShowAlerts(false)}
            />
          )}
        </Grid>

        <Grid item container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <InputLabel>{t('academic.areas.add.name')}</InputLabel>
            <TextField
              fullWidth
              {...getFieldProps('ar_name')}
              error={Boolean(errors.ar_name && touched.ar_name)}
              placeholder={t('academic.areas.add.name.placeholder')}
            />
            {errors.ar_name && touched.ar_name && (
              <FormHelperText error>{t(errors.ar_name)}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12}>
            <InputLabel>{t('academic.areas.add.description')}</InputLabel>
            <TextField
              fullWidth
              {...getFieldProps('ar_description')}
              error={Boolean(errors.ar_description && touched.ar_description)}
              placeholder={t('academic.areas.add.description.placeholder')}
            />
            {errors.ar_description && touched.ar_description && (
              <FormHelperText error>{t(errors.ar_description)}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12}>
            <InputLabel>{t('academic.areas.add.keyword')}</InputLabel>
            <TextField
              fullWidth
              {...getFieldProps('ar_keywords')}
              error={Boolean(errors.ar_keywords && touched.ar_keywords)}
              placeholder={t('academic.areas.add.keyword.placeholder')}
            />
            {errors.ar_keywords && touched.ar_keywords && (
              <FormHelperText error>{t(errors.ar_keywords)}</FormHelperText>
            )}
          </Grid>

          {!!editId && (
            <Grid item xs={12}>
              <InputLabel>{t('academic.areas.add.status')}</InputLabel>
              <Autocomplete
                options={statusAvailables}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField {...params} error={Boolean(errors.status_id && touched.status_id)} />
                )}
                value={
                  statusAvailables.find((status) => status.id === values.status_id) ||
                  statusAvailables[0]
                }
                onChange={(e, value) => setFieldValue('status_id', value?.id || '')}
                fullWidth
                clearIcon={null}
              />
              {errors.status_id && touched.status_id && (
                <FormHelperText error>{t(errors.status_id)}</FormHelperText>
              )}
            </Grid>
          )}
        </Grid>

        <Grid
          item
          container
          alignItems="center"
          justifyContent="flex-end"
          spacing={2}
          sx={{ mt: 1 }}
        >
          <Grid item>
            <Button
              variant="outlined"
              size="large"
              color="primary"
              onClick={() => history.push(`/${location.pathname.split('/')[1]}`)}
            >
              {t('academic.areas.add.back')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              disabled={isLoading}
              endIcon={isLoading && <CircularProgress size={20} />}
            >
              {editId ? t('academic.areas.edit.submit') : t('academic.areas.add.submit')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default AreaForm;
