import { CourseStudents } from '../../../courses/interfaces/courseStudents.interfaces';
import {
  Distribution,
  DistributionCalification,
} from '../../interfaces/modelCalification.interfaces';
import { StudentCalifications } from '../../interfaces/studentCalification.interfaces';

export interface Column {
  key: string;
  value: string;
  id: number;
}

export const useColumns = (distribution?: DistributionCalification[]) => {
  let columns: string[] = [];
  let distributions: { [key: string]: Distribution } = {};
  try {
    distribution?.forEach((d) => {
      if (d.distributions) {
        d.distributions?.forEach((di) => {
          columns.push(
            `${d.description}-${d.id}-${di.ref2_supply.description}-${di.ref2_supply_id}`
          );
          distributions[
            `${d.description}-${d.id}-${di.ref2_supply.description}-${di.ref2_supply_id}`
          ] = di;
        });
        if (d.distributions.length > 1) columns.push(`res-${d.description}-${d.id}`);
      } else {
        columns.push(`${d.description}-${d.id}`);
      }
    });
    columns.unshift('name');
    return { columns, distributions };
  } catch (error) {
    return { columns, distributions };
  }
};

export const getStudentGrades = (students?: CourseStudents[], grades?: StudentCalifications[]) => {
  return students?.map((s) => {
    const grade = grades?.filter(
      (g) => g.course_student.student_record.student.id === s.id
      // && (g.calification_model_distribution || g.formula_id)
    );

    return {
      name: {
        id: s.id,
        fullname: `${s.pers_full_name}`,
      },
      grade,
    };
  });
};
export interface DataStudent {
  name: Name;
  grade?: StudentCalifications[];
}
export interface Name {
  id: number;
  fullname: string;
}
export const getRows = (column: string[], grades?: DataStudent[]) => {
  const col = column.slice(1);
  let rows: any[] = [];
  grades?.forEach((g) => {
    let row: any = {};
    col?.forEach((c) => {
      if (c.split('-').length > 3) {
        //q2q2q2
        row[`${c}`] = g.grade?.find(
          (s) =>
            s.supply.description === c.split('-')[2] &&
            s.supply_id === c.split('-')[3] &&
            s.calification_model_distribution?.ref1_supply_id === c.split('-')[1]
        );
      } else if (c.split('-').length === 2) {
        //123
        row[`${c}`] = g.grade?.find(
          (s) =>
            s.supply.description === c.split('-')[0] &&
            s.supply_id === c.split('-')[1] &&
            !s.calification_model_distribution
        );
      } else {
        //sadasd
        row[`${c}`] = g.grade?.find(
          (s) =>
            s.supply.description === c.split('-')[1] &&
            s.supply_id === c.split('-')[2] &&
            !s.formula_id &&
            !s.calification_model_distribution
        );
      }
    });
    row['name'] = g.name;
    rows.push(row);
  });
  return rows;
};

export const getCol = (rows: any[], column: string[]) => {
  return rows.map((r) => {
    let row: any = {};
    column.forEach((c) => {
      row[`${c}`] = r[`${c}`];
    });
    return row;
  });
};

export const getColHeader = (distribution?: DistributionCalification[]) => {
  let col: string[] = [];
  distribution?.forEach((d) => {
    if (d?.distributions) {
      d.distributions.forEach((dd) => {
        col.push(dd.ref2_supply.name);
      });
      if (d.distributions.length > 1) col.push('Resumen');
    }
  });
  return { col };
};
