import { Dialog, DialogContent, Grid, makeStyles, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../../../../../hooks/useRedux';
import Logo from '../../../../components/Logo';
import Noloadedavatar from './../../../../../../assets/images/users/noloadedavatar.png';
import { format } from 'date-fns';

interface Props {
  open: boolean;
  onClose: () => void;
}

const useStyles = makeStyles((theme) => ({
  copyright: {
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
}));

const RedlinksCard = ({ open, onClose }: Props) => {
  const classes = useStyles();

  const { userInfo } = useAppSelector((state) => state.auth);
  const { currentRecordStudent, currentProfile } = useAppSelector((state) => state.access);

  const [photo, setPhoto] = useState(Noloadedavatar);

  const handleResetPhoto = () => {
    setPhoto(Noloadedavatar);
  };

  useEffect(() => {
    if (currentProfile?.imgProfile) setPhoto(currentProfile?.imgProfile);
  }, [currentProfile?.imgProfile]);

  return (
    <Dialog open={open} scroll="body" onClose={onClose} maxWidth="xs" fullWidth>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item container xs={12} justifyContent="center">
            <Grid
              item
              style={{
                width: '200px',
              }}
            >
              <Logo />
            </Grid>
          </Grid>

          <Grid item container xs={12} alignItems="center" justifyContent="center">
            <img
              style={{
                height: '140px',
                width: '140px',
                borderRadius: '50%',
              }}
              alt="avatar"
              src={photo}
              onError={handleResetPhoto}
            />
          </Grid>

          <Grid item container xs={12} justifyContent="center">
            <Grid item>
              <Typography variant="h3" color="primary" align="center">
                {userInfo?.fullname}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4" align="center">
                C.I {userInfo.identification}
              </Typography>
            </Grid>
            {currentRecordStudent?.education_level && (
              <Grid item xs={12}>
                <Typography
                  variant="h4"
                  sx={{
                    fontSize: '1em',
                  }}
                  align="center"
                >
                  {currentRecordStudent?.education_level?.edu_name}
                </Typography>
              </Grid>
            )}
          </Grid>

          <Grid item container xs={12} justifyContent="center">
            <Grid item>
              <Typography
                variant="h5"
                align="center"
                className={classes.copyright}
                onClick={() => {
                  window.open(`https://links.com.ec/redlinks`, '_blank');
                }}
              >
                © Links S.A. {format(new Date(), 'yyyy')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default RedlinksCard;
