import { Button, FormControlLabel, Grid, makeStyles, Radio, RadioGroup } from '@material-ui/core';
import { IconCircleX, IconFile } from '@tabler/icons';
import React from 'react';
import InputLabel from '../../../shared/components/forms/InputLabel';

const useStyles = makeStyles((theme) => ({
  uploadFile: {
    borderColor: theme.palette.secondary.light,
    borderWidth: '2px',
    borderStyle: 'solid',
    borderRadius: 20,
    textAlign: 'center',
    width: '100%',
    height: 'auto',
    position: 'relative',
  },
  fileIcon: {
    mt: 3,
  },
  closeButton: {
    position: 'absolute',
    marginTop: 2,
    marginRight: 2,
    right: 0,
  },
}));

const ImportData = () => {
  const [value, setValue] = React.useState('Leads');
  const [file, setFile] = React.useState('');
  const classes = useStyles();
  console.log(setFile);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <InputLabel>Opción</InputLabel>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={value}
            onChange={handleChange}
          >
            <FormControlLabel value="Leads" control={<Radio />} label="Leads" />
            <FormControlLabel value="Clientes" control={<Radio />} label="Clientes" />
          </RadioGroup>
        </Grid>
        <Grid item xs={12} md={12}>
          <InputLabel>Importar Archivo</InputLabel>
          <Grid item xs={12} md={6} container>
            <label className={classes.uploadFile}>
              {file && (
                <IconCircleX
                  className={classes.closeButton}
                  //onClick={onRemoveFile}
                />
              )}
              <br />
              <input
                name="files[]"
                type="file"
                accept="image/png"
                hidden
                //onChange={onChangeFile}
              />

              <IconFile size={32} />
              <p>{!file ? ' Cargar archivo' : 'target.name'}</p>
            </label>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={12}
          md={6}
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Button
            variant="contained"
            size="large"
            color="primary"
            onClick={() => {
              /* if (filtersSearch) {
                                refetchClassroomData();
                            } else {
                                setDisplayFilters(false);
                            }
                            setFiltersSearch(true); */
            }}
            //disabled={!searchBtn}
          >
            Guardar
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default ImportData;
