import { useState } from 'react';

import { Box, Button, Typography, useTheme } from '@material-ui/core';
import {
  SubdirectoryArrowRightOutlined,
  KeyboardArrowUpOutlined,
  KeyboardArrowDownOutlined,
} from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

import useExpandAccordion from '../../hooks/useExpandAccordion';
import EditorText from './EditorText';
import {
  useAddCommentsMutation,
  useDeleteCommentMutation,
  useUpdateCommentMutation,
} from '../../slices/virtualClassroomApiSlice';
import { useAppSelector } from '../../../../../hooks/useRedux';
import EditorReadText from './EditorReadText';
import { AnswersActivity } from '../interfaces/forums.interfaces';
import MenuMoreVert from '../../components/MenuMoreVert';

interface Props {
  reply: AnswersActivity;
  children: JSX.Element | JSX.Element[];
  forumId: string;
  refetch: () => void;
}
const Replies = ({ reply, children, forumId, refetch }: Props) => {
  const { expanded, handleClickToogle } = useExpandAccordion();
  const theme = useTheme();
  const { t } = useTranslation();

  const {
    id,
    csact_comments,
    csact_name_file,
    children: comments,
    created_at,
    user_id,
    user: {
      person: { pers_first_lastname, pers_second_lastname, pers_firstname },
    },
  } = reply;

  const [editorType, setEditorType] = useState<'add' | 'edit'>('add');

  const data = {
    name: `${pers_first_lastname} ${pers_second_lastname} ${pers_firstname}`,
    comment: csact_comments,
    images: [{ img: csact_name_file }],
    comments,
  };
  const profile = {
    id: user_id.toString(),
    avatar: 'N/A',
    name: `${pers_first_lastname} ${pers_second_lastname} ${pers_firstname}`,
    time: created_at.toLocaleString(),
  };

  const { comments: replies, comment } = data;

  const { userInfo } = useAppSelector((state) => state.auth);
  const { /*currentProfile ,*/ currentStudent } = useAppSelector((state) => state.access);

  const [viewEditor, setViewEditor] = useState<boolean>(false);
  const [createComment /*{ isSuccess: isSuccessComment, isError: isErrorComment, error }*/] =
    useAddCommentsMutation();

  const [editComment] = useUpdateCommentMutation();
  const [deleteComment] = useDeleteCommentMutation();

  const handleDeleteComment = async () => {
    await deleteComment({
      commentId: id,
    });
    refetch();
  };

  const handleEditComment = async (data: { comment: string; files: File | undefined }) => {
    await editComment({
      commentId: id,
      CommentPayload: {
        csact_comments: data.comment,
        files: data.files,
        user_id: Number(userInfo?.id!),
        student_id: Number(currentStudent?.id!),
        type_document: 12,
        parent_id: id.toString(),
      },
    });
    refetch();
    setEditorType('add');
    setViewEditor(false);
  };

  const replyAdd = async (data: { comment: string; files: File | undefined }) => {
    await createComment({
      forumId: Number(forumId),
      CommentPayload: {
        csact_comments: data.comment,
        files: data.files,
        user_id: Number(userInfo?.id!),
        student_id: Number(currentStudent?.id!),
        type_document: 12,
        parent_id: id.toString(),
      },
    });
    refetch();
    // setShowAlerts(true);
  };

  let textEditorRead: any;
  if (!comment?.includes('{') || !comment?.includes('[')) {
    textEditorRead = {
      ops: [
        {
          attributes: {
            bold: true,
          },
          insert: `@${profile.name}`,
        },
        {
          insert: data.comment,
        },
      ],
    };
  } else {
    textEditorRead = JSON.parse(comment);
    textEditorRead.ops.unshift({
      attributes: {
        bold: true,
      },
      insert: `@${profile.name}, `,
    });
  }
  let textEditor: any;
  if (!data.comment?.includes('{') || !data.comment?.includes('[')) {
    textEditor = {
      ops: [
        {
          insert: data.comment,
        },
      ],
    };
  } else {
    textEditor = JSON.parse(data.comment);
  }

  const menuData = [
    {
      name: t('studentVirtualClassroom.forum.editor.edit'),
      handleClick: () => {
        setViewEditor(true);
        setEditorType('edit');
      },
    },
    {
      name: t('studentVirtualClassroom.forum.editor.delete'),
      handleClick: () => {
        handleDeleteComment();
      },
    },
  ];

  return (
    <Box
      sx={{
        pl: 2.5,
      }}
    >
      <Box
        sx={{
          width: '100%',
          borderRadius: 3,
          borderLeft: `10px solid ${theme.palette.primary.main}`,
          px: 3,
          py: 1,
          boxShadow: '0px 0px 5px 0px #777',
          mb: 3,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            color: theme.palette.primary.dark,
          }}
        >
          <EditorReadText text={textEditorRead} />
          {profile.id === userInfo.id.toString() && (
            <Box
              sx={{
                marginLeft: 1,
              }}
            >
              <MenuMoreVert dataMenu={menuData} />
            </Box>
          )}
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            borderTop: `1px solid #ccc`,
            py: 0.5,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              color: theme.palette.primary.dark,
            }}
          >
            <Typography variant="body2">por @{profile.name}</Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {replies && replies.length > 0 && (
              <Button
                startIcon={expanded ? <KeyboardArrowUpOutlined /> : <KeyboardArrowDownOutlined />}
                onClick={handleClickToogle}
              >
                {expanded
                  ? t('studentVirtualClassroom.forum.editor.Hide')
                  : t('studentVirtualClassroom.forum.editor.Show')}
              </Button>
            )}
            <Button
              startIcon={<SubdirectoryArrowRightOutlined />}
              onClick={() => setViewEditor((prev) => !prev)}
            >
              {t('studentVirtualClassroom.forum.editor.reply')}
            </Button>
          </Box>
        </Box>
      </Box>
      {expanded && children}
      {viewEditor && (
        <EditorText
          commentAdd={replyAdd}
          setViewEditor={() => setViewEditor(false)}
          commentEdit={handleEditComment}
          quillCurrent={textEditor}
          type={editorType}
        />
      )}
    </Box>
  );
};

export default Replies;
