import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  Autocomplete,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  TextField,
  InputAdornment,
} from '@material-ui/core';

import { useFormik } from 'formik';

import ErrorAlert from '../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../shared/components/alerts/SuccessAlert';
import InputLabel from '../../../shared/components/forms/InputLabel';

import { useAppSelector } from '../../../../hooks/useRedux';
import { useAddPositionMutation, useGetAvailableRolesQuery } from '../slices/PositionsApiSlice';

import { positionSchema } from '../constants/validation-schemas';
import { statusAvailables } from '../../../shared/constants/resourceStatus';
import { useGetAvailableDepartmentsQuery } from '../../departments/slices/departmentsApiSlice';

const AddPositionForm = () => {
  const history = useHistory();

  const { currentProfile } = useAppSelector((state) => state.access);

  const [showAlerts, setShowAlerts] = useState(true);

  const [addPosition, { isLoading, isError, error, isSuccess }] = useAddPositionMutation();

  const { data: rolesAvailables = [], isLoading: isLoadingRoles } = useGetAvailableRolesQuery({
    profileId: currentProfile?.profileId!,
  });

  const { data: departmentsAvailables = [], isLoading: isLoadingDepartments } =
    useGetAvailableDepartmentsQuery({
      profileId: currentProfile?.profileId!,
    });

  const { getFieldProps, errors, touched, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      posName: '',
      posDescription: '',
      roleId: 1,
      departmentId: 1,
      posKeyword: '',
      statusId: 1,
    },
    validationSchema: positionSchema,
    onSubmit: async (values) => {
      await addPosition({
        profileId: currentProfile?.profileId!,
        positionPayload: {
          pos_name: values.posName,
          pos_description: values.posDescription,
          pos_keyword: values.posKeyword,
          department_id: values.departmentId,
          role_id: values.roleId,
          status_id: values.statusId,
        },
      });

      setShowAlerts(true);
    },
  });

  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction="column" gap={2}>
        <Grid item>
          {isError && showAlerts && (
            <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
          )}

          {isSuccess && showAlerts && (
            <SuccessAlert
              message="El cargo ha sido creado correctamente."
              handleDismiss={() => setShowAlerts(false)}
            />
          )}
        </Grid>

        <Grid item container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <InputLabel>Nombre del cargo</InputLabel>
            <TextField
              fullWidth
              error={Boolean(errors.posName && touched.posName)}
              placeholder="Ingrese el nombre del cargo"
              {...getFieldProps('posName')}
            />
            {errors.posName && touched.posName && (
              <FormHelperText error>{errors.posName}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Descripción</InputLabel>
            <TextField
              multiline
              rows={2}
              fullWidth
              error={Boolean(errors.posDescription && touched.posDescription)}
              placeholder="Ingrese una referencia para el cargo"
              {...getFieldProps('posDescription')}
            />
            {errors.posDescription && touched.posDescription && (
              <FormHelperText error>{errors.posDescription}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12}>
            <InputLabel>Abreviatura</InputLabel>
            <TextField
              fullWidth
              error={Boolean(errors.posKeyword && touched.posKeyword)}
              placeholder="Ingrese la abreviatura"
              {...getFieldProps('posKeyword')}
            />
            {errors.posKeyword && touched.posKeyword && (
              <FormHelperText error>{errors.posKeyword}</FormHelperText>
            )}
          </Grid>

          <Grid item xs={12}>
            <InputLabel>Rol</InputLabel>
            {isLoadingRoles ? (
              <>
                <TextField
                  disabled
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <CircularProgress size={25} />
                      </InputAdornment>
                    ),
                  }}
                  error={Boolean(errors.roleId && touched.roleId)}
                  placeholder="Cargando datos ..."
                />
                {errors.roleId && touched.roleId && (
                  <FormHelperText error>{errors.roleId}</FormHelperText>
                )}
              </>
            ) : (
              <>
                <Autocomplete
                  options={rolesAvailables}
                  loading={isLoadingRoles}
                  loadingText="Cargando roles..."
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(errors.roleId && touched.roleId)}
                      placeholder="Seleccione un rol"
                    />
                  )}
                  onChange={(_, value) => {
                    setFieldValue('roleId', value?.id!);
                  }}
                  fullWidth
                  clearIcon={null}
                />
                {errors.roleId && touched.roleId && (
                  <FormHelperText error>{errors.roleId}</FormHelperText>
                )}
              </>
            )}
          </Grid>

          <Grid item xs={12}>
            <InputLabel>Departamento</InputLabel>
            {isLoadingDepartments ? (
              <>
                <TextField
                  disabled
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <CircularProgress size={25} />
                      </InputAdornment>
                    ),
                  }}
                  error={Boolean(errors.roleId && touched.roleId)}
                  placeholder="Cargando datos ..."
                />
                {errors.roleId && touched.roleId && (
                  <FormHelperText error>{errors.roleId}</FormHelperText>
                )}
              </>
            ) : (
              <>
                <Autocomplete
                  options={departmentsAvailables}
                  loading={isLoadingDepartments}
                  loadingText="Cargando departamentos..."
                  getOptionLabel={(option) => option.dep_name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(errors.departmentId && touched.departmentId)}
                      placeholder="Seleccione un departamento"
                    />
                  )}
                  onChange={(_, value) => {
                    setFieldValue('departmentId', value?.id!);
                  }}
                  fullWidth
                  clearIcon={null}
                />
                {errors.departmentId && touched.departmentId && (
                  <FormHelperText error>{errors.departmentId}</FormHelperText>
                )}
              </>
            )}
          </Grid>

          <Grid item xs={12}>
            <InputLabel>Estado</InputLabel>
            <Autocomplete
              options={statusAvailables}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField {...params} error={Boolean(errors.statusId && touched.statusId)} />
              )}
              defaultValue={statusAvailables[0]}
              onChange={(e, value) => setFieldValue('statusId', value?.id || '')}
              fullWidth
              clearIcon={null}
            />
            {errors.statusId && touched.statusId && (
              <FormHelperText error>{errors.statusId}</FormHelperText>
            )}
          </Grid>
        </Grid>

        <Grid
          item
          container
          alignItems="center"
          justifyContent="flex-end"
          spacing={2}
          sx={{ mt: 1 }}
        >
          <Grid item>
            <Button
              variant="outlined"
              size="large"
              color="primary"
              onClick={() => history.goBack()}
            >
              Regresar
            </Button>
          </Grid>
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              disabled={isLoading}
              endIcon={isLoading && <CircularProgress size={20} />}
            >
              Agregar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default AddPositionForm;
