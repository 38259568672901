import { Grid, Input, LinearProgress } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { Distribution, GradePayload } from '../../interfaces/modelCalification.interfaces';
import { StudentCalifications } from '../../interfaces/studentCalification.interfaces';
import { format } from 'date-fns';
import { Name } from './../helpers/dataGridHelper';
import { useStyles } from './../constants/styles';
import { useAddGradeStudentMutation } from '../../slices/virtualClassroomTeacherApiSlice';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import {
  setCalificationsError,
  setCalificationsAlertOpen,
} from '../../slices/virtualClassroomTeacherSlice';

interface Props {
  grade?: StudentCalifications;
  distributions: Distribution;
  student: Name;
}

const InputGrade = ({ grade, distributions, student }: Props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const {
    course: { courseId },
  } = useAppSelector((state) => state.virtualClassroomTeacherShared);
  const [valueChange, setValueChange] = useState<number | string>(
    grade ? Number(grade.value).toFixed(2) : ''
  );
  const [oldValue, setOldValue] = useState<number | string>('');

  const [addGradeStudent, { isError, isLoading, error }] = useAddGradeStudentMutation();

  useEffect(() => {
    if (isError) {
      setValueChange(oldValue);
      dispatch(setCalificationsError(error as string));
      dispatch(setCalificationsAlertOpen(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError, oldValue, error]);

  const handleBlur = (e: any) => {
    if (e.target.value !== oldValue) {
      const payload: GradePayload = {
        supply_scheme_id: Number(distributions.ref1_supply_id),
        supply_id: Number(distributions.ref2_supply_id),
        score: Number(e.target.value),
        score_date: format(Date.now(), 'yyyy-MM-dd'),
        catalog_score_id: 2,
      };
      addGradeStudent({
        GradePayload: payload,
        course_id: courseId,
        student_id: student.id,
      });
    }
  };

  const handleChange = (e: any) => {
    setValueChange(e.target.value);
  };

  const handleFocus = (e: any) => {
    setOldValue(e.target.value);
  };

  return (
    <>
      <Input
        inputProps={{ min: 0, style: { textAlign: 'center' } }}
        // disableUnderline={true}
        className={classes.input}
        type="number"
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        value={valueChange}
      />
      {isLoading && (
        <Grid height={2}>
          <LinearProgress color="inherit" />
        </Grid>
      )}
    </>
  );
};

export default InputGrade;
