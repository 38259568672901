import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../../../hooks/useRedux';
import MainCard from '../../../../../shared/components/cards/MainCard';
import AddGroupsUsersSideDialog from '../components/users/AddGroupsUsersSideDialog';
import GroupsUsersTable from '../components/users/GroupsUsersTable';
import BackButton from '../../../../../shared/components/buttons/BackButton';

const UsuariosGruposScreen = () => {
  const { t } = useTranslation();
  const { openAddGroupsUser } = useAppSelector((state) => state.groupsUser);

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid container alignItems="center" sx={{ mb: 3 }}>
        <BackButton />
        <Typography variant="h2">{t('maintenance.groups.form.users')}</Typography>
      </Grid>
      <GroupsUsersTable />
      {openAddGroupsUser && <AddGroupsUsersSideDialog />}
    </Grid>
  );
};

export default UsuariosGruposScreen;
