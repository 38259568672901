import { Switch } from 'react-router-dom';

import GuardRoute from '../../../../router/guards/GuardRoute';

import AnnouncemenstScreen from '../announcements/screens/AnnouncementsScreen';
import SendAnnouncementScreen from '../announcements/screens/SendAnnouncementScreen';
import AssistantsAppList from '../assistants/AssistantsAppList';
import CalificationsScreens from '../califications/screens/CalificationsScreens';
import AddContentScreen from '../dashboard/screens/AddContentScreen';
import DashboardScreen from '../dashboard/screens/DashboardScreen';
import StudentsScreen from '../estudents/screens/StudentsScreen';
import EvaluationCreateScreen from '../evaluations/screens/EvaluationCreateScreen';
import EvaluationDetailScreen from '../evaluations/screens/EvaluationDetailScreen';
import EvaluationQualifyScreen from '../evaluations/screens/EvaluationQualifyScreen';
import EvaluationsListScreen from '../evaluations/screens/EvalutionsListScreen';
import ForoScreen from '../forum/screens/ForoScreen';
import AddForum from '../forum/screens/AddForum';
import EditForum from '../forum/screens/EditForum';
import CourseDetailScreen from '../header/CourseDetailScreen';
import TaskCreateScreen from '../tasks/screens/TaskCreateScreen';
import TaskListScreen from '../tasks/screens/TaskListScreen';
import { useAppSelector } from '../../../../hooks/useRedux';
import MixedSkeletons from '../../../shared/components/forms/skeletons/MixedSkeletons';
import ScreenMateriales from '../materials/Screens/ScreenMateriales';
import MaterialScreen from '../materials/MaterialScreen';
import ScreenAsistencePrincipal from '../forum/screens/ScreenAsistencePrincipal';
import BannerMaterials from '../materials/Screens/BannerMaterials';
import MeetingsScreen from '../meetings/screens/MeetingsScreen';
import AddMeetingsScreen from '../meetings/screens/AddMeetingsScreen';
import EditMeetingsScreen from '../meetings/screens/EditMeetingsScreen';
import ScreenEditTask from '../tasks/components/Edit_Task/ScreenEditTask';
import DetailForo from '../forum/screens/DetailForo';
import TaskDetail from '../tasks/screens/TaskDetail';
import CalificateMain from '../califications/screens/CalificateMain';
import ViewNotesScreen from '../califications/screens/ViewNotesScreen';
import ParticipantsActivities from '../dashboard/screens/ParticipantsActivities';
import DetailActivityCalificate from '../califications/screens/DetailActivityCalifcate';
import ScreenQualifyTask from '../tasks/components/QualifyTask/ScreenQualifyTask';
import EditScreenEvaluations from '../evaluations/components/options-answer/copy/EditScreenEvaluations';
import AssistantsAppListView from '../assistants/AssistantsAppListView';
import DetailActivity from '../tasks/components/DetailActivity/DetailAcitvity';
import ConfigCalifications from '../califications/screens/ConfigCalifications';
const VirtualClassroomRouter = () => {
  const {
    course: { courseId },
  } = useAppSelector((state) => state.virtualClassroomTeacherShared);

  return (
    <>
      <CourseDetailScreen />

      {courseId && courseId > 0 ? (
        <Switch>
          <GuardRoute
            exact
            path={`/administrar-aula-virtual/contenido`}
            component={DashboardScreen}
          />
          <GuardRoute
            exact
            path={`/administrar-aula-virtual/contenido/crear`}
            component={AddContentScreen}
          />
          <GuardRoute
            exact
            path={`/administrar-aula-virtual/test`}
            component={EvaluationsListScreen}
          />
          <GuardRoute
            exact
            path="/administrar-aula-virtual/test/crear"
            component={EvaluationCreateScreen}
          />
          <GuardRoute
            exact
            path="/administrar-aula-virtual/test/plantilla/:evaluationId/curso/:courseId"
            component={EditScreenEvaluations}
          />
          <GuardRoute
            exact
            path="/administrar-aula-virtual/test/detalle/:evaluationId/curso/:courseId"
            component={EvaluationDetailScreen}
          />
          <GuardRoute
            exact
            path="/administrar-aula-virtual/test/calificar/:evaluationId/estudiante/:studentId/:userId"
            component={EvaluationQualifyScreen}
          />
          <GuardRoute
            exact
            path={`/administrar-aula-virtual/estudiantes`}
            component={StudentsScreen}
          />
          <GuardRoute
            exact
            path={`/administrar-aula-virtual/reuniones`}
            component={MeetingsScreen}
          />
          <GuardRoute
            exact
            path={`/administrar-aula-virtual/reuniones/agregar`}
            component={AddMeetingsScreen}
          />
          <GuardRoute
            exact
            path={`/administrar-aula-virtual/reuniones/:activityId/editar`}
            component={EditMeetingsScreen}
          />
          <GuardRoute exact path={`/administrar-aula-virtual/sala-debate`} component={ForoScreen} />
          <GuardRoute
            exact
            path={`/administrar-aula-virtual/sala-debate/agregar`}
            component={AddForum}
          />
          <GuardRoute
            exact
            path={`/administrar-aula-virtual/sala-debate/:forumId/editar`}
            component={EditForum}
          />
          <GuardRoute
            exact
            path={`/administrar-aula-virtual/sala-debate/:foroId/view`}
            component={DetailForo}
          />
          <GuardRoute
            exact
            path={`/administrar-aula-virtual/asistencia`}
            component={ScreenAsistencePrincipal}
          />
          <GuardRoute
            exact
            path={`/administrar-aula-virtual/asistencia/calificar`}
            component={AssistantsAppList}
          />
          <GuardRoute
            exact
            path={`/administrar-aula-virtual/asistencia/listar`}
            component={AssistantsAppListView}
          />
          <GuardRoute
            exact
            path={`/administrar-aula-virtual/materiales/crear`}
            component={ScreenMateriales}
          />
          */
          <GuardRoute
            exact
            path={`/administrar-aula-virtual/materiales`} /*BannerMaterials */
            component={BannerMaterials}
          />
          <GuardRoute
            exact
            path={`/administrar-aula-virtual/materiales/importar`}
            component={MaterialScreen}
          />
          <GuardRoute
            exact
            path={`/administrar-aula-virtual/anuncios`}
            component={AnnouncemenstScreen}
          />
          <GuardRoute
            exact
            path={`/administrar-aula-virtual/anuncios/crear`}
            component={SendAnnouncementScreen}
          />
          <GuardRoute
            exact
            path={`/administrar-aula-virtual/notas`}
            component={CalificateMain} /**CalificateMain */
          />
          <GuardRoute
            exact
            path={`/administrar-aula-virtual/notas/view/activities/:activityId`}
            component={ParticipantsActivities} /**CalificateMain */
          />
          <GuardRoute
            exact
            path={`/administrar-aula-virtual/notas/view/activities/:activityId/student/:studentId`}
            component={DetailActivityCalificate} /**CalificateMain */
          />
          <GuardRoute
            exact
            path={`/administrar-aula-virtual/notas/calificar`}
            component={CalificationsScreens}
          />
          <GuardRoute
            exact
            path={`/administrar-aula-virtual/notas/configurations`}
            component={ConfigCalifications}
          />
          <GuardRoute
            exact
            path={`/administrar-aula-virtual/notas/view`}
            component={ViewNotesScreen}
          />
          <GuardRoute
            exact
            path={`/administrar-aula-virtual/actividades`}
            component={TaskListScreen}
          />
          <GuardRoute
            exact
            path={`/administrar-aula-virtual/actividades/:activityId/detail`}
            component={DetailActivity}
          />
          <GuardRoute
            exact
            path={`/administrar-aula-virtual/actividades/crear`}
            component={TaskCreateScreen}
          />
          <GuardRoute
            exact
            path={`/administrar-aula-virtual/actividades/course/:idcourse/task/:idtarea`}
            component={ScreenEditTask}
          />
          <GuardRoute
            exact
            path={`/administrar-aula-virtual/actividades/course/detail/:idtarea`}
            component={TaskDetail}
          />
          <GuardRoute
            exact
            path={`/administrar-aula-virtual/actividades/course/detail/:activityId/student/:studentId`}
            component={ScreenQualifyTask}
          />
        </Switch>
      ) : (
        <MixedSkeletons /> //TaskDetail
      )}
    </>
  );
};

export default VirtualClassroomRouter;
