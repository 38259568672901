import { mainApi } from '../../../../api/mainApi';
import { middlewareApi } from '../../../middleware/middlewareApi';

import { AccessState } from '../../../../types';
import { CourseStudents, DataPayload } from '../interfaces/courseStudents.interfaces';
import { setCourseStudentsList } from './coursesTeacherSlice';
import { RegisterSyllabusDataResponse } from '../interfaces/RegisterSyllabusPayload.interface';
import { RegisterSyllabusData } from '../../../shared/interfaces/registerSyllabusData';
import { MatterInfoForSyllabus } from '../interfaces/matterInfoForSyllabus';
import { DependenceByMatter } from '../../../shared/interfaces/dependencesByMatter.interface';

export const coursesTeachersTags = middlewareApi.enhanceEndpoints({
  addTagTypes: ['CourseStudents'],
});

export const coursesTeacherApi = coursesTeachersTags.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * @GET obtiene los estudiantes de un curso
     */
    getCourseStudents: builder.query<
      CourseStudents[],
      { courseId?: number; payload: DataPayload; allStudents?: boolean }
    >({
      queryFn: async ({ courseId, payload, allStudents = true }, { getState, dispatch }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = currentProfile?.profileId;

          const { data } = await mainApi.get<CourseStudents[]>(`/course/${courseId}/students`, {
            params: {
              ...(!allStudents && { statusApproval: 'Cursando' }),
              user_profile_id,
              ...payload,
              sort: 'pers_full_lastname',
              type_sort: 'asc',
            },
          });

          dispatch(setCourseStudentsList(Array.isArray(data) ? data : []));

          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'CourseStudents', id } as const)),
              { type: 'CourseStudents', id: 'LIST' },
            ]
          : [{ type: 'CourseStudents', id: 'LIST' }],
    }),

    getResponseSyllabus: builder.query<
      RegisterSyllabusDataResponse,
      { id: number; period_id: number }
    >({
      queryFn: async ({ id, period_id }, { getState, dispatch }) => {
        try {
          const { data } = await mainApi.get<RegisterSyllabusDataResponse>(
            `/getResponseSyllabus/${id}/${period_id}`
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    getMatterInfoForSyllabus: builder.query<
      MatterInfoForSyllabus,
      { course_id: number | string; materia_id: number | string }
    >({
      queryFn: async ({ course_id, materia_id }) => {
        try {
          const { data } = await mainApi.get<MatterInfoForSyllabus>(
            `subjects/${course_id}/${materia_id}`
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    addRegisterSyllabus: builder.mutation<
      RegisterSyllabusDataResponse,
      { payload: RegisterSyllabusData }
    >({
      queryFn: async ({ payload }, { getState }) => {
        const {
          access: { currentProfile },
        } = getState() as { access: AccessState };

        const user_profile_id = currentProfile?.profileId;

        try {
          const { data } = await mainApi.post<RegisterSyllabusDataResponse>(
            `/syllabus?user_profile_id=${user_profile_id}`,
            payload
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },

      invalidatesTags: ['CourseStudents'],
    }),
    getDependenceByMatter: builder.query<DependenceByMatter, { materia_id: number | string }>({
      queryFn: async ({ materia_id }) => {
        try {
          const { data } = await mainApi.get<DependenceByMatter>(
            `get-subject-dependecie-by-matter/${materia_id}`
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),
  }),
});

export const {
  useGetCourseStudentsQuery,
  useGetResponseSyllabusQuery,
  useLazyGetResponseSyllabusQuery,
  useAddRegisterSyllabusMutation,
  useGetMatterInfoForSyllabusQuery,
  useGetDependenceByMatterQuery,
} = coursesTeacherApi;
