import { Grid, CircularProgress, Typography } from '@material-ui/core';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import MainCard from '../cards/MainCard';
import { useGetSubjectByIdQuery } from '../../slices/catalogApi';
import MatterTableInfo from './MatterTableInfo';
import { Formik } from 'formik';
import StepperPrimarySyllabus from '../register_syllabus/StepperPrimarySyllabus';
import {
  useAddRegisterSyllabusMutation,
  useGetMatterInfoForSyllabusQuery,
  useGetResponseSyllabusQuery,
} from '../../../teachers/courses/slices/coursesTeacherApiSlice';
import { useEffect, useState } from 'react';
import { bibliography, generalDetails, initialValues, lessonPlan } from './constants';
import SuccessAlert from '../alerts/SuccessAlert';
import ErrorAlert from '../alerts/ErrorAlert';
import { SyllabusSchema } from './validation';
import FormRegisterSyllabus from './FormRegisterSyllabus';
import { useTranslation } from 'react-i18next';

const steps = [generalDetails, lessonPlan, bibliography];

const RegisterSilabusStepper = () => {
  const history = useHistory();

  const { path } = useRouteMatch();

  const { caseKey } = useParams<{ caseKey: string }>();

  const { id, courseId, period } = useParams<{ id?: string; courseId?: string; period?: string }>();

  const [showAlerts, setShowAlerts] = useState(false);

  const { t } = useTranslation();

  const { data: syllabusData, isLoading: isLoadingSyllabus } = useGetResponseSyllabusQuery({
    id: Number(id!),
    period_id: Number(period!),
  });

  const {
    data: matterInfoForSyllabus,
    isError,
    error,
  } = useGetMatterInfoForSyllabusQuery({
    course_id: Number(courseId!),
    materia_id: Number(id!),
  });

  const [
    addRegisterSyllabus,
    {
      isLoading: isLoadingAddRegisterSyllabus,
      isSuccess: isSuccessAddRegisterSyllabus,
      isError: isErrorAddRegisterSyllabus,
      error: errorAddRegisterSyllabus,
    },
  ] = useAddRegisterSyllabusMutation();

  const { data: matterInfo } = useGetSubjectByIdQuery({
    id: id!,
  });

  const activeStep = steps.findIndex((step) => step.url === caseKey) || 0;

  const handleStep = (step: number) => () => {
    history.push(
      path
        .replace(':caseKey', steps[step].url || generalDetails.url)
        .replace(':id', id ? id : '')
        .replace(':courseId', courseId ? courseId : '')
        .replace(':period', period ? period : '')
    );
    // setActiveStep(step);
  };

  useEffect(() => {
    if (isSuccessAddRegisterSyllabus) {
      setShowAlerts(true);
      setTimeout(() => {
        history.push(`/administrar-cursos-profesor`);
      }, 1500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessAddRegisterSyllabus]);

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      {isLoadingSyllabus ? (
        <Grid container justifyContent="center">
          <CircularProgress />
        </Grid>
      ) : (
        <>
          {isError && (
            <Grid item xs={12}>
              <ErrorAlert message={error as any} />
            </Grid>
          )}
          <Grid container justifyContent="center" alignItems="center" mb={6}>
            <Grid item xs={10} container justifyContent="center" alignItems="center" m={1}>
              <Typography fontWeight={2} variant="h2" color="initial">
                {matterInfo?.education_level?.edu_name}
              </Typography>
            </Grid>
            <Grid item xs={10} container justifyContent="center" alignItems="center" mt={2} mb={4}>
              <Typography fontWeight={2} variant="h3" color="initial">
                Syllabus
              </Typography>
            </Grid>

            <Grid item xs={10} container justifyContent="center" alignItems="center">
              <MatterTableInfo matterData={matterInfoForSyllabus} />
            </Grid>
          </Grid>

          <Formik
            initialValues={initialValues}
            validationSchema={SyllabusSchema}
            onSubmit={async (values) => {
              console.log(values);
              try {
                const { temp, ...formValues } = values;
                await addRegisterSyllabus({
                  payload: {
                    ...formValues,
                    syllabus: {
                      ...formValues.syllabus,
                      date_send: new Date().toISOString().split('T')[0],
                    },
                  },
                }).unwrap();
                setShowAlerts(true);
              } catch (error) {
                setShowAlerts(true);
                console.log(error);
              }
            }}
          >
            {({ handleSubmit }) => (
              <>
                {isSuccessAddRegisterSyllabus && showAlerts && (
                  <Grid item xs={12}>
                    <SuccessAlert
                      message={t('Formulario guardado exitosamente')}
                      handleDismiss={() => setShowAlerts(false)}
                    />
                  </Grid>
                )}
                {isErrorAddRegisterSyllabus && showAlerts && (
                  <Grid item xs={12}>
                    <ErrorAlert
                      message={errorAddRegisterSyllabus as any}
                      handleDismiss={() => setShowAlerts(false)}
                    />
                  </Grid>
                )}
                <Grid container justifyContent="center" alignItems="center">
                  <StepperPrimarySyllabus
                    activeStep={activeStep}
                    handleStep={handleStep}
                    steps={steps}
                  />
                </Grid>
                <Grid container justifyContent="center" alignItems="center" mt={4}>
                  <FormRegisterSyllabus
                    handleSubmit={handleSubmit}
                    isLoadingAddRegisterSyllabus={isLoadingAddRegisterSyllabus}
                    steps={steps}
                    addRegisterSyllabus={addRegisterSyllabus}
                    setShowAlerts={setShowAlerts}
                    syllabusData={syllabusData}
                    matterInfoForSyllabus={matterInfoForSyllabus}
                  />
                </Grid>
              </>
            )}
          </Formik>
        </>
      )}
    </Grid>
  );
};

export default RegisterSilabusStepper;
