import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import SearchPaginatedTable from '../../../shared/components/tables/SearchPaginatedTable';
import { useGetParametersCompanyQuery } from '../slices/parameterCompanyApiSlice';
import AddButton from '../../../shared/components/tables/buttons/AddButton';
import { useTableSearch } from '../../../../hooks/useTableSearch';
import {
  setIsOpenModalAdd,
  setIsOpenModalEdit,
  updateParameterTable,
  setPcIdSelected,
  setParametersCompanyData,
} from '../slices/parameterCompanySlice';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import DisallowActionCompaniesBtn from './DisallowActionCompaniesBtn';
import { CircularProgress, IconButton, Tooltip, Box } from '@material-ui/core';
import { ToggleOff, ToggleOn } from '@material-ui/icons';

const ParametersCompanyTable = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const {
    syllabusTable: { page, perPage: size, search },
  } = useAppSelector((state) => state.syllabus);

  const { setPage, setPerPage, setSearch } = useTableSearch({
    page,
    perPage: size,
    search,
    updateFunction: updateParameterTable,
  });

  const { data, isLoading, isFetching, error } = useGetParametersCompanyQuery();

  const datafetch =
    data?.data!.map((d, index) => ({
      id: index + ((page - 1) * size + 1),
      module: d.cp_module,
      name: {
        name: d.cp_name,
        description: d.descripcion,
        keyword: d.cp_keyword,
      },
      value: d.value,
      options: d,
    })) || [];

  useEffect(() => {
    if (data && Array.isArray(data.data)) {
      dispatch(setParametersCompanyData(data.data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <SearchPaginatedTable
      data={datafetch}
      headers={{
        id: 'N°',
        module: t('Módulo'),
        name: t('financial.economic_group.table.name'),
        value: t('Valor'),
        options: t('shared.search_paginated_table.table.options'),
      }}
      keyExtractor={(item) => item.id.toString()}
      searchPlacehoder={t('Buscar...')}
      isLoading={isLoading}
      isFetching={isFetching}
      error={error}
      perPage={size}
      setPerPage={setPerPage}
      total={data?.total || 0}
      page={page}
      setPage={setPage}
      numHeader={5}
      search={search}
      setSearch={setSearch}
      customDataCellsProperties={{
        id: {
          align: 'center',
        },
        module: {
          align: 'center',
        },
        value: {
          align: 'center',
        },
        options: {
          align: 'center',
        },
      }}
      customRenderers={{
        options: (row) => (
          <>
            <DisallowActionCompaniesBtn
              parameterId={row.options.id}
              disabled={row.options.action_disallow === '1'}
            />
            {row.value.toString() !== '1' && row.value.toString() !== '0' && (
              <>
                <Tooltip
                  title={`${
                    Boolean(Number(row.options.value)) ? 'Desactivar valor' : 'Activar valor'
                  }`}
                >
                  <IconButton
                    disabled={row.options.action_disallow === '1'}
                    aria-label="Editar"
                    onClick={() => {
                      dispatch(setIsOpenModalEdit(true));
                      dispatch(setPcIdSelected(row.options.id));
                    }}
                  >
                    {isLoading && <CircularProgress size={20} />}
                    {!isLoading && Boolean(Number(row.options.value)) && (
                      <ToggleOff sx={{ fontSize: '1.3rem' }} />
                    )}
                    {!isLoading && !Boolean(Number(row.options.value)) && (
                      <ToggleOn sx={{ fontSize: '1.3rem' }} color="primary" />
                    )}
                  </IconButton>
                </Tooltip>
              </>
            )}
          </>
        ),
        name: (row) => (
          <>
            <Box>{`${row.name.keyword} - ${row.name.name}`}</Box>
            <Box
              sx={{
                mt: 2,
              }}
            >
              {row.name.description}
            </Box>
          </>
        ),

        value: (row) => (
          <>
            {row.value.toString() !== '1' && row.value.toString() !== '0' ? (
              <Box>{row.value}</Box>
            ) : (
              <>
                <Tooltip
                  title={`${
                    Boolean(Number(row.options.value)) ? 'Desactivar valor' : 'Activar valor'
                  }`}
                >
                  <IconButton
                    disabled={row.options.action_disallow === '1'}
                    aria-label="Editar"
                    onClick={() => {
                      dispatch(setIsOpenModalEdit(true));
                      dispatch(setPcIdSelected(row.options.id));
                    }}
                  >
                    {isLoading && <CircularProgress size={20} />}
                    {!isLoading && Boolean(Number(row.options.value)) && (
                      <ToggleOff sx={{ fontSize: '1.3rem' }} />
                    )}
                    {!isLoading && !Boolean(Number(row.options.value)) && (
                      <ToggleOn sx={{ fontSize: '1.3rem' }} color="primary" />
                    )}
                  </IconButton>
                </Tooltip>
              </>
            )}
          </>
        ),
      }}
      ActionButtons={
        <AddButton
          size="large"
          onClick={() => {
            dispatch(setIsOpenModalAdd(true));
          }}
        />
      }
    />
  );
};

export default ParametersCompanyTable;
