import { Grid, Typography, IconButton } from '@material-ui/core';
import { IconArrowNarrowLeft } from '@tabler/icons';
import { useAppSelector } from '../../../../hooks/useRedux';
import MainCard from '../../../shared/components/cards/MainCard';
import { useHistory } from 'react-router-dom';

import DeleteELSDialog from '../components/DeleteELCDialog';
import ELSGridView from '../components/group-subjects/ELCGridView';
// import AddSubjects from './AddSubjects';

const ELSScreen = () => {
  const { educationLevelSubjectIdToDelete } = useAppSelector(
    (state) => state.educationLevelSubject
  );
  const history = useHistory();

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      {/* <Grid item sx={{ mb: 3 }} container justifyContent="space-between" alignItems="center"> */}
      <Grid item container alignItems="center" justifyContent="space-between" gap={1}>
        <Grid item>
          <Grid item container alignItems="center" spacing={4}>
            <IconButton onClick={() => history.goBack()} sx={{ display: 'block' }}>
              <IconArrowNarrowLeft size={30} />
            </IconButton>
            <Typography variant="h2">Carreras de grupo de nivelación</Typography>
          </Grid>
        </Grid>
      </Grid>

      <ELSGridView />

      {educationLevelSubjectIdToDelete && <DeleteELSDialog />}
    </Grid>
  );
};

export default ELSScreen;
