import DeleteAlert from '../../../shared/components/dialogs/DeleteAlert';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
//import { useDeleteCampusMutation } from '../slices/campusApiSlice';
import { resetShelfToDelete, setDeleteModalOpen } from '../slices/shelfsSlice';
import { useDeleteShelftMutation } from '../slices/shelfsApiSlice';

const DeleteShelftDialog = () => {
  const dispatch = useAppDispatch();

  const { deleteModalOpen, shelftToDelete } = useAppSelector((state) => state.shelfts);
  //const { currentProfile } = useAppSelector((state) => state.access);

  const [deleteShelft, { isLoading, isSuccess, isError, error }] = useDeleteShelftMutation();

  const handleDelete = async () => {
    await deleteShelft({
      id: shelftToDelete!,
    });
  };

  const handleClose = () => {
    dispatch(setDeleteModalOpen(false));
    dispatch(resetShelfToDelete());
  };

  return (
    <DeleteAlert
      open={deleteModalOpen}
      onClose={handleClose}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      handleDelete={handleDelete}
      error={String(error)}
      deleteTitle="¿Estás seguro de eliminar el estante?"
    />
  );
};

export default DeleteShelftDialog;
