import Avatar from '@material-ui/core/Avatar';
// import Circle from '@material-ui/icons/Circle';
import { getSubjectStatusColor } from '../helpers/colors';

interface Props {
  variant: string;
  quantity?: string | number;
}

const CircleIcon = ({ variant, quantity }: Props) => {
  const color = getSubjectStatusColor(variant);

  // return <Circle sx={{ color, marginBottom: 0 }} />;
  return (
    <Avatar
      sx={{
        bgcolor: color,
        width: 20,
        height: 20,
        fontSize: '0.9rem',
        marginRight: '3px',
        color: 'white',
      }}
    >
      {quantity || 0}
    </Avatar>
  );
};

export default CircleIcon;
