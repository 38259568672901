import { useEffect, useRef, useState } from 'react';

import {
  Dialog,
  DialogProps,
  Grid,
  Button,
  Typography,
  useTheme,
  DialogTitle,
  Stack,
  Avatar,
  Autocomplete,
  TextField,
} from '@material-ui/core';

import PhoneIcon from '@material-ui/icons/Phone';
import MailIcon from '@material-ui/icons/Mail';

import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import {
  useGetStudentRecordsByStudentQuery,
  useUpdateStudentCounselorMutation,
} from '../slices/studentsApiSlice';
import { StudentRecords } from '../interfaces/studentRecords.interface';
import ProfileSkeletonCard from '../../../shared/components/cards/Skeleton/ProfileSkeletonCard';
import MainCard from '../../../shared/components/cards/MainCard';
import {
  useGetCollaboratorQuery,
  useLazyGetCollaboratorsAdvisorQuery,
} from '../../../hr/collaborators/slices/collaboratorsApiSlice';
import Noloadedavatar from './../../../../../src/assets/images/users/noloadedavatar.png';
import CustomAsyncPaginatedSelect from '../../../shared/components/extended/Form/CustomAsyncPaginatedSelect';
import { useGetRedlinksAvatarQueryQuery } from '../../../shared/slices/redlinksApi';
import NoData from '../../../shared/components/NoData';
import image from '../../../../assets/images/illustrations/telework.svg';
import { useAppSelector } from '../../../../hooks/useRedux';
import TextFieldFetching from '../../../shared/components/TextFieldFetching';

const useStyles = makeStyles((theme) => ({
  paper: { maxWidth: '600px', width: '90%', minHeight: '30%', padding: 0 },
  /////////////

  card: {
    // backgroundColor:
    //   theme.palette.mode === 'dark' ? theme.palette.dark.dark : theme.palette.primary.main,
    color: '#fff',
    overflow: 'hidden',
    position: 'relative',
    '&>div': {
      position: 'relative',
      zIndex: 5,
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      width: '210px',
      height: '210px',
      // background:
      //   theme.palette.mode === 'dark'
      //     ? 'linear-gradient(210.04deg, ' +
      //       theme.palette.primary.dark +
      //       ' -50.94%, rgba(144, 202, 249, 0) 95.49%)'
      //     : theme.palette.primary[200],
      borderRadius: '50%',
      zIndex: 1,
      top: '-85px',
      right: '-95px',
      [theme.breakpoints.down('xs')]: {
        top: '-105px',
        right: '-140px',
      },
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      zIndex: 1,
      width: '210px',
      height: '210px',
      // background:
      //   theme.palette.mode === 'dark'
      //     ? 'linear-gradient(140.9deg, ' +
      //       theme.palette.primary.dark +
      //       ' -14.02%, rgba(144, 202, 249, 0) 82.50%)'
      //     : theme.palette.primary[200],
      borderRadius: '50%',
      top: '-125px',
      right: '-15px',
      opacity: 0.5,
      [theme.breakpoints.down('xs')]: {
        top: '-155px',
        right: '-70px',
      },
    },
  },
  content: {
    padding: '20px !important',
  },
  avatar: {
    ...theme.typography.commonAvatar,
    ...theme.typography.largeAvatar,
    cursor: 'default',
    // margin: '8px',
    height: 'auto',
    width: 'auto',
    backgroundColor: 'transparent',
    // backgroundColor: theme.palette.secondary[200],
    // padding: '0px 15px',
  },
}));

interface Props extends DialogProps {
  onClose: () => void;
  title: string;

  userId: number | null;
  studentId: string | null;
  data: StudentRecords[];
}

const SetCounselorDialog = ({ onClose, title, userId, studentId, ...props }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();

  const { studentsList } = useAppSelector((state) => state.students);

  const student = studentsList?.find((student) => student.id === Number(studentId));

  const { data: studentRecordsAvaliables = [], isLoading: isLoadingER } =
    useGetStudentRecordsByStudentQuery({ studentId: studentId! }, { skip: !studentId });

  const [studentRecord, setStudentRecord] = useState<StudentRecords | null>(null);

  useEffect(() => {
    if (studentRecordsAvaliables.length > 0) {
      setStudentRecord(studentRecordsAvaliables[0]);
    }
  }, [studentRecordsAvaliables]);

  const [photo, setPhoto] = useState(Noloadedavatar);

  const [selectedColl, setSelectedColl] = useState<any | null>(null);

  const [cahngeCounselor, { isSuccess, isError, isLoading }] = useUpdateStudentCounselorMutation();

  const { data, isFetching: isFetchingColl } = useGetCollaboratorQuery(
    {
      collaboratorId: Number(studentRecord?.collaborator_id) || 0,
    },
    { skip: !studentRecord?.collaborator_id }
  );

  const { data: redlinksInfo } = useGetRedlinksAvatarQueryQuery(
    {
      identification: studentRecord?.collaborator_id || '0',
    },
    { skip: !studentRecord?.collaborator_id }
  );

  const handleResetPhoto = () => {
    setPhoto(Noloadedavatar);
  };

  useEffect(() => {
    if (redlinksInfo?.result?.foto) setPhoto(redlinksInfo?.result?.foto);
  }, [redlinksInfo]);

  const [getCollaboratorTrigger] = useLazyGetCollaboratorsAdvisorQuery();

  const getCollaborators = async (search: string, page: number, perPage: number) => {
    const response = await getCollaboratorTrigger({
      page,
      perPage,
      search,
    }).unwrap();

    const hasMore = response.current_page < response.last_page;

    return {
      options: response.data.map((dt) => {
        return {
          value: dt.collaborator_id,
          label: (dt as any).pers_full_name,
          ...dt,
        };
      }),
      hasMore,
    };
  };

  const handleChangeCounselor = async () => {
    try {
      await cahngeCounselor({
        stdudent_record_id: studentRecord?.id!,
        collaborator_id: selectedColl.value,
      }).unwrap();

      setStudentRecord(
        (prevState) => prevState && { ...prevState, collaborator_id: selectedColl.value }
      );
    } catch (error) {
      console.log('f');
    }
  };

  const containerRef = useRef<HTMLDivElement | null>(null);

  // const theme = useTheme();
  return (
    <Dialog
      {...props}
      classes={{ paper: classes.paper }}
      sx={{ backgroundColor: 'transparent' }}
      onClose={onClose}
    >
      <DialogTitle>
        <Typography
          variant="h2"
          textAlign="center"
          sx={{ color: theme.palette.primary.main, mb: 1 }}
        >
          {t('academic.students_list.student.counselor.title')}
        </Typography>
      </DialogTitle>
      <form>
        {isFetchingColl ? (
          <ProfileSkeletonCard className={classes.card} />
        ) : (
          <MainCard
            border={false}
            // className={classes.card}
            contentClass={classes.content}
            ref={containerRef}
            mb={3}
          >
            <Stack spacing={2} width="100%">
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item>
                    <Typography
                      // fontSize="1.1rem"
                      variant="subtitle1"
                      fontWeight="bold"
                      component="span"
                      sx={{ color: theme.palette.primary.main }}
                    >
                      {t('academic.students_list.student.counselor.student_label')}
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Typography
                      variant="subtitle1"
                      component="span"
                      // sx={{ color: theme.palette.primary.main }}
                    >
                      {student?.pers_full_name}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item>
                {isLoadingER && <TextFieldFetching />}
                {!isLoadingER && (
                  <Autocomplete
                    options={studentRecordsAvaliables || []}
                    getOptionLabel={(option) =>
                      `${option.education_level?.edu_name} (${option.mesh?.anio})`
                    }
                    clearIcon={null}
                    onChange={(_, val) => {
                      if (val) {
                        setStudentRecord(val);
                      }
                    }}
                    value={
                      studentRecordsAvaliables?.find((record) => record.id === studentRecord?.id) ||
                      null
                    }
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        placeholder="Seleccione la unidad académica"
                        label="Unidad académica"
                        // helperText={
                        //   Boolean(formik.errors.discount && formik.touched.discount) &&
                        //   formik.errors.discount
                        // }
                      />
                    )}
                  />
                )}
              </Grid>

              <Grid item>
                <Typography fontSize="1.3rem" fontWeight="bold">
                  {t('academic.students_list.student.counselor.current_counselor')}
                </Typography>
              </Grid>

              {!data ? (
                <>
                  <Grid container alignItems="center" mt={10}>
                    <NoData
                      imgWitdh={100}
                      image={image}
                      title={t('academic.students_list.student.counselor.no_counselor_title')}
                      subtitle={''}
                    />
                  </Grid>

                  <Grid item>
                    <Typography>
                      {t('academic.students_list.student.counselor.new_counselor')}
                    </Typography>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item>
                    <Grid container>
                      <Grid item xs={4}>
                        <Avatar variant="rounded" className={`${classes.avatar}`} sx={{ mb: 0 }}>
                          <img
                            style={{
                              height: '80px',
                              borderRadius: '50%',
                            }}
                            src={photo}
                            onError={handleResetPhoto}
                            alt={'Consejero'}
                          />
                        </Avatar>
                      </Grid>
                      <Grid item xs={8}>
                        <Stack spacing={1}>
                          <Typography
                            component="p"
                            width="100%"
                            fontSize="1.1rem"
                            fontWeight="bold"
                            align="left"
                          >
                            {data?.user.person.pers_full_name || 'No Tiene asignado un consejero/a'}
                          </Typography>

                          <Grid item xs={12}>
                            <Grid container spacing={1} alignItems="center">
                              <Grid item>
                                <MailIcon fontSize="small" />
                              </Grid>
                              <Grid item>
                                <Typography component="span" align="left">
                                  {data?.coll_email}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid item xs={12}>
                            <Grid container spacing={1} alignItems="center">
                              <Grid item>
                                <PhoneIcon fontSize="small" />
                              </Grid>
                              <Grid item>
                                <Typography component="span" align="left">
                                  {data?.user.person.pers_cell}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item>
                    <Typography>
                      {t('academic.students_list.student.counselor.update_counselor')}
                    </Typography>
                  </Grid>
                </>
              )}

              <Grid item xs={12} mb={1}>
                <CustomAsyncPaginatedSelect
                  key={'collaboratorId'}
                  value={selectedColl}
                  isError={false}
                  placeholder={'Escoger colaborador'}
                  setValue={setSelectedColl}
                  fetchFunc={getCollaborators}
                  inDialog
                />
              </Grid>

              <Grid item container xs={12} justifyContent="center" gap={1}>
                {!isSuccess && !isError && (
                  <Button
                    variant="text"
                    size="large"
                    color="primary"
                    onClick={onClose}
                    disabled={isLoading}
                  >
                    {t('alerts.no_cancel')}
                  </Button>
                )}

                {(isSuccess || isError) && (
                  <Button variant="contained" size="large" color="primary" onClick={onClose}>
                    {t('alerts.continue')}
                  </Button>
                )}

                {!isSuccess && !isError && (
                  <Button
                    variant="contained"
                    size="large"
                    onClick={handleChangeCounselor}
                    color="primary"
                    autoFocus
                    disabled={isLoading || !selectedColl}
                  >
                    Guardar
                  </Button>
                )}
              </Grid>
            </Stack>
          </MainCard>
        )}
      </form>
    </Dialog>
  );
};

export default SetCounselorDialog;
