import { useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import { useCreateFaqMutation } from '../../slices/faqApiSlice';
import ErrorAlert from '../../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../../shared/components/alerts/SuccessAlert';
import { useAppSelector } from '../../../../../hooks/useRedux';
import { FormikStep, FaqStepper } from '../stepper';
import { FaqCreateInfo } from './FaqCreateInfo';
import { FaqCreateInfoSchema, FaqStepperModule } from '../../constants/validation-schema';
import { FaqCreateFiles } from './FaqCreateFiles';
import { FaqSelectModule } from './FaqSelectModule';
import { useTranslation } from 'react-i18next';

export const FaqCreateStepperIndex = () => {
  const { t } = useTranslation();

  const [showAlerts, setShowAlerts] = useState(false);

  const [addFaq, { isError, error, isSuccess }] = useCreateFaqMutation();

  const { currentProfile } = useAppSelector((state) => state.access);

  return (
    <>
      <Grid container sx={{ mt: 2, mb: 2 }}>
        <Grid item xs={12}>
          {isError && showAlerts && (
            <ErrorAlert message={error as string} handleDismiss={() => setShowAlerts(false)} />
          )}

          {isSuccess && showAlerts && (
            <SuccessAlert
              message={t('configuration.faqs.stepper.success')}
              handleDismiss={() => setShowAlerts(false)}
            />
          )}
        </Grid>
      </Grid>
      <FaqStepper
        initialValues={{
          faq_question: '',
          faq_answer: '',
          faq_keyword: '',
          faq_link_video: '',
          faq_tags: [],
          file: null,
          faq_module:'',
        }}
        validateOnChange={false}
        onSubmit={async (values) => {
          await addFaq({
            profileId: currentProfile?.profileId!,
            payload: {
              faq_answer: values.faq_answer,
              faq_keyword: values.faq_keyword,
              faq_link_video: values.faq_link_video,
              faq_question: values.faq_question,
              faq_tags: values.faq_tags,
              file: values.file,
              faq_module: values.faq_module,
            },
          });
          setShowAlerts(true);
        }}
      >
        <FormikStep
          label={t('configuration.faqs.stepper.step1')}
          validationSchema={FaqStepperModule}
        >
          <Box paddingBottom={3} paddingTop={3}>
            <FaqSelectModule />
          </Box>
        </FormikStep>
        <FormikStep
          label={t('configuration.faqs.stepper.step2')}
          validationSchema={FaqCreateInfoSchema}
        >
          <Box paddingBottom={3} paddingTop={3}>
            <FaqCreateInfo />
          </Box>
        </FormikStep>
        <FormikStep label={t('configuration.faqs.stepper.step3')}>
          <Box paddingBottom={3} paddingTop={3}>
            <FaqCreateFiles />
          </Box>
        </FormikStep>
      </FaqStepper>
    </>
  );
};
