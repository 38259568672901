import { useHistory } from 'react-router-dom';
import {
  Grid,
  InputAdornment,
  TextField,
  useTheme,
  Typography,
  makeStyles,
  Button,
  TextFieldProps,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
} from '@material-ui/core';
import { DebounceInput } from 'react-debounce-input';
import SearchIcon from '@material-ui/icons/Search';
import { useFilterFaqsQuery } from '../../faqs/slices/faqApiSlice';
import { useAppSelector, useAppDispatch } from '../../../../hooks/useRedux';
import CustomIcon from '../../../shared/components/illustrations/CustomIcon';
import LogoLegacy from '../../../../assets/images/logo-web-NBS.svg';
import { IconSettings } from '@tabler/icons';
import { useGetFaqsQuery, useGetFaqByModuleQuery } from '../../faqs/slices/faqApiSlice';
import { setFaqs } from '../../faqs/slices/faqSlice';
import { useState } from 'react';
import { ExpandMore } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '20px 20px 20px 20px',
    boxShadow: '0px 0px 20px #BFDAF9',
    borderRadius: '10px',
    display: 'flex',
    height: '75px',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: '20px',
    width: '100%',
    position: 'relative',
    "&:hover":{
    background:theme.palette.secondary.light,
  },
  },
  active: {
    backgroundColor: theme.palette.primary[200],
    "&:hover":{
      background:theme.palette.secondary.dark,
    }
  },
  cardRow: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cardTitle: {
    color: theme.palette.primary.main,
    fontSize: '14px',
    fontWeight: 600,
  },
  cardTitleActive: {
    color: theme.palette.common.white,
    fontSize: '14px',
    fontWeight: 600,
  },
}));

export const HelpCenter = () => {
  const classes = useStyles();

  const theme = useTheme();

  const history = useHistory();

  const dispatch = useAppDispatch();

  const [search, setSearch] = useState<string>('');

  const [searchModule, setSearchModule] = useState<string>('');

  const { currentProfile, currentRol } = useAppSelector((state) => state.access);

  const modules = Object.values(currentRol?.modules!);

  const modulesName = modules?.map((e) => e);

  const { data, isLoading } = useFilterFaqsQuery({
    profileId: currentProfile?.profileId!,
    sort: 'faq_num_visit',
    typeSort: 'desc',
  });

  const { data: resultFaq, isLoading: isLoadingFaqs } = useGetFaqsQuery(
    {
      profileId: currentProfile?.profileId!,
      search: search,
    },
    { skip: search === '', refetchOnMountOrArgChange: true }
  );

  const { data: resultFaqModule, isLoading: isLoadingFaqsModule } = useGetFaqByModuleQuery(
    {
      profileId: currentProfile?.profileId!,
      search: searchModule,
    },
    { skip: searchModule === '', refetchOnMountOrArgChange: true }
  );

  const handleHelpCenter = async (faqId: number) => {
    history.push(`/centro-asistencia/detalle/${faqId}`);

    await dispatch(setFaqs(false));
  };

  const resultFaqFilter = resultFaq?.data?.map((e) => ({ id: e?.id, name: e?.faq_question }));
  
  const resultFaqFilterModule = resultFaqModule?.data?.map((e) => ({ id: e?.id, name: e?.faq_question }));

  const tags = data?.data?.map((e) => e?.faq_tags.split(','))[0]!;

  const handleFaqsFav =(e: { currentTarget: { value: any; textContent: any; }; }) => {
    setSearch(e.currentTarget.textContent);
    setSearchModule('');
  };

  const handleFaqsModul =(modules: string) => {
      setSearchModule(modules);
      setSearch('');
      console.log(resultFaqFilterModule);
      console.log(searchModule);
  }

  const ModuleCard = ({ name, modules }: { name: string, modules: string  }) => {
    return (
      <Grid item xs={12} sm={6} md={4} xl={3} sx={{ cursor: 'pointer' }}>
        <Button
          className={`${classes.root}`}
          sx={{
            '&::after': {
              bgcolor: theme.palette.primary.main,
            },
            minHeight: 50,
            opacity: 1,
          }}
        onClick={()=>handleFaqsModul(modules)}    
        >
          <Grid container item spacing={1}>
            <Grid
              item
              container
              xs={12}
              spacing={1}
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid
                item
                container
                xs={12}
                md={4}
                lg={3}
                justifyContent="center"
                alignItems="center"
              >
                <Grid item>
                <IconSettings
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} md={8} lg={9}>
                <Grid
                  item
                  sx={{
                    justifyContent: 'flex-start !important',
                  }}
                >
                  <Typography variant="body2" 
                  >
                    {name}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Button>
      </Grid>
    );
  };

  const FaqDetail = ({ id, name }: { name: string; id: number }) => {
    return (
      <Grid item xs={12}>
        <Typography
          variant="h5"
          onClick={() => handleHelpCenter(id)}
          sx={{ textDecoration: 'underline', cursor: 'pointer' }}
          color="primary"
        >
          {name}
        </Typography>
      </Grid>
    );
  };

  const ResultFaqsNoFound = () => {
    return (
      <Grid item>
        <Typography variant="h5" color="primary">
          No se encontraron resultados
        </Typography>
      </Grid>
    );
  };

  const Modules = () => {
    return (
      <>
        {modulesName?.map((e, index) => (
          <ModuleCard  key={`$modules-${index}`} name={e.name} modules={e.key} />
        ))}
      </>
    );
  };

  const ResultFaqs = () => {
    return (
      <>
        {resultFaqFilter?.map(({ id, name }, index) => (
          <FaqDetail key={`result-search-faq-${index}`} id={id} name={name} />
        ))}
      </>
    );
  };

  const ResultFaqsModule = () => {
    return (
      <>
        {resultFaqFilterModule?.map(({ id, name }, index) => (
          <FaqDetail key={`result-search-faq-${index}`} id={id} name={name} />
        ))}
      </>
    );
  };

  return (
    <Grid container justifyContent="center" alignContent="center">
      <Grid container item xs={12} justifyContent={'center'} sx={{ mb: 6 }}>
        <CustomIcon icon={LogoLegacy} customWidth={350} />
      </Grid>
      <Grid item xs={6}>
        <Grid item>
          <DebounceInput
            autoFocus={true}
            minLength={2}
            debounceTimeout={300}
            onChange={({ target }) => {
              setSearch(target.value);
            }}
            value={search}
            element={(props: JSX.IntrinsicAttributes & TextFieldProps) => (
              <TextField
                style={{
                  width: '100%',
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon color="primary" fontSize="small" />
                    </InputAdornment>
                  ),
                }}
                placeholder="Realiza la búsqueda de tu interés"
                variant="outlined"
                size="medium"
                {...props}
              />
            )}
          />
        </Grid>
        <Grid
          container
          item
          justifyContent="left"
          alignContent="flex-start"
          sx={{ mt: 1 }}
          spacing={1}
        >
          <Grid item>
            <Typography variant="body1">Búsquedas populares:</Typography>
          </Grid>
          {isLoading
            ? '...'
            : tags?.map((e, index) => (
                <Grid item key={`$more-visit-faq-${index}`}>
                  <Button 
                  onClick={handleFaqsFav}
                  >
                    {e}{' '}
                  </Button>
                </Grid>
              ))}
        </Grid>
        {(isLoadingFaqs || isLoadingFaqsModule) && (
          <Grid item xs={12} sx={{mt:5}} container justifyContent="center" alignItems="center">
          <CircularProgress size={30} />
        </Grid>
        )}
        {search !== '' && searchModule === '' && (
          <Grid container item justifyContent="left" sx={{ mt: 5 }}>
            <Grid item>
              <Typography variant="h4">Resultados</Typography>
            </Grid>
            <Grid container item justifyContent="left" sx={{ mt: 2 }} spacing={2}>
              {resultFaqFilter?.length !== 0 ? <ResultFaqs /> : <ResultFaqsNoFound /> }
            </Grid>
          </Grid>
        )}
        {searchModule !== '' && search === '' &&(
          <Grid container item justifyContent="left" sx={{ mt: 5 }}>
            <Grid item>
              <Typography variant="h4">Resultados</Typography>
            </Grid>
            <Grid container item justifyContent="left" sx={{ mt: 2 }} spacing={2}>
              {resultFaqFilterModule?.length !== 0 ? <ResultFaqsModule /> : <ResultFaqsNoFound /> }
            </Grid>
          </Grid>
        )}
        <Grid>
        <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore/>}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h5">Buscar por modulos en el sistema</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container item justifyContent="left" sx={{ mt: 5 }} spacing={2}>
            <Modules />
          </Grid>
        </AccordionDetails>
      </Accordion>
        </Grid>
      </Grid>
    </Grid>
  );
};
