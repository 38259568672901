import { mainApi } from '../../../../api/mainApi';
import { AccessState } from '../../../../types';
import { middlewareApi } from '../../../middleware/middlewareApi';
import { EventNewResponse } from '../../events-news/interfaces/event-new.interfaces';
import { Profile, ProfileResponse } from '../interfaces/profiles.interfaces';
import { setProfilesList } from './profilesSlice';

export const profilesTags = middlewareApi.enhanceEndpoints({
  addTagTypes: ['Profiles'],
});
export const profilesApi = profilesTags.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * @GET all profiles
     */
    getProfiles: builder.query<
      ProfileResponse,
      {
        profileId: number;
        page: number;
        perPage: number;
        search: string;
        sort?: string;
        type_sort?: string;
      }
    >({
      queryFn: async ({ profileId, page, search, perPage, sort, type_sort }, { dispatch }) => {
        try {
          const { data } = await mainApi.get<ProfileResponse>('/profiles', {
            params: {
              user_profile_id: profileId,
              page,
              size: perPage,
              ...(sort && { sort }),
              type_sort: type_sort || 'desc',
              search,
            },
          });

          dispatch(setProfilesList(data.data));

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'Profiles', id } as const)),
              { type: 'Profiles', id: 'LIST' },
            ]
          : [{ type: 'Profiles', id: 'LIST' }],
    }),
    /**
     * @GET all profiles availables
     */
    getAvailablesProfiles: builder.query<Profile[], { profileId?: number }>({
      queryFn: async ({ profileId }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = currentProfile?.profileId;

          const { data } = await mainApi.get<Profile[]>('/profiles', {
            params: {
              ...(profileId ? { user_profile_id: profileId } : { user_profile_id }),
              data: 'all',
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    /**
     * @GET Profile events
     */
    getProfilesEventsNews: builder.query<
      EventNewResponse,
      { profileId: number; page: number; perPage: number; search: string }
    >({
      queryFn: async ({ profileId, page, perPage, search }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = currentProfile?.profileId;
          const { data } = await mainApi.get<EventNewResponse>(`/profiles/${profileId}/events`, {
            params: {
              user_profile_id,
              page,
              size: perPage,
              type_sort: 'desc',
              search,
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    /**
     * @GET Profile by id
     */
    getProfile: builder.query<Profile, { profileId: number; profileGetId: number }>({
      queryFn: async ({ profileId, profileGetId }, { dispatch }) => {
        try {
          const { data } = await mainApi.get<Profile>('/profiles', {
            params: {
              user_profile_id: profileId,
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result, error, { profileId }) => [{ type: 'Profiles', profileId }],
    }),

    /**
     * @POST create profile
     */
    addProfile: builder.mutation<Profile, { profileId: number; profilePayload: Partial<Profile> }>({
      queryFn: async ({ profileId, profilePayload }) => {
        try {
          const { data } = await mainApi.post<Profile>('/profiles', {
            user_profile_id: profileId,
            ...profilePayload,
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'Profiles', id: 'LIST' }],
    }),

    /**
     * @PUT update economic group
     */
    updateProfile: builder.mutation<
      Profile,
      { profileId: number; profileUpdateId: number; profilePayload: Partial<Profile> }
    >({
      queryFn: async ({ profileId, profileUpdateId, profilePayload }) => {
        try {
          const { data } = await mainApi.put<Profile>(`/profiles/${profileUpdateId}`, {
            user_profile_id: profileId,
            ...profilePayload,
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'Profiles', id: 'LIST' }],
    }),

    /**
     * @DELETE remove profiles
     */
    deleteProfile: builder.mutation<Profile, { profileId: number; profileDeleteId: number }>({
      queryFn: async ({ profileId, profileDeleteId }) => {
        try {
          const { data } = await mainApi.delete(`/profiles/${profileDeleteId}`, {
            data: {
              user_profile_id: profileId,
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'Profiles', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetProfilesQuery,
  useAddProfileMutation,
  useGetAvailablesProfilesQuery,
  useDeleteProfileMutation,
  useGetProfilesEventsNewsQuery,
  useGetProfileQuery,
  useUpdateProfileMutation,
} = profilesApi;
