import { FC, useState, useEffect } from 'react';
import useTheme from '@material-ui/core/styles/useTheme';

import { useTranslation } from 'react-i18next';

import SearchPaginatedTable from '../../../shared/components/tables/SearchPaginatedTable';
import { useGetCashPointReportDetailsQuery } from '../slices/cashPointsApiSlice';
import { useAppSelector } from '../../../../hooks/useRedux';
import { Tooltip, Typography } from '@material-ui/core';
import { IconAlertCircle, IconCircleCheck, IconCircleX } from '@tabler/icons';
// import Grid from '@material-ui/core/Grid';

interface Props {
  cashPointId: number;
  payment_way_keyword: string;
  financial_institutions_keyword?: string;
}

const ReportDetails: FC<Props> = ({ cashPointId, payment_way_keyword }) => {
  const theme = useTheme();

  const { t } = useTranslation();
  //funciones filtro
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const [search, setSearch] = useState('');

  useEffect(() => {
    setPage(1);
  }, [perPage, search]);

  const { isLoading, error, data, isFetching } = useGetCashPointReportDetailsQuery(
    {
      page,
      perPage,
      search,
      cashPointId,
      payment_way_keyword,
    },
    { refetchOnMountOrArgChange: 30 }
  );

  const datafetch = data?.data!.map((cp) => ({
    // debtor: cp,
    status: cp.payment_status?.cat_keyword,
    debtor: cp,
    originAccount: cp.financial_institutions?.fin_int_name || '-',
    DestinedAccount: cp.cuenta_destino || '-',
    payValue: '-',
    payDiscount: '-',
    amount: cp.o_payment_amount,
  }));

  const defaultConfiguration = useAppSelector((state) => state.access.defaultConfiguration);

  return (
    <SearchPaginatedTable
      data={datafetch!}
      headers={{
        status: '',
        // debtor: t('financial.close_cash_point.report_details.table.debt_description'),
        debtor: 'Deudor',
        originAccount: 'Cuenta Origen',
        DestinedAccount: t('financial.close_cash_point.report_details.table.destined_account'),
        payValue: `(${defaultConfiguration?.currency_symbol}) ${t(
          'financial.close_cash_point.report_details.table.pay_value'
        )}`,
        payDiscount: `(${defaultConfiguration?.currency_symbol}) ${t(
          'financial.close_cash_point.report_details.table.pay_discount'
        )}`,
        amount: t('financial.close_cash_point.report_details.table.amount'),
      }}
      tableProps={{
        tableContainer: { sx: { borderRadius: theme.shape.borderRadius } },
        tableHead: { sx: { backgroundColor: theme.palette.grey[200] } },
      }}
      keyExtractor={(item) => item.debtor.id.toString()}
      searchPlacehoder={t('financial.close_cash_point.report_details.table.search_placeholder')}
      isLoading={isLoading}
      isFetching={isFetching}
      error={error}
      perPage={perPage}
      setPerPage={setPerPage}
      total={data?.total || 0}
      page={page}
      setPage={setPage}
      numHeader={5}
      search={search}
      setSearch={setSearch}
      showFilter={false}
      customHeadersCellsProperties={{
        originAccount: {
          align: 'center',
        },
        DestinedAccount: {
          align: 'center',
        },
        payValue: {
          align: 'center',
        },
        payDiscount: {
          align: 'center',
        },
        amount: {
          align: 'center',
        },
      }}
      customDataCellsProperties={{
        status: { align: 'center' },
        debtor: {
          align: 'center',
        },
        originAccount: {
          align: 'center',
        },
        DestinedAccount: {
          align: 'center',
        },
        payValue: {
          align: 'center',
        },
        payDiscount: {
          align: 'center',
        },
        amount: {
          align: 'center',
        },
      }}
      customRenderers={{
        status: (row) => (
          <>
            {row.status === 'estado-cobro-rechazado' && (
              <Tooltip title="Rechazado">
                <IconCircleX style={{ color: theme.palette.error.main }} />
              </Tooltip>
            )}
            {row.status === 'estado-cobro-pagado' && (
              <Tooltip title="Pagado">
                <IconCircleCheck style={{ color: theme.palette.success.main }} />
              </Tooltip>
            )}
            {row.status === 'estado-cobro-por-cobrar' && (
              <Tooltip title="Por cobrar">
                <IconAlertCircle style={{ color: theme.palette.warning.dark }} />
              </Tooltip>
            )}
          </>
        ),
        debtor: (row) => (
          <>
            {
              <Typography color="textPrimary" textAlign="left">
                {row.debtor.debtor}
              </Typography>
            }
            <Typography color="textPrimary" textAlign="left">
              Ref. interna: {row.debtor.id_transaction}
            </Typography>
          </>
        ),
        DestinedAccount: (row) => (
          <>
            {!row.debtor.financial_institution_target?.fin_int_name &&
              row.DestinedAccount === '-' && (
                <Typography color="textPrimary" textAlign="center">
                  {'-'}
                </Typography>
              )}
            {row.debtor.financial_institution_target?.fin_int_name && (
              <Typography color="textPrimary" textAlign="left">
                {row.debtor.financial_institution_target?.fin_int_name}
              </Typography>
            )}
            {row.DestinedAccount !== '-' && (
              <Typography color="textPrimary" textAlign="left">
                N° {row.DestinedAccount}
              </Typography>
            )}
          </>
        ),
      }}
    />
  );
};

export default ReportDetails;
