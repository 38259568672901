import { useTranslation } from 'react-i18next';

import ConfirmAlert from '../../../shared/components/dialogs/ConfirmAlert';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { resetOpenCashPoint, setStartCashPointModalOpen } from '../slices/cashPointsSlice';
import { useReOpenCashPointMutation } from '../slices/cashPointsApiSlice';

const OpenCashPointDialog = () => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const { isOpenStartCashPointModal, cashPointToOpen } = useAppSelector(
    (state) => state.cashPoints
  );

  const [openCashPoint, { isLoading, isSuccess, isError, error }] = useReOpenCashPointMutation();

  const handleConfirm = () => {
    openCashPoint({
      ...cashPointToOpen!,
    });
  };

  const handleClose = () => {
    dispatch(setStartCashPointModalOpen(false));
    dispatch(resetOpenCashPoint());
  };

  return (
    <ConfirmAlert
      open={isOpenStartCashPointModal}
      onClose={handleClose}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      handleDelete={handleConfirm}
      error={error as string}
      title={t('financial.close_cash_point.open_dialog.open_title')}
      subtitle={t('financial.close_cash_point.open_dialog.open_sub_title')}
      confirmBtnMessage={t('financial.close_cash_point.open_dialog.confirm')}
      successMessage={t('financial.close_cash_point.open_dialog.open_success')}
    />
  );
};

export default OpenCashPointDialog;
