import { Autocomplete, Grid, Stack, TextField, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { format, isValid } from 'date-fns';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import { useTableSearch } from '../../../../hooks/useTableSearch';
import SearchPaginatedTable from '../../../shared/components/tables/SearchPaginatedTable';
import { useGetPrincipalEducationLevelsQuery } from '../../education-levels/slices/educationLevelsApiSlice';
import { useGetGraduatedStudentsQuery } from '../slices/studentsGraduateApiSlice';
import {
  setCareerIdSelected,
  setEducationLevelIdSelected,
  setGraduationDateSelected,
  updateGraduatedStudentsTable,
} from '../slices/studentsGraduateSlice';
import { useGetCareerByEduLevelQuery } from '../../courses/slices/courseApiSlice';
import LocalizedDatePicker from '../../../shared/components/controlled/LocalizedDatePicker';
import useVerificationColl from '../hooks/useVerificationColl';
import DownloadButton from '../../../shared/components/tables/buttons/DownloadPdfButton';
import { IconFile } from '@tabler/icons';

const GraduatedStudentsTable = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { blockFilter } = useVerificationColl();

  const { currentProfile } = useAppSelector((state) => state.access);

  const {
    graduatedStudentsTable: { page, perPage, search, filterArrow },
    educationLevelIdSelected,
    careerIdSelected,
    graduationDateSelected,
  } = useAppSelector((state) => state.studentsGraduate);

  const { setPage, setPerPage, setSearch, setFilterArrow } = useTableSearch({
    page,
    perPage,
    search,
    filterArrow,
    updateFunction: updateGraduatedStudentsTable,
    otherFilters: {
      educationLevelIdSelected,
      careerIdSelected,
      graduationDateSelected: graduationDateSelected
        ? format(graduationDateSelected, 'yyyy-MM-dd')
        : null,
    },
  });

  const { data, error, isLoading, isFetching } = useGetGraduatedStudentsQuery(
    {
      page: page,
      perPage: perPage,
      search: search,
      ...(educationLevelIdSelected && { educationLevelId: educationLevelIdSelected }),
      ...(graduationDateSelected && {
        graduationDate: format(graduationDateSelected, 'yyyy-MM-dd'),
      }),
      ...(filterArrow && { sort: filterArrow.name, type_sort: filterArrow.direction }),
    },
    {
      skip: Boolean(graduationDateSelected && !isValid(graduationDateSelected)),
    }
  );

  const { data: eduLevels = [], isLoading: isLoadingE } = useGetPrincipalEducationLevelsQuery(
    {
      profileId: currentProfile?.profileId!,
    },
    {
      skip: !currentProfile?.profileId,
    }
  );

  const { data: careers, isLoading: isLoadingC } = useGetCareerByEduLevelQuery(
    {
      eduLevelId: educationLevelIdSelected!,
      profileId: currentProfile?.profileId!,
    },
    {
      skip: !educationLevelIdSelected || !currentProfile?.profileId,
    }
  );

  const datafetch =
    data?.data?.map((item, index) => ({
      id: index + ((page - 1) * perPage + 1),
      code: item.student?.stud_code,
      name: {
        current: item.student?.user?.person?.pers_full_lastname_fullname,
        graduate: item.person_education.person_name,
      },
      dni: item.student?.user?.person?.pers_identification,
      gender: {
        current: item.student?.user?.person?.pers_gender,
        graduate: item.person_education.person_gender,
      },
      title: item?.person_education?.pers_edu_certificate,
      graduationDate: item?.person_education?.pers_edu_graduation_date,
      options: item,
    })) || [];

  return (
    <Grid item xs={12} mt={2}>
      <SearchPaginatedTable
        data={datafetch}
        error={error}
        isLoading={isLoading}
        isFetching={isFetching}
        search={search}
        searchPlacehoder={t('academic.students_graduate.search')}
        listFilterArrows={{
          name: 'persons.pers_full_name',
          // dni: 'student.user.person.pers_identification',
          // title: 'person_education.pers_edu_certificate',
          graduationDate: 'person_educations.pers_edu_graduation_date',
        }}
        filterArrow={filterArrow}
        setFilterArrow={setFilterArrow}
        perPage={perPage}
        page={page}
        setPage={setPage}
        setPerPage={setPerPage}
        setSearch={setSearch}
        keyExtractor={(item) => item.id.toString()}
        headers={{
          id: 'N°',
          code: 'Código',
          name: t('academic.students_graduate.name'),
          dni: t('academic.students_graduate.dni'),
          gender: t('academic.students_graduate.gender'),
          title: t('academic.students_graduate.certificate'),
          graduationDate: t('academic.students_graduate.graduation_date'),
          options: t('shared.search_paginated_table.table.options'),
        }}
        customRenderers={{
          name: ({ name }) => (
            <Stack spacing={1}>
              <Grid item>
                <Typography align="left">{name.current}</Typography>
              </Grid>
              {!!name.graduate && (
                <Grid item>
                  <Typography variant="subtitle2" align="left">
                    <b>Graduación: </b>
                    {name.graduate}
                  </Typography>
                </Grid>
              )}
            </Stack>
          ),
          gender: ({ gender }) => (
            <Stack spacing={1}>
              <Grid item>
                <Typography align="left">{gender.current}</Typography>
              </Grid>
              {!!gender.graduate && (
                <Grid item>
                  <Typography variant="subtitle2" align="left">
                    <b>Graduación: </b>
                    {gender.graduate}
                  </Typography>
                </Grid>
              )}
            </Stack>
          ),
          options: (row) => (
            <DownloadButton
              url={row.options.person_education.pers_edu_document_url}
              buttonLabel={t('shared.table.file')}
              typeButton="icon-button"
              iconButtonProps={{ Icon: IconFile }}
              onlyView={true}
            />
          ),
        }}
        customDataCellsProperties={{
          id: {
            align: 'center',
          },
          name: {
            align: 'center',
          },
          dni: {
            align: 'center',
          },
          gender: {
            align: 'center',
          },
          title: {
            align: 'center',
          },
          graduationDate: {
            align: 'center',
          },
          code: {
            align: 'center',
          },
        }}
        numHeader={6}
        total={data?.total || 0}
        additionalFilters={
          <Grid container spacing={2}>
            <Grid item container xs={12} md={3}>
              {isLoadingE ? (
                <TextField
                  disabled
                  placeholder={t('academic.students_graduate.edu_level.loading')}
                  fullWidth
                />
              ) : (
                <Autocomplete
                  options={eduLevels}
                  getOptionLabel={(option) => option.edu_name}
                  loading={isLoadingE}
                  disabled={blockFilter}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t('academic.students_graduate.edu_level')}
                      placeholder={t('academic.students_graduate.edu_level.placeholder')}
                      variant="outlined"
                      fullWidth
                    />
                  )}
                  onChange={(event, value) => {
                    dispatch(setEducationLevelIdSelected(value?.id!));
                  }}
                  value={eduLevels.find((item) => item.id === educationLevelIdSelected)}
                  fullWidth
                />
              )}
            </Grid>
            <Grid item container xs={12} md={3}>
              {!educationLevelIdSelected && (
                <TextField
                  disabled
                  placeholder={t('academic.students_graduate.career.no_edu_level')}
                  fullWidth
                />
              )}
              {educationLevelIdSelected && isLoadingC && (
                <TextField
                  disabled
                  placeholder={t('academic.students_graduate.career.loading')}
                  fullWidth
                />
              )}
              {educationLevelIdSelected && !isLoadingC && (
                <Autocomplete
                  options={careers?.children || []}
                  getOptionLabel={(option) => option.edu_name}
                  loading={isLoadingC}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t('academic.students_graduate.career')}
                      placeholder={t('academic.students_graduate.career.placeholder')}
                      variant="outlined"
                      fullWidth
                    />
                  )}
                  onChange={(event, value) => {
                    dispatch(setCareerIdSelected(value?.id!));
                  }}
                  value={careers?.children?.find((item) => item.id === careerIdSelected)}
                  fullWidth
                />
              )}
            </Grid>

            <Grid item xs={12} md={3}>
              <LocalizedDatePicker
                label={t('academic.students_graduate.graduation_date')}
                value={graduationDateSelected}
                onChange={(newValue) => {
                  dispatch(setGraduationDateSelected(newValue as Date));
                }}
                placeholder={t('academic.students_graduate.graduation_date')}
                clearIcon={true}
              />
            </Grid>
          </Grid>
        }
      />
    </Grid>
  );
};

export default GraduatedStudentsTable;
