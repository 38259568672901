import { useMemo } from 'react';

import { Grid, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { useAppSelector } from '../../../../hooks/useRedux';
import MainCard from '../../../shared/components/cards/MainCard';
import AccountStatus from '../components/AccountStatus';
import BottomSticky from '../components/builder/BottomSticky';
import PaymentsHistoryList from '../components/PaymentsHistoryList';
import PaymentsTabs from '../components/PaymentsTabs';
import ContactFoot from '../components/builder/ContactFoot';

const PaymentsHistoryScreen = () => {
  const history = useHistory();
  const { selectedDebts } = useAppSelector((state) => state.payments);

  const totalAmount = useMemo(() => {
    let amount = 0;

    selectedDebts.forEach((el) => {
      amount += +el?.apply_early_payment!
        ? Number(el.net_value_early_payment)
        : Number(el.fees_balance);
    });

    return amount;
  }, [selectedDebts]);

  const goToPay = () => {
    history.push('/estado-de-cuenta/checkout');
  };

  return (
    <>
      <Grid component={MainCard} mb={2} sx={{ border: '1px solid', borderColor: 'secondary' }}>
        <AccountStatus goToPay={goToPay} />
      </Grid>

      <Grid sx={{ minHeight: '100vh' }}>
        <Grid component={MainCard} sx={{ padding: 2 }}>
          <Grid sx={{ mb: 3 }}>
            <Typography variant="h2">Estado de cuenta</Typography>

            <PaymentsTabs />

            <ContactFoot />

            {false && (
              <>
                {/* descartado */}

                <PaymentsHistoryList />
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
      {/* <Grid item sx={{ height: '100vh' }}> */}
      <BottomSticky totalAmount={totalAmount} handleCLick={goToPay} />
      {/* </Grid> */}
    </>
  );
};

export default PaymentsHistoryScreen;
