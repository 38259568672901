import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StudentAnnouncement, User } from '../interfaces/student-announcements.interfaces';
import { StudentHistoryCalification } from '../interfaces/student-history-calification.interfaces';

interface AcademicCounselingState {
  historyCalificationList: StudentHistoryCalification[];
  matterIdDetail?: number;
  matterIdNew?: boolean;
  matterName?: string;
  studentAnnouncements: StudentAnnouncement[];
  openModalMatterDetail: boolean;
  openSendAnnouncementModal: boolean;
  teacherSelected?: User | null;
}

const initialState: AcademicCounselingState = {
  openModalMatterDetail: false,
  studentAnnouncements: [],
  historyCalificationList: [],
  openSendAnnouncementModal: false,
  teacherSelected: null,
};

const academicCounselingSlice = createSlice({
  name: 'academicCounseling',
  initialState,
  reducers: {
    setHistoryCalification: (state, action: PayloadAction<StudentHistoryCalification[]>) => {
      state.historyCalificationList = action.payload;
    },
    setOpenModalMatterDetail: (state, action: PayloadAction<boolean>) => {
      state.openModalMatterDetail = action.payload;
    },
    setMatterIdDetail: (state, action: PayloadAction<number>) => {
      state.matterIdDetail = action.payload;
    },
    setMatterIdNew: (state, action: PayloadAction<boolean>) => {
      state.matterIdNew = action.payload;
    },
    setMatterName: (state, action: PayloadAction<string>) => {
      state.matterName = action.payload;
    },
    setStudentAnnouncements: (state, action: PayloadAction<StudentAnnouncement[]>) => {
      state.studentAnnouncements = state.studentAnnouncements.concat(action.payload);
    },
    resetStudentAnnouncements: (state) => {
      state.studentAnnouncements = [];
    },
    resetMatterIdDetail: (state) => {
      state.matterIdDetail = undefined;
    },
    setOpenSendAnnouncementModal: (state, action: PayloadAction<boolean>) => {
      state.openSendAnnouncementModal = action.payload;
    },
    setTeacherSelected: (state, action: PayloadAction<User | null>) => {
      state.teacherSelected = action.payload;
    },
  },
});

export const {
  setOpenModalMatterDetail,
  setMatterIdDetail,
  setMatterIdNew,
  setMatterName,
  setStudentAnnouncements,
  setHistoryCalification,
  resetStudentAnnouncements,
  resetMatterIdDetail,
  setOpenSendAnnouncementModal,
  setTeacherSelected,
} = academicCounselingSlice.actions;

export default academicCounselingSlice.reducer;
