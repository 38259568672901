import { Switch, Redirect } from 'react-router-dom';

import { useAppSelector } from '../../../../hooks/useRedux';

import AssignCourseScreen from '../screens/ChangePeriodScreen';

import GuardRoute from '../../../../router/guards/GuardRoute';

import { permissionKey } from '../constants/permissions';

function ChangePeriodRouter() {
  // Verify permission
  const { currentModule } = useAppSelector((state) => state.access);
  const hasPermission = permissionKey in currentModule?.permissions!;

  if (!hasPermission) return <Redirect to="/" />;

  return (
    <Switch>
      <GuardRoute
        exact
        path={`/cambiar-periodo`}
        component={AssignCourseScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={usersSubPermissionKeys.read}
      />
    </Switch>
  );
}

export default ChangePeriodRouter;
