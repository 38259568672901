import * as yup from 'yup';

export const MeshValidationSchema = yup.object().shape({
  name: yup.string().required('El pensum requerido.'),
  // CAS CES
  casRes: yup.string().required('La resolución CES es requerida.'),
  ocasRes: yup.string().required('La resolución OCAS es requerida.'),
  creationDate: yup
    .date()
    .required('La fecha de creación es requerida.')
    .typeError('Seleccione una fecha.'),
  year: yup
    .date()
    .required('El año es requerido.')
    .typeError('Ingrese un año con el formato correcto.'),
  // .number()
  // .integer()
  // .min(0, 'El año debe ser un valor mayor a cero')
  // .max(9999, 'Valor no válido, límite excedido')
  // .required('El año es requerido.')
  // .typeError('Ingrese un año con el formato correcto.'),
  title: yup.string().required('El título es requerido.'),
  itinerary: yup.string(),
  numPeriods: yup
    .number()
    .required('El número de períodos es requerido.')
    .min(0, 'El número de períodos debe ser un valor mayor a cero')
    .max(9999, 'Valor no válido, límite excedido')
    .typeError('Ingrese un número válido'),
  internalHomologationSubjects: yup
    .number()
    // .required('Campo requerido.')
    .min(0, 'Este valor no debe ser menor a cero')
    .max(9999, 'Valor no válido, límite excedido')
    .typeError('Ingrese un número válido'),
  externalHomologationSubjects: yup
    .number()
    // .required('Campo requerido.')
    .min(0, 'Este valor no debe ser menor a cero')
    .max(9999, 'Valor no válido, límite excedido')
    .typeError('Ingrese un número válido'),
  levelEduId: yup
    .number()
    .required('El nivel educativo es requerido.')
    .typeError('Seleccione un nivel educativo.'),
  modalityId: yup
    .number()
    .required('La modalidad de estudio es requerida.')
    .typeError('Seleccione una modalidad de estudio.'),
  statusId: yup.number().nullable(),
  // .required('El estado de la malla es requerido.')
  // .typeError('Seleccione un estado para la malla.'),
  calificationTypeId: yup.mixed().when('isRequiredCalificationType', {
    is: true,
    then: yup
      .string()
      .required('El tipo de calificación es requerida.')
      .typeError('Seleccione un tipo de calificación.'),
    otherwise: yup.string().nullable().notRequired(),
  }),
  description: yup.string(),
  learningComponents: yup
    .array()
    .of(yup.object())
    .min(1, 'Debe seleccionar al menos un componente de aprendizaje'),
  subjectsToPP: yup
    .number()
    .integer()
    .required('La cantidad de materias para prácticas preprofesionales es requerida.')
    .min(0, 'La cantidad de materias no puede ser menor a cero')
    .max(9999, 'Valor no válido, límite excedido')
    .typeError('Ingrese un número válido')
    .nullable(),
  maxFailedSubject: yup
    .number()
    .integer()
    .required('La cantidad máxima de materias reprobadas es requerida.')
    .min(0, 'La cantidad de materias no puede ser menor a cero')
    .max(9999, 'Valor no válido, límite excedido')
    .typeError('Ingrese un número válido')
    .nullable(),
  percentage_titulation1: yup
    .number()
    .integer()
    .required('Porcentaje de titulación 1 es requerido.')
    .min(0, 'Porcentaje de titulación 1 no puede ser menor a cero')
    .max(100, 'Valor no válido, límite excedido (100%).')
    .typeError('Ingrese un número válido')
    .nullable(),
  percentage_titulation2: yup
    .number()
    .integer()
    .required('Porcentaje de titulación 2 es requerido.')
    .min(0, 'Porcentaje de titulación 2 no puede ser menor a cero')
    .max(100, 'Valor no válido, límite excedido (100%).')
    .typeError('Ingrese un número válido')
    .nullable(),
  offer_id: yup.string().nullable(),
  vigencyDate: yup
    .date()
    .nullable()
    .when('offer_id', {
      is: (value: string) => value === '3',
      then: yup
        .date()
        .required('La fecha de vigencia es requerida.')
        .typeError('La fecha de vigencia es requerida.'),
    }),
  cacesResolutionDate: yup
    .date()
    .nullable()
    .when('offer_id', {
      is: (value: string) => value === '3',
      then: yup
        .date()
        .required('La fecha de aprobación del CACES es requerida.')
        .typeError('La fecha de aprobación del CACES es requerida.'),
    }),
  cacesResolutionNumber: yup
    .string()
    .nullable()
    .when('offer_id', {
      is: (value: string) => value === '3',
      then: yup
        .string()
        .required('El número de aprobación del CACES es requerido.')
        .typeError('El número de aprobación del CACES es requerido.'),
    }),

  // internalHomologationSubjects: yup.number().min(0).typeError('Ingrese un número válido'),
  // externalHomologationSubjects: yup.number().min(0).typeError('Ingrese un número válido'),
});

//validaciones para agregar materias a las mallas
export const MatterMeshValidationSchema = yup.object().shape({
  subject: yup.object().required('La materia es requerida').nullable(),
  gradeType: yup.object().required('El tipo de calificación es requerido').nullable(),
  renameSubject: yup.string(),
  amount_credits: yup
    .number()
    .integer()
    .when('isNewCurriculum', (isNewCurriculum, MeshValidationSchema) => {
      return !isNewCurriculum
        ? MeshValidationSchema.required('La cantidad de créditos por materia es requerida.')
            .min(1, 'La cantidad de créditos por materia no es válida.')
            .typeError('Ingrese un número válido')
        : MeshValidationSchema.nullable();
    }),
  // minGradeAp: yup
  //   .number()
  //   .required('La nota mínima es requerida')
  //   .min(0, 'La nota no puede ser menor que cero')
  //   .max(yup.ref('maxGrade'), 'La nota para aprobar no puede ser mayor que la nota máxima'),
  // minGrade: yup
  //   .number()
  //   .required('La nota mínima es requerida')
  //   .min(0, 'La nota no puede ser menor que cero')
  //   .max(yup.ref('maxGrade'), 'La nota mínima no puede ser mayor que la nota máxima'),
  // maxGrade: yup.number().required('La nota mínima es requerida'),
  // numFouls: yup
  //   .number()
  //   .required('El número de faltas es requerido')
  //   .min(0, 'El número de faltas no puede ser menor que cero')
  //   .integer('Ingrese un número entero'),
  symbology: yup.object().required('La simblogía de la materia es requerida').nullable(),
  prerequisites: yup.array().of(yup.object()).nullable(),
  status: yup.object().required('El estado es requerido').nullable(),
  subjectIntegrated: yup.boolean(),
  learningComponents: yup
    .array()
    .of(
      yup.object().shape({
        label: yup.string(),
        components_id: yup.number(),
        lea_workload: yup.number().min(0, 'Ingrese un valor positivo').nullable(),
      })
    )
    .test(
      'lcmax',
      'La suma de los valores de los componentes de aprendizaje debe ser igual al número de horas',
      (value, context: any) => {
        const total = value?.reduce((acc, item) => acc + (item.lea_workload || 0), 0) || 0;

        return total === context.parent.amount_credits;
      }
    ),
});

//validaciones del autocomplete de las dependencias
export const ValidationSchemaDepen = yup.object().shape({
  matterMesh: yup.number().required('Dependencias Requeridas').min(1, 'Dependencias Requeridas'),
});

export const InternalHomologationSubjectSchema = yup.object().shape({
  subjectCurriculumCurrentId: yup.string().required('Valor requerido'),
  carrerId: yup.string().required('Valor requerido'),
  meshId: yup.string().required('Valor requerido'),
  subjectCurriculumNewId: yup.string().required('Valor requerido'),
  relation: yup.string().required('Valor requerido'),
  comments: yup.string(),
});
