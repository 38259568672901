// import { useEffect } from 'react';

import { useHistory, useLocation } from 'react-router-dom';
import { Grid, Typography, IconButton, useTheme } from '@material-ui/core';
import { IconArrowNarrowLeft } from '@tabler/icons';

import MainCard from '../../../shared/components/cards/MainCard';
import AreaForm from '../components/AreaForm';
import { useAddAreaMutation } from '../slices/areasApiSlice';
import { AreaPayload } from '../interfaces/payload.interface';
import { useTranslation } from 'react-i18next';
// import DeleteRoleDialog from '../components/DeleteRoleDialog';

// import UserProfilesTable from '../components/UserProfilesTable';
// import { renderSkeletonTable } from '../../../shared/helpers/render';

const AddAreaScreen = () => {
  // const { roleIdToDelete } = useAppSelector((state) => state.roles);
  const { t } = useTranslation();
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();

  const [addArea, { isLoading, isError, error, isSuccess }] = useAddAreaMutation();

  const addAreaHandler = async (values: AreaPayload) => {
    await addArea({ areaPayload: values });
  };

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid item xs={12} sx={{ mb: 3 }}>
        <Grid item container alignItems="center" gap={1}>
          <IconButton onClick={() => history.push(`/${location.pathname.split('/')[1]}`)}>
            <IconArrowNarrowLeft size={30} color={theme.palette.text.dark} />
          </IconButton>
          <Typography variant="h2">{t('academic.areas.add.title')}</Typography>
        </Grid>
      </Grid>

      <Grid item xs={12} md={8} lg={6} sx={{ mt: 2 }}>
        <AreaForm
          states={{ isLoading, isError, error, isSuccess }}
          submitRequestHandler={addAreaHandler}
        />
      </Grid>
      {/* // */}

      {/* {Boolean(areasList.length) ? <UserProfilesTable /> : renderSkeletonTable(5, 5)} */}
      {/* {roleIdToDelete && <DeleteRoleDialog />} */}
    </Grid>
  );
};

export default AddAreaScreen;
