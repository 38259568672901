import { IconNotes, IconNotebook, IconFileAnalytics } from '@tabler/icons';
// import MeetingRoomOutlinedIcon from '@material-ui/icons/MeetingRoomOutlined';
// import { UserCircle, ClockAfternoon, UsersThree, ChalkboardSimple, BookOpen } from 'phosphor-react';
const icons = {
  IconNotes,
  IconNotebook,
  IconFileAnalytics,
};

export const shared = [
  // {
  //   id: 'informacion-institucional',
  //   title: 'Información Institucional',
  //   type: 'item',
  //   url: '/informacion-institucional',
  //   icon: icons['IconNotes'],
  //   permissionKey: 'shared',
  // },
  // {
  //   id: 'terminos-condiciones',
  //   title: 'Términos y Condiciones',
  //   type: 'item',
  //   url: '/terminos-condiciones',
  //   icon: icons['IconClipboard'],
  //   permissionKey: 'shared',
  // },
  // {
  //   id: 'biblioteca',
  //   title: 'Biblioteca',
  //   menuId: 'students.menu.library',
  //   type: 'item',
  //   // url: 'https://biblio.ecotec.edu.ec/apps',
  //   url: '/biblioteca/inicio',
  //   icon: icons['IconNotebook'],
  // },
  {
    id: 'reportes',
    title: 'Reportes',
    menuId: 'academic.menu.reports',
    type: 'item',
    url: '/reportes',
    permissionKey: 'report-dashboard',
    icon: icons['IconFileAnalytics'],
  },
  {
    id: 'informacion-institucional',
    title: 'Información institucional',
    type: 'item',
    url: '/informacion-institucional',
    icon: icons['IconNotes'],
    permissionKey: 'shared',
  },
];
