export const subPermissionKeys = {
  groupKey: 'periods',
  read: 'periods-listar-periodos',
  create: 'periods-crear-periodo',
  edit: 'periods-actualizar-periodo',
  delete: 'periods-borrar-periodo',
  hPerPeriod: 'periods-listar-horarios-por-periodo',
  getPeriodsTypes: 'typePeriods-listar-tiposPeriodos',
};

export const subPermissionKeys2 = {
  groupKey: 'periodStages',
  read: 'periodStages-listar-periodoEtapa',
  create: 'periodStages-crear-periodoEtapa',
  edit: 'periodStages-actualizar-periodoEtapa',
  delete: 'periodStages-borrar-periodoEtapa',
  getOne: 'periodStages-obtener-periodoEtapa',
};
