// material ui
import { useTheme, Box, Divider, Grid, List, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// project imports
import MinimalLayout from '../layout/MinimalLayout';
import ProfileItem from '../components/cards/ProfileItem';

// assets
import SubCard from '../components/cards/SubCard';

//redux
import { useAppDispatch, useAppSelector } from '../../../hooks/useRedux';
import NavMotion from '../layout/NavMotion';
import DialogAlert from '../components/dialogs/DialogAlert';

import { fireDialog } from '../reducers/accessReducer';
import { Profile } from '../../../types';
import Logo from '../components/Logo';
import LogoERP from '../components/LogoERP';
import { useTranslation } from 'react-i18next';

// style constant
const useStyles = makeStyles({
  boxContainer: {
    minHeight: '86vh',
    display: 'flex',
    alignItems: 'center',
    // padding: '32px',
  },
  cardContainer: {
    width: '90%',
    maxWidth: 600,
    padding: 0,
    '&:last-child': {
      padding: 0,
    },
  },
});

/**
 * Si hay un solo perfil ya esta validado en PublicRoute.tsx
 * @returns
 */
const ProfilesScreen = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { profiles, errorDialogOpen } = useAppSelector((state) => state.access);

  return (
    <MinimalLayout>
      <NavMotion>
        <Grid container sx={{ backgroundColor: theme.palette.primary.light, height: '100vh' }}>
          <Grid item container xs={12} alignItems="center" sx={{ pl: 3 }}>
            {/* <img
              src={`${baseUrl}/show?name=${localStorage.getItem('logoPath')}`}
              alt={`logo ${tenant}`}
              width="100px"
              height="32px"
            /> */}
            <LogoERP />
          </Grid>
          <Grid item container justifyContent="center" alignItems="center" xs={12} height="80vh">
            <Grid item container direction="column" alignItems="center" xs={10}>
              <Grid
                item
                container
                justifyContent="center"
                xs={12}
                mb={1}
                sx={{
                  width: '140px',
                }}
              >
                <Logo />
              </Grid>
              <Grid item>
                <Typography variant="h2" color="primary" mb={3}>
                  {t('shared.profiles.select')}
                </Typography>
              </Grid>
              <Grid item>
                <SubCard content={false} className={classes.cardContainer}>
                  <List sx={{ p: 0 }}>
                    {Object.keys(profiles).map((key, index) => {
                      const profile: { [key: string]: Profile } = { [`${key}`]: profiles[key] };

                      return (
                        <Box key={profile[`${key}`].profileId}>
                          <ProfileItem profile={profile} />
                          {Object.keys(profiles).length - 1 !== index ? <Divider /> : null}
                        </Box>
                      );
                    })}
                  </List>
                </SubCard>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            sx={{
              pl: 3,
              pr: '3rem',
            }}
            justifyContent="space-between"
          >
            <Typography variant="body1" sx={{ fontWeight: '400' }}>
              {t('utils.developed_by')} {new Date().getFullYear()} ©
            </Typography>
            <Typography variant="body1" sx={{ fontWeight: '400' }}>
              {process.env.REACT_APP_APP_NAME} {t('utils.version')} {process.env.REACT_APP_VERSION}
            </Typography>
          </Grid>
        </Grid>
      </NavMotion>

      <DialogAlert open={errorDialogOpen} onClose={() => dispatch(fireDialog(false))} />
    </MinimalLayout>
  );
};

export default ProfilesScreen;
