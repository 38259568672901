import { Box, CircularProgress, Grid } from '@material-ui/core';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../../../hooks/useRedux';
import ErrorAlert from '../../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../../shared/components/alerts/SuccessAlert';
import {
  useGetPersonCollaboratorQuery,
  useUpdatePersonCollaboratorMutation,
} from '../../slices/collaboratorsApiSlice';

import { CollaboratorsStepper, FormikStep } from '../CollaboratorsStepper';
import { format } from 'date-fns';
import PersonalEditDataStep from './PersonalEditDataStep';
import ComplementaryDataEdit from './ComplementaryDataEdit';
import DesabilitiesDataEdit from './DesabilitiesDataEdit';
import {
  ComplementaryEditDataSchema,
  DisabilitiesEditSchema,
  PersonalEditDataSchema,
} from '../../constants/validationSchemas';
import { useCreatePersonRequisitionMutation } from '../../../../maintenance/requisition-teacher/slices/requisitionsApiSlice';

interface Props {
  add?: true;
}

const EditCollaboratorWizard = ({ add }: Props) => {
  const { currentProfile } = useAppSelector((state) => state.access);

  const { personId } = useParams<{ personId: string }>();

  const [showAlerts, setShowAlerts] = useState(false);

  const profileId = currentProfile!.profileId;

  const { isLoading, data } = useGetPersonCollaboratorQuery(
    {
      profileId: currentProfile?.profileId!,
      personId: Number(personId),
    },
    { refetchOnMountOrArgChange: 30, skip: add }
  );

  const [updatePerson, { isSuccess, isError, error }] = useUpdatePersonCollaboratorMutation();

  const [createPerson, { isSuccess: isSuccessCreate, isError: isErrorCreate, error: errorCreate }] =
    useCreatePersonRequisitionMutation();

  return (
    <>
      <Grid container>
        <Grid item>
          {(isError || isErrorCreate) && showAlerts && (
            <ErrorAlert
              message={(error || errorCreate) as string}
              handleDismiss={() => setShowAlerts(false)}
            />
          )}

          {(isSuccess || isSuccessCreate) && showAlerts && (
            <SuccessAlert
              message={
                add ? 'Ha sido creado correctamente.' : 'El registro ha sido editado correctamente.'
              }
              handleDismiss={() => setShowAlerts(false)}
            />
          )}
        </Grid>
      </Grid>
      {isLoading ? (
        <Grid container spacing={2} justifyContent="center">
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      ) : (
        <CollaboratorsStepper
          initialValues={{
            // Datos personales

            identificationTypeKeyword: data?.identification?.cat_keyword || '',
            identification: data?.pers_identification || '',
            firstName: data?.pers_firstname || '',
            secondName: data?.pers_secondname || '',
            lastName: data?.pers_first_lastname || '',
            secondLastName: data?.pers_second_lastname || '',
            gender: data?.pers_gender || '',
            birthDate: new Date(data?.pers_date_birth!) || null,
            homeAddress: data?.pers_phone_home || '',
            homePhone: data?.pers_phone_home || '',
            cellPhone: data?.pers_cell || '',
            cityKeyword: data?.city?.id || '',
            sectorKeyword: data?.sector?.id || null,
            currentCityKeyword: data?.current_city?.id || null,
            nacionalityKeyword: data?.nationality?.id || null,
            //si es casado
            civilStatusKeyword: data?.status_marital?.id || null,

            //datos complementarios
            proffesion: data?.pers_profession || '',
            studyReason: data?.pers_study_reason || '',
            hasVehicle: !!Number(data?.pers_has_vehicle) || false,
            taxPayersHousehold: data?.pers_num_taxpayers_household || 0,
            numChild: data?.pers_num_child || 0,
            viviendaKeyword: data?.living_place?.id || '',
            numBedrooms: data?.pers_num_bedrooms || 0,
            religionKeyword: data?.religion?.id || '',
            etnicKeyword: data?.ethnic?.id || '',
            //accesibilidad
            hasDisability: !!Number(data?.pers_has_disability) || false,
            numDisabilityCertificate: data?.pers_disability_identification || '',
            disabilityPercentage: data?.pers_disability_percent || '',
          }}
          onSubmit={async (values) => {
            !add
              ? await updatePerson({
                  profileId,
                  personId: Number(personId),
                  personPayload: {
                    // Datos personales
                    type_identification_keyword: values.identificationTypeKeyword,
                    pers_identification: values.identification,
                    pers_firstname: values.firstName,
                    pers_secondname: values.secondName,
                    pers_first_lastname: values.lastName,
                    pers_second_lastname: values.secondLastName,
                    pers_nationality_keyword: values.nacionalityKeyword,
                    city_id: values.cityKeyword,
                    current_city_id: values.currentCityKeyword,
                    pers_direction: values.homeAddress,
                    sector_keyword: values.sectorKeyword,
                    pers_gender: values.gender,
                    pers_cell: values.cellPhone,
                    pers_phone_home: values.homePhone,
                    pers_date_birth: format(values.birthDate!, 'yyyy-MM-dd'),
                    // Si es casado
                    status_marital_keyword: values.civilStatusKeyword,
                    // Complementary
                    pers_profession: values.proffesion,
                    pers_study_reason: values.studyReason,
                    pers_has_vehicle: values.hasVehicle,
                    pers_num_taxpayers_household: values.taxPayersHousehold,
                    pers_num_child: values.numChild,
                    vivienda_keyword: values.viviendaKeyword,
                    pers_num_bedrooms: values.numBedrooms,
                    type_religion_keyword: values.religionKeyword,
                    ethnic_keyword: values.etnicKeyword,
                    // Accesibilidad
                    pers_has_disability: values.hasDisability,
                    pers_disability_identification: values.numDisabilityCertificate,
                    pers_disability_percent: values.disabilityPercentage,
                  },
                })
              : await createPerson({
                  profileId,
                  createPersonPayload: {
                    // Datos personales
                    type_identification_keyword: values.identificationTypeKeyword,
                    pers_identification: values.identification,
                    pers_firstname: values.firstName,
                    pers_secondname: values.secondName,
                    pers_first_lastname: values.lastName,
                    pers_second_lastname: values.secondLastName,
                    pers_nationality_id: values.nacionalityKeyword,
                    city_id: values.cityKeyword,
                    current_city_id: values.currentCityKeyword,
                    pers_direction: values.homeAddress,
                    sector_id: values.sectorKeyword,
                    pers_gender: values.gender,
                    pers_cell: values.cellPhone,
                    pers_phone_home: values.homePhone,
                    pers_date_birth: format(values.birthDate!, 'yyyy-MM-dd'),
                    // Si es casado
                    status_marital_id: values.civilStatusKeyword,
                    // Complementary
                    pers_profession: values.proffesion,
                    pers_study_reason: values.studyReason,
                    pers_has_vehicle: values.hasVehicle,
                    pers_num_taxpayers_household: values.taxPayersHousehold,
                    pers_num_child: values.numChild,
                    vivienda_id: values.viviendaKeyword,
                    pers_num_bedrooms: values.numBedrooms,
                    type_religion_id: values.religionKeyword,
                    ethnic_id: values.etnicKeyword,
                    // Accesibilidad
                    pers_has_disability: values.hasDisability,
                    pers_disability_identification: values.numDisabilityCertificate,
                    pers_disability_percent: values.disabilityPercentage,
                  },
                });

            setShowAlerts(true);
          }}
        >
          <FormikStep label="Información Personal" validationSchema={PersonalEditDataSchema}>
            <Box paddingBottom={3} paddingTop={3}>
              <PersonalEditDataStep />
            </Box>
          </FormikStep>
          <FormikStep label="Accesibilidad" validationSchema={DisabilitiesEditSchema}>
            <Box paddingBottom={2} paddingTop={2}>
              <DesabilitiesDataEdit />
            </Box>
          </FormikStep>
          <FormikStep label="Datos complementarios" validationSchema={ComplementaryEditDataSchema}>
            <Box paddingBottom={3} paddingTop={3}>
              <ComplementaryDataEdit />
            </Box>
          </FormikStep>
        </CollaboratorsStepper>
      )}
    </>
  );
};

export default EditCollaboratorWizard;
