import { Autocomplete, Grid, Stack, TextField } from '@material-ui/core';
import { useState } from 'react';
import { useAppSelector } from '../../../../hooks/useRedux';
import CustomDialog from '../../../shared/components/dialogs/CustomDialog';
import Chip from '../../../shared/components/extended/Chip';
import DeleteButton from '../../../shared/components/tables/buttons/DeleteButton';
import SearchPaginatedTable from '../../../shared/components/tables/SearchPaginatedTable';
import { useGetAcademicRequestsQuery } from '../../../students/academic-requests/slices/academicRequestsApiSlice';
import RemoveAppForm from './RemoveAppForm';

const ApplicationsStatus = () => {
  const { currentProfile } = useAppSelector((state) => state.access);
  const { userInfo } = useAppSelector((state) => state.auth);

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(5);
  const [search, setSearch] = useState('');
  const [appId, setAppId] = useState<number | undefined>();

  const { data, isLoading, isFetching, error } = useGetAcademicRequestsQuery({
    userId: String(userInfo.id!),
    profileId: currentProfile?.profileId!,
    page,
    perPage,
    search,
  });

  const options = [
    'Solicitud Vacaciones',
    'Solicitud Happy Birthday',
    'Solicitud Anticipo',
    'Solicitud Personal Day',
  ];

  return (
    <Grid container>
      <Grid item xs={12} md={5} mb={4}>
        <Autocomplete
          options={options}
          onChange={(_, val) => {
            if (val) {
              setSearch(val);
            } else {
              setSearch('');
            }
          }}
          renderInput={(props) => (
            <TextField {...props} placeholder="Tipo" label="Tipo de solicitud" fullWidth />
          )}
        />
      </Grid>
      <SearchPaginatedTable
        data={
          data?.data?.map((el, index) => ({
            num: index + data.from,
            code: el.app_code,
            type: el.type_application,
            date: el.app_register_date,
            status: el.current_status,
            options: '',
          })) || []
        }
        headers={{
          num: 'Nº',
          code: 'Código',
          type: 'Tipo',
          date: 'Fecha de envio',
          status: 'Estado',
          options: 'Opciones',
        }}
        error={error}
        isFetching={isFetching}
        isLoading={isLoading}
        keyExtractor={(row) => String(row)}
        numHeader={2}
        page={page}
        setPage={setPage}
        perPage={perPage}
        setPerPage={setPerPage}
        search={search}
        searchPlacehoder=""
        setSearch={setSearch}
        total={data?.total!}
        customDataCellsProperties={{
          num: { align: 'center' },
          code: { align: 'center' },
          type: { align: 'center' },
          date: { align: 'center' },
          status: { align: 'center' },
        }}
        customRenderers={{
          status: (row) => (
            <Chip
              label={row.status}
              size="small"
              chipcolor={
                row.status === 'Enviado' ? 'info' : row.status === 'Rechazado' ? 'error' : 'success'
              }
            />
          ),
          options: (row) => (
            <Stack alignItems="center">
              {row.status.toLowerCase() === 'aprobado' ||
              row.status.toLowerCase() === 'rechazado' ? null : (
                <DeleteButton
                  handleDelete={() => {
                    setAppId(
                      data?.data?.find((el, index) => index === Number(row.num) - Number(data.from))
                        ?.id!
                    );
                  }}
                />
              )}
            </Stack>
          ),
        }}
        showFilter={false}
      />
      {appId && (
        <CustomDialog
          actionHandler={() => {}}
          isError={false}
          isLoading={false}
          isSuccess={false}
          onClose={() => setAppId(undefined)}
          open={Boolean(appId)}
          hideFooter={true}
          title="¿Eliminar esta solicitud?"
        >
          <RemoveAppForm
            appId={appId}
            handleClose={() => {
              setAppId(undefined);
            }}
          />
        </CustomDialog>
      )}
    </Grid>
  );
};

export default ApplicationsStatus;
