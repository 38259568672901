import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISearchTable } from '../../../../../shared/interfaces/paginatedTable.interface';
import { IOptionsTemplate, OptionsTemplateState } from '../interfaces/optionsTemplate.interface';
import { RepresentativeScaleData } from '../interfaces/representativeScale.interface';

const initialState: OptionsTemplateState = {
  optionsTemplateList: [],
  represensativeScaleList: [],
  optionsTemplateIdSelected: null,
  represensativeScaleIdSelected: null,
  optionsTemplateIdToDelete: null,
  optionsTemplatesDeleteModalOpen: false,
  representativeDeleteModalOpen: false,
  optionsTemplateSideDialogOpen: false,
  optionsTemplateTable: {
    page: 1,
    perPage: 5,
    search: '',
  },
};

export const optionsTemplateSlice = createSlice({
  name: 'optionsTemplate',
  initialState,
  reducers: {
    setOptionsTemplateList: (state, action: PayloadAction<IOptionsTemplate[]>) => {
      state.optionsTemplateList = action.payload;
    },
    setRepresentativeScaleList: (state, action: PayloadAction<RepresentativeScaleData[]>) => {
      state.represensativeScaleList = action.payload;
    },
    setOptionsTemplateSideDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.optionsTemplateSideDialogOpen = action.payload;
    },
    setOptionsTemplateIdSelected: (state, action: PayloadAction<string | null>) => {
      state.optionsTemplateIdSelected = action.payload;
    },
    setRepresensativeScaleIdSelected: (state, action: PayloadAction<string | null>) => {
      state.represensativeScaleIdSelected = action.payload;
    },
    setOptionsTemplateIdToDelete: (state, action: PayloadAction<string | null>) => {
      state.optionsTemplateIdToDelete = action.payload;
    },
    setOptionsTemplatesDeleteModalOpen: (state, action: PayloadAction<boolean>) => {
      state.optionsTemplatesDeleteModalOpen = action.payload;
    },
    setOptionsTemplateTable: (state, action: PayloadAction<ISearchTable>) => {
      state.optionsTemplateTable = {
        ...state.optionsTemplateTable,
        ...action.payload,
      };
    },
    setRepresentativeDeleteModalOpen: (state, action: PayloadAction<boolean>) => {
      state.representativeDeleteModalOpen = action.payload;
    },
  },
});

export const {
  setOptionsTemplateList,
  setOptionsTemplateSideDialogOpen,
  setOptionsTemplateIdSelected,
  setOptionsTemplateIdToDelete,
  setOptionsTemplatesDeleteModalOpen,
  setOptionsTemplateTable,
  setRepresentativeScaleList,
  setRepresensativeScaleIdSelected,
  setRepresentativeDeleteModalOpen,
} = optionsTemplateSlice.actions;
