import { middlewareApi } from '../../../middleware/middlewareApi';
import { mainApi } from '../../../../api/mainApi';
import { CategoriesResponse, Category, CategoryPayload } from '../interfaces/category.interface';
import { AccessState } from '../../../../types';

export const CategoriesTags = middlewareApi.enhanceEndpoints({
  addTagTypes: ['Categories'],
});

export const categoryApi = CategoriesTags.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * @GET all categories
     */
    getCategories: builder.query<
      CategoriesResponse,
      {
        profileId: number;
        page?: number;
        size?: number;
        sort?: string;
        type_sort: string;
        search: string;
      }
    >({
      queryFn: async ({ profileId, page, size, sort, type_sort, search }, { dispatch }) => {
        try {
          const { data } = await mainApi.get<CategoriesResponse>(`/categories`, {
            params: {
              user_profile_id: profileId,
              page: page,
              size: size,
              sort: sort,
              type_sort: type_sort,
              search: search,
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'Categories', id } as const)),
              { type: 'Categories', id: 'LIST' },
            ]
          : [{ type: 'Categories', id: 'LIST' }],
    }),

    /**
     * Get all categories
     */
    getAllAvailablesCategories: builder.query<
      Category[],
      {
        profileId: number;
        page?: number;
        size?: number;
        sort?: string;
        dataAll?: string;
        type_sort: string;
        search: string;
      }
    >({
      queryFn: async (
        { profileId, page, size, sort, type_sort, search, dataAll },
        { dispatch }
      ) => {
        try {
          const { data } = await mainApi.get<Category[]>(`/categories`, {
            params: {
              user_profile_id: profileId,
              page: page,
              data: dataAll,
              size: size,
              sort: sort,
              type_sort: type_sort,
              search: search,
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),
    /**
     * @GET category by ID
     */
    getCategoryById: builder.query<Category, { profileId?: number; categoryId: string }>({
      queryFn: async ({ profileId, categoryId }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = profileId || currentProfile?.profileId;

          const { data } = await mainApi.get<Category>(`/categories/${categoryId}`, {
            params: {
              user_profile_id,
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    /**
     * @GET all categories, param productCategories for filter
     */
    getAllCategories: builder.query<
      Category[],
      {
        profileId?: number;
        productCategories?: boolean;
      }
    >({
      queryFn: async ({ profileId, productCategories }, { getState }) => {
        try {
          const {
            access: { currentProfile },
          } = getState() as { access: AccessState };

          const user_profile_id = currentProfile?.profileId;

          const { data } = await mainApi.get<Category[]>(
            `/categories${productCategories ? '/product-categories' : ''}`,
            {
              params: {
                ...(profileId ? { user_profile_id: profileId } : { user_profile_id }),
                data: 'all',
              },
            }
          );

          return { data: Array.isArray(data) ? data : [] };
        } catch (error: any) {
          return { error };
        }
        //revisar necesidad de agregar tags
      },
    }),

    /**
     * @POST create categories
     */
    addCategory: builder.mutation<CategoryPayload, { categoryPayload: CategoryPayload }>({
      queryFn: async ({ categoryPayload }, { dispatch }) => {
        try {
          const { data } = await mainApi.post<CategoryPayload>(`/categories`, {
            ...categoryPayload,
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'Categories', id: 'LIST' }],
    }),
    /**
     * @PUT update categories
     */
    updateCategory: builder.mutation<
      CategoryPayload,
      { categoryId: string; categoryPayload: CategoryPayload }
    >({
      queryFn: async ({ categoryId, categoryPayload }, { dispatch }) => {
        try {
          const { data } = await mainApi.put<CategoryPayload>(`/categories/${categoryId}`, {
            ...categoryPayload,
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'Categories', id: 'LIST' }],
    }),
    /**
     * @DELETE categorie
     */
    deleteCategory: builder.mutation<{}, { profileId: number; categoryId: string }>({
      queryFn: async ({ profileId, categoryId }) => {
        try {
          const { data } = await mainApi.delete(`/categories/${categoryId}`, {
            data: {
              user_profile_id: profileId,
            },
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'Categories', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetCategoriesQuery,
  useGetCategoryByIdQuery,
  useLazyGetCategoryByIdQuery,
  useGetAllAvailablesCategoriesQuery,
  useAddCategoryMutation,
  useUpdateCategoryMutation,
  useDeleteCategoryMutation,

  useGetAllCategoriesQuery,
} = categoryApi;
