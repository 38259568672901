import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';

import ErrorAlert from '../alerts/ErrorAlert';
import SubCard from '../cards/SubCard';
import IconTelework from '../illustrations/IconTelework';

interface Props {
  title?: string;
  message?: string;
  messageList?: Array<any>;
}

const ImportantAnnouncement = ({ message, title, messageList }: Props) => {
  const { t } = useTranslation();

  return (
    <Grid container item mt={3} justifyContent="center">
      <SubCard title={title || 'Aviso importante'} darkTitle={true}>
        <Grid item>
          {!!messageList?.length ? (
            messageList?.map((value) => <ErrorAlert type="fixed" message={value} />)
          ) : (
            <ErrorAlert
              type="fixed"
              message={message ? t(`${message}`) : t('students.applications.error_message')}
            />
          )}
        </Grid>
        <Box>
          <Grid container justifyContent="center" alignContent="center">
            <Grid container justifyContent="center" item xs={8} md={6} spacing={2}>
              <Grid container item xs={12} justifyContent="center">
                <IconTelework size={300} />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </SubCard>
    </Grid>
  );
};

export default ImportantAnnouncement;
