import { Button, Dialog, Grid, Typography } from '@material-ui/core';
import { format, parseISO } from 'date-fns';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import ErrorAlert from '../../../../shared/components/alerts/ErrorAlert';
import SuccessAlert from '../../../../shared/components/alerts/SuccessAlert';
import LoadingButton from '../../../../shared/components/buttons/LoadingButton';
import LocalizedDatePicker from '../../../../shared/components/controlled/LocalizedDatePicker';
import InputLabel from '../../../../shared/components/forms/InputLabel';
import { useProrogueDebtMutation } from '../../slices/clientApiSlice';
import { setDebtDateSelected, setDebtIdSelected } from '../../slices/clientSlice';

const ProrrogueDialog = () => {
  const dispatch = useAppDispatch();

  const [showAlert, setShowAlert] = useState(false);
  const [prorogue, { isLoading, isError, isSuccess, error }] = useProrogueDebtMutation();

  const handleClose = () => {
    dispatch(setDebtIdSelected(null));
    dispatch(setDebtDateSelected(null));
  };

  const { currentProfile } = useAppSelector((state) => state.access);
  const { debtDateSelected, debtIdSelected } = useAppSelector((state) => state.client);

  const { handleSubmit, values, setFieldValue } = useFormik({
    initialValues: {
      date: debtDateSelected ? parseISO(debtDateSelected) : null,
    },
    onSubmit: async (values) => {
      await prorogue({
        profileId: currentProfile?.profileId!,
        feeId: debtIdSelected!,
        date: format(values.date!, 'yyyy-MM-dd'),
      });

      setShowAlert(true);
      setTimeout(() => {
        handleClose();
      }, 1000);
    },
  });

  return (
    <Dialog
      onClose={handleClose}
      open
      sx={{
        '& .MuiDialog-paper': {
          padding: 2,
          width: 500,
        },
      }}
    >
      {showAlert && isSuccess && (
        <SuccessAlert
          handleDismiss={() => setShowAlert(false)}
          message="La fecha de vencimiento se ha cambiado correctamente"
        />
      )}
      {showAlert && isError && (
        <ErrorAlert handleDismiss={() => setShowAlert(false)} message={error as string} />
      )}

      <form onSubmit={handleSubmit}>
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item xs={12}>
            <Typography variant="h3" align="center" color="primary">
              Cambiar fecha de vencimiento
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Fecha de vencimiento</InputLabel>
            <LocalizedDatePicker
              value={values.date}
              onChange={(date) => {
                setFieldValue('date', date);
              }}
              minDate={new Date()}
            />
          </Grid>
          <Grid item>
            <Button color="secondary" variant="text" onClick={handleClose}>
              Cancelar
            </Button>
          </Grid>
          <Grid item>
            <LoadingButton type="submit" isLoading={isLoading} />
          </Grid>
        </Grid>
      </form>
    </Dialog>
  );
};

export default ProrrogueDialog;
