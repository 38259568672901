import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
  Typography,
  useTheme,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { Person } from '../../interfaces/tasks.interface';

interface Props {
  group: Person[];
  groupId: string;
}

const TaskItemHeaderGroups = ({ groupId, group }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Accordion
      sx={{
        padding: 0,
      }}
    >
      <AccordionSummary
        sx={{
          padding: 0,
          margin: 0,
          height: '30px',
          minHeight: '30px',
          '&.Mui-expanded': {
            minHeight: '30px',
          },
        }}
        expandIcon={<ExpandMore />}
      >
        <Typography
          variant="h6"
          sx={{
            fontSize: '0.7rem',
            fontWeight: 'bold',
          }}
        >
          {t('students.virtual_classroom.task.card.groups')}: {groupId}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          px: 0,
        }}
      >
        <Stack>
          {group.map((person) => (
            <Box
              key={person.id}
              sx={{
                display: 'flex',
                alignItems: 'center',
                my: 1,
                padding: 0.75,
                borderRadius: '6px',
                backgroundColor: theme.palette.contentBg.main,
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontSize: '0.7rem',
                  fontWeight: 'bold',
                }}
              >
                {`${person.pers_firstname} ${person.pers_secondname} ${person.pers_first_lastname} ${person.pers_second_lastname}`}
              </Typography>
            </Box>
          ))}
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

export default TaskItemHeaderGroups;
