import { mainApi } from '../../../../api/mainApi';
import { middlewareApi } from '../../../middleware/middlewareApi';
import {
  ElectronicDocumentPayload,
  ElectronicDocumentResponse,
} from '../interfaces/electronicDocuments.interfaces';

export const electronicDocumentTags = middlewareApi.enhanceEndpoints({
  addTagTypes: ['electronicDocuments'],
});
export const electronicDocumentApi = electronicDocumentTags.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * @GET electronic documents by filters
     */
    electronicDocumentsFilter: builder.query<
      ElectronicDocumentResponse,
      {
        page: number;
        perPage: number;
        search: string;
        sort?: string;
        type_sort?: string;
        electronicDocumentPayload: Partial<ElectronicDocumentPayload>;
      }
    >({
      queryFn: async (
        { page, perPage, search, electronicDocumentPayload, sort, type_sort },
        { dispatch, getState }
      ) => {
        try {
          const {
            access: {
              currentProfile: { profileId },
            },
          } = getState() as { access: { currentProfile: { profileId: number } } };

          const { data } = await mainApi.get<ElectronicDocumentResponse>('/sri-documents', {
            params: {
              user_profile_id: profileId,
              search,
              page,
              size: perPage,
              sort: sort || 'id',
              type_sort: type_sort || 'asc',
              ...electronicDocumentPayload,
            },
          });

          //dispatch(setCurriculumList(paginatedInfo.data));

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ id }) => ({ type: 'electronicDocuments', id } as const)),
              { type: 'electronicDocuments', id: 'LIST' },
            ]
          : [{ type: 'electronicDocuments', id: 'LIST' }],
    }),

    /**
     * @PUT update electronic document state
     */
    updateElectronicDocumentState: builder.mutation<any, { sriDocumentId: number }>({
      queryFn: async ({ sriDocumentId }, { getState }) => {
        try {
          const {
            access: {
              currentProfile: { profileId },
            },
          } = getState() as { access: { currentProfile: { profileId: number } } };

          const { data } = await mainApi.put<any>(`/sri-documents-process/${sriDocumentId}`, {
            user_profile_id: profileId,
          });

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'electronicDocuments', id: 'LIST' }],
    }),

    /**
     * @GET electronic documents authorized
     */
    getElectronicDocumentsAuthorized: builder.query<
      ElectronicDocumentResponse,
      {
        page: number;
        perPage: number;
        search?: string;

        type_document?: number;
        no_document?: string;
        status_payment?: string;
      }
    >({
      queryFn: async (
        { page, perPage, search, type_document = 409, no_document, status_payment },
        { getState }
      ) => {
        try {
          const {
            access: {
              currentProfile: { profileId },
            },
          } = getState() as { access: { currentProfile: { profileId: number } } };

          const { data } = await mainApi.get<ElectronicDocumentResponse>(
            '/sri-documents-availables',
            {
              params: {
                user_profile_id: profileId,
                ...(search && { search }),
                page,
                size: perPage,
                sort: 'id',
                type_sort: 'asc',

                type_document,
                ...(no_document && { no_document }),
                ...(status_payment && { status_payment }),
              },
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: [{ type: 'electronicDocuments', id: 'LIST' }],
    }),

    /**
     * @PUT resend electronic document
     */
    resendElectronicDocument: builder.query<any, { electronicDocumentId: number }>({
      queryFn: async ({ electronicDocumentId }, { getState }) => {
        try {
          const {
            access: {
              currentProfile: { profileId },
            },
          } = getState() as { access: { currentProfile: { profileId: number } } };

          const { data } = await mainApi.get<any>(`/resend-mail-document/${electronicDocumentId}`, {
            params: {
              user_profile_id: profileId,
            },
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    /**
     * @GET ride electronic document
     */
    getRIDE: builder.query<any, { sriCabecera: number }>({
      queryFn: async ({ sriCabecera }, { getState }) => {
        try {
          const {
            access: {
              currentProfile: { profileId },
            },
          } = getState() as { access: { currentProfile: { profileId: number } } };

          const { data } = await mainApi.get<any>(`/sri-documents-ride/${sriCabecera}`, {
            responseType: 'arraybuffer',
            headers: {
              Accept: 'application/pdf',
              'Content-Type': 'application/json',
            },
            params: {
              user_profile_id: profileId,
            },
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    /**
     * @GET ride electronic document
     */
    getXML: builder.query<any, { sriCabecera: number }>({
      queryFn: async ({ sriCabecera }, { getState }) => {
        try {
          const {
            access: {
              currentProfile: { profileId },
            },
          } = getState() as { access: { currentProfile: { profileId: number } } };

          const { data } = await mainApi.get<any>(`/sri-documents-xml/${sriCabecera}`, {
            responseType: 'arraybuffer',
            headers: {
              Accept: 'application/pdf',
              'Content-Type': 'application/json',
            },
            params: {
              user_profile_id: profileId,
            },
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    /**
     * @GET massive documents
     */
    getMassiveSriZip: builder.query<
      any,
      { type_document?: string; end_date: string; start_date: string }
    >({
      queryFn: async ({ type_document, start_date, end_date }, { getState }) => {
        try {
          const {
            access: {
              currentProfile: { profileId },
            },
          } = getState() as { access: { currentProfile: { profileId: number } } };

          const { data } = await mainApi.get<any>(`/sri-documents-download-massive-zip`, {
            responseType: 'arraybuffer',
            headers: {
              Accept: 'application/pdf',
              'Content-Type': 'application/json',
            },
            params: {
              user_profile_id: profileId,
              start_date,
              end_date,
              type_document,
            },
          });
          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    //
  }),
});
export const {
  useElectronicDocumentsFilterQuery,
  useUpdateElectronicDocumentStateMutation,
  useGetElectronicDocumentsAuthorizedQuery,
  useResendElectronicDocumentQuery,
  useGetRIDEQuery,
  useGetXMLQuery,

  useGetMassiveSriZipQuery,
  useLazyGetMassiveSriZipQuery,
} = electronicDocumentApi;
