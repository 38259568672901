import { Switch, Redirect } from 'react-router-dom';
import GuardRoute from '../../../../router/guards/GuardRoute';

import useGuard from '../hooks/useGuard';

import CoursesScreen from '../screens/CoursesScreen';
// import CourseDetailScreen from '../screens/CourseDetailScreen';

const CoursesRouter = () => {
  const { hasAccess } = useGuard();

  if (!hasAccess('read')) return <Redirect to="/dashboard" />;
  return (
    <Switch>
      <GuardRoute exact path="/administrar-cursos-profesor" component={CoursesScreen} />
    </Switch>
  );
};

export default CoursesRouter;
