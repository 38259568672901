import { Switch } from 'react-router-dom';
import PaymentsScreen from '../screens/PaymentsScreen';
import CheckoutScreen from '../screens/CheckoutScreen';
import GuardRoute from '../../../../router/guards/GuardRoute';

const PaymentsHistoryRouter = () => {
  return (
    <Switch>
      <GuardRoute exact path="/estado-de-cuenta" component={PaymentsScreen} />
      <GuardRoute exact path="/estado-de-cuenta/checkout" component={CheckoutScreen} />
    </Switch>
  );
};

export default PaymentsHistoryRouter;
