import { Switch, Redirect } from 'react-router-dom';

import { useAppSelector } from '../../../../hooks/useRedux';

//import { permissionKey, subPermissionKeys } from '../constants/permissions';
import GuardRoute from '../../../../router/guards/GuardRoute';

import LendingsScreen from '../screens/LendingsScreen';
import RequestedScreen from '../screens/RequestedScreen';
import AddLendScreen from '../screens/AddLendScreen';
import BookingScreen from '../screens/BookingScreen';
import LendingsDetailsScreen from '../screens/LendingsDetailsScreen';

const LendingsRouter = () => {
  const { currentModule } = useAppSelector((state) => state.access);

  const hasPermission = 'borrows' in currentModule?.permissions!;

  if (!hasPermission) return <Redirect to="/dashboard" />;

  return (
    <Switch>
      <GuardRoute
        exact
        path="/administrar-solicitados"
        component={RequestedScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.create}
      />
      <GuardRoute
        exact
        path="/administrar-prestamos"
        component={LendingsScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.create}
      />
      <GuardRoute
        exact
        path="/administrar-prestamos/detalle/:id"
        component={LendingsDetailsScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.create}
      />
      <GuardRoute
        exact
        path="/administrar-reservas"
        component={BookingScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.create}
      />
      <GuardRoute
        exact
        path="/administrar-prestamos/agregar"
        component={AddLendScreen}
        // permissionKey={permissionKey}
        // subPermissionKey={subPermissionKeys.create}
      />
      {/* 
            <GuardRoute
                exact
                path="/administrar-autores/editar"
                component={EditAuthorScreen}
                permissionKey={permissionKey}
                subPermissionKey={subPermissionKeys.create}
            /> */}
    </Switch>
  );
};

export default LendingsRouter;
