import { Dialog, DialogProps, Slide, SlideProps, useMediaQuery, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { forwardRef } from 'react';

import MoveZendeskStyles from '../helpers/MoveZendeskStyles';

const useStyles = makeStyles((theme) => ({
  userAddDialog: {
    '&>div:nth-child(3)': {
      justifyContent: 'flex-end',
      '&>div': {
        margin: '0px',
        borderRadius: '0px',
        maxWidth: '650px',
        maxHeight: '100%',
        '&>div.MuiDialogContent-root>div.scrollbar-container': {
          paddingLeft: '20px',
          overflow: 'hidden',
        },
      },
    },
  },
  paper: {
    minHeight: '100vh',
    width: '37%',
  },
  paperTable: {
    minHeight: '100vh',
    width: '50%!important',
  },
  movilPaper: {
    minHeight: '100vh',
    width: '100%',
  },
}));

// animation
const Transition = forwardRef(function Transition(props: SlideProps, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

interface Props extends DialogProps {
  isStyled?: boolean;
}
//

const ExtendedSideDialog = ({ isStyled = false, ...props }: Props) => {
  const { open, children } = props;

  const theme = useTheme();
  const classes = useStyles();

  const isSm = useMediaQuery(theme.breakpoints.down('md'));

  const styles = isStyled
    ? {
        className: classes.userAddDialog,
        classes: { paper: isSm ? classes.movilPaper : classes.paper },
        TransitionComponent: Transition,
      }
    : {};

  return (
    <Dialog {...styles} {...props}>
      {open && <MoveZendeskStyles />}
      {children}
    </Dialog>
  );
};

export default ExtendedSideDialog;
