import { useState } from 'react';

import { Box, Grid, Typography, useTheme } from '@material-ui/core';
import { Skeleton, Timeline } from '@material-ui/lab';
import { getMonth, getYear, parseISO } from 'date-fns';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../../hooks/useRedux';
import useGoToVirtualClassroom from '../../../students/academic-portfolio/hooks/useGoToVirtualClassroom';
import { scrollStyles } from '../../../students/dashboard/constants/styles';
import TimelineOnce from '../../virtual-classroom/dashboard/components/TimelineOnce';
import { useGetTeacherActivitiesNotPagedQuery } from '../slices/dashboardApiSlice';
import { useGoActivityMoodleMutation } from '../../../shared/slices/moodle';

export default function TimeLineActivities() {
  const theme = useTheme();
  const classes = scrollStyles();
  const { t } = useTranslation();

  const [courseId, setCourseId] = useState(0);
  const [activityId, setActivityId] = useState(0);

  const { userInfo } = useAppSelector((state) => state.auth);

  const setCourseData = (courseId: number, activityId: number) => {
    setCourseId(courseId);
    setActivityId(activityId);
  };

  const [getMoodleActivity, { isLoading: isLoadingMoodle }] = useGoActivityMoodleMutation();

  const { isFetching: isFetchingCourse } = useGoToVirtualClassroom(
    courseId,
    `actividades/${activityId}/detail`,
    true
  );

  const {
    userInfo: { collaboratorInfo },
  } = useAppSelector((state) => state.auth);

  const { data, isFetching } = useGetTeacherActivitiesNotPagedQuery(
    {
      collaboratorId: collaboratorInfo?.id || 0,
      isCalendar: true,
      month: getMonth(new Date()) + 1,
      year: getYear(new Date()),
    },
    { skip: !collaboratorInfo }
  );

  const goToActivity = async (
    courseId: number,
    activityId: number,
    instance?: number | null,
    modulename?: string | null
  ) => {
    setActivityId(activityId);

    if (instance && modulename) {
      try {
        const moodleActivity = await getMoodleActivity({
          instance,
          modulename,
          userId: userInfo?.id!,
        }).unwrap();

        window.open(moodleActivity);
      } finally {
        // catch (error) {
        // console.log('F');
        // }
        return;
      }
    }

    setCourseData(courseId, activityId);

    return;
  };

  return (
    <>
      <>
        {isFetching ? (
          <Skeleton
            variant="rectangular"
            sx={{
              width: '100%',
              borderRadius: 3,
              height: '100%',
            }}
          />
        ) : (
          <Box
            sx={{
              // border: `1px solid ${theme.palette.secondary.main}`,
              padding: 2,
              bgcolor: `${theme.palette.grey[200]}`,
              borderRadius: 3,
            }}
          >
            <Typography component="h3" variant="h3">
              {t('teachers.dashboard.activities_history')}
            </Typography>
            <Grid container pt={2}>
              <Grid
                item
                xs={12}
                sx={{
                  maxHeight: 720,
                  overflow: 'hidden',
                }}
              >
                <PerfectScrollbar component={'div'} className={classes.scrollRoot}>
                  <Timeline position="right">
                    {data?.map((item) => (
                      <TimelineOnce
                        Category={item?.type_activity?.cat_description!}
                        Descripcion={item?.act_description}
                        FechaInicio={parseISO(item.cact_start_date!)}
                        FechaEnd={
                          item?.cact_end_date
                            ? parseISO(item?.cact_end_date)
                            : parseISO(item.cact_start_date!)
                        }
                        isLoading={isFetchingCourse || isLoadingMoodle}
                        onClickHandler={() =>
                          goToActivity(
                            Number(item.course_id),
                            Number(item.activity_id),
                            item.instance,
                            item.modulename
                          )
                        }
                      />
                    ))}
                  </Timeline>
                </PerfectScrollbar>
              </Grid>
            </Grid>
          </Box>
        )}
      </>
    </>
  );
}
