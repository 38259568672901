import { mainApi } from '../../../../../../api/mainApi';
import { middlewareApi } from '../../../../../middleware/middlewareApi';
import { IResponse } from '../../../../../shared/interfaces/response.interface';
import { Evaluated } from '../interfaces/evaluated.interface';
import { LaunchingResultsData } from '../interfaces/lanzamientoResults.interface';
import { LaunchingData, LaunchingPayload } from '../interfaces/launchingList.interface';

export const launchingTags = middlewareApi.enhanceEndpoints({
  addTagTypes: ['LaunchingList'],
});

export const launchingApi = launchingTags.injectEndpoints({
  endpoints: (builder) => ({
    getLaunchingList: builder.query<
      IResponse<LaunchingData>,
      {
        page: number;
        perPage: number;
        search: string;
        sort?: string;
        typeSort?: string;
        start_date: string;
        end_date: string;
        status?: string;
        period_id?: number;
      }
    >({
      queryFn: async (
        { page, perPage, search, sort, typeSort, end_date, start_date, status, period_id },
        { dispatch }
      ) => {
        try {
          const { data } = await mainApi.get<IResponse<LaunchingData>>(
            '/eva/evaluations-releases',
            {
              params: {
                page,
                size: perPage,
                search,
                start_date,
                end_date,
                ...(sort && { sort }),
                ...(typeSort && { type_sort: typeSort }),
                ...(status && { status }),
                ...(period_id && { period_id }),
              },
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: [{ type: 'LaunchingList', id: 'LIST' }],
    }),
    getLaunchingResultsList: builder.query<
      IResponse<LaunchingResultsData>,
      {
        page: number;
        perPage: number;
        search: string;
        sort?: string;
        typeSort?: string;
        start_date: string;
        end_date: string;
        eva_type_id?: string;
        evaluated_id?: string | number;
        period_id?: string | number;
        education_level_id?: string | number;
        eva_value?: boolean;
      }
    >({
      queryFn: async (
        {
          page,
          perPage,
          search,
          sort,
          typeSort,
          end_date,
          start_date,
          eva_type_id,
          evaluated_id,
          eva_value,
          period_id,
          education_level_id,
        },
        { dispatch }
      ) => {
        try {
          const { data } = await mainApi.get<IResponse<LaunchingResultsData>>(
            '/eva/evaluations-releases/detail-evaluation-release',
            {
              params: {
                page,
                size: perPage,
                search,
                start_date,
                end_date,
                ...(sort && { sort }),
                ...(typeSort && { type_sort: typeSort }),
                ...(eva_type_id && { eva_type_id: eva_type_id }),
                ...(evaluated_id && { evaluated_id: evaluated_id }),
                ...(eva_value && { eva_value: eva_value }),
                ...(period_id && { period_id: period_id }),
                ...(education_level_id && { education_level_id: education_level_id }),
              },
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: [{ type: 'LaunchingList', id: 'LIST' }],
    }),

    getEvaluated: builder.query<
      Evaluated[],
      {
        id: number;
      }
    >({
      queryFn: async ({ id }) => {
        try {
          const { data } = await mainApi.get<Evaluated[]>(`/eva/evaluations/feature/${id}`);

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
    }),

    addLaunching: builder.mutation<IResponse<LaunchingData>, LaunchingPayload>({
      queryFn: async (payload) => {
        try {
          const { data } = await mainApi.post<IResponse<LaunchingData>>(
            '/eva/evaluations-releases',
            {
              ...payload,
            }
          );

          return { data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: 'LaunchingList', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetLaunchingListQuery,
  useAddLaunchingMutation,
  useLazyGetEvaluatedQuery,
  useGetLaunchingResultsListQuery,
} = launchingApi;
