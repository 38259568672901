import { useHistory, useParams } from 'react-router-dom';
import { Grid, IconButton, Typography, useTheme } from '@material-ui/core';
import { IconArrowNarrowLeft } from '@tabler/icons';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import StudentAsignedHistoryAnnouncement from '../components/StudentHistoryAnnouncements';
import MainCard from '../../../../shared/components/cards/MainCard';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingScreen from '../../../../shared/screens/LoadingScreen';
import { setStudentList } from '../slices/studentsAsignedSlice';
import { useLazyGetStudentAssignedQuery } from '../slices/studentsAsignedApiSlice';

const StudentAsignedHistoryAdsScreen = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const { studentId, collId } = useParams<{ studentId: string; collId: string }>();

  const { currentProfile } = useAppSelector((state) => state.access);

  const { studentSelected, studentsList } = useAppSelector((state) => state.studentsAsigned);

  const student = studentsList.find(
    (value) =>
      Number(value.user.user_id) === studentSelected ||
      Number(value.user.user_id) === Number(studentId)
  );

  const [getStudent] = useLazyGetStudentAssignedQuery();

  useEffect(() => {
    if (studentId && studentsList.length === 0) {
      getStudent({
        profileId: currentProfile?.profileId!,
        studentId: Number(studentId),
        collaboratorId: Number(collId),
      }).then((res) => {
        if (res?.data) {
          console.log(res.data);
          dispatch(setStudentList([res.data]));
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studentId, studentsList]);

  return (
    <>
      <React.Fragment>
        <Grid component={MainCard}>
          <Grid item xs={12}>
            <Grid item container alignItems="center" gap={1} sx={{ cursor: 'pointer' }}>
              <IconButton onClick={() => history.goBack()}>
                <IconArrowNarrowLeft size={30} color={theme.palette.text.dark} />
              </IconButton>
              <Typography variant="h2">
                {t('teachers.academic_counseling.students_assigned.history.title')}{' '}
                {student ? ' - ' : ''}
                {student?.user?.person?.pers_first_name}{' '}
                {student?.user?.person?.pers_first_lastname}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid component={MainCard} style={{ marginTop: 20 }}>
          {student ? <StudentAsignedHistoryAnnouncement /> : <LoadingScreen />}
        </Grid>
      </React.Fragment>
    </>
  );
};

export default StudentAsignedHistoryAdsScreen;
