import { Box, Button, Grid, Skeleton, TextField, Typography } from '@material-ui/core';
import { DateRangePicker, LocalizationProvider } from '@material-ui/lab';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import { es } from 'date-fns/locale';
import { useFormik } from 'formik';
import InputLabel from '../../../../shared/components/forms/InputLabel';
import { useLazyMetricsVisitsDatabaseQuery } from '../../slices/statisticsApiSlice';
import { format } from 'date-fns';
import { useAppSelector } from '../../../../../hooks/useRedux';
import Chart from 'react-apexcharts';
import MetricsMessage from '../MetricsMessage';

const Docentes = () => {
  const { currentProfile } = useAppSelector((state) => state.access);
  const [metrics, { isLoading, data, isUninitialized }] = useLazyMetricsVisitsDatabaseQuery();

  const { values, errors, touched, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      ini: '',
      end: '',
    },
    onSubmit: (values) => {
      metrics({
        start_date: format(new Date(values.ini), 'yyyy-MM-dd'),
        end_date: format(new Date(values.end), 'yyyy-MM-dd'),
        user_profile_id: currentProfile?.profileId!,
      });
    },
  });

  return (
    <Grid container>
      <Grid item container spacing={2} direction="row-reverse">
        <Grid item xs={12} md={8} lg={8}>
          <InputLabel>Fechas</InputLabel>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
            <DateRangePicker
              value={[values.ini, values.end]}
              onChange={(val) => {
                if (val) {
                  setFieldValue('ini', val[0]);
                  setFieldValue('end', val[1]);
                }
              }}
              renderInput={(startProps, endProps) => (
                <>
                  <TextField
                    {...startProps}
                    label={null}
                    fullWidth
                    placeholder="Fecha"
                    error={Boolean(errors.ini && touched.ini)}
                    helperText={Boolean(errors.ini && touched.ini) && errors.ini}
                  />
                  <Box sx={{ mx: 2 }}> - </Box>
                  <TextField
                    {...endProps}
                    label={null}
                    fullWidth
                    placeholder="Fecha"
                    error={Boolean(errors.end && touched.end)}
                    helperText={Boolean(errors.end && touched.end) && errors.end}
                  />
                </>
              )}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={12} md={12} lg={12} textAlign="right">
          <Button
            variant="contained"
            size="large"
            color="primary"
            sx={{ mt: 2 }}
            onClick={() => handleSubmit()}
          >
            Buscar
          </Button>
        </Grid>
      </Grid>
      <br />
      <br />
      <Grid container spacing={6}>
        {isUninitialized ? (
          <Grid item xs={12}>
            <MetricsMessage />
          </Grid>
        ) : (
          <>
            <Grid item xs={12} md={12} lg={12}>
              <Typography variant="h3" sx={{ m: 2 }}>
                Número de visitas por Bases de datos contratadas
              </Typography>
              <Box sx={{ maxWidth: 600, margin: '0 auto' }}>
                {isLoading ? (
                  <Skeleton width={400} height={400} />
                ) : (
                  <>
                    {data && (
                      <Chart
                        series={[
                          {
                            name: 'Docentes',
                            data: [
                              data?.fielweb_collaborator ? Number(data?.fielweb_collaborator) : 0,
                              data?.web_of_science_group_collaborator
                                ? Number(data?.web_of_science_group_collaborator)
                                : 0,
                              data?.vlex_collaborator ? Number(data?.vlex_collaborator) : 0,
                              data?.eureka_collaborator ? Number(data?.eureka_collaborator) : 0,
                              data?.scopus_collaborator ? Number(data?.scopus_collaborator) : 0,
                              data?.ebsco_collaborator ? Number(data?.ebsco_collaborator) : 0,
                              //   data?.bibliotechnia ? Number(data?.bibliotechnia) : 0,
                              //   data?.res_non_verba ? Number(data?.res_non_verba) : 0,
                              //   data?.
                            ],
                          },
                          {
                            name: 'Estudiantes',
                            data: [
                              data?.fielweb_students ? Number(data?.fielweb_students) : 0,
                              data?.web_of_science_group_students
                                ? Number(data?.web_of_science_group_students)
                                : 0,
                              data?.vlex_students ? Number(data?.vlex_students) : 0,
                              data?.eureka_students ? Number(data?.eureka_students) : 0,
                              data?.scopus_students ? Number(data?.scopus_students) : 0,
                              data?.ebsco_students ? Number(data?.ebsco_students) : 0,
                              //   data?.bibliotechnia ? Number(data?.bibliotechnia) : 0,
                              //   data?.res_non_verba ? Number(data?.res_non_verba) : 0,
                              //   data?.
                            ],
                          },
                        ]}
                        options={{
                          xaxis: {
                            categories: [
                              'FielWeb',
                              'Web of Science',
                              'Vlex',
                              'Eureka',
                              'Scopus',
                              'Ebsco',
                            ],

                            labels: {
                              rotate: 0,
                              style: {
                                fontSize: '8px',
                              },
                            },
                          },
                          dataLabels: {
                            enabled: true,
                            offsetX: -6,
                            style: {
                              fontSize: '12px',
                              colors: ['#fff'],
                            },
                          },
                          chart: {
                            type: 'bar',
                            stacked: true,
                          },
                        }}
                        type="bar"
                        width="100%"
                      />
                    )}
                  </>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h3" sx={{ m: 2 }}>
                Total de Búsquedas por Docentes
              </Typography>
              {data && (
                <Chart
                  series={[
                    {
                      name: 'ING',
                      data: [
                        data?.fielweb_ingenierias_collaborators
                          ? Number(data?.fielweb_ingenierias_collaborators)
                          : 12,
                        data?.web_of_science_group_ingenierias_collaborators
                          ? Number(data?.web_of_science_group_ingenierias_collaborators)
                          : 5,
                        data?.vlex_ingenierias_collaborators
                          ? Number(data?.vlex_ingenierias_collaborators)
                          : 3,
                        data?.eureka_ingenierias_collaborators
                          ? Number(data?.eureka_ingenierias_collaborators)
                          : 2,
                        data?.scopus_ingenierias_collaborators
                          ? Number(data?.scopus_ingenierias_collaborators)
                          : 1,
                        data?.ebsco_ingenierias_collaborators
                          ? Number(data?.ebsco_ingenierias_collaborators)
                          : 9,
                      ],
                    },
                    {
                      name: 'FCEE',
                      data: [
                        data?.fielweb_ciencias_economicas_collaborators
                          ? Number(data?.fielweb_ciencias_economicas_collaborators)
                          : 0,
                        data?.web_of_science_group_ciencias_economicas_collaborators
                          ? Number(data?.web_of_science_group_ciencias_economicas_collaborators)
                          : 0,
                        data?.vlex_ciencias_economicas_collaborators
                          ? Number(data?.vlex_ciencias_economicas_collaborators)
                          : 0,
                        data?.eureka_ciencias_economicas_collaborators
                          ? Number(data?.eureka_ciencias_economicas_collaborators)
                          : 0,
                        data?.scopus_ciencias_economicas_collaborators
                          ? Number(data?.scopus_ciencias_economicas_collaborators)
                          : 0,
                        data?.ebsco_ciencias_economicas_collaborators
                          ? Number(data?.ebsco_ciencias_economicas_collaborators)
                          : 0,
                      ],
                    },
                    {
                      name: 'FMC',
                      data: [
                        data?.fielweb_marketing_collaborators
                          ? Number(data?.fielweb_marketing_collaborators)
                          : 0,
                        data?.web_of_science_group_marketing_collaborators
                          ? Number(data?.web_of_science_group_marketing_collaborators)
                          : 0,
                        data?.vlex_marketing_collaborators
                          ? Number(data?.vlex_marketing_collaborators)
                          : 0,
                        data?.eureka_marketing_collaborators
                          ? Number(data?.eureka_marketing_collaborators)
                          : 0,
                        data?.scopus_marketing_collaborators
                          ? Number(data?.scopus_marketing_collaborators)
                          : 0,
                        data?.ebsco_marketing_collaborators
                          ? Number(data?.ebsco_marketing_collaborators)
                          : 0,
                      ],
                    },
                    {
                      name: 'FDG',
                      data: [
                        data?.fielweb_derecho_collaborators
                          ? Number(data?.fielweb_derecho_collaborators)
                          : 0,
                        data?.web_of_science_group_derecho_collaborators
                          ? Number(data?.web_of_science_group_derecho_collaborators)
                          : 0,
                        data?.vlex_derecho_collaborators
                          ? Number(data?.vlex_derecho_collaborators)
                          : 0,
                        data?.eureka_derecho_collaborators
                          ? Number(data?.eureka_derecho_collaborators)
                          : 0,
                        data?.scopus_derecho_collaborators
                          ? Number(data?.scopus_derecho_collaborators)
                          : 0,
                        data?.ebsco_derecho_collaborators
                          ? Number(data?.ebsco_derecho_collaborators)
                          : 0,
                      ],
                    },
                    {
                      name: 'EGH',
                      data: [
                        data?.fielweb_estudios_globales_collaborators
                          ? Number(data?.fielweb_estudios_globales_collaborators)
                          : 0,
                        data?.web_of_science_group_estudios_globales_collaborators
                          ? Number(data?.web_of_science_group_estudios_globales_collaborators)
                          : 0,
                        data?.vlex_estudios_globales_collaborators
                          ? Number(data?.vlex_estudios_globales_collaborators)
                          : 0,
                        data?.eureka_estudios_globales_collaborators
                          ? Number(data?.eureka_estudios_globales_collaborators)
                          : 0,
                        data?.scopus_estudios_globales_collaborators
                          ? Number(data?.scopus_estudios_globales_collaborators)
                          : 0,
                        data?.ebsco_estudios_globales_collaborators
                          ? Number(data?.ebsco_estudios_globales_collaborators)
                          : 0,
                      ],
                    },
                    {
                      name: 'FCS',
                      data: [
                        data?.fielweb_ciencias_salud_collaborators
                          ? Number(data?.fielweb_ciencias_salud_collaborators)
                          : 0,
                        data?.web_of_science_group_ciencias_salud_collaborators
                          ? Number(data?.web_of_science_group_ciencias_salud_collaborators)
                          : 0,
                        data?.vlex_ciencias_salud_collaborators
                          ? Number(data?.vlex_ciencias_salud_collaborators)
                          : 0,
                        data?.eureka_ciencias_salud_collaborators
                          ? Number(data?.eureka_ciencias_salud_collaborators)
                          : 0,
                        data?.scopus_ciencias_salud_collaborators
                          ? Number(data?.scopus_ciencias_salud_collaborators)
                          : 0,
                        data?.ebsco_ciencias_salud_collaborators
                          ? Number(data?.ebsco_ciencias_salud_collaborators)
                          : 0,
                      ],
                    },
                    {
                      name: 'POSGRADO',
                      data: [
                        data?.fielweb_ciencias_salud_collaborators
                          ? Number(data?.fielweb_ciencias_salud_collaborators)
                          : 0,
                        data?.web_of_science_group_posgrado_collaborators
                          ? Number(data?.web_of_science_group_posgrado_collaborators)
                          : 0,
                        data?.vlex_posgrado_collaborators
                          ? Number(data?.vlex_posgrado_collaborators)
                          : 0,
                        data?.eureka_posgrado_collaborators
                          ? Number(data?.eureka_posgrado_collaborators)
                          : 0,
                        data?.scopus_posgrado_collaborators
                          ? Number(data?.scopus_posgrado_collaborators)
                          : 0,
                        data?.ebsco_posgrado_collaborators
                          ? Number(data?.ebsco_posgrado_collaborators)
                          : 0,
                      ],
                    },
                  ]}
                  options={{
                    xaxis: {
                      categories: [
                        'FielWeb',
                        'Web of Science',
                        'Vlex',
                        'Eureka',
                        'Scopus',
                        'Ebsco',
                      ],

                      labels: {
                        rotate: 0,
                        style: {
                          fontSize: '8px',
                        },
                      },
                    },
                    dataLabels: {
                      enabled: true,
                      offsetX: -6,
                      style: {
                        fontSize: '12px',
                        colors: ['#fff'],
                      },
                    },
                    chart: {
                      type: 'bar',
                      height: 400,
                      stacked: true,
                      toolbar: {
                        show: true,
                      },
                    },
                  }}
                  type="bar"
                  width="100%"
                />
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h3" sx={{ m: 2 }}>
                Total de Búsquedas por Alumnos
              </Typography>
              {data && (
                <Chart
                  series={[
                    {
                      name: 'ING',
                      data: [
                        data?.fielweb_ingenierias_students
                          ? Number(data?.fielweb_ingenierias_students)
                          : 0,
                        data?.web_of_science_group_ingenierias_students
                          ? Number(data?.web_of_science_group_ingenierias_students)
                          : 0,
                        data?.vlex_ingenierias_students
                          ? Number(data?.vlex_ingenierias_students)
                          : 0,
                        data?.eureka_ingenierias_students
                          ? Number(data?.eureka_ingenierias_students)
                          : 0,
                        data?.scopus_ingenierias_students
                          ? Number(data?.scopus_ingenierias_students)
                          : 0,
                        data?.ebsco_ingenierias_students
                          ? Number(data?.ebsco_ingenierias_students)
                          : 0,
                      ],
                    },
                    {
                      name: 'FCEE',
                      data: [
                        data?.fielweb_ciencias_economicas_students
                          ? Number(data?.fielweb_ciencias_economicas_students)
                          : 0,
                        data?.web_of_science_group_ciencias_economicas_students
                          ? Number(data?.web_of_science_group_ciencias_economicas_students)
                          : 0,
                        data?.vlex_ciencias_economicas_students
                          ? Number(data?.vlex_ciencias_economicas_students)
                          : 0,
                        data?.eureka_ciencias_economicas_students
                          ? Number(data?.eureka_ciencias_economicas_students)
                          : 0,
                        data?.scopus_ciencias_economicas_students
                          ? Number(data?.scopus_ciencias_economicas_students)
                          : 0,
                        data?.ebsco_ciencias_economicas_students
                          ? Number(data?.ebsco_ciencias_economicas_students)
                          : 0,
                      ],
                    },
                    {
                      name: 'FMC',
                      data: [
                        data?.fielweb_marketing_students
                          ? Number(data?.fielweb_marketing_students)
                          : 0,
                        data?.web_of_science_group_marketing_students
                          ? Number(data?.web_of_science_group_marketing_students)
                          : 0,
                        data?.vlex_marketing_students ? Number(data?.vlex_marketing_students) : 0,
                        data?.eureka_marketing_students
                          ? Number(data?.eureka_marketing_students)
                          : 0,
                        data?.scopus_marketing_students
                          ? Number(data?.scopus_marketing_students)
                          : 0,
                        data?.ebsco_marketing_students ? Number(data?.ebsco_marketing_students) : 0,
                      ],
                    },
                    {
                      name: 'FDG',
                      data: [
                        data?.fielweb_derecho_students ? Number(data?.fielweb_derecho_students) : 0,
                        data?.web_of_science_group_derecho_students
                          ? Number(data?.web_of_science_group_derecho_students)
                          : 0,
                        data?.vlex_derecho_students ? Number(data?.vlex_derecho_students) : 0,
                        data?.eureka_derecho_students ? Number(data?.eureka_derecho_students) : 0,
                        data?.scopus_derecho_students ? Number(data?.scopus_derecho_students) : 0,
                        data?.ebsco_derecho_students ? Number(data?.ebsco_derecho_students) : 0,
                      ],
                    },
                    {
                      name: 'EGH',
                      data: [
                        data?.fielweb_estudios_globales_students
                          ? Number(data?.fielweb_estudios_globales_students)
                          : 0,
                        data?.web_of_science_group_estudios_globales_students
                          ? Number(data?.web_of_science_group_estudios_globales_students)
                          : 0,
                        data?.vlex_estudios_globales_students
                          ? Number(data?.vlex_estudios_globales_students)
                          : 0,
                        data?.eureka_estudios_globales_students
                          ? Number(data?.eureka_estudios_globales_students)
                          : 0,
                        data?.scopus_estudios_globales_students
                          ? Number(data?.scopus_estudios_globales_students)
                          : 0,
                        data?.ebsco_estudios_globales_students
                          ? Number(data?.ebsco_estudios_globales_students)
                          : 0,
                      ],
                    },
                    {
                      name: 'FCS',
                      data: [
                        data?.fielweb_ciencias_salud_students
                          ? Number(data?.fielweb_ciencias_salud_students)
                          : 0,
                        data?.web_of_science_group_ciencias_salud_students
                          ? Number(data?.web_of_science_group_ciencias_salud_students)
                          : 0,
                        data?.vlex_ciencias_salud_students
                          ? Number(data?.vlex_ciencias_salud_students)
                          : 0,
                        data?.eureka_ciencias_salud_students
                          ? Number(data?.eureka_ciencias_salud_students)
                          : 0,
                        data?.scopus_ciencias_salud_students
                          ? Number(data?.scopus_ciencias_salud_students)
                          : 0,
                        data?.ebsco_ciencias_salud_students
                          ? Number(data?.ebsco_ciencias_salud_students)
                          : 0,
                      ],
                    },
                    {
                      name: 'POSGRADO',
                      data: [
                        data?.fielweb_ciencias_salud_students
                          ? Number(data?.fielweb_ciencias_salud_students)
                          : 0,
                        data?.web_of_science_group_posgrado_students
                          ? Number(data?.web_of_science_group_posgrado_students)
                          : 0,
                        data?.vlex_posgrado_students ? Number(data?.vlex_posgrado_students) : 0,
                        data?.eureka_posgrado_students ? Number(data?.eureka_posgrado_students) : 0,
                        data?.scopus_posgrado_students ? Number(data?.scopus_posgrado_students) : 0,
                        data?.ebsco_posgrado_students ? Number(data?.ebsco_posgrado_students) : 0,
                      ],
                    },
                  ]}
                  options={{
                    xaxis: {
                      categories: [
                        'FielWeb',
                        'Web of Science',
                        'Vlex',
                        'Eureka',
                        'Scopus',
                        'Ebsco',
                      ],

                      labels: {
                        rotate: 0,
                        style: {
                          fontSize: '8px',
                        },
                      },
                    },
                    dataLabels: {
                      enabled: true,
                      offsetX: -6,
                      style: {
                        fontSize: '12px',
                        colors: ['#fff'],
                      },
                    },
                    chart: {
                      type: 'bar',
                      height: 400,
                      stacked: true,
                      toolbar: {
                        show: true,
                      },
                    },
                  }}
                  type="bar"
                  width="100%"
                />
              )}
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default Docentes;
