import MainCard from '../../../shared/components/cards/MainCard';
import { Typography, Grid } from '@material-ui/core';
import MatterList from '../components/MatterList';
import { useTranslation } from 'react-i18next';

const AcademicPortofolioScreen = () => {
  const { t } = useTranslation();

  return (
    <Grid component={MainCard} sx={{ padding: 2 }}>
      <Grid sx={{ mb: 3 }}>
        <Typography variant="h2">{t('students.courses.title')}</Typography>
        <Typography variant="body1">{t('students.courses.description')}</Typography>
        <MatterList />
      </Grid>
    </Grid>
  );
};

export default AcademicPortofolioScreen;
