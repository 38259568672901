import { DefaultConfiguration } from '../interfaces/tenants.interfaces';

export const initialDefaultConfiguration: DefaultConfiguration = {
  currency: 'dollar',
  currency_symbol: '$',
  current_country: 'pais-ec',
  current_province: 'provincia-ec-guayas',
  decimal_discount: 4,
  language: 'es',
  number_decimal: 2,
  timezone: 'America/Guayaquil',
  time_inactive_client: 65,
  redLinksPhotoId: 21,
  redLinksToken: '',
  file_size: '5MB',
  multilanguage: false,
};
