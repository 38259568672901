import { Switch } from 'react-router-dom';

import GuardRoute from '../../../../router/guards/GuardRoute';

// import { permissionKey, subPermissionKeys } from '../constants/permissions';
import ModuleReportsScreen from '../screens/ModuleReportsScreen';
import ViewReportScreen from '../screens/ViewReportScreen';
import { subPermissionKeys } from '../constants/permissions';

const RequestsStatusRouter = () => {
  return (
    <Switch>
      <GuardRoute
        exact
        path="/reportes"
        component={ModuleReportsScreen}
        permissionKey={subPermissionKeys.groupKey}
        subPermissionKey={subPermissionKeys.read}
      />

      <GuardRoute
        exact
        path="/reportes/:reportId"
        component={ViewReportScreen}
        permissionKey={subPermissionKeys.groupKey}
        subPermissionKey={subPermissionKeys.show}
      />
    </Switch>
  );
};

export default RequestsStatusRouter;
