import { IconLogout } from '@tabler/icons';
import { Button, useTheme } from '@material-ui/core';

import { useLogoutMutation } from '../../../auth/slices/authApiSlice';
import { useTranslation } from 'react-i18next';

const LogoutButton = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [logout] = useLogoutMutation();

  const handleLogout = async () => {
    await logout({});
  };

  return (
    // <Tooltip title="Cerrar sesión">
    <Button
      startIcon={<IconLogout />}
      onClick={handleLogout}
      variant="contained"
      color="inherit"
      size="small"
      sx={{
        // borderRadius: `12px`,
        // py: 0,
        // px: 4,
        backgroundColor: `${theme.palette.primary.light}`,
        color: `${theme.palette.primary.main}`,
        // fontWeight: 800,
        // fontSize: '1.2rem',
      }}
    >
      {t('shared.modules.logout')}
    </Button>
    // </Tooltip>
  );
};

export default LogoutButton;
