export const typesDocuments = [
  { value: 'Publicacion cientifica', label: 'Publicación científica' },
  { value: 'Multimedia', label: 'Multimedia' },
  { value: 'Manual institucional', label: 'Manual institucional' },
  { value: 'Revista', label: 'Revista' },
  { value: 'Folletos', label: 'Folletos' },
  { value: 'Periodico', label: 'Periódico' },
  { value: 'Proyecto de investigacion', label: 'Proyecto de investigación' },
  { value: 'Enciclopedia', label: 'Enciclopedia' },
  { value: 'Diccionario', label: 'Diccionario' },
  { value: 'Videos: Documentales/Peliculas', label: 'Videos: Documentales/Peliculas' },
];
