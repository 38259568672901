import { Grid, Typography } from '@material-ui/core';
import { useAppSelector } from '../../../../hooks/useRedux';
import MainCard from '../../../shared/components/cards/MainCard';
import DeleteProfileDialog from '../components/DeleteProfileDialog';
import ProfilesTable from '../components/ProfilesTable';
import { useTranslation } from 'react-i18next';

const ProfilesScreen = () => {
  const { t } = useTranslation();
  const { profileIdToDelete } = useAppSelector((state) => state.profiles);

  return (
    <div>
      <Grid component={MainCard} sx={{ padding: 2 }}>
        <Grid sx={{ mb: 3 }}>
          <Typography variant="h2">{t('configuration.profiles.title')}</Typography>
        </Grid>
        <ProfilesTable />

        {profileIdToDelete && <DeleteProfileDialog />}
      </Grid>
    </div>
  );
};

export default ProfilesScreen;
