import { useTranslation } from 'react-i18next';
import DeleteAlert from '../../../../shared/components/dialogs/DeleteAlert';
import { useDeleteStudentEmergencyContactMutation } from '../../slices/studentsApiSlice';

interface Props {
  openModal: boolean;
  dataId: number;
  studentId: string;
  handleCloseModal: () => void;
}

const DeleteEmergencyContDialog = ({ openModal, dataId, studentId, handleCloseModal }: Props) => {
  const { t } = useTranslation();
  const [deleteContact, { isLoading, isSuccess, isError, error, reset }] =
    useDeleteStudentEmergencyContactMutation();

  console.log({ emergencyContactId: dataId, studentId });
  const handleDelete = () => {
    deleteContact({ emergencyContactId: dataId, studentId });
  };

  const handleClose = () => {
    handleCloseModal();
    setTimeout(() => reset(), 300);
  };
  //

  return (
    <DeleteAlert
      open={openModal}
      onClose={handleClose}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      handleDelete={handleDelete}
      deleteTitle={t('¿Estás seguro de eliminar el contacto?')}
      error={error as string}
    />
  );
};

export default DeleteEmergencyContDialog;
