export const permissionKey = 'financial period';

export interface SubPermissionKeys {
  read: string;
  create: string;
  edit: string;
  delete: string;
}

export const subPermissionKeys: SubPermissionKeys = {
  read: 'financial-periods-listar-periodo-financiero',
  create: 'financial-period-crear-periodo-financiero',
  edit: 'financial-period-actualizar-periodo-financiero',
  delete: 'financial-period-eliminar-periodo-financiero',
};
