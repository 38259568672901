import { IconButton, Tooltip } from '@material-ui/core';
import { IconEyeCheck } from '@tabler/icons';

const PreviewEventNewImage = ({ handlePreview }: { handlePreview?: () => void }) => {
  return (
    <Tooltip title="Visualizar imagen">
      <IconButton aria-label="Agregar foto" color="default" onClick={handlePreview}>
        <IconEyeCheck />
      </IconButton>
    </Tooltip>
  );
};

export default PreviewEventNewImage;
