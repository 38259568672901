import { Grid, InputLabel } from '@material-ui/core';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';
import {
  useLazyGetPosgraduatePlanningCalendarQuery,
  useLazyGetPosgraduatePlanningCalendarsQuery,
} from '../../academic-planning-postgraduate-calendars/slices/posgraduatePlanningCalendarApiSlice';
import { setCalendarIdSelected, setHolidaysMove } from '../slices/acPlanPostgraduateSlice';
import telework from '../../../../assets/images/illustrations/telework.svg';
import IconComponent from '../../../shared/components/errors-boundary/IconComponent';
import { useEffect, useState } from 'react';
import CustomAsyncPaginatedSelect from '../../../shared/components/extended/Form/CustomAsyncPaginatedSelect';
import { PosgraduatePlanningCalendar } from '../../academic-planning-postgraduate-calendars/interfaces/posgraduatePlanningCalendars.interface';

const CalendarSelection = () => {
  const dispatch = useAppDispatch();

  const { calendarIdSelected } = useAppSelector((state) => state.academicPlanningPosgraduate);

  const { currentProfile } = useAppSelector((state) => state.access);

  const [calendarValue, setCalendarValue] = useState<any | null>(null);

  const [getCalendar] = useLazyGetPosgraduatePlanningCalendarQuery();

  useEffect(() => {
    if (calendarIdSelected) {
      getCalendar({ id: calendarIdSelected, profileId: currentProfile?.profileId! })
        .unwrap()
        .then((calendar: PosgraduatePlanningCalendar) => {
          setCalendarValue({
            value: calendar.id,
            label: calendar.pc_description,
            ...calendar,
          });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarIdSelected]);

  const [
    getCalendarsTrigger,
    // { data: institutesAvailables, isLoading: isLoadingInstitutesAvailables },
  ] = useLazyGetPosgraduatePlanningCalendarsQuery();

  const getCalendars = async (search: string, page: number, perPage: number) => {
    const response = await getCalendarsTrigger({
      page,
      perPage,
      search,
      profileId: currentProfile?.profileId!,
    }).unwrap();

    const hasMore = response.current_page < response.last_page;

    return {
      options: response.data.map((dt) => {
        return {
          value: dt.id,
          label: dt.pc_description,
          ...dt,
        };
      }),
      hasMore,
    };
  };

  useEffect(() => {
    if (calendarValue) {
      dispatch(setCalendarIdSelected(calendarValue?.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarValue]);

  return (
    <Grid container justifyContent="center" mb={2}>
      <Grid item xs={12} md={3}>
        <InputLabel>Calendario</InputLabel>
        <CustomAsyncPaginatedSelect
          key={'id'}
          value={calendarValue}
          isError={false}
          placeholder={'Seleccione un calendario'}
          setValue={(value) => {
            setCalendarValue(value);
            dispatch(setHolidaysMove(false));
          }}
          fetchFunc={getCalendars}
        />
      </Grid>

      {!calendarIdSelected && (
        <Grid item xs={12}>
          <IconComponent
            icon={<img src={telework} alt="telework" />}
            title="Selecciona un calendario"
            subtitle="Para poder visualizar la planificación debes seleccionar un calendario"
          />
        </Grid>
      )}
    </Grid>
  );
};

export default CalendarSelection;
