import { Grid, Typography } from '@material-ui/core';
import { useAppSelector } from '../../../../hooks/useRedux';
import MainCard from '../../../shared/components/cards/MainCard';
import CalificationModelTable from '../components/CalificationModelTable';
import DeleteCalificationModelDialog from '../components/DeleteCalificationModelDialog';

const CalificationModelScreen = () => {
  const { calificationModelIdToDelete } = useAppSelector((state) => state.calificationModel);
  return (
    <div>
      <Grid component={MainCard} sx={{ padding: 2 }}>
        <Grid sx={{ mb: 3 }}>
          <Typography variant="h2">Modelo de Calificación</Typography>
        </Grid>
        <CalificationModelTable />

        {calificationModelIdToDelete && <DeleteCalificationModelDialog />}
      </Grid>
    </div>
  );
};

export default CalificationModelScreen;
