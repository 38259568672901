import { Switch } from 'react-router-dom';

// import { useAppSelector } from '../../../../hooks/useRedux';
import BusRouteScreen from '../screens/BusRouteScreen';
import GuardRoute from '../../../../router/guards/GuardRoute';
import EditBusRoutesScreen from '../screens/EditBusRoutesScreen';
import EditBusToursScreen from '../screens/EditBusToursScreen';
import EditBusSchedulesScreen from '../screens/BusSchedulesFormScreen';
import BusSchedulesScreen from '../screens/BusSchedulesScreen';
import { subPermissionKeys } from '../constants/permissions';

function BusRouteRouter() {
  // Verify permission
  // const { currentModule } = useAppSelector((state) => state.access);
  // const hasPermission = permissionKey in currentModule?.permissions!;

  // if (!hasPermission) return <Redirect to="/" />;

  return (
    <Switch>
      <GuardRoute
        exact
        path={`/bus-route`}
        component={BusRouteScreen}
        permissionKey={subPermissionKeys.groupKey}
        subPermissionKey={subPermissionKeys.getRoutesCampus}
      />

      <GuardRoute
        exact
        path={`/bus-route/editar-rutas`}
        component={EditBusRoutesScreen}
        permissionKey={subPermissionKeys.groupKey}
        subPermissionKey={subPermissionKeys.getRoutes}
      />

      <GuardRoute
        exact
        path={`/bus-route/ruta/:route/paradas`}
        component={EditBusToursScreen}
        permissionKey={subPermissionKeys.groupKey}
        subPermissionKey={[
          subPermissionKeys.getRouteTours,
          subPermissionKeys.createToursListGrouped,
        ]}
      />

      <GuardRoute
        exact
        path={`/bus-route/horarios`}
        component={BusSchedulesScreen}
        permissionKey={subPermissionKeys.groupKey}
        subPermissionKey={subPermissionKeys.getScheduleList}
      />

      <GuardRoute
        exact
        path={`/bus-route/horarios/crear`}
        component={EditBusSchedulesScreen}
        permissionKey={subPermissionKeys.groupKey}
        subPermissionKey={[
          subPermissionKeys.getSchedule,
          subPermissionKeys.updateSchedulesDetailsGroudped,
        ]}
      />

      <GuardRoute
        exact
        path={`/bus-route/horarios/:scheduleId`}
        component={EditBusSchedulesScreen}
        permissionKey={subPermissionKeys.groupKey}
        subPermissionKey={[
          subPermissionKeys.getSchedule,
          subPermissionKeys.updateSchedulesDetailsGroudped,
        ]}
      />
    </Switch>
  );
}

export default BusRouteRouter;
