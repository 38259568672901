import { parseISO, isValid, format } from 'date-fns';
import { isPrimitive } from '../helpers';
import { useGetLocaleDateLang } from '../../../translation/hooks/useGetLocaleDateLang';

interface IFormatDateOptions {
  withTime?: boolean;
  format?: string;
}

const defaultFormatDateOptions: IFormatDateOptions = {
  withTime: false,
  format: 'dd-MMM-yyyy',
};

export const useFormatDate = () => {
  const formatDateDefault = 'dd-MMM-yyyy';

  const divisorDateSymbol = '-';

  const { lang } = useGetLocaleDateLang();

  /**
   * Formatea una fecha
   * @param {any} value - Valor a formatear
   * @param {string=} formatDate - Formato de fecha
   * @param {IFormatDateOptions=} options - Opciones de formateo
   * - withTime: boolean - Formatea la fecha con hora
   * @returns string
   * @example
   * const { formatTableDate } = useFormatDate();
   * const date = formatTableDate('2021-01-01', 'dd-MMM-yyyy', { withTime: true });
   * console.log(date); // 01-Ene-2021 00:00
   * @example
   * const { formatTableDate } = useFormatDate();
   * const date = formatTableDate('2021-01-01', 'dd-MMM-yyyy', { withTime: false });
   * console.log(date); // 01-Ene-2021
   * @example
   * const { formatTableDate } = useFormatDate();
   * const date = formatTableDate('2021-01-01', 'dd-MMM-yyyy');
   * console.log(date); // 01-Ene-2021
   * @example
   * const { formatTableDate } = useFormatDate();
   * const date = formatTableDate('2021-01-01');
   * console.log(date); // 01-Ene-2021
   */
  const formatTableDate = (
    value: any,
    formatDate = formatDateDefault,
    symbolDivisor = divisorDateSymbol,
    options: IFormatDateOptions = defaultFormatDateOptions
  ): string => {
    const { withTime } = options;

    const finalDateFormat = withTime ? `${formatDate} HH:mm` : formatDate;

    const date =
      isValid(parseISO(value)) && isPrimitive(value) && `${value}`.split(symbolDivisor).length === 3
        ? format(parseISO(value + ''), finalDateFormat, { locale: lang })
        : value
        ? value + ''
        : '';

    return date;
  };

  /**
   * Formatea una fecha
   * @param {any} value - Valor a formatear
   * @param {IFormatDateOptions} options - Opciones de formateo
   * - withTime: boolean - Formatea la fecha con hora
   * - format: string - Formato de fecha
   * @returns string
   * @example
   * const { formatDate } = useFormatDate();
   * const date = formatDate('2021-01-01', { withTime: true });
   * console.log(date); // 01-Ene-2021 00:00
   * @example
   * const { formatDate } = useFormatDate();
   * const date = formatDate('2021-01-01', { withTime: false });
   * console.log(date); // 01-Ene-2021
   * @example
   * const { formatDate } = useFormatDate();
   * const date = formatDate('2021-01-01');
   * console.log(date); // 01-Ene-2021
   * @example
   * const { formatDate } = useFormatDate();
   * const date = formatDate('2021-01-01', { format: 'dd/MM/yyyy' });
   * console.log(date); // 01/01/2021
   * @example
   *  const { formatDate } = useFormatDate();
   * const date = formatDate('2021-01-01', { format: 'dd/MM/yyyy', withTime: true });
   * console.log(date); // 01/01/2021 00:00
   * @example
   * const { formatDate } = useFormatDate();
   * const date = formatDate('2021-01-01', { format: 'dd/MM/yyyy', withTime: false });
   * console.log(date); // 01/01/2021
   */
  const formatDate = (
    value: any,
    options: IFormatDateOptions = defaultFormatDateOptions
  ): string => {
    const { withTime, format: formatDate = formatDateDefault } = options;

    const finalDateFormat = withTime ? `${formatDate} HH:mm` : formatDate;

    const date =
      isValid(parseISO(value)) && isPrimitive(value)
        ? format(parseISO(value + ''), finalDateFormat, { locale: lang })
        : isValid(value)
        ? format(value, finalDateFormat, { locale: lang })
        : value
        ? value + ''
        : '';

    return date;
  };

  /**
   * Convierte una hora de 12 a 24 horas
   * @param {string} time12h - Hora en formato 12 horas
   * @returns string
   * @example
   * const { convertTime12to24 } = useFormatDate();
   * const time = convertTime12to24('12:00 pm');
   * console.log(time); // 12:00
   * @example
   * const { convertTime12to24 } = useFormatDate();
   * const time = convertTime12to24('7:00 PM');
   * console.log(time); // 19:00
   * @example
   * const { convertTime12to24 } = useFormatDate();
   * const time = convertTime12to24('7:00 am');
   * console.log(time); // 07:00
   * @example
   * const { convertTime12to24 } = useFormatDate();
   * const time = convertTime12to24('12:00 am');
   * console.log(time); // 00:00
   */
  const convertTime12to24 = (time12h: string) => {
    const [time, modifier] = time12h.split(' ');

    let [hours, minutes] = time.split(':');

    if (hours === '12') {
      hours = '00';
    }

    if (modifier === 'pm' || modifier === 'PM') {
      hours = `${parseInt(hours, 10) + 12}`;
    }

    return `${hours}:${minutes}`;
  };

  return { formatTableDate, formatDate, convertTime12to24 };
};

export default useFormatDate;
