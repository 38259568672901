import React, { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux';
import { useGetStudentAllAnnouncementsQuery } from '../../slices/academicCounselingApi';
import moment from 'moment';
import {
  Button,
  Grid,
  Paper,
  CardContent,
  TextField,
  Badge,
  Typography,
  Autocomplete,
} from '@material-ui/core';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@material-ui/lab';
import InfiniteScroll from 'react-infinite-scroll-component';
import { renderSkeletonTable } from '../../../../shared/helpers/render';
import { FilterAnnouncements } from '../../constants';
import MailIcon from '@material-ui/icons/EmailTwoTone';
import MainCard from '../../../../shared/components/cards/MainCard';
import { styleTimeline, styleTimelineByStudentMessage } from '../../constants/styles';
import { User } from '../../interfaces/student-announcements.interfaces';
import { useTranslation } from 'react-i18next';
import {
  setOpenSendAnnouncementModal,
  setTeacherSelected,
} from '../../slices/AcademicCounselingSlice';
import LoadingChildren from '../../../../shared/components/LoadingChildren';

interface Props {
  id: number;
  ans_message: string;
  ans_date_send: string;
  emisor: User;
  isStudent?: string | boolean;
}

const Accompainment = () => {
  const { t } = useTranslation();

  const classes = styleTimeline();

  const classesByStudentMessage = styleTimelineByStudentMessage();

  const dispatch = useAppDispatch();

  const [search, setSearch] = useState<string>('');
  const [dataSearch, setDataSearch] = useState<string>('');

  const { currentProfile, currentStudent } = useAppSelector((state) => state.access);

  const [page, setPage] = useState(1);
  const [perPage] = useState(10);

  const [announces, setAnnounces] = useState<any[]>([]);

  const { data, isLoading } = useGetStudentAllAnnouncementsQuery({
    studentId: currentStudent?.id!,
    user_profile_id: currentProfile?.profileId!,
    page,
    perPage,
  });

  const datafetch = useMemo(
    () =>
      data?.data?.map((value) => ({
        id: value?.id,
        ans_message: value?.announcement?.ans_message,
        ans_date_send: value?.announcement?.ans_date_send,
        emisor: value?.announcement?.user,
        isStudent: value?.announcement?.is_student,
      })) || [],
    [data]
  );

  useEffect(() => {
    if (page > 1) {
      setAnnounces((prev) => [...prev, ...datafetch]);
    } else {
      setAnnounces(datafetch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datafetch]);

  useEffect(() => {
    if (datafetch.length > 0) {
      dispatch(setTeacherSelected(datafetch[0].emisor));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datafetch]);

  const fetchMoreData = () => {
    if (page >= (data?.last_page || 0)) return;

    setPage((prev) => prev + 1);
  };

  const renderBadgeMessage = (date: string) => {
    return (
      <>
        {moment(date).isSame(moment(), 'day') ? (
          <Badge
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            color="primary"
            badgeContent={1}
          >
            <MailIcon />
          </Badge>
        ) : (
          <MailIcon />
        )}
      </>
    );
  };

  const RenderAnnouncement = ({ id, ans_message, ans_date_send, emisor, isStudent }: Props) => {
    return (
      <TimelineItem key={`${id}`} style={{ cursor: 'pointer' }}>
        <TimelineOppositeContent>
          <Typography variant="body2" color="textSecondary">
            {moment(ans_date_send).fromNow()}
          </Typography>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot color="grey">{renderBadgeMessage(ans_date_send)}</TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Paper
            elevation={3}
            className={isStudent ? classesByStudentMessage.paper : classes.paper}
          >
            <Typography>
              {emisor?.person?.pers_first_name} {emisor?.person?.pers_first_lastname}
            </Typography>
            <Typography variant="subtitle2">{emisor?.email}</Typography>
            <Grid sx={{ marginTop: 2 }}>
              <Typography>{ans_message}</Typography>
            </Grid>
          </Paper>
        </TimelineContent>
      </TimelineItem>
    );
  };

  const filterOrData = () => {
    if (dataSearch === '' || dataSearch === 'all') return announces;
    return announces.filter(
      (value) => moment(value.ans_date_send).isSame(moment(), 'day') === true
    );
  };

  const renderAnnouncements = () => {
    const data = filterOrData();
    return <>{data.map(RenderAnnouncement)}</>;
  };

  const NotData = () => {
    return <Typography variant="h5">{t('students.academic_counseling.no_announces')}</Typography>;
  };

  const InfiniteScrollComponent = () => {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12}>
          <LoadingChildren
            isLoading={isLoading}
            loadingSkeleton={renderSkeletonTable(4, 6)}
            isEmpty={!announces.length}
            emptyFallback={<></>}
          ></LoadingChildren>
          <InfiniteScroll
            dataLength={announces?.length}
            next={fetchMoreData}
            hasMore={(data?.total || 0) > announces.length}
            loader={renderSkeletonTable(3, 6)}
            endMessage={<NotData />}
          >
            {announces?.length! > 0 && (
              <MainCard title={t('students.academic_counseling.warnings_received')}>
                <CardContent>
                  <Timeline position="alternate">{renderAnnouncements()}</Timeline>
                </CardContent>
              </MainCard>
            )}
          </InfiniteScroll>
        </Grid>
      </Grid>
    );
  };

  return (
    <div>
      <React.Fragment>
        <Grid
          container
          style={{ marginBottom: 25 }}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item container spacing={2} xs={12}>
            {/* Container filter search */}
            <Grid item container xs={12} sm={4}>
              <Grid item xs={12}>
                <Autocomplete
                  options={FilterAnnouncements || []}
                  getOptionLabel={(option) => t(`utils.announcements.${option.key}`)}
                  onChange={(_, value) => {
                    setSearch(value?.value || '');
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Seleccione un filtro" />
                  )}
                  fullWidth
                />

                {/* <TextField
                id="button-action-search"
                fullWidth
                select
                value={search}
                onChange={handleChange}
                SelectProps={{
                  native: true,
                }}
                variant="outlined"
              >
                {FilterAnnouncements.map((option) => (
                  <option key={option.value} value={option.value}>
                    {t(`utils.announcements.${option.key}`)}
                  </option>
                ))}
              </TextField> */}
              </Grid>
            </Grid>
            {/* Action buttons */}
            <Grid
              item
              container
              spacing={1}
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              xs={12}
              sm={4}
            >
              <Grid item xs={4} md={5}>
                <Button
                  onClick={() => {
                    setDataSearch('');
                    setSearch('');
                  }}
                  disabled={search === ''}
                  fullWidth
                  variant="outlined"
                >
                  {t('students.academic_counseling.reset')}
                </Button>
              </Grid>
              <Grid item xs={4} md={4}>
                <Button
                  fullWidth
                  onClick={() => {
                    setDataSearch(search);
                  }}
                  disabled={search === ''}
                  variant="contained"
                >
                  {t('students.academic_counseling.filter')}
                </Button>
              </Grid>
            </Grid>
            <Grid
              item
              container
              spacing={1}
              justifyContent="flex-end"
              direction="row"
              alignItems="center"
              xs={12}
              sm={4}
            >
              <Grid item xs={4} md={4}>
                <Button
                  fullWidth
                  onClick={() => {
                    dispatch(setOpenSendAnnouncementModal(true));
                  }}
                  variant="contained"
                  disabled={datafetch?.length <= 0}
                >
                  {t('Enviar aviso')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <InfiniteScrollComponent />
      </React.Fragment>
    </div>
  );
};

export default Accompainment;
