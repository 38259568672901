import { Box, Button, Typography, useTheme } from '@material-ui/core';
import { DescriptionOutlined, FileDownloadOutlined } from '@material-ui/icons';
import moment from 'moment';
import { ToolsMaterial } from '../interfaces/materialsStudent.interfaces';
import { IconLink } from '@tabler/icons';

interface Props {
  tool: ToolsMaterial;
  modalOptions: {
    handleOpen: () => void;
    setDataModal: React.Dispatch<React.SetStateAction<ToolsMaterial | undefined>>;
  };
}

const SubAccordionItem = ({ tool, modalOptions }: Props) => {
  const { tool_ms_description, upload_date } = tool;
  const { handleOpen, setDataModal } = modalOptions;
  const dateUpload = moment(upload_date).format('DD MMM - hh:mm');
  const theme = useTheme();

  const handleLink = (url: string) => {
    window.open(url, '_blank');
  };
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingX: '1rem',
        marginBottom: '0.5rem',
        paddingY: '0.5rem',
        borderRadius: '8px',
        border: `1px solid ${theme.palette.primary.light}`,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {Boolean(tool.tool_ms_link) ? (
          <IconLink style={{ color: theme.palette.secondary.main }} />
        ) : (
          <DescriptionOutlined sx={{ color: theme.palette.secondary.main }} />
        )}
        <Typography
          sx={{
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.primary.dark,
            marginX: '0.5rem',
          }}
        >
          {tool_ms_description}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
            marginX: '1.5rem',
          }}
        >
          <Typography
            sx={{
              fontWeight: theme.typography.fontWeightBold,
              color: theme.palette.primary.dark,
              marginX: '0.5rem',
            }}
          >
            Subido el:{' '}
          </Typography>
          <Typography>{dateUpload.replace('.', '')}</Typography>
        </Box>
        <Box>
          {Boolean(tool.tool_ms_link) ? (
            <Button
              sx={{
                color: theme.palette.secondary.main,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              onClick={() => {
                handleLink(tool.tool_ms_link!);
              }}
            >
              <IconLink />
            </Button>
          ) : (
            <Button
              sx={{
                color: theme.palette.secondary.main,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              onClick={() => {
                handleOpen();
                setDataModal(tool);
              }}
            >
              <FileDownloadOutlined />
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default SubAccordionItem;
