import { useEffect } from 'react';

import { Autocomplete, Grid, TextField, Typography } from '@material-ui/core';
import { Link, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IconList, IconClipboardText } from '@tabler/icons';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';

import StatusChip from '../../../shared/components/badges/StatusChip';
import SearchPaginatedTable from '../../../shared/components/tables/SearchPaginatedTable';
import { useTableSearch } from '../../../../hooks/useTableSearch';
import {
  setCollaboratorsList,
  setEducationLevelIdSelected,
  setJointAcademicIdSelected,
  updateTeachersTableStates,
} from '../slices/teachersSlice';
import { useGetCollaboratorsQuery } from '../../../hr/collaborators/slices/collaboratorsApiSlice';
import CustomIconButton from '../../../shared/components/tables/buttons/CustomIconButton';
import { ITeacher } from '../interfaces/teachers.interface';
import TextFieldFetching from '../../../shared/components/TextFieldFetching';
import {
  useGetCollectiveEducationLevelQuery,
  useGetEducationLevelsParentsAndChildrenQuery,
} from '../../education-levels/slices/educationLevelsApiSlice';
import useSharedGuard from '../../../../hooks/useSharedGuard';
import { subPermissionKeys } from '../constants/permissions';

const TeachersTable = () => {
  const { t } = useTranslation();

  const { currentProfile } = useAppSelector((state) => state.access);

  const dispatch = useAppDispatch();

  const { path } = useRouteMatch();

  const { educationLevelIdSelected, jointAcademicIdSelected } = useAppSelector(
    (state) => state.teacher
  );

  const { page, perPage, search, filterArrow } = useAppSelector(
    (state) => state.teacher.teachersTable
  );

  const { data: eduLevels, isLoading: isLoadingEduLevels } =
    useGetEducationLevelsParentsAndChildrenQuery({
      profileId: currentProfile?.profileId!,
    });

  const { data: jointAcademics } = useGetCollectiveEducationLevelQuery(
    {
      educationLevelId: educationLevelIdSelected!,
    },
    {
      skip: !educationLevelIdSelected,
    }
  );

  const { setPage, setPerPage, setSearch, setFilterArrow } = useTableSearch({
    page,
    perPage,
    search,
    filterArrow,
    updateFunction: updateTeachersTableStates,
    otherFilters: {
      educationLevelIdSelected,
      jointAcademicIdSelected,
    },
  });

  const { hasAccess } = useSharedGuard(subPermissionKeys);

  const { isLoading, error, data, isFetching } = useGetCollaboratorsQuery(
    {
      profileId: currentProfile?.profileId!,
      page,
      perPage,
      search,
      coll_type: 'D',
      isFaculty: 1,
      statusId: 1,
      ...(filterArrow && { sort: filterArrow.name, type_sort: filterArrow.direction }),
      ...(educationLevelIdSelected && { education_level_id: educationLevelIdSelected }),
      ...(jointAcademicIdSelected && { joint_academic_id: jointAcademicIdSelected }),
    },
    { refetchOnMountOrArgChange: 30 }
  );

  useEffect(() => {
    if (data) dispatch(setCollaboratorsList(data.data as unknown as ITeacher[]));
  }, [data, dispatch]);

  const datafetch = (data?.data as ITeacher[] | undefined)?.map((d, index) => ({
    id: index + ((page - 1) * perPage + 1),
    name: `${d.pers_firstname || ''} ${d.pers_secondname || ''} ${d.pers_first_lastname || ''} ${
      d.pers_second_lastname || ''
    }`,
    // mail: d.coll_email,
    // gender: d.pers_gender,
    academic_unit: d.edu_name,
    joint_academic: d.joint_academic_name,
    phone_home: d.pers_phone_home,
    identification: d.pers_identification,
    address: d.pers_direction,
    // birthday: d.pers_date_birth,
    statusId: d.status_id,
    options: {
      id: d.id,
      email: d.coll_email,
      gender: d.pers_gender,
    },
  }));

  return (
    <SearchPaginatedTable
      data={datafetch!}
      headers={{
        id: 'N°',
        name: t('academic.teachers_list.table.name'),
        academic_unit: t('academic.teachers_list.table.academic_unit'),
        joint_academic: t('academic.teachers_list.table.joint_academic'),
        phone_home: t('academic.teachers_list.table.phone'),
        identification: t('academic.teachers_list.table.identification'),
        address: t('academic.teachers_list.table.address'),
        statusId: t('shared.input.status.label'),
        options: t('shared.search_paginated_table.table.options'),
      }}
      keyExtractor={(item) => item.options.id.toString()}
      searchPlacehoder={t('academic.teachers_list.table.search.placeholder')}
      isLoading={isLoading}
      isFetching={isFetching}
      error={error}
      perPage={perPage}
      setPerPage={setPerPage}
      total={data?.total || 0}
      page={page}
      setPage={setPage}
      numHeader={7}
      search={search}
      setSearch={setSearch}
      listFilterArrows={{
        name: 'p.pers_full_name',
        identification: 'p.pers_identification',
        address: 'p.pers_direction',
        statusId: 'status_id',
      }}
      filterArrow={filterArrow}
      setFilterArrow={setFilterArrow}
      customDataCellsProperties={{
        id: {
          align: 'center',
        },
        phone_home: {
          align: 'center',
        },
        identification: {
          align: 'center',
        },
        statusId: {
          align: 'center',
        },
        options: {
          align: 'center',
        },
        joint_academic: { align: 'center' },
        academic_unit: { align: 'center' },
      }}
      customRenderers={{
        statusId: (row) => <StatusChip statusId={Number(row.statusId)} />,
        name: (row) => (
          <>
            {row.name}
            <Typography variant="subtitle2" color="grey">
              {row.options.email}
            </Typography>
            <Typography variant="subtitle2" color="grey">
              {row.options.gender}
            </Typography>
          </>
        ),

        options: (row) => (
          <>
            {hasAccess('listCoursesAvailableCollaborator') && (
              <Link to={`${path}/${row.options.id}/asignar-cursos`}>
                <CustomIconButton
                  title={t('academic.teachers_list.assign_courses.tooltip')}
                  Icon={IconList}
                />
              </Link>
            )}

            {hasAccess('show') && (
              <Link to={`${path}/${row.options.id}/ficha`}>
                <CustomIconButton
                  title={t('academic.teachers_list.files.tooltip')}
                  Icon={IconClipboardText}
                  color="info"
                />
              </Link>
            )}

            {!hasAccess('listCoursesAvailableCollaborator') && !hasAccess('show') && 'Ninguna'}
          </>
        ),
      }}
      additionalFilters={
        <Grid container spacing={2}>
          {hasAccess('showAcademicUnits') && (
            <Grid item xs={12} sm={6} md={3}>
              {isLoadingEduLevels && <TextFieldFetching />}
              {!isLoadingEduLevels && (
                <Autocomplete
                  options={eduLevels || []}
                  getOptionLabel={(option) => option.edu_name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Unidad Académica"
                      placeholder="Seleccione una unidad académica"
                    />
                  )}
                  value={eduLevels?.find((edu) => edu.id === educationLevelIdSelected) || null}
                  onChange={(event, newValue) => {
                    dispatch(setEducationLevelIdSelected(newValue?.id || null));
                    dispatch(setJointAcademicIdSelected(null));
                  }}
                />
              )}
            </Grid>
          )}

          {hasAccess(['jointAcademic', 'showAcademicUnits']) && (
            <Grid item xs={12} sm={6} md={3}>
              {!educationLevelIdSelected && (
                <TextField label="Seleccione primero una unidad académica" disabled fullWidth />
              )}
              {educationLevelIdSelected && isLoadingEduLevels && <TextFieldFetching />}
              {educationLevelIdSelected && !isLoadingEduLevels && (
                <Autocomplete
                  options={jointAcademics || []}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Colectivo académico"
                      placeholder="Seleccione un colectivo académico"
                    />
                  )}
                  value={
                    jointAcademics?.find((joint) => joint.id === jointAcademicIdSelected) || null
                  }
                  onChange={(event, newValue) => {
                    dispatch(setJointAcademicIdSelected(newValue?.id || null));
                  }}
                />
              )}
            </Grid>
          )}
        </Grid>
      }
    />
  );
};

export default TeachersTable;
