import { useState } from 'react';

import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  Typography,
} from '@material-ui/core';

import { useTranslation } from 'react-i18next';

import { useAppSelector, useAppDispatch } from '../../../../../../hooks/useRedux';
import CollapsibleCard from '../../../../../shared/components/cards/CollapsibleCard';

import { renderErrorOrEmptyRow, renderSkeletonRows } from '../../../../../shared/helpers/render';
import { AmountCollectionPayload } from '../../../interfaces/payloads.interface';
import EditButton from '../../../../../shared/components/tables/buttons/EditButton';
import DeleteButton from '../../../../../shared/components/tables/buttons/DeleteButton';

import { setPayCollection } from '../../../slices/debtsPaymentSlice';
import AddPaymountMethod from '../../AddPaymountMethod';
import { removeItemFromIndexArr, showWithDecimals } from '../../../../../shared/helpers';
import AddButton from '../../../../../shared/components/tables/buttons/AddButton';

interface Props {
  handleOpen: () => void;
}

const AmountsPaysTable = ({ handleOpen: handleOpenAddPayment }: Props) => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const defaultConfiguration = useAppSelector((state) => state.access.defaultConfiguration);

  const { payCollection, totalPayAmount } = useAppSelector((state) => state.debtsPayment);

  //control open side dialog add payment
  const [open, setOpen] = useState(false);
  const [indexToEdit, setIndexToEdit] = useState<number>();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeletePay = (indextToDel: number) => {
    try {
      let newPayCollection = removeItemFromIndexArr(payCollection, indextToDel);

      dispatch(setPayCollection(newPayCollection));
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditPay = (indexToEdit: number) => {
    try {
      setIndexToEdit(indexToEdit);
      handleOpen();
    } catch (error) {
      console.log(error);
    }
  };

  const renderRow = (paids: AmountCollectionPayload, i: number) => (
    <TableRow
      hover
      className="table-row"
      key={`${paids.payment_amount.toString()}-${i}`}
      //   sx={{ cursor: 'pointer', backgroundColor: selected === paids.id ? '#eee' : '' }}
      onClick={() => {
        // setSelected(paids.id);
      }}
    >
      <TableCell>{paids.payment_way_name}</TableCell>
      <TableCell align="center">{showWithDecimals(paids.payment_amount)}</TableCell>
      <TableCell align="center">
        <EditButton handleEdit={() => handleEditPay(i)} />
        <DeleteButton handleDelete={() => handleDeletePay(i)} />
      </TableCell>
    </TableRow>
  );

  const renderRows = () => {
    return <>{payCollection?.map(renderRow)}</>;
  };

  return (
    <>
      <Grid
        item
        xs={12}
        component={CollapsibleCard}
        containerClick={false}
        title={
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h4">
                {t('financial.debts_payment.wizard.pay_method_table.title')}
              </Typography>
            </Grid>
            <Grid item>
              <AddButton
                variant="contained"
                onClick={handleOpenAddPayment}
                btnText={t('financial.debts_payment.wizard.confirm_fees.add_payment_btn')}
                sx={{
                  zIndex: 50,
                }}
              />
            </Grid>
          </Grid>
        }
        border={1}
        contentSX={{ padding: 0 }}
        sx={{ padding: 0, '& .MuiCardHeader-root': { p: 1 } }}
      >
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  {t('financial.debts_payment.wizard.amounts_pays_table.pay_method')}
                </TableCell>
                <TableCell align="center">
                  {t('financial.debts_payment.wizard.amounts_pays_table.pay_amount')}
                </TableCell>
                <TableCell align="center">
                  {t('financial.debts_payment.wizard.amounts_pays_table.options')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!payCollection ? renderSkeletonRows(5, 5) : renderRows()}
              {Boolean(payCollection.length) && (
                <TableRow>
                  <TableCell align="right">
                    <b>{t('financial.debts_payment.wizard.amounts_pays_table.total')}</b>
                  </TableCell>
                  <TableCell align="center">
                    {defaultConfiguration?.currency_symbol}
                    {showWithDecimals(totalPayAmount)}
                  </TableCell>
                  <TableCell align="center"></TableCell>
                </TableRow>
              )}

              {payCollection?.length === 0 &&
                renderErrorOrEmptyRow(
                  5,
                  t('financial.debts_payment.wizard.amounts_pays_table.no_data')
                )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      {/* dialogo para editar formas de pagos */}
      {/* considerar usar otro estado para controlar su renderizado en el DOM sin afectar la transicion de cierre */}
      <AddPaymountMethod
        open={open}
        isEdit
        indexPayToEdit={indexToEdit}
        handleCloseDialog={handleClose}
      />
    </>
  );
};

export default AmountsPaysTable;
