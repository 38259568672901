export const journeyTypes = [
  {
    id: 'TC',
    label: 'Tiempo completo',
    hours: 40,
  },
  {
    id: 'MT',
    label: 'Medio tiempo',
    hours: 20,
  },
  {
    id: 'TP',
    label: 'Tiempo parcial',
    hours: 20,
  },
];
