import { Grid, Typography } from '@material-ui/core';

import MainCard from '../../../shared/components/cards/MainCard';
import CardsScreen from './CardsScreen';
import DialogDeleteServicesCompany from '../components/DialogDeleteServicesCompany';
import { useAppSelector } from '../../../../hooks/useRedux';

const CompaniesScreen = () => {
  const { servicesDeleteModalOpen } = useAppSelector((state) => state.companies);

  return (
    <div>
      <Grid component={MainCard} sx={{ padding: 2 }}>
        <Grid sx={{ mb: 3 }}>
          <Typography variant="h2">Compañía</Typography>
        </Grid>

        <CardsScreen />
      </Grid>
      {servicesDeleteModalOpen && <DialogDeleteServicesCompany />}
    </div>
  );
};

export default CompaniesScreen;
