import { Switch, Redirect } from 'react-router-dom';
import { useAppSelector } from '../../../../hooks/useRedux';
import GuardRoute from '../../../../router/guards/GuardRoute';
import AcademicCounselingIndexScreen from '../screens/AcademicCouselingIndexScreen';

const AcademicCounselingRouter = () => {
  const { userInfo } = useAppSelector((state) => state.auth);

  if (!userInfo || !userInfo.studentInfo) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <Switch>
      <GuardRoute exact path="/consejeria-academica" component={AcademicCounselingIndexScreen} />
    </Switch>
  );
};

export default AcademicCounselingRouter;
